import React, { useEffect, useState } from 'react';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { getQuoteEvents } from 'Api/Shipment';
import { useColumns } from './EventsTable.data';

const EventsTable = ({ quoteId }) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ page: 1, itemsPerPage: 25 });
  const [eventsData, setEventsData] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState(null);

  const getEvents = async () => {
    try {
      setLoading(true);
      const params = {
        page: filters.page,
        itemsPerPage: filters.itemsPerPage,
      };

      const response = await getQuoteEvents(quoteId, params);
      setEventsData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };
  const onChangeRowPerPage = (rowPage) => {
    setFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (page) => {
    setFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getEvents();
  }, []);

  const columns = useColumns();

  return (
    <div>
      {loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          data={eventsData.data}
          rowPerPage={filters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: eventsData,
                rowPerPage: filters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          columns={columns}
        />
      )}
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </div>
  );
};

export default EventsTable;
