import React from 'react';
import sm from './CustomRadioButton.module.css';

const CustomRadioButton = ({ field, children, disabled = false, labelStyle, ...props }) => {
  return (
    <label className={`${sm.label} ${disabled ? sm.disabled : ''}`} onChange={props.onChange} style={labelStyle}>
      {children && children}
      <input {...field} type='radio' disabled={disabled} />
      <span className={sm.checkmark} />
    </label>
  );
};

export default CustomRadioButton;
