import uuid from 'react-uuid';

export const STATUSES = [
  {
    title: 'Available',
    id: 1,
    value: 'login_status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Logged Off',
    id: 2,
    value: 'login_status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Away',
    id: 3,
    value: 'login_status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Do Not Disturb',
    id: 4,
    value: 'login_status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Disabled',
    id: 5,
    value: 'login_status',
    type: 'checkbox',
    key: uuid(),
  },
];
