import React from 'react';
import ExportCsv from 'componentsV2/ExportCsv';
import HeaderStar from '../HeaderStar';
import classes from './shipmentHistory.module.css';

const ShipmentHistoryHeader = () => {
  return (
    <div className={classes.headerWrapper}>
      <HeaderStar title='Shipment History' />
      <ExportCsv type='shipment-history' />
    </div>
  );
};

export default ShipmentHistoryHeader;
