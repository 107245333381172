import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import Input, { InputWithText } from 'common/Input';
import Search from 'common/Search';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import CustomButton from 'components/CustomButton/CustomButton';
import { getGroups } from 'Api/StaffV2';
import { getAllVehicles } from 'Api/Map';
import { getDrivers } from 'Api/EquipmentProfile';
import { initialFilters } from 'pages/Planner/Availability/Availability.data';
import { SSearchWrapper } from 'pages/Planner/Availability/Availability.styles';
import { palette } from 'utils/constants';
import { SDatePicker, SWrapper } from './AvailabilityFilters.styles';

const AvailabilityFilters = ({ search, setSearch, filters, updateFilters, tab }) => {
  const navigate = useNavigate();
  const [drivers, setDrivers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [filterType, setFilterType] = useState('All');

  const getVehicles = async () => {
    try {
      const { data } = await getAllVehicles();
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getGroupsList = async () => {
    try {
      const { data } = await getGroups();
      setGroups(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onFilterChange = (key, value) => {
    updateFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  const onGearClick = () => {
    navigate('/company-settings', { state: { tabId: 18, subTabName: 'NextAvailability' } });
  };

  useEffect(() => {
    getDriversList();
    getGroupsList();
    getVehicles();
  }, []);

  return (
    <SWrapper>
      <SSearchWrapper>
        <div>
          <Autocomplete
            width='110px'
            options={['All', 'Location']}
            value={filterType}
            onChange={(e, val) => setFilterType(val)}
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FilterIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {filterType === 'All' && <Search search={search} onChange={setSearch} className='search-input' />}
        {filterType === 'Location' && (
          <div>
            <div className='d-flex ms-2 gap-2'>
              <Input
                width='120px'
                name='city'
                placeholder='City'
                onChange={(e) => onFilterChange('city', e.target.value)}
                value={filters.city}
                labelProps={{ className: 'mb-0' }}
              />
              <Input
                width='80px'
                name='state'
                placeholder='State'
                onChange={(e) => onFilterChange('state', e.target.value)}
                value={filters.state}
              />
              <div>
                <InputWithText
                  text='Zip Code'
                  width='80px'
                  name='zipcode'
                  placeholder='Zip'
                  onChange={(e) => onFilterChange('zipcode', e.target.value)}
                  value={filters.zipcode}
                  bgColor={palette.gray0}
                />
              </div>
              <div>
                <InputWithText
                  type='number'
                  text='Miles'
                  width='80px'
                  name='miles'
                  placeholder='Radius'
                  onChange={(e) => onFilterChange('miles', e.target.value)}
                  value={filters.miles}
                  bgColor={palette.gray0}
                />
              </div>
            </div>
          </div>
        )}
      </SSearchWrapper>
      <div>
        <Autocomplete
          width='300px'
          required
          multiple
          limitTags={1}
          placeholder={filters.equipment?.length ? '' : 'Select Equipment..'}
          options={vehicles}
          value={filters.equipment}
          onChange={(e, value) => onFilterChange('equipment', value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) =>
            `${option.equipment_type?.title || ''} ${option?.equipment_id || ''}${
              option.drivers?.length ? ` (${option.drivers[0].fname} ${option.drivers[0].lname})` : ''
            }`
          }
          getTagLabel={(option) =>
            `${option.equipment_type?.title || ''} ${option?.equipment_id || ''}${
              option.drivers?.length ? ` (${option.drivers[0].fname} ${option.drivers[0].lname})` : ''
            }`
          }
        />
      </div>
      <div>
        <Autocomplete
          width='300px'
          multiple
          limitTags={1}
          placeholder={filters.drivers?.length ? '' : 'Select Drivers..'}
          options={drivers}
          value={filters.drivers}
          onChange={(e, value) => onFilterChange('drivers', value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => `${option.fname} ${option.lname}`}
          getTagLabel={(option) => `${option.fname} ${option.lname}`}
        />
      </div>
      <div>
        <Autocomplete
          multiple
          name='group'
          width='320px'
          limitTags={2}
          value={filters.groups}
          options={groups}
          labelKey='group_name'
          placeholder={filters.groups?.length ? '' : 'Select Tags...'}
          onChange={(e, value) => onFilterChange('groups', value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </div>
      <div>
        <div className='position-relative'>
          <SDatePicker
            selectsRange
            renderStart={false}
            shouldCloseOnSelect={false}
            startDate={filters.dateRange[0]}
            endDate={filters.dateRange[1]}
            placeholderText='MM/DD/YYYY - MM/DD/YYYY'
            onChange={(dates) => onFilterChange('dateRange', dates)}
          />
          <DeleteIcon className='delete-icon' onClick={() => onFilterChange('dateRange', [null, null])} />
        </div>
      </div>
      {tab === 2 && (
        <>
          <div className='d-flex align-items-center gap-2'>
            <Input
              type='number'
              width='50px'
              name='radius'
              onChange={(e) => onFilterChange('radius', e.target.value)}
              value={filters.radius}
            />
            <InputLabel className='mb-0'>mile radius</InputLabel>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <Input
              type='number'
              width='50px'
              name='date_span'
              onChange={(e) => onFilterChange('date_span', e.target.value)}
              value={filters.date_span}
            />
            <InputLabel className='mb-0'>day span</InputLabel>
          </div>
        </>
      )}
      <GearIcon width={16} height={16} onClick={onGearClick} className='pointer' />
      <CustomButton
        type='secondary'
        title='Clear'
        styleButton={{ padding: '6px 12px' }}
        styleTitle={{ fontSize: 14, margin: 0 }}
        onClick={() => {
          updateFilters(initialFilters);
          setSearch('');
        }}
      />
    </SWrapper>
  );
};

export default AvailabilityFilters;
