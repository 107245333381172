import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right-2.svg';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${palette.white};
  padding: 6px 12px;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
  min-width: 80px;
  position: relative;
  height: 34px;
  box-shadow: ${palette.boxShadow2};
`;

export const SContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SSearchTitle = styled.span`
  cursor: pointer;
  margin: 0 8px;
  font-family: 'Inter', sans-serif;
  color: ${palette.gray700};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  user-select: none;
`;

export const SArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: rotate(90deg) translateX(-50%);

  & path {
    fill: ${palette.gray700};
  }
`;

export const SFilterOptions = styled.ul`
  padding: 8px 6px;
  margin: 0;
  list-style-type: none;

  li {
    padding: 4px 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    color: ${palette.gray700};

    &.selected {
      background-color: ${palette.indigo500};
      color: ${palette.white};
    }

    &.space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.flex {
      display: flex;
      align-items: flex-start;
      column-gap: 8px;
    }

    &:not(.selected):hover {
      cursor: pointer;
      background-color: ${palette.indigo0};
    }

    &.with-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        margin-left: 12px;

        &.arrow {
          path {
            fill: ${palette.gray700};
          }
        }
      }
    }
  }
`;

export const SInput = styled.input`
  width: 300px;
  height: 34px;
  padding: 5px 12px;
  border: none;
  outline: none;
  box-shadow: ${palette.boxShadow2};
  border-radius: 0 6px 6px 0;
  @media (max-width: 768px) {
    width: 180px;
  }
  @media (max-width: 480px) {
    width: 130px;
  }
`;

export const SAutocomplete = styled(Autocomplete)`
  &&.MuiAutocomplete-root {
    width: 300px;

    .MuiInputBase-root {
      border-radius: 0 6px 6px 0;
      height: 34px;
    }

    @media (max-width: 768px) {
      width: 180px;
    }
    @media (max-width: 480px) {
      width: 130px;
    }
  }
`;

export const SPopover = styled(Popover)`
  .MuiPaper-root {
    width: 120px;
  }
`;
