import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import Search from 'pages/ApplicantsAndReferrals/components/Search';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import ScheduleMaintenance from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/MaintenanceCost/MaintenanceTable/MaintainanceComponents/ScheduleMaintenance';
import { getEquipmentMaintenanceList } from 'Api/Dashboard';
import { SSectionHeader } from 'pages/Dashboard/Dashboard.styles';
import { useColumns } from './EquipmentMaintenace.data';

const EquipmentMaintenance = () => {
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(true);
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [search, setSearch] = useState('');
  const [openScheduleMaintenance, setOpenScheduleMaintenance] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const [selectedFilters, setSelectedFilters] = useState({
    page: 1,
    itemsPerPage: 10,
  });

  const getMaintenance = async () => {
    const { page, itemsPerPage } = selectedFilters || {};

    const params = {
      page,
      itemsPerPage,
      query: debouncedSearch || undefined,
    };

    try {
      setLoading(true);
      const res = await getEquipmentMaintenanceList(params);
      setMaintenanceData(res);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getMaintenance();
  }, [selectedFilters, debouncedSearch]);

  const PaginationComponent = () => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.itemsPerPage}
      totalCount={maintenanceData?.total}
      totalLength={maintenanceData?.data?.length}
      lastPage={maintenanceData?.last_page}
      currentPage={maintenanceData?.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
      perPageOptions={[10, 25, 50, 100, 150]}
    />
  );

  const columns = useColumns({ currency });

  return (
    <div className='d-flex flex-column gap-2'>
      <SSectionHeader style={{ borderBottom: `1px solid ${palette.gray50}`, paddingBottom: '8px' }}>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Equipment Maintenance
        </Typography>
        <div className='d-flex align-items-center gap-2'>
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title='Schedule Maintenance'
            leftIcon={<PlusIcon fill={palette.gray700} style={{ marginRight: '8px' }} />}
            styleButton={{
              padding: '6px 12px',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
            }}
            onClick={() => setOpenScheduleMaintenance(true)}
          />
        </div>
      </SSectionHeader>
      <Search search={search} onChange={setSearch} />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={maintenanceData?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
      {openScheduleMaintenance && (
        <ScheduleMaintenance
          open={openScheduleMaintenance}
          onClose={() => setOpenScheduleMaintenance(false)}
          onSuccess={getMaintenance}
        />
      )}
    </div>
  );
};

export default EquipmentMaintenance;
