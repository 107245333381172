import React from 'react';
import axios from 'axios';
import './reserveEquipment.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { useFormik } from 'formik';
import * as yup from 'yup';

import authHeader from 'services/auth-header';

const ReserveEquipment = ({
  id,
  GetreserveMsgData,
  ReserveEquipmentModalClose,
  toggleShowA,
  toggleCloseA,
  onSuccess = () => null,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { use } = useTheme();

  const validation = yup.object({
    time: yup.number().max(4),
  });

  const formik = useFormik({
    initialValues: {
      action: [],
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const str = values.action.split(' ');
      const duration = str[0];
      const type = str[1];
      const body = { duration, type };
      // GetreserveMsgData([duration,type])

      axios.post(`${API_URL}/equipment-reserve/${id}`, body, { headers: authHeader() }).then((res) => {
        GetreserveMsgData(res.data);
        ReserveEquipmentModalClose();
        toggleShowA(id);
        onSuccess();
        setTimeout(() => {
          toggleCloseA();
        }, 4000);
      });
    },
  });

  const getValue = () => {};

  return (
    <div className='equipment-list-reserve-equipment' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <div className='p-0'>
        <form onSubmit={formik.handleSubmit}>
          <div className='hook-trailer-modal-header'>
            <h6
              className='hook-trailer-modal-heading mt-3 ms-3'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              Reserve Equipment
            </h6>
          </div>
          <div className='hook-trailer-modal-body' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <p className='hook-trailer-modal-text' style={{ color: use(palette.dark800, palette.gray200) }}>
              {' '}
              Reserve duration
            </p>
            <div>
              <div className='d-flex align-items-center  mt-2'>
                <input
                  type='radio'
                  name='action'
                  id='15 minutes'
                  value='15 minutes'
                  onClick={getValue}
                  onChange={formik.handleChange}
                />
                <label
                  htmlFor='15 minutes'
                  className='reserve-equipment-input-label mt-0 ms-2'
                  style={{ color: use(palette.dark800, palette.gray200) }}
                >
                  15 minutes
                </label>
              </div>
              <div className='d-flex align-items-center  mt-2'>
                <input
                  type='radio'
                  name='action'
                  id='30 minutes'
                  value='30 minutes'
                  onClick={getValue}
                  onChange={formik.handleChange}
                />
                <label
                  htmlFor='30 minutes'
                  className='reserve-equipment-input-label mt-0 ms-2'
                  style={{ color: use(palette.dark800, palette.gray200) }}
                >
                  30 minutes
                </label>
              </div>
              <div className='d-flex align-items-center  mt-2'>
                <input
                  type='radio'
                  name='action'
                  id='1 hour'
                  value='1 hour'
                  onClick={getValue}
                  onChange={formik.handleChange}
                />
                <label
                  htmlFor='1 hour'
                  className='reserve-equipment-input-label mt-0 ms-2'
                  style={{ color: use(palette.dark800, palette.gray200) }}
                >
                  1 hour
                </label>
              </div>
            </div>
          </div>

          <div
            className='add-driver-modal-footer d-flex justify-content-end'
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
          >
            <button
              type='button'
              className='hook-trailer-modal-cancel-button'
              onClick={ReserveEquipmentModalClose}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              Cancel
            </button>
            <button type='submit' className='hook-trailer-modal-hook-trailer-button'>
              Reserve Equipment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReserveEquipment;
