import React, { useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import IncludedFeatures from '../IncludedFeatures';
import { SInfoSection } from './InfoSection.styles';
import { SAccordion } from '../IncludedFeatures/IncludedFeatures.styles';

const InfoSection = () => {
  const [expandedQuestions, setExpandedQuestions] = useState(false);

  return (
    <SInfoSection>
      <div>
        <IncludedFeatures />
      </div>
      <Divider color={palette.gray50} margin='32px auto 0' width='600px' />
      <div className='faq mt-4 position-relative'>
        <div className='text-center'>
          <Typography variant='h4' style={{ color: palette.gray900 }}>
            Frequently Asked Questions
          </Typography>
        </div>
        {/* <h1 className='header mobile-faq-header' style={{ color: '#171C26', textAlign: 'center' }} /> */}
        <div className='faq-card-container'>
          <div className='faq-card'>
            <p className='faq-head'>What if I am a broker and carrier?</p>
            <p className='faq-text'>
              We will link your brokerage account to your carrier account so you can switch between the accounts.
            </p>
          </div>
          <div className='faq-card'>
            <p className='faq-head'>How do drivers log in?</p>
            <p className='faq-text'>
              After you have created your account, you must then create your driver profiles. Next, the system will send
              a text message to your driver giving him/her instructions on how to install the app on their smart tablet
              / phone device.
            </p>
          </div>
          <SAccordion
            expanded={expandedQuestions}
            onChange={(e, isExpanded) => setExpandedQuestions(isExpanded)}
            className='outer-accordion'
          >
            <AccordionSummary>
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                Show {expandedQuestions ? 'Less' : 'More'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='faq-card'>
                <p className='faq-head'>IFTA</p>
                <p className='faq-text'>
                  IFTA Reporting: Mileage, Gallons and Diesel Amount Price. We require fuel data input or auto sync with
                  card provider with our connected services. All IFTA Reporting only covers United States, Canada and
                  Mexico. We do not file to the state, we only provide a report.
                </p>
              </div>
              <div className='faq-card'>
                <p className='faq-head'>EDI & API Integrations</p>
                <p className='faq-text'>
                  We provide a list of integrated service providers with the system. You can locate this in our
                  Connected Services page. If your provider is not on the list, you may request it. Fees apply only if
                  expedited service is required.
                </p>
              </div>

              <div className='faq-card'>
                <p className='faq-head'>Connected Services</p>
                <p className='faq-text'>
                  Connected Services (ELD's, Tracking etc.) has variety of Providers which may have separate cost
                  associated to them.
                </p>
              </div>

              <div className='faq-card'>
                <p className='faq-head'>Document Manager</p>
                <p className='faq-text'>
                  Receive Scanned Documents in real-time to your document manager dashboard. Scanned documents can be
                  uploaded by web, manager app or by driver app. There is a storage limit for entire company based on
                  your plan, after a limit is reached you may upgrade your limits at a cost.
                </p>
              </div>

              <div className='faq-card'>
                <p className='faq-head'>12 or 36 Month Licenses</p>
                <p className='faq-text'>
                  For 12 or 36 month licenses, we require up-front payment or credit app for monthly payments for 36
                  months.
                </p>
              </div>

              <div className='faq-card'>
                <p className='faq-head'>4G/5G Data Use</p>
                <p className='faq-text'>
                  Truckin Digital Manager App and Driver Mobile App utilize 4G/5G Data and Truckin Digital is not
                  responsible for data usage cost.
                </p>
              </div>

              <div className='faq-card'>
                <p className='faq-head'>Document Manager</p>
                <p className='faq-text'>
                  Receive Scanned Documents in real-time to your document manager dashboard. Scanned documents can be
                  uploaded by web, manager app or by driver app. There is a storage limit for entire company based on
                  your plan, after a limit is reached you may upgrade your limits at a cost.
                </p>
              </div>

              <div className='faq-card'>
                <p className='faq-head'>Can you import my data from my previous system?</p>
                <p className='faq-text'>
                  We do not import your old data or create any driver, truck, trailer, customer profiles on your behalf.
                </p>
              </div>
            </AccordionDetails>
          </SAccordion>
        </div>
      </div>
      <Divider color={palette.gray50} margin='32px auto 0' width='600px' />
      <div className='disclaimer-container'>
        <div className='disclaimer'>
          <p className='heading'>Disclaimer</p>
          <p className='disclaimer-body '>
            Addons and hardware are sold separately and are optional upon account creation. You may cancel service at
            any time, however it is your option to use monthly, annual or tri-annual payment methods. If billed
            tri-annually, you are pre-paid for 3 years. If billed annually, you are pre-paid for 1 year. If billed
            monthly, you are pre-paid for 1 month and renew on a monthly basis. All license purchases are
            non-refundable. 14 day trial is free of charge. Billed per user (driver and staff).
          </p>
        </div>
      </div>
    </SInfoSection>
  );
};

export default InfoSection;
