import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getCustomerPayTerms } from 'Api/Customers';
import { Typography } from 'components/Typography';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import Input from 'common/Input';
import AddressFields from 'common/AddressFields';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createCarrierPaymentTerms } from 'Api/Carriers';
import { payTermValidationSchema } from 'pages/OnboardCarrier/validationSchemas';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomButton from 'components/CustomButton/CustomButton';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import PaymentMethod from 'components/Carriers/AddCarrier/PaymentMethod';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './PaymentTerms.module.scss';
import { defaultInvoicePayTerm } from '../../../pages/OnboardCarrier/OnboardCarrier.data';

const PaymentTerms = ({ Cancel, nextStep, carrier, company }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { offer_quick_pay, quick_pay_count, quick_pay_type, quick_pay_percent } = company || {};
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState();
  const [loading, setLoading] = useState(false);

  const onSkip = () => {
    nextStep();
  };
  const initialValues = {
    invoicePayTerm: company?.default_invoice_pay_term || defaultInvoicePayTerm,
    payFactoring: false,
    factoringCompany: '',
    email: '',
    address: '',
    address2: '',
    country: null,
    city: null,
    state: null,
    zipcode: '',
    quick_pay: false,
    payment_method: 'check',
    bank_name: '',
    bank_routing: '',
    routing_confirmed: '',
    bank_account: '',
    account_confirmed: '',
    bank_direct_deposit_form: '',
    third_party_name: '',
    third_party_id: '',
  };

  const handleBasicInfoSubmit = (values) => {
    setLoading(true);
    const data = {
      carrier_id: carrier.basicDetails.carrier,
      invoice_pay_term: values.invoicePayTerm?.id,
      pay_factoring: Number(values.payFactoring),
      factoring_company: values.factoringCompany,
      email: values.email,
      address1: values.address,
      address2: values.address2,
      country_id: values.country?.id,
      state_id: values.state?.id,
      city_id: values.city?.id,
      zipcode: values.zipcode,
      quick_pay: Number(values.quick_pay),
      payment_method: values.payment_method,
      bank_name: values.bank_name || '',
      bank_routing: values.bank_routing || '',
      bank_account: values.bank_account || '',
      bank_direct_deposit_form: values.bank_direct_deposit_form || '',
      third_party_name: values.third_party_name || '',
      third_party_id: values.third_party_id || '',
    };
    createCarrierPaymentTerms(data)
      .then((res) => {
        showToaster({ type: 'success', message: 'Payment terms added successfully!' });
        nextStep(res.data);
        setLoading(false);
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
        setLoading(false);
      });
  };

  const form = useForm({
    initialValues,
    onSubmit: handleBasicInfoSubmit,
    validationSchema: payTermValidationSchema,
  });

  const { values, handleChange, touchedErrors, setValues } = form;

  useEffect(() => {
    getCustomerPayTerms().then((res) => {
      if (res && res?.data) {
        const selectData = res.data.map((el) => {
          return {
            ...el,
            key: el.id,
            label: `${el.net_day}  ${el.title}`,
          };
        });
        setInvoicePayTermsOptions(selectData);
      }
    });
  }, []);

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div>
          <div className='d-flex gap-5 align-items-end'>
            <div className='mt-2'>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Invoice Pay Terms <span className='required'>*</span>
              </Typography>
              <Autocomplete
                getOptionLabel={(option) => `${getPaymentTermTitle(option)}`}
                placeholder='Select ...'
                options={invoicePayTermsOptions || []}
                value={values.invoicePayTerm}
                onChange={(e, val) => handleChange('invoicePayTerm', val)}
                isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                style={{ width: '340px', marginTop: 5 }}
                error={touchedErrors?.invoicePayTerm}
              />
            </div>
            <div>
              {!!offer_quick_pay && (
                <CustomCheckbox
                  checked={values.quick_pay}
                  onChange={(e) => handleChange('quick_pay', e)}
                  disabled={values.payFactoring}
                >
                  <Typography variant='s2' style={{ color: palette.gray700, marginLeft: '8px' }}>
                    Enable - {quick_pay_count} {quick_pay_type} Day Quick Pay - {quick_pay_percent}% Fee Deducted Per
                    Invoice
                  </Typography>
                </CustomCheckbox>
              )}
            </div>
          </div>

          <div className={classes.payFactoringWrapper}>
            <CustomCheckbox
              name='pay_driver1'
              type='switch'
              smail={false}
              checked={values.payFactoring}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({ ...prevState, payFactoring: checked, quick_pay: false }));
                } else {
                  handleChange(`payFactoring`, checked);
                }
              }}
            />
            <Typography variant='s2'>Pay Factoring</Typography>
          </div>

          {values.payFactoring && (
            <div className={classes.payFactoringFieldsWrapper}>
              <div className={classes.contactWrapper}>
                <div>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Factoring Company <span className='required'>*</span>
                  </Typography>
                  <Input
                    name='origin_city'
                    style={{ width: '340px', marginTop: 5 }}
                    placeholder='Factoring Company'
                    onChange={(e) => handleChange(`factoringCompany`, e.target.value)}
                    value={values.factoringCompany}
                    error={touchedErrors.factoringCompany}
                  />
                </div>
                <div>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Factor Email <span className='required'>*</span>
                  </Typography>
                  <Input
                    name='email'
                    style={{ width: '340px', marginTop: 5 }}
                    placeholder='example@email.com'
                    onChange={(e) => handleChange(`email`, e.target.value)}
                    value={values.email}
                    error={touchedErrors.email}
                  />
                </div>
              </div>
              <div className='w-75 mt-4'>
                <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
              </div>
            </div>
          )}

          <div className='mt-5'>
            <Typography variant='s1' style={{ color: palette.gray900 }}>
              Payout Method
            </Typography>
            <PaymentMethod form={form} carrierId={carrier.basicDetails.carrier} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button className='cancel-button' onClick={onSkip}>
              Skip
            </Button>
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Next Step'
              onClick={form.handleSubmit}
              disabled={loading}
              leftIcon={
                loading ? (
                  <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
      </Modal.Footer>
    </Form>
  );
};

export default PaymentTerms;
