import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import ReadyMissingPaperworkInvoicesChart from '../Charts/ReadyMissingPaperworkInvoicesChart';
import { SBoxWrapper } from '../../StatsSection.styles';

const ReadyMissingPaperworkInvoices = ({ readyAndMissing, onTitleClick, loading }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <span
          className='d-inline-flex align-items-center gap-1 pointer'
          onClick={() => onTitleClick('ready_to_invoice')}
        >
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            READY TO INVOICE
          </Typography>
          <ArrowUp fill={palette.gray400} style={{ transform: 'rotate(90deg)', marginRight: '4px' }} />
        </span>
        <span
          className='d-inline-flex align-items-center gap-1 pointer'
          onClick={() => onTitleClick('missing_paperwork')}
        >
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            MISSING PAPERWORK
          </Typography>
          <ArrowUp fill={palette.gray400} style={{ transform: 'rotate(90deg)', marginRight: '8px' }} />
        </span>
      </div>
      <div className='d-flex justify-content-between'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <div className='d-flex align-items-center gap-1'>
            <Typography variant='h5' style={{ color: palette.indigo500 }}>
              {currency}
              {formatNumber(readyAndMissing?.readyToInvoiceAmount)}
            </Typography>
            <Chip
              label={readyAndMissing?.readyToInvoiceCount}
              bgColor={palette.indigo0}
              labelColor={palette.indigo500}
            />
          </div>
        )}
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <div className='d-flex align-items-center gap-1 me-2'>
            <Typography variant='h5' style={{ color: palette.red500 }}>
              {currency}
              {formatNumber(readyAndMissing?.missingPaperworkAmount)}
            </Typography>
            <Chip label={readyAndMissing?.missingPaperworkCount} bgColor={palette.red0} labelColor={palette.red500} />
          </div>
        )}
      </div>
      <Divider color={palette.white} />
      <div className='chart-wrapper'>
        <ReadyMissingPaperworkInvoicesChart monthsData={readyAndMissing?.monthsData} />
      </div>
    </SBoxWrapper>
  );
};

export default ReadyMissingPaperworkInvoices;
