import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import './CustomMultiSelect.css';
import styled from 'styled-components';
import { palette } from 'utils/constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;

    .MuiSvgIcon-root {
      fill: ${palette.indigo500};
      width: 22px;
      height: 22px;
    }
  }
`;

const CustomMultiSelect = ({
  placeholder = '',
  selectedValues = [],
  patchProperty = 'id',
  propertyName = 'name',
  options = [],
  formik = {},
  isUseForm = false,
  handleFieldChange = () => null,
}) => {
  const [values, setValues] = React.useState([]);

  React.useEffect(() => {
    setValues([...selectedValues.map((val) => val?.id)]);
  }, [selectedValues]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (Array.isArray(value)) {
      if (isUseForm) {
        handleFieldChange(value.map((id) => options.find((option) => option.id === id)));
      } else {
        formik.setFieldValue(
          patchProperty,
          value.map((id) => options.find((option) => option.id === id))
        );
      }
    }
    setValues(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
        <Select
          multiple
          value={values}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!options?.length) {
              return '';
            }
            return !selected?.length
              ? 'Select tags'
              : selected.map((id) => options?.find((option) => option?.id === id)[propertyName])?.join(', ');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {placeholder && (
            <MenuItem disabled value=''>
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options?.map((option) => (
            <MenuItem key={option?.id} value={option?.id}>
              <SCheckbox checked={values?.indexOf(option?.id) > -1} />
              <ListItemText primary={option[propertyName]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default CustomMultiSelect;
