import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { palette, PERMISSIONS } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { MenuIcons } from './menuIcons';

const LeftMenuFooter = ({ setActiveId, menuStyleLocal, openInNewTab }) => {
  const { use, theme } = useTheme();
  const { permissions } = useSelector((state) => state?.root);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { SettingsIcon, CloudIcon } = MenuIcons;
  const footerButtons = useMemo(() => {
    return [
      {
        id: 14,
        title: 'Settings',
        Icon: SettingsIcon,
        path: '/company-settings',
        menuPermissions: [PERMISSIONS.SETTINGS.name],
      },
      {
        id: 15,
        title: 'Connected Services',
        Icon: CloudIcon,
        path: '/connectedservicesediapiintegrations',
        menuPermissions: [PERMISSIONS.CONNECTED_SERVICES.name],
      },
    ];
  }, []);

  const renderTooltip = (title) => {
    if (theme.condensed) {
      return <Tooltip>{title}</Tooltip>;
    }
    return <span />;
  };

  const getActivePath = (item) => {
    if (item.path) {
      return pathname.startsWith(item.path);
    }
    if (item.collapse?.subItems) {
      const activeSubItem = item.collapse.subItems.find((subItem) => subItem.path && pathname.startsWith(subItem.path));
      return activeSubItem ? pathname.startsWith(activeSubItem.path) : '';
    }
    return '';
  };

  return (
    <div style={{ padding: '0 8px', cursor: 'pointer' }}>
      {footerButtons.map((el) => {
        const { id, title, Icon, path, menuPermissions } = el;

        const hasPermission = menuPermissions?.some((permission) => permissions.includes(permission));
        if (!hasPermission) {
          return null;
        }

        return (
          <OverlayTrigger key={id} placement='right' delay={{ show: 250, hide: 400 }} overlay={renderTooltip(title)}>
            <div style={{ display: 'flex', justifyContent: menuStyleLocal ? 'center' : 'flex-start', marginBottom: 8 }}>
              <NavLink
                to={path}
                className={`icon_wrapper settingsBlock ${use('light', 'dark', 'primary')}`}
                style={{
                  width: !theme.condensed ? '100%' : 'auto',
                  backgroundColor: 'initial',
                  color: 'initial',
                  textDecoration: 'none',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveId(id);
                  openInNewTab ? window.open(el.path) : navigate(path);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div className='icon-container'>
                    <Icon
                      width={19}
                      height={19}
                      fill={
                        getActivePath(el)
                          ? use(palette.indigo500, palette.indigo400, palette.white)
                          : use(palette.gray500, palette.gray400, palette.indigo200)
                      }
                    />
                  </div>
                  <p
                    className='button-text'
                    style={{
                      display: menuStyleLocal ? 'none' : 'block',
                      color: getActivePath(el)
                        ? use(palette.indigo500, palette.indigo400, palette.white)
                        : use(palette.gray500, palette.gray200, palette.indigo200),
                    }}
                  >
                    {title}
                  </p>
                </div>
              </NavLink>
            </div>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

export default LeftMenuFooter;
