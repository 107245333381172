import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { formatNumber } from 'utils/constants';
import { numberToWords } from '../../../constants';
import classes from './ChecksToPrintTemplate.module.scss';

const ChecksToPrintTemplate = ({
  checkStyleVoucher,
  useCompanyLogo,
  useSignature,
  useBankLogo,
  checkNumber,
  memoValue,
  company,
  check,
  bank,
  amount,
  signatureUrl,
}) => {
  return (
    <div className={classNames(classes.pdfCard, { [classes.voucher]: checkStyleVoucher })}>
      <div className={classes.pdfHeader}>
        <div className={classes.pdfHeaderInfo}>
          {useCompanyLogo && (
            <img crossOrigin='anonymous' src={`${company?.logo_path}?cache=${Date.now().toString()}`} alt='logo' />
          )}
          <div className={classes.pdfHeaderInfoTexts}>
            <span>{company?.company_name}</span>
            <span>{company?.address1}</span>
            <span>{company?.address2}</span>
            <span>
              {company?.city?.name && `${company?.city?.name},`}{' '}
              {company?.state?.short_name && `${company?.state?.short_name}`} {company?.zip && `${company?.zip}`}
            </span>
          </div>
        </div>
        <div className={classes.pdfHeaderInfo}>
          {useBankLogo && (
            <img crossOrigin='anonymous' src={`${bank?.account_logo}?cache=${Date.now().toString()}`} alt='logo' />
          )}
          <div className={classes.pdfHeaderInfoTexts}>
            <span>{bank?.account_bank_name}</span>
            <span>{bank?.address1}</span>
            <span>{bank?.address2}</span>
            <span>
              {bank?.city?.name && `${bank?.city?.name},`} {bank?.state?.short_name && `${bank?.state?.short_name}`}{' '}
              {bank?.zipcode && `${bank?.zipcode}`}
            </span>
          </div>
        </div>
        <div className={classes.pdfHeaderDate}>
          <span className={classes.pdfCheckNumber}>{checkNumber}</span>
          <span>
            DATE <b>{moment().format('MM/DD/YYYY')}</b>
          </span>
        </div>
      </div>

      <div className={classes.pdfSection}>
        <div className={classes.pdfOrderOfContainer}>
          <span className={classes.pdfOrderOfTitle}>
            PATH TO THE <br /> ORDER OF
          </span>
          <span className={classes.pdfOrderOfName}>{check?.name}</span>
          <span className={classes.pdfOrderOfDollar}>$</span>
          {amount ? (
            <span className={classes.pdfOrderOfPrice}>{amount && formatNumber(+amount)}</span>
          ) : (
            <span className={classes.pdfOrderOfPrice}>{check?.amount && formatNumber(+check.amount)}</span>
          )}
        </div>
        <div className={classes.pdfDollars}>
          {amount ? (
            <span>{amount && numberToWords(Number(amount).toFixed(2))}</span>
          ) : (
            <span>{check?.amount && numberToWords(Number(check.amount).toFixed(2))}</span>
          )}
          <span>{'*'.repeat(200)}</span>
          <span>DOLLARS</span>
        </div>
        <div className={classes.pdfUserAddress}>
          <span>{check?.name}</span>
          <span>{check?.address}</span>
          <span>{check?.address2}</span>
          <span>
            {check?.city?.name && `${check?.city?.name},`} {check?.state?.short_name && `${check?.state?.short_name}`}{' '}
            {check?.zipcode && `${check?.zipcode}`}
          </span>
        </div>
      </div>

      <div className={classes.pdfFooter}>
        <div className={classes.pdfFooterTitleWrapper}>
          <div>
            <span>MEMO</span>
            <span style={{ marginTop: memoValue ? 0 : '12px' }}>{memoValue}</span>
          </div>
          <div className={classes.signature}>
            {useSignature && !!signatureUrl && (
              <img
                crossOrigin='anonymous'
                src={signatureUrl?.includes('blob:') ? signatureUrl : `${signatureUrl}?cache=${Date.now().toString()}`}
                alt='signature'
              />
            )}
          </div>
        </div>

        <div className={classes.pdfFooterCode}>
          <div className={classes.pdfFooterCodeLeft}>
            <span>a{bank?.account_routing}a</span>
          </div>
          <div className={classes.pdfFooterCodeRight}>
            <span>{bank?.account_number}c</span>
            <span>{checkNumber}</span>
          </div>
        </div>

        {checkStyleVoucher &&
          Array(2)
            .fill(0)
            .map((el, i) => {
              return (
                <div key={`${el + i}`} className={classNames(classes.dataForVoucher, { [classes.second]: i === 1 })}>
                  <div className={classes.dataForVoucherTop}>
                    <span>{company?.company_name}</span>
                    <span>{checkNumber}</span>
                  </div>
                  <div className={classes.dataForVoucherCenter}>
                    {i === 0 && <span>{moment().format('MM/DD/YYYY')}</span>}
                  </div>
                  <div className={classes.dataForVoucherBottom}>
                    <span>{check?.name}</span>
                    <span>{memoValue}</span>
                    {amount ? <span>${formatNumber(+amount)}</span> : <span>${formatNumber(+check.amount)}</span>}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default ChecksToPrintTemplate;
