import { autoAwardTimeOptions, timeOptions } from 'componentsV2/Planner/OfferOptions/OfferOptions.data';
import { removeCommas } from 'utils/helpers';

export const planAheadActions = [
  { id: 1, name: 'Create to Planner' },
  { id: 2, name: 'Auto Dispatch to Driver' },
  { id: 3, name: 'Auto Dispatch to Carrier' },
  { id: 4, name: 'Auto Offer' },
];

export const driverStatuses = [
  { id: 1, name: 'Available' },
  { id: 2, name: 'Next Available' },
];

export const getInitialValues = (data, offerSettings) => {
  const { plan_ahead_type, dispatch_options, offer_options } = data || {};

  const {
    driver,
    carrier,
    location,
    carrier_contact_user,
    location_lat,
    location_lng,
    flat_rate,
    immediately,
    next_in_line,
    start_from_type,
    before_scheduled_pickup,
    before_scheduled_pickup_hours,
    recipient_customer_contact_book,
    send_pickup_reminder_to_driver,
    send_pickup_reminder_to_driver_before_minutes,
  } = dispatch_options || {};

  const {
    expire_shipment_offer,
    expire_shipment_offer_unit,
    auto_repost,
    retract_carrier_award,
    retract_carrier_award_unit,
    auto_award_first_lowest_amount,
    auto_award_first_lowest_amount_unit,
    auto_dispatch,
    require_minimum_margin,
    require_minimum_margin_percent,
    offer_amount,
    note,
    radius,
    available_drivers,
    next_available_drivers,
    next_available_prior_to_pickup,
  } = offer_options || {};

  const {
    expire_shipment_offer: settings_expire_shipment_offer,
    expire_shipment_offer_unit: settings_expire_shipment_offer_unit,
    auto_repost: settings_auto_repost,
    retract_carrier_award: settings_retract_carrier_award,
    retract_carrier_award_unit: settings_retract_carrier_award_unit,
    auto_award_first_lowest_amount: settings_auto_award_first_lowest_amount,
    auto_award_first_lowest_amount_unit: settings_auto_award_first_lowest_amount_unit,
    auto_dispatch: settings_auto_dispatch,
    require_minimum_margin: settings_require_minimum_margin,
    require_minimum_margin_percent: settings_require_minimum_margin_percent,
  } = offerSettings || {};

  const driver_status = [];

  if (available_drivers) {
    driver_status.push(driverStatuses[0]);
  }

  if (next_available_drivers) {
    driver_status.push(driverStatuses[1]);
  }

  return {
    plan_ahead_type: planAheadActions.find((i) => i.id === plan_ahead_type) || planAheadActions[0],

    driver: driver || null,
    carrier: carrier || null,
    carrier_contact_user: carrier_contact_user || null,
    location: location || '',
    location_lat: location_lat || null,
    location_lng: location_lng || null,
    flat_rate: flat_rate || '',
    immediately: !!immediately,
    next_in_line: !!next_in_line,
    before_scheduled_pickup: !!before_scheduled_pickup,
    before_scheduled_pickup_hours: before_scheduled_pickup_hours || '',
    send_trucking_link_to_customer: !!recipient_customer_contact_book,
    recipient_customer_contact_book: recipient_customer_contact_book || null,
    send_pickup_reminder_to_driver: !!send_pickup_reminder_to_driver,
    send_pickup_reminder_to_driver_before_minutes: send_pickup_reminder_to_driver_before_minutes || '',

    radius: radius || (offer_options ? '' : '50'),
    start_from_type: Number(start_from_type) || 1,
    driver_status,
    next_available_prior_to_pickup: next_available_prior_to_pickup || '',
    drivers: [],
    carriers: [],
    offer_amount: offer_amount || '',
    note: note || '',
    expire_shipment_offer: expire_shipment_offer || settings_expire_shipment_offer || '1',
    expire_shipment_offer_unit:
      timeOptions.find((i) => i.value === (expire_shipment_offer_unit || settings_expire_shipment_offer_unit)) ||
      timeOptions[2],
    auto_repost: !!auto_repost || !!settings_auto_repost || false,
    retract_carrier_award: retract_carrier_award || settings_retract_carrier_award || '30',
    retract_carrier_award_unit:
      timeOptions.find((i) => i.value === (retract_carrier_award_unit || settings_retract_carrier_award_unit)) ||
      timeOptions[0],
    auto_award_first_lowest_amount: auto_award_first_lowest_amount || settings_auto_award_first_lowest_amount || '',
    auto_award_first_lowest_amount_unit:
      autoAwardTimeOptions.find(
        (i) => i.value === (auto_award_first_lowest_amount_unit || settings_auto_award_first_lowest_amount_unit)
      ) || autoAwardTimeOptions[3],
    auto_dispatch: !!auto_dispatch || !!settings_auto_dispatch || false,
    require_minimum_margin: !auto_award_first_lowest_amount_unit
      ? false
      : !!require_minimum_margin || !!settings_require_minimum_margin || false,
    require_minimum_margin_percent: require_minimum_margin_percent || settings_require_minimum_margin_percent || '',
  };
};

export const planAheadDataConverter = (values) => {
  const {
    plan_ahead_type,
    driver,
    carrier,
    carrier_contact_user,
    location,
    location_lat,
    location_lng,
    flat_rate,
    immediately,
    next_in_line,
    before_scheduled_pickup,
    before_scheduled_pickup_hours,
    recipient_customer_contact_book,
    send_pickup_reminder_to_driver,
    send_pickup_reminder_to_driver_before_minutes,
    drivers,
    carriers,
    start_from_type,
    radius,
    driver_status,
    next_available_prior_to_pickup,
    offer_amount,
    note,
    expire_shipment_offer,
    auto_repost,
    expire_shipment_offer_unit,
    retract_carrier_award,
    retract_carrier_award_unit,
    auto_award_first_lowest_amount,
    auto_award_first_lowest_amount_unit,
    auto_dispatch,
    require_minimum_margin,
    require_minimum_margin_percent,
  } = values || {};

  return {
    plan_ahead_type: plan_ahead_type.id,

    driver_id: driver?.id || null,
    carrier_id: carrier?.id || null,
    carrier_contact_user_id: carrier_contact_user?.id || null,
    location: location || null,
    location_lat: location_lat || null,
    location_lng: location_lng || null,
    flat_rate: flat_rate ? Number(removeCommas(flat_rate)) : null,
    immediately: Number(immediately),
    next_in_line: Number(next_in_line),
    before_scheduled_pickup: Number(before_scheduled_pickup),
    before_scheduled_pickup_hours: before_scheduled_pickup_hours || null,
    recipient_customer_contact_book_id: recipient_customer_contact_book?.id || null,
    send_pickup_reminder_to_driver: Number(send_pickup_reminder_to_driver),
    send_pickup_reminder_to_driver_before_minutes: send_pickup_reminder_to_driver_before_minutes || 0,

    driver_ids: drivers?.map((i) => i.id),
    carrier_ids: carriers?.map((i) => i.id),
    start_from_type,
    radius: radius || null,
    available_drivers: driver_status?.some((i) => i.id === 1) ? 1 : 0,
    next_available_drivers: driver_status?.some((i) => i.id === 2) ? 1 : 0,
    next_available_prior_to_pickup: next_available_prior_to_pickup || null,
    offer_amount: offer_amount ? Number(removeCommas(offer_amount)) : null,
    note: note || null,
    expire_shipment_offer: expire_shipment_offer || null,
    expire_shipment_offer_unit: expire_shipment_offer_unit?.value || null,
    auto_repost,
    retract_carrier_award: retract_carrier_award || null,
    retract_carrier_award_unit: retract_carrier_award_unit?.value || null,
    auto_award_first_lowest_amount: auto_award_first_lowest_amount || null,
    auto_award_first_lowest_amount_unit: auto_award_first_lowest_amount_unit?.value || null,
    auto_dispatch,
    require_minimum_margin,
    require_minimum_margin_percent,
  };
};
