import styled from 'styled-components';
import CustomModal from 'components/CustomModal/CustomModal';
import { palette } from 'utils/constants';

export const SCustomModal = styled(CustomModal)`
  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '700px'};
    max-width: ${({ $maxWidth }) => $maxWidth || ''};
    height: ${({ $height }) => $height || 'auto'};

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: 100%;
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      overflow: auto;
      border-radius: 0;
      border-bottom: 1px solid ${palette.gray50};

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      padding: 16px 20px 0;
    }
  }
`;
