import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getDriverStatus } from 'Api/AccidentsAndCrashes';
import CompanyVsOwnerChart from '../Charts/CompanyVsOwnerChart';
import { SBoxWrapper } from '../../StatsSection.styles';

export const DriverStatus = ({ statsRefreshIndex }) => {
  const [driverStatus, setDriverStatus] = useState({});

  const getCounts = async () => {
    try {
      const params = {};
      const { data } = await getDriverStatus(params);
      setDriverStatus(data.driver_status);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCounts();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex flex-column gap-1'>
        <Typography variant='s2'>OWNER OPERATOR VS COMPANY</Typography>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          DRIVER TYPE POOL
        </Typography>
      </div>
      <div className='chart-wrapper'>
        <CompanyVsOwnerChart companyVsOwner={driverStatus} />
      </div>
    </SBoxWrapper>
  );
};
