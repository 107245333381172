import axios from '../services/axios';

export async function validateEquipment(body) {
  const { data } = await axios.post('equipment-import/create-validation', body);
  return data;
}

export async function createEquipments(body) {
  const { data } = await axios.post('equipment-import/create', body);
  return data;
}

export async function getVehicles() {
  const { data } = await axios.get('vehicles');
  return data;
}

export async function getStates(params) {
  return axios.get(`search-states`, { params });
}

export async function getTrailers() {
  const { data } = await axios.get('trailers');
  return data;
}

export async function getEquipmentDimensions(params) {
  const { data } = await axios.get('equipment-dimensions', { params });
  return data;
}

export async function connectEquipmentService(body) {
  const { data } = await axios.post('connected-services/equipment', body);
  return data;
}

export async function deleteConnectedServices(id, params) {
  const { data } = await axios.delete(`connected-services/vehicle/${id}`, { params });
  return data;
}

export async function deleteConnectedServicesTrailer(id, params) {
  const { data } = await axios.delete(`connected-services/trailer/${id}`, { params });
  return data;
}

export async function combineSamsaraAndCsv(body) {
  const { data } = await axios.post('equipment-import/samsara-csv', body);
  return data;
}

export async function importSamsaraEquipment(body) {
  const { data } = await axios.post('equipment-import/samsara', body);
  return data;
}

export async function getExportCSV(params, cancelToken) {
  const { data } = await axios.get('export-csv', { params, cancelToken });
  return data;
}
