import { personTakingReport } from 'pages/AccidentsAndCrashes/AddAccidents/AddAccidents.data';

export const getInitialValues = ({ user, selectedDriver }) => {
  return {
    report_date_time: new Date(),
    person_taking_report_type: personTakingReport[1],
    person_taking_report: user,
    driver: selectedDriver || null,
    driver_injured: null,
    injury_type: '',
    treating_facility: '',
    injury_worker_comp_recordable: null,
    treating_facility_telephone: '',
    injury_osha_reportable: null,
  };
};
