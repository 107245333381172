import React from 'react';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import PayTypeChip from '../../../shared/PayTypeChip';
import { SWrapper } from './PayOption.styles';

const payOption = {
  mile: 'Per Mile',
  percentage: 'By Percentage',
  per_hour: 'Per Hour',
  flat_rate: 'Flat Rate',
};

const PayOption = ({ data }) => {
  const { use } = useTheme();

  if (!data) {
    return null;
  }

  return (
    <SWrapper>
      <PayTypeChip data={data} />
      <Typography variant='c1' style={{ color: use(palette.gray700, palette.gray200) }}>
        {payOption[data.pay_option]}
      </Typography>
    </SWrapper>
  );
};

export default PayOption;
