import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'common/Modal';
import Loader from 'common/Loader';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import classes from 'components/ShipmentHistory/components/PlannedLoads/PlannedLoadsTable/plannedLoadsTable.module.css';
import { palette } from 'utils/constants';
import { getSplits } from 'Api/Shipment';
import { useColumns } from 'componentsV2/Shipment/ShipmentSplits/ShipmentSplits.data';

const ShipmentSplits = ({ open, onClose, shipment }) => {
  const { shipment_id } = shipment || {};
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState([]);
  const navigate = useNavigate();

  const getShipments = async () => {
    try {
      setLoading(true);
      const { data } = await getSplits({ shipment_id });
      setShipments(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getShipments();
  }, []);

  const columns = useColumns({ onClose });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Split Shipments for ${shipment_id?.split('-')[0]}`}
      $bgColor={palette.gray0}
      $maxWidth='1000px'
      $minWidth='1000px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done' }]}
    >
      {loading ? (
        <Loader loading />
      ) : (
        <div className={classes.container}>
          <div style={{ margin: 1 }}>
            <MaterialTableWrapper
              data={shipments}
              rowPerPage={100}
              onRowClick={(e, rowData) => {
                navigate(`/shipment/${rowData?.shipment_id}`);
                onClose();
              }}
              style={{ backgroundColor: palette.white }}
              columns={columns}
              options={{
                paging: false,
                sorting: false,
                toolbar: false,
                title: false,
                rowStyle: {
                  borderBottom: 'none',
                  width: '100%',
                },
                headerStyle: {
                  borderTop: 'none',
                  width: '100%',
                },
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ShipmentSplits;
