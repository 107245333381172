import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  max-width: 600px;

  .invoices-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 4px;
    margin: 52px 0 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${palette.gray50};

    .invoice-wrapper {
      cursor: pointer;
      padding: 3px 8px;
      border-radius: 4px;
      transition: background-color 0.2s;

      :hover {
        background-color: ${palette.indigo0};
      }
    }
  }

  .print-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    transition: 0.4s;
    padding: 4px;
  }

  .print-wrapper:hover {
    background-color: ${palette.indigo200};
  }

  .invoice-info-footer {
    display: flex;
    align-items: center;
    column-gap: 4px;
    margin-top: 124px;

    a {
      color: ${palette.indigo500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }

  .try-again {
    font-size: 14px;
    color: ${palette.indigo500};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  .MuiChip-root .MuiChip-label {
    line-height: 24px;
  }
`;
