import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { SCustomModal } from 'pages/ApplicantsAndReferrals/ApplicantsAndReferrals.styles';
import Header from './Header';

const PaystubModal = ({ pdfUrl, settlement, updateSettlementStatus, onClose, loading }) => {
  return (
    <SCustomModal
      showModal={!!pdfUrl}
      onHide={onClose}
      headerTitle={<Header settlement={settlement} />}
      $minWidth='80vw'
      $height='auto'
      $bodyMaxHeight='calc(100vh - 184px)'
      $footerJustify='space-between'
      buttons={[
        {
          key: 'revert',
          type: 'secondary',
          title: 'Revert & Edit',
          onClick: updateSettlementStatus,
          className: 'modal-cancel-btn',
          disabled: loading,
        },
        {
          key: 'submit',
          type: 'secondary',
          title: 'Done',
          className: 'modal-submit-btn',
          onClick: onClose,
        },
      ]}
    >
      <CloseIcon className='close-modal-icon' onClick={onClose} />
      <div>
        <iframe
          src={pdfUrl}
          title='testPdf'
          id='pdf-iframe'
          height='458px'
          width='100%'
          style={{ height: 'calc(100vh - 224px)' }}
        />
      </div>
    </SCustomModal>
  );
};

export default PaystubModal;
