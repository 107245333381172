import React, { useEffect, useState } from 'react';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import { getAccountTypes } from 'Api/Accounts';
import { allAccountOption } from 'pages/Accounting/Accounts/Accounts.data';
import { SWrapper } from './AccountsFilter.styles';

const AccountsFilter = ({ filters, onFilterChange }) => {
  const [typeOptions, setTypeOptions] = useState([]);

  const getAccountTypeOptions = async () => {
    try {
      const { data } = await getAccountTypes();
      setTypeOptions([allAccountOption, ...data]);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getAccountTypeOptions();
  }, []);

  return (
    <SWrapper>
      <Autocomplete
        name='account_type'
        labelKey='account_type'
        placeholder='Select Account Type'
        options={typeOptions}
        value={filters.account_type}
        onChange={(e, val) => onFilterChange('account_type', val)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableClearable={false}
      />
      <Search search={filters.search} onChange={(val) => onFilterChange('search', val)} className='accounts-search' />
    </SWrapper>
  );
};

export default AccountsFilter;
