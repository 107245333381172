import React from 'react';
import { AgeGroup } from './components/AgeGroup';
import { SWrapper } from './StatsSection.styles';
import DayOfWeek from './components/DayOfWeekChart';
import { MonthOfCollision } from './components/MonthOfCollision';
import TypeOfCollision from './components/TypeOfCollision';
import { WeatherFactors } from './components/WeatherFactors';
import TimeOfDay from './components/TimeOfDay';
import SpeedLimit from './components/SpeedLimit';
import { LengthOfEmployment } from './components/LengthOfEmployment';
import { ControlDevice } from './components/ControlDevice';
import { DriverExperience } from './components/DriverExperience';
import { DOTRecordable } from './components/DOTRecordable';
import { DriverStatus } from './components/DriverStatus';

const StatsSection = ({ statsRefreshIndex }) => {
  return (
    <SWrapper>
      <div className='scrollable-container'>
        <div className='chart-boxes-wrapper'>
          <DayOfWeek statsRefreshIndex={statsRefreshIndex} />
          <DOTRecordable statsRefreshIndex={statsRefreshIndex} />
          <DriverStatus statsRefreshIndex={statsRefreshIndex} />
          <MonthOfCollision statsRefreshIndex={statsRefreshIndex} />
        </div>
        <div className='chart-boxes-wrapper'>
          <SpeedLimit statsRefreshIndex={statsRefreshIndex} />
          <ControlDevice statsRefreshIndex={statsRefreshIndex} />
          <LengthOfEmployment statsRefreshIndex={statsRefreshIndex} />
          <WeatherFactors statsRefreshIndex={statsRefreshIndex} />
        </div>
        <div className='chart-boxes-wrapper'>
          <TypeOfCollision statsRefreshIndex={statsRefreshIndex} />
          <DriverExperience statsRefreshIndex={statsRefreshIndex} />
          <AgeGroup statsRefreshIndex={statsRefreshIndex} />
          <TimeOfDay statsRefreshIndex={statsRefreshIndex} />
        </div>
      </div>
    </SWrapper>
  );
};

export default StatsSection;
