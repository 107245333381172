import React, { useState } from 'react';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Input from 'common/Input';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { deleteEquipmentDocument, uploadEquipmentDocument } from 'Api/Equipment';
import classNames from 'classnames';
import { getSettingsData, hasNoSettings } from './DocumentsList.data';
import { SActions, SMergedInputs } from './DocumentsList.styles';

const FormRow = ({
  document,
  index,
  form,
  readOnly,
  documentTypes,
  insuranceSettings,
  equipmentId,
  onDeleteSuccess = () => null,
  isUpdate,
  documentsToDelete,
  setDocumentsToDelete,
}) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const shouldBeDeleted = isUpdate && !document.isNew && documentsToDelete?.includes(document.id);

  const { values, setValues, handleChange, handleBlur, touchedErrors } = form;
  const unusedDocumentTypes = documentTypes.filter(
    (i) => i.id === 10 || values.documents.every((item) => item.equipment_document_type?.id !== i.id)
  );

  const onDocumentUpload = async (file) => {
    try {
      const formData = new FormData();
      if (document.equipment_document_type) {
        formData.append('equipment_document_type_id', document.equipment_document_type.id);
      }

      if (equipmentId) {
        formData.append('equipment_id', equipmentId);
      }
      formData.append('document', file);
      const { data } = await uploadEquipmentDocument(formData);
      handleChange(`documents[${index}].document`, data.document_path);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onDelete = async (row) => {
    if (isUpdate) {
      setDocumentsToDelete((prevState) => [...prevState, row.id]);
      return;
    }

    setLoading(true);
    try {
      await deleteEquipmentDocument(row.id);
      showToaster({ type: 'success', message: 'Document has been successfully deleted!' });
      setValues((prevState) => ({ documents: prevState.documents.filter((item) => item.id !== row.id) }));
      onDeleteSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteRow = async (row) => {
    if (row.isNew) {
      setValues((prevState) => ({ documents: prevState.documents.filter((item) => item.id !== row.id) }));
    } else {
      onDelete(row);
    }
  };

  const onRevert = async (row) => {
    setDocumentsToDelete((prevState) => prevState.filter((i) => i !== row.id));
  };

  const onUseFromSettings = async (row, checked) => {
    handleChange(`documents[${index}].use_from_policy`, checked);

    if (!checked) {
      return;
    }

    setValues((prevState) => ({
      documents: prevState.documents.map((item) =>
        item.id !== row.id
          ? item
          : { ...item, ...getSettingsData(insuranceSettings, row.equipment_document_type.id, checked) }
      ),
    }));
  };

  return (
    <tr className={classNames('body-row', { 'row-to-delete': shouldBeDeleted })}>
      <td style={{ width: '720px' }}>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {document.type || '-'}
          </Typography>
        ) : (
          <div className='d-flex align-items-center gap-3'>
            <SMergedInputs>
              <div>
                <Autocomplete
                  width={document.equipment_document_type?.id === 10 ? '100px' : '200px'}
                  placeholder='Select Document Type'
                  options={unusedDocumentTypes}
                  value={document.equipment_document_type}
                  onChange={(e, val) => handleChange(`documents[${index}].equipment_document_type`, val)}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  error={touchedErrors?.documents?.[index]?.equipment_document_type}
                  disabled={
                    shouldBeDeleted ||
                    ((document.use_from_policy || !document.isNew) && !!document.equipment_document_type)
                  }
                />
              </div>
              {document.equipment_document_type?.id === 10 && (
                <div>
                  <Input
                    width='150px'
                    placeholder='Document Name'
                    name={`documents[${index}].document_name`}
                    value={document.document_name}
                    onChange={handleChange}
                    error={touchedErrors?.documents?.[index]?.document_name}
                    disabled={shouldBeDeleted || document.use_from_policy}
                  />
                </div>
              )}
              <div>
                <Input
                  width='150px'
                  placeholder='Reference ID'
                  name={`documents[${index}].reference_id`}
                  value={document.reference_id}
                  onChange={handleChange}
                  error={touchedErrors?.documents?.[index]?.reference_id}
                  disabled={shouldBeDeleted || document.use_from_policy}
                  classNameInput={document.equipment_document_type?.id === 8 ? 'last-input' : ''}
                />
              </div>
              {document.equipment_document_type?.id !== 8 && (
                <div>
                  <DatePicker
                    width='150px'
                    borderRadius='0 6px 6px 0'
                    name={`documents[${index}].document_exp_date`}
                    value={document.document_exp_date}
                    onBlur={handleBlur}
                    onChange={(val) => handleChange(`documents[${index}].document_exp_date`, val)}
                    disabled={shouldBeDeleted || !!document.no_expire || document.use_from_policy}
                    disablePast
                    error={touchedErrors?.documents?.[index]?.document_exp_date}
                  />
                </div>
              )}
            </SMergedInputs>
            {document.equipment_document_type?.id !== 8 && (
              <div>
                <CustomCheckbox
                  name={`documents[${index}].no_expire`}
                  checked={!!document.no_expire}
                  onChange={(checked) => {
                    setValues((prevState) => {
                      return {
                        documents: prevState.documents.map((item, i) =>
                          i === index
                            ? {
                                ...item,
                                no_expire: checked,
                                document_exp_date: checked ? null : item.document_exp_date,
                              }
                            : item
                        ),
                      };
                    });
                  }}
                  disabled={shouldBeDeleted || document.use_from_policy}
                >
                  <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                    No Expiry
                  </Typography>
                </CustomCheckbox>
              </div>
            )}
          </div>
        )}
      </td>
      <td>
        {!!document.equipment_document_type && !hasNoSettings.includes(document.equipment_document_type.id) && (
          <div>
            <CustomCheckbox
              name={`documents[${index}].use_from_policy`}
              checked={!!document.use_from_policy}
              onChange={(checked) => onUseFromSettings(document, checked)}
              disabled={shouldBeDeleted || !document.equipment_document_type}
            >
              <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                Use from Policy
              </Typography>
            </CustomCheckbox>
          </div>
        )}
      </td>
      <td>
        <div>
          <Uploader
            type={3}
            allowReplace
            accept={['application/pdf']}
            document={document.document}
            onDrop={(files) => onDocumentUpload(files[0])}
            error={touchedErrors?.documents?.[index]?.document}
            disabled={shouldBeDeleted || document.use_from_policy}
          />
        </div>
      </td>
      <td>
        {!!document.document_exp_date && moment(document.document_exp_date).isValid() && (
          <Typography
            variant='b2'
            style={{ color: moment().isAfter(document.document_exp_date) ? palette.red500 : palette.green500 }}
          >
            {moment().isAfter(document.document_exp_date)
              ? `Expired ${moment().diff(document.document_exp_date, 'days')} days ago`
              : `Expires in ${moment(document.document_exp_date).diff(moment(), 'days')} days`}
          </Typography>
        )}
      </td>
      <td>
        {!readOnly && (document.isNew ? true : document.equipment_document_type?.id !== 9) && (
          <SActions>
            {loading ? (
              <span className='loading-wrapper'>
                <CircularProgress size={16} />
              </span>
            ) : shouldBeDeleted ? (
              <span className='action-wrapper' onClick={() => onRevert(document)}>
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  Revert
                </Typography>
              </span>
            ) : (
              <span className='action-wrapper' onClick={() => onDeleteRow(document)}>
                <Typography variant='s2' style={{ color: palette.red500 }}>
                  Remove
                </Typography>
              </span>
            )}
          </SActions>
        )}
      </td>
    </tr>
  );
};

export default FormRow;
