const autoFillData = [
  {
    id: 3,
    equipment_type: 'Cargo Van',
    length: 1,
    height: '56',
    width: '55',
    cargo_payload: '3700',
  },
  {
    id: 4,
    equipment_type: 'Sprinter',
    length: 2,
    height: '96',
    width: '92',
    cargo_payload: '4488',
  },
  {
    id: 5,
    equipment_type: 'Pickup Truck',
    length: 2,
    height: '75',
    width: '80',
    cargo_payload: '1500',
  },
  {
    id: 9,
    equipment_type: 'Straight Truck',
    length: 1,
    height: '78',
    width: '84',
    cargo_payload: '3000',
  },
  {
    id: 9,
    equipment_type: 'Straight Truck',
    length: 1,
    height: '78',
    width: '84',
    cargo_payload: '3000',
  },
  {
    id: 11,
    equipment_type: 'Cube Van',
    length: 1,
    height: '83',
    width: '95',
    cargo_payload: '3600',
  },
  {
    id: 12,
    equipment_type: 'Dry Van',
    length: 8,
    height: '108',
    width: '98',
    cargo_payload: '45000',
  },
  {
    id: 13,
    equipment_type: 'Plated Van',
    length: 8,
    height: '108',
    width: '98',
    cargo_payload: 45000,
  },
  {
    id: 14,
    equipment_type: 'Vented Van',
    length: 8,
    height: '108',
    width: '98',
    cargo_payload: '45000',
  },
  {
    id: 15,
    equipment_type: 'Reinforced Van',
    length: 8,
    height: '108',
    width: '98',
    cargo_payload: '45000',
  },
  {
    id: '16',
    equipment_type: 'Flat Bed',
    length: 8,
    height: '102',
    width: '102',
    cargo_payload: '42000',
  },
  {
    id: 17,
    equipment_type: 'Flat Bed Curtain',
    length: 8,
    height: '102',
    width: '102',
    cargo_payload: '48000',
  },
  {
    id: 18,
    equipment_type: 'Reefer Van',
    length: 8,
    height: '103',
    width: '96',
    cargo_payload: '45000',
  },
  {
    id: '19',
    equipment_type: 'Lowboy',
    length: 5,
    height: '120',
    width: '102',
    cargo_payload: '42000',
  },
  {
    id: '20',
    equipment_type: 'Step Deck',
    length: 8,
    height: '120',
    width: '102',
    cargo_payload: '48000',
  },
  {
    id: 21,
    equipment_type: 'Step Deck Curtain',
    length: 8,
    height: '102',
    width: '120',
    cargo_payload: '48000',
  },
  {
    id: 22,
    equipment_type: 'Conestoga',
    length: 8,
    height: '98',
    width: '102',
    cargo_payload: '41000',
  },
  {
    id: 24,
    equipment_type: 'Multi-Car',
    length: 8,
    height: '102',
    width: '115',
    cargo_payload: '17100',
  },
  {
    id: 23,
    equipment_type: 'Gooseneck',
    length: 8,
    height: '150',
    width: '102',
    cargo_payload: '40000',
  },
  {
    id: 26,
    equipment_type: 'Dump',
    length: '16ft',
    height: '84',
    width: '88',
    cargo_payload: '28000',
  },
  {
    id: 27,
    equipment_type: 'Container Chassis',
    length: '53ft',
    height: '109',
    width: '98',
    cargo_payload: '43000',
  },
  {
    id: 28,
    equipment_type: 'Curtain Side Robotic',
    length: 8,
    height: '98',
    width: '102',
    cargo_payload: '45000',
  },
];
export { autoFillData };
const lengthData = [
  {
    label: '12ft',
    value: 1,
  },
  {
    label: '18ft',
    value: 2,
  },
  {
    label: '20ft',
    value: 3,
  },

  {
    label: '22ft',
    value: 4,
  },
  {
    label: '24ft',
    value: 5,
  },
  {
    label: '26ft',
    value: 6,
  },
  {
    label: '28ft',
    value: 7,
  },
  {
    label: '48ft',
    value: 8,
  },
  {
    label: '53ft',
    value: 9,
  },
];

export { lengthData };
