import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';

export const SWrapper = styled.div`
  margin-bottom: 24px;
`;

export const SAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    :before {
      opacity: 0;
    }

    .MuiAccordionSummary-root {
      flex-direction: row-reverse;
      min-height: auto;
      width: fit-content;
      padding: 0;

      .MuiAccordionSummary-content {
        margin: 4px 0 4px 12px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
`;
