import React from 'react';
import uuid from 'react-uuid';
import Grid from '@mui/material/Grid';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import Input, { Textarea } from 'common/Input';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { trafficAccidentsInitialValues } from 'pages/PublicPositions/ApplyJob/ApplyJob.data';
import { SAddMore, SFormListItem } from '../Steps.styles';

const TrafficAccidents = ({ form }) => {
  const { values, handleChange, touchedErrors } = form;

  const addNewAccident = () => {
    handleChange(`accidents[${values.accidents.length}]`, {
      ...trafficAccidentsInitialValues.accidents[0],
      id: uuid(),
    });
  };

  const addNewTraffic = () => {
    handleChange(`traffics[${values.traffics.length}]`, {
      ...trafficAccidentsInitialValues.traffics[0],
      id: uuid(),
    });
  };

  const removeAccidentRow = (id) => {
    handleChange(
      `accidents`,
      values.accidents.filter((item) => item.id !== id)
    );
  };

  const removeTrafficRow = (id) => {
    handleChange(
      `traffics`,
      values.traffics.filter((item) => item.id !== id)
    );
  };

  const handleAddressChange = (event, value, index) => {
    const addressFieldsMapper = {
      address: `traffics[${index}].address`,
      city: `traffics[${index}].city`,
      state: `traffics[${index}].state`,
      country: `traffics[${index}].country`,
      zipcode: `traffics[${index}].zipcode`,
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>
              Were you involved in any accidents/incidents with any vehicle in the last 5 years (even if not at fault)?
            </InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='involved_in_accidents_1'
                name='involved_in_accidents'
                value={1}
                checked={values.involved_in_accidents === 1}
                onChange={() => handleChange(`involved_in_accidents`, 1)}
                label='Yes'
              />
              <Radio
                id='involved_in_accidents_0'
                name='involved_in_accidents'
                value={0}
                checked={values.involved_in_accidents === 0}
                onChange={() => handleChange(`involved_in_accidents`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.involved_in_accidents} />
          </div>
        </Grid>
        {!!values.involved_in_accidents &&
          values.accidents.map((item, i) => (
            <SFormListItem item container columnSpacing={2} rowSpacing={2} key={item.id || i}>
              {i !== 0 && (
                <DeleteIcon
                  width={12}
                  height={12}
                  fill={palette.red500}
                  onClick={() => removeAccidentRow(item.id)}
                  className='delete-icon absolute-positioned'
                />
              )}
              <Grid item xs={12} md={6}>
                <Textarea
                  rows={3}
                  required
                  name={`accidents[${i}].accident_nature`}
                  label='Nature of accident? (head-on, rear-end etc.)'
                  onChange={handleChange}
                  value={item.accident_nature}
                  error={touchedErrors.accidents?.[i]?.accident_nature}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <DatePicker
                  width='100%'
                  required
                  label='Date'
                  name={`accidents[${i}].date`}
                  value={item.date}
                  onChange={(val) => handleChange(`accidents[${i}].date`, val)}
                  error={touchedErrors.accidents?.[i]?.date}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <div className='mb-3'>
                  <InputLabel required>Fatalities</InputLabel>
                  <div className='d-flex gap-3'>
                    <Radio
                      id={`accidents[${i}].fatalities_1`}
                      name={`accidents[${i}].fatalities`}
                      value={1}
                      checked={item.fatalities === 1}
                      onChange={() => handleChange(`accidents[${i}].fatalities`, 1)}
                      label='Yes'
                    />
                    <Radio
                      id={`accidents[${i}].fatalities_0`}
                      name={`accidents[${i}].fatalities`}
                      value={0}
                      checked={item.fatalities === 0}
                      onChange={() => handleChange(`accidents[${i}].fatalities`, 0)}
                      label='No'
                    />
                  </div>
                  <ErrorMessage error={touchedErrors.accidents?.[i].fatalities} />
                  {item.fatalities === 1 && (
                    <div className='mt-2'>
                      <Input
                        width='150px'
                        placeholder='Count'
                        type='number'
                        name={`accidents[${i}].fatalities_count`}
                        onChange={(e) => handleChange(`accidents[${i}].fatalities_count`, e.target.value)}
                        value={item.fatalities_count}
                        onKeyDown={blockNonPositiveNumbers}
                        error={touchedErrors.accidents?.[i]?.fatalities_count}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className='mb-3'>
                  <InputLabel required>Injuries</InputLabel>
                  <div className='d-flex gap-3'>
                    <Radio
                      id={`accidents[${i}].injuries_1`}
                      name={`accidents[${i}].injuries`}
                      value={1}
                      checked={item.injuries === 1}
                      onChange={() => handleChange(`accidents[${i}].injuries`, 1)}
                      label='Yes'
                    />
                    <Radio
                      id={`accidents[${i}].injuries_0`}
                      name={`accidents[${i}].injuries`}
                      value={0}
                      checked={item.injuries === 0}
                      onChange={() => handleChange(`accidents[${i}].injuries`, 0)}
                      label='No'
                    />
                  </div>
                  <ErrorMessage error={touchedErrors.accidents?.[i].injuries} />
                  {item.injuries === 1 && (
                    <div className='mt-2'>
                      <Input
                        width='150px'
                        placeholder='Count'
                        type='number'
                        name={`accidents[${i}].injuries_count`}
                        onChange={(e) => handleChange(`accidents[${i}].injuries_count`, e.target.value)}
                        value={item.injuries_count}
                        onKeyDown={blockNonPositiveNumbers}
                        error={touchedErrors.accidents?.[i]?.injuries_count}
                      />
                    </div>
                  )}
                </div>
              </Grid>
            </SFormListItem>
          ))}
        {!!values.involved_in_accidents && (
          <SAddMore onClick={addNewAccident}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add More...
            </Typography>
          </SAddMore>
        )}

        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>
              Have you had any moving violations or traffic convictions in the past 3 years?
            </InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='involved_in_violations_1'
                name='involved_in_violations'
                value={1}
                checked={values.involved_in_violations === 1}
                onChange={() => handleChange(`involved_in_violations`, 1)}
                label='Yes'
              />
              <Radio
                id='involved_in_violations_0'
                name='involved_in_violations'
                value={0}
                checked={values.involved_in_violations === 0}
                onChange={() => handleChange(`involved_in_violations`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.involved_in_violations} />
          </div>
        </Grid>
        {!!values.involved_in_violations &&
          values.traffics.map((item, i) => (
            <SFormListItem item container columnSpacing={2} rowSpacing={2} key={item.id || i}>
              {i !== 0 && (
                <DeleteIcon
                  width={12}
                  height={12}
                  fill={palette.red500}
                  onClick={() => removeTrafficRow(item.id)}
                  className='delete-icon absolute-positioned'
                />
              )}
              <Grid item xs={12} md={8}>
                <AddressFields
                  required
                  hideAddress2
                  values={item}
                  handleChange={(e, value) => handleAddressChange(e, value, i)}
                  touchedErrors={touchedErrors?.traffics?.[i]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  width='100%'
                  required
                  label='Date'
                  name={`traffics[${i}].date`}
                  value={item.date}
                  onChange={(val) => handleChange(`traffics[${i}].date`, val)}
                  error={touchedErrors.traffics?.[i]?.date}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  required
                  name={`traffics[${i}].charge`}
                  label='Charge'
                  onChange={(e) => handleChange(`traffics[${i}].charge`, e.target.value)}
                  value={item.charge}
                  error={touchedErrors.traffics?.[i]?.charge}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  required
                  name={`traffics[${i}].penalty`}
                  label='Penalty'
                  onChange={(e) => handleChange(`traffics[${i}].penalty`, e.target.value)}
                  value={item.penalty}
                  error={touchedErrors.traffics?.[i]?.penalty}
                />
              </Grid>
            </SFormListItem>
          ))}
        {!!values.involved_in_violations && (
          <SAddMore onClick={addNewTraffic}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add More...
            </Typography>
          </SAddMore>
        )}
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>
              Have you ever been denied a license, permit or privilege to operate a motor vehicle?
            </InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='denied_license_1'
                name='denied_license'
                value={1}
                checked={values.denied_license === 1}
                onChange={() => handleChange(`denied_license`, 1)}
                label='Yes'
              />
              <Radio
                id='denied_license_0'
                name='denied_license'
                value={0}
                checked={values.denied_license === 0}
                onChange={() => handleChange(`denied_license`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.denied_license} />
            {values.denied_license === 1 && (
              <div className='mt-2'>
                <Textarea
                  rows={3}
                  required
                  name='denied_statement'
                  label='Statement'
                  onChange={handleChange}
                  value={values.denied_statement}
                  error={touchedErrors.denied_statement}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className='mb-3'>
            <InputLabel required>Has any license, permit or privilege ever been suspended or revoked?</InputLabel>
            <div className='d-flex gap-3'>
              <Radio
                id='suspended_license_1'
                name='suspended_license'
                value={1}
                checked={values.suspended_license === 1}
                onChange={() => handleChange(`suspended_license`, 1)}
                label='Yes'
              />
              <Radio
                id='suspended_license_0'
                name='suspended_license'
                value={0}
                checked={values.suspended_license === 0}
                onChange={() => handleChange(`suspended_license`, 0)}
                label='No'
              />
            </div>
            <ErrorMessage error={touchedErrors.suspended_license} />
            {values.suspended_license === 1 && (
              <div className='mt-2'>
                <Textarea
                  rows={3}
                  required
                  name='suspended_statement'
                  label='Statement'
                  onChange={handleChange}
                  value={values.suspended_statement}
                  error={touchedErrors.suspended_statement}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default TrafficAccidents;
