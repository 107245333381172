import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Radio from 'common/Radio';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { CURRENCY } from 'utils/constants';
import { updateCurrency, updateQtyUnit, updateWeightUnit } from 'store/reducers/root.reducer';
import { createCurrencyAndMetrics, getCurrencyAndMetrics } from 'Api/CompanySettings';
import { validationSchema } from './validationSchema';
import { getInitialValues, CURRENCIES, QTY_TYPES, WEIGHTS } from './CurrencyAndMetrics.data';
import { SWrapper, SItemRow } from './CurrencyAndMetrics.styles';

const CurrencyAndMetrics = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const dispatch = useDispatch();
  const [settingsData, setSettingsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    setLoadingUpdate(true);
    try {
      const body = {
        default_currency: values.default_currency || null,
        default_weight: values.default_weight || null,
        default_qty_type: values.default_qty_type || null,
      };
      const { data } = await createCurrencyAndMetrics(body);
      dispatch(updateCurrency(CURRENCY[data.default_currency]?.symbol || '$'));
      dispatch(updateWeightUnit(data.default_weight));
      dispatch(updateQtyUnit(data.default_qty_type));
      setSettingsData(data);
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(settingsData),
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const getMetricsSettings = async () => {
    try {
      const { data } = await getCurrencyAndMetrics();
      setSettingsData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getMetricsSettings();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      <PageHeader
        hideSkip
        title='Currency and Metrics'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
      />
      <Divider />
      <div>
        <SItemRow>
          <div>
            <Typography variant='s2'>Default System Currency</Typography>
          </div>
          <div>
            <div className='radio-wrapper'>
              {CURRENCIES.map((item) => (
                <Radio
                  key={item.id}
                  label={item.label}
                  value={item.value}
                  checked={values.default_currency === item.value}
                  onChange={() => handleChange('default_currency', item.value)}
                />
              ))}
            </div>
            <ErrorMessage error={touchedErrors.default_currency} />
          </div>
        </SItemRow>
        <SItemRow>
          <div>
            <Typography variant='s2'>Default System Weight Metric</Typography>
          </div>
          <div>
            <div className='radio-wrapper'>
              {WEIGHTS.map((item) => (
                <Radio
                  key={item.id}
                  label={item.label}
                  value={item.value}
                  checked={values.default_weight === item.value}
                  onChange={() => handleChange('default_weight', item.value)}
                />
              ))}
            </div>
            <ErrorMessage error={touchedErrors.default_weight} />
          </div>
        </SItemRow>
        <SItemRow>
          <div>
            <Typography variant='s2'>Default Qty Type</Typography>
          </div>
          <div>
            <div className='radio-wrapper'>
              {QTY_TYPES.map((item) => (
                <Radio
                  key={item.id}
                  value={item.value}
                  checked={values.default_qty_type === item.value}
                  onChange={() => handleChange('default_qty_type', item.value)}
                  label={item.label}
                />
              ))}
            </div>
            <ErrorMessage error={touchedErrors.default_currency} />
          </div>
        </SItemRow>
      </div>
    </SWrapper>
  );
};

export default CurrencyAndMetrics;
