import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateAlert, uploadDriverDocument } from 'Api/AlertCenter';
import {
  criminalCheckResultOptions,
  resultOptions,
  fmcsaReasonOptions,
  drugTestReasonOptions,
  mvrReasonOptions,
} from 'componentsV2/MedicalAndBackgroundTab/MedicalAndBackgroundTab.data';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { validationSchema } from './validationSchema';
import { ALERT_TYPE, statusOptions } from '../TakeActions.data';

const options = {
  [ALERT_TYPE.CRIMINAL_CHECK_DUE]: criminalCheckResultOptions,
  [ALERT_TYPE.FMCSA_CLEARING_HOUSE_CHECK_DUE]: fmcsaReasonOptions,
  [ALERT_TYPE.MVR_CHECK_DUE]: mvrReasonOptions,
  [ALERT_TYPE.DRUG_TESTING_CHECK_DUE]: drugTestReasonOptions,
};

const CheckExpiration = ({ open, onClose, alert, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        driver_id: alert.driver_id,
        status: values.status.value,
      };

      const attachment = await uploadDocument(values.attachment);

      switch (alert.alert_name.alert_name) {
        case ALERT_TYPE.CRIMINAL_CHECK_DUE:
          body.criminal_check_results = values.results;
          body.criminal_check_date = moment(values.check_date).format('YYYY-MM-DD');
          body.attach_criminal_result = attachment;
          break;
        case ALERT_TYPE.MVR_CHECK_DUE:
          body.mvr_check_results = values.results;
          body.mvr_check_date = moment(values.check_date).format('YYYY-MM-DD');
          body.mvr_next_check_date = moment(values.next_check_date).format('YYYY-MM-DD');
          body.attach_mvr_check_result = attachment;
          break;
        case ALERT_TYPE.FMCSA_CLEARING_HOUSE_CHECK_DUE:
          body.fmcsa_check_results = values.results;
          body.fmcsa_checked_date = moment(values.check_date).format('YYYY-MM-DD');
          body.fmcsa_next_check_date = moment(values.next_check_date).format('YYYY-MM-DD');
          body.attach_fmcsa_result = attachment;
          break;
        case ALERT_TYPE.DRUG_TESTING_CHECK_DUE:
          body.drug_check_results = values.results;
          body.drug_checked_date = moment(values.check_date).format('YYYY-MM-DD');
          body.attach_drug_check_result = attachment;
          break;
        default:
      }

      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: {
      results: null,
      check_date: null,
      next_check_date: null,
      attachment: null,
      status: statusOptions.find((i) => i.name === alert?.status) || null,
    },
    validationSchema,
    onSubmit,
  });

  const uploadDocument = async (file) => {
    try {
      const formData = new FormData();
      formData.append('driver_id', alert.driver_id || 1);
      formData.append('source', 'general');
      formData.append('file_upload', file);

      const { document_path } = await uploadDriverDocument(formData);
      return document_path;
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      $maxWidth='750px'
      $minWidth='750px'
      padding='0'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <div className='d-flex gap-4 mb-4 align-items-center'>
            <div>
              <Autocomplete
                required
                width='220px'
                label='Results'
                name='results'
                options={options[alert.alert_name.alert_name] || resultOptions}
                value={values.results}
                onChange={(e, val) => handleChange('results', val)}
                error={touchedErrors.results}
              />
            </div>
            <div>
              <div className='d-flex'>
                <DatePicker
                  required
                  width='140px'
                  label='Checked'
                  name='check_date'
                  value={values.check_date}
                  onBlur={handleBlur}
                  borderRadius='6px 0 0 6px'
                  onChange={(val) => {
                    handleChange('check_date', val);
                    if (moment(val).isValid()) {
                      handleChange('next_check_date', moment(val).add(1, 'year').toDate());
                    }
                  }}
                  disableFuture
                />
                {![ALERT_TYPE.DRUG_TESTING_CHECK_DUE, ALERT_TYPE.CRIMINAL_CHECK_DUE].includes(
                  alert.alert_name?.alert_name
                ) && (
                  <div>
                    <DatePicker
                      required
                      width='140px'
                      label='Next Check Date'
                      name='next_check_date'
                      value={values.next_check_date}
                      onBlur={handleBlur}
                      borderRadius='0 6px 6px 0'
                      onChange={(val) => handleChange('next_check_date', val)}
                      disablePast
                    />
                  </div>
                )}
              </div>
              <ErrorMessage error={touchedErrors.check_date} />
            </div>
            <div className='mt-4'>
              <Uploader
                type={3}
                uploaderLabel='Attach Results'
                document={values.attachment}
                onDrop={(files) => handleChange('attachment', files[0])}
                onRemove={() => handleChange('attachment', null)}
                error={touchedErrors.attachment}
              />
            </div>
          </div>
          <div>
            <Autocomplete
              required
              width='260px'
              label='Action'
              name='status'
              options={statusOptions}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.status}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckExpiration;
