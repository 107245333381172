import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import { useSearchParams } from 'react-router-dom';
import './CustomerProfileTab.css';
import CustomizedSnackbars from 'components/toast/Toast';
import SalesAgents from 'componentsV2/Customer/SalesAgents';
import HolidaysTab from 'components/HolidaysTab/HolidaysTab';
import CustomerLogTab from 'components/CustomerLogTab/LogTab';
import DelayCodesTab from 'components/DelayCodesTab/DelayCodesTab';
import ContactBookTab from 'components/ContactBookTab/ContactBookTab';
import PaymentTermsTab from 'components/PaymentTermsTab/PaymentTermsTab';
import CustomerTransactionsTab from 'components/CustomerTransactionsTab';
import LinehaulRateMatrixTab from 'components/LinehaulRateMatrixTab/LinehaulRateMatrixTab';
import GeneralInformationTab from 'components/GeneralInformationTab/GeneralInformationTab';
import FuelSurchargeMatrixTab from 'components/FuelSurchargeMatrixTab/FuelSurchargeMatrixTab';
import { palette, PERMISSIONS } from 'utils/constants';
import { useTheme } from 'context/themeContext';

export default function CustomerProfileTab({ customer, onSuccess }) {
  const [searchParams] = useSearchParams();
  const { permissions } = useSelector((state) => state?.root);
  const initialTab = searchParams.get('tab');
  const [key, setKey] = useState(initialTab || 'General');
  const { use } = useTheme();
  const isDarkMode = use(palette.white, palette.dark800) !== '#FFFFFF';
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  return (
    <>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <div className='modal-container-tab'>
        <Tabs
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className={`mb-3 profile-tab justify-content-start ${isDarkMode ? 'dark-mode-tab' : ''}`}
          style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
        >
          <Tab eventKey='General' className='profile-tab-content' unmountOnExit title='General'>
            <GeneralInformationTab
              customerInfo={{
                companyName: customer?.company_name,
                companyDot: customer?.company_dot_mc,
                phone_number:
                  customer?.telephone_number && !customer.telephone_number?.startsWith('+')
                    ? `+${customer.telephone_number}`
                    : customer?.telephone_number,
                customer_address: customer?.address1,
                customer_country: customer?.customer_country?.name,
                customer_state: customer?.state?.name,
                customer_city: customer?.city?.name,
                country_data: customer?.country,
                state_data: customer?.state,
                city_data: customer?.city,
                customer_zip: customer?.zipcode,
                customer_address2: customer?.address2,
                equipment_restrictions: customer?.equipment_restrictions,
                account: customer?.account,
              }}
              onSuccess={onSuccess}
              customer={customer}
            />
          </Tab>
          <Tab
            eventKey='Contact Book'
            unmountOnExit
            className='profile-tab-content'
            title='Contact Book'
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
          >
            <ContactBookTab />
          </Tab>
          <Tab eventKey='terms' unmountOnExit className='profile-tab-content' title='Terms & Requirements'>
            <PaymentTermsTab showError={showError} />
          </Tab>
          {(permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) ||
            permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_READ_ONLY.name)) && (
            <Tab
              eventKey='Delay Codes'
              unmountOnExit
              className='profile-tab-content'
              title='EDI/API'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            >
              <DelayCodesTab />
            </Tab>
          )}
          <Tab eventKey='Sales Agents' unmountOnExit className='profile-tab-content' title='Sales Agents'>
            <SalesAgents />
          </Tab>
          <Tab
            eventKey='Fuel Surcharge Matrix'
            unmountOnExit
            className='profile-tab-content'
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            title='Fuel Surcharge Matrix'
          >
            <FuelSurchargeMatrixTab />
          </Tab>
          <Tab
            eventKey='Linehaul Rate Matrix'
            unmountOnExit
            className='profile-tab-content'
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            title='Linehaul Rate Matrix'
          >
            <LinehaulRateMatrixTab />
          </Tab>
          <Tab
            eventKey='Shipments'
            unmountOnExit
            className='profile-tab-content'
            title='Transactions'
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
          >
            <CustomerTransactionsTab customer={customer} />
          </Tab>
          <Tab eventKey='Holidays' unmountOnExit className='profile-tab-content' title='Holidays'>
            <HolidaysTab />
          </Tab>
          <Tab eventKey='Log' unmountOnExit className='profile-tab-content' title='Log'>
            <CustomerLogTab />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
