import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/createShipment/arrowDown.svg';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import { OVERVIEW_STOPS_TOP_MODAL } from '../../../constats';
import styles from '../steps/OverView/OverView.module.css';

const OverLayTopStops = ({
  activeStopPointType,
  onDelete,
  onChange,
  deleted = true,
  validateDelete,
  onlyDelete = false,
  changeType,
  onChangeStopType,
}) => {
  const { use } = useTheme();
  const target = useRef(null);
  const [isOpenTopModal, setIsOpenTopModal] = useState(false);

  const onChangeHandler = (e) => {
    typeof onChange === 'function' && onChange(e);
  };

  const onDeleteHandler = () => {
    if (validateDelete === undefined) {
      typeof onDelete === 'function' && onDelete();
    } else {
      const result = validateDelete();
      !!result && typeof onDelete === 'function' && onDelete();
      setIsOpenTopModal(false);
    }
  };

  const changeStopType = (stopType) => {
    setIsOpenTopModal(false);
    onChangeStopType(stopType.id);
  };

  return (
    <>
      <div
        ref={target}
        onClick={() => {
          setIsOpenTopModal(true);
        }}
        className={styles.arrow_top_stops}
      >
        <ArrowDownIcon />
      </div>
      <Overlay
        placement='bottom'
        rootClose
        show={isOpenTopModal}
        target={target?.current}
        onHide={() => setIsOpenTopModal(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div className={styles.overLay_backDrop}>
            <div
              {...props}
              className={styles.overLay_top_stops_wrapper}
              style={{ ...props.style, backgroundColor: use(palette.white, palette.dark800) }}
            >
              {!onlyDelete &&
                !changeType &&
                OVERVIEW_STOPS_TOP_MODAL.map((el) => {
                  return (
                    <div key={el.id}>
                      <CustomRadioButton
                        field={{
                          name: 'stop_top_modal',
                          value: el.id,
                          onChange: onChangeHandler,
                          defaultChecked: +activeStopPointType === el.id,
                        }}
                      >
                        <Typography variant='s2' style={{ marginLeft: 8 }}>
                          {el.title}
                        </Typography>
                      </CustomRadioButton>
                    </div>
                  );
                })}
              {deleted && !changeType && (
                <>
                  {!onlyDelete && <div className={styles.line} style={{ margin: '12px 0' }} />}
                  <CustomButton
                    type='danger'
                    title='Delete Stop'
                    onClick={onDeleteHandler}
                    styleButton={{ padding: '3px 6px', margin: 0 }}
                    styleTitle={{ fontSize: 14, fontWeight: 500 }}
                  />
                </>
              )}
              {changeType && (
                <>
                  {OVERVIEW_STOPS_TOP_MODAL.map((el) => {
                    return (
                      <div key={el.id}>
                        <CustomRadioButton
                          field={{
                            name: 'stop_top_modal',
                            value: el.id,
                            onChange: () => changeStopType(el),
                            defaultChecked: +activeStopPointType === el.id,
                          }}
                        >
                          <Typography variant='s2' style={{ marginLeft: 8 }}>
                            {el.title}
                          </Typography>
                        </CustomRadioButton>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default OverLayTopStops;
