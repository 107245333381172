import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { useTheme } from 'context/themeContext';
import styles from './WorkingHours.module.css';
import { WEEK_DAYS } from '../../constants/constants';

const WorkingHoursPreview = ({ setEdit, stopPointsWorkingHours }) => {
  const { use } = useTheme();
  const subTitle =
    stopPointsWorkingHours?.data?.working_hour_by === 1
      ? 'First Come First Serve'
      : stopPointsWorkingHours?.data?.working_hour_by === 2
      ? 'By Appointment'
      : stopPointsWorkingHours?.data?.working_hour_by === 3
      ? 'By Time Window'
      : '';

  return (
    <div className={styles.workingHours_wrapper}>
      <div className={styles.workingHours_information}>
        <div className={styles.header}>
          <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
            Working Hours
          </Typography>
          <div onClick={() => setEdit(true)} className={styles.icon_wrapper}>
            <EditIcon className={styles.icon} />
          </div>
        </div>
        <div className={styles.subtitle}>
          <Typography variant='s1' style={{ color: use(palette.gray900, palette.gray200) }}>
            {subTitle}
          </Typography>
        </div>
        <div className={styles.info_section}>
          {!!stopPointsWorkingHours?.data?.working_hour_by && (
            <>
              {WEEK_DAYS.map((item) => {
                const element = stopPointsWorkingHours?.data?.stop_point_working_hour?.find(
                  (d) => +d.week_day === item.id
                );
                return (
                  <div key={item} className={styles.day_row}>
                    {!element ? (
                      <div style={{ display: 'flex' }}>
                        <Typography variant='s2' style={{ color: use(palette.red500), width: 100 }}>
                          {item.day}
                        </Typography>
                        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                          Closed
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant='s2'
                          style={{
                            color: use(palette.gray900, palette.gray200),
                            width: 100,
                          }}
                        >
                          {item.day}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={styles.date}>
                            <Typography variant='c2' style={{ color: use(palette.gray700, palette.gray200) }}>
                              {element.start}
                            </Typography>
                          </div>
                          <div
                            style={{
                              padding: '0 4px',
                              color: '#D5DAE5',
                            }}
                          >
                            -
                          </div>
                          <div className={styles.date}>
                            <Typography variant='c2' style={{ color: use(palette.gray700, palette.gray200) }}>
                              {element.end}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default WorkingHoursPreview;
