import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Modal from 'common/Modal';
import Input from 'common/Input';
import Uploader from 'common/Uploader';
import ViewPdf from 'components/ViewPdf';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { updateAlert, uploadDriverDocument } from 'Api/AlertCenter';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { ALERT_TYPE, statusOptions, noExpirationAlerts } from '../TakeActions.data';
import { validationSchema } from './validationSchema';

const DocumentExpiration = ({ open, onClose, alert, onSuccess }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { value } = useAuth();
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);

  const onSubmit = async () => {
    try {
      setLoading(true);

      const attachment = await uploadDocument(values.attachment);

      const body = {
        driver_id: alert.driver_id,
        staff_id: alert.staff_id,
        document_name: alert.document_name,
        document_id: alert.document_id,
        status: values.status.value,
      };

      switch (alert.alert_name?.alert_name) {
        case ALERT_TYPE.EQUIPMENT_REGISTRATION_EXPIRING:
          body.license_plate_number = alert.license_plate_number;
          body.state = alert.state;
          body.license_plate_expire_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.upload_registration = attachment;
          break;
        case ALERT_TYPE.DOT_INSPECTION:
          body.dot_inspection_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.dot_attach = attachment;
          break;
        case ALERT_TYPE.HIGHWAY_TAX:
          body.highway_tax_expire_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.highway_tax_date_attach = attachment;
          break;
        case ALERT_TYPE.IFTA:
        case ALERT_TYPE.IFTA_LICENSE:
          body.ifta_sticker_expire_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.ifta_sticker_attach = attachment;
          body.ifta_sticker_no = values.ifta_sticker_no;
          break;
        case ALERT_TYPE.DRIVER_DOCUMENT:
          body.name = values.document_name;
          body.certificate_path = attachment;
          body.certification_exp_date = values.expire_date ? moment(values.expire_date).format('YYYY-MM-DD') : null;
          break;
        case ALERT_TYPE.DRIVER_CUSTOM_DOCUMENT_EXPIRATION:
          body.document_exp_date = values.expire_date ? moment(values.expire_date).format('YYYY-MM-DD') : null;
          body.document = attachment;
          body.document_name = values.document_name;
          break;
        case ALERT_TYPE.STAFF_CUSTOM_DOCUMENT_EXPIRATION:
          body.document_name = values.document_name;
          body.exp_date = values.expire_date ? moment(values.expire_date).format('YYYY-MM-DD') : null;
          body.document_path = attachment;
          break;
        case ALERT_TYPE.OWNER_OPERATOR_DOCUMENT:
          body.document_name = values.document_name;
          body.expiry_date = values.expire_date ? moment(values.expire_date).format('YYYY-MM-DD') : null;
          body.document_path = attachment;
          break;
        case ALERT_TYPE.CARRIER_DOCUMENT_EXPIRED:
          body.carrier_id = alert.for_user_id;
          body.carrier_document_type = alert.document_type;
          body.expiry_date = values.expire_date ? moment(values.expire_date).format('YYYY-MM-DD') : null;
          body.path = attachment;
          break;
        default:
          body.exp_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.document_path = attachment;
      }

      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: {
      document_name: '',
      ifta_sticker_no: '',
      expire_date: null,
      attachment: null,
      no_expiration: false,
      status: statusOptions.find((i) => i.name === alert?.status) || null,
    },
    validationSchema,
    onSubmit,
  });

  const uploadDocument = async (file) => {
    try {
      const formData = new FormData();
      formData.append('driver_id', alert.driver_id || alert.staff_id || alert.equipment_id || value.user.id);
      formData.append('source', 'general');
      formData.append('file_upload', file);

      const { document_path } = await uploadDriverDocument(formData);
      return document_path;
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      padding='0'
      $maxWidth='750px'
      $minWidth='750px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        ...(alert.alert_name?.alert_name === ALERT_TYPE.CARRIER_DOCUMENT_EXPIRED && alert.for_user_id
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: () => {
                  navigate(`/carrier-profile/${alert.for_user_id}`, { state: { activeTab: 'Documents' } });
                  onClose();
                },
              },
            ]
          : []),
        ...(alert.document_path || alert.certificate_path
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: () => setDocument(alert.document_path || alert.certificate_path),
              },
            ]
          : []),
        ...(alert.equipment_type && alert.equipment_id
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: () => {
                  navigate(
                    `/equipment-profile/${alert.equipment_type === 1 ? 'vehicle' : 'trailer'}/${alert.equipment_id}`
                  );
                  onClose();
                },
              },
            ]
          : []),
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <div className='d-flex gap-4 mb-4 align-items-start'>
            {noExpirationAlerts.includes(alert?.alert_name?.alert_name) &&
              alert?.alert_name?.alert_name !== ALERT_TYPE.CARRIER_DOCUMENT_EXPIRED && (
                <Input
                  width='250px'
                  required
                  name='document_name'
                  label='Document Name'
                  placeholder='Document Name'
                  value={values.document_name}
                  onChange={handleChange}
                  error={touchedErrors.document_name}
                />
              )}
            {[ALERT_TYPE.IFTA, ALERT_TYPE.IFTA_LICENSE].includes(alert.alert_name?.alert_name) && (
              <Input
                width='250px'
                required
                name='ifta_sticker_no'
                label='IFTA License Number'
                placeholder='License Number'
                value={values.ifta_sticker_no}
                onChange={handleChange}
                error={touchedErrors.ifta_sticker_no}
              />
            )}
            <div>
              <DatePicker
                required
                label='Expiry Date'
                width='150px'
                name='expire_date'
                value={values.expire_date}
                onBlur={handleBlur}
                onChange={(val) => handleChange('expire_date', val)}
                disablePast
                disabled={!!values.no_expiration}
                error={touchedErrors.expire_date}
              />
              {noExpirationAlerts.includes(alert?.alert_name?.alert_name) && (
                <div className='mt-1'>
                  <CustomCheckbox
                    name='no_expiration'
                    checked={!!values.no_expiration}
                    onChange={(checked) => {
                      handleChange('no_expiration', checked);
                      if (checked) {
                        handleChange(`expire_date`, null);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                      No Expiration
                    </Typography>
                  </CustomCheckbox>
                </div>
              )}
            </div>
            <div style={{ marginTop: '32px' }}>
              <Uploader
                type={3}
                document={values.attachment}
                onDrop={(files) => handleChange('attachment', files[0])}
                onRemove={() => handleChange('attachment', null)}
                error={touchedErrors.attachment}
              />
            </div>
          </div>
          <div>
            <Autocomplete
              required
              width='260px'
              label='Action'
              name='status'
              options={statusOptions}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.status}
            />
          </div>
        </div>
      </div>
      <ViewPdf open={!!document} pdfUrl={document} onClose={() => setDocument(null)} />
    </Modal>
  );
};

export default DocumentExpiration;
