import React from 'react';
import { palette } from 'utils/constants';
import { Line } from 'react-chartjs-2';
import { inspectionsOptions } from './Charts.data';

const InspectionsChart = ({ monthsData }) => {
  const data = {
    labels: (monthsData || []).map((item) => `${item.month}`),
    datasets: [
      {
        fill: true,
        label: 'Inspection Total',
        data: monthsData?.map((i) => i.inspection) || [],
        borderColor: palette.indigo500,
        pointBackgroundColor: palette.indigo500,
        backgroundColor: 'rgba(79, 90, 237, 0.2)',
      },
      {
        fill: false,
        label: 'Out of Service Total',
        data: monthsData?.map((i) => i.outOfService) || [],
        borderColor: palette.red500,
        pointBackgroundColor: palette.red500,
      },
    ],
  };

  return <Line options={inspectionsOptions(monthsData)} data={data} />;
};

export default InspectionsChart;
