import React from 'react';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import Chip from 'common/Chip';
import PhoneInput from 'common/PhoneInput';
import Autocomplete from 'common/Autocomplete';
import Input, { InputWithText } from 'common/Input';
import { industryOptions } from 'pages/CardManagement/TruckinDigitalCashCard/TruckinDigitalCashCard.data';
import AddressFields from './AddressFields';

const BasicInfo = ({ form, onClose }) => {
  const { values, handleChange, touchedErrors, handleSubmit } = form;

  return (
    <div className='d-flex flex-column gap-4' style={{ width: '400px' }}>
      <div className='d-flex flex-column' style={{ marginTop: '32px', gap: '2px' }}>
        <Typography variant='h2'>Tell us more about your business</Typography>
        <Typography variant='s3' style={{ color: palette.gray500 }}>
          Truckin Digital collects this information to better serve your business and help meet the requirements of
          regulators, financial partners, and our Services Agreement.
        </Typography>
      </div>
      <div>
        <Input
          name='legal_business_name'
          placeholder='Company'
          label='Legal business name'
          onChange={handleChange}
          value={values.legal_business_name}
          error={touchedErrors.legal_business_name}
          required
        />
        <Typography variant='c2' style={{ color: palette.gray500 }}>
          The combination of your name and Employer Identification Number (EIN) must exactly match the one listed on
          your IRS documents (e.g., Letter 147C or SS-4 Confirmation letter), including capitalization and punctuation.
        </Typography>
      </div>
      <Input
        name='ein'
        placeholder='12-3456789'
        label='Employer Identification Number (EIN)'
        onChange={handleChange}
        value={values.ein}
        error={touchedErrors.ein}
        required
      />
      <Input
        name='doing_business_as'
        label={
          <>
            <Typography style={{ marginRight: '4px' }}>Doing business as</Typography>
            <Chip label='Optional' bgColor={palette.gray100} fontSize='12px' />
          </>
        }
        onChange={handleChange}
        value={values.doing_business_as}
      />
      <div>
        <AddressFields label='Registered business address' form={form} />
      </div>
      <PhoneInput
        name='business_phone_number'
        placeholder='+1 (555) 555-1234'
        label='Business phone number'
        onlyCountries={['us', 'ca', 'mx']}
        onChange={(val) => handleChange('business_phone_number', val)}
        value={values.business_phone_number}
        error={touchedErrors.business_phone_number}
        required
      />
      <div>
        <Autocomplete
          label='Industry'
          name='industry'
          options={industryOptions}
          value={values.industry}
          onChange={(e, value) => handleChange('industry', value)}
          isOptionEqualToValue={(option, value) => option.mcc === value.mcc}
          error={touchedErrors.industry || touchedErrors.industry}
          required
        />
      </div>
      <div>
        <InputWithText
          left
          label='Business website'
          className='w-100'
          text='https://'
          name='website'
          onChange={handleChange}
          value={values.website}
          error={touchedErrors.website}
          placeholder='www.website.com'
          required
        />
      </div>
      <div className='d-flex flex-column gap-3 pb-5'>
        <CustomButton
          height={12}
          type='primary'
          title='Continue'
          styleButton={{ padding: '4px 12px', margin: 0, justifyContent: 'center', width: '400px' }}
          onClick={() => handleSubmit(values)}
        />
        <CustomButton
          height={12}
          type='secondary'
          title='Save for Later'
          styleButton={{ padding: '4px 12px', margin: 0, justifyContent: 'center', width: '400px' }}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default BasicInfo;
