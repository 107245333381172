import axios from '../services/axios';

export async function getReportCategories(params) {
  const { data } = await axios.get('/report/get-categories', { params });
  return data;
}

export async function updateReportFavoriteStatus({ id, body }) {
  const { data } = await axios.put(`/report/mark-as-favourite/${id}`, body);
  return data;
}

export async function getCategoryDetails(id, params) {
  const { data } = await axios.put(`/report/get-sub-category-details/${id}`, undefined, { params });
  return data;
}

export async function getSubcategoryReport(id, params) {
  const { data } = await axios.put(`/report/get-sub-category-report/${id}`, undefined, { params });
  return data;
}

export async function getPayableAgingSummary(params) {
  const { data } = await axios.get(`/accounts-payable/get-aging-summary`, { params });
  return data;
}

export async function getPayableAgingDetails(params) {
  const { data } = await axios.get(`/accounts-payable/get-aging-details`, { params });
  return data;
}

export async function getCustomerTransactions(id, params) {
  const { data } = await axios.get(`/customer/customer/${id}/transactions`, { params });
  return data;
}

export async function getEquipmentAtStopReport(params) {
  const { data } = await axios.get(`/equipment-reports/equipment-at-stop-point-report`, { params });
  return data;
}

export async function getEquipmentAtStopDetailedReport(params) {
  const { data } = await axios.get(`/equipment-reports/equipment-at-stop-point-report-detailed`, { params });
  return data;
}

export async function getFleetPerMonthReport(params) {
  const { data } = await axios.get(`/customer/safety/accidents/report/fleet-safest-year`, { params });
  return data;
}

export async function getAssetPerformanceDetails(params) {
  const { data } = await axios.get(`/reports/get-asset-performance-details`, { params });
  return data;
}

export async function getMaintenanceCost(params, signal) {
  const { data } = await axios.get(`/reports/maintanance-cost`, { params, signal });
  return data;
}

export async function getCost(id, params, signal) {
  const { data } = await axios.get(`/reports/cost-by-type/${id}`, { params, signal });
  return data;
}

export async function getCustomerSalesSummary(params, signal) {
  const { data } = await axios.get(`/reports/accounts-receivable/sales-summary`, { params, signal });
  return data;
}

export async function getCustomerSalesDetail(params, signal) {
  const { data } = await axios.get(`/reports/accounts-receivable/sales-detail`, { params, signal });
  return data;
}

export async function getDrugAlcoholReport(params, signal) {
  const { data } = await axios.get(`/reports/drug-alcohol-report`, { params, signal });
  return data;
}
