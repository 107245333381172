import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';

const ConfirmOffer = ({ open, onClose, handleSubmit, offersCount, offerTo }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Bulk Offer to ${offerTo === 'driver' ? 'Vehicles' : 'Carriers'}`}
      $bgColor={palette.gray0}
      $minWidth='500px'
      $maxWidth='500px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Submit',
          className: 'me-2',
          onClick: () => handleSubmit(false),
        },
        {
          key: 'submit-and-watch',
          type: 'primary',
          title: 'Submit and Watch Bids',
          onClick: () => handleSubmit(true),
        },
      ]}
    >
      <div>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          You are submitting {offersCount} offer{offersCount > 1 ? 's' : ''}, please confirm to submit.
        </Typography>
      </div>
    </Modal>
  );
};

export default ConfirmOffer;
