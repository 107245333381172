import React, { useState } from 'react';
import './createChannel.css';
import CustomRadioButton from '../../CustomRadioButton';
import CheckBoxSelectBox from '../SelectBox/CheckSelectBox';

const CreateChannel = ({ setIsOpen }) => {
  const [isOnewayBroadcast, setisOnewayBroadcast] = useState(false);
  const [broadCastUser, setbroadCastUser] = useState([]);
  const [selectedUser, setselectedUser] = useState([]);
  const [channelName, setchannelName] = useState('');

  const handleSubmit = () => {};

  const handleBroadcastType = (e) => {
    if (e.target.value === 'one-way') {
      setisOnewayBroadcast(true);
    }
    if (e.target.value === 'two-way') {
      setisOnewayBroadcast(false);
    }
  };

  const users = [...new Set([])];

  const { customer } = JSON.parse(localStorage.getItem('user'));
  return (
    <div className='modalContainer' style={{ zIndex: 5 }}>
      <div className='addon-modal-area create-channel' style={{ width: '550px' }}>
        <div
          className='modal-footer-custom'
          style={{
            justifyContent: 'space-between',
            borderRadius: '8px 8px 0px 0px ',
          }}
        >
          <p className='channel-modal-heading'>Create Channel</p>
        </div>
        <div className='modal-body-custom'>
          <div className='channel-modal-filds'>
            <p className='channel-modal-subheading'>Group</p>
            <span className='input-group-text' id='basic-addon1'>
              {customer.dot}
            </span>
            <input
              name='Channel_Name'
              className='channel_name_input'
              type='text'
              value={channelName}
              onChange={(e) => setchannelName(e.target.value)}
              placeholder='Channel Name'
              aria-label='Channel_Name'
              aria-describedby='Channel_Name'
            />
            <p className='requiredMessage' />
          </div>
          <p className='channel-modal-filds channel-modal-subheading' style={{ marginTop: '30px' }}>
            Type
          </p>

          <div className='channel-modal-filds radio'>
            <CustomRadioButton
              field={{
                name: 'broadcastType',
                value: 'one-way',
                onChange: handleBroadcastType,
                // defaultChecked: !menuStyleLocal
              }}
            />
            <p className='channel-modal-subheading'>Broadcast Only</p>
            {isOnewayBroadcast && (
              <div style={{ marginLeft: '25px' }}>
                <CheckBoxSelectBox
                  options={users}
                  setvalues={setbroadCastUser}
                  values={broadCastUser}
                  placeholder='Users selected that can broadcast'
                  defaultValue='people can broadcast only'
                />
              </div>
            )}
          </div>

          <div className='channel-modal-filds radio' style={{ marginBottom: '20px' }}>
            <CustomRadioButton
              field={{
                name: 'broadcastType',
                value: 'two-way',
                onChange: handleBroadcastType,
                // defaultChecked: !menuStyleLocal
              }}
            />
            <p className='channel-modal-subheading'>Two-Way Communication</p>
            <p className='channel-modal-subheading' style={{ marginTop: '25px' }}>
              Select Users from Group {isOnewayBroadcast ? '(Listen Only)' : ' (Broadcast & Listen)'}
            </p>
            <CheckBoxSelectBox
              options={users}
              setvalues={setselectedUser}
              values={selectedUser}
              placeholder='Users selected '
              defaultValue='Select...'
            />
          </div>
        </div>
        <div className=' create-channel  modal-footer-custom addon-footer-btn '>
          <button className='cancel-btn' onClick={() => setIsOpen(false)}>
            Cancel
          </button>
          <button className='create-channel-btn' onClick={handleSubmit}>
            Create Channel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateChannel;
