import moment from 'moment/moment';
import { getManufacturedYear } from 'pages/AccidentsAndCrashes/AddAccidents/AddAccidents.data';

export const getInitialValues = ({ selectedVehicle, selectedTrailer, vehiclesList, trailersList }) => {
  return {
    vehicle_unit: selectedVehicle ? vehiclesList.find((el) => el.equipment_id === selectedVehicle.equipment_id) : null,
    vehicle_year: selectedVehicle?.year ? moment(selectedVehicle.year).format('YYYY') : '',
    vehicle_make: selectedVehicle?.make ? selectedVehicle.make : '',
    vehicle_owner: selectedVehicle?.equipment_ownership?.owner ? selectedVehicle.equipment_ownership.owner : '',
    vehicle_damage: '',
    trailer_unit: selectedTrailer ? trailersList.find((el) => el.equipment_id === selectedTrailer.equipment_id) : null,
    trailer_year:
      selectedTrailer?.year && selectedTrailer?.year !== 'Invalid date'
        ? Number(getManufacturedYear(selectedTrailer.year)) || ''
        : '',
    trailer_make: selectedTrailer?.make ? selectedTrailer.make : '',
    trailer_owner: selectedTrailer?.equipment_ownership?.owner ? selectedTrailer.equipment_ownership.owner : '',
    trailer_damage: '',
  };
};
