import React from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import LineChart from '../LineChart/LineChart';
import './ShipmentActivity.css';

const ShipmentActivity = ({ driverShipmentData }) => {
  const { use } = useTheme();

  return (
    <div
      className='shipment-activity-wrapper driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div
        className='shipment-activity-header-container'
        style={{ borderColor: use(palette.gray50, palette.darkborder) }}
      >
        <h2 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Shipment Activity
        </h2>
        <div className='select-month-container'>
          <div className='month-container'>
            <div className='bg-color--months-last' />
            <p className='month' style={{ color: use(palette.gray700, palette.gray200) }}>
              Last Month
            </p>
          </div>
          <div className='month-container'>
            <div className='bg-color--months' />
            <p className='month' style={{ color: use(palette.gray700, palette.gray200) }}>
              This Month
            </p>
          </div>
        </div>
      </div>
      <div className='lineChart-wrapper'>
        <LineChart driverShipmentData={driverShipmentData} />
      </div>
    </div>
  );
};

export default ShipmentActivity;
