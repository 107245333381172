import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import ExportCsv from 'componentsV2/ExportCsv';
import { Typography } from 'components/Typography';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomModal from 'components/CustomModal/CustomModal';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { customerStopPoint, getBillToBillingCharges } from 'Api/Planner';

import { GetEquipmentTrailer, GetEquipmentVehicle, getInventoryTab, getTrailerStatusesWithCounts } from 'Api/StopPoint';
import {
  onChangeInventoryTab,
  onInventoryDateRangeChange,
  onInventoryRangeNameChange,
} from 'store/reducers/stopPoint.reducer';
import EquipmentUpdateModal from 'components/EquipmentStopPoint/EquipmentUpdateModal/EquipmentUpdateModal';
import EquipmentLogs from 'pages/landing/Equipment/EquipmentProfile/EquipmentLogs';
import { useColumns } from 'components/StopPoint/Walmart/steps/Inventory/Inventory.data';
import { cargoOnboardStatuses } from 'components/StopPoint/StopPoint.data';
import CheckoutTrailer from 'components/TableShipments/detailsRow/steps/Detention/CheckoutTrailer';
import TableFilters from '../../TableFilters';
import styles from './Inventory.module.css';
import AddTabModal from './AddTab';

const Inventory = () => {
  const { id } = useParams();
  const controller = useRef(new AbortController());
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearch = searchParams.get('search');
  const dispatch = useDispatch();
  const { inventoryEquipmentType, inventoryDateRange } = useSelector((state) => state?.stopPoints);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(initialSearch || '');
  const debouncedSearch = useDebounce(search, 500);
  const [showModal, setShowModal] = useState(false);
  const [loadTabs, setLoadTabs] = useState();
  const [tabModalEditData, setTabModalEditData] = useState({});
  const [customers, setCustomers] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [sort, setSort] = useState({ field: 'equipment_id', subField: '', sortBy: 'desc' });
  const [vehicleCustomTabs, setVehicleCustomTabs] = useState([]);
  const [trailerCustomTabs, setTrailerCustomTabs] = useState([]);
  const [activeSubTab, setActiveSubTab] = useState(null);
  const [equipmentLogsData, setEquipmentLogsData] = useState(null);
  const [cargoOnboardStatusesCounts, setCargoOnboardStatusesCounts] = useState([]);
  const [trailerToCheckout, setTrailerToCheckout] = useState(null);
  const [updateModal, setUpdateModal] = useState({
    visible: false,
  });
  const showToaster = useShowToaster();

  const [selectedFilters, setSelectedFilters] = useState({
    tab: inventoryEquipmentType === 1 ? 0 : 1,
    query: '',
    page: 1,
    history: 0,
    page_size: 10,
    drivers: [],
    equipment_types: [],
    cargo_onboard_id: [],
    groups: [],
  });
  const [filter, setFilter] = useState({
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    filterTableTop: [],
    selectedValues: [],
    applyAllUsersModal: { value: '0' },
  });
  const [vehicles, setVehicles] = useState(null);

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction, subField: '' });
  };

  async function getVehiclesData(filters) {
    setLoading(true);
    const {
      page,
      page_size,
      tab,
      query,
      history,
      equipment_type,
      customer_id,
      first_stop,
      second_stop,
      drivers,
      equipment_types,
      groups,
      ...rest
    } = filters || {};
    const sortField = `sort${!sort.subField ? `[]` : ''}[${sort.field}]${sort.subField ? `[${sort.subField}]` : ''}`;

    try {
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const params = {
        page,
        history: 0,
        page_size,
        stop_point_id: id,
        customer_id,
        first_stop,
        second_stop,
        drivers: drivers?.length && inventoryEquipmentType !== 2 ? drivers.map((i) => i.id) : undefined,
        equipment_types: equipment_types?.length ? equipment_types.map((i) => i.id) : undefined,
        groups: groups?.length ? groups.map((i) => i.id) : undefined,
        from: inventoryDateRange.start ? moment(inventoryDateRange.start).format('YYYY-MM-DD') : undefined,
        to: inventoryDateRange.end ? moment(inventoryDateRange.end).format('YYYY-MM-DD') : undefined,
        [sortField]: sort.sortBy,
        ...rest,
        cargo_onboard_id: inventoryEquipmentType === 2 ? selectedFilters.cargo_onboard_id : undefined,
        query: debouncedSearch || undefined,
      };

      const vehicles =
        inventoryEquipmentType === 2
          ? await GetEquipmentTrailer(params, undefined, controller.current?.signal)
          : await GetEquipmentVehicle(params, undefined, controller.current?.signal);
      setVehicles(vehicles);

      setLoading(false);
    } catch (e) {
      if (e.name === 'AbortError') {
        // Ignore abort errors
      } else {
        setLoading(false);
      }
    }
  }

  const onChangeTab = (n) => {
    dispatch(onChangeInventoryTab(n.vehicleOrTrailer));

    setSort({ field: 'equipment_id', subField: '', sortBy: 'desc' });
    setActiveSubTab(null);
    setSelectedFilters({
      ...selectedFilters,
      tab: +n.value,
      page: 1,
      equipment_type: n.vehicleOrTrailer,
      customer_id: null,
      first_stop: null,
      second_stop: null,
    });
  };

  const onCustomTabChange = (n) => {
    dispatch(onChangeInventoryTab(n.vehicleOrTrailer));
    setActiveSubTab(n.value);
    setSort({ field: 'equipment_id', subField: '', sortBy: 'desc' });
    setSelectedFilters((prevState) => ({
      ...prevState,
      page: 1,
      equipment_type: n.vehicleOrTrailer,
      customer_id: n.customer_id,
      first_stop: n.first_stop,
      second_stop: n.second_stop,
      equipment_types: [],
      drivers: [],
      groups: [],
    }));
  };

  const onPageChange = (event, page) => {
    setSelectedFilters({ ...selectedFilters, page });
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const PaginationComponent = (event) => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.page_size}
      totalCount={vehicles?.total}
      totalLength={vehicles?.data?.length}
      lastPage={vehicles?.last_page}
      currentPage={vehicles?.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  function filterTableColumn(columns) {
    if (inventoryEquipmentType === 1) {
      return columns.map((col) => {
        if (col.field === 'cargo_onboard') {
          return { ...col, hidden: true };
        }
        return col;
      });
    }
    return columns;
  }

  useEffect(() => {
    getVehiclesData(selectedFilters).then();

    return () => {
      controller.current?.abort();
    };
  }, [selectedFilters, debouncedSearch, inventoryDateRange, sort]);

  useEffect(() => {
    if (initialSearch) {
      searchParams.delete('search');
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  useEffect(() => {
    getInventoryTab({ stop_point_id: id, type: 'inventory' }).then((res) => {
      const rawData = res.data;
      const systemOneItems = [];
      const vehiclTabs = [];
      const trailerTabs = [];

      rawData.forEach((item, index) => {
        const newItem = {
          ...item,
          key: item.name,
          vehicleOrTrailer: item.equipment_type,
          label: item.name,
          value: index,
          id: index,
          tabId: item.id,
          count: item.system
            ? item.count
            : `(${item.count} of ${item.max_quantity}) ${item.count - item.max_quantity} `,
        };

        if (item.system === 1) {
          systemOneItems.push(newItem);
        } else if (item.system === 0 && item.equipment_type === 1) {
          vehiclTabs.push(newItem);
        } else {
          trailerTabs.push(newItem);
        }
      });

      setFilter((prevState) => {
        return {
          ...prevState,
          filterTableTop: systemOneItems,
        };
      });

      setVehicleCustomTabs(vehiclTabs);
      setTrailerCustomTabs(trailerTabs);
    });
    onChangeTab({ value: inventoryEquipmentType === 1 ? 0 : 1, vehicleOrTrailer: inventoryEquipmentType });
  }, [loadTabs, inventoryEquipmentType]);

  const getCargoOnboardStatuses = async () => {
    try {
      const { data } = await getTrailerStatusesWithCounts({
        history: 0,
        stop_point_id: id,
      });
      setCargoOnboardStatusesCounts(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getFilterCustomerData();
    getFilterStopPointData();
    getCargoOnboardStatuses();
  }, []);

  const getFilterCustomerData = useCallback(() => {
    getBillToBillingCharges()
      .then((res) => {
        const newCustomers = res.data.map((el) => ({
          id: el.id,
          title: el.company_name,
          label: el.company_name,
          key: el.id,
        }));
        setCustomers(newCustomers);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterStopPointData = useCallback(() => {
    customerStopPoint()
      .then((res) => {
        if (res && res?.data) {
          const stopPoints = res.data.map((el) => ({
            id: el.id,
            key: el.id,
            label: el?.location_name,
          }));
          setStopPoints([...stopPoints]);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const onEditTableTab = useCallback((item) => {
    const { label, vehicleOrTrailer, customer_id, max_quantity, first_stop, second_stop, alert, tabId } = item;
    const initialValue = {
      name: label,
      count: max_quantity,
      equipment_type: vehicleOrTrailer,
      customer: customer_id,
      first_stop,
      second_stop,
      alert,
      tabId,
    };
    setTabModalEditData(initialValue);
    setShowModal(true);
  }, []);

  const onClickModalButtons = (isVisible, reload) => {
    setTabModalEditData({});
    setShowModal(isVisible);
    if (reload) {
      setLoadTabs(new Date());
      onChangeTab({ value: inventoryEquipmentType === 1 ? 0 : 1, vehicleOrTrailer: inventoryEquipmentType });
    }
  };

  const columns = useColumns({
    sort,
    sortingQuery,
    setEquipmentLogsData,
    setTrailerToCheckout,
    onUpdateTrailer: (rowData) => setUpdateModal({ visible: true, ...rowData }),
  });

  return (
    <div className={styles.workingHours_wrapper}>
      <div className={styles.workingHours_information}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 28,
            gap: '16px',
            padding: '0 20px',
          }}
        >
          <div className='d-flex align-items-center gap-3'>
            <TableFilters
              dateRange={inventoryDateRange}
              setDateRange={(val) => dispatch(onInventoryDateRangeChange(val))}
              onRangeNameChange={(val) => dispatch(onInventoryRangeNameChange(val))}
              search={search}
              setSearch={setSearch}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <ExportCsv
              type='stop-point-inventory'
              exportParams={{ stop_point_id: id, equipment_type: inventoryEquipmentType === 1 ? 'vehicle' : 'trailer' }}
            />
          </div>
          {inventoryEquipmentType === 2 && (
            <div className='d-flex flex-column gap-1'>
              <Typography variant='s2' style={{ marginLeft: '10px' }}>
                Cargo Onboard
              </Typography>
              <div className='d-flex align-items-center gap-2'>
                {cargoOnboardStatuses
                  .filter((i) => Object.keys(cargoOnboardStatusesCounts)?.some((id) => id === i.id))
                  .map((status) => (
                    <div
                      key={status.id}
                      className={styles.onboard_statuses}
                      onClick={() =>
                        setSelectedFilters((prevState) => ({ ...prevState, cargo_onboard_id: [status.id] }))
                      }
                    >
                      <div className='d-flex align-items-center gap-1'>
                        {status.icon}
                        <Typography variant='b2' style={{ color: palette.gray700 }}>
                          {status.label || '-'}
                        </Typography>
                        <Chip
                          disableRipple
                          key={status.id}
                          className={styles.status_counts}
                          label={cargoOnboardStatusesCounts[status.id]}
                          fontSize='10px'
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <SwitchRadio
          color
          name='tableTopTabMenu'
          items={[...filter.filterTableTop]}
          value={selectedFilters.tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
        <hr className='m-0' />
        <div className='ms-4 mb-2'>
          <SwitchRadio
            color
            name='tableTopTabMenu'
            items={inventoryEquipmentType === 1 ? vehicleCustomTabs : trailerCustomTabs}
            value={activeSubTab}
            type='tab'
            onChange={onCustomTabChange}
            plus
            onClickPlus={() => setShowModal(true)}
            onEdit={onEditTableTab}
          />
        </div>
        {loading ? (
          <Preloader />
        ) : (
          <MaterialTableWrapper
            data={vehicles?.data}
            rowPerPage={selectedFilters.page_size}
            components={{
              Pagination: PaginationComponent,
            }}
            style={{ backgroundColor: palette.white }}
            columns={filterTableColumn(columns)}
            onRowClick={(e, rowData) => {
              if (inventoryEquipmentType === 1) {
                navigate(`/equipment-profile/vehicle/${rowData.equipment.id}`);
              } else {
                navigate(`/equipment-profile/trailer/${rowData.equipment.id}`);
              }
            }}
          />
        )}
      </div>
      <CustomModal
        className={styles.supportModal}
        showModal={updateModal.visible}
        styleBody={{ width: 1050, backgroundColor: '#F7F9FC', padding: '0 20px !important' }}
        onHide={() => setUpdateModal({ visible: false })}
        headerTitle={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Update {updateModal?.equipment?.equipment_type?.vehicle_type_id === 1 ? 'Vehicle' : 'Trailer'}{' '}
              {updateModal?.equipment?.equipment_id}
            </span>
            <DeleteIcon
              height={15}
              width={15}
              style={{ cursor: 'pointer' }}
              fill={palette.red500}
              onClick={() => setUpdateModal({ visible: false })}
            />
          </div>
        }
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <EquipmentUpdateModal
          data={updateModal}
          onSuccess={(data) => {
            setUpdateModal((prevState) => ({ ...prevState, cargo_onboard_id: data.cargo_onboard_id }));
            showToaster({ type: 'success', message: 'Trailer status has been successfully updated!' });
            setLoadTabs(new Date());
            getCargoOnboardStatuses();
          }}
          onClose={() => setUpdateModal({ visible: false })}
        />
      </CustomModal>

      <CustomModal
        footer={false}
        filter={filter}
        showModal={showModal}
        headerTitle='Add Tab'
        styleBody={{ width: 446 }}
      >
        <AddTabModal
          equipmentType={inventoryEquipmentType}
          filter={filter}
          customers={customers}
          type='inventory'
          stopPointsData={stopPoints}
          initialValue={tabModalEditData}
          onClickButtons={onClickModalButtons}
        />
      </CustomModal>
      {!!equipmentLogsData && (
        <EquipmentLogs
          open={!!equipmentLogsData}
          profileData={equipmentLogsData}
          onClose={() => setEquipmentLogsData(null)}
        />
      )}
      {!!trailerToCheckout && (
        <CheckoutTrailer
          open={!!trailerToCheckout}
          onClose={() => setTrailerToCheckout(null)}
          data={trailerToCheckout}
          onSuccess={() => getVehiclesData(selectedFilters)}
        />
      )}
    </div>
  );
};
export default Inventory;
