import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { formatNumber, palette } from 'utils/constants';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import TotalOpenReceivablesChart from '../Charts/TotalOpenReceivablesChart';
import { SBoxWrapper } from '../../StatsSection.styles';

const TotalOpenReceivables = ({ openReceivables, onTitleClick, loading }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <span className='d-inline-flex align-items-center gap-1 pointer' onClick={onTitleClick}>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            TOTAL OPEN RECEIVABLES
          </Typography>
          <ArrowUp fill={palette.gray400} style={{ transform: 'rotate(90deg)', marginRight: '4px' }} />
        </span>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          TOTAL
        </Typography>
      </div>
      <div className='d-flex justify-content-between'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            {formatNumber(openReceivables?.count, 0)}
          </Typography>
        )}
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            {currency}
            {formatNumber(openReceivables?.amount)}
          </Typography>
        )}
      </div>
      <Divider />
      <div className='chart-wrapper'>
        <TotalOpenReceivablesChart monthsData={openReceivables?.monthlyStats} />
      </div>
    </SBoxWrapper>
  );
};

export default TotalOpenReceivables;
