import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import './DriversListStyle.css';
import Popover from '@mui/material/Popover';

import Pagination from 'common/Pagination';
import DriverHeader from 'components/DriverHeader';
import CustomizedSnackbars from 'components/toast/Toast';
import ConfirmationModal from 'common/ConfirmationModal';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import TabUpdateModal from 'components/AddTabModal/AddTabModal';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import AlertMsg from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { TableContext } from 'context/tableContext';
import { deleteTab, getDrivers, getTabs, hourOfServiceAll } from 'Api/Driver';
import { getBillToBillingCharges, getPlannerPopoverSettings } from 'Api/Planner';
import { initialFilters, useColumns } from './DriversList.data';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';

const DriversTableList = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [driverData, setDriverData] = useState({ data: [] });
  const [UpdateModalShow, setUpdateModalShow] = useState(false);
  const [AddModalShow, setAddModalShow] = useState(false);
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [createModalCLose, setCreateModalCLose] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [, setHourService] = useState([]);
  const [removeTabModalOpen, setRemoveTabModalOpen] = useState(null);
  const [removeTabloading, setRemoveTabloading] = useState(null);
  const [profileAlert, setProfileAlert] = useState(null);
  const [alertData, setAlertData] = useState();
  const [filterTableTop, setFilterTableTop] = useState([]);
  const [tabModalEditData, setTabModalEditData] = useState({});
  const [search, setSearch] = useState('');

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const locationInitialValue = {
    city: { searchValue: '', value: 'City', key: uuid() },
    state: { searchValue: '', value: 'State', key: uuid() },
    zipCode: { searchValue: '', value: 'Postcode', key: uuid() },
    miles: { searchValue: '', value: 'Radius', key: uuid() },
  };
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'Active', value: 'active' },
    searchValue: '',
    allValue: '',
    selectedValues: [],
    locations: [],
    origin_location: locationInitialValue,
    destination_location: locationInitialValue,
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: switchRadioButtons?.amount || '1',
      date: switchRadioButtons?.date || '1',
      time: switchRadioButtons?.time || '1',
    },
    forAll: false,
    planerHeaderSwitch: { planerSwitch: '0' },
    applyAllUsersModal: { value: '0' },
  });
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});

  const sortingQuery = (field) => {
    const direction = selectedFilters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSelectedFilters((prevState) => ({ ...prevState, sort: { field, sortBy: direction } }));
  };

  const updateOnCreate = (value) => {
    setCreateModalCLose(value);
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const ProfileAlerthandleClick = (e, alerts) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
  };
  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  const ProfileAlertOpen = Boolean(profileAlert);
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;
  const navigate = useNavigate();

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col });
    });
    return cols;
  };

  const mapperSettingsData = (key) => {
    const map = {
      phone_number: 'Phone Number',
      groups: 'Tags',
      equipment: 'Equipment',
      license_type: 'License',
      driver_next_available_location: 'Next Available',
      driver_not_available_until_date: 'Next Available Date/Time',
      driver_compensation: 'Compensation',
      driver_type: 'Type',
      fname: 'Name',
      status: 'Status',
      drive_remaining: 'Driver Remaining',
      shift_remaining: 'Shift Remaining',
      time_until_break: 'Time Until Break',
      cycle_remaining_duration: 'Cycle Remaining',
      duty_status: 'Duty Status',
      driver_id: 'Driver ID',
      hour_of_service: 'Hours of Service',
    };
    return map[key] ? map[key] : key;
  };

  const getSettings = () => {
    getPlannerPopoverSettings({ type: 'driver' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });
          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
          setFilter({
            ...filter,
            switchRadioButtons: {
              date,
              amount,
              time,
            },
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  function onSearchLocations(locations) {
    if (locations.length > 0) {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      const index = filtered.findIndex((el) => Array.isArray(el));
      const locationIndex = locations[0].valuePrefix === 'origin_location' ? index : index + 1;
      filtered.splice(locationIndex, 0, [...locations]);
      setShowHeaderFilters([...filtered]);
    } else {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      setShowHeaderFilters([...filtered]);
    }
  }

  function onChangeMultiselectSearchBy(data) {
    const origin = showHeaderFilters.map((el) => el?.value === 'scheduled_origin');
    const destination = showHeaderFilters.find((el) => el?.value === 'scheduled_destination');
    if ((data.value === 'scheduled_origin' || data.value === 'scheduled_destination') && (!origin || !destination)) {
      const updatedData = { ...filter, searchBy: data };
      updateFilter(updatedData);
    } else {
      const values = showHeaderFilters.map((el) => el.value);

      const updateData = { ...filter, searchBy: data };
      if (data.value === 'all' && !values.includes(data.value)) updateData.allValue = '';
      if (data.value === 'name' && !values.includes(data.value)) updateData.allValue = '';
      updateFilter(updateData);
    }
  }

  const multiSelectOptionsDriver = useMemo(() => {
    return {
      title: 'All',
      id: 999,
      value: 'all',
      key: 9999,
      submenu: [
        {
          title: 'Active',
          id: 1,
          value: 'active',
          key: 1,
        },
        {
          title: 'View Inactive',
          id: 2,
          value: 'viewInactive',
          key: 2,
        },
      ],
    };
  }, []);

  const getFilterCustomerData = () => {
    getBillToBillingCharges().then((res) => {
      const newCustomers = res.data.map((el) => ({ id: el.id, title: el.company_name }));
      setCustomers(newCustomers);
    });
  };

  useEffect(() => {
    if (filter.searchBy?.value === 'customers') {
      getFilterCustomerData();
    }
  }, [filter.searchBy]);

  const inserthourOfService = (drivers, services) => {
    return drivers.map((driver) => {
      const getSeriveById = services.find((service) => service.driver_id === driver.id) || {};
      return {
        ...getSeriveById,
        ...driver,
      };
    });
  };

  const onChangeTab = (val) => {
    setLoading(true);
    const { value } = val;
    setTab(value);
  };

  const remove = () => {
    const { tabId, value } = removeTabModalOpen;
    const getTabIdIndex = filterTableTop.findIndex((tab) => tab.value === value);
    setRemoveTabloading(true);
    if (getTabIdIndex >= 0) {
      deleteTab({ tab: tabId })
        .then(() => {
          onSuccess('Tab has been deleted successfully');
          const tempTabs = [...filterTableTop];
          tempTabs.splice(getTabIdIndex, 1);
          setFilterTableTop(tempTabs);
          setRemoveTabModalOpen(null);
          setRemoveTabloading(false);
          setUpdateModalShow(false);
        })
        .catch((error) => {
          setRemoveTabloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  function onEditTableTab(item) {
    const { label, filters, tabId, system, value, key, count } = item;
    const initialValue = {
      label,
      key,
      filters,
      tabId,
      system,
      value,
      count,
    };
    setTabModalEditData(initialValue);
    setUpdateModalShow(true);
  }

  const addTabs = () => {
    getTabs('driver').then((response) => {
      if (filter.searchBy.title === 'Active') {
        const terminatedIndex = response?.data?.findIndex((val) => val.data?.name === 'Terminated' && val.system === 1);
        if (terminatedIndex >= 0) {
          response?.data?.splice(terminatedIndex, 1);
        }
        const quitIndex = response?.data?.findIndex((val) => val.data?.name === 'Quit' && val.system === 1);

        if (quitIndex >= 0) {
          response?.data?.splice(quitIndex, 1);
        }
      } else if (filter.searchBy.title === 'View Inactive') {
        const availableIndex = response?.data?.findIndex((val) => val.data?.name === 'Available' && val.system === 1);
        if (availableIndex >= 0) {
          response?.data?.splice(availableIndex, 1);
        }
        const notAvailableIndex = response?.data?.findIndex(
          (val) => val.data?.name === 'Not Available' && val.system === 1
        );
        if (notAvailableIndex >= 0) {
          response?.data?.splice(notAvailableIndex, 1);
        }
        const outOfServiceIndex = response?.data?.findIndex(
          (val) => val.data?.name === 'Out of Service' && val.system === 1
        );
        if (outOfServiceIndex >= 0) {
          response?.data?.splice(outOfServiceIndex, 1);
        }
      }
      let filterTableTopTemp = [filterTableTop[0]];

      filterTableTopTemp = [
        ...filterTableTopTemp,
        ...(response.data || []).map((tab, index) => ({
          user_id: tab.user_id,
          key: tab.data.name,
          label: tab.data.name,
          value: index + 1,
          count: tab.count,
          filters: tab.data.filters,
          tabId: tab.id,
          system: tab.system,
        })),
      ];
      setFilterTableTop([...filterTableTopTemp]);
    });
  };

  const handleClickOpen = (selectedTab) => {
    setRemoveTabModalOpen(selectedTab);
  };

  const getDriversList = async (data) => {
    const { advancedFilter = {} } = data || {};
    try {
      const selectedTab = filterTableTop.find((i) => i.value === tab);
      const sortField = `sort[${selectedFilters.sort.nested_field || ''}][${selectedFilters.sort.field}]`;
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        driver_name: selectedFilters.search || undefined,
        status_id: selectedTab?.filters?.status?.length
          ? selectedTab.filters.status.toString()
          : filter?.searchBy?.title === 'Active'
          ? '1,2,3,4'
          : filter?.searchBy?.title === 'View Inactive'
          ? '5,6'
          : '',
        group_id: selectedTab?.filters?.group_id?.length ? selectedTab.filters.group_id.toString() : undefined,
        [sortField]: selectedFilters.sort.sortBy,
        ...advancedFilter,
        canTravelIds: undefined,
        approvedForId: undefined,
      };

      const responses = await Promise.allSettled([getDrivers(params), getTabs('driver'), hourOfServiceAll()]);
      let tableTop = filterTableTop[0] ? [filterTableTop[0]] : [{ key: 'all', label: 'All', value: 0, count: 0 }];
      if (responses[0].status === 'fulfilled') {
        setDriverData({
          ...responses[0].value,
          data: inserthourOfService(responses[0].value.data || [], responses[2]?.value?.data || []),
        });
        if (tab === 0) {
          tableTop[0].count = responses[0].value.total;
        }
      }
      if (responses[1].status === 'fulfilled') {
        if (filter.searchBy.title === 'Active') {
          const terminatedIndex = responses[1]?.value.data?.findIndex(
            (val) => val.data?.name === 'Terminated' && val.system === 1
          );
          if (terminatedIndex >= 0) {
            responses[1]?.value.data?.splice(terminatedIndex, 1);
          }
          const quitIndex = responses[1]?.value.data?.findIndex((val) => val.data?.name === 'Quit' && val.system === 1);
          if (quitIndex >= 0) {
            responses[1]?.value.data?.splice(quitIndex, 1);
          }
        } else if (filter.searchBy.title === 'View Inactive') {
          const availableIndex = responses[1]?.value.data?.findIndex(
            (val) => val.data?.name === 'Available' && val.system === 1
          );
          if (availableIndex >= 0) {
            responses[1]?.value.data?.splice(availableIndex, 1);
          }
          const notAvailableIndex = responses[1]?.value.data?.findIndex(
            (val) => val.data?.name === 'Not Available' && val.system === 1
          );
          if (notAvailableIndex >= 0) {
            responses[1]?.value.data?.splice(notAvailableIndex, 1);
          }
          const outOfServiceIndex = responses[1]?.value.data?.findIndex(
            (val) => val.data?.name === 'Out of Service' && val.system === 1
          );
          if (outOfServiceIndex >= 0) {
            responses[1]?.value.data?.splice(outOfServiceIndex, 1);
          }
        }
        tableTop = [
          ...tableTop,
          ...(responses[1].value.data || []).map((tab, index) => ({
            user_id: tab.user_id,
            key: tab.data.name,
            label: tab.data.name,
            value: index + 1,
            count: tab.count,
            filters: tab.data.filters,
            tabId: tab.id,
            system: tab.system,
          })),
        ];
      }
      if (responses[2].status === 'fulfilled') {
        setHourService(responses[2].value.data || []);
      }
      setFilterTableTop([...tableTop]);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (itemsPerPage) => {
    setLoading(true);
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage }));
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        if (key === 'canTravelIds' || key === 'approvedForId') {
          value.forEach((el) => {
            requestData[el.value] = 1;
          });
          requestData[key] = value.map((el) => el.value);
        } else if (key === 'driver_duty_status' || key === 'driver_compensation') {
          requestData[key] = value.map((el) => el.value).toString();
        } else if (key === 'license_type') {
          requestData[key] = value.map((el) => el.name).toString();
        } else if (key === 'driver_type') {
          requestData[key] = value.map((el) => el.value).toString();
        } else {
          requestData[key] = value.map((el) => el.id).toString();
        }
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const onSearch = (data) => {
    const clearAll = typeof data === 'object' && Object.keys(data).length === 0;
    const filterObject = data || advancedFilter;

    if (!search || clearAll) {
      delete filterObject.driver_name;
    } else {
      filterObject.driver_name = search;
    }

    getDriversList({ advancedFilter: { page: 1, ...convertFilterData(filterObject) } }).catch();
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  useEffect(() => {
    getDriversList();
  }, [selectedFilters, filter?.searchBy?.title, tab]);

  const columns = useColumns({
    sortingQuery,
    ProfileAlerthandleClick,
    sort: selectedFilters.sort,
  });

  return (
    <>
      <div className='mt-4 driver-list driversList-main-table'>
        <TableContext.Provider
          value={{
            filter,
            dragItem,
            switchRadioButtons,
            onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
            tableColumn,
            onsStTableColumn: (v) => setTableColumn(v),
            getSettings,
            customers,
            updateFilter,
            onChangeOrder: (items) => setDragItem(items),
            driverData: driverData?.data,
            createModalCLose,
            updateOnCreate,
            onSearchLocations,
            onSearchMultiDropDown: () => null,
            onCheckMultiselectData: () => null,
            onChangeMultiselectSearchBy,
            multiSelectOptionsDriver,
          }}
        >
          <AdvancedFilter
            filterType={filter.searchBy?.value}
            open={advancedOpen}
            onSearch={onSearch}
            setSearch={setSearch}
            filter={advancedFilter}
            setFilter={setAdvancedFilter}
          >
            {({ onSearchFilter }) => {
              return (
                <DriverHeader
                  search={search}
                  setSearch={setSearch}
                  getDrivers={getDriversList}
                  onSearch={onSearchFilter}
                  advancedOpen={advancedOpen}
                  setAdvancedOpen={setAdvancedOpen}
                />
              );
            }}
          </AdvancedFilter>
          <SwitchRadio
            name='tableTopTabMenu'
            items={[...(filterTableTop || [])]}
            value={tab}
            type='tab'
            onChange={(value) => onChangeTab(value)}
            plus
            onEdit={onEditTableTab}
            onClickPlus={() => setAddModalShow(true)}
          />
        </TableContext.Provider>
        {loading ? (
          <TablePreLoader />
        ) : (
          <div className='tableFixHead table-fixed-header-340'>
            <MaterialTableWrapper
              data={driverData.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: palette.white }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: driverData,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={filterTableColumn(columns)}
              onRowClick={(e, rowData) =>
                navigate(`/driver-profile/${rowData.id}/general`, { state: { from: 'drivers' } })
              }
            />
          </div>
        )}
        <Popover
          id={ProfileAlertContent}
          // anchorPosition={{ top: 299, left: 669 }}
          open={ProfileAlertOpen}
          anchorEl={profileAlert}
          onClose={ProfileAlerthandleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          style={{ marginLeft: '20px' }}
        >
          <AlertMsg alertData={alertData} driverAlert='Driver Alerts' />
        </Popover>
        {!!removeTabModalOpen && (
          <ConfirmationModal
            width='340px'
            open={!!removeTabModalOpen}
            onClose={() => setRemoveTabModalOpen(null)}
            headerTitle='Remove Tab'
            text='Are you sure you want to remove tab?'
            onConfirm={remove}
            buttonProps={{ title: 'Remove' }}
            disabled={removeTabloading}
          />
        )}
      </div>
      {!!UpdateModalShow && (
        <TabUpdateModal
          show={UpdateModalShow}
          onHide={() => setUpdateModalShow(false)}
          onSuccess={onSuccess}
          showError={showError}
          save={addTabs}
          initialValue={tabModalEditData}
          tabName='Update Tab'
          onClickCross={(e, selectedTab) => {
            handleClickOpen(selectedTab);
          }}
        />
      )}
      {!!AddModalShow && (
        <TabUpdateModal
          show={AddModalShow}
          onHide={() => setAddModalShow(false)}
          onSuccess={onSuccess}
          showError={showError}
          save={addTabs}
          tabName='Add Tab'
        />
      )}
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default DriversTableList;
