import React from 'react';
import { Typography } from 'components/Typography';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import { palette } from 'utils/constants';
import { SGradientBox, SWrapper } from 'components/RestrictedPage/RestrictedPage.styles';

const RepairShop = () => {
  return (
    <SWrapper>
      <SGradientBox />
      <div className='restriction-content-wrapper'>
        <img src={truckinLogo} alt='fallback-loading' width={80} height={80} />
        <Typography variant='s1' style={{ color: palette.gray700 }}>
          This feature is not yet available.
        </Typography>
      </div>
    </SWrapper>
  );
};

export default RepairShop;
