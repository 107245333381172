import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { palette, PERMISSIONS } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import {
  createCustomerDelayCode,
  deleteCustomersDelayCode,
  getCustomerDelayCode,
  updateCustomersDelayCode,
} from 'Api/Customers';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { getDelayReasonsList } from 'Api/CompanySettings';
import useShowToaster from 'hooks/useShowToaster';
import SettingIcon from 'assets/sidemanu/settings-20px.svg';
import ColumnHeader from 'common/ColumnHeader';
import Logs from 'componentsV2/Customer/EdiApi/Logs';
import Connections from 'componentsV2/Customer/EdiApi/Connections';
import RejectReasonCodes from 'componentsV2/Customer/EdiApi/RejectReasonCodes';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';
import { AppendToFormData } from '../CreateShipment/ShipmentStops/helpers/constants';
import MaterialTableWrapper from '../MaterialTableWrapper';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CreateShipment/helpers/CustomInput';
import { Typography } from '../Typography';
import sm from './DelayCodesTab.module.css';

const DelayCodesTab = () => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state?.root);
  const [toggle, setToggle] = useState(false);
  const [delayCodes, setDelayCodes] = useState();
  const [refetchIndex, setRefetchIndex] = useState(Date.now());
  const [reasons, setReasons] = useState();
  const [changedIndexes, setChangedIndexes] = useState([]);
  const showToaster = useShowToaster();
  const { id } = useParams();
  const formRef = useRef();
  const { use } = useTheme();
  const defaultValue = {
    reason_id: '',
    edi_api_code: '',
  };

  const titlesOption = useMemo(() => {
    return [
      { key: 1, label: 'Reason', width: '424px', required: true },
      { key: 2, label: 'EDI/API Code', width: '150px', required: true },
    ];
  }, []);

  const handleSelctChange = (index) => {
    if (!changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    }
  };

  const handleFieldChange = (index, fieldName, values, setChangedIndexes, changedIndexes) => (event) => {
    const newValue = event.target.value;
    const oldValue = values.delay_codes[index][fieldName];
    const isChanged = newValue !== oldValue;

    if (isChanged && !changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    } else if (!isChanged && changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
  };

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const onUpdate = (el, index, values, errors, getFieldHelpers, arrayHelpers) => {
    if (changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
    const reason = getFieldHelpers(`delay_codes[${index}][reason_id]`);
    const edi_api_code = getFieldHelpers(`delay_codes[${index}][edi_api_code]`);
    reason.setTouched('Required');
    edi_api_code.setTouched('Required');
    if (el?.id) {
      updateDelayCodeUpdate(el, values);
    } else {
      createDelayCodeUpdate(el, values, index, arrayHelpers);
    }
  };

  const updateDelayCodeUpdate = (el) => {
    const updateData = {
      edi_api_code: el.edi_api_code,
      reason_id: el.reason_id,
    };

    updateCustomersDelayCode(updateData, el.id).then((res) => {
      if (res && res.data) {
        showToaster({ type: 'success', message: 'Delay Reason Code updated successfully' });
      }
    });
  };

  const createDelayCodeUpdate = (el, values, index) => {
    const formData = new FormData();
    const updateData = AppendToFormData({}, values, index);
    const map = mapper(updateData, index);
    Object.keys(map).map((key) => formData.append(key, map[key]));
    createCustomerDelayCode(formData).then((res) => {
      if (res?.status === 200) {
        showToaster({ type: 'success', message: 'Delay Reason Code created successfully!' });
      }
    });
  };

  const mapper = (obj, index) => {
    const newData = {
      customer_id: id,
    };
    const namesMap = {
      [`delay_codes[${index}]reason_id`]: [`delay_codes[${index}][reason_id]`],
      [`delay_codes[${index}]edi_api_code`]: [`delay_codes[${index}][edi_api_code]`],
    };

    Object.keys(obj).forEach((key) => {
      const name = namesMap[key];
      newData[name] = obj[key];
    });

    return newData;
  };

  const onDelete = (el, index, arrayHelpers) => {
    if (el?.id) {
      deleteCustomersDelayCode(el.id).then(() => {
        arrayHelpers.remove(index);
        showToaster({ type: 'success', message: 'Delay Reason Code deleted successfully!' });
      });
    } else {
      arrayHelpers.remove(index);
    }
  };

  const onCancel = () => {
    setToggle(!toggle);
    setRefetchIndex(Date.now());
    setChangedIndexes([]);
  };

  useEffect(() => {
    getCustomerDelayCode({ id }).then((res) => {
      setDelayCodes({ delayCodes: res });
    });
    getDelayReasonsList().then((res) => {
      if (res && res?.data) {
        const options = res.data.map((el) => {
          return {
            ...el,
            key: el.id,
            label: el.title,
            labelSelected: null,
          };
        });
        setReasons(options);
      }
    });
  }, [refetchIndex]);

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = delayCodes?.delayCodes?.data?.length
    ? { delay_codes: delayCodes?.delayCodes?.data }
    : { delay_codes: [{ ...defaultValue }] };

  return (
    <div className='mb-4'>
      <div
        className={sm.delayCodesTab}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div
          className={sm['delayCodesTab-header']}
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderBottomColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Typography variant='h4' style={{ color: palette.gray900 }}>
            Delay Reason Codes
          </Typography>
          {permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) &&
            (!toggle ? (
              <div>
                <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
              </div>
            ) : (
              <div>
                <button onClick={onCancel} className='driver-compansation-cancel-btn'>
                  Close
                </button>
              </div>
            ))}
        </div>

        <div className={sm.container_wrapper}>
          {toggle ? (
            <div
              className={sm.container}
              style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
            >
              <div className={sm.body}>
                <Formik initialValues={initialValue} innerRef={formRef}>
                  {({ values, getFieldHelpers, errors }) => (
                    <Form>
                      <FieldArray
                        name='delay_codes'
                        render={(arrayHelpers) => {
                          return (
                            <div>
                              <div className={sm.row} style={{ justifyContent: 'flex-start', marginTop: 0 }}>
                                {titlesOption.map((el, index) => {
                                  return (
                                    <Typography
                                      key={el.key}
                                      variant='s2'
                                      style={{
                                        width: el.width,
                                        whiteSpace: 'nowrap',
                                        marginRight: 10,
                                      }}
                                    >
                                      {el.label}
                                      {el.required && <span className={sm.required_system}>*</span>}
                                      {index === 0 && (
                                        <img
                                          src={SettingIcon}
                                          alt=''
                                          className='ms-2'
                                          onClick={() =>
                                            navigate('/company-settings', {
                                              state: {
                                                tabId: 7,
                                                subTabName: 'DelayReasons',
                                              },
                                            })
                                          }
                                        />
                                      )}
                                    </Typography>
                                  );
                                })}
                              </div>

                              {values.delay_codes.map((el, index) => {
                                const namePrefix = `delay_codes[${index}]`;
                                return (
                                  <div
                                    key={namePrefix}
                                    className={[sm.row, sm.line].join(' ')}
                                    style={{ marginTop: 0, justifyContent: 'flex-start' }}
                                  >
                                    <div style={{ width: '30%', marginRight: 10, maxWidth: '400px' }}>
                                      <div>
                                        <Field
                                          name={`${namePrefix}[reason_id]`}
                                          options={reasons}
                                          component={CustomSelect}
                                          styles={styles.type}
                                          labelStyle={styles.labelStyle}
                                          validate={validateStopPointInput}
                                          onChange={(value) => {
                                            handleSelctChange(index);
                                            getFieldHelpers(`delay_codes[${index}][edi_api_code]`).setValue(value.code);
                                            getFieldHelpers(`delay_codes[${index}][reason_id]`).setValue(value);
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`${namePrefix}[reason_id]`}
                                          render={(error) => (
                                            <Typography variant='c2' style={styles.error}>
                                              {error}
                                            </Typography>
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className={sm.field_wrapper1}>
                                      <Field
                                        name={`${namePrefix}[edi_api_code]`}
                                        component={CustomInput}
                                        style={{ marginLeft: '24px', paddingLeft: '10px', maxWidth: '150px' }}
                                        labelStyle={styles.labelStyle}
                                        validate={validateStopPointInput}
                                        className={sm.input_basic_details}
                                        onChange={handleFieldChange(
                                          index,
                                          'edi_api_code',
                                          values,
                                          setChangedIndexes,
                                          changedIndexes
                                        )}
                                      />
                                      <ErrorMessage
                                        name={`${namePrefix}[edi_api_code]`}
                                        render={(error) => (
                                          <Typography variant='c2' style={styles.error}>
                                            {error}
                                          </Typography>
                                        )}
                                      />
                                    </div>

                                    {/* {values.delay_codes.length > 1 && index !== 0 ? */}
                                    <DeleteIcon
                                      width={12}
                                      height={12}
                                      fill={palette.indigo500}
                                      style={styles.deleteIcon}
                                      onClick={() => onDelete(el, index, arrayHelpers)}
                                    />
                                    {/*:*/}
                                    {/* <span style={{ width: 28 }}/> */}
                                    {/* } */}
                                    {!changedIndexes.includes(index) ? (
                                      <TickIcon
                                        fill={use(palette.green500, palette.white)}
                                        style={{ margin: '12px 0 0 0' }}
                                      />
                                    ) : (
                                      <CustomButton
                                        type='primary'
                                        title=''
                                        styleButton={{
                                          padding: '6px 12px',
                                          margin: '6px 0 0 0',
                                        }}
                                        styleTitle={{ fontSize: 14 }}
                                        onClick={() => onUpdate(el, index, values, errors, getFieldHelpers)}
                                        leftIcon={
                                          <TickIcon
                                            fill={use(palette.white, palette.white)}
                                            style={{ width: 9, height: 9 }}
                                          />
                                        }
                                        buttonProps={{
                                          type: 'submit',
                                        }}
                                      />
                                    )}
                                  </div>
                                );
                              })}

                              <CustomButton
                                type='secondary'
                                title='Add Another'
                                styleTitle={styles.addAnotherTitle}
                                styleButton={styles.addAnotherButton}
                                onClick={() => arrayHelpers.push({ ...defaultValue })}
                                leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                              />
                            </div>
                          );
                        }}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <div className={sm['table-wrap']}>
              <div className='sub-table-container'>
                <MaterialTableWrapper
                  columns={[
                    {
                      title: <ColumnHeader title='REASON' field='reason' />,
                      field: 'reason',
                      render: (row) => <Typography variant='b2'>{row.reason?.title}</Typography>,
                    },
                    {
                      title: <ColumnHeader title='CODE' field='edi_api_code' />,
                      field: 'edi_api_code',
                      render: (row) => {
                        return (
                          <p className={sm['list-text']}>
                            <span className={sm['list-styled-text']} style={{ margin: 0 }}>
                              {row.edi_api_code || '-'}
                            </span>
                          </p>
                        );
                      },
                    },
                  ]}
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray700, palette.gray200),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  data={delayCodes?.delayCodes?.data}
                  options={{
                    sorting: false,
                    toolbar: false,
                    paging: false,
                    draggable: false,
                    rowStyle: {
                      borderBottom: 'none',
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <RejectReasonCodes customerId={id} />
      <Connections customerId={id} />
      <Logs customerId={id} />
    </div>
  );
};

export default DelayCodesTab;
