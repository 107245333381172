import React, { useEffect, useState } from 'react';
import XIcon from 'assets/icons/x.png';

const PinnedMessage = ({ pinnedMessage, setpinnedMessage, activeChannel }) => {
  const [pinnedmes, setPinnedmes] = useState([]);

  useEffect(() => {
    const getpinnedMessage = async () => {
      const pinned = await activeChannel?.getPinnedMessages({ limit: 1000 });
      setPinnedmes(pinned);
    };
    getpinnedMessage();
  }, [pinnedMessage, activeChannel]);

  return (
    <div
      className='group_members'
      style={{
        width: pinnedMessage ? '25%' : '0px',
        padding: pinnedMessage ? '' : '0px',
      }}
    >
      <p className='group_member_heading'>
        Pinned Message
        <img src={XIcon} alt='close' onClick={() => setpinnedMessage(false)} />
      </p>
      {pinnedmes?.length !== 0 && (
        <div className='group_user_list'>
          {pinnedmes?.messages?.map((m) => (
            <div className='pinned-message-user' key={m.id}>
              <p className='pinned-by' style={{ fontSize: '10px', margin: '0px' }}>
                Pinned by {m.pinned_by.name}
              </p>

              <div>
                {m?.attachments.length !== 0 ? (
                  <a
                    href={m?.attachments[0].image_url}
                    target='_blank'
                    className='download-attechment'
                    rel='noopener noreferrer'
                    download
                  >
                    Download Attachment
                  </a>
                ) : (
                  <p className='scheduled_text'>{m.text}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PinnedMessage;
