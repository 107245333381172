import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STableRow = styled.tr`
  background-color: ${({ $matched }) => ($matched ? palette.green0 : '')};
  cursor: pointer;
  transition: background-color 0.2s;

  :hover {
    background-color: ${({ $matched }) => ($matched ? palette.green50 : palette.gray50)};
  }
`;

export const STableWrapper = styled.div`
  border: 1px solid ${palette.gray50};
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 16px;
  background-color: ${palette.gray0};

  table {
    margin-top: 8px;

    th {
      border-top: 1px solid ${palette.gray50};
    }

    td:first-child,
    th:first-child {
      width: 40px;
      padding: 8px;
    }
  }

  &.scrollable {
    padding-right: 0;

    .scrollable-box {
      padding-right: 8px;
      height: 300px;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      ::-webkit-scrollbar-thumb {
        background: #9e9e9e;
        border-radius: 8px;
      }
    }
  }
`;
