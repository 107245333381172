import React from 'react';
import sm from '../../BillingCharges/BillingCharges.module.css';
import BillingChargesLtlOneChargeHeader from './BillingChargesLtlOneChargeHeader';
import BillingChargesLtlOneChargeBody from './BillingChargesLtlOneChargeBody';

const BillingChargesLtlOneCharge = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  indexPrefix,
  namePrefix,
  setTouched,
  validateForm,
  valuesForm,
  onChangeDisabledNextStep,
  arrayHelpersPrefix,
}) => {
  return (
    <div className={sm.billingChargesLtlOneCharge}>
      <BillingChargesLtlOneChargeHeader
        charge={values}
        errors={errors}
        touched={touched}
        valuesForm={valuesForm}
        namePrefix={namePrefix}
        indexPrefix={indexPrefix}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        arrayHelpersPrefix={arrayHelpersPrefix}
      />
      <BillingChargesLtlOneChargeBody
        values={values}
        touched={touched}
        valuesForm={valuesForm}
        setTouched={setTouched}
        namePrefix={namePrefix}
        indexPrefix={indexPrefix}
        validateForm={validateForm}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        onChangeDisabledNextStep={onChangeDisabledNextStep}
      />
    </div>
  );
};

export default BillingChargesLtlOneCharge;
