import React from 'react';
import HeaderStar from 'components/HeaderStar';
import TimeTrackingTable from './components/TimeTrackingTable';
import { SHeaderWrapper, SPageWrapper } from './TimeTracking.styles';

const TimeTracking = () => {
  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Time Tracking' />
      </SHeaderWrapper>
      <TimeTrackingTable />
    </SPageWrapper>
  );
};

export default TimeTracking;
