import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import CustomModal from 'components/CustomModal/CustomModal';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin: 40px 0 20px;
`;

export const STableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const STitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SCustomModal = styled(CustomModal)`
  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '700px'};
    max-width: ${({ $maxWidth }) => $maxWidth || ''};
    height: ${({ $height }) => $height || 'auto'};

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: 100%;
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      overflow: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      border-bottom: 1px solid ${palette.gray50};
    }

    .customFooter {
      border-top: 1px solid ${palette.gray50};
    }
  }
`;

export const SUserInfo = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  .user-name-wrapper {
    display: inline-flex;
    flex-direction: column;
  }

  .user-name {
    color: ${palette.indigo500};
  }

  .user-name:hover {
    text-decoration: underline;
  }

  .user-type {
    font-size: 14px;
    color: ${palette.gray700};
  }

  .phone-icon {
    margin-left: 8px;

    svg path {
      fill: ${palette.indigo400};
    }
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .assign-user {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px dashed ${palette.indigo400};
    background-color: ${palette.indigo50};
    width: 40px;
    height: 40px;
  }
`;

export const SDotsWrapper = styled.div`
  cursor: pointer;

  .dots {
    display: inline-flex;
    width: 20px;
    height: 20px;
  }

  .popover-actions {
    list-style-type: none;
    background: #ffffff;
    box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 0;
  }
`;

export const SPopover = styled(Popover)`
  &.MuiPopover-root {
    .MuiPaper-root {
      margin-top: -12px;
      min-width: 150px;
    }

    .actions-wrapper {
      list-style-type: none;
      background: #ffffff;
      box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
        0 5px 15px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 6px 4px;

      li {
        text-transform: none;
        cursor: pointer;
        width: 100%;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        border-radius: 4px;

        &.delete-action {
          color: ${palette.red500};
        }

        :hover {
          background-color: ${palette.indigo0};
        }
      }
    }
  }
`;

export const SActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
`;

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 9999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;

export const SCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;

    .MuiSvgIcon-root {
      fill: ${palette.indigo500};
      fill: ${({ checked }) => (checked ? palette.indigo500 : palette.gray700)};
    }
  }
`;
