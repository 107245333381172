import React, { useEffect, useState } from 'react';
import moment from 'moment';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { useAuth } from 'context/auth.context';
import { getUserSettlements } from 'Api/Payroll';
import CompareGross from '../DetailsComponents/CompareGross';
import CompareTaxes from '../DetailsComponents/CompareTaxes';
import CompareTakeHome from '../DetailsComponents/CompareTakeHome';
import CompareBenefits from '../DetailsComponents/CompareBenefits';
import CompareDeductions from '../DetailsComponents/CompareDeductions';

const Compare = ({ settlement }) => {
  const { value } = useAuth();
  const [settlements, setSettlements] = useState([]);
  const [selectedSettlement, setSelectedSettlement] = useState(null);
  const { pay_period_end } = settlement ?? {};

  const getSettlements = async () => {
    try {
      const { data } = await getUserSettlements({ user_type: 1, user_id: value.user.id });
      setSettlements(data.filter((item) => [3, 5].includes(item.status_id) && item.id !== settlement.id));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getSettlements();
  }, []);

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <div>
          <InputLabel>Primary Statement</InputLabel>
          <Typography variant='h5'>{moment(pay_period_end).format('MMM D, YYYY')}</Typography>
        </div>
        <div>
          <Autocomplete
            width='200px'
            label='Secondary Statement'
            value={selectedSettlement}
            onChange={(e, val) => setSelectedSettlement(val)}
            options={settlements}
            getOptionLabel={(option) => moment(option.pay_period_end).format('MMM D, YYYY')}
          />
        </div>
      </div>
      {selectedSettlement && (
        <div className='d-flex flex-column gap-2'>
          <CompareGross primarySettlement={settlement} secondarySettlement={selectedSettlement} />
          <CompareTaxes primarySettlement={settlement} secondarySettlement={selectedSettlement} />
          <CompareBenefits primarySettlement={settlement} secondarySettlement={selectedSettlement} />
          <CompareDeductions primarySettlement={settlement} secondarySettlement={selectedSettlement} />
          <CompareTakeHome primarySettlement={settlement} secondarySettlement={selectedSettlement} />
        </div>
      )}
    </div>
  );
};

export default Compare;
