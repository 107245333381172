import React, { useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette, PERMISSIONS } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getSecondaryApiKeys } from 'Api/CompanySettings';
import { getEdiSettings, updateEdiSettings } from 'Api/Customers';
import IncomingSettings from './components/IncomingSettings';
import OutgoingSettings from './components/OutgoingSettings';
import { validationSchema } from './validationSchema';
import { getInitialValues, initialValues } from './Connections.data';
import { SSection, SAddMore } from '../EdiApi.styles';

const Connections = ({ customerId }) => {
  const showToaster = useShowToaster();
  const { permissions } = useSelector((state) => state?.root);
  const [loading, setLoading] = useState(false);
  const [secondaryKeys, setSecondaryKeys] = useState([]);
  const readOnly =
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) ||
    permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_READ_ONLY.name);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        partners: values.map((item) => ({
          document_204: Number(item.document_204),
          document_214: Number(item.document_214),
          document_210: Number(item.document_210),
          send_vehicle_gps_positions: Number(item.send_vehicle_gps_positions),
          send_vehicle_arrives_at_pickup: Number(item.send_vehicle_arrives_at_pickup),
          send_vehicle_departs_pickup: Number(item.send_vehicle_departs_pickup),
          send_vehicle_arrives_at_delivery: Number(item.send_vehicle_arrives_at_delivery),
          send_vehicle_departs_delivery: Number(item.send_vehicle_departs_delivery),
          send_shipment_completed: Number(item.send_shipment_completed),
          send_outbound_data_to_edi: Number(item.send_outbound_data_to_edi),
          auto_approve_all_incoming_shipments: Number(item.auto_approve_all_incoming_shipments),
          secondary_api_key_id: item.secondary_api_key?.id || null,
        })),
      };

      await updateEdiSettings(customerId, body);
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues: [initialValues],
    onSubmit,
    validationSchema,
  });
  const { values, handleChange, handleSubmit, touchedErrors, setValues } = form;

  const getSettings = async () => {
    try {
      const { data } = await getEdiSettings(customerId);
      const { data: keys } = await getSecondaryApiKeys({
        not_assigned: 1,
        customer_id: customerId,
        itemsPerPage: 1000,
      });
      setSecondaryKeys(keys);

      const initialValues = getInitialValues(data, keys);
      setValues(initialValues);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddMore = () => {
    handleChange(`${values.length}`, { id: uuid(), ...initialValues });
  };

  useEffect(() => {
    getSettings();
  }, []);

  const filteredSecondaryKeys = useMemo(() => {
    return secondaryKeys.filter((item) => values.every((i) => i.secondary_api_key?.id !== item.id));
  }, [secondaryKeys, values]);

  if (
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) &&
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_READ_ONLY.name)
  ) {
    return null;
  }

  return (
    <SSection>
      <div className='section-header'>
        <Typography variant='h4' style={{ color: palette.gray900 }}>
          EDI/API Connections
        </Typography>
        {permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) && (
          <CustomButton
            styleTitle={{ fontSize: 14 }}
            styleButton={{ padding: '3px 12px', margin: 0 }}
            type='primary'
            title='Save'
            onClick={handleSubmit}
            disabled={loading}
          />
        )}
      </div>
      <div className='section-body'>
        <div>
          {values.map((item, index) => (
            <div className='setting-item' key={item.id}>
              <div className='d-flex justify-content-between align-items-center gap-2'>
                <div className='d-flex gap-5 mb-4'>
                  <div>
                    <Autocomplete
                      required
                      label='API Key'
                      width='250px'
                      labelKey='key_name'
                      placeholder='Trade Partner Key Name'
                      name={`${index}.secondary_api_key`}
                      options={filteredSecondaryKeys}
                      value={values[index].secondary_api_key}
                      onChange={(e, val) => handleChange(`${index}.secondary_api_key`, val)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      error={touchedErrors?.[index]?.secondary_api_key}
                      disabled={readOnly}
                    />
                  </div>
                  <div className='text-center'>
                    <InputLabel className='mb-1'>Load Tenders (204)</InputLabel>
                    <CustomCheckbox
                      type='switch'
                      name={`${index}.document_204`}
                      checked={!!values[index].document_204}
                      onChange={(checked) => handleChange(`${index}.document_204`, checked)}
                      smail={false}
                      disabled={readOnly}
                    />
                  </div>
                  <div className='text-center'>
                    <InputLabel className='mb-1'>Shipment Updates (214)</InputLabel>
                    <CustomCheckbox
                      type='switch'
                      name={`${index}.document_214`}
                      checked={!!values[index].document_214}
                      onChange={(checked) => handleChange(`${index}.document_214`, checked)}
                      smail={false}
                      disabled={readOnly}
                    />
                  </div>
                  <div className='text-center'>
                    <InputLabel className='mb-1'>Motor Carrier Freight Invoice (210)</InputLabel>
                    <CustomCheckbox
                      type='switch'
                      name={`${index}.document_210`}
                      checked={!!values[index].document_210}
                      onChange={(checked) => handleChange(`${index}.document_210`, checked)}
                      smail={false}
                      disabled={readOnly}
                    />
                  </div>
                </div>
                {!readOnly && (
                  <div className='action-wrapper' onClick={() => setValues(values.filter((_, i) => i !== index))}>
                    <DeleteIcon width={13} height={13} fill={palette.red500} />
                  </div>
                )}
              </div>
              {!!values[index].document_214 && (
                <div>
                  <OutgoingSettings form={form} readOnly={readOnly} index={index} />
                </div>
              )}
              <div>
                <IncomingSettings form={form} readOnly={readOnly} index={index} />
              </div>
            </div>
          ))}
        </div>
        {!readOnly && (
          <SAddMore onClick={onAddMore}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        )}
      </div>
    </SSection>
  );
};

export default Connections;
