import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import locationIcon from 'assets/icons/location.png';
import globeIcon from 'assets/icons/globe.png';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';

const getBarcode = (id, displayValue = true) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id, { displayValue });

  return canvas.toDataURL('image/jpeg');
};

const createTableHeader = (doc, top = 118) => {
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('ID', 16, top);
  doc.text('Hours Tracked', 24, top);
  doc.text('Start Time', 52, top);
  doc.text('Start Location', 74, top);
  doc.text('End Time', 116, top);
  doc.text('End Location', 138, top);
  doc.text('Amount', 181, top);
};

const createRow = (doc, data, index, userData, top = 124, isLastPage, isLastItem, dateFormat) => {
  const { compensation_details, totalHours, totalMinutes } = userData;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const y = top + index * 16;

  // const diff = data.start_time && data.end_time ? moment(data.end_time).diff(data.start_time) : null;
  // const diffInHours = diff / 1000 / 60 / 60;

  const amount =
    (Number(data.hours_tracked) + Number(data.minutes_tracked) / 60) * (compensation_details?.pay_per_hour || 0);

  doc.setFontSize(9);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${data.id}`, 16, y);
  doc.text(`${data.hours_tracked || 0}h ${data.minutes_tracked || 0}m`, 30, y);

  // Start time
  doc.text(`${dateFormat.formatDate(data.start_time)}`, 52, y);
  doc.text(`${dateFormat.formatTime(data.start_time)}`, 52, y + 4);

  // Start location
  if (data.start_time_street) {
    doc.text(`${data.start_time_street}`, 74, y);
    doc.text(`${data.start_time_city}, ${data.start_time_state_code}`, 74, y + 4);
    doc.text(`${data.start_time_country}`, 74, y + 8);
  } else {
    doc.text('-', 74, y);
  }

  // End time
  if (data.end_time) {
    doc.text(`${dateFormat.formatDate(data.end_time)}`, 116, y);
    doc.text(`${dateFormat.formatTime(data.end_time)}`, 116, y + 4);
  } else {
    doc.text('-', 116, y);
  }

  // End location
  if (data.end_time_street) {
    doc.text(`${data.end_time_street},`, 138, y);
    doc.text(`${data.end_time_city}, ${data.end_time_state_code},`, 138, y + 4);
    doc.text(`${data?.end_time_country}`, 138, y + 8);
  } else {
    doc.text('-', 138, y);
  }

  // Amount
  doc.text(`$${amount.toFixed(2)}`, 181, y);
  doc.setDrawColor(233, 237, 245);
  doc.line(16, y + 12, pageWidth - 16, y + 12);

  if (isLastPage && isLastItem) {
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.text(`${totalHours}h ${totalMinutes}m`, 28, y + 17);
    doc.text(
      `$${((totalHours + totalMinutes / 60) * Number(compensation_details?.pay_per_hour)).toFixed(2)}`,
      180,
      y + 17
    );
  }
};

const createFooter = (doc, company, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const { address1, city, state, zip, phone_number, email, company_website } = company || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.line(0, pageHeight - 26, pageWidth, pageHeight - 26);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 26, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 2, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4, pageHeight - 20, pageWidth / 4, pageHeight - 10);
    doc.line(pageWidth / 2, pageHeight - 20, pageWidth / 2, pageHeight - 10);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 13, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 6, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3);
    doc.text('Address', 18, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 11, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);
    doc.setFontSize(9);
    doc.setTextColor('#171C26');
    doc.text(`${address1}`, 13, pageHeight - 13);
    doc.text(`${city.name}, ${state.name} - ${zip}`, 13, pageHeight - 9, { maxWidth: 40 });
    doc.text(`${company_website || '-'}`, pageWidth / 4 + 6, pageHeight - 13);
    doc.text(`${email || '-'}`, pageWidth / 2 + 6, pageHeight - 13);
    doc.text(`${phone}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);
  }
};

export const generatePDF = (data, company, dateRange, dateFormat) => {
  if (!data) {
    return;
  }

  const { address1, city, state, zip, logo_path } = company || {};
  const {
    first_name,
    last_name,
    city_data,
    country_data,
    driver_zip,
    driver_address,
    country: staff_country,
    city: staff_city,
    address1: staff_address,
    address2: staff_address2,
    zip: staff_zip,
  } = data || {};

  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.addImage(`${logo_path}?cache=${Math.random()}`, 'JPEG', 16, 16, 60, 12);
  doc.setFontSize(12);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#171C26');
  doc.text(address1, 16, 38);
  doc.text(`${city.name}, ${state.name} ${zip}`, 16, 43);

  doc.text(`${first_name} ${last_name}`, 16, 54);
  if (driver_address || staff_address) {
    doc.text(`${driver_address || staff_address} ${staff_address2 ? `, ${staff_address2}` : ''}`, 16, 59);
  }
  doc.text(
    `${(city_data || staff_city)?.name ? `${(city_data || staff_city).name},` : ''} ${
      (country_data || staff_country)?.name ? `${(country_data || staff_country).name} -` : ''
    } ${driver_zip || staff_zip || ''}`,
    16,
    64
  );

  if ((driver_zip || staff_zip)?.length === 5 && /^\d+$/.test(driver_zip || staff_zip)) {
    doc.addImage(
      `https://bwipjs-api.metafloor.com/?bcid=postnet&text=${driver_zip || staff_zip}`,
      'JPEG',
      16,
      68,
      35,
      8
    );
  } else if (driver_zip || staff_zip) {
    doc.addImage(getBarcode(driver_zip || staff_zip, false), 'JPEG', 15, 66, 40, 12);
  }

  doc.setDrawColor(188, 194, 206);
  doc.line(16, 84, pageWidth - 16, 84);
  doc.line(16, 84.1, pageWidth - 16, 84.1);
  doc.setFontSize(24);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Time Tracking Statement', 16, 94);
  doc.setFontSize(10);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);
  doc.text('This time tracking history statement shows your clock in and out data along with location data.', 16, 100);
  doc.setFontSize(11);
  doc.setTextColor('#4F5AED');
  doc.setFont('Inter', 'normal', 400);
  doc.text('Hours Tracked', 16, 110);
  doc.line(16, 113, pageWidth - 16, 113);

  // Date span
  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 110, 16, pageWidth - (pageWidth - 94), 7, 'F');
  doc.setFontSize(11);
  doc.setTextColor('#FFFFFF');
  doc.text('Date Span', pageWidth - 106, 21);
  doc.setFontSize(12);
  doc.text(`${dateFormat.formatDate(dateRange.start)} to ${dateFormat.formatDate(dateRange.end)}`, pageWidth - 78, 21);
  doc.addImage(getBarcode(`${first_name} ${last_name}`, false), 'JPEG', pageWidth - 50, 24, 35, 15);

  createTableHeader(doc);

  if (data?.time_trackings?.length) {
    const firstPageData = data.time_trackings.slice(0, 9);
    const restData = data.time_trackings.slice(9);
    const totalPages = Math.ceil(restData.length / 14) + 1;
    createFooter(doc, company, 1, totalPages);

    firstPageData.forEach((item, index, arr) => {
      createRow(doc, item, index, data, 124, totalPages === 1, index === arr.length - 1, dateFormat);
    });

    if (restData.length) {
      for (let i = 0; i < totalPages - 1; i++) {
        const newArray = restData.slice(i * 14, i * 14 + 14);

        if (newArray.length > 0) {
          doc.addPage();
          doc.line(16, 16, pageWidth - 16, 16);
          createTableHeader(doc, 20);
          createFooter(doc, company, i + 2, totalPages);

          newArray.forEach((item, index, arr) => {
            createRow(doc, item, index, data, 26, i === totalPages - 2, index === arr.length - 1, dateFormat);
          });
        }
      }
    }
  }

  const url = doc.output('bloburi');
  const blob = doc.output('blob');

  // doc.save(`${first_name}_${last_name}-time_tracking.pdf`);
  return { blob, url };
};
