import React from 'react';
import { useSelector } from 'react-redux';
import right from 'assets/icons/drivers/right.svg';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { getShipmentType } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { formatNumber, palette } from 'utils/constants';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import RowActions from 'pages/Accounting/Receivables/TableSection/shared/RowActions';
import {
  findNextStop,
  paymentMethods,
  paymentMethodsNumeric,
} from 'pages/Accounting/Receivables/TableSection/TableSection.data';
import { SUnderlineText } from 'pages/Accounting/Receivables/Receivables.styles';

export const useColumns = ({
  onViewPDF,
  onViewNotes,
  onRefundAndRevert,
  sort,
  sortingQuery,
  handleSelectRow,
  handleSelectAll,
  selectedRows,
  data,
}) => {
  const { formatDate } = useDateFormat();
  const hasQuickbook = useHasQuickbook();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: '',
      title: <CustomCheckbox onChange={handleSelectAll} checked={selectedRows?.length === data?.length} />,
      render: (row) => (
        <div onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.id === row.id)}
          />
        </div>
      ),
    },
    {
      field: 'shipment_id',
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.billing?.shipment?.shipment_id}
            </Typography>
            {!!row.billing?.shipment?.shipment_notes?.length && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onViewNotes(row);
                }}
              >
                <NotesIcon width={14} height={14} />
              </div>
            )}
            {(!!row.billing?.shipment?.is_edi_api || !!row.billing?.shipment?.manual_edi_api) && (
              <CloudIcon fill={palette.green400} />
            )}
            {!!row.qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
          </div>
        );
      },
    },
    {
      field: 'invoice_id',
      title: <ColumnHeader title='INVOICE #' field='invoice_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <div className='d-flex flex-column gap-2'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.invoice_id}
          </Typography>
          {row.batch && (
            <SUnderlineText
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                onViewPDF(row.batch);
              }}
            >
              {row.batch?.batch_id}
            </SUnderlineText>
          )}
        </div>
      ),
    },
    {
      field: 'reference_id',
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.billing?.reference_id}
        </Typography>
      ),
    },
    {
      field: 'shipment_type',
      title: <ColumnHeader title='SHIPMENT TYPE' field='shipment_type' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {getShipmentType(row.billing?.shipment.shipment_type)}
        </Typography>
      ),
    },
    {
      field: 'customer',
      title: <ColumnHeader title='CUSTOMER' field='customer' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.billing?.billing_customer?.company_name || '-'}
        </Typography>
      ),
    },
    {
      field: 'equipment',
      title: <ColumnHeader title='EQUIPMENT' field='equipment' />,
      render: (row) =>
        row.shipment?.brokerage_dispatch || row.shipment?.equipment ? (
          <>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.billing?.shipment?.brokerage_dispatch
                ? row.billing?.shipment?.brokerage_dispatch?.vehicle_id
                : row.billing?.shipment?.equipment?.equipment_id}
              &nbsp;
            </Typography>
            <Typography variant='overLine' style={{ color: palette.gray500, textTransform: 'uppercase' }}>
              {row.billing?.shipment?.brokerage_dispatch
                ? row.billing?.shipment?.brokerage_dispatch?.vehicle_type?.title
                : row.billing?.shipment?.equipment?.equipment_type?.title}
            </Typography>
            {(!!row.billing?.shipment?.last_hooked_equipment ||
              !!row.billing?.shipment?.brokerage_dispatch?.trailer_type) && (
              <>
                <TwoWaysIcon style={{ margin: '0 8px' }} />
                <Typography variant='overLine2' style={{ textTransform: 'uppercase' }}>
                  {row.billing?.shipment?.last_hooked_equipment?.equipment_id ||
                    row.billing?.shipment?.brokerage_dispatch?.trailer_id}
                  &nbsp;
                  <Typography variant='overLine'>
                    {row.billing?.shipment?.last_hooked_equipment?.length?.length ||
                      row.billing?.shipment?.brokerage_dispatch?.trailer_length?.length}
                    -
                    {row.billing?.shipment?.last_hooked_equipment?.equipment_type?.title ||
                      row.billing?.shipment?.brokerage_dispatch?.trailer_type?.title}
                  </Typography>
                </Typography>
              </>
            )}
          </>
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
        ),
    },
    {
      field: 'pw',
      title: <ColumnHeader title='PW' field='pw' />,
      render: (row) =>
        row.billing?.paperwork_received ? <img src={right} alt='' /> : <DeleteIcon fill={palette.red500} />,
    },
    {
      field: 'age',
      title: <ColumnHeader title='AGING' field='age' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.age || '-'}
        </Typography>
      ),
    },
    {
      field: 'stops',
      title: <ColumnHeader title='STOPS' field='stops' />,
      render: (row) => {
        const count = findNextStop(row.billing?.shipment);

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {count} of {row.billing?.shipment?.stops_count}
          </Typography>
        );
      },
    },
    {
      field: 'origin',
      title: <ColumnHeader title='ORIGIN' field='origin' />,
      render: (row) => {
        const city = row.billing?.origin_stop?.stop_point?.city?.name || '';
        const state = row.billing?.origin_stop?.stop_point?.state?.short_name || '';
        const zip = row.billing?.origin_stop?.stop_point?.zipcode || '';

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {city ? `${city},` : ''} {state} {zip}
          </Typography>
        );
      },
    },
    {
      field: 'destination',
      title: <ColumnHeader title='DESTINATION' field='destination' />,
      render: (row) => {
        const city = row.billing?.destination_stop?.stop_point?.city?.name || '';
        const state = row.billing?.destination_stop?.stop_point?.state?.short_name || '';
        const zip = row.billing?.destination_stop?.stop_point?.zipcode || '';

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {city ? `${city},` : ''} {state} {zip}
          </Typography>
        );
      },
    },
    {
      field: 'complete_date',
      title: <ColumnHeader title='COMPLETE DATE' field='end_date_time' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.billing?.shipment?.end_date_time ? formatDate(row.billing?.shipment?.end_date_time) : '-'}
        </Typography>
      ),
    },
    {
      field: 'miles',
      title: <ColumnHeader title='MILES' field='miles' />,
      render: (row) => {
        const loaded = row.billing?.shipment?.loaded_miles ? Math.round(row.billing?.shipment.loaded_miles) : 0;
        const empty = row.billing?.shipment?.empty_miles ? Math.round(row.billing?.shipment.empty_miles) : 0;
        const total = loaded + empty;

        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {total}
          </Typography>
        );
      },
    },
    {
      field: 'customer_billed_date',
      title: <ColumnHeader title='SENT DATE' field='customer_billed_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.customer_billed_date)}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      title: <ColumnHeader title='DUE DATE' field='due_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.due_date)}
        </Typography>
      ),
    },
    {
      field: 'customer_payment_date',
      title: <ColumnHeader title='PAID DATE' field='customer_payment_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.customer_payment_date)}
        </Typography>
      ),
    },
    {
      field: 'payment_method',
      title: <ColumnHeader title='PAYMENT METHOD' field='payment_method' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {/* paymentMethods[row.payment_method] is used for old invoices, now changed to paymentMethodsNumeric */}
          {row.payment_method ? paymentMethods[row.payment_method] || paymentMethodsNumeric[row.payment_method] : '-'}
        </Typography>
      ),
    },
    {
      field: 'total_amount',
      title: <ColumnHeader title='BALANCE' field='total_amount' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.amount)}
        </Typography>
      ),
    },
    {
      field: 'amount_paid',
      title: <ColumnHeader title='AMOUNT PAID' field='amount_paid' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.amount_paid)}
        </Typography>
      ),
    },
    {
      field: 'deposit_account',
      title: <ColumnHeader title='DEPOSIT ACCOUNT' field='deposit_account' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.accounting_account?.account_name || '-'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      title: <ColumnHeader title='' />,
      render: (row) => (
        <RowActions
          row={row}
          onViewPDF={() => onViewPDF(row)}
          onViewNotes={() => onViewNotes(row)}
          onRefundAndRevert={() => onRefundAndRevert(row)}
        />
      ),
    },
  ];
};

export const useOtherColumns = ({ onViewPDF, onViewNotes, onRefundAndRevert, sort, sortingQuery }) => {
  const { formatDate } = useDateFormat();
  const hasQuickbook = useHasQuickbook();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'invoice_id',
      title: <ColumnHeader title='INVOICE ID' field='invoice_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.invoice_id}
            </Typography>
            {!!row.invoice_notes?.length && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onViewNotes(row);
                }}
              >
                <NotesIcon width={14} height={14} />
              </div>
            )}
            {!!row.qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
          </div>
        );
      },
    },
    {
      field: 'customer',
      title: <ColumnHeader title='CUSTOMER' field='customer' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.customer && row.customer.company_name}
        </Typography>
      ),
    },
    {
      field: 'pay_reference',
      title: <ColumnHeader title='REFERENCE ID' field='pay_reference' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.pay_reference}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'customer_billed_date',
      title: <ColumnHeader title='SENT DATE' field='customer_billed_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.customer_billed_date)}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      title: <ColumnHeader title='DUE DATE' field='due_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.due_date)}
        </Typography>
      ),
    },
    {
      field: 'customer_payment_date',
      title: <ColumnHeader title='PAID DATE' field='customer_payment_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.customer_payment_date)}
        </Typography>
      ),
    },
    {
      field: 'age',
      title: <ColumnHeader title='AGING' field='age' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.age || '-'}
        </Typography>
      ),
    },
    {
      field: 'payment_method',
      title: <ColumnHeader title='PAYMENT METHOD' field='payment_method' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.payment_method ? paymentMethods[row.payment_method] || paymentMethodsNumeric[row.payment_method] : '-'}
        </Typography>
      ),
    },
    {
      field: 'amount',
      title: <ColumnHeader title='BALANCE' field='amount' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.amount)}
        </Typography>
      ),
    },
    {
      field: 'amount_paid',
      title: <ColumnHeader title='AMOUNT PAID' field='amount_paid' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.amount_paid)}
        </Typography>
      ),
    },
    {
      field: 'deposit_account',
      title: <ColumnHeader title='DEPOSIT ACCOUNT' field='deposit_account' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.accounting_account?.account_name || '-'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      title: <ColumnHeader title='' />,
      render: (row) => (
        <RowActions
          row={row}
          onViewPDF={() => onViewPDF(row)}
          onViewNotes={() => onViewNotes(row)}
          onRefundAndRevert={() => onRefundAndRevert(row)}
        />
      ),
    },
  ];
};
