import React from 'react';
import xIcon from 'assets/icons/icon(1).png';
import './errorMessage.css';

const ErrorMessage = ({ message, successMsg, close, show, left }) => {
  return (
    <div
      className='error-msg'
      style={{
        display: show ? 'flex' : 'none',
        background: successMsg ? 'green' : '#9B1133',
        justifyContent: left ? 'flex-start' : '',
      }}
    >
      <div className='d-flex'>
        <img src={xIcon} alt='icon' onClick={close} />
      </div>
      <div>
        <p
          className='error-txt'
          style={{
            color: successMsg ? '#ffff' : '#F1B9C7',
          }}
        >
          {message}
          {successMsg}
        </p>
      </div>
    </div>
  );
};

export default ErrorMessage;
