export const initialFilters = {
  tab: 0,
  page: 1,
  itemsPerPage: 50,
  types: [],
  payTerm: [],
  canTravelTo: [],
  approvedFor: [],
  equipment_types: [],
};
