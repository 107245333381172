import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .filters-wrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  .watch-video-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    :hover {
      cursor: pointer;

      .underline-text {
        text-decoration: underline;
      }
    }
  }
`;
