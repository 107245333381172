import React from 'react';
import styles from '../DocumentsHeader/DocumentsHeader.module.css';
import HeaderStar from '../HeaderStar';
import CustomButton from '../CustomButton/CustomButton';
import { ReactComponent as PlusIcon } from '../../assets/icons/pluseIcon.svg';

const SupportHeader = ({ onOpenModal, ticketTypes, priorities }) => {
  return (
    <div className={styles.documentHeader_wrapper}>
      <HeaderStar title='Support' />
      <div className='d-flex align-items-end'>
        {!!ticketTypes.length && !!priorities.length && (
          <CustomButton
            width={12}
            height={12}
            type='primary'
            title='Create Ticket'
            leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px' }}
            onClick={onOpenModal}
          />
        )}
      </div>
    </div>
  );
};
export default SupportHeader;
