import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { GetCompanyProfile } from 'Api/CompanySettings';
import { getCities, getStates } from 'Api/JobPositions';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import classes from './UpdateCurrnetLocation.module.scss';
import Input from '../../../common/Input';
import { SAddressWrapper, SFlexRow } from '../../firstTimeLogin/components/AddressStep/AddressStep.styles';
import Autocomplete from '../../../common/Autocomplete';
import useForm from '../../../hooks/useForm';
import { COUNTRIES } from '../../../pages/PublicPositions/ApplyJob/ApplyJob.data';

const UpdateCurrentLocation = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();
  const subTitle = `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name} `;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState();
  const [initialCountry, setInitialCountry] = useState(null);

  const styles = useMemo(() => {
    return {
      styleBody: {
        maxHeight: 600,
        overflowY: 'auto',
        padding: '20px',
      },
      modal: {
        width: 600,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
      labelStyle: {
        margin: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      inputDriver: {
        height: 32,
        width: '100%',
        borderRadius: '0 4px 4px 0',
      },
      selectStyle: {
        height: 32,
        width: 250,
        borderRadius: '4px 0 0 4px',
        marginLeft: 36,
      },
      dropDownStyles: {
        flexGrow: 0,
        width: 221,
      },
      menuStyle: {
        width: 221,
        marginLeft: 36,
      },
      datePicker: {
        minWidth: 221,
        margin: 0,
        justifyContent: 'flex-start',
      },
    };
  }, [use, palette]);

  const onSubmit = async (values) => {
    const { country, state, city, zip } = values;

    if (!country && !state && !city && !zip) {
      setError('Please enter at least one of the following: City, State, Country or Zip code');
      return;
    }
    if (country && !state && !city && !zip) {
      setError('Enter City and State or Zip code');
      return;
    }
    if (country && state && !city && !zip) {
      setError('Enter City');
      return;
    }
    if (!country && !state && city && !zip) {
      setError('Enter both Country and State');
      return;
    }
    if (!country && state && !city && !zip) {
      setError('Enter City');
      return;
    }
    if (country && !state && city && !zip) {
      setError('Enter State');
      return;
    }

    onAddBtn(null, null, null, values);
  };

  const getCompanyProfile = async () => {
    try {
      const data = await GetCompanyProfile();
      setInitialCountry(data.country_id);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(async () => {
    getCompanyProfile();
  }, []);

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: {
      address1: '',
      country: initialCountry || null,
      state: null,
      city: null,
      zip: '',
    },
    onSubmit,
  });

  useEffect(() => {
    if (initialCountry) {
      handleChange('country', initialCountry);
    }
  }, [initialCountry]);

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      /* empty */
    }
  };

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      backdrop='static'
      dialogClassName='dialogClassName'
    >
      <Modal.Body style={styles.modalBody}>
        <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <Typography variant='button2'>Update Driver Location</Typography>
          <Typography variant='overLine'>{subTitle}</Typography>
        </div>

        <div
          className={classes.modalBody}
          style={{
            backgroundColor: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.gray700),
          }}
        >
          <SAddressWrapper>
            <SFlexRow>
              <div className='w-100'>
                <Autocomplete
                  name='state'
                  placeholder='Select State..'
                  value={values.state}
                  onChange={(e, value) => {
                    handleChange('state', value);
                    handleChange('city', null);
                    setError(null);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={states}
                  disableClearable={false}
                  error={touchedErrors?.state}
                />
              </div>
              <div className='w-100'>
                <Autocomplete
                  name='city'
                  placeholder='Select City..'
                  value={values.city}
                  onChange={(e, value) => {
                    handleChange('city', value);
                    setError(null);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={cities}
                  disableClearable={false}
                  error={touchedErrors?.city}
                />
              </div>
              <div className='w-100'>
                <Autocomplete
                  name='country'
                  placeholder='Select Country..'
                  value={values.country}
                  onChange={(e, value) => {
                    handleChange('country', value);
                    handleChange('state', null);
                    handleChange('city', null);
                    setError(null);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={COUNTRIES}
                  disableClearable={false}
                  isThreeCountry
                  error={touchedErrors?.country}
                />
              </div>
            </SFlexRow>
            <div className='d-flex justify-content-center align-items-center gap-3'>
              <div className={classes.line} />
              <Typography variant='s2' style={{ color: use(palette.gray500, palette.gray700) }}>
                or
              </Typography>
              <div className={classes.line} />
            </div>
            <SFlexRow>
              <div className='d-flex justify-content-center w-100'>
                <Input
                  name='zip'
                  placeholder='Zip Code'
                  onChange={(e) => {
                    handleChange('zip', e.target.value);
                    setError(null);
                  }}
                  value={values.zip}
                  error={touchedErrors?.zip}
                />
              </div>
            </SFlexRow>
          </SAddressWrapper>
          {error && (
            <div className={classes.errorWrapper}>
              <span> {error}</span>
            </div>
          )}
        </div>

        {/* // footer */}
        <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <div className={classes.modalFooterLane} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
            <CustomButton
              type='secondary'
              title='Cancel'
              onClick={() => {
                setIsOpen(false);
              }}
              styleButton={{ padding: '2px 8px', marginTop: 0 }}
            />
            <CustomButton
              type='primary'
              title='Update'
              onClick={handleSubmit}
              styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateCurrentLocation;
