import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SInputWrapper = styled.div`
  position: relative;

  .end-adornment {
    position: absolute;
    right: 10px;
    top: 37px;
    cursor: pointer;
  }
`;

export const SInput = styled.input`
  width: ${({ width }) => width || '100%'};
  background: ${palette.white};
  box-shadow: ${palette.boxShadow2};
  border: none;
  outline: none;
  font-size: 14px;
  padding: ${({ size }) => (size === 'small' ? '2px 12px' : '6px 12px')};
  padding-right: ${({ $endAdornment }) => ($endAdornment ? '36px' : '')};
  border-radius: 6px;
  font-family: Inter, sans-serif;

  ::placeholder {
    color: ${palette.gray300};
  }

  :disabled {
    background-color: ${palette.gray0};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`;

export const SInputWithIconWrapper = styled.div`
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || 'auto'};
  display: flex;
  align-items: center;
  background: ${({ $disabled }) => ($disabled ? palette.gray0 : palette.white)};
  box-shadow: ${palette.boxShadow2};
  border-radius: 6px;
  padding: 0 8px;
  color: ${palette.gray200};
`;

export const SInputWithIcon = styled.input`
  width: ${({ width }) => width || '100%'};
  background: ${palette.white};
  box-shadow: none;
  border: none;
  outline: none;
  font-size: 14px;
  padding: ${({ size, $left }) =>
    size === 'small' ? ($left ? '2px 0 2px 12px' : '2px 12px 2px 2px') : $left ? '6px 0 6px 12px' : '6px 12px 6px 0'};
  border-radius: 6px;
  font-family: Inter, sans-serif;

  ::placeholder {
    color: ${palette.gray300};
  }

  :disabled {
    background-color: ${palette.gray0};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`;

export const STextareaWrapper = styled.div`
  position: relative;

  .extender-icon {
    position: absolute;
    right: 3px;
    bottom: 3px;
    pointer-events: none;
  }
`;

export const STextarea = styled.textarea`
  width: ${({ $width }) => $width || '100%'};
  min-height: 33px;
  background: ${palette.white};
  box-shadow: ${palette.boxShadow2};
  border: none;
  outline: none;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 6px;
  vertical-align: middle;
  font-family: Inter, sans-serif;

  ::placeholder {
    color: ${palette.gray300};
  }

  :disabled {
    background-color: ${palette.gray0};
  }

  ::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-resizer {
    display: none;
  }
`;

export const SInputLabel = styled.div`
  width: ${({ $textWidth }) => $textWidth || ''};
  color: ${palette.gray500};
  background-color: ${({ $bgColor }) => $bgColor || ''};
  box-shadow: ${palette.boxShadow2};
  border-left: none;
  border-radius: ${({ $left }) => ($left ? '6px 0 0 6px' : '0 6px 6px 0')};
  padding: ${({ $size }) => ($size === 'small' ? '0px 8px 1px' : '4px 10px 5px')};
  height: 100%;
  white-space: nowrap;
`;
