import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { Typography } from 'components/Typography';
import { SBackButton, SHeaderWrapper, SLink, SLoadingWrapper } from './PageWrapper.styles';

const PageWrapper = ({ loading, children, title, hideTitle }) => {
  return (
    <div className='mb-5'>
      {!hideTitle && (
        <SBackButton>
          <ArrowLeft width={10} height={8} />
          <SLink to={-1}>Back</SLink>
        </SBackButton>
      )}
      {loading ? (
        <SLoadingWrapper>
          <CircularProgress size={26} />
        </SLoadingWrapper>
      ) : (
        <>
          {!hideTitle && (
            <SHeaderWrapper>
              <Typography variant='h1'>{title}</Typography>
            </SHeaderWrapper>
          )}
          {children}
        </>
      )}
    </div>
  );
};

export default PageWrapper;
