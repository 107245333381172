import uuid from 'react-uuid';
import moment from 'moment';

export const initialValues = {
  id: uuid(),
  isNew: true,
  equipment_document_type: null,
  reference_id: '',
  document_exp_date: null,
  no_expire: false,
  document: null,
  use_from_policy: false,
};

export const getInitialValue = (document) => {
  return {
    id: document.id || uuid(),
    equipment_document_type: document.equipment_document_type || null,
    reference_id: document.reference_id || '',
    document_exp_date: document.document_exp_date ? moment(document.document_exp_date).toDate() : null,
    no_expire: !document.document_exp_date,
    use_from_policy: document.use_from_policy,
    document: document.document || null,
    isNew: document.isNew || false,
  };
};

export const getInitialValues = (documents) => {
  return documents?.length ? documents.map((item) => getInitialValue(item)) : [initialValues];
};
