import React from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import sm from './Documents.module.css';
import CustomButton from '../../CustomButton/CustomButton';

const DocumentsFooter = ({
  submitForm,
  updateDocumentsUploadFiles,
  setStep,
  onCloseModal,
  onClickPlanAssign,
  shipmentCreateLoading,
}) => {
  const { use } = useTheme();

  return (
    <div className={sm.documents_footer} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <div>
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onCloseModal}
        />
      </div>

      <div className={sm.right_buttons}>
        <CustomButton
          type='secondary'
          title='Back'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={() => {
            updateDocumentsUploadFiles({});
            setStep((p) => p - 1);
          }}
        />

        <CustomButton
          type='secondary'
          title='Create & Offer'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          buttonProps={{ type: 'submit' }}
          onClick={() => onClickPlanAssign('offer_assign', submitForm)}
          disabled={shipmentCreateLoading}
        />

        <CustomButton
          type='secondary'
          title='Create & Dispatch'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          buttonProps={{ type: 'submit' }}
          onClick={() => onClickPlanAssign('plan_assign', submitForm)}
          disabled={shipmentCreateLoading}
        />

        <CustomButton
          type='primary'
          title='Create Shipment'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={submitForm}
          buttonProps={{ type: 'submit' }}
          disabled={shipmentCreateLoading}
        />
      </div>
    </div>
  );
};

export default DocumentsFooter;
