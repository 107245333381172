import { palette } from 'utils/constants';

export const getInitialValues = (tab, alertCategories) => {
  const alertTypes = alertCategories.flatMap((el) => el.alerts);

  return {
    tabName: tab?.label || '',
    alert_categories: tab?.filters?.alert_type?.length
      ? alertCategories.filter((item) => tab.filters.alert_type.includes(item.title))
      : [],
    alert_types: tab?.filters?.alert_id?.length
      ? alertTypes.filter((item) => tab.filters.alert_id.includes(item.id))
      : [],
    color: tab?.color || palette.indigo500,
    forAll: tab ? !tab?.user_id : false,
  };
};
