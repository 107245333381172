import React, { useState } from 'react';
import HeaderStar from 'components/HeaderStar';
import TableSection from './TableSection';
import StatsSection from './StatsSection';
import { SHeaderWrapper, SPageWrapper } from './AccidentsAndCrashes.styles';

const AccidentsAndCrashes = () => {
  const [statsRefreshIndex, setStatsRefreshIndex] = useState(Date.now());

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Accidents / Crashes' />
      </SHeaderWrapper>
      <StatsSection statsRefreshIndex={statsRefreshIndex} />
      <TableSection refreshStats={() => setStatsRefreshIndex(Date.now())} />
    </SPageWrapper>
  );
};

export default AccidentsAndCrashes;
