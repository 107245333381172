import { formatNumber } from 'utils/constants';

export const getInitialValues = (bills) => {
  return bills.map((bill) => {
    return {
      id: bill.id || null,
      vendor: bill.vendor || null,
      bill_due_date: bill.bill_due_date ? new Date(bill.bill_due_date) : null,
      aging: bill.aging || null,
      payment_date: new Date(),
      reference_id: bill.reference_id || '',
      open_balance: bill.open_balance || 0,
      amount_paid: bill.open_balance ? formatNumber(bill.open_balance) : '',
      pay_reference: bill.pay_reference || '',
      payment_method: bill.payment_method ? paymentMethods.find((i) => i.value === bill.payment_method) || null : null,
      account: null,
    };
  });
};

export const paymentMethods = [
  { name: 'Wire', value: 'wire' },
  { name: 'ACH', value: 'ach' },
  { name: 'Check', value: 'check' },
  { name: 'Credit Card', value: 'credit_card' },
  { name: 'Cash', value: 'cash' },
];
