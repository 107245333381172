import React from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getStatusParamsInStatus } from 'components/TableShipments/helpers/constants';
import classes from './plannedLoadsTable.module.css';

const PlannedLoadsTable = ({ shipments, handleSelectAll, allSelected, selectedRows, handleSelectRow, hideView }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const { formatDate, formatTime } = useDateFormat();

  const columns = [
    ...(!hideView
      ? [
          {
            field: '',
            title: <CustomCheckbox onChange={handleSelectAll} checked={allSelected} />,
            render: (row) => (
              <span onClick={(e) => e.stopPropagation()}>
                <CustomCheckbox
                  onChange={(checked) => handleSelectRow(checked, row)}
                  checked={selectedRows?.some((i) => i.shipment_id === row.shipment_id)}
                />
              </span>
            ),
          },
        ]
      : []),
    {
      field: 'Shipment ID',
      title: <Typography variant='c3'>SHIPMENT ID</Typography>,
      render: (rowData) => {
        return (
          <div className={classes.colWrapper}>
            <Typography variant='s3'>{rowData?.shipment_id}</Typography>
          </div>
        );
      },
    },
    {
      field: 'Status',
      title: <Typography variant='c3'>STATUS</Typography>,
      render: (rowData) => {
        const status = rowData?.status;
        const paramsInStatus = getStatusParamsInStatus(status);
        const { bgStatus, colorStatus, statusName } = paramsInStatus || {};
        return (
          <div className='d-flex justify-content-start'>
            <div className={classes.statusWrapper}>
              <span className={classes.statusColumns} style={{ backgroundColor: bgStatus, color: colorStatus }}>
                {statusName}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'ORIGIN CITY, STATE',
      title: <Typography variant='c3'>ORIGIN</Typography>,
      render: (rowData) => {
        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;

        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOrigin} ${toData || ''}`;

        const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
        return (
          <>
            <span className='rowData_wrapper}' style={{ color: use(palette.dark800, palette.gray200) }}>
              {city && `${city},`} {state}
            </span>
            <div>
              <Typography variant='b3'>{formatDate(scheduledOrigin)} </Typography>
              <br />
              <span style={{ color: use(palette.dark800, palette.gray200) }}>
                {fromData ? ` ${formatTime(from)}` : ''}
                {toData && [1, 3].includes(Number(typeId)) ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </div>
          </>
        );
      },
    },
    {
      field: 'DESTINATION CITY, STATE',
      title: <Typography variant='c3'>DESTINATION</Typography>,
      render: (rowData) => {
        const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
        const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
        const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;

        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestination} ${toData || ''}`;

        const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        return (
          <>
            <span className='rowData_wrapper' style={{ color: use(palette.dark800, palette.gray200) }}>
              {city && `${city},`} {state}
            </span>
            <div>
              <Typography variant='b3'>{formatDate(scheduledDestination)} </Typography>
              <br />
              <span style={{ color: use(palette.dark800, palette.gray200) }}>
                {fromData ? `${formatTime(from)}` : ''}
                {toData && [1, 3].includes(Number(typeId)) ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </div>
          </>
        );
      },
    },
    {
      field: 'MILES',
      title: <Typography variant='c3'>TOTAL MILES</Typography>,
      render: (rowData) => {
        const { loaded_miles, empty_miles } = rowData;
        const miles = Math.round(loaded_miles) + Math.round(empty_miles);
        return (
          <Typography
            variant='s3'
            className={classes.rowData_wrapper}
            style={{ color: use(palette.dark800, palette.gray200) }}
          >
            {miles && miles}
          </Typography>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div style={{ margin: 1 }}>
        <MaterialTableWrapper
          data={shipments}
          rowPerPage={100}
          onRowClick={(e, rowData) => {
            navigate(`/shipment/${rowData?.shipment_id}`);
          }}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          columns={columns}
          options={{
            paging: false,
            sorting: false,
            toolbar: false,
            title: false,
            rowStyle: {
              borderBottom: 'none',
              width: '100%',
            },
            headerStyle: {
              borderTop: 'none',
              width: '100%',
            },
          }}
        />
      </div>
    </div>
  );
};

export default PlannedLoadsTable;
