import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import calendar from 'assets/icons/drivers/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { CircularProgress } from '@mui/material';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import plus from 'assets/icons/drivers/plus.svg';
import { uploadDriverDocument } from 'Api/Staff';
import useShowToaster from 'hooks/useShowToaster';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import ImageDropZone from '../ImgageDropZone';

export default function UpdateDocument({
  updateDocumentData,
  setUpdateDocumentData,
  loading,
  AddUpdateDocuments,
  id,
  ...props
}) {
  const showToaster = useShowToaster();
  const { index } = updateDocumentData;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({ ...updateDocumentData, count: 1 });
  }, []);

  const setDoc = async (index, type, value) => {
    if (type === 'exp_date') {
      value = moment(value).format('YYYY-MM-DD');
    }
    if (type === 'exp_date' && updateDocumentData.exp_date === value) {
      // Do nothing
    } else if (type === 'exp_date' && updateDocumentData.exp_date !== value && data.count === 1) {
      const temUpdatedData = { ...updateDocumentData, [type]: value, document_path: null };
      setUpdateDocumentData(temUpdatedData);
      setData({ ...updateDocumentData, count: data.count + 1 });
    } else {
      const temUpdatedData = { ...updateDocumentData, [type]: value };
      setUpdateDocumentData(temUpdatedData);
    }
  };

  const updateDocuments = () => {
    if (updateDocumentData.document_name && !updateDocumentData.document_path) {
      showToaster({ type: 'error', message: 'Document is required!' });
      return;
    }
    AddUpdateDocuments(updateDocumentData, 'update', props.onHide, 'Documents has been successfully updated');
  };

  const uploadDocument = (file, propertyName, index) => {
    uploadDriverDocument(file[0], id).then((res) => {
      setDoc(index, 'document_path', res.data.document_path);
    });
  };

  return (
    <div className='modal-container'>
      <Modal {...props} enforceFocus={false} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title className='heading' id='contained-modal-title-vcenter'>
            Update Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-box driver-style-wrap'>
          <div className='content-wrapper-date type-date'>
            <p className='text-style' style={{ marginLeft: '0' }}>
              Document Name
            </p>
            <div className='mb-2'>
              <div className='d-flex update-expiry-container mb-0'>
                <div className='expiry-input'>
                  <input
                    type='text'
                    value={updateDocumentData?.document_name}
                    onChange={(e) => {
                      setDoc(index, 'document_name', e.target.value);
                    }}
                  />
                </div>
                <div className='select-expiry-type test'>
                  <img src={calendar} alt='caleder' />
                  <DatePicker
                    selected={
                      updateDocumentData?.exp_date
                        ? new Date(moment(new Date(updateDocumentData?.exp_date), 'MM-DD-YYYY'))
                        : new Date()
                    }
                    placeholderText='mm/dd/yyyy'
                    className='text-style ms-0'
                    value={
                      updateDocumentData?.no_expiration
                        ? ''
                        : new Date(moment(new Date(updateDocumentData?.exp_date), 'MM-DD-YYYY'))
                    }
                    onChange={(date) => {
                      setDoc(index, 'exp_date', date);
                    }}
                    highlightDates={new Date()}
                    showYearDropdown
                    showMonthDropdown
                    disabled={!!updateDocumentData?.no_expiration}
                  />
                </div>
              </div>
              <div style={{ marginTop: '4px', marginLeft: '250px' }}>
                <CustomCheckbox
                  name='no_expiration'
                  checked={updateDocumentData?.no_expiration}
                  onChange={(checked) => setDoc(index, 'no_expiration', checked)}
                >
                  <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                    No Expiration
                  </Typography>
                </CustomCheckbox>
              </div>
            </div>
            {updateDocumentData.document_path ? (
              <p className='attachment-wrapper mt-0'>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={() => setDoc(index, 'document_path', null)}
                />
              </p>
            ) : (
              <div className='ms-2 mt-2 attach-file' style={{ position: 'relative' }}>
                <label htmlFor='myFile' className='text-style upload'>
                  <span className='upload-icon'>
                    <img src={plus} alt='plus' />
                  </span>
                  <span className='text-style choose-file '>Choose File</span>
                </label>
                <ImageDropZone
                  onDrop={(file) => uploadDocument(file, 'attachment', index)}
                  width='100%'
                  height='100%'
                  name='attachment'
                  id='attachment'
                  deletable={false}
                  // onBlur={formik.handleBlur}
                  maxSize={1000000}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['application/pdf']}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button className='next-step' type='submit' onClick={updateDocuments}>
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
