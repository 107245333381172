import React, { useEffect, useState } from 'react';
import { getCarrierGraphData } from 'Api/Carriers';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { ReactComponent as Up } from 'assets/icons/arrow-up.svg';
import { palette } from 'utils/constants';
import StatBox from './components/StatBox';
import DriverInspections from './components/DriverInspections';
import VehicleInspections from './components/VehicleInspections';
import { initialStats, statsDataConverter } from './StatsSection.data';
import { SWrapper } from './StatsSection.styles';

const StatsSection = ({ carrierId, carrier }) => {
  const [stats, setStats] = useState(initialStats);
  const [loading, setLoading] = useState(false);
  const [driverInspectionsYear, setDriverInspectionsYear] = useState('All');
  const [vehicleInspectionsYear, setVehicleInspectionsYear] = useState('All');

  const {
    us_driver_national_average,
    us_driver_out_of_service_percent,
    us_vehicle_out_of_service_percent,
    us_vehicle_national_average,
  } = carrier || {};

  const truncateToTwoDecimals = (num) => {
    const multiplier = 100;
    return Math.floor(num * multiplier) / multiplier;
  };

  const getCarrierStats = async () => {
    setLoading(true);
    try {
      const params = {
        year_driver: driverInspectionsYear === 'All' ? null : driverInspectionsYear,
        year_vehicle: vehicleInspectionsYear === 'All' ? null : vehicleInspectionsYear,
      };
      const { data } = await getCarrierGraphData(carrierId, params);
      const convertedData = statsDataConverter(data);
      setStats(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCarrierStats();
  }, [driverInspectionsYear, vehicleInspectionsYear]);

  const onVehicleInspectionYearChange = (val) => {
    setVehicleInspectionsYear(val);
  };

  const onDriverInspectionYearChange = (val) => {
    setDriverInspectionsYear(val);
  };

  return (
    <SWrapper>
      <div className='scrollable-container'>
        <div className='chart-boxes-wrapper'>
          <DriverInspections
            inspections={stats.driverInspections}
            driverInspectionsYear={driverInspectionsYear}
            onDriverInspectionYearChange={onDriverInspectionYearChange}
          />

          <VehicleInspections
            inspections={stats.vehicleInspections}
            vehicleInspectionsYear={vehicleInspectionsYear}
            onVehicleInspectionYearChange={onVehicleInspectionYearChange}
          />
        </div>
        <div className='stat-boxes-wrapper'>
          {Object.keys(stats.statsSection || {})?.map((key, index) => (
            <StatBox key={key} title={key} index={index} data={stats.statsSection[key]} loading={loading} />
          ))}
        </div>
      </div>
      <div className='w-100'>
        <div
          className='d-flex align-items-center gap-3'
          style={{ width: '80%', borderRight: ' 1px solid #E9EDF5', padding: 16 }}
        >
          <div className='d-flex justify-content-between w-100'>
            <div className='w-50 d-flex align-items-center justify-content-around'>
              <div className='d-flex align-items-baseline gap-1 flex-column'>
                <Typography variant='s2'>NATIONAL AVERAGE</Typography>
                <Typography variant='h5'>{us_driver_national_average}%</Typography>
              </div>
              <div className='d-flex align-items-baseline gap-1 flex-column'>
                <Typography variant='s2'>CARRIER AVERAGE</Typography>
                <Typography variant='h5'>
                  {truncateToTwoDecimals(us_driver_out_of_service_percent)}%
                  {us_driver_out_of_service_percent - us_driver_national_average < 0 ? (
                    <Up fill={palette.green400} style={{ transform: 'rotate(180deg)' }} />
                  ) : (
                    <Up fill={palette.red400} />
                  )}
                  <Chip
                    bgColor={
                      us_driver_out_of_service_percent - us_driver_national_average < 0 ? palette.green0 : palette.red0
                    }
                    labelColor={
                      us_driver_out_of_service_percent - us_driver_national_average < 0
                        ? palette.green400
                        : palette.red400
                    }
                    label={`${truncateToTwoDecimals(
                      Math.abs(
                        truncateToTwoDecimals(us_driver_out_of_service_percent) -
                          truncateToTwoDecimals(us_driver_national_average)
                      )?.toFixed(2)
                    )}%`}
                  />{' '}
                </Typography>
              </div>
            </div>
            <div className='w-50 d-flex align-items-center justify-content-around'>
              <div className='d-flex align-items-baseline gap-1 flex-column'>
                <Typography variant='s2'>NATIONAL AVERAGE</Typography>
                <Typography variant='h5'>{us_vehicle_national_average}%</Typography>
              </div>
              <div className='d-flex align-items-baseline gap-1 flex-column'>
                <Typography variant='s2'>CARRIER AVERAGE</Typography>
                <Typography variant='h5'>
                  {truncateToTwoDecimals(us_vehicle_out_of_service_percent)}%
                  {us_vehicle_out_of_service_percent - us_vehicle_national_average < 0 ? (
                    <Up fill={palette.green400} style={{ transform: 'rotate(180deg)' }} />
                  ) : (
                    <Up fill={palette.red400} />
                  )}
                  <Chip
                    bgColor={
                      us_vehicle_out_of_service_percent - us_vehicle_national_average < 0
                        ? palette.green0
                        : palette.red0
                    }
                    labelColor={
                      us_vehicle_out_of_service_percent - us_vehicle_national_average < 0
                        ? palette.green400
                        : palette.red400
                    }
                    label={`${truncateToTwoDecimals(
                      Math.abs(
                        truncateToTwoDecimals(us_vehicle_out_of_service_percent) -
                          truncateToTwoDecimals(us_vehicle_national_average)
                      )?.toFixed(2)
                    )}%`}
                  />{' '}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SWrapper>
  );
};

export default StatsSection;
