import axios from '../services/axios';

export async function getOpenJobPositions(dot, params) {
  const { data } = await axios.get('open-job-positions', {
    params,
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function getOpenJobPosition(dot, id, params) {
  const { data } = await axios.get(`job-positions/${id}`, {
    params,
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function getJobApplicationSettings(dot) {
  const { data } = await axios.get(`job-position/job-application-settings`, {
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function getCustomerInfo(dot) {
  const { data } = await axios.get(`customer-info`, {
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function getQuestionnaireList(dot) {
  const { data } = await axios.get(`/job-position/questionnaire-list`, {
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function getCountries(params) {
  const url = `search-countries`;
  const { data } = await axios.get(url, {
    params,
  });
  return data;
}

export async function getStates(params) {
  const { data } = await axios.get(`/search-states`, { params });
  return data;
}

export async function getCities(params) {
  const { data } = await axios.get(`/search-cities`, { params });
  return data;
}

export async function getApplicantTypes(params) {
  const url = `job-applicant-type`;
  const { data } = await axios.get(url, {
    params,
  });
  return data;
}

export async function getEquipmentTypes(dot, params) {
  const { data } = await axios.get('equipment-types', {
    params,
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function getDriverLicenseTypes(dot) {
  const url = `driver-license-type`;
  const { data } = await axios.get(url, {
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function createBasicInfo(data, dot) {
  const url = `job-application`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createJobPosition(data, dot) {
  const url = `job-position`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createApplicantInfo(data, dot) {
  const url = `applicant-information`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createDriverLicense(data, dot) {
  const url = `driving-licence`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createStaffLicense(data, dot) {
  const response = await axios.post('photo-identification', data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateStaffLicense(data, id, dot) {
  const response = await axios.put(`photo-identification/${id}`, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createMedicalExaminerCard(data, dot) {
  const url = `medical-examiner-card`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createPreviousEmployment(data, dot) {
  const url = `job-application-previous-employment`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createMilitaryHistory(data, dot) {
  const url = `job-application-military-detail`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createEducation(data, dot) {
  const url = `job-application-education-detail`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createTrafficAccident(data, dot) {
  const url = `job-application-traffic-accident-history`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createOther(data, dot) {
  const url = `job-application-other`;
  const response = await axios.post(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateBasicInfo(data, id, dot) {
  const url = `job-application/${id}`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateJobPosition(data, id, dot) {
  const url = `job-position/${id}`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateApplicantInfo(data, id, dot) {
  const url = `applicant-information/${id}`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateDriverLicense(data, id, dot) {
  const url = `driving-licence/${id}`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateMedicalExaminerCard(data, id, dot) {
  const url = `medical-examiner-card/${id}`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updatePreviousEmployment(data, dot) {
  const url = `job-application-previous-employment`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateMilitaryHistory(data, dot) {
  const url = `job-application-military-detail`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateEducation(data, dot) {
  const url = `job-application-education-detail`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateTrafficAccident(data, dot, id) {
  const url = `job-application-traffic-accident-history/${id}`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function updateOther(data, dot) {
  const url = `job-application-other`;
  const response = await axios.put(url, data, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function deletePreviousEmployment(id, dot) {
  const url = `job-application-previous-employment/${id}`;
  const response = await axios.delete(url, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function uploadDocument(file, dot) {
  const url = 'upload-doc-public';
  if (!file) {
    return;
  }
  const formData = new FormData();
  formData.append('file_upload', file);

  const response = await axios.post(url, formData, {
    headers: {
      'x-dot': dot,
    },
  });
  return response.data;
}

export async function createPosition(body) {
  const { data } = await axios.post('/customer/job-position', body);
  return data;
}

export async function updatePosition(id, body) {
  const { data } = await axios.put(`/customer/job-position/${id}`, body);
  return data;
}

export async function getJobPositionStatuses() {
  const { data } = await axios.get(`/customer/job-position-status`);
  return data;
}

export async function getJobRequirements() {
  const { data } = await axios.get(`/job-position/requirement`);
  return data;
}

export async function createJobRequirement(body) {
  const { data } = await axios.post(`/job-position/requirement`, body);
  return data;
}

export async function deleteJobRequirement(id) {
  const { data } = await axios.delete(`/job-position/requirement/${id}`);
  return data;
}
