import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './DriverActivity.css';
import milesGeneral from 'assets/icons/drivers/milesGeneral.svg';
import grossGeneral from 'assets/icons/drivers/grossGeneral.svg';
import requestGeneral from 'assets/icons/drivers/requestGeneral.svg';
import absenceGeneral from 'assets/icons/drivers/absenceGeneral.svg';
import shipmentGeneral from 'assets/icons/drivers/shipmentGeneral.svg';
import incidentGeneral from 'assets/icons/drivers/incidentGeneral.svg';
import YearPicker from 'common/YearPicker';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getDriverHeaderStats } from 'Api/Driver';

const DriverActivity = ({ driverId }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const [driverStatsData, setDriverStatsData] = useState([]);
  const [year, setYear] = useState(new Date());

  const getStats = async () => {
    try {
      const params = {
        year: moment(year || new Date()).year(),
      };
      const { data } = await getDriverHeaderStats(driverId, params);
      const activity = [
        // {
        //   icon: shipmentGeneral,
        //   detailName: 'SHIPMENTS',
        //   detail: data?.shipments_completed || '0',
        // },
        // {
        //   icon: requestGeneral,
        //   detailName: 'GROSS REVENUE',
        //   detail: `${currency}${formatNumber(data?.gross_revenue || 0) || '0.00'}`,
        // },
        // {
        //   icon: milesGeneral,
        //   detailName: 'MILES DRIVEN',
        //   detail: Math.ceil(data?.miles_driven) || '0',
        // },
        {
          icon: shipmentGeneral,
          detailName: 'YTD - GROSS SHIPMENT REVENUE',
          detail: `(${data?.shipments_completed || '0'}) ${currency}${formatNumber(data?.gross_shipment_revenue || 0)}`,
        },
        {
          icon: requestGeneral,
          detailName: 'YTD - GROSS PAY',
          detail: `${currency}${formatNumber(data?.gross_revenue || 0)}`,
        },
        {
          icon: milesGeneral,
          detailName: 'YTD - MILES DRIVEN',
          detail: Math.ceil(data?.miles_driven) || '0',
        },
        {
          icon: grossGeneral,
          detailName: 'REQUESTS',
          detail: data?.request_count,
        },
        {
          icon: absenceGeneral,
          detailName: 'ABSENCES',
          detail: data?.absence_count,
        },
        {
          icon: incidentGeneral,
          detailName: 'INCIDENTS',
          detail: data?.incident_count,
        },
      ];

      setDriverStatsData(activity);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getStats();
  }, [year]);

  return (
    <div
      className='driver-activity'
      style={{
        position: 'relative',
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div style={{ position: 'absolute', right: 8, top: 8 }}>
        <YearPicker year={year} onChange={setYear} yearItemNumber={24} />
      </div>
      {driverStatsData.map((item) => {
        return (
          <div
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
            className={
              item.detailName === 'Miles Driven' || item.detailName === 'Incidents'
                ? 'driver-activity-wrap last-activity'
                : 'driver-activity-wrap '
            }
            key={item.id}
          >
            <img src={item.icon} alt='' className='activity-image' />
            <div className='activity-details'>
              <p className='detail-name'>{item.detailName}</p>
              <p className='detail' style={{ color: use(palette.gray900, palette.gray50) }}>
                {item.detail}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DriverActivity;
