import React, { useState, useMemo, useEffect } from 'react';
import './TablePlaner.css';
import { Box, Modal } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import Layout from '../CreateShipment/Layout';
import PlannerHeader from '../PlannerHeader';
import RecurringLanes from './helpers/RecurringLanes';
import PendingAssignmentTab from './helpers/PendingAssignmentTab';

const TablePlaner = () => {
  const { use } = useTheme();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { isShipmentHeaderNavigatedStatus } = location?.state || {};

  const [planerHeaderSwitch, setPlanerHeaderSwitch] = useState(tab === 'lanes' ? 1 : 0);
  const [isOpen, setIsOpen] = useState(false);
  const [createModalCLose, setCreateModalCLose] = useState(false);
  const [filterInCreateShipment, setFilterInCreateShipment] = useState({});
  const [initialValueOutSide, setInitialValueOutSide] = useState({});
  const [continueDraft, setContinueDraft] = useState(false);
  const [lanesIndex, setLanesIndex] = useState(null);
  const [exportLanesParams, setExportLanesParams] = useState(null);

  const updateOnCreate = (value) => {
    setCreateModalCLose(value);
  };

  const styles = useMemo(() => {
    return {
      styleModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        padding: '1.5%',
        boxShadow: 24,
        borderRadius: '8px',
      },
    };
  }, [use, palette]);

  useEffect(() => {
    if (+isShipmentHeaderNavigatedStatus === 1) {
      setIsOpen(true);
    } else if (+isShipmentHeaderNavigatedStatus === 2) {
      setIsOpen(true);
      setInitialValueOutSide({ shipment_details_type: '3' });
    }
  }, [location?.state]);

  useEffect(() => {
    if (!tab) {
      return;
    }

    setPlanerHeaderSwitch(tab === 'lanes' ? 1 : 0);
  }, [tab]);

  return (
    <div>
      <PlannerHeader
        setIsOpen={setIsOpen}
        setContinueDraft={setContinueDraft}
        exportLanesParams={exportLanesParams}
        setInitialValueOutSide={setInitialValueOutSide}
        headerSwitchValue={planerHeaderSwitch}
        onChange={(value) => {
          setPlanerHeaderSwitch(value);
          searchParams.set('tab', value === 1 ? 'lanes' : 'assignments');
          setSearchParams(searchParams);
        }}
      />

      {planerHeaderSwitch === 0 ? (
        <PendingAssignmentTab
          createModalCLose={createModalCLose}
          filterInCreateShipment={filterInCreateShipment}
          setFilterInCreateShipment={setFilterInCreateShipment}
        />
      ) : planerHeaderSwitch === 1 ? (
        <RecurringLanes key={lanesIndex} setExportLanesParams={setExportLanesParams} />
      ) : null}

      <div className='top_popover_search-wrapper'>
        <Modal
          disableEscapeKeyDown
          open={isOpen}
          disableEnforceFocus
          onClose={() => setIsOpen(false)}
          style={{ zIndex: 1050 }}
        >
          <Box sx={styles.styleModal}>
            <Layout
              initialValueOutSide={initialValueOutSide}
              setInitialValueOutSide={setInitialValueOutSide}
              onCloseModal={() => setIsOpen(false)}
              createModalCLose={createModalCLose}
              updateOnCreate={updateOnCreate}
              setFilterInCreateShipment={setFilterInCreateShipment}
              onRecurringLaneCreateSuccess={() => setLanesIndex(Date.now())}
              initialFrequency={planerHeaderSwitch === 1 ? '2' : null}
              continueDraft={continueDraft}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default TablePlaner;
