import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';

const ViewPdf = ({ open, onClose, pdfUrl, title = 'View PDF' }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={title}
      $width='80vw'
      $minWidth='1000px'
      $height='auto'
      $bodyMaxHeight='calc(100vh - 184px)'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
        },
      ]}
    >
      <CloseIcon className='close-modal-icon' fill={palette.red500} onClick={onClose} />
      <iframe
        src={pdfUrl}
        title='testPdf'
        id='pdf-iframe'
        height='458px'
        width='100%'
        style={{ height: 'calc(100vh - 224px)' }}
      />
    </Modal>
  );
};

export default ViewPdf;
