import moment from 'moment';

const doDateRangesOverlap = (start1, end1, start2, end2) => {
  return start1.isBefore(end2) && start2.isBefore(end1);
};

export const availabilityDataConverter = (data, convertToCustomerTime, dateRange, type) => {
  // Eventually the business logic became so complicated here so do not blame me for this piece of shit

  return data.map((item) => {
    let booked_from;
    let booked_to;
    let total_miles = 0;
    let driver_gross_revenue = 0;
    const shipmentsAndAbsences = [];

    item.shipments?.forEach((shipment) => {
      if (
        type === 'planner-match' ||
        type === 'calendar' ||
        (type === 'list' &&
          doDateRangesOverlap(
            moment(dateRange[0] || '2000-01-01').startOf('day'),
            moment(dateRange[1] || moment().add(100, 'year')).endOf('day'),
            moment(`${shipment.shipment_stops[0].scheduled_date} ${shipment.shipment_stops[0].from}`),
            moment(
              [1, 3].includes(Number(shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_type))
                ? `${
                    shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date_to ||
                    shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date
                  } ${shipment.shipment_stops[shipment.shipment_stops.length - 1].to}`
                : `${shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date} ${
                    shipment.shipment_stops[shipment.shipment_stops.length - 1].from
                  }`
            )
          ))
      ) {
        shipmentsAndAbsences.push({ ...shipment, entryType: 'shipment' });

        if (
          !doDateRangesOverlap(
            moment(dateRange[0] || '2000-01-01').startOf('day'),
            moment(dateRange[1] || moment().add(100, 'year')).endOf('day'),
            moment(`${shipment.shipment_stops[0].scheduled_date} ${shipment.shipment_stops[0].from}`),
            moment(
              [1, 3].includes(Number(shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_type))
                ? `${
                    shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date_to ||
                    shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date
                  } ${shipment.shipment_stops[shipment.shipment_stops.length - 1].to}`
                : `${shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date} ${
                    shipment.shipment_stops[shipment.shipment_stops.length - 1].from
                  }`
            )
          )
        ) {
          return;
        }

        total_miles += Math.round(Number(shipment.loaded_miles || 0)) + Math.round(Number(shipment.empty_miles || 0));
        driver_gross_revenue += shipment.shipment_billing.reduce(
          (acc, cur) => acc + (Number(cur.total_amount) || 0),
          0
        );

        if (
          !booked_from ||
          moment(`${shipment.shipment_stops[0].scheduled_date} ${shipment.shipment_stops[0].from}`).isBefore(
            booked_from
          )
        ) {
          booked_from = `${shipment.shipment_stops[0].scheduled_date} ${shipment.shipment_stops[0].from}`;
        }

        if (
          !booked_to ||
          moment(
            [1, 3].includes(Number(shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_type))
              ? `${
                  shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date_to ||
                  shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date
                } ${shipment.shipment_stops[shipment.shipment_stops.length - 1].to}`
              : `${shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date} ${
                  shipment.shipment_stops[shipment.shipment_stops.length - 1].from
                }`
          ).isAfter(booked_to)
        ) {
          booked_to = [1, 3].includes(
            Number(shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_type)
          )
            ? `${
                shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date_to ||
                shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date
              } ${shipment.shipment_stops[shipment.shipment_stops.length - 1].to}`
            : `${shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date} ${
                shipment.shipment_stops[shipment.shipment_stops.length - 1].from
              }`;
        }
      }
    });

    item.absences?.forEach((absence) => {
      if (
        type === 'planner-match' ||
        type === 'calendar' ||
        (type === 'list' &&
          doDateRangesOverlap(
            moment(dateRange[0] || '2000-01-01').startOf('day'),
            moment(dateRange[1] || moment().add(100, 'year')).endOf('day'),
            moment(absence.start_date),
            moment(absence.end_date)
          ))
      ) {
        shipmentsAndAbsences.push({ ...absence, entryType: 'absence' });

        if (
          !doDateRangesOverlap(
            moment(dateRange[0] || '2000-01-01').startOf('day'),
            moment(dateRange[1] || moment().add(100, 'year')).endOf('day'),
            moment(absence.start_date),
            moment(absence.end_date)
          )
        ) {
          return;
        }

        if (!booked_from || moment(absence.start_date).isBefore(booked_from)) {
          booked_from = absence.start_date;
        }

        if (!booked_to || moment(absence.end_date).isAfter(booked_to)) {
          booked_to = absence.end_date;
        }
      }
    });

    const sortedShipmentsAndAbsences = shipmentsAndAbsences.sort((a, b) => {
      const endDateA =
        a.entryType === 'shipment'
          ? [1, 3].includes(Number(a.shipment_stops[a.shipment_stops.length - 1].scheduled_type))
            ? `${
                a.shipment_stops[a.shipment_stops.length - 1].scheduled_date_to ||
                a.shipment_stops[a.shipment_stops.length - 1].scheduled_date
              } ${a.shipment_stops[a.shipment_stops.length - 1].to}`
            : `${a.shipment_stops[a.shipment_stops.length - 1].scheduled_date} ${
                a.shipment_stops[a.shipment_stops.length - 1].from
              }`
          : moment(a.end_date).endOf('day');
      const endDateB =
        b.entryType === 'shipment'
          ? [1, 3].includes(Number(b.shipment_stops[b.shipment_stops.length - 1].scheduled_type))
            ? `${
                b.shipment_stops[b.shipment_stops.length - 1].scheduled_date_to ||
                b.shipment_stops[b.shipment_stops.length - 1].scheduled_date
              } ${b.shipment_stops[b.shipment_stops.length - 1].to}`
            : `${b.shipment_stops[b.shipment_stops.length - 1].scheduled_date} ${
                b.shipment_stops[b.shipment_stops.length - 1].from
              }`
          : moment(b.end_date).endOf('day');

      return moment(endDateA).isAfter(endDateB) ? 1 : moment(endDateA).isBefore(endDateB) ? -1 : 0;
    });

    const lastItem = sortedShipmentsAndAbsences[sortedShipmentsAndAbsences.length - 1];

    const lastItemEndDate = lastItem
      ? (lastItem.entryType === 'shipment'
          ? moment(
              [1, 3].includes(Number(lastItem.shipment_stops[lastItem.shipment_stops.length - 1].scheduled_type))
                ? `${
                    lastItem.shipment_stops[lastItem.shipment_stops.length - 1].scheduled_date_to ||
                    lastItem.shipment_stops[lastItem.shipment_stops.length - 1].scheduled_date
                  } ${lastItem.shipment_stops[lastItem.shipment_stops.length - 1].to}`
                : `${lastItem.shipment_stops[lastItem.shipment_stops.length - 1].scheduled_date} ${
                    lastItem.shipment_stops[lastItem.shipment_stops.length - 1].from
                  }`
            ).add(
              Number(
                lastItem.shipment_stops[lastItem.shipment_stops.length - 1]?.stop_point?.average_waiting_time || 0
              ),
              'minutes'
            )
          : moment(lastItem.end_date).endOf('day')
        ).format('YYYY-MM-DD HH:mm')
      : null;

    const equipment = item.driver_info.equipment?.[0];
    let nextAvailability = null;

    if (lastItem && moment(lastItemEndDate).isBefore(moment(convertToCustomerTime(equipment?.next_available_date)))) {
      nextAvailability = {
        start: lastItemEndDate,
        end: convertToCustomerTime(equipment?.next_available_date),
        title:
          item.status === 2
            ? `Not Available - ${equipment.next_available_location} ➝ ${convertToCustomerTime(
                equipment?.next_available_date
              )}`
            : `Updated Next Availability to ${equipment.next_available_location} ➝ ${convertToCustomerTime(
                equipment?.next_available_date
              )}`,
        entryType: 'nextAvailability',
        status: item.status,
      };
    }

    const planners = (item.planners || []).map((shipment) => {
      return {
        ...shipment,
        grossRevenue: shipment.shipment_billing.reduce((acc, cur) => acc + (Number(cur.total_amount) || 0), 0),
        totalMiles: Number(shipment.loaded_miles || 0) + Number(shipment.empty_miles || 0),
      };
    });

    return {
      ...item,
      ...item.driver_info,
      booked_from,
      booked_to,
      total_miles,
      driver_gross_revenue,
      shipmentsAndAbsences: sortedShipmentsAndAbsences,
      planners,
      nextAvailability,
    };
  });
};
