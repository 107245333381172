import { object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  policy_id: string().trim().required('Required').nullable(),
  expire_date: validator.futureRequiredDate(),
  attachment: string().trim().required('Required').nullable(),
  status: object()
    .shape({ value: string().required('Required') })
    .required('Required')
    .nullable(),
});
