import styled from 'styled-components';
import { palette } from '../../../utils/constants';

export const SWrapper = styled.div`
  background-color: ${({ $bgColor }) => $bgColor || palette.white};
`;

export const SHeader = styled.div``;

export const SCompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${palette.white};
  align-items: center;
  padding: 20px 0 16px;
  min-height: 116px;

  .logo-wrapper {
    min-height: 48px;

    img {
      object-fit: contain;
    }
  }

  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    padding: 20px 8px 16px;
    .logo-wrapper {
      width: 50%;

      img {
        width: 100%;
      }
    }

    & > * {
      flex: unset;
    }
  }
`;

export const SFooter = styled.div`
  background-color: ${palette.white};
  border-top: 1px solid ${palette.gray50};
  min-height: 50px;
  padding: 16px 40px 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ step }) => (step === 0 ? 'row-reverse' : 'row')};
  align-items: center;

  .next-actions {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 16px;
    min-height: unset;
  }
`;

export const ScrollableContainer = styled.div`
  overflow: auto;
  background: ${palette.white};
  padding: 20px 20px 16px;
`;

export const SAddressFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    span {
      font-size: 12px !important;
    }
  }
`;
