import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getWeatherFactorGraph } from 'Api/AccidentsAndCrashes';
import WeatherFactorsChart from '../Charts/WeatherFactorsChart';
import { SBoxWrapper } from '../../StatsSection.styles';

export const WeatherFactors = ({ statsRefreshIndex }) => {
  const [countsData, setCountsData] = useState([]);

  const getCounts = async () => {
    try {
      const params = {};
      const { data } = await getWeatherFactorGraph(params);
      setCountsData(data.weather_factor);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCounts();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex flex-column gap-1'>
        <Typography variant='s2'>WEATHER FACTORS</Typography>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          CONDITION DURING ACCIDENT
        </Typography>
      </div>
      <div className='chart-wrapper'>
        <WeatherFactorsChart chartData={countsData} />
      </div>
    </SBoxWrapper>
  );
};
