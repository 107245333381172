import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import PhoneInput from 'common/PhoneInput';
import Autocomplete from 'common/Autocomplete';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import Input, { InputWithText } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { getAccountsList } from 'Api/Accounts';

import { createVendor, getPaymentTerms, updateVendor, getNextVendorId } from 'Api/Vendors';
import { validationSchema } from './validationSchemas';
import { getInitialValues } from './AddVendor.data';

const AddVendor = ({ open, onClose, onSuccess, vendor }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [vendorId, setVendorId] = useState('');
  const [paymentTerms, setPaymentTerms] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new URLSearchParams();
      formData.append('name', values.name);
      formData.append('phone_number', values.phone_number);
      formData.append('email', values.email);
      formData.append('website', values.website || '');
      formData.append('address', values.address);
      formData.append('address2', values.address2 || '');
      formData.append('payment_term_id', values.payment_term.id);
      formData.append('account_id', values.account.id);
      formData.append('account_custom_id', values.account_custom_id || '');
      formData.append('bank_name', values.bank_name || '');
      formData.append('bank_account_number', values.bank_account_number || '');
      formData.append('routing_number', values.routing_number || '');
      formData.append('add_to_report', Number(values.add_to_report));
      formData.append('tax_id', values.tax_id || '');
      formData.append('zipcode', values.zipcode || '');
      formData.append('city_id', values.city?.id || '');
      formData.append('state_id', values.state?.id || '');
      formData.append('country_id', values.country?.id || '');
      let createdVendor;

      if (vendor) {
        const { data } = await updateVendor(vendor.id, formData);
        createdVendor = data;
      } else {
        const { data } = await createVendor(formData);
        createdVendor = data;
      }
      showToaster({ type: 'success', message: `Vendor has been successfully ${vendor ? 'updated' : 'created'}!` });
      onSuccess(createdVendor);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(vendor),
    validationSchema,
    onSubmit,
  });

  const getAccountOptions = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(
        data.filter((i) =>
          [
            ACCOUNT_TYPE.EXPENSE,
            ACCOUNT_TYPE.OTHER_CURRENT_ASSET,
            ACCOUNT_TYPE.COST_OF_GOODS_SOLD,
            ACCOUNT_TYPE.OTHER_EXPENSE,
          ].includes(i.account_type?.id)
        )
      );
    } catch (e) {
      // Do nothing
    }
  };

  const getPaymentTermOptions = async () => {
    try {
      const { data } = await getPaymentTerms();
      setPaymentTerms(
        (data || []).map((item) => ({ ...item, name: item.id === 4 ? 'Due on Discount' : `Net ${item.net_day}` }))
      );
    } catch (e) {
      // Do nothing
    }
  };

  const getVendorId = async () => {
    try {
      const { data } = await getNextVendorId();
      setVendorId(data.next_id);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (!vendor) {
      getVendorId();
    }
    getAccountOptions();
    getPaymentTermOptions();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={vendor ? 'Update Vendor' : 'Add Vendor'}
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: vendor ? 'Update' : 'Add Vendor',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-3'>
        <div className='d-flex flex-column gap-2'>
          <Input
            required
            label='Vendor Name'
            placeholder='Name'
            name='name'
            value={values.name}
            onChange={handleChange}
            error={touchedErrors.name}
          />
          {!vendor && (
            <Typography variant='c1' style={{ color: palette.gray700 }}>
              Vendor ID: {vendorId}
            </Typography>
          )}
        </div>
        <PhoneInput
          name='phone_number'
          placeholder='+1 (555) 555-1234'
          label='Phone Number'
          onChange={(val) => handleChange('phone_number', val)}
          value={values.phone_number}
          error={touchedErrors.phone_number}
          required
        />
        <Input
          required
          label='Email'
          placeholder='Email'
          name='email'
          value={values.email}
          onChange={handleChange}
          error={touchedErrors.email}
        />
        <InputWithText
          left
          label='Website'
          className='w-100'
          text='https://'
          name='website'
          onChange={handleChange}
          value={values.website}
          placeholder='www.website.com'
        />
        <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
        <Input
          label='Account Custom ID'
          name='account_custom_id'
          value={values.account_custom_id}
          onChange={handleChange}
          error={touchedErrors.account_custom_id}
        />
        <Input
          label={
            <>
              Bank Name <span style={{ color: palette.indigo500 }}>(Optional for Bill Pay)</span>
            </>
          }
          placeholder='ABCD Bank'
          name='bank_name'
          value={values.bank_name}
          onChange={handleChange}
          error={touchedErrors.bank_name}
        />
        <Input
          label={
            <>
              Bank Account Number <span style={{ color: palette.indigo500 }}>(Optional for Bill Pay)</span>
            </>
          }
          placeholder='00000000000'
          name='bank_account_number'
          value={values.bank_account_number}
          onChange={handleChange}
          error={touchedErrors.bank_account_number}
        />
        <Input
          label={
            <>
              Routing Number <span style={{ color: palette.indigo500 }}>(Optional for Bill Pay)</span>
            </>
          }
          placeholder='000 000 000'
          name='routing_number'
          value={values.routing_number}
          onChange={handleChange}
          error={touchedErrors.routing_number}
        />
        <div>
          <Autocomplete
            required
            label='Payment Term'
            name='payment_term'
            options={paymentTerms}
            value={values.payment_term}
            onChange={(e, val) => handleChange('payment_term', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.payment_term}
          />
        </div>
        <div>
          <Input label='Tax ID' name='tax_id' value={values.tax_id} onChange={handleChange} className='mb-2' />
          <CustomCheckbox
            name='add_to_report'
            checked={!!values.add_to_report}
            onChange={(checked) => handleChange('add_to_report', checked ? 1 : 0)}
          >
            <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 8, whiteSpace: 'nowrap' }}>
              Add to 1099-MISC Reports
            </Typography>
          </CustomCheckbox>
        </div>
        <div className='flex-grow-1'>
          <Autocomplete
            required
            label='Account'
            name='account'
            labelKey='account_name'
            options={accounts}
            value={values.account}
            onChange={(e, val) => handleChange('account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.account}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddVendor;
