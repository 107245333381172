import React, { useState, useEffect, useContext } from 'react';

import { Typography } from 'components/Typography';
import { getProductivityEssentialsSetting, updateProductivityEssentialsSetting } from 'Api/CompanySettings';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getErrorMessage } from 'utils/error';
import { LayoutContext } from 'components/layout';
import styles from './ProductivityEssentials.module.css';

const ProductivityEssentials = () => {
  const [productivityEssential, setProductivityEssential] = useState({});
  const showToaster = useShowToaster();
  const { use } = useTheme();

  const { setFavoritesOnHeader, setOpenInNewTab } = useContext(LayoutContext);
  const getProductivityEssential = async () => {
    getProductivityEssentialsSetting()
      .then((res) => {
        if (res) {
          setProductivityEssential(res);
          setFavoritesOnHeader(res.favorite_on_header);
          setOpenInNewTab(res.open_in_new_tab);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  useEffect(() => {
    getProductivityEssential();
  }, []);

  // update favorites setting to show on header
  const handleChangeToggle = (values) => {
    const formData = new FormData();
    formData.append('favorite_on_header', values ? 1 : 0);
    updateProductivityEssentialsSetting(formData)
      .then(() => {
        showToaster({ type: 'success', message: `Changes have been successfully updated!` });
        getProductivityEssential();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const handleChangeOpenMenuLinks = (values) => {
    const formData = new FormData();
    formData.append('open_in_new_tab', values ? 1 : 0);

    updateProductivityEssentialsSetting(formData)
      .then(() => {
        showToaster({ type: 'success', message: `Changes have been successfully updated!` });
        getProductivityEssential();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  return (
    <div className='mb-5'>
      <div className={styles.productivityEssential_header}>
        <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
          Productivity Essentials
        </Typography>
      </div>
      <div className={styles.basic_wrapper}>
        <label className={styles.actions}>
          <Typography
            variant='s2'
            style={{
              color: use(palette.gray700, palette.gray200),
              whiteSpace: 'nowrap',
            }}
          >
            Show Favorites on Header
          </Typography>
          <div>
            <CustomCheckbox
              onChange={(checked) => handleChangeToggle(checked)}
              name='favorite_on_header'
              type='switch'
              smail={false}
              checked={productivityEssential.favorite_on_header}
            />
          </div>
        </label>
        <label className={styles.actions}>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), whiteSpace: 'nowrap' }}>
            Open Menu Links in New Tab
          </Typography>
          <div>
            <CustomCheckbox
              smail={false}
              type='switch'
              name='open_in_new_tab'
              checked={productivityEssential?.open_in_new_tab}
              onChange={(checked) => handleChangeOpenMenuLinks(checked)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export default ProductivityEssentials;
