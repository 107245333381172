import React from 'react';
import { ReactComponent as TopArrow } from 'assets/icons/tableSortArrowTop.svg';
import { ReactComponent as DownArrow } from 'assets/icons/tableSortArrowDown.svg';
import { ReactComponent as TopArrowActive } from 'assets/icons/tableSortArrowTopActive.svg';
import { ReactComponent as DownArrowActive } from 'assets/icons/tabelSortArrowDownActive.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SWrapper } from './ColumnHeader.styles';

const Top = ({ sort, field }) => {
  const { use } = useTheme();

  if (sort?.field !== field) {
    return <TopArrow />;
  }

  return sort?.sortBy === 'asc'
    ? use(<TopArrowActive />, <TopArrow />)
    : use(<TopArrow />, <TopArrowActive fill={use(palette.dark800, palette.gray700)} />);
};

const Bottom = ({ sort, field }) => {
  const { use } = useTheme();

  if (sort?.field !== field) {
    return <DownArrow />;
  }

  return sort?.sortBy === 'asc'
    ? use(<DownArrow />, <DownArrowActive fill={use(palette.dark800, palette.gray700)} />)
    : use(<DownArrowActive />, <DownArrow />);
};

const ColumnHeader = ({ field, title, onClick, sort }) => {
  const { use } = useTheme();

  return (
    <SWrapper onClick={() => onClick(field)}>
      <span className='column-title' style={{ color: use(palette.gray900, palette.white) }}>
        {title}
      </span>
      <span className='sort-icons'>
        <Top sort={sort} field={field} />
        <Bottom sort={sort} field={field} />
      </span>
    </SWrapper>
  );
};

export default ColumnHeader;
