import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Delete } from 'assets/icons/xClose.svg';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import Input, { InputWithText } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { validationSchema } from './validateSchema';
import { SActions } from '../PaymentTerms.styles';
import { SWrapper } from './ItemForm.styles';

const ItemForm = ({ onSubmit, onDelete, loading, editable, onCancel, item }) => {
  const initialValues = {
    title: item?.title || '',
    net_day: item?.net_day || '',
    discount: item?.discount || '',
  };
  const { handleChange, handleSubmit, values, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <SWrapper>
      <div className='flex-item'>
        <Input
          name='title'
          onChange={handleChange}
          value={values.title}
          style={{ width: '100%' }}
          error={touchedErrors.title}
        />
      </div>
      <div className='flex-item'>
        <Input
          type='number'
          name='net_day'
          onChange={handleChange}
          value={values.net_day}
          style={{ width: '100px' }}
          onKeyDown={(e) => {
            blockNotNumberChars(e);
            blockNonPositiveNumbers(e);
          }}
          error={touchedErrors.net_day}
        />
      </div>
      <div className='flex-item'>
        <InputWithText
          type='number'
          width='60px'
          text='%'
          name='discount'
          onKeyDown={(e) => {
            blockNonPositiveNumbers(e);
            blockNotNumberChars(e);
          }}
          onChange={(e) => {
            if (Number(e.target.value) <= 100) {
              handleChange('discount', e.target.value);
            }
          }}
          value={values.discount}
        />
      </div>
      <SActions className='flex-item'>
        {!!editable && (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '1px 6px', margin: 0 }}
            onClick={onCancel}
          />
        )}
        {loading ? (
          <span className='loading-wrapper'>
            <CircularProgress size={16} />
          </span>
        ) : (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title=''
            leftIcon={<Delete style={{ fill: palette.white }} />}
            styleButton={{ padding: '7px 8px', margin: 0, backgroundColor: palette.red500 }}
            onClick={onDelete}
          />
        )}
        <CustomButton
          width={12}
          height={12}
          type='primary'
          title=''
          leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={handleSubmit}
        />
      </SActions>
    </SWrapper>
  );
};

export default ItemForm;
