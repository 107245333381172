import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrows.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getTaxesList } from 'Api/PayrollSettings';
import { deleteTax } from 'Api/Payroll';
import { getErrorMessage } from 'utils/error';
import DisputedActions from 'pages/Payroll/shared/DisputedActions';
import TaxesSettings from './components/TaxesSettings';
import DeclineReason from '../../components/DeclineReason';
import { calcAppliedTaxAmount, getTaxAmount } from '../../helpers';
import { userTypes } from '../Tables.data';
import { getInitialValues } from './Taxes.data';
import { STable, SWrapper, SAddMore, SCheckboxWrapper, SPickerWrapper, SAutocomplete, SInput } from '../Tables.styles';

const Taxes = ({ taxes, updateTaxes, gross, user_type, settlement, deductions, additions }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loadingDeleteId, setLoadingDeleteId] = useState(null);
  const [taxTypes, setTaxTypes] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [taxToDecline, setTaxToDecline] = useState(null);

  const totalAppliedTaxes = useMemo(() => {
    return calcAppliedTaxAmount(taxes || [], deductions || [], additions || [], gross) || 0;
  }, [taxes, deductions, additions, gross]);

  const getTaxTypes = async () => {
    try {
      const { data } = await getTaxesList();
      setTaxTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onMouseEnter = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(row.id);
    const newTaxes = taxes.map((item) => (item.id === row.id ? { ...item, edit: true } : { ...item, edit: false }));
    updateTaxes(newTaxes);
  };

  const onMouseLeave = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(null);
    const newTaxes = taxes.map((item) => (item.id === row.id ? { ...item, edit: false } : item));
    updateTaxes(newTaxes);
  };

  const onDateChange = (id, value) => {
    const date = moment(value).format('MM/DD/YYYY');
    const time = moment(value).format('HH:mm:ss');

    const newTaxes = taxes.map((item) => (item.id === id ? { ...item, date, time } : item));
    updateTaxes(newTaxes);
  };

  const onTypeChange = (id, value) => {
    const newTaxes = taxes.map((item) =>
      item.id === id
        ? { ...item, tax_type: value, percent: value.percent, amount: (gross * value.percent) / 100 }
        : item
    );
    updateTaxes(newTaxes);
  };

  const onPercentChange = (id, value) => {
    const newTaxes = taxes.map((item) =>
      item.id === id ? { ...item, percent: value, amount: (gross * value) / 100 } : item
    );
    updateTaxes(newTaxes);
  };

  const onAutoDeductChange = (id, value) => {
    const newTaxes = taxes.map((item) => (item.id === id ? { ...item, auto_deduct: Number(value) } : item));
    updateTaxes(newTaxes);
  };

  const onApplyChange = (id, checked) => {
    const newTaxes = taxes.map((item) => {
      return item.id === id ? { ...item, apply: checked } : item;
    });
    updateTaxes(newTaxes);
  };

  const removeTax = async (row) => {
    if (row.isNew) {
      const newTaxes = taxes.filter((item) => item.id !== row.id);
      updateTaxes(newTaxes);
      return;
    }
    setLoadingDeleteId(row.id);
    try {
      await deleteTax({ user_type: userTypes[user_type], id: row.id });
      updateTaxes((prevState) => prevState.filter((item) => item.id !== row.id));
      showToaster({ type: 'success', message: 'Tax has been deleted successfully' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingDeleteId(null);
  };

  const onAddMore = () => {
    updateTaxes((prevState) => [...prevState, { ...getInitialValues(), id: Date.now() }]);
  };

  const onSettingsClose = () => {
    getTaxTypes();
    setSettingsOpen(false);
  };

  const onDecline = async (reason) => {
    const newTaxes = taxes.map((item) =>
      item.id === taxToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
    );
    updateTaxes(newTaxes);
    setTaxToDecline(null);
  };

  const onApprove = (tax) => {
    const newTaxes = taxes.map((item) => (item.id === tax.id ? { ...item, dispute_approved: true } : item));
    updateTaxes(newTaxes);
  };

  useEffect(() => {
    getTaxTypes();
  }, []);

  return (
    <SWrapper>
      <Typography variant='h3'>Taxes</Typography>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>DATE / TIME</th>
            <th>
              TAX TYPE
              <GearIcon onClick={() => setSettingsOpen(true)} className='settings-gear' />
            </th>
            <th />
            <th>PERCENT</th>
            <th>TOTAL</th>
            <th>SET INTERVAL</th>
            <th>APPLY TO SETTLEMENT</th>
          </tr>
        </thead>
        <tbody>
          {taxes?.map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.isNew ? 'bg-gray' : ''} ${
                  item.disputed && settlement.status_id === 4 ? 'disputed' : ''
                } ${item.dispute_approved ? 'dispute-approved' : ''}`}
                onMouseEnter={() => onMouseEnter(item)}
                onMouseLeave={() => onMouseLeave(item)}
              >
                <td style={{ width: '16%' }}>
                  {item.edit || item.isNew ? (
                    <SPickerWrapper>
                      <DatePicker
                        showTimeInput
                        showYearDropdown
                        selected={item.date && item.time ? new Date(`${item.date} ${item.time}`) : ''}
                        placeholderText='mm/dd/yyyy hh:mm'
                        dateFormat='MM/dd/yyyy h:mm aa'
                        onChange={(val) => onDateChange(item.id, val)}
                      />
                      <ArrowIcon className='arrow-icon' width={11} height={11} />
                    </SPickerWrapper>
                  ) : (
                    <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                      {formatDateTime(`${item.date} ${item.time}`)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '25%' }}>
                  {item.edit || item.isNew ? (
                    <SAutocomplete
                      labelKey='tax_type'
                      value={item.tax_type || null}
                      onChange={(e, value) => onTypeChange(item.id, value)}
                      isOptionEqualToValue={(option, value) => option.tax_type === value.tax_type}
                      options={taxTypes}
                    />
                  ) : (
                    <Typography variant='b2'>{item.tax_type?.tax_type || 'N/A'}</Typography>
                  )}
                </td>
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }}>
                  {item.edit || item.isNew ? (
                    <SInput
                      type='number'
                      value={item.percent || ''}
                      onChange={(e) => onPercentChange(item.id, e.target.value)}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {item.percent}%
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  <Typography variant='button2' className='nowrap'>
                    - {currency}
                    {formatNumber(getTaxAmount({ tax: item, deductions, additions, gross }))}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox
                      field={{ id: 'auto-deduct' }}
                      checked={!!item.auto_deduct}
                      onChange={(checked) => onAutoDeductChange(item.id, checked)}
                    />
                    <Typography
                      as='label'
                      htmlFor='auto-deduct'
                      variant='s2'
                      style={{ color: use(palette.gray700, palette.gray50), marginTop: 0 }}
                    >
                      Auto deduct taxes on each settlement
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '10%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox checked={!!item.apply} onChange={(value) => onApplyChange(item.id, value)} />
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Deduct Tax
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '5%' }}>
                  {loadingDeleteId === item.id ? (
                    <span className='loading-wrapper'>
                      <CircularProgress size={16} />
                    </span>
                  ) : (
                    <span className='action-wrapper' onClick={() => removeTax(item)}>
                      <DeleteIcon width={9} height={9} fill={palette.red500} />
                    </span>
                  )}
                </td>
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''} ${
                    item.dispute_approved ? 'dispute-approved-actions' : ''
                  }`}
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={() => onMouseLeave(item)}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setTaxToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          <tr className='total-row'>
            <td>
              <SAddMore onClick={onAddMore}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                  Add Another...
                </Typography>
              </SAddMore>
            </td>
            <td />
            <td colSpan='2' className='align-end'>
              <Typography variant='button2' className='nowrap'>
                Total applied amount:
              </Typography>
            </td>
            <td>
              <Typography variant='button2' className='nowrap'>
                - {currency}
                {formatNumber(totalAppliedTaxes || 0)}
              </Typography>
            </td>
            <td colSpan='3' />
          </tr>
        </tbody>
      </STable>
      <TaxesSettings open={settingsOpen} onClose={onSettingsClose} />
      <DeclineReason open={!!taxToDecline} onClose={() => setTaxToDecline(null)} onSubmit={onDecline} />
    </SWrapper>
  );
};

export default Taxes;
