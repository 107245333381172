import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomButton from 'components/CustomButton/CustomButton';
import { deleteDepartment, GetSettingsDepartments } from 'Api/CompanySettings';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import RowActions from './components/RowActions';
import AddDepartment from './components/AddDepartment';
import styles from './Departments.module.css';

const Departments = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [departments, setDepartments] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [openAddDepartment, setOpenAddDepartment] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getDepartments = () => {
    return GetSettingsDepartments().then((res) => {
      setDepartments(res.data);
    });
  };

  const onDeleteConfirm = async () => {
    setLoadingDelete(true);
    try {
      await deleteDepartment(departmentToDelete.id);
      showToaster({ type: 'success', message: 'Department has been successfully deleted!' });
      getDepartments();
      setDepartmentToDelete(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    getDepartments().then();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header_wrapper}>
          <div>
            <Typography variant='h4'>Departments & Permissions</Typography>
          </div>
          <div className={styles.actions}>
            {isConfiguringStep && (
              <>
                {' '}
                <CustomButton
                  onClick={onCancelPrevPage}
                  type='secondary'
                  title='Back'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
                <CustomButton
                  onClick={onSaveAndNext}
                  type='secondary'
                  title='Skip'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
              </>
            )}
            <CustomButton
              type='secondary'
              title='Add Department'
              leftIcon={<PlusIcon style={{ marginRight: 10, fill: '#464F60' }} />}
              styleButton={{ margin: 0 }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              onClick={() => setOpenAddDepartment(true)}
            />
          </div>
        </div>
        <div className={styles.wrapper2}>
          <div className={styles.lines}>
            <div className={styles.left}>
              <Typography variant='overLine'>NAME</Typography>
            </div>
            <div className={styles.right}>
              <Typography variant='overLine'>PERMISSIONS</Typography>
            </div>
            <div className={styles.right2}>
              <Typography variant='overLine'>DASHBOARD WIDGETS</Typography>
            </div>
            <div style={{ width: '50px' }} />
          </div>
        </div>
        <div className={styles.wrapper2}>
          <div className={styles.body}>
            {departments?.map((el) => {
              return (
                <div key={el?.department_name} className={styles.row}>
                  <div className={styles.left}>
                    <Typography variant='s2'>{el?.department_name}</Typography>
                  </div>
                  <div className={styles.right}>
                    {el?.permissions?.map((item) => {
                      return (
                        <span key={item?.permission_name} className={styles.permission}>
                          {item?.permission_name}
                        </span>
                      );
                    })}
                  </div>
                  <div className={styles.right2}>
                    {el?.dashboard_tab_permission?.map((item) => {
                      return (
                        <span key={item?.dashboard_tab?.id} className={styles.permission}>
                          {item?.dashboard_tab?.tab_name}
                        </span>
                      );
                    })}
                  </div>
                  <div style={{ width: '50px' }}>
                    <RowActions
                      data={el}
                      setUpdateData={(row) => {
                        setOpenAddDepartment(true);
                        setUpdateData(row);
                      }}
                      setDepartmentToDelete={setDepartmentToDelete}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ConfirmationModal
        title='Department'
        open={!!departmentToDelete}
        onClose={() => setDepartmentToDelete(null)}
        onConfirm={onDeleteConfirm}
        disabled={loadingDelete}
      />
      {openAddDepartment && (
        <AddDepartment
          open={openAddDepartment}
          onClose={() => {
            setOpenAddDepartment(false);
            setUpdateData(null);
          }}
          department={updateData}
          onSuccess={getDepartments}
        />
      )}
    </>
  );
};
export default Departments;
