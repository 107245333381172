import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import ViewPdf from 'components/ViewPdf';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { getTaxType, uploadDriverDocument } from 'Api/Driver';
import { CompensationOwnerValidationSchema } from '../Drivers/AddDriver/ValidationSchema';
import { CompensationContent } from '../Drivers/AddOwnerOperator/CompensationOwnerOperator';

const OwnerOperatorCompensation = ({
  updateCompensation,
  showError,
  onSuccess,
  loading,
  operatorCompansation,
  email_id,
}) => {
  const { currency } = useSelector((state) => state.root);
  const [toggle, setToggle] = useState(false);
  const [taxOption, setTaxOption] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { id } = useParams();
  const { use } = useTheme();
  useEffect(() => {
    getTaxType().then((res) => {
      setTaxOption(res?.data);
    });
  }, []);

  const taxDetails = [
    {
      property: 'Tax ID',
      value: operatorCompansation?.tax_id,
    },
    {
      property: 'Tax Type',
      value: operatorCompansation?.tax_type?.name,
    },
  ];

  const bankDetails = [
    {
      property: 'Bank Name',
      value: operatorCompansation?.bank_name,
    },
    {
      property: 'Direct Deposit Form',
      value: operatorCompansation?.deposit_form_path ? 'View' : '-',
    },
    {
      property: 'Routing Number',
      value: operatorCompansation?.routing,
    },
    {
      property: 'Account Number',
      value: operatorCompansation?.account,
    },
  ];

  useEffect(() => {
    if (!operatorCompansation) {
      formik.setFieldValue('tax_id', '');
      return;
    }
    formik.setFieldValue('tax_id', operatorCompansation?.tax_id || '');
    formik.setFieldValue('routing_confirmed', operatorCompansation?.routing);
    formik.setFieldValue('account_confirmed', operatorCompansation?.account);
    formik.setFieldValue('tax_type_id', operatorCompansation?.tax_type?.id);
    formik.setFieldValue('bank_name', operatorCompansation?.bank_name);
    formik.setFieldValue('routing', operatorCompansation?.routing);
    formik.setFieldValue('account', operatorCompansation?.account);
    formik.setFieldValue('deposit_form_path', operatorCompansation?.deposit_form_path);
    formik.setFieldValue('pay_option_id', String(operatorCompansation?.pay_option?.id));
    formik.setFieldValue('per_hour_worked', operatorCompansation?.per_hour_worked);
    formik.setFieldValue('break_time_allowed', operatorCompansation?.break_time_allowed);
    formik.setFieldValue('breaks_allowed_in_a_day', operatorCompansation?.breaks_allowed_in_a_day);
    formik.setFieldValue('pay_per_mile_empty', operatorCompansation?.pay_per_mile_empty);
    formik.setFieldValue('pay_per_mile_loaded', operatorCompansation?.pay_per_mile_loaded);
    formik.setFieldValue('pay_by_percentage', operatorCompansation?.pay_by_percentage || 0);
    formik.setFieldValue('pay_stubs_option', operatorCompansation?.pay_stubs_option);
    formik.setFieldValue(
      'pay_stubs_email',
      operatorCompansation?.pay_stubs_option === 1 ? operatorCompansation?.pay_stubs_email : ''
    );
    formik.setFieldValue('paid_time_off_option', operatorCompansation?.paid_time_off_option);
    formik.setFieldValue('paid_time_off_amount', operatorCompansation?.paid_time_off_amount);
    formik.setFieldValue('detention_pay_option', operatorCompansation?.detention_pay_option);
    formik.setFieldValue('detention_amt', operatorCompansation?.detention_amt);
    formik.setFieldValue('detention_hrs', operatorCompansation?.detention_hrs);
    formik.setFieldValue('layover_option', operatorCompansation?.layover_option);
    formik.setFieldValue('layover_amt', operatorCompansation?.layover_amt);
    formik.setFieldValue('stopoff_option', operatorCompansation?.stopoff_option);
    formik.setFieldValue('per_stop_amt', operatorCompansation?.per_stop_amt);
    formik.setFieldValue('no_of_stops', operatorCompansation?.no_of_stops);
    formik.setFieldValue('live_unload_option', operatorCompansation?.live_unload_option);
    formik.setFieldValue('unload_amt', operatorCompansation?.unload_amt);
    formik.setFieldValue('truck_order_unused_option', operatorCompansation?.truck_order_unused_option);
    formik.setFieldValue('each_truck_order_unused_amt', operatorCompansation?.each_truck_order_unused_amt);
    formik.setFieldValue('breakdown_option', operatorCompansation?.breakdown_option);
    formik.setFieldValue('after_breakdown_hrs', operatorCompansation?.after_breakdown_hrs);
    formik.setFieldValue('redelivery_option', operatorCompansation?.redelivery_option);
    formik.setFieldValue('after_first_attempt_amt', operatorCompansation?.after_first_attempt_amt);
    formik.setFieldValue('tarp_pay_option', operatorCompansation?.tarp_pay_option);
    formik.setFieldValue('isCompanyPolice', operatorCompansation?.isCompanyPolice);
    formik.setFieldValue('each_stop_amt', operatorCompansation?.each_stop_amt);
  }, [operatorCompansation]);

  const formik = useFormik({
    initialValues: {
      operator_id: id,
      tax_id: '',
      tax_type_id: '',
      bank_name: '',
      routing: '',
      routing_confirmed: '',
      account: '',
      account_confirmed: '',
      deposit_form_path: '',
      pay_option_id: '1',
      pay_per_mile_loaded: 0,
      pay_per_mile_empty: 0,
      pay_by_percentage: 0,
      pay_stubs_option: false,
      pay_stubs_email: '',
      detention_pay_option: false,
      detention_amt: 0,
      detention_hrs: 1,
      layover_option: false,
      layover_amt: 0,
      stopoff_option: false,
      per_stop_amt: 0,
      no_of_stops: 0,
      live_unload_option: false,
      unload_amt: 0,
      truck_order_unused_option: false,
      each_truck_order_unused_amt: 0,
      breakdown_option: 0,
      breakdown_amt: 0,
      redelivery_option: false,
      after_first_attempt_amt: 0,
      tarp_pay_option: false,
      each_stop_amt: 0,
      per_hour_worked: 0,
      break_time_allowed: 0,
      breaks_allowed_in_a_day: 0,
    },
    validationSchema: CompensationOwnerValidationSchema,
  });

  const saveCompensation = () => {
    if (formik.values.tax_type === 'Select') {
      showError('Please Select Tax type');
    } else {
      const payload = { ...operatorCompansation, ...formik.values };
      if (payload?.deposit_form_path && payload?.deposit_form_path.includes('https')) {
        delete payload.deposit_form_path;
      }
      updateCompensation(payload, null, successResponse, 'Compensation has been updated successfully');
    }
  };

  const successResponse = (msg) => {
    onSuccess(msg);
    setToggle(!toggle);
  };

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], id)
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  return (
    <div
      className='Driver-compansation-wrapper driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.dark600),
      }}
    >
      <div className='header' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Compensation
        </p>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={() => setToggle(!toggle)} className='driver-compansation-cancel-btn'>
              Cancel
            </button>

            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <button
                style={{ opacity: formik.isValid ? 1 : 0.7 }}
                disabled={!formik.isValid}
                onClick={() => {
                  saveCompensation();
                }}
                className='driver-compansation-save-btn'
              >
                Save
              </button>
            )}
          </div>
        )}
      </div>
      {toggle ? (
        <div className='driver-compansation-edit-mode'>
          <CompensationContent
            formik={formik}
            use={use}
            uploadDocument={uploadDocument}
            taxOption={taxOption}
            email_id={email_id}
          />
        </div>
      ) : (
        <div className='Driver-compansation'>
          <div>
            <div
              className='compansation-name-container'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                tax details
              </p>
            </div>
            <div className='details-container'>
              {taxDetails.map((item) => {
                return (
                  <div key={item.property} className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                      {item.property}
                    </p>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      {item.value}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              className='compansation-name-container'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray200) }}>
                Bank Details
              </p>
            </div>
            <div className='details-container'>
              {bankDetails.map((item) => {
                return (
                  <div key={item.property} className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {item.property}
                    </p>
                    {item.value === 'View' ? (
                      <p
                        className='details-value details-value-text'
                        style={{ color: '#4F5AED' }}
                        onClick={() => setPdfUrl(operatorCompansation?.deposit_form_path)}
                      >
                        View
                      </p>
                    ) : (
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.value}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              className='compansation-name-container'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                Payment Option
              </p>
            </div>
            {operatorCompansation?.pay_option?.name === 'Per Hour' ? (
              <div className='details-container-payemnt'>
                <div
                  className='Driver-compansation-details-payment'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Per Hour
                  </p>
                  <div className='payment-per-hour' style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}>
                    <p className='payment-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                      $ {operatorCompansation?.per_hour_worked}
                    </p>
                  </div>
                </div>
                <div className='Driver-compansation-details-wrapper'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                      Breaks
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight'
                        style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {operatorCompansation?.breaks_allowed_in_a_day}
                        </p>
                      </div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        Breaks Allowed In Given Work Day for{' '}
                      </p>
                      <div
                        className='details-value-highlight'
                        style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {operatorCompansation?.break_time_allowed} minutes
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Break Time Paid
                    </p>
                  </div>
                </div>
              </div>
            ) : operatorCompansation?.pay_option?.name === 'Per Mile' ? (
              <div className='details-container-payemnt'>
                <div
                  className='Driver-compansation-details-payment'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Per Mile
                  </p>
                </div>
                <div className='Driver-compansation-details-wrapper'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                      Loaded
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      {/* //operatorCompansation?.mile_loaded_amt */}
                      <div
                        className='details-value-highlight'
                        style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {currency}
                          {operatorCompansation?.pay_per_mile_empty}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Empty
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight'
                        style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {currency}
                          {operatorCompansation?.pay_per_mile_loaded}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : operatorCompansation?.pay_option?.name === 'Flat Rate' ? (
              <div className='details-container-payemnt'>
                <div
                  className='Driver-compansation-details-payment'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Flat Rate
                  </p>
                </div>
              </div>
            ) : operatorCompansation?.pay_option?.name === 'By Percentage' ? (
              <div className='details-container-payemnt'>
                <div
                  className='Driver-compansation-details-payment'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Percentage
                  </p>
                </div>
                <div className='Driver-compansation-details-wrapper'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                      Shipment Percentage
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight'
                        style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {`% ${operatorCompansation?.pay_by_percentage}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className='details-container-payemnt stups' style={{ borderTop: '1px solid #ededed' }}>
              <div className='Driver-compansation-details-wrapper '>
                <div className='Driver-compansation-details'>
                  <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                    I want to email pay stubs
                  </p>
                  <div className='Driver-compansation-details-wrap'>
                    <div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {operatorCompansation?.pay_stubs_email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className='compansation-name-container'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                Other Payment Conditions
              </p>
            </div>
            <div className='payment-conditions'>
              <div className='details-container-payemnt details-container-payemnt-condition'>
                <div className='Driver-compansation-details'>
                  <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Detention Pay
                  </p>
                  <div className='Driver-compansation-details-wrap'>
                    <div
                      className='details-value-highlight'
                      style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                    >
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {currency} {operatorCompansation?.detention_amt}
                      </p>
                    </div>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      per hour after
                    </p>
                    <div
                      className='details-value-highlight'
                      style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                    >
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {operatorCompansation?.detention_hrs}
                      </p>
                    </div>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      hour&#40;s&#41; have passed
                    </p>
                  </div>
                </div>
              </div>
              <div className='details-container-payemnt details-container-payemnt-condition'>
                <div className='Driver-compansation-details'>
                  <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Layover Pay
                  </p>
                  <div className='Driver-compansation-details-wrap'>
                    <div
                      className='details-value-highlight'
                      style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                    >
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {currency} {operatorCompansation?.layover_amt}
                      </p>
                    </div>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      for each layover
                    </p>
                  </div>
                </div>
              </div>
              <div className='details-container-payemnt details-container-payemnt-condition'>
                <div className='Driver-compansation-details'>
                  <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Stop Off Pay
                  </p>
                  <div className='Driver-compansation-details-wrap'>
                    <div
                      className='details-value-highlight'
                      style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                    >
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {currency} {operatorCompansation?.per_stop_amt}
                      </p>
                    </div>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      per each stop off after
                    </p>
                    <div
                      className='details-value-highlight'
                      style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                    >
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {operatorCompansation?.no_of_stops}
                      </p>
                    </div>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      stops
                    </p>
                  </div>
                </div>
              </div>
              <div className='details-container-payemnt details-container-payemnt-condition'>
                <div className='Driver-compansation-details'>
                  <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Truck Order Not Used Pay
                  </p>
                  <div className='Driver-compansation-details-wrap'>
                    <div
                      className='details-value-highlight'
                      style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                    >
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {currency} {operatorCompansation?.each_truck_order_unused_amt}
                      </p>
                    </div>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      per each truck order not used
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Document' />}
    </div>
  );
};

export default OwnerOperatorCompensation;
