import React, { forwardRef } from 'react';
import searchIcon from '../../assets/icons/header/Search.png';
import { SSearchInput, SWrapper } from './Search.styles';

const Search = forwardRef(({ search, onChange, margin, className, width }, ref) => {
  return (
    <SWrapper $margin={margin} $width={width}>
      <SSearchInput
        type='text'
        value={search}
        onChange={(e) => onChange && onChange(e.target.value)}
        placeholder='Search'
        className={className}
        ref={ref}
      />
      <img src={searchIcon} alt='Search' className='search-icon' />
    </SWrapper>
  );
});

export default Search;
