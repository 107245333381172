import React from 'react';
import HeaderStar from 'components/HeaderStar';
import CustomerHardware from './CustomerHardware/CustomerHardware';

const MyHardware = () => {
  return (
    <div className='equipment-table-header mt-3'>
      <div className='d-flex justify-content-between mb-3'>
        <HeaderStar title='My Hardware' />
      </div>
      <CustomerHardware />
    </div>
  );
};

export default MyHardware;
