import React, { useEffect, useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { ListManager } from 'react-beautiful-dnd-grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as ArrowDown } from 'assets/sidemanu/arrowDown.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowFourWays.svg';
import { ReactComponent as ArrowTwoWayIcon } from 'assets/icons/arrowsTwoWay.svg';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Actions from 'pages/Dashboard/EditWidgets/Actions';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { GetDepartments } from 'Api/CompanySettings';
import { updateTabDepartments, updateUserTabSettings } from 'Api/Dashboard';
import { SVerticalDrag, SWidget, SWidgetsList } from './EditWidgets.styles';

const EditWidgets = ({ onClose, onSuccess, initialWidgets }) => {
  const { value: userData } = useAuth();
  const showToaster = useShowToaster();
  const [anchorEl, setAnchorEl] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [widgets, setWidgets] = useState(initialWidgets);
  const [outerDrugDisabled, setOuterDrugDisabled] = useState(false);
  const allWidgets = useMemo(() => {
    return widgets.reduce((acc, cur) => [...acc, ...cur.widgets.map((item) => ({ ...item, sectionId: cur.id }))], []);
  }, [widgets]);

  const { values, handleChange } = useForm({
    initialValues: widgets,
    enableReinitialize: true,
  });

  const updatePermissions = async () => {
    const bodies = [];
    const allInnerWidgets = [];

    values.forEach((item) => {
      allInnerWidgets.push(item.widgets);
    });
    const allInnerFlattenWidgets = allInnerWidgets.flat();
    const widgetsToUpdate = allInnerFlattenWidgets.filter((i) => i.isUpdated);
    widgetsToUpdate.forEach((item) => {
      bodies.push({ dashboard_tab_id: item.id, departments: item.departments.map((i) => i.id) });
    });

    try {
      await Promise.all(bodies.map((body) => updateTabDepartments(body)));
    } catch (e) {
      // Do nothing
    }
  };

  const onSave = async () => {
    updatePermissions();
    try {
      const body = widgets.map((item, index) => ({
        id: item.id,
        order: index + 1,
        widgets: item.widgets.map((i, index) => ({ id: i.id, order: index + 1, active: Number(i.active) })),
      }));
      await updateUserTabSettings({ tab_data: body });
      onSuccess(widgets);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    const widgetsCopy = cloneDeep(widgets);
    const movableItem = widgetsCopy[source.index];

    widgetsCopy.splice(source.index, 1);
    widgetsCopy.splice(destination.index, 0, movableItem);

    setWidgets(widgetsCopy);
  };

  const onSubDragEnd = (startIndex, endIndex, id) => {
    const newWidgets = widgets.map((item) => {
      if (item.id === id) {
        const widgetsCopy = cloneDeep(item.widgets);
        const movableItem = widgetsCopy[startIndex];

        widgetsCopy.splice(startIndex, 1);
        widgetsCopy.splice(endIndex, 0, movableItem);

        return { ...item, widgets: widgetsCopy };
      }

      return item;
    });

    setWidgets(newWidgets);
  };

  const onCheckboxChange = (sectionId, widgetId, checked) => {
    const newWidgets = widgets.map((item) => {
      if (item.id === sectionId) {
        const newSubWidgets = item.widgets.map((i) => (i.id === widgetId ? { ...i, active: checked } : i));
        return { ...item, widgets: newSubWidgets };
      }

      return item;
    });

    setWidgets(newWidgets);
  };

  const onMouseEnter = () => {
    setOuterDrugDisabled(true);
  };

  const onMouseLeave = () => {
    setOuterDrugDisabled(false);
  };

  const getDepartmentsList = async () => {
    try {
      const { data } = await GetDepartments();
      setDepartments(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDepartmentsList();
  }, []);

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex gap-3 justify-content-end mb-4'>
        <ThreeDotActions
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          actionButton={
            <CustomButton
              type='secondary'
              title='Select'
              rightIcon={<ArrowDown style={{ marginLeft: 10 }} />}
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={() => null}
            />
          }
          popoverStyles={{ margin: '10px 0 0 0' }}
        >
          {allWidgets.map((item) => (
            <div key={item.id} className='p-1'>
              <CustomCheckbox
                checked={!!item.active}
                onChange={(checked) => onCheckboxChange(item.sectionId, item.id, checked)}
              >
                <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                  {item.title}
                </Typography>
              </CustomCheckbox>
            </div>
          ))}
        </ThreeDotActions>
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '6px 12px', margin: 0 }}
          onClick={onClose}
        />
        <CustomButton type='primary' title='Save' styleButton={{ padding: '6px 12px', margin: 0 }} onClick={onSave} />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='dashboard'>
          {(provided) => (
            <div className='d-flex flex-column gap-4' {...provided.droppableProps} ref={provided.innerRef}>
              {widgets.map((widget, index) => (
                <Draggable
                  key={widget.id}
                  draggableId={widget.id.toString()}
                  index={index}
                  isDragDisabled={outerDrugDisabled}
                >
                  {(provided) => (
                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                      <SVerticalDrag>
                        <div className='widget-section-title'>
                          <Typography variant='h5' style={{ color: palette.gray900 }}>
                            {widget.title}
                          </Typography>
                          <div className='d-flex align-items-center justify-content-center gap-2'>
                            <ArrowTwoWayIcon />
                            <Typography variant='s2' style={{ color: palette.gray500 }}>
                              Drag to swap
                            </Typography>
                          </div>
                          <div />
                        </div>
                        <SWidgetsList>
                          <ListManager
                            items={widget.widgets}
                            direction='horizontal'
                            maxItems={4}
                            render={(subWidget) => (
                              <SWidget onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                <div className='h-100'>
                                  <div className='w-100 d-flex justify-content-between align-items-center'>
                                    <CustomCheckbox
                                      checked={!!subWidget.active}
                                      onChange={(checked) => onCheckboxChange(widget.id, subWidget.id, checked)}
                                    >
                                      <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                                        {subWidget.title}
                                      </Typography>
                                    </CustomCheckbox>
                                    {userData.user?.department?.department_name === 'Management' ? (
                                      <Actions
                                        section={widget}
                                        widget={subWidget}
                                        departments={departments}
                                        values={values}
                                        handleChange={handleChange}
                                        sectionIndex={index}
                                      />
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                  <div className='drag-arrow-text d-flex align-items-center justify-content-center'>
                                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                                      <ArrowIcon className='me-2' /> Drag to swap
                                    </Typography>
                                  </div>
                                </div>
                              </SWidget>
                            )}
                            onDragEnd={(startIndex, endIndex) => onSubDragEnd(startIndex, endIndex, widget.id)}
                          />
                        </SWidgetsList>
                      </SVerticalDrag>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default EditWidgets;
