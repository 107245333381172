import React from 'react';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { numberWithCommas, palette } from 'utils/constants';
import { SFlexWrapper, SImage, SImageContainer, SWrapper } from './HardwareLicenses.styles';

export const useHardwareLicensesColumns = (sort, sortingQuery) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'hardware_license',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          HARDWARE LICENSE
        </Typography>
      ),
      render: (rowData) => {
        return (
          <SWrapper>
            <SImageContainer>
              <SImage src={rowData?.hardware?.image} alt='' />
            </SImageContainer>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.hardware?.name}
            </Typography>
          </SWrapper>
        );
      },
    },
    {
      field: 'license_type',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          LICENSE TYPE
        </Typography>
      ),
      render: (rowData) => (
        <SFlexWrapper>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.hardware?.contract_for_mo} months
          </Typography>
        </SFlexWrapper>
      ),
    },
    {
      field: 'id',
      title: <ColumnHeader title='AGREEMENT NUMBER' field='id' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <SFlexWrapper>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.agreement_number}
          </Typography>
        </SFlexWrapper>
      ),
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const status = rowData?.status;
        const color = rowData?.status === 'Pending' ? '#4f5aed' : rowData?.status === 'Active' ? '#14804A' : '#de2352';
        const bgColor =
          rowData?.status === 'Pending' ? '#f0f1fa' : rowData?.status === 'Active' ? '#E1FCEF' : '#FAF0F3';

        return (
          <Chip label={status?.toUpperCase()} labelColor={color} bgColor={bgColor} fontSize='11px' fontWeight={500} />
        );
      },
    },
    {
      field: 'license_count',
      title: <ColumnHeader title='QTY' field='license_count' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <SFlexWrapper>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.license_count}
          </Typography>
        </SFlexWrapper>
      ),
    },
    {
      field: 'amount_per_quantity',
      title: <ColumnHeader title='PER QTY AMOUNT' field='amount_per_quantity' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <SFlexWrapper>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            ${numberWithCommas(Number(rowData?.amount_per_quantity).toFixed(2))}
          </Typography>
        </SFlexWrapper>
      ),
    },
    {
      field: 'grand_total',
      title: <ColumnHeader title='AMOUNT' field='grand_total' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        return (
          <SFlexWrapper>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              ${numberWithCommas(Number(rowData?.grand_total).toFixed(2))}
            </Typography>
            <Chip
              label={rowData?.hardware?.price_for_mo ? `${rowData?.hardware?.price_for_mo} MONTHS` : 'ONE TIME'}
              fontSize='11px'
              fontWeight={500}
            />
          </SFlexWrapper>
        );
      },
    },
    {
      field: 'next_renewal_date',
      title: <ColumnHeader title='RENEWAL DATE' field='next_renewal_date' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const date = rowData?.next_renewal_date;

        return (
          <SFlexWrapper>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {date ? formatDate(date) : '-'}
            </Typography>
          </SFlexWrapper>
        );
      },
    },
  ];
};
