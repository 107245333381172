import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import profile from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as TrophyIcon } from 'assets/icons/trophy.svg';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getCompanyName } from './helpers';
import { SDriverInfoWrapper, SInfo } from './TopSection.styles';

const DriverInfo = ({ data, companyName, isCurrent, top = 1 }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const { currency } = useSelector((state) => state.root);
  const monthName = isCurrent ? moment().format('MMMM') : moment().subtract(1, 'months').format('MMMM');

  const { total_revenue, total_drive_pay, shipment_count, user } = data || {};

  const onUserClick = () => {
    if (!user?.id) {
      return null;
    }

    navigate(`/driver-profile/${user.id}/general`);
  };

  return (
    <SDriverInfoWrapper>
      <Typography
        variant='overLine'
        style={{
          display: 'inline-block',
          color: use(palette.indigo500, palette.gray200),
          marginBottom: 12,
        }}
      >
        <TrophyIcon className='trophy-icon' fill={palette.indigo400} />#{top} TOP EARNING DRIVER –{' '}
        {monthName.toUpperCase()}{' '}
        {!isCurrent && monthName === 'December' ? moment().subtract(1, 'year').year() : moment().year()}
      </Typography>
      <div className='driver-image'>
        <div onClick={onUserClick}>
          <img src={user?.image || profile} width={48} height={48} alt='Driver' />
        </div>
        <div className='driver-data'>
          <SInfo>
            <Typography
              variant='h5'
              style={{ color: use(palette.gray900, palette.gray200), cursor: 'pointer' }}
              onClick={onUserClick}
            >
              {user?.fname} {user?.lname}
            </Typography>
            <Typography variant='c1' style={{ color: use(palette.gray700, palette.gray200) }}>
              {getCompanyName(data, companyName)}
            </Typography>
          </SInfo>

          <div className='payroll-revenue'>
            <SInfo>
              <Typography variant='c2' style={{ color: use(palette.gray700, palette.gray200) }}>
                Shipment Revenue
              </Typography>
              <Typography variant='button2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {currency}
                {formatNumber(total_revenue)}
              </Typography>
            </SInfo>
            <SInfo>
              <Typography variant='c2' style={{ color: use(palette.gray700, palette.gray200) }}>
                Driver Pay
              </Typography>
              <Typography variant='button2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {currency}
                {formatNumber(total_drive_pay || 0)}
              </Typography>
            </SInfo>
            <SInfo>
              <Typography variant='c2' style={{ color: use(palette.gray700, palette.gray200) }}>
                Total Shipments
              </Typography>
              <Typography variant='button2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {shipment_count}
              </Typography>
            </SInfo>
          </div>
        </div>
      </div>
    </SDriverInfoWrapper>
  );
};

export default DriverInfo;
