import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import TakeActions from 'componentsV2/AlertCenter/TakeActions';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette, PERMISSIONS } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { GetSettingsAlerts, GetSettingsDepartments } from 'Api/CompanySettings';
import { getAlertDetails, getAlertsList, markAlertsResolved } from 'Api/AlertCenter';
import classNames from 'classnames';
import { getTabs } from 'Api/Driver';
import Tabs from './components/Tabs';
import AddTab from './components/AddTab';
import ProgressBar from './components/ProgressBar';
import TableFilters from './components/TableFilters';
import FilterOptions from './components/FilterOptions';
import { initialFilters, initialTabs, useColumns } from './AlertsTable.data';
import { SWrapper } from './AlertsTable.styles';

const AlertsTable = () => {
  const { value } = useAuth();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(initialTabs);
  const [alertsData, setAlertsData] = useState({ data: [] });
  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [alertTypes, setAlertTypes] = useState([]);
  const [alertToUpdate, setAlertToUpdate] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [loadingResolve, setLoadingResolve] = useState(false);
  const [tabToEdit, setTabToEdit] = useState(null);
  const [openAddTab, setOpenAddTab] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const debouncedSearch = useDebounce(search, 500);
  const showCheckboxes = permissions.includes(PERMISSIONS.RESOLVE_ALL_ALERTS.name) && selectedFilters.tab !== 4;

  const getAlerts = async () => {
    setLoading(true);
    try {
      const sortField = selectedFilters.sort?.field ? `sort[][${selectedFilters.sort.field}]` : null;
      const tab = tabs.find((i) => i.value === selectedFilters.tab) || initialTabs[0];

      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        query: debouncedSearch || undefined,
        start_date: selectedFilters.dateRange[0]
          ? moment(selectedFilters.dateRange[0]).format('YYYY-MM-DD')
          : undefined,
        end_date: selectedFilters.dateRange[1] ? moment(selectedFilters.dateRange[1]).format('YYYY-MM-DD') : undefined,
        alert_type: selectedFilters.alertTypes?.length ? selectedFilters.alertTypes : undefined,
        ...(tab.filters || {}),
      };

      if (sortField) {
        params[sortField] = selectedFilters.sort.sortBy;
      }

      const response = await getAlertsList(params);
      setAlertsData(response);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const getTableTabs = async () => {
    try {
      const { data } = await getTabs('alert_center');
      setTabs(
        data.map((tab, index) => ({
          ...tab,
          value: index,
          label: tab.data.name,
          color: tab.data.color,
          count: tab.data.name === 'Resolved' ? null : tab.count,
          filters: tab.data.filters,
        }))
      );
    } catch (e) {
      // Do nothing
    }
  };

  const getAlertTypeOptions = async () => {
    try {
      const { data } = await GetSettingsAlerts();
      setAlertTypes(data.map((item) => ({ title: item.name, id: item.id, alerts: item.alerts })));
    } catch (e) {
      // Do nothing
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await GetSettingsDepartments();
      const userPermissions =
        data.find((department) => department.id === value?.user?.department?.id)?.permissions || [];
      setPermissions(userPermissions.map((i) => i.permission_name));
    } catch (e) {
      // Do nothing
    }
  };

  const resolveSelectedAlerts = async () => {
    if (!selectedRowIds.length) {
      showToaster({ type: 'error', message: 'Please select alerts to resolve!' });
      return;
    }
    try {
      setLoadingResolve(true);
      await markAlertsResolved({ id: selectedRowIds });
      setSelectedRowIds([]);
      showToaster({ type: 'success', message: 'Alerts have been successfully resolved!' });
      getTableTabs();
      if (selectedFilters.page === 1) {
        getAlerts();
      } else {
        onPageChange(1);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingResolve(false);
    }
  };

  const onDateRangeChange = (value) => {
    setSelectedFilters((prevState) => ({ ...prevState, dateRange: value }));
  };

  const sortingQuery = (field) => {
    const direction = selectedFilters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSelectedFilters((prevState) => ({ ...prevState, sort: { field, sortBy: direction } }));
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const handleTabChange = (tab) => {
    setSelectedFilters((prevState) => ({ ...prevState, tab }));
  };

  const handleTabEdit = (tab) => {
    setTabToEdit(tab);
    setOpenAddTab(true);
  };

  const handleSelectAll = () => {
    setSelectedRowIds(selectedRowIds.length !== alertsData.data.length ? alertsData.data.map((i) => i.id) : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRowIds((prevState) => [...prevState, row.id]);
    } else {
      setSelectedRowIds((prevState) => prevState.filter((id) => id !== row.id));
    }
  };

  const onRowClick = async (alert) => {
    const { status } = alert || {};

    if (status === 'Resolved') {
      return;
    }

    try {
      const { data } = await getAlertDetails(alert?.id);
      if (data.resolved) {
        showToaster({
          type: 'success',
          message: `Unable to display this alert, because the entry has been removed! Alert will be resolved automatically!`,
        });
        getAlerts();
        getTableTabs();
        return;
      }

      setAlertToUpdate({ ...alert, ...data });
    } catch (e) {
      if (e.response?.data?.message?.includes('No query results')) {
        showToaster({
          type: 'error',
          message: `Unable to display this alert, because the entry has been removed!`,
        });
        return;
      }
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  useEffect(() => {
    getDepartments();
  }, [value?.user?.department?.id]);

  useEffect(() => {
    getAlerts();
  }, [selectedFilters, debouncedSearch]);

  useEffect(() => {
    getTableTabs();
    getAlertTypeOptions();
  }, []);

  const columns = useColumns({
    sort: selectedFilters.sort,
    alerts: alertsData.data,
    selectedRowIds,
    sortingQuery,
    handleSelectAll,
    handleSelectRow,
    showCheckboxes,
  });

  return (
    <SWrapper $showCheckboxes={showCheckboxes}>
      <TableFilters
        search={search}
        setSearch={setSearch}
        dateRange={selectedFilters.dateRange}
        onDateRangeChange={onDateRangeChange}
      />
      <ProgressBar tabs={tabs} />
      <FilterOptions alertTypes={alertTypes} filter={selectedFilters} updateFilter={setSelectedFilters} />
      <div
        className={classNames('bulk-resolve-wrapper', {
          'button-visible': !!selectedRowIds.length && !!showCheckboxes,
        })}
      >
        {!!selectedRowIds.length && showCheckboxes && (
          <CustomButton
            title={`Resolve (${selectedRowIds.length})`}
            type='primary'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ padding: '4px 12px', margin: 0 }}
            onClick={resolveSelectedAlerts}
            disabled={loadingResolve}
          />
        )}
      </div>
      <div className='tabs-wrapper'>
        <Tabs tabs={tabs} activeTab={selectedFilters.tab} handleTabChange={handleTabChange} onEdit={handleTabEdit} />
        <PlusIcon className='add-tab-btn' onClick={() => setOpenAddTab(true)} />
      </div>
      {loading ? (
        <TableSkeleton rowCount={20} />
      ) : (
        <MaterialTableWrapper
          data={alertsData?.data}
          rowPerPage={1000}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: alertsData,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          onRowClick={(e, rowData) => onRowClick(rowData)}
          columns={columns}
        />
      )}
      {!!alertToUpdate && (
        <TakeActions
          open={!!alertToUpdate}
          onClose={() => setAlertToUpdate(null)}
          alert={alertToUpdate}
          onSuccess={() => {
            getAlerts();
            getTableTabs();
          }}
        />
      )}
      {!!openAddTab && (
        <AddTab
          open={!!openAddTab}
          onClose={() => {
            setTabToEdit(null);
            setOpenAddTab(false);
          }}
          tab={tabToEdit}
          onSuccess={() => {
            getAlerts();
            getTableTabs();
          }}
          onDeleteSuccess={() => {
            setSelectedFilters((prevState) => ({ ...prevState, tab: 0 }));
            getTableTabs();
          }}
          alertCategories={alertTypes}
        />
      )}
    </SWrapper>
  );
};

export default AlertsTable;
