import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import LogbookList from 'pages/Hos-Logbook/LogbookList';

const HosRecordModal = ({ open, onClose, driverId, startTime, endTime }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Hours of Service'
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='80vw'
      $minHeight='80vh'
      $maxHeight='80vh'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <LogbookList isModalView driverId={driverId} startTime={startTime} endTime={endTime} />
    </Modal>
  );
};

export default HosRecordModal;
