import React from 'react';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { validationSchema } from './validationSchema';
import { SFormWrapper, SActions } from '../ActionTaken.styles';

const ItemForm = ({ onSubmit, onCancel, item, defaultItem }) => {
  const { handleChange, handleSubmit, values, touchedErrors } = useForm({
    initialValues: {
      action: item?.action || '',
      color_code: item?.color_code || palette.indigo500,
    },
    validationSchema,
    onSubmit,
  });

  return (
    <SFormWrapper>
      <div className='flex-item'>
        {!defaultItem ? (
          <Input
            name='action'
            onChange={handleChange}
            value={values.action}
            placeholder='Action'
            error={touchedErrors.action}
            style={{ maxWidth: '250px' }}
          />
        ) : (
          <Typography variant='s2'>{item.action}</Typography>
        )}
      </div>
      <div className='flex-item'>
        <Input
          className='color-input'
          name='color_code'
          width='100px'
          type='color'
          value={values.color_code}
          onChange={handleChange}
          error={touchedErrors.color_code}
        />
      </div>
      <div className='flex-item' />
      <SActions className='flex-item'>
        <CustomButton
          width={12}
          height={12}
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '1px 6px', margin: 0 }}
          onClick={onCancel}
        />
        <CustomButton
          width={12}
          height={12}
          type='primary'
          title=''
          leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={handleSubmit}
        />
      </SActions>
    </SFormWrapper>
  );
};

export default ItemForm;
