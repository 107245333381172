import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { getPayableStats } from 'Api/Payables';
import StatBox from './components/StatBox';
import Revenue from './components/Revenue';
import Accounts from './components/Accounts';
import UpcomingBills from './components/UpcomingBills';
import PastDueBills from './components/PastDueBills';
import { statsDataConverter } from './converters';
import { initialStats, initialYearFilters, statTypeYearMapper } from './StatsSection.data';
import { tabs } from '../TableSection/TableSection.data';
import { SWrapper } from './StatsSection.styles';

const StatsSection = ({ changeTab, statsRefreshIndex }) => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState(initialStats);
  const [yearFilters, setYearFilters] = useState(initialYearFilters);
  const didMountRef = useRef(false);

  const getStats = async () => {
    try {
      const params = {
        variable_cost_year: moment(yearFilters.variable_cost_year).format('YYYY-MM-DD'),
        fixed_cost_year: moment(yearFilters.fixed_cost_year).format('YYYY-MM-DD'),
        admin_cost_year: moment(yearFilters.admin_cost_year).format('YYYY-MM-DD'),
        maintenance_cost_year: moment(yearFilters.maintenance_cost_year).format('YYYY-MM-DD'),
      };
      const { data } = await getPayableStats(params);
      const convertedData = statsDataConverter(data);
      setStats(convertedData);
    } finally {
      setLoading(false);
    }
  };

  const onYearChange = (key, date) => {
    setYearFilters((prevState) => ({ ...prevState, [key]: date }));
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
      didMountRef.current = true;
    }
    getStats();
  }, [yearFilters, statsRefreshIndex]);

  return (
    <SWrapper>
      <div className='scrollable-container'>
        <div className='chart-boxes-wrapper' key={statsRefreshIndex}>
          <Revenue />
          <UpcomingBills onTitleClick={() => changeTab(0)} />
          <PastDueBills onTitleClick={() => changeTab(0)} />
          <Accounts />
        </div>
      </div>
      <div className='scrollable-container'>
        <div className='stat-boxes-wrapper'>
          {Object.keys(stats || {})?.map((key) => (
            <StatBox
              key={key}
              title={key}
              data={stats[key]}
              loading={loading}
              year={yearFilters[statTypeYearMapper[key]]}
              onYearChange={(date) => onYearChange(statTypeYearMapper[key], date)}
              onTitleClick={() => changeTab(tabs.findIndex((tab) => tab.key === key) || -1)}
            />
          ))}
        </div>
      </div>
    </SWrapper>
  );
};

export default StatsSection;
