import React, { useEffect, useState } from 'react';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import Input, { InputWithText, Textarea } from 'common/Input';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import { getAccountsList } from 'Api/Accounts';
import { CreateChargeType, UpdateChargeType } from 'Api/CompanySettings';
import { getInitialValues } from './ItemModal.data';
import { validationSchema } from './validationSchema';
import { SFlexWrapper, SOption } from '../ShipmentTypes.styles';

const ItemModal = ({ open, onClose, onSuccess, itemData }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [currentLiabilityAccounts, setCurrentLiabilityAccounts] = useState([]);

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccountOptions(data);
      setCurrentLiabilityAccounts(data.filter((item) => item.account_type?.id === ACCOUNT_TYPE.CURRENT_LIABILITY));

      if (itemData) {
        const selectedAccount = data.find((item) => item.id === itemData.account.id);
        const taxAccount = data.find((item) => item.id === itemData?.tax_account);
        handleChange('account', selectedAccount || null);
        handleChange('tax_account', taxAccount || null);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        title: values.title,
        code: values.code,
        account_id: values.account.id,
        type_id: values.type_id || values.account?.account_type?.id,
        desc: values.desc,
        is_taxable: values.is_taxable ? 1 : 0,
      };

      if (values.type_id === ACCOUNT_TYPE.INCOME) {
        body.tax_percentage = values.is_taxable ? values.tax_percentage : null;
        body.tax_account = values.is_taxable ? values.tax_account.id : null;
      }

      if (itemData) {
        await UpdateChargeType(itemData.id, body);
      } else {
        await CreateChargeType(body);
      }
      showToaster({ type: 'success', message: `Item has been successfully ${itemData ? 'updated' : 'created'}!` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(itemData),
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={itemData ? 'Edit Item' : 'Add Item'}
      $bgColor={palette.gray0}
      $maxWidth='580px'
      $minWidth='580px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: itemData ? 'Save' : 'Add Item',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex align-items-center gap-3'>
          <Input
            required
            label='Item Code'
            name='code'
            value={values.code}
            onChange={handleChange}
            error={touchedErrors.code}
          />
          <Input
            required
            label='Item Name'
            name='title'
            value={values.title}
            onChange={handleChange}
            className='flex-grow-1'
            error={touchedErrors.title}
            disabled={!!itemData?.default}
          />
        </div>
        <SFlexWrapper>
          <CustomRadioButton
            disabled={!!itemData?.default}
            field={{
              name: 'type_id',
              value: 1,
              onChange: () => {
                handleChange('type_id', 1);
                handleChange('account', null);
              },
              checked: values.type_id === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
              Income
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            disabled={!!itemData?.default}
            field={{
              name: 'type_id',
              value: 2,
              onChange: () => {
                handleChange('type_id', 2);
                handleChange('account', null);
              },
              checked: values.type_id === 2,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
              Expense
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            disabled={!!itemData?.default}
            field={{
              name: 'type_id',
              value: 0,
              onChange: () => {
                handleChange('type_id', 0);
                handleChange('account', null);
              },
              checked: values.type_id === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
              Other
            </Typography>
          </CustomRadioButton>
        </SFlexWrapper>
        <div className='flex-grow-1'>
          <Autocomplete
            required
            label={values.type_id === 0 ? 'Account' : values.type_id === 1 ? 'Income Account' : 'Expense Account'}
            name='account'
            labelKey='account_name'
            options={accountOptions.filter((i) => values.type_id === 0 || i.account_type.id === values.type_id)}
            value={values.account}
            onChange={(e, val) => handleChange('account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.account}
            disabled={!!itemData?.default}
            getOptionLabel={(option) =>
              `${option.account_name}${option.account_code ? ` (${option.account_code})` : ''}`
            }
            renderOption={(props, option) => (
              <SOption {...props}>
                <Typography>{option.account_name}</Typography>
                {!!option.account_code && <Chip label={option.account_code} />}
              </SOption>
            )}
          />
        </div>
        {values.type_id === 1 && (
          <div className='d-flex align-items-start gap-4'>
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                name='is_taxable'
                checked={!!values.is_taxable}
                onChange={(checked) => handleChange('is_taxable', checked)}
                smail={false}
              />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                Taxable
              </Typography>
            </div>
            <div>
              <InputWithText
                type='number'
                width='80px'
                text='%'
                name='tax_percentage'
                onKeyDown={(e) => blockNonPositiveNumbers(e)}
                onChange={(e) => {
                  if (Number(e.target.value) <= 100) {
                    handleChange('tax_percentage', e.target.value);
                  }
                }}
                value={values.tax_percentage}
                error={touchedErrors.tax_percentage}
                disabled={!values.is_taxable}
              />
            </div>
            <div className='w-100'>
              <Autocomplete
                name='tax_account'
                labelKey='account_name'
                placeholder='Account Type'
                options={currentLiabilityAccounts}
                value={values.tax_account}
                onChange={(e, val) => handleChange('tax_account', val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors.tax_account}
                disabled={!values.is_taxable}
                getOptionLabel={(option) => `${option.account_name} (${option.account_code})`}
                renderOption={(props, option) => (
                  <SOption {...props}>
                    <Typography>{option.account_name}</Typography>
                    <Chip label={option.account_code} />
                  </SOption>
                )}
              />
            </div>
          </div>
        )}
        <Textarea
          required
          rows={3}
          label={
            values.type_id === 0 ? 'Description' : values.type_id === 1 ? 'Income Description' : 'Expense Description'
          }
          name='desc'
          value={values.desc}
          onChange={handleChange}
          error={touchedErrors.desc}
        />
      </div>
    </Modal>
  );
};

export default ItemModal;
