import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { getAllTrailers } from 'Api/Map';
import { getEquipmentTypes } from 'Api/Equipment';
import { GetEquipmentTrailer } from 'Api/StopPoint';
// import { createQuickEquipment } from 'Api/Shipment';
import { getEquipmentDimensions } from 'Api/EquipmentImport';
import { customerStopPoint, getCommodity } from 'Api/Planner';

import ShipmentLeg from './ShipmentLeg';
import { initialLeg } from '../../CreateQuote.data';
import { SAddMore, SSchedule } from '../../CreateQuote.styles';

const ShipmentStops = ({ form, quote, shouldSave }) => {
  // const showToaster = useShowToaster();
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [, setLocalEquipmentIdData] = useState([]);
  const [, setDroppedTrailerIds] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [commodity, setCommodity] = useState([]);

  const { value: userData } = useAuth();
  const { values, handleChange } = form;

  const getEquipmentType = async () => {
    try {
      const { data } = await getEquipmentTypes({}, userData.user?.customer?.dot);
      const { data: dimensions } = await getEquipmentDimensions();

      const convertedTypes = data.map((item) => ({
        ...item,
        title: item.id === 1 ? 'Truck (Power Only)' : item.title,
        dimensions: dimensions.filter((i) => i.equipment_type_id === item.id),
      }));

      setEquipmentTypes(convertedTypes);
    } catch (e) {
      // Do nothing
    }
  };

  const getStopPoints = async (setNewAdded) => {
    try {
      const { data } = await customerStopPoint(undefined, { only_active: 1 });
      setStopPoints(data);
      if (setNewAdded) {
        handleChange('stop_point', data[data.length - 1]);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getEquipmentIdInType = async () => {
    try {
      const { data } = await getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8' } });
      setLocalEquipmentIdData(data);
      // setEquipmentId([...equipmentId]);
    } catch (e) {
      // Do nothing
    }
  };

  const getTrailers = async () => {
    try {
      const { data } = await GetEquipmentTrailer({
        'sort[][equipment_id]': 'desc',
        stop_point_id: Number(values.stop_point?.id),
      });
      setDroppedTrailerIds(data.map((i) => i.equipment_id));
    } catch (e) {
      // Do nothing
    }
  };

  const getCommodityList = async () => {
    try {
      const { data } = await getCommodity();
      setCommodity(data);
    } catch (e) {
      // Do nothing
    }
  };

  // const onAddCustomTrailer = async (stop, index) => {
  //   const { custom_trailer_type, custom_trailer_id, custom_trailer_length } = stop || {};
  //
  //   if (!custom_trailer_type || !custom_trailer_id || !custom_trailer_length) {
  //     return;
  //   }
  //
  //   try {
  //     const body = {
  //       equipment_type_id: custom_trailer_type.key,
  //       equipment_id: custom_trailer_id,
  //       length_id: custom_trailer_length.key,
  //     };
  //     const { data } = await createQuickEquipment(body);
  //     const label = `${data?.equipment_id || ''}  (${data?.make || ''}  ${data?.model_id || ''})`;
  //     const newValue = {
  //       ...data,
  //       key: data.id,
  //       label,
  //       labelSelected: null,
  //     };
  //     setLocalEquipmentIdData([...localEquipmentIdData, newValue]);
  //     showToaster({ type: 'success', message: 'Success' });
  //     handleChange(`stops[${index}].custom_trailer_id`, '');
  //     handleChange(`stops[${index}].custom_trailer_type`, null);
  //     handleChange(`stops[${index}].custom_trailer_length`, null);
  //     handleChange(`stops[${index}].equipment_id`, data);
  //   } catch (e) {
  //     showToaster({ type: 'error', message: getErrorMessage(e) });
  //   }
  // };

  const onAddLeg = () => {
    handleChange('legs', [...values.legs, { ...initialLeg, id: uuid() }]);
  };

  useEffect(() => {
    getEquipmentType();
    getEquipmentIdInType();
    getStopPoints();
    getCommodityList();
  }, []);

  useEffect(() => {
    if (!values.stop_point?.id) {
      return;
    }

    getTrailers();
  }, [values.stop_point]);

  return (
    <SSchedule>
      <div className='mb-3'>
        <Typography variant='s1'>Schedule</Typography>
      </div>
      {values.legs.map((leg, legIndex) => (
        <ShipmentLeg
          key={leg.id}
          form={form}
          leg={leg}
          legIndex={legIndex}
          quote={quote}
          shouldSave={shouldSave}
          stopPoints={stopPoints}
          getStopPoints={getStopPoints}
          commodity={commodity}
          equipmentTypes={equipmentTypes}
        />
      ))}
      <SAddMore onClick={onAddLeg}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500 }}>
          Add Another...
        </Typography>
      </SAddMore>
    </SSchedule>
  );
};

export default ShipmentStops;
