import React from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import styles from './General.module.scss';

const GeneralPreview = ({ setEdit, stopPointsItem, showVehicleAlert, showTrailerAlert }) => {
  const { use } = useTheme();
  return (
    <div className={styles.general_wrapper}>
      <div className={styles.general_information}>
        <div className={styles.header}>
          <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
            General Information
          </Typography>
          <div
            onClick={() => {
              setEdit(true);
            }}
            className={styles.icon_wrapper}
          >
            <EditIcon className={styles.icon} />
          </div>
        </div>
        <div className={styles.info_section}>
          <div className={styles.left_side}>
            <div className={styles.row}>
              <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                Location Name
              </Typography>
              <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {stopPointsItem?.stop_point?.location_name || ''}
              </Typography>
            </div>
            <div className={styles.row}>
              <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                Require Scale/Tickets
              </Typography>
              {stopPointsItem?.stop_point?.require_scale_tickets === 1 ? (
                <div className={styles.infoYes}>
                  <Typography variant='overLine' style={{ color: use(palette.green500) }}>
                    YES
                  </Typography>
                </div>
              ) : (
                <div className={styles.infoNo}>
                  <Typography variant='overLine' style={{ color: use(palette.red500) }}>
                    NO
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className={styles.center}>
            <div className={styles.row}>
              <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                Address
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {stopPointsItem?.stop_point?.address1 || ''}
                </Typography>
                <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {stopPointsItem?.stop_point?.address2 || ''}
                </Typography>
                <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {stopPointsItem?.city?.name || ''}
                  {stopPointsItem?.city?.name && <span>, </span>}
                  {stopPointsItem?.state?.name || ''} <span> </span>
                  {stopPointsItem?.stop_point?.zipcode || ''}
                </Typography>
              </div>
            </div>
            <div className={styles.row}>
              <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                Require Lumper Receipt
              </Typography>
              {stopPointsItem?.stop_point?.require_lumper_receipt === 1 ? (
                <div className={styles.infoYes}>
                  <Typography variant='overLine' style={{ color: use(palette.green500) }}>
                    YES
                  </Typography>
                </div>
              ) : (
                <div className={styles.infoNo}>
                  <Typography variant='overLine' style={{ color: use(palette.red500) }}>
                    NO
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className={styles.right_side}>
            {!!showVehicleAlert && (
              <div style={{ display: 'flex', gap: 10 }}>
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  Alert me when equipment at stop point reach
                </Typography>
                {stopPointsItem?.stop_point_alert_setting?.vehicle_setting ? (
                  <span className={styles.number}>{stopPointsItem?.stop_point_alert_setting?.number_of_vehicle}</span>
                ) : (
                  <span className={styles.number}>-</span>
                )}
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  vehicles
                </Typography>
              </div>
            )}
            {!!showTrailerAlert && (
              <div style={{ display: 'flex', gap: 10 }}>
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  Alert me when equipment at stop point reach
                </Typography>
                {stopPointsItem?.stop_point_alert_setting?.trailer_setting ? (
                  <span className={styles.number}>{stopPointsItem?.stop_point_alert_setting?.number_of_trailer}</span>
                ) : (
                  <span className={styles.number}>-</span>
                )}
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  trailers
                </Typography>
              </div>
            )}
            <div style={{ display: 'flex', gap: 10 }}>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                This stop point is considered a yard - {stopPointsItem?.stop_point?.considered_yard ? 'Yes' : 'No'}
              </Typography>
            </div>
            <div style={{ display: 'flex', gap: 10 }}>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                Do not track average wait time -{' '}
                {stopPointsItem?.stop_point?.not_track_average_waiting_time ? 'Yes' : 'No'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GeneralPreview;
