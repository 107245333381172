import { number, object } from 'yup';

export const absencePolicyValidationSchema = object().shape({
  driver_reset_based_on: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  driver_excused_absences: number().required('Required').nullable(),
  driver_unexcused_absences: number().required('Required').nullable(),
  driver_vacations_allowed: number().required('Required').nullable(),
  driver_excused_reset_after_month: number().required('Required').nullable(),
  driver_unexcused_reset_after_month: number().required('Required').nullable(),
  driver_vacations_reset_after_month: number().required('Required').nullable(),
  staff_reset_based_on: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  staff_excused_absences: number().required('Required').nullable(),
  staff_unexcused_absences: number().required('Required').nullable(),
  staff_vacations_allowed: number().required('Required').nullable(),
  staff_excused_reset_after_month: number().required('Required').nullable(),
  staff_unexcused_reset_after_month: number().required('Required').nullable(),
  staff_vacations_reset_after_month: number().required('Required').nullable(),
});
