import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const SModal = styled(Modal)`
  &.without-map {
    .modal-dialog {
      width: 800px;
    }

    .Tracking-details {
      width: 70%;
    }
  }
`;
