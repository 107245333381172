import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import moment from 'moment';
import classNames from 'classnames';
import { ReactComponent as CircularArrows } from 'assets/icons/circle-arrows.svg';
import Loader from 'common/Loader';
import Search from 'common/Search';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import {
  deleteBankFeedTransactions,
  getAccountsList,
  getBankFeed,
  getPayeeList,
  getPlaidItemToken,
  reconcileBankFeed,
  syncBankFeed,
  updatePlaidItem,
} from 'Api/Accounts';
import Rules from 'pages/Accounting/Accounts/components/Rules';
import RefreshFeed from 'pages/Accounting/Accounts/BankFeed/components/RefreshFeed';
import Header from './components/Header';
import Transactions from './components/Transactions';
import { SWrapper } from './BankFeed.styles';

const BankFeed = () => {
  const { id } = useParams();
  const showToaster = useShowToaster();
  const [transactions, setTransactions] = useState([]);
  const [transactionsData, setTransactionsData] = useState({ data: [] });
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [payees, setPayees] = useState([]);
  const [search, setSearch] = useState('');
  const [rulesOpen, setRulesOpen] = useState(false);
  const [sort, setSort] = useState({ field: 'date', sortBy: 'asc' });
  const [openRefreshFeed, setOpenRefreshFeed] = useState(false);
  const [plaidToken, setPlaidToken] = useState(null);
  const [refreshFeedValues, setRefreshFeedValues] = useState(null);
  const [filters, setFilters] = useState({ page: 0, itemsPerPage: 25 });
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const searchRef = useRef(null);
  const didMountRef = useRef(false);
  const debouncedSearch = useDebounce(search, 500);

  const getTransactions = async () => {
    try {
      let accountsList = accounts;
      let payeeList = payees;
      if (!accounts.length || !payees.length || !account) {
        const [{ data: accountsResponse }, { data: payeeResponse }] = await Promise.all([
          getAccountsList(),
          getPayeeList(),
        ]);
        accountsList = accountsResponse.filter((i) => i.id !== Number(id));
        payeeList = payeeResponse || [];
        setAccounts(accountsList);
        setPayees(payeeResponse || []);
        setAccount((accountsResponse || []).find((i) => Number(i.id) === Number(id)) || null);
      }
      const sortField = `sort[][${sort.field}]`;

      const params = {
        account_id: id,
        page: filters.page,
        itemsPerPage: filters.itemsPerPage,
        start_date: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        [sortField]: sort.sortBy,
        query: debouncedSearch || undefined,
      };
      const response = await getBankFeed(params);
      setTransactionsData(response);
      const convertedData = response.data.map((item) => ({
        ...item,
        payment: Math.abs(item.payment),
        payee:
          (payeeList || []).find((i) => Number(i.id) === Number(item.payee_id) && i.type === item.payee_type) || null,
        account: (accountsList || []).find((acc) => acc.id === Number(item.account)) || null,
      }));
      setTransactions(convertedData);

      const matchedTransactions = convertedData.reduce((acc, cur) => {
        if (cur.status === 'Matched') {
          acc.push(cur.id);
        }
        return acc;
      }, []);
      setSelectedRows(matchedTransactions);
    } catch (e) {
      /* empty */
    } finally {
      setLoading(false);
      setLoadingSkeleton(false);
    }
  };

  const deleteTransactions = async () => {
    try {
      setLoadingDelete(true);
      const body = { id: selectedRows };
      await deleteBankFeedTransactions(body);
      await getTransactions();
      showToaster({ type: 'success', message: 'Transactions have been successfully deleted!' });
      setSelectedRows([]);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onRefreshFeed = async (values) => {
    try {
      setLoadingRefresh(true);
      await syncBankFeed({
        id,
        start_date:
          values.type === 1
            ? moment().startOf('year').format('YYYY-MM-DD')
            : moment(values.start_date).format('YYYY-MM-DD'),
        end_date: values.type === 1 ? moment().format('YYYY-MM-DD') : moment(values.end_date).format('YYYY-MM-DD'),
      });
      showToaster({ type: 'success', message: 'Success!' });
      setOpenRefreshFeed(false);
      setLoading(true);
      getTransactions();
    } catch (e) {
      const message = getErrorMessage(e) || 'Something went wrong!';

      if (message.includes('login details of this item have changed')) {
        setRefreshFeedValues(values);
        showToaster({ type: 'error', message: 'Please, login to your bank account to proceed!' });
        const { data } = await getPlaidItemToken({ account_id: account.id });
        setPlaidToken(data.link_token);
      }

      showToaster({
        type: 'error',
        message: message.includes('There are no transactions to retrieve for this account')
          ? 'Please wait 15 minutes and try refreshing the feed again.'
          : message,
      });
    } finally {
      setLoadingRefresh(false);
    }
  };

  const onUpdateSuccess = async (updatedRows, shouldGetPayees) => {
    let newPayees = payees;

    if (shouldGetPayees) {
      const { data } = await getPayeeList();
      newPayees = data;
      setPayees(data);
    }

    const convertedData = updatedRows.map((item) => ({
      ...item,
      payment: Math.abs(item.payment),
      payee:
        (newPayees || []).find((i) => Number(i.id) === Number(item.payee_id) && i.type === item.payee_type) || null,
      account: (accounts || []).find((acc) => acc.id === Number(item.account)) || null,
    }));

    const updatedTransactions = transactions.map((tr) => {
      const matchingTransaction = convertedData.find((i) => i.id === tr.id);

      if (matchingTransaction) {
        return matchingTransaction;
      }
      return tr;
    });

    setTransactions(updatedTransactions.filter((i) => !i.reconciled));
  };

  const onMatchPaymentSuccess = (updatedTransactions) => {
    onUpdateSuccess(updatedTransactions);
    setAccount((prevState) => ({
      ...prevState,
      reconcile_count: prevState.reconcile_count - 1,
    }));
  };

  const onReconcile = async () => {
    try {
      const selectedTransactions = selectedRows.map((id) => transactions.find((tr) => tr.id === id));
      if (selectedTransactions.some((row) => !row?.account || (!row?.payment && !row?.deposit))) {
        showToaster({
          type: 'error',
          message: 'Please fill account and payment/deposit fields on selected rows before reconciliation!',
        });
        return;
      }

      const body = selectedTransactions.reduce(
        (acc, cur) => {
          if (!cur) {
            return acc;
          }

          acc.account_id.push(cur.account_id);
          acc.date.push(cur.date);
          acc.reference_id.push(cur.reference_id);
          acc.payee_id.push(cur.payee?.id || 0);
          acc.payee_type.push(cur.payee?.type || null);
          acc.account.push(cur.account?.id || 0);
          acc.memo.push(cur.memo || null);
          acc.reconciled.push(1);
          acc.payment.push(cur.payment || 0);
          acc.deposit.push(cur.deposit || 0);

          return acc;
        },
        {
          account_id: [],
          date: [],
          reference_id: [],
          payee_id: [],
          payee_type: [],
          account: [],
          memo: [],
          reconciled: [],
          payment: [],
          deposit: [],
        }
      );

      const { data } = await reconcileBankFeed(body);
      onUpdateSuccess(data);
      setAccount((prevState) => ({
        ...prevState,
        reconcile_count: prevState.reconcile_count - (data?.length || selectedRows.length),
      }));
      showToaster({ type: 'success', message: 'Transactions have been successfully reconciled' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onAuthorizeSuccess = async () => {
    try {
      await updatePlaidItem({ account_id: account.id });
      await onRefreshFeed(refreshFeedValues);
    } catch (e) {
      // Do nothing
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeRowPerPage = (rowPage) => {
    setFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (page) => {
    setFilters((prevState) => ({ ...prevState, page }));
  };

  const { open: openPlaid, ready } = usePlaidLink({
    onSuccess: onAuthorizeSuccess,
    token: plaidToken,
    linkCustomizationName: 'connect_single',
  });

  useEffect(() => {
    setLoading(true);
    getTransactions();

    const intervalId = setInterval(() => {
      const search = searchRef?.current?.value;
      setSearch(search || '');
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    getTransactions();
  }, [dateRange, filters, sort, debouncedSearch]);

  useEffect(() => {
    if (ready) {
      openPlaid();
    }
  }, [ready]);

  if (loading) {
    return <Loader loading={loading} size={36} />;
  }

  return (
    <SWrapper>
      <Header
        account={account}
        onRefresh={() => setOpenRefreshFeed(true)}
        onRulesClick={() => setRulesOpen(true)}
        loadingRefresh={loadingRefresh}
      />
      <div className='d-flex align-items-center gap-3'>
        <DateRangePicker dateRange={dateRange} initialRangeName='All Time' setDateRange={setDateRange} type='allTime' />
        <Search ref={searchRef} />
      </div>
      <div className='d-flex align-items-center gap-3'>
        <div className={classNames('delete-wrapper', { 'button-visible': !!selectedRows.length })}>
          {!!selectedRows.length && (
            <CustomButton
              title={`Delete (${selectedRows.length})`}
              type='primary'
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={deleteTransactions}
              disabled={loadingDelete}
            />
          )}
        </div>
        <div className={classNames('delete-wrapper', { 'button-visible': !!selectedRows.length })}>
          {!!selectedRows.length && (
            <CustomButton
              title={`Reconcile ${selectedRows.length ? `(${selectedRows.length})` : ''}`}
              type='primary'
              leftIcon={<CircularArrows fill={palette.white} className='me-1' />}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={onReconcile}
            />
          )}
        </div>
      </div>
      {loadingSkeleton ? (
        <TableSkeleton />
      ) : (
        <Transactions
          transactions={transactions}
          updateTransactions={setTransactions}
          account={account}
          accounts={accounts}
          payees={payees}
          loading={loading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onUpdateSuccess={onUpdateSuccess}
          onMatchPaymentSuccess={onMatchPaymentSuccess}
          sort={sort}
          sortingQuery={sortingQuery}
        />
      )}
      <Pagination
        data={transactionsData}
        rowPerPage={filters.itemsPerPage}
        onPageChange={onPageChange}
        onChangeRowPerPage={onChangeRowPerPage}
        rowsPerPageOptions={[25, 50, 100, 150]}
      />
      {rulesOpen && (
        <Rules open={rulesOpen} onClose={() => setRulesOpen(false)} title={account.account_name} account={account} />
      )}
      {openRefreshFeed && (
        <RefreshFeed
          open={openRefreshFeed}
          onClose={() => setOpenRefreshFeed(false)}
          loading={loadingRefresh}
          onRefreshFeed={onRefreshFeed}
        />
      )}
    </SWrapper>
  );
};

export default BankFeed;
