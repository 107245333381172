import styled from 'styled-components';
import { palette } from '../../../../utils/constants';

export const SWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-radius: 8px;
  padding: 20px 38px 20px 20px;

  .arrow-right,
  .arrow-right-hover {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  .arrow-right {
    display: inline-block;
  }

  .arrow-right-hover {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background: #f7f9fc66;
    backdrop-filter: blur(4px);

    .arrow-right {
      display: none;
    }

    .arrow-right-hover {
      display: inline-block;
    }
  }
`;
