import React, { useEffect, useMemo, useState } from 'react';

import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { TableContext } from 'context/tableContext';
import {
  GetSupport,
  CreateSupport,
  GetSupportStatus,
  getSupportTicketTypes,
  getSupportTicketPriorities,
} from 'Api/Support';
import { ReactComponent as SupportIcon } from 'assets/icons/support2.svg';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import SupportHeader from 'components/SupportHeader/SupportHeader';
import SupportSearch from 'components/SupportSearch/SupportSearch';
import SupportFooter from 'components/SupportFooter/SupportFooter';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import SupportTicketModal from 'components/SupportTicketModal/SupportTicketModal';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import styles from './Support.module.css';
import SupportItemModal from './SupportItemModal/SupportItemModal';
import { sortByResolvedStatusToEnd, useColumns } from './Support.data';

const Support = () => {
  const userData = useMemo(() => {
    return JSON.parse(localStorage.getItem('user'));
  }, []);
  const [supportItemModal, setSupportItemModal] = useState({});
  const [filter, setFilter] = useState({
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    filterTableTop: [],
    selectedValues: [],
  });
  const [totalCount, setTotalCount] = useState();
  const [search, setSearch] = useState('');
  const [dragItem, setDragItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const [showModal, setShowModal] = useState(false);
  const [ticketTypes, setTikcetTypes] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState({
    tab: 0,
    page: 1,
    itemsPerPage: 50,
  });
  const [supportList, setSupportList] = useState({ data: [] });
  const [supportStatusesList, setSupportStatusesList] = useState([]);

  useEffect(() => {
    async function init() {
      try {
        const { types } = selectedFilters || {};

        const { data } = await GetSupportStatus({
          user_type: types,
        });

        const sortedStatuses = data.sort(sortByResolvedStatusToEnd);

        setSupportStatusesList(sortedStatuses);
        setTotalCount(
          (sortedStatuses?.[0]?.status_count || 0) +
            (sortedStatuses?.[1]?.status_count || 0) +
            (sortedStatuses?.[2]?.status_count || 0) +
            (sortedStatuses?.[3]?.status_count || 0)
        );
        setFilter((prevState) => {
          return {
            ...prevState,
            filterTableTop: [
              ...sortedStatuses.map((item) => {
                return {
                  key: item.id,
                  label: item.status,
                  value: item.id,
                  count: item.status_count,
                  id: item.id,
                  color: item.color,
                };
              }),
            ],
          };
        });
      } catch (e) {
        // Do nothing
      }
    }

    init();
  }, [selectedFilters.types, supportList]);

  const getSupportData = async () => {
    setLoading(true);
    const { page, itemsPerPage, types, tab, ...rest } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    try {
      const params = {
        page,
        itemsPerPage,
        query: debouncedSearch,
        status_id: tab === 0 ? [2, 3, 4, 5] : [tab],
        [sortField]: sort.sortBy,
        ...rest,
      };
      const supports = await GetSupport(params);
      setSupportList(supports);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSupportData().then();
  }, [debouncedSearch, selectedFilters, sort, updateIndex]);

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const PaginationComponent = (event) => {
    return (
      <SupportFooter
        setLoading={(v) => setLoading(v)}
        rowPerPage={selectedFilters.itemsPerPage}
        totalCount={supportList.total}
        totalLength={supportList.data.length}
        lastPage={supportList.last_page}
        currentPage={supportList.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setSupportList={(data) => setSupportList(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onChangeTab = (n) => {
    setSelectedFilters({ ...selectedFilters, tab: +n.value });
  };
  const onOpenModal = () => {
    setShowModal(true);
  };

  const onAddAttachmentSuccess = () => {
    setUpdateIndex(Date.now());
  };

  const getTicketTypesAndPriorities = async () => {
    try {
      const { data } = await getSupportTicketTypes();
      const { data: priorityData } = await getSupportTicketPriorities();

      setTikcetTypes(data);
      setPriorities(priorityData);
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    try {
      setLoadingCreate(true);
      const data = {
        title: values.title,
        user_type: 1,
        user_id: userData.id,
        company_dot: userData.customer.dot,
        description: values?.description,
        phone_no: values?.phone_no,
        image: values?.image,
        type_id: values?.type?.id,
        priority_id: values?.priority?.id,
      };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      await CreateSupport(formData);

      setShowModal(false);
      setSelectedFilters({
        tab: 0,
        page: 1,
        itemsPerPage: 50,
      });
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingCreate(false);
    }
  };

  useEffect(() => {
    getTicketTypesAndPriorities();
  }, []);

  const columns = useColumns({ sort, sortingQuery });

  return (
    <div>
      <TableContext.Provider
        value={{
          filter,
          dragItem,
          updateFilter,
          onChangeOrder: (items) => setDragItem(items),
          supportList,
          supportStatusesList,
        }}
      >
        <SupportHeader onOpenModal={onOpenModal} ticketTypes={ticketTypes} priorities={priorities} />
        <div className='d-flex justify-content-between'>
          <SupportSearch search={search} onChange={setSearch} />
          <div className={styles.view_text} onClick={() => window.open('https://www.truckindigital.com/support')}>
            <SupportIcon />
            <span>View support site for more information</span>
          </div>
        </div>

        <SwitchRadio
          color
          name='tableTopTabMenu'
          items={[
            {
              key: 'all',
              label: 'All',
              value: 0,
              id: 0,
              count: totalCount || 0,
            },
            ...filter.filterTableTop,
          ]}
          value={selectedFilters.tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
      </TableContext.Provider>

      <div style={{ display: loading ? 'block' : 'none' }}>
        <Preloader />
      </div>
      <div style={{ display: !loading ? 'block' : 'none' }}>
        <MaterialTableWrapper
          data={supportList?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          onRowClick={(e, rowData) => setSupportItemModal({ ...rowData, isOpen: true })}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      </div>

      {!!showModal && (
        <SupportTicketModal
          open={!!showModal}
          onClose={() => setShowModal(false)}
          userData={userData}
          onSubmit={onSubmit}
          ticketTypes={ticketTypes}
          priorities={priorities}
          loading={loadingCreate}
        />
      )}

      {supportItemModal?.isOpen && (
        <SupportItemModal
          supportItemModal={supportItemModal}
          setSupportItemModal={setSupportItemModal}
          isOpen={supportItemModal.isOpen}
          setIsOpen={setSupportItemModal}
          modalData={supportItemModal}
          onAddAttachmentSuccess={onAddAttachmentSuccess}
        />
      )}
    </div>
  );
};

export default Support;
