import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import styles from '../Transactions.module.css';

const TransactionsHeader = ({ title }) => {
  const { use } = useTheme();

  return (
    <div className={styles.transactions_header}>
      <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
        {title}
      </Typography>
    </div>
  );
};
export default TransactionsHeader;
