import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import CustomButton from 'components/CustomButton/CustomButton';
import CargoTable from 'componentsV2/Commodity/ShipmentCommodity/components/CargoTable';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import useShipmentLocked from 'hooks/useShipmentLocked';
import { getShipmentCargo, updateMultipleCargo } from 'Api/Shipment';
import {
  createCargoConverter,
  getInitialValues,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { validationSchema } from 'componentsV2/Commodity/ShipmentCommodity/validationSchema';
import { SCargo } from './Cargo.styles';

const Cargo = ({ shipment }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { isShipmentLocked } = useShipmentLocked();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [cargo, setCargo] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const { shipment_id, shipment_billing } = shipment || {};

  const onSubmit = async (values) => {
    try {
      setLoadingSave(true);
      const body = values.map((row) => createCargoConverter(row));
      await updateMultipleCargo({ cargos: body });
      setEditMode(false);
      showToaster({ type: 'success', message: 'Cargo has been successfully updated!' });
      getCargo();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSave(false);
    }
  };

  const form = useForm({
    initialValues: cargo?.length ? getInitialValues(cargo) : [],
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const getCargo = async () => {
    try {
      setLoading(true);
      const { data } = await getShipmentCargo({ shipment_id });
      setCargo(data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!shipment_id) {
      return;
    }

    getCargo();
  }, [shipment_id]);

  return (
    <SCargo>
      <div className='cargo-content'>
        <div className='px-4 py-3'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-2'>
              <Typography variant='h2'>Cargo</Typography>
              <div>
                <InfoIcon width={14} height={14} fill={palette.indigo500} />
                {shipment_billing?.[0]?.billing_customer?.customer_payment_term?.require_cargo_scan_on_pickup ? (
                  <Typography variant='b2' className='ms-1'>
                    <Typography
                      variant='b2'
                      style={{ color: palette.indigo500 }}
                      className='navigate-button'
                      onClick={() =>
                        navigate(`/customer-profile/${shipment_billing?.[0]?.billing_customer?.id}?tab=terms&edit=true`)
                      }
                    >
                      Require Cargo Scan on Pickups
                    </Typography>{' '}
                    is{' '}
                    <Typography variant='b2' style={{ color: palette.green400 }}>
                      Enabled
                    </Typography>{' '}
                    for this customer.
                  </Typography>
                ) : (
                  <Typography variant='b2' className='ms-1'>
                    To allow driver's to update cargo, you must enable{' '}
                    <Typography
                      variant='b2'
                      style={{ color: palette.indigo500 }}
                      className='navigate-button'
                      onClick={() =>
                        navigate(`/customer-profile/${shipment_billing?.[0]?.billing_customer?.id}?tab=terms&edit=true`)
                      }
                    >
                      Require Cargo Scan on Pickups
                    </Typography>{' '}
                    in the customer profile.
                  </Typography>
                )}
              </div>
            </div>
            {!!cargo.length &&
              (isShipmentLocked(shipment) ? (
                <ActionLocked shipment={shipment} size={16} />
              ) : (
                <div>
                  {editMode ? (
                    <div className='d-flex align-items-center gap-3'>
                      <DeleteIcon className='edit-action' onClick={() => setEditMode(false)} />
                      <CustomButton
                        styleTitle={{ fontSize: 14 }}
                        styleButton={{ padding: '3px 12px', margin: 0 }}
                        type='primary'
                        title='Save'
                        disabled={loadingSave}
                        onClick={form.handleSubmit}
                      />
                    </div>
                  ) : (
                    <ActionDots className='edit-action' onClick={() => setEditMode(true)} />
                  )}
                </div>
              ))}
          </div>
        </div>
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className='cargo-table'>
            <CargoTable form={form} onUpdateSuccess={getCargo} readOnly={!editMode} createMode={false} hideAddNew />
          </div>
        )}
      </div>
    </SCargo>
  );
};

export default Cargo;
