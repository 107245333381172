import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from 'utils/constants';

export const SBackButton = styled.div`
  margin: 4px 0 12px;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const SLink = styled(Link)`
  color: ${palette.indigo500};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: ${palette.indigo500};
  }
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const SLoadingWrapper = styled.div`
  min-height: 300px;
  position: relative;

  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;
