import axios from 'services/axios';

export async function getPrevEmployment({ id, userType, params }) {
  const { data } = await axios.get(`/previous-employment/${userType}/${id}`, { params });
  return data;
}

export async function createPrevEmployment(body) {
  const { data } = await axios.post('/send-previous-employment-verification-request', body);
  return data;
}

export async function updatePrevEmployment(body) {
  const { data } = await axios.post('/update-previous-employment-verification-request', body);
  return data;
}

export async function deletePrevEmployment({ id, userType }) {
  const { data } = await axios.delete(`/previous-employment/${userType}/${id}`);
  return data;
}

export async function deletePrevEmploymentAttempt({ id, userType }) {
  const { data } = await axios.delete(`/previous-employment-attempt/${userType}/${id}`);
  return data;
}

// Previous Employment Notes
export async function getPrevEmploymentNotes({ id, userType }) {
  const { data } = await axios.get(`${userType}/previous-employment/${id}/notes`);
  return data;
}

export async function addPrevEmploymentNote({ formData, userType }) {
  const { data } = await axios.post(`${userType}/previous-employment/notes`, formData);
  return data;
}

export async function deletePrevEmploymentNote({ id, userType }) {
  const { data } = await axios.delete(`${userType}/previous-employment/notes/${id}`);
  return data;
}
