export const getInitialValues = (data, accounts) => {
  const {
    addition_type,
    deduction_type,
    tax_type,
    benefit_type,
    amount,
    percent,
    account_id,
    tax_exempt,
    payroll_tax,
    is_percent,
  } = data || {};
  const type = addition_type || deduction_type || tax_type || benefit_type || '';

  return {
    type,
    amount: amount || percent || '',
    account: accounts.find((item) => item.id === account_id) || null,
    tax_exempt: !!tax_exempt,
    taxes: payroll_tax || [],
    is_percent: is_percent ? 1 : 0,
  };
};
