import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import uuid from 'react-uuid';
import { RemoveFavorites } from 'Api/Layout';
import './leftMenu.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as EditIcon } from 'assets/sidemanu/edit.svg';
import { Typography } from '../Typography';

import { LayoutContext } from '../layout';

const LeftMenuClosedPopover = ({ children, subItems, editable, parentTitle, indigoItems, onClick, openInNewTab }) => {
  const { onDeleteFavorites } = useContext(LayoutContext);
  const { permissions } = useSelector((state) => state?.root);
  const [show, setShow] = useState(false);
  const [className, setClassName] = useState('');
  const { use } = useTheme();
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(null);
  const [edit, setEdit] = useState(false);
  const stylesObj = useMemo(() => {
    return {
      buttonText: {
        fontWeight: 700,
        margin: '0 0 8px 0',
        color: use(palette.dark800, palette.gray200),
      },
      editItems: {
        backgroundColor: use(palette.gray700, palette.gray200),
      },
      editIcon: {
        margin: '0 0 8px 15px',
      },
    };
  }, [use, palette, subItems]);
  const [hoveredFavoriteIndex, setHoveredFavoriteIndex] = useState(-1);
  const onClickPopoverItem = (path, state) => {
    if (state) {
      navigate(path, { state });
      return;
    }
    path && path !== '' && navigate(path);
  };

  const onMouseOver = (e) => {
    const item = e.target.closest('.leftMenuClosedPopover_item');

    setHoveredFavoriteIndex(item.dataset.id || -1);
    setClassName(use('light', 'dark', 'primary'));
  };

  const onMouseLeave = () => {
    setClassName('');
  };

  const deleteItemHandler = (id) => {
    RemoveFavorites(id).then(() => {
      onDeleteFavorites(id);
    });
  };

  const content = (
    <Popover.Body>
      <div className='leftMenuClosedPopover_title_wrapper'>
        <Typography variant='s2' className='button-text' style={stylesObj.buttonText}>
          {parentTitle}
        </Typography>

        {!!editable && (
          <EditIcon
            fill={use(palette.gray700, palette.gray500)}
            onClick={() => setEdit((p) => !p)}
            style={stylesObj.editIcon}
          />
        )}
      </div>
      {subItems
        ?.filter(
          (el) =>
            !el.subMenuPermissions?.length ||
            el.subMenuPermissions?.some((permission) => permissions.includes(permission))
        )
        ?.map((el, index) => {
          const { id, subTitle, Icon, path, state } = el;
          const subTitleStyle = {
            marginLeft: Icon && 4,
            fontWeight: indigoItems ? 700 : 500,
            color: indigoItems
              ? use(palette.indigo500, palette.indigo400, palette.indigo500)
              : use(palette.gray700, palette.gray200),
          };

          const TagName = el?.path ? NavLink : 'div';
          const searchParams = new URLSearchParams(state);
          const fullPath = searchParams?.size !== 0 ? `${path}?${searchParams}` : path;
          return (
            <TagName
              {...(fullPath && { to: fullPath })}
              key={uuid()}
              data-id={index}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShow(false);
                +openInNewTab === 0 && onClickPopoverItem(path, state);
                setActiveId(id);
                onClick(el);
              }}
              className={`leftMenuClosedPopover_item ${+hoveredFavoriteIndex === index ? className : ''} 
                        ${id === activeId ? use('light_pressed', 'dark_pressed') : ''}
                        ${indigoItems ? 'favorites' : ''}
                        `}
            >
              {!indigoItems && (
                <div className='leftMenuClosedPopover_line_wrapper'>
                  <div className='leftMenuClosedPopover_line' />
                </div>
              )}

              <div
                className='leftMenuClosedPopover_block'
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                style={{
                  marginLeft: indigoItems ? 0 : 16,
                }}
              >
                {Icon && <Icon fill={use(palette.gray700, palette.gray200)} width={18} />}

                <Typography variant='s2' className='popover_text' style={subTitleStyle}>
                  {subTitle}
                </Typography>

                <div
                  onClick={() => deleteItemHandler(id)}
                  className={`edit_items_delete_wrapper ${edit ? 'show' : ''}`}
                >
                  <span className='edit_items' style={stylesObj.editItems} />
                </div>
              </div>
            </TagName>
          );
        })}
    </Popover.Body>
  );

  return (
    <div>
      {children && (
        <OverlayTrigger
          trigger='click'
          placement='right-start'
          overlay={<UpdatingPopover id='popover-contained'>{content}</UpdatingPopover>}
          rootClose={show}
          onToggle={(value) => {
            setShow(value);
          }}
          show={show}
          defaultShow={false}
          delay={0}
        >
          {children}
        </OverlayTrigger>
      )}
    </div>
  );
};

export default LeftMenuClosedPopover;

const UpdatingPopover = React.forwardRef(({ popper, children, show: _, ...props }, ref) => {
  const { use } = useTheme();
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);
  return (
    <Popover
      ref={ref}
      {...props}
      placement='right-end'
      arrowProps={{ style: { transform: 'translate(-7px, 3px)' } }}
      className={`leftMenuClosedPopover ${use('light', 'dark')}`}
    >
      {children}
    </Popover>
  );
});
