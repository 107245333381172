import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import backId from 'assets/icons/back-id.png';
import frontId from 'assets/icons/front-id.png';
import close from 'assets/icons/drivers/close.svg';
import button from 'assets/icons/drivers/button.svg';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import editIcon from 'assets/icons/drivers/edit-white-icon.png';
import ImageDropZone from 'components/ImgageDropZone';
import DriverLicenseModal from 'components/DriverLicenseModal';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { LicenseType, uploadDriverDocument } from 'Api/Driver';
import { DatePicker } from 'common/Pickers';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import AddressFields from 'common/AddressFields';
import { validationSchema } from './validationSchema';

const DriverLicense = ({ driverInfo, updateGeneralProfile, loading }) => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [toggle, setToggle] = useState(false);
  const [isBackSide, setIsBackSide] = useState(false);
  const [cropper, setCropper] = useState();
  const [cropData, setCropData] = useState('');
  const [cropDataBack, setCropDataBack] = useState('');
  const [driverLicense, setDriverLicense] = useState([]);
  const [modalShow, setModalShow] = useState();
  const [image, setImage] = useState();
  const [openDriverLicense, setOpenDriverLicense] = useState(false);

  const saveGeneral = (values) => {
    const {
      license_city,
      license_state,
      license_country,
      license_country_data,
      license_state_data,
      license_city_date,
    } = values;
    const payload = {
      ...driverInfo,
      ...values,
      license_city: license_city_date?.id || license_city,
      license_state: license_state_data?.id || license_state,
      license_country: license_country_data?.id || license_country,
      license_exp_dt: values?.license_exp_dt ? moment(values.license_exp_dt).format('YYYY-MM-DD') : null,
    };

    if (payload?.application_upload && payload?.application_upload.includes('https')) {
      delete payload.application_upload;
    }
    if (payload?.driver_licence_upload && payload?.driver_licence_upload.includes('https')) {
      delete payload.driver_licence_upload;
    }
    if (payload?.driver_licence_back_upload && payload?.driver_licence_back_upload.includes('https')) {
      delete payload.driver_licence_back_upload;
    }
    updateGeneralProfile(payload, null, successResponse, 'Driver license has been updated successfully!');
  };

  const { values, handleChange, handleBlur, touchedErrors, handleSubmit } = useForm({
    initialValues: {
      license_no: '',
      license_exp_dt: new Date(),
      license_type: '',
      driver_licence_upload: null,
      driver_licence_back_upload: null,
      license_address1: '',
      license_address2: '',
      license_country: null,
      license_state: null,
      license_city: null,
      license_zip: '',
      license_country_data: null,
      license_state_data: null,
      license_city_date: null,
      is_license_exp_dt: false,
    },
    validationSchema,
    onSubmit: saveGeneral,
  });

  useEffect(() => {
    handleChange('driver_licence_upload', driverInfo?.driver_licence_upload);
    handleChange('driver_licence_back_upload', driverInfo?.driver_licence_back_upload);
    handleChange('license_no', driverInfo?.license_no);
    handleChange(
      'license_exp_dt',
      driverInfo?.license_exp_dt ? moment(driverInfo?.license_exp_dt).toDate() : new Date()
    );
    handleChange('license_type', driverInfo?.license_type);
    handleChange('license_address1', driverInfo?.license_address1);
    handleChange('license_address2', driverInfo?.license_address2);
    handleChange('license_country', driverInfo?.license_country);
    handleChange('license_state', driverInfo?.license_state);
    handleChange('license_city', driverInfo?.license_city);
    handleChange('license_zip', driverInfo?.license_zip);
    handleChange('license_city_date', driverInfo?.license_city_date);
    handleChange('license_country_data', driverInfo?.license_country_data);
    handleChange('license_state_data', driverInfo?.license_state_data);

    if (driverInfo?.license_exp_dt) {
      const compareDate = moment(new Date(), 'MM/DD/YYYY');
      const startDate = moment(new Date(driverInfo?.license_exp_dt), 'MM/DD/YYYY').subtract(31, 'days');
      const endDate = moment(new Date(driverInfo?.license_exp_dt), 'MM/DD/YYYY');
      handleChange('is_license_exp_dt', compareDate.isBetween(startDate, endDate));
    }
  }, [driverInfo]);

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], driverInfo?.id).then((res) => {
      handleChange(propertyName, res.data.document_path);
      if (propertyName !== 'application_upload') {
        if (!isBackSide) {
          setCropData(cropper.getCroppedCanvas().toDataURL());
        } else {
          setCropDataBack(cropper.getCroppedCanvas().toDataURL());
        }
      }
    });
  };

  const successResponse = (msg) => {
    showToaster({ type: 'success', message: msg });
    setToggle(!toggle);
  };

  useEffect(() => {
    LicenseType().then((res) => {
      setDriverLicense(res.data);
    });
  }, []);

  const imageSelected = (file) => {
    setModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadDocument([blob], isBackSide ? 'driver_licence_back_upload' : 'driver_licence_upload');
        setModalShow(false);
      });
    }
  };

  const handleDriverLicenseAddressChange = (event, value) => {
    const addressFieldsMapper = {
      address: 'license_address1',
      address2: 'license_address2',
      city: 'license_city_date',
      state: 'license_state_data',
      country: 'license_country_data',
      zipcode: 'license_zip',
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  return (
    <>
      <div className='header' style={{ borderColor: palette.gray50 }}>
        <p className='heading' style={{ color: palette.gray900 }}>
          Driver License
        </p>
        {!toggle ? (
          <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
        ) : (
          <div>
            <img src={close} alt='' onClick={() => setToggle(!toggle)} className='tick-close' />
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <img src={button} alt='' onClick={handleSubmit} className='tick-close' />
            )}
          </div>
        )}
      </div>
      <div className='document-details' style={{ backgroundColor: palette.white }}>
        <div className='d-flex align-items-center' style={{ marginLeft: '16px' }}>
          <Typography variant='s2' style={{ marginRight: '8px' }}>
            Front Side
          </Typography>
          <CustomCheckbox
            type='switch'
            name='license_side'
            smail={false}
            checked={isBackSide}
            onChange={(checked) => {
              setIsBackSide(checked);
            }}
          />
          <Typography variant='s2' style={{ marginLeft: '8px' }}>
            Back Side
          </Typography>
        </div>
        <div className='upload-wrapper' style={{ borderBottomColor: palette.gray50 }}>
          {!isBackSide ? (
            toggle ? (
              <div className='license-view' style={{ backgroundColor: palette.white, position: 'relative' }}>
                <img
                  src={cropData || (values?.driver_licence_upload ? values?.driver_licence_upload : frontId)}
                  style={
                    cropData || values?.driver_licence_upload
                      ? { width: '100%', height: '100%' }
                      : { width: '100%', height: '100%', objectFit: 'contain' }
                  }
                  alt='License'
                  className='license-image'
                />
                <div className='license-view-edit-icon'>
                  <img
                    src={editIcon}
                    alt='editIcon'
                    className='license-image'
                    onChange={() => handleChange('driver_licence_upload', values.driver_licence_upload)}
                  />
                </div>
                <ImageDropZone
                  onDrop={(file) => imageSelected(file)}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['image/jpeg', 'image/png']}
                />
              </div>
            ) : (
              <div
                className='upload-document-info'
                style={{
                  backgroundColor: palette.gray50,
                  borderColor: palette.gray50,
                }}
                onClick={() => {
                  if (driverInfo?.driver_licence_upload) {
                    setOpenDriverLicense(true);
                  }
                }}
              >
                <img
                  src={driverInfo?.driver_licence_upload || frontId}
                  style={
                    driverInfo?.driver_licence_upload
                      ? { width: '100%', height: '100%' }
                      : { width: '100%', height: '100%', objectFit: 'contain' }
                  }
                  alt=''
                />
              </div>
            )
          ) : toggle ? (
            <div className='license-view' style={{ backgroundColor: palette.white, position: 'relative' }}>
              <img
                src={cropDataBack || (values?.driver_licence_back_upload ? values?.driver_licence_back_upload : backId)}
                style={
                  cropDataBack || values?.driver_licence_back_upload
                    ? { width: '100%', height: '100%' }
                    : { width: '100%', height: '100%', objectFit: 'contain' }
                }
                alt='License'
                className='license-image'
              />
              <div className='license-view-edit-icon'>
                <img
                  src={editIcon}
                  alt='editIcon'
                  className='license-image'
                  onChange={() => handleChange('driver_licence_back_upload', values.driver_licence_back_upload)}
                />
              </div>
              <ImageDropZone
                onDrop={(file) => imageSelected(file)}
                width='100%'
                height='100%'
                name='small'
                deletable={false}
                customStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
                accept={['image/jpeg', 'image/png']}
              />
            </div>
          ) : (
            <div
              className='upload-document-info'
              style={{
                backgroundColor: palette.gray50,
                borderColor: palette.gray50,
              }}
              onClick={() => {
                if (driverInfo?.driver_licence_back_upload) {
                  setOpenDriverLicense(true);
                }
              }}
            >
              <img
                src={driverInfo?.driver_licence_back_upload || backId}
                style={
                  driverInfo?.driver_licence_back_upload
                    ? { width: '100%', height: '100%' }
                    : { width: '100%', height: '100%', objectFit: 'contain' }
                }
                alt=''
              />
            </div>
          )}
        </div>
        {toggle ? (
          <div className='information-edit-mode' style={{ backgroundColor: palette.white }}>
            <div
              className='col-12 d-flex align-items-center information-edit-mode-sections'
              style={{ borderBottomColor: palette.gray50 }}
            >
              <div className='col-6'>
                <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                  Driver License #
                </p>
              </div>
              <div className='col-6'>
                <input
                  className='input-type'
                  style={{
                    backgroundColor: palette.white,
                    borderColor: palette.gray50,
                    color: 'black',
                  }}
                  type='text'
                  name='license_no'
                  id='license_no'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.license_no}
                />
              </div>
            </div>
            <div
              className='col-12 d-flex align-items-center information-edit-mode-sections'
              style={{ borderBottomColor: palette.gray50 }}
            >
              <div className='col-6'>
                <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                  Driver License Type
                </p>
              </div>
              <div className='col-6'>
                <CustomSelect
                  defaultValue='Select License Type..'
                  options={driverLicense}
                  onChange={(value) => handleChange('license_type', value)}
                  onBlur={handleBlur}
                  value={values.license_type}
                  id='license_type'
                  name='name'
                  patchProperty='name'
                />
              </div>
            </div>
            <div
              className='col-12 d-flex align-items-center information-edit-mode-sections'
              style={{ borderBottomColor: palette.gray50 }}
            >
              <div className='col-6'>
                <p className='table-data-text m-0' style={{ color: palette.gray400 }}>
                  Driver License Expiry
                </p>
              </div>
              <div className='col-6'>
                <DatePicker
                  size='small'
                  name='license_exp_dt'
                  value={values.license_exp_dt}
                  onChange={(date) => handleChange('license_exp_dt', date)}
                  onBlur={handleBlur}
                  disablePast
                  error={touchedErrors.license_exp_dt}
                />
              </div>
            </div>
            <div className='col-12 d-flex information-edit-mode-sections' style={{ borderBottomColor: palette.gray50 }}>
              <div className='col-6'>
                <p className='table-data-text property' style={{ color: palette.gray400 }}>
                  License Address
                </p>
              </div>
              <div className='col-6'>
                <AddressFields
                  size='small'
                  hideLabel
                  isOneColumn
                  values={{
                    address: values.license_address1,
                    address2: values.license_address2,
                    city: values.license_city_date,
                    state: values.license_state_data,
                    country: values.license_country_data,
                    zipcode: values.license_zip,
                  }}
                  handleChange={handleDriverLicenseAddressChange}
                  touchedErrors={touchedErrors}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='information'>
            <div className='info-container border-block'>
              <p className='property'>Driver License #</p>
              <p className='value contact'>{driverInfo?.license_no}</p>
            </div>
            <div className='info-container border-block'>
              <p className='property'>Driver License Type</p>
              <p className='value' style={{ color: palette.gray900 }}>
                {driverInfo?.license_type}
              </p>
            </div>
            <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
              <p className='property'>Driver License Expiry</p>
              {values.is_license_exp_dt ? (
                <div className='expiry-container mb-0' style={{ backgroundColor: '#faf0f3' }}>
                  <p className='expiry' style={{ color: 'rgb(220, 64, 103)' }}>
                    {driverInfo?.license_exp_dt ? formatDate(driverInfo?.license_exp_dt) : '-'}
                  </p>
                </div>
              ) : (
                <p className='value' style={{ color: palette.gray900 }}>
                  {driverInfo?.license_exp_dt ? formatDate(driverInfo?.license_exp_dt) : '-'}
                </p>
              )}
            </div>
            <div className='info-container address border-block' style={{ borderBottomColor: palette.gray50 }}>
              <p className='property'>License Address</p>
              <div className='address-details'>
                <p className='value' style={{ color: palette.gray900 }}>
                  {driverInfo?.license_address1}
                </p>
                <p className='value' style={{ color: palette.gray900 }}>
                  {driverInfo?.license_address2}
                </p>
                <p className='value' style={{ color: palette.gray900 }}>{`${driverInfo?.license_city_date?.name || ''}${
                  driverInfo?.license_state_data?.name ? ',' : ''
                } ${driverInfo?.license_state_data?.name || ''}  ${driverInfo?.license_zip || ''}`}</p>
                <p className='value' style={{ color: palette.gray900 }}>
                  {driverInfo?.license_country_data?.name}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={modalShow ? 'fade modal-backdrop show' : 'modal'}>
        {!!modalShow && (
          <ImageCropModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            image={image}
            getCropData={getCropData}
            setCropper={setCropper}
          />
        )}
      </div>
      {openDriverLicense && (
        <DriverLicenseModal
          open={openDriverLicense}
          onClose={() => setOpenDriverLicense(false)}
          license={isBackSide ? driverInfo.driver_licence_back_upload : driverInfo.driver_licence_upload}
        />
      )}
    </>
  );
};

export default DriverLicense;
