import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { palette } from 'utils/constants';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { getApplicant, getApplicantsList, getJobStatuses, updateJobStatus } from 'Api/Applicants';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import useShowToaster from 'hooks/useShowToaster';
import useDebounce from 'hooks/useDebounce';
import ViewApplicantModal from '../ViewApplicantModal';
import { initialFilters, useApplicantsColumns } from './ApplicantsTable.data';

import Search from '../Search';
import TableFooter from '../TableFooter';
import { STableWrapper } from '../../ApplicantsAndReferrals.styles';

const ApplicantsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showToaster = useShowToaster();
  const [applicants, setApplicants] = useState({ data: [] });
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [tableIndex, setTableIndex] = useState(Date.now());
  const debouncedSearch = useDebounce(search, 500);
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [filter, setFilter] = useState({ filterTableTop: [] });

  const getStatuses = async (total) => {
    const { data } = await getJobStatuses();
    setStatuses(data);
    setFilter((prevState) => ({
      ...prevState,
      filterTableTop: [
        {
          id: 0,
          value: 0,
          label: 'All',
          key: 'all',
          count: prevState?.filterTableTop?.[0]?.count || total,
        },
        ...data.map((i) => ({ ...i, label: i.name, key: i.name.toLowerCase() })),
      ],
    }));
  };

  const getApplicants = async () => {
    setLoading(true);
    const { page, itemsPerPage, tab, ...rest } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    const params = {
      page,
      itemsPerPage,
      query: debouncedSearch || undefined,
      status_id: tab || undefined,
      [sortField]: sort.sortBy,
      ...rest,
    };

    try {
      const response = await getApplicantsList(params);
      setApplicants(response);
      getStatuses(response.total);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
    setLoading(false);
  };

  const getApplicantById = async (id) => {
    try {
      const response = await getApplicant(id);
      setSelectedApplicant(response.data);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
  };

  const updateApplicantStatus = async (id) => {
    try {
      await updateJobStatus(id, { status_id: 2 });
      setTableIndex(Date.now());
    } catch (e) {
      // Do nothing
    }
  };

  const onView = async (row) => {
    if (row.status?.name === 'New') {
      updateApplicantStatus(row.id);
    }
    getApplicantById(row.id);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeTab = (n) => {
    setSearch('');
    setSelectedFilters({ ...selectedFilters, page: 1, tab: n.id });
  };

  useEffect(() => {
    getApplicants();
  }, [selectedFilters, sort, debouncedSearch, tableIndex]);

  useEffect(() => {
    const applicantToViewId = searchParams.get('applicantToViewId');

    if (applicantToViewId) {
      getApplicantById(applicantToViewId);
      searchParams.delete('applicantToViewId');
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  const PaginationComponent = (event) => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.itemsPerPage}
      totalCount={applicants.total}
      totalLength={applicants.data.length}
      lastPage={applicants.last_page}
      currentPage={applicants.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  const columns = useApplicantsColumns({ sortingQuery, sort });

  return (
    <STableWrapper>
      <Search search={search} onChange={setSearch} />
      <SwitchRadio
        name='tableTopTabMenu'
        items={filter.filterTableTop}
        value={selectedFilters.tab}
        type='tab'
        onChange={onChangeTab}
        plus={false}
      />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={applicants?.data}
          onRowClick={(e, row) => onView(row)}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
      {!!selectedApplicant && (
        <ViewApplicantModal
          statuses={statuses}
          applicant={selectedApplicant}
          onClose={() => setSelectedApplicant(null)}
          onUpdate={() => setTableIndex(Date.now())}
        />
      )}
    </STableWrapper>
  );
};

export default ApplicantsTable;
