import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import AddVendor from 'pages/Accounting/Vendors/components/AddVendor';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getVendorsList } from 'Api/Vendors';
import { compoundingOptions } from '../AddAccount.data';
import { SInputWrapper } from '../AddAccount.styles';

const LoanFields = ({ values, handleChange, handleBlur, touchedErrors, account }) => {
  const [openAddVendor, setOpenAddVendor] = useState(false);
  const [vendors, setVendors] = useState([]);

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddVendorSuccess = (vendor) => {
    getVendors();
    handleChange('vendor', vendor);
  };

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <div>
      <div className='d-flex gap-3 mb-4'>
        <InputWithIcon
          required
          icon='%'
          type='number'
          label='Interest Rate'
          placeholder='0'
          name='interest_rate'
          value={values.interest_rate}
          onChange={handleChange}
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors.interest_rate}
        />
        <div>
          <InputLabel required>Loan Term</InputLabel>
          <SInputWrapper>
            <Input
              type='number'
              placeholder='0'
              name='loan_term'
              value={values.loan_term}
              onChange={handleChange}
              onKeyDown={blockNonPositiveNumbers}
              className='loan-term-input'
            />
            <Autocomplete
              width='90px'
              name='loan_term_type'
              options={['months', 'years']}
              value={values.loan_term_type}
              onChange={(e, val) => handleChange('loan_term_type', val)}
            />
          </SInputWrapper>
          <ErrorMessage error={touchedErrors.loan_term} />
        </div>
        <InputWithIcon
          required
          type='number'
          label='Loan Amount'
          placeholder='0'
          name='loan_amount'
          value={values.loan_amount}
          onChange={handleChange}
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors.loan_amount}
        />
      </div>
      <div className='d-flex gap-3 mb-4'>
        {!account && (
          <>
            <InputWithIcon
              required
              width='150px'
              type='number'
              placeholder='0'
              label='Current Balance'
              name='current_balance'
              value={values.current_balance}
              onChange={handleChange}
              onKeyDown={blockNonPositiveNumbers}
              error={touchedErrors.current_balance}
            />
            <DatePicker
              required
              label='Opening Date'
              width='150px'
              name='opening_date'
              onBlur={handleBlur}
              value={values.opening_date}
              onChange={(val) => handleChange('opening_date', val)}
              error={touchedErrors.opening_date}
            />
          </>
        )}
        <div>
          <Autocomplete
            required
            width='184px'
            label='Compounding'
            name='compounding'
            options={compoundingOptions}
            value={values.compounding}
            onChange={(e, val) => handleChange('compounding', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.compounding}
          />
        </div>
      </div>
      {!account && (
        <div className='d-flex align-items-center gap-3'>
          <CustomCheckbox
            type='switch'
            name='add_recurring_bill'
            checked={!!values.add_recurring_bill}
            onChange={(checked) => handleChange('add_recurring_bill', checked)}
            smail={false}
          />
          <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
            Add to Recurring Bills
          </Typography>
        </div>
      )}
      {!!values.add_recurring_bill && !account && (
        <div className='d-flex gap-4 ms-4 mt-2'>
          <div className='w-100'>
            <div className='d-flex justify-content-between'>
              <InputLabel required>Lender Name</InputLabel>
              <Typography
                variant='s2'
                style={{ color: palette.indigo500 }}
                className='add-action'
                onClick={() => setOpenAddVendor(true)}
              >
                <PlusIcon fill={palette.indigo500} className='me-2' />
                Add Vendor
              </Typography>
            </div>
            <Autocomplete
              name='vendor'
              options={vendors}
              value={values.vendor}
              onChange={(e, val) => handleChange('vendor', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.vendor}
            />
          </div>
          <DatePicker
            required
            width='190px'
            label='Next Payment Due'
            name='next_payment_due'
            value={values.next_payment_due}
            onBlur={handleBlur}
            onChange={(val) => handleChange('next_payment_due', val)}
            error={touchedErrors.next_payment_due}
          />
        </div>
      )}
      {openAddVendor && (
        <AddVendor open={openAddVendor} onClose={() => setOpenAddVendor(false)} onSuccess={onAddVendorSuccess} />
      )}
    </div>
  );
};

export default LoanFields;
