import get from 'lodash/get';

export const checkDateInterval = (startDate, endDate, datesArray) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  for (let i = 0; i < datesArray.length; i++) {
    const dateObj = new Date(datesArray[i]);
    if (dateObj >= new Date() && dateObj >= startDateObj && dateObj <= endDateObj) {
      return true;
    }
  }
  return false;
};

export const getDatesInRange = (datesArray, startDate, endDate, formatDate) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const result = [];
  datesArray.forEach((item) => {
    const currentDate = new Date(item);
    if (currentDate >= startDateObj && currentDate <= endDateObj) {
      result.push(formatDate(item));
    }
  });
  return result;
};

const calculateSums = (weight, stops) => {
  const sums = [];
  let sum = 0;
  for (let i = 0; i < stops.length; i++) {
    if (Number(stops[i].stop_point_type) === 1) {
      sum += Number(stops[i].weight);
    } else if (Number(stops[i].stop_point_type) === 2) {
      sums.push(Number(sum));
      sum = 0;
    }
  }
  return sums;
};

export const validateWeight = (weight, shipments) => {
  const sums = calculateSums(weight, shipments);
  const maxSum = Math.max(...sums);

  if (Number(weight) < Number(maxSum)) {
    return {
      isValid: false,
      weight: maxSum,
    };
  }
  return {
    isValid: true,
    weight: maxSum,
  };
};

export const filterDrivers = (drivers, { sort }) => {
  if (!sort?.field) {
    return drivers;
  }

  return [...drivers].sort((a, b) => {
    if (get(a, sort.field) > get(b, sort.field)) {
      return sort.sortBy === 'asc' ? 1 : -1;
    }
    if (get(a, sort.field) < get(b, sort.field)) {
      return sort.sortBy === 'asc' ? -1 : 1;
    }
    return 0;
  });
};
