import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import { palette } from 'utils/constants';

export const SActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
`;

export const SDotsWrapper = styled.div`
  cursor: pointer;

  .dots {
    display: inline-flex;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
`;

export const SPopover = styled(Popover)`
  &.MuiPopover-root {
    .MuiPaper-root {
      min-width: 80px;
      margin-top: -12px;

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      ::-webkit-scrollbar-thumb {
        background: ${palette.gray300};
        border-radius: 8px;
      }
    }

    .actions-wrapper {
      list-style-type: none;
      background: #ffffff;
      box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
        0 5px 15px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: ${({ $padding }) => $padding || '6px 4px'};
      max-height: calc(100% - 200px);
      overflow: auto;

      li {
        text-transform: none;
        cursor: pointer;
        width: 100%;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        border-radius: 4px;

        &.delete-action {
          color: ${palette.red500};
        }

        &.green-action {
          color: ${palette.green500};
        }

        &.disabled {
          color: ${palette.gray700};
          cursor: not-allowed;
        }

        :hover {
          background-color: ${palette.indigo0};
        }
      }
    }
  }
`;
