import React from 'react';
import { useNavigate } from 'react-router-dom';
import person from 'assets/images/person.svg';
import { ReactComponent as CallIcon } from 'assets/icons/drivers/call.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SWrapper } from './UserInfo.styles';

const UserInfo = ({ userData, onUserClick = () => null }) => {
  const navigate = useNavigate();
  const {
    id,
    image,
    profile_logo,
    fname,
    first_name,
    lname,
    last_name,
    phone_number,
    phone_no,
    owner_operator_name,
    user_type,
  } = userData || {};

  const onUserNameClick = () => {
    if (user_type === 3) {
      navigate(`/ownerOperator-profile/${id}`);
    } else {
      navigate(`/${user_type === 1 ? 'staff' : 'driver'}-profile/${id}/general`);
    }
    onUserClick();
  };

  return (
    <SWrapper>
      {user_type !== 3 && <img src={image || profile_logo || person} alt='img' className='user-profile-picture' />}
      <Typography variant='s2' style={{ color: palette.indigo500 }} className='user-name' onClick={onUserNameClick}>
        {fname || first_name || owner_operator_name} {lname || last_name}
      </Typography>
      <span className='icon-wrapper'>
        <a href={`tel:${phone_number || phone_no}`}>
          <CallIcon />
        </a>
      </span>
    </SWrapper>
  );
};

export default UserInfo;
