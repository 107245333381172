import React from 'react';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { convertToCustomerTime, formatAmount } from 'utils/helpers';

export const initialFilters = {
  page: 1,
  itemsPerPage: 50,
};

export const useColumns = () => {
  return [
    {
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' />,
      field: 'shipment_id',
      render: (row) => <Typography variant='b2'>{row.shipment_id}</Typography>,
    },
    {
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' />,
      field: 'reference_id',
      render: (row) => <Typography variant='b2'>{row.reference_id}</Typography>,
    },
    {
      title: <ColumnHeader title='ORIGIN' field='origin' />,
      field: 'origin',
      render: (row) => {
        const city = row.stops[0]?.stop_point[0]?.city?.name;
        const state = row.stops[0]?.stop_point[0]?.state?.short_name;
        const zip = row.stops[0]?.stop_point[0]?.zipcode;

        return (
          <Typography variant='b2'>
            {city}, {state} {zip}
          </Typography>
        );
      },
    },
    {
      title: <ColumnHeader title='DESTINATION' field='destination' />,
      field: 'destination',
      render: (row) => {
        const city = row.stops[row.stops.length - 1]?.stop_point[0]?.city?.name;
        const state = row.stops[row.stops.length - 1]?.stop_point[0]?.state?.short_name;
        const zip = row.stops[row.stops.length - 1]?.stop_point[0]?.zipcode;

        return (
          <Typography variant='b2'>
            {city}, {state} {zip}
          </Typography>
        );
      },
    },
    {
      title: <ColumnHeader title='STOPS' field='stops' />,
      field: 'stops',
      render: (row) => <Typography variant='b2'>{row.stops ? row.stops.length : '-'}</Typography>,
    },
    {
      title: <ColumnHeader title='TOTAL MILES' field='total_miles' />,
      field: 'total_miles',
      render: (row) => <Typography variant='b2'>{row.total_miles ? Math.round(row.total_miles) : '-'}</Typography>,
    },
  ];
};

export const useRevenueColumns = (currency) => {
  return [
    {
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' />,
      field: 'shipment_id',
      render: (row) => <Typography variant='b2'>{row.shipment_id}</Typography>,
    },
    {
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' />,
      field: 'reference_id',
      render: (row) => <Typography variant='b2'>{row.reference_id}</Typography>,
    },
    {
      title: <ColumnHeader title='GROSS AMOUNT' field='gross_amount' />,
      field: 'gross_amount',
      render: (row) => (
        <Typography variant='b2'>
          {currency}
          {formatAmount(row.gross_amount)}
        </Typography>
      ),
    },
    {
      title: <ColumnHeader title='STARTED - COMPLETED' field='started_completed' />,
      field: 'started_completed`',
      render: (row) => {
        return (
          <div>
            {row.start_date || row.stops?.[0]?.arrival_date ? (
              <>
                <Chip
                  label={
                    row.start_date || row.stops?.[0]?.arrival_date
                      ? convertToCustomerTime(row?.start_date || row.stops?.[0]?.arrival_date)
                      : '-'
                  }
                />{' '}
                - <Chip label={row.complete_date ? convertToCustomerTime(row?.complete_date) : 'Pending'} />
              </>
            ) : (
              <Chip label='Pending' />
            )}
          </div>
        );
      },
    },
  ];
};
