import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import { Modal } from 'react-bootstrap';
import Input from 'common/Input';
import Loader from 'common/Loader';
import ViewPdf from 'components/ViewPdf';
import DatePicker from 'common/DatePicker';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import DocumentsList from 'componentsV2/Equipment/Documents/DocumentsList';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { validationSchema } from 'componentsV2/Equipment/Documents/UpdateDocuments/validationSchema';
import { getInitialValues } from 'componentsV2/Equipment/Documents/UpdateDocuments/UpdateDocuments.data';
import {
  getAppliedEquipmentTypes,
  getHighwayTaxPolicy,
  getIFTAPolicy,
  getInsurancePolicies,
  getKYUPolicy,
} from 'Api/Insurance';
import {
  bulkCreateEquipmentDocuments,
  createEquipment,
  getEquipmentDocuments,
  getEquipmentDocumentTypes,
} from 'Api/Equipment';
import { SMergedInputs } from 'componentsV2/Equipment/Documents/DocumentsList/DocumentsList.styles';
import { createEquipmentDataConverter } from '../AddEquipment.data';
import { getSettingSuggestions } from './Documents.data';
import { SDocuments } from './Documents.styles';

const Documents = ({ Cancel, prevStep, nextStep, equipment, equipmentData, equipmentType }) => {
  const showToaster = useShowToaster();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const registrationDocument = useMemo(() => {
    return {
      id: uuid(),
      equipment_document_type: { id: 9, name: 'Registration' },
      reference_id: equipment?.ownership?.license_plate_number || '',
      document_exp_date: equipment?.ownership?.license_plate_expire_date
        ? moment(equipment?.ownership?.license_plate_expire_date)
        : null,
      no_expire: !equipment?.ownership?.license_plate_expire_date,
      use_from_policy: false,
      document: equipment?.ownership?.upload_registration || null,
      isNew: false,
    };
  }, []);

  const createNewEquipment = async () => {
    const value = {
      ...equipment?.equipmentType,
      ...equipment?.equipmentInfo,
      ...equipment?.ownership,
    };
    const formData = createEquipmentDataConverter(value, equipmentType);
    const { data } = await createEquipment(formData);
    showToaster({ type: 'success', message: 'Equipment has been created' });
    return data;
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const newEquipment = await createNewEquipment();

      if (values.documents?.length) {
        const body = {
          equipment_id: newEquipment.id,
          equipment_documents: values.documents.map((item) => ({
            equipment_document_type_id: item.equipment_document_type.id,
            document: item.document,
            document_name: item.document_name || null,
            document_exp_date:
              item.no_expire || !item.document_exp_date ? null : moment(item.document_exp_date).format('YYYY-MM-DD'),
            document_date: null,
            reference_id: item.reference_id,
            use_from_policy: !!item.use_from_policy,
          })),
        };

        await bulkCreateEquipmentDocuments(body);
      }
      nextStep(newEquipment);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues: { documents: getInitialValues([...documents, registrationDocument]) },
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  const getDocuments = async () => {
    try {
      const { data } = await getEquipmentDocuments(equipmentData?.id);
      setDocuments(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getPolicyTypes = async () => {
    setLoadingSuggestions(true);
    try {
      const [{ value: insurance }, { value: highwayTax }, { value: ifta }, { value: kyu }, { value: documentTypes }] =
        await Promise.allSettled([
          getInsurancePolicies(),
          getHighwayTaxPolicy(),
          getIFTAPolicy(),
          getKYUPolicy(),
          getEquipmentDocumentTypes(),
        ]);
      const insuranceSettings = {
        ...(insurance?.data || {}),
        ...(highwayTax?.data || {}),
        ...(ifta?.data || {}),
        ...(kyu?.data || {}),
      };
      setDocumentTypes(documentTypes);
      const { data } = await getAppliedEquipmentTypes({
        ownership: Number(equipment?.ownership?.owner_operator_type),
        equipment_type_id: Number(equipment?.equipmentType?.equipment_type_id),
      });

      const policyTypes = [];

      data.forEach((item) => {
        if (!policyTypes.includes(item.policy_name)) {
          policyTypes.push(item.policy_name);
        }
      });

      if (policyTypes.length) {
        const data = getSettingSuggestions(policyTypes, insuranceSettings);
        setSuggestions(data || []);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingSuggestions(false);
    }
  };

  useEffect(() => {
    if (
      equipment?.equipmentType?.equipment_type_id &&
      [1, 2, 3].includes(Number(equipment?.ownership?.owner_operator_type))
    ) {
      getPolicyTypes();
    }

    if (!equipmentData?.id) {
      return;
    }

    getDocuments();
  }, []);

  const filteredSuggestions = suggestions.filter((item) =>
    form.values.documents.every((i) => i.equipment_document_type?.id !== item.equipment_document_type?.id)
  );

  return (
    <div>
      <Modal.Body
        style={{
          padding: '0 20px 16px 20px',
          height: 'calc(80vh - 100px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
          background: '#f7f9fc',
          border: '0',
        }}
      >
        <SDocuments>
          <div className='documents-title'>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              Add Equipment Documents
            </Typography>
            <Typography variant='s3' style={{ color: palette.gray700 }}>
              Please upload PDF only and when setting an expiry date, please be sure the Out of Service triggers /
              alerts are correctly set in Company Settings.
            </Typography>
          </div>
          <div className='mt-4'>
            <DocumentsList form={form} equipmentId={equipment?.id} equipmentType={equipmentType} />
          </div>

          {loadingSuggestions ? (
            <Loader loading />
          ) : (
            !!filteredSuggestions.length && (
              <div className='suggestions'>
                <div>
                  <div className='documents-title'>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      Recommended Based on Company Policy
                    </Typography>
                    <Typography variant='s3' style={{ color: palette.gray700 }}>
                      These documents are used on same vehicle types that you have in your fleet.
                    </Typography>
                  </div>
                </div>
                <div className='mt-4'>
                  {filteredSuggestions.map((document) => (
                    <div key={document.id} className='d-flex align-items-center gap-4 mb-3'>
                      <div style={{ width: '630px' }}>
                        <div className='d-flex align-items-center gap-3'>
                          <SMergedInputs>
                            <div>
                              <Autocomplete
                                width='200px'
                                placeholder='Select Document Type'
                                options={documentTypes}
                                value={document.equipment_document_type}
                                onChange={() => null}
                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                disabled
                              />
                            </div>
                            <div>
                              <Input
                                width='150px'
                                placeholder='Reference ID'
                                value={document.reference_id}
                                onChange={() => null}
                                disabled
                                classNameInput={document.equipment_document_type?.id === 8 ? 'last-input' : ''}
                              />
                            </div>
                            {document.equipment_document_type?.id !== 8 && (
                              <div>
                                <DatePicker
                                  width='150px'
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={10}
                                  value={document.document_exp_date}
                                  dateFormat='MM/dd/yyyy'
                                  renderStart={false}
                                  onChange={() => null}
                                  disabled
                                  minDate={new Date()}
                                />
                              </div>
                            )}
                          </SMergedInputs>
                          {document.equipment_document_type?.id !== 8 && (
                            <div>
                              <CustomCheckbox checked={!!document.no_expire} disabled>
                                <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                                  No Expiry
                                </Typography>
                              </CustomCheckbox>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div>
                          <Typography
                            variant='s2'
                            style={{ color: palette.indigo500, cursor: 'pointer' }}
                            onClick={() => document.document && setPdfUrl(document.document)}
                          >
                            View Attachment
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <CustomButton
                          type='primary'
                          title='Select'
                          styleButton={{ padding: '2px 8px', margin: 0 }}
                          onClick={() => form.handleChange(`documents[${form.values.documents.length}]`, document)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </SDocuments>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between', padding: '5px 20px' }}>
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '4px 8px', margin: 0 }}
          onClick={Cancel}
        />
        <div className='d-flex gap-3'>
          <CustomButton
            type='secondary'
            title='Back'
            styleButton={{ padding: '4px 8px', margin: 0 }}
            onClick={() => prevStep(4)}
            disabled={loading}
          />
          <CustomButton
            type='secondary'
            title='Skip'
            styleButton={{ padding: '4px 8px', margin: 0 }}
            onClick={() => nextStep()}
            disabled={loading}
          />
          <CustomButton
            type='primary'
            title='Next Step'
            styleButton={{ padding: '4px 8px', margin: 0 }}
            onClick={form.handleSubmit}
            disabled={loading}
          />
        </div>
      </Modal.Footer>
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </div>
  );
};

export default Documents;
