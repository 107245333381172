import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PlusIcon from 'assets/icons/plus.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import styles from './SwitchRadio.module.css';
import EditModal from './EditTab/EditModal';
import EquipmentTableModal from './EditTab/EditTabContent';

import tabService from './tabs.service';

const SwitchRadio = ({
  style,
  items,
  name,
  defaultValue,
  TabData,
  title,
  OnTabChange,
  onChange,
  value,
  type,
  plus,
  onClickPlus,
  multiSelectOptions,
}) => {
  const { use } = useTheme();
  const [editModal, setEditModal] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const onChangeHandler = (value, key, item) => {
    onChange(name, value);
    OnTabChange(value, key, item);
  };
  async function tabsDataEquipment() {
    tabService.getTabs().then((tabs) => {
      setTabsData(tabs.filter((f) => f?.type === title));
    });
  }
  useEffect(() => {
    if (title) {
      tabsDataEquipment();
    }
  }, []);

  return (
    <>
      {type === 'tab' ? (
        <div className={styles.container} style={{ ...style }}>
          <div className={styles.tab_switchRadio} style={{ marginTop: title ? 10 : 14 }}>
            {items.map((item) => {
              return (
                <label className={styles.tab_switchRadio_label} key={item.key}>
                  <input
                    type='radio'
                    hidden
                    name={name}
                    checked={value === item.value}
                    value={item.value}
                    defaultChecked={defaultValue !== undefined ? item.value === defaultValue : undefined}
                    onChange={(e) => onChangeHandler(e.target.value, item?.key, item)}
                  />
                  <div className={styles.tab_switchRadio_item_wrap}>
                    <span
                      className={styles.tab_switchRadio_item}
                      style={{
                        color:
                          value === item.value || TabData.some((s) => s?.type.search(item?.key) > -1)
                            ? '#4F5AED'
                            : use(palette.gray700, palette.white),
                        fontWeight: value === item.value,
                      }}
                    >
                      {item.label}
                    </span>
                    <span
                      style={{
                        color: value === item.value ? '#4F5AED' : use(palette.gray700, palette.white),
                        backgroundColor: use(palette.indigo0, palette.dark600),
                      }}
                    >
                      {item.totalCount}
                    </span>
                    {item.count !== undefined && (
                      <span
                        className={styles.tab_switchRadio_count}
                        style={{
                          backgroundColor: use(palette.indigo0, palette.dark600),
                          color: value === item.value ? '#4F5AED' : use(palette.gray700, palette.white),
                        }}
                      >
                        {item.count}
                      </span>
                    )}
                  </div>
                </label>
              );
            })}

            {plus ? (
              <img
                src={PlusIcon}
                width={20}
                height={20}
                style={{ padding: 3, cursor: 'pointer' }}
                onClick={onClickPlus}
                alt=''
                className={styles.plus_icon}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className={styles.container} style={{ ...style }}>
          <span>{title || ''}</span>
          <div className={styles.switchRadio} style={{ marginTop: title ? 10 : 14 }}>
            {items.map((item) => {
              return (
                <label className={styles.switchRadio_label} key={item.key}>
                  <input
                    type='radio'
                    hidden
                    name={name}
                    checked={value === item.key}
                    value={item.value}
                    defaultChecked={defaultValue !== undefined ? item.value === defaultValue : undefined}
                    onChange={(e) => onChangeHandler(e.target.value)}
                  />
                  <span className={styles.switchRadio_item}>{item.label}</span>
                </label>
              );
            })}
          </div>
        </div>
      )}
      <Modal size='lg' centered show={editModal} enforceFocus={false} className='customModal'>
        <EditModal styleBody={{ width: 446 }} onHide={() => setEditModal(false)} headerTitle='Update Tab'>
          <EquipmentTableModal
            onHide={() => setEditModal(false)}
            multiSelectOptions={multiSelectOptions}
            Tabs={tabsData}
          />
        </EditModal>
      </Modal>
    </>
  );
};

export default SwitchRadio;
