import React, { useState } from 'react';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { SInfoSection, STable } from '../ShipmentSummary.styles';

const ShipmentNotes = ({ shipment, notes, loading }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const { convertToCustomerTime } = useDateFormat();

  const { shipment_billing } = shipment || {};

  return (
    <SInfoSection>
      <div>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          Shipment Notes -{' '}
          <Typography variant='s2' style={{ color: palette.indigo500 }}>
            {shipment_billing[0].billing_customer.company_name}
          </Typography>
        </Typography>
      </div>
      <div>
        {loading ? (
          <TableSkeleton loading={loading} />
        ) : (
          <STable>
            <thead>
              <tr className='header-row'>
                <th>#</th>
                <th>TITLE</th>
                <th>NOTE</th>
                <th>ADDED BY</th>
                <th>DATE</th>
                <th>ATTACHMENT</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((item) => (
                <tr key={item.id} className='body-row'>
                  <td>
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      {item.id}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      {item.title}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      {item.notes}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      {item.added_by ? `${item.added_by.first_name} ${item.added_by.last_name}` : ''}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      {item.created_at ? convertToCustomerTime(item.created_at) : '-'}
                    </Typography>
                  </td>
                  <td>
                    {item.attachment?.document ? (
                      <Typography
                        variant='s2'
                        style={{ color: palette.indigo500 }}
                        className='pointer'
                        onClick={() => setPdfUrl(item.attachment.document)}
                      >
                        View
                      </Typography>
                    ) : (
                      <Typography variant='s2' style={{ color: palette.gray900 }}>
                        -
                      </Typography>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </STable>
        )}
        {!loading && !notes.length && <NoRecords />}
      </div>
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Document' />}
    </SInfoSection>
  );
};

export default ShipmentNotes;
