import React, { useEffect, useState } from 'react';
import './signin.style.css';
import validator from 'validator/es';
import { isMobile } from 'react-device-detect';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useAuth } from 'context/auth.context';
import ErrorMessage from 'common/message/errorMessage';
import { detectBrowser } from 'common/BrowserVarify';
import eyeIcon from 'assets/icons/eye.png';
import profile from 'assets/icons/profile.svg';
import { ReactComponent as Logo } from 'assets/images/main-logo.svg';

const CryptoJS = require('crypto-js');

const SigninAsPrompt = ({ setsignPrompt, setUsername, setPassword }) => {
  const userCred = JSON.parse(localStorage.getItem('userCred'));
  const { login } = useAuth();
  const [active, setActive] = useState(0);
  const [handleSingIn, setHandleSingIn] = useState();

  const handleSelectAcc = (acc, idx) => {
    setActive(idx);
    setHandleSingIn(acc);
    setUsername(acc.userName);
    const bytes = CryptoJS.AES.decrypt(handleSingIn.password, 'my-secret-key@123');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    setPassword(decryptedData);
  };

  const signInas = () => {
    if (Object.keys(handleSingIn).length === 0) {
      return;
    }
    const bytes = CryptoJS.AES.decrypt(handleSingIn.password, 'my-secret-key@123');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    login(handleSingIn.userName, decryptedData);
  };

  return (
    <div className='prompt'>
      {userCred?.map((acc, idx) => (
        <div
          className={active === idx ? 'signInAs activeSignAcc' : 'signInAs'}
          key={acc.id}
          onClick={() => handleSelectAcc(acc, idx)}
        >
          <img src={profile} alt='profile' />
          <p>{acc.userName}</p>
        </div>
      ))}
      <div className='proptBtn'>
        <button onClick={signInas}>Sign In</button>
        <button onClick={() => setsignPrompt(false)}>Cancel</button>
      </div>
    </div>
  );
};

const Signin = () => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const { pathname } = useLocation();
  const browser = detectBrowser();
  const { value, setAuth, login, firstLogin } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [signPrompt, setsignPrompt] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkIfValidEmail = (e) => {
    e.preventDefault();
    if (validator.isEmail(e.target.value)) {
      setUsername(e.target.value);
      handleEmailChange(e.target.value);
    }
  };

  const handleEmailChange = (values) => {
    firstLogin(values);
  };

  useEffect(() => {
    if (isMobile) {
      window.open('https://www.truckindigital.com/app-manager-install', '_self');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    if (rememberMe) {
      localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
    }
    setLoading(true);
    await login(username, password);
    setLoading(false);
  };

  useEffect(() => {
    if (redirect) {
      login(username, password, redirect);
    }
  }, [redirect]);

  useEffect(() => {
    const userCred = JSON.parse(localStorage.getItem('userCred'));
    if (userCred !== null) {
      setsignPrompt(true);
    }
  }, [browser]);

  return (
    <div className='signin-layout'>
      <div className='video-cover'>
        <video
          autoPlay
          loop
          muted
          playsInline
          data-wf-ignore
          data-object-fit='cover'
          id='video'
          poster='../../assets/images/image 23.png'
        >
          <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.mp4`} data-wf-ignore='true' />
          <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.webm`} data-wf-ignore='true' />
        </video>
      </div>

      <div className='purple-filter' />
      {signPrompt && pathname === '/login' && (
        <SigninAsPrompt setsignPrompt={setsignPrompt} setUsername={setUsername} setPassword={setPassword} />
      )}
      <div className='form-container'>
        <div className='logo'>
          <Logo alt='logo' />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h1 className='header'>Welcome!</h1>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className='subheader'>Sign in into your account.</p>
        </div>
        <div>
          <form className='form'>
            <input
              id='username'
              placeholder='Email'
              name='username'
              type='email'
              className='form-control shadow-none onboarding-input'
              autoComplete=''
              onChange={(e) => checkIfValidEmail(e)}
            />
            <input
              id='password'
              name='Password'
              onChange={(e) => setPassword(e.target.value)}
              className='form-control onboarding-input'
              type={!showPwd ? 'password' : 'text'}
              autoComplete='off'
              placeholder='Password'
            />
            <div className='input-group-addon'>
              <img src={eyeIcon} onClick={() => setShowPwd(!showPwd)} alt='eyeIcon' />
            </div>
            {!!value.error && (
              <ErrorMessage
                message={value.error.includes('many login attempts') ? value.error : 'Incorrect password or email'}
                close={() => setAuth((prevState) => ({ ...prevState, error: false }))}
                show
              />
            )}
            <div className='forgot-pwd input-group'>
              <div className='remember'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onChange={() => setRememberMe(!rememberMe)}
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
                <p className='subtitle-s2'>Remember me.</p>
              </div>
              <a style={{ cursor: 'pointer' }} className='subtitle-s2 ' onClick={() => navigate('/forgot-password')}>
                Forgot password
              </a>
            </div>
            <button className='button btn signIn-btn' onClick={handleSubmit}>
              Sign In
            </button>
          </form>
        </div>
        <div className='signup'>
          <p className='subtitle-s2' style={{ color: '#E9EDF5' }}>
            Don't have an account? &nbsp; &nbsp;
          </p>
          <a className='button-text txt-btn' onClick={() => navigate('/onboarding')}>
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
};

export default Signin;
