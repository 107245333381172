import React, { useState } from 'react';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import { useTheme } from 'context/themeContext';
import useForm from 'hooks/useForm';
import { assignUser } from 'Api/CardManagement';
import { SFlexWrapper } from './UpdateStatus.styles';
import { SCustomModal } from '../../Cards.styles';

const UpdateStatus = ({ open, onClose, onSuccess, data }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');
      formData.append('card_status', values.status === 1 ? 'Active' : 'Inactive');
      await assignUser(formData);
      showToaster({ type: 'success', message: 'Card status has been successfully updated' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: {
      status: data.status.id || null,
    },
    onSubmit,
  });

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Update Status'
      $bgColor={palette.gray0}
      $maxWidth='440px'
      $minWidth='440px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update',
          disabled: values.status === data.status.id || loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <Typography variant='s2'>Card Status</Typography>
      <SFlexWrapper>
        <CustomRadioButton
          field={{
            name: 'status',
            value: 1,
            onChange: () => handleChange('status', 1),
            checked: values.status === 1,
          }}
        >
          <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
            Active
          </Typography>
        </CustomRadioButton>
        <CustomRadioButton
          field={{
            name: 'status',
            value: 2,
            onChange: () => handleChange('status', 2),
            checked: values.status === 2,
          }}
        >
          <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
            Inactive
          </Typography>
        </CustomRadioButton>
      </SFlexWrapper>
    </SCustomModal>
  );
};

export default UpdateStatus;
