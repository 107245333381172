import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import hexRgb from 'hex-rgb';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Speed } from 'assets/icons';
import { formatPhoneNumber } from 'utils/helpers';
import tsa from 'assets/icons/drivers/tsa.svg';
import twic from 'assets/icons/drivers/twic.svg';
import fast from 'assets/icons/drivers/fast.svg';
import maxico from 'assets/icons/drivers/mexico.png';
import useFlag from 'assets/icons/drivers/usaFlag.svg';
import turkeyFlag from 'assets/icons/drivers/turkeyFlag.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/createShipment/subRight.svg';
import Chip from 'common/Chip';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import Hos from 'components/TableShipments/helpers/Hos';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { palette, statusColor } from 'utils/constants';
import classes from 'components/TableShipments/tableShipments.module.css';
import { statues } from 'pages/Hos-Logbook/CustomCollapsibleRow/CustomCollapsibleRow.data';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  search: '',
  sort: { field: 'fname', sortBy: 'asc' },
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow:
        '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      backgroundColor: palette.white,
      color: palette.dark800,
    },
  })
);

const NavLinkWrapper = ({ deleteDefaultStyles = false, to, onClick, children, style, ...rest }) => {
  const defaultStyles = useMemo(() => {
    return {
      top: 0,
      left: 0,
      inset: 0,
      color: 'initial',
      position: 'absolute',
      background: 'initial',
      textDecoration: 'none',
      padding: '8px 8px 30px 8px',
    };
  }, []);
  return (
    <NavLink
      style={deleteDefaultStyles ? style : { ...defaultStyles, ...style }}
      to={to}
      onClick={(e) => {
        e.stopPropagation();
        // e.preventDefault();
        onClick?.(e);
      }}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

export const useColumns = ({ sortingQuery, ProfileAlerthandleClick, sort }) => {
  const { use } = useTheme();
  const { formatDateTime } = useDateFormat();

  return [
    {
      field: 'fname',
      title: <ColumnHeader title='NAME' field='fname' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <UserInfo
          size='44px'
          data={{
            ...rowData,
            id: rowData.id,
            name: `${rowData.fname} ${rowData.lname}`,
            image: rowData.image,
            status: rowData.status_title,
            user_type: 'driver',
          }}
          additionalInfo={
            <>
              <div className='user-country-and-status-container'>
                <div className='country-flag-icons align-items-center'>
                  {rowData?.certifications?.can_travel_usa === 1 && <img src={useFlag} alt='USA-Flag' />}
                  {rowData?.certifications?.can_travel_mexico === 1 && (
                    <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />
                  )}
                  {rowData?.certifications?.can_travel_canada === 1 && <img src={turkeyFlag} alt='canada-Flag' />}
                  <div>
                    {rowData?.certifications?.has_tsa === 1 && (
                      <img src={tsa} alt='tsa' className='approvedFor-tsa-icon' />
                    )}
                    {rowData?.certifications?.has_twic === 1 && (
                      <img src={twic} alt='twic' className='approvedFor-twic-icon' />
                    )}
                    {rowData?.certifications?.has_fast === 1 && (
                      <img src={fast} alt='fast' className='approvedFor-fast-icon' />
                    )}
                    {rowData?.certifications?.has_hazmat === 1 && (
                      <Typography variant='c1' style={{ color: palette.orange400 }}>
                        HAZMAT
                      </Typography>
                    )}
                    {rowData?.certifications?.custom_name && (
                      <div className='ms-2'>
                        <Typography variant='b2' style={{ color: palette.gray700 }}>
                          {rowData.certifications.custom_name.split(',').join(', ')}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='user-country-and-status-container'>
                {rowData?.expiring_driver_alerts ? (
                  <div className='ms-1 me-1' onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }}>
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      onClick={(e) => ProfileAlerthandleClick(e, rowData?.expiring_driver_alerts)}
                    >
                      <path
                        d='M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z'
                        fill='#C97A20'
                      />
                      <path
                        d='M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z'
                        fill='#C97A20'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z'
                        fill='#C97A20'
                      />
                    </svg>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          }
        />
      ),
    },
    {
      field: 'phone_number',
      title: <ColumnHeader title='PHONE NUMBER' field='phone_number' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          <div onClick={(e) => e.stopPropagation()}>
            <Typography variant='b2' className='list-driver-phone'>
              <a href={`tel:${rowData.phone_number}`} style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
                {rowData.phone_number ? formatPhoneNumber(rowData.phone_number) : '-'}
              </a>
            </Typography>
          </div>
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'driver_id',
      title: <ColumnHeader title='DRIVER ID' field='driver_id' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          <div onClick={(e) => e.stopPropagation()}>
            <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData?.driver_id || '-'}
            </p>
          </div>
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'drive_remaining',
      title: <ColumnHeader title='DRIVE REMAINING' field='drive_remaining' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          {rowData?.driver_connected_services_hos?.drive_remaining ? (
            <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData.driver_connected_services_hos.drive_remaining}
            </p>
          ) : (
            <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
              <span>
                <LightTooltip title='No HOS service connected.' placement='right'>
                  <span>{Speed}</span>
                </LightTooltip>
              </span>
            </div>
          )}
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'equipment',
      title: <Typography variant='c3'>EQUIPMENT</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        if (!rowData?.equipment?.[0]) {
          return (
            <>
              <span>-</span>
              <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
            </>
          );
        }

        const { equipment_id, equipment_type, hooked_to, id } = rowData?.equipment?.[0] || {};
        return (
          <>
            <div className={classes.equipmentColWrapper}>
              <div className={classes.equipmentVehicleWrapper}>
                <NavLinkWrapper to={`/equipment-profile/vehicle/${id}`} style={{ position: 'relative', zIndex: 1 }}>
                  <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {equipment_id} {equipment_type?.title}
                  </Typography>
                </NavLinkWrapper>
              </div>

              {!!hooked_to && (
                <div className='d-flex align-items-baseline'>
                  <TwoWaysIcon style={{ margin: '0 4px' }} />
                  <div className={classes.hookToWrapper}>
                    <NavLinkWrapper
                      to={`/equipment-profile/trailer/${hooked_to.id}`}
                      style={{ position: 'relative', zIndex: 1 }}
                    >
                      <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200), marginLeft: 5 }}>
                        {hooked_to?.equipment_id} {hooked_to?.length?.length}&nbsp;
                        {hooked_to?.length?.unit} {hooked_to?.equipment_type?.title}
                      </Typography>
                    </NavLinkWrapper>
                  </div>
                </div>
              )}
            </div>
            <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
          </>
        );
      },
    },
    {
      field: 'shift_remaining',
      title: <ColumnHeader title='SHIFT REMAINING' field='shift_remaining' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          {rowData?.driver_connected_services_hos?.shift_remaining ? (
            <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData.driver_connected_services_hos.shift_remaining}
            </p>
          ) : (
            <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
              <span>
                <LightTooltip title='No HOS service connected.' placement='right'>
                  <span>{Speed}</span>
                </LightTooltip>
              </span>
            </div>
          )}
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'time_until_break',
      title: <ColumnHeader title='TIME UNTIL BREAK' field='time_until_break' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          {rowData?.driver_connected_services_hos?.time_until_break ? (
            <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData.driver_connected_services_hos?.time_until_break}
            </p>
          ) : (
            <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
              <span>
                <LightTooltip title='No HOS service connected.' placement='right'>
                  <span>{Speed}</span>
                </LightTooltip>
              </span>
            </div>
          )}
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'cycle_remaining_duration',
      title: (
        <ColumnHeader title='CYCLE REMAINING' field='cycle_remaining_duration' onClick={sortingQuery} sort={sort} />
      ),
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          {rowData?.driver_connected_services_hos?.cycle_remaining_duration ? (
            <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData.driver_connected_services_hos.cycle_remaining_duration}
            </p>
          ) : (
            <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
              <span>
                <LightTooltip title='No HOS service connected.' placement='right'>
                  <span>{Speed}</span>
                </LightTooltip>
              </span>
            </div>
          )}
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'duty_status',
      title: <ColumnHeader title='DUTY STATUS' field='duty_status' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          {rowData?.driver_connected_services_hos?.duty_status ? (
            <Chip
              bgColor={statues[rowData.driver_connected_services_hos.duty_status]?.bgColor}
              labelColor={statues[rowData.driver_connected_services_hos.duty_status]?.color}
              label={statues[rowData.driver_connected_services_hos.duty_status]?.name}
              fontWeight={500}
              fontSize='11px'
              style={{ textTransform: 'uppercase' }}
            />
          ) : (
            <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
              <span>
                <LightTooltip title='No HOS service connected.' placement='right'>
                  <span>{Speed}</span>
                </LightTooltip>
              </span>
            </div>
          )}
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <div className='status-container-wrap'>
            <div
              className='warning-status-container'
              style={{
                backgroundColor: use(
                  statusColor[rowData?.status_title]?.bgColor,
                  statusColor[rowData?.status_title]?.darkBgColor
                ),
              }}
            >
              <p
                className='warning-status-text'
                style={{
                  color: use(statusColor[rowData?.status_title]?.color, statusColor[rowData?.status_title]?.darkColor),
                }}
              >
                {rowData?.status_title}
              </p>
            </div>
            <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
          </div>
        );
      },
    },
    {
      field: 'driver_next_available_location',
      title: (
        <ColumnHeader
          title='NEXT AVAILABLE'
          field='driver_next_available_location'
          onClick={sortingQuery}
          sort={sort}
        />
      ),
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            {(rowData?.status_title === 'Not Available' ||
              rowData?.status_title === 'On Shipment' ||
              rowData?.status_title === 'Available') &&
            rowData?.driver_next_available_location ? (
              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.driver_next_available_location}
              </p>
            ) : rowData?.location ? (
              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.location}
              </p>
            ) : (
              <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
                <span>
                  <LightTooltip title='Driver GPS data not available.' placement='right'>
                    <span>{Speed}</span>
                  </LightTooltip>
                </span>
              </div>
            )}
            {(rowData?.status_title === 'Not Available' ||
              rowData?.status_title === 'On Shipment' ||
              rowData?.status_title === 'Available') &&
            (rowData?.driver_not_available_until_date || rowData?.driver_not_available_until_time) ? (
              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                {formatDateTime(
                  `${rowData?.driver_not_available_until_date} ${rowData?.driver_not_available_until_time}`
                )}
              </p>
            ) : rowData?.location_date_time ? (
              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                {formatDateTime(`${rowData?.location_date_time}`)}
              </p>
            ) : null}
            <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
          </>
        );
      },
    },
    {
      field: 'driver_not_available_until_date',
      title: (
        <ColumnHeader
          title='NEXT AVAILABLE DATE/TIME'
          field='driver_not_available_until_date'
          onClick={sortingQuery}
          sort={sort}
        />
      ),
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            {(rowData?.status_title === 'Not Available' ||
              rowData?.status_title === 'On Shipment' ||
              rowData?.status_title === 'Available') &&
            (rowData?.driver_not_available_until_date || rowData?.driver_not_available_until_time) ? (
              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                {formatDateTime(
                  `${rowData?.driver_not_available_until_date} ${rowData?.driver_not_available_until_time}`
                )}
              </p>
            ) : rowData?.location_date_time ? (
              <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                {formatDateTime(`${rowData?.location_date_time}`)}
              </p>
            ) : (
              <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
                <span>
                  <LightTooltip title='Driver GPS data not available.' placement='right'>
                    <span>{Speed}</span>
                  </LightTooltip>
                </span>
              </div>
            )}
            <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
          </>
        );
      },
    },
    {
      field: 'driver_type',
      title: <ColumnHeader title='TYPE' field='driver_type' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData?.driver_type?.driver_type === 'Company'
                ? 'Company'
                : rowData?.driver_type?.driver_type === 'Sub-Contractor'
                ? 'Owner'
                : ''}
            </span>
            {rowData?.driver_type?.driver_type === 'Company' ? (
              <p style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.driver_type?.driver_type_company}
              </p>
            ) : rowData?.driver_type?.driver_type === 'Sub-Contractor' ? (
              <p style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.app?.operator?.owner_operator_name}
              </p>
            ) : null}
            <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
          </>
        );
      },
    },
    {
      field: 'license_type',
      title: <ColumnHeader title='LICENSE' field='license_type' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => (
        <>
          <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData.license_type}</span>
          <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
        </>
      ),
    },
    {
      field: 'groups',
      title: <Typography variant='c3'>TAGS</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const groups = rowData?.driver_group;
        return (
          <>
            <div className={`flex-row d-flex table_planer_groups_wrapper ${rowData.id ? 'rowData_wrapper' : ''}`}>
              {groups?.length ? (
                [...(groups || [])].splice(0, 1)?.map((group) => {
                  const colorCode = hexRgb(group.color);
                  const TextColor =
                    colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';
                  return (
                    <div className='user-data-group' key={group.color} style={{ backgroundColor: group?.color }}>
                      <p className='group-text' style={{ color: TextColor }}>
                        {group?.short_name}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div
                  className='user-data-group'
                  style={{ backgroundColor: groups?.color ? groups?.color : 'transparent' }}
                >
                  <p className='group-text'>{groups?.short_name}</p>
                </div>
              )}
            </div>
            <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
          </>
        );
      },
    },
    {
      field: 'driver_compensation',
      title: <ColumnHeader title='COMPENSATION' field='driver_compensation' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const isPerMile = rowData?.driver_compensation?.includes('Loaded');

        const index = rowData?.driver_compensation?.indexOf('Loaded ');
        const loadedMiles = rowData?.driver_compensation?.substring(0, index + 7);
        const emptyMiles = rowData?.driver_compensation?.substring(index + 7);

        return (
          <div className='compensation-and-dropdown'>
            {rowData?.driver_compensation && (
              <div className='compensation-container'>
                <span style={{ color: use(palette.dark800, palette.gray200) }}>
                  {!isPerMile ? rowData?.driver_compensation : loadedMiles}
                </span>
                {!!isPerMile && <span style={{ color: use(palette.dark800, palette.gray200) }}>{emptyMiles}</span>}
              </div>
            )}
            <NavLinkWrapper state={{ from: 'drivers' }} to={`/driver-profile/${rowData.id}/general`} />
          </div>
        );
      },
    },
    {
      field: 'hour_of_service',
      title: <ColumnHeader title='HOURS OF SERVICE' field='hour_of_service' />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return rowData.driver_connected_services_hos ? (
          <div style={{ width: 250 }}>
            <Hos driver={rowData} />
          </div>
        ) : (
          <div className='mb-2' style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
            <span>
              <LightTooltip title='Driver HOS data not available.' placement='right'>
                <span>{Speed}</span>
              </LightTooltip>
            </span>
          </div>
        );
      },
    },
  ];
};
