import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'common/Pagination';
import TableWrapper from 'components/TableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getSettlements } from 'Api/Payroll';
import { useSettlementsColumns } from '../Tables.data';

const initialFilters = {
  page: 1,
  page_size: 25,
};

const ReadySettlements = ({ search }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [settlements, setSettlements] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  const getSettlementsList = async () => {
    setLoading(true);
    try {
      const { page, page_size } = selectedFilters || {};
      const params = {
        page,
        page_size,
        query: search || undefined,
        status_id: 1,
        'sort[pay_period_start]': 'desc',
      };
      const response = await getSettlements(params);
      setSettlements(response);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getSettlementsList();
  }, [selectedFilters, sort, search]);

  const columns = useSettlementsColumns({ sort, sortingQuery });

  return (
    <div>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <div className='tableFixHead table-fixed-header-300'>
          <TableWrapper
            data={settlements?.data}
            rowPerPage={selectedFilters.page_size}
            onRowClick={(e, row) =>
              navigate(
                `/settlement/${row.user_type?.includes('Driver') ? 2 : 1}/${row.user_id}/${row.id}?pay_period_start=${
                  row.pay_period_start
                }&pay_period_end=${row.pay_period_end}`
              )
            }
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: () =>
                Pagination({
                  data: settlements,
                  rowPerPage: selectedFilters.page_size,
                  onChangeRowPerPage,
                  onPageChange,
                }),
            }}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

export default ReadySettlements;
