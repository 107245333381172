import React, { useState } from 'react';
import HeaderStar from 'components/HeaderStar';
import Settlements from './Settlements';
import SettlementDetails from './SettlementDetails';
import { SHeaderWrapper, SPageWrapper } from './MyPay.styles';

const MyPay = () => {
  const [activeSettlement, setActiveSettlement] = useState(null);

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='My Pay' />
      </SHeaderWrapper>
      <div className='d-flex'>
        <Settlements activeSettlement={activeSettlement} setActiveSettlement={setActiveSettlement} />
        {!!activeSettlement && <SettlementDetails settlement={activeSettlement} />}
      </div>
    </SPageWrapper>
  );
};

export default MyPay;
