import React, { useState, useEffect, useMemo, useContext } from 'react';
import { ErrorMessage, FastField, Field, useField } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getAssignToGroupBillingCharges, getBillToBillingCharges, getCustomerPaymentTerm } from 'Api/Planner';
import { getCustomerPayTerms } from 'Api/Customers';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';
import sm from './BillingCharges.module.css';

import CustomInput from '../helpers/CustomInput';
import { Typography } from '../../Typography';
import CustomSelect from '../ShipmentStops/helpers/CustomSelect';
import { ShipmentContext } from '../Layout';
import { BillingChargesContext } from './index';

import CustomizedSnackbars from '../../toast/Toast';

const BillingChargesHeader = () => {
  const { use } = useTheme();
  const [disabled, setDisabled] = useState(false);
  const helpersContact = useField('contact_user_id')[2];
  const helpersGroup = useField('group')[2];
  const helpersBillTo = useField('customer_id')[2];
  const helpersPayTerm = useField('payment_term_id')[2];
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState([]);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'error',
  });

  const {
    billToData,
    updateBillToData,
    selectedBillTo,
    updateBillToSelected,
    contactData,
    selectedContact,
    updateContactSelected,
    assignToGroupAllData,
    updateAssignToGroup,
    onChangeContactEditModal,
    getContacts,
    paymentTermData,
    updatePaymentTermData,
  } = useContext(ShipmentContext);
  const contactOption = contactData?.[0];
  const { values, errors, touched, setFieldValue } = useContext(BillingChargesContext);

  const activeItemGroups = useMemo(() => {
    if (values?.group) {
      return assignToGroupAllData.find((el) => +el.id === +values.group);
    }
  }, [assignToGroupAllData, values]);

  const validateRequired = (value) => {
    let error;
    if (value !== undefined && value === '') {
      error = 'Required';
    }
    return error;
  };

  const getBillTo = () => {
    return getBillToBillingCharges().then((res) => {
      if (res && res?.data) {
        const newData = res.data.map((item) => {
          return {
            ...item,
            label: item.company_name,
            key: item.id,
            labelSelected: null,
          };
        });
        updateBillToData(newData);
        return newData;
      }
    });
  };

  const getAssignToGroup = () => {
    return getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          const newData = res.data.map((item) => {
            return {
              ...item,
              label: item?.group_name,
              key: item.id,
              labelSelected: null,
            };
          });
          updateAssignToGroup(newData);
          return newData;
        }
        return res;
      })
      .catch(() => {
        // Do nothing
      });
  };

  function onChangeBillTo(selectedItem) {
    const customer_id = selectedItem?.id;
    const cond = [2, 3, 4].includes(Number(selectedItem?.status?.id));
    if (!cond) {
      helpersBillTo.setValue(customer_id);
      getCustomerPaymentTerm({ customer_id }).then((res) => {
        const cloneTermData = { ...paymentTermData };
        cloneTermData[0] = res?.data;
        updatePaymentTermData(cloneTermData);
      });

      if (selectedItem?.customer_payment_term?.pay_term_types?.id) {
        helpersPayTerm.setValue(selectedItem.customer_payment_term.pay_term_types.id);
      }

      setShowMessage((prev) => {
        return {
          ...prev,
          message: '',
          visible: false,
        };
      });
    } else {
      setShowMessage((prev) => {
        return {
          ...prev,
          message: `Cannot proceed with ${selectedItem?.company_name} due to ${selectedItem?.status?.title}.`,
          visible: true,
        };
      });
    }
    return cond;
  }

  const onAddCustomerSuccess = (customer) => {
    getBillTo();
    onChangeBillTo({ ...customer, status: { id: customer.status_id } });
  };

  const billingStyles = useMemo(() => {
    return {
      headerLeft: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
      global: {
        width: 'calc(20% - 40px)',
      },
      text: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
      },
      group: {
        maxWidth: '100%',
        wordBreak: 'break-word',
        margin: 10,
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
    };
  }, [use, palette]);

  const updateBillTo = (billTo) => {
    const selected = { ...selectedBillTo };
    selected[0] = billTo;
    updateBillToSelected(selected);
  };

  useEffect(() => {
    if (values.customer_id) {
      setDisabled(false);
      const billTo = billToData.find((item) => +item.id === +values.customer_id);
      updateBillTo(billTo);
      getCustomerPayTerms().then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: getPaymentTermTitle(el),
              labelSelected: null,
            };
          });
          setInvoicePayTermsOptions(selectData);
        }
      });
      if (billTo?.id) {
        getContacts(billTo.id, 0).then((data) => {
          const founded = data?.find((item) => +item.id === +values.contact_user_id);
          const primary = data?.find((item) => +item.contact_type_id === 1);
          !founded && helpersContact.setValue('');
          if (primary) {
            helpersContact.setValue(primary.id);
          }
        });

        if (billTo?.customer_payment_term?.pay_term_types?.id) {
          helpersPayTerm.setValue(billTo.customer_payment_term.pay_term_types.id);
        }
      }
    } else {
      setDisabled(true);
    }
  }, [values.customer_id, billToData]);

  useEffect(() => {
    const contact = contactOption?.find((item) => +item.id === +values.contact_user_id);
    if (contactOption?.length > 0) {
      updateContactSelected({ 0: contact });
    } else {
      const newContactData = { ...selectedContact };
      delete newContactData[0];
      updateContactSelected(newContactData);
    }
  }, [values?.contact_user_id, contactOption]);

  useEffect(() => {
    getBillTo().then();
    getAssignToGroup().then();

    if (values?.customer_id) {
      getCustomerPaymentTerm({ customer_id: values?.customer_id }).then((res) => {
        const cloneTermData = { ...paymentTermData };
        cloneTermData[0] = res?.data;
        updatePaymentTermData(cloneTermData);
      });
    }
  }, []);

  return (
    <div className={sm.billing_charges_header}>
      <div className={sm.header_titles_wrapper}>
        <div className={sm.titles}>
          <div className={sm.header_title_left}>
            <Typography
              variant='overLine'
              style={billingStyles.global}
              className='d-flex align-items-center justify-content-between'
            >
              BILL TO <EditIcon className='pointer' onClick={() => setOpenAddCustomer(true)} />
            </Typography>
            <Typography variant='overLine' style={billingStyles.global}>
              REFERENCE ID
            </Typography>
            <Typography variant='overLine' style={billingStyles.global}>
              ASSIGN TAG
            </Typography>
            <div className={[sm.edit_wrapper].join(' ')} style={billingStyles.global}>
              <Typography variant='overLine'>CONTACT</Typography>
              {!!values.customer_id && (
                <div
                  onClick={() => {
                    Object.keys(selectedBillTo[0]).length > 0 && onChangeContactEditModal(true);
                  }}
                >
                  <EditIcon style={{ cursor: 'pointer' }} fill={use(palette.gray500, palette.gray200)} />
                </div>
              )}
            </div>
            <Typography style={{ width: 'calc(20% - 40px)' }} variant='overLine'>
              PAYMENT TERM
            </Typography>
          </div>
        </div>
        <div className={sm.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
      </div>
      <div className={sm.header_wrapper}>
        <div style={billingStyles.headerLeft}>
          <div style={billingStyles.global}>
            <Field
              name='customer_id'
              isSearchable
              options={billToData}
              component={CustomSelect}
              onChange={onChangeBillTo}
              allControlOutSideForm
              validate={validateRequired}
              styles={{ height: 32 }}
            />
            <ErrorMessage
              name='customer_id'
              render={(error) => {
                return (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                );
              }}
            />
            {!!values?.customer_id && (
              <div className={sm.header_titles_wrapper}>
                <Typography variant='s2' style={billingStyles.text}>
                  {selectedBillTo[0]?.address1 ? `${selectedBillTo[0]?.address1},` : ''}&nbsp;
                  {selectedBillTo[0]?.address2}
                </Typography>
                <Typography variant='s2' style={billingStyles.text}>
                  {selectedBillTo[0]?.city?.name ? `${selectedBillTo[0]?.city?.name},` : ''}&nbsp;
                  {selectedBillTo[0]?.state?.name}&nbsp;
                  {selectedBillTo[0]?.zipcode}&nbsp;
                </Typography>
                <Typography variant='s2' style={billingStyles.text}>
                  {selectedBillTo[0]?.country?.name}
                </Typography>
              </div>
            )}
          </div>
          <div style={billingStyles.global}>
            <Field
              name='reference_id'
              component={CustomInput}
              labelStyle={{ margin: 0 }}
              style={{ width: '100%', borderRadius: 6, height: 32, padding: '0 6px' }}
            />
            {!!errors?.reference_id && touched?.reference_id && (
              <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                {errors.reference_id}
              </Typography>
            )}
          </div>
          <div style={billingStyles.global}>
            <Field
              isSearchable
              name='group'
              styles={{ height: 32 }}
              component={CustomSelect}
              dropDownStyles={{ flexGrow: 0 }}
              options={assignToGroupAllData}
              deleted
              onDelete={() => helpersGroup.setValue('')}
            />

            {activeItemGroups?.short_name && (
              <div className={sm.group_wrapper} style={{ backgroundColor: activeItemGroups?.color }}>
                <Typography variant='c2' style={billingStyles.group}>
                  {activeItemGroups.short_name}
                </Typography>
              </div>
            )}
          </div>
          <div style={billingStyles.global}>
            <Field
              isSearchable
              deleted
              onDelete={() => helpersContact.setValue('')}
              name='contact_user_id'
              disabled={disabled}
              options={contactOption}
              styles={{ height: 32 }}
              component={CustomSelect}
            />

            {!!values?.contact_user_id && selectedContact[0] && (
              <div className={sm.header_titles_wrapper}>
                {!!selectedContact[0]?.phone_number && (
                  <Typography variant='s2' style={billingStyles.text}>
                    <ContactIcon style={{ marginRight: 8 }} />
                    {selectedContact[0]?.phone_number}
                  </Typography>
                )}
                {!!selectedContact[0]?.contact_email && (
                  <Typography variant='s2' style={billingStyles.text}>
                    <MailIcon style={{ marginRight: 8 }} />
                    {selectedContact[0]?.contact_email}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <div style={billingStyles.global}>
            <FastField
              name='payment_term_id'
              options={invoicePayTermsOptions}
              styles={{ height: 32 }}
              component={CustomSelect}
              shouldUpdate={() => true}
            />
          </div>
        </div>
      </div>
      {openAddCustomer && (
        <AddPayee
          open={!!openAddCustomer}
          onClose={() => setOpenAddCustomer(false)}
          onSuccess={onAddCustomerSuccess}
          type='customer'
        />
      )}
      {showMessage?.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </div>
  );
};

export default BillingChargesHeader;
