import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { palette } from 'utils/constants';
import down from 'assets/icons/drivers/down.svg';
import subRight from 'assets/icons/vehicles/sub-right.svg';
import Autocomplete from 'common/Autocomplete';
import { useTheme } from 'context/themeContext';
import { dispatchFromOptions } from 'components/DispatchVehicle/helpers';
import { getShipmentDispatchSettings } from 'Api/CompanySettings';
import { getCostCalculation, getPlannedShipments, getPreviousShipments } from 'Api/Planner';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Typography } from '../Typography';
import AddressAutocomplete from '../../common/AddressAutocomplete';
import useShowToaster from '../../hooks/useShowToaster';

const LocationSelect = ({ rowData, mapValues, setOneEquipmentData }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [value, setValue] = useState('Current Location');
  const [previousShipments, setPreviousShipments] = useState([]);
  const [previousShipment, setPreviousShipment] = useState(null);
  const [customAddress, setCustomAddress] = useState('');
  const [customLatLong, setCustomLatLong] = useState(null);
  const [planedShipments, setPlanedShipments] = useState([]);
  const [planedShipment, setPlanedShipment] = useState(null);
  const [searchParams] = useSearchParams();
  const shipmentId = searchParams.get('id');

  const getDispatchSettings = async () => {
    try {
      const { data } = await getShipmentDispatchSettings();
      if (data.driver_from_location) {
        handleLocationSelect(dispatchFromOptions[Number(data?.driver_from_location)]);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const handleLocationSelect = (location) => {
    setCustomAddress('');
    setCustomLatLong(null);
    setPreviousShipment(null);
    setPlanedShipment(null);
    setValue(location);
  };

  const changeAddress = ({ formatted_address }) => {
    setCustomAddress(formatted_address);
  };

  useEffect(() => {
    const assign_driver_from = value;
    const params = {
      shipment_id: shipmentId,
      assign_driver_from:
        value === 'Previous Shipment' ? 2 : value === 'Planned Shipment' ? 3 : value === 'Custom Location' ? 4 : 1,
      route_type: mapValues.route_type,
      hwy_only: mapValues.hwy_only ? 1 : 2,
      open_borders: mapValues.open_borders ? 1 : 2,
      avoid_tolls: mapValues.avoid_tolls ? 1 : 2,
      assign_driver_from_shipment:
        assign_driver_from === 'Previous Shipment' && previousShipment?.shipment_id
          ? previousShipment?.shipment_id
          : assign_driver_from === 'Planned Shipment' && planedShipment?.shipment_id
          ? planedShipment?.shipment_id
          : undefined,
      assign_driver_from_shipment_latitude:
        assign_driver_from === 'Custom Location' && customLatLong ? customLatLong?.latitude : undefined,
      assign_driver_from_shipment_longitude:
        assign_driver_from === 'Custom Location' && customLatLong ? customLatLong?.longitude : undefined,
    };
    params[`equipments[0]`] = rowData.equipment_id;
    getCostCalculation(params)
      .then((res) => {
        if (res.data && res.data[0]) {
          const newDriverObj = { ...res.data[0] };
          if (planedShipment || previousShipment) {
            if (newDriverObj?.equipment?.location_target === 'Driver') {
              newDriverObj.equipment.drivers[0].driver_gps.longitude = (
                previousShipment || planedShipment
              )?.shipment_stops?.[(previousShipment || planedShipment).shipment_stops.length - 1].stop_point.longitude;
              newDriverObj.equipment.drivers[0].driver_gps.latitude = (
                previousShipment || planedShipment
              )?.shipment_stops?.[(previousShipment || planedShipment).shipment_stops.length - 1].stop_point.latitude;
            } else {
              newDriverObj.equipment.equipment_gps.longitude = (previousShipment || planedShipment)?.shipment_stops?.[
                (previousShipment || planedShipment).shipment_stops.length - 1
              ].stop_point.longitude;
              newDriverObj.equipment.equipment_gps.latitude = (previousShipment || planedShipment)?.shipment_stops?.[
                (previousShipment || planedShipment).shipment_stops.length - 1
              ].stop_point.latitude;
            }
          }
          if (customLatLong) {
            if (newDriverObj?.equipment?.location_target === 'Driver') {
              newDriverObj.equipment.drivers[0].driver_gps.longitude = customLatLong.longitude;
              newDriverObj.equipment.drivers[0].driver_gps.latitude = customLatLong.latitude;
            } else {
              newDriverObj.equipment.equipment_gps.longitude = customLatLong.longitude;
              newDriverObj.equipment.equipment_gps.latitude = customLatLong.latitude;
            }
          }
          setOneEquipmentData(newDriverObj, previousShipment, planedShipment, customLatLong);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [previousShipment, planedShipment, customLatLong]);

  const onAddressSelect = async (data) => {
    const res = await getGeoLocation(data);
    if (res.latitude && res.longitude) {
      setCustomLatLong(res);
    }
  };

  const getGeoLocation = async (value) => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          addr: value.address,
          city: value.city?.name,
          state: value.state.name,
          zip: value.zip,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                latitude: el.Coords?.Lat,
                longitude: el.Coords?.Lon,
              };
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          showToaster({ type: 'error', message: 'Could not find location' });
          resolve(null);
        },
      });
    });
  };

  useEffect(() => {
    if (rowData?.equipment?.drivers[0]?.id) {
      const params = {
        driver_id: rowData?.equipment?.drivers[0]?.id,
      };
      if (value === 'Planned Shipment') {
        getPlannedShipments(params).then((res) => {
          setPlanedShipments(res.data);
        });
      }
      if (value === 'Previous Shipment') {
        getPreviousShipments(params).then((res) => {
          setPreviousShipments(res.data);
          setPreviousShipment(res.data?.[0] || null);
        });
      }
    }
  }, [value]);

  useEffect(() => {
    getDispatchSettings();
  }, []);

  return (
    <div style={{ minHeight: 120, minWidth: 280 }}>
      <div className='d-flex align-items-center'>
        <Dropdown>
          <Dropdown.Toggle
            // className="AvailableButton btn btn-link"
            id='dropdown-autoclose-outside'
            style={{
              border: 'none',
              outline: 'none',
              background: 'none',
              boxShadow: 'none',
              marginLeft: '0',
              paddingLeft: '0',
              height: 40,
            }}
          >
            <div className='d-flex align-items-center'>
              <p
                className='table-data-text'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  fontSize: 14,
                }}
              >
                {value}
              </p>
              <img src={down} alt='down' className='ms-2' />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-location' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <Dropdown.Item
              className='outofserviceDropDownItemsList'
              style={{ color: use(palette.dark800, palette.gray200) }}
              onClick={() => handleLocationSelect('Current Location')}
            >
              Current Location
            </Dropdown.Item>
            <Dropdown.Item
              className='outofserviceDropDownItemsList'
              onClick={() => handleLocationSelect('Previous Shipment')}
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              Previous Shipment
            </Dropdown.Item>
            <Dropdown.Item
              className='outofserviceDropDownItemsList'
              onClick={() => handleLocationSelect('Planned Shipment')}
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              Planned Shipment
            </Dropdown.Item>
            <Dropdown.Item
              className='outofserviceDropDownItemsList'
              onClick={() => handleLocationSelect('Custom Location')}
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              Custom Location
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {value === 'Previous Shipment' && (
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <img src={subRight} className='sub-right-icon' alt={subRight} style={{ display: 'inline-block' }} />
            <Autocomplete
              options={previousShipments}
              value={previousShipment}
              onChange={(e, value) => setPreviousShipment(value)}
              getOptionLabel={(option) =>
                `Shipment ${option.shipment_id} (${
                  option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                }, ${option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state?.short_name})`
              }
              id='select_list'
              name='list'
            />
          </div>
          <div className='mt-2'>
            {previousShipment?.shipment_stops?.length && (
              <>
                <p className='dispatch-details-text'>
                  {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]?.stop_point?.address1
                    ? `${
                        previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1].stop_point.address1
                      },`
                    : ''}
                  {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]?.stop_point?.address2
                    ? `${
                        previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1].stop_point.address2
                      },`
                    : ''}
                </p>
                <p className='dispatch-details-text'>
                  {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]?.stop_point?.state?.name
                    ? `${
                        previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1].stop_point.state
                          .name
                      },`
                    : ''}
                  {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]?.stop_point?.city?.name
                    ? `${
                        previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1].stop_point.city.name
                      },`
                    : ''}
                  {previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1]?.stop_point?.zipcode
                    ? previousShipment.shipment_stops[previousShipment.shipment_stops.length - 1].stop_point.zipcode
                    : ''}
                </p>
              </>
            )}
          </div>
        </div>
      )}
      {value === 'Planned Shipment' && (
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <img src={subRight} className='sub-right-icon' alt={subRight} style={{ display: 'inline-block' }} />
            <Autocomplete
              options={planedShipments}
              value={planedShipment}
              onChange={(e, value) => setPlanedShipment(value)}
              getOptionLabel={(option) =>
                `Shipment ${option.shipment_id} (${
                  option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                }, ${option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state?.short_name})`
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant='c1'>
                    {`Shipment ${option.shipment_id} (${
                      option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                    }, ${option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state?.short_name})`}
                  </Typography>
                </li>
              )}
              id='select_list'
              name='list'
            />
          </div>
          <div className='mt-2'>
            {planedShipment?.shipment_stops?.length && (
              <>
                <p className='dispatch-details-text'>
                  {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point?.address1
                    ? `${planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1].stop_point.address1},`
                    : ''}
                  {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point?.address2
                    ? `${planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1].stop_point.address2},`
                    : ''}
                </p>
                <p className='dispatch-details-text'>
                  {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point?.state?.name
                    ? `${
                        planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1].stop_point.state.name
                      },`
                    : ''}
                  {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point?.city?.name
                    ? `${planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1].stop_point.city.name},`
                    : ''}
                  {planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1]?.stop_point?.zipcode
                    ? planedShipment.shipment_stops[planedShipment.shipment_stops.length - 1].stop_point.zipcode
                    : ''}
                </p>
              </>
            )}
          </div>
        </div>
      )}

      {value === 'Current Location' && (
        <span className='dispatch-details-text'>
          {rowData?.equipment?.location_target === 'Equipment'
            ? `${rowData.equipment?.equipment_gps?.location}`
            : `${rowData?.equipment?.drivers[0]?.driver_gps?.location}`}
        </span>
      )}

      {value === 'Custom Location' && (
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <img src={subRight} className='sub-right-icon' alt={subRight} style={{ display: 'inline-block' }} />
            <AddressAutocomplete
              placeholder='Search Custom Location'
              onSuccess={(data) => onAddressSelect(data)}
              changeAddress={(data) => changeAddress(data)}
              onChange={(e) => {
                setCustomAddress(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                }
              }}
              value={customAddress}
              options={{
                types: ['geocode', 'establishment'],
                componentRestrictions: { country: ['us', 'ca', 'mx'] },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationSelect;
