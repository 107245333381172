import { useNavigate } from 'react-router-dom';
import { palette, STOP_POINT_TAB } from 'utils/constants';
import { onChangeInventoryTab } from 'store/reducers/stopPoint.reducer';
import { useDispatch } from 'react-redux';

export const statusOptions = [
  { value: 'IP', name: 'In Progress' },
  { value: 'RS', name: 'Resolved' },
];

export const alertStatus = {
  New: { label: 'New', color: palette.indigo500, bgColor: palette.indigo50 },
  'In Progress': { label: 'In Progress', color: palette.goldDark, bgColor: palette.goldLight },
  Resolved: { label: 'Resolved', color: palette.green500, bgColor: palette.green0 },
};

export const ALERT_TYPE = {
  NEW_INVOICES_READY: 'New Invoices Ready',
  NEW_DOCUMENTS_ARRIVED: 'New Documents Arrived',
  DECLINED_PAYMENT: 'Declined Payment',
  DRIVER_LICENSE_EXPIRATION: 'Driver License Expiration',
  CRIMINAL_CHECK_DUE: 'Criminal Check Due',
  MVR_CHECK_DUE: 'MVR Check Due',
  FMCSA_CLEARING_HOUSE_CHECK_DUE: 'FMCSA Clearing House Check Due',
  DRUG_TESTING_CHECK_DUE: 'Drug Testing Check Due',
  DRIVER_OUT_OF_SERVICE: 'Driver Out of Service',
  CUSTOM_CERTIFICATION_EXPIRATION: 'Custom Certification Expiration',
  DRIVER_TIME_OFF_REQUEST: 'Driver Time Off Request',
  DRIVER_PAY_RAISE_INITIATED: 'Driver Pay Raise Initiated',
  DRIVER_DID_NOT_TAKE_BREAK: 'Driver Did Not Take Break',
  DRIVER_INCIDENT: 'Driver Incident',
  DRIVER_PREVIOUS_EMPLOYMENT_VERIFICATION: 'Previous Employer Verification',
  DRIVER_NOT_MOVING: 'Driver Not Moving',
  DRIVER_CLOCKED_OUT_INACTIVITY: 'Driver Clocked-Out Due to Inactivity',
  DRIVER_COMPENSATION_POLICY: 'Driver Compensation Policy',
  DRIVER_DOCUMENT: 'Driver Document',
  MEDICAL_EXAMINERS_EXPIRATION: 'Medical Examiners Expiration',
  INCORRECT_PASSWORD_OR_ATTEMPT: 'Incorrect Password or Attempt',
  UNAUTHORIZED_SHIFT_ATTEMPT: 'Unauthorized Shift Attempt',
  UNAUTHORIZED_IP_ATTEMPT: 'Unauthorized IP Attempt',
  ROADSIDE_INSPECTION: 'Roadside Inspection',
  ROADSIDE_VIOLATION: 'Roadside Violation',
  CRASH_RECORD: 'Crash Record',
  MCS_150_BIENNIAL_UPDATE: 'MCS 150 Biennial Update',
  UCR_ANNUAL_UPDATE: 'UCR Annual Update',
  URS_BIENNIAL_UPDATE: 'URS Biennial Update',
  IFTA_LICENSE: 'IFTA License',
  MAINTENANCE_SCHEDULED: 'Maintenance Scheduled',
  MAINTENANCE_OVERDUE: 'Maintenance Overdue',
  EQUIPMENT_REGISTRATION_EXPIRING: 'Equipment Registration Expiring',
  GENERAL_LIABILITY_INSURANCE: 'General Liability Insurance',
  AUTOMOBILE_LIABILITY: 'Automobile Liability',
  MOTOR_CARGO_INSURANCE: 'Motor Cargo Insurance',
  CUSTOM_EXPIRY: 'Custom Expiry',
  DRIVER_CUSTOM_DOCUMENT_EXPIRATION: 'Custom Document Expiry',
  VEHICLE_UNAUTHORIZED_MOVE: 'Unauthorized Move - Vehicle',
  TRAILER_UNAUTHORIZED_MOVE: 'Unauthorized Move - Trailer',
  DROPPED_TRAILER: 'Dropped Trailer',
  EQUIPMENT_AT_LOCATION_DURATION: 'Equipment at Location Duration',
  VEHICLE_REACH_NUMBER_OF_STOPS: 'Stop Point Alerts Vehicle Inventory',
  TRAILER_REACH_NUMBER_OF_STOPS: 'Stop Point Alerts Trailer Inventory',
  DOT_INSPECTION: 'DOT Inspection',
  IFTA: 'IFTA',
  HIGHWAY_TAX: 'Highway Tax',
  PHYSICAL_INSURANCE: 'Physical Insurance',
  STAFF_PREVIOUS_EMPLOYMENT_VERIFICATION: 'Previous Employer Verification',
  STAFF_CUSTOM_DOCUMENT_EXPIRATION: 'Custom Document Expiration',
  STAFF_COMPENSATION_POLICY: 'Staff Compensation Policy',
  PHOTO_IDENTIFICATION_EXPIRATION: 'Photo Identification Expiration',
  DRIVER_DOCUMENT_RECEIVED: 'Driver Document Received',
  HOURS_FROM_ORIGIN_PICK_UP: '4 Hours from Origin Pick Up',
  DRIVER_RECEIVED_SHIPMENT_OFFER: 'Driver Received Shipment Offer',
  DRIVER_ACCEPTED_SHIPMENT_OFFER: 'Driver Accepted Shipment Offer',
  DRIVER_DECLINED_SHIPMENT_OFFER: 'Driver Declined Shipment Offer',
  NEW_EDI_API_SHIPMENT: 'New EDI/API Shipment',
  EXPIRED_EDI_API_SHIPMENT: 'Expired EDI/API Shipment',
  DRIVER_DISPATCHED: 'Driver Dispatched',
  DRIVER_CONFIRMED: 'Driver Confirmed',
  DELAYED_SHIPMENT: 'Delayed Shipment',
  DRIVER_WAITING_FOR_DOC: 'Driver waiting for Dock',
  DRIVER_AT_DOC: 'Driver at Dock',
  DETENTION_WARNING: 'Detention Warning',
  SHIPMENT_COMPETED: 'Shipment Completed',
  SHIPMENT_ARRIVED: 'Shipment Arrived',
  SHIPMENT_DEPARTED_ORIGIN: 'Shipment Departed Origin',
  DRIVER_SENT_DOCUMENT: 'Driver Sent a Document',
  JOB_APPLICATION_CREATED: 'New Job Application Created',
  PAYROLL_DISPUTE_OCCURS: 'Payroll Dispute Occurs',
  FUEL_FRAUD_DETECTED: 'Possible Fuel Fraud Detected',
  ACCOUNT_LOW_BALANCE_THRESHOLD: 'Account Low Balance Threshold',
  TRANSACTION_OVER_AMOUNT: 'Transaction over set amount',
  CARD_HOLDER_BALANCE_THRESHOLD: 'Card Holder Balance Threshold',
  INVENTORY_REACHED_MAXIMUM_LIMIT: 'Inventory Reached Maximum Limit',
  AGING_IS_OVER: 'Aging is Over',
  RATING_IS_LOWER: 'Rating is Lower',
  OVER_MAXIMUM_CREDIT_LINE: 'Over Maximum Credit Line',
  DOCUMENT_EXPIRY: 'Document Expiry',
  CARRIER_NOT_AUTHORIZED: 'Not Authorized',
  DRIVER_INSPECTION_OVER_AVERAGE: 'Driver Inspection Over National Average',
  VEHICLE_INSPECTION_OVER_AVERAGE: 'Vehicle Inspection Over National Average',
  CARRIER_DOCUMENT_EXPIRED: 'Carrier Document Expired',
  CARRIER_OPERATING_NOT_AUTHORIZED: 'Carrier Operating Status - Not Authorized',
  CARRIER_VIOLATION_NOTICE: 'Carrier Violation Notice',
  CARRIER_CRASH_NOTICE: 'Carrier Crash Notice',
  CARRIER_INSPECTION_NOTICE: 'Carrier Inspection Notice',
  OWNER_OPERATOR_DOCUMENT: 'Owner Operator Document',
  POSSIBLE_FRAUD_DETECTED: 'Possible Fraud Detection',
};

export const useGetStatusUpdateButtons = ({ alert, onClose, updateStatus, loading, setDocument }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToInventory = (stopId, equipmentType) => {
    dispatch(onChangeInventoryTab(equipmentType));
    navigate(`/stop-point/${stopId}/${STOP_POINT_TAB.INVENTORY}`);
  };

  return [
    { key: 'close', type: 'secondary', title: 'Close' },
    ...(alert.request_data && alert.driver_details
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/driver-profile/${alert.driver_details.id}/requests`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.STAFF_PREVIOUS_EMPLOYMENT_VERIFICATION && alert.staff_details
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              if (alert.staff_details?.id) {
                navigate(`/staff-profile/${alert.staff_details.id}/previous-employment`);
                onClose();
              }
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.STAFF_PREVIOUS_EMPLOYMENT_VERIFICATION &&
    alert.alert_type === 'Applicant' &&
    alert.user_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/recruiting-applicants?applicantToViewId=${alert.user_id}`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.UNAUTHORIZED_IP_ATTEMPT && alert.for_user_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/staff-profile/${alert.for_user_id}/restriction-schedule`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.INCORRECT_PASSWORD_OR_ATTEMPT && alert.for_user_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate('/company-settings', { state: { tabId: 4, subTabName: 'Security' } });
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.TRANSACTION_OVER_AMOUNT &&
    (alert.driver_details || alert.staff_details)
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              if (alert.staff_details?.id) {
                navigate(`/staff-profile/${alert.staff_details.id}/transactions`);
              } else {
                navigate(`/driver-profile/${alert.driver_details.id}/transactions`);
              }
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.ACCOUNT_LOW_BALANCE_THRESHOLD
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/card-management`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.OVER_MAXIMUM_CREDIT_LINE && alert.for_user_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/customer-profile/${alert.for_user_id}?tab=terms`);
              onClose();
            },
          },
        ]
      : []),
    ...([
      ALERT_TYPE.DRIVER_RECEIVED_SHIPMENT_OFFER,
      ALERT_TYPE.DRIVER_ACCEPTED_SHIPMENT_OFFER,
      ALERT_TYPE.DRIVER_DECLINED_SHIPMENT_OFFER,
      ALERT_TYPE.DRIVER_DISPATCHED,
      ALERT_TYPE.DRIVER_CONFIRMED,
      ALERT_TYPE.SHIPMENT_COMPETED,
      ALERT_TYPE.EXPIRED_EDI_API_SHIPMENT,
    ].includes(alert.alert_name?.alert_name) && alert.shipment_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/shipment/${alert.shipment_id}`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.DRIVER_PREVIOUS_EMPLOYMENT_VERIFICATION && alert.driver_details
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/driver-profile/${alert.driver_details.id}/previous-employment`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.DRIVER_CLOCKED_OUT_INACTIVITY && alert.driver_details
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/driver-profile/${alert.driver_details.id}/time-tracking`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.DRIVER_INCIDENT && alert.driver_details
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/driver-profile/${alert.driver_details.id}/incidents`);
              onClose();
            },
          },
        ]
      : []),
    ...(alert.document_path
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => setDocument(alert.document_path),
          },
        ]
      : []),
    ...(alert.equipment_type && alert.equipment_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(
                `/equipment-profile/${alert.equipment_type === 1 ? 'vehicle' : 'trailer'}/${alert.equipment_id}`
              );
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.PAYROLL_DISPUTE_OCCURS && alert.settlement_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(
                `/settlement/${alert.user_type}/${alert.user_id}/${alert.settlement_id}?pay_period_start=${alert.pay_period_start}&pay_period_end=${alert.pay_period_end}`
              );
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.VEHICLE_REACH_NUMBER_OF_STOPS ||
    alert.alert_name?.alert_name === ALERT_TYPE.TRAILER_REACH_NUMBER_OF_STOPS
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigateToInventory(
                alert.stop_point_id,
                alert.alert_name?.alert_name === ALERT_TYPE.VEHICLE_REACH_NUMBER_OF_STOPS ? 1 : 2
              );
              onClose();
            },
          },
        ]
      : []),
    ...(alert.alert_name?.alert_name === ALERT_TYPE.POSSIBLE_FRAUD_DETECTED && alert.carrier_id
      ? [
          {
            key: 'view',
            type: 'primary',
            title: 'View',
            onClick: () => {
              navigate(`/carrier-profile/${alert.carrier_id}`, { state: { activeTab: 'Payment Terms' } });
              onClose();
            },
          },
        ]
      : []),
    {
      key: 'submit',
      type: 'primary',
      title: 'Save',
      onClick: updateStatus,
      disabled: loading,
    },
  ];
};

export const noExpirationAlerts = [
  ALERT_TYPE.DRIVER_CUSTOM_DOCUMENT_EXPIRATION,
  ALERT_TYPE.DRIVER_DOCUMENT,
  ALERT_TYPE.STAFF_CUSTOM_DOCUMENT_EXPIRATION,
  ALERT_TYPE.OWNER_OPERATOR_DOCUMENT,
  ALERT_TYPE.CARRIER_DOCUMENT_EXPIRED,
];
