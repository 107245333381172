import React from 'react';
import moment from 'moment';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SWrapper } from './DetailsComponents.styles';

const CompareTakeHome = ({ primarySettlement, secondarySettlement }) => {
  const { amount, pay_period_end } = primarySettlement ?? {};
  const { amount: amount2, pay_period_end: pay_period_end2 } = secondarySettlement ?? {};
  const amountDiff = amount - amount2;

  return (
    <SWrapper $badgeColor={palette.green400}>
      <div className='d-flex align-items-center gap-2 mb-2'>
        <div className='d-flex align-items-center gap-1'>
          <span className='detail-badge' />
          <Typography variant='s2' style={{ color: palette.gray900 }}>
            Take Home
          </Typography>
        </div>
        <div className='d-flex'>
          {amountDiff !== 0 && (
            <ArrowUp
              fill={amountDiff > 0 ? palette.green500 : palette.red500}
              style={{ transform: amountDiff < 0 ? 'rotate(180deg)' : '' }}
            />
          )}
          <Typography
            variant='c2'
            style={{
              color: amountDiff === 0 ? palette.gray700 : amountDiff > 0 ? palette.green500 : palette.red500,
            }}
          >
            ${formatNumber(Math.abs(amountDiff))}
          </Typography>
        </div>
      </div>
      <div className='w-100 d-flex justify-content-between'>
        <div className='d-flex flex-column'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {moment(pay_period_end).format('MMM D, YYYY')}
          </Typography>
          <Typography variant='button2' style={{ color: palette.gray900 }}>
            ${formatNumber(amount)}
          </Typography>
        </div>
        <div className='d-flex flex-column'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {moment(pay_period_end2).format('MMM D, YYYY')}
          </Typography>
          <Typography variant='button2' style={{ color: palette.gray900 }}>
            ${formatNumber(amount2)}
          </Typography>
        </div>
      </div>
    </SWrapper>
  );
};

export default CompareTakeHome;
