import React, { useState } from 'react';

const MenuItem = ({ setisDeleteChannel, leaveChannel, onLeave }) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className='menuItem' onClick={() => setIsShow(!isShow)}>
      <div />
      <div />
      <div />
      {isShow && (
        <ul className='menu'>
          {leaveChannel ? (
            <li style={{ width: '130px', color: '#D12953' }} onClick={() => onLeave(true)}>
              Leave Channel
            </li>
          ) : (
            <>
              <li>Edit</li>
              <li onClick={() => setisDeleteChannel(true)} style={{ color: '#D12953' }}>
                Delete
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
};
export default MenuItem;
