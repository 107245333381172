import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .user-name {
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  .user-profile-picture {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 24px;
    height: 24px;

    a {
      width: 100%;
      height: 100%;
    }

    svg {
      vertical-align: baseline;
    }

    :hover {
      background-color: ${palette.indigo50};
    }
  }
`;
