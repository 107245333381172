import React, { useEffect, useState } from 'react';
import './Ifta.css';
import HeaderStar from 'components/HeaderStar';
import { getIFTAConnectedService } from 'services/IFTAServices';
import FuelPrice from './fuelPrice/FuelPrice';
import Jurisdiction from './jurisdiction/Jurisdiction';
import Vehicle from './vehicle/Vehicle';

const allFuelTypes = ['All Fuel Types', 'Diesel', 'Gasoline'];

const tab = [
  {
    id: 1,
    title: 'Fuel Prices',
    activeId: 'fuelPrice',
    component: (props) => <FuelPrice {...props} />,
  },
  {
    id: 2,
    title: 'Jurisdiction',
    activeId: 'Jurisdiction',
    component: (props) => <Jurisdiction fuelType={allFuelTypes} {...props} />,
  },
  {
    id: 3,
    title: 'Vehicle',
    activeId: 'Vehicle',
    component: (props) => <Vehicle fuelType={allFuelTypes} {...props} />,
  },
];

const IFTAPage = () => {
  const [active, setActive] = useState(tab[0]);
  const [connectedService, setConnectedService] = useState(null);

  const getConnectedService = async () => {
    try {
      const data = await getIFTAConnectedService();
      setConnectedService(data);
    } catch (e) {
      setConnectedService({ success: false });
    }
  };

  useEffect(() => {
    getConnectedService();
  }, []);

  return (
    <div className='ifta-layout'>
      <HeaderStar title='IFTA' />
      <ul className='Ifta-tabs'>
        {tab.map((t) => (
          <li onClick={() => setActive(t)} className={active.activeId === t.activeId ? 'active-ifta' : ''}>
            {t.title}
          </li>
        ))}
      </ul>
      {active?.component({ connectedService })}
    </div>
  );
};

export default IFTAPage;
