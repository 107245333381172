import styled from 'styled-components';
import { Popover } from 'react-bootstrap';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: ${palette.gray200};
  padding: 4px 20px;
  margin-top: 18px;

  .title-wrapper {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
  }

  .mapped-data-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 15px;
  }

  .mapped-data-wrapper {
    border-radius: 4px 0 0 4px;
    padding: 1px 6px 1px 0;
    flex-wrap: wrap;
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .many-data-show-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--indigo-0);
    margin-top: 4px;

    :hover .delete-item-wrapper {
      opacity: 1;
      width: 12px;
      transform: scale(1.1) translateX(-2px);
    }
  }

  .delete-item-wrapper {
    width: 0;
    opacity: 0;
    z-index: 10;
    height: 12px;
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
    background-color: var(--indigo-0);
    transform: scale(0) translateX(-2px);
  }

  .many-data-show-wrapper:hover .delete-item-wrapper {
    opacity: 1;
    width: 12px;
    transform: scale(1.1) translateX(-2px);
  }
`;

export const SPopover = styled(Popover)`
  min-width: 510px;
  background-color: ${palette.white};

  .overlay-header {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: ${palette.gray200};

    span {
      margin-left: 16px;
    }
  }

  .overlay-body {
    padding: 0 24px;
    background-color: ${palette.gray0};
  }

  .overlay-status-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .closed {
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;
  }

  .opened {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 380px;
    overflow: auto;
  }

  .overlay-footer {
    height: 56px;
    border-top: 1px solid ${palette.gray200};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
  }

  .collapse:not(.show) {
    display: none;
  }
`;
