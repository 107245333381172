import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function getTimeZone() {
  const { data } = await axios.get(`${API_URL}/time-zones`, { headers: authHeader() });
  return data;
}

export async function updateUserTimeZone(id) {
  const { data } = await axios.put(
    `${API_URL}/customer/time-zone`,
    {
      time_zone: id,
    },
    {
      headers: authHeader(),
    }
  );
  return data;
}

export async function UpdateUserStatus(status) {
  const { data } = await axios.put(
    `${API_URL}/users/update-status`,
    {
      status,
    },
    { headers: authHeader() }
  );
  return data;
}
