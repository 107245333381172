import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Collapse } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { getRequestData } from 'Api/Request';
import { SDatePicker } from 'pages/OfferedShipments/ShipmentsTable/components/AdvancedFilter/AdvancedFilter.styles';
import moment from 'moment';
import { STATUSES } from '../constat';
import classes from './AdvancedFilter.module.scss';

const AdvancedFilter = ({ open, filter, setFilter, setSearch, onSearch, requestTypes, children }) => {
  const [badgeData, setBadgeData] = useState([]);
  const [requestIds, setRequestIds] = useState([]);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [requestDateRange, setRequestDateRange] = useState(filter.requestDateRange || [null, null]);

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    const start_date =
      nameKey === 'dates' ? (item?.[0] ? moment(item?.[0]).format('MM/DD/YYYY') : undefined) : undefined;
    const end_date = nameKey === 'dates' ? (item?.[1] ? moment(item?.[1]).format('MM/DD/YYYY') : undefined) : undefined;
    const name = nameKey === 'dates' ? `${start_date || ''} - ${end_date || ''}` : item?.[nameKey] || '';

    return {
      name,
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const getAllRequests = async () => {
    try {
      setRequestsLoading(true);
      const { data } = await getRequestData({ page: 1, itemsPerPage: 10000 });
      setRequestIds(data.map((el) => ({ id: el.request_id, title: el.request_id })));
    } catch (e) {
      // Do nothing
    } finally {
      setRequestsLoading(false);
    }
  };

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(filter.request_id
        ? [
            {
              title: 'Request ID',
              children: filter.request_id.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'request_id' })),
            },
          ]
        : []),
      ...(filter.status
        ? [
            {
              title: 'Status',
              children: filter.status.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'status' })),
            },
          ]
        : []),
      ...(filter.type
        ? [
            {
              title: 'Request Type',
              children: filter.type.map((item) => normalizeBadgeData(item, 'type', 'id', { key: 'type' })),
            },
          ]
        : []),
      ...(filter.requestDateRange
        ? [
            {
              title: 'Request Date(s)',
              children: [normalizeBadgeData(filter.requestDateRange, 'dates', 'dates', { key: 'requestDateRange' })],
            },
          ]
        : []),
    ];
  }, []);

  const onSearchFilter = () => {
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      if (key === 'requestDateRange') {
        delete newFilter[key];
      } else {
        const newItemValue = itemValue.filter((el) => el.id !== id);

        if (newItemValue.length === 0) {
          delete newFilter[key];
        } else {
          newFilter[key] = newItemValue;
        }
      }
    } else if (typeof itemValue === 'string') {
      delete newFilter[key];
    }

    if (key === 'requestDateRange') {
      setRequestDateRange([null, null]);
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));
  };

  const handleClearAll = () => {
    setRequestDateRange([null, null]);
    setFilter({});
    onSearch({});
    setSearch('');
    setBadgeData([]);
  };

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
    } else {
      newFilter[key] = value;
    }
    return setFilter(newFilter);
  };

  useEffect(() => {
    getAllRequests();
  }, []);

  return (
    <>
      {children?.({ onSearchFilter })}
      <Collapse in={open}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }} className={classes.autocompleteWrapper}>
              <InputLabel className='mb-0'>Request ID</InputLabel>
              <Autocomplete
                multiple
                limitTags={2}
                loading={requestsLoading}
                options={requestIds}
                value={filter?.request_id || []}
                onChange={(e, value) => onChangeMultiSelectValues('request_id', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }} className={classes.autocompleteWrapper}>
              <InputLabel className='mb-0'>Status</InputLabel>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={STATUSES}
                value={filter?.status || []}
                onChange={(e, value) => onChangeMultiSelectValues('status', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }} className={classes.autocompleteWrapper}>
              <InputLabel className='mb-0'>Request Type</InputLabel>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.type || []}
                options={requestTypes}
                onChange={(e, value) => onChangeMultiSelectValues('type', value)}
                getTagLabel={(option) => (option ? option?.type : '')}
                getOptionLabel={(option) => (option ? option?.type : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 150 }}>
              <InputLabel className='mb-0'>Request Date(s)</InputLabel>
              <div className='d-flex align-items-center gap-1 position-relative'>
                <SDatePicker
                  selectsRange
                  renderStart={false}
                  shouldCloseOnSelect={false}
                  startDate={requestDateRange[0]}
                  endDate={requestDateRange[1]}
                  placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                  onChange={(dates) => {
                    setRequestDateRange(dates);
                    setFilter((prevState) => ({ ...prevState, requestDateRange: dates }));
                  }}
                />
                <DeleteIcon
                  className='delete-icon'
                  onClick={() => {
                    setRequestDateRange([null, null]);
                    setFilter((prevState) => ({ ...prevState, requestDateRange: [null, null] }));
                  }}
                />
              </div>
            </div>
          </div>
          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>
      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index ? <span className={classes.comma}>,</span> : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AdvancedFilter;
