import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateAlert, uploadDriverDocument } from 'Api/AlertCenter';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { statusOptions } from '../TakeActions.data';
import { validationSchema } from './validationSchema';

const MedicalExaminersExpiration = ({ open, onClose, alert, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      const cardAttachment = await uploadDocument(values.attach_card);
      let longFormAttachment;

      if (values.attach_long_form) {
        longFormAttachment = await uploadDocument(values.attach_long_form);
      }

      const body = {
        driver_id: alert.driver_id,
        attach_card: cardAttachment,
        attach_long_form: longFormAttachment || null,
        medical_examiners_expire_date: moment(values.medical_examiners_expire_date).format('YYYY-MM-DD'),
        status: values.status.value,
      };

      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: {
      attach_card: null,
      attach_long_form: null,
      medical_examiners_expire_date: null,
      status: statusOptions.find((i) => i.name === alert?.status) || null,
    },
    validationSchema,
    onSubmit,
  });

  const uploadDocument = async (file) => {
    try {
      const formData = new FormData();
      formData.append('driver_id', alert.driver_id);
      formData.append('source', 'general');
      formData.append('file_upload', file);

      const { document_path } = await uploadDriverDocument(formData);
      return document_path;
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      padding='0'
      $maxWidth='750px'
      $minWidth='750px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <div className='d-flex gap-4 mb-4 align-items-center'>
            <div>
              <DatePicker
                required
                label='Expiry Date'
                width='150px'
                name='medical_examiners_expire_date'
                value={values.medical_examiners_expire_date}
                onBlur={handleBlur}
                onChange={(val) => handleChange('medical_examiners_expire_date', val)}
                disablePast
                error={touchedErrors.medical_examiners_expire_date}
              />
            </div>
            <div>
              <Uploader
                type={2}
                required
                label='Card'
                document={values.attach_card}
                onDrop={(files) => handleChange('attach_card', files[0])}
                onRemove={() => handleChange('attach_card', null)}
                error={touchedErrors.attach_card}
              />
            </div>
            <div>
              <Uploader
                type={2}
                label='Long Form'
                document={values.attach_long_form}
                onDrop={(files) => handleChange('attach_long_form', files[0])}
                onRemove={() => handleChange('attach_long_form', null)}
                error={touchedErrors.attach_long_form}
              />
            </div>
          </div>
          <div>
            <Autocomplete
              required
              width='260px'
              label='Action'
              name='status'
              options={statusOptions}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.status}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MedicalExaminersExpiration;
