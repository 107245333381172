import uuid from 'react-uuid';

export const TRUCK_DAY_CAB_OPTION = {
  title: 'Truck (Day-Cab)',
  id: 1,
  value: 'equipment_types',
  type: 'checkbox',
  key: 'Truck (Day-Cab)',
  typeData: 'vehicle',
  truckType: 1,
};

export const TRUCK_SLEEPER_OPTION = {
  title: 'Truck (Sleeper)',
  id: '1',
  value: 'equipment_types',
  type: 'checkbox',
  key: 'Truck (Sleeper)',
  typeData: 'vehicle',
  truckType: 2,
};

export const OWNERSHIP_OPTIONS = [
  {
    id: 1,
    key: uuid(),
    title: 'Company Owned',
  },
  {
    id: 2,
    key: uuid(),
    title: 'Leasing Owned',
  },
  {
    id: 3,
    key: uuid(),
    title: 'Owner Operator',
  },
  {
    id: 4,
    key: uuid(),
    title: '3rd Party',
  },
];

export const MOTION_DATA = [
  {
    title: 'Not Moving',
    id: 1,
    value: 'motions',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Idling',
    id: 2,
    value: 'motions',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Moving',
    id: 3,
    value: 'motions',
    type: 'checkbox',
    key: uuid(),
  },
];

export const FILTER_SEARCH_BY_VEHICLE = [
  {
    title: 'Equipment',
    id: 1,
    value: 'equipment_id',
  },
  {
    title: 'Location',
    id: 2,
    value: 'location',
  },
  {
    title: 'Drivers',
    id: 3,
    value: 'driver',
  },
  {
    title: 'Retired',
    id: 4,
    value: 'retired',
  },
];

export const FILTER_SEARCH_BY_TRAILER = [
  {
    title: 'Equipment',
    id: 1,
    value: 'equipment_id',
  },
  {
    title: 'Location',
    id: 2,
    value: 'location',
  },
  {
    title: 'Retired',
    id: 3,
    value: 'retired',
  },
];
