import React from 'react';
import { Textarea } from 'common/Input';
import { Typography } from 'components/Typography';
import ContactAutocomplete from 'componentsV2/Customer/ContactAutocomplete';
import { palette } from 'utils/constants';
import QuotesList from './QuotesList';

const CustomerSection = ({ form, customerData, customerIndex, onPreview }) => {
  const { values, handleChange, touchedErrors } = form;
  const errors = touchedErrors.customers?.[customerIndex]?.quotes;

  const onContactChange = (value) => {
    handleChange(
      `customers[${customerIndex}].quotes`,
      values.customers[customerIndex].quotes.map((item) => ({
        ...item,
        billing: { ...item.billing, contact: value },
      }))
    );
  };

  return (
    <div key={customerData.customer.id} className='customer-section-wrapper'>
      <div className='d-flex align-items-center justify-content-between'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          {customerData.customer.company_name}
        </Typography>
      </div>
      <QuotesList form={form} quotes={customerData.quotes} customerIndex={customerIndex} onPreview={onPreview} />
      <div>
        <div>
          <div>
            {!!values.send_email && (
              <div className='mb-4'>
                <ContactAutocomplete
                  showAddContact
                  size='small'
                  width='350px'
                  label='Send To Email'
                  labelProps={{ required: true }}
                  name={`customers[${customerIndex}].quotes[0].billing.contact`}
                  value={values.customers[customerIndex].quotes[0].billing.contact}
                  onChange={(e, value) => onContactChange(value)}
                  customer={customerData.customer}
                  error={errors?.[0]?.billing?.contact}
                />
              </div>
            )}
            <div>
              <Textarea
                rows={3}
                label='Notes to Customer'
                name={`customers[${customerIndex}].quotes[0].customer_notes`}
                value={values.customers[customerIndex].quotes[0].customer_notes}
                onChange={handleChange}
                error={errors?.[0]?.customer_notes}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSection;
