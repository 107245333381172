import { array, date, number, object } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  contact: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  bid_amount: number().required('Required').nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  stops: array().of(
    object().shape({
      new_eta: date().when('can_handle', {
        is: (can_handle) => !can_handle,
        then: validator.futureRequiredDate(),
        otherwise: validator.date(),
      }),
    })
  ),
});
