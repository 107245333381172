import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import { updateShipmentMiles } from 'Api/Shipment';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import useShipmentLocked from 'hooks/useShipmentLocked';
import classes from './completedShipments.module.css';

const MilesColumn = ({ shipment, type, onSuccess }) => {
  const showToaster = useShowToaster();
  const { isShipmentLocked } = useShipmentLocked();
  const miles = Math.round(shipment?.[`${type}_miles`]);
  const [editMiles, setEditMiles] = useState(false);
  const [milesToEdit, setMilesToEdit] = useState(miles);

  const onMilesChange = async () => {
    try {
      if (type === 'empty') {
        const body = {
          empty_miles: milesToEdit || 0,
          loaded_miles: Math.round(shipment?.loaded_miles),
        };
        await updateShipmentMiles(shipment.shipment_id, body);
        setEditMiles(false);

        showToaster({ type: 'success', message: `${capitalize(type)} miles are successfully updated!` });
        onSuccess();
      } else {
        const body = {
          empty_miles: Math.round(shipment?.empty_miles),
          loaded_miles: milesToEdit || 0,
        };
        await updateShipmentMiles(shipment.shipment_id, body);
        setEditMiles(false);

        showToaster({ type: 'success', message: `${capitalize(type)} miles are successfully updated!` });
        onSuccess();
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  return (
    <div className='d-flex align-items-center gap-2'>
      {editMiles ? (
        <>
          <Input
            size='small'
            width='100px'
            type='number'
            placeholder='0'
            name='miles'
            value={milesToEdit}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setMilesToEdit(e.target.value)}
            onKeyDown={(e) => {
              blockNonPositiveNumbers(e);
              blockNotNumberChars(e);
            }}
          />
          <DeleteIcon
            className='pointer'
            fill={palette.red500}
            onClick={(e) => {
              e.stopPropagation();
              setMilesToEdit(miles);
              setEditMiles(false);
            }}
          />
          <CheckIcon
            className='pointer'
            onClick={(e) => {
              e.stopPropagation();
              onMilesChange();
            }}
          />
        </>
      ) : (
        <>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {miles}
          </Typography>
          {isShipmentLocked(shipment) ? (
            <ActionLocked shipment={shipment} />
          ) : (
            <EditIcon
              width={13}
              height={13}
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                setEditMiles(true);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MilesColumn;
