import React, { useState } from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SWrapper, SAccordion } from './FeatureCard.styles';

const FeatureCard = ({ feature }) => {
  const [expanded, setExpanded] = useState(false);
  const { children } = feature || {};

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <SWrapper>
      <SAccordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary>
          <div className='feature-header'>
            <div />
            <Typography variant='s1' style={{ color: palette.gray900 }}>
              {feature.name}
            </Typography>
            <span onClick={() => setExpanded((prevState) => !!prevState)}>
              <ArrowRight width={12} height={12} className={`expand-arrow ${expanded ? 'reverse' : ''}`} />
            </span>
          </div>
        </AccordionSummary>
        <Divider color={palette.gray50} margin='0' />
        <div className='feature-body'>
          {!!children?.length && (
            <AccordionDetails>
              <ul>
                {children?.map((item) => (
                  <Typography variant='s2' as='li' style={{ color: palette.gray700 }} key={item.id}>
                    {item.name}
                  </Typography>
                ))}
              </ul>
            </AccordionDetails>
          )}
        </div>
      </SAccordion>
    </SWrapper>
  );
};

export default FeatureCard;
