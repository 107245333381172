import React from 'react';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import { SActionsWrapper, SDotsWrapper, SPopover } from './ThreeDotActions.styles';

const ThreeDotActions = ({
  anchorEl,
  setAnchorEl,
  children,
  actionButton,
  handleClose = () => null,
  size = 15,
  popoverStyles,
  popoverProps,
  className,
  padding,
  disabled,
}) => {
  const onDotsClick = (event) => {
    if (disabled) {
      return;
    }

    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    handleClose();
  };

  return (
    <SActionsWrapper className={className}>
      <SDotsWrapper>
        {actionButton ? (
          <div onClick={onDotsClick}>{actionButton}</div>
        ) : (
          <span className='dots' onClick={onDotsClick}>
            <ActionDots width={size} height={size} />
          </span>
        )}
        <SPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          style={popoverStyles}
          $padding={padding}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...popoverProps}
        >
          <div className='actions-wrapper'>{children}</div>
        </SPopover>
      </SDotsWrapper>
    </SActionsWrapper>
  );
};

export default ThreeDotActions;
