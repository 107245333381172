export const bankFeedMapper = {
  Date: 'date',
  'Reference ID': 'reference_id',
  Description: 'description',
  Memo: 'memo',
  Payment: 'payment',
  Deposit: 'deposit',
};

export const transactionsMapper = {
  Date: 'date',
  'Reference ID': 'reference_id',
  Payee: 'payee',
  Account: 'account',
  Memo: 'memo',
  Debit: 'debit',
  Credit: 'credit',
};
