import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.gray300};

  .pay-type-chip-wrapper {
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
  }

  .MuiChip-label {
    font-size: 12px;
    font-weight: 500;
  }
`;
