import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .flex-right {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .download-icon-text {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg path {
      fill: ${palette.gray700};
    }
  }

  .action-button {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 12px;
    font-family: Inter, sans-serif;
  }
`;
