import styled from 'styled-components';

export const SWrapper = styled.div`
  margin-top: 24px;
  padding: 0 12px;

  .MuiAccordion-root {
    box-shadow: none;
    text-align: center;

    .MuiAccordionSummary-root {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      min-height: auto;

      &.Mui-expanded {
        min-height: auto;
      }
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;
