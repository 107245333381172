import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import { getCustomersWithDetention } from 'Api/Customers';
import { getStopPointsWithDetention } from 'Api/StopPoint';
import useDebounce from 'hooks/useDebounce';
import { SSearchWrapper } from './TableFilters.styles';

const TableFilters = ({ search, setSearch, selectedFilters, setSelectedFilters }) => {
  const [filterType, setFilterType] = useState('All');
  const [customers, setCustomers] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [equipmentIdSearch, setEquipmentIdSearch] = useState('');
  const debouncedSearch = useDebounce(equipmentIdSearch, 500);

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersWithDetention();
      setCustomers(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getStopPoints = async () => {
    try {
      const { data } = await getStopPointsWithDetention();
      setStopPoints(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    setSelectedFilters((prevState) => ({ ...prevState, 'filters[equipment_id]': debouncedSearch || undefined }));
  }, [debouncedSearch]);

  useEffect(() => {
    getCustomers();
    getStopPoints();
  }, []);

  return (
    <SSearchWrapper>
      <div>
        <Autocomplete
          width='120px'
          options={['All', 'Customer', 'Location', 'Equipment ID']}
          value={filterType}
          onChange={(e, val) => {
            setFilterType(val);
            setSearch('');
          }}
          inputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FilterIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      {filterType === 'All' && <Search search={search} onChange={setSearch} className='search-input' />}
      {filterType === 'Equipment ID' && (
        <Search search={equipmentIdSearch} onChange={setEquipmentIdSearch} className='search-input' />
      )}
      {filterType === 'Customer' && (
        <Autocomplete
          width='250px'
          disableClearable={false}
          placeholder='Select Customer..'
          options={customers}
          value={selectedFilters.customer}
          onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, customer: val }))}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          className='filter-dropdown'
        />
      )}
      {filterType === 'Location' && (
        <Autocomplete
          width='320px'
          labelKey='location_name'
          disableClearable={false}
          placeholder='Select Location..'
          options={stopPoints}
          value={selectedFilters.location}
          onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, location: val }))}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          className='filter-dropdown'
        />
      )}
    </SSearchWrapper>
  );
};

export default TableFilters;
