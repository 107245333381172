import { date, number, object } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  type: number().required('Required').nullable(),
  flat_per_shipment: number().when('type', {
    is: 1,
    then: number().required('Required').min(1, 'Required').nullable(),
    otherwise: number().nullable(),
  }),
  percentage_per_shipment: number().when('type', {
    is: 2,
    then: number().required('Required').min(1, 'Required').nullable(),
    otherwise: number().nullable(),
  }),
  percentage_after_driver_pay: number().when('type', {
    is: 3,
    then: number().required('Required').min(1, 'Required').nullable(),
    otherwise: number().nullable(),
  }),
  end_date: date().when('is_active', {
    is: (is_active) => !is_active,
    then: validator.requiredDate(),
    otherwise: validator.date(),
  }),
});
