import moment from 'moment';

export const getBidExpirationData = (shipment) => {
  const { planner } = shipment;

  const postedDate = moment.utc(planner.shipment_offer_options.posted_date);

  const expire_date = moment(postedDate).add(
    planner.shipment_offer_options.expire_shipment_offer,
    planner.shipment_offer_options.expire_shipment_offer_unit
  );

  const totalDiff = expire_date.diff(postedDate, 'minutes');
  const minutesPassed = moment().diff(postedDate, 'minutes');

  return { expire_date, totalDiff, minutesPassed };
};

export const getAwardExpirationData = (shipment) => {
  const { planner, bid } = shipment;
  const awardDate = moment.utc(bid.award_date);

  const expire_date = moment(awardDate).add(
    planner.shipment_offer_options.retract_carrier_award,
    planner.shipment_offer_options.retract_carrier_award_unit
  );

  const totalDiff = expire_date.diff(awardDate, 'minutes');
  const minutesPassed = moment().diff(awardDate, 'minutes');

  return { expire_date, totalDiff, minutesPassed };
};
