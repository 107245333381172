import React, { useEffect, useState } from 'react';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import DeductionsSettings from 'pages/Payroll/Settlement/Tables/Deduction/components/DeductionsSettings';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { createDriverBalance } from 'Api/Driver';
import { createStaffBalance } from 'Api/Staff';
import { getDeductionsList } from 'Api/PayrollSettings';
import { validationSchema } from './validationSchema';
import { monthDays, weekDays } from './AddBalance.data';
import { SRadio, SAutocomplete } from './AddBalance.styles';

const AddBalance = ({ open, onClose, onSuccess, userId, isStaff }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [deductionTypes, setDeductionTypes] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        driver_id: !isStaff ? userId : undefined,
        staff_id: isStaff ? userId : undefined,
        name: values.deductionType.deduction_type,
        deduction_type_id: values.deductionType.id,
        type: values.type,
        original_balance: Number(values.original_balance),
        payment_amount: Number(values.payment_amount),
        month_week_settlement: Number(values.type === 'Recurring'),
        amortized_settlement: Number(values.type === 'Amortized'),
        day: values.term === 'Month' ? values.monthDay : values.term === 'Week' ? values.weekDay : '',
        month_week: values.type === 'Recurring' ? values.term : '',
        amortized_total: Number(values.original_balance),
        amortized_months:
          values.type === 'Amortized'
            ? values.amortized_months
            : Math.ceil(Number(values.original_balance) / Number(values.payment_amount)),
      };
      isStaff ? await createStaffBalance(body) : await createDriverBalance(body);
      onSuccess();
      onClose();
      showToaster({ type: 'success', message: 'Balance has been successfully added!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: {
      deductionType: null,
      type: '',
      original_balance: '',
      payment_amount: '',
      term: '',
      monthDay: '',
      weekDay: '',
      amortized_months: '',
    },
    onSubmit,
    validationSchema,
  });

  const onTypeChange = (val) => {
    if (val === 'Recurring') {
      setValues((prevState) => ({
        ...prevState,
        type: val,
        term: prevState.term === 'amortized' ? '' : prevState.term,
        amortized_months: '',
        payment_amount: '',
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        type: val,
        term: 'amortized',
        payment_amount: '',
      }));
    }
  };

  const getDeductionTypes = async () => {
    try {
      const { data } = await getDeductionsList();
      setDeductionTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onSettingsClose = () => {
    getDeductionTypes();
    setSettingsOpen(false);
  };

  useEffect(() => {
    getDeductionTypes();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Add Balance'
      $bgColor={palette.gray0}
      $maxWidth='580px'
      $minWidth='580px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Add Balance',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-3'>
        <div>
          <div className='d-flex align-items-center gap-2'>
            <InputLabel required>Deduction Type</InputLabel>
            <GearIcon onClick={() => setSettingsOpen(true)} className='settings-gear' />
          </div>
          <Autocomplete
            required
            labelKey='deduction_type'
            name='deductionType'
            value={values.deductionType}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={deductionTypes}
            onChange={(e, val) => handleChange('deductionType', val)}
            error={touchedErrors.deductionType}
          />
        </div>
        <div>
          <Autocomplete
            required
            label='Type'
            name='type'
            options={['Amortized', 'Recurring']}
            onChange={(e, val) => onTypeChange(val)}
            value={values.type}
            error={touchedErrors.type}
          />
        </div>
        <InputWithIcon
          required
          width='200px'
          label='Original Balance'
          type='number'
          name='original_balance'
          placeholder='0.00'
          value={values.original_balance}
          onChange={(e) => {
            if (values.term === 'amortized') {
              setValues((prevState) => ({
                ...prevState,
                original_balance: e.target.value,
                payment_amount:
                  values.amortized_months && e.target.value
                    ? (Number(e.target.value || 0) / Number(values.amortized_months || 1)).toFixed(2)
                    : '',
              }));
            } else {
              handleChange('original_balance', e.target.value);
            }
          }}
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors.original_balance}
        />
        <InputWithIcon
          required
          width='200px'
          label='Payment Amount'
          type='number'
          name='payment_amount'
          placeholder='-'
          value={values.payment_amount}
          onChange={(e) => handleChange('payment_amount', e.target.value)}
          onKeyDown={blockNonPositiveNumbers}
          disabled={values.term === 'amortized'}
          error={touchedErrors.payment_amount}
        />
        <div>
          <InputLabel required>Select Term</InputLabel>
          <div className='d-flex flex-column gap-3'>
            {values.type !== 'Amortized' && (
              <>
                <div className='d-flex align-items-center gap-2'>
                  <SRadio
                    type='radio'
                    name='term'
                    value='Month'
                    checked={values.term === 'Month'}
                    onChange={(e) => {
                      setValues((prevState) => ({
                        ...prevState,
                        term: e.target.value,
                        type: 'Recurring',
                      }));
                    }}
                    disabled={values.type === 'Amortized'}
                  />
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Every
                  </Typography>
                  <div>
                    <SAutocomplete
                      width='118px'
                      value={values.monthDay}
                      onChange={(e, value) => handleChange('monthDay', value)}
                      options={monthDays}
                      disabled={values.term !== 'Month'}
                      error={touchedErrors.monthDay}
                    />
                  </div>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    of the month, until paid off from settlement(s).
                  </Typography>
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <SRadio
                    type='radio'
                    name='term'
                    value='Week'
                    checked={values.term === 'Week'}
                    onChange={(e) => {
                      setValues((prevState) => ({
                        ...prevState,
                        term: e.target.value,
                        type: 'Recurring',
                      }));
                    }}
                    disabled={values.type === 'Amortized'}
                  />
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Every
                  </Typography>
                  <div>
                    <SAutocomplete
                      width='118px'
                      value={values.weekDay}
                      onChange={(e, value) => handleChange('weekDay', value)}
                      options={weekDays}
                      disabled={values.term !== 'Week'}
                      error={touchedErrors.weekDay}
                    />
                  </div>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    of the week, until paid off from settlement(s).
                  </Typography>
                </div>
              </>
            )}
            {values.type !== 'Recurring' && (
              <div className='d-flex align-items-center gap-2'>
                <SRadio
                  type='radio'
                  name='term'
                  value='amortized'
                  checked={values.term === 'amortized'}
                  onChange={(e) => {
                    setValues((prevState) => ({
                      ...prevState,
                      term: e.target.value,
                      type: 'Amortized',
                      payment_amount: '',
                    }));
                  }}
                  disabled={values.type === 'Recurring'}
                />
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Amortized over
                </Typography>
                <Input
                  required
                  width='100px'
                  type='number'
                  name='amortized_months'
                  value={values.amortized_months}
                  onChange={(e) => {
                    setValues((prevState) => ({
                      ...prevState,
                      amortized_months: e.target.value,
                      payment_amount:
                        values.original_balance && e.target.value
                          ? (Number(values.original_balance || 0) / Number(e.target.value || 1)).toFixed(2)
                          : '',
                    }));
                  }}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                  disabled={values.term !== 'amortized'}
                  error={touchedErrors.amortized_months}
                />
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  settlement(s), deducted on each settlement.
                </Typography>
              </div>
            )}
          </div>
          <ErrorMessage error={touchedErrors.term} />
        </div>
      </div>
      <DeductionsSettings open={settingsOpen} onClose={onSettingsClose} />
    </Modal>
  );
};

export default AddBalance;
