import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import ItemsSettings from 'pages/Accounting/Payables/components/ItemsSettings';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { GetChargeTypes } from 'Api/CompanySettings';
import { getItemSubtotalAmount, getItemTaxAmount } from '../EditInvoice.data';
import { SAddMore, SFormRow, SFormWrapper, SOption, STotalSection, SWarningPopup } from '../EditInvoice.styles';

const ChargesForm = ({ values, handleChange, touchedErrors }) => {
  const { currency } = useSelector((state) => state.root);
  const [items, setItems] = useState([]);
  const [openTaxWarning, setOpenTaxWarning] = useState(false);
  const [openItemsSettings, setOpenItemsSettings] = useState(false);

  const handleOpenTaxWarning = () => {
    setOpenTaxWarning(true);

    setTimeout(() => {
      setOpenTaxWarning(false);
    }, 8000);
  };

  const total = useMemo(() => {
    return values.items.reduce((acc, cur) => {
      if (cur.quantity && cur.price) {
        acc += Number(cur.total_amount);
      }
      return acc;
    }, 0);
  }, [values]);

  const totalTax = useMemo(() => {
    return values.items.reduce((acc, cur) => {
      if (cur.is_taxable && cur.tax_amount) {
        acc += Number(cur.tax_amount);
      }
      return acc;
    }, 0);
  }, [values]);

  const onAddMore = () => {
    handleChange(`items[${values.items.length}]`, {
      id: Date.now(),
      item: null,
      desc: '',
      quantity: '',
      price: '',
      is_taxable: false,
      tax_amount: '',
      total_amount: '',
    });
  };

  const onDeleteRow = (index) => {
    handleChange(
      'items',
      values.items.filter((item, i) => i !== index)
    );
  };

  const getItems = async () => {
    try {
      const { data } = await GetChargeTypes();
      setItems(data || []);

      values.items.forEach((item, index) => {
        const updatedItem = data.find((el) => el.id === item.item.id);

        handleChange(`items[${index}].item`, updatedItem);

        if (!!values.items[index].is_taxable && !updatedItem.is_taxable) {
          handleChange(`items[${index}].is_taxable`, false);
        }
      });
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (!openItemsSettings) {
      getItems();
    }
  }, [openItemsSettings]);

  return (
    <div>
      <div className='mb-2'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Charges
        </Typography>
      </div>
      <SFormWrapper>
        {values.items.map((item, index) => (
          <SFormRow key={item.id}>
            <div>
              <Autocomplete
                width='250px'
                required
                label={
                  !index ? (
                    <Typography variant='c1' style={{ color: palette.gray700 }}>
                      ITEM
                    </Typography>
                  ) : null
                }
                labelKey='title'
                name={`items[${index}].item`}
                options={items}
                value={item.item}
                onChange={(e, val) => {
                  handleChange(`items[${index}].item`, val);
                  handleChange(`items[${index}].desc`, val.desc || '');
                  handleChange(`items[${index}].is_taxable`, !!val.is_taxable);
                  handleChange(
                    `items[${index}].total_amount`,
                    getItemSubtotalAmount(item.quantity, item.price, !!val.is_taxable, val.tax_percentage)
                  );
                  handleChange(
                    `items[${index}].tax_amount`,
                    getItemTaxAmount(item.quantity, item.price, !!val.is_taxable, val.tax_percentage)
                  );
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors.items?.[index]?.item}
                renderOption={(props, option) => (
                  <SOption {...props}>
                    <Typography>{option.title}</Typography>
                    <Chip label={option.code} />
                  </SOption>
                )}
              />
            </div>
            <Input
              className='w-100'
              required
              label={
                !index ? (
                  <Typography variant='c1' style={{ color: palette.gray700 }}>
                    DESCRIPTION
                  </Typography>
                ) : null
              }
              name={`items[${index}].desc`}
              value={item.desc}
              onChange={handleChange}
              error={touchedErrors.items?.[index]?.desc}
            />
            <Input
              width='80px'
              required
              type='number'
              placeholder='0'
              label={
                !index ? (
                  <Typography variant='c1' style={{ color: palette.gray700 }}>
                    QTY
                  </Typography>
                ) : null
              }
              name={`items[${index}].quantity`}
              value={item.quantity}
              onChange={(e) => {
                handleChange(e);
                handleChange(
                  `items[${index}].total_amount`,
                  getItemSubtotalAmount(e.target.value, item.price, item.is_taxable, item.item?.tax_percentage)
                );
                handleChange(
                  `items[${index}].tax_amount`,
                  getItemTaxAmount(e.target.value, item.price, item.is_taxable, item.item?.tax_percentage)
                );
              }}
              error={touchedErrors.items?.[index]?.quantity}
              onKeyDown={(e) => blockNonPositiveNumbers(e)}
            />
            <InputWithIcon
              width='150px'
              required
              type='number'
              placeholder='0.00'
              label={
                !index ? (
                  <Typography variant='c1' style={{ color: palette.gray700 }}>
                    RATE
                  </Typography>
                ) : null
              }
              name={`items[${index}].price`}
              value={item.price}
              onChange={(e) => {
                handleChange(e);
                handleChange(
                  `items[${index}].total_amount`,
                  getItemSubtotalAmount(item.quantity, e.target.value, item.is_taxable, item.item?.tax_percentage)
                );
                handleChange(
                  `items[${index}].tax_amount`,
                  getItemTaxAmount(item.quantity, e.target.value, item.is_taxable, item.item?.tax_percentage)
                );
              }}
              error={touchedErrors.items?.[index]?.price}
              onKeyDown={blockNonPositiveNumbers}
            />
            <div>
              {!index && (
                <div className='d-flex align-items-center mb-2'>
                  <Typography variant='c1' style={{ color: palette.gray700 }}>
                    TAX
                  </Typography>
                </div>
              )}
              <CustomCheckbox
                name={`items[${index}].is_taxable`}
                style={{ width: '25px', position: 'relative', top: '7px', left: '5px' }}
                checked={!!values.items[index].is_taxable}
                onChange={(checked) => {
                  if (!values.items[index].item?.is_taxable) {
                    handleOpenTaxWarning();
                    return;
                  }
                  handleChange(`items[${index}].is_taxable`, checked);
                  handleChange(
                    `items[${index}].total_amount`,
                    getItemSubtotalAmount(item.quantity, item.price, checked, item.item?.tax_percentage)
                  );
                  handleChange(
                    `items[${index}].tax_amount`,
                    getItemTaxAmount(item.quantity, item.price, checked, item.item?.tax_percentage)
                  );
                }}
              />
            </div>
            <InputWithIcon
              width='150px'
              type='number'
              placeholder='0.00'
              label={
                !index ? (
                  <Typography variant='c1' style={{ color: palette.gray700 }}>
                    SUB-TOTAL
                  </Typography>
                ) : null
              }
              name={`items[${index}].total_amount`}
              value={item.total_amount}
              disabled
            />
            <div className='icon-wrapper'>
              {index !== 0 ? (
                <span className='delete-icon' onClick={() => onDeleteRow(index)}>
                  <DeleteIcon fill={palette.indigo500} />
                </span>
              ) : (
                <div className='empty-icon' />
              )}
            </div>
          </SFormRow>
        ))}
      </SFormWrapper>
      <SAddMore onClick={onAddMore}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500 }}>
          Add Another...
        </Typography>
      </SAddMore>
      <STotalSection marginTop='10px'>
        {values?.items?.some((item) => item.is_taxable) && (
          <div className='total-row'>
            <Typography variant='c1' style={{ color: palette.gray700 }}>
              TOTAL SALES TAX
            </Typography>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {currency}
              {formatNumber(totalTax)}
            </Typography>
          </div>
        )}
        <div className='total-row'>
          <Typography variant='c1' style={{ color: palette.gray700 }}>
            GRAND TOTAL
          </Typography>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            {currency}
            {formatNumber(total)}
          </Typography>
        </div>
      </STotalSection>
      {openTaxWarning && (
        <SWarningPopup>
          <div className='d-flex align-items-center gap-1'>
            <InfoIcon />
            <Typography variant='s1' style={{ whiteSpace: 'nowrap' }}>
              This item does not have tax associated to it, please add tax details on
            </Typography>
            <div
              className='underline-text'
              onClick={(e) => {
                e.stopPropagation();
                setOpenItemsSettings(true);
              }}
            >
              Items and Services.
            </div>
          </div>
        </SWarningPopup>
      )}
      {openItemsSettings && <ItemsSettings open={openItemsSettings} onClose={() => setOpenItemsSettings(false)} />}
    </div>
  );
};

export default ChargesForm;
