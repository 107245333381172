import { number, object } from 'yup';

export const validationSchema = object().shape({
  payperiod_type: number().required('Pay period type is required').nullable(),
  week_day: object().when('payperiod_type', {
    is: (payperiod_type) => payperiod_type === 1 || payperiod_type === 2,
    then: object()
      .shape({ value: number().required('Week day is required') })
      .required('Week day is required')
      .nullable(),
    otherwise: object().shape().nullable(),
  }),
  set_payment_for: object()
    .shape({ value: number().required('This field is required') })
    .required('This field is required')
    .nullable(),
});
