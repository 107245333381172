import React, { Fragment } from 'react';
import { ReactComponent as OneTime } from 'assets/icons/createShipment/oneTime.svg';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as RecurringLane } from 'assets/icons/createShipment/recurringLane.svg';
import Radio from 'common/Radio';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import sm from '../TypeOfShipment.module.css';

const Frequency = ({ values, setFieldValue, touched, errors }) => {
  return (
    <>
      <Typography variant='s1'>How often do you want to create this shipment?</Typography>
      <div className={sm.line} />
      <div>
        <div className={sm.type_shipment_child}>
          <Radio
            label='One Time'
            name='frequency'
            id='frequency-1'
            value='1'
            checked={values.frequency === '1'}
            onChange={() => setFieldValue('frequency', '1')}
            gap='8px'
          />
          <OneTime
            style={{ marginTop: 10 }}
            fill={Number(values?.frequency) === 1 ? palette.indigo500 : palette.gray500}
          />
        </div>
        <div className={sm.line} />

        <div className={sm.type_shipment_child}>
          <Radio
            label='Recurring Lane (Create Template)'
            name='frequency'
            id='frequency-2'
            value='2'
            checked={values.frequency === '2'}
            onChange={() => setFieldValue('frequency', '2')}
            gap='8px'
          />
          <RecurringLane
            style={{ marginTop: 10 }}
            fill={Number(values?.frequency) === 2 ? palette.indigo500 : palette.gray500}
          />
        </div>
        {Number(values.frequency) === 2 && (
          <div className='d-flex align-items-start gap-3 mt-2'>
            <SubRight style={{ marginLeft: 25 }} className='mt-1' />
            <Input
              required
              width='320px'
              label='Lane Name'
              name='laneName'
              value={values.laneName}
              onChange={(e) => setFieldValue('laneName', e.target.value)}
              error={touched.laneName && errors.laneName}
            />
          </div>
        )}
        <div className={sm.line} style={{ marginTop: 16 }} />
      </div>
    </>
  );
};

export default Frequency;
