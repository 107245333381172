import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;

  .pay-period-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .settings-header {
    margin-bottom: 8px;
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;

// This is shared for deductions, taxes, benefits and additions. So be careful when changing
export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .settings-header-item:nth-child(1) {
    width: 35%;
  }

  .settings-header-item:nth-child(2) {
    width: 20%;
  }

  .settings-header-item:nth-child(3) {
    width: 35%;
  }

  .settings-header-item:nth-child(4) {
    width: 10%;
  }
`;

// This is shared for deductions, taxes, benefits and additions. So be careful when changing
export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};
  transition: background 0.3s;

  .settings-table-item:nth-child(1) {
    width: 35%;
  }

  .settings-table-item:nth-child(2) {
    width: 20%;
  }

  .settings-table-item:nth-child(3) {
    width: 35%;
  }

  .settings-table-item:nth-child(4) {
    width: 10%;
  }

  .settings-table-actions-wrapper {
    visibility: hidden;
  }

  :hover {
    background: #f7f9fc;

    .settings-table-actions-wrapper {
      visibility: visible;
    }
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const STable = styled.table`
  margin-top: 10px;
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell {
    width: 100px;

    & > div {
      visibility: hidden;
      transition: visibility 0.1s;
    }
  }

  :hover {
    background-color: ${palette.gray0};

    .actions-cell > div {
      visibility: visible;
    }
  }
`;
