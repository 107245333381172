import React, { useState } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-2.svg';
import Chip from 'common/Chip';
import ThreeDotActions from 'common/ThreeDotActions';
import { RECURRING_LANE_STATUS } from 'components/TablePlaner/constats';
import { palette } from 'utils/constants';
import { updateRecurringLane } from 'Api/Shipment';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import ConfirmModal from 'common/ConfirmModal';
import { SWrapper, SListItem } from './LaneStatus.styles';

const LaneStatus = ({ data, onSuccess }) => {
  const { shipment_lane } = data;
  const showToaster = useShowToaster();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const changeStatus = async (status) => {
    try {
      const formData = new URLSearchParams();
      formData.append('status', status);

      await updateRecurringLane(data.shipment_lane.id, formData);
      showToaster({ type: 'success', message: 'Recurring lane has been successfully updated!' });
      onSuccess();
      setOpenConfirm(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onActionClick = async (event, status) => {
    event.stopPropagation();

    if (status === shipment_lane.status) {
      return;
    }
    setAnchorEl(null);

    if (status === 'Cancelled') {
      setOpenConfirm(true);
      return;
    }

    changeStatus(status);
  };

  return (
    <SWrapper>
      <Chip
        label={shipment_lane.status?.toUpperCase()}
        bgColor={RECURRING_LANE_STATUS[shipment_lane.status]?.bgColor}
        labelColor={RECURRING_LANE_STATUS[shipment_lane.status]?.color}
        fontWeight={500}
      />
      <ThreeDotActions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        actionButton={<ArrowIcon fill={palette.gray500} className='arrow-icon' />}
        popoverProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
      >
        <SListItem
          onClick={(e) => onActionClick(e, 'Active')}
          className={shipment_lane.status === 'Active' ? 'selected' : ''}
        >
          ACTIVE
        </SListItem>
        <SListItem
          className={`delete-action ${shipment_lane.status === 'Canceled' ? 'selected' : ''}`}
          onClick={(e) => onActionClick(e, 'Cancelled')}
        >
          CANCEL
        </SListItem>
      </ThreeDotActions>
      <ConfirmModal
        open={!!openConfirm}
        title='Cancel Recurring Lane'
        text='Are you sure you want to cancel this recurring lane?'
        btnType='primary'
        btnText='Yes'
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => changeStatus('Cancelled')}
      />
    </SWrapper>
  );
};

export default LaneStatus;
