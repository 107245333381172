import axios from '../services/axios';

export async function createIncident(body) {
  const { data } = await axios.post('driver/incident', body);
  return data;
}

export async function updateIncident(id, body) {
  const { data } = await axios.put(`driver/incident/${id}`, body);
  return data;
}

export async function getStaffIncidents(params) {
  const { data } = await axios.get(`staff/incidents`, { params });
  return data;
}

export async function createStaffIncident(body) {
  const { data } = await axios.post('staff/incident', body);
  return data;
}

export async function updateStaffIncident(id, body) {
  const { data } = await axios.put(`staff/incident/${id}`, body);
  return data;
}

export async function deleteStaffIncident(id) {
  const { data } = await axios.delete(`staff/incident/${id}`);
  return data;
}

export async function getIncidentRecommendedAction(params) {
  const { data } = await axios.get('customer/incident-recommended-action', { params });
  return data;
}

export async function getActionTaken(params) {
  const { data } = await axios.get(`incidents/actiontakens`, { params });
  return data;
}

export async function createActionTaken(body) {
  const { data } = await axios.post('incidents/actiontakens', body);
  return data;
}

export async function updateActionTaken(id, body) {
  const { data } = await axios.put(`incidents/actiontakens/${id}`, body);
  return data;
}

export async function deleteActionTaken(id) {
  const { data } = await axios.delete(`incidents/actiontakens/${id}`);
  return data;
}

export async function getDriverShipments(driverId) {
  const { data } = await axios.get('customer/shipments-history', {
    params: {
      page: 1,
      itemsPerPage: 10000,
      'filters[driver_id][]': driverId,
    },
  });
  return data;
}
