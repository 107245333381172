import React from 'react';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import HeaderStar from 'components/HeaderStar';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import { toggleManageModalIsVisible, toggleUpDownGradeModalIsVisible } from 'store/reducers/billing.reducer';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getBillingDetail } from 'store/actions/billing.actions';
import { cancelDowngrade } from 'Api/Billing';
import { useAuth } from 'context/auth.context';
import CancelAccountModal from '../CancelAccountModal/CancelAccountModal';
import styles from './BillingHeader.module.css';

const accountStatus = {
  active: { label: 'Active', color: '#14804A' },
  intrial: { label: 'In Trial', color: '#14804A' },
  trialexpired: { label: 'Trial Expired', color: '#D12953' },
  pastdue: { label: 'Past Due', color: '#D12953' },
  canceled: { label: 'Canceled', color: '#464F60' },
};

const BillingHeader = ({ billingDetail, cancelAccount, setCancelAccount }) => {
  const { use } = useTheme();
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const { upDownGradeModalIsVisible, manageModalIsVisible, paymentRequired } = useSelector((state) => state.billing);
  const accountStatusData = JSON.parse(localStorage.getItem('accountStatus'));
  const { value: userData } = useAuth();

  const cancelPlanDowngrade = async () => {
    try {
      await cancelDowngrade();
      dispatch(getBillingDetail());
      showToaster({ type: 'success', message: 'User license downgrade is canceled!' });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
  };

  const duration = billingDetail?.customer_plan?.plan?.duration;

  return (
    <>
      <div className={styles.billing_header}>
        <HeaderStar title='Billing' />
        <div className={styles.info_section}>
          <div className={styles.line} />
          <div className={styles.customer_contact_wrapper}>
            <div className={styles.customer_wrapper}>
              <div>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Plan Type
                </Typography>
              </div>
              <div>
                <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {planMapper[duration]?.title}
                </Typography>
              </div>
              <div>
                <Typography
                  className={styles.blue_hover}
                  variant='c2'
                  style={{ color: use(palette.indigo500, palette.gray200) }}
                  onClick={() => {
                    if (!billingDetail) {
                      return;
                    }
                    dispatch(toggleUpDownGradeModalIsVisible(!upDownGradeModalIsVisible));
                  }}
                >
                  {(paymentRequired?.is_require_payment_enable === 0 && billingDetail?.payment_method_bypass) ||
                  (paymentRequired?.is_require_payment_enable === 0 && accountStatus.account_status === 'trialexpired')
                    ? 'Select Plan'
                    : 'Upgrade / Downgrade Plan'}
                </Typography>
              </div>
              {!!billingDetail?.customer_plan?.requested_downgrade && (
                <div className={styles.downgrade_msg}>
                  <Typography variant='c2' style={{ color: '#BD9B19' }}>
                    Pending downgrade from {billingDetail?.customer_plan?.user_licence_count} user license to{' '}
                    {billingDetail?.customer_plan?.downgrade_upgrade_truck_size} on{' '}
                    {billingDetail?.customer_plan?.next_renewal_date
                      ? formatDate(billingDetail?.customer_plan?.next_renewal_date.split('T')[0])
                      : ''}
                    .
                  </Typography>
                  <Typography
                    className={styles.red_hover}
                    variant='c2'
                    style={{ color: use(palette.red500, palette.gray200) }}
                    onClick={cancelPlanDowngrade}
                  >
                    Cancel
                  </Typography>
                </div>
              )}
              {!!billingDetail?.customer_plan?.upgrade_monthly_to_yearly && (
                <div className={styles.downgrade_msg}>
                  <Typography variant='c2' style={{ color: '#BD9B19' }}>
                    Pending upgrade to 3-year plan, awaiting agreement signature and payment.
                  </Typography>
                </div>
              )}
            </div>

            <div className={styles.contact_wrapper}>
              <div>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  User License(s)
                </Typography>
              </div>
              <div>
                <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {billingDetail?.customer_plan?.user_licence_count}
                </Typography>
              </div>
              <div>
                <Typography
                  className={styles.blue_hover}
                  variant='c2'
                  style={{ color: use(palette.indigo500, palette.gray200) }}
                  onClick={() => dispatch(toggleManageModalIsVisible(!manageModalIsVisible))}
                >
                  Manage
                </Typography>
              </div>
            </div>

            {/* <div className={styles.contact_wrapper}> */}
            {/*  <div className={styles.contact_header}> */}
            {/*    <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}> */}
            {/*      {planMapper[duration]?.billingHeaderTitle} */}
            {/*    </Typography> */}
            {/*  </div> */}
            {/*  {!!billingDetail && ( */}
            {/*    <div className={styles.contact}> */}
            {/*      <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}> */}
            {/*        ${numberWithCommas((billingDetail?.customer_plan?.amount || 0)?.toFixed(2))} */}
            {/*      </Typography> */}
            {/*    </div> */}
            {/*  )} */}
            {/*  <div */}
            {/*    className={styles.contact_header} */}
            {/*    style={{ columnGap: '16px', whiteSpace: 'nowrap', marginTop: '8px', alignItems: 'center' }} */}
            {/*  > */}
            {/*    <Typography variant='c2' style={{ color: use(palette.indigo500, palette.gray200) }}> */}
            {/*      Payment Renewal Date */}
            {/*    </Typography> */}
            {/*  </div> */}
            {/*  <div className={styles.contact}> */}
            {/*    <Typography variant='c2' style={{ color: use(palette.gray900, palette.gray200), fontWeight: '700' }}> */}
            {/*      {billingDetail?.customer_plan?.next_renewal_date */}
            {/*        ? formatDate(billingDetail.customer_plan.next_renewal_date.split('T')[0]) */}
            {/*        : ''} */}
            {/*    </Typography> */}
            {/*  </div> */}
            {/* </div> */}

            <div className={styles.contact_wrapper}>
              <div className={styles.contact_header}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Account Status
                </Typography>
              </div>
              <div className={styles.contact}>
                <Typography
                  variant='h4'
                  style={{ color: accountStatus[billingDetail?.customer_plan?.customer_status]?.color }}
                >
                  {accountStatus[billingDetail?.customer_plan?.customer_status]?.label ||
                    capitalize(billingDetail?.customer_plan?.customer_status)}
                </Typography>
              </div>
              {accountStatusData?.account_status !== 'canceled' && !userData?.user?.customer?.pending_cancellation && (
                <div className={styles.contact}>
                  <Typography
                    className={styles.red_hover}
                    variant='c2'
                    style={{ color: use(palette.red500, palette.gray200) }}
                    onClick={() => {
                      setCancelAccount(true);
                    }}
                  >
                    Cancel Account
                  </Typography>
                </div>
              )}
              {!!userData?.user?.customer?.pending_cancellation && (
                <div className={styles.contact}>
                  <Typography variant='c2' style={{ color: palette.gray700 }}>
                    Account will be canceled on {convertToCustomerTime(billingDetail?.customer_plan?.next_renewal_date)}
                  </Typography>
                </div>
              )}
            </div>

            {/* <div className={styles.contact_wrapper}> */}
            {/*  <PaymentMethod /> */}
            {/* </div> */}
          </div>
          <div className={styles.line} />
        </div>
      </div>
      {!!cancelAccount && (
        <CancelAccountModal
          show={cancelAccount}
          onClose={() => setCancelAccount(false)}
          data={billingDetail}
          onSuccess={() => dispatch(getBillingDetail())}
        />
      )}
    </>
  );
};

export default BillingHeader;
