import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onRemove, onUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'remove':
        onRemove();
        break;
      case 'update':
        onUpdate();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li onClick={(event) => onActionClick(event, 'update')}>Update</li>
      <li className='delete-action' onClick={(event) => onActionClick(event, 'remove')}>
        Remove
      </li>
    </ThreeDotActions>
  );
};

export default RowActions;
