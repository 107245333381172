import React from 'react';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import Info from './Info';
import { SColumn, SRow, SSection } from '../ViewApplicantModal.styles';

const TrafficHistory = ({ data }) => {
  const { formatDate } = useDateFormat();
  const {
    involved_in_accidents,
    accidents,
    involved_in_violations,
    traffics,
    denied_license,
    denied_statement,
    suspended_license,
    suspended_statement,
  } = data || {};

  if (!data) {
    return null;
  }

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        History of Traffic Accidents, Violations and/or Convictions
      </Typography>
      <SSection>
        <SColumn>
          <Info
            label='Were you involved in any accidents/incidents with any vehicle in the last 5 years (even if not at fault)?'
            infoText={involved_in_accidents ? 'Yes' : 'No'}
          />
          {!!involved_in_accidents && !!accidents?.length && (
            <div className='overflow-x-auto' style={{ marginTop: '-24px' }}>
              <SRow>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  NATURE OF ACCIDENT
                </Typography>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  DATE
                </Typography>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  FATALITIES
                </Typography>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  INJURIES
                </Typography>
              </SRow>
              {accidents.map((item) => (
                <SRow key={item.id}>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.accident_nature}
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.date ? formatDate(item.date) : '-'}
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.fatalities ? item.fatalities_count : '-'}
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.injuries ? item.injuries_count : '-'}
                  </Typography>
                </SRow>
              ))}
            </div>
          )}

          <Info
            label='Have you had any moving violations or traffic convictions in the past 3 years?'
            infoText={involved_in_violations ? 'Yes' : 'No'}
          />
          {!!involved_in_violations && !!traffics?.length && (
            <div className='overflow-x-auto' style={{ marginTop: '-24px' }}>
              <SRow>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  ADDRESS
                </Typography>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  DATE
                </Typography>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  CHARGE
                </Typography>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  PENALTY
                </Typography>
              </SRow>
              {traffics.map((item) => (
                <SRow key={item.id}>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.city?.name}
                    {item.city ? ',' : ''} {item.state?.name}
                    {item.state ? ',' : ''} {item.country?.sortname || item.country?.name} {item.zipcode}
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.date ? formatDate(item.date) : '-'}
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.charge || '-'}
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.penalty || '-'}
                  </Typography>
                </SRow>
              ))}
            </div>
          )}
          <Info
            label='Have you ever been denied a license, permit or privilege to operate a motor vehicle?'
            infoText={denied_license ? 'Yes' : 'No'}
          />
          {!!denied_license && (
            <div style={{ marginTop: '-18px' }}>
              <Info label='Statement' infoText={denied_statement || '-'} />
            </div>
          )}
          <Info
            label='Has any license, permit or privilege ever been suspended or revoked?'
            infoText={suspended_license ? 'Yes' : 'No'}
          />
          {!!suspended_license && (
            <div style={{ marginTop: '-18px' }}>
              <Info label='Statement' infoText={suspended_statement || '-'} />
            </div>
          )}
        </SColumn>
      </SSection>
    </div>
  );
};

export default TrafficHistory;
