import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/error.svg';
import { ReactComponent as Shipper } from 'assets/icons/onboarding/shipper.svg';
import { ReactComponent as RepairShop } from 'assets/icons/onboarding/repairShop.svg';
import { ReactComponent as FreightBroker } from 'assets/icons/onboarding/freightBorker.svg';
import { ReactComponent as CarrierBroker } from 'assets/icons/onboarding/carrierBroker.svg';
import { ReactComponent as TruckingCompany } from 'assets/icons/onboarding/truckingCompany.svg';
import { ReactComponent as DispatchCompany } from 'assets/icons/onboarding/dispatchCompany.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { SAccountTypes, SMessageBox, SWrapper } from './AccountType.styles';

const AccountType = ({ accountType, onAccountTypeChange, onContinue }) => {
  const [showNotAvailable, setShowNotAvailable] = useState(false);

  const resetStorage = () => {
    localStorage.removeItem('account');
    localStorage.removeItem('brokerInfo');
    localStorage.removeItem('goBackFromCompany');
    localStorage.removeItem('plan-selected');
    localStorage.removeItem('selected-plan');
    localStorage.removeItem('dot-num');
  };

  const onCardClick = (value) => {
    switch (value) {
      case 1:
        setShowNotAvailable(false);
        onAccountTypeChange(value);
        resetStorage();
        break;
      case 3:
        setShowNotAvailable(false);
        onAccountTypeChange(value);
        resetStorage();
        break;
      default:
        setShowNotAvailable(true);
    }
  };

  return (
    <SWrapper>
      <Typography variant='h1' className='mb-2 text-center onboarding-title'>
        Let's get started! Which best describes your business?
      </Typography>

      {showNotAvailable && (
        <SMessageBox>
          <DeleteIcon onClick={() => setShowNotAvailable(!showNotAvailable)} width={14} height={14} />
          <Typography variant='c2'>
            This product is not available at this time. Please contact sales at +1 (866) 300-7701
          </Typography>
        </SMessageBox>
      )}
      <SAccountTypes>
        <div className={`account-type-card ${accountType === 1 ? 'selected' : ''}`} onClick={() => onCardClick(1)}>
          <TruckingCompany width={50} height={50} />
          <Typography variant='s2'>Trucking Company</Typography>
        </div>
        <div className={`account-type-card ${accountType === 2 ? 'selected' : ''}`} onClick={() => onCardClick(2)}>
          <FreightBroker width={44} height={50} />
          <Typography variant='s2'>Freight Broker</Typography>
        </div>
        <div className={`account-type-card ${accountType === 3 ? 'selected' : ''}`} onClick={() => onCardClick(3)}>
          <CarrierBroker width={40} height={40} />
          <Typography variant='s2'>Carrier & Broker</Typography>
        </div>
        <div className={`account-type-card ${accountType === 4 ? 'selected' : ''}`} onClick={() => onCardClick(4)}>
          <RepairShop width={40} height={40} />
          <Typography variant='s2'>Repair Shop</Typography>
        </div>
        <div className={`account-type-card ${accountType === 5 ? 'selected' : ''}`} onClick={() => onCardClick(5)}>
          <DispatchCompany width={40} height={40} />
          <Typography variant='s2'>Dispatch Company</Typography>
        </div>
        <div className={`account-type-card ${accountType === 6 ? 'selected' : ''}`} onClick={() => onCardClick(6)}>
          <Shipper width={40} height={40} />
          <Typography variant='s2'>Shipper</Typography>
        </div>
      </SAccountTypes>
      <div className='w-100 d-flex justify-content-center'>
        <CustomButton
          type='primary'
          title='Continue'
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          styleButton={{ padding: '6px 12px', margin: 0, justifyContent: 'center', width: '370px', maxWidth: '95%' }}
          onClick={onContinue}
        />
      </div>
    </SWrapper>
  );
};

export default AccountType;
