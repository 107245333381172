import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { endOfYear, startOfYear } from 'date-fns';
import moment from 'moment';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import Search from 'common/Search';
import Loader from 'common/Loader';
import NoRecords from 'common/NoRecords';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { COST_TYPE, COST_TYPE_REPORT_ID, palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getAccountCosts } from 'Api/Payables';
import { getAccountsList, getAccountTransactions, getPayeeList } from 'Api/Accounts';
import { STableSection } from 'pages/Accounting/Payables/Payables.styles';

import AccountsList from './components/AccountsList';
import Transactions from './components/Transactions';

const Costs = ({ costTypeId }) => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  });
  const debouncedSearch = useDebounce(search, 500);

  const getTransactions = async () => {
    try {
      const params = {
        account_id: selectedAccount.account_id,
        itemsPerPage: 10000,
      };
      const [{ data: payees }, { data: accounts }] = await Promise.all([getPayeeList(), getAccountsList()]);
      const { data } = await getAccountTransactions(params);
      const onlyDebits = data.filter((i) => i.debit > 0);
      setTransactions(
        onlyDebits.map((item) => ({
          ...item,
          payee:
            (payees || []).find((i) => Number(i.id) === Number(item.payee_id) && i.type === item.payee_type) || null,
          account: (accounts || []).find((acc) => acc.id === Number(item.account)) || null,
        }))
      );
    } finally {
      setLoadingTransactions(false);
    }
  };

  const getCostsData = async () => {
    setLoadingAccounts(true);
    try {
      const params = {
        from: moment(dateRange.start).format('YYYY-MM-DD'),
        to: moment(dateRange.end).format('YYYY-MM-DD'),
        all: debouncedSearch || undefined,
        itemsPerPage: 1000,
      };
      const { data } = await getAccountCosts(costTypeId, params);
      setAccounts(data);
      setSelectedAccount(data[0] || null);
    } finally {
      setLoadingAccounts(false);
    }
  };

  useEffect(() => {
    getCostsData();
  }, [costTypeId, dateRange, debouncedSearch]);

  useEffect(() => {
    setLoadingTransactions(true);
    setTransactions([]);
    if (!selectedAccount) {
      setLoadingTransactions(false);
      return;
    }
    getTransactions();
  }, [selectedAccount]);

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mb-3 gap-2'>
        <div className='d-flex align-items-center gap-2'>
          <DateRangePicker
            top='-108px'
            initialRangeName='This Year'
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <Search search={search} onChange={setSearch} className='search-input' />
        </div>
        <div className='d-flex gap-3'>
          <CustomButton
            type='secondary'
            title='Print Report'
            leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => {
              if (costTypeId !== COST_TYPE.MAINTENANCE) {
                navigate(
                  `/reports/${COST_TYPE_REPORT_ID[costTypeId]}?startDate=${moment(dateRange.start).format(
                    'YYYY-MM-DD'
                  )}&endDate=${moment(dateRange.end).format('YYYY-MM-DD')}`
                );
              }
            }}
          />
        </div>
      </div>
      <STableSection>
        <div className='left-section'>
          {loadingAccounts ? (
            <Loader loading={loadingAccounts} />
          ) : (
            <>
              <AccountsList accounts={accounts} selected={selectedAccount} updateSelected={setSelectedAccount} />
              {!accounts?.length && <NoRecords />}
            </>
          )}
        </div>
        <div className='right-section'>
          {loadingTransactions ? (
            <Loader loading={loadingTransactions} />
          ) : (
            <>
              <Transactions account={selectedAccount} transactions={transactions} />
              {!accounts?.length && !transactions.length && <NoRecords />}
            </>
          )}
        </div>
      </STableSection>
    </div>
  );
};

export default Costs;
