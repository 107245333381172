import React from 'react';
import HeaderStar from '../../components/HeaderStar';
import DetentionTable from './components/DetentionTable';
import { SHeaderWrapper, SPageWrapper } from './Detention.styles';

const Detention = () => {
  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Detention' />
      </SHeaderWrapper>
      <DetentionTable />
    </SPageWrapper>
  );
};

export default Detention;
