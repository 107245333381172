import React from 'react';
import Modal from 'common/Modal';
import DriverPays from 'components/TableShipments/detailsRow/steps/DriverPays';
import { palette } from 'utils/constants';

const DriverPay = ({ open, onClose, onSuccess, shipment }) => {
  const { brokerage_dispatch } = shipment || {};
  const { carrier } = brokerage_dispatch || {};

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Update ${carrier ? 'Carrier' : 'Driver'} Pay`}
      className='modified-scrollbar'
      $bgColor={palette.gray0}
      $maxWidth='80vw'
      $minWidth='1200px'
      padding='0'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      <DriverPays infoHeader={shipment} getHeaderInfo={onSuccess} isModalView />
    </Modal>
  );
};

export default DriverPay;
