import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import Modal from 'common/Modal';
import Radio from 'common/Radio';
import Input from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import Autocomplete from 'common/Autocomplete';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { createCustomer, getAccountsList } from 'Api/Accounts';
import { createVendor, getNextVendorId } from 'Api/Vendors';

import { validationSchema } from './validationSchemas';
import { getInitialValues } from './AddPayee.data';

const AddPayee = ({ open, name, onClose, onSuccess, type }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [vendorId, setVendorId] = useState('');

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new URLSearchParams();
      if (values.type === 'vendor') {
        formData.append('name', values.name);
        formData.append('phone_number', values.phone_number);
        formData.append('payment_term_id', '1');
        formData.append('add_to_report', '0');
        formData.append('account_id', values.account.id);
        formData.append('address', values.address);
      } else {
        formData.append('company_name', values.name);
        formData.append(
          'telephone_number',
          values.phone_number.startsWith('+') ? values.phone_number : `+${values.phone_number}`
        );
        formData.append('status_id', '1');
        formData.append('account_id', values.account.id);
        formData.append('address1', values.address);
      }

      formData.append('address2', values.address2 || '');
      formData.append('zipcode', values.zipcode || '');
      formData.append('city_id', values.city?.id || '');
      formData.append('state_id', values.state?.id || '');
      formData.append('country_id', values.country?.id || '');

      let newPayee;

      if (values.type === 'vendor') {
        const { data: newVendor } = await createVendor(formData);
        newPayee = { ...newVendor, type: 'vendor' };
      } else {
        const { data: newCustomer } = await createCustomer(formData);

        newPayee = {
          ...newCustomer,
          name: newCustomer.company_name,
          type: 'customer',
        };
      }
      showToaster({ type: 'success', message: `${capitalize(values.type)} has been successfully created!` });
      onSuccess(newPayee);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(name, type),
    validationSchema,
    onSubmit,
  });

  const getAccountOptions = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getVendorId = async () => {
    try {
      const { data } = await getNextVendorId();
      setVendorId(data.next_id);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getVendorId();
    getAccountOptions();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Add ${capitalize(values.type)}`}
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: `Add ${capitalize(values.type)}`,
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-3'>
        {!type && (
          <div className='d-flex gap-4'>
            <Radio
              name='type'
              value='vendor'
              checked={values.type === 'vendor'}
              onChange={() => handleChange('type', 'vendor')}
              label='Vendor'
            />
            <Radio
              name='type'
              value='customer'
              checked={values.type === 'customer'}
              onChange={() => {
                handleChange('type', 'customer');
                handleChange('account', accounts.find((i) => i.account_type?.id === ACCOUNT_TYPE.INCOME) || null);
              }}
              label='Customer'
            />
          </div>
        )}
        <div className='d-flex flex-column gap-2'>
          <Input
            required
            label={`${capitalize(values.type)} Name`}
            placeholder='Name'
            name='name'
            value={values.name}
            onChange={handleChange}
            error={touchedErrors.name}
          />
          {values.type === 'vendor' && (
            <Typography variant='c1' style={{ color: palette.gray700 }}>
              Vendor ID: {vendorId}
            </Typography>
          )}
        </div>
        <PhoneInput
          name='phone_number'
          placeholder='+1 (555) 555-1234'
          label='Phone Number'
          onChange={(val) => handleChange('phone_number', val)}
          value={values.phone_number}
          error={touchedErrors.phone_number}
          required
        />
        <div>
          <Autocomplete
            required
            label='Account'
            name='account'
            labelKey='account_name'
            options={
              values.type === 'customer'
                ? accounts.filter((i) => i.account_type?.id === ACCOUNT_TYPE.INCOME)
                : accounts.filter((i) =>
                    [
                      ACCOUNT_TYPE.EXPENSE,
                      ACCOUNT_TYPE.OTHER_CURRENT_ASSET,
                      ACCOUNT_TYPE.COST_OF_GOODS_SOLD,
                      ACCOUNT_TYPE.OTHER_EXPENSE,
                    ].includes(i.account_type?.id)
                  )
            }
            value={values.account}
            onChange={(e, val) => handleChange('account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.account}
          />
        </div>
        <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
      </div>
    </Modal>
  );
};

export default AddPayee;
