import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Icons } from 'assets/icons';
import mapImage from 'assets/map.png';
import { PusherJs } from 'common/pusher';
import Autocomplete from 'common/Autocomplete';
import useDateFormat from 'hooks/useDateFormat';
import { getAllVehicles, mapPageFlag } from 'Api/Map';
import { onChangeInventoryTab } from 'store/reducers/stopPoint.reducer';
import { getAlphabet, palette, STOP_POINT_TAB } from 'utils/constants';
import ViewVehicles from 'components/Map/MapComponents/ViewVehicles';
import CustomStopPoint from 'pages/landing/Equipment/EquipmentProfile/CustomStopPoint/CustomStopPoint';
import DriverCustomCost from 'pages/landing/Equipment/EquipmentProfile/DriverCustomCost/DriverCustomCost';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { addCustomStop } from 'Api/EquipmentProfile';
import SplashScreen from '../../splashScreen/splashScreen';
import './EquipmentMap.css';

const steps = ['Gathering live coordinates..', 'Preparing map..', 'Map ready..'];

const Map = ({
  setGetCoords,
  setMyCustomMap,
  visibleTrafic,
  visibleWeather,
  stopPointVisible,
  POI,
  status,
  routeCoords,
  updatePoint,
  mapLayersValues,
  routeChange,
  radius,
  setZoomData,
  zoomData,
  routeMarkers,
  routeStopContent,
  setRouteMarkers,
  setRouteStopContent,
  stopPointAll,
  stopPointCoords,
  stopPointsData,
  firstCoords,
  firstCoordsFound,
  setEmptyMiles,
  emptyMiles,
  lastCenter,
  lastZoom,
  setLastCenter,
  setLastZoom,
  zoomId,
  locationFilterData,
  optionFilterData,
  markerAdded,
  setMarkerAdded,
  showStop,
  setShowStop,
  nextLocations,
}) => {
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const { formatDateTime } = useDateFormat();
  const [vehicleData, setVehicleData] = useState({ data: [] });
  const [coords, setCoords] = useState([]);
  const [latlng, setlatlng] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [stopPointMarker, setStopPointMarker] = useState([]);
  const [addressValues, setAddressVales] = useState([]);
  const [loadingStopPoints, setLoadingStopPoints] = useState(false);
  const [stopPointValues, setStopPointValues] = useState(null);
  const [routeValues, setRouteValues] = useState({
    open_borders: false,
    highway_only: false,
    haz_mat: false,
    avoid_tolls: false,
    route_type: { name: 'Practical', value: '0' },
  });
  const [customStopsData, setCustomStopsData] = useState(null);
  const [driverCustomCost, setDriverCustomCost] = useState(null);
  const [routeInstance, setRouteInstance] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [vehiclesToView, setVehiclesToView] = useState(null);
  const { customer } = JSON.parse(localStorage.getItem('user')) || {};
  const { dot } = customer || {};

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function init() {
      try {
        const res = await getAllVehicles(filters, source.token, locationFilterData, optionFilterData);
        setVehicleData(res);
        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          // Do nothing
        }
      }
    }

    init();

    return () => {
      source.cancel('Canceled due to stale request');
    };
  }, [filters]);

  const garlaxPopup = useRef([]);

  const removeGarlaxPopups = () => {
    if (garlaxPopup.current?.length) {
      garlaxPopup.current.forEach((item) => item.remove());
      garlaxPopup.current = [];
    }
  };

  useEffect(() => {
    if (latlng?.length === 0 || (!loading && vehicleData)) {
      setlatlng(vehicleData?.data);
    }
  }, [vehicleData, loading]);

  useEffect(() => {
    if (stopPointsData[0]?.emptyMiles) {
      setEmptyMiles(true);
    }
  }, [stopPointsData[0]?.emptyMiles]);

  useEffect(() => {
    if (!latlng) return;
    const copyEquipmentGPS = [...latlng];
    const copyDriverGPS = [...latlng];

    const equipmentGPS = copyEquipmentGPS?.filter((v) => v?.location_target === 'Equipment');
    const equipmentCoords = equipmentGPS?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
        length: c?.hook_to?.length?.length,
        unit: c?.hook_to?.length?.unit,
      },
      location_target: c?.location_target,
      latitude: c?.equipment_gps?.latitude,
      longitude: c?.equipment_gps?.longitude,
      hooked_to: !!c?.hook_to?.equipment_id,
      heading: c?.equipment_gps?.heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
      drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
      driver_id: c?.drivers ? c?.drivers[0]?.id : '',
      image: c?.drivers ? c?.drivers[0]?.image : '',
      phone_number: c?.drivers ? c?.drivers[0]?.phone_number : '',
      current_location: c?.equipment_gps?.location,
      last_update: c?.equipment_gps?.updated_at,
      motion: c?.equipment_gps?.motion,
      speed: c?.equipment_connected_services_device?.speed_mph,
      engine_state: c?.equipment_connected_services_device?.engine_state,
      checkEngin: true,
    }));
    const EquipmentGPSwithLastKnown = equipmentGPS?.filter((g) => !g.equipment_gps);

    const EquipmentGPSwithLastKnownCoords = EquipmentGPSwithLastKnown?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
        length: c?.hook_to?.length?.length,
        unit: c?.hook_to?.length?.unit,
      },
      location_target: c?.location_target,
      latitude: c?.last_known_latitude,
      longitude: c?.last_known_longitude,
      hooked_to: !!c?.hook_to?.equipment_id,
      heading: c?.last_known_heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
      driver_id: c?.drivers ? c?.drivers[0]?.id : '',
      drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
      image: c?.drivers ? c?.drivers[0]?.image : '',
      phone_number: c?.drivers ? c?.drivers[0]?.phone_number : '',
      current_location: c?.last_known_location,
      last_update: c?.last_known_update,
      speed: c?.equipment_connected_services_device?.speed_mph,
    }));
    const driverGPS = copyDriverGPS.filter((v) => v?.location_target === 'Driver');
    const CopydriversGPS = driverGPS?.filter((v) => v?.drivers);
    const driverCoords = CopydriversGPS?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
        length: c?.hook_to?.length?.length,
        unit: c?.hook_to?.length?.unit,
      },
      location_target: c?.location_target,

      latitude: c?.drivers[0]?.driver_gps?.latitude,
      longitude: c?.drivers[0]?.driver_gps?.longitude,
      hooked_to: !!c?.hook_to?.equipment_id,
      heading: c?.drivers[0]?.driver_gps?.heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
      drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
      driver_id: c?.drivers ? c?.drivers[0]?.id : '',
      image: c?.drivers ? c?.drivers[0]?.image : '',
      phone_number: c?.drivers ? c?.drivers[0]?.phone_number : '',
      current_location: c?.drivers && c?.drivers[0]?.driver_gps ? c?.drivers[0]?.driver_gps?.location : '',
      last_update: c?.drivers && c?.drivers[0]?.driver_gps ? c?.drivers[0]?.driver_gps?.updated_at : '',
      motion: c?.drivers && c?.drivers[0]?.driver_gps ? c?.drivers[0]?.driver_gps?.motion : '',
      speed: c?.drivers[0]?.driver_gps?.mph,
    }));
    const DriverGPSwithLastKnown = driverGPS?.filter((g) => !g.drivers);
    const DriverGPSwithLastKnownCopy = CopydriversGPS?.filter((g) => !g?.drivers?.driver_gps);
    const DriverGpslastknown = [...DriverGPSwithLastKnown, DriverGPSwithLastKnownCopy];
    const DriverGPSwithLastKnownCoords = DriverGpslastknown?.map((c) => ({
      id: c?.id,
      hook_to: {
        id: c?.hook_to?.id,
        equipment_id: c?.hook_to?.equipment_id,
        title: c?.hook_to?.equipment_type?.title,
        length: c?.hook_to?.length?.length,
        unit: c?.hook_to?.length?.unit,
      },
      location_target: c?.location_target,

      latitude: c?.last_known_latitude,
      longitude: c?.last_known_longitude,
      hooked_to: !!c?.hook_to?.equipment_id,
      heading: c?.last_known_heading,
      equipment_id: c?.equipment_id,
      equipment_type: c?.equipment_type?.title,
      equipment_status: c?.equipment_status,
      drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
      drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
      driver_id: c?.drivers ? c?.drivers[0]?.id : '',
      image: c?.drivers ? c?.drivers[0]?.image : '',
      phone_number: c?.drivers ? c?.drivers[0]?.phone_number : '',
      current_location: c?.last_known_location,
      last_update: c?.last_known_update,
      speed: c?.equipment_connected_services_device?.speed_mph,
    }));

    const finalCoords = [
      ...equipmentCoords,
      ...driverCoords,
      ...EquipmentGPSwithLastKnownCoords,
      ...DriverGPSwithLastKnownCoords,
    ];
    const data = finalCoords?.filter((c) => c?.latitude);
    setCoords(data);
    if (!zoomData.length) {
      setZoomData(data);
    }
  }, [latlng, loading]);

  const navigate = useNavigate();

  const dataRaw = localStorage.getItem('user');
  const data = JSON.parse(dataRaw);
  PusherJs.subscribe(`private-equipmentList-Update.${data?.customer?.dot}`);

  function deepEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  const getLiveMarker = (isHookedToVehicle, isMoving, checkEngin, engine_state) => {
    let vehicleType;
    if (isHookedToVehicle) {
      if (isMoving) {
        vehicleType = 'vehiclePlusTrailer';
      } else {
        if (checkEngin && engine_state !== 'Off') {
          vehicleType = 'greenCircleGreySquare';
        } else {
          vehicleType = 'greyVehiclePlusTrailer';
        }
      }
    } else {
      if (isMoving) {
        vehicleType = 'vehicles';
      } else {
        if (checkEngin && engine_state !== 'Off') {
          vehicleType = 'greenCircle';
        } else {
          vehicleType = 'greyVehicle';
        }
      }
    }
    return vehicleType;
  };

  function updateGeoJsonFeatures(data) {
    const features = [];

    data?.forEach((v, i) => {
      const lng = v?.longitude;
      const lat = v?.latitude;

      const speed = v?.speed?.toString().split('.')[0];
      const isMoving = speed && speed !== '0';
      const isHookedToVehicle = !!v?.hooked_to;
      const engine_state = v?.engine_state;
      const checkEngin = v?.checkEngin;
      let vehicleType;
      if (isHookedToVehicle) {
        if (isMoving) {
          vehicleType = 'vehiclePlusTrailer';
        } else {
          if (checkEngin && engine_state !== 'Off') {
            vehicleType = 'greenCircleGreySquare';
          } else {
            vehicleType = 'greyVehiclePlusTrailer';
          }
        }
      } else {
        if (isMoving) {
          vehicleType = 'vehicles';
        } else {
          if (checkEngin && engine_state !== 'Off') {
            vehicleType = 'greenCircle';
          } else {
            vehicleType = 'greyVehicle';
          }
        }
      }

      features.push({
        type: 'Feature',
        properties: {
          i,
          Type: vehicleType,
          bearing: parseInt(v.heading, 10),
          equipment_id: `${v?.equipment_id} ${v.drivers_fname ? `(${v.drivers_fname} ${v.drivers_lname})` : ''}`,
          id: v.id,
          speed,
        },
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
      });
    });
    return features;
  }

  function getGeoJsonFeatures() {
    const features = [];

    coords?.forEach((v, i) => {
      const lng = v?.longitude;
      const lat = v?.latitude;

      const speed = v?.speed?.toString().split('.')[0];
      const isMoving = speed && speed !== '0';
      const isHookedToVehicle = !!v?.hooked_to;
      const engine_state = v?.engine_state;
      const checkEngin = v?.checkEngin;
      let vehicleType;
      if (isHookedToVehicle) {
        if (isMoving) {
          vehicleType = 'vehiclePlusTrailer';
        } else {
          if (checkEngin && engine_state !== 'Off') {
            vehicleType = 'greenCircleGreySquare';
          } else {
            vehicleType = 'greyVehiclePlusTrailer';
          }
        }
      } else {
        if (isMoving) {
          vehicleType = 'vehicles';
        } else {
          if (checkEngin && engine_state !== 'Off') {
            vehicleType = 'greenCircle';
          } else {
            vehicleType = 'greyVehicle';
          }
        }
      }

      features.push({
        type: 'Feature',
        properties: {
          i,
          Type: vehicleType,
          bearing: parseInt(v.heading, 10),
          equipment_id: `${v?.equipment_id} ${v.drivers_fname ? `(${v.drivers_fname} ${v.drivers_lname})` : ''}`,
          id: v.id,
          speed,
        },
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
      });
    });
    return features;
  }

  const getGeoLocation = async ({ city, state, zip }) => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          state,
          city,
          zip,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return el.Coords;
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          showToaster({ type: 'error', message: 'Could not find location' });
          resolve(null);
        },
      });
    });
  };

  useEffect(() => {
    let timer = null;
    let interval = null;
    let GPS = null;
    let myMap = null;

    const pointsRenderListener = (evt) => {
      removeGarlaxPopups();

      if (!myMap) {
        return;
      }

      for (let i = 0; i < evt?.features?.length; i++) {
        const index = evt.features[i].properties?.i;
        const popupLocation = evt.features[i].geometry.coordinates.slice();
        const driverAssigned = !!coords[index]?.drivers_fname;
        const driverName = driverAssigned ? `(${coords[index]?.drivers_fname} ${coords[index]?.drivers_lname})` : '';
        const equipmentId = coords[index]?.equipment_id;
        const trailerId = coords[index]?.hook_to?.equipment_id;
        const vehicleTableId = coords[index]?.id;
        const trailerTableId = coords[index]?.hook_to?.id;

        const popupContent = `
            <div class='d-flex flex-column align-items-start gap-1'>
              <span id='vehicle-box-${vehicleTableId}' class='vehicle-info info-box pointer'>
                 ${equipmentId} ${driverName}
              </span>
              <span id='trailer-box-${trailerTableId}' class='trailer-info info-box pointer' style='display: ${
          trailerId ? 'inline' : 'none'
        }'>
                ${trailerId}
              </span>
            </div>
          `;

        const pop = new TrimbleMaps.Popup({ closeButton: false })
          .setLngLat(popupLocation)
          .setHTML(popupContent)
          .addTo(myMap);
        garlaxPopup.current.push(pop);
        pop.addClassName(`garlax-popup`);
        if (trailerId) {
          pop.addClassName(`with-trailer`);
        }

        const vehicle = document.getElementById(`vehicle-box-${vehicleTableId}`);
        vehicle?.addEventListener('click', () => {
          navigate(`/equipment-profile/vehicle/${vehicleTableId}`);
        });

        if (trailerTableId) {
          const trailer = document.getElementById(`trailer-box-${trailerTableId}`);
          trailer?.addEventListener('click', () => {
            navigate(`/equipment-profile/trailer/${trailerTableId}`);
          });
        }
      }
    };

    if (!loading) {
      let searchedCoords = null;

      const initializeMap = async () => {
        if (locationFilterData.allFilterData?.city && locationFilterData.allFilterData?.state) {
          searchedCoords = await getGeoLocation({
            city: locationFilterData.allFilterData.city,
            state: locationFilterData.allFilterData.state,
            zip: locationFilterData.allFilterData.postCode,
          });
        }
        TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
        myMap = new TrimbleMaps.Map({
          container: 'myMap',
          style: TrimbleMaps.Common.Style.TRANSPORTATION,
          center: new TrimbleMaps.LngLat(
            Number(searchedCoords?.Lon) || -96.7026,
            Number(searchedCoords?.Lat) || 40.8136
          ),
          zoom: !!searchedCoords?.Lon && !!searchedCoords?.Lat ? 9.4 : 4.6,
        });

        if (myMap) {
          setMyCustomMap(myMap);
          setMapInstance(myMap);
          setGetCoords(coords);
        }

        const vehicle = new Image(); // Image constructor
        vehicle.src = Icons.Vehicle;
        vehicle.alt = 'alt';

        const GreyColorVehicle = new Image(); // Image constructor
        GreyColorVehicle.src = Icons.GreyVehicle;
        GreyColorVehicle.alt = 'alt';

        const GreyVehiclePlusTrailer = new Image(); // Image constructor
        GreyVehiclePlusTrailer.src = Icons.GreyColorVehicleTrailer;
        GreyVehiclePlusTrailer.alt = 'alt';

        const GreyTrailer = new Image(); // Image constructor
        GreyTrailer.src = Icons.GreyColorTrailer;
        GreyTrailer.alt = 'alt';

        const VehiclePlusTrailer = new Image(); // Image constructor
        VehiclePlusTrailer.src = Icons.VehiclePlusTrailer;
        VehiclePlusTrailer.alt = 'alt';

        const GreenCircle = new Image();
        GreenCircle.src = Icons.GreenCircle;
        GreenCircle.alt = 'alt';

        const GreenCircleGreySquare = new Image();
        GreenCircleGreySquare.src = Icons.GreenCircleGreenSquare;
        GreenCircleGreySquare.alt = 'alt';

        const lastStopImg = new Image(); // Image constructor
        lastStopImg.src = Icons.LastStopImg;
        lastStopImg.alt = 'alt';

        const placeClickControl = new TrimbleMaps.PlaceClickControl();
        myMap.addControl(placeClickControl);

        myMap.on('load', () => {
          myMap.addImage('vehicle', vehicle);
          myMap.addImage('VehiclePlusTrailer', VehiclePlusTrailer);
          myMap.addImage('GreyColorVehicle', GreyColorVehicle);
          myMap.addImage('GreyVehiclePlusTrailer', GreyVehiclePlusTrailer);
          myMap.addImage('GreenCircleGreySquare', GreenCircleGreySquare);
          myMap.addImage('GreenCircle', GreenCircle);
          myMap.addControl(new TrimbleMaps.FullscreenControl());
          myMap.addControl(new TrimbleMaps.NavigationControl());
          myMap.addSource('randomPoints', {
            type: 'geojson',
            cluster: true,
            clusterRadius: 40,
            clusterMaxZoom: 14,
            data: {
              type: 'FeatureCollection',
              features: getGeoJsonFeatures(),
            },
          });

          myMap.addLayer({
            id: 'clusteredPoints',
            type: 'circle',
            source: 'randomPoints',
            filter: ['has', 'point_count'],
            paint: {
              'circle-radius': ['step', ['get', 'point_count'], 15, 5, 20, 50, 25],
              'circle-color': '#4f5aed',
              'circle-stroke-color': '#FFF',
              'circle-stroke-width': 3,
            },
          });

          myMap.addLayer({
            id: 'clusterCount',
            type: 'symbol',
            source: 'randomPoints',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count}',
              'text-font': ['Roboto Regular'],
              'text-size': 12,
            },
            paint: {
              'text-color': '#FFF',
            },
          });
          // let popup;
          // let closeTimeout;

          if (updatePoint) {
            myMap.getSource('randomPoints')?.setData(updatePoint);
          }

          myMap.on('render', 'hqPoints', pointsRenderListener);

          myMap.on('click', 'hqPoints', (evt) => {
            if (!evt?.features) {
              return;
            }

            const index = evt.features[0].properties?.i;
            navigate(`/equipment-profile/vehicle/${coords[index]?.id}`);
          });

          // Change cursor when hovering over a feature on the hqPoints layer
          myMap.on('mouseenter', 'hqPoints', () => {
            myMap.getCanvas().style.cursor = 'pointer';
          });

          let popupIk;
          // Listen for clicks on the truck stops layer
          myMap.on('click', 'truck-stops', (evt) => {
            if (!evt?.features?.length) {
              return;
            }

            const popupLocation = evt.features[0].geometry.coordinates.slice();
            const popupContent = `Name: ${evt.features[0].properties.name}<br />
                    ID: ${evt.features[0].properties.poi}<br />
                    Set: ${evt.features[0].properties.set}`;

            popupIk = new TrimbleMaps.Popup().setLngLat(popupLocation).setHTML(popupContent).addTo(myMap);
          });

          myMap.addLayer({
            id: 'hqPoints',
            type: 'symbol',
            source: 'randomPoints',
            filter: ['!', ['has', 'point_count']],
            layout: {
              'icon-image': [
                'match', // type of expression
                ['get', 'Type'], // property to match
                'vehicles',
                'vehicle',
                'greyVehicle',
                'GreyColorVehicle',
                'vehiclePlusTrailer',
                'VehiclePlusTrailer',
                'greyVehiclePlusTrailer',
                'GreyVehiclePlusTrailer',
                'greenCircle',
                'GreenCircle',
                'greenCircleGreySquare',
                'GreenCircleGreySquare',
                '',
              ],
              'icon-rotate': ['get', 'bearing'],
              'icon-rotation-alignment': 'auto',
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
              'text-allow-overlap': true,
              'text-font': ['Roboto Regular'],
              'text-anchor': 'bottom',
              'text-offset': [0, -2],
              'text-padding': 20,
              'text-size': 15,
              'text-transform': 'uppercase',
            },
          });

          myMap.setWeatherRadarVisibility(false);
          myMap.addLayer({
            id: 'truck-stops',
            type: 'symbol',
            source: 'Base',
            'source-layer': 'poi',
            paint: {
              'icon-halo-color': 'rgba(255, 255, 255, 1)',
              'icon-halo-width': 5,
              'icon-translate': [0, -1],
              'text-halo-color': 'rgba(255, 255, 255, 0.8)',
              'text-halo-width': 1.5,
              'text-color': 'rgba(5, 57, 150, 1)',
            },
            layout: {
              'icon-image': 'poi_truck_stop',
              'icon-size': ['interpolate', ['linear'], ['zoom'], 9, 0.7, 12, 1],
              'text-field': ['step', ['zoom'], '', 12, ['get', 'name']],
              'text-font': ['Roboto Regular'],
              'text-anchor': 'top',
              'text-size': 11,
              'text-offset': [0, 1],
            },
            filter: ['match', ['get', 'type'], ['truck_stop'], true, false],
          });
          myMap.setLayoutProperty('truck-stops', 'visibility', 'none');

          myMap.on('zoomend', () => {
            const zoom = myMap.getZoom();
            if (zoom <= 10 && popupIk && popupIk.isOpen()) {
              popupIk.remove(); // Remove popup from the map.
            }
          });
        });
        let uniqueEquipmentIds = [];

        myMap.on('move', () => {
          // Update stored center and zoom whenever the map is moved
          myMap.off('render', 'hqPoints', pointsRenderListener);
          removeGarlaxPopups();
          myMap.on('render', 'hqPoints', pointsRenderListener);
          setLastCenter(myMap.getCenter());
          setLastZoom(myMap.getZoom());
        });

        myMap.on('zoomend', () => {
          const zoom = myMap.getZoom();
          let zoomedFeatures;
          let animations = {};
          let equipmentLocations = {};
          // let firstUpdates = {};
          const lastUpdates = {};

          if (zoom >= 9.5) {
            zoomedFeatures = myMap.querySourceFeatures('randomPoints', {
              sourceLayer: 'hqPoints',
            });
            uniqueEquipmentIds = [...new Set(zoomedFeatures.map((obj) => obj.properties.id))].filter(
              (equipmentId) => equipmentId !== undefined
            );
            mapPageFlag({ equipment_id: uniqueEquipmentIds })
              .then(() => {
                GPS = PusherJs.subscribe(`private-equipmentLocation-MapPage.${dot}`);

                GPS.bind('equipment-location', (updateData) => {
                  const equipmentId = updateData.message.equipment_id;
                  const data = updateData.message.data;
                  const speed_mph = updateData.message.data.speed_mph || 0;
                  if (!lastUpdates[equipmentId] || !deepEqual(lastUpdates[equipmentId], data)) {
                    lastUpdates[equipmentId] = data; // Save new update

                    equipmentLocations[equipmentId] = {
                      coordinates: [data.longitude, data.latitude],
                      bearing: data.heading,
                      duration: +speed_mph * 150,
                      is_hooked: data.is_hooked,
                      engine_state: data.engine_state,
                    };
                  }

                  if (myMap && myMap.getSource('randomPoints')) {
                    window.cancelAnimationFrame(timer);
                    timer = window.requestAnimationFrame(updateEquipmentLocations.bind(this, speed_mph));
                  }
                });

                function updateEquipmentLocations(speed_mph, timestamp) {
                  const zoom = myMap.getZoom();
                  if (zoom < 9.5) {
                    return;
                  }

                  const sourceData = myMap.getSource('randomPoints')?._data;
                  const newData = sourceData?.features?.map((item) => {
                    if (equipmentLocations.hasOwnProperty(item.properties.id)) {
                      const { coordinates, bearing, duration, is_hooked, engine_state } =
                        equipmentLocations[item.properties.id];
                      let oldCoordinates;
                      const feature = sourceData?.features?.find(
                        (feature) => feature.properties.id === item.properties.id
                      );
                      if (feature) {
                        oldCoordinates = feature.geometry.coordinates;
                      }

                      if (!animations[item.properties.id] && oldCoordinates) {
                        animations[item.properties.id] = {
                          startTime: timestamp,
                          startCoordinates: oldCoordinates,
                          endCoordinates: coordinates,
                          duration,
                        };
                      }

                      if (animations[item.properties.id]) {
                        let progress =
                          duration === 0
                            ? 1
                            : (timestamp - animations[item.properties.id].startTime) /
                              animations[item.properties.id].duration;

                        if (progress > 1) {
                          progress = 1;
                          delete animations[item.properties.id];
                        }

                        if (animations[item.properties.id]) {
                          const newCoordinates = [
                            animations[item.properties.id].startCoordinates[0] * (1 - progress) +
                              coordinates[0] * progress,
                            animations[item.properties.id].startCoordinates[1] * (1 - progress) +
                              coordinates[1] * progress,
                          ];

                          const isMoving = duration > 150;
                          const type = getLiveMarker(is_hooked, isMoving, !!engine_state, engine_state);

                          return {
                            type: 'Feature',
                            properties: {
                              ...item.properties,
                              Type: type,
                              bearing,
                              speed: speed_mph,
                            },
                            geometry: {
                              type: 'Point',
                              coordinates: newCoordinates,
                            },
                          };
                        }
                      }
                    }

                    return item;
                  });

                  const updatedPoints = {
                    ...sourceData,
                    features: newData,
                  };

                  myMap.getSource('randomPoints')?.setData(updatedPoints);

                  if (Object.keys(equipmentLocations).length > 0 || Object.keys(animations).length > 0) {
                    timer = window.requestAnimationFrame(updateEquipmentLocations.bind(this, speed_mph));
                  }
                }
              })
              .catch(() => {
                // Do nothing
              });
          } else {
            if (timer) {
              window.cancelAnimationFrame(timer);
              timer = null;
            }
            animations = null;
            equipmentLocations = null;
            myMap.off('render', 'hqPoints', pointsRenderListener);

            if (uniqueEquipmentIds.length) {
              mapPageFlag({
                equipment_id: uniqueEquipmentIds,
                delete_flag: true,
              }).catch(() => {
                // Do nothing
              });
            }
            GPS && GPS.unsubscribe(`private-equipmentLocation-MapPage.${dot}`);
          }
        });

        const updateFeatures = async () => {
          try {
            if (myMap && myMap?.getLayer('hqPoints') && !zoomId) {
              const res = await getAllVehicles(filters, undefined, locationFilterData, optionFilterData);
              const copyEquipmentGPS = [...res.data];
              const copyDriverGPS = [...res.data];

              const equipmentGPS = copyEquipmentGPS?.filter((v) => v?.location_target === 'Equipment');
              const equipmentCoords = equipmentGPS?.map((c) => ({
                id: c?.id,
                hook_to: {
                  id: c?.hook_to?.id,
                  equipment_id: c?.hook_to?.equipment_id,
                  title: c?.hook_to?.equipment_type?.title,
                  length: c?.hook_to?.length?.length,
                  unit: c?.hook_to?.length?.unit,
                },
                location_target: c?.location_target,
                latitude: c?.equipment_gps?.latitude,
                longitude: c?.equipment_gps?.longitude,
                hooked_to: !!c?.hook_to?.equipment_id,
                heading: c?.equipment_gps?.heading,
                equipment_id: c?.equipment_id,
                equipment_type: c?.equipment_type?.title,
                equipment_status: c?.equipment_status,
                drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
                drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
                current_location: c?.equipment_gps?.location,
                last_update: c?.equipment_gps?.updated_at,
                speed: c?.equipment_connected_services_device?.speed_mph,
                engine_state: c?.equipment_connected_services_device?.engine_state,
                checkEngin: true,
              }));
              const EquipmentGPSwithLastKnown = equipmentGPS?.filter((g) => !g.equipment_gps);

              const EquipmentGPSwithLastKnownCoords = EquipmentGPSwithLastKnown?.map((c) => ({
                id: c?.id,
                hook_to: {
                  id: c?.hook_to?.id,
                  equipment_id: c?.hook_to?.equipment_id,
                  title: c?.hook_to?.equipment_type?.title,
                  length: c?.hook_to?.length?.length,
                  unit: c?.hook_to?.length?.unit,
                },
                location_target: c?.location_target,
                latitude: c?.last_known_latitude,
                longitude: c?.last_known_longitude,
                hooked_to: !!c?.hook_to?.equipment_id,
                heading: c?.last_known_heading,
                equipment_id: c?.equipment_id,
                equipment_type: c?.equipment_type?.title,
                equipment_status: c?.equipment_status,
                drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
                drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
                current_location: c?.last_known_location,
                last_update: c?.last_known_update,
                speed: c?.equipment_connected_services_device?.speed_mph,
              }));
              const driverGPS = copyDriverGPS.filter((v) => v?.location_target === 'Driver');
              const CopydriversGPS = driverGPS?.filter((v) => v?.drivers);
              const driverCoords = CopydriversGPS?.map((c) => ({
                id: c?.id,
                hook_to: {
                  id: c?.hook_to?.id,
                  equipment_id: c?.hook_to?.equipment_id,
                  title: c?.hook_to?.equipment_type?.title,
                  length: c?.hook_to?.length?.length,
                  unit: c?.hook_to?.length?.unit,
                },
                location_target: c?.location_target,

                latitude: c?.drivers[0]?.driver_gps?.latitude,
                longitude: c?.drivers[0]?.driver_gps?.longitude,
                hooked_to: !!c?.hook_to?.equipment_id,
                heading: c?.drivers[0]?.driver_gps?.heading,
                equipment_id: c?.equipment_id,
                equipment_type: c?.equipment_type?.title,
                equipment_status: c?.equipment_status?.equipment_status,
                drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
                drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
                current_location: c?.drivers && c?.drivers[0]?.driver_gps ? c?.drivers[0]?.driver_gps?.location : '',
                last_update: c?.drivers && c?.drivers[0]?.driver_gps ? c?.drivers[0]?.driver_gps?.updated_at : '',
                speed: c?.drivers[0]?.driver_gps?.mph,
              }));
              const DriverGPSwithLastKnown = driverGPS?.filter((g) => !g.drivers);
              const DriverGPSwithLastKnownCopy = CopydriversGPS?.filter((g) => !g?.drivers?.driver_gps);
              const DriverGpslastknown = [...DriverGPSwithLastKnown, DriverGPSwithLastKnownCopy];
              const DriverGPSwithLastKnownCoords = DriverGpslastknown?.map((c) => ({
                id: c?.id,
                hook_to: {
                  id: c?.hook_to?.id,
                  equipment_id: c?.hook_to?.equipment_id,
                  title: c?.hook_to?.equipment_type?.title,
                  length: c?.hook_to?.length?.length,
                  unit: c?.hook_to?.length?.unit,
                },
                location_target: c?.location_target,

                latitude: c?.last_known_latitude,
                longitude: c?.last_known_longitude,
                hooked_to: !!c?.hook_to?.equipment_id,
                heading: c?.last_known_heading,
                equipment_id: c?.equipment_id,
                equipment_type: c?.equipment_type?.title,
                equipment_status: c?.equipment_status?.equipment_status,
                drivers_fname: c?.drivers ? c?.drivers[0]?.fname : '',
                drivers_lname: c?.drivers ? c?.drivers[0]?.lname : '',
                current_location: c?.last_known_location,
                last_update: c?.last_known_update,
                speed: c?.equipment_connected_services_device?.speed_mph,
              }));

              const finalCoords = [
                ...equipmentCoords,
                ...driverCoords,
                ...EquipmentGPSwithLastKnownCoords,
                ...DriverGPSwithLastKnownCoords,
              ];
              const data = finalCoords?.filter((c) => c?.latitude);

              const newFeatures = updateGeoJsonFeatures(data);
              const vazgen = getGeoJsonFeatures();
              const mergedFeatures = vazgen.map((item) => {
                const updated = newFeatures?.find((i) => i.properties.equipment_id === item.properties.equipment_id);
                if (updated) {
                  return updated;
                }
                return item;
              });

              const sourceData = myMap.getSource('randomPoints')._data;

              const updatedData = {
                ...sourceData,
                features: mergedFeatures,
              };
              myMap.getSource('randomPoints')?.setData(updatedData);
            }
          } catch (err) {
            if (!axios.isCancel(err)) {
              // Do nothing
            }
          }
        };

        interval = setInterval(() => updateFeatures(), 90000);
      };

      initializeMap();
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (timer) {
        window.cancelAnimationFrame(timer);
        timer = null;
      }
      if (GPS) {
        GPS.unsubscribe(`private-equipmentLocation-MapPage.${dot}`);
      }
      if (myMap) {
        myMap.off('render', 'hqPoints', pointsRenderListener);
        removeGarlaxPopups();
      }
    };
  }, [coords, loading, mapLayersValues]);

  const array = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const routemarker = [];
  const routeContent = [];

  useEffect(() => {
    if (firstCoords.length === 1) {
      if (radius) return;
      if (!mapInstance) return;
      if (routeCoords.length >= 2) {
        setTimeout(() => {
          // if (!emptyMiles) return;
          routeCoords?.map((tracking, i) => {
            const city = tracking?.city;
            const state = tracking?.state;
            const zipcode = tracking?.zipcode;
            const streetAddress = tracking?.streetAddress;

            const el = document.createElement('div');
            el.classList.add(`black-marker${i}`);
            const el2 = document.createElement('div');
            el.classList.add(`<black-marker1>i+30</black-marker1>`);

            if (i !== 0) {
              const htmlContent = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;

              const htmlContent2 = `<div style='text-transform: uppercase; color: white; background:#2196F3; box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 15px 35px rgba(17, 24, 38, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.08);border-radius: 4px;margin-bottom:10px;width: max-content;height: 20px;left:30px;position:absolute;top:-20px;padding:10px;display:flex;'
              '><span style="font-family: Inter;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">
              ${i !== 0 ? `Stop ${array[i - 1]}` : ''}: </span>
                <span style="font-family: Inter;margin-left:3px ;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">
                ${streetAddress ? `${streetAddress},` : ''}
                ${city ? `${city}` : ''}
                ${state ? `,${state}` : ''}
                ${zipcode ? `,${zipcode}` : ''}</span></div>`;
              el.innerHTML = htmlContent;
              el2.innerHTML = htmlContent2;
            } else {
              el.innerHTML = `<div style='text-transform: uppercase; color: white;background: #AA5B00;box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 15px 35px rgba(17, 24, 38, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.08);border-radius: 4px;margin-bottom:10px;width: 160px;height: 20px;left:30px;position:absolute;top:-20px;padding:10px;display:flex;'>
                                        <span style="font-family: Inter;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">Dead Head - ${stopPointsData[0]?.emptyMiles?.toFixed()} Miles</span>
                                  </div>`;
            }
            let marker;
            if (!markerAdded) {
              marker = new TrimbleMaps.Marker({
                color: 'blue',
                element: el,
              })
                .setLngLat([tracking?.longitude, tracking?.latitude])
                .addTo(mapInstance);

              new TrimbleMaps.Marker({
                color: 'blue',
                element: el2,
              })
                .setLngLat([tracking?.longitude, tracking?.latitude])
                .addTo(mapInstance);
            }

            setMarkerAdded(true);
            routeContent.push(marker);
            setRouteMarkers(routemarker);
            setRouteStopContent(routeContent);
            return marker;
          });
        }, 3000);
        setTimeout(() => {
          const myRoute = new TrimbleMaps.Route({
            routeId: `myRoute_${Math.random()}`,
            isDraggable: false,
            stops: [
              ...(routeCoords || []).map((tracking) => new TrimbleMaps.LngLat(tracking?.longitude, tracking?.latitude)),
            ],
            frameRoute: true,
            showStops: false,
            routeType:
              routeValues.route_type?.value === '0'
                ? TrimbleMaps.Common.RouteType.PRACTICAL
                : routeValues.route_type?.value === '1'
                ? TrimbleMaps.Common.RouteType.SHORTEST
                : TrimbleMaps.Common.RouteType.SHORTEST,
            routeOption: {
              highwayOnly: routeValues?.highway_only,
              bordersOpen: routeValues?.open_borders,
            },
            routeColor: '#4F5AED',
            routeWidth: 5,
            bordersOpen: routeValues?.open_borders,
            tollRoads:
              routeValues?.avoid_tolls === true
                ? TrimbleMaps.Common.TollRoadsType.AVOID_IF_POSSIBLE
                : TrimbleMaps.Common.TollRoadsType.NONE,
            hazMatType:
              routeValues?.hazmat === true ? TrimbleMaps.Common.HazMatType.GENERAL : TrimbleMaps.Common.HazMatType.NONE,
            destinationIcon: {
              size: 1,
              url: 'https://developer.trimblemaps.com/maps-sdk/img/marker_blue.png',
              offset: [0, -6],
            },
          });
          setRouteInstance(myRoute);
          myRoute.addTo(mapInstance);
        }, 3000);
      }
    }
  }, [routeCoords, routeChange, routeValues, mapInstance, firstCoords, emptyMiles]);

  function handleSpanClick(stopId, count, equipmentType) {
    dispatch(onChangeInventoryTab(equipmentType));
    navigate(`/stop-point/${stopId}/${STOP_POINT_TAB.INVENTORY}`);
  }

  const StopPointArr = [];
  useEffect(() => {
    if (!mapInstance) return;
    if (stopPointVisible) {
      if (stopPointAll.length > 1) {
        stopPointAll?.forEach((StopPointsData, i) => {
          const address1 = StopPointsData?.value?.address1;
          const address2 = StopPointsData?.value?.address2;
          const city = StopPointsData?.value?.city;
          const state = StopPointsData?.value?.state;
          const zipcode = StopPointsData?.value?.zipcode;
          const country = StopPointsData?.value?.country;
          const StopPointName = StopPointsData?.value?.current_location;
          const stop_point_vehicles_count = StopPointsData?.value?.stop_point_vehicles_count;
          const stop_point_trailers_count = StopPointsData?.value?.stop_point_trailers_count;
          const stopId = StopPointsData?.id;

          const el = document.createElement('div');
          el.classList.add(`black-marker${i}`);
          // Create a popup
          const popup = new TrimbleMaps.Popup({
            offset: 0,
          }).setHTML(
            `<span style="font-family: 'Inter', sans-serif; font-size: 18px; font-weight: bold; color: #000000; display: block; margin-bottom: 10px;">${StopPointName}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${address1}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${address2}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${city}, ${state} ${zipcode}</span>
            <span style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">${country}</span>
            <div style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">Current Vehicles - <span style="font-weight: bold; color: #4F5AED; cursor: pointer" id='equipment-map-page-vehicle-in-stop'>${stop_point_vehicles_count}</span></div>
            <div style="font-family: 'Inter', sans-serif; display: block; margin-bottom: 5px; font-size: 14px;">Current Trailers - <span style="font-weight: bold; color: #4F5AED; cursor: pointer" id='equipment-map-page-trailer-in-stop'>${stop_point_trailers_count}</span></div>
            `
          );

          popup.on('open', () => {
            const vehicleSpan = document.getElementById('equipment-map-page-vehicle-in-stop');
            vehicleSpan?.addEventListener('click', () => handleSpanClick(stopId, stop_point_vehicles_count, 1));

            const trailerSpan = document.getElementById('equipment-map-page-trailer-in-stop');
            trailerSpan?.addEventListener('click', () => handleSpanClick(stopId, stop_point_trailers_count, 2));
          });

          const ele = document.createElement('div');
          ele.classList.add(`cust-marker${i}`);
          ele.innerHTML = `<div id='image' style='background: url(${Icons.StopPoint}) no-repeat; height: 26px; width: 26px;'/>`;
          const marker2 = new TrimbleMaps.Marker({
            element: ele,
            color: 'red',
          })
            .setLngLat([StopPointsData?.value?.longitude, StopPointsData?.value?.latitude])
            .setPopup(popup)
            .addTo(mapInstance);
          StopPointArr.push(marker2);

          setStopPointMarker(StopPointArr);
        });
      }
    } else {
      if (stopPointMarker.length) {
        stopPointMarker?.map((M) => M.remove());
      }
    }
  }, [mapInstance, stopPointAll, stopPointVisible]);

  const locations = useRef([]);
  useEffect(() => {
    if (locations.current.length) {
      locations.current.forEach((item) => {
        item.remove();
      });
      locations.current = [];
    }

    if (!mapInstance) return;
    if (status === 'available' && nextLocations.length) {
      nextLocations?.forEach((location, i) => {
        const popupContent =
          location.vehicles?.length > 1
            ? `
            <div class='d-flex flex-column align-items-start gap-1'>
                <span id='open-vehicles-list-${i}' class='vehicle-info info-box pointer text-decoration-underline'>
                  Multiple Vehicles
                </span>
            </div>
          `
            : `
            <div class='d-flex flex-column align-items-start gap-1'>
              ${location.vehicles.map(
                (item) => `
                <span id='equipment-info-${item.id}' class='vehicle-info info-box pointer'>
                  ${item.equipment_id} (${item.driver?.first_name || ''} ${item.driver?.last_name || ''}) <br />
                  ${
                    item.next_available_date
                      ? formatDateTime(item.next_available_date.replace('Z', '').split('T').join(' '))
                      : ''
                  }
                </span>
              `
              )}
            </div>
          `;

        const popup = new TrimbleMaps.Popup({ closeButton: false, className: 'garlax-popup' }).setHTML(popupContent);
        popup.on('open', () => {
          const vehicle = document.getElementById(`open-vehicles-list-${i}`);
          vehicle?.addEventListener('click', () => {
            setVehiclesToView(location.vehicles);
          });
        });

        const ele = document.createElement('div');
        ele.classList.add(`next-location-marker${i}`);
        ele.innerHTML = `<div id='image' style='background: url(${Icons.StopPointGreen}) no-repeat; height: 26px; width: 26px;'/>`;
        const marker = new TrimbleMaps.Marker({
          element: ele,
          color: 'red',
        })
          .setLngLat([location?.lng, location.lat])
          .setPopup(popup)
          .addTo(mapInstance);
        locations.current.push(marker);
      });
    }
  }, [mapInstance, nextLocations, status]);

  useEffect(() => {
    if (radius) {
      if (!mapInstance) return;
      if (stopPointCoords.length >= 1) {
        setTimeout(() => {
          stopPointCoords?.forEach((stopPointValue, i) => {
            const ele = document.createElement('div');
            ele.classList.add(`cust-marker${i}`);
            const htmlContent_1 = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;
            ele.innerHTML = htmlContent_1;
            new TrimbleMaps.Marker({
              element: ele,
              color: 'red',
            })
              .setLngLat([stopPointValue?.longitude, stopPointValue?.latitude])
              .addTo(mapInstance);
          });
        }, 3000);
      }
    }
  }, [coords, mapInstance, stopPointCoords]);

  useEffect(() => {
    if (!mapInstance) return;
    mapLayersValues && mapLayersValues === 'SATELLITE'
      ? mapInstance.setStyle(TrimbleMaps.Common.Style.SATELLITE)
      : mapLayersValues === 'TRANSPORTATION_DARK'
      ? mapInstance.setStyle(TrimbleMaps.Common.Style.TRANSPORTATION_DARK)
      : mapInstance.setStyle(TrimbleMaps.Common.Style.BASIC);
    if (!Object.values(locationFilterData || {})?.length) {
      mapInstance.setCenter(lastCenter);
      mapInstance.setZoom(lastZoom);
    }
  }, [mapLayersValues, mapInstance]);

  useEffect(() => {
    if (!mapInstance) return;

    if (mapInstance && mapInstance.isStyleLoaded()) {
      const trafficVisible = mapInstance.isTrafficVisible();
      const radarVisible = mapInstance.isWeatherRadarVisible();
      const poiVisible = mapInstance?.getLayer('truck-stops');
      if (visibleTrafic) {
        mapInstance.setTrafficVisibility(true);
      } else if (trafficVisible) {
        mapInstance.setTrafficVisibility(false);
      }
      if (visibleWeather) {
        mapInstance.setWeatherRadarVisibility(true);
      } else if (radarVisible) {
        mapInstance.setWeatherRadarVisibility(false);
      }
      if (POI) {
        mapInstance.setLayoutProperty('truck-stops', 'visibility', 'visible');
      } else if (poiVisible) {
        mapInstance.setLayoutProperty('truck-stops', 'visibility', 'none');
      }
    }
  }, [visibleTrafic, mapInstance, visibleWeather, POI]);

  useEffect(() => {
    const mapFilters = [];
    if (status) {
      mapFilters.push(status === 'next_location' ? 'on_shipment' : status);
    }
    setLoading(true);
    setFilters(mapFilters);
  }, [status, locationFilterData, optionFilterData]);

  useEffect(() => {
    if (firstCoordsFound) return;
    if (firstCoords.length === 1) return;
    if (radius) return;
    if (!mapInstance) return;
    if (routeCoords.length >= 1) {
      setTimeout(() => {
        routeCoords?.forEach((tracking, i) => {
          const city = tracking?.city;
          const state = tracking?.state;
          const zipcode = tracking?.zipcode;
          const streetAddress = tracking?.streetAddress;
          const el = document.createElement('div');
          el.classList.add(`black-marker${i}`);
          const htmlContent = `<div style='text-transform: uppercase; color: white; background:#2196F3; box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 15px 35px rgba(17, 24, 38, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.08);border-radius: 4px;margin-bottom:10px;width: max-content;height: 20px;left:30px;position:absolute;top:-20px;padding:10px;display:flex;'
            '><span style="font-family: Inter;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">
            ${`Stop ${array[i]}`}: </span>
              <span style="font-family: Inter;margin-left:3px ;font-style: normal;font-weight: 500;font-size: 11px;line-height: 16px;position: relative;top: -8px;">
           ${streetAddress ? `${streetAddress},` : ''}
            ${city ? `${city}` : ''}
            ${state ? `,${state}` : ''}
            ${zipcode ? `,${zipcode}` : ''}
            </span></div>`;
          el.innerHTML = htmlContent;
          const marker = new TrimbleMaps.Marker({
            color: 'blue',
            element: el,
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])

            .addTo(mapInstance);
          const ele = document.createElement('div');
          ele.classList.add(`cust-marker${i}`);
          const htmlContent1 = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;
          ele.innerHTML = htmlContent1;
          const marker2 = new TrimbleMaps.Marker({
            element: ele,
            color: 'red',
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])
            .addTo(mapInstance);
          routemarker.push(marker2);
          routeContent.push(marker);
          setRouteMarkers(routemarker);
          setRouteStopContent(routeContent);
        });
      }, 3000);
    } else {
      if (routeMarkers.length) {
        routeMarkers?.map((M) => M.remove());
      }
      if (routeStopContent?.length) {
        routeStopContent?.map((m) => m?.remove());
      }
    }
  }, [routeCoords, routeChange, routeValues, mapInstance, firstCoords]);

  const clearRoute = (leaveEquipment) => {
    if (routeStopContent?.length) {
      routeStopContent?.map((m) => m?.remove());
    }
    if (routeInstance) {
      routeInstance.remove();
    }
    if (!leaveEquipment) {
      setEquipment(null);
    }
    // setRouteOptions({
    //   open_borders: false,
    //   highway_only: false,
    //   haz_mat: false,
    //   avoid_tolls: false,
    // })
    setDriverCustomCost(null);
    setCustomStopsData(null);
    setRouteInstance(null);
    setRouteStopContent([]);
  };

  const addStopPointsRoutes = (data, addresses, options) => {
    const pointsData = data.stopPointLatLong?.map((item) => ({ ...item }));

    const routeCoords = [...pointsData];

    if (equipment) {
      const { location_target, drivers, equipment_gps, last_known_latitude, last_known_longitude } = equipment;

      const latitude =
        (location_target === 'Driver' ? drivers?.[0]?.driver_gps?.latitude : equipment_gps?.latitude) ||
        last_known_latitude;
      const longitude =
        (location_target === 'Driver' ? drivers?.[0]?.driver_gps?.longitude : equipment_gps?.longitude) ||
        last_known_longitude;

      if (!latitude && !longitude) {
        showToaster({ type: 'error', message: 'Selected equipment has no location data!' });
      }
      routeCoords.unshift({ latitude, longitude });
    }

    if (routeCoords.length >= 2) {
      setTimeout(() => {
        routeCoords?.map((tracking, i) => {
          // - 1 because first item is driver data
          const city = addresses[i - equipment ? 1 : 0]?.city;
          const state = addresses[i - equipment ? 1 : 0]?.state;
          const zipcode = addresses[i - equipment ? 1 : 0]?.zipcode;
          const streetAddress = addresses[i - equipment ? 1 : 0]?.streetAddress;

          const el = document.createElement('div');
          el.classList.add(`black-marker${i}`);
          const el2 = document.createElement('div');
          el.classList.add(`<black-marker1>i+30</black-marker1>`);

          if (i !== 0 || !equipment) {
            const htmlContent = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;

            const htmlContent2 = `<div class="equipment-profile-address-wrapper"><span class="equipment-profile-address-inner"> ${`Stop ${getAlphabet(
              i - equipment ? 1 : 0
            )}`}: </span>
                <span class="equipment-profile-address">
                ${streetAddress ? `${streetAddress}, ` : ''}
                ${city ? `${city}, ` : ''}
                ${state ? `${state}, ` : ''}
                ${zipcode ? `${zipcode}` : ''}</span></div>`;
            el.innerHTML = htmlContent;
            el2.innerHTML = htmlContent2;
          } else {
            el.innerHTML = `<div class="equipment-profile-deadhead-wrapper">
                                        <span class="equipment-profile-deadhead-inner">Dead Head - ${Number(
                                          data.emptyMiles
                                        )?.toFixed()} Miles</span>
                                  </div>`;
          }

          const marker = new TrimbleMaps.Marker({
            color: 'blue',
            element: el,
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])
            .addTo(mapInstance);

          const marker2 = new TrimbleMaps.Marker({
            color: 'blue',
            element: el2,
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])
            .addTo(mapInstance);

          routeContent.push(marker);
          routeContent.push(marker2);
          setRouteStopContent(routeContent);
          return marker;
        });
      }, 300);
      let determinedRouteType = options?.route_type?.value;

      switch (routeValues.route_type?.value) {
        case '0':
          determinedRouteType = TrimbleMaps.Common.RouteType.PRACTICAL;
          break;
        case '1':
          determinedRouteType = TrimbleMaps.Common.RouteType.SHORTEST;
          break;
        case '2':
          determinedRouteType = TrimbleMaps.Common.RouteType.FASTEST;
          break;
        default:
          determinedRouteType = TrimbleMaps.Common.RouteType.PRACTICAL;
      }
      const myRoute = new TrimbleMaps.Route({
        routeId: `myRoute_${Math.random()}`,
        isDraggable: false,
        stops: routeCoords.map((tracking) => new TrimbleMaps.LngLat(tracking?.longitude, tracking?.latitude)),
        frameRoute: true,
        showStops: false,
        routeType: determinedRouteType,
        highwayOnly: !!options?.highway_only,
        tollRoads: options?.avoid_tolls ? 2 : 3,
        bordersOpen: !!options?.open_borders,
        hazMatType: options?.haz_mat ? 1 : 0,
        routeColor: '#4F5AED',
        routeWidth: 5,
        destinationIcon: {
          size: 1,
          url: 'https://developer.trimblemaps.com/maps-sdk/img/marker_blue.png',
          offset: [0, -6],
        },
      });
      setRouteInstance(myRoute);
      myRoute.addTo(mapInstance);
      setLoadingStopPoints(false);
      // setCostOpen(true);
    }
  };

  const addStopPoint2 = async (data, options) => {
    if (data.length === 1 && !equipment) {
      showToaster({ type: 'error', message: 'Please select vehicle or add one more stop to create route!' });
      setLoadingStopPoints(false);
      return;
    }

    clearRoute(true);
    setCustomStopsData(data);
    let CustomData = null;
    const values = {
      equipment_id: equipment?.id,
      stop_points: data,
      radius: null,
      mode:
        options.route_type?.value === '0' ? 'practical' : options.route_type?.value === '1' ? 'shortest' : 'fastest',
      open_borders: options?.open_borders,
      highway_only: options?.highway_only,
      hazmat: options?.haz_mat,
      avoid_tolls: options?.avoid_tolls,
    };
    try {
      const res = await addCustomStop(values);
      let data;
      if (res?.data) {
        data = res?.data;
      } else {
        data = res;
      }
      CustomData = data;
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
      setLoadingStopPoints(false);
    }
    setDriverCustomCost(CustomData);
    addStopPointsRoutes(CustomData, values.stop_points, options);
  };

  return (
    <>
      {loading && (
        <div style={{ height: '100%', width: '100%', backgroundImage: `url(${mapImage})` }}>
          <SplashScreen steps={steps} />
        </div>
      )}
      {!!vehiclesToView && (
        <ViewVehicles open={!!vehiclesToView} onClose={() => setVehiclesToView(false)} vehicles={vehiclesToView} />
      )}
      <div id='myMap' style={{ height: '100%', width: '100%' }} />
      {!!showStop && (
        <div className='d-flex ms-3' style={{ position: 'absolute', top: '28px', zIndex: 2 }}>
          <div
            className='overlay-equipment-details-container-stops'
            style={{
              width: customStopsData && driverCustomCost ? '33vw' : '676px',
            }}
          >
            <div className='d-flex justify-content-between'>
              <p
                className='overlay-content mb-1'
                style={{
                  color: palette.dark800,
                }}
              >
                Create Route
              </p>
              <img
                onClick={() => setShowStop(false)}
                className='overlay-menu-icon'
                style={{
                  position: 'absolute',
                  right: '6px',
                  top: '0',
                }}
                src={Icons.menuOndisplay}
                alt='menu'
              />
            </div>
            <Autocomplete
              width='300px'
              label='Vehicle'
              options={vehicleData.data}
              value={equipment}
              onChange={(e, val) => setEquipment(val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                `${option.equipment_id} ${option.equipment_type.title}${
                  option.equipment_type.title === 'Truck' ? ' (Sleeper)' : ''
                }`
              }
            />
            <CustomStopPoint
              addressValues={addressValues}
              setAddressVales={setAddressVales}
              addStopPoint={addStopPoint2}
              stopPointValues={stopPointValues}
              setStopPointValues={setStopPointValues}
              loadingStopPoints={loadingStopPoints}
              setLoadingStopPoints={setLoadingStopPoints}
              clearRoute={clearRoute}
              routeInstance={routeInstance}
              routeValues={routeValues}
              setRouteValues={setRouteValues}
            />
          </div>
          {customStopsData && driverCustomCost && (
            <div style={{ width: '21vw', marginLeft: '16px' }} className='overlay-equipment-details-container-stops'>
              <div className='d-flex justify-content-between'>
                <p
                  className='overlay-content mb-1'
                  style={{
                    color: palette.dark800,
                  }}
                >
                  TRIP SUMMARY
                </p>
                <img
                  onClick={() => setShowStop(false)}
                  className='overlay-menu-icon'
                  style={{
                    position: 'absolute',
                    right: '6px',
                    top: '0',
                  }}
                  src={Icons.menuOndisplay}
                  alt='menu'
                />
              </div>
              <DriverCustomCost customStopsData={customStopsData} driverCustomCost={driverCustomCost} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(Map);
