import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { createCustomerFuelMatrix, getCommodity, getFullPrices } from 'Api/Customers';
import { DatePicker } from 'common/Pickers';
import sm from './ContactBook.module.css';
import { Typography } from '../../Typography';
import CustomInput from '../../CreateShipment/helpers/CustomInput';
import CustomButton from '../../CustomButton/CustomButton';
import CustomSelect from '../../CreateShipment/ShipmentStops/helpers/CustomSelect';
import { fuelSurchargeValidationSchema } from './ValidationSchema';

const FuelSurchargeMatrix = ({ Cancel, nextStep, customer }) => {
  const [onNextloading, setOnNextloading] = useState(false);
  const [fullPrice, setFullPrice] = useState({});
  const [shipmentTypes, setShipmentTypes] = useState();

  const formRef = useRef();

  const onSkip = () => {
    nextStep();
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();

      if (formRef.current.isValid && formRef.current.dirty) {
        onNextStep(formRef.current.values.fuel_matrix);
      }
    }
  };
  const { use } = useTheme();
  const defaultValue = {
    shipment_type: '',
    pricingDate: null,
    from: '',
    to: '',
    fsc: '',
  };
  const titlesOption = useMemo(() => {
    return [
      { key: 1, label: 'Shipment Type', width: '30%' },
      { key: 2, label: 'Pricing Date', width: '140px' },
      { key: 3, label: 'From', width: '10%' },
      { key: 4, label: 'To', width: '10%' },
      { key: 5, label: 'FSC', width: '18%' },
      { key: 6, label: '', width: '33px' },
    ];
  }, []);

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const onNextStep = (values) => {
    setOnNextloading(true);
    const f = new FormData();
    values.forEach((item, index) => {
      f.append(`fuel_matrix[${index}][commodity_type_id]`, item.shipment_type);
      f.append(`fuel_matrix[${index}][pricing_date]`, item.pricingDate);
      f.append(`fuel_matrix[${index}][from]`, item.from);
      f.append(`fuel_matrix[${index}][to]`, item.to);
      f.append(`fuel_matrix[${index}][fsc]`, item.fsc);
    });
    f.append(`customer_id`, customer.basicDetails.customer);
    createCustomerFuelMatrix(f)
      .then((res) => {
        if (res?.status === 200) {
          setOnNextloading(false);
          nextStep(res.data);
        }
      })
      .catch(() => setOnNextloading(false));
  };

  useEffect(() => {
    getFullPrices().then((res) => {
      if (res && res?.data) {
        const price = res.data?.diesel;
        setFullPrice(price);
      }
      return res;
    });
    getCommodity().then((res) => {
      if (res && res?.data) {
        const any = [{ id: '*', title: 'Any' }];
        const options = any.concat(res.data).map((el) => {
          return {
            ...el,
            key: el.id,
            label: el.title,
            labelSelected: null,
          };
        });
        setShipmentTypes(options);
      }
    });
  }, []);

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = { fuel_matrix: [{ ...defaultValue }] };

  return (
    <>
      <Modal.Body className='add-driver-modal-body'>
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <Typography variant='s1'>EIA</Typography>
              <Typography variant='s2' sx={{ alignItems: 'center' }}>
                <span className='d-flex align-items-center' data-mui-internal-clone-element='true'>
                  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M15.5 8C15.5 12.1422 12.1423 15.5 8 15.5C3.8577 15.5 0.5 12.1422 0.5 8C0.5 3.85781 3.8577 0.5 8 0.5C12.1423 0.5 15.5 3.85781 15.5 8ZM6.37799 6.32184H9.02997V11.9711C9.02997 12.5179 8.58667 12.9612 8.03983 12.9612C7.49299 12.9612 7.04968 12.5179 7.04968 11.9711V8.07782H6.37799C5.89309 8.07782 5.5 7.68473 5.5 7.19983C5.5 6.71493 5.89309 6.32184 6.37799 6.32184ZM6.76221 4.16559C6.76221 4.00973 6.7899 3.86436 6.84483 3.72949C6.90431 3.59045 6.98466 3.47036 7.08636 3.36924C7.18805 3.26812 7.30655 3.1881 7.44229 3.12907C7.50404 3.103 7.56759 3.08269 7.63342 3.06813C7.71696 3.04984 7.80367 3.04059 7.89356 3.04059C8.04611 3.04059 8.19002 3.07004 8.32577 3.12907C8.36254 3.14499 8.39795 3.16248 8.432 3.18144C8.52416 3.23279 8.60724 3.29543 8.6817 3.36924C8.78339 3.47036 8.86375 3.59045 8.92322 3.72949C8.9541 3.79981 8.97725 3.87294 8.99178 3.94889C9.0054 4.01876 9.01221 4.09099 9.01221 4.16559C9.01221 4.24956 9.00358 4.33116 8.98633 4.41027C8.9718 4.47799 8.95046 4.5439 8.92322 4.608C8.86375 4.74287 8.78339 4.86081 8.6817 4.96194C8.62949 5.01374 8.57319 5.06001 8.51236 5.10076C8.45425 5.13947 8.39205 5.17333 8.32577 5.20211C8.19002 5.26113 8.04611 5.29059 7.89356 5.29059C7.81411 5.29059 7.73739 5.28348 7.66339 5.26914C7.58666 5.25447 7.51311 5.23213 7.44229 5.20211C7.30655 5.14308 7.18805 5.06306 7.08636 4.96194C6.98466 4.86081 6.90431 4.74287 6.84483 4.608C6.7899 4.46896 6.76221 4.32145 6.76221 4.16559Z'
                      fill='#4F5AED'
                    />
                  </svg>
                  {fullPrice.type}: {(+fullPrice.price).toFixed(2)} {fullPrice.unit} as of {fullPrice.date}
                </span>
              </Typography>
              <Formik initialValues={initialValue} innerRef={formRef} validationSchema={fuelSurchargeValidationSchema}>
                {({ values, setFieldValue, handleBlur, touched, errors }) => (
                  <Form>
                    <FieldArray
                      name='fuel_matrix'
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            <div className={sm.row}>
                              {titlesOption.map((el) => {
                                return (
                                  <Typography
                                    key={el?.label}
                                    variant='s2'
                                    style={{ width: el.width, whiteSpace: 'nowrap' }}
                                  >
                                    {el.label}
                                    {el.required && <span className={sm.required_system}>*</span>}
                                  </Typography>
                                );
                              })}
                            </div>

                            {values.fuel_matrix.map((el, index) => {
                              const namePrefix = `fuel_matrix[${index}]`;
                              return (
                                <div key={el?.id} className={[sm.row, sm.line].join(' ')} style={{ marginTop: 0 }}>
                                  <div style={{ width: '30%' }}>
                                    <Field
                                      name={`${namePrefix}[shipment_type]`}
                                      options={shipmentTypes}
                                      styles={styles.type}
                                      component={CustomSelect}
                                      menuStyles={styles.fullWidthPercent}
                                      validate={validateStopPointInput}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[shipment_type]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '140px' }}>
                                    <DatePicker
                                      name={`fuel_matrix[${index}].pricingDate`}
                                      value={el.pricingDate}
                                      onBlur={handleBlur}
                                      onChange={(val) => setFieldValue(`fuel_matrix[${index}].pricingDate`, val)}
                                      error={
                                        touched?.fuel_matrix?.[index]?.pricingDate &&
                                        errors?.fuel_matrix?.[index]?.pricingDate
                                      }
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[from]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'right' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                      leftIcon={<p style={{ margin: 0, padding: 3 }}>$</p>}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[from]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[to]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'right' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                      leftIcon={<p style={{ margin: 0, padding: 3 }}>$</p>}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[to]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '18%' }}>
                                    <Field
                                      name={`${namePrefix}[fsc]`}
                                      component={CustomInput}
                                      style={{ marginTop: 0, height: '32px', borderRadius: '8px 0 0 8px' }}
                                      labelStyle={{
                                        alignItems: 'flex-start',
                                        marginTop: 0,
                                      }}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                      rightParentText={<p style={{ margin: 0, padding: 3 }}>/mile</p>}
                                      rightParentTextStyle={{
                                        color: use(palette.gray500, palette.gray200),
                                        backgroundColor: palette.gray0,
                                        height: '32px',
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[fsc]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>
                                  {values.fuel_matrix.length > 1 && index !== 0 ? (
                                    <DeleteIcon
                                      width={12}
                                      height={12}
                                      fill={palette.indigo500}
                                      style={styles.deleteIcon}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  ) : (
                                    <span style={{ width: 28 }} />
                                  )}
                                </div>
                              );
                            })}

                            <CustomButton
                              type='secondary'
                              title='Add Another'
                              styleTitle={styles.addAnotherTitle}
                              styleButton={styles.addAnotherButton}
                              onClick={() => arrayHelpers.push({ ...defaultValue })}
                              leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                            />
                          </div>
                        );
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button className='cancel-button' onClick={onSkip}>
              Skip
            </Button>
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button onClick={handleSubmit} className='next-step'>
                Next
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default FuelSurchargeMatrix;
