import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { updateStaffBasic } from 'Api/User';
import { validationSchema } from './validationSchema';

const TimeCardId = ({ open, onClose, userData, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    if (!userData?.staff) {
      return;
    }

    setLoading(true);
    try {
      const body = { nfc_id: values.nfc_id };
      await updateStaffBasic(userData.staff.id, body);
      showToaster({ type: 'success', message: 'Time Card NFC ID has been successfully updated!' });
      onSuccess(values.nfc_id);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { nfc_id: userData?.nfc_id || '' },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Time Card NFC ID'
      $maxWidth='470px'
      $minWidth='470px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div>
        <Input
          name='nfc_id'
          value={values.nfc_id}
          onChange={handleChange}
          placeholder='NFC ID'
          error={touchedErrors.nfc_id}
        />
      </div>
    </Modal>
  );
};

export default TimeCardId;
