import React, { useState } from 'react';
import { ReactComponent as CalendarIcon } from 'assets/icons/drivers/calendar.svg';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import DateRange from 'components/DateRangePicker/DateRangePicker';
import { palette } from 'utils/constants';
import { SButton, SDateRangeWrapper, SWrapper } from './DateRangePicker.styles';

const DateRangePicker = ({
  initialRangeName = 'Last 7 days',
  dateRange,
  setDateRange,
  top,
  size,
  labelType,
  width,
  onRangeNameChange,
  ...props
}) => {
  const [rangeName, setRangeName] = useState(initialRangeName);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <SWrapper>
      <ThreeDotActions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        actionButton={
          <SButton $size={size} $width={width} className='PrimaryBtn' style={{ backgroundColor: palette.white }}>
            <CalendarIcon />
            <Typography variant='s2'>{rangeName}</Typography>
          </SButton>
        }
        popoverStyles={{ margin: '20px 0 0 0' }}
        padding='0px'
        className='date-range-wrapper'
      >
        <SDateRangeWrapper top={top}>
          <DateRange
            labelType={labelType}
            dateTimeRange={dateRange}
            dateSelect={setDateRange}
            setRangeName={(name) => {
              setRangeName(name);
              if (onRangeNameChange) {
                onRangeNameChange(name);
              }
            }}
            setIsShowDatePicker={() => setAnchorEl(null)}
            className='chatlax-picker'
            {...props}
          />
        </SDateRangeWrapper>
      </ThreeDotActions>
    </SWrapper>
  );
};

export default DateRangePicker;
