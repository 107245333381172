import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { ReactComponent as CheckedIcon } from 'assets/icons/createChecked.svg';
import { ReactComponent as RadioIcon } from 'assets/icons/radioChecked.svg';
import { Typography } from 'components/Typography';
import { getCustomersList } from 'Api/Customers';
import { getAccident, getStatusTypes, updateCollision } from 'Api/AccidentsAndCrashes';
import Logbook from 'pages/AccidentsAndCrashes/AddAccidents/Logbook';
import { getTrailerList, getVehicleList } from 'Api/HOS';
import UserInfo from 'components/UserInfo';
import General from './General';
import CollisionCost from './CollisionCost';
import Driver from './Driver';
import Shipment from './Shipment';
import Equipment from './Equipment';
import AlcoholDrugTest from './AlcoholDrugTest';
import StatusChangeOverlay from './StatusChangeOverlay';
import { SModalWrapper } from '../AccidentsAndCrashes.styles';

const AddAccidents = ({ close, accidentId }) => {
  const { use } = useTheme();
  const { formatDateTime } = useDateFormat();
  const [status, setStatus] = useState(1);
  const [accident, setAccident] = useState(null);
  const [id, setId] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [activeStatus, setActiveStatus] = useState(null);
  const [showStatuses, setShowStatuses] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedTrailer, setSelectedTrailer] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const [selectedCollisionDateTime, setSelectedCollisionDateTime] = useState(null);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [trailersList, setTrailersList] = useState([]);
  const [customers, setCustomers] = useState([]);

  const getAccidentData = async () => {
    try {
      setLoadingData(true);
      const { data } = await getAccident(accidentId);
      setAccident(data);
      setLoadingData(false);
    } catch (e) {
      // Do nothing
    }
  };

  const getStatusTypesList = async () => {
    try {
      const { data } = await getStatusTypes();
      setStatuses(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getVehicles = async () => {
    try {
      const { data } = await getVehicleList();
      setVehiclesList(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getTrailers = async () => {
    try {
      const { data } = await getTrailerList();
      setTrailersList(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList({ page: 1, page_size: 10000 });
      setCustomers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const headerOverLayChange = async (status) => {
    const data = {
      status_id: status.id,
      collision_date_time: accident.collision_date_time,
    };
    await updateCollision(accident.id, data);
    setAccident((prevState) => ({ ...prevState, status }));
    setActiveStatus(status);
  };

  const nextStep = () => {
    setStatus(status + 1);
  };

  const prevStep = () => {
    setStatus(status - 1);
  };

  const Cancel = () => {
    close();
  };

  useEffect(() => {
    if (accidentId) {
      getAccidentData();
      getStatusTypesList();
    }
  }, [accidentId]);

  const changeStep = (step) => {
    if (accident?.id) {
      setStatus(step);
    }
  };

  useEffect(() => {
    getVehicles();
    getTrailers();
    getCustomers();
  }, []);

  return (
    <>
      <div
        className='main-modal-wrapper'
        style={{ backgroundColor: use(palette.white, palette.dark600), position: 'relative' }}
      >
        <div className='d-flex align-items-center gap-3'>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            {accident?.id ? `Report ${accident.report_number || ''}` : 'Create Report'}
          </Typography>
          {!!accident?.id && !!accident?.status && (
            <StatusChangeOverlay
              show={showStatuses}
              data={statuses}
              onChange={headerOverLayChange}
              onChaneShow={setShowStatuses}
              defaultSelected={accident.status}
              activeStatus={activeStatus}
              setActiveStatus={setActiveStatus}
            />
          )}
          {accident?.report_date_time && (
            <div className='d-flex align-items-center gap-1'>
              <Typography variant='button2'>Date/Time of Report:</Typography>
              <Typography variant='s2'>{formatDateTime(accident?.report_date_time)}</Typography>
            </div>
          )}
          {accident?.person_taking_report && (
            <div className='d-flex align-items-center gap-1'>
              <Typography variant='button2'>Person Taking Report:</Typography>
              <UserInfo
                size='32px'
                statusSize='7px'
                data={{
                  ...(accident.person_taking_report || {}),
                  name: `${accident.person_taking_report.fname || accident.person_taking_report.first_name} ${
                    accident.person_taking_report.lname || accident.person_taking_report.last_name
                  }`,
                  image: accident.person_taking_report.image || accident.person_taking_report.profile_logo,
                  status:
                    accident.person_taking_report?.status_data?.driver_status ||
                    accident.person_taking_report?.status_data?.id ||
                    accident.person_taking_report.status,
                  user_type: accident.person_taking_report_type === 'App\\Driver' ? 'driver' : 'staff',
                }}
              />
            </div>
          )}
          {accident?.type && (
            <div className='d-flex align-items-center gap-1'>
              <Typography variant='button2'>Type:</Typography>
              <Typography variant='s2'>{accident?.type}</Typography>
            </div>
          )}
        </div>

        <Modal.Header className='add-customer-modal-header driver-style-wrap'>
          <div className='carrier-steps-wrapper'>
            <div
              className={status >= 1 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}
              onClick={() => changeStep(1)}
            >
              {status > 1 ? (
                <div className='d-flex gap-2'>
                  <CheckedIcon />
                  <Typography variant='button2' style={{ color: palette.indigo500 }}>
                    General
                  </Typography>
                </div>
              ) : (
                <div className='d-flex gap-2'>
                  <RadioIcon />
                  <Typography variant='button1'>General</Typography>
                </div>
              )}

              <div className='carrier-progress'>
                <div
                  className={status === 1 ? 'incomplete-progressbar' : status > 1 ? 'complete-progressbar' : ''}
                  role='progressbar'
                  aria-valuenow='50'
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>

            <div
              className={status >= 2 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}
              onClick={() => changeStep(2)}
            >
              {status > 2 ? (
                <div className='d-flex gap-2'>
                  <CheckedIcon />
                  <Typography variant='button2' style={{ color: palette.indigo500 }}>
                    Driver
                  </Typography>
                </div>
              ) : (
                <div className='d-flex gap-2'>
                  <RadioIcon />
                  <Typography variant='button1'>Driver</Typography>
                </div>
              )}

              <div className='carrier-progress'>
                <div
                  className={status === 2 ? 'incomplete-progressbar' : status > 2 ? 'complete-progressbar' : ''}
                  role='progressbar'
                  aria-valuenow='50'
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>

            <div
              className={status >= 3 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}
              onClick={() => changeStep(3)}
            >
              {status > 3 ? (
                <div className='d-flex gap-2'>
                  <CheckedIcon />
                  <Typography variant='button2' style={{ color: palette.indigo500 }}>
                    Equipment
                  </Typography>
                </div>
              ) : (
                <div className='d-flex gap-2'>
                  <RadioIcon />
                  <Typography variant='button1'>Equipment</Typography>
                </div>
              )}

              <div className='carrier-progress'>
                <div
                  className={status === 3 ? 'incomplete-progressbar' : status > 3 ? 'complete-progressbar' : ''}
                  role='progressbar'
                  aria-valuenow='50'
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>

            <div
              className={status >= 4 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}
              onClick={() => changeStep(4)}
            >
              {status > 4 ? (
                <div className='d-flex gap-2'>
                  <CheckedIcon />
                  <Typography variant='button2' style={{ color: palette.indigo500 }}>
                    Shipment
                  </Typography>
                </div>
              ) : (
                <div className='d-flex gap-2'>
                  <RadioIcon />
                  <Typography variant='button1'>Shipment</Typography>
                </div>
              )}

              <div className='carrier-progress'>
                <div
                  className={status === 4 ? 'incomplete-progressbar' : status > 4 ? 'complete-progressbar' : ''}
                  role='progressbar'
                  aria-valuenow='50'
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>

            <div
              className={status >= 5 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}
              onClick={() => changeStep(5)}
            >
              {status > 5 ? (
                <div className='d-flex gap-2'>
                  <CheckedIcon />
                  <Typography variant='button2' style={{ color: palette.indigo500 }}>
                    Logbook
                  </Typography>
                </div>
              ) : (
                <div className='d-flex gap-2'>
                  <RadioIcon />
                  <Typography variant='button1'>Logbook</Typography>
                </div>
              )}

              <div className='carrier-progress'>
                <div
                  className={status === 5 ? 'incomplete-progressbar' : status > 5 ? 'complete-progressbar' : ''}
                  role='progressbar'
                  aria-valuenow='50'
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>

            <div
              className={status >= 6 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}
              onClick={() => changeStep(6)}
            >
              {status > 6 ? (
                <div className='d-flex gap-2'>
                  <CheckedIcon />
                  <Typography variant='button2' style={{ color: palette.indigo500 }}>
                    Alcohol / Drug Test
                  </Typography>
                </div>
              ) : (
                <div className='d-flex gap-2'>
                  <RadioIcon />
                  <Typography variant='button1'> Alcohol / Drug Test</Typography>
                </div>
              )}

              <div className='carrier-progress'>
                <div
                  className={status === 6 ? 'incomplete-progressbar' : status > 6 ? 'complete-progressbar' : ''}
                  role='progressbar'
                  aria-valuenow='50'
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>

            <div
              className={status >= 7 ? 'carrier-step-heading-active' : 'carrier-steps-heading'}
              onClick={() => changeStep(7)}
            >
              {status > 7 ? (
                <div className='d-flex gap-2'>
                  <CheckedIcon />
                  <Typography variant='button2' style={{ color: palette.indigo500 }}>
                    Collision Cost
                  </Typography>
                </div>
              ) : (
                <div className='d-flex gap-2'>
                  <RadioIcon />
                  <Typography variant='button1'>Collision Cost</Typography>
                </div>
              )}

              <div className='carrier-progress'>
                <div
                  className={status === 7 ? 'incomplete-progressbar' : status > 7 ? 'complete-progressbar' : ''}
                  role='progressbar'
                  aria-valuenow='50'
                  aria-valuemin='0'
                  aria-valuemax='100'
                />
              </div>
            </div>
          </div>
        </Modal.Header>
      </div>
      {status === 1 && (
        <SModalWrapper>
          <General
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            accident={accident}
            accidentId={accidentId}
            setId={setId}
            id={id}
            loadingData={loadingData}
            setSelectedDriver={setSelectedDriver}
            setSelectedVehicle={setSelectedVehicle}
            setSelectedTrailer={setSelectedTrailer}
            setSelectedShipment={setSelectedShipment}
            setSelectedIncidentId={setSelectedIncidentId}
            setSelectedCollisionDateTime={setSelectedCollisionDateTime}
            vehiclesList={vehiclesList}
            trailersList={trailersList}
          />
        </SModalWrapper>
      )}
      {status === 2 && (
        <SModalWrapper>
          <Driver
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            accident={accident}
            id={id}
            accidentId={accidentId}
            setAccident={setAccident}
            loadingData={loadingData}
            selectedIncidentId={selectedIncidentId}
            selectedCollisionDateTime={selectedCollisionDateTime}
            selectedDriver={selectedDriver}
          />
        </SModalWrapper>
      )}
      {status === 3 && (
        <SModalWrapper>
          <Equipment
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            accident={accident}
            id={id}
            accidentId={accidentId}
            setAccident={setAccident}
            loadingData={loadingData}
            selectedIncidentId={selectedIncidentId}
            selectedCollisionDateTime={selectedCollisionDateTime}
            selectedDriver={selectedDriver}
            vehiclesList={vehiclesList}
            trailersList={trailersList}
          />
        </SModalWrapper>
      )}
      {status === 4 && (
        <SModalWrapper>
          <Shipment
            nextStep={nextStep}
            Cancel={Cancel}
            prevStep={prevStep}
            accident={accident}
            accidentId={accidentId}
            id={id}
            loadingData={loadingData}
            selectedDriver={selectedDriver}
            selectedShipment={selectedShipment}
            selectedVehicle={selectedVehicle}
            selectedTrailer={selectedTrailer}
            vehiclesList={vehiclesList}
            trailersList={trailersList}
          />
        </SModalWrapper>
      )}
      {status === 5 && (
        <SModalWrapper>
          <Logbook nextStep={nextStep} Cancel={Cancel} accident={accident} id={id} onSuccess={getAccidentData} />
        </SModalWrapper>
      )}
      {status === 6 && (
        <SModalWrapper>
          <AlcoholDrugTest nextStep={nextStep} cancel={Cancel} accident={accident} id={id} />
        </SModalWrapper>
      )}
      {status === 7 && (
        <SModalWrapper>
          <CollisionCost
            id={id}
            Cancel={Cancel}
            prevStep={prevStep}
            accident={accident}
            selectedVehicle={selectedVehicle}
            selectedTrailer={selectedTrailer}
            customers={customers}
          />
        </SModalWrapper>
      )}
    </>
  );
};

export default AddAccidents;
