import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  .top-wrapper {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }
`;
