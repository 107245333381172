import React, { useState } from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import './ReccuringTypeModal.css';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';

const month = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
  '29th',
  '30th',
];

export default function RecurringModal({ recurringModalData, ...props }) {
  const { every_settlement, day, month_week, amortized_month, amortized_months, amortized_total } = recurringModalData;
  const { use } = useTheme();
  const week = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const [reccurring, setReccurring] = useState({
    every_settlement,
    day,
    month_week,
    amortized_month,
    amortized_months,
    amortized_total,
  });
  const updateReccuringData = () => {
    const updateData = { ...recurringModalData, reccurring };
    props.updateReccuringData(updateData);
  };

  return (
    <div className='modal-container'>
      <Modal
        {...props}
        dialogClassName='recurring-modal-dialog-box'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Set Interval
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.white, palette.darkborder),
          }}
        >
          <p className='subline' style={{ color: use(palette.dark900, palette.gray50) }}>
            Interval Type
          </p>
          <div role='group' aria-labelledby='my-radio-group' id='my-radio-group'>
            <div className='status-container recurring-compensation-recurring-main' aria-labelledby='my-radio-group'>
              <input
                type='radio'
                className='type'
                value={0}
                name='driver_type'
                id='driver_type'
                onChange={() => setReccurring({ ...reccurring, every_settlement: 0 })}
              />
              <div className='compensation-recurring-wraper type-compensation-recurring-wraper'>
                <label
                  className='text-style compensation-text-style-lable'
                  htmlFor='flexSwitchCheckChecked'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  Every
                </label>
                <Dropdown
                  className='dropdown custom-dropdown-in-compensation'
                  onSelect={(value) => setReccurring({ ...reccurring, day: value })}
                >
                  <Dropdown.Toggle
                    placement='top'
                    variant='outline-secondary'
                    id='dropdown-basic'
                    className='tablePlaner_dropdownToggle compensation__dropdownToggle'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      backgroundColor: use(palette.white, palette.dark800),
                    }}
                  >
                    {' '}
                    {reccurring.day}
                  </Dropdown.Toggle>
                  {reccurring.month_week === 'Month' ? (
                    <Dropdown.Menu
                      className='tablePlaner_menu compansation_menu'
                      style={{ backgroundColor: use(palette.white, palette.dark800) }}
                    >
                      {month?.map((el) => (
                        <Dropdown.Item eventKey={el} key={el} active={reccurring.day === el}>
                          {el}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu
                      className='tablePlaner_menu compansation_menu'
                      style={{ backgroundColor: use(palette.white, palette.dark800) }}
                    >
                      {week?.map((el) => (
                        <Dropdown.Item eventKey={el} key={el} active={reccurring.day === el}>
                          {el}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                <label
                  className='text-style compensation-text-style-lable-of'
                  htmlFor='flexSwitchCheckChecked'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  of the
                </label>
                <Dropdown
                  className='dropdown custom-dropdown-in-compensation'
                  onSelect={(value) =>
                    setReccurring({ ...reccurring, month_week: value, day: value === 'Week' ? 'Monday' : '1st' })
                  }
                >
                  <Dropdown.Toggle
                    placement='top'
                    variant='outline-secondary'
                    id='dropdown-basic'
                    className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      backgroundColor: use(palette.white, palette.dark800),
                    }}
                  >
                    {' '}
                    {reccurring.month_week}
                    <div className='tablePlaner_arrows_wrapper'>
                      <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className='tablePlaner_menu compansation_menu'
                    style={{ backgroundColor: use(palette.white, palette.dark800) }}
                  >
                    <Dropdown.Item eventKey='Month' active={reccurring.month_week === 'Month'}>
                      Month
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Week' active={reccurring.month_week === 'Week'}>
                      Week
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='status-container' aria-labelledby='my-radio-group'>
              <input
                type='radio'
                className='type'
                value={1}
                name='driver_type'
                id='driver_type'
                onChange={() => setReccurring((prev) => ({ ...prev, every_settlement: 1 }))}
              />
              <p style={{ color: use(palette.gray700, palette.gray50) }} className='status-text'>
                Every Settlement
              </p>
            </div>
            {/* {type === 'deductions' && ( */}
            {/*  <div className='status-container recurring-compensation-recurring-main' aria-labelledby='my-radio-group'> */}
            {/*    <input */}
            {/*      type='radio' */}
            {/*      className='type' */}
            {/*      value={2} */}
            {/*      name='driver_type' */}
            {/*      id='driver_type' */}
            {/*      onChange={() => setReccurring({ ...reccurring, every_settlement: 2 })} */}
            {/*    /> */}
            {/*    <div className='compensation-recurring-wraper type-compensation-recurring-wraper'> */}
            {/*      <label */}
            {/*        className='text-style compensation-text-style-lable' */}
            {/*        htmlFor='flexSwitchCheckChecked' */}
            {/*        style={{ color: use(palette.gray700, palette.gray200) }} */}
            {/*      > */}
            {/*        Amortized */}
            {/*      </label> */}
            {/*      <div className='compensation-amount-wrapper mt-0'> */}
            {/*        <span */}
            {/*          className='doller-icon' */}
            {/*          style={{ */}
            {/*            backgroundColor: use(palette.white, palette.dark600), */}
            {/*            borderColor: use(palette.gray50, palette.darkborder), */}
            {/*            color: palette.gray400, */}
            {/*          }} */}
            {/*        > */}
            {/*          {currency} */}
            {/*        </span> */}
            {/*        <input */}
            {/*          type='number' */}
            {/*          placeholder='0.00' */}
            {/*          style={{ */}
            {/*            backgroundColor: use(palette.white, palette.dark600), */}
            {/*            borderColor: use(palette.gray50, palette.darkborder), */}
            {/*          }} */}
            {/*          name='amortized_total' */}
            {/*          id='amortized_total' */}
            {/*          value={reccurring?.amortized_total} */}
            {/*          onChange={(e) => setReccurring({ ...reccurring, amortized_total: e.target.value })} */}
            {/*        /> */}
            {/*      </div> */}
            {/*      <label */}
            {/*        className='text-style compensation-text-style-lable-of' */}
            {/*        htmlFor='flexSwitchCheckChecked' */}
            {/*        style={{ color: use(palette.gray700, palette.gray200) }} */}
            {/*      > */}
            {/*        over */}
            {/*      </label> */}
            {/*      <div */}
            {/*        className='re-enter-routing-in-recurring' */}
            {/*        style={{ */}
            {/*          backgroundColor: use(palette.white, palette.dark600), */}
            {/*          borderColor: use(palette.gray50, palette.darkborder), */}
            {/*        }} */}
            {/*      > */}
            {/*        <input */}
            {/*          type='number' */}
            {/*          style={{ backgroundColor: use(palette.white, palette.dark600) }} */}
            {/*          className='input-field' */}
            {/*          placeholder='00' */}
            {/*          name='amortized_months' */}
            {/*          id='amortized_months' */}
            {/*          value={reccurring?.amortized_months} */}
            {/*          onChange={(e) => setReccurring({ ...reccurring, amortized_months: e.target.value })} */}
            {/*        /> */}
            {/*      </div> */}
            {/*      <label */}
            {/*        className='text-style compensation-text-style-lable-of' */}
            {/*        htmlFor='flexSwitchCheckChecked' */}
            {/*        style={{ color: use(palette.gray700, palette.gray200) }} */}
            {/*      > */}
            {/*        settlements, deducted on each settlement. */}
            {/*      </label> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* )} */}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' onClick={() => updateReccuringData()} type='submit'>
                Set Interval
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
