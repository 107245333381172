import jsPDF from 'jspdf';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Date', 16, top);
  doc.text('Title', 42, top);
  doc.text('Note', 80, top);
  doc.text('Added By', pageWidth - 16, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
};

const createTableRow = (doc, note, top, formatDate) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${formatDate(note.date)}`, 16, top);
  doc.text(`${note.title.length <= 85 ? note.title : note.title.substring(0, 85)}`, 42, top, { maxWidth: 30 });
  doc.text(`${note.notes.length <= 230 ? note.notes : note.notes.substring(0, 230)}`, 80, top, { maxWidth: 85 });
  doc.text(`${note.added_by?.first_name || ''} ${note.added_by?.last_name || ''}`, pageWidth - 16, top, {
    align: 'right',
  });
};

const createTableRows = (doc, notes, formatDate) => {
  if (!notes?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  notes.forEach((note, index) => {
    createTableRow(doc, note, top + index * 18, formatDate);
  });

  return top + notes.length * 18;
};

export const generatePDF = async (employment, notes, convertToCustomerTime, formatDate) => {
  if (!notes?.length) {
    return;
  }

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(
    `${employment.driver?.fname || employment.staff?.first_name} ${
      employment.driver?.lname || employment.staff?.last_name
    }`,
    pageWidth / 2,
    22,
    { align: 'center' }
  );
  doc.setFontSize(10.5);
  doc.text(`Previous Employment Notes for ${employment.name}`, pageWidth / 2, 28, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 16, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(16, 30, pageWidth - 16, 30);

  const firstPageNotes = notes.slice(0, 12);
  const restNotes = notes.slice(12);

  createTableRows(doc, firstPageNotes, formatDate);

  for (let i = 0; i < (restNotes?.length || 0) / 13; i++) {
    doc.addPage();
    const currentPageNotes = restNotes.slice(i * 13, (i + 1) * 13);
    createTableRows(doc, currentPageNotes, formatDate);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc);
  }

  doc.setDocumentProperties({ title: `Previous Employment Notes` });

  const url = doc.output('datauristring', { filename: 'Previous Employment Notes' });
  const blob = doc.output('blob', { filename: 'Previous Employment Notes' });

  return { blob, url };
};
