export const getErrorMessage = (error) => {
  let errorMessage = '';
  if (error?.response?.data) {
    const { message, response, error: err, data, errors } = error?.response?.data || {};
    if (typeof message === 'object' && !Array.isArray(message) && Object?.values(message)?.length) {
      errorMessage = Object?.values(message)[0][0];
    } else if (typeof message === 'object' && Array.isArray(message) && typeof message[0] === 'string') {
      errorMessage = message[0];
    } else if (response && Object?.values(response)?.length) {
      errorMessage = Object?.values(response)[0][0];
    } else if (typeof message === 'string') {
      errorMessage = message;
    } else if (typeof err?.message === 'string' && err?.message) {
      errorMessage = err?.message;
    } else if (typeof data === 'string' && data) {
      errorMessage = data;
    } else if (typeof data === 'object' && data?.data && typeof data?.data === 'string') {
      errorMessage = data?.data;
    } else if (typeof errors === 'object' && errors?.length) {
      [errorMessage] = errors;
    } else if (typeof err === 'object' && err?.length) {
      [errorMessage] = err;
    } else if (typeof errors === 'object' && Object.values(errors || {})?.length) {
      errorMessage = Object.values(errors)?.[0]?.[0];
    } else {
      errorMessage = 'Something went wrong!';
    }
  } else if (error?.message) {
    errorMessage = error.message;
  }

  return errorMessage || 'Something went wrong!';
};
