import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, FastField, Form, Formik } from 'formik';
import classNames from 'classnames';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { DepartmentsEmail } from 'Api/Driver';
import { Typography } from 'components/Typography';
import { validateRequired } from 'components/AddEditModalStops/validates';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import ImageDropZone from 'components/ImgageDropZone';
import CustomTextArea from 'components/CustomTextArea';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import classes from './notes.module.css';

const AddNotes = ({ onSubmit, styleFormWrapper, hideInvoice, loading }) => {
  const { use } = useTheme();
  const [department, setDepartment] = useState([]);

  const onDrop = (acceptedFiles, propsForm) => {
    const attachment = acceptedFiles[0];
    propsForm.setFieldValue('attachment', attachment);
  };

  const onDeleteHandler = (e, propsForm) => {
    e.stopPropagation();
    propsForm.setFieldValue('attachment', '');
  };

  const style = useMemo(() => {
    return {
      error: {
        color: use(palette.red500, palette.red800),
      },
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      textAreaText: {
        marginBottom: 8,
      },
      dropZone: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
  }, [use, palette]);

  const initialValue = useMemo(() => {
    return {
      title: '',
      notes: '',
      attachment: '',
      note_to_email: '',
      department_id: '',
      show_on_invoice: 0,
    };
  }, []);

  useEffect(() => {
    DepartmentsEmail().then((res) => res && res?.data && setDepartment(res.data));
  }, []);

  return (
    <div className={classNames(classes.addNotesContainer, use(classes.light, classes.dark))}>
      <div className={classes.openedNotesDetails} style={{ ...styleFormWrapper }}>
        <Formik onSubmit={() => {}} enableReinitialize initialValues={initialValue}>
          {({ values, ...propsForm }) => {
            return (
              <Form>
                <div className={classes.formikWrapper}>
                  <div className={classes.inputUploadWrapper}>
                    <div className={classes.errorWrapper}>
                      <div style={{ width: '100%', maxWidth: 206 }}>
                        <FastField
                          placeholder='Note Title'
                          name='title'
                          required
                          label='Title'
                          component={CustomInput}
                          validate={validateRequired}
                          labelStyle={style.labelStyle}
                          className={classes.titleInput}
                        />
                        <ErrorMessage
                          name='title'
                          render={(error) => (
                            <Typography variant='c2' style={style.error}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>
                    </div>
                    <div className={classes.attachmentWrapper}>
                      <FastField name='attachment'>
                        {() => (
                          <div className={classNames(classes.uploadWrapper, use(classes.light, classes.dark))}>
                            <ImageDropZone
                              accept={['application/pdf', 'image/jpeg', 'image/png', 'image/svg+xml']}
                              onDrop={(acceptedFiles) => onDrop(acceptedFiles, propsForm)}
                              height={32}
                              name='attachment'
                              deletable={false}
                              maxSize={1000000}
                              customStyle={style.dropZone}
                              deletedComponent
                            >
                              <div style={{ borderRadius: 4 }} className={classes.chooseFileWrapper}>
                                <UploadIcon style={{ marginBottom: 6 }} />
                                <Typography variant='s2'>
                                  {values?.attachment?.name ? (
                                    <div className={classes.fileUploadedWrapper}>
                                      <TickIcon fill={palette.green400} />
                                      <Typography
                                        variant='s2'
                                        style={{
                                          color: palette.green400,
                                          margin: '0 6px',
                                        }}
                                      >
                                        File Uploaded
                                      </Typography>

                                      <div
                                        className={classes.deleteIcon}
                                        onClickCapture={(e) => onDeleteHandler(e, propsForm)}
                                      >
                                        <DeleteIcon />
                                      </div>
                                    </div>
                                  ) : (
                                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                                      Upload document&nbsp;
                                    </Typography>
                                  )}
                                </Typography>
                              </div>
                            </ImageDropZone>
                          </div>
                        )}
                      </FastField>
                      <ErrorMessage
                        name='attachment'
                        render={(error) => (
                          <Typography variant='c2' style={style.error}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>
                  </div>

                  <div className={classes.formikWrapperRight}>
                    <div className={classes.textarea}>
                      <FastField
                        name='notes'
                        placeholder='Notes'
                        component={CustomTextArea}
                        validate={validateRequired}
                        style={{ width: '100%', height: 105 }}
                        styleLabel={{ margin: 0 }}
                      />
                      <ErrorMessage
                        name='notes'
                        render={(error) => (
                          <Typography variant='c2' style={style.error}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>

                    <div className={classes.smallFieldsContainer}>
                      <div className={classes.smallFieldsWrapper}>
                        <div className={classes.errorWrapperSelect}>
                          <FastField
                            name='note_to_email'
                            placeholder='Email note to...'
                            component={CustomInput}
                            labelStyle={style.labelStyle}
                            style={{ borderRadius: 6, height: 32 }}
                            className={classes.titleInputEmail}
                          />
                        </div>

                        <div className={classes.optional}>
                          <Typography variant='overLine'>OPTIONAL</Typography>
                        </div>

                        <div className={classes.errorWrapperSelect} style={{ marginLeft: 16 }}>
                          <FastField
                            keyName='id'
                            deleted
                            options={department}
                            name='department_id'
                            component={CustomSelect}
                            menuStyles={{ width: '100%' }}
                            labelName='department_name'
                            dropDownStyles={{ width: '100%' }}
                            defaultText='Select Department'
                            styles={{ width: '100%', height: 32 }}
                            onDelete={() => propsForm.setFieldValue('department_id', '')}
                            shouldUpdate={(nextProps, props) => nextProps?.options !== props?.options}
                          />
                        </div>

                        <div className={classes.optional} style={{ marginLeft: 6 }}>
                          <Typography variant='overLine'>OPTIONAL</Typography>
                        </div>
                      </div>
                      <div
                        className={classes.modalFooter}
                        style={{ backgroundColor: use(palette.white, palette.dark900) }}
                      >
                        <CustomButton
                          type='primary'
                          title='Add'
                          onClick={() => {
                            onSubmit({ values, ...propsForm });
                          }}
                          styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0, marginLeft: '16px' }}
                          disabled={loading}
                        />
                      </div>
                      <div style={{ marginLeft: '16px', visibility: hideInvoice ? 'hidden' : 'visible' }}>
                        <CustomCheckbox
                          name='show_on_invoice'
                          checked={!!values.show_on_invoice}
                          onChange={(checked) => propsForm.setFieldValue('show_on_invoice', checked ? 1 : 0)}
                        >
                          <Typography
                            variant='s2'
                            style={{ whiteSpace: 'nowrap', color: palette.gray700, marginLeft: 8 }}
                          >
                            Show on Invoice
                          </Typography>
                        </CustomCheckbox>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddNotes;
