import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SAttachmentWrapper = styled.div`
  .upload-document-deposit {
    width: 100%;
    position: relative;
    border: 1px dashed ${palette.gray200};
    background: ${palette.white};
    color: ${palette.gray900};
  }
`;
