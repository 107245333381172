import React from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import authHeader from 'services/auth-header';
import style from './Maintanance.module.css';

const ScheduleServiceModal = ({ handleClose, id, openToast, MaintenanceRecord }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const ScheduleServicestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 0,
    margin: 0,
    padding: '0px',
    borderRadius: '10px',
  };

  const validationSchema = Yup.object().shape({
    mechanic_notes: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      mechanic_notes: '',
      mark_complete: 1,
      status_id: 1,
    },

    validationSchema,
    onSubmit: (values) => {
      axios.put(`${API_URL}/equipment/maintenance-record/${id}`, values, { headers: authHeader() }).then(() => {
        MaintenanceRecord();
        handleClose();
        // AddRecordModalClose()
        openToast('Record Updated Successfully');
      });
    },
  });

  return (
    <Box sx={ScheduleServicestyle}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <div>
              <p className={style.ScheduleServiceHeader}> Update Record</p>
            </div>
            <div className={style.ScheduleServiceBody}>
              {/*
        <div className="mt-3">
          <span className={style.ScheduleServiceFormHeader}>
             <span style={{ color: "blue" }}>*</span>
          </span>
          <div className="mt-1">
            <textarea rows="3"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={style.recordFormDescription} >
              </textarea>
          </div>
        </div>
       */}
              <div className='mb-2 '>
                <span className={style.recordFormHeader}>
                  Mechanic Notes <span style={{ color: 'blue' }}>*</span>
                </span>
              </div>
              <div className='mt-1'>
                <textarea
                  className={style.recordFormDescription}
                  name='mechanic_notes'
                  value={formik.values.mechanic_notes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows='3'
                />
                {formik.errors.mechanic_notes && formik.touched.mechanic_notes ? (
                  <div
                    className={style['add-vendor-modal-content']}
                    style={{ color: '#D12953', fontSize: '12px', marginTop: '12px' }}
                  >
                    {formik.errors.mechanic_notes}{' '}
                  </div>
                ) : null}
              </div>
              {/* <span className="mt-3 d-flex">
            <input type="checkbox" onChange={(e)=>{
              formik.setFieldValue("mark_complete", e.target.checked ? 1 : 0)
            }} className="me-2" /><label className={style.recordFormHeader} style={{margin:"-4px"}}> Mark Complete</label>
          </span> */}
            </div>
            <div className={style.ScheduleServiceFormFooter}>
              <button className={style.ScheduleServiceFormCancelButton} onClick={handleClose}>
                Cancel
              </button>
              <button type='submit' className={style.ScheduleServiceFormAddRecordButton}>
                Mark Completed
              </button>
            </div>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default ScheduleServiceModal;
