import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import useShowToaster from 'hooks/useShowToaster';
import Uploader from 'common/Uploader';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import useForm from 'hooks/useForm';
import Input from 'common/Input';
import moment from 'moment';
import { createCarrierDocuments } from 'Api/Carriers';
import { getErrorMessage } from 'utils/error';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import sm from './ContactBook.module.css';
import { documentsValidationSchema } from './ValidationSchema';
import { SAddMore } from './AddCarrier.styles';

const Documents = ({ Cancel, nextStep, carrier, getCarriers }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    master_agreement: '',
    w9: '',
    authority: '',
    reference_sheet: '',
    liability_insurance_exp_date: null,
    liability_insurance: '',
    cargo_insurance_exp_date: null,
    general_liability: '',
    general_liability_exp_date: null,
    cargo_insurance: '',
    documents: [],
  };

  const removeRow = (index) => {
    const newDocuments = values.documents.filter((_, idx) => idx !== index);
    setValues({ ...values, documents: newDocuments });
  };

  const addRow = () => {
    const newDocuments = [
      ...values.documents,
      {
        name: '',
        expiration_date: null,
        file: '',
        no_expiration: false,
      },
    ];
    setValues({ ...values, documents: newDocuments });
  };

  const onSkip = () => {
    Cancel();
    nextStep();
    showToaster({ type: 'success', message: `Successfully added ${carrier?.basicDetails?.name}!` });
    getCarriers();
  };

  const handleBasicInfoSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(`carrier_id`, carrier.basicDetails.carrier);
    formData.append(`master_agreement`, values.master_agreement);
    formData.append(`w9`, values.w9);
    formData.append(`authority`, values.authority);
    formData.append(`reference_sheet`, values.reference_sheet);
    formData.append(`liability_insurance`, values.liability_insurance);
    formData.append('liability_insurance_exp_date', moment(values.liability_insurance_exp_date).format('YYYY/MM/DD'));
    formData.append(`cargo_insurance`, values.cargo_insurance);
    formData.append('cargo_insurance_exp_date', moment(values.cargo_insurance_exp_date).format('YYYY/MM/DD'));
    formData.append(`general_liability`, values.general_liability);
    formData.append('general_liability_exp_date', moment(values.general_liability_exp_date).format('YYYY/MM/DD'));
    values?.documents?.forEach((item, index) => {
      formData.append(`documents[${index}][name]`, item.name);
      formData.append(
        `documents[${index}][expiration_date]`,
        item.no_expiration ? '' : moment(item.expiration_date).format('YYYY/MM/DD')
      );
      formData.append(`documents[${index}][file]`, item.file);
    });
    createCarrierDocuments(formData)
      .then(() => {
        Cancel();
        showToaster({ type: 'success', message: `Successfully added!` });
        setLoading(false);
        getCarriers();
      })
      .catch((err) => {
        setLoading(false);
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors, handleBlur } = useForm({
    initialValues,
    onSubmit: handleBasicInfoSubmit,
    validationSchema: documentsValidationSchema,
  });

  return (
    <>
      <Modal.Body className='add-driver-modal-body'>
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <div>
                <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
                  Master Agreement <span className='required'>*</span>
                </Typography>
                <Uploader
                  type={3}
                  uploaderLabel='Attach '
                  document={values.master_agreement}
                  onDrop={(files) => handleChange('master_agreement', files[0])}
                  onRemove={() => handleChange('master_agreement', null)}
                  error={touchedErrors.master_agreement}
                />
              </div>
              <div className='mt-4'>
                <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
                  W9 <span className='required'>*</span>
                </Typography>
                <Uploader
                  type={3}
                  uploaderLabel='Attach '
                  document={values.w9}
                  onDrop={(files) => handleChange('w9', files[0])}
                  onRemove={() => handleChange('w9', null)}
                  error={touchedErrors.w9}
                />
              </div>
              <div className='mt-4'>
                <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
                  Authority <span className='required'>*</span>
                </Typography>
                <Uploader
                  type={3}
                  uploaderLabel='Attach '
                  document={values.authority}
                  onDrop={(files) => handleChange('authority', files[0])}
                  onRemove={() => handleChange('authority', null)}
                  error={touchedErrors.authority}
                />
              </div>
              <div className='mt-4'>
                <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
                  Reference Sheet <span className='required'>*</span>
                </Typography>
                <Uploader
                  type={3}
                  uploaderLabel='Attach '
                  document={values.reference_sheet}
                  onDrop={(files) => handleChange('reference_sheet', files[0])}
                  onRemove={() => handleChange('reference_sheet', null)}
                  error={touchedErrors.reference_sheet}
                />
              </div>
              <div className='d-flex  mt-4 '>
                <div>
                  <InputLabel required>Auto Liability Insurance</InputLabel>
                  <div className='d-flex gap-3 align-items-center'>
                    <div className='d-flex flex-column'>
                      <DatePicker
                        name='liability_insurance_exp_date'
                        value={values.liability_insurance_exp_date}
                        onChange={(val) => handleChange('liability_insurance_exp_date', val)}
                        onBlur={handleBlur}
                        disablePast
                        error={touchedErrors.liability_insurance_exp_date}
                      />
                    </div>
                    <div className='w-100'>
                      <Uploader
                        type={3}
                        document={values.liability_insurance}
                        onDrop={(files) => handleChange('liability_insurance', files[0])}
                        onRemove={() => handleChange('liability_insurance', null)}
                        error={touchedErrors.liability_insurance}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex  mt-4 '>
                <div>
                  <InputLabel required>Cargo Insurance</InputLabel>
                  <div className='d-flex gap-3 align-items-center'>
                    <div className='d-flex flex-column'>
                      <DatePicker
                        name='cargo_insurance_exp_date'
                        value={values.cargo_insurance_exp_date}
                        onChange={(val) => handleChange('cargo_insurance_exp_date', val)}
                        onBlur={handleBlur}
                        disablePast
                        error={touchedErrors.cargo_insurance_exp_date}
                      />
                    </div>
                    <div className='w-100'>
                      <Uploader
                        type={3}
                        document={values.cargo_insurance}
                        onDrop={(files) => handleChange('cargo_insurance', files[0])}
                        onRemove={() => handleChange('cargo_insurance', null)}
                        error={touchedErrors.cargo_insurance}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex mt-4 '>
                <div>
                  <InputLabel required>General Liability</InputLabel>
                  <div className='d-flex gap-3 align-items-center'>
                    <div>
                      <DatePicker
                        name='general_liability_exp_date'
                        value={values.general_liability_exp_date}
                        onChange={(val) => handleChange('general_liability_exp_date', val)}
                        onBlur={handleBlur}
                        disablePast
                        error={touchedErrors.general_liability_exp_date}
                      />
                    </div>
                    <div className='w-100'>
                      <Uploader
                        type={3}
                        document={values.general_liability}
                        onDrop={(files) => handleChange('general_liability', files[0])}
                        onRemove={() => handleChange('general_liability', null)}
                        error={touchedErrors.general_liability}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex  mt-4 '>
                <div>
                  <InputLabel>Custom Document(s)</InputLabel>
                  {values.documents.map((row, index) => (
                    <div className='d-flex gap-3 mb-2 align-items-center'>
                      <div className='d-flex'>
                        <Input
                          name={`documents[${index}].name`}
                          onChange={(e) => handleChange(`documents[${index}].name`, e.target.value)}
                          style={{
                            width: '340px',
                            marginTop: 4,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            height: 33,
                          }}
                          placeholder='Document Name'
                          value={row.name}
                          error={touchedErrors.documents?.[index]?.name}
                        />
                        <div>
                          <div className='mt-1'>
                            <DatePicker
                              width='170px'
                              borderRadius='0 6px 6px 0'
                              name={`documents[${index}].expiration_date`}
                              value={row.no_expiration ? null : row.expiration_date}
                              onBlur={handleBlur}
                              onChange={(val) => handleChange(`documents[${index}].expiration_date`, val)}
                              disablePast
                              disabled={row.no_expiration}
                              error={touchedErrors.documents?.[index]?.expiration_date}
                            />
                          </div>
                          <CustomCheckbox
                            name={`documents[${index}].no_expiration`}
                            checked={!!values?.documents[index]?.no_expiration}
                            onChange={(checked) => handleChange(`documents[${index}].no_expiration`, checked)}
                          >
                            <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                              No Expiration
                            </Typography>
                          </CustomCheckbox>
                        </div>
                      </div>
                      <div className='w-100'>
                        <Uploader
                          type={3}
                          document={row.file}
                          onDrop={(files) => handleChange(`documents[${index}].file`, files[0])}
                          onRemove={() => handleChange(`documents[${index}].file`, null)}
                          error={touchedErrors.documents?.[index]?.file}
                        />
                      </div>
                      <DeleteIcon fill={palette.red500} onClick={() => removeRow(index)} />
                    </div>
                  ))}
                  <SAddMore onClick={addRow}>
                    <PlusIcon />
                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                      Add Another
                    </Typography>
                  </SAddMore>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Skip and Create'
              onClick={onSkip}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Add Carrier'
              onClick={handleSubmit}
              disabled={loading}
              leftIcon={
                loading ? (
                  <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default Documents;
