import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import UserInfo from './components/UserInfo';
import RowActions from './components/RowActions';
import { SUserInfo } from './Cards.styles';

export const statusFilterOptions = [
  { name: 'All', value: '' },
  { name: 'Active', value: '1' },
  { name: 'Inactive', value: '2' },
  { name: 'Lost/Stolen', value: '3' },
];

export const initialFilters = {
  tab: 0,
  page: 1,
  page_size: 10,
  status: statusFilterOptions[0],
};

export const spendLimitTypeOptions = [
  { name: 'Daily', id: 1 },
  { name: 'Weekly', id: 2 },
  { name: 'Monthly', id: 3 },
];

export const statusMapper = {
  1: { label: 'ACTIVE', color: palette.green500, bgColor: palette.green0 },
  2: { label: 'INACTIVE', color: palette.gray500, bgColor: palette.gray0 },
  3: { label: 'HOLD', color: palette.orange500, bgColor: palette.orange0 },
};

export const spendLimitType = {
  1: 'Daily',
  2: 'Weekly',
  3: 'Monthly',
};

export const filterTableTop = [
  {
    key: 'all',
    label: 'All',
    value: 0,
    id: 0,
    count: 0,
  },
  {
    key: 'driver',
    label: 'Driver',
    value: 2,
    id: 2,
    count: 0,
  },
  {
    key: 'staff',
    label: 'Staff',
    value: 1,
    id: 1,
    count: 0,
  },
];

export const cardProviders = [
  { id: 44, name: 'WEX EFS Fleet Card' },
  { id: 62, name: 'WEX Fleet One' },
];

const Title = ({ title }) => {
  const { use } = useTheme();

  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useColumns = ({
  onAssignCard,
  onDeleteCard,
  onLoadCash,
  onSetLimit,
  onSetPin,
  onUpdateStatus,
  onCardDetails,
  onViewLimits,
  onSetRestriction,
  onViewCashHistory,
  setOpenFeatureNotAvailable,
}) => {
  const { use } = useTheme();
  const { formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'assigned_to',
      title: <Title title='ASSIGNED TO' />,
      render: (row) =>
        row.user ? (
          <UserInfo data={row} />
        ) : (
          <SUserInfo
            onClick={(e) => {
              e.stopPropagation();
              onAssignCard(row);
            }}
          >
            <div className='assign-user'>
              <PlusIcon width={12} height={12} />
            </div>
            <Typography variant='s2' style={{ color: use(palette.indigo500) }} className='user-name'>
              Assign User
            </Typography>
          </SUserInfo>
        ),
    },
    {
      field: 'service',
      title: <Title title='SERVICE' />,
      render: (row) =>
        row.card_provider?.logo ? (
          <img src={row.card_provider.logo} alt='card logo' height={22} />
        ) : (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            -
          </Typography>
        ),
    },
    {
      field: 'card_number',
      title: <Title title='CARD NUMBER' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          <span style={{ fontSize: '8px' }}>●●●●</span> {row.card_number.slice(-4)}
        </Typography>
      ),
    },
    {
      field: 'exp',
      title: <Title title='EXPIRY DATE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row?.exp || '-'}
        </Typography>
      ),
    },
    {
      field: 'spend_limit',
      title: <Title title='SPEND LIMIT' />,
      render: (row) => (
        <Typography
          variant='s2'
          style={{ color: use(palette.indigo500, palette.gray200) }}
          onClick={(e) => {
            e.stopPropagation();
            onViewLimits(row);
          }}
        >
          View
        </Typography>
      ),
    },
    {
      field: 'balance',
      title: <Title title='CASH BALANCE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {currency}
          {formatNumber(row.cash_balance)}
        </Typography>
      ),
    },
    {
      field: 'status',
      title: <Title title='STATUS' />,
      render: (row) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', rowGap: 4 }}>
          <Chip
            size='small'
            label={statusMapper[row.status?.id]?.label || row.status?.status}
            labelColor={statusMapper[row.status?.id]?.color}
            bgColor={statusMapper[row.status?.id]?.bgColor}
          />
          {!!row.deduct_payroll_charges && (
            <Typography variant='b2' style={{ color: palette.orange500 }}>
              Deduct On Payroll
            </Typography>
          )}
        </div>
      ),
    },
    {
      field: 'last_used',
      title: <Title title='LAST USED' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row?.last_used ? formatDateTime(row.last_used) : '-'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      title: <Title title='' />,
      render: (row) => (
        <RowActions
          row={row}
          onAssignCard={() => onAssignCard(row)}
          onDeleteCard={() => onDeleteCard(row)}
          onLoadCash={() => onLoadCash(row)}
          onSetLimit={() => onSetLimit(row)}
          onSetPin={() => onSetPin(row)}
          onUpdateStatus={() => onUpdateStatus(row)}
          onCardDetails={() => onCardDetails(row)}
          onSetRestriction={() => onSetRestriction(row)}
          onViewCashHistory={() => onViewCashHistory(row)}
          setOpenFeatureNotAvailable={setOpenFeatureNotAvailable}
        />
      ),
    },
  ];
};
