import React from 'react';
import { palette } from 'utils/constants';
import CustomButton from 'components/CustomButton/CustomButton';
import { useTheme } from 'context/themeContext';
import sm from './addStopPointModal.module.css';

const AddStopPointFooter = ({ step, setStep, onChangeShow, submitForm, getCustomerStopPoint, loading }) => {
  const { use } = useTheme();

  const onCancel = () => {
    onChangeShow(false);
    setStep(1);
    if (typeof getCustomerStopPoint === 'function') {
      (step === 2 || step === 3) && getCustomerStopPoint();
    }
  };
  const onclickSkip = () => {
    const nextStep = step + 1;
    setStep(nextStep);
    if (step === 3) {
      onChangeShow(false);
      getCustomerStopPoint();
    }
  };

  return (
    <div className={sm.addStopPointFooter_footer} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      {step === 1 && (
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onCancel}
        />
      )}
      {(step === 2 || step === 3) && (
        <CustomButton
          type='secondary'
          title={step === 2 ? 'Skip' : 'Skip and Create'}
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onclickSkip}
        />
      )}
      <CustomButton
        type='primary'
        title={step !== 3 ? 'Next Step' : 'Add Stop Point'}
        styleButton={{ padding: '6px 12px' }}
        styleTitle={{ fontSize: 14 }}
        onClick={submitForm}
        buttonProps={{ type: 'submit' }}
        disabled={loading}
      />
    </div>
  );
};

export default AddStopPointFooter;
