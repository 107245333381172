import React from 'react';
import profile from '../../assets/icons/drivers/user-profile.svg';
import { SWrapper } from './Avatar.styles';

const Avatar = ({ images }) => {
  const images1 = images?.slice(0, 2);
  const images2 = images?.slice(2, 4);

  return (
    <SWrapper $count={images?.length}>
      <div className='row-container'>
        {images1?.map((i, index) => (
          <img src={i || profile} alt='avatar' key={i} className={`image-${index}`} />
        ))}
      </div>
      {!!images2?.length && (
        <div className='row-container'>
          {images2.map((i, index) => (
            <img
              src={i || profile}
              alt='avatar'
              key={i}
              className={`image-${index}`}
              style={{ width: images2.length === 1 ? '100%' : '50%' }}
            />
          ))}
        </div>
      )}
    </SWrapper>
  );
};

export default Avatar;
