import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const ViewReason = ({ open, onClose, details_info }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<span />}
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <div className='mt-1'>
        <Typography variant='s2'>Here is what we have gathered</Typography>
        <div className='d-flex flex-column mt-2 gap-2'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Account Owner Name: <Typography variant='s2'>{details_info?.names?.join(', ')}</Typography>
          </Typography>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Addresses:{' '}
            {details_info?.addresses?.map((item) => (
              <Typography variant='s2' as='p' className='mb-0'>
                {item.data?.street} {item.data?.city} {item.data?.region}, {item.data?.postal_code} {item.data?.country}
              </Typography>
            ))}
          </Typography>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Emails:{' '}
            <Typography variant='s2'>
              {details_info.emails?.map((item) => (
                <Typography variant='s2' as='p' className='mb-0'>
                  {item.data}
                </Typography>
              ))}
            </Typography>
          </Typography>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Phone Numbers:{' '}
            <Typography variant='s2'>
              {details_info?.phone_numbers?.map((item) => (
                <Typography variant='s2' as='p' className='mb-0'>
                  {item.data}
                </Typography>
              ))}
            </Typography>
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

export default ViewReason;
