import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import classes from './detention.module.scss';
import { Typography } from '../../Typography';
import CustomButton from '../../CustomButton/CustomButton';
import DetentionStop from './types/DetentionStop';

const Detention = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();
  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );
  const styles = useMemo(() => {
    return {
      modal: {
        width: 740,
        overflow: 'hidden',
        maxHeight: '90%',
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
    };
  }, [use, palette]);

  const initialValue = useMemo(() => {
    return data.detentions
      ? data.detentions.reduce((acc, cur, index) => {
          acc[index] = {
            arrival_date: cur.arrived_date,
            departure_date: cur.departed_date,
            bill_rate: '',
            bill_hour: '',
            bill_total: '',
            bill_rate_time: '',
            checked: false,
          };
          return acc;
        }, {})
      : {};
  }, [data.detentions]);

  return (
    <Modal
      size='lg'
      centered
      show={isOpen}
      autoFocus={false}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Formik initialValues={{ ...initialValue }} enableReinitialize onSubmit={() => {}}>
        {({ values, resetForm, submitForm, validateForm, setValues }) => {
          return (
            <Form>
              <Modal.Body style={styles.modalBody}>
                <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <Typography variant='button2'>Pay Detention</Typography>
                  <Typography variant='overLine'>{subTitle}</Typography>
                </div>
                <div
                  className={classes.modalBody}
                  style={{
                    backgroundColor: use(palette.gray0, palette.dark800),
                    borderColor: use(palette.gray50, palette.gray700),
                  }}
                >
                  <div className={classes.switchers}>
                    {data.detentions.map((detention, index) => {
                      return (
                        <DetentionStop
                          data={data}
                          values={values}
                          detention={detention}
                          setValues={setValues}
                          name={index}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <div
                    className={classes.modalFooterLane}
                    style={{ backgroundColor: use(palette.white, palette.dark900) }}
                  >
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title='Pay Detention'
                      onClick={() => onAddBtn(validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Detention;
