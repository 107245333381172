import React, { useState } from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import axios from 'axios';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { Groups } from 'Api/Equipment';
import authHeader from 'services/auth-header';
import styles from '../equipmentTab.module.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: 'none',
  boxShadow: 24,
  borderRadius: '6px',
  outline: 'none',
  padding: '10px',
  background: 'rgb(233, 237, 245)',
};

const EQUIPMENT_TYPES = {
  VEHICLE: 'Vehicle Type',
  TRAILER: 'Trailer Type',
};

const EquipmentModal = ({
  onChange,
  multiSelectOptions,
  onHide,
  Tabs,
  EditModalData,
  editModalData,
  TabData,
  OnTabChange,
}) => {
  const [mySwitch] = useState(true);
  const [CheckboxValue, setCheckboxValue] = useState(editModalData?.data?.filters?.equipment_type_id);
  const [groupData, setGroupData] = useState(editModalData?.data?.filters?.group_id);
  const [tabName, setTabName] = useState(editModalData?.data?.name);
  const [statusId, setStatusId] = useState(editModalData?.data?.filters?.status);
  const { use } = useTheme();
  const API_URL = process.env.REACT_APP_API_URL;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAlert, setOpenAlert] = useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [modeType] = useState(editModalData?.type === 'vehicle' ? EQUIPMENT_TYPES.VEHICLE : EQUIPMENT_TYPES.TRAILER);

  const status = [
    {
      title: 'Assigned',
      id: 15,
      value: 'Assigned',
      type: 'checkbox',
      status_id: '7',
      parent: 'Status',
    },
    {
      title: 'Available',
      id: 12,
      value: 'Available',
      type: 'checkbox',
      status_id: '1',
      parent: 'Status',
    },
    {
      title: 'On Shipment',
      id: 13,
      value: 'On Shipment',
      type: 'checkbox',
      status_id: '2',
      parent: 'Status',
    },
    {
      title: 'Out Of Service',
      id: 14,
      value: 'Out Of Service',
      type: 'checkbox',
      status_id: '3',
      parent: 'Status',
    },
    {
      title: 'Not Assigned',
      id: 14,
      value: 'Not Assigned',
      type: 'checkbox',
      status_id: '4',
      parent: 'Status',
    },
    {
      title: 'Dropped Trailer',
      id: 14,
      value: 'Dropped Trailer',
      type: 'checkbox',
      status_id: '6',
      parent: 'Status',
    },
    {
      title: 'Retired',
      id: 14,
      value: 'Retired',
      type: 'checkbox',
      status_id: '5',
      parent: 'Status',
    },
  ];

  const onChangeTrailer = (e, id) => {
    let updatedList = [...CheckboxValue];
    if (e.target.checked) {
      updatedList = [...CheckboxValue, id];
    } else {
      updatedList.splice(CheckboxValue.indexOf(id), 1);
    }
    setCheckboxValue(updatedList);
  };

  const GrouphandleChange = (e) => {
    const newGroupData = e.map((item) => item.value);
    setGroupData(newGroupData);
  };

  const onChangeStatus = (e, id) => {
    let updatedList = [...statusId];
    if (e.target.checked) {
      updatedList = [...statusId, id];
    } else {
      updatedList.splice(statusId.indexOf(id), 1);
    }
    setStatusId(updatedList);
  };

  const { data, isLoading } = useQuery('grp', Groups);
  if (isLoading) {
    return <span className='text-style' />;
  }
  const array = data.data.map((t) => ({
    label: `${t.group_name}`,
    value: t.id,
  }));
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      marginTop: 4,
      minWidth: 250,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
  };

  const equipmentData = (options, type) => {
    let data;
    options?.submenu?.forEach((item) => {
      item?.value === type ? (data = item?.submenu) : item?.submenu && equipmentData(item);
    });

    return data || [];
  };

  const updateTabs = () => {
    let groupvalue = [];
    if (groupData) {
      groupvalue = groupData;
    }

    const values = {
      type: modeType === 'Vehicle Type' ? 'vehicle' : 'trailer',
      data: {
        name: tabName,
        filters: {
          status: statusId,
          equipment_type_id: CheckboxValue,
          group_id: groupvalue,
        },
      },
      forAll: false,
    };
    axios
      .put(`${API_URL}/tabs/${editModalData?.id}`, values, {
        headers: authHeader(),
      })
      .then((res) => {
        EditModalData(res?.data);
        Tabs();
        onHide();
      });
  };
  const deleteTab = (id) => {
    axios
      .delete(`${API_URL}/tabs/${id}`, {
        headers: authHeader(),
      })
      .then(() => {
        setTimeout(() => {
          Tabs();
          const lastTabs = TabData[TabData.length - 2];
          OnTabChange(lastTabs?.id, lastTabs?.type, lastTabs);
          onChange(1, lastTabs?.id);
          onHide();
        }, 2000);
        handleClick();
        handleClose();
      });
  };

  return (
    <>
      <div className={styles['equipment-container']} style={{ backgroundColor: use(palette.gray50, palette.dark900) }}>
        <label className={styles.label_wrapper}>
          <span style={{ color: use(palette.gray700, palette.gray200) }}>Tab name</span>
          <input
            type='text'
            value={tabName}
            onChange={(e) => setTabName(e.target.value)}
            style={{
              backgroundColor: use(palette.white, palette.dark900),
              color: use(palette.gray700, palette.gray200),
            }}
          />
        </label>

        <div className={styles.select_wrapper}>
          {mySwitch && (
            <div className={styles.equipment_mode_wrapper}>
              {modeType === EQUIPMENT_TYPES.VEHICLE ? (
                <div className={styles.equipment_childes}>
                  {equipmentData(multiSelectOptions, EQUIPMENT_TYPES.VEHICLE).map((item) => {
                    return (
                      <div className={styles.equipment_child} key={item.id}>
                        <input
                          type='checkbox'
                          defaultChecked={editModalData?.data?.filters?.equipment_type_id.find(
                            (v) => v === item.equipment_id
                          )}
                          onChange={(e) => onChangeTrailer(e, item.equipment_id)}
                        />
                        <span
                          style={{
                            color: use(palette.gray700, palette.gray200),
                          }}
                        >
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={styles.equipment_childes}>
                  {equipmentData(multiSelectOptions, EQUIPMENT_TYPES.TRAILER).map((item) => {
                    return (
                      <div className={styles.equipment_child} key={item.equipment_id}>
                        <input
                          type='checkbox'
                          defaultChecked={editModalData?.data?.filters?.equipment_type_id.find(
                            (v) => v === item.equipment_id
                          )}
                          onChange={(e) => onChangeTrailer(e, item.equipment_id)}
                        />
                        <span
                          style={{
                            color: use(palette.gray700, palette.gray200),
                          }}
                        >
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          <div style={{ marginTop: 32 }}>
            <span style={{ marginLeft: 0 }}>If has Tag</span>
            <Select
              isMulti
              options={array}
              type='checkbox'
              name='array'
              defaultValue={array.filter((value) => editModalData?.data?.filters?.group_id.includes(value?.value))}
              onChange={GrouphandleChange}
              styles={customStyles}
            />
          </div>
          {modeType === EQUIPMENT_TYPES.VEHICLE ? (
            <div style={{ marginTop: 32 }}>
              <span style={{ marginLeft: 0 }}>If Status</span>
              {status.map((item) => {
                return (
                  item.title !== 'Dropped Trailer' &&
                  item.title !== 'Assigned' && (
                    <div>
                      <input
                        type='checkbox'
                        defaultChecked={editModalData?.data?.filters?.status.find((v) => v === item.status_id)}
                        onChange={(e) => onChangeStatus(e, item.status_id)}
                        key={item.id}
                      />
                      <span style={{ color: use(palette.gray700, palette.gray200) }}>{item.title}</span>
                    </div>
                  )
                );
              })}
            </div>
          ) : modeType === EQUIPMENT_TYPES.TRAILER ? (
            <div style={{ marginTop: 32 }}>
              <span style={{ marginLeft: 0 }}>If Status</span>
              {status.map((item) => {
                return (
                  item.title !== 'Available' &&
                  item.title !== 'On Shipment' && (
                    <div>
                      <input
                        type='checkbox'
                        defaultChecked={editModalData?.data?.filters?.status.find((v) => v === item.status_id)}
                        onChange={(e) => onChangeStatus(e, item.status_id)}
                        key={item.id}
                      />
                      <span style={{ color: use(palette.gray700, palette.gray200) }}>{item.title}</span>
                    </div>
                  )
                );
              })}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div style={{ backgroundColor: use(palette.white, palette.dark900) }}>
        <div className={styles.customFooter}>
          <div className={styles.deleteTabs} onClick={handleOpen}>
            <span className={styles.CustomFooterRemove}>Remove</span>
          </div>
          <div className='footer_buttons'>
            <button
              className={styles.TabCloseButton}
              onClick={onHide}
              style={{
                backgroundColor: use(palette.gray50, palette.dark900),
                color: use(palette.gray700, palette.gray200),
              }}
            >
              Close
            </button>
            <button disabled={isLoading} className={styles.addTabButtons} onClick={updateTabs}>
              Update
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <p className={styles.ConfirmActionMainContent}>Confirm Your Action</p>
          <p className={styles.ConfirmActionSubContent} id='modal-modal-description'>
            Are you sure you want to remove tab ?
          </p>
          <div className={styles.ConfirmActionFooter}>
            <button className={styles.ConfirmActionCloseButton} onClick={handleClose}>
              Close
            </button>
            <button className={styles.ConfirmActionRemoveButton} onClick={() => deleteTab(editModalData?.id)}>
              Remove
            </button>
          </div>
        </Box>
      </Modal>
      <Stack spacing={2} sx={{ width: '100%' }} className='toast-wrapper-success'>
        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert onClose={handleCloseAlert} severity='success' sx={{ width: '100%' }}>
            Tab has been deleted successfully
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default EquipmentModal;
