import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrows.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { deleteDeduction } from 'Api/Payroll';
import { getDeductionsList } from 'Api/PayrollSettings';
import DisputedActions from 'pages/Payroll/shared/DisputedActions';
import Recurring from './components/Recurring';
import DeductionsSettings from './components/DeductionsSettings';
import DeclineReason from '../../components/DeclineReason';
import { calcAppliedAmount } from '../../helpers';
import { getInitialValues } from './Deduction.data';
import { userTypes } from '../Tables.data';
import { STable, SWrapper, SAddMore, SPickerWrapper, SInput, SAutocomplete } from '../Tables.styles';

const Deduction = ({
  deductions,
  balances,
  onViewBalanceDetails,
  updateDeductions,
  user_type,
  settlement,
  gross,
  transactions,
  updateTransactions,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [deductionTypes, setDeductionTypes] = useState([]);
  const [loadingDeleteId, setLoadingDeleteId] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [deductionToDecline, setDeductionToDecline] = useState(null);

  const totalAppliedDeductions = useMemo(() => {
    return calcAppliedAmount(deductions) + calcAppliedAmount(transactions || []);
  }, [transactions, deductions]);

  const getDeductionTypes = async () => {
    try {
      const { data } = await getDeductionsList();
      setDeductionTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onMouseEnter = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(row.id);
    const newDeductions = deductions.map((item) =>
      item.id === row.id ? { ...item, edit: true } : { ...item, edit: false }
    );
    updateDeductions(newDeductions);
  };

  const onMouseLeave = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(null);
    const newDeductions = deductions.map((item) => (item.id === row.id ? { ...item, edit: false } : item));
    updateDeductions(newDeductions);
  };

  const onDateChange = (id, value) => {
    const date = moment(value).format('MM/DD/YYYY');
    const time = moment(value).format('HH:mm:ss');

    const newDeductions = deductions.map((item) => (item.id === id ? { ...item, date, time } : item));
    updateDeductions(newDeductions);
  };

  const onTypeChange = (id, value) => {
    const newDeductions = deductions.map((item) =>
      item.id === id
        ? {
            ...item,
            deduction_type: value,
            quantity: 1,
            per_quantity_amount: value.is_percent ? (gross * Number(value.percent)) / 100 : value.amount,
            amount: value.is_percent ? (gross * Number(value.percent)) / 100 : value.amount,
          }
        : item
    );
    updateDeductions(newDeductions);
  };

  const onQuantityChange = (id, value) => {
    const newDeductions = deductions.map((item) =>
      item.id === id ? { ...item, quantity: value, amount: Number(item.per_quantity_amount) * Number(value) } : item
    );
    updateDeductions(newDeductions);
  };

  const onAmountChange = (id, value) => {
    const newDeductions = deductions.map((item) =>
      item.id === id ? { ...item, per_quantity_amount: value, amount: Number(item.quantity) * Number(value) } : item
    );
    updateDeductions(newDeductions);
  };

  const onDayChange = (id, value) => {
    const newDeductions = deductions.map((item) => (item.id === id ? { ...item, day: value } : item));
    updateDeductions(newDeductions);
  };

  const onMonthWeekChange = (id, value) => {
    const newDeductions = deductions.map((item) => {
      return item.id === id ? { ...item, month_week: value, day: value === 'Month' ? '1st' : 'Monday' } : item;
    });
    updateDeductions(newDeductions);
  };

  const onAmortizedFieldChange = (id, name, value) => {
    const newDeductions = deductions.map((item) => {
      return item.id === id ? { ...item, [name]: value } : item;
    });
    updateDeductions(newDeductions);
  };

  // const onApplyChange = (id, checked) => {
  //   const newDeductions = deductions.map((item) => {
  //     return item.id === id ? { ...item, apply: checked } : item;
  //   });
  //   updateDeductions(newDeductions);
  // };
  //
  // const onTransactionApplyChange = (id, checked) => {
  //   const newTransactions = transactions.map((item) => {
  //     return item.id === id ? { ...item, apply: checked } : item;
  //   });
  //   updateTransactions(newTransactions);
  // };

  const handleSubmit = (id, newData) => {
    if (newData.amortized_settlement) {
      const newDeductions = deductions.map((item) => {
        return item.id === id
          ? {
              ...newData,
              amount: (newData.amortized_total / newData.amortized_months).toFixed(2),
              balance: (newData.amortized_total - newData.amortized_total / newData.amortized_months || 0).toFixed(2),
            }
          : item;
      });
      updateDeductions(newDeductions);
      return;
    }

    const newDeductions = deductions.map((item) => {
      return item.id === id ? { ...newData } : item;
    });
    updateDeductions(newDeductions);
  };

  const onAddMore = () => {
    updateDeductions((prevState) => [...prevState, { ...getInitialValues(), id: Date.now() }]);
  };

  const removeDeduction = async (row) => {
    if (row.isNew) {
      const newDeductions = deductions.filter((item) => item.id !== row.id);
      updateDeductions(newDeductions);
      return;
    }
    setLoadingDeleteId(row.id);
    try {
      await deleteDeduction({ user_type: userTypes[user_type], id: row.id });
      updateDeductions((prevState) => prevState.filter((item) => item.id !== row.id));
      showToaster({ type: 'success', message: 'Deduction has been deleted successfully' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingDeleteId(null);
  };

  const onSettingsClose = () => {
    getDeductionTypes();
    setSettingsOpen(false);
  };

  const onDecline = async (reason) => {
    if (deductionToDecline.deduction_type === 'transaction') {
      const newTransactions = transactions.map((item) =>
        item.id === deductionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
      );
      updateTransactions(newTransactions);
      setDeductionToDecline(null);
      return;
    }

    const newDeductions = deductions.map((item) =>
      item.id === deductionToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
    );
    updateDeductions(newDeductions);
    setDeductionToDecline(null);
  };

  const onApprove = (deduction) => {
    if (deduction.deduction_type === 'transaction') {
      const newTransactions = transactions.map((item) =>
        item.id === deduction.id ? { ...item, dispute_approved: true } : item
      );
      updateTransactions(newTransactions);
      return;
    }

    const newDeductions = deductions.map((item) =>
      item.id === deduction.id ? { ...item, dispute_approved: true } : item
    );
    updateDeductions(newDeductions);
  };

  useEffect(() => {
    getDeductionTypes();
  }, []);

  return (
    <SWrapper>
      <Typography variant='h3'>Deductions</Typography>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>DATE / TIME</th>
            <th>
              DEDUCTION TYPE
              <GearIcon onClick={() => setSettingsOpen(true)} className='settings-gear' />
            </th>
            <th>QUANTITY</th>
            <th>AMOUNT PER QUANTITY</th>
            <th>TOTAL</th>
            <th>SET INTERVAL</th>
            {/* <th>APPLY TO SETTLEMENT</th> */}
            <th />
          </tr>
        </thead>
        <tbody>
          {deductions?.map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.isNew ? 'bg-gray' : ''} ${
                  item.disputed && settlement.status_id === 4 ? 'disputed' : ''
                } ${item.dispute_approved ? 'dispute-approved' : ''}`}
                onMouseEnter={() => onMouseEnter(item)}
                onMouseLeave={() => onMouseLeave(item)}
              >
                <td style={{ width: '16%' }}>
                  {item.edit || item.isNew ? (
                    <SPickerWrapper>
                      <DatePicker
                        showTimeInput
                        showYearDropdown
                        selected={item.date && item.time ? new Date(`${item.date} ${item.time}`) : ''}
                        placeholderText='mm/dd/yyyy hh:mm'
                        dateFormat='MM/dd/yyyy h:mm aa'
                        onChange={(val) => onDateChange(item.id, val)}
                      />
                      <ArrowIcon className='arrow-icon' width={11} height={11} />
                    </SPickerWrapper>
                  ) : (
                    <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                      {formatDateTime(`${item.date} ${item.time}`)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '25%' }}>
                  {(item.edit || item.isNew) && !item.is_balance_deduction ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SAutocomplete
                        labelKey='deduction_type'
                        value={item.deduction_type || null}
                        onChange={(e, value) => onTypeChange(item.id, value)}
                        isOptionEqualToValue={(option, value) => option.deduction_type === value.deduction_type}
                        options={deductionTypes}
                      />
                      {!!item.amortized_settlement && !!item.balance && (
                        <Typography
                          variant='s3'
                          style={{ color: use(palette.gray700, palette.gray50), marginLeft: '50px' }}
                        >
                          Balance: {currency}
                          {formatNumber(item.balance || 0)}
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <>
                      <Typography variant='b2'>{item.deduction_type?.deduction_type || 'N/A'}</Typography>
                      {!!item.amortized_settlement && !!item.balance && (
                        <Typography
                          variant='s3'
                          style={{ color: use(palette.gray700, palette.gray50), marginLeft: '100px' }}
                        >
                          Balance: {currency}
                          {formatNumber(item.balance || 0)}
                        </Typography>
                      )}
                    </>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  {(item.edit || item.isNew) && !item.is_balance_deduction ? (
                    <SInput
                      type='number'
                      value={item.quantity || ''}
                      onChange={(e) => onQuantityChange(item.id, e.target.value)}
                      onKeyDown={(e) => {
                        blockNonPositiveNumbers(e);
                        blockNotNumberChars(e);
                      }}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {item.quantity || 0}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  {(item.edit || item.isNew) && !item.is_balance_deduction ? (
                    <SInput
                      type='number'
                      value={item.per_quantity_amount || ''}
                      onChange={(e) => onAmountChange(item.id, e.target.value)}
                      onKeyDown={blockNonPositiveNumbers}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {currency}
                      {formatNumber(item.per_quantity_amount || 0)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  <Typography variant='button2' className='nowrap'>
                    - {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
                <td style={{ width: '25%' }}>
                  <Recurring
                    data={item}
                    onDayChange={onDayChange}
                    onMonthWeekChange={onMonthWeekChange}
                    onAmortizedFieldChange={onAmortizedFieldChange}
                    handleSubmit={handleSubmit}
                  />
                </td>
                {/* <td style={{ width: '10%' }}> */}
                {/*  <SCheckboxWrapper> */}
                {/*    <CustomCheckbox checked={!!item.apply} onChange={(value) => onApplyChange(item.id, value)} /> */}
                {/*    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}> */}
                {/*      Deduct */}
                {/*    </Typography> */}
                {/*  </SCheckboxWrapper> */}
                {/* </td> */}
                <td style={{ width: '10%' }}>
                  {!item.is_balance_deduction ? (
                    loadingDeleteId === item.id ? (
                      <span className='loading-wrapper'>
                        <CircularProgress size={16} />
                      </span>
                    ) : (
                      <span className='action-wrapper' onClick={() => removeDeduction(item)}>
                        <DeleteIcon width={9} height={9} fill={palette.red500} />
                      </span>
                    )
                  ) : (
                    <span
                      className='action-wrapper-edit'
                      onClick={() => onViewBalanceDetails(balances.find((el) => el.id === item.balance_id))}
                    >
                      <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                        Edit Payment
                      </Typography>
                    </span>
                  )}
                </td>
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''} ${
                    item.dispute_approved ? 'dispute-approved-actions' : ''
                  }`}
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={() => onMouseLeave(item)}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setDeductionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          {(transactions || []).map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
                key={item.id}
              >
                <td style={{ width: '16%' }}>
                  <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                    {formatDateTime(item.transaction.date)}
                  </Typography>
                </td>
                <td style={{ width: '25%' }}>
                  <Typography variant='b2' style={{ marginLeft: '4px' }}>
                    {item.transaction.purchase_description} - REF# {item.transaction.invoice} -{' '}
                    {item.transaction.merchant}
                  </Typography>
                </td>
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }} />
                <td style={{ width: '8%' }}>
                  <Typography variant='button2' className='nowrap'>
                    - {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
                <td style={{ width: '30%' }}>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    One Time Deduction
                  </Typography>
                </td>
                {/* <td style={{ width: '10%' }}> */}
                {/*  <SCheckboxWrapper> */}
                {/*    <CustomCheckbox */}
                {/*      checked={!!item.apply} */}
                {/*      onChange={(value) => onTransactionApplyChange(item.id, value)} */}
                {/*    /> */}
                {/*    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}> */}
                {/*      Add */}
                {/*    </Typography> */}
                {/*  </SCheckboxWrapper> */}
                {/* </td> */}
                <td style={{ width: '5%' }} />
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''}
                ${item.dispute_approved ? 'dispute-approved-actions' : ''}`}
                  key={item.id}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setDeductionToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          <tr className='total-row'>
            <td>
              <SAddMore onClick={onAddMore}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                  Add Another...
                </Typography>
              </SAddMore>
            </td>
            <td />
            <td colSpan='2' className='align-end'>
              <Typography variant='button2' className='nowrap'>
                Total applied amount:
              </Typography>
            </td>
            <td>
              <Typography variant='button2' className='nowrap'>
                - {currency}
                {formatNumber(totalAppliedDeductions || 0)}
              </Typography>
            </td>
            <td colSpan='3' />
          </tr>
        </tbody>
      </STable>
      <DeductionsSettings open={settingsOpen} onClose={onSettingsClose} />
      <DeclineReason open={!!deductionToDecline} onClose={() => setDeductionToDecline(null)} onSubmit={onDecline} />
    </SWrapper>
  );
};

export default Deduction;
