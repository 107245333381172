import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function OwnerOperator(filter) {
  const params = {
    ...filter,
    'sort[][id]': 'desc',
  };
  const { data } = await axios.get(`${API_URL}/operators`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function OperatorGeneral({ id }) {
  const url = `${API_URL}/operator/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getStatus({ id }) {
  const url = `${API_URL}/operator/status/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function UpdateStatus(Status) {
  const url = `${API_URL}/operator/status`;
  const { data } = await axios.put(
    url,
    {
      ...Status,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function operatorDriverCount({ id }) {
  const url = `${API_URL}/operator/driver/count/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function operatorEquipmentCount({ id }) {
  const url = `${API_URL}/operator/equipment/count/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function operatorStats({ id }) {
  const url = `${API_URL}/operator/${id}/stats`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}
export async function operatorDriver({ id }) {
  const url = `${API_URL}/operator/driver/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function operatorEquipmentVehicle({ id }) {
  const url = `${API_URL}/operator/equipment/vehicle/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function operatorEquipmentTrailer({ id }) {
  const url = `${API_URL}/operator/equipment/trailer/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function operatorCompansationData({ id }) {
  const url = `${API_URL}/operator/compensation/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function operatorDocumentData({ id, sortField = 'sort[][id]', sortBy = 'desc' }) {
  const url = `${API_URL}/operator/document/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      [sortField]: sortBy,
    },
  });
  return data;
}

export async function operatorDocumentUpdate(doc) {
  const url = `${API_URL}/operator/document`;
  const { data } = await axios.put(url, { ...doc }, { headers: { ...authHeader() } });
  return data;
}

export async function OperatorRemoveDocumentData(id) {
  const url = `${API_URL}/operator/document/${id}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function OperatorLogData({
  page,
  query,
  sortField = 'sort[][id]',
  sortBy,
  itemsPerPage,
  operator_id,
  from_date,
  to_date,
}) {
  const url = `${API_URL}/operator/change-log`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      query,
      operator_id,
      itemsPerPage,
      [sortField]: sortBy,
      from_date,
      to_date,
    },
  });
  return data;
}

export async function operatorNoteData({ page, sort = 'asc', operator_id, itemsPerPage }) {
  const url = `${API_URL}/notes/operator/${operator_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      operator_id,
      'sort[][id]': sort,
      itemsPerPage,
    },
  });
  return data;
}

export async function operatorCreateNotes(notesData) {
  const url = `${API_URL}/notes/operator`;
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url,
    data: notesData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function deleteOperaotNote({ noteId }) {
  const url = `${API_URL}/notes/operator/${noteId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function ownerShipmentData({ page, query, itemsPerPage, operator_id, from_date, to_date }) {
  const url = `${API_URL}/operator/shipments/${operator_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      itemsPerPage,
      query,
      start_date: from_date,
      end_date: to_date,
    },
  });
  return data;
}
