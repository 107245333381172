import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 30%;
  min-width: 350px;
  max-width: 600px;
  border-right: 1px solid ${palette.gray100};
  padding: 12px 0 0 0;
`;

export const SCardsWrapper = styled.div`
  height: 78vh;
  overflow: auto;
  padding-right: 12px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${palette.gray300};
    border-radius: 8px;
  }
`;
