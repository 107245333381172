import React from 'react';
import { Typography } from 'components/Typography';
import ShipmentSummary from 'componentsV2/Shipment/ShipmentSummary';
import { SFlexColumn } from '../Shipments.styles';

const ShipmentId = ({ data }) => {
  return (
    <SFlexColumn>
      <Typography variant='s2'>
        {data.shipment_id} {data.shipment_type === 'ltl' && 'LTL'}
      </Typography>
      <ShipmentSummary shipmentId={data.shipment_id} className='ms-1' />
    </SFlexColumn>
  );
};

export default ShipmentId;
