import React, { useEffect, useRef, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import classNames from 'classnames';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import person from 'assets/images/person.svg';
import locationFilled from 'assets/icons/location-filled.svg';
import { getAlphabet, palette, statusColor } from 'utils/constants';
import './DispatchMap.css';
import { getAverageTimeConverted } from '../../StopPoint/Walmart/helpers';

const NAME_TYPES = {
  1: 'PICK UP',
  2: 'DELIVERY',
  3: 'WAYPOINT',
};

const CLASS_TYPES = {
  1: 'pickUp',
  2: 'delivery',
  3: 'wayPoint',
};

const DispatchMap = ({
  data,
  selectedVehicle,
  mapValues,
  children,
  empty_miles,
  previousShipment,
  planedShipment,
  customLatLong,
}) => {
  const [driverCoords, setDriverCoords] = useState([]);
  const [coords, setCoords] = useState([]);
  const [myMap, setMyMap] = useState(null);
  const [divContent, setDivContent] = useState(null);
  const [driverIconContentState, setDriverIconContentState] = useState(null);
  const [myDriverMarker, setMyDriverMarker] = useState(null);
  const [myDrive2rMarker, setMyDrive2rMarker] = useState(null);
  const [driverRoutes, setDriverRoutes] = useState([]);
  const miles = empty_miles?.toFixed();
  const routes = useRef([]);

  useEffect(() => {
    const startPoint = data.find((item) => item.stop_point_type === '1');

    setDriverCoords([
      {
        latitude:
          selectedVehicle?.location_target === 'Driver'
            ? selectedVehicle?.drivers[0]?.driver_gps?.latitude
            : selectedVehicle?.equipment_gps?.latitude,
        longitude:
          selectedVehicle?.location_target === 'Driver'
            ? selectedVehicle?.drivers[0]?.driver_gps?.longitude
            : selectedVehicle?.equipment_gps?.longitude,
      },
      {
        latitude: startPoint?.stop_point?.geofencing_latitude,
        longitude: startPoint?.stop_point?.geofencing_longitude,
      },
    ]);

    const coords = data.map((item) => ({
      latitude: item?.stop_point?.geofencing_latitude,
      longitude: item.stop_point?.geofencing_longitude,
      average_waiting_time: item.stop_point?.average_waiting_time,
      city: item.stop_point?.city,
      state: item.stop_point?.state,
      zipcode: item.stop_point?.zipcode,
      location_name: item.stop_point?.location_name,
    }));
    setCoords(coords);
  }, [data, selectedVehicle]);

  const popover = `<div class="vehicle-popup-wrapper">
      <div class="d-flex justify-content-between align-items-center vehicle-popup">
            <span class="vehicle-popup-driver">${selectedVehicle?.drivers[0]?.fname} ${
    selectedVehicle?.drivers[0]?.lname
  }</span>
            <div class="vehicle-popup-status" style="background-color: ${
              statusColor[selectedVehicle?.drivers[0]?.status_data?.driver_status]?.bgColor
            }" >
              <span style="color: ${statusColor[selectedVehicle?.drivers[0]?.status_data?.driver_status]?.color}">${
    selectedVehicle?.drivers[0]?.status_data?.driver_status
  }</span>
            </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-1">
        <div class="d-flex">
          <span class="number-truck me-1">${selectedVehicle?.equipment_id}</span> <span class="type-truck">${
    selectedVehicle?.equipment_type?.title
  }</span>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-1">
      </div>
      <div class="d-flex align-items-center gap-3 mt-1">
        <div>
          <img src="${locationFilled}" alt="">
        </div>
        <div class="d-flex flex-column">
          <span class="vehicle-popup-address">${
            selectedVehicle?.location_target === 'Driver'
              ? selectedVehicle?.drivers[0]?.driver_gps?.location
              : selectedVehicle?.equipment_gps?.location
          }</span>
        </div>
      </div>
    </div>
    `;

  useEffect(() => {
    let localMap;

    async function init() {
      if (!myMap) {
        TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
        localMap = new TrimbleMaps.Map({
          container: 'myMap',
          style: TrimbleMaps.Common.Style.BASIC,
          center: new TrimbleMaps.LngLat(-96, 35),
          zoom: 3,
        });
        setMyMap(localMap);
      }
    }

    init();

    return () => {
      if (localMap) {
        localMap.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (myMap) {
      const routeId = `myRoute-${Math.random()}`;
      const driverRouteId = `driverRoute-${Math.random()}`;
      /// ///// Marker //////////
      // Create a marker with SVG content
      coords.forEach((cord, i) => {
        const alphabet = getAlphabet(i);
        const id = data[i]?.stop_point_type;
        const svgContent = document.createElement('div');

        let label;

        const content = `
                             <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                                 <img src="${mapMarkerIcon}">
                                 <div class='myMap_marker_text_wrapper'>
                                   <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                                 </div> 
                                 <div 
                                  class="map_popover ${!label ? 'noneLAbel' : ''}"
                                  style="opacity: 0"
                                  >
                                  ${label}
                                 </div>
                             </div>
                             `;
        if (!divContent) {
          svgContent.innerHTML = content;
          setDivContent(content);
        }

        const popup = new TrimbleMaps.Popup({ anchor: 'left', closeButton: true, closeOnClick: false }).setLngLat([
          Number(cord?.longitude),
          Number(cord?.latitude),
        ]).setHTML(`
                                <div class="vehicle-popup-wrapper">
                                      <div class="d-flex align-items-center vehicle-popup">
                                            ${cord.location_name}
                                      </div>
                                     <div class="d-flex align-items-center vehicle-popup">
                                               <img src="${locationFilled}" alt="" class="me-2">
                                            ${cord.city?.name},  ${cord.state?.name} ${cord.zipcode}
                                      </div>
                                    <div class="d-flex align-items-center vehicle-popup">
                                           Avg. Wait Time:  ${getAverageTimeConverted(cord.average_waiting_time)}
                                      </div>
                                </div>    
                                `);

        const marker = new TrimbleMaps.Marker({
          draggable: false,
          element: svgContent,
        })
          .setLngLat([cord?.longitude, cord?.latitude])
          .addTo(myMap)
          .setPopup(popup);
        marker.getElement().addEventListener('mouseenter', () => {
          marker.togglePopup();
        });

        marker.getElement().addEventListener('mouseleave', () => {
          marker.togglePopup();
        });
      });

      const svgContent = document.createElement('div');
      const driverIconContent = `
                             <div class='myMap_driver_marker_container' id="driver-dead-head">
                                 <img src="${selectedVehicle?.drivers[0]?.image || person}" alt='driver'>
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                    <span>DEAD HEAD ${miles} Miles</span>
                                 </div> 
                             </div>`;
      if (!driverIconContentState) {
        svgContent.innerHTML = driverIconContent;
        setDriverIconContentState(driverIconContent);
      }

      const stopsMap = coords.map((cord) => new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude));

      let driverRoute;
      if (driverCoords && driverCoords.length) {
        const driverStops = driverCoords.map((cord) => {
          if (cord?.longitude && cord?.latitude) {
            return new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude);
          }
          return null;
        });
        /// ///// Marker //////////
        const driverRouteOptions = {
          routeId: driverRouteId,
          stops: driverStops,
          routeColor: palette.blue300, // optional routeColor,
          routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
          highwayOnly: !!mapValues.hwy_only,
          tollRoads: mapValues.avoid_tolls ? 2 : 3,
          hazMatType: mapValues.haz_mat ? 1 : 0,
          vehicleType: mapValues.vehicle_type,
          bordersOpen: !!mapValues.open_borders,
        };

        driverRoute = new TrimbleMaps.Route(driverRouteOptions);
      }

      if (driverRoute) {
        setDriverRoutes((prevState) => [...prevState, driverRoute]);
      }

      const routeOptions = {
        routeId,
        stops: stopsMap,
        routeColor: palette.indigo500, // optional routeColor,
        routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
        highwayOnly: !!mapValues.hwy_only,
        tollRoads: mapValues.avoid_tolls ? 2 : 3,
        hazMatType: mapValues.haz_mat ? 1 : 0,
        vehicleType: mapValues.vehicle_type,
        bordersOpen: !!mapValues.open_borders,
      };

      const myRoute = new TrimbleMaps.Route(routeOptions);

      if (myMap.loaded()) {
        if (routes.current.length) {
          routes.current.forEach((route) => route.remove());
          routes.current = [];
        }
        myRoute.addTo(myMap);
        routes.current = [myRoute];
      }

      myMap.on('load', async () => {
        myRoute.addTo(myMap);
        const svgContent = document.createElement('div');
        svgContent.innerHTML = `
                             <div class='myMap_driver_marker_container' id="dead-head">
                                 <img src="${selectedVehicle?.drivers[0]?.image || person}" alt='driver'>
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                    <span>DEAD HEAD ${miles} Miles</span>
                                 </div> 
                             </div>`;

        if (driverCoords[0]?.longitude) {
          const driverMarker = new TrimbleMaps.Marker({ draggable: false, element: svgContent })
            .setLngLat(new TrimbleMaps.LngLat(driverCoords[0]?.longitude, driverCoords[0]?.latitude))
            .addTo(myMap);
          setMyDrive2rMarker(driverMarker);
        }
        routes.current = [myRoute];
        driverRoute && driverRoute.addTo(myMap);
        myMap.setWeatherRadarVisibility(false);
        myMap.setTrafficVisibility(false);
      });
    }
  }, [coords, data, mapValues, myMap]); // Include all the necessary dependencies here

  useEffect(() => {
    if (myMap && myMap.loaded() && mapValues) {
      myMap.setWeatherRadarVisibility(!!mapValues.show_weather);
      myMap.setTrafficVisibility(!!mapValues.show_traffic);
    }
  }, [mapValues.show_weather, mapValues.show_traffic]);

  const removeCurrentDriverRoute = () => {
    if (driverRoutes) {
      driverRoutes.forEach((item) => item.remove());
    }
  };

  useEffect(() => {
    if (!myMap || !myMap.loaded()) {
      return;
    }

    myDrive2rMarker?.remove();
    myDriverMarker?.remove();
    if (planedShipment || previousShipment || customLatLong) {
      const { latitude, longitude } =
        customLatLong ||
        (planedShipment || previousShipment)?.shipment_stops?.[
          (planedShipment || previousShipment).shipment_stops.length - 1
        ]?.stop_point ||
        {};

      if (driverRoutes.length) {
        const stops = driverCoords.map((cord) => {
          if (cord?.longitude && cord?.latitude) {
            return new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude);
          }
          return null;
        });
        if (!latitude || !longitude) {
          removeCurrentDriverRoute();
          const driverRouteOptions = {
            routeId: Math.random().toString(),
            stops,
            routeColor: palette.blue300, // optional routeColor,
            routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
            highwayOnly: mapValues.highwayOnly,
            tollRoads: mapValues.avoid_tolls ? 2 : 3,
            hazMatType: mapValues.haz_mat ? 1 : 0,
            bordersOpen: !!mapValues.open_borders,
          };
          const newDriverRoute = new TrimbleMaps.Route(driverRouteOptions).addTo(myMap);
          setDriverRoutes((prevState) => [...prevState, newDriverRoute]);
          return;
        }

        stops[0] = new TrimbleMaps.LngLat(longitude, latitude);
        removeCurrentDriverRoute();
        const driverRouteOptions = {
          routeId: Math.random().toString(),
          stops,
          routeColor: palette.blue300, // optional routeColor,
          routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
          highwayOnly: mapValues.highwayOnly,
          tollRoads: mapValues.avoid_tolls ? 2 : 3,
          hazMatType: mapValues.haz_mat ? 1 : 0,
          bordersOpen: !!mapValues.open_borders,
        };
        const newDriverRoute = new TrimbleMaps.Route(driverRouteOptions).addTo(myMap);
        setDriverRoutes((prevState) => [...prevState, newDriverRoute]);
        const svgContent = document.createElement('div');
        svgContent.innerHTML = `
                             <div class='myMap_driver_marker_container' id="dead-head">
                                 <img src="${selectedVehicle?.drivers[0]?.image || person}" alt='driver'>
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                    <span>DEAD HEAD ${miles} Miles</span>
                                 </div> 
                             </div>`;

        if (driverCoords[0]?.longitude) {
          const driverMarker = new TrimbleMaps.Marker({
            draggable: false,
            element: svgContent,
          })
            .setLngLat(stops[0])
            .addTo(myMap);
          setMyDrive2rMarker(driverMarker);
        }
      }
    } else {
      if (!driverRoutes.length) {
        return;
      }
      removeCurrentDriverRoute();
      const stops = driverCoords.map((cord) => {
        if (cord?.longitude && cord?.latitude) {
          return new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude);
        }
        return null;
      });
      const driverRouteOptions = {
        routeId: Math.random().toString(),
        stops,
        routeColor: palette.blue300, // optional routeColor,
        routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
        highwayOnly: mapValues.highwayOnly,
        tollRoads: mapValues.avoid_tolls ? 2 : 3,
        hazMatType: mapValues.haz_mat ? 1 : 0,
        bordersOpen: !!mapValues.open_borders,
      };
      const svgContent = document.createElement('div');
      const driverIconContent = `
                             <div class='myMap_driver_marker_container' id="vazgen">
                                 <img src="${selectedVehicle?.drivers[0]?.image || person}" alt='driver'>
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                    <span>DEAD HEAD ${miles} Miles</span>
                                 </div> 
                             </div>`;
      svgContent.innerHTML = driverIconContent;
      setDriverIconContentState(driverIconContent);

      if (driverCoords[0]?.longitude) {
        const popoverMap = new TrimbleMaps.Popup({
          anchor: 'left',
          closeButton: true,
          closeOnClick: false,
        }).setHTML(popover);

        const driverMarker = new TrimbleMaps.Marker({
          draggable: false,
          element: svgContent,
        })
          .setLngLat([driverCoords[0]?.longitude, driverCoords[0]?.latitude])
          .setPopup(popoverMap)
          .addTo(myMap);
        setMyDriverMarker(driverMarker);
        driverMarker.getElement().addEventListener('mouseenter', () => {
          driverMarker.togglePopup();
        });

        driverMarker.getElement().addEventListener('mouseleave', () => {
          driverMarker.togglePopup();
        });
      }
      const newDriverRoute = new TrimbleMaps.Route(driverRouteOptions).addTo(myMap);
      setDriverRoutes((prevState) => [...prevState, newDriverRoute]);
    }
  }, [planedShipment, previousShipment?.id, empty_miles, customLatLong]);

  useEffect(() => {
    return () => {
      setMyMap(null);
      setDivContent(null);
      setDriverIconContentState(null);
    };
  }, []);

  return (
    <div className={classNames('plan_map_container')}>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100%', width: '100%' }} />
        {children}
      </div>
    </div>
  );
};

export default DispatchMap;
