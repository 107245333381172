import React, { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { retractBid } from 'Api/OfferedShipments';
import Countdown from '../Countdown';
import SubmitBid from '../SubmitBid';
import { SMapWidget, SMinimizeButton } from './MapWidget.styles';

const MapWidget = ({ shipment, onSuccess, onDeclineSuccess, onStartingLocationChange }) => {
  const showToaster = useShowToaster();
  const { dot, token, type } = useParams();
  const [loadingRetractBid, setLoadingRetractBid] = useState(false);
  const [expireDate, setExpireDate] = useState('');
  const [minimize, setMinimize] = useState(false);

  const { offer_amount, planner, bid } = shipment || {};
  const isExpired = !bid && !!expireDate && moment().isAfter(expireDate);

  const handleRetractBid = async () => {
    setLoadingRetractBid(true);
    try {
      await retractBid({ bid_id: bid.id }, { dot, token, type });
      showToaster({ type: 'success', message: `Bid has been successfully retracted!` });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingRetractBid(false);
    }
  };

  return (
    <SMapWidget $minimize={minimize}>
      <SMinimizeButton onClick={() => setMinimize((prevState) => !prevState)}>
        {minimize ? <PlusIcon width={12} height={12} /> : <MinusIcon width={12} height={12} />}
      </SMinimizeButton>
      {!minimize && (
        <>
          <Countdown shipment={shipment} expireDate={expireDate} setExpireDate={setExpireDate} />
          <div className='d-flex justify-content-between align-items-center'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {planner.shipment_stops?.length} Stops
            </Typography>
            {!!Number(offer_amount) && (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                Posted Rate ${formatNumber(offer_amount)}
              </Typography>
            )}
          </div>
          <Divider margin='16px 0' />
          <SubmitBid
            shipment={shipment}
            onSuccess={onSuccess}
            onDeclineSuccess={onDeclineSuccess}
            onStartingLocationChange={onStartingLocationChange}
            handleRetractBid={handleRetractBid}
            loadingRetractBid={loadingRetractBid}
            readOnly={!!bid?.offer_accepted}
            isExpired={isExpired}
          />
        </>
      )}
    </SMapWidget>
  );
};

export default MapWidget;
