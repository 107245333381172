import React from 'react';
import { SErrorMessage } from './ErrorMessage.styles';

const ErrorMessage = ({ error, className, ...props }) => {
  if (!error) {
    return null;
  }

  return (
    <SErrorMessage className={className} {...props}>
      {error}
    </SErrorMessage>
  );
};

export default ErrorMessage;
