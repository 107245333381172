import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import logo from 'assets/images/Mask Group.png';
import { ReactComponent as EyeIcon } from 'assets/icons/eyeS.svg';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { checkPasswordStrength } from 'utils/helpers';
import { resetPassword } from 'Api/User';
import { validationSchema } from './validationSchema';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [strength, setStrength] = useState({ strength: '', color: '' });
  const [inputType, setInputType] = useState({ password: 'password', password_confirmation: 'password' });

  const onSubmit = async (values) => {
    try {
      if (strength.strength < 3) {
        showToaster({ type: 'error', message: 'Password is weak, please pick better password!' });
        return;
      }

      setLoading(true);

      const body = {
        email: decodeURIComponent(email),
        token,
        password: values.password,
        password_confirmation: values.password_confirmation,
      };

      await resetPassword(body);
      showToaster({ type: 'success', message: 'You have successfully changed your password! ' });
      navigate('/login');
    } catch (error) {
      const message = getErrorMessage(error);
      if (message.includes('password reset token is invalid')) {
        setIsExpired(true);
        showToaster({ type: 'error', message: 'This link is expired or already used, please try to send new link.' });
        return;
      }
      showToaster({ type: 'error', message });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    onSubmit,
    validationSchema,
  });

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    handleChange('password', value);
    setStrength(checkPasswordStrength(value));
  };

  const onEyeClick = (field) => {
    setInputType((prevState) => ({ ...prevState, [field]: prevState[field] === 'password' ? 'text' : 'password' }));
  };

  return (
    <div>
      <div className='signin-layout'>
        <div className='video-cover'>
          <video
            autoPlay
            loop
            muted
            playsInline
            data-wf-ignore
            data-object-fit='cover'
            id='video'
            poster='assets/images/image 23.png'
          >
            <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.mp4`} data-wf-ignore='true' />
            <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.webm`} data-wf-ignore='true' />
          </video>
        </div>

        <div className='purple-filter' />
        <div className='form-container'>
          <div className='logo'>
            <img src={logo} alt='Logo' />
          </div>
          <div style={{ textAlign: 'center' }}>
            <h1 className='header'>Reset Password</h1>
          </div>
          <div style={{ textAlign: 'center' }}>
            <p className='subheader'>Enter your new password.</p>
          </div>
          <div className='d-flex align-items-center justify-content-center mt-4'>
            <div className='d-flex flex-column gap-4' style={{ width: '400px' }}>
              <Input type='email' id='username' name='username' value={email} disabled />
              <div>
                <Input
                  type={inputType.password}
                  placeholder='New Password'
                  name='password'
                  values={values.password}
                  onChange={handlePasswordChange}
                  error={touchedErrors.password}
                  endAdornment={
                    <EyeIcon
                      fill={palette.gray300}
                      width={16}
                      height={16}
                      className='end-adornment'
                      onClick={() => onEyeClick('password')}
                      style={{ top: '10px' }}
                    />
                  }
                />
                {strength.strength && (
                  <div className='d-flex gap-1 mt-1'>
                    <Typography variant='b2' style={{ color: palette.white }}>
                      Strength:
                    </Typography>
                    <Typography variant='b2' style={{ color: strength.color, backgroundColor: palette.white }}>
                      {strength.strength}
                    </Typography>
                  </div>
                )}
              </div>
              <Input
                type={inputType.password_confirmation}
                placeholder='Confirm New Password'
                name='password_confirmation'
                values={values.password_confirmation}
                onChange={handleChange}
                error={touchedErrors.password_confirmation}
                endAdornment={
                  <EyeIcon
                    fill={palette.gray300}
                    width={16}
                    height={16}
                    className='end-adornment'
                    onClick={() => onEyeClick('password_confirmation')}
                    style={{ top: '10px' }}
                  />
                }
              />
              <button className='button btn' onClick={handleSubmit} disabled={loading}>
                Reset Password
              </button>
              {isExpired && (
                <p
                  className='subheader txt-btn'
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => navigate('/forgot-password')}
                >
                  Back to <b>Forgot Password</b>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
