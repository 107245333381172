import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

const StatusChangeOverlay = ({ onChange, onChaneShow, show, data, defaultSelected }) => {
  const { use } = useTheme();
  const target = useRef(null);

  const [activeStatus, setActiveStatus] = useState();

  useEffect(() => {
    !!defaultSelected && setActiveStatus(defaultSelected);
  }, []);

  const headerStyle = useMemo(() => {
    return {
      overLay: {
        width: 250,
        borderRadius: 6,
        padding: '4px',
        position: 'absolute',
        backgroundColor: use(palette.white, palette.dark800),
        boxShadow:
          '0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2), 0 5px 15px rgba(0, 0, 0, 0.08)',
      },
      triggerWrapper: {
        width: 'fit-content',
        cursor: 'pointer',
        marginRight: 5,
      },
      trigger: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '16px',
        fontStyle: 'normal',
        letterSpacing: '0.04em',
        whiteSpace: 'nowrap',
        padding: '2px 6px',
        display: 'flex',
        borderRadius: 6,
        cursor: 'pointer',
      },
    };
  }, [use, palette, activeStatus]);

  return (
    <div className='customer-status_wrapper'>
      <div className='d-flex align-items-center' onClick={() => onChaneShow(!show)}>
        <div
          ref={target}
          style={{
            ...headerStyle.triggerWrapper,
            backgroundColor: defaultSelected?.background_color,
            color: defaultSelected?.color,
          }}
        >
          <Typography variant='s2' style={headerStyle.trigger}>
            {defaultSelected?.title}
          </Typography>
        </div>
        <ArrowDown />
      </div>

      <Overlay placement='bottom-start' rootClose show={show} target={target.current} onHide={() => onChaneShow(false)}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div {...props} style={{ ...headerStyle.overLay, ...props.style }}>
            <div>
              {data.map((status) => {
                const active = activeStatus?.id === status?.id;
                return (
                  <div
                    onClick={() => {
                      !!onChange && onChange(status);
                      setActiveStatus(status);
                      onChaneShow(!show);
                    }}
                    key={status.id}
                    className='customer_header_status_item'
                    style={{ backgroundColor: active ? palette.indigo500 : use(palette.white, palette.dark800) }}
                  >
                    <Typography
                      variant='s2'
                      style={{ color: active ? palette.white : use(palette.gray900, palette.gray50) }}
                    >
                      {_.startCase(_.toLower(status.title))}
                    </Typography>
                    {active && <TickIcon fill={palette.white} />}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default StatusChangeOverlay;
