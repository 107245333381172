import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;

  .title-wrapper {
    display: flex;
    justify-content: space-between;

    .edit-action {
      height: 32px;
      padding: 2px 14px;
      border-radius: 6px;
      cursor: pointer;

      :hover {
        background-color: ${palette.indigo0};
      }

      svg {
        transform: rotate(90deg);
      }
    }
  }
`;
