import React, { useState } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { SPageWrapper } from './Accounts.styles';

const Accounts = () => {
  const [activeAccount, setActiveAccount] = useState(null);
  const [accountToEdit, setAccountToEdit] = useState(null);
  const [refreshIndex, setRefreshIndex] = useState(Date.now());

  const updateActiveAccount = (account) => {
    setActiveAccount(account);
    window.scrollTo(0, 0, { behavior: 'smooth' });
  };

  return (
    <SPageWrapper>
      <LeftSection
        activeAccount={activeAccount}
        updateActiveAccount={updateActiveAccount}
        accountToEdit={accountToEdit}
        setAccountToEdit={setAccountToEdit}
        refreshIndex={refreshIndex}
      />
      <RightSection
        account={activeAccount}
        onEdit={() => setAccountToEdit(activeAccount)}
        refreshAccountsList={() => setRefreshIndex(Date.now())}
      />
    </SPageWrapper>
  );
};

export default Accounts;
