import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import CustomButton from 'components/CustomButton/CustomButton';
import { SWrapper } from './PageHeader.styles';
import { SHeaderActions } from '../../pagesComponents/Payroll/Payroll.styles';

const PageHeader = ({
  title,
  isConfiguringStep,
  onCancelPrevPage,
  onSaveAndNext,
  handleSubmit,
  loading,
  hideSave,
  hideSkip,
}) => {
  return (
    <SWrapper>
      <Typography variant='h2' style={{ color: palette.gray900 }}>
        {title}
      </Typography>
      <SHeaderActions>
        {isConfiguringStep && (
          <>
            <CustomButton
              onClick={onCancelPrevPage}
              type='secondary'
              title='Back'
              styleButton={{ margin: 0 }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
            />
            {!hideSkip && (
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                disabled={loading}
              />
            )}
          </>
        )}
        {(!hideSave || isConfiguringStep) && (
          <CustomButton
            onClick={handleSubmit}
            type='primary'
            title={isConfiguringStep ? 'Save and Next' : 'Save'}
            styleButton={{ margin: 0 }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            disabled={loading}
          />
        )}
      </SHeaderActions>
    </SWrapper>
  );
};

export default PageHeader;
