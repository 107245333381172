import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Collapse } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { getCustomersList } from 'Api/Customers';
import { getAssignToGroupBillingCharges } from 'Api/Planner';
import Autocomplete from '../../../common/Autocomplete';

import classes from './AdvancedFilter.module.scss';

const AdvancedFilter = ({ open, filter, setFilter, setSearch, onSearch, statuses, payTerms, children }) => {
  const [badgeData, setBadgeData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [customerIds, setCustomerIds] = useState([]);
  const [generalLedgerIds, setGeneralLedgerIds] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(false);

  const getAllCustomers = async () => {
    try {
      setCustomersLoading(true);
      const { data } = await getCustomersList({ page: 1, itemsPerPage: 10000 });
      setCustomerIds(data.filter((el) => !!el.customer_id).map((el) => ({ id: el.id, title: el.customer_id })));
      setCompanyNames(data.map((el) => ({ id: el.id, title: el.company_name })));
      setGeneralLedgerIds(
        data.filter((el) => !!el.general_ledger_id).map((el) => ({ id: el.id, title: el.general_ledger_id }))
      );
    } catch (e) {
      // Do nothing
    } finally {
      setCustomersLoading(false);
    }
  };

  const getFilterByGroups = async () => {
    try {
      const { data } = await getAssignToGroupBillingCharges();
      data && setGroupsData(data);
    } catch (e) {
      // Do noting
    }
  };

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    return {
      name: item?.[nameKey] || '',
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(filter.status
        ? [
            {
              title: 'Status',
              children: filter.status.map((item) => normalizeBadgeData(item, 'label', 'id', { key: 'status' })),
            },
          ]
        : []),
      ...(filter.pay_term
        ? [
            {
              title: 'Pay Terms',
              children: filter.pay_term.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'pay_term' })),
            },
          ]
        : []),
      ...(filter?.group_id
        ? [
            {
              title: 'Tag',
              children: filter.group_id.map((item) =>
                normalizeBadgeData(item, 'group_name', 'id', { key: 'group_id' })
              ),
            },
          ]
        : []),
      ...(filter?.customer_id
        ? [
            {
              title: 'Customer ID',
              children: filter.customer_id.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'customer_id' })
              ),
            },
          ]
        : []),
      ...(filter?.company_name
        ? [
            {
              title: 'Company',
              children: filter.company_name.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'company_name' })
              ),
            },
          ]
        : []),
      ...(filter?.general_ledger_id
        ? [
            {
              title: 'General Ledger ID',
              children: filter.general_ledger_id.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'general_ledger_id' })
              ),
            },
          ]
        : []),
    ];
  }, []);

  const onSearchFilter = () => {
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      const newItemValue = itemValue.filter((el) => el.id !== id);

      if (newItemValue.length === 0) {
        delete newFilter[key];
      } else {
        newFilter[key] = newItemValue;
      }
    } else if (typeof itemValue === 'string') {
      delete newFilter[key];
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));
  };

  const handleClearAll = () => {
    onSearch({});
    setSearch('');
    setFilter({});
    setBadgeData([]);
  };

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
    } else {
      newFilter[key] = value;
    }
    return setFilter(newFilter);
  };

  useEffect(() => {
    getAllCustomers();
    getFilterByGroups();
  }, []);

  return (
    <>
      {children?.({ onSearchFilter })}
      <Collapse in={open}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.customer_id || []}
                options={customerIds}
                loading={customersLoading}
                placeholder='Customer ID'
                onChange={(e, value) => onChangeMultiSelectValues('customer_id', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.company_name || []}
                options={companyNames}
                loading={customersLoading}
                placeholder='Company'
                onChange={(e, value) => onChangeMultiSelectValues('company_name', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={statuses}
                placeholder='Status'
                value={filter?.status || []}
                onChange={(e, value) => onChangeMultiSelectValues('status', value)}
                getTagLabel={(option) => (option ? option?.label : '')}
                getOptionLabel={(option) => (option ? option?.label : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={payTerms}
                placeholder='Pay Terms'
                value={filter?.pay_term || []}
                onChange={(e, value) => onChangeMultiSelectValues('pay_term', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.group_id || []}
                options={groupsData}
                placeholder='Tags'
                onChange={(e, value) => onChangeMultiSelectValues('group_id', value)}
                getTagLabel={(option) => (option ? option?.group_name : '')}
                getOptionLabel={(option) => (option ? option?.group_name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.general_ledger_id || []}
                options={generalLedgerIds}
                loading={customersLoading}
                placeholder='General Ledger ID'
                onChange={(e, value) => onChangeMultiSelectValues('general_ledger_id', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>
          </div>
          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>

      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index ? <span className={classes.comma}>,</span> : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AdvancedFilter;
