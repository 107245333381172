import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin-bottom: 36px;

  .accordion-arrow {
    transform: rotate(180deg);

    path {
      fill: ${palette.gray400};
    }
  }
`;

export const SAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    .MuiAccordionSummary-root {
      flex-direction: row-reverse;
      min-height: auto;
      width: fit-content;

      .MuiAccordionSummary-content {
        margin: 4px 0 4px 12px;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0 0 0 36px;
    }
  }
`;

export const STableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${palette.gray50};
`;

export const SItem = styled.div`
  width: 50%;
  padding: 8px 12px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s;

  .title-wrapper {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  :nth-child(even) {
    border-bottom: 1px solid ${palette.gray50};
  }

  :nth-child(odd) {
    border-right: 1px solid ${palette.gray50};
    border-bottom: 1px solid ${palette.gray50};
  }

  .item-star-icon {
    cursor: pointer;
  }

  .arrow-icon {
    visibility: hidden;

    path {
      transition: fill 0.3s;
      fill: ${palette.white};
    }
  }

  :hover {
    background-color: ${palette.indigo0};

    .arrow-icon {
      visibility: visible;

      path {
        fill: ${palette.gray500};
      }
    }
  }
`;
