import React from 'react';
import { STableWrapper } from '../AccidentsAndCrashes.styles';
import Accidents from './Accidents';

const TableSection = ({ refreshStats }) => {
  return (
    <STableWrapper>
      <Accidents refreshStats={refreshStats} />
    </STableWrapper>
  );
};

export default TableSection;
