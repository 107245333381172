import React, { useState } from 'react';
import useShowToaster from 'hooks/useShowToaster';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CustomButton from 'components/CustomButton/CustomButton';
import { SWrapper } from 'pages/InvoicePayment/components/MakePayment/MakePayment.styles';

const MakePayment = ({ invoice, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      showToaster({ type: 'error', message: 'Stripe is not loaded yet. Please try again!' });
      return;
    }

    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: invoice.invoice?.stripe_payment_link || invoice.batch?.stripe_payment_link,
      },
      redirect: 'if_required',
    });

    if (result.error) {
      showToaster({ type: 'error', message: result.error.message || 'Something went wrong' });
    } else {
      showToaster({ type: 'success', message: 'Payment succeeded!' });
      onSuccess();
    }
    setLoading(false);
  };

  return (
    <SWrapper>
      <div className='mb-4'>
        <PaymentElement
          options={{
            wallets: { applePay: 'auto', googlePay: 'auto' },
            defaultValues: {
              billingDetails: { email: invoice.invoice?.email_to, name: invoice.invoice?.customer?.company_name },
            },
          }}
        />
      </div>
      <CustomButton
        type='primary'
        styleTitle={{ fontSize: 14 }}
        styleButton={{ padding: '6px 12px', margin: 0, width: '100%', justifyContent: 'center' }}
        title='Pay'
        onClick={handleSubmit}
        disabled={loading || !stripe}
      />
    </SWrapper>
  );
};

export default MakePayment;
