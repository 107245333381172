import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SNameImage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;

  .profile-image-container {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      box-sizing: border-box;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
      outline: 1px solid ${palette.gray50};
    }

    .profile-active-indicator {
      position: absolute;
      right: 6px;
      bottom: 2px;
      background: #38a06c;
      box-shadow: 0 0 0 2px #ffffff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  .user-name-id {
    display: flex;
    flex-direction: column;

    .user-name-icons {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }
  }
`;

export const SEmailPhone = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;

  a {
    display: inline-flex;
  }

  svg {
    cursor: pointer;
  }
`;
