import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  height: 100%;
  font-family: 'Inter', sans-serif;
  margin-top: 16px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const STableWrapper = styled.div`
  margin-top: 66px;
  margin-bottom: 100px;

  .MuiTableRow-root th.MuiTableCell-head {
    :first-child {
      width: 50px !important;
    }
  }
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0;
    margin-top: 12px;

    .MuiTabs-flexContainer {
      column-gap: 8px;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      min-height: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      padding: 3px 10px;
      text-transform: none;
      color: ${palette.gray900};
      border-bottom: 2px solid ${palette.white};

      &.Mui-selected {
        border-bottom: 2px solid ${palette.indigo500};
        color: ${palette.indigo500};

        :hover {
          border-bottom: 2px solid ${palette.indigo500};
        }
      }

      .MuiTab-iconWrapper {
        font-family: 'Inter', sans-serif;
        border-radius: 8px;
        padding: 2px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        background-color: #f0f1fa;
      }
    }

    .MuiButtonBase-root:hover {
      border-bottom: 2px solid ${palette.gray400};
    }
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const SSimpleList = styled.table`
  width: 100%;
  margin-top: 12px;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 10px 12px;

    &.text-right {
      text-align: right;
    }
  }

  td {
    padding: 10px 12px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
      width: 100%;
    }

    :last-child {
      border: none;
      background-color: ${palette.white};
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const SSimpleListRow = styled.tr`
  transition: background-color 0.2s;

  &.selected-row {
    background-color: ${palette.indigo0};
  }

  :hover {
    background-color: ${palette.indigo0};
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell > div {
    visibility: hidden;
    transition: visibility 0.1s;
  }

  :hover {
    background-color: ${palette.gray0};

    .actions-cell > div {
      visibility: visible;
    }
  }
`;

export const STableSection = styled.div`
  display: flex;

  .left-section {
    width: 100%;
    min-width: 330px;
    max-width: 500px;
    min-height: 500px;
  }

  .right-section {
    border-top: 1px solid ${palette.gray50};
    border-left: 1px solid ${palette.gray50};
    width: 100%;
    min-width: 70%;
    max-width: 100%;
    margin-top: 50px;
  }
`;

export const STransactionsWrapper = styled.div`
  overflow: auto;
  padding: 0 20px 16px;

  .table-title {
    margin: 16px 0 12px;
  }

  .table-container {
    width: max-content;
    min-width: 100%;

    &.fixed-header {
      height: calc(100vh - 300px);

      thead {
        th {
          box-shadow: inset 0px -3px 0px -2px ${palette.gray50};
          background-color: ${palette.white};
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${palette.gray200};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${palette.gray0};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${palette.gray300};
  }
`;
