export const STATUSES = [
  {
    id: 'Pending',
    title: 'Pending',
  },
  {
    id: 'Approved',
    title: 'Approved',
  },
  {
    id: 'Rejected',
    title: 'Rejected',
  },
];
