import { useSelector } from 'react-redux';
import { COUNTRIES } from 'pages/PublicPositions/ApplyJob/ApplyJob.data';
import { PERMISSIONS } from 'utils/constants';
import jsPDF from 'jspdf';

export const generateId = (firstName, lastName, dateTime) => {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  const [datePart, timePart] = dateTime.split(' ');
  const formattedDate = datePart.replace(/\//g, '');
  const formattedTime = timePart.replace(/:/g, '');

  return `${initials}${formattedDate}${formattedTime}`;
};

export const getInitialValues = (incident, customerTime, firstName, lastName, dateTime) => {
  const { permissions } = useSelector((state) => state?.root);

  const {
    incident_id,
    report_number,
    incident_date,
    incident_time,
    country,
    city,
    state,
    incident_type,
    incident_record_documents,
    statement_document,
    incident_record_images,
    incident_record_video,
    incident_action_taken,
    notes,
    report_details,
    reason,
    vehicles_involved,
    are_you_injured,
    anyone_injured,
    how_many_individuals_injured,
    is_there_fuel_split,
    direction_of_travel,
    doing_at_accident_time,
    jack_knifed_turn_over,
    is_vehicle_drivable,
    need_to_be_towed,
    is_freight_damage_trailer,
    police_arrived_at_the_scene,
    police_department,
    are_you_on_the_shipment,
    shipment_number,
    inspection_level,
    inspection_status,
    inspection_out_of_service,
    added_by,
  } = incident || {};

  return {
    driver: null,
    incident_id: incident_id || (firstName ? generateId(firstName, lastName, dateTime) : ''),
    report_number: report_number || '',
    incident_date:
      incident_date && incident_time ? new Date(`${incident_date} ${incident_time}`) : new Date(customerTime),
    country: country || COUNTRIES[0],
    city: city || null,
    state: state || null,
    incident_type: incident_type || null,
    document_path: incident_record_documents || [],
    document_path_name: [],
    statement_document: statement_document || '',
    incident_record_images: incident_record_images?.map((image) => image.image_path) || [],
    incident_record_video: incident_record_video?.map((image) => image.image_path) || [],
    action_taken: incident_action_taken || null,
    notes: notes || '',
    added_by: added_by || null,
    report_details: report_details || '',
    reason: reason || '',
    vehicles_involved: vehicles_involved || '',
    are_you_injured: typeof are_you_injured === 'number' ? are_you_injured : '',
    anyone_injured: typeof anyone_injured === 'number' ? anyone_injured : '',
    how_many_individuals_injured: how_many_individuals_injured || '',
    is_there_fuel_split: typeof is_there_fuel_split === 'number' ? is_there_fuel_split : '',
    direction_of_travel: direction_of_travel || '',
    doing_at_accident_time: doing_at_accident_time || '',
    jack_knifed_turn_over: typeof jack_knifed_turn_over === 'number' ? jack_knifed_turn_over : '',
    is_vehicle_drivable: typeof is_vehicle_drivable === 'number' ? is_vehicle_drivable : '',
    need_to_be_towed: typeof need_to_be_towed === 'number' ? need_to_be_towed : '',
    is_freight_damage_trailer: typeof is_freight_damage_trailer === 'number' ? is_freight_damage_trailer : '',
    police_arrived_at_the_scene: typeof police_arrived_at_the_scene === 'number' ? police_arrived_at_the_scene : '',
    police_department: police_department || '',
    are_you_on_the_shipment: typeof are_you_on_the_shipment === 'number' ? are_you_on_the_shipment : '',
    shipment_number: shipment_number || '',
    inspection_level: inspection_level || '',
    inspection_status: typeof inspection_status === 'number' ? inspection_status : '',
    inspection_out_of_service: typeof inspection_out_of_service === 'number' ? inspection_out_of_service : '',
    canReview: permissions.includes(PERMISSIONS.CAN_REVIEW_INCIDENTS.name) || false,
  };
};

export const generatePdf = (files) => {
  const doc = new jsPDF({ format: 'letter' });

  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  files.forEach((file, index) => {
    if (file?.endsWith('.png') || file?.endsWith('.jpg') || file?.endsWith('.jpeg')) {
      if (index !== 0) {
        doc.addPage();
      }
      doc.addImage(
        `${file}?cache=${Math.random()}`,
        'JPEG',
        16,
        16,
        pageWidth - 32,
        pageHeight - 50,
        `image-${index}`,
        'FAST'
      );
    }
  });

  doc.setDocumentProperties({ title: `Incident Documents` });

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  return { blob, url };
};
