import React, { useState } from 'react';
import axios from 'axios';
import { palette } from 'utils/constants';
import authHeader from 'services/auth-header';
import style from './AlertIcon.module.css';

const AlertIcon = ({ alertData, alertID, driverAlert, shipmentAlert, equipmentAlert }) => {
  const [data, setData] = useState([...alertData]);

  const allAlerts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/equipment-alert/${alertID}`, {
        headers: authHeader(),
      })
      .then((res) => setData([...data, ...(res?.data?.data || [])]));
  };

  return (
    <>
      <div className={style.AlertHeader} style={{ backgroundColor: palette.white }}>
        <p className={style.ExpiringItems} style={{ color: palette.dark800 }}>
          {shipmentAlert || driverAlert || 'Equipment Alerts'}{' '}
          <span className={style.OrangeAlertCounting}>{data?.length}</span>
        </p>
      </div>
      <div className={style.AlertBody} style={{ backgroundColor: palette.white }}>
        {data?.map((a) =>
          shipmentAlert ? (
            <p className={style.AlertMsg} style={{ color: palette.dark800 }}>
              {a?.billing_customer?.company_name}
            </p>
          ) : (
            <p className={style.AlertMsg} style={{ color: palette.dark800 }}>
              {a.alert_message ? a.alert_message : a}
            </p>
          )
        )}
        {shipmentAlert ? null : equipmentAlert ? null : driverAlert ? null : data?.length === 3 ? (
          <button onClick={allAlerts} className={style.seeAllButton}>
            See All
          </button>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default AlertIcon;
