import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { palette } from 'utils/constants';
import CircularProgress from '@mui/material/CircularProgress';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  tension: 0.1,
  borderWidth: 2,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
    tooltip: {
      callbacks: {
        title(item) {
          const data = item[0];

          if (!data) {
            return null;
          }

          const count = data.dataset.data[data.dataIndex];
          return `${data.label} - ${count} Shipment${count === 1 ? '' : 's'}`;
        },
        label() {
          return null;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      boxPadding: 3,
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value, index, values) {
          if (index === 0) {
            return moment().subtract(1, 'month').format('MMM D');
          }
          if (index === values.length - 1) {
            return 'Today';
          }
        },
      },
    },
    y: {
      suggestedMax: 5,
      beginAtZero: true,
      grid: {
        borderDash: [8, 8],
        color: palette.gray50,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
};

export const chartShipmentData = {
  labels: [...new Array(31).fill('')],
  datasets: [
    {
      fill: true,
      label: '',
      data: [], // 417, 418, 145, 11, 149, 111, 227, 535, 333, 313, 933, 534, 213, 178, 413, 131, 518, 175, 12, 674, 675, 34, 34
      borderColor: '#cccccc',
      backgroundColor: 'rgba(204, 204, 204, 0.2)',
    },
    {
      fill: true,
      label: '',
      data: [], // 47, 438, 945, 411, 49, 191, 257, 555, 33, 31, 331, 564, 223, 78, 43, 111, 588, 975
      borderColor: palette.indigo500,
      backgroundColor: 'rgba(79, 90, 237, 0.2)',
      pointBackgroundColor: palette.indigo500,
    },
  ],
};

const LineChart = ({ driverShipmentData }) => {
  const { loading, data } = driverShipmentData;
  const [chartData, setChartData] = useState({ ...chartShipmentData });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loading && !data?.length) {
      return;
    }
    const tempChartData = { ...chartShipmentData };

    const prevMonthData = [];
    const currentMonthData = [];

    for (const [key, value] of Object.entries(data)) {
      if (moment(key).isSameOrAfter(moment().endOf('day'))) {
        continue;
      }

      if (moment(key).isSameOrAfter(moment().startOf('month'))) {
        currentMonthData.push(value);
      } else {
        prevMonthData.push(value);
      }
    }

    tempChartData.datasets[0].data = prevMonthData;
    tempChartData.datasets[1].data = [...Array(prevMonthData.length).fill(null), ...currentMonthData];
    tempChartData.labels = [...prevMonthData, ...currentMonthData].map((item, i) =>
      moment(Object.keys(data)[i]).format('MMM D')
    );
    setChartData({ ...tempChartData });
    setIsLoading(false);
  }, [data, loading]);

  return isLoading ? (
    <div>
      <CircularProgress size={80} />
    </div>
  ) : (
    <Line options={options} data={chartData} />
  );
};
export default LineChart;
