import { date, object } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  driver_not_available_until_date: date().when('status', {
    is: (status) => status === 2,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  out_of_service_date_time: date().when('status', {
    is: (status) => status === 4,
    then: validator.requiredDate(),
    otherwise: validator.date(),
  }),
  terminated_date_time: date().when('status', {
    is: (status) => status === 5,
    then: validator.requiredDate(),
    otherwise: validator.date(),
  }),
  quite_date_time: date().when('status', {
    is: (status) => status === 6,
    then: validator.requiredDate(),
    otherwise: validator.date(),
  }),
});
