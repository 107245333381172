import { palette } from 'utils/constants';

export const HEADER_STATUSES = [
  { title: 'Active', id: 1, color: '#14804A', background: '#E1FCEF' },
  { title: 'Ended', id: 2, color: '#14804A', background: '#E1FCEF' },
];

export const RECURRING_TABS = [
  { title: 'Overview', id: 1 },
  { title: 'Shipments', id: 2 },
  { title: 'Billing Charges', id: 3 },
  { title: 'Notes', id: 4 },
  { title: 'Schedule', id: 5 },
  { title: 'Plan Ahead', id: 6 },
];

export const OVERVIEW_STOPS_TOP_MODAL = [
  { id: 1, type: 'pickup', title: 'Pick Up' },
  { id: 2, type: 'delivery', title: 'Delivery' },
  { id: 3, type: 'waypoint', title: 'Waypoint' },
];

export const FIRST_STOPS_TOP_MODAL = [{ id: 1, type: 'pickup', title: 'Pick Up' }];
export const LAST_STOPS_TOP_MODAL = [{ id: 2, type: 'delivery', title: 'Delivery' }];

export const RECURRING_LANE_STATUS = {
  Active: { color: palette.green400, bgColor: palette.green0 },
  Ended: { color: palette.gray700, bgColor: palette.gray0 },
  Cancelled: { color: palette.red500, bgColor: palette.red0 },
};
