import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import Radio from 'common/Radio';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { getCustomersList } from 'Api/Customers';
import { getCustomerSalesSummary } from 'Api/Reports';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { reportItemsConverter } from './CustomerSalesSummary.data';
import { STable, STableFilters, STableWrapper } from '../ReportDetails.styles';

const CustomerSalesDetails = () => {
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [pdfUrl, setPdfUrl] = useState('');
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [type, setType] = useState('accrual');
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportItems, setReportItems] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const didMountRef = useRef(false);
  const controller = useRef(new AbortController());

  const totalAmount = useMemo(() => {
    return reportItems.reduce((acc, cur) => acc + Number(cur.total_amount), 0);
  }, [reportItems]);

  const getCustomerSalesData = async () => {
    try {
      setLoading(true);
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const customerIds = selectedCustomers.map((el) => el.id);

      const params = {
        start_date: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        type: type === 'paid' ? type : undefined,
        customer_id: customerIds.length ? customerIds : undefined,
      };
      const response = await getCustomerSalesSummary(params, controller.current?.signal);
      const convertedData = reportItemsConverter(response);
      setReportItems(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const getCustomers = async () => {
    try {
      setLoadingCustomers(true);
      const { data } = await getCustomersList({ page: 1, itemsPerPage: 10000 });
      setCustomers(data.map((el) => ({ id: el.id, title: el.company_name })));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingCustomers(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const { url } = await generatePDF(
        reportItems,
        totalAmount,
        download,
        dateRange,
        formatDate,
        convertToCustomerTime,
        currency
      );
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const onExport = () => {
    if (!reportItems?.length) {
      return;
    }

    const titles = {
      customer: 'Customer',
      amount: 'Amount',
    };

    const invoiceRecords = reportItems.map((item) => ({
      customer: item.customer || '-',
      amount: item.total_amount || '-',
    }));

    const arrayToConvert = [titles, ...invoiceRecords];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute(
      'download',
      `Sales by Customer Summary - ${
        dateRange.start && dateRange.end ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}` : 'All Time'
      }`
    );
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setInitialLoading(true);
    }
    getCustomerSalesData();
    didMountRef.current = true;
  }, [dateRange, type, selectedCustomers]);

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <PageWrapper loading={initialLoading} title='Sales by Customer Summary'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName='All Time'
              type='allTime'
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Autocomplete
              multiple
              width='320px'
              limitTags={3}
              value={selectedCustomers}
              options={customers}
              loading={loadingCustomers}
              placeholder={!selectedCustomers.length ? 'Select Customer..' : ''}
              onChange={(e, val) => setSelectedCustomers(val)}
              getTagLabel={(option) => (option ? option?.title : '')}
              getOptionLabel={(option) => (option ? option?.title : '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <div className='d-flex align-items-center gap-3'>
              <Radio label='Accrual' value='accrual' checked={type === 'accrual'} onChange={() => setType('accrual')} />
              <Radio label='Cash' value='paid' checked={type === 'paid'} onChange={() => setType('paid')} />
            </div>
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportItems.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={reportItems.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        <STable>
          <thead>
            <tr className='header-row'>
              <th>CUSTOMER</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {reportItems.map((item) => (
              <tr key={item.customer} className='body-row'>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {item.customer || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {currency}
                    {formatAmount(item.total_amount)}
                  </Typography>
                </td>
              </tr>
            ))}
            <tr className='body-row'>
              <td>
                <Typography variant='h5' style={{ color: palette.gray900 }}>
                  Total
                </Typography>
              </td>
              <td>
                <Typography variant='h5' style={{ color: palette.gray900 }}>
                  {currency}
                  {formatAmount(totalAmount)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </STable>
      </STableWrapper>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Sales by Customer Summary' />
      )}
    </PageWrapper>
  );
};

export default CustomerSalesDetails;
