import React, { useEffect, useState } from 'react';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import useDebounce from 'hooks/useDebounce';
import { palette } from 'utils/constants';
import { getReferralsList } from 'Api/Applicants';
import { initialFilters, useReferralsColumns } from './ReferralsTable.data';

import Search from '../Search';
import TableFooter from '../TableFooter';
import { STableWrapper } from '../../ApplicantsAndReferrals.styles';

const ReferralsTable = () => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [referrals, setReferrals] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [sort, setSort] = useState({ field: 'created_at', sortBy: 'desc' });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getReferrals = async () => {
    setLoading(true);
    const { page, itemsPerPage, ...rest } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    try {
      const params = {
        page,
        itemsPerPage,
        query: debouncedSearch || undefined,
        [sortField]: sort.sortBy,
        ...rest,
      };

      const response = await getReferralsList(params);
      setReferrals(response);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
    setLoading(false);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getReferrals();
  }, [selectedFilters, debouncedSearch, sort]);

  const PaginationComponent = (event) => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.itemsPerPage}
      totalCount={referrals.total}
      totalLength={referrals.data.length}
      lastPage={referrals.last_page}
      currentPage={referrals.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  const columns = useReferralsColumns({ sortingQuery, sort });

  return (
    <STableWrapper>
      <Search search={search} onChange={setSearch} margin='0 0 24px' />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={referrals?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
    </STableWrapper>
  );
};

export default ReferralsTable;
