import React from 'react';
import { Typography } from 'components/Typography';
import { SCustomModal } from '../Cards/components/RemoveCard/RemoveCard.styles';

const FeatureNotAvailable = ({ open, onClose, text }) => {
  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Feature Not Available'
      $maxWidth='382px'
      $minWidth='382px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'button',
          type: 'primary',
          title: 'Okay',
          onClick: onClose,
        },
      ]}
    >
      <Typography variant='h6'>{text || 'This feature is not available for WEX EFS Fleet Card'}</Typography>
    </SCustomModal>
  );
};

export default FeatureNotAvailable;
