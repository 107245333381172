import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Typography } from 'components/Typography';
import { searchCities, searchCountries, searchStates } from 'Api/Driver';

const RepairShop = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Formik onSubmit={() => null} enableReinitialize initialValues={{}}>
      {({ validateField, getFieldHelpers, ...props }) => {
        return (
          <RepairShopForm
            {...props}
            validateField={validateField}
            getFieldHelpers={getFieldHelpers}
            accountData={null}
          />
        );
      }}
    </Formik>
  );
};

function RepairShopForm({ values, getFieldHelpers, setFieldValue, accountData }) {
  const [countries, setCountries] = useState(null);
  const [activeOption] = useState({});

  const getCities = (state_id) => {
    return searchCities('', state_id).then((res) => {
      return res?.data;
    });
  };
  const getStates = (country_id) => {
    return searchStates('', country_id).then((res) => {
      if (res && res?.data) {
        return res?.data;
      }
    });
  };
  const onChangeCountry = ({ value, getFieldHelpers, first }) => {
    if (first) {
      const country_id = getFieldHelpers('country_id');
      const zipcode = getFieldHelpers('zipcode');
      zipcode.setValue(activeOption?.Zip);
      country_id.setValue(value?.id);
      getStates(value?.id).then((res) => {
        if (Object.keys(activeOption).length <= 0) {
          const foundedState = res?.find((data) => Number(data?.id) === Number(accountData?.state?.id));
          !!foundedState && onChangeState({ value: foundedState, getFieldHelpers, first: true });
        } else {
          const foundedState = res?.find((data) => data?.name === activeOption?.StateName);
          !!foundedState && onChangeState({ value: foundedState, getFieldHelpers, first: true });
        }
      });
    } else {
      const state_id = getFieldHelpers('state_id');
      const city_id = getFieldHelpers('city_id');
      city_id.setValue('');
      state_id.setValue('');
      getStates(value?.id);
    }
    if (Object.keys(activeOption).length <= 0) {
      const zipcode = getFieldHelpers('zipcode');
      zipcode.setValue(values?.zipcode);
    }
    return false;
  };
  const onChangeState = ({ value, getFieldHelpers, first }) => {
    if (first) {
      const state_id = getFieldHelpers('state_id');
      state_id.setValue(value?.id);
      getCities(value?.id).then((data) => {
        if (Object.keys(activeOption).length <= 0) {
          const foundedData = data?.find((el) => Number(el?.id) === Number(accountData?.city?.id));
          setTimeout(() => onChangeCity(foundedData, true), 0);
        } else {
          const foundedData = data?.find((el) => el?.name === activeOption?.City);
          setTimeout(() => onChangeCity(foundedData, true), 0);
        }
      });
    } else {
      const city_id = getFieldHelpers('city_id');
      city_id.setValue('');
      getCities(value?.id);
    }
    return false;
  };

  const onChangeCity = (e, first) => {
    if (first) {
      setFieldValue('city_id', e?.id.toString());
    }
    return false;
  };

  useEffect(() => {
    if (typeof activeOption === 'object' && Object.keys(activeOption).length > 0) {
      let foundActiveCountry;
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].sortname === activeOption.Country) {
          foundActiveCountry = countries[i];
          break;
        }
      }
      if (accountData && countries) {
        onChangeCountry({ value: foundActiveCountry, getFieldHelpers, first: true });
      }
    } else if (accountData && countries && typeof activeOption === 'object' && Object.keys(activeOption).length <= 0) {
      const foundedCountry = countries?.find((s) => s?.id === accountData?.country?.id);
      onChangeCountry({ value: foundedCountry, getFieldHelpers, first: true });
    }
  }, [accountData, countries, activeOption]);

  useEffect(() => {
    searchCountries('').then((res) => {
      setCountries(res.data);
    });
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%', padding: '40px 20px', justifyContent: 'center' }}>
      <Typography variant='s2'>This feature is not available for your company.</Typography>
    </div>
  );
}

export default RepairShop;
