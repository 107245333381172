import React from 'react';
import { ReactComponent as FilterDark } from 'assets/icons/filterIconDark.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import MultiDropdownItem from './MultiDropdownItem';

const FilterComponent = ({ filterBy }) => {
  const { use } = useTheme();
  return (
    <div className='default_searchTitle_wrapper'>
      <span>
        <FilterDark style={{ width: 12, height: 12 }} fill={use(palette.gray700, palette.gray200)} />
      </span>
      <span className='default_searchTitle' style={{ color: use(palette.gray700, palette.gray200) }}>
        {filterBy}
      </span>
    </div>
  );
};

const Dropdown = ({ data, filterBy, context }) => {
  return (
    <ul className='menus'>
      <MultiDropdownItem
        data={data}
        depthLevel={0}
        context={context}
        component={<FilterComponent filterBy={filterBy} />}
      />
    </ul>
  );
};

export default Dropdown;
