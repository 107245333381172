import React from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { getOptions } from './Charts.data';

const RevenueChart = ({ monthsData }) => {
  const { currency } = useSelector((state) => state.root);

  const data = {
    labels: (monthsData || []).map((item) => `${item.month}`),
    datasets: [
      {
        data: (monthsData || []).map((item) => item.amount),
        backgroundColor: palette.green400,
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getOptions(currency)} data={data} />;
};

export default RevenueChart;
