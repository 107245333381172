import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { getTwilioPhoneNumbers, selectGroupPhoneNumber } from 'Api/chat';
import validationSchema from './ValidationSchema';
import sm from './PurchaseNumber.module.css';

const PurchaseNumber = ({ ...props }) => {
  const { use } = useTheme();
  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema,
  });
  const [selectData, setSelectData] = useState([]);

  const submit = () => {
    if (formik.values.phone) {
      const myArray = props.cid.split('_');
      let group_id;
      if (props.cid.includes('_group_')) {
        group_id = myArray[myArray.length - 1];
      }
      const data = {
        phone_number: formik.values.phone.phone_number,
        group_id,
      };
      selectGroupPhoneNumber(data).then(() => {
        props.close();
      });
    }
  };

  useEffect(() => {
    getTwilioPhoneNumbers().then((res) => {
      const selectData = res.data.map((el) => {
        return {
          ...el,
          key: el.id,
          label: formatPhoneNumberIntl(el.phone_number),
          labelSelected: null,
        };
      });
      setSelectData(selectData);
    });
  }, []);

  return (
    <div style={{ border: '1px solid rgba(0,0,0,0.4)', borderRadius: '0.5rem' }}>
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title className='document-modal' id='contained-modal-title-vcenter'>
          <h3 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Select or Purchase Phone Number
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div>
          <div className='d-flex flex-column'>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Select Number <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column' style={{ width: '100%' }}>
                <CustomSelect
                  options={selectData}
                  onChange={(value) => {
                    formik.setFieldValue('phone', value);
                  }}
                  styles={{ height: 32, marginTop: 12 }}
                  dropDownStyles={{ flexGrow: 0, minWidth: '100%' }}
                />
                {formik.touched.country && formik.errors.country ? (
                  <span className={sm['tab-error-message']}>{formik.errors.country}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.white, palette.darkborder),
        }}
      >
        <div className='footer-btn-container'>
          <Button className='cancel-button' onClick={props.close}>
            Cancel
          </Button>
          <Button className='next-step' type='submit' onClick={submit}>
            Select Number
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default PurchaseNumber;
