import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import NoRecords from 'common/NoRecords';
import ColumnHeader from 'common/ColumnHeader';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getRules } from 'Api/Accounts';
import { convertRuleData } from 'pages/Accounting/Accounts/components/Rules/Rules.data';
import TransactionRow from '../TransactionRow';
import MatchTransaction from '../MatchTransaction';
import { STable, STransactionsTableWrapper } from '../../BankFeed.styles';

const Transactions = ({
  transactions,
  account,
  accounts,
  payees,
  selectedRows,
  setSelectedRows,
  onUpdateSuccess,
  onMatchPaymentSuccess,
  sort,
  sortingQuery,
}) => {
  const [transactionToMatch, setTransactionToMatch] = useState(null);
  const [rules, setRules] = useState([]);

  const getRulesList = async () => {
    try {
      const { data } = await getRules(account.id);
      setRules((data || []).map((rule) => convertRuleData(rule, accounts || [], payees || [])));
    } catch (e) {
      // Do nothing
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== transactions.length ? transactions.map((i) => i.id) : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row.id]);
    } else {
      setSelectedRows((prevState) => prevState.filter((id) => id !== row.id));
    }
  };

  const onRowClick = (row) => {
    if (window.getSelection()?.toString()?.length) {
      // Prevent click on text selection
      return;
    }

    if (row.type === 'TRSFR') {
      return;
    }

    setTransactionToMatch(row);
  };

  useEffect(() => {
    getRulesList();
  }, []);

  return (
    <STransactionsTableWrapper>
      <div className={classNames('table-container fixed-header', { 'small-height': !!selectedRows.length })}>
        <STable>
          <thead>
            <tr>
              <th>
                <div className='d-flex justify-content-end'>
                  {!!transactions.length && (
                    <CustomCheckbox onChange={handleSelectAll} checked={selectedRows.length === transactions.length} />
                  )}
                </div>
              </th>
              <th>
                <ColumnHeader title='STATUS' field='status' sort={sort} onClick={sortingQuery} />
              </th>
              <th>TYPE</th>
              <th>
                <ColumnHeader title='DATE' field='date' sort={sort} onClick={sortingQuery} />
              </th>
              <th>REFERENCE</th>
              <th>DOWNLOADED AS</th>
              <th>PAYEE</th>
              <th>
                <ColumnHeader title='ACCOUNT' field='to_account' sort={sort} onClick={sortingQuery} />
              </th>
              <th>MEMO</th>
              <th>
                <ColumnHeader title='PAYMENT' field='payment' sort={sort} onClick={sortingQuery} />
              </th>
              <th>
                <ColumnHeader title='DEPOSIT' field='deposit' sort={sort} onClick={sortingQuery} />
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((item) => (
              <TransactionRow
                key={item.id}
                transaction={item}
                accounts={accounts}
                payees={payees}
                rules={rules}
                selected={selectedRows.includes(item.id)}
                handleSelectRow={handleSelectRow}
                onUpdateSuccess={onUpdateSuccess}
                onRowClick={() => onRowClick(item)}
                getRulesList={getRulesList}
              />
            ))}
          </tbody>
        </STable>
        {!transactions?.length && <NoRecords />}
      </div>
      {!!transactionToMatch && (
        <MatchTransaction
          open={!!transactionToMatch}
          onClose={() => setTransactionToMatch(null)}
          transaction={transactionToMatch}
          onSuccess={onMatchPaymentSuccess}
          payees={payees}
          accounts={accounts}
        />
      )}
    </STransactionsTableWrapper>
  );
};

export default Transactions;
