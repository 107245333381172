import React from 'react';
import CustomDatePicker from '../../components/CustomDatePicker';
import { SPickerWrapper } from './DatePicker.styles';

const DatePicker = ({ size, disabled, width, borderRadius, ...props }) => {
  return (
    <SPickerWrapper size={size} $disabled={disabled} $width={width} $borderRadius={borderRadius}>
      <CustomDatePicker disabled={disabled} {...props} />
    </SPickerWrapper>
  );
};

export default DatePicker;
