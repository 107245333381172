import { transformArray } from 'pages/Accounting/Receivables/StatsSection/StatsSection.data';

export const statsDataConverter = (data) => {
  const { closedReceivable, miles_data, openReceivables, pieChart, readyAndMissing, transport_revenue } = data || {};

  const milesAmountDiff = (miles_data?.current_month || 0) - (miles_data?.last_month || 0);
  const revenuePerMileDiff =
    (transport_revenue?.current_month?.per_mile || 0) - (transport_revenue?.last_month?.per_mile || 0);
  const closedAmountDiff = (closedReceivable?.currentMonth || 0) - (closedReceivable?.lastMonth || 0);
  const ready_and_missing = transformArray(readyAndMissing?.graph);
  ready_and_missing.shift();

  return {
    pieChart,
    openReceivables,
    readyAndMissing: { ...readyAndMissing, monthsData: ready_and_missing },
    statsSection: {
      miles: { ...miles_data, milesAmountDiff },
      'gross transport revenue': { ...transport_revenue, perMileDiff: revenuePerMileDiff },
      'closed receivables': { ...closedReceivable, amountDiff: closedAmountDiff },
    },
  };
};
