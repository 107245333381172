import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Button } from 'react-bootstrap';
import { getVendorsList } from 'Api/Vendors';
import { getBills as getBillsList } from 'Api/Payables';
import { getDrivers as getDriversList } from 'Api/EquipmentProfile';

import Autocomplete from 'common/Autocomplete';
import { ReactComponent as UserProfile } from 'assets/icons/drivers/user-profile.svg';
import { Typography } from 'components/Typography';
import { OwnerOperator as getOwnerOperatorList } from 'Api/OwnerOperator';
import { getSettlements as getSettlementsList } from 'Api/Payroll';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Staff } from 'Api/Staff';
import classes from './SendPaymentStep1.module.scss';
import { MAP_KEYS_SEND_PAYMENT } from '../../../constants';
import { SendPaymentModalContext } from '../../../index';

const SendPaymentStep1 = () => {
  const {
    values,
    touchedErrors,
    handleChange,
    setStep,
    vendors,
    drivers,
    ownerOperators,
    searchOpenBills,
    searchOpenSettlements,
    setVendors,
    setDrivers,
    setOwnerOperators,
    setSearchOpenBills,
    setSearchOpenSettlements,
    staff,
    setStaff,
  } = useContext(SendPaymentModalContext);
  const { use } = useTheme();
  const [loadings, setLoadings] = useState({
    vendors: false,
    drivers: false,
    operators: false,
    bills: false,
    settlements: false,
    staff: false,
  });

  const getVendors = async () => {
    setLoadings((p) => ({ ...p, vendors: true }));
    try {
      const { data } = await getVendorsList();
      setVendors(data);
      setLoadings((p) => ({ ...p, vendors: false }));
    } catch (e) {
      setLoadings((p) => ({ ...p, vendors: false }));
    }
  };

  const getDrivers = async () => {
    setLoadings((p) => ({ ...p, drivers: true }));
    try {
      const { data } = await getDriversList();
      setDrivers(data);
      setLoadings((p) => ({ ...p, drivers: false }));
    } catch (e) {
      setLoadings((p) => ({ ...p, drivers: false }));
    }
  };

  const getOwnerOperators = async () => {
    setLoadings((p) => ({ ...p, operators: true }));
    try {
      const { data } = await getOwnerOperatorList();
      setOwnerOperators(data);
      setLoadings((p) => ({ ...p, operators: false }));
    } catch (e) {
      setLoadings((p) => ({ ...p, operators: false }));
    }
  };

  const getStaff = async () => {
    setLoadings((p) => ({ ...p, staff: true }));
    try {
      const { data } = await Staff({ page: 1, itemsPerPage: 10000 });
      setStaff(data);
      setLoadings((p) => ({ ...p, staff: false }));
    } catch (e) {
      setLoadings((p) => ({ ...p, staff: false }));
    }
  };

  const getSearchOpenBills = async () => {
    setLoadings((p) => ({ ...p, bills: true }));
    try {
      const { data } = await getBillsList({ status: 0, itemsPerPage: 10000 });
      setSearchOpenBills(data);
      setLoadings((p) => ({ ...p, bills: false }));
    } catch (e) {
      setLoadings((p) => ({ ...p, bills: false }));
    }
  };

  const getSearchOpenSettlements = async () => {
    setLoadings((p) => ({ ...p, settlements: true }));
    try {
      const { data } = await getSettlementsList({ status_id: 3, page_size: 10000 });
      setSearchOpenSettlements(data);
      setLoadings((p) => ({ ...p, settlements: false }));
    } catch (e) {
      setLoadings((p) => ({ ...p, settlements: false }));
    }
  };

  const getOptionLabelForSettlements = useCallback(
    (option, props) => {
      if (option && props) {
        const selected = values.searchOpenSettlements.find((el) => el?.id === option?.id);
        const styleTexts = { color: selected ? palette.white : use(palette.gray900, palette.gray50) };
        return (
          <div
            key={option?.id}
            style={{ background: selected ? palette.indigo500 : '' }}
            className={classes['label_settlements_in-send_payment']}
            {...props}
          >
            {option?.user?.profile_logo || option?.user?.image ? (
              <img
                alt=''
                src={option?.user?.profile_logo || option?.user?.image}
                className={classes['label_settlements_in-send_payment_img']}
              />
            ) : (
              <UserProfile />
            )}
            <Typography variant='s2' style={styleTexts}>
              {option?.user?.first_name || option?.user?.fname} {option?.user?.last_name || option?.user?.lname} -
              Settlement {option.id}
              {' - '}
              {moment(option?.pay_period_start).format('MMM D, YYYY')} to{' '}
              {moment(option?.pay_period_end).format('MMM D, YYYY')}
            </Typography>
          </div>
        );
      }
      return `${option.user.first_name || option.user.fname} - ${option.id} - ${moment(option.pay_period_start).format(
        'MMM D, YYYY'
      )} - ${moment(option.pay_period_end).format('MMM D, YYYY')}`;
    },
    [values, use]
  );

  const getOptionLabelStaff = useCallback((option) => {
    if (option) {
      return (
        <>
          {option?.staff?.profile_logo ? (
            <img
              alt=''
              src={option?.staff?.profile_logo}
              className={classes['label_settlements_in-send_payment_img']}
            />
          ) : (
            <UserProfile />
          )}
          <Typography variant='s2'>
            {option?.staff?.first_name} {option?.staff?.last_name}
          </Typography>
        </>
      );
    }
    return '';
  }, []);

  const getOptionLabelDrivers = useCallback((option) => {
    if (option) {
      return (
        <>
          {option?.image ? (
            <img alt='' src={option?.image} className={classes['label_settlements_in-send_payment_img']} />
          ) : (
            <UserProfile />
          )}
          <Typography variant='s2'>
            {option?.fname} {option?.lname}
          </Typography>
        </>
      );
    }
    return '';
  }, []);

  const getOptionLabelForBills = useCallback(
    (option) =>
      option
        ? `${option.vendor.name} - ${option.reference_id} - Due on ${moment(option.bill_due_date).format(
            'MMM D, YYYY'
          )}`
        : '',
    []
  );

  const isDisabledField = useCallback(
    (thatKey) => {
      let disabled = false;
      Object.keys(values).forEach((key) => {
        const value = values[key];
        if (MAP_KEYS_SEND_PAYMENT.includes(key) && Array.isArray(value) && value.length > 0) {
          disabled = thatKey !== key;
        }
      });
      return disabled;
    },
    [values]
  );

  const isDisabledNext = useMemo(() => {
    let disabled = true;

    for (let i = 0; i < Object.keys(values).length; i++) {
      const key = Object.keys(values)[i];
      const value = values[key];
      if (!MAP_KEYS_SEND_PAYMENT.includes(key)) {
        continue;
      }
      if (MAP_KEYS_SEND_PAYMENT.includes(key) && Array.isArray(value) && value.length > 0) {
        disabled = false;
        break;
      }
    }
    return disabled;
  }, [values]);

  useEffect(() => {
    !vendors.length && getVendors();
    !drivers.length && getDrivers();
    !ownerOperators.length && getOwnerOperators();
    !searchOpenBills.length && getSearchOpenBills();
    !searchOpenSettlements.length && getSearchOpenSettlements();
    !staff.length && getStaff();
  }, []);

  return (
    <div className='d-flex flex-column align-items-center gap-1'>
      <div>
        <Autocomplete
          multiple
          width='500px'
          limitTags={2}
          name='vendors'
          label='Vendors'
          options={vendors}
          value={values.vendors}
          loading={loadings.vendors}
          error={touchedErrors.vendors}
          disabled={isDisabledField('vendors')}
          onChange={(e, val) => handleChange('vendors', val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </div>
      <div>
        <Autocomplete
          multiple
          width='500px'
          limitTags={2}
          name='drivers'
          label='Drivers'
          options={drivers}
          value={values.drivers}
          loading={loadings.drivers}
          error={touchedErrors.drivers}
          onChange={(e, val) => handleChange('drivers', val)}
          disabled={isDisabledField('drivers')}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getTagLabel={(option) => (option ? `${option.fname} ${option.lname}` : '')}
          getOptionLabel={(option) => getOptionLabelDrivers(option)}
        />
      </div>
      <div>
        <Autocomplete
          multiple
          width='500px'
          limitTags={2}
          name='ownerOperators'
          label='Owner Operators'
          options={ownerOperators}
          loading={loadings.operators}
          value={values.ownerOperators}
          error={touchedErrors.ownerOperators}
          disabled={isDisabledField('ownerOperators')}
          onChange={(e, val) => handleChange('ownerOperators', val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getTagLabel={(option) => (option ? option.owner_operator_name : '')}
          getOptionLabel={(option) => (option ? option.owner_operator_name : '')}
        />
      </div>
      <div>
        <Autocomplete
          multiple
          width='500px'
          limitTags={2}
          name='staff'
          label='Staff'
          options={staff}
          value={values.staff}
          loading={loadings.staff}
          error={touchedErrors?.staff}
          onChange={(e, val) => handleChange('staff', val)}
          disabled={isDisabledField('staff')}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getTagLabel={(option) => (option ? `${option?.staff?.first_name} ${option?.staff?.last_name}` : '')}
          getOptionLabel={(option) => getOptionLabelStaff(option)}
        />
      </div>
      <div>
        <Autocomplete
          multiple
          width='500px'
          limitTags={2}
          name='searchOpenBills'
          labelKey='reference_id'
          loading={loadings.bills}
          label='Search Open Bills'
          options={searchOpenBills}
          value={values.searchOpenBills}
          error={touchedErrors.searchOpenBills}
          disabled={isDisabledField('searchOpenBills')}
          onChange={(e, val) => handleChange('searchOpenBills', val)}
          getOptionLabel={(option) => getOptionLabelForBills(option)}
          getTagLabel={(option) => (option ? option?.vendor?.name : '')}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </div>
      <div>
        <Autocomplete
          multiple
          width='500px'
          limitTags={2}
          name='searchOpenSettlements'
          loading={loadings.settlements}
          label='Search Open Settlements'
          options={searchOpenSettlements}
          value={values.searchOpenSettlements}
          error={touchedErrors.searchOpenSettlements}
          disabled={isDisabledField('searchOpenSettlements')}
          onChange={(e, val) => handleChange('searchOpenSettlements', val)}
          getOptionLabel={(option) => getOptionLabelForSettlements(option)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => getOptionLabelForSettlements(option, props)}
          getTagLabel={(option) => (option ? `${option.user.first_name || option.user.fname} - ${option.id}` : '')}
        />
      </div>

      <Button disabled={isDisabledNext} className='next-step mt-2 px-lg-3' onClick={() => setStep((p) => p + 1)}>
        Next
      </Button>
    </div>
  );
};

export default SendPaymentStep1;
