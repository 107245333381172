import { stopPointMapper } from 'componentsV2/ImportCSV/mappers';

export const CSV_TYPE = {
  STOP_POINTS: 'stop-points',
};

export const typeMapper = {
  [CSV_TYPE.STOP_POINTS]: stopPointMapper,
};

export const templateLink = {
  [CSV_TYPE.STOP_POINTS]: 'ImportStopPoint',
};
