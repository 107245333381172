import React, { useEffect, useState } from 'react';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getStaffOnboarding, updateStaffOnboarding } from 'Api/CompanySettings';
import { getInitialValues } from './StaffOnboarding.data';
import { SWrapper } from '../StaffDriver.styles';

const StaffOnboarding = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      const body = { require_identification_photo: Number(values.require_identification_photo) };
      await updateStaffOnboarding(body);

      showToaster({ type: 'success', message: 'Successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleSubmit, handleChange } = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
  });

  const getSettings = async () => {
    try {
      const { data } = await getStaffOnboarding();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Staff Onboarding'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      {loading ? (
        <Loader loading />
      ) : (
        <div>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='require_identification_photo'
              checked={!!values.require_identification_photo}
              onChange={(checked) => handleChange('require_identification_photo', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Require Staff Users to Provide Photo Identification Upon Onboarding
            </Typography>
          </div>
        </div>
      )}
    </SWrapper>
  );
};

export default StaffOnboarding;
