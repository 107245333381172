import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import Input, { InputWithText } from 'common/Input';
import CustomTextArea from 'components/CustomTextArea';
import { DatePicker, TimePicker } from 'common/Pickers';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import sm from 'components/CreateShipment/ShipmentStops/AddStopPoint/AddStopPoint.module.css';
import { getAllTrailers } from 'Api/Map';
import { getGrandTotal, getMeasurementTypes, getQuantityTypes, getWeightTypes } from 'Api/Planner';
import { getAvailableCargo, getShipmentCargo } from 'Api/Shipment';
import { formatNumber } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import {
  EQUIPMENT_ACTION_DELIVERY,
  QUANTITY,
  SCHEDULED_DATE_TYPE,
  WEIGHT_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import {
  getCargoTotals,
  getInitialValues as getCargoInitialValues,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { SMergedInputs } from 'components/CreateShipment/CreateShipment.styles';
import classes from '../addEditModalStops.module.css';
import { shouldUpdateStops } from '../validates';

const Delivery = ({
  stop,
  touched,
  errors,
  stopPoints,
  setEquipmentId,
  currentStopAddValue,
  onChangeAddStopPointModal,
  getCustomerStopPoint,
  editMood,
  isShipmentTable,
  setFieldValue,
  setFieldTouched,
  shipmentId,
  shipmentSettings,
  stopInfo,
}) => {
  const controller = new AbortController();
  const [weightTypes, setWeightTypes] = useState([]);
  const [, setMeasurementTypes] = useState([]);
  const [quantityTypes, setQuantityTypes] = useState([]);
  const [cargos, setCargos] = useState([]);

  const totalValues = getCargoTotals(stop.connected_cargos);

  const onChangeTimeValidate = (date, field) => {
    setFieldValue(field, date);

    if (field === 'scheduled_date') {
      const startDate = moment(date);
      const endDate = moment(stop.scheduled_date_to);
      if (endDate.isBefore(startDate)) {
        setFieldValue(`scheduled_date_to`, startDate.toDate());
      }
    }

    if (field === 'scheduled_date_to') {
      setFieldValue(`to`, '');
      setFieldTouched(`to`, false);
    } else {
      setFieldValue(`from`, '');
      setFieldTouched(`from`, false);
    }
  };

  const onChangeStopPointId = (key, first = false) => {
    const selectedStopPointId = stopPoints.find((el) => +el.key === +key);
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      !first && onChangeTimeValidate(date, 'scheduled_date');
    }
    if (selectedStopPointId?.working_hour_by) {
      setFieldValue(`scheduled_type`, selectedStopPointId?.working_hour_by?.toString());
      onChangeScheduledType(SCHEDULED_DATE_TYPE.find((i) => i.key === selectedStopPointId?.working_hour_by));
    }
  };

  const getGrandTotalDefault = () => {
    getGrandTotal()
      .then((res) => {
        if (res && res?.data) {
          const qtyObj = {
            pieces: QUANTITY[0].key,
            units: QUANTITY[1].key,
            qty: QUANTITY[2].key,
          };
          const weightObj = {
            lbs: WEIGHT_TYPE[0].key,
            kgs: WEIGHT_TYPE[1].key,
          };

          setFieldValue('quantity_type', String(qtyObj[res.data?.default_qty_type]));
          setFieldValue('weight_type', String(weightObj[res.data?.default_weight]));
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onChangeScheduledType = () => {
    setFieldValue(`to`, '');
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, 'scheduled_date');
    }

    if (stop.scheduled_date_to) {
      const date = moment(stop.scheduled_date_to, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, 'scheduled_date_to');
    }
  };

  useEffect(() => {
    getEquipmentIdInType();
    (!stop.reported_weight_type || !stop.quantity_type) && getGrandTotalDefault();
  }, []);

  const getEquipmentIdInType = useCallback((length_id) => {
    getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8', length_id } }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `
                                     ${el?.equipment_id || ''}
                                    (${el?.make || ''}
                                     ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setEquipmentId([...equipmentId]);
      }
    });
  }, []);

  const onChangeAction = useCallback(
    (obj) => {
      const key = +obj.key;
      setFieldValue('equipment_action', obj.key);
      key === 3 && getEquipmentIdInType();
    },
    [getEquipmentIdInType]
  );

  const getAdditionalData = async () => {
    try {
      const [{ data: measurement }, { data: quantity }, { data: weight }, { data: cargo }] = await Promise.all([
        getMeasurementTypes(),
        getQuantityTypes(),
        getWeightTypes(),
        shipmentId && (!!stopInfo?.legIndex || stopInfo?.legIndex === 0) && (!!stopInfo?.index || stopInfo?.index === 0)
          ? getAvailableCargo({
              leg: stopInfo.legIndex,
              order_in_leg: stopInfo.index + 1,
              shipment_id: shipmentId,
              stop_point_id: stop?.stop_point_id || undefined,
            })
          : getShipmentCargo({ shipment_id: shipmentId }),
      ]);
      setWeightTypes(weight || []);
      setMeasurementTypes(measurement || []);
      setQuantityTypes(quantity || []);
      setCargos(cargo.length ? getCargoInitialValues(cargo) : []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getAdditionalData() + stop.equipment_action === 3 && getEquipmentIdInType();
    getCustomerStopPoint(controller.signal);
  }, []);

  useEffect(() => {
    !!stop?.stop_point_id && onChangeStopPointId(stop.stop_point_id, true);
  }, [stop.stop_point_id]);

  useEffect(() => {
    if (currentStopAddValue) {
      setFieldValue(`stop_point_id`, currentStopAddValue);
    }
  }, [currentStopAddValue]);

  return (
    <div className={classes.allFiledWrapper}>
      {/* Scheduled Date ID */}
      <div className={classes.fieldErrorWrapper}>
        <CustomSelect
          field={{
            name: `stop_point_id`,
            value: stop.stop_point_id,
          }}
          form={{ setFieldValue }}
          isSearchable
          options={stopPoints}
          menuStyles={{ maxWidth: 480 }}
          defaultText='Select Stop Point'
          onChange={(value) => onChangeStopPointId(value?.key)}
          onAddStopPoint={() => onChangeAddStopPointModal(true)}
          styles={{ maxWidth: 480, height: 32, margin: '8px 0 4px' }}
          shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'stop_point_id')}
          isSearchableStyles={{ maxWidth: 480, width: '100%', margin: '8px 0 4px', height: 32 }}
        />
        <ErrorMessage error={touched?.stop_point_id && errors?.stop_point_id} />
      </div>

      {/* Scheduled Date Type */}
      <div className={classes.fieldWrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Scheduled Date Type
        </Typography>
        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{ name: `scheduled_type`, value: stop.scheduled_type }}
            form={{ setFieldValue }}
            menuStyles={{ width: 400 }}
            options={SCHEDULED_DATE_TYPE}
            styles={{ maxWidth: 400, height: 22 }}
            onChange={(e) => onChangeScheduledType(e)}
            shouldUpdate={() => true}
          />
          <ErrorMessage error={touched?.scheduled_type && errors?.scheduled_type} />
        </div>
      </div>

      {/* Scheduled Date/Time */}
      <div className='d-flex justify-content-start align-items-start mt-2'>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start' }}>
          Scheduled Date/Time
        </Typography>
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex justify-content-start align-items-start gap-2'>
            <div>
              <DatePicker
                width='130px'
                size='small'
                name='scheduled_date'
                value={stop.scheduled_date}
                onChange={(date) => onChangeTimeValidate(date, 'scheduled_date')}
                error={touched?.scheduled_date && errors?.scheduled_date}
                disablePast={!shipmentSettings?.allow_past_date}
              />
            </div>
            <div>
              <TimePicker
                size='small'
                width='100px'
                value={stop.from}
                onChange={(time) => setFieldValue('from', time)}
                error={touched?.from && errors?.from}
              />
            </div>
          </div>
          {[1, 3].includes(+stop.scheduled_type) && (
            <div className='d-flex justify-content-start align-items-start gap-2'>
              <Typography variant='s2'>to</Typography>
              <div>
                <DatePicker
                  width='130px'
                  size='small'
                  name='scheduled_date_to'
                  value={stop.scheduled_date_to}
                  onChange={(date) => onChangeTimeValidate(date, 'scheduled_date_to')}
                  error={touched?.scheduled_date_to && errors?.scheduled_date_to}
                  minDate={moment(stop.scheduled_date).toDate()}
                />
              </div>
              <div>
                <TimePicker
                  size='small'
                  width='100px'
                  value={stop.to}
                  onChange={(time) => setFieldValue('to', time)}
                  error={touched?.to && errors?.to}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Arrival Date Type */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <div className={classes.fieldWrapperInput}>
            <Typography
              variant='s2'
              style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}
            >
              Arrival Date/Time
            </Typography>
            <div className={classes.fieldErrorWrapper} style={{ marginTop: 10 }}>
              <DatePicker
                width='130px'
                size='small'
                name='arrival_date'
                value={stop.arrival_date}
                onChange={(date) => setFieldValue('arrival_date', date)}
                error={touched?.arrival_date && errors?.arrival_date}
                disabled={!stopInfo?.stop?.arrival_date}
              />
            </div>
          </div>

          {/* /*Arrival Start Time*!/ */}
          <div className={classes.fieldWrapperInput} style={{ marginLeft: 12, marginTop: 10 }}>
            <div className={classes.fieldErrorWrapper}>
              <TimePicker
                size='small'
                width='100px'
                value={stop.arrival_time}
                onChange={(time) => setFieldValue('arrival_time', time)}
                error={touched?.arrival_time && errors?.arrival_time}
                disabled={!stopInfo?.stop?.arrival_date}
              />
            </div>
          </div>
        </div>
      )}

      {/* Departure Date Type */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <div className={classes.fieldWrapperInput}>
            <Typography
              variant='s2'
              style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}
            >
              Departure Date/Time
            </Typography>
            <div className={classes.fieldErrorWrapper} style={{ marginTop: 10 }}>
              <DatePicker
                width='130px'
                size='small'
                name='departure_date'
                value={stop.departure_date}
                onChange={(date) => setFieldValue('departure_date', date)}
                error={touched?.departure_date && errors?.departure_date}
                disabled={!stopInfo?.stop?.departure_date}
              />
            </div>
          </div>

          {/* Departure Start Time */}
          <div className={classes.fieldWrapperInput} style={{ marginLeft: 12, marginTop: 10 }}>
            <div className={classes.fieldErrorWrapper}>
              <TimePicker
                size='small'
                width='100px'
                value={stop.departure_time}
                onChange={(time) => setFieldValue('departure_time', time)}
                error={touched?.departure_time && errors?.departure_time}
                disabled={!stopInfo?.stop?.departure_date}
              />
            </div>
          </div>
        </div>
      )}

      {/* Equipment Action */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Action
        </Typography>

        <div style={{ flexGrow: 1, maxWidth: 400 }}>
          <Autocomplete
            size='small'
            name='equipment_action'
            labelKey='label'
            isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
            value={EQUIPMENT_ACTION_DELIVERY.find((i) => i.key === Number(stop.equipment_action)) || null}
            onChange={(e, val) => onChangeAction(val)}
            options={EQUIPMENT_ACTION_DELIVERY}
          />
          <ErrorMessage error={touched.equipment_action && errors.equipment_action} />
        </div>
      </div>

      {/* Delivery Number */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Delivery Number
        </Typography>
        <div className={classes.fieldErrorWrapper}>
          <CustomInput
            name='number'
            value={stop.number}
            onChange={(e) => setFieldValue('number', e.target.value)}
            className={classes.customInputField}
          />
        </div>
      </div>

      {/* Commodity */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Commodity
        </Typography>
        <div style={{ marginTop: '10px' }}>
          <Autocomplete
            multiple
            width='400px'
            placeholder={stop?.connected_cargos?.length ? '' : 'Select Cargo for Delivery'}
            labelKey='title'
            options={cargos}
            getOptionLabel={(option) =>
              `${option.shipment_stop?.stop_point?.location_name} - ${option.commodity?.title}${
                option.sku_barcode ? ` (${option.sku_barcode})` : ''
              }`
            }
            getTagLabel={(option) =>
              `${option.shipment_stop?.stop_point?.location_name} - ${option.commodity?.title}${
                option.sku_barcode ? ` (${option.sku_barcode})` : ''
              }`
            }
            value={stop.connected_cargos || []}
            onChange={(e, value) => setFieldValue(`connected_cargos`, value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>
      </div>

      {/* Qty */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Qty
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {!stop.connected_cargos?.length ? (
              <>
                <div className={classes.fieldErrorWrapper}>
                  <CustomInput
                    name='quantity'
                    type='number'
                    className={classes.customInputField}
                    style={{ width: 200, borderRadius: '4px 0 0 4px' }}
                    value={stop.quantity}
                    onChange={(e) => setFieldValue(`quantity`, e.target.value)}
                    labelStyle={{ marginBottom: -0.6 }}
                  />
                </div>

                <div className={classes.fieldErrorWrapper}>
                  <CustomSelect
                    field={{
                      name: `quantity_type`,
                      value: stop.quantity_type,
                    }}
                    form={{ setFieldValue }}
                    name='quantity_type'
                    styles={{ width: 200, height: 22, borderRadius: '0 4px 4px 0' }}
                    menuStyles={{ width: 200 }}
                    options={QUANTITY}
                  />
                </div>
              </>
            ) : (
              <div style={{ marginTop: '10px' }}>
                <SMergedInputs>
                  <Input
                    width='250px'
                    type='number'
                    size='small'
                    value={totalValues.expected_quantity}
                    onKeyDown={(e) => {
                      blockNotNumberChars(e);
                      blockNonPositiveNumbers(e);
                    }}
                    disabled
                  />
                  <div>
                    <Autocomplete
                      width='150px'
                      size='small'
                      name='cargos[0].expected_quantity_type'
                      options={quantityTypes}
                      value={stop.connected_cargos?.[0]?.expected_quantity_type || null}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled
                    />
                  </div>
                </SMergedInputs>
              </div>
            )}
          </div>
          {/* <ErrorMessage */}
          {/*    name={"quantity"} */}
          {/*    render={(error) => ( */}
          {/*        <Typography */}
          {/*            variant={'c2'} */}
          {/*            style={{color: use(palette.red500, palette.red800)}} */}
          {/*        > */}
          {/*            {error} */}
          {/*        </Typography> */}
          {/*    )} */}
          {/* /> */}
        </div>
      </div>

      {/* Dimensions */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Dimensions
        </Typography>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {!stop.connected_cargos?.length ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width='90px'
                type='number'
                size='small'
                text='L'
                name='dimensions.l'
                value={stop.dimensions.l}
                onChange={(e) => setFieldValue(`dimensions.l`, e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          ) : stop.connected_cargos?.length === 1 ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={stop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='L'
                name='connected_cargos[0].expected_dimensions.l'
                value={Number(totalValues.length || 0).toFixed(2)}
                onKeyDown={blockNonPositiveNumbers}
                disabled
              />
            </div>
          ) : null}

          {(!stop.connected_cargos?.length || stop.connected_cargos?.length === 1) && (
            <div className={classes.dimensionsX}>x</div>
          )}
          {!stop.connected_cargos?.length ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width='90px'
                type='number'
                size='small'
                text='W'
                name='dimensions.w'
                value={stop.dimensions.w}
                onChange={(e) => setFieldValue(`dimensions.w`, e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          ) : stop.connected_cargos?.length === 1 ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={stop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='W'
                name='connected_cargos[0].expected_dimensions.w'
                value={Number(totalValues.width || 0).toFixed(2)}
                onKeyDown={blockNonPositiveNumbers}
                disabled
              />
            </div>
          ) : null}

          {(!stop.connected_cargos?.length || stop.connected_cargos?.length === 1) && (
            <div className={classes.dimensionsX}>x</div>
          )}
          {!stop.connected_cargos?.length ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width='90px'
                type='number'
                size='small'
                text='H'
                name='dimensions.w'
                value={stop.dimensions.h}
                onChange={(e) => setFieldValue(`dimensions.h`, e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          ) : stop.connected_cargos?.length === 1 ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={stop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='H'
                name='connected_cargos[0].expected_dimensions.h'
                value={Number(totalValues.height || 0).toFixed(2)}
                onKeyDown={blockNonPositiveNumbers}
                disabled
              />
            </div>
          ) : null}
          {stop.connected_cargos?.length > 1 && (
            <div style={{ marginTop: '10px' }} className='ms-2'>
              <Typography variant='b2' className='nowrap'>
                {formatNumber(totalValues.volumeInCubicFeet)} <Typography variant='c3'>CU. FT.</Typography>
              </Typography>
            </div>
          )}
        </div>
      </div>

      {/* Weight */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Weight
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {!stop.connected_cargos?.length ? (
              <>
                <div className={sm.field_error_wrapper}>
                  <CustomInput
                    name='weight'
                    type='number'
                    className={sm.customInputField}
                    style={{ width: 200, borderRadius: '4px 0 0 4px' }}
                    value={stop.weight}
                    onChange={(e) => setFieldValue(`weight`, e.target.value)}
                    labelStyle={{ marginBottom: -0.6 }}
                  />
                </div>

                <div className={sm.field_error_wrapper}>
                  <CustomSelect
                    field={{
                      name: `weight_type`,
                      value: stop.weight_type,
                    }}
                    form={{ setFieldValue }}
                    name='weight_type'
                    styles={{ width: 200, height: 22, borderRadius: '0 4px 4px 0' }}
                    menuStyles={{ width: 200 }}
                    options={WEIGHT_TYPE}
                  />
                </div>
              </>
            ) : (
              <div style={{ marginTop: '10px' }}>
                <SMergedInputs>
                  <Input
                    width='250px'
                    size='small'
                    name='cargos[0].expected_weight'
                    value={formatNumber(totalValues.expected_weight)}
                    onKeyDown={blockNonPositiveNumbers}
                    disabled
                  />
                  <div>
                    <Autocomplete
                      width='150px'
                      size='small'
                      labelKey='unit'
                      name='connected_cargos[0].expected_weight_type'
                      options={weightTypes}
                      value={stop.connected_cargos?.[0]?.expected_weight_type || null}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled
                    />
                  </div>
                </SMergedInputs>
              </div>
            )}
          </div>
          {/* <ErrorMessage */}
          {/*    name={"weight"} */}
          {/*    render={(error) => ( */}
          {/*        <Typography */}
          {/*            variant={'c2'} */}
          {/*            style={{color: use(palette.red500, palette.red800)}} */}
          {/*        > */}
          {/*            {error} */}
          {/*        </Typography> */}
          {/*    )} */}
          {/* /> */}
        </div>
      </div>

      {/* Stop Notes */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 114 }}>
          Stop Notes
        </Typography>
        <CustomTextArea
          field={{ value: stop.stop_notes }}
          styleLabel={{ marginLeft: 36 }}
          name='stop_notes'
          styles={{ maxWidth: 400, height: 22 }}
          value={stop.stop_notes}
          onChange={(value) => setFieldValue(`stop_notes`, value)}
        />
      </div>

      {/* {bill_type} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.bill_type}
          name='bill_type'
          onChange={(checked) => setFieldValue(`bill_type`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Billable Move
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {dock_high} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.dock_high}
          name='dock_high'
          onChange={(checked) => setFieldValue(`dock_high`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Dock High
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {labor_required} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.labor_required}
          name='labor_required'
          onChange={(checked) => setFieldValue(`labor_required`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Labor Required (Loading)
          </Typography>
        </CustomCheckbox>
      </div>
    </div>
  );
};

export default Delivery;
