import moment from 'moment';

export const validateRequired = (value) => {
  let error;
  if (value !== undefined && value === '' && value?.trim() === '') {
    error = 'Required';
  }
  return error;
};

export const noValidate = () => {
  return '';
};

export const isSameTime = (a, b) => {
  return a.isSame(b, 'hour') && a.isSame(b, 'minute');
};

export const isAfterTime = (a, b) => {
  if (a.isSame(b, 'hour')) {
    return a.isAfter(b, 'minute');
  }
  return a.isAfter(b, 'hour');
};

export const getMomentByTime = (time) => {
  if (time) {
    return moment(`${moment().format('YYYY-MM-DD')} ${time}`);
  }
  return time;
};

export const validateStartTime = (value, stop, endKeyName) => {
  const endKey = endKeyName || 'endTime';
  let error;
  if (value !== '' && stop?.[endKey] !== '' && !value.includes('-')) {
    const startDate = getMomentByTime(value);
    const endDate = getMomentByTime(stop?.[endKey]);
    if (isAfterTime(startDate, endDate) || isSameTime(startDate, endDate)) {
      error = 'does not match';
    }
  } else {
    if (value === '' || value.includes('-')) {
      error = 'Required';
    }
  }
  return error;
};

export const validateEndTime = (value, stop, startKeyName) => {
  const startKey = startKeyName || 'startTime';
  let error;
  if (+stop.scheduled_type === 3) {
    if (stop?.[startKey] !== '' && value !== '' && !value.includes('-')) {
      const startDate = getMomentByTime(stop?.[startKey]);
      const endDate = getMomentByTime(value);

      if (isAfterTime(startDate, endDate)) {
        error = 'does not match';
      }
    } else {
      if (value === '' || value.includes('-')) {
        error = 'Required';
      }
    }
    return error;
  }
};

export const validateEquipmentTypeLength = (value, flag) => {
  if (flag !== 'vehicles') {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  }
};

export const shouldUpdateStops = (nextProps, props, name) => {
  const next = nextProps.formik.values;
  const current = props.formik.values;
  const nextError = nextProps.formik.errors;
  const currentError = props.formik.errors;
  const nextTouched = nextProps.formik.touched;
  const currentTouched = props.formik.touched;

  switch (name) {
    case 'stop_point_id':
      return nextProps?.options !== props?.options;
    case 'scheduled_date':
      return next?.scheduled_type !== current?.scheduled_date;
    case 'endTime':
      return (
        nextError?.startTime !== currentError?.startTime ||
        nextTouched?.endTime === currentTouched?.endTime ||
        !!props?.formik.submitForm
      );
    case 'startTime':
      return (
        nextError?.endTime !== currentError?.endTime ||
        nextTouched?.startTime === currentTouched?.startTime ||
        !!props?.formik.submitForm
      );
    case 'equipment_type':
      return nextProps?.menuStyles.width !== props?.menuStyles.width || nextProps?.options !== props?.options;
    case 'equipment_action':
      return nextError?.options !== props?.options || next?.equipment_type !== current?.equipment_type;
    case 'commodity':
      return nextError?.options !== props?.options;
    case 'equipment_type_length':
      return nextError?.options !== props?.options;
    case 'equipment_id':
      return true;
    default:
  }
};
