import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Autocomplete from 'common/Autocomplete';
import Input, { InputWithIcon } from 'common/Input';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getBills } from 'Api/Payables';
import AddBill from 'pages/Accounting/Payables/components/AddBill';

const CollisionCostRow = ({ row, index, type, vendors, removeRow, handleChange, setValues, setOpenAddVendor }) => {
  const [bills, setBills] = useState([]);
  const [openAddBill, setOpenAddBill] = useState(false);

  const getOpenBills = async () => {
    try {
      const params = {
        status: 0,
        page: 1,
        itemsPerPage: 10000,
        vendor_id: row.vendor.id,
        'sort[][aging]': 'desc',
      };

      const { data } = await getBills(params);
      data.unshift(
        { id: 'add', reference_id: '+ Add Bill', open_balance: '' },
        { id: '*', reference_id: 'Pending', open_balance: '' }
      );
      setBills(data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleVendorChange = (val) => {
    if (val.id === '*') {
      setOpenAddVendor(true);
    } else {
      setValues((prevState) => ({
        ...prevState,
        [`${type}_collision_cost`]: prevState[`${type}_collision_cost`].map((el, i) =>
          i !== index ? el : { ...el, vendor: val, bill: null, amount: '' }
        ),
      }));
    }
  };

  useEffect(() => {
    if (row.vendor) {
      getOpenBills();
    }
  }, [row.vendor]);

  return (
    <div key={row.id} className='d-flex gap-3 mb-2 align-items-center'>
      <div className='d-flex'>
        <Input
          name={`${type}_collision_cost[${index}].description`}
          placeholder='Description'
          value={row.description}
          onChange={(e) => handleChange(`${type}_collision_cost[${index}].description`, e.target.value)}
        />
      </div>
      <div>
        <Autocomplete
          width='200px'
          placeholder='Vendor'
          name={`${type}_collision_cost[${index}].vendor`}
          value={row.vendor}
          options={vendors}
          onChange={(e, val) => handleVendorChange(val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </div>
      <div>
        <Autocomplete
          width='200px'
          placeholder='Bill'
          labelKey='reference_id'
          name={`${type}_collision_cost[${index}].bill`}
          value={row.bill}
          options={bills}
          onChange={(e, val) => {
            if (val.id === 'add') {
              setOpenAddBill(true);
            } else {
              setValues((prevState) => ({
                ...prevState,
                [`${type}_collision_cost`]: prevState[`${type}_collision_cost`].map((el, i) =>
                  i !== index ? el : { ...el, bill: val, amount: val.open_balance }
                ),
              }));
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </div>

      <div>
        <InputWithIcon
          type='number'
          name='amount'
          placeholder='Cost of Bill'
          onChange={(e) => handleChange(`${type}_collision_cost[${index}].amount`, e.target.value)}
          value={row.amount}
          onKeyDown={blockNonPositiveNumbers}
        />
      </div>
      <DeleteIcon fill={palette.red500} onClick={() => removeRow(type, index)} />
      {openAddBill && (
        <AddBill
          open={openAddBill}
          onClose={() => setOpenAddBill(false)}
          onSuccess={getOpenBills}
          vendor={row.vendor}
        />
      )}
    </div>
  );
};

export default CollisionCostRow;
