import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/xClose.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warningOutline.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SButtonsWrapper, SFlexWrapper, SRowActionsWrapper } from './DisputedActions.styles';

const DisputedActions = ({ data, loading, onApprove, onReject }) => {
  const { use } = useTheme();

  return (
    <SRowActionsWrapper>
      {!data?.dispute_approved && (
        <SFlexWrapper>
          <WarningIcon fill={palette.red500} />
          <Typography variant='s2' style={{ color: use(palette.red500, palette.red200) }}>
            {data?.dispute_declined_reason ? `Decline Reason: ${data.dispute_declined_reason}` : 'Disputed by Driver'}
          </Typography>
        </SFlexWrapper>
      )}
      {!data?.dispute_approved && !data?.dispute_declined && (
        <SButtonsWrapper className='action-buttons'>
          <CustomButton
            title='Approve'
            leftIcon={<CheckIcon fill={palette.white} style={{ marginRight: 10 }} />}
            onClick={onApprove}
            disabled={loading}
          />
          <CustomButton
            title='Decline'
            className='decline-button'
            leftIcon={<CloseIcon fill={palette.white} style={{ marginRight: 10 }} />}
            onClick={onReject}
            disabled={loading}
          />
        </SButtonsWrapper>
      )}
    </SRowActionsWrapper>
  );
};

export default DisputedActions;
