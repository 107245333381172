import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import Input, { InputWithIcon } from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import { SButtonWrapper, SGrid, SIconButton } from '../AddReferrer.styles';

const ReferrerInfoForm = ({ values, touchedErrors, handleChange, onDelete, hideDelete, index }) => {
  const [showPhoneInput, setShowPhoneInput] = useState(false);

  useEffect(() => {
    const onlyNumbers = /^[0-9 +]+$/.test(values.contact_information);
    setShowPhoneInput(!!onlyNumbers);
  }, [values.contact_information]);

  return (
    <SGrid container columnSpacing={2}>
      <Grid item xs={4}>
        <Input
          name='referral_name'
          placeholder='Full Name'
          onChange={(e) => handleChange(`${index}.referral_name`, e.target.value)}
          value={values.referral_name}
          error={touchedErrors?.referral_name}
        />
      </Grid>
      <Grid item xs={4}>
        {showPhoneInput ? (
          <PhoneInput
            name='contact_information'
            placeholder='+1 (555) 555-1234'
            onChange={(val) => handleChange(`${index}.contact_information`, val)}
            value={values.contact_information}
            autoFocus
          />
        ) : (
          <Input
            name='contact_information'
            placeholder='Email Address or Telephone'
            onChange={(e) => handleChange(`${index}.contact_information`, e.target.value)}
            value={values.contact_information}
            error={touchedErrors?.contact_information}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        <InputWithIcon
          name='commission'
          type='number'
          placeholder='0.00'
          onChange={(e) => handleChange(`${index}.commission`, e.target.value)}
          value={values.commission}
          error={touchedErrors?.commission}
        />
      </Grid>
      <Grid item={1}>
        {!hideDelete && (
          <SButtonWrapper>
            <SIconButton type='secondary' onClick={onDelete}>
              <CloseIcon width={13} height={13} />
            </SIconButton>
          </SButtonWrapper>
        )}
      </Grid>
    </SGrid>
  );
};

export default ReferrerInfoForm;
