import { number, object } from 'yup';

export const validationSchema = object().shape({
  date_format: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  time_format: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
