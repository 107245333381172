import styled from 'styled-components';

export const SChartWrapper = styled.div`
  height: 400px;
  margin: 12px 0 48px;

  canvas {
    height: 100% !important;
  }
`;
