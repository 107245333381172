import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { SAddressFields, SCompanyInfo, SHeader, SProgressWrapper } from '../OnboardCarrier.styles';

const OnboardCarrierHeader = ({ company, progress }) => {
  const { use } = useTheme();
  const matches = useMediaQuery('(max-width:600px)');

  const { company_name, address1, city, state, zip, phone_number, logo, company_email } = company || {};

  return (
    <SHeader>
      <SCompanyInfo>
        <div className='logo-wrapper'>{logo && <img src={logo} alt='Company logo' height={48} />}</div>
        {!matches && (
          <Typography variant='h2' style={{ textAlign: 'center' }}>
            Carrier Onboarding
          </Typography>
        )}
        <SAddressFields>
          <Typography variant='s2'>{company_name}</Typography>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {address1}
          </Typography>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {city?.name}, {state?.short_name || state?.name} {zip}
          </Typography>
          <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {formatPhoneNumberIntl(phone_number)}
            </Typography>
          </a>
          <a href={`mailto:${company_email}`} style={{ textDecoration: 'none' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {company_email}
            </Typography>
          </a>
        </SAddressFields>
      </SCompanyInfo>
      <SProgressWrapper last={progress === 100}>
        <LinearProgress variant='determinate' value={progress} />
      </SProgressWrapper>
    </SHeader>
  );
};

export default OnboardCarrierHeader;
