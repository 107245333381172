import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import { ReactComponent as CircularArrows } from 'assets/icons/circle-arrows.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { formatNumber, palette } from 'utils/constants';
import { showBalanceFor } from '../../Accounts.data';
import { SFlexRow, SWrapper } from './AccountCard.styles';

const tooltipStyles = {
  tooltip: {
    sx: {
      bgcolor: palette.white,
      color: palette.gray900,
      boxShadow: '0px 1px 6px 4px rgb(0 0 0 / 10%)',
      marginBottom: '4px !important',
      fontSize: '13px',
      padding: '2px 4px',
    },
  },
  arrow: {
    sx: {
      color: palette.white,
    },
  },
};

const AccountCard = ({ data, hideBalances, onClick, className }) => {
  const hasQuickbook = useHasQuickbook();
  const { currency } = useSelector((state) => state.root);
  const {
    account_name,
    account_bank_name,
    account_type,
    account_balance,
    account_code,
    is_connected,
    reconcile_count,
    is_truckindigital,
    qb_sync,
  } = data ?? {};

  const showReconcile = !!is_connected || account_bank_name === 'WEX EFS LLC.';

  return (
    <SWrapper onClick={onClick} className={className}>
      <SFlexRow>
        <div className='d-flex gap-2'>
          <Typography variant='s2'>
            {account_name}
            {(!!showReconcile || !!is_truckindigital) && (
              <CircularArrows
                fill={reconcile_count === 0 ? palette.green400 : palette.orange300}
                className='ms-1 mb-1'
              />
            )}
          </Typography>
          {!!showReconcile && (
            <Chip
              label={`${reconcile_count}`}
              labelColor={reconcile_count === 0 ? palette.green400 : palette.goldDark}
              bgColor={reconcile_count === 0 ? palette.green0 : palette.goldLight}
              fontSize='13px'
            />
          )}
          {!!qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
        </div>
        <Chip label={account_code} />
      </SFlexRow>
      <SFlexRow>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          Type: {account_type?.account_type}
        </Typography>
        {!hideBalances && showBalanceFor.includes(account_type.id) && (
          <Tooltip title='Balance' arrow placement='top' componentsProps={tooltipStyles}>
            <span>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                {account_balance < 0 && '-'}
                {currency}
                {formatNumber(Math.abs(account_balance))}
              </Typography>
            </span>
          </Tooltip>
        )}
      </SFlexRow>
    </SWrapper>
  );
};

export default AccountCard;
