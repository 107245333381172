import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import appStore from 'assets/images/app-store.png';
import iphoneBig from 'assets/images/iphone-big.png';
import googlePlay from 'assets/images/google-play.png';
import iphoneSmall from 'assets/images/iphone-small.png';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getAppSettings } from 'Api/User';
import { SBannerWrapper, SDownloadPopup } from './ScanQR.styles';

const ScanQR = () => {
  const [appSettings, setAppSettings] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const getManagerAppSettings = async () => {
    try {
      const { data } = await getAppSettings();
      setAppSettings(data?.[0] || null);
    } catch (e) {
      // Do nothing
    }
  };

  const onImageClick = () => {
    window.open(appSettings.url, '_self');
  };

  useEffect(() => {
    getManagerAppSettings();
  }, []);

  if (!appSettings?.is_active) {
    return null;
  }

  return (
    <div className='position-relative'>
      <SBannerWrapper onClick={onImageClick}>
        <img src={appSettings.image || iphoneSmall} alt='new feature' width={40} height={40} />
        <Typography variant='s2' style={{ color: palette.gray600 }}>
          {appSettings.title}{' '}
          <Typography variant='button2' style={{ color: palette.green400 }}>
            NEW
          </Typography>
        </Typography>
      </SBannerWrapper>
      {openPopup && (
        <ClickAwayListener onClickAway={() => setOpenPopup(false)}>
          <SDownloadPopup>
            <div className='download-content-wrapper'>
              <div className='triangle-down' />
              <img src={iphoneBig} alt='iphone' className='phone-image' />
              <div className='download-left-wrapper'>
                <div className='d-flex flex-column gap-5'>
                  <Typography variant='h1' style={{ fontWeight: 300, color: palette.gray700, letterSpacing: '1.4px' }}>
                    Download the Manager App to get{' '}
                    <Typography style={{ color: palette.green300, fontWeight: 600 }}>real-time updates</Typography> from
                    anywhere!
                  </Typography>

                  <div className='d-flex align-items-start gap-4'>
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(appSettings.qr_code)}`}
                      alt='QR'
                      width={156}
                      height={156}
                    />
                    <div className='d-flex flex-column gap-2'>
                      <Typography variant='h4' style={{ color: palette.gray700, lineHeight: '22px' }}>
                        Scan QR Code to download
                      </Typography>
                      <div className='d-flex flex-column gap-2'>
                        <img
                          src={appStore}
                          alt='App Store'
                          width={112}
                          height={34}
                          onClick={onImageClick}
                          className='pointer'
                        />
                        <img
                          src={googlePlay}
                          alt='Google Play'
                          width={112}
                          height={34}
                          onClick={onImageClick}
                          className='pointer'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SDownloadPopup>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default ScanQR;
