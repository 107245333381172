import axios from '../services/axios';

export async function getAllVehicles(filters, cancelToken, locationFilterData, optionFilterData) {
  const params = { filter: filters };
  if (locationFilterData || optionFilterData) {
    const { allFilterData } = locationFilterData || optionFilterData;
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      if (value !== undefined) {
        params[`${key}`] = typeof allFilterData[key] === 'object' ? allFilterData[key].toString() : allFilterData[key];
      }
    });
  }
  const { data } = await axios.get('/equipment/vehicles', { params, cancelToken });
  return data;
}

export async function getMapTabs() {
  const { data } = await axios.get('/tabs?type=map');
  return data;
}

export async function getAllTrailers(filters, cancelToken, locationFilterData, optionFilterData) {
  const params = { filter: filters };
  if (locationFilterData || optionFilterData) {
    const { allFilterData } = locationFilterData || optionFilterData;
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      if (value !== undefined) {
        params[`${key}`] = typeof allFilterData[key] === 'object' ? allFilterData[key].toString() : allFilterData[key];
      }
    });
  }
  const { data } = await axios.get('/equipment/trailers', { params, cancelToken });
  return data;
}

export async function equipmentStatuses(type) {
  const params = { equipment_type: type };
  const { data } = await axios.get('/equipment/statuses', { params });
  return data;
}

export async function mapPageFlag(dataJson) {
  const { data } = await axios.post('/equipment/map/map-page-flag', dataJson);
  return data;
}

export async function getVehicleNextAvailableStops() {
  const { data } = await axios.get('/equipment/map-next-available-locations');
  return data;
}
