import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTheme } from 'context/themeContext';
import { getAlphabet, palette } from 'utils/constants';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import useDateFormat from 'hooks/useDateFormat';
import sm from '../../ShipmentStops/helpers/OptimizeRoutePage/OptimizeRoutePage.module.css';
import { Typography } from '../../../Typography';
import { SCHEDULED_DATE_TYPE } from '../../ShipmentStops/helpers/constants';
import { ShipmentContext } from '../../Layout';
import { getAverageTimeConverted } from '../../../StopPoint/Walmart/helpers';

const OptimizeRoutePageLTL = ({ data }) => {
  const { use } = useTheme();
  const { formatDate, formatTime } = useDateFormat();
  const { stopPoints, createShipmentData } = useContext(ShipmentContext);

  const getStopName = (type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: use(palette.green500, palette.green400) },
      2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500) },
      3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText) },
    };
    return TYPE_STOPS[type];
  };

  return (
    <div className={sm.optimize_route_container}>
      {data?.map((stop, index) => {
        const { reference_id, customer } = stop;
        const point = stopPoints.find((point) => Number(point?.key) === +stop.stop_point_id);
        const scheduledType = SCHEDULED_DATE_TYPE.find((type) => Number(type?.key) === +stop.scheduled_type);
        return (
          <Draggable
            key={stop.id.toString()}
            draggableId={stop.id.toString()}
            index={index}
            disableInteractiveElementBlocking
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div className={[sm.optimize_stop_wrapper, sm[use('light', 'dark')]].join(' ')}>
                  <div className={sm.order_wrapper}>
                    <Typography
                      variant='c1'
                      className={sm.pickUp_title}
                      style={{ color: getStopName(stop.stop_point_type).color }}
                    >
                      STOP {getAlphabet(index)} {getStopName(stop.stop_point_type).type}
                    </Typography>
                    <Typography variant='c2' style={{ marginLeft: 8 }}>
                      {`${customer?.company_name}-${reference_id}`}
                    </Typography>
                  </div>
                  <div className={sm.line} />
                  <div className={sm.optimize_dragItem_wrapper}>
                    <div className={sm.dragItem_left}>
                      <Typography variant='s1'>{point?.location_name}</Typography>
                      <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray300) }}>
                        {point?.address1}
                      </Typography>
                      <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray300) }}>
                        {point?.address2}
                      </Typography>
                      <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray300) }}>
                        {point?.city} {point?.state} {point?.zipcode}
                      </Typography>
                    </div>
                    <div className={sm.dragItem_right} style={{ whiteSpace: 'nowrap' }}>
                      <span
                        style={{
                          backgroundColor: '#E9EDF5',
                          padding: '2px 8px',
                          borderRadius: 8,
                          fontSize: 11,
                          marginRight: 4,
                        }}
                      >
                        {' '}
                        Avg. Wait Time: {getAverageTimeConverted(point?.average_waiting_time)}
                      </span>
                      <DragIcon className={sm.drag_icon} width={14} height={14} />
                    </div>
                  </div>

                  {Number(createShipmentData?.frequency) !== 2 && (
                    <div className={sm.date_wrapper}>
                      <Typography variant='c1'>
                        {stop?.scheduled_date ? formatDate(stop?.scheduled_date) : ''}
                      </Typography>
                      <Typography variant='c1' style={{ marginRight: 5 }}>
                        {stop?.startTime ? `, ${formatTime(stop?.startTime)}` : ''}
                      </Typography>
                      <Typography variant='c1' style={{ marginRight: 5 }}>
                        {stop?.endTime ? `to ${formatTime(stop?.endTime)}` : ''}
                      </Typography>
                      <Typography variant='c1'>
                        {Number(scheduledType?.key) === 1 ? '(FCFS)' : scheduledType?.label ? scheduledType?.label : ''}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Draggable>
        );
      })}
    </div>
  );
};

export default OptimizeRoutePageLTL;
