import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getDriverHosDailyLogs } from 'Api/HOS';
import { createLogbook, deleteLogbook, updateLogbook } from 'Api/AccidentsAndCrashes';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import ViewAttachment from 'components/ViewAttachment';
import { getLogbookInitialValues } from '../AddAccidents.data';
import { STable, SAddMore } from '../AddCarrier.styles';
import HosRecordModal from './HosRecordModal';
import { validationSchema } from './validationSchema';

const Logbook = ({ Cancel, nextStep, accident, id, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(!accident);
  const [attachment, setAttachment] = useState(null);
  const [dailyLogs, setDailyLogs] = useState([]);
  const [hosToView, setHosToView] = useState(null);
  const { formatDate } = useDateFormat();
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const newItems = values.filter((i) => i.isNew);
      const existingItems = values.filter((i) => !i.isNew);

      const createPromises = newItems.map((item) => {
        const formData = new FormData();
        if (item.logbook_date) {
          formData.append('logbook_date', moment(item.logbook_date).format('YYYY-MM-DD'));
        }
        if (item.logbook_attachment) {
          formData.append('logbook_attachment', item.logbook_attachment);
        }
        if (item.hos_daily_log) {
          formData.append('hos_daily_log_id', item.hos_daily_log.id);
        }

        return createLogbook(accident?.id || id, formData);
      });

      const updatePromises = existingItems.map((item) => {
        const formData = new FormData();
        if (item.logbook_date) {
          formData.append('logbook_date', moment(item.logbook_date).format('YYYY-MM-DD'));
        }
        if (item.logbook_attachment) {
          formData.append('logbook_attachment', item.logbook_attachment);
        }
        if (item.hos_daily_log) {
          formData.append('hos_daily_log_id', item.hos_daily_log.id);
        }

        return updateLogbook(item.id, formData);
      });

      await Promise.all([...createPromises, ...updatePromises]);
      onSuccess();
      nextStep();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, touchedErrors, setValues } = useForm({
    initialValues: getLogbookInitialValues(accident),
    onSubmit,
    validationSchema,
  });

  const getDailyLogs = async () => {
    try {
      const { data } = await getDriverHosDailyLogs(accident?.driver_id, {});
      setDailyLogs(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddMore = () => {
    handleChange(`${values.length}`, {
      id: Math.random(),
      logbook_date: new Date(),
      logbook_attachment: null,
      hos_daily_log: null,
      isNew: true,
    });
  };

  const onDelete = async (logbook) => {
    try {
      if (!logbook.isNew) {
        await deleteLogbook(logbook.id);
        showToaster({ type: 'success', message: 'Log has been successfully deleted!' });
      }

      const newItems = values.filter((i) => i.id !== logbook.id);
      setValues(newItems);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  useEffect(() => {
    if (accident?.driver_id) {
      getDailyLogs();
    }
  }, []);

  return (
    <>
      <Modal.Body className='add-accident-modal-body'>
        <div className='mt-5'>
          <STable>
            <thead>
              <tr className='header-row'>
                <th>LOGBOOK DATE</th>
                {!!editMode && <th>ATTACH LOGBOOK</th>}
                <th>PREVIEW</th>
                {!!editMode && <th style={{ width: '100px' }}>ACTION</th>}
              </tr>
            </thead>
            <tbody>
              {values?.map((item, index) => (
                <tr key={item.id} className='body-row'>
                  <td>
                    {editMode ? (
                      <div>
                        <DatePicker
                          width='170px'
                          name={`${index}.logbook_date`}
                          value={item.logbook_date}
                          onBlur={handleBlur}
                          onChange={(val) => handleChange(`${index}.logbook_date`, val)}
                          error={touchedErrors?.[index]?.logbook_date}
                        />
                      </div>
                    ) : (
                      <Typography variant='s2'>{item.logbook_date ? formatDate(item.logbook_date) : '-'}</Typography>
                    )}
                  </td>
                  {!!editMode && (
                    <td>
                      <div className='d-flex align-items-center gap-2'>
                        {!item.hos_daily_log && (
                          <Uploader
                            type={3}
                            uploadedLabel='Uploaded'
                            document={item.logbook_attachment}
                            onDrop={(files) => handleChange(`${index}.logbook_attachment`, files[0])}
                            onRemove={() => handleChange(`${index}.logbook_attachment`, null)}
                            accept={['image/*']}
                          />
                        )}
                        {!!accident?.driver &&
                          !!dailyLogs.length &&
                          !item.logbook_attachment &&
                          !item.hos_daily_log && <Typography variant='s2'>or</Typography>}
                        {!!accident?.driver && !!dailyLogs.length && !item.logbook_attachment && (
                          <Autocomplete
                            width='250px'
                            name={`${index}.hos_daily_log`}
                            options={dailyLogs}
                            placeholder='Select from Hours of Service'
                            value={item.hos_daily_log}
                            onChange={(e, val) => handleChange(`${index}.hos_daily_log`, val)}
                            renderOption={(props, option) => (
                              <li {...props} key={option}>
                                {accident.driver.fname} {accident.driver.lname} - {formatDate(option.start_time)}
                              </li>
                            )}
                            getOptionLabel={(option) =>
                              `${accident.driver.fname} ${accident.driver.lname} - ${formatDate(option.start_time)}`
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disableClearable={false}
                          />
                        )}
                      </div>
                    </td>
                  )}
                  <td>
                    <div>
                      {item.logbook_attachment ? (
                        <img
                          width={40}
                          height={40}
                          src={
                            typeof item.logbook_attachment === 'string'
                              ? item.logbook_attachment
                              : URL.createObjectURL(item.logbook_attachment)
                          }
                          alt='thumbnail'
                          className='attachment-thumbnail'
                          onClick={() =>
                            setAttachment(
                              typeof item.logbook_attachment === 'string'
                                ? item.logbook_attachment
                                : URL.createObjectURL(item.logbook_attachment)
                            )
                          }
                        />
                      ) : item.hos_daily_log ? (
                        <SAddMore onClick={() => setHosToView(item.hos_daily_log)}>
                          <Typography variant='s2' style={{ color: palette.indigo500 }}>
                            View HOS
                          </Typography>
                        </SAddMore>
                      ) : (
                        <Typography variant='s2'>-</Typography>
                      )}
                    </div>
                  </td>
                  {!!editMode && (
                    <td>
                      <span className='action-wrapper' onClick={() => onDelete(item)}>
                        <DeleteIcon width={9} height={9} fill={palette.red500} />
                      </span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </STable>
          {!!editMode && (
            <SAddMore onClick={onAddMore}>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                Add Another...
              </Typography>
            </SAddMore>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: palette.white, borderColor: palette.gray50 }}>
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Close
            </Button>
          </div>
          <div className='pagination-btn'>
            {!!accident && (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='secondary'
                title='Edit'
                disabled={loading}
                leftIcon={<EditIcon style={{ marginRight: 10 }} />}
                onClick={() => setEditMode((prevState) => !prevState)}
              />
            )}
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Next'
              disabled={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
        {!!attachment && (
          <ViewAttachment
            open={!!attachment}
            onClose={() => setAttachment(null)}
            attachment={attachment}
            title='Attachment'
          />
        )}
        {!!hosToView && (
          <HosRecordModal
            open={!!hosToView}
            onClose={() => setHosToView(null)}
            driverId={hosToView.driver_id}
            startTime={hosToView.start_time}
            endTime={hosToView.end_time}
          />
        )}
      </Modal.Footer>
    </>
  );
};

export default Logbook;
