import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onEdit, onView, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'edit':
        onEdit();
        break;
      case 'view':
        onView();
        break;
      case 'delete':
        onDelete();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      {onView && <li onClick={(event) => onActionClick(event, 'view')}>View</li>}
      {onEdit && <li onClick={(event) => onActionClick(event, 'edit')}>Edit</li>}
      {onDelete && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'delete')}>
          Delete
        </li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
