import React, { useEffect, useState } from 'react';
import { palette, statusColor } from 'utils/constants';
import linkIcon from 'assets/icons/drivers/linkIcon.svg';
import './OwnerContractor.css';
import { useTheme } from 'context/themeContext';
import { useNavigate, useParams } from 'react-router-dom';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { Dropdown } from 'react-bootstrap';
import { operatorDriver } from 'Api/OwnerOperator';

const RequestTable = () => {
  const [contractorList, setContractorList] = useState();
  const { id } = useParams();
  const { use } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    operatorDriver({ id })
      .then((res) => {
        setContractorList(res.data);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  return (
    <div
      className='owner-equip-container owner-contractor-container'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Contractors
          <span className='contractor-count-wrapper'>{contractorList?.drivers?.length || 0}</span>
        </p>
      </div>
      <div className='contractor-operator-list-wrap'>
        {contractorList?.drivers?.length ? (
          contractorList?.drivers?.map((item) => {
            return (
              <div className='wrapper' key={item.id} onClick={() => navigate(`/driver-profile/${item.id}/general`)}>
                <div>
                  <Dropdown className='driver-name-basic-wrapper' onClick={(e) => e.stopPropagation()}>
                    <Dropdown.Toggle className='menu-drop' id='driver-name-basic'>
                      <div className='details-container'>
                        <div className='image-container'>
                          <img src={item?.image ? item?.image : userDefault} alt='' className='user-image' />
                          <div
                            className='user-active-indicator'
                            style={{
                              backgroundColor: use(
                                statusColor[item?.status_data?.driver_status]?.color,
                                statusColor[item?.status_data?.driver_status]?.darkColor
                              ),
                            }}
                          />
                        </div>
                        <div className='username--details-contractor'>
                          <div className='user-name-and-actions-container'>
                            <h2
                              className='name'
                              style={{
                                color: use(palette.dark800, palette.gray200),
                              }}
                            >
                              {`${item?.fname} ${item?.lname}`}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>

                <div className='linkIcon-conteiner'>
                  <img src={linkIcon} alt='' />
                </div>
              </div>
            );
          })
        ) : (
          <div className='contractor-operator-list-wrap--subline-wrap'>
            <span className='contractor-operator-list-wrap--subline'>No records to display</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestTable;
