import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import Info from './Info';
import { SColumn, SSection } from '../ViewApplicantModal.styles';

const Education = ({ data }) => {
  const { formatDate } = useDateFormat();
  const {
    school_name,
    start_date,
    end_date,
    address1,
    country,
    state,
    city,
    zipcode,
    subject,
    phone_no,
    is_graduated,
    graduation_date,
    gpa,
    instruction_hrs,
    log_books,
    subject_to_safety_regulations,
    perform_safety_functions,
    crossed_border,
    federal_motor_regulations,
    hazardous_materials,
  } = data || {};

  if (!data) {
    return null;
  }

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        Education
      </Typography>
      <SSection>
        <SColumn>
          <Info label='School Name' infoText={school_name} />
          <Info label='Start Date' infoText={start_date ? formatDate(start_date) : '-'} />
          <Info label='End Date' infoText={end_date ? formatDate(end_date) : '-'} />
          <Info label='Address' infoText={address1}>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {city?.name}
              {city ? ',' : ''} {state?.name}
              {state ? ',' : ''} {country?.sortname || country?.name} {zipcode}
            </Typography>
          </Info>
        </SColumn>
        <SColumn>
          <Info label='What did you study?' infoText={subject || '-'} />
          <Info
            label='Phone Number'
            infoText={phone_no ? formatPhoneNumberIntl(phone_no.startsWith('+') ? phone_no : `+${phone_no}`) : '-'}
          />
          <Info
            label='Graduation Date'
            infoText={!is_graduated ? 'On-going' : graduation_date ? formatDate(graduation_date) : '-'}
          />
          <Info label='GPA' infoText={gpa ? Number(gpa) : '-'} />
          <Info label='Hours of Instruction' infoText={instruction_hrs ? Number(instruction_hrs) : '-'} />
        </SColumn>
        <SColumn>
          <Info label='Logbooks used?' infoText={Number(log_books) ? 'Yes' : 'No'} />
          <Info
            label='Were you subject to the FMCSA or Transport Canada Safety Regulations while attending this school?'
            infoText={subject_to_safety_regulations ? 'Yes' : 'No'}
          />
          <Info
            label='Did you perform any safety sensitive functions at this school, regulated by DOT, and subject to drug and alcohol testing?'
            infoText={perform_safety_functions ? 'Yes' : 'No'}
          />
          <Info label='Border Crossing' infoText={crossed_border ? 'Yes' : 'No'} />
          <Info label='Federal Motor Carrier Regulations' infoText={federal_motor_regulations ? 'Yes' : 'No'} />
          <Info label='Hazardous Materials' infoText={hazardous_materials ? 'Yes' : 'No'} />
        </SColumn>
      </SSection>
    </div>
  );
};

export default Education;
