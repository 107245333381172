import React, { forwardRef, useState } from 'react';
import MaterialTable from 'material-table';
import './EquipmentTableWrapper.css';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';

import { ReactComponent as TopArrow } from 'assets/icons/tableSortArrowTop.svg';
import { ReactComponent as TopArrowActive } from 'assets/icons/tableSortArrowTopActive.svg';
import { ReactComponent as DownArrow } from 'assets/icons/tableSortArrowDown.svg';
import { ReactComponent as DownArrowActive } from 'assets/icons/tabelSortArrowDownActive.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MaterialTableWrapper = ({ ...props }) => {
  const { use } = useTheme();
  return (
    <MaterialTable
      icons={tableIcons}
      options={{
        pageSize: Number(props.rowPerPage),
        pageSizeOptions: [10, 50, 100, 250, 500],
        emptyRowsWhenPaging: false,
        toolbar: false,
        sorting: false,
        thirdSortClick: false,
        draggable: false,
        tableLayout: 'auto',
        columnResizable: true,
        headerStyle: {
          backgroundColor: use(palette.white, palette.dark800),
        },
      }}
      {...props}
    />
  );
};

export default MaterialTableWrapper;

export const EquipmentTableSort = ({ title, field, sortingQuery, nested_field }) => {
  const [sortBy, setSortBy] = useState(field === 'service_date_time' ? 'asc' : 'desc');
  const { use } = useTheme();
  return (
    <div
      className='columns_item_wrapper'
      onClick={() => {
        setSortBy((p) =>
          field === 'service_date_time' ? (p === 'desc' ? 'asc' : 'desc') : p === 'asc' ? 'desc' : 'asc'
        );
        sortingQuery(field, sortBy, nested_field);
      }}
    >
      <span className='equipment_table_title' style={{ color: use(palette.gray900, palette.white) }}>
        {title}
      </span>
      <span className='equipment_table_custom_sorting_icons'>
        {sortBy !== 'asc'
          ? use(<TopArrowActive style={{ marginBottom: 1 }} />, <TopArrow style={{ marginBottom: 1 }} />)
          : use(<TopArrow style={{ marginBottom: 1 }} />, <TopArrowActive style={{ marginBottom: 1 }} />)}
        {sortBy !== 'asc'
          ? use(<DownArrow style={{ marginTop: 1 }} />, <DownArrowActive style={{ marginBottom: 1 }} />)
          : use(<DownArrowActive style={{ marginBottom: 1 }} />, <DownArrow style={{ marginTop: 1 }} />)}
      </span>
    </div>
  );
};
