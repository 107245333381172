import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warningOutline.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import Input, { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import AdditionsSettings from 'pages/Payroll/Settlement/Tables/Additions/components/AdditionsSettings';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getAdditionsList } from 'Api/PayrollSettings';
import { getDriverCompensation, updateDriverCompensation } from 'Api/CompanySettings';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { InputWithText, InputWithIconAndText } from '../shared/InputWithText';
import { getDriverCompensationConverter, updateDriverCompensationConverter } from './converters';
import { validationSchema } from './validationSchema';
import { initialValues } from './DriverCompensation.data';
import { SCircularProgress, SWrapper, SAddMore } from '../Policies.styles';

const DriverCompensation = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(true);
  const [additionTypes, setAdditionTypes] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const updateDriverCompensationSettings = async (values) => {
    try {
      setLoadingUpdate(true);
      const body = updateDriverCompensationConverter(values);
      await updateDriverCompensation(body);
      showToaster({ type: 'success', message: 'Settings have been successfully updated' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingUpdate(false);
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit: updateDriverCompensationSettings,
  });

  const getDriverCompensationSettings = async () => {
    try {
      setLoading(true);
      const { data } = await getDriverCompensation();
      const convertedData = getDriverCompensationConverter(data);
      setValues(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const handleNumberInputChange = (field, value) => {
    if (value < 0) {
      return;
    }
    handleChange(field, value);
  };

  const getAdditionTypes = async () => {
    try {
      const { data } = await getAdditionsList();
      const types = data?.map((item) => ({ id: item.id, amount: item.amount, type: item.addition_type }));
      setAdditionTypes(types || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onSettingsClose = () => {
    getAdditionTypes();
    setSettingsOpen(false);
  };

  const addRow = () => {
    const newAdditions = [
      ...values.shipment_addition,
      {
        payroll_addition: null,
        amount: '',
      },
    ];
    setValues({ ...values, shipment_addition: newAdditions });
  };

  const deleteRow = (index) => {
    const newAdditions = values.shipment_addition;
    newAdditions.splice(index, 1);
    handleChange('shipment_addition', newAdditions);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAdditionTypes();
    getDriverCompensationSettings();
  }, []);

  if (loading) {
    return (
      <SWrapper>
        <SCircularProgress size={26} />
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      <PageHeader
        title='Driver Compensation'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
      />
      <Divider />
      <Grid container alignItems='center'>
        <CustomCheckbox
          type='switch'
          name='driver_dispute'
          checked={!!values.driver_dispute}
          onChange={(checked) => handleChange('driver_dispute', checked)}
          smail={false}
        />
        <Grid item>
          <div className='d-flex align-items-center'>
            <Typography variant='s1' style={{ margin: '0 16px' }}>
              Allow Drivers to dispute settlements
            </Typography>
            <Input
              type='number'
              onKeyDown={blockNotNumberChars}
              name='driver_dispute_days'
              placeholder='0'
              style={{ width: '80px' }}
              disabled={!values.driver_dispute}
              value={values.driver_dispute_days}
              onChange={(e) => handleNumberInputChange('driver_dispute_days', e.target.value)}
            />
            <Typography variant='s1' style={{ margin: '0 16px' }}>
              day(s) prior to Pay Date.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container alignItems='center'>
        <CustomCheckbox
          type='switch'
          name='default_pay_raise'
          checked={!!values.default_pay_raise}
          onChange={(checked) => handleChange('default_pay_raise', checked)}
          smail={false}
        />
        <Grid item>
          <Typography variant='s1' style={{ marginLeft: 16 }}>
            Default Pay Raise
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={3}>
          <Typography variant='s2' style={{ marginLeft: 44, color: use(palette.gray700, palette.gray200) }}>
            Per Mile
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <div className='d-flex'>
            <InputWithIconAndText
              text='Loaded'
              type='number'
              name='pay_raise_per_mile_loaded'
              placeholder='0.00'
              style={{ width: '100px' }}
              value={values.pay_raise_per_mile_loaded}
              onChange={(e) => handleNumberInputChange('pay_raise_per_mile_loaded', e.target.value)}
            />
            <div style={{ display: 'flex', marginLeft: '12px' }}>
              <InputWithIconAndText
                text='Empty'
                type='number'
                name='pay_raise_per_mile_empty'
                placeholder='0.00'
                style={{ width: '100px' }}
                value={values.pay_raise_per_mile_empty}
                onChange={(e) => handleNumberInputChange('pay_raise_per_mile_empty', e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0', columnGap: '10px' }}>
            <ArrowIcon />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              after
            </Typography>
            <InputWithText
              text='Days'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_per_mile_days'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_per_mile_days}
              onChange={(e) => handleNumberInputChange('pay_raise_per_mile_days', e.target.value)}
            />
            <InputWithText
              text='Months'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_per_mile_months'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_per_mile_months}
              onChange={(e) => handleNumberInputChange('pay_raise_per_mile_months', e.target.value)}
            />
            <InputWithText
              text='Years'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_per_mile_years'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_per_mile_years}
              onChange={(e) => handleNumberInputChange('pay_raise_per_mile_years', e.target.value)}
            />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              From hire date
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={3}>
          <Typography variant='s2' style={{ marginLeft: 44, color: use(palette.gray700, palette.gray200) }}>
            Per Hour
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <div className='d-flex'>
            <InputWithIconAndText
              text='per hour'
              type='number'
              name='pay_raise_per_hour'
              placeholder='0.00'
              style={{ width: '100px' }}
              value={values.pay_raise_per_hour}
              onChange={(e) => handleNumberInputChange('pay_raise_per_hour', e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0', columnGap: '10px' }}>
            <ArrowIcon />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              after
            </Typography>
            <InputWithText
              text='Days'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_per_hour_days'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_per_hour_days}
              onChange={(e) => handleNumberInputChange('pay_raise_per_hour_days', e.target.value)}
            />
            <InputWithText
              text='Months'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_per_hour_months'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_per_hour_months}
              onChange={(e) => handleNumberInputChange('pay_raise_per_hour_months', e.target.value)}
            />
            <InputWithText
              text='Years'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_per_hour_years'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_per_hour_years}
              onChange={(e) => handleNumberInputChange('pay_raise_per_hour_years', e.target.value)}
            />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              From hire date
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0', columnGap: '10px' }}>
            <Input
              name='pay_per_hour_breaks_allowed_in_given_days'
              type='number'
              onKeyDown={blockNotNumberChars}
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_per_hour_breaks_allowed_in_given_days}
              onChange={(e) => handleNumberInputChange('pay_per_hour_breaks_allowed_in_given_days', e.target.value)}
            />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Breaks Allowed In Given Work Day for
            </Typography>
            <InputWithText
              text='minutes'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_per_hour_break_time_allowed'
              style={{ width: '80px' }}
              placeholder='0'
              value={values.pay_per_hour_break_time_allowed}
              onChange={(e) => handleNumberInputChange('pay_per_hour_break_time_allowed', e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '12px', margin: '12px 0' }}>
            <CustomCheckbox
              name='pay_per_hour_breaks_required'
              checked={!!values.pay_per_hour_breaks_required}
              onChange={(checked) => handleChange('pay_per_hour_breaks_required', checked)}
            >
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: 5,
                  whiteSpace: 'nowrap',
                }}
              >
                Breaks are Required
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='deduct_break_time'
              checked={!!values.deduct_break_time}
              onChange={(checked) => handleChange('deduct_break_time', checked)}
            >
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: 5,
                  whiteSpace: 'nowrap',
                }}
              >
                Deduct Break Time
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='pay_per_hour_break_time_paid'
              checked={!!values.pay_per_hour_break_time_paid}
              onChange={(checked) => handleChange('pay_per_hour_break_time_paid', checked)}
            >
              <Typography
                variant='s2'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: 5,
                  whiteSpace: 'nowrap',
                }}
              >
                Break Time Paid
              </Typography>
            </CustomCheckbox>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={3}>
          <Typography variant='s2' style={{ marginLeft: 44, color: use(palette.gray700, palette.gray200) }}>
            By Percentage
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <div>
            <InputWithText
              text='%'
              type='number'
              name='pay_raise_percentage'
              placeholder='0.00'
              style={{ width: '60px' }}
              value={values.pay_raise_percentage}
              onChange={(e) => handleNumberInputChange('pay_raise_percentage', e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0 48px 0', columnGap: '10px' }}>
            <ArrowIcon />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              after
            </Typography>
            <InputWithText
              text='Days'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_percentage_days'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_percentage_days}
              onChange={(e) => handleNumberInputChange('pay_raise_percentage_days', e.target.value)}
            />
            <InputWithText
              text='Months'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_percentage_months'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_percentage_months}
              onChange={(e) => handleNumberInputChange('pay_raise_percentage_months', e.target.value)}
            />
            <InputWithText
              text='Years'
              type='number'
              onKeyDown={blockNotNumberChars}
              name='pay_raise_percentage_years'
              placeholder='0'
              style={{ width: '60px' }}
              value={values.pay_raise_percentage_years}
              onChange={(e) => handleNumberInputChange('pay_raise_percentage_years', e.target.value)}
            />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              From hire date
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray200) }}>
        Other Payment Conditions
      </Typography>
      <div className='d-flex align-items-center justify-content-start gap-2'>
        <WarningIcon fill={palette.indigo500} />
        <Typography variant='b2' style={{ color: palette.indigo500 }}>
          These payment conditions will not modify existing driver compensation profiles. They are intended solely for
          pre-filling data when adding a new driver.
        </Typography>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            type='switch'
            name='paid_time_off_required'
            smail={false}
            checked={!!values.paid_time_off_required}
            onChange={(checked) => handleChange('paid_time_off_required', checked)}
          />
          <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
            Paid Time Off (PTO)
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <InputWithIconAndText
            text='per day'
            type='number'
            name='paid_time_off_per_day'
            placeholder='0.00'
            style={{ width: '100px' }}
            disabled={!values.paid_time_off_required}
            value={values.paid_time_off_per_day}
            onChange={(e) => handleNumberInputChange('paid_time_off_per_day', e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            type='switch'
            name='detention'
            smail={false}
            checked={!!values.detention}
            onChange={(checked) => handleChange('detention', checked)}
          />
          <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
            Detention Pay
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <InputWithIconAndText
            text='per hour'
            type='number'
            name='detention_pay_per_hour'
            placeholder='0.00'
            style={{ width: '100px' }}
            disabled={!values.detention}
            value={values.detention_pay_per_hour}
            onChange={(e) => handleNumberInputChange('detention_pay_per_hour', e.target.value)}
          />
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            after
          </Typography>
          <Input
            type='number'
            onKeyDown={blockNotNumberChars}
            name='detention_pay_after_hours_passed'
            placeholder='0'
            style={{ width: '80px' }}
            disabled={!values.detention}
            value={values.detention_pay_after_hours_passed}
            onChange={(e) => handleNumberInputChange('detention_pay_after_hours_passed', e.target.value)}
          />
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            hour(s) have passed
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            type='switch'
            name='layover'
            smail={false}
            checked={!!values.layover}
            onChange={(checked) => handleChange('layover', checked)}
          />
          <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
            Layover Pay
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <InputWithIconAndText
            text='for each layover'
            type='number'
            name='each_layover_pay'
            placeholder='0.00'
            style={{ width: '100px' }}
            disabled={!values.layover}
            value={values.each_layover_pay}
            onChange={(e) => handleNumberInputChange('each_layover_pay', e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            type='switch'
            name='stop_off'
            smail={false}
            checked={!!values.stop_off}
            onChange={(checked) => handleChange('stop_off', checked)}
          />
          <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
            Stop Off Pay
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <InputWithIconAndText
            text='per each stop off'
            type='number'
            name='stop_off_pay_each_stop'
            placeholder='0.00'
            style={{ width: '100px' }}
            disabled={!values.stop_off}
            value={values.stop_off_pay_each_stop}
            onChange={(e) => handleNumberInputChange('stop_off_pay_each_stop', e.target.value)}
          />
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            after
          </Typography>
          <Input
            name='stop_off_pay_after_stop'
            type='number'
            onKeyDown={blockNotNumberChars}
            placeholder='0'
            style={{ width: '80px' }}
            disabled={!values.stop_off}
            value={values.stop_off_pay_after_stop}
            onChange={(e) => handleNumberInputChange('stop_off_pay_after_stop', e.target.value)}
          />
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            stops
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {/* <Grid container> */}
      {/*  <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}> */}
      {/*    <CustomCheckbox */}
      {/*      type='switch' */}
      {/*      name='live_upload' */}
      {/*      smail={false} */}
      {/*      checked={!!values.live_upload} */}
      {/*      onChange={(checked) => handleChange('live_upload', checked)} */}
      {/*    /> */}
      {/*    <Typography */}
      {/*      variant='s2' */}
      {/*      style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }} */}
      {/*    > */}
      {/*      Live Unload Pay */}
      {/*    </Typography> */}
      {/*  </Grid> */}
      {/*  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}> */}
      {/*    <InputWithIconAndText */}
      {/*      text='per live unload' */}
      {/*      type='number' */}
      {/*      name='live_unload_pay' */}
      {/*      placeholder='0.00' */}
      {/*      style={{ width: '100px' }} */}
      {/*      disabled={!values.live_upload} */}
      {/*      value={values.live_unload_pay} */}
      {/*      onChange={handleChange} */}
      {/*    /> */}
      {/*  </Grid> */}
      {/* </Grid> */}
      {/* <Divider /> */}
      <Grid container>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            type='switch'
            name='truck_order_not_used'
            checked={!!values.truck_order_not_used}
            onChange={(checked) => handleChange('truck_order_not_used', checked)}
            smail={false}
          />
          <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
            Truck Order Not Used Pay
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <InputWithIconAndText
            text='per each truck order not used'
            type='number'
            name='track_order_not_used_pay'
            placeholder='0.00'
            style={{ width: '100px' }}
            disabled={!values.truck_order_not_used}
            value={values.track_order_not_used_pay}
            onChange={(e) => handleNumberInputChange('track_order_not_used_pay', e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider />
      {values.shipment_addition.map((item, index) => {
        return (
          <div key={item.id || index}>
            <Grid container>
              <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                {index === 0 && (
                  <>
                    <CustomCheckbox
                      type='switch'
                      name='pay_on_each_shipment'
                      checked={values.pay_on_each_shipment}
                      onChange={(checked) => handleChange('pay_on_each_shipment', checked)}
                      smail={false}
                    />
                    <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
                      Pay On Each Shipment
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={9}>
                {index !== 0 && <Divider />}
                <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                  <div>
                    <InputWithIcon
                      type='number'
                      name={`shipment_addition[${index}].amount`}
                      placeholder='0.00'
                      style={{ width: '100px' }}
                      value={values.shipment_addition[index].amount}
                      onChange={(e) => handleChange(`shipment_addition[${index}].amount`, e.target.value)}
                      onKeyDown={blockNonPositiveNumbers}
                      disabled={!values.pay_on_each_shipment}
                      error={touchedErrors.shipment_addition?.[index]?.amount}
                    />
                  </div>
                  <Typography variant='s2'>for</Typography>
                  <div>
                    <Autocomplete
                      width='250px'
                      labelKey='type'
                      value={values.shipment_addition[index].payroll_addition}
                      onChange={(e, val) => handleChange(`shipment_addition[${index}].payroll_addition`, val)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={additionTypes}
                      disabled={!values.pay_on_each_shipment}
                      error={touchedErrors.shipment_addition?.[index]?.payroll_addition}
                    />
                  </div>
                  <GearIcon onClick={() => setSettingsOpen(true)} className='settings-gear' />
                  {index !== 0 && (
                    <span className='delete-icon' onClick={() => deleteRow(index)}>
                      <DeleteIcon fill={palette.red500} />
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        );
      })}
      {values.pay_on_each_shipment && (
        <Grid container>
          <Grid item xs={3} />
          <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
            <SAddMore onClick={addRow}>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                Add Another
              </Typography>
            </SAddMore>
          </Grid>
        </Grid>
      )}
      <AdditionsSettings open={settingsOpen} onClose={onSettingsClose} />
      {/* <Divider /> */}
      {/* <Grid container> */}
      {/*  <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}> */}
      {/*    <CustomCheckbox */}
      {/*      type='switch' */}
      {/*      name='break_down' */}
      {/*      smail={false} */}
      {/*      checked={!!values.break_down} */}
      {/*      onChange={(checked) => handleChange('break_down', checked)} */}
      {/*    /> */}
      {/*    <Typography */}
      {/*      variant='s2' */}
      {/*      style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }} */}
      {/*    > */}
      {/*      Break Down Pay */}
      {/*    </Typography> */}
      {/*  </Grid> */}
      {/*  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}> */}
      {/*    <InputWithIconAndText */}
      {/*      text='per every hour broken down' */}
      {/*      type='number' */}
      {/*      placeholder='0.00' */}
      {/*      name='break_down_pay' */}
      {/*      style={{ width: '100px' }} */}
      {/*      disabled={!values.break_down} */}
      {/*      value={values.break_down_pay} */}
      {/*      onChange={handleChange} */}
      {/*    /> */}
      {/*  </Grid> */}
      {/* </Grid> */}
      {/* <Divider /> */}
      {/* <Grid container> */}
      {/*  <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}> */}
      {/*    <CustomCheckbox */}
      {/*      type='switch' */}
      {/*      name='redelivery' */}
      {/*      smail={false} */}
      {/*      checked={!!values.redelivery} */}
      {/*      onChange={(checked) => handleChange('redelivery', checked)} */}
      {/*    /> */}
      {/*    <Typography */}
      {/*      variant='s2' */}
      {/*      style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }} */}
      {/*    > */}
      {/*      Re-Delivery Pay */}
      {/*    </Typography> */}
      {/*  </Grid> */}
      {/*  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}> */}
      {/*    <InputWithIconAndText */}
      {/*      text='per each re-delivery of a shipment' */}
      {/*      type='number' */}
      {/*      name='redelivery_pay' */}
      {/*      placeholder='0.00' */}
      {/*      style={{ width: '100px' }} */}
      {/*      disabled={!values.redelivery} */}
      {/*      value={values.redelivery_pay} */}
      {/*      onChange={handleChange} */}
      {/*    /> */}
      {/*  </Grid> */}
      {/* </Grid> */}
      {/* <Divider /> */}
      {/* <Grid container> */}
      {/*  <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}> */}
      {/*    <CustomCheckbox */}
      {/*      type='switch' */}
      {/*      name='tarp' */}
      {/*      smail={false} */}
      {/*      checked={!!values.tarp} */}
      {/*      onChange={(checked) => handleChange('tarp', checked)} */}
      {/*    /> */}
      {/*    <Typography */}
      {/*      variant='s2' */}
      {/*      style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }} */}
      {/*    > */}
      {/*      Tarp Pay */}
      {/*    </Typography> */}
      {/*  </Grid> */}
      {/*  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}> */}
      {/*    <InputWithIconAndText */}
      {/*      text='per each stop if tarp type shipment' */}
      {/*      type='number' */}
      {/*      name='tarp_pay' */}
      {/*      placeholder='0.00' */}
      {/*      style={{ width: '100px' }} */}
      {/*      disabled={!values.tarp} */}
      {/*      value={values.tarp_pay} */}
      {/*      onChange={handleChange} */}
      {/*    /> */}
      {/*  </Grid> */}
      {/* </Grid> */}
    </SWrapper>
  );
};

export default DriverCompensation;
