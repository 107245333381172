import React, { useState } from 'react';
import Modal from 'common/Modal';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import {
  createMultipleCargo,
  createMultipleQuoteCargo,
  createMultipleRecurringCargo,
  updateMultipleCargo,
  updateMultipleQuoteCargo,
  updateMultipleRecurringCargo,
} from 'Api/Shipment';
import CargoTable from './components/CargoTable';
import { validationSchema } from './validationSchema';
import { createCargoConverter, getInitialValues } from './ShipmentCommodity.data';

const ShipmentCommodity = ({
  open,
  onClose,
  cargo,
  stopId,
  quoteId,
  isRecurring,
  onSave = () => null,
  onCreateSuccess = () => null,
  onUpdateSuccess = () => null,
  onDeleteSuccess = () => null,
  readOnly,
  createMode,
  equipment,
}) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const createCargo = async (values) => {
    try {
      const body = values.map((row) => createCargoConverter(row));
      let createdCargo;
      if (isRecurring) {
        const { data } = await createMultipleRecurringCargo(stopId, { cargos: body });
        createdCargo = data;
      } else if (quoteId) {
        const { data } = await createMultipleQuoteCargo(stopId, { cargos: body });
        createdCargo = data;
      } else {
        const { data } = await createMultipleCargo(stopId, { cargos: body });
        createdCargo = data;
      }
      return createdCargo;
    } catch (e) {
      setLoading(false);
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const editCargo = async (values) => {
    try {
      const body = values.map((row) => createCargoConverter(row));
      let updatedCargo;
      if (isRecurring) {
        const { data } = await updateMultipleRecurringCargo({ cargos: body });
        updatedCargo = data;
      } else if (quoteId) {
        const { data } = await updateMultipleQuoteCargo({ cargos: body });
        updatedCargo = data;
      } else {
        const { data } = await updateMultipleCargo({ cargos: body });
        updatedCargo = data;
      }
      return updatedCargo;
    } catch (e) {
      setLoading(false);
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onSubmit = async (values) => {
    if (createMode) {
      onSave(values);
      onClose();
      return;
    }

    const newCargos = values.filter((i) => i.isNew);
    const updatedCargos = values.filter((i) => !i.isNew);

    setLoading(true);

    const updatedCargoData = [];

    if (newCargos.length) {
      const newCargoData = await createCargo(newCargos);
      updatedCargoData.push(...newCargoData);
    }

    if (updatedCargos.length) {
      const newCargoData = await editCargo(updatedCargos);
      updatedCargoData.push(...newCargoData);
    }

    onUpdateSuccess(updatedCargoData);
    showToaster({ type: 'success', message: 'Cargo has been successfully updated!' });
    setLoading(false);
    onClose();
  };

  const form = useForm({
    initialValues: getInitialValues(cargo),
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Cargo'
      $bgColor={palette.gray0}
      $width='95vw'
      $maxWidth={readOnly ? '1500px' : '1800px'}
      $minWidth='1200px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: readOnly ? 'Done' : 'Cancel',
        },
        ...(!readOnly
          ? [
              {
                key: 'submit',
                type: 'primary',
                title: 'Save',
                onClick: form.handleSubmit,
                disabled: loading,
              },
            ]
          : []),
      ]}
    >
      <div className='my-2'>
        <CargoTable
          cargo={cargo}
          stopId={stopId}
          quoteId={quoteId}
          isRecurring={isRecurring}
          onCreateSuccess={onCreateSuccess}
          onUpdateSuccess={onUpdateSuccess}
          onDeleteSuccess={onDeleteSuccess}
          readOnly={readOnly}
          createMode={createMode}
          form={form}
          equipment={equipment}
        />
      </div>
    </Modal>
  );
};

export default ShipmentCommodity;
