import React from 'react';
import { ReactComponent as CheckFilled } from '../../../../../assets/icons/check-filled.svg';
import { Typography } from '../../../../../components/Typography';
import { useTheme } from '../../../../../context/themeContext';
import { palette } from '../../../../../utils/constants';
import { SFlexWrapper, SWrapper } from './Final.styles';

const Final = () => {
  const { use } = useTheme();

  return (
    <SWrapper>
      <SFlexWrapper>
        <CheckFilled />
        <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray200) }} className='success-title'>
          Success!
        </Typography>
        <Typography variant='b1' style={{ color: use(palette.gray700, palette.gray200) }}>
          Your application has been successfully submitted. You should hear back from us soon.
        </Typography>
      </SFlexWrapper>
    </SWrapper>
  );
};

export default Final;
