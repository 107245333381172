import styled from 'styled-components';

export const SMergedInputs = styled.div`
  display: flex;

  input,
  .merged-input {
    border-radius: 6px 0 0 6px;
    vertical-align: top;
  }

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 6px 6px 0;
    height: 25px;
  }
`;
