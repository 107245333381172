import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  min-width: 350px;
  padding: 0 0 0 20px;

  .MuiTabs-root {
    margin: 0;

    .MuiTabs-flexContainer {
      justify-content: center;
    }
  }
`;

export const STabContent = styled.div`
  padding: 12px 12px 0;
`;
