export const tabOptions = [
  { id: 1, title: 'All' },
  { id: 2, title: 'Staff Groups', collapsable: true, collapsed: false },
  { id: 3, title: 'Staff', collapsable: true, collapsed: false },
  { id: 4, title: 'Drivers', collapsable: true, collapsed: false },
];

export const getChannelFilters = (data) => {
  const { tab, statusTab, chatUser, dot_num, search, filterOption } = data;

  // All
  const allOpenChannels = {
    members: { $in: [`${chatUser.id}`] },
    open_chat: true,
    organization_id: dot_num,
    name: { $autocomplete: search || undefined },
  };
  const allClosedChannels = {
    members: { $in: [`${chatUser.id}`] },
    open_chat: false,
    organization_id: dot_num,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
    name: { $autocomplete: search || undefined },
  };

  // Staff Groups
  const staffOpenGroupChannels = {
    type: 'truckindigital',
    members: { $in: [`${chatUser.id}`] },
    cid: filterOption?.length ? { $in: filterOption } : undefined,
    open_chat: true,
    organization_id: dot_num,
    name: { $autocomplete: search || undefined },
    is_group: 1,
  };
  const staffClosedGroupChannels = {
    type: 'truckindigital',
    members: { $in: [`${chatUser.id}`] },
    cid: filterOption?.length ? { $in: filterOption } : undefined,
    open_chat: false,
    organization_id: dot_num,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
    name: { $autocomplete: search || undefined },
    is_group: 1,
  };

  // Staff direct
  const staffOpenChannels = {
    type: 'messaging',
    members: { $in: [`${chatUser.id}`] },
    open_chat: true,
    organization_id: dot_num,
    name: { $autocomplete: search || undefined },
  };
  const staffClosedChannels = {
    type: 'messaging',
    members: { $in: [`${chatUser.id}`] },
    open_chat: false,
    organization_id: dot_num,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
    name: { $autocomplete: search || undefined },
  };

  // Driver direct
  const driverOpenChannels = {
    type: 'driver_channel',
    members: { $in: [`${chatUser.id}`] },
    open_chat: true,
    organization_id: dot_num,
    name: { $autocomplete: search || undefined },
  };
  const driverClosedChannels = {
    type: 'driver_channel',
    members: { $in: [`${chatUser.id}`] },
    open_chat: false,
    organization_id: dot_num,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
    name: { $autocomplete: search || undefined },
  };

  switch (tab.id) {
    case 1:
      return statusTab === 2 ? allClosedChannels : allOpenChannels;
    case 2:
      return statusTab === 2 ? staffClosedGroupChannels : staffOpenGroupChannels;
    case 3:
      return statusTab === 2 ? staffClosedChannels : staffOpenChannels;
    case 4:
      return statusTab === 2 ? driverClosedChannels : driverOpenChannels;
    default:
      return statusTab === 2 ? allClosedChannels : allOpenChannels;
  }
};

export const getAllChannelFilters = (data) => {
  const { tab, chatUser, dot_num } = data;

  // All
  const allOpenChannels = {
    members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
  };

  // Staff Groups
  const staffOpenGroupChannels = {
    type: 'truckindigital',
    members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
    is_group: 1,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
  };

  // Staff direct
  const staffOpenChannels = {
    type: 'messaging',
    members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
  };

  // Driver direct
  const driverOpenChannels = {
    type: 'driver_channel',
    members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
    last_message_at: { $gt: '2020-01-15T09:30:20.45Z' },
  };

  switch (tab.id) {
    case 1:
      return allOpenChannels;
    case 2:
      return staffOpenGroupChannels;
    case 3:
      return staffOpenChannels;
    case 4:
      return driverOpenChannels;
    default:
      return allOpenChannels;
  }
};
