import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { getEquipmentLogs } from 'Api/EquipmentProfile';
import EquipmentLogsTable from './EquipmentLogsTable';

const EquipmentLogs = ({ open, onClose, profileData }) => {
  const { equipment_id, equipment_type, id } = profileData;
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [page, setPage] = useState(1);

  const getLogs = async () => {
    try {
      const tableData = await getEquipmentLogs({
        page,
        itemsPerPage: rowPerPage,
        equipment_id: id,
        'sort[][created_at]': 'desc',
      });
      setLoading(false);
      setLogs(tableData);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getLogs();
  }, [rowPerPage, page]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <>
          Equipment Logs for {equipment_id} {equipment_type?.title}
        </>
      }
      $bgColor={palette.gray0}
      $minWidth='1200px'
      $maxWidth='1400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      {loading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <CircularProgress size={30} />
        </div>
      ) : (
        <EquipmentLogsTable
          logs={logs}
          rowPerPage={rowPerPage}
          setRowPerPage={setRowPerPage}
          page={page}
          setPage={setPage}
        />
      )}
    </Modal>
  );
};

export default EquipmentLogs;
