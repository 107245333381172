import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import { getDOTRecordable } from 'Api/AccidentsAndCrashes';
import { Typography } from 'components/Typography';
import DoughnutChart from '../../../../Dashboard/components/Charts/DoughnutChart';
import { SBoxWrapper } from '../../StatsSection.styles';

const labels = {
  0: 'Non-Recordable',
  1: 'Fatal/Injury/Tow',
};

export const DOTRecordable = ({ statsRefreshIndex }) => {
  const [dotRecordable, setDotRecordable] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCounts = async () => {
    try {
      setLoading(true);
      const params = {};
      const { data } = await getDOTRecordable(params);
      setDotRecordable(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasets = [
    {
      data: [
        dotRecordable?.dot_recordable?.['Non-Recordable']?.total_count,
        dotRecordable?.dot_recordable?.['Fatal/Injury/Tow']?.total_count,
      ],
      backgroundColor: [palette.green400, palette.red400],
      borderColor: [palette.green400, palette.red400],
      hoverBackgroundColor: [palette.green500, palette.red500],
      hoverBorderColor: [palette.green500, palette.red500],
      borderWidth: 1,
    },
  ];

  useEffect(() => {
    getCounts();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={dotRecordable?.total_accidents}
        header={
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s2'>NON RECORDABLE VS RECORDABLE</Typography>
            <Typography variant='c3' style={{ color: palette.gray700 }}>
              NOT REPORTED OR FATAL/INJURY/TOW
            </Typography>
          </div>
        }
      />
    </SBoxWrapper>
  );
};
