import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const SFlexRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: flex-end;
`;
