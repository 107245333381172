export const DEFAULT_WIDTH_OF_COLUMNS = {
  shipment_id: 150,
  customer: 150,
  assigned_to: 150,
  equipment: 150,
  planned: 75,
  stops: 100,
  status: 130,
  origin_stop_name: 150,
  destination_stop_name: 150,
  total_eta: 120,
  groups: 80,
  next_equipment_action: 100,
  amount: 100,
  created_by: 150,
  weight: 172,
};

export const getPlannerStatusParamsInStatus = (scheduledDate, status, pendingDispatch, canceled_date, type) => {
  let bgStatus;
  let colorStatus;
  let statusName;
  if (pendingDispatch) {
    bgStatus = '#F0F2F7';
    colorStatus = '#5A6376';
    statusName = `Pending Dispatch`;
  } else if (canceled_date) {
    bgStatus = '#FAF0F3';
    colorStatus = '#D12953';
    statusName = 'Canceled';
  } else {
    if (status === 'Offered') {
      bgStatus = '#F0F2F7';
      colorStatus = '#5A6376';
      statusName = `Offered`;
    } else if (type === 'split-parent' || type === 'slave') {
      bgStatus = '#FFFAEB';
      colorStatus = '#B48909';
      statusName = 'IN PROGRESS';
    } else if (status >= 24) {
      const daysCount = Math.floor(status / 24);
      bgStatus = '#EBF0FA';
      colorStatus = '#2264E6';
      statusName = `+${daysCount} DAYS SCHEDULED`;
    } else if (status >= 4 && status < 24) {
      bgStatus = '#EBF0FA';
      colorStatus = '#2264E6';
      statusName = 'SCHEDULED WITHIN 24H';
    } else if (status >= 0 && status < 4) {
      bgStatus = '#FFFAEB';
      colorStatus = '#B48909';
      statusName = 'SCHEDULED WITHIN 24H';
    } else if (status < 0) {
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      statusName = 'BEHIND SCHEDULE';
    }
  }

  return { bgStatus, colorStatus, statusName };
};
