import React, { useEffect, useState } from 'react';
import Divider from 'common/Divider';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getInsurancePolicies, updateInsurancePolicies } from 'Api/Insurance';
import { validationSchema } from 'pages/CompanySettings/pagesComponents/StaffDriver/Insurance/validationSchema';
import Loader from 'common/Loader';
import InsuranceFields from '../components/InsuranceFields';
import { getInitialValues, insuranceFields, updateInsuranceConverter } from './Insurance.data';
import { SWrapper } from '../StaffDriver.styles';

const Insurance = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      const body = updateInsuranceConverter(values);
      await updateInsurancePolicies(body);

      showToaster({ type: 'success', message: 'Successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const getSettings = async () => {
    try {
      const { data } = await getInsurancePolicies();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Insurance'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={form.handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      <div>{loading ? <Loader loading={loading} /> : <InsuranceFields field={insuranceFields} form={form} />}</div>
    </SWrapper>
  );
};

export default Insurance;
