import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { assignUser, removeConnectService, removeConnectServiceStaff } from 'Api/CardManagement';
import { SCustomModal } from './RemoveCard.styles';

const RemoveCard = ({ open, onClose, onSuccess, data }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const removeConnectedServices = async () => {
    try {
      const body = {
        driver_id: data.user_type === 'driver' ? data.user.id : undefined,
        staff_id: data.user_type === 'staff' ? data.user.id : undefined,
        connected_service_id: 44,
      };

      if (data.user_type === 'driver') {
        await removeConnectService(body);
      } else {
        await removeConnectServiceStaff(body);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onRemoveUser = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');

      await assignUser(formData);
      await removeConnectedServices();
      showToaster({ type: 'success', message: 'User has been successfully removed' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Remove User'
      $maxWidth='382px'
      $minWidth='382px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'danger',
          title: 'Remove User',
          disabled: loading,
          onClick: onRemoveUser,
        },
      ]}
    >
      <Typography variant='b2'>
        You are removing {data.user.first_name} {data.user.last_name} ({data.user_type}) from the access of card ending{' '}
        {data.card_number.slice(-4)}. You can reassign to someone else anytime.
      </Typography>
    </SCustomModal>
  );
};

export default RemoveCard;
