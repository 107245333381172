import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { endOfDay, startOfYear } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { formatNumber, palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getCustomersList } from 'Api/Customers';
import { getCustomerTransactions } from 'Api/Reports';
import { GetCompanyProfile, GetSettingsAccounting } from 'Api/CompanySettings';
import ColumnHeader from 'common/ColumnHeader';
import { generatePDF } from './generatePdf';
import {
  statementTypes,
  getOverdueAmount,
  getTotalBalanceDue,
  filterTransactions,
  convertTransactionsData,
} from './CustomerStatement.data';
import { STable } from '../ReportDetails.styles';
import { SBackButton, SLink, SLoadingWrapper } from '../shared/PageWrapper/PageWrapper.styles';
import { SBody, SDetailsWrapper, SFiltersWrapper } from './CustomerStatement.styles';

const CustomerStatement = ({ isModalView, customer }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [accountingSettings, setAccountingSettings] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(customer || null);
  const [customerTransactions, setCustomerTransactions] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [statementType, setStatementType] = useState({ name: 'All', value: 'activity' });
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [sort, setSort] = useState({ field: 'age', sortBy: 'desc' });
  const [dateRange, setDateRange] = useState({
    start: isModalView ? undefined : startOfYear(new Date()),
    end: isModalView ? undefined : endOfDay(new Date()),
  });

  const sortedTransactions = useMemo(() => {
    return filterTransactions(customerTransactions, sort);
  }, [customerTransactions, sort]);

  const didMountRef = useRef(false);

  const getCompanyProfile = async () => {
    try {
      const company = await GetCompanyProfile();
      setCompanyProfile(company);
    } catch (e) {
      // Do nothing
    }
  };

  const getAccountingSettings = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountingSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList({ page: 1, page_size: 10000 });
      setCustomers(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getTransactions = async () => {
    setLoadingTransactions(true);
    try {
      const params = {
        itemsPerPage: 10000,
        start_date: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.start ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        filter: statementType.value,
      };
      setSelectedFilters({ ...params, customer: selectedCustomer });
      const { data } = await getCustomerTransactions(selectedCustomer.id, params);
      setCustomerTransactions(convertTransactionsData(data));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingTransactions(false);
    }
  };

  const onPdfClick = () => {
    const { url } = generatePDF({
      selectedFilters,
      companyProfile,
      accountingSettings,
      sortedTransactions,
      currency,
      formatDate,
    });
    setPdfUrl(url);
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    if (!isModalView) {
      if (!didMountRef.current) {
        setLoading(true);
      }
      getCustomers();
      didMountRef.current = true;
    } else {
      getTransactions();
    }
    getCompanyProfile();
    getAccountingSettings();
  }, []);

  return (
    <div className='mb-4'>
      {!isModalView && (
        <SBackButton>
          <ArrowLeft width={10} height={8} />
          <SLink to='/reports'>Reports</SLink>
        </SBackButton>
      )}
      {loading ? (
        <SLoadingWrapper>
          <CircularProgress size={26} />
        </SLoadingWrapper>
      ) : (
        <SBody>
          <div className='d-flex align-items-center gap-3'>
            <Typography variant='h1'>Statement for {customer?.company_name}</Typography>
            {!isModalView && (
              <div>
                <Autocomplete
                  name='send_to'
                  width='250px'
                  labelKey='company_name'
                  value={selectedCustomer}
                  onChange={(e, value) => setSelectedCustomer(value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={customers}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.company_name}
                    </li>
                  )}
                />
              </div>
            )}
          </div>
          <SFiltersWrapper>
            <Typography variant='s2'>Statement Type:</Typography>
            <div>
              <Autocomplete
                name='statement_type'
                width='200px'
                value={statementType}
                onChange={(e, value) => setStatementType(value)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={statementTypes}
              />
            </div>
            <Typography variant='s2'>Statement Period:</Typography>
            <DateRangePicker
              initialRangeName={isModalView ? 'All Time' : 'This Year'}
              dateRange={dateRange}
              setDateRange={setDateRange}
              type='allTime'
            />
            <CustomButton title='Update' onClick={getTransactions} disabled={!selectedCustomer} />
          </SFiltersWrapper>
          {loadingTransactions ? (
            <SLoadingWrapper>
              <CircularProgress size={26} />
            </SLoadingWrapper>
          ) : (
            customerTransactions && (
              <SDetailsWrapper>
                <div className='details-header'>
                  <Typography variant='button2'>Statement Details</Typography>
                  <CustomButton
                    className='action-button'
                    title='Print PDF'
                    type='secondary'
                    onClick={() => onPdfClick()}
                    disabled={!customerTransactions.length}
                  />
                </div>
                <div className='details-body'>
                  <div className='d-flex flex-column gap-1'>
                    <Typography variant='button2'>Customer</Typography>
                    <Typography variant='s2'>{selectedFilters.customer.company_name}</Typography>
                    <Typography variant='s2'>
                      {selectedFilters.customer.address1}
                      {selectedFilters.customer.address2 && `, ${selectedFilters.customer.address2}`}
                    </Typography>
                    <Typography variant='s2'>
                      {`${selectedFilters.customer.city?.name}, ${selectedFilters.customer.state?.name}, ${selectedFilters.customer.zipcode}`}
                    </Typography>
                    <Typography variant='s2'>{selectedFilters.customer.country?.name}</Typography>
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <Typography variant='button2'>Activity Type</Typography>
                    <Typography variant='s2'>
                      {selectedFilters.filter === 'outstanding' ? 'Outstanding' : 'All'}
                    </Typography>
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <Typography variant='button2'>From Date</Typography>
                    <Typography variant='s2'>
                      {selectedFilters.start_date ? formatDate(selectedFilters.start_date) : '-'}
                    </Typography>
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <Typography variant='button2'>To Date</Typography>
                    <Typography variant='s2'>
                      {selectedFilters.start_date ? formatDate(selectedFilters.end_date) : 'All Time'}
                    </Typography>
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <Typography variant='button2'>Overdue</Typography>
                    <Typography variant='s2'>
                      {currency}
                      {formatNumber(getOverdueAmount(sortedTransactions))}
                    </Typography>
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <Typography variant='button2'>Current</Typography>
                    <Typography variant='s2'>
                      {currency}
                      {formatNumber(getTotalBalanceDue(sortedTransactions) - getOverdueAmount(sortedTransactions))}
                    </Typography>
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <Typography variant='button2'>Balance Due</Typography>
                    <Typography variant='s2'>
                      {currency}
                      {formatNumber(getTotalBalanceDue(sortedTransactions))}
                    </Typography>
                  </div>
                </div>
                <Divider color={palette.gray200} margin='32px 0 64px' />
                <STable>
                  <thead>
                    <tr className='header-row'>
                      <th>
                        <ColumnHeader
                          title='INVOICE DATE'
                          field='customer_billed_date'
                          onClick={sortingQuery}
                          sort={sort}
                        />
                      </th>
                      <th>
                        <ColumnHeader title='PAYMENT DATE' field='paymentDate' onClick={sortingQuery} sort={sort} />
                      </th>
                      <th>
                        <ColumnHeader title='INVOICE #' field='invoice_id' onClick={sortingQuery} sort={sort} />
                      </th>
                      <th>
                        <ColumnHeader title='REFERENCE' field='reference_id' onClick={sortingQuery} sort={sort} />
                      </th>
                      <th>
                        <ColumnHeader title='AGING' field='age' onClick={sortingQuery} sort={sort} />
                      </th>
                      <th>
                        <ColumnHeader title='DUE DATE' field='due_date' onClick={sortingQuery} sort={sort} />
                      </th>
                      <th>
                        <ColumnHeader title='INVOICE AMOUNT' field='amount' onClick={sortingQuery} sort={sort} />
                      </th>
                      <th>
                        <ColumnHeader title='PAYMENTS' field='totalPaymentsAmount' onClick={sortingQuery} sort={sort} />
                      </th>
                      <th>
                        <ColumnHeader title='OPEN BALANCE' field='balance' onClick={sortingQuery} sort={sort} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!sortedTransactions.length &&
                      sortedTransactions.map((transaction) => {
                        return (
                          <tr key={transaction.invoice_id} className='body-row'>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {transaction.customer_billed_date ? formatDate(transaction.customer_billed_date) : '-'}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {transaction.paymentDate ? formatDate(transaction.paymentDate) : '-'}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {transaction.invoice_id}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {transaction.billing?.reference_id || transaction.reference_id || '-'}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {transaction.age || '-'}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {formatDate(transaction.due_date)}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {formatNumber(transaction.amount)}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {formatNumber(transaction.totalPaymentsAmount)}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='b2' style={{ color: palette.gray700 }}>
                                {formatNumber(transaction.balance)}
                              </Typography>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </STable>
                {!loadingTransactions && selectedFilters && !sortedTransactions?.length && (
                  <NoRecords className='no-records' />
                )}
              </SDetailsWrapper>
            )
          )}
        </SBody>
      )}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Customer Statement' />
      )}
    </div>
  );
};

export default CustomerStatement;
