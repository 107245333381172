import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import { formatNumber, palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import Label from 'pages/Payroll/shared/Label';
import PayPeriod from 'pages/Payroll/shared/PayPeriod';
import useDateFormat from 'hooks/useDateFormat';
import PayType from '../PayType';
import PayRate from '../PayRate';
import { getCompanyName } from '../helpers';
import { SPickerWrapper } from '../../../Tables/Tables.styles';
import { SInfoItem, SMiles, SRow, SWrapper } from '../UserInfo.styles';

const DriverInfo = ({ data, setData, totalAdditions, totalDeductions, totalSettlement }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [payDateEdit, setPayDateEdit] = useState(false);
  const {
    user,
    pay_period_start,
    pay_period_end,
    shipments,
    gross,
    loaded_miles,
    live_loaded_miles,
    empty_miles,
    live_empty_miles,
    total_miles,
    ytd_cost,
    ytd_shipments_count,
    set_payment_date,
  } = data || {};

  const onPayDateChange = (date) => {
    setData({ ...data, set_payment_date: moment(date).format('MM/DD/YYYY') });
    setPayDateEdit(false);
  };

  return (
    <SWrapper>
      <SRow>
        <SInfoItem>
          <Label>Pay Period</Label>
          <PayPeriod start={pay_period_start} end={pay_period_end} />
        </SInfoItem>
        <SInfoItem onMouseEnter={() => setPayDateEdit(true)} onMouseLeave={() => setPayDateEdit(false)}>
          <Label>Set Pay Date</Label>
          {payDateEdit ? (
            <SPickerWrapper style={{ width: '120px' }}>
              <DatePicker
                showYearDropdown
                selected={moment(set_payment_date).toDate() || new Date()}
                placeholderText='mm/dd/yyyy'
                dateFormat='MM/dd/yyyy'
                minDate={new Date()}
                onChange={onPayDateChange}
              />
            </SPickerWrapper>
          ) : (
            <Chip style={{ width: '120px' }} label={formatDate(set_payment_date)} />
          )}
        </SInfoItem>
        <SInfoItem>
          <Label>Type</Label>
          <Typography variant='s2'>{getCompanyName(data)}</Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Pay Type</Label>
          <PayType data={user?.compensation_details} />
        </SInfoItem>
        {user?.compensation_details?.pay_option === 'mile' ? (
          <SInfoItem>
            <Label>Current - Miles</Label>
            <SMiles>
              {(!!live_loaded_miles || live_loaded_miles === 0) && (
                <Chip label={`${Math.ceil(live_loaded_miles)} Loaded`} />
              )}
              {(!!live_empty_miles || live_empty_miles === 0) && (
                <Chip label={`${Math.ceil(live_empty_miles)} Empty`} />
              )}
            </SMiles>
          </SInfoItem>
        ) : (
          <SInfoItem>
            <Label>Rate</Label>
            <PayRate data={user?.compensation_details} />
          </SInfoItem>
        )}
        <SInfoItem>
          <Label>Current - Shipments</Label>
          <Typography variant='s2'>{shipments?.length || 0}</Typography>
        </SInfoItem>
      </SRow>
      <Divider margin='16px 0' />
      <SRow>
        <SInfoItem>
          <Label>YTD - Shipments</Label>
          <Typography variant='s2'>{ytd_shipments_count + (shipments?.length || 0)}</Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>YTD - Earnings</Label>
          <Typography variant='s2'>
            {currency}
            {formatNumber(Number(ytd_cost) + Number(totalSettlement))}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>YTD - Miles Driven</Label>
          <Typography variant='s2'>
            {Math.ceil(total_miles) + Math.ceil(loaded_miles || 0) + Math.ceil(empty_miles || 0) || '0'}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Current - Gross Revenue</Label>
          <Typography variant='s2'>
            {currency}
            {formatNumber(gross)}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Current - Deductions</Label>
          <Typography variant='s2' style={{ color: use(palette.red500, palette.red800) }}>
            - {currency}
            {formatNumber(totalDeductions)}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Current - Additions</Label>
          <Typography variant='s2' style={{ color: use(palette.green500, palette.green400) }}>
            + {currency}
            {formatNumber(totalAdditions)}
          </Typography>
        </SInfoItem>
        <SInfoItem>
          <Label>Total Settlement</Label>
          <Typography variant='s2' style={{ color: use(palette.indigo500, palette.indigo500) }}>
            {totalSettlement < 0 && '- '}
            {currency}
            {formatNumber(Math.abs(totalSettlement))}
          </Typography>
        </SInfoItem>
      </SRow>
    </SWrapper>
  );
};

export default DriverInfo;
