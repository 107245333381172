import React from 'react';
import styles from './SupportSearch.module.css';
import searchIcon from '../../assets/icons/header/Search.png';

const SupportSearch = ({ search, onChange }) => {
  return (
    <div className={styles.filtersWrapper}>
      <div className={styles['header-search']}>
        <div className='header-search-container d-flex'>
          <div>
            <input
              className={styles.searchInput}
              type='text'
              value={search}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Search'
            />
            <div className={styles['input-group-addon-search']}>
              <img src={searchIcon} alt='search' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SupportSearch;
