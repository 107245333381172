import React, { useRef, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-filled.svg';
import Input from 'common/Input';
import { palette } from 'utils/constants';
import { updateDeviceName } from 'Api/Billing';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { Typography } from 'components/Typography';
import { SWrapper, SInputWrapper } from './DeviceNickname.styles';

const DeviceNickname = ({ rowData, onSuccess, showParentheses }) => {
  const { use } = useTheme();
  const ref = useRef(null);
  const showToaster = useShowToaster();
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(rowData?.nickname || rowData?.hardware || '');
  const [isError, setIsError] = useState(false);

  const updateNickname = async () => {
    if (value === rowData.nickname) {
      setIsEdit(false);
      return;
    }

    if (value === '') {
      setIsError(true);
      return;
    }

    try {
      const { data } = await updateDeviceName({
        identifier: rowData.identifier,
        nickname: value,
      });
      setIsEdit(false);
      onSuccess(data);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const handleChange = (e) => {
    setIsError(false);
    setValue(e.target.value);
  };

  const onEditClick = () => {
    setIsEdit(true);
    setTimeout(() => {
      ref.current.focus();
    });
  };

  return (
    <SWrapper>
      {isEdit ? (
        <SInputWrapper>
          <Input value={value} onChange={handleChange} error={isError && 'Required'} ref={ref} />
          <CheckIcon width={18} height={18} onClick={updateNickname} />
        </SInputWrapper>
      ) : (
        <SInputWrapper>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {showParentheses && '('}
            {rowData?.nickname ? rowData?.nickname : rowData?.hardware}
            {showParentheses && ')'}
          </Typography>
          <EditIcon width={16} height={16} onClick={onEditClick} />
        </SInputWrapper>
      )}
    </SWrapper>
  );
};

export default DeviceNickname;
