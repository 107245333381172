import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { ReactComponent as RefundIcon } from 'assets/icons/createShipment/subRight.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { formatNumber, palette } from 'utils/constants';
import { getTransactionDetails } from 'Api/Accounts';

import Refund from './components/Refund';
import Overview from './components/Overview';
import PaymentMethod from './components/PaymentMethod';
import PaymentDetails from './components/PaymentDetails';
import { SBodyWrapper } from './TransactionDetails.styles';

const TransactionDetails = ({ open, onClose, transactionData, account, onRefundSuccess }) => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openRefundConfirm, setOpenRefundConfirm] = useState(false);
  const { refund } = transactionData || {};
  const { payment_intent } = transaction?.transaction_details || {};
  const { amount, status } = payment_intent || {};

  const getDetails = async () => {
    try {
      setLoading(true);
      const body = {
        account_id: account.id,
        payment_id: transactionData.group_id,
        stripe_ac_id: account.stripe_account_id,
      };

      const { data } = await getTransactionDetails(body);
      setTransaction(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Transaction Details'
      $bgColor={palette.white}
      $width='95vw'
      $maxWidth='1500px'
      $minWidth='1100px'
      $minHeight='78vh'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
        },
      ]}
    >
      {loading || !transaction ? (
        <Loader loading={loading} />
      ) : (
        <SBodyWrapper>
          <div className='d-flex justify-content-between'>
            <Typography variant='h1'>{refund === 'refund_self' ? 'Refund' : 'Deposit'}</Typography>
            {!['refund_self', 'pending', 'succeeded'].includes(refund) && (
              <CustomButton
                type='secondary'
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px', margin: 0 }}
                title='Refund'
                leftIcon={<RefundIcon fill={palette.gray700} width={14} className='refund-icon me-2' />}
                onClick={() => setOpenRefundConfirm(true)}
              />
            )}
          </div>
          <div className='d-flex align-items-center gap-2 mt-2'>
            <Typography variant='h2'>${formatNumber(amount / 100)}</Typography>
            {refund !== 'refund_self' && (
              <Chip label={capitalize(status)} labelColor={palette.green500} bgColor={palette.green0} />
            )}
          </div>
          <Overview transaction={transaction} transactionData={transactionData} />
          <PaymentDetails transaction={transaction} />
          <PaymentMethod transaction={transaction} />
        </SBodyWrapper>
      )}
      {openRefundConfirm && (
        <Refund
          open={openRefundConfirm}
          onClose={() => setOpenRefundConfirm(false)}
          transaction={transaction}
          onRefundSuccess={() => {
            onRefundSuccess();
            onClose();
          }}
        />
      )}
    </Modal>
  );
};

export default TransactionDetails;
