import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Tooltip from 'common/Tooltip';
import { SimpleLoader } from 'common/Loader';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import ConfirmDispatch from './ConfirmDispatch';

const RowActions = ({ bid, shipment, onRevertOffer, onAwardBid, onDeclineBid, onRevertAward, loading }) => {
  const navigate = useNavigate();
  const [openConfirmDispatch, setOpenConfirmDispatch] = useState(false);
  const { offer_status } = shipment || {};
  const {
    shipment_id,
    selected_contact_user,
    driver,
    carrier,
    selected_contact_user_id,
    offer_accepted,
    bid_amount,
    posted_amount,
    state,
    city,
  } = bid || {};

  const onDispatch = (confirmed) => {
    if (carrier?.status_id === 1 && !confirmed) {
      setOpenConfirmDispatch(true);
      return;
    }

    if (selected_contact_user && offer_accepted === 4) {
      navigate(
        `/planner/carrier-dispatch?id=${shipment_id}&carrier=${carrier.id}&contact=${selected_contact_user_id}&amount=${bid_amount}&state=${state.id}&city=${city.id}`
      );
    }
    if (driver && offer_accepted === 1 && !!driver.equipment?.length) {
      navigate(`/planner/dispatch?id=${shipment_id}&equipment=${driver.equipment[0].id}&amount=${posted_amount || ''}`);
    }
  };

  const goToCarrierProfile = () => {
    navigate(`/carrier-profile/${bid.carrier.id}`);
  };

  return (
    <div className='d-flex gap-3'>
      {(!selected_contact_user || !!driver) && (
        <div className='d-flex align-items-center gap-3'>
          <SimpleLoader loading color={palette.indigo500} size={18} />
          <Tooltip title='Revert Offer'>
            <span>
              <CustomButton
                type='secondary'
                styleButton={{ padding: '6px 12px', margin: 0 }}
                title={
                  <div className='d-flex'>
                    <RevertIcon width={14} height={14} />
                  </div>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  onRevertOffer(bid);
                }}
                disabled={loading}
              />
            </span>
          </Tooltip>
        </div>
      )}
      {!!selected_contact_user && offer_accepted === 1 && (
        <div className='d-flex align-items-center gap-2'>
          {offer_status !== 'awarded' && (
            <Tooltip title='Award'>
              <span>
                <CustomButton
                  type='primary'
                  styleButton={{ padding: '6px 12px', margin: 0 }}
                  title={
                    <div className='d-flex'>
                      <CheckIcon fill={palette.white} width={14} height={14} />
                    </div>
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    onAwardBid(bid);
                  }}
                  className='award-button'
                  disabled={loading}
                />
              </span>
            </Tooltip>
          )}
          <Tooltip title='Decline Bid'>
            <span>
              <CustomButton
                styleButton={{ padding: '6px 12px', margin: 0 }}
                title={
                  <div className='d-flex'>
                    <CloseIcon fill={palette.white} width={14} height={14} />
                  </div>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  onDeclineBid(bid);
                }}
                className='decline-button'
                disabled={loading}
              />
            </span>
          </Tooltip>
        </div>
      )}
      {!!selected_contact_user && offer_accepted === 3 && (
        <div className='d-flex align-items-center gap-3'>
          <SimpleLoader loading color={palette.indigo500} size={18} />
          <Tooltip title='Revert Award'>
            <span>
              <CustomButton
                type='secondary'
                styleButton={{ padding: '6px 12px', margin: 0 }}
                title={
                  <div className='d-flex'>
                    <RevertIcon width={14} height={14} />
                  </div>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  onRevertAward(bid);
                }}
                disabled={loading}
              />
            </span>
          </Tooltip>
        </div>
      )}
      {((!!selected_contact_user && offer_accepted === 4) || (!!driver && offer_accepted === 1)) && (
        <Tooltip title='Dispatch'>
          <span>
            <CustomButton
              type='primary'
              styleButton={{ padding: '6px 12px', margin: 0 }}
              title={
                <div className='d-flex'>
                  <ArrowRight fill={palette.white} width={14} height={14} />
                </div>
              }
              onClick={(e) => {
                e.stopPropagation();
                onDispatch();
              }}
              disabled={loading || (!!driver && !driver.equipment?.length)}
            />
          </span>
        </Tooltip>
      )}
      {openConfirmDispatch && (
        <ConfirmDispatch
          open={openConfirmDispatch}
          onClose={() => setOpenConfirmDispatch(false)}
          onConfirm={() => onDispatch(true)}
          onNavigate={goToCarrierProfile}
        />
      )}
    </div>
  );
};

export default RowActions;
