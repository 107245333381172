import React, { useEffect, useState } from 'react';
import moment from 'moment';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import upload from 'assets/icons/drivers/upload.png';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import ImageDropZone from 'components/ImgageDropZone';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { uploadDriverDocument } from 'Api/Staff';
import { getStaffBasic, updateStaffBasic } from 'Api/User';
import { validationSchema } from './validationSchema';
import { typeOptions } from './PhotoIdentificationStep.data';
import { SUploaderWrapper } from './PhotoIdentificationStep.styles';

const PhotoIdentificationStep = ({ value, nextStep, sendOtp, handleSubmission }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [cropModalShow, setCropModalShow] = useState();
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState();

  const onSubmit = async (values) => {
    try {
      const body = {
        photo_identification: values.photo_identification || null,
        photo_identification_expiration: values.photo_identification_expiration
          ? moment(values.photo_identification_expiration).format('YYYY-MM-DD')
          : null,
        photo_identification_type: values.photo_identification_type?.id || undefined,
        upload_identification_card: values.upload_identification_card?.startsWith('https')
          ? undefined
          : values.upload_identification_card || undefined,
      };
      await updateStaffBasic(value.user.id, body);
      if (value.user?.customer?.send_otp_to_user) {
        sendOtp();
        nextStep();
      } else {
        handleSubmission({ preventDefault: () => null }, true);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleBlur, handleChange, touchedErrors, setValues } = useForm({
    initialValues: {
      photo_identification: '',
      photo_identification_expiration: new Date(),
      photo_identification_type: null,
      upload_identification_card: '',
    },
    validationSchema,
    onSubmit,
  });

  const getBasicDetails = async () => {
    try {
      const { data } = await getStaffBasic(value.user?.id);
      setValues({
        photo_identification: data?.photo_identification || '',
        photo_identification_expiration: data?.photo_identification_expiration
          ? new Date(data.photo_identification_expiration)
          : new Date(),
        photo_identification_type: data?.photo_identification_type
          ? typeOptions.find((i) => i.id === Number(data.photo_identification_type))
          : null,
        upload_identification_card: data?.upload_identification_card || '',
      });
    } catch (e) {
      // Do nothing
    }
  };

  const uploadLicenseDocument = (file) => {
    uploadDriverDocument(file[0], value?.user?.id).then((res) => {
      handleChange('upload_identification_card', res.data.document_path);
    });
  };

  const imageSelected = (file) => {
    setCropModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadLicenseDocument([blob]);
        setCropModalShow(false);
      });
    }
  };

  useEffect(() => {
    getBasicDetails();
  }, []);

  return (
    <>
      <div className='modal-header-custom'>
        <h1 className='welcome-bg'>Photo Identification</h1>
      </div>
      <div className='modal-body-custom'>
        <div className='w-100 d-flex flex-column gap-4 ps-3 pe-3 mb-4' style={{ textAlign: 'left' }}>
          <div className='d-flex align-items-center gap-4'>
            <Input
              required
              label='Photo Identification #'
              name='photo_identification'
              className='w-50'
              placeholder='Photo Identification #'
              onChange={handleChange}
              value={values.photo_identification}
              error={touchedErrors.photo_identification}
            />
            <div className='w-50'>
              <DatePicker
                required
                label='Photo Identification Expiration'
                name='photo_identification_expiration'
                onBlur={handleBlur}
                value={values.photo_identification_expiration}
                onChange={(val) => handleChange('photo_identification_expiration', val)}
                error={touchedErrors.photo_identification_expiration}
              />
            </div>
          </div>
          <div className='d-flex align-items-center gap-4'>
            <div className='w-50'>
              <Autocomplete
                required
                label='Photo Identification Type'
                name='photo_identification_type'
                value={values.photo_identification_type}
                onChange={(e, value) => handleChange('photo_identification_type', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={typeOptions}
                error={touchedErrors?.photo_identification_type}
              />
            </div>
            <SUploaderWrapper>
              <InputLabel required>Upload Identification Card</InputLabel>
              {values?.upload_identification_card ? (
                <p className='attachment-wrapper' style={{ backgroundColor: '#f0f1fa' }}>
                  <img src={right} alt='attachment' className='attachment-icon' />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <img src={cross} alt='cross' onClick={() => handleChange('upload_identification_card', null)} />
                </p>
              ) : (
                <div
                  className='upload-selector'
                  style={{
                    position: 'relative',
                    backgroundColor: palette.white,
                    borderColor: palette.gray50,
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <label htmlFor='myFile' className='text-style'>
                    <span className='upload-icon'>
                      <img src={upload} alt='upload' />
                    </span>
                    <span
                      style={{
                        color: '#4F5AED',
                      }}
                    >
                      Upload Identification Card
                    </span>
                  </label>
                  <ImageDropZone
                    onDrop={(file) => imageSelected(file)}
                    width='100%'
                    height='100%'
                    name='small'
                    deletable={false}
                    customStyle={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    accept={['application/pdf', 'image/jpeg', 'image/png']}
                  />
                </div>
              )}
              <ErrorMessage error={touchedErrors.upload_identification_card} />
            </SUploaderWrapper>
          </div>
        </div>
      </div>
      <div className='modal-footer-custom'>
        <button className='button-footer btn' onClick={handleSubmit} disabled={loading}>
          Continue
        </button>
      </div>
      {!!cropModalShow && (
        <ImageCropModal
          show={cropModalShow}
          onHide={() => setCropModalShow(false)}
          image={image}
          getCropData={getCropData}
          setCropper={setCropper}
        />
      )}
    </>
  );
};

export default PhotoIdentificationStep;
