import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { TableContext } from 'context/tableContext';
import { useTheme } from 'context/themeContext';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import PopoverSettings from '../../PopoverSettings/PopoverSettings';
import DropDownPlaner from '../../DropDownPlaner';
import FilterPlaner from '../../FilterPlaner';
import DarkOverlay from '../../DarkOverlay';
import TableHeaderFilterByShow from './TableHeaderFilterByShow';
import { EDI_API_STATUS, STATUS_FILTER_DATA_PENDING } from '../../CreateShipment/ShipmentStops/helpers/constants';
import useForm from '../../../hooks/useForm';
import { Typography } from '../../Typography';
import Input from '../../../common/Input';
import CustomButton from '../../CustomButton/CustomButton';
import Autocomplete from '../../../common/Autocomplete';
import CustomDatePicker from '../../CustomDatePicker';
import CreatedFilter from './CreatedFilter';

const PendingAssignmentFilter = () => {
  const { use } = useTheme();
  const [show, setShow] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  const [pickUpDateRange, setPickUpDateRange] = useState([null, null]);
  const [deliveryDateRange, setDeliveryDateRange] = useState([null, null]);

  const initialValues = {
    shipment_id: '',
    reference: '',
    status: [],
    group: [],
    stopPoint: [],
    customer: [],
    equipment_types: [],
    created_by: [],
    pickupDateRange: '',
    deliveryDateRange: '',

    origin_city: '',
    origin_state: '',
    origin_zipcode: '',
    origin_miles: 50,

    destination_city: '',
    destination_state: '',
    destination_zipCode: '',
    destination_miles: 50,

    ediApiStatus: [],
  };

  const initialStyle = useMemo(() => {
    return {
      date: {
        minWidth: 220,
        margin: 0,
        height: 32,
        transform: 'translateY(1px)',
        borderRadius: 4,
        justifyContent: 'flex-start',
        fontSize: 16,
      },
      inputLabel: {
        display: 'flex',
      },
      radius: {
        background: '#f7f9fc',
        height: '100%',
        whiteSpace: 'nowrap',
        fontSize: 14,
        color: '#687182',
        padding: '6px 6px',
        lineHeight: '20px',
        borderRadius: '0px 6px 6px 0px',
        boxShadow: '0 1px 2px rgb(0 0 0 / 6%), 0 0 0 1px rgb(104 113 130 / 16%)',
      },
      dateLabel: {
        marginTop: 0,
      },
    };
  }, [use, palette]);

  const {
    loading,
    triggerAdvancedClear,
    filter,
    dragItem,
    customers,
    getSettings,
    updateFilter,
    stopPoints,
    stopPointCondition,
    switchRadioButtons,
    multiSelectOptions,
    onsStTableColumn,
    onChangeOrder,
    onSetSwitchRadioButtons,
    dateRangeStopPoint,
    setDateRangeStopPoint,
    tableColumn,
    dateRangeLocation,
    setDateRangeLocation,
    /** Filter helpers* */
    onChangeAll,
    onChangeCustomers,
    onChangeLocationValues,
    onChangeLocationDatePicker,
    onChangeDatePickerStopPoint,
    onChangeStopPointSelect,
    onDeleteStopPointSelect,
    dateRangeCreatedBy,
    onChangeDatePickerCreatedBy,
    onDeleteDatePickerCreatedBy,
    /** table show data* */
    groups,
    onReset,
    statuses,
    setGroups,
    equipments,
    showHeaderFilters,
    setStatuses,
    setEquipments,
    disabledFilters,
    filterByGroupsData,
    onDeleteManyDataItem,
    localFilterCheckboxes,
    onDeleteStopPointItem,
    onDeleteLocationsItems,
    onApplyFilterCheckboxes,
    setLocalFilterCheckboxes,
    filterByEquipmentTypeData,
  } = useContext(TableContext);

  const handleBasicInfoSubmit = (values) => {
    onApplyFilterCheckboxes({
      checkboxes: {
        status: values.status,
        groups: values.group,
        equipment_types: values.equipment_types,
        customer: values.customer,
        stop_point: values.stopPoint,
        edi_api_status: values.ediApiStatus,
        shipment_id: values.shipment_id ? [{ label: values.shipment_id, value: values.shipment_id }] : undefined,
        reference_id: values.reference ? [{ label: values.reference, value: values.reference }] : undefined,
      },
      customers: values.customer.map((item) => item.id),
      status: values.status.map((item) => item.id),
      groups: values.group.map((item) => item.id),
      equipment_types: values.equipment_types.map((item) => item.id),
      stop_points: values.stopPoint.map((item) => item.id),
      edi_api_statuses: values.ediApiStatus.map((item) => item.id),
      shipment_id: values.shipment_id ? [values.shipment_id] : undefined,
      reference_id: values.reference ? [values.reference] : undefined,
      valuePickupDate: valuePickupDate || undefined,
      valueDeliveryDate: valueDeliveryDate || undefined,
      created_by: (values.created_by || []).map((i) => i.id),
      origin: {
        origin_city: values.origin_city,
        origin_state: values.origin_state,
        origin_zipcode: values.origin_zipcode,
        origin_miles: values.origin_miles,
      },
      destination: {
        destination_city: values.destination_city,
        destination_state: values.destination_state,
        destination_zipCode: values.destination_zipCode,
        destination_miles: values.destination_miles,
      },
    });
  };

  const form = useForm({
    initialValues,
    onSubmit: handleBasicInfoSubmit,
  });
  const { values, handleChange, resetForm } = form;

  const onChangePickUpRange = (dates) => {
    setPickUpDateRange(dates);
  };

  const onChangeDeliveryRange = (dates) => {
    setDeliveryDateRange(dates);
  };

  const clearSearchValues = () => {
    onReset({ searchBy: false });
    setPickUpDateRange([null, null]);
    setDeliveryDateRange([null, null]);
    resetForm();
  };

  useEffect(() => {
    if (triggerAdvancedClear) {
      setPickUpDateRange([null, null]);
      setDeliveryDateRange([null, null]);
      resetForm();
    }
  }, [triggerAdvancedClear]);

  const valuePickupDate = useMemo(() => {
    if (!pickUpDateRange[0]) return '';
    const fromValue = moment(pickUpDateRange[0]).format('MM/DD/YYYY');
    if (!pickUpDateRange[1]) return fromValue;
    const toValue = moment(pickUpDateRange[1]).format('MM/DD/YYYY');

    return `${fromValue} - ${toValue}`;
  }, [pickUpDateRange]);

  const valueDeliveryDate = useMemo(() => {
    const fromValue = deliveryDateRange[0] && moment(deliveryDateRange[0]).format('MM/DD/YYYY');
    const toValue = deliveryDateRange[1] && moment(deliveryDateRange[1]).format('MM/DD/YYYY');
    const isValidTo = !moment(toValue).isValid();

    if (fromValue && isValidTo) {
      return fromValue;
    }
    return fromValue && toValue ? `${fromValue} - ${toValue}` : '';
  }, [deliveryDateRange]);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettings
          filter={filter}
          dragItem={dragItem}
          typeDataJson='planner'
          tableColumn={tableColumn}
          getSettings={getSettings}
          updateFilter={updateFilter}
          onChangeOrder={onChangeOrder}
          onsStTableColumn={onsStTableColumn}
          switchRadioButtons={switchRadioButtons}
          onSetSwitchRadioButtons={onSetSwitchRadioButtons}
          setShowPopover={(isOpen) => setShow(isOpen)}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <OverlayTrigger
          delay={0}
          show={show}
          trigger='click'
          rootClose={show}
          onToggle={setShow}
          defaultShow={false}
          placement='bottom-start'
          overlay={popOverContent}
        >
          <div className='top_popover' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
          </div>
        </OverlayTrigger>

        {/* MultiDropDown */}
        <div className='filterPlaner '>
          <DropDownPlaner context={TableContext} data={multiSelectOptions} filterBy={filter.searchBy.title} />
          <FilterPlaner
            filter={filter}
            customers={customers}
            stopPoints={stopPoints}
            onChangeAll={onChangeAll}
            updateFilter={updateFilter}
            dateRangeLocation={dateRangeLocation}
            onChangeCustomers={onChangeCustomers}
            dateRangeStopPoint={dateRangeStopPoint}
            stopPointCondition={stopPointCondition}
            setDateRangeLocation={setDateRangeLocation}
            setDateRangeStopPoint={setDateRangeStopPoint}
            onChangeLocationValues={onChangeLocationValues}
            onDeleteStopPointSelect={onDeleteStopPointSelect}
            onChangeStopPointSelect={onChangeStopPointSelect}
            onChangeLocationDatePicker={onChangeLocationDatePicker}
            onChangeDatePickerStopPoint={onChangeDatePickerStopPoint}
          />
        </div>
        <DarkOverlay visible={show} styles={{ zIndex: 11 }} />
        <CustomButton
          type='primary'
          title=''
          leftIcon={
            advancedOpen ? (
              <MinusIcon fill='#fff' style={{ margin: 8 }} />
            ) : (
              <PlusIcon fill='#fff' style={{ margin: 8 }} />
            )
          }
          className='ms-3'
          styleButton={{ height: 28 }}
          onClick={() => setAdvancedOpen(!advancedOpen)}
        />
      </div>

      {advancedOpen && (
        <div className='mb-3'>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12} container columnSpacing={5} rowSpacing={3}>
              <Grid item xs={3}>
                <div className='w-100'>
                  <Typography variant='b2'>Shipment ID</Typography>
                  <Input
                    name='shipment_id'
                    placeholder='Shipment ID'
                    value={values.shipment_id}
                    onChange={(e) => handleChange(`shipment_id`, e.target.value)}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Reference ID</Typography>
                  <Input
                    name='reference'
                    value={values.reference}
                    placeholder='Reference ID'
                    onChange={(e) => handleChange(`reference`, e.target.value)}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Status</Typography>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    labelKey='title'
                    value={values.status}
                    placeholder={values.status.length ? '' : 'Select Status'}
                    onChange={(e, val) => handleChange('status', val)}
                    options={STATUS_FILTER_DATA_PENDING.filter((i) => i.id !== 6)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className='w-100'>
                  <Typography variant='b2'>Tags</Typography>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    labelKey='title'
                    value={values.group}
                    options={filterByGroupsData}
                    placeholder={values.status.length ? '' : 'Select Tags'}
                    onChange={(e, val) => handleChange('group', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Stop Point</Typography>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    labelKey='label'
                    options={stopPoints}
                    value={values.stopPoint}
                    placeholder={values.status.length ? '' : 'Select Stop Point'}
                    onChange={(e, val) => handleChange('stopPoint', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Customer</Typography>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    labelKey='label'
                    options={customers}
                    value={values.customer}
                    placeholder={values.status.length ? '' : 'Select Customer'}
                    onChange={(e, val) => handleChange('customer', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className='w-100'>
                  <Typography variant='b2'>Origin</Typography>
                  <div className='d-flex gap-2'>
                    <Input
                      name='origin_city'
                      placeholder='City'
                      style={{ width: '100%' }}
                      value={values.origin_city}
                      onChange={(e) => handleChange(`origin_city`, e.target.value)}
                    />
                    <Input
                      placeholder='State'
                      name='origin_state'
                      style={{ width: 80 }}
                      value={values.origin_state}
                      onChange={(e) => handleChange(`origin_state`, e.target.value)}
                    />
                    <div style={initialStyle.inputLabel}>
                      <Input
                        name='origin_zipcode'
                        placeholder='Zip Code'
                        value={values.origin_zipcode}
                        style={{ width: 90, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        onChange={(e) => handleChange(`origin_zipcode`, e.target.value)}
                      />
                      <div style={initialStyle.radius}>Zip Code</div>
                    </div>
                    <div style={initialStyle.inputLabel}>
                      <Input
                        name='origin_miles'
                        placeholder='Miles'
                        value={values.origin_miles}
                        style={{ width: 50, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        onChange={(e) => handleChange(`origin_miles`, e.target.value)}
                      />
                      <div style={initialStyle.radius}>Miles</div>
                    </div>
                  </div>
                </div>
                <div className='w-100 mt-2'>
                  <Typography variant='b2'>Destination</Typography>
                  <div className='d-flex gap-2'>
                    <Input
                      placeholder='City'
                      name='destination_city'
                      style={{ width: '100%' }}
                      value={values.destination_city}
                      onChange={(e) => handleChange(`destination_city`, e.target.value)}
                    />
                    <Input
                      placeholder='State'
                      style={{ width: 80 }}
                      name='destination_state'
                      value={values.destination_state}
                      onChange={(e) => handleChange(`destination_state`, e.target.value)}
                    />
                    <div style={initialStyle.inputLabel}>
                      <Input
                        placeholder='Zip Code'
                        name='destination_zipCode'
                        value={values.destination_zipCode}
                        style={{ width: 90, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        onChange={(e) => handleChange(`destination_zipCode`, e.target.value)}
                      />
                      <div style={initialStyle.radius}>Zip Code</div>
                    </div>
                    <div style={initialStyle.inputLabel}>
                      <Input
                        placeholder='Miles'
                        name='destination_miles'
                        value={values.destination_miles}
                        style={{ width: 50, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        onChange={(e) => handleChange(`destination_miles`, e.target.value)}
                      />
                      <div style={initialStyle.radius}>Miles</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-end gap-2 mt-2'>
                  <div className='mt-2' style={{ width: 182, flexShrink: 0 }}>
                    <Typography variant='b2'>EDI / API</Typography>
                    <Autocomplete
                      multiple
                      width='100%'
                      limitTags={2}
                      labelKey='title'
                      options={EDI_API_STATUS}
                      value={values.ediApiStatus}
                      placeholder={values.ediApiStatus.length ? '' : 'Select Status'}
                      onChange={(e, val) => handleChange('ediApiStatus', val)}
                      isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                    />
                  </div>
                  <div>
                    <CreatedFilter
                      filter={filter}
                      values={values}
                      handleChange={handleChange}
                      dateRangeCreatedBy={dateRangeCreatedBy}
                      onChangeDatePicker={onChangeDatePickerCreatedBy}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className='w-100 d-flex flex-column'>
                  <Typography variant='b2'>Pick Up Date Range</Typography>
                  <CustomDatePicker
                    selectsRange
                    renderStart={false}
                    style={initialStyle.date}
                    shouldCloseOnSelect={false}
                    endDate={pickUpDateRange[1]}
                    valueToShow={valuePickupDate}
                    startDate={pickUpDateRange[0]}
                    labelStyle={initialStyle.dateLabel}
                    placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                    onChange={(dates) => onChangePickUpRange(dates)}
                  />
                </div>
                <div className='w-100 d-flex flex-column mt-3'>
                  <Typography variant='b2'>Delivery Date Range</Typography>
                  <CustomDatePicker
                    selectsRange
                    renderStart={false}
                    style={initialStyle.date}
                    shouldCloseOnSelect={false}
                    endDate={deliveryDateRange[1]}
                    valueToShow={valueDeliveryDate}
                    startDate={deliveryDateRange[0]}
                    labelStyle={initialStyle.dateLabel}
                    placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                    onChange={(dates) => onChangeDeliveryRange(dates)}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ width: 269, flexShrink: 0 }}>
                  <Typography variant='b2'>Equipment Types</Typography>
                  <Autocomplete
                    multiple
                    width='100%'
                    limitTags={2}
                    labelKey='title'
                    options={filterByEquipmentTypeData}
                    value={values.equipment_types || []}
                    placeholder='Select Equipment Types'
                    onChange={(e, val) => handleChange('equipment_types', val)}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: 2 }} container spacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent='center'>
                <Grid item>
                  <CustomButton
                    type='secondary'
                    title='Clear All'
                    className='ms-3'
                    onClick={() => clearSearchValues()}
                    styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    type='primary'
                    onClick={form.handleSubmit}
                    disabled={loading}
                    leftIcon={
                      loading ? (
                        <CircularProgress
                          style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }}
                        />
                      ) : (
                        <div />
                      )
                    }
                    title='Search'
                    className='ms-3'
                    styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      <TableHeaderFilterByShow
        tableType='planner'
        filter={filter}
        dateRangeCreatedBy={dateRangeCreatedBy}
        onChangeDatePickerCreatedBy={onChangeDatePickerCreatedBy}
        onDeleteDatePickerCreatedBy={onDeleteDatePickerCreatedBy}
        /** this top level params for planer createdBy Filter* */
        groups={groups}
        onReset={onReset}
        statuses={statuses}
        setGroups={setGroups}
        equipments={equipments}
        data={showHeaderFilters}
        setStatuses={setStatuses}
        setEquipments={setEquipments}
        disabledFilters={disabledFilters}
        multiSelectOptions={multiSelectOptions}
        filterByGroupsData={filterByGroupsData}
        onDeleteManyDataItem={onDeleteManyDataItem}
        localFilterCheckboxes={localFilterCheckboxes}
        onDeleteStopPointItem={onDeleteStopPointItem}
        onDeleteLocationsItems={onDeleteLocationsItems}
        onApplyFilterCheckboxes={onApplyFilterCheckboxes}
        setLocalFilterCheckboxes={setLocalFilterCheckboxes}
        filterByEquipmentTypeData={filterByEquipmentTypeData}
        statusData={STATUS_FILTER_DATA_PENDING.filter((i) => i.id !== 6)}
      />
    </div>
  );
};

export default PendingAssignmentFilter;
