import Pusher from 'pusher-js';

export const PusherJs = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
  cluster: 'us2',
  encrypted: true,
  authEndpoint: `${process.env.REACT_APP_BASE_URL}/api/broadcasting`,
  auth: {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.accessToken}`,
    },
  },
});
