import React, { useContext, useState } from 'react';
import './leftMenu.css';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TruckinLogo } from 'assets/icons/logo/truckinLogo.svg';
import { ReactComponent as TruckinDigitalLogo } from 'assets/icons/logo/truckinDigital.svg';
import { useTheme } from 'context/themeContext';
import { palette, PERMISSIONS } from 'utils/constants';
import { onChangeMenuSettings } from 'store/reducers/root.reducer';
import { onChangeIsOpenSendPayment } from 'store/reducers/header.reducer';
import { MenuIcons } from './menuIcons';
import LeftMenuSettings from './leftMenuSettings';
import LeftMenuClosedPopover from './LeftMenuClosedPopover';
import LeftMenuOpenCollapse from './LeftMenuOpenCollapse';
import LeftMenuFooter from './LeftMenuFooter';
import { LayoutContext } from '../layout';
import FavoritesPopover from './FavoritesPopover';
import { SNavigationWrapper } from './LeftMenu.styles';

const LeftMenu = () => {
  const navigate = useNavigate();
  const { theme, use } = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { menuSettings, permissions } = useSelector((state) => state?.root);
  const { menuButtons, openInNewTab } = useContext(LayoutContext);
  const [activeId, setActiveId] = useState(null);
  const { Brush, LogoIcon, TruckInDigital } = MenuIcons;

  const getActivePath = (item) => {
    if (item.path) {
      return pathname.startsWith(item.path);
    }
    if (item.collapse?.subItems) {
      const activeSubItem = item.collapse.subItems.find((subItem) => subItem.path && pathname.startsWith(subItem.path));
      return activeSubItem ? pathname.startsWith(activeSubItem.path) && pathname !== '/company-settings' : '';
    }
    return '';
  };

  const renderTooltip = (title) => {
    if (theme.condensed) {
      return <Tooltip>{title}</Tooltip>;
    }
    return <span />;
  };

  return (
    <div className='left_menu_container_wrapper'>
      <LeftMenuSettings
        customstyle={menuSettings ? 'left-menu-settings-display' : 'left-menu-settings-display-none'}
        close={() => dispatch(onChangeMenuSettings(false))}
      />
      <div
        className={theme.condensed ? 'left-menu' : 'left-menu-active'}
        style={{
          backgroundColor: use(palette.white, palette.dark800, palette.indigo500),
          borderRight: `1px solid ${use(palette.gray100, palette.gray700, palette.indigo500)}`,
        }}
      >
        <div
          className='left-menu-top logo-container'
          style={{
            marginLeft: theme.condensed ? 0 : 4,
            flexDirection: theme.condensed ? 'column' : 'row',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: theme.condensed ? 9 : 0 }}>
            {!theme.condensed
              ? use(
                  <TruckinDigitalLogo width={160} height={33} />,
                  <>
                    <LogoIcon
                      fill={use(palette.gray500, palette.gray200, palette.white)}
                      style={{ width: 32, height: 32, marginRight: 10 }}
                    />
                    <TruckInDigital
                      fill={use(palette.gray500, palette.gray200, palette.white)}
                      style={{ width: 115, height: 32 }}
                    />
                  </>,
                  <>
                    <LogoIcon
                      fill={use(palette.gray500, palette.gray200, palette.white)}
                      style={{ width: 32, height: 32, marginRight: 10 }}
                    />
                    <TruckInDigital
                      fill={use(palette.gray500, palette.gray200, palette.white)}
                      style={{ width: 115, height: 32 }}
                    />
                  </>
                )
              : use(
                  <TruckinLogo width={32} height={32} style={{ marginRight: 10 }} />,
                  <LogoIcon
                    fill={use(palette.gray500, palette.gray200, palette.white)}
                    style={{ width: 32, height: 32, marginRight: 10 }}
                  />,
                  <LogoIcon
                    fill={use(palette.gray500, palette.gray200, palette.white)}
                    style={{ width: 32, height: 32, marginRight: 10 }}
                  />
                )}
          </div>
          <div
            className='brush-icon-container '
            style={{ marginLeft: theme.condensed ? 0 : 30 }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(onChangeMenuSettings(true));
            }}
          >
            <div className='brush-icon'>
              <Brush fill={use(palette.indigo500, palette.gray900, palette.indigo500)} width={12} height={15} />
            </div>
          </div>
        </div>

        <SNavigationWrapper $isCondensed={theme.condensed}>
          <div
            className='left-menu-middle-active'
            style={{
              width: theme.condensed ? 'auto' : '100%',
              display: theme.condensed ? 'flex' : 'block',
              flexDirection: theme.condensed ? 'column' : 'row',
            }}
          >
            {menuButtons
              .filter((el) => {
                const { menuPermissions } = el;

                const hasPermission =
                  !menuPermissions?.length || menuPermissions?.some((permission) => permissions.includes(permission));

                return (el.title === 'Repair Shop' && permissions.includes(PERMISSIONS.BILLING.name)) || hasPermission;
              })
              .map((el) => {
                const { id, title, Icon, className, titleStyles, iconStyles, path, collapse } = el;
                const { editable, subItems, indigoItems } = collapse || {};

                if (collapse && !theme.condensed) {
                  return (
                    <div key={id}>
                      <LeftMenuOpenCollapse
                        subItems={subItems}
                        editable={editable}
                        parentItem={el}
                        setActiveId={() => setActiveId(id)}
                        activeId={activeId}
                        ParentIcon={Icon}
                        menuStyleLocal={theme.condensed}
                        openInNewTab={openInNewTab}
                      />
                    </div>
                  );
                }

                if (collapse && !menuSettings && editable) {
                  return (
                    <div
                      key={id}
                      className={`icon_wrapper ${subItems ? 'have_subItems' : ''} ${use('light', 'dark', 'primary')} ${
                        className || ''
                      }`}
                      style={{ borderRadius: '50%', paddingBottom: '10px' }}
                    >
                      <FavoritesPopover
                        el={el}
                        Icon={Icon}
                        getActivePath={getActivePath}
                        subItems={subItems}
                        editable={editable}
                        indigoItems={indigoItems}
                        parentTitle={title}
                        onClick={() => {
                          setActiveId(id);
                          path && navigate(path);
                        }}
                      />
                    </div>
                  );
                }

                const TagName = el?.path ? NavLink : 'div';
                return (
                  <OverlayTrigger
                    key={uuid()}
                    placement='right'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(title)}
                  >
                    <TagName
                      {...(el?.path && { to: el?.path })}
                      className={`icon_wrapper ${subItems ? 'have_subItems' : ''} ${use('light', 'dark', 'primary')} ${
                        !theme?.condensed ? '' : className || ''
                      }`}
                      style={{ borderRadius: '6px', textDecoration: 'none' }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!collapse || !!menuSettings) {
                          if (openInNewTab) {
                            window.open(el.path);
                            return;
                          }
                          setActiveId(id);
                          path && navigate(path);
                        }
                      }}
                    >
                      {collapse && !menuSettings ? (
                        <LeftMenuClosedPopover
                          subItems={subItems}
                          editable={editable}
                          indigoItems={indigoItems}
                          parentTitle={title}
                          openInNewTab={openInNewTab}
                          onClick={(el) => {
                            if (openInNewTab && +el.id !== 86 && el.path) {
                              const searchParams = new URLSearchParams(el?.state);
                              const fullPath = searchParams?.size !== 0 ? `${el.path}?${searchParams}` : el.path;
                              window.open(fullPath);
                              return;
                            }
                            +el.id === 86 && dispatch(onChangeIsOpenSendPayment({ open: true }));
                            setActiveId(id);
                            path && navigate(path);
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div className='icon-container'>
                              <Icon
                                width={19}
                                height={19}
                                fill={
                                  getActivePath(el)
                                    ? use(palette.indigo500, palette.indigo400, palette.white)
                                    : use(palette.gray500, palette.gray400, palette.indigo200)
                                }
                              />
                            </div>
                            <p
                              className='button-text'
                              style={{
                                display: theme.condensed ? 'none' : 'block',
                                color: getActivePath(el)
                                  ? use(palette.indigo500, palette.indigo400, palette.white)
                                  : use(palette.gray500, palette.gray400, palette.indigo200),
                                ...titleStyles,
                              }}
                            >
                              {title}
                            </p>
                          </div>
                        </LeftMenuClosedPopover>
                      ) : (
                        <span>
                          <div
                            className='open_collapse_items'
                            onClick={() => {
                              setActiveId(id);
                              // path && navigate(path)
                            }}
                          >
                            <div
                              className='icon-container'
                              style={{ ...(!theme.condensed ? { ...iconStyles, flexShrink: 0 } : {}) }}
                            >
                              <Icon
                                width={19}
                                height={19}
                                fill={
                                  getActivePath(el)
                                    ? use(palette.indigo500, palette.indigo400, palette.white)
                                    : use(palette.gray500, palette.gray400, palette.indigo200)
                                }
                              />
                            </div>
                            <p
                              className='button-text'
                              style={{
                                display: theme.condensed ? 'none' : 'block',
                                color: getActivePath(el)
                                  ? use(palette.indigo500, palette.indigo400, palette.white)
                                  : use(palette.gray500, palette.gray400, palette.indigo200),
                                ...titleStyles,
                              }}
                            >
                              {title}
                            </p>
                            {/* // Reports */}
                            {+el.id === 13 && !theme.condensed && (
                              <div className='leftMenu_beta_button' style={{ marginLeft: 'calc(100% - 22.5px)' }}>
                                <span>BETA</span>
                              </div>
                            )}
                          </div>
                        </span>
                      )}
                    </TagName>
                  </OverlayTrigger>
                );
              })}
            <div
              className={`leftMenuFooter_wrapper${theme.condensed ? '-condensed' : ''}`}
              style={{ backgroundColor: use(palette.white, palette.dark800, palette.indigo500) }}
            >
              <div style={{ padding: '0 16px' }}>
                <div
                  className='lineInLeftMenu'
                  style={{ backgroundColor: use(palette.gray200, palette.dark700, palette.indigo400) }}
                />
              </div>
              <LeftMenuFooter
                setActiveId={setActiveId}
                activeId={activeId}
                menuStyleLocal={theme.condensed}
                openInNewTab={openInNewTab}
              />
            </div>
          </div>
        </SNavigationWrapper>
      </div>
    </div>
  );
};
export default LeftMenu;
