import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { palette } from 'utils/constants';

export const SFeaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 24px;
  padding: 0 12px;
  margin: 18px 0;
`;

export const SAddonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 24px;
  padding: 0 12px;
  margin: 18px 0;
`;

export const SServicesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  padding: 0 12px;
  margin: 18px 0;
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    margin-bottom: 24px;
    border-bottom: 1px solid ${palette.gray200};
    min-height: 36px;

    .MuiTab-root {
      padding: 4px 12px;
      min-height: 32px;
      min-width: 60px;
      text-transform: none;
      &.Mui-selected {
        color: ${palette.indigo500};
      }
    }

    .MuiButtonBase-root.MuiTabScrollButton-root {
      width: 20px;
    }

    .MuiTabs-indicator {
      background-color: ${palette.indigo500};
    }

    .MuiTabs-scrollableX {
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      ::-webkit-scrollbar-thumb {
        background: ${palette.gray200};
        border-radius: 8px;
      }
    }
  }
`;
