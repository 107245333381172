import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import { getControlDevice } from 'Api/AccidentsAndCrashes';
import { Typography } from 'components/Typography';
import ControlDeviceChart from '../Charts/ControlDeviceChart';
import { SBoxWrapper } from '../../StatsSection.styles';

export const ControlDevice = ({ statsRefreshIndex }) => {
  const [controlDevice, setControlDevice] = useState([]);

  const getChartData = async () => {
    try {
      const { data } = await getControlDevice();
      setControlDevice(data.control_device);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getChartData();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex flex-column gap-1'>
        <Typography variant='s2'>CONTROL DEVICE</Typography>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          WHAT TYPE OF DEVICE WAS PRESENT
        </Typography>
      </div>
      <div className='chart-wrapper'>
        <ControlDeviceChart chartData={controlDevice} />
      </div>
    </SBoxWrapper>
  );
};
