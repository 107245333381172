import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import { palette } from 'utils/constants';
import { UpdateStopPoint } from 'Api/StopPoint';
import { useTheme } from 'context/themeContext';
import AddressAutocomplete from 'common/AddressAutocomplete';
import { searchCities, searchCountries, searchStates } from 'Api/Driver';

import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { STOP_POINT_COUNTRY } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import styles from './General.module.scss';

const GeneralEdit = ({
  setEdit,
  stopPointsItem,
  getStopPointItem,
  setUpdateIndex,
  showVehicleAlert,
  showTrailerAlert,
}) => {
  const { id } = useParams();

  const handleSubmit = async (values) => {
    if (!values.address1) {
      return;
    }
    UpdateStopPoint(id, {
      ...values,
      vehicle_setting: Number(values?.vehicle_setting),
      trailer_setting: Number(values?.trailer_setting),
      country_id: values?.country_id.toString(),
      state_id: values?.state_id.toString(),
    }).then(async (res) => {
      if (res) {
        await getStopPointItem(id);
        setUpdateIndex(Date.now());
        setEdit(false);
      }
    });
  };

  const initialValue = useMemo(() => {
    const { country, city, state, stop_point, stop_point_alert_setting } = stopPointsItem || {};
    return {
      location_name: stop_point?.location_name ? stop_point?.location_name : '',
      address1: stop_point?.address1 ? stop_point?.address1 : '',
      address2: stop_point?.address2 ? stop_point?.address2 : '',
      country_id: country?.id ? stopPointsItem?.country?.id?.toString() : '',
      city_id: city?.id ? stopPointsItem?.city?.id?.toString() : '',
      state_id: state?.id ? stopPointsItem?.state?.id?.toString() : '',
      zipcode: stop_point?.zipcode ? stop_point?.zipcode : '',
      require_lumper_receipt: stop_point?.require_lumper_receipt?.toString()
        ? Number(stop_point?.require_lumper_receipt)
        : '',
      require_scale_tickets: stop_point?.require_scale_tickets?.toString()
        ? Number(stop_point?.require_scale_tickets)
        : '',
      equipment_count: stop_point?.equipment_count?.toString() ? stop_point?.equipment_count?.toString() : '',
      status_id: stop_point?.status_id?.toString() ? stop_point?.status_id?.toString() : '',
      working_hour_by: stop_point?.working_hour_by?.toString() ? stop_point?.working_hour_by?.toString() : '',
      auto_check_in: stop_point?.auto_check_in?.toString() ? stop_point?.auto_check_in?.toString() : '',
      number_of_vehicle: stop_point_alert_setting?.number_of_vehicle ? stop_point_alert_setting?.number_of_vehicle : '',
      number_of_trailer: stop_point_alert_setting?.number_of_trailer ? stop_point_alert_setting?.number_of_trailer : '',
      vehicle_setting: stop_point_alert_setting?.vehicle_setting ? !!stop_point_alert_setting?.vehicle_setting : false,
      trailer_setting: stop_point_alert_setting?.trailer_setting ? !!stop_point_alert_setting?.trailer_setting : false,
      considered_yard: stop_point?.considered_yard ? !!stop_point?.considered_yard : false,
      not_track_average_waiting_time: stop_point?.not_track_average_waiting_time
        ? !!stop_point?.not_track_average_waiting_time
        : false,
    };
  }, [stopPointsItem]);
  return (
    <Formik onSubmit={handleSubmit} initialValues={{ ...initialValue }}>
      {({ validateField, getFieldHelpers, ...props }) => (
        <GeneralEditForma
          {...props}
          setEdit={setEdit}
          validateField={validateField}
          getFieldHelpers={getFieldHelpers}
          stopPointsItem={stopPointsItem}
          getStopPointItem={getStopPointItem}
          showVehicleAlert={showVehicleAlert}
          showTrailerAlert={showTrailerAlert}
        />
      )}
    </Formik>
  );
};

function GeneralEditForma({
  values,
  submitForm,
  setFieldValue,
  getFieldHelpers,
  setEdit,
  stopPointsItem,
  showVehicleAlert,
  showTrailerAlert,
}) {
  const { use } = useTheme();
  const [countries, setCountries] = useState(null);
  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(null);
  const [activeOption] = useState({});
  const [invalidAddress, setInvalidAddress] = useState(false);

  const getCities = (state_id) => {
    return searchCities('', state_id).then((res) => {
      setCities(res.data);
      return res?.data;
    });
  };
  const getStates = (country_id) => {
    return searchStates('', country_id).then((res) => {
      if (res && res?.data) {
        setStates(res?.data);
        return res?.data;
      }
    });
  };
  const onChangeCountry = ({ value, getFieldHelpers, first }) => {
    if (first) {
      const country_id = getFieldHelpers('country_id');
      const zipcode = getFieldHelpers('zipcode');
      zipcode.setValue(activeOption?.Zip);
      country_id.setValue(value?.id);
      getStates(value?.id).then((res) => {
        if (Object.keys(activeOption).length <= 0) {
          const foundedState = res?.find((data) => Number(data?.id) === Number(stopPointsItem?.state?.id));
          setStates(res);
          !!foundedState && onChangeState({ value: foundedState, getFieldHelpers, first: true });
        } else {
          const foundedState = res?.find((data) => data?.name === activeOption?.StateName);
          setStates(res);
          !!foundedState && onChangeState({ value: foundedState, getFieldHelpers, first: true });
        }
      });
    } else {
      const state_id = getFieldHelpers('state_id');
      const city_id = getFieldHelpers('city_id');
      city_id.setValue('');
      state_id.setValue('');
      setCities(null);
      getStates(value?.id).then((data) => setStates(data));
    }
    if (Object.keys(activeOption).length <= 0) {
      const zipcode = getFieldHelpers('zipcode');
      zipcode.setValue(values?.zipcode);
    }
    return false;
  };
  const onChangeState = ({ value, getFieldHelpers, first }) => {
    if (first) {
      const state_id = getFieldHelpers('state_id');
      state_id.setValue(value?.id);
      getCities(value?.id).then((data) => {
        if (Object.keys(activeOption).length <= 0) {
          const foundedData = data?.find((el) => Number(el?.id) === Number(stopPointsItem?.city?.id));
          setTimeout(() => onChangeCity(foundedData, true), 0);
        } else {
          const foundedData = data?.find((el) => el?.name === activeOption?.City);
          setTimeout(() => onChangeCity(foundedData, true), 0);
        }
      });
    } else {
      const city_id = getFieldHelpers('city_id');
      city_id.setValue('');
      getCities(value?.id).then((data) => setCities(data));
    }
    return false;
  };
  const onChangeCity = (e, first) => {
    setCities(e ? [e] : []);
    if (first) {
      setFieldValue('city_id', e?.id.toString());
    }
    return false;
  };

  useEffect(() => {
    if (typeof activeOption === 'object' && Object.keys(activeOption).length > 0) {
      let foundActiveCountry;
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].sortname === activeOption.Country) {
          foundActiveCountry = countries[i];
          break;
        }
      }
      if (stopPointsItem && countries) {
        onChangeCountry({ value: foundActiveCountry, getFieldHelpers, first: true });
      }
    } else if (
      stopPointsItem &&
      countries &&
      typeof activeOption === 'object' &&
      Object.keys(activeOption).length <= 0
    ) {
      const foundedCountry = countries?.find((s) => s?.id === stopPointsItem?.country?.id);
      onChangeCountry({ value: foundedCountry, getFieldHelpers, first: true });
    }
  }, [stopPointsItem, countries, activeOption]);

  useEffect(() => {
    searchCountries('').then((res) => {
      setCountries(res.data);
    });
  }, []);

  const validateRequired = (value) => {
    let error;
    if (value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };
  const validateCountsVehicles = (value) => {
    let error;
    if (values?.vehicle_setting) {
      if (value !== undefined && value === '' && value?.trim() === '') {
        error = 'Required';
      }
      if (value < 1) {
        error = 'Invalid value';
      }
    }
    return error;
  };
  const validateCountsTrailers = (value) => {
    let error;
    if (values?.trailer_setting) {
      if (value !== undefined && value === '' && value?.trim() === '') {
        error = 'Required';
      }
      if (value < 1) {
        error = 'Invalid value';
      }
    }
    return error;
  };

  const onAddressSelect = (data) => {
    setFieldValue('country_id', data.country?.id?.toString());
    onChangeCountry({ value: data.country, getFieldHelpers, first: false });
    setFieldValue('state_id', data.state?.id?.toString());
    onChangeState({ value: data.state, getFieldHelpers, first: false });
    setFieldValue('city_id', data.city?.id?.toString());
  };

  const changeAddress = ({ address, zip }) => {
    setInvalidAddress(false);
    setFieldValue('address1', address);
    setFieldValue('zipcode', zip || values.zipcode);
  };

  return (
    <Form>
      <div className={styles.general_wrapper}>
        <div className={styles.general_geoFencing}>
          <div className={styles.header2}>
            <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
              General Information
            </Typography>
            <div className={styles.actions}>
              <CustomButton
                onClick={() => {
                  setEdit(false);
                }}
                type='secondary'
                title='Cancel'
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                className={styles.action}
              />
              <CustomButton
                onClick={() => {
                  setInvalidAddress(!values.address1);
                  submitForm();
                }}
                styleButton={{ marginRight: 0 }}
                type='primary'
                title='Save'
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                className={styles.action}
              />
            </div>
          </div>
          <div className={styles.change_section}>
            <div className={styles.change_top}>
              <div className={styles.left_side}>
                <div className={styles.label_wrapper}>
                  <span className={styles.label_required}>Location Name</span>
                  <Field
                    type='text'
                    name='location_name'
                    validate={validateRequired}
                    component={CustomInput}
                    style={{ width: 440, padding: '6px 12px' }}
                    labelStyle={{ margin: 0 }}
                    value={values?.title}
                  />
                  <ErrorMessage
                    name='location_name'
                    render={(error) => (
                      <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                        {error}
                      </Typography>
                    )}
                  />
                </div>
                <label className={styles.label_wrapper}>
                  <Field name='require_scale_tickets'>
                    {({ field }) => {
                      return (
                        <CustomCheckbox
                          field={field}
                          checked={+!!field.value}
                          name='require_scale_tickets'
                          withinForm
                          onChange={() => {
                            setFieldValue('require_scale_tickets', +!values.require_scale_tickets);
                          }}
                        >
                          <Typography
                            variant='s2'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              marginLeft: 5,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Require Scale/Tickets
                          </Typography>
                        </CustomCheckbox>
                      );
                    }}
                  </Field>
                </label>
                <label className={styles.label_wrapper}>
                  <Field name='require_lumper_receipt'>
                    {({ field }) => {
                      return (
                        <CustomCheckbox
                          field={field}
                          checked={+!!field.value}
                          name='require_lumper_receipt'
                          withinForm
                          onChange={() => {
                            setFieldValue('require_lumper_receipt', +!values.require_lumper_receipt);
                          }}
                        >
                          <Typography
                            variant='s2'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              marginLeft: 5,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Require Lumper Receipt
                          </Typography>
                        </CustomCheckbox>
                      );
                    }}
                  </Field>
                </label>
                {!!showVehicleAlert && (
                  <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                    <div>
                      <Field name='vehicle_setting'>
                        {({ field }) => (
                          <CustomCheckbox
                            name='vehicle_setting'
                            field={field}
                            type='switch'
                            withinForm
                            checked={!!field.value}
                            style={{ margin: '0 10px 0 0' }}
                          />
                        )}
                      </Field>
                    </div>
                    <div style={{ marginRight: 10 }}>
                      <span style={{ fontSize: 16 }}>Alert me when equipment at stop point reach</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                      <Field
                        type='number'
                        name='number_of_vehicle'
                        component={CustomInput}
                        validate={validateCountsVehicles}
                        disabled={!values?.vehicle_setting}
                        style={{ width: 100, padding: '6px 12px', borderRadius: '6px' }}
                        labelStyle={{ margin: 0 }}
                      />
                      <ErrorMessage
                        name='number_of_vehicle'
                        render={(error) => (
                          <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <span style={{ fontSize: 16 }}>vehicles</span>
                    </div>
                  </div>
                )}
                {!!showTrailerAlert && (
                  <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                    <div>
                      <Field name='trailer_setting'>
                        {({ field }) => (
                          <CustomCheckbox
                            name='trailer_setting'
                            field={field}
                            type='switch'
                            withinForm
                            checked={field.value}
                            style={{ margin: '0 10px 0 0' }}
                          />
                        )}
                      </Field>
                    </div>
                    <div style={{ marginRight: 10 }}>
                      <span style={{ fontSize: 16 }}>Alert me when equipment at stop point reach</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                      <Field
                        type='number'
                        name='number_of_trailer'
                        component={CustomInput}
                        disabled={!values?.trailer_setting}
                        validate={validateCountsTrailers}
                        style={{ width: 100, padding: '6px 12px', borderRadius: '6px' }}
                        labelStyle={{ margin: 0 }}
                      />
                      <ErrorMessage
                        name='number_of_trailer'
                        render={(error) => (
                          <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <span style={{ fontSize: 16 }}>trailers</span>
                    </div>
                  </div>
                )}
                <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                  <div>
                    <Field name='considered_yard'>
                      {({ field }) => (
                        <CustomCheckbox
                          name='considered_yard'
                          field={field}
                          type='switch'
                          withinForm
                          checked={field.value}
                          style={{ margin: '0 10px 0 0' }}
                        />
                      )}
                    </Field>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <span style={{ fontSize: 16 }}>This stop point is considered a yard</span>
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                  <div>
                    <Field name='not_track_average_waiting_time'>
                      {({ field }) => (
                        <CustomCheckbox
                          name='not_track_average_waiting_time'
                          field={field}
                          type='switch'
                          withinForm
                          checked={field.value}
                          style={{ margin: '0 10px 0 0' }}
                        />
                      )}
                    </Field>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <span style={{ fontSize: 16 }}>Do not track average wait time</span>
                  </div>
                </div>
              </div>
              <div className={styles.center}>
                <div className={styles.label_wrapper}>
                  <span className={styles.label_required}>Address</span>
                  <AddressAutocomplete
                    id='address1'
                    name='address1'
                    onSuccess={onAddressSelect}
                    changeAddress={changeAddress}
                    onChange={(e) => {
                      setInvalidAddress(true);
                      setFieldValue('address1', e.target.value);
                    }}
                    value={values.address1}
                    options={{ componentRestrictions: { country: ['us', 'ca', 'mx'] } }}
                    error={
                      (invalidAddress && !values.address1 && 'Required') ||
                      (invalidAddress && !!values.address1 && 'Invalid Address')
                    }
                  />
                </div>
                <div className={styles.label_wrapper}>
                  <Field
                    type='text'
                    name='address2'
                    placeholder='Address 2...'
                    component={CustomInput}
                    style={{ width: 440, padding: '6px 12px' }}
                    labelStyle={{ flexDirection: 'column', alignItems: 'flex-start', margin: 0 }}
                  />
                  <ErrorMessage
                    name='address2'
                    render={(error) => (
                      <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                        {error}
                      </Typography>
                    )}
                  />
                </div>
                <div className={styles.label_wrapper}>
                  <Field
                    name='country_id'
                    options={STOP_POINT_COUNTRY}
                    styles={{
                      height: 32,
                      width: 440,
                      marginTop: 8,
                    }}
                    component={CustomSelect}
                    keyName='id'
                    labelName='label'
                    isSearchable
                    onChange={(value) => {
                      onChangeCountry({ value, getFieldHelpers });
                    }}
                  />
                </div>
                <div className={styles.label_wrapper}>
                  <Field
                    name='state_id'
                    options={states}
                    styles={{
                      height: 32,
                      width: 440,
                      marginTop: 10,
                      borderRadius: 6,
                    }}
                    isSearchable
                    component={CustomSelect}
                    keyName='id'
                    labelName='name'
                    onChange={(value) => onChangeState({ value, getFieldHelpers })}
                  />
                </div>
                <div className={styles.label_wrapper1}>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <Field
                        name='city_id'
                        options={cities}
                        styles={{
                          height: 32,
                          width: 440,
                          marginTop: 10,
                        }}
                        isSearchable
                        component={CustomSelect}
                        keyName='id'
                        labelName='name'
                        onChange={onChangeCity}
                      />
                    </div>
                    <ErrorMessage
                      name='city_id'
                      render={(error) => (
                        <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                          {error}
                        </Typography>
                      )}
                    />
                  </div>
                </div>
                <div className={styles.label_wrapper}>
                  <FastField
                    name='zipcode'
                    component={CustomInput}
                    validate={validateRequired}
                    placeholder='Zip Code'
                    style={{
                      width: 440,
                      borderRadius: 6,
                      height: 32,
                      padding: '6px 12px',
                      marginTop: 0,
                    }}
                  />
                  <ErrorMessage
                    name='zipcode'
                    render={(error) => (
                      <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                        {error}
                      </Typography>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default GeneralEdit;
