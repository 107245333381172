import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { numberWithCommas, palette } from 'utils/constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getOptions = (currency) => ({
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1.5,
  barThickness: 18,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value, index, values) {
          if (index === 0) {
            return moment().subtract(11, 'month').format('MMM YYYY');
          }
          if (index === values.length - 1) {
            return moment().format('MMM YYYY');
          }
        },
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label} - ${currency}${numberWithCommas(Number(item[0].raw).toFixed(2))}`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
});

const TotalOpenReceivable = ({ monthsData }) => {
  const { currency } = useSelector((state) => state.root);

  const data = {
    labels: (monthsData || []).map((item) => `${item.month}`),
    datasets: [
      {
        data: (monthsData || []).map((item) => item.amount),
        backgroundColor: palette.green400,
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getOptions(currency)} data={data} />;
};

export default TotalOpenReceivable;
