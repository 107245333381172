export const getInitialValues = (data) => {
  const {
    carrier_uploaded_doc_verification_required,
    driver_uploaded_doc_verification_required,
    rename_bill_of_lading_to_proof_of_delivery,
    require_approval_for_driver_paper_log,
  } = data || {};
  return {
    carrier_uploaded_doc_verification_required: !!carrier_uploaded_doc_verification_required || false,
    driver_uploaded_doc_verification_required: !!driver_uploaded_doc_verification_required || false,
    rename_bill_of_lading_to_proof_of_delivery: !!rename_bill_of_lading_to_proof_of_delivery,
    require_approval_for_driver_paper_log: !!require_approval_for_driver_paper_log,
  };
};
