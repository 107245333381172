import React from 'react';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { SCustomModal } from 'pages/landing/Equipment/Equipment.styles';
import ShippingAddress, { validationSchema } from '../../../ShippingAddress';

const ShippingAddressModal = ({ open, onClose, onSuccess }) => {
  const shippingAddress = JSON.parse(localStorage.getItem('shippingAddress'));
  const initialValues = {
    shipping_address1: shippingAddress?.shipping_address1 || '',
    shipping_address2: shippingAddress?.shipping_address2 || '',
    shipping_country: shippingAddress?.shipping_country || null,
    shipping_state: shippingAddress?.shipping_state || null,
    shipping_city: shippingAddress?.shipping_city || null,
    shipping_zip: shippingAddress?.shipping_zip || '',
  };

  const onSubmit = (values) => {
    localStorage.setItem('shippingAddress', JSON.stringify(values));
    onSuccess(values);
    onClose();
  };

  const addressForm = useForm({ initialValues, validationSchema, onSubmit });

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      onExited={() => addressForm.resetForm()}
      headerTitle='Shipping Address Details'
      $minWidth='500px'
      $height='auto'
      $bgColor={palette.gray0}
      $bodyMaxHeight='calc(100vh - 184px)'
      $footerJustify='space-between'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Submit',
          className: 'modal-submit-btn',
          onClick: addressForm.handleSubmit,
        },
      ]}
    >
      <ShippingAddress {...addressForm} isOnboarding />
    </SCustomModal>
  );
};

export default ShippingAddressModal;
