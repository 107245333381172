import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getCustomerSalesCommissions } from 'Api/Customers';
import ConfirmationModal from 'common/ConfirmationModal';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { deleteSalesCommission } from 'Api/Staff';
import UpdateAgent from 'componentsV2/Customer/SalesAgents/UpdateAgent';
import { useColumns } from './SalesAgents.data';

const SalesAgents = () => {
  const { use } = useTheme();
  const { id } = useParams();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [salesAgents, setSalesAgents] = useState({ data: [] });
  const [agentToUpdate, setAgentToUpdate] = useState(null);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getSalesAgents = async () => {
    setLoading(true);

    try {
      const response = await getCustomerSalesCommissions(id);
      setSalesAgents(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const deleteAgent = async () => {
    setLoadingDelete(true);
    try {
      await deleteSalesCommission({ staffId: agentToDelete.user?.staff?.id, id: agentToDelete.id });
      showToaster({ type: 'success', message: 'Sales agent has been successfully deleted!' });
      getSalesAgents();
      setAgentToDelete(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onEdit = (row) => {
    setAgentToUpdate(row);
  };

  const onDelete = (row) => {
    setAgentToDelete(row);
  };

  const onUpdateSuccess = () => {
    getSalesAgents();
    setAgentToUpdate(null);
  };

  useEffect(() => {
    getSalesAgents();
  }, []);

  const columns = useColumns({ onDelete, onEdit });

  return (
    <div>
      <div className='mb-4'>
        <Typography variant='h2'>Sales Agents</Typography>
      </div>
      {loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          data={salesAgents?.data || []}
          rowPerPage={salesAgents.data?.length}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          columns={columns}
          components={{ Pagination: () => null }}
        />
      )}
      {!!agentToUpdate && (
        <UpdateAgent
          open={!!agentToUpdate}
          onClose={() => setAgentToUpdate(null)}
          agent={agentToUpdate}
          onSuccess={onUpdateSuccess}
          customerId={id}
        />
      )}
      {!!agentToDelete && (
        <ConfirmationModal
          title='Sales Agent'
          open={!!agentToDelete}
          onClose={() => setAgentToDelete(null)}
          onConfirm={deleteAgent}
          disabled={loadingDelete}
        />
      )}
    </div>
  );
};

export default SalesAgents;
