import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './HandbookModal.module.css';
import { Typography } from '../../Typography';

const HandbookModal = ({ show, onClose, data }) => {
  const linkInfo = useMemo(() => {
    let link;
    let videoId;
    let videoStartTime;
    try {
      link = new URL(data?.video);
      videoId = link.searchParams.get('v');
      videoStartTime = link.searchParams.get('t');
    } catch (e) {
      // Do nothing
    }
    return { link, videoStartTime, videoId };
  }, [data]);

  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>{data?.title}</Typography>
        </div>
        <div className={styles.body}>
          {data?.document ? (
            <div className={styles.image_wrapper}>
              <figure className={styles.figure}>
                <iframe className={styles.image} src={data?.document} title='handbook' />
              </figure>
            </div>
          ) : (
            <div className={styles.no_image_wrapper}>
              <Typography variant='overLine'>No documentation uploaded</Typography>
            </div>
          )}
          {data?.video ? (
            <div className={styles.image_wrapper}>
              <iframe
                width='670'
                height='400'
                src={`https://www.youtube.com/embed/${linkInfo.videoId}?start=${linkInfo.videoStartTime}`}
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          ) : (
            <div className={styles.image_wrapper}>
              <figure className={styles.no_figure}>
                <img className={styles.no_image} src='https://www.rrcampus.com/images/no-video.jpg' alt='No Video' />
              </figure>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default HandbookModal;
