import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icons } from 'assets/icons';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { ReactComponent as OneTime } from 'assets/icons/createShipment/oneTime.svg';
import { ReactComponent as RecurringLane } from 'assets/icons/createShipment/recurringLane.svg';
import { CURRENCY, nFormatter, palette, STOP_POINT_TAB } from 'utils/constants';
import { formatAmount, getShipmentType } from 'utils/helpers';
import Chip from 'common/Chip';
import CustomTooltip from 'common/Tooltip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import TableRowsEdit from 'components/TableRowsEdit';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import classes from 'components/TableShipments/tableShipments.module.css';
import { equipmentTypeText, getPlannerStatus } from 'components/TableShipments/helpers/constants';
import {
  PLANNER_PENDING_EDIT,
  PLANNER_PENDING_EDIT_CANCELED,
  PLANNER_ROWS_EDIT_DATA_MASTER_SPLIT,
  SCHEDULED_DATE_TYPE_OBJ,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import hexRgb from 'hex-rgb';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import ShipmentSummary from 'componentsV2/Shipment/ShipmentSummary';
import OverLayShipmentIdCloud from './OverLayShipmentIdCloud';

export const useColumns = ({
  columnWidths,
  animationList,
  rowReserveHandler,
  getPlanner,
  getPlanerFilterData,
  planerData,
  activeTabData,
  rowCheckBoxes,
  filter,
  tableRowCheckboxChange,
  onClickRowIcon,
  onClickTableRowsEditOption,
  sort,
  sortingQuery,
}) => {
  const { formatDate, formatTime, convertToCustomerTime } = useDateFormat();
  const navigate = useNavigate();
  const { use } = useTheme();
  const hasBrokerage = useHasBrokerage();

  return [
    {
      field: '',
      resizable: false,
      width: columnWidths.checkbox,
      title: (
        <div>
          {/* <CustomCheckbox */}
          {/*    labelStyle={{marginTop: 0}} */}
          {/*    checked={rowCheckBoxes.includes('all')} */}
          {/*    onChange={() => tableRowCheckboxChange('all', planerData)} */}
          {/* /> */}
        </div>
      ),
      render: (rowData) => {
        const { status, pending_dispatch } = rowData;

        if (pending_dispatch || status === 'Offered') {
          return null;
        }

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <CustomCheckbox
              labelStyle={{ marginTop: 5 }}
              checked={rowCheckBoxes.some((el) => rowData?.shipment_id === el.shipment_id)}
              onChange={() => tableRowCheckboxChange(rowData?.shipment_id, planerData)}
            />
          </div>
        );
      },
    },
    {
      field: 'shipment_id',
      resizable: true,
      width: columnWidths.shipment_id,
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { shipment_notes } = rowData || {};
        const type = rowData?.shipment_type;
        const shipmentBilling = rowData.shipment_billing;

        return (
          <div>
            <div
              key={rowData.id}
              style={{ display: 'flex', alignItems: 'flex-start' }}
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  rowReserveHandler(rowData);
                }}
                className={classes.rowSaveButtonWrapper}
              >
                <img src={rowData?.shipment_reserve ? Icons.bookmark : Icons.buttonIcon} alt='save' />
              </div>
              <span className='tablePlaner_shipment' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData.shipment_id}
                <ShipmentSummary shipmentId={rowData.shipment_id} className='ms-1' />
              </span>
              <div className='flex-row d-flex align-items-center icons-wrapper ' onClick={(e) => e.stopPropagation()}>
                {Array.isArray(shipment_notes) && shipment_notes?.length > 0 && (
                  <div onClick={(e) => onClickRowIcon(e, rowData, 'notes')}>
                    <NotesIcon width={14} height={14} style={{ marginLeft: 4, flexShrink: 0 }} />
                  </div>
                )}
                {(rowData?.is_edi_api === 1 || rowData?.manual_edi_api === 1) && (
                  <OverLayShipmentIdCloud
                    rowData={rowData}
                    onClickButtons={getPlanner}
                    allFilterData={{ ...getPlanerFilterData, ...activeTabData?.data?.filters }}
                  />
                )}
              </div>
            </div>
            {(shipmentBilling && type !== 'ltl') || (type === 'ltl' && shipmentBilling.length === 1) ? (
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: use(palette.dark800, palette.gray200) }}
              >
                {shipmentBilling?.[0]?.reference_id}
              </Typography>
            ) : (
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='button-tooltip-2'>
                    {shipmentBilling?.map((el, i) => (
                      <p key={el?.id || i} style={{ margin: 0 }}>
                        {el?.reference_id}
                      </p>
                    ))}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div ref={ref} className='ellipsis'>
                    <Typography
                      variant='s3'
                      {...triggerHandler}
                      className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Multi-Ref LTL
                    </Typography>
                  </div>
                )}
              </OverlayTrigger>
            )}
            <br />
            <div className='d-flex align-items-center gap-1'>
              <Typography variant='s3'>{getShipmentType(rowData.shipment_type)}</Typography>
              {(!rowData.shipment_type || rowData.shipment_type === 'ltl') &&
                (rowData.lane_id ? <RecurringLane fill={palette.indigo500} /> : <OneTime fill={palette.indigo500} />)}
              {!!rowData.reverted && (
                <CustomTooltip title='Reverted'>
                  <ReloadIcon fill={palette.red500} />
                </CustomTooltip>
              )}
              {rowData.shipment_stops?.some((i) => i.hazardous_materials) && (
                <Typography variant='c1' style={{ color: palette.orange400 }}>
                  HAZMAT
                </Typography>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'customer',
      resizable: true,
      width: columnWidths.customer,
      title: <ColumnHeader title='CUSTOMER' field='customer' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const type = rowData?.shipment_type;
        const shipmentBilling = rowData.shipment_billing;
        return shipmentBilling && shipmentBilling.length && shipmentBilling.length === 1 ? (
          <Typography
            variant='s3'
            className={`${animationList[rowData.id] ? classes.rowData_wrapper : 'customerWrapper'}`}
            style={{ color: use(palette.dark800, palette.gray200) }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/customer-profile/${shipmentBilling?.[0]?.billing_customer?.id}`);
            }}
          >
            {shipmentBilling?.[0]?.billing_customer?.company_name}&nbsp;
            {type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
          </Typography>
        ) : shipmentBilling && shipmentBilling.length > 1 ? (
          <CustomTooltip
            arrow={false}
            title={
              <div>
                {shipmentBilling.map((el, i) => (
                  <Typography
                    key={el?.id || i}
                    as='p'
                    variant='s2'
                    className='customerWrapper'
                    style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/customer-profile/${el?.billing_customer?.id}`);
                    }}
                  >
                    {el?.billing_customer?.company_name}
                  </Typography>
                ))}
              </div>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 13,
                  fontFamily: 'Inter',
                  color: palette.gray700,
                  bgcolor: palette.white,
                  border: '1px solid #dadde9',
                  marginTop: '4px !important',
                },
              },
            }}
          >
            <div>
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                Multi-Customer LTL
              </Typography>
            </div>
          </CustomTooltip>
        ) : null;
      },
    },
    {
      field: 'type',
      resizable: true,
      width: columnWidths.type,
      title: <Typography variant='c3'>TYPE</Typography>,
      render: (rowData) => {
        const typeLength = rowData.shipment_stops[0]?.equipment_type_length_value?.length;
        const equipmentType = rowData.shipment_stops[0]?.equipment_type_data?.title;
        const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;
        const firstNullArrivalDate = rowData.shipment_stops.find((stop) => !stop.arrival_date);

        return (
          <div className='d-flex flex-column'>
            <span
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              {type}
            </span>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              {!firstNullArrivalDate?.stop_point_type
                ? '-'
                : equipmentTypeText({
                    type: firstNullArrivalDate.stop_point_type,
                    action: firstNullArrivalDate.equipment_action,
                    equipment: firstNullArrivalDate.equipment,
                    equipment_tbd: firstNullArrivalDate.equipment_tbd,
                  })}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'amount',
      resizable: true,
      width: columnWidths.amount,
      title: <ColumnHeader title='AMOUNT' field='amount' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        let totalAmount = 0;

        rowData?.shipment_billing?.forEach((el) => {
          totalAmount += Number(el?.total_amount);
        });
        const amount = +filter.switchRadioButtons.amount;
        const formattedAmount = amount === 2 ? nFormatter(totalAmount) : formatAmount(totalAmount.toFixed(2));
        const currency = Object.values(CURRENCY).find(
          (item) => item.id === Number(rowData?.shipment_billing[0]?.currency_id)
        );

        return (
          <Typography
            variant='s3'
            className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
            style={{ color: use(palette.dark800, palette.gray200) }}
          >
            {currency?.symbol || '$'}
            {formattedAmount}
          </Typography>
        );
      },
    },
    {
      field: 'groups',
      resizable: true,
      width: columnWidths.groups,
      title: <ColumnHeader title='TAGS' field='groups' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const shipmentBilling = rowData.shipment_billing?.filter((item) => !!item.groups);
        return shipmentBilling?.map((el, i) => {
          const colorCode = hexRgb(el.groups.color);
          const textColor =
            colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? palette.white : palette.gray900;
          return (
            <div
              key={el?.id || i}
              className={`flex-row d-flex table_planer_groups_wrapper ${
                animationList[rowData.id] ? classes.rowData_wrapper : ''
              }`}
            >
              <div
                className={`${classes.shortName}`}
                style={{
                  color: textColor,
                  backgroundColor: el?.groups?.color,
                }}
              >
                {el?.groups?.short_name}
              </div>
            </div>
          );
        });
      },
    },
    {
      field: 'stops',
      resizable: true,
      center: true,
      width: columnWidths.stops,
      title: <ColumnHeader title='STOPS' field='stops' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const stops = rowData.shipment_stops.length;
        return (
          <div
            className={`${
              animationList[rowData.id]
                ? 'rowData_wrapper d-flex justify-content-center'
                : ' d-flex justify-content-center'
            }`}
            style={{ color: use(palette.dark800, palette.gray200) }}
          >
            {stops}
          </div>
        );
      },
    },
    {
      field: 'status',
      resizable: true,
      center: true,
      width: columnWidths.status,
      title: <ColumnHeader title='STATUS' field='status' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { bgStatus, colorStatus, statusName } = getPlannerStatus(rowData) || {};
        return (
          <div className={`d-flex gap-1 justify-content-start ${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}>
            <div className='d-flex flex-column gap-1'>
              <Chip
                label={statusName?.toUpperCase()}
                labelColor={colorStatus}
                bgColor={bgStatus}
                fontSize='11px'
                fontWeight={500}
              />
              {[3, 4, 5].includes(activeTabData?.id) && statusName !== 'BEHIND SCHEDULE' && (
                <Chip label='BEHIND SCHEDULE' labelColor='#D12953' bgColor='#FAF0F3' fontSize='11px' fontWeight={500} />
              )}
            </div>
            {rowData.status === 'Offered' && (
              <CustomTooltip title='Bids Count'>
                <span>
                  <Chip label={rowData.bids_count || 0} radius='12px' />
                </span>
              </CustomTooltip>
            )}
          </div>
        );
      },
    },
    {
      field: 'origin_stop_name',
      resizable: true,
      width: columnWidths.origin_stop_name,
      title: <Typography variant='c3'>ORIGIN</Typography>,
      render: (rowData) => {
        const originStopName = rowData.shipment_stops[0]?.stop_point?.location_name;
        const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
        const id = rowData.shipment_stops[0]?.stop_point?.id;
        const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[0]?.stop_point?.zipcode;

        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const scheduledOriginTo = rowData.shipment_stops[0].scheduled_date_to;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;
        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOriginTo} ${toData || ''}`;

        return (
          <>
            <Typography
              variant='b3'
              className={classes.originNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {originStopName}{' '}
            </Typography>
            <br />
            <span
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
              <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
              {!!toData && [1, 3].includes(Number(typeId)) && (
                <>
                  <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                  <Typography variant='b2'>{formatTime(to)}</Typography>
                </>
              )}
              <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
            </>
          </>
        );
      },
    },
    {
      field: 'destination_stop_name',
      resizable: true,
      width: columnWidths.destination_stop_name,
      title: <Typography variant='c3'>DESTINATION</Typography>,
      render: (rowData) => {
        const destinationStopName =
          rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.location_name;
        const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;
        const id = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.id;

        const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
        const scheduledDestinationTo = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date_to;
        const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
        const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;
        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestinationTo} ${toData || ''}`;

        return (
          <>
            <Typography
              variant='b3'
              className={classes.destinationNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {destinationStopName}{' '}
            </Typography>
            <br />
            <span
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
              <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
              {!!toData && [1, 3].includes(Number(typeId)) && (
                <>
                  <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                  <Typography variant='b2'>{formatTime(to)}</Typography>
                </>
              )}
              <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
            </>
          </>
        );
      },
    },
    {
      field: 'miles',
      resizable: true,
      width: columnWidths.miles,
      title: <ColumnHeader title='MILES' field='miles' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const miles = Math.round(rowData?.loaded_miles);
        return (
          <span
            className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
            style={{ color: use(palette.dark800, palette.gray200) }}
          >
            {miles && miles}
          </span>
        );
      },
    },
    {
      field: 'created_by',
      resizable: true,
      width: columnWidths.created_by,
      title: <Typography variant='c3'>CREATED BY</Typography>,
      render: (rowData) => {
        const { created_by, api_name } = rowData;
        return (
          <div className='d-flex flex-column'>
            {api_name ? (
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : 'createdByWrapper'}`}
                style={{ color: use(palette.dark800, palette.gray200) }}
              >
                {api_name}
              </span>
            ) : (
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : 'createdByWrapper'}`}
                style={{ color: use(palette.dark800, palette.gray200) }}
                onClick={(e) => {
                  if (created_by.id) {
                    e.stopPropagation();
                    navigate(`/staff-profile/${created_by.id}/general`);
                  }
                }}
              >
                {created_by?.first_name} {created_by?.last_name}
              </span>
            )}
            <div className={`flex-row d-flex ${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}>
              <span style={{ color: use(palette.dark800, palette.gray200) }}>
                {convertToCustomerTime(rowData.created_at)}
              </span>
              <div className='tablePlaner_cloud_container' />
            </div>
          </div>
        );
      },
    },
    {
      field: '',
      resizable: false,
      width: columnWidths.threeDotes,
      title: '',
      render: (rowData) => {
        let options = PLANNER_PENDING_EDIT;

        if (
          rowData.shipment_type === 'ltl' ||
          rowData.shipment_type === 'slave' ||
          rowData.shipment_type === 'split-parent'
        ) {
          options = options.filter((el) => +el.id !== 8);
        }

        if (rowData.canceled_at) {
          options = PLANNER_PENDING_EDIT_CANCELED;
        }

        if ((rowData.is_edi_api || rowData.manual_edi_api) && (!!rowData.declined_at || !!rowData.expired_at)) {
          options = PLANNER_PENDING_EDIT_CANCELED.filter((el) => +el.id !== 125);
        }

        if (!hasBrokerage) {
          options = options.filter((el) => +el.id !== 22);
        }

        if (rowData.shipment_type === 'split-parent') {
          options = PLANNER_ROWS_EDIT_DATA_MASTER_SPLIT;
        }

        rowData?.shipment_reserve
          ? (options = options.filter((el) => +el.id !== 12))
          : (options = options.filter((el) => +el.id !== 112));

        if (rowData.status === 'Offered') {
          options = options.filter((el) => +el.id !== 20 && +el.id !== 21);
        } else {
          options = options.filter((el) => +el.id !== 22);
        }

        if (rowData.shipment_type !== 'split-children') {
          options = options.filter((el) => +el.id !== 24);
        }

        if (rowData.shipment_type === 'slave' || rowData.shipment_type === 'split-parent') {
          options = options.filter((el) => +el.id !== 5);
        }

        return (
          <div className='table_edit'>
            <TableRowsEdit
              placement='bottom-end'
              overlayStyle={{ width: 193 }}
              options={options}
              onClickOption={(e, i) => onClickTableRowsEditOption(e, i, rowData, 'modal')}
            />
          </div>
        );
      },
    },
  ];
};
