import React, { useState } from 'react';
import { ReactComponent as TopArrow } from 'assets/icons/tableSortArrowTop.svg';
import { ReactComponent as DownArrow } from 'assets/icons/tableSortArrowDown.svg';
import { ReactComponent as TopArrowActive } from 'assets/icons/tableSortArrowTopActive.svg';
import { ReactComponent as DownArrowActive } from 'assets/icons/tabelSortArrowDownActive.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

export const DocumentTableSort = ({ title, field, sortingQuery, nested_field }) => {
  const [sortBy, setSortBy] = useState(field === 'service_date_time' ? 'asc' : 'desc');
  const { use } = useTheme();
  return (
    <div
      className='columns_item_wrapper'
      onClick={() => {
        setSortBy((p) =>
          field === 'service_date_time' ? (p === 'desc' ? 'asc' : 'desc') : p === 'asc' ? 'desc' : 'asc'
        );
        sortingQuery(field, sortBy, nested_field);
      }}
    >
      <span
        className='equipment_table_title'
        style={{ color: use(palette.gray900, palette.white), textTransform: 'uppercase' }}
      >
        {title}
      </span>
      <span className='equipment_table_custom_sorting_icons'>
        {sortBy !== 'asc'
          ? use(<TopArrowActive style={{ marginBottom: 1 }} />, <TopArrow style={{ marginBottom: 1 }} />)
          : use(<TopArrow style={{ marginBottom: 1 }} />, <TopArrowActive style={{ marginBottom: 1 }} />)}
        {sortBy !== 'asc'
          ? use(<DownArrow style={{ marginTop: 1 }} />, <DownArrowActive style={{ marginBottom: 1 }} />)
          : use(<DownArrowActive style={{ marginBottom: 1 }} />, <DownArrow style={{ marginTop: 1 }} />)}
      </span>
    </div>
  );
};
