import React, { useEffect, useState } from 'react';
import Loader from 'common/Loader';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import TableFooter from '../../../ApplicantsAndReferrals/components/TableFooter';
import AlertPopover from '../components/AlertPopover';
import { tableDataConverter } from '../ImportSamsaraTrailers/converters';
import { SCustomModal, STable, STableData } from '../Equipment.styles';

const ImportTrailersPreview = ({ open, onClose, data, isSamsara, onSubmit }) => {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [alertItem, setAlertItem] = useState(null);
  const [pagination, setPagination] = useState({ rowPerPage: 500, page: 1 });
  const [currentData, setCurrentData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const alertPopupOpen = Boolean(anchorEl);

  const handleSubmit = () => {
    onSubmit(tableData);
  };

  const resetState = () => {
    setTableData([]);
    setPagination({ rowPerPage: 500, page: 1 });
    setCurrentData([]);
    setAlertItem(null);
  };

  const onPageChange = (page) => {
    setPagination((prevState) => ({ ...prevState, page }));
    setCurrentData(tableData.slice((page - 1) * pagination.rowPerPage, page * pagination.rowPerPage));
  };

  const onRowPerPageChange = (rowPerPage) => {
    setPagination({ page: 1, rowPerPage });
    setCurrentData(tableData.slice(0, rowPerPage));
  };

  useEffect(() => {
    (async () => {
      if (open) {
        setLoading(true);
        const convertedData = tableDataConverter(data);
        setTableData(convertedData);
        const current = convertedData.slice(0, pagination.rowPerPage);
        setCurrentData(current);
        setLoading(false);
      }
    })();
  }, [open]);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      onExited={resetState}
      headerTitle='Import Trailers'
      $minWidth='80vw'
      $maxWidth='80vw'
      $height='auto'
      $minHeight='60vh'
      $bodyMaxHeight='calc(100vh - 184px)'
      backdrop='static'
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', className: 'modal-cancel-btn' },
        {
          key: 'submit',
          type: 'primary',
          title: `Next`,
          className: 'modal-submit-btn',
          disabled: !currentData?.length,
          onClick: () => handleSubmit(),
        },
      ]}
    >
      <Loader loading={loading} />
      {!loading && (
        <STable>
          <thead>
            <tr className='header-row'>
              <th>EQUIPMENT ID</th>
              {isSamsara && <th>SAMSARA SERIAL</th>}
              <th>LICENSE PLATE NUMBER</th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item) => (
              <tr className='body-row' key={item.id}>
                <STableData>
                  <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                    {item.equipment_id}
                  </Typography>
                </STableData>
                {isSamsara && (
                  <STableData>
                    <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                      {item.serial_id}
                    </Typography>
                  </STableData>
                )}
                <STableData>
                  <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                    {item.license_plate_number}
                  </Typography>
                </STableData>
              </tr>
            ))}
          </tbody>
        </STable>
      )}
      {!currentData.length && !loading && <NoRecords />}
      <TableFooter
        rowPerPage={pagination.rowPerPage}
        totalCount={tableData.length}
        totalLength={currentData.length}
        lastPage={Math.ceil(tableData.length / pagination.rowPerPage)}
        currentPage={pagination.page}
        onChangeRowPerPage={onRowPerPageChange}
        onPageChange={onPageChange}
      />
      <AlertPopover
        open={alertPopupOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAlertItem(null);
          setAnchorEl(null);
        }}
        data={alertItem}
      />
    </SCustomModal>
  );
};

export default ImportTrailersPreview;
