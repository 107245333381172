import moment from 'moment';

export const getBidExpirationData = (offerOptions) => {
  const postedDate = moment.utc(offerOptions.posted_date);

  const expire_date = moment(postedDate).add(
    offerOptions.expire_shipment_offer,
    offerOptions.expire_shipment_offer_unit
  );

  const totalDiff = expire_date.diff(postedDate, 'minutes');
  const minutesPassed = moment().diff(postedDate, 'minutes');

  return { expire_date, totalDiff, minutesPassed };
};

export const getAwardExpirationData = (offerOptions, awardDate) => {
  const awardDateUTC = moment.utc(awardDate);

  const expire_date = moment(awardDateUTC).add(
    offerOptions.retract_carrier_award,
    offerOptions.retract_carrier_award_unit
  );

  const totalDiff = expire_date.diff(awardDateUTC, 'minutes');
  const minutesPassed = moment().diff(awardDateUTC, 'minutes');

  return { expire_date, totalDiff, minutesPassed };
};
