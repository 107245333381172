import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { getBrokerageSetting } from 'Api/CompanySettings';
import { SSnackbar } from 'components/Carriers/ConfigRequired/ConfigRequired.styles';

const ConfigRequired = () => {
  const navigate = useNavigate();
  const { value } = useAuth();
  const [openNotification, setOpenNotification] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);

  const checkBrokerageSettings = async () => {
    setLoadingSettings(true);
    try {
      const { data } = await getBrokerageSetting();
      setOpenNotification(!data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingSettings(false);
    }
  };

  const onSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  useEffect(() => {
    if (!value?.user?.id) {
      return;
    }

    checkBrokerageSettings();
  }, [value?.user?.id, value?.shouldUpdateBrokerage]);

  if (!value?.user?.id || loadingSettings) {
    return null;
  }

  return (
    <div>
      <SSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotification}
        onClose={onSnackbarClose}
        message={
          <div className='d-flex justify-content-between align-items-center gap-3'>
            <Typography variant='s1' style={{ color: palette.gray700 }}>
              Please setup and configure your broker addon!{' '}
              <span
                className='navigate-button'
                onClick={() => navigate('/company-settings', { state: { tabId: 15, subTabName: 'Brokerage' } })}
              >
                Configure
              </span>
            </Typography>
          </div>
        }
      />
    </div>
  );
};

export default ConfigRequired;
