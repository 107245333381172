import { formatNumber, palette } from 'utils/constants';

export const inspectionsOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 1.5,
  tension: 0.2,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label}`;
        },
        label(context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += `${formatNumber(context.parsed.y, 0)}`;
          }
          return label;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      boxPadding: 3,
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        // callback(value, index, values) {
        //   // if (index === 0) {
        //   //   return moment().startOf('year').format('MMM YYYY');
        //   // }
        //   //
        //   // // For the last tick, return the current month and year (as per your original logic)
        //   // if (index === values.length - 1) {
        //   //   return moment().format('MMM YYYY');
        //   // }
        //
        //   // if (values[index] && values[index].label) {
        //   //   return moment(values[index].label, 'MMMM YYYY').format('MMM YYYY');
        //   // }
        //
        //   // For all other ticks, return the corresponding label from your dataset
        //   // return value;
        // },
      },
    },
    y: {
      suggestedMax: 10,
      beginAtZero: true,
      grid: {
        borderDash: [8, 8],
        color: palette.gray50,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
});
