import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import Search from 'common/Search';
import CustomButton from 'components/CustomButton/CustomButton';
import { getCustomersList } from 'Api/Customers';
import { getDrivers } from 'Api/EquipmentProfile';
import { getAllTrailers, getAllVehicles } from 'Api/Map';
import AdvancedFilter from '../AdvancedFilter';
import { SWrapper } from './TableHeader.styles';

const TableHeader = ({ search, setSearch, selectedFilters, setSelectedFilters, loading }) => {
  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [openAdvanced, setOpenAdvanced] = useState(false);

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList();
      setCustomers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getVehiclesList = async () => {
    try {
      const { data } = await getAllVehicles(null, null, null, null);
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getTrailersList = async () => {
    try {
      const { data } = await getAllTrailers(null, null, null, null);
      setTrailers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomers();
    getDriversList();
    getVehiclesList();
    getTrailersList();
  }, []);

  return (
    <SWrapper>
      <div className='d-flex align-items-center gap-3'>
        <Search search={search} onChange={setSearch} width='250px' />
        <CustomButton
          type='primary'
          title=''
          leftIcon={
            openAdvanced ? (
              <MinusIcon fill='#fff' style={{ margin: 8 }} />
            ) : (
              <PlusIcon fill='#fff' style={{ margin: 8 }} />
            )
          }
          className='ms-3'
          styleButton={{ height: 28, margin: 0 }}
          onClick={() => setOpenAdvanced((prevState) => !prevState)}
        />
      </div>
      {openAdvanced && (
        <AdvancedFilter
          filters={selectedFilters}
          updateFilters={setSelectedFilters}
          loading={loading}
          customers={customers}
          drivers={drivers}
          vehicles={vehicles}
          trailers={trailers}
        />
      )}
    </SWrapper>
  );
};

export default TableHeader;
