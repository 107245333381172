import axios from 'services/axios';

export async function getAccidentsTabsCount(params) {
  const { data } = await axios.get(`customer/safety/accidents/tabs-count`, { params });
  return data;
}

export async function getDayOfWeekGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/days-of-week`, { params });
  return data;
}

export async function getAgeGroupGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/age-group`, { params });
  return data;
}

export async function getTimeOfDayGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/time-of-day`, { params });
  return data;
}

export async function getSpeedLimitGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/speed-limit`, { params });
  return data;
}

export async function getMonthOfCollisionGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/month-of-collision`, { params });
  return data;
}

export async function getTypeOfCollisionGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/type-of-collision`, { params });
  return data;
}

export async function getWeatherFactorGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/weather-factor`, { params });
  return data;
}

export async function getCollisionCostGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/collision-cost`, { params });
  return data;
}

export async function getLengthOfEmploymentGraph(params) {
  const { data } = await axios.get(`customer/safety/accidents/graph-data/length-of-employment`, { params });
  return data;
}

export async function getDriverExperience(params) {
  const { data } = await axios.get(`/customer/safety/accidents/graph-data/driver-experience`, { params });
  return data;
}

export async function getControlDevice(params) {
  const { data } = await axios.get(`/customer/safety/accidents/graph-data/control-device`, { params });
  return data;
}

export async function getDOTRecordable(params) {
  const { data } = await axios.get(`/customer/safety/accidents/graph-data/dot-recordable`, { params });
  return data;
}

export async function getAccidents(params, signal) {
  const { data } = await axios.get(`/customer/safety/accidents`, { params, signal });
  return data;
}

export async function getDriverStatus(params) {
  const { data } = await axios.get(`/customer/safety/accidents/graph-data/driver-status`, { params });
  return data;
}

export async function getCrashTypes(params) {
  const { data } = await axios.get(`/customer/safety/accidents/types-of-crash`, { params });
  return data;
}

export async function getControlDeviceTypes(params) {
  const { data } = await axios.get(`/customer/safety/accidents/control-device-type`, { params });
  return data;
}

export async function getRoadTypes(params) {
  const { data } = await axios.get(`/customer/safety/accidents/road-type`, { params });
  return data;
}

export async function getRoadConditions(params) {
  const { data } = await axios.get(`/customer/safety/accidents/road-conditions`, { params });
  return data;
}

export async function getRoadControl(params) {
  const { data } = await axios.get(`/customer/safety/accidents/road-control`, { params });
  return data;
}

export async function getTypeOfWeather(params) {
  const { data } = await axios.get(`/customer/safety/accidents/weather-type`, { params });
  return data;
}

export async function getLightCondition(params) {
  const { data } = await axios.get(`/customer/safety/accidents/light-condition`, { params });
  return data;
}

export async function getAllIncidents(params, signal) {
  const { data } = await axios.get(`driver/incidents`, { params, signal });
  return data;
}

// Step 1. Create Collision
export async function createCollision(body) {
  const { data } = await axios.post(`/customer/safety/accidents/collision`, body);
  return data;
}

// Step 2. Create Accident Report
export async function createAccidentReport(id, body) {
  const { data } = await axios.post(`/customer/safety/accidents/${id}/report`, body);
  return data;
}

export async function createWitness(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/witness`, body);
  return data;
}

// Step 3. Create Cost Analysis
export async function createCostAnalysis(id, body) {
  const { data } = await axios.post(`/customer/safety/accidents/${id}/cost-analysis`, body);
  return data;
}

// Add Vehicle Collision Cost
export async function createVehicleCollisionCost(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/vehicle-damage`, body);
  return data;
}

// Add Vehicle Damage Photo
export async function createVehicleDamagePhoto(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/vehicle-damage-photos`, body);
  return data;
}

// Delete Vehicle Damage Photo
export async function deleteVehicleDamagePhoto(id) {
  const { data } = await axios.delete(`/customer/safety/accident/vehicle-damage-photos/${id}`);
  return data;
}

// Add Trailer Collision Cost
export async function createTrailerCollisionCost(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/trailer-damage`, body);
  return data;
}

// Trailer Damage Photo
export async function createTrailerDamagePhoto(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/trailer-damage-photos`, body);
  return data;
}

// Delete Vehicle Damage Photo
export async function deleteTrailerDamagePhoto(id) {
  const { data } = await axios.delete(`/customer/safety/accident/trailer-damage-photos/${id}`);
  return data;
}

// Add Cargo Collision Cost
export async function createCargoCollisionCost(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/cargo-damage`, body);
  return data;
}

// Cargo Damage Photo
export async function createCargoDamagePhoto(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/cargo-damage-photos`, body);
  return data;
}

// Cargo Vehicle Damage Photo
export async function deleteCargoDamagePhoto(id) {
  const { data } = await axios.delete(`/customer/safety/accident/cargo-damage-photos/${id}`);
  return data;
}

// Add Liability Collision Cost
export async function createLiabilityDamageCost(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/liability-damage`, body);
  return data;
}

// Liability Damage Photo
export async function createLiabilityDamagePhoto(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/liability-damage-photos`, body);
  return data;
}

// Liability Vehicle Damage Photo
export async function deleteLiabilityDamagePhoto(id) {
  const { data } = await axios.delete(`/customer/safety/accident/liability-damage-photos/${id}`);
  return data;
}

// Step 4. Create Checklist
export async function createChecklist(id, body) {
  const { data } = await axios.post(`/customer/safety/accidents/${id}/checklist`, body);
  return data;
}

// get Accident
export async function getAccident(id) {
  const { data } = await axios.get(`/customer/safety/accident/${id}`);
  return data;
}

// Step 1. Update
export async function updateCollision(id, body) {
  const { data } = await axios.post(`/customer/safety/accidents/${id}/collision`, body);
  return data;
}

export async function getSearchAutocomplete(params) {
  const { data } = await axios.get(`customer/search-autocomplete`, { params });
  return data;
}

export async function getStatusTypes() {
  const { data } = await axios.get(`customer/safety/accidents/status`, {});
  return data;
}

// Logbook
export async function createLogbook(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/${id}/logbook`, body);
  return data;
}

export async function updateLogbook(id, body) {
  const { data } = await axios.post(`/customer/safety/accident/logbook/${id}`, body);
  return data;
}

export async function deleteLogbook(id) {
  const { data } = await axios.delete(`/customer/safety/accident/logbook/${id}`);
  return data;
}
