import styled from 'styled-components';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import { palette } from 'utils/constants';

export const SSubmitBid = styled.div`
  height: calc(100% - 114px);

  .form-wrapper {
    height: ${({ $expanded }) => ($expanded ? '100%' : 'calc(100% - 57px)')};
    overflow: auto;
    padding: 4px 6px 24px 2px;

    ::-webkit-scrollbar {
      width: 8px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${palette.gray200};
      border-radius: 8px;
    }
  }

  .form-row {
    display: flex;
    margin-bottom: 18px;

    & > div:first-child {
      width: 170px;
    }
  }

  .submit-bid-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }

  .confirmation-button {
    background: ${palette.green500};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);

    :hover {
      background: ${palette.green600} !important;
    }
  }

  .react-timer-picker_wrapper {
    width: 72px !important;
    height: 26px !important;
    margin: 0 !important;

    .react-time-input-picker-wrapper {
      border: none;
      height: 100%;
      margin: 0;
      padding: 0;

      .is-disabled {
        background: ${palette.gray0};
      }
    }
  }

  .can-handle-box {
    width: 196px;
  }

  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
      row-gap: 4px;
    }
  }
`;

export const STimeline = styled(Timeline)`
  &.MuiTimeline-root {
    position: relative;

    .MuiTimelineContent-root {
      padding: 6px 10px;
    }

    .miles {
      display: flex;
      column-gap: 12px;
      position: absolute;
      transform: rotate(-90deg);
      top: 90px;
      left: -66px;
    }

    .timeline-content {
      display: flex;
      justify-content: space-between;
      column-gap: 8px;
    }

    @media (max-width: 768px) {
      &.MuiTimeline-root {
        padding: 6px;

        .timeline-content {
          flex-direction: column;
          row-gap: 8px;
        }
      }
    }
  }
`;

export const STimelineDot = styled(TimelineDot)`
  &&.MuiTimelineDot-root {
    margin: 6px 0;
    border: 2px solid ${palette.white};
    background: ${({ $bgColor }) => $bgColor || palette.green500};
    color: ${palette.white};
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    box-shadow: 0 2px 5px 0 rgba(89, 96, 120, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
