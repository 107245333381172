import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';

const createFooter = (doc) => {
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Driver Name', 8, top);
  doc.text('Date', 60, top, { align: 'center' });
  doc.text('Results', 110, top, { align: 'center' });
  doc.text('Type', 160, top, { align: 'center' });
  doc.text('Reason', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createTableRow = (doc, data, top, formatDate) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${data.driver?.full_name || '-'}`, 8, top);
  doc.text(`${data.drug_checked_date ? formatDate(data.drug_checked_date) : '-'}`, 60, top, { align: 'center' });
  doc.text(`${data.drug_check_results || '-'}`, 110, top, { align: 'center' });
  doc.text(`${data.type || '-'}`, 160, top, { align: 'center' });
  doc.text(`${data.reason || '-'}`, pageWidth - 8, top, { align: 'right' });
};

const createTableRows = (doc, records, formatDate) => {
  if (!records?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  records.forEach((record, index) => {
    createTableRow(doc, record, top + index * 5, formatDate);
  });

  return top + records.length * 5;
};

export const generatePDF = async (reportItems, dateRange, formatDate, convertToCustomerTime) => {
  if (!reportItems?.length) {
    return;
  }

  const { start, end } = dateRange;

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Drug and Alcohol Report', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      28,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, 28, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const firstPageRecords = reportItems.slice(0, 40);
  const restRecords = reportItems.slice(40);

  createTableRows(doc, firstPageRecords, formatDate);

  for (let i = 0; i < (restRecords?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageRecords = restRecords.slice(i * 45, (i + 1) * 45);
    createTableRows(doc, currentPageRecords, formatDate);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    doc.setFontSize(12);
    createFooter(doc);
    createTableHeader(doc);
  }

  doc.setDocumentProperties({
    title: `Drug and Alcohol Report - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}`,
  });

  const url = doc.output('datauristring', { filename: 'Drug and Alcohol Report' });
  const blob = doc.output('blob');

  return { blob, url };
};
