import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download2.svg';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getVehicleInventory, updateVehicleInventory } from 'Api/Equipment';
import ViewPhotos from './ViewPhotos';
import AddInventory from './AddInventory';
import { generatePDF } from './generatePdf';
import { useColumns } from './InventoryList.data';
import { SWrapper } from './InventoryList.styles';

const InventoryList = ({ open, onClose, equipmentId, idOfEquipment, equipmentType }) => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [inventory, setInventory] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [openAddInventory, setOpenAddInventory] = useState(false);
  const [imagesToView, setImagesToView] = useState([]);

  const getInventoryList = async () => {
    try {
      const params = {
        page: 1,
        itemsPerPage: 10000,
        'filters[equipment_id]': equipmentId,
      };
      setLoading(true);
      const response = await getVehicleInventory(params);
      setInventory(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onRemove = async (id) => {
    try {
      await updateVehicleInventory(id, { deleted_at: moment().utc().format('YYYY-MM-DD HH:mm:ss') });
      showToaster({ type: 'success', message: 'Inventory has been successfully removed!' });
      getInventoryList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onExportPdf = () => {
    generatePDF(inventory.data, idOfEquipment, equipmentType, convertToCustomerTime, currency);
  };

  const onExportCsv = () => {
    if (!inventory?.data?.length) {
      return;
    }

    const titles = {
      NAME: 'NAME',
      DESCRIPTION: 'DESCRIPTION',
      QTY: 'QTY',
      'COST PER QTY': 'COST PER QTY',
      'TOTAL VALUE': 'TOTAL VALUE',
      'DATE ADDED': 'DATE ADDED',
      'DATE REMOVED': 'DATE REMOVED',
      OWNERSHIP: 'OWNERSHIP',
      'UPDATED BY': 'UPDATED BY',
    };

    const rows = inventory?.data?.map((item) => ({
      NAME: item.name || '',
      DESCRIPTION: item.description || '',
      QTY: item.quantity || '',
      'COST PER QTY': item.cost_per_quantity || '',
      'TOTAL VALUE': item.total_value || '',
      'DATE ADDED': `${item.created_at ? convertToCustomerTime(item.created_at) : ''}`,
      'DATE REMOVED': `${item.deleted_at ? convertToCustomerTime(item.deleted_at) : ''}`,
      OWNERSHIP: item.ownership || '',
      'UPDATED BY': `${item.updated_by.first_name || item.updated_by.fname} ${
        item.updated_by.last_name || item.updated_by.lname
      }`,
    }));

    const arrayToConvert = [titles, ...rows];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('download', 'Inventory-List');
    link.href = url;
    link.click();
  };

  useEffect(() => {
    getInventoryList();
  }, []);

  const columns = useColumns({ onRemove, setImagesToView });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Equipment Inventory List'
      $minHeight='500px'
      $maxHeight='90vh'
      $minWidth='800px'
      $maxWidth='90vw'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      <SWrapper>
        <div className='d-flex justify-content-end w-100 gap-2'>
          <div className='download-icon-text'>
            <DownloadIcon />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Export:
            </Typography>
          </div>
          <CustomButton className='action-button' title='PDF' type='secondary' onClick={onExportPdf} />
          <CustomButton className='action-button' title='CSV' type='secondary' onClick={onExportCsv} />
          <CustomButton
            type='primary'
            title='Add Inventory'
            leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={() => setOpenAddInventory(true)}
          />
        </div>
        <div className='w-100'>
          {loading ? (
            <TablePreLoader styleWrapper={{ marginTop: 0 }} />
          ) : (
            <MaterialTableWrapper
              data={inventory.data}
              style={{ backgroundColor: palette.white }}
              components={{ Pagination: () => null }}
              columns={columns}
            />
          )}
        </div>
      </SWrapper>
      {openAddInventory && (
        <AddInventory
          open={openAddInventory}
          onClose={() => setOpenAddInventory(false)}
          equipmentId={equipmentId}
          onSuccess={getInventoryList}
        />
      )}
      {!!imagesToView.length && (
        <ViewPhotos open={!!imagesToView.length} onClose={() => setImagesToView([])} images={imagesToView} />
      )}
    </Modal>
  );
};

export default InventoryList;
