import React from 'react';
import { useSelector } from 'react-redux';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import RowActions from 'pages/Accounting/Payables/TableSection/shared/RowActions';

export const useColumns = ({
  allSelected,
  selectedRows,
  hideSelectAll,
  handleSelectAll,
  handleSelectRow,
  sort,
  sortingQuery,
  onView,
  onDelete,
  onRevertToUnpaid,
  onClickRowItem,
}) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: '',
      title: !hideSelectAll ? <CustomCheckbox onChange={handleSelectAll} checked={allSelected} /> : '',
      render: (row) => (
        <span onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.id === row.id)}
          />
        </span>
      ),
    },
    {
      field: 'paid_date',
      title: <ColumnHeader title='PAID DATE' field='paid_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {formatDate(row.paid_date)}
        </Typography>
      ),
    },
    {
      field: 'vendor',
      title: <ColumnHeader title='VENDOR' field='vendor_id' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700) }}>
          {row.vendor?.name || '-'}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      title: <ColumnHeader title='DUE DATE' field='bill_due_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {formatDate(row.bill_due_date)}
        </Typography>
      ),
    },
    {
      field: 'aging',
      title: <ColumnHeader title='AGING' field='aging' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.aging > 0
            ? `Past due ${row.aging} day${Math.abs(row.aging) > 1 ? 's' : ''}`
            : row.aging < 0
            ? row.aging
            : '0'}
        </Typography>
      ),
    },
    {
      field: 'reference_id',
      title: <ColumnHeader title='BILL REFERENCE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.reference_id}
        </Typography>
      ),
    },
    {
      field: 'memo',
      title: <ColumnHeader title='MEMO' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.memo}
        </Typography>
      ),
    },
    {
      field: 'payment_reference',
      title: <ColumnHeader title='PAYMENT REFERENCE' />,
      render: (row) =>
        row?.pdf_check_file_link ? (
          <Typography
            variant='b2'
            style={{ color: palette.indigo500, textDecoration: 'underline' }}
            onClick={(e) => {
              e.stopPropagation();
              onClickRowItem({ filed: 'payment_reference', row });
            }}
          >
            {row?.payment_reference || '-'}
          </Typography>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row?.payment_reference || '-'}
          </Typography>
        ),
    },
    {
      field: 'amount_due',
      title: <ColumnHeader title='AMOUNT DUE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency}
          {formatNumber(row.amount_due)}
        </Typography>
      ),
    },
    {
      field: 'payment_accounts',
      title: <ColumnHeader title='PAYMENT ACCOUNT(S)' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.payments?.map((i) => i.bank_account?.account_name)?.join(', ') || '-'}
        </Typography>
      ),
    },
    {
      field: 'amount_paid',
      title: <ColumnHeader title='AMOUNT PAID' field='amount_paid' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency}
          {formatNumber(row.amount_paid)}
        </Typography>
      ),
    },
    {
      field: 'open_balance',
      title: <ColumnHeader title='OPEN BALANCE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency}
          {formatNumber(row.open_balance)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      title: <ColumnHeader title='' />,
      render: (row) => (
        <RowActions
          onRevertToUnpaid={() => onRevertToUnpaid(row)}
          onView={() => onView(row)}
          onRevertAndDelete={() => onDelete(row)}
        />
      ),
    },
  ];
};
