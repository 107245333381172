import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import truckinDigitalCard from 'assets/truckinDigitalCard.svg';
import useForm from 'hooks/useForm';
import { GetCompanyProfile, GetSettingsAccounting } from 'Api/CompanySettings';
import BasicInfo from './steps/BasicInfo';
import Validation from './steps/Validation';
import Verification from './steps/Verification';
import IdVerification from './steps/IdVerification';
import SsnVerification from './steps/SsnVerification';
import AddDepositWithdrawalSource from './steps/AddDepositWithdrawalSource';
import { basicInfoValidationSchema, verificationValidationSchema } from './validationSchemas';
import { basicInfoInitialValues, redirectPath, verificationInitialValues } from './TruckinDigitalCashCard.data';
import { SPageWrapper } from './TruckinDigitalCashCard.styles';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const TruckinDigitalCashCard = () => {
  const navigate = useNavigate();
  const { module } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [idNumber, setIdNumber] = useState('');
  const [idFront, setIdFront] = useState(null);
  const [idBack, setIdBack] = useState(null);
  const [personId, setPersonId] = useState(null);

  const handleBasicInfoSubmit = () => {
    setCurrentStep(2);
  };

  const handleAddRepresentativeSubmit = () => {
    setCurrentStep(3);
  };

  const basicInfoForm = useForm({
    initialValues: basicInfoInitialValues,
    validationSchema: basicInfoValidationSchema,
    onSubmit: handleBasicInfoSubmit,
  });
  const verificationForm = useForm({
    initialValues: verificationInitialValues,
    validationSchema: verificationValidationSchema,
    onSubmit: handleAddRepresentativeSubmit,
  });

  const getCompanyInfo = async () => {
    try {
      const res = await GetCompanyProfile();
      const { data } = await GetSettingsAccounting();
      await basicInfoForm.setValues({
        ...basicInfoInitialValues,
        legal_business_name: res?.company_name || '',
        ein: data?.ein || '',
        address1: res?.address1 || '',
        address2: res?.address2 || '',
        country: res?.country_id || null,
        state: res?.state_id || null,
        city: res?.city_id || null,
        zipCode: res?.zip || '',
        business_phone_number: res?.phone_number || '',
        website: res?.company_website || '',
        product_description: `Transportation Courier Services in United States with FMCSA DOT Number ${res?.dot}.`,
      });
    } catch (e) {
      // Do nothing
    }
  };

  const onClose = () => {
    navigate(redirectPath[module]);
  };

  useEffect(() => {
    document.getElementsByClassName('right-section').item(0).scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  useEffect(() => {
    getCompanyInfo();
  }, []);

  return (
    // <Elements stripe={stripePromise}>
    <SPageWrapper>
      <div className='d-flex gap-5'>
        <div className='left-section'>
          <img alt='Truckin Digital Card' src={truckinDigitalCard} />
        </div>
        <div className='right-section'>
          {currentStep === 1 ? (
            <BasicInfo form={basicInfoForm} onClose={onClose} />
          ) : currentStep === 2 ? (
            <Verification form={verificationForm} setCurrentStep={setCurrentStep} onClose={onClose} />
          ) : currentStep === 3 ? (
            <Validation
              basicInfo={basicInfoForm.values}
              verification={verificationForm.values}
              setPersonId={setPersonId}
              idNumber={idNumber}
              idFront={idFront}
              idBack={idBack}
              setCurrentStep={setCurrentStep}
              onClose={onClose}
            />
          ) : currentStep === 4 ? (
            <SsnVerification
              idNumber={idNumber}
              setIdNumber={setIdNumber}
              setCurrentStep={setCurrentStep}
              verification={verificationForm.values}
              personId={personId}
            />
          ) : currentStep === 5 ? (
            <IdVerification
              idFront={idFront}
              setIdFront={setIdFront}
              idBack={idBack}
              setIdBack={setIdBack}
              setCurrentStep={setCurrentStep}
              verification={verificationForm.values}
              personId={personId}
            />
          ) : (
            <AddDepositWithdrawalSource onClose={onClose} module={module} />
          )}
        </div>
      </div>
    </SPageWrapper>
    // </Elements>
  );
};

export default TruckinDigitalCashCard;
