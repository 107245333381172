import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { getAccountTypes, createAccountType, updateAccountType, deleteAccountType } from 'Api/Accounts';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import ItemForm from './ItemForm/ItemForm';
import styles from './AccountTypes.module.css';
import { SActions, SAddMore, SContentRow, SRow, SWrapper } from '../Payroll/Payroll.styles';

const AccountTypes = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [accountTypes, setAccountTypes] = useState([]);
  const [forms, setForms] = useState([]);
  const [editItems, setEditItems] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const getAccountTypesList = async () => {
    try {
      const { data } = await getAccountTypes();
      setAccountTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getAccountTypesList();
  }, []);

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createNewAccountType = async (values, id) => {
    setLoadingCreate(true);
    try {
      const data = {
        account_type: values?.account_type,
      };
      await createAccountType(data);
      await getAccountTypesList();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Account type has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const updateType = async (values, id) => {
    try {
      const data = {
        account_type: values?.account_type,
      };
      await updateAccountType(id, data);
      await getAccountTypesList();
      onCancel(id);
      showToaster({ type: 'success', message: 'Account type has been successfully updated!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  const deleteItem = async (item) => {
    setLoadingDelete(true);
    try {
      await deleteAccountType(item?.id);
      const newAccountTypes = accountTypes.filter((el) => el.id !== item?.id);
      setAccountTypes(newAccountTypes);
      showToaster({ type: 'success', message: 'Account type has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingDelete(false);
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const toggleEdit = (id) => {
    setEditItems([...editItems, id]);
  };
  const onCancel = (id) => {
    const newEditItems = editItems.filter((item) => item !== id);
    setEditItems(newEditItems);
  };

  return (
    <SWrapper>
      <div className={styles.header}>
        <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
          Account Types
        </Typography>
        <div className={styles.actions}>
          {isConfiguringStep && (
            <>
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('saveAndNext')}
                type='primary'
                title={isConfiguringStep ? 'Save and Next' : 'Save'}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ margin: 0 }}
              />
            </>
          )}
        </div>
      </div>
      <SRow>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            TYPE
          </Typography>
        </div>
      </SRow>
      {accountTypes?.map((item) => {
        return editItems.includes(item.id) ? (
          <ItemForm
            editable
            item={item}
            key={item.id}
            onCancel={() => onCancel(item?.id)}
            onSubmit={(values) => updateType(values, item.id)}
            onDelete={() => deleteItem(item)}
            loading={loadingCreate}
          />
        ) : (
          <SContentRow key={item.id}>
            <div className='settings-table-item'>
              <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {item.account_type}
              </Typography>
            </div>
            <SActions className='settings-table-item settings-table-actions-wrapper'>
              {loadingDelete ? (
                <span className='loading-wrapper'>
                  <CircularProgress size={16} />
                </span>
              ) : (
                !item?.default &&
                !item?.account_list && (
                  <span className='action-wrapper' onClick={() => toggleEdit(item.id)}>
                    <EditIcon width={11} height={11} />
                  </span>
                )
              )}
            </SActions>
          </SContentRow>
        );
      })}
      {forms.map((item) => (
        <ItemForm
          key={item.id}
          onSubmit={(values) => createNewAccountType(values, item.id)}
          onDelete={() => deleteFormItem(item.id)}
          loading={loadingCreate}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200), cursor: 'pointer' }}>
          Add Type...
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default AccountTypes;
