import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { palette } from 'utils/constants';

export const SPaper = styled(Paper)`
  &.MuiPaper-root {
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
      0px 5px 15px rgba(0, 0, 0, 0.08);

    .user-info-content {
      ul {
        list-style-type: none;
        padding: 8px 6px;
        margin: 0;

        li {
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.02em;
          text-align: left;
          color: ${palette.gray700};
          padding: 4px 10px;
          cursor: pointer;
          border-radius: 4px;
          display: flex;
          align-items: center;
          column-gap: 8px;

          a {
            text-decoration: none;
            color: ${palette.gray700};
          }

          :hover {
            background-color: ${palette.indigo0};
          }
        }
      }
    }
  }
`;

export const SUserInfo = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;

  .user-name-wrapper {
    display: inline-flex;
    flex-direction: column;
  }

  .user-name:hover {
    text-decoration: underline;
  }
`;
