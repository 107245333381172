import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  name: string().trim().required('Required'),
  last_name: string().trim().required('Required'),
  company_name: string().trim().required('Required'),
  dot: string().trim().required('Required'),
  phone: string().trim().required('Required'),
  email: string().required('Required').email('Invalid Email Address'),
  workers: number()
    .required('Required')
    .test('Required', 'Required', (value) => value > 0)
    .nullable(),
});
