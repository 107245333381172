import React, { useState } from 'react';
import '../../equipmentdetails.css';
import { Toast } from 'react-bootstrap';
import hexRgb from 'hex-rgb';
import Popover from '@mui/material/Popover';
import { Icons, satelight } from 'assets/icons';
import { palette } from 'utils/constants';
import { Fade, Box, Modal } from '@mui/material';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import style from '../../EquipmentProfile.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import AddHook from '../../../DialogueModals/AddHook/AddhookTrailer';
import UnHooked from '../../../DialogueModals/AddHook/UnhookedVehicle';

import Documents from './Documents';
import EquipmentDetails from './EquipmentDetailsComponent';
import EquipmentConnectedServices from '../EquipmentConnectedServices/EquipmentConnectedServicesTrailer';
import UnhookedMsg from '../../../DialogueModals/Equipmentsmsg/UnhookedTrailerMsg';
import AddHookedSuccess from '../../../DialogueModals/Equipmentsmsg/HookedTrailerSuccuss';
import AddGroup from '../../../DialogueModals/AddGroup/AddGroup';
import RemoveGroup from '../../../DialogueModals/AddGroup/RemoveGroup';
import AddGroupSuccess from '../../../DialogueModals/Equipmentsmsg/AddGroupSuccess';
import RemoveGroupSuccess from '../../../DialogueModals/Equipmentsmsg/RemoveGroupSuccess';
import ReserveEquipment from '../../../DialogueModals/ReserveEquipment/ReserveEquipment';
import UnresevedEquipment from '../../../DialogueModals/ReserveEquipment/UnreservedEquipment';
import AlreadyReserved from '../../../DialogueModals/Equipmentsmsg/AlreadyReserved';
import UnreservedEquipmentmsg from '../../../DialogueModals/ReserveEquipment/UnreserveEquipmentmsg';
import ReserveEquipmentMsg from '../../../DialogueModals/ReserveEquipment/ReservedEquipmentMsg';
import './DateRangePicker/DatePicker.css';
import AlertMsg from '../../../DialogueModals/ProfileAlert/AlertIcon';
import TrackingHistory from './TrackingHistory/TrackingHistory';

const Trailer = ({ profileData, init, timeLine, timeLineClose, equipmentId }) => {
  const { convertToCustomerTime } = useDateFormat();
  const [hook, setHook] = useState(false);
  const [id, setId] = useState();
  const [success, setSuccess] = useState(false);
  const [unHookedTrailer, setUnHookedTrailer] = useState(false);
  const [unHookedData, setUnhookedData] = useState({ data: [] });
  const { use } = useTheme();
  const [unHookedMsg, setUnhookedMsg] = useState(false);
  const [unreserveSuccess, setUnReserveSuccess] = useState(false);
  const [RemoveGroupData, setRemoveGroupData] = useState({ data: [] });
  const [removeGroup, setRemoveGroup] = useState(false);
  const [addGroup, setAddGroup] = useState(false);
  const [removeGroupSuccess, setRemoveGroupSuccess] = useState(false);
  const [addGroupSuccess, setAddGroupSuccess] = useState(false);
  const [reserveEquipment, setReserveEquipment] = useState(false);
  const [reserveMsgData, setReserveMsgData] = useState();
  const [showA, setShowA] = useState(false);
  const [unReseveredEq, setUnReserveredEq] = useState(false);
  const [unReseveredEqId, setUnReserveredEqId] = useState({ data: [] });
  const [alreadyReserveMsg, setAlreadyReserveMsg] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [alertId, setAlertId] = useState(null);
  const [connectedServicesIndex, setConnectedServicesIndex] = useState(Date.now());

  const [profileAlert, setProfileAlert] = useState(null);
  const [alreadyReserveid, setAlreadyReserveId] = useState();

  const user = JSON.parse(localStorage.getItem('user'));
  const AlreadyReserveMsgModalClose = () => setAlreadyReserveMsg(false);
  const getId = (id) => {
    setId(id);
    ReserveEquipmentModal(true);
  };

  const UnReserveSuccessShow = () => {
    setUnReserveSuccess(true);
    setTimeout(() => {}, 4000);
  };
  const UnReservedModalClose = () => {
    setUnReserveredEq(false);
  };

  const AlreadyReserveMsgModal = (id) => {
    setAlreadyReserveId(id);
    setAlreadyReserveMsg(true);
    setTimeout(() => {
      AlreadyReserveMsgModalClose();
    }, 5000);
  };
  const UnReservedEqModal = (id, duration, type, row_id) => {
    setUnReserveredEqId([id, duration, type, row_id]);
    setUnReserveredEq(true);
  };
  const ProfileAlerthandleClick = (e, alerts, id) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
    setAlertId(id);
  };
  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };
  const ReserveEquipmentModalClose = () => {
    setReserveEquipment(false);
  };
  const GetreserveMsgData = (val) => {
    setReserveMsgData(val);
  };
  const toggleShowA = () => {
    setShowA(true);
  };
  const ProfileAlertOpen = Boolean(profileAlert);

  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;

  const toggleCloseA = () => setShowA(false);
  const ReserveEquipmentModal = () => {
    setReserveEquipment(true);
  };
  const UnHookedModalShow = (row_id, id, title, eq_id) => {
    setUnhookedData([row_id, id, title, eq_id]);
    setUnHookedTrailer(true);
  };
  const RemoveGroupModal = (id, eq_id, row_id, title, group_name) => {
    setRemoveGroup(true);
    setRemoveGroupData([id, eq_id, row_id, title, group_name]);
  };
  const addGroupModal = () => {
    setId(profileData.id);
    setAddGroup(true);
  };
  const AddGroupSuccessClose = () => {
    setAddGroupSuccess(false);
  };
  const AddGroupSuccessModal = () => {
    setAddGroupSuccess(true);
    setTimeout(() => {
      AddGroupSuccessClose();
    }, 3000);
  };
  const addGroupModalClose = () => setAddGroup(false);
  const UnHookedModalClose = () => {
    setUnHookedTrailer(false);
  };
  const RemoveGroupModalClose = () => {
    setRemoveGroup(false);
  };
  const hookModalClose = () => setHook(false);
  const hookModalShow = () => {
    setId(profileData.id);
    setHook(true);
  };
  const RemoveGroupSuccessModalClose = () => {
    setRemoveGroupSuccess(false);
  };
  const RemoveGroupSuccessModal = () => {
    setRemoveGroupSuccess(true);
    setTimeout(() => {
      RemoveGroupSuccessModalClose();
    }, 3000);
  };
  const SuccessShow = () => {
    init(null, true);
    setSuccess(true);
  };
  const SuccessClose = () => setSuccess(false);
  const UnhookedMsgClose = () => {
    setUnhookedMsg(false);
  };
  const UnReserveSuccessClose = () => {
    setUnReserveSuccess(false);
  };
  const UnHoookedMsgShow = () => {
    init(null, true);
    setUnhookedMsg(true);
    setTimeout(() => {
      setUnhookedMsg(false);
    }, 4000);
  };
  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    backgroundColor: use(palette.white, palette.dark800),
    outline: 0,
  };

  const trailer = 'true';
  return (
    <div
      className='overlay-equipment-details-container'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
      }}
    >
      <div className='d-flex mt-1 equipment-id-column equipment-profile-group-box justify-content-between'>
        <div className='d-flex align-item-center'>
          <div className='flex-row d-flex align-items-center'>
            <div className='d-flex p-0'>
              {profileData?.equipment_reserve && profileData?.equipment_reserve?.duration !== 'Expired' ? (
                <div>
                  <svg
                    className='ms-1'
                    width='12'
                    height='14'
                    viewBox='0 0 12 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={($event) => {
                      $event.stopPropagation();
                      user?.department?.department_name === 'Management' ||
                      user.id === profileData?.equipment_reserve?.user_id
                        ? UnReservedEqModal(
                            profileData?.equipment_reserve?.id,
                            profileData?.duration,
                            profileData?.equipment_type?.title,
                            profileData.equipment_id
                          )
                        : AlreadyReserveMsgModal(profileData?.equipment_reserve?.id);
                    }}
                  >
                    <path
                      d='M0.5 2C0.5 0.895431 1.39543 0 2.5 0H9.5C10.6046 0 11.5 0.895431 11.5 2V13.5993C11.5 13.8965 11.1873 14.0899 10.9213 13.9572L6.4467 11.723C6.16546 11.5826 5.83454 11.5826 5.55329 11.723L1.07868 13.9572C0.812734 14.0899 0.5 13.8965 0.5 13.5993V2Z'
                      fill='#767FFB'
                    />
                  </svg>
                  {/* <button > click me</button> */}
                </div>
              ) : (
                <div
                  className='reserve-equipment'
                  onClick={($event) => {
                    $event.stopPropagation();
                    getId(profileData?.id);
                  }}
                >
                  <img src={Icons.buttonIcon} alt='button-icon' className='bookmark' />
                </div>
              )}
            </div>
            {profileData?.expiring_equipment_alerts ? (
              <div className='ms-1 me-1' onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={(e) => ProfileAlerthandleClick(e, profileData?.expiring_equipment_alerts, profileData?.id)}
                >
                  <path
                    d='M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z'
                    fill='#C97A20'
                  />
                  <path
                    d='M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z'
                    fill='#C97A20'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z'
                    fill='#C97A20'
                  />
                </svg>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div
          className='d-flex align-items-center'
          style={{
            backgroundColor: use(palette.white, palette.dark800),
          }}
        >
          {profileData?.location_target === 'Equipment' ? (
            <span className='me-2'> {satelight}</span>
          ) : profileData?.location_target === 'Vehicle' ? (
            <span className='me-2'>
              {' '}
              <img src={Icons?.EquipmentProfileIcon} alt='' />
            </span>
          ) : profileData?.location_target === null ? (
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M4.79114 3.72156C4.49577 3.42619 4.01689 3.42619 3.72152 3.72156C3.42616 4.01693 3.42616 4.49581 3.72152 4.79117L6.93037 8.00002L3.72155 11.2088C3.42619 11.5042 3.42619 11.9831 3.72155 12.2784C4.01692 12.5738 4.4958 12.5738 4.79117 12.2784L7.99998 9.06963L11.2088 12.2785C11.5042 12.5738 11.9831 12.5738 12.2784 12.2785C12.5738 11.9831 12.5738 11.5042 12.2784 11.2089L9.06959 8.00002L12.2785 4.79114C12.5738 4.49577 12.5738 4.01689 12.2785 3.72152C11.9831 3.42616 11.5042 3.42616 11.2089 3.72152L7.99998 6.9304L4.79114 3.72156Z'
                fill='#DC4067'
              />
            </svg>
          ) : (
            ''
          )}
        </div>
      </div>

      {timeLine && <TrackingHistory timeLineClose={timeLineClose} trailer={trailer} equipmentId={equipmentId} />}
      {!timeLine && (
        <div>
          {/* <EquipmentProfileDrivers profileData={profileData} /> */}
          <div className='equipment-profile-group-box mt-4'>
            <div className='d-flex mb-1 justify-content-between '>
              <span
                className={style['equipment-profile-content']}
                style={{
                  color: use(palette.dark800, palette.gray200),
                  width: '12vw',
                }}
              >
                Tags
              </span>
              <div className='equipment-group fade-in-row d-flex' style={{ width: '11vw' }}>
                <div className='group-wrapper d-flex'>
                  <div className='row-style'>
                    {profileData?.equipment_group && profileData?.equipment_group.length >= 1 ? (
                      ''
                    ) : (
                      <div className='add-group'>
                        <div
                          className='d-flex'
                          style={{ display: 'flex', justifyContent: 'center' }}
                          onClick={($event) => {
                            $event.stopPropagation();
                            addGroupModal(profileData.id);
                          }}
                        >
                          <div className='d-flex'>
                            <img src={Icons.add} alt='add' className={style['add-group-icon']} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {profileData?.equipment_group
                      ? profileData?.equipment_group.map((g) => {
                          const colorCode = hexRgb(g.color);
                          const TextColor =
                            colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                              ? 'white'
                              : 'rgb(23, 28, 38)';
                          return (
                            <span key={g.id} style={{ cursor: 'pointer' }}>
                              <span
                                className='equipment-groups'
                                style={{
                                  backgroundColor: g.color,
                                  color: TextColor,
                                }}
                                onClick={($event) => {
                                  RemoveGroupModal(
                                    g.id,
                                    profileData.equipment_id,
                                    profileData?.id,
                                    profileData?.equipment_type?.title,
                                    g?.group_name
                                  );
                                  $event.stopPropagation();
                                }}
                              >
                                {g.short_name}
                              </span>
                            </span>
                          );
                        })
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='equipment-profile-group-box mt-2'>
            <div className='d-flex mb-1 justify-content-between '>
              <span
                className={style['equipment-profile-content']}
                style={{
                  color: use(palette.dark800, palette.gray200),
                  width: '12vw',
                }}
              >
                Vehicle
              </span>
              <div className='equipment-group fade-in-row d-flex'>
                <div className='group-wrapper d-flex ' style={{ width: '11vw' }}>
                  <div className='row-style'>
                    <div className='add-group'>
                      <div className='hooked-to-column-wrapper fade-in-row'>
                        {profileData?.hook_to?.equipment_id ? (
                          <div
                            onClick={($event) => {
                              UnHookedModalShow(
                                profileData?.id,
                                profileData?.hook_to?.id,
                                profileData?.hook_to?.equipment_type?.title,
                                profileData?.hook_to.equipment_id
                              );
                              $event.stopPropagation();
                            }}
                          >
                            <div style={{ cursor: 'pointer' }}>
                              <span
                                className={style['equipment-profile-content']}
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                }}
                              >
                                {profileData?.hook_to?.equipment_type?.title}
                              </span>
                              <span
                                className={style['equipment-profile-content']}
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                }}
                              >
                                <span className='ms-1'>{profileData?.hook_to?.equipment_id}</span>
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div style={{ cursor: 'pointer' }} onClick={() => hookModalShow(profileData.id)}>
                            <svg
                              width='12'
                              height='12'
                              viewBox='0 0 12 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                                fill='#4f5aed'
                              />
                            </svg>
                            <span className='add-driver-content'>Hook</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mt-4 '>
            <span
              className={style['equipment-profile-content']}
              style={{
                color: use(palette.dark800, palette.gray200),
                width: '12vw',
              }}
            >
              Last Update
            </span>
            <span
              className={style['last-update']}
              style={{
                color: use(palette.dark800, palette.gray200),
                width: '11vw',
              }}
            >
              <span style={{ fontSize: '10px' }} className='d-flex'>
                <span>
                  <img src={Icons?.LocationStep} alt='location' />
                </span>
                <span className=' equipment-datepicker-input'>
                  <div style={{ fontSize: '13px' }}>
                    {profileData?.location_target === 'Vehicle' ? (
                      profileData?.hooked_to?.location_target === 'Equipment' ? (
                        profileData?.hooked_to?.equipment_gps?.updated_at ? (
                          <div
                            className='flex-row d-flex fade-in-row'
                            key={id}
                            style={{
                              color: use(palette.dark800, palette.gray200),
                            }}
                          >
                            {profileData?.hooked_to?.equipment_gps?.updated_at &&
                              convertToCustomerTime(profileData?.hooked_to?.equipment_gps?.updated_at)}
                          </div>
                        ) : (
                          <div
                            className='flex-row d-flex fade-in-row'
                            key={id}
                            style={{
                              color: use(palette.dark800, palette.gray200),
                            }}
                          >
                            {profileData?.hooked_to?.last_known_update &&
                              convertToCustomerTime(profileData?.hooked_to?.equipment_gps?.updated_at)}
                          </div>
                        )
                      ) : profileData?.hooked_to?.location_target === 'Driver' && profileData?.hooked_to?.drivers ? (
                        <div
                          className='flex-row d-flex fade-in-row'
                          key={id}
                          style={{
                            color: use(palette.dark800, palette.gray200),
                          }}
                        >
                          {profileData?.hooked_to?.drivers?.map((v) =>
                            v?.driver_gps?.updated_at
                              ? convertToCustomerTime(v?.driver_gps?.updated_at)
                              : profileData?.hooked_to?.last_known_update &&
                                convertToCustomerTime(profileData?.hooked_to?.last_known_update)
                          )}
                        </div>
                      ) : profileData?.hooked_to?.last_known_update ? (
                        <div
                          className='flex-row d-flex fade-id-row'
                          key={id}
                          style={{
                            color: use(palette.dark800, palette.gray200),
                          }}
                        >
                          {/* <p>{d?.driver_gps?.updated_at}</p> */}
                          {profileData?.hooked_to?.last_known_update &&
                            convertToCustomerTime(profileData?.hooked_to?.last_known_update)}
                        </div>
                      ) : (
                        <div
                          className='flex-row d-flex fade-id-row'
                          key={id}
                          style={{
                            color: use(palette.dark800, palette.gray200),
                          }}
                        >
                          {/* <p>{d?.driver_gps?.updated_at}</p> */}
                          {profileData?.last_known_update && convertToCustomerTime(profileData?.last_known_update)}
                        </div>
                      )
                    ) : profileData?.location_target === 'Equipment' && profileData?.equipment_gps?.updated_at ? (
                      <div
                        className='flex-row d-flex fade-in-row'
                        key={id}
                        style={{ color: use(palette.dark800, palette.gray200) }}
                      >
                        {profileData?.equipment_gps?.updated_at &&
                          convertToCustomerTime(profileData?.equipment_gps?.updated_at)}
                      </div>
                    ) : (
                      profileData?.last_known_update && (
                        <div
                          className='flex-row d-flex fade-in-row'
                          key={id}
                          style={{
                            color: use(palette.dark800, palette.gray200),
                          }}
                        >
                          {profileData?.last_known_update && convertToCustomerTime(profileData?.last_known_update)}
                        </div>
                      )
                    )}
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div className='d-flex mt-4 justify-content-between equipment-profile-group-box'>
            <span
              className={style['equipment-profile-content']}
              style={{
                color: use(palette.dark800, palette.gray200),
                display: 'flex',
              }}
            >
              <span style={{ width: '12vw' }}> Location </span>
              <span className='mt-1 ms-1'>{/* <UpdateLocation /> */}</span>
            </span>
            <span
              className={style.location}
              style={{
                color: use(palette.dark800, palette.gray200),
                width: '11vw',
              }}
            >
              <div className=' '>
                {profileData?.location_target === 'Vehicle' ? (
                  profileData?.hooked_to?.location_target === 'Equipment' ? (
                    profileData?.hooked_to?.equipment_gps ? (
                      <p
                        className='current-location'
                        style={{
                          color: use(palette.dark800, palette.gray200),
                        }}
                      >
                        {profileData?.hooked_to?.equipment_gps?.location}
                      </p>
                    ) : (
                      <p
                        className='current-location'
                        style={{
                          color: use(palette.dark800, palette.gray200),
                        }}
                      >
                        {profileData?.hooked_to?.last_known_location}
                      </p>
                    )
                  ) : profileData?.hooked_to?.location_target === 'Driver' && profileData?.hooked_to?.drivers ? (
                    profileData?.hooked_to?.drivers?.map((v) => (
                      <p
                        className='current-location'
                        style={{
                          color: use(palette.dark800, palette.gray200),
                        }}
                      >
                        {v?.driver_gps?.location}
                      </p>
                    ))
                  ) : profileData?.hooked_to ? (
                    <p className='current-location' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {profileData?.hooked_to?.last_known_location}
                    </p>
                  ) : (
                    <p className='current-location' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {profileData?.last_known_location}
                    </p>
                  )
                ) : profileData?.location_target === 'Equipment' ? (
                  <p className='current-location' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {profileData?.equipment_gps?.location
                      ? profileData?.equipment_gps?.location
                      : profileData?.last_known_location}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </span>
          </div>
          <div>
            <EquipmentDetails
              profileData={profileData}
              init={init}
              type='trailer'
              updateConnectedServices={() => setConnectedServicesIndex(Date.now())}
            />
          </div>
          <Documents equipment={profileData} onUpdateSuccess={init} />
          <EquipmentConnectedServices profileData={profileData} refreshIndex={connectedServicesIndex} />
        </div>
      )}

      <Modal
        open={hook}
        onClose={hookModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={hook}>
          <Box sx={styles}>
            <AddHook
              id={id}
              handleClose={hookModalClose}
              SuccessShow={SuccessShow}
              SuccessClose={SuccessClose}
              HookedErrClose={() => null}
              HookedErrShow={() => null}
              GetHookedError={() => null}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={unHookedTrailer}
        onClose={UnHookedModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={unHookedTrailer}>
          <Box sx={styles}>
            <UnHooked
              handleClose={UnHookedModalClose}
              unHookedData={unHookedData}
              UnhookedMsgClose={UnhookedMsgClose}
              UnHoookedMsgShow={(id) => UnHoookedMsgShow(id)}
              HookedErrClose={() => null}
              HookedErrShow={() => null}
              GetHookedError={() => null}
            />
          </Box>
        </Fade>
      </Modal>

      <Toast show={unHookedMsg} onClose={UnhookedMsgClose} className='equipment-success-msg'>
        <div>
          <UnhookedMsg
            handleClose={UnReserveSuccessClose}
            UnhookedMsgClose={UnhookedMsgClose}
            UnHoookedMsgShow={UnHoookedMsgShow}
          />
        </div>
      </Toast>
      <Toast show={success} onClose={SuccessClose} className='equipment-success-msg'>
        <AddHookedSuccess handleClose={SuccessClose} />
      </Toast>
      <Toast show={addGroupSuccess} onClose={AddGroupSuccessClose} className='equipment-success-msg'>
        <div>
          <AddGroupSuccess AddGroupSuccessClose={AddGroupSuccessClose} />
        </div>
      </Toast>
      <Toast show={removeGroupSuccess} onClose={RemoveGroupSuccessModalClose} className='equipment-success-msg'>
        <div>
          <RemoveGroupSuccess RemoveGroupSuccessModalClose={RemoveGroupSuccessModalClose} />
        </div>
      </Toast>
      <Toast show={showA} onClose={toggleCloseA} className='reserve-equipment-toast'>
        <ReserveEquipmentMsg
          handleClose={toggleCloseA}
          reserveMsgData={reserveMsgData}
          userFname={user.first_name}
          userLname={user.last_name}
        />
      </Toast>
      <Toast show={unreserveSuccess} onClose={UnReserveSuccessClose} className='equipment-success-msg'>
        <div>
          <UnreservedEquipmentmsg handleClose={UnReserveSuccessClose} />
        </div>
      </Toast>
      <Toast show={alreadyReserveMsg} onClose={AlreadyReserveMsgModalClose} className='already-reserve-equipment-toast'>
        <div>
          <AlreadyReserved
            alreadyReserveid={alreadyReserveid}
            AlreadyReserveMsgModalClose={AlreadyReserveMsgModalClose}
          />
        </div>
      </Toast>
      <Modal
        open={addGroup}
        onClose={addGroupModalClose}
        style={{ outline: 0, zIndex: 999 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={addGroup}>
          <Box sx={styles}>
            <AddGroup
              handleClose={addGroupModalClose}
              id={id}
              AddGroupSuccessModal={(id) => AddGroupSuccessModal(id)}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={removeGroup}
        onClose={RemoveGroupModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={removeGroup}>
          <Box sx={styles}>
            <RemoveGroup
              handleClose={RemoveGroupModalClose}
              RemoveGroupData={RemoveGroupData}
              RemoveGroupSuccessModal={(id) => RemoveGroupSuccessModal(id)}
            />
          </Box>
        </Fade>
      </Modal>

      <Modal
        show={reserveEquipment}
        open={reserveEquipment}
        onClose={ReserveEquipmentModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={reserveEquipment}>
          <Box sx={styles}>
            <ReserveEquipment
              id={id}
              ReserveEquipmentModalClose={ReserveEquipmentModalClose}
              toggleShowA={toggleShowA}
              toggleCloseA={toggleCloseA}
              GetreserveMsgData={GetreserveMsgData}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={unReseveredEq}
        onClose={UnReservedModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={unReseveredEq}>
          <Box sx={styles}>
            <UnresevedEquipment
              handleClose={UnReservedModalClose}
              unReseverdEqId={unReseveredEqId}
              alreadyReserveid={alreadyReserveid}
              UnReserveSuccessShow={(id) => UnReserveSuccessShow(id)}
              UnReserveSuccessClose={UnReserveSuccessClose}
              onSuccess={() => init()}
            />
          </Box>
        </Fade>
      </Modal>
      <Popover
        id={ProfileAlertContent}
        // anchorPosition={{ top: 299, left: 669 }}
        open={ProfileAlertOpen}
        anchorEl={profileAlert}
        onClose={ProfileAlerthandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <AlertMsg alertData={alertData} alertID={alertId} equipmentAlert />
      </Popover>
    </div>
  );
};

export default Trailer;
