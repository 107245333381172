import React, { useCallback, useEffect, useMemo, useState, createContext } from 'react';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { Modal } from '@mui/material';
import { ReactComponent as Close } from 'assets/icons/xClose.svg';
import classes from './SendPaymentModal.module.scss';

import useForm from '../../hooks/useForm';
import SendPaymentStep1 from './components/Steps/Step1/SendPaymentStep1';
import SendPaymentStep2 from './components/Steps/Step2/SendPaymentStep2';
import SendPaymentStep3 from './components/Steps/Step3/SendPaymentStep3';
import { initialValues } from './SendPaymentModal.data';
import { MAP_KEYS_SEND_PAYMENT, NAVBAR_OPTIONS } from './constants';
import { getValidationSchema } from './validationSchema';
import { Typography } from '../Typography';

export const SendPaymentModalContext = createContext({});

const SendPaymentModal = ({ onClose, defaultStep = 1, data = [], fromTabName, isAch = false }) => {
  const [step, setStep] = useState(defaultStep);
  const [vendors, setVendors] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [activeSelectName, setActiveSelectName] = useState('');
  const [ownerOperators, setOwnerOperators] = useState([]);
  const [searchOpenBills, setSearchOpenBills] = useState([]);
  const [searchOpenSettlements, setSearchOpenSettlements] = useState([]);
  const [staff, setStaff] = useState([]);

  const {
    values,
    handleChange,
    handleSubmit,
    touchedErrors,
    isValid,
    setFieldValue,
    validateField,
    validateForm,
    setTouched,
  } = useForm({
    initialValues,
    onSubmit,
    validationSchema: getValidationSchema(activeSelectName),
  });

  function onSubmit() {}

  const activeTableData = useMemo(() => {
    let activeArr = [];
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (MAP_KEYS_SEND_PAYMENT.includes(key) && Array.isArray(value) && value.length > 0 && activeArr.length === 0) {
        setActiveSelectName(key);
        activeArr = value;
      }
    });
    return activeArr;
  }, [values]);

  const setInitialValueForm = useCallback(() => {
    if (Number(defaultStep) === 3) {
      isAch && setFieldValue('wontToPay', 1);
      MAP_KEYS_SEND_PAYMENT.includes(fromTabName) && setFieldValue(fromTabName, data);
    }
  }, [defaultStep, fromTabName, data, isAch]);

  const StepComponent = useMemo(() => {
    switch (step) {
      case 1:
        return <SendPaymentStep1 />;
      case 2:
        return <SendPaymentStep2 />;
      case 3:
        return <SendPaymentStep3 />;
      default:
    }
  }, [step]);

  useEffect(() => {
    setInitialValueForm();
  }, []);

  const providerValue = {
    step,
    vendors,
    drivers,
    ownerOperators,
    searchOpenBills,
    searchOpenSettlements,
    setVendors,
    setDrivers,
    setOwnerOperators,
    setSearchOpenBills,
    setSearchOpenSettlements,
    staff,
    setStaff,
    values,
    onClose,
    setStep,
    isValid,
    handleSubmit,
    handleChange,
    touchedErrors,
    activeSelectName,
    setFieldValue,
    validateField,
    validateForm,
    setTouched,
    data: activeTableData,
  };

  return (
    <SendPaymentModalContext.Provider value={providerValue}>
      <Modal open>
        <Box sx={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className={classes.sendPaymentContainer}>
            <div className={classes.closeBtn} onClick={onClose}>
              <Close />
            </div>
            <section>
              <ul className={classes.navbar}>
                <div className={classes.sendPaymentTitle}>
                  <Typography variant='title1'>Send Payment</Typography>
                </div>
                {NAVBAR_OPTIONS.map((option) => {
                  return (
                    <li
                      key={option.id}
                      className={classNames(classes.navbarOption, { [classes.active]: +step === +option.id })}
                    >
                      <span>{option.name}</span>
                    </li>
                  );
                })}
              </ul>
              <div className={classes.content}>{StepComponent}</div>
            </section>
          </div>
        </Box>
      </Modal>
    </SendPaymentModalContext.Provider>
  );
};

export default SendPaymentModal;
