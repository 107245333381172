import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getHighwayTaxPolicy, updateHighwayTaxPolicy } from 'Api/Insurance';
import { validationSchema } from 'pages/CompanySettings/pagesComponents/Equipment/HighwayTax/validationSchema';
import InsuranceFields from '../components/InsuranceFields';
import { getInitialValues, highwayTaxFields } from './HighwayTax.data';
import { SWrapper } from '../Equipment.styles';

const HighwayTax = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('highway_tax_active', Number(values.highway_tax_active));
      formData.append(
        'highway_tax_expire_date',
        values.highway_tax_no_expire ? '' : moment(values.highway_tax_expire_date).format('YYYY-MM-DD')
      );
      if (typeof values.highway_tax_date_attach !== 'string') {
        formData.append('highway_tax_date_attach', values.highway_tax_date_attach);
      }

      await updateHighwayTaxPolicy(formData);
      showToaster({ type: 'success', message: 'Successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  const getSettings = async () => {
    try {
      const { data } = await getHighwayTaxPolicy();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Highway Tax'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={form.handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      <div>{loading ? <Loader loading={loading} /> : <InsuranceFields field={highwayTaxFields} form={form} />}</div>
    </SWrapper>
  );
};

export default HighwayTax;
