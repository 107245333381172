import React, { useState } from 'react';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import searchIcon from 'assets/icons/header/Search.png';
import { severityies } from 'components/Carriers/Carriers.data';
import Autocomplete from 'common/Autocomplete';
import DateRangePicker from 'common/DateRangePicker';
import styles from './SafetyFilters.module.css';

const SafetyFilters = ({
  search,
  onChange,
  onSelectDate,
  setStates,
  states,
  reportedStates,
  severity,
  setSeverity,
}) => {
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });

  const dateSelect = (dates) => {
    setDateRange(dates);
    onSelectDate(dates);
  };

  return (
    <div className={styles.filtersSafety}>
      <div>
        <div className='d-flex align-items-center'>
          <div>
            <input
              className={styles.searchInput}
              type='text'
              value={search}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Search'
            />
            <div className='input-group-addon-search'>
              <img src={searchIcon} alt='search' />
            </div>
          </div>
          <div className='ms-3'>
            <DateRangePicker
              dateRange={dateRange}
              initialRangeName='All Time'
              setDateRange={dateSelect}
              type='allTime'
            />
          </div>
          <Autocomplete
            placeholder={states.length ? '' : 'Select Report State'}
            multiple
            options={reportedStates}
            value={states}
            onChange={(e, val) => {
              setStates(val);
            }}
            style={{ width: 320, marginLeft: 20 }}
            isOptionEqualToValue={(option, value) => option === value}
          />
          <Autocomplete
            placeholder={severity.length ? '' : 'Select Severity'}
            multiple
            options={severityies}
            value={severity}
            onChange={(e, val) => {
              setSeverity(val);
            }}
            style={{ width: 320, marginLeft: 20 }}
            isOptionEqualToValue={(option, value) => option === value}
          />
        </div>
      </div>
    </div>
  );
};
export default SafetyFilters;
