import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { palette } from 'utils/constants';
import { convertToCustomerTime } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import { deleteBillOfLading, getBillOfLadings } from 'Api/Shipment';
import { Typography } from 'components/Typography';
import ConfirmModal from 'common/ConfirmModal';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import CustomButton from 'components/CustomButton/CustomButton';
import ViewPdf from 'components/ViewPdf';
import ShareEbol from 'componentsV2/Shipment/ShareEbol';
import classes from './ElectronicBillOfLading.module.css';

const ElectronicBillOfLading = ({ infoHeader }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { id } = useParams();
  const [ebolToShare, setEbolToShare] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shipmentEventsData, setShipmentEventsData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(null);

  const columns = [
    {
      field: 'updated_at',
      title: <Typography variant='c3'>UPDATED DATE</Typography>,
      render: (rowData) => {
        return (
          <div className={classes.colWrapper}>
            <Typography variant='s3'>{convertToCustomerTime(rowData?.updated_at, 'YYYY-MM-DD HH:mm')}</Typography>
          </div>
        );
      },
    },
    {
      field: 'bol_id',
      title: <Typography variant='c3'>BOL ID</Typography>,
      render: (rowData) => {
        return (
          <div className={classes.colWrapper}>
            <Typography variant='s3'>{checkNullOrUndefined(rowData?.bol_id)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'customer',
      title: <Typography variant='c3'>CUSTOMER</Typography>,
      render: (rowData) => {
        return (
          <div className={classes.colWrapper}>
            <Typography variant='s3'>{checkNullOrUndefined(rowData.customer_invoice)}</Typography>
          </div>
        );
      },
    },

    {
      field: 'actions',
      title: <Typography variant='c3'>ACTIONS</Typography>,
      render: (rowData) => {
        return (
          <div className={classes.colWrapper}>
            <div className='d-flex gap-3'>
              <CustomButton
                type='secondary'
                styleButton={{
                  height: 32,
                  padding: '2px 10px',
                  margin: 0,
                }}
                onClick={() => setPdfUrl(rowData.path)}
                title='View'
                styleTitle={{ fontSize: '12px' }}
              />
              <CustomButton
                type='secondary'
                styleButton={{
                  height: 32,
                  padding: '2px 10px',
                  margin: 0,
                }}
                disabled={infoHeader.start_date_time}
                title='Regenerate'
                onClick={() => navigate(`/bill-of-lading/${id}/${rowData.id}`)}
                styleTitle={{ fontSize: '12px' }}
              />
              <CustomButton
                type='secondary'
                styleButton={{ height: 32, padding: '2px 10px', margin: 0 }}
                title='Share'
                onClick={() => setEbolToShare(rowData.id)}
                styleTitle={{ fontSize: '12px' }}
              />
              <CustomButton
                type='danger'
                styleButton={{
                  height: 32,
                  padding: '2px 10px',
                  margin: 0,
                }}
                disabled={infoHeader.start_date_time}
                title='Delete'
                onClick={() => setOpenConfirm(rowData.id)}
                styleTitle={{ fontSize: '12px' }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  function checkNullOrUndefined(val) {
    return val === null || val === undefined ? '-' : val;
  }

  const getCols = () => {
    !!loading && setLoading(true);
    getBillOfLadings({ shipment_id: id })
      .then((res) => setShipmentEventsData(res.data))
      .finally(() => !!loading && setLoading(false));
  };

  useEffect(() => {
    getCols();
  }, []);

  const deleteBillOfLadingDocument = () => {
    deleteBillOfLading(openConfirm)
      .then(() => {
        getCols();
        setOpenConfirm(null);
      })
      .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant='h4'>eBill of Lading</Typography>
      </div>

      <div style={{ margin: 1 }}>
        {loading ? (
          <EquipmentPreloaderSkeleton />
        ) : (
          <MaterialTableWrapper
            data={shipmentEventsData}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            columns={columns}
            components={{ Pagination: () => null }}
          />
        )}
      </div>
      <ViewPdf open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
      <ConfirmModal
        open={!!openConfirm}
        title='Delete eBill of Lading'
        text='Are you sure you want to delete eBill of Lading?'
        btnType='primary'
        btnText='Yes'
        onClose={() => setOpenConfirm(null)}
        onConfirm={() => deleteBillOfLadingDocument()}
      />
      {!!ebolToShare && <ShareEbol open={!!ebolToShare} onClose={() => setEbolToShare(null)} ebolId={ebolToShare} />}
    </div>
  );
};

export default ElectronicBillOfLading;
