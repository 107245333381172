import React, { useState } from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';

const SelectExistingIncident = ({ open, onClose, onSuccess, incidents }) => {
  const [selectedIncident, setSelectedIncident] = useState(null);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<span />}
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'done',
          type: 'primary',
          title: 'Update',
          onClick: () => onSuccess(selectedIncident),
          disabled: !selectedIncident,
        },
      ]}
    >
      <div>
        <div className='mt-2'>
          <Autocomplete
            label='Incident'
            width='380px'
            labelKey='incident_id'
            disableClearable={false}
            options={incidents}
            value={selectedIncident}
            placeholder='Select Incident'
            onChange={(e, val) => setSelectedIncident(val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            className='filter-dropdown'
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectExistingIncident;
