import React, { useState } from 'react';
import XIcon from 'assets/icons/x.png';
import ChatAppServices from 'services/chatApp';
import useDateFormat from 'hooks/useDateFormat';

const NotesComponent = ({ notes, getAllNotes, chatUser }) => {
  const { convertToCustomerTime } = useDateFormat();
  const [wait, setwait] = useState(false);
  const [warning, setwarning] = useState('Please wait ...');

  const handleDeleteMessage = (Id) => {
    setwait(true);
    if (notes.chat_id === chatUser.id) {
      ChatAppServices.deleteNotes(Id)
        .then(() => {
          getAllNotes();
          setwait(false);
        })
        .catch(() => {
          setwait(false);
        });
    } else {
      setwarning('You can not delete this note');
    }
  };

  return (
    <>
      {wait && (
        <div className='wait-for-res' onClick={() => setwait(false)}>
          <p style={{ fontSize: '14px' }}>{warning}</p>
        </div>
      )}
      <img src={XIcon} alt='close' className='delete' onClick={() => handleDeleteMessage(notes.id)} />
      <div className='notes_title_time'>
        <p style={{ fontWeight: 'bold' }}>{notes.title}</p>
        <span className='created_at'>
          {convertToCustomerTime(notes.created_at)} <br />
          {notes.added_by}
        </span>{' '}
      </div>
      <p className='notes_text'>{notes.notes}</p>
      {notes.attachment !== '' && (
        <a href={notes.attachment} target='_blank' className='download-attechment' rel='noopener noreferrer' download>
          Download Attachment
        </a>
      )}
    </>
  );
};

export default NotesComponent;
