import React from 'react';
import { capitalize } from 'lodash';
import { ReactComponent as InfoIcon } from 'assets/icons/infoPrimary.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import Tooltip from 'common/Tooltip';
import useDateFormat from 'hooks/useDateFormat';
import styles from './Transactions.module.css';

const transactionType = {
  'Carrier & Broker': 'Carrier & Broker',
  addon: 'Addon',
  hardware: 'Hardware',
  plan: 'Plan',
  'Cellular Tracking': 'Cellular Tracking',
  plan_addon: 'Plan & Addon',
};

export const useTransactionsColumns = ({ sort, sortingQuery, title }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'created_at',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          DATE
        </Typography>
      ),
      render: (rowData) => {
        const date = rowData?.date;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='s2' style={{ color: use(palette.dark800, palette.gray200) }}>
              {formatDate(date.split('T')[0])}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'id',
      title: <ColumnHeader title='INVOICE ID' field='id' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const transactionId = rowData?.agreement_number;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {transactionId}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'type',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          TYPE
        </Typography>
      ),
      render: (rowData) => {
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {transactionType[rowData?.purchase_type] || capitalize(rowData?.purchase_type)}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'payment_status',
      title: <ColumnHeader title='STATUS' field='status' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const status = rowData?.status;
        const color =
          rowData?.status === 'Pending Financing' || rowData?.status === 'Pending Payment'
            ? '#4f5aed'
            : rowData?.status === 'Payment Processing' || rowData?.status === 'Processing'
            ? '#AA5B00'
            : rowData?.status === 'Declined' ||
              rowData?.status === 'Failed Payment' ||
              rowData?.status === 'Declined Payment'
            ? '#de2352'
            : rowData?.status === 'Financing Declined'
            ? '#de2352'
            : rowData?.status === 'Succeeded'
            ? '#14804A'
            : '';
        const bgColor =
          rowData?.status === 'Pending Financing' || rowData?.status === 'Pending Payment'
            ? '#f0f1fa'
            : rowData?.status === 'Payment Processing' || rowData?.status === 'Processing'
            ? '#FCF2E6'
            : rowData?.status === 'Declined' ||
              rowData?.status === 'Failed Payment' ||
              rowData?.status === 'Declined Payment'
            ? '#FAF0F3'
            : rowData?.status === 'Financing Declined'
            ? '#FAF0F3'
            : rowData?.status === 'Succeeded'
            ? '#E1FCEF'
            : '';
        return (
          <div className='d-flex align-items-center gap-2'>
            <div>
              <Typography
                variant='overLine'
                style={{
                  color: `${color}`,
                  padding: '2px 6px',
                  backgroundColor: `${bgColor}`,
                  borderRadius: '4px',
                  textTransform: 'uppercase',
                }}
              >
                {status}
              </Typography>
            </div>
            {status === 'Declined Payment' && !!rowData.declined_reason && (
              <Tooltip title={rowData.declined_reason}>
                <InfoIcon fill={palette.red400} width={14} height={14} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: 'amount',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          AMOUNT
        </Typography>
      ),
      render: (rowData) => {
        const amount = rowData?.amount;
        return (
          <div style={{ display: 'flex' }}>
            {amount ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  ${amount}
                </Typography>
              </div>
            ) : (
              <span style={{ marginLeft: 30 }}> - </span>
            )}
          </div>
        );
      },
    },
    ...(title === 'Open Bills'
      ? [
          {
            field: 'due_date',
            title: (
              <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
                DUE DATE
              </Typography>
            ),
            render: (rowData) => {
              const dueDate = rowData?.due_date ? formatDate(rowData?.due_date) : '';
              return (
                <div style={{ display: 'flex' }}>
                  <Typography variant='s2' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {dueDate}
                  </Typography>
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: 'payment_receipt',
      title: <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }} />,
      render: (rowData) => {
        if (!rowData?.invoice_pdf) {
          return null;
        }
        const status = rowData?.status;
        return (
          <div className={styles.lastRow} style={{ gap: '24px' }}>
            {status === 'Succeeded' && (
              <span
                className={styles.view_text}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(rowData?.invoice_pdf);
                }}
              >
                View Receipt
              </span>
            )}
            {(status === 'Pending Payment' || status === 'Failed Payment' || status === 'Declined Payment') && (
              <span
                className={styles.view_text}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(rowData?.invoice_pdf);
                }}
              >
                View & Make Payment
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'view_agreement',
      title: <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }} />,
      render: (rowData) => {
        const urls = rowData?.agreement_document.split(',');
        const firstUrl = urls[0];

        return (
          <div className={styles.lastRow} style={{ gap: '24px' }}>
            {rowData?.agreement_document?.startsWith('https://') && (
              <span
                className={styles.view_text}
                onClick={async (e) => {
                  e.stopPropagation();
                  window.open(firstUrl);
                }}
              >
                View Agreement
              </span>
            )}
          </div>
        );
      },
    },
  ];
};
