import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPickerWrapper = styled.div`
  width: ${({ $width }) => $width || ''};
  flex-shrink: ${({ $width }) => ($width ? 0 : '')};

  .MuiFormControl-root {
    width: ${({ $width }) => ($width ? '100%' : '')};
    background-color: ${palette.white};

    .MuiInputBase-root {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
      color: ${palette.gray700};
      box-shadow: ${palette.boxShadow2};
      padding-right: ${({ $size }) => ($size === 'small' ? '22px' : '28px')};
      border-radius: ${({ $borderRadius }) => $borderRadius || '6px'};

      .MuiInputBase-input {
        box-sizing: border-box;
        height: ${({ $size }) => ($size === 'small' ? '26px' : 'auto')};
        padding: ${({ $size }) => ($size === 'small' ? '5px 0 5px 10px' : '8px 0 8px 10px')};
        border-radius: 6px;
        font-size: ${({ $size }) => ($size === 'small' ? '13px' : '14px')};
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }

    .Mui-disabled {
      background-color: ${palette.gray0};
    }

    .MuiInputAdornment-root {
      width: 0;
      margin-left: 0;

      .MuiButtonBase-root {
        padding: 0;
      }

      .MuiSvgIcon-root {
        width: ${({ $size }) => ($size === 'small' ? '16px' : '20px')};
        height: ${({ $size }) => ($size === 'small' ? '16px' : '20px')};
      }
    }
  }
`;
