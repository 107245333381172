import { array, date, number, object, ref, string } from 'yup';
import moment from 'moment';
import { validator } from 'utils/helpers';

export const getValidationSchema = (stops) =>
  object().shape({
    laneStartDate: date().when('frequency', {
      is: (frequency) => Number(frequency) === 2,
      then: validator.requiredDate().max(ref('laneEndDate'), "Start date can't be after end date!").nullable(),
      otherwise: validator.date(),
    }),
    laneEndDate: date().when('frequency', {
      is: (frequency) => Number(frequency) === 2,
      then: validator.requiredDate().min(ref('laneStartDate'), '').nullable(),
      otherwise: validator.date(),
    }),
    leadTime: number()
      .required('Required')
      .test('Required', 'Required', (value) => value > 0)
      .nullable(),
    recurs: array().of(
      object().shape({
        amount: number().when('checked', {
          is: (checked) => !!checked,
          then: number()
            .required('Required')
            .test('Required', 'Required', (value) => value > 0)
            .nullable(),
        }),
        dateSettings: array().when('checked', {
          is: (checked) => !!checked,
          then: array().of(
            object().shape({
              day_wise_pickup_time: string()
                .required('Required')
                .test('Required', 'Required', (value) => value?.split(':')?.every((i) => !Number.isNaN(Number(i))))
                .nullable(),
              day_wise_pickup_time_to: string()
                .test('Invalid', 'Invalid', (value, context) => {
                  const sameValueFrom = context.parent.day_wise_pickup_time;
                  return [1, 3].includes(Number(stops[0].scheduled_type))
                    ? value?.split(':')?.every((i) => !Number.isNaN(Number(i))) &&
                        (sameValueFrom ? !moment(value, 'HH:mm').isBefore(moment(sameValueFrom, 'HH:mm')) : true)
                    : true;
                })
                .nullable(),
              day_wise_delivery_time_after: array().of(
                number()
                  .test('Required', 'Required', (value, context) => {
                    const arr = context.path.split('.');
                    const index = Number(
                      arr[arr.length - 1].replace('day_wise_delivery_time_after[', '').replace(']', '')
                    );
                    return index !== 0 ? value !== '' && Number(value) >= 0 : true;
                  })
                  .nullable()
              ),
              delivery_time: array().of(
                string()
                  .test('Invalid', 'Invalid', (value, context) => {
                    const arr = context.path.split('.');
                    const index = Number(arr[arr.length - 1].replace('delivery_time[', '').replace(']', ''));
                    const sameValueAfterDays = context.from[0].value.day_wise_delivery_time_after[index];
                    const prevFrom =
                      index === 1
                        ? context.from[0].value.day_wise_pickup_time
                        : context.from[0].value.delivery_time[index - 1];

                    return index !== 0
                      ? value?.split(':')?.every((i) => !Number.isNaN(Number(i))) &&
                          (Number(sameValueAfterDays) === 0
                            ? !moment(value, 'HH:mm').isBefore(moment(prevFrom, 'HH:mm'))
                            : true)
                      : true;
                  })
                  .nullable()
              ),
              delivery_time_to: array().of(
                string()
                  .test('Invalid', 'Invalid', (value, context) => {
                    const arr = context.path.split('.');
                    const index = Number(arr[arr.length - 1].replace('delivery_time_to[', '').replace(']', ''));
                    const sameDeliveryFrom = context.from[0].value.delivery_time[index];

                    return index !== 0 && [1, 3].includes(Number(stops[index].scheduled_type))
                      ? value?.split(':')?.every((i) => !Number.isNaN(Number(i))) &&
                          !moment(value, 'HH:mm').isBefore(moment(sameDeliveryFrom, 'HH:mm'))
                      : true;
                  })
                  .nullable()
              ),
            })
          ),
        }),
      })
    ),
  });
