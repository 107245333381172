import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { endOfMonth, startOfMonth } from 'date-fns';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import NoRecords from 'common/NoRecords';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import useDebounce from 'hooks/useDebounce';
import useDateFormat from 'hooks/useDateFormat';
import { formatAmount } from 'utils/helpers';
import { palette } from 'utils/constants';
import { getMaintenanceCost } from 'Api/Reports';
import { getTrailerList, getVehicleList } from 'Api/HOS';
import { getEquipmentRequiredTrailers, getEquipmentRequiredVehicles } from 'Api/Equipment';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { STableFilters, STableWrapper, STable } from '../ReportDetails.styles';

const MaintenanceCost = () => {
  const { formatDate, formatDateTime, convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [pdfUrl, setPdfUrl] = useState('');
  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const [loadingEquipmentTypes, setLoadingEquipmentTypes] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [reportItems, setReportItems] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });
  const [selectedFilters, setSelectedFilters] = useState({
    equipmentIds: [],
    equipmentTypes: [],
    status: [],
  });
  const debouncedFilters = useDebounce(selectedFilters, 600);
  const didMountRef = useRef(false);
  const controller = useRef(new AbortController());

  const totalCost = useMemo(() => {
    return reportItems.reduce((acc, cur) => acc + Number(cur.cost), 0);
  }, [reportItems]);

  const getMaintenanceData = async () => {
    try {
      setLoading(true);
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }
      const equipmentIds = selectedFilters.equipmentIds.map((el) => el.equipment_id);
      const equipmentTypes = selectedFilters.equipmentTypes.map((el) => el.title);

      const params = {
        page: 1,
        itemsPerPage: 10000,
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        'filters[to]': dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        'filters[equipment_id]': selectedFilters.equipmentIds.length ? equipmentIds : undefined,
        'filters[equipment_type]': selectedFilters.equipmentTypes.length ? equipmentTypes : undefined,
        'filters[status]': selectedFilters.status.length ? selectedFilters.status : undefined,
      };
      const { data } = await getMaintenanceCost(params, controller.current?.signal);
      setReportItems(data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const getEquipmentTypes = async () => {
    try {
      setLoadingEquipmentTypes(true);
      const { data: vehicleTypes } = await getEquipmentRequiredVehicles();
      const { data: trailerTypes } = await getEquipmentRequiredTrailers();
      setEquipmentTypes([...vehicleTypes, ...trailerTypes]);
    } catch (e) {
      // Do noting
    } finally {
      setLoadingEquipmentTypes(false);
    }
  };

  const getAllEquipment = async () => {
    try {
      setLoadingEquipment(true);
      const { data: vehicles } = await getVehicleList();
      const { data: trailers } = await getTrailerList();
      setEquipmentList([...vehicles, ...trailers]);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingEquipment(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const { url } = await generatePDF(
        reportItems,
        totalCost,
        download,
        dateRange,
        formatDateTime,
        formatDate,
        convertToCustomerTime,
        currency
      );
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const onExport = () => {
    if (!reportItems?.length) {
      return;
    }

    const titles = {
      equipment_id: 'Equipment ID',
      type: 'Type',
      description: 'Description',
      status: 'Status',
      service_date_time: 'Service Date/Time',
      service_at: 'Serviced At',
      cost: 'Cost',
      updated_on: 'Updated On',
      updated_by: 'Updated By',
    };

    const maintenanceRecords = reportItems.map((item) => ({
      equipment_id: item.equipment?.equipment_id || '-',
      type: item.record_type?.type || '-',
      description: item.description || '-',
      status: item.status?.status || '-',
      service_date_time: formatDateTime(item.service_date_time),
      service_at: item.service_at?.name || '-',
      cost: item.cost,
      updated_on: formatDate(item.updated_at),
      updated_by: item.added_by || 'System',
    }));

    const arrayToConvert = [titles, ...maintenanceRecords];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute(
      'download',
      `Maintenance Cost - ${
        dateRange.start && dateRange.end ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}` : 'All Time'
      }`
    );
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setInitialLoading(true);
    }
    getMaintenanceData();
    didMountRef.current = true;
  }, [dateRange, debouncedFilters]);

  useEffect(() => {
    getEquipmentTypes();
    getAllEquipment();
  }, []);

  return (
    <PageWrapper loading={initialLoading} title='Maintenance Cost'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName='This Month'
              type='allTime'
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Autocomplete
              multiple
              width='320px'
              limitTags={3}
              options={equipmentList}
              placeholder={!selectedFilters.equipmentIds.length ? 'Select Equipment..' : ''}
              value={selectedFilters.equipmentIds}
              loading={loadingEquipment}
              getTagLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type?.title}` : '')}
              getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type?.title}` : '')}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, equipmentIds: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable={false}
            />
            <Autocomplete
              multiple
              width='320px'
              limitTags={3}
              value={selectedFilters.equipmentTypes}
              options={equipmentTypes}
              loading={loadingEquipmentTypes}
              placeholder={!selectedFilters.equipmentTypes.length ? 'Equipment Type..' : ''}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, equipmentTypes: val }))}
              getTagLabel={(option) => (option ? option?.title : '')}
              getOptionLabel={(option) => (option ? option?.title : '')}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disableClearable={false}
            />
            <Autocomplete
              multiple
              width='320px'
              limitTags={2}
              value={selectedFilters.status}
              options={['Completed', 'Scheduled', 'Overdue']}
              placeholder={!selectedFilters.status.length ? 'Status..' : ''}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, status: val }))}
              disableClearable={false}
            />
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportItems.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={reportItems.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        <STable>
          <thead>
            <tr className='header-row'>
              <th>EQUIPMENT ID</th>
              <th>TYPE</th>
              <th>DESCRIPTION</th>
              <th>STATUS</th>
              <th>SERVICE DATE/TIME</th>
              <th>SERVICED AT</th>
              <th>COST</th>
              <th>UPDATED ON</th>
              <th>UPDATED BY</th>
            </tr>
          </thead>
          <tbody>
            {reportItems.map((item) => (
              <tr key={item.id} className='body-row'>
                <td>
                  <Typography variant='s2'>{item.equipment?.equipment_id || '-'}</Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.record_type?.type || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.description || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.status?.status || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatDateTime(item.service_date_time)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.service_at?.name || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatAmount(item.cost)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatDate(item.updated_at)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.added_by || 'System'}
                  </Typography>
                </td>
              </tr>
            ))}
            {!!reportItems.length && (
              <tr className='body-row'>
                <td>
                  <Typography variant='h5' style={{ color: palette.gray900 }}>
                    Total Cost
                  </Typography>
                </td>
                <td colSpan='5' />
                <td>
                  <Typography variant='h5' style={{ color: palette.gray900 }}>
                    {currency}
                    {formatAmount(totalCost)}
                  </Typography>
                </td>
                <td colSpan='2' />
              </tr>
            )}
          </tbody>
        </STable>
      </STableWrapper>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Maintenance Cost' />}
    </PageWrapper>
  );
};

export default MaintenanceCost;
