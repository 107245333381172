import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ setOpenFeatureNotAvailable }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li
        onClick={() => {
          setOpenFeatureNotAvailable(true);
          setAnchorEl(null);
        }}
      >
        Dispute
      </li>
    </ThreeDotActions>
  );
};

export default RowActions;
