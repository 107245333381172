import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import classes from 'components/TableShipments/tableShipments.module.css';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import { formatAmount } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { CURRENCY, palette, STOP_POINT_TAB } from 'utils/constants';
import hexRgb from 'hex-rgb';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

const getStatusParamsInStatus = (scheduledDate, status, pendingDispatch, canceled_date) => {
  let bgStatus;
  let colorStatus;
  let statusName;
  if (pendingDispatch) {
    bgStatus = '#F0F2F7';
    colorStatus = '#5A6376';
    statusName = `Pending Dispatch`;
  } else if (canceled_date) {
    bgStatus = '#FAF0F3';
    colorStatus = '#D12953';
    statusName = 'Canceled';
  } else {
    if (status === 'Offered') {
      bgStatus = '#F0F2F7';
      colorStatus = '#5A6376';
      statusName = `Offered`;
    } else if (status >= 24) {
      const daysCount = Math.floor(status / 24);
      bgStatus = '#EBF0FA';
      colorStatus = '#2264E6';
      statusName = `+${daysCount} DAYS SCHEDULED`;
    } else if (status >= 4 && status < 24) {
      bgStatus = '#EBF0FA';
      colorStatus = '#2264E6';
      statusName = 'SCHEDULED WITHIN 24H';
    } else if (status >= 0 && status < 4) {
      bgStatus = '#FFFAEB';
      colorStatus = '#B48909';
      statusName = 'SCHEDULED WITHIN 24H';
    } else if (status < 0) {
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      statusName = 'BEHIND SCHEDULE';
    }
  }

  return { bgStatus, colorStatus, statusName };
};

export const getShipmentStatusParamsInStatus = (status) => {
  const id = status?.id;
  let bgStatus;
  let colorStatus;
  const statusName = status?.title;

  switch (+id) {
    case 1:
      bgStatus = '#F0F2F7';
      colorStatus = palette.gray600;
      break;
    case 2:
      bgStatus = '#F0F2F7';
      colorStatus = palette.gray600;
      break;
    case 3:
      bgStatus = palette.indigo0;
      colorStatus = palette.indigo500;
      break;
    case 4:
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      break;
    case 5:
      bgStatus = palette.green0;
      colorStatus = palette.green500;
      break;
    case 6:
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      break;
    case 7:
      bgStatus = palette.green0;
      colorStatus = palette.green500;
      break;
    case 8:
      bgStatus = '#FFFAEB';
      colorStatus = '#B48909';
      break;
    case 10:
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      break;
    default:
  }

  return { bgStatus, colorStatus, statusName, id };
};

export const useColumns = ({ tableRowCheckboxChange, rowCheckBoxes }) => {
  const navigate = useNavigate();
  const { formatDate, formatTime } = useDateFormat();

  const equipmentTypeText = ({ type, action, equipment }) => {
    if (+type === 1) {
      const map = {
        1: 'Live Load',
        2: `Hook to ${equipment?.equipment_id}`,
        3: `Drop Trailer & Hook to ${equipment?.equipment_id}`,
      };
      return map[action];
    }
    if (+type === 2) {
      const map = { 1: 'Live Unload', 2: 'Drop Trailer' };
      return map[action];
    }
    if (+type === 3) {
      const map = {
        1: 'Live Unload',
        2: `Hook to ${equipment?.equipment_id}`,
        3: `Drop Trailer & Hook to ${equipment?.equipment_id}`,
        4: 'Other',
      };
      return map[action];
    }
  };

  function onClickRowIcon(e, rowData, type) {
    e.stopPropagation();
    switch (type) {
      case 'notes':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 6 } });
        break;
      default:
    }
  }

  return [
    {
      field: '',
      render: (rowData) => {
        const { status, pending_dispatch, isDispatched } = rowData;

        if (pending_dispatch || status === 'Offered' || isDispatched) {
          return null;
        }

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <CustomCheckbox
              labelStyle={{ marginTop: 5 }}
              checked={rowCheckBoxes.some((el) => rowData?.shipment_id === el.shipment_id)}
              onChange={() => tableRowCheckboxChange(rowData?.shipment_id)}
            />
          </div>
        );
      },
    },
    {
      field: 'shipment_id',
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' />,
      render: (rowData) => {
        const { shipment_notes } = rowData || {};
        const type = rowData?.shipment_type;
        const shipmentBilling = rowData.shipment_billing;

        return (
          <div>
            <div key={rowData.id} style={{ display: 'flex', alignItems: 'flex-start' }}>
              <span className='tablePlaner_shipment' style={{ color: palette.dark800 }}>
                {rowData.shipment_id}
              </span>
              <div className='flex-row d-flex align-items-center icons-wrapper '>
                {Array.isArray(shipment_notes) && shipment_notes?.length > 0 && (
                  <div onClick={(e) => onClickRowIcon(e, rowData, 'notes')}>
                    <NotesIcon width={14} height={14} style={{ marginLeft: 4, flexShrink: 0 }} />
                  </div>
                )}
              </div>
            </div>
            {(shipmentBilling && type !== 'ltl') || (type === 'ltl' && shipmentBilling.length === 1) ? (
              <Typography variant='s3' style={{ color: palette.dark800 }}>
                {shipmentBilling?.[0]?.reference_id}
              </Typography>
            ) : (
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='button-tooltip-2'>
                    {shipmentBilling?.map((el, i) => (
                      <p key={el?.id || i} style={{ margin: 0 }}>
                        {el?.reference_id}
                      </p>
                    ))}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div ref={ref} className='ellipsis'>
                    <Typography variant='s3' {...triggerHandler} style={{ color: palette.dark800 }}>
                      Multi-Ref LTL
                    </Typography>
                  </div>
                )}
              </OverlayTrigger>
            )}
          </div>
        );
      },
    },
    {
      field: 'customer',
      title: <ColumnHeader title='Customer' field='customer' />,
      render: (rowData) => {
        const type = rowData?.shipment_type;
        const shipmentBilling = rowData.shipment_billing;
        return shipmentBilling && shipmentBilling.length && shipmentBilling.length === 1 ? (
          <Typography
            variant='s3'
            style={{ color: palette.dark800 }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/customer-profile/${shipmentBilling?.[0]?.billing_customer?.id}`);
            }}
          >
            {shipmentBilling?.[0]?.billing_customer?.company_name}&nbsp;
            {type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
          </Typography>
        ) : shipmentBilling && shipmentBilling.length > 1 ? (
          <OverLayTriggerHoverWrapper
            overlay={
              <>
                {shipmentBilling.map((el, i) => (
                  <Typography
                    key={el?.id || i}
                    as='p'
                    variant='s2'
                    className='customerWrapper'
                    style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/customer-profile/${el?.billing_customer?.id}`);
                    }}
                  >
                    {el?.billing_customer?.company_name}
                  </Typography>
                ))}
              </>
            }
          >
            <div>
              <Typography variant='s3' style={{ color: palette.dark800 }}>
                Multi-Customer LTL
              </Typography>
            </div>
          </OverLayTriggerHoverWrapper>
        ) : null;
      },
    },
    {
      field: 'type',
      title: <Typography variant='c3'>TYPE</Typography>,
      render: (rowData) => {
        const typeLength = rowData.shipment_stops?.[0]?.equipment_type_length_value?.length;
        const equipmentType = rowData.shipment_stops?.[0]?.equipment_type_data?.title;
        const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;
        const firstNullArrivalDate = rowData.shipment_stops?.find((stop) => !stop.arrival_date);

        return (
          <div className='d-flex flex-column'>
            <span style={{ color: palette.dark800 }}>{type}</span>
            <Typography variant='s3' style={{ color: palette.dark800 }}>
              {!firstNullArrivalDate?.stop_point_type
                ? '-'
                : equipmentTypeText({
                    type: firstNullArrivalDate?.stop_point_type,
                    action: firstNullArrivalDate?.equipment_action,
                    equipment: firstNullArrivalDate?.equipment,
                  })}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'amount',
      title: <ColumnHeader title='Amount' field='amount' />,
      render: (rowData) => {
        let totalAmount = 0;

        rowData?.shipment_billing?.forEach((el) => {
          totalAmount += Number(el?.total_amount);
        });
        const formattedAmount = formatAmount(totalAmount.toFixed(2));
        const currency = Object.values(CURRENCY).find(
          (item) => item.id === Number(rowData?.shipment_billing?.[0]?.currency_id)
        );

        return (
          <Typography variant='s3' style={{ color: palette.dark800 }}>
            {currency?.symbol || '$'}
            {formattedAmount}
          </Typography>
        );
      },
    },
    {
      field: 'groups',
      title: <ColumnHeader title='Tags' field='groups' />,
      render: (rowData) => {
        const shipmentBilling = rowData.shipment_billing?.filter((i) => !!i.groups);
        return shipmentBilling?.map((el, i) => {
          const colorCode = hexRgb(el.groups.color);
          const textColor =
            colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? palette.white : palette.gray900;
          return (
            <div key={el?.id || i} className='flex-row d-flex table_planer_groups_wrapper'>
              <div
                className={`${classes.shortName}`}
                style={{
                  color: textColor,
                  backgroundColor: el?.groups?.color,
                }}
              >
                {el?.groups?.short_name}
              </div>
            </div>
          );
        });
      },
    },
    {
      field: 'stops',
      title: <ColumnHeader title='Stops' field='stops' />,
      render: (rowData) => {
        const stops = rowData.shipment_stops.length;
        return (
          <Typography variant='s3' style={{ color: palette.dark800, marginLeft: '14px' }}>
            {stops}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      title: <ColumnHeader title='Status' field='status' />,
      render: (rowData) => {
        const scheduledDate = rowData.shipment_stops[0].scheduled_date;
        const status = rowData?.status;
        const pendingDispatch = rowData?.pending_dispatch;
        const canceled_date = rowData?.canceled_at;
        const paramsInStatus = rowData.isDispatched
          ? getShipmentStatusParamsInStatus(rowData.status)
          : getStatusParamsInStatus(scheduledDate, status, pendingDispatch, canceled_date);
        const { bgStatus, colorStatus, statusName } = paramsInStatus || {};
        return (
          <div className='d-flex justify-content-start'>
            <div className='tablePlaner_status-wrapper'>
              <span className='tablePlaner_status' style={{ backgroundColor: bgStatus, color: colorStatus }}>
                {statusName}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'origin_stop_name',
      title: <Typography variant='c3'>ORIGIN</Typography>,
      render: (rowData) => {
        const originStopName = rowData.shipment_stops[0]?.stop_point?.location_name;
        const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
        const id = rowData.shipment_stops[0]?.stop_point?.id;
        const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;

        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;
        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOrigin} ${toData || ''}`;

        return (
          <>
            <Typography
              variant='b3'
              className={classes.originNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {originStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(scheduledOrigin)} </Typography>
              <span style={{ color: palette.dark800 }}>
                {fromData ? ` ${formatTime(from)}` : ''}
                {toData ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </>
          </>
        );
      },
    },
    {
      field: 'destination_stop_name',
      title: <Typography variant='c3'>DESTINATION</Typography>,
      render: (rowData) => {
        const destinationStopName =
          rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.location_name;
        const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;
        const id = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.id;

        const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
        const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
        const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;

        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestination} ${toData || ''}`;

        return (
          <>
            <Typography
              variant='b3'
              className={classes.destinationNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {destinationStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(scheduledDestination)} </Typography>
              <span style={{ color: palette.dark800 }}>
                {fromData ? `${formatTime(from)}` : ''}
                {toData ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </>
          </>
        );
      },
    },
    {
      field: 'miles',
      title: <ColumnHeader title='Miles' field='miles' />,
      render: (rowData) => {
        const miles = Math.round(rowData?.loaded_miles) + Math.round(rowData?.empty_miles || 0);
        return <span style={{ color: palette.dark800 }}>{miles && miles}</span>;
      },
    },
  ];
};
