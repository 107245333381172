import React, { useEffect, useRef, useState } from 'react';
import { getStatsGraph } from 'Api/AccountingReceivables';
import moment from 'moment';
import Accounts from '../../Payables/StatsSection/components/Accounts';
import StatBox from './components/StatBox';
import PaidInvoices from './components/PaidInvoices';
import TotalOpenReceivables from './components/TotalOpenReceivables';
import ReadyMissingPaperworkInvoices from './components/ReadyMissingPaperworkInvoices';
import { statsDataConverter } from './converters';
import { initialStats, initialYearFilters, statTypeRangeMapper } from './StatsSection.data';
import { SWrapper } from './StatsSection.styles';

const StatsSection = ({ changeTab, statsRefreshIndex }) => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState(initialStats);
  const [yearFilters, setYearFilters] = useState(initialYearFilters);
  const didMountRef = useRef(false);

  const getStats = async () => {
    try {
      const params = {
        miles_from_date: moment(yearFilters.miles_range.start).format('YYYY-MM-DD'),
        miles_to_date: moment(yearFilters.miles_range.end).format('YYYY-MM-DD'),
        revenue_from_date: moment(yearFilters.revenue_range.start).format('YYYY-MM-DD'),
        revenue_to_date: moment(yearFilters.revenue_range.end).format('YYYY-MM-DD'),
        closed_from_date: moment(yearFilters.closed_range.start).format('YYYY-MM-DD'),
        closed_to_date: moment(yearFilters.closed_range.end).format('YYYY-MM-DD'),
        graphReadyAndMissingYear: yearFilters.graphReadyAndMissingYear,
        graphOpenReceivablesYear: yearFilters.graphOpenReceivablesYear,
        graphOpenReceivablesMonth: yearFilters.graphOpenReceivablesMonth,
        closed_month: yearFilters.closed_month,
      };

      const { data } = await getStatsGraph(params);

      const convertedData = statsDataConverter(data);
      setStats(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onRangeChange = (key, range) => {
    setYearFilters((prevState) => ({ ...prevState, [key]: range }));
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
      didMountRef.current = true;
    }
    getStats();
  }, [yearFilters, statsRefreshIndex]);

  return (
    <SWrapper>
      <div className='scrollable-container'>
        <div className='chart-boxes-wrapper' key={statsRefreshIndex}>
          <ReadyMissingPaperworkInvoices
            loading={loading}
            onTitleClick={(tab) => changeTab(tab)}
            readyAndMissing={stats.readyAndMissing}
          />
          <TotalOpenReceivables
            loading={loading}
            onTitleClick={() => changeTab('open_invoices')}
            openReceivables={stats.openReceivables}
          />
          <PaidInvoices loading={loading} paidInvoices={stats.pieChart} />
          <Accounts />
        </div>
      </div>
      <div className='scrollable-container'>
        <div className='stat-boxes-wrapper'>
          {Object.keys(stats.statsSection || {})?.map((key) => (
            <StatBox
              key={key}
              title={key}
              data={stats.statsSection[key]}
              loading={loading}
              range={yearFilters[statTypeRangeMapper[key]]}
              onRangeChange={(range) => onRangeChange(statTypeRangeMapper[key], range)}
              onTitleClick={(tab) => changeTab(tab)}
            />
          ))}
        </div>
      </div>
    </SWrapper>
  );
};

export default StatsSection;
