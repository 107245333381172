import styled from 'styled-components';
import { palette } from 'utils/constants';
import LinearProgress from '@mui/material/LinearProgress';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';

export const SBidPreview = styled.div`
  width: 36%;
  padding: 24px 20px;
  border-radius: 8px;
  background: ${palette.white}BB;
  backdrop-filter: blur(4px);
  box-shadow: 0 0 0 1px rgba(152, 161, 178, 0.1), 0 1px 4px rgba(69, 75, 87, 0.12), 0 0 2px rgba(0, 0, 0, 0.08);
  z-index: 3;
  position: relative;

  .close-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    background-color: ${palette.gray50};
    border-radius: 4px;

    .MuiLinearProgress-bar {
      background-color: ${({ $bgColor }) => $bgColor || palette.green400};
    }
  }
`;

export const SSubmitBid = styled.div`
  .form-wrapper {
    padding: 4px 6px 0 2px;

    ::-webkit-scrollbar {
      width: 8px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${palette.gray200};
      border-radius: 8px;
    }
  }

  .form-row {
    display: flex;
    margin-bottom: 18px;

    & > div:first-child {
      width: 170px;
    }
  }

  .submit-bid-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }

  .react-timer-picker_wrapper {
    width: 72px !important;
    height: 26px !important;
    margin: 0 !important;

    .react-time-input-picker-wrapper {
      border: none;
      height: 100%;
      margin: 0;
      padding: 0;

      .is-disabled {
        background: ${palette.gray0};
      }
    }
  }

  .can-handle-box {
    width: 196px;
  }
`;

export const STimeline = styled(Timeline)`
  &.MuiTimeline-root {
    position: relative;
    padding-right: 0;

    .MuiTimelineContent-root {
      padding: 6px 10px;
    }

    .miles {
      display: flex;
      column-gap: 12px;
      position: absolute;
      transform: rotate(-90deg);
      top: 90px;
      left: -66px;
    }
  }
`;

export const STimelineDot = styled(TimelineDot)`
  &&.MuiTimelineDot-root {
    margin: 6px 0;
    border: 2px solid ${palette.white};
    background: ${({ $bgColor }) => $bgColor || palette.green500};
    color: ${palette.white};
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    box-shadow: 0 2px 5px 0 rgba(89, 96, 120, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
