import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const ConfirmModal = ({ open, onClose, title, text, onConfirm, disabled, btnType, btnText, width }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={title}
      $maxWidth={width || '470px'}
      $minWidth={width || '470px'}
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: btnType || 'danger',
          title: btnText || 'Delete',
          onClick: onConfirm,
          disabled,
        },
      ]}
    >
      <Typography variant='s1' as='p' className='mt-3 mb-3' style={{ color: palette.gray700 }}>
        {text}
      </Typography>
    </Modal>
  );
};

export default ConfirmModal;
