import React, { useContext, useMemo, useRef, useState } from 'react';

import { useOutletContext } from 'react-router-dom';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { UpdateUserProfile } from 'Api/Customers';
import { useAuth } from 'context/auth.context';
import styles from './Profile.module.css';
import ProfileEdit from './ProfileEdit';
import ProfilePreview from './ProfilePreview';
import HeaderStar from '../HeaderStar';
import CustomButton from '../CustomButton/CustomButton';
import { LayoutContext } from '../layout';

const Profile = () => {
  const { userData: contextUserData, onSetUserData } = useContext(LayoutContext);
  const showToaster = useShowToaster();
  const { getLocalStorageData } = useOutletContext();
  const [isEdit, setIsEdit] = useState(false);
  const submitForm = useRef(null);
  const { value, setAuth } = useAuth();
  const userData = JSON.parse(localStorage.getItem('user'));

  const onSubmit = (values) => {
    const formData = new FormData();

    for (const key in values) {
      formData.append(key, values[key]);
    }

    UpdateUserProfile(formData)
      .then((res) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const newUserData = JSON.stringify({
          accessToken: user?.accessToken,
          ...res.data,
        });
        onSetUserData({ ...contextUserData, profileLogo: res?.data?.profile_logo });
        localStorage.setItem('user', newUserData);
        setAuth({ ...value, user: res.data });
        getLocalStorageData();
        setIsEdit(false);
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      });
  };

  const children = useMemo(() => {
    return isEdit ? (
      <ProfileEdit
        userData={userData}
        onSubmit={onSubmit}
        bindSubmitForm={(submitFormFn) => {
          submitForm.current = submitFormFn;
        }}
      />
    ) : (
      <ProfilePreview userData={userData} />
    );
  }, [isEdit, userData]);

  const actions = useMemo(() => {
    return isEdit ? (
      <div className={styles.edit_wrapper}>
        <CustomButton
          onClick={() => setIsEdit(false)}
          type='secondary'
          title='Cancel'
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          className={styles.action}
          styleButton={{ marginRight: 16 }}
        />

        <CustomButton
          onClick={() => submitForm?.current?.()}
          type='primary'
          title='Save'
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          className={styles.action}
        />
      </div>
    ) : (
      <div className={styles.edit_wrapper}>
        <CustomButton
          onClick={() => setIsEdit(true)}
          type='secondary'
          title='Edit'
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          leftIcon={<EditIcon width={12} height={12} style={{ marginRight: 10 }} />}
          className={styles.action}
        />
      </div>
    );
  }, [isEdit]);

  return (
    <div>
      <div id='profile' className={styles.profileHeader_wrapper}>
        <HeaderStar title='Profile' />
        <div className={styles.actions}>{actions}</div>
      </div>
      {children}
    </div>
  );
};

export default Profile;
