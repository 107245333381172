import React, { useState, useEffect, useRef, createRef } from 'react';
import { OverlayTrigger, ProgressBar, Popover } from 'react-bootstrap';
import Pagination from 'common/Pagination';
import { Typography } from 'components/Typography';
import LogHistory from 'components/SecurityLog/LogHistory';
import CustomButton from 'components/CustomButton/CustomButton';
import { useColumns } from 'components/SecurityLog/SecurityLog.data';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { TableContext } from 'context/tableContext';
import { clearLoginLock, GetSecurityLogList, GetSecurityLogStatus } from 'Api/SecurityLog';
import { createSMSCommunicationSettings, getSMSCommunicationSettings } from 'Api/CompanySettings';
import SecurityLogHeader from './SecurityLogHeader';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import MaterialTableWrapper from '../MaterialTableWrapper';
import SecurityLogFilter from './SecurityLogFilter/SecurityLogFilter';
import SecurityLogSearch from './SecurityLogSearch/SecurityLogSearch';
import Preloader from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';

const SecurityLog = ({ setActiveTab }) => {
  const showToaster = useShowToaster();
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [dragItem, setDragItem] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [securityLogList, setSecurityLogList] = useState({ data: [] });
  const [communicationSettings, setCommunicationSettings] = useState(null);
  const [securityLogStatusesList, setSecurityLogStatusesList] = useState([]);
  const [loadingClearLock, setLoadingClearLock] = useState(false);
  const [logToViewHistory, setLogToViewHistory] = useState(null);
  const [filter, setFilter] = useState({
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    filterTableTop: [],
    selectedValues: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    tab: 0,
    page: 1,
    itemsPerPage: 50,
    sort: { field: '', nested_field: 'created_at', sortBy: 'desc' },
  });
  const elementsRef = useRef();

  useEffect(() => {
    async function init() {
      try {
        const { types } = selectedFilters || {};

        const getStatues = await GetSecurityLogStatus({
          user_type: types,
        });
        setSecurityLogStatusesList(getStatues.data);
        setTotalCount(getStatues.data.reduce((sum, item) => sum + item.status_count, 0));
        setFilter((prevState) => {
          return {
            ...prevState,
            filterTableTop: [
              ...getStatues.data.map((item) => {
                return {
                  key: item.id,
                  label: item.status,
                  value: item.id,
                  count: item.status_count,
                  id: item.id,
                  color: item.color,
                };
              }),
            ],
          };
        });
      } catch (e) {
        // Do nothing
      }
    }

    init();
  }, [selectedFilters.types]);

  const getSecurityLogData = async () => {
    setLoading(true);
    const { page, itemsPerPage, types, tab } = selectedFilters || {};
    const sortField = `sort[${selectedFilters.sort.nested_field || ''}][${selectedFilters.sort.field}]`;
    try {
      const params = {
        page,
        itemsPerPage,
        query: debouncedSearch,
        user_type: types,
        version_status: tab ? [tab] : undefined,
        [sortField]: selectedFilters.sort.sortBy,
      };

      const securityLogs = await GetSecurityLogList(params);

      const refs = {};
      securityLogs?.data.forEach((item) => {
        refs[item.id] = createRef();
      });
      elementsRef.current = refs;

      setSecurityLogList(securityLogs);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onSettingsChange = async (checked, isDriver = false) => {
    try {
      if (isDriver) {
        const { data } = await createSMSCommunicationSettings({ send_otp_to_driver: Number(checked) });
        setCommunicationSettings(data);
      } else {
        const { data } = await createSMSCommunicationSettings({ send_otp_to_user: Number(checked) });
        setCommunicationSettings(data);
      }
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getCommunicationSettings = async () => {
    try {
      const { data } = await getSMSCommunicationSettings();
      setCommunicationSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSecurityLogData();
  }, [debouncedSearch, selectedFilters]);

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters({ ...selectedFilters, page });
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const updateSelectedFilter = (updatedFilter) => {
    setSelectedFilters(updatedFilter);
  };

  const sortingQuery = (field, nested_field) => {
    const direction = selectedFilters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSelectedFilters((prevState) => ({
      ...prevState,
      sort: { field, nested_field, sortBy: direction },
    }));
  };

  function isWhatPercentOf(numA, numB) {
    return (numA / numB) * 100;
  }

  const onChangeTab = (n) => {
    setSelectedFilters({ ...selectedFilters, tab: +n.value });
  };

  const clearLock = async (userId) => {
    if (loadingClearLock) {
      return;
    }

    try {
      setLoadingClearLock(true);
      await clearLoginLock({ user_id: userId });
      showToaster({ type: 'success', message: 'User has been successfully unlocked!' });
      getSecurityLogData();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingClearLock(false);
    }
  };

  const onRowClick = (row) => {
    setLogToViewHistory(row);
  };

  useEffect(() => {
    getCommunicationSettings();
  }, []);

  const columns = useColumns({ sort: selectedFilters.sort, sortingQuery, clearLock });

  return (
    <div>
      <TableContext.Provider
        value={{
          filter,
          dragItem,
          updateFilter,
          onChangeOrder: (items) => setDragItem(items),
          securityLogList,
          securityLogStatusesList,
        }}
      >
        <SecurityLogHeader />
        <div className='d-flex justify-content-between flex-wrap'>
          <SecurityLogSearch search={search} onChange={setSearch} />
          <div className='d-flex align-items-center gap-4 flex-wrap'>
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                name='send_otp_to_driver'
                checked={!!communicationSettings?.send_otp_to_driver}
                onChange={(checked) => onSettingsChange(checked, true)}
                smail={false}
              />
              <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                OTP 2FA requirement for Drivers
              </Typography>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                name='send_otp_to_user'
                checked={!!communicationSettings?.send_otp_to_user}
                onChange={onSettingsChange}
                smail={false}
              />
              <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                OTP 2FA requirement for Users
              </Typography>
            </div>
            <CustomButton
              type='secondary'
              title='Departments & Permissions'
              styleButton={{ margin: '16px 0', height: '32px' }}
              styleTitle={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                setActiveTab('Departments');
              }}
            />
          </div>
        </div>
        <SecurityLogFilter
          data={[]}
          disabledFilters={{}}
          filter={selectedFilters}
          updateFilter={updateSelectedFilter}
          handleReset={() => setSearch('')}
        />
        <ProgressBar className='w-100' style={{ height: 16, borderRadius: 8 }}>
          {securityLogStatusesList?.map((item, index) => {
            return (
              <OverlayTrigger
                key={item.color}
                placement='top'
                overlay={
                  <Popover
                    id={`tooltip-${index}`}
                    style={{
                      maxWidth: 500,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3))',
                    }}
                  >
                    <Popover.Body>
                      <div>
                        <span
                          style={{
                            display: 'inline-block',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: item.color,
                            marginRight: 6,
                          }}
                        />
                        <span style={{ marginRight: 8 }}>{item.status}</span>
                        <span
                          style={{
                            marginRight: 40,
                            width: 31,
                            height: 16,
                            backgroundColor: '#E9EDF5',
                            borderRadius: '8px',
                            padding: '0 8px',
                          }}
                        >
                          {item?.status_count}
                        </span>
                        <span>{isWhatPercentOf(item?.status_count, totalCount).toFixed(1)}%</span>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <ProgressBar
                  isChild
                  style={{ height: '100%', background: item.color }}
                  now={(item?.status_count || 0) * 100}
                  key={item.color}
                />
              </OverlayTrigger>
            );
          })}
        </ProgressBar>
        <SwitchRadio
          color
          name='tableTopTabMenu'
          items={[
            {
              key: 'all',
              label: 'All',
              value: 0,
              id: 0,
              count: totalCount || 0,
            },
            ...filter.filterTableTop,
          ]}
          value={selectedFilters.tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
      </TableContext.Provider>

      {loading ? (
        <Preloader />
      ) : (
        <MaterialTableWrapper
          data={securityLogList?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: () =>
              Pagination({
                data: securityLogList,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          onRowClick={(e, row) => onRowClick(row)}
          columns={columns}
        />
      )}
      {!!logToViewHistory && (
        <LogHistory
          open={!!logToViewHistory}
          onClose={() => setLogToViewHistory(false)}
          securityLog={logToViewHistory}
          onClearLockSuccess={getSecurityLogData}
        />
      )}
    </div>
  );
};

export default SecurityLog;
