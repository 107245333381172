export const reportItemsConverter = (data) => {
  if (!data) {
    return [];
  }

  return Object.keys(data).map((key) => {
    const listData = {
      customerName: key,
      invoices: [],
      total_balance: data[key].total_balance,
      total_amount: data[key].total_amount,
    };

    Object.entries(data[key]).forEach((item) => {
      if (!Number.isNaN(Number(item[0]))) {
        listData.invoices.push(item[1]);
      }
    });

    return listData;
  });
};

export const transformReportItems = (reportItems, isPdf) => {
  const transformed = [];

  reportItems.forEach((item, index) => {
    const itemWithoutInvoices = { ...item, isCustomer: true };
    delete itemWithoutInvoices.invoices;

    if (index !== 0 && isPdf) {
      transformed.push({ isBlank: true }, { isBlank: true });
    }
    transformed.push(itemWithoutInvoices);

    item.invoices.forEach((invoice, i) => {
      transformed.push(invoice);
      if (i === item.invoices.length - 1) {
        transformed.push({
          customerName: item.customerName,
          total_amount: item.total_amount,
          total_balance: item.total_balance,
          isTotal: true,
        });
      }
    });
  });

  return transformed;
};
