import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import Pagination from 'common/Pagination';
import { useTheme } from 'context/themeContext';
import ColumnHeader from 'common/ColumnHeader';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment/moment';
import useDateFormat from 'hooks/useDateFormat';
import Chip from 'common/Chip';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import UserInfo from 'components/UserInfo';
import AddIncidentModal from 'components/AddIncidentModal';
import {
  acknowledgeCrash,
  acknowledgeInspections,
  acknowledgeViolations,
  getAllIncidents,
  getCrashes,
  getIncidentById,
  getInspections,
  getReportStates,
  getTabsCount,
  getViolations,
  updateCrash,
  updateInspections,
  updateViolations,
} from 'Api/DotMonitoring';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import CustomButton from 'components/CustomButton/CustomButton';
import { getAllTrailers, getAllVehicles } from 'Api/Map';
import Autocomplete from 'common/Autocomplete';
import { getDrivers } from 'Api/EquipmentProfile';
import { Typography } from 'components/Typography';
import Popover from '@mui/material/Popover';
import { getRulesAndConduct } from 'Api/CompanySettings';
import { initialFilters } from '../TableSection.data';
import SafetyFilters from './SafetyFilters';
import classes from './SafetyRecords.module.css';
import Description from './Description';
import CreateOrAssign from './CreateOrAssign';
import SelectExistingIncident from './SelectExistingIncident';
import IncidentDetailsModal from '../../../../components/ViewIncidentModal';
import RuleDetails from '../../../RulesAndConduct/components/RuleDetails';

let controller = null;

const SafetyRecords = ({ driverId, vehicleId }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [reportedStates, setReportedStates] = useState([]);
  const [states, setStates] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [inspections, setInspections] = useState({ data: [] });
  const [violations, setViolations] = useState({ data: [] });
  const [crashes, setCrashes] = useState({ data: [] });
  const [tabs, setTabs] = useState([]);
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, 500);
  const [dayFilter, setDayFilter] = useState();
  const [sort, setSort] = useState({ field: 'date', sortBy: 'desc' });
  const [vehicleData, setVehicleData] = useState([]);
  const [trailerData, setTrailerData] = useState([]);
  const [driverData, setDriverData] = useState([]);

  const [openCreateIncident, setOpenCreateIncident] = useState(false);
  const [editData, setEditData] = useState(null);
  const [refreshData, setRefreshData] = useState(null);
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const [selectExistingOpen, setSelectExisting] = useState(false);
  const [allIncident, setAllIncident] = useState(null);
  const [faultCode, setFaultCode] = useState(null);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [createOrAssignOpen, setCreateOrAssignOpen] = useState(faultCode);
  const [viewData, setViewData] = useState(null);
  const [ruleToView, setRuleToView] = useState(null);
  const [rules, setRules] = useState([]);

  const onChangeTab = (tab) => {
    setSearch('');
    setStates([]);
    setSeverity([]);
    setSort({ field: 'date', sortBy: 'desc' });
    setActiveTab(tab.id);
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  useEffect(() => {
    const params = {
      'filters[driver_id]': driverId,
      'filters[vehicle_id]': vehicleId,
      'filters[from]': dayFilter?.from || undefined,
      'filters[to]': dayFilter?.to || undefined,
    };

    if (states?.length) {
      params['filters[report_state]'] = states;
    }

    if (severity?.length) {
      severity.forEach((serv, index) => {
        params[`filters[severity_weight][${index}]`] = serv.value;
      });
    }

    getTabsCount(params).then((res) => {
      setTabs([
        {
          label: 'Inspections',
          count: res?.data?.inspection_count,
          id: 1,
        },
        {
          label: 'Violations',
          count: res?.data?.violation_count,
          id: 2,
        },
        {
          label: 'Crashes',
          count: res?.data?.crash_count,
          id: 3,
        },
      ]);
    });

    getReportStates(params).then((res) => {
      setReportedStates(res?.data);
    });
  }, [selectedFilters, dayFilter, debouncedSearch, states, sort, severity, refreshData]);

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    getIncidents();
  }, [refreshData]);

  useEffect(() => {
    controller && controller.abort();
    controller = new AbortController();

    const params = {
      page: selectedFilters.page,
      itemsPerPage: selectedFilters.itemsPerPage,
      'filters[query]': debouncedSearch || undefined,
      'filters[from]': dayFilter?.from || undefined,
      'filters[to]': dayFilter?.to || undefined,
      'filters[driver_id]': driverId || undefined,
      'filters[vehicle_id]': vehicleId || undefined,
    };

    setLoading(!sort.field);
    if (sort.field) {
      params[`sort[][${sort.field}]`] = sort.sortBy;
    }

    if (states?.length) {
      params['filters[report_state]'] = states;
    }

    if (severity?.length) {
      severity.forEach((serv, index) => {
        params[`filters[severity_weight][${index}]`] = serv.value;
      });
    }

    if (activeTab === 1) {
      getInspections(params, controller.signal)
        .then((res) => {
          setInspections(res);
          setLoading(false);
        })
        .catch(() => {
          // Do nothing
        });
    }

    if (activeTab === 2) {
      getViolations(params, controller.signal)
        .then((res) => {
          setViolations(res);
          setLoading(false);
        })
        .catch(() => {
          // Do nothing
        });
    }

    if (activeTab === 3) {
      getCrashes(params, controller.signal)
        .then((res) => {
          setCrashes(res);
          setLoading(false);
        })
        .catch(() => {
          // Do nothing
        });
    }
  }, [activeTab, selectedFilters, dayFilter, debouncedSearch, states, sort, severity, refreshData]);

  useEffect(async () => {
    const vehicles = await getAllVehicles(null, null, null, null);
    setVehicleData(vehicles.data);

    const trailers = await getAllTrailers(null, null, null, null);
    setTrailerData(trailers.data);

    const drivers = await getDrivers();
    setDriverData(drivers.data);
  }, []);

  const getIncidents = async () => {
    try {
      const params = {
        page: 1,
        itemsPerPage: 10000,
      };

      const response = await getAllIncidents(params);
      setAllIncident(response?.data);
    } catch (e) {
      // do something
    } finally {
      setLoading(false);
    }
  };

  const changeToViolation = (report_number) => {
    setActiveTab(2);
    setSearch(report_number);
  };

  const getStatusLabel = (value) => {
    if (value === undefined || value === null) {
      return { name: 'Passed', color: palette.green400, bgColor: palette.green0 };
    }
    if (value >= 8 && value <= 10) {
      return { name: 'High', color: palette.red400, bgColor: palette.red0 };
    }
    if (value >= 5 && value <= 7) {
      return { name: 'Medium', color: palette.orange500, bgColor: palette.orange0 };
    }
    if (value >= 1 && value <= 4) {
      return { name: 'Low', color: palette.indigo400, bgColor: palette.indigo0 };
    }
    return { name: 'Invalid', color: 'grey' };
  };

  const createIncident = (row) => {
    setCreateOrAssignOpen(false);
    setSelectedIncident(null);
    setFaultCode(null);
    setEditData(row);
    setOpenCreateIncident(true);
  };

  const selectIncident = (row) => {
    setEditData(row);
    setCreateOrAssignOpen(false);
    setFaultCode(null);
    setSelectExisting(true);
  };

  const onSelectDate = (dates) => {
    const { start, end } = dates;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');
    setDayFilter({
      from: startFormat,
      to: endFormat,
    });
  };

  const acknowledge = (row) => {
    if (activeTab === 1) {
      acknowledgeInspections(row.id)
        .then(() => {
          showToaster({ type: 'success', message: 'Success!' });
          setRefreshData(new Date().getTime());
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    } else if (activeTab === 2) {
      acknowledgeViolations(row.id)
        .then(() => {
          showToaster({ type: 'success', message: 'Success!' });
          setRefreshData(new Date().getTime());
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    } else {
      acknowledgeCrash(row.id)
        .then(() => {
          showToaster({ type: 'success', message: 'Success!' });
          setRefreshData(new Date().getTime());
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const updateIncident = (body) => {
    if (activeTab === 1) {
      updateInspections(editData.id, body)
        .then(() => {
          showToaster({ type: 'success', message: 'Success!' });
          setRefreshData(new Date().getTime());
          setEditData(null);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    } else if (activeTab === 2) {
      updateViolations(editData.id, body)
        .then(() => {
          showToaster({ type: 'success', message: 'Success!' });
          setRefreshData(new Date().getTime());
          setEditData(null);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    } else {
      updateCrash(editData.id, body)
        .then(() => {
          showToaster({ type: 'success', message: 'Success!' });
          setRefreshData(new Date().getTime());
          setEditData(null);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const showIncident = async (e, incident_id) => {
    e.stopPropagation();
    const { data } = await getIncidentById(incident_id);
    if (data) {
      setViewData(data);
    }
  };

  const getRules = async () => {
    try {
      const { data } = await getRulesAndConduct();
      setRules(data);
    } catch (e) {
      // Do nothing
    }
  };

  const inspectionColumns = [
    {
      field: 'date',
      title: <ColumnHeader title='Date' field='date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { date } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{formatDate(date) || '-'}</span>;
      },
    },
    ...(!driverId
      ? [
          {
            field: 'driver',
            title: <ColumnHeader title='Driver' field='driver' sort={sort} onClick={sortingQuery} />,
            render: (rowData) => {
              return rowData.driver ? (
                <div className='d-flex align-items-center gap-3'>
                  <UserInfo
                    size='28px'
                    data={{
                      ...rowData,
                      id: rowData.driver?.id,
                      name: `${rowData.driver?.fname} ${rowData?.driver?.lname}`,
                      image: rowData.driver?.image,
                      email: rowData.driver?.email,
                      phone_number: rowData.driver?.phone_number,
                      status: rowData.driver?.status_data,
                      user_type: 'driver',
                    }}
                  />
                  <DeleteIcon
                    fill={palette.red500}
                    onClick={(e) => {
                      e.stopPropagation();
                      const body = {
                        driver_id: null,
                      };
                      updateInspections(rowData.id, body)
                        .then(() => {
                          showToaster({ type: 'success', message: 'Success!' });
                          setRefreshData(new Date().getTime());
                        })
                        .catch((err) => {
                          showToaster({ type: 'error', message: getErrorMessage(err) });
                        });
                    }}
                  />
                </div>
              ) : (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Autocomplete
                    placeholder='Select Driver'
                    options={driverData}
                    getOptionLabel={(option) => {
                      return option ? `${option.fname} ${option.lname}` : '';
                    }}
                    style={{ minWidth: 150 }}
                    onChange={(e, val) => {
                      const body = {
                        driver_id: val.id,
                      };
                      updateInspections(rowData.id, body)
                        .then(() => {
                          showToaster({ type: 'success', message: 'Success!' });
                          setRefreshData(new Date().getTime());
                        })
                        .catch((err) => {
                          showToaster({ type: 'error', message: getErrorMessage(err) });
                        });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </span>
              );
            },
          },
        ]
      : []),
    {
      field: 'report_state',
      title: <ColumnHeader title='Report State' field='report_state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_state } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_state}</span>;
      },
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_number}</span>;
      },
    },
    {
      field: 'vehicle_unit_type_description',
      title: (
        <ColumnHeader
          title='Vehicle Inspected'
          field='vehicle_unit_type_description'
          sort={sort}
          onClick={sortingQuery}
        />
      ),
      render: (rowData) => {
        const { vehicle } = rowData;
        const { equipment_id, equipment_type } = vehicle || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {equipment_type?.title || ''}
                </Typography>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      vehicle_id: null,
                    };
                    updateInspections(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Autocomplete
                  labelKey='equipment_id'
                  placeholder='Select Vehicle'
                  options={vehicleData}
                  getOptionLabel={(option) => {
                    return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                  }}
                  style={{ minWidth: 150 }}
                  onChange={(e, val) => {
                    const body = {
                      vehicle_id: val.id,
                    };
                    updateInspections(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </span>
            )}
          </span>
        );
      },
    },
    {
      field: 'vehicle_unit_vin',
      title: <ColumnHeader title='VEHICLE VIN' field='vehicle_unit_vin' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_unit_vin } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_unit_vin}</span>;
      },
    },
    {
      field: 'vehicle_unit_license',
      title: <ColumnHeader title='VEHICLE PLATE' field='vehicle_unit_license' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_unit_license } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_unit_license}</span>;
      },
    },
    {
      field: 'trailer_unit_make',
      title: <ColumnHeader title='TRAILER INSPECTED' field='trailer_unit_make' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer } = rowData;
        const { equipment_type, length, equipment_id } = trailer || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {length?.length || ''} {equipment_type?.title || ''}
                </Typography>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      trailer_id: null,
                    };
                    updateInspections(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Autocomplete
                  labelKey='equipment_id'
                  placeholder='Select Trailer'
                  options={trailerData}
                  getOptionLabel={(option) => {
                    return option
                      ? `${option?.equipment_id} ${option?.length?.length || ''} ${option?.equipment_type?.title || ''}`
                      : '';
                  }}
                  style={{ minWidth: 150 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e, val) => {
                    const body = {
                      trailer_id: val.id,
                    };
                    updateInspections(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </span>
            )}
          </span>
        );
      },
    },
    {
      field: 'trailer_unit_vin',
      title: <ColumnHeader title='TRAILER VIN' field='trailer_unit_vin' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer_unit_vin } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{trailer_unit_vin || '-'}</span>;
      },
    },
    {
      field: 'trailer_unit_license',
      title: <ColumnHeader title='TRAILER PLATE' field='trailer_unit_license' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer_unit_license } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{trailer_unit_license || '-'}</span>;
      },
    },
    {
      field: 'time_weight',
      title: <ColumnHeader title='Time Weight' field='time_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { time_weight } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{time_weight || '-'}</span>;
      },
    },
    {
      field: 'violation_severity_weight',
      title: (
        <ColumnHeader title='SEVERITY WEIGHT' field='violation_severity_weight' sort={sort} onClick={sortingQuery} />
      ),
      render: (rowData) => {
        const { violation_severity_weight } = rowData;
        const { color, bgColor, name } = getStatusLabel(violation_severity_weight);
        return <Chip labelColor={color} bgColor={bgColor} label={name?.toUpperCase()} />;
      },
    },
    {
      field: 'violation_per_inspection',
      title: <ColumnHeader title='VIOLATION' field='violation_per_inspection' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_per_inspection, report_number } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {violation_per_inspection > 0 ? (
              <span>
                <Chip label='YES' labelColor={palette.red400} bgColor={palette.red0} /> -{' '}
                <span onClick={() => changeToViolation(report_number)} style={{ color: palette.indigo500 }}>
                  {report_number}
                </span>
              </span>
            ) : (
              <Chip label='NO' labelColor={palette.green400} bgColor={palette.green0} />
            )}
          </span>
        );
      },
    },
  ];

  const violationsColumns = [
    {
      field: 'incident_id',
      title: <ColumnHeader title='Incident ID' field='incident_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { incident } = rowData;
        const { incident_id, id } = incident || {};
        return (
          <div>
            {incident_id ? (
              <div className='d-flex align-items-center gap-3 pointer'>
                <span style={{ color: use(palette.dark800, palette.gray200) }} onClick={(e) => showIncident(e, id)}>
                  {incident_id}
                </span>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      incident_id: null,
                    };
                    updateViolations(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                className={classes.viewButton}
                onClick={(e) => {
                  setSelectedIncident(rowData);
                  setFaultCode(e.currentTarget);
                  setCreateOrAssignOpen(true);
                }}
              >
                Create or Assign Incident
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'date',
      title: <ColumnHeader title='Date' field='date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { date } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{formatDate(date) || '-'}</span>;
      },
    },
    ...(!driverId
      ? [
          {
            field: 'driver',
            title: <ColumnHeader title='Driver' field='driver' sort={sort} onClick={sortingQuery} />,
            render: (rowData) => {
              return rowData.driver ? (
                <div className='d-flex align-items-center gap-3'>
                  <UserInfo
                    size='28px'
                    data={{
                      ...rowData,
                      id: rowData.driver?.id,
                      name: `${rowData.driver?.fname} ${rowData?.driver?.lname}`,
                      image: rowData.driver?.image,
                      email: rowData.driver?.email,
                      phone_number: rowData.driver?.phone_number,
                      status: rowData.driver?.status_data,
                      user_type: 'driver',
                    }}
                  />
                  <DeleteIcon
                    fill={palette.red500}
                    onClick={(e) => {
                      e.stopPropagation();
                      const body = {
                        driver_id: null,
                      };
                      updateViolations(rowData.id, body)
                        .then(() => {
                          showToaster({ type: 'success', message: 'Success!' });
                          setRefreshData(new Date().getTime());
                        })
                        .catch((err) => {
                          showToaster({ type: 'error', message: getErrorMessage(err) });
                        });
                    }}
                  />
                </div>
              ) : (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Autocomplete
                    placeholder='Select Driver'
                    options={driverData}
                    getOptionLabel={(option) => {
                      return option ? `${option.fname} ${option.lname}` : '';
                    }}
                    style={{ minWidth: 150 }}
                    onChange={(e, val) => {
                      const body = {
                        driver_id: val.id,
                      };
                      updateViolations(rowData.id, body)
                        .then(() => {
                          showToaster({ type: 'success', message: 'Success!' });
                          setRefreshData(new Date().getTime());
                        })
                        .catch((err) => {
                          showToaster({ type: 'error', message: getErrorMessage(err) });
                        });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </span>
              );
            },
          },
        ]
      : []),
    {
      field: 'vehicle_unit_type_description',
      title: (
        <ColumnHeader
          title='Vehicle Inspected'
          field='vehicle_unit_type_description'
          sort={sort}
          onClick={sortingQuery}
        />
      ),
      render: (rowData) => {
        const { vehicle } = rowData;
        const { equipment_id, equipment_type } = vehicle || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {equipment_type?.title || ''}
                </Typography>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      vehicle_id: null,
                    };
                    updateViolations(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Autocomplete
                  labelKey='equipment_id'
                  placeholder='Select Vehicle'
                  options={vehicleData}
                  getOptionLabel={(option) => {
                    return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                  }}
                  style={{ minWidth: 150 }}
                  onChange={(e, val) => {
                    const body = {
                      vehicle_id: val.id,
                    };
                    updateViolations(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </span>
            )}
          </span>
        );
      },
    },
    {
      field: 'trailer_unit_make',
      title: <ColumnHeader title='TRAILER INSPECTED' field='trailer_unit_make' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer } = rowData;
        const { equipment_type, length, equipment_id } = trailer || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {length?.length || ''} {equipment_type?.title || ''}
                </Typography>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      trailer_id: null,
                    };
                    updateViolations(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Autocomplete
                  labelKey='equipment_id'
                  placeholder='Select Trailer'
                  options={trailerData}
                  getOptionLabel={(option) => {
                    return option
                      ? `${option?.equipment_id} ${option?.length?.length || ''} ${option?.equipment_type?.title || ''}`
                      : '';
                  }}
                  style={{ minWidth: 150 }}
                  onChange={(e, val) => {
                    const body = {
                      trailer_id: val.id,
                    };
                    updateViolations(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </span>
            )}
          </span>
        );
      },
    },
    {
      field: 'title',
      title: <ColumnHeader title='Title' field='basic' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { basic } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{basic || '-'}</span>;
      },
    },
    {
      field: 'report_state',
      title: <ColumnHeader title='Report State' field='report_state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_state } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_state || '-'}</span>;
      },
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_number || '-'}</span>;
      },
    },
    {
      field: 'violation_group_description',
      title: <ColumnHeader title='Group' field='violation_group_description' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_group_description } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>{violation_group_description || '-'}</span>
        );
      },
    },
    {
      field: 'violation_code',
      title: <ColumnHeader title='Violation Code' field='violation_code' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_code } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{violation_code || '-'}</span>;
      },
    },
    {
      field: 'violation_description',
      title: <ColumnHeader title='Description' field='violation_description' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_description } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {violation_description.slice(0, 40)}
            {violation_description.length > 40 && (
              <Typography
                variant='c1'
                style={{ color: palette.indigo500, marginLeft: 10 }}
                onClick={() => setShowMoreOpen(violation_description)}
              >
                Read More
              </Typography>
            )}
          </span>
        );
      },
    },
    {
      field: 'violation_severity_weight',
      title: (
        <ColumnHeader title='Severity Weight' field='violation_severity_weight' sort={sort} onClick={sortingQuery} />
      ),
      render: (rowData) => {
        const { violation_severity_weight } = rowData;
        const { color, bgColor, name } = getStatusLabel(violation_severity_weight);
        return <Chip labelColor={color} bgColor={bgColor} label={name} />;
      },
    },
    {
      field: 'time_weight',
      title: <ColumnHeader title='Time Weight' field='time_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { time_weight } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{time_weight}</span>;
      },
    },
    {
      field: 'out_of_service_violation',
      title: <ColumnHeader title='O.O.S' field='out_of_service_violation' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { out_of_service } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {out_of_service ? (
              <Chip label='Yes' labelColor={palette.red400} bgColor={palette.red0} />
            ) : (
              <Chip label='No' labelColor={palette.green400} bgColor={palette.green0} />
            )}
          </span>
        );
      },
    },
    {
      field: 'action',
      title: <ColumnHeader title='Action' field='action' />,
      render: (rowData) => {
        const { acknowledged_by } = rowData;
        const { staff } = acknowledged_by || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {staff ? (
              <Typography>
                Acknowledged by {staff?.first_name} {staff?.last_name}
              </Typography>
            ) : (
              <CustomButton
                type='primary'
                title='Acknowledge'
                className='ms-3'
                onClick={() => acknowledge(rowData)}
                styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
              />
            )}
          </span>
        );
      },
    },
  ];

  const crashesColumns = [
    {
      field: 'incident_id',
      title: <ColumnHeader title='Incident ID' field='incident_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { incident } = rowData;
        const { incident_id, id } = incident || {};
        return (
          <div>
            {incident_id ? (
              <div className='d-flex align-items-center gap-3'>
                <span style={{ color: use(palette.dark800, palette.gray200) }} onClick={(e) => showIncident(e, id)}>
                  {incident_id}
                </span>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      incident_id: null,
                    };
                    updateCrash(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                className={classes.viewButton}
                onClick={(e) => {
                  setSelectedIncident(rowData);
                  setFaultCode(e.currentTarget);
                  setCreateOrAssignOpen(true);
                }}
              >
                Create or Assign Incident
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'date',
      title: <ColumnHeader title='Date' field='date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { date } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{formatDate(date) || '-'}</span>;
      },
    },
    ...(!driverId
      ? [
          {
            field: 'driver',
            title: <ColumnHeader title='Driver' field='driver' sort={sort} onClick={sortingQuery} />,
            render: (rowData) => {
              return rowData.driver ? (
                <div className='d-flex align-items-center gap-3'>
                  <UserInfo
                    size='28px'
                    data={{
                      ...rowData,
                      id: rowData.driver?.id,
                      name: `${rowData.driver?.fname} ${rowData?.driver?.lname}`,
                      image: rowData.driver?.image,
                      email: rowData.driver?.email,
                      phone_number: rowData.driver?.phone_number,
                      status: rowData.driver?.status_data,
                      user_type: 'driver',
                    }}
                  />
                  <DeleteIcon
                    fill={palette.red500}
                    onClick={(e) => {
                      e.stopPropagation();
                      const body = {
                        driver_id: null,
                      };
                      updateCrash(rowData.id, body)
                        .then(() => {
                          showToaster({ type: 'success', message: 'Success!' });
                          setRefreshData(new Date().getTime());
                        })
                        .catch((err) => {
                          showToaster({ type: 'error', message: getErrorMessage(err) });
                        });
                    }}
                  />
                </div>
              ) : (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Autocomplete
                    placeholder='Select Driver'
                    options={driverData}
                    getOptionLabel={(option) => {
                      return option ? `${option.fname} ${option.lname}` : '';
                    }}
                    style={{ minWidth: 150 }}
                    onChange={(e, val) => {
                      const body = {
                        driver_id: val.id,
                      };
                      updateCrash(rowData.id, body)
                        .then(() => {
                          showToaster({ type: 'success', message: 'Success!' });
                          setRefreshData(new Date().getTime());
                        })
                        .catch((err) => {
                          showToaster({ type: 'error', message: getErrorMessage(err) });
                        });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                  />
                </span>
              );
            },
          },
        ]
      : []),
    {
      field: 'vehicle_unit_type_description',
      title: <ColumnHeader title='Vehicle' field='vehicle_unit_type_description' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle } = rowData;
        const { equipment_id, equipment_type } = vehicle || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {equipment_type?.title || ''}
                </Typography>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      vehicle_id: null,
                    };
                    updateCrash(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Autocomplete
                  labelKey='equipment_id'
                  placeholder='Select Vehicle'
                  options={vehicleData}
                  getOptionLabel={(option) => {
                    return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                  }}
                  style={{ minWidth: 150 }}
                  onChange={(e, val) => {
                    const body = {
                      vehicle_id: val.id,
                    };
                    updateCrash(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </span>
            )}
          </span>
        );
      },
    },
    {
      field: 'trailer_unit_make',
      title: <ColumnHeader title='TRAILER' field='trailer_unit_make' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer } = rowData;
        const { equipment_type, length, equipment_id } = trailer || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {equipment_id ? (
              <div className='d-flex align-items-center gap-3'>
                <Typography>
                  {equipment_id} {length?.length || ''} {equipment_type?.title || ''}
                </Typography>
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    const body = {
                      trailer_id: null,
                    };
                    updateCrash(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                />
              </div>
            ) : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Autocomplete
                  labelKey='equipment_id'
                  placeholder='Select Trailer'
                  options={trailerData}
                  getOptionLabel={(option) => {
                    return option
                      ? `${option?.equipment_id} ${option?.length?.length || ''} ${option?.equipment_type?.title || ''}`
                      : '';
                  }}
                  style={{ minWidth: 150 }}
                  onChange={(e, val) => {
                    const body = {
                      trailer_id: val.id,
                    };
                    updateCrash(rowData.id, body)
                      .then(() => {
                        showToaster({ type: 'success', message: 'Success!' });
                        setRefreshData(new Date().getTime());
                      })
                      .catch((err) => {
                        showToaster({ type: 'error', message: getErrorMessage(err) });
                      });
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </span>
            )}
          </span>
        );
      },
    },
    {
      field: 'report_state',
      title: <ColumnHeader title='Report State' field='report_state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_state } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_state}</span>;
      },
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_number || '-'}</span>;
      },
    },
    {
      field: 'trafficway_description',
      title: <ColumnHeader title='Description' field='trafficway_description' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trafficway_description } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{trafficway_description || '-'}</span>;
      },
    },
    {
      field: 'vehicle_vin',
      title: <ColumnHeader title='Vehicle Vin' field='vehicle_vin' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_vin } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_vin || '-'}</span>;
      },
    },
    {
      field: 'vehicle_license_number',
      title: <ColumnHeader title='Vehicle Plate' field='vehicle_license_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_license_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_license_number}</span>;
      },
    },
    {
      field: 'total_injuries',
      title: <ColumnHeader title='Injuries' field='total_injuries' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { total_injuries } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{total_injuries}</span>;
      },
    },
    {
      field: 'total_fatalities',
      title: <ColumnHeader title='Fatalities' field='total_fatalities' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { total_fatalities } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{total_fatalities || '-'}</span>;
      },
    },
    {
      field: 'tow_away',
      title: <ColumnHeader title='Towing' field='tow_away' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { tow_away } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{tow_away ? 'True' : 'False'}</span>;
      },
    },
    {
      field: 'hazmat_released',
      title: <ColumnHeader title='Hazmat' field='hazmat_released' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { hazmat_released } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{hazmat_released ? 'Yes' : 'No'}</span>;
      },
    },
    {
      field: 'road_surface',
      title: <ColumnHeader title='Road Condition' field='road_surface' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { road_surface } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{road_surface || '-'}</span>;
      },
    },
    {
      field: 'weather',
      title: <ColumnHeader title='Weather' field='weather' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { weather } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{weather || '-'}</span>;
      },
    },
    {
      field: 'light',
      title: <ColumnHeader title='Day/Night' field='light' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { light } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{light || '-'}</span>;
      },
    },
    {
      field: 'preventable',
      title: <ColumnHeader title='Preventable' field='preventable' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { preventable } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{preventable ? 'true' : '-'}</span>;
      },
    },
    {
      field: 'severity_weight',
      title: <ColumnHeader title='Severity Weight' field='severity_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { severity_weight } = rowData;
        const { color, bgColor, name } = getStatusLabel(severity_weight);
        return <Chip labelColor={color} bgColor={bgColor} label={name} />;
      },
    },
    {
      field: 'time_weight',
      title: <ColumnHeader title='Time Weight' field='time_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { time_weight } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{time_weight || '-'}</span>;
      },
    },
    {
      field: 'action',
      title: <ColumnHeader title='Action' field='action' />,
      render: (rowData) => {
        const { acknowledged_by } = rowData;
        const { staff } = acknowledged_by || {};
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {staff ? (
              <span>
                Acknowledged by {staff?.first_name} {staff?.last_name}
              </span>
            ) : (
              <CustomButton
                type='primary'
                title='Acknowledge'
                className='ms-3'
                onClick={() => acknowledge(rowData)}
                styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
              />
            )}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <SwitchRadio type='tab' items={tabs} onChange={onChangeTab} name='tableTopTabMenu' value={activeTab} />
      </div>
      <SafetyFilters
        states={states}
        setStates={setStates}
        reportedStates={reportedStates}
        search={search}
        onChange={setSearch}
        onSelectDate={onSelectDate}
        severity={severity}
        setSeverity={setSeverity}
      />
      <div>
        {activeTab === 1 &&
          (loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              data={inspections.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: inspections,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={inspectionColumns}
            />
          ))}
        {activeTab === 2 &&
          (loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              data={violations.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              onRowClick={(e, rowData) => {
                if (rowData.incident?.id) {
                  showIncident(e, rowData.incident.id);
                }
              }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: violations,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={violationsColumns}
            />
          ))}
        {activeTab === 3 &&
          (loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              data={crashes.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              onRowClick={(e, rowData) => {
                if (rowData.incident?.id) {
                  showIncident(e, rowData.incident?.id);
                }
              }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: crashes,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={crashesColumns}
            />
          ))}
      </div>
      {openCreateIncident && (
        <AddIncidentModal
          open={openCreateIncident}
          onClose={() => setOpenCreateIncident(false)}
          onSuccess={(res) => {
            if (res.id) {
              const body = {
                incident_id: res.id,
                driver_id: res.driver_id,
              };
              updateIncident(body);
            }
          }}
          incident={editData}
          driverId={editData?.driver?.id}
          firstName={editData?.driver?.fname}
          lastName={editData?.driver?.lname}
          dotMonitoring
        />
      )}
      {showMoreOpen && (
        <Description open={showMoreOpen} onClose={() => setShowMoreOpen(false)} description={showMoreOpen} />
      )}
      {!!viewData && (
        <IncidentDetailsModal
          open={!!viewData}
          incident={viewData}
          rules={rules}
          hideEdit
          setRuleToView={setRuleToView}
          onClose={() => {
            setViewData(null);
          }}
        />
      )}
      {!!ruleToView && <RuleDetails open={!!ruleToView} onClose={() => setRuleToView(null)} rule={ruleToView} />}
      {selectExistingOpen && (
        <SelectExistingIncident
          open={selectExistingOpen}
          incidents={allIncident}
          onClose={() => {
            setSelectedIncident(null);
            setSelectExisting(false);
            setEditData(null);
          }}
          onSuccess={(data) => {
            const body = {
              incident_id: data.id,
            };
            updateIncident(body);
            setSelectExisting(false);
          }}
        />
      )}
      <Popover
        disableScrollLock
        anchorPosition={{ top: 299, left: 669 }}
        open={createOrAssignOpen}
        anchorEl={faultCode}
        onClose={() => {
          setSelectedIncident(null);
          setFaultCode(null);
          setCreateOrAssignOpen(false);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '40px' }}
      >
        <div style={{ width: '200px' }}>
          <CreateOrAssign
            rowData={selectedIncident}
            createIncident={createIncident}
            setSelectExisting={selectIncident}
          />
        </div>
      </Popover>
    </div>
  );
};

export default SafetyRecords;
