import React from 'react';
import searchIcon from 'assets/icons/header/Search.png';
import { SSearchInput, SWrapper } from './Search.styles';

const Search = ({ search, onChange, margin, onKeyDown }) => {
  return (
    <SWrapper $margin={margin}>
      <SSearchInput
        type='text'
        value={search}
        onChange={(e) => onChange(e.target.value)}
        placeholder='Search'
        onKeyDown={onKeyDown}
      />
      <img src={searchIcon} alt='Search' className='search-icon' />
    </SWrapper>
  );
};

export default Search;
