export const getInitialValues = (dot) => {
  return {
    emp_history_id: null,
    start_date: new Date(),
    end_date: new Date(),
    miles_per_week: null,
    hours_per_week: null,
    hold_position: '',
    reason_for_leaving: '',
    driver_class: '',
    driver_type: '',
    employer_truck: [],
    experience: '',
    truck: '',
    subject_to_fmcsRs: '',
    subject_to_dot_dna: '',
    eligible_for_rehire: '',
    employer_experience: [],
    responsible_for_maintaining_logs: '',
    area_driven: '',
    states_driven: '',
    driver_terminated: '',
    loads_hauled: '',
    trailer_length: '',
    accidents_preventable: '',
    accidents_non_preventable: '',
    accidents_dot_reportable: '',
    employee_alcohol_test_result: '',
    employee_verified_positive_drug_test: '',
    employee_refuse_test: '',
    employee_drug_alcohol_regulations_violations: '',
    previous_employee_drug_alcohol_regulations_violations: '',
    employee_complete_return_to_duty_process: '',
    title: '',
    date: new Date(),
    phone_no: '',
    company_dot: '',
    email: '',
    signature: '',
    comments: '',
    employer_area_driven: [],
    accident: [
      {
        acc_date: null,
        acc02_country_state_city: '',
        acc_description: '',
        acc_fatalities: '',
        acc_injuries: '',
        acc_hazmat: false,
        acc_preventable: false,
      },
    ],
    otherExperience: [],
    hasOtherExperence: false,
    otherTruck: [],
    hasOtherTruck: false,
    otherAreaDriven: [],
    hasOtherAreaDriven: false,
    signature_preview: '',
    signature_name: '',
    response_path: '',
    dot,
  };
};
