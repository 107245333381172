import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import './PreviousEmploymentModal.css';
import 'react-datepicker/dist/react-datepicker.css';
import sorting from 'assets/icons/drivers/sorting.svg';
import { onDownload } from 'pages/Accounting/Receivables/TableSection/shared/SendInvoice/SendInvoice.data';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { ViewPreviousEmploy, getStaffPrevEmploymentVerification } from 'Api/Driver';

export default function PreviousEmploymentModal({ ...props }) {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const [prevVerification, setPrevVerification] = useState({});
  const [expData, setExpData] = useState([]);
  const [areaDrivenData, setAreaDrivenData] = useState([]);
  const [typeData, setTypeData] = useState([]);

  useEffect(() => {
    const id = props.previousEmployment?.id;
    if (props.previousEmployment?.driver_id) {
      ViewPreviousEmploy({ id }).then((res) => {
        setPrevVerification(res.data);
        let type = res.data?.driver_employment_other_histories?.find((val) => val?.type === 1)?.value;
        type = type?.split(',');
        setTypeData(type);
        let exp = res.data?.driver_employment_other_histories?.find((val) => val?.type === 2)?.value;
        exp = exp?.split(',');
        setExpData(exp);
        let area = res.data?.driver_employment_other_histories?.find((val) => val?.type === 3)?.value;
        area = area?.split(',');
        setAreaDrivenData(area);
      });
    } else {
      getStaffPrevEmploymentVerification({ id }).then((res) => {
        setPrevVerification(res.data);
        let type = res.data?.employment_other_histories?.find((val) => val?.type === 1)?.value;
        type = type?.split(',');
        setTypeData(type);
        let exp = res.data?.employment_other_histories?.find((val) => val?.type === 2)?.value;
        exp = exp?.split(',');
        setExpData(exp);
        let area = res.data?.employment_other_histories?.find((val) => val?.type === 3)?.value;
        area = area?.split(',');
        setAreaDrivenData(area);
      });
    }
  }, []);

  return (
    <Modal {...props} dialogClassName='modal-90w' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
        closeButton
      >
        <div className='sub-head-wrap'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <Modal.Title
              className='heading'
              id='contained-modal-title-vcenter'
              style={{ color: use(palette.gray900, palette.gray50) }}
            >
              Previous Employer Verification
            </Modal.Title>
            {props.previousEmployment?.response_path && (
              <PrintIcon
                className='me-2 pointer'
                width={24}
                height={24}
                fill={palette.gray700}
                onClick={() => onDownload(props.previousEmployment?.response_path, 'response-form')}
              />
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        className='previous-modal-body-box driver-style-wrap'
        style={{ backgroundColor: use(palette.white, palette.dark900) }}
      >
        <div>
          <div className='period-of-service-detail'>
            <p style={{ color: use(palette.gray900, palette.gray50) }}>Period of Service Detail</p>
          </div>
          <Row className=' justify-content-between'>
            <Col md={4}>
              <div className='start-end-date'>
                <p
                  className='table-data-text'
                  style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                >
                  Start-End Date 1 <span className='required'>*</span>
                </p>
                <input
                  type='text'
                  disabled
                  value={`${formatDate(prevVerification?.previous_employer_history?.start_date)} - ${formatDate(
                    prevVerification?.previous_employer_history?.end_date
                  )}`}
                  style={{
                    width: '100%',
                    background: use(palette.white, palette.dark800),
                    color: use(palette.dark900, palette.gray200),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={6}>
                  <div className='miles-week'>
                    <p
                      className='table-data-text'
                      style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                    >
                      Miles / Week <span className='required'>*</span>
                    </p>
                    <input
                      type='text'
                      value={prevVerification?.previous_employer_history?.miles_per_week}
                      disabled
                      style={{
                        width: '100%',
                        background: use(palette.white, palette.dark800),
                        color: use(palette.dark900, palette.gray200),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className='hours-week'>
                    <p
                      className='table-data-text'
                      style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                    >
                      Hours / Week <span className='required'>*</span>
                    </p>
                    <input
                      type='text'
                      value={prevVerification?.previous_employer_history?.hours_per_week}
                      disabled
                      style={{
                        width: '100%',
                        background: use(palette.white, palette.dark800),
                        color: use(palette.dark900, palette.gray200),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-between position-held-section'>
            <Col
              md={4}
              xs={6}
              className='d-md-flex flex-block justify-content-between align-items-center positions-held'
            >
              <p
                className='m-0 align-self-center table-data-text'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Position(s) Held
              </p>
              <input
                type='text'
                value={prevVerification?.previous_employer_history?.hold_position}
                disabled
                style={{
                  width: '100%',
                  background: use(palette.white, palette.dark800),
                  color: use(palette.dark900, palette.gray200),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
            </Col>
            <Col
              md={4}
              xs={6}
              className='d-md-flex d-block justify-content-between align-items-center reasons-for-leaving'
            >
              <p
                className='m-0 align-self-center table-data-text'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Reason(s) for Leaving
              </p>
              <input
                type='text'
                value={prevVerification?.previous_employer_history?.reason_for_leaving}
                disabled
                style={{
                  width: '100%',
                  background: use(palette.white, palette.dark800),
                  color: use(palette.dark900, palette.gray200),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
            </Col>
          </Row>
          <div className='drive-class-section flex-column d-flex'>
            <Row className='justify-content-between'>
              <Col md={2} xs={6}>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Driver Class:
                </p>
                <div className='d-flex align-items-center'>
                  <input
                    type='radio'
                    name=''
                    className='radio-button'
                    checked={prevVerification?.previous_employer_history?.driver_class === 'company'}
                    disabled
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Company
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.driver_class === 'Lease'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Lease
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.driver_class === 'own/op'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Own/Op
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.driver_class === 'other'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Other
                  </span>
                </div>
              </Col>
              <Col md={2} xs={6}>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Type:
                </p>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.driver_type === 'solo'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Solo
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.driver_type === 'Team'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Team
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.driver_type === 'Student'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Student
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.driver_type === 'other'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Other
                  </span>
                </div>
              </Col>
              <Col md={2} xs={6} className='mt-md-0 mt-3'>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Truck:
                </p>
                {typeData?.map((item) => (
                  <div className='d-flex align-items-center' key={item}>
                    <input checked type='checkbox' name='' className='radio-button' />
                    <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                      {item}
                    </span>
                  </div>
                ))}
              </Col>
              <Col md={2} xs={6} className='mt-md-0 mt-3'>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Subject to FMCSRs?
                </p>

                <div className='d-flex align-items-center'>
                  <input
                    type='radio'
                    checked={prevVerification?.previous_employer_history?.subject_to_fmcsRs === 'Yes'}
                    disabled
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Yes
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.subject_to_fmcsRs === 'No'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    No
                  </span>
                </div>
              </Col>
              <Col md={2} xs={6} className='mt-md-0 mt-3'>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Subject to DOT D&A?
                </p>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.subject_to_dot_dna === 'Yes'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Yes
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.subject_to_dot_dna === 'No'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    No
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2} xs={6}>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Eligible for rehire?
                </p>
                <div className='d-flex align-items-center'>
                  <input
                    type='radio'
                    name=''
                    className='radio-button'
                    checked={prevVerification?.previous_employer_history?.eligible_for_rehire === 'Yes'}
                    disabled
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Yes
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.eligible_for_rehire === 'No'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    No
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.eligible_for_rehire === 'Review'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Review
                  </span>
                </div>
              </Col>
              <Col md={2} xs={6}>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Experience:
                </p>
                {expData?.map((item) => (
                  <div className='d-flex align-items-center' key={item}>
                    <input checked type='checkbox' name='' className='radio-button' />
                    <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                      {item}
                    </span>
                  </div>
                ))}
              </Col>
              <Col md={2} xs={6} className='mt-md-0 mt-3'>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Responsible for maintaining logs?
                </p>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.responsible_for_maintaining_logs === 'Yes'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Yes
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.responsible_for_maintaining_logs === 'No'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    No
                  </span>
                </div>
              </Col>
              <Col md={2} xs={6} className='mt-md-0 mt-3'>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Area Driven:
                </p>
                {areaDrivenData?.map((item, index) => (
                  <div className='d-flex align-items-center' key={item || index}>
                    <input checked type='checkbox' name='' className='radio-button' />
                    <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                      {item}
                    </span>
                  </div>
                ))}
                <div className='table-data-text other'>
                  <p style={{ color: use(palette.gray700, palette.gray200) }}># of state driven</p>
                  <div>
                    <input
                      type='text'
                      className='m-0'
                      value={prevVerification?.previous_employer_history?.states_driven || ''}
                      disabled
                      style={{
                        width: '100%',
                        background: use(palette.white, palette.dark800),
                        color: use(palette.dark900, palette.gray200),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={2} xs={6} className='mt-md-0 mt-3'>
                <p className='m-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Terminated?
                </p>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.responsible_for_maintaining_logs === 'Yes'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Yes
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    checked={prevVerification?.previous_employer_history?.responsible_for_maintaining_logs === 'No'}
                    disabled
                    type='radio'
                    name=''
                    className='radio-button'
                  />
                  <span className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    No
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Row className='loads-hauled-section'>
            <Col md={5} xs={12}>
              <div className='d-md-flex d-block align-items-center'>
                <p style={{ color: use(palette.gray900, palette.gray50) }}>Loads Hauled</p>
                <input
                  type='text'
                  value={prevVerification?.previous_employer_history?.loads_hauled}
                  disabled
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.dark900, palette.gray200),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
            </Col>
            <Col md={5} xs={12}>
              <div className='d-md-flex d-block align-items-center mt-md-0 mt-2'>
                <p style={{ color: use(palette.gray900, palette.gray50) }}>Trailer Length</p>
                <input
                  type='text'
                  value={prevVerification?.previous_employer_history?.trailer_length}
                  disabled
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.dark900, palette.gray200),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className='accidents-section' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
            <Row className='first-section' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
              <Col md={3}>
                <p className='acident-section-head' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Accidents: (if none, enter zero)
                </p>
              </Col>
              <Col md={3} xs={6}>
                <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                  # Preventable:{' '}
                </p>
                <input
                  type='text'
                  value={prevVerification?.previous_employer_history?.accidents_preventable}
                  disabled
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.dark900, palette.gray200),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </Col>
              <Col md={3} xs={6} className='align-items-center'>
                <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                  # Non-Preventable:{' '}
                </p>
                <input
                  type='text'
                  value={prevVerification?.previous_employer_history?.accidents_non_preventable}
                  disabled
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.dark900, palette.gray200),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </Col>
              <Col md={3} className=''>
                <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
                  # DOT Reportable:{' '}
                </p>
                <input
                  type='text'
                  value={prevVerification?.previous_employer_history?.accidents_dot_reportable}
                  disabled
                  style={{
                    width: '100px',
                    background: use(palette.white, palette.dark800),
                    color: use(palette.dark900, palette.gray200),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </Col>
            </Row>
            <div className='second-section row'>
              {(
                prevVerification?.driver_employment_accidents_report || prevVerification?.employment_accidents_report
              )?.map((item) => {
                return (
                  <div className='d-flex justify-content-between first-input-section col-11'>
                    <div className='col-1'>
                      <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Date
                      </p>
                      <input
                        type='text'
                        value={item?.date}
                        disabled
                        style={{
                          background: use(palette.white, palette.dark800),
                          color: use(palette.dark900, palette.gray200),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      />
                    </div>
                    <div className='col-2'>
                      <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        City,State
                      </p>
                      <input
                        type='text'
                        value={item?.acc_city_state}
                        disabled
                        style={{
                          background: use(palette.white, palette.dark800),
                          color: use(palette.dark900, palette.gray200),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      />
                    </div>
                    <div className='col-2'>
                      <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Description
                      </p>
                      <input
                        type='text'
                        value={item?.description}
                        disabled
                        style={{
                          background: use(palette.white, palette.dark800),
                          color: use(palette.dark900, palette.gray200),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      />
                    </div>
                    <div className='col-2'>
                      <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        # Fatalities
                      </p>
                      <input
                        type='text'
                        value={item?.fatalities}
                        disabled
                        style={{
                          background: use(palette.white, palette.dark800),
                          color: use(palette.dark900, palette.gray200),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      />
                    </div>
                    <div className='col-2'>
                      <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        # Injuries
                      </p>
                      <input
                        type='text'
                        value={item?.injuries}
                        disabled
                        style={{
                          background: use(palette.white, palette.dark800),
                          color: use(palette.dark900, palette.gray200),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      />
                    </div>
                    <div className='col-1'>
                      <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Hazmat?
                      </p>
                      <input checked={item?.hazmat === 'Yes'} type='checkbox' className='accident-check-box' />
                    </div>
                    <div className='col-1'>
                      <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Preventable?
                      </p>
                      <input checked={item?.preventable === 'Yes'} type='checkbox' className='accident-check-box' />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='drug-and-alcohol-section'>
            <div className='first-section d-flex justify-content-between col-12'>
              <p className='table-data-text heading' style={{ color: use(palette.gray900, palette.gray50) }}>
                Drug and Alcohol
              </p>
            </div>
            <div className='second-section col-12' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
              <p className='table-data-text m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                In the three years prior to the date of the employee’s signature (on the release), for DOT-regulated
                testing (To be accompanied by an appropriate drug and alcohol release):
              </p>
            </div>
          </div>
          <div className='drug-and-alcohol-question-section'>
            <div className='question' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
              <Row>
                <Col md={10}>
                  <p className='m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    1. Did the employee have alcohol tests with a result of 0.04 or higher?
                  </p>
                </Col>
                <Col md={2} className='d-flex buttons-section'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      checked={prevVerification?.previous_employer_history?.employee_alcohol_test_result === 'Yes'}
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>Yes</span>
                  </div>
                  <div className='d-flex align-items-center no-button'>
                    <input
                      type='radio'
                      checked={prevVerification?.previous_employer_history?.employee_alcohol_test_result === 'No'}
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>No</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='question' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
              <Row>
                <Col md={10}>
                  <p className='m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    2. Did the employee have verified positive drug tests?
                  </p>
                </Col>
                <Col md={2} className='d-flex buttons-section'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history?.employee_verified_positive_drug_test === 'Yes'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>Yes</span>
                  </div>
                  <div className='d-flex align-items-center no-button'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history?.employee_verified_positive_drug_test === 'No'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>No</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='question' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
              <Row>
                <Col md={10}>
                  <p className='m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    3. Did the employee refuse to be tested?
                  </p>
                </Col>
                <Col md={2} className='d-flex buttons-section'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      checked={prevVerification?.previous_employer_history?.employee_refuse_test === 'Yes'}
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>Yes</span>
                  </div>
                  <div className='d-flex align-items-center no-button'>
                    <input
                      type='radio'
                      checked={prevVerification?.previous_employer_history?.employee_refuse_test === 'No'}
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>No</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='question' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
              <Row>
                <Col md={10}>
                  <p className='m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    4. Did the employee have other violations of DOT agency drug and alcohol testing regulations?
                  </p>
                </Col>
                <Col md={2} className='d-flex buttons-section'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history?.employee_drug_alcohol_regulations_violations ===
                        'Yes'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>Yes</span>
                  </div>
                  <div className='d-flex align-items-center no-button'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history?.employee_drug_alcohol_regulations_violations ===
                        'No'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>No</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='question' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
              <Row>
                <Col md={10}>
                  <p className='m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    5. Did a previous employee have other report a drug and alcohol rule violation to you?
                  </p>
                </Col>
                <Col md={2} className='d-flex buttons-section'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history
                          ?.previous_employee_drug_alcohol_regulations_violations === 'Yes'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>Yes</span>
                  </div>
                  <div className='d-flex align-items-center no-button'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history
                          ?.previous_employee_drug_alcohol_regulations_violations === 'No'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>No</span>
                  </div>
                </Col>
              </Row>
              {/* <div className="d-flex alig-items-center upload-document-section"> */}
              {/* <p className="table-data-text m-0 upload-document-content" style={{ color: use(palette.gray700, palette.gray200) }}>Please provide the previous employee’s report </p> */}
              {/* <p className="attachment-wrapper" style={{ backgroundColor: use(palette.white, palette.dark0) }}>
                  <img src={attachment} alt='attachment' className='attachment-icon' />
                  <span className="text-style" style={{ color: use(palette.gray900, palette.indigo400) }}>Direct Deposit Form.jpg</span>
                </p> */}
              {/* </div> */}
            </div>
            <div className='question remove-border'>
              <Row>
                <Col md={10}>
                  <p className='m-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    6. If you answered “Yes” to any of the above items, did the employee complete the return-to-duty
                    process?
                  </p>
                </Col>
                <Col md={2} className='d-flex flex-wrap buttons-section'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history?.employee_complete_return_to_duty_process === 'N/A'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>N/A</span>
                  </div>
                  <div className='d-flex align-items-center yes-button'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history?.employee_complete_return_to_duty_process === 'Yes'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>Yes</span>
                  </div>
                  <div className='d-flex align-items-center no-button'>
                    <input
                      type='radio'
                      checked={
                        prevVerification?.previous_employer_history?.employee_complete_return_to_duty_process === 'No'
                      }
                      disabled
                    />
                    <span style={{ color: use(palette.gray700, palette.gray200) }}>No</span>
                  </div>
                </Col>
              </Row>
              {/* <div className="d-flex alig-items-center upload-document-section"> */}
              {/* <p className="table-data-text m-0 upload-document-content" style={{ color: use(palette.gray700, palette.gray200) }}>Please provide the previous employee’s report </p> */}
              {/* <p className="attachment-wrapper" style={{ backgroundColor: use(palette.white, palette.dark0) }}>
                  <img src={attachment} alt='attachment' className='attachment-icon' />
                  <span className="text-style" style={{ color: use(palette.gray900, palette.indigo400) }}>Direct Deposit Form.jpg</span>
                </p> */}
              {/* </div> */}
            </div>
          </div>

          <Row className='d-flex justify-content-between mt-32'>
            <Col md={4} className='direction'>
              <div>
                <p className='table-data-text m-0 mb-8 font' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Signature
                </p>
              </div>
              <div
                className='signature-field'
                style={{
                  background: use(palette.white, palette.dark800),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <img
                  src={prevVerification?.previous_employer_history?.signature}
                  className='signature-field-image'
                  alt=''
                />
              </div>
              <div>
                <input
                  type='text'
                  value={prevVerification?.previous_employer_history?.signature_name}
                  disabled
                  className='name-field'
                  placeholder='John Smith'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
            </Col>
            <Col md={8} xs={12} className='second-direction'>
              <Row className='row-inputs'>
                <Col md={4} xs={6} className='first-input mt-md-0 mt-2'>
                  <div className='first-heading'>
                    <p
                      className='table-data-text m-0 mb-8 font'
                      style={{ color: use(palette.gray900, palette.gray50) }}
                    >
                      Title
                    </p>
                  </div>
                  <input
                    type='text'
                    placeholder='Manager'
                    value={prevVerification?.previous_employer_history?.title}
                    disabled
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </Col>
                <Col md={2} xs={6} className='second-input mt-md-0 mt-2'>
                  <div className='second-heading'>
                    <p
                      className='table-data-text m-0 mb-8 font'
                      style={{ color: use(palette.gray900, palette.gray50) }}
                    >
                      Date
                    </p>
                  </div>
                  <input
                    type='date'
                    value={prevVerification?.previous_employer_history?.date}
                    disabled
                    className='date-input'
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </Col>
                <Col md={6} xs={12} className='third-input mt-md-0 mt-2'>
                  <div className='third-heading'>
                    <p
                      className='table-data-text m-0 mb-8 font'
                      style={{ color: use(palette.gray900, palette.gray50) }}
                    >
                      Phone Number
                    </p>
                  </div>
                  <input
                    type='text'
                    value={prevVerification?.previous_employer_history?.phone_no}
                    disabled
                    placeholder='Phone number'
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </Col>
              </Row>
              <Row className='row-inputs mt-3'>
                <Col md={6} xs={6} className='first-input'>
                  <div className='first-heading'>
                    <p className='table-data-text m-0 font' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Company DOT #
                    </p>
                  </div>
                  <input
                    type='text'
                    value={prevVerification?.previous_employer_history?.company_dot}
                    disabled
                    placeholder='022873782'
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </Col>
                <Col md={4} xs={6} className='second-input set-width'>
                  <div className='second-heading'>
                    <p className='table-data-text m-0 font' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Email
                    </p>
                  </div>
                  <input
                    value={prevVerification?.previous_employer_history?.email}
                    disabled
                    type='text'
                    placeholder='example@gmail.com'
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <div className='comment-section mt-32'>
            <div className='d-flex justify-content-between'>
              <p className='table-data-text m-0 mb-8 font' style={{ color: use(palette.gray900, palette.gray50) }}>
                Comments
              </p>
            </div>
            <div className='d-flex justify-content-between col-12'>
              <textarea
                value={prevVerification?.previous_employer_history?.comments}
                disabled
                className='comment-field'
                style={{
                  background: use(palette.white, palette.dark800),
                  color: use(palette.gray900, palette.gray50),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
              {/* <input type="text" className="comment-field" /> */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        className='justify-content-md-end justify-content-center'
        style={{
          background: use(palette.white, palette.dark800),
          color: use(palette.gray900, palette.gray50),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn-container prev-sub-footer-wrap'>
          <div
            className='select-custom-dropdown-id'
            style={{ background: use(palette.white, palette.dark800), color: use(palette.gray900, palette.gray50) }}
          >
            <select
              style={{
                fontSize: '14px',
                fontWeight: '500',
                background: use(palette.white, palette.dark800),
                color: use(palette.gray900, palette.gray50),
              }}
              disabled
              value={prevVerification?.response}
            >
              <option>Pending Verification</option>
              <option>Verified</option>
              <option>Not Verified/Inaccurate</option>
            </select>
            <img src={sorting} alt='' />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
