import React, { useState } from 'react';
import Box from '@mui/material/Box';
import './OutOfService.css';
import axios from 'axios';
import authHeader from 'services/auth-header';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  p: 0,
  borderRadius: '6px',
};

const OutofserviceStatus = ({ handleOutOfServiceClose, id, init }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [reason, setReason] = useState('');

  const handleSubmit = (status_id) => {
    let value;
    if (status_id) {
      value = {
        status_id,
        reason,
        drop_trailer_cargo_onboard_id: null,
        drop_trailer_shipment_id: null,
        drop_trailer_stop_point_id: null,
      };
    }
    axios
      .put(`${API_URL}/equipment/equipment-status/${id}`, value, { headers: authHeader() })
      .then((res) => {
        if (res) {
          init();
          handleOutOfServiceClose();
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  return (
    <div>
      <Box sx={style}>
        <div>
          <p className='outofserviceHeader'>Out Of Service</p>
        </div>
        <div className='outofserviceBody'>
          <div className='mt-3'>
            <div className='d-flex justify-content-between mb-2'>
              <span className='outofserviceFormHeader'>
                Reason <span style={{ color: 'blue' }}>*</span>
              </span>
            </div>
            <div className='form-group outofservicePlaceHolder'>
              <textarea
                className='form-control'
                id='exampleFormControlTextarea3'
                placeholder='Write why this equipment is Out Of Service...'
                rows='5'
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='outofserviceFormFooter'>
          <button className='outofserviceFormCancelButton' onClick={handleOutOfServiceClose}>
            Cancel
          </button>
          <button className='outofserviceFormAddRecordButton' onClick={() => handleSubmit(3)}>
            Update
          </button>
        </div>
      </Box>
    </div>
  );
};

export default OutofserviceStatus;
