import React, { useEffect, useState } from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { SAccordion, SMenuItemTitle } from './SubMenu.styles';

const SubMenu = ({ menuItem, isConfiguringStep, statusCheck, isActive, handleClick, activeTab }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (menuItem.subtitle.some((i) => i.activeId === activeTab)) {
      setExpanded(true);
    }
  }, [activeTab]);

  return (
    <SAccordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ArrowUp className='accordion-arrow' />}>
        <SMenuItemTitle>{menuItem.title}</SMenuItemTitle>
      </AccordionSummary>
      <AccordionDetails>
        <ul className={`company-submenu-title ${isActive ? 'show' : ''}`}>
          {menuItem.subtitle.map((t) => (
            <li
              key={t.id}
              onClick={() => handleClick(t.activeId)}
              className={activeTab === t.activeId ? 'activeOption' : ''}
            >
              {t.title}{' '}
              {statusCheck?.[t.activeId] === 'saveAndNext' && !!isConfiguringStep ? (
                <CheckIcon />
              ) : statusCheck?.[t.activeId] === 'skip' && !!isConfiguringStep ? (
                <span className='dash' />
              ) : null}
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </SAccordion>
  );
};

export default SubMenu;
