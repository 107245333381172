import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HeaderStar from 'components/HeaderStar';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import './companySettings.css';
import SubMenu from './components/SubMenu';
import { useGetMenuList } from './CompanySettings.data';

const CompanyLeftMenu = ({ setactiveTab, activeTab, statusCheck, isConfiguringStep }) => {
  const [activeOption, setActiveOption] = useState(false);
  const { menuList, configureMenuList } = useGetMenuList();

  const handleMenuClick = (activeTabID) => {
    if (!activeTabID) {
      return;
    }
    setactiveTab(activeTabID);
    setActiveOption(true);
  };

  const handleSubMenuClick = (activeTabID) => {
    if (!activeTabID) {
      return;
    }
    setactiveTab(activeTabID);
  };

  return (
    <div className='company-left-section'>
      <ul className='company-menu-title'>
        {(isConfiguringStep ? configureMenuList : menuList).map((m) => (
          <li
            key={m.id}
            onClick={() => handleMenuClick(m.activeId)}
            className={activeTab === m.activeId ? 'activeTabOption' : ''}
          >
            {m.subtitle ? (
              <SubMenu
                menuItem={m}
                isConfiguringStep={isConfiguringStep}
                statusCheck={statusCheck}
                isActive={activeOption}
                activeTab={activeTab}
                handleClick={handleSubMenuClick}
              />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {m.title}{' '}
                {statusCheck?.[m.activeId] === 'saveAndNext' && !!isConfiguringStep ? (
                  <CheckIcon />
                ) : statusCheck?.[m.activeId] === 'skip' && !!isConfiguringStep ? (
                  <span className='dash' />
                ) : (
                  ''
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const CompanyRightSection = ({ activeTab, setActiveTab, onSaveAndNext, onCancelPrevPage, isConfiguringStep }) => {
  let cases = {};
  const { menuList, configureMenuList } = useGetMenuList();

  (isConfiguringStep ? configureMenuList : menuList).find((m) => {
    if (!m.subtitle && m.activeId === activeTab) {
      cases = m;
      return m;
    }
    if (m.subtitle) {
      m.subtitle.find((t) => {
        if (t.activeId === activeTab) {
          cases = t;
          return t;
        }
        return null;
      });
    }
    return null;
  });
  const Component = cases.component;

  return (
    <div className='company-right-section' style={{ background: cases?.background || '#F7F9FC' }}>
      {Component && (
        <Component
          isConfiguringStep={isConfiguringStep}
          onSaveAndNext={onSaveAndNext}
          onCancelPrevPage={onCancelPrevPage}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
};

const CompanySettings = ({ isConfiguringStep, onConfigureFinish }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { menuList, configureStepQueue } = useGetMenuList();
  const [searchParams, setSearchParams] = useSearchParams();
  const { tabId, subTabName } = location?.state || {
    tabId: Number(searchParams?.get('tabId')),
    subTabName: searchParams?.get('subTabName'),
  };
  const defaultTab = tabId ? menuList.find((item) => item.id === tabId) : null;
  const defaultSubTab =
    defaultTab && subTabName ? defaultTab?.subtitle?.find((item) => item.activeId === subTabName) : null;
  const [activeTab, setactiveTab] = useState(
    defaultSubTab?.activeId || defaultTab?.activeId || menuList[0].subtitle[0].activeId
  );
  const [statusCheck, setStatusCheck] = useState({});

  const onSaveAndNext = (e) => {
    const currentIndex = configureStepQueue.findIndex((t) => t === activeTab);
    if (configureStepQueue[currentIndex + 1]) {
      // Go to next step
      setactiveTab(configureStepQueue[currentIndex + 1]);
    } else {
      // Finished all steps
      onConfigureFinish();
    }
    if (e === 'saveAndNext') {
      setStatusCheck((prev) => {
        return {
          ...prev,
          [activeTab]: e,
        };
      });
    }
    if (e === 'skip' && !statusCheck[activeTab]) {
      setStatusCheck((prev) => {
        return { ...prev, [activeTab]: e };
      });
    }
  };
  const onCancelPrevPage = () => {
    const currentIndex = configureStepQueue.findIndex((t) => t === activeTab);
    if (configureStepQueue[currentIndex - 1]) {
      // Go back step
      setactiveTab(configureStepQueue[currentIndex - 1]);
    } else {
      // Finished all steps
      onConfigureFinish();
    }
  };

  useEffect(() => {
    if (location.state?.subTabName) {
      setactiveTab(location.state.subTabName);
    }
  }, [location.state?.subTabName]);

  useEffect(() => {
    setSearchParams('');
  }, []);

  return (
    <>
      {!isConfiguringStep && (
        <div className='company-header'>
          <HeaderStar title='Company Settings' />
        </div>
      )}
      <div className='company-page-layout'>
        <CompanyLeftMenu
          setactiveTab={(e) => {
            if (location.state?.tabId) {
              navigate('.', { state: null });
            }
            !isConfiguringStep ? setactiveTab(e) : null;
          }}
          activeTab={activeTab}
          statusCheck={statusCheck}
          isConfiguringStep={isConfiguringStep}
        />
        <CompanyRightSection
          activeTab={activeTab}
          setActiveTab={setactiveTab}
          isConfiguringStep={isConfiguringStep}
          onSaveAndNext={onSaveAndNext}
          onCancelPrevPage={onCancelPrevPage}
        />
      </div>
    </>
  );
};

export default CompanySettings;
