import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import northAmericaMap from 'assets/combined-us-canada-mexico-with-states-provinces.geojson';
import { palette } from 'utils/constants';
import Tooltip from 'common/Tooltip';
import { getEquipmentTypes, getStats } from 'Api/Carriers';
import Autocomplete from 'common/Autocomplete';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import sm from 'components/Carriers/AddCarrier/ContactBook.module.css';
import InputLabel from 'common/InputLabel';

const Operations = ({ carrier, form }) => {
  const { use } = useTheme();
  const { dot } = useParams();
  const showToaster = useShowToaster();
  const { can_travel_to } = carrier || {};
  const [states, setStates] = useState({});
  const [tooltipContent, setTooltipContent] = useState('');
  const [equipmentRequired, setEquipmentRequired] = useState([]);
  const { values, handleChange, touchedErrors } = form || {};

  const toggleState = (stateId) => {
    const partToCheck = stateId.includes('-') ? stateId.split('-').pop() : stateId;
    const country = stateId.includes('-') ? stateId.split('-')?.[0] : stateId;

    const checkCountryId = country === 'US' ? 1 : country === 'CA' ? 2 : 3;

    if (can_travel_to?.includes(String(checkCountryId))) {
      const currentState = states.find((state) => state.short_name === partToCheck);
      const isStatePresent = values.operating_states.some((item) => item.id === currentState.id);
      const newState = isStatePresent
        ? values.operating_states.filter((item) => item.id !== currentState.id)
        : [...values.operating_states, currentState];
      handleChange('operating_states', newState);
    } else {
      showToaster({ type: 'error', message: 'Carrier can not travel to that country' });
    }
  };

  useEffect(() => {
    const params = {
      'country_id[]': ['38', '231', '142'],
    };

    getStats(params).then((res) => {
      const statesWithCountries = res.data
        .map((item) => {
          if (item.id && item.short_name) {
            return item;
          }
          return null;
        })
        .filter((item) => item !== null);

      if (statesWithCountries) {
        if (can_travel_to?.includes('1')) {
          statesWithCountries.unshift({ short_name: 'All United States', id: 'usa' });
        }
        if (can_travel_to?.includes('2')) {
          statesWithCountries.unshift({ short_name: 'All Canada', id: 'canada' });
        }
        if (can_travel_to?.includes('3')) {
          statesWithCountries.unshift({ short_name: 'All Mexico', id: 'mexico' });
        }
      }

      setStates(statesWithCountries);
    });
    getEquipments();
  }, []);

  const getEquipments = async () => {
    try {
      const response = await getEquipmentTypes(dot);
      setEquipmentRequired((response?.data || []).filter((item) => item.id !== 1 && item.id !== 10));
    } catch (e) {
      // Do nothing
    }
  };

  const handleStateChange = (value, reason, details) => {
    const updateStatesList = (countryId, countryName) => {
      if (reason === 'selectOption' && details.option.id === countryId) {
        return states.filter((item) => item.country?.name === countryName);
      }
      if (reason === 'removeOption' && details.option.id === countryId) {
        value = value.filter((item) => item.country?.name !== countryName);
        return [];
      }
      return [];
    };

    const allUsaStates = updateStatesList('usa', 'United States');
    const allCanadaStates = updateStatesList('canada', 'Canada');
    const allMexicoStates = updateStatesList('mexico', 'Mexico');

    const mergedValues = [...value, ...allUsaStates, ...allCanadaStates, ...allMexicoStates];
    handleChange('operating_states', mergedValues);
  };

  return (
    <div className={sm.container_wrapper}>
      <div className={sm.container} style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}>
        <div className={sm.body}>
          <div className='mb-4 w-50'>
            <div className='w-100 mt-2'>
              <InputLabel>Please select equipment type you have:</InputLabel>
              <Autocomplete
                labelKey='title'
                multiple
                options={equipmentRequired}
                placeholder={values?.equipment_types?.length ? '' : 'Select Equipment Type'}
                value={values?.equipment_types}
                onChange={(e, val) => handleChange('equipment_types', val)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                error={touchedErrors?.equipment_types}
              />
            </div>
          </div>

          <div className='w-50 mt-2'>
            <InputLabel>Please select where you operate:</InputLabel>
            <Autocomplete
              labelKey='short_name'
              multiple
              options={states}
              placeholder={values.operating_states?.length ? '' : 'Select States'}
              value={values.operating_states}
              onChange={(e, val, reason, details) => handleStateChange(val, reason, details)}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              error={touchedErrors?.operating_states}
            />
          </div>

          <ComposableMap
            projection='geoMercator'
            projectionConfig={{
              scale: 200,
              center: [-100, 60],
            }}
            style={{
              width: '100%',
              height: 'auto',
              margin: '0',
              display: 'block',
            }}
          >
            <Tooltip
              title={tooltipContent}
              placement='top'
              arrow={false}
              componentsProps={{
                placement: 'top',
                tooltip: {
                  sx: {
                    fontSize: 16,
                    fontFamily: 'Inter',
                    whiteSpace: 'nowrap',
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              <Geographies geography={northAmericaMap}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const partToCheck = geo.id?.includes('-') ? geo.id?.split('-').pop() : geo?.id;
                    const isStateSelected = values.operating_states?.some((item) => item.short_name === partToCheck);
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        onClick={() => {
                          toggleState(geo.id);
                        }}
                        onMouseEnter={() => {
                          const { name } = geo.properties;
                          setTooltipContent(name);
                        }}
                        onMouseLeave={() => {
                          setTooltipContent('');
                        }}
                        style={{
                          default: {
                            fill: isStateSelected ? palette.indigo500 : palette.gray700,
                            stroke: '#FFF',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          hover: {
                            fill: palette.indigo200,
                            stroke: '#FFF',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          pressed: {
                            fill: palette.indigo500,
                            stroke: '#FFF',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </Tooltip>
          </ComposableMap>
        </div>
      </div>
    </div>
  );
};

export default Operations;
