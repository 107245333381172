import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { Typography } from '../../Typography';
import sm from './BillingCharges.module.css';

const PopoverBillingCharges = ({ data, overlay, onClick, selectedItem }) => {
  const { use } = useTheme();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <>
      <div ref={target} style={{ cursor: 'pointer' }} onClick={() => setShow(true)}>
        {overlay || (
          <Typography variant='s2' style={{ display: 'flex', alignItems: 'center' }}>
            {selectedItem?.title}
            <ArrowDown fill={use(palette.gray700, palette.white)} style={{ marginLeft: 8 }} />
          </Typography>
        )}
      </div>
      <Overlay
        placement='bottom'
        target={target.current}
        show={show}
        onHide={() => setShow(false)}
        rootCloseEvent='click'
        rootClose
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => {
          return (
            <div
              {...props}
              className={sm.popover_billing_charges}
              style={{ backgroundColor: use(palette.white, palette.dark800), ...props.style }}
            >
              {data?.map((item) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => {
                      onClick(item);
                      setShow(false);
                    }}
                    className={[sm.texts_wrapper, selectedItem?.id === item.id ? sm.active : ''].join(' ')}
                  >
                    <Typography variant='s2' className={sm.texts}>
                      {item.title}
                    </Typography>

                    {selectedItem?.id === item.id && <TickIcon fill={use(palette.white, palette.white)} />}
                  </div>
                );
              })}
            </div>
          );
        }}
      </Overlay>
    </>
  );
};

export default PopoverBillingCharges;
