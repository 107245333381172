import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as RefreshIcon } from 'assets/icons/circle-arrows.svg';
import Pagination from 'common/Pagination';
import MessagePopup from 'common/MessagePopup';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import BillDetention from 'components/TableShipments/detailsRow/steps/Detention/BillDetention';
import CheckoutTrailer from 'components/TableShipments/detailsRow/steps/Detention/CheckoutTrailer';
import { onChangeInventoryTab } from 'store/reducers/stopPoint.reducer';
import CreateInvoice from 'pages/Accounting/Receivables/TableSection/shared/CreateInvoice';
import { palette, STOP_POINT_TAB } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { getDetention, getDetentionsTotalAmount } from 'Api/Shipment';
import ModifyDuration from 'components/TableShipments/detailsRow/steps/Detention/ModifyDuration';
import TableFilters from './components/TableFilters';
import AddToBillingCharges from './components/AddToBillingCharges';
import { useColumns } from './DetentionTable.data';
import { SAmountWrapper, SFiltersWrapper, SLinkText, STableWrapper } from './DetentionTable.styles';

const DetentionTable = () => {
  const { use } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [searchParams] = useSearchParams();
  const { currency } = useSelector((state) => state.root);
  const [detentionData, setDetentionData] = useState({ data: [] });
  const [totalData, setTotalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amountLoading, setAmountLoading] = useState(false);
  const [billData, setBillData] = useState(null);
  const [dateToModify, setDateToModify] = useState(null);
  const [checkoutData, setCheckoutData] = useState(null);
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningData, setWarningData] = useState(null);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const debouncedSearch = useDebounce(search, 500);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    billed: 0,
    equipmentType: 'vehicle',
    location: null,
    customer: null,
    page: 1,
    page_size: 25,
  });

  const [openBulKBillCharges, setOpenBulKBillCharges] = useState(false);
  const [openCreateInvoice, setOpenCreateInvoice] = useState(false);
  const billableDetentions = detentionData?.data?.filter((el) => el.departed_date);

  const getDetentionData = async () => {
    try {
      const params = {
        page: selectedFilters.page,
        page_size: selectedFilters.page_size,
        billed: selectedFilters.billed,
        'filters[customers][]': selectedFilters.customer?.id,
        'filters[equipment_type]': selectedFilters.equipmentType,
        query: debouncedSearch || selectedFilters.location?.location_name || undefined,
      };

      const response = await getDetention(params);
      setDetentionData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getTotalAmount = async () => {
    setAmountLoading(true);
    try {
      const { data } = await getDetentionsTotalAmount();
      setTotalData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setAmountLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, page_size: rowPage }));
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const onSearch = (val) => {
    setSearch(val);
    if (selectedFilters.page !== 1) {
      setSelectedFilters((prevState) => ({ ...prevState, page: 1 }));
    }
  };

  const onRowClick = (rowData) => {
    if (!rowData.departed_date) {
      setWarningOpen(true);
      setWarningData(rowData);
      return;
    }

    if (selectedFilters.billed) {
      navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 7 } });
      return;
    }

    setBillData(rowData);
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== billableDetentions?.length ? billableDetentions : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  function navigateToInventory(stopId, equipmentType) {
    dispatch(onChangeInventoryTab(equipmentType));
    navigate(`/stop-point/${stopId}/${STOP_POINT_TAB.INVENTORY}`);
  }

  const onModifyDuration = (data) => {
    setDateToModify(data);
  };

  useEffect(() => {
    setLoading(true);
    getDetentionData();
    setSelectedRows([]);
  }, [selectedFilters, debouncedSearch]);

  useEffect(() => {
    getTotalAmount();
  }, []);

  const PaginationComponent = () => (
    <Pagination
      data={detentionData}
      rowPerPage={selectedFilters.page_size}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
    />
  );

  const columns = useColumns({
    setBillData,
    setCheckoutData,
    setWarningOpen,
    setWarningData,
    billed: selectedFilters.billed,
    onModifyDuration,
  });

  return (
    <STableWrapper>
      <SFiltersWrapper>
        <div className='billed-toggle'>
          <Typography variant='s2' style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>
            Not Billed
          </Typography>
          <CustomCheckbox
            type='switch'
            name='billed'
            smail={false}
            checked={!!selectedFilters.billed}
            onChange={(checked) => {
              setSelectedFilters((prevState) => ({ ...prevState, billed: checked ? 1 : 0, page: 1 }));
            }}
          />
          <Typography variant='s2' style={{ marginRight: '8px' }}>
            Billed
          </Typography>
        </div>
        <TableFilters
          search={search}
          setSearch={onSearch}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        <div className='billed-toggle'>
          <Typography variant='s2' style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>
            Vehicle
          </Typography>
          <CustomCheckbox
            type='switch'
            name='equipment_type'
            smail={false}
            checked={selectedFilters.equipmentType === 'trailer'}
            onChange={(checked) => {
              setSelectedFilters((prevState) => ({
                ...prevState,
                equipmentType: checked ? 'trailer' : 'vehicle',
                page: 1,
              }));
            }}
          />
          <Typography variant='s2' style={{ marginRight: '8px' }}>
            Trailer
          </Typography>
        </div>
      </SFiltersWrapper>
      <div className='d-flex align-items-center gap-5 mb-3'>
        {selectedFilters.billed === 0 && (
          <SAmountWrapper>
            <Typography variant='s2'>Total</Typography>
            {amountLoading ? (
              <Skeleton style={{ width: '80px', height: '28px' }} />
            ) : (
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='h2'>
                  {currency}
                  {selectedFilters.equipmentType === 'vehicle'
                    ? totalData?.vehicle_total || '0.00'
                    : totalData?.trailer_total || '0.00'}
                </Typography>
                <RefreshIcon fill={palette.green400} className='refresh-icon pointer' onClick={getTotalAmount} />
              </div>
            )}
          </SAmountWrapper>
        )}
        {!!selectedRows.length && (
          <div className='d-flex align-items-center gap-4'>
            <CustomButton
              type='primary'
              title='Create Invoice'
              onClick={() => setOpenCreateInvoice(true)}
              styleButton={{ padding: '5px 12px', marginTop: 0 }}
            />
            <CustomButton
              type='primary'
              title='Apply to Shipment Billing Charges'
              onClick={() => {
                if (selectedRows.some((row) => row.shipment_billing?.customer_billed)) {
                  showToaster({ type: 'error', message: 'You can not bill detention for already billed shipments!' });
                } else {
                  setOpenBulKBillCharges(true);
                }
              }}
              styleButton={{ padding: '5px 12px', marginTop: 0 }}
            />
          </div>
        )}
      </div>

      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={detentionData?.data}
          rowPerPage={selectedFilters.page_size}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={[
            ...(!selectedFilters.billed
              ? [
                  {
                    field: '',
                    title: billableDetentions?.length ? (
                      <CustomCheckbox
                        onChange={handleSelectAll}
                        checked={billableDetentions?.length === selectedRows.length}
                      />
                    ) : (
                      ''
                    ),
                    render: (row) => (
                      <span onClick={(e) => e.stopPropagation()}>
                        {row.departed_date && (
                          <CustomCheckbox
                            onChange={(checked) => handleSelectRow(checked, row)}
                            checked={selectedRows?.some((i) => i.id === row.id)}
                          />
                        )}
                      </span>
                    ),
                  },
                ]
              : []),
            ...columns,
          ]}
          onRowClick={(e, rowData) => onRowClick(rowData)}
        />
      )}
      {!!billData && (
        <BillDetention
          open={!!billData}
          onClose={() => setBillData(false)}
          data={billData}
          onSuccess={() => {
            getDetentionData();
            getTotalAmount();
          }}
        />
      )}
      {!!checkoutData && (
        <CheckoutTrailer
          open={!!checkoutData}
          onClose={() => setCheckoutData(null)}
          data={checkoutData}
          onSuccess={() => {
            getDetentionData();
            getTotalAmount();
          }}
        />
      )}
      <MessagePopup
        title='Warning'
        message={
          <div>
            <Typography variant='s2'>You must checkout vehicle prior to billing detention!</Typography>
            <SLinkText
              className='underline-text'
              onClick={() =>
                navigateToInventory(
                  warningData?.shipment_stop?.stop_point_id,
                  selectedFilters.equipmentType === 'vehicle' ? 1 : 2
                )
              }
            >
              View Stop Point - Inventory
            </SLinkText>
          </div>
        }
        open={warningOpen}
        onClose={() => {
          setWarningData(null);
          setWarningOpen(false);
        }}
      />
      {openBulKBillCharges && (
        <AddToBillingCharges
          open={openBulKBillCharges}
          onClose={() => setOpenBulKBillCharges(false)}
          onSuccess={getDetentionData}
          data={selectedRows}
        />
      )}
      {openCreateInvoice && (
        <CreateInvoice
          open={openCreateInvoice}
          onClose={() => setOpenCreateInvoice(false)}
          detentions={selectedRows}
          onSuccess={() => {
            getDetentionData();
            setSelectedRows([]);
          }}
        />
      )}
      {!!dateToModify && (
        <ModifyDuration
          open={!!dateToModify}
          onClose={() => setDateToModify(null)}
          data={dateToModify}
          onSuccess={() => {
            getDetentionData();
            getTotalAmount();
          }}
        />
      )}
    </STableWrapper>
  );
};

export default DetentionTable;
