import React from 'react';
import { palette } from 'utils/constants';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';

const RoadsideInspectionQuestions = ({ values, handleChange }) => {
  return (
    <>
      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>What level inspection was it?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'inspection_level',
              value: 'Level 1',
              onChange: () => handleChange('inspection_level', 'Level 1'),
              checked: values.inspection_level === 'Level 1',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Level 1
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'inspection_level',
              value: 'Level 2',
              onChange: () => handleChange('inspection_level', 'Level 2'),
              checked: values.inspection_level === 'Level 2',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Level 2
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'inspection_level',
              value: 'Level 3',
              onChange: () => handleChange('inspection_level', 'Level 3'),
              checked: values.inspection_level === 'Level 3',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Level 3
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Did you pass inspection?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'inspection_status',
              value: 1,
              onChange: () => handleChange('inspection_status', 1),
              checked: values.inspection_status === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'inspection_status',
              value: 0,
              onChange: () => handleChange('inspection_status', 0),
              checked: values.inspection_status === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      {values.inspection_status === 0 && (
        <div className='d-flex flex-column'>
          <InputLabel className='mb-0'>Did you go out of service?</InputLabel>
          <div className='d-flex align-items-center gap-4'>
            <CustomRadioButton
              field={{
                name: 'inspection_out_of_service',
                value: 1,
                onChange: () => handleChange('inspection_out_of_service', 1),
                checked: values.inspection_out_of_service === 1,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                Yes
              </Typography>
            </CustomRadioButton>
            <CustomRadioButton
              field={{
                name: 'inspection_out_of_service',
                value: 0,
                onChange: () => handleChange('inspection_out_of_service', 0),
                checked: values.inspection_out_of_service === 0,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                No
              </Typography>
            </CustomRadioButton>
          </div>
        </div>
      )}
    </>
  );
};

export default RoadsideInspectionQuestions;
