import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';

import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Overlay } from 'react-bootstrap';
import classNames from 'classnames';
import { Typography } from 'components/Typography';
import { deleteStaffIncident } from 'Api/Incidents';
import { deleteDriverIncident } from 'Api/Driver';
import { getRulesAndConduct } from 'Api/CompanySettings';
import IncidentDetailsModal from 'components/ViewIncidentModal';
import AddIncident from 'components/AddIncidentModal';
import { getAllIncidents, getIncidentTabsCount } from 'Api/DotMonitoring';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import styles from 'components/StopPoint/Walmart/Walmart.module.css';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import AddStaffIncident from 'componentsV2/Staff/AddStaffIncident';
import { useLocation } from 'react-router-dom';
import SearchAutoFill from 'common/SearchAutoFill';
import { getSearchAutocomplete } from 'Api/AccidentsAndCrashes';
import RuleDetails from '../../../../RulesAndConduct/components/RuleDetails';
import { useColumns } from './Incidents.data';
import { INCIDENT_TABS, INFRACTION_TYPES } from '../../TableSection.data';
import classes from './Incidents.module.scss';

let controller = null;

const IncidentsTable = ({ refreshData, setRefreshData }) => {
  const location = useLocation();

  const target = useRef(null);
  const [isOpenShipment, setIsOpenShipment] = useState(false);
  const [openCreateIncident, setOpenCreateIncident] = useState(false);
  const [openCreateStaffIncident, setOpenCreateStaffIncident] = useState(false);

  const isStaff = false;

  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [viewData, setViewData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [rules, setRules] = useState([]);
  const [ruleToView, setRuleToView] = useState(null);
  const [addModalShow, setAddModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [driverIncident, setDriverIncident] = useState({});
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ field: 'incident_date', sortBy: 'desc' });
  const [tabs, setTabs] = useState([]);
  const [autcompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25, tab: 0, topTab: 0 });
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const debouncedSearch = useDebounce(search, 500);

  const getIncidents = async () => {
    setLoading(true);
    try {
      controller && controller.abort();
      controller = new AbortController();

      let stateSort;
      let citySort;
      const sortField = `sort[][${sort.field}]`;
      if (sort.field === 'country') {
        stateSort = `sort[][state]`;
        citySort = `sort[][city]`;
      }
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        from_date: dateRange.start ? moment(dateRange.start).format('DD-MM-YYYY') : undefined,
        to_date: dateRange.end ? moment(dateRange.end).format('DD-MM-YYYY') : undefined,
        [`${sortField}`]: sort.sortBy,
        'filters[action_taken]': selectedFilters.tab ? selectedFilters.tab : undefined,
        'filters[risk_level]':
          selectedFilters.topTab === 'H' || selectedFilters.topTab === 'L' ? selectedFilters.topTab : undefined,
        'filters[infraction_type]': selectedFilters.infraction_type?.id || undefined,
        query: debouncedSearch || undefined,
        [stateSort]: sort.sortBy,
        [citySort]: sort.sortBy,
      };

      const response = await getAllIncidents(params, controller.signal);
      setDriverIncident(response);
    } catch (e) {
      // do something
    } finally {
      setLoading(false);
    }
  };

  const getRules = async () => {
    try {
      const { data } = await getRulesAndConduct();
      setRules(data);
    } catch (e) {
      // Do nothing
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeTab = (item) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, tab: item.id });
  };

  const onChangeTopTab = (item) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, topTab: item.id });
  };

  const getTabsCount = async (params) => {
    const data = await getIncidentTabsCount(params);
    const allTab = {
      count: data.all,
      label: 'All',
      key: 0,
      value: 0,
      id: 0,
    };
    const localTabs = [allTab];
    data.action_taken.forEach((item) => {
      localTabs.push({
        count: item.incident_count,
        label: item.action,
        key: item.id,
        value: item.id,
        id: item.id,
      });
    });
    setTabs(localTabs);
  };

  useEffect(() => {
    const params = {
      from_date: dateRange.start ? moment(dateRange.start).format('DD-MM-YYYY') : undefined,
      to_date: dateRange.end ? moment(dateRange.end).format('DD-MM-YYYY') : undefined,
      'filters[risk_level]':
        selectedFilters.topTab === 'H' || selectedFilters.topTab === 'L' ? selectedFilters.topTab : undefined,
      'filters[infraction_type]': selectedFilters.infraction_type?.id || undefined,
      query: debouncedSearch || undefined,
    };
    getTabsCount(params);
  }, [selectedFilters, debouncedSearch, refreshData, dateRange]);

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  const removeIncident = (item) => {
    const { id } = item;
    deleteDriverIncident(id)
      .then(() => {
        showToaster({ type: 'success', message: 'Incident has been deleted successfully' });
        getIncidents();
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
      });
  };

  const removeStaffIncident = (item) => {
    const { id } = item;
    deleteStaffIncident(id)
      .then(() => {
        showToaster({ type: 'success', message: 'Incident has been deleted successfully' });
        getIncidents();
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
      });
  };

  const onView = (row) => {
    setViewData(row);
  };

  const onEdit = (rowData) => {
    setEditData(rowData);
    setAddModalShow(true);
  };

  useEffect(() => {
    getIncidents();
  }, [selectedFilters, sort, debouncedSearch, dateRange, refreshData]);

  useEffect(() => {
    getAutocompleteValues();
    getRules();
  }, []);

  useEffect(() => {
    if (location?.state?.openCreate && location?.state?.type === 'driver') {
      setOpenCreateIncident(true);
    }
    if (location?.state?.openCreate && location?.state?.type === 'staff') {
      setOpenCreateStaffIncident(true);
    }
  }, [location?.state]);

  const getAutocompleteValues = async () => {
    const params = {
      type: ['driver_name', 'staff_name', 'incident_id'],
    };
    const { data } = await getSearchAutocomplete(params);
    setAutocompleteOptions(data);
  };

  const columns = useColumns({
    sort,
    sortingQuery,
    onView,
    onDelete: isStaff ? removeStaffIncident : removeIncident,
    onEdit,
    rules,
    setRuleToView,
  });
  return (
    <div>
      <div className={styles.tabs_container}>
        {INCIDENT_TABS.map((tab) => {
          const active = selectedFilters.topTab === tab.id;
          return (
            <div key={tab?.id} className={classNames(styles.tabs_wrapper, { [styles.has_a_hover]: !active })}>
              <CustomButton
                type={active ? 'primary' : 'secondary'}
                title={tab?.title}
                onClick={() => onChangeTopTab(tab)}
                className={styles.tabs}
                styleTitle={{ padding: '2px 10px' }}
                hover={false}
              />
            </div>
          );
        })}
      </div>

      <Divider className='mt-2' />

      <div className='mb-3'>
        <SwitchRadio
          name='tableTopTabMenu'
          items={tabs}
          value={selectedFilters.tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
      </div>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex align-items-center gap-3'>
          <DateRangePicker
            top='-108px'
            initialRangeName='All Time'
            dateRange={dateRange}
            setDateRange={setDateRange}
            type='allTime'
          />
          <SearchAutoFill search={search} onChange={setSearch} className='search-input' options={autcompleteOptions} />
          <div className='w-100 '>
            <Autocomplete
              labelKey='title'
              options={INFRACTION_TYPES}
              placeholder='Select Infraction Type'
              value={selectedFilters.infraction_type}
              onChange={(e, val) => {
                setSelectedFilters({ ...selectedFilters, infraction_type: val });
              }}
              disableClearable={false}
              style={{ width: 200 }}
              isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
            />
          </div>
        </div>
        <CustomButton
          ref={target}
          type='primary'
          title='Add Incident'
          leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
          styleButton={{ padding: '5px 12px', margin: 0, fontSize: '14px' }}
          onClick={() => setIsOpenShipment(true)}
        />
        <Overlay
          placement='bottom-end'
          target={target.current}
          show={isOpenShipment}
          onHide={() => setIsOpenShipment(false)}
          rootCloseEvent='mousedown'
          rootClose
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => {
            return (
              <div
                {...props}
                className={classes.headerOverlay}
                style={{ ...props.style, backgroundColor: use(palette.white, palette.dark800) }}
              >
                <Typography
                  variant='s2'
                  onClick={() => {
                    setIsOpenShipment(false);
                    setOpenCreateIncident(true);
                  }}
                >
                  Driver Incident
                </Typography>
                <Typography
                  variant='s2'
                  onClick={() => {
                    setIsOpenShipment(false);
                    setOpenCreateStaffIncident(true);
                  }}
                >
                  Staff Incident
                </Typography>
              </div>
            );
          }}
        </Overlay>
      </div>

      {loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          data={driverIncident?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: () =>
              Pagination({
                data: driverIncident,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [10, 25, 50, 100, 250],
              }),
          }}
          columns={columns}
          onRowClick={(e, row) => onView(row)}
        />
      )}

      {openCreateIncident && (
        <AddIncident
          open={openCreateIncident}
          onClose={() => setOpenCreateIncident(false)}
          onSuccess={() => {
            setRefreshData(new Date());
          }}
        />
      )}
      {openCreateStaffIncident && (
        <AddStaffIncident
          open={openCreateStaffIncident}
          onClose={() => setOpenCreateStaffIncident(false)}
          onSuccess={() => {
            setRefreshData(new Date());
          }}
        />
      )}
      {!!viewData && (
        <IncidentDetailsModal
          open={!!viewData}
          incident={viewData}
          rules={rules}
          setRuleToView={setRuleToView}
          onEdit={onEdit}
          onClose={() => {
            setViewData(null);
          }}
        />
      )}
      {!!addModalShow && editData?.driver && (
        <AddIncident
          open={addModalShow}
          onClose={() => {
            setAddModalShow(false);
            setEditData(null);
          }}
          driverId={editData?.driver?.id}
          firstName={editData?.driver?.fname}
          lastName={editData?.driver?.lname}
          onSuccess={(data) => {
            getIncidents();
            if (viewData) {
              setViewData(data);
            }
          }}
          incident={editData}
        />
      )}
      {!!addModalShow && editData?.staff?.staff && (
        <AddStaffIncident
          open={addModalShow}
          onClose={() => {
            setAddModalShow(false);
            setEditData(null);
          }}
          staffId={editData?.staff?.staff?.id}
          firstName={editData?.staff?.staff?.firstName}
          lastName={editData?.staff?.staff?.lastName}
          onSuccess={(data) => {
            getIncidents();
            if (viewData) {
              setViewData(data);
            }
          }}
          incident={editData}
        />
      )}
      {!!ruleToView && <RuleDetails open={!!ruleToView} onClose={() => setRuleToView(null)} rule={ruleToView} />}
    </div>
  );
};

export default IncidentsTable;
