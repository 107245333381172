import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SStopBox = styled.div`
  padding: 0 8px;
  margin: 16px 0;
  border: 1px dashed ${palette.gray200};
  border-radius: 6px;
  height: 90px;
`;
