import moment from 'moment/moment';
import { formatNumber } from 'utils/constants';

export const getOptions = (currency) => ({
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 18,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value, index, values) {
          if (index === 0) {
            return `Jan ${moment().year()}`;
          }
          if (index === values.length - 1) {
            return `Dec ${moment().year()}`;
          }
        },
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label} - ${currency}${formatNumber(item[0].raw)}`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
});
