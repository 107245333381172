import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { acceptTerms, addRepresentative, cardOnboarding } from 'Api/CardManagement';

const Validation = ({ basicInfo, verification, setCurrentStep, idNumber, idFront, idBack, setPersonId }) => {
  const showToaster = useShowToaster();
  const [isVerifying, setIsVerifying] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await acceptTerms();
      showToaster({
        type: 'success',
        message: 'Application has been successfully submitted! Some features may take some time to activate',
      });
      setCurrentStep(6);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAccount = async () => {
    try {
      const bodyBasicInfo = {
        business_profile: {
          name: basicInfo.doing_business_as || '',
          mcc: basicInfo.industry.mcc,
          url: basicInfo.website,
          product_description: basicInfo.product_description,
        },
        company: {
          name: basicInfo.legal_business_name,
          address: {
            line1: basicInfo.address1,
            line2: basicInfo.address2 || '',
            postal_code: basicInfo.zipCode,
            city: basicInfo.city.name,
            state: basicInfo.state.name,
            country: basicInfo.country.sortname,
          },
          phone: basicInfo.business_phone_number,
          tax_id: basicInfo.ein,
        },
      };

      const bodyRepresentative = {
        first_name: verification.first_name,
        last_name: verification.last_name,
        email: verification.email,
        phone: verification.phone_number,
        dob: {
          day: moment(verification.date_of_birth).date().toString(),
          month: (moment(verification.date_of_birth).month() + 1).toString(),
          year: moment(verification.date_of_birth).year().toString(),
        },
        address: {
          line1: verification.address1,
          line2: verification.address2 || '',
          postal_code: verification.zipCode,
          city: verification.city.name,
          state: verification.state.name,
          country: verification.country.sortname,
        },
        ssn_last_4: verification.last4,
        relationship: {
          representative: true,
          title: verification.job_title,
          executive: !!verification.is_25_percent_owner && verification.is_the_only_25_percent_owner === 1,
          owner: !!verification.is_25_percent_owner,
          percent_ownership:
            verification.is_25_percent_owner === 1 && verification.is_the_only_25_percent_owner === 1
              ? 100
              : verification.is_25_percent_owner === 1
              ? 30
              : 0,
        },
      };

      await cardOnboarding(bodyBasicInfo);
      const { data } = await addRepresentative(bodyRepresentative);
      const person_id = data?.[0]?.id;
      setPersonId(person_id);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  useEffect(() => {
    if (!idNumber && !idFront && !idBack) {
      handleCreateAccount();
    }

    setTimeout(
      () => {
        setIsVerifying(false);
      },
      !idNumber ? 10000 : 20000
    );
  }, []);

  return (
    <div className='d-flex flex-column gap-4' style={{ width: '400px' }}>
      <div className='d-flex flex-column' style={{ marginTop: '32px', gap: '2px' }}>
        <Typography variant='h2'>Review and finish up</Typography>
        <Typography variant='s3' style={{ color: palette.gray500 }}>
          You’re almost ready to get started with Rocket Deliveries. Take a moment to review and confirm your
          information.
        </Typography>
      </div>
      <div>
        <div className='d-flex justify-content-between'>
          <Typography variant='c1' style={{ color: palette.gray700 }}>
            BUSINESS DETAILS
          </Typography>
          <Chip label='APPROVED' labelColor={palette.green500} bgColor={palette.green0} />
        </div>
        <div
          style={{
            width: '400px',
            backgroundColor: palette.gray100,
            border: `1px solid ${palette.gray200}`,
            borderRadius: '8px',
            padding: '20px 24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            margin: '8px 0 24px',
          }}
        >
          <Typography variant='c1'>{basicInfo.legal_business_name}</Typography>
          <div className='d-flex flex-column' style={{ marginBottom: '16px' }}>
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              {basicInfo.website}
            </Typography>
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              {basicInfo.address1}
            </Typography>
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              {basicInfo.city.name}, {basicInfo.state.short_name} {basicInfo.address2} {basicInfo.country.sortname}
            </Typography>
          </div>
          <Typography variant='c1'>Other information provided</Typography>
          <Typography variant='c2' style={{ color: palette.gray700 }}>
            EIN, DBA, Phone, Industry
          </Typography>
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-between align-items-end'>
          <Typography variant='c1' style={{ color: palette.gray700 }}>
            MANAGEMENT AND OWNERSHIP
          </Typography>
          {(!idNumber || !idFront || !idBack) && !isVerifying && (
            <Chip
              size='small'
              label='ADDITIONAL INFORMATION REQUIRED'
              labelColor={palette.goldDark}
              bgColor={palette.goldLight}
              height='20px'
            />
          )}
          {idNumber && idFront && idBack && !isVerifying && (
            <Chip label='APPROVED' labelColor={palette.green500} bgColor={palette.green0} />
          )}
        </div>
        <div
          style={{
            width: '400px',
            backgroundColor: (!idNumber || !idFront || !idBack) && !isVerifying ? palette.red0 : palette.gray100,
            border:
              (!idNumber || !idFront || !idBack) && !isVerifying
                ? `1px solid ${palette.red200}`
                : `1px solid ${palette.gray200}`,
            borderRadius: '8px',
            padding: '20px 24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginTop: '8px',
          }}
        >
          {(!idNumber || !idFront || !idBack) && !isVerifying && (
            <div>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center gap-1'>
                  <WarningIcon fill={palette.red500} width={24} />
                  <Typography variant='s2'>Missing required information</Typography>
                </div>
                <CustomButton
                  type='secondary'
                  title='Update'
                  leftIcon={<EditIcon height={14} width={14} fill={palette.gray700} style={{ marginRight: '6px' }} />}
                  styleButton={{ padding: '6px 12px', margin: 0 }}
                  styleTitle={{ fontSize: 14 }}
                  onClick={() => {
                    if (!idNumber) {
                      setCurrentStep(4);
                      return;
                    }
                    if (!idFront || !idBack) {
                      setCurrentStep(5);
                    }
                  }}
                />
              </div>
              <Divider color={palette.red200} />
            </div>
          )}
          <div>
            <div className='d-flex flex-column'>
              <Typography variant='c1'>
                {verification.first_name} {verification.last_name}
              </Typography>
              <Typography variant='c2'>Account Representative, Owner and Executive</Typography>
            </div>
          </div>
          <Typography variant='c2' style={{ color: palette.gray700 }}>
            {verification.email}
          </Typography>
          <Typography variant='c2' style={{ color: palette.gray700 }}>
            Born on {moment(verification.date_of_birth).format('MMMM D, YYYY')}
          </Typography>
          <div className='d-flex flex-column' style={{ marginBottom: '16px' }}>
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              {verification.address1}
            </Typography>
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              {verification.city.name}, {verification.state.short_name} {verification.address2}{' '}
              {verification.country.sortname}
            </Typography>
          </div>
          <Typography variant='c1'>Other information provided</Typography>
          <Typography variant='c2' style={{ color: palette.gray700 }}>
            SSN, Job title, Phone
          </Typography>
          {isVerifying && (
            <>
              <Divider color={palette.gray500} />
              <div className='d-flex align-items-center gap-3'>
                <CircularProgress size={16} />
                <div className='d-flex flex-column'>
                  <Typography variant='c1'>Verifying...</Typography>
                  <Typography variant='c1'>This can take up to 1 minute.</Typography>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <Typography variant='c1'>
          By clicking Agree & submit, you agree to the{' '}
          <a href='https://stripe.com/legal/connect-account' target='_blank' rel='noreferrer'>
            <Typography variant='c1' style={{ color: palette.indigo500, cursor: 'pointer' }}>
              Connected Account Agreement
            </Typography>
            .
          </a>
        </Typography>
      </div>
      <div className='d-flex flex-column gap-3 pb-5'>
        <CustomButton
          height={12}
          type='primary'
          title='Agree & Submit'
          styleButton={{
            backgroundColor: palette.green500,
            padding: '4px 12px',
            margin: 0,
            justifyContent: 'center',
            width: '400px',
            boxShadow: 'none',
          }}
          onClick={handleSubmit}
          disabled={!idNumber || !idFront || !idBack || isVerifying || loading}
        />
        <CustomButton
          height={12}
          type='secondary'
          title='Back'
          styleButton={{ padding: '4px 12px', margin: 0, justifyContent: 'center', width: '400px' }}
          onClick={() => setCurrentStep(2)}
        />
      </div>
    </div>
  );
};

export default Validation;
