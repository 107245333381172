import React from 'react';
import Input, { InputWithIcon } from '../../../../../../common/Input';
import { Typography } from '../../../../../../components/Typography';
import { palette } from '../../../../../../utils/constants';
import { useTheme } from '../../../../../../context/themeContext';
import { SWrapper, SInputLabel } from './Input.styles';

export const InputWithIconAndText = ({ text, ...props }) => {
  const { use } = useTheme();

  return (
    <SWrapper>
      <InputWithIcon wrapperStyle={{ borderRadius: '6px 0 0 6px' }} {...props} />
      <SInputLabel>
        <Typography variant='s2' style={{ color: use(palette.gray500, palette.gray200) }}>
          {text}
        </Typography>
      </SInputLabel>
    </SWrapper>
  );
};

export const InputWithText = ({ text, style = {}, ...props }) => {
  const { use } = useTheme();

  return (
    <SWrapper>
      <Input style={{ borderRadius: '6px 0 0 6px', ...style }} {...props} />
      <SInputLabel>
        <Typography variant='s2' style={{ color: use(palette.gray500, palette.gray200) }}>
          {text}
        </Typography>
      </SInputLabel>
    </SWrapper>
  );
};
