import React, { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { getTopCompany } from 'Api/Payroll';
import ChartSection from 'pages/Payroll/PayrollAndSettlements/components/TopSection/ChartSection';
import { topCompanyConverter } from 'pages/Payroll/PayrollAndSettlements/components/TopSection/helpers';
import { SBoxContent } from 'pages/Dashboard/Dashboard.styles';

const Payroll = () => {
  const [loading, setLoading] = useState(true);
  const [topCompany, setTopCompany] = useState(null);

  const getTopCompanyData = async () => {
    try {
      setLoading(true);
      const { data } = await getTopCompany({ driver_type: ['company', 'owner'] });
      const convertedData = topCompanyConverter(data);
      setTopCompany(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTopCompanyData();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <SBoxContent>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          Payroll
        </Typography>
        {loading || !topCompany ? (
          <Skeleton
            variant='rect'
            animation='wave'
            height='100%'
            width='100%'
            style={{ borderRadius: '10px', minHeight: '260px' }}
          />
        ) : (
          <ChartSection topCompany={topCompany} />
        )}
      </SBoxContent>
    </div>
  );
};

export default Payroll;
