import React, { useEffect, useMemo, useRef, useState } from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { deleteSettingsGroup, GetSettingsGroups } from 'Api/CompanySettings';
import AddGroup from 'componentsV2/Group/AddGroup';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as UpdateIcon } from 'assets/icons/editPensle.svg';
import styles from './Groups.module.css';

const Groups = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [groups, setGroups] = useState({ data: [] });
  const [addModal, setAddModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const { theme, use } = useTheme();
  const elementsRef = useRef();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedFilters] = useState({
    page: 1,
    itemsPerPage: 100,
  });

  const getGroupsList = async () => {
    const { page, itemsPerPage, ...rest } = selectedFilters || {};
    try {
      const params = {
        page,
        itemsPerPage,
        ...rest,
      };
      const groupsData = await GetSettingsGroups(params);
      setGroups(groupsData);
    } catch (e) {
      /* emty */
    }
  };

  const deleteGroup = async (id) => {
    if (updateLoading) {
      return;
    }

    setUpdateLoading(true);
    try {
      await deleteSettingsGroup(id);
      await getGroupsList();
      showToaster({ type: 'success', message: 'Tag has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
    setUpdateLoading(false);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'group_name',
        width: '5%',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            TAG NAME
          </Typography>
        ),
        render: (rowData) => {
          const name = rowData?.group_name;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='s2' style={{ color: use(palette.dark800, palette.gray200) }}>
                {name || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'group_logo',
        width: '5%',
        title: <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }} />,
        render: (rowData) => {
          return (
            <div className={styles.group_wrapper} style={{ backgroundColor: `${rowData?.color}` }}>
              <Typography variant='s2' style={{ color: use(palette.dark800, palette.gray200), padding: '2px 6px' }}>
                {rowData?.short_name || rowData?.name || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'actions',
        width: '5%',
        title: <Typography />,
        render: (rowData) => {
          return (
            <div style={{ display: 'flex', gap: '16px', justifyContent: 'flex-end' }}>
              <span
                className={styles.action_wrapper}
                onClick={() => {
                  setUpdateData(rowData);
                  setAddModal(true);
                }}
              >
                <UpdateIcon width={11} height={11} fill={palette.indigo500} />
              </span>
              <span className={styles.action_wrapper} onClick={() => deleteGroup(rowData.id)}>
                <DeleteIcon width={11} height={11} fill={palette.red500} />
              </span>
            </div>
          );
        },
      },
    ];
  }, [groups?.data, elementsRef, theme, updateLoading]);

  useEffect(() => {
    getGroupsList().then();
  }, [selectedFilters]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header_wrapper}>
          <div>
            <Typography variant='h4'>Tags</Typography>
          </div>
          <div className={styles.actions}>
            {isConfiguringStep && (
              <>
                {' '}
                <CustomButton
                  onClick={onCancelPrevPage}
                  type='secondary'
                  title='Back'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
                <CustomButton
                  onClick={onSaveAndNext}
                  type='secondary'
                  title='Skip'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
              </>
            )}
            <CustomButton
              type='secondary'
              title='Add Tag'
              leftIcon={<PlusIcon style={{ marginRight: 10, fill: '#464F60' }} />}
              styleButton={{ margin: 0 }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              onClick={() => {
                setUpdateData(null);
                setAddModal(true);
              }}
            />
          </div>
        </div>
        <div className={styles.table_wrapper}>
          <MaterialTableWrapper
            data={groups?.data}
            rowPerPage={selectedFilters.itemsPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{ Pagination: () => null }}
            columns={columns}
          />
        </div>
      </div>
      {!!addModal && (
        <AddGroup open={addModal} onClose={() => setAddModal(false)} group={updateData} onSuccess={getGroupsList} />
      )}
    </>
  );
};
export default Groups;
