import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

class UserService {
  getCurrentUser(id) {
    return axios.get(`${API_URL}/users/${id}`, { headers: authHeader() });
  }
}

export default new UserService();
