import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import './PlanToVehicle.css';
import { Tab, Tabs } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Accordion from '@mui/material/Accordion';
import Skeleton from '@material-ui/lab/Skeleton';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import tsa, { ReactComponent as Tsa } from 'assets/icons/drivers/tsa.svg';
import fast, { ReactComponent as Fast } from 'assets/icons/drivers/fast.svg';
import twic, { ReactComponent as Twic } from 'assets/icons/drivers/twic.svg';
import maxico from 'assets/icons/drivers/mexico.png';
import useFlag, { ReactComponent as UsaFlag } from 'assets/icons/drivers/usaFlag.svg';
import location from 'assets/icons/vehicles/location.svg';
import subRight from 'assets/icons/vehicles/sub-right.svg';
import turkeyFlag from 'assets/icons/drivers/turkeyFlag.svg';
import { ReactComponent as IdleIcon } from 'assets/icons/idle.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import { ReactComponent as FuelIcon } from 'assets/icons/fuel.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/expand.svg';
import { ReactComponent as OfferIcon } from 'assets/icons/offer.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as EnginOnIcon } from 'assets/icons/enginOn.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as EnginOfIcon } from 'assets/icons/engineOff.svg';
import { ReactComponent as Approved } from 'assets/icons/icons-approval.svg';
import { ReactComponent as Favourite } from 'assets/icons/favourite.svg';
import { ReactComponent as StarFilled } from 'assets/icons/star-filled.svg';
import { ReactComponent as CallIcon } from 'assets/icons/drivers/call.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/drivers/inbox.svg';

import Progress from 'components/Progress';
import { Typography } from 'components/Typography';
import TableRowsEdit from 'components/TableRowsEdit';
import AddEditModalDetails from 'components/AddEditModalStops';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import ShipmentInfo from 'components/TablePlaner/helpers/ShipmentInfo';
import classes from 'components/TableShipments/detailsRow/details.module.css';
import { SkeletonStops } from 'components/TableShipments/detailsRow/steps/OverView/OverView';
import OverViewStop from 'components/TableShipments/detailsRow/steps/OverView/OverViewStops';
import { formatAmount } from 'components/Customers/helpers/CustomersTableHeaderFilterByShow';
import SettingsModal from 'components/TableShipments/detailsRow/steps/OverView/SettingsModal';
import TablePlannerRowEditChildes from 'components/TablePlaner/helpers/TablePlannerRowEditChildes';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import {
  OFFER_TO_VEHICLE,
  PLAN_TO_VEHICLE,
  PLAN_TO_VEHICLE_CANCELED,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { palette, TRUCK_TYPES } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { UpdateStopPoint } from 'Api/StopPoint';
import useShowToaster from 'hooks/useShowToaster';
import { updateRowEditItem } from 'store/reducers/root.reducer';
import { getAbsenceData, getShipmentStops, nearbyVehicles } from 'Api/Planner';
import {
  addShipmentStop,
  deleteShipmentStop,
  getMultipleShipmentsInfo,
  getShipmentInfo,
  getStops,
  updateCargo,
  UpdateStopPointStop,
} from 'Api/Shipment';
import useDateFormat from 'hooks/useDateFormat';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Chip from 'common/Chip';
import { getCarriersList } from 'Api/Carriers';
import { getShipmentType } from 'utils/helpers';
import headerIcon from 'assets/icons/vehicles/green.svg';
import Pagination from 'common/Pagination';
import UserInfo from 'components/UserInfo';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { initialFilters } from 'components/Carriers/Carriers.data';
import VehicleSkeleton from 'components/Map/MapComponents/VehicleSkeleton';
import { getPlannerStatus } from 'components/TableShipments/helpers/constants';
import EquipmentTypes from 'components/Carriers/CarrierProfile/EquipmentTypes';
import { DocumentTableSort } from 'components/DocumentsTableSort/DocumentsTableSort';
import { SAccordion, SWrapper } from 'components/DispatchVehicle/DispatchVehicle.styles';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import OfferOptions from 'componentsV2/Planner/OfferOptions';
import OfferCarrier from 'componentsV2/Planner/OfferCarrier';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { getShipmentOfferSettings } from 'Api/CompanySettings';
import {
  createCargoConverter,
  createCargoFormDataConverter,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import useForm from 'hooks/useForm';
import { checkAddOnStatus } from 'Api/Equipment';
import Dropdown from 'react-bootstrap/Dropdown';
import down from 'assets/icons/drivers/down.svg';
import ValidationlModal from './ValidationlModal';
import OfferMap from './OfferMap';
import PlanMap from './PlanMap';
import AlreadyDispatchedModal from './AlreadyDispatchedModal';
import Hos from '../TableShipments/helpers/Hos';

export default function PlanToVehicle() {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const showToaster = useShowToaster();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const tab = searchParams.get('tab');
  const shipmentIds = searchParams.getAll('id');
  const action = searchParams.get('action');
  const { currency } = useSelector((state) => state.root);
  const [tableShipmentModalObj, setTableShipmentModalObj] = useState({});
  const [pageData, setPageData] = useState(null);
  const [radius, setRadius] = useState('');
  const [showEquipmentType, setShowEquipmentType] = useState(true);
  const [showPreferredTravelArea, setShowPreferredTravelArea] = useState(false);
  const [preferredOnly, setPreferredOnly] = useState(false);
  const [shipmentForEquipmentType, setShipmentForEquipmentType] = useState(false);
  const [operatingStates, setOperatingStates] = useState(false);
  const [vehicles, setVehicles] = useState();
  const [reloadConfig] = useState(true);
  const [offers, setOffers] = useState();
  const [validationModalData, setValidationModalData] = useState({ title: '', header: '' });
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [checked, setChecked] = useState([]);
  const [key, setKey] = useState(tab || (action === 'offer' ? 'Offer' : 'Vehicle') || 'Vehicle');
  const navigate = useNavigate();
  const [selectModalType, setSelectModalType] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchCarrier, setSearchCarrier] = useState('');
  const debouncedSearch = useDebounce(searchValue, 500);
  const debouncedSearchCarrier = useDebounce(searchCarrier, 500);
  const [route, setRoute] = useState([]);
  const [infoHeader, setInfoHeader] = useState({});
  const [legs, setLegs] = useState([]);
  const [expandedShipment, setExpandedShipment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCarriers, setLoadingCarriers] = useState(false);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [shipmentsInfo, setShipmentsInfo] = useState([]);
  const [alreadyDispatched, setAlreadyDispatched] = useState(null);
  const [openOfferOptions, setOpenOfferOptions] = useState(false);
  const [refreshOfferOptions, setRefreshOfferOptions] = useState(Date.now());
  const [showLicence, setShowLicence] = useState(false);
  const [driverRouteIncludesCountries, setDriverRouteIncludesCountries] = useState([]);
  const [stopsRouteIncludesCountries, setStopsRouteIncludesCountries] = useState([]);
  const legsStopsCount = [];
  const hasBrokerage = useHasBrokerage();

  const pageTitle = {
    vehicle:
      shipmentsInfo?.length > 1
        ? hasBrokerage
          ? 'Multi-Shipment Dispatch'
          : 'Multi-Shipment Plan to Vehicle'
        : hasBrokerage
        ? 'Dispatch'
        : 'Plan to Vehicle',
    offer:
      shipmentsInfo?.length > 1
        ? hasBrokerage
          ? 'Multi-Shipment Offer'
          : 'Multi-Shipment Offer to Vehicle'
        : hasBrokerage
        ? 'Offer'
        : 'Offer to Vehicle',
    carriers: action === 'offer' ? 'Offer' : hasBrokerage ? 'Dispatch' : 'In-Network Carriers',
    external: action === 'offer' ? 'Offer' : hasBrokerage ? 'Dispatch' : 'External Network Carriers',
  };

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [carriers, setCarriers] = useState({ data: [] });
  const [showMoreOpen, setShowMoreOpen] = useState(null);
  const [moreModalType, setMoreModalType] = useState('');
  const [routeSelect, setRouteSelect] = useState('practical');

  const { values: mapValues, handleChange: handleMapValuesChange } = useForm({
    initialValues: {
      route_type: 'practical',
      hwy_only: 0,
      open_borders: 0,
      haz_mat: 0,
      avoid_tolls: 0,
      show_traffic: 0,
      show_weather: 0,
      vehicle_type: 0,
    },
  });

  const sendEmail = (event, email) => {
    event.stopPropagation();
    window.location.href = `mailto:${email}`;
  };

  const call = (event, phone) => {
    event.stopPropagation();
    window.location.href = `tel:${phone}`;
  };
  const columns = [
    {
      field: 'name',
      title: <DocumentTableSort title='Carrier name' field='name' />,
      render: (rowData) => {
        const { name, can_travel_to, approved_for, token, preferred } = rowData;
        return (
          <div>
            <div className='d-flex gap-2 align-items-center'>
              {rowData?.logo_path ? (
                <img
                  src={rowData.logo_path}
                  alt='img'
                  width={30}
                  height={30}
                  style={{ borderRadius: '50%', objectFit: 'cover' }}
                />
              ) : (
                <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
              )}
              <span style={{ color: use(palette.dark800, palette.gray200) }}>{name}</span>
              {!!token && !!token?.used && <Approved width={18} height={18} className='ms-2' />}
              {preferred ? <StarFilled fill={palette.amber500} /> : <Favourite />}
            </div>
            <div>
              {!!can_travel_to?.includes('1') && <UsaFlag />}
              {!!can_travel_to?.includes('2') && <img src={turkeyFlag} alt='mexico-Flag' />}
              {!!can_travel_to?.includes('3') && <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />}
              {!!approved_for?.includes('1') && <Tsa width={30} height={30} />}
              {!!approved_for?.includes('2') && <Fast width={40} height={30} />}
              {!!approved_for?.includes('3') && <Twic width={40} height={30} />}
            </div>
          </div>
        );
      },
    },
    {
      field: 'dot',
      title: <DocumentTableSort title='DOT/MC' field='dot' />,
      render: (rowData) => {
        const { dot, mc } = rowData;
        return (
          <div className='d-flex flex-column'>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>DOT {dot || '-'}</span>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>MC {mc || '-'}</span>
          </div>
        );
      },
    },
    {
      field: 'status',
      title: <DocumentTableSort title='Status' field='status_id' />,
      render: (rowData) => {
        const { status } = rowData;

        return (
          <Chip
            fontWeight={500}
            fontSize='11px'
            style={{ textTransform: 'uppercase' }}
            bgColor={status.bg_color}
            labelColor={status.color}
            label={status.title}
          />
        );
      },
    },
    {
      field: 'states',
      title: <DocumentTableSort title='OPERATING STATES' field='status_id' />,
      render: (rowData) => {
        const { all_states_canada, all_states_mexico, all_states_usa } = rowData;

        return (
          <>
            <div className='d-flex flex-column'>
              {!!all_states_usa && (
                <span style={{ color: use(palette.dark800, palette.gray200) }}>USA - All States</span>
              )}
              {!!all_states_canada && (
                <span style={{ color: use(palette.dark800, palette.gray200) }}>Canada - All States</span>
              )}
              {!!all_states_mexico && (
                <span style={{ color: use(palette.dark800, palette.gray200) }}>Mexico - All States</span>
              )}
            </div>
            <>
              {!rowData?.operating_states?.length && '-'}
              {(rowData?.operating_states || [])?.slice(0, 2).map((item) => (
                <Chip
                  key={item.id}
                  size='medium'
                  label={item?.short_name}
                  labelColor={palette.indigo500}
                  fontWeight={500}
                  bgColor={palette.indigo0}
                  height='20px'
                  style={{ marginRight: '5px' }}
                />
              ))}
              {rowData?.operating_states?.length > 2 && (
                <Typography
                  variant='c1'
                  style={{ color: palette.indigo500 }}
                  onClick={(e) => showMore(e, rowData, 'states')}
                >
                  Show More
                </Typography>
              )}
            </>
          </>
        );
      },
    },
    {
      field: 'contact_info',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
          CONTACT INFO
        </Typography>
      ),
      render: (rowData) => {
        const { phone_number, email } = rowData;
        return (
          <div>
            <a onClick={(e) => sendEmail(e, email)} style={{ textDecoration: 'none' }}>
              <EmailIcon /> <span style={{ color: use(palette.dark800, palette.gray200) }}>{email || '-'}</span>
            </a>
            <br />
            <a onClick={(e) => call(e, phone_number)} style={{ textDecoration: 'none' }}>
              <CallIcon />
              <span style={{ color: use(palette.dark800, palette.gray200) }}>
                {formatPhoneNumberIntl(phone_number) || '-'}
              </span>
            </a>
          </div>
        );
      },
    },
    {
      field: 'equipment_type',
      title: <DocumentTableSort title='OPERATING EQUIPMENT' field='equipment_type' />,
      render: (rowData) => {
        return (
          <div>
            {!rowData?.equipment_types?.length && '-'}
            {(rowData?.equipment_types || [])?.slice(0, 2).map((item) => (
              <Chip
                key={item.id}
                size='medium'
                label={item?.title}
                labelColor={palette.indigo500}
                fontWeight={500}
                bgColor={palette.indigo0}
                height='20px'
                style={{ marginRight: '5px' }}
              />
            ))}
            {rowData?.equipment_types?.length > 2 && (
              <Typography
                variant='c1'
                style={{ color: palette.indigo500 }}
                onClick={(e) => showMore(e, rowData, 'equipment')}
              >
                Show More
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'power_units',
      title: <DocumentTableSort title='Power Units' field='power_unit' />,
      render: (rowData) => {
        const { power_unit } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{power_unit || '-'}</span>;
      },
    },
    {
      field: 'action',
      render: (rowData) => {
        return (
          <CustomButton
            type='primary'
            title='Select'
            styleButton={{ padding: '6px 12px' }}
            onClick={() => navigate(`/planner/carrier-dispatch?id=${id}&carrier=${rowData.id}`)}
          />
        );
      },
    },
  ];

  const showMore = (e, data, type) => {
    e.stopPropagation();
    setMoreModalType(type);
    setShowMoreOpen(type === 'equipment' ? data.equipment_types : data.operating_states);
  };

  const orderingInLegsStops = useCallback((data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    return legsStopsData;
  }, []);

  const getMultipleShipmentsData = async () => {
    try {
      const { data } = await getMultipleShipmentsInfo({ shipment_id: shipmentIds });
      const sortedData = shipmentIds.map((id) => data.find((item) => item.shipment_id === id));
      setShipmentsInfo(sortedData);
      setExpandedShipment({ ...sortedData[0], index: 0 });
    } catch (e) {
      // Do nothing
    }
  };

  const getStopsData = () => {
    setLoading(true);
    getStops({ shipment_id: id })
      .then((res) => res?.data && setLegs(orderingInLegsStops(res?.data)))
      .finally(() => setLoading(false));
  };

  const getHeaderInfo = () => {
    getShipmentInfo(expandedShipment?.shipment_id)
      .then((res) => {
        if (res?.data) {
          setInfoHeader(res.data);
          handleMapValuesChange('open_borders', res.data.shipment_route?.open_borders);
          handleMapValuesChange('hwy_only', res.data.shipment_route?.highway_only);
          handleMapValuesChange('haz_mat', res.data.shipment_route?.hazmat_id);
          handleMapValuesChange('route_type', res.data.shipment_route?.route_type);
          handleMapValuesChange('vehicle_type', res.data.shipment_route?.vehicle_type);
          handleMapValuesChange('avoid_tolls', res.data.shipment_route?.avoid_tolls);
          setRouteSelect(res.data.shipment_route?.route_type);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  function onClickItemSettingsIcon(stop) {
    setIsOpenSettingsModal(stop?.stop_point?.id);
  }

  function onDeleteStop(stopId) {
    deleteShipmentStop(stopId)
      .then(() => {
        getHeaderInfo();
        getStopsData();
        showToaster({ type: 'success', message: 'Shipment stop has been deleted successfully!' });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  }

  const onUpdateSuccess = () => {
    getHeaderInfo();
    getStopsData();
  };

  useEffect(() => {
    getMultipleShipmentsData();
  }, []);

  useEffect(() => {
    if (expandedShipment) {
      getHeaderInfo();
      getShipmentStops(expandedShipment?.shipment_id).then((res) => {
        setPageData(res.data);
      });
    }
  }, [expandedShipment]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function getPairs() {
      try {
        setLoadingVehicles(true);
        const res = await nearbyVehicles({
          id,
          radius: radius || undefined,
          type: showEquipmentType,
          shipment_for_operating_states: Number(showPreferredTravelArea),
          query: debouncedSearch || undefined,
          cancelToken: source.token,
        });

        if (res.data) {
          setVehicles(res.data);
          setSelectedVehicle(res.data[0]);
          const offers = res.data.filter((item) => item.drivers?.[0].compensation_details?.allow_shipment_offers === 1);
          setOffers(offers);
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          // Do nothing
          if (err?.response?.data?.response?.shipment_id) {
            getShipmentInfo(id)
              .then((res) => {
                const { dispatched_by_user } = res.data || {};
                if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                  setAlreadyDispatched(
                    `${dispatched_by_user.first_name || ''} ${
                      dispatched_by_user.last_name || ''
                    } has already dispatched this shipment, we are now exiting the dispatch process!`
                  );
                }
              })
              .catch(() => {
                // Do nothing
              });
          }
        }
      } finally {
        setLoadingVehicles(false);
      }
    }

    if (reloadConfig) {
      getPairs();
    }

    return () => {
      source.cancel('Canceled due to stale request');
    };
  }, [reloadConfig, radius, showEquipmentType, showPreferredTravelArea, debouncedSearch]);

  function onUpdateSettings(id, validateForm, resetForm, submitForm, values) {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        const backData = {};
        Object.keys(values).forEach((key) => {
          const value = values[key];
          if (typeof value === 'boolean') {
            backData[key] = value ? 1 : 0;
          } else {
            backData[key] = value;
          }
        });
        UpdateStopPoint(id, backData).then(() => setIsOpenSettingsModal(''));
      }
    });
  }

  function checkDateInterval(startdate, enddate, datesArray) {
    const startDateObj = new Date(startdate);
    const endDateObj = new Date(enddate);

    for (let i = 0; i < datesArray.length; i++) {
      const dateObj = new Date(datesArray[i]);
      if (dateObj >= new Date() && dateObj >= startDateObj && dateObj <= endDateObj) {
        return true;
      }
    }
    return false;
  }

  function calculateSums(weight, stops) {
    const sums = [];
    let sum = 0;
    for (let i = 0; i < stops.length; i++) {
      if (Number(stops[i].stop_point_type) === 1) {
        sum += Number(stops[i].weight);
      } else if (Number(stops[i].stop_point_type) === 2) {
        sums.push(Number(sum));
        sum = 0;
      }
    }
    return sums;
  }

  function validateWeight(weight, shipments) {
    const sums = calculateSums(weight, shipments);
    const maxSum = Math.max(...sums);

    if (Number(weight) < Number(maxSum)) {
      return {
        isValid: false,
        weight: maxSum,
      };
    }
    return {
      isValid: true,
      weight: maxSum,
    };
  }

  function getDatesInRange(datesArray, startDate, endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const result = [];
    datesArray.forEach((item) => {
      const currentDate = new Date(item);
      if (currentDate >= startDateObj && currentDate <= endDateObj) {
        result.push(formatDate(item));
      }
    });
    return result;
  }

  function onBackPlanner() {
    navigate('/planner');
  }

  const offer = () => {
    if (key === 'External' && action === 'offer') {
      setRefreshOfferOptions(Date.now());
      return;
    }

    const equipmentsArray = checked.map((item) => item.equipment_id);
    const equipments = equipmentsArray.join();

    if (shipmentsInfo.length > 1) {
      const queryParams = new URLSearchParams();
      equipmentsArray.forEach((equipment) => {
        queryParams.append('equipment', equipment);
      });
      shipmentIds.forEach((id) => {
        queryParams.append('id', id);
      });
      Object.keys(mapValues).forEach((key) => {
        if (mapValues[key]) {
          queryParams.append(key, mapValues[key]);
        }
      });

      navigate(`/planner/offer-multiple?${queryParams.toString()}`);
      return;
    }
    const queryParams = new URLSearchParams();
    Object.keys(mapValues).forEach((key) => {
      if (mapValues[key]) {
        queryParams.append(key, mapValues[key]);
      }
    });
    navigate(`/planner/offer?id=${id}&equipment=${equipments}&${queryParams.toString()}`);
  };

  const selectVehicle = async (vehicle) => {
    const vehicleData = vehicle || selectedVehicle;

    if (shipmentsInfo.length > 1) {
      const queryParams = new URLSearchParams();
      queryParams.set('equipment', selectedVehicle?.equipment_id);
      shipmentIds.forEach((id) => {
        queryParams.append('id', id);
      });

      navigate(`/planner/dispatch-multiple?${queryParams.toString()}`);
      return;
    }

    const messages = [];
    const arr = [];
    if (vehicleData.drivers[0].certifications.can_travel_usa === 1) {
      arr.push(231);
    }
    if (vehicleData.drivers[0].certifications.can_travel_canada === 1) {
      arr.push(38);
    }
    if (vehicleData.drivers[0].certifications.can_travel_mexico === 1) {
      arr.push(142);
    }

    if (vehicleData?.drivers[0]?.status_data?.id === 4) {
      messages.push('Driver is Out of Service');
    }
    if (!vehicleData.required_equipment_type) {
      messages.push('Driver is not in proper equipment type for the pick up');
    }

    const pickupDate = infoHeader.shipment_stops.find((item) => +item.stop_point_type === 1)?.scheduled_date;
    const deliveryDate = infoHeader.shipment_stops.find((item) => +item.stop_point_type === 2)?.scheduled_date;

    const absenceData = await getAbsenceData({ driver_id: vehicleData.drivers[0].id });

    let isDateInRange = false;
    if (absenceData && absenceData.data && absenceData.data.dates?.length) {
      isDateInRange = checkDateInterval(pickupDate, deliveryDate, absenceData.data.dates);
    }
    if (isDateInRange) {
      messages.push(
        `Driver has an absence scheduled on ${getDatesInRange(absenceData.data.dates, pickupDate, deliveryDate)}`
      );
    }

    if (
      !!infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age_flag &&
      vehicleData.year &&
      moment().year() - Number(vehicleData.year) >
        infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age
    ) {
      messages.push(
        `This customer does not allow to use vehicle older than ${infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age} years`
      );
    }

    if (
      !!infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age_flag &&
      vehicleData?.hooked_to?.year &&
      moment(vehicleData.hooked_to.year).isValid() &&
      moment()
        .subtract(
          infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age,
          'years'
        )
        .isAfter(vehicleData.hooked_to.year)
    ) {
      messages.push(
        `This customer does not allow to use trailer older than ${infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age} years`
      );
    }

    let shouldNavigate = true;
    const restrictedCountryIds = [];

    pageData.shipment_stops.forEach((stop) => {
      if (!arr.includes(stop.stop_point.country.id)) {
        shouldNavigate = false;
        restrictedCountryIds.push(stop.stop_point.country.id);
      }
    });

    driverRouteIncludesCountries.forEach((countryId) => {
      if (!arr.includes(countryId)) {
        shouldNavigate = false;
        restrictedCountryIds.push(countryId);
      }
    });

    stopsRouteIncludesCountries.forEach((countryId) => {
      if (!arr.includes(countryId)) {
        shouldNavigate = false;
        restrictedCountryIds.push(countryId);
      }
    });

    if (!shouldNavigate) {
      const restrictedCountries = [...new Set(restrictedCountryIds)].map((item) =>
        item === 231 ? 'USA' : item === 38 ? 'Canada' : item === 142 ? 'Mexico' : ''
      );
      messages.push(`Driver cannot travel to ${restrictedCountries.join(', ')}`);
    }

    const weight = vehicleData?.vehicleData || vehicleData?.hooked_to?.cargo_maximum_capacity_weight;
    const weightType = selectedVehicle?.cargo_capacity_measuring_unit
      ? vehicleData?.cargo_capacity_measuring_unit
      : vehicleData?.hooked_to?.cargo_capacity_measuring_unit;
    if (weight) {
      const validWeight = validateWeight(weight, infoHeader.shipment_stops);
      if (!validWeight.isValid) {
        const shipmentWeightType = infoHeader.shipment_stops[0]?.reported_weight_types?.unit;
        messages.push(
          `The shipment weight is ${validWeight?.weight} ${shipmentWeightType} however the equipment you selected can only haul up to ${weight} ${weightType}`
        );
      }
    }

    if (
      infoHeader.shipment_stops?.some((item) => item.hazardous_materials) &&
      vehicleData?.drivers?.every((i) => !i.certifications?.has_hazmat)
    ) {
      messages.push(`This driver does not have an HAZMAT endorsement`);
    }

    if (messages.length) {
      setValidationModalData({
        header: 'Select Vehicle',
        title: `Heads up! ${messages.join(', ')}, do you wish to proceed?`,
      });
      setValidationModalOpen(true);
    } else {
      const queryParams = new URLSearchParams();
      Object.keys(mapValues).forEach((key) => {
        if (mapValues[key]) {
          queryParams.append(key, mapValues[key]);
        }
      });

      navigate(`/planner/dispatch?id=${id}&equipment=${vehicleData?.equipment_id}&${queryParams.toString()}`);
    }
  };

  const continueSelect = (offer) => {
    if (offer) {
      setChecked((prevState) => [...prevState, offer]);
      setValidationModalOpen(false);
    } else {
      navigate(`/planner/dispatch?id=${id}&equipment=${selectedVehicle?.equipment_id}`);
    }
  };

  const handleExpand = (shipment, index) => {
    setExpandedShipment({ ...shipment, index });
  };

  async function onTickCheckboxes(item, value) {
    if (item === 'all' && value) {
      setChecked(offers);
    } else if (item === 'all' && !value) {
      setChecked([]);
    } else {
      const foundedIndex = checked.find((el) => el === item);
      let updatedList = [...checked];
      if (!foundedIndex) {
        const messages = [];
        const arr = [];
        if (item.drivers[0].certifications.can_travel_usa === 1) {
          arr.push(231);
        }
        if (item.drivers[0].certifications.can_travel_canada === 1) {
          arr.push(38);
        }
        if (item.drivers[0].certifications.can_travel_mexico === 1) {
          arr.push(142);
        }

        if (item?.drivers[0]?.status_data?.id === 4) {
          messages.push('Driver is Out of Service');
        }
        if (!item.required_equipment_type) {
          messages.push('Driver is not in proper equipment type for the pick up');
        }

        if (
          !!infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age_flag &&
          infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age <=
            moment().year() - Number(item.year)
        ) {
          messages.push(
            `This customer does not allow to use vehicle older than ${infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_vehicle_age} years`
          );
        }

        if (
          !!infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age_flag &&
          infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age <=
            item?.hooked_to?.year
        ) {
          messages.push(
            `This customer does not allow to use trailer older than ${infoHeader.shipment_billing[0]?.billing_customer?.equipment_restrictions?.equipment_trailer_age} years`
          );
        }

        const pickupDate = infoHeader.shipment_stops.find((item) => +item.stop_point_type === 1)?.scheduled_date;
        const deliveryDate = infoHeader.shipment_stops.find((item) => +item.stop_point_type === 2)?.scheduled_date;

        const absenceData = await getAbsenceData({ driver_id: item.drivers[0].id });

        let isDateInRange = false;
        if (absenceData && absenceData.data && absenceData.data.dates?.length) {
          isDateInRange = checkDateInterval(pickupDate, deliveryDate, absenceData.data.dates);
        }
        if (isDateInRange) {
          messages.push(
            `Driver has an absence scheduled on ${getDatesInRange(absenceData.data.dates, pickupDate, deliveryDate)}`
          );
        }

        let shouldNavigate = true;
        const restrictedCountryIds = [];

        pageData.shipment_stops.forEach((stop) => {
          if (!arr.includes(stop.stop_point.country.id)) {
            shouldNavigate = false;
            restrictedCountryIds.push(stop.stop_point.country.id);
          }
        });

        driverRouteIncludesCountries.forEach((countryId) => {
          if (!arr.includes(countryId)) {
            shouldNavigate = false;
            restrictedCountryIds.push(countryId);
          }
        });

        stopsRouteIncludesCountries.forEach((countryId) => {
          if (!arr.includes(countryId)) {
            shouldNavigate = false;
            restrictedCountryIds.push(countryId);
          }
        });

        if (!shouldNavigate) {
          const restrictedCountries = [...new Set(restrictedCountryIds)].map((item) =>
            item === 231 ? 'USA' : item === 38 ? 'Canada' : item === 142 ? 'Mexico' : ''
          );
          messages.push(`Driver cannot travel to ${restrictedCountries.join(', ')}`);
        }

        const weight = item?.cargo_maximum_capacity_weight || item?.hooked_to?.cargo_maximum_capacity_weight;
        const weightType = item?.cargo_capacity_measuring_unit
          ? item?.cargo_capacity_measuring_unit
          : item?.hooked_to?.cargo_capacity_measuring_unit;
        if (weight) {
          const validWeight = validateWeight(weight, infoHeader.shipment_stops);
          if (!validWeight.isValid) {
            const shipmentWeightType = infoHeader.shipment_stops[0]?.reported_weight_types?.unit;
            messages.push(
              `The shipment weight is ${validWeight?.weight} ${shipmentWeightType} however the equipment you selected can only haul up to ${weight} ${weightType}`
            );
          }
        }

        if (messages.length) {
          setValidationModalData({
            header: 'Offer Vehicle',
            title: `Heads up! ${messages.join(', ')}, do you wish to proceed?`,
            offer: item,
          });
          setValidationModalOpen(true);
        } else {
          updatedList = [...checked, item];
        }
      } else {
        updatedList.splice(checked.indexOf(item), 1);
      }
      setChecked(updatedList);
    }
  }

  function onClickHeaderOverLayOption(item) {
    switch (item?.id) {
      case 1:
        dispatch(updateRowEditItem({ ...pageData, label: 'Shipment' }));
        break;
      case 2:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 11:
      case 12:
      case 13:
        setTableShipmentModalObj({ item, rowData: pageData });
        break;
      case 20:
        navigate(`/planner/plan?id=${pageData.shipment_id}&action=dispatch`);
        break;
      case 21:
        navigate(`/planner/plan?id=${pageData.shipment_id}&action=offer`);
        break;
      case 46:
        navigate(`/shipment/${pageData.shipment_id}`);
        break;
      case 125:
      case 17:
        setTableShipmentModalObj({ item, rowData: [pageData] });
        break;
      case 99:
        setOpenOfferOptions(true);
        break;
      default:
    }
  }

  function onSubmitShipmentRowEditChildes(data) {
    const ids = data.lastData.item.id;
    switch (ids) {
      case 13:
        navigate(`/shipment/${id}`, { state: { defaultTab: 5 } });
        break;
      case 125:
      case 17:
        navigate('/planner');
        break;
      default:
    }
  }

  const updateStopInRoute = ({ row, routeArr }) => {
    const route = [];
    const stops = row?.shipment_stops;
    let indexStop = null;

    if (routeArr) {
      for (let i = 0; i < routeArr.length; i++) {
        const stopPoint = routeArr[i]?.stopPoint;
        if (stopPoint) {
          indexStop === null ? (indexStop = 0) : (indexStop += 1);
          const {
            geofencing_latitude: latitude,
            geofencing_longitude: longitude,
            location_name,
            city,
            state,
            zipcode,
            average_waiting_time,
            stop_point_id,
          } = stops[indexStop]?.stop_point || {};
          route.push({
            latitude,
            longitude,
            stop_point_type: stops[indexStop]?.stop_point_type,
            location_name,
            city,
            state,
            zipcode,
            average_waiting_time,
            stop_point_id,
          });
        } else {
          route.push(routeArr[i]);
        }
      }
    } else {
      for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];
        const {
          geofencing_latitude: latitude,
          geofencing_longitude: longitude,
          location_name,
          city,
          state,
          zipcode,
          average_waiting_time,
          stop_point_id,
        } = stop?.stop_point || {};
        route.push({
          latitude,
          longitude,
          stop_point_type: stop?.stop_point_type,
          location_name,
          city,
          state,
          zipcode,
          average_waiting_time,
          stop_point_id,
        });
      }
    }
    setRoute(route);
  };

  const updateStop = (params, id) => {
    setLoading(true);
    UpdateStopPointStop({ params, id })
      .then((res) => {
        if (res?.data) {
          getHeaderInfo();
          getStopsData();
        }
      })
      .finally(() => setLoading(false));
  };

  const { totalDollar } = useMemo(() => {
    let totalDollar = 0;
    let totalCad = 0;

    infoHeader?.shipment_billing?.forEach((el) => {
      const amount = el?.total_amount || 0; // Safeguard against null/undefined values
      if (Number(el?.currency_id) === 1) {
        totalDollar += amount;
      } else {
        totalCad += amount;
      }
    });

    return { totalDollar, totalCad };
  }, [infoHeader]);

  const onAddEditModalDetails = (values, type, item) => {
    delete values?.id;
    if (type === 'edit') {
      const formData = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (key === 'to' || (val !== '' && !['arrival_time', 'departure_time'].includes(key))) {
          if (key === 'dimensions' && val?.l && val?.h && val?.w) {
            formData.append(`dimensions[l]`, val?.l || '');
            formData.append(`dimensions[h]`, val?.h || '');
            formData.append(`dimensions[w]`, val?.w || '');
          } else if (key === 'arrival_date') {
            if (val) {
              formData.append(
                'arrival_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.arrival_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'departure_date') {
            if (val) {
              formData.append(
                'departure_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.departure_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'bill_type') {
            formData.append('bill_type', values.bill_type ? '1' : '2');
          } else if (key === 'scheduled_date') {
            formData.append('scheduled_date', moment(val).format('YYYY-MM-DD'));
          } else if (key === 'scheduled_date_to') {
            formData.append('scheduled_date_to', moment(val || values?.scheduled_date).format('YYYY-MM-DD'));
          } else if (key === 'from') {
            formData.append('from', moment(values?.from).format('HH:mm'));
          } else if (key === 'to') {
            formData.append('to', moment(val || values?.from).format('HH:mm'));
          } else if (key === 'reported_quantity_type' && !!val) {
            formData.append(
              'reported_quantity_type',
              values?.reported_quantity_type?.id || values?.reported_quantity_type
            );
          } else if (key === 'reported_weight_type' && !!val) {
            formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
          } else if (key === 'connected_cargos' && val?.length) {
            val.forEach((cargo, index) => {
              formData.append(`connected_cargos[${index}]`, cargo.id);
            });
          } else if (key === 'cargos') {
            // Do nothing
          } else {
            let value = val;
            if (typeof val === 'boolean') {
              if (key === 'bill_type') {
                return;
              }
              value = val ? 1 : 0;
            }
            if (key === 'dimensions') {
              return;
            }
            formData.append(key, value);
          }
        }
      });
      updateStop(formData, item?.id);

      if (Number(values.stop_point_type) === 1 && values.cargos?.length === 1) {
        const body = createCargoConverter(values.cargos[0]);
        updateCargo(values.cargos[0].id, body);
      }
    } else {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'id') {
          return;
        }
        let value = values[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        if (key === 'bill_type') {
          formData.append('bill_type', !values.bill_type ? 1 : 2);
        }

        if (key === 'dimensions' && typeof value === 'object') {
          Object.keys(value).forEach((dimensionKey) => {
            formData.append(`dimensions[${dimensionKey}]`, value[dimensionKey] || '');
          });
        } else if (key === 'cargos') {
          const cargos = values.cargos?.length ? values.cargos.filter((i) => !!i?.commodity) : [];
          createCargoFormDataConverter(cargos, formData);
        } else if (key === 'scheduled_date') {
          formData.append('scheduled_date', moment(value).format('YYYY-MM-DD'));
        } else if (key === 'scheduled_date_to') {
          formData.append('scheduled_date_to', moment(value || values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'from') {
          formData.append('from', moment(values?.from).format('HH:mm'));
        } else if (key === 'to') {
          formData.append('to', moment(value || values?.from).format('HH:mm'));
        } else {
          formData.append(key, value);
        }
      });
      formData.append('shipment_id', pageData.shipment_id);
      formData.append('leg', selectModalType.legIndex);
      formData.append('order_in_leg', selectModalType.order_in_leg);
      addShipmentStop(formData)
        .then(() => {
          getStopsData();
          getHeaderInfo();
          getShipmentStops(id).then((res) => {
            setPageData(res.data);
          });
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const checkAddonStatus = (value, checked) => {
    checkAddOnStatus(2, selectedVehicle?.equipment_id)
      .then((res) => {
        if (res.is_addon_active) {
          handleMapValuesChange(value, checked);
        } else {
          setShowLicence(true);
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong!' });
      });
  };

  const handleRouteSelect = (route) => {
    setRouteSelect(route);
    handleMapValuesChange('route_type', route);
  };

  useEffect(() => {
    if (pageData) {
      if (pageData?.shipment_type === 'slave') {
        updateStopInRoute({ row: pageData, routeArr: null });
      } else {
        if (infoHeader?.shipment_route_direction?.route) {
          const route = infoHeader?.shipment_route_direction?.route;
          const routeArr = route ? JSON.parse(route) : null;
          updateStopInRoute({ row: pageData, routeArr });
        }
      }
    }
  }, [infoHeader, pageData]);

  useEffect(() => {
    getStopsData();
  }, []);

  useEffect(() => {
    setKey((action === 'offer' ? 'Offer' : 'Vehicle') || 'Vehicle');
  }, [action]);

  useEffect(() => {
    getCarriers();
  }, [debouncedSearchCarrier, preferredOnly, operatingStates, shipmentForEquipmentType]);

  const getCarriers = async () => {
    try {
      const { data } = await getShipmentOfferSettings(id);
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'filters[query]': debouncedSearchCarrier || undefined,
        'filters[preferred]': preferredOnly ? 1 : undefined,
        'filters[shipment_for_equipment_type]': shipmentForEquipmentType ? id : undefined,
        'filters[shipment_for_operating_states]': operatingStates ? id : undefined,
        'sort[][preferred]': 'desc',
        'filters[status][0]': 2,
        'filters[status][1]': data.offer_pending_carrier ? 1 : undefined,
      };

      const response = await getCarriersList(params);

      setCarriers(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoadingCarriers(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoadingCarriers(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  return (
    <div className='profile-container'>
      <div className='user-profile-header w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='plan-vehicle-container w-100'>
          <div className='plan-vehicle-text-wrapper'>
            <div onClick={onBackPlanner} className='back_plan_to_vehicle'>
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className='back'>
                Planner
              </Typography>
            </div>
          </div>
          {infoHeader?.is_edi_api || infoHeader?.manual_edi_api ? (
            <div className='dispatch-vehicle-text-wrapper'>
              <div className='d-flex'>
                <img src={headerIcon} alt='headerIcon' />
                <p className='dispatch-vehicle-text green-text'>EDI/API SHIPMENT </p>
              </div>
            </div>
          ) : null}

          <div className='d-flex  align-items-center'>
            <div className='plan-vehicle-details w-100'>
              <div className={`plan-vehicle-details-item ${shipmentsInfo.length > 1 ? 'border-0' : ''}`}>
                <h2
                  className='username'
                  style={{
                    color: use(palette.gray900, palette.gray50),
                    whiteSpace: 'nowrap',
                  }}
                >
                  {pageTitle?.[key?.toLowerCase()]}
                </h2>
              </div>
              {shipmentsInfo.length === 1 &&
                (!!pageData && typeof pageData === 'object' && Object.keys(pageData).length > 0 ? (
                  <>
                    <div className='dispatch-vehicle-details-item'>
                      <Typography variant='overLine2' className='d-flex'>
                        <span className='bold-text'>{pageData?.shipment_id}</span>
                        (REF:&nbsp;
                        {(pageData?.shipment_billing && pageData?.shipment_type !== 'ltl') ||
                        (pageData?.shipment_type === 'ltl' && pageData?.shipment_billing.length === 1) ? (
                          <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                            {pageData?.shipment_billing?.[0]?.reference_id}
                          </Typography>
                        ) : (
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip id='button-tooltip-2'>
                                {pageData?.shipment_billing?.map((el) => (
                                  <p key={el?.reference_id} style={{ margin: 0 }}>
                                    {el?.reference_id}
                                  </p>
                                ))}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div ref={ref}>
                                <Typography
                                  variant='s3'
                                  {...triggerHandler}
                                  style={{ color: use(palette.dark800, palette.gray200) }}
                                >
                                  Multi-Ref LTL
                                </Typography>
                              </div>
                            )}
                          </OverlayTrigger>
                        )}
                        )
                      </Typography>
                    </div>
                    <div className='plan-vehicle-details-item'>
                      {pageData?.shipment_billing &&
                      pageData?.shipment_billing.length &&
                      pageData?.shipment_billing.length === 1 ? (
                        <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                          {pageData?.shipment_billing?.[0]?.billing_customer?.company_name}&nbsp;
                          {pageData.shipment_type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
                        </Typography>
                      ) : pageData?.shipment_billing && pageData?.shipment_billing.length > 1 ? (
                        <OverLayTriggerHoverWrapper
                          overlay={pageData?.shipment_billing.map((el, i) => (
                            <Typography
                              key={el?.id || i}
                              as='p'
                              variant='s2'
                              style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/customer-profile/${el?.billing_customer?.id}`);
                              }}
                            >
                              {el?.billing_customer?.company_name}
                            </Typography>
                          ))}
                        >
                          <div>
                            <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                              Multi-Customer LTL
                            </Typography>
                          </div>
                        </OverLayTriggerHoverWrapper>
                      ) : null}
                    </div>
                    {pageData?.shipment_billing[0]?.groups && (
                      <div className='plan-vehicle-details-item'>
                        <div
                          className='tablePlaner-shortName }'
                          style={{
                            color: use(palette.gray900, palette.gray700),
                            backgroundColor: pageData?.shipment_billing[0]?.groups?.color,
                          }}
                        >
                          {pageData?.shipment_billing[0]?.groups?.short_name}
                        </div>
                      </div>
                    )}
                    <div className='plan-vehicle-details-item'>
                      <p className='plan-details-text'>{pageData?.shipment_stops_count} Stops</p>
                    </div>
                    <div className='plan-vehicle-details-item'>
                      <div className='d-flex justify-content-start}'>
                        <div className='tablePlaner_status-wrapper'>
                          <span
                            className='tablePlaner_status'
                            style={{
                              backgroundColor: getPlannerStatus(infoHeader)?.bgStatus,
                              color: getPlannerStatus(infoHeader)?.colorStatus,
                            }}
                          >
                            {getPlannerStatus(infoHeader)?.statusName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='plan-vehicle-details-item'>
                      <p className='plan-details-text'>
                        {currency} {formatAmount(totalDollar)}
                      </p>
                    </div>
                    <div className='plan-vehicle-details-item'>
                      <p className='plan-details-text'>{pageData?.loaded_miles?.toFixed()} Miles</p>
                    </div>
                    <div className='plan-vehicle-details-item'>
                      <p className='plan-details-text'>{getShipmentType(pageData.shipment_type)}</p>
                    </div>
                  </>
                ) : (
                  <div className='plan-vehicle-details-skeleton'>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ))}
            </div>
            {shipmentsInfo.length === 1 &&
              (!!pageData && typeof pageData === 'object' && Object.keys(pageData).length > 0 ? (
                <TableRowsEdit
                  placement='bottom-end'
                  overlayStyle={{ width: 200 }}
                  options={
                    infoHeader.canceled_at
                      ? infoHeader.shipment_type === 'ltl' ||
                        infoHeader.shipment_type === 'slave' ||
                        infoHeader.shipment_type === 'split-parent'
                        ? PLAN_TO_VEHICLE_CANCELED.filter((el) => +el.id !== 8)
                        : PLAN_TO_VEHICLE_CANCELED
                      : infoHeader.shipment_type === 'ltl' ||
                        infoHeader.shipment_type === 'slave' ||
                        infoHeader.shipment_type === 'split-parent'
                      ? (action === 'offer' ? OFFER_TO_VEHICLE : PLAN_TO_VEHICLE).filter((el) => +el.id !== 8)
                      : action === 'offer'
                      ? OFFER_TO_VEHICLE
                      : PLAN_TO_VEHICLE
                  }
                  onClickOption={(e) => onClickHeaderOverLayOption(e)}
                  targetStyle={{ width: 20, height: 20 }}
                />
              ) : (
                <div className='plan-vehicle-details-skeleton'>
                  <Skeleton />
                </div>
              ))}
          </div>
        </div>
      </div>
      {showLicence && (
        <div className='check-addon-dispatch' onClick={() => setShowLicence(false)}>
          You do not have this feature enabled.
          <span onClick={() => navigate('/billing?addonId=2&planId=34')}> Purchase Addon </span>
        </div>
      )}
      {shipmentsInfo.length === 1 && (
        <div className='plan-accordion'>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowUp className='accordion-arrow' />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            />
            <AccordionDetails>
              <div className='w-100 mt-4'>
                <div>
                  {loading ? (
                    <SkeletonStops />
                  ) : (
                    <>
                      {legs.map((leg, index) => {
                        legsStopsCount.push(leg.stops.length);
                        return (
                          <div key={leg.id || index} className={classes.legCountContainer}>
                            <OverViewStop
                              leg={leg}
                              legs={legs}
                              setLegs={setLegs}
                              indexPrefix={index}
                              setIsOpen={setIsOpen}
                              infoHeader={infoHeader}
                              legsStopsCount={legsStopsCount}
                              onSelectPlus={setSelectModalType}
                              setIsOpenModal={() => null}
                              deleteStop={onDeleteStop}
                              onClickSettingsIcon={onClickItemSettingsIcon}
                              onUpdateSuccess={onUpdateSuccess}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      {shipmentsInfo.length > 1 && (
        <SWrapper>
          {!!shipmentsInfo.length &&
            shipmentsInfo.map((shipment, index) => (
              <SAccordion
                key={shipment.id}
                expanded={expandedShipment?.shipment_id === shipment.shipment_id}
                onChange={() => handleExpand(shipment, index)}
              >
                <AccordionSummary
                  expandIcon={<ArrowUp width={16} className='accordion-arrow' />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  Shipment {shipment.shipment_id}
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <ShipmentInfo shipment={shipment} />
                  </div>
                </AccordionDetails>
              </SAccordion>
            ))}
        </SWrapper>
      )}
      {(key === 'Vehicle' || key === 'Offer') && (
        <div className='d-flex align-items-end justify-content-between'>
          <div className='d-flex align-items-end'>
            <div style={{ marginRight: 20 }}>
              <label className='plan-input-label'>SEARCH VEHICLE</label>
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                type='text'
                className='plan-input'
                placeholder='Search'
              />
            </div>
            <div style={{ marginRight: 20 }}>
              <label className='plan-input-label'>RADIUS MILES</label>
              <input
                type='text'
                className='plan-input'
                placeholder='0'
                value={radius}
                onChange={(e) => setRadius(e.target.value)}
                style={{ minWidth: 100 }}
              />
            </div>
            <div className='me-4'>
              <CustomCheckbox checked={showEquipmentType} onChange={setShowEquipmentType}>
                <Typography variant='s2' style={{ marginLeft: 10 }}>
                  Show Required Equipment Type
                </Typography>
              </CustomCheckbox>
            </div>
            <div>
              <CustomCheckbox checked={showPreferredTravelArea} onChange={setShowPreferredTravelArea}>
                <Typography variant='s2' style={{ marginLeft: 10 }}>
                  Show Preferred Driver Travel Area
                </Typography>
              </CustomCheckbox>
            </div>
          </div>

          {key === 'Offer' ? (
            <div className='d-flex align-items-end justify-content-end'>
              <CustomButton
                type='primary'
                title='Offer'
                leftIcon={<OfferIcon fill={palette.white} style={{ marginRight: 10 }} />}
                styleButton={{ padding: '6px 12px' }}
                disabled={!checked?.length}
                onClick={offer}
              />
            </div>
          ) : null}
          {key === 'Vehicle' ? (
            <div className='d-flex align-items-end justify-content-end'>
              <CustomButton
                width={12}
                height={12}
                type='primary'
                title='Select Vehicle'
                leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
                styleButton={{ padding: '6px 12px' }}
                onClick={() => selectVehicle()}
                disabled={!selectedVehicle}
              />
            </div>
          ) : null}
        </div>
      )}

      {key === 'External' && (
        <div className='d-flex align-items-end justify-content-between'>
          <div className='d-flex align-items-end'>
            <div style={{ marginRight: 20 }}>
              <label className='plan-input-label'>SEARCH CARRIER</label>
              <input
                value={searchCarrier}
                onChange={(e) => setSearchCarrier(e.target.value)}
                type='text'
                className='plan-input'
                placeholder='Search'
              />
            </div>
            <div style={{ marginRight: 20 }}>
              <CustomCheckbox checked={preferredOnly} onChange={setPreferredOnly}>
                <Typography variant='s2' style={{ marginLeft: 10 }}>
                  Preferred Only
                </Typography>
              </CustomCheckbox>
            </div>
            <div style={{ marginRight: 20 }}>
              <CustomCheckbox checked={shipmentForEquipmentType} onChange={setShipmentForEquipmentType}>
                <Typography variant='s2' style={{ marginLeft: 10 }}>
                  Show Required Equipment Type
                </Typography>
              </CustomCheckbox>
            </div>
            <div style={{ marginRight: 20 }}>
              <CustomCheckbox checked={operatingStates} onChange={setOperatingStates}>
                <Typography variant='s2' style={{ marginLeft: 10 }}>
                  Show Required Operating State Type
                </Typography>
              </CustomCheckbox>
            </div>
          </div>
        </div>
      )}

      <div className='w-100 mt-4 d-flex'>
        <div className='plan-tabs' style={{ minWidth: '530px' }}>
          <div className='tab-content' id='myTabContent'>
            <Tabs defaultActiveKey='home' id='plan-to-vehicle-tabs' activeKey={key} onSelect={(k) => setKey(k)}>
              {(!hasBrokerage || action !== 'offer') && (
                <Tab
                  eventKey='Vehicle'
                  title={
                    <p className='d-flex'>
                      <span className='tabs-text'> {hasBrokerage ? 'Drivers' : 'Dispatch'} </span>
                      <span className='tabs-values'>{vehicles?.length}</span>
                    </p>
                  }
                  className='tab-container position-relative ms-3 fixed-tab'
                >
                  {loadingVehicles && (
                    <div>
                      {Array.from({ length: 7 }).map((_, index) => (
                        <VehicleSkeleton key={_ || index} />
                      ))}
                    </div>
                  )}

                  {(vehicles || []).map((vehicle) => (
                    <div
                      key={vehicle?.equipment_id}
                      className={`plan-vehicle-tab-wrapper ${
                        selectedVehicle?.equipment_id === vehicle.equipment_id ? 'selected-vehicle' : ''
                      }`}
                      onClick={(e) => {
                        setSelectedVehicle(vehicle);
                        if (e.detail === 2) {
                          selectVehicle(vehicle);
                        }
                      }}
                    >
                      <p className='plan-vehicle-yellow-text'>
                        {vehicle?.empty_miles?.toFixed()} MILES AWAY ({vehicle.distance_time})
                      </p>

                      <div className='d-flex align-items-center'>
                        <p className='plan-vehicle-text'>
                          {vehicle.equipment_number}{' '}
                          <span className='plan-vehicle-gray-text'>
                            {vehicle.equipment_type?.title}{' '}
                            {!!vehicle?.truck_type && <span>({TRUCK_TYPES[vehicle.truck_type]})</span>}
                          </span>
                        </p>
                        <div className={classes.flexAlign}>
                          {vehicle.location_target === 'Equipment' &&
                          vehicle.equipment_connected_services_device?.fuel_percent ? (
                            <Progress
                              icon={<FuelIcon />}
                              percent={vehicle.equipment_connected_services_device?.fuel_percent}
                              colorPercent={palette.green200}
                            />
                          ) : null}
                          {vehicle.location_target === 'Driver' && vehicle.drivers[0]?.driver_gps?.fuel_percent ? (
                            <Progress
                              icon={<FuelIcon />}
                              percent={vehicle.drivers[0]?.driver_gps?.fuel_percent}
                              colorPercent={palette.green200}
                            />
                          ) : null}

                          {vehicle.location_target === 'Equipment' &&
                            vehicle.equipment_connected_services_device?.engine_state === 'Off' && (
                              <EnginOfIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.equipment_connected_services_device?.engine_state === 'On' && (
                              <EnginOnIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.equipment_connected_services_device?.engine_state === 'Idle' && (
                              <IdleIcon className={classes.iconNoHover} />
                            )}

                          {vehicle.location_target === 'Driver' &&
                            vehicle.drivers[0]?.driver_connected_services_hos?.engine_state === 'Off' && (
                              <EnginOfIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.drivers[0]?.driver_connected_services_hos?.engine_state === 'On' && (
                              <EnginOnIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.drivers[0]?.driver_connected_services_hos?.engine_state === 'Idle' && (
                              <IdleIcon className={classes.iconNoHover} />
                            )}

                          {vehicle.location_target === 'Equipment' && <SignalIcon className={classes.iconNoHover} />}

                          {vehicle?.location_target === 'Equipment' && (
                            <div
                              className={
                                Number(vehicle?.equipment_connected_services_device?.speed_mph) <= 0.5
                                  ? 'header-gray-wrapper'
                                  : 'header-green-wrapper'
                              }
                            >
                              <Typography variant='overLine'>
                                {Number(vehicle?.equipment_connected_services_device?.speed_mph) <= 0.5
                                  ? 0
                                  : Number(vehicle?.equipment_connected_services_device?.speed_mph).toFixed()}{' '}
                                MPH
                              </Typography>
                            </div>
                          )}
                          {vehicle?.location_target === 'Driver' && (
                            <div
                              className={
                                Number(vehicle?.drivers[0]?.driver_gps?.mph) <= 0.5
                                  ? 'header-gray-wrapper'
                                  : 'header-green-wrapper'
                              }
                            >
                              <Typography variant='overLine'>
                                {Number(vehicle?.drivers[0]?.driver_gps?.mph).toFixed(1) <= 0.5
                                  ? 0
                                  : Number(vehicle?.drivers[0]?.driver_gps?.mph).toFixed()}{' '}
                                MPH
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <div style={{ marginRight: 10 }}>
                          <img src={subRight} alt='right' />
                        </div>
                        {vehicle.hooked_to ? (
                          <p className='plan-vehicle-text'>
                            {vehicle.hooked_to?.equipment_number}{' '}
                            <span className='plan-vehicle-gray-text'>
                              {vehicle?.hooked_to?.length?.length}-{vehicle.hooked_to?.equipment_type?.title}
                            </span>
                          </p>
                        ) : (
                          <p className='plan-vehicle-text-small'>Not hooked to a trailer</p>
                        )}
                      </div>
                      <div className='d-flex flex-column gap-2'>
                        {vehicle.drivers.map((d) => {
                          return (
                            <>
                              <div className='d-flex gap-2 align-items-center'>
                                <UserInfo
                                  key={d.id}
                                  size='26px'
                                  statusSize='6px'
                                  data={{
                                    id: d.id,
                                    image: d.image,
                                    name: `${d.fname} ${d.lname}`,
                                    email: d.email,
                                    phone_number: d.phone_number,
                                    status: d.status_data?.driver_status,
                                    user_type: 'driver',
                                  }}
                                />
                                <div className='country-flag-icons'>
                                  {d?.certifications?.can_travel_usa === 1 ? (
                                    <img src={useFlag} alt='USA-Flag' />
                                  ) : null}
                                  {d?.certifications?.can_travel_mexico === 1 ? (
                                    <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />
                                  ) : null}
                                  {d?.certifications?.can_travel_canada === 1 ? (
                                    <img src={turkeyFlag} alt='canada-Flag' />
                                  ) : null}
                                  {d?.certifications?.has_tsa === 1 ? (
                                    <img src={tsa} alt='tsa' className='approvedFor-tsa-icon' />
                                  ) : null}
                                  {d?.certifications?.has_twic === 1 ? (
                                    <img src={twic} alt='twic' className='approvedFor-twic-icon' />
                                  ) : null}
                                  {d?.certifications?.has_fast === 1 ? (
                                    <img src={fast} alt='fast' className='approvedFor-fast-icon' />
                                  ) : null}
                                  {!!d.certifications?.has_hazmat && (
                                    <Typography variant='c1' style={{ color: palette.orange400 }}>
                                      HAZMAT
                                    </Typography>
                                  )}
                                </div>
                              </div>
                              {!!d.driver_connected_services_hos && (
                                <div style={{ width: 250 }}>
                                  <Hos driver={d} />
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                      <div className='d-flex align-items-center'>
                        <div style={{ marginRight: 10 }}>
                          <img src={location} alt='location' />
                        </div>
                        <p className='plan-vehicle-text-small' style={{ marginRight: 10 }}>
                          {vehicle?.location_target === 'Equipment'
                            ? vehicle?.equipment_gps?.location
                            : vehicle?.drivers[0]?.driver_gps?.location}
                        </p>
                      </div>
                    </div>
                  ))}
                  {vehicles && !vehicles.length && !loadingVehicles && (
                    <div className='d-flex justify-content-center mt-5' style={{ background: '#fff' }}>
                      <Typography style={{ color: use(palette.gray900, palette.gray50) }}>
                        No vehicles found in radius provided. Please increase radius to select vehicle.
                      </Typography>
                    </div>
                  )}
                </Tab>
              )}
              {(!hasBrokerage || action === 'offer') && (
                <Tab
                  eventKey='Offer'
                  title={
                    <p className='d-flex ms-2'>
                      <span className='connected-services-tab-text'>{hasBrokerage ? 'Drivers' : 'Offer'}</span>
                      <span className='tabs-values'>{offers?.length}</span>
                    </p>
                  }
                  className='tab-container position-relative ms-3 fixed-tab'
                >
                  {offers && !offers.length && !loadingVehicles && (
                    <div className='d-flex justify-content-center mt-5 ' style={{ background: '#fff' }}>
                      <Typography style={{ color: use(palette.gray900, palette.gray50) }}>
                        No vehicles found in radius provided. Please increase radius to offer vehicle.
                      </Typography>
                    </div>
                  )}

                  {loadingVehicles && (
                    <div>
                      {Array.from({ length: 7 }).map((_, index) => (
                        <VehicleSkeleton key={_ || index} />
                      ))}
                    </div>
                  )}

                  {!!offers?.length && (
                    <div className='plan-vehicle-tab-wrapper d-flex align-items-center'>
                      <CustomCheckbox
                        checked={offers.length === checked.length}
                        onChange={(checked) => onTickCheckboxes('all', checked)}
                      />
                      <p className='plan-vehicle-yellow-text ms-2'>Select All</p>
                    </div>
                  )}

                  {(offers || []).map((vehicle) => (
                    <div
                      key={vehicle?.equipment_id}
                      className={`plan-vehicle-tab-wrapper ${checked.includes(vehicle) ? 'selected-vehicle' : ''}`}
                    >
                      <div className='d-flex align-items-center'>
                        <CustomCheckbox
                          checked={checked.includes(vehicle)}
                          onChange={() => onTickCheckboxes(vehicle)}
                        />
                        <p className='plan-vehicle-yellow-text ms-2'>
                          {vehicle?.empty_miles?.toFixed()} MILES AWAY ({vehicle.distance_time})
                        </p>
                      </div>

                      <div className='d-flex align-items-center'>
                        <p className='plan-vehicle-text'>
                          {vehicle.equipment_number}{' '}
                          <span className='plan-vehicle-gray-text'>
                            {vehicle.equipment_type?.title}{' '}
                            {!!vehicle?.truck_type && <span>({TRUCK_TYPES[vehicle.truck_type]})</span>}
                          </span>
                        </p>
                        <div className={classes.flexAlign}>
                          {vehicle.location_target === 'Equipment' &&
                          vehicle.equipment_connected_services_device?.fuel_percent ? (
                            <Progress
                              icon={<FuelIcon />}
                              percent={vehicle.equipment_connected_services_device?.fuel_percent}
                              colorPercent={palette.green200}
                            />
                          ) : null}
                          {vehicle.location_target === 'Driver' && vehicle.drivers[0]?.driver_gps?.fuel_percent ? (
                            <Progress
                              icon={<FuelIcon />}
                              percent={vehicle.drivers[0]?.driver_gps?.fuel_percent}
                              colorPercent={palette.green200}
                            />
                          ) : null}

                          {vehicle.location_target === 'Equipment' &&
                            vehicle.equipment_connected_services_device?.engine_state === 'Off' && (
                              <EnginOfIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.equipment_connected_services_device?.engine_state === 'On' && (
                              <EnginOnIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.equipment_connected_services_device?.engine_state === 'Idle' && (
                              <IdleIcon className={classes.iconNoHover} />
                            )}

                          {vehicle.location_target === 'Driver' &&
                            vehicle.drivers[0]?.driver_connected_services_hos?.engine_state === 'Off' && (
                              <EnginOfIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.drivers[0]?.driver_connected_services_hos?.engine_state === 'On' && (
                              <EnginOnIcon className={classes.iconNoHover} />
                            )}
                          {vehicle.location_target === 'Equipment' &&
                            vehicle.drivers[0]?.driver_connected_services_hos?.engine_state === 'Idle' && (
                              <IdleIcon className={classes.iconNoHover} />
                            )}

                          {vehicle.location_target === 'Equipment' && <SignalIcon className={classes.iconNoHover} />}

                          {vehicle?.location_target === 'Equipment' && (
                            <div
                              className={
                                Number(vehicle?.equipment_connected_services_device?.speed_mph) <= 0.5
                                  ? 'header-gray-wrapper'
                                  : 'header-green-wrapper'
                              }
                            >
                              <Typography variant='overLine'>
                                {Number(vehicle?.equipment_connected_services_device?.speed_mph) <= 0.5
                                  ? 0
                                  : Number(vehicle?.equipment_connected_services_device?.speed_mph).toFixed()}{' '}
                                MPH
                              </Typography>
                            </div>
                          )}
                          {vehicle?.location_target === 'Driver' && (
                            <div
                              className={
                                Number(vehicle?.drivers[0]?.driver_gps?.mph) <= 0.5
                                  ? 'header-gray-wrapper'
                                  : 'header-green-wrapper'
                              }
                            >
                              <Typography variant='overLine'>
                                {Number(vehicle?.drivers[0]?.driver_gps?.mph).toFixed(1) <= 0.5
                                  ? 0
                                  : Number(vehicle?.drivers[0]?.driver_gps?.mph).toFixed()}{' '}
                                MPH
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <div style={{ marginRight: 10 }}>
                          <img src={subRight} alt='right' />
                        </div>
                        {vehicle.hooked_to ? (
                          <p className='plan-vehicle-text'>
                            {vehicle.hooked_to?.equipment_number}{' '}
                            <span className='plan-vehicle-gray-text'>
                              {vehicle?.hooked_to?.length?.length}-{vehicle.hooked_to?.equipment_type?.title}
                            </span>
                          </p>
                        ) : (
                          <p className='plan-vehicle-text-small'>Not hooked to a trailer</p>
                        )}
                      </div>

                      <div className='d-flex flex-column gap-2'>
                        {vehicle.drivers.map((d) => {
                          return (
                            <>
                              <div className='d-flex gap-2 align-items-center'>
                                <UserInfo
                                  key={d.id}
                                  size='26px'
                                  statusSize='6px'
                                  data={{
                                    id: d.id,
                                    image: d.image,
                                    name: `${d.fname} ${d.lname}`,
                                    email: d.email,
                                    phone_number: d.phone_number,
                                    status: d.status_data?.driver_status,
                                    user_type: 'driver',
                                  }}
                                />
                                <div className='country-flag-icons'>
                                  {d?.certifications?.can_travel_usa === 1 ? (
                                    <img src={useFlag} alt='USA-Flag' />
                                  ) : null}
                                  {d?.certifications?.can_travel_mexico === 1 ? (
                                    <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />
                                  ) : null}
                                  {d?.certifications?.can_travel_canada === 1 ? (
                                    <img src={turkeyFlag} alt='canada-Flag' />
                                  ) : null}
                                  {d?.certifications?.has_tsa === 1 ? (
                                    <img src={tsa} alt='tsa' className='approvedFor-tsa-icon' />
                                  ) : null}
                                  {d?.certifications?.has_twic === 1 ? (
                                    <img src={twic} alt='twic' className='approvedFor-twic-icon' />
                                  ) : null}
                                  {d?.certifications?.has_fast === 1 ? (
                                    <img src={fast} alt='fast' className='approvedFor-fast-icon' />
                                  ) : null}
                                </div>
                              </div>

                              {!!d.driver_connected_services_hos && (
                                <div style={{ width: 250 }}>
                                  <Hos driver={d} />
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                      <div className='d-flex align-items-center'>
                        <div style={{ marginRight: 10 }}>
                          <img src={location} alt='location' />
                        </div>
                        <p className='plan-vehicle-text-small' style={{ marginRight: 10 }}>
                          {vehicle?.location_target === 'Equipment'
                            ? vehicle?.equipment_gps?.location
                            : vehicle?.drivers[0]?.driver_gps?.location}
                        </p>
                      </div>
                    </div>
                  ))}
                </Tab>
              )}
              {!!hasBrokerage && (
                <Tab
                  style={{ background: 'none' }}
                  eventKey='Carriers'
                  title={
                    <p className='d-flex ms-2'>
                      <span className='connected-services-tab-text'>In-Network Carriers</span>
                      <span className='tabs-values'>0</span>
                    </p>
                  }
                >
                  <div className='d-flex justify-content-center mt-5'>
                    <Typography style={{ color: use(palette.gray900, palette.gray50) }}>
                      This feature is not available for your company at this time.
                    </Typography>
                  </div>
                </Tab>
              )}
              {!!hasBrokerage && (
                <Tab
                  style={{ background: 'none' }}
                  eventKey='External'
                  title={
                    <p className='d-flex ms-2'>
                      <span className='connected-services-tab-text'>External Network Carriers</span>
                      <span className='tabs-values'>{carriers?.total}</span>
                    </p>
                  }
                />
              )}
            </Tabs>
          </div>
        </div>
        <div className='plan-map'>
          {pageData && (key === 'Vehicle' || key === 'Offer') && (
            <div className='d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                <p className='dispatch-vehicle-check-text' style={{ marginLeft: 0, textTransform: 'capitalize' }}>
                  {routeSelect}
                </p>
                <Dropdown className='d-inline mx-2'>
                  <Dropdown.Toggle
                    // className="AvailableButton btn btn-link"
                    id='dropdown-autoclose-outside'
                    style={{
                      border: 'none',
                      outline: 'none',
                      background: 'none',
                      boxShadow: 'none',
                      marginLeft: '0',
                      paddingLeft: '10x',
                    }}
                  >
                    <img src={down} alt='down' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => handleRouteSelect('practical')}
                    >
                      Practical
                    </Dropdown.Item>

                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={() => handleRouteSelect('shortest')}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Shortest
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={() => handleRouteSelect('fastest')}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Fastest
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className='d-flex align-items-center mb-1'>
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem', marginLeft: 0 }}>
                  Open Borders
                </p>
                <CustomCheckbox
                  checked={!!mapValues.open_borders}
                  onChange={(checked) => handleMapValuesChange('open_borders', checked)}
                />
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem' }}>
                  Highway Only
                </p>
                <CustomCheckbox
                  checked={!!mapValues.hwy_only}
                  onChange={(checked) => handleMapValuesChange('hwy_only', checked)}
                />
                <p className='dispatch-vehicle-check-text'>HazMat</p>
                <CustomCheckbox
                  checked={!!mapValues.haz_mat}
                  disabled
                  onChange={(checked) => handleMapValuesChange('haz_mat', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                <CustomCheckbox
                  checked={!!mapValues.avoid_tolls}
                  onChange={(checked) => handleMapValuesChange('avoid_tolls', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Traffic</p>
                <CustomCheckbox
                  checked={!!mapValues.show_traffic}
                  onChange={(checked) => checkAddonStatus('show_traffic', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Weather</p>
                <CustomCheckbox
                  checked={!!mapValues.show_weather}
                  onChange={(checked) => checkAddonStatus('show_weather', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
              </div>
            </div>
          )}
          <div>
            {pageData && key === 'Vehicle' && (
              <PlanMap
                data={pageData.shipment_stops}
                stopPointArr={route.length === 0 ? false : route}
                selectedVehicle={selectedVehicle}
                infoHeader={infoHeader}
                shipmentsInfo={shipmentsInfo}
                mapValues={mapValues}
                setDriverRouteIncludesCountries={setDriverRouteIncludesCountries}
                setStopsRouteIncludesCountries={setStopsRouteIncludesCountries}
              />
            )}
            {pageData && key === 'Offer' && (
              <OfferMap
                data={pageData.shipment_stops}
                selectedDrivers={checked}
                infoHeader={infoHeader}
                mapValues={mapValues}
                setDriverRouteIncludesCountries={setDriverRouteIncludesCountries}
                setStopsRouteIncludesCountries={setStopsRouteIncludesCountries}
              />
            )}
          </div>
        </div>
      </div>
      {key === 'External' && action !== 'offer' && (
        <div className='w-100'>
          {loadingCarriers ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              data={carriers?.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: carriers,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={columns}
              onRowClick={(e, rowData) => {
                navigate(`/planner/carrier-dispatch?id=${id}&carrier=${rowData.id}`);
              }}
            />
          )}
        </div>
      )}
      {key === 'External' && action === 'offer' && (
        <OfferCarrier
          shipment={infoHeader}
          search={debouncedSearchCarrier}
          preferredOnly={preferredOnly}
          operatingStates={operatingStates}
          shipmentForEquipmentType={shipmentForEquipmentType}
          openOfferOptions={() => setOpenOfferOptions(true)}
          refreshOfferOptions={refreshOfferOptions}
        />
      )}
      <SettingsModal isOpen={isOpenSettingsModal} setIsOpen={setIsOpenSettingsModal} onUpdate={onUpdateSettings} />
      <TablePlannerRowEditChildes data={tableShipmentModalObj} onSubmit={onSubmitShipmentRowEditChildes} />
      {!!isOpen?.open && (
        <AddEditModalDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={selectModalType}
          setType={setSelectModalType}
          isShipmentTable={false}
          onAdd={onAddEditModalDetails}
          onUpdateSuccess={getStopsData}
          shipmentId={id}
          legs={legs}
        />
      )}
      <ValidationlModal
        isOpen={validationModalOpen}
        setIsOpen={setValidationModalOpen}
        onAddBtn={continueSelect}
        data={validationModalData}
      />
      {showMoreOpen && (
        <EquipmentTypes
          open={showMoreOpen}
          moreModalType={moreModalType}
          onClose={() => setShowMoreOpen(null)}
          equipmentTypes={showMoreOpen}
        />
      )}

      <AlreadyDispatchedModal
        open={!!alreadyDispatched}
        text={alreadyDispatched}
        onClose={() => setAlreadyDispatched(null)}
      />
      {openOfferOptions && (
        <OfferOptions
          open={openOfferOptions}
          onClose={() => setOpenOfferOptions(false)}
          shipment={infoHeader}
          offerTo={key === 'External' ? 'carrier' : 'vehicle'}
          onSuccess={offer}
        />
      )}
    </div>
  );
}
