import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import add from 'assets/icons/drivers/add.png';
import cross from 'assets/icons/drivers/cross.svg';
import sorting from 'assets/icons/drivers/sorting.svg';
import setting, { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import Autocomplete from 'common/Autocomplete';
import AddBalance from 'componentsV2/AddBalance';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import PaymentOptions from 'components/Staff/AddStaff/PaymentOptions';
import RecurringModal from 'components/ReccuringTypeModal/ReccuringTypeModal';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars, formatAmount } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import {
  AddCompensation,
  UpdateCompensation,
  getStaffCompanyPolicy,
  getStaffTaxType,
  uploadDriverDocument,
  getSalesCommissions,
  deleteStaffBalance,
  createSalesCommission,
  staffCompansationData,
} from 'Api/Staff';
import './AddStaffStyle.css';
import PaymentMethod from 'components/Staff/AddStaff/PaymentMethod';
import { saveFinishLater } from 'Api/StaffV2';
import { getAccountsList } from 'Api/Accounts';
import moment from 'moment/moment';
import TaxesSettings from 'pages/Payroll/Settlement/Tables/Taxes/components/TaxesSettings';
import BenefitsSettings from 'pages/Payroll/Settlement/Tables/Benefits/components/BenefitsSettings';
import AdditionsSettings from 'pages/Payroll/Settlement/Tables/Additions/components/AdditionsSettings';
import DeductionsSettings from 'pages/Payroll/Settlement/Tables/Deduction/components/DeductionsSettings';
import { CompensationValidationSchema } from '../ValidationSchemaStaff';
import { SInput } from './AddStaff.styles';

const weekData = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const monthData = ['Month', 'Week'];
const month = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
  '29th',
  '30th',
];

const StaffCompensation = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  compensation = {},
  stepsDone,
  setStepsDone,
  staffId,
  isDriverProfile = false,
  draftDriver,
}) => {
  const { use } = useTheme();
  const location = useLocation();
  const [onNextloading, setOnNextloading] = useState(false);
  const [taxOption, setTaxOption] = useState([]);
  const [companyPolicy, setCompanyPolicy] = useState([]);

  const {
    tax_id,
    tax_type_id,
    account_id,
    bank_name,
    payment_method,
    third_party_name,
    third_party_id,
    routing,
    routing_confirmed,
    account,
    account_confirmed,
    deposit_form_path,
    compensation_option,
    per_hr,
    per_hr_amt,
    each_booked_shipment,
    flat_or_per,
    percentage,
    flat_amt,
    sales_commission_option,
  } = compensation || {};

  const formik = useFormik({
    initialValues: {
      staff_id: staffId || '',
      tax_id: tax_id || '',
      tax_type_id: tax_type_id || '',
      account_id: account_id || 29,
      bank_name: bank_name || '',
      payment_method: payment_method || 'check',
      third_party_name: third_party_name || '',
      third_party_id: third_party_id || '',
      routing: routing || '',
      routing_confirmed: routing_confirmed || '',
      account: account || '',
      account_confirmed: account_confirmed || '',
      deposit_form_path: deposit_form_path || '',
      compensation_option: compensation_option || '1',
      per_hr: per_hr || false,
      per_hr_amt: per_hr_amt || '',
      each_booked_shipment: each_booked_shipment || false,
      flat_or_per: flat_or_per || null,
      percentage: percentage || '',
      flat_amt: flat_amt || '',
      sales_commission_option: !!sales_commission_option || false,
      commissions: [
        {
          id: Math.random(),
          customer: null,
          type: 1,
          flat_per_shipment: '',
          percentage_per_shipment: '',
          percentage_after_driver_pay: '',
          is_active: false,
          end_date: null,
        },
      ],
    },
    validationSchema: CompensationValidationSchema,
  });

  const updateCommissions = async () => {
    try {
      const body = {
        customer_id: [],
        type: [],
        flat_per_shipment: [],
        percentage_per_shipment: [],
        percentage_after_driver_pay: [],
        end_date: [],
        is_active: [],
      };
      formik.values.commissions.forEach((item) => {
        body.customer_id.push(item.customer.id);
        body.type.push(Number(item.type));
        body.flat_per_shipment.push(item.type === 1 ? Number(item.flat_per_shipment) : 0);
        body.percentage_per_shipment.push(item.type === 2 ? Number(item.percentage_per_shipment) : 0);
        body.percentage_after_driver_pay.push(item.type === 3 ? Number(item.percentage_after_driver_pay) : 0);
        body.end_date.push(moment(item.end_date || new Date()).format('YYYY-MM-DD'));
        body.is_active.push(item.is_active);
      });

      return createSalesCommission(staffId, body);
    } catch (e) {
      // Do nothing
    }
  };

  const GetCompensation = async (id) => {
    const { data } = await staffCompansationData({ id });
    if (data?.staff_compensation) {
      const { staff_compensation: compensation } = data || {};
      formik.setFieldValue('account_id', compensation?.account_id || 29);
      formik.setFieldValue('tax_id', compensation?.tax_id || '');
      formik.setFieldValue('tax_type_id', compensation?.tax_type?.id || '');
    }
  };

  const getCommissions = async () => {
    try {
      const { data } = await getSalesCommissions(staffId);
      if (data.length) {
        formik.setFieldValue(
          'commissions',
          data.map((item) => ({
            id: item.id,
            customer: item.customer || null,
            type: item.type,
            flat_per_shipment: item.type === 1 ? Number(item.flat_per_shipment).toString() || '' : '',
            percentage_per_shipment: item.type === 2 ? Number(item.percentage_per_shipment).toString() || '' : '',
            percentage_after_driver_pay:
              item.type === 3 ? Number(item.percentage_after_driver_pay).toString() || '' : '',
            is_active: !!item.is_active,
            end_date: !item.is_active ? moment(item.end_date || new Date()).toDate() : null,
          }))
        );
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onPrevStep = () => {
    setStepsDone({
      ...stepsDone,
      isBasicDetailsSaved: true,
    });
    prevStep();
  };

  const onNextStep = () => {
    setOnNextloading(true);

    const staffInfo = {
      ...formik.values,
      each_booked_shipment: formik.values?.each_booked_shipment ? 1 : 0,
      tax_type_id: +formik.values.tax_type_id,
      routing_confirmed: String(formik.values.routing_confirmed),
      account: String(formik.values.account),
      account_confirmed: String(formik.values.account_confirmed),
      per_hr_amt: String(formik.values.per_hr_amt),
      staff_id: String(formik.values.staff_id),
      per_hr_option: formik.values?.per_hr ? 1 : 0,
    };
    if (formik.values.flat_or_per === '1') {
      delete staffInfo?.percentage;
    } else if (formik.values.flat_or_per === '2') {
      delete staffInfo?.flat_amt;
    }
    if (staffInfo.per_hr === 0) {
      delete staffInfo?.per_hr_amt;
    }

    if (!staffInfo.tax_type_id) {
      setOnNextloading(false);
      return showError('Tax Type is Missing');
    }

    if (staffInfo.deposit_form_path === '') {
      delete staffInfo.deposit_form_path;
    }
    if (stepsDone) {
      UpdateCompensation(staffInfo, staffId)
        .then(() => {
          setOnNextloading(false);
          if (formik.values.sales_commission_option && formik.values.commissions?.length) {
            updateCommissions();
          }
          nextStep(formik.values, `Compensation has been ${stepsDone ? 'updated' : 'created'}`);
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      AddCompensation(staffInfo)
        .then(() => {
          setOnNextloading(false);
          if (formik.values.sales_commission_option && formik.values.commissions?.length) {
            updateCommissions();
          }
          nextStep(formik.values, `Compensation has been ${stepsDone ? 'updated' : 'created'}`);
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  useEffect(() => {
    if (!stepsDone) {
      formik.setFieldValue('tax_id', '');
    }
    getStaffTaxType().then((res) => {
      setTaxOption(res.data);
    });

    getStaffCompanyPolicy()
      .then((res) => {
        setCompanyPolicy(res.data);
      })
      .catch(() => {
        // Do nothing
      });

    if (location?.state?.currentDriverId) {
      GetCompensation(location?.state?.currentDriverId);
    }
  }, []);

  useEffect(() => {
    if (!staffId) {
      return;
    }
    getCommissions();
  }, [staffId]);

  const remove = (index, data) => {
    if (data === 'pay_raise') {
      const temp = formik.values.pay_raise;
      temp.splice(index, 1);
      formik.setFieldValue('pay_raise', temp);
    }
  };

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], staffId)
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 2,
    };

    saveFinishLater(staffId, body).then(() => {
      Cancel();
    });
  };

  if (isDriverProfile) {
    <CompensationContent
      formik={formik}
      use={use}
      taxOption={taxOption}
      uploadDocument={uploadDocument}
      companyPolicy={companyPolicy}
      driver_id={staffId}
      staffId={staffId}
      remove={remove}
    />;
  }

  return (
    <>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <CompensationContent
          formik={formik}
          use={use}
          taxOption={taxOption}
          uploadDocument={uploadDocument}
          companyPolicy={companyPolicy}
          driver_id={staffId}
          staffId={staffId}
          remove={remove}
        />
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button onClick={nextStep} className='cancel-button skip-btn-staff'>
              Skip
            </Button>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}

            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <button
                type='button'
                onClick={onNextStep}
                className='next-step-btn'
                disabled={!formik.isValid}
                style={{ opacity: formik.isValid ? 1 : 0.7 }}
              >
                Next Step
              </button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
export default StaffCompensation;

export const CompensationContent = ({
  setRecurringModalData,
  recurringModalData,
  formik,
  use,
  taxOption,
  uploadDocument,
  staffId,
  remove,
  isDriverProfile,
  setRecurringModalShow,
  recurringModalShow,
  deductionFormik,
  deductionType,
  addtionType,
  benefitType,
  taxesType,
  Benefitformik,
  saveCompensationData,
  setReccuring,
  Additionsformik,
  taxesformik,
  getCommissions,
  refreshOptions,
  balances,
  getBalances,
  getDeductions,
  onViewBalanceDetails,
}) => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [openSettings, setOpenSettings] = useState(null);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const [balanceToDeleteId, setBalanceToDeleteId] = useState(null);
  const [loadingDeleteBalance, setLoadingDeleteBalance] = useState(false);
  const [openAddBalance, setOpenAddBalance] = useState(false);

  const deleteBalance = async () => {
    try {
      setLoadingDeleteBalance(true);
      await deleteStaffBalance(balanceToDeleteId);
      getBalances();
      getDeductions();
      setBalanceToDeleteId(null);
      showToaster({ type: 'success', message: 'Balance has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingDeleteBalance(false);
    }
  };

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      const filteredData = data.filter((i) => i.account_type.id === ACCOUNT_TYPE.EXPENSE && i.account_status === 1);
      setExpenseAccountOptions(filteredData);
      return filteredData;
    } catch (e) {
      // Do nothing
    }
  };

  const expenseAccountValue = useMemo(
    () => expenseAccountOptions.find((el) => Number(el?.id) === Number(formik?.values?.account_id)) || null,
    [expenseAccountOptions, formik?.values?.account_id]
  );

  const onSettingsClose = () => {
    if (refreshOptions) {
      refreshOptions();
    }
    setOpenSettings(null);
  };

  useEffect(() => {
    deductionFormik?.validateForm();
    Additionsformik?.validateForm();
    taxesformik?.validateForm();
    Benefitformik?.validateForm();
    getAccounts();
  }, []);

  return (
    <div className='step-wrapper driver-style-wrap'>
      <div className='d-flex align-items-start gap-2'>
        <div className='flex-col'>
          <p className='text-style tax-id' style={{ color: use(palette.gray700, palette.gray200) }}>
            Tax ID <span className='required'>*</span>
          </p>
          <div
            className='tax-id-input'
            style={{
              backgroundColor: use(palette.white, palette.dark600),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          >
            <input
              type='text'
              style={{ backgroundColor: use(palette.white, palette.dark600) }}
              className='input-field'
              name='tax_id'
              id='tax_id'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.tax_id}
            />
          </div>
          {formik.touched.tax_id && formik.errors.tax_id ? (
            <span className='error-message-staff'>{formik.errors.tax_id}</span>
          ) : null}
        </div>
        <div className='custom-select-wrap-contain'>
          <div className='flex-col'>
            <p className='text-style tax-id' style={{ color: use(palette.gray700, palette.gray200) }}>
              Tax Type <span className='required'>*</span>
            </p>
            <CustomSelect
              defaultValue='Select'
              options={taxOption}
              id='tax_type_id'
              name='tax_type_id'
              value={formik.values.tax_type_id}
              onBlur={formik.handleBlur}
              onChange={(value) => formik.setFieldValue('tax_type_id', value)}
            />
          </div>
          {formik.touched.tax_type_id && formik.errors.tax_type_id ? (
            <span className='error-message-staff'>{formik.errors.tax_type_id}</span>
          ) : null}
        </div>
        <div>
          <Autocomplete
            width='250px'
            required
            name='account_id'
            labelKey='account_name'
            label='Expense Account'
            value={expenseAccountValue}
            options={expenseAccountOptions}
            error={formik?.touchedErrors?.account_id}
            onChange={(e, val) => formik.setFieldValue('account_id', val?.id)}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
        </div>
      </div>
      <div className='bank-details mb-2'>
        <p className='bank-details-heading' style={{ color: use(palette.gray900, palette.gray200) }}>
          Payout Method
        </p>
      </div>
      <PaymentMethod formik={formik} uploadDocument={uploadDocument} />
      <PaymentOptions formik={formik} staffId={staffId} onSuccess={getCommissions} />
      {isDriverProfile ? (
        <>
          <div>
            <div>
              <div className='deduction-header-wrap'>
                <div className='d-flex align-items-center gap-3'>
                  <p
                    className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                    style={{ color: use(palette.gray900, palette.gray200) }}
                  >
                    Balances
                  </p>
                  <Tooltip
                    placement='right'
                    title={
                      <Typography variant='s2' style={{ color: palette.gray700 }}>
                        Truckin Digital Balances is a feature that enables companies to manage and track financial
                        balances for their drivers or staff members. Users can add specific balances for each driver or
                        staff member, allowing the company to maintain a clear overview of any deductions or outstanding
                        balances.
                      </Typography>
                    }
                    tooltipStyles={{ bgcolor: palette.white, border: '1px solid #dadde9', maxWidth: '600px' }}
                  >
                    <InfoIcon width={16} height={16} />
                  </Tooltip>
                </div>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date Created
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Name
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Type
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Payments
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Payment Amount
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Term
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Original Balance
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Balance Remain
                </p>
              </div>
              {balances?.map((item) => {
                const processedSettlements = item.deductions?.[0]?.settlement?.filter(
                  (settlement) => settlement.staff_settlement.status_id === 3
                );
                const paidAmount = (processedSettlements || []).reduce((acc, cur) => acc + Number(cur.amount), 0);

                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode balance-row'
                    onClick={() => onViewBalanceDetails(item)}
                  >
                    <div className='compensation-amount-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {convertToCustomerTime(item.created_at)}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {item.name}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap' style={{ display: 'flex' }}>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item.type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {(processedSettlements || []).length} out of {item.amortized_months}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.payment_amount)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item.month_week_settlement
                          ? `Every ${item.day} of the ${item.month_week}`
                          : `Over ${item.amortized_months} settlements`}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.original_balance)}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.original_balance - paidAmount)}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap delete-action-text  mb-0'
                        onClick={(e) => {
                          e.stopPropagation();
                          setBalanceToDeleteId(item.id);
                        }}
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='add-another compensation-add-another-feild'>
              <img src={add} className='add-employee-icon' alt='add' />
              <button className='add-employer-button' onClick={() => setOpenAddBalance(true)}>
                Add Another
              </button>
            </div>
          </div>
          <div>
            <div className='deduction-header-wrap'>
              <p
                className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                style={{ color: use(palette.gray900, palette.gray200) }}
              >
                Deductions
              </p>
              <button
                onClick={() => {
                  saveCompensationData('deductions');
                }}
                className='driver-compansation-save-btn'
                style={{ opacity: deductionFormik.isValid && deductionFormik?.values?.deductions?.length ? 1 : 0.7 }}
                disabled={!deductionFormik?.isValid || !deductionFormik?.values?.deductions?.length}
              >
                Save
              </button>
            </div>

            <div className='compensation-feilds-header-wraper'>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
              <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>
                Deduction Type
                <GearIcon onClick={() => setOpenSettings('deductions')} className='ms-2' />
              </p>
              <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Quantity</p>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Amount Per Quantity</p>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Total</p>
              <p className='compensation-feilds-header-head'>Set Interval</p>
            </div>
            {deductionFormik?.values?.deductions?.map((item, index) => {
              if (item.one_time_deduction) {
                return null;
              }

              return (
                <div className='compensation-types-header-wraper' key={item?.id || index}>
                  <div className='compensation-datepicker-main-wrap'>
                    <div
                      className='compensation-datepicker-wrapper'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    >
                      <DatePicker
                        selected={deductionFormik?.values?.deductions[index]?.date}
                        showYearDropdown
                        placeholderText='mm/dd/yyyy'
                        className='text-style ms-0'
                        id={`deductions[${index}].date`}
                        name={`deductions[${index}].date`}
                        onBlur={deductionFormik?.handleBlur}
                        value={deductionFormik?.values?.deductions[index]?.date}
                        onChange={(date) => {
                          deductionFormik?.setFieldValue(`deductions[${index}].date`, date);
                        }}
                        showTimeInput
                        disabled={!!item?.is_balance_deduction}
                      />
                      <span className='text-style'>
                        {' '}
                        <img src={sorting} alt='calendar' />
                      </span>
                    </div>
                  </div>
                  <div className='compensation-custom-select-main-wrap'>
                    <div
                      className='compensation-custom-select-wrapper'
                      style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
                    >
                      <div className='compensation-custom-select'>
                        <CustomSelect
                          defaultValue='Select'
                          options={deductionType}
                          onBlur={deductionFormik.handleBlur}
                          id={`deductions[${index}].deduction_type_id`}
                          name={`deductions[${index}].deduction_type_id`}
                          value={deductionFormik?.values?.deductions[index]?.deduction_type_id}
                          propertyName='type'
                          onChange={(val) => {
                            const option = deductionType?.find((item) => item.id === Number(val));
                            if (option) {
                              deductionFormik?.setFieldValue(`deductions[${index}]`, {
                                ...deductionFormik?.values?.deductions[index],
                                deduction_type_id: val,
                                quantity: 1,
                                per_quantity_amount: Number(option.amount),
                                amount: option.amount,
                              });
                            } else {
                              deductionFormik?.setFieldValue(`deductions[${index}].deduction_type_id`, val);
                            }
                          }}
                          disabled={!!item?.is_balance_deduction}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='compensation-amount-main-wrap'>
                    <div className='compensation-quantity-wrapper'>
                      <SInput
                        type='number'
                        value={item.quantity || ''}
                        onChange={(e) => {
                          if (e.target.value < 1) {
                            return;
                          }
                          deductionFormik?.setFieldValue(`deductions[${index}].quantity`, e.target.value);
                          deductionFormik?.setFieldValue(
                            `deductions[${index}].amount`,
                            Number(e.target.value) * Number(item.per_quantity_amount)
                          );
                        }}
                        onKeyDown={(e) => {
                          blockNonPositiveNumbers(e);
                          blockNotNumberChars(e);
                        }}
                        disabled={!!item?.is_balance_deduction}
                      />
                    </div>
                  </div>
                  <div className='compensation-datepicker-main-wrap'>
                    <div className='compensation-amount-wrapper'>
                      <span
                        className='doller-icon'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                          color: palette.gray400,
                        }}
                      >
                        {currency}
                      </span>
                      <input
                        type='number'
                        placeholder='0.00'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                        name={`deductions[${index}].per_quantity_amount`}
                        id={`deductions[${index}].per_quantity_amount`}
                        onKeyDown={(event) => {
                          ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                        }}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            return;
                          }
                          deductionFormik?.setFieldValue(`deductions[${index}].per_quantity_amount`, e.target.value);
                          deductionFormik?.setFieldValue(
                            `deductions[${index}].amount`,
                            Number(e.target.value) * Number(item.quantity)
                          );
                        }}
                        value={
                          item?.is_balance_deduction
                            ? item?.amount
                            : deductionFormik?.values?.deductions[index]?.per_quantity_amount
                        }
                        disabled={!!item?.is_balance_deduction}
                      />
                    </div>
                  </div>
                  <div className='compensation-datepicker-main-wrap'>
                    <div style={{ marginTop: '8px' }}>
                      {!!item.is_balance_deduction && !!item.isFulfilledBalance ? (
                        <Chip label='BALANCE FULFILLED' labelColor={palette.green500} bgColor={palette.green0} />
                      ) : (
                        <Typography variant='s3'>
                          - {currency}
                          {formatNumber(item.amount)}
                        </Typography>
                      )}
                    </div>
                  </div>
                  {item.every_settlement === 1 && (
                    <div className='compensation-recurring-wraper'>
                      <p className='taxes-compensation-subline'>Every Settlement</p>
                      <div className='setting-icon-container taxes-setting-icon-container'>
                        <img
                          src={setting}
                          alt=''
                          onClick={() => {
                            setRecurringModalShow(true);
                            setRecurringModalData({ ...item, index });
                          }}
                        />
                      </div>
                      <div className=''>
                        <img src={cross} alt='' onClick={() => remove(index, 'deductions')} />
                      </div>
                    </div>
                  )}
                  {item.month_week_settlement === 1 && (
                    <div className='compensation-recurring-wraper'>
                      <label
                        className='text-style compensation-text-style-lable'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        Every
                      </label>
                      <Dropdown
                        className='custom-dropdown-in-compensation'
                        onSelect={(value) => {
                          deductionFormik?.setFieldValue(`deductions[${index}].day`, value);
                        }}
                      >
                        <Dropdown.Toggle
                          placement='top'
                          variant='outline-secondary'
                          id='dropdown-basic'
                          className='tablePlaner_dropdownToggle compensation__dropdownToggle'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                          disabled={!!item?.is_balance_deduction}
                        >
                          {deductionFormik?.values?.deductions[index]?.day}
                        </Dropdown.Toggle>
                        {item?.month_week === 'Month' ? (
                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{ backgroundColor: use(palette.white, palette.dark800) }}
                          >
                            {month?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={deductionFormik?.values?.deductions[index]?.day === el}
                                  key={el}
                                  style={{
                                    color:
                                      deductionFormik?.values?.deductions[index]?.day === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        ) : (
                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{ backgroundColor: use(palette.white, palette.dark800) }}
                          >
                            {weekData?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={deductionFormik?.values?.deductions[index]?.day === el}
                                  key={el}
                                  style={{
                                    color:
                                      deductionFormik?.values?.deductions[index]?.day === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                      <label
                        className='text-style compensation-text-style-lable-of'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        of the
                      </label>
                      <Dropdown
                        className='custom-dropdown-in-compensation'
                        onSelect={(value) => {
                          deductionFormik.setFieldValue(`deductions[${index}].month_week`, value);
                          if (value === 'Month') {
                            deductionFormik.setFieldValue(`deductions[${index}].day`, '1st');
                          } else {
                            deductionFormik.setFieldValue(`deductions[${index}].day`, 'Monday');
                          }
                        }}
                      >
                        <Dropdown.Toggle
                          placement='top'
                          variant='outline-secondary'
                          id='dropdown-basic'
                          className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                          disabled={!!item?.is_balance_deduction}
                        >
                          {deductionFormik?.values?.deductions[index]?.month_week}
                          <div className='tablePlaner_arrows_wrapper'>
                            <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className='tablePlaner_menu compansation_menu'
                          style={{ backgroundColor: use(palette.white, palette.dark800) }}
                        >
                          {monthData?.map((el, index) => {
                            return (
                              <Dropdown.Item
                                eventKey={el}
                                active={deductionFormik?.values?.deductions[index]?.month_week === el}
                                key={el}
                                style={{
                                  color:
                                    deductionFormik?.values?.deductions[index]?.month_week === el
                                      ? use(palette.white, palette.white)
                                      : use(palette.gray700, palette.gray200),
                                }}
                              >
                                {el}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className='setting-icon-container'>
                        {!item?.is_balance_deduction ? (
                          <img
                            src={setting}
                            alt=''
                            onClick={() => {
                              setRecurringModalShow(true);
                              setRecurringModalData({ ...item, index });
                            }}
                          />
                        ) : (
                          <span
                            className='download-sample'
                            onClick={() => onViewBalanceDetails(balances.find((el) => el.id === item.balanceId))}
                          >
                            Edit Payment
                          </span>
                        )}
                      </div>
                      {!item?.is_balance_deduction && (
                        <div className=''>
                          <img src={cross} alt='' onClick={() => remove(index, 'deductions')} />
                        </div>
                      )}
                    </div>
                  )}
                  {item.amortized_settlement === 1 && (
                    <div className='compensation-recurring-wraper type-compensation-recurring-wraper'>
                      <label
                        className='text-style compensation-text-style-lable'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        Amortized
                      </label>
                      <div className='compensation-amount-wrapper mt-0'>
                        <span
                          className='doller-icon'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            color: palette.gray400,
                          }}
                        >
                          {currency}
                        </span>
                        <input
                          type='number'
                          placeholder='0.00'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                          }}
                          name={`deductions[${index}].amortized_total`}
                          id={`deductions[${index}].amortized_total`}
                          onChange={deductionFormik?.handleChange}
                          value={deductionFormik?.values?.deductions[index]?.amortized_total}
                          disabled={!!item?.is_balance_deduction}
                        />
                      </div>
                      <label
                        className='text-style compensation-text-style-lable-of'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        over
                      </label>
                      <div
                        className='re-enter-routing-in-recurring'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <input
                          type='number'
                          style={{ backgroundColor: use(palette.white, palette.dark600) }}
                          className='input-field'
                          placeholder='00'
                          name={`deductions[${index}].amortized_months`}
                          id={`deductions[${index}].amortized_months`}
                          onChange={deductionFormik?.handleChange}
                          value={deductionFormik?.values?.deductions[index]?.amortized_months}
                          disabled={!!item?.is_balance_deduction}
                        />
                      </div>
                      <label
                        className='text-style compensation-text-style-lable-of'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        settlements, deducted on each settlement.
                      </label>
                      <div className='setting-icon-container taxes-setting-icon-container'>
                        {!item?.is_balance_deduction ? (
                          <img
                            src={setting}
                            alt=''
                            onClick={() => {
                              setRecurringModalShow(true);
                              setRecurringModalData({ ...item, index });
                            }}
                          />
                        ) : (
                          <span
                            className='download-sample'
                            onClick={() => onViewBalanceDetails(balances.find((el) => el.id === item.balanceId))}
                          >
                            Edit Payment
                          </span>
                        )}
                      </div>
                      {!item?.is_balance_deduction && (
                        <div className=''>
                          <img src={cross} alt='' onClick={() => remove(index, 'deductions')} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
            <div className='add-another compensation-add-another-feild'>
              <img src={add} className='add-employee-icon' alt='add' />
              <button
                className='add-employer-button'
                onClick={() =>
                  deductionFormik.setFieldValue('deductions', [
                    ...deductionFormik.values.deductions,
                    {
                      driver_id: staffId,
                      date: new Date(convertToCustomerTime()),
                      time: '',
                      deduction_type_id: 0,
                      quantity: 1,
                      per_quantity_amount: '',
                      amount: 0,
                      every_settlement: 0,
                      month_week_settlement: 1,
                      amortized_settlement: 0,
                      day: 'Monday',
                      month_week: 'Week',
                      amortized_total: 0,
                      amortized_month: 0,
                      amortized_months: 0,
                      deduction_id: 'new',
                      type: 'deductions',
                    },
                  ])
                }
              >
                Add Another
              </button>
            </div>
          </div>
          <div>
            <div className='deduction-header-wrap'>
              <p
                className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                style={{ color: use(palette.gray900, palette.gray200) }}
              >
                Additions
              </p>
              <button
                onClick={() => {
                  saveCompensationData('additions');
                }}
                className='driver-compansation-save-btn'
                style={{ opacity: Additionsformik.isValid && Additionsformik?.values?.additions?.length ? 1 : 0.7 }}
                disabled={!Additionsformik?.isValid || !Additionsformik?.values?.additions?.length}
              >
                Save
              </button>
            </div>
            <div className='compensation-feilds-header-wraper'>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
              <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>
                Addition Type
                <GearIcon onClick={() => setOpenSettings('additions')} className='ms-2' />
              </p>
              <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Quantity</p>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Amount Per Quantity</p>
              <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Total</p>
              <p className='compensation-feilds-header-head'>Set Interval</p>
            </div>

            {Additionsformik?.values?.additions?.map((item, index) => {
              return (
                <div className='compensation-types-header-wraper' key={item?.id || index}>
                  <div className='compensation-datepicker-main-wrap'>
                    <div
                      className='compensation-datepicker-wrapper'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    >
                      <DatePicker
                        selected={Additionsformik?.values?.additions[index]?.date}
                        showYearDropdown
                        placeholderText='mm/dd/yyyy'
                        className='text-style ms-0'
                        id={`additions[${index}].date`}
                        name={`additions[${index}].date`}
                        onBlur={Additionsformik?.handleBlur}
                        value={Additionsformik?.values?.additions[index]?.date}
                        onChange={(date) => {
                          Additionsformik?.setFieldValue(`additions[${index}].date`, date);
                        }}
                        showTimeInput
                      />
                      <span className='text-style'>
                        {' '}
                        <img src={sorting} alt='calendar' />
                      </span>
                    </div>
                  </div>
                  <div className='compensation-custom-select-main-wrap'>
                    <div
                      className='compensation-custom-select-wrapper'
                      style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
                    >
                      <div className='compensation-custom-select'>
                        <CustomSelect
                          defaultValue='Select'
                          options={addtionType}
                          onBlur={Additionsformik.handleBlur}
                          id={`additions[${index}].addition_type_id`}
                          name={`additions[${index}].addition_type_id`}
                          onChange={(val) => {
                            const option = addtionType?.find((item) => item.id === Number(val));
                            if (option) {
                              Additionsformik?.setFieldValue(`additions[${index}]`, {
                                ...Additionsformik?.values?.additions[index],
                                addition_type_id: val,
                                quantity: 1,
                                per_quantity_amount: Number(option.amount),
                                amount: option.amount,
                              });
                            } else {
                              Additionsformik?.setFieldValue(`additions[${index}].addition_type_id`, val);
                            }
                          }}
                          value={Additionsformik?.values?.additions[index]?.addition_type_id}
                          propertyName='type'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='compensation-amount-main-wrap'>
                    <div className='compensation-quantity-wrapper'>
                      <SInput
                        type='number'
                        value={item.quantity || ''}
                        onChange={(e) => {
                          if (e.target.value < 1) {
                            return;
                          }
                          Additionsformik?.setFieldValue(`additions[${index}].quantity`, e.target.value);
                          Additionsformik?.setFieldValue(
                            `additions[${index}].amount`,
                            Number(e.target.value) * Number(item.per_quantity_amount)
                          );
                        }}
                        onKeyDown={(e) => {
                          blockNonPositiveNumbers(e);
                          blockNotNumberChars(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='compensation-datepicker-main-wrap'>
                    <div className='compensation-amount-wrapper'>
                      <span
                        className='doller-icon'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                          color: palette.gray400,
                        }}
                      >
                        {currency}
                      </span>
                      <input
                        type='number'
                        placeholder='0.00'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                        name={`additions[${index}].per_quantity_amount`}
                        id={`additions[${index}].per_quantity_amount`}
                        onKeyDown={(event) => {
                          ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                        }}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            return;
                          }
                          Additionsformik?.setFieldValue(`additions[${index}].per_quantity_amount`, e.target.value);
                          Additionsformik?.setFieldValue(
                            `additions[${index}].amount`,
                            Number(e.target.value) * Number(item.quantity)
                          );
                        }}
                        value={Additionsformik?.values?.additions[index]?.per_quantity_amount}
                      />
                    </div>
                  </div>
                  <div className='compensation-amount-main-wrap'>
                    <div style={{ marginTop: '8px' }}>
                      <Typography variant='s3'>
                        {currency}
                        {formatNumber(item.amount)}
                      </Typography>
                    </div>
                  </div>
                  {item.every_settlement === 0 ? (
                    <div className='compensation-recurring-wraper'>
                      <label
                        className='text-style compensation-text-style-lable'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        Every
                      </label>
                      <Dropdown
                        className='custom-dropdown-in-compensation'
                        onSelect={(value) => {
                          Additionsformik?.setFieldValue(`additions[${index}].day`, value);
                        }}
                      >
                        <Dropdown.Toggle
                          placement='top'
                          variant='outline-secondary'
                          id='dropdown-basic'
                          className='tablePlaner_dropdownToggle compensation__dropdownToggle'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                        >
                          {Additionsformik?.values?.additions[index]?.day}
                        </Dropdown.Toggle>

                        {item?.month_week === 'Month' ? (
                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{ backgroundColor: use(palette.white, palette.dark800) }}
                          >
                            {month?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={Additionsformik?.values?.additions[index]?.day === el}
                                  key={el}
                                  style={{
                                    color:
                                      Additionsformik?.values?.additions[index]?.day === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        ) : (
                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{ backgroundColor: use(palette.white, palette.dark800) }}
                          >
                            {weekData?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={Additionsformik?.values?.additions[index]?.day === el}
                                  key={el}
                                  style={{
                                    color:
                                      Additionsformik?.values?.additions[index]?.day === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                      <label
                        className='text-style compensation-text-style-lable-of'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        of the
                      </label>
                      <Dropdown
                        className='custom-dropdown-in-compensation'
                        onSelect={(value) => {
                          Additionsformik.setFieldValue(`additions[${index}].month_week`, value);
                          if (value === 'Month') {
                            Additionsformik.setFieldValue(`additions[${index}].day`, '1st');
                          } else {
                            Additionsformik.setFieldValue(`additions[${index}].day`, 'Monday');
                          }
                        }}
                      >
                        <Dropdown.Toggle
                          placement='top'
                          variant='outline-secondary'
                          id='dropdown-basic'
                          className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                        >
                          {Additionsformik?.values?.additions[index]?.month_week}
                          <div className='tablePlaner_arrows_wrapper'>
                            <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className='tablePlaner_menu compansation_menu'
                          style={{ backgroundColor: use(palette.white, palette.dark800) }}
                        >
                          {monthData?.map((el, index) => {
                            return (
                              <Dropdown.Item
                                eventKey={el}
                                active={Additionsformik?.values?.additions[index]?.month_week === el}
                                key={el}
                                style={{
                                  color:
                                    Additionsformik?.values?.additions[index]?.month_week === el
                                      ? use(palette.white, palette.white)
                                      : use(palette.gray700, palette.gray200),
                                }}
                              >
                                {el}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className='setting-icon-container'>
                        <img
                          src={setting}
                          alt=''
                          onClick={() => {
                            setRecurringModalShow(true);
                            setRecurringModalData({ ...item, index });
                          }}
                        />
                      </div>
                      <div className=''>
                        <img src={cross} alt='' onClick={() => remove(index, 'additions')} />
                      </div>
                    </div>
                  ) : (
                    <div className='compensation-recurring-wraper'>
                      <p className='taxes-compensation-subline'>Every Settlement</p>
                      <div className='setting-icon-container taxes-setting-icon-container'>
                        <img
                          src={setting}
                          alt=''
                          onClick={() => {
                            setRecurringModalShow(true);
                            setRecurringModalData({ ...item, index });
                          }}
                        />
                      </div>
                      <div className=''>
                        <img src={cross} alt='' onClick={() => remove(index, 'additions')} />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            <div className='add-another compensation-add-another-feild'>
              <img src={add} className='add-employee-icon' alt='add' />
              <button
                className='add-employer-button'
                onClick={() =>
                  Additionsformik.setFieldValue('additions', [
                    ...Additionsformik.values.additions,
                    {
                      driver_id: staffId,
                      date: new Date(convertToCustomerTime()),
                      time: '',
                      addition_type_id: null,
                      quantity: 1,
                      per_quantity_amount: '',
                      amount: 0,
                      every_settlement: 0,
                      month_week_settlement: 1,
                      day: 'Monday',
                      month_week: 'Week',
                      addition_id: 'new',
                      type: 'additions',
                    },
                  ])
                }
              >
                Add Another
              </button>
            </div>
          </div>

          <div>
            <div className='deduction-header-wrap'>
              <p
                className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                style={{ color: use(palette.gray900, palette.gray200) }}
              >
                Taxes
              </p>
              <button
                onClick={() => {
                  saveCompensationData('taxes');
                }}
                className='driver-compansation-save-btn'
                style={{ opacity: taxesformik.isValid && taxesformik?.values?.taxes?.length ? 1 : 0.7 }}
                disabled={!taxesformik?.isValid || !taxesformik?.values?.taxes?.length}
              >
                Save
              </button>
            </div>
            <div className='compensation-feilds-header-wraper'>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
              <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>
                Tax Description <GearIcon onClick={() => setOpenSettings('taxes')} className='ms-2' />
              </p>
              <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Percent</p>
              <p className='compensation-feilds-header-head'>Set Interval</p>
            </div>
            {taxesformik?.values?.taxes?.map((item, index) => {
              return (
                <div className='compensation-types-header-wraper' key={item?.id || index}>
                  <div className='compensation-datepicker-main-wrap'>
                    <div
                      className='compensation-datepicker-wrapper'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    >
                      <DatePicker
                        selected={taxesformik?.values?.taxes[index]?.date}
                        showYearDropdown
                        placeholderText='mm/dd/yyyy'
                        className='text-style ms-0'
                        id={`taxes[${index}].date`}
                        name={`taxes[${index}].date`}
                        onBlur={taxesformik?.handleBlur}
                        value={taxesformik?.values?.taxes[index]?.date}
                        onChange={(date) => {
                          taxesformik?.setFieldValue(`taxes[${index}].date`, date);
                        }}
                        showTimeInput
                      />
                      <span className='text-style'>
                        {' '}
                        <img src={sorting} alt='calendar' />
                      </span>
                    </div>
                  </div>
                  <div className='compensation-custom-select-main-wrap'>
                    <div
                      className='compensation-custom-select-wrapper'
                      style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
                    >
                      <div className='compensation-custom-select'>
                        <CustomSelect
                          defaultValue='Select'
                          options={taxesType}
                          onBlur={taxesformik.handleBlur}
                          id={`taxes[${index}].tax_type_id`}
                          name={`taxes[${index}].tax_type_id`}
                          value={taxesformik?.values?.taxes[index]?.tax_type_id}
                          propertyName='tax_type'
                          onChange={(val) => {
                            const option = taxesType?.find((item) => item.id === Number(val));
                            if (option) {
                              taxesformik?.setFieldValue(`taxes[${index}]`, {
                                ...taxesformik?.values?.taxes[index],
                                tax_type_id: val,
                                percent: option.percent,
                              });
                            } else {
                              taxesformik?.setFieldValue(`taxes[${index}].tax_type_id`, val);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='compensation-amount-main-wrap'>
                    <div className='compensation-per-amount-wrapper'>
                      <input
                        type='number'
                        placeholder='0.00'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                        name={`taxes[${index}].percent`}
                        id={`taxes[${index}].percent`}
                        onKeyDown={(event) => {
                          ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                        }}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            return;
                          }
                          taxesformik?.setFieldValue(`taxes[${index}].percent`, e.target.value);
                        }}
                        value={taxesformik?.values?.taxes[index]?.percent}
                      />
                    </div>
                  </div>
                  <div className='compensation-recurring-wraper'>
                    <input
                      type='checkbox'
                      name={`taxes[${index}].auto_deduct`}
                      checked={item?.auto_deduct}
                      value={1}
                      onChange={() =>
                        taxesformik.setFieldValue(
                          `taxes[${index}].auto_deduct`,
                          !taxesformik?.values.taxes[index]?.auto_deduct
                        )
                      }
                    />
                    <p className='taxes-compensation-subline'>Auto deduct taxes on each settlement</p>
                    <div className=''>
                      <img src={cross} alt='' onClick={() => remove(index, 'taxes')} />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className='add-another compensation-add-another-feild'>
              <img src={add} className='add-employee-icon' alt='add' />
              <button
                className='add-employer-button'
                onClick={() =>
                  taxesformik.setFieldValue('taxes', [
                    ...taxesformik.values.taxes,
                    {
                      driver_id: staffId,
                      date: new Date(convertToCustomerTime()),
                      time: '',
                      tax_type_id: null,
                      percent: 0,
                      auto_deduct: 0,
                      tax_id: 'new',
                    },
                  ])
                }
              >
                Add Another
              </button>
            </div>
          </div>

          <div>
            <div className='deduction-header-wrap'>
              <p
                className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                style={{ color: use(palette.gray900, palette.gray200) }}
              >
                Benefits
              </p>
              <button
                onClick={() => {
                  saveCompensationData('benefits');
                }}
                className='driver-compansation-save-btn'
                style={{ opacity: Benefitformik.isValid && Benefitformik?.values?.benefits?.length ? 1 : 0.7 }}
                disabled={!Benefitformik?.isValid || !Benefitformik?.values?.benefits?.length}
              >
                Save
              </button>
            </div>
            <div className='compensation-feilds-header-wraper'>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
              <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>
                Benefit Description
                <GearIcon onClick={() => setOpenSettings('benefits')} className='ms-2 pointer' />
              </p>
              <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Quantity</p>
              <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Amount Per Quantity</p>
              <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Total</p>
              <p className='compensation-feilds-header-head'>Set Interval</p>
            </div>
            {Benefitformik?.values?.benefits?.map((item, index) => {
              return (
                <div className='compensation-types-header-wraper' key={item?.id || index}>
                  <div className='compensation-datepicker-main-wrap'>
                    <div
                      className='compensation-datepicker-wrapper'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    >
                      <DatePicker
                        selected={Benefitformik?.values?.benefits[index]?.date}
                        showYearDropdown
                        placeholderText='mm/dd/yyyy'
                        className='text-style ms-0'
                        id={`benefits[${index}].date`}
                        name={`benefits[${index}].date`}
                        onBlur={Benefitformik?.handleBlur}
                        value={Benefitformik?.values?.benefits[index]?.date}
                        onChange={(date) => {
                          Benefitformik?.setFieldValue(`benefits[${index}].date`, date);
                        }}
                        showTimeInput
                      />
                      <span className='text-style'>
                        {' '}
                        <img src={sorting} alt='calendar' />
                      </span>
                    </div>
                  </div>
                  <div className='compensation-custom-select-main-wrap'>
                    <div
                      className='compensation-custom-select-wrapper'
                      style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
                    >
                      <div className='compensation-custom-select'>
                        <CustomSelect
                          defaultValue='Select'
                          options={benefitType}
                          onBlur={Benefitformik.handleBlur}
                          id={`benefits[${index}].benefit_type_id`}
                          name={`benefits[${index}].benefit_type_id`}
                          onChange={(val) => {
                            const option = benefitType?.find((item) => item.id === Number(val));
                            if (option) {
                              Benefitformik?.setFieldValue(`benefits[${index}]`, {
                                ...Benefitformik?.values?.benefits[index],
                                benefit_type_id: val,
                                quantity: 1,
                                per_quantity_amount: Number(option.amount),
                                amount: option.amount,
                              });
                            } else {
                              Benefitformik?.setFieldValue(`benefits[${index}].benefit_type_id`, val);
                            }
                          }}
                          value={Benefitformik?.values?.benefits[index]?.benefit_type_id}
                          propertyName='type'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='compensation-amount-main-wrap'>
                    <div className='compensation-quantity-wrapper'>
                      <SInput
                        type='number'
                        value={item.quantity || ''}
                        onChange={(e) => {
                          if (e.target.value < 1) {
                            return;
                          }
                          Benefitformik?.setFieldValue(`benefits[${index}].quantity`, e.target.value);
                          Benefitformik?.setFieldValue(
                            `benefits[${index}].amount`,
                            Number(e.target.value) * Number(item.per_quantity_amount)
                          );
                        }}
                        onKeyDown={(e) => {
                          blockNonPositiveNumbers(e);
                          blockNotNumberChars(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='compensation-datepicker-main-wrap'>
                    <div className='compensation-amount-wrapper'>
                      <span
                        className='doller-icon'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                          color: palette.gray400,
                        }}
                      >
                        {currency}
                      </span>
                      <input
                        type='number'
                        placeholder='0.00'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                        name={`benefits[${index}].per_quantity_amount`}
                        id={`benefits[${index}].per_quantity_amount`}
                        onKeyDown={(event) => {
                          ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                        }}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            return;
                          }
                          Benefitformik?.setFieldValue(`benefits[${index}].per_quantity_amount`, e.target.value);
                          Benefitformik?.setFieldValue(
                            `benefits[${index}].amount`,
                            Number(e.target.value) * Number(item.quantity)
                          );
                        }}
                        value={Benefitformik?.values?.benefits[index]?.per_quantity_amount}
                      />
                    </div>
                  </div>
                  <div className='compensation-amount-main-wrap'>
                    <div style={{ marginTop: '8px' }}>
                      <Typography variant='s3'>
                        - {currency}
                        {formatNumber(item.amount)}
                      </Typography>
                    </div>
                  </div>
                  {item.every_settlement === 0 ? (
                    <div className='compensation-recurring-wraper'>
                      <label
                        className='text-style compensation-text-style-lable'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        Every
                      </label>
                      <Dropdown
                        className='custom-dropdown-in-compensation'
                        onSelect={(value) => {
                          Benefitformik?.setFieldValue(`benefits[${index}].day`, value);
                        }}
                      >
                        <Dropdown.Toggle
                          placement='top'
                          variant='outline-secondary'
                          id='dropdown-basic'
                          className='tablePlaner_dropdownToggle compensation__dropdownToggle'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                        >
                          {Benefitformik?.values?.benefits[index]?.day}
                        </Dropdown.Toggle>

                        {item?.month_week === 'Month' ? (
                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{ backgroundColor: use(palette.white, palette.dark800) }}
                          >
                            {month?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={Benefitformik?.values?.benefits[index]?.day === el}
                                  key={el}
                                  style={{
                                    color:
                                      Benefitformik?.values?.benefits[index]?.day === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        ) : (
                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{ backgroundColor: use(palette.white, palette.dark800) }}
                          >
                            {weekData?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={Benefitformik?.values?.benefits[index]?.day === el}
                                  key={el}
                                  style={{
                                    color:
                                      Benefitformik?.values?.benefits[index]?.day === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                      <label
                        className='text-style compensation-text-style-lable-of'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        of the
                      </label>
                      <Dropdown
                        className='custom-dropdown-in-compensation'
                        onSelect={(value) => {
                          Benefitformik.setFieldValue(`benefits[${index}].month_week`, value);
                          if (value === 'Month') {
                            Benefitformik.setFieldValue(`benefits[${index}].day`, '1st');
                          } else {
                            Benefitformik.setFieldValue(`benefits[${index}].day`, 'Monday');
                          }
                        }}
                      >
                        <Dropdown.Toggle
                          placement='top'
                          variant='outline-secondary'
                          id='dropdown-basic'
                          className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                        >
                          {Benefitformik?.values?.benefits[index]?.month_week}
                          <div className='tablePlaner_arrows_wrapper'>
                            <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className='tablePlaner_menu compansation_menu'
                          style={{ backgroundColor: use(palette.white, palette.dark800) }}
                        >
                          {monthData?.map((el, index) => {
                            return (
                              <Dropdown.Item
                                eventKey={el}
                                active={Benefitformik?.values?.benefits[index]?.month_week === el}
                                key={el}
                                style={{
                                  color:
                                    Benefitformik?.values?.benefits[index]?.month_week === el
                                      ? use(palette.white, palette.white)
                                      : use(palette.gray700, palette.gray200),
                                }}
                              >
                                {el}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className='setting-icon-container'>
                        <img
                          src={setting}
                          alt=''
                          onClick={() => {
                            setRecurringModalShow(true);
                            setRecurringModalData({ ...item, index });
                          }}
                        />
                      </div>
                      <div className=''>
                        <img src={cross} alt='' onClick={() => remove(index, 'benefits')} />
                      </div>
                    </div>
                  ) : (
                    <div className='compensation-recurring-wraper'>
                      <p className='taxes-compensation-subline'>Every Settlement</p>
                      <div className='setting-icon-container taxes-setting-icon-container'>
                        <img
                          src={setting}
                          alt=''
                          onClick={() => {
                            setRecurringModalShow(true);
                            setRecurringModalData({ ...item, index });
                          }}
                        />
                      </div>
                      <div className=''>
                        <img src={cross} alt='' onClick={() => remove(index, 'benefits')} />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            <div className='add-another compensation-add-another-feild'>
              <img src={add} className='add-employee-icon' alt='add' />
              <button
                className='add-employer-button'
                onClick={() =>
                  Benefitformik.setFieldValue('benefits', [
                    ...Benefitformik.values.benefits,
                    {
                      driver_id: staffId,
                      date: new Date(convertToCustomerTime()),
                      time: '',
                      benefit_type_id: null,
                      quantity: 1,
                      per_quantity_amount: '',
                      amount: 0,
                      every_settlement: 0,
                      month_week_settlement: 1,
                      day: 'Monday',
                      month_week: 'Week',
                      benefit_id: 'new',
                      type: 'benefits',
                    },
                  ])
                }
              >
                Add Another
              </button>
            </div>
          </div>
          <DeductionsSettings open={openSettings === 'deductions'} onClose={onSettingsClose} />
          <AdditionsSettings open={openSettings === 'additions'} onClose={onSettingsClose} />
          <TaxesSettings open={openSettings === 'taxes'} onClose={onSettingsClose} />
          <BenefitsSettings open={openSettings === 'benefits'} onClose={onSettingsClose} />
          {recurringModalShow && (
            <RecurringModal
              show={setRecurringModalShow}
              onHide={() => setRecurringModalShow(false)}
              recurringModalData={recurringModalData}
              updateReccuringData={setReccuring}
            />
          )}
        </>
      ) : null}
      {!!balanceToDeleteId && (
        <ConfirmationModal
          width='510px'
          open={!!balanceToDeleteId}
          onClose={() => setBalanceToDeleteId(null)}
          headerTitle='Delete Balance'
          text='Are you sure you want to delete this balance?'
          onConfirm={deleteBalance}
          disabled={loadingDeleteBalance}
        />
      )}
      {!!openAddBalance && (
        <AddBalance
          open={openAddBalance}
          onClose={() => setOpenAddBalance(false)}
          onSuccess={() => {
            getBalances();
            getDeductions();
          }}
          userId={staffId}
          isStaff
        />
      )}
    </div>
  );
};
