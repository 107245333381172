import React, { useEffect, useRef, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { ReactComponent as ArrowLeft } from 'assets/icons/vehicles/sub-right.svg';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import AddressAutocomplete from 'common/AddressAutocomplete';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Input, { InputWithIcon, InputWithText, Textarea } from 'common/Input';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars, formatInputNumber, removeCommas } from 'utils/helpers';
import { getContactsBillingCharges } from 'Api/Planner';
import { getCarrierContactBook, getCarriers } from 'Api/Carriers';
import { getSMSCommunicationSettings } from 'Api/CompanySettings';
import { getAssignedDrivers, getDrivers } from 'Api/EquipmentProfile';
import { autoAwardTimeOptions, timeOptions } from 'componentsV2/Planner/OfferOptions/OfferOptions.data';
import { driverStatuses, planAheadActions } from './PlanAhead.data';
import { SMergedInputs, SWrapper } from './PlanAhead.styles';

const PlanAhead = ({ form, data, customerId, billingAmount }) => {
  const showToaster = useShowToaster();
  const controller = useRef(new AbortController());

  const [drivers, setDrivers] = useState([]);
  const [offerDrivers, setOfferDrivers] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [carrierContactBook, setCarrierContactBook] = useState([]);
  const [customerContactBook, setCustomerContactBook] = useState([]);
  const [smsSettings, setSmsSettings] = useState(null);
  const hasBrokerage = useHasBrokerage();
  const didMountRef = useRef(false);

  const { shipment_lane, shipment_billing } = data || {};
  const { values, handleChange, touchedErrors, setValues } = form;

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data.filter((i) => !!i.equipment).map((item) => ({ ...item, name: `${item.fname} ${item.lname}` })));
    } catch (e) {
      // Do nothing
    }
  };

  const getAssignedDriversList = async () => {
    try {
      if (controller?.current) {
        controller.current.abort();
        controller.current = new AbortController();
      }

      const { data } = await getAssignedDrivers({ allow_offer: 1 }, controller?.current?.signal);

      setOfferDrivers(data.map((item) => ({ ...item, name: `${item.fname} ${item.lname}` })));

      if (!didMountRef.current) {
        const driver_ids =
          typeof shipment_lane?.offer_options?.driver_ids === 'string' && shipment_lane?.plan_ahead_type === 4
            ? JSON.parse(shipment_lane?.offer_options?.driver_ids)
            : null;

        if (driver_ids?.length) {
          handleChange(
            'drivers',
            data
              .filter((item) => driver_ids.map((i) => Number(i)).includes(item.id))
              .map((item) => ({ ...item, name: `${item.fname} ${item.lname}` }))
          );
        }
        didMountRef.current = true;
      } else {
        handleChange('drivers', []);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getCarriersList = async () => {
    try {
      const { data } = await getCarriers();
      setCarriers(data);

      const carrier_ids =
        typeof shipment_lane?.offer_options?.carrier_ids === 'string' && shipment_lane?.plan_ahead_type === 4
          ? JSON.parse(shipment_lane?.offer_options?.carrier_ids)
          : null;

      if (carrier_ids?.length) {
        handleChange(
          'carriers',
          data.filter((item) => carrier_ids.map((i) => Number(i)).includes(item.id))
        );
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getCarrierContact = async () => {
    try {
      const { data } = await getCarrierContactBook({ id: values.carrier?.id });
      setCarrierContactBook(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerContacts = async (customer) => {
    try {
      const res = await getContactsBillingCharges(customer);
      setCustomerContactBook(res.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getSmsSettings = async () => {
    try {
      const { data } = await getSMSCommunicationSettings();
      setSmsSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddressSelect = async (data) => {
    try {
      const res = await getGeoLocation(data);
      if (res.latitude && res.longitude) {
        setValues((prevState) => ({ ...prevState, location_lat: res.latitude, location_lng: res.longitude }));
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getGeoLocation = async (value) => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          addr: value.address,
          city: value.city?.name,
          state: value.state.name,
          zip: value.zip,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                latitude: el.Coords?.Lat,
                longitude: el.Coords?.Lon,
              };
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          showToaster({ type: 'error', message: 'Could not find location' });
          resolve(null);
        },
      });
    });
  };

  const changeAddress = ({ formatted_address }) => {
    handleChange('location', formatted_address);
  };

  const handleDriversChange = (e, value, reason, details) => {
    if (details?.option?.name === 'Select All') {
      handleChange('drivers', values.drivers.length === offerDrivers.length ? [] : offerDrivers);
      return;
    }
    handleChange('drivers', value);
  };

  const handleCarriersChange = (e, value, reason, details) => {
    if (details?.option?.name === 'Select All') {
      handleChange('carriers', values.carriers.length === carriers.length ? [] : carriers);
      return;
    }
    handleChange('carriers', value);
  };

  useEffect(() => {
    getDriversList();
    getCarriersList();
    getSmsSettings();
    getAssignedDriversList();
  }, []);

  useEffect(() => {
    if (!values.carrier?.id) {
      return;
    }

    getCarrierContact();
  }, [values.carrier]);

  useEffect(() => {
    if (!shipment_billing?.[0]?.billing_customer?.id && !customerId) {
      return;
    }

    getCustomerContacts(shipment_billing?.[0]?.billing_customer?.id || customerId);
  }, [shipment_billing?.[0]?.billing_customer?.id, customerId]);

  const shipmentAmount = billingAmount || shipment_billing?.reduce((acc, cur) => acc + Number(cur.total_amount), 0);
  const percent = values.offer_amount
    ? ((shipmentAmount - Number(removeCommas(values.offer_amount) || 0)) * 100) / shipmentAmount
    : null;

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex align-items-center gap-2 mb-2'>
        <CustomCheckbox
          type='switch'
          name='plan_ahead_type'
          checked={values.plan_ahead_type?.id === 1}
          onChange={(checked) => handleChange('plan_ahead_type', checked ? planAheadActions[0] : null)}
          smail={false}
        />
        <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
          {planAheadActions[0].name}
        </Typography>
      </div>
      <div className='d-flex align-items-center gap-2 mb-2'>
        <CustomCheckbox
          type='switch'
          name='plan_ahead_type'
          checked={values.plan_ahead_type?.id === 2}
          onChange={(checked) => handleChange('plan_ahead_type', checked ? planAheadActions[1] : null)}
          smail={false}
        />
        <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
          {planAheadActions[1].name}
        </Typography>
      </div>
      {values.plan_ahead_type?.id === 2 && (
        <div className='d-flex gap-2'>
          <ArrowLeft className='ms-4' />
          <div className='mb-4'>
            <Autocomplete
              required
              width='300px'
              label='Select Driver'
              placeholder='Select Driver'
              name='driver'
              options={drivers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={values.driver}
              getOptionLabel={(option) =>
                `${option.fname} ${option.lname}${
                  option.equipment?.[0]
                    ? ` - ${option.equipment?.[0]?.equipment_id} ${option.equipment?.[0]?.equipment_type?.title}`
                    : ''
                }`
              }
              onChange={(e, value) => handleChange('driver', value)}
              error={touchedErrors.driver}
            />
          </div>
        </div>
      )}
      {values.plan_ahead_type?.id !== 2 && (
        <div className='d-flex align-items-center gap-2 mb-2'>
          <CustomCheckbox
            type='switch'
            name='plan_ahead_type'
            checked={values.plan_ahead_type?.id === 3}
            onChange={(checked) => {
              if (checked) {
                setValues((prevState) => ({
                  ...prevState,
                  plan_ahead_type: planAheadActions[2],
                  start_from_type: 2,
                }));
              } else {
                handleChange('plan_ahead_type', null);
              }
            }}
            smail={false}
          />
          <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
            {planAheadActions[2].name}
          </Typography>
        </div>
      )}
      {values.plan_ahead_type?.id === 3 && (
        <div className='d-flex gap-2'>
          <ArrowLeft className='ms-4' />
          <div>
            <div className='mb-4'>
              <Autocomplete
                width='300px'
                required
                label='Select Carrier'
                placeholder='Select Carrier'
                name='carrier'
                options={carriers}
                value={values.carrier}
                onChange={(e, value) => handleChange('carrier', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors.carrier}
              />
            </div>
            <div className='mb-4'>
              <Autocomplete
                width='300px'
                required
                label='Select Contact'
                placeholder='Select Contact'
                name='carrier_contact_user'
                options={carrierContactBook}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={values.carrier_contact_user}
                getOptionLabel={(option) => `${option.contact_type?.title} - ${option.contact_name}`}
                onChange={(e, value) => handleChange('carrier_contact_user', value)}
                error={touchedErrors.carrier_contact_user}
              />
            </div>
          </div>
        </div>
      )}
      {[2, 3].includes(values.plan_ahead_type?.id) && (
        <div className='ms-5'>
          <div className='mb-4'>
            <Input
              required
              width='200px'
              label='Set Flat Rate'
              name='flat_rate'
              value={values.flat_rate}
              onChange={(e) => handleChange('flat_rate', formatInputNumber(e.target.value))}
              onKeyDown={blockNotNumberChars}
              error={touchedErrors.flat_rate}
            />
          </div>
          <div className='mb-4'>
            <InputLabel required>Starting Location</InputLabel>
            {values.plan_ahead_type?.id !== 3 && (
              <Radio
                name='start_from_type'
                checked={values.start_from_type === 1}
                onChange={() => handleChange('start_from_type', 1)}
                label='Previous Shipment'
              />
            )}
            <div className='d-flex align-items-center gap-3 my-2'>
              {values.plan_ahead_type?.id !== 3 && (
                <Radio
                  name='start_from_type'
                  checked={values.start_from_type === 2}
                  onChange={() => handleChange('start_from_type', 2)}
                  label='Custom Location'
                />
              )}
              <div>
                <AddressAutocomplete
                  width='300px'
                  required
                  placeholder='Search Custom Location'
                  onSuccess={(data) => onAddressSelect(data)}
                  changeAddress={(data) => changeAddress(data)}
                  onChange={(e) => handleChange('location', e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  value={values.location}
                  options={{
                    types: ['geocode', 'establishment'],
                    componentRestrictions: { country: ['us', 'ca', 'mx'] },
                  }}
                  error={touchedErrors.location_lat || touchedErrors.location_lng}
                  disabled={values.start_from_type !== 2}
                />
              </div>
            </div>
          </div>

          <Typography variant='h5'>Dispatch Options</Typography>
          <div className='d-flex align-items-center gap-2 my-2'>
            <CustomCheckbox
              smail={false}
              type='switch'
              checked={values.immediately}
              name='immediately'
              onChange={(checked) => {
                if (!checked && !values.immediately && !values.in_future && !values.before_scheduled_pickup) {
                  return;
                }
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    in_future: false,
                    next_in_line: false,
                    before_scheduled_pickup: false,
                    immediately: checked,
                  }));
                } else {
                  handleChange('immediately', checked);
                }
              }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Dispatch to {values.plan_ahead_type?.id === 2 ? 'Driver' : 'Carrier'} Immediately
            </Typography>
          </div>
          <div className='d-flex align-items-center gap-2 my-2'>
            <CustomCheckbox
              smail={false}
              type='switch'
              checked={values.next_in_line}
              name='next_in_line'
              onChange={(checked) => {
                if (!checked && !values.immediately && !values.in_future && !values.before_scheduled_pickup) {
                  return;
                }
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    immediately: false,
                    in_future: false,
                    before_scheduled_pickup: false,
                    next_in_line: checked,
                  }));
                } else {
                  handleChange('next_in_line', checked);
                }
              }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Pending Dispatch as Next Shipment in Sequential Date Order
            </Typography>
          </div>
          <div className='d-flex align-items-center gap-2 my-2'>
            <CustomCheckbox
              smail={false}
              type='switch'
              checked={values.before_scheduled_pickup}
              name='before_scheduled_pickup'
              onChange={(checked) => {
                if (!checked && !values.immediately && !values.in_future && !values.before_scheduled_pickup) {
                  return;
                }
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    in_future: false,
                    next_in_line: false,
                    immediately: false,
                    before_scheduled_pickup: checked,
                  }));
                } else {
                  handleChange('before_scheduled_pickup', checked);
                }
              }}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Pending Dispatch Queue for Dispatch
            </Typography>
            <div>
              <Input
                width='70px'
                type='number'
                placeholder='0'
                name='before_scheduled_pickup_hours'
                value={values.before_scheduled_pickup_hours}
                onChange={handleChange}
              />
            </div>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Hour(s) Prior to Scheduled Pickup
            </Typography>
          </div>
          {customerContactBook && values.plan_ahead_type?.id === 2 && (
            <div className='d-flex align-items-center gap-2 my-2'>
              <CustomCheckbox
                smail={false}
                type='switch'
                checked={values.send_trucking_link_to_customer}
                name='send_trucking_link_to_customer'
                onChange={(checked) => handleChange('send_trucking_link_to_customer', checked)}
              />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Send Tracking Updates to Customer Contact
              </Typography>
              <div>
                <Autocomplete
                  width='200px'
                  placeholder='Select Contact'
                  name='recipient_customer_contact_book'
                  options={customerContactBook}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={values.recipient_customer_contact_book}
                  getOptionLabel={(option) => `${option.contact_type?.title} - ${option.contact_name}`}
                  onChange={(e, value) => handleChange('recipient_customer_contact_book', value)}
                  error={touchedErrors.recipient_customer_contact_book}
                />
              </div>
            </div>
          )}
          {values.plan_ahead_type?.id === 2 && (
            <div className='d-flex align-items-center gap-2 my-2'>
              <CustomCheckbox
                smail={false}
                type='switch'
                checked={values.send_pickup_reminder_to_driver}
                name='send_pickup_reminder_to_driver'
                onChange={(checked) => {
                  if (!smsSettings?.send_sms) {
                    showToaster({
                      type: 'error',
                      message:
                        'Send SMS option is disabled for this account, please enable it from settings before using this option!',
                    });
                    return;
                  }
                  handleChange('send_pickup_reminder_to_driver', checked);
                }}
              />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Send Driver SMS Reminder
              </Typography>
              <div>
                <Input
                  width='80px'
                  type='number'
                  placeholder='0'
                  name='send_pickup_reminder_to_driver_before_minutes'
                  value={values.send_pickup_reminder_to_driver_before_minutes}
                  onChange={handleChange}
                />
              </div>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Minute(s) Prior to Scheduled Pickup
              </Typography>
            </div>
          )}
        </div>
      )}
      {values.plan_ahead_type?.id === 2 && (
        <div className='d-flex align-items-center gap-2 mb-2'>
          <CustomCheckbox
            type='switch'
            name='plan_ahead_type'
            checked={values.plan_ahead_type?.id === 3}
            onChange={(checked) => handleChange('plan_ahead_type', checked ? planAheadActions[2] : null)}
            smail={false}
          />
          <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
            {planAheadActions[2].name}
          </Typography>
        </div>
      )}
      <div className='d-flex align-items-center gap-2 mb-2'>
        <CustomCheckbox
          type='switch'
          name='plan_ahead_type'
          checked={values.plan_ahead_type?.id === 4}
          onChange={(checked) => handleChange('plan_ahead_type', checked ? planAheadActions[3] : null)}
          smail={false}
        />
        <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
          {planAheadActions[3].name}
        </Typography>
      </div>
      {values.plan_ahead_type?.id === 4 && (
        <SWrapper>
          <ArrowLeft className='ms-4' />
          <div className='form-wrapper'>
            <div className='d-flex flex-wrap align-items-end gap-3'>
              <div>
                <Autocomplete
                  multiple
                  required
                  width='420px'
                  limitTags={2}
                  label='Select Drivers'
                  disableClearable={false}
                  placeholder={values.drivers?.length ? '' : 'Select Drivers'}
                  name='drivers'
                  options={offerDrivers?.length ? [{ id: 'all', name: 'Select All' }, ...offerDrivers] : []}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id ||
                    (option.name === 'Select All' && offerDrivers?.length === values.drivers.length)
                  }
                  value={values.drivers}
                  onChange={handleDriversChange}
                  error={touchedErrors.drivers}
                />
              </div>
              <Typography variant='s2' style={{ color: palette.gray700 }} className='mb-2'>
                with
              </Typography>
              <InputWithText
                width='80px'
                text='miles'
                name='radius'
                type='number'
                onKeyDown={blockNonPositiveNumbers}
                onChange={handleChange}
                value={values.radius}
                placeholder='0'
              />
              <Typography variant='s2' style={{ color: palette.gray700 }} className='mb-2'>
                radius and offer to
              </Typography>
              <Autocomplete
                multiple
                required
                width='280px'
                name='driver_status'
                placeholder={values.driver_status?.length ? '' : 'Select..'}
                options={driverStatuses}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={values.driver_status}
                onChange={(e, val) => handleChange('driver_status', val)}
                error={touchedErrors.driver_status}
              />
              {values.driver_status.some((i) => i.id === 2) && (
                <>
                  <InputWithText
                    width='80px'
                    text='hours'
                    type='number'
                    name='next_available_prior_to_pickup'
                    value={values.next_available_prior_to_pickup}
                    onChange={handleChange}
                    error={touchedErrors.next_available_prior_to_pickup}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                  <Typography variant='s2' style={{ color: palette.gray700 }} className='mb-2'>
                    prior to schedule pickup.
                  </Typography>
                </>
              )}
            </div>
            <div>
              <Autocomplete
                multiple
                required
                width='420px'
                limitTags={2}
                label='Select Carriers'
                disableClearable={false}
                placeholder={values.carriers?.length ? '' : 'Select Carriers'}
                name='carriers'
                options={carriers?.length ? [{ id: 'all', name: 'Select All' }, ...carriers] : []}
                value={values.carriers}
                onChange={handleCarriersChange}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id ||
                  (option.name === 'Select All' && carriers?.length === values.carriers.length)
                }
                error={touchedErrors.carriers}
              />
            </div>
            <div className='mt-4'>
              <Typography variant='h5'>Offer Options</Typography>
            </div>
            <div className='d-flex gap-4'>
              <InputWithIcon
                required
                label='Offer Amount'
                name='offer_amount'
                value={values.offer_amount}
                onChange={(e) => handleChange('offer_amount', formatInputNumber(e.target.value))}
                onKeyDown={blockNotNumberChars}
                error={touchedErrors.offer_amount}
              />
              <div>
                <InputLabel>Margin</InputLabel>
                <Typography
                  variant='b2'
                  style={{
                    color:
                      !percent || percent === 0 ? palette.gray700 : percent > 0 ? palette.green500 : palette.red500,
                  }}
                >
                  {percent !== null ? (
                    <>
                      {percent < 0 ? '-' : percent > 0 ? '+' : ''}
                      {formatNumber(Math.abs(percent))}%
                    </>
                  ) : (
                    '-'
                  )}
                </Typography>
              </div>
            </div>
            <Textarea
              width='500px'
              rows={3}
              label='Note'
              name='note'
              placeholder='Enter notes for carrier..'
              value={values.note}
              onChange={handleChange}
              error={touchedErrors.note}
            />
            <div className='form-field-row'>
              <Typography variant='s2'>Expire Shipment Offer After</Typography>
              <SMergedInputs>
                <Input
                  width='120px'
                  type='number'
                  name='expire_shipment_offer'
                  value={values.expire_shipment_offer}
                  onChange={handleChange}
                  error={touchedErrors.expire_shipment_offer}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                />
                <Autocomplete
                  width='100px'
                  name='expire_shipment_offer_unit'
                  options={timeOptions}
                  value={values.expire_shipment_offer_unit}
                  onChange={(e, val) => handleChange('expire_shipment_offer_unit', val)}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  error={touchedErrors.expire_shipment_offer_unit}
                />
              </SMergedInputs>
            </div>
            {!!hasBrokerage && (
              <div className='form-field-row'>
                <Typography variant='s2'>Retract Carrier Award if Not Confirmed After</Typography>
                <SMergedInputs>
                  <Input
                    width='120px'
                    name='retract_carrier_award'
                    value={values.retract_carrier_award}
                    onChange={handleChange}
                    error={touchedErrors.retract_carrier_award}
                    onKeyDown={(e) => {
                      blockNotNumberChars(e);
                      blockNonPositiveNumbers(e);
                    }}
                  />
                  <Autocomplete
                    width='100px'
                    name='retract_carrier_award_unit'
                    options={timeOptions}
                    value={values.retract_carrier_award_unit}
                    onChange={(e, val) => handleChange('retract_carrier_award_unit', val)}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    error={touchedErrors.retract_carrier_award_unit}
                  />
                </SMergedInputs>
              </div>
            )}
            {!!hasBrokerage && (
              <div className='form-field-row'>
                <Typography variant='s2'>Auto Award First Lowest Amount After</Typography>
                <SMergedInputs>
                  <Input
                    width='120px'
                    name='auto_award_first_lowest_amount'
                    value={values.auto_award_first_lowest_amount}
                    onChange={handleChange}
                    error={touchedErrors.auto_award_first_lowest_amount}
                    disabled={values.auto_award_first_lowest_amount_unit?.value === null}
                    onKeyDown={(e) => {
                      blockNotNumberChars(e);
                      blockNonPositiveNumbers(e);
                    }}
                  />
                  <Autocomplete
                    width='100px'
                    name='auto_award_first_lowest_amount_unit'
                    options={autoAwardTimeOptions}
                    value={values.auto_award_first_lowest_amount_unit}
                    onChange={(e, val) => {
                      handleChange('auto_award_first_lowest_amount_unit', val);
                      if (!val?.value) {
                        handleChange('require_minimum_margin', false);
                        handleChange('require_minimum_margin_percent', '');
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    error={touchedErrors.auto_award_first_lowest_amount_unit}
                  />
                </SMergedInputs>
              </div>
            )}
            {!!hasBrokerage && (
              <div className='form-field-row'>
                <CustomCheckbox
                  name='require_minimum_margin'
                  type='switch'
                  smail={false}
                  checked={values.require_minimum_margin}
                  onChange={(checked) => handleChange('require_minimum_margin', checked)}
                  disabled={!values.auto_award_first_lowest_amount_unit?.value}
                />
                <Typography variant='s2'>Require Minimum Margin</Typography>
                <InputWithIcon
                  icon='%'
                  left={false}
                  width='120px'
                  name='require_minimum_margin_percent'
                  value={values.require_minimum_margin_percent}
                  onChange={handleChange}
                  error={touchedErrors.require_minimum_margin_percent}
                  disabled={!values.require_minimum_margin}
                  onKeyDown={blockNonPositiveNumbers}
                />
              </div>
            )}
            <div className='form-field-row'>
              <CustomCheckbox
                name='auto_repost'
                type='switch'
                smail={false}
                checked={values.auto_repost}
                onChange={(checked) => handleChange('auto_repost', checked)}
              />
              <Typography variant='s2'>Auto Repost After Offer is Expired</Typography>
            </div>
            {!!hasBrokerage && (
              <div className='form-field-row'>
                <CustomCheckbox
                  name='auto_dispatch'
                  type='switch'
                  smail={false}
                  checked={values.auto_dispatch}
                  onChange={(checked) => handleChange('auto_dispatch', checked)}
                />
                <Typography variant='s2'>Auto Dispatch After Award is Accepted</Typography>
              </div>
            )}
          </div>
        </SWrapper>
      )}
    </div>
  );
};

export default PlanAhead;
