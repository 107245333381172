import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;

  .carriers-table {
    width: calc(100% - 400px);
  }

  .offer-options {
    width: 400px;
  }
`;

export const SCarrierNameImage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .carrier-image {
    border-radius: 50%;
    object-fit: cover;
  }
`;
