import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useFormik } from 'formik';
import './OfferVehicle.css';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import down from 'assets/icons/drivers/down.svg';
import headerIcon from 'assets/icons/vehicles/green.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/expand.svg';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ShipmentIcon } from 'assets/icons/vehicles/shipment.svg';

import UserInfo from 'components/UserInfo';
import CustomTooltip from 'common/Tooltip';
import { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import CustomModal from 'components/CustomModal/CustomModal';
import AddEditModalDetails from 'components/AddEditModalStops';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import OfferVehicleMap from 'components/PlanToVehicle/OfferVehicleMap';
import classes from 'components/TableShipments/detailsRow/details.module.css';
import styles from 'components/OpenDocumentModal/OpenDocumentModal.module.css';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { SkeletonStops } from 'components/TableShipments/detailsRow/steps/OverView/OverView';
import OverViewStop from 'components/TableShipments/detailsRow/steps/OverView/OverViewStops';
import SettingsModal from 'components/TableShipments/detailsRow/steps/OverView/SettingsModal';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import {
  deleteOffer,
  deleteSingleOffer,
  driverAccept,
  getContactsBillingCharges,
  getCostCalculation,
  getFullPrices,
  getOfferState,
  getShipmentStops,
  offerShipment,
  shipmentDispatch,
} from 'Api/Planner';
import { UpdateStopPoint } from 'Api/StopPoint';
import { checkAddOnStatus } from 'Api/Equipment';
import { addShipmentStop, getShipmentInfo, getStops, updateCargo, UpdateStopPointStop } from 'Api/Shipment';
import useForm from 'hooks/useForm';
import { getAlphabet, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { formatAmount } from 'services/IFTAServices';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import { getShipmentDispatchSettings, getSMSCommunicationSettings } from 'Api/CompanySettings';
import { blockNonPositiveNumbers, blockNotNumberChars, getShipmentType, removeCommas } from 'utils/helpers';
import { updateRowEditItem } from 'store/reducers/root.reducer';
import BulkOffer from 'componentsV2/Planner/BulkOffer';
import { SOfferVehicle } from 'components/OfferVehicle/OfferVehicle.styles';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { SimpleLoader } from 'common/Loader';
import {
  createCargoConverter,
  createCargoFormDataConverter,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { DateTimePicker } from 'common/Pickers';
import Row from './Row';
import LocationSelect from './LocationSelect';
import { getPlannerStatus } from '../TableShipments/helpers/constants';
import TableRowsEdit from '../TableRowsEdit';
import { OFFER_TO_VEHICLE, PLAN_TO_VEHICLE_CANCELED } from '../CreateShipment/ShipmentStops/helpers/constants';
import TablePlannerRowEditChildes from '../TablePlaner/helpers/TablePlannerRowEditChildes';
import AlreadyDispatchedModal from '../PlanToVehicle/AlreadyDispatchedModal';

export default function OfferVehicle() {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shipmentId = searchParams.get('id');
  const equipmentId = searchParams.get('equipment');
  const selectList = [
    { name: 'Current Location', id: 1 },
    { name: 'Previous Shipment', id: 2 },
    { name: 'Planned Shipment', id: 3 },
    { name: 'Custom Location', id: 4 },
  ];
  const { currency } = useSelector((state) => state.root);
  const [routeSelect, setRouteSelect] = useState('practical');
  const [locationSelect] = useState('Current Location');
  const [customerContactBook, setCustomerContactBook] = useState([]);
  const [previousShipment, setPreviousShipment] = useState(null);
  const [customLatLong, setCustomLatLong] = useState(null);
  const [planedShipment, setPlanedShipment] = useState(null);
  const [data, setData] = useState(null);
  const [checked, setChecked] = useState(null);
  const [shipmentStops, setShipmentStops] = useState(null);
  const [offerState, setOfferState] = useState(null);
  // const [canGoBack, setCanGoBack] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [fuelPrice, setFuelPrice] = useState(null);
  const [legs, setLegs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableShipmentModalObj, setTableShipmentModalObj] = useState({});
  const [selectModalType, setSelectModalType] = useState({});
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState('');
  const [isOpen, setIsOpen] = useState({});
  const [infoHeader, setInfoHeader] = useState({});
  const [showLicence, setShowLicence] = useState(false);
  const [smsSettings, setSmsSettings] = useState(null);
  const [alreadyDispatched, setAlreadyDispatched] = useState(null);
  const [postedRate, setPostedRate] = useState('');
  const [loadingRevert, setLoadingRevert] = useState(false);
  const hasBrokerage = useHasBrokerage();

  const legsStopsCount = [];

  const orderingInLegsStops = useCallback((data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    return legsStopsData;
  }, []);
  const sendAmountFormik = useFormik({
    initialValues: {
      amount: '',
    },
  });

  const { values, handleChange, setValues } = useForm({
    initialValues: {
      immediately: true,
      in_future: false,
      future_date: null,
      after_specific_shipment_complated: false,
      before_scheduled_pickup: false,
      before_scheduled_pickup_hours: '0',
      send_trucking_updates_to_customer: false,
      send_trucking_link_to_customer: false,
      recipient_customer_contact_book_id: '',
      send_pickup_reminder_to_driver: false,
      send_pickup_reminder_to_driver_before_minutes: '30',
    },
  });

  const { values: mapValues, handleChange: handleMapValuesChange } = useForm({
    initialValues: {
      route_type: searchParams.get('route_type') || 'practical',
      hwy_only: searchParams.get('hwy_only') ? 1 : 0,
      open_borders: searchParams.get('open_borders') ? 1 : 0,
      haz_mat: searchParams.get('haz_mat') ? 1 : 0,
      avoid_tolls: searchParams.get('avoid_tolls') ? 1 : 0,
      show_traffic: searchParams.get('show_traffic') ? 1 : 0,
      show_weather: searchParams.get('show_weather') ? 1 : 0,
      vehicle_type: searchParams.get('vehicle_type') ? 1 : 0,
    },
  });

  const handleRouteSelect = (route) => {
    setRouteSelect(route);
    handleMapValuesChange('route_type', route);
  };

  const revertOffers = async () => {
    try {
      setLoadingRevert(true);
      const params = { shipment_id: shipmentId };
      await deleteOffer(params);
      setOfferState([]);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingRevert(false);
    }
  };

  const revertOfferForVehicle = async (id) => {
    try {
      await deleteSingleOffer(id, { offer_type: 'driver' });
      const { data } = await getOfferState(shipmentId, { offer_type: 'driver' });
      setOfferState(data);
      if (!offerState?.length) {
        // setCanGoBack(true);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getHeaderInfo = () => {
    getShipmentInfo(shipmentId)
      .then((res) => {
        if (res.data) {
          // handleMapValuesChange('open_borders', res.data.shipment_route?.open_borders);
          // handleMapValuesChange('hwy_only', res.data.shipment_route?.highway_only);
          // handleMapValuesChange('haz_mat', res.data.shipment_route?.hazmat_id);
          // handleMapValuesChange('route_type', res.data.shipment_route?.route_type);
          // handleMapValuesChange('vehicle_type', res.data.shipment_route?.vehicle_type);
          // handleMapValuesChange('avoid_tolls', res.data.shipment_route?.avoid_tolls);
          setRouteSelect(res.data.shipment_route?.route_type);
          setInfoHeader(res.data);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onDispatch = () => {
    if (
      !values.immediately &&
      !values.in_future &&
      !values.before_scheduled_pickup &&
      !values.after_specific_shipment_complated
    ) {
      showToaster({ type: 'error', message: 'One of the dispatch options should be selected!' });
      return;
    }

    const assign_driver_from = selectList?.find((item) => item.name === locationSelect).id;
    const { estimated_fuel_cost, net_profit } = checked;
    const params = {
      shipment_id: shipmentId,
      equipment_id: checked.equipment_id,
      assign_driver_from,
      estimated_fuel_cost,
      net_profit,
      route_type: mapValues.route_type,
      hwy_only: Number(mapValues.hwy_only),
      open_borders: Number(mapValues.open_borders),
      avoid_tolls: Number(mapValues.avoid_tolls),
      haz_mat: Number(mapValues.haz_mat),
      driver1_amount: checked.offer_amount,
      flat: 1,
      company_acceptance: 1,
      immediately: Number(values.immediately),
      in_future: Number(values.in_future),
      send_trucking_link_to_customer: Number(values.send_trucking_link_to_customer),
      before_scheduled_pickup: Number(values.before_scheduled_pickup),
      after_specific_shipment_complated: Number(values.after_specific_shipment_complated),
      future_date: values.future_date,
      before_scheduled_pickup_hours: values.before_scheduled_pickup_hours,
      recipient_customer_contact_book_id: values.recipient_customer_contact_book_id,
      send_pickup_reminder_to_driver: Number(values.send_pickup_reminder_to_driver),
      send_pickup_reminder_to_driver_before_minutes: Number(values.send_pickup_reminder_to_driver_before_minutes),
      assign_driver_from_shipment:
        assign_driver_from === 2
          ? previousShipment?.shipment_id
          : assign_driver_from === 3
          ? planedShipment?.shipment_id
          : undefined,
      assign_driver_from_shipment_latitude:
        assign_driver_from === 4 && customLatLong ? customLatLong?.latitude : undefined,
      assign_driver_from_shipment_longitude:
        assign_driver_from === 4 && customLatLong ? customLatLong?.longitude : undefined,
      lane_id: infoHeader.lane_id,
    };
    const acceptParams = {
      shipment_id: shipmentId,
      driver_id: checked.equipment?.drivers[0]?.id,
    };
    driverAccept(acceptParams)
      .then(() => {
        shipmentDispatch(params)
          .then(() => {
            navigate(`/shipment/${shipmentId}/dispatch`, { state: { fromDispatch: true } });
          })
          .catch(() => {
            // Do nothing
          });
      })
      .catch(() => {
        // Do nothing
      });
  };

  const style = useMemo(() => {
    return {
      mapWrapper: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        height: '726px',
      },
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        borderRadius: '8px 0 0 8px',
      },
    };
  }, []);

  useEffect(() => {
    const assign_driver_from = selectList?.find((item) => item.name === locationSelect).id;
    const params = {
      shipment_id: shipmentId,
      assign_driver_from,
      route_type: mapValues.route_type,
      hwy_only: mapValues.hwy_only ? 1 : 2,
      open_borders: mapValues.open_borders ? 1 : 2,
      avoid_tolls: mapValues.avoid_tolls ? 1 : 2,
      assign_driver_from_shipment:
        assign_driver_from === 2 && previousShipment?.shipment_id
          ? previousShipment?.shipment_id
          : assign_driver_from === 3 && planedShipment?.shipment_id
          ? planedShipment?.shipment_id
          : undefined,
    };
    if (equipmentId) {
      equipmentId.split(',').forEach((el, index) => {
        params[`equipments[${index}]`] = el;
      });

      getCostCalculation(params)
        .then((res) => {
          if (res.data && res.data[0]) {
            setData(res);
          }
        })
        .catch((err) => {
          // Do nothing
          if (err?.response?.data?.response?.shipment_id) {
            getShipmentInfo(shipmentId)
              .then((res) => {
                const { dispatched_by_user } = res.data || {};
                if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                  setAlreadyDispatched(
                    `${dispatched_by_user.first_name || ''} ${
                      dispatched_by_user.last_name || ''
                    } has already dispatched this shipment, we are now exiting the dispatch process!`
                  );
                }
              })
              .catch(() => {
                // Do nothing
              });
          }
        });
    } else {
      getOfferState(shipmentId)
        .then((res) => {
          setOfferState(res.data);
          // setCanGoBack(false);
          const equipments = res.data.map((item) => item.equipment_id).join();
          navigate(`/planner/offer?id=${shipmentId}&equipment=${equipments}`, { replace: true });
          // setData(res)
        })
        .catch(() => {
          // Do nothing
        });
    }
  }, [shipmentId, equipmentId, mapValues, locationSelect]);

  const getShipmentOffers = async () => {
    try {
      const { data } = await getOfferState(shipmentId);
      setOfferState(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getShipmentOffers();

    getShipmentStops(shipmentId).then((res) => {
      setShipmentStops(res.data);
    });

    const intervalId = setInterval(() => {
      getOfferState(shipmentId)
        .then((res) => {
          setOfferState(res.data);
          if (!res.data?.length) {
            // setCanGoBack(true);
            setChecked(null);
          }
        })
        .catch(() => {
          // Do nothing
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getFullPriceShipment();
  }, [data]);

  useEffect(() => {
    if (shipmentStops?.shipment_billing[0]?.billing_customer?.id) {
      getContactsBillingCharges(shipmentStops?.shipment_billing[0]?.billing_customer?.id).then((res) => {
        setCustomerContactBook(res.data);
      });
    }
  }, [shipmentStops?.shipment_billing[0]?.billing_customer?.id]);

  const offer = (data, value) => {
    const offer_date = moment.utc(new Date()).format('YYYY/MM/DD');
    const offer_time = moment.utc(new Date()).format('HH:mm');
    const params = {
      shipment_id: shipmentId,
      equipment_id: data.equipment_id,
      offer_date,
      offer_time,
      offer_amount: value,
      empty_miles: data.empty_miles,
    };
    offerShipment([params])
      .then(() => {
        // setCanGoBack(false);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const submitAmountModal = () => {
    if (sendAmountFormik.values.amount) {
      const offer_date = moment.utc(new Date()).format('YYYY/MM/DD');
      const offer_time = moment.utc(new Date()).format('HH:mm');
      const body = selectedRows.map((item) => {
        return {
          shipment_id: shipmentId,
          equipment_id: item.equipment_id,
          offer_date,
          offer_time,
          offer_amount: Number(sendAmountFormik.values.amount),
          empty_miles: item.empty_miles,
        };
      });

      offerShipment(body)
        .then(() => {
          // setCanGoBack(false);
          setOpenModal(false);
          setSelectedRows([]);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const sendOffer = async (watchBids) => {
    try {
      const offer_date = moment.utc(new Date()).format('YYYY/MM/DD');
      const offer_time = moment.utc(new Date()).format('HH:mm');
      const body = selectedRows.map((item) => {
        return {
          shipment_id: shipmentId,
          equipment_id: item.equipment_id,
          offer_date,
          offer_time,
          offer_amount: Number(removeCommas(postedRate)),
          empty_miles: item.empty_miles,
        };
      });
      await offerShipment(body);
      if (watchBids) {
        navigate(`/offered-shipments/${shipmentId}`, { replace: true });
      }
      setSelectedRows([]);
    } catch (e) {
      // Do nothing
    }
  };

  const getFullPriceShipment = () => {
    return getFullPrices().then((res) => {
      if (res && res?.data) {
        setFuelPrice(res.data?.diesel?.price);
      }
      return res;
    });
  };

  const getSmsSettings = async () => {
    try {
      const { data } = await getSMSCommunicationSettings();
      setSmsSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDispatchSettings = async () => {
    try {
      const { data } = await getShipmentDispatchSettings();
      if (data) {
        setValues((prevState) => ({
          ...prevState,
          immediately: !!data.immediately,
          after_specific_shipment_complated: !!data.dispatch_next_in_line,
          before_scheduled_pickup: !!data.dispatch_driver_hours_before_scheduled,
          before_scheduled_pickup_hours: data.dispatch_driver_hours_before_scheduled || '0',
          send_trucking_link_to_customer: !!data.send_tracking_updates,
          send_pickup_reminder_to_driver: !!data.send_driver_reminder,
          send_pickup_reminder_to_driver_before_minutes: data.send_driver_reminder,
        }));
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getStopsData();
    getHeaderInfo();
    getSmsSettings();
    getDispatchSettings();
  }, []);

  const getStopsData = () => {
    setLoading(true);
    getStops({ shipment_id: shipmentId })
      .then((res) => res?.data && setLegs(orderingInLegsStops(res?.data)))
      .finally(() => setLoading(false));
  };

  function onClickItemSettingsIcon(stop) {
    setIsOpenSettingsModal(stop?.stop_point?.id);
  }

  const onUpdateSuccess = () => {
    getHeaderInfo();
    getStopsData();
  };

  const onAddEditModalDetails = (values, type, item) => {
    delete values?.id;
    if (type === 'edit') {
      const formData = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (key === 'to' || (val !== '' && !['arrival_time', 'departure_time'].includes(key))) {
          if (key === 'dimensions' && val?.l && val?.h && val?.w) {
            formData.append(`dimensions[l]`, val?.l || '');
            formData.append(`dimensions[h]`, val?.h || '');
            formData.append(`dimensions[w]`, val?.w || '');
          } else if (key === 'arrival_date') {
            if (val) {
              formData.append(
                'arrival_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.arrival_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'departure_date') {
            if (val) {
              formData.append(
                'departure_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.departure_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'bill_type') {
            formData.append('bill_type', values.bill_type ? '1' : '2');
          } else if (key === 'scheduled_date') {
            formData.append('scheduled_date', moment(val).format('YYYY-MM-DD'));
          } else if (key === 'scheduled_date_to') {
            formData.append('scheduled_date_to', moment(val || values?.scheduled_date).format('YYYY-MM-DD'));
          } else if (key === 'from') {
            formData.append('from', moment(values?.from).format('HH:mm'));
          } else if (key === 'to') {
            formData.append('to', moment(val || values?.from).format('HH:mm'));
          } else if (key === 'reported_quantity_type' && !!val) {
            formData.append(
              'reported_quantity_type',
              values?.reported_quantity_type?.id || values?.reported_quantity_type
            );
          } else if (key === 'reported_weight_type' && !!val) {
            formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
          } else if (key === 'connected_cargos' && val?.length) {
            val.forEach((cargo, index) => {
              formData.append(`connected_cargos[${index}]`, cargo.id);
            });
          } else if (key === 'cargos') {
            // Do nothing
          } else {
            let value = val;
            if (typeof val === 'boolean') {
              if (key === 'bill_type') {
                return;
              }
              value = val ? 1 : 0;
            }
            if (key === 'dimensions') {
              return;
            }
            formData.append(key, value);
          }
        }
      });
      updateStop(formData, item?.id);

      if (Number(values.stop_point_type) === 1 && values.cargos?.length === 1) {
        const body = createCargoConverter(values.cargos[0]);
        updateCargo(values.cargos[0].id, body);
      }
    } else {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'id') {
          return;
        }
        let value = values[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        if (key === 'bill_type') {
          formData.append('bill_type', !values.bill_type ? 1 : 2);
        }

        if (key === 'dimensions' && typeof value === 'object') {
          Object.keys(value).forEach((dimensionKey) => {
            formData.append(`dimensions[${dimensionKey}]`, value[dimensionKey] || '');
          });
        } else if (key === 'cargos') {
          const cargos = values.cargos?.length ? values.cargos.filter((i) => !!i?.commodity) : [];
          createCargoFormDataConverter(cargos, formData);
        } else if (key === 'scheduled_date') {
          formData.append('scheduled_date', moment(value).format('YYYY-MM-DD'));
        } else if (key === 'scheduled_date_to') {
          formData.append('scheduled_date_to', moment(value || values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'from') {
          formData.append('from', moment(values?.from).format('HH:mm'));
        } else if (key === 'to') {
          formData.append('to', moment(value || values?.from).format('HH:mm'));
        } else {
          formData.append(key, value);
        }
      });
      formData.append('shipment_id', infoHeader?.shipment_id);
      formData.append('leg', selectModalType.legIndex);
      formData.append('order_in_leg', selectModalType.order_in_leg);
      addShipmentStop(formData)
        .then(() => {
          getStopsData();
          getHeaderInfo();
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  function onSubmitShipmentRowEditChildes(data) {
    const ids = data.lastData.item.id;
    switch (ids) {
      case 13:
        navigate(`/shipment/${shipmentId}`, { state: { defaultTab: 5 } });
        break;
      case 125:
      case 17:
        navigate('/planner');
        break;
      default:
    }
  }

  function onClickHeaderOverLayOption(item) {
    switch (item?.id) {
      case 1:
        dispatch(updateRowEditItem({ ...infoHeader, label: 'Shipment' }));
        break;
      case 2:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 11:
      case 12:
      case 13:
        setTableShipmentModalObj({ item, rowData: infoHeader });
        break;
      case 20:
        navigate(`/planner/plan?id=${infoHeader.shipment_id}&action=dispatch`);
        break;
      case 46:
        navigate(`/shipment/${infoHeader.shipment_id}`);
        break;
      case 125:
      case 17:
        setTableShipmentModalObj({ item, rowData: [infoHeader] });
        break;
      default:
    }
  }

  const updateStop = (params, id) => {
    setLoading(true);
    UpdateStopPointStop({ params, id })
      .then(() => {
        getStopsData();
      })
      .finally(() => setLoading(false));
  };

  function onUpdateSettings(id, validateForm, resetForm, submitForm, values) {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        const backData = {};
        Object.keys(values).forEach((key) => {
          const value = values[key];
          if (typeof value === 'boolean') {
            backData[key] = value ? 1 : 0;
          } else {
            backData[key] = value;
          }
        });
        UpdateStopPoint(id, backData).then(() => setIsOpenSettingsModal(''));
      }
    });
  }

  const columns = [
    {
      field: '',
      title: (
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
          {!!((data?.data?.length || 0) - (offerState?.length || 0)) && (
            <CustomCheckbox
              labelStyle={{ marginTop: 0 }}
              checked={selectedRows.length === (data?.data?.length || 0) - (offerState?.length || 0)}
              onChange={() => {
                if (selectedRows.length === (data?.data?.length || 0) - (offerState?.length || 0)) {
                  setSelectedRows([]);
                } else {
                  const selectableRows = data?.data?.filter((item) => {
                    return !offerState?.some((offer) => offer.equipment_id === item.equipment_id);
                  });
                  setSelectedRows(selectableRows || []);
                }
              }}
            />
          )}
          {!hasBrokerage && !!selectedRows.length && (
            <CustomButton
              width={12}
              height={12}
              type='primary'
              title='Bulk Offer'
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={() => setOpenModal(true)}
            />
          )}
        </div>
      ),
      render: (rowData) => {
        const checked = selectedRows?.find((item) => item.equipment_id === rowData.equipment_id);

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <CustomCheckbox
              labelStyle={{ marginTop: 5 }}
              checked={!!checked}
              disabled={offerState?.some((offer) => offer.equipment_id === rowData.equipment_id)}
              onChange={() => {
                if (checked) {
                  const newData = selectedRows.filter((item) => item.equipment_id !== rowData.equipment_id);
                  setSelectedRows(newData);
                } else {
                  setSelectedRows((prevState) => [...prevState, rowData]);
                }
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Vehicle',
      field: 'VehicleID',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => (
        <div>
          <p className='shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
            {rowData?.equipment?.equipment_id}
          </p>
        </div>
      ),
    },
    {
      title: 'Trailer',
      field: 'Trailer',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
        minWidth: '200px',
      },
      render: (rowData) => (
        <div className='d-flex flex-column'>
          <div className='equipment-container d-flex align-items-center'>
            <span
              className='dispatch-details-text'
              style={{
                color: use(palette.gray700, palette.gray200),
              }}
            >
              {' '}
              {rowData?.equipment?.hooked_to?.equipment_number}
            </span>
            &nbsp;
            <span className='dispatch-details-text'> {rowData?.equipment?.hooked_to?.equipment_type?.title} </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Compensation',
      field: 'Compensation',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => (
        <div>
          {rowData?.equipment?.drivers.map((driver) => (
            <>
              {driver?.compensation_details?.pay_option === 'mile' && (
                <div className='d-flex flex-column'>
                  <Typography variant='s2'>Pay Per Mile</Typography>
                  <span>
                    {currency}
                    {formatAmount(driver?.compensation_details?.mile_loaded_amt)} Loaded
                  </span>
                  <span>
                    {currency}
                    {formatAmount(driver?.compensation_details?.mile_empty_amt)} Empty
                  </span>
                </div>
              )}
              {driver?.compensation_details?.pay_option === 'percentage' && (
                <div className='d-flex flex-column'>
                  <Typography variant='s2'>Percentage</Typography>
                  <span>{driver?.compensation_details?.shipment_percentage}% of Shipment</span>
                </div>
              )}

              {driver?.compensation_details?.pay_option === 'per_hour' && (
                <div className='d-flex flex-column'>
                  <Typography variant='s2'>Per Hour</Typography>
                  <span>
                    {currency}
                    {formatAmount(driver?.compensation_details?.per_hour_worked)} Per Hour
                  </span>
                </div>
              )}
              {driver?.compensation_details?.pay_option === 'flat_rate' && (
                <div className='d-flex flex-column'>
                  <Typography variant='s2'>Flat Rate</Typography>
                  <span>
                    {currency}
                    {formatAmount(driver?.compensation_details?.flat_rate_amount)}
                  </span>
                </div>
              )}
            </>
          ))}
        </div>
      ),
    },
    {
      title: 'Driver',
      field: 'Driver',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return rowData?.equipment?.drivers.map((d) => {
          return (
            <UserInfo
              data={{
                ...d,
                name: `${d.fname} ${d.lname}`,
                status: d.status_data?.driver_status,
                user_type: 'driver',
              }}
            />
          );
        });
      },
    },
    {
      title: 'Assign Driver From',
      field: 'AssignDriverFrom',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return <LocationSelect rowData={rowData} mapValues={mapValues} setOneEquipmentData={setOneEquipmentData} />;
      },
    },
    {
      title: 'Empty MIles',
      field: 'EmptyMIles',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {Number(rowData?.empty_miles).toFixed()}
          </p>
        );
      },
    },
    {
      title: 'Loaded Miles',
      field: 'LoadedMiles',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {Number(rowData?.loaded_miles).toFixed()}
          </p>
        );
      },
    },
    {
      title: 'ETA',
      field: 'ETA',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (rowData) => {
        return (
          <div>
            <p className='dispatch-details-text'>{rowData?.eta}</p>
          </div>
        );
      },
    },
    {
      title: 'Offer Amount',
      field: 'Cost',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (row) => {
        const matchingOffer = offerState?.find((item) => item.equipment_id === row.equipment_id);
        return hasBrokerage ? (
          <div>
            <InputWithIcon
              size='small'
              width='120px'
              value={
                matchingOffer
                  ? matchingOffer.offer_amount
                  : selectedRows?.some((i) => i.id === row.id)
                  ? postedRate
                  : '' || ''
              }
              error={!!row.costError && 'Required'}
              onKeyDown={blockNotNumberChars}
              disabled
            />
          </div>
        ) : (
          <Row rowData={row} offer={offer} offerState={offerState} revertOfferForVehicle={revertOfferForVehicle} />
        );
      },
    },
    ...(hasBrokerage
      ? [
          {
            field: 'action',
            render: (row) => {
              const offer = offerState?.find((item) => item.equipment_id === row.equipment_id);

              return (
                !!offer && (
                  <div className='d-flex align-items-center gap-2'>
                    <CustomButton
                      type='primary'
                      title={
                        <div className='d-flex'>
                          <SimpleLoader loading color={palette.white} size={16} />
                        </div>
                      }
                      styleButton={{ padding: '6px 12px', margin: 0 }}
                    />
                    <CustomButton
                      type='secondary'
                      styleButton={{ padding: '6px 12px', margin: 0 }}
                      title={
                        <CustomTooltip title='Revert Offer'>
                          <div className='d-flex'>
                            <RevertIcon width={16} height={18} />
                          </div>
                        </CustomTooltip>
                      }
                      onClick={() => revertOfferForVehicle(offer.id)}
                    />
                  </div>
                )
              );
            },
          },
        ]
      : []),
  ];

  const checkAddonStatus = (value, checked) => {
    if (checked) {
      checkAddOnStatus(2, checked.id)
        .then((res) => {
          if (res.is_addon_active) {
            handleMapValuesChange(value, checked);
          } else {
            setShowLicence(true);
          }
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong!' });
        });
    }
  };

  const setOneEquipmentData = (newData, previousShipment, planedShipment, customLatLong) => {
    const updatedData = data.data?.map((obj) => {
      if (obj.equipment_id === newData.equipment_id) {
        return newData;
      }
      return obj;
    });
    setData({ data: updatedData });
    setPreviousShipment(previousShipment);
    setPlanedShipment(planedShipment);
    setCustomLatLong(customLatLong);
  };

  const sumDurations = (durations) => {
    let totalMinutes = 0;
    for (const duration of durations) {
      let days = 0;
      let hours = 0;
      let minutes = 0;
      const parts = duration.split(' ');
      for (const part of parts) {
        if (part.endsWith('d')) {
          days = parseInt(part, 10);
        } else if (part.endsWith('h')) {
          hours = parseInt(part, 10);
        } else if (part.endsWith('m')) {
          minutes = parseInt(part, 10);
        }
      }
      totalMinutes += days * 24 * 60 + hours * 60 + minutes;
    }
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${days}d ${hours}h ${minutes}m`;
  };

  return (
    <SOfferVehicle className='profile-container'>
      {showLicence && (
        <div className='check-addon-dispatch' onClick={() => setShowLicence(false)}>
          You do not have this feature enabled.
          <span onClick={() => navigate('/billing?addonId=2&planId=34')}> Purchase Addon </span>
        </div>
      )}
      <div className='user-profile-header w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='dispatch-vehicle-container w-100'>
          <div className='plan-vehicle-text-wrapper'>
            <div
              onClick={() => navigate(`/planner/plan?id=${infoHeader.shipment_id}&action=offer`)}
              className='back_plan_to_vehicle'
            >
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className='back'>
                Go Back
              </Typography>
            </div>
          </div>
          {shipmentStops?.is_edi_api || shipmentStops?.manual_edi_api ? (
            <div className='dispatch-vehicle-text-wrapper'>
              <div className='d-flex'>
                <img src={headerIcon} alt='headerIcon' />
                <p className='dispatch-vehicle-text green-text'>EDI/API SHIPMENT </p>
              </div>
            </div>
          ) : null}

          <div className='d-flex '>
            <div className='dispatch-vehicle-details w-100'>
              <div className='dispatch-vehicle-details-item'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Offer to Vehicle
                </h2>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <Typography variant='overLine2' className='d-flex'>
                  <span className='bold-text'>{shipmentStops?.shipment_id}</span>
                  (REF:&nbsp;
                  {(shipmentStops?.shipment_billing && shipmentStops?.shipment_type !== 'ltl') ||
                  (shipmentStops?.shipment_type === 'ltl' && shipmentStops?.shipment_billing.length === 1) ? (
                    <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {shipmentStops?.shipment_billing?.[0]?.reference_id}
                    </Typography>
                  ) : (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id='button-tooltip-2'>
                          {shipmentStops?.shipment_billing?.map((el, i) => (
                            <p key={el.id || i} style={{ margin: 0 }}>
                              {el?.reference_id}
                            </p>
                          ))}
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div ref={ref}>
                          <Typography
                            variant='s3'
                            {...triggerHandler}
                            style={{ color: use(palette.dark800, palette.gray200) }}
                          >
                            Multi-Ref LTL
                          </Typography>
                        </div>
                      )}
                    </OverlayTrigger>
                  )}
                  )
                </Typography>
              </div>
              <div className='dispatch-vehicle-details-item'>
                {shipmentStops?.shipment_billing &&
                shipmentStops?.shipment_billing.length &&
                shipmentStops?.shipment_billing.length === 1 ? (
                  <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {shipmentStops?.shipment_billing?.[0]?.billing_customer?.company_name}&nbsp;
                    {shipmentStops.shipment_type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
                  </Typography>
                ) : shipmentStops?.shipment_billing && shipmentStops?.shipment_billing.length > 1 ? (
                  <OverLayTriggerHoverWrapper
                    overlay={shipmentStops?.shipment_billing.map((el, i) => (
                      <Typography
                        key={el.id || i}
                        as='p'
                        variant='s2'
                        style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/customer-profile/${el?.billing_customer?.id}`);
                        }}
                      >
                        {el?.billing_customer?.company_name}
                      </Typography>
                    ))}
                  >
                    <div>
                      <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                        Multi-Customer LTL
                      </Typography>
                    </div>
                  </OverLayTriggerHoverWrapper>
                ) : null}
              </div>
              {shipmentStops?.shipment_billing[0]?.groups && (
                <div className='dispatch-vehicle-details-item'>
                  <div
                    className='tablePlaner-shortName'
                    style={{
                      color: use(palette.gray900, palette.gray700),
                      backgroundColor: shipmentStops?.shipment_billing[0]?.groups?.color,
                    }}
                  >
                    {shipmentStops?.shipment_billing[0]?.groups?.short_name}
                  </div>
                </div>
              )}
              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>{shipmentStops?.shipment_stops_count} Stops</p>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <div className='d-flex justify-content-start}'>
                  <div className='tablePlaner_status-wrapper'>
                    <span
                      className='tablePlaner_status'
                      style={{
                        backgroundColor: getPlannerStatus(infoHeader)?.bgStatus,
                        color: getPlannerStatus(infoHeader)?.colorStatus,
                      }}
                    >
                      {getPlannerStatus(infoHeader)?.statusName}
                    </span>
                  </div>
                </div>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>
                  $ {formatAmount(shipmentStops?.shipment_billing[0]?.shipment_amount)} USD
                </p>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>
                  {checked
                    ? Number(checked.loaded_miles || 0).toFixed() + +checked.empty_miles.toFixed()
                    : Number(shipmentStops?.loaded_miles || 0).toFixed()}{' '}
                  Miles
                </p>
              </div>
              <div className='plan-vehicle-details-item'>
                <p className='plan-details-text'>{getShipmentType(infoHeader.shipment_type)}</p>
              </div>
              {infoHeader?.planned_duration && data?.data?.[0]?.shipment_drive_time && (
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text d-flex'>
                    Route Duration:{' '}
                    <span className='cost-purple-text ms-2'>
                      {sumDurations([
                        data.data[0].shipment_drive_time,
                        ...[
                          ...(infoHeader?.shipment_stops || []).map((item) =>
                            getAverageTimeConverted(item.stop_point?.average_waiting_time)
                          ),
                        ],
                      ])}
                    </span>
                  </p>
                  <Dropdown className='d-inline mx-2' autoClose='outside'>
                    <Dropdown.Toggle
                      id='dropdown-autoclose-outside'
                      style={{
                        border: 'none',
                        outline: 'none',
                        background: 'none',
                        boxShadow: 'none',
                        padding: 0,
                      }}
                    >
                      <img alt='down' src={down} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                      <Dropdown.Item style={{ color: use(palette.dark800, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span>Shipment Drive Time:</span>{' '}
                          <span className='cost-purple-text ms-2'>{data.data[0].shipment_drive_time}</span>
                        </p>
                      </Dropdown.Item>
                      {infoHeader?.shipment_stops?.map((item, index) => (
                        <Dropdown.Item key={item.id} style={{ color: use(palette.dark800, palette.gray200) }}>
                          <p className='dispatch-details-text d-flex justify-content-between'>
                            <span>Stop {getAlphabet(index)} - Avg. Wait Time:</span>{' '}
                            <span className='cost-purple-text ms-2'>
                              {getAverageTimeConverted(item.stop_point?.average_waiting_time)}
                            </span>
                          </p>
                        </Dropdown.Item>
                      ))}

                      <Dropdown.Item style={{ color: use(palette.dark500, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span style={{ fontWeight: 'bold' }}>Total Duration:</span>{' '}
                          <span className='cost-purple-text ms-2'>
                            {sumDurations([
                              data.data[0].shipment_drive_time,
                              ...[
                                ...(infoHeader?.shipment_stops || []).map((item) =>
                                  getAverageTimeConverted(item.stop_point?.average_waiting_time)
                                ),
                              ],
                            ])}
                          </span>
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {infoHeader?.scheduled_duration && (
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text d-flex'>
                    Scheduled Duration:{' '}
                    <span className='cost-purple-text ms-2'>
                      {getAverageTimeConverted(infoHeader.scheduled_duration)}
                    </span>
                  </p>
                </div>
              )}
              <div style={{ marginLeft: 'auto' }}>
                {!!Object.keys(infoHeader).length > 0 && (
                  <TableRowsEdit
                    placement='bottom-end'
                    overlayStyle={{ width: 200 }}
                    options={
                      infoHeader.canceled_at
                        ? infoHeader.shipment_type === 'ltl' ||
                          infoHeader.shipment_type === 'slave' ||
                          infoHeader.shipment_type === 'split-parent'
                          ? PLAN_TO_VEHICLE_CANCELED.filter((el) => +el.id !== 8)
                          : PLAN_TO_VEHICLE_CANCELED
                        : infoHeader.shipment_type === 'ltl' ||
                          infoHeader.shipment_type === 'slave' ||
                          infoHeader.shipment_type === 'split-parent'
                        ? OFFER_TO_VEHICLE.filter((el) => +el.id !== 8)
                        : OFFER_TO_VEHICLE
                    }
                    onClickOption={(e) => onClickHeaderOverLayOption(e)}
                    targetStyle={{ width: 20, height: 20 }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='plan-accordion'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowUp className='accordion-arrow' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          />
          <AccordionDetails>
            <div className='w-100 mt-4'>
              <div className={styles.leg_count_container}>
                {loading ? (
                  <SkeletonStops />
                ) : (
                  <>
                    {legs.map((leg, index) => {
                      legsStopsCount.push(leg.stops.length);
                      return (
                        <div key={leg.id} className={classes.legCountContainer}>
                          <OverViewStop
                            leg={leg}
                            legs={legs}
                            setLegs={setLegs}
                            indexPrefix={index}
                            setIsOpen={setIsOpen}
                            infoHeader={infoHeader}
                            legsStopsCount={legsStopsCount}
                            onSelectPlus={setSelectModalType}
                            onClickSettingsIcon={onClickItemSettingsIcon}
                            onUpdateSuccess={onUpdateSuccess}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className='w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='table-wrap'>
          <div className='sub-table-container-dispatch'>
            <div className='d-flex gap-4'>
              <div className='drivers-table'>
                {!!data && typeof data === 'object' && Object.keys(data).length > 0 ? (
                  <MaterialTableWrapper
                    columns={columns}
                    onRowClick={(event, row) => {
                      const accepted = offerState?.find((item) => item?.equipment_id === row.equipment_id);
                      const canSelect = accepted?.driver_acceptance === 1;
                      if (canSelect) {
                        const dispatchData = {
                          ...row,
                          offer_amount: accepted.offer_amount,
                        };
                        setChecked(dispatchData);
                      } else {
                        setChecked(null);
                      }
                    }}
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      borderTop: 'none',
                      width: '100%',
                      minHeight: 200,
                    }}
                    data={data?.data}
                    options={{
                      paging: false,
                      sorting: false,
                      toolbar: false,
                      rowStyle: (rowData) => {
                        const decliends = offerState?.filter((item) => item.driver_acceptance === 2);
                        const selected = checked?.equipment_id === rowData?.equipment_id;
                        return {
                          backgroundColor: decliends?.some((item) => item?.equipment_id === rowData?.equipment_id)
                            ? 'rgb(248,229,235)'
                            : selected
                            ? palette.green200
                            : '',
                          borderBottom: 'none',
                          width: '100%',
                        };
                      },

                      headerStyle: {
                        borderTop: 'none',
                        width: '100%',
                      },
                    }}
                  />
                ) : (
                  <Preloader />
                )}
              </div>
              <div className='offer-options'>
                <BulkOffer
                  shipment={infoHeader}
                  sendOffer={(values, watchBids) => sendOffer(watchBids)}
                  onSuccess={getShipmentOffers}
                  offersCount={selectedRows?.length}
                  readOnly={!!offerState?.length}
                  onPostedRateChange={setPostedRate}
                  revertAll={() => revertOffers()}
                  loadingRevert={loadingRevert}
                  offerTo='driver'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end mt-4'>
        <CustomButton
          width={12}
          height={12}
          disabled={!offerState?.length}
          type='secondary'
          title='Revert Offers'
          styleButton={{ padding: '6px 12px', marginRight: '18px' }}
          onClick={revertOffers}
        />
        <CustomButton
          width={12}
          height={12}
          type='primary'
          disabled={!checked}
          title='Dispatch Vehicle'
          leftIcon={<ShipmentIcon style={{ marginRight: 10 }} />}
          styleButton={{ padding: '6px 12px' }}
          onClick={onDispatch}
        />
      </div>
      <div className='dispatch-vehicle-content'>
        <div className='dispatch-vehicle-data'>
          <div>
            <form>
              <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Options
              </h6>
              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.immediately}
                  name='immediately'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('in_future', false);
                      handleChange('after_specific_shipment_complated', false);
                      handleChange('before_scheduled_pickup', false);
                    }
                    handleChange('immediately', checked);
                  }}
                  // disabled={values.in_future || values.after_specific_shipment_complated ||
                  //   values.before_scheduled_pickup}
                />
                <p className='dispatch-vehicle-check-text'>Dispatch to Driver Immediately</p>
              </div>
              <div className='with-border mt-2' />

              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.after_specific_shipment_complated}
                  name='after_specific_shipment_complated'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('in_future', false);
                      handleChange('before_scheduled_pickup', false);
                    }
                    handleChange('after_specific_shipment_complated', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text me-2'>
                  Pending Dispatch as Next Shipment in Sequential Date Order
                </p>
              </div>
              <div className='with-border mt-2' />

              <div className='d-flex align-items-center my-2 w-100'>
                <CustomCheckbox
                  type='switch'
                  checked={values.in_future}
                  name='in_future'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('after_specific_shipment_complated', false);
                      handleChange('before_scheduled_pickup', false);
                    }
                    handleChange('in_future', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text me-2'>Pending Dispatch Queue for Dispatch on</p>
                <DateTimePicker
                  width='190px'
                  name='future_date'
                  value={values.future_date}
                  onChange={(value) => handleChange('future_date', value)}
                  onBlur={(e) => {
                    const { value } = e.target;
                    if (value && moment(value).isValid() && moment(value).isBefore(moment())) {
                      handleChange('future_date', new Date());
                    }
                  }}
                  disablePast
                />
                <p className='dispatch-vehicle-check-text'>(Based on Specific Date/Time)</p>
              </div>
              <div className='with-border mt-2' />

              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.before_scheduled_pickup}
                  name='before_scheduled_pickup'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('in_future', false);
                      handleChange('after_specific_shipment_complated', false);
                    }
                    handleChange('before_scheduled_pickup', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text'>Pending Dispatch Queue for Dispatch</p>
                <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                  <input
                    type='number'
                    placeholder='0'
                    name='before_scheduled_pickup_hours'
                    value={values.before_scheduled_pickup_hours}
                    onChange={handleChange}
                  />
                </div>
                <p className='dispatch-vehicle-check-text'>Hour(s) Prior to Scheduled Pickup</p>
              </div>
              <div className='with-border mt-2' />
              {customerContactBook && (
                <>
                  <div className='d-flex align-items-center my-2'>
                    <CustomCheckbox
                      type='switch'
                      checked={values.send_trucking_link_to_customer}
                      name='send_trucking_link_to_customer'
                      onChange={(checked) => handleChange('send_trucking_link_to_customer', checked)}
                    />
                    <p className='dispatch-vehicle-check-text'>Send Tracking Updates to Customer Contact</p>
                    <CustomSelect
                      options={customerContactBook}
                      defaultValue='Select Contact...'
                      onChange={(value) => handleChange('recipient_customer_contact_book_id', value)}
                      styles={{ maxWidth: 200, marginLeft: '0.5rem', marginTop: 0 }}
                      id='select_list'
                      name='contact_name'
                      propertyName='contact_name'
                      patchProperty='id'
                    />
                  </div>
                  <div className='with-border mt-2' />
                </>
              )}
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.send_pickup_reminder_to_driver}
                  name='send_pickup_reminder_to_driver'
                  onChange={(checked) => {
                    if (!smsSettings?.send_sms) {
                      showToaster({
                        type: 'error',
                        message:
                          'Send SMS option is disabled for this account, please enable it from settings before using this option!',
                      });
                      return;
                    }
                    handleChange('send_pickup_reminder_to_driver', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text'>Send Driver SMS Reminder</p>
                <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                  <input
                    type='number'
                    placeholder='0'
                    name='send_pickup_reminder_to_driver_before_minutes'
                    value={values.send_pickup_reminder_to_driver_before_minutes}
                    onChange={handleChange}
                  />
                </div>
                <p className='dispatch-vehicle-check-text'>Minute(s) Prior to Scheduled Pickup</p>
              </div>
            </form>
            {checked && (
              <>
                <div>
                  <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                    Cost Estimate
                  </h6>
                  <div className='with-border mt-2' />
                  <div className='dispatch-vehicle-cost'>
                    <div>
                      <p className='dispatch-vehicle-cost-text'>Driver Costs</p>
                      <p className='dispatch-vehicle-cost-text'>
                        Estimated Fuel Costs{' '}
                        <span className='cost-purple-text'> ${(+fuelPrice).toFixed(2)} /GAL DIESEL</span>
                      </p>
                      <p className='dispatch-vehicle-cost-text cost-bold-text'>Estimated Costs</p>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <p className='dispatch-vehicle-cost-text'>
                        {currency}
                        {formatAmount(checked?.driver1_cost)}
                      </p>
                      <p className='dispatch-vehicle-cost-text'>
                        {currency}
                        {formatAmount(checked?.estimated_fuel_cost)}
                      </p>
                      <p className='dispatch-vehicle-cost-text cost-bold-text'>
                        {currency}
                        {formatAmount(checked?.cost)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='with-border mt-2' />
                <div>
                  <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                    Net Profit
                  </h6>
                  <div className='with-border mt-2' />
                  <div className='dispatch-vehicle-cost'>
                    <div>
                      <p className='dispatch-vehicle-cost-text'>
                        Gross Shipment Charges{' '}
                        <span className='profit-gray-text'>
                          {' '}
                          $
                          {formatAmount(
                            Number(checked?.total_amount || 0) /
                              (Number(Number(checked?.empty_miles || 0).toFixed()) +
                                Number(Number(checked?.loaded_miles || 0).toFixed()))
                          )}{' '}
                          /MILE EST. GROSS
                        </span>
                      </p>
                      <p className='dispatch-vehicle-cost-text'>
                        Estimated Shipment Costs{' '}
                        <span className='profit-red-text'>
                          $
                          {formatAmount(
                            Number(checked?.cost || 0) /
                              (Number(Number(checked?.empty_miles || 0).toFixed()) +
                                Number(Number(checked?.loaded_miles || 0).toFixed()))
                          )}{' '}
                          /MILE EST. COST
                        </span>
                      </p>
                      <p className='dispatch-vehicle-cost-text cost-green-text'>
                        Estimated Net Profit{' '}
                        <span className='profit-green-text'>
                          $
                          {formatAmount(
                            Number(checked?.net_profit) /
                              (Number(Number(checked?.empty_miles || 0).toFixed()) +
                                Number(Number(checked?.loaded_miles || 0).toFixed()))
                          )}{' '}
                          /MILE EST. NET
                        </span>
                      </p>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <p className='dispatch-vehicle-cost-text'>${formatAmount(checked?.total_amount)}</p>
                      <p className='dispatch-vehicle-cost-text'>${formatAmount(checked?.cost)}</p>
                      <p className='dispatch-vehicle-cost-text cost-green-text'>${formatAmount(checked?.net_profit)}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='dispatch-vehicle-map'>
          <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
            Route
          </h6>
          <div className='with-border mt-2' />
          <div className='d-flex justify-content-between align-items-center my-2'>
            <div className='d-flex align-items-center'>
              <p className='dispatch-vehicle-check-text' style={{ marginLeft: 0, textTransform: 'capitalize' }}>
                {routeSelect}
              </p>
              <Dropdown className='d-inline mx-2' autoClose='outside'>
                <Dropdown.Toggle
                  id='dropdown-autoclose-outside'
                  style={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    boxShadow: 'none',
                    marginLeft: '0',
                    paddingLeft: '10x',
                  }}
                >
                  <img src={down} alt='down' />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                    onClick={() => handleRouteSelect('practical')}
                  >
                    Practical
                  </Dropdown.Item>

                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => handleRouteSelect('shortest')}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Shortest
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => handleRouteSelect('fastest')}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Fastest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <div className='d-flex align-items-center mb-1'>
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem', marginLeft: 0 }}>
                  Open Borders
                </p>
                <CustomCheckbox
                  checked={!!mapValues.open_borders}
                  onChange={(checked) => handleMapValuesChange('open_borders', checked)}
                />
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem' }}>
                  Highway Only
                </p>
                <CustomCheckbox
                  checked={!!mapValues.hwy_only}
                  onChange={(checked) => handleMapValuesChange('hwy_only', checked)}
                />
                <p className='dispatch-vehicle-check-text'>HazMat</p>
                <CustomCheckbox
                  checked={!!mapValues.haz_mat}
                  disabled
                  onChange={(checked) => handleMapValuesChange('haz_mat', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                <CustomCheckbox
                  checked={!!mapValues.avoid_tolls}
                  onChange={(checked) => handleMapValuesChange('avoid_tolls', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Traffic</p>
                <CustomCheckbox
                  checked={!!mapValues.show_traffic}
                  onChange={(checked) => checkAddonStatus('show_traffic', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Weather</p>
                <CustomCheckbox
                  checked={!!mapValues.show_weather}
                  onChange={(checked) => checkAddonStatus('show_weather', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
              </div>
            </div>
          </div>
          <div style={style.mapWrapper}>
            {shipmentStops && (
              <OfferVehicleMap
                data={shipmentStops.shipment_stops}
                selectedDrivers={data?.data}
                mapValues={mapValues}
                previousShipment={previousShipment}
                planedShipment={planedShipment}
                customLatLong={customLatLong}
              />
            )}
          </div>
        </div>
      </div>
      <CustomModal
        showModal={openModal}
        styleBody={{ width: 446, backgroundColor: '#F7F9FC' }}
        onHide={() => setOpenModal(false)}
        headerTitle='Send Offer to All'
        customBody={{
          background: use(palette.white, palette.gray900),
          paddingTop: 0,
          paddingBottom: '10px',
        }}
        styleButtons={{ padding: '8px 12px' }}
        buttons={[
          { key: 'close', type: 'secondary', title: 'Cancel' },
          { key: 'submit', type: 'primary', title: 'Send', onClick: () => submitAmountModal() },
        ]}
      >
        <div className={styles['document-container']}>
          <div>
            <InputWithIcon
              required
              type='number'
              label='Amount'
              name='amount'
              onBlur={sendAmountFormik.handleBlur}
              onChange={sendAmountFormik.handleChange}
              value={sendAmountFormik.values.amount}
              onKeyDown={blockNonPositiveNumbers}
            />
          </div>
        </div>
      </CustomModal>
      <SettingsModal isOpen={isOpenSettingsModal} setIsOpen={setIsOpenSettingsModal} onUpdate={onUpdateSettings} />
      <TablePlannerRowEditChildes data={tableShipmentModalObj} onSubmit={onSubmitShipmentRowEditChildes} />
      {!!isOpen?.open && (
        <AddEditModalDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={selectModalType}
          setType={setSelectModalType}
          isShipmentTable={false}
          onAdd={onAddEditModalDetails}
          onUpdateSuccess={getStopsData}
          shipmentId={shipmentId}
          legs={legs}
        />
      )}
      <AlreadyDispatchedModal
        open={!!alreadyDispatched}
        text={alreadyDispatched}
        onClose={() => setAlreadyDispatched(null)}
      />
    </SOfferVehicle>
  );
}
