import React from 'react';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { SCustomModal } from '../../../../Settlement/Settlements.styles';
import { SCheckboxWrapper } from '../../../../Settlement/Tables/Tables.styles';
import { SWrapper } from './DriverType.styles';

const DriverTypes = ({ open, onClose, checkedValues, onDriverTypeChange }) => {
  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      $bgColor={palette.gray0}
      $minWidth='300px'
      $maxWidth='300px'
      headerTitle='Driver types'
      buttons={[
        {
          key: 'submit',
          type: 'primary',
          title: 'Apply',
          onClick: onClose,
        },
      ]}
    >
      <SWrapper>
        <SCheckboxWrapper>
          <CustomCheckbox checked={checkedValues.includes('company')} onChange={() => onDriverTypeChange('company')} />
          <Typography variant='s2'>Company Drivers</Typography>
        </SCheckboxWrapper>
        <SCheckboxWrapper>
          <CustomCheckbox checked={checkedValues.includes('owner')} onChange={() => onDriverTypeChange('owner')} />
          <Typography variant='s2'>Owner Operators</Typography>
        </SCheckboxWrapper>
      </SWrapper>
    </SCustomModal>
  );
};

export default DriverTypes;
