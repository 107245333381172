import React from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';

const DetentionHeader = () => {
  const { use } = useTheme();

  return (
    <div style={{ marginBottom: 30 }}>
      <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray200) }}>
        Detention
      </Typography>
    </div>
  );
};
export default DetentionHeader;
