import React, { useEffect, useState } from 'react';
import './CarrierProfile.css';
import { useNavigate, useParams } from 'react-router-dom';
import BackArrow from 'assets/icons/drivers/arrow-left.svg';
import call from 'assets/icons/drivers/call.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/drivers/inbox.svg';
import { ReactComponent as UsaFlag } from 'assets/icons/drivers/usaFlag.svg';
import { ReactComponent as Tsa } from 'assets/icons/drivers/tsa.svg';
import { ReactComponent as Fast } from 'assets/icons/drivers/fast.svg';
import { ReactComponent as Twic } from 'assets/icons/drivers/twic.svg';
import { ReactComponent as Favourite } from 'assets/icons/favourite.svg';
import { ReactComponent as StarFilled } from 'assets/icons/star-filled.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as Approved } from 'assets/icons/icons-approval.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';

import Mexico from 'assets/icons/drivers/mexico.png';
import CanadaFlag from 'assets/icons/drivers/turkeyFlag.svg';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import {
  carrierStatuses,
  getCarrier,
  resendOnboardLink,
  updateCarrierGeneralInfo,
  updateCarrierStatus,
} from 'Api/Carriers';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import { getBrokerageSetting } from 'Api/CompanySettings';
import { getErrorMessage } from 'utils/error';
import { CarrierContext } from 'context/tableContext';
import StatusChangeOverlay from './StatusChangeOverlay';
import { Typography } from '../../Typography';
import CarrierProfileTab from './CarrierProfileTab/CarrierProfileTab';
import CustomButton from '../../CustomButton/CustomButton';
import AddReason from './AddReason';
import EquipmentTypes from './EquipmentTypes';
import classes from '../../TableShipments/detailsRow/details.module.css';

const CarrierProfile = () => {
  const { id } = useParams();
  const { use } = useTheme();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [carrier, setCarrier] = useState();
  const [showStatuses, setShowStatuses] = useState(false);
  const [statuses, setStatuses] = useState();
  const [company, setCompany] = useState(null);
  const [addReason, setAddReason] = useState(false);
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState();
  const equipmentTypes = carrier?.equipment_types || [];

  const [openEdit, setOpenEdit] = useState('');
  const [recordTab, setRecordTab] = useState();

  useEffect(() => {
    getProfile();
    carrierStatuses().then((res) => {
      setStatuses(res.data);
    });
    getCompanyProfile();
  }, []);

  const getCompanyProfile = async () => {
    return getBrokerageSetting().then((res) => {
      if (res?.data) {
        setCompany(res?.data);
      }
    });
  };

  const changeToRestricted = (reason) => {
    const data = {
      status_id: 3,
      restriction_reasons: JSON.stringify([reason]),
    };
    updateCarrierStatus(data, id).then(() => {
      const restricted = statuses?.find((item) => item.id === 3);
      setActiveStatus(restricted);
      setAddReason(false);
      showToaster({ type: 'success', message: `Carrier status has been changed to Restricted successfully!` });
      getProfile();
    });
  };

  const changePreferred = (e, data) => {
    e.stopPropagation();
    const payload = {
      preferred: Number(!data.preferred),
    };
    updateCarrierGeneralInfo(payload, data.id)
      .then(() => {
        getProfile();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  function headerOverLayChange(status) {
    if (status.id !== 3) {
      const data = {
        status_id: status.id,
        restriction_reasons: null,
      };
      updateCarrierStatus(data, id).then(() => {
        showToaster({ type: 'success', message: `Carrier status has been changed to ${status.title} successfully!` });
        getCarrier({ id }).then((res) => {
          setCarrier(res.data);
        });
      });
      setActiveStatus(status);
    } else {
      setAddReason(true);
    }
  }

  const getProfile = () => {
    getCarrier({ id }).then((res) => {
      setCarrier(res.data);
    });
  };

  const resend = () => {
    const body = {
      carrier_id: carrier.id,
    };
    resendOnboardLink(body)
      .then(() => {
        showToaster({ type: 'success', message: 'Onboarding link sent successfully' });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  return (
    <CarrierContext.Provider
      value={{
        openEdit,
        setOpenEdit,
        recordTab,
        setRecordTab,
      }}
    >
      <div className='profile-container'>
        <div className='back-navigate-container' onClick={() => navigate(-1)}>
          <img src={BackArrow} alt='Back-Arrow' />
          <span className='navigat-page-name'>Go Back</span>
        </div>
        <div className='carrier-profile-header' style={{ height: '80px' }}>
          <div>
            <div className='user-details-container'>
              <div>
                {carrier && (
                  <div className='user-name-and-actions-container'>
                    {carrier?.logo_path ? (
                      <img
                        src={carrier.logo_path}
                        alt='img'
                        width={30}
                        height={30}
                        style={{ borderRadius: '50%', objectFit: 'cover' }}
                      />
                    ) : (
                      <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                    )}
                    <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray50) }}>
                      {carrier?.name}
                    </Typography>
                    <div className='action-icons'>
                      <a href={`mailto:${carrier?.email}`}>
                        <EmailIcon />
                      </a>
                      <a href={`tel:${carrier?.phone_number}`}>
                        <img src={call} alt='call-ic on' />
                      </a>
                    </div>
                    <Typography variant='title1' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {carrier?.type === 0 ? 'External' : 'In-Network'}
                    </Typography>
                    {carrier?.preferred ? (
                      <StarFilled fill={palette.amber500} onClick={(e) => changePreferred(e, carrier)} />
                    ) : (
                      <Favourite onClick={(e) => changePreferred(e, carrier)} />
                    )}
                    {!!carrier?.token && !!carrier?.token?.used && <Approved width={18} height={18} />}
                  </div>
                )}
                {carrier?.status && (
                  <StatusChangeOverlay
                    show={showStatuses}
                    data={statuses}
                    customer={carrier}
                    onChange={headerOverLayChange}
                    onChaneShow={setShowStatuses}
                    defaultSelected={carrier?.status}
                    activeStatus={activeStatus}
                    setActiveStatus={setActiveStatus}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='align-self-center'>
            {!carrier?.token?.used && (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='secondary'
                title='Resend Onboarding Link'
                onClick={resend}
              />
            )}
          </div>
        </div>
        <div className='d-flex gap-3 flex-wrap mb-2'>
          {Object.values(carrier?.restriction_reasons || {}).map((item) => (
            <Chip
              fontWeight={500}
              fontSize='11px'
              style={{ textTransform: 'uppercase' }}
              bgColor='#FAF0F3'
              labelColor='#D12953'
              label={item}
            />
          ))}
        </div>
        <div className='boxes-wrapper'>
          <div className='boxes-wrapper-item'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              MC
            </Typography>
            <br />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {carrier?.mc || '-'}
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              DOT
            </Typography>
            <br />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {carrier?.dot}
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              SCAC
            </Typography>
            <br />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {carrier?.scac || '-'}
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <div className='d-flex justify-content-between me-2'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Can Travel To
              </Typography>
              <EditIcon width={13} height={13} className={classes.editIcon} onClick={() => setOpenEdit('General')} />
            </div>
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {!!carrier?.can_travel_to?.includes('1') && <UsaFlag />}
              {!!carrier?.can_travel_to?.includes('2') && (
                <img src={CanadaFlag} alt='mexico-Flag' className='mexico-flag-icon' />
              )}
              {!!carrier?.can_travel_to?.includes('3') && (
                <img src={Mexico} alt='mexico-Flag' className='mexico-flag-icon' />
              )}
              {!carrier?.can_travel_to?.length && '-'}
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <div className='d-flex justify-content-between me-2'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Approved For
              </Typography>
              <EditIcon width={13} height={13} className={classes.editIcon} onClick={() => setOpenEdit('General')} />
            </div>
            <div className='d-flex gap-2'>
              {!!carrier?.approved_for?.includes('1') && <Tsa width={30} height={30} />}
              {!!carrier?.approved_for?.includes('2') && <Fast width={40} height={30} />}
              {!!carrier?.approved_for?.includes('3') && <Twic width={40} height={30} />}
              {!!carrier?.approved_for?.includes('4') && <Typography>{carrier?.approved_for_custom || '-'}</Typography>}
              {!carrier?.approved_for?.length && '-'}
            </div>
          </div>
          <div className='boxes-wrapper-item'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              Carrier Pay
            </Typography>
            <br />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {currency} {carrier?.gross_brokered}
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              Power Units
            </Typography>
            <br />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {carrier?.power_unit || '-'}
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              FMCSA Op. Status
            </Typography>
            <br />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {carrier?.operating_status === 1 ? 'Authorized' : 'Not Authorized'}
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <div className='d-flex justify-content-between me-2'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Equipment Type
              </Typography>
              <EditIcon width={13} height={13} className={classes.editIcon} onClick={() => setOpenEdit('Operations')} />
            </div>
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {equipmentTypes.slice(0, 2).map((item) => (
                  <Chip
                    key={item.id}
                    size='medium'
                    label={item?.title}
                    labelColor={palette.indigo500}
                    fontWeight={500}
                    bgColor={palette.indigo0}
                    height='20px'
                    style={{ marginRight: '5px' }}
                  />
                ))}
                {equipmentTypes.length > 2 && (
                  <Typography variant='c1' style={{ color: palette.indigo500 }} onClick={() => setShowMoreOpen(true)}>
                    Show More
                  </Typography>
                )}
              </Typography>
            </Typography>
          </div>
          <div className='boxes-wrapper-item'>
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              Cargo Carried
            </Typography>
            <br />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {carrier?.cargo_carried || '-'}
            </Typography>
          </div>
        </div>
        <CarrierProfileTab company={company} loading={false} carrier={carrier} onSuccess={() => getProfile()} />
        {addReason && (
          <AddReason
            open={addReason}
            onClose={() => {
              getProfile();
              setAddReason(false);
            }}
            onSuccess={(reason) => changeToRestricted(reason)}
          />
        )}
        {showMoreOpen && (
          <EquipmentTypes open={showMoreOpen} onClose={() => setShowMoreOpen(false)} equipmentTypes={equipmentTypes} />
        )}
      </div>
    </CarrierContext.Provider>
  );
};
export default CarrierProfile;
