import React, { useMemo, useState } from 'react';
import Input from 'common/Input';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { addTabs, updateTabs } from 'Api/Driver';
import ModalFooter from './components/ModalFooter';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './AddTab.data';

const AddTab = ({ open, onClose, tab, onSuccess, onDeleteSuccess, alertCategories }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        type: !tab ? 'alert_center' : undefined,
        forAll: !tab ? values.forAll : undefined,
        data: {
          name: values.tabName,
          color: values.color,
          filters: {
            alert_type: values.alert_categories.length ? values.alert_categories.map((item) => item.title) : undefined,
            alert_id: values.alert_types.length ? values.alert_types.map((item) => item.id) : undefined,
          },
        },
      };
      if (tab) {
        await updateTabs(body, tab.id);
      } else {
        await addTabs(body);
      }
      onSuccess();
      onClose();
      showToaster({ type: 'success', message: `Tab has been successfully ${tab ? 'updated' : 'created'}!` });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, setValues, touchedErrors } = useForm({
    initialValues: getInitialValues(tab, alertCategories),
    validationSchema,
    onSubmit,
  });

  const alertTypeOptions = useMemo(() => {
    return values.alert_categories.flatMap((el) => el.alerts);
  }, [values.alert_categories]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={tab ? 'Update Tab' : 'Add Tab'}
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdrop='static'
      customFooter={
        <ModalFooter
          tab={tab}
          values={values}
          onClose={onClose}
          loading={loading}
          onDeleteSuccess={onDeleteSuccess}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      }
    >
      <div className='d-flex flex-column gap-4'>
        <Input
          required
          name='tabName'
          label='Tab Name'
          onChange={handleChange}
          value={values.tabName}
          error={touchedErrors?.tabName}
        />
        <div className='d-flex align-items-center gap-3'>
          <InputLabel className='mb-0'>Select Color</InputLabel>
          <Input name='color' width='100px' type='color' value={values.color} onChange={handleChange} />
        </div>
        <div>
          <Autocomplete
            required
            multiple
            label='Alert Category'
            name='alert_categories'
            labelKey='title'
            options={alertCategories}
            value={values.alert_categories}
            onChange={(e, val) => {
              const alertTypeOptions = val.flatMap((el) => el.alerts);

              setValues((prevState) => ({
                ...prevState,
                alert_categories: val,
                alert_types: prevState.alert_types.filter((type) =>
                  alertTypeOptions.some((option) => option.id === type.id)
                ),
              }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            error={touchedErrors.alert_categories}
            disableClearable={false}
          />
        </div>
        <div>
          <Autocomplete
            multiple
            label='Select Alerts'
            name='alert_types'
            labelKey='alert_name'
            options={alertTypeOptions}
            value={values.alert_types}
            onChange={(e, val) => handleChange('alert_types', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            disableClearable={false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddTab;
