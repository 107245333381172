import moment from 'moment';

export const applicantTypes = {
  1: 'Driver',
  2: 'Mechanic',
  3: 'Staff User',
};

export const photoIdentificationTypes = {
  1: 'Driver License',
  2: 'Passport',
  3: 'ID',
};

export const checkResultOptions = [
  { value: 1, name: 'Passed' },
  { value: 0, name: 'Failed' },
];

export const criminalCheckResultOptions = [
  { value: 1, name: 'Completed' },
  { value: 0, name: 'Pending' },
];

export const actionFormFields = [
  {
    select: { name: 'criminal_check_result', label: 'Criminal Check Results' },
    date: { name: 'criminal_checked_date' },
    attachment: { name: 'criminal_checked_attachment' },
  },
  {
    select: { name: 'mvr_check_result', label: 'MVR Check Results' },
    date: { name: 'mvr_checked_date' },
    attachment: { name: 'mvr_check_attachment' },
  },
  {
    select: { name: 'drug_test_result', label: 'Drug Test Results' },
    date: { name: 'drug_checked_date' },
    attachment: { name: 'drug_test_attachment' },
  },
  {
    select: { name: 'fmcsa_result', label: 'FMCSA Clearinghouse Results' },
    date: { name: 'fmcsa_checked_date' },
    attachment: { name: 'fmcsa_attachment' },
  },
];

export const actionInitialValues = {
  criminal_check_result: null,
  mvr_check_result: null,
  drug_test_result: null,
  fmcsa_result: null,
  attach_medical_verification: null,
  attach_national_registry: null,
  criminal_checked_date: null,
  mvr_checked_date: null,
  drug_checked_date: null,
  fmcsa_checked_date: null,
  criminal_checked_attachment: '',
  mvr_check_attachment: '',
  drug_test_attachment: '',
  fmcsa_attachment: [],
  prevEmployments: [
    {
      request_date: new Date(),
      employer_name: '',
      start_date: null,
      end_date: null,
      present: 0,
      request_form_path: '',
      request_form_source: 'Email',
      response: '',
      email: '',
      fax: '',
      phone_no: '',
      request_type: 'Pdf',
      response_path: '',
    },
  ],
};

export const navigationTabs = [
  { label: 'Applicant Info', key: 'driver-info', id: 0 },
  { label: 'Position & Experience', key: 'position', id: 1 },
  { label: 'Type of Applicant', key: 'applicant-type', id: 2 },
  { label: 'Medical', key: 'medical', id: 3 },
  { label: 'Previous Employment', key: 'previous-employment', id: 4 },
  { label: 'Military History', key: 'military', id: 6 },
  { label: 'Education', key: 'education', id: 7 },
  { label: 'Traffic Accidents', key: 'trafficHistory', id: 8 },
  { label: 'Other', key: 'other', id: 5 },
];

export const getActionInitialValues = (data) => {
  if (!data) {
    return actionInitialValues;
  }

  return {
    criminal_check_result: data.criminal_check_result ? criminalCheckResultOptions[0] : criminalCheckResultOptions[1],
    mvr_check_result: data.mvr_check_result ? checkResultOptions[0] : checkResultOptions[1],
    drug_test_result: data.drug_test_result ? checkResultOptions[0] : checkResultOptions[1],
    fmcsa_result: data.fmcsa_result ? checkResultOptions[0] : checkResultOptions[1],
    criminal_checked_date: data.criminal_checked_date,
    mvr_checked_date: data.mvr_checked_date,
    drug_checked_date: data.drug_checked_date,
    fmcsa_checked_date: data.fmcsa_checked_date,
    criminal_checked_attachment: data.criminal_checked_attachment || '',
    mvr_check_attachment: data.mvr_check_attachment || '',
    drug_test_attachment: data.drug_test_attachment || '',
    fmcsa_attachment: data.fmcsa_attachment || '',
  };
};

export const createActionConvertor = (values, fmcsaAttachment, id) => {
  return {
    job_application_id: id,
    criminal_check_result: values.criminal_check_result?.value,
    mvr_check_result: values.mvr_check_result?.value,
    drug_test_result: values.drug_test_result?.value,
    fmcsa_result: values.fmcsa_result?.value,
    criminal_checked_date: values.criminal_checked_date
      ? moment(values.criminal_checked_date).format('MM/DD/YYYY')
      : null,
    mvr_checked_date: values.mvr_checked_date ? moment(values.mvr_checked_date).format('MM/DD/YYYY') : null,
    drug_checked_date: values.drug_checked_date ? moment(values.drug_checked_date).format('MM/DD/YYYY') : null,
    fmcsa_checked_date: values.fmcsa_checked_date ? moment(values.fmcsa_checked_date).format('MM/DD/YYYY') : null,
    criminal_checked_attachment: values.criminal_checked_attachment || null,
    mvr_check_attachment: values.mvr_check_attachment || null,
    drug_test_attachment: values.drug_test_attachment || null,
    fmcsa_attachment: fmcsaAttachment || null,
    attach_medical_verification: values.attach_medical_verification || null,
    attach_national_registry: values.attach_national_registry || null,
  };
};

export const previousEmploymentConvertor = (data, jobId) => {
  if (!data) {
    return null;
  }

  const newForms = [];
  const updatedForms = [];
  data.forEach((item) => {
    (item.id ? updatedForms : newForms).push(item);
  });

  const formData = new FormData();

  formData.append('job_application_id', jobId);
  newForms.forEach((item, index) => {
    if (item.id) {
      return;
    }

    if (item.employer_name) {
      formData.append(`employer_name[${index}]`, item.employer_name);
    }
    if (item.email && item.request_form_source === 'Email') {
      formData.append(`email[${index}]`, item.email);
    }
    formData.append(`phone_no[${index}]`, item.phone_no || '');
    if (item.fax) {
      formData.append(`fax[${index}]`, item.fax);
    }
    formData.append(`present[${index}]`, Number(item.present));
    if (moment(item.start_date).isValid()) {
      formData.append(`start_date[${index}]`, moment(item.start_date).format('MM/DD/YYYY'));
    }
    if (moment(item.end_date).isValid()) {
      formData.append(`end_date[${index}]`, item.end_date ? moment(item.end_date).format('MM/DD/YYYY') : '');
    }

    formData.append(`request_date[${index}]`, moment(item.request_date).format('MM/DD/YYYY'));

    if (item.request_form_source) {
      formData.append(`request_form_source[${index}]`, item.request_form_source);
    }
    if (item.response) {
      formData.append(`response[${index}]`, item.response);
    }
    if (item.request_type) {
      formData.append(`request_type[${index}]`, item.request_type);
    }
    if (item.request_form_path) {
      formData.append(`request_form_path[${index}]`, item.request_form_path);
    }
  });

  const updated = updatedForms.reduce(
    (acc, item) => {
      acc.id.push(item.id);
      acc.employer_name.push(item.employer_name);
      acc.email.push(item.request_form_source === 'Email' ? item.email : '');
      acc.phone_no.push(item.phone_no);
      acc.fax.push(item.fax);
      acc.present.push(Number(item.present));
      acc.start_date.push(moment(item.start_date).format('MM/DD/YYYY'));
      acc.end_date.push(moment(item.end_date).format('MM/DD/YYYY'));

      acc.request_date.push(moment(item.request_date).format('MM/DD/YYYY'));
      acc.request_form_source.push(item.request_form_source);
      acc.request_type.push(item.request_type);
      acc.request_form_path.push(item.request_form_path);
      acc.response.push(item.response);
      acc.response_path.push(item.response_path);

      acc.address.push(item.address);
      acc.country_id.push(item.country?.id);
      acc.state_id.push(item.state?.id);
      acc.city_id.push(item.city?.id);
      acc.zipcode.push(item.zipcode);
      acc.position.push(item.position);
      acc.reason.push(item.reason);
      acc.terminated.push(item.terminated);
      acc.current_employer.push(item.current_employer);
      acc.can_contact.push(item.can_contact);
      acc.operated_commercial_vehicle.push(item.operated_commercial_vehicle);
      acc.subject_to_safety_regulations.push(item.subject_to_safety_regulations);
      acc.perform_safety_functions.push(item.perform_safety_functions);
      acc.areas_driven.push(item.areas_driven);
      acc.miles_driven.push(item.miles_driven);
      acc.pay_range.push(item.pay_range);
      acc.truck_name.push(item.truck_name);
      acc.trailer_name_size.push(item.trailer_name_size);

      return acc;
    },
    {
      job_application_id: jobId,
      id: [],
      employer_name: [],
      phone_no: [],
      present: [],
      start_date: [],
      end_date: [],
      email: [],
      fax: [],
      request_date: [],
      request_form_source: [],
      request_type: [],
      request_form_path: [],
      response: [],
      response_path: [],
      address: [],
      country_id: [],
      state_id: [],
      city_id: [],
      zipcode: [],
      position: [],
      reason: [],
      terminated: [],
      current_employer: [],
      can_contact: [],
      operated_commercial_vehicle: [],
      subject_to_safety_regulations: [],
      perform_safety_functions: [],
      areas_driven: [],
      miles_driven: [],
      pay_range: [],
      truck_name: [],
      trailer_name_size: [],
    }
  );

  return { formData, updated, shouldCreate: !!newForms.length };
};
