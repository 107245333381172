import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input, { InputWithIcon } from 'common/Input';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { createVehicleInventory } from 'Api/Equipment';
import Photos from './Photos';
import { validationSchema } from './validationSchema';

const AddInventory = ({ open, onClose, equipmentId, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('equipment_id', equipmentId);
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('ownership', values.ownership);
      formData.append('quantity', values.quantity);
      formData.append('cost_per_quantity', values.cost_per_quantity);
      if (values.quantity && values.cost_per_quantity) {
        formData.append('total_value', (values.cost_per_quantity * values.quantity).toFixed(2));
      }
      values.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      await createVehicleInventory(formData);
      showToaster({ type: 'success', message: 'Inventory has been successfully added!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      name: '',
      description: '',
      quantity: '',
      cost_per_quantity: '',
      files: [],
      ownership: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Add Inventory'
      $bgColor={palette.gray0}
      $maxWidth='500px'
      $minWidth='500px'
      backdrop='static'
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Add Inventory',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <Input
          required
          label='Name'
          name='name'
          value={values.name}
          onChange={handleChange}
          error={touchedErrors.name}
        />
        <Input
          label='Description'
          name='description'
          value={values.description}
          onChange={handleChange}
          error={touchedErrors.description}
        />
        <Input
          type='number'
          placeholder='0'
          label='Qty'
          name='quantity'
          value={values.quantity}
          onChange={handleChange}
          error={touchedErrors.quantity}
          onKeyDown={(e) => blockNonPositiveNumbers(e)}
        />
        <InputWithIcon
          type='number'
          placeholder='0.00'
          label='Cost Per Qty'
          name='cost_per_quantity'
          value={values.cost_per_quantity}
          onChange={handleChange}
          error={touchedErrors.quantity}
        />
        <InputWithIcon
          placeholder='0.00'
          label='Total Value'
          value={formatNumber(values.quantity * values.cost_per_quantity)}
          disabled
        />
        <Photos values={values} handleChange={handleChange} />
        <Input
          label='Ownership'
          name='ownership'
          value={values.ownership}
          onChange={handleChange}
          error={touchedErrors.ownership}
        />
      </div>
    </Modal>
  );
};

export default AddInventory;
