import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import VerificationCode from 'common/VerificationCode';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { createLocationPin, getTimeZoneList, updateLocationPin } from 'Api/CompanySettings';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './CratePin.data';
import { SPinWrapper } from '../../TimeApp.styles';

const CreatePin = ({ open, onClose, onSuccess, location }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        location_name: values.location_name,
        address1: values.address,
        address2: values.address2,
        country_id: values.country.id,
        state_id: values.state.id,
        city_id: values.city.id,
        zipcode: values.zipcode,
        pin: values.pin,
        timezone: values.timezone.id,
        nfc_enabled: Number(values.nfc_enabled),
        qr_enabled: Number(values.qr_enabled),
        card_enabled: Number(values.card_enabled),
      };

      if (location) {
        await updateLocationPin(location.id, body);
      } else {
        await createLocationPin(body);
      }
      showToaster({ type: 'success', message: `PIN has been successfully ${location ? 'updated' : 'created'}!` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, dirty } = useForm({
    initialValues: getInitialValues(location),
    onSubmit,
    validationSchema,
  });

  const getTimeZones = async () => {
    try {
      const { data } = await getTimeZoneList();
      setTimezones(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getTimeZones();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={location ? 'Update Location' : 'Create Location'}
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          buttonProps: { type: 'submit' },
          key: 'submit',
          type: 'primary',
          title: location ? 'Update' : 'Create',
          onClick: handleSubmit,
          disabled: loading || !dirty,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-3'>
        <Input
          required
          name='location_name'
          label='Location Name'
          placeholder='Location Name'
          value={values.location_name}
          onChange={handleChange}
          error={touchedErrors.location_name}
        />
        <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
        <SPinWrapper>
          <InputLabel required>PIN</InputLabel>
          <VerificationCode
            codeLength={6}
            onChange={(value) => handleChange('pin', value)}
            initialValue={location?.pin || null}
          />
          <ErrorMessage error={touchedErrors.pin} />
        </SPinWrapper>
        <div>
          <Autocomplete
            required
            name='timezone'
            label='Timezone'
            labelKey='zone_name'
            options={timezones}
            value={values.timezone}
            onChange={(e, val) => handleChange('timezone', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.timezone}
          />
        </div>
        <div>
          <div className='d-flex align-items-center gap-3'>
            <CustomCheckbox
              type='switch'
              name='nfc_enabled'
              checked={!!values.nfc_enabled}
              onChange={(checked) => handleChange('nfc_enabled', checked)}
              smail={false}
            />
            <Typography variant='s2'>Enable NFC Card Tap</Typography>
          </div>
          <div className='d-flex align-items-center gap-3'>
            <CustomCheckbox
              type='switch'
              name='qr_enabled'
              checked={!!values.qr_enabled}
              onChange={(checked) => handleChange('qr_enabled', checked)}
              smail={false}
            />
            <Typography variant='s2'>Enable QR Code Reader</Typography>
          </div>
          <div className='d-flex align-items-center gap-3'>
            <CustomCheckbox
              type='switch'
              name='card_enabled'
              checked={!!values.card_enabled}
              onChange={(checked) => handleChange('card_enabled', checked)}
              smail={false}
            />
            <Typography variant='s2'>Enable Manual Card ID Entry</Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreatePin;
