import moment from 'moment';

export const bidsConverter = (bids, shipment) => {
  const loadedMiles = Math.round(shipment?.loaded_miles || 0);
  const firstStop = shipment.shipment_stops[0];

  return bids.map((bid) => {
    const eta_minutes = bid?.eta?.minutes || 0;
    const shipment_stops_schedule_dates = bid.shipment_stops_schedule_dates
      ? JSON.parse(bid.shipment_stops_schedule_dates)
      : null;

    const fistStopScheduledDate = `${firstStop.scheduled_date} ${firstStop.from}`;
    const driverToFirstStopDate = moment(bid.driver_available_from).add(bid.eta?.minutes || 0, 'minutes');

    const driverToFirstStopMinutes = moment(driverToFirstStopDate).diff(moment(fistStopScheduledDate), 'minutes');
    const driverToFirstStopHours = moment(driverToFirstStopDate).diff(moment(fistStopScheduledDate), 'hours') % 24;
    const driverToFirstStopDays = moment(driverToFirstStopDate).diff(moment(fistStopScheduledDate), 'days');

    return {
      ...bid,
      shipment_stops_schedule_dates,
      rpm: Number(bid.bid_amount || 0) / (loadedMiles + Number(bid.eta?.empty_miles || 0)),
      eta_margin: bid.eta ? eta_minutes - driverToFirstStopMinutes : null,
      eta_margin_labels: bid.eta
        ? [Math.abs(driverToFirstStopDays), Math.abs(driverToFirstStopHours), Math.abs(driverToFirstStopMinutes) % 60]
        : null,
      meet_eta:
        !!bid.offer_accepted && !!bid.carrier && !!shipment_stops_schedule_dates
          ? Object.values(shipment_stops_schedule_dates).some((i) => !!i)
            ? 'No'
            : 'Yes'
          : '',
    };
  });
};

export const getFilteredBids = (bids, sort, shipment) => {
  const { field, sortBy } = sort;
  const counter = sortBy === 'asc' ? 1 : -1;
  const shipmentAmount = shipment?.shipment_billing?.reduce((acc, cur) => acc + Number(cur.total_amount), 0);

  const filteredBids = [...(bids || [])];

  switch (field) {
    case 'status':
      filteredBids.sort((a, b) =>
        a.offer_accepted < b.offer_accepted ? counter : a.offer_accepted === b.offer_accepted ? 0 : -counter
      );
      break;
    case 'bid_date':
      filteredBids.sort((a, b) =>
        a.updated_at < b.updated_at ? counter : a.updated_at === b.updated_at ? 0 : -counter
      );
      break;
    case 'rpm':
      filteredBids.sort((a, b) => (a.rpm < b.rpm ? counter : a.rpm === b.rpm ? 0 : -counter));
      break;
    case 'empty_miles':
      filteredBids.sort((a, b) =>
        a.eta?.empty_miles < b.eta?.empty_miles ? counter : a.eta?.empty_miles === b.eta?.empty_miles ? 0 : -counter
      );
      break;
    case 'loaded_miles':
      filteredBids.sort((a, b) =>
        a.offer_accepted < b.offer_accepted ? counter : a.offer_accepted === b.offer_accepted ? 0 : -counter
      );
      break;
    case 'eta':
      filteredBids.sort((a, b) =>
        a.eta?.minutes < b.eta?.minutes ? counter : a.eta?.minutes === b.eta?.minutes ? 0 : -counter
      );
      break;
    case 'meet_eta':
      filteredBids.sort((a, b) => (a.meet_eta < b.meet_eta ? counter : a.meet_eta === b.meet_eta ? 0 : -counter));
      break;
    case 'bid_amount':
      filteredBids.sort((a, b) =>
        !a.bid_amount
          ? 1
          : Number(a.bid_amount) < Number(b.bid_amount)
          ? counter
          : Number(a.bid_amount) === Number(b.bid_amount)
          ? 0
          : -counter
      );
      break;
    case 'margin':
      filteredBids.sort((a, b) => {
        const percentA = a.bid_amount ? ((shipmentAmount - Number(a.bid_amount)) * 100) / shipmentAmount : 0;
        const percentB = b.bid_amount ? ((shipmentAmount - Number(b.bid_amount)) * 100) / shipmentAmount : 0;

        return percentA < percentB ? counter : percentA === percentB ? 0 : -counter;
      });
      break;
    case 'bid_by':
      filteredBids.sort((a, b) =>
        a.selected_contact_user?.contact_name < b.selected_contact_user?.contact_name
          ? counter
          : a.selected_contact_user?.contact_name === b.selected_contact_user?.contact_name
          ? 0
          : -counter
      );
      break;
    case 'eta_margin':
      filteredBids.sort((a, b) =>
        a.eta_margin < b.eta_margin ? counter : a.eta_margin === b.eta_margin ? 0 : -counter
      );
      break;
    default:
      filteredBids.sort((a, b) => {
        const percentA = a.bid_amount ? ((shipmentAmount - Number(a.bid_amount)) * 100) / shipmentAmount : 0;
        const percentB = b.bid_amount ? ((shipmentAmount - Number(b.bid_amount)) * 100) / shipmentAmount : 0;

        return percentA < percentB ? 1 : percentA === percentB ? 0 : -1;
      });
      break;
  }

  return filteredBids.filter((item) => !!item.offer_accepted);
};
