import React, { useEffect, useState } from 'react';
import { GetSettingsAlerts } from 'Api/CompanySettings';
import { ALERT_TYPE } from 'componentsV2/AlertCenter/TakeActions/TakeActions.data';
import GeneralEdit from './GeneralEdit';
import GeneralPreview from './GeneralPreview';
import GeneralGeoEdit from './GenerealGeoEdit';

const General = (props) => {
  const [showVehicleAlert, setShowVehicleAlert] = useState(false);
  const [showTrailerAlert, setShowTrailerAlert] = useState(false);
  const [edit, setEdit] = useState(false);
  const { getGeofence, geofenceData, setGeofenceData, getStopPointItem, stopPointsItem, updateIndex, setUpdateIndex } =
    props;

  const getAlertTypeOptions = async () => {
    try {
      const { data } = await GetSettingsAlerts();
      const equipmentAlerts = data.find((item) => item.name === 'Equipment');

      const vehicleAlert = equipmentAlerts?.alerts?.find(
        (item) => item.alert_name === ALERT_TYPE.VEHICLE_REACH_NUMBER_OF_STOPS
      );
      const trailerAlert = equipmentAlerts?.alerts?.find(
        (item) => item.alert_name === ALERT_TYPE.TRAILER_REACH_NUMBER_OF_STOPS
      );
      setShowVehicleAlert(!!vehicleAlert?.alert_status);
      setShowTrailerAlert(!!trailerAlert?.alert_status);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getAlertTypeOptions();
  }, []);

  if (!stopPointsItem || !geofenceData) return null;

  return (
    <>
      {edit ? (
        <GeneralEdit
          getStopPointItem={getStopPointItem}
          stopPointsItem={stopPointsItem}
          setEdit={setEdit}
          setUpdateIndex={setUpdateIndex}
          showVehicleAlert={showVehicleAlert}
          showTrailerAlert={showTrailerAlert}
        />
      ) : (
        <GeneralPreview
          stopPointsItem={stopPointsItem}
          setEdit={setEdit}
          showVehicleAlert={showVehicleAlert}
          showTrailerAlert={showTrailerAlert}
        />
      )}

      <GeneralGeoEdit
        getStopPointItem={getStopPointItem}
        setGeofenceData={setGeofenceData}
        geofenceData={geofenceData}
        stopPointsItem={stopPointsItem}
        getGeofence={getGeofence}
        updateIndex={updateIndex}
      />
    </>
  );
};
export default General;
