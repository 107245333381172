import React, { useEffect, useRef } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { isFirefox } from 'react-device-detect';
import styles from './PopoverSettings.module.css';

const ColumnOrder = ({ localDragItem, onChangeOrderLocal }) => {
  const rootRef = useRef();

  const onDragEnd = (itemsDragDrop) => {
    const lastItem = itemsDragDrop.map((el, index) => {
      return { ...el, order: index + 1 };
    });
    onChangeOrderLocal(lastItem);
  };

  useEffect(() => {
    setTimeout(() => {
      const floatingDragElement = document.querySelector('.dl-item.floating');
      const modalStyles = getComputedStyle(rootRef?.current?.closest('[role=tooltip]'));
      const match = modalStyles?.transform?.match(/-?\d*\.?\d+/g);
      if (!isFirefox) {
        floatingDragElement.style.transform = `translate(-${match[match.length - 2]}px, -${match[match.length - 1]}px)`;
      }
    }, 0);
  }, [rootRef?.current]);

  return (
    <div ref={rootRef} className={styles.dragContainer}>
      <span>Column Order</span>
      <div className={styles.columnOrder_wrapper}>
        <RLDD
          layout='horizontal'
          onChange={onDragEnd}
          items={localDragItem}
          cssClasses={styles['dl-container']}
          itemRenderer={(item) => <div className={styles.item_drabButton}>{item?.title}</div>}
        />
      </div>
    </div>
  );
};

export default ColumnOrder;
