import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Speed } from 'assets/icons';
import down from 'assets/icons/drivers/down.svg';
import 'react-datepicker/dist/react-datepicker.css';
import authHeader from 'services/auth-header';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, numberWithCommas, palette, TRUCK_TYPES } from 'utils/constants';
import { getGVWRClass } from 'pages/landing/Equipment/ImportSamsaraVehicles/helpers';
import {
  LicenseState,
  DimensionArray,
  getEquipmentRequiredVehicles,
  getEquipmentRequiredTrailers,
  OwnerOperator,
  getFuelTypes,
  updateEquipmentStatus,
  getEquipmentDocuments,
  createEquipmentDocument,
} from 'Api/Equipment';
import { getVendorsList } from 'Api/Vendors';
import EditDetails from './EditDetails';
import style from '../../EquipmentProfile.module.css';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow:
        '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      backgroundColor: palette.white,
      color: palette.dark800,
    },
  })
);

export const transmissionType = {
  1: 'Automatic',
  2: 'Manual',
};

const EquipmentDetails = ({ profileData, init, type, updateConnectedServices }) => {
  const { formatDate } = useDateFormat();
  const API_URL = process.env.REACT_APP_API_URL;
  const { currency } = useSelector((state) => state.root);
  const [details, setDetails] = useState();
  const [expand, setExpand] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const { use } = useTheme();
  const [plateState, setPlateState] = useState([]);
  const [lengthData, setLengthData] = useState();
  const [vehicleList, setVehicleList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [ownerOperator, setOwnerOperator] = useState([]);
  const [equipmentOwner, setEquipmentOnwer] = useState();
  const [loading, setLoading] = useState(false);
  const [leasingCompanyError, setLeasingCompanyError] = useState(false);
  const [ownerOperatorError, setOwnerOperatorError] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [registration, setRegistration] = useState(null);
  const userData = JSON.parse(localStorage.getItem('user'));

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getFuelTypeOptions = async () => {
    try {
      const { data } = await getFuelTypes();
      setFuelTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRegistrationDocument = async () => {
    try {
      const { data } = await getEquipmentDocuments(profileData.id, { 'filters[equipment_document_type_id][]': 9 });
      if (data?.[0]) {
        setRegistration(data[0]);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const openEditModal = () => {
    setEditDetails(!editDetails);
  };

  useEffect(() => {
    if (profileData?.id) {
      setEquipmentOnwer(
        profileData?.equipment_ownership?.owner_operator === '1'
          ? 'Company Owned'
          : profileData?.equipment_ownership?.owner_operator === '2'
          ? 'Leasing Owned'
          : profileData?.equipment_ownership?.owner_operator === '3'
          ? 'Owner Operator'
          : '-'
      );

      getRegistrationDocument();
    }
  }, [profileData]);

  useEffect(() => {
    setDetails(profileData);
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      DimensionArray(profileData?.equipment_type?.id).then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              label: el.length_info ? `${el.length_info.length} ${el.length_info.unit}` : '-',
              value: el.length,
              id: el?.length_info?.id,
            };
          });
          setLengthData(filterByData);
        }
      });
    }
  }, [profileData]);

  const OwnerOperatorID = async () => {
    OwnerOperator().then((res) => {
      const OwnerOp = res.data.map((v) => {
        return {
          label: v.owner_operator_name,
          value: v.id,
        };
      });
      setOwnerOperator(OwnerOp);
    });
  };

  const onSubmit = async (values, { resetForm }) => {
    if (equipmentOwner === 'Leasing Owned' && !values?.leasing_company_name) {
      setLeasingCompanyError(true);
      return;
    }
    if (equipmentOwner === 'Owner Operator' && !ownerOperator?.some((i) => i.label === values.owner)) {
      setOwnerOperatorError(true);
      return;
    }

    let ownerType;
    if (equipmentOwner === 'Company Owned') {
      ownerType = 1;
    } else if (equipmentOwner === 'Leasing Owned') {
      ownerType = 2;
    } else if (equipmentOwner === 'Owner Operator') {
      ownerType = 3;
    } else if (equipmentOwner === '3rd Party') {
      ownerType = 4;
    }

    const value = {
      truck_type: values.equipment_type_id === 1 ? values?.truck_type : null,
      make: values?.make,
      model_id: values?.model_id,
      color: values?.color,
      year: type ? moment(values.year, 'MM/YYYY').format('MM/YYYY') : values?.year,
      tire_size: values?.tire_size,
      axles: values?.axles,
      lift_gate: values?.lift_gate ? 1 : 0,
      vin: values?.vin,
      created_at: values?.created_at,
      start_odometer: values?.start_odometer,
      odometer_unit: values?.odometer_unit,
      gross_vehicle_weight: values?.gross_vehicle_weight,
      gross_vehicle_measuring_unit: values?.gross_vehicle_measuring_unit,
      gvwr_class: getGVWRClass(values?.gross_vehicle_weight, { value: values?.gross_vehicle_measuring_unit }),
      cargo_maximum_capacity_weight: values?.cargo_maximum_capacity_weight,
      height: values?.height,
      width: values?.width,
      length_id: values?.length?.id || null,
      fuel_type_id: values?.fuel_type_id,
      fuel_gallon_capacity: values.fuel_gallon_capacity,
      equipment_type_id: values?.equipment_type_id,
      transmission_type: values.transmission_type,
      transmission_speed: values.transmission_type === 2 ? values.transmission_speed : null,
      equipment_ownership: {
        purchase_cost: values?.purchase_cost || 0,
        owner: values?.owner,
        license_plate_number: values?.license_plate_number,
        state: values?.state,
        owner_operator: ownerType,
        operator_id: ownerType === 3 ? ownerOperator.find((el) => el.label === values?.owner).value : null,
        leasing_company_name: values?.leasing_company_name,
        lease_or_credit_payment: values?.lease_or_credit_payment,
      },
    };

    try {
      setLoading(true);
      await axios
        .put(`${API_URL}/equipment/${profileData?.id}`, value, {
          headers: authHeader(),
        })
        .then((res) => {
          if (res) {
            openEditModal();
            setLoading(false);
          }
        }, resetForm());

      if (values.is_retired) {
        await updateEquipmentStatus(profileData.id, {
          status_id: 5,
          retired_date: moment(values.retired_date).format('YYYY-MM-DD'),
        });
        updateConnectedServices();
      } else if (profileData?.equipment_status?.id === 5 && !values.is_retired) {
        await updateEquipmentStatus(profileData.id, {
          status_id:
            profileData?.equipment_type?.vehicle_type_id === 2
              ? profileData?.hooked_to
                ? 7
                : 4
              : profileData?.drivers?.length
              ? 1
              : 4,
          retired_date: null,
        });
      }

      if (values.license_plate_number !== registration?.reference_id) {
        const formData = new FormData();
        formData.append('equipment_id', profileData.id);
        formData.append(
          'document_exp_date',
          registration?.document_exp_date ? moment(registration.document_exp_date).format('YYYY-MM-DD') : ''
        );
        formData.append('equipment_document_type_id', 9);
        formData.append('use_from_policy', Number(registration?.use_from_policy || 0));
        formData.append('document_name', registration?.document_name || '');
        formData.append('reference_id', values.license_plate_number || '');

        await createEquipmentDocument(formData);
      }

      init();
    } catch (error) {
      setLoading(false);
    }
  };

  const getVehicleList = useCallback(() => {
    getEquipmentRequiredVehicles()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              label: el.title,
              value: el.id,
            };
          });
          setVehicleList(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);
  const getTrailerList = useCallback(() => {
    getEquipmentRequiredTrailers()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              label: el.title,
              value: el.id,
            };
          });
          setTrailerList(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  useEffect(() => {
    if (profileData && Object.keys(profileData).length === 0) return;
    if (profileData?.equipment_type?.vehicle_type_id === 1) {
      if (vehicleList.length !== 0) return;
      getVehicleList();
    }
    if (profileData?.equipment_type?.vehicle_type_id === 2) {
      if (trailerList.length !== 0) return;
      getTrailerList();
    }
  }, [profileData]);

  useEffect(() => {
    LicenseStateID();
    OwnerOperatorID();
    getVendors();
    getFuelTypeOptions();
  }, []);

  const expandComponent = () => {
    setExpand(!expand);
  };

  const LicenseStateID = async () => {
    const promises = [];

    ['231', '38', '142'].map((id) => {
      promises.push(LicenseState(id));
      return id;
    });

    const response = await Promise.all(promises);
    const finalRes = [...response[0].data.data, ...response[1].data.data, ...response[2].data.data];
    const PlateStateData = finalRes.map((v) => {
      return { label: v.name, value: v.id };
    });
    setPlateState(PlateStateData);
  };

  useEffect(() => {
    LicenseStateID();
  }, []);

  return (
    <form>
      {expand && editDetails === true ? null : (
        <div className='d-flex justify-content-between  align-item-center equipment-profile-group-box'>
          <span className={style.details} style={{ color: use(palette.dark800, palette.gray200) }}>
            DETAILS <img className='ms-2' src={down} alt='' onClick={expandComponent} />
          </span>
          <div className='mt-2'>
            {expand && (
              <div>
                {editDetails === false && (
                  <span onClick={openEditModal} style={{ cursor: 'pointer' }}>
                    ...
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {expand && editDetails === false && (
        <div>
          <div className='d-flex mt-2' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Type</span>
            <span className={style['equipment-profile-content-value']}>
              {profileData?.equipment_type?.title ? profileData?.equipment_type?.title : '-'}{' '}
              {!!profileData?.truck_type && <span>({TRUCK_TYPES[profileData?.truck_type]})</span>}
            </span>
          </div>
          <div className='d-flex mt-3' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Owned By</span>
            <span className={style['equipment-profile-content-value']}>
              {profileData?.equipment_ownership?.owner_operator === '1' && userData?.customer?.company_name}
              {profileData?.equipment_ownership?.owner_operator === '2' &&
                profileData?.equipment_ownership?.leasing_company_name}
              {profileData?.equipment_ownership?.owner_operator === '3' && profileData?.equipment_ownership?.owner}
            </span>
          </div>
          <div className='d-flex mt-3' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Owner of Equipment</span>
            <span className={style['equipment-profile-content-value']}>
              {profileData?.equipment_ownership?.owner_operator === '1'
                ? 'Company Owned'
                : profileData?.equipment_ownership?.owner_operator === '2'
                ? 'Leasing Owned'
                : profileData?.equipment_ownership?.owner_operator === '3'
                ? 'Owner Operator'
                : profileData?.equipment_ownership?.owner_operator === '4'
                ? '3rd Party'
                : ''}{' '}
            </span>
          </div>
          {['1', '2'].includes(profileData?.equipment_ownership?.owner_operator) && (
            <div className='d-flex mt-3' style={{ color: use(palette.dark800, palette.gray200) }}>
              <span className={style['equipment-profile-content-new']}>
                {profileData?.equipment_ownership?.owner_operator === '2' ? 'Lease Cost' : 'Purchase Cost'}
              </span>
              {profileData?.equipment_ownership?.owner_operator === '2' ? (
                <span className={style['equipment-profile-content-value']}>
                  {currency}
                  {formatNumber(profileData?.equipment_ownership?.lease_or_credit_payment || 0)} /month
                </span>
              ) : (
                <span className={style['equipment-profile-content-value']}>
                  {currency}
                  {profileData?.equipment_ownership?.purchase_cost
                    ? numberWithCommas(Number(profileData.equipment_ownership.purchase_cost).toFixed(2))
                    : '0.00'}
                </span>
              )}
            </div>
          )}
          <div className='d-flex mt-3' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>{type ? 'Manufactured Date' : 'Year'} </span>
            <span className={style['equipment-profile-content-value']}>{profileData?.year}</span>
          </div>
          <div className='d-flex mt-3' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Make </span>
            <span className={style['equipment-profile-content-value']}>{profileData?.make}</span>
          </div>

          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Model </span>
            <span className={style['equipment-profile-content-value']}>{profileData?.model_id}</span>
          </div>
          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Color</span>
            <span className={style['equipment-profile-content-value']}>{profileData?.color}</span>
          </div>
          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Tire Size</span>
            <span className={style['equipment-profile-content-value']}>{profileData?.tire_size || ''}</span>
          </div>
          {profileData.equipment_type_id < 12 && (
            <>
              <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                <span className={style['equipment-profile-content-new']}>Transmission Type</span>
                <span className={style['equipment-profile-content-value']}>
                  {profileData?.transmission_type ? transmissionType[profileData?.transmission_type] : ''}
                </span>
              </div>
              {profileData?.transmission_type === 2 && (
                <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                  <span className={style['equipment-profile-content-new']}>Speed</span>
                  <span className={style['equipment-profile-content-value']}>
                    {profileData?.transmission_speed || ''}
                  </span>
                </div>
              )}
            </>
          )}

          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Axles</span>
            <span className={style['equipment-profile-content-value']}>{profileData?.axles || ''}</span>
          </div>
          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Lift Gate</span>
            <span className={style['equipment-profile-content-value']}>{profileData?.lift_gate ? 'Yes' : 'No'}</span>
          </div>
          <div className='mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <div className='d-flex '>
              <span className={style['equipment-profile-content-new']}>VIN </span>
              <span className={style['equipment-profile-content-value']}>{profileData?.vin}</span>
            </div>
          </div>
          <div className='mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <div className='d-flex mb-2 '>
              <span className={style['equipment-profile-content-new']}>Join Date</span>
              <span className={style['equipment-profile-content-value']}>{formatDate(profileData?.created_at)}</span>
            </div>
          </div>
          {!type && (
            <div className='mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
              <div className='d-flex mb-2'>
                <span className={style['equipment-profile-content-new']}>Odometer Reading</span>
                {profileData?.odometer_unit === 'miles'
                  ? profileData?.equipment_connected_services_device?.obd_odometer_miles && (
                      <span className={style['equipment-profile-content-value']}>
                        {profileData?.equipment_connected_services_device?.obd_odometer_miles}
                        <span> {profileData?.odometer_unit}</span>
                      </span>
                    )
                  : profileData?.equipment_connected_services_device?.obd_odometer_meter && (
                      <span className={style['equipment-profile-content-value']}>
                        {profileData?.equipment_connected_services_device?.obd_odometer_meter}
                        <span> {profileData?.odometer_unit}</span>
                      </span>
                    )}
                {profileData?.location_target === 'Driver' ? (
                  <div className=''>
                    <span>
                      <LightTooltip title='No telematics equipped.' placement='right'>
                        <span>{Speed}</span>
                      </LightTooltip>
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {profileData?.location_target === 'Driver' ||
                profileData?.equipment_connected_services_device?.obd_odometer_miles ||
                profileData?.equipment_connected_services_device?.obd_odometer_meter ? (
                  <div className=''>{/* "" */}</div>
                ) : (
                  <div className=''>
                    <span>
                      <LightTooltip title='No telematics equipped.' placement='right'>
                        <span>{Speed}</span>
                      </LightTooltip>
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          {!type && (
            <div className='mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
              <div className='d-flex mb-2 '>
                <span className={style['equipment-profile-content-new']}>Start Odometer</span>
                <span className={style['equipment-profile-content-value']}>
                  {profileData?.start_odometer} {(!!profileData?.start_odometer && profileData?.odometer_unit) || ''}
                </span>
              </div>
            </div>
          )}
          <div className='mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <div className='d-flex mb-2'>
              <span className={style['equipment-profile-content-new']}>Gross Vehicle Weight</span>
              <span className={style['equipment-profile-content-value']}>
                {profileData?.gross_vehicle_weight}{' '}
                {!!profileData?.gross_vehicle_weight && profileData?.gross_vehicle_measuring_unit}
              </span>
            </div>
          </div>
          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>GVWR Class</span>
            <span className={style['equipment-profile-content-value']}>{profileData?.gvwr_class || ''}</span>
          </div>
          {!type && (
            <>
              <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                <span className={style['equipment-profile-content-new']}>Fuel Type</span>
                <span className={style['equipment-profile-content-value']}>
                  {profileData?.fuel_type?.id ? profileData?.fuel_type?.type : ''}
                </span>
              </div>
              <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                <span className={style['equipment-profile-content-new']}>Fuel Capacity</span>
                <span className={style['equipment-profile-content-value']}>
                  {profileData?.fuel_gallon_capacity ? `${profileData?.fuel_gallon_capacity}/gal` : '-'}
                </span>
              </div>
            </>
          )}
          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Plate Number</span>
            <span className={style['equipment-profile-content-value']}>{registration?.reference_id}</span>
          </div>
          <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content-new']}>Plate State</span>
            <span className={style['equipment-profile-content-value']}>
              {profileData?.equipment_ownership?.state_data?.name}
            </span>
          </div>
          <div className='equipment-profile-group-box' style={{ color: use(palette.dark800, palette.gray200) }}>
            {type ||
            profileData?.equipment_type?.id === 3 ||
            profileData?.equipment_type?.id === 4 ||
            profileData?.equipment_type?.id === 5 ||
            profileData?.equipment_type?.id === 9 ||
            profileData?.equipment_type?.id === 11 ? (
              <>
                <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                  <span className={style['equipment-profile-content-new']}>Cargo Payload</span>
                  <span className={style['equipment-profile-content-value']}>
                    {profileData?.cargo_maximum_capacity_weight} {profileData?.cargo_capacity_measuring_unit}
                  </span>
                </div>
                <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                  <span className={style['equipment-profile-content-new']}>Length</span>
                  {profileData.length ? (
                    <span className={style['equipment-profile-content-value']}>
                      {profileData?.length?.length} {profileData?.length?.unit}
                    </span>
                  ) : (
                    ' '
                  )}
                </div>
                <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                  <span className={style['equipment-profile-content-new']}>Height</span>
                  <span className={style['equipment-profile-content-value']}>{profileData?.height} inches</span>
                </div>
                <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
                  <span className={style['equipment-profile-content-new']}>Width</span>
                  <span className={style['equipment-profile-content-value']}>{profileData?.width} inches</span>
                </div>
              </>
            ) : (
              ''
            )}
            <div className='d-flex mt-3 ' style={{ color: use(palette.dark800, palette.gray200) }}>
              <span className={style['equipment-profile-content-new']}>Retire Equipment</span>
              <span className={style['equipment-profile-content-value']} style={{ width: '200px' }}>
                {profileData?.equipment_status?.id === 5
                  ? `Retired ${profileData?.retired_date ? `on ${formatDate(profileData?.retired_date)}` : ''}`
                  : 'Active'}
              </span>
            </div>
          </div>
        </div>
      )}
      {expand && editDetails === true && (
        <EditDetails
          profileData={profileData}
          registration={registration}
          details={details}
          setDetails={setDetails}
          onSubmit={onSubmit}
          userData={userData}
          trailerList={trailerList}
          vehicleList={vehicleList}
          equipmentOwner={equipmentOwner}
          vendors={vendors}
          getVendors={getVendors}
          leasingCompanyError={leasingCompanyError}
          setLeasingCompanyError={setLeasingCompanyError}
          ownerOperator={ownerOperator}
          ownerOperatorError={ownerOperatorError}
          setOwnerOperatorError={setOwnerOperatorError}
          setEquipmentOnwer={setEquipmentOnwer}
          type={type}
          plateState={plateState}
          fuelTypes={fuelTypes}
          lengthData={lengthData}
          openEditModal={openEditModal}
          expand={expand}
          expandComponent={expandComponent}
          loading={loading}
        />
      )}
    </form>
  );
};

export default EquipmentDetails;
