import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { StreamChat } from 'stream-chat';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import bidReceivedSound from 'assets/bidReceived.mp3';
import awardAcceptedSound from 'assets/awardAccepted.mp3';
import AuthService from 'services/auth.service';
import { useTheme } from 'context/themeContext';
import { Favorites, RegistrationFeatures } from 'Api/Layout';
import { UpdateUserStatus } from 'Api/Header';
import { channelMsg, tabChannel } from 'channels/tabChannel';
import { PusherJs } from 'common/pusher';
import RateUs from 'componentsV2/RateUs';
import { useAuth } from 'context/auth.context';
import { updateRowEditItem, handleOutside } from 'store/reducers/root.reducer';
import { setIsOpenSendPaymentDefault } from 'store/reducers/header.reducer';
import { addRecentActivity } from 'store/reducers/shipments.reducer';
import { IDLE_EVENTS, IDLE_MESSAGES, IDLE_TIMEOUT, IDLE_TIMEOUT_60MIN, PERMISSIONS } from 'utils/constants';
import './layout.css';
import { getUnreadSettlementsCount } from 'Api/Payroll';
import { getOfferedShipmentsCount } from 'Api/OfferedShipments';
import { getProductivityEssentialsSetting } from 'Api/CompanySettings';
import LeftMenu from './leftMenu/leftMenu';
import Header from './header/header';
import Footer from './footer/footer';
import IdleModal from './IdleModal';
import { MenuIcons } from './leftMenu/menuIcons';
import styles from './DropDownAvatar/DropDownAvatar.module.css';
import { LOGIN_STATUSES } from './DropDownAvatar';
import AccountStatus from './AccountStatusMessage/AccountStatus';
import MinimizedGlobal from './MinimizedGlobal';
import NotesEditModal from './TableRowEditModalTypes/NotesEditModal/NotesEditModal';
import SendPaymentModal from './SendPaymentModal';

export const LayoutContext = createContext();

const Layout = () => {
  const dispatch = useDispatch();
  const chatUserToken = JSON.parse(localStorage.getItem('chatToken'));
  const user = JSON.parse(localStorage.getItem('user'));
  const dot_num = user?.customer?.dot;
  const chatUser = {
    id: `${dot_num}_staff_${user?.id}`,
    name: `${user?.first_name} ${user?.last_name}`,
    image: user?.profile_logo,
  };
  const { activeRowEditItem, permissions } = useSelector((state) => state?.root);
  const { isOpenSendPaymentModal } = useSelector((state) => state?.header);

  const [users, setUsers] = useState([]);
  const [chatClient, setChatClient] = useState(null);
  const [showNotepad, setShowNotepad] = useState(false);
  const [cartItemsData, setCartItemsData] = useState(0);
  const { value, setAuth } = useAuth();
  const { theme, use } = useTheme();
  const [open, setOpen] = useState(false);
  const [registration, setRegistration] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [favoritesOnHeader, setFavoritesOnHeader] = useState();
  const [openInNewTab, setOpenInNewTab] = useState();
  const [hardwarePurchaseTab, setHardwarePurchaseTab] = useState();
  const [accountStatus, setAccountStatus] = useState(false);
  const [messageNotification, setMessageNotification] = useState(0);
  const [unreadSettlementsCount, setUnreadSettlementsCount] = useState(0);
  const [offeredShipmentsCount, setOfferedShipmentsCount] = useState(0);
  const [timeZone, setTimeZone] = useState(null);
  const [userStatus, setUserStatus] = useState({
    id: 10,
    title: 'Available',
    className: styles.collapse_item1,
    status: 1,
  });
  const [userData, setUserData] = useState({
    profileLogo: '',
    big: '',
    small: '',
    bigImg: '',
    smallImg: '',
    dot: '',
  });
  const [profileLogo, setProfileLogo] = useState();
  const { pathname } = useLocation();
  const path = pathname?.split('/').join('');
  const screen = registration.find((el) => el.slug === pathname?.replace('/', ''));
  const {
    MenuIcon,
    DashboardIcon,
    StaffIcon,
    PlannerIcon,
    CarrierIcon,
    ShipmentIcon,
    StopIcon,
    Customers,
    AccountingIcon,
    CardIcon,
    MapIcon,
    DocIcon,
    FuelIcon,
    ReportIcon,
    PayIcon,
    ShieldIcon,
    RepairIcon,
    RecruitingIcon,
  } = MenuIcons;

  const menuButtons = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Favorites',
        Icon: MenuIcon,
        path: '',
        collapse: {
          editable: true,
          indigoItems: true,
          subItems: favorites,
        },
      },
      { id: 2, title: 'Dashboard', Icon: DashboardIcon, path: '/dashboard' },
      {
        id: 330,
        title: 'Fleet + Staff',
        Icon: StaffIcon,
        path: '',
        menuPermissions: [
          PERMISSIONS.FLEET_STAFF.name,
          PERMISSIONS.DRIVERS.name,
          PERMISSIONS.FLEET_STAFF_DRIVERS_LIST.name,
          PERMISSIONS.FLEET_STAFF_EQUIPMENT.name,
          PERMISSIONS.OWNER_OPERATORS.name,
          PERMISSIONS.ABSENCES.name,
          PERMISSIONS.REQUESTS.name,
          PERMISSIONS.REQUESTS_READ_ONLY.name,
        ],
        collapse: {
          editable: false,
          subItems: [
            {
              id: 333,
              subTitle: 'Drivers',
              path: '/driver',
              show: true,
              subMenuPermissions: [
                PERMISSIONS.FLEET_STAFF_DRIVERS_LIST.name,
                PERMISSIONS.DRIVERS.name,
                PERMISSIONS.FLEET_STAFF.name,
              ],
            },
            {
              id: 334,
              subTitle: 'Equipment',
              path: '/equipment',
              show: true,
              subMenuPermissions: [PERMISSIONS.FLEET_STAFF_EQUIPMENT.name, PERMISSIONS.FLEET_STAFF.name],
            },
            {
              id: 335,
              subTitle: 'Staff Users',
              path: '/staff',
              show: true,
              subMenuPermissions: [PERMISSIONS.STAFF.name, PERMISSIONS.FLEET_STAFF.name],
            },
            {
              id: 336,
              subTitle: 'Owner Operators',
              path: '/owner-operators',
              show: true,
              subMenuPermissions: [PERMISSIONS.OWNER_OPERATORS.name, PERMISSIONS.FLEET_STAFF.name],
            },
            {
              id: 337,
              subTitle: 'Attendance',
              path: '/absence-list',
              show: true,
              subMenuPermissions: [PERMISSIONS.ABSENCES.name, PERMISSIONS.FLEET_STAFF.name],
            },
            {
              id: 338,
              subTitle: 'Requests',
              path: '/request-list',
              show: true,
              subMenuPermissions: [
                PERMISSIONS.REQUESTS_READ_ONLY,
                PERMISSIONS.REQUESTS.name,
                PERMISSIONS.FLEET_STAFF.name,
              ],
            },
          ],
        },
      },
      {
        id: 424,
        title: 'Recruiting',
        Icon: RecruitingIcon,
        path: '',
        menuPermissions: [
          PERMISSIONS.APPLICANTS.name,
          PERMISSIONS.APPLICANTS_REFERRALS.name,
          PERMISSIONS.REFERRALS.name,
          PERMISSIONS.REFERRERS.name,
          PERMISSIONS.JOB_POSITIONS.name,
        ],
        collapse: {
          editable: false,
          subItems: [
            {
              id: 410,
              subTitle: 'Applicants',
              path: '/recruiting-applicants',
              show: true,
              subMenuPermissions: [PERMISSIONS.APPLICANTS.name, PERMISSIONS.APPLICANTS_REFERRALS.name],
            },
            {
              id: 420,
              subTitle: 'Referrals',
              path: '/referrals',
              show: true,
              subMenuPermissions: [PERMISSIONS.REFERRALS.name, PERMISSIONS.APPLICANTS_REFERRALS.name],
            },
            {
              id: 430,
              subTitle: 'Referrers',
              path: '/referrers',
              show: true,
              subMenuPermissions: [PERMISSIONS.REFERRERS.name],
            },
            {
              id: 440,
              subTitle: 'Job Listings',
              path: '/job-positions',
              show: true,
              subMenuPermissions: [PERMISSIONS.JOB_POSITIONS.name],
            },
          ],
        },
      },
      {
        id: 4,
        title: 'Planner',
        Icon: PlannerIcon,
        path: '',
        menuPermissions: [PERMISSIONS.PLANNER.name],
        collapse: {
          editable: false,
          subItems: [
            {
              id: 1,
              subTitle: 'Plan Shipments',
              path: '/planner',
              show: true,
              subMenuPermissions: [PERMISSIONS.PLANNER.name],
            },
            {
              id: 2,
              subTitle: 'Availability',
              path: '/planner-availability',
              show: true,
              subMenuPermissions: [PERMISSIONS.PLANNER.name],
            },
            // {
            //   id: 3,
            //   subTitle: 'Quotes',
            //   path: '/quotes',
            //   show: true,
            //   subMenuPermissions: [PERMISSIONS.PLANNER.name],
            // },
          ],
        },
      },
      {
        id: 5,
        title: 'Shipments',
        Icon: ShipmentIcon,
        path: '',
        menuPermissions: [],
        collapse: {
          editable: false,
          subItems: [
            {
              id: 400,
              subTitle: 'Shipments',
              path: '/shipments',
              show: true,
              subMenuPermissions: [PERMISSIONS.SHIPMENTS.name],
            },
            {
              id: 401,
              subTitle: 'Shipment Audit',
              path: '/shipment-audit',
              show: true,
              subMenuPermissions: [PERMISSIONS.COMPLETED_AUDIT.name],
            },
            {
              id: 402,
              subTitle: 'Shipment History',
              path: '/shipment-history',
              show: true,
              subMenuPermissions: [PERMISSIONS.SHIPMENT_HISTORY.name],
            },
            {
              id: 403,
              subTitle: 'Detention',
              path: '/detention',
              show: true,
              subMenuPermissions: [PERMISSIONS.DETENTION.name],
            },
            {
              id: 404,
              subTitle: 'EDI/API Logs',
              path: '/edi-api-logs',
              show: true,
              subMenuPermissions: [],
            },
          ],
        },
      },
      { id: 17, title: 'Carriers', Icon: CarrierIcon, path: '/carriers', menuPermissions: [PERMISSIONS.CARRIERS.name] },
      {
        id: 15,
        title: 'Safety & Compliance',
        Icon: ShieldIcon,
        className: 'small-icon',
        path: '',
        menuPermissions: [PERMISSIONS.SAFETY.name, PERMISSIONS.REPORTS.name],
        iconStyles: {
          marginLeft: '-3px',
        },
        collapse: {
          editable: false,
          subItems: [
            {
              id: 151,
              subTitle: 'Incidents',
              path: '/incidents',
              show: true,
              subMenuPermissions: [PERMISSIONS.SAFETY.name],
            },
            {
              id: 152,
              subTitle: 'DOT Monitoring',
              path: '/dot-monitoring',
              show: true,
              subMenuPermissions: [PERMISSIONS.SAFETY.name],
            },
            {
              id: 153,
              subTitle: 'Hours Of Service',
              path: '/hours-of-service',
              show: true,
              subMenuPermissions: [PERMISSIONS.SAFETY.name],
            },
            {
              id: 154,
              subTitle: 'Accidents / Crashes',
              path: '/accidents-crashes',
              show: true,
              subMenuPermissions: [PERMISSIONS.SAFETY.name],
            },
            {
              id: 155,
              subTitle: 'Accident Register',
              path: '/accident-register',
              show: true,
              subMenuPermissions: [PERMISSIONS.SAFETY.name],
            },
            {
              id: 156,
              subTitle: 'Drug and Alcohol Report',
              path: '/reports/40',
              show: true,
              subMenuPermissions: [PERMISSIONS.REPORTS.name],
            },
          ],
        },
      },
      {
        id: 6,
        title: 'Stop Point',
        Icon: StopIcon,
        path: '',
        menuPermissions: [PERMISSIONS.STOP_POINTS.name, PERMISSIONS.REPORTS.name],
        className: 'stop-icon',
        collapse: {
          editable: false,
          subItems: [
            {
              id: 1,
              subTitle: 'Stop Points',
              path: '/stop-point',
              show: true,
              subMenuPermissions: [PERMISSIONS.STOP_POINTS.name],
            },
            {
              id: 2,
              subTitle: 'Arrivals and Departures',
              path: '/reports/37',
              show: true,
              subMenuPermissions: [PERMISSIONS.REPORTS.name],
            },
          ],
        },
        titleStyles: {
          marginLeft: '5px',
        },
        iconStyles: {
          marginLeft: '-2px',
        },
      },
      { id: 7, title: 'Customers', Icon: Customers, path: '/customers', menuPermissions: [PERMISSIONS.CUSTOMERS.name] },
      {
        id: 8,
        title: 'Accounting',
        Icon: AccountingIcon,
        path: '',
        menuPermissions: [
          PERMISSIONS.ACCOUNTING.name,
          PERMISSIONS.RECEIVABLES.name,
          PERMISSIONS.PAYABLES.name,
          PERMISSIONS.ACCOUNTS.name,
          PERMISSIONS.VENDORS.name,
          PERMISSIONS.SEND_PAYMENT.name,
        ],
        collapse: {
          editable: false,
          subItems: [
            {
              id: 81,
              subTitle: 'Receivables',
              path: '/accounting/receivables',
              show: true,
              subMenuPermissions: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.RECEIVABLES.name],
            },
            {
              id: 82,
              subTitle: 'Payables',
              path: '/accounting/payables',
              show: true,
              subMenuPermissions: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.PAYABLES.name],
            },
            {
              id: 83,
              subTitle: 'Chart of Accounts',
              path: '/accounting/accounts',
              show: true,
              subMenuPermissions: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.ACCOUNTS.name],
            },
            {
              id: 84,
              subTitle: 'Vendors',
              path: '/accounting/vendors',
              show: true,
              subMenuPermissions: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.VENDORS.name],
            },
            {
              id: 85,
              subTitle: 'Items and Services',
              path: '/company-settings',
              state: {
                tabId: 2,
                subTabName: 'ShipmentTypes',
              },
              show: true,
              subMenuPermissions: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.ITEMS.name],
            },
            {
              id: 86,
              show: true,
              subTitle: 'Send Payment',
              subMenuPermissions: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.SEND_PAYMENT.name],
            },
          ],
        },
      },
      {
        id: 9,
        title: 'Card Management',
        Icon: CardIcon,
        path: '/card-management',
        menuPermissions: [PERMISSIONS.CARD_MANAGEMENT.name],
      },
      { id: 10, title: 'Map', Icon: MapIcon, path: '/map', menuPermissions: [PERMISSIONS.MAP.name] },
      { id: 11, title: 'Documents', Icon: DocIcon, path: '/documents', menuPermissions: [PERMISSIONS.DOCUMENTS.name] },
      {
        id: 16,
        title: 'Repair Shop',
        Icon: RepairIcon,
        className: 'small-icon',
        path: '/repair-shop',
        menuPermissions: [PERMISSIONS.BILLING.name],
        titleStyles: {
          marginLeft: '6px',
        },
      },
      { id: 12, title: 'IFTA', Icon: FuelIcon, path: '/ifta', menuPermissions: [PERMISSIONS.IFTA.name] },
      { id: 13, title: 'Reports', Icon: ReportIcon, path: '/reports', menuPermissions: [PERMISSIONS.REPORTS.name] },
      {
        id: 14,
        title: 'Payroll',
        Icon: PayIcon,
        path: '',
        menuPermissions: [PERMISSIONS.PAYROLL.name],
        collapse: {
          editable: false,
          subItems: [
            {
              id: 339,
              subTitle: 'Payroll',
              path: '/payroll',
              show: true,
              subMenuPermissions: [PERMISSIONS.PAYROLL.name],
            },
            {
              id: 340,
              subTitle: 'Time Tracking',
              path: '/time-tracking',
              show: true,
              subMenuPermissions: [PERMISSIONS.PAYROLL.name],
            },
          ],
        },
      },
    ];
  }, [favorites]);

  const [modalData, setModalData] = useState(IDLE_MESSAGES.available);

  const updateUserStatus = async (statusId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      const res = await UpdateUserStatus(statusId);
      if (res && res.success) {
        const newUserData = { ...user, login_status: Number(statusId) };
        localStorage.setItem('user', JSON.stringify(newUserData));
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onPrompt = async () => {
    if (!open) {
      updateUserStatus(3);
      setModalData(IDLE_MESSAGES.available);
      setOpen(true);
    }
  };

  const onIdle = async () => {
    setModalData(IDLE_MESSAGES.logOuted);
    await updateUserStatus(2);
    await AuthService.logout(false);
    AuthService.removeUserToken();
  };

  const { activate } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    promptTimeout: IDLE_TIMEOUT_60MIN,
    onPrompt,
    onIdle,
    crossTab: true,
    name: 'idleCrossTabName',
    syncTimers: 1,
    events: open ? IDLE_EVENTS.click : IDLE_EVENTS.all,
  });

  const handleStillHere = () => {
    UpdateUserStatus(1)
      .then((res) => {
        if (res && res.success) {
          setUserStatus({
            id: 10,
            title: 'Available',
            className: styles.collapse_item1,
            status: 1,
          });
          setOpen(false);
          tabChannel.postMessage(channelMsg.CLOSE_AWAY_MODAL).catch(null);
        }
      })
      .catch(() => {
        // Do nothing
      });
    activate();
  };

  const getLocalStorageData = () => {
    try {
      const { login_status, profile_logo, customer } = JSON.parse(localStorage.getItem('user')) || {};
      const { dot } = customer || {};
      const big = customer?.theme_settings?.big_logo || '';
      const small = customer?.theme_settings?.small_logo || '';
      const avatar = JSON.parse(localStorage.getItem('userAvatar'));
      let avtarPath;
      if (avatar) {
        avtarPath = avatar?.path;
      }
      const logo = profile_logo === undefined ? avtarPath : profile_logo;
      setProfileLogo(logo);
      setUserData({
        ...userData,
        profileLogo: logo,
        big,
        small,
        dot,
      });
      let status = LOGIN_STATUSES[1];
      if ([1, 3, 4].includes(login_status)) {
        status = LOGIN_STATUSES[login_status];
      }
      setUserStatus(status);
    } catch (e) {
      // Do nothing
    }
  };

  const onSetUserData = (data) => {
    setUserData(data);
  };

  const handleMessage = (msg) => {
    switch (msg) {
      case channelMsg.CLOSE_AWAY_MODAL:
        setOpen(false);
        break;
      default:
    }
  };

  useEffect(() => {
    const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY, { timeout: 60000 });
    const initUser = async () => {
      try {
        const user = await client.connectUser(chatUser, chatUserToken);
        if (user?.me?.total_unread_count) {
          setMessageNotification(user?.me?.total_unread_count);
        }
        const userList = await client.queryUsers({
          role: 'staff',
          organization_id: `${dot_num}`,
        });
        setUsers(userList.users);
        setChatClient(client);
        client.on('message.new', (event) => {
          setMessageNotification(event.total_unread_count);
        });
        client.on('notification.message_new', (event) => {
          setMessageNotification(event.total_unread_count);
        });
        client.on('notification.mark_read', (event) => {
          setMessageNotification(event.total_unread_count);
        });
      } catch (e) {
        // Do nothing
      }
    };
    initUser();
    return () => {
      if (client) {
        setChatClient(null);
        client?.disconnectUser();
      }
    };
  }, []);

  useEffect(() => {
    RegistrationFeatures()
      .then((res) => {
        const data = [];
        res?.forEach((item) => {
          if (item?.children) {
            const { children, ...rest } = item;
            data.push({ ...rest });
            children?.forEach((child) => {
              data.push(child);
            });
          } else {
            data.push(item);
          }
        });
        setRegistration(data);
      })
      .catch(() => {
        // Do nothing
      });
    Favorites()
      .then((res) => {
        const changeKeys = {
          id: 'id',
          name: 'subTitle',
          slug: 'path',
          parent_id: 'parent_id',
        };

        let favoritesArr;
        if (res[0] && res[0].feature && res[0].length !== 0) {
          favoritesArr = res.map((item) => {
            return Object.fromEntries(
              Object.entries(item.feature).map((entry) => {
                if (changeKeys[entry[0]] === changeKeys.slug) {
                  return [changeKeys[entry[0]], `/${entry[1]}`];
                }
                return [changeKeys[entry[0]], entry[1]];
              })
            );
          });
          setFavorites(favoritesArr);
        }
      })
      .catch(() => {
        // Do nothing
      });
    getLocalStorageData();
    tabChannel.addEventListener('message', handleMessage);
    return () => {
      tabChannel.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    document.body.style.background = use('#ffffff', '#161D32');
  }, [theme]);

  const onDeleteFavorites = (id) => {
    const newFavorites = favorites?.filter((item) => item.id !== id);
    setFavorites(newFavorites);
  };

  const onAddFavorites = (data) => {
    setFavorites([...favorites, data]);
  };

  const getSettlementsCount = async () => {
    try {
      const { data } = await getUnreadSettlementsCount();
      setUnreadSettlementsCount(data?.count || 0);
    } catch (e) {
      // Do nothing
    }
  };

  const getOfferedCount = async () => {
    try {
      const { data } = await getOfferedShipmentsCount();
      setOfferedShipmentsCount(data?.count || 0);
    } catch (e) {
      // Do nothing
    }
  };

  const playAudio = (audio) => {
    try {
      const sound = new Audio(audio);
      sound.play();
    } catch (e) {
      // Do nothing
    }
  };

  const loadChatSupportScript = () => {
    const companyDot = value.user?.customer?.dot;
    const staffId = value.user?.id;

    const externalId = !!companyDot && !!staffId ? `${companyDot}.staff.${staffId}` : null;

    window.fcWidgetMessengerConfig = {
      externalId,
      restoreId: externalId,
      firstName: value.user?.first_name || '',
      lastName: value.user?.last_name || '',
      email: value.user?.email || '',
      phone: value.user?.phone_number || '',
    };
    const script = document.createElement('script');
    script.src = '//fw-cdn.com/11567328/4220483.js';
    script.setAttribute('chat', 'true');

    const head = document.head || document.getElementsByTagName('head')[0];
    head.insertBefore(script, head.firstChild);
  };

  const removeChatSupportScript = () => {
    if (window.fcWidget && window.fcWidget.isInitialized() === true) {
      window.fcWidget.destroy();
    }
  };

  useEffect(() => {
    const accountStatusData = JSON.parse(localStorage.getItem('accountStatus'));
    if (accountStatusData) {
      setAccountStatus(accountStatusData);
    }
  }, [value]);

  useEffect(() => {
    if (!value?.user?.id) {
      return;
    }
    getSettlementsCount();
    getOfferedCount();
    loadChatSupportScript();

    return () => {
      removeChatSupportScript();
    };
  }, [value?.user?.id]);

  useEffect(() => {
    if (value?.user && permissions.includes(PERMISSIONS.PLANNER.name)) {
      const channelName = `private-shipment-bids-count-update.${value.user.customer.dot}`;
      const channel2Name = `private-shipment-bid-updates.${value.user.customer.dot}`;

      const channel = PusherJs.subscribe(channelName);
      const channel2 = PusherJs.subscribe(channel2Name);

      channel.bind('shipment-bids-count-update', (data) => {
        if (data?.message?.data?.count || data?.message?.data?.count === 0) {
          setOfferedShipmentsCount(Number(data.message.data.count));
        }
      });

      channel2.bind('shipment-bid-updates', (data) => {
        if (data?.message?.data?.data?.bid) {
          playAudio(bidReceivedSound);
        }
        if (data?.message?.data?.data?.accepted_award) {
          playAudio(awardAcceptedSound);
        }
      });

      return () => {
        PusherJs.unsubscribe(channelName);
        PusherJs.unsubscribe(channel2Name);
      };
    }
  }, [value?.user?.customer?.dot, permissions]);

  useEffect(() => {
    let channelName;
    let channelRecentActivity_table;
    let channelRecentActivity;

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const dot = user?.customer?.dot;
      channelRecentActivity = `private-get-shipment-recent-activity.${dot}`;

      if (user) {
        channelRecentActivity_table = PusherJs.subscribe(channelRecentActivity);

        channelRecentActivity_table.bind('get-shipment-recent-activity', (e) => {
          if (e?.message?.data?.shipment_recent_activity) {
            dispatch(addRecentActivity(e?.message?.data?.shipment_recent_activity));
          }
        });
      }
    } catch (e) {
      // Do nothing
    }

    return () => {
      !!channelName && PusherJs.unsubscribe(channelName);
      !!channelRecentActivity && PusherJs.unsubscribe(channelName);
    };
  }, []);

  const getProductivityEssential = async () => {
    getProductivityEssentialsSetting()
      .then((res) => {
        if (res) {
          setFavoritesOnHeader(res.favorite_on_header);
          setOpenInNewTab(res.open_in_new_tab);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  useEffect(() => {
    getProductivityEssential();
  }, []);

  useEffect(() => {
    let channelName;
    try {
      const dot = user?.customer?.dot;
      channelName = `private-update-user-status.${dot}.${user.id}`;

      if (user) {
        const channelUserStatusTable = PusherJs.subscribe(channelName);
        channelUserStatusTable.bind('update-user-status', (e) => {
          const storageUser = JSON.parse(localStorage.getItem('user'));

          const status = e?.message?.data?.status;
          if (status) {
            const updatedUser = { ...storageUser, login_status: Number(status) };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            const statusData = LOGIN_STATUSES[Number(status)];
            setUserStatus(statusData || userStatus);
          }
        });
      }
    } catch (e) {
      // Do nothing
    }

    return () => {
      !!channelName && PusherJs.unsubscribe(channelName);
    };
  }, []);

  useEffect(() => {
    let channelName;
    try {
      const dot = user?.customer?.dot;
      channelName = `private-update-department.${dot}`;
      if (user) {
        const channelDepartmentsTable = PusherJs.subscribe(channelName);
        channelDepartmentsTable.bind('update-department', (e) => {
          const storageUser = JSON.parse(localStorage.getItem('user'));

          const { department, user_id } = e?.message?.data || {};

          if (department && Number(value?.user?.id) === Number(user_id)) {
            setAuth({ ...value, user: { ...value.user, department } });
            const updatedUser = { ...storageUser, department };
            localStorage.setItem('user', JSON.stringify(updatedUser));
          }
        });
      }
    } catch (e) {
      // Do nothing
    }

    return () => {
      !!channelName && PusherJs.unsubscribe(channelName);
    };
  }, []);

  useEffect(() => {
    const avatar = JSON.parse(localStorage.getItem('userAvatar')) || {};
    if (Object.keys(avatar).length !== 0) {
      if (!value.profile_logo) {
        const avtarPath = avatar?.path;
        setProfileLogo(avtarPath);
        setTimeout(() => {
          localStorage.removeItem('userAvatar');
        }, 3000);
      }

      userData.profileLogo = profileLogo;
    }

    if (value.profile_logo !== undefined) {
      setProfileLogo(value.profile_logo);
      userData.profileLogo = profileLogo;
    }
  }, [profileLogo, value, userData]);

  const location = useLocation();
  return (
    <LayoutContext.Provider
      value={{
        chatClient,
        chatUser,
        dot_num,
        showNotepad,
        setShowNotepad,
        users,
        path,
        screen,
        registration,
        menuButtons,
        userStatus,
        userData,
        timeZone,
        setCartItemsData,
        cartItemsData,
        favorites,
        messageNotification,
        onSetUserData,
        setFavoritesOnHeader,
        openInNewTab,
        setOpenInNewTab,
        favoritesOnHeader,
        onDeleteFavorites,
        onAddFavorites,
        setTimeZone,
        setHardwarePurchaseTab,
        hardwarePurchaseTab,
        unreadSettlementsCount,
        setUnreadSettlementsCount,
        offeredShipmentsCount,
        userStatusUpdate: (data) => setUserStatus(data),
      }}
    >
      <div
        className='layout'
        style={
          location.pathname !== '/map'
            ? {
                backgroundColor: use('#ffffff', '#161D32'),
                color: use('#161D32', '#ffffff'),
                width: theme.appWidth === 'fluid' ? '100%' : '1300px',
                overflow: open && 'hidden',
                paddingBottom: 12,
              }
            : {
                backgroundColor: use('#ffffff', '#161D32'),
                color: use('#161D32', '#ffffff'),
                width: theme.appWidth === 'fluid' ? '100%' : '1300px',
                overflow: open && 'hidden',
                height: '100vh',
              }
        }
      >
        <div className='layout_container' style={{ display: 'flex' }}>
          <LeftMenu />
          {open && (
            <IdleModal modalData={modalData} setOpen={(value) => setOpen(value)} handleStillHere={handleStillHere} />
          )}
          <div className='global_right_block'>
            {accountStatus && (
              <div style={{ marginLeft: !theme.condensed ? '195px' : 0 }}>
                <AccountStatus accountStatus={accountStatus} />
              </div>
            )}
            <Header />
            <div
              className='outlet_wrapper'
              style={{
                marginLeft: !theme.condensed ? 210 : 20,
                marginTop: '10px',
              }}
            >
              <Outlet
                context={{
                  registration,
                  favorites,
                  getLocalStorageData,
                }}
              />
            </div>
            {location.pathname !== '/map' ? <Footer /> : ''}
          </div>
        </div>
        <RateUs />
        {location.pathname !== '/map' ? <MinimizedGlobal /> : ''}
        <NotesEditModal
          id={activeRowEditItem?.id}
          isOpen={Object.keys(activeRowEditItem).length > 0}
          minimizeLabel={activeRowEditItem?.label}
          setIsOpen={() => {
            dispatch(updateRowEditItem({}));
            dispatch(handleOutside(true));
          }}
        />
        {isOpenSendPaymentModal.open && (
          <SendPaymentModal
            data={isOpenSendPaymentModal.data}
            isAch={isOpenSendPaymentModal.isAch}
            fromTabName={isOpenSendPaymentModal.fromTabName}
            defaultStep={isOpenSendPaymentModal.defaultStep}
            onClose={() => dispatch(setIsOpenSendPaymentDefault())}
          />
        )}
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
