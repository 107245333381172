import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from 'common/Loader';
import Notes from 'components/TableShipments/detailsRow/steps/Notes';
import BillingCharges from 'components/TableShipments/detailsRow/steps/BillingCharges';
import Schedule from 'components/TablePlaner/helpers/RecurrningDetails/steps/Schedule';
import OverView from 'components/TablePlaner/helpers/RecurrningDetails/steps/OverView';
import PlanAhead from 'components/TablePlaner/helpers/RecurrningDetails/steps/PlanAhead';
import ShipmentsTable from 'components/TablePlaner/helpers/RecurrningDetails/steps/ShipmentsTable';
import { recurringDataConverter } from 'components/TablePlaner/helpers/RecurrningDetails/converters';
import { getRecurringLane } from 'Api/Planner';
import DetailsHeader from './helpers/DetailsHeader';

const RecurringDetails = () => {
  const { id } = useParams();
  const { activeTabRecurring } = useSelector((state) => state?.planner);
  const [lane, setLane] = useState(null);
  const [loading, setLoading] = useState(false);

  const getLaneData = async () => {
    try {
      const { data } = await getRecurringLane(id);
      const convertedData = recurringDataConverter(data);
      setLane(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const currentTab = useMemo(() => {
    switch (+activeTabRecurring.id) {
      case 1:
        return <OverView infoHeader={lane} isShipmentTable={false} getRecurringData={getLaneData} />;
      case 2:
        return <ShipmentsTable data={lane} getLaneData={getLaneData} />;
      case 3:
        return <BillingCharges infoHeader={lane} isRecurring onUpdate={getLaneData} />;
      case 4:
        return <Notes isRecurring />;
      case 5:
        return loading ? null : <Schedule data={lane} onSuccess={getLaneData} />;
      case 6:
        return loading ? null : <PlanAhead data={lane} onSuccess={getLaneData} />;
      default:
    }
  }, [activeTabRecurring, lane, loading]);

  useEffect(() => {
    setLoading(true);
    getLaneData();
  }, []);

  if (loading || !lane) {
    return <Loader loading={loading} />;
  }

  return (
    <div>
      <DetailsHeader data={lane} onUpdateSuccess={getLaneData} />
      {currentTab}
    </div>
  );
};

export default RecurringDetails;
