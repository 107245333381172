import { number, object, string, ref } from 'yup';

export const validationSchema = object().shape({
  bank_name: string().trim().required('Bank Name is required!'),
  address: string().trim().required('Required'),
  zipcode: string().trim().required('Required'),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  ach_account_number: string().when('ach_enabled', {
    is: true,
    then: string().required('Account number is required!'),
    otherwise: string().nullable(),
  }),
  confirm_ach_account_number: string().when('ach_enabled', {
    is: true,
    then: string()
      .required('Account number confirmation is required!')
      .oneOf([ref('ach_account_number'), null], 'Account numbers must match!'),
    otherwise: string().nullable(),
  }),
  ach_routing_number: string().when('ach_enabled', {
    is: true,
    then: string().required('Account number is required!'),
    otherwise: string().nullable(),
  }),
  confirm_ach_routing_number: string().when('ach_enabled', {
    is: true,
    then: string()
      .required('Routing number confirmation is required!')
      .oneOf([ref('ach_routing_number'), null], 'Account numbers must match!'),
    otherwise: string().nullable(),
  }),
  wire_account_number: string().when('wire_enabled', {
    is: true,
    then: string().required('Account number is required!'),
    otherwise: string().nullable(),
  }),
  confirm_wire_account_number: string().when('wire_enabled', {
    is: true,
    then: string()
      .required('Account number confirmation is required!')
      .oneOf([ref('wire_account_number'), null], 'Account numbers must match!'),
    otherwise: string().nullable(),
  }),
  wire_routing_number: string().when('wire_enabled', {
    is: true,
    then: string().required('Account number is required!'),
    otherwise: string().nullable(),
  }),
  confirm_wire_routing_number: string().when('wire_enabled', {
    is: true,
    then: string()
      .required('Routing number confirmation is required!')
      .oneOf([ref('wire_routing_number'), null], 'Account numbers must match!'),
    otherwise: string().nullable(),
  }),
});
