import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { capitalize } from 'lodash';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import AddressAutocomplete from 'common/AddressAutocomplete';
import { getCities, getCountries, getStates } from 'Api/JobPositions';
import { SAddressWrapper, SFlexRow } from './AddressFields.styles';

const limitedCountries = [
  { sortname: 'US', name: 'United States', id: 231, phonecode: 1 },
  { sortname: 'CA', name: 'Canada', id: 38, phonecode: 1 },
  { sortname: 'MX', name: 'Mexico', id: 142, phonecode: 52 },
];

const AddressFields = forwardRef(
  (
    {
      gap,
      size,
      label,
      values,
      handleChange,
      touchedErrors,
      hideLabel,
      disabled,
      defaultAddress,
      limitCountries,
      isOneRow,
      isOneColumn,
      hideAddress,
      hideAddress2,
      hideZipcode,
      required = true,
      setLatLng = false,
      autocompleteProps,
    },
    ref
  ) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const isDefaultAddressUsed = useRef(false);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [loadingStates, setLoadingStates] = useState(false);
    const [loadingCities, setLoadingCities] = useState(false);
    const { country, state, city } = defaultAddress || {};

    const getCountriesList = async () => {
      try {
        setLoadingCountries(true);

        const response = await getCountries();
        setCountries(response.data);
        if (country && !isDefaultAddressUsed.current) {
          const matchingCountry = response.data.find(
            (item) => item.sortname === country || item.name.toLowerCase() === country.toLowerCase()
          );
          if (matchingCountry) {
            handleChange('country', matchingCountry);
          }
        }
      } catch (e) {
        /* empty */
      } finally {
        setLoadingCountries(false);
      }
    };

    const getStatesList = async (params) => {
      try {
        setLoadingStates(true);
        const response = await getStates(params);
        setStates(response.data);
        if (state && !isDefaultAddressUsed.current) {
          const matchingState = response.data.find(
            (item) => item.short_name === state || item.name.toLowerCase() === state.toLowerCase()
          );
          if (matchingState) {
            handleChange('state', matchingState);
          }
        }
      } catch (e) {
        /* empty */
      } finally {
        setLoadingStates(false);
      }
    };

    const getCitiesList = async (stateId) => {
      try {
        setLoadingCities(true);
        const response = await getCities({ state_id: stateId });
        setCities(response.data);
        if (city && !isDefaultAddressUsed.current) {
          const matchingCity = response.data.find((item) => item.name.toLowerCase() === city.toLowerCase());
          if (matchingCity) {
            handleChange('city', matchingCity);
          } else {
            const { data } = await getCities({ state_id: stateId, city_name: capitalize(city) });
            const createdCity = data.find((item) => item.name.toLowerCase() === city.toLowerCase());
            setCities((prevState) => [...prevState, createdCity]);
            handleChange('city', createdCity || values.city);
          }
          isDefaultAddressUsed.current = true;
        }
      } catch (e) {
        /* empty */
      } finally {
        setLoadingCities(false);
      }
    };

    const onAddressSelect = (data) => {
      handleChange('country', data.country);
      handleChange('state', data.state);
      handleChange('city', data.city);
    };

    const changeAddress = ({ address, zip, lat, lng }) => {
      handleChange('address', address);
      handleChange('zipcode', zip || values.zipcode);

      if (setLatLng) {
        handleChange('lat', lat);
        handleChange('lng', lng);
      }
    };

    useEffect(() => {
      if (defaultAddress) {
        isDefaultAddressUsed.current = false;
      }

      getCountriesList();
    }, [defaultAddress]);

    useEffect(() => {
      if (values.country) {
        getStatesList({ 'country_id[]': [values.country.id] });
      }
    }, [values.country]);

    useEffect(() => {
      if (values.state) {
        getCitiesList(values.state.id);
      }
    }, [values.state]);

    return (
      <SAddressWrapper $isOneRow={isOneRow} $gap={gap}>
        {!hideAddress && (
          <div className='address-inputs'>
            <AddressAutocomplete
              size={size}
              ref={ref}
              required={required}
              label={hideLabel ? null : label || 'Address'}
              name='address'
              id='address'
              onSuccess={onAddressSelect}
              changeAddress={changeAddress}
              onChange={handleChange}
              value={values.address}
              error={touchedErrors?.address}
              disabled={disabled}
              {...autocompleteProps}
            />
            {!hideAddress2 && (
              <Input
                size={size}
                name='address2'
                className='w-100'
                placeholder='Address 2'
                onChange={handleChange}
                value={values.address2}
                error={touchedErrors?.address2}
                disabled={disabled}
              />
            )}
          </div>
        )}
        <SFlexRow
          $isOneRow={isOneRow}
          $isOneColumn={isOneColumn}
          $onlyCountryStateCity={hideAddress && hideZipcode}
          $gap={gap}
        >
          <div className='address-input-wrapper'>
            <Autocomplete
              size={size}
              name='country'
              placeholder='Select Country..'
              value={values.country}
              onChange={(e, value) => {
                handleChange('country', value);
                handleChange('state', null);
                handleChange('city', null);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={limitCountries ? limitedCountries : countries}
              disableClearable={false}
              error={touchedErrors?.country}
              disabled={disabled}
              loading={loadingCountries}
            />
          </div>
          <div className='address-input-wrapper'>
            <Autocomplete
              size={size}
              name='state'
              placeholder='Select State..'
              value={values.state}
              onChange={(e, value) => {
                handleChange('state', value);
                handleChange('city', null);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={values?.country ? states : []}
              disableClearable={false}
              error={touchedErrors?.state}
              disabled={disabled}
              loading={loadingStates}
            />
          </div>
          <div className='address-input-wrapper'>
            <Autocomplete
              size={size}
              name='city'
              placeholder='Select City..'
              value={values.city}
              onChange={(e, value) => handleChange('city', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={values?.country && values?.state ? cities : []}
              disableClearable={false}
              error={touchedErrors?.city}
              disabled={disabled}
              loading={loadingCities}
            />
          </div>
          {!hideZipcode && (
            <div className='address-input-wrapper'>
              <Input
                size={size}
                name='zipcode'
                className='w-100'
                placeholder='Zip Code'
                onChange={handleChange}
                value={values.zipcode}
                error={touchedErrors?.zipcode}
                disabled={disabled}
              />
            </div>
          )}
        </SFlexRow>
      </SAddressWrapper>
    );
  }
);

export default AddressFields;
