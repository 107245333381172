import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 8, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Account/Payee', 8, top);
  doc.text('Reference', 60, top);
  doc.text('Memo', 86, top);
  doc.text('Date', 118, top);
  doc.text('Amount', 142, top);
  doc.text('% of Expense', 162, top);
  doc.text('Cost Per Mile', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createTableRow = (doc, data, top, formatDate, currency) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  if (data.isAccount) {
    doc.setFont('Inter', 'normal', 700);
  } else {
    doc.setFont('Inter', 'normal', 400);
  }
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  if (!data.isBlank) {
    if (data.payee) {
      doc.text(
        `${data.payee?.name <= 30 ? data.payee?.name || '' : data.payee?.name?.substring(0, 30) || '-'}`,
        8,
        top
      );
    } else {
      doc.text(`${data.account_name || '-'}`, 8, top);
    }
    doc.text(
      `${data.reference_id <= 14 ? data.reference_id || '' : data.reference_id?.substring(0, 14) || ''}`,
      60,
      top
    );
    doc.text(`${data.memo <= 14 ? data.memo || '' : data.memo?.substring(0, 14) || ''}`, 86, top);
    doc.text(`${data.date ? `${formatDate(data.date)}` : ''}`, 118, top);
    doc.text(`${currency}${formatAmount(data.amount)}`, 142, top);
    doc.text(`${formatAmount(data.percentage)}%`, 162, top);
    doc.text(`${formatAmount(data.miles, 4)}`, pageWidth - 8, top, { align: 'right' });
  }
};

const createTableRows = (doc, records, formatDate, currency) => {
  if (!records?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  records.forEach((record, index) => {
    createTableRow(doc, record, top + index * 5, formatDate, currency);
  });

  return top + records.length * 5;
};

export const generatePDF = async (
  reportItems,
  title,
  download,
  dateRange,
  formatDate,
  convertToCustomerTime,
  currency
) => {
  if (!reportItems?.length) {
    return;
  }

  const { start, end } = dateRange;

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text(`${title}`, pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      28,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, 28, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const firstPageRecords = reportItems.slice(0, 40);
  const restRecords = reportItems.slice(40);

  createTableRows(doc, firstPageRecords, formatDate, currency);

  for (let i = 0; i < (restRecords?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageRecords = restRecords.slice(i * 45, (i + 1) * 45);
    createTableRows(doc, currentPageRecords, formatDate, currency);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc);
  }

  doc.setDocumentProperties({
    title: `${title} - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}`,
  });

  const url = doc.output('datauristring', { filename: `${title}` });
  const blob = doc.output('blob');

  if (download) {
    doc.save(`${title} - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}.pdf`);
  }
  return { blob, url };
};
