import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import Tabs from 'react-bootstrap/Tabs';
import { CircularProgress } from '@mui/material';
import { Modal, Button, Tab } from 'react-bootstrap';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import DriverHorizontalCard from '../DriverIDCard/DriverIDCard';
import './GenerateIdCardModal.css';

export default function GenerateId({ ...props }) {
  const [key, setKey] = useState('horizontal');
  const showToaster = useShowToaster();
  const { use } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isPathLoading, setIsPathLoading] = useState(false);

  const [idCardDetail, setIdCardDetail] = useState({ type: 'jpg', size: '3.38 x 2.12' });
  const ref = React.createRef();
  const horizontalRef = React.createRef();

  const imageDownloader = () => {
    const input = document.querySelector(key === 'vertical' ? '#vertical' : '#horizontal');
    html2canvas(input, { scale: 4 })
      .then((canvas) => {
        const imgData = canvas.toDataURL(`image/${idCardDetail.type}`);
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `${props.driverInfo?.fname || props.driverInfo?.first_name}${
          props.driverInfo.lname || props.driverInfo?.last_name
        }.${idCardDetail.type}`;
        link.click();
        setIsLoading(false);
        showToaster({ type: 'success', message: 'Your ID Card has been generated successfully.' });
        props.onHide();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='modal-container-card driver-style-wrap'>
      <Modal {...props} dialogClassName='modal-dialog-box' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Generate ID Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box'
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.white, palette.darkborder),
          }}
        >
          <Tabs id='controlled-tab-example' activeKey={key} onSelect={(k) => setKey(k)} className='mb-3 my-tab'>
            <Tab eventKey='horizontal' className='my-tab-content' title='Horizontal'>
              <div
                className='modal-body-box'
                style={{
                  backgroundColor: use(palette.gray0, palette.dark900),
                  color: use(palette.gray50, palette.gray900),
                }}
              >
                <div>
                  <DriverHorizontalCard
                    isPathLoading={isPathLoading}
                    setIsPathLoading={setIsPathLoading}
                    horizontalref={horizontalRef}
                    isVertical={false}
                    companyInfo={props?.companyInfo}
                    driverInfo={props.driverInfo}
                    reference={ref}
                    idCardDetail={idCardDetail}
                    setIdCardDetail={setIdCardDetail}
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey='vertical' className='my-tab-content' title='Vertical'>
              <div
                className='modal-body-box'
                style={{
                  backgroundColor: use(palette.gray0, palette.dark900),
                  color: use(palette.gray50, palette.gray900),
                }}
              >
                <div>
                  <DriverHorizontalCard
                    isPathLoading={isPathLoading}
                    setIsPathLoading={setIsPathLoading}
                    horizontalref={horizontalRef}
                    isVertical
                    companyInfo={props?.companyInfo}
                    driverInfo={props.driverInfo}
                    reference={ref}
                    idCardDetail={idCardDetail}
                    setIdCardDetail={setIdCardDetail}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {isLoading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <div onClick={() => setIsLoading(true)}>
                <Button disabled={isPathLoading} className='next-step' type='submit' onClick={imageDownloader}>
                  Generate
                </Button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
