import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Overlay, OverlayTrigger, Popover } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { useTheme } from 'context/themeContext';
import { TableContext } from 'context/tableContext';
import { ReactComponent as Success } from 'assets/icons/succes2.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { ReactComponent as Empty } from 'assets/icons/empty.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import { ReactComponent as Required } from 'assets/icons/detention.svg';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';

import {
  CreateEquipmentAtStop,
  DeleteEquipmentStops,
  GetEquipmentStops,
  GetEquipmentTrailer,
  GetEquipmentVehicle,
  GetStopPoints,
  getStopPointTableSettings,
} from 'Api/StopPoint';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from '../Typography';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import styles from './EquipmentStopPoint.module.css';
import CustomModal from '../CustomModal/CustomModal';
import MaterialTableWrapper from '../MaterialTableWrapper';
import { DocumentTableSort } from '../DocumentsTableSort/DocumentsTableSort';
import EquipmentUpdateModal from './EquipmentUpdateModal/EquipmentUpdateModal';
import Preloader from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import EquipmentStopPointHeader from './EquipmentStopPointHeader/EquipmentStopPointHeader';
import EquipmentStopPointFooter from './EquipmentStopPointFooter/EquipmentStopPointFooter';
import EquipmentStopPointSearch from './EquipmentStopPointSearch/EquipmentStopPointSearch';
import PopoverSettingEquipmentAtStopPoint from './PopoverSettingEquipmentAtStopPoint/PopoverSettingEquipmentAtStopPoint';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomButton from '../CustomButton/CustomButton';
import CheckoutTrailer from '../TableShipments/detailsRow/steps/Detention/CheckoutTrailer';
import UserInfo from '../UserInfo';
import DarkOverlay from '../DarkOverlay';

const EquipmentStopPoint = () => {
  const elementsRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, use } = useTheme();
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const myTableRef = useRef(null).current;
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);
  const [dragItem, setDragItem] = useState([]);
  const [stopPointList, setStopPointList] = useState({ data: [] });
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [oneTab, setOneTab] = useState(true);
  const [showTool, setShowTool] = useState({});
  const [checkoutData, setCheckoutData] = useState(null);
  const [updateModal, setUpdateModal] = useState({
    visible: false,
  });
  const [tableColumn, setTableColumn] = useState([]);
  const [vehicles, setVehicles] = useState(null);
  const [trailers, setTrailers] = useState(null);
  const [stopPoints, setStopPoints] = useState(null);
  const [stopTabs, setStopTabs] = useState(null);
  const [filter, setFilter] = useState({
    switchRadioButtons: {
      dateFormat: '1',
      timeFormat: '1',
    },
    forAll: false,
    filterTableTop: [
      {
        key: 'vehicles',
        label: 'Vehicles',
        value: 0,
        id: 0,
        count: 0,
      },
      {
        key: 'trailers',
        label: 'Trailers',
        value: 1,
        id: 1,
        count: 0,
      },
    ],
    selectedValues: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    tab: 0,
    page: 1,
    query: '',
    history: 0,
    page_size: 25,
  });

  const columns = useMemo(() => {
    return [
      {
        field: 'location_name',
        title: <DocumentTableSort title='LOCATION NAME' field='location_name' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const location_name = rowData?.shipment_stop?.stop_point?.location_name;
          const shipment_id = rowData?.shipment_id;
          const stop_location = rowData?.stop_point?.location_name;
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200), marginRight: 5 }}>
                  {shipment_id ? location_name : stop_location || ''}
                </Typography>
              </div>
            </div>
          );
        },
      },
      {
        field: 'equipment_id',
        title: <DocumentTableSort title='EQUIPMENT AT STOP' field='equipment_id' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const equipment_id = rowData?.equipment?.equipment_id;
          const equipment_type = rowData?.equipment?.equipment_type?.title;
          const length = rowData?.equipment?.length?.length;
          const unit = rowData?.equipment?.length?.unit;
          return (
            <div style={{ display: 'flex', gap: 8 }}>
              {equipment_id && (
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {equipment_id || ''}
                </Typography>
              )}
              {length && (
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {length || ''}
                  {unit || ''}
                </Typography>
              )}
              {equipment_type && (
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {equipment_type || ''}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        field: 'customer',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            CUSTOMER
          </Typography>
        ),
        render: (rowData) => {
          const customer = rowData?.shipment_billing?.billing_customer?.customer_name;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {customer || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'shipment_id',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            SHIPMENT ID
          </Typography>
        ),
        render: (rowData) => {
          const shipment_id = rowData?.shipment_id;
          return (
            <div style={{ display: 'flex' }}>
              <Typography
                className={styles.view_text}
                variant='b2'
                style={{ color: '#4F5AED' }}
                onClick={(e) => {
                  if (!oneTab && shipment_id) {
                    e.stopPropagation();
                    navigate(`/shipment/${shipment_id}`);
                  }
                }}
              >
                {shipment_id || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'cargo_onboard',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            CARGO ONBOARD
          </Typography>
        ),
        render: (rowData) => {
          const cargo_onboard = rowData?.cargo_onboard_type?.title;
          const cargo_id = rowData?.cargo_onboard_id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {cargo_id === '1' ? (
                <div style={{ marginRight: 7 }}>
                  <Success />
                </div>
              ) : cargo_id === '2' ? (
                <div style={{ marginRight: 7 }}>
                  <Reload />
                </div>
              ) : cargo_id === '3' ? (
                <div style={{ marginRight: 7 }}>
                  <Empty />
                </div>
              ) : null}
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {cargo_id === '3' ? 'Empty' : cargo_onboard || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'arrived_date',
        title: <DocumentTableSort title='ARRIVED DATE' field='arrived_date' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const arrived_date = rowData?.arrived_date;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {arrived_date ? convertToCustomerTime(arrived_date) : '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'duration',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DURATION
          </Typography>
        ),
        render: (rowData) => {
          const duration_days = rowData?.duration_days;
          const duration_hours = rowData?.duration_hours;
          const duration_minutes = rowData?.duration_minutes;
          return (
            <div style={{ display: 'flex', gap: 6 }}>
              {(!!duration_days || duration_days === 0) && (
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {duration_days}d
                </Typography>
              )}
              {(!!duration_hours || duration_hours === 0) && (
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {duration_hours}h
                </Typography>
              )}
              {(!!duration_minutes || duration_minutes === 0) && (
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {duration_minutes}m
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        field: 'detention',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DETENTION
          </Typography>
        ),
        render: (rowData) => {
          const detention = rowData?.detention;
          const shipment_id = rowData?.shipment_id;

          return (
            <div style={{ display: 'flex' }}>
              {detention === 1 ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: 6 }}
                  onClick={() => {
                    navigate(`/shipment/${shipment_id}`);
                  }}
                >
                  <Required style={{ fill: '#DC4067' }} />
                  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Action Required
                  </Typography>
                </div>
              ) : detention === 0 ? (
                <div>
                  <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Action Not Required
                  </Typography>
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        field: 'updated_by',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            UPDATED BY
          </Typography>
        ),
        render: (rowData) => {
          const updated_by = rowData?.updated_by;
          const updated_by_type = rowData?.updated_by_type;
          return (
            <div style={{ display: 'flex' }}>
              <div>
                {updated_by_type === 'App\\User' ? (
                  <UserInfo
                    size='32px'
                    statusSize='7px'
                    data={{
                      ...(updated_by || {}),
                      image: updated_by?.profile_logo,
                      name: `${updated_by?.first_name} ${updated_by?.last_name}`,
                      user_type: 'staff',
                    }}
                  />
                ) : updated_by_type === 'App\\Driver' ? (
                  <UserInfo
                    size='32px'
                    statusSize='7px'
                    data={{
                      ...(updated_by || {}),
                      name: `${updated_by?.fname} ${updated_by?.lname}`,
                      user_type: 'driver',
                    }}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <div style={{ height: 30 }}>
                      <figure
                        style={{
                          display: 'flex',
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                        }}
                      >
                        <img
                          style={{
                            display: 'flex',
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                          }}
                          src={truckinLogo}
                          alt='img'
                        />
                      </figure>
                    </div>
                    <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                      System
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        field: 'update',
        title: <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }} />,
        render: (rowData) => {
          return (
            <div className={styles.lastRow}>
              <div onMouseLeave={() => setShowTool((prev) => ({ ...prev, [rowData.id]: false }))}>
                <div
                  ref={elementsRef?.current?.[rowData.id]}
                  className={classNames([styles.editIconBox, { [styles.show]: showTool[rowData.id] }])}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTool((prev) => ({ ...prev, [rowData.id]: true }));
                  }}
                >
                  <EditIcon className={styles.editIcon} />
                </div>

                <Overlay show={!!showTool[rowData.id]} target={elementsRef?.current?.[rowData.id]} placement='left'>
                  {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div {...props} className={styles.dropDown}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setUpdateModal({
                            visible: true,
                            ...rowData,
                          });
                        }}
                        className={styles.text}
                      >
                        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                          Update
                        </Typography>
                      </div>
                      {!!rowData.detention && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/shipment/${rowData?.shipment_id}`, {
                              state: {
                                ...rowData,
                                defaultTab: 6,
                              },
                            });
                          }}
                          className={styles.text}
                        >
                          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                            Bill Detention
                          </Typography>
                        </div>
                      )}
                      {!rowData.detention && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setCheckoutData(rowData);
                          }}
                          className={styles.text}
                        >
                          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                            Checkout
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                </Overlay>
              </div>
            </div>
          );
        },
      },
    ];
  }, [vehicles?.data, trailers?.data, showTool, theme, elementsRef, filter]);

  useEffect(() => {
    const refs = {};
    if (oneTab) {
      vehicles?.data?.forEach((item) => {
        refs[item.id] = createRef();
      });
    } else if (!oneTab) {
      trailers?.data?.forEach((item) => {
        refs[item.id] = createRef();
      });
    }
    elementsRef.current = refs;
  }, [oneTab, vehicles, trailers]);

  useEffect(async () => {
    getTrailersData(selectedFilters).then();
    await getStopPoints().then();
    await getStopsTabs().then();
  }, [activeTab, oneTab]);

  useEffect(() => {
    if (stopPoints) {
      const activeIds = stopTabs.map((tab) => Number(tab?.stop_point?.id));
      const filtered = stopPoints.filter((el) => !activeIds.includes(Number(el?.id)));
      setStopPoints(filtered);
    }
  }, [stopTabs]);

  useEffect(() => {
    if (selectedFilters.query !== debouncedSearch) {
      setSelectedFilters({ ...selectedFilters, query: debouncedSearch, page: 1 });
    }
  }, [debouncedSearch, activeTab]);

  useEffect(() => {
    getVehiclesData(selectedFilters).then();
    getTrailersData(selectedFilters).then();
  }, [selectedFilters, oneTab, activeTab]);

  useEffect(async () => {
    if (location?.state?.selectedStopId) {
      await getStopPoints().then();
      setActiveTab(location.state.selectedStopId);
      setSelectedFilters({ ...selectedFilters, tab: location.state.tab });
    }
  }, [location?.state?.selectedStopId]);

  useEffect(() => {
    getSettings();
  }, [debouncedSearch, selectedFilters]);

  const getStopPoints = () => {
    return GetStopPoints().then((res) => {
      const stops = res.data.filter((item) =>
        oneTab ? item.stop_point_vehicles_count : item.stop_point_trailers_count
      );
      setStopPoints(stops);
    });
  };

  const getStopsTabs = () => {
    return GetEquipmentStops().then((res) => {
      setStopTabs(res?.data);
    });
  };

  const getVehiclesData = async (filters) => {
    const { page, page_size, tab, query, sort, history, ...rest } = filters || {};
    if (!sort) {
      setLoading(true);
    }
    try {
      const params = {
        page,
        history: 0,
        page_size,
        ...(!!activeTab && { stop_point_id: activeTab }),
        ...(!!query && { query }),
        ...sort,
        ...rest,
      };

      const vehicles = await GetEquipmentVehicle(params);
      setVehicles(vehicles);
      setFilter((prevState) => {
        const filterTableTop = [...prevState.filterTableTop];
        filterTableTop[0].count = vehicles?.total;
        return {
          ...prevState,
          filterTableTop,
        };
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getTrailersData = async (filters) => {
    const { page, page_size, tab, query, sort, history, ...rest } = filters || {};
    if (!sort) {
      setLoading(true);
    }

    try {
      const params = {
        page,
        history: 0,
        page_size,
        ...(!!activeTab && { stop_point_id: activeTab }),
        ...(!!query && { query }),
        ...sort,
        ...rest,
      };

      const trailers = await GetEquipmentTrailer(params);
      setTrailers(trailers);
      setFilter((prevState) => {
        const filterTableTop = [...prevState.filterTableTop];
        filterTableTop[1].count = trailers?.total;
        return {
          ...prevState,
          filterTableTop,
        };
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
    myTableRef?.dataManager?.changePageSize(rowPage);
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const PaginationComponent = (event) => {
    return (
      <EquipmentStopPointFooter
        setLoading={(v) => setLoading(v)}
        myTableRef={myTableRef}
        rowPerPage={selectedFilters.page_size}
        totalCount={oneTab ? vehicles?.total : trailers?.total}
        totalLength={oneTab ? vehicles?.data?.length : trailers?.data?.length}
        lastPage={oneTab ? vehicles?.last_page : trailers?.last_page}
        currentPage={oneTab ? vehicles?.current_page : trailers?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setStopPointList={(data) => setStopPointList(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  function sortingQuery(field, sortBy, nested_field) {
    let sortField;
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]`;
    } else {
      sortField = `sort[][${field}]`;
    }
    if (field === 'location_name') {
      sortField = `sort[stop_point][${field}]`;
    }

    setSelectedFilters({ ...selectedFilters, sort: { [sortField]: sortBy } });
  }

  const onChangeTab = (n) => {
    setOneTab(!oneTab);
    setSelectedFilters({ ...selectedFilters, tab: +n.value });
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    return cols;
  };

  const mapperSettingsData = useCallback((key) => {
    const map = {
      location_name: 'Location Name',
      equipment_id: 'Equipment At Stop',
      customer: 'Customer',
      shipment_id: 'Shipment ID',
      cargo_onboard: 'Cargo Onboard',
      arrived_date: 'Arrived Date',
      duration: 'Duration',
      billed: 'Billed',
      updated_by: 'Updated By',
    };
    return map[key] ? map[key] : key;
  }, []);

  const getSettings = useCallback(() => {
    getStopPointTableSettings({ type: 'equipmentAtStopPoints' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const tabFilterFn = (i) => !(!!oneTab && i === 'cargo_onboard');
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order.filter(tabFilterFn);
          const tableColumnsData = [];
          Object.keys(tableColumns)
            .filter(tabFilterFn)
            .forEach((key) => {
              const value = tableColumns[key];
              tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
            });

          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setFilter((prevState) => ({
            ...prevState,
            switchRadioButtons: {
              dateFormat: res.data.data.date?.toString() || '1',
              timeFormat: res.data.data.time?.toString() || '1',
            },
          }));
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [oneTab, mapperSettingsData]);

  const onRowClick = (e, rowData) => {
    if (oneTab) {
      const shipment_id = rowData?.shipment_id;
      if (shipment_id) {
        +rowData.detention === 1
          ? navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 6 } })
          : navigate(`/shipment/${rowData?.shipment_id}`);
      }
    } else {
      setUpdateModal({
        visible: true,
        ...rowData,
      });
    }
  };

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettingEquipmentAtStopPoint
          filter={filter}
          dragItem={dragItem}
          getSettings={getSettings}
          updateFilter={updateFilter}
          tableColumn={tableColumn}
          setShowPopover={(isOpen) => setShow(isOpen)}
        />
      </Popover.Body>
    </Popover>
  );

  const onChangeFilter = (e) => {
    CreateEquipmentAtStop({
      stop_point_id: e?.id,
    }).then((res) => {
      if (res?.data) {
        getStopsTabs().then();
        e.location_name = 'Select Location';
      }
    });
    const filtered = stopPoints?.filter((i) => {
      return i.id !== e.id;
    });
    setStopPoints(filtered);
    setActiveTab(e.id);
  };

  const onDeleteTab = (item) => {
    DeleteEquipmentStops(item?.id).then((res) => {
      if (res?.success) {
        setStopPoints([item?.stop_point, ...stopPoints]);
        getStopsTabs();
        if (item?.stop_point?.id === activeTab) {
          setActiveTab(null);
        }
      }
    });
  };

  const onClickTab = (item) => {
    setActiveTab(item?.stop_point?.id);
  };

  return (
    <div>
      <TableContext.Provider
        value={{
          filter,
          dragItem,
          updateFilter,
          tableColumn,
          getSettings,
          onChangeOrder: (items) => setDragItem(items),
          onsStTableColumn: (v) => setTableColumn(v),
          stopPointList,
          stopPointStatusesList: [],
        }}
      >
        <EquipmentStopPointHeader />

        <div className='top_popover_search-wrapper d-flex align-items-center'>
          <OverlayTrigger
            trigger='click'
            placement='bottom-start'
            overlay={popOverContent}
            rootClose={show}
            onToggle={setShow}
            show={show}
            defaultShow={false}
            delay={0}
          >
            <div
              className='top_popover'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                marginBottom: 0,
                marginRight: '16px',
              }}
            >
              <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
            </div>
          </OverlayTrigger>

          <EquipmentStopPointSearch search={search} onChange={setSearch} />
          <DarkOverlay visible={show} styles={{ zIndex: 500 }} />
        </div>

        <SwitchRadio
          color
          name='tableTopTabMenu'
          items={[...filter.filterTableTop]}
          value={selectedFilters.tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
        <div className={styles.filter_section}>
          <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
            {stopTabs?.map((item) => {
              return (
                <div
                  key={item?.stop_point?.id}
                  onClick={() => onClickTab(item)}
                  className={classNames([styles.item_wrapper, activeTab === item?.stop_point?.id && styles.active_tab])}
                >
                  <span className={styles.item_title}>{item?.stop_point?.location_name}</span>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteTab(item);
                    }}
                    className={styles.delete_icon}
                  >
                    <DeleteIcon style={{ fill: '#D12953' }} />
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ width: 250 }}>
            <CustomSelect
              styles={{ height: 28, width: 240 }}
              defaultText='Select Location'
              onChange={onChangeFilter}
              keyName='id'
              isSearchable
              labelName='location_name'
              menuStyles={{ width: 240 }}
              options={stopPoints}
            />
          </div>
          <CustomButton
            type='secondary'
            title='Reset'
            styleButton={{ padding: '2px 8px', margin: 0 }}
            onClick={() => setActiveTab(null)}
          />
        </div>
      </TableContext.Provider>

      {loading ? (
        <Preloader />
      ) : (
        <MaterialTableWrapper
          data={oneTab ? vehicles?.data : trailers?.data}
          rowPerPage={selectedFilters.page_size}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: PaginationComponent,
          }}
          columns={filterTableColumn(columns)}
          onRowClick={onRowClick}
        />
      )}

      <CustomModal
        className={styles.supportModal}
        showModal={updateModal.visible}
        styleBody={{ width: 1050, backgroundColor: '#F7F9FC', padding: '0 20px !important' }}
        onHide={() => setUpdateModal({ visible: false })}
        headerTitle={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Update {updateModal?.equipment?.equipment_type?.vehicle_type_id === 1 ? 'Vehicle' : 'Trailer'}{' '}
              {updateModal?.equipment?.equipment_id}
            </span>
            <DeleteIcon
              height={15}
              width={15}
              style={{ cursor: 'pointer' }}
              fill={palette.red500}
              onClick={() => setUpdateModal({ visible: false })}
            />
          </div>
        }
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <EquipmentUpdateModal
          data={updateModal}
          onSuccess={(data) => {
            setUpdateModal((prevState) => ({ ...prevState, cargo_onboard_id: data.cargo_onboard_id }));
            showToaster({ type: 'success', message: 'Trailer status has been successfully updated!' });
            getTrailersData({
              page: 1,
              page_size: 25,
            });
          }}
          onClose={() => setUpdateModal({ visible: false })}
        />
      </CustomModal>
      {!!checkoutData && (
        <CheckoutTrailer
          open={!!checkoutData}
          onClose={() => setCheckoutData(null)}
          data={checkoutData}
          onSuccess={() => {
            getTrailersData({
              page: 1,
              page_size: 25,
            });
          }}
        />
      )}
    </div>
  );
};
export default EquipmentStopPoint;
