import React from 'react';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import Autocomplete from 'common/Autocomplete';

const AccidentQuestions = ({ values, handleChange, driverShipments, loadingShipments }) => {
  return (
    <>
      <Input
        type='number'
        label='How many Vehicles are involved?'
        name='involved_vehicles_count'
        value={values.involved_vehicles_count}
        onChange={handleChange}
        onKeyDown={(e) => {
          blockNotNumberChars(e);
          blockNonPositiveNumbers(e);
        }}
      />
      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Are you injured?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'are_you_injured',
              value: 1,
              onChange: () => handleChange('are_you_injured', 1),
              checked: values.are_you_injured === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'are_you_injured',
              value: 0,
              onChange: () => handleChange('are_you_injured', 0),
              checked: values.are_you_injured === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Has anyone been injured?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'anyone_injured',
              value: 1,
              onChange: () => handleChange('anyone_injured', 1),
              checked: values.anyone_injured === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'anyone_injured',
              value: 0,
              onChange: () => handleChange('anyone_injured', 0),
              checked: values.anyone_injured === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
        {!!values.anyone_injured && (
          <div className='d-flex align-items-center gap-3 mt-2'>
            <InputLabel className='mb-0'>How many individuals?</InputLabel>
            <Input
              width='200px'
              type='number'
              name='how_many_individuals_injured'
              value={values.how_many_individuals_injured}
              onChange={handleChange}
              onKeyDown={(e) => {
                blockNotNumberChars(e);
                blockNonPositiveNumbers(e);
              }}
            />
          </div>
        )}
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Is there a fuel spill?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'is_there_fuel_split',
              value: 1,
              onChange: () => handleChange('is_there_fuel_split', 1),
              checked: values.is_there_fuel_split === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'is_there_fuel_split',
              value: 0,
              onChange: () => handleChange('is_there_fuel_split', 0),
              checked: values.is_there_fuel_split === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <Input
        label='What was your direction of travel?'
        name='direction_of_travel'
        value={values.direction_of_travel}
        onChange={handleChange}
      />

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>What where you doing at the time of the accident?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'doing_at_accident_time',
              value: 'Stopped at a control device',
              onChange: () => handleChange('doing_at_accident_time', 'Stopped at a control device'),
              checked: values.doing_at_accident_time === 'Stopped at a control device',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Stopped at a control device
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'doing_at_accident_time',
              value: 'Parked',
              onChange: () => handleChange('doing_at_accident_time', 'Parked'),
              checked: values.doing_at_accident_time === 'Parked',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Parked
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'doing_at_accident_time',
              value: 'Driving',
              onChange: () => handleChange('doing_at_accident_time', 'Driving'),
              checked: values.doing_at_accident_time === 'Driving',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Driving
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Is your vehicle jack-knifed or turned over?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'jack_knifed_turn_over',
              value: 1,
              onChange: () => handleChange('jack_knifed_turn_over', 1),
              checked: values.jack_knifed_turn_over === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'jack_knifed_turn_over',
              value: 0,
              onChange: () => handleChange('jack_knifed_turn_over', 0),
              checked: values.jack_knifed_turn_over === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Is your vehicle drivable?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'is_vehicle_drivable',
              value: 1,
              onChange: () => handleChange('is_vehicle_drivable', 1),
              checked: values.is_vehicle_drivable === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'is_vehicle_drivable',
              value: 0,
              onChange: () => handleChange('is_vehicle_drivable', 0),
              checked: values.is_vehicle_drivable === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Do you need to be towed?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'need_to_be_towed',
              value: 1,
              onChange: () => handleChange('need_to_be_towed', 1),
              checked: values.need_to_be_towed === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'need_to_be_towed',
              value: 0,
              onChange: () => handleChange('need_to_be_towed', 0),
              checked: values.need_to_be_towed === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Is freight damaged or removed from the trailer?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'is_freight_damage_trailer',
              value: 1,
              onChange: () => handleChange('is_freight_damage_trailer', 1),
              checked: values.is_freight_damage_trailer === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'is_freight_damage_trailer',
              value: 0,
              onChange: () => handleChange('is_freight_damage_trailer', 0),
              checked: values.is_freight_damage_trailer === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Has the police or first responders arrived at the scene?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'police_arrived_at_the_scene',
              value: 1,
              onChange: () => handleChange('police_arrived_at_the_scene', 1),
              checked: values.police_arrived_at_the_scene === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'police_arrived_at_the_scene',
              value: 0,
              onChange: () => handleChange('police_arrived_at_the_scene', 0),
              checked: values.police_arrived_at_the_scene === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
        {!!values.police_arrived_at_the_scene && (
          <div className='d-flex align-items-center gap-3 mt-2'>
            <InputLabel className='mb-0'>What police department:</InputLabel>
            <Input width='200px' name='police_department' value={values.police_department} onChange={handleChange} />
          </div>
        )}
      </div>

      <div className='d-flex flex-column'>
        <InputLabel className='mb-0'>Are you on the shipment?</InputLabel>
        <div className='d-flex align-items-center gap-4'>
          <CustomRadioButton
            field={{
              name: 'are_you_on_the_shipment',
              value: 1,
              onChange: () => handleChange('are_you_on_the_shipment', 1),
              checked: values.are_you_on_the_shipment === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Yes
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'are_you_on_the_shipment',
              value: 0,
              onChange: () => handleChange('are_you_on_the_shipment', 0),
              checked: values.are_you_on_the_shipment === 0,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              No
            </Typography>
          </CustomRadioButton>
        </div>
        {!!values.are_you_on_the_shipment && (
          <div className='d-flex align-items-center gap-3 mt-2'>
            <InputLabel className='mb-0'>Shipment ID:</InputLabel>
            <Autocomplete
              width='200px'
              options={driverShipments}
              loading={loadingShipments}
              value={values.shipment_number}
              onChange={(e, value) => handleChange('shipment_number', value)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AccidentQuestions;
