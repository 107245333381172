import { createSlice } from '@reduxjs/toolkit';
import { DATE_FORMAT, TIME_FORMAT } from 'utils/constants';

const initialState = {
  minimizedNotes: [],
  currency: '$',
  qtyUnit: 'pieces',
  weightUnit: 'lbs',
  dateTimeFormat: { dateFormat: DATE_FORMAT[1], timeFormat: TIME_FORMAT[1] },
  activeRowEditItem: {},
  menuSettings: false,
  isOutside: false,
  showMessage: {
    message: '',
    visible: false,
    type: 'success',
  },
  purchasedAddons: [],
  permissions: [],
  dashboardPermissions: [],
  billOfLadingTitle: 'Bill of Lading',
  connectedServices: [],
  shouldUpdateConnectedServices: Date.now(),
};
export const counterSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    addMinimizedNotes: (state, action) => {
      const founded = state.minimizedNotes.find((el) => Number(el?.id) === Number(action.payload?.id));
      if (!founded) state.minimizedNotes = [...state.minimizedNotes, action.payload];
    },
    deleteMinimizedNotes: (state, action) => {
      const newData = state.minimizedNotes.filter((el) => Number(el?.id) !== Number(action.payload));
      state.minimizedNotes = [...newData];
    },
    updateRowEditItem: (state, action) => {
      state.activeRowEditItem = { ...action.payload };
    },
    onChangeMenuSettings: (state, action) => {
      state.menuSettings = action.payload;
    },
    handleToaster: (state, action) => {
      state.showMessage = action.payload;
    },
    handleOutside: (state, action) => {
      state.isOutside = action.payload;
    },
    updateCurrency: (state, action) => {
      state.currency = action.payload;
    },
    updateWeightUnit: (state, action) => {
      state.weightUnit = action.payload;
    },
    updateQtyUnit: (state, action) => {
      state.qtyUnit = action.payload;
    },
    updateDateTimeFormat: (state, action) => {
      state.dateTimeFormat = action.payload;
    },
    updatePurchasedAddons: (state, action) => {
      state.purchasedAddons = action.payload;
    },
    updatePermissions: (state, action) => {
      state.permissions = action.payload;
    },
    updateDashboardPermissions: (state, action) => {
      state.dashboardPermissions = action.payload;
    },
    updateBillOfLadingTitle: (state, action) => {
      state.billOfLadingTitle = action.payload;
    },
    updateConnectedServices: (state, action) => {
      state.connectedServices = action.payload;
    },
    updateShouldUpdateConnectedServices: (state, action) => {
      state.shouldUpdateConnectedServices = action.payload;
    },
  },
});

export const {
  addMinimizedNotes,
  deleteMinimizedNotes,
  updateRowEditItem,
  onChangeMenuSettings,
  handleToaster,
  handleOutside,
  updateCurrency,
  updateWeightUnit,
  updateQtyUnit,
  updateDateTimeFormat,
  updatePurchasedAddons,
  updatePermissions,
  updateDashboardPermissions,
  updateBillOfLadingTitle,
  updateConnectedServices,
  updateShouldUpdateConnectedServices,
} = counterSlice.actions;

export default counterSlice.reducer;
