import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Input from 'common/Input';
import { palette } from 'utils/constants';

export const SRequirementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const SPickerWrapper = styled.div`
  width: 100%;

  .react-datepicker-wrapper {
    width: 100% !important;

    .custom-date-picker-input {
      width: 100%;
      justify-content: flex-start;
      column-gap: 8px;
      margin: 0;
    }
  }

  .disabled-date-picker {
    background-color: ${palette.gray0} !important;

    span {
      color: ${palette.gray400} !important;
    }
  }

  .react-datepicker__year-dropdown {
    height: 250px;
    background-color: ${palette.white};

    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 8px;
    }

    .react-datepicker__year-option {
      background-color: ${palette.white};
      padding: 2px 4px;
      font-size: 14px;

      &:hover {
        background-color: ${palette.gray50};
      }

      &.react-datepicker__year-option--selected_year {
        background-color: ${palette.indigo500};
        color: ${palette.white};
      }

      &:has(.react-datepicker__navigation) {
        padding: 10px;
      }
    }

    .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation {
      display: none;
    }
  }
`;

export const SFlexGridItem = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    column-gap: 16px;

    &.direction-column {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 8px;
    }

    label {
      margin-top: 0;
    }
  }
`;

export const SUploadWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 8px;

  .uploader-content {
    display: flex;
    align-items: center;
    column-gap: 6px;

    svg path {
      fill: ${palette.indigo500};
    }
  }

  &:hover .uploader-content span {
    text-decoration: underline;
  }
`;

export const SBigUploadWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding: 12px;
  border-radius: 6px;
  border: 2px dashed ${palette.gray200};
  background: ${palette.white};
  box-sizing: border-box;
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .uploader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
  }
`;

export const SFormListItem = styled(Grid)`
  &.MuiGrid-root {
    border-bottom: 1px solid ${palette.gray50};
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;

    .last-column {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 12px;

      svg {
        cursor: pointer;
      }
    }

    .delete-icon.absolute-positioned {
      position: absolute;
      top: 20px;
      right: 10px;
      cursor: pointer;
    }

    @media (max-width: 900px) {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid ${palette.gray200};

      .delete-icon {
        position: absolute;
        top: 20px;
        right: 10px;
      }
    }
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: pointer;
`;

export const SFileUploaded = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f1fa;
  border-radius: 4px;
  height: 24px;
  padding: 0 8px;
  margin: 10px 0 0 8px;
  width: max-content;

  .delete-icon {
    cursor: pointer;
  }
`;

export const SMaskWrapper = styled.div`
  position: relative;

  input {
    color: #fff;
    caret-color: black;
  }

  .input-mask {
    position: absolute;
    top: 36px;
    left: 11px;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 18px;
    max-width: ${({ $maxWidth }) => `${$maxWidth}px`};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      display: inline-block;
      width: 7px;
    }
  }
`;

export const SInput = styled(Input)`
  input {
    ::selection {
      color: #fffffe;
      background: white;
    }
  }
`;
