import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from '@mui/material/Modal';
import { useLocation, useNavigate } from 'react-router-dom';

import Pagination from 'common/Pagination';
import { TableContext } from 'context/tableContext';
import { palette, STOP_POINT_TAB } from 'utils/constants';
import { initialFilters, useColumns } from 'components/StopPoint/StopPoint.data';
import { getStopPoints, getStopPointTableSettings } from 'Api/StopPoint';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';
import StopPointHeader from './StopPointHeader';
import AddStopPointModal from '../CreateShipment/CreateModals/addStopPointModal';
import MaterialTableWrapper from '../MaterialTableWrapper';

import Preloader from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import styles from './StopPoint.module.css';

const StopPoint = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isInitialMount = useRef(true);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState(1);
  const [dragItem, setDragItem] = useState([]);
  const [stopPointList, setStopPointList] = useState({ data: [] });
  const [first, setFirst] = useState(true);
  const [showAddStopPointModal, setShowAddStopPointModal] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all' },
    allValue: '',
    shipmentValue: '',
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    forAll: false,
    filterTableTop: [
      {
        key: 'active',
        label: 'active',
        value: 1,
        id: 1,
        count: 0,
      },
      {
        key: 'inactive',
        label: 'inactive',
        value: 2,
        id: 2,
        count: 0,
      },
    ],
    selectedValues: [],
  });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});

  const getStopPointsData = async (data, updateTabCounts) => {
    const { advancedFilter: advancedFilterProps, page: pageProps } = data || {};
    const filterAdvanced = advancedFilterProps || convertFilterData(advancedFilter);

    const { page, page_size, types, sort, ...rest } = selectedFilters || {};
    if (!sort) {
      setLoading(true);
    }

    try {
      const params = {
        page: pageProps || page,
        page_size,
        status_id: tab ? [tab] : undefined,
        ...sort,
        ...rest,
        ...filterAdvanced,
      };

      pageProps && setSelectedFilters((p) => ({ ...p, page: pageProps }));

      const stopPoints = await getStopPoints(params);
      setStopPointList(stopPoints);
      setFilter((prevState) => {
        const filterTableTop = [...prevState.filterTableTop];
        if (first || updateTabCounts) {
          filterTableTop[0].count = stopPoints?.active_count;
          filterTableTop[1].count = stopPoints?.inactive_count;
        }
        setFirst(false);
        return {
          ...prevState,
          filterTableTop,
        };
      });
    } catch (e) {
      /* empty */
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location?.state?.openCreate) {
      setShowAddStopPointModal(true);
    }
  }, [location?.state]);

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, page_size: rowPage }));
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  function sortingQuery(field, sortBy, nested_field) {
    let sortField;
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]`;
    } else {
      sortField = `sort[${field}]`;
    }

    setSelectedFilters({ ...selectedFilters, sort: { [sortField]: sortBy } });
  }

  const onChangeTab = (n) => {
    setTab(+n.value);
  };

  const mapperSettingsData = useCallback((key) => {
    const map = {
      stop_point_id: 'Stop Point ID',
      location_name: 'Location Name',
      address1: 'Address',
      primary_contact_name: 'Primary Contact Name',
      status_id: 'Status',
      average_wait_time: 'Avg. Wait Time',
      working_hour_by: 'Working Hours',
      equipments_at_stop_vehicles: 'Equipments At Stop Vehicles',
      equipments_at_stop_trailers: 'Equipments At Stop Trailers',
    };
    return map[key] ? map[key] : key;
  }, []);

  const getSettings = useCallback(() => {
    getStopPointTableSettings({ type: 'stopPoint' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });

          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [mapperSettingsData]);

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    return cols;
  };

  const onRowClick = (e, value) => {
    const stoppointId = value?.id;
    navigate(`/stop-point/${stoppointId}/${STOP_POINT_TAB.GENERAL}`, { state: value });
  };

  const onOpenModal = () => {
    setShowAddStopPointModal(true);
  };

  const onChangeAddStopPointModal = (value) => {
    setShowAddStopPointModal(value);
  };

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        if (key === 'working_hours') {
          requestData.working_hours = value.map((el) => el.id);
        }
        if (key === 'status_id') {
          requestData.status_id = value.map((el) => el.id);
        }
        if (key === 'location_name') {
          requestData.location_name = value.map((el) => el.title);
        }
      } else if (typeof value === 'object') {
        // for single select
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const onSearch = (data) => {
    const clearAll = typeof data === 'object' && Object.keys(data).length === 0;
    const filterObject = data || advancedFilter;

    if (!search || clearAll) {
      delete filterObject.query;
    } else {
      filterObject.query = search;
    }

    getStopPointsData({ advancedFilter: convertFilterData(filterObject), page: 1 }).catch();
  };

  useEffect(() => {
    getSettings();
    getStopPointsData();
  }, [selectedFilters]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getSettings();
      getStopPointsData({ isTabChange: true });
    }
  }, [tab]);

  const columns = useColumns({ sortingQuery });

  return (
    <div>
      <TableContext.Provider
        value={{
          filter,
          dragItem,
          updateFilter,
          onChangeOrder: (items) => setDragItem(items),
          onsStTableColumn: (v) => setTableColumn(v),
          stopPointList,
          stopPointStatusesList: [],
        }}
      >
        <AdvancedFilter
          onSearch={onSearch}
          open={advancedOpen}
          filter={advancedFilter}
          setFilter={setAdvancedFilter}
          setSearch={setSearch}
        >
          {({ onSearchFilter }) => {
            return (
              <StopPointHeader
                filter={filter}
                dragItem={dragItem}
                getSettings={getSettings}
                updateFilter={updateFilter}
                tableColumn={tableColumn}
                onOpenModal={onOpenModal}
                search={search}
                setSearch={setSearch}
                onSearch={onSearchFilter}
                advancedOpen={advancedOpen}
                setAdvancedOpen={setAdvancedOpen}
                onImportSuccess={() => getStopPointsData(null, true)}
              />
            );
          }}
        </AdvancedFilter>

        <SwitchRadio
          color
          name='tableTopTabMenu'
          items={[...filter.filterTableTop]}
          value={tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
      </TableContext.Provider>

      {!!showAddStopPointModal && (
        <Modal open={showAddStopPointModal}>
          <AddStopPointModal
            show={showAddStopPointModal}
            getCustomerStopPoint={getStopPointsData}
            onChangeShow={onChangeAddStopPointModal}
            containerClassName={styles.addModal_wrapper}
          />
        </Modal>
      )}
      {loading ? (
        <Preloader />
      ) : (
        <MaterialTableWrapper
          data={stopPointList?.data}
          rowPerPage={selectedFilters.page_size}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: stopPointList,
                rowPerPage: selectedFilters.page_size,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          columns={filterTableColumn(columns)}
          onRowClick={onRowClick}
        />
      )}
    </div>
  );
};

export default StopPoint;
