import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { ReactComponent as WarningIcon } from 'assets/icons/warningYellow.svg';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import styles from './ConfirmCancelModal.module.css';

const ConfirmCancelModal = ({ data, show, onClose, onConfirm, values, handleChange, loading }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      backdropClassName='cancel-modal-backdrop'
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Confirm Cancellation</Typography>
        </div>
        <div className={styles.body_wrapper}>
          <div className={styles.info}>
            <WarningIcon style={{ marginTop: 4 }} />
            <Typography variant='b2' style={{ color: use(palette.orange500) }}>
              Your plan will cancel on {formatDate(data?.customer_plan?.next_renewal_date)} automatically. All data will
              be purged within 30 days of your plan cancellation date.
            </Typography>
          </div>
          <div>
            <Input
              required
              type='password'
              label='Enter Password to Confirm'
              name='password'
              values={values.password}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.footer_wrapper}>
          <CustomButton
            type='secondary'
            title='Cancel'
            leftIcon={false}
            styleButton={{ padding: '2px 6px', margin: 0 }}
            onClick={onClose}
          />
          <CustomButton
            type='primary'
            title='Confirm Cancellation'
            leftIcon={false}
            styleButton={{ padding: '2px 6px', margin: 0 }}
            onClick={onConfirm}
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCancelModal;
