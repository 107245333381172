import axios from '../services/axios';

export async function getAllCards(params) {
  const { data } = await axios.get('/card-management/get-all-cards', { params });
  return data;
}

export async function getCardNumbers(params) {
  const { data } = await axios.get('/card-management/all-cards', { params });
  return data;
}

export async function getStats() {
  const { data } = await axios.get('/card-management/stats');
  return data;
}

export async function getCards(params) {
  const { data } = await axios.get('/card-management/cards', { params });
  return data;
}

export async function assignUser(body) {
  const { data } = await axios.post('/card-management/set-card', body);
  return data;
}

export async function deleteCard(params) {
  const { data } = await axios.delete('/card-management/card', { params });
  return data;
}

export async function getCardDetails(params) {
  const { data } = await axios.get('/card-management/get-card', { params });
  return data;
}

export async function getAllTransactions(params, signal) {
  const { data } = await axios.get('/card-management/transactions', { params, signal });
  return data;
}

export async function createTransaction(body) {
  const { data } = await axios.post('/card-management/transaction', body);
  return data;
}

export async function getWexTransactions(params) {
  const { data } = await axios.get('/card-management/get-transactions', { params });
  return data;
}

export async function getDriverStaffList(params) {
  const { data } = await axios.get('/driver-staff/list', { params });
  return data;
}

export async function loadCashByCard(body) {
  const { data } = await axios.post('/card-management/load-cash-by-card', body);
  return data;
}

export async function getCardLimits(params) {
  const { data } = await axios.get('/card-management/get-card-limits', { params });
  return data;
}

export async function setCardLimit(body) {
  const { data } = await axios.post('/card-management/set-card-limits', body);
  return data;
}

export async function setCardPin(body) {
  const { data } = await axios.post('/card-management/set-card-pin', body);
  return data;
}

export async function getFuelTypes(params) {
  const { data } = await axios.get('/card-management/fuel-type', params);
  return data;
}

export async function getTransactionCategories(params) {
  const { data } = await axios.get('/card-management/purchase-description', params);
  return data;
}

export async function getCardHolders(params) {
  const { data } = await axios.get('/card-management/transaction-holders', params);
  return data;
}

export async function getMerchants(params) {
  const { data } = await axios.get('/card-management/merchants', params);
  return data;
}

export async function getLimitTypes(params) {
  const { data } = await axios.get('/card-management/get-limit-id', params);
  return data;
}

export async function connectService(body) {
  const { data } = await axios.post('/customer/connect-driver-connected-service', body);
  return data;
}

export async function connectServiceStaff(body) {
  const { data } = await axios.post('/user/staff/connect-connected-service', body);
  return data;
}

export async function removeConnectService(body) {
  const { data } = await axios.post('/customer/remove-driver-connected-service', body);
  return data;
}

export async function removeConnectServiceStaff(body) {
  const { data } = await axios.post('/user/staff/remove-connected-service', body);
  return data;
}

export async function getDriverTransactions(params) {
  const { data } = await axios.get('/driver/transactions', { params });
  return data;
}

export async function updateDriverTransaction(id, body) {
  const { data } = await axios.post(`/driver/transaction/${id}`, body);
  return data;
}

export async function getAccountsInfo(params) {
  const { data } = await axios.get('/card-management/card-providers/account-balance', { params });
  return data;
}

export async function searchLocation(params) {
  const { data } = await axios.get('/card-management/wex/location-search', { params });
  return data;
}

export async function updateCardInfos(body) {
  const { data } = await axios.post('/card-management/set-card-infos', body);
  return data;
}

export async function updateCard(body) {
  const { data } = await axios.post('/card-management/update-card', body);
  return data;
}

export async function cardOnboarding(body) {
  const { data } = await axios.post('/card-management/stripe/connect-onboarding', body);
  return data;
}

export async function addRepresentative(body) {
  const { data } = await axios.post('/card-management/stripe/connect-onboarding/add-representative', body);
  return data;
}

export async function addIdNumber(body) {
  const { data } = await axios.post('/card-management/stripe/connect-onboarding/add-id-number', body);
  return data;
}

export async function addIdDocument(body) {
  const { data } = await axios.post('/card-management/stripe/connect-onboarding/add-document', body);
  return data;
}

export async function getAllConnectedServices() {
  const { data } = await axios.get('/customer/all-connected-services');
  return data;
}

export async function acceptTerms() {
  const body = {
    tos_acceptance: true,
    settings: {
      treasury: {
        tos_acceptance: true,
      },
      card_issuing: {
        tos_acceptance: true,
      },
    },
  };

  const { data } = await axios.post('/card-management/stripe/connect-onboarding/accept-terms', body);
  return data;
}

export async function setupExternalAccount(body) {
  const { data } = await axios.post('/card-management/stripe/create-payment-source', body);
  return data;
}

export async function getPaymentSource() {
  const { data } = await axios.get('/card-management/stripe/get-payment-source');
  return data;
}

export async function getTransferHistory(params) {
  const { data } = await axios.get('/card-management/stripe/transfer-history', { params });
  return data;
}

// Truckin Digital Accounts
export async function getStripeFees() {
  const { data } = await axios.get('/card-management/stripe/fees');
  return data;
}
export async function withdrawFunds(body) {
  const { data } = await axios.post('/card-management/stripe/withdraw-funds', body);
  return data;
}
export async function depositFunds(body) {
  const { data } = await axios.post('/card-management/stripe/inbound-transfer', body);
  return data;
}
export async function transferFunds(body) {
  const { data } = await axios.post('/card-management/stripe/transfer-funds', body);
  return data;
}

export async function createCardHolder(body) {
  const { data } = await axios.post('/card-management/stripe/create-card-holder', body);
  return data;
}
export async function purchaseCard(body) {
  const { data } = await axios.post('/card-management/purchase-cards', body);
  return data;
}
