export const purchaseAddonConverter = (values, addonData, prices) => {
  const data = {
    agreement: {
      terms_and_conditions: values.term ? 'on' : 'off',
      privacy_policy: values.privacy ? 'on' : 'off',
      billing_policy: values.billing ? 'on' : 'off',
    },
    addon_plan_id: addonData.activePlan?.id,
    payment_method: values?.paymentMethod || 'financing',
    addon_id: addonData.id,
    // stripe_token: paymentMethod?.payment_method_details?.id || null,
    applied_coupon: values.coupon_code || '',
    addon_monthly_amount: prices.addon_monthly_amount,
    setup_one_time_fee: prices.setup_one_time_fee || 0,
    license_cnt: prices.license_cnt,
    amount_total_license_cnt: prices.amount_total_license_cnt,
    next_renewal_date: prices.next_renewal_date,
    promo_code_applied: !!values.coupon_code,
    discount_amount: prices.discount_amount,
    to_be_paid: prices.to_be_paid,
    message: prices.message || '',
    text: prices.text || '',
    monthly_payment: prices.monthly_payment,
    is_upgrade: prices.is_upgrade,
    is_downgrade: prices.is_downgrade,
    pro_rated: prices.pro_rated,
  };

  addonData?.add_custom_fields?.forEach((item) => {
    if (item?.field_name && values[item.field_name]) {
      data[item.field_name] = values[item.field_name];
    }
  });

  return data;
};
