import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { SCustomModal } from '../../Settlements.styles';

const ProcessApprove = ({ open, onClose, onSubmit, settlement }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const { user, pay_period_end } = settlement || {};

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      $maxWidth='350px'
      $height='auto'
      headerTitle='Early Settlement Closure'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Go Back',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save & Process',
          onClick: onSubmit,
        },
      ]}
    >
      <Typography variant='s1' as='p' style={{ color: use(palette.red500, palette.red200) }}>
        WARNING!
      </Typography>
      <Typography variant='s2'>
        Are you sure you want to close this pay period for {user?.fname} {user?.lname}? The pay period is not over and
        any activity conducted after processing will be missed up to {formatDate(pay_period_end)}!
      </Typography>
    </SCustomModal>
  );
};

export default ProcessApprove;
