import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCarriersReportedStates,
  getCarriersCrashes,
  getCarriersInspections,
  getCarriersViolations,
  getSafetyTabsCounts,
} from 'Api/Carriers';
import { palette } from 'utils/constants';
import Pagination from 'common/Pagination';
import { useTheme } from 'context/themeContext';
import { CarrierContext } from 'context/tableContext';
import ColumnHeader from 'common/ColumnHeader';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment/moment';
import useDateFormat from 'hooks/useDateFormat';
import Chip from 'common/Chip';
import SafetyFilters from './SafetyFilters';
import MaterialTableWrapper from '../../../MaterialTableWrapper';
import EquipmentPreloaderSkeleton from '../../../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { initialFilters } from '../../Carriers.data';
import SwitchRadio from '../../../SwitchRadio/SwitchRadio';

const SafetyRecords = () => {
  const { id } = useParams();
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [reportedStates, setReportedStates] = useState([]);
  const [states, setStates] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [inspections, setInspections] = useState({ data: [] });
  const [violations, setViolations] = useState({ data: [] });
  const [crashes, setCrashes] = useState({ data: [] });
  const [tabs, setTabs] = useState([]);
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, 500);
  const [dayFilter, setDayFilter] = useState();
  const [rangeName, setRangeName] = useState();
  const [sort, setSort] = useState({ field: 'date', sortBy: 'desc' });

  const { openEdit, recordTab } = useContext(CarrierContext);

  const onChangeTab = (tab) => {
    setSearch('');
    setDayFilter({});
    setRangeName('');
    setStates([]);
    setSeverity([]);
    setSort({ field: 'date', sortBy: 'desc' });
    setActiveTab(tab.id);
  };
  useEffect(() => {
    if (recordTab && openEdit) {
      setActiveTab(recordTab);
    }
  }, [recordTab, openEdit]);

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  useEffect(() => {
    getSafetyTabsCounts(id).then((res) => {
      setTabs([
        {
          label: 'Inspections',
          count: res?.data?.inspections_count,
          id: 1,
        },
        {
          label: 'Violations',
          count: res?.data?.violations_count,
          id: 2,
        },
        {
          label: 'Crashes',
          count: res?.data?.crashes_count,
          id: 3,
        },
      ]);
    });

    getCarriersReportedStates(id).then((res) => {
      setReportedStates(res?.data);
    });
  }, []);

  useEffect(() => {
    const params = {
      page: selectedFilters.page,
      itemsPerPage: selectedFilters.itemsPerPage,
      'filters[query]': debouncedSearch || undefined,
      'filters[from]': dayFilter?.from || undefined,
      'filters[to]': dayFilter?.to || undefined,
    };

    setLoading(!sort.field);
    if (sort.field) {
      params[`sort[][${sort.field}]`] = sort.sortBy;
    }

    if (states?.length) {
      params['filters[report_states]'] = states;
    }

    if (severity?.length) {
      severity.forEach((serv, index) => {
        params[`filters[severity_weight][${index}]`] = serv.value;
      });
    }

    if (activeTab === 1) {
      getCarriersInspections(id, params).then((res) => {
        setInspections(res);
        setLoading(false);
      });
    }

    if (activeTab === 2) {
      getCarriersViolations(id, params).then((res) => {
        setViolations(res);
        setLoading(false);
      });
    }

    if (activeTab === 3) {
      getCarriersCrashes(id, params).then((res) => {
        setCrashes(res);
        setLoading(false);
      });
    }
  }, [activeTab, selectedFilters, dayFilter, debouncedSearch, states, sort, severity]);

  const changeToViolation = (report_number) => {
    setActiveTab(2);
    setSearch(report_number);
  };

  const getStatusLabel = (value) => {
    if (value === undefined || value === null) {
      return { name: 'Passed', color: palette.green400, bgColor: palette.green0 };
    }
    if (value >= 8 && value <= 10) {
      return { name: 'High', color: palette.red400, bgColor: palette.red0 };
    }
    if (value >= 5 && value <= 7) {
      return { name: 'Medium', color: palette.orange500, bgColor: palette.orange0 };
    }
    if (value >= 1 && value <= 4) {
      return { name: 'Low', color: palette.indigo400, bgColor: palette.indigo0 };
    }
    return { name: 'Invalid', color: 'grey' };
  };

  const inspectionColumns = [
    {
      field: 'date',
      title: <ColumnHeader title='Date' field='date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { date } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{formatDate(date) || '-'}</span>;
      },
    },
    {
      field: 'report_state',
      title: <ColumnHeader title='Report State' field='report_state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_state } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_state}</span>;
      },
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_number}</span>;
      },
    },

    {
      field: 'vehicle_unit_type_description',
      title: (
        <ColumnHeader
          title='Vehicle Inspected'
          field='vehicle_unit_type_description'
          sort={sort}
          onClick={sortingQuery}
        />
      ),
      render: (rowData) => {
        const { vehicle_unit_type_description } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_unit_type_description}</span>;
      },
    },
    {
      field: 'vehicle_unit_vin',
      title: <ColumnHeader title='VEHICLE VIN' field='vehicle_unit_vin' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_unit_vin } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_unit_vin}</span>;
      },
    },
    {
      field: 'vehicle_unit_license',
      title: <ColumnHeader title='VEHICLE PLATE' field='vehicle_unit_license' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_unit_license } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_unit_license}</span>;
      },
    },
    {
      field: 'trailer_unit_make',
      title: <ColumnHeader title='Trailer Make' field='trailer_unit_make' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer_unit_make } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{trailer_unit_make || '-'}</span>;
      },
    },
    {
      field: 'trailer_unit_vin',
      title: <ColumnHeader title='TRAILER VIN' field='trailer_unit_vin' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer_unit_vin } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{trailer_unit_vin || '-'}</span>;
      },
    },
    {
      field: 'trailer_unit_license',
      title: <ColumnHeader title='TRAILER PLATE' field='trailer_unit_license' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trailer_unit_license } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{trailer_unit_license || '-'}</span>;
      },
    },
    {
      field: 'time_weight',
      title: <ColumnHeader title='Time Weight' field='time_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { time_weight } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{time_weight || '-'}</span>;
      },
    },
    {
      field: 'violation_severity_weight',
      title: (
        <ColumnHeader title='SEVERITY WEIGHT' field='violation_severity_weight' sort={sort} onClick={sortingQuery} />
      ),
      render: (rowData) => {
        const { violation_severity_weight } = rowData;
        const { color, bgColor, name } = getStatusLabel(violation_severity_weight);
        return <Chip labelColor={color} bgColor={bgColor} label={name} />;
      },
    },
    {
      field: 'violation_per_inspection',
      title: <ColumnHeader title='VIOLATION' field='violation_per_inspection' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_per_inspection, report_number } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {violation_per_inspection > 0 ? (
              <span>
                <Chip label='Yes' labelColor={palette.red400} bgColor={palette.red0} /> -{' '}
                <span onClick={() => changeToViolation(report_number)} style={{ color: palette.indigo500 }}>
                  {report_number}
                </span>
              </span>
            ) : (
              <Chip label='No' labelColor={palette.green400} bgColor={palette.green0} />
            )}
          </span>
        );
      },
    },
  ];

  const violationsColumns = [
    {
      field: 'date',
      title: <ColumnHeader title='Date' field='date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { date } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{formatDate(date) || '-'}</span>;
      },
    },
    {
      field: 'title',
      title: <ColumnHeader title='Title' field='basic' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { basic } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{basic || '-'}</span>;
      },
    },
    {
      field: 'report_state',
      title: <ColumnHeader title='Report State' field='report_state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_state } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_state || '-'}</span>;
      },
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_number || '-'}</span>;
      },
    },
    {
      field: 'violation_group_description',
      title: <ColumnHeader title='Group' field='violation_group_description' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_group_description } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>{violation_group_description || '-'}</span>
        );
      },
    },
    {
      field: 'violation_code',
      title: <ColumnHeader title='Violation Code' field='violation_code' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_code } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{violation_code || '-'}</span>;
      },
    },
    {
      field: 'violation_description',
      title: <ColumnHeader title='Description' field='violation_description' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { violation_description } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{violation_description || '-'}</span>;
      },
    },
    {
      field: 'violation_severity_weight',
      title: (
        <ColumnHeader title='Severity Weight' field='violation_severity_weight' sort={sort} onClick={sortingQuery} />
      ),
      render: (rowData) => {
        const { violation_severity_weight } = rowData;
        const { color, bgColor, name } = getStatusLabel(violation_severity_weight);
        return <Chip labelColor={color} bgColor={bgColor} label={name} />;
      },
    },
    {
      field: 'time_weight',
      title: <ColumnHeader title='Time Weight' field='time_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { time_weight } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{time_weight}</span>;
      },
    },
    {
      field: 'out_of_service_violation',
      title: <ColumnHeader title='O.O.S' field='out_of_service_violation' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { out_of_service } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {out_of_service ? (
              <Chip label='Yes' labelColor={palette.red400} bgColor={palette.red0} />
            ) : (
              <Chip label='No' labelColor={palette.green400} bgColor={palette.green0} />
            )}
          </span>
        );
      },
    },
  ];

  const crashesColumns = [
    {
      field: 'date',
      title: <ColumnHeader title='Date' field='date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { date } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{formatDate(date) || '-'}</span>;
      },
    },
    {
      field: 'report_state',
      title: <ColumnHeader title='Report State' field='report_state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_state } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_state}</span>;
      },
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { report_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{report_number || '-'}</span>;
      },
    },
    {
      field: 'trafficway_description',
      title: <ColumnHeader title='Description' field='trafficway_description' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { trafficway_description } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{trafficway_description || '-'}</span>;
      },
    },
    {
      field: 'vehicle_vin',
      title: <ColumnHeader title='Vehicle Vin' field='vehicle_vin' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_vin } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_vin || '-'}</span>;
      },
    },
    {
      field: 'vehicle_license_number',
      title: <ColumnHeader title='Vehicle Plate' field='vehicle_license_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { vehicle_license_number } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{vehicle_license_number}</span>;
      },
    },
    {
      field: 'total_injuries',
      title: <ColumnHeader title='Injuries' field='total_injuries' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { total_injuries } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{total_injuries}</span>;
      },
    },
    {
      field: 'total_fatalities',
      title: <ColumnHeader title='Fatalities' field='total_fatalities' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { total_fatalities } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{total_fatalities || '-'}</span>;
      },
    },
    {
      field: 'tow_away',
      title: <ColumnHeader title='Towing' field='tow_away' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { tow_away } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{tow_away ? 'True' : 'False'}</span>;
      },
    },
    {
      field: 'hazmat_released',
      title: <ColumnHeader title='Hazmat' field='hazmat_released' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { hazmat_released } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{hazmat_released ? 'Yes' : 'No'}</span>;
      },
    },
    {
      field: 'road_surface',
      title: <ColumnHeader title='Road Condition' field='road_surface' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { road_surface } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{road_surface || '-'}</span>;
      },
    },
    {
      field: 'weather',
      title: <ColumnHeader title='Weather' field='weather' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { weather } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{weather || '-'}</span>;
      },
    },
    {
      field: 'light',
      title: <ColumnHeader title='Day/Night' field='light' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { light } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{light || '-'}</span>;
      },
    },
    {
      field: 'preventable',
      title: <ColumnHeader title='Preventable' field='preventable' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { preventable } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{preventable ? 'true' : '-'}</span>;
      },
    },
    {
      field: 'severity_weight',
      title: <ColumnHeader title='Severity Weight' field='severity_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { severity_weight } = rowData;
        const { color, bgColor, name } = getStatusLabel(severity_weight);
        return <Chip labelColor={color} bgColor={bgColor} label={name} />;
      },
    },
    {
      field: 'time_weight',
      title: <ColumnHeader title='Time Weight' field='time_weight' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const { time_weight } = rowData;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{time_weight || '-'}</span>;
      },
    },
  ];

  const onSelectDate = (dates) => {
    const { start, end } = dates;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');
    setDayFilter({
      from: startFormat,
      to: endFormat,
    });
  };

  return (
    <div>
      <div>
        <SwitchRadio type='tab' items={tabs} onChange={onChangeTab} name='tableTopTabMenu' value={activeTab} />
      </div>
      <SafetyFilters
        states={states}
        setStates={setStates}
        reportedStates={reportedStates}
        rangeName={rangeName}
        setRangeName={setRangeName}
        search={search}
        onChange={setSearch}
        onSelectDate={onSelectDate}
        severity={severity}
        setSeverity={setSeverity}
      />
      <div>
        {activeTab === 1 &&
          (loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              data={inspections.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: inspections,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={inspectionColumns}
            />
          ))}
        {activeTab === 2 &&
          (loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              data={violations.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: violations,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={violationsColumns}
            />
          ))}
        {activeTab === 3 &&
          (loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              data={crashes.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: crashes,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={crashesColumns}
            />
          ))}
      </div>
    </div>
  );
};

export default SafetyRecords;
