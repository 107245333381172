import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  position: absolute;
  background-color: ${palette.gray0};
  top: 0;
  left: 0;
  right: 0;
  z-index: 1056;

  .right-section {
    display: flex;
    height: 100vh;
    overflow-y: scroll;
    flex-grow: 1;
    padding-left: 1px;
  }

  .left-section {
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
      padding: 12px;
    }
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
`;

export const SSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  padding: 8px 80px 8px 60px;
  border-right: 1px solid ${palette.gray50};
`;
