import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Skeleton } from '@material-ui/lab';
import MuiTooltip from '@mui/material/Tooltip';
import { MobileIcon } from 'assets/icons';
import cellularTracking from 'assets/icons/cellular-tracking.png';
import { ReactComponent as FuelIcon } from 'assets/icons/fuel.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as EnginOnIcon } from 'assets/icons/enginOn.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as LightningIcon } from 'assets/icons/lightning1.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { useTheme } from 'context/themeContext';
import { onChangeActiveTab } from 'store/reducers/shipments.reducer';
import { onChangeActiveTab as onChangeRecurringActiveTab } from 'store/reducers/planner.reducer';
import { CURRENCY, palette, statusColor, TRUCK_TYPES } from 'utils/constants';
import { ReactComponent as MinimizeIcon } from 'assets/icons/minus.svg';
import { addMinimizedNotes } from 'store/reducers/root.reducer';
import useShowToaster from 'hooks/useShowToaster';
import { ReactComponent as WarningIcon } from 'assets/icons/detention.svg';
import {
  blockNonPositiveNumbers,
  blockNotNumberChars,
  formatAmount,
  formatPhoneNumber,
  getShipmentType,
} from 'utils/helpers';

import Progress from 'components/Progress';
import CustomTooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import TableRowsEdit from 'components/TableRowsEdit';
import CustomButton from 'components/CustomButton/CustomButton';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import OverLayShipmentIdCloud from 'components/TablePlaner/helpers/OverLayShipmentIdCloud';
import {
  MAPPER_STOPS,
  PLANNER_VIEW_MASTER_SPLIT,
  PLANNER_VIEW,
  SHIPMENT_ROWS_EDIT_DATA_DETAIL,
  SHIPMENT_ROWS_EDIT_DATA_DETAIL_COMPLETED,
  PENDING_DISPATCH_ACTIONS,
  DRY_RUN_ACTIONS,
  PLANNER_PENDING_EDIT_CANCELED,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { RECURRING_TABS } from 'components/TablePlaner/constats';
import { UpdateShipmentBillingHeader, updateShipmentMiles } from 'Api/Shipment';
import { getErrorMessage } from 'utils/error';
import hexRgb from 'hex-rgb';
import Input from 'common/Input';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import useShipmentLocked from 'hooks/useShipmentLocked';
import ShipmentSplits from 'componentsV2/Shipment/ShipmentSplits';
import HeaderUserInfo from './HeaderUserInfo';
import { getPlannerStatus, getStatusParamsInStatus, SHIPMENT_DETAILS_TABS } from '../helpers/constants';
import classes from './details.module.css';
import UpdateReference from '../components/UpdateReference';
import UpdateGroup from '../components/UpdateGroup';

const mapper = {
  1: { type: 'Arrived', name: 'Pick Up' },
  2: { type: 'Out of Service', name: 'Delivery' },
  3: { type: 'On Shipment', name: 'Waypoint' },
};

const DetailsHeader = ({
  user,
  data,
  onClickOption,
  getHeaderInfo,
  isShipmentTable,
  isRecurringLane,
  children,
  GPS,
  isBillOfLading,
  refreshDriverPay,
}) => {
  const { use } = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { isShipmentLocked } = useShipmentLocked();
  const { currency } = useSelector((state) => state.root);
  const { activeTabDetails } = useSelector((state) => state?.shipments);
  const { activeTabRecurring } = useSelector((state) => state?.planner);
  const {
    status,
    is_edi_api,
    manual_edi_api,
    shipment_billing,
    equipment,
    shipment_type,
    stops_count,
    driver_amount,
    last_hooked_equipment,
    brokerage_dispatch,
    loaded_miles,
    empty_miles,
    cellular_tracking_status,
  } = data || {};
  const { carrier } = brokerage_dispatch || {};
  const { equipment_connected_services_device: device } = equipment || {};
  const styleStatus = useMemo(() => status && status?.id && getStatusParamsInStatus(status), [data]);
  const [editEmptyMiles, setEditEmptyMiles] = useState(false);
  const [editLoadedMiles, setEditLoadedMiles] = useState(false);
  const [emptyMilesToEdit, setEmptyMilesToEdit] = useState(0);
  const [loadedMilesToEdit, setLoadedMilesToEdit] = useState(0);
  const [editReferenceOpen, setEditReferenceOpen] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [viewSpeed, setViewSpeed] = useState(0);
  const [openViewSplits, setOpenViewSplits] = useState(false);
  const currencyData = Object.values(CURRENCY).find((item) => item.id === Number(shipment_billing?.[0]?.currency_id));

  const onChangeTab = (tab) => {
    if (+(isRecurringLane ? activeTabRecurring : activeTabDetails).id !== +tab.id) {
      dispatch(isRecurringLane ? onChangeRecurringActiveTab(tab) : onChangeActiveTab(tab));
    }
  };

  function onClickCloudButtons() {
    getHeaderInfo();
  }

  const optionsEditDots = useMemo(() => {
    try {
      let options = isShipmentTable
        ? data.shipment_stops?.[0].arrival_date
          ? SHIPMENT_ROWS_EDIT_DATA_DETAIL.filter((item) =>
              data.shipment_type === 'ltl' || data.shipment_type === 'slave' ? item.id !== 8 : true
            )
          : SHIPMENT_ROWS_EDIT_DATA_DETAIL.filter((item) => {
              if (item.id === 97) return false;
              return !((data.shipment_type === 'ltl' || data.shipment_type === 'slave') && item.id === 8);
            })
        : PLANNER_VIEW;

      if (!isShipmentTable) {
        const userId = Number(JSON.parse(localStorage.getItem('user'))?.id);
        const createdById = Number(data?.created_by?.id);
        if (userId === createdById) {
          options = PLANNER_VIEW.filter((item) => (data?.shipment_reserve ? +item.id !== 12 : +item.id !== 112));
        } else {
          options = PLANNER_VIEW.filter((item) => +item.id !== 12 && +item.id !== 112);
        }
        if (data.shipment_type === 'ltl' || data.shipment_type === 'split-parent') {
          options = PLANNER_VIEW.filter((el) => +el.id !== 8);
        }
        if (data.status === 'Offered') {
          options = PLANNER_VIEW.filter((el) => +el.id !== 20 && +el.id !== 21);
        } else {
          options = PLANNER_VIEW.filter((el) => +el.id !== 22);
        }
        // garlaxnoc
        if (data.shipment_type === 'ltl' || data.shipment_type === 'slave' || data.shipment_type === 'split-parent') {
          options = PLANNER_VIEW.filter((el) => +el.id !== 8);
        }
        if (data.shipment_type === 'split-parent') {
          options = PLANNER_VIEW_MASTER_SPLIT;
        }
        if (data.shipment_type !== 'split-children') {
          options = options.filter((el) => +el.id !== 24);
        }
        if (data.shipment_type === 'slave' || data.shipment_type === 'split-parent') {
          options = options.filter((el) => +el.id !== 5);
        }
        if ((data.is_edi_api || data.manual_edi_api) && (!!data.declined_at || !!data.expired_at)) {
          options = PLANNER_PENDING_EDIT_CANCELED.filter((el) => +el.id !== 125);
        }
      } else {
        if (+data.status_id === 7) {
          options = SHIPMENT_ROWS_EDIT_DATA_DETAIL_COMPLETED.filter((item) =>
            data.invoiced ? item.id !== 19 && item.id !== 97 : true
          );
        }

        if (+data.status_id === 8) {
          options = PENDING_DISPATCH_ACTIONS.filter((i) => i.id !== 9);
        }

        if (+data.status_id === 6) {
          options = DRY_RUN_ACTIONS;
        }

        if (
          !data.shipment_stops?.[0]?.arrival_date ||
          (data.shipment_stops?.[0]?.arrival_date && data.shipment_stops?.[0]?.departure_date)
        ) {
          options = options.filter((el) => +el.id !== 5);
        }
      }

      if (data.status_id !== '5') {
        options = options.filter((item) => item.id !== 61);
      }

      if (data?.brokerage_dispatch?.carrier) {
        options = options.filter((item) => item.id !== 14 && item.id !== 3);
      }

      if (
        !brokerage_dispatch?.carrier ||
        !brokerage_dispatch?.driver_phone_number ||
        cellular_tracking_status === 'started' ||
        cellular_tracking_status === 'completed' ||
        !user?.customer?.cellular_tracking
      ) {
        options = options.filter((item) => item.id !== 44);
      }

      if (!brokerage_dispatch?.carrier || cellular_tracking_status !== 'started') {
        options = options.filter((item) => item.id !== 45);
      }

      return options;
    } catch (e) {
      // Do nothing
    }
  }, [isShipmentTable, data]);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const activeTab = params.get('activeTab');
    if (activeTab) {
      if (isRecurringLane) {
        dispatch(onChangeRecurringActiveTab(RECURRING_TABS.find((item) => item.id === Number(activeTab))));
      } else {
        dispatch(onChangeActiveTab(SHIPMENT_DETAILS_TABS.find((item) => item.id === Number(activeTab))));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    try {
      if (!!GPS && isShipmentTable) {
        const equipment = data?.equipment || {};
        const pusherTriggerObject = { profile_status: true, userId: equipment?.drivers[0]?.id };

        GPS.trigger('client-equipment-location-fe', pusherTriggerObject);
        const TriggerInterval = setInterval(() => {
          GPS.trigger('client-equipment-location-fe', pusherTriggerObject);
        }, 60000);

        if (data.equipment.location_target === 'Equipment') {
          GPS.bind('equipment-location', (data) => {
            if (typeof data === 'string') {
              data = JSON.parse(data);
            }
            if (
              equipment.equipment_connected_services_device?.provider_device_id ===
              data?.message?.data?.provider_device_id
            ) {
              const { speed_mph: speed } = data?.message?.data || {};
              clearInterval(TriggerInterval);
              setViewSpeed(speed);
            }
          });
        } else {
          GPS.bind('client-equipment-location', (data) => {
            if (typeof data === 'string') {
              data = JSON.parse(data);
            }
            if (Number(equipment?.id) === Number(data?.message?.data?.equipment_id)) {
              const { speed_mph: speed } = data?.message?.data || {};
              clearInterval(TriggerInterval);
              setViewSpeed(speed);
            }
          });
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [data, GPS]);

  useEffect(() => {
    if (data.shipment_id) {
      setEmptyMilesToEdit(Math.round(data?.empty_miles));
      setLoadedMilesToEdit(Math.round(data?.loaded_miles));
    }

    if (isShipmentTable) {
      const speed_mph =
        data.equipment?.location_target === 'Equipment'
          ? Number(data?.equipment?.equipment_connected_services_device?.speed_mph)
          : Number(data.equipment?.drivers?.[0]?.driver_gps?.mph);
      setViewSpeed(Number(speed_mph).toFixed(1) <= 0 ? 0 : Number(speed_mph).toFixed() || 0);
    }
  }, [data]);

  function onMinimize(e) {
    e.stopPropagation();
    dispatch(addMinimizedNotes({ label: 'Shipment-profile', shipment_id: data?.shipment_id, id: data?.shipment_id }));
    showToaster({ type: 'success', message: 'Shipment was minimized for quick access on bottom of your page!' });
  }

  function sumDurations(durations) {
    let totalMinutes = 0;
    for (const duration of durations) {
      let days = 0;
      let hours = 0;
      let minutes = 0;
      const parts = duration?.split(' ');
      for (const part of parts) {
        if (part) {
          if (part.endsWith('d')) {
            days = parseInt(part, 10);
          } else if (part.endsWith('h')) {
            hours = parseInt(part, 10);
          } else if (part.endsWith('m')) {
            minutes = parseInt(part, 10);
          }
        }
      }
      totalMinutes += days * 24 * 60 + hours * 60 + minutes;
    }
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${days}d ${hours}h ${minutes}m`;
  }

  const onMilesChange = async (type) => {
    try {
      const body = {
        empty_miles: emptyMilesToEdit || 0,
        loaded_miles: loadedMilesToEdit || 0,
      };
      await updateShipmentMiles(data.shipment_id, body);
      setEditEmptyMiles(false);
      setEditLoadedMiles(false);

      showToaster({ type: 'success', message: `${type} miles are successfully updated!` });
      getHeaderInfo();
      refreshDriverPay();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  return (
    <div className={classNames(classes.header, use(classes.light, classes.dark))}>
      <div className={classes.flexCol}>
        <div className={classes.topWrapper}>
          <div style={{ position: 'relative', marginRight: 8 }}>
            <Typography style={{ whiteSpace: 'nowrap' }} variant='h1'>
              {isRecurringLane ? data.shipment_lane?.name : `Shipment ID ${params.id}`}
            </Typography>

            <div onClick={() => navigate(-1)} className={classes.backWrapper}>
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className={classes.backText}>
                Go Back
              </Typography>
            </div>
          </div>

          {Object.keys(data).length > 0 ? (
            <div className={classes.flexAlign} style={{ justifyContent: 'space-between', width: '100%' }}>
              <div className={classes.top}>
                {isShipmentTable && (
                  <div>
                    {(is_edi_api === 1 || manual_edi_api === 1) && (
                      <div className={classes.cloud}>
                        <OverLayShipmentIdCloud rowData={data} onClickButtons={onClickCloudButtons} />
                      </div>
                    )}
                  </div>
                )}

                {status.id !== 7 && !isRecurringLane && (
                  <div className={classes.flexAlign}>
                    {equipment?.location_target === 'Driver' ? (
                      <span className='mb-1'> {MobileIcon}</span>
                    ) : (
                      <>
                        {device ? (
                          <Progress
                            icon={<FuelIcon fill={palette.green400} />}
                            percent={device?.fuel_percent !== null ? device?.fuel_percent : '1'}
                            colorPercent={palette.green200}
                          />
                        ) : (
                          <FuelIcon />
                        )}
                        {!brokerage_dispatch?.carrier && (
                          <>
                            <EnginOnIcon
                              fill={device?.engine_state === 'On' ? palette.green400 : palette.gray400}
                              className={classes.iconNoHover}
                            />
                            <SignalIcon
                              fill={device?.hardware_model ? palette.green400 : palette.gray400}
                              className={classes.iconNoHover}
                            />
                          </>
                        )}
                      </>
                    )}

                    {brokerage_dispatch?.carrier && cellular_tracking_status === 'started' && (
                      <MuiTooltip
                        placement='bottom'
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: palette.white,
                              border: '1px solid #dadde9',
                            },
                          },
                        }}
                        title={
                          <div className='d-flex flex-column'>
                            <Typography variant='s2' style={{ color: palette.gray700 }}>
                              Cell Tracking Transmission Active
                            </Typography>
                            <div>
                              <Typography variant='s2' style={{ color: palette.gray700 }}>
                                {brokerage_dispatch?.driver_phone_number?.startsWith('+')
                                  ? formatPhoneNumber(brokerage_dispatch?.driver_phone_number)
                                  : formatPhoneNumber(`+${brokerage_dispatch?.driver_phone_number}`)}
                              </Typography>
                              <span
                                className={classes.redUnderlineText}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onClickOption({ id: 45 });
                                }}
                              >
                                Stop Tracking
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <div className='ms-1 mb-1'>
                          <img src={cellularTracking} width={20} height={20} alt='tracking' />
                        </div>
                      </MuiTooltip>
                    )}

                    {!Number.isNaN(Number(viewSpeed)) &&
                      (!!viewSpeed || +viewSpeed === 0) &&
                      isShipmentTable &&
                      !carrier && (
                        <div
                          className={classNames(classes.headerGreenWrapper, {
                            [classes.zeroMph]: Number(viewSpeed).toFixed(1) <= 0,
                          })}
                        >
                          <Typography variant='overLine'>
                            {Number(viewSpeed).toFixed(1) <= 0 ? 0 : Number(viewSpeed).toFixed()} MPH
                          </Typography>
                        </div>
                      )}
                  </div>
                )}

                <div className={classes.flexAlign}>
                  {isShipmentTable ? (
                    <>
                      <CustomTooltip title='Delayed'>
                        <WarningIcon width={14} height={14} style={{ marginLeft: 4, flexShrink: 0 }} fill='#C97A20' />
                      </CustomTooltip>

                      <div className={classes.headerStatus} style={{ backgroundColor: styleStatus?.bgStatus }}>
                        <Typography variant='overLine' style={{ color: styleStatus?.colorStatus }}>
                          {styleStatus?.statusName}
                        </Typography>
                      </div>
                    </>
                  ) : !isRecurringLane ? (
                    <div
                      className={classes.headerStatus}
                      style={{
                        backgroundColor: getPlannerStatus(data).bgStatus,
                      }}
                    >
                      <Typography
                        variant='overLine'
                        style={{
                          color: getPlannerStatus(data)?.colorStatus,
                        }}
                      >
                        {getPlannerStatus(data)?.statusName}
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='d-flex'>
                {!!isShipmentTable && (
                  <div onClick={(e) => onMinimize(e)} className={classes.minimize}>
                    <MinimizeIcon />
                  </div>
                )}

                {isShipmentLocked(data) ? (
                  <ActionLocked shipment={data} size={16} />
                ) : (
                  !isRecurringLane &&
                  !isBillOfLading && (
                    <TableRowsEdit
                      placement='bottom-end'
                      overlayStyle={{ width: 210 }}
                      options={optionsEditDots}
                      onClickOption={(e, i) => onClickOption(e, i)}
                      targetStyle={{ width: 20, height: 20 }}
                    />
                  )
                )}
              </div>
            </div>
          ) : (
            <div className={classes.skeleton}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          )}
        </div>
      </div>

      {Object.keys(data).length > 0 ? (
        isShipmentTable ? (
          <div style={{ marginTop: 12 }}>
            <div className={classes.blockWrapperTop}>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  {shipment_billing?.length > 1 ? 'Customers' : 'Customer'}
                </Typography>
                {shipment_type !== 'ltl' ? (
                  <Typography
                    className={classes?.customerId_text_single}
                    variant='overLine2'
                    onClick={() => navigate(`/customer-profile/${shipment_billing?.[0]?.billing_customer?.id}`)}
                  >
                    {shipment_billing?.[0]?.billing_customer?.company_name}
                  </Typography>
                ) : (
                  <div className={classes.multi}>
                    {shipment_billing?.map((el) => {
                      return (
                        <div className='d-flex flex-column' key={el.id}>
                          <Typography
                            variant='overLine2'
                            style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                            onClick={() => navigate(`/customer-profile/${el?.slave_billing?.billing_customer?.id}`)}
                          >
                            {el?.slave_billing?.billing_customer?.company_name} - Shipment{' '}
                            {el?.slave_billing?.shipment_id}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Type
                </Typography>
                <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                  {getShipmentType(data.shipment_type)}
                </Typography>
                {data.shipment_stops?.some((i) => i.hazardous_materials) && (
                  <Typography variant='c1' style={{ color: palette.orange400 }}>
                    HAZMAT
                  </Typography>
                )}
              </div>
              {data?.shipment_type === 'split-children' && (
                <div className={`${classes.blockItem} justify-content-center`}>
                  <Typography
                    variant='overLine2'
                    style={{ color: palette.indigo500 }}
                    className='pointer'
                    onClick={() => setOpenViewSplits(true)}
                  >
                    View Splits
                  </Typography>
                </div>
              )}
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Assigned to
                </Typography>
                {!!data.assigned_to1 || !!data.brokerage_dispatch ? (
                  <HeaderUserInfo data={data} />
                ) : (
                  <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                    -
                  </Typography>
                )}
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Equipment
                </Typography>
                {equipment ? (
                  <div className={classes.flexAlign} style={{ whiteSpace: 'nowrap' }}>
                    <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                      {equipment?.equipment_id}&nbsp;
                      <Typography variant='overLine'>{equipment?.equipment_type?.title}</Typography>&nbsp;
                      {!!equipment?.truck_type && (
                        <Typography variant='overLine'>({TRUCK_TYPES[equipment.truck_type]})</Typography>
                      )}
                    </Typography>

                    {!!equipment?.hooked_to && status?.id !== 7 && (
                      <>
                        <TwoWaysIcon style={{ margin: '0 8px' }} />
                        <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                          {equipment?.hooked_to?.equipment_id}&nbsp;
                          {equipment?.hooked_to?.length?.length && equipment?.hooked_to?.equipment_type?.title && (
                            <Typography variant='overLine'>
                              {equipment.hooked_to.length.length}-{equipment.hooked_to.equipment_type.title}
                            </Typography>
                          )}
                        </Typography>
                      </>
                    )}

                    {(!!last_hooked_equipment || !!brokerage_dispatch?.trailer_type) && status?.id === 7 && (
                      <>
                        <TwoWaysIcon style={{ margin: '0 8px' }} />
                        <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                          {last_hooked_equipment?.equipment_id || brokerage_dispatch?.equipment_id}&nbsp;
                          <Typography variant='overLine'>
                            {last_hooked_equipment?.length?.length || brokerage_dispatch?.trailer_length?.length}-
                            {last_hooked_equipment?.equipment_type?.title || brokerage_dispatch?.trailer_type?.title}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </div>
                ) : (
                  <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                    -
                  </Typography>
                )}
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  {carrier ? 'Carrier Pay' : 'Driver Cost'}
                </Typography>
                {driver_amount && !Number.isNaN(Number(driver_amount).toFixed()) && (
                  <div className='d-flex justify-content-between align-items-center w-100'>
                    <Typography variant='overLine2'>
                      {currency}
                      {Number(driver_amount).toFixed(2)}
                    </Typography>
                    {isShipmentLocked(data) ? (
                      <ActionLocked shipment={data} />
                    ) : (
                      <EditIcon
                        width={13}
                        height={13}
                        className={classes.editIcon}
                        onClick={() => onChangeTab({ title: 'Driver Pay', id: 8 })}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className={classes.blockItem}>
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                    Reference
                  </Typography>
                  {isShipmentLocked(data) ? (
                    <ActionLocked shipment={data} />
                  ) : (
                    <EditIcon
                      width={13}
                      height={13}
                      className={classes.editIcon}
                      onClick={() => setEditReferenceOpen(true)}
                    />
                  )}
                </div>
                {shipment_type !== 'ltl' || (shipment_type === 'ltl' && shipment_billing?.length === 1) ? (
                  <Typography variant='overLine2'>{shipment_billing?.[0]?.reference_id}</Typography>
                ) : (
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id='button-tooltip-2' className={classes.toolTip}>
                        <div className={classes.multiReferenceToolTip}>
                          {shipment_billing?.map((el) => (
                            <span key={el?.id}>{el?.reference_id}</span>
                          ))}
                        </div>
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div ref={ref} className={classes.multi}>
                        <Typography {...triggerHandler} variant='overLine2'>
                          Multi-Ref ID
                        </Typography>
                      </div>
                    )}
                  </OverlayTrigger>
                )}
              </div>
              <div className={classes.blockItem}>
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                    Tags
                  </Typography>
                  {isShipmentLocked(data) ? (
                    <ActionLocked shipment={data} />
                  ) : (
                    <EditIcon
                      width={13}
                      height={13}
                      className={classes.editIcon}
                      onClick={() => setEditGroupOpen(true)}
                    />
                  )}
                </div>
                <div className='d-flex flex- gap-2 flex-wrap'>
                  {shipment_billing.map((billing) => {
                    const colorCode = billing?.groups?.color ? hexRgb(billing?.groups?.color) : null;
                    const textColor =
                      colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                        ? palette.white
                        : palette.gray900;
                    return (
                      <Typography
                        variant='overLine2'
                        style={{
                          backgroundColor: billing.groups?.color,
                          borderRadius: '10px',
                          padding: 3,
                          color: textColor,
                        }}
                      >
                        {billing?.groups?.short_name || '-'}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={classes.blockWrapperBottom}>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Billing Charges
                </Typography>
                {Array.isArray(shipment_billing) && shipment_billing?.length > 0 && (
                  <Typography variant='overLine2'>
                    {currencyData?.symbol || '$'}
                    {formatAmount(
                      shipment_billing.reduce((acc, el) => +acc + Number(el?.total_amount), 0),
                      2
                    )}
                  </Typography>
                )}
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  RPM
                </Typography>
                {Array.isArray(shipment_billing) && shipment_billing?.length > 0 && (
                  <Typography variant='overLine2'>
                    {currencyData?.symbol || '$'}
                    {formatAmount(
                      shipment_billing.reduce((acc, el) => +acc + Number(el?.total_amount), 0) /
                        (Math.round(loaded_miles) + Math.round(empty_miles)),
                      2
                    )}
                  </Typography>
                )}
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Stops
                </Typography>
                <div className={classes.blockItemStopsWrapper}>
                  {stops_count && (
                    <div className={classes.stopCountWrapper}>
                      <Typography variant='overLine'>{stops_count}</Typography>
                    </div>
                  )}
                  <div className={classes.stopsContainer}>
                    {data?.shipment_stops?.map((el) => {
                      const stopPointType = Number(el?.stop_point_type);
                      const name = mapper?.[stopPointType]?.name;
                      return (
                        <div
                          key={el?.id}
                          className={classes.stopCountWrapper}
                          style={{ backgroundColor: MAPPER_STOPS[+stopPointType]?.background }}
                        >
                          <Typography style={{ color: MAPPER_STOPS[+stopPointType]?.color }} variant='overLine'>
                            {name.toUpperCase()}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Empty Miles
                </Typography>
                <div className='d-flex align-items-center gap-3'>
                  {editEmptyMiles ? (
                    <>
                      <Input
                        size='small'
                        width='100px'
                        type='number'
                        placeholder='0'
                        name='empty_miles'
                        value={emptyMilesToEdit}
                        onChange={(e) => setEmptyMilesToEdit(e.target.value)}
                        onKeyDown={(e) => {
                          blockNonPositiveNumbers(e);
                          blockNotNumberChars(e);
                        }}
                      />
                      <DeleteIcon
                        className='pointer'
                        fill={palette.red500}
                        onClick={() => {
                          setEmptyMilesToEdit(Math.round(data?.empty_miles));
                          setEditEmptyMiles(false);
                        }}
                      />
                      <CheckIcon className='pointer' onClick={() => onMilesChange('Empty')} />
                    </>
                  ) : (
                    <>
                      <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                        {Math.round(data?.empty_miles)}
                      </Typography>
                      {isShipmentLocked(data) ? (
                        <ActionLocked shipment={data} />
                      ) : (
                        <EditIcon
                          width={13}
                          height={13}
                          className={classes.editIcon}
                          onClick={() => setEditEmptyMiles(true)}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Loaded Miles
                </Typography>
                <div className='d-flex align-items-center gap-3'>
                  {editLoadedMiles ? (
                    <>
                      <Input
                        size='small'
                        width='100px'
                        type='number'
                        placeholder='0'
                        name='loaded_mies'
                        value={loadedMilesToEdit}
                        onChange={(e) => setLoadedMilesToEdit(e.target.value)}
                        onKeyDown={(e) => {
                          blockNonPositiveNumbers(e);
                          blockNotNumberChars(e);
                        }}
                      />
                      <DeleteIcon
                        className='pointer'
                        fill={palette.red500}
                        onClick={() => {
                          setLoadedMilesToEdit(Math.round(data?.loaded_miles));
                          setEditLoadedMiles(false);
                        }}
                      />
                      <CheckIcon className='pointer' onClick={() => onMilesChange('Loaded')} />
                    </>
                  ) : (
                    <>
                      <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                        {Math.round(data?.loaded_miles)}
                      </Typography>
                      {isShipmentLocked(data) ? (
                        <ActionLocked shipment={data} />
                      ) : (
                        <EditIcon
                          width={13}
                          height={13}
                          className={classes.editIcon}
                          onClick={() => setEditLoadedMiles(true)}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Total Miles
                </Typography>
                <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                  {Math.round(data?.loaded_miles) + Math.round(data?.empty_miles)}
                </Typography>
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Route Duration
                </Typography>
                {data?.deadhead_drive_time && data?.shipment_drive_time && (
                  <Typography variant='overLine2' style={{ whiteSpace: 'nowrap' }}>
                    {sumDurations([
                      data?.deadhead_drive_time,
                      data?.shipment_drive_time,
                      ...[
                        ...(data?.shipment_stops || []).map((item) =>
                          getAverageTimeConverted(item.stop_point?.average_waiting_time)
                        ),
                      ],
                    ])}
                  </Typography>
                )}
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Scheduled Duration
                </Typography>
                {data?.scheduled_duration && (
                  <Typography variant='overLine2' style={{ whiteSpace: 'nowrap' }}>
                    {getAverageTimeConverted(data.scheduled_duration)}
                  </Typography>
                )}
              </div>
              <div className={classes.blockItem}>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Actual Duration
                </Typography>
                <Typography variant='overLine2' style={{ whiteSpace: 'nowrap' }}>
                  {data?.actual_duration || '-'}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={classes.blockWrapperTop}
            style={{ marginTop: 12, borderBottom: `1px solid ${palette.gray50}` }}
          >
            <div className={classes.blockItem}>
              <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                {shipment_billing?.length > 1 ? 'Customers' : 'Customer'}
              </Typography>
              {shipment_type !== 'ltl' ? (
                <Typography
                  className={classes?.customerId_text_single}
                  variant='overLine2'
                  onClick={() => navigate(`/customer-profile/${shipment_billing?.[0]?.billing_customer?.id}`)}
                >
                  {shipment_billing?.[0]?.billing_customer?.company_name}
                </Typography>
              ) : (
                <div className={classes.multi}>
                  {shipment_billing?.map((el) => {
                    return (
                      <div className='d-flex flex-column' key={el.id}>
                        <Typography
                          variant='overLine2'
                          style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                          onClick={() => navigate(`/customer-profile/${el?.slave_billing?.billing_customer?.id}`)}
                        >
                          {el?.slave_billing?.billing_customer?.company_name} - Shipment{' '}
                          {el?.slave_billing?.shipment_id}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className={classes.blockItem}>
              <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                Type
              </Typography>
              <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                {getShipmentType(data.shipment_type)}
              </Typography>
              {data.shipment_stops?.some((i) => i.hazardous_materials) && (
                <Typography variant='c1' style={{ color: palette.orange400 }}>
                  HAZMAT
                </Typography>
              )}
            </div>
            {data?.shipment_type === 'split-children' && (
              <div className={`${classes.blockItem} justify-content-center`}>
                <Typography
                  variant='overLine2'
                  style={{ color: palette.indigo500 }}
                  className='pointer'
                  onClick={() => setOpenViewSplits(true)}
                >
                  View Splits
                </Typography>
              </div>
            )}
            <div className={classes.blockItem}>
              <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                Stops
              </Typography>
              <div className={classes.blockItemStopsWrapper}>
                {stops_count && (
                  <div className={classes.stopCountWrapper}>
                    <Typography variant='overLine'>{stops_count}</Typography>
                  </div>
                )}
                <div className={classes.stopsContainer}>
                  {data?.shipment_stops?.map((el) => {
                    const stopPointType = Number(el?.stop_point_type);
                    const type = mapper?.[stopPointType]?.type;
                    const name = mapper?.[stopPointType]?.name;
                    return (
                      <div
                        key={el?.id}
                        className={classes.stopCountWrapper}
                        style={{ backgroundColor: statusColor[type].bgColor }}
                      >
                        <Typography style={{ color: statusColor[type].color }} variant='overLine'>
                          {name.toUpperCase()}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={classes.blockItem}>
              <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                Miles
              </Typography>
              <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
                {Math.round(Number(data?.loaded_miles) + Number(data?.empty_miles))}
              </Typography>
            </div>
            <div className={classes.blockItem}>
              <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                Billing Charges
              </Typography>
              {Array.isArray(shipment_billing) && shipment_billing?.length > 0 && (
                <Typography variant='overLine2'>
                  {currencyData?.symbol || '$'}
                  {formatAmount(
                    shipment_billing.reduce((acc, el) => +acc + Number(el?.total_amount), 0),
                    2
                  )}
                </Typography>
              )}
            </div>
            <div className={classes.blockItem}>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Reference
                </Typography>
                {isShipmentLocked(data) ? (
                  <ActionLocked shipment={data} />
                ) : (
                  <EditIcon
                    width={13}
                    height={13}
                    className={classes.editIcon}
                    onClick={() => setEditReferenceOpen(true)}
                  />
                )}
              </div>
              {shipment_type !== 'ltl' || (shipment_type === 'ltl' && shipment_billing?.length === 1) ? (
                <Typography variant='overLine2'>{shipment_billing?.[0]?.reference_id}</Typography>
              ) : (
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip id='button-tooltip-2' className={classes.toolTip}>
                      <div className={classes.multiReferenceToolTip}>
                        {shipment_billing?.map((el, i) => (
                          <span key={el?.id || i}>{shipment_billing?.[0]?.reference_id}</span>
                        ))}
                      </div>
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div ref={ref} className={classes.multi}>
                      <Typography {...triggerHandler} variant='overLine2'>
                        Multi-Ref ID
                      </Typography>
                    </div>
                  )}
                </OverlayTrigger>
              )}
            </div>
            <div className={classes.blockItem}>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <Typography variant='overLine2' style={{ fontWeight: 300 }}>
                  Tags
                </Typography>
                {isShipmentLocked(data) ? (
                  <ActionLocked shipment={data} />
                ) : (
                  <EditIcon
                    width={13}
                    height={13}
                    className={classes.editIcon}
                    onClick={() => setEditGroupOpen(true)}
                  />
                )}
              </div>
              <div className='d-flex flex- gap-2 flex-wrap'>
                {shipment_billing.map((billing) => {
                  const colorCode = billing?.groups?.color ? hexRgb(billing?.groups?.color) : null;
                  const textColor =
                    colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                      ? palette.white
                      : palette.gray900;
                  return (
                    <Typography
                      variant='overLine2'
                      style={{
                        backgroundColor: billing.groups?.color,
                        borderRadius: '10px',
                        padding: 3,
                        color: textColor,
                      }}
                    >
                      {billing?.groups?.short_name || '-'}
                    </Typography>
                  );
                })}
              </div>
            </div>
          </div>
        )
      ) : (
        <SkeletonCell />
      )}
      {children}
      {/* // Tabs */}
      {editReferenceOpen && (
        <UpdateReference
          open={editReferenceOpen}
          shipmentData={data}
          onClose={() => setEditReferenceOpen(false)}
          onSuccess={(editedShipmentData) => {
            editedShipmentData.forEach((billing) => {
              const params = {
                reference_id: billing.reference_id,
                customer_id: billing.billing_customer?.id,
                currency_id: billing.currency_id,
                contact_user_id: billing.contact_user?.id,
              };
              UpdateShipmentBillingHeader({ params, id: billing.id })
                .then(() => {
                  getHeaderInfo();
                })
                .catch((err) => {
                  showToaster({ type: 'error', message: getErrorMessage(err) });
                });
            });
            setEditReferenceOpen(false);
          }}
        />
      )}
      {!isBillOfLading && (
        <div className={classes.tabsContainer}>
          {(isRecurringLane
            ? RECURRING_TABS
            : isShipmentTable
            ? data.bill_of_lading_count
              ? SHIPMENT_DETAILS_TABS
              : SHIPMENT_DETAILS_TABS.filter((el) => el.id !== 9)
            : data.bill_of_lading_count
            ? SHIPMENT_DETAILS_TABS.filter((el) => el.id !== 8)
            : SHIPMENT_DETAILS_TABS.filter((el) => el.id !== 8 && el.id !== 9)
          )
            .filter((item) => (item.statuses ? item.statuses.includes(status) : true))
            .map((tab) => {
              const active = Number(isRecurringLane ? activeTabRecurring?.id : activeTabDetails?.id) === +tab.id;
              return (
                <div key={tab.id} className={classNames(classes.tabsWrapper, { [classes.hasAHover]: !active })}>
                  <CustomButton
                    hover={false}
                    title={
                      <span className='d-flex align-items-center gap-1 position-relative'>
                        {tab.id === 9 && !!data.bill_of_lading_count ? <LightningIcon /> : <div />}
                        {carrier && tab.secondTitle ? tab.secondTitle : tab.title}
                        {tab.id === 2 && data.communication_error > 0 && (
                          <WarningIcon fill={active ? palette.red300 : palette.red500} style={{ borderRadius: 0 }} />
                        )}
                        {tab.id === 6 && !!data?.shipment_notes?.length && (
                          <span className={classes.countBadge}>{data.shipment_notes.length}</span>
                        )}
                      </span>
                    }
                    className={classes.tabs}
                    onClick={() => onChangeTab(tab)}
                    styleTitle={{ padding: '2px 10px' }}
                    type={active ? 'primary' : 'secondary'}
                  />
                </div>
              );
            })}
        </div>
      )}
      {editGroupOpen && (
        <UpdateGroup
          open={editGroupOpen}
          shipmentData={data}
          onClose={() => setEditGroupOpen(false)}
          onSuccess={(editedShipmentData) => {
            editedShipmentData.forEach((billing) => {
              const params = {
                reference_id: billing.reference_id,
                customer_id: billing.billing_customer?.id,
                currency_id: billing.currency_id,
                contact_user_id: billing.contact_user?.id,
                group_id: billing.groups?.id,
              };
              UpdateShipmentBillingHeader({ params, id: billing.id })
                .then(() => {
                  getHeaderInfo();
                })
                .catch((err) => {
                  showToaster({ type: 'error', message: getErrorMessage(err) });
                });
            });
            setEditGroupOpen(false);
          }}
        />
      )}
      {openViewSplits && (
        <ShipmentSplits open={openViewSplits} onClose={() => setOpenViewSplits(false)} shipment={data} />
      )}
    </div>
  );
};

export default DetailsHeader;

const SkeletonCell = () => {
  return (
    <div className={classes.cellContainer}>
      <div className={classes.cellWrapperTop}>
        <div className={classes.cellItems}>
          <Skeleton animation='wave' style={{ width: '30%' }} />
          <Skeleton animation='wave' style={{ width: '80%' }} />
        </div>
        <div className={classes.cellItems}>
          <Skeleton animation='wave' style={{ width: '50%' }} />

          <div className={classes.flexAlign} style={{ width: '100%' }}>
            <Skeleton animation='wave' variant='circle' style={{ width: '20px', height: '20px', marginRight: 4 }} />
            <Skeleton animation='wave' style={{ width: '50%', marginRight: 4 }} />
            <Skeleton animation='wave' style={{ width: '12px', marginRight: 4 }} />
            <Skeleton animation='wave' style={{ width: '12px', marginRight: 4 }} />
            <Skeleton animation='wave' style={{ width: '12px', marginRight: 4, borderRadius: 4 }} />
          </div>
        </div>
        <div className={classes.cellItems}>
          <Skeleton animation='wave' style={{ width: '30%' }} />
          <Skeleton animation='wave' style={{ width: '90%' }} />
        </div>
        <div className={classes.cellItems}>
          <Skeleton animation='wave' style={{ width: '30%' }} />
          <Skeleton animation='wave' style={{ width: '50%' }} />
        </div>
        <div className={classes.cellItems}>
          <Skeleton animation='wave' style={{ width: '30%' }} />
          <Skeleton animation='wave' style={{ width: '50%' }} />
        </div>
      </div>
    </div>
  );
};
