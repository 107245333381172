import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FastField } from 'formik';
import { ReactComponent as CalendarIcon } from 'assets/icons/createShipment/calendar.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import SignatureCanvas from 'react-signature-canvas';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getEquipmentIdInTrailers } from 'Api/Planner';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from 'components/Typography';
import Input from 'common/Input';
import ErrorMessage from 'common/ErrorMessage';
import { DateTimePicker } from 'common/Pickers';
import { validateRequired } from 'components/AddEditModalStops/validates';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import useDateFormat from 'hooks/useDateFormat';
import classes from '../checkInOut.module.scss';

const PickUpInModal = ({ values, stop, setFieldValue, touched, errors, shipment, setImageData, shipmentSettings }) => {
  const showToaster = useShowToaster();
  const { use } = useTheme();
  const { billOfLadingTitle } = useSelector((state) => state.root);
  const { formatDate, formatDateTime, dateFormat } = useDateFormat();
  const [equipmentId, setEquipmentId] = useState([]);
  const signPad = useRef({});
  const styles = useMemo(() => {
    return {
      datePicker: {
        width: 206,
        margin: 0,
        justifyContent: 'flex-start',
      },
      labelInput: {
        marginTop: '8',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    };
  }, [use, palette]);

  function getEquipmentId() {
    return getEquipmentIdInTrailers({ status_id: '4,6', itemsPerPage: 1000 }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `${el?.equipment_id || ''} (${el?.make || ''} ${el?.model_id || ''})`;
          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setEquipmentId(equipmentId);
      }
    });
  }

  const onClear = () => {
    setFieldValue('signature', '');
    signPad?.current.clear();
  };

  const shouldUpdateStops = (nextProps, props, name) => {
    const nextError = nextProps.formik.errors?.equipment_id;
    switch (name) {
      case 'equipment_id':
        return nextError?.options !== props?.options;
      default:
    }
  };

  const save = () => {
    signPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      setImageData(file);
      setFieldValue('signature', file);
      showToaster({ type: 'success', message: 'Signature has been saved' });
    }, 'image/png');
  };

  useEffect(() => {
    getEquipmentId();
  }, []);

  return (
    <div className={classes.globalWrapper}>
      <div className={classes.datesWrapper}>
        <div className={classes.datePickerDataWrapper}>
          <Typography variant='s2' className='mb-2'>
            Scheduled Date/Time
          </Typography>
          <div className={classes.datePickerData}>
            <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
            <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
              {stop?.scheduled_date && formatDate(stop.scheduled_date)}, {stop?.from} {stop?.to ? `to ${stop?.to}` : ''}
            </Typography>
          </div>
        </div>

        <div className={classes.errorWrapper}>
          {stop.arrival_date ? (
            <div className={classes.datePickerDataWrapper}>
              <Typography variant='s2' className='mb-2'>
                Check In
              </Typography>
              <div className={classes.datePickerData}>
                <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
                <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
                  {stop.arrival_date ? formatDateTime(stop.arrival_date, true) : ''}
                </Typography>
              </div>
            </div>
          ) : (
            <DateTimePicker
              label='Check In'
              name='arrival_date'
              value={values.arrival_date}
              onChange={(val) => setFieldValue('arrival_date', val)}
              disabled={!!stop.arrival_date}
              disablePast={!shipmentSettings?.allow_past_date}
              error={touched?.arrival_date && errors?.arrival_date}
            />
          )}
        </div>

        {!!stop.departure_date || !stop.arrival_date ? (
          <div className={classes.datePickerDataWrapper}>
            <Typography variant='s2' className='mb-2'>
              Check Out
            </Typography>
            <div className={classes.datePickerData}>
              <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
              <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
                {dateFormat}
              </Typography>
            </div>
          </div>
        ) : (
          <DateTimePicker
            label='Check Out'
            name='departure_date'
            value={values.departure_date}
            onChange={(val) => setFieldValue('departure_date', val)}
            disabled={!!stop.departure_date || !stop.arrival_date}
            minDate={new Date(stop.arrival_date)}
            error={touched?.departure_date && errors?.departure_date}
          />
        )}
      </div>

      <div className={classes.equipmentActionWrapper}>
        <Typography variant='s2'>Equipment Action</Typography>

        {!!values.equipment_tbd && !values.check_in && (
          <div className='mt-2 mb-1'>
            <InfoIcon fill='#AA5B00FF' />
            <Typography
              variant='s2'
              style={{
                color: '#AA5B00FF',
                marginLeft: 6,
              }}
            >
              You need to determine trailer.
            </Typography>
          </div>
        )}

        <div>
          <FastField
            value='1'
            type='radio'
            name='equipment_action'
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Live Load
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>
        <div>
          <FastField
            value='3'
            type='radio'
            name='equipment_action'
            labelStyle={{ marginTop: 8 }}
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Drop & Hook
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>

        {Number(values?.equipment_action) === 3 && (
          <div className={classes.dropHookSubBlock}>
            <Typography variant='s2'>Drop Trailer & Hook to </Typography>
            <div className={classes.selectDropHook}>
              <FastField
                name='equipment_id'
                component={CustomSelect}
                styles={{ height: 32 }}
                isSearchable
                disabled={!!values.check_in}
                options={equipmentId}
                shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_id')}
                defaultText={
                  stop?.equipment?.equipment_id
                    ? `${stop?.equipment?.equipment_id}  (${stop?.equipment?.length?.length || ''} ${
                        stop?.equipment?.length?.unit || ''
                      } ${stop?.equipment?.equipment_type?.title || '-'}) `
                    : ''
                }
              />
            </div>
          </div>
        )}
        <div>
          <FastField
            value='2'
            type='radio'
            name='equipment_action'
            labelStyle={{ marginTop: 8 }}
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Hook Trailer
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>
        {Number(values?.equipment_action) === 2 && (
          <div className={classes.dropHookSubBlock} style={{ margin: '8px 0 0 16px' }}>
            <div className={classes.selectDropHook}>
              <FastField
                name='equipment_id'
                component={CustomSelect}
                styles={{ height: 32 }}
                options={equipmentId}
                disabled={!!values.check_in}
                defaultText={
                  stop?.equipment?.equipment_id
                    ? `${stop?.equipment?.equipment_id}  (${stop?.equipment?.length?.length || ''} ${
                        stop?.equipment?.length?.unit || ''
                      } ${stop?.equipment?.equipment_type?.title || '-'}) `
                    : ''
                }
                shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_id')}
              />
            </div>
          </div>
        )}
      </div>

      {!values.check_in && (
        <>
          {/* <div className={classes.inputsWrapper}> */}
          {/*  <div className={classes.skilletInputWrapper}> */}
          {/*    <Typography variant='s2'>Quantity</Typography> */}
          {/*    <div className={classes.skilletInput}> */}
          {/*      <Typography variant='s2'>{stop?.quantity}</Typography> */}
          {/*    </div> */}
          {/*  </div> */}
          {/*  <div className={classes.errorWrapper} style={{ width: '100%', maxWidth: 206 }}> */}
          {/*    <FastField */}
          {/*      required={!!shipmentSettings?.require_actual_data} */}
          {/*      name='actual_quantity' */}
          {/*      component={CustomInput} */}
          {/*      label='Actual Qty' */}
          {/*      validate={shipmentSettings?.require_actual_data ? validateRequired : undefined} */}
          {/*      labelStyle={styles.labelInput} */}
          {/*      className={classes.customInputField} */}
          {/*      key={shipmentSettings?.require_actual_data} */}
          {/*    /> */}
          {/*    <ErrorMessage error={touched?.actual_quantity && errors?.actual_quantity} /> */}
          {/*  </div> */}
          {/* </div> */}

          {/* <div className={classes.inputsWrapper}> */}
          {/*  <div className={classes.skilletInputWrapper}> */}
          {/*    <Typography variant='s2'>Weight</Typography> */}
          {/*    <div className={classes.skilletInput}> */}
          {/*      <Typography variant='s2'>{stop?.weight}</Typography> */}
          {/*    </div> */}
          {/*  </div> */}

          {/*  <div className={classes.errorWrapper} style={{ width: '100%', maxWidth: 206 }}> */}
          {/*    <FastField */}
          {/*      required={!!shipmentSettings?.require_actual_data} */}
          {/*      name='actual_weight' */}
          {/*      component={CustomInput} */}
          {/*      label='Actual Weight' */}
          {/*      validate={shipmentSettings?.require_actual_data ? validateRequired : undefined} */}
          {/*      labelStyle={styles.labelInput} */}
          {/*      className={classes.customInputField} */}
          {/*      key={shipmentSettings?.require_actual_data} */}
          {/*    /> */}
          {/*    <ErrorMessage error={touched?.actual_weight && errors?.actual_weight} /> */}
          {/*  </div> */}
          {/* </div> */}
          <div className='d-flex flex-column gap-4 mb-4'>
            {values.cargos.map((item, index) => (
              <div className={`${values.cargos?.length > 1 ? classes.cargoContainer : ''}`}>
                {values.cargos?.length > 1 && (
                  <Typography variant='s2' style={{ color: palette.gray700, display: 'inline-block' }} className='mb-2'>
                    {item.commodity?.title} {item.sku_barcode ? `(${item.sku_barcode})` : ''}
                  </Typography>
                )}
                <div className='d-flex gap-5 mb-3'>
                  <div>
                    <Input
                      required={!!shipmentSettings?.require_actual_data}
                      label='Quantity'
                      name={`cargos[${index}].expected_quantity`}
                      value={item.expected_quantity || ''}
                      disabled
                    />
                  </div>
                  <div>
                    <Input
                      required={!!shipmentSettings?.require_actual_data}
                      label='Actual Qty'
                      name={`cargos[${index}].reported_quantity`}
                      value={item.reported_quantity || ''}
                      onChange={(e) => setFieldValue(`cargos[${index}].reported_quantity`, e.target.value)}
                      error={touched?.cargos?.[index]?.reported_quantity && errors?.cargos?.[index]?.reported_quantity}
                    />
                  </div>
                </div>

                <div className='d-flex gap-5'>
                  <div>
                    <Input
                      required={!!shipmentSettings?.require_actual_data}
                      label='Weight'
                      name={`cargos[${index}].expected_weight`}
                      value={
                        formatNumber(
                          item.expected_weight_unit === 1
                            ? item.expected_quantity * item.expected_weight
                            : item.expected_weight
                        ) || ''
                      }
                      disabled
                    />
                  </div>
                  <div>
                    <Input
                      required={!!shipmentSettings?.require_actual_data}
                      label='Actual Weight'
                      name={`cargos[${index}].reported_weight`}
                      value={item.reported_weight || ''}
                      onChange={(e) => setFieldValue(`cargos[${index}].reported_weight`, e.target.value)}
                      error={touched?.cargos?.[index]?.reported_weight && errors?.cargos?.[index]?.reported_weight}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className='mb-4'>
            <div className='d-flex gap-5 mb-3'>
              <div>
                <Input
                  name='seal_id'
                  label='Seal ID'
                  value={values.seal_id || ''}
                  onChange={(e) => setFieldValue(`seal_id`, e.target.value)}
                  error={touched?.seal_id && errors?.seal_id}
                />
              </div>
              <div>
                <Input
                  name='bill_lading_id'
                  required={!!shipmentSettings?.require_actual_data}
                  label={`${billOfLadingTitle} ID`}
                  value={values.bill_lading_id || ''}
                  onChange={(e) => setFieldValue(`bill_lading_id`, e.target.value)}
                  error={touched?.bill_lading_id && errors?.bill_lading_id}
                />
              </div>
            </div>
          </div>

          {/* <div className={classes.inputsWrapper}> */}
          {/*  <div className={classes.errorWrapper} style={{ width: '100%', maxWidth: 206, marginRight: 20 }}> */}
          {/*    <FastField */}
          {/*      name='seal_id' */}
          {/*      component={CustomInput} */}
          {/*      label='Seal ID' */}
          {/*      labelStyle={styles.labelInput} */}
          {/*      className={classes.customInputField} */}
          {/*    /> */}
          {/*    <ErrorMessage error={touched?.seal_id && errors?.seal_id} /> */}
          {/*  </div> */}

          {/*  <div className={classes.errorWrapper} style={{ width: '100%', maxWidth: 206 }}> */}
          {/*    <FastField */}
          {/*      required={!!shipmentSettings?.require_actual_data} */}
          {/*      name='bill_lading_id' */}
          {/*      component={CustomInput} */}
          {/*      label={`${billOfLadingTitle} ID`} */}
          {/*      validate={shipmentSettings?.require_actual_data ? validateRequired : undefined} */}
          {/*      labelStyle={styles.labelInput} */}
          {/*      className={classes.customInputField} */}
          {/*      key={shipmentSettings?.require_actual_data} */}
          {/*    /> */}
          {/*    <ErrorMessage error={touched?.bill_lading_id && errors?.bill_lading_id} /> */}
          {/*  </div> */}
          {/* </div> */}
          {!!shipment?.shipment_billing?.[0]?.billing_customer?.customer_payment_term?.require_signature_on_stop && (
            <>
              <div className={classes.signature_wrapper}>
                <div className={classes.signature_top}>
                  <div style={{ marginBottom: 8 }}>
                    <span>Signature</span>
                  </div>
                  <div>
                    <span onClick={onClear} className={classes.clear} style={{ marginRight: 10 }}>
                      Clear
                    </span>
                    <span onClick={save} className={classes.clear}>
                      Save
                    </span>
                  </div>
                </div>
                <div className={classes.signature_area}>
                  <SignatureCanvas canvasProps={{ className: classes.sigCanvas }} penColor='black' ref={signPad} />
                </div>
              </div>
              <div>
                <FastField
                  name='signature_name'
                  component={CustomInput}
                  style={{ width: '100%', maxWidth: 320, paddingLeft: 5 }}
                  validate={validateRequired}
                  placeholder='Full Name'
                  labelStyle={styles.nameInput}
                  className={classes.customInputField}
                />
                <ErrorMessage error={touched?.signature_name && errors?.signature_name} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PickUpInModal;
