import React from 'react';
import HeaderStar from 'components/HeaderStar';
import StatsSection from './StatsSection';
import TableSection from './TableSection';
import { SHeaderWrapper, SPageWrapper } from './DotMonitoring.styles';

const DotMonitoring = () => {
  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='DOT Monitoring' />
      </SHeaderWrapper>
      <StatsSection />
      <TableSection />
    </SPageWrapper>
  );
};

export default DotMonitoring;
