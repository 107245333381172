import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Delete } from 'assets/icons/xClose.svg';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import Input from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { validationSchema } from './validateSchema';
import { SActions } from '../../Payroll/Payroll.styles';
import { SWrapper } from '../../Payroll/FormItem/FormItem.styles';

const ItemForm = ({ onSubmit, onDelete, loading, editable, onCancel, item }) => {
  const initialValues = {
    account_type: item?.account_type || '',
  };
  const { handleChange, handleSubmit, values, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <SWrapper>
      <div className='flex-item' style={{ width: '100%' }}>
        <Input
          name='account_type'
          onChange={handleChange}
          value={values.account_type}
          placeholder='Account Type'
          error={touchedErrors.account_type}
          style={{ maxWidth: '900px', width: '100%' }}
        />
      </div>
      <SActions className='flex-item'>
        {!!editable && (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '1px 6px', margin: 0 }}
            onClick={onCancel}
          />
        )}
        {loading ? (
          <span className='loading-wrapper'>
            <CircularProgress size={16} />
          </span>
        ) : (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title=''
            leftIcon={<Delete style={{ fill: '#464F60' }} />}
            styleButton={{ padding: '7px 8px', margin: 0 }}
            onClick={onDelete}
          />
        )}
        <CustomButton
          width={12}
          height={12}
          type='primary'
          title=''
          leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={handleSubmit}
        />
      </SActions>
    </SWrapper>
  );
};

export default ItemForm;
