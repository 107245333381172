import React, { useMemo, useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import { tableCloudPopover } from 'Api/Planner';
import CustomButton from 'components/CustomButton/CustomButton';
import EDIRejectReason from 'componentsV2/Shipment/EDIRejectReason';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';

const OverLayShipmentIdCloud = ({ rowData, onClickButtons, allFilterData, placement = 'bottom' }) => {
  const { convertToCustomerTime } = useDateFormat();
  const shoiwToaster = useShowToaster();
  const [showPopoverCloud, setShowPopoverCloud] = useState(false);
  const [openRejectReason, setOpenRejectReason] = useState(false);

  const { accept_date, accepted_by } = rowData || {};

  const onAccept = (data) => {
    const shipment_ids = [data?.shipment_id];
    tableCloudPopover({ shipment_ids, acceptOrDecline: 1 })
      .then((res) => {
        if (res?.status) {
          shoiwToaster({ type: 'success', message: 'Shipment Accepted Successfully' });
          setTimeout(() => {
            setShowPopoverCloud(false);
            if (typeof onClickButtons === 'function') {
              onClickButtons({ allFilterData, hasALoading: false });
            }
          }, 0);
        } else {
          setShowPopoverCloud(false);
          shoiwToaster({ type: 'error', message: 'Something went wrong!' });
        }
      })
      .finally(() => {
        setShowPopoverCloud(false);
      });
  };

  const onDecline = () => {
    setOpenRejectReason(true);
    setShowPopoverCloud(false);
  };

  const popoverCloud = useMemo(() => {
    return (
      <Popover
        id='popover-basic'
        style={{
          zIndex: 2000,
          backgroundColor: palette.white,
        }}
      >
        <Popover.Body style={{ padding: 12 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CustomButton
              type='primary'
              title='Accept'
              onClick={(e) => {
                e.stopPropagation();
                onAccept(rowData);
              }}
              styleButton={{
                padding: '0px 8px',
                margin: '0 0 8px 0',
              }}
            />
            <CustomButton
              type='danger'
              title='Reject'
              disabled={rowData?.edi_acceptance_status}
              onClick={(e) => {
                e.stopPropagation();
                onDecline(rowData);
              }}
              styleButton={{
                padding: '0px 6px',
                margin: 0,
              }}
            />
          </div>
        </Popover.Body>
      </Popover>
    );
  }, []);

  const toolTip = useMemo(
    (props) => {
      return (
        <Tooltip id='button-tooltip' {...props} style={{ display: showPopoverCloud ? 'none' : 'inherit' }}>
          EDI/API SHIPMENT
        </Tooltip>
      );
    },
    [showPopoverCloud]
  );

  return (
    <>
      {rowData.accept_date !== null ? (
        <OverlayTrigger
          placement={placement}
          delay={{ show: 200, hide: 350 }}
          overlay={(props) => (
            <Tooltip id='button-tooltip' {...props}>
              EDI/API SHIPMENT - Accepted by&nbsp;
              {accepted_by?.first_name} {accepted_by?.last_name} {!accepted_by && 'System'}&nbsp; on {accept_date}
            </Tooltip>
          )}
        >
          <div onClick={(e) => e.stopPropagation()} className='tablePlaner_cloud_container'>
            <CloudIcon fill={palette.green400} />
          </div>
        </OverlayTrigger>
      ) : rowData.declined_at ? (
        <OverlayTrigger
          placement={placement}
          delay={{ show: 200, hide: 350 }}
          overlay={(props) => (
            <Tooltip id='button-tooltip' {...props}>
              EDI/API SHIPMENT - Rejected at {convertToCustomerTime(rowData.declined_at)}
            </Tooltip>
          )}
        >
          <div onClick={(e) => e.stopPropagation()} className='tablePlaner_cloud_container'>
            <CloudIcon fill={palette.green400} />
          </div>
        </OverlayTrigger>
      ) : rowData.expired_at ? (
        <OverlayTrigger
          placement={placement}
          delay={{ show: 200, hide: 350 }}
          overlay={(props) => (
            <Tooltip id='button-tooltip' {...props}>
              EDI/API SHIPMENT - Expired on {convertToCustomerTime(rowData.expired_at)}
            </Tooltip>
          )}
        >
          <div onClick={(e) => e.stopPropagation()} className='tablePlaner_cloud_container'>
            <CloudIcon fill={palette.green400} />
          </div>
        </OverlayTrigger>
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <OverlayTrigger
            trigger='click'
            placement='auto'
            overlay={popoverCloud}
            show={showPopoverCloud}
            rootClose
            rootCloseEvent='mousedown'
            onToggle={(e) => setShowPopoverCloud(e)}
          >
            <div className='tablePlaner_cloud_container'>
              <OverlayTrigger placement='bottom' delay={{ show: 200, hide: 350 }} overlay={toolTip}>
                <div className='tablePlaner_cloud_container'>
                  <CloudIcon fill={palette.green400} />
                </div>
              </OverlayTrigger>
            </div>
          </OverlayTrigger>
        </div>
      )}
      {openRejectReason && (
        <EDIRejectReason
          open={openRejectReason}
          onClose={() => setOpenRejectReason(false)}
          shipment={rowData}
          onSuccess={() => {
            if (typeof onClickButtons === 'function') {
              onClickButtons({ allFilterData, hasALoading: false });
            }
          }}
        />
      )}
    </>
  );
};

export default OverLayShipmentIdCloud;
