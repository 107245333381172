import React, { useState } from 'react';
import { palette } from 'utils/constants';
import Modal from 'common/Modal';
import ContactBookTab from 'components/ContactBookTab/ContactBookTab';

const AddCustomerContact = ({ open, onClose, customer, onSuccess }) => {
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Update Customer Contacts'
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      $bgColor={palette.gray0}
      $maxWidth='1600px'
      $minWidth='1600px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        ...(!hasChanges
          ? [
              {
                key: 'submit',
                type: 'primary',
                title: 'Done',
                onClick: onClose,
              },
            ]
          : []),
      ]}
    >
      <ContactBookTab isModalView customer={customer} onSuccess={onSuccess} setHasChanges={setHasChanges} />
    </Modal>
  );
};

export default AddCustomerContact;
