import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cross from 'assets/icons/drivers/secondClose.svg';
import ViewPdf from 'components/ViewPdf';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { DepartmentsEmail } from 'Api/Driver';
import { staffNoteData, staffCreateNotes, deleteStaffNote, getStaffNotes } from 'Api/Staff';
import { getErrorMessage } from 'utils/error';
import { ReactComponent as MinimizeIcon } from 'assets/icons/minus.svg';
import { addMinimizedNotes, handleOutside } from 'store/reducers/root.reducer';
import MaterialTableWrapper from '../MaterialTableWrapper';
import TableFooter from '../TableFooter/TableFooter';
import NoteModal from '../AddNoteModal/AddNoteModal';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import CustomizedSnackbars from '../toast/Toast';
import AddNotes from '../TableShipments/detailsRow/steps/Notes/AddNotes';
import classes from '../TableRowEditModalTypes/NotesEditModal/notesEditModal.module.css';
import { useColumns } from './StaffNoteTable.data';

const StaffNoteTable = ({ staff_name }) => {
  const dispatch = useDispatch();
  const { isOutside } = useSelector((state) => state?.root);
  const { use } = useTheme();
  const [modalShow, setModalShow] = React.useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [noteTab, setNoteTab] = React.useState({});
  const [emails, setEmails] = React.useState({});
  const [user, SetUser] = React.useState({});
  const [open, setOpen] = React.useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [sort, setSort] = useState({ field: 'id', sortBy: 'desc' });
  const [page, setPage] = useState(1);

  const [createLoading, setCreateLoading] = useState(false);
  const [showMessage, setShowMessage] = React.useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 60000);
    }
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const createNotes = (createNotesInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);

    staffCreateNotes(createNotesInfo)
      .then((res) => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        const tempNotes = [...noteTab.data, res.data.data];
        setNoteTab({ ...noteTab, total: noteTab.total + 1, data: tempNotes });
        setCreateLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setCreateLoading(false);
      });
  };

  const PaginationComponent = () => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        rowPerPage={rowPerPage}
        totalCount={noteTab?.total}
        totalLength={noteTab?.data?.length}
        lastPage={noteTab?.last_page}
        currentPage={noteTab?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setNoteTab(data)}
        onPageChange={onPageChange}
      />
    );
  };

  const removeNote = useCallback(
    (item) => {
      const noteId = item?.id;
      const index = item?.tableData?.id;
      deleteStaffNote({ noteId })
        .then(() => {
          const tempNote = [...(noteTab?.data || [])];
          tempNote.splice(index, 1);
          setNoteTab({ ...noteTab, data: tempNote });
          onSuccess('Note has been deleted successfully');
          setOpen(null);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    },
    [noteTab]
  );

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    setLoading(true);
  };

  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem('user'));
    SetUser(getUser);
  }, []);

  useEffect(() => {
    if (isOutside) {
      getNotesData();
    }
  }, [isOutside]);

  const getNotesData = async () => {
    setLoading(true);
    staffNoteData({ page: '1', driver_staff_id: id, itemsPerPage: rowPerPage })
      .then((res) => {
        setNoteTab(res || {});
        setLoading(false);
        dispatch(handleOutside(false));
      })
      .catch(() => {
        setLoading(false);
      });
    DepartmentsEmail().then((res) => {
      setEmails(res.data);
    });
  };

  const onClickView = (path1) => {
    setPdfUrl(path1);
  };

  const columns = useColumns({ sort, sortingQuery, onClickView });

  const handleClickOpen = (item) => {
    setOpen(item);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const getNotes = async () => {
    try {
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page,
        itemsPerPage: rowPerPage,
        [sortField]: sort.sortBy,
      };
      const res = await getStaffNotes(id, params);
      setNoteTab(res);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getNotes();
  }, [rowPerPage, page, sort]);

  if (loading) {
    return <TablePreLoader />;
  }

  const onAddNote = (form) => {
    const notesObj = {
      ...form.values,
      staff_id: Number(id),
    };
    const formData = new FormData();
    formData.append('staff_id', notesObj.staff_id);
    formData.append('title', notesObj.title);
    formData.append('notes', notesObj.notes);
    formData.append('attachment', notesObj.attachment);
    formData.append('note_to_email', notesObj.note_to_email);
    formData.append('department_id', notesObj.department_id);

    staffCreateNotes(formData)
      .then(() => {
        setTimeout(() => {
          form.resetForm();
        }, 1000);
        onSuccess('Notes has been successfully created');
        getNotes();
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setCreateLoading(false);
      });
  };

  function onMinimize(e) {
    e.stopPropagation();
    dispatch(addMinimizedNotes({ label: 'Staff', shipment_id: id, id, name: staff_name }));
    dispatch(handleOutside(false));
  }

  return (
    <div
      className='notes-table-container driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.gray700, palette.gray200),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='notes-header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Notes
        </p>
        <div onClick={(e) => onMinimize(e)} className={classes.minimize}>
          <MinimizeIcon />
        </div>
      </div>
      <div className='AddNotes-div'>
        <AddNotes onSubmit={onAddNote} hideInvoice />
      </div>
      <div className='table-wrap'>
        <div className='sub-table-container'>
          <MaterialTableWrapper
            data={noteTab?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
            actionsColumnIndex={-1}
            actions={[
              (rowData) => ({
                icon: () => {
                  return (
                    <div onClick={(e) => e.stopPropagation()}>
                      {user?.id === rowData?.added_by?.id ? (
                        <img
                          src={cross}
                          alt='cross'
                          onClick={() => handleClickOpen(rowData)}
                          className='cross-icon-wrap'
                        />
                      ) : null}
                    </div>
                  );
                },
              }),
            ]}
          />
        </div>
        {modalShow && (
          <NoteModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            driverId={id}
            createNotes={createNotes}
            onSuccess={onSuccess}
            loading={createLoading}
            emails={emails}
          />
        )}

        <Dialog
          open={!!open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Confirm Your Action</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>Are you sure you want to remove this?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={handleClose}>
              Cancel
            </Button>
            <Button className='next-step' onClick={() => removeNote(open)} autoFocus>
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl('')} pdfUrl={pdfUrl} />
    </div>
  );
};

export default StaffNoteTable;
