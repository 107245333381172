export const MAP_FILTER_SEARCH_BY_VEHICLE = [
  {
    title: 'Equipment',
    id: 1,
    value: 'equipment_id',
  },
  {
    title: 'Location',
    id: 2,
    value: 'location',
  },
  {
    title: 'Drivers',
    id: 3,
    value: 'driver',
  },
];

export const MAP_FILTER_SEARCH_BY_TRAILER = [
  {
    title: 'Equipment',
    id: 1,
    value: 'equipment_id',
  },
  {
    title: 'Location',
    id: 2,
    value: 'location',
  },
];

export const CAN_TRAVEL_TO = [
  {
    id: 'usa',
    title: 'USA',
    value: 'usa',
  },
  {
    id: 'canada',
    title: 'Canada',
    value: 'canada',
  },
  {
    id: 'mexico',
    title: 'Mexico',
    value: 'mexico',
  },
];
