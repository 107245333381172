import { useMemo } from 'react';

export const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize }) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    return {
      totalPageCount,
      paginationRange: range(1, totalPageCount),
    };
  }, [totalCount, pageSize]);
};
