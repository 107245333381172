import React from 'react';
import { Line } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { monthOfCollisionOptions } from './Charts.data';

const MonthOfCollisionChart = ({ monthsData }) => {
  const data = {
    labels: (monthsData || []).map((item) => `${item.month}`),
    datasets: [
      {
        label: '',
        data: monthsData?.map((i) => i.accidents) || [],
        borderColor: palette.red500,
        pointBackgroundColor: palette.red500,
      },
    ],
  };

  return <Line options={monthOfCollisionOptions()} data={data} />;
};

export default MonthOfCollisionChart;
