import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { palette } from 'utils/constants';
import Modal from 'common/Modal';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateAlert } from 'Api/AlertCenter';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { ALERT_TYPE, statusOptions } from '../TakeActions.data';

const DocumentReceived = ({ open, onClose, alert, onSuccess }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [document, setDocument] = useState(null);

  const updateStatus = async () => {
    if (!status) {
      setError(true);
      return;
    }

    try {
      setLoading(true);
      const body = {
        ...alert,
        status: status.value,
      };
      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const onView = () => {
    if (alert.alert_name?.alert_name === ALERT_TYPE.DRIVER_DOCUMENT_RECEIVED) {
      navigate('/documents', { state: { newDocumentId: alert.document_id } });
      onClose();
      return;
    }

    if (alert.alert_name?.alert_name === ALERT_TYPE.NEW_INVOICES_READY) {
      navigate(`/shipment/${alert.shipment_id}`, { state: { defaultTab: 5 } });
      onClose();
      return;
    }

    setDocument(alert.document_path);
  };

  const onChange = (val) => {
    setStatus(val);
    setError(false);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      padding='0'
      $maxWidth='870px'
      $minWidth='870px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        ...(alert.document_path ||
        (alert.alert_name?.alert_name === ALERT_TYPE.DRIVER_DOCUMENT_RECEIVED && alert.document_id) ||
        (alert.alert_name?.alert_name === ALERT_TYPE.NEW_INVOICES_READY && alert.invoice_id)
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: onView,
              },
            ]
          : []),
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: updateStatus,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <Autocomplete
            width='260px'
            required
            label='Action'
            name='action'
            options={statusOptions}
            value={status}
            onChange={(e, val) => onChange(val)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            error={error && 'Required'}
          />
        </div>
      </div>
      <ViewPdf open={!!document} title='Document' pdfUrl={document} onClose={() => setDocument(null)} />
    </Modal>
  );
};

export default DocumentReceived;
