import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './selectPlan.css';
import { useNavigate } from 'react-router-dom';
import check from 'assets/images/onboarding/check.png';
import PlanTable from './planTable/planTable';
import PlanCard from './PlanCard';

const SelectPlan = () => {
  const navigate = useNavigate();
  const { plans } = useSelector((state) => state.onboarding);
  const [defaultActive, setDefaultActive] = useState(0);
  const [active, setActive] = useState(defaultActive);
  const [activePlan, setActivePlan] = useState(null);

  const selectPlan = (plan_id, plan) => {
    localStorage.setItem('selected-plan', JSON.stringify(plan));
    localStorage.setItem('plan-selected', JSON.stringify(plan_id));
    navigate('/onboarding/company');
  };

  const changeActive = (idx, item) => {
    setActive(idx);
    setDefaultActive(idx);
    setActivePlan(item);
  };

  useEffect(() => {
    if (!plans?.length) {
      return;
    }

    setActivePlan(plans[0].plan_types?.[0]);
  }, [plans]);

  return (
    <div className='select-plan'>
      <div className='select-plan-main  '>
        <h1 className='header gradient plan-mobile-heading'>Try Truckin Digital for Free, No Commitment!</h1>
        <p className='subtitle-s1'>Simple pricing for Everyone, choose the plan that’s right for your Company.</p>
        <div className='check'>
          <p className='check-text'>
            <img src={check} alt='check' /> Support Included
          </p>
          <p className='check-text'>
            <img src={check} alt='check' />
            Billed Per User (Driver & Staff)
          </p>
          <p className='check-text'>
            <img src={check} alt='check' />
            Cancel Anytime
          </p>
        </div>
        <div className='plansContainer'>
          <div className='plans'>
            {plans?.[0]?.plan_types?.map((item, idx) => (
              <PlanCard
                key={item.id}
                plan={item}
                isActive={idx === active}
                selectPlan={() => selectPlan(item, plans[0])}
                onClick={() => changeActive(idx, item)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='price-table'>
        <PlanTable activePlan={activePlan} />
      </div>
      <div className='divider' />
      <div className='faq'>
        <h1 className='header mobile-faq-header' style={{ color: '#171C26', textAlign: 'center' }}>
          Frequently Asked Questions
        </h1>
        <div className='faq-card-container'>
          <div className='faq-card'>
            <p className='faq-head'>Plans, Upgrades and Hardware</p>
            <p className='faq-text'>
              Features are based on the plan you select. If the plan does not offer the feature you are looking for, you
              must upgrade to the plan that does. Hardware can be added from the Hardware settings page.
            </p>
          </div>
          <div className='faq-card'>
            <p className='faq-head'>What if I am a broker and carrier?</p>
            <p className='faq-text'>
              We will link your brokerage account to your carrier account so you can switch between the accounts.
            </p>
          </div>
          <div className='faq-card'>
            <p className='faq-head'>How do drivers log in?</p>
            <p className='faq-text'>
              After you have created your account, you must then create your driver profiles. Next, the system will send
              a text message to your driver giving him/her instructions on how to install the app on their smart tablet
              / phone device.
            </p>
          </div>
          <div className='faq-card'>
            <p className='faq-head'>IFTA</p>
            <p className='faq-text'>
              IFTA Reporting: Mileage, Gallons and Diesel Amount Price. We require fuel data input or auto sync with
              card provider with our connected services. All IFTA Reporting only covers United States, Canada and
              Mexico. We do not file to the state, we only provide a report.
            </p>
          </div>
          <div className='faq-card'>
            <p className='faq-head'>EDI & API Integrations</p>
            <p className='faq-text'>
              We provide a list of integrated service providers with the system. You can locate this in our Connected
              Services page. If your provider is not on the list, you may request it. Fees apply only if expedited
              service is required.
            </p>
          </div>

          <div className='faq-card'>
            <p className='faq-head'>Connected Services</p>
            <p className='faq-text'>
              Connected Services (ELD's, Tracking etc.) has variety of Providers which may have separate cost associated
              to them.
            </p>
          </div>

          <div className='faq-card'>
            <p className='faq-head'>Document Manager</p>
            <p className='faq-text'>
              Receive Scanned Documents in real-time to your document manager dashboard. Scanned documents can be
              uploaded by web, manager app or by driver app. There is a storage limit for entire company based on your
              plan, after a limit is reached you may upgrade your limits at a cost.
            </p>
          </div>

          <div className='faq-card'>
            <p className='faq-head'>12 or 36 Month Licenses</p>
            <p className='faq-text'>
              For 12 or 36 month licenses, we require up-front payment or credit app for monthly payments for 36 months.
            </p>
          </div>

          <div className='faq-card'>
            <p className='faq-head'>4G/5G Data Use</p>
            <p className='faq-text'>
              Truckin Digital Manager App and Driver Mobile App utilize 4G/5G Data and Truckin Digital is not
              responsible for data usage cost.
            </p>
          </div>

          <div className='faq-card'>
            <p className='faq-head'>Document Manager</p>
            <p className='faq-text'>
              Receive Scanned Documents in real-time to your document manager dashboard. Scanned documents can be
              uploaded by web, manager app or by driver app. There is a storage limit for entire company based on your
              plan, after a limit is reached you may upgrade your limits at a cost.
            </p>
          </div>

          <div className='faq-card'>
            <p className='faq-head'>Can you import my data from my previous system?</p>
            <p className='faq-text'>
              We do not import your old data or create any driver, truck, trailer, customer profiles on your behalf.
            </p>
          </div>
        </div>
      </div>
      {/* <div className='divider' /> */}
      {/* <div className='mt-5 mb-5'> */}
      {/*  <div className='text-center'> */}
      {/*    <Typography variant='h4' style={{ color: palette.gray900 }}> */}
      {/*      Addons */}
      {/*    </Typography> */}
      {/*  </div> */}
      {/*  <div className='d-flex justify-content-center'> */}
      {/*    <div className='ps-4 pe-4 mt-4 w-100' style={{ maxWidth: '2000px' }}> */}
      {/*      <STabs value={tab} onChange={(e, value) => setTab(value)} variant='scrollable' scrollButtons={false}> */}
      {/*        {categories.map((item) => ( */}
      {/*          <Tab */}
      {/*            key={item} */}
      {/*            value={item} */}
      {/*            disableRipple */}
      {/*            label={ */}
      {/*              <span style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}> */}
      {/*                {item}{' '} */}
      {/*                <Chip */}
      {/*                  label={ */}
      {/*                    item === 'Included in Trial' */}
      {/*                      ? addons.filter((i) => Object.values(freeTrialAddons).includes(i.plan_id))?.length || 0 */}
      {/*                      : addons?.filter((i) => i.category === item)?.length || 0 */}
      {/*                  } */}
      {/*                  style={{ borderRadius: '12px' }} */}
      {/*                /> */}
      {/*              </span> */}
      {/*            } */}
      {/*          /> */}
      {/*        ))} */}
      {/*      </STabs> */}
      {/*      <div className='d-flex flex-column align-items-center'> */}
      {/*        {(tab === 'Included in Trial' */}
      {/*          ? addons.filter((i) => Object.values(freeTrialAddons).includes(i.plan_id)) */}
      {/*          : addons.filter((i) => i.category === tab) */}
      {/*        )?.map((item) => ( */}
      {/*          <div key={item?.id} className={styles.item_wrapper} style={{ maxWidth: '92%', width: '1100px' }}> */}
      {/*            <div className={styles.image_wrapper}> */}
      {/*              <figure className={styles.figure}> */}
      {/*                <img className={styles.image} src={item?.image} alt='Img' /> */}
      {/*              </figure> */}
      {/*            </div> */}
      {/*            <div className={styles.info_wrapper}> */}
      {/*              <div className={styles.center}> */}
      {/*                <div className={styles.title_subtitle}> */}
      {/*                  <Typography variant='h5' style={{ color: palette.gray900 }}> */}
      {/*                    {item?.name} */}
      {/*                  </Typography> */}
      {/*                  <Typography variant='b2'>{item?.description}</Typography> */}
      {/*                </div> */}
      {/*                <div className={styles.plans}> */}
      {/*                  {item?.plans?.map((plan) => ( */}
      {/*                    <Typography */}
      {/*                      variant='s2' */}
      {/*                      style={{ color: palette.gray700, whiteSpace: 'nowrap' }} */}
      {/*                      key={plan.id} */}
      {/*                    > */}
      {/*                      {plan?.plan_title}, */}
      {/*                      <Typography */}
      {/*                        variant='s2' */}
      {/*                        style={{ */}
      {/*                          color: palette.gray500, */}
      {/*                          marginLeft: 5, */}
      {/*                          whiteSpace: 'nowrap', */}
      {/*                        }} */}
      {/*                      > */}
      {/*                        {Number(plan?.monthly_amount) === 0 */}
      {/*                          ? `Included with plan` */}
      {/*                          : `$${formatNumber(Number(plan?.monthly_amount))} ${label}`}{' '} */}
      {/*                        {!!plan?.setup_one_time_fee && */}
      {/*                        plan?.setup_one_time_fee !== '0.00' && */}
      {/*                        plan?.setup_one_time_fee !== '0' */}
      {/*                          ? `+ One Time Setup Fee $${formatNumber(plan?.setup_one_time_fee)}` */}
      {/*                          : ''} */}
      {/*                      </Typography> */}
      {/*                    </Typography> */}
      {/*                  ))} */}
      {/*                </div> */}
      {/*              </div> */}
      {/*              <div> */}
      {/*                {Object.values(freeTrialAddons).includes(item.plan_id) && ( */}
      {/*                  <Chip label='Included in free trial' labelColor={palette.green500} bgColor={palette.green0} /> */}
      {/*                )} */}
      {/*                {item.guide && ( */}
      {/*                  <CustomButton */}
      {/*                    type='secondary' */}
      {/*                    title='View Guide' */}
      {/*                    styleButton={{ padding: '6px 12px', marginTop: '8px' }} */}
      {/*                    styleTitle={{ fontSize: 14, whiteSpace: 'nowrap' }} */}
      {/*                    onClick={() => onViewGuide(item)} */}
      {/*                  /> */}
      {/*                )} */}
      {/*              </div> */}
      {/*            </div> */}
      {/*          </div> */}
      {/*        ))} */}
      {/*        <ViewPdf open={!!guide} title='Guide' pdfUrl={guide} onClose={() => setGuide(null)} /> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  );
};

export default SelectPlan;
