import jsPDF from 'jspdf';
import mailIcon from 'assets/icons/mail.png';
import globeIcon from 'assets/icons/globe.png';
import phoneIcon from 'assets/icons/phone.png';
import locationIcon from 'assets/icons/location.png';
import { formatNumber } from 'utils/constants';
import { currencies, getOverdueAmount, getTotalBalanceDue, splitArrayIntoRanges } from './CustomerStatement.data';

const createFooter = ({
  doc,
  accountingSettings,
  companyProfile,
  sortedTransactions,
  customer_company_name,
  page = 1,
  totalPages = 1,
  currency,
}) => {
  if (!accountingSettings || !companyProfile) {
    return null;
  }

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const { website, show_website, show_billing, receivable_email } = accountingSettings || {};

  const { company_name, address1, address2, city_id, state_id, country_id, zip, phone_number } = companyProfile || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  // background
  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 26, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5.5, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4, pageHeight - 20, pageWidth / 4, pageHeight - 10);
    doc.line(pageWidth / 2 + 1, pageHeight - 20, pageWidth / 2 + 1, pageHeight - 10);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 8, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 5, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3);
    doc.text('Address', 13, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 10, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);

    doc.setFontSize(8);
    doc.setTextColor('#171C26');
    doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 8, pageHeight - 13);
    doc.text(`${city_id?.name}, ${state_id?.name} ${zip}`, 8, pageHeight - 9, { maxWidth: 45 });

    if (show_website === 1) {
      doc.text(`${website}`, pageWidth / 4 + 5, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 4 + 5, pageHeight - 13);
    }
    if (show_billing) {
      doc.text(`${receivable_email}`, pageWidth / 2 + 6, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 2 + 6, pageHeight - 13);
    }
    doc.text(`${phone}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);

    // Invoice Terms
    if (accountingSettings?.invoice_terms) {
      doc.setFont('Inter', 'normal', 700);
      doc.text('Invoice Terms', 8, pageHeight - 74);

      doc.setFont('Inter', 'normal', 400);
      doc.text(`${accountingSettings.invoice_terms}`, 8, pageHeight - 69, {
        maxWidth: pageWidth - 16,
      });
    }

    // Summary section
    doc.setDrawColor(0, 0, 0);
    doc.setLineDash([3, 3], 0);
    doc.line(8, pageHeight - 61, pageWidth - 8, pageHeight - 61);
    doc.line(8, pageHeight - 61.1, pageWidth - 8, pageHeight - 61.1);

    // Right side
    doc.setTextColor('#000000');
    doc.setFont('Inter', 'normal', 700);
    doc.text('Customer', 125, pageHeight - 55);
    doc.setFont('Inter', 'normal', 400);
    doc.text(customer_company_name, 160, pageHeight - 55);

    doc.setDrawColor(188, 194, 206);
    doc.setLineDash(0);
    doc.line(125, pageHeight - 53, pageWidth - 8, pageHeight - 53);

    doc.setFont('Inter', 'normal', 700);
    doc.text('Overdue', 125, pageHeight - 48);
    doc.text('Current', 160, pageHeight - 48);
    doc.text(`Total ${currencies[currency]} Due`, 185, pageHeight - 48);

    doc.setFont('Inter', 'normal', 400);
    doc.text(`${currency}${formatNumber(getOverdueAmount(sortedTransactions))}`, 125, pageHeight - 44);
    doc.text(
      `${currency}${formatNumber(getTotalBalanceDue(sortedTransactions) - getOverdueAmount(sortedTransactions))}`,
      160,
      pageHeight - 44
    );
    doc.text(`${currency}${formatNumber(getTotalBalanceDue(sortedTransactions))}`, 185, pageHeight - 44);

    doc.line(125, pageHeight - 40, pageWidth - 8, pageHeight - 40);
    doc.setDrawColor(0, 0, 0);
    doc.text('Amount Enclosed', 125, pageHeight - 35);

    doc.setFont('Inter', 'normal', 400);
    doc.text('(if by Check)', 125, pageHeight - 31);
    doc.text('Enter the amount you are paying above', 155, pageHeight - 30);
    doc.line(154, pageHeight - 33.5, pageWidth - 8, pageHeight - 33.5);

    // Left side
    doc.setFont('Inter', 'normal', 700);
    doc.text('Payable to:', 8, pageHeight - 55);

    doc.setFont('Inter', 'normal', 400);
    doc.text(`${company_name}`, 28, pageHeight - 55);
    doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 28, pageHeight - 51);
    doc.text(`${city_id?.name}, ${state_id?.name}, ${zip}`, 28, pageHeight - 47);
    doc.text(country_id.name, 28, pageHeight - 43);
  }
};

export const generatePDF = (data) => {
  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  const { selectedFilters, companyProfile, accountingSettings, sortedTransactions, currency, formatDate } = data;

  const { customer, start_date, end_date, filter } = selectedFilters;

  const { address1, address2, city_id, state_id, country_id, zip, company_logo, company_name } = companyProfile || {};

  const {
    company_name: customer_company_name,
    address1: customer_address1,
    address2: customer_address2,
    city: customer_city,
    state: customer_state,
    country: customer_country,
    zipcode: customer_zip,
  } = customer || {};

  doc.addImage(`${company_logo}?cache=${Math.random()}`, 'JPEG', 8, 8, 60, 12, 'Logo', 'FAST');

  doc.setFontSize(22);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Statement', 8, 34);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${customer_company_name}`, 16, 42);
  doc.text(`${customer_address1}${customer_address2 ? `, ${customer_address2}` : ''}`, 16, 46);
  doc.text(`${customer_city?.name || ''}, ${customer_state?.name || ''} ${customer_zip || ''}`, 16, 50);
  doc.text(`${customer_country?.name || ''}`, 16, 54);

  doc.setFont('Inter', 'normal', 700);
  doc.text('Activity Type', pageWidth - 115, 34, { align: 'center' });
  doc.text('From Date', pageWidth - 90, 34);
  doc.text('To Date', pageWidth - 90, 45);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${filter === 'outstanding' ? 'Outstanding' : 'All'}`, pageWidth - 115, 38, { align: 'center' });
  doc.text(`${selectedFilters.start_date ? formatDate(start_date) : '-'}`, pageWidth - 90, 38);
  doc.text(`${selectedFilters.start_date ? formatDate(end_date) : 'All Time'}`, pageWidth - 90, 49);

  doc.text(`${company_name}`, pageWidth - 50, 34);
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, pageWidth - 50, 38);
  doc.text(`${city_id?.name}, ${state_id?.short_name} ${zip}`, pageWidth - 50, 42, { maxWidth: 40 });

  const addressLineLength = Number(city_id?.name?.length) + Number(state_id?.short_name?.length) + Number(zip?.length);

  doc.text(country_id.name, pageWidth - 50, addressLineLength > 24 ? 49 : 46);

  const firstPageTransactions = sortedTransactions.slice(0, 35);
  const restTransactions = sortedTransactions.slice(35);

  const restArray = splitArrayIntoRanges(restTransactions, 45);
  const transactionsByPages = [firstPageTransactions, ...restArray];

  doc.setFont('Inter', 'normal', 700);

  transactionsByPages.forEach((onePageTransactions, i) => {
    const page = doc.internal.getCurrentPageInfo().pageNumber; // Current Page

    doc.setFont('Inter', 'normal', 700);
    doc.text('Invoice Date', 8, page === 1 ? 70 : 16);
    doc.text('Payment Date', 31, page === 1 ? 70 : 16);
    doc.text('Invoice #', 57, page === 1 ? 70 : 16);
    doc.text('Reference', 76, page === 1 ? 70 : 16);
    doc.text('Aging', 105, page === 1 ? 70 : 16);
    doc.text('Due Date', 120, page === 1 ? 70 : 16);
    doc.text('Invoice Amount', 140, page === 1 ? 70 : 16);
    doc.text('Payments', 168, page === 1 ? 70 : 16);
    doc.text('Open Balance', 190, page === 1 ? 70 : 16);
    doc.line(8, page === 1 ? 72.5 : 18.5, pageWidth - 8, page === 1 ? 72.5 : 18.5);

    const transactionsStart = page === 1 ? 72 : 18;
    let transactionsEnd;

    onePageTransactions.forEach((transaction, index, array) => {
      doc.setFont('Inter', 'normal', 400);

      // invoice date
      doc.text(
        transaction.customer_billed_date ? formatDate(transaction.customer_billed_date) : '-',
        8,
        transactionsStart + (index + 1) * 5
      );

      // payment date
      doc.text(
        transaction.paymentDate ? formatDate(transaction.paymentDate) : '-',
        31,
        transactionsStart + (index + 1) * 5
      );

      // invoice #
      doc.text(transaction.invoice_id, 57, transactionsStart + (index + 1) * 5);

      // reference
      doc.text(
        transaction.billing?.reference_id || transaction.reference_id || '-',
        76,
        transactionsStart + (index + 1) * 5
      );

      // age
      doc.text(`${transaction.age || '-'}`, 105, transactionsStart + (index + 1) * 5);

      // due date
      doc.text(formatDate(transaction.due_date), 120, transactionsStart + (index + 1) * 5);

      // invoice amount
      doc.text(formatNumber(transaction.amount), 142, transactionsStart + (index + 1) * 5);

      // payments
      doc.text(formatNumber(transaction.totalPaymentsAmount), 168, transactionsStart + (index + 1) * 5);

      // balance
      doc.text(formatNumber(transaction.balance), 190, transactionsStart + (index + 1) * 5);

      if (index !== array.length - 1) {
        doc.setDrawColor(188, 194, 206);
        doc.line(
          8,
          transactionsStart + 1.5 + (index + 1) * 5,
          pageWidth - 8,
          transactionsStart + 1.5 + (index + 1) * 5
        );
      }

      doc.setDrawColor(0, 0, 0);
      if (index === array.length - 1 && i === transactionsByPages.length - 1) {
        transactionsEnd = transactionsStart + (index + 1) * 5 + 5;
        doc.line(188, transactionsEnd - 2, pageWidth - 8, transactionsEnd - 2);
        doc.setFont('Inter', 'normal', 700);
        doc.text('BALANCE DUE', 160, transactionsEnd + 2);
        doc.text(`${currency}${formatNumber(getTotalBalanceDue(sortedTransactions))}`, 188, transactionsEnd + 2);
      }

      if (index === array.length - 1) {
        if (i === 0 && index > 22) {
          doc.addPage();
        }

        if (i !== 0 && index > 34) {
          doc.addPage();
        }
      }
    });
  });

  const totalPages = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter({
      doc,
      accountingSettings,
      companyProfile,
      sortedTransactions,
      customer_company_name,
      page,
      totalPages,
      currency,
    });
  }

  const url = doc.output('datauristring', { filename: 'Customer Statement' });
  const blob = doc.output('blob', { filename: 'Customer Statement' });

  return { blob, url };
};
