import React from 'react';
import { useNavigate } from 'react-router-dom';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import { Typography } from 'components/Typography';
import { ADDON_PLAN_ID, palette, RESTRICTION_TYPE } from 'utils/constants';
import { SGradientBox, SWrapper } from './RestrictedPage.styles';

const addonInfo = {
  [ADDON_PLAN_ID.ACCOUNTING]: { title: 'Accounting Full Suite', addonId: 21, planId: 57 },
  [ADDON_PLAN_ID.PAYROLL]: { title: 'Payroll Full Suite', addonId: 22, planId: 58 },
  [ADDON_PLAN_ID.IFTA]: { title: 'IFTA Full Suite', addonId: 23, planId: 59 },
  [ADDON_PLAN_ID.SAFETY]: { title: 'Safety Full Suite', addonId: 24, planId: 60 },
  [ADDON_PLAN_ID.REPAIR_SHOP]: { title: 'Repair Shop', addonId: 27, planId: 63 },
  [ADDON_PLAN_ID.BROKERAGE]: { title: 'Brokerage', addonId: 26, planId: 62 },
};

const settingsInfo = {
  brokerage: { title: 'your broker addon', tabId: 15, subTabName: 'Brokerage' },
};

const RestrictedPage = ({ restrictionType = RESTRICTION_TYPE.DEPARTMENT_PERMISSION, planId, settingsTab, loading }) => {
  const navigate = useNavigate();

  const onPurchaseClick = () => {
    navigate(`/billing?openAddon=true&addonId=${addonInfo[planId]?.addonId}&planId=${addonInfo[planId]?.planId}`);
  };

  const onSettingsClick = () => {
    navigate('/company-settings', {
      state: { tabId: settingsInfo[settingsTab].tabId, subTabName: settingsInfo[settingsTab].subTabName },
    });
  };

  if (loading) {
    return null;
  }

  return (
    <SWrapper>
      <SGradientBox />
      <div className='restriction-content-wrapper'>
        <img src={truckinLogo} alt='truckin' width={80} height={80} />
        {restrictionType === RESTRICTION_TYPE.ADDON && (
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            License required for {addonInfo[planId]?.title || 'this function'}, please{' '}
            <span className='purchase-addon' onClick={onPurchaseClick}>
              purchase here
            </span>
          </Typography>
        )}
        {restrictionType === RESTRICTION_TYPE.DEPARTMENT_PERMISSION && (
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            You do not have permission to access this page! Please contact your company manager.
          </Typography>
        )}
        {restrictionType === RESTRICTION_TYPE.SETTINGS_REQUIRED && (
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            Please setup and configure {settingsInfo[settingsTab].title}!{' '}
            <span className='purchase-addon' onClick={onSettingsClick}>
              Configure
            </span>
          </Typography>
        )}
      </div>
    </SWrapper>
  );
};

export default RestrictedPage;
