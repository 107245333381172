import React from 'react';
import { useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { getPaidInvoicesChartData, paidInvoicesOptions, paidInvoicesPlugins } from './Charts.data';

const PaidInvoicesChart = ({ invoicesData }) => {
  const { currency } = useSelector((state) => state.root);

  if (invoicesData?.total?.amount === 0) {
    return (
      <div className='d-flex align-items-center justify-content-center p4 h-100' style={{ flex: 1 }}>
        <Typography variant='h2' style={{ color: palette.gray500 }}>
          No stats to show
        </Typography>
      </div>
    );
  }

  return (
    <Doughnut
      data={getPaidInvoicesChartData(invoicesData)}
      options={paidInvoicesOptions}
      plugins={paidInvoicesPlugins(currency)}
    />
  );
};

export default PaidInvoicesChart;
