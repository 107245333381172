import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { numberWithCommas, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ChartOption } from './ChartOption';
import { calcPercentage } from '../helpers';
import { SChartSection, SChartWrapper } from '../TopSection.styles';

const ChartSection = ({ topCompany }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const { amounts_by_months: data, prev_month, this_month } = topCompany;
  const percent = useMemo(() => {
    return calcPercentage({ prev_month, this_month });
  }, [data]);

  return (
    <div className='sub-section'>
      <SChartSection>
        <div className='info'>
          <Typography variant='button2' style={{ color: use(palette.gray900, palette.gray200) }}>
            {prev_month?.amount !== undefined ? `${currency}${numberWithCommas(prev_month.amount.toFixed(2))}` : '-'}
          </Typography>
          <Typography variant='c2' style={{ color: use(palette.gray700, palette.gray200) }}>
            Last Month
          </Typography>
        </div>
        <div className='info'>
          <div className='row-flex'>
            <Typography variant='button2' style={{ color: use(palette.gray900, palette.gray200) }}>
              {this_month?.amount !== undefined ? `${currency}${numberWithCommas(this_month.amount.toFixed(2))}` : '-'}
            </Typography>
            {this_month &&
              prev_month &&
              (this_month?.amount === prev_month?.amount ? (
                <Chip
                  size='small'
                  bgColor={palette.gray0}
                  labelColor={palette.gray500}
                  label='+0%'
                  fontSize='11px'
                  fontWeight={500}
                />
              ) : percent[0] === '+' ? (
                <Chip
                  size='small'
                  bgColor={palette.red0}
                  labelColor={palette.red500}
                  label={percent}
                  fontSize='11px'
                  fontWeight={500}
                />
              ) : (
                <Chip
                  size='small'
                  bgColor={palette.green0}
                  labelColor={palette.green500}
                  label={percent}
                  fontSize='11px'
                  fontWeight={500}
                />
              ))}
          </div>
          <Typography variant='c2' style={{ color: use(palette.gray700, palette.gray200) }}>
            This Month
          </Typography>
        </div>
      </SChartSection>
      <SChartWrapper>
        <ChartOption monthsData={data} />
      </SChartWrapper>
    </div>
  );
};

export default ChartSection;
