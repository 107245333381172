import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { planMapper } from './PlanCard.data';
import { SWrapper } from './PlanCard.styles';

const PlanCard = ({ plan, isActive, selectPlan }) => {
  return (
    <SWrapper className={isActive ? 'active-option' : ''}>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        {planMapper[plan.duration].title}
      </Typography>
      {planMapper[plan.duration].commitmentLabel}
      <div className='price-wrapper'>
        <Typography variant='big' style={{ color: isActive ? palette.indigo500 : palette.gray900 }}>
          ${plan.amount / planMapper[plan.duration].monthsCount}
        </Typography>
        <Typography variant='s2'>USD</Typography>
      </div>
      <Typography variant='c1'>Per User / Monthly</Typography>
      <button
        className='price-buttons'
        onClick={() => selectPlan(plan)}
        style={{ background: isActive ? palette.indigo300 : '', color: isActive ? palette.white : '' }}
        disabled={isActive}
      >
        {isActive ? 'Selected' : 'Select'}
      </button>
      <Typography variant='c1' style={{ color: palette.gray700 }}>
        {planMapper[plan.duration].billTitle}
      </Typography>
    </SWrapper>
  );
};

export default PlanCard;
