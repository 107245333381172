import React, { useState } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { SPageWrapper } from './Vendors.styles';

const Vendors = () => {
  const [activeVendor, setActiveVendor] = useState(null);
  const [vendorToEdit, setVendorToEdit] = useState(null);
  const [refreshIndex, setRefreshIndex] = useState(Date.now());

  const onVendorClick = (vendor) => {
    setActiveVendor(vendor);
    window.scrollTo(0, 0, { behavior: 'smooth' });
  };

  return (
    <SPageWrapper>
      <LeftSection
        activeVendor={activeVendor}
        onVendorClick={onVendorClick}
        vendorToEdit={vendorToEdit}
        setVendorToEdit={setVendorToEdit}
        refreshIndex={refreshIndex}
      />
      <RightSection
        vendor={activeVendor}
        onEdit={() => setVendorToEdit(activeVendor)}
        refreshList={() => setRefreshIndex(Date.now())}
      />
    </SPageWrapper>
  );
};

export default Vendors;
