import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette, TRANSMISSION_TYPE } from 'utils/constants';

const ConfirmAssign = ({ open, onClose, driver, vehicle, onConfirm }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Warning!'
      $maxWidth='520px'
      $minWidth='520px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Proceed Regardless & Assign',
          onClick: onConfirm,
        },
      ]}
    >
      <div className='d-flex flex-column gap-2 mt-2 mb-3'>
        <Typography variant='s1' style={{ color: palette.red500 }}>
          WARNING!
        </Typography>
        <Typography variant='s2'>
          Driver cannot drive this vehicle {TRANSMISSION_TYPE[vehicle.transmission_type]}{' '}
          {vehicle.transmission_type === 2 && !!vehicle.transmission_speed && `${vehicle.transmission_speed} Speed`}{' '}
          transmission type. Driver can only drive {TRANSMISSION_TYPE[driver.transmission_type]}
          {driver.transmission_type === 2 && !!driver.transmission_speed && ` ${driver.transmission_speed} Speed`}.
        </Typography>
      </div>
    </Modal>
  );
};

export default ConfirmAssign;
