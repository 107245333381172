import React, { useState } from 'react';
import moment from 'moment-timezone';
import AddEditModalDetails from 'components/AddEditModalStops';
import OverViewStop from 'components/TableShipments/detailsRow/steps/OverView/OverViewStops';
import { SkeletonStops } from 'components/TableShipments/detailsRow/steps/OverView/OverView';
import SettingsModal from 'components/TableShipments/detailsRow/steps/OverView/SettingsModal';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { UpdateStopPoint } from 'Api/StopPoint';
import { addShipmentStop, deleteShipmentStop, updateCargo, UpdateStopPointStop } from 'Api/Shipment';
import {
  createCargoConverter,
  createCargoFormDataConverter,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { SWrapper } from './Stops.styles';

const Stops = ({ shipment, legs, setLegs, loading, getShipment, getStops }) => {
  const showToaster = useShowToaster();
  const [isOpen, setIsOpen] = useState(false);
  const [selectModalType, setSelectModalType] = useState({});
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const legsStopsCount = [];

  const updateStop = (params, stopId) => {
    UpdateStopPointStop({ params, id: stopId })
      .then(() => {
        getStops();
        getShipment();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const onAddEditModalDetails = (values, type, item) => {
    delete values?.id;
    if (type === 'edit') {
      const formData = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (key === 'to' || (val !== '' && !['arrival_time', 'departure_time'].includes(key))) {
          if (key === 'dimensions' && val?.l && val?.h && val?.w) {
            formData.append(`dimensions[l]`, val?.l || '');
            formData.append(`dimensions[h]`, val?.h || '');
            formData.append(`dimensions[w]`, val?.w || '');
          } else if (key === 'arrival_date') {
            if (val) {
              formData.append(
                'arrival_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.arrival_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'departure_date') {
            if (val) {
              formData.append(
                'departure_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.departure_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'bill_type') {
            formData.append('bill_type', values.bill_type ? '1' : '2');
          } else if (key === 'scheduled_date') {
            formData.append('scheduled_date', moment(val).format('YYYY-MM-DD'));
          } else if (key === 'scheduled_date_to') {
            formData.append('scheduled_date_to', moment(val || values?.scheduled_date).format('YYYY-MM-DD'));
          } else if (key === 'from') {
            formData.append('from', moment(values?.from).format('HH:mm'));
          } else if (key === 'to') {
            formData.append('to', moment(val || values?.from).format('HH:mm'));
          } else if (key === 'reported_quantity_type' && !!val) {
            formData.append(
              'reported_quantity_type',
              values?.reported_quantity_type?.id || values?.reported_quantity_type
            );
          } else if (key === 'reported_weight_type' && !!val) {
            formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
          } else if (key === 'connected_cargos' && val?.length) {
            val.forEach((cargo, index) => {
              formData.append(`connected_cargos[${index}]`, cargo.id);
            });
          } else if (key === 'cargos') {
            // Do nothing
          } else {
            let value = val;
            if (typeof val === 'boolean') {
              if (key === 'bill_type') {
                return;
              }
              value = val ? 1 : 0;
            }
            if (key === 'dimensions') {
              return;
            }
            formData.append(key, value);
          }
        }
      });
      updateStop(formData, item?.id);

      if (Number(values.stop_point_type) === 1 && values.cargos?.length === 1) {
        const body = createCargoConverter(values.cargos[0]);
        updateCargo(values.cargos[0].id, body);
      }
    } else {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'id') {
          return;
        }
        let value = values[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        if (key === 'bill_type') {
          formData.append('bill_type', !values.bill_type ? '1' : '2');
        }

        if (key === 'dimensions' && typeof value === 'object') {
          Object.keys(value).forEach((dimensionKey) => {
            formData.append(`dimensions[${dimensionKey}]`, value[dimensionKey] || '');
          });
        } else if (key === 'cargos') {
          const cargos = values.cargos?.length ? values.cargos.filter((i) => !!i?.commodity) : [];
          createCargoFormDataConverter(cargos, formData);
        } else if (key === 'reported_quantity_type' && values?.reported_quantity_type) {
          formData.append(
            'reported_quantity_type',
            values?.reported_quantity_type?.id || values?.reported_quantity_type
          );
        } else if (key === 'reported_weight_type' && values?.reported_weight_type) {
          formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
        } else if (key === 'connected_cargos' && value.length) {
          value.forEach((cargo, index) => {
            formData.append(`connected_cargos[${index}]`, cargo.id);
          });
        } else if (key === 'scheduled_date') {
          formData.append('scheduled_date', moment(values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'scheduled_date_to') {
          formData.append('scheduled_date_to', moment(value || values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'from') {
          formData.append('from', moment(values?.from).format('HH:mm'));
        } else if (key === 'to') {
          formData.append('to', moment(value || values?.from).format('HH:mm'));
        } else {
          formData.append(key, value);
        }
      });
      formData.append('leg', selectModalType.legIndex);
      formData.append('order_in_leg', selectModalType.order_in_leg);
      formData.append('shipment_id', shipment.shipment_id);

      addShipmentStop(formData)
        .then(() => {
          getShipment();
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  function onDeleteStop(stopId) {
    deleteShipmentStop(stopId)
      .then(() => {
        getShipment();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  }

  function onClickItemSettingsIcon(stop) {
    setIsOpenSettingsModal(stop?.stop_point?.id);
  }

  function onUpdateSettings(id, validateForm, resetForm, submitForm, values) {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        const backData = {};
        Object.keys(values).forEach((key) => {
          const value = values[key];
          if (typeof value === 'boolean') {
            backData[key] = value ? 1 : 0;
          } else {
            backData[key] = value;
          }
        });
        UpdateStopPoint(id, backData).then(() => setIsOpenSettingsModal(''));
      }
    });
  }

  return (
    <SWrapper>
      <div className='w-100'>
        {loading ? (
          <SkeletonStops />
        ) : (
          <>
            {legs.map((leg, index) => {
              legsStopsCount.push(leg.stops.length);
              return (
                <div key={leg.id || index}>
                  <OverViewStop
                    leg={leg}
                    legs={legs}
                    setLegs={setLegs}
                    indexPrefix={index}
                    infoHeader={shipment}
                    legsStopsCount={legsStopsCount}
                    setIsOpen={setIsOpen}
                    onSelectPlus={setSelectModalType}
                    deleteStop={onDeleteStop}
                    onClickSettingsIcon={onClickItemSettingsIcon}
                    onUpdateSuccess={getShipment}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
      {!!isOpen?.open && (
        <AddEditModalDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={selectModalType}
          setType={setSelectModalType}
          isShipmentTable={false}
          onAdd={onAddEditModalDetails}
          onUpdateSuccess={() => {
            getShipment();
            getStops();
          }}
          shipmentId={shipment?.shipment_id}
          legs={legs}
        />
      )}
      <SettingsModal isOpen={isOpenSettingsModal} setIsOpen={setIsOpenSettingsModal} onUpdate={onUpdateSettings} />
      {/* <Accordion> */}
      {/*  <AccordionSummary */}
      {/*    expandIcon={<ArrowUp className='accordion-arrow' />} */}
      {/*    aria-controls='panel1a-content' */}
      {/*    id='panel1a-header' */}
      {/*  /> */}
      {/*  <AccordionDetails> */}
      {/*    <div className='w-100'> */}
      {/*      {loading ? ( */}
      {/*        <SkeletonStops /> */}
      {/*      ) : ( */}
      {/*        <> */}
      {/*          {legs.map((leg, index) => { */}
      {/*            legsStopsCount.push(leg.stops.length); */}
      {/*            return ( */}
      {/*              <div key={leg.id || index}> */}
      {/*                <OverViewStop */}
      {/*                  leg={leg} */}
      {/*                  legs={legs} */}
      {/*                  setLegs={setLegs} */}
      {/*                  indexPrefix={index} */}
      {/*                  infoHeader={shipment} */}
      {/*                  legsStopsCount={legsStopsCount} */}
      {/*                  readOnly */}
      {/*                /> */}
      {/*              </div> */}
      {/*            ); */}
      {/*          })} */}
      {/*        </> */}
      {/*      )} */}
      {/*    </div> */}
      {/*  </AccordionDetails> */}
      {/* </Accordion> */}
    </SWrapper>
  );
};

export default Stops;
