import React from 'react';
import { Icons } from 'assets/icons';
import './msgStyle.css';

const UnhookedTrailer = ({ handleClose }) => {
  return (
    <div className='d-flex unhooked-success'>
      <div className=''>
        <div className='d-flex main-wrapper-eq-Unhooked-success align-item-center'>
          <span className='me-2'>
            <img src={Icons.success} alt='success' />
          </span>
          <div>
            <p className='eq-reserved ms-1 mb-0 '>Trailer Unhooked Successfully</p>
          </div>
        </div>
      </div>
      <div>
        <button className='eq-reserved-okay-msg ms-2' onClick={handleClose}>
          Okay
        </button>
      </div>
    </div>
  );
};

export default UnhookedTrailer;
