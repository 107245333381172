import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { Typography } from 'components/Typography';
import { HEADER_STATUSES } from '../../../constats';
import styles from '../recuring.module.css';

const OverLayWrapper = ({ onChange, onChaneShow, show, data, onDelete, deleted, defaultSelected }) => {
  const { use } = useTheme();
  const target = useRef(null);
  const [activeStatus, setActiveStatus] = useState(HEADER_STATUSES[0]);

  useEffect(() => {
    !!defaultSelected && setActiveStatus(defaultSelected);
  }, []);

  const headerStyle = useMemo(() => {
    return {
      overLay: {
        width: 120,
        borderRadius: 6,
        padding: '4px',
        position: 'absolute',
        backgroundColor: use(palette.white, palette.dark800),
        boxShadow:
          '0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2), 0 5px 15px rgba(0, 0, 0, 0.08)',
      },
      triggerWrapper: {
        width: 'fit-content',
        cursor: 'pointer',
        marginBottom: 18,
      },
      trigger: {
        backgroundColor: activeStatus?.background,
        color: activeStatus?.color,
        padding: '2px 6px',
        marginRight: 8,
        borderRadius: 6,
      },
    };
  }, [use, palette, activeStatus]);

  return (
    <div className={styles.status_wrapper}>
      <div ref={target} onClick={() => onChaneShow(!show)} style={{ ...headerStyle.triggerWrapper }}>
        <Typography variant='s2' style={headerStyle.trigger}>
          {activeStatus?.title}
        </Typography>
        <ArrowDown />
      </div>
      <div className={styles.line} />

      <Overlay placement='bottom-start' rootClose show={show} target={target.current} onHide={() => onChaneShow(false)}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div {...props} style={{ ...headerStyle.overLay, ...props.style }}>
            <div>
              {data.map((status) => {
                const active = activeStatus?.id === status?.id;
                return (
                  <div
                    onClick={() => {
                      !!onChange && onChange(status);
                      setActiveStatus(status);
                    }}
                    key={status.id}
                    className={styles.header_status_item}
                    style={{ backgroundColor: active ? palette.indigo500 : use(palette.white, palette.dark800) }}
                  >
                    <Typography
                      variant='s2'
                      style={{ color: active ? palette.white : use(palette.gray900, palette.gray50) }}
                    >
                      {status.title}
                    </Typography>
                    {active && <TickIcon fill={palette.white} />}
                  </div>
                );
              })}
              {!!deleted && (
                <div className={styles.header_status_item} onClick={onDelete}>
                  <Typography variant='s2' style={{ color: palette.red400 }}>
                    Delete
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default OverLayWrapper;
