import React from 'react';
import pending from 'assets/icons/drivers/pending.svg';
import approved from 'assets/icons/drivers/approved.svg';
import rejected from 'assets/icons/drivers/rejected.svg';
import excused from 'assets/icons/drivers/excused.svg';
import unexcused from 'assets/icons/drivers/unexcused.svg';
import vacation from 'assets/icons/drivers/vacation.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import './DriverAbsenceAndRequest.css';

const DriverAbsenceAndRequest = ({ data, isRequest }) => {
  const { use } = useTheme();

  const activity = [
    {
      icon: isRequest ? pending : excused,
      detailName: isRequest ? 'Pending' : 'Excused',
      detail: isRequest ? data?.other_data?.pending || 0 : data?.absence_allowance?.excused_absences_taken || 0,
      subDetail: isRequest ? null : data?.absence_allowance?.excused_absences || 0,
    },
    {
      icon: isRequest ? approved : unexcused,
      detailName: isRequest ? 'Approved' : 'Unexcused',
      detail: isRequest ? data?.other_data?.approved || 0 : data?.absence_allowance?.unexcused_absences_taken || 0,
      subDetail: isRequest ? null : data?.absence_allowance?.unexcused_absences || 0,
    },
    {
      icon: isRequest ? rejected : vacation,
      detailName: isRequest ? 'Rejected' : 'Paid Time Off (PTO)',
      detail: isRequest ? data?.other_data?.rejected || 0 : data?.absence_allowance?.vacations_taken || 0,
      subDetail: isRequest ? null : data?.absence_allowance?.vacations_allowed || 0,
    },
  ];

  return (
    <div
      className='driver-absence driver-style-wrap absence-driver'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      {activity.map((item) => {
        return (
          <div
            style={{ borderColor: use(palette.gray50, palette.darkborder), flex: 1 }}
            className={
              item.detailName === 'Paid Time Off (PTO)' ? 'driver-absence-wrap last-absence' : 'driver-absence-wrap '
            }
            key={item.id}
          >
            <img src={item.icon} alt='' className='absence-image' />
            <div className='absence-details'>
              <p className='detail-name' style={{ color: use(palette.gray900, palette.gray400) }}>
                {item.detailName}
              </p>
              <p className='detail' style={{ color: use(palette.gray900, palette.gray50) }}>
                {item.detail}
                <span className='sub-details' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {typeof item?.subDetail === 'number' ? ` of ${item?.subDetail}` : ' '}
                </span>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DriverAbsenceAndRequest;
