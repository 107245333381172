import React, { useEffect } from 'react';
import moment from 'moment';

import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { GetFMCSACompliance, CreateFMCSACompliance } from 'Api/CompanySettings';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { validationSchema } from './validationSchema';
import { initialValues, updateComplianceConverter } from './FmcsaCompliance.data';
import { SWrapper, SHeader, SActions } from './FmcsaCompliance.styles';

const FmcsaCompliance = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();

  const handleFormSubmit = async (values) => {
    try {
      const updateData = updateComplianceConverter(values);
      const { data } = await CreateFMCSACompliance(updateData);
      updateInitialValues(data);
      showToaster({ type: 'success', message: `Changes have been successfully updated!` });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
  };

  const { values, handleSubmit, handleChange, setValues, touchedErrors, handleBlur } = useForm({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const updateInitialValues = (data) => {
    const {
      mcs_biennial_update_date,
      mcs_biennial_active,
      ucr_annual_due_date,
      ucr_annual_active,
      urs_biennial_renewal_date,
      urs_biennial_active,
      clearing_house_renewal_date,
      clearing_house_active,
      scac_code_renewal_date,
      scac_code_active,
      cvor_certificate_renewal_date,
      cvor_certificate_active,
    } = data || {};
    setValues({
      urs_biennial_renewal_date: urs_biennial_renewal_date
        ? moment(urs_biennial_renewal_date).format('MM/DD/YYYY')
        : null,
      urs_biennial_active: !!urs_biennial_active,
      mcs_biennial_update_date: mcs_biennial_update_date ? moment(mcs_biennial_update_date).format('MM/DD/YYYY') : null,
      mcs_biennial_active: !!mcs_biennial_active,
      ucr_annual_due_date: ucr_annual_due_date ? moment(ucr_annual_due_date).format('MM/DD/YYYY') : null,
      ucr_annual_active: !!ucr_annual_active,
      clearing_house_renewal_date: clearing_house_renewal_date
        ? moment(clearing_house_renewal_date).format('MM/DD/YYYY')
        : null,
      clearing_house_active: !!clearing_house_active,
      scac_code_renewal_date: scac_code_renewal_date ? moment(scac_code_renewal_date).format('MM/DD/YYYY') : null,
      scac_code_active: !!scac_code_active,
      cvor_certificate_renewal_date: cvor_certificate_renewal_date
        ? moment(cvor_certificate_renewal_date).format('MM/DD/YYYY')
        : null,
      cvor_certificate_active: !!cvor_certificate_active,
    });
  };

  const getFMCSACompliance = async () => {
    try {
      const { data } = await GetFMCSACompliance();
      updateInitialValues(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getFMCSACompliance();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <form>
      <div>
        <SHeader>
          <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
            FMCSA Compliance
          </Typography>
          <SActions>
            {isConfiguringStep && (
              <>
                <CustomButton
                  onClick={onCancelPrevPage}
                  type='secondary'
                  title='Back'
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                  styleButton={{ margin: 0 }}
                />
                <CustomButton
                  onClick={() => onSaveAndNext('skip')}
                  type='secondary'
                  title='Skip'
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                  styleButton={{ margin: 0 }}
                />
              </>
            )}
            <CustomButton
              onClick={handleSubmit}
              type='primary'
              title={isConfiguringStep ? 'Save and Next' : 'Save'}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ margin: 0 }}
            />
          </SActions>
        </SHeader>
        <SWrapper>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='urs_biennial_active'
              checked={values?.urs_biennial_active}
              onChange={(checked) => handleChange('urs_biennial_active', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ width: 300, color: use(palette.gray700, palette.gray200) }}>
              URS Biennial Update Renewal Date
            </Typography>
          </div>
          <div>
            <DatePicker
              name='urs_biennial_renewal_date'
              value={values.urs_biennial_renewal_date}
              onChange={(val) => handleChange('urs_biennial_renewal_date', val)}
              onBlur={handleBlur}
              disablePast
              disabled={!values.urs_biennial_active}
              error={touchedErrors.urs_biennial_renewal_date}
            />
          </div>
        </SWrapper>
        <SWrapper>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='ucr_annual_active'
              checked={values?.ucr_annual_active}
              onChange={(checked) => handleChange('ucr_annual_active', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ width: 300, color: use(palette.gray700, palette.gray200) }}>
              UCR Annual Update Due Date
            </Typography>
          </div>
          <div>
            <DatePicker
              name='ucr_annual_due_date'
              value={values.ucr_annual_due_date}
              onChange={(val) => handleChange('ucr_annual_due_date', val)}
              onBlur={handleBlur}
              disablePast
              disabled={!values.ucr_annual_active}
              error={touchedErrors.ucr_annual_due_date}
            />
          </div>
        </SWrapper>
        <SWrapper>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='mcs_biennial_active'
              checked={values?.mcs_biennial_active}
              onChange={(checked) => handleChange('mcs_biennial_active', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ width: 300, color: use(palette.gray700, palette.gray200) }}>
              MCS 150 Biennial Update Due Date
            </Typography>
          </div>
          <div>
            <DatePicker
              name='mcs_biennial_update_date'
              value={values.mcs_biennial_update_date}
              onChange={(val) => handleChange('mcs_biennial_update_date', val)}
              onBlur={handleBlur}
              disablePast
              disabled={!values.mcs_biennial_active}
              error={touchedErrors.mcs_biennial_update_date}
            />
          </div>
        </SWrapper>
        <SWrapper>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='clearing_house_active'
              checked={values?.clearing_house_active}
              onChange={(checked) => handleChange('clearing_house_active', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ width: 300, color: use(palette.gray700, palette.gray200) }}>
              FMCSA Clearing House Pull Report Date
            </Typography>
          </div>
          <div>
            <DatePicker
              name='clearing_house_renewal_date'
              value={values.clearing_house_renewal_date}
              onChange={(val) => handleChange('clearing_house_renewal_date', val)}
              onBlur={handleBlur}
              disablePast
              disabled={!values.clearing_house_active}
              error={touchedErrors.clearing_house_renewal_date}
            />
          </div>
        </SWrapper>
        <SWrapper>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='cvor_certificate_active'
              checked={values?.cvor_certificate_active}
              onChange={(checked) => handleChange('cvor_certificate_active', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ width: 300, color: use(palette.gray700, palette.gray200) }}>
              CVOR Certificate Renewal Date
            </Typography>
          </div>
          <div>
            <DatePicker
              name='cvor_certificate_renewal_date'
              value={values.cvor_certificate_renewal_date}
              onChange={(val) => handleChange('cvor_certificate_renewal_date', val)}
              onBlur={handleBlur}
              disablePast
              disabled={!values.cvor_certificate_active}
              error={touchedErrors.cvor_certificate_renewal_date}
            />
          </div>
        </SWrapper>
        <SWrapper>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='scac_code_active'
              checked={values?.scac_code_active}
              onChange={(checked) => handleChange('scac_code_active', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ width: 300, color: use(palette.gray700, palette.gray200) }}>
              SCAC Code Renewal Date
            </Typography>
          </div>
          <div>
            <DatePicker
              name='scac_code_renewal_date'
              value={values.scac_code_renewal_date}
              onChange={(val) => handleChange('scac_code_renewal_date', val)}
              onBlur={handleBlur}
              disablePast
              disabled={!values.scac_code_active}
              error={touchedErrors.scac_code_renewal_date}
            />
          </div>
        </SWrapper>
      </div>
    </form>
  );
};

export default FmcsaCompliance;
