import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import subRight from 'assets/icons/drivers/sub-right.png';
import { Icons } from 'assets/icons';
import { palette } from 'utils/constants';
import authHeader from 'services/auth-header';
import { DimensionArray } from 'Api/Equipment';
import { useTheme } from 'context/themeContext';
import { validationSchema } from './validationSchema';
import style from '../AddEquipment.module.css';

const Type = ({
  nextStep,
  Cancel,
  getEquipmentData,
  setgetEquipmentData,
  onChangeTab,
  status,
  getAllVinData,
  equipmentType = {},
  getEquipmentType,
  vehicleList,
  trailerList,
  LengthDataByVehicle,
  selectedVehicleLength,
  defaultType,
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      minHeight: 32,
      minWidth: 250,
      border: 'none',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      boxShadow: palette.boxShadow3,
      borderRadius: 6,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
    }),
  };
  const lengthStyle = {
    control: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      minHeight: 32,
      minWidth: 200,
      border: 'none',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      boxShadow: palette.boxShadow3,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
    }),
  };
  const { use } = useTheme();
  const API_URL = process.env.REACT_APP_API_URL;
  const { equipment_type_id, type, vin, length, lengthValue } = equipmentType;

  const [vinResponse, setVinResponse] = useState(false);

  const [loading, setLoading] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState(type || defaultType || 'vehicle');
  const [lengthData, setLengthData] = useState();
  getEquipmentType(equipmentTypes);

  const [vehicleVin, setVehicleVin] = useState(type === 'vehicle' ? vin : '');

  const formik = useFormik({
    initialValues: {
      equipment_type_id: equipment_type_id || '',
      type: type || defaultType || 'vehicle',
      vin,
      length: length || '',
      lengthValue,
    },
    validationSchema,
    onSubmit: (values) => {
      nextStep({ values });
      onChangeTab(equipmentTypes);
    },
  });

  useEffect(() => {
    equipmentTypehandleChange(equipment_type_id);
  }, [status === 1]);

  const equipmentTypehandleChange = (id) => {
    DimensionArray(id).then((res) => {
      if (res && res?.data) {
        const filterByData = res.data.map((el) => {
          return {
            label: el?.length_info ? `${el?.length_info?.length} ${el?.length_info?.unit}` : null,
            value: el?.length,
          };
        });
        setLengthData(filterByData);
        LengthDataByVehicle(filterByData);
      }
    });
  };

  const getVinDataByAPI = (value) => {
    setLoading(true);
    axios
      .post(`${API_URL}/vin`, { vin: value }, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.Results[0].ErrorCode.search('0') > -1) {
            setTimeout(() => {
              setVinResponse(true);
              getAllVinData(res?.data);

              setLoading(false);
            }, 1000);
          }
          // nextStep({values:values})
        }
      })
      .catch(
        setTimeout(() => {
          setLoading(false);
        }, 2000)
      );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body className={style['add-equipment-modal-body']}>
        <div className='py-4'>
          <div>
            <p className={style['equipment-type-text']}>
              <span className='mt-3'>Equipment Type</span>
            </p>
          </div>
          <div id='my-radio-group'>
            <div className='d-flex' role='group' aria-labelledby='my-radio-group'>
              <div>
                <p>
                  <input
                    type='radio'
                    name='equipmentTypes'
                    value='vehicle'
                    onClick={() => {
                      setEquipmentTypes('vehicle');
                      setVinResponse(false);
                    }}
                    checked={formik.values.type === 'vehicle'}
                    onChange={() => {
                      formik.setValues((prevState) => ({
                        ...prevState,
                        type: 'vehicle',
                        equipment_type_id: null,
                        length: '',
                      }));
                    }}
                  />
                  <span className={style['equipment-headings']}>Vehicle</span>
                </p>
              </div>
              <div>
                <p>
                  <input
                    type='radio'
                    name='equipmentTypes'
                    value='trailer'
                    onClick={() => {
                      setEquipmentTypes('trailer');
                      setVinResponse(false);
                    }}
                    checked={formik.values.type === 'trailer'}
                    onChange={() => {
                      formik.setValues((prevState) => ({
                        ...prevState,
                        type: 'trailer',
                        equipment_type_id: null,
                        length: '',
                      }));
                    }}
                  />
                  <span className={style['equipment-headings']}>Trailer</span>
                </p>
              </div>
            </div>
            {equipmentTypes === 'trailer' ? (
              <div className={style['trailer-type-list']}>
                <img src={subRight} className={style['sub-right-icon']} alt={subRight} />
                <div className={style['select-owner-operator']}>
                  <Select
                    options={trailerList}
                    defaultValue={
                      trailerList?.filter((v) =>
                        v?.value === formik.values.equipment_type_id ? { label: v.label, value: v.value } : ''
                      )[0]
                    }
                    name='equipment_type_id'
                    onChange={(e) => {
                      setgetEquipmentData({ ...getEquipmentData, equipment_type_id: e?.value });
                      equipmentTypehandleChange(e?.value);
                      formik.setFieldValue('equipment_type_id', e?.value);
                      formik.setFieldValue('length', '');
                    }}
                    onBlur={formik.handleBlur}
                    styles={customStyles}
                  />
                  <span className='d-flex justify-content-end me-1'>
                    {formik.errors.equipment_type_id && formik.touched.equipment_type_id ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                          marginTop: '12px',
                        }}
                      >
                        {formik.errors.equipment_type_id}
                      </div>
                    ) : null}
                  </span>
                </div>
                <div className=''>
                  <div className={style['equipment-trailer-selector']}>
                    <span>Length</span>
                    <Select
                      options={lengthData}
                      type='checkbox'
                      name='length'
                      defaultValue={type === 'trailer' ? { label: lengthValue, value: length } : ''}
                      isDisabled={!formik.values.equipment_type_id}
                      onChange={(e) => {
                        setgetEquipmentData({ ...getEquipmentData, length: e?.value });
                        formik.setFieldValue('length', e?.value);
                        formik.setFieldValue('lengthValue', e?.label);
                        selectedVehicleLength(e);
                      }}
                      value={
                        formik.values.length ? { value: formik.values.length, label: formik.values.lengthValue } : null
                      }
                      onBlur={formik.handleBlur}
                      styles={lengthStyle}
                    />
                  </div>

                  <span className='d-flex justify-content-end me-1'>
                    {formik.errors.length && formik.touched.length ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                          marginTop: '12px',
                        }}
                      >
                        {formik.errors.length}
                      </div>
                    ) : null}
                  </span>
                </div>
                <div className='d-flex'>
                  <div className={style['equipment-trailer-selector']}>
                    <span>VIN #</span>
                    <input
                      type='text'
                      name='vin'
                      value={formik.values.vin}
                      onChange={(e) => {
                        formik.setFieldValue('vin', e.target.value);
                        if (e.target.value) {
                          getVinDataByAPI(e.target.value);
                        } else {
                          setVinResponse(false);
                        }
                      }}
                    />
                  </div>
                  {vinResponse && <img src={Icons?.Right} alt='' className='ms-2' />}
                  {loading && (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '10px',
                          marginTop: '5px',
                        }}
                      />
                    </Box>
                  )}
                  <span className='d-flex justify-content-end me-1'>
                    {formik.errors.vin && formik.touched.vin ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                          marginTop: '12px',
                        }}
                      >
                        {formik.errors.vin}
                      </div>
                    ) : null}
                  </span>
                </div>
              </div>
            ) : (
              <div className='d-flex'>
                <img src={subRight} className='sub-right-icon' alt={subRight} style={{ display: 'inline-block' }} />
                <span>
                  <Select
                    options={vehicleList}
                    name='equipment_type_id'
                    defaultValue={
                      vehicleList?.filter((v) =>
                        v?.value === formik.values.equipment_type_id ? { label: v.label, value: v.value } : ''
                      )[0]
                    }
                    onBlur={formik.handleBlur}
                    styles={customStyles}
                    onChange={(e) => {
                      setgetEquipmentData({ ...getEquipmentData, equipment_type_id: e?.value });
                      equipmentTypehandleChange(e?.value);
                      formik.setFieldValue('equipment_type_id', e?.value);
                      formik.setFieldValue('length', '');
                    }}
                  />

                  <span className='d-flex justify-content-end me-1'>
                    {formik.errors.equipment_type_id && formik.touched.equipment_type_id ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                          marginTop: '12px',
                        }}
                      >
                        {formik.errors.equipment_type_id}
                      </div>
                    ) : null}
                  </span>
                </span>
                <div className=''>
                  {formik.values.equipment_type_id === 3 ||
                  formik.values.equipment_type_id === 11 ||
                  formik.values.equipment_type_id === 9 ||
                  formik.values.equipment_type_id === 4 ||
                  formik.values.equipment_type_id === 8 ||
                  formik.values.equipment_type_id === 5 ? (
                    <div className={style['equipment-trailer-selector']}>
                      <span>Length</span>
                      <Select
                        options={lengthData}
                        type='checkbox'
                        name='length'
                        defaultValue={type !== 'trailer' ? { label: lengthValue, value: length } : ''}
                        onChange={(e) => {
                          setgetEquipmentData({ ...getEquipmentData, length: e?.value });
                          formik.setFieldValue('length', e?.value);
                          formik.setFieldValue('lengthValue', e?.label);

                          selectedVehicleLength(e);
                        }}
                        value={
                          formik.values.length
                            ? { value: formik.values.length, label: formik.values.lengthValue }
                            : null
                        }
                        onBlur={formik.handleBlur}
                        styles={lengthStyle}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  <span className='d-flex justify-content-end me-1'>
                    {formik.errors.length && formik.touched.length ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                          marginTop: '12px',
                        }}
                      >
                        {formik.errors.length}
                      </div>
                    ) : null}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex' }}>
                    <div className={style['equipment-trailer-selector']}>
                      <span>VIN #</span>
                      <input
                        type='text'
                        name='vin'
                        value={formik.values.type === 'vehicle' ? vehicleVin : ''}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          setVehicleVin(e.target.value);
                          if (e.target.value) {
                            getVinDataByAPI(e.target.value);
                          } else {
                            setVinResponse(false);
                          }
                          formik.setFieldValue('vin', e?.target.value);
                        }}
                      />
                    </div>

                    {vinResponse && <img src={Icons?.Right} alt='' className='ms-2' />}
                    {loading && (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress
                          style={{
                            width: '20px',
                            height: '20px',
                            marginLeft: '10px',
                            marginTop: '5px',
                          }}
                        />
                      </Box>
                    )}
                  </div>
                  <span className='d-flex justify-content-end me-1'>
                    {formik.errors.vin && formik.touched.vin ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                          marginTop: '12px',
                        }}
                      >
                        {formik.errors.vin}{' '}
                      </div>
                    ) : null}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className={`${style['type-cancel-button']} ${'ms-2'}`} onClick={Cancel}>
          Cancel
        </button>
        <div>
          <button className={style['add-equipment-next-step']} disabled={!!loading} type='submit'>
            Next Step
          </button>
        </div>
      </Modal.Footer>
    </Form>
  );
};

export default Type;
