import React from 'react';
import { ReactComponent as CutIcon } from 'assets/icons/cut.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { palette } from 'utils/constants';
import Tooltip from 'common/Tooltip';

const SplitLine = ({ onAddSection, onSaveStops, onRevertStops, stopsCreated }) => {
  return (
    <div className='split-line-wrapper'>
      <svg style={{ width: '100%', height: '100%' }} fill='transparent'>
        <line
          x1={0}
          y1={0}
          x2='100%'
          y2={0}
          width='100%'
          height='100%'
          stroke='#E56081'
          strokeWidth='8px'
          strokeDasharray='2px 4px'
        />
      </svg>
      {!!onAddSection && (
        <div className='add-split-section-icon' onClick={onAddSection}>
          <PlusIcon fill={palette.red300} />
        </div>
      )}
      {!!onSaveStops && (
        <div className='cut-icon' onClick={stopsCreated ? onRevertStops : onSaveStops}>
          {stopsCreated ? (
            <Tooltip title='Revert Stops' className='d-flex'>
              <RevertIcon fill={palette.red300} />{' '}
            </Tooltip>
          ) : (
            <Tooltip title='Create Shipment Stops' className='d-flex'>
              <CutIcon />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default SplitLine;
