import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@material-ui/core/Button';
import { OwnerOperator } from 'Api/OwnerOperator';
import { deleteTab, getTabs, hourOfServiceAll } from 'Api/Driver';
import { getBillToBillingCharges, getPlannerPopoverSettings } from 'Api/Planner';
import { TableContext } from 'context/tableContext';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { CircularProgress } from '@mui/material';
import Popover from '@mui/material/Popover';
import AlertMsg from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon';
import TableWrapper from 'components/TableWrapper';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import TableFooter from '../TableFooter/TableFooter';
import TabUpdateModal from '../owner-operator-tab-modal/owner-operator-tab-modal';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import CustomizedSnackbars from '../toast/Toast';
import OwnerHeader from '../OwnerOperatorHeader/OwnerOperatorHeader';
import { useColumns } from './OwnerOperator.data';
import './OwnerOperator.css';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';

const OwnerOperatorList = () => {
  const { use } = useTheme();
  const myTableRef = useRef(null).current;
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [driverData, setDriverData] = useState({});
  const [UpdateModalShow, setUpdateModalShow] = useState(false);
  const [AddModalShow, setAddModalShow] = useState(false);
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [getPlanerFilterData, setGetPlanerFilterData] = useState({});
  const [createModalCLose, setCreateModalCLose] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [hourService, setHourService] = useState([]);
  const [removeTabModalOpen, setRemoveTabModalOpen] = useState(null);
  const [removeTabloading, setRemoveTabloading] = useState(null);
  const [profileAlert, setProfileAlert] = useState(null);
  const [filterTableTop, setFilterTableTop] = useState([]);
  const [tabModalEditData, setTabModalEditData] = useState({});
  const [search, setSearch] = useState(null);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const locationInitialValue = {
    city: { searchValue: '', value: 'City', key: uuid() },
    state: { searchValue: '', value: 'State', key: uuid() },
    zipCode: { searchValue: '', value: 'Postcode', key: uuid() },
    miles: { searchValue: '', value: 'Radius', key: uuid() },
  };
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'Active', value: 'active' },
    searchValue: '',
    allValue: '',
    selectedValues: [],
    locations: [],
    origin_location: locationInitialValue,
    destination_location: locationInitialValue,
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: switchRadioButtons?.amount || '1',
      date: switchRadioButtons?.date || '1',
      time: switchRadioButtons?.time || '1',
    },
    forAll: false,
    planerHeaderSwitch: { planerSwitch: '0' },
    applyAllUsersModal: { value: '0' },
  });
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});

  function filterDataTable(data) {
    if (!data) return [];
    return data?.filter((data) => {
      const { owner_operator_name, phone_no, status, drivers_count, equipment_count, compensation } = data || {};
      const phone = phone_no;
      const name = owner_operator_name;
      const driverCompensation = compensation || {};
      const statusTitle = status || {};
      const equipmentCount = equipment_count;
      const driversCount = drivers_count;
      let cond = false;
      switch (filter?.searchBy?.value) {
        case 'all':
          cond = !filter.allValue
            ? true
            : filterByValue([phone, name, equipmentCount, driverCompensation, statusTitle, driversCount]);
          break;
        case 'name':
          cond = !filter.allValue ? true : filterByValue([`${name}`]);
          break;
        default:
          cond = true;
      }
      return cond;
    });
  }

  const updateFilterCount = (res) => {
    const tempFilterTableTop = [...filterTableTop];
    tempFilterTableTop[0].count = res.total;
    setFilterTableTop([...tempFilterTableTop]);
  };

  function onCheckMultiselectData(checkboxes, checkbox) {
    const map = {
      group_id: 'Tags',
      status_id: 'Status',
    };
    const filtersType = checkbox.value;
    const filtersValue = checkboxes.filter((el) => el.value === checkbox.value).map((el) => el.id);
    const checkboxData = checkboxes
      .filter((el) => el.value === checkbox.value)
      .map((el) => {
        return {
          ...el,
          title: el.value,
          searchValue: el.title,
        };
      });

    if (checkboxData.length > 1) {
      const mappedData = showHeaderFilters.map((el) => {
        if (el.value === checkbox.value) {
          return {
            ...el,
            searchValue: checkboxData,
          };
        }
        return el;
      });
      const includes = showHeaderFilters.find((el) => el.value === checkbox.value);
      includes
        ? setShowHeaderFilters([...mappedData])
        : setShowHeaderFilters([
            ...mappedData,
            {
              ...checkbox,
              title: map[checkbox.value],
              searchValue: checkbox.title,
            },
          ]);
    } else {
      const founded = showHeaderFilters.find((el) => el.value === checkbox.value);

      if (founded) {
        let mappedData;
        if (checkboxData.length === 0) {
          mappedData = showHeaderFilters.filter((el) => el.value !== checkbox.value);
        } else {
          mappedData = showHeaderFilters.map((el) => {
            if (el.value === founded.value) {
              return {
                ...el,
                searchValue: checkboxData,
              };
            }
            return el;
          });
        }

        setShowHeaderFilters([...mappedData]);
      } else {
        const firstElement = checkboxData[0];
        const { id, value, key, searchValue } = firstElement;
        const showData = { id, key, searchValue, title: map[value], value };
        setShowHeaderFilters([...showHeaderFilters, showData]);
      }
    }
    setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
    getDriver({ filtersType, filtersValue });
  }

  const sortingQuery = (field, sortBy) => {
    if (sortData.some((i) => i.field === field)) {
      const newData = sortData.filter((el) => el.field !== field);
      newData.unshift({ field, sortBy });
      setSortData(newData);
    } else {
      setSortData((p) => [{ field, sortBy }, ...p]);
    }
  };

  const getAllFilters = (filters = {}) => {
    const allFilters = { ...filters };
    sortData?.forEach(({ field, sortBy }) => {
      allFilters[`sort[][${field}]`] = sortBy;
    });

    return allFilters;
  };

  const onReset = (filterData = null, isClearAll) => {
    let params = { page: 1, itemsPerPage: rowPerPage };
    if (filterData) {
      params = { ...params, ...filterData };
    }
    setLoading(true);
    const { group_id, status } = params;
    let payload = { ...params, status_id: status?.toString(), group_id: group_id?.toString() };
    if (!payload.group_id) {
      delete payload.group_id;
    }
    if (!payload.status_id) {
      delete payload.status_id;
    }
    if (!filterData) {
      payload = {
        page: 1,
        itemsPerPage: rowPerPage,
        status_id: filter?.searchBy?.title === 'Active' ? '1' : filter?.searchBy?.title === 'View Inactive' ? '2' : '',
      };
    }
    OwnerOperator(getAllFilters(payload))
      .then((res) => {
        setDriverData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
        if (isClearAll) {
          updateFilterCount(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };

  const updateOnCreate = (value) => {
    setCreateModalCLose(value);
  };

  const multiSelectOptions = useMemo(() => {
    return {
      title: 'All',
      id: 999,
      value: 'all',
      key: 9999,
      submenu: [
        {
          title: 'Active',
          id: 1,
          value: 'active',
          key: 1,
        },
        {
          title: 'View Inactive',
          id: 2,
          value: 'viewInactive',
          key: 2,
        },
      ],
    };
  }, []);

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  const ProfileAlertOpen = Boolean(profileAlert);
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;
  const navigate = useNavigate();

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    setLoading(true);
    const filterData = {
      page: 1,
      itemsPerPage: rowPage,
      status_id: filter?.searchBy?.title === 'Active' ? '1' : filter?.searchBy?.title === 'View Inactive' ? '2' : '',
    };
    OwnerOperator(getAllFilters(filterData))
      .then((res) => {
        setDriverData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
        updateFilterCount(res);
      })
      .finally(() => setLoading(false));
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const onPageChange = (event, page) => {
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filterData = {
      page,
      itemsPerPage: rowPerPage,
      status_id: filter?.searchBy?.title === 'Active' ? '1' : filter?.searchBy?.title === 'View Inactive' ? '2' : '',
    };
    OwnerOperator(getAllFilters(filterData))
      .then((res) => {
        setDriverData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
        updateFilterCount(res);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    return cols;
  };

  const mapperSettingsData = (key) => {
    const map = {
      owner_operator_name: 'Name',
      phone_no: 'Phone Number',
      status: 'Status',
      drivers_count: 'Driver Count',
      equipment_count: 'Equipment Count',
      operator_group: 'Tags',
      compensation: 'Compensation',
    };
    return map[key] ? map[key] : key;
  };

  const getSettings = () => {
    getPlannerPopoverSettings({ type: 'owner_operator' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });
          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
          setFilter({
            ...filter,
            switchRadioButtons: {
              date,
              amount,
              time,
            },
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getDriver = ({ filtersType, filtersValue, reset = false, remainingObj }) => {
    setLoading(true);
    return OwnerOperator(
      getAllFilters({
        page: driverData?.current_page,
        itemsPerPage: rowPerPage,
        status_id: filter?.searchBy?.title === 'Active' ? '1' : filter?.searchBy?.title === 'View Inactive' ? '2' : '',
        filtersType,
        filtersValue,
        remainingData: reset ? {} : remainingObj || getPlanerFilterData,
      })
    )
      .then((res) => {
        setDriverData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
      })
      .finally(() => setLoading(false));
  };

  function find(string, chunk) {
    return String(string).toLowerCase().indexOf(chunk.toLowerCase()) === 0;
  }
  function filterByValue(criterionArr) {
    return criterionArr.some((criterion) => {
      return find(criterion, filter.allValue);
    });
  }

  function onSearchLocations(locations) {
    if (locations.length > 0) {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      const index = filtered.findIndex((el) => Array.isArray(el));
      const locationIndex = locations[0].valuePrefix === 'origin_location' ? index : index + 1;
      filtered.splice(locationIndex, 0, [...locations]);
      setShowHeaderFilters([...filtered]);
    } else {
      const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
      setShowHeaderFilters([...filtered]);
    }
  }
  function onChangeMultiselectSearchBy(data) {
    const origin = showHeaderFilters.map((el) => el?.value === 'scheduled_origin');
    const destination = showHeaderFilters.find((el) => el?.value === 'scheduled_destination');
    if ((data.value === 'scheduled_origin' || data.value === 'scheduled_destination') && (!origin || !destination)) {
      const updatedData = { ...filter, searchBy: data };
      updateFilter(updatedData);
    } else {
      const values = showHeaderFilters.map((el) => el.value);

      const updateData = { ...filter, searchBy: data };
      if (data.value === 'all' && !values.includes(data.value)) updateData.allValue = '';
      if (data.value === 'name' && !values.includes(data.value)) updateData.allValue = '';
      updateFilter(updateData);
    }
  }

  function onSearchMultiDropDown(query) {
    if (typeof query === 'string') {
      onChangeAll(query);
      return;
    }

    const founded = showHeaderFilters.find((el) => +el.id === +filter.searchBy.id);
    const customersSearchValue = filter.customerSelectData.map((el) => ({ ...el, searchValue: el.title }));
    const customersIds = filter.customerSelectData.map((el) => el.id);
    const filtersType = filter.searchBy.value;
    let filtersValue;
    switch (filtersType) {
      case 'customers':
        filtersValue = customersIds;
        break;
      case 'name':
        filtersValue = filter.allValue;
        break;
      case 'all':
        filtersValue = filter.allValue;
        break;
      default:
    }

    if (!founded && founded?.value !== 'customers') {
      let showData;
      if (filter.searchBy.value === 'customers') {
        if (filter.customerSelectData.length > 0) {
          showData = [{ ...filter.searchBy, searchValue: customersSearchValue }, ...showHeaderFilters];
          setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
          getDriver({ filtersType, filtersValue });
        } else {
          showData = [...showHeaderFilters];
        }
      } else if (filtersValue) {
        showData = [{ ...filter.searchBy, searchValue: filtersValue }, ...showHeaderFilters];
        setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
        getDriver({ filtersType, filtersValue });
      } else {
        showData = [...showHeaderFilters];
      }
      setShowHeaderFilters(showData);
    } else if (!!filtersValue && typeof filtersValue === 'string') {
      const mapped = showHeaderFilters.map((el) => {
        if (+el.id === +founded.id) {
          return { ...el, searchValue: filtersValue };
        }
        return el;
      });
      if (filtersValue !== founded.searchValue) {
        setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
        getDriver({ filtersType, filtersValue });
      }
      setShowHeaderFilters([...mapped]);
    } else if (founded?.value === 'customers') {
      if (customersSearchValue.length !== 0) {
        const showData = showHeaderFilters.map((data) => {
          if (data.value === 'customers') {
            return { ...data, searchValue: [...customersSearchValue] };
          }
          return data;
        });
        setShowHeaderFilters([...showData]);
        setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
        getDriver({ filtersType, filtersValue });
      } else {
        const showData = showHeaderFilters.filter((data) => !['customers'].includes(data.value));
        setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
        setShowHeaderFilters([...showData]);
        getDriver({ filtersType, filtersValue });
      }
    } else {
      const filtered = showHeaderFilters.filter((el) => Number(el?.id) !== Number(founded?.id));
      setShowHeaderFilters([...filtered]);
      setGetPlanerFilterData({ ...getPlanerFilterData, [filtersType]: filtersValue });
      getDriver({ filtersType, filtersValue });
    }
  }

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        myTableRef={myTableRef}
        rowPerPage={rowPerPage}
        totalCount={driverData?.total}
        totalLength={driverData?.data?.length}
        lastPage={driverData?.last_page}
        currentPage={driverData?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setDriverData(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const getFilterCustomerData = () => {
    getBillToBillingCharges().then((res) => {
      const newCustomers = res.data.map((el) => ({ id: el.id, title: el.company_name }));
      setCustomers(newCustomers);
    });
  };
  const onChangeAll = useCallback((value) => {
    OwnerOperator(
      getAllFilters({
        query: value,
        page: 1,
        itemsPerPage: rowPerPage,
        status_id: filter?.searchBy?.title === 'Active' ? '1' : filter?.searchBy?.title === 'View Inactive' ? '2' : '',
      })
    )
      .then((res) => {
        setDriverData({ ...res, data: inserthourOfService(res.data || [], hourService || []) });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (filter.searchBy?.value === 'customers') {
      getFilterCustomerData();
    }
  }, [filter.searchBy]);

  useEffect(() => {
    setLoading(true);
    getSettings();
    getOwnerOperator();
  }, [createModalCLose, filter?.searchBy?.title]);

  useEffect(() => {
    getOwnerOperator();
  }, [sortData]);

  const getOwnerOperator = (data) => {
    const { advancedFilter = {} } = data || {};

    Promise.allSettled([
      OwnerOperator(
        getAllFilters({
          page: 1,
          itemsPerPage: rowPerPage,
          status_id:
            filter?.searchBy?.title === 'Active' ? '1' : filter?.searchBy?.title === 'View Inactive' ? '2' : '',
          ...advancedFilter,
        })
      ),
      getTabs('owner_operator'),
      hourOfServiceAll(),
    ])
      .then((responses) => {
        let filterTableTop = [{ key: 'all', label: 'All', value: 0, count: 0 }];
        if (responses[0].status === 'fulfilled') {
          setDriverData({
            ...responses[0].value,
            data: inserthourOfService(responses[0].value.data || [], responses[2]?.value?.data || []),
          });
          filterTableTop[0].count = responses[0].value.total;
        }

        if (responses[1].status === 'fulfilled') {
          const deAactiveIndex = responses[1]?.value.data.findIndex(
            (val) => val.data?.name === 'Disabled' && val.system === 1
          );
          if (deAactiveIndex >= 0) {
            responses[1]?.value.data.splice(deAactiveIndex, 1);
          }

          const activeIndex = responses[1]?.value.data.findIndex(
            (val) => val.data?.name === 'Active' && val.system === 1
          );
          if (activeIndex >= 0) {
            responses[1]?.value.data.splice(activeIndex, 1);
          }

          filterTableTop = [
            ...filterTableTop,
            ...(responses[1].value.data?.map((tab, index) => ({
              key: tab.data.name,
              label: tab.data.name,
              value: index + 1,
              count: tab.count,
              filters: tab.data.filters,
              tabId: tab.id,
              system: tab.system,
              user_id: tab.user_id,
            })) || []),
          ];
        }
        if (responses[2].status === 'fulfilled') {
          setHourService(responses[2].value.data || []);
        }
        setFilterTableTop([...filterTableTop]);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const inserthourOfService = (drivers, services) => {
    return drivers.map((driver) => {
      const getSeriveById = services.find((service) => service.driver_id === driver.id) || {};
      return {
        ...getSeriveById,
        ...driver,
      };
    });
  };

  const switcherData = useMemo(() => {
    const data = [...filterTableTop];
    return data;
  }, [filterTableTop]);

  const onChangeTab = (val) => {
    const { value } = val;
    setTab(value);
    const getTab = { ...(filterTableTop.find((tab) => tab.value === value) || {}) };
    onReset(getTab?.filters);
  };

  const remove = () => {
    const { tabId, value } = removeTabModalOpen;
    const getTabIdIndex = filterTableTop.findIndex((tab) => tab.value === value);
    setRemoveTabloading(true);
    if (getTabIdIndex >= 0) {
      deleteTab({ tab: tabId })
        .then(() => {
          onSuccess('Tab has been deleted successfully');
          const tempTabs = [...filterTableTop];
          tempTabs.splice(getTabIdIndex, 1);
          setFilterTableTop(tempTabs);
          setRemoveTabModalOpen(null);
          setRemoveTabloading(false);
          setUpdateModalShow(false);
        })
        .catch((error) => {
          setRemoveTabloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  function onEditTableTab(item) {
    const { label, filters, tabId, system, value, key, count } = item;
    const initialValue = {
      label,
      key,
      filters,
      tabId,
      system,
      value,
      count,
    };
    setTabModalEditData(initialValue);
    setUpdateModalShow(true);
  }

  const addTabs = () => {
    getTabs('owner_operator').then((response) => {
      const deAactiveIndex = response.data.findIndex((val) => val.data?.name === 'Disabled' && val.system === 1);
      if (deAactiveIndex >= 0) {
        response.data.splice(deAactiveIndex, 1);
      }
      const activeIndex = response.data.findIndex((val) => val.data?.name === 'Active' && val.system === 1);
      if (activeIndex >= 0) {
        response.data.splice(activeIndex, 1);
      }
      let filterTableTopTemp = [filterTableTop[0]];
      filterTableTopTemp = [
        ...filterTableTopTemp,
        ...(response.data?.map((tab, index) => ({
          key: tab.data.name,
          label: tab.data.name,
          value: index + 1,
          count: tab.count,
          filters: tab.data.filters,
          tabId: tab.id,
          system: tab.system,
          user_id: tab.user_id,
        })) || []),
      ];
      setFilterTableTop([...filterTableTopTemp]);
    });
  };

  const handleClose = () => {
    setRemoveTabModalOpen(null);
  };

  const handleClickOpen = (selectedTab) => {
    setRemoveTabModalOpen(selectedTab);
  };

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        requestData[key] = value.map((el) => el.id).toString();
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const onSearch = (data) => {
    setLoading(true);
    const clearAll = typeof data === 'object' && Object.keys(data).length === 0;
    const filterObject = data || advancedFilter;

    if (!search || clearAll) {
      delete filterObject.query;
    } else {
      filterObject.query = search;
    }

    getOwnerOperator({ advancedFilter: convertFilterData(filterObject) });
  };

  const columns = useColumns({ sortingQuery });

  return (
    <>
      <div className='mt-4 driver-list'>
        <TableContext.Provider
          value={{
            filter,
            dragItem,
            switchRadioButtons,
            onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
            tableColumn,
            onsStTableColumn: (v) => setTableColumn(v),
            getSettings,
            customers,
            updateFilter,
            onChangeOrder: (items) => setDragItem(items),
            driverData: driverData?.data,
            createModalCLose,
            updateOnCreate,
            onSearchLocations,
            onSearchMultiDropDown,
            onCheckMultiselectData,
            onChangeMultiselectSearchBy,
            onChangeAll,
            multiSelectOptions,
          }}
        >
          <AdvancedFilter
            onSearch={onSearch}
            open={advancedOpen}
            setSearch={setSearch}
            filter={advancedFilter}
            setFilter={setAdvancedFilter}
          >
            {({ onSearchFilter }) => {
              return (
                <OwnerHeader
                  search={search}
                  setSearch={setSearch}
                  onSearch={onSearchFilter}
                  advancedOpen={advancedOpen}
                  setAdvancedOpen={setAdvancedOpen}
                  getOwnerOperator={getOwnerOperator}
                />
              );
            }}
          </AdvancedFilter>
          <SwitchRadio
            name='tableTopTabMenu'
            items={[...(switcherData || [])]}
            value={tab}
            type='tab'
            onChange={(value) => onChangeTab(value)}
            plus
            onEdit={onEditTableTab}
            onClickPlus={() => setAddModalShow(true)}
          />
        </TableContext.Provider>
        <div className='owner-list'>
          {loading ? (
            <TablePreLoader />
          ) : (
            <div className='tableFixHead table-fixed-header-340'>
              <TableWrapper
                data={filterDataTable(driverData?.data)}
                rowPerPage={rowPerPage}
                style={{ backgroundColor: use(palette.white, palette.dark800) }}
                components={{
                  Pagination: PaginationComponent,
                }}
                columns={filterTableColumn(columns)}
                onRowClick={(e, rowData) => navigate(`/ownerOperator-profile/${rowData.id}`)}
              />
            </div>
          )}
        </div>
        <Popover
          id={ProfileAlertContent}
          open={ProfileAlertOpen}
          anchorEl={profileAlert}
          onClose={ProfileAlerthandleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          style={{ marginLeft: '20px' }}
        >
          <AlertMsg alertData={null} driverAlert='Driver Alerts' />
        </Popover>
        {!!removeTabModalOpen && (
          <div>
            <Dialog
              open={!!removeTabModalOpen}
              onClose={handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>Confirm Your Action</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Are you sure you want to remove tab?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className='cancel-button' onClick={handleClose}>
                  Cancel
                </Button>
                {removeTabloading ? (
                  <div>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <Button className='next-step remove-modal-action-btn' onClick={() => remove()} autoFocus>
                    Remove
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
      {UpdateModalShow && (
        <TabUpdateModal
          show={UpdateModalShow}
          onHide={() => setUpdateModalShow(false)}
          onSuccess={onSuccess}
          showError={showError}
          save={addTabs}
          initialValue={tabModalEditData}
          tabName='Update Tab'
          onClickCross={(e, selectedTab) => {
            handleClickOpen(selectedTab);
          }}
        />
      )}
      {AddModalShow && (
        <TabUpdateModal
          show={AddModalShow}
          onHide={() => setAddModalShow(false)}
          onSuccess={onSuccess}
          showError={showError}
          save={addTabs}
          tabName='Add Tab'
        />
      )}
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default OwnerOperatorList;
