import { date, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  ifta_sticker_no: string().when('ifta_sticker_active', {
    is: true,
    then: string().trim().required('Required'),
  }),
  ifta_sticker_expire_date: date().when(['ifta_sticker_active', 'ifta_sticker_no_expire'], {
    is: (ifta_sticker_active, ifta_sticker_no_expire) => !!ifta_sticker_active && !ifta_sticker_no_expire,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  ifta_sticker_attach: string().when('ifta_sticker_active', {
    is: true,
    then: string().trim().required('Required'),
  }),
});
