import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import Input from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { deleteCarrierContactBook } from 'Api/Carriers';
import { getCustomerContactBookType } from 'Api/Customers';
import { getErrorMessage } from 'utils/error';
import { contactBookInitialValues } from 'pages/OnboardCarrier/OnboardCarrier.data';
import { STable } from './ContactBook.styles';

const ContactBookForm = ({ form }) => {
  const showToaster = useShowToaster();
  const [typeData, setTypeData] = useState([]);

  const { values, handleChange, touchedErrors, setValues } = form || {};

  const getTypeData = async () => {
    try {
      const { data } = await getCustomerContactBookType();
      setTypeData(data);
    } catch (e) {
      // Do nothing
    }
  };

  const deleteContact = async (row, index) => {
    if (row.isNew) {
      setValues((prevState) => prevState.filter((el, i) => i !== index));
      return;
    }
    try {
      await deleteCarrierContactBook(row.id);
      setValues((prevState) => prevState.filter((el, i) => i !== index));
      showToaster({ type: 'success', message: 'Contact has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  useEffect(() => {
    getTypeData();
  }, []);

  return (
    <div>
      <STable>
        <thead>
          <tr className='header-row'>
            <th>
              <InputLabel required>Name</InputLabel>
            </th>
            <th>
              <InputLabel required>Email</InputLabel>
            </th>
            <th>
              <InputLabel required>Phone Number</InputLabel>
            </th>
            <th>
              <InputLabel required>Type</InputLabel>
            </th>
            <th>
              <InputLabel>Receive Offers</InputLabel>
            </th>
            <th>
              <InputLabel>Allow Check Calls</InputLabel>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {values.map((item, index) => (
            <tr key={item.id}>
              <td>
                <Input
                  name={`${index}.contact_name`}
                  onChange={handleChange}
                  value={values[index].contact_name}
                  error={touchedErrors[index]?.contact_name}
                />
              </td>
              <td>
                <Input
                  name={`${index}.contact_email`}
                  onChange={handleChange}
                  value={values[index].contact_email}
                  error={touchedErrors[index]?.contact_email}
                />
              </td>
              <td>
                <PhoneInput
                  name={`${index}.phone_number`}
                  placeholder='+1 (555) 555-1234'
                  onChange={(val) => handleChange(`${index}.phone_number`, val)}
                  value={values[index].phone_number}
                  error={touchedErrors[index]?.phone_number}
                />
              </td>
              <td>
                <Autocomplete
                  width='150px'
                  labelKey='title'
                  name={`${index}.contact_type_id`}
                  value={values[index].contact_type_id}
                  options={typeData}
                  onChange={(e, val) => handleChange(`${index}.contact_type_id`, val)}
                  error={touchedErrors[index]?.contact_type_id}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </td>
              <td>
                <CustomCheckbox
                  name={`${index}.receive_offers`}
                  type='switch'
                  smail={false}
                  checked={values[index].receive_offers}
                  onChange={(checked) => handleChange(`${index}.receive_offers`, checked)}
                />
              </td>
              <td>
                <CustomCheckbox
                  name={`${index}.check_calls`}
                  type='switch'
                  smail={false}
                  checked={values[index].check_calls}
                  onChange={(checked) => handleChange(`${index}.check_calls`, checked)}
                />
              </td>
              <td>
                {index > 0 && (
                  <DeleteIcon
                    width={12}
                    height={12}
                    fill={palette.indigo500}
                    onClick={() => deleteContact(item, index)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </STable>
      <CustomButton
        type='secondary'
        title='Add Another'
        styleTitle={{ fontSize: 14, color: palette.indigo500, marginLeft: 8 }}
        styleButton={{ padding: '7px 12px', boxShadow: 'none', backgroundColor: 'transparent', paddingLeft: 0 }}
        onClick={() => handleChange(values.length, { ...contactBookInitialValues, id: Date.now() })}
        leftIcon={<PlusIcon fill={palette.indigo500} />}
      />
    </div>
  );
};

export default ContactBookForm;
