import React from 'react';
import { useParams } from 'react-router-dom';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import ConnectedServices from '../Drivers/AddDriver/ConnectedServices';
import './ConnectedService.css';

const ConnectedServicesTab = () => {
  const { use } = useTheme();
  const { id } = useParams();

  return (
    <div className='connected-series-main-wraper-top driver-style-wrap'>
      <div
        className='header-left'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          color: use(palette.gray700, palette.gray200),
        }}
      >
        <div>
          <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Connected Services
          </p>
        </div>
      </div>
      <div
        className='connectedService-table-container'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          color: use(palette.gray700, palette.gray200),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='table-wrap'>
          <div className='sub-table-connected-service' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
            <ConnectedServices
              isDriverProfile
              nextStep=''
              prevStep=''
              Cancel=''
              driverId={id}
              stepsDone=''
              setStepsDone=''
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectedServicesTab;
