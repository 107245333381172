import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { formatTimeDiff, parseFlexibleTimeToMinutes } from 'components/TableShipments/BillOfLading/helpers/utils';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import moment from 'moment';

const ScheduledWarning = ({ open, onClose, onSuccess, data, duration, stopPoints }) => {
  const { formatDateTime } = useDateFormat();

  const firstStop = data?.[0]?.stops[0];
  const lastStopArray = data[data.length - 1]?.stops;
  const lastStop = lastStopArray[lastStopArray.length - 1];

  const firstStopTime = extractTime(firstStop);
  const lastStopTime = extractTime(lastStop);

  const diffMinutes = (lastStopTime - firstStopTime) / 60000;
  const scheduled = formatTimeDiff(diffMinutes);

  const minutes = parseFlexibleTimeToMinutes(duration);
  const lastTopAverageTime =
    stopPoints.find((i) => Number(i.key) === Number(lastStop?.stop_point_id))?.average_waiting_time || 0;
  const minutesSubtracted = Number(minutes) - Number(lastTopAverageTime);
  const durationWithoutLastAverage = formatTimeDiff(minutesSubtracted);

  function extractTime(stop) {
    const timeStr = moment(stop.endTime || stop.startTime).format('HH:mm');
    const [hours, minutes] = timeStr.split(':').map(Number);
    return new Date(`${moment(stop.scheduled_date).format('YYYY-MM-DD')} ${hours}:${minutes}`);
  }

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <Typography variant='title1' style={{ color: palette.red400 }}>
          WARNING!
        </Typography>
      }
      $bgColor={palette.gray0}
      $maxWidth='500px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Go back',
          onClick: onClose,
        },
        {
          key: 'Acknowledge',
          type: 'primary',
          title: 'Acknowledge',
          onClick: onSuccess,
        },
      ]}
    >
      <div className='d-flex align-items-center justify-content-center'>
        <Typography variant='b2'>
          You have scheduled this shipment for pick up on {formatDateTime(firstStopTime)} and final delivery on{' '}
          {formatDateTime(lastStopTime)} which is total scheduled duration {scheduled} but this route requires at
          minimum {durationWithoutLastAverage}!
        </Typography>
      </div>
    </Modal>
  );
};

export default ScheduledWarning;
