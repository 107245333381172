import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { useDropzone } from 'react-dropzone';
import camIcon from 'assets/icons/icon.png';
import useShowToaster from 'hooks/useShowToaster';
import CustomButton from 'components/CustomButton/CustomButton';

// webcam
const videoConstraints = {
  width: 280,
  height: 280,
  left: 0,
  right: 50,
  facingMode: 'user',
};

const SecondStep = ({ nextStep, imgFile, setImgFile, setBlobToFile }) => {
  const showToaster = useShowToaster();
  const [previewScreenshot, setPreviewScreenshot] = useState(null);
  const [camOpen, setCamOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const webcamRef = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/png', 'image/jpeg'],
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setImgFile(acceptedFiles[0]);
      setPreviewScreenshot(null);
    },
    maxSize: 10485760,
    onDropRejected: (fileRejections) => {
      if (fileRejections?.[0]?.errors?.[0]?.code === 'file-too-large') {
        showToaster({ type: 'error', message: 'File is larger than 10MB' });
      }
    },
  });

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    const blob = await fetch(imageSrc).then((res) => res.blob());
    const f = new FormData();
    f.append('som', blob);
    if (imageSrc) {
      setBlobToFile(f.get('som'));
      setPreviewScreenshot(imageSrc);
      setImgFile(null);
    }
    setCamOpen(false);
  }, [webcamRef]);

  const thumbs = () => {
    return (
      <img
        key={imgFile}
        src={imgFile?.preview && imgFile !== null ? imgFile.preview : previewScreenshot}
        alt='icon'
        style={{ width: '100px', height: '100px', borderRadius: '50%' }}
      />
    );
  };

  const cameraModal = () => {
    document.addEventListener('keyup', (event) => {
      if (event.code === 'Space') {
        capture();
      }
    });
    return (
      <>
        <Webcam
          style={{ margin: '0 auto' }}
          audio={false}
          ref={webcamRef}
          screenshotFormat='image/jpeg'
          videoConstraints={videoConstraints}
        />
        <button className='camera-btn-click btn' onClick={capture}>
          Capture
        </button>
      </>
    );
  };

  return (
    <>
      <div className='modal-header-custom'>
        <h1 className='welcome-bg'>Upload Profile Picture </h1>
        <p className='welcome-label'>
          Please upload profile picture to identify your account. This will appear to other staff members.
        </p>
      </div>

      <div className='modal-body-custom' style={{ alignItems: 'start' }}>
        {camOpen ? (
          cameraModal()
        ) : (
          <div className='upload'>
            <div {...getRootProps({ className: 'dropzone' })} className='camera'>
              <input {...getInputProps()} name='profile_logo' />
              {files.length !== 0 || previewScreenshot !== null ? thumbs() : <img alt='camera' src={camIcon} />}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
              }}
            >
              <div className='btn-group-cam'>
                <button {...getRootProps({ className: 'dropzone' })} className='upload-btn btn'>
                  Upload
                </button>
                <p className='camera-btn btn' onClick={() => setCamOpen(true)}>
                  Camera
                </p>
              </div>
              <div>
                <p className='file-names'>PNG, JPG up to 1MB</p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='modal-footer-custom'>
        {camOpen ? (
          <button className='button-footer-resend btn' onClick={() => setCamOpen(false)}>
            {' '}
            Cancel{' '}
          </button>
        ) : (
          <div className='d-flex gap-3'>
            <CustomButton
              type='secondary'
              title='Skip'
              styleButton={{ margin: 0, padding: '4px 12px' }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              onClick={nextStep}
            />
            <CustomButton
              type='primary'
              title='Continue'
              styleButton={{ margin: 0, padding: '4px 12px' }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              onClick={nextStep}
              disabled={!(files.length !== 0 || previewScreenshot !== null)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SecondStep;
