import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import React from 'react';

import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import CustomButton from 'components/CustomButton/CustomButton';
import { AddonsCancel } from 'Api/Billing';
import useDateFormat from 'hooks/useDateFormat';
import styles from './AddonsCancelModal.module.css';

const AddonsCancelModal = ({ data, show, onClose, getServices }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  const onCancelAddOn = () => {
    AddonsCancel(data?.id).then((res) => {
      if (res) {
        getServices();
        onClose();
      }
    });
  };

  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      backdropClassName={styles.backDropModal}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Cancel Add-On</Typography>
        </div>
        <div className={styles.body_wrapper}>
          <div className={styles.bottom_line}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Are you sure you want to cancel Add-On “
              <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {data?.addon_plan?.addon?.name}
              </Typography>
              ”? If cancel Add-On now you won’t be able to use the services from{' '}
              <span>{formatDate(data?.created_at)}</span>.
            </Typography>
          </div>
        </div>
        <div className={styles.footer_wrapper}>
          <CustomButton
            type='secondary'
            title='Nevermind'
            leftIcon={false}
            styleButton={{ padding: '2px 6px', margin: 0 }}
            onClick={onClose}
          />
          <CustomButton
            type='danger'
            title='Yes, Cancel Add-On'
            leftIcon={false}
            styleButton={{ padding: '2px 6px', margin: 0 }}
            onClick={onCancelAddOn}
          />
        </div>
      </div>
    </Modal>
  );
};
export default AddonsCancelModal;
