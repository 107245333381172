export const confirmationShipment = {
  shipment_progress_bar: null,
  shipment_id: '251-S3',
  assigned_to1: null,
  assigned_to2: null,
  status_id: '1',
  shipment_type: 'split-children',
  dispatch_type: 'external_carrier',
  driver_from_gps_lat: '50.036217',
  driver_from_gps_long: '-125.283955',
  driver_from_city: 'Campbell River',
  driver_from_state: 'BC',
  next_stop: '1',
  eta: '52h 27m',
  total_eta: '53h 2m',
  shipment_drive_time: '0h 35m',
  deadhead_drive_time: '52h 27m',
  weight: '1231',
  empty_miles: 2622.51,
  loaded_miles: 29.68,
  planned_duration: '0d 0h 32m',
  miles_remaining: '2622.511',
  driver_amount: '33',
  staff_amount: null,
  driver1_amount: '0',
  driver2_amount: null,
  estimated_date_time_arrival: '2023-11-30 11:16:17',
  total_estimated_date_time_final: '2023-11-30 11:51:17',
  total_miles_remain: '2652.187',
  driver_acknowledged: 0,
  is_edi_api: 0,
  api_name: null,
  edi_api_doc_id: null,
  edi_timelimit_date_time: null,
  accept_date: null,
  driver_confirm_date: null,
  start_date_time: null,
  tonu_date_time: null,
  created_at: '2023-11-28T11:49:17.000000Z',
  last_hooked_equipment_id: null,
  lane_id: null,
  stops_count: 2,
  actual_duration: null,
  duration_diff: null,
  end_date_time: null,
  delay_reason: [],
  delay_reason_required: false,
  planned_count: 10,
  bill_of_lading_count: 0,
  communication_error: 0,
  confirmation_document: null,
  brokerage_dispatch: {
    id: 16,
    shipment_id: 'AB5778945743',
    carrier_id: 90,
    vehicle_id: null,
    vehicle_type_id: 1,
    vehicle_length_id: null,
    trailer_id: null,
    trailer_type_id: 12,
    trailer_length_id: 9,
    driver_name: 'driver',
    driver_phone_number: null,
    contact_id: 33,
    revise_confirmation: 0,
    confirmation_signed: 0,
    carrier: {
      id: 90,
      name: 'Midwest Freight',
      dot: '2341232',
      mc: '232332',
      phone_number: '+1234243243',
      email: 'gorgrigoryan1997+102@gmail.com',
      address1: '123 Street',
      address2: null,
      zipcode: '56379',
      city: {
        id: 45361,
        name: 'Warren',
        state_id: 3946,
      },
      state: {
        id: 3946,
        name: 'Michigan',
        short_name: 'MI',
        country_id: 231,
      },
      country: {
        id: 231,
        sortname: 'US',
        name: 'United States',
        phonecode: 1,
      },
      all_states_usa: false,
      all_states_canada: false,
      all_states_mexico: false,
    },
    vehicle_type: {
      id: 1,
      title: 'Truck',
      vehicle_type_id: 1,
      mph: '50',
      edi_type: null,
      edi_description: 'TR',
      created_at: null,
      updated_at: null,
    },
    vehicle_length: null,
    trailer_type: {
      id: 12,
      title: 'Dry Van',
      vehicle_type_id: 2,
      mph: null,
      edi_type: 'ST',
      edi_description: 'TF',
      created_at: null,
      updated_at: null,
    },
    trailer_length: {
      id: 9,
      length: 53,
      unit: 'ft',
      created_at: '2023-06-07T08:06:53.000000Z',
      updated_at: '2023-06-07T08:06:53.000000Z',
    },
    contact_user: {
      id: 33,
      carrier_id: 90,
      contact_name: 'Jim Carrey',
      contact_email: 'jim@example.com',
      phone_number: '+14234234242',
      contact_type_id: 1,
      portal_access: 0,
      email_updates: 0,
      sms_updates: 0,
      created_at: '2023-11-23T16:46:49.000000Z',
      updated_at: '2023-11-30T18:33:36.000000Z',
    },
  },
  shipment_notes: [{ title: 'Confirmation Note', notes: 'Test Note' }],
  shipment_stops: [
    {
      id: 4090,
      shipment_id: '251-S3',
      stop_point_type: '1',
      bill_type: '2',
      scheduled_date: '2023-08-01',
      scheduled_type: 1,
      from: '12:00',
      to: '12:00',
      arrival_date: null,
      departure_date: null,
      dock_high: 0,
      stackable: 0,
      liftgate_service: 0,
      labor_required: 0,
      billable_move: null,
      hazardous_materials: 0,
      equipment_action: '1',
      equipment_tbd: 0,
      equipment_type_length: 9,
      number: '23232',
      quantity: 222,
      quantity_type: 1,
      dimensions: '{"l":"11","h":"11","w":"11"}',
      weight: '1231.00',
      value: 2122,
      reported_quantity: null,
      reported_weight: null,
      reported_seal_id: null,
      bill_of_lading_id: null,
      proof_of_delivery: null,
      reported_dock_status: null,
      notes_text: null,
      notes: null,
      order_in_leg: 'A',
      signature_name: null,
      leg: 1,
      distance_to_next_stop: null,
      created_at: '2023-11-17T09:38:25.000000Z',
      updated_at: '2023-11-24T10:37:19.000000Z',
      bill_of_lading: null,
      equipment_type_data: {
        id: 12,
        title: 'Dry Van',
        vehicle_type_id: 2,
        mph: null,
        edi_type: 'ST',
        edi_description: 'TF',
        created_at: null,
        updated_at: null,
      },
      commodity_type: {
        id: 1,
        title: 'Articles of metal',
        default: 1,
        created_at: '2023-06-20T12:55:56.000000Z',
        updated_at: '2023-06-20T12:55:56.000000Z',
      },
      stop_point: {
        id: 1,
        location_name: 'AAA Products',
        address1: '1616 Oak St.',
        address2: null,
        zipcode: '12345',
        geofencing_latitude: '42.580339',
        geofencing_longitude: '-83.416096',
        average_waiting_time: '1209.4927113703',
        country: {
          id: 231,
          sortname: 'US',
          name: 'United States',
          phonecode: 1,
        },
        city: {
          id: 45258,
          name: 'Anytown',
          state_id: 3945,
        },
        state: {
          id: 3945,
          name: 'Michigan',
          short_name: 'MI',
          country_id: 231,
        },
        primary_contact_book: {
          id: 1,
          stop_point_id: 1,
          name: 'Al Cer',
          email: 'alceric@yopmail.com',
          phone_number: '+12489890805',
          contact_type_id: 1,
          portal_access: 0,
          email_updates: 1,
          sms_updates: 1,
          created_at: '2023-06-20T14:40:56.000000Z',
          updated_at: '2023-06-20T14:40:56.000000Z',
        },
      },
      reported_weight_type: {
        id: 1,
        unit: 'LBS',
      },
      equipment_type_length_value: {
        id: 9,
        length: 53,
        unit: 'ft',
        created_at: '2023-06-07T08:06:53.000000Z',
        updated_at: '2023-06-07T08:06:53.000000Z',
      },
      slave_stop: null,
    },
    {
      id: 4091,
      shipment_id: '251-S3',
      stop_point_type: '2',
      bill_type: '2',
      scheduled_date: '2023-08-01',
      scheduled_type: 1,
      from: '13:00',
      to: '13:00',
      arrival_date: null,
      departure_date: null,
      dock_high: 0,
      stackable: null,
      liftgate_service: null,
      labor_required: 0,
      billable_move: null,
      hazardous_materials: null,
      equipment_action: '1',
      equipment_tbd: 0,
      equipment_type_length: null,
      number: '3121',
      quantity: 1000,
      quantity_type: 1,
      dimensions: '{"l":"22","h":"22","w":"22"}',
      weight: null,
      value: 0,
      reported_quantity: null,
      reported_weight: null,
      reported_seal_id: null,
      bill_of_lading_id: null,
      proof_of_delivery: null,
      reported_dock_status: null,
      notes_text: null,
      notes: null,
      order_in_leg: 'B',
      signature_name: null,
      leg: 1,
      distance_to_next_stop: null,
      created_at: '2023-11-17T09:38:25.000000Z',
      updated_at: '2023-11-24T10:37:19.000000Z',
      bill_of_lading: null,
      stop_point: {
        id: 2,
        location_name: 'ABC Service',
        address1: '1515 Water St',
        address2: null,
        zipcode: '52469',
        geofencing_latitude: '42.458222359086',
        geofencing_longitude: '-83.003160766726',
        average_waiting_time: '274.11333333333',
        country: {
          id: 231,
          sortname: 'US',
          name: 'United States',
          phonecode: 1,
        },
        city: {
          id: 45254,
          name: 'Anytown',
          state_id: 3945,
        },
        state: {
          id: 3945,
          name: 'Michigan',
          short_name: 'MI',
          country_id: 231,
        },
        primary_contact_book: {
          id: 2,
          stop_point_id: 2,
          name: 'Joe',
          email: 'joe@yopmail.com',
          phone_number: '+13135130257',
          contact_type_id: 1,
          portal_access: 0,
          email_updates: 1,
          sms_updates: 1,
          created_at: '2023-06-20T14:42:19.000000Z',
          updated_at: '2023-06-20T14:42:19.000000Z',
        },
      },
      reported_weight_type: {
        id: 1,
        unit: 'LBS',
      },
      equipment_type_length_value: null,
      slave_stop: null,
    },
  ],
  status: {
    id: 1,
    title: 'Dispatched',
  },
  shipment_billing: [
    {
      id: 1662,
      shipment_id: '251-S3',
      reference_id: '4212',
      contact_user_id: 44,
      total_amount: 464,
      currency_id: 1,
      customer_billed: 0,
      age: null,
      net: '431.00',
      customer_payment_date: null,
      margin: 92.89,
      customer_billed_date: null,
      paperwork_received: 0,
      open_balance: 464,
      billing_charges: [
        {
          id: 2126,
          billing_id: 1662,
          charge_type: 1,
          quantity: 2,
          rate: 232,
          sub_total: 464,
          currency_id: 1,
          created_at: '2023-11-17T09:38:25.000000Z',
          updated_at: '2023-11-17T09:38:25.000000Z',
          charge_type_name: {
            id: 1,
            title: 'Linehaul',
            code: '4000',
            desc: null,
            default: 1,
            is_taxable: 0,
            tax_percentage: null,
            tax_account: null,
            account: {
              id: 1,
              account_type_id: 1,
              account_code: '400',
              account_name: 'Transportation Revenue',
              cost_type_id: 0,
              account_number: null,
              account_bank_name: null,
              account_routing: null,
              account_starting_check: null,
              account_balance: 661394,
              description: 'Default system account',
              account_status: 1,
              default: 1,
              stripe_fca_id: null,
              access_token: null,
              stripe_account_id: null,
              stripe_bank_id: null,
              system_account_type: 0,
              created_at: '2023-06-20T12:56:02.000000Z',
              updated_at: '2023-11-30T08:31:12.000000Z',
              is_truckindigital: 0,
              interest_rate: null,
              loan_term: null,
              loan_amount: null,
              is_connected: 0,
              loan_term_type: null,
              compounding: null,
            },
            type: {
              id: 1,
              account_type: 'Income',
              default: 1,
              created_at: '2023-06-20T12:56:02.000000Z',
              updated_at: '2023-06-20T12:56:02.000000Z',
            },
          },
        },
      ],
      billing_customer: {
        id: 1,
        customer_id: 'JACK',
        company_name: 'Jack Metals Inc',
        customer_payment_term: {
          id: 1,
          customer_id: 1,
          invoice_pay_term: '16',
          account_id: 1,
          send_invoice_to_factoring: '1',
          invoice_note: 'test notest for customer cash',
          require_signature_on_stop: 1,
          require_bill_of_lading: 1,
          require_confirmations: 1,
          detention_starts_after_hour: '1',
          detention_trailer_dropped_starts_after: '1',
          detention_trailer_dropped_start_after_per: 'HOURS',
          detention_trailer_dropped_billed_at: '100',
          invoice_itemized: 0,
          billed_at: '200',
          credit_line_maximum: '600000',
          pay_term_types: {
            id: 16,
            title: '7 Days (Discounted)',
            discount: '10',
            default: 0,
            net_day: '7',
            created_at: '2023-10-03T14:45:19.000000Z',
            updated_at: '2023-10-03T14:45:19.000000Z',
          },
        },
      },
      groups: null,
      contact_user: {
        id: 44,
        customer_id: 1,
        contact_name: 'John Doe',
        contact_email: 'jdoe@example.com',
        phone_number: '+15551234567',
        contact_type_id: 1,
        portal_access: 0,
        email_updates: 1,
        sms_updates: 1,
      },
      slave_billing: null,
      payment_term: null,
    },
  ],
  dispatched_by_user: {
    id: 1964,
    first_name: 'John',
    last_name: 'Smith',
    phone_number: '+15555551234',
  },
  detentions: [],
  accepted_by: null,
  assigned_to_1: {
    fname: 'driver',
    lname: null,
    phone_number: null,
  },
  assigned_to_2: null,
  shipment_route_direction: {
    id: 995,
    shipment_id: '251-S3',
    route:
      '[{"lng":"-83.416096","lat":"42.580339","stopPoint":true},{"lng":"-83.003160766726","lat":"42.458222359086","stopPoint":true}]',
    created_at: '2023-11-17T09:38:25.000000Z',
    updated_at: '2023-11-17T09:38:25.000000Z',
  },
  shipment_route: {
    id: 1462,
    shipment_id: '251-S3',
    vehicle_type: 0,
    route_type: 'practical',
    open_borders: 0,
    highway_only: 0,
    hazmat_id: 0,
    avoid_tolls: 0,
    created_at: '2023-11-17T09:38:25.000000Z',
    updated_at: '2023-11-17T09:38:25.000000Z',
  },
  shipment_option: {
    id: 791,
    shipment_id: '251-S3',
    customer_contact_book: null,
  },
  next_stop_info: {
    id: 1,
    location_name: 'GRASSLAND',
    address1: '6777 Grassland Ave',
    address2: null,
    zipcode: '48324',
    geofencing_latitude: '42.580339',
    geofencing_longitude: '-83.416096',
    average_waiting_time: '1209.4927113703',
    country: {
      id: 231,
      sortname: 'US',
      name: 'United States',
      phonecode: 1,
    },
    city: {
      id: 45258,
      name: 'West Bloomfield Township',
      state_id: 3945,
    },
    state: {
      id: 3945,
      name: 'Michigan',
      short_name: 'MI',
      country_id: 231,
    },
    primary_contact_book: {
      id: 1,
      stop_point_id: 1,
      name: 'Al Cer',
      email: 'alceric@yopmail.com',
      phone_number: '+12489890805',
      contact_type_id: 1,
      portal_access: 0,
      email_updates: 1,
      sms_updates: 1,
      created_at: '2023-06-20T14:40:56.000000Z',
      updated_at: '2023-06-20T14:40:56.000000Z',
    },
  },
  created_by: {
    id: 1966,
    first_name: 'Chirag',
    last_name: 'Sondagar',
    phone_number: '+918460808665',
  },
  carrier_gps_latest: null,
  carrier_pay: [
    {
      id: 2,
      shipment_id: '251-S3',
      carrier_id: 90,
      type: '55',
      description: 'Carrier Linehaul',
      quantity: 1,
      rate: 100,
      total: 100,
      created_at: '2023-11-28T11:49:17.000000Z',
      updated_at: '2023-11-28T11:49:17.000000Z',
      type_name: { id: 55, title: 'Carrier Linehaul', code: 'B4000' },
    },
    {
      id: 3,
      shipment_id: '251-S3',
      carrier_id: 90,
      type: '6',
      description: 'Carrier Linehaul',
      quantity: 1,
      rate: -100,
      total: -100,
      created_at: '2023-11-28T12:08:41.000000Z',
      updated_at: '2023-11-30T15:14:57.000000Z',
      type_name: { id: 55, title: 'Carrier Linehaul', code: 'B4000' },
    },
    {
      id: 4,
      shipment_id: '251-S3',
      carrier_id: 90,
      type: '4',
      description: 'Carrier Linehaul',
      quantity: 1,
      rate: 33,
      total: 33,
      created_at: '2023-11-28T12:08:50.000000Z',
      updated_at: '2023-11-28T12:08:50.000000Z',
      type_name: { id: 55, title: 'Carrier Linehaul', code: 'B4000' },
    },
  ],
  equipment: {
    equipment_id: null,
    equipment_type_id: 1,
    equipment_type: {
      id: 1,
      title: 'Truck',
      vehicle_type_id: 1,
      mph: '50',
      edi_type: null,
      edi_description: 'TR',
      created_at: null,
      updated_at: null,
    },
    length: null,
    hooked_to: {
      equipment_id: null,
      equipment_type_id: 12,
      equipment_type: {
        id: 12,
        title: 'Dry Van',
        vehicle_type_id: 2,
        mph: null,
        edi_type: 'ST',
        edi_description: 'TF',
        created_at: null,
        updated_at: null,
      },
      length: {
        id: 9,
        length: 53,
        unit: 'ft',
        created_at: '2023-06-07T08:06:53.000000Z',
        updated_at: '2023-06-07T08:06:53.000000Z',
      },
    },
    location_target: 'carrier',
  },
};

export const pickupStops = [
  {
    quantity: 10,
    weight: '1000.00',
    stop_point: {
      location_name: 'GRASSLAND',
    },
  },
];
