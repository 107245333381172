import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SFlexColumn } from '../HoursTracked.styles';

const EndLocation = ({ data }) => {
  const { use } = useTheme();

  return (
    <SFlexColumn>
      {data?.end_time_street && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          {data?.end_time_street},
        </Typography>
      )}
      {data?.end_time_city && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          {data?.end_time_city}, {data?.end_time_state_code},
        </Typography>
      )}
      {data?.end_time_country && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          {data?.end_time_country}
        </Typography>
      )}
      {!data?.end_time_street && !data?.end_time_city && !data?.end_time_country && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          -
        </Typography>
      )}
    </SFlexColumn>
  );
};

export default EndLocation;
