import moment from 'moment';
import { getTaxAmount, twoDecimals } from './helpers';

const transactionType = {
  1: 'Fees',
  2: 'CASH ADVANCE',
  3: 'Fuel',
};

export const oneTimeAdditionType = {
  1: 'Layover',
  2: 'Stop Off',
  3: 'Truck Order Not Used',
};

export const updateDeductionsConverter = (data, user_type, user_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    deduction_id: [],
    date: [],
    time: [],
    deduction_type_id: [],
    quantity: [],
    per_quantity_amount: [],
    amount: [],
    every_settlement: [],
    month_week_settlement: [],
    day: [],
    month_week: [],
    amortized_settlement: [],
    amortized_total: [],
    amortized_month: [],
    amortized_months: [],
    one_time_deduction: [],
  };

  data.forEach((item) => {
    if (!item?.deduction_type?.id) {
      return;
    }
    body.deduction_id.push(item.isNew ? 'new' : item.id);
    body.date.push(item.date ? moment(item.date).format('YYYY-MM-DD') : '');
    body.time.push(item.time || '');
    body.deduction_type_id.push(item.deduction_type.id);
    body.quantity.push(item.quantity ? Number(item.quantity) : 0);
    body.per_quantity_amount.push(item.per_quantity_amount ? Number(item.per_quantity_amount) : 0);
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.every_settlement.push(item.every_settlement ? 1 : 0);
    body.month_week_settlement.push(item.month_week_settlement ? 1 : 0);
    body.day.push(item.day);
    body.month_week.push(item.month_week);
    body.amortized_settlement.push(item.amortized_settlement ? 1 : 0);
    body.one_time_deduction.push(item.one_time_deduction ? 1 : 0);
    body.amortized_total.push(item.amortized_total ? Number(item.amortized_total) : 0);
    body.amortized_month.push(item.amortized_month ? Number(item.amortized_month) : 0);
    body.amortized_months.push(item.amortized_months ? Number(item.amortized_months) : 0);
  });

  switch (user_type) {
    case 1:
      body.staff_id = user_id;
      break;
    case 2:
      body.driver_id = user_id;
      break;
    default:
  }

  return body;
};

export const updateAdditionsConverter = (data, user_type, user_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    addition_id: [],
    date: [],
    time: [],
    addition_type_id: [],
    quantity: [],
    per_quantity_amount: [],
    amount: [],
    every_settlement: [],
    month_week_settlement: [],
    day: [],
    month_week: [],
    one_time_addition: [],
  };

  data.forEach((item) => {
    if (!item?.addition_type?.id) {
      return;
    }
    body.addition_id.push(item.isNew ? 'new' : item.id);
    body.date.push(item.date ? moment(item.date).format('YYYY-MM-DD') : '');
    body.time.push(item.time || '');
    body.addition_type_id.push(item.addition_type.id);
    body.quantity.push(item.quantity ? Number(item.quantity) : 0);
    body.per_quantity_amount.push(item.per_quantity_amount ? Number(item.per_quantity_amount) : 0);
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.every_settlement.push(item.every_settlement ? 1 : 0);
    body.month_week_settlement.push(item.month_week_settlement ? 1 : 0);
    body.one_time_addition.push(item.one_time_addition ? 1 : 0);
    body.day.push(item.day);
    body.month_week.push(item.month_week);
  });

  switch (user_type) {
    case 1:
      body.staff_id = user_id;
      break;
    case 2:
      body.driver_id = user_id;
      break;
    default:
  }

  return body;
};

export const updateTaxesConverter = (data, user_type, user_id, gross_revenue) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    tax_id: [],
    date: [],
    time: [],
    tax_type_id: [],
    percent: [],
    auto_deduct: [],
    amount: [],
  };

  data.forEach((item) => {
    if (!item?.tax_type?.id) {
      return;
    }
    body.tax_id.push(item.isNew ? 'new' : item.id);
    body.date.push(item.date ? moment(item.date).format('YYYY-MM-DD') : '');
    body.time.push(item.time || '');
    body.tax_type_id.push(item.tax_type.id);
    body.percent.push(item.percent ? Number(item.percent) : 0);
    body.amount.push(item.percent ? (((Number(gross_revenue) || 0) * item.percent) / 100).toFixed(2) : 0);
    body.auto_deduct.push(item.auto_deduct ? 1 : 0);
  });

  switch (user_type) {
    case 1:
      body.staff_id = user_id;
      break;
    case 2:
      body.driver_id = user_id;
      break;
    default:
  }

  return body;
};

export const updateBenefitsConverter = (data, user_type, user_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    benefit_id: [],
    date: [],
    time: [],
    benefit_type_id: [],
    quantity: [],
    per_quantity_amount: [],
    amount: [],
    every_settlement: [],
    month_week_settlement: [],
    day: [],
    month_week: [],
  };

  data.forEach((item) => {
    if (!item?.benefit_type?.id) {
      return;
    }
    body.benefit_id.push(item.isNew ? 'new' : item.id);
    body.date.push(item.date ? moment(item.date).format('YYYY-MM-DD') : '');
    body.time.push(item.time || '');
    body.benefit_type_id.push(item.benefit_type.id);
    body.quantity.push(item.quantity ? Number(item.quantity) : 0);
    body.per_quantity_amount.push(item.per_quantity_amount ? Number(item.per_quantity_amount) : 0);
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.every_settlement.push(item.every_settlement ? 1 : 0);
    body.month_week_settlement.push(item.month_week_settlement ? 1 : 0);
    body.day.push(item.day);
    body.month_week.push(item.month_week);
  });

  switch (user_type) {
    case 1:
      body.staff_id = user_id;
      break;
    case 2:
      body.driver_id = user_id;
      break;
    default:
  }

  return body;
};

export const updateDetentionConverter = (data, settlement_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    settlement_id,
    shipment_stop: [],
    date: [],
    time: [],
    apply: [],
    amount: [],
    shipment_id: [],
  };

  data.forEach((item) => {
    body.shipment_stop.push(item.shipment_stop_id);
    body.date.push(item.date ? moment(item.date).format('YYYY-MM-DD hh:mm:ss') : '');
    body.time.push(item.time || '');
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.apply.push(item.apply ? 1 : 0);
    body.shipment_id.push(item.shipment_id);
  });

  return body;
};

export const updateOvertimeConverter = (data, settlement_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    settlement_id,
    time_tracker: [],
    date: [],
    time: [],
    apply: [],
    amount: [],
    shipment_id: [],
  };

  data.forEach((item) => {
    body.time_tracker.push(item.time_tracker);
    body.date.push(item.date ? moment(item.date).format('YYYY-MM-DD') : '');
    body.time.push(item.time || '');
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.apply.push(item.apply ? 1 : 0);
    body.shipment_id.push(item.shipment_id);
  });

  return body;
};

export const updatePtoConverter = (data, settlement_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    settlement_id,
    driver_absence_id: [],
    start_date: [],
    end_date: [],
    apply: [],
    amount: [],
    days: [],
  };

  data.forEach((item) => {
    body.driver_absence_id.push(item.driver_absence_id);
    body.start_date.push(item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : '');
    body.end_date.push(item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : '');
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.apply.push(item.apply ? 1 : 0);
    body.days.push(item.days);
  });

  return body;
};

export const updateTransactionsConverter = (data, settlement_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    settlement_id,
    transaction_id: [],
    type: [],
    amount: [],
    apply: [],
  };

  data.forEach((item) => {
    body.transaction_id.push(item.transaction_id);
    body.type.push(transactionType[item.type]);
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.apply.push(item.apply ? 1 : 0);
  });

  return body;
};

export const updateOneTimeAdditionsConverter = (data, settlement_id) => {
  if (!data?.length) {
    return [];
  }

  const body = {
    settlement_id,
    driver_id: 0,
    id: [],
    addition_type_id: [],
    amount: [],
    apply: [],
    shipment_id: [],
    layover_id: [],
  };

  data.forEach((item) => {
    body.id.push(item.id);
    body.addition_type_id.push(item.addition_type_id);
    body.amount.push(item.amount ? Number(item.amount) : 0);
    body.apply.push(item.apply ? 1 : 0);
    body.shipment_id.push(item.shipment_id);
    body.layover_id.push(item.layover_id || null);

    if (item.driver_id) {
      body.driver_id = item.driver_id;
    }
  });

  return body;
};

export const updateShipmentConverter = (shipment) => {
  return {
    shipment_id: shipment.shipment_id,
    empty_miles: Number(shipment.empty_miles) || 0,
    loaded_miles: Number(shipment.loaded_miles) || 0,
  };
};

export const updateDriverPayConverter = (driverPay) => {
  return {
    shipment_id: driverPay.shipment_id,
    driver_id: driverPay.driver_id,
    type: driverPay.type,
    quantity: driverPay.quantity,
    rate: driverPay.rate,
    total: driverPay.total,
    addition_id: driverPay.addition_id,
  };
};

export const absencesConverter = (data) => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    const amount = Number(Number(item.amount).toFixed(2));
    return { ...item, amount, type: 'Paid Time Off (PTO)' };
  });
};

export const shipmentsConverter = (settlement, user_id) => {
  const { shipments, user, user_type } = settlement || {};
  const { each_booked_shipment, flat_or_per, flat_amt, percentage } = user?.compensation_details || {};

  if (user_type === 1 && !each_booked_shipment) {
    return [];
  }

  return (shipments || []).map((item) => {
    const assigned_user_num = user_id === item.assigned_to1 ? '1' : '2';
    const driver_amount = item[`driver${assigned_user_num}_compensation`];
    const loaded_miles = Math.round(item.loaded_miles);
    const empty_miles = Math.round(item.empty_miles);
    const amount =
      flat_or_per === 1 ? Number(flat_amt || 0) : (Number(item.total_amount || 0) * Number(percentage || 0)) / 100;

    return {
      ...item,
      assigned_user_num,
      driver_amount: Number(driver_amount).toFixed(2),
      loaded_miles,
      empty_miles,
      amount,
    };
  });
};

export const commissionsConverter = (commissions) => {
  return (commissions || []).map((item) => {
    const pay_type = Number(item.pay_type);

    return { ...item, pay_type };
  });
};

export const deductionsConverter = (data, settlement_id) => {
  const convertedData = [];

  data.forEach((item) => {
    const settlement =
      !!item?.settlement?.length && item.settlement.find((settlement) => settlement.settlement_id === settlement_id);

    if (!settlement || (item.is_balance_deduction && (item.one_time_deduction || !item.balance_id))) {
      return;
    }
    convertedData.push({
      ...item,
      quantity: Number(item.quantity) || 0,
      per_quantity_amount: item.is_balance_deduction
        ? Number(settlement.amount)
        : Number(item.per_quantity_amount) || 0,
      apply: !!settlement,
      disputed: !!settlement?.disputed,
      amount: item.is_balance_deduction ? Number(settlement.amount) : item.amount,
    });
  });

  return convertedData;
};

export const convertBalanceDetails = (balance) => {
  const settlements = balance?.deductions?.[0]?.settlement || [];
  let remainingBalance = balance.original_balance;

  const convertedSettlements = settlements.map((settlement) => {
    remainingBalance -= Number(settlement.amount);

    return {
      ...settlement,
      remainingBalance,
    };
  });

  return {
    ...balance,
    deductions: [{ settlement: convertedSettlements }],
  };
};

export const additionsConverter = (data, settlement_id) => {
  const convertedData = [];

  data.forEach((item) => {
    const settlement =
      !!item?.settlement?.length && item.settlement.find((settlement) => settlement.settlement_id === settlement_id);

    if (item.one_time_addition && !settlement) {
      return;
    }
    convertedData.push({
      ...item,
      quantity: Number(item.quantity) || 0,
      per_quantity_amount: Number(item.per_quantity_amount) || 0,
      apply: !!settlement,
      disputed: !!settlement?.disputed,
    });
  });

  return convertedData;
};

export const taxesConverter = (data, settlement) => {
  const convertedData = [];

  data.forEach((item) => {
    const appliedSettlement =
      !!item?.settlement?.length && item.settlement.find((s) => s.settlement_id === settlement.id);

    convertedData.push({
      ...item,
      apply: !!appliedSettlement,
      disputed: !!appliedSettlement?.disputed,
    });
  });

  return convertedData;
};

export const benefitsConverter = (data, settlement_id) => {
  const convertedData = [];

  data.forEach((item) => {
    const settlement =
      !!item?.settlement?.length && item.settlement.find((settlement) => settlement.settlement_id === settlement_id);

    convertedData.push({
      ...item,
      quantity: Number(item.quantity) || 0,
      per_quantity_amount: Number(item.per_quantity_amount) || 0,
      apply: !!settlement,
      disputed: !!settlement?.disputed,
    });
  });

  return convertedData;
};

export const getApplied = (data, newAddedData, additionalData) => {
  const { additions, deductions, gross } = additionalData || {};
  const newIds = [];
  const newItems = (data || []).filter((i) => i.isNew);
  newItems.forEach((item, index) => {
    if (item.apply) {
      newIds.push({
        id: newAddedData[index].id,
        amount: additionalData ? getTaxAmount({ tax: item, deductions, additions, gross }) : newAddedData[index].amount,
      });
    }
  });

  const ids = data.reduce((acc, cur) => {
    if (cur.apply && !cur.isNew) {
      acc.push({
        id: cur.id,
        amount: additionalData ? getTaxAmount({ tax: cur, deductions, additions, gross }) : cur.amount,
      });
    }
    return acc;
  }, []);

  return [...ids, ...newIds];
};

export const getNotApplied = (data) => {
  return data.reduce((acc, cur) => {
    if (!cur.apply && !cur.isNew) {
      acc.push(cur.id);
    }
    return acc;
  }, []);
};

export const settlementConverter = (data) => {
  const gross = twoDecimals(data.gross);

  return {
    ...data,
    gross,
  };
};

export const detentionConverter = (data) => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    const amount = Number(Number(item.amount).toFixed(2));

    return { ...item, amount, type: 'Detention' };
  });
};

export const overtimeConverter = (data) => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    const amount = Number(Number(item.amount).toFixed(2));

    return { ...item, amount, type: 'Overtime Pay' };
  });
};

export const transactionsConverter = (data) => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    const amount = Number(Number(item.amount).toFixed(2));

    return {
      ...item,
      amount,
      deduction_type: 'transaction',
      apply: item.apply === 1 ? 1 : 0,
    };
  });
};

export const oneTimeAdditionsConverter = (data) => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    const amount = Number(Number(item.amount).toFixed(2));

    return {
      ...item,
      amount,
      type: 'one_time_addition',
      addition_type: item.type,
      apply: item.apply === 1 ? 1 : 0,
    };
  });
};

export const declineAllConverter = (reason, data) => {
  const {
    settlement,
    additions,
    benefits,
    deductions,
    taxes,
    absences,
    detention,
    overtime,
    shipments,
    time_trackings,
    transactions,
    oneTimeAdditions,
  } = data || {};

  const body = {
    settlement_id: settlement.id,
    dispute_id: [],
    status: [],
    reason: [],
    type: [],
    shipments: [],
    time_trackings: [],
    taxes: [],
    deductions: [],
    additions: [],
    benefits: [],
    detentions: [],
    overtimes: [],
    ptos: [],
    transactions: [],
    driver_one_time_additions: [],
    accepted_shipments: [],
    accepted_time_trackings: [],
    accepted_taxes: [],
    accepted_deductions: [],
    accepted_additions: [],
    accepted_benefits: [],
    accepted_detentions: [],
    accepted_overtimes: [],
    accepted_ptos: [],
    accepted_transactions: [],
    accepted_driver_one_time_additions: [],
    declined_shipments: [],
    declined_time_trackings: [],
    declined_taxes: [],
    declined_deductions: [],
    declined_additions: [],
    declined_benefits: [],
    declined_detentions: [],
    declined_overtimes: [],
    declined_ptos: [],
    declined_transactions: [],
    declined_driver_one_time_additions: [],
    clear_all: 1,
    clear_all_reason: reason,
  };

  [
    ...shipments,
    ...time_trackings,
    ...additions,
    ...deductions,
    ...taxes,
    ...benefits,
    ...absences,
    ...detention,
    ...overtime,
    ...transactions,
    ...oneTimeAdditions,
  ].forEach((item) => {
    if (item.disputed) {
      body.dispute_id.push(item.shipment_id || item.id);
      body.status.push(0);
      body.reason.push('');
    }
  });

  shipments.forEach((item) => {
    if (item.disputed) {
      body.shipments.push(item.shipment_id);
      body.declined_shipments.push(item.shipment_id);
      body.type.push('shipment');
    }
  });

  time_trackings.forEach((item) => {
    if (item.disputed) {
      body.time_trackings.push(item.id);
      body.declined_time_trackings.push(item.id);
      body.type.push('time_tracking');
    }
  });

  additions.forEach((item) => {
    if (item.disputed) {
      body.additions.push(item.id);
      body.declined_additions.push(item.id);
      body.type.push('addition');
    }
  });

  deductions.forEach((item) => {
    if (item.disputed) {
      body.deductions.push(item.id);
      body.declined_deductions.push(item.id);
      body.type.push('deduction');
    }
  });
  taxes.forEach((item) => {
    if (item.disputed) {
      body.taxes.push(item.id);
      body.declined_taxes.push(item.id);
      body.type.push('tax');
    }
  });
  benefits.forEach((item) => {
    if (item.disputed) {
      body.benefits.push(item.id);
      body.declined_benefits.push(item.id);
      body.type.push('benefit');
    }
  });
  absences.forEach((item) => {
    if (item.disputed) {
      body.ptos.push(item.id);
      body.declined_ptos.push(item.id);
      body.type.push('pto');
    }
  });
  detention.forEach((item) => {
    if (item.disputed) {
      body.detentions.push(item.id);
      body.declined_detentions.push(item.id);
      body.type.push('detention');
    }
  });
  overtime.forEach((item) => {
    if (item.disputed) {
      body.overtimes.push(item.id);
      body.declined_overtimes.push(item.id);
      body.type.push('overtime');
    }
  });
  transactions.forEach((item) => {
    if (item.disputed) {
      body.transactions.push(item.id);
      body.declined_transactions.push(item.id);
      body.type.push('transaction');
    }
  });
  oneTimeAdditions.forEach((item) => {
    if (item.disputed) {
      body.one_time_additions.push(item.id);
      body.declined_one_time_additions.push(item.id);
      body.type.push('one_time_addition');
    }
  });
  return body;
};

export const declineOnProcessConverter = (data) => {
  const {
    settlement,
    additions,
    benefits,
    deductions,
    taxes,
    absences,
    detention,
    overtime,
    shipments,
    time_trackings,
    transactions,
    oneTimeAdditions,
  } = data || {};

  const body = {
    settlement_id: settlement.id,
    dispute_id: [],
    status: [],
    reason: [],
    type: [],
    shipments: [],
    time_trackings: [],
    taxes: [],
    deductions: [],
    additions: [],
    benefits: [],
    detentions: [],
    overtimes: [],
    ptos: [],
    transactions: [],
    driver_one_time_additions: [],
    accepted_shipments: [],
    accepted_time_trackings: [],
    accepted_taxes: [],
    accepted_deductions: [],
    accepted_additions: [],
    accepted_benefits: [],
    accepted_detentions: [],
    accepted_overtimes: [],
    accepted_ptos: [],
    accepted_transactions: [],
    accepted_driver_one_time_additions: [],
    declined_shipments: [],
    declined_time_trackings: [],
    declined_taxes: [],
    declined_deductions: [],
    declined_additions: [],
    declined_benefits: [],
    declined_detentions: [],
    declined_overtimes: [],
    declined_ptos: [],
    declined_transactions: [],
    declined_driver_one_time_additions: [],
    clear_all: 0,
    clear_all_reason: '',
  };

  [
    ...shipments,
    ...time_trackings,
    ...additions,
    ...deductions,
    ...taxes,
    ...benefits,
    ...absences,
    ...detention,
    ...overtime,
    ...transactions,
    ...oneTimeAdditions,
  ].forEach((item) => {
    if (item.disputed) {
      body.dispute_id.push(item.shipment_id || item.id);
      body.status.push(item.dispute_declined ? 0 : 1);
      body.reason.push(item.dispute_declined_reason || '');
    }
  });

  shipments.forEach((item) => {
    if (item.disputed) {
      body.shipments.push(item.shipment_id);
      body.type.push('shipment');
      if (item.dispute_declined) {
        body.declined_shipments.push(item.shipment_id);
      }
      if (item.dispute_approved) {
        body.accepted_shipments.push(item.shipment_id);
      }
    }
  });

  time_trackings.forEach((item) => {
    if (item.disputed) {
      body.time_trackings.push(item.id);
      body.type.push('time_tracking');
      if (item.dispute_declined) {
        body.declined_time_trackings.push(item.id);
      }
      if (item.dispute_approved) {
        body.accepted_time_trackings.push(item.id);
      }
    }
  });

  additions.forEach((item) => {
    if (item.disputed) {
      body.additions.push(item.id);
      body.type.push('addition');
      if (item.dispute_declined) {
        body.declined_additions.push(item.id);
      }
      if (item.dispute_approved) {
        body.accepted_additions.push(item.id);
      }
    }
  });

  deductions.forEach((item) => {
    if (item.disputed) {
      body.deductions.push(item.id);
      body.type.push('deduction');
      if (item.dispute_declined) {
        body.declined_deductions.push(item.id);
      }
      if (item.dispute_approved) {
        body.accepted_deductions.push(item.id);
      }
    }
  });

  taxes.forEach((item) => {
    if (item.disputed) {
      body.taxes.push(item.id);
      body.type.push('tax');
      if (item.dispute_declined) {
        body.declined_taxes.push(item.id);
      }
      if (item.dispute_approved) {
        body.accepted_taxes.push(item.id);
      }
    }
  });

  benefits.forEach((item) => {
    if (item.disputed) {
      body.benefits.push(item.id);
      body.type.push('benefit');
      if (item.dispute_declined) {
        body.declined_benefits.push(item.id);
      }
      if (item.dispute_approved) {
        body.accepted_benefits.push(item.id);
      }
    }
  });

  absences.forEach((item) => {
    if (item.disputed) {
      body.ptos.push(item.id);
      body.type.push('pto');
      if (item.dispute_declined) {
        body.declined_ptos.push(item.id);
      }
      if (item.accepted_ptos) {
        body.accepted_ptos.push(item.id);
      }
    }
  });

  detention.forEach((item) => {
    if (item.disputed) {
      body.detentions.push(item.id);
      body.type.push('detention');
      if (item.declined_detentions) {
        body.declined_detentions.push(item.id);
      }
      if (item.accepted_detentions) {
        body.accepted_detentions.push(item.id);
      }
    }
  });

  overtime.forEach((item) => {
    if (item.disputed) {
      body.overtimes.push(item.id);
      body.type.push('overtime');
      if (item.declined_overtimes) {
        body.declined_overtimes.push(item.id);
      }
      if (item.accepted_overtimes) {
        body.accepted_overtimes.push(item.id);
      }
    }
  });

  transactions.forEach((item) => {
    if (item.disputed) {
      body.transactions.push(item.id);
      body.type.push('transaction');
      if (item.declined_transactions) {
        body.declined_transactions.push(item.id);
      }
      if (item.accepted_transactions) {
        body.accepted_transactions.push(item.id);
      }
    }
  });

  oneTimeAdditions.forEach((item) => {
    if (item.disputed) {
      body.one_time_additions.push(item.id);
      body.type.push('one_time_addition');
      if (item.declined_one_time_additions) {
        body.declined_one_time_additions.push(item.id);
      }
      if (item.accepted_one_time_additions) {
        body.accepted_one_time_additions.push(item.id);
      }
    }
  });

  return body;
};
