import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBodyWrapper = styled.div`
  display: flex;

  .form-section {
    width: 50%;
    padding: 24px;
  }

  .right-section {
    width: 50%;
    padding: 24px;
    background-color: ${palette.white};

    .card-icon-container {
      position: relative;

      .visa-icon {
        position: absolute;
        right: 108px;
        bottom: 42px;
      }
    }
  }

  .terms {
    margin-top: 16px;

    a {
      color: ${palette.indigo500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;

export const SCardHolders = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: default;

  :hover span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SFeesBox = styled.div`
  margin-top: 24px;
  padding: 20px 24px;
  border: 1px solid ${palette.indigo100};
  background-color: ${palette.indigo0};
  border-radius: 8px;
`;
