import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import moment from 'moment';
import Search from 'common/Search';
import Pagination from 'common/Pagination';
import { BackdropLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import ConfirmationModal from 'common/ConfirmationModal';
import Deposit from 'componentsV2/CardManagement/Deposit';
import Transfer from 'componentsV2/CardManagement/Transfer';
import Withdraw from 'componentsV2/CardManagement/Withdraw';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getAccountsList, getFcaSessionId, getPayeeList, syncBankFeed, updateAccount } from 'Api/Accounts';

import LoanSummary from './components/LoanSummary';
import AccountHeader from './components/AccountHeader';
import ImportTransactions from '../components/ImportTransactions';
import AccountTransactions from './components/AccountTransactions';
import BankAccountTransactions from './components/BankAccountTransactions';
import { SWrapper, SFiltersWrapper } from './RightSection.styles';

const RightSection = ({ account, onEdit, refreshAccountsList }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [transactions, setTransactions] = useState({ data: [] });
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [openImportCsv, setOpenImportCsv] = useState(false);
  const [openConfirmDisconnect, setOpenConfirmDisconnect] = useState(false);
  const [search, setSearch] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [payees, setPayees] = useState([]);
  const [filters, setFilters] = useState({ page: 1, itemsPerPage: 25 });
  const [refreshIndex, setRefreshIndex] = useState(Date.now());
  const [plaidToken, setPlaidToken] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const debouncedSearch = useDebounce(search, 200);

  const { account_name, account_type, account_status, is_truckindigital } = account ?? {};

  const onChangeRowPerPage = (rowPage) => {
    setFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (page) => {
    setFilters((prevState) => ({ ...prevState, page }));
  };

  const onUpdateStatus = async () => {
    setLoadingUpdate(true);
    try {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('account_status', Number(!account_status));

      await updateAccount(account.id, formData);
      showToaster({ type: 'success', message: 'Account status has been successfully updated!' });
      refreshAccountsList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const disconnectBankFeed = async () => {
    setLoadingUpdate(true);
    try {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('is_connected', 0);

      await updateAccount(account.id, formData);
      showToaster({ type: 'success', message: 'Bank feed has been successfully disconnected!' });
      setOpenConfirmDisconnect(false);
      refreshAccountsList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const onConnectBankFeed = async () => {
    if (!account.stripe_fca_id) {
      if (!ready) {
        const { data } = await getFcaSessionId({ link_customization_name: 'connect_single' });
        setPlaidToken(data.link_token);
      } else {
        openPlaid();
      }
      return;
    }

    setLoadingUpdate(true);
    try {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('is_connected', 1);

      await updateAccount(account.id, formData);
      await syncBankFeed({
        id: account.id,
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      });
      showToaster({ type: 'success', message: 'Bank feed has been successfully connected!' });
      refreshAccountsList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const connectBankFeed = async (public_token, metadata) => {
    setLoadingUpdate(true);
    try {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('access_token', public_token);
      formData.append('stripe_fca_id', metadata.account.id);
      formData.append('is_connected', 1);

      await updateAccount(account.id, formData);
      await syncBankFeed({
        id: account.id,
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      });
      showToaster({ type: 'success', message: 'Bank feed has been successfully connected!' });
      refreshAccountsList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const getPayees = async () => {
    try {
      const { data } = await getPayeeList();
      setPayees(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onExport = () => {
    if (!transactions.data?.length) {
      return;
    }

    const titles = {
      date: 'Date',
      referenceId: 'Reference ID',
      payee: 'Payee',
      account: 'Account',
      memo: 'Memo',
      payment: account_type?.id === ACCOUNT_TYPE.BANK ? 'Payment' : 'Credit',
      deposit: account_type?.id === ACCOUNT_TYPE.BANK ? 'Deposit' : 'Debit',
      balance: 'Balance',
    };
    const transactionsToExport = transactions.data.map((tr) => ({
      date: moment(tr.date).format('MM/DD/YYYY'),
      referenceId: tr.reference_id || '',
      payee: tr.payee?.name || '',
      account: tr.account?.account_name || '',
      memo: tr.memo || '',
      payment: account_type?.id === ACCOUNT_TYPE.BANK ? tr.payment || 0 : tr.credit || 0,
      deposit: account_type?.id === ACCOUNT_TYPE.BANK ? tr.deposit || 0 : tr.debit || 0,
      balance: tr.balance || 0,
    }));

    const arrayToConvert = [titles, ...transactionsToExport];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('download', `${account_name}`);
    link.href = url;
    link.click();
  };

  const onUpdateSuccess = async (updatedRow, shouldGetPayees) => {
    let newPayees = payees;

    if (shouldGetPayees) {
      const { data } = await getPayeeList();
      newPayees = data;
      setPayees(data);
    }

    const convertedData = {
      ...updatedRow,
      payee:
        (newPayees || []).find(
          (i) => Number(i.id) === Number(updatedRow.payee_id) && i.type === updatedRow.payee_type
        ) || null,
      account: (accounts || []).find((acc) => acc.id === Number(updatedRow.account)) || null,
    };

    const updatedTransactions = transactions.data.map((tr) => (updatedRow.id === tr.id ? convertedData : tr));

    setTransactions((prevState) => ({ ...prevState, data: updatedTransactions }));
    refreshAccountsList();
  };

  const onDeleteSuccess = (id) => {
    setTransactions((prevState) => ({ ...prevState, data: prevState.data.filter((i) => i.id !== id) }));
    refreshAccountsList();
  };

  const onImportSuccess = () => {
    refreshAccountsList();
    setRefreshIndex(Date.now());
  };

  const { open: openPlaid, ready } = usePlaidLink({
    onSuccess: connectBankFeed,
    token: plaidToken,
    linkCustomizationName: 'connect_single',
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([getPayees(), getAccounts()]).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (ready) {
      openPlaid();
    }
  }, [ready]);

  if (!account) {
    return null;
  }

  return (
    <SWrapper>
      <AccountHeader
        account={account}
        onEdit={onEdit}
        onExport={onExport}
        dateRange={dateRange}
        onUpdateStatus={onUpdateStatus}
        onWithdraw={() => setOpenWithdraw(true)}
        onDeposit={() => setOpenDeposit(true)}
        onTransfer={() => setOpenTransfer(true)}
        onImportCsv={() => setOpenImportCsv(true)}
        onConnectBankFeed={onConnectBankFeed}
        onDisconnectBankFeed={() => setOpenConfirmDisconnect(true)}
        csvDisabled={!transactions.data?.length}
      />
      {[ACCOUNT_TYPE.LOAN, ACCOUNT_TYPE.CREDIT_CARD].includes(account_type?.id) && account.loan_amount && (
        <LoanSummary account={account} />
      )}
      <div className='d-flex justify-content-between gap-2'>
        <SFiltersWrapper>
          <DateRangePicker
            dateRange={dateRange}
            initialRangeName='All Time'
            setDateRange={setDateRange}
            type='allTime'
          />
          <Search search={search} onChange={setSearch} className='transaction-search-input' />
        </SFiltersWrapper>
        {!!is_truckindigital && (
          <Typography
            as='div'
            variant='c1'
            className='manage-balance'
            style={{ color: palette.indigo500 }}
            onClick={() => navigate(`/manage-balance`)}
          >
            Manage Balance
          </Typography>
        )}
      </div>
      <div className='mt-4' />
      {!loading &&
        (account_type?.id === ACCOUNT_TYPE.BANK ? (
          <BankAccountTransactions
            key={account.id}
            transactions={transactions}
            setTransactions={setTransactions}
            account={account}
            payees={payees}
            accounts={accounts}
            filters={filters}
            dateRange={dateRange}
            search={debouncedSearch}
            getPayees={getPayees}
            onUpdateSuccess={onUpdateSuccess}
            onDeleteSuccess={onDeleteSuccess}
            refreshIndex={refreshIndex}
            refreshAccountsList={refreshAccountsList}
          />
        ) : (
          <AccountTransactions
            key={account.id}
            transactions={transactions}
            setTransactions={setTransactions}
            account={account}
            payees={payees}
            accounts={accounts}
            filters={filters}
            dateRange={dateRange}
            search={debouncedSearch}
            getPayees={getPayees}
            onUpdateSuccess={onUpdateSuccess}
            onDeleteSuccess={onDeleteSuccess}
            refreshIndex={refreshIndex}
            refreshAccountsList={refreshAccountsList}
          />
        ))}
      <Pagination
        data={transactions}
        rowPerPage={filters.itemsPerPage}
        onPageChange={onPageChange}
        onChangeRowPerPage={onChangeRowPerPage}
        rowsPerPageOptions={[25, 50, 100, 150]}
      />
      {openWithdraw && <Withdraw open={openWithdraw} onClose={() => setOpenWithdraw(false)} />}
      {openDeposit && <Deposit open={openDeposit} onClose={() => setOpenDeposit(false)} />}
      {openTransfer && <Transfer open={openTransfer} onClose={() => setOpenTransfer(false)} accounts={accounts} />}
      {openImportCsv && (
        <ImportTransactions
          open={openImportCsv}
          onClose={() => setOpenImportCsv(false)}
          onSuccess={onImportSuccess}
          account={account}
        />
      )}
      <ConfirmationModal
        width='510px'
        open={openConfirmDisconnect}
        onClose={() => setOpenConfirmDisconnect(false)}
        headerTitle='Disconnect Bank Feed'
        text={`Are you sure you want to disconnect bank feed for ${account_name} account?`}
        onConfirm={disconnectBankFeed}
        buttonProps={{
          type: 'primary',
          title: 'Disconnect',
        }}
      />
      <BackdropLoader loading={loadingUpdate} />
    </SWrapper>
  );
};

export default RightSection;
