import moment from 'moment';
import { endOfYear, startOfYear } from 'date-fns';

export const initialYearFilters = {
  miles_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  revenue_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  closed_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  closed_month: moment().month() + 1,
  graphReadyAndMissingYear: moment().year(),
  graphOpenReceivablesYear: moment().year(),
  graphOpenReceivablesMonth: moment().month() + 1,
};

export const statTypeRangeMapper = {
  miles: 'miles_range',
  'gross transport revenue': 'revenue_range',
  'closed receivables': 'closed_range',
};

export const initialStats = {
  pieChart: {},
  readyAndMissing: {},
  openReceivables: {},
  statsSection: {
    miles: {},
    'gross transport revenue': {},
    'closed receivables': {},
  },
};

export const transformArray = (inputArray) => {
  const outputArray = [];

  let currentMonth = '';
  let readyTotal = 0;
  let missingTotal = 0;

  for (const entry of inputArray) {
    const entryDate = new Date(entry.date);
    const entryMonth = entryDate.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (currentMonth !== entryMonth) {
      if (currentMonth !== '') {
        outputArray.push({
          month: currentMonth,
          ready: readyTotal,
          missing: missingTotal,
        });
      }

      currentMonth = entryMonth;
      readyTotal = 0;
      missingTotal = 0;
    }

    readyTotal += entry.ready.amount;
    missingTotal += entry.missing.amount;
  }

  // Push the last month's totals
  if (currentMonth !== '') {
    outputArray.push({
      month: currentMonth,
      ready: readyTotal,
      missing: missingTotal,
    });
  }

  return outputArray;
};
