import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { planAheadLane } from 'Api/Shipment';
import { getShipmentOfferSettings } from 'Api/CompanySettings';
import PlanAheadForm from 'componentsV2/Shipment/PlanAhead';
import { validationSchema } from 'componentsV2/Shipment/PlanAhead/validationSchema';
import { getInitialValues, planAheadDataConverter } from 'componentsV2/Shipment/PlanAhead/PlanAhead.data';
import { SPlanAhead } from './PlanAhead.styles';

const PlanAhead = ({ data, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [offerSettings, setOfferSettings] = useState(null);

  const { shipment_lane } = data || {};

  const onSubmit = async (values) => {
    if (
      [2, 3].includes(values.plan_ahead_type?.id) &&
      !values.immediately &&
      !values.next_in_line &&
      !values.before_scheduled_pickup
    ) {
      showToaster({ type: 'error', message: 'One of the dispatch options should be selected!' });
      return;
    }

    setLoading(true);
    try {
      const body = planAheadDataConverter(values);
      await planAheadLane(shipment_lane.id, body);
      showToaster({ type: 'success', message: 'Plan ahead options have been successfully updated!' });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(shipment_lane, offerSettings),
    onSubmit,
    validationSchema,
  });

  const getOfferSettings = async () => {
    try {
      const { data: settingsData } = await getShipmentOfferSettings();
      setOfferSettings(settingsData);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getOfferSettings();
  }, []);

  return (
    <SPlanAhead>
      <div className='plan-ahead-header'>
        <Typography variant='h4'>Plan Ahead</Typography>
        <CustomButton
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '3px 12px', margin: 0 }}
          type='primary'
          title='Save'
          onClick={form.handleSubmit}
          disabled={loading}
        />
      </div>
      <PlanAheadForm form={form} data={data} onSuccess={onSuccess} />
    </SPlanAhead>
  );
};

export default PlanAhead;
