import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SSplitSection = styled.div`
  width: 100%;
  height: 100%;

  .disabled-item {
    background-color: #f3f4f6;
    color: #9fa6b2;
    cursor: not-allowed;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    opacity: 0.5;

    &:hover {
      background-color: #f3f4f6;
    }
  }

  .optimize-stop-wrapper {
    width: 98%;
    margin-bottom: 5px;
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    transition: 0.2s all ease-in-out;

    .order-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 4px;
    }

    &.light:hover {
      background-color: ${palette.gray0};
    }
  }

  .optimize-drag-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .drag-item-left {
      display: flex;
      flex-direction: column;
    }

    .drag-item-right {
      margin-right: 14px;
    }
  }
`;
