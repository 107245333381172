import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { createIncidentType } from 'Api/CompanySettings';
import { validationSchema } from './validationSchema';

const riskLevelOptions = [
  { id: 1, name: 'Low Risk', risk_level: 'L' },
  { id: 2, name: 'High Risk', risk_level: 'H' },
];

const AddIncidentType = ({ open, onClose, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        type: values.type,
        type_for: null,
        risk_level: values.risk_level.risk_level,
      };

      const { data } = await createIncidentType(body);
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: {
      type: '',
      risk_level: null,
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Add Custom Incident'
      $bgColor={palette.gray0}
      $maxWidth='520px'
      $minWidth='520px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Add',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-4'>
        <Input
          required
          label='Incident Type'
          name='type'
          value={values.type}
          onChange={handleChange}
          error={touchedErrors.type}
        />
        <div>
          <Autocomplete
            required
            label='Risk Level'
            name='risk_level'
            value={values.risk_level}
            onChange={(e, value) => handleChange('risk_level', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={riskLevelOptions}
            error={touchedErrors.risk_level}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddIncidentType;
