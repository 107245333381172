import { object, ref, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  results: string().trim().required('Required').nullable(),
  check_date: validator
    .pastRequiredDate()
    .max(ref('next_check_date'), "Checked date can't be after next check date!")
    .nullable(),
  next_check_date: validator.futureRequiredDate().min(ref('check_date'), '').nullable(),
  attachment: string().trim().required('Required').nullable(),
  status: object()
    .shape({ value: string().required('Required') })
    .required('Required')
    .nullable(),
});
