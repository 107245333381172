import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

import { updateRowEditItem, deleteMinimizedNotes } from 'store/reducers/root.reducer';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as ShipmentIcon } from 'assets/sidemanu/shipment.svg';
import { Typography } from '../Typography';
import classes from './minimizedGlobal.module.css';
import { icons } from './MinimizedGlobal.data';

const MinimizedGlobal = () => {
  const { use, theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { minimizedNotes, menuSettings } = useSelector((state) => state?.root);
  const [cond, setCond] = useState(Infinity);
  const [small, setSmall] = useState(false);

  function onClickItem(item) {
    switch (item.label) {
      case 'Shipment-profile':
        navigate(`/shipment/${item.id}`);
        break;
      case 'accounting-account':
        navigate(`/accounting/accounts?id=${item.id}`);
        break;
      case 'update-transaction':
        navigate(`/accounting/accounts?id=${item.accountId}&transactionId=${item.transactionId}`);
        break;
      default:
        dispatch(updateRowEditItem(item));
    }
  }

  function onDeleteItem(item) {
    dispatch(deleteMinimizedNotes(item?.id));
  }

  const wrapper = useMemo(() => {
    const widthLeftMenu = !!theme.condensed && !menuSettings ? 59 : 240;
    return {
      width: `calc(100% - ${widthLeftMenu}px)`,
      transform: `translateX(${widthLeftMenu}px)`,
    };
  }, [theme, menuSettings]);

  const styleContainer = useMemo(() => {
    const innerWidth = document.documentElement.clientWidth;
    const widthLeftMenu = !!theme.condensed && !menuSettings ? 59 : 240;
    const widthBox = innerWidth - widthLeftMenu;
    const widthItems = minimizedNotes.length * 170 + 20;
    const width = widthBox / minimizedNotes.length;

    const condHideIcon = widthBox / (minimizedNotes.length * 80) < 1.1;
    const condition = widthBox / widthItems;

    setCond(condition);
    setSmall(condHideIcon);

    if (condition <= 1) {
      return {
        width: `${width}%`,
      };
    }
  }, [minimizedNotes, theme, menuSettings]);

  const renderTooltip = (props, item) => {
    return (
      <Tooltip id='button-tooltip' {...props}>
        <span>{item?.id}</span>
      </Tooltip>
    );
  };

  if (!minimizedNotes?.length) {
    return null;
  }

  return (
    <div className={classNames(classes.fixedContainer, use(classes.light, classes.dark))} style={wrapper}>
      {minimizedNotes?.map((item) => {
        return item?.name ? (
          <div
            key={item?.id}
            onClick={(e) => {
              e.stopPropagation();
              onClickItem(item);
            }}
            className={classNames(classes.itemWrapper, classes.slideDown)}
            style={{
              backgroundColor: use(palette.white, palette.dark900),
              padding: small ? '2px' : '11.25px 17.25px',
              justifyContent: !small ? 'space-between' : 'center',
              ...styleContainer,
              ...(item.styles || {}),
            }}
          >
            <div>
              {!small && (icons[item.label] || <NotesIcon />)}
              {+cond.toFixed(2) > 0.8 && (
                <Typography variant={cond <= 1 ? 'c1' : 's2'}>{item?.name ? item?.name : item?.id}</Typography>
              )}
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                onDeleteItem(item);
              }}
            >
              <DeleteIcon />
            </div>
          </div>
        ) : (
          <OverlayTrigger key={item?.id} placement='top' overlay={(props) => renderTooltip(props, item)}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                onClickItem(item);
              }}
              className={classNames(classes.itemWrapper, classes.slideDown)}
              style={{
                backgroundColor: use(palette.white, palette.dark900),
                padding: small ? '2px' : '11.25px 17.25px',
                justifyContent: !small ? 'space-between' : 'center',
                ...styleContainer,
              }}
            >
              <div>
                {!small && item.label !== 'Shipment-profile' && <NotesIcon />}
                {!small && item.label === 'Shipment-profile' && <ShipmentIcon />}

                {+cond.toFixed(2) > 0.8 && (
                  <Typography variant={cond <= 1 ? 'c1' : 's2'}>{item?.name ? item?.name : item?.id}</Typography>
                )}
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteItem(item);
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

export default MinimizedGlobal;
