import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import add from 'assets/icons/drivers/add.svg';
import { getRequestType, getDriverShipments } from 'Api/Driver';
import { staffRequestData, AddStaffRequest, UpdateStaffRequest, staffCreateAbsenceData } from 'Api/Staff';
import { palette, statusColor } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import calendar from 'assets/icons/drivers/calendar.svg';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import CustomizedSnackbars from 'components/toast/Toast';
import TableFooter from 'components/TableFooter/TableFooter';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import DateRange from 'components/DateRangePicker/DateRangePicker';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import StaffRequestAddModal from 'components/StaffRequestAddModal/StaffRequestAddModal';
import MaterialTableWrapper, { MaterialTableSort } from 'components/MaterialTableWrapper';
import StaffRequestUpdateModal from 'components/StaffRequestUpdateModal/StaffRequestUpdateModal';
import RowActions from './RowActions';
import './staffRequest.css';

const StaffRequest = ({ driverInfo, isChangeRequest, setIsChangeRequest }) => {
  const { value } = useAuth();
  const { user } = value;
  const { formatDate } = useDateFormat();
  const showToaster = useShowToaster();
  const [tab, setTab] = useState(1);
  const myTableRef = useRef(null).current;
  const [modalShow, setModalShow] = React.useState(false);
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [driverRequest, setDriverRequest] = React.useState({});
  const [rowPerPage, setRowPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [allRequest, setAllRequest] = useState([]);
  const [requestType, setRequestType] = useState([]);
  const [shipmentList, setShipmentList] = useState([]);

  const [udpdateRequestData, setUdpdateRequestData] = useState([]);
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });
  const { id } = useParams();
  const { use } = useTheme();
  const [createLoading, setCreateLoading] = useState(false);
  const [showMessage, setShowMessage] = React.useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [search, setSearch] = React.useState('');

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const createRequest = (createNotesInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);
    AddStaffRequest(createNotesInfo)
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setIsChangeRequest(!isChangeRequest);
        getRequestData();
      })
      .catch((error) => {
        showError(getErrorMessage(error));
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  const updateRequest = async (updatedRequestInfo, absencePayload) => {
    try {
      setCreateLoading(true);
      const payload = { ...updatedRequestInfo };
      delete payload.requestId;
      delete payload.index;
      const { data } = await UpdateStaffRequest(payload, updatedRequestInfo?.requestId);
      if (absencePayload) {
        await staffCreateAbsenceData({ ...absencePayload, reason_title: `Request ID - ${data.request_id}` });
      }
      setIsChangeRequest(!isChangeRequest);
      getRequestData();
      setUpdateModalShow(false);
      showToaster({ type: 'success', message: 'Request has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setCreateLoading(false);
    }
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        rowPerPage={rowPerPage}
        totalCount={driverRequest?.total}
        totalLength={driverRequest?.data?.length}
        lastPage={driverRequest?.last_page}
        currentPage={driverRequest?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setDriverRequest(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onPageChange = (event, page) => {
    setLoading(true);
    event.onChangePage(event, page - 1);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('DD-MM-YYYY');
    const endFormat = moment(end).format('DD-MM-YYYY');
    const filter = { page, itemsPerPage: rowPerPage, from_date: startFormat, to_date: endFormat, staff_id: id };
    if (search) {
      filter.query = search;
    }
    staffRequestData(filter)
      .then((res) => {
        setDriverRequest({ ...res, data: res?.request_data });
        setLoading(false);
        setIsShowDatePicker(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRequestData = useCallback(
    (searchText = '') => {
      const { start, end } = dateRange;
      const startFormat = moment(start).format('DD-MM-YYYY');
      const endFormat = moment(end).format('DD-MM-YYYY');
      const filter = {
        page: '1',
        sort: 'desc',
        from_date: startFormat,
        to_date: endFormat,
        itemsPerPage: rowPerPage,
        staff_id: id,
      };
      if (searchText) {
        filter.query = searchText;
      }
      staffRequestData(filter)
        .then((res) => {
          const tempTabs = [];
          let value = 1;
          for (const req of res?.request_data?.data || {}) {
            const hasRequestIndex = tempTabs.findIndex((tab) => tab.label === req.req_status);
            if (hasRequestIndex === -1) {
              value += 1;
              tempTabs.push({ label: req.req_status, count: 1, key: req.req_status, value });
            } else {
              tempTabs[hasRequestIndex].count += 1;
            }
          }
          tempTabs.unshift({ label: 'All', count: res?.request_data?.data?.length, key: 'all', value: 1 });
          setTabs(tempTabs);
          setDriverRequest(res?.request_data);
          setAllRequest(res?.request_data);
          setIsShowDatePicker(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setIsShowDatePicker(false);
        });
    },
    [dateRange, id, rowPerPage]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getRequestData(search);
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    getRequestData();

    getRequestType().then((res) => {
      setRequestType(res?.data);
    });

    getDriverShipments({ id }).then((res) => {
      setShipmentList(res?.data);
    });
  }, [dateRange, getRequestData]);

  const onChangeTab = (tabValue) => {
    setTab(tabValue);
    if (tabValue.value === 1) {
      setDriverRequest(allRequest);
    } else {
      const getSelectedRequest = tabs.find((req) => req.value === tabValue?.value);
      const reqFilterData = allRequest?.data.filter((req) => req?.req_status === getSelectedRequest.label);
      setDriverRequest({ ...driverRequest, data: reqFilterData });
    }
  };

  function sortingQuery(field, sortBy) {
    const sortField = `sort[][${field}]`;
    getSortedDocuments(sortField, sortBy);
  }

  const getSortedDocuments = (sortField, sortBy) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('DD-MM-YYYY');
    const endFormat = moment(end).format('DD-MM-YYYY');
    const payload = {
      page: '1',
      sort: 'asc',
      from_date: startFormat,
      to_date: endFormat,
      itemsPerPage: rowPerPage,
      driver_id: id,
      sortField,
      sortBy,
    };
    if (search) {
      payload.query = search;
    }
    staffRequestData(payload).then((res) => {
      setDriverRequest(res?.request_data);
    });
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    setLoading(true);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('DD-MM-YYYY');
    const endFormat = moment(end).format('DD-MM-YYYY');
    const payload = { page: 1, itemsPerPage: rowPage, from_date: startFormat, to_date: endFormat };
    if (search) {
      payload.query = search;
    }
    staffRequestData(payload)
      .then((res) => {
        setDriverRequest({ ...res, data: res.request_data?.data });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        title: <MaterialTableSort title='ID' field='request_id' sortingQuery={sortingQuery} />,
        render: (rowData) => <span style={{ color: use(palette.gray900, palette.white) }}>{rowData?.request_id}</span>,
      },
      {
        field: 'request_type',
        title: (
          <span
            className='materialTableSort_title request-table-header-titile'
            style={{ color: use(palette.gray900, palette.white) }}
          >
            REQUEST TYPE
          </span>
        ),
        render: (rowData) => (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.request_type}
          </p>
        ),
      },
      {
        field: 'staff_notes',
        title: <MaterialTableSort title='Staff Notes' field='staff_notes' sortingQuery={sortingQuery} />,
        render: (rowData) => (
          <p
            className='table-data-text'
            style={{ color: use(palette.gray700, palette.gray200), whiteSpace: 'break-spaces' }}
          >
            {rowData?.note}
          </p>
        ),
      },
      {
        field: 'req_start_datetime',
        title: <MaterialTableSort title='REQUEST DATE(S)' field='req_start_datetime' sortingQuery={sortingQuery} />,
        render: (rowData) => (
          <div className='sub-wraper'>
            <div
              className='date-container'
              style={{
                backgroundColor: use('#F0F2F7', palette.dark600),
                color: use(palette.gray600, palette.gray400),
              }}
            >
              <p className='sub-text'>{formatDate(rowData?.req_start_datetime)}</p>
            </div>
            <span>-</span>
            <div
              className='date-container'
              style={{
                backgroundColor: use('#F0F2F7', palette.dark600),
                color: use(palette.gray600, palette.gray400),
              }}
            >
              <p className='sub-text'>{formatDate(rowData?.req_end_datetime)}</p>
            </div>
          </div>
        ),
      },
      {
        field: 'req_status',
        title: (
          <span
            className='materialTableSort_title request-table-header-titile'
            style={{ color: use(palette.gray900, palette.white) }}
          >
            STATUS
          </span>
        ),
        render: (rowData) =>
          rowData?.req_status === 'Pending' || rowData?.req_status === 'P' ? (
            <div
              className='break-container staff-status '
              style={{ backgroundColor: use(palette.orangeBackground1, palette.orangeBackground) }}
            >
              <p
                className='break-text'
                style={{
                  color: use(statusColor.Pending?.color, statusColor.Pending?.bgColor),
                }}
              >
                {rowData?.req_status === 'P' ? 'PENDING' : rowData?.req_status?.toUpperCase()}
              </p>
            </div>
          ) : rowData?.req_status === 'Approved' || rowData?.req_status === 'A' ? (
            <div className='d-flex flex-column'>
              <div
                className='success-container staff-status'
                style={{ backgroundColor: use(palette.lightgreen, palette.darkgreen) }}
              >
                <p
                  className='success-text'
                  style={{
                    color: use(statusColor.Approved?.color, statusColor.Approved?.bgColor),
                  }}
                >
                  {rowData?.req_status === 'A' ? 'APPROVED' : rowData?.req_status?.toUpperCase()}
                </p>
              </div>
              {rowData?.updated_by && (
                <p
                  className='table-data-text mt-1'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                  }}
                >
                  By {`${rowData?.updated_by?.first_name} ${rowData?.updated_by?.last_name}`}
                </p>
              )}
            </div>
          ) : rowData?.req_status === 'Rejected' || rowData?.req_status === 'R' ? (
            <div className='d-flex flex-column'>
              <div className='warning-container staff-status' style={{ backgroundColor: palette.red0 }}>
                <p
                  className='warning-text'
                  style={{
                    color: use(statusColor.Rejected?.color, statusColor.Rejected?.bgColor),
                  }}
                >
                  {rowData?.req_status === 'R' ? 'REJECTED' : rowData?.req_status?.toUpperCase()}
                </p>
              </div>
              {rowData?.updated_by && (
                <p
                  className='table-data-text mt-1'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                  }}
                >
                  By {`${rowData?.updated_by?.first_name} ${rowData?.updated_by?.last_name}`}
                </p>
              )}
            </div>
          ) : null,
      },
      {
        field: '',
        render: (rowData) => (
          <RowActions
            onEdit={() => {
              setUpdateModalShow(true);
              setUdpdateRequestData(rowData);
            }}
          />
        ),
      },
    ];
  }, [use, driverRequest]);

  if (loading) {
    return <TablePreLoader />;
  }

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  return (
    <div
      className='request-table-container staff-request driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-header'>
          <p className='heading' style={{ color: use(palette.gray700, palette.gray200) }}>
            Requests
          </p>
          {user.id === Number(id) && (
            <div className='header-modal-container'>
              <PrimaryBtn icon={add} title='Add Request' onClick={() => setModalShow(true)} />
            </div>
          )}
        </div>
        <div className='serach-wrapper'>
          <input
            type='text'
            onChange={(e) => setSearch(e.target.value)}
            className='serach-feild'
            placeholder='Search'
            style={{
              backgroundColor: use(palette.white, palette.dark800),

              color: use(palette.gray400, palette.white),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          />
          <div className='calender-wrap'>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '143px', bottom: '57px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
          </div>
        </div>
      </div>
      <div className='table-wrap'>
        <SwitchRadio
          name='tableTopTabMenu'
          items={tabs}
          value={tab}
          type='tab'
          onChange={(v) => onChangeTab(v)}
          plus={false}
        />
        <div className='document-details sub-table-container'>
          <MaterialTableWrapper
            data={driverRequest?.data || []}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
            onRowClick={(e, rowData) => {
              setUpdateModalShow(true);
              setUdpdateRequestData(rowData);
            }}
          />
        </div>
        {!!modalShow && (
          <StaffRequestAddModal
            show={!!modalShow}
            onHide={() => setModalShow(false)}
            title='Add Request'
            createRequest={createRequest}
            onSuccess={onSuccess}
            createLoading={createLoading}
            requestType={requestType}
            id={id}
            name={`${driverInfo?.first_name} ${driverInfo?.last_name}`}
            shipmentList={shipmentList}
          />
        )}
        {!!updateModalShow && (
          <StaffRequestUpdateModal
            show={!!updateModalShow}
            onHide={() => setUpdateModalShow(false)}
            title='Update Request'
            createLoading={createLoading}
            requestType={requestType}
            id={id}
            name={`${driverInfo?.first_name} ${driverInfo?.last_name}`}
            udpdateRequestData={udpdateRequestData}
            updateRequest={updateRequest}
            shipmentList={shipmentList}
          />
        )}
        {showMessage && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      </div>
    </div>
  );
};

export default StaffRequest;
