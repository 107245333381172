import React from 'react';
import { Bar } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { getAgeGroupOptions } from './Charts.data';

const AgeGroupChart = ({ ageGroup }) => {
  const data = {
    labels: (ageGroup || []).map((item) => `${item.age_group}`),
    datasets: [
      {
        data: (ageGroup || []).map((item) => item.accidents),
        backgroundColor: [
          palette.gray200,
          palette.dark0,
          palette.red300,
          palette.orange300,
          palette.amber300,
          palette.green300,
          palette.indigo300,
        ],
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getAgeGroupOptions(ageGroup)} data={data} />;
};

export default AgeGroupChart;
