import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { getPayableAgingDetails } from 'Api/Reports';
import Header from 'pages/Reports/ReportDetails/shared/Header';
import useDateFormat from 'hooks/useDateFormat';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { agingDetailsConverter, transformReportItems } from './converters';
import { STableFilters, STableWrapper, STable } from '../ReportDetails.styles';

const PayableAgingDetail = () => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [agingDetails, setAgingDetails] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: startDate ? moment(startDate).toDate() : null,
    end: endDate ? moment(endDate).toDate() : null,
  });
  const didMountRef = useRef(false);

  const getVendorsList = async () => {
    try {
      const params = {
        from: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
      };
      const { data } = await getPayableAgingDetails(params);
      const convertedData = agingDetailsConverter(data?.[0]);
      setAgingDetails(convertedData);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      navigate('/reports');
    } finally {
      setLoading(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const convertedItems = transformReportItems(agingDetails, true);
      const { url } = await generatePDF(convertedItems, download, dateRange, dateFormat, currency);
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onExport = () => {
    if (!agingDetails?.length) {
      return;
    }

    const titles = {
      type: 'Type',
      date: 'Date',
      number: 'Number',
      name: 'Name',
      due_date: 'Due Date',
      aging: 'Aging',
      open_balance: 'Open Balance',
    };

    const convertedItems = transformReportItems(agingDetails);

    const csvRecords = convertedItems.map((item) => ({
      type: item.isType ? item.title || '-' : item.isTotal ? `Total ${item.title || ''}` : 'Bill',
      date: !item.isType && !item.isTotal ? dateFormat.formatDate(item.date_entered) : '',
      number: !item.isType && !item.isTotal ? item.reference_id : '',
      name: !item.isType && !item.isTotal ? item.vendor_name : '',
      due_date: !item.isType && !item.isTotal ? dateFormat.formatDate(item.bill_due_date) : '',
      aging: !item.isType && !item.isTotal ? item.aging : '',
      open_balance: item.isTotal ? item.total_amount : item.isType ? '' : item.open_balance,
    }));

    const arrayToConvert = [titles, ...csvRecords];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute(
      'download',
      `Accounts Payable Aging Detail - ${
        dateRange.start && dateRange.end
          ? `${dateFormat.formatDate(dateRange.start)} - ${dateFormat.formatDate(dateRange.end)}`
          : 'All Time'
      }`
    );
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getVendorsList();
    didMountRef.current = true;
  }, [dateRange]);

  useEffect(() => {
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams, { replace: true });
  }, []);

  return (
    <PageWrapper loading={loading} title='Accounts Payable Aging Detail'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName={dateRange.start ? 'Custom Range' : 'All Time'}
              dateRange={dateRange}
              setDateRange={setDateRange}
              type='allTime'
            />
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={agingDetails.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          disabled={agingDetails.length === 0 || loading}
          onCsvExport={onExport}
        />
        <Divider margin='16px 0 16px' />
        {agingDetails.map((detail) => (
          <div className='mb-5' key={detail.title}>
            <div className='table-title'>
              <Typography variant='button2' style={{ color: palette.gray900 }}>
                {detail.title}
              </Typography>
            </div>
            <STable>
              <thead>
                <tr className='header-row'>
                  <th>TYPE</th>
                  <th>DATE</th>
                  <th>NUMBER</th>
                  <th>NAME</th>
                  <th>DUE DATE</th>
                  <th>AGING</th>
                  <th>OPEN BALANCE</th>
                </tr>
              </thead>
              <tbody>
                {detail.data.map((item) => (
                  <tr key={item.id} className='body-row'>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        Bill
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {dateFormat.formatDate(item.date_entered)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.reference_id || '-'}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.vendor_name}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {dateFormat.formatDate(item.bill_due_date)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {item.aging}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {currency}
                        {formatNumber(item.open_balance)}
                      </Typography>
                    </td>
                  </tr>
                ))}
                {!!detail.data?.length && (
                  <tr className='body-row'>
                    <td colSpan={6}>
                      <Typography variant='s2' style={{ color: palette.gray900 }}>
                        Total {detail.title}
                      </Typography>
                    </td>
                    <td colSpan={6}>
                      <Typography variant='s2' style={{ color: palette.gray900 }}>
                        {currency}
                        {formatNumber(detail.total_amount)}
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </STable>
            {!detail.data?.length && <NoRecords />}
          </div>
        ))}
      </STableWrapper>
      {!!pdfUrl && (
        <ViewPdf
          open={!!pdfUrl}
          onClose={() => setPdfUrl(null)}
          pdfUrl={pdfUrl}
          title='Accounts Payable Aging Detail'
        />
      )}
    </PageWrapper>
  );
};

export default PayableAgingDetail;
