import React from 'react';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { SContent, SHeader, SWrapper } from './InfoBox.styles';

const InfoBox = ({ title, content, checked, handleCheckboxChange }) => {
  return (
    <SWrapper>
      <SHeader>
        <h4>{title}</h4>
      </SHeader>
      <SContent>
        <Typography variant='c1' className='info'>
          {content}
        </Typography>
        <div className='answer'>
          <CustomCheckbox checked={!!checked} onChange={handleCheckboxChange}>
            <Typography variant='c1' style={{ marginLeft: '5px' }}>
              Yes, I consent
            </Typography>
          </CustomCheckbox>
        </div>
      </SContent>
    </SWrapper>
  );
};

export default InfoBox;
