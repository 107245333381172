import React from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { SimpleLoader } from 'common/Loader';
import { palette } from 'utils/constants';
import { SActions } from './ActionButtons.styles';

const ActionButtons = ({ data, onEdit, onSave, onDelete, loadingSave, loadingDelete }) => {
  return (
    <SActions>
      {!!onEdit &&
        (!data.isEdit && !data.isNew ? (
          <span className='action-wrapper' onClick={onEdit}>
            <EditIcon width={14} height={14} className='pointer' />
          </span>
        ) : (
          <span className='action-wrapper' onClick={onSave}>
            {loadingSave ? (
              <SimpleLoader loading size={14} />
            ) : (
              <CheckIcon width={14} height={14} className='pointer' />
            )}
          </span>
        ))}
      {!!onDelete && (
        <span className='action-wrapper' onClick={onDelete}>
          {loadingDelete ? (
            <SimpleLoader loading size={13} />
          ) : (
            <DeleteIcon fill={palette.red500} width={13} height={13} className='pointer' />
          )}
        </span>
      )}
    </SActions>
  );
};

export default ActionButtons;
