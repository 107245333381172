import React from 'react';
import ModalWithMap from './ModalWithMap';
import UpdateStatus from './UpdateStatus';
import EdiApiShipment from './EdiApiShipment';
import CheckExpiration from './CheckExpiration';
import DelayedShipment from './DelayedShipment';
import DocumentReceived from './DocumentReceived';
import ExpirationUpdate from './ExpirationUpdate';
import PolicyExpiration from './PolicyExpiration';
import LicenseExpiration from './LicenseExpiration';
import DocumentExpiration from './DocumentExpiration';
import PhotoIdentification from './PhotoIdentification';
import MedicalExaminersExpiration from './MedicalExaminersExpiration';
import { ALERT_TYPE } from './TakeActions.data';

const TakeActions = ({ open, onClose, alert, onSuccess }) => {
  const { alert_name } = alert || {};

  switch (alert_name?.alert_name) {
    case ALERT_TYPE.NEW_INVOICES_READY:
    case ALERT_TYPE.NEW_DOCUMENTS_ARRIVED:
    case ALERT_TYPE.DRIVER_DOCUMENT_RECEIVED:
      return <DocumentReceived open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.DRIVER_LICENSE_EXPIRATION:
      return <LicenseExpiration open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.PHYSICAL_INSURANCE:
    case ALERT_TYPE.GENERAL_LIABILITY_INSURANCE:
    case ALERT_TYPE.MOTOR_CARGO_INSURANCE:
    case ALERT_TYPE.AUTOMOBILE_LIABILITY:
    case ALERT_TYPE.DRIVER_COMPENSATION_POLICY:
    case ALERT_TYPE.STAFF_COMPENSATION_POLICY:
      return <PolicyExpiration open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.DELAYED_SHIPMENT:
      return <DelayedShipment open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.MEDICAL_EXAMINERS_EXPIRATION:
      return <MedicalExaminersExpiration open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.CRIMINAL_CHECK_DUE:
    case ALERT_TYPE.MVR_CHECK_DUE:
    case ALERT_TYPE.FMCSA_CLEARING_HOUSE_CHECK_DUE:
    case ALERT_TYPE.DRUG_TESTING_CHECK_DUE:
      return <CheckExpiration open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.MCS_150_BIENNIAL_UPDATE:
    case ALERT_TYPE.UCR_ANNUAL_UPDATE:
    case ALERT_TYPE.URS_BIENNIAL_UPDATE:
      return <ExpirationUpdate open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.HIGHWAY_TAX:
    case ALERT_TYPE.DOT_INSPECTION:
    case ALERT_TYPE.IFTA:
    case ALERT_TYPE.IFTA_LICENSE:
    case ALERT_TYPE.EQUIPMENT_REGISTRATION_EXPIRING:
    case ALERT_TYPE.STAFF_CUSTOM_DOCUMENT_EXPIRATION:
    case ALERT_TYPE.DRIVER_CUSTOM_DOCUMENT_EXPIRATION:
    case ALERT_TYPE.DRIVER_DOCUMENT:
    case ALERT_TYPE.OWNER_OPERATOR_DOCUMENT:
    case ALERT_TYPE.CARRIER_DOCUMENT_EXPIRED:
      return <DocumentExpiration open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.NEW_EDI_API_SHIPMENT:
      return <EdiApiShipment open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    case ALERT_TYPE.PHOTO_IDENTIFICATION_EXPIRATION:
      return <PhotoIdentification open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />;
    default:
      return alert?.latitude || alert?.location_data?.latitude || alert?.location_data?.[0]?.latitude ? (
        <ModalWithMap open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />
      ) : (
        <UpdateStatus open={open} onClose={onClose} alert={alert} onSuccess={onSuccess} />
      );
  }
};

export default TakeActions;
