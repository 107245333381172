import React from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as UpDownIcon } from 'assets/icons/arrows.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { SAutocomplete } from './Autocomplete.styles';

const Autocomplete = ({
  multiple,
  showCheckbox,
  labelKey = 'name',
  optionKey = 'id',
  placeholder = 'Select..',
  label,
  error,
  required,
  className,
  size,
  inputProps,
  loading,
  borderRadius,
  tagProps,
  labelProps,
  getTagLabel,
  disableClearable = true,
  getOptionLabel,
  ...props
}) => {
  return (
    <>
      {label && (
        <InputLabel required={required} {...labelProps}>
          {label}
        </InputLabel>
      )}
      <SAutocomplete
        size={size}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        loading={loading}
        getOptionLabel={(option) =>
          getOptionLabel ? getOptionLabel(option) : typeof option === 'object' ? option[labelKey] : option
        }
        popupIcon={loading ? <CircularProgress size={20} /> : <UpDownIcon />}
        disableClearable={disableClearable}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              ...(inputProps || {}),
            }}
            placeholder={placeholder}
          />
        )}
        renderOption={(props, option, { selected }) =>
          showCheckbox ? (
            <li {...props} key={typeof option === 'object' ? option[optionKey] : option}>
              <CustomCheckbox checked={selected} />
              <span>
                {getOptionLabel ? getOptionLabel(option) : typeof option === 'object' ? option[labelKey] : option}
              </span>
            </li>
          ) : (
            <li {...props} key={typeof option === 'object' ? option[optionKey] : option}>
              {getOptionLabel ? getOptionLabel(option) : typeof option === 'object' ? option[labelKey] : option}
            </li>
          )
        }
        renderTags={(values, getTagProps) =>
          values.map((option, index) => (
            <Chip
              size='medium'
              label={getTagLabel ? getTagLabel(option) : typeof option === 'object' ? option[labelKey] : option}
              labelColor={palette.indigo500}
              fontWeight={500}
              bgColor={palette.indigo0}
              deleteIcon={<DeleteIcon />}
              height='20px'
              {...tagProps}
              {...getTagProps({ index })}
            />
          ))
        }
        className={className}
        $borderRadius={borderRadius}
        {...props}
      />
      <ErrorMessage error={error} />
    </>
  );
};

export default Autocomplete;
