import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateMph } from 'Api/CompanySettings';
import { validationSchema } from './validationSchema';

const UpdateSpeed = ({ open, onClose, data, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await updateMph(data.id, { mph: Number(values.mph) });
      showToaster({ type: 'success', message: 'Speed has been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { mph: data.mph },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Update Equipment Speed'
      $bgColor={palette.gray0}
      $maxWidth='350px'
      $minWidth='350px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update Speed',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div>
        <Input
          required
          type='number'
          name='mph'
          label='Speed'
          placeholder='Speed'
          value={values.mph}
          onChange={handleChange}
          error={touchedErrors.mph}
        />
      </div>
    </Modal>
  );
};

export default UpdateSpeed;
