import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { QueryClientProvider } from 'react-query';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Provider as ProviderRedux } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import App from './App';
import { AuthProvider } from './context/auth.context';
import { queryClient } from './reactQuery/reactQuery';
import { ThemeProvider } from './context/themeContext';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import store from './store/store';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore: window.location.hostname === 'localhost' ? () => true : null, // Ignore all during development
  environment:
    process.env.REACT_APP_BASE_URL === 'https://prod.truckindigital.com'
      ? 'production'
      : process.env.REACT_APP_BASE_URL === 'https://staging.truckindigital.com'
      ? 'staging'
      : 'dev',
  payload: {
    person: localStorage.getItem('user')
      ? {
          ...JSON.parse(localStorage.getItem('user')),
          id: JSON.parse(localStorage.getItem('user'))?.id?.toString(),
          accessToken: undefined,
        }
      : undefined,
    client: {
      javascript: {
        source_map_enabled: false,
      },
    },
  },
};

ReactDOM.render(
  <ProviderRedux store={store}>
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthProvider>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </AuthProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </Provider>
  </ProviderRedux>,
  document.getElementById('root')
);
