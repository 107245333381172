import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { getCustomerContactBookType } from 'Api/Customers';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import CustomizedSnackbars from 'components/toast/Toast';
import { AppendToFormData } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomPhoneInput from 'components/CustomPhoneInput';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import { createCarrierContactBook, getCarrierContactBook, updateCarrierContactBook } from 'Api/Carriers';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { useColumns } from './ContactBookTab.data';
import sm from './ContactBookTab.module.css';

const ContactBookTab = () => {
  const showToaster = useShowToaster();
  const [toggle, setToggle] = React.useState(false);
  const [contactBooK, setContactBook] = React.useState();
  const [refetchIndex, setRefetchIndex] = useState(Date.now());
  const [changedIndexes, setChangedIndexes] = useState([]);
  const formRef = useRef();
  const { use } = useTheme();
  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        maxWidth: '200px',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);
  const { id } = useParams();
  const defaultValue = {
    contact_name: '',
    contact_email: '',
    phone_number: '',
    contact_type_id: '',
    portal_access: false,
    receive_offers: false,
    check_calls: false,
    inactive: true,
  };

  const titlesOption = [
    { key: 1, label: 'Name', width: '18%', required: true },
    { key: 2, label: 'Email', width: '18%', required: true },
    { key: 3, label: 'Phone Number', width: '15%', required: true },
    { key: 4, label: 'Type', width: '15%', required: true },
    { key: 6, label: 'Receive Offers', width: '8%', required: false },
    { key: 7, label: 'Allow Check Calls', width: '8%', required: false },
    { key: 5, label: '', width: '20%', required: false },
    { key: 8, label: '', width: '33px', required: false },
  ];

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const [typeData, setTypeData] = useState([]);

  const getTypeData = () => {
    getCustomerContactBookType()
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              labelSelected: null,
            };
          });
          setTypeData(selectData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const validateEmail = (value) => {
    let error;
    const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!isValidEmail.test(value) && value) {
      error = 'Invalid email address';
    } else if (!value) {
      error = 'Email address is required';
    }
    return error;
  };

  const onUpdate = (el, index, values, errors, getFieldHelpers, setValues) => {
    if (changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
    const name = getFieldHelpers(`contact_books[${index}][contact_name]`);
    const email = getFieldHelpers(`contact_books[${index}][contact_email]`);
    const phone_number = getFieldHelpers(`contact_books[${index}][phone_number]`);
    const contact_type_id = getFieldHelpers(`contact_books[${index}][contact_type_id]`);
    name.setTouched('Required');
    email.setTouched('Required');
    phone_number.setTouched('Required');
    contact_type_id.setTouched('Required');
    if (el?.id) {
      updateContactUpdate(el, values);
    } else {
      createContactUpdate(el, values, index, setValues);
    }
  };

  const updateContactUpdate = (el) => {
    const updateData = {
      customer_id: el.customer_id,
      contact_name: el.contact_name,
      phone_number: el.phone_number,
      contact_type_id: el.contact_type_id,
      portal_access: el.portal_access ? 1 : 0,
      receive_offers: el.receive_offers ? 1 : 0,
      check_calls: el.check_calls ? 1 : 0,
      inactive: el.inactive ? 0 : 1,
      contact_email: el.contact_email,
    };

    updateCarrierContactBook(updateData, el.id)
      .then((res) => {
        if (res && res.data) {
          setShowMessage({ ...showMessage, message: 'Contact updated successfully', visible: true });
        }
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) });
      });
  };

  const createContactUpdate = (el, values, index, setValues) => {
    const formData = new FormData();
    const updateData = AppendToFormData({}, values, index);
    const map = mapper(updateData, index);
    Object.keys(map).map((key) => formData.append(key, map[key]));
    createCarrierContactBook(formData)
      .then(() => {
        setShowMessage({ ...showMessage, message: 'Contact created successfully!', visible: true });
        setValues((prevState) => ({
          contact_books: prevState.contact_books.map((el) => ({ ...el, isNew: false })),
        }));
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) });
      });
  };

  const mapper = (obj, index) => {
    const newData = {
      carrier_id: id,
    };
    const map = {
      [`contact_books[${index}]receive_offers`]: obj[`contact_books[${index}]receive_offers`] ? '1' : '0',
      [`contact_books[${index}]portal_access`]: obj[`contact_books[${index}]portal_access`] ? '1' : '0',
      [`contact_books[${index}]check_calls`]: obj[`contact_books[${index}]check_calls`] ? '1' : '0',
      [`contact_books[${index}]inactive`]: obj[`contact_books[${index}]inactive`] ? '0' : '1',
    };
    const namesMap = {
      [`contact_books[${index}]check_calls`]: [`contact_books[${index}][check_calls]`],
      [`contact_books[${index}]inactive`]: [`contact_books[${index}][inactive]`],
      [`contact_books[${index}]receive_offers`]: [`contact_books[${index}][receive_offers]`],
      [`contact_books[${index}]portal_access`]: [`contact_books[${index}][portal_access]`],
      [`contact_books[${index}]contact_name`]: [`contact_books[${index}][contact_name]`],
      [`contact_books[${index}]contact_email`]: [`contact_books[${index}][contact_email]`],
      [`contact_books[${index}]phone_number`]: [`contact_books[${index}][phone_number]`],
      [`contact_books[${index}]contact_type_id`]: [`contact_books[${index}][contact_type_id]`],
    };

    Object.keys(obj).forEach((key) => {
      const name = namesMap[key];
      if (map[key]) {
        newData[name] = map[key];
      } else {
        newData[name] = obj[key];
      }
    });

    return newData;
  };

  const onCancel = () => {
    setToggle(!toggle);
    setRefetchIndex(Date.now());
    setShowMessage({
      ...showMessage,
      message: '',
      visible: false,
    });
    setChangedIndexes([]);
  };

  const initialValue = contactBooK?.contactBook?.data?.length
    ? {
        contact_books: contactBooK.contactBook.data.map((el) => {
          return {
            ...el,
            portal_access: el?.portal_access === 1,
            receive_offers: el?.receive_offers === 1,
            check_calls: el?.check_calls === 1,
            inactive: el?.inactive === 0,
          };
        }),
      }
    : { contact_books: [{ ...defaultValue }] };

  useEffect(() => {
    getCarrierContactBook({ id })
      .then((res) => {
        setContactBook({ contactBook: res });
      })
      .catch(() => {
        // Do nothing
      });
    getTypeData();
  }, [refetchIndex]);

  const handleSelectChange = (index) => {
    if (!changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    }
  };

  const handleFieldChange = (index, fieldName, values, setChangedIndexes, changedIndexes) => (event) => {
    const newValue = event.target.value;
    const oldValue = values.contact_books[index][fieldName];
    const isChanged = newValue !== oldValue;

    if (isChanged && !changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    } else if (!isChanged && changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
  };

  const columns = useColumns();

  return (
    <div
      className={sm.contactBookTab}
      style={{
        backgroundColor: use(palette.gray0, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div
        className={sm['contactBookTab-header']}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray50) }}>
          Contact Book
        </Typography>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={onCancel} className='driver-compansation-cancel-btn'>
              Close
            </button>
          </div>
        )}
      </div>
      {toggle ? (
        <div className={sm.body} style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
          <Formik initialValues={initialValue} innerRef={formRef}>
            {({ values, getFieldHelpers, setValues, errors }) => (
              <Form>
                <FieldArray
                  name='contact_books'
                  render={(arrayHelpers) => {
                    return (
                      <div>
                        <div className={sm.row}>
                          {titlesOption.map((el) => {
                            return (
                              <Typography
                                key={el?.label}
                                variant='s2'
                                style={{ width: el.width, whiteSpace: 'nowrap' }}
                              >
                                {el.label}
                                {el.required && <span className={sm.required_system}>*</span>}
                              </Typography>
                            );
                          })}
                        </div>

                        {values.contact_books.map((el, index) => {
                          const namePrefix = `contact_books[${index}]`;
                          return (
                            <div key={namePrefix} className={[sm.row, sm.line].join(' ')} style={{ marginTop: 0 }}>
                              <div className={sm.field_wrapper1}>
                                <Field
                                  name={`${namePrefix}[contact_name]`}
                                  component={CustomInput}
                                  style={{ paddingLeft: 10, maxWidth: '250px' }}
                                  labelStyle={styles.labelStyle}
                                  validate={validateStopPointInput}
                                  className={sm.input_basic_details}
                                  onChange={handleFieldChange(
                                    index,
                                    'contact_name',
                                    values,
                                    setChangedIndexes,
                                    changedIndexes
                                  )}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[contact_name]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>

                              <div className={sm.field_wrapper2}>
                                <Field
                                  name={`${namePrefix}[contact_email]`}
                                  component={CustomInput}
                                  style={{ paddingLeft: 10, maxWidth: '250px' }}
                                  labelStyle={styles.labelStyle}
                                  validate={validateEmail}
                                  className={sm.input_basic_details}
                                  onChange={handleFieldChange(
                                    index,
                                    'contact_email',
                                    values,
                                    setChangedIndexes,
                                    changedIndexes
                                  )}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[contact_email]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>

                              <div className={sm.field_wrapper3}>
                                <Field
                                  name={`${namePrefix}[phone_number]`}
                                  inputStyle={{ maxWidth: '250px' }}
                                  labelStyle={{ marginTop: 0 }}
                                  countryCodeEditable={false}
                                  component={CustomPhoneInput}
                                  validate={validateStopPointInput}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[phone_number]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>

                              <div className={sm.field_wrapper4}>
                                <div>
                                  <Field
                                    name={`${namePrefix}[contact_type_id]`}
                                    options={typeData}
                                    styles={styles.type}
                                    component={CustomSelect}
                                    menuStyles={styles.fullWidthPercent}
                                    validate={validateStopPointInput}
                                    onChange={() => {
                                      handleSelectChange(index);
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`${namePrefix}[contact_type_id]`}
                                    render={(error) => (
                                      <Typography variant='c2' style={styles.error}>
                                        {error}
                                      </Typography>
                                    )}
                                  />
                                </div>
                              </div>

                              <div className={sm.field_wrapper5}>
                                <Field name={`${namePrefix}[receive_offers]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[receive_offers]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                      onChange={() => {
                                        handleSelectChange(index);
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>

                              <div className={sm.field_wrapper5}>
                                <Field name={`${namePrefix}[check_calls]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[check_calls]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                      onChange={() => {
                                        handleSelectChange(index);
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>

                              {/* {values.contact_books.length > 1 && index !== 0 ? */}
                              {/* <DeleteIcon */}
                              {/*  width={12} */}
                              {/*  height={12} */}
                              {/*  fill={palette.indigo500} */}
                              {/*  style={styles.deleteIcon} */}
                              {/*  onClick={() => onDelete(el, index, arrayHelpers)} */}
                              {/* /> */}

                              <div className={sm.field_wrapper6}>
                                <div className='d-flex gap-2 align-items-center'>
                                  <InputLabel style={{ margin: 0 }}>INACTIVE</InputLabel>
                                  <Field name={`${namePrefix}[inactive]`}>
                                    {({ field }) => (
                                      <CustomCheckbox
                                        name={`${namePrefix}[inactive]`}
                                        field={field}
                                        type='switch'
                                        withinForm
                                        checked={!!field.value}
                                        onChange={() => {
                                          handleSelectChange(index);
                                        }}
                                      />
                                    )}
                                  </Field>
                                  <InputLabel style={{ margin: 0 }}>ACTIVE</InputLabel>
                                </div>
                              </div>
                              <span style={{ width: 28 }} />
                              {/* } */}
                              <div className='d-flex align-items-center gap-2' style={{ width: '60px' }}>
                                {!changedIndexes.includes(index) ? (
                                  <TickIcon
                                    fill={use(palette.green500, palette.white)}
                                    style={{ margin: '12px 0 0 0' }}
                                  />
                                ) : (
                                  <CustomButton
                                    type='primary'
                                    title=''
                                    styleButton={{
                                      padding: '6px 12px',
                                      margin: '6px 0 0 0',
                                    }}
                                    styleTitle={{ fontSize: 14 }}
                                    onClick={() => onUpdate(el, index, values, errors, getFieldHelpers, setValues)}
                                    leftIcon={
                                      <TickIcon
                                        fill={use(palette.white, palette.white)}
                                        style={{ width: 9, height: 9 }}
                                      />
                                    }
                                    buttonProps={{
                                      type: 'submit',
                                    }}
                                  />
                                )}
                                {el.isNew && (
                                  <DeleteIcon
                                    width={12}
                                    height={12}
                                    fill={palette.indigo500}
                                    style={{ margin: '12px 0 0 0', cursor: 'pointer' }}
                                    onClick={() =>
                                      setValues((prevState) => ({
                                        contact_books: prevState.contact_books.filter((el, i) => i !== index),
                                      }))
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <CustomButton
                          type='secondary'
                          title='Add Another'
                          styleTitle={styles.addAnotherTitle}
                          styleButton={styles.addAnotherButton}
                          onClick={() => arrayHelpers.push({ ...defaultValue, isNew: true })}
                          leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                        />
                      </div>
                    );
                  }}
                />
              </Form>
            )}
          </Formik>
          {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
        </div>
      ) : (
        <div className={sm['table-wrap']}>
          <div className='sub-table-container'>
            <div className='planner_table_wrapper'>
              <MaterialTable
                data={contactBooK?.contactBook?.data}
                columns={columns}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  color: use(palette.gray700, palette.gray200),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
                options={{
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  draggable: false,
                  rowStyle: {
                    borderBottom: 'none',
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBookTab;
