import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const BidDate = ({ bidDate }) => {
  const [, setBidAt] = useState([0, 0, 0]);

  const getBidAt = () => {
    const days = moment.utc().diff(moment.utc(bidDate), 'days');
    const hours = moment.utc().diff(moment.utc(bidDate), 'hours') % 24;
    const minutes = moment.utc().diff(moment.utc(bidDate), 'minutes') % 60;

    setBidAt([days, hours < 0 ? '00' : hours.toString(), minutes < 0 ? '00' : minutes.toString()]);
  };

  useEffect(() => {
    if (!bidDate) {
      return;
    }

    getBidAt();

    const intervalId = setInterval(() => {
      getBidAt();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [bidDate]);

  return (
    <Typography variant='b2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
      {moment.utc(bidDate).fromNow() === 'a few seconds ago' ? 'Just now' : moment.utc(bidDate).fromNow()}
    </Typography>
  );
};

export default BidDate;
