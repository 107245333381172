import React from 'react';
import { useNavigate } from 'react-router-dom';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/drivers/comment.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette, statusColor } from 'utils/constants';
import { SNameImage, SEmailPhone } from './NameImage.styles';

const NameImage = ({ user, settlementId, userType }) => {
  const { use } = useTheme();
  const navigate = useNavigate();

  const onImageClick = () => {
    if (!user?.id) {
      return null;
    }

    navigate(`/${Number(userType) === 2 ? 'driver' : 'staff'}-profile/${user.id}/general`);
  };

  return (
    <SNameImage>
      <div className='profile-image-container' onClick={onImageClick}>
        <img src={user?.image || user?.profile_logo || userDefault} alt='' width={32} height={32} />
        <div
          className='profile-active-indicator'
          style={{
            backgroundColor: use(
              statusColor[user?.status_data?.driver_status || user?.status_data?.staff_status]?.color,
              statusColor[user?.status_data?.driver_status || user?.status_data?.staff_status]?.darkColor
            ),
          }}
        />
      </div>
      <div className='user-name-id'>
        <div className='user-name-icons'>
          <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
            {user?.fname || user?.first_name} {user?.lname || user?.last_name}
          </Typography>
          <SEmailPhone>
            <a href={`tel:${user?.phone_number}`}>
              <PhoneIcon width={20} height={20} fill={palette.indigo400} />
            </a>
            <ChatIcon onClick={() => navigate('/chat')} />
            <a href={`mailto:${user?.email}`}>
              <MailIcon width={22} height={12} fill={palette.indigo400} />
            </a>
          </SEmailPhone>
        </div>
        <div>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            Settlement ID {settlementId}
          </Typography>
        </div>
      </div>
    </SNameImage>
  );
};

export default NameImage;
