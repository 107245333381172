import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  padding: 4px 16px;
  background: #5371cc;
}

;

h4 {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: ${palette.white};
}
`;

export const SContent = styled.div`
  display: flex;
  column-gap: 16px;
  padding: 4px 8px;
  border: 2px solid ${palette.gray50};
  border-top: none;
  background: ${palette.gray0};

  .info {
    width: 65%;
    font-size: 13px !important;
  }

  .answer {
    width: 35%;
    font-size: 13px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SDatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 6px;
  background: ${palette.white};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
  padding: 0 8px;
  width: 111px;
  height: 32px;

  input {
    width: inherit;
    font-size: 14px;
  }

  .react-datepicker-wrapper {
    width: 100% !important;

    .custom-date-picker-input {
      width: 100%;
      justify-content: flex-start;
      column-gap: 8px;
      margin: 0;
    }
  }

  .disabled-date-picker {
    background-color: ${palette.gray0} !important;

    span {
      color: ${palette.gray400} !important;
    }
  }

  .react-datepicker__year-dropdown {
    background-color: ${palette.white};

    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 8px;
    }

    .react-datepicker__year-option {
      background-color: ${palette.white};
      padding: 2px 4px;
      font-size: 14px;

      &:hover {
        background-color: ${palette.gray50};
      }

      &.react-datepicker__year-option--selected_year {
        background-color: ${palette.indigo500};
        color: ${palette.white};
      }
    }

    .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation {
      display: none;
    }
  }
`;
