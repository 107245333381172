import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import classes from './revertModal.module.scss';

const RevertModal = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const subTitle = useMemo(
    () =>
      data.map((item) => {
        return `Shipment ${item?.shipment_id} ${item?.shipment_billing?.[0]?.billing_customer?.company_name} \n`;
      }),
    []
  );

  const styles = useMemo(() => {
    return {
      modal: {
        width: 450,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      styleButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 16,
      },
    };
  }, [use, palette]);

  function getIdsString(objects) {
    return objects.map((obj) => obj.shipment_id);
  }

  const onClickCancel = () => {
    onAddBtn(null, null, null, getIdsString(data));
    setLoading(true);
  };
  const onClickNeverMind = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      backdropClassName={classes.backDropModal}
      dialogClassName='dialogClassName'
    >
      <div className={classNames(classes.modalWrapper, use(classes.light, classes.dark))}>
        <div className={classes.header}>
          <Typography variant='s2'>Revert Shipment</Typography>
          <Typography variant='s3'>{subTitle}</Typography>
        </div>
        <div className={classes.line} />
        <div className={classes.body}>
          <Typography variant='title1' style={{ color: palette.orange500 }}>
            WARNING!
          </Typography>
          <Typography variant='s3'>Reverting this shipment will result in the following:</Typography>
          <ul>
            <li>
              <Typography variant='s3'>Customer will not be billed.</Typography>
            </li>
            <li>
              {data?.[0]?.brokerage_dispatch ? (
                <>
                  <Typography variant='s3'>Carrier will be unassigned and </Typography>
                  <Typography variant='s3' style={{ textDecoration: 'underline' }}>
                    shipment cancellation email will be sent to carrier contact.
                  </Typography>
                </>
              ) : (
                <Typography variant='s3'>Driver will be unassigned from the shipment.</Typography>
              )}
            </li>
            <li>
              <Typography variant='s3'>Shipment will be moved back to Planner.</Typography>
            </li>
          </ul>
        </div>
        <div className={classes.line} />
        <div className={classes.buttons}>
          <CustomButton
            type='danger'
            title='Yes, Revert Shipment'
            styleButton={styles.styleButton}
            disabled={loading}
            onClick={onClickCancel}
          />

          <CustomButton
            type='secondary'
            title='Nevermind'
            styleButton={styles.styleButton}
            onClick={onClickNeverMind}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RevertModal;
