import { number, object, string } from 'yup';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchema = object().shape({
  factoring_company: string().when('is_factoring', {
    is: true,
    then: string().trim().required('Required'),
    otherwise: string(),
  }),
  address_line1: string().when('is_factoring', {
    is: true,
    then: string().trim().required('Required'),
    otherwise: string(),
  }),
  factor_email: string().when('is_factoring', {
    is: true,
    then: string().trim().required('Required'),
    otherwise: string(),
  }),
  factor_fax: string().when('is_factoring', {
    is: true,
    then: string().matches(phoneRegExp, 'Fax is not valid').required('Required'),
    otherwise: string(),
  }),
  zip: string().when('is_factoring', {
    is: true,
    then: string().trim().required('Required'),
    otherwise: string(),
  }),
  country: object().when('is_factoring', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  city: object().when('is_factoring', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  state: object().when('is_factoring', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  notice: string().when('is_factoring', {
    is: true,
    then: string().trim().required('Required'),
    otherwise: string(),
  }),
});
