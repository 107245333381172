import React, { useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Input, { InputWithIcon } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useShowToaster from 'hooks/useShowToaster';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { reconcileBankFeed } from 'Api/Accounts';
import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';
import { getAccountToUpdate } from 'pages/Accounting/Accounts/helpers';
import PayeeAutocomplete from 'pages/Accounting/Accounts/components/PayeeAutocomplete';
import SuggestNewRule from 'pages/Accounting/Accounts/BankFeed/components/SuggestNewRule';
import { SStatusDot, STableData, STableRow } from 'pages/Accounting/Accounts/BankFeed/BankFeed.styles';
import { statusColor, getInitialValues } from '../Transactions/Transactions.data';

const TransactionRow = ({
  transaction,
  accounts,
  payees,
  rules,
  selected,
  handleSelectRow,
  onUpdateSuccess,
  onRowClick,
  getRulesList,
}) => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [timeoutId, setTimeoutId] = useState(null);
  const [disabledInput, setDisabledInput] = useState(transaction?.payment ? 'deposit' : 'payment');
  const [addPayeeOpen, setAddPayeeOpen] = useState(false);
  const [ruleToSuggest, setRuleToSuggest] = useState(null);
  const paymentRef = useRef(null);
  const depositRef = useRef(null);

  const { handleChange, values } = useForm({
    initialValues: getInitialValues(transaction),
    enableReinitialize: true,
  });

  const onFieldChange = (field, value, shouldGetPayees) => {
    handleChange(field, value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (field === 'payee' && !!value && (value.account || value.account_id)) {
      const account = value.account || accounts.find((acc) => Number(acc.id) === value.account_id);
      handleChange('account', account);
    }

    const account = getAccountToUpdate(field, value, transaction);

    const body = {
      account_id: [transaction.account_id],
      date: [transaction.date],
      reference_id: [transaction.reference_id],
      payee_id: [field === 'payee' ? value?.id || 0 : values.payee?.id || 0],
      payee_type: [field === 'payee' ? value?.type || null : values.payee?.type || null],
      account: [account],
      memo: [field === 'memo' ? value : values.memo || ''],
      reconciled: [0],
      payment: [field === 'payment' ? Number(value) : disabledInput === 'payment' ? 0 : values.payment],
      deposit: [field === 'deposit' ? Number(value) : disabledInput === 'deposit' ? 0 : values.deposit],
    };

    const id = setTimeout(async () => {
      try {
        const { data } = await reconcileBankFeed(body);
        onUpdateSuccess(data, shouldGetPayees);
        showToaster({ type: 'success', message: 'Successfully updated!' });
      } catch (e) {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      }
    }, 300);
    setTimeoutId(id);
  };

  const onAddNewPayeeSuccess = (payee) => {
    onFieldChange('payee', payee, true);
  };

  const onAmountInputClick = (e, type) => {
    e.stopPropagation();
    setDisabledInput(type === 'payment' ? 'deposit' : 'payment');
    setTimeout(() => {
      if (type === 'payment') {
        paymentRef.current.focus();
      } else {
        depositRef.current.focus();
      }
    });
  };

  const getRuleToSuggest = () => {
    const containsExists = rules.some((rule) => {
      return rule.contains?.some((item) => item.value.toLowerCase() === transaction.description.toLowerCase());
    });

    if (containsExists) {
      return;
    }

    return { contains: transaction.description, account: values.account, payee: values.payee };
  };

  const onSelectRow = (checked) => {
    handleSelectRow(checked, transaction);

    if (!checked || !transaction.description || !values.payee || !values.account || transaction.status === 'Matched') {
      setRuleToSuggest(null);
      return;
    }
    const rule = getRuleToSuggest();

    if (rule) {
      setRuleToSuggest(rule);
    }
  };

  const onCreateRuleSuccess = () => {
    getRulesList();
    setRuleToSuggest(null);
  };

  return (
    <>
      <STableRow className={ruleToSuggest ? 'no-border-bottom' : ''} onClick={onRowClick}>
        <STableData $width={transaction.status === 'Matched' ? '120px' : '60px'}>
          <div className='d-flex justify-content-end gap-2'>
            {transaction.status === 'Matched' && (
              <Typography variant='s2' style={{ color: palette.green400 }}>
                Matched
              </Typography>
            )}
            <span onClick={(e) => e.stopPropagation()}>
              <CustomCheckbox onChange={onSelectRow} checked={selected} />
            </span>
          </div>
        </STableData>
        <STableData $width='88px'>
          <div className='text-center'>
            <SStatusDot color={statusColor[transaction.status]} />
          </div>
        </STableData>
        <td>
          <Typography variant='s3' style={{ color: palette.gray700 }}>
            {transaction.type}
          </Typography>
        </td>
        <td>
          <Typography variant='s3' style={{ color: palette.gray700 }}>
            {formatDate(transaction.date)}
          </Typography>
        </td>
        <td>
          <Tooltip
            arrow
            title={transaction.reference_id}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 13,
                  fontFamily: 'Inter',
                },
              },
            }}
          >
            <span style={{ color: palette.gray700 }}>*****{transaction.reference_id?.slice(-5)}</span>
          </Tooltip>
        </td>
        <td>
          <Typography variant='s3' style={{ color: palette.gray700 }}>
            {transaction.description || '-'}
          </Typography>
        </td>
        <STableData $width='320px' onClick={(e) => e.stopPropagation()}>
          <PayeeAutocomplete
            options={payees}
            value={values.payee}
            onChange={(e, val) => onFieldChange('payee', val)}
            onAddNew={setAddPayeeOpen}
            disableClearable={false}
          />
        </STableData>
        <STableData $width='320px' onClick={(e) => e.stopPropagation()}>
          <Autocomplete
            size='small'
            name='account'
            placeholder='Select Account'
            labelKey='account_name'
            options={accounts}
            value={values.account}
            onChange={(e, val) => onFieldChange('account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableClearable={false}
          />
        </STableData>
        <STableData $width='250px'>
          <Input
            size='small'
            name='memo'
            placeholder='Memo'
            value={values.memo || ''}
            onChange={(e) => onFieldChange('memo', e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        </STableData>
        <STableData $width='170px'>
          <InputWithIcon
            ref={paymentRef}
            size='small'
            type='number'
            name='payment'
            placeholder='0.00'
            value={values.payment || ''}
            onChange={(e) => onFieldChange('payment', e.target.value)}
            onKeyDown={blockNonPositiveNumbers}
            disabled={disabledInput === 'payment'}
            onClick={(e) => onAmountInputClick(e, 'payment')}
          />
        </STableData>
        <STableData $width='170px'>
          <InputWithIcon
            ref={depositRef}
            size='small'
            type='number'
            name='deposit'
            placeholder='0.00'
            value={values.deposit || ''}
            onChange={(e) => onFieldChange('deposit', e.target.value)}
            onKeyDown={blockNonPositiveNumbers}
            disabled={disabledInput === 'deposit'}
            onClick={(e) => onAmountInputClick(e, 'deposit')}
          />
        </STableData>
      </STableRow>
      {ruleToSuggest && (
        <SuggestNewRule rule={ruleToSuggest} onCreateSuccess={onCreateRuleSuccess} accountId={transaction.account_id} />
      )}
      {!!addPayeeOpen && (
        <AddPayee
          open={!!addPayeeOpen}
          name={addPayeeOpen}
          onClose={() => setAddPayeeOpen(false)}
          onSuccess={onAddNewPayeeSuccess}
        />
      )}
    </>
  );
};

export default TransactionRow;
