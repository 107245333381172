import { formatNumber } from 'utils/constants';

const currencies = {
  $: 'USD',
  C$: 'CAD',
  '₱': 'MXN',
};

export const generateShipmentCharges = (
  doc,
  pageWidth,
  chargesStart,
  shipmentCharges,
  chargeTypes,
  pickupStops,
  shipment_notes,
  customer_note,
  accountingSettings,
  currency
) => {
  let chargesEnd;
  let termsEnd;
  let notesEnd;
  let noticeEnd;

  if (customer_note && shipment_notes[0]?.notes !== customer_note) {
    shipment_notes.unshift({ show_on_invoice: 1, notes: customer_note });
  }

  const total_amount = shipmentCharges.reduce((acc, cur) => acc + cur.sub_total, 0);
  const total_weight = pickupStops.reduce((acc, cur) => acc + Number(cur.reported_weight), 0);
  const total_qty = pickupStops.reduce((acc, cur) => acc + cur.reported_quantity, 0);

  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#000000');
  doc.setFontSize(8);

  [...(shipmentCharges || [])]?.forEach((item, index, array) => {
    // description of articles
    doc.text(
      item.charge_type_name?.title
        ? `${item.charge_type_name.title}`
        : `${chargeTypes.find((el) => el.id === item.charge_type).title}`,
      8,
      chargesStart + (index + 1) * 5
    );

    // Quantity
    doc.text(`${formatNumber(item.quantity)}`, 132, chargesStart + (index + 1) * 5);

    // Rate
    doc.text(`${formatNumber(item.rate)}`, 162, chargesStart + (index + 1) * 5);

    // Amount
    doc.text(`${formatNumber(item.sub_total)}`, 188, chargesStart + (index + 1) * 5);

    if (index !== array.length - 1) {
      doc.setDrawColor(188, 194, 206);
      doc.line(8, chargesStart + 1.5 + (index + 1) * 5, pageWidth - 8, chargesStart + 1.5 + (index + 1) * 5);
    }

    doc.setDrawColor(0, 0, 0);
    if (index === array.length - 1) {
      chargesEnd = chargesStart + (index + 1) * 5 + 5;
      doc.line(132, chargesEnd - 2, pageWidth - 8, chargesEnd - 2);
      doc.setFont('Inter', 'normal', 700);
      doc.text(`TOTAL ${currencies[currency]}`, 132, chargesEnd + 2);
      doc.setFont('Inter', 'normal', 400);
      doc.text(`${formatNumber(total_amount)}`, 188, chargesEnd + 2);

      // Invoice Terms
      if (accountingSettings?.invoice_terms) {
        doc.setFont('Inter', 'normal', 700);
        doc.text('Invoice Terms', 8, chargesEnd + 20);

        doc.setFont('Inter', 'normal', 400);
        doc.text(`${accountingSettings.invoice_terms}`, 8, chargesEnd + 24, {
          maxWidth: pageWidth - 16,
        });
        termsEnd = chargesEnd + 24;
      }

      const notesToShow = shipment_notes.filter((note) => note.show_on_invoice === 1);
      const notesStart = accountingSettings.invoice_terms ? termsEnd + 12 : chargesEnd + 20;

      // Shipment notes
      if (notesToShow.length > 0) {
        doc.setFont('Inter', 'normal', 700);
        doc.text('Invoice Notes', 8, notesStart);

        doc.setFont('Inter', 'normal', 400);

        notesToShow.forEach((note, index) => {
          doc.text(`${note.notes}`, 8, notesStart + (index + 1) * 4, { maxWidth: pageWidth - 16 });
          if (index === notesToShow.length - 1) {
            notesEnd = notesStart + (index + 3) * 4;
          }
        });
      }

      let noticeStart = chargesEnd + 20;

      if (accountingSettings?.invoice_terms) {
        noticeStart = termsEnd + 12;
      }

      if (notesToShow.length > 0) {
        noticeStart = notesEnd;
      }

      // Notice of Assignment
      if (accountingSettings.is_factoring === 1 && accountingSettings?.notice) {
        doc.setFontSize(8);
        doc.setFont('Inter', 'normal', 700);
        doc.text('Notice of Assignment', 8, noticeStart);

        doc.setFontSize(8);
        doc.setFont('Inter', 'normal', 400);
        doc.text(`${accountingSettings?.notice}`, 8, noticeStart + 4, {
          maxWidth: pageWidth - 16,
        });
        noticeEnd = noticeStart + 36;
      }
    }
  });

  // Total Pieces
  doc.setFont('Inter', 'normal', 700);
  doc.text('Total Pieces', 132, chargesEnd + 8);
  doc.text('Total Weight', 188, chargesEnd + 8);

  doc.setDrawColor(0, 0, 0);
  doc.line(132, chargesEnd + 9, pageWidth - 8, chargesEnd + 9);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${total_qty}`, 132, chargesEnd + 13);
  doc.text(`${total_weight} ${pickupStops[0]?.reported_weight_type.unit || ''}`, 188, chargesEnd + 13);

  return { total_amount, chargesEnd: noticeEnd || notesEnd || termsEnd || chargesEnd };
};
