import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import right from 'assets/icons/drivers/right.svg';
import twoWays from 'assets/icons/drivers/two-ways.svg';
import documentIcon from 'assets/icons/drivers/document.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import classes from 'components/CompletedShipmets/completedShipments.module.css';
import { getStatusParamsInStatus } from 'components/TableShipments/helpers/constants';
import ShipmentSummary from 'componentsV2/Shipment/ShipmentSummary';

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useShipmentColumns = ({ ProfileAlerthandleClick, sortingQuery, sort }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const { formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'shipment_id',
      title: <ColumnHeader title='SHIPMENT ID' field='shipments.shipment_id' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        return (
          <div className='shipment-row-container'>
            <p className='shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
              {rowData?.shipment_id}
            </p>
            <ShipmentSummary shipmentId={rowData.shipment_id} className='ms-1' />
            {rowData?.shipment_notes?.length ? (
              <img
                src={documentIcon}
                alt=''
                className='document-icon'
                onClick={(e) => {
                  navigate({
                    pathname: `/shipment/${rowData?.shipment_id}`,
                    search: '?activeTab=5',
                  });
                  e.stopPropagation();
                }}
              />
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'customer',
      title: <Title title='CUSTOMER' />,
      render: (rowData) =>
        rowData?.shipment_billing.length ? (
          <p
            className='table-data-text'
            style={{
              color: use(palette.gray700, palette.gray200),
            }}
          >
            {rowData?.shipment_billing[0]?.billing_customer?.company_name}
          </p>
        ) : (
          <p
            className='table-data-text'
            onClick={(e) => {
              ProfileAlerthandleClick(e, rowData?.shipment_billing);
              e.stopPropagation();
            }}
            style={{ color: use(palette.gray700, palette.gray200) }}
          >
            Multi Customer
          </p>
        ),
    },
    {
      field: 'equipment',
      title: <Title title='EQUIPMENT' />,
      render: (rowData) => (
        <div className='equipment-container'>
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.equipment?.equipment_id}
          </p>
          <p className='equipment-sub-text'>{rowData?.equipment?.equipment_type?.title}</p>
          {rowData?.equipment?.hooked_to && rowData.status?.id !== 7 ? (
            <>
              <img src={twoWays} alt='' />
              <p
                className='table-data-text'
                style={{
                  color: use(palette.gray700, palette.gray200),
                }}
              >
                {rowData?.equipment?.hooked_to?.equipment_id}
              </p>
              <p className='equipment-sub-text'>
                {`${
                  rowData?.equipment?.hooked_to.length?.length
                    ? (rowData?.equipment?.hooked_to.length?.length || 0) +
                      (rowData?.equipment?.hooked_to.length?.unit || 0)
                    : ''
                } ${rowData?.equipment?.hooked_to?.equipment_type?.title}`}
              </p>
            </>
          ) : null}
          {!!rowData?.last_hooked_equipment && rowData.status?.id === 7 ? (
            <>
              <img src={twoWays} alt='' />
              <p
                className='table-data-text'
                style={{
                  color: use(palette.gray700, palette.gray200),
                }}
              >
                {rowData?.last_hooked_equipment?.equipment_id}
              </p>
              <p className='equipment-sub-text'>
                {`${
                  rowData?.last_hooked_equipment.length?.length
                    ? (rowData?.last_hooked_equipment.length?.length || 0) +
                      (rowData?.last_hooked_equipment.length?.unit || 0)
                    : ''
                } ${rowData?.last_hooked_equipment?.equipment_type?.title}`}
              </p>
            </>
          ) : null}
        </div>
      ),
    },
    {
      field: 'paperwork_received',
      title: <Title title='PW' />,
      render: (rowData) => (rowData?.isPW ? <img src={right} alt='' /> : <DeleteIcon fill={palette.red500} />),
    },
    {
      field: 'status',
      title: <Title title='STATUS' />,
      render: (rowData) => {
        const status = rowData?.status;
        const paramsInStatus = getStatusParamsInStatus(status);
        const { bgStatus, colorStatus, statusName } = paramsInStatus || {};
        return (
          <div className='d-flex justify-content-start'>
            <div className={classes.statusWrapper}>
              <span className={classes.statusColumns} style={{ backgroundColor: bgStatus, color: colorStatus }}>
                {statusName}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'stops',
      title: <ColumnHeader title='STOPS' field='stops' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
          {rowData?.stopDepartureCount} of {rowData?.shipment_stops?.length}
        </p>
      ),
    },
    {
      field: 'origin',
      title: <Title title='ORIGIN' />,
      render: (rowData) => {
        const origin = rowData?.origin?.stop_point;
        return (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {(origin?.city?.name || '') +
              (origin?.city?.name ? ', ' : '') +
              (origin?.state?.name || '') +
              (origin?.state?.name ? ' ' : '') +
              (origin?.zipcode || '')}
          </p>
        );
      },
    },
    {
      field: 'destination',
      title: <Title title='DESTINATION' />,
      render: (rowData) => {
        const destination = rowData?.destination?.stop_point;
        return (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {(destination?.city?.name || '') +
              (destination?.city?.name ? ', ' : '') +
              (destination?.state?.name || '') +
              (destination?.state?.name ? ' ' : '') +
              (destination?.zipcode || '')}
          </p>
        );
      },
    },
    {
      field: 'complete_date',
      title: (
        <ColumnHeader title='COMPLETE DATE' field='shipment_stops.departure_date' onClick={sortingQuery} sort={sort} />
      ),
      render: (rowData) => {
        const departure = rowData?.shipment_stops[Number(rowData?.shipment_stops?.length) - 1];
        return (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {departure?.departure_date ? formatDateTime(departure?.departure_date, true) : '-'}
          </p>
        );
      },
    },
    {
      field: 'total_miles',
      title: <Title title='MILES' />,
      render: (rowData) => {
        const miles = (rowData?.empty_miles || 0) + (rowData?.loaded_miles || 0);
        return (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {' '}
            {miles.toFixed(0)}
          </p>
        );
      },
    },
    {
      field: 'driver_amount',
      title: <Title title='DRIVER PAY' />,
      render: (rowData) => {
        return (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {currency}
            {formatNumber(rowData?.driver_amount)}
          </p>
        );
      },
    },
  ];
};
