import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onRevert, onMarkPaid }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'revertAndEdit':
        onRevert();
        break;
      case 'markPaid':
        onMarkPaid();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl} size={18}>
      <li onClick={(event) => onActionClick(event, 'markPaid')}>Mark Paid</li>
      <li onClick={(event) => onActionClick(event, 'revertAndEdit')}>Revert & Edit</li>
    </ThreeDotActions>
  );
};

export default RowActions;
