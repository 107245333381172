import React, { useState } from 'react';
import './checkboxSelect.css';
import upDown from 'assets/icons/drivers/up-down.png';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';

const CheckBoxSelectBox = ({ options, placeholder, defaultValue, setvalues, values }) => {
  const [isShow, setisShow] = useState(false);
  return (
    <div className='CheckBoxSelectBox'>
      <p onClick={() => setisShow(!isShow)}>
        {values?.length === 0 ? defaultValue : `${values?.length} ${placeholder}`}
        <img src={upDown} alt='' />
      </p>
      {isShow && (
        <ul>
          {options.map((option) => {
            const handleOptions = (e) => {
              if (e) {
                if (values?.includes(option)) return;
                setvalues([...values, option]);
              } else {
                const newVal = values.filter((val, idx, v) => val !== option && v.indexOf(val) === idx);
                setvalues(newVal);
              }
            };
            return (
              <li key={option}>
                <CustomCheckbox onChange={handleOptions} name={option} />
                <span>{option}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CheckBoxSelectBox;
