import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 64px;

  .scrollable-container {
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .chart-boxes-wrapper {
    width: 100%;
    height: 366px;
    display: flex;
  }

  .stat-boxes-wrapper {
    min-width: 1554px;
    display: flex;
  }
`;

export const SBoxWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-left: none;
  padding: 16px;
  min-width: ${({ $minWidth }) => $minWidth || ''};
  max-width: ${({ $maxWidth }) => $maxWidth || ''};

  :first-child {
    padding-left: 2px;
  }

  :last-child {
    padding-right: 2px;
    border-right: none;
  }

  .title-wrapper {
    min-height: 25px;
  }

  .chart-wrapper {
    margin-top: 16px;
    height: calc(100% - 80px);
  }

  canvas {
    height: 100% !important;
  }
`;
