export const initialValues = {
  vendors: [],
  drivers: [],
  ownerOperators: [],
  searchOpenBills: [],
  searchOpenSettlements: [],
  staff: [],

  printChecks: [],
  wontToPay: 2,

  paid_by_account: null,
  startingCheckNumberValue: '',
  defaultStartingCheckNumberValue: '',
  checkStyle: 'voucher',
  useCompanyLogo: true,
  useBankLogo: true,
  addSignature: false,
};
