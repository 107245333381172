import axios from '../services/axios';

export async function getStatsGraph(params) {
  const { data } = await axios.get('accounts-receivable/gets-stats-graph', { params });
  return data;
}

export async function getReceivablesTableData(params, signal) {
  const { data } = await axios.get('accounts-receivable/receivable', { params, signal });
  return data;
}

export async function getReadyStandaloneInvoices(params, signal) {
  const { data } = await axios.get('accounts-receivable/standalone-invoice', { params, signal });
  return data;
}

export async function getReceivablesCSV(params) {
  const { data } = await axios.get('accounts-receivable/export-csv', { params });
  return data;
}

export async function getOpenInvoicesTableData(params, signal) {
  const { data } = await axios.get('accounts-receivable/open-invoice', { params, signal });
  return data;
}

export async function getOpenStandaloneInvoices(params, signal) {
  const { data } = await axios.get('accounts-receivable/open-standalone-invoice', { params, signal });
  return data;
}

export async function getPaidInvoicesTableData(params) {
  const { data } = await axios.get('accounts-receivable/paid-invoice', { params });
  return data;
}

export async function getPaidStandaloneInvoice(params) {
  const { data } = await axios.get('accounts-receivable/standalone-paid-invoice', { params });
  return data;
}

export async function getInvoiceId(params) {
  const { data } = await axios.get('accounts-receivable/get-invoice-id', { params });
  return data;
}

export async function processInvoice(body) {
  const { data } = await axios.post(`accounts-receivable/process-invoice`, body);
  return data;
}

export async function updateInvoicePdf(body) {
  const { data } = await axios.post(`accounts-receivable/update-invoice`, body);
  return data;
}

export async function markInvoicePaid(body) {
  const { data } = await axios.post(`accounts-receivable/mark-invoice-paid`, body);
  return data;
}

export async function revertInvoice(body) {
  const { data } = await axios.post(`accounts-receivable/update-status`, body);
  return data;
}

export async function getDraftStandaloneInvoices(params) {
  const { data } = await axios.get('accounts-receivable/standalone-draft-invoice', { params });
  return data;
}

export async function updateDraftInvoice({ id, body }) {
  const { data } = await axios.post(`accounts-receivable/standalone-invoice/${id}`, body);
  return data;
}

export async function createStandaloneInvoices(body) {
  const { data } = await axios.post('accounts-receivable/standalone-invoice', body);
  return data;
}

export async function deleteStandaloneInvoice(id) {
  const { data } = await axios.delete(`accounts-receivable/standalone-invoice/${id}`);
  return data;
}

export async function editStandaloneInvoices(id, body) {
  const { data } = await axios.post(`accounts-receivable/update-standalone-invoice/${id}`, body);
  return data;
}

export async function deleteInvoiceAttachment(id) {
  const { data } = await axios.delete(`accounts-receivable/standalone-invoice/${id}`);
  return data;
}

export async function revertStandaloneInvoice(body) {
  const { data } = await axios.post('accounts-receivable/revert-standalone-invoice', body);
  return data;
}

export async function getStandaloneRecurringInvoices(params) {
  const { data } = await axios.get('accounts-receivable/standalone-recurring-invoice', { params });
  return data;
}

export async function getInvoiceDetails(body) {
  const { data } = await axios.post('accounts-receivable/invoice-details', body);
  return data;
}

export async function getStandaloneInvoiceNotes(id) {
  const { data } = await axios.get(`accounts-receivable/standalone-invoice-notes/${id}`);
  return data;
}

export async function createStandaloneInvoiceNote(body) {
  const { data } = await axios.post('accounts-receivable/standalone-invoice-notes', body);
  return data;
}

export async function deleteStandaloneInvoiceNote(id) {
  const { data } = await axios.delete(`accounts-receivable/standalone-invoice-note/${id}`);
  return data;
}

export async function refundAndRevertPaidInvoices(body) {
  const { data } = await axios.post(`accounts-receivable/revert-paid-invoice`, body);
  return data;
}

export async function connectQuickbooksService(body) {
  const { data } = await axios.post('customer/quickbook/connect', body);
  return data;
}

export async function authorizeQuickbooksService(body) {
  const { data } = await axios.post('customer/quickbook/callback', body);
  return data;
}

export async function syncWithQuickbook(params) {
  const { data } = await axios.get(`customer/quickbook/sync_data_with_qb`, { params });
  return data;
}
