import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import classNames from 'classnames';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import styles from '../steps/OverView/OverView.module.css';
import { FIRST_STOPS_TOP_MODAL, LAST_STOPS_TOP_MODAL, OVERVIEW_STOPS_TOP_MODAL } from '../../../constats';

const OverLayStopsPlus = ({ onChange, className, index, isShipmentTable, addInleg, lastIndex }) => {
  const { use } = useTheme();
  const target = useRef(null);
  const [isOpenTopModal, setIsOpenTopModal] = useState(false);

  const onChangeHandler = (el) => {
    setIsOpenTopModal(false);
    typeof onChange === 'function' && onChange(el);
  };

  return (
    <>
      <div
        ref={target}
        onClick={() => setIsOpenTopModal(true)}
        style={index === 0 ? { left: -20, top: isShipmentTable ? '43%' : '50%' } : addInleg ? { right: '50%' } : {}}
        className={classNames(styles.overlay_stops_plus_wrapper, className)}
      >
        <PlusIcon />
      </div>
      <Overlay
        placement='bottom'
        rootClose
        show={isOpenTopModal}
        target={target?.current}
        onHide={() => setIsOpenTopModal(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div className={styles.overLay_backDrop}>
            <div
              {...props}
              className={styles.overLay_top_stops_plus_wrapper}
              style={{ ...props.style, backgroundColor: use(palette.white, palette.dark800) }}
            >
              {(index === 0 ? FIRST_STOPS_TOP_MODAL : lastIndex ? LAST_STOPS_TOP_MODAL : OVERVIEW_STOPS_TOP_MODAL).map(
                (el) => {
                  return (
                    <div
                      key={el.id}
                      onClick={() => onChangeHandler(el)}
                      className={classNames(styles.overLay_top_stops_plus_item, use(styles.light, styles.dark))}
                    >
                      <Typography variant='s2'>{el.title}</Typography>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default OverLayStopsPlus;
