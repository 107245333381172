import React from 'react';
import ColumnHeader from 'common/ColumnHeader';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import RowActions from 'pages/Accounting/Payables/TableSection/shared/RowActions';
import Chip from 'common/Chip';
import UserInfo from 'components/UserInfo';

export const useColumns = ({ sort, sortingQuery, onView, onEdit, onDelete, rules, setRuleToView }) => {
  const { formatDateTime } = useDateFormat();

  return [
    {
      field: 'incident_id',
      title: <ColumnHeader title='INCIDENT ID' field='incident_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray900 }}>
          {rowData?.incident_id}
        </p>
      ),
    },
    {
      field: 'incident_date',
      title: <ColumnHeader title='DATE + TIME' field='incident_date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='incident-date' style={{ color: palette.gray900 }}>
          {`${formatDateTime(`${rowData?.incident_date} ${rowData?.incident_time}`)}`}
        </p>
      ),
    },
    {
      field: 'user',
      title: <ColumnHeader title='First, Last Name' field='first_name' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <UserInfo
          size='28px'
          data={{
            ...(rowData.user_type === 'staff' ? rowData?.staff?.staff : rowData.driver),
            id: rowData.user_type === 'staff' ? rowData?.staff?.staff?.id : rowData.driver?.id,
            name:
              rowData.user_type === 'staff'
                ? `${rowData?.staff?.staff?.first_name} ${rowData?.staff?.staff?.last_name}`
                : `${rowData.driver?.fname} ${rowData.driver?.lname}`,
            image: rowData.user_type === 'staff' ? rowData?.staff?.staff?.profile_logo : rowData.driver?.image,
            user_type: rowData.user_type,
            status: rowData.user_type === 'staff' ? rowData?.staff?.staff?.status_data : rowData.driver?.status_data,
          }}
        />
      ),
    },
    {
      field: 'country',
      title: <ColumnHeader title='LOCATION' field='country' nested_field='state' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {' '}
          {(rowData?.city?.name || '') +
            (rowData?.city?.name ? ', ' : '') +
            (rowData?.state?.name || '') +
            (rowData?.state?.name ? ', ' : '') +
            (rowData?.country?.name || '')}
        </p>
      ),
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='REPORT NUMBER' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.report_number}
        </p>
      ),
    },
    {
      field: 'risk_level',
      title: <ColumnHeader title='Risk Level' field='risk_level' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.incident_type?.risk_level === 'L' ? 'Low' : 'High'}
        </p>
      ),
    },
    {
      field: 'incident_type',
      title: <ColumnHeader title='TYPE' field='incident_type' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.incident_type ? rowData?.incident_type?.type : rowData?.custom_incident_type}
        </p>
      ),
    },
    {
      field: 'infraction_type',
      title: <ColumnHeader title='Infraction Type' field='infraction_type' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.incident_type?.infraction_type ? rowData?.incident_type?.infraction_type?.type : '-'}
        </p>
      ),
    },
    {
      field: 'related_to',
      title: <ColumnHeader title='RELATED TO' field='related_to' />,
      render: (row) => (
        <div className='d-flex align-items-center gap-1'>
          {rules?.some((rule) => rule?.incident_types?.some((type) => type?.id === row.incident_type?.id)) ? (
            rules
              ?.filter((rule) => rule?.incident_types?.some((type) => type?.id === row.incident_type?.id))
              .map((rule) => {
                return (
                  <div
                    key={rule.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRuleToView(rule);
                    }}
                  >
                    <Chip
                      label={rule.title}
                      bgColor={palette.indigo0}
                      labelColor={palette.indigo500}
                      sx={{
                        '& .MuiChip-label': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  </div>
                );
              })
          ) : (
            <p className='table-data-text' style={{ color: palette.gray700 }}>
              -
            </p>
          )}
        </div>
      ),
    },
    {
      field: 'incident_action_taken',
      title: <ColumnHeader title='ACTION TAKEN' field='action' sort={sort} onClick={sortingQuery} />,
      render: (rowData) =>
        rowData?.incident_action_taken && (
          <Chip
            label={
              !rowData?.incident_action_taken?.action || rowData?.incident_action_taken?.action === 'Draft'
                ? 'NEW'
                : rowData?.incident_action_taken?.action?.toUpperCase()
            }
            bgColor={`${rowData?.incident_action_taken?.color_code || palette.orange500}11`}
            labelColor={rowData?.incident_action_taken?.color_code || palette.orange500}
          />
        ),
    },
    {
      field: 'added_by',
      title: <ColumnHeader title='ACTION BY' field='added_by' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <div className='more-dowpdown-container'>
          {rowData?.added_by && (
            <UserInfo
              size='28px'
              data={{
                ...rowData?.added_by,
                id: rowData.added_by?.id,
                name: `${rowData.added_by?.first_name} ${rowData?.added_by?.last_name}`,
                image: rowData.added_by?.profile_logo,
                status: rowData.added_by?.status_data,
                user_type: 'staff',
              }}
            />
          )}
        </div>
      ),
    },
    {
      field: '',
      render: (rowData) => (
        <RowActions onView={() => onView(rowData)} onEdit={() => onEdit(rowData)} onDelete={() => onDelete(rowData)} />
      ),
    },
  ];
};
