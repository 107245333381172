/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { CircularProgress } from '@mui/material';
import add from 'assets/icons/drivers/add.png';
import '../AddDriver/datepickerstyle.css';
import 'react-datepicker/dist/react-datepicker.css';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';

import { DriverGroups, AddBasicOwnerDetail, UpdateBasicOwnerDetail } from 'Api/Driver';
import AddressFields from 'common/AddressFields';
import AddGroup from 'componentsV2/Group/AddGroup';
import CustomMultiSelect from 'common/CustomMultiSelect/CustomMultiSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomizedSnackbars from '../../toast/Toast';
import { validationBasicOwnerSchema } from '../AddDriver/ValidationSchema';

const AddOwnerOperatorStep1 = ({
  nextStep,
  Cancel,
  operatorId,
  basicDetails = {},
  stepsDone,
  showError,
  save,
  basicDetailsStates = {},
  module,
}) => {
  const [groups, setGroups] = useState([]);
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onNextloading, setOnNextloading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const {
    phone_no,
    owner_operator_name,
    email_id,
    group,
    address,
    address2,
    country,
    state,
    city,
    zipcode,
    operator_type_id,
    vehicles_can_travel,
    lname,
    fname,
  } = basicDetails || {};

  const onNextStep = (values) => {
    const tempArray = [];
    values.group?.map((item) => tempArray.push(String(item?.id)));
    const { address, country, state, city, zipcode, owner_operator_name, operator_type_id, fname, lname } = values;
    let name = '';
    if (operator_type_id === '1' || operator_type_id === 1) {
      name = `${fname} ${lname}`;
    } else {
      name = owner_operator_name;
    }
    if (stepsDone) {
      setOnNextloading(true);
      UpdateBasicOwnerDetail(
        {
          ...values,
          address1: address,
          country_id: country?.id,
          state_id: state?.id,
          city_id: city?.id,
          zip: zipcode,
          group: tempArray,
          owner_operator_name: name,
        },
        operatorId
      )
        .then((res) => {
          setOnNextloading(false);
          nextStep(
            { formikData: values, stateData: { groups }, operator: res?.data },
            `Owner Operator has been ${stepsDone ? 'updated' : 'created'}`
          );
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      setOnNextloading(true);
      AddBasicOwnerDetail({
        ...values,
        operator_type_id: +operator_type_id,
        address1: address,
        country_id: country?.id,
        state_id: state?.id,
        city_id: city?.id,
        zip: zipcode,
        group: tempArray,
        owner_operator_name: name,
      })
        .then((res) => {
          setOnNextloading(false);
          nextStep(
            { formikData: values, stateData: { groups }, operator: res?.data },
            `Owner Operator has been ${stepsDone ? 'updated' : 'created'}`
          );
          if (save) {
            if (module) {
              save();
            } else {
              save(res.data);
            }
          }
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors, isValid, validateForm } = useForm({
    initialValues: {
      owner_operator_name: owner_operator_name || '',
      phone_no: phone_no || '',
      email_id: email_id || '',
      group: group || [],
      address: address || '',
      address2: address2 || '',
      state: state || null,
      country: country || null,
      city: city || null,
      zipcode: zipcode || '',
      operator_type_id: operator_type_id || 1,
      vehicles_can_travel: vehicles_can_travel || [],
      lname: lname || '',
      fname: fname || '',
    },
    validationSchema: validationBasicOwnerSchema,
    onSubmit: onNextStep,
  });

  const whitelist = ['US', 'CA', 'MX'];

  const onQuickAdd = async () => {
    try {
      await validateForm(values);
      const tempArray = [];
      values.group?.map((item) => tempArray.push(String(item?.id)));
      setLoading(true);
      const { operator_type_id, address, country, state, city, fname, lname, owner_operator_name, zipcode } = values;
      let name = '';
      if (operator_type_id === '1' || operator_type_id === 1) {
        name = `${fname} ${lname}`;
      } else {
        name = owner_operator_name;
      }
      AddBasicOwnerDetail({
        ...values,
        operator_type_id: +operator_type_id,
        address1: address,
        country_id: country?.id,
        state_id: state?.id,
        city_id: city?.id,
        zip: zipcode,
        group: tempArray,
        owner_operator_name: name,
      })
        .then((res) => {
          setShowMessage({
            message: 'Owner Operator has been created',
            visible: true,
            type: 'success',
          });
          setTimeout(() => {
            Cancel();
            setLoading(false);
            if (module) {
              save();
            } else {
              save(res.data);
            }
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setShowMessage({
            message: getErrorMessage(error),
            visible: true,
            type: 'error',
          });
        });
    } catch (e) {
      // Do nothing
    }
  };

  const { use } = useTheme();

  useEffect(() => {
    if (stepsDone) {
      const { groups } = basicDetailsStates;
      setGroups(groups);
      return;
    }
    handleChange('owner_operator_name', '');
    DriverGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const saveGroup = (val) => {
    setGroups([...groups, val]);
    setValues((prevState) => ({
      ...prevState,
      group: [...prevState.group, val],
    }));
  };

  const onChangeVehicles = (value) => {
    const temp = values.vehicles_can_travel;
    const hasValueIndex = temp.indexOf(value);
    if (hasValueIndex >= 0) {
      temp.splice(hasValueIndex, 1);
    } else {
      temp.push(value);
    }
    handleChange('vehicles_can_travel', temp);
  };

  const handleFieldChange = (event, value) => {
    if (event?.target) {
      const { name, value } = event.target;
      handleChange(name, value);
    } else {
      handleChange(event, value);
    }
  };

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className='basic-container driver-style-wrap'>
          <div>
            <p className='driver-type-text text-focus' style={{ color: use('black', palette.gray200) }}>
              What type of owner operator is this?
            </p>
          </div>
          <div className='d-flex' id='my-radio-group'>
            <div className='d-flex' role='group' aria-labelledby='my-radio-group'>
              <div>
                <p className='basic-start-driver-type-wrap'>
                  <input type='radio' name='operator_type_id' defaultChecked onChange={handleChange} value={1} />
                  <span
                    className='text-style'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      marginLeft: '0',
                    }}
                  >
                    Individual
                  </span>
                </p>
              </div>
              <div>
                <p className='basic-start-driver-type-wrap'>
                  <input type='radio' name='operator_type_id' onChange={handleChange} value={2} />
                  <span
                    className='text-style'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      marginLeft: '0',
                    }}
                  >
                    Company
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className='d-flex'>
            {values.operator_type_id === 1 || values.operator_type_id === '1' ? (
              <>
                <div style={{ marginRight: '20px' }}>
                  <p
                    className='text-style'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      marginLeft: '0',
                    }}
                  >
                    First Name <span className='required'>*</span>
                  </p>
                  <div className='d-flex flex-column'>
                    <div
                      className='selector-name name-wrap'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    >
                      <input
                        type='text'
                        name='fname'
                        id='fname'
                        className='input-field'
                        placeholder='Wilson'
                        onChange={handleChange}
                        value={values.fname}
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      />
                    </div>
                    {touchedErrors?.fname ? <span className='error-message-staff'>{touchedErrors?.fname}</span> : null}
                  </div>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p
                    className='text-style'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      marginLeft: '0',
                    }}
                  >
                    Last Name <span className='required'>*</span>
                  </p>
                  <div className='d-flex flex-column'>
                    <div
                      className='selector-name name-wrap'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    >
                      <input
                        type='text'
                        name='lname'
                        id='lname'
                        className='input-field'
                        placeholder='Lubin'
                        onChange={handleChange}
                        value={values.lname}
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      />
                    </div>
                    {touchedErrors?.lname ? <span className='error-message-staff'>{touchedErrors?.lname}</span> : null}
                  </div>
                </div>
              </>
            ) : values.operator_type_id === 2 || values.operator_type_id === '2' ? (
              <div style={{ marginRight: '20px' }}>
                <p
                  className='text-style'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    marginLeft: '0',
                  }}
                >
                  Company Name <span className='required'>*</span>
                </p>
                <div className='d-flex flex-column'>
                  <div
                    className='selector-name company-name-wrap'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <input
                      type='text'
                      name='owner_operator_name'
                      id='owner_operator_name'
                      className='input-field'
                      placeholder='Company Name'
                      onChange={handleChange}
                      value={values.owner_operator_name}
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    />
                  </div>
                  {touchedErrors?.owner_operator_name ? (
                    <span className='error-message'>{touchedErrors?.owner_operator_name}</span>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Phone Number <span className='required'>*</span>
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <PhoneInput
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  addInternationalOption={false}
                  className='input-field-phone'
                  value={values.phone_no}
                  countries={whitelist}
                  onChange={(val) => handleChange('phone_no', val)}
                  defaultCountry='US'
                  placeholder='(555) 555-1234'
                />
              </div>
              {touchedErrors?.phone_no ? <span className='error-message'>{touchedErrors?.phone_no}</span> : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Email <span className='required'>*</span>
              </p>
              <div
                className='selector-name email-selector-owner'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  className='input-field'
                  name='email_id'
                  value={values.email_id}
                  onChange={handleChange}
                  placeholder='example@gmail.com'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
            </div>
            {touchedErrors.email_id ? <span className='error-message'>{touchedErrors.email_id}</span> : null}
          </div>

          <div className='mt-4' style={{ width: '814px' }}>
            <AddressFields
              values={values}
              handleChange={handleFieldChange}
              isOneRow
              limitCountries
              touchedErrors={touchedErrors}
            />
          </div>

          <div>
            <p
              className='driver-type-text text-focus'
              style={{
                color: use('black', palette.gray200),
                marginTop: '20px',
                marginbottom: '0',
              }}
            >
              Vehicles Can Travel To
            </p>
            <div className='d-flex' style={{ marginTop: '4px' }}>
              <div className='d-flex justify-content-center align-items-center'>
                <input
                  className='check-input'
                  type='checkbox'
                  checked={values.vehicles_can_travel.indexOf('1') >= 0}
                  onChange={() => onChangeVehicles('1')}
                />
                <label
                  className='text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  USA
                </label>
                <input
                  className='check-input'
                  type='checkbox'
                  checked={values.vehicles_can_travel.indexOf('2') >= 0}
                  onChange={() => onChangeVehicles('2')}
                />
                <label
                  className=' text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  Canada
                </label>
                <input
                  className='check-input'
                  type='checkbox'
                  checked={values.vehicles_can_travel.indexOf('3') >= 0}
                  onChange={() => onChangeVehicles('3')}
                />
                <label
                  className='text-style'
                  htmlFor='flexCheckDefault'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  Mexico
                </label>
              </div>
            </div>
          </div>
          <div>
            <div
              className='d-flex  justify-content-between align-items-center'
              style={{ marginTop: '4px', width: '300px' }}
            >
              <p
                className='driver-type-text'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginTop: '20px',
                }}
              >
                Add Tag(s)
              </p>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginTop: '10px',
                  marginbottom: '0px',
                }}
                onClick={() => setOpenAddGroup(true)}
              >
                <img src={add} className='' alt='add' /> New Tags
              </p>
            </div>
            <div style={{ marginRight: '12px' }}>
              <div className='custom-select-wrap-contain-sub'>
                <CustomMultiSelect
                  placeholder={!groups?.length ? 'No Data' : null}
                  selectedValues={values.group}
                  propertyName='group_name'
                  options={groups}
                  isUseForm
                  handleFieldChange={(value) => handleChange('group', value)}
                />
              </div>
              <div style={{ marginRight: '12px' }} className='d-flex justify-content-center align-items-center' />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        {!stepsDone && (
          <Button type='button' className='cancel-button' onClick={Cancel}>
            Cancel
          </Button>
        )}
        <div className='Basic-detail-footer-btn'>
          {!stepsDone &&
            (loading ? (
              <div className='me-4'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button type='button' onClick={onQuickAdd} className='quick-add' disabled={!isValid}>
                Quick Add
              </Button>
            ))}
          {onNextloading ? (
            <div className='ms-2'>
              <CircularProgress size={30} />
            </div>
          ) : (
            <CustomButton
              type='secondary'
              title='Next Step'
              styleButton={{ padding: '4px 12px', margin: '0 16px 0 0' }}
              onClick={handleSubmit}
              disabled={!isValid}
            />
          )}
        </div>
      </Modal.Footer>
      {!!openAddGroup && <AddGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={saveGroup} />}
      {showMessage && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </Form>
  );
};

export default AddOwnerOperatorStep1;
