import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ThreeDots } from 'assets/icons/drivers/moreInfo.svg';
import Input from 'common/Input';
import Tooltip from 'common/Tooltip';
import Uploader from 'common/Uploader';
import ViewPdf from 'components/ViewPdf';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { GetInsurance } from 'Api/Insurance';
import { uploadDriverDocument as uploadStaffDocument } from 'Api/Staff';
import { uploadDriverDocument } from 'Api/Driver';
import { createStaffPolicies, getStaffPolicies } from 'Api/StaffV2';
import { createDriverPolicies, getDriverPolicies } from 'Api/DriverV2';
import { capitalize } from 'lodash';
import { validationSchema } from 'components/Policies/validationSchema';
import { getInitialValues } from './Policies.data';
import { SWrapper, SMergedInputs } from './Policies.styles';

const Policies = ({ userId, userType }) => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [loadingSave, setLoadingSave] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [insuranceSettings, setInsuranceSettings] = useState(null);
  const [policies, setPolicies] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const onSubmit = async (values) => {
    try {
      setLoadingSave(true);
      const body = {
        use_default_worker_compensation: Number(values.use_default_worker_compensation || false),
        compensation_policy_id: values.compensation_policy_id || null,
        compensation_policy_expiry_date: values.compensation_policy_expiry_date
          ? moment(values.compensation_policy_expiry_date).format('YYYY-MM-DD')
          : null,
        compensation_policy_attachment: values.compensation_policy_attachment || null,
        excused_absences: policies?.excused_absences || (userType === 'driver' ? null : 0),
        unexcused_absences: policies?.unexcused_absences || (userType === 'driver' ? null : 0),
        vacations_allowed: policies?.vacations_allowed || (userType === 'driver' ? null : 0),
      };
      if (userType === 'driver') {
        body.driver_id = userId;
        await createDriverPolicies(body);
      } else {
        body.staff_id = userId;
        await createStaffPolicies(body);
      }

      showToaster({ type: 'success', message: `${capitalize(userType)} policies have been successfully saved!` });
      await getPolicies();
      setEditMode(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSave(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues, handleBlur } = useForm({
    initialValues: getInitialValues(policies),
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const getPolicies = async () => {
    try {
      const { data } = userType === 'driver' ? await getDriverPolicies(userId) : await getStaffPolicies(userId);
      setPolicies(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getInsuranceSettings = async () => {
    try {
      const { data } = await GetInsurance();
      setInsuranceSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const uploadDocument = async (file) => {
    if (userType === 'driver') {
      const { data } = await uploadDriverDocument(file, userId);
      handleChange('compensation_policy_attachment', data.full_path);
    } else {
      const { data } = await uploadStaffDocument(file, userId);
      handleChange('compensation_policy_attachment', data.full_path);
    }
  };

  const onApplyInsuranceFromSettings = (checked) => {
    if (checked && insuranceSettings?.workers_compensation_and_employers_liability_policy_active) {
      setValues((prevState) => ({
        ...prevState,
        compensation_policy_id: insuranceSettings.workers_compensation_and_employers_liability_policy_id,
        compensation_policy_expiry_date: insuranceSettings.workers_compensation_and_employers_liability_expire_date
          ? moment(insuranceSettings.workers_compensation_and_employers_liability_expire_date).toDate()
          : null,
        compensation_policy_attachment:
          insuranceSettings.workers_compensation_and_employers_liability_policy_attach || '',
        use_default_worker_compensation: checked,
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        compensation_policy_id: policies?.compensation_policy_id || '',
        compensation_policy_expiry_date: policies?.compensation_policy_expiry_date
          ? new Date(policies?.compensation_policy_expiry_date)
          : null,
        compensation_policy_attachment: policies?.compensation_policy_attachment || null,
        use_default_worker_compensation: checked,
      }));
    }
  };

  useEffect(() => {
    getInsuranceSettings();
    getPolicies();
  }, []);

  return (
    <SWrapper>
      <div className='policy-header'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Policies
        </Typography>

        {editMode ? (
          <div className='d-flex gap-2'>
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '1px 12px', margin: 0 }}
              type='secondary'
              title='Cancel'
              onClick={() => setEditMode(false)}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '1px 12px', margin: 0 }}
              type='primary'
              title='Save'
              onClick={handleSubmit}
              disabled={loadingSave}
            />
          </div>
        ) : (
          <div>{!hideSensitiveInfo && <ThreeDots className='edit-icon' onClick={() => setEditMode(true)} />}</div>
        )}
      </div>
      <div className='policy-content'>
        <div className='d-flex align-items-center gap-4 mb-2'>
          <Typography variant='button2'>Workers Compensation and Employers Liability</Typography>
          {editMode && (
            <CustomCheckbox
              checked={values.use_default_worker_compensation}
              onChange={(checked) => onApplyInsuranceFromSettings(checked)}
              disabled={!insuranceSettings?.workers_compensation_and_employers_liability_policy_active}
            >
              <Typography variant='c1' style={{ marginLeft: '8px' }}>
                Apply from Company Policy - Workers Compensation
              </Typography>
              {!insuranceSettings?.workers_compensation_and_employers_liability_policy_active && (
                <Tooltip title='Please, enable and provide insurance policy info in company settings to enable this option!'>
                  <InfoIcon width={14} height={14} className='ms-2' />
                </Tooltip>
              )}
            </CustomCheckbox>
          )}
        </div>
        {editMode ? (
          <div className='mt-2'>
            <div className='d-flex gap-3'>
              <SMergedInputs>
                <Input
                  width='230px'
                  label='Policy ID'
                  name='compensation_policy_id'
                  placeholder='Policy ID'
                  onChange={(e) => handleChange('compensation_policy_id', e.target.value)}
                  value={values.compensation_policy_id}
                  error={touchedErrors.compensation_policy_id}
                  disabled={!!values.use_default_worker_compensation}
                />
                <div>
                  <InputLabel>Expiration</InputLabel>
                  <DatePicker
                    width='150px'
                    borderRadius='0 6px 6px 0'
                    onBlur={handleBlur}
                    name='compensation_policy_expiry_date'
                    value={values.compensation_policy_expiry_date}
                    onChange={(val) => handleChange('compensation_policy_expiry_date', val)}
                    disabled={!!values.use_default_worker_compensation}
                    error={touchedErrors?.compensation_policy_expiry_date}
                  />
                </div>
              </SMergedInputs>
              <div className='uploader-wrapper'>
                <Uploader
                  type={3}
                  document={values.compensation_policy_attachment}
                  onDrop={(files) => uploadDocument(files[0])}
                  onRemove={() => handleChange('compensation_policy_attachment', null)}
                  error={touchedErrors.compensation_policy_attachment}
                  disabled={!!values.use_default_worker_compensation}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='section-wrapper'>
            <div className='d-flex justify-content-between'>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Policy ID
              </Typography>
              <Typography variant='b2'>{policies?.compensation_policy_id || '-'}</Typography>
            </div>
            <div className='d-flex justify-content-between'>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Expiration
              </Typography>
              <Typography variant='b2'>
                {policies?.compensation_policy_expiry_date
                  ? formatDate(policies?.compensation_policy_expiry_date)
                  : '-'}
              </Typography>
            </div>
            <div className='d-flex justify-content-between'>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Attachment
              </Typography>
              {policies?.compensation_policy_attachment ? (
                <Typography
                  variant='b2'
                  style={{ color: palette.indigo500 }}
                  className='view-attachment'
                  onClick={() => setPdfUrl(policies.compensation_policy_attachment)}
                >
                  View
                </Typography>
              ) : (
                <Typography variant='b2'>-</Typography>
              )}
            </div>
          </div>
        )}
      </div>
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Attachment' />
    </SWrapper>
  );
};

export default Policies;
