import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWidgetsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  [data-react-beautiful-dnd-droppable] {
    gap: 24px;
    width: 100%;
  }

  [data-react-beautiful-dnd-drag-handle] {
    flex: 1;
  }
`;

export const SWidget = styled.div`
  width: 100%;
  height: 130px;
  border: 1px dashed ${palette.gray200};
  border-radius: 8px;
  padding: 10px;

  .drag-arrow-text {
    display: flex;
    height: calc(100% - 34px);
    align-items: center;
    justify-content: center;
  }
`;

export const SVerticalDrag = styled.div`
  width: 100%;
  border: 1px dashed ${palette.gray200};
  border-radius: 8px;
  padding: 12px 20px 20px;

  .widget-section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    svg {
      transform: rotate(90deg);
    }

    & > * {
      flex: 1;
    }
  }
`;
