import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;

  .settings-header {
    margin-bottom: 8px;
  }
`;

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 6px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .settings-header-item {
    width: calc(100% / 3 - 130px);
  }

  .settings-header-item:first-child {
    width: 260px;
  }

  .settings-header-item:nth-child(2) {
    width: 80px;
  }

  .settings-header-item:last-child {
    width: 70px;
  }
`;

export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};
  transition: background 0.3s;
  cursor: pointer;

  .settings-table-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% / 3 - 130px);
  }

  .settings-table-item:first-child {
    width: 260px;
  }

  .settings-table-item:nth-child(2) {
    width: 80px;
  }

  .settings-table-item:last-child {
    width: 70px;
  }

  .settings-table-actions-wrapper {
    visibility: hidden;
  }

  :hover {
    background: ${palette.indigo0};

    .settings-table-actions-wrapper {
      visibility: visible;
    }
  }
`;

export const SPinWrapper = styled.div`
  width: fit-content;

  div.verification-input input {
    height: 32px;
    font-size: 18px;
  }
`;
