export const infoOptions = [
  {
    title: 'Federal FCRA summary of Rights Acknowledgment',
    content: `By checking this box, I (a) acknowledge that I have read and understand the federal FCRA Summary of Rights and have been given the opportunity to copy/print the Summary of rights and (b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`,
  },
  {
    title: 'PSP Disclosure and Authorization',
    content: `By checking the box, I (a) acknowledge that I have read and understand the PSP Disclosure and Authorization and also have been given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`,
  },
  {
    title: 'FRCA Disclosure',
    content: `By checking the box, I (a) acknowledge that I have read and understand the above and have been given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`,
  },
  {
    title: 'FRCA Authorization',
    content: `By checking the box, I (a) acknowledge that I have read and understand the above and have been given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`,
  },
  {
    title: 'Employment Verification Acknowledgment and Release (DOT Drug and Alcohol)',
    content: `By checking the box, I (a) acknowledge that I have read and understand the above and have been given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`,
  },
];
