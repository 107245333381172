import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';

import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import sm from 'pages/CompanySettings/pagesComponents/RejectReasons/RejectReasons.module.css';
import { createEdiRejectionReasons, deleteEdiRejectionReasons, getEdiRejectionReasons } from 'Api/Planner';
import ItemForm from './ItemForm';
import PageHeader from '../../components/PageHeader';
import { SActions, SAddMore, SCircularProgress, SContentRow, SRow, SWrapper } from '../Payroll/Payroll.styles';

const RejectReasons = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [rejectReasons, setRejectReasons] = useState([]);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const getRejectionReasons = async () => {
    try {
      const { data } = await getEdiRejectionReasons();
      setRejectReasons(data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRejectionReasons();
  }, []);

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createNewRejectReason = async (values, id) => {
    setLoadingCreate(true);
    try {
      const data = { title: values?.title, code: values.code };
      await createEdiRejectionReasons(data);
      await getRejectionReasons();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Reject reason has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const deleteItem = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteEdiRejectionReasons(id);
      const newReasons = rejectReasons.filter((item) => item.id !== id);
      setRejectReasons(newReasons);
      showToaster({ type: 'success', message: 'Reject reason has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingDelete(false);
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getRejectionReasons();
  }, []);

  if (loading) {
    return (
      <SWrapper>
        <SCircularProgress size={26} />
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      <PageHeader
        title='Reject Reason Codes'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={() => onSaveAndNext('saveAndNext')}
        loading={false}
        hideSave
      />
      <Divider margin='8px 0' />
      <SRow>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: palette.gray700 }}>
            TYPE
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: palette.gray700 }}>
            CODE
          </Typography>
        </div>
        <div className='settings-header-item'>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              ACTIONS
            </Typography>
          </div>
        </div>
      </SRow>
      {rejectReasons.map((item) => (
        <SContentRow key={item.id}>
          <div className='settings-table-item'>
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {item.title}
            </Typography>
          </div>
          <div className='settings-table-item'>
            <p className={sm['list-text']}>
              <span className={sm['list-styled-text']} style={{ margin: 0 }}>
                {item.code}
              </span>
            </p>
          </div>
          <SActions className='settings-table-item settings-table-actions-wrapper'>
            {loadingDelete ? (
              <span className='loading-wrapper'>
                <CircularProgress size={16} />
              </span>
            ) : (
              !item.default && (
                <span className='action-wrapper' onClick={() => deleteItem(item.id)}>
                  <DeleteIcon width={11} height={11} fill={palette.red500} />
                </span>
              )
            )}
          </SActions>
        </SContentRow>
      ))}
      {forms.map((item) => (
        <ItemForm
          key={item.id}
          onSubmit={(values) => createNewRejectReason(values, item.id)}
          onDelete={() => deleteFormItem(item.id)}
          loading={loadingCreate}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          Add Type...
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default RejectReasons;
