import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import { useFormik } from 'formik';
import Popover from '@mui/material/Popover';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getValidateData, importBasicDriverData } from 'Api/Driver';
import More from 'assets/icons/drivers/more.svg';
import warn from 'assets/icons/drivers/warn.svg';
import { getErrorMessage } from 'utils/error';
import tickgray from 'assets/icons/drivers/tickgray.svg';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomizedSnackbars from 'components/toast/Toast';
import 'components/ShipmentTab/ShipmentTab.css';
import { importDriverValidationSchema } from '../AddDriver/ValidationSchema';
import DriverAlertIcon from './WarningPopover';
import './DriverUserModal.css';

const whitelist = ['US', 'CA', 'MX'];

const driverMapping = {
  'First name': 'fname',
  'First Name': 'fname',
  'Last Name': 'lname',
  'Phone Number': 'phone_number',
};
export default function DriverUserModal({ ...props }) {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [onSubmitloading, setOnSubmitloading] = useState(false);
  const [onClickEdit, setOnClickEdit] = useState(false);
  const [masterChecked, setMasterChecked] = useState(true);
  const [list, setList] = useState([]);
  const [profileAlert, setProfileAlert] = useState(null);
  const [alertData, setAlertData] = useState();
  const [initialValues, setInitialValues] = useState([]);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const formik = useFormik({
    initialValues: {
      drivers: [
        {
          fname: '',
          lname: '',
          phone_number: '',
          can_travel_canada: false,
          can_travel_mexico: false,
          can_travel_usa: true,
          selected: true,
          id: null,
          isEdit: false,
          driver_type: 'company',
          errors: {
            phoneError: '',
          },
        },
      ],
    },
    validationSchema: importDriverValidationSchema,
  });

  function csvToArray(str, delimiter = ',') {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str
      .slice(0, str.indexOf('\n'))
      .split(delimiter)
      .map((heading) => driverMapping[heading]);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const rowFilter = rows?.filter((val) => val);
    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rowFilter?.map((row) => {
      const values = row.split(delimiter);
      const el = headers.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      setLoading(false);
      return el;
    });
    const validate = {
      phone_number: [],
    };
    if (arr?.length) {
      for (let i = 0; i < arr?.length; i++) {
        formik.setFieldValue(`drivers[${i}].id`, i);
        formik.setFieldValue(`drivers[${i}].fname`, arr[i]?.fname);
        formik.setFieldValue(`drivers[${i}].lname`, arr[i]?.lname);
        formik.setFieldValue(
          `drivers[${i}].phone_number`,
          arr[i]?.phone_number && arr[i]?.phone_number?.startsWith('+')
            ? arr[i]?.phone_number
            : `+${arr[i]?.phone_number || 1}`
        );
        formik.setFieldValue(`drivers[${i}].selected`, true);
        formik.setFieldValue(`drivers[${i}].isEdit`, false);
        formik.setFieldValue(`drivers[${i}].driver_type`, 'company');
        formik.setFieldValue(`drivers[${i}].can_travel_usa`, true);

        validate.phone_number.push(
          arr[i]?.phone_number?.startsWith('+') ? arr[i]?.phone_number : `+${arr[i]?.phone_number}`
        );
      }
    }
    getValidateData(validate).then((res) => {
      for (let i = 0; i < arr?.length; i++) {
        if (res?.phoneCheck[i] === 1) {
          formik.setFieldValue(`drivers[${i}].errors.phoneError`, 'Phone Number already exist');
        }
      }
    });

    setLoading(false);
    setMasterChecked(true);
    return arr;
  }

  const validateAgain = (rowData, i) => {
    setOnClickEdit(true);

    const validate = {
      phone_number: [rowData.phone_number],
    };

    getValidateData(validate)
      .then((res) => {
        if (res?.phoneCheck[0] === 1) {
          formik.setFieldValue(`drivers[${i}].errors.phoneError`, 'Phone Number already exist');
        } else if (res?.phoneCheck[0] === 0) {
          formik.setFieldValue(`drivers[${i}].errors.phoneError`, '');
        }
        formik.setFieldValue(`drivers[${i}].isEdit`, false);
        setOnClickEdit(false);
        setInitialValues((prevState) => prevState.filter((i) => i.id !== rowData.id));
      })
      .catch(() => {
        setOnClickEdit(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const input = props?.document[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      csvToArray(text);
    };

    reader.readAsText(input);
  }, [props?.document]);

  const importUsers = () => {
    setOnSubmitloading(true);
    const importDriverData = {
      fname: [],
      lname: [],
      phone_number: [],
      can_travel_usa: [],
      driver_type: [],
    };
    for (const val of formik.values?.drivers || []) {
      if (val?.selected) {
        importDriverData.fname.push(val?.fname);
        importDriverData.lname.push(val.lname);
        importDriverData.phone_number.push(val.phone_number);
        importDriverData.can_travel_usa.push(val.can_travel_usa);
        importDriverData.driver_type.push(val.driver_type);
      }
    }
    importBasicDriverData(importDriverData)
      .then(() => {
        setOnSubmitloading(false);
        setShowMessage({
          message: 'Driver has been created',
          visible: true,
          type: 'success',
        });
        props?.getDrivers();
        setTimeout(() => {
          props.onHide();
          props.close();
        }, 1000);
      })
      .catch((error) => {
        setOnSubmitloading(false);
        setShowMessage({
          message: getErrorMessage(error),
          visible: true,
          type: 'error',
        });
      });
  };

  const onMasterCheck = (e) => {
    const tempList = formik?.values?.drivers;
    tempList.forEach((user) => {
      user.selected = e;
    });

    // Update State
    setList(tempList);
    setMasterChecked(e);
  };
  const ProfileAlertOpen = Boolean(profileAlert);
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;
  const ProfileAlerthandleClick = (e, alerts, formikErrors, canTravelError) => {
    setProfileAlert(e.currentTarget);
    setAlertData({ ...alerts, formikErrors, canTravelError });
  };
  const onItemCheck = (e, item) => {
    const tempList = formik?.values?.drivers;
    const updatedList = tempList.map((user) => {
      if (user.id === item) {
        user.selected = e;
      }
      return user;
    });

    setList(updatedList);
    const totalItems = list?.length;
    const totalCheckedItems = updatedList.filter((e) => e.selected)?.length;

    // Update State
    setMasterChecked(totalItems === totalCheckedItems);
  };

  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  let validate = false;
  const isValid = useMemo(() => {
    const selected = formik?.values?.drivers?.filter((e) => e.selected);
    return selected?.every(
      (i) =>
        (!i?.errors || Object.keys(i.errors)?.every((key) => !i.errors[key])) &&
        !!i?.fname &&
        !!i?.lname &&
        !!i?.phone_number &&
        (!!i.can_travel_mexico || !!i.can_travel_canada || !!i.can_travel_usa)
    );
  }, [formik]);
  const totalSelected = formik?.values?.drivers?.filter((e) => e.selected)?.length;

  return (
    <Modal {...props} size='lg' centered fullscreen='lg-down' dialogClassName='modal-custom-width-driver'>
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title className='document-modal' id='contained-modal-title-vcenter'>
          <h3 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Import Driver&#40;s&#41;
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='driver-style-wrap driver-user-modal'
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
      >
        <div className='table-wrap  import-user-data-main-wrap'>
          <div className='sub-table-container sub-table-container--import-user'>
            {loading ? (
              <TablePreLoader styleWrapper={{ marginTop: 0 }} />
            ) : (
              <div className='sub-table-container' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                <table className='custom-table-wraper import-driver-modal-table'>
                  <thead className='custom-table-header-wraper'>
                    <tr className='custom-table-row-wraper  custom-table-row-wraper-header-import-user'>
                      <td>
                        <CustomCheckbox checked={masterChecked} id='mastercheck' onChange={(e) => onMasterCheck(e)} />
                      </td>
                      <td>ACTION</td>
                      <td>FIRST NAME</td>
                      <td>LAST NAME</td>
                      <td>PHONE NUMBER</td>
                    </tr>
                  </thead>
                  {formik?.values?.drivers?.map((rowData, index) => {
                    validate = !!(
                      formik?.errors?.drivers !== undefined ||
                      rowData?.errors?.phoneError ||
                      (!formik.values?.drivers?.[index]?.can_travel_mexico &&
                        !formik.values?.drivers?.[index]?.can_travel_canada &&
                        !formik.values?.drivers?.[index]?.can_travel_usa)
                    );
                    return (
                      <Fragment key={rowData.id}>
                        <tr
                          style={validate ? { background: 'rgb(251 195 195 / 24%)' } : { color: 'white' }}
                          className={`${
                            rowData?.selected
                              ? 'custom-table-row-wraper custom-table-row-wraper-selected '
                              : 'custom-table-row-wraper '
                          } `}
                        >
                          <td>
                            <CustomCheckbox
                              checked={rowData?.selected}
                              className='form-check-input'
                              id={`drivers[${index}].id`}
                              onChange={(e) => onItemCheck(e, index)}
                            />
                          </td>
                          <td>
                            <div className='Action-container'>
                              {!rowData.isEdit && (
                                <div className='user-name-and-actions-container'>
                                  <img
                                    src={More}
                                    alt='nore'
                                    onClick={() => {
                                      formik.setFieldValue(`drivers[${index}].isEdit`, true);
                                      setInitialValues((prevState) => [...prevState, formik.values.drivers[index]]);
                                    }}
                                  />
                                </div>
                              )}
                              {rowData.isEdit &&
                                (onClickEdit ? (
                                  <div>
                                    <CircularProgress size={26} />
                                  </div>
                                ) : (
                                  <div className='d-flex align-items-center gap-2'>
                                    <img
                                      src={tickgray}
                                      alt='nore-cross'
                                      className='close-edit-mode-icon'
                                      onClick={() => validateAgain(rowData, index)}
                                    />
                                    <CancelIcon
                                      width={16}
                                      height={16}
                                      onClick={() => {
                                        const initialData = initialValues.find(
                                          (i) => i.id === formik.values.drivers[index].id
                                        );
                                        if (initialData) {
                                          formik.setFieldValue(`drivers[${index}]`, initialData);
                                          return;
                                        }
                                        formik.setFieldValue(`drivers[${index}].isEdit`, false);
                                      }}
                                    />
                                  </div>
                                ))}
                              {(formik?.errors?.drivers !== undefined ||
                                (!formik.values?.drivers?.[index]?.can_travel_mexico &&
                                  !formik.values?.drivers?.[index]?.can_travel_canada &&
                                  !formik.values?.drivers?.[index]?.can_travel_usa) ||
                                rowData?.errors?.phoneError) && (
                                <div
                                  className='ms-2 me-1'
                                  onClick={(e) => e.stopPropagation()}
                                  style={{ position: 'relative' }}
                                >
                                  <WarningIcon
                                    width={20}
                                    height={20}
                                    fill={palette.red400}
                                    onClick={(e) =>
                                      ProfileAlerthandleClick(
                                        e,
                                        rowData?.errors,
                                        formik?.errors?.drivers ? formik?.errors?.drivers[index] : null,
                                        !formik.values?.drivers?.[index]?.can_travel_mexico &&
                                          !formik.values?.drivers?.[index]?.can_travel_canada &&
                                          !formik.values?.drivers?.[index]?.can_travel_usa
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            {!rowData.isEdit && (
                              <div className='import-user-view-mode'>
                                <div className='user-name-and-actions-container'>
                                  <h2 className='name' style={{ color: use(palette.dark800, palette.gray200) }}>
                                    {rowData?.fname || '-'}
                                  </h2>
                                </div>
                              </div>
                            )}
                            {rowData.isEdit && (
                              <div className='import-user-edit-mode'>
                                <div
                                  className='selector-name name-wrap'
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                >
                                  <input
                                    type='text'
                                    className='input-field'
                                    placeholder='Wilson'
                                    style={{
                                      backgroundColor: use(palette.white, palette.dark600),
                                      borderColor: use(palette.gray50, palette.darkborder),
                                    }}
                                    name={`drivers[${index}].fname`}
                                    id={`drivers[${index}].fname`}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={rowData?.fname}
                                  />
                                </div>
                                {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.fname ? (
                                  <span className='error-message'>{formik.errors?.drivers[index]?.fname}</span>
                                ) : null}
                              </div>
                            )}
                          </td>
                          <td>
                            {!rowData.isEdit && (
                              <div className='import-user-view-mode'>
                                <div className='user-name-and-actions-container'>
                                  <h2 className='name' style={{ color: use(palette.dark800, palette.gray200) }}>
                                    {rowData?.lname || '-'}
                                  </h2>
                                </div>
                              </div>
                            )}
                            {rowData.isEdit && (
                              <div className='import-user-edit-mode'>
                                <div
                                  className='selector-name name-wrap'
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                >
                                  <input
                                    type='text'
                                    className='input-field'
                                    placeholder='Wilson'
                                    name={`drivers[${index}].lname`}
                                    id={`drivers[${index}].lname`}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={rowData?.lname}
                                    style={{
                                      backgroundColor: use(palette.white, palette.dark600),
                                      borderColor: use(palette.gray50, palette.darkborder),
                                    }}
                                  />
                                </div>
                                {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.lname ? (
                                  <span className='error-message'>{formik.errors?.drivers[index]?.lname}</span>
                                ) : null}
                              </div>
                            )}
                          </td>
                          <td>
                            {!rowData.isEdit && (
                              <div className='import-user-view-mode'>
                                <p className='phone' style={{ color: use(palette.dark800, palette.gray200) }}>
                                  {rowData?.phone_number || '-'}
                                </p>
                              </div>
                            )}
                            {rowData.isEdit && (
                              <div className='import-user-edit-mode'>
                                <div
                                  className='phone-number'
                                  style={{
                                    backgroundColor: use(palette.white, palette.dark600),
                                    borderColor: use(palette.gray50, palette.darkborder),
                                  }}
                                >
                                  <PhoneInput
                                    style={{
                                      backgroundColor: use(palette.white, palette.dark600),
                                      borderColor: use(palette.gray50, palette.darkborder),
                                    }}
                                    addInternationalOption={false}
                                    className='input-field-phone'
                                    value={rowData?.phone_number}
                                    countries={whitelist}
                                    onChange={(val) => formik.setFieldValue(`drivers[${index}].phone_number`, val)}
                                    defaultCountry='US'
                                    placeholder='(555) 555-1234'
                                    countryOptionsOrder={whitelist}
                                  />
                                </div>
                                {formik.errors?.drivers?.length && formik.errors?.drivers[index]?.phone_number ? (
                                  <span className='error-message'>{formik.errors?.drivers[index]?.phone_number}</span>
                                ) : null}
                                {rowData?.errors?.phoneError ? (
                                  <span className='error-message'>Phone Number already exists</span>
                                ) : null}
                              </div>
                            )}
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </table>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        className='import-modal-footer-wrapper'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-line-container mt-2'>
          <img src={warn} alt='' />
          <p className='footer-line'>Please make sure you have filled all the fields.</p>
        </div>
        <div className='footer-btn-container footer-btn-container-import-modal'>
          <Button className='cancel-button' onClick={props.onHide}>
            Cancel
          </Button>
          {onSubmitloading ? (
            <div className='ms-2'>
              <CircularProgress size={30} />
            </div>
          ) : (
            <Button disabled={!isValid} style={{ opacity: !isValid ? 0.7 : 1 }} onClick={() => importUsers()}>
              Import {totalSelected} Drivers
            </Button>
          )}
        </div>
      </Modal.Footer>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <Popover
        id={ProfileAlertContent}
        open={ProfileAlertOpen}
        anchorEl={profileAlert}
        onClose={ProfileAlerthandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <DriverAlertIcon alertData={alertData} driverAlert='Driver Alerts' />
      </Popover>
    </Modal>
  );
}
