import uuid from 'react-uuid';
import moment from 'moment';
import { getAlphabet } from 'utils/constants';
import {
  BILLING_GRAND_TOTAL_CURRENCY,
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  EQUIPMENT_ACTION_WAYPOINT,
  SCHEDULED_DATE_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import {
  initialValues as cargoInitialValues,
  getInitialValues as getCargoInitialValues,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';

export const stopPointTypes = [
  { id: 1, title: 'Pick Up' },
  { id: 2, title: 'Delivery' },
  { id: 3, title: 'Waypoint' },
];

export const initialCharge = { isNew: true, id: uuid(), charge_type: null, quantity: '', rate: '' };
export const initialPickup = {
  isNew: true,
  id: uuid(),
  stop_point_type: 1,
  stop_point: null,
  country: null,
  state: null,
  city: null,
  zipcode: '',
  scheduled_type: null,
  scheduled_tbd: false,
  scheduled_date: null,
  from: null,
  scheduled_date_to: null,
  to: null,
  equipment_type: null,
  equipment_type_length: null,
  equipment: '',
  equipment_tbd: false,
  equipment_action: null,
  cargos: [{ ...cargoInitialValues, id: uuid() }],
  notes_text: '',
  bill_type: true,
  dock_high: false,
  stackable: false,
  liftgate_service: false,
  labor_required: false,
  hazardous_materials: false,
};
export const initialDelivery = {
  isNew: true,
  id: uuid(),
  stop_point_type: 2,
  stop_point: null,
  country: null,
  state: null,
  city: null,
  zipcode: '',
  scheduled_type: null,
  scheduled_tbd: false,
  scheduled_date: null,
  from: null,
  scheduled_date_to: null,
  to: null,
  equipment_type: null,
  equipment_type_length: null,
  equipment: '',
  equipment_tbd: false,
  equipment_action: null,
  cargos: [],
  notes_text: '',
  bill_type: true,
  dock_high: false,
  stackable: false,
  liftgate_service: false,
  labor_required: false,
  hazardous_materials: false,
};
export const initialWaypoint = {
  isNew: true,
  id: uuid(),
  stop_point_type: 3,
  stop_point: null,
  country: null,
  state: null,
  city: null,
  zipcode: '',
  scheduled_type: null,
  scheduled_tbd: false,
  scheduled_date: null,
  from: null,
  scheduled_date_to: null,
  to: null,
  equipment_type: null,
  equipment_type_length: null,
  equipment: '',
  equipment_tbd: false,
  equipment_action: null,
  notes_text: '',
  bill_type: true,
  dock_high: false,
  stackable: false,
  liftgate_service: false,
  labor_required: false,
  hazardous_materials: false,
};

export const initialLeg = {
  isNew: true,
  id: uuid(),
  quantity: '1',
  deadhead_stop_point: null,
  deadhead_country: null,
  deadhead_state: null,
  deadhead_city: null,
  deadhead_zipcode: '',
  deadhead_start_date: null,
  stops: [initialPickup, initialDelivery],
};

export const initialValues = {
  expiry_date: null,
  billing: {
    customer: null,
    reference_id: '',
    contact: null,
    payment_term: null,
    total_power_unit: '',
    currency: BILLING_GRAND_TOTAL_CURRENCY[0],
  },
  charges: [initialCharge],
  legs: [initialLeg],
  line_items_currency: BILLING_GRAND_TOTAL_CURRENCY[0],
  line_items: [initialCharge],
  estimated_cost_count: '',
  estimated_cost_rate: '',
  payment_term_notes: '',
};

export const getDeliveryCargos = (stops, delivery) => {
  const prevStops = stops.slice(
    0,
    stops.findIndex((i) => i.id === delivery.id)
  );
  const allConnectedCargos = stops.reduce(
    (acc, cur) => (Number(cur.stop_point_type) === 2 ? [...acc, ...(cur.cargos || [])] : acc),
    []
  );

  const allCargos = prevStops.reduce(
    (acc, cur, index) =>
      Number(cur.stop_point_type) === 1
        ? [...acc, ...(cur.cargos || []).map((i) => ({ ...i, stopIndex: getAlphabet(index) }))]
        : acc,
    []
  );

  return allCargos.filter((i) => !!i.commodity && allConnectedCargos.every((item) => item.id !== i.id));
};

export const getQuoteStartEndDate = (quote) => {
  const allDates = [];

  quote.legs.forEach((leg) => {
    allDates.push(leg.deadhead_start_date);

    leg.stops.forEach((stop) => {
      if (stop.scheduled_date && stop.from) {
        allDates.push(`${moment(stop.scheduled_date).format('YYYY-MM-DD')} ${moment(stop.from).format('HH:mm')}`);
      }
      if (stop.scheduled_date_to && stop.to) {
        allDates.push(`${moment(stop.scheduled_date_to).format('YYYY-MM-DD')} ${moment(stop.to).format('HH:mm')}`);
      }
    });
  });

  allDates.sort((a, b) => {
    return moment(a).isBefore(b) ? -1 : moment(a).isBefore(b) ? 1 : 0;
  });

  return {
    startDate: allDates[0] ? moment(allDates[0]).toDate() : null,
    endDate: allDates[allDates.length - 1] ? moment(allDates[allDates.length - 1]).toDate() : null,
    totalDuration:
      allDates[0] && allDates[allDates.length - 1]
        ? moment(allDates[allDates.length - 1]).diff(moment(allDates[0]), 'minutes')
        : '',
  };
};

export const getQuoteTotalDistance = (quote) => {
  let totalDistance = 0;

  quote.legs.forEach((leg) => {
    totalDistance += Math.round(Number(leg.distance_to_next_stop || 0));

    leg.stops.forEach((stop) => {
      totalDistance += Math.round(Number(stop.distance_to_next_stop || 0));
    });
  });

  return totalDistance;
};

export const hasUnsavedData = (quote) => {
  let hasUnsavedData = false;

  quote.legs.forEach((leg) => {
    leg.stops.forEach((stop) => {
      if (stop.isEdit || stop.isNew) {
        hasUnsavedData = true;
      }
    });
  });

  quote.charges.forEach((charge) => {
    if (charge.isEdit || charge.isNew) {
      hasUnsavedData = true;
    }
  });

  if (quote.billing.isEdit) {
    hasUnsavedData = true;
  }

  quote.line_items.forEach((charge) => {
    if (charge.isEdit || charge.isNew) {
      hasUnsavedData = true;
    }
  });

  return hasUnsavedData;
};

const getStopInitialValues = (stop) => {
  const equipmentActions =
    Number(stop.stop_point_type) === 1
      ? stop.equipment_type_data?.vehicle_type_id === 2 || stop.equipment_type_data?.id === 1
        ? EQUIPMENT_ACTION
        : [EQUIPMENT_ACTION[0]]
      : Number(stop.stop_point_type) === 2
      ? EQUIPMENT_ACTION_DELIVERY
      : EQUIPMENT_ACTION_WAYPOINT;

  return {
    isNew: false,
    id: stop.id,
    stop_point_type: Number(stop.stop_point_type),
    stop_point: stop.stop_point || null,
    country: stop.country || null,
    state: stop.state || null,
    city: stop.city || null,
    zipcode: stop.zipcode ? stop.zipcode.toString() : '',
    scheduled_type: stop.scheduled_type ? SCHEDULED_DATE_TYPE.find((i) => i.key === Number(stop.scheduled_type)) : null,
    scheduled_tbd: !!stop.scheduled_tbd,
    scheduled_date: stop.scheduled_date ? moment(stop.scheduled_date, 'YYYY-MM-DD').toDate() : null,
    from: stop.from ? moment(stop.from, 'HH:mm').toDate() : null,
    scheduled_date_to: stop.scheduled_date_to ? moment(stop.scheduled_date_to, 'YYYY-MM-DD').toDate() : null,
    to: stop.to ? moment(stop.to, 'HH:mm').toDate() : null,
    equipment_type: stop.equipment_type_data || null,
    equipment_type_length: stop.equipment_type_length || null,
    equipment: stop.equipment || '',
    equipment_tbd: stop.equipment_tbd || false,
    equipment_action: stop.equipment_action
      ? equipmentActions.find((i) => i.key === Number(stop.equipment_action))
      : null,
    cargos:
      Number(stop.stop_point_type) === 1
        ? getCargoInitialValues(stop.cargos)
        : Number(stop.stop_point_type) === 2
        ? stop.connected_cargos?.length
          ? getCargoInitialValues(stop.connected_cargos)
          : []
        : null,
    notes_text: stop.notes_text || '',
    bill_type: Number(stop.bill_type) === 1,
    dock_high: !!stop.dock_high,
    stackable: !!stop.stackable,
    liftgate_service: !!stop.liftgate_service,
    labor_required: !!stop.labor_required,
    hazardous_materials: !!stop.hazardous_materials,
  };
};

export const getInitialValues = (quote) => {
  if (!quote) {
    return initialValues;
  }

  const {
    expiry_date,
    billing,
    additional_line_items,
    estimated_cost_count,
    estimated_cost_rate,
    payment_term_notes,
    stops,

    legs,
  } = quote;
  const { customer, reference_id, contact_user, payment_term, currency_id, charges, total_power_unit } = billing || {};

  const initialLegs = legs.map((leg) => {
    const matchingStops = stops
      .filter((stop) => stop.leg === leg.leg_number)
      .sort((a, b) => a.order_in_leg - b.order_in_leg);

    const {
      deadhead_stop_point,
      deadhead_country,
      deadhead_state,
      deadhead_city,
      deadhead_zipcode,
      deadhead_start_date,
    } = leg;

    return {
      isNew: false,
      isEdit: false,
      id: leg.id,
      quantity: leg.quantity,
      deadhead_stop_point: deadhead_stop_point || null,
      deadhead_country: deadhead_country || null,
      deadhead_state: deadhead_state || null,
      deadhead_city: deadhead_city || null,
      deadhead_zipcode: deadhead_zipcode ? deadhead_zipcode.toString() : '',
      deadhead_start_date: deadhead_start_date ? moment(deadhead_start_date).toDate() : null,
      stops: matchingStops.length
        ? matchingStops.map((stop) => getStopInitialValues(stop))
        : [initialPickup, initialDelivery],
    };
  });

  return {
    expiry_date: expiry_date ? moment(expiry_date).toDate() : null,
    billing: {
      id: billing.id,
      customer: customer || null,
      reference_id: reference_id || '',
      contact: contact_user || null,
      payment_term: payment_term || null,
      total_power_unit: total_power_unit ? total_power_unit.toString() : '',
      currency: currency_id ? BILLING_GRAND_TOTAL_CURRENCY.find((i) => i.id === currency_id) : null,
    },
    charges: (charges || []).map((item) => ({
      isNew: false,
      id: item.id,
      charge_type: item.charge_type || null,
      quantity: item.quantity ? item.quantity.toString() : '',
      rate: item.rate ? item.rate.toString() : '',
    })),
    legs: initialLegs.length ? initialLegs : [initialLeg],
    line_items_currency: additional_line_items?.[0]?.currency_id
      ? BILLING_GRAND_TOTAL_CURRENCY.find((i) => i.id === additional_line_items[0].currency_id)
      : BILLING_GRAND_TOTAL_CURRENCY[0],
    line_items: (additional_line_items || []).map((item) => ({
      isNew: false,
      id: item.id,
      charge_type: item.charge_type || null,
      quantity: item.quantity ? item.quantity.toString() : '',
      rate: item.rate ? item.rate.toString() : '',
    })),
    estimated_cost_count: estimated_cost_count || '',
    estimated_cost_rate: estimated_cost_rate || '',
    payment_term_notes: payment_term_notes || '',
  };
};
