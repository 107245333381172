import React, { useState } from 'react';
import calendar from 'assets/icons/drivers/calendar.svg';
import playVideoIcon from 'assets/images/icons-video.png';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Search from 'common/Search';
import ViewVideo from 'components/ViewVideo';
import ExportCsv from 'componentsV2/ExportCsv';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette, TIME_APP_VIDEO_LINK } from 'utils/constants';
import { SWrapper } from './TableWrapper.styles';

const TableFilters = ({
  search,
  rangeName,
  setSearch,
  activeTab,
  showPicker,
  exportParams,
  setShowPicker,
  setOpenAddUpdateRecord,
}) => {
  const [openVideo, setOpenVideo] = useState(false);

  return (
    <SWrapper>
      <div className='filters-wrapper'>
        <div>
          <button
            className='PrimaryBtn driver-style-wrap'
            style={{ backgroundColor: palette.white, height: '32px', width: '100%' }}
            onClick={() => setShowPicker(!showPicker)}
          >
            <img src={calendar} alt='Calendar' />
            <p style={{ color: palette.gray900, whiteSpace: 'nowrap' }} className='Primary-btn-title'>
              {rangeName}
            </p>
          </button>
        </div>
        <Search search={search} onChange={setSearch} />
        <ExportCsv type='time-tracking' exportParams={exportParams} />
      </div>
      <div className='d-flex align-items-center gap-3'>
        {activeTab === 1 && (
          <div className='watch-video-wrapper' onClick={() => setOpenVideo(true)}>
            <img src={playVideoIcon} alt='play-video' height='36px' />
            <Typography className='underline-text' variant='s2' style={{ color: palette.indigo500 }}>
              Watch Video
            </Typography>
          </div>
        )}
        <CustomButton
          type='primary'
          title='Add Time'
          leftIcon={<PlusIcon fill={palette.white} style={{ marginRight: 10 }} />}
          styleButton={{ padding: '5px 12px', marginTop: 0 }}
          onClick={() => setOpenAddUpdateRecord(true)}
        />
      </div>
      {openVideo && <ViewVideo open={openVideo} onClose={() => setOpenVideo(false)} videoUrl={TIME_APP_VIDEO_LINK} />}
    </SWrapper>
  );
};

export default TableFilters;
