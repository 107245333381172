import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import DateRangePicker from 'common/DateRangePicker';
import { getVendorsList } from 'Api/Vendors';
import { SWrapper, SSearchWrapper } from './TableFilters.styles';

const TableFilters = ({ dateRange, setDateRange, search, setSearch, selectedFilters, setSelectedFilters }) => {
  const [filterType, setFilterType] = useState('All');
  const [vendors, setVendors] = useState([]);

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <SWrapper>
      <DateRangePicker
        top='-108px'
        initialRangeName='All Time'
        dateRange={dateRange}
        setDateRange={setDateRange}
        type='allTime'
      />
      <SSearchWrapper>
        <div>
          <Autocomplete
            width='110px'
            options={['All', 'Vendor']}
            value={filterType}
            onChange={(e, val) => setFilterType(val)}
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FilterIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {filterType === 'All' && <Search search={search} onChange={setSearch} className='search-input' />}
        {filterType === 'Vendor' && (
          <Autocomplete
            width='250px'
            disableClearable={false}
            placeholder='Select Vendor..'
            options={vendors}
            value={selectedFilters.vendor}
            onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, vendor: val }))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            className='filter-dropdown'
          />
        )}
      </SSearchWrapper>
    </SWrapper>
  );
};

export default TableFilters;
