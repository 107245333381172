import React, { useState } from 'react';
import axios from 'axios';
import styles from './TableSettingPopover.module.css';
import TableColumn from './TableColumn';
import authHeader from '../../../services/auth-header';

import ColumnOrder from './ColumnOrder';
import SwitchRadio from '../../SwitchRadio/SwitchRadio';
import CustomButton from '../../CustomButton/CustomButton';
import { amountSwitch } from './TableSettingConstants';

const PopoverSettings = ({ setShowPopover, types, filter, updateFilter, OnTableSettingChange }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [localState, setLocalState] = useState({});
  const [switchRadioButtons, setSwitchRadioButtons] = useState({ ...filter.switchRadioButtons });
  const [localDragItem, setLocalDragItem] = useState([...(filter?.ColumnOrder || [])]);
  const applyAllUsers = Number(filter.applyAllUsers.value);
  const onChangeHandler = (name, value) => {
    setSwitchRadioButtons({ ...switchRadioButtons, [name]: value });
  };
  const onChangeTick = (data) => {
    const applyAllUsers = { ...filter.applyAllUsers, value: String(data) };
    updateFilter({ ...filter, applyAllUsers });
  };
  const user = JSON.parse(localStorage.getItem('user'));

  const onClickApply = async () => {
    const values = {
      type: 'vehicle',
      data: {
        date: switchRadioButtons?.date,
        time: switchRadioButtons?.time,
        amount: switchRadioButtons?.amount,
        columns: localState,
        columns_order: localDragItem,
      },
      forAll: Number(filter?.applyAllUsers?.value) === 1,
    };
    try {
      axios.put(`${API_URL}/table-settings`, values, { headers: authHeader() }).then((res) => {
        if (res?.status === 200) {
          OnTableSettingChange(true);
          setShowPopover(false);
        }
      });
    } catch (err) {
      // Do nothing
    }
  };
  return (
    <div>
      <div className={styles.popoverSettings_title_wrapper}>
        <span>Table Settings</span>
      </div>
      <TableColumn
        types={types}
        localState={localState}
        updateFilter={updateFilter}
        filter={filter}
        setLocalState={(param) => setLocalState(param)}
        onChangeOrder={(items) => setLocalDragItem(items)}
        setLocalDragItem={setLocalDragItem}
        localDragItem={localDragItem}
      />
      <div style={{ marginBottom: 16 }} />

      <ColumnOrder
        localDragItem={localDragItem}
        updateFilter={updateFilter}
        filter={filter}
        onChangeOrder={(items) => setLocalDragItem(items)}
      />
      <div style={{ marginBottom: 16 }} />
      <div>
        <SwitchRadio
          style={{ marginLeft: 10, position: 'static' }}
          name='amount'
          items={amountSwitch}
          title='Amount '
          value={+switchRadioButtons.amount}
          onChange={onChangeHandler}
        />
      </div>
      <div style={{ borderBottom: '1px solid #D5DBE5', margin: '16px 0 8px', height: '1px' }} />
      <div className={styles.popoverSettings_footer}>
        <div className='d-flex'>
          <input
            type='checkbox'
            id='user'
            disabled={user?.department?.department_name !== 'Management'}
            checked={!!applyAllUsers}
            onChange={() => onChangeTick(applyAllUsers ? 0 : 1)}
            style={{ cursor: 'pointer' }}
          />
          <label htmlFor='user' className={styles.footer_title_checkbox}>
            Apply to all users
          </label>
        </div>
        <div className={styles.footer_right}>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '2px 8px', marginRight: 16 }}
            onClick={() => setShowPopover(false)}
          />
          <CustomButton type='primary' title='Apply' styleButton={{ padding: '2px 8px' }} onClick={onClickApply} />
        </div>
      </div>
    </div>
  );
};

export default PopoverSettings;
