import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class ChatAppServices {
  deleteSendMessageLater(Id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .delete(`${API_URL}/customer/delete-chat-send-later/${Id}`, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  sendMessageLater(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };

    return axios
      .post(`${API_URL}/customer/create-chat-send-later`, data, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  getSendMessageLater() {
    const user = JSON.parse(localStorage.getItem('user'));
    const config = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: `Bearer ${user.accessToken}`,
      },
      url: `${API_URL}/customer/get-chat-send-later`,
    };

    return axios(config).then((response) => {
      return response;
    });
  }

  checkSMSLicence() {
    const user = JSON.parse(localStorage.getItem('user'));
    const config = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: `Bearer ${user.accessToken}`,
      },
      url: `${API_URL}/customer/addon/check-two-way-sms-addon`,
    };
    return axios(config).then((response) => {
      return response;
    });
  }

  addNotes(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };

    return axios
      .post(`${API_URL}/notes/chat`, data, {
        headers,
      })
      .then((response) => {
        return response;
      })
      .catch(() => {
        // Do nothing
      });
  }

  getNotes(Id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .get(`${API_URL}/notes/chat?chat_user_id=${Id}`, {
        headers,
      })
      .then((response) => {
        return response;
      })
      .catch(() => {
        // Do nothing
      });
  }

  deleteNotes(Id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .delete(`${API_URL}/notes/chat/${Id}`, {
        headers,
      })
      .then((response) => {
        return response;
      })
      .catch(() => {
        // Do nothing
      });
  }

  closeNotification(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };

    return axios
      .post(`${API_URL}/stream-chat/message-mark-read`, data, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }
}

export default new ChatAppServices();
