import React from 'react';
import NoRecords from 'common/NoRecords';
import UserInfo from 'components/UserInfo';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { SInfoSection, STable } from '../ShipmentSummary.styles';

const ShipmentEvents = ({ events, loading }) => {
  const { convertToCustomerTime } = useDateFormat();

  return (
    <SInfoSection>
      {loading ? (
        <TableSkeleton loading={loading} />
      ) : (
        <STable>
          <thead>
            <tr className='header-row'>
              <th>EVENT</th>
              <th>EVENT DESCRIPTION</th>
              <th>EQUIPMENT ID</th>
              <th>MILES TRAVELED</th>
              <th>LOCATION</th>
              <th>DATE</th>
              <th>ACTION BY</th>
            </tr>
          </thead>
          <tbody>
            {events.map((item) => (
              <tr key={item.id} className='body-row'>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {item.type?.title}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {item.description}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {item.equipment?.equipment_id || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {Math.round(item.miles_traveled || 0)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {item.location || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {convertToCustomerTime(item.date)}
                  </Typography>
                </td>
                <td>
                  {item.action_by ? (
                    <UserInfo
                      size='32px'
                      statusSize='7px'
                      data={{
                        ...(item.action_by || {}),
                        name:
                          item.action_by_type === 'App\\User'
                            ? `${item.action_by?.first_name} ${item.action_by?.last_name}`
                            : `${item.action_by?.fname} ${item.action_by?.lname}`,
                        image: item.action_by.image || item.action_by.profile_logo,
                        user_type: item.action_by_type === 'App\\User' ? 'staff' : 'driver',
                      }}
                    />
                  ) : (
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      -
                    </Typography>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
      )}
      {!loading && !events.length && <NoRecords />}
    </SInfoSection>
  );
};

export default ShipmentEvents;
