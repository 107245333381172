import React, { useEffect, useMemo, useState } from 'react';
import Chip from 'common/Chip';
import Search from 'common/Search';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getRulesAndConduct } from 'Api/CompanySettings';
import RuleDetails from './components/RuleDetails';
import { SHeader, SCircularProgress, SRow, SContentRow } from './RulesAndConduct.styles';

const RulesAndConduct = () => {
  const [rules, setRules] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [ruleToView, setRuleToView] = useState(null);

  const filteredRules = useMemo(() => {
    return rules.filter((item) => item.title?.toLowerCase()?.includes(search.toLowerCase()));
  }, [rules, search]);

  const getRules = async () => {
    try {
      const { data } = await getRulesAndConduct();
      setRules(data.filter((rule) => rule.applies_to?.includes('staff')));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRules();
  }, []);

  return (
    <div>
      <SHeader>
        <Typography variant='h1'>Rules and Conduct (Handbook)</Typography>
      </SHeader>
      <Search search={search} onChange={setSearch} margin='8px' />
      {loading ? (
        <SCircularProgress size={26} />
      ) : (
        <>
          <SRow>
            <div className='header-item'>
              <Typography variant='overLine'>TITLE</Typography>
            </div>
            <div className='header-item'>
              <Typography variant='overLine'>DESCRIPTION</Typography>
            </div>
            <div className='header-item'>
              <Typography variant='overLine'>ASSOCIATED INCIDENT TYPE</Typography>
            </div>
          </SRow>
          {filteredRules?.map((item) => (
            <SContentRow
              key={item.id}
              onClick={(e) => {
                e.preventDefault();
                setRuleToView(item);
              }}
            >
              <div className='table-item'>
                <Typography variant='s2'>{item.title}</Typography>
              </div>
              <div className='table-item'>
                <Typography variant='s2'>{item.description}</Typography>
              </div>
              <div className='table-item'>
                {item.incident_types.map((incident) => (
                  <span key={incident.id}>
                    <Chip
                      size='small'
                      label={incident.type}
                      labelColor={palette.indigo500}
                      bgColor={palette.indigo100}
                      style={{ marginLeft: '4px' }}
                    />
                  </span>
                ))}
              </div>
            </SContentRow>
          ))}
        </>
      )}
      {!!ruleToView && <RuleDetails open={!!ruleToView} onClose={() => setRuleToView(null)} rule={ruleToView} />}
    </div>
  );
};

export default RulesAndConduct;
