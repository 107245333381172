import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getPlannerStatus } from 'components/TableShipments/helpers/constants';
import { BID_STATUS, PROGRESS_COLOR } from 'pages/Shipment/ShipmentBid/ShipmentBid.data';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAwardExpirationData, getBidExpirationData } from './helpers';
import { SLinearProgress } from '../../ShipmentBid.styles';

const Countdown = ({ shipment, expireDate, setExpireDate }) => {
  const [progress, setProgress] = useState(0);
  const [expiration, setExpiration] = useState([0, 0, 0]);
  const { planner, bid } = shipment || {};
  const { bgStatus, colorStatus, statusName } = getPlannerStatus(planner) || {};
  const isExpired = !bid && !!expireDate && moment().isAfter(expireDate);
  const matches = useMediaQuery('(max-width:768px)');
  const [bidProgress, setBidProgress] = useState(0);

  const getExpirationData = (shipment) => {
    const { expire_date, totalDiff, minutesPassed } =
      bid?.offer_accepted === 3 ? getAwardExpirationData(shipment) : getBidExpirationData(shipment);

    const days = moment(expire_date).diff(moment().utc(), 'days');
    const hours = moment(expire_date).diff(moment().utc(), 'hours') % 24;
    const minutes = moment(expire_date).diff(moment().utc(), 'minutes') % 60;

    setExpiration([days, hours < 0 ? '00' : hours.toString(), minutes < 0 ? '00' : minutes.toString()]);
    setProgress((minutesPassed * 100) / totalDiff);
    setExpireDate(expire_date);
  };

  useEffect(() => {
    if (!planner) {
      return;
    }
    getExpirationData(shipment);

    const intervalId = setInterval(() => {
      getExpirationData(shipment);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [shipment]);

  useEffect(() => {
    if (bid?.offer_accepted !== 1) {
      return;
    }

    const intervalId = setInterval(() => {
      setBidProgress((prevState) => (prevState >= 100 ? 0 : prevState + 0.5));
    }, 400);

    return () => {
      clearInterval(intervalId);
    };
  }, [shipment]);

  return (
    <>
      <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
        {bid?.offer_accepted === 1 && (
          <Typography variant='c2'>Submitted on {moment(bid.created_at).format('MM/DD/YYYY HH:mm')}</Typography>
        )}
        {bid?.offer_accepted === 2 && (
          <Typography variant='c2'>
            Declined on {moment(bid.updated_at).format('MM/DD/YYYY HH:mm')} ({bid.decline_reason?.name})
          </Typography>
        )}
        {bid?.offer_accepted === 6 && (
          <Typography variant='c2'>
            Bid Declined by {moment(bid.updated_at).format('MM/DD/YYYY HH:mm')} ({bid.decline_reason_text})
          </Typography>
        )}
        {bid?.offer_accepted === 4 && <div />}
        {!isExpired && (!bid?.offer_accepted || bid?.offer_accepted === 3) && (
          <div className='d-flex align-items-end gap-2'>
            <Typography
              variant={matches ? 's2' : 'h1'}
              style={{ fontWeight: 700, color: palette.gray900, whiteSpace: 'nowrap' }}
            >
              {expiration[0] ? `${expiration[0]}d` : ''} {expiration[1]}h{' '}
              {expiration[2]?.length === 1 ? `0${expiration[2]}` : expiration[2]}m
            </Typography>
            <Typography variant={matches ? 'c1' : 's2'} style={{ color: palette.gray500, whiteSpace: 'nowrap' }}>
              {bid?.offer_accepted === 3 ? 'remaining until award expires' : 'remaining until bidding is over'}
            </Typography>
          </div>
        )}
        {!!isExpired && (
          <Typography variant='c2'>
            Offer has expired automatically on {moment(expireDate).local().format('MM/DD/YYYY HH:mm')}
          </Typography>
        )}
        <Chip
          label={isExpired ? 'OFFER EXPIRED' : BID_STATUS[bid?.offer_accepted]?.label || statusName?.toUpperCase()}
          labelColor={isExpired ? palette.red500 : BID_STATUS[bid?.offer_accepted]?.color || colorStatus}
          bgColor={isExpired ? palette.red0 : BID_STATUS[bid?.offer_accepted]?.bgColor || bgStatus}
          fontSize='11px'
          fontWeight={500}
        />
      </div>
      <div className='mb-3'>
        {bid?.offer_accepted === 1 ? (
          <div className='d-flex align-items-center gap-2'>
            <SLinearProgress
              variant='determinate'
              value={bidProgress < 25 ? bidProgress * 4 : 100}
              $bgColor={PROGRESS_COLOR['1']}
            />
            <SLinearProgress
              variant='determinate'
              value={bidProgress >= 25 && bidProgress < 50 ? (bidProgress - 25) * 4 : bidProgress < 26 ? 0 : 100}
              $bgColor={PROGRESS_COLOR['1']}
            />
            <SLinearProgress
              variant='determinate'
              value={bidProgress >= 50 && bidProgress < 75 ? (bidProgress - 50) * 4 : bidProgress < 51 ? 0 : 100}
              $bgColor={PROGRESS_COLOR['1']}
            />
            <SLinearProgress
              variant='determinate'
              value={bidProgress >= 75 ? (bidProgress - 75) * 4 : 0}
              $bgColor={PROGRESS_COLOR['1']}
            />
          </div>
        ) : (
          <SLinearProgress
            variant='determinate'
            value={[1, 2, 4, 6].includes(bid?.offer_accepted) || isExpired ? 100 : progress}
            $bgColor={isExpired ? palette.red500 : PROGRESS_COLOR[bid?.offer_accepted] || palette.green400}
          />
        )}
      </div>
    </>
  );
};

export default Countdown;
