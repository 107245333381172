import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { palette } from '../../../../utils/constants';

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: pointer;
`;

export const SGrid = styled(Grid)`
  padding: 8px 0;
  border-top: 1px solid ${palette.gray50};
  border-bottom: 1px solid ${palette.gray50};
`;

export const SIconButton = styled.button`
  border: none;
  outline: none;
  border-radius: 4px;
  box-shadow: ${palette.boxShadow2};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 33px;

  background: ${({ type }) => (type === 'secondary' ? palette.white : palette.indigo500)};
`;

export const SButtonWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;
