import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  height: 100%;
  display: flex;
  font-family: 'Inter', sans-serif;
  margin-top: -10px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  column-gap: 8px;

  .right-section {
    display: inline-flex;
    align-items: center;
    column-gap: 18px;
  }

  .reconcile-badge {
    position: absolute;
    top: -12px;
    right: -12px;
    padding: 4px;
    border-radius: 50%;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    line-height: 14px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${palette.goldLight};
    color: ${palette.goldDark};

    &.no-transactions {
      background-color: ${palette.green0};
      color: ${palette.green500};
    }
  }
`;

export const STableWrapper = styled.div`
  margin-top: 66px;
  margin-bottom: 100px;
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0;
    margin-top: 12px;

    .MuiTabs-flexContainer {
      column-gap: 8px;
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      min-height: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      padding: 3px 10px;
      border-radius: 24px;
      text-transform: none;
      color: ${palette.gray900};
      transition: 0.4s;

      &.Mui-selected {
        background-color: ${palette.indigo500} !important;
        color: ${palette.white};
      }
    }

    .MuiButtonBase-root:hover {
      background-color: ${palette.indigo0};
    }
  }
`;

export const SFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
