import React, { useState } from 'react';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import { Textarea } from 'common/Input';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { createStandaloneInvoices, updateDraftInvoice } from 'Api/AccountingReceivables';
import { markDetentionsBilled } from 'Api/DetentionShipment';
import BillTo from './components/BillTo';
import Recurring from './components/Recurring';
import Attachment from './components/Attachment';
import ChargesForm from './components/ChargesForm';
import { validationSchema } from './validationSchema';
import { getInitialValues, getRequestBody } from './CreateInvoice.data';
import { SBodyWrapper } from './CreateInvoice.styles';

const CreateInvoice = ({ open, onClose, onSuccess, draftData = null, detentions = null }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const createDraftInvoice = async (values) => {
    try {
      setLoading(true);
      const body = getRequestBody({ ...values, is_draft: 1 });
      await createStandaloneInvoices(body);
      showToaster({ type: 'success', message: 'Draft invoice has been successfully created!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const updateDraft = async (id, values) => {
    try {
      setLoading(true);
      const body = getRequestBody(values);
      await updateDraftInvoice({ id, body });
      showToaster({ type: 'success', message: 'Draft invoice has been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      if (detentions) {
        const formData = new FormData();
        values.items.forEach((item) => {
          formData.append('detention[]', item.detentionId);
        });
        await markDetentionsBilled(formData);
      }
      const body = getRequestBody(values);
      await createStandaloneInvoices(body);
      showToaster({ type: 'success', message: 'Invoice has been successfully created!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, setValues, handleSubmit, handleBlur, touchedErrors, dirty } = useForm({
    initialValues: getInitialValues(draftData, detentions),
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={!detentions ? 'Create Invoice' : 'Bill Detention (Standalone Invoice)'}
      $bgColor={palette.gray0}
      $width='1300px'
      $maxWidth='90vw'
      $minWidth='1200px'
      className='modified-scrollbar'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', margin: 10, fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'draft',
          type: 'secondary',
          title: 'Draft',
          onClick: () => (draftData ? updateDraft(draftData.id, values) : createDraftInvoice(values)),
          disabled: detentions,
        },
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Create Invoice',
          disabled: loading || !dirty,
          onClick: handleSubmit,
        },
      ]}
    >
      <SBodyWrapper>
        <div className='first-line'>
          <DatePicker
            required
            width='200px'
            name='bill_date'
            label='Date Billed'
            value={values.bill_date}
            onBlur={handleBlur}
            onChange={(val) => handleChange('bill_date', val)}
            error={touchedErrors.bill_date}
          />
          <DatePicker
            required
            name='due_date'
            label='Date Due'
            width='200px'
            value={values.due_date}
            onBlur={handleBlur}
            onChange={(val) => handleChange('due_date', val)}
            error={touchedErrors.due_date}
          />
        </div>
        <BillTo values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
        <ChargesForm
          values={values}
          handleChange={handleChange}
          touchedErrors={touchedErrors}
          detentions={detentions}
        />
        {!detentions && (
          <Recurring values={values} handleChange={handleChange} setValues={setValues} touchedErrors={touchedErrors} />
        )}
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {!detentions ? 'Notes' : 'Invoice Notes'}
            </Typography>
            {!detentions && <Chip label='OPTIONAL' />}
          </div>
          <Textarea
            rows={6}
            name='note'
            placeholder='Public note...'
            value={values.note}
            onChange={handleChange}
            error={touchedErrors.note}
          />
        </div>
        <div className='d-flex flex-column'>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            Attachment(s)
          </Typography>
          <Attachment values={values} handleChange={handleChange} />
        </div>
      </SBodyWrapper>
    </Modal>
  );
};

export default CreateInvoice;
