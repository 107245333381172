import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import Grid from '@mui/material/Grid';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { createAccidentReport } from 'Api/AccidentsAndCrashes';
import { getErrorMessage } from 'utils/error';
import useForm from 'hooks/useForm';
import { getManufacturedYear } from 'pages/AccidentsAndCrashes/AddAccidents/AddAccidents.data';
import Loader from 'common/Loader';
import { palette } from 'utils/constants';
import { Button, Modal } from 'react-bootstrap';
import CustomButton from 'components/CustomButton/CustomButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import Input, { Textarea } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { getInitialValues } from './Equipment.data';

const Equipment = ({
  Cancel,
  nextStep,
  accident,
  loadingData,
  id,
  accidentId,
  selectedVehicle,
  selectedTrailer,
  vehiclesList,
  trailersList,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [detailView, setDetailView] = useState(!!accidentId);
  const [onSubmitAction, setOnSubmitAction] = useState('next');

  const onSubmit = async (values) => {
    try {
      const {
        vehicle_unit,
        trailer_unit,
        vehicle_year,
        trailer_year,
        vehicle_make,
        trailer_make,
        vehicle_owner,
        trailer_owner,
        vehicle_damage,
        trailer_damage,
      } = values || {};
      setLoading(true);
      const formData = new FormData();
      formData.append('safety_accident_id', accident?.id || id);
      vehicle_unit && formData.append('vehicle_unit_number', vehicle_unit.equipment_id);
      vehicle_year && formData.append('vehicle_year', vehicle_year);
      trailer_unit && formData.append('trailer_unit_number', trailer_unit.equipment_id);
      trailer_year && formData.append('trailer_year', trailer_year);
      vehicle_make && formData.append('vehicle_make', vehicle_make);
      trailer_make && formData.append('trailer_make', trailer_make);
      vehicle_owner && formData.append('vehicle_owner', vehicle_owner);
      trailer_owner && formData.append('trailer_owner', trailer_owner);
      vehicle_damage && formData.append('vehicle_damage', vehicle_damage);
      trailer_damage && formData.append('trailer_damage', trailer_damage);

      await createAccidentReport(accident?.id || id, formData);

      if (onSubmitAction === 'next') {
        nextStep();
        return;
      }

      if (onSubmitAction === 'close') {
        Cancel();
      }
      showToaster({ type: 'success', message: 'Success!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, setValues } = useForm({
    initialValues: getInitialValues({
      selectedVehicle,
      selectedTrailer,
      vehiclesList,
      trailersList,
    }),
    onSubmit,
  });

  const onSave = () => {
    setOnSubmitAction('save');
    handleSubmit();
  };

  const onSaveAndClose = () => {
    setOnSubmitAction('close');
    handleSubmit();
  };

  const onNext = () => {
    setOnSubmitAction('next');
    handleSubmit();
  };

  useEffect(() => {
    if (accident) {
      const {
        vehicle_unit_number,
        vehicle_year,
        vehicle_make,
        vehicle_owner,
        vehicle_damage,
        trailer_unit_number,
        trailer_year,
        trailer_make,
        trailer_owner,
        trailer_damage,
      } = accident || {};

      setValues((prevState) => ({
        ...prevState,
        vehicle_unit: vehicle_unit_number
          ? vehiclesList.find((item) => item.equipment_id === vehicle_unit_number)
          : null,
        vehicle_year: vehicle_year || '',
        vehicle_make: vehicle_make || '',
        vehicle_owner: vehicle_owner || '',
        vehicle_damage: vehicle_damage || '',
        trailer_unit: trailer_unit_number
          ? trailersList.find((item) => item.equipment_id === trailer_unit_number)
          : null,
        trailer_year: trailer_year || '',
        trailer_make: trailer_make || '',
        trailer_owner: trailer_owner || '',
        trailer_damage: trailer_damage || '',
      }));
    }
  }, [accident]);

  return (
    <>
      <Modal.Body className='add-accident-modal-body'>
        {loadingData ? (
          <Loader loading={loadingData} />
        ) : detailView ? (
          <Grid container columnSpacing={2.5} rowSpacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4}>
              <Typography variant='b1' style={{ color: palette.gray500 }}>
                Vehicle
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='b1' style={{ color: palette.gray500 }}>
                Trailer
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Unit Number</InputLabel>
              <Typography variant='s3'>{accident?.vehicle_unit_number || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Unit Number</InputLabel>
              <Typography variant='s3'>{accident?.trailer_unit_number || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Year</InputLabel>
              <Typography variant='s3'>{accident?.vehicle_year || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Year</InputLabel>
              <Typography variant='s3'>{accident?.trailer_year || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Make</InputLabel>
              <Typography variant='s3'>{accident?.vehicle_make || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Make</InputLabel>
              <Typography variant='s3'>{accident?.trailer_make || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Owner</InputLabel>
              <Typography variant='s3'>{accident?.vehicle_owner || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Owner</InputLabel>
              <Typography variant='s3'>{accident?.trailer_owner || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Damage</InputLabel>
              <Typography variant='s3'>{accident?.vehicle_damage || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Damage</InputLabel>
              <Typography variant='s3'>{accident?.trailer_damage || '-'}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container columnSpacing={2.5} rowSpacing={2} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} sm={4}>
              <Typography variant='b1' style={{ color: palette.gray500 }}>
                Vehicle
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='b1' style={{ color: palette.gray500 }}>
                Trailer
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel required>Unit Number</InputLabel>
              <Autocomplete
                options={vehiclesList}
                value={values.vehicle_unit}
                getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
                onChange={(e, val) => {
                  setValues((prevState) => ({
                    ...prevState,
                    vehicle_unit: val,
                    vehicle_year: val?.year ? moment(val.year).format('YYYY') : '',
                    vehicle_make: val?.make ? val.make : '',
                    vehicle_owner: val?.equipment_ownership?.owner ? val.equipment_ownership.owner : '',
                  }));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel required>Unit Number</InputLabel>
              <Autocomplete
                options={trailersList}
                value={values.trailer_unit}
                getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
                onChange={(e, val) => {
                  setValues((prevState) => ({
                    ...prevState,
                    trailer_unit: val,
                    trailer_year:
                      val?.year && val?.year !== 'Invalid date' ? Number(getManufacturedYear(val.year)) || '' : '',
                    trailer_make: val?.make ? val.make : '',
                    trailer_owner: val?.equipment_ownership?.owner ? val.equipment_ownership.owner : '',
                  }));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <Input label='Year' name='vehicle_year' value={values.vehicle_year} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input label='Year' name='trailer_year' value={values.trailer_year} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <Input label='Make' name='vehicle_make' value={values.vehicle_make} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input label='Make' name='trailer_make' value={values.trailer_make} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <Input label='Owner' name='vehicle_owner' value={values.vehicle_owner} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input label='Owner' name='trailer_owner' value={values.trailer_owner} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <Textarea
                label='Damage'
                rows={3}
                name='vehicle_damage'
                value={values.vehicle_damage}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Textarea
                label='Damage'
                rows={3}
                name='trailer_damage'
                value={values.trailer_damage}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Close
            </Button>
          </div>
          <div className='pagination-btn'>
            {!!accidentId && (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='secondary'
                title='Edit'
                disabled={loading}
                leftIcon={<EditIcon style={{ marginRight: 10 }} />}
                onClick={() => setDetailView((prevState) => !prevState)}
              />
            )}
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save'
              disabled={loading}
              onClick={onSave}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save & Go To List'
              disabled={loading}
              onClick={onSaveAndClose}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Next'
              onClick={onNext}
              disabled={loading}
              leftIcon={
                loading ? (
                  <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default Equipment;
