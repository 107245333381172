import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import ViewPdf from 'components/ViewPdf';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { updateDriverPay } from 'Api/Shipment';
import {
  applyToSettlement,
  createAdditions,
  createBenefits,
  createDeductions,
  createTaxes,
  declineDisputedRow,
  getAdditions,
  getBenefits,
  getDeductions,
  getSettlement,
  getTaxes,
  processSettlement,
  removeFromSettlement,
  updateDetention,
  updateOneTimeAdditions,
  updateOvertime,
  updatePto,
  updateSettlement,
  updateShipment,
  updateTransactions,
} from 'Api/Payroll';
import { getStaffBalances } from 'Api/Staff';
import { getDriverBalances } from 'Api/Driver';
import Taxes from './Tables/Taxes';
import Benefits from './Tables/Benefits';
import Balances from './Tables/Balances';
import Additions from './Tables/Additions';
import Shipments from './Tables/Shipments';
import Deduction from './Tables/Deduction';
import HoursWorked from './Tables/HoursWorked';
import Commissions from './Tables/Commissions';
import NameImage from './components/NameImage';
import ActionsDone from './components/ActionsDone';
import BookedShipments from './Tables/BookedShipments';
import DeclineReason from './components/DeclineReason';
import StaffInfo from './components/UserInfo/StaffInfo';
import ProcessApprove from './components/ProcessApprove';
import DriverInfo from './components/UserInfo/DriverInfo';
import DisputeReason from '../PayrollAndSettlements/components/DisputeReason';
import { userTypes } from './Tables/Tables.data';
import {
  additionsConverter,
  absencesConverter,
  deductionsConverter,
  shipmentsConverter,
  updateAdditionsConverter,
  updateBenefitsConverter,
  updateDeductionsConverter,
  updateShipmentConverter,
  updateTaxesConverter,
  taxesConverter,
  benefitsConverter,
  getApplied,
  getNotApplied,
  settlementConverter,
  detentionConverter,
  updateDetentionConverter,
  updateOvertimeConverter,
  overtimeConverter,
  updatePtoConverter,
  declineAllConverter,
  declineOnProcessConverter,
  transactionsConverter,
  updateTransactionsConverter,
  updateOneTimeAdditionsConverter,
  oneTimeAdditionsConverter,
  commissionsConverter,
  updateDriverPayConverter,
  convertBalanceDetails,
} from './converters';
import { hoursWorkedConverter } from '../PayrollAndSettlements/Tables/converters';
import { generatePDF } from '../PayrollAndSettlements/Tables/ProcessedSettlements/helpers';
import {
  calcAdditions,
  calcAppliedAmount,
  calcAppliedTaxAmount,
  calcDeductions,
  calcGross,
  calcMiles,
  calcTotalMiles,
  calcTotalSettlement,
  compensationTypes,
  isAllActionsDone,
  validateAdditions,
  validateBenefits,
  validateDeductions,
  validateTaxes,
} from './helpers';
import { SBackButton, SButtonsWrapper, SHeaderWrapper, SLink, SWrapper, SBackdrop } from './Settlements.styles';

const Settlement = () => {
  const { user_type, user_id, id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [settlement, setSettlement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [timeTracking, setTimeTracking] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [additions, setAdditions] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [balances, setBalances] = useState([]);
  const [absences, setAbsences] = useState([]);
  const [detention, setDetention] = useState([]);
  const [overtime, setOvertime] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [oneTimeAdditions, setOneTimeAdditions] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [actionsDoneOpen, setActionsDoneOpen] = useState(false);
  const [itemToDecline, setItemToDecline] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [balanceToEdit, setBalanceToEdit] = useState(null);

  const totalAdditions = useMemo(() => {
    return calcAdditions({ additions, absences, detention, overtime, oneTimeAdditions });
  }, [additions, absences, detention, overtime, oneTimeAdditions]);
  const gross = useMemo(() => {
    return calcGross({ settlement, shipments, commissions, timeTracking, totalAdditions });
  }, [settlement, shipments, commissions, timeTracking, totalAdditions]);
  const miles = useMemo(() => {
    return calcMiles({ shipments });
  }, [shipments]);
  const totalDeductions = useMemo(() => {
    return calcDeductions({ deductions, additions, transactions, taxes, benefits, gross });
  }, [deductions, additions, taxes, benefits, gross, transactions]);
  const totalSettlement = useMemo(() => {
    return calcTotalSettlement({ gross, totalDeductions });
  }, [totalDeductions, gross]);
  const { pay_option, per_hr_option, each_booked_shipment, sales_commission_option } = get(
    settlement,
    'user.compensation_details',
    {}
  );

  const updateSettlementData = async () => {
    try {
      const body = {
        set_payment_date: moment(settlement.set_payment_date || new Date()).format('YYYY-MM-DD'),
        additions_amount: calcAppliedAmount(additions),
        deductions_amount: calcAppliedAmount(deductions) + calcAppliedAmount(transactions || []),
        taxes_amount: calcAppliedTaxAmount(taxes, deductions, additions, gross),
        benefits_amount: calcAppliedAmount(benefits),
        absence_amount: calcAppliedAmount(absences),
        amount: totalSettlement,
      };
      await updateSettlement({ id, body });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const processAndSavePdf = async (file) => {
    try {
      const formData = new FormData();
      formData.append('pdf_file', file);
      formData.append('gross', gross);
      formData.append('amount', totalSettlement);
      await processSettlement({ id, body: formData });
      showToaster({ type: 'success', message: 'Settlement has been processed successfully!' });
      navigate('/payroll', { state: { defaultTab: 3, openSettlement: id } });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getUserAdditions = async () => {
    try {
      const { data } = await getAdditions({ user_id, user_type: userTypes[user_type] });
      const convertedData = additionsConverter(data, Number(id));
      setAdditions(convertedData);
    } catch (e) {
      // Do nothing
    }
  };

  const getUserBenefits = async () => {
    try {
      const { data } = await getBenefits({ user_id, user_type: userTypes[user_type] });
      const convertedData = benefitsConverter(data, Number(id));
      setBenefits(convertedData);
    } catch (e) {
      // Do nothing
    }
  };

  const getUserDeductions = async () => {
    try {
      const { data } = await getDeductions({ user_id, user_type: userTypes[user_type] });
      const convertedData = deductionsConverter(data, Number(id));
      setDeductions(convertedData);
    } catch (e) {
      // Do nothing
    }
  };

  const getUserBalances = async () => {
    try {
      const { data } = Number(user_type) === 1 ? await getStaffBalances(user_id) : await getDriverBalances(user_id);
      setBalances(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getUserTaxes = async (settlement) => {
    try {
      const { data } = await getTaxes({ user_id, user_type: userTypes[user_type] });
      const convertedData = taxesConverter(data, { ...settlement, id: Number(id) });
      setTaxes(convertedData);
    } catch (e) {
      // Do nothing
    }
  };

  const updateDeductions = async () => {
    // No try catch, error is handled from outside
    const body = updateDeductionsConverter(deductions, Number(user_type), Number(user_id));
    return createDeductions({ user_type: userTypes[user_type], body });
  };

  const updateAdditions = async () => {
    // No try catch, error is handled from outside
    const body = updateAdditionsConverter(additions, Number(user_type), Number(user_id));
    return createAdditions({ user_type: userTypes[user_type], body });
  };

  const updateTaxes = async () => {
    // No try catch, error is handled from outside
    const body = updateTaxesConverter(taxes, Number(user_type), Number(user_id), gross);
    return createTaxes({ user_type: userTypes[user_type], body });
  };

  const updateBenefits = async () => {
    // No try catch, error is handled from outside
    const body = updateBenefitsConverter(benefits, Number(user_type), Number(user_id));
    return createBenefits({ user_type: userTypes[user_type], body });
  };

  const updateDetentions = async () => {
    // No try catch, error is handled from outside
    const body = updateDetentionConverter(detention, id);
    return updateDetention({ body });
  };

  const updateOvertimePay = async () => {
    // No try catch, error is handled from outside
    const body = updateOvertimeConverter(overtime, id);
    return updateOvertime({ body });
  };

  const updatePaidTime = async () => {
    // No try catch, error is handled from outside
    const body = updatePtoConverter(absences, id);
    return updatePto({ body });
  };

  const updateTransaction = async () => {
    // No try catch, error is handled from outside
    const body = updateTransactionsConverter(transactions, id);
    return updateTransactions({ body });
  };

  const updateOneTimeAddition = async () => {
    // No try catch, error is handled from outside
    const body = updateOneTimeAdditionsConverter(oneTimeAdditions, id);
    return updateOneTimeAdditions({ body });
  };

  const updateSingleShipment = async (shipment) => {
    // No try catch, error is handled from outside
    const body = updateShipmentConverter(shipment);
    return updateShipment(body);
  };

  const updateCompensationDriverPay = async (driverPay) => {
    // No try catch, error is handled from outside
    const body = updateDriverPayConverter(driverPay);
    return updateDriverPay(body, driverPay.id);
  };

  const handleApplyToSettlement = async (r1, r2, r3, r4) => {
    const body = {
      user_id,
      user_type,
      deductions: getApplied(deductions, r1),
      additions: getApplied(additions, r2),
      taxes: getApplied(taxes, r3, { additions, deductions, gross }),
      benefits: getApplied(benefits, r4),
    };

    try {
      await applyToSettlement({ id, body });
    } catch (e) {
      // Do nothing
    }
  };

  const handleRemoveFromSettlement = async () => {
    const body = {
      user_id,
      user_type,
      additions: getNotApplied(additions),
      deductions: getNotApplied(deductions),
      taxes: getNotApplied(taxes),
      benefits: getNotApplied(benefits),
    };

    try {
      await removeFromSettlement({ id, body });
    } catch (e) {
      // Do nothing
    }
  };

  const onTimeTrackingUpdate = async () => {
    try {
      const pay_period_start = searchParams.get('pay_period_start');
      const pay_period_end = searchParams.get('pay_period_end');
      const { data } = await getSettlement({ user_id, user_type, pay_period_start, pay_period_end });
      const convertedSettlement = settlementConverter(data);
      setSettlement({ ...convertedSettlement, pay_period_start, pay_period_end });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
  };

  const getSettlementDetails = async () => {
    try {
      const pay_period_start = searchParams.get('pay_period_start');
      const pay_period_end = searchParams.get('pay_period_end');
      const { data } = await getSettlement({ user_id, user_type, pay_period_start, pay_period_end });
      const convertedSettlement = settlementConverter({ ...data, user_type: Number(user_type) });
      const convertedAbsences = absencesConverter(data.paidtimeoff);
      const convertedDetention = detentionConverter(data.detention);
      const convertedOvertime = overtimeConverter(data.overtimer);
      const convertedTransactions = transactionsConverter(data.driver_deduction_transactions);
      const convertedOneTimeAdditions = oneTimeAdditionsConverter(data.driver_one_time_additions);
      const convertedCommissions = commissionsConverter(data.commission);

      await Promise.allSettled([
        getUserAdditions({ ...data, pay_period_start, pay_period_end }),
        getUserDeductions({ ...data, pay_period_start, pay_period_end }),
        getUserBalances(),
        getUserTaxes({ ...data, pay_period_start, pay_period_end }),
        getUserBenefits({ ...data, pay_period_start, pay_period_end }),
      ]);
      setAbsences(convertedAbsences);
      setDetention(convertedDetention);
      setOvertime(convertedOvertime);
      setTransactions(convertedTransactions);
      setOneTimeAdditions(convertedOneTimeAdditions);
      setCommissions(convertedCommissions);
      setSettlement({ ...convertedSettlement, pay_period_start, pay_period_end });
      const convertedShipments = shipmentsConverter(data, user_id);
      setShipments(convertedShipments || []);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
      navigate('/payroll');
    }
    setLoading(false);
  };

  const handleSaveAndExit = async () => {
    const deductionsAreValid = validateDeductions(deductions);
    const additionsAreValid = validateAdditions(additions);
    const taxesAreValid = validateTaxes(taxes);
    const benefitsAreValid = validateBenefits(benefits);

    if (!deductionsAreValid || !additionsAreValid || !taxesAreValid || !benefitsAreValid) {
      showToaster({ type: 'error', message: 'There is a missing data field not selected below.' });
      return;
    }

    setLoadingSave(true);
    try {
      const body = {
        set_payment_date: moment(settlement.set_payment_date || new Date()).format('YYYY-MM-DD'),
        additions_amount: calcAppliedAmount(additions),
        deductions_amount: calcAppliedAmount(deductions) + calcAppliedAmount(transactions || []),
        taxes_amount: calcAppliedTaxAmount(taxes, deductions, additions, gross),
        benefits_amount: calcAppliedAmount(benefits),
        absence_amount: calcAppliedAmount(absences),
        amount: totalSettlement,
      };
      const promises = [];
      const p1 = deductions.length ? updateDeductions() : null;
      promises.push(p1);
      const p2 = additions.length ? updateAdditions() : null;
      promises.push(p2);
      const p3 = taxes.length ? updateTaxes() : null;
      promises.push(p3);
      const p4 = benefits.length ? updateBenefits() : null;
      promises.push(p4);
      if (detention.length) {
        const p5 = updateDetentions();
        promises.push(p5);
      }
      if (overtime.length && pay_option === 'per_hour') {
        const p6 = updateOvertimePay();
        promises.push(p6);
      }
      if (absences.length) {
        const p7 = updatePaidTime();
        promises.push(p7);
      }
      if (transactions.length) {
        const p8 = updateTransaction();
        promises.push(p8);
      }
      if (oneTimeAdditions.length) {
        const p9 = updateOneTimeAddition();
        promises.push(p9);
      }
      const settlementPromise = updateSettlement({ id, body });
      const shipmentPromises = shipments.map(updateSingleShipment);

      const driverPays = [];
      shipments.forEach((shipment) =>
        (shipment.driver_pay || []).forEach((driverPay) => {
          if (compensationTypes.includes(driverPay.type)) {
            driverPays.push(driverPay);
          }
        })
      );
      const driverPayPromises = driverPays.map(updateCompensationDriverPay);

      const [r1, r2, r3, r4] = await Promise.allSettled([
        ...promises,
        settlementPromise,
        ...(shipmentPromises || []),
        ...(driverPayPromises || []),
      ]);
      const applyPromise = handleApplyToSettlement(r1?.value?.new, r2?.value?.new, r3?.value?.new, r4?.value?.new);
      const removePromise = handleRemoveFromSettlement();
      await Promise.allSettled([applyPromise, removePromise]);
      showToaster({ type: 'success', message: 'Settlement has been saved successfully!' });
      navigate('/payroll', { state: { defaultTab: 1 } });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingSave(false);
  };

  const handleSaveAndProcess = async (approved) => {
    const deductionsAreValid = validateDeductions(deductions);
    const additionsAreValid = validateAdditions(additions);
    const taxesAreValid = validateTaxes(taxes);
    const benefitsAreValid = validateBenefits(benefits);

    if (!deductionsAreValid || !additionsAreValid || !taxesAreValid || !benefitsAreValid) {
      showToaster({ type: 'error', message: 'There is a missing data field not selected below.' });
      return;
    }

    if (settlement.status_id === 2 && !approved) {
      setApproveDialogOpen(true);
      return;
    }

    if (settlement.status_id === 4 && !approved) {
      const allActionsAreTaken = isAllActionsDone({
        shipments,
        timeTracking,
        additions,
        deductions,
        benefits,
        taxes,
        absences,
        detention,
        overtime,
        transactions,
        oneTimeAdditions,
      });

      if (!allActionsAreTaken) {
        setActionsDoneOpen(true);
        return;
      }

      setLoadingSave(true);
      const body = declineOnProcessConverter({
        settlement: { ...settlement, id },
        additions,
        benefits,
        deductions,
        taxes,
        absences,
        detention,
        overtime,
        shipments,
        time_trackings: timeTracking,
        transactions,
        oneTimeAdditions,
      });
      await declineDisputedRow({ body });
    }
    setApproveDialogOpen(false);
    setLoadingSave(true);
    try {
      const promises = [];
      const p1 = deductions.length ? updateDeductions() : null;
      promises.push(p1);
      const p2 = additions.length ? updateAdditions() : null;
      promises.push(p2);
      const p3 = taxes.length ? updateTaxes() : null;
      promises.push(p3);
      const p4 = benefits.length ? updateBenefits() : null;
      promises.push(p4);
      if (detention.length) {
        const p5 = updateDetentions();
        promises.push(p5);
      }
      if (overtime.length && pay_option === 'per_hour') {
        const p6 = updateOvertimePay();
        promises.push(p6);
      }
      if (absences.length) {
        const p7 = updatePaidTime();
        promises.push(p7);
      }
      if (transactions.length) {
        const p8 = updateTransaction();
        promises.push(p8);
      }
      if (oneTimeAdditions.length) {
        const p9 = updateOneTimeAddition();
        promises.push(p9);
      }
      const shipmentPromises = shipments.map(updateSingleShipment);

      const driverPays = [];
      shipments.forEach((shipment) =>
        (shipment.driver_pay || []).forEach((driverPay) => {
          if (compensationTypes.includes(driverPay.type)) {
            driverPays.push(driverPay);
          }
        })
      );
      const driverPayPromises = driverPays.map(updateCompensationDriverPay);

      const [r1, r2, r3, r4] = await Promise.all([
        ...promises,
        ...(shipmentPromises || []),
        ...(driverPayPromises || []),
      ]);
      const applyPromise = handleApplyToSettlement(r1?.new, r2?.new, r3?.new, r4?.new);
      const removePromise = handleRemoveFromSettlement();
      await Promise.allSettled([applyPromise, removePromise]);

      const newData = hoursWorkedConverter(settlement);
      const { empty_miles, loaded_miles } = calcTotalMiles(shipments);
      const { blob } = await generatePDF({
        ...newData,
        settlementDetails: { ...settlement, gross, take_home: totalSettlement, id, empty_miles, loaded_miles },
        additions,
        benefits,
        deductions,
        balances,
        taxes,
        absences,
        detention,
        overtime,
        transactions,
        oneTimeAdditions,
        shipments,
        currency,
        dateFormat,
      });

      await updateSettlementData();
      await processAndSavePdf(blob);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingSave(false);
  };

  const handlePreview = async () => {
    const deductionsAreValid = validateDeductions(deductions);
    const additionsAreValid = validateAdditions(additions);
    const taxesAreValid = validateTaxes(taxes);
    const benefitsAreValid = validateBenefits(benefits);

    if (!deductionsAreValid || !additionsAreValid || !taxesAreValid || !benefitsAreValid) {
      showToaster({ type: 'error', message: 'There is a missing data field not selected below.' });
      return;
    }

    try {
      const newData = hoursWorkedConverter(settlement);
      const { empty_miles, loaded_miles } = calcTotalMiles(shipments);
      const { url } = await generatePDF({
        ...newData,
        settlementDetails: { ...settlement, gross, take_home: totalSettlement, id, empty_miles, loaded_miles },
        additions,
        benefits,
        deductions,
        balances,
        taxes,
        absences,
        detention,
        overtime,
        transactions,
        oneTimeAdditions,
        shipments,
        currency,
        dateFormat,
      });
      setPreviewUrl(url);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingSave(false);
  };

  const onViewBalanceDetails = (balance) => {
    setBalanceToEdit(convertBalanceDetails(balance));
  };

  const onEditBalanceClose = () => {
    setBalanceToEdit(null);
  };

  const onEditBalanceSuccess = (settlement) => {
    setBalanceToEdit((prevState) => convertBalanceDetails({ ...prevState, deductions: [{ settlement }] }));
    getUserDeductions();
    getUserBalances();
  };

  const onDeclineAll = async (reason) => {
    setLoadingSave(true);
    try {
      const body = declineAllConverter(reason, {
        settlement: { ...settlement, id },
        additions,
        benefits,
        deductions,
        taxes,
        absences,
        detention,
        overtime,
        shipments,
        transactions,
        oneTimeAdditions,
        time_trackings: timeTracking,
      });
      await declineDisputedRow({ body });
      setItemToDecline(null);
      handleSaveAndProcess(true);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getSettlementDetails();
  }, []);

  return (
    <SWrapper>
      <SBackdrop open={loadingSave}>
        <CircularProgress size={30} />
      </SBackdrop>
      <SBackButton>
        <ArrowLeft width={10} height={8} />
        <SLink to='/payroll'>Payroll</SLink>
      </SBackButton>
      {settlement?.status_id === 4 && <DisputeReason settlement={settlement} />}
      {!settlement || loading ? (
        <CircularProgress size={26} className='settlement-loader' />
      ) : (
        <div>
          <SHeaderWrapper>
            <NameImage user={settlement.user} settlementId={id} userType={user_type} />
            {settlement.status_id !== 4 ? (
              <SButtonsWrapper>
                <CustomButton type='secondary' title='Preview' onClick={handlePreview} disabled={loadingSave} />
                <CustomButton
                  type='secondary'
                  title='Save Changes & Exit'
                  onClick={handleSaveAndExit}
                  disabled={loadingSave}
                />
                {settlement.status_id !== 2 && (
                  <CustomButton
                    title='Save & Process'
                    rightIcon={<ArrowRight fill={palette.white} />}
                    onClick={() => handleSaveAndProcess(false)}
                    disabled={loadingSave}
                  />
                )}
              </SButtonsWrapper>
            ) : (
              <SButtonsWrapper>
                {/* <CustomButton */}
                {/*  title='Decline Dispute' */}
                {/*  onClick={() => setItemToDecline('All')} */}
                {/*  disabled={loadingSave} */}
                {/*  className='decline-button' */}
                {/* /> */}
                <CustomButton
                  title='Re-Process Settlement'
                  onClick={() => handleSaveAndProcess(false)}
                  disabled={loadingSave}
                />
              </SButtonsWrapper>
            )}
          </SHeaderWrapper>
          {Number(user_type) === 1 && (
            <StaffInfo
              data={{ ...settlement, gross }}
              setData={setSettlement}
              totalAdditions={totalAdditions}
              totalDeductions={totalDeductions}
              totalSettlement={totalSettlement}
            />
          )}
          {Number(user_type) === 2 && (
            <DriverInfo
              data={{ ...settlement, ...miles, gross }}
              setData={setSettlement}
              totalAdditions={totalAdditions}
              totalDeductions={totalDeductions}
              totalSettlement={totalSettlement}
            />
          )}
          {(pay_option === 'per_hour' || per_hr_option === 1) && (
            <HoursWorked
              settlement={settlement}
              onUpdateSuccess={onTimeTrackingUpdate}
              settlementId={id}
              timeTracking={timeTracking}
              setTimeTracking={setTimeTracking}
            />
          )}
          {Number(user_type) === 2 && (
            <Shipments shipments={shipments} updateShipments={setShipments} settlement={settlement} />
          )}
          {Number(user_type) === 1 && each_booked_shipment === 1 && (
            <BookedShipments shipments={shipments} updateShipments={setShipments} settlement={settlement} />
          )}
          {Number(user_type) === 1 && sales_commission_option === 1 && (
            <Commissions commissions={commissions} updateCommissions={setCommissions} settlement={settlement} />
          )}
          <Balances
            balances={balances}
            userType={userTypes[user_type]}
            userId={Number(user_id)}
            onSuccess={() => {
              getUserDeductions();
              getUserBalances();
            }}
            balanceToEdit={balanceToEdit}
            onViewBalanceDetails={onViewBalanceDetails}
            onEditBalanceSuccess={onEditBalanceSuccess}
            onEditBalanceClose={onEditBalanceClose}
          />
          <Deduction
            settlement={settlement}
            deductions={deductions}
            updateDeductions={setDeductions}
            user_type={user_type}
            transactions={transactions}
            gross={gross}
            updateTransactions={setTransactions}
            balances={balances}
            onViewBalanceDetails={onViewBalanceDetails}
          />
          <Additions
            settlement={settlement}
            additions={additions}
            absences={absences}
            detention={detention}
            overtime={overtime}
            updateAdditions={setAdditions}
            updateAbsences={setAbsences}
            updateDetention={setDetention}
            updateOvertime={setOvertime}
            user_type={user_type}
            oneTimeAdditions={oneTimeAdditions}
            updateOneTimeAdditions={setOneTimeAdditions}
          />
          <Taxes
            settlement={settlement}
            taxes={taxes}
            updateTaxes={setTaxes}
            gross={gross}
            user_type={user_type}
            deductions={deductions}
            additions={additions}
          />
          <Benefits settlement={settlement} benefits={benefits} updateBenefits={setBenefits} user_type={user_type} />
        </div>
      )}
      <ProcessApprove
        open={approveDialogOpen}
        onClose={() => setApproveDialogOpen(false)}
        onSubmit={() => handleSaveAndProcess(true)}
        settlement={settlement}
      />
      <ActionsDone open={actionsDoneOpen} onClose={() => setActionsDoneOpen(false)} />
      <DeclineReason open={!!itemToDecline} onClose={() => setItemToDecline(null)} onSubmit={onDeclineAll} />
      <ViewPdf title='Statement Preview' open={!!previewUrl} pdfUrl={previewUrl} onClose={() => setPreviewUrl(null)} />
    </SWrapper>
  );
};

export default Settlement;
