import React, { useMemo, useState } from 'react';
import Tab from '@mui/material/Tab';
import Divider from 'common/Divider';
import PageHeader from './components/PageHeader';
import ReferralsTable from './components/ReferralsTable';
import ReferrersTable from './components/ReferrersTable';
import ApplicantsTable from './components/ApplicantsTable';
import { SPageWrapper, STabs } from './ApplicantsAndReferrals.styles';

const ApplicantsAndReferrals = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [addReferrerOpen, setAddReferrerOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = useMemo(
    () => [
      { id: 0, component: <ApplicantsTable /> },
      { id: 1, component: <ReferralsTable /> },
      {
        id: 2,
        component: (
          <ReferrersTable addReferrerOpen={addReferrerOpen} onAddReferrerClose={() => setAddReferrerOpen(false)} />
        ),
      },
    ],
    [addReferrerOpen]
  );

  return (
    <SPageWrapper>
      <PageHeader
        showCopyUrl={activeTab === 0}
        hideAction={activeTab !== 2}
        onAddReferrer={() => setAddReferrerOpen(true)}
      />
      <STabs value={activeTab} onChange={handleTabChange}>
        <Tab label='Applicants' id='tab-1' />
        <Tab label='Referrals' id='tab-2' />
        <Tab label='Referrers' id='tab-3' />
      </STabs>
      <Divider />
      {tabs[activeTab].component}
    </SPageWrapper>
  );
};

export default ApplicantsAndReferrals;
