export const getInitialValues = (name, type) => {
  return {
    type: type || 'vendor',
    name: name || '',
    phone_number: '',
    account: null,
    address: '',
    address2: '',
    city: null,
    state: null,
    country: null,
    zipcode: '',
  };
};
