import React from 'react';
import { ReactComponent as Delete } from 'assets/icons/xClose.svg';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import Input from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { validationSchema } from './validateSchema';
import { SFormWrapper, SActions } from '../Documents.styles';

const ItemForm = ({ onSubmit, onDelete }) => {
  const initialValues = {
    name: '',
    driver_visible: false,
    shipment_id_required: false,
  };

  const { handleChange, handleSubmit, values, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <SFormWrapper>
      <div className='flex-item'>
        <Input
          name='name'
          onChange={handleChange}
          value={values.name}
          placeholder='Document Type'
          error={touchedErrors.name}
        />
      </div>
      <div className='flex-item'>
        <CustomCheckbox
          type='switch'
          name='driver_visible'
          checked={values.driver_visible}
          onChange={(checked) => handleChange('driver_visible', checked)}
          smail={false}
          style={{ paddingLeft: '50px' }}
        />
      </div>
      <div className='flex-item'>
        <CustomCheckbox
          type='switch'
          name='shipment_id_required'
          checked={values.shipment_id_required}
          onChange={(checked) => handleChange('shipment_id_required', checked)}
          smail={false}
          style={{ paddingLeft: '50px' }}
        />
      </div>
      <SActions className='flex-item'>
        <CustomButton
          width={12}
          height={12}
          type='secondary'
          title=''
          leftIcon={<Delete style={{ fill: '#464F60' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={onDelete}
        />
        <CustomButton
          width={12}
          height={12}
          type='primary'
          title=''
          leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={handleSubmit}
        />
      </SActions>
    </SFormWrapper>
  );
};

export default ItemForm;
