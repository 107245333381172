import axios from '../services/axios';

// Applicants
export async function getApplicantsList(params) {
  const { data } = await axios.get('job-application', {
    params,
  });
  return data;
}

export async function getJobStatuses() {
  const { data } = await axios.get('job-statuses');
  return data;
}

export async function getApplicant(id) {
  const { data } = await axios.get(`job-application/${id}`);
  return data;
}

export async function createJobAction(body) {
  const { data } = await axios.post(`job-action`, body);
  return data;
}

export async function updateJobStatus(id, body) {
  const { data } = await axios.put(`job-status/${id}`, body);
  return data;
}

// Referrals
export async function getReferralsList(params) {
  const { data } = await axios.get('job/referrals', {
    params,
  });
  return data;
}

// Referrers
export async function getReferrersList(params) {
  const { data } = await axios.get('job/referrers', {
    params,
  });
  return data;
}

// JobPosition
export async function getJobPositionsList(params) {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    'x-dot': user.customer.dot,
  };

  const { data } = await axios.get('customer/job-position', {
    params,
    headers,
  });
  return data;
}

export async function createReferrer(body) {
  const { data } = await axios.post('job/referrer', body);
  return data;
}

export async function deleteReferrer(id) {
  const { data } = await axios.delete(`job/referrer/${id}`);
  return data;
}

export async function sendEmailOrSMS(id) {
  const { data } = await axios.get(`job/referrer/email-sms/${id}`);
  return data;
}
