import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { CircularProgress } from '@mui/material';
import jsPDF from 'jspdf';
import forward from 'assets/icons/forward.png';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import download from 'assets/icons/drivers/download.svg';
import calendar from 'assets/icons/drivers/calendar.png';
import './previousEmploymentVerification.css';
import add from 'assets/icons/drivers/add.png';
import poweredBy from 'assets/icons/drivers/poweredBy.svg';
import cross from 'assets/icons/drivers/cross.svg';

import ErrorMessage from 'common/ErrorMessage';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import {
  forwardPrevData,
  getPreviousRecordData,
  PreviousEmolymentVerificationData,
  submitApplicantPreviousEmploymentVerification,
  submitStaffPreviousEmploymentVerification,
} from 'Api/Driver';
import CustomButton from 'components/CustomButton/CustomButton';
import ForwardPreviousEmployment from 'components/ForwardPreviousEmploymentVerification/ForwardPreviousEmploymentVerification';
import MedicalAndBackgroundModal from 'components/MedicalAndBackgroundModal/MedicalAndBackgroundModal';
import { PrevEmployVerification } from 'components/Drivers/AddDriver/ValidationSchema';
import { getInitialValues } from './PreviousEmploymentVerification.data';

const whitelist = ['US', 'CA', 'MX'];
const PreviousEmolymentVerification = () => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);
  const [viewModalShow, setViewModalShow] = useState({ isOpen: false, data: [] });
  const [isShowDatePicker, setIsShowDatePicker] = useState('');
  const [employment, setEmployment] = useState({});
  const [customTruck, setCustomTruck] = useState('');
  const [customExperience, setCustomExperience] = useState('');
  const [customAreaDriven, setCustomAreaDriven] = useState('');
  const [loading, setLoading] = useState(false);
  const [forwardLoading, setForwardLoading] = useState(false);
  const [forwardEmployment, setForwardEmployment] = useState(false);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const dot = params.get('dot');
  const user_type = params.get('user-type');
  const attempt_id = params.get('attempt_id');
  const sigPad = useRef({});

  const userData = employment?.staff || employment?.driver || employment?.applicant;

  const imageDownloader = (values, handleChange, isDownload) => {
    let defaultLength = 120.5;
    const doc = new jsPDF('p', 'mm', [215.9, 279.4]); // [8.5, 11]
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    // Previous Employment Verification
    doc.setFontSize(20);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text('Previous Employment Verification', pageWidth / 2, 15, { align: 'center' });

    const topLine = 52;
    const heightFirstLine = 50;
    const heightSecondLine = 50;
    const heightThirdLine = 50;
    const heightFourthLine = 50;
    const heightFifthLine = 50;
    const heightSixLine = 50;
    const heightEightLine = 10;
    doc.setFontSize(10);
    doc.text(`${userData.full_name || `${userData.fname} ${userData.lname}`}`, pageWidth / 2, 22, {
      align: 'center',
    });

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(`${employment?.start_date || ''} - ${employment?.end_date || ''}`, pageWidth / 2, 27, {
      align: 'center',
    });

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(`Requested by:`, pageWidth / 2.2, 32, { align: 'center' });

    doc.setFontSize(10);
    doc.text(` ${employment?.company_name || ''} `, pageWidth / 1.81 + 3, 32, { align: 'center' });

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(` ${employment?.email || ''}`, pageWidth / 2, 36, { align: 'center' });

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(
      `${employment?.phone_number || employment?.phone_no || ''} ${employment?.phone_number ? '(Phone Number)' : ''}`,
      pageWidth / 2,
      41,
      { align: 'center' }
    );

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(`${employment?.fax_number || ''} ${employment?.fax_number ? '(Fax)' : ''}`, pageWidth / 2, 45, {
      align: 'center',
    });

    doc.setFontSize(9);
    doc.setTextColor('#464F60');
    doc.text(
      `This request is for previous employment verification for ${employment?.name} by ${employment?.company_name}. We are requesting your verification  -`,
      pageWidth / 2,
      topLine,
      { align: 'center' }
    );

    doc.setFontSize(9);
    doc.setTextColor('#464F60');
    doc.text(
      `for the above mentioned ${
        user_type === 'staff' ? 'employee' : 'driver'
      } that previously worked for your company from to ${employment?.start_date || ''} - ${
        employment?.end_date || ''
      }.`,
      pageWidth / 2,
      topLine + 5,
      { align: 'center' }
    );

    doc.setFontSize(9);
    doc.setTextColor('#464F60');
    doc.text(
      'Please fill the following form and click the submit verification response button for data to be transmitted back to our system. ',
      pageWidth / 2,
      topLine + 10,
      { align: 'center' }
    );

    /// 1st line start
    // Start-End Date
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Start-End Date', 10, 25 + heightFirstLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(`${formatDate(values?.start_date)} - ${formatDate(values?.end_date)}`, 11, 31 + heightFirstLine);
    doc.roundedRect(10, 27 + heightFirstLine, 41, 6, 1, 1);
    // driver name
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(`${user_type === 'staff' ? 'Employee' : 'Driver'} Name`, 90, 25 + heightFirstLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(
      userData?.fname && userData?.lname
        ? `${userData?.fname} ${userData?.lname}`
        : userData?.full_name
        ? `${userData.full_name}`
        : '',
      91,
      31 + heightFirstLine
    );
    doc.roundedRect(90, 27 + heightFirstLine, 42, 6, 1, 1);

    // miles_per_week
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Miles / Week', 140, 25 + heightFirstLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(`${values?.miles_per_week || ''}`, 141, 31 + heightFirstLine);
    doc.roundedRect(140, 27 + heightFirstLine, 28, 6, 1, 1);

    // Hours / Week
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Hours / Week', 176, 25 + heightFirstLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(`${values?.hours_per_week || ''}`, 177, 31 + heightFirstLine);
    doc.roundedRect(176, 27 + heightFirstLine, 27, 6, 1, 1);

    /// 2nd line start

    // Driver Class
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Driver Class', 10, 45 + heightSecondLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.driver_class, 11, 51 + heightSecondLine);
    doc.roundedRect(10, 47 + heightSecondLine, 28, 6, 1, 1);
    // Type
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Type', 55, 45 + heightSecondLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.driver_type, 56, 51 + heightSecondLine);
    doc.roundedRect(55, 47 + heightSecondLine, 28, 6, 1, 1);

    // Subject to FMCSRs?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Subject to FMCSRs?', 100, 45 + heightSecondLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.subject_to_fmcsRs, 101, 51 + heightSecondLine);
    doc.roundedRect(100, 47 + heightSecondLine, 38, 6, 1, 1);

    // Truck
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Truck', 165, 45 + heightSecondLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    [...(values?.employer_truck || []), ...(values?.otherTruck || [])]
      .splice(0, 3)
      ?.map((tru, i) => doc.text(tru, 166, 51 + heightSecondLine + i * 5));
    doc.text(values?.employer_truck?.length > 2 ? 'Others ...' : '', 166, 61 + heightSecondLine);
    doc.roundedRect(165, 47 + heightSecondLine, 38, 14, 1, 1);

    /// third line start

    // Subject to DOT D&A?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Subject to DOT D&A? ', 10, 65 + heightThirdLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.subject_to_dot_dna, 11, 71 + heightThirdLine);
    doc.roundedRect(10, 67 + heightThirdLine, 28, 6, 1, 1);

    // Eligible for rehire?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Eligible for rehire?', 55, 65 + heightThirdLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.eligible_for_rehire, 56, 71 + heightThirdLine);
    doc.roundedRect(55, 67 + heightThirdLine, 28, 6, 1, 1);
    // Responsible for maintaining logs?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Responsible for maintaining logs?', 100, 65 + heightThirdLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.responsible_for_maintaining_logs, 101, 71 + heightThirdLine);
    doc.roundedRect(100, 67 + heightThirdLine, 38, 6, 1, 1);

    // Experience
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Experience', 165, 65 + heightThirdLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    [...(values?.employer_experience || []), ...(values?.otherExperience || [])]
      .splice(0, 3)
      ?.map((tru, i) => doc.text(tru, 166, 71 + heightThirdLine + i * 4));
    doc.text(values?.employer_experience?.length > 2 ? 'Others ...' : '', 166, 79 + heightThirdLine);
    doc.roundedRect(165, 67 + heightThirdLine, 38, 14, 1, 1);

    // Fourth Line

    // Terminated?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Terminated? ', 10, 85 + heightFourthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.driver_terminated, 11, 91 + heightFourthLine);
    doc.roundedRect(10, 87 + heightFourthLine, 28, 6, 1, 1);

    // Loads Hauled
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Loads Hauled', 55, 85 + heightFourthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(`${values?.loads_hauled || ''}`, 56, 91 + heightFourthLine);
    doc.roundedRect(55, 87 + heightFourthLine, 28, 6, 1, 1);

    // Trailer Length
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Trailer Length', 100, 85 + heightFourthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.trailer_length, 101, 91 + heightFourthLine);
    doc.roundedRect(100, 87 + heightFourthLine, 38, 6, 1, 1);

    // Area Driven
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Area Driven', 165, 85 + heightFourthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    [...(values?.employer_area_driven || []), ...(values?.otherAreaDriven || [])]
      .splice(0, 3)
      ?.map((tru, i) => doc.text(tru, 166, 91 + heightFourthLine + i * 4));
    doc.text(values?.employer_area_driven?.length > 2 ? 'Others ...' : '', 166, 99 + heightFourthLine);
    doc.roundedRect(165, 87 + heightFourthLine, 38, 14, 1, 1);

    // # of state driven
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('# of state driven:', 10, 100 + heightFourthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.states_driven, 41, 100 + heightFourthLine);
    doc.roundedRect(40, 96.5 + heightFifthLine, 15, 5, 1, 1);
    // Fifth Line

    // Accidents
    doc.setFontSize(10);
    doc.setTextColor('#171C26');
    doc.text('Accidents', 10, 107 + heightFifthLine);
    // Preventable
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('# Preventable:', 50, 107 + heightFifthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.accidents_preventable, 76, 107.5 + heightFifthLine);
    doc.roundedRect(75, 104 + heightFifthLine, 10, 5, 1, 1);
    // # Non-Preventable
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('# Non-Preventable:', 90, 107 + heightFifthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.accidents_non_preventable, 124, 107.5 + heightFifthLine);
    doc.roundedRect(123, 104 + heightFifthLine, 10, 5, 1, 1);
    // # DOT Reportable:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('# DOT Reportable:', 140, 107 + heightFifthLine);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.accidents_dot_reportable, 173, 107.5 + heightFifthLine);
    doc.roundedRect(172, 104 + heightFifthLine, 15, 5, 1, 1);

    // Sixth Line

    // Date:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Date', 10, 115 + heightSixLine);
    // City,State:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('City,State', 35, 115 + heightSixLine);
    // Description:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Description', 68, 115 + heightSixLine);
    // Fatalities:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Fatalities', 110, 115 + heightSixLine);
    // Injuries:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Injuries', 135, 115 + heightSixLine);
    // Hazmat:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Hazmat?', 158, 115 + heightSixLine);
    // Preventable:
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Preventable?', 180, 115 + heightSixLine);

    // Seventth LIne
    for (let i = 0; i < values?.accident?.length; i++) {
      const accident = values?.accident[i];
      doc.setFontSize(10);
      doc.setFont('Inter', 'normal', 700);
      doc.setTextColor('#171C26');
      doc.text(accident?.acc_date ? formatDate(accident?.acc_date) : '', 11, 120 + heightSixLine + i * 7);
      doc.roundedRect(10, 116.5 + heightSixLine + i * 7, 22, 5, 1, 1);
      doc.setFontSize(10);
      doc.setFont('Inter', 'normal', 700);
      doc.setTextColor('#171C26');
      doc.text(accident?.acc02_country_state_city, 36, 120 + heightSixLine + i * 7);
      doc.roundedRect(35, 116.5 + heightSixLine + i * 7, 28, 5, 1, 1);
      doc.setFontSize(10);
      doc.setFont('Inter', 'normal', 700);
      doc.setTextColor('#171C26');
      doc.text(accident?.acc_description, 69, 120 + heightSixLine + i * 7);
      doc.roundedRect(68, 116.5 + heightSixLine + i * 7, 37, 5, 1, 1);

      doc.setFontSize(10);
      doc.setFont('Inter', 'normal', 700);
      doc.setTextColor('#171C26');
      doc.text(accident?.acc_fatalities, 111, 120 + heightSixLine + i * 7);
      doc.roundedRect(110, 116.5 + heightSixLine + i * 7, 10, 5, 1, 1);

      doc.setFontSize(10);
      doc.setFont('Inter', 'normal', 700);
      doc.setTextColor('#171C26');
      doc.text(accident?.acc_injuries, 136, 120 + heightSixLine + i * 7);
      doc.roundedRect(135, 116.5 + heightSixLine + i * 7, 10, 5, 1, 1);

      doc.setFontSize(10);
      doc.setFont('Inter', 'normal', 700);
      doc.setTextColor('#171C26');
      doc.text(accident.acc_hazmat ? 'Yes' : 'No', 159, 120 + heightSixLine + i * 7);
      doc.roundedRect(158, 116.5 + heightSixLine + i * 7, 10, 5, 1, 1);

      doc.setFontSize(10);
      doc.setFont('Inter', 'normal', 700);
      doc.setTextColor('#171C26');
      doc.text(accident.acc_preventable ? 'Yes' : 'No', 181, (defaultLength = 120 + heightSixLine + i * 7));
      doc.roundedRect(180, 116.5 + heightSixLine + i * 7, 10, 5, 1, 1);
    }

    // eight Line

    // Drug and Alcohol
    doc.setFontSize(10);
    doc.setTextColor('#171C26');
    defaultLength = defaultLength + 10 + heightEightLine;
    doc.text('Drug and Alcohol', 10, defaultLength);
    // Did the employee have alcohol tests with a result of 0.04 or higher?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.roundedRect(193, defaultLength + 4, 10, 5, 1, 1);
    doc.roundedRect(193, defaultLength + 10.5, 10, 5, 1, 1);
    doc.roundedRect(193, defaultLength + 16.5, 10, 5, 1, 1);
    doc.roundedRect(193, defaultLength + 22.5, 10, 5, 1, 1);
    doc.roundedRect(193, defaultLength + 28.5, 10, 5, 1, 1);
    doc.roundedRect(193, defaultLength + 34.5, 10, 5, 1, 1);
    defaultLength += 10;
    doc.text('Did the employee have alcohol tests with a result of 0.04 or higher? ', 10, defaultLength);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.employee_alcohol_test_result, 195, defaultLength - 3);
    // Did the employee have verified positive drug tests?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    defaultLength += 5;
    doc.text('Did the employee have verified positive drug tests? ', 10, defaultLength);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.employee_verified_positive_drug_test, 195, defaultLength - 1);
    // Did the employee refuse to be tested?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    defaultLength += 6;
    doc.text('Did the employee refuse to be tested?', 10, defaultLength);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.employee_refuse_test, 195, defaultLength - 1);
    // Did the employee have other violations of DOT agency drug and alcohol testing regulations?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    defaultLength += 6;
    doc.text(
      'Did the employee have other violations of DOT agency drug and alcohol testing regulations?',
      10,
      defaultLength
    );
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.employee_drug_alcohol_regulations_violations, 195, defaultLength - 1);
    // Did a previous employee have other report a drug and alcohol rule violation to you?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    defaultLength += 6;
    doc.text('Did a previous employee have other report a drug and alcohol rule violation to you?', 10, defaultLength);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.previous_employee_drug_alcohol_regulations_violations, 195, defaultLength - 1);
    // If you answered “Yes” to any of the above items, did the employee complete the return-to-duty process?
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    defaultLength += 6;
    doc.text(
      'If you answered “Yes” to any of the above items, did the employee complete the return-to-duty process?',
      10,
      defaultLength
    );
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.employee_complete_return_to_duty_process, 195, defaultLength - 1);

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(`1 of 2`, 16, 275);
    doc.setTextColor('#171C26');
    doc.setFontSize(11);
    doc.setTextColor('#171C26');

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Powered by Truckin Digital', 150, 275);

    /// ////////// next page

    let y = 500; // Height position of new content
    if (y >= 400) {
      doc.addPage();
      y = 0; // Restart height position
    }

    defaultLength = 20;
    // Signature
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Signature', 10, defaultLength);
    // Title
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Title', 65, defaultLength);
    // Date
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Date', 110, defaultLength);
    // Phone Number
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Phone Number', 165, defaultLength);
    defaultLength += 5;
    // Signature
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.setFillColor(255, 0, 0);
    doc.roundedRect(10, defaultLength - 3, 48, 18, 1, 1);
    const imgData = sigPad?.current?.toDataURL('image/png');
    doc.addImage(imgData, 'png', 10, defaultLength - 3, 48, 18);
    // Title
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.title, 66, defaultLength + 1);
    doc.roundedRect(65, defaultLength - 3, 28, 6, 1, 1);

    // Date
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(formatDate(values?.date), 111, defaultLength + 1);
    doc.roundedRect(110, defaultLength - 3, 35, 6, 1, 1);

    // Phone Number
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.phone_no, 166, defaultLength + 1);
    doc.roundedRect(165, defaultLength - 3, 35, 6, 1, 1);

    defaultLength += 20;
    // Company DOT #
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Company DOT #', 10, defaultLength);

    // Email
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Email', 55, defaultLength);

    // Full Name
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Full Name', 120, defaultLength);

    defaultLength += 5;
    // Company DOT #
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.company_dot, 11, defaultLength + 1);
    doc.roundedRect(10, defaultLength - 3, 28, 6, 1, 1);
    // Email
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.email, 56, defaultLength + 1);
    doc.roundedRect(55, defaultLength - 3, 50, 6, 1, 1);
    // Full Name
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text(values?.signature_name, 121, defaultLength + 1);
    doc.roundedRect(120, defaultLength - 3, 40, 6, 1, 1);

    // Comments
    defaultLength += 15;
    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Comments', 10, defaultLength);
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    defaultLength += 5;
    doc.text(values?.comments, 13, defaultLength + 2, { maxWidth: 180 });
    doc.roundedRect(10, defaultLength - 3, 193, 30, 1, 1);

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text(`2 of 2`, 16, 275);
    doc.setTextColor('#171C26');
    doc.setFontSize(11);
    doc.setTextColor('#171C26');

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Powered by Truckin Digital', 150, 275);

    /// //////// next page enc

    if (isDownload === 'download') {
      doc.save('prevEmployVerification.pdf');
    } else {
      const pdfImage = doc.output('blob');
      handleChange('response_path', pdfImage);
      submit(pdfImage);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(dot),
    validationSchema: PrevEmployVerification,
    onSubmit: () => imageDownloader(values, handleChange, 'upload'),
  });

  const remove = (index) => {
    const temp = values.accident;
    temp.splice(index, 1);
    handleChange('accident', temp);
  };

  const apply = () => {
    handleChange('start_date', dateSelect[0]?.startDate);
    handleChange('end_date', dateSelect[0]?.endDate);
    setIsShowDatePicker(false);
  };

  useEffect(() => {
    handleChange('signature_name', '');
    getPreviousRecordData(token, dot, user_type, attempt_id).then((res) => {
      setEmployment(res?.data);
      handleChange('start_date', res?.data?.start_date);
      handleChange('end_date', res?.data?.end_date);
    });
  }, []);

  const onChangeExperience = (value) => {
    const temp = values.employer_experience;
    const hasValueIndex = temp.indexOf(value);
    if (hasValueIndex >= 0) {
      temp.splice(hasValueIndex, 1);
    } else {
      temp.push(value);
    }
    handleChange('employer_experience', temp);
  };

  const onChangeTruck = (value) => {
    const temp = values.employer_truck;
    const hasValueIndex = temp.indexOf(value);
    if (hasValueIndex >= 0) {
      temp.splice(hasValueIndex, 1);
    } else {
      temp.push(value);
    }
    handleChange('employer_truck', temp);
  };

  const onChangeAreaDriven = (value) => {
    const temp = values.employer_area_driven;
    const hasValueIndex = temp.indexOf(value);
    if (hasValueIndex >= 0) {
      temp.splice(hasValueIndex, 1);
    } else {
      temp.push(value);
    }
    handleChange('employer_area_driven', temp);
  };

  const downloadFile = (fileUrl) => {
    fetch(fileUrl, { mode: 'cors' })
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = new Blob([resp]);
        const link = document.createElement('a');
        const url = URL.createObjectURL(file);
        link.setAttribute('download', 'download');
        link.href = url;
        link.download = `prevEmployVerification.pdf`;
        link.click();
      });
  };

  const onSubmitSuccess = () => {
    setLoading(false);
    showToaster({
      type: 'success',
      message: `Previous employer verification has been successfully submitted on ${formatDate(new Date())}`,
    });
    getPreviousRecordData(token, dot, user_type, attempt_id).then((res) => {
      setEmployment(res?.data);
    });
  };

  const onSubmitError = (e) => {
    setLoading(false);
    showToaster({ type: 'error', message: getErrorMessage(e) });
  };

  const submit = (response_path) => {
    setLoading(true);
    const exp = [...values.employer_experience, values?.otherExperience];
    const expData = exp.flat(1);
    const tru = [...values.employer_truck, values?.otherTruck];
    const truckData = tru.flat(1);
    const driven = [...values.employer_area_driven, values?.otherAreaDriven];
    const drivenData = driven.flat(1);
    const {
      start_date,
      end_date,
      miles_per_week,
      hours_per_week,
      hold_position,
      reason_for_leaving,
      driver_class,
      driver_type,
      subject_to_fmcsRs,
      subject_to_dot_dna,
      eligible_for_rehire,
      responsible_for_maintaining_logs,
      states_driven,
      driver_terminated,
      loads_hauled,
      trailer_length,
      accidents_preventable,
      accidents_non_preventable,
      accidents_dot_reportable,
      employee_alcohol_test_result,
      employee_verified_positive_drug_test,
      employee_refuse_test,
      employee_drug_alcohol_regulations_violations,
      previous_employee_drug_alcohol_regulations_violations,
      employee_complete_return_to_duty_process,
      title,
      date,
      phone_no: phone,
      company_dot,
      email,
      comments,
      accident,
      signature_name,
      dot,
    } = values;

    const phone_no = phone.replace('+', '');

    const userType = employment?.applicant
      ? 'applicant'
      : employment?.driver
      ? 'driver'
      : employment?.staff
      ? 'staff'
      : 'driver';

    const formData = new FormData();
    formData.append('user_type', userType);
    formData.append('emp_history_id', employment?.id || null);
    formData.append('start_date', moment(new Date(start_date)).format('YYYY-MM-DD'));
    formData.append('end_date', moment(new Date(end_date)).format('YYYY-MM-DD'));
    if (miles_per_week) {
      formData.append('miles_per_week', miles_per_week);
    }
    if (hours_per_week) {
      formData.append('hours_per_week', hours_per_week);
    }
    formData.append('hold_position', hold_position);
    formData.append('reason_for_leaving', reason_for_leaving);
    formData.append('driver_class', driver_class);
    formData.append('driver_type', driver_type);
    formData.append('subject_to_fmcsRs', subject_to_fmcsRs);
    formData.append('subject_to_dot_dna', subject_to_dot_dna);
    formData.append('eligible_for_rehire', eligible_for_rehire);
    formData.append('responsible_for_maintaining_logs', responsible_for_maintaining_logs);
    formData.append('states_driven', states_driven);
    formData.append('driver_terminated', driver_terminated);
    if (loads_hauled) {
      formData.append('loads_hauled', loads_hauled);
    }
    formData.append('trailer_length', trailer_length);
    formData.append('accidents_preventable', accidents_preventable);
    formData.append('accidents_non_preventable', accidents_non_preventable);
    formData.append('accidents_dot_reportable', accidents_dot_reportable);
    formData.append('employee_alcohol_test_result', employee_alcohol_test_result);
    formData.append('employee_verified_positive_drug_test', employee_verified_positive_drug_test);
    formData.append('employee_refuse_test', employee_refuse_test);
    formData.append('employee_drug_alcohol_regulations_violations', employee_drug_alcohol_regulations_violations);
    formData.append(
      'previous_employee_drug_alcohol_regulations_violations',
      previous_employee_drug_alcohol_regulations_violations
    );
    formData.append('employee_complete_return_to_duty_process', employee_complete_return_to_duty_process);
    formData.append('title', title);
    formData.append('signature_name', signature_name);
    formData.append('date', moment(date).format('YYYY-MM-DD'));
    formData.append('phone_no', phone_no);
    formData.append('company_dot', company_dot);
    formData.append('email', email);
    const signatureBlob = b64toBlob(sigPad?.current?.toDataURL('image/png'));
    formData.append('signature', signatureBlob);
    formData.append('comments', comments);
    formData.append('employer_experience[]', expData);
    formData.append('employer_truck[]', truckData);
    formData.append('employer_area_driven[]', drivenData);
    formData.append('response_path', response_path || values.response_path);
    formData.append('dot', dot);

    const filteredAccidents = accident.filter((i) => !!i.acc_date);

    for (let i = 1; i <= filteredAccidents.length; i++) {
      const acc = filteredAccidents[i - 1];
      const sequence = i.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      formData.append(`attempt_id`, employment?.attempt_id);
      formData.append(`acc${sequence}_date`, moment(new Date(acc?.acc_date || new Date())).format('MM-DD-YYYY'));
      formData.append(`acc${sequence}_country_state_city`, acc?.acc02_country_state_city);
      formData.append(`acc${sequence}_description`, acc?.acc_description);
      formData.append(`acc${sequence}_fatalities`, acc?.acc_fatalities);
      formData.append(`acc${sequence}_hazmat`, acc?.acc_hazmat ? 'Yes' : 'No');
      formData.append(`acc${sequence}_injuries`, acc?.acc_injuries === null ? 'No' : acc?.acc_injuries);
      formData.append(`acc${sequence}_preventable`, acc?.acc_preventable ? 'Yes' : 'No');
    }

    switch (userType) {
      case 'staff':
        submitStaffPreviousEmploymentVerification(formData).then(onSubmitSuccess).catch(onSubmitError);
        break;
      case 'driver':
        PreviousEmolymentVerificationData(formData).then(onSubmitSuccess).catch(onSubmitError);
        break;
      case 'applicant':
        submitApplicantPreviousEmploymentVerification(formData).then(onSubmitSuccess).catch(onSubmitError);
        break;
      default:
    }
  };

  const Forward = (forward, onHide = null) => {
    setForwardLoading(true);
    forwardPrevData(forward)
      .then(() => {
        if (onHide) {
          onHide();
        }
        setForwardLoading(false);
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) });
        setForwardLoading(false);
      });
  };

  const clear = () => {
    sigPad.current.clear();
  };

  const b64toBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  return (
    <div className='employment-container driver-style-wrap'>
      <div className='employment-wrapper-main-wrap'>
        <div className='d-flex flex-lg-row flex-column flex-wrap justify-content-between align-items-center employment-wrapper'>
          <div>
            <img className='Previous-employ-logo' src={employment?.company_logo} alt='' />
          </div>
          <div className='employment-wrapper-middle'>
            <div>
              <h3 className='fs-3 text-center' style={{ color: palette.gray900 }}>
                Previous Employer Verification
              </h3>
            </div>
            <div className='employment-title'>
              <p className='View-text fs-5' style={{ color: palette.indigo500 }}>
                {`${userData?.full_name || `${userData?.fname} ${userData?.lname}`}`}
              </p>
            </div>
            <div className='d-flex justify-content-around align-items-center sub-wraper'>
              <div className='date-container-previous-verification' style={{ backgroundColor: '#F0F2F7' }}>
                <p className='sub-text-previous-verification' style={{ color: palette.gray600 }}>
                  {employment?.start_date}
                </p>
              </div>
              <span>{` - `}</span>
              <div className='date-container-previous-verification' style={{ backgroundColor: '#F0F2F7' }}>
                <p className='sub-text-previous-verification' style={{ color: palette.gray600 }}>
                  {employment?.end_date}
                </p>
              </div>
            </div>
            <div className='employment-title'>
              <p className='req-pre-details'>
                Requested By: <span className='View-text-req-pre'>{employment?.company_name}</span>
              </p>
              <p className='req-pre-details'>
                <span>{employment?.email}</span>
              </p>
              <p className='req-pre-details'>
                <span>{employment?.phone_number ? `${employment?.phone_number} (Phone Number)` : ''}</span>
              </p>
              <p className='req-pre-details'>
                <span>{employment?.fax_number ? `${employment?.fax_number} (Fax)` : ''}</span>
              </p>
            </div>
          </div>
          {!employment?.response_date && (
            <div className='employment-wrapper-end'>
              <img src={forward} alt='' className='employe-icon' onClick={() => setForwardEmployment(true)} />
              <img
                src={download}
                alt=''
                className='employe-icon'
                onClick={() => imageDownloader(values, handleChange, 'download')}
              />
            </div>
          )}
          {employment?.response_path && (
            <div className='employment-wrapper-end'>
              <img
                src={download}
                alt=''
                className='employe-icon'
                onClick={() => downloadFile(employment?.response_path)}
              />
            </div>
          )}
        </div>
      </div>
      {employment?.response_date && (
        <div className='employment-content' style={{ minHeight: '61vh' }}>
          <div className='emplye-form-desc'>
            <div className='emplye-desc-first'>
              <p className='fs-6'>
                Previous employer verification has been successfully submitted on {formatDate(employment.response_date)}
                .
              </p>
            </div>
          </div>
        </div>
      )}
      {!employment?.response_date && (
        <div className='employment-content'>
          <div className='emplye-form-desc'>
            <div className='emplye-desc-first'>
              <p className='fs-6'>
                This request is for previous employment verification for {employment?.name} by{' '}
                {employment?.company_name}. We are requesting your verification for the above-{' '}
              </p>
              <p className='fs-6'>
                mentioned {`${user_type === 'staff' ? 'employee' : 'driver'}`} that previously worked for your company
                from {employment?.start_date} to {employment?.end_date}.{' '}
              </p>
            </div>
            <div>
              <p className='fs-6'>
                Please fill out the following form and click the submit verification response button for data to be
                transmitted back to our system.{' '}
              </p>
            </div>
          </div>
          <div className='employe-form p-md-4 p-3' id='employe-form-verifiation'>
            <div className='period-of-service-detail'>
              <p style={{ color: palette.gray900 }}>Period of Service Detail</p>
            </div>
            <div className='row justify-content-between'>
              <div className='col-lg-4 col-md-6 col-12'>
                <div className='start-end-date mb-2'>
                  <p className='table-data-text' style={{ marginLeft: '0', color: palette.gray700 }}>
                    Start-End Date 1 <span className='required'>*</span>
                  </p>
                  <span
                    onClick={() => setIsShowDatePicker(true)}
                    className='start-end-date-container'
                    style={{
                      background: palette.white,
                      color: palette.dark900,
                      borderColor: palette.gray50,
                    }}
                  >
                    {values.start_date ? formatDate(new Date(values.start_date)) : '-'} -{' '}
                    {values.end_date ? formatDate(new Date(values.end_date)) : '-'}
                  </span>
                  {isShowDatePicker && (
                    <div className='datePicker'>
                      <div>
                        <DateRange
                          editableDateInputs
                          onChange={(item) => setDateSelect([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={dateSelect}
                          direction='vertical'
                          scroll={{ enabled: true }}
                        />
                      </div>
                      <div>
                        <div
                          className='d-flex justify-content-end flex-wrap'
                          style={{ padding: '10px', background: '#fff' }}
                        >
                          <div onClick={() => setIsShowDatePicker(false)} style={{ marginRight: '10px' }}>
                            <PrimaryBtn title='Close' />
                          </div>
                          <div onClick={apply}>
                            <PrimaryBtn icon={calendar} title='Apply' />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <ErrorMessage error={touchedErrors.end_date} />
              </div>
              <div className='col-lg-4 col-md-6 col-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-12 col-6'>
                    <p className='table-data-text' style={{ marginLeft: '0', color: palette.gray700 }}>
                      Miles / Week
                    </p>
                    <input
                      type='text'
                      style={{
                        background: palette.white,
                        color: palette.dark900,
                        borderColor: palette.gray50,
                      }}
                      name='miles_per_week'
                      id='miles_per_week'
                      onChange={handleChange}
                      value={values.miles_per_week}
                    />
                  </div>
                  <div className='col-lg-6 col-md-12 col-6'>
                    <p className='table-data-text' style={{ marginLeft: '0', color: palette.gray700 }}>
                      Hours / Week
                    </p>
                    <input
                      type='text'
                      style={{
                        background: palette.white,
                        color: palette.dark900,
                        borderColor: palette.gray50,
                      }}
                      name='hours_per_week'
                      id='hours_per_week'
                      onChange={handleChange}
                      value={values.hours_per_week}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-between position-held-section'>
              <div className='d-md-flex col-md-4 col-12 justify-content-between align-items-center positions-held'>
                <p
                  className='m-0 align-self-center table-data-text'
                  style={{ marginLeft: '0', color: palette.gray700 }}
                >
                  Position(s) Held <span className='required'>*</span>
                </p>
                <div>
                  <input
                    type='text'
                    style={{
                      background: palette.white,
                      color: palette.dark900,
                      borderColor: palette.gray50,
                    }}
                    name='hold_position'
                    id='hold_position'
                    onChange={handleChange}
                    value={values.hold_position}
                  />
                  <ErrorMessage error={touchedErrors.hold_position} />
                </div>
              </div>
              <div className='d-md-flex mt-md-0 mt-2 col-md-5 col-12 justify-content-between align-items-center reasons-for-leaving'>
                <p
                  className='m-0 align-self-center table-data-text'
                  style={{ marginLeft: '0', color: palette.gray700 }}
                >
                  Reason(s) for Leaving <span className='required'>*</span>
                </p>
                <div>
                  <input
                    type='text'
                    style={{
                      background: palette.white,
                      color: palette.dark900,
                      borderColor: palette.gray50,
                    }}
                    name='reason_for_leaving'
                    id='reason_for_leaving'
                    onChange={handleChange}
                    value={values.reason_for_leaving}
                  />
                  <ErrorMessage error={touchedErrors.reason_for_leaving} />
                </div>
              </div>
            </div>
            <div className='drive-class-section flex-column d-flex flex-wrap gap-2'>
              <div className='row'>
                <div className='d-flex flex-column col-lg-2 col-md-3 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Driver Class: <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_class'
                      className='radio-button'
                      value='company'
                      onChange={(event) => handleChange('driver_class', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Company
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_class'
                      className='radio-button'
                      value='Lease'
                      onChange={(event) => handleChange('driver_class', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Lease
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_class'
                      className='radio-button'
                      value='own/op'
                      onChange={(event) => handleChange('driver_class', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Own/Op
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_class'
                      className='radio-button'
                      value='other'
                      onChange={(event) => handleChange('driver_class', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Other
                    </span>
                  </div>
                  <ErrorMessage error={touchedErrors.driver_class} />
                </div>
                <div className='d-flex flex-column col-md-2 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Type: <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_type'
                      className='radio-button'
                      value='solo'
                      onChange={(event) => handleChange('driver_type', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Solo
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_type'
                      className='radio-button'
                      value='Team'
                      onChange={(event) => handleChange('driver_type', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Team
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_type'
                      className='radio-button'
                      value='Student'
                      onChange={(event) => handleChange('driver_type', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Student
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_type'
                      className='radio-button'
                      value='other'
                      onChange={(event) => handleChange('driver_type', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Other
                    </span>
                  </div>
                  <ErrorMessage error={touchedErrors.driver_type} />
                </div>
                <div className='d-flex mt-md-0 mt-4 flex-column col-lg-2 col-md-3 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Truck: <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_truck.indexOf('Tractor-Trailer') >= 0}
                      onChange={() => onChangeTruck('Tractor-Trailer')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Tractor-Trailer
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_truck.indexOf('Straight Truck') >= 0}
                      onChange={() => onChangeTruck('Straight Truck')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Straight Truck
                    </span>
                  </div>
                  {values?.otherTruck?.map((val, index) => (
                    <div className='d-flex align-items-center' key={val}>
                      <input
                        type='checkbox'
                        className='checkbox-style'
                        name={val}
                        id={val}
                        checked
                        onChange={() => {
                          const temp = values?.otherTruck || [];
                          temp?.splice(index, 1);
                          handleChange([...(temp || [])]);
                        }}
                      />
                      <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                        {val}
                      </span>
                    </div>
                  ))}
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      name='hasOtherTruck'
                      id='hasOtherTruck'
                      value={values.hasOtherTruck}
                      checked={values.hasOtherTruck}
                      onChange={() => {
                        handleChange('hasOtherTruck', !values?.hasOtherTruck);
                      }}
                    />
                    <span className='table-data-text m-0 other' style={{ color: palette.gray700 }}>
                      Other:
                      {values?.hasOtherTruck && (
                        <input
                          type='text'
                          placeholder='Other Truck'
                          name='otherTruck'
                          id='otherTruck'
                          className='precious-verification-othes-input'
                          onChange={(event) => setCustomTruck(event.target.value)}
                          value={customTruck}
                          style={{
                            backgroundColor: palette.white,
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleChange('otherTruck', [...values.otherTruck, customTruck]);
                              setCustomTruck('');
                            }
                          }}
                        />
                      )}
                    </span>
                    {values?.hasOtherTruck && (
                      <CustomButton
                        title='Add'
                        type='primary'
                        styleTitle={{ fontSize: 12, fontWeight: 400 }}
                        styleButton={{ margin: 0 }}
                        onClick={() => {
                          handleChange('otherTruck', [...values.otherTruck, customTruck]);
                          setCustomTruck('');
                        }}
                      />
                    )}
                  </div>
                  <ErrorMessage error={touchedErrors.employer_truck} />
                </div>
                <div className='d-flex mt-md-0 mt-4 flex-column col-md-2 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Subject to FMCSRs? <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='subject_to_fmcsRs'
                      className='radio-button'
                      value='yes'
                      onChange={(event) => handleChange('subject_to_fmcsRs', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Yes
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='subject_to_fmcsRs'
                      className='radio-button'
                      value='no'
                      onChange={(event) => handleChange('subject_to_fmcsRs', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      No
                    </span>
                  </div>
                  <ErrorMessage error={touchedErrors.subject_to_fmcsRs} />
                </div>
                <div className='d-flex mt-md-0 mt-4 flex-column col-md-2 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Subject to DOT D&A? <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='subject_to_dot_dna'
                      className='radio-button'
                      value='yes'
                      onChange={(event) => handleChange('subject_to_dot_dna', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Yes
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='subject_to_dot_dna'
                      className='radio-button'
                      value='no'
                      onChange={(event) => handleChange('subject_to_dot_dna', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      No
                    </span>
                  </div>
                  <ErrorMessage error={touchedErrors.subject_to_dot_dna} />
                </div>
              </div>
              <div className='row'>
                <div className='d-flex flex-column col-lg-2 col-md-4 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Eligible for rehire? <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='eligible_for_rehire'
                      className='radio-button'
                      value='yes'
                      onChange={(event) => handleChange('eligible_for_rehire', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Yes
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='eligible_for_rehire'
                      className='radio-button'
                      value='no'
                      onChange={(event) => handleChange('eligible_for_rehire', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      No
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='eligible_for_rehire'
                      className='radio-button'
                      value='review'
                      onChange={(event) => handleChange('eligible_for_rehire', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Review
                    </span>
                  </div>
                  <ErrorMessage error={touchedErrors.eligible_for_rehire} />
                </div>
                <div className='d-flex flex-column col-lg-2 col-md-4 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Experience: <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_experience.indexOf('Flatbed') >= 0}
                      onChange={() => onChangeExperience('Flatbed')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Flatbed
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_experience.indexOf('Van') >= 0}
                      onChange={() => onChangeExperience('Van')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Van
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_experience.indexOf('Reefer') >= 0}
                      onChange={() => onChangeExperience('Reefer')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Reefer
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_experience.indexOf('Intermodal') >= 0}
                      onChange={() => onChangeExperience('Intermodal')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Intermodal
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_experience.indexOf('Snow/Ice') >= 0}
                      onChange={() => onChangeExperience('Snow/Ice')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Snow/Ice
                    </span>
                  </div>
                  {values?.otherExperience?.map((val, index) => (
                    <div className='d-flex align-items-center' key={val}>
                      <input
                        type='checkbox'
                        className='checkbox-style'
                        name={val}
                        id={val}
                        checked
                        onChange={() => {
                          const temp = values?.otherExperience || [];
                          temp?.splice(index, 1);
                          handleChange([...(temp || [])]);
                        }}
                      />
                      <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                        {val}
                      </span>
                    </div>
                  ))}
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      name='hasOtherExperence'
                      id='hasOtherExperence'
                      value={values.hasOtherExperence}
                      checked={values.hasOtherExperence}
                      onChange={() => {
                        handleChange('hasOtherExperence', !values?.hasOtherExperence);
                      }}
                    />
                    <div className='d-flex justify-content-center align-items-center' />
                    <span className='table-data-text m-0 other' style={{ color: palette.gray700 }}>
                      Other:
                      {values?.hasOtherExperence && (
                        <input
                          type='text'
                          placeholder='Other Experience'
                          name='otherExperience'
                          id='otherExperience'
                          className='precious-verification-othes-input'
                          onChange={(event) => setCustomExperience(event.target.value)}
                          value={customExperience}
                          style={{
                            backgroundColor: palette.white,
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleChange('otherExperience', [...values.otherExperience, customExperience]);
                              setCustomExperience('');
                            }
                          }}
                        />
                      )}
                    </span>
                    {values?.hasOtherExperence && (
                      <CustomButton
                        title='Add'
                        type='primary'
                        styleTitle={{ fontSize: 12, fontWeight: 400 }}
                        styleButton={{ margin: 0 }}
                        onClick={() => {
                          handleChange('otherExperience', [...values.otherExperience, customExperience]);
                          setCustomExperience('');
                        }}
                      />
                    )}
                  </div>
                  <ErrorMessage error={touchedErrors.employer_experience} />
                </div>
                <div className='d-flex mt-md-0 mt-4 flex-column col-lg-2 col-md-4 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Responsible for maintaining logs? <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='responsible_for_maintaining_logs'
                      className='radio-button'
                      value='yes'
                      onChange={(event) => handleChange('responsible_for_maintaining_logs', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Yes
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='responsible_for_maintaining_logs'
                      className='radio-button'
                      value='no'
                      onChange={(event) => handleChange('responsible_for_maintaining_logs', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      No
                    </span>
                  </div>
                  <ErrorMessage error={touchedErrors.responsible_for_maintaining_logs} />
                </div>
                <div className='d-flex mt-md-0 mt-4 flex-column col-lg-2 col-md-4 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Area Driven: <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_area_driven.indexOf('OTR') >= 0}
                      onChange={() => onChangeAreaDriven('OTR')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      OTR
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_area_driven.indexOf('Regional') >= 0}
                      onChange={() => onChangeAreaDriven('Regional')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Regional
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      checked={values.employer_area_driven.indexOf('Local') >= 0}
                      onChange={() => onChangeAreaDriven('Local')}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Local
                    </span>
                  </div>
                  {values?.otherAreaDriven?.map((val, index) => (
                    <div className='d-flex align-items-center' key={val}>
                      <input
                        type='checkbox'
                        className='checkbox-style'
                        name={val}
                        id={val}
                        checked
                        onChange={() => {
                          const temp = values?.otherAreaDriven || [];
                          temp?.splice(index, 1);
                          handleChange([...(temp || [])]);
                        }}
                      />
                      <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                        {val}
                      </span>
                    </div>
                  ))}
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      className='checkbox-style'
                      name='hasOtherAreaDriven'
                      id='hasOtherAreaDriven'
                      value={values.hasOtherAreaDriven}
                      checked={values.hasOtherAreaDriven}
                      onChange={() => {
                        handleChange('hasOtherAreaDriven', !values?.hasOtherAreaDriven);
                      }}
                    />
                    <span className='table-data-text m-0 other' style={{ color: palette.gray700 }}>
                      Other:
                      {values?.hasOtherAreaDriven && (
                        <input
                          type='text'
                          placeholder='Other Area Driven'
                          name='otherAreaDriven'
                          id='otherAreaDriven'
                          className='precious-verification-othes-input'
                          onChange={(event) => setCustomAreaDriven(event.target.value)}
                          value={customAreaDriven}
                          style={{
                            backgroundColor: palette.white,
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleChange('otherAreaDriven', [...values.otherAreaDriven, customAreaDriven]);
                              setCustomAreaDriven('');
                            }
                          }}
                        />
                      )}
                    </span>
                    {values?.hasOtherAreaDriven && (
                      <CustomButton
                        title='Add'
                        type='primary'
                        styleTitle={{ fontSize: 12, fontWeight: 400 }}
                        styleButton={{ margin: 0 }}
                        onClick={() => {
                          handleChange('otherAreaDriven', [...values.otherAreaDriven, customAreaDriven]);
                          setCustomAreaDriven('');
                        }}
                      />
                    )}
                  </div>
                  <ErrorMessage error={touchedErrors.employer_area_driven} />
                </div>
                <div className='d-flex mt-md-0 mt-4 flex-column col-lg-2 col-md-4 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    # of state driven <span className='required'>*</span>
                  </p>
                  <div>
                    <input
                      type='text'
                      name='states_driven'
                      id='states_driven'
                      onChange={handleChange}
                      value={values.states_driven}
                      className='m-0'
                      style={{
                        width: '100%',
                        background: palette.white,
                        color: palette.dark900,
                        borderColor: palette.gray50,
                      }}
                    />
                    <ErrorMessage error={touchedErrors.states_driven} />
                  </div>
                </div>
                <div className='d-flex mt-md-0 mt-4 flex-column col-lg-2 col-md-4 col-6'>
                  <p className='m-0' style={{ color: palette.gray900 }}>
                    Terminated? <span className='required'>*</span>
                  </p>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_terminated'
                      className='radio-button'
                      value='yes'
                      onChange={(event) => handleChange('driver_terminated', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      Yes
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='radio'
                      name='driver_terminated'
                      className='radio-button'
                      value='no'
                      onChange={(event) => handleChange('driver_terminated', event.target.value)}
                    />
                    <span className='table-data-text m-0' style={{ color: palette.gray700 }}>
                      No
                    </span>
                  </div>
                  <ErrorMessage error={touchedErrors.driver_terminated} />
                </div>
              </div>
            </div>
            <div className='loads-hauled-section row'>
              <div className='d-flex align-items-center col-md-5 col-12'>
                <p style={{ color: palette.gray900 }}>Loads Hauled</p>
                <div>
                  <input
                    type='text'
                    style={{
                      background: palette.white,
                      color: palette.dark900,
                      borderColor: palette.gray50,
                    }}
                    className='input-field'
                    name='loads_hauled'
                    id='loads_hauled'
                    onChange={handleChange}
                    value={values.loads_hauled}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center mt-md-0 mt-3 col-md-7 col-12'>
                <p style={{ color: palette.gray900 }}>
                  Trailer Length <span className='required'>*</span>
                </p>
                <div>
                  <input
                    type='text'
                    style={{
                      background: palette.white,
                      color: palette.dark900,
                      borderColor: palette.gray50,
                    }}
                    className='input-field'
                    name='trailer_length'
                    id='trailer_length'
                    onChange={handleChange}
                    value={values.trailer_length}
                  />
                  <ErrorMessage error={touchedErrors.trailer_length} />
                </div>
              </div>
            </div>
            <div className='accidents-section' style={{ borderColor: palette.gray50 }}>
              <div className='first-section row align-items-center' style={{ borderColor: palette.gray50 }}>
                <div className='col-lg-3 col-md-12 col-12'>
                  <p className='acident-section-head' style={{ color: palette.gray900 }}>
                    Accidents: (if none, enter zero)
                  </p>
                </div>
                <div className='d-flex align-items-center col-lg-3 col-md-4 col-12'>
                  <p className='table-data-text text-nowrap m-0' style={{ color: palette.gray700 }}>
                    # Preventable:{' '}
                  </p>
                  <div>
                    <input
                      type='text'
                      name='accidents_preventable'
                      id='accidents_preventable'
                      onChange={handleChange}
                      value={values.accidents_preventable}
                      style={{
                        background: palette.white,
                        color: palette.dark900,
                        borderColor: palette.gray50,
                      }}
                    />
                    <ErrorMessage error={touchedErrors.accidents_preventable} />
                  </div>
                </div>
                <div className='d-flex mt-md-0 mt-2 align-items-center col-lg-3 col-md-4 col-12'>
                  <p className='table-data-text text-nowrap m-0' style={{ color: palette.gray700 }}>
                    # Non-Preventable:{' '}
                  </p>
                  <div>
                    <input
                      type='text'
                      name='accidents_non_preventable'
                      id='accidents_non_preventable'
                      onChange={handleChange}
                      value={values.accidents_non_preventable}
                      style={{
                        background: palette.white,
                        color: palette.dark900,
                        borderColor: palette.gray50,
                      }}
                    />
                    <ErrorMessage error={touchedErrors.accidents_non_preventable} />
                  </div>
                </div>
                <div className='d-flex mt-md-0 mt-2 align-items-center col-lg-3 col-md-4 col-12'>
                  <p className='table-data-text text-nowrap m-0' style={{ color: palette.gray700 }}>
                    # DOT Reportable:{' '}
                  </p>
                  <div>
                    <input
                      type='text'
                      name='accidents_dot_reportable'
                      id='accidents_dot_reportable'
                      onChange={handleChange}
                      value={values.accidents_dot_reportable}
                      style={{
                        background: palette.white,
                        color: palette.dark900,
                        borderColor: palette.gray50,
                      }}
                    />
                    <ErrorMessage error={touchedErrors.accidents_dot_reportable} />
                  </div>
                </div>
                <div />
              </div>
              <div className='second-section '>
                {values?.accident?.map((item, index) => {
                  return (
                    <div key={item?.acc_date} className='row first-input-section me-md-1'>
                      <div className='col-lg-2 col-md-3 col-6'>
                        <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                          Date
                        </p>
                        <DatePicker
                          selected={values.accident[index].acc_date}
                          showTimeInput
                          placeholderText='dd/mm/yyyy'
                          value={values.accident[index].acc_date}
                          onChange={(date) => {
                            handleChange(`accident[${index}].acc_date`, date);
                          }}
                          id={`accident[${index}].acc_date`}
                          name={`accident[${index}].acc_date`}
                          style={{
                            background: palette.white,
                            color: palette.dark900,
                            borderColor: palette.gray50,
                          }}
                          className='prev-veri-date-picaker-wrap'
                        />
                        <ErrorMessage error={touchedErrors.accident?.[index]?.acc_date} />
                      </div>
                      <div className='col-lg-2 col-md-3 col-6'>
                        <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                          City,State
                        </p>
                        <input
                          type='text'
                          id={`accident[${index}].acc02_country_state_city`}
                          name={`accident[${index}].acc02_country_state_city`}
                          value={values.accident[index].acc02_country_state_city}
                          onChange={handleChange}
                          className='w-100'
                          style={{
                            background: palette.white,
                            color: palette.dark900,
                            borderColor: palette.gray50,
                          }}
                        />
                        <ErrorMessage error={touchedErrors.accident?.[index]?.acc02_country_state_city} />
                      </div>
                      <div className='col-lg-2 col-md-3 col-6'>
                        <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                          Description
                        </p>
                        <input
                          type='text'
                          id={`accident[${index}].acc_description`}
                          name={`accident[${index}].acc_description`}
                          value={values.accident[index].acc_description}
                          onChange={handleChange}
                          className='w-100'
                          style={{
                            background: palette.white,
                            color: palette.dark900,
                            borderColor: palette.gray50,
                          }}
                        />
                        <ErrorMessage error={touchedErrors.accident?.[index]?.acc_description} />
                      </div>
                      <div className='col-lg-2 col-md-3 col-6'>
                        <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                          # Fatalities
                        </p>
                        <input
                          type='text'
                          id={`accident[${index}].acc_fatalities`}
                          name={`accident[${index}].acc_fatalities`}
                          value={values.accident[index].acc_fatalities}
                          onChange={handleChange}
                          style={{
                            background: palette.white,
                            color: palette.dark900,
                            borderColor: palette.gray50,
                            width: '80px',
                          }}
                        />
                        <ErrorMessage error={touchedErrors.accident?.[index]?.acc_fatalities} />
                      </div>
                      <div className='col-lg-2 col-md-3 col-6'>
                        <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                          # Injuries
                        </p>
                        <input
                          type='text'
                          id={`accident[${index}].acc_injuries`}
                          name={`accident[${index}].acc_injuries`}
                          value={values.accident[index].acc_injuries}
                          onChange={handleChange}
                          style={{
                            background: palette.white,
                            color: palette.dark900,
                            borderColor: palette.gray50,
                            width: '80px',
                          }}
                        />
                        <ErrorMessage error={touchedErrors.accident?.[index]?.acc_injuries} />
                      </div>
                      <div className='col-lg-1 col-md-3 col-6'>
                        <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                          Hazmat?
                        </p>
                        <input
                          type='checkbox'
                          className='checkbox-style-custom'
                          id={`accident[${index}].acc_hazmat`}
                          name={`accident[${index}].acc_hazmat`}
                          checked={!!values?.accident?.[index]?.acc_hazmat}
                          onChange={(e) => handleChange(`accident[${index}].acc_hazmat`, e.target.checked)}
                        />
                      </div>
                      <div className='col-lg-1 col-md-3 col-6'>
                        <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                          Preventable?
                        </p>
                        <input
                          type='checkbox'
                          className='checkbox-style-custom'
                          id={`accident[${index}].acc_preventable`}
                          name={`accident[${index}].acc_preventable`}
                          checked={!!values?.accident?.[index]?.acc_preventable}
                          onChange={(e) => handleChange(`accident[${index}].acc_preventable`, e.target.checked)}
                        />
                        {index > 0 ? (
                          <div className='ms-5'>
                            <img src={cross} alt='' onClick={() => remove(index)} />
                          </div>
                        ) : (
                          <span />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='add-another-prev-verification'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button
                  onClick={() =>
                    handleChange('accident', [
                      ...values.accident,
                      {
                        acc_date: null,
                        acc02_country_state_city: '',
                        acc_description: '',
                        acc_fatalities: '',
                        acc_injuries: '',
                        acc_hazmat: false,
                        acc_preventable: false,
                      },
                    ])
                  }
                  className='add-employer-button'
                >
                  Add Another
                </button>
              </div>
            </div>
            <div className='drug-and-alcohol-section'>
              <div className='first-section d-flex justify-content-between col-12'>
                <p className='table-data-text heading' style={{ color: palette.gray900 }}>
                  Drug and Alcohol
                </p>
              </div>
              <div className='second-section col-12' style={{ borderColor: palette.gray50 }}>
                <p className='table-data-text m-0' style={{ color: palette.gray700 }}>
                  In the three years prior to the date of the employee’s signature (on the release), for DOT-regulated
                  testing (To be accompanied by an appropriate drug and alcohol release):
                </p>
              </div>
            </div>
            <div className='drug-and-alcohol-question-section'>
              <div className='question' style={{ borderColor: palette.gray50 }}>
                <div className='row'>
                  <div className='col-lg-10 col-md-12'>
                    <p className='m-0' style={{ color: palette.gray700 }}>
                      1. Did the employee have alcohol tests with a result of 0.04 or higher?{' '}
                      <span className='required'>*</span>
                    </p>
                  </div>
                  <div className='d-flex flex-column col-lg-2'>
                    <div className='d-flex justify-content-lg-between buttons-section'>
                      <div className='d-flex align-items-center'>
                        <input
                          type='radio'
                          name='employee_alcohol_test_result'
                          className='radio-button'
                          value='yes'
                          onChange={(event) => handleChange('employee_alcohol_test_result', event.target.value)}
                        />
                        <span style={{ color: palette.gray700 }}>Yes</span>
                      </div>
                      <div className='d-flex align-items-center no-button'>
                        <input
                          type='radio'
                          name='employee_alcohol_test_result'
                          className='radio-button'
                          value='no'
                          onChange={(event) => handleChange('employee_alcohol_test_result', event.target.value)}
                        />
                        <span style={{ color: palette.gray700 }}>No</span>
                      </div>
                    </div>
                    <ErrorMessage error={touchedErrors.employee_alcohol_test_result} />
                  </div>
                </div>
              </div>
              <div className='question' style={{ borderColor: palette.gray50 }}>
                <div className='row'>
                  <div className='col-lg-10 col-md-12'>
                    <p className='m-0' style={{ color: palette.gray700 }}>
                      2. Did the employee have verified positive drug tests? <span className='required'>*</span>
                    </p>
                  </div>
                  <div className='d-flex flex-column col-md-2'>
                    <div className='d-flex justify-content-md-between buttons-section'>
                      <div className='d-flex align-items-center'>
                        <input
                          type='radio'
                          name='employee_verified_positive_drug_test'
                          className='radio-button'
                          value='yes'
                          onChange={(event) => handleChange('employee_verified_positive_drug_test', event.target.value)}
                        />
                        <span style={{ color: palette.gray700 }}>Yes</span>
                      </div>
                      <div className='d-flex align-items-center no-button'>
                        <input
                          type='radio'
                          name='employee_verified_positive_drug_test'
                          className='radio-button'
                          value='no'
                          onChange={(event) => handleChange('employee_verified_positive_drug_test', event.target.value)}
                        />
                        <span style={{ color: palette.gray700 }}>No</span>
                      </div>
                    </div>
                    <ErrorMessage error={touchedErrors.employee_verified_positive_drug_test} />
                  </div>
                </div>
              </div>
              <div className='question' style={{ borderColor: palette.gray50 }}>
                <div className='row'>
                  <div className='col-lg-10 col-md-12'>
                    <p className='m-0' style={{ color: palette.gray700 }}>
                      3. Did the employee refuse to be tested? <span className='required'>*</span>
                    </p>
                  </div>
                  <div className='d-flex flex-column col-md-2'>
                    <div className='d-flex justify-content-md-between buttons-section'>
                      <div className='d-flex align-items-center'>
                        <input
                          type='radio'
                          name='employee_refuse_test'
                          className='radio-button'
                          value='yes'
                          onChange={(event) => handleChange('employee_refuse_test', event.target.value)}
                        />
                        <span style={{ color: palette.gray700 }}>Yes</span>
                      </div>
                      <div className='d-flex align-items-center no-button'>
                        <input
                          type='radio'
                          name='employee_refuse_test'
                          className='radio-button'
                          value='no'
                          onChange={(event) => handleChange('employee_refuse_test', event.target.value)}
                        />
                        <span style={{ color: palette.gray700 }}>No</span>
                      </div>
                    </div>
                    <ErrorMessage error={touchedErrors.employee_refuse_test} />
                  </div>
                </div>
              </div>
              <div className='question' style={{ borderColor: palette.gray50 }}>
                <div className='row'>
                  <div className='col-lg-10 col-md-12'>
                    <p className='m-0' style={{ color: palette.gray700 }}>
                      4. Did the employee have other violations of DOT agency drug and alcohol testing regulations?{' '}
                      <span className='required'>*</span>
                    </p>
                  </div>
                  <div className='d-flex flex-column col-md-2'>
                    <div className='d-flex justify-content-md-between buttons-section'>
                      <div className='d-flex align-items-center'>
                        <input
                          type='radio'
                          name='employee_drug_alcohol_regulations_violations'
                          className='radio-button'
                          value='yes'
                          onChange={(event) =>
                            handleChange('employee_drug_alcohol_regulations_violations', event.target.value)
                          }
                        />
                        <span style={{ color: palette.gray700 }}>Yes</span>
                      </div>
                      <div className='d-flex align-items-center no-button'>
                        <input
                          type='radio'
                          name='employee_drug_alcohol_regulations_violations'
                          className='radio-button'
                          value='no'
                          onChange={(event) =>
                            handleChange('employee_drug_alcohol_regulations_violations', event.target.value)
                          }
                        />
                        <span style={{ color: palette.gray700 }}>No</span>
                      </div>
                    </div>
                    <ErrorMessage error={touchedErrors.employee_drug_alcohol_regulations_violations} />
                  </div>
                </div>
              </div>
              <div className='question' style={{ borderColor: palette.gray50 }}>
                <div className='row'>
                  <div className='col-lg-10 col-md-12'>
                    <p className='m-0' style={{ color: palette.gray700 }}>
                      5. Did a previous employee have other report a drug and alcohol rule violation to you?{' '}
                      <span className='required'>*</span>
                    </p>
                  </div>
                  <div className='d-flex flex-column col-md-2'>
                    <div className='d-flex justify-content-md-between buttons-section'>
                      <div className='d-flex align-items-center'>
                        <input
                          type='radio'
                          name='previous_employee_drug_alcohol_regulations_violations'
                          className='radio-button'
                          value='yes'
                          onChange={(event) =>
                            handleChange('previous_employee_drug_alcohol_regulations_violations', event.target.value)
                          }
                        />
                        <span style={{ color: palette.gray700 }}>Yes</span>
                      </div>
                      <div className='d-flex align-items-center no-button'>
                        <input
                          type='radio'
                          name='previous_employee_drug_alcohol_regulations_violations'
                          className='radio-button'
                          value='no'
                          onChange={(event) =>
                            handleChange('previous_employee_drug_alcohol_regulations_violations', event.target.value)
                          }
                        />
                        <span style={{ color: palette.gray700 }}>No</span>
                      </div>
                    </div>
                    <ErrorMessage error={touchedErrors.previous_employee_drug_alcohol_regulations_violations} />
                  </div>
                </div>
              </div>
              <div className='question remove-border'>
                <div className='row'>
                  <div className='col-lg-10 col-md-12'>
                    <p className='m-0' style={{ color: palette.gray700 }}>
                      6. If you answered “Yes” to any of the above items, did the employee complete the return-to-duty
                      process? <span className='required'>*</span>
                    </p>
                  </div>
                  <div className='d-flex flex-column col-md-2 px-0'>
                    <div className='d-flex justify-content-md-between flex-wrap buttons-section'>
                      <div className='d-flex align-items-center'>
                        <input
                          type='radio'
                          name='employee_complete_return_to_duty_process'
                          className='radio-button'
                          value='N/A'
                          onChange={(event) =>
                            handleChange('employee_complete_return_to_duty_process', event.target.value)
                          }
                        />
                        <span style={{ color: palette.gray700 }}>N/A</span>
                      </div>
                      <div className='d-flex align-items-center yes-button'>
                        <input
                          type='radio'
                          name='employee_complete_return_to_duty_process'
                          className='radio-button'
                          value='yes'
                          onChange={(event) =>
                            handleChange('employee_complete_return_to_duty_process', event.target.value)
                          }
                        />
                        <span style={{ color: palette.gray700 }}>Yes</span>
                      </div>
                      <div className='d-flex align-items-center no-button'>
                        <input
                          type='radio'
                          name='employee_complete_return_to_duty_process'
                          className='radio-button'
                          value='no'
                          onChange={(event) =>
                            handleChange('employee_complete_return_to_duty_process', event.target.value)
                          }
                        />
                        <span style={{ color: palette.gray700 }}>No</span>
                      </div>
                    </div>
                    <ErrorMessage error={touchedErrors.employee_complete_return_to_duty_process} />
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-32'>
              <div className='col-md-4 col-12 direction'>
                <div className='d-flex justify-content-between'>
                  <p className='table-data-text m-0 mb-8 font' style={{ color: palette.gray900 }}>
                    Signature <span className='required'>*</span>
                  </p>
                  <img src={cross} alt='' className='me-1' onClick={clear} />
                </div>
                <div
                  className='signature-field'
                  style={{
                    background: palette.white,
                    borderColor: palette.gray50,
                    position: 'relative',
                  }}
                >
                  <SignatureCanvas
                    clearOnResize={false}
                    penColor='black'
                    canvasProps={{ className: 'sigCanvas' }}
                    ref={sigPad}
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='name-field'
                    name='signature_name'
                    id='signature_name'
                    placeholder='Full Name'
                    onChange={handleChange}
                    value={values.signature_name}
                    style={{
                      backgroundColor: palette.white,
                      color: palette.gray900,
                      borderColor: palette.gray50,
                    }}
                  />
                  <ErrorMessage error={touchedErrors.signature_name} />
                </div>
              </div>
              <div className='col-md-8 col-12'>
                <div className='row row-inputs'>
                  <div className='col-md-4 mt-md-0 mt-4 col-6 first-heading'>
                    <p className='table-data-text m-0 mb-8 font' style={{ color: palette.gray900 }}>
                      Title <span className='required'>*</span>
                    </p>
                    <div className='first-input'>
                      <input
                        type='text'
                        placeholder='Title'
                        style={{
                          backgroundColor: palette.white,
                          color: palette.gray900,
                          borderColor: palette.gray50,
                        }}
                        name='title'
                        id='title'
                        onChange={handleChange}
                        value={values.title}
                      />
                      <ErrorMessage error={touchedErrors.title} />
                    </div>
                  </div>
                  <div className='col-md-3 mt-md-0 mt-4 col-6 second-heading'>
                    <p className='table-data-text m-0 mb-8 font' style={{ color: palette.gray900 }}>
                      Date <span className='required'>*</span>
                    </p>
                    <div className='information-edit-selector'>
                      <img src={calendar} alt='calendar' />
                      <DatePicker
                        selected={values.date}
                        showTimeInput
                        placeholderText='mm/dd/yyyy'
                        value={values.date}
                        onChange={(date) => {
                          handleChange('date', date);
                        }}
                        maxDate={new Date()}
                      />
                      <ErrorMessage error={touchedErrors.date} />
                    </div>
                  </div>
                  <div className='col-md-5 mt-md-0 mt-4 col-12 third-heading'>
                    <p className='table-data-text m-0 mb-8 font' style={{ color: palette.gray900 }}>
                      Phone Number <span className='required'>*</span>
                    </p>
                    <div
                      className='phone-number-prev-verification'
                      style={{
                        backgroundColor: palette.white,
                        borderColor: palette.gray50,
                      }}
                    >
                      <PhoneInput
                        style={{
                          backgroundColor: palette.white,
                          borderColor: palette.gray50,
                        }}
                        addInternationalOption={false}
                        className='input-type-previous-veification'
                        value={values.phone_no}
                        countries={whitelist}
                        onChange={(val) => handleChange('phone_no', val)}
                        defaultCountry='US'
                        placeholder='(555) 555-1234'
                      />
                    </div>
                    <ErrorMessage error={touchedErrors.phone_no} />
                  </div>
                </div>
                <div className='row row-inputs mt-32'>
                  <div className='col-md-6 col-12 first-heading mr-40'>
                    <p className='table-data-text m-0 font' style={{ color: palette.gray900 }}>
                      Company DOT # <span className='required'>*</span>
                    </p>
                    <div className='first-input'>
                      <input
                        type='text'
                        placeholder='Company DOT #'
                        style={{
                          backgroundColor: palette.white,
                          color: palette.gray900,
                          borderColor: palette.gray50,
                        }}
                        name='company_dot'
                        id='company_dot'
                        onChange={handleChange}
                        value={values.company_dot}
                      />
                      <ErrorMessage error={touchedErrors.company_dot} />
                    </div>
                  </div>
                  <div className='col-md-5 mt-md-0 mt-4 col-12 second-heading mr-0'>
                    <p className='table-data-text m-0 font' style={{ color: palette.gray900 }}>
                      Email <span className='required'>*</span>
                    </p>
                    <div className='second-input set-width'>
                      <input
                        type='text'
                        placeholder='example@gmail.com'
                        style={{
                          backgroundColor: palette.white,
                          color: palette.gray900,
                          borderColor: palette.gray50,
                        }}
                        name='email'
                        id='email'
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage error={touchedErrors.email} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='comment-section mt-32'>
              <div className='d-flex justify-content-between'>
                <p className='table-data-text m-0 mb-8 font' style={{ color: palette.gray900 }}>
                  Comments
                </p>
              </div>
              <div className='d-flex justify-content-between col-12'>
                <textarea
                  className='comment-field'
                  style={{
                    background: palette.white,
                    color: palette.gray900,
                    borderColor: palette.gray50,
                  }}
                  name='comments'
                  id='comments'
                  onChange={handleChange}
                  value={values.comments}
                />
              </div>
            </div>
            <div className='footer-btn-container prev-sub-footer-wrap mt-4'>
              <div />
              {loading ? (
                <div>
                  <CircularProgress size={30} />
                </div>
              ) : (
                <Button className='next-step' type='submit' onClick={() => handleSubmit()}>
                  Submit Verification Response
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='employment-footer-wrapper-main'>
        <div className='employment-footer-wrapper'>
          <img src={poweredBy} alt='' />
          <p className='employment-footer-text text-wrap'>Electronic Previous Employment Verification</p>
        </div>
      </div>
      {forwardEmployment && (
        <ForwardPreviousEmployment
          show={forwardEmployment}
          onHide={() => setForwardEmployment(false)}
          token={token}
          forwardLoading={forwardLoading}
          Forward={Forward}
          dot={dot}
        />
      )}
      {viewModalShow.isOpen && (
        <MedicalAndBackgroundModal
          show={viewModalShow.isOpen}
          data={viewModalShow.data}
          onHide={() => setViewModalShow(false)}
        />
      )}
    </div>
  );
};

export default PreviousEmolymentVerification;
