import axios from 'axios';
import authHeader from '../services/auth-header';
import axiosConfigured from '../services/axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function RequestData({
  page,
  name,
  start_date,
  end_date,
  sortField = 'sort[][id]',
  sortBy = 'desc',
  itemsPerPage,
  status,
  type,
}) {
  const url = `${API_URL}/request`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      name,
      itemsPerPage,
      [sortField]: sortBy,
      start_date,
      end_date,
      status,
      type,
    },
  });
  return data;
}

export async function getRequestData(params) {
  const { data } = await axiosConfigured.get('request', { params });
  return data;
}

export async function getDriverStaff(name) {
  const url = `${API_URL}/request/driverStaffUser`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      name,
    },
  });
  return data;
}

export async function AddRequestDetail(request) {
  const url = `${API_URL}/request`;
  const { data } = await axios.post(
    url,
    {
      ...request,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function updateRequestDetail(updateRequestData, requestId) {
  const url = `${API_URL}/request/${requestId}`;
  const { data } = await axios.put(
    url,
    {
      ...updateRequestData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function removeRequestData(user_type, requestId) {
  const url = `${API_URL}/request/${user_type}/${requestId}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverCreateAbsenceData(absenceData) {
  const url = `${API_URL}/absence`;
  const { data } = await axios.post(
    url,
    {
      ...absenceData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function getMaintenanceData(id) {
  const { data } = await axiosConfigured.get(`work-order/${id}`);
  return data;
}
