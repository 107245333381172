import React, { useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { getExportCSV } from 'Api/EquipmentImport';
import useShowToaster from 'hooks/useShowToaster';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import { SBackdrop } from 'pages/Payroll/PayrollAndSettlements/PayrollAndSettlements.styles';

const ExportCsvButton = ({ type }) => {
  const showToaster = useShowToaster();
  // const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { value: userData } = useAuth();

  // TODO: Can be used when specific data is needed
  // const getDataIds = async () => {
  //   try {
  //     switch (type) {
  //       case 'driver':
  //         const drivers = await Drivers({ page: 1, itemsPerPage: 10000 });
  //         const driverIds = drivers.data.map((item) => item.id);
  //         setIds(driverIds);
  //         break;
  //       case 'staff':
  //         const staff = await Staff({ page: 1, itemsPerPage: 10000 });
  //         const staffIds = staff.data.map((item) => item.id);
  //         setIds(staffIds);
  //         break;
  //       case 'vehicles':
  //         const vehicles = await getVehicleData();
  //         const vehicleIds = vehicles.data.map((item) => item.id);
  //         setIds(vehicleIds);
  //         break;
  //       case 'trailers':
  //         const trailers = await Trailer({ page: 1, itemsPerPage: 10000 });
  //         const trailerIds = trailers.data.map((item) => item.id);
  //         setIds(trailerIds);
  //         break;
  //       default:
  //     }
  //   } catch (e) {
  //     // Do nothing
  //   }
  // };

  const handleClick = async () => {
    setLoading(true);
    try {
      const source = axios.CancelToken.source();

      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          source.cancel('request timed out');
          reject(new Error('request timed out'));
        }, 5000);
      });

      const params = { type };
      const data = await Promise.race([getExportCSV(params, source.token), timeoutPromise]);
      const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (error?.message === 'request timed out') {
        try {
          await getExportCSV({ type, email: userData.user.email });
          showToaster({
            type: 'success',
            message: `Processing is taking longer then expected, we will email you the export to ${userData.user.email} when it is complete!`,
          });
        } catch (secondError) {
          showToaster({ type: 'error', message: getErrorMessage(secondError) || 'Something went wrong!' });
        }
      } else {
        showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip title='Export CSV'>
        <CustomButton
          type='secondary'
          styleButton={{ padding: '9px 12px', margin: 0 }}
          styleTitle={(type === 'vehicles' || type === 'trailers') && { color: 'black' }}
          leftIcon={<ExportIcon />}
          onClick={handleClick}
          disabled={loading}
        />
      </Tooltip>
      <SBackdrop open={loading}>
        <CircularProgress size={30} style={{ marginTop: '30px' }} />
        <Typography variant='h1' style={{ color: palette.white }}>
          Processing, please wait
        </Typography>
      </SBackdrop>
    </>
  );
};

export default ExportCsvButton;
