import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { palette } from 'utils/constants';

export const SDrawer = styled(Drawer)`
  position: relative;
  width: ${({ open }) => (open ? '250px' : 0)};
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  .MuiDrawer-paper {
    width: 250px;
    position: absolute;
    z-index: 1;
    margin-right: 8px;
    padding: 8px;
  }
`;

export const SSidebarOptions = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    margin-bottom: 18px;

    .channel-category-title {
      cursor: pointer;
      padding: 8px;
      border-radius: 6px;
      transition: 0.2s;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;

      :hover {
        background-color: ${palette.gray0};
      }

      &.active {
        background-color: ${palette.indigo50};
      }
    }
  }

  .str-chat-channel-list {
    float: unset;
    box-shadow: none;

    .str-chat__channel-list-messenger {
      min-width: unset;

      .str-chat__channel-list-messenger__main {
        padding: 0 0 0 16px;
        height: auto;
      }
    }
  }

  .collapse-arrow {
    cursor: pointer;
    transition: all 0.2s;

    &.collapsed {
      transform: rotate(180deg);
    }
  }

  .channel-list-collapse {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;

    &.open {
      max-height: 800px;
    }
  }
`;
