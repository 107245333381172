import React, { useCallback, useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { Button } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import classes from './AdvancedFilter.module.scss';
import Autocomplete from '../../../common/Autocomplete';

import { carrierStatuses, getStats } from '../../../Api/Carriers';
import { APPROVED_FOR, CAN_TRAVEL_TO } from '../constat';
import { getEquipmentRequiredTrailers, getEquipmentRequiredVehicles } from '../../../Api/Planner';

const AdvancedFilter = ({ open, filter, setFilter, setSearch, onSearch, children }) => {
  const [statuses, setStatuses] = useState([]);
  const [states, setStates] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [badgeData, setBadgeData] = useState([]);

  /** GET DATA* */
  const GetStatuses = async () => {
    try {
      const { data } = await carrierStatuses();
      setStatuses(data);
    } catch (e) {
      // Do nothing
    }
  };

  const GetAllStates = async () => {
    try {
      const { data } = await getStats({ 'country_id[]': ['38', '231', '142'] });
      setStates(data.filter((item) => item.short_name));
    } catch (e) {
      // Do nothing
    }
  };

  const getEquipments = useCallback(async () => {
    return Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicles, trailers]) => {
        const data = [];

        if (vehicles?.data) {
          const addFlagVehicles = vehicles?.data.map((el) => {
            if ([1, 2, 3, 4, 5, 10].includes(+el.id)) {
              return {
                id: el.id,
                label: el.title,
                flag: 'vehicles',
                labelSelected: null,
                noLengthField: true,
                type: el?.vehicle_type_id,
              };
            }
            return {
              id: el.id,
              label: el.title,
              flag: 'vehicles',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagVehicles);
        }

        if (trailers?.data) {
          const addFlagTrailers = trailers?.data.map((el) => {
            return {
              id: el.id,
              label: el.title,
              flag: 'trailers',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagTrailers);
        }
        data.sort((a, b) => a.id - b.id);

        setEquipments(data);
        return data;
      })
      .catch(() => {
        // Do nothing
      });
  }, []);
  /** GET DATA* */

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    return {
      name: item?.[nameKey] || '',
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(filter.status
        ? [
            {
              title: 'Status',
              children: filter.status.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'status' })),
            },
          ]
        : []),

      ...(filter.can_travel_to
        ? [
            {
              title: 'Can Travel To',
              children: filter.can_travel_to.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'can_travel_to' })
              ),
            },
          ]
        : []),
      ...(!!filter.state_from || !!filter.state_to
        ? [
            {
              title: 'Operations',
              children: [
                ...(filter.state_from
                  ? [{ name: filter.state_from?.short_name, id: filter.state_from.id, key: 'state_from' }]
                  : []),
                ...(filter.state_to
                  ? [
                      {
                        name: `${filter.state_from?.short_name ? 'to ' : ''} ${filter.state_to?.short_name}`,
                        id: filter.state_to.id,
                        key: 'state_to',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(filter.equipment_types
        ? [
            {
              title: 'Equipment Types',
              children: filter.equipment_types.map((item) =>
                normalizeBadgeData(item, 'label', 'id', { key: 'equipment_types' })
              ),
            },
          ]
        : []),
      ...(filter.approved_for
        ? [
            {
              title: 'Approved For',
              children: filter.approved_for.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'approved_for' })
              ),
            },
          ]
        : []),
    ];
  }, []);

  const onSearchFilter = () => {
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      const newItemValue = itemValue.filter((el) => el.id !== id);

      if (newItemValue.length === 0) {
        delete newFilter[key];
      } else {
        newFilter[key] = newItemValue;
      }
    } else if (typeof itemValue === 'string' || typeof itemValue === 'object') {
      delete newFilter[key];
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));
  };

  const handleClearAll = () => {
    onSearch({});
    setSearch('');
    setFilter({});
    setBadgeData([]);
  };

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
    } else {
      newFilter[key] = value;
    }
    return setFilter(newFilter);
  };

  const onChangeSingleSelectValues = (key, value) => {
    const newFilter = { ...filter };

    if (value === null) {
      delete newFilter[key];
    } else {
      newFilter[key] = value;
    }

    setFilter(newFilter);
  };

  useEffect(() => {
    GetStatuses().catch();
    GetAllStates().catch();
    getEquipments().catch();
  }, []);

  return (
    <>
      {children?.({ onSearchFilter })}
      <Collapse in={open}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={statuses}
                placeholder='Status'
                value={filter?.status || []}
                onChange={(e, value) => onChangeMultiSelectValues('status', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={CAN_TRAVEL_TO}
                placeholder='Can Travel To'
                value={filter.can_travel_to || []}
                onChange={(e, value) => onChangeMultiSelectValues('can_travel_to', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
              />
            </div>

            <div className='d-flex align-items-center'>
              <span style={{ marginRight: 5 }}>Operations</span>
              <Autocomplete
                width='100%'
                options={states}
                labelKey='short_name'
                disableClearable={false}
                value={filter?.state_from || null}
                style={{ width: 120 }}
                onChange={(e, value) => onChangeSingleSelectValues('state_from', value)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
              />
              <span style={{ margin: '0 8px' }}>to</span>
              <Autocomplete
                width='100%'
                options={states}
                labelKey='short_name'
                disableClearable={false}
                value={filter?.state_to || null}
                style={{ width: 120, marginRight: 20 }}
                onChange={(e, value) => onChangeSingleSelectValues('state_to', value)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={equipments}
                placeholder='Select Equipment Type'
                value={filter?.equipment_types || []}
                onChange={(e, value) => onChangeMultiSelectValues('equipment_types', value)}
                getTagLabel={(option) => (option ? option?.label : '')}
                getOptionLabel={(option) => (option ? option?.label : '')}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={APPROVED_FOR}
                placeholder='Select Approved For'
                value={filter?.approved_for || []}
                onChange={(e, value) => onChangeMultiSelectValues('approved_for', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
              />
            </div>
          </div>
          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>

      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index && child.key !== 'state_from' ? (
                          <span className={classes.comma}>,</span>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AdvancedFilter;
