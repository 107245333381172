import React, { useRef } from 'react';
import styles from './TableSettingPopover.module.css';

const ColumnOrder = ({ localDragItem, onChangeOrder, updateFilter, filter }) => {
  const sortBy = () => {
    // return a.order > b.order ? 1 : -1
  };

  document.addEventListener('dragover', (event) => {
    event.preventDefault();
  });

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (e) => {
    e.preventDefault();
    const copyListItems = [...localDragItem];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    onChangeOrder(copyListItems);
    updateFilter({ ...filter, ColumnOrder: copyListItems });
  };

  return (
    <div className={styles.columnOrder_wrapper}>
      <span>Column Order</span>
      <div className={styles.items_container}>
        {localDragItem?.sort(sortBy).map((item, index) => (
          <div
            className={styles.item_drabButton}
            draggable
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={item}
          >
            {item === 'next_pm'
              ? 'Next PM'
              : item === 'vin'
              ? 'VIN'
              : item === 'equipment_id'
              ? 'Equipment ID'
              : item === 'group'
              ? 'Tags'
              : item.replaceAll('_', ' ')}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnOrder;
