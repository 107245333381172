import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { STable } from 'pages/Accounting/Payables/Payables.styles';

const ViewVehicles = ({ open, onClose, vehicles }) => {
  const { formatDateTime } = useDateFormat();

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Vehicles'
      $maxWidth='520px'
      $minWidth='520px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
        },
      ]}
    >
      <div className=' mt-2 mb-3'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>EQUIPMENT ID</th>
              <th>DRIVER</th>
              <th>NEXT AVAILABLE DATE</th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((item) => (
              <tr key={item.id} className='body-row'>
                <td>
                  <Typography variant='b2'>{item.equipment_id}</Typography>
                </td>
                <td>
                  <Typography variant='b2'>
                    {item.driver?.first_name} {item.driver?.last_name}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2'>
                    {item.next_available_date
                      ? formatDateTime(item.next_available_date.replace('Z', '').split('T').join(' '))
                      : ''}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
      </div>
    </Modal>
  );
};

export default ViewVehicles;
