import styled from 'styled-components';
import { palette } from 'utils/constants';
import hexRgb from 'hex-rgb';

export const SWrapper = styled.div`
  .navigate-back-button {
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
`;

export const SInfoItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-right: 1px solid ${palette.gray100};

  :first-child {
    border-left: 1px solid ${palette.gray100};
  }

  :last-child {
    border-right: none;
  }
`;

export const SGroup = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ $bgColor }) => $bgColor || palette.indigo500};
  color: ${({ $bgColor }) =>
    hexRgb($bgColor)?.red < 70 || hexRgb($bgColor)?.green || hexRgb($bgColor)?.blue < 70
      ? palette.white
      : palette.gray900};
  border-radius: 12px;
  padding: 2px 6px;
`;
