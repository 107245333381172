import React, { useState } from 'react';
import Modal from 'common/Modal';
import ViewAttachment from 'components/ViewAttachment';
import classes from 'components/Support/SupportItemModal/SupportItemModal.module.css';

const ViewPhotos = ({ open, onClose, images }) => {
  const [openAttachment, setOpenAttachment] = useState(false);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='View Image(s)'
      $minHeight='500px'
      $maxHeight='90vh'
      $minWidth='800px'
      $maxWidth='800px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      <div className={classes.attachmentFiles}>
        {images?.map((item) => {
          return (
            <div className={classes.fileWrapper}>
              <div className={classes.file}>
                <figure>
                  <img id='img' src={item?.path} alt='upload' onClick={() => setOpenAttachment(item?.path)} />
                </figure>
              </div>
            </div>
          );
        })}
      </div>
      {!!openAttachment && (
        <ViewAttachment
          open={openAttachment}
          onClose={() => setOpenAttachment(null)}
          attachment={openAttachment}
          title='Inventory Image'
        />
      )}
    </Modal>
  );
};

export default ViewPhotos;
