import React, { useMemo, useState } from 'react';
import { palette } from 'utils/constants';
import InputLabel from 'common/InputLabel';
import DatePicker from 'common/DatePicker';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import Input, { InputWithText } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import { blockNotNumberChars, weekdaysBetween } from 'utils/helpers';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { updateRecurringLane } from 'Api/Shipment';
import Recurs from 'components/CreateShipment/Schedule/Recurs';
import { getInitialValues, updateRequestBodyConverter } from './Schedule.data';
import { getValidationSchema } from './validationSchema';
import { SSchedule } from './Schedule.styles';

const Schedule = ({ data, onSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    if (values.recurs?.every((i) => !i.checked)) {
      showToaster({ type: 'error', message: 'At least one day should be selected' });
      return;
    }

    setLoading(true);
    try {
      const formData = updateRequestBodyConverter(values, data);
      await updateRecurringLane(data.shipment_lane.id, formData);
      showToaster({ type: 'success', message: 'Recurring lane has been successfully updated!' });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(data.shipment_lane),
    onSubmit,
    validationSchema: getValidationSchema(data.shipment_stops),
  });
  const { values, handleSubmit, handleChange, touchedErrors } = form;

  const totalCount = useMemo(() => {
    return values.recurs.reduce((acc, cur, index) => {
      if (cur.checked) {
        const daysCount = weekdaysBetween(values.start_date, values.end_date, index + 1);
        const occurrence = (cur.amount || 0) * (daysCount || 0);
        acc += occurrence;
      }
      return acc;
    }, 0);
  }, [values]);

  return (
    <SSchedule>
      <div className='schedule-header'>
        <Typography variant='h4'>Schedule</Typography>
        <CustomButton
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '3px 12px', margin: 0 }}
          type='primary'
          title='Save'
          onClick={handleSubmit}
          disabled={loading}
        />
      </div>
      <div className='d-flex flex-column gap-4'>
        <Input
          width='300px'
          required
          label='Lane Name'
          name='name'
          onChange={handleChange}
          value={values.name}
          error={touchedErrors.name}
        />
        <div className='schedule-date-pickers d-flex gap-3'>
          <div className='w-100'>
            <InputLabel required>Start Date</InputLabel>
            <DatePicker
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={10}
              name='start_date'
              value={values.start_date}
              dateFormat='MM/dd/yyyy'
              renderStart={false}
              onChange={(val) => handleChange('start_date', val)}
            />
            <ErrorMessage error={touchedErrors.start_date} />
          </div>
          <div className='w-100'>
            <InputLabel required>End Date</InputLabel>
            <DatePicker
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={10}
              name='end_date'
              value={values.end_date}
              dateFormat='MM/dd/yyyy'
              renderStart={false}
              onChange={(val) => handleChange('end_date', val)}
            />
            <ErrorMessage error={touchedErrors.end_date} />
          </div>
        </div>
        <div>
          <InputWithText
            width='70px'
            label='Lead Time: Creation of shipment prior to days selected below'
            text='Days'
            type='number'
            onKeyDown={blockNotNumberChars}
            name='lead_time_days'
            placeholder='0'
            value={values.lead_time_days}
            onChange={(e) => handleChange('lead_time_days', e.target.value)}
            error={touchedErrors.lead_time_days}
          />
        </div>
        <Recurs form={form} stops={data.shipment_stops} />
        <div>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            Create {totalCount} Shipments Only
          </Typography>
        </div>
      </div>
    </SSchedule>
  );
};

export default Schedule;
