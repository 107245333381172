import React, { useState } from 'react';
import moment from 'moment';
import Rating from '@mui/material/Rating';
import Modal from 'common/Modal';
import { Textarea } from 'common/Input';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createReview } from 'Api/User';
import { SBodyWrapper } from './RateUsModal.styles';

const RateUsModal = ({ open, onClose, onSuccess, reviewSettings }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const { display_text, terms_and_condition, review_type, url } = reviewSettings || {};

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      await createReview({
        ...values,
        is_reviewed: 1,
        next_review_date: moment().add(1, 'week').format('MM/DD/YYYY'),
      });
      showToaster({ type: 'success', message: 'Review has been successfully submitted' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: { rating: 0, comment: '' },
    onSubmit,
  });

  const onThirdPartyClick = async (url) => {
    try {
      if (url) {
        window.open(url, '_blank');
      }
      await createReview({
        rating: 5,
        comment: 'Third party',
        is_reviewed: 1,
        next_review_date: moment().add(1, 'week').format('MM/DD/YYYY'),
      });
      onSuccess();
      onClose();
    } catch (e) {
      // Do nothing
    }
  };

  const handleNotNow = async () => {
    try {
      await createReview({
        rating: 0,
        comment: '',
        is_reviewed: 0,
        next_review_date: moment().add(1, 'week').format('MM/DD/YYYY'),
      });
      onSuccess();
      onClose();
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Rate Us'
      $bgColor={palette.gray0}
      $maxWidth='440px'
      $minWidth='440px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Not Now',
          onClick: handleNotNow,
        },
        {
          key: 'submit',
          type: 'primary',
          title: review_type === 'internal_only' ? 'Submit' : 'Write Review',
          disabled: loading,
          onClick: review_type === 'internal_only' ? handleSubmit : () => onThirdPartyClick(url),
        },
      ]}
    >
      <SBodyWrapper>
        <div>
          <Typography variant='s1' style={{ color: palette.gray900 }}>
            {display_text}
          </Typography>
        </div>
        <div>
          <Typography variant='b2' style={{ color: palette.gray500 }}>
            Product reviews help the rest of us make great decisions about Truckin Digital!
          </Typography>
        </div>
        {review_type === 'internal_only' ? (
          <>
            <div className='mb-4'>
              <Rating
                size='large'
                name='rating'
                value={values.rating}
                onChange={(event, newValue) => handleChange('rating', newValue)}
              />
            </div>
            <Textarea
              rows={5}
              label='Tell us about your experience'
              name='comment'
              value={values.comment}
              onChange={handleChange}
            />
          </>
        ) : (
          <div className='w-75 mt-3 mb-3'>
            <a
              href='https://www.capterra.com/reviews/353713/Truckin-Digital?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'
              target='_blank'
              rel='noreferrer'
              onClick={() => onThirdPartyClick()}
            >
              {' '}
              <img
                width={300}
                height={98}
                border='0'
                alt='capterra'
                src='https://assets.capterra.com/badge/acfebf770c28d4b5580021c8ba7f1e4c.svg?v=2301291&p=353713'
              />{' '}
            </a>
          </div>
        )}
        <div className='mt-2'>
          <Typography variant='c1' style={{ color: palette.gray500 }}>
            {terms_and_condition}
          </Typography>
        </div>
      </SBodyWrapper>
    </Modal>
  );
};

export default RateUsModal;
