import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onCancelAccount, onUpgradeDowngrade, onManageLicenses }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountStatusData = JSON.parse(localStorage.getItem('accountStatus'));

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'manageLicenses':
        onManageLicenses();
        break;
      case 'upgradeDowngrade':
        onUpgradeDowngrade();
        break;
      case 'cancelAccount':
        onCancelAccount();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li onClick={(event) => onActionClick(event, 'manageLicenses')}>Manage Licenses</li>
      <li onClick={(event) => onActionClick(event, 'upgradeDowngrade')}>Upgrade / Downgrade</li>
      {accountStatusData?.account_status !== 'canceled' && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'cancelAccount')}>
          Cancel Account
        </li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
