import React from 'react';
import { Icons } from 'assets/icons';
import call from 'assets/icons/drivers/call.svg';
import inbox from 'assets/icons/drivers/inbox.svg';
import right from 'assets/icons/drivers/right.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { formatPhoneNumber } from 'utils/helpers';
import sm from './ContactBookTab.module.css';

export const useColumns = () => {
  return [
    {
      title: <ColumnHeader title='NAME' field='contact_name' />,
      field: 'contact_name',
      render: (row) => <Typography variant='b2'>{row.contact_name}</Typography>,
    },
    {
      title: <ColumnHeader title='EMAIL' field='contact_email' />,
      field: 'contact_email',
      render: (row) => (
        <div className='d-flex'>
          {row.contact_email ? (
            <div className={sm['call-to-action-wrapper']}>
              <div className={sm['call-to-action-icons-contactBook']}>
                <a href={`mailto:${row.contact_email}`}>
                  <img src={inbox} alt='inbox-icon' />
                </a>
              </div>
              <Typography variant='b2'>{row.contact_email}</Typography>
            </div>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: <ColumnHeader title='PHONE NUMBER' field='phone_number' />,
      field: 'phone_number',
      render: (row) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Typography variant='b2' className='list-driver-phone'>
            <img src={call} alt='call-icon' />
            <a href={`tel:${row.phone_number}`}>{row.phone_number ? formatPhoneNumber(row.phone_number) : '-'}</a>
          </Typography>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='EXT' field='extension' />,
      field: 'extension',
      render: (row) => <Typography variant='b2'>{row.extension || ''}</Typography>,
    },
    {
      title: <ColumnHeader title='TYPE' field='contact_type' />,
      field: 'contact_type',
      render: (row) => <Typography variant='b2'>{row.contact_type?.title}</Typography>,
    },
    {
      title: (
        <div className='d-flex justify-content-center'>
          <ColumnHeader title='INVOICING' field='invoicing' />
        </div>
      ),
      field: 'invoicing',
      render: (row) => {
        const invoicing = row.invoicing;
        return (
          <div className='d-flex justify-content-center'>
            {invoicing ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
          </div>
        );
      },
    },
    {
      title: (
        <div className='d-flex justify-content-center'>
          <ColumnHeader title='PORTAL ACCESS' field='portal_access' />
        </div>
      ),
      field: 'portal_access',
      render: (row) => {
        const portal_access = row.portal_access;
        return (
          <div className='d-flex justify-content-center'>
            {portal_access ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
          </div>
        );
      },
    },
    {
      title: (
        <div className='d-flex justify-content-center'>
          <ColumnHeader title='EMAIL UPDATES' field='email_updates' />
        </div>
      ),
      field: 'email_updates',
      render: (row) => {
        const email_updates = row.email_updates;
        return (
          <div className='d-flex justify-content-center'>
            {email_updates ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
          </div>
        );
      },
    },
    {
      title: (
        <div className='d-flex justify-content-center'>
          <ColumnHeader title='SMS UPDATES' field='sms_updates' />
        </div>
      ),
      field: 'sms_updates',
      render: (row) => {
        const sms_updates = row.sms_updates;
        return (
          <div className='d-flex justify-content-center'>
            {sms_updates ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
          </div>
        );
      },
    },
  ];
};
