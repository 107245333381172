import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, FastField, useField } from 'formik';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getCustomerPaymentTerm } from 'Api/Planner';
import { getCustomerPayTerms } from 'Api/Customers';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { Typography } from 'components/Typography';
import CustomizedSnackbars from 'components/toast/Toast';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { ShipmentContext } from '../../Layout';
import CustomSelect from '../../ShipmentStops/helpers/CustomSelect';
import CustomInput from '../../helpers/CustomInput';
import sm from '../../BillingCharges/BillingCharges.module.css';

const BillingChargesLtlOneChargeHeader = ({ charge, indexPrefix, namePrefix, arrayHelpersPrefix, valuesForm }) => {
  const { use } = useTheme();

  const [disabled, setDisabled] = useState(false);
  const [, , helpers] = useField(`${namePrefix}.contact_user_id`);
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState([]);
  const PLACE = `${namePrefix}`;
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'error',
  });

  const {
    createShipmentData,
    updateShipmentData,
    selectedCharges,
    selectedGrandTotal,
    contactData,
    getContacts,
    billToData,
    selectedBillTo,
    updateBillToSelected,
    selectedContact,
    updateContactSelected,
    paymentTermData,
    updatePaymentTermData,
    assignToGroupAllData,
    onChangeContactEditModal,
    setActiveBillChargeIndex,
    updateSelectedGrandTotal,
    updateSelectedCharges,
  } = useContext(ShipmentContext);
  const contactOption = contactData?.[indexPrefix];
  const helpersGroup = useField(`${PLACE}.group`)[2];
  const helpersContact = useField(`${PLACE}.contact_user_id`)[2];
  const helpersBillTo = useField(`${PLACE}.customer_id`)[2];
  const helpersPayTerm = useField(`${PLACE}.payment_term_id`)[2];

  const activeItemGroups = useMemo(() => {
    if (charge?.group) {
      return assignToGroupAllData.find((el) => +el.id === +charge.group);
    }
  }, [assignToGroupAllData, charge]);

  const validateRequired = (value) => {
    let error;
    if (value !== undefined && value === '') {
      error = 'Required';
    }
    return error;
  };

  function onChangeBillTo(selectedItem) {
    const customer_id = selectedItem?.id;
    const cond = [2, 3, 4].includes(Number(selectedItem?.status?.id));
    if (!cond) {
      helpersBillTo.setValue(customer_id);
      getCustomerPaymentTerm({ customer_id }).then((res) => {
        const cloneTermData = { ...paymentTermData };
        cloneTermData[indexPrefix] = { ...res?.data };
        updatePaymentTermData(cloneTermData);
      });

      if (selectedItem?.customer_payment_term?.pay_term_types?.id) {
        helpersPayTerm.setValue(selectedItem.customer_payment_term.pay_term_types.id);
      }

      setShowMessage((prev) => {
        return {
          ...prev,
          message: '',
          visible: false,
        };
      });
    } else {
      setShowMessage((prev) => {
        return {
          ...prev,
          message: `Cannot proceed with ${selectedItem?.company_name} due to ${selectedItem?.status?.title}.`,
          visible: true,
        };
      });
    }
    return cond;
  }

  const onClickDeleteBilling = () => {
    const charges = {};
    const grandTotal = {};
    arrayHelpersPrefix.remove(indexPrefix);
    const newSelectedGrandTotal = { ...selectedGrandTotal };
    const newSelectedCharges = { ...selectedCharges };
    delete newSelectedGrandTotal[indexPrefix];
    delete newSelectedCharges[indexPrefix];

    Object.keys(newSelectedCharges).forEach((key) => {
      const value = newSelectedCharges[key];
      indexPrefix - 1 === +key ? (charges[key] = value) : (charges[key - 1] = value);
    });
    Object.keys(newSelectedGrandTotal).forEach((key) => {
      const value = newSelectedCharges[key];
      indexPrefix - 1 === +key ? (charges[key] = value) : (charges[key - 1] = value);
    });
    const newCreateShipmentData = { ...createShipmentData };
    newCreateShipmentData?.shipmentsLegs && newCreateShipmentData.shipmentsLegs.splice(indexPrefix, 1);
    updateShipmentData(newCreateShipmentData);

    updateSelectedGrandTotal({ ...grandTotal });
    updateSelectedCharges({ ...charges });
  };

  const billingStyles = useMemo(() => {
    return {
      headerLeft: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
      global: {
        width: 'calc(20% - 40px)',
      },
      text: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
      },
      group: {
        maxWidth: '100%',
        wordBreak: 'break-word',
        margin: 10,
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
    };
  }, [use, palette]);

  const updateBillTo = (billTo) => {
    const selected = { ...selectedBillTo };
    selected[indexPrefix] = billTo;
    updateBillToSelected(billTo, indexPrefix);
  };

  const shouldUpdates = (nextProps, props, name) => {
    switch (name) {
      case 'contact_user_id':
        return true;
      default:
    }
  };

  useEffect(() => {
    if (charge.customer_id) {
      setDisabled(false);
      const billTo = billToData.find((item) => +item.id === +charge.customer_id);
      updateBillTo(billTo);
      getCustomerPayTerms().then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: getPaymentTermTitle(el),
              labelSelected: null,
            };
          });
          setInvoicePayTermsOptions(selectData);
        }
      });
      if (billTo?.id) {
        getContacts(billTo.id, indexPrefix).then((data) => {
          const founded = data?.find((item) => +item.id === +charge.contact_user_id);
          const primary = data?.find((item) => +item.contact_type_id === 1);
          !founded && helpers.setValue('');
          if (primary) {
            helpersContact.setValue(primary.id);
          }
        });

        if (billTo?.customer_payment_term?.pay_term_types?.id) {
          helpersPayTerm.setValue(billTo.customer_payment_term.pay_term_types.id);
        }
      }
    } else {
      setDisabled(true);
    }
  }, [charge.customer_id, billToData, indexPrefix]);

  useEffect(() => {
    const contact = contactOption?.find((item) => +item.id === +charge.contact_user_id);
    if (typeof contact === 'object' && Object.keys(contact)?.length > 0) {
      updateContactSelected(contact, indexPrefix);
    } else {
      updateContactSelected(undefined, indexPrefix);
    }
  }, [charge?.contact_user_id, contactOption, indexPrefix]);

  return (
    <div>
      {showMessage?.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <div className={sm.billing_charges_header}>
        <div className={sm.header_titles_wrapper}>
          <div className={sm.titles}>
            <div className={sm.header_title_left}>
              <Typography variant='overLine' style={billingStyles.global}>
                BILL TO
              </Typography>
              <Typography variant='overLine' style={billingStyles.global}>
                REFERENCE ID
              </Typography>
              <Typography variant='overLine' style={billingStyles.global}>
                ASSIGN TAG
              </Typography>
              <div className={[sm.edit_wrapper].join(' ')} style={billingStyles.global}>
                <Typography variant='overLine'>CONTACT</Typography>
                <div
                  onClick={() => {
                    setActiveBillChargeIndex(indexPrefix);
                    Object.keys(selectedBillTo?.[indexPrefix]).length > 0 && onChangeContactEditModal(true);
                  }}
                >
                  <EditIcon style={{ cursor: 'pointer' }} fill={use(palette.gray500, palette.gray200)} />
                </div>
              </div>
              <div className={sm.paymentTerm}>
                <Typography variant='overLine'>PAYMENT TERM</Typography>
                {valuesForm?.shipmentsBilling.length === 1 ? (
                  <div />
                ) : (
                  <div onClick={onClickDeleteBilling} className={sm.header_delete_icon}>
                    <DeleteIcon fill={palette.indigo500} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={sm.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
        </div>
        <div className={sm.header_wrapper}>
          <div style={billingStyles.headerLeft}>
            <div style={billingStyles.global}>
              <FastField
                isSearchable
                name={`${PLACE}.customer_id`}
                options={billToData}
                component={CustomSelect}
                onChange={onChangeBillTo}
                allControlOutSideForm
                validate={validateRequired}
                styles={{ height: 32 }}
                shouldUpdate={(n, p) => n.options !== p.options}
              />
              <ErrorMessage
                name={`${PLACE}.customer_id`}
                render={(error) => {
                  return (
                    <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                      {error}
                    </Typography>
                  );
                }}
              />
              {!!charge?.customer_id && (
                <div className={sm.header_titles_wrapper}>
                  <Typography variant='s2' style={billingStyles.text}>
                    {selectedBillTo[indexPrefix]?.address1},&nbsp;
                    {selectedBillTo[indexPrefix]?.address2}
                  </Typography>
                  <Typography variant='s2' style={billingStyles.text}>
                    {selectedBillTo[indexPrefix]?.city?.name},&nbsp;
                    {selectedBillTo[indexPrefix]?.state?.name}&nbsp;
                    {selectedBillTo[indexPrefix]?.zipcode}&nbsp;
                  </Typography>
                  <Typography variant='s2' style={billingStyles.text}>
                    {selectedBillTo[indexPrefix]?.country?.name}
                  </Typography>
                </div>
              )}
            </div>
            <div style={billingStyles.global}>
              <FastField
                name={`${PLACE}.reference_id`}
                component={CustomInput}
                labelStyle={{ margin: 0 }}
                style={{ width: '100%', borderRadius: 6, height: 32, padding: '0 6px' }}
              />
              <ErrorMessage
                name={`${PLACE}.reference_id`}
                render={(error) => (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                )}
              />
            </div>
            <div style={billingStyles.global}>
              <FastField
                deleted
                isSearchable
                name={`${PLACE}.group`}
                styles={{ height: 32 }}
                component={CustomSelect}
                dropDownStyles={{ flexGrow: 0 }}
                options={assignToGroupAllData}
                onDelete={() => helpersGroup.setValue('')}
                shouldUpdate={(n, p) => n.options !== p.options}
              />

              {activeItemGroups?.short_name && (
                <div className={sm.group_wrapper} style={{ backgroundColor: activeItemGroups?.color }}>
                  <Typography variant='c2' style={billingStyles.group}>
                    {activeItemGroups.short_name}
                  </Typography>
                </div>
              )}
            </div>
            <div style={billingStyles.global}>
              <FastField
                deleted
                isSearchable
                name={`${PLACE}.contact_user_id`}
                disabled={disabled}
                options={contactOption}
                styles={{ height: 32 }}
                component={CustomSelect}
                onDelete={() => helpersContact.setValue('')}
                shouldUpdate={(n, p) => shouldUpdates(n, p, 'contact_user_id')}
              />

              {!!charge?.contact_user_id && selectedContact?.[indexPrefix] && (
                <div className={sm.header_titles_wrapper}>
                  {!!selectedContact[indexPrefix]?.phone_number && (
                    <Typography variant='s2' style={billingStyles.text}>
                      <ContactIcon style={{ marginRight: 8 }} />
                      {selectedContact[indexPrefix]?.phone_number}
                    </Typography>
                  )}
                  {!!selectedContact[indexPrefix]?.contact_email && (
                    <Typography variant='s2' style={billingStyles.text}>
                      <MailIcon style={{ marginRight: 8 }} />
                      {selectedContact[indexPrefix]?.contact_email}
                    </Typography>
                  )}
                </div>
              )}
            </div>
            <div style={billingStyles.global}>
              <FastField
                name={`${PLACE}.payment_term_id`}
                options={invoicePayTermsOptions}
                styles={{ height: 32 }}
                component={CustomSelect}
                shouldUpdate={() => true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingChargesLtlOneChargeHeader;
