import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as UpdateIcon } from 'assets/icons/editPensle.svg';
import Chip from 'common/Chip';
import ConfirmModal from 'common/ConfirmModal';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { deleteRule, getRulesAndConduct } from 'Api/CompanySettings';
import { appliesToOptions } from './components/AddRule/AddRule.data';
import AddRule from './components/AddRule';
import { SHeader, SRow, SContentRow, SCircularProgress, SActions, SUnderlineText } from './RulesConduct.styles';

const RulesConduct = () => {
  const showToaster = useShowToaster();
  const [rules, setRules] = useState([]);
  const [editRule, setEditRule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openAddRule, setOpenAddRule] = useState(false);
  const [ruleToDeleteId, setRuleToDeleteId] = useState(null);
  const [attachment, setAttachment] = useState('');

  const getRules = async () => {
    try {
      const { data } = await getRulesAndConduct();
      setRules(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const deleteRuleConduct = async () => {
    try {
      setLoadingDelete(true);
      await deleteRule(ruleToDeleteId);
      setRuleToDeleteId(null);
      showToaster({ type: 'success', message: 'Rule has been successfully deleted!' });
      getRules();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    getRules();
  }, []);

  return (
    <div>
      <SHeader>
        <Typography variant='h2'>Rules and Conduct (Handbook)</Typography>
        <CustomButton
          width={135}
          height={12}
          type='secondary'
          title='Add Rule'
          leftIcon={<PlusIcon style={{ marginRight: 10, fill: '#464F60' }} />}
          styleButton={{ margin: 0 }}
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          onClick={() => setOpenAddRule(true)}
        />
      </SHeader>
      {loading ? (
        <SCircularProgress size={26} />
      ) : (
        <>
          <SRow>
            <div className='header-item'>
              <Typography variant='overLine'>TITLE</Typography>
            </div>
            <div className='header-item'>
              <Typography variant='overLine'>DESCRIPTION</Typography>
            </div>
            <div className='header-item'>
              <Typography variant='overLine'>ASSOCIATED INCIDENT TYPE</Typography>
            </div>
            <div className='header-item'>
              <Typography variant='overLine'>APPLIES TO</Typography>
            </div>
            <div className='header-item'>
              <Typography variant='overLine' style={{ whiteSpace: 'nowrap' }}>
                POLICY ATTACHMENT
              </Typography>
            </div>
            <div className='header-item'>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography />
              </div>
            </div>
          </SRow>
          {rules?.map((item) => (
            <SContentRow key={item.id}>
              <div className='table-item'>
                <Typography variant='s2'>{item.title}</Typography>
              </div>
              <div className='table-item'>
                <Typography variant='s2'>{item.description}</Typography>
              </div>
              <div className='table-item'>
                {item.incident_types.map((incident) => (
                  <span key={incident.id}>
                    <Chip
                      size='small'
                      label={incident.type}
                      labelColor={palette.indigo500}
                      bgColor={palette.indigo100}
                      style={{ marginLeft: '4px' }}
                    />
                  </span>
                ))}
              </div>
              <div className='table-item'>
                {appliesToOptions.map(
                  (el) =>
                    item.applies_to?.includes(el.value) && (
                      <span key={el.id}>
                        <Chip
                          size='small'
                          label={el.name}
                          labelColor={palette.indigo500}
                          bgColor={palette.indigo100}
                          style={{ marginLeft: '4px' }}
                        />
                      </span>
                    )
                )}
              </div>
              <div className='table-item'>
                {!!item.policy_attachment && (
                  <SUnderlineText onClick={() => setAttachment(item.policy_attachment)}>View</SUnderlineText>
                )}
              </div>
              <SActions className='table-item table-actions-wrapper'>
                {loadingDelete ? (
                  <span className='action_wrapper'>
                    <SCircularProgress size={16} />
                  </span>
                ) : (
                  <span className='action_wrapper action-delete' onClick={() => setRuleToDeleteId(item.id)}>
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </span>
                )}
                <span
                  className='action_wrapper'
                  onClick={() => {
                    setEditRule(item);
                    setOpenAddRule(true);
                  }}
                >
                  <UpdateIcon width={11} height={11} fill={palette.indigo500} />
                </span>
              </SActions>
            </SContentRow>
          ))}
        </>
      )}
      {openAddRule && (
        <AddRule
          open={openAddRule}
          onClose={() => {
            setOpenAddRule(false);
            setEditRule(null);
          }}
          rule={editRule}
          onSuccess={getRules}
        />
      )}
      {!!ruleToDeleteId && (
        <ConfirmModal
          open={!!ruleToDeleteId}
          title='Delete Rule'
          text='Are you sure you want to delete this rule?'
          btnText='Delete'
          onClose={() => setRuleToDeleteId(null)}
          onConfirm={deleteRuleConduct}
        />
      )}
      {!!attachment && <ViewPdf open={!!attachment} onClose={() => setAttachment('')} pdfUrl={attachment} />}
    </div>
  );
};

export default RulesConduct;
