import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { CreateSettingMobileApps, GetSettingMobileApps, getSelectOptions } from 'Api/CompanySettings';
import { pingIntervalOptions } from './SettingsMobileApps.data';
import styles from './SettingsMobileApps.module.css';

const mapData = [
  { labelName: 'Google Maps', keyName: 'google_maps', id: 1 },
  { labelName: 'CoPilot GPS Navigation & Traffic', keyName: 'copilot_gps_navigation_traffic', id: 2 },
  { labelName: 'Hammer: Truck GPS & Maps', keyName: 'hammer_truck_gps_maps', id: 3 },
];

const pageData = [
  // { fieldName: 'moving_two_hr_n', title: 'GPS Ping Every 1 Second(s).', id: 1 },
  // { fieldName: 'send_dispatch_sms', title: 'Send SMS Notification on Dispatch', id: 2 },
  {
    fieldName: 'offline_mode',
    title: 'Offline Mode (Send during background data sync upon first sign of connection)',
    id: 2,
  },
  { fieldName: 'is_allowed_for_requests', title: 'Allow Drivers to Make Requests', id: 3 },
  { fieldName: 'is_auto_directions_mode', title: 'Auto Open Navigation on Start Shipment', id: 4 },
  { fieldName: 'distract_driving_prevention', title: 'Enable Distracted Driving Mode (When on Shipment) ', id: 5 },
  { fieldName: 'allowed_to_upload_profile_pic', title: 'Allow Drivers to Change Profile Picture', id: 6 },
  { fieldName: 'appear_license_requirement', title: 'Require Drivers to Upload Driver License', id: 7 },
  { fieldName: 'appear_medicalcard_requirement', title: 'Require Driver to Upload Medical Card', id: 8 },
  { fieldName: 'is_allowed_driver_select_vehicle', title: 'Allow Drivers to Select Vehicle', id: 9 },
  { fieldName: 'is_allowed_driver_select_trailer', title: 'Allow Drivers to Select Trailer', id: 10 },
  { fieldName: 'equipment_fault_code_alert', title: 'Show Equipment Fault Code Alerts', id: 11 },
  { fieldName: 'equipment_system_alert', title: 'Show Equipment System Alerts', id: 12 },
  {
    fieldName: 'allow_to_start_only_first_shipment',
    title: 'Require Driver to Start Only First Shipment in Line Based on Time',
    id: 13,
  },
  { fieldName: 'hide_amount_on_shipments', title: 'Hide Driver Amount on Shipments', id: 14 },
];

const SettingsMobileApps = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [mobileApps, setMobileApps] = useState(null);

  const getMobileApps = () => {
    return GetSettingMobileApps().then((res) => {
      setMobileApps(res?.data);
    });
  };

  useEffect(() => {
    getMobileApps().then();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleSubmit = (values) => {
    const dataJson = {
      default_map_routing: values?.default_map_routing,
      is_allowed_for_requests: Number(values?.is_allowed_for_requests),
      is_auto_directions_mode: Number(values?.is_auto_directions_mode),
      allowed_to_upload_profile_pic: Number(values?.allowed_to_upload_profile_pic),
      appear_license_requirement: Number(values?.appear_license_requirement),
      appear_medicalcard_requirement: Number(values?.appear_medicalcard_requirement),
      is_allowed_driver_select_vehicle: Number(values?.is_allowed_driver_select_vehicle),
      is_allowed_driver_select_trailer: Number(values?.is_allowed_driver_select_trailer),
      equipment_fault_code_alert: Number(values?.equipment_fault_code_alert),
      equipment_system_alert: Number(values?.equipment_system_alert),
      distract_driving_prevention: Number(values?.distract_driving_prevention),
      send_dispatch_sms: Number(values?.send_dispatch_sms),
      is_allowed_to_reschedule: Number(values?.is_allowed_to_reschedule),
      ping_interval: Number(values?.ping_interval?.value || 1),
      allow_to_start_only_first_shipment: Number(values?.allow_to_start_only_first_shipment),
      hide_amount_on_shipments: Number(values?.hide_amount_on_shipments),
      auto_start_shipment: Number(values?.auto_start_shipment),
      offline_mode: Number(values?.offline_mode),
    };
    CreateSettingMobileApps(dataJson).then((res) => {
      if (res) {
        showToaster({ type: 'success', message: 'Mobile Apps has been successfully updated!' });
        if (isConfiguringStep) {
          onSaveAndNext('saveAndNext');
        }
        getMobileApps().then();
      }
    });
  };

  const initialValue = useMemo(() => {
    const {
      is_allowed_for_requests,
      is_auto_directions_mode,
      is_allowed_to_reschedule,
      allowed_to_upload_profile_pic,
      appear_license_requirement,
      appear_medicalcard_requirement,
      default_map_routing,
      is_allowed_driver_select_vehicle,
      is_allowed_driver_select_trailer,
      distract_driving_prevention,
      equipment_fault_code_alert,
      equipment_system_alert,
      send_dispatch_sms,
      ping_interval,
      allow_to_start_only_first_shipment,
      hide_amount_on_shipments,
      auto_start_shipment,
      offline_mode,
    } = mobileApps || {};
    return {
      default_map_routing: default_map_routing || '',
      is_allowed_for_requests: is_allowed_for_requests ? !!is_allowed_for_requests : '',
      is_auto_directions_mode: is_auto_directions_mode ? !!is_auto_directions_mode : '',
      allowed_to_upload_profile_pic: allowed_to_upload_profile_pic ? !!allowed_to_upload_profile_pic : '',
      appear_license_requirement:
        appear_license_requirement === 0 || appear_license_requirement === 1 ? !!appear_license_requirement : false,
      appear_medicalcard_requirement: appear_medicalcard_requirement ? !!appear_medicalcard_requirement : false,
      is_allowed_driver_select_vehicle: is_allowed_driver_select_vehicle ? !!is_allowed_driver_select_vehicle : '',
      is_allowed_driver_select_trailer: is_allowed_driver_select_trailer ? !!is_allowed_driver_select_trailer : '',
      equipment_fault_code_alert: equipment_fault_code_alert ? !!equipment_fault_code_alert : '',
      equipment_system_alert: equipment_system_alert ? !!equipment_system_alert : '',
      distract_driving_prevention: distract_driving_prevention ? !!distract_driving_prevention : '',
      send_dispatch_sms: send_dispatch_sms === 0 || send_dispatch_sms === 1 ? !!send_dispatch_sms : false,
      is_allowed_to_reschedule:
        is_allowed_to_reschedule === 0 || is_allowed_to_reschedule === 1 ? !!is_allowed_to_reschedule : false,
      ping_interval: pingIntervalOptions.find((i) => i.value === Number(ping_interval)) || pingIntervalOptions[0],
      allow_to_start_only_first_shipment: !!allow_to_start_only_first_shipment,
      hide_amount_on_shipments: !!hide_amount_on_shipments,
      auto_start_shipment: !!auto_start_shipment,
      offline_mode: !!offline_mode,
    };
  }, [mobileApps]);

  return (
    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{ ...initialValue }}>
      {({ validateField, getFieldHelpers, ...props }) => {
        return (
          <SettingsMobileAppsForm
            {...props}
            validateField={validateField}
            getFieldHelpers={getFieldHelpers}
            isConfiguringStep={isConfiguringStep}
            onSaveAndNext={onSaveAndNext}
            onCancelPrevPage={onCancelPrevPage}
          />
        );
      }}
    </Formik>
  );
};

function SettingsMobileAppsForm({
  values,
  submitForm,
  setFieldValue,
  isConfiguringStep,
  onSaveAndNext,
  onCancelPrevPage,
}) {
  const { use } = useTheme();
  const [mapOptions, setMapOptions] = useState(null);

  const getMapOptions = async () => {
    try {
      const { data } = await getSelectOptions({ select_name: 'default_map_routing' });
      const parsedOptions = JSON.parse(data.options);
      const options = Object.keys(parsedOptions).map((key, index) => {
        return {
          labelName: parsedOptions[key],
          keyName: key,
          id: index + 1,
        };
      });
      setMapOptions(options);
    } catch (e) {
      setMapOptions(mapData);
    }
  };

  useEffect(() => {
    getMapOptions();
  }, []);

  return (
    <Form>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
            Truckin Digital Driver App
          </Typography>
          <div className={styles.actions}>
            {isConfiguringStep && (
              <>
                <CustomButton
                  onClick={onCancelPrevPage}
                  type='secondary'
                  title='Back'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
                <CustomButton
                  onClick={() => onSaveAndNext('skip')}
                  type='secondary'
                  title='Skip'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
              </>
            )}
            <CustomButton
              onClick={submitForm}
              type='primary'
              title={isConfiguringStep ? 'Save and Next' : 'Save'}
              styleButton={{ margin: 0 }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
            />
          </div>
        </div>
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Typography variant='s2'>Default Map for Navigation</Typography>
          </div>
          <div className={styles.right}>
            <Field
              name='default_map_routing'
              options={mapOptions}
              styles={{ width: 320, padding: '6px 12px', margin: 0 }}
              component={CustomSelect}
              keyName='keyName'
              labelName='labelName'
            />
          </div>
        </div>
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Typography variant='s2'>GPS Ping</Typography>
          </div>
          <div className={styles.right}>
            <Autocomplete
              width='320px'
              name='ping_interval'
              options={pingIntervalOptions}
              value={values.ping_interval}
              onChange={(e, val) => setFieldValue('ping_interval', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </div>
        </div>
        {pageData?.map((el) => {
          return (
            <Fragment key={el.fieldName}>
              <div className={styles.basic}>
                <Field name={`${el?.fieldName}`}>
                  {({ field }) => (
                    <CustomCheckbox
                      name={`${el?.fieldName}`}
                      field={field}
                      type='switch'
                      withinForm
                      smail={false}
                      style={el?.fieldName === 'moving_two_hr_n' && { opacity: 0.5 }}
                      checked={el?.fieldName === 'moving_two_hr_n' ? true : !!field.value}
                    />
                  )}
                </Field>
                <Typography style={{ marginLeft: 10 }} variant='s2'>
                  {el?.title}
                </Typography>
                {el?.fieldName === 'is_allowed_to_reschedule' && (
                  <div className={styles.actions_wrapper}>
                    <Typography variant='s2'>If no movement detected for</Typography>
                    <div className={styles.input_wrapper}>
                      <Field
                        name='moment_deducted_hr'
                        component={CustomInput}
                        labelStyle={{ margin: 0 }}
                        disabled={!values?.is_allowed_to_reschedule}
                        style={{
                          width: 62,
                          borderTopLeftRadius: 6,
                          borderBottomLeftRadius: 6,
                          height: 32,
                          padding: '0 6px',
                        }}
                      />
                      <div className={styles.info}>
                        <Typography>hour(s)</Typography>
                      </div>
                    </div>
                    <Typography variant='s2'>after</Typography>
                    <div className={styles.input_wrapper}>
                      <Field
                        name='clock_out_notify_min'
                        component={CustomInput}
                        labelStyle={{ margin: 0 }}
                        disabled={!values?.is_allowed_to_reschedule}
                        style={{
                          width: 62,
                          borderTopLeftRadius: 6,
                          borderBottomLeftRadius: 6,
                          height: 32,
                          padding: '0 6px',
                        }}
                      />
                      <div className={styles.info}>
                        <Typography>minutes</Typography>
                      </div>
                    </div>
                    <Typography variant='s2'>from Notification.</Typography>
                  </div>
                )}
              </div>
              {el.id === 13 && !!values[el.fieldName] && (
                <div className={styles.basic}>
                  <ArrowIcon className='ms-5 me-2' />
                  <Field name='auto_start_shipment'>
                    {({ field }) => (
                      <CustomCheckbox
                        name='auto_start_shipment'
                        field={field}
                        type='switch'
                        withinForm
                        smail={false}
                        checked={!!field.value}
                      />
                    )}
                  </Field>
                  <Typography style={{ marginLeft: 10 }} variant='s2'>
                    Auto Start Shipment
                  </Typography>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </Form>
  );
}

export default SettingsMobileApps;
