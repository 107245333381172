import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { AddTab, DeleteTab, UpdateTab } from 'Api/Planner';
import Chip from 'common/Chip';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import styles from 'components/PlanerModalContent/PlanerModalContent.module.css';
import { ALL_TAB_FILTER_ID } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { palette } from 'utils/constants';
import InputLabel from 'common/InputLabel';

const EQUIPMENT_TYPES = {
  VEHICLE: 'vehicle',
  TRAILER: 'trailers',
  GROUP: 'groups',
  STATUS: 'status',
};

const ShipmentModalContent = ({
  filterByGroupsData,
  filterByEquipmentTypeData,
  onClickButtons,
  initialValue,
  filter,
  updateFilter,
  customers,
  stopPointsData,
  statusData,
  typeofDataJson,
  timeLines,
}) => {
  const [customer, setCustomer] = useState(false);
  const [timeLine, setTimeLine] = useState(false);
  const [stopPoint, setStopPoint] = useState(false);
  const [mySwitch, setMySwitch] = useState(false);
  const [isEdi, setIsEdi] = useState(false);
  const [isBrokered, setIsBrokered] = useState(false);

  const [zipCodes, setZipCodes] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [zipCodeChecked, setZipCodeChecked] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [selectDataCustomers, setSelectDataCustomers] = useState([]);
  const [selectedTimeLine, setSelectedTimeLine] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [stopPointSelected, setStopPointSelected] = useState({});
  const departmentName =
    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.department?.department_name;
  const [multiSelectGroups, setMultiSelectGroups] = useState([]);

  const getShipmentType = (options) => {
    const data = [];

    function recursive(submenu) {
      submenu.forEach((item) => {
        if (
          item?.typeData &&
          (item?.typeData === EQUIPMENT_TYPES.VEHICLE || item.typeData === EQUIPMENT_TYPES.TRAILER)
        ) {
          data.push(item);
        } else {
          item?.submenu && recursive(item.submenu);
        }
      });
    }

    recursive(options);
    return data;
  };

  const getGroups = (options) => {
    const data = [];

    function recursive(submenu) {
      submenu.forEach((item) => {
        if (item?.typeData && item?.typeData === EQUIPMENT_TYPES.GROUP) {
          data.push({ ...item, label: item?.title, key: item.id });
        } else {
          item?.submenu && recursive(item.submenu);
        }
      });
    }

    recursive(options);
    return data;
  };

  const addTabModal = (dataJson) => {
    AddTab(dataJson).then((res) => {
      if (res && res.data) {
        onClickButtons(false, res.data, { type: 'add', data: res.data });
      }
    });
  };

  const updateTabModal = (dataJson) => {
    UpdateTab({ id: initialValue.id, dataJson }).then((res) => {
      if (res && res.data) {
        onClickButtons(false, res.data, { type: 'update', data: res?.data });
      }
    });
  };

  function onChangeTimeLine(value) {
    setSelectedTimeLine(value.id);
  }

  function onChangeStopPont(value) {
    setStopPointSelected(value);
  }

  function onChangeShipmentTypesItems(id) {
    if (equipmentTypes.includes(id)) {
      const filtered = equipmentTypes.filter((el) => +el !== +id);
      setEquipmentTypes([...filtered]);
    } else {
      setEquipmentTypes([...equipmentTypes, id]);
    }
  }

  function onChangeTick(data) {
    const applyAllUsersModal = { ...filter.applyAllUsersModal, value: String(data) };
    updateFilter({ ...filter, applyAllUsersModal });
  }

  function onTickStatuses(id) {
    if (statuses.includes(id)) {
      const newStatuses = statuses.filter((status) => +status !== +id);
      setStatuses(newStatuses);
    } else {
      setStatuses([...statuses, id]);
    }
  }

  function onDelete() {
    DeleteTab(initialValue.id).then((res) => {
      if (res && res?.data) {
        onClickButtons(
          false,
          {
            key: 'all',
            label: 'All',
            value: 1,
            id: ALL_TAB_FILTER_ID,
            count: 0,
          },
          { type: 'delete', data: initialValue.id }
        );
      }
    });
  }

  function onSubmit(values) {
    const { tab_name } = values;
    const dataJson = {
      type: typeofDataJson,
      forAll: filter.applyAllUsersModal.value,
      data: {
        name: tab_name,
        filters: {
          status: statuses,
          equipment_types: mySwitch ? equipmentTypes : [],
          groups: multiSelectGroups.map((i) => i.id),
          customers: customer ? selectDataCustomers.map((i) => i.id) : [],
          time_frame: selectedTimeLine,
          stop_point: stopPoint ? stopPointSelected : {},
          is_edi: isEdi,
          is_brokered: isBrokered,
          zipcode: zipCodeChecked ? zipCodes : [],
        },
      },
    };
    !initialValue?.name ? addTabModal(dataJson) : updateTabModal(dataJson);
  }

  const validateRequired = (value) => {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  };

  useEffect(() => {
    const {
      name,
      status,
      groups,
      equipment_types,
      customers: selectedCustomers,
      stop_point,
      is_edi,
      time_frame,
      is_brokered,
      zipcode,
    } = initialValue || {};
    if (name) {
      selectedCustomers?.length > 0 && setCustomer(true);
      equipment_types?.length > 0 && setMySwitch(true);
      !!is_edi && setIsEdi(true);
      !!is_brokered && setIsBrokered(true);
      !!time_frame && setSelectedTimeLine(time_frame);
      !!time_frame && setTimeLine(true);
      stop_point !== null &&
        typeof stop_point === 'object' &&
        Object.keys(stop_point)?.length > 0 &&
        setStopPoint(true);
      status && setStatuses(status);
      setSelectDataCustomers(customers.filter((i) => selectedCustomers?.includes(i.id)));
      setEquipmentTypes(equipment_types);
      setMultiSelectGroups(getGroups(filterByGroupsData).filter((i) => groups?.includes(i.id)));
      setStopPointSelected(stop_point);
      setZipCodes(zipcode || []);

      if (zipcode.length) {
        setZipCodeChecked(true);
      }
    }
  }, [initialValue]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        tab_name: initialValue.name ? initialValue.name : '',
      }}
    >
      {({ submitForm }) => (
        <Form>
          <div className={styles.container}>
            <div className={styles.filed_error_wrapper}>
              <Field
                required
                name='tab_name'
                label='Tab name'
                component={CustomInput}
                validate={validateRequired}
                style={{
                  width: 414,
                  borderRadius: 6,
                  height: 32,
                  padding: '0 6px',
                }}
                labelStyle={{
                  margin: 0,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              />
              <ErrorMessage
                name='tab_name'
                render={(error) => (
                  <Typography variant='c2' style={{ color: palette.red500 }}>
                    {error}
                  </Typography>
                )}
              />
            </div>

            <div className={styles.select_wrapper}>
              <div className='mb-2'>
                <CustomCheckbox checked={customer} onChange={(value) => setCustomer(value)}>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Customer
                  </Typography>
                </CustomCheckbox>
              </div>
              <Autocomplete
                multiple
                labelKey='label'
                limitTags={2}
                placeholder={selectDataCustomers.length > 0 ? '' : 'Select..'}
                options={customers}
                value={selectDataCustomers}
                onChange={(e, val) => setSelectDataCustomers(val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={!customer}
              />

              <div className='d-flex flex-column gap-2 mt-3'>
                <div className='md-2'>
                  <CustomCheckbox checked={zipCodeChecked} onChange={(value) => setZipCodeChecked(value)}>
                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                      Zip Code
                    </Typography>
                  </CustomCheckbox>
                </div>

                <div>
                  <div className='d-flex align-items-center gap-2'>
                    <Input
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && !!zipCode?.length) {
                          event.preventDefault();
                          setZipCodes((prevState) => [...prevState, zipCode]);
                          setZipCode('');
                        }
                      }}
                      disabled={!zipCodeChecked}
                    />
                    {!!zipCode.length && (
                      <CheckIcon
                        className='pointer'
                        width={18}
                        height={18}
                        onClick={() => {
                          setZipCodes((prevState) => [...prevState, zipCode]);
                          setZipCode('');
                        }}
                      />
                    )}
                  </div>
                  <div className='d-flex flex-wrap gap-2 mt-2'>
                    {zipCodes.map((item) => (
                      <Chip
                        size='medium'
                        label={item}
                        labelColor={palette.indigo500}
                        fontWeight={500}
                        bgColor={palette.indigo0}
                        deleteIcon={<DeleteIcon />}
                        height='20px'
                        onDelete={() => setZipCodes((prevState) => prevState.filter((i) => i !== item))}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className={styles.tab_stopPoint}>
                <CustomCheckbox checked={stopPoint} onChange={(value) => setStopPoint(value)}>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Stop Point
                  </Typography>
                </CustomCheckbox>

                <CustomSelect
                  isSearchable
                  disabled={!stopPoint}
                  options={stopPointsData}
                  onChange={onChangeStopPont}
                  styles={{ height: 32, marginTop: 12 }}
                  defaultActiveValue={stopPointSelected}
                  dropDownStyles={{ flexGrow: 0, minWidth: '100%' }}
                  isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 12 }}
                />
              </div>

              <div className={`${styles.switch_wrap} ${styles.light}`}>
                <Form.Check
                  type='switch'
                  id='custom-switch'
                  label='Equipment Type'
                  checked={mySwitch}
                  onChange={() => setMySwitch((prev) => !prev)}
                />
              </div>

              {/* // Shipment Type */}
              {mySwitch && (
                <div className={styles.equipment_mode_wrapper}>
                  <div className={styles.equipment_childes}>
                    {getShipmentType(filterByEquipmentTypeData)?.map((item) => {
                      return (
                        <div key={item.id} className={styles.equipment_child}>
                          <CustomCheckbox
                            checked={equipmentTypes.includes(item.id)}
                            onChange={() => onChangeShipmentTypesItems(item.id)}
                          >
                            <Typography variant='s2' style={{ color: palette.gray700 }}>
                              {item.title}
                            </Typography>
                          </CustomCheckbox>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* // groups */}
              <div style={{ marginTop: 32 }}>
                <InputLabel className='ms-0'>If has Tag</InputLabel>
                <Autocomplete
                  multiple
                  labelKey='label'
                  limitTags={2}
                  placeholder={multiSelectGroups.length > 0 ? '' : 'Select..'}
                  options={getGroups(filterByGroupsData)}
                  value={multiSelectGroups}
                  onChange={(e, val) => setMultiSelectGroups(val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </div>

              <div className={`${styles.switch_wrap} ${styles.light}`}>
                <Form.Check
                  type='switch'
                  id='custom-switch-cloud'
                  label='Is EDI/API'
                  checked={isEdi}
                  onChange={() => setIsEdi((prev) => !prev)}
                />
              </div>

              <div className={`${styles.switch_wrap} ${styles.light}`}>
                <Form.Check
                  type='switch'
                  id='custom-switch-cloud'
                  label='Is Brokered'
                  checked={isBrokered}
                  onChange={() => setIsBrokered((prev) => !prev)}
                />
              </div>

              <div style={{ marginTop: 32 }}>
                <CustomCheckbox checked={timeLine} onChange={(value) => setTimeLine(value)}>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    If in timeframe
                  </Typography>
                </CustomCheckbox>

                <CustomSelect
                  options={timeLines}
                  disabled={!timeLine}
                  dropDownStyles={{ flexGrow: 0 }}
                  styles={{ height: 32, marginTop: 12 }}
                  onChange={onChangeTimeLine}
                />
              </div>
              <div style={{ marginTop: 32 }}>
                <Typography
                  variant='s2'
                  style={{
                    marginLeft: 0,
                    color: palette.gray700,
                  }}
                >
                  If Status
                </Typography>
                <div>
                  {statusData?.map((el) => {
                    const { id, title } = el;
                    return (
                      <CustomCheckbox key={id} checked={statuses.includes(id)} onChange={() => onTickStatuses(id)}>
                        <Typography variant='s2' style={{ color: palette.gray700 }}>
                          {title}
                        </Typography>
                      </CustomCheckbox>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer} style={{ background: palette.white }}>
            {departmentName === 'Management' && !initialValue?.name ? (
              <CustomCheckbox
                checked={Number(filter?.applyAllUsersModal.value)}
                onChange={() => onChangeTick(Number(filter?.applyAllUsersModal.value) ? 0 : 1)}
              >
                <div className='footer_title_checkbox'>
                  <span>Apply to all users</span>
                </div>
              </CustomCheckbox>
            ) : initialValue.name ? (
              <CustomButton
                title='Remove'
                type='danger'
                styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                onClick={onDelete}
              />
            ) : (
              <div />
            )}

            <div className={styles.footer_right}>
              <CustomButton
                title='Cancel'
                type='secondary'
                onClick={() => onClickButtons(false, null, { type: 'cancel', data: null })}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
              />
              <CustomButton
                title={initialValue.name ? 'Update Tab' : 'Add Tab'}
                type='primary'
                onClick={submitForm}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShipmentModalContent;
