import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { tableCloudPopover } from 'Api/Planner';
import { getCustomerRejectReasons } from 'Api/Customers';
import { validationSchema } from './validationSchema';

const EdiApiShipment = ({ open, onClose, alert, onSuccess }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [rejectionReasons, setRejectionReasons] = useState([]);

  const onAccept = async () => {
    try {
      await tableCloudPopover({ shipment_ids: [alert.shipment_id], acceptOrDecline: 1 });
      showToaster({ type: 'success', message: 'Shipment Accepted Successfully!' });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
  };

  const onDecline = async (values) => {
    try {
      await tableCloudPopover({
        shipment_ids: [alert.shipment_id],
        acceptOrDecline: 2,
        rejection_reason_id: values.rejection_reason?.id,
      });
      showToaster({ type: 'success', message: 'Shipment Declined Successfully!' });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { rejection_reason: null },
    onSubmit: onDecline,
    validationSchema,
  });

  const getCustomerRejectionReasons = async () => {
    try {
      const { data } = await getCustomerRejectReasons(alert.customer_id);
      setRejectionReasons(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomerRejectionReasons();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Take Action'
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        {
          key: 'navigate',
          type: 'primary',
          title: 'View',
          onClick: () => {
            navigate(`/shipment/${alert.shipment_id}`);
            onClose();
          },
        },
        {
          key: 'decline',
          type: 'danger',
          title: 'Decline',
          disabled: alert?.edi_acceptance_status,
          onClick: handleSubmit,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Accept',
          onClick: onAccept,
        },
      ]}
    >
      <CloseIcon className='close-modal-icon' fill={palette.red500} onClick={onClose} />
      <div className='d-flex flex-column gap-4 align-items-start'>
        <Typography variant='b2'>{alert?.alert_message}</Typography>
        <div>
          <Autocomplete
            width='350px'
            required
            label='Rejection Reason'
            name='rejection_reason'
            placeholder='Select Reason'
            labelKey='title'
            options={rejectionReasons}
            value={values.rejection_reason}
            onChange={(e, val) => handleChange('rejection_reason', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.rejection_reason}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EdiApiShipment;
