import React from 'react';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { STable } from 'pages/Reports/ReportDetails/ReportDetails.styles';

const SummaryTable = ({ reportData, type, sort, sortingQuery }) => {
  return (
    <STable>
      <thead>
        <tr className='header-row'>
          <th>
            <ColumnHeader title='LOCATION NAME' />
          </th>
          <th>
            <ColumnHeader title={type === 'equipment' ? 'EQUIPMENT' : 'DRIVER'} />
          </th>
          <th>
            <ColumnHeader title='AVG WAIT TIME' field='wait_time' sort={sort} onClick={sortingQuery} />
          </th>
        </tr>
      </thead>
      <tbody>
        {reportData.map((item) => (
          <tr key={item.id} className='body-row'>
            <td className='nowrap'>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {item.location_name || '-'}
              </Typography>
            </td>
            {type === 'equipment' ? (
              <td className='nowrap'>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.equipment?.equipment_id || ''}
                </Typography>{' '}
                {item.equipment?.length && (
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.equipment.length.length || ''}
                    {item.equipment.length.unit || ''}{' '}
                  </Typography>
                )}
                {item.equipment?.equipment_type && (
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.equipment.equipment_type.title || ''}
                    {item.equipment.equipment_type.title === 'Truck'
                      ? item.equipment.truck_type === 1
                        ? ' (Day-Cab)'
                        : ' (Sleeper)'
                      : ''}
                  </Typography>
                )}
              </td>
            ) : (
              <td className='nowrap'>
                {item.driver ? (
                  <UserInfo
                    size='32px'
                    statusSize='7px'
                    data={{
                      ...(item.driver || {}),
                      name: `${item.driver?.fname} ${item.driver?.lname}`,
                      user_type: 'driver',
                    }}
                  />
                ) : (
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    -
                  </Typography>
                )}
              </td>
            )}
            <td className='nowrap'>
              {(!!item.average_waiting_time?.days || item.average_waiting_time.days === 0) && (
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.average_waiting_time.days}d{' '}
                </Typography>
              )}
              {(!!item.average_waiting_time?.hours || item.average_waiting_time.hours === 0) && (
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.average_waiting_time.hours}h{' '}
                </Typography>
              )}
              {(!!item.average_waiting_time?.minutes || item.average_waiting_time.minutes === 0) && (
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.average_waiting_time.minutes}m
                </Typography>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </STable>
  );
};

export default SummaryTable;
