export const reportItemsConverter = (data) => {
  if (!data) {
    return [];
  }

  return Object.keys(data).map((key) => {
    return {
      customer: key,
      total_amount: data[key].total_amount,
    };
  });
};
