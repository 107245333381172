import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { endOfYear, startOfYear } from 'date-fns';
import { capitalize } from 'lodash';
import Modal from 'common/Modal';
import Loader from 'common/Loader';
import Search from 'common/Search';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { applyPaymentToShipment, getBillingData, reconcileBankFeed } from 'Api/Accounts';

import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';
import PayeeAutocomplete from 'pages/Accounting/Accounts/components/PayeeAutocomplete';
import { STable } from 'pages/Accounting/Accounts/BankFeed/BankFeed.styles';

import Transaction from './components/Transaction';
import ConfirmModal from './components/ConfirmModal';
import MatchTransactionRow from './components/MatchTransactionRow';
import { filterInvoices } from './helpers';
import { convertBillingData, getApplyBody } from './converters';
import { STableWrapper } from './MatchTransaction.styles';

const MatchTransaction = ({ open, onClose, transaction, onSuccess, payees, accounts }) => {
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [invoices, setInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [applyNotMatch, setApplyNotMatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [willAlsoApplyInvoice, setWillAlsoApplyInvoice] = useState(null);
  const [pdfLink, setPdfLink] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedPayee, setSelectedPayee] = useState(null);
  const [addPayeeOpen, setAddPayeeOpen] = useState(false);
  const [sort, setSort] = useState({ field: 'billed_date', sortBy: 'asc' });
  const [dateRange, setDateRange] = useState({
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  });

  const filteredInvoices = useMemo(() => {
    return filterInvoices(invoices, { search, sort, dateRange });
  }, [invoices, search, sort]);

  const { deposit, payment } = transaction || {};
  const isReceivable = deposit > 0;
  const transactionAmount = isReceivable ? deposit : payment;
  const itemsType = isReceivable ? 'invoices' : 'bills';
  const totalAmount = useMemo(() => {
    return selectedRows.reduce((a, b) => a + b.total_amount, 0);
  }, [selectedRows]);
  const matches = totalAmount === transactionAmount;
  const overpay = totalAmount < transactionAmount;
  const shouldSelectPayee =
    isReceivable &&
    selectedRows.some((i) => i.payee_id !== selectedRows[0]?.payee_id || i.payee_type !== selectedRows[0]?.payee_type);
  const disabled = !selectedRows.length || (!matches && !applyNotMatch) || (shouldSelectPayee && !selectedPayee);

  const reconcileTransaction = async () => {
    const payee = shouldSelectPayee ? selectedPayee : selectedRows[0].payee;
    const account = selectedRows[0].account;

    try {
      const body = {
        account_id: [transaction.account_id],
        date: [transaction.date],
        reference_id: [transaction.reference_id],
        payee_id: [payee.id || transaction.payee?.id || 0],
        payee_type: [payee.type || transaction.payee?.type || null],
        account: [account?.id || payee?.account_id || transaction.account?.id || 0],
        memo: [transaction.memo],
        reconciled: [1],
        payment: [transaction.payment || 0],
        deposit: [transaction.deposit || 0],
      };

      const { data } = await reconcileBankFeed(body);
      onSuccess(data);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onSubmit = async (confirmed) => {
    if (shouldSelectPayee && !selectedPayee) {
      showToaster({ type: 'error', message: 'Payee is missing!' });
      return;
    }

    setLoadingSubmit(true);
    if (!confirmed && totalAmount - (applyNotMatch?.total_amount || 0) > transactionAmount) {
      const notApplyInvoices = selectedRows.filter((item) => item.id !== applyNotMatch?.id);
      setWillAlsoApplyInvoice(notApplyInvoices[notApplyInvoices.length - 1]);
      return;
    }

    try {
      const body = getApplyBody(selectedRows, transaction, applyNotMatch);

      await applyPaymentToShipment(body);
      await reconcileTransaction();
      showToaster({ type: 'success', message: 'Transaction has been successfully matched and reconciled!' });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const getBilling = async () => {
    setLoading(true);
    try {
      const { data } = await getBillingData({ id: transaction.id });
      const combinedData = isReceivable ? [...data.open_invoice, ...(data.standalone_invoice || [])] : data;
      const dataWithBilling = combinedData.filter((item) => !isReceivable || !!item.billing || !!item.customer);
      const convertedData = convertBillingData(dataWithBilling, transaction, { accounts, payees });

      setInvoices(convertedData);
      setSelectedRows(convertedData.filter((i) => i.isSelected));
    } finally {
      setLoading(false);
    }
  };

  const handleSelectRow = (checked, row) => {
    setApplyNotMatch(null);
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const handleSelectApplyNotMatch = (checked, row) => {
    setApplyNotMatch(checked ? row : null);
  };

  const onRowClick = (row) => {
    if (row.pdf_file_link) {
      setPdfLink(row.pdf_file_link);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getBilling();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Match Transaction'
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='80vw'
      $minHeight='500px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Apply & Reconcile',
          disabled: loadingSubmit || disabled,
          onClick: () => onSubmit(false),
        },
      ]}
    >
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>
          <div className='d-flex mb-3 gap-2'>
            <DateRangePicker initialRangeName='This Year' dateRange={dateRange} setDateRange={setDateRange} />
            <Search search={search} onChange={setSearch} />
          </div>
          <Transaction transaction={transaction} />
          <STableWrapper className='scrollable'>
            <div className='scrollable-box'>
              <Typography variant='s2'>1. Find and select matching {itemsType}</Typography>
              <STable>
                <thead>
                  <tr>
                    <th />
                    <th>
                      <ColumnHeader title='BILLED DATE' field='billed_date' sort={sort} onClick={sortingQuery} />
                    </th>
                    <th>
                      <ColumnHeader
                        title={isReceivable ? 'CUSTOMER' : 'VENDOR'}
                        field='payee_name'
                        sort={sort}
                        onClick={sortingQuery}
                      />
                    </th>
                    <th>REFERENCE</th>
                    <th>
                      <ColumnHeader title='DUE DATE' field='due_date' sort={sort} onClick={sortingQuery} />
                    </th>
                    <th>
                      <ColumnHeader title='AMOUNT' field='total_amount' sort={sort} onClick={sortingQuery} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredInvoices.map((item) => (
                    <MatchTransactionRow
                      key={item.id}
                      isMatchTable
                      rowData={item}
                      totalAmount={totalAmount}
                      transactionAmount={transactionAmount}
                      selected={selectedRows.some((i) => i.id === item.id)}
                      handleSelectRow={handleSelectRow}
                      onRowClick={() => onRowClick(item)}
                      isReceivable={isReceivable}
                      selectedRows={selectedRows}
                    />
                  ))}
                </tbody>
              </STable>
              {!invoices?.length && <NoRecords />}
            </div>
          </STableWrapper>
          <STableWrapper>
            <div className='d-flex flex-column'>
              <Typography variant='s2'>2. View your selected {itemsType}</Typography>
              <Typography variant='s2' style={{ color: matches ? palette.green500 : palette.red500 }}>
                {!!selectedRows.length &&
                  (matches
                    ? `Transaction amount matches with ${itemsType} amount`
                    : overpay
                    ? `Transaction amount is grater than ${itemsType} amount. Please, select ${
                        isReceivable ? 'invoice' : 'bill'
                      } on which you want to apply overpayment.`
                    : `Transaction amount is less than ${itemsType} amount. Please, select ${
                        isReceivable ? 'invoice' : 'bill'
                      } on which you want to apply underpayment.`)}
              </Typography>
            </div>
            <STable>
              <thead>
                <tr>
                  <th />
                  <th>DATE</th>
                  <th>{isReceivable ? 'CUSTOMER' : 'VENDOR'}</th>
                  <th>REFERENCE</th>
                  <th>DUE DATE</th>
                  <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {selectedRows.map((item) => (
                  <MatchTransactionRow
                    key={item.id}
                    rowData={item}
                    totalAmount={totalAmount}
                    transactionAmount={transactionAmount}
                    selected={applyNotMatch?.id === item.id}
                    handleSelectRow={handleSelectApplyNotMatch}
                    matches={matches}
                    onRowClick={() => onRowClick(item)}
                    isReceivable={isReceivable}
                    selectedRows={selectedRows}
                  />
                ))}
              </tbody>
            </STable>
            {!selectedRows?.length && <NoRecords text='No selected items' />}
          </STableWrapper>
          <STableWrapper>
            <div className='d-flex justify-content-between'>
              {shouldSelectPayee && (
                <div>
                  <PayeeAutocomplete
                    required
                    label='Select Payee'
                    size='default'
                    width='250px'
                    options={payees}
                    value={selectedPayee}
                    onChange={(e, val) => setSelectedPayee(val)}
                    onAddNew={setAddPayeeOpen}
                  />
                </div>
              )}
              <div className='w-100 d-flex flex-column align-items-end gap-3'>
                <div className='d-flex align-items-center gap-3'>
                  <Typography variant='s2'>Transaction Amount:</Typography>
                  <Typography variant='h5'>
                    {currency}
                    {formatNumber(isReceivable ? deposit : payment)}
                  </Typography>
                </div>
                <div className='d-flex align-items-center gap-3'>
                  <Typography variant='s2'>{capitalize(itemsType)} Total Amount:</Typography>
                  <Typography variant='h5'>
                    {currency}
                    {formatNumber(totalAmount)}
                  </Typography>
                </div>
              </div>
            </div>
          </STableWrapper>
        </div>
      )}
      <ConfirmModal
        open={!!willAlsoApplyInvoice}
        invoice={willAlsoApplyInvoice}
        onClose={() => setWillAlsoApplyInvoice(null)}
        onConfirm={() => onSubmit(true)}
      />
      {!!pdfLink && (
        <ViewPdf open={!!pdfLink} onClose={() => setPdfLink(null)} pdfUrl={pdfLink} title='View Invoice(s)' />
      )}
      {!!addPayeeOpen && (
        <AddPayee
          open={!!addPayeeOpen}
          name={addPayeeOpen}
          onClose={() => setAddPayeeOpen(false)}
          onSuccess={setSelectedPayee}
        />
      )}
    </Modal>
  );
};

export default MatchTransaction;
