import React from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import useDateFormat from 'hooks/useDateFormat';
import { SPickerWrapper } from '../Pickers.styles';

const DateTimePicker = ({ label, error, required, labelProps, width, borderRadius, onBlur, size, ...props }) => {
  const { pickerDateFormat, pickerTimeFormat } = useDateFormat();

  return (
    <SPickerWrapper $width={width} $borderRadius={borderRadius} $size={size}>
      {!!label && (
        <InputLabel required={required} {...labelProps}>
          {label}
        </InputLabel>
      )}
      <MuiDateTimePicker
        format={`${pickerDateFormat} ${pickerTimeFormat}`}
        slotProps={{ textField: { onBlur } }}
        {...props}
      />
      <ErrorMessage error={error} />
    </SPickerWrapper>
  );
};

export default DateTimePicker;
