import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { documentType } from 'Api/Document';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { getSlaveShipments } from 'Api/Shipment';

import Autocomplete from 'common/Autocomplete';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import ImageDropZone from 'components/ImgageDropZone';
import {
  fillAddDocumentsShipment,
  getAddDocumentsShipment,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import classes from './documents.module.css';

const AddDocumentModalShipment = ({ isOpen, setIsOpen, onSubmit, initialValueOutSide, infoHeader, loading }) => {
  const { use } = useTheme();
  const [typeData, setTypeData] = useState([]);
  const [slaves, setSlaves] = useState([]);

  function onSubmitHandler(validateForm, resetForm, submitForm, values) {
    onSubmit(values, validateForm, resetForm, submitForm);
  }

  function onDrop(acceptedFiles, name, setFiledValue) {
    setFiledValue(name, acceptedFiles[0]);
  }

  function onDelete(e, setFieldValue, name) {
    e.stopPropagation();
    setFieldValue(name, '');
  }

  function cancel(resetForm) {
    setIsOpen(false);
    resetForm();
  }

  const styles = useMemo(() => {
    return {
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      labelInputs: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      add: {
        padding: '2px 8px',
        marginRight: 0,
        marginTop: 0,
      },
      cancel: {
        padding: '2px 8px',
        marginTop: 0,
      },
      modalBody: {
        maxHeight: 600,
        overflowY: 'auto',
        padding: '20px',
        width: 600,
      },
      modalFooter: {
        backgroundColor: use(palette.white, palette.dark900),
      },
      dropZone: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
  }, [use, palette]);

  const validateRequired = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const initialValues = useMemo(() => {
    return initialValueOutSide
      ? { ...fillAddDocumentsShipment(initialValueOutSide) }
      : { ...getAddDocumentsShipment() };
  }, [initialValueOutSide]);

  useEffect(() => {
    if (isOpen) {
      documentType({ shipment_id_required: 1 }).then((res) => {
        if (res) {
          if (res.data && res.data.length) {
            const options = res.data
              .filter((el) => !el.hidden)
              .map((el) => {
                return { ...el, key: el.id, label: el.name, labelSelected: null };
              });
            setTypeData(options);
          }
        }
      });

      const body = {
        shipment_id: infoHeader?.shipment_id,
      };
      getSlaveShipments(body).then((res) => {
        if (res) {
          if (res.data && res.data.length) {
            const options = res.data.map((el) => {
              return {
                ...el,
                key: el.shipment_id,
                label: `${el.shipment_id} ${el.shipment_billing?.[0]?.billing_customer?.company_name}`,
                labelSelected: null,
              };
            });
            setSlaves(options);
          }
        }
      });
    }
  }, [isOpen]);

  return (
    <div>
      <Formik onSubmit={() => {}} enableReinitialize initialValues={initialValues}>
        {({ values, resetForm, submitForm, validateForm, setFieldValue }) => {
          return (
            <Form>
              <ModalWrapper
                width={600}
                isOpen={isOpen}
                title='Add Document'
                styleBody={styles.modalBody}
                onHide={() => setIsOpen(false)}
                footer={
                  <div style={styles.modalFooter} className={classes.modalFooterLane}>
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => cancel(resetForm)}
                      styleButton={styles.cancel}
                    />
                    <CustomButton
                      type='primary'
                      styleButton={styles.add}
                      title={initialValueOutSide ? 'Update Document' : 'Add Document'}
                      onClick={() => onSubmitHandler(validateForm, resetForm, submitForm, values)}
                      disabled={loading}
                    />
                  </div>
                }
              >
                {/* // Reference */}
                <div className={classes.errorWrapper}>
                  <Field
                    required
                    label='Reference'
                    name='reference_id'
                    component={CustomInput}
                    className={classes.addDocInputs}
                    validate={validateRequired}
                    labelStyle={styles.labelInputs}
                  />
                  <ErrorMessage
                    name='reference_id'
                    render={(error) => (
                      <Typography variant='c2' style={styles.error}>
                        {error}
                      </Typography>
                    )}
                  />
                </div>

                {/* // Document Type */}
                {infoHeader?.shipment_type === 'ltl' && (
                  <div className={classes.errorWrapper}>
                    <Field
                      required
                      options={slaves}
                      styles={styles.type}
                      label='Shipment ID/Customer'
                      component={CustomSelect}
                      name='shipment_id'
                      validate={validateRequired}
                      menuStyles={styles.fullWidthPercent}
                    />
                    <ErrorMessage
                      name='type'
                      render={(error) => (
                        <Typography variant='c2' style={styles.error}>
                          {error}
                        </Typography>
                      )}
                    />
                  </div>
                )}

                {/* // Document Type */}
                <div className={classes.errorWrapper}>
                  <Field
                    required
                    options={typeData}
                    styles={styles.type}
                    label='Document Type'
                    component={CustomSelect}
                    name='type'
                    validate={validateRequired}
                    menuStyles={styles.fullWidthPercent}
                  />
                  <ErrorMessage
                    name='type'
                    render={(error) => (
                      <Typography variant='c2' style={styles.error}>
                        {error}
                      </Typography>
                    )}
                  />
                </div>

                <div className={classes.errorWrapper}>
                  <Field
                    name='shipment_stop'
                    label='Shipment Stop'
                    options={infoHeader.shipment_stops}
                    value={values.shipment_stop}
                    onChange={(e, val) => setFieldValue('shipment_stop', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.stop_point.location_name}
                      </li>
                    )}
                    getOptionLabel={(option) => `${option.stop_point.location_name}`}
                    component={Autocomplete}
                  />
                </div>

                {/* // drop zone */}
                <div className={classes.fileWrapper}>
                  <Typography variant='s2'>
                    Upload Document
                    <span className={classes.required}>*</span>
                  </Typography>
                  <div
                    className={classes.modalAddDocumentUpload}
                    style={{ backgroundColor: use(palette.white, palette.dark800) }}
                  >
                    <Field name='doc' validate={validateRequired}>
                      {() => (
                        <ImageDropZone
                          width='100%'
                          height='100%'
                          maxSize={100000000}
                          deletable={false}
                          name='doc'
                          deletedComponent
                          accept={['application/pdf']}
                          customStyle={styles.dropZone}
                          onDrop={(a, n) => onDrop(a, n, setFieldValue)}
                        >
                          <div className={classes.dropZoneContent}>
                            <UploadIcon />

                            {values?.doc ? (
                              <div className={classes.uploadedFiles}>
                                <TickIcon fill={palette.green500} />
                                <Typography variant='s2' style={{ color: palette.green500 }}>
                                  File Uploaded
                                </Typography>
                                <div onClick={(e) => onDelete(e, setFieldValue, 'doc')}>
                                  <DeleteIcon fill={palette.indigo500} />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <Typography variant='s2'>
                                  <Typography style={{ color: palette.indigo500 }}>Upload document&nbsp;</Typography>
                                  or drag and drop
                                </Typography>
                              </div>
                            )}
                          </div>
                        </ImageDropZone>
                      )}
                    </Field>
                  </div>

                  <ErrorMessage
                    name='doc'
                    render={(error) => (
                      <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                        {error}
                      </Typography>
                    )}
                  />
                </div>
              </ModalWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddDocumentModalShipment;
