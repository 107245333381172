import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  height: 100%;

  .accounts-table-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .accounts-table-wrapper::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 8px;
  }

  .MuiTableCell-head {
    display: none;
  }

  .MuiTableCell-root {
    padding: 8px 4px;
    border-bottom: none;
  }

  .MuiTableRow-root.MuiTableRow-head th {
    height: 32px !important;
  }

  .MuiTableRow-root.MuiTableRow-head {
    border: none;
  }

  .add-account {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin-right: 8px;
    padding: 8px 0 0 8px;
    width: fit-content;
  }

  .add-account span {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #4f5aed;
    border-bottom: transparent;
  }

  .add-account:hover span {
    text-decoration: underline;
  }
`;
