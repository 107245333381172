import moment from 'moment/moment';
import useDateFormat from 'hooks/useDateFormat';

export const getItemSubtotalAmount = (quantity, price, is_taxable, tax_percentage = 0) => {
  if (is_taxable) {
    return Number((Number(quantity) * Number(price) * (Number(tax_percentage) / 100 + 1)).toFixed(2));
  }
  return Number((Number(quantity) * Number(price)).toFixed(2));
};

export const getItemTaxAmount = (quantity, price, is_taxable, tax_percentage = 0) => {
  if (is_taxable) {
    return Number((Number(quantity) * Number(price) * Number(tax_percentage / 100)).toFixed(2));
  }
  return 0;
};

export const getInitialValues = (invoice, detentions) => {
  const { convertToCustomerTime } = useDateFormat();

  const detentionItems = detentions?.map((detention, index) => {
    const paymentTerms = detention?.shipment_billing?.billing_customer?.customer_payment_term;
    const isTrailer = detention?.equipment?.equipment_type?.vehicle_type_id === 2;
    const rate = isTrailer
      ? Number(paymentTerms?.detention_trailer_dropped_billed_at)
      : Number(paymentTerms?.billed_at);
    const daysHours = isTrailer ? paymentTerms?.detention_trailer_dropped_start_after_per : 'HOURS';
    const duration =
      daysHours === 'HOURS'
        ? Number((detention.duration_days * 24 + detention.duration_hours + detention.duration_minutes / 60).toFixed(2))
        : Number(
            (detention.duration_days + detention.duration_hours / 24 + detention.duration_minutes / 1440).toFixed(2)
          );
    const totalAmount = Number((duration * rate).toFixed(2));

    return {
      id: Date.now() + index,
      item: { id: 6, is_taxable: 0, tax_account: null, tax_percentage: null, title: 'Detention' },
      desc: `Detention (${detention?.shipment_stop?.stop_point?.location_name}) - ${convertToCustomerTime(
        detention?.arrived_date,
        null,
        true
      )} to ${convertToCustomerTime(detention?.departed_date, null, true)}`,
      quantity: duration,
      price: rate,
      is_taxable: false,
      tax_amount: '',
      total_amount: totalAmount,
      detentionId: detention.id,
    };
  });

  return {
    bill_date: invoice?.customer_billed_date ? moment(invoice.customer_billed_date).toDate() : new Date(),
    due_date: invoice?.due_date ? moment(invoice.due_date).toDate() : null,
    is_auto_debt_enabled: invoice?.is_auto_debt_enabled || false,
    payment_method: invoice?.payment_method || '',
    customer: invoice?.customer || null,
    reference_id: invoice?.reference_id || '',
    terms: invoice?.payment_terms || null,
    email_to: invoice?.email_to || null,
    items: detentionItems ||
      invoice?.items || [
        {
          id: Date.now(),
          item: null,
          desc: '',
          quantity: '',
          price: '',
          is_taxable: false,
          tax_amount: '',
          total_amount: '',
        },
      ],
    is_recurring: invoice?.is_recurring || false,
    recurring_days: invoice?.recurring_days || '',
    recurring_months: invoice?.recurring_months || null,
    recurring_duration: invoice?.recurring_duration || 1,
    recurring_frequency: invoice?.recurring_frequency || '',
    next_renew_date: invoice?.next_renew_date ? moment(invoice?.next_renew_date).toDate() : null,
    invoice_id: invoice?.invoice_id,
    note: invoice?.note || '',
    attachments: [],
  };
};

export const getRequestBody = (values) => {
  const {
    bill_date,
    due_date,
    is_auto_debt_enabled,
    payment_method,
    customer,
    reference_id,
    terms,
    email_to,
    items,
    is_recurring,
    recurring_days,
    recurring_months,
    recurring_duration,
    recurring_frequency,
    next_renew_date,
    note,
    attachments,
    invoice_id,
    is_draft,
  } = values || {};

  const formData = new FormData();
  if (bill_date && moment(bill_date).isValid()) {
    formData.append('bill_date', moment(bill_date).format('YYYY-MM-DD'));
  }
  if (due_date && moment(due_date).isValid()) {
    formData.append('due_date', moment(due_date).format('YYYY-MM-DD'));
  }
  formData.append('is_auto_debt_enabled', is_auto_debt_enabled ? '1' : '0');

  if (payment_method) {
    formData.append('payment_method', payment_method);
  }

  if (customer) {
    formData.append('customer_customer_id', customer.id);
  }
  if (reference_id) {
    formData.append('reference_id', reference_id);
  }
  if (terms) {
    formData.append('terms', terms.id);
  }
  if (email_to) {
    formData.append('contact_user_id', email_to.id);
  }

  items.forEach((item, index) => {
    if (item.item) {
      formData.append(`items[${index}][item_id]`, item.item.id);
    }
    if (item.desc) {
      formData.append(`items[${index}][desc]`, item.desc);
    }
    if (item.quantity) {
      formData.append(`items[${index}][quantity]`, item.quantity);
    }
    if (item.price) {
      formData.append(`items[${index}][price]`, item.price);
    }
    formData.append(`items[${index}][is_taxable]`, item.is_taxable ? '1' : '0');
    if (item.is_taxable) {
      formData.append(`items[${index}][tax_amount]`, item.tax_amount);
    }
    if (item.total_amount) {
      formData.append(`items[${index}][total_amount]`, item.total_amount);
    }
  });

  formData.append('is_recurring', is_recurring ? '1' : '0');

  if (is_recurring) {
    if (recurring_days) {
      formData.append('recurring_days', recurring_days);
    }
    if (recurring_months) {
      formData.append('recurring_months', recurring_months.name);
    }
    if (recurring_duration) {
      formData.append('recurring_duration', recurring_duration);
    }
    formData.append('recurring_frequency', recurring_duration === 1 ? 0 : recurring_frequency);
    if (next_renew_date) {
      formData.append('next_renew_date', moment(next_renew_date).format('YYYY-MM-DD'));
    }
  }

  if (note) {
    formData.append('note', note);
  }

  if (attachments?.length) {
    attachments.forEach((attachment) => {
      formData.append(`attachments[]`, attachment);
    });
  }

  if (invoice_id) {
    formData.append('invoice_id', invoice_id);
  }

  if (is_draft) {
    formData.append('is_draft', 1);
  } else {
    formData.append('is_draft', 0);
  }

  return formData;
};

export const recurringOptions = [
  { name: 'Weekly', days: 7, amount: 1, unit: 'weeks' },
  { name: 'Bi-Weekly', days: 14, amount: 2, unit: 'weeks' },
  { name: 'Monthly', days: 30, amount: 1, unit: 'months' },
  { name: 'Yearly', days: 365, amount: 1, unit: 'years' },
];
