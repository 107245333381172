import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import { DatePicker } from 'common/Pickers';
import Input, { Textarea } from 'common/Input';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { addPrevEmploymentNote } from 'Api/PreviousEmployment';
import { validationSchema } from './validationSchema';

const AddPreviousEmploymentNote = ({ open, onClose, onSuccess = () => null, employmentId, attemptId, userType }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        userType === 'applicant' ? `appl_empl_history_id` : `${userType}_employment_history_id`, // Qaqaca
        employmentId
      );
      formData.append('date', moment(values.date).format('YYYY-MM-DD'));
      formData.append('title', values.title);
      formData.append('notes', values.notes);
      formData.append('attempt_id', attemptId);

      await addPrevEmploymentNote({ formData, userType });
      onSuccess();
      onClose();
      showToaster({ type: 'success', message: 'Note has been successfully added!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, handleBlur } = useForm({
    initialValues: {
      date: new Date(),
      title: '',
      notes: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Add Note'
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Add Note',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-3'>
        <div>
          <DatePicker
            width='150px'
            required
            label='Date'
            name='date'
            value={values.date}
            onBlur={handleBlur}
            onChange={(val) => handleChange('date', val)}
            maxDate={new Date()}
            disableFuture
            error={touchedErrors.date}
          />
        </div>
        <Input
          required
          name='title'
          label='Title'
          onChange={handleChange}
          value={values.title}
          error={touchedErrors.title}
        />
        <Textarea
          rows={4}
          required
          label='Note'
          name='notes'
          placeholder='Notes...'
          value={values.notes}
          onChange={handleChange}
          error={touchedErrors.notes}
        />
      </div>
    </Modal>
  );
};

export default AddPreviousEmploymentNote;
