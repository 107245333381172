import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 64px;

  .scrollable-container {
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .chart-boxes-wrapper {
    width: 100%;
    height: 366px;
    display: flex;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    align-items: stretch;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item1,
  .item2 {
    border-right: 1px solid #e9edf5;
    border-bottom: 1px solid #e9edf5;
    grid-column: 1;
  }

  .item3 {
    grid-column: 2;
    grid-row: 1 / span 2;
    border-bottom: 1px solid #e9edf5;
  }

  .stat-boxes-wrapper {
    min-width: 1554px;
    display: flex;
  }
`;

export const SBoxWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-left: none;
  padding: 8px 2px 16px 16px;
  min-width: ${({ $minWidth }) => $minWidth || ''};
  max-width: ${({ $maxWidth }) => $maxWidth || ''};

  :first-child {
    padding-left: 2px;
  }

  :last-child {
    padding-right: 2px;
    border-right: none;
  }

  .title-wrapper {
    min-height: 25px;
  }

  .chart-wrapper {
    margin-top: 16px;
    height: calc(100% - 80px);
  }

  .MuiTableCell-root {
    padding: 8px 4px;
    border-bottom: none;
  }

  .MuiTableRow-root.MuiTableRow-head th {
    height: 32px !important;
  }

  .MuiTableRow-root.MuiTableRow-head {
    border-top: none;
    border-bottom: 1px solid rgb(224, 224, 224);
  }
`;
