import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContext } from 'components/layout';
import DarkOverlay from 'components/DarkOverlay';
import { Typography } from 'components/Typography';
import TableDrawer from 'components/TableShipments/TableDrawer';
import { palette } from 'utils/constants';
import { updateRecentActivity } from 'store/reducers/shipments.reducer';
import { getRecentActivity } from 'Api/Shipment';
import { SActions } from 'pages/Dashboard/Dashboard.styles';

const Actions = () => {
  const dispatch = useDispatch();
  const { setShowNotepad } = useContext(LayoutContext);
  const { recentActivity } = useSelector((state) => state?.shipments);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const fetchRecentActivity = () => {
    getRecentActivity({ page, itemsPerPage: 25 })
      .then((res) => {
        if (res && res?.data) {
          dispatch(updateRecentActivity([...recentActivity, ...(res?.data || [])]));
        }
        setPage((prevState) => ++prevState);
      })
      .catch(() => {
        // Do nothing
      });
  };

  useEffect(() => {
    fetchRecentActivity();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.overflowX = 'hidden';
    }
    if (!isOpen) {
      document.body.style.overflowY = 'auto';
      document.body.style.overflowX = 'hidden';
    }
  }, [isOpen]);

  return (
    <SActions>
      <Typography
        variant='s2'
        className='underline-text'
        style={{ color: palette.indigo500, borderRight: '1px solid #D5DAE5', paddingRight: '8px' }}
        onClick={() => setShowNotepad(true)}
      >
        To Do
      </Typography>
      <Typography
        variant='s2'
        className='underline-text'
        style={{ color: palette.indigo500, paddingLeft: '8px' }}
        onClick={() => setIsOpen(true)}
      >
        Recent Activity
      </Typography>
      <TableDrawer
        width={450}
        scrollHeight='95vh'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={recentActivity}
        activityStyles={{ margin: '36px 20px 0 0' }}
        page={page}
        moreData={fetchRecentActivity}
      />
      <DarkOverlay visible={isOpen} styles={{ zIndex: 999 }} onClick={() => setIsOpen(false)} />
    </SActions>
  );
};

export default Actions;
