import uuid from 'react-uuid';
import { DIMENSION_UNIT_TO_FEET, VALUE_TYPES } from 'utils/constants';

export const initialValues = {
  id: uuid(),
  isNew: true,
  commodity: null,
  description: '',
  expected_quantity: '',
  expected_quantity_type: null,
  expected_weight: '',
  expected_weight_type: null,
  expected_weight_unit: VALUE_TYPES[1],
  expected_stackable: false,
  value: '',
  value_type: VALUE_TYPES[0],
  expected_dimensions: { l: '', w: '', h: '' },
  expected_dimension_unit: null,
  sku_barcode: '',
};

export const getInitialValue = (item) => {
  const dimensions =
    typeof item.expected_dimensions === 'string'
      ? JSON.parse(item.expected_dimensions)
      : item.expected_dimensions || null;

  return {
    ...item,
    shipment_stop: item.shipment_stop || null,
    id: item.id || uuid(),
    commodity: item.commodity || null,
    description: item.description || '',
    expected_quantity: item.expected_quantity || '',
    expected_quantity_type: item.expected_quantity_type?.id
      ? item.expected_quantity_type
      : item.expected_quantity_type_data || item.expected_quantity_type || null,
    expected_weight: item.expected_weight || '',
    expected_weight_type: item.expected_weight_type?.id
      ? item.expected_weight_type
      : item.expected_weight_type_data || item.expected_weight_type || null,
    expected_weight_unit:
      typeof item.expected_weight_unit === 'number'
        ? VALUE_TYPES.find((i) => i.id === item.expected_weight_unit) || VALUE_TYPES[1]
        : item.expected_weight_unit || VALUE_TYPES[1],
    reported_quantity: item.reported_quantity || '',
    reported_quantity_type: item.reported_quantity_type?.id
      ? item.reported_quantity_type
      : item.reported_quantity_type_data || item.reported_quantity_type || null,
    reported_weight: item.reported_weight || '',
    reported_weight_type: item.reported_weight_type?.id
      ? item.reported_weight_type
      : item.reported_weight_type_data || item.reported_weight_types || item.reported_weight_type || null,
    reported_weight_unit:
      typeof item.reported_weight_unit === 'number'
        ? VALUE_TYPES.find((i) => i.id === item.reported_weight_unit) || VALUE_TYPES[1]
        : item.reported_weight_unit || VALUE_TYPES[1],
    value: item.value || '',
    value_type:
      typeof item.value_type === 'number'
        ? VALUE_TYPES.find((i) => i.id === item.value_type) || null
        : item.value_type || null,
    reported_value: item.reported_value || '',
    reported_value_type:
      typeof item.reported_value_type === 'number'
        ? VALUE_TYPES.find((i) => i.id === item.reported_value_type) || null
        : item.reported_value_type || null,
    expected_dimensions: {
      l: dimensions?.l || '',
      w: dimensions?.w || '',
      h: dimensions?.h || '',
    },
    expected_stackable: item.expected_stackable || false,
    expected_dimension_unit: item.expected_dimension_unit_data || item.expected_dimension_unit || null,
    sku_barcode: item.sku_barcode || '',
    isNew: item.isNew || false,
  };
};

export const getInitialValues = (cargo) => {
  return cargo?.length ? cargo.map((item) => getInitialValue(item)) : [initialValues];
};

export const createCargoConverter = (data) => {
  return {
    id: data.id,
    commodity_id: data.commodity.id,
    description: data.description || null,
    sku_barcode: data.sku_barcode || null,
    expected_quantity: Number(data.expected_quantity),
    expected_quantity_type: data.expected_quantity_type?.id || null,
    expected_weight: Number(data.expected_weight),
    expected_weight_type: data.expected_weight_type?.id || null,
    expected_weight_unit: data.expected_weight_unit?.id || null,

    reported_quantity: data.reported_quantity ? Number(data.reported_quantity) : undefined,
    reported_quantity_type: data.reported_quantity_type?.id || undefined,
    reported_weight: data.reported_weight ? Number(data.reported_weight) : undefined,
    reported_weight_type: data.reported_weight_type?.id || undefined,

    expected_dimensions: {
      l: Number(data.expected_dimensions?.l) || 0,
      w: Number(data.expected_dimensions?.w) || 0,
      h: Number(data.expected_dimensions?.h) || 0,
    },
    expected_stackable: Number(data.expected_stackable),
    expected_dimension_unit: data.expected_dimension_unit?.id || 2,
    value: data.value || null,
    value_type: data.value_type?.id || null,
  };
};

export const createCargoFormDataConverter = (cargos, formData) => {
  cargos.forEach((data, index) => {
    if (data.commodity) {
      formData.append(`cargos[${index}][commodity_id]`, data.commodity.id);
    }
    if (data.description) {
      formData.append(`cargos[${index}][description]`, data.description);
    }
    if (data.sku_barcode) {
      formData.append(`cargos[${index}][sku_barcode]`, data.sku_barcode);
    }
    if (data.expected_quantity) {
      formData.append(`cargos[${index}][expected_quantity]`, data.expected_quantity);
    }
    if (data.expected_quantity_type) {
      formData.append(`cargos[${index}][expected_quantity_type]`, data.expected_quantity_type.id);
    }
    if (data.expected_weight) {
      formData.append(`cargos[${index}][expected_weight]`, data.expected_weight);
    }
    if (data.expected_weight_type) {
      formData.append(`cargos[${index}][expected_weight_type]`, data.expected_weight_type.id);
    }
    if (data.expected_weight_unit) {
      formData.append(`cargos[${index}][expected_weight_unit]`, data.expected_weight_unit.id);
    }
    if (data.expected_dimensions) {
      formData.append(`cargos[${index}][expected_dimensions][l]`, Number(data.expected_dimensions?.l) || 0);
      formData.append(`cargos[${index}][expected_dimensions][w]`, Number(data.expected_dimensions?.w) || 0);
      formData.append(`cargos[${index}][expected_dimensions][h]`, Number(data.expected_dimensions?.h) || 0);
    }
    if (data.expected_dimension_unit) {
      formData.append(`cargos[${index}][expected_dimension_unit]`, data.expected_dimension_unit.id);
    }
    if (data.value) {
      formData.append(`cargos[${index}][value]`, data.value);
    }
    if (data.value_type) {
      formData.append(`cargos[${index}][value_type]`, data.value_type.id);
    }

    formData.append(`cargos[${index}][expected_stackable]`, Number(data.expected_stackable));
  });

  return formData;
};

export const getCargoTotals = (cargos, equipmentHeight) => {
  let expected_quantity = '';
  let expected_weight = '';
  let value = '';
  let length = '';
  let width = '';
  let height = '';
  let reported_quantity = '';
  let reported_weight = '';
  let reported_value = '';
  let volumeInCubicFeet = 0;
  let totalStackableHeight = 0;

  (cargos || []).forEach((item) => {
    Number(item.expected_quantity) && (expected_quantity = Number(expected_quantity) + Number(item.expected_quantity));
    Number(item.expected_weight) &&
      (expected_weight =
        Number(expected_weight) +
        (item.expected_weight_unit?.id === 1
          ? Number(item.expected_weight) * Number(item.expected_quantity || 0)
          : Number(item.expected_weight)));
    Number(item.value) &&
      (value =
        Number(value) +
        (item.value_type?.id === 1 ? Number(item.value) * Number(item.expected_quantity) : Number(item.value)));
    Number(item.expected_dimensions?.l) && (length = Number(length) + Number(item.expected_dimensions.l));
    Number(item.expected_dimensions?.w) && (width = Number(width) + Number(item.expected_dimensions.w));
    Number(item.expected_dimensions?.h) && (height = Number(height) + Number(item.expected_dimensions.h));

    Number(item.reported_quantity) && (reported_quantity = Number(reported_quantity) + Number(item.reported_quantity));
    Number(item.reported_weight) && (reported_weight = Number(reported_weight) + Number(item.reported_weight));
    Number(item.reported_value) &&
      (reported_value =
        Number(reported_value) +
        (item.reported_value_type?.id === 1 || item.reported_value_type === 1
          ? Number(item.reported_value) * Number(item.reported_quantity)
          : Number(item.reported_value)));

    volumeInCubicFeet +=
      Number(item.expected_dimensions?.l || 0) *
      (DIMENSION_UNIT_TO_FEET[item.expected_dimension_unit?.id] || 0) *
      Number(item.expected_dimensions?.w || 0) *
      (DIMENSION_UNIT_TO_FEET[item.expected_dimension_unit?.id] || 0) *
      (item.expected_stackable && equipmentHeight
        ? equipmentHeight
        : Number(item.expected_dimensions?.h || 0) * (DIMENSION_UNIT_TO_FEET[item.expected_dimension_unit?.id] || 0)) *
      Number(item.expected_quantity || 0);

    totalStackableHeight += item.expected_stackable
      ? Number(item.expected_dimensions?.h || 0) * (DIMENSION_UNIT_TO_FEET[item.expected_dimension_unit?.id] || 0)
      : 0;
  });

  return {
    expected_quantity,
    expected_weight,
    reported_quantity,
    reported_weight,
    reported_value,
    value,
    length,
    width,
    height,
    volumeInCubicFeet,
    totalStackableHeight,
  };
};
