import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import add from 'assets/icons/drivers/add.svg';
import useDebounce from 'hooks/useDebounce';
import { staffAbsenceData, staffCreateAbsenceData, staffUpdateAbsenceData, staffRemoveAbsenceData } from 'Api/Staff';
import { absencseStatusColor, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import MaterialTableWrapper, { MaterialTableSort } from '../MaterialTableWrapper';
import TableFooter from '../TableFooter/TableFooter';
import StaffAbsenceModal from '../StaffAbsenceModal/StaffAbsenceModal';
import AbsenceAllowanceModal from '../UpdateAbsenceAllowanceModal/UpdateAbsenceAllowanceModal';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import CustomizedSnackbars from '../toast/Toast';
import RowActions from './RowActions';
import './StaffAbsenceTable.css';

const AbsenceTable = ({
  absenceDetails,
  absence_allowance,
  updateAbsenceAllowanceData,
  absLoading,
  isChange,
  setIsChange,
}) => {
  const [tab, setTab] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [updateAbsenceModalShow, setUpdateAbsenceModalShow] = useState(false);
  const [driverAbsence, setDriverAbsence] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [otherData, setOtherData] = useState({});
  const [selectedYear, setSelectedYear] = useState(moment().year().toString());
  const [tabs, setTabs] = useState([]);
  const { id } = useParams();
  const { use } = useTheme();
  const [allAbsence, setAllAbsence] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateData, setUpdatedData] = useState({});
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const getDriverAbsenceData = (searchText = '', sortBy = 'desc', year = Number(moment(new Date()).format('yyyy'))) => {
    setLoading(true);
    const payload = { page: '1', itemsPerPage: rowPerPage, year, sortBy, staff_id: id };
    if (searchText) {
      payload.query = searchText;
    }
    staffAbsenceData(payload)
      .then((res) => {
        setLoading(false);
        const tempTabs = [];
        let value = 1;
        for (const req of res?.absence_data?.data || []) {
          const hasRequestIndex = tempTabs.findIndex((tab) => tab.label === req?.absence_type?.name);
          if (hasRequestIndex === -1) {
            value += 1;
            tempTabs.push({ label: req?.absence_type?.name, count: 1, key: req?.type, value });
          } else {
            tempTabs[hasRequestIndex].count += 1;
          }
        }
        tempTabs.unshift({ label: 'All', count: res?.absence_data?.data?.length, key: 'all', value: 1 });
        setTabs(tempTabs);
        setDriverAbsence(res?.absence_data);
        setOtherData(res.other_data);
        setAllAbsence(res?.absence_data);
        absenceDetails(year);
        const data = res?.other_data?.years?.find((item) => item === Number(moment(new Date()).format('yyyy')));
        if (data === undefined) {
          res?.other_data?.years?.push(Number(moment(new Date()).format('yyyy')));
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getDriverAbsenceData(debouncedSearch);
  }, [debouncedSearch]);

  const showError = (message) => {
    setShowMessage({ message, visible: true, type: 'error' });
    setTimeout(() => {
      setShowMessage({ visible: false, message: '' });
    }, 6000);
  };

  const onSuccess = (message) => {
    getDriverAbsenceData(debouncedSearch, undefined, selectedYear);
    if (message) {
      setShowMessage({ message, visible: true, type: 'success' });
      setTimeout(() => {
        setShowMessage({ visible: false, message: '' });
      }, 6000);
    }
  };

  const createAbsence = (absence, type, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);
    if (type === 'add') {
      staffCreateAbsenceData(absence)
        .then(() => {
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          setIsChange(!isChange);
          getDriverAbsenceData();
          setCreateLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setCreateLoading(false);
        });
    } else {
      const absenceId = absence?.id;
      delete absence?.id;
      delete absence.driver_id;
      staffUpdateAbsenceData(absence, absenceId)
        .then(() => {
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          setIsChange(!isChange);
          getDriverAbsenceData();
          setCreateLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setCreateLoading(false);
        });
    }
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={driverAbsence?.total}
        totalLength={driverAbsence?.data?.length}
        lastPage={driverAbsence?.last_page}
        currentPage={driverAbsence?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => {
          setDriverAbsence(data);
        }}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onPageChange = (event, page) => {
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filter = { page, itemsPerPage: rowPerPage, year: selectedYear, sortBy: 'desc', staff_id: id };
    staffAbsenceData(filter)
      .then((res) => {
        setDriverAbsence(res?.absence_data);
      })
      .finally(() => setLoading(false));
  };

  const onChangeTab = (tabValue) => {
    setLoading(true);
    let currentTabData = {};
    if (tabValue.value === 1) {
      // show all data
      currentTabData = allAbsence;
    } else {
      const reqFilterData = allAbsence?.data?.filter((req) => req?.type === tabValue.key);
      currentTabData = { ...driverAbsence, data: JSON.parse(JSON.stringify(reqFilterData)) };
    }
    setTimeout(() => {
      setDriverAbsence(currentTabData);
      setTab(tabValue);
      setLoading(false);
    }, 500);
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    setLoading(true);
    const payload = { page: 1, itemsPerPage: rowPage, year: selectedYear, sortBy: 'desc', staff_id: id };
    if (debouncedSearch) {
      payload.query = debouncedSearch;
    }
    staffAbsenceData(payload)
      .then((res) => {
        setDriverAbsence({ ...res, data: res?.absence_data?.data });
      })
      .finally(() => setLoading(false));
  };

  const removeAbsence = (item) => {
    const { id } = item;
    staffRemoveAbsenceData(id)
      .then(() => {
        getDriverAbsenceData();
        onSuccess('Attendance has been deleted successfully');
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setCreateLoading(false);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'start_date',
        title: <MaterialTableSort title='Date' field='start_date' />,
        render: (rowData) => {
          return rowData?.start_date ? (
            <div className='sub-wraper'>
              <div
                className='date-container'
                style={{
                  backgroundColor: use('#F0F2F7', palette.dark600),
                  color: use(palette.gray600, palette.gray400),
                }}
              >
                <p className='sub-text'>{rowData?.start_date}</p>
              </div>
              <span>-</span>
              <div
                className='date-container'
                style={{
                  backgroundColor: use('#F0F2F7', palette.dark600),
                  color: use(palette.gray600, palette.gray400),
                }}
              >
                <p className='sub-text'>{rowData?.end_date}</p>
              </div>
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'reason_title',
        title: <MaterialTableSort title='REASON' field='reason_title' />,
        render: (rowData) => (
          <p
            className='table-data-text'
            style={{
              color: use(palette.gray700, palette.gray200),
              whiteSpace: 'break-spaces',
              minWidth: rowData?.reason_title?.length > 40 ? '440px' : '',
            }}
          >
            {rowData?.reason_title}
          </p>
        ),
      },
      {
        field: 'days',
        title: <MaterialTableSort title='DAYS' field='days' />,
        render: (rowData) => {
          const a = moment(rowData?.start_date);
          const b = moment(rowData?.end_date);
          const day = moment.duration(b.diff(a)).asDays();
          return (
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {day === 0 ? '1 Day' : day ? `${day + 1} Days` : '-'}
            </p>
          );
        },
      },
      {
        field: 'type',
        title: <MaterialTableSort title='TYPE' field='type' />,
        render: (rowData) => (
          <div
            className='warning-status-container'
            style={{
              backgroundColor: use(
                absencseStatusColor[rowData?.type]?.bgColor,
                absencseStatusColor[rowData?.type]?.darkBgColor
              ),
            }}
          >
            <p
              className='warning-status-text-absence'
              style={{
                color: use(absencseStatusColor[rowData?.type]?.color, absencseStatusColor[rowData?.type]?.color),
              }}
            >
              {rowData.absence_type?.name?.toUpperCase() || rowData.type?.toUpperCase()}
            </p>
          </div>
        ),
      },
      {
        field: 'absence_details',
        title: <MaterialTableSort title='STAFF NOTES' field='absence_details' />,
        render: (rowData) => (
          <p
            className='table-data-text'
            style={{
              color: use(palette.gray700, palette.gray200),
              whiteSpace: 'break-spaces',
              minWidth: rowData?.absence_details?.length > 40 ? '440px' : '',
            }}
          >
            {rowData?.absence_details || '-'}
          </p>
        ),
      },
      {
        field: 'absence_document',
        title: <MaterialTableSort title='SUPPORTING DOCUMENT' field='absence_document' />,
        render: (rowData) =>
          rowData?.absence_document ? (
            <p className='table-view-text mb-0'>Uploaded</p>
          ) : (
            <p className='table-view-text mb-0'>-</p>
          ),
      },
      {
        field: 'added_by_user',
        title: <MaterialTableSort title='Reviewed By' field='added_by_user' />,
        render: (rowData) => (
          <div className='more-dowpdown-container' onClick={(e) => e.stopPropagation()}>
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.added_by_user?.first_name
                ? `${rowData?.added_by_user?.first_name} ${rowData?.added_by_user?.last_name}`
                : '-'}
            </p>
          </div>
        ),
      },
      {
        field: '',
        render: (rowData) => (
          <RowActions
            onEdit={() => {
              setUpdatedData(rowData);
              setUpdateAbsenceModalShow(true);
            }}
            onDelete={() => removeAbsence(rowData)}
          />
        ),
      },
    ];
  }, [driverAbsence]);

  if (loading) {
    return <TablePreLoader />;
  }

  return (
    <div
      className='absence-table-container absence-staff driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-header'>
          <p className='heading' style={{ color: use(palette.gray700, palette.gray200) }}>
            Attendance
          </p>
          <div className='header-modal-container'>
            <PrimaryBtn icon={add} title='Add Attendance' onClick={() => setModalShow(true)} />
            <img src={moreInfo} alt='' onClick={() => setUpdateModalShow(true)} />
          </div>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <Search search={search} onChange={setSearch} className='absence-search-input' />
          <Autocomplete
            width='120px'
            options={(otherData?.years || []).map((item) => item.toString())}
            value={selectedYear}
            onChange={(e, value) => {
              setSelectedYear(value);
              getDriverAbsenceData(null, null, value);
            }}
          />
        </div>
      </div>
      <div className='table-wrap'>
        <SwitchRadio
          name='tableTopTabMenu'
          items={tabs}
          value={tab}
          type='tab'
          onChange={(v) => onChangeTab(v)}
          plus={false}
        />
        <div className='document-details sub-table-container'>
          <MaterialTableWrapper
            data={driverAbsence?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
            onRowClick={(e, rowData) => {
              setUpdatedData(rowData);
              setUpdateAbsenceModalShow(true);
            }}
          />
        </div>
        {modalShow && (
          <StaffAbsenceModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            loading={createLoading}
            title='Add Attendance'
            isupdate={false}
            driverId={id}
            createAbsence={createAbsence}
            onSuccess={onSuccess}
          />
        )}

        {updateAbsenceModalShow && (
          <StaffAbsenceModal
            show={updateAbsenceModalShow}
            onHide={() => setUpdateAbsenceModalShow(false)}
            loading={createLoading}
            title='Update Attendance'
            isupdate
            updateData={updateData}
            driverId={id}
            createAbsence={createAbsence}
            onSuccess={onSuccess}
          />
        )}

        {!!updateModalShow && (
          <AbsenceAllowanceModal
            show={updateModalShow}
            onHide={() => setUpdateModalShow(false)}
            absenceAllowance={absence_allowance}
            updateAbsenceAllowanceData={updateAbsenceAllowanceData}
            onSuccess={onSuccess}
            absLoading={absLoading}
            userType='staff'
          />
        )}
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </div>
  );
};

export default AbsenceTable;
