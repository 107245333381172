import React from 'react';
import moment from 'moment';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import packageJson from '../../../package.json';
import { SFooter } from './PublicFooter.styles';

const PublicFooter = () => {
  return (
    <SFooter>
      <div className='d-flex align-items-center gap-2'>
        <img src={truckinLogo} alt='Truckin digital logo' width={32} height={32} />
        <Typography variant='s2' style={{ color: palette.gray700 }} className='powered-by'>
          Powered by{' '}
          <a className='blue-link' href='https://www.truckindigital.com'>
            Truckin Digital
          </a>
        </Typography>
      </div>
      <div className='footer-right'>
        <Typography variant='c2' style={{ color: palette.gray400 }}>
          © {moment().year()}
        </Typography>
        <div className='vertical-divide' />
        <Typography variant='c2' style={{ color: palette.gray400 }}>
          All rights reserved
        </Typography>
        <div className='vertical-divide' />
        <Typography variant='c2' style={{ color: palette.gray400 }}>
          v{packageJson.version}
        </Typography>
      </div>
    </SFooter>
  );
};

export default PublicFooter;
