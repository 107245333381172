import axios from 'services/axios';

export async function getOfferedShipmentsCount() {
  const { data } = await axios.get(`/customer/shipments/offered-shipments-count`);
  return data;
}

export async function getOfferedShipments(params, signal) {
  const { data } = await axios.get(`/customer/shipments/offered-shipments`, { params, signal });
  return data;
}

export async function getShipmentBids(params) {
  const { data } = await axios.get(`customer/shipments/offered-shipments/bids`, { params });
  return data;
}

export async function revertShipmentOffer(id, params) {
  const { data } = await axios.delete(`/customer/shipments/shipment-offers/revert-offers/${id}`, { params });
  return data;
}

export async function revertShipmentOffers(params) {
  const { data } = await axios.delete(`/customer/shipments/shipment-offers/revert-carrier-offers`, { params });
  return data;
}

export async function revertAllOffers(params) {
  const { data } = await axios.delete(`/customer/shipments/shipment-offers/revert-offers`, { params });
  return data;
}

export async function cancelShipment(body) {
  const { data } = await axios.put(`/customer/shipments/cancel`, body);
  return data;
}

export async function createBid(body, headers) {
  const { data } = await axios.post(`/carrier/shipment/offer/bid`, body, { headers });
  return data;
}

export async function getGroups() {
  const { data } = await axios.get('/group');
  return data;
}

export async function getVehicles() {
  const { data } = await axios.get(`/vehicles`);
  return data;
}

export async function getTrailers() {
  const { data } = await axios.get('/trailers');
  return data;
}

export async function getDeclineReasons(params, headers) {
  const { data } = await axios.get('/carrier/shipment/decline-reasons', { params, headers });
  return data;
}

export async function awardBid(bid_id, body) {
  const { data } = await axios.post(`/customer/shipments/offered-shipments/bids/${bid_id}/award-bid`, body);
  return data;
}

export async function acceptAward(body, headers) {
  const { data } = await axios.post(`/carrier/shipment/award-acceptance`, body, { headers });
  return data;
}

export async function retractBid(params, headers) {
  const { data } = await axios.delete(`/carrier/shipment/revert-bid`, { params, headers });
  return data;
}

export async function extendExpiration(shipmentId, body) {
  const { data } = await axios.post(`/customer/shipments/${shipmentId}/extend-expiration`, body);
  return data;
}

export async function declineBid(bidId, body) {
  const { data } = await axios.post(`/customer/shipments/offered-shipments/bids/${bidId}/decline-bid`, body);
  return data;
}

export async function revertAward(bidId, body) {
  const { data } = await axios.post(`/customer/shipments/offered-shipments/bids/${bidId}/award-revert`, body);
  return data;
}

export async function updatePreferBid(carrierId, body) {
  const { data } = await axios.post(`/customer/carrier/${carrierId}`, body);
  return data;
}
