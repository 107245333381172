import { number, object, string } from 'yup';
import { ACCOUNT_TYPE } from 'utils/constants';

export const validationSchema = object().shape({
  account: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  code: string().trim().required('Required').nullable(),
  title: string().trim().required('Required').nullable(),
  desc: string().trim().required('Required').nullable(),
  tax_percentage: string().when(['is_taxable', 'type_id'], {
    is: (is_taxable, type_id) => is_taxable && type_id === ACCOUNT_TYPE.INCOME,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  tax_account: object().when(['is_taxable', 'type_id'], {
    is: (is_taxable, type_id) => is_taxable && type_id === ACCOUNT_TYPE.INCOME,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});
