import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  height: calc(100vh - 320px);
  max-height: 1000px;
  margin-top: 18px;
`;

export const STitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 6px;

  :hover {
    background-color: ${palette.gray0};
  }
`;

export const SCustomHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navigation-button {
    margin-top: -12px;
    padding: 0;
    color: ${palette.gray500};
  }

  select {
    font-size: 14px;
    appearance: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 4px;
    padding-right: 24px;
  }
`;
