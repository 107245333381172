import React, { Fragment, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/equipment/AddpointIcon.svg';

import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import { palette } from 'utils/constants';
import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';
import PayeeAutocomplete from 'pages/Accounting/Accounts/components/PayeeAutocomplete';
import { SActions } from 'pages/Accounting/Accounts/RightSection/RightSection.styles';
import useForm from 'hooks/useForm';
import Contains from './components/Contains';
import { containTypes, getInitialValues, matchPatterns } from './Rules.data';
import { validationSchema } from './validationSchema';
import { SAddAction, STableRow } from './Rules.styles';

const RulesRow = ({
  rule,
  onCreate,
  updateExistingRule,
  onNewRowDelete,
  onExistingRuleDelete,
  accounts,
  payees,
  loadingCreate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddPayee, setOpenAddPayee] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [showMemo, setShowMemo] = useState(false);

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(rule),
    onSubmit: onCreate,
    validationSchema,
    enableReinitialize: true,
  });
  const { isNew, account, payee, contains, edit, contains_type, match_pattern, memo } = values || {};
  const editMode = isNew || edit;

  const onMouseEnter = () => {
    if (isNew) {
      return;
    }
    handleChange('edit', true);
  };

  const onMouseLeave = (preventUpdate) => {
    if (isNew) {
      return;
    }
    handleChange('edit', false);

    if (!preventUpdate && values.contains?.length && values.account && values.payee) {
      updateExistingRule(values);
    }
  };

  const onAddContainsClick = () => {
    handleChange(`contains[${contains.length}]`, { isNew: true, value: '' });
  };

  const onContainsInputChange = (e, index) => {
    handleChange(`contains[${index}]`, { isNew: true, value: e.target.value });
  };

  const onContainsInputBlur = (index) => {
    handleChange(`contains[${index}]`, contains[index].value ? { ...contains[index], isNew: false } : contains[index]);
  };

  const onContainsDelete = (index) => {
    const newContains = contains.filter((el, i) => i !== index);
    handleChange('contains', newContains);
  };

  const onAccountChange = (value) => {
    handleChange('account', value);
    setUpdateIndex(Date.now());
    onMouseLeave(true);
  };

  const handlePayeeChange = (value) => {
    handleChange('payee', value);
    if (value.account || value.account_id) {
      const account = value.account || accounts.find((i) => i.id === value.account_id);
      if (account) {
        handleChange('account', account || null);
      }
    }
    setUpdateIndex(Date.now());
    onMouseLeave(true);
  };

  const onContainsTypeChange = (value) => {
    handleChange('contains_type', value);
    setUpdateIndex(Date.now());
    onMouseLeave(true);
    handleChange(`contains`, []);
  };

  const onMatchPatternChange = (value) => {
    handleChange('match_pattern', value);
    setUpdateIndex(Date.now());
    onMouseLeave(true);
  };

  const onAddNewPayeeSuccess = (newPayee) => {
    handlePayeeChange(newPayee);
  };

  useEffect(() => {
    if (
      !isNew &&
      updateIndex &&
      values.contains?.length &&
      values.account &&
      values.payee &&
      values.contains_type &&
      values.match_pattern
    ) {
      updateExistingRule(values);
    }
  }, [updateIndex]);

  return (
    <>
      <STableRow onMouseEnter={onMouseEnter} onMouseLeave={() => onMouseLeave(false)}>
        <td>
          <div className='row-item-wrapper'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              If
            </Typography>
            {editMode ? (
              <div className='ms-1 me-1'>
                <Autocomplete
                  width='130px'
                  size='small'
                  name='contains_type'
                  options={containTypes}
                  value={contains_type}
                  onChange={(e, val) => onContainsTypeChange(val)}
                  error={touchedErrors.contains_type}
                />
              </div>
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {contains_type}
              </Typography>
            )}
            {editMode ? (
              <div className='ms-1 me-1'>
                <Autocomplete
                  width='130px'
                  size='small'
                  name='match_pattern'
                  options={matchPatterns}
                  value={match_pattern}
                  onChange={(e, val) => onMatchPatternChange(val)}
                  error={touchedErrors.match_pattern}
                />
              </div>
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {match_pattern}
              </Typography>
            )}
            {contains.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {index > 0 && (!editMode ? !item.isNew : true) && (
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    or
                  </Typography>
                )}
                <Contains
                  key={item.id}
                  data={item}
                  type={contains_type}
                  editMode={editMode}
                  onChange={(e) => onContainsInputChange(e, index)}
                  onBlur={() => onContainsInputBlur(index)}
                  onDelete={() => onContainsDelete(index)}
                  error={touchedErrors.contains?.[index]?.value}
                />
              </Fragment>
            ))}
            {editMode && (
              <>
                {contains.length > 0 && (
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    or
                  </Typography>
                )}
                <SAddAction onClick={onAddContainsClick}>
                  <PlusIcon />
                </SAddAction>
              </>
            )}
          </div>
        </td>
        <td>
          <div className='row-item-wrapper'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              Itemize as
            </Typography>
            {editMode ? (
              <div className='ms-1 me-1'>
                <Autocomplete
                  width='180px'
                  size='small'
                  name='account'
                  placeholder='Select Account'
                  labelKey='account_name'
                  options={accounts}
                  value={account}
                  onChange={(e, val) => onAccountChange(val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  error={touchedErrors.account}
                />
              </div>
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {account.account_name} ({account.account_code})
              </Typography>
            )}
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              and rename Payee to
            </Typography>
            {editMode ? (
              <div className='ms-1 me-1'>
                <PayeeAutocomplete
                  width='180px'
                  options={payees}
                  value={payee}
                  onChange={(e, val) => handlePayeeChange(val)}
                  onAddNew={setOpenAddPayee}
                  error={touchedErrors.payee}
                />
              </div>
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {payee.name}
              </Typography>
            )}

            {!editMode && !!memo && (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                and set memo to {memo}
              </Typography>
            )}
            {editMode &&
              (showMemo || !!memo ? (
                <div className='d-flex align-items-center gap-2'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    and set memo to
                  </Typography>
                  <Input
                    width='130px'
                    size='small'
                    value={memo}
                    onChange={(e) => handleChange(`memo`, e.target.value)}
                  />
                </div>
              ) : (
                <SAddAction onClick={() => setShowMemo(true)}>
                  <PlusIcon />
                </SAddAction>
              ))}
          </div>
        </td>
        <td>
          {rule.isNew ? (
            <SActions>
              {loadingCreate ? (
                <span className='loading-wrapper'>
                  <CircularProgress size={16} />
                </span>
              ) : (
                <span className='action-wrapper' onClick={handleSubmit}>
                  <CheckIcon width={11} height={11} />
                </span>
              )}
              <span className='action-wrapper' onClick={() => onNewRowDelete()}>
                <DeleteIcon width={11} height={11} fill={palette.red500} />
              </span>
            </SActions>
          ) : (
            <div className='actions-wrapper' style={{ width: 62 }}>
              <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
                <li
                  className='delete-action'
                  onClick={() => {
                    setAnchorEl(null);
                    onExistingRuleDelete();
                  }}
                >
                  Delete
                </li>
              </ThreeDotActions>
            </div>
          )}
        </td>
      </STableRow>
      {!!openAddPayee && (
        <AddPayee
          open={!!openAddPayee}
          name={openAddPayee}
          onClose={() => setOpenAddPayee(false)}
          onSuccess={onAddNewPayeeSuccess}
        />
      )}
    </>
  );
};

export default RulesRow;
