import React, { useEffect, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import XIcon from 'assets/icons/x.png';

const MutedComp = ({ setMuted, isMuted, activeChannel, updateMutedStatus, setUpdateMutedStatus }) => {
  const { client } = useChatContext();
  const [mutedChannel, setMutedChannel] = useState([]);
  const [channelStatus, setchannelStatus] = useState(null);

  const getMutedChannels = async () => {
    const mutedchannels = await client.queryChannels({ muted: true });
    const status = activeChannel?.muteStatus();
    setchannelStatus(status);
    setMutedChannel(mutedchannels);
  };

  const unMute = async (c) => {
    const res = await c.unmute();
    if (res) {
      getMutedChannels();
    }
    if (c.cid === activeChannel.cid) {
      setUpdateMutedStatus(Date.now());
    }
  };
  useEffect(() => {
    if (isMuted || activeChannel !== null) {
      getMutedChannels();
    }
  }, [isMuted, activeChannel, updateMutedStatus]);

  return (
    <div
      className='group_members'
      style={{
        width: isMuted ? '25%' : '0px',
        minWidth: isMuted ? '300px' : '0px',
        padding: isMuted ? '' : '0px',
      }}
    >
      <p className='group_member_heading'>
        Channel Status
        <img src={XIcon} alt='close' onClick={() => setMuted(false)} />
      </p>
      <div className='group_user_list'>
        {channelStatus !== null && (
          <div className='scheduled_msg'>
            <p className='scheduled_text' style={{ fontSize: '14px', marginBottom: '10px' }}>
              Active channel :<span style={{ fontWeight: 'bold', marginLeft: '10px' }}>{activeChannel.data.name}</span>
            </p>
            <p className='scheduled_text' style={{ fontSize: '14px', marginBottom: '10px' }}>
              Chat closed by :
              <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>{activeChannel.data.chat_closed_by}</span>
            </p>
            <p className='scheduled_text' style={{ fontSize: '14px', marginBottom: '10px' }}>
              {' '}
              Channel status :
              <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                {channelStatus.muted ? 'Muted' : 'Unmuted'}
              </span>
            </p>
          </div>
        )}
        <p className='scheduled_time' style={{ fontSize: '14px', margin: '20px 0px' }}>
          All muted channels ({mutedChannel.length})
        </p>
        {mutedChannel.map((c) => (
          <div className='scheduled_msg'>
            <p className='scheduled_text' style={{ fontSize: '14px', marginBottom: '10px' }}>
              {c.data.name}{' '}
            </p>
            <span
              className='download-attechment'
              style={{ fontSize: '12px', cursor: 'pointer' }}
              onClick={() => unMute(c)}
            >
              Unmute
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MutedComp;
