import { number, object } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  user: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  start_time: validator.pastRequiredDate(),
  end_time: validator.pastRequiredDate(),
});

export const validationSchemaTime = object().shape({
  start_time: validator.pastRequiredDate(),
  end_time: validator.pastRequiredDate(),
});
