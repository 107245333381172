import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette, TRUCK_TYPES } from 'utils/constants';
import { cargoOnboardIconMapper } from 'components/StopPoint/StopPoint.data';
import RowActions from './RowActions';
import styles from './EquipmentHistory.module.css';

export const useColumns = ({ sort, sortingQuery, setEquipmentLogsData }) => {
  const navigate = useNavigate();
  const { convertToCustomerTime } = useDateFormat();
  const { historyEquipmentType } = useSelector((state) => state?.stopPoints);

  return [
    {
      field: 'equipment_id',
      title: <ColumnHeader title='EQUIPMENT ID' field='equipment_id' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const { equipment_id, equipment_type, length, truck_type } = rowData.equipment || {};

        return (
          <div style={{ display: 'flex', gap: 8 }}>
            {equipment_id && (
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                {equipment_id || ''}
              </Typography>
            )}
            {length && (
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                {length?.length || ''}
                {length?.unit || ''}
              </Typography>
            )}
            {equipment_type?.title && (
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                {equipment_type?.title || ''}
                {!!truck_type && <span> ({TRUCK_TYPES[truck_type]})</span>}
              </Typography>
            )}
          </div>
        );
      },
    },
    ...(historyEquipmentType === 1
      ? [
          {
            field: 'driver',
            title: <ColumnHeader title='DRIVER' field='driver' />,
            render: (row) =>
              row.driver ? (
                <UserInfo
                  data={{
                    ...row.driver,
                    name: `${row.driver.fname} ${row.driver.lname}`,
                    status: row.driver.status_data?.driver_status,
                    user_type: 'driver',
                  }}
                />
              ) : (
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  -
                </Typography>
              ),
          },
        ]
      : []),
    ...(historyEquipmentType === 1
      ? [
          {
            field: 'hooked_to',
            title: <ColumnHeader title='HOOKED TO' field='hooked_to' />,
            render: (row) =>
              row.hooked_to ? (
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  {row.hooked_to.equipment_id} {row.hooked_to.length?.length}
                  {row.hooked_to.length?.unit} {row.hooked_to.equipment_type?.title}
                </Typography>
              ) : (
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  -
                </Typography>
              ),
          },
        ]
      : []),
    {
      field: 'equipment_type',
      title: <ColumnHeader title='CUSTOMER' field='customer' />,
      render: (rowData) => {
        const { billing_customer } = rowData?.shipment_billing || {};
        return billing_customer ? (
          <div style={{ display: 'flex' }} className={styles.customerWrapper}>
            <Typography
              variant='s2'
              style={{ color: palette.gray700 }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/customer-profile/${billing_customer?.id}`);
              }}
            >
              {billing_customer?.customer_name}
            </Typography>
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'shipment_id',
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' />,
      render: (rowData) => {
        const shipment_id = rowData?.shipment_id;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {shipment_id || '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'cargo_onboard',
      title: <ColumnHeader title='CARGO ONBOARD' field='cargo_onboard' />,
      render: (rowData) => {
        const cargo_onboard = rowData?.cargo_onboard_type?.title;
        const cargo_id = rowData?.cargo_onboard_id;
        return (
          <div className='d-flex align-items-center'>
            {cargoOnboardIconMapper[cargo_id]}
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {cargo_onboard || '-'}
            </Typography>
          </div>
        );
      },
    },
    ...(historyEquipmentType === 2
      ? [
          {
            field: 'dropped_by',
            title: <ColumnHeader title='DROPPED BY' field='dropped_by' />,
            render: (row) =>
              row.dropped_by_vehicle ? (
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  {row.dropped_by_vehicle.equipment_id} {row.dropped_by_vehicle.equipment_type?.title}{' '}
                  {row.dropped_by_vehicle.equipment_type?.title === 'Truck' && '(Sleeper)'}
                </Typography>
              ) : (
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  -
                </Typography>
              ),
          },
        ]
      : []),
    {
      field: 'arrived_date',
      title: <ColumnHeader title='ARRIVED DATE' field='arrived_date' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const arrived_date = rowData?.arrived_date;
        return (
          !!arrived_date && (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {arrived_date ? convertToCustomerTime(arrived_date, null, true) : '-'}
              </Typography>
            </div>
          )
        );
      },
    },
    {
      field: 'departed_date',
      title: <ColumnHeader title='DEPARTED DATE' field='departed_date' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const departed_date = rowData?.departed_date;
        return (
          !!departed_date && (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {departed_date ? convertToCustomerTime(departed_date, null, true) : '-'}
              </Typography>
            </div>
          )
        );
      },
    },
    {
      field: 'updated_by',
      title: <ColumnHeader title='UPDATED BY' field='updated_by' />,
      render: (rowData) => {
        const updated_by = rowData?.updated_by;
        const updated_by_type = rowData?.updated_by_type;
        return (
          <div style={{ display: 'flex' }}>
            <div>
              {updated_by_type === 'App\\User' ? (
                <UserInfo
                  size='32px'
                  statusSize='7px'
                  data={{
                    ...(updated_by || {}),
                    image: updated_by?.profile_logo,
                    name: `${updated_by?.first_name} ${updated_by?.last_name}`,
                    user_type: 'staff',
                  }}
                />
              ) : updated_by_type === 'App\\Driver' ? (
                <UserInfo
                  size='32px'
                  statusSize='7px'
                  data={{
                    ...(updated_by || {}),
                    name: `${updated_by?.fname} ${updated_by?.lname}`,
                    user_type: 'driver',
                  }}
                />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <div style={{ height: 30 }}>
                    <figure
                      style={{
                        display: 'flex',
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                      }}
                    >
                      <img
                        style={{
                          display: 'flex',
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                        }}
                        src={truckinLogo}
                        alt='img'
                      />
                    </figure>
                  </div>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    System
                  </Typography>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'duration',
      title: <ColumnHeader title='AGING' field='duration' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const duration_days = rowData?.duration_days;
        const duration_hours = rowData?.duration_hours;
        const duration_minutes = rowData?.duration_minutes;
        return (
          <div style={{ display: 'flex', gap: 6 }}>
            {(!!duration_days || duration_days === 0) && (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {duration_days}d
              </Typography>
            )}
            {(!!duration_hours || duration_hours === 0) && (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {duration_hours}h
              </Typography>
            )}
            {(!!duration_minutes || duration_minutes === 0) && (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {duration_minutes}m
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: '',
      render: (rowData) => (
        <RowActions
          onViewEquipment={() =>
            navigate(
              `/equipment-profile/${rowData.equipment?.equipment_type_id === 1 ? 'vehicle' : 'trailer'}/${
                rowData.equipment?.id
              }`
            )
          }
          onViewLog={() => setEquipmentLogsData(rowData.equipment)}
        />
      ),
    },
  ];
};
