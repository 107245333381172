import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getCustomerContactBookType } from 'Api/Customers';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import styles from './TypeChangeModal.module.css';

const TypeChangeModal = ({ onSubmit, setShowTypeModal }) => {
  const [types, setTypes] = useState(null);

  const { use } = useTheme();

  const handleSubmit = (values) => {
    onSubmit(values);
  };
  useEffect(() => {
    getCustomerContactBookType()
      .then((res) => {
        setTypes(res.data);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ submitForm }) => {
        return (
          <Form>
            <div className={styles.container}>
              <div className={styles.label_wrapper}>
                <span className={styles.label_required}>Type</span>
                <Field
                  name='type'
                  options={types}
                  styles={{
                    height: 32,
                    width: 400,
                    marginTop: 8,
                  }}
                  component={CustomSelect}
                  defaultText='Management'
                  keyName='id'
                  labelName='title'
                />
              </div>
            </div>

            <div className='customFooter' style={{ background: use(palette.white, palette.dark800) }}>
              <div className='footer_buttons'>
                <CustomButton
                  type='secondary'
                  onClick={() => setShowTypeModal(false)}
                  title='Cancel'
                  styleButton={{ margin: 10, padding: '6px 12px' }}
                  styleTitle={{
                    fontSize: 12,
                    marginTop: 1,
                  }}
                />
                <CustomButton
                  type='primary'
                  onClick={submitForm}
                  title='Update'
                  styleButton={{ margin: '10px 4px 10px 0', padding: '6px 12px' }}
                  styleTitle={{
                    fontSize: 12,
                    marginTop: 1,
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TypeChangeModal;
