import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { CircularProgress } from '@material-ui/core';
import { getAlphabet } from 'utils/constants';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import AddressAutocomplete from 'common/AddressAutocomplete';
import CustomButton from 'components/CustomButton/CustomButton';
import { ReactComponent as SearchIcon } from 'assets/icons/drivers/Search.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as ArrowsIcon } from 'assets/icons/two-way.svg';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from 'components/Typography';
import Autocomplete from 'common/Autocomplete';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import styles from './CustomStopPoint.module.css';

const CustomStopPoint = ({
  addStopPoint,
  addressValues,
  setAddressVales,
  stopPointValues,
  setStopPointValues,
  loadingStopPoints,
  setLoadingStopPoints,
  clearRoute,
  routeInstance,
  routeValues,
  setRouteValues,
}) => {
  const showToaster = useShowToaster();
  const defaultValue = {
    address1: '',
    country_id: '',
    city: '',
    state: '',
    zipcode: '',
  };

  const initialValue = stopPointValues || { selectStopPoints: [{ ...defaultValue }] };
  let filterByData = '';
  const [errors, setErrors] = useState([]);

  const handleSubmit = async (values) => {
    const emptyValues = [];
    for (let i = 0; i < values.selectStopPoints.length; i++) {
      if (Object.values(values.selectStopPoints[i]).every((value) => value === '')) {
        emptyValues.push(i);
      }
    }
    setErrors(emptyValues);
    if (!emptyValues.length) {
      setLoadingStopPoints(true);
      const allCords = [];
      for (const value of values.selectStopPoints) {
        const res = await getGeoLocation(value);
        if (res) {
          allCords.push(res);
        }
      }
      if (allCords.length > 0) {
        addStopPoint(allCords, routeValues);
        setAddressVales(allCords);
      } else {
        showToaster({ type: 'warning', message: 'No valid coordinates were returned.' });
        setLoadingStopPoints(false);
      }
    }
  };
  const getGeoLocation = async (value) => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          addr: value.address1,
          city: value.city,
          state: value.state,
          zip: value.zipcode,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            filterByData = response?.map((el) => {
              return {
                latitude: el.Coords?.Lat,
                longitude: el.Coords?.Lon,
                city: `${el?.Address?.City ? el?.Address?.City : ''}`,
                state: `${el?.Address?.State ? el?.Address?.State : ''}`,
                zipcode: `${el?.Address?.Zip ? el?.Address?.Zip : ''}`,
                streetAddress: `${el?.Address?.StreetAddress ? el?.Address?.StreetAddress : ''}`,
              };
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          resolve(null);
        },
      });
    });
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ ...initialValue }}>
      {({ validateField, getFieldHelpers, arrayHelpers, ...props }) => (
        <AddCustomForm
          {...props}
          validateField={validateField}
          getFieldHelpers={getFieldHelpers}
          initialValue={initialValue}
          defaultValue={defaultValue}
          setStopPointValues={setStopPointValues}
          loadingStopPoints={loadingStopPoints}
          clearRoute={clearRoute}
          routeInstance={routeInstance}
          errors={errors}
          routeValues={routeValues}
          setRouteValues={setRouteValues}
          setErrors={setErrors}
          addStopPoint={addStopPoint}
          addressValues={addressValues}
        />
      )}
    </Formik>
  );
};

const options = [
  { name: 'Practical', value: '0' },
  { name: 'Shortest', value: '1' },
  { name: 'Fastest', value: '2' },
];

function AddCustomForm({
  values,
  submitForm,
  setFieldValue,
  defaultValue,
  setStopPointValues,
  loadingStopPoints,
  clearRoute,
  routeInstance,
  errors,
  setErrors,
  addStopPoint,
  addressValues,
  routeValues,
  setRouteValues,
}) {
  const {
    values: mapValues,
    handleChange: handleMapValuesChange,
    resetForm,
  } = useForm({
    initialValues: {
      route_type: routeValues.route_type || options[0],
      highway_only: routeValues?.highway_only,
      open_borders: routeValues?.open_borders,
      haz_mat: routeValues?.haz_mat,
      avoid_tolls: routeValues?.avoid_tolls,
    },
  });

  const onAddressSelect = (data, index) => {
    setFieldValue(`selectStopPoints[${index}].state`, data.state?.short_name);
    setFieldValue(`selectStopPoints[${index}].city`, data.city?.name);
  };

  const changeAddress = ({ address, zip, formatted_address }, index) => {
    setFieldValue(`selectStopPoints[${index}].address1`, formatted_address || address);
    setFieldValue(`selectStopPoints[${index}].zipcode`, zip || values.zipcode);
  };

  const handleRemoveClick = (index) => {
    setFieldValue(
      'selectStopPoints',
      values.selectStopPoints.filter((item, i) => i !== index)
    );
  };

  const clear = () => {
    setFieldValue('selectStopPoints', [{ ...defaultValue }]);
    clearRoute();
    resetForm();
  };

  useEffect(() => {
    setStopPointValues(values);
  }, [values]);

  return (
    <Form>
      <div className={styles.general_wrapper}>
        <div className={styles.general_geoFencing}>
          <div className={styles.change_section}>
            {values.selectStopPoints.map((item, index) => {
              return (
                <div className='d-flex flex-column gap-1' key={item.id}>
                  <div className={styles.center}>
                    <div className={styles.MapPointsMainInputStartText}>Stop {getAlphabet(index)}</div>
                    <div className={styles.label_wrapper}>
                      <AddressAutocomplete
                        id='address1'
                        name='address1'
                        placeholder='Search Truckin Digital Maps'
                        onSuccess={(data) => onAddressSelect(data, index)}
                        changeAddress={(data) => changeAddress(data, index)}
                        onChange={(e) => {
                          setFieldValue(`selectStopPoints[${index}].address1`, e.target.value);
                          setErrors([]);
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        value={item.address1}
                        options={{
                          types: ['geocode', 'establishment'],
                          componentRestrictions: { country: ['us', 'ca', 'mx'] },
                        }}
                      />
                    </div>
                    <div className={styles.arrows_icon}>
                      <ArrowsIcon />
                    </div>
                    <CustomInput
                      name='zipcode'
                      placeholder='Zip Code'
                      value={item.zipcode}
                      onChange={(e) => {
                        setFieldValue(`selectStopPoints[${index}].zipcode`, e.target.value);
                        setErrors([]);
                      }}
                      style={{
                        width: 100,
                        borderRadius: 6,
                        height: 32,
                        padding: '6px 12px',
                        marginTop: 0,
                        boxShadow:
                          '0 1px 1px rgb(0 0 0 / 10%), 0 0 0 1px rgb(70 79 96 / 16%), 0 2px 5px rgb(89 96 120 / 10%)',
                      }}
                    />

                    <div className='d-flex gap-3'>
                      {index !== 0 && (
                        <CustomButton
                          type='secondary'
                          styleButton={{
                            height: 32,
                            padding: '2px 10px',
                            margin: 0,
                          }}
                          title='-'
                          styleTitle={{ marginTop: '-4px', fontSize: '16px' }}
                          onClick={() => handleRemoveClick(index)}
                        />
                      )}
                    </div>
                  </div>
                  {!!errors.length && errors.includes(index) && (
                    <div className={styles.errorMessage}>This stop point is missing address or zip code.</div>
                  )}
                </div>
              );
            })}
            <div className='d-flex gap-3 justify-content-end' style={{ paddingRight: 10 }}>
              {loadingStopPoints ? (
                <CircularProgress style={{ height: '18px', width: '18px', margin: '5px 10px' }} />
              ) : (
                <CustomButton
                  type='secondary'
                  styleButton={{
                    height: 32,
                    padding: '2px 10px',
                    margin: 0,
                  }}
                  rightIcon={<SearchIcon />}
                  styleTitle={{ marginTop: '-2px', fontSize: '16px' }}
                  onClick={() => submitForm()}
                />
              )}
              <CustomButton
                type='secondary'
                styleButton={{
                  height: 32,
                  padding: '2px 10px',
                  margin: 0,
                }}
                rightIcon={<PlusIcon fill='#868FA0' />}
                styleTitle={{ marginTop: '-4px', fontSize: '16px' }}
                onClick={() => setFieldValue(`selectStopPoints[${values.selectStopPoints.length}]`, defaultValue)}
              />

              {!!routeInstance && (
                <CustomButton
                  type='secondary'
                  styleButton={{
                    height: 32,
                    padding: '2px 10px',
                    margin: 0,
                  }}
                  title='Clear Route'
                  styleTitle={{ fontSize: '12px' }}
                  onClick={clear}
                />
              )}
              <div />
            </div>
            <div className='pt-2 mt-auto'>
              <Typography variant='s1'>Route Options</Typography>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <div className='d-flex'>
                <div className='d-flex align-items-center ha'>
                  <CustomCheckbox
                    checked={!!mapValues.open_borders}
                    onChange={(checked) => {
                      handleMapValuesChange('open_borders', checked);
                      setRouteValues((prevState) => ({ ...prevState, open_borders: checked }));
                      !!addressValues.length && addStopPoint(addressValues, { ...mapValues, open_borders: checked });
                    }}
                  />
                  <span className={styles.routeOptionLabel}>Open Borders</span>
                </div>

                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    checked={!!mapValues.highway_only}
                    onChange={(checked) => {
                      handleMapValuesChange('highway_only', checked);
                      setRouteValues((prevState) => ({ ...prevState, highway_only: checked }));
                      !!addressValues.length && addStopPoint(addressValues, { ...mapValues, highway_only: checked });
                    }}
                  />
                  <span className={styles.routeOptionLabel}>Highway Only</span>
                </div>

                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    checked={!!mapValues.haz_mat}
                    onChange={(checked) => {
                      handleMapValuesChange('haz_mat', checked);
                      setRouteValues((prevState) => ({ ...prevState, haz_mat: checked }));
                      !!addressValues.length && addStopPoint(addressValues, { ...mapValues, haz_mat: checked });
                    }}
                  />
                  <span className={styles.routeOptionLabel}>HazMat</span>
                </div>

                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    checked={!!mapValues.avoid_tolls}
                    onChange={(checked) => {
                      handleMapValuesChange('avoid_tolls', checked);
                      setRouteValues((prevState) => ({ ...prevState, avoid_tolls: checked }));
                      !!addressValues.length && addStopPoint(addressValues, { ...mapValues, avoid_tolls: checked });
                    }}
                  />
                  <span className={styles.routeOptionLabel}>Avoid Tolls</span>
                </div>
              </div>
              <div className='d-flex align-items-center '>
                <Autocomplete
                  options={options}
                  style={{ width: '180px' }}
                  value={mapValues.route_type}
                  onChange={(e, val) => {
                    handleMapValuesChange('route_type', val);
                    setRouteValues((prevState) => ({ ...prevState, route_type: val }));
                    !!addressValues.length && addStopPoint(addressValues, { ...mapValues, route_type: val });
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default CustomStopPoint;
