import axios from '../services/axios';

export async function GetSecurityLogList({ page, itemsPerPage, query, user_type, version_status, ...rest }) {
  const params = {
    page,
    itemsPerPage,
    ...rest,
  };
  if (query) params.query = query;
  if (user_type?.length !== 0) params.user_type = user_type;
  if (Array.isArray(version_status) && version_status?.length !== 0 && version_status[0] !== 0)
    params.version_status = version_status;

  const { data } = await axios.get('/securitylog', {
    params,
  });
  return data;
}

export async function GetSecurityLogStatus({ user_type, version_status }) {
  const params = {};

  if (user_type?.length !== 0) params.user_type = user_type;
  if (Array.isArray(version_status) && version_status?.length !== 0 && version_status[0] !== 0)
    params.version_status = version_status;

  const { data } = await axios.get('/securitylog/status', { params });
  return data;
}

export async function clearLoginLock(body) {
  const { data } = await axios.post(`/user/staff/unlock`, body);
  return data;
}

export async function getLogHistory(id, params) {
  const { data } = await axios.get(`/single-securitylog/${id}`, { params });
  return data;
}
