import moment from 'moment';
import { vehicleRequiredFieldsMapper, cargoMeasuringUnits, measuringUnits } from '../Equipment.data';

export const tableDataConverter = (data) => {
  return (data || []).map((item, index) => {
    const alerts = [];

    const convertedData = {
      ...item,
      id: index,
      equipment_id: item.equipment_id || '',
      truck_type: item.truck_type || 2,
      cargo_capacity_measuring_unit: item.cargo_capacity_measuring_unit || cargoMeasuringUnits[0],
      gross_vehicle_measuring_unit: item.gross_vehicle_measuring_unit || measuringUnits[0],
      color: item.color || 'Blue',
      axles: item.axles || '',
      gross_vehicle_weight: item.gross_vehicle_weight || '',
      gvwr_class: item.gvwr_class || '',
      license_plate_expire_date: item.license_plate_expire_date || '',
      license_plate_number: item.license_plate_number || '',
      make: item.make || '',
      model_id: item.model_id || '',
      fuel_gallon_capacity: item.fuel_gallon_capacity || '',
      vin: item.vin || '',
      year: item.year || '',
    };

    Object.keys(vehicleRequiredFieldsMapper).forEach((key) => {
      if (!item[key]) {
        alerts.push(vehicleRequiredFieldsMapper[key]);
      }
    });
    convertedData.alerts = alerts;

    return convertedData;
  });
};

export const createEquipmentsConverter = (data, type) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return data.map((item) => {
    return {
      equipment_type_id: item.equipment_type_id?.id || null,
      truck_type: item.equipment_type_id?.id === 1 ? item.truck_type : null,
      equipment_id: item.equipment_id || null,
      equipment_ownership: {
        owner:
          item.owner_operator?.value === '1'
            ? user?.customer?.company_name
            : item.owner_operator?.value === '3'
            ? item.owner?.label
            : 'Ricky Road LLC',
        leasing_company_name: item.leasing_company_name || null,
        lease_or_credit_payment: item.lease_or_credit_payment || 0,
        owner_operator: item.owner_operator?.value || null,
        license_plate_number: item.license_plate_number || null,
        state: item.state?.value || null,
        license_plate_expire_date: item.license_plate_no_expiry
          ? null
          : item.license_plate_expire_date
          ? moment(item.license_plate_expire_date).format('YYYY-MM-DD')
          : null,
        operator_id: item.owner_operator?.value === '3' ? item.owner?.value : null,
      },
      make: item.make || null,
      axles: item.axles || null,
      model_id: item.model_id || null,
      gross_vehicle_weight: item.gross_vehicle_weight || null,
      year:
        type === 'trailer'
          ? item.year
            ? moment(item.year).format('MM/YYYY')
            : null
          : item.year
          ? moment(item.year).year()
          : null,
      start_odometer: item.start_odometer || null,
      odometer_unit: item.odometer_unit?.value || null,
      vin: item.vin || null,
      color: item.color || null,
      tire_size: item.tire_size || null,
      gross_vehicle_measuring_unit: item.gross_vehicle_measuring_unit?.value || null,
      cargo_maximum_capacity_weight: item.cargo_maximum_capacity_weight || null,
      cargo_capacity_measuring_unit: item.cargo_capacity_measuring_unit?.value || null,
      gvwr_class: item.gvwr_class || null,
      fuel_type_id: item.fuel_type_id?.value || '1',
      fuel_gallon_capacity: item.fuel_gallon_capacity || null,
      length_id: item?.length?.length_id || null,
      width: item.width || null,
      height: item.height || null,
      lift_gate: item?.lift_gate?.value || 0,
      type: type || 'vehicle',
    };
  });
};

export const vehiclesConverter = (vehicles, dimensions) => {
  const dimensionsById = dimensions?.reduce((acc, cur) => {
    const item = {
      ...cur,
      length: cur?.length_info ? `${cur?.length_info?.length}${cur?.length_info?.unit}` : null,
      length_id: cur?.length_info?.id || null,
    };

    if (!acc[cur.equipment_type_id]) {
      acc[cur.equipment_type_id] = [item];
    } else {
      acc[cur.equipment_type_id].push(item);
    }

    return acc;
  }, {});

  return vehicles?.map((item) => ({ ...item, dimensions: dimensionsById[item.id] || [] })) || [];
};

export const connectServiceConverter = (equipments) => {
  return equipments
    .map((equipment) => {
      if (!equipment.serial_id) {
        return null;
      }

      return {
        serial_id: equipment.serial_id,
        equipment_id: equipment.id,
        type: 'Vehicle',
        vehicle_or_trailer_id: 1,
        vin: equipment.vin,
        hardware_model: 'VG54',
      };
    })
    .filter((i) => !!i);
};
