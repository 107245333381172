import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getOwnerOperatorCounts } from 'Api/Dashboard';
import { SCountBox } from '../../../Dashboard.styles';

const OwnerOperatorsCount = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCounts = async () => {
    setLoading(true);
    try {
      const { data } = await getOwnerOperatorCounts();
      setCount(data.count);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <SCountBox onClick={() => navigate('/owner-operators')}>
      <Typography variant='s2' style={{ color: use(palette.gray500) }}>
        Owner Operators
      </Typography>
      {loading ? (
        <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '30px', height: '32px' }} />
      ) : (
        <Typography variant='h1'>{count}</Typography>
      )}
    </SCountBox>
  );
};

export default OwnerOperatorsCount;
