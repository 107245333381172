import React, { useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { createCustomerLinehaulRateMatrix } from 'Api/Customers';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import useShowToaster from 'hooks/useShowToaster';
import { SMergedInputs } from 'components/CreateShipment/CreateShipment.styles';
import sm from './ContactBook.module.css';
import { Typography } from '../../Typography';
import CustomInput from '../../CreateShipment/helpers/CustomInput';
import CustomButton from '../../CustomButton/CustomButton';
import CustomSelect from '../../CreateShipment/ShipmentStops/helpers/CustomSelect';
import { linehaulValidationSchema } from './ValidationSchema';

const weightUnities = [
  { id: 1, name: 'LBS', key: 1, label: 'LBS', labelSelected: null },
  {
    id: 2,
    name: 'KG',
    key: 2,
    label: 'KG',
    labelSelected: null,
  },
];

const LinehaulRateMatrix = ({ Cancel, nextStep, customer, getCustomers }) => {
  const showToaster = useShowToaster();
  const [onNextloading, setOnNextloading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const formRef = useRef();

  const onSkip = () => {
    Cancel();
    nextStep();
    showToaster({ type: 'success', message: `Successfully added ${customer?.basicDetails?.company_name}!` });
    getCustomers();
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();

      if (formRef.current.isValid && formRef.current.dirty) {
        onNextStep(formRef.current.values.lenehaulRate);
      }
    }
  };
  const { use } = useTheme();
  const defaultValue = {
    pricing_date: null,
    loaded_miles_start: '',
    loaded_miles_end: '',
    weight_start: '',
    weight_end: '',
    weight_unit_id: '',
    rate_per_mile: '',
    rate_type: 'mile',
  };

  const titlesOption = useMemo(() => {
    return [
      { key: 1, label: 'Pricing Date', width: '10%', required: true },
      { key: 2, label: 'Loaded Miles Start', width: '10%', required: true },
      { key: 3, label: 'Loaded Miles End', width: '10%', required: true },
      { key: 4, label: 'Weight Start', width: '10%', required: true },
      { key: 5, label: 'Weight End', width: '10%', required: true },
      { key: 6, label: 'Weight Unit', width: '10%', required: true },
      { key: 7, label: 'Linehaul Rate', width: '10%', required: true },
      { key: 8, label: '', width: '5%' },
    ];
  }, []);

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const onNextStep = (values) => {
    setOnNextloading(true);
    const f = new FormData();
    values.forEach((item, index) => {
      f.append(`linehaul_rate_matrix[${index}][loaded_miles_start]`, item.loaded_miles_start);
      f.append(`linehaul_rate_matrix[${index}][loaded_miles_end]`, item.loaded_miles_end);
      if (moment(item.pricing_date).isValid()) {
        f.append(`linehaul_rate_matrix[${index}][pricing_date]`, moment(item.pricing_date).format('YYYY-MM-DD'));
      }
      f.append(`linehaul_rate_matrix[${index}][weight_start]`, item.weight_start);
      f.append(`linehaul_rate_matrix[${index}][weight_end]`, item.weight_end);
      f.append(`linehaul_rate_matrix[${index}][weight_unit_id]`, item.weight_unit_id);
      f.append(`linehaul_rate_matrix[${index}][rate_per_mile]`, item.rate_per_mile);
      f.append(`linehaul_rate_matrix[${index}][rate_type]`, item.rate_type);
    });
    f.append(`customer_id`, customer.basicDetails.customer);
    createCustomerLinehaulRateMatrix(f)
      .then((res) => {
        if (Number(res?.status) === 200) {
          setOnNextloading(false);
          Cancel();
          setShowMessage({ ...showMessage, message: 'Customer added successfully!', visible: true });
          showToaster({ type: 'success', message: `Successfully added ${customer?.basicDetails?.company_name}!` });
          getCustomers();
        }
      })
      .catch(() => setOnNextloading(false));
  };

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = { lenehaulRate: [{ ...defaultValue }] };

  return (
    <>
      <Modal.Body className='add-driver-modal-body'>
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <Formik initialValues={initialValue} innerRef={formRef} validationSchema={linehaulValidationSchema}>
                {({ values, setFieldValue, handleBlur, touched, errors }) => (
                  <Form style={{ width: '100%' }}>
                    <FieldArray
                      name='lenehaulRate'
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            <div className={sm.row}>
                              {titlesOption.map((el) => {
                                return (
                                  <Typography
                                    key={el.label}
                                    variant='s2'
                                    style={{ width: el.width, whiteSpace: 'nowrap' }}
                                  >
                                    {el.label}
                                    {el.required && <span className={sm.required_system}>*</span>}
                                  </Typography>
                                );
                              })}
                            </div>

                            {values.lenehaulRate.map((el, index) => {
                              const namePrefix = `lenehaulRate[${index}]`;
                              return (
                                <div key={el?.label} className={[sm.row, sm.line].join(' ')} style={{ marginTop: 0 }}>
                                  <div style={{ width: '140px' }}>
                                    <DatePicker
                                      name={`lenehaulRate[${index}].pricing_date`}
                                      value={el.pricing_date}
                                      onBlur={handleBlur}
                                      onChange={(val) => setFieldValue(`lenehaulRate[${index}].pricing_date`, val)}
                                      error={
                                        touched?.lenehaulRate?.[index]?.pricing_date &&
                                        errors?.lenehaulRate?.[index]?.pricing_date
                                      }
                                    />
                                  </div>

                                  <div style={{ width: '129px' }}>
                                    <Field
                                      name={`${namePrefix}[loaded_miles_start]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'right' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[loaded_miles_start]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '129px' }}>
                                    <Field
                                      name={`${namePrefix}[loaded_miles_end]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'right' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[loaded_miles_end]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '129px' }}>
                                    <Field
                                      name={`${namePrefix}[weight_start]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'right' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[weight_start]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '129px' }}>
                                    <Field
                                      name={`${namePrefix}[weight_end]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'right' }}
                                      type='number'
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[weight_end]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '129px' }}>
                                    <Field
                                      name={`${namePrefix}[weight_unit_id]`}
                                      options={weightUnities}
                                      styles={styles.type}
                                      component={CustomSelect}
                                      menuStyles={styles.fullWidthPercent}
                                      validate={validateStopPointInput}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[weight_unit_id]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '18%' }}>
                                    <SMergedInputs>
                                      <Field
                                        name={`${namePrefix}[rate_per_mile]`}
                                        component={CustomInput}
                                        style={{ marginTop: 0, height: '32px', borderRadius: '8px 0 0 8px' }}
                                        labelStyle={{
                                          alignItems: 'flex-start',
                                          marginTop: 0,
                                        }}
                                        validate={validateStopPointInput}
                                        className={sm.input_basic_details}
                                      />
                                      <Autocomplete
                                        width='100px'
                                        name={`${namePrefix}.rate_type`}
                                        value={values.lenehaulRate[index].rate_type}
                                        options={['mile', 'flat']}
                                        onChange={(e, val) => {
                                          setFieldValue(`${namePrefix}.rate_type`, val);
                                        }}
                                      />
                                    </SMergedInputs>
                                    <ErrorMessage
                                      name={`${namePrefix}[rate_per_mile]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>
                                  {values.lenehaulRate.length > 1 && index !== 0 ? (
                                    <DeleteIcon
                                      width={12}
                                      height={12}
                                      fill={palette.indigo500}
                                      style={styles.deleteIcon}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  ) : (
                                    <span style={{ width: 28 }} />
                                  )}
                                </div>
                              );
                            })}

                            <CustomButton
                              type='secondary'
                              title='Add Another'
                              styleTitle={styles.addAnotherTitle}
                              styleButton={styles.addAnotherButton}
                              onClick={() => arrayHelpers.push({ ...defaultValue })}
                              leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                            />
                          </div>
                        );
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button className='cancel-button' onClick={onSkip}>
              Skip and Create
            </Button>
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button onClick={handleSubmit} className='next-step'>
                Add Customer
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default LinehaulRateMatrix;
