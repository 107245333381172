import axios from '../services/axios';

export async function GetNotepad(is_checked) {
  const params = {
    ...(!!is_checked && { is_checked }),
  };

  const { data } = await axios.get('/notepad', {
    params,
  });
  return data;
}

export async function PostNotepad(note) {
  const { data } = await axios.post('/notepad', {
    note,
  });

  return data;
}

export async function UpdateNotepad(id, is_checked) {
  const { data } = await axios.put(`/notepad/${id}`, {
    is_checked,
  });

  return data;
}
