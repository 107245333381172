import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import 'assets/fonts/Inter';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useAuth } from 'context/auth.context';
import { PusherJs } from 'common/pusher';
import Equipment from 'components/Equipment/TableEquipment/EquipmentList';
import ProtectedRoute from 'routes/route';
import Signin from 'pages/signin/signin';
import ForgotPassword from 'pages/signin/forgotPassword';
import Dashboard from 'pages/Dashboard';
import ResetPassword from 'pages/signin/resetPassword';
import Layout from 'components/layout';
import QuickbooksAuthorize from 'pages/QuickbooksAuthorize';
import CustomerOnboarding from 'pages/customerOnboarding/customerOnboarding';
import OnboardingAccount from 'components/customerOnboardingComponents/account/onboardingAccount';
import SelectPlan from 'components/customerOnboardingComponents/plan/selectPlan';
import Company from 'components/customerOnboardingComponents/company/company';
import Hardware from 'components/customerOnboardingComponents/hardware/hardware';
import Addons from 'components/customerOnboardingComponents/Addons';
import AccountSummary from 'components/customerOnboardingComponents/summary/accountSummary';
import EquipmentDetails from 'pages/landing/Equipment/EquipmentProfile/EquipmentDetails';
import EquipmentProfileTrailer from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileTrailer/EquipmentProfileTrailer';

import TablePlaner from 'components/TablePlaner/TablePlaner';
import DriversTableList from 'components/Drivers/DriversList';
import StaffTableList from 'components/Staff/StaffList';
import DriverProfile from 'components/DriverProfile/DriverProfile';
import Documents from 'components/Documents/Documents';
import PushToTalk from 'pages/pushToTalk/PushToTalk';
import Customers from 'components/Customers/Customers';
import ChatApp from 'pages/chatApp';
import Profile from 'components/Profile/Profile';
import CompanySettings from 'pages/CompanySettings/CompanySettings';
import CustomerProfile from 'components/CustomerProfile/CustomerProfile';
import Support from 'components/Support/Support';
import StopPoint from 'components/StopPoint/StopPoint';
import RecurringDetails from 'components/TablePlaner/helpers/RecurrningDetails';
import PreviousEmolymentVerification from 'pages/previousEmploymentVerification/previousEmploymentVerification';
import Walmart from 'components/StopPoint/Walmart/Walmart';
import OwnerOperatorList from 'components/OwnerOperator/OwnerOperator';
import IFTAPage from 'pages/IFTA/IFTAPage';
import EquipmentStopPoint from 'components/EquipmentStopPoint/EquipmentStopPoint';
import ShipmentsDetails from 'components/TableShipments/detailsRow';
import DispatchVehicleMap from 'components/DispatchVehicleMap';
import TableShipments from 'components/TableShipments';
import PayrollAndSettlements from 'pages/Payroll/PayrollAndSettlements';
import Settlement from 'pages/Payroll/Settlement';
import Reports from 'pages/Reports';
import ReportDetails from 'pages/Reports/ReportDetails';
import MyPay from 'pages/MyPay';
import CardManagement from 'pages/CardManagement/CardManagement';
import TruckinDigitalCashCard from 'pages/CardManagement/TruckinDigitalCashCard';
import ManageBalance from 'pages/CardManagement/ManageBalance';
import Receivables from 'pages/Accounting/Receivables';
import Payables from 'pages/Accounting/Payables';
import Accounts from 'pages/Accounting/Accounts';
import BankFeed from 'pages/Accounting/Accounts/BankFeed';
import Vendors from 'pages/Accounting/Vendors';
import TimeTracking from 'pages/Payroll/TimeTracking';
import RepairShop from 'pages/RepairShop';
import Billing from 'components/Billing/Billing';
import RequestList from 'components/RequestList/RequestList';
import MapComponent from 'components/Map/MapComponent';
import PlanToVehicle from 'components/PlanToVehicle/PlanToVehicle';
import DispatchVehicle from 'components/DispatchVehicle/DispatchVehicle';
import StaffProfile from 'components/StaffProfile/StaffProfile';
import Absence from 'pages/Absence';
import InvoicePayment from 'pages/InvoicePayment';
import CellularTracking from 'pages/CellularTracking';
import PublicPositions from 'pages/PublicPositions';
import PositionDetails from 'pages/PublicPositions/PositionDetails';
import ApplyJob from 'pages/PublicPositions/ApplyJob';
import ApplicantsAndReferrals from 'pages/ApplicantsAndReferrals';
import AlertCenter from 'pages/AlertCenter';
import HardwareList from 'components/MyHardware/Hardware';
import MyHardware from 'components/MyHardware/MyHardware';
import CustomizedSnackbars from 'components/toast/Toast';
import ShipmentHistory from 'components/ShipmentHistory';
import BillOfLading from 'components/TableShipments/BillOfLading';
import {
  handleToaster,
  updateCurrency,
  updateWeightUnit,
  updateQtyUnit,
  updatePermissions,
  updateDashboardPermissions,
  updateDateTimeFormat,
  updatePurchasedAddons,
  updateBillOfLadingTitle,
  updateConnectedServices,
} from 'store/reducers/root.reducer';
import { updatePaymentRequired } from 'store/reducers/billing.reducer';
import ConnectedServicesModule from 'components/ConnectedServiceModule.jsx/ConnectedServiceModule';
import OwnerOperatorProfile from 'components/OwnerOperatorProfile/OwnerOperatorProfile';
import RestrictedPage from 'components/RestrictedPage';
import ShipmentAuditProfile from 'components/ShipmentAuditProfile/ShipmentAuditProfile';
import OfferVehicle from 'components/OfferVehicle/OfferVehicle';
import PendingDispatch from 'components/PendingDispatch/PendingDispatch';
import Handbook from 'components/Handbook/Handbook';
import OfferMultipleShipments from 'components/OfferVehicle/OfferMultipleShipments';
import DispatchMultipleShipments from 'components/DispatchVehicle/DispatchMultipleShipments';
import ConfigureSettings from 'components/ConfigureSettings/ConfigureSettings';
import CompletedShipments from 'components/CompletedShipmets';
import ErrorBoundary from 'ErrorBoundary/ErrorBoundary';
import Detention from 'pages/Detention';
import EDILogs from 'pages/EDI/EDILogs';
import RulesAndConduct from 'pages/RulesAndConduct';
import Availability from 'pages/Planner/Availability';
import ShipmentBid from 'pages/Shipment/ShipmentBid';
import OfferedShipments from 'pages/OfferedShipments';
import CarrierContactBook from 'pages/CarrierContactBook';
import PublicQuote from 'pages/Planner/Quotes/PublicQuote';
import QuotesTable from 'pages/Planner/Quotes/QuotesTable';
import DisconnectQuickbooks from 'pages/DisconnectQuickbooks';
import { Referrals } from 'pages/ApplicantsAndReferrals/Referrals';
import { Referrers } from 'pages/ApplicantsAndReferrals/Referrers';
import ShipmentDetails from 'pages/OfferedShipments/ShipmentDetails';
import { JobPositions } from 'pages/ApplicantsAndReferrals/JobPositions';
import { GetAdditionalServices } from 'Api/Billing';
import {
  getCurrencyAndMetrics,
  getDateTimeSettings,
  getDocumentSettings,
  GetSettingsDepartments,
} from 'Api/CompanySettings';
import { ADDON_PLAN_ID, CURRENCY, DATE_FORMAT, PERMISSIONS, RESTRICTION_TYPE, TIME_FORMAT } from 'utils/constants';
import { useClearCaches } from 'hooks/useClearCaches';
import { getOnboardingSettings } from 'Api/Onboarding';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { getBillingDetail } from 'store/actions/billing.actions';
import { getConnectedServices } from 'Api/DriverV2';
import { Helmet } from 'react-helmet';
import { getAppUrl, isValidJSON } from 'utils/helpers';
import Carriers from './components/Carriers/Carriers';
import OnboardCarrier from './pages/OnboardCarrier';
import CarrierProfile from './components/Carriers/CarrierProfile/CarrierProfile';
import ConfigRequired from './components/Carriers/ConfigRequired';
import DispatchCarrier from './components/DispatchCarrier/DispatchCarrier';
import CarrierConfirmation from './pages/CarrierConfirmation';
import CarrierDocumentUpload from './pages/CarrierDocumentUpload';
import packageJson from '../package.json';
import Incidents from './pages/Incidents';
import DotMonitoring from './pages/DotMonitoring';
import AccidentRegister from './pages/Safety/AccidentRegister';
import Applicatnts from './pages/ApplicantsAndReferrals/Applicatnts';
import AccidentsAndCrashes from './pages/AccidentsAndCrashes';
import HosLogbook from './pages/Hos-Logbook';
import LogbookDetail from './pages/Hos-Logbook/LogbookDetails';

const App = () => {
  const { value, login } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showMessage, permissions, shouldUpdateConnectedServices } = useSelector((state) => state?.root);
  const user = JSON.parse(localStorage.getItem('user'));
  const browserInfo = JSON.parse(localStorage.getItem('browserInfo'));
  const [addons, setAddons] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  useClearCaches();

  const showRepairShop = addons?.some(
    (i) => i.addon_plan?.addon?.plan_id === ADDON_PLAN_ID.REPAIR_SHOP && i.status === 'Active'
  );
  const hasBrokerage = useHasBrokerage();

  const checkIfLoggedIn = async () => {
    if (value.isLoggedIn && !browserInfo) {
      navigate('/dashboard');
    }
  };

  const getAddons = async () => {
    setLoadingPlans(true);
    try {
      const { data } = await GetAdditionalServices({ itemsPerPage: 1000 });
      setAddons(data);
      dispatch(updatePurchasedAddons(data));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingPlans(false);
    }
  };

  const getMetricsSettings = async () => {
    try {
      const { data } = await getCurrencyAndMetrics();
      dispatch(updateCurrency(CURRENCY[data.default_currency]?.symbol || '$'));
      dispatch(updateWeightUnit(data.default_weight));
      dispatch(updateQtyUnit(data.default_qty_type));
    } catch (e) {
      // Do nothing
    }
  };

  const getDateTimeFormat = async () => {
    try {
      const response = await getDateTimeSettings();
      dispatch(
        updateDateTimeFormat({
          dateFormat: DATE_FORMAT[response.date_format] || DATE_FORMAT[1],
          timeFormat: TIME_FORMAT[response.time_format] || TIME_FORMAT[1],
        })
      );
    } catch (e) {
      // Do nothing
    }
  };

  const getPaymentRequired = async () => {
    try {
      const response = await getOnboardingSettings();
      dispatch(updatePaymentRequired(response));
    } catch (e) {
      // Do nothing
    }
  };

  const getDepartments = async () => {
    try {
      setLoadingPermissions(true);
      const { data } = await GetSettingsDepartments();
      const userPermissions =
        data.find((department) => department.id === value?.user?.department?.id)?.permissions || [];
      const userDashboardPermissions =
        data.find((department) => department.id === value?.user?.department?.id)?.dashboard_tab_permission || [];
      dispatch(updatePermissions(userPermissions.map((i) => i.permission_name)));
      dispatch(updateDashboardPermissions(userDashboardPermissions.map((i) => i.dashboard_tab.tab_name)));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingPermissions(false);
    }
  };

  const getAllConnectedServices = async () => {
    try {
      const { data } = await getConnectedServices();
      const connectedServices = data.filter((service) => service.connected);
      dispatch(updateConnectedServices(connectedServices));
    } catch (e) {
      // Do nothing
    }
  };

  const getDocSettings = async () => {
    try {
      const { data } = await getDocumentSettings();
      const billOfLadingTitle = data.rename_bill_of_lading_to_proof_of_delivery
        ? 'Proof of Delivery'
        : 'Bill of Lading';
      dispatch(updateBillOfLadingTitle(billOfLadingTitle));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    checkIfLoggedIn();

    if (
      value?.user &&
      value.user?.email === value.user?.customer?.email &&
      value.user?.is_password_set &&
      !value?.shouldPassConfigureModal &&
      !value.user?.is_setup_complete
    ) {
      navigate('/configure-settings');
    }
  }, [value]);

  useEffect(() => {
    if (!value?.user?.id) {
      return;
    }
    getAddons();
    getMetricsSettings();
    getDateTimeFormat();
    getPaymentRequired();
    dispatch(getBillingDetail());
  }, [value?.user?.id, value?.shouldUpdateAddons]);

  useEffect(() => {
    if (!value?.user?.id) {
      return;
    }
    getDepartments();
    getDocSettings();
  }, [value?.user?.department?.id, value?.shouldUpdateSettings]);

  useEffect(() => {
    if (!value?.user?.id) {
      return;
    }
    getAllConnectedServices();
  }, [value?.user?.id, shouldUpdateConnectedServices]);

  useEffect(() => {
    const dot = user?.customer?.dot;
    const userId = user?.id;
    const token = user?.accessToken;

    if (user) {
      const channel = PusherJs.subscribe(`private-force-logout.${dot}.${userId}`);
      channel.bind('force-logout', (e) => {
        if (e?.message?.data?.token) {
          const updatedToken = e.message.data.token;
          if (updatedToken !== token) {
            localStorage.clear();
            localStorage.setItem('version', packageJson.version);
            navigate('/login');
          }
        }
      });
    }
    return () => {
      PusherJs.unsubscribe(`private-force-logout.${dot}.${userId}`);
    };
  }, [user]);

  useEffect(() => {
    const url = getAppUrl();
    const origin =
      url === 'https://app.truckindigital.com'
        ? 'https://app.beta.truckindigital.com'
        : 'https://app.truckindigital.com';

    const handler = (event) => {
      if (event.origin !== origin) {
        return;
      }

      const userInfo = isValidJSON(event.data) ? JSON.parse(event.data) : null;
      const user = JSON.parse(localStorage.getItem('user'));

      if (userInfo?.email && userInfo?.password && !user) {
        login(userInfo.email, userInfo.password);
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Helmet>
        <title>Truckin Digital</title>
      </Helmet>
      <Routes>
        <Route element={<ProtectedRoute isAllowed={!!value.isLoggedIn} />}>
          <Route element={<Layout />}>
            <Route
              path='/dashboard'
              element={
                <>
                  <Helmet>
                    <title>Dashboard | Truckin Digital</title>
                  </Helmet>
                  <Dashboard />
                </>
              }
            />
            <Route
              path='/hardware'
              element={
                <>
                  <Helmet>
                    <title>Hardware | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.HARDWARE.name) ? (
                    <HardwareList />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/my-hardware'
              element={
                <>
                  <Helmet>
                    <title>My Hardware | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.HARDWARE.name) ? (
                    <MyHardware />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/planner'
              element={
                <>
                  <Helmet>
                    <title>Planner | Truckin Digital</title>
                  </Helmet>
                  {!permissions.includes(PERMISSIONS.PLANNER.name) ? (
                    <RestrictedPage loading={loadingPermissions} />
                  ) : (
                    <Outlet />
                  )}
                </>
              }
            >
              <Route index path='' element={<TablePlaner />} />
              <Route path='plan' element={<PlanToVehicle />} />
              <Route path='dispatch' element={<DispatchVehicle />} />
              <Route path='carrier-dispatch' element={<DispatchCarrier />} />
              <Route path='dispatch-multiple' element={<DispatchMultipleShipments />} />
              <Route path='offer' element={<OfferVehicle />} />
              <Route path='offer-multiple' element={<OfferMultipleShipments />} />
              <Route path='pending-dispatch' element={<PendingDispatch />} />
            </Route>
            <Route
              path='/planner-availability'
              element={
                <>
                  <Helmet>
                    <title>Availability | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PLANNER.name) ? (
                    <Availability />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/quotes'
              element={
                <>
                  <Helmet>
                    <title>Quotes | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PLANNER.name) ? (
                    <QuotesTable />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/bill-of-lading/:id/:bolId'
              element={
                <>
                  <Helmet>
                    <title>Bill of Lading | Truckin Digital</title>
                  </Helmet>
                  <BillOfLading />
                </>
              }
            />
            <Route
              path='/shipments'
              element={
                <>
                  <Helmet>
                    <title>Shipments | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SHIPMENTS.name) ? (
                    <TableShipments />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/shipment-audit'
              element={
                <>
                  <Helmet>
                    <title>Shipment Audit | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.COMPLETED_AUDIT.name) ? (
                    <CompletedShipments />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/shipment-history'
              element={
                <>
                  <Helmet>
                    <title>Shipment History | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SHIPMENT_HISTORY.name) ? (
                    <ShipmentHistory />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/offered-shipments'
              element={
                <>
                  <Helmet>
                    <title>Offered Shipments | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PLANNER.name) ? (
                    <OfferedShipments />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/offered-shipments/:id'
              element={
                <>
                  <Helmet>
                    <title>Offered Shipments | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PLANNER.name) ? (
                    <ShipmentDetails />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/detention'
              element={
                <>
                  <Helmet>
                    <title>Detention | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.DETENTION.name) ? (
                    <Detention />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/shipmentAudit-profile/:id'
              element={
                <>
                  <Helmet>
                    <title>Shipment Audit Profile | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.COMPLETED_AUDIT.name) ? (
                    <ShipmentAuditProfile />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/lane/:id'
              element={
                <>
                  <Helmet>
                    <title>Lane | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PLANNER.name) ? (
                    <RecurringDetails />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/shipment/:id'
              element={
                <>
                  <Helmet>
                    <title>Shipment | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SHIPMENTS.name) ? (
                    <ShipmentsDetails />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/shipment/:id/dispatch'
              element={
                <>
                  <Helmet>
                    <title>Shipment | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SHIPMENTS.name) ? (
                    <DispatchVehicleMap />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/edi-api-logs'
              element={
                <>
                  <Helmet>
                    <title>EDI/API Logs | Truckin Digital</title>
                  </Helmet>
                  <EDILogs />
                </>
              }
            />
            <Route
              path='/driver'
              element={
                <>
                  <Helmet>
                    <title>Drivers | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.FLEET_STAFF_DRIVERS_LIST.name) ||
                  permissions.includes(PERMISSIONS.DRIVERS.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <DriversTableList />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/driver-profile/:id/:slug'
              element={
                <>
                  <Helmet>
                    <title>Driver Profile | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.DRIVERS.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <DriverProfile />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/owner-operators'
              element={
                <>
                  <Helmet>
                    <title>Owner Operators | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.OWNER_OPERATORS.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <OwnerOperatorList />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/ownerOperator-profile/:id'
              element={
                <>
                  <Helmet>
                    <title>Owner Operators Profile | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.OWNER_OPERATORS.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <OwnerOperatorProfile />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/connectedservicesediapiintegrations'
              element={
                <>
                  <Helmet>
                    <title>Connected Services | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CONNECTED_SERVICES.name) ? (
                    <ConnectedServicesModule />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/quickbooks-connect'
              element={
                <>
                  <Helmet>
                    <title>QuickBooks Connect | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CONNECTED_SERVICES.name) ? (
                    <QuickbooksAuthorize />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/staff'
              element={
                <>
                  <Helmet>
                    <title>Staff Users | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.STAFF.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <StaffTableList />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/staff-profile/:id/:slug'
              element={
                <>
                  <Helmet>
                    <title>Staff Profile | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.STAFF.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <StaffProfile />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/equipment'
              element={
                <>
                  <Helmet>
                    <title>Equipment | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.FLEET_STAFF_EQUIPMENT.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <Equipment />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/ifta'
              element={
                <>
                  <Helmet>
                    <title>IFTA | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.IFTA.name) ? (
                    <IFTAPage />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/payroll'
              element={
                <>
                  <Helmet>
                    <title>Payroll | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PAYROLL.name) ? (
                    <PayrollAndSettlements />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/settlement/:user_type/:user_id/:id'
              element={
                <>
                  <Helmet>
                    <title>Settlement | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PAYROLL.name) ? (
                    <Settlement />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/my-pay'
              element={
                <>
                  <Helmet>
                    <title>My Pay | Truckin Digital</title>
                  </Helmet>
                  <MyPay />
                </>
              }
            />
            <Route
              path='/card-management'
              element={
                <>
                  <Helmet>
                    <title>Card Management | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CARD_MANAGEMENT.name) ? (
                    <CardManagement />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/truckin-digital-cash-card/:module'
              element={
                <>
                  <Helmet>
                    <title>Cash Card | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CARD_MANAGEMENT.name) ? (
                    <TruckinDigitalCashCard />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/manage-balance'
              element={
                <>
                  <Helmet>
                    <title>Manage Balance | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CARD_MANAGEMENT.name) ? (
                    <ManageBalance />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route path='/accounting'>
              <Route
                path='receivables'
                element={
                  <>
                    <Helmet>
                      <title>Receivables | Truckin Digital</title>
                    </Helmet>
                    {permissions.includes(PERMISSIONS.RECEIVABLES.name) ||
                    permissions.includes(PERMISSIONS.ACCOUNTING.name) ? (
                      <Receivables />
                    ) : (
                      <RestrictedPage loading={loadingPermissions} />
                    )}
                  </>
                }
              />
              <Route
                path='payables'
                element={
                  <>
                    <Helmet>
                      <title>Payables | Truckin Digital</title>
                    </Helmet>
                    {permissions.includes(PERMISSIONS.PAYABLES.name) ||
                    permissions.includes(PERMISSIONS.ACCOUNTING.name) ? (
                      <Payables />
                    ) : (
                      <RestrictedPage loading={loadingPermissions} />
                    )}
                  </>
                }
              />
              <Route
                path='accounts'
                element={
                  <>
                    {' '}
                    <Helmet>
                      <title>Chart of Accounts | Truckin Digital</title>
                    </Helmet>
                    {permissions.includes(PERMISSIONS.ACCOUNTS.name) ||
                    permissions.includes(PERMISSIONS.ACCOUNTING.name) ? (
                      <Accounts />
                    ) : (
                      <RestrictedPage loading={loadingPermissions} />
                    )}
                  </>
                }
              />
              <Route
                path='accounts/:id/bank-feed'
                element={
                  <>
                    <Helmet>
                      <title>Bank Feed | Truckin Digital</title>
                    </Helmet>
                    {permissions.includes(PERMISSIONS.ACCOUNTS.name) ||
                    permissions.includes(PERMISSIONS.ACCOUNTING.name) ? (
                      <BankFeed />
                    ) : (
                      <RestrictedPage loading={loadingPermissions} />
                    )}
                  </>
                }
              />
              <Route
                path='vendors'
                element={
                  <>
                    <Helmet>
                      <title>Vendors | Truckin Digital</title>
                    </Helmet>
                    {permissions.includes(PERMISSIONS.VENDORS.name) ||
                    permissions.includes(PERMISSIONS.ACCOUNTING.name) ? (
                      <Vendors />
                    ) : (
                      <RestrictedPage loading={loadingPermissions} />
                    )}
                  </>
                }
              />
            </Route>
            <Route
              path='/incidents'
              element={
                <>
                  <Helmet>
                    <title>Incidents | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SAFETY.name) ? (
                    <Incidents />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/dot-monitoring'
              element={
                <>
                  <Helmet>
                    <title>DOT Monitoring | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SAFETY.name) ? (
                    <DotMonitoring />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/accidents-crashes'
              element={
                <>
                  <Helmet>
                    <title>Accidents Crashes | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SAFETY.name) ? (
                    <AccidentsAndCrashes />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/accident-register'
              element={
                <>
                  <Helmet>
                    <title>Accident Register | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SAFETY.name) ? (
                    <AccidentRegister />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/hours-of-service'
              element={
                <>
                  <Helmet>
                    <title>Hours of Service | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SAFETY.name) ? (
                    <HosLogbook />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/hos-logbook/:id'
              element={
                <>
                  <Helmet>
                    <title>Hos Logbook | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SAFETY.name) ? (
                    <LogbookDetail />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/repair-shop'
              element={
                <>
                  <Helmet>
                    <title>Repair Shop | Truckin Digital</title>
                  </Helmet>
                  {showRepairShop ? (
                    <RepairShop />
                  ) : (
                    <RestrictedPage
                      restrictionType={RESTRICTION_TYPE.ADDON}
                      loading={loadingPlans}
                      planId={ADDON_PLAN_ID.REPAIR_SHOP}
                    />
                  )}
                </>
              }
            />
            <Route
              path='/time-tracking'
              element={
                <>
                  <Helmet>
                    <title>Time Tracking | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PAYROLL.name) ? (
                    <TimeTracking />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/equipment-profile/vehicle/:id'
              element={
                <>
                  <Helmet>
                    <title>Vehicle | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.FLEET_STAFF_EQUIPMENT.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <EquipmentDetails />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/equipment-profile/trailer/:id'
              element={
                <>
                  <Helmet>
                    <title>Trailer | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.FLEET_STAFF_EQUIPMENT.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <EquipmentProfileTrailer />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/chat'
              element={
                <>
                  <Helmet>
                    <title>Chat | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CHAT.name) ? (
                    <ChatApp />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/chat/:id'
              element={
                <>
                  <Helmet>
                    <title>Chat | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CHAT.name) ? (
                    <ChatApp />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/billing'
              element={
                <>
                  <Helmet>
                    <title>Billing | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.BILLING.name) ? (
                    <Billing />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/profile'
              element={
                <>
                  <Helmet>
                    <title>Profile | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.PROFILE.name) ? (
                    <Profile />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/support'
              element={
                <>
                  <Helmet>
                    <title>Support | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SUPPORT.name) ? (
                    <Support />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/handbook'
              element={
                <>
                  <Helmet>
                    <title>Handbook | Truckin Digital</title>
                  </Helmet>
                  <Handbook />
                </>
              }
            />
            <Route
              path='/rules-conduct'
              element={
                <>
                  <Helmet>
                    <title>Rules Conduct | Truckin Digital</title>
                  </Helmet>
                  <RulesAndConduct />
                </>
              }
            />
            <Route
              path='/customers'
              element={
                <>
                  <Helmet>
                    <title>Customers | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CUSTOMERS.name) ? (
                    <Customers />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/carriers'
              element={
                <>
                  <Helmet>
                    <title>Carriers | Truckin Digital</title>
                  </Helmet>
                  {hasBrokerage ? (
                    permissions.includes(PERMISSIONS.CARRIERS.name) ? (
                      <Carriers />
                    ) : (
                      <RestrictedPage loading={loadingPermissions} />
                    )
                  ) : (
                    <RestrictedPage
                      restrictionType={RESTRICTION_TYPE.ADDON}
                      loading={loadingPlans}
                      planId={ADDON_PLAN_ID.BROKERAGE}
                    />
                  )}
                </>
              }
            />
            <Route
              path='/carrier-profile/:id'
              element={
                <>
                  <Helmet>
                    <title>Carrier Profile | Truckin Digital</title>
                  </Helmet>
                  {hasBrokerage ? (
                    permissions.includes(PERMISSIONS.CARRIER_PROFILE.name) ? (
                      <CarrierProfile />
                    ) : (
                      <RestrictedPage loading={loadingPermissions} />
                    )
                  ) : (
                    <RestrictedPage
                      restrictionType={RESTRICTION_TYPE.ADDON}
                      loading={loadingPlans}
                      planId={ADDON_PLAN_ID.BROKERAGE}
                    />
                  )}
                </>
              }
            />
            <Route
              path='/customer-profile/:id'
              element={
                <>
                  <Helmet>
                    <title>Customer Profile | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.CUSTOMERS.name) ? (
                    <CustomerProfile />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/stop-point'
              element={
                <>
                  <Helmet>
                    <title>Stop Points | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.STOP_POINTS.name) ? (
                    <StopPoint />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/stop-point/:id/:tab'
              element={
                <>
                  <Helmet>
                    <title>Stop Point | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.STOP_POINTS.name) ? (
                    <Walmart />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/equipment-at-stop-point'
              element={
                <>
                  <Helmet>
                    <title>Equipment At Stop Point | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.STOP_POINTS.name) ? (
                    <EquipmentStopPoint />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/absence-list'
              element={
                <>
                  <Helmet>
                    <title>Attendance | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.ABSENCES.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <Absence />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/request-list'
              element={
                <>
                  <Helmet>
                    <title>Requests | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.REQUESTS.name) ||
                  permissions.includes(PERMISSIONS.REQUESTS_READ_ONLY.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <RequestList />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/applicants'
              element={
                <>
                  <Helmet>
                    <title>Applicants | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.APPLICANTS_REFERRALS.name) ||
                  permissions.includes(PERMISSIONS.FLEET_STAFF.name) ? (
                    <ApplicantsAndReferrals />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/recruiting-applicants'
              element={
                <>
                  <Helmet>
                    <title>Recruiting Applicants | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.APPLICANTS.name) ? (
                    <Applicatnts />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/referrals'
              element={
                <>
                  <Helmet>
                    <title>Referrals | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.REFERRALS.name) ? (
                    <Referrals />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/referrers'
              element={
                <>
                  <Helmet>
                    <title>Referrers | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.REFERRERS.name) ? (
                    <Referrers />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/job-positions'
              element={
                <>
                  <Helmet>
                    <title>Job Listings | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.JOB_POSITIONS.name) ? (
                    <JobPositions />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/reports'
              element={
                <>
                  <Helmet>
                    <title>Reports | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.REPORTS.name) ? (
                    <Reports />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/reports/:id'
              element={
                <>
                  <Helmet>
                    <title>Reports | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.REPORTS.name) ? (
                    <ReportDetails />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/talk'
              element={
                <>
                  <Helmet>
                    <title>Talk | Truckin Digital</title>
                  </Helmet>
                  <PushToTalk />
                </>
              }
            />
            <Route
              path='/documents'
              element={
                <>
                  <Helmet>
                    <title>Documents | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.DOCUMENTS.name) ? (
                    <Documents />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/alert-center'
              element={
                <>
                  <Helmet>
                    <title>Alert Center | Truckin Digital</title>
                  </Helmet>
                  <AlertCenter />
                </>
              }
            />
            <Route
              path='/company-settings'
              element={
                <>
                  <Helmet>
                    <title>Company Settings | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.SETTINGS.name) ? (
                    <CompanySettings />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/map'
              element={
                <>
                  <Helmet>
                    <title>Map | Truckin Digital</title>
                  </Helmet>
                  {permissions.includes(PERMISSIONS.MAP.name) ? (
                    <MapComponent />
                  ) : (
                    <RestrictedPage loading={loadingPermissions} />
                  )}
                </>
              }
            />
            <Route
              path='/restricted'
              element={
                <>
                  <Helmet>
                    <title>Restricted | Truckin Digital</title>
                  </Helmet>
                  <RestrictedPage />
                </>
              }
            />
          </Route>
          <Route
            path='/configure-settings'
            element={
              <>
                <Helmet>
                  <title>Configure Settings | Truckin Digital</title>
                </Helmet>
                <ConfigureSettings />
              </>
            }
          />
        </Route>
        <Route
          path='/login'
          element={
            <>
              <Helmet>
                <title>Login | Truckin Digital</title>
              </Helmet>
              <Signin />
            </>
          }
        />
        <Route
          path='/forgot-password'
          element={
            <>
              <Helmet>
                <title>Forgot Password | Truckin Digital</title>
              </Helmet>
              <ForgotPassword />
            </>
          }
        />
        <Route
          path='/password/reset/:token'
          element={
            <>
              <Helmet>
                <title>Password Reset | Truckin Digital</title>
              </Helmet>
              <ResetPassword />
            </>
          }
        />
        <Route
          path='/employee-verification'
          element={
            <>
              <Helmet>
                <title>Employee Verification | Truckin Digital</title>
              </Helmet>
              <PreviousEmolymentVerification />
            </>
          }
        />
        <Route element={<CustomerOnboarding />}>
          <Route path='/onboarding' element={<Navigate replace to='/onboarding/account' />} />
          <Route
            path='/onboarding/account'
            element={
              <>
                <Helmet>
                  <title>Onboarding Account | Truckin Digital</title>
                </Helmet>
                <OnboardingAccount />
              </>
            }
          />
          <Route
            path='/onboarding/select-plan'
            element={
              <>
                <Helmet>
                  <title>Onboarding Select Plan | Truckin Digital</title>
                </Helmet>
                <SelectPlan />
              </>
            }
          />
          <Route
            path='/onboarding/company'
            element={
              <>
                <Helmet>
                  <title>Onboarding Company | Truckin Digital</title>
                </Helmet>
                <Company />
              </>
            }
          />
          <Route
            path='/onboarding/addons'
            element={
              <>
                <Helmet>
                  <title>Onboarding Addons | Truckin Digital</title>
                </Helmet>
                <Addons />
              </>
            }
          />
          <Route
            path='/onboarding/hardware'
            element={
              <>
                <Helmet>
                  <title>Onboarding Hardware | Truckin Digital</title>
                </Helmet>
                <Hardware />
              </>
            }
          />
          <Route
            path='/onboarding/account-summary'
            element={
              <>
                <Helmet>
                  <title>Onboarding Account Summary | Truckin Digital</title>
                </Helmet>
                <AccountSummary />
              </>
            }
          />
        </Route>
        <Route
          path='/job-positions/:companyId'
          element={
            <>
              <Helmet>
                <title>Job Listings | Truckin Digital</title>
              </Helmet>
              <PublicPositions />
            </>
          }
        />
        <Route
          path='/job-positions/:companyId/:id'
          element={
            <>
              <Helmet>
                <title>Job Listings | Truckin Digital</title>
              </Helmet>
              <PositionDetails />
            </>
          }
        />
        <Route
          path='/job-positions/:companyId/:id/apply'
          element={
            <>
              <Helmet>
                <title>Job Listings Apply | Truckin Digital</title>
              </Helmet>
              <ApplyJob />
            </>
          }
        />
        <Route
          path='/:dot/:carrier-onboard/:token'
          element={
            <>
              <Helmet>
                <title>Carrier Onboard | Truckin Digital</title>
              </Helmet>
              <OnboardCarrier />
            </>
          }
        />
        <Route
          path='/:dot/shipment-confirmation/:shipment_id/:token'
          element={
            <>
              <Helmet>
                <title>Shipment Confirmation | Truckin Digital</title>
              </Helmet>
              <CarrierConfirmation />
            </>
          }
        />
        <Route
          path='/:dot/shipment-document-upload/:shipment_id/:token'
          element={
            <>
              <Helmet>
                <title>Shipment Document | Truckin Digital</title>
              </Helmet>
              <CarrierDocumentUpload />
            </>
          }
        />
        <Route
          path='/:dot/p/:invoiceId/:isBatch'
          element={
            <>
              <Helmet>
                <title>Invoice Payment | Truckin Digital</title>
              </Helmet>
              <InvoicePayment />
            </>
          }
        />
        <Route
          path='/:dot/shipment-offer/:shipment_id/:type/:token'
          element={
            <>
              <Helmet>
                <title>Shipment Offer | Truckin Digital</title>
              </Helmet>
              <ShipmentBid />
            </>
          }
        />
        <Route
          path='/:dot/contact-book/:carrierId/:token'
          element={
            <>
              <Helmet>
                <title>Contact Book | Truckin Digital</title>
              </Helmet>
              <CarrierContactBook />
            </>
          }
        />
        <Route
          path='/cellular-tracking/:dot/:shipmentId'
          element={
            <>
              <Helmet>
                <title>Cellular Tracking | Truckin Digital</title>
              </Helmet>
              <CellularTracking />
            </>
          }
        />
        <Route
          path='/quickbooks-disconnect'
          element={
            <>
              <Helmet>
                <title>Quickbooks Disconnect | Truckin Digital</title>
              </Helmet>
              <DisconnectQuickbooks />
            </>
          }
        />
        <Route
          path='/:dot/quote/:hash'
          element={
            <>
              <Helmet>
                <title>Quote | Truckin Digital</title>
              </Helmet>
              <PublicQuote />
            </>
          }
        />
        <Route path='/' element={<Navigate replace to={`${value?.isLoggedIn ? '/dashboard' : '/login'}`} />} />
        <Route path='*' element={<Navigate replace to={`${value?.isLoggedIn ? '/dashboard' : '/login'}`} />} />
      </Routes>
      {showMessage.visible && (
        <CustomizedSnackbars
          showMessage={showMessage}
          setShowMessage={() => dispatch(handleToaster({ message: '', visible: false, type: 'success' }))}
        />
      )}
      {hasBrokerage && value?.user?.id && <ConfigRequired />}
    </ErrorBoundary>
  );
};
export default App;
