import React, { useContext, useEffect, useState } from 'react';
import { ChannelList, useChatContext } from 'stream-chat-react';
import { useParams } from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import plus from 'assets/icons/plus.svg';
import filterIconDark from 'assets/icons/filterIconDark.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/hamburger.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';
import HeaderStar from 'components/HeaderStar';
import { LayoutContext } from 'components/layout';
import { Typography } from 'components/Typography';
import AddNewChannel from 'pages/chatApp/components/AddNewChannel';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import Tabs from 'common/Tabs';

import { getChannelFilters } from 'pages/chatApp/ChatApp.data';
import ChannelItem from '../ChannelItem';

const tabs = [
  { value: 1, label: 'Open' },
  { value: 2, label: 'Closed' },
];

const PopoverOption = ({ c, setFilterOption, filterOption }) => {
  const [value, setValue] = useState(false);

  const handleChange = (e) => {
    setValue(e);
    if (e) {
      const found = filterOption.includes(c.data.cid);
      if (!found) {
        setFilterOption([...filterOption, c.data.cid]);
      }
    } else {
      setFilterOption(filterOption.filter((item) => item !== c.data.cid));
    }
  };

  return (
    <span>
      <CustomCheckbox onChange={handleChange} checked={value} />
      &nbsp; {c?.data?.name}
    </span>
  );
};

const sort = { last_message_at: -1 };

const ChannelsList = ({
  openSideBar,
  setOpenSideBar,
  updateMutedStatus,
  setUpdateMutedStatus,
  tab,
  activeTab,
  setActiveTab,
}) => {
  const { client } = useChatContext();
  const { chatUser, dot_num } = useContext(LayoutContext);
  const [filterOption, setFilterOption] = useState([]);
  const [filterByName, setFilterByName] = useState([]);
  const [search, setSearch] = useState('');
  const { id } = useParams();

  const [addNewModal, setAddNewModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [groupChannel, setGroupChannel] = useState(null);

  const handleActive = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const getChannelGroups = async () => {
      const groupChannels = await client.queryChannels(
        {
          type: 'truckindigital',
          organization_id: dot_num,
        },
        sort,
        {
          watch: true,
          state: true,
        }
      );
      setGroupChannel(groupChannels);
    };
    getChannelGroups();
  }, [activeTab]);

  const handleComposerModal = () => {
    setAddNewModal(!addNewModal);
  };

  useEffect(() => {
    if (filterOption.length !== 0) {
      const getfilterChannels = async () => {
        const filter = {
          type: 'truckindigital',
          cid: { $in: filterOption },
          organization_id: dot_num,
        };
        const channels = await client.queryChannels(filter, sort, {
          watch: true,
          state: true,
        });
        setFilterByName(channels.map((c) => c.data.name));
      };
      getfilterChannels();
    } else {
      setFilterByName([]);
    }
  }, [filterOption]);

  const filterChannel = (
    <Popover>
      <Popover.Body>
        <div className='popover-filter'>
          <p className='popover-headings'>Groups</p>
          <div className='filter-option'>
            {groupChannel?.map((c, i) => (
              <div className='option' key={c?.id || i}>
                <PopoverOption c={c} setFilterOption={setFilterOption} filterOption={filterOption} />
              </div>
            ))}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className='user-tab'>
      <div className='d-flex align-items-center gap-2'>
        {openSideBar ? (
          <ArrowRight
            width={15}
            height={15}
            fill={palette.indigo400}
            onClick={() => setOpenSideBar((prevState) => !prevState)}
            className='pointer close-sidebar-arrow'
          />
        ) : (
          <MenuIcon
            fill={palette.indigo400}
            onClick={() => setOpenSideBar((prevState) => !prevState)}
            className='pointer'
          />
        )}
        <Typography variant='s1' style={{ color: palette.gray700 }}>
          {tab?.title}
        </Typography>
      </div>
      <div className='user-tab-header'>
        {addNewModal && <AddNewChannel open={addNewModal} onClose={() => setAddNewModal(false)} />}
        <HeaderStar title='Chat' />
        <div className='edit-btn' onClick={handleComposerModal}>
          <img src={plus} alt='' />
        </div>
      </div>
      <div className='header-search usertab-search-box'>
        <OverlayTrigger
          trigger='click'
          placement='right'
          rootClose={showFilter}
          onToggle={setShowFilter}
          show={showFilter}
          overlay={filterChannel}
        >
          <div className='filter-icon'>
            <img src={filterIconDark} alt='' />
            <span>Options: {filterOption ? filterOption.length : 0} </span>
            &nbsp;
            <span style={{ color: '#4F5AED' }} onClick={() => setFilterOption([])}>
              Clear All
            </span>
          </div>
        </OverlayTrigger>
        <div className='header-search-container'>
          <input
            type='text'
            placeholder='Search'
            className='search-input'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>

      <div className='tab'>
        <Tabs tabs={tabs} activeTab={activeTab} handleTabChange={handleActive} />
        <div className='user-channel-list'>
          <ChannelList
            customActiveChannel={id}
            Preview={(props) =>
              ChannelItem({
                ...props,
                tab,
                activeTab,
                chatUser,
                filterByName,
                updateMutedStatus,
                setUpdateMutedStatus,
              })
            }
            EmptyStateIndicator={() => (
              <Typography>You have no {activeTab === 1 ? 'open' : 'closed'} channels currently.</Typography>
            )}
            filters={getChannelFilters({ tab, statusTab: activeTab, chatUser, dot_num, search, filterOption })}
            sort={sort}
          />
        </div>
      </div>
    </div>
  );
};

export default ChannelsList;
