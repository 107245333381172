import React, { useMemo, useState, createContext, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from 'common/Loader';
import useIsMounted from 'hooks/useIsMounted';
import { weekdaysBetween } from 'utils/helpers';
import { CURRENCY, palette } from 'utils/constants';
import {
  CreateShipmentPost,
  CreateShipmentPostLTL,
  customerStopPoint,
  getCommodity,
  getContactsBillingCharges,
  getFullPrices,
  getShipmentChargeType,
  getShipmentsList,
  getTotalMiles,
} from 'Api/Planner';
import { handleToaster } from 'store/reducers/root.reducer';
import { GetSettingsShipments } from 'Api/CompanySettings';
import PlanAhead from 'components/CreateShipment/PlanAhead';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import sm from './Layout.module.css';

import Schedule from './Schedule';
import Documents from './Documents';
import { Typography } from '../Typography';
import ShipmentStops from './ShipmentStops';
import TypeOfShipment from './TypeOfShipment';
import BillingCharges from './BillingCharges';
import HeaderProgress from './helpers/HeaderProgres';

import ContactEditModal from './CreateModals/ContactEditModal';
import AddStopPointModal from './CreateModals/addStopPointModal';
import CommoditySettingsModal from './CreateModals/CommoditySettingsModal';
import BillingChargesLTL from './LTL/BillingChargesLTL';
import ShipmentStopsLTL from './LTL/ShipmentStopsLTL';
import DocumentsLTL from './LTL/ShipmentStopsLTL/DocumentsLTL';
import ValidationPage from './ValidationPage';
import { getInitialStopsData } from './helpers';
import {
  PROGRESS_LTL,
  PROGRESS_RECURRING_LTL,
  PROGRESS_RECURRING_TL,
  PROGRESS_TL,
} from './ShipmentStops/helpers/constants';

export const ShipmentContext = createContext();

const Layout = ({
  onCloseModal,
  updateOnCreate,
  createModalCLose,
  setFilterInCreateShipment,
  initialValueOutSide,
  setInitialValueOutSide,
  onRecurringLaneCreateSuccess = () => null,
  initialFrequency,
  continueDraft,
}) => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showToaster = useShowToaster();

  const [step, setStep] = useState(1);
  const [showAddStopPointModal, setShowAddStopPointModal] = useState(false);
  const [currentStopAddValue, setCurrentStopAddValue] = useState(null);
  const [currentStopAddIndex, setCurrentStopAddIndex] = useState(null);
  const [showContactEditModal, setShowContactEditModal] = useState(false);
  const [showCommodityModal, setShowCommodityModal] = useState(false);

  const [activeShipmentType, setActiveShipmentType] = useState(1);
  const [documentsUploadFiles, setDocumentsUploadFiles] = useState({});
  const [selectedCharges, setSelectedCharges] = useState({});
  const [selectedGrandTotal, setSelectedGrandTotal] = useState({});
  const [detailCopyShipmentData, setDetailCopyShipmentData] = useState([]);
  const [detailCopyShipmentLTLData, setDetailCopyShipmentLTLData] = useState([]);
  const [shipmentSettings, setShipmentSettings] = useState(null);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [shipmentCreateLoading, setShipmentCreateLoading] = useState(false);

  // Stop Point
  const [stopPointAll, setStopPointAll] = useState([]);
  const stopPoints = useMemo(() => {
    return stopPointAll.map((el) => {
      const city = el?.city?.name ? el?.city?.name : '';
      const state = el?.state?.short_name ? el?.state?.short_name : '';
      return {
        key: el.id,
        city,
        state,
        label: `${el?.location_name} (${city}${state ? ` ${state}` : ''})`,
        labelSelected: `${el?.location_name} - ${el?.address1} ${city}`,
        latitude: el?.geofencing_latitude,
        longitude: el?.geofencing_longitude,
        zipcode: el?.zipcode,
        address1: el?.address1,
        location_name: el?.location_name,
        stop_point_working_hour: el.stop_point_working_hour,
        average_waiting_time: el?.average_waiting_time,
        working_hour_by: el?.working_hour_by,
      };
    });
  }, [stopPointAll]);
  const [totalAmount, setTotalAmount] = useState({}); // total_amount
  const [equipmentLengthData, setEquipmentLengthData] = useState([]);
  const [equipmentRequired, setEquipmentRequired] = useState([]);
  const [equipmentId, setEquipmentId] = useState([]);
  const [docType, setDocType] = useState([]);
  const [commodityAll, setCommodityAll] = useState([]);
  const commodity = useMemo(() => {
    return commodityAll.map((commodity) => {
      return {
        ...commodity,
        key: commodity?.id,
        label: commodity?.title,
        labelSelected: null,
      };
    });
  }, [commodityAll]);
  // billing charges
  const [billToData, setBillToData] = useState([]);

  const [paymentTermData, setPaymentTermData] = useState({});

  const [selectedBillTo, setSelectedBillTo] = useState({});
  const [activeBillChargeIndex, setActiveBillChargeIndex] = useState(0);

  const [contactDataAll, setContactDataAll] = useState({});
  const contactData = useMemo(() => {
    const newContactData = {};
    Object.keys(contactDataAll).forEach((key) => {
      const arr = contactDataAll[key];
      const dataArr = [];
      arr.forEach((contact) => {
        const data = {
          ...contact,
          key: contact?.id,
          label: contact?.contact_name,
          labelSelected: null,
        };
        dataArr.push(data);
      });
      newContactData[key] = dataArr;
    });
    return newContactData;
  }, [contactDataAll]);

  const [selectedContact, setSelectedContact] = useState({});

  const [assignToGroupAllData, setAssignToGroupAllData] = useState([]);
  const [shipmentChargeType, setShipmentChargeType] = useState([]);

  const [fullPrice, setFullPrice] = useState({});
  const [totalData, setTotalData] = useState({});
  const [docShipmentNumberData, setDocShipmentNumberData] = useState([]);
  const [optimizeLTLOrder, setOptimizeLTLOrder] = useState([]);
  const [mapDragRoute, setMapDragRoute] = useState([]);

  const [createShipmentData, setCreateShipmentData] = useState({});

  const progressData = useMemo(() => {
    const stepsTL = Number(createShipmentData.frequency) === 2 ? PROGRESS_RECURRING_TL : PROGRESS_TL;
    const stepsLTL = Number(createShipmentData.frequency) === 2 ? PROGRESS_RECURRING_LTL : PROGRESS_LTL;

    return +activeShipmentType === 1
      ? stepsTL.filter((i) =>
          shipmentSettings?.hide_validation ? i.id !== (Number(createShipmentData.frequency) === 2 ? 5 : 4) : true
        )
      : stepsLTL.filter((i) =>
          shipmentSettings?.hide_validation ? i.id !== (Number(createShipmentData.frequency) === 2 ? 5 : 4) : true
        );
  }, [activeShipmentType, shipmentSettings, createShipmentData?.frequency]);

  const getMilesTotal = ({ stopPointsArr, equipment_required, mapValues }) => {
    const params = {
      stop_points: stopPointsArr,
      equipment_required,
      avoid_tolls: mapValues.avoid_tolls,
      hazmat: mapValues.hazmat_id,
      highway_only: mapValues.highway_only,
      mode: mapValues.route_type,
      open_borders: mapValues.open_borders,
    };
    getTotalMiles(params)
      .then((res) => setTotalData({ miles: res?.loaded_miles, time: res?.total_time }))
      .catch(() => {
        // Do nothing
      });
  };

  const getShipmentSettings = async () => {
    setLoadingSettings(true);
    try {
      const { data } = await GetSettingsShipments();
      setShipmentSettings(data);
      if (data) {
        const initialData = {
          copy_count: '1',
          frequency: initialFrequency || '1',
          shipment_copy_id: '',
          shipment_details_type: '1',
          type: data.default_global_data && data.default_shipment_type ? String(data.default_shipment_type) : '1',
        };

        if (Number(data.default_shipment_type) === 2) {
          initialData.shipmentsLegs = [
            { number: 1, stops: getInitialStopsData(data, Number(initialFrequency) === 2) },
            { number: 2, stops: getInitialStopsData(data, Number(initialFrequency) === 2) },
          ];
        } else {
          initialData.legs = [{ number: 1, stops: getInitialStopsData(data, Number(initialFrequency) === 2) }];
        }

        setCreateShipmentData(initialData);

        if (data?.default_global_data) {
          setActiveShipmentType(Number(data.default_shipment_type));
          if (initialFrequency !== '2') {
            setStep(2);
          }
        }
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingSettings(false);
    }
  };

  const getChargeType = () => {
    return getShipmentChargeType().then((res) => {
      if (res && res?.data) {
        const newData = res.data.map((item) => {
          return {
            ...item,
            label: item?.title,
            key: item.id,
            labelSelected: null,
          };
        });
        updateShipmentChargeType(newData);
        return newData;
      }
      return res;
    });
  };

  const getFullPriceShipment = () => {
    return getFullPrices()
      .then((res) => {
        if (res && res?.data) {
          setFullPrice(res.data);
        }
        return res;
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getCustomerStopPointAfterCreate = (signal) => {
    customerStopPoint(signal, { only_active: 1 })
      .then((res) => {
        setStopPointAll([...res.data]);
        setCurrentStopAddValue(res.data[res.data.length - 1].id);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getContacts = (id, index) => {
    return getContactsBillingCharges(id)
      .then((res) => {
        if (res && res?.data) {
          setContactDataAll((prevState) => ({ ...prevState, [index]: [...res.data] }));
          return res.data;
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getCustomerStopPoint = (signal) => {
    customerStopPoint(signal, { only_active: 1 })
      .then((res) => res && res?.data && setStopPointAll([...res.data]))
      .catch(() => {
        // Do nothing
      });
  };

  const getCommodityAll = useCallback(() => {
    getCommodity().then((res) => {
      if (res && res?.data) {
        updateCommodityAll(res.data);
      }
    });
  }, []);

  const getDetailCopyShipment = (shipment_type = 'tl') => {
    getShipmentsList({ shipment_type })
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((data) => {
            const currencyData = Object.values(CURRENCY).find((item) => item.id === Number(data.currency));
            const originCity = data?.origin_city?.name ? data?.origin_city?.name : '';
            const originState = data?.origin_state?.name ? data?.origin_state?.name : '';
            const destinationCity = data?.destination_city?.name ? data?.destination_city?.name : '';
            const destinationState = data?.destination_state?.name ? `, ${data?.destination_state?.name}` : '';
            const label = ` ${data?.shipment_id}
                                    ${currencyData?.symbol || '$'}${data?.amount}
                                    ${data?.customer_name} ${originCity},${originState} to 
                                    ${destinationCity}${destinationState}`;
            return {
              ...data,
              key: data?.shipment_id,
              label,
              labelSelected: label,
            };
          });

          if (isMounted()) {
            if (shipment_type === 'ltl') {
              setDetailCopyShipmentLTLData(selectData);
            } else {
              setDetailCopyShipmentData(selectData);
            }
          }
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const updateTotalData = (data) => {
    setTotalData(data);
  };

  const updateShipmentData = (createShipmentData) => {
    setCreateShipmentData(createShipmentData);
  };

  const updateSelectedCharges = (charge) => {
    setSelectedCharges(() => charge);
  };

  const updateSelectedGrandTotal = (currency) => {
    setSelectedGrandTotal(currency);
  };

  const updateDocumentsUploadFiles = (value) => {
    setDocumentsUploadFiles(value);
  };

  const updateShipmentType = (value) => {
    setActiveShipmentType(value);
  };

  const updateEquipmentLengthData = (data) => {
    setEquipmentLengthData(data);
  };

  const updateEquipmentRequired = (data) => {
    setEquipmentRequired(data);
  };

  const updateEquipmentId = (data) => {
    setEquipmentId(data);
  };

  const updateCommodityAll = (data) => {
    setCommodityAll(data);
  };

  const updateDocType = (data) => {
    setDocType(data);
  };

  const updateBillToData = (data) => {
    setBillToData(data);
  };

  const updateBillToSelected = (data, index) => {
    if (index || index === 0) {
      setSelectedBillTo((prevState) => {
        const copy = { ...prevState };
        copy[index] = data;
        return copy;
      });

      return;
    }
    setSelectedBillTo(data);
  };

  const updateContactData = (data) => {
    setContactDataAll(data);
  };

  const updateContactSelected = (data, index) => {
    if (index || index === 0) {
      setSelectedContact((prevState) => {
        if (!data) {
          delete prevState[index];
          return prevState;
        }
        return { ...prevState, [index]: data };
      });
      return;
    }
    setSelectedContact(data);
  };

  const updateAssignToGroup = (data) => {
    setAssignToGroupAllData(data);
  };

  const updateShipmentChargeType = (data) => {
    setShipmentChargeType(data);
  };

  const onChangeAddStopPointModal = (value) => {
    setShowAddStopPointModal(value);
  };

  const onChangeContactEditModal = (value) => {
    setShowContactEditModal(value);
  };

  const onChangeCommodityModal = (value) => {
    setShowCommodityModal(value);
  };

  const updateSelectedTotalAmount = (value) => {
    setTotalAmount(value);
  };

  const updatePaymentTermData = (data) => {
    setPaymentTermData(data);
  };

  const updateDocShipmentNumberData = (data) => {
    setDocShipmentNumberData(data);
  };

  const appendRecurringData = (values, formData) => {
    let totalShipmentsCount = 0;

    values.recurs.forEach((item, index) => {
      if (item.checked) {
        const daysCount = weekdaysBetween(values.laneStartDate, values.laneEndDate, index + 1);
        const occurrence = (item.amount || 0) * (daysCount || 0);

        totalShipmentsCount += occurrence;

        formData.append(`lane[days][${item.title}][recurring_shipments_per_day]`, item.amount);

        item.dateSettings.forEach((el, i) => {
          const pickupTimeArray = el.day_wise_pickup_time.split(':');
          formData.append(`lane[days][${item.title}][day_wise_pickup_time][${i}]`, el.day_wise_pickup_time);
          formData.append(
            `lane[days][${item.title}][day_wise_pickup_time_to][${i}]`,
            el.day_wise_pickup_time_to || el.day_wise_pickup_time
          );

          el.delivery_time.slice(1).forEach((time, timeIndex, arr) => {
            const deliveryTimeArray = time.split(':');
            const prevStopStartTimeArray = timeIndex === 0 ? pickupTimeArray : arr[timeIndex - 1].split(':');
            const day_wise_delivery_time_after = Number(el.day_wise_delivery_time_after[timeIndex + 1]) * 1440;

            const minutesToAdd =
              (deliveryTimeArray[0] - prevStopStartTimeArray[0]) * 60 +
              (deliveryTimeArray[1] - prevStopStartTimeArray[1]);
            formData.append(
              `lane[days][${item.title}][day_wise_delivery_time_after][${i}][${timeIndex}]`,
              day_wise_delivery_time_after + minutesToAdd
            );
          });

          (el.delivery_time_to || el.delivery_time).slice(1).forEach((time, timeIndex) => {
            const deliveryTimeArray = time.split(':');
            const deliveryFrom = el.delivery_time[timeIndex + 1].split(':');

            const minutesToAdd = time
              ? (deliveryTimeArray[0] - deliveryFrom[0]) * 60 + (deliveryTimeArray[1] - deliveryFrom[1])
              : 0;
            formData.append(
              `lane[days][${item.title}][day_wise_delivery_time_after_to][${i}][${timeIndex}]`,
              minutesToAdd || 0
            );
          });
        });
      }
    });

    formData.append(`lane[shipments_count]`, totalShipmentsCount);
  };

  const onCreate = ({ lastStepData, isLTL = false, planAssign, offerAssign }) => {
    setShipmentCreateLoading(true);
    const allData = { ...createShipmentData, ...lastStepData };
    const formData = new FormData();
    const updatedData = {};
    if (continueDraft) {
      localStorage.removeItem('createShipmentData');
    }

    function appendToFormData(data, namePrefix = '') {
      if (Array.isArray(data)) {
        if (data[0] && typeof data[0] === 'object') {
          data.forEach((object, objectIndex) => {
            const name = `${namePrefix}[${objectIndex}]`;
            if (object?.documentName === undefined || !!object?.documentName) {
              appendToFormData(object, name);
            }
          });
        } else {
          // Array item no object
          // eslint-disable-next-line no-return-assign
          data.forEach((el, index) => (updatedData[`${namePrefix}[${index}]`] = el));
        }
      } else if (typeof data === 'object' && data !== null) {
        const keys = Object.keys(data);
        keys.forEach((key) => {
          const value = changeValues(key, data[key]);
          const name = isLTL ? nameMapperLTL(namePrefix, key) : nameMapper(namePrefix, key);
          appendToFormData(value, name);
        });
      } else if (
        !(
          /billing\[charges\]\[\d+\]id/g.test(namePrefix) ||
          /legs\[\d+\]\[stops\]\[\d+\]id/g.test(namePrefix) ||
          /shipments\[\d+\]\[billing\]\[charges\]\[\d+\]id/g.test(namePrefix) ||
          /shipments\[\d+\]\[stops\]\[\d+\]id/g.test(namePrefix)
        )
      ) {
        updatedData[namePrefix] = mapper(namePrefix, data);
      }
    }

    const requestData = {
      ...allData,
    };

    if (Number(requestData.frequency) === 2) {
      requestData.laneStartDate = moment(allData.laneStartDate).format('YYYY-MM-DD');
      requestData.laneEndDate = moment(allData.laneEndDate).format('YYYY-MM-DD');
    }

    requestData.recurs = null;

    if (isLTL) {
      // To not put unnecessary data in request form data
      requestData.shipmentsBilling = allData.shipmentsBilling.map((item) => {
        return {
          reference_id: item.reference_id,
          customer_id: item.customer_id,
          payment_term_id: item.payment_term_id,
          contact_user_id: item.contact_user_id,
          billingCharges: item.billingCharges.map((item) => {
            return {
              ...item,
              charges: item.charges?.id || item.charges,
            };
          }),

          // ...item,
          // shipment_id: null,
          // billing_charges: null,
          // billing_customer: null,
          // contact_user: null,
          // groups: null,
          // id: null,
          // total_amount: null,
          // currency_id: null,
          // customer_billed: null,
          // net: null,
          // margin: null,
          // paperwork_received: null,
          // open_balance: null,
          // slave_billing: null,
        };
      });

      requestData.shipmentsLegs = allData.shipmentsLegs.map((leg) => {
        return {
          ...leg,
          stops: leg.stops.map((item) => {
            return {
              ...item,
              scheduled_date: moment(item.scheduled_date).format('YYYY-MM-DD'),
              scheduled_date_to: moment(item.scheduled_date_to || item.scheduled_date).format('YYYY-MM-DD'),
              startTime:
                Number(requestData.frequency) === 2 ? moment().format('HH:mm') : moment(item.startTime).format('HH:mm'),
              endTime:
                Number(requestData.frequency) === 2
                  ? moment().format('HH:mm')
                  : moment(item.endTime || item.startTime).format('HH:mm'),
              cargos: (item.cargos || []).map((i) => ({
                commodity_id: i.commodity?.id,
                description: i.description,
                sku_barcode: i.sku_barcode,
                expected_quantity: i.expected_quantity,
                expected_quantity_type: i.expected_quantity_type?.id,
                expected_weight: i.expected_weight,
                expected_weight_type: i.expected_weight_type?.id,
                expected_weight_unit: i.expected_weight_unit?.id,
                expected_dimensions: i.expected_dimensions,
                expected_dimension_unit: i.expected_dimension_unit?.id,
                expected_stackable: Number(i.expected_stackable),
                value: i.value,
                value_type: i.value_type?.id,
                uuid: i.id,
              })),
              connected_cargos: (item.connected_cargos || []).map((i) => i.id),
              thirdParty: undefined,
            };
          }),
        };
      });
    } else {
      requestData.legs = allData.legs.map((leg) => {
        return {
          ...leg,
          stops: leg.stops?.map((item) => {
            return {
              ...item,
              scheduled_date: moment(item.scheduled_date).format('YYYY-MM-DD'),
              scheduled_date_to: moment(item.scheduled_date_to || item.scheduled_date).format('YYYY-MM-DD'),
              startTime:
                Number(requestData.frequency) === 2
                  ? moment().format('HH:mm')
                  : item.startTime
                  ? moment(item.startTime).format('HH:mm')
                  : moment().format('HH:mm'),
              endTime:
                Number(requestData.frequency) === 2
                  ? moment().format('HH:mm')
                  : item.endTime || item.startTime
                  ? moment(item.endTime || item.startTime).format('HH:mm')
                  : moment().format('HH:mm'),
              cargos: (item.cargos || []).map((i) => ({
                commodity_id: i.commodity?.id,
                description: i.description,
                sku_barcode: i.sku_barcode,
                expected_quantity: i.expected_quantity,
                expected_quantity_type: i.expected_quantity_type?.id,
                expected_weight: i.expected_weight,
                expected_weight_type: i.expected_weight_type?.id,
                expected_weight_unit: i.expected_weight_unit?.id,
                expected_dimensions: i.expected_dimensions,
                expected_dimension_unit: i.expected_dimension_unit?.id,
                expected_stackable: Number(i.expected_stackable),
                value: i.value,
                value_type: i.value_type?.id,
                uuid: i.id,
              })),
              connected_cargos: (item.connected_cargos || []).map((i) => i.id),
              thirdParty: undefined,
            };
          }),
        };
      });

      requestData.billingCharges = allData.billingCharges.map((item) => {
        return {
          ...item,
          charges: item.charges?.id || item.charges,
        };
      });
    }

    appendToFormData(requestData);
    addNoFormData(updatedData, isLTL);

    if (Number(requestData.frequency) === 2) {
      appendRecurringData(allData, formData);
    }

    Object.keys(updatedData).forEach((key) => {
      const value = updatedData[key];
      if (value === 0 || !!value) formData.append(key, value);
    });

    if (isLTL) {
      CreateShipmentPostLTL(formData)
        .then((res) => {
          if (res && res?.data && Number(res?.status) === 200) {
            const numberBeforeSymbol = res.data.data.shipment_id.match(/\d+/g)[0];
            dispatch(
              handleToaster({
                visible: true,
                message: `Shipment ${numberBeforeSymbol} created successfully!`,
                type: 'success',
              })
            );
            if (res?.data?.data?.shipment_id && !planAssign && !offerAssign) {
              setFilterInCreateShipment({ allValue: res?.data?.data?.shipment_id });
            }
            updateOnCreate(!createModalCLose);

            setTimeout(() => {
              onCloseModal(true);
              if (res?.data?.data?.frequency === '2') {
                onRecurringLaneCreateSuccess();
                navigate(`/planner?tab=lanes`);
              } else if (res?.data?.data?.shipment_id && !!planAssign) {
                navigate(`/planner/plan?id=${res?.data?.data?.shipment_id}`);
              } else if (res?.data?.data?.shipment_id && !!offerAssign) {
                navigate(`/planner/plan?id=${res?.data?.data?.shipment_id}&action=offer`);
              }
            }, 100);
          }
        })
        .catch((e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) });
        })
        .finally(() => {
          setShipmentCreateLoading(false);
        });
    } else {
      CreateShipmentPost(formData)
        .then((res) => {
          if (res && res?.data && res?.status === 200) {
            dispatch(
              handleToaster({
                visible: true,
                message: `Shipment ${res.data.data.shipment_id} created successfully!`,
                type: 'success',
              })
            );
            if (res?.data?.data?.shipment_id && !planAssign && !offerAssign) {
              setFilterInCreateShipment({ allValue: res?.data?.data?.shipment_id });
            }
            setTimeout(() => {
              onCloseModal(true);
              if (res?.data?.data?.frequency === '2') {
                onRecurringLaneCreateSuccess();
                navigate(`/planner?tab=lanes`);
              } else if (res?.data?.data?.shipment_id && !!planAssign) {
                navigate(`/planner/plan?id=${res?.data?.data?.shipment_id}`);
              } else if (res?.data?.data?.shipment_id && !!offerAssign) {
                navigate(`/planner/plan?id=${res?.data?.data?.shipment_id}&action=offer`);
              }
            }, 100);
            updateOnCreate(!createModalCLose);
          }
        })
        .catch((e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) });
        })
        .finally(() => {
          setShipmentCreateLoading(false);
        });
    }
  };

  function mapper(name, data) {
    // const regex = /\d+/g;
    // const getNameRegs = /[^0-9[\]]+/gi;
    // const arrNames = name.match(getNameRegs);
    // const arrIndex = name.match(regex);
    // const levelOneName = arrNames[0];
    // const levelTwoName = arrNames[1];
    // const levelOneIndex = arrIndex?.[0];
    // const levelTwoIndex = arrIndex?.[1];
    //
    // if (levelOneIndex && levelTwoIndex && levelOneName === 'legs') {
    //   const endTimes = `${levelOneName}[${levelOneIndex}][${levelTwoName}][${levelTwoIndex}][to]`;
    //   const stop = createShipmentData?.[levelOneName]?.[levelOneIndex]?.[levelTwoName]?.[levelTwoIndex];
    //   switch (name) {
    //     case endTimes:
    //       return [1, 3].includes(Number(stop?.scheduled_type)) ? stop.endTime : stop.startTime;
    //     default:
    //       return data;
    //   }
    // } else if (levelOneIndex && levelTwoIndex && levelOneName === 'shipments' && levelTwoName === 'stops') {
    //   const endTimes = `${levelOneName}[${levelOneIndex}][${levelTwoName}][${levelTwoIndex}][to]`;
    //   const stop = createShipmentData?.shipmentsLegs?.[levelOneIndex]?.stops?.[levelTwoIndex];
    //   switch (name) {
    //     case endTimes:
    //       return [1, 3].includes(Number(stop?.scheduled_type)) ? stop.endTime : stop.startTime;
    //     default:
    //       return data;
    //   }
    // }
    return data;
  }

  function nameMapper(namePrefix, key) {
    const nameInServer = {
      l: `${namePrefix}[${key}]`,
      w: `${namePrefix}[${key}]`,
      h: `${namePrefix}[${key}]`,
      documents: `docs`,
      documentName: `${namePrefix}[type]`,
      documentReferenceId: `${namePrefix}[reference_id]`,
      contact_user_id: 'billing[contact_user_id]',
      payment_term_id: 'billing[payment_term_id]',
      customer_id: 'billing[customer_id]',
      group: 'billing[group]',
      reference_id: 'billing[reference_id]',
      billingCharges: `billing[charges]`,
      charges: `${namePrefix}[charge_type]`,
      qty: `${namePrefix}[quantity]`,
      equipment_tbd: `${namePrefix}[equipment_tbd]`,
      rate: `${namePrefix}[rate]`,
      stops: `${namePrefix}[stops]`,
      stop_point_type: `${namePrefix}[stop_point_type]`,
      bill_type: `${namePrefix}[bill_type]`,
      stop_point_id: `${namePrefix}[stop_point_id]`,
      scheduled_date: `${namePrefix}[scheduled_date]`,
      scheduled_date_to: `${namePrefix}[scheduled_date_to]`,
      dock_high: `${namePrefix}[dock_high]`,
      stackable: `${namePrefix}[stackable]`,
      liftgate_service: `${namePrefix}[liftgate_service]`,
      labor_required: `${namePrefix}[labor_required]`,
      equipment_type: `${namePrefix}[equipment_type]`,
      equipment_action: `${namePrefix}[equipment_action]`,
      equipment_type_length: `${namePrefix}[equipment_type_length]`,
      number: `${namePrefix}[number]`,
      quantity: `${namePrefix}[quantity]`,
      dimensions: `${namePrefix}[dimensions]`,
      weight: `${namePrefix}[weight]`,
      weight_type: `${namePrefix}[weight_type]`,
      value: `${namePrefix}[value]`,
      commodity: `${namePrefix}[commodity]`,
      equipment_id: `${namePrefix}[equipment_id]`,
      hazardous_materials: `${namePrefix}[hazardous_materials]`,
      quantity_type: `${namePrefix}[quantity_type]`,
      scheduled_type: `${namePrefix}[scheduled_type]`,
      startTime: `${namePrefix}[from]`,
      endTime: `${namePrefix}[to]`,
      stop_notes: `${namePrefix}[notes_text]`,
      // 'shipmentsBilling': `shipments${namePrefix}[billing]`
      laneName: `lane[name]`,
      laneStartDate: `lane[start_date]`,
      laneEndDate: `lane[end_date]`,
      leadTime: `lane[lead_time_days]`,
      cargos: `${namePrefix}[cargos]`,
      commodity_id: `${namePrefix}[commodity_id]`,
      description: `${namePrefix}[description]`,
      expected_quantity: `${namePrefix}[expected_quantity]`,
      expected_quantity_type: `${namePrefix}[expected_quantity_type]`,
      expected_weight: `${namePrefix}[expected_weight]`,
      expected_weight_type: `${namePrefix}[expected_weight_type]`,
      expected_weight_unit: `${namePrefix}[expected_weight_unit]`,
      expected_dimensions: `${namePrefix}[expected_dimensions]`,
      expected_dimension_unit: `${namePrefix}[expected_dimension_unit]`,
      expected_stackable: `${namePrefix}[expected_stackable]`,
      value_type: `${namePrefix}[value_type]`,
      sku_barcode: `${namePrefix}[sku_barcode]`,
      uuid: `${namePrefix}[uuid]`,
      connected_cargos: `${namePrefix}[connected_cargos]`,

      plan_ahead_type: `${namePrefix}[plan_ahead_type]`,
      driver_id: `${namePrefix}[driver_id]`,
      carrier_id: `${namePrefix}[carrier_id]`,
      carrier_contact_user_id: `${namePrefix}[carrier_contact_user_id]`,
      location: `${namePrefix}[location]`,
      location_lat: `${namePrefix}[location_lat]`,
      location_lng: `${namePrefix}[location_lng]`,
      flat_rate: `${namePrefix}[flat_rate]`,
      immediately: `${namePrefix}[immediately]`,
      next_in_line: `${namePrefix}[next_in_line]`,
      before_scheduled_pickup: `${namePrefix}[before_scheduled_pickup]`,
      before_scheduled_pickup_hours: `${namePrefix}[before_scheduled_pickup_hours]`,
      recipient_customer_contact_book_id: `${namePrefix}[recipient_customer_contact_book_id]`,
      send_pickup_reminder_to_driver: `${namePrefix}[send_pickup_reminder_to_driver]`,
      send_pickup_reminder_to_driver_before_minutes: `${namePrefix}[send_pickup_reminder_to_driver_before_minutes]`,
      driver_ids: `${namePrefix}[driver_ids]`,
      carrier_ids: `${namePrefix}[carrier_ids]`,
      available_drivers: `${namePrefix}[available_drivers]`,
      next_available_drivers: `${namePrefix}[next_available_drivers]`,
      radius: `${namePrefix}[radius]`,
      start_from_type: `${namePrefix}[start_from_type]`,
      offer_amount: `${namePrefix}[offer_amount]`,
      note: `${namePrefix}[note]`,
      expire_shipment_offer: `${namePrefix}[expire_shipment_offer]`,
      expire_shipment_offer_unit: `${namePrefix}[expire_shipment_offer_unit]`,
      auto_repost: `${namePrefix}[auto_repost]`,
      retract_carrier_award: `${namePrefix}[retract_carrier_award]`,
      retract_carrier_award_unit: `${namePrefix}[retract_carrier_award_unit]`,
      auto_award_first_lowest_amount: `${namePrefix}[auto_award_first_lowest_amount]`,
      auto_award_first_lowest_amount_unit: `${namePrefix}[auto_award_first_lowest_amount_unit]`,
      auto_dispatch: `${namePrefix}[auto_dispatch]`,
      require_minimum_margin: `${namePrefix}[require_minimum_margin]`,
      require_minimum_margin_percent: `${namePrefix}[require_minimum_margin_percent]`,
    };

    const name = nameInServer[key] ? nameInServer[key] : namePrefix + key;
    return name;
  }

  /**
   * uncorrected data names
   * 1. file
   * 2. shipments[0][billing][sub_total] - subTotal
   * 3. shipments[0][billing][total_amount] - grandTotal
   * * */
  function nameMapperLTL(namePrefix, key) {
    const nameInServer = {
      shipmentsBilling: `shipments${namePrefix}`,
      customer_id: `${namePrefix}[billing][customer_id]`,
      group: `${namePrefix}[billing][group]`,
      reference_id: `${namePrefix}[billing][reference_id]`,
      contact_user_id: `${namePrefix}[billing][contact_user_id]`,
      payment_term_id: `${namePrefix}[billing][payment_term_id]`,
      billingCharges: `${namePrefix}[billing][charges]`,

      shipmentsDoc: `shipments${namePrefix}`,
      documentName: `${namePrefix}[type]`,
      documentReferenceId: `${namePrefix}[reference_id]`,
      equipment_tbd: `${namePrefix}[equipment_tbd]`,
      shipmentsLegs: `shipments${namePrefix}`,
      l: `${namePrefix}[${key}]`,
      w: `${namePrefix}[${key}]`,
      h: `${namePrefix}[${key}]`,
      charges: `${namePrefix}[charge_type]`,
      qty: `${namePrefix}[quantity]`,
      rate: `${namePrefix}[rate]`,
      stops: `${namePrefix}[stops]`,
      stop_point_type: `${namePrefix}[stop_point_type]`,
      bill_type: `${namePrefix}[bill_type]`,
      stop_point_id: `${namePrefix}[stop_point_id]`,
      scheduled_date: `${namePrefix}[scheduled_date]`,
      scheduled_date_to: `${namePrefix}[scheduled_date_to]`,
      dock_high: `${namePrefix}[dock_high]`,
      stackable: `${namePrefix}[stackable]`,
      liftgate_service: `${namePrefix}[liftgate_service]`,
      labor_required: `${namePrefix}[labor_required]`,
      equipment_type: `${namePrefix}[equipment_type]`,
      equipment_action: `${namePrefix}[equipment_action]`,
      equipment_type_length: `${namePrefix}[equipment_type_length]`,
      number: `${namePrefix}[number]`,
      quantity: `${namePrefix}[quantity]`,
      dimensions: `${namePrefix}[dimensions]`,
      weight: `${namePrefix}[weight]`,
      weight_type: `${namePrefix}[weight_type]`,
      value: `${namePrefix}[value]`,
      commodity: `${namePrefix}[commodity]`,
      equipment_id: `${namePrefix}[equipment_id]`,
      hazardous_materials: `${namePrefix}[hazardous_materials]`,
      quantity_type: `${namePrefix}[quantity_type]`,
      scheduled_type: `${namePrefix}[scheduled_type]`,
      startTime: `${namePrefix}[from]`,
      endTime: `${namePrefix}[to]`,
      stop_notes: `${namePrefix}[notes_text]`,
      docs: `${namePrefix}[docs]`,
      laneName: `lane[name]`,
      laneStartDate: `lane[start_date]`,
      laneEndDate: `lane[end_date]`,
      leadTime: `lane[lead_time_days]`,
      cargos: `${namePrefix}[cargos]`,
      commodity_id: `${namePrefix}[commodity_id]`,
      description: `${namePrefix}[description]`,
      expected_quantity: `${namePrefix}[expected_quantity]`,
      expected_quantity_type: `${namePrefix}[expected_quantity_type]`,
      expected_weight: `${namePrefix}[expected_weight]`,
      expected_weight_type: `${namePrefix}[expected_weight_type]`,
      expected_weight_unit: `${namePrefix}[expected_weight_unit]`,
      expected_dimensions: `${namePrefix}[expected_dimensions]`,
      expected_dimension_unit: `${namePrefix}[expected_dimension_unit]`,
      expected_stackable: `${namePrefix}[expected_stackable]`,
      value_type: `${namePrefix}[value_type]`,
      sku_barcode: `${namePrefix}[sku_barcode]`,
      uuid: `${namePrefix}[uuid]`,
      connected_cargos: `${namePrefix}[connected_cargos]`,

      plan_ahead_type: `${namePrefix}[plan_ahead_type]`,
      driver_id: `${namePrefix}[driver_id]`,
      carrier_id: `${namePrefix}[carrier_id]`,
      carrier_contact_user_id: `${namePrefix}[carrier_contact_user_id]`,
      location: `${namePrefix}[location]`,
      location_lat: `${namePrefix}[location_lat]`,
      location_lng: `${namePrefix}[location_lng]`,
      flat_rate: `${namePrefix}[flat_rate]`,
      immediately: `${namePrefix}[immediately]`,
      next_in_line: `${namePrefix}[next_in_line]`,
      before_scheduled_pickup: `${namePrefix}[before_scheduled_pickup]`,
      before_scheduled_pickup_hours: `${namePrefix}[before_scheduled_pickup_hours]`,
      recipient_customer_contact_book_id: `${namePrefix}[recipient_customer_contact_book_id]`,
      send_pickup_reminder_to_driver: `${namePrefix}[send_pickup_reminder_to_driver]`,
      send_pickup_reminder_to_driver_before_minutes: `${namePrefix}[send_pickup_reminder_to_driver_before_minutes]`,
      driver_ids: `${namePrefix}[driver_ids]`,
      carrier_ids: `${namePrefix}[carrier_ids]`,
      available_drivers: `${namePrefix}[available_drivers]`,
      next_available_drivers: `${namePrefix}[next_available_drivers]`,
      radius: `${namePrefix}[radius]`,
      start_from_type: `${namePrefix}[start_from_type]`,
      offer_amount: `${namePrefix}[offer_amount]`,
      note: `${namePrefix}[note]`,
      expire_shipment_offer: `${namePrefix}[expire_shipment_offer]`,
      expire_shipment_offer_unit: `${namePrefix}[expire_shipment_offer_unit]`,
      auto_repost: `${namePrefix}[auto_repost]`,
      retract_carrier_award: `${namePrefix}[retract_carrier_award]`,
      retract_carrier_award_unit: `${namePrefix}[retract_carrier_award_unit]`,
      auto_award_first_lowest_amount: `${namePrefix}[auto_award_first_lowest_amount]`,
      auto_award_first_lowest_amount_unit: `${namePrefix}[auto_award_first_lowest_amount_unit]`,
      auto_dispatch: `${namePrefix}[auto_dispatch]`,
      require_minimum_margin: `${namePrefix}[require_minimum_margin]`,
      require_minimum_margin_percent: `${namePrefix}[require_minimum_margin_percent]`,
    };

    const name = nameInServer[key] ? nameInServer[key] : namePrefix + key;
    return name;
  }

  function addNoFormData(result, isLTL) {
    if (isLTL) {
      let levelOneIndex = '0';
      let levelTwoIndex = '0';
      Object.keys(result).forEach((key) => {
        const regex = /\d+/g;
        const arrIndex = key?.match(regex);
        levelOneIndex = arrIndex?.[0] || '0';
        levelTwoIndex = arrIndex?.[1] || '0';

        const qty = result[`shipments[${levelOneIndex}][billing][charges][${levelTwoIndex}][quantity]`];
        const rate = result[`shipments[${levelOneIndex}][billing][charges][${levelTwoIndex}][rate]`];
        if (
          documentsUploadFiles?.[levelOneIndex]?.[levelTwoIndex] &&
          Object.keys(documentsUploadFiles?.[levelOneIndex]?.[levelTwoIndex])?.length > 0
        ) {
          result[`shipments[${levelOneIndex}][docs][${levelTwoIndex}][file]`] =
            documentsUploadFiles[levelOneIndex][levelTwoIndex];
          result[`shipments[${levelOneIndex}][billing][total_amount]`] = totalAmount[levelOneIndex];
        }

        if (!!qty && !!rate) {
          result[`shipments[${levelOneIndex}][billing][charges][${levelTwoIndex}][sub_total]`] = (qty * rate).toFixed(
            2
          ); // numberWithCommas(qty * rate, '.')
          result[`shipments[${levelOneIndex}][billing][charges][${levelTwoIndex}][currency_id]`] =
            selectedGrandTotal[levelOneIndex]?.id;
        }
      });
      createShipmentData.shipmentsBilling.forEach((_, index) => {
        result[`shipments[${index}][billing][currency_id]`] = selectedGrandTotal[index]?.id;
        result[`shipments[${index}][billing][total_amount]`] = totalAmount[index];
      });
      const orders = optimizeLTLOrder.map((el) => el?.order);
      result.orders = JSON.stringify(orders);
      result.route = JSON.stringify(mapDragRoute);
    } else {
      Object.keys(result).forEach((key) => {
        const regex = /\d+/g;
        const arrIndex = key?.match(regex);
        const levelOneIndex = arrIndex?.[0] || '0';

        const qty = result[`billing[charges][${levelOneIndex}][quantity]`];
        const rate = result[`billing[charges][${levelOneIndex}][rate]`];
        if (documentsUploadFiles[levelOneIndex] && Object.keys(documentsUploadFiles).length > 0) {
          result[`docs[${levelOneIndex}][file]`] = documentsUploadFiles[levelOneIndex];
        }
        if (!!qty && !!rate) {
          result[`billing[charges][${levelOneIndex}][sub_total]`] = (qty * rate).toFixed(2); // numberWithCommas(qty * rate, '.')
          result[`billing[charges][${levelOneIndex}][currency_id]`] = selectedGrandTotal[0]?.id;
        }
      });
      result[`billing[currency_id]`] = selectedGrandTotal[0]?.id;
      result[`billing[total_amount]`] = totalAmount[0];
      result.route = JSON.stringify(mapDragRoute);
    }
  }

  function changeValues(key, value) {
    const values = {
      bill_type: value ? 1 : 2,
      dock_high: value ? 1 : 0,
      stackable: value ? 1 : 0,
      liftgate_service: value ? 1 : 0,
      labor_required: value ? 1 : 0,
      hazardous_materials: value ? 1 : 0,
    };
    return values?.[key] !== undefined ? values?.[key] : value;
  }

  const currentStep = useMemo(() => {
    switch (step) {
      case 1:
        return <TypeOfShipment />;
      case 2:
        return +activeShipmentType === 1 ? <ShipmentStops /> : <BillingChargesLTL />;
      case 3:
        return +activeShipmentType === 1 ? <BillingCharges /> : <ShipmentStopsLTL />;
      case 4:
        return shipmentSettings?.hide_validation ? (
          +activeShipmentType === 1 ? (
            <Documents />
          ) : (
            <DocumentsLTL />
          )
        ) : (
          <ValidationPage />
        );
      case 5:
        return +activeShipmentType === 1 ? <Documents /> : <DocumentsLTL />;
      default:
        return <TypeOfShipment />;
    }
  }, [step, activeShipmentType, shipmentSettings]);

  const currentRecurringStep = useMemo(() => {
    switch (step) {
      case 1:
        return <TypeOfShipment />;
      case 2:
        return +activeShipmentType === 1 ? <ShipmentStops /> : <BillingChargesLTL />;
      case 3:
        return +activeShipmentType === 1 ? <Schedule /> : <ShipmentStopsLTL />;
      case 4:
        return +activeShipmentType === 1 ? <BillingCharges /> : <Schedule />;
      case 5:
        return shipmentSettings?.hide_validation ? <PlanAhead /> : <ValidationPage />;
      case 6:
        return <PlanAhead />;
      default:
        return <TypeOfShipment />;
    }
  }, [step, activeShipmentType, shipmentSettings]);

  const styles = useMemo(() => {
    const isTL = +activeShipmentType === 1;
    const isLTL = +activeShipmentType === 2;
    const condLTl = +step !== 3 && isLTL && +step !== 2 && isLTL && +step !== 4 && isLTL && +step !== 5 && isLTL;
    return {
      layout: {
        backgroundColor: (+step !== 2 && isTL) || condLTl ? palette.gray0 : palette.white,
      },
      header: { backgroundColor: palette.white },
      body: {
        marginTop: isLTL && +step === 4 && 0,
      },
    };
  }, [step, activeShipmentType]);

  useEffect(() => {
    getChargeType().then();
    if (initialFrequency) {
      setCreateShipmentData((prevState) => ({ ...prevState, frequency: initialFrequency }));
    }

    if (typeof initialValueOutSide === 'object' && Object.keys(initialValueOutSide).length > 0) {
      setCreateShipmentData(initialValueOutSide);
    } else {
      getShipmentSettings();
    }
    getDetailCopyShipment('tl');
    getDetailCopyShipment('ltl');
    return () => {
      setInitialValueOutSide({});
    };
  }, []);

  return (
    <div className={sm.createModalLayout} style={styles.layout}>
      <div className={sm.header} style={styles.header}>
        <Typography variant='h5'>
          {Number(createShipmentData?.frequency) === 2 ? 'Create Recurring Lane' : 'Create Shipment'}
        </Typography>
        <HeaderProgress step={step} progressData={progressData} />
      </div>
      {loadingSettings ? (
        <Loader loading size={30} />
      ) : (
        <div className={sm.body} style={styles.body}>
          <ShipmentContext.Provider
            value={{
              setStep: (v) => setStep(v),
              stopPoints,
              activeShipmentType,
              getCustomerStopPointAfterCreate,
              getCustomerStopPoint,
              currentStopAddValue,
              setCurrentStopAddValue,
              setCurrentStopAddIndex,
              currentStopAddIndex,
              detailCopyShipmentData,
              detailCopyShipmentLTLData,
              getDetailCopyShipment,
              updateShipmentType,
              onCloseModal,
              createShipmentData,
              updateShipmentData,
              selectedCharges,
              updateSelectedCharges,
              selectedGrandTotal,
              updateSelectedGrandTotal,
              documentsUploadFiles,
              updateDocumentsUploadFiles,
              equipmentLengthData,
              updateEquipmentLengthData,
              equipmentRequired,
              updateEquipmentRequired,
              equipmentId,
              updateEquipmentId,
              commodity,
              updateCommodityAll,
              docType,
              updateDocType,
              // billing charges
              getContacts,
              billToData,
              updateBillToData,
              selectedBillTo,
              updateBillToSelected,
              contactData,
              updateContactData,
              selectedContact,
              updateContactSelected,
              assignToGroupAllData,
              updateAssignToGroup,
              shipmentChargeType,
              updateShipmentChargeType,
              // addStopPointModal
              showAddStopPointModal,
              onChangeAddStopPointModal,
              //  bill charge contact edit
              showContactEditModal,
              onChangeContactEditModal,
              // commodity modal
              showCommodityModal,
              onChangeCommodityModal,
              // on close update
              createModalCLose,
              updateOnCreate,
              updateSelectedTotalAmount,
              onCreate,
              paymentTermData,
              updatePaymentTermData,
              fullPrice,
              getFullPriceShipment,
              totalData,
              updateTotalData,
              getMilesTotal,
              setActiveBillChargeIndex,
              docShipmentNumberData,
              updateDocShipmentNumberData,
              optimizeLTLOrder,
              setOptimizeLTLOrder,
              mapDragRoute,
              setMapDragRoute,
              shipmentSettings,
              shipmentCreateLoading,
            }}
          >
            {Number(createShipmentData?.frequency) === 2 ? currentRecurringStep : currentStep}
          </ShipmentContext.Provider>
        </div>
      )}
      {!!showAddStopPointModal && (
        <AddStopPointModal
          show={showAddStopPointModal}
          getCustomerStopPoint={getCustomerStopPointAfterCreate}
          onChangeShow={onChangeAddStopPointModal}
        />
      )}

      <ContactEditModal
        contactData={contactData}
        getContacts={getContacts}
        show={showContactEditModal}
        selectedBillTo={selectedBillTo}
        updateContactData={updateContactData}
        onChangeShow={onChangeContactEditModal}
        activeBillChargeIndex={activeBillChargeIndex}
      />
      <CommoditySettingsModal
        commodity={commodity}
        show={showCommodityModal}
        onChangeShow={onChangeCommodityModal}
        updateCommodityAll={getCommodityAll}
      />
    </div>
  );
};

export default Layout;
