import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onGeneratePdf }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onPrintPdf = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    onGeneratePdf();
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li onClick={(event) => onPrintPdf(event)}>Print PDF</li>
    </ThreeDotActions>
  );
};

export default RowActions;
