import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import classes from './UnReserve.module.scss';

const UnReserve = ({ isOpen, setIsOpen, onAddBtn, title, tableData, endPointData }) => {
  const { use } = useTheme();
  const styles = useMemo(() => {
    return {
      styleBody: {
        maxHeight: 600,
        overflowY: 'auto',
        padding: '20px',
      },
      modal: {
        width: 440,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
      labelStyle: {
        margin: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      inputDriver: {
        height: 32,
        width: '100%',
        borderRadius: '0 4px 4px 0',
      },
      selectStyle: {
        height: 32,
        width: 221,
        borderRadius: '4px 0 0 4px',
        marginLeft: 36,
      },
      dropDownStyles: {
        flexGrow: 0,
        width: 221,
      },
      menuStyle: {
        width: 221,
        marginLeft: 36,
      },
    };
  }, [use, palette]);

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Modal.Body style={styles.modalBody}>
        <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <Typography variant='button2'>{title}</Typography>
        </div>

        <div
          className={classes.modalBody}
          style={{
            backgroundColor: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.gray700),
          }}
        >
          <Typography variant='s2'>
            Are you sure you want to unreserve&nbsp;
            {endPointData?.shipment?.shipment_id}&nbsp; reserved by&nbsp;
            {endPointData?.user?.first_name} {endPointData?.user?.last_name}&nbsp;
            {endPointData?.type === 'always' ? 'until shipment is dispatched' : `for ${endPointData?.remaining_time}`}?
          </Typography>
        </div>

        {/* // footer */}
        <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <div className={classes.modalFooterLane} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
            <CustomButton
              type='secondary'
              title='Cancel'
              onClick={() => setIsOpen(false)}
              styleButton={{ padding: '2px 8px', marginTop: 0 }}
            />
            <CustomButton
              type='primary'
              title='Yes, Unreserve'
              onClick={() => onAddBtn(tableData?.item?.id, { title: 'Shipment was unreserved successfully.' })}
              styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnReserve;
