import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  border-bottom: 1px solid ${palette.gray50};
  padding: 16px 0 30px;
`;

export const SBackButton = styled.div`
  color: ${palette.indigo500};
  cursor: pointer;
  width: fit-content;
  margin: 4px 0 12px;
  display: flex;
  align-items: center;
  column-gap: 6px;

  &:hover {
    text-decoration: underline;
  }
`;

export const SLink = styled.span`
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
`;

export const SFlexWrapper = styled.div`
  display: flex;

  .external-account {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 450px;
    border-right: 1px solid ${palette.gray50};

    .external-account-header {
      display: flex;
      justify-content: space-between;
      padding: 32px 0 8px;
      border-bottom: 1px solid ${palette.gray50};
    }

    .external-account-details {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 8px 0;

      .detail-row {
        display: flex;

        .label {
          width: 200px;
        }
      }
    }
  }

  .card-accounts {
    width: 50%;
    display: flex;
    min-width: 450px;
    flex-direction: column;

    .card-accounts-header {
      padding: 32px 0 12px 16px;
      border-bottom: 1px solid ${palette.gray50};
    }

    .card-accounts-details {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 16px;

      .detail-row {
        display: flex;
        justify-content: space-between;

        .account-name {
          display: flex;
          flex-direction: column;
          row-gap: 0.25rem;
          width: 220px;
        }

        .balance {
          display: flex;
          flex-direction: column;
          row-gap: 0.25rem;
          align-items: end;
          width: 120px;
        }

        .actions {
          display: flex;
          justify-content: end;
          gap: 0.5rem;
          width: 170px;
        }
      }

      .detail-row:first-child {
        border-bottom: 1px solid ${palette.gray50};
        padding-bottom: 16px;
      }
    }
  }

  .feat-not-available {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 104px;
  }
`;
