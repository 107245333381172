import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import Tooltip from 'common/Tooltip';
import HeaderStar from 'components/HeaderStar';
import QuickbooksSync from 'componentsV2/QuickbooksSync';
import CustomButton from 'components/CustomButton/CustomButton';
import AddCustomer from 'components/Customers/AddCustomer/AddCustomer';
import { palette } from 'utils/constants';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/download2.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import ImportCsv from './ImportCsv';
import styles from './CustomersHeader.module.css';
import PopoverSettingsCustomers from '../PopoverSettings/PopoverSettingsCustomer';
import CustomerSearch from '../Customers/CustomerSearch/CustomerSearch';
import DarkOverlay from '../DarkOverlay';
import { useTheme } from '../../context/themeContext';

const CustomersHeader = ({
  getCustomers,
  exportCsv,
  filter,
  dragItem,
  getSettings,
  updateFilter,
  tableColumn,
  search,
  setSearch,
  advancedOpen,
  setAdvancedOpen,
  onSearch,
}) => {
  const { use } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [openImportCsv, setOpenImportCsv] = useState(false);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: use(palette.white, palette.dark800) }}>
      <Popover.Body>
        <PopoverSettingsCustomers
          filter={filter}
          dragItem={dragItem}
          getSettings={getSettings}
          updateFilter={updateFilter}
          tableColumn={tableColumn}
          setShowPopover={(isOpen) => setShow(isOpen)}
        />
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (location?.state?.openCreate) {
      setOpenAddCustomer(true);
    }
  }, [location?.state]);

  return (
    <>
      <div className={styles.plannerHeader_wrapper}>
        <HeaderStar title='Customers' />
        <div className='d-flex align-items-center gap-3'>
          <QuickbooksSync action='customer' onSuccess={getCustomers} />
          <CustomButton
            type='secondary'
            title='Print Report'
            leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => navigate('/reports/4')}
          />
          <Tooltip title='Import from CSV'>
            <CustomButton
              type='secondary'
              leftIcon={<ImportIcon fill={palette.gray700} />}
              styleButton={{ padding: '9px 12px', margin: 0 }}
              onClick={() => setOpenImportCsv(true)}
            />
          </Tooltip>
          <Tooltip title='Export CSV'>
            <CustomButton
              type='secondary'
              leftIcon={<ExportIcon fill={palette.gray700} />}
              styleButton={{ padding: '9px 12px', margin: 0 }}
              onClick={exportCsv}
            />
          </Tooltip>
          <CustomButton
            width={12}
            height={12}
            type='primary'
            title='Add Customer'
            leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px' }}
            onClick={() => setOpenAddCustomer(true)}
          />
        </div>
        <Modal
          show={openAddCustomer}
          onHide={() => setOpenAddCustomer(false)}
          backdrop='static'
          centered
          id='customer-add-modal'
        >
          <AddCustomer close={() => setOpenAddCustomer(false)} getCustomers={getCustomers} />
        </Modal>
        {openImportCsv && (
          <ImportCsv open={openImportCsv} onClose={() => setOpenImportCsv(false)} onSuccess={getCustomers} />
        )}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        <div className='d-flex align-items-start'>
          <OverlayTrigger
            trigger='click'
            placement='bottom-start'
            overlay={popOverContent}
            rootClose={show}
            onToggle={setShow}
            show={show}
            defaultShow={false}
            delay={0}
          >
            <div className='top_popover' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
              <DataTableIcon widht={14} height={14} fill={use(palette.gray700, palette.white)} />
            </div>
          </OverlayTrigger>
          <CustomerSearch search={search} onChange={setSearch} />
          <CustomButton
            type='primary'
            title='Search'
            onClick={() => onSearch()}
            styleTitle={{ fontSize: 14 }}
            styleButton={{ padding: '5px 12px', margin: '0 0 0 8px' }}
          />
          <CustomButton
            type='primary'
            title=''
            leftIcon={
              advancedOpen ? (
                <MinusIcon fill='#fff' style={{ margin: 8 }} />
              ) : (
                <PlusIcon fill='#fff' style={{ margin: 8 }} />
              )
            }
            className='ms-3'
            styleButton={{ height: 32, margin: 0 }}
            onClick={() => setAdvancedOpen(!advancedOpen)}
          />
          <DarkOverlay visible={show} styles={{ zIndex: 500 }} />
        </div>
      </form>
    </>
  );
};
export default CustomersHeader;
