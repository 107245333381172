import { date, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  expire_date: date().when('no_expiration', {
    is: (no_expiration) => !no_expiration,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  attachment: string().trim().required('Required').nullable(),
  status: object()
    .shape({ value: string().required('Required') })
    .required('Required')
    .nullable(),
});
