import { createSlice } from '@reduxjs/toolkit';
import { SHIPMENT_DETAILS_TABS } from 'components/TableShipments/helpers/constants';

const initialState = {
  shipmentData: { data: [] },
  activeTabDetails: SHIPMENT_DETAILS_TABS[0],
  assignToGroupsData: [],
  billToData: [],
  recentActivity: [],
};
export const counterSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    onChangeActiveTab: (state, action) => {
      state.activeTabDetails = action.payload;
    },
    updateAssignToGroup: (state, action) => {
      state.assignToGroupsData = action.payload;
    },
    updateBillToData: (state, action) => {
      state.billToData = action.payload;
    },
    updateRecentActivity: (state, action) => {
      state.recentActivity = action.payload;
    },
    addRecentActivity: (state, action) => {
      state.recentActivity = [{ ...action.payload }, ...(state.recentActivity || {})];
    },
  },
});

export const { onChangeActiveTab, updateAssignToGroup, updateBillToData, updateRecentActivity, addRecentActivity } =
  counterSlice.actions;

export default counterSlice.reducer;
