import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin-bottom: 8px;

  .delete-wrapper {
    margin: 14px 0;
    height: 0;
    transition: 0.3s;
    opacity: 0;

    &.button-visible {
      height: 33px;
      opacity: 1;
    }
  }
`;

export const SHeaderWrapper = styled.div`
  margin: 12px 0 16px;

  .actions-title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }
  .actions-wrapper {
    display: flex;
    column-gap: 12px;
  }
`;

export const SBackButton = styled.div`
  margin: 4px 0 12px;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const SLink = styled(Link)`
  color: ${palette.indigo500};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: ${palette.indigo500};
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 20px;
  }

  td {
    padding: 12px 20px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }
`;

export const STableRow = styled.tr`
  cursor: pointer;
  background-color: ${({ $bgColor }) => $bgColor || ''};

  td {
    max-width: 330px;
  }

  transition: background-color 0.2s;

  :hover {
    background-color: ${palette.gray0};
  }

  &.no-border-bottom {
    td {
      border-bottom: none;
    }
  }
`;

export const STableData = styled.td`
  width: ${({ $width }) => $width || '100%'};
`;

export const STransactionsTableWrapper = styled.div`
  overflow: auto;

  .table-container {
    width: max-content;
    min-width: 100%;

    &.fixed-header {
      height: calc(100vh - 290px);
      transition: height 0.3s;

      &.small-height {
        height: calc(100vh - 308px);
      }

      thead {
        th {
          box-shadow: inset 0px -3px 0px -2px ${palette.gray50};
          background-color: ${palette.white};
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SStatusDot = styled.span`
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: ${({ color }) => color || palette.gray700};
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  :hover span {
    text-decoration: underline;
  }
`;
