import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import {
  assignUser,
  connectService,
  connectServiceStaff,
  getAllConnectedServices,
  getCardNumbers,
  getDriverStaffList,
} from 'Api/CardManagement';
import { cardProviders } from '../../Cards.data';
import { validationSchema } from './validationSchema';
import { SBackdrop, SCustomModal } from '../../Cards.styles';
import { SPaper, STabs } from './LinkCard.styles';

const LinkCard = ({ open, onClose, assignData, onSuccess }) => {
  const showToaster = useShowToaster();
  const [driverStaff, setDriverStaff] = useState([]);
  const [assignToTab, setAssignToTab] = useState('all');
  const [cardNumbers, setCardNumbers] = useState([]);
  const [openPopover, setOpenPopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);

  const getCardNumbersList = async (provider_id) => {
    try {
      const { data } = await getCardNumbers({ 'filter[provider_id]': provider_id });
      setCardNumbers(data.map((item) => item.card_number));
    } catch (e) {
      // Do nothing
    }
  };

  const getConnectedServices = async () => {
    try {
      const { data } = await getAllConnectedServices();
      const wex1 = data.find((item) => item.id === 44);
      const wex2 = data.find((item) => item.id === 62);
      const providersList = [];
      if (wex1?.connected) {
        providersList.push(cardProviders[0]);
      }
      if (wex2?.connected) {
        providersList.push(cardProviders[1]);
      }
      setProviders(providersList);
    } catch (e) {
      // Do nothing
    }
  };

  const connectWexService = async (userId, cardNumber, userType) => {
    const body = {
      driver_id: userType === 'driver' ? userId : undefined,
      staff_id: userType === 'staff' ? userId : undefined,
      connected_service_id: 44,
      fields: [{ id: 219, custom_field_value: cardNumber }],
    };
    try {
      if (userType === 'driver') {
        await connectService(body);
      } else {
        await connectServiceStaff(body);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('cardNumber', values.card_number);
      formData.append('user_id', values.assign_to.id);
      formData.append('user_name', values.assign_to.full_name);
      formData.append('user_type', values.assign_to.user_type === 'driver' ? '2' : '1');
      formData.append('provider_id', values.card_provider?.id || '');

      await assignUser(formData);
      await connectWexService(values.assign_to.id, values.card_number, values.assign_to.user_type);
      showToaster({ type: 'success', message: 'User has been successfully assigned' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: {
      card_provider: cardProviders.find((i) => i.id === assignData?.card_provider?.id) || null,
      card_number: assignData?.card_number || null,
      assign_to: null,
    },
    onSubmit,
    validationSchema,
  });

  const getDriverStaff = async () => {
    try {
      const { data } = await getDriverStaffList({ 'filter[card]': 'not-assigned' });
      setDriverStaff(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onProviderChange = (value) => {
    if ([].includes(value.id) && values.assign_to?.user_type !== 'driver') {
      setValues((prevState) => ({ ...prevState, card_provider: value, assign_to: null }));
      return;
    }

    setValues((prevState) => ({ ...prevState, card_provider: value, card_number: null }));
  };

  useEffect(() => {
    getDriverStaff();
    getConnectedServices();
  }, []);

  useEffect(() => {
    if (!values.card_provider) {
      return;
    }

    getCardNumbersList(values.card_provider.id);
  }, [values.card_provider]);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Link Card'
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Link Card',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        <div>
          <Autocomplete
            required
            name='card_provider'
            label='Card Provider'
            options={providers}
            value={values.card_provider}
            onChange={(e, val) => onProviderChange(val)}
            disabled={!!assignData && !!values.card_provider}
            error={touchedErrors.card_provider}
          />
        </div>
        <div>
          <Autocomplete
            required
            label='Card Number'
            placeholder='0000 0000 0000 0000'
            options={cardNumbers}
            value={values.card_number}
            onChange={(e, val) => handleChange('card_number', val)}
            disabled={!!assignData}
            error={touchedErrors.card_number}
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CardIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          {/* {values?.card_provider?.name === 'WEX EFS Fleet Card' ? ( */}
          {/*  <Autocomplete */}
          {/*    required */}
          {/*    labelKey='full_name' */}
          {/*    label='Assign To' */}
          {/*    value={values.assign_to} */}
          {/*    onChange={(e, val) => handleChange('assign_to', val)} */}
          {/*    isOptionEqualToValue={(option, value) => option.id === value.id} */}
          {/*    options={driverStaff.filter(i => i.user_type === 'driver')} */}
          {/*    error={touchedErrors.assign_to} */}
          {/*  /> */}
          {/* ) : ( */}
          <Autocomplete
            required
            open={openPopover}
            labelKey='full_name'
            label='Assign To'
            value={values.assign_to}
            inputProps={{
              onClick: () => setOpenPopover(true),
            }}
            onChange={(e, val) => {
              handleChange('assign_to', val);
              setOpenPopover(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={driverStaff.filter((i) =>
              // This will be used when some provider's card should be assigned only to driver/staff type users
              [].includes(values?.card_provider?.id)
                ? i.user_type === 'driver'
                : assignToTab === 'all' || i.user_type === assignToTab
            )}
            error={touchedErrors.assign_to}
            PaperComponent={({ children, ...props }) => {
              return (
                <ClickAwayListener onClickAway={() => setOpenPopover(false)}>
                  <SPaper {...props}>
                    {![].includes(values?.card_provider?.id) && (
                      <STabs
                        value={assignToTab}
                        onChange={(e, tab) => setAssignToTab(tab)}
                        variant='scrollable'
                        scrollButtons={false}
                      >
                        <Tab value='all' label='All' disableRipple />
                        <Tab value='driver' label='Driver' disableRipple />
                        <Tab value='staff' label='Staff' disableRipple />
                      </STabs>
                    )}
                    {children}
                  </SPaper>
                </ClickAwayListener>
              );
            }}
          />
          {/* )} */}
        </div>
      </div>
      <SBackdrop open={loading}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default LinkCard;
