import React from 'react';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import classes from './ApplicantsModal.module.scss';

const MedicalCard = ({ data }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  if (!data) {
    return null;
  }

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray200) }}>
        Medical
      </Typography>
      <Divider />
      <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
        Medical examiners card expires in {formatDate(data?.expiry_date)}
      </Typography>
      <div className='iframe-wrapper'>
        <iframe
          className={classes.examinerCard}
          src={data.upload_examiner_card}
          title='testPdf'
          height='1126px'
          width='100%'
        />
      </div>
    </div>
  );
};

export default MedicalCard;
