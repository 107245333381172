import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SettingsIcon } from 'assets/sidemanu/settings-20px.svg';
import HeaderStar from 'components/HeaderStar';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import AlertsTable from './AlertsTable';
import { SHeaderWrapper, SPageWrapper } from './AlertCenter.styles';

const AlertCenter = () => {
  const navigate = useNavigate();

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Alert Center' />
        <CustomButton
          type='secondary'
          title=''
          leftIcon={<SettingsIcon fill={palette.gray600} />}
          styleButton={{ padding: '10px 12px', margin: 0 }}
          onClick={() => navigate('/company-settings', { state: { tabId: 3, subTabName: 'Alerts' } })}
        />
      </SHeaderWrapper>
      <AlertsTable />
    </SPageWrapper>
  );
};

export default AlertCenter;
