import moment from 'moment';
import { twoDecimals } from '../../helpers';

const convertSchedulesByWeekday = (workingSchedule) => {
  const data = {};

  workingSchedule.forEach((item) => {
    switch (item.day) {
      case 'Mon':
        data.Monday = item;
        break;
      case 'Tue':
        data.Tuesday = item;
        break;
      case 'Wed':
        data.Wednesday = item;
        break;
      case 'Thur':
        data.Thursday = item;
        break;
      case 'Fri':
        data.Friday = item;
        break;
      case 'Sat':
        data.Saturday = item;
        break;
      case 'Sun':
        data.Sunday = item;
        break;
      default:
    }
  });

  return data;
};

export const timeTrackingConverter = (data, workingSchedule) => {
  if (!data) {
    return [];
  }

  const { user, time_trackings } = data;

  const schedules = convertSchedulesByWeekday(workingSchedule || []);
  const pay_per_hour = Number(user?.compensation_details?.per_hour_worked || user?.compensation_details?.per_hr_amt);

  return time_trackings?.map((item) => {
    let scheduleData = {};

    if (item.user_type === 2) {
      const weekDay = moment(item.start_time).format('dddd');
      const scheduledStartTimeForWeekday = schedules[weekDay]?.start_time;
      const scheduledEndTimeForWeekday = schedules[weekDay]?.end_time;

      const actualStartTime = moment(item.start_time);
      const actualEndTime = moment(item.end_time);
      const scheduledStartTime = scheduledStartTimeForWeekday
        ? moment(item.start_time)
            .set('hour', Number(scheduledStartTimeForWeekday.split(':')[0]))
            .set('minute', Number(scheduledStartTimeForWeekday.split(':')[1]))
            .set('second', 0)
        : null;
      const scheduledEndTime = scheduledEndTimeForWeekday
        ? moment(item.end_time)
            .set('hour', Number(scheduledEndTimeForWeekday.split(':')[0]))
            .set('minute', Number(scheduledEndTimeForWeekday.split(':')[1]))
            .set('second', 0)
        : null;

      const startedPrior = scheduledStartTime ? scheduledStartTime.isAfter(actualStartTime) : false;
      const endedAfter = scheduledEndTime ? scheduledEndTime.isBefore(actualEndTime) : false;
      const diff = scheduledStartTime ? scheduledStartTime.diff(actualStartTime, 'minutes') : null;

      scheduleData = {
        startedPrior,
        endedAfter,
        scheduledStartTimeForWeekday,
        scheduledEndTimeForWeekday,
        diffToScheduled: diff,
      };
    }

    const hoursTracked = Number(item.hours_tracked || 0) + Number(item.minutes_tracked || 0) / 60;
    const amount = hoursTracked * pay_per_hour;

    return {
      ...item,
      ...scheduleData,
      amount: twoDecimals(amount),
    };
  });
};
