import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './TransactionTab.css';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import add from 'assets/icons/drivers/add.svg';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import Search from 'common/Search';
import Tooltip from 'common/Tooltip';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import DateRangePicker from 'common/DateRangePicker';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDebounce from 'hooks/useDebounce';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { getAllTransactions, getDriverTransactions, getMerchants, getTransactionCategories } from 'Api/CardManagement';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import TransactionFilters from 'pages/CardManagement/Transactions/components/TransactionFilters';
import { SFiltersWrapper, SSearchWrapper } from 'pages/CardManagement/Transactions/Transactions.styles';
import AddTransaction from './components/AddTransaction';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import { useColumns, transactionStatuses, initialFilters } from './TransactionTab.data';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import CustomButton from '../CustomButton/CustomButton';
import MaterialTableWrapper from '../MaterialTableWrapper';

const TransactionTabTable = ({ isStaff }) => {
  const { use } = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const provider = searchParams.get('provider');
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [modalShow, setModalShow] = useState(false);
  const [driverTransactions, setDriverTransactions] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [updatedData, setUpdatedData] = useState(null);
  const [tableIndex, setTableIndex] = useState(Date.now());
  const [selectedFilters, setSelectedFilters] = useState({
    ...initialFilters,
    providers: provider ? [Number(provider)] : [],
  });
  const [filterType, setFilterType] = useState('All');
  const [search, setSearch] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [receipt, setReceipt] = useState(null);
  const debouncedSearch = useDebounce(search, 500);
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(30, 'days').toDate(),
    end: new Date(),
  });

  const getTransactions = async () => {
    try {
      setLoading(true);
      const params = {
        driver_id: isStaff ? undefined : id,
        'staff_id[]': isStaff ? id : undefined,
        user_type: isStaff ? 'staff' : undefined,
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.page_size,
        query: debouncedSearch || undefined,
        start_date: moment(dateRange.start).format('YYYY-MM-DD'),
        end_date: moment(dateRange.end).format('YYYY-MM-DD'),
        'sort[][date]': 'desc',
        'filter[category][]': selectedFilters?.category?.id || undefined,
        'filter[type][]': selectedFilters?.status?.name || undefined,
        'filter[merchant][]': selectedFilters?.merchant || undefined,
      };
      if (selectedFilters?.providers?.length) {
        selectedFilters.providers.forEach((item, index) => {
          params[`filter[provider][${index}]`] = item;
        });
      }

      if (provider) {
        navigate('.', { replace: true });
      }
      const res = isStaff ? await getAllTransactions(params) : await getDriverTransactions(params);
      setDriverTransactions(res);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const downloadCsv = async (data) => {
    try {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `transactions.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      // Do nothing
    }
  };

  const exportTransactions = async () => {
    try {
      const params = {
        export: true,
        'driver_id[]': isStaff ? undefined : id,
        'staff_id[]': isStaff ? id : undefined,
        user_type: isStaff ? 'staff' : undefined,
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.page_size,
        query: debouncedSearch || undefined,
        start_date: moment(dateRange.start).format('YYYY-MM-DD'),
        end_date: moment(dateRange.end).format('YYYY-MM-DD'),
        'sort[][date]': 'desc',
        'filter[category][]': selectedFilters?.category?.id || undefined,
        'filter[type][]': selectedFilters?.status?.name || undefined,
        'filter[merchant][]': selectedFilters?.merchant || undefined,
      };

      if (selectedFilters?.providers?.length) {
        selectedFilters?.providers.forEach((item, index) => {
          params[`filter[provider][${index}]`] = item;
        });
      }

      const data = await getAllTransactions(params);
      downloadCsv(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getTransactionCategory = async () => {
    try {
      const { data } = await getTransactionCategories();
      setCategoryOptions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getMerchantsList = async () => {
    try {
      const { data } = await getMerchants();
      setMerchants(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onViewReceipt = (receipt) => {
    setReceipt(receipt);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getTransactionCategory();
    getMerchantsList();
  }, []);

  useEffect(() => {
    getTransactions();
  }, [selectedFilters, debouncedSearch, dateRange, tableIndex]);

  const columns = useColumns({ onViewReceipt });

  const PaginationComponent = () => (
    <TableFooter
      rowPerPage={selectedFilters.page_size}
      totalCount={driverTransactions?.total}
      totalLength={driverTransactions?.data?.length}
      lastPage={driverTransactions?.last_page}
      currentPage={driverTransactions?.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
      perPageOptions={[10, 50, 100, 150]}
    />
  );

  return (
    <div
      className='transaction-table-container'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.gray700, palette.gray200),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div style={{ borderBottom: '1px solid #E9EDF5', paddingBottom: '12px' }}>
        <div
          className='transaction-header-container'
          style={{ borderColor: use(palette.gray50, palette.darkborder), height: 'auto', padding: '12px 20px 0' }}
        >
          <div>
            <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
              Card Transactions
            </p>
            <SFiltersWrapper>
              <DateRangePicker
                top='-168px'
                dateRange={dateRange}
                initialRangeName='Last 30 Days'
                setDateRange={setDateRange}
              />
              <SSearchWrapper>
                <div>
                  <Autocomplete
                    width='110px'
                    options={['All', 'Merchant', 'Category', 'Type']}
                    value={filterType}
                    onChange={(e, val) => setFilterType(val)}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <FilterIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {filterType === 'All' && (
                  <Search search={search} onChange={setSearch} className='transaction-search-input' />
                )}
                {filterType === 'Type' && (
                  <Autocomplete
                    width='250px'
                    disableClearable={false}
                    placeholder='Select Type..'
                    options={transactionStatuses}
                    value={selectedFilters.status}
                    onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, status: val }))}
                    className='filter-dropdown'
                  />
                )}
                {filterType === 'Category' && (
                  <Autocomplete
                    width='250px'
                    disableClearable={false}
                    placeholder='Select Category..'
                    labelKey='description'
                    options={categoryOptions}
                    value={selectedFilters.category}
                    onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, category: val }))}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    className='filter-dropdown'
                  />
                )}
                {filterType === 'Merchant' && (
                  <Autocomplete
                    width='250px'
                    disableClearable={false}
                    placeholder='Select Merchant..'
                    options={merchants}
                    value={selectedFilters.merchant}
                    onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, merchant: val }))}
                    className='filter-dropdown'
                  />
                )}
              </SSearchWrapper>
            </SFiltersWrapper>
          </div>
          <div className='d-flex gap-3'>
            <Tooltip title='Export CSV'>
              <span>
                <CustomButton
                  type='secondary'
                  leftIcon={<ExportIcon fill={palette.gray700} />}
                  styleButton={{ padding: '9px 12px', margin: 0 }}
                  onClick={exportTransactions}
                />
              </span>
            </Tooltip>
            {(isStaff || !hideSensitiveInfo) && (
              <PrimaryBtn icon={add} title='Add Transaction' onClick={() => setModalShow(true)} />
            )}
          </div>
        </div>
        <TransactionFilters
          filter={selectedFilters}
          updateFilter={setSelectedFilters}
          resetSearch={() => setSearch('')}
        />
      </div>
      <div className='table-wrap'>
        <div className='sub-table-container'>
          {loading ? (
            <TablePreLoader />
          ) : (
            <MaterialTableWrapper
              data={driverTransactions?.data}
              rowPerPage={selectedFilters.page_size}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{ Pagination: PaginationComponent }}
              columns={columns}
            />
          )}
        </div>
        {modalShow && (
          <AddTransaction
            open={modalShow}
            onClose={() => {
              setModalShow(false);
              setUpdatedData(null);
            }}
            onSuccess={() => setTableIndex(Date.now())}
            userData={{ id }}
            transaction={updatedData}
            isStaff={isStaff}
          />
        )}
      </div>
      <ViewPdf title='Receipt' open={!!receipt} onClose={() => setReceipt(null)} pdfUrl={receipt} />
    </div>
  );
};

export default TransactionTabTable;
