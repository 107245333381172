export const initialValues = {
  ebolDate: '',
  blNumber: '',

  shipperNumber: '',
  trailerNumber: '',

  pickup: null,
  pickupLocationName: '',
  pickupAddress: '',
  pickupCity: '',
  pickupState: '',
  pickupZip: '',

  delivery: null,
  deliveryNumber: '',
  deliveryLocationName: '',
  deliveryAddress: '',
  deliveryCity: '',
  deliveryState: '',
  deliveryZip: '',

  invoice: null,
  customerAddress1: '',
  customerAddress2: '',
  customerCity: '',
  customerState: '',
  customerZipCode: '',

  customerNumber: '',
  storeNumber: '',
  department: '',
  poNumber: '',
  specialInstruction: '',

  contactName: null,
  contactPhone: '',

  guaranteedStandard: false,
  guaranteedMultiday: false,
  timeFrom: null,
  timeTo: null,
  driverByTime: '',
  deliveryBy: false,
  deliveryByDate: null,
  deliverOn: false,
  deliverOnDate: '',
  betweenFrom: '',
  betweenTo: '',
  noDeliveryDate: '',

  CODFee: '',
  CODCheck: '',
  CODAmount: '',

  pickUpDetails: [
    {
      pickUpUnity: '',
      pickUpKind: '',
      pickUpQty: '',
      pickUpMeasure: '',
      pickUpHm: '',
      pickUpDescription: '',
      pickUpNMFCItem: '',
      pickUpClass: '',
      pickUpWeight: '',
      pickUpLength: '',
      pickUpWidth: '',
      pickUpHeight: '',
    },
  ],

  phone: '',
  name: '',
  contract: '',
  collect: false,
  totalCharges: '',

  note1: '',
  note2: '',

  trailerOrVehicleNumber: '',
  signDate: '',
  huReceived: '',

  carrier: '',

  trailerLoadedBy: '',
  freightCountedBy: '',
};
