import * as Yup from 'yup';
import { object, date, number, string } from 'yup';

export const validationSchema = object().shape({
  license_plate_expire_date: date().when('no_expiration', {
    is: false,
    then: date().required('Required').nullable(),
    otherwise: date().nullable(),
  }),
  state: Yup.string().required('Required'),
  license_plate_number: Yup.string().required('Required'),
  upload_registration: Yup.string().required('Required'),
  leasing_company_name: Yup.string().when('owner_operator_type', {
    is: 2,
    then: string().trim().required('Required'),
  }),
  owner_operator: Yup.object().when('owner_operator_type', {
    is: 3,
    then: Yup.object()
      .shape({ value: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: Yup.object().nullable(),
  }),
});
