import React from 'react';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { photoIdentificationTypes } from '../ViewApplication.data';
import { SImage } from '../../../ApplicantsAndReferrals.styles';

const DriverLicense = ({ data }) => {
  const photoIdType = photoIdentificationTypes[data?.photo_id_type];

  return (
    <div>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        {data?.photo_id_type ? `${photoIdType === 'ID' ? '' : photoIdType} ID` : 'Driver License ID'}{' '}
        {data?.driving_licence_number || data?.photo_id_number || 'N/A'}
      </Typography>
      <Divider />
      <div className='d-flex align-items-center gap-4'>
        <SImage src={data?.driving_licence_attachment_file || data?.photo_id_attachment_file} alt='Driver license' />
        {data?.driving_licence_attachment_back_file && (
          <SImage src={data.driving_licence_attachment_back_file} alt='Driver license back' />
        )}
      </div>
    </div>
  );
};

export default DriverLicense;
