import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { palette } from 'utils/constants';

export const STableWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;

  .MuiTableRow-root th.MuiTableCell-head {
    :first-child {
      width: 50px !important;
    }
  }
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0;
    margin-top: 12px;

    .MuiTabs-flexContainer {
      column-gap: 8px;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      min-height: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      padding: 3px 10px;
      text-transform: none;
      color: ${palette.gray900};
      border-bottom: 2px solid ${palette.white};

      &.Mui-selected {
        border-bottom: 2px solid ${palette.indigo500};
        color: ${palette.indigo500};

        :hover {
          border-bottom: 2px solid ${palette.indigo500};
        }
      }

      .MuiTab-iconWrapper {
        font-family: 'Inter', sans-serif;
        border-radius: 8px;
        padding: 2px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        background-color: #f0f1fa;
      }
    }

    .MuiButtonBase-root:hover {
      border-bottom: 2px solid ${palette.gray400};
    }
  }
`;
