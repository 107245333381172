import { array, number, object } from 'yup';

export const validationSchema = object().shape({
  equipment: array()
    .of(
      object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable()
    )
    .min(1, 'Required'),
  ownership: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
