export const stopPointsConverter = (columns) => {
  const data = {
    stop_point_id: [],
    location_name: [],
    country: [],
    city: [],
    state: [],
    address1: [],
    address2: [],
    zipcode: [],
    require_scale_tickets: [],
    require_lumper_receipt: [],
  };

  columns.forEach((item) => {
    data.stop_point_id.push(item.stop_point_id);
    data.location_name.push(item.location_name);
    data.country.push(item.country);
    data.city.push(item.city);
    data.state.push(item.state);
    data.address1.push(item.address1);
    data.address2.push(item.address2);
    data.zipcode.push(item.zipcode);
    data.require_scale_tickets.push(
      item.require_scale_tickets?.toLowerCase()?.trim() === 'yes' || item.require_scale_tickets === '1' ? 1 : 0
    );
    data.require_lumper_receipt.push(
      item.require_lumper_receipt?.toLowerCase()?.trim() === 'yes' || item.require_lumper_receipt === '1' ? 1 : 0
    );
  });

  return data;
};
