import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { tabs } from './Bills.data';
import { STableWrapper, STabs } from './Bills.styles';

const Bills = ({ vendor, refreshList }) => {
  const [activeTab, setActiveTab] = useState(0);

  if (!vendor) {
    return null;
  }

  return (
    <STableWrapper>
      <Typography variant='h4' style={{ color: palette.gray900 }}>
        Bills
      </Typography>
      <STabs value={Number(activeTab) || 0} onChange={(e, newValue) => setActiveTab(newValue)}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            id={`tab-${index}`}
            value={index}
            label={tab.label}
            iconPosition='end'
            disableRipple
            icon={tab.key === 'openBills' ? <span>{vendor.open_bills_count}</span> : null}
          />
        ))}
      </STabs>
      <Divider margin='2px 0 16px 0' />
      {tabs[Number(activeTab) || 0]?.component({ vendor, refreshList })}
    </STableWrapper>
  );
};

export default Bills;
