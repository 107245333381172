import React, { useContext } from 'react';
import { Chat, LoadingIndicator } from 'stream-chat-react';
import { LayoutContext } from 'components/layout';
import ChatAppContent from './ChatApp';

const ChatApp = () => {
  const { chatClient } = useContext(LayoutContext);

  if (!chatClient) {
    return (
      <div className='w-100 d-flex'>
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <Chat client={chatClient} theme='messaging'>
      <ChatAppContent />
    </Chat>
  );
};

export default ChatApp;
