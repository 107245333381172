import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { ReactComponent as CalendarIcon } from 'assets/icons/createShipment/calendar.svg';
import { quarters } from '../IFTA.data';

const QuarterYearFilter = ({ setStartDate, setEndDate, quarter, setQuarter, onYearChange }) => {
  const [year, setYear] = useState(new Date());
  const [selectedQuarter, setSelectedQuarter] = useState('Q1');
  const [showResult, setShowResult] = useState(false);

  const handleQuarterChange = (data) => {
    setQuarter(data);
    setSelectedQuarter(data.key);
    setShowResult(!showResult);
  };

  useEffect(() => {
    const startDates = {
      Q1: `01/01/${moment(year).format('Y')}`,
      Q2: `04/01/${moment(year).format('Y')}`,
      Q3: `07/01/${moment(year).format('Y')}`,
      Q4: `10/01/${moment(year).format('Y')}`,
    };

    const endDates = {
      Q1: `03/31/${moment(year).format('Y')}`,
      Q2: `06/30/${moment(year).format('Y')}`,
      Q3: `09/30/${moment(year).format('Y')}`,
      Q4: `12/31/${moment(year).format('Y')}`,
    };

    setStartDate(startDates[selectedQuarter]);
    setEndDate(endDates[selectedQuarter]);
    onYearChange(moment(year).format('Y'));
  }, [selectedQuarter, year]);

  return (
    <div className='quarter-year-picker-wrapper'>
      <div>
        <CustomSelect
          value={quarter}
          defaultActiveValue={quarter}
          onChange={handleQuarterChange}
          options={quarters}
          styles={{ height: 32, width: 60 }}
        />
      </div>
      <div className='year-picker-wrapper'>
        <CalendarIcon />
        <DatePicker
          selected={year}
          onChange={(date) => setYear(date)}
          showYearPicker
          dateFormat='yyyy'
          yearItemNumber={9}
        />
      </div>
    </div>
  );
};

export default QuarterYearFilter;
