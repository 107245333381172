import { useSelector } from 'react-redux';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'utils/constants';

const useDateFormat = () => {
  const { dateTimeFormat } = useSelector((state) => state.root);
  const { dateFormat, timeFormat } = dateTimeFormat || {};

  const formatDate = (date) => {
    return moment(date).format(dateFormat || DATE_FORMAT[1]);
  };

  const formatTime = (date, showSeconds) => {
    const format = showSeconds ? (timeFormat || TIME_FORMAT[1]).replace(':mm', ':mm:ss') : timeFormat || TIME_FORMAT[1];

    return moment(date).format(format);
  };

  const formatDateTime = (date, showSeconds) => {
    const time = showSeconds ? (timeFormat || TIME_FORMAT[1]).replace(':mm', ':mm:ss') : timeFormat || TIME_FORMAT[1];

    return moment(date).format(`${dateFormat || DATE_FORMAT[1]} ${time}`);
  };

  const convertToCustomerTime = (date, format, showSeconds) => {
    const localData = JSON.parse(localStorage.getItem('user'));
    const timeZone = JSON.parse(localData?.customer?.timezone);
    const dateFormatted = timeZone.zone_name.replace(/ - /g, '/');
    const time = showSeconds ? (timeFormat || TIME_FORMAT[1]).replace(':mm', ':mm:ss') : timeFormat || TIME_FORMAT[1];

    return moment.tz(moment.utc(date), dateFormatted).format(format || `${dateFormat || DATE_FORMAT[1]} ${time}`);
  };

  return {
    formatDate,
    formatTime,
    formatDateTime,
    convertToCustomerTime,
    dateFormat,
    timeFormat,
    pickerDateFormat: (dateFormat || DATE_FORMAT[1]).toLowerCase().replace('mm', 'MM'),
    pickerTimeFormat: (timeFormat || TIME_FORMAT[1]).replace('A', 'aa'),
    dateTimeFormat: `${dateFormat} ${timeFormat}`,
  };
};

export default useDateFormat;
