import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const table = {
  id: 1,
  column1: '12%',
  column2: '2%',
  column3: '13%',
  column4: '2%',
  column5: '7%',
  column6: '10%',
  column7: '15%',
  column8: '8%',
  column9: '2%',
  column10: '15%',
  column11: '12%',
};

const TableSkeleton = ({ showHeader, rowCount = 5 }) => {
  return (
    <div>
      <div>
        {showHeader && (
          <div className='d-flex'>
            <Skeleton style={{ width: '50px' }} />
            <Skeleton style={{ width: '200px' }} className='ms-4' />
          </div>
        )}
        <div className='d-flex justify-content-around'>
          <Skeleton style={{ width: '100px', height: '25px' }} className='mt-3 me-5' />
          <Skeleton style={{ width: '70px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '20px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '30px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '100px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '130px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '80px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '100px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '100px' }} className='mt-3 me-5 ' />
        </div>
        <Skeleton style={{ width: '100%', height: '2px' }} className='mt-2 me-5 ' />
      </div>
      {Array(rowCount)
        .fill(table)
        .map((item, index) => (
          <div className='d-flex' key={`${item.id * index}`}>
            <Skeleton style={{ width: `${item.column1}` }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: `${item.column2}` }} className='mt-3 me-1 ' />
            <Skeleton style={{ width: `${item.column3}` }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: `${item.column4}` }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: `${item.column5}` }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: `${item.column6}` }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: `${item.column7}` }} className='mt-3 me-5 ms-2' />
            <Skeleton style={{ width: `${item.column8}` }} className='mt-3 me-5 ms-4' />
            <Skeleton variant='circle' style={{ width: `${item.column9}`, height: '20px' }} className='mt-3' />
            <Skeleton style={{ width: `${item.column10}` }} className='mt-3 me-5 ms-2' />
            <Skeleton style={{ width: `${item.column11}` }} className='mt-3 me-5 ms-2' />
          </div>
        ))}
    </div>
  );
};

export default TableSkeleton;
