import React, { createContext, useContext, useState } from 'react';

const ThemeContext = createContext(null);

const useTheme = () => {
  const [theme, setTheme, use] = useContext(ThemeContext);
  return { theme, setTheme, use };
};

const user = JSON.parse(localStorage.getItem('user'));

const ThemeProvider = ({ children }) => {
  const appTheme = user?.theme_settings?.theme || user?.customer?.theme_settings?.theme || null;
  const condensed = user?.theme_settings?.menu_style || user?.customer?.theme_settings?.menu_style || null;
  const initialState = {
    appTheme: appTheme !== null ? appTheme : 1,
    appWidth: 'fluid',
    condensed: condensed !== null ? +condensed === 1 : true,
  };
  const [theme, setTheme] = useState(initialState); // useReducer(useThemeReducer, initialState);

  const use = (light, dark, primary) => {
    switch (Number(theme.appTheme)) {
      case 1:
        return primary || light;
      case 2:
        return light;
      case 3:
        return dark;
      default:
    }
  };

  return <ThemeContext.Provider value={[theme, setTheme, use]}>{children}</ThemeContext.Provider>;
};

export { ThemeProvider, useTheme };
