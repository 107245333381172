import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'edit':
        onEdit();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      {onEdit && (
        <li style={{ padding: '0 8px' }} onClick={(event) => onActionClick(event, 'edit')}>
          Update
        </li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
