import React, { useEffect, useState } from 'react';
import trash_filled from 'assets/icons/documents/trash_filled.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { addDocumentType, deleteDocumentType, documentType, updateDocumentType } from 'Api/Document';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import ItemForm from 'pages/CompanySettings/pagesComponents/Documents/ItemForm';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SAddMore } from 'pages/CompanySettings/pagesComponents/Documents/Documents.styles';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import styles from './UpdateDocumentType.module.css';

const UpdateDocumentType = ({ onListUpdate }) => {
  const showToaster = useShowToaster();
  const [forms, setForms] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const getDocumentTypes = async () => {
    try {
      const { data } = await documentType();
      setDocumentTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const onUpdateDocumentType = async (field, checked, documentType) => {
    if (loadingUpdate) {
      return;
    }

    try {
      setLoadingUpdate(true);
      setDocumentTypes((prevState) =>
        prevState.map((item) => (item.id === documentType.id ? { ...item, [field]: !item[field] } : item))
      );
      await updateDocumentType({
        document_type_id: documentType.id,
        driver_visible: documentType.driver_visible,
        shipment_id_required: documentType.shipment_id_required,
        [field]: checked ? 1 : 0,
      });
      showToaster({ type: 'success', message: 'Document type has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
      getDocumentTypes();
    } finally {
      setLoadingUpdate(false);
    }
  };

  const createNewDocumentType = async (values, id) => {
    try {
      const data = {
        name: values?.name,
        driver_visible: Number(values.driver_visible),
        shipment_id_required: Number(values.shipment_id_required),
      };
      await addDocumentType(data);
      onListUpdate();
      getDocumentTypes();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Document type has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteDocumentType(item.id);
      const newDocumentTypes = documentTypes.filter((el) => el.id !== item?.id);
      setDocumentTypes(newDocumentTypes);
      onListUpdate();
      showToaster({ type: 'success', message: 'Document type has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.listWrapper}>
        <div className={styles.listItems}>
          <p className={styles.listTitle}>DOCUMENT TYPE</p>
          <p className={styles.listTitle}>VISIBLE TO DRIVER APP</p>
          <p className={styles.listTitle}>REQUIRE SHIPMENT ID</p>
          <p className={styles.listTitle}>ACTIONS</p>
        </div>
        {documentTypes.map((item) => {
          return (
            <div className={styles.listItems} key={item.id}>
              <p className={styles.listText}>{item.name}</p>
              <div className={styles.listText}>
                <CustomCheckbox
                  type='switch'
                  name='driver_visible'
                  checked={item.driver_visible}
                  onChange={(checked) => onUpdateDocumentType('driver_visible', checked, item)}
                  smail={false}
                  style={{ paddingLeft: '50px' }}
                />
              </div>
              <div className={styles.listText}>
                <CustomCheckbox
                  type='switch'
                  name='shipment_id_required'
                  checked={item.shipment_id_required}
                  onChange={(checked) => onUpdateDocumentType('shipment_id_required', checked, item)}
                  smail={false}
                  style={{ paddingLeft: '50px' }}
                />
              </div>
              <div className={styles.listText}>
                {!!item.is_delete && (
                  <div className={styles.listIcons} onClick={() => deleteItem(item)}>
                    <img width='20px' src={trash_filled} alt='edit' />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {forms.map((item) => (
        <ItemForm
          key={item.id}
          onSubmit={(values) => createNewDocumentType(values, item.id)}
          onDelete={() => deleteFormItem(item.id)}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          Add Another...
        </Typography>
      </SAddMore>
    </div>
  );
};

export default UpdateDocumentType;
