import styled from 'styled-components';

export const SWrapper = styled.div`
  margin-bottom: 24px;

  .accounts-search {
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
