import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .table-item:nth-child(1) {
    width: 20%;
  }

  .table-item:nth-child(2) {
    width: 70%;
  }

  .table-item:nth-child(3) {
    width: 10%;
  }

  .icon-wrapper {
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;

    .delete-icon {
      display: inline-block;
      padding: 3px;
      cursor: pointer;
    }

    svg {
      display: block;
    }
  }
  }
`;
