import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenSendPaymentModal: {
    open: false,
    data: [],
    isAch: false,
    defaultStep: 1,
    fromTabName: undefined,
  },
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    onChangeIsOpenSendPayment: (state, action) => {
      state.isOpenSendPaymentModal = { ...state.isOpenSendPaymentModal, ...action.payload };
    },
    setIsOpenSendPaymentDefault: (state) => {
      state.isOpenSendPaymentModal = {
        open: false,
        data: [],
        isAch: false,
        defaultStep: 1,
        fromTabName: undefined,
      };
    },
  },
});

export const { onChangeIsOpenSendPayment, setIsOpenSendPaymentDefault } = headerSlice.actions;

export default headerSlice.reducer;
