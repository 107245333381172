import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { DatePicker } from 'common/Pickers';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { AddStaffDocument, updateStaffDocument, uploadDriverDocument } from 'Api/Staff';
import { getErrorMessage } from 'utils/error';
import plus from 'assets/icons/drivers/plus.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import { saveFinishLater } from 'Api/StaffV2';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import ImageDropZone from '../../ImgageDropZone';
import { documentsValidationSchema } from '../ValidationSchemaStaff';

const StaffDocument = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  stepsDone,
  setStepsDone,
  staffId,
  documents = {},
  documentId,
  draftDriver,
}) => {
  const { use } = useTheme();
  const [onNextloading, setOnNextloading] = useState(false);
  const { addDocument } = documents || {};

  const onNextStep = (values) => {
    setOnNextloading(true);
    const requestPostData = {
      staff_id: staffId,
      document_name: [],
      exp_date: [],
      document_path: [],
    };
    if (values.addDocument.every((item) => !item.document_name && !item.document_path)) {
      nextStep(values);
      return;
    }

    for (const val of values.addDocument) {
      if (val?.document_name && !val?.document_path) {
        setOnNextloading(false);
        showError('Please upload document');
        return;
      }
      const date = moment(val?.exp_date || new Date())?.format('YYYY-MM-DD');
      requestPostData.exp_date.push(val.no_expiration ? null : date);
      requestPostData.document_name.push(val.document_name);
      requestPostData.document_path.push(val.document_path);
    }

    const requestUpdatePostData = {
      id: documentId,
      document_name: [],
      exp_date: [],
      document_path: [],
    };
    for (const val of values.addDocument) {
      if (val?.document_name && !val?.document_path) {
        setOnNextloading(false);
        showError('Please upload document');
        return;
      }
      const date = val.date ? moment(val.date)?.format('YYYY-MM-DD') : '';
      requestUpdatePostData.exp_date.push(val.no_expiration ? null : date);
      requestUpdatePostData.document_name.push(val.document_name);
      requestUpdatePostData.document_path.push(val.document_path);
    }
    if (stepsDone) {
      updateStaffDocument(requestUpdatePostData, staffId)
        .then(() => {
          setOnNextloading(false);
          nextStep(values, `Document has been ${stepsDone ? 'updated' : 'created'}`);
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      AddStaffDocument(requestPostData)
        .then((res) => {
          setOnNextloading(false);
          nextStep(
            { formikData: values, documentId: res?.data?.map((item) => item?.id) },
            `Document has been ${stepsDone ? 'updated' : 'created'}`
          );
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      addDocument: addDocument ?? [
        {
          document_name: '',
          exp_date: null,
          document_path: '',
          staff_id: staffId,
          no_expiration: false,
        },
      ],
    },
    onSubmit: onNextStep,
    validationSchema: documentsValidationSchema,
  });

  useEffect(() => {
    if (!stepsDone) {
      formik.setFieldValue(`addDocument[${0}].document_path`, '');
    }
  }, []);

  const onPrevStep = () => {
    setStepsDone({
      ...stepsDone,
      isPreviousEmployement: true,
    });
    prevStep();
  };

  const uploadDocument = (file, propertyName, index) => {
    uploadDriverDocument(file[0], staffId).then((res) => {
      formik.setFieldValue(`addDocument[${index}].${'document_path'}`, res.data.document_path);
    });
  };

  const remove = (index) => {
    const temp = formik.values.addDocument;
    temp.splice(index, 1);
    formik.setFieldValue('addDocument', temp);
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 5,
    };

    saveFinishLater(staffId, body).then(() => {
      Cancel();
    });
  };

  return (
    <>
      <Modal.Body
        className='driver-style-wrap'
        style={{ backgroundColor: use(palette.whiteBackground, palette.dark800), height: '682px' }}
      >
        {formik?.values?.addDocument?.map((docs, index) => {
          return (
            <div key={docs?.id || index}>
              <div className='add-document-header'>
                <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                  Document Name
                </p>
                <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                  Expiry
                </p>
              </div>
              <div className='mb-4'>
                <div className='d-flex  expiry-container mb-0'>
                  <div className='expiry-input' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                    <input
                      type='text'
                      style={{ backgroundColor: use(palette.white, palette.dark800) }}
                      id={`addDocument[${index}].document_name`}
                      name={`addDocument[${index}].document_name`}
                      onBlur={formik.handleBlur}
                      value={formik.values.addDocument[index].document_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.document_name && formik.errors.document_name ? (
                    <span className='error-message-staff'>{formik.errors.document_name}</span>
                  ) : null}

                  <div className='mt-2'>
                    <DatePicker
                      width='170px'
                      name={`addDocument[${index}].exp_date`}
                      borderRadius='0 6px 6px 0'
                      value={
                        formik?.values?.addDocument[index]?.no_expiration
                          ? null
                          : formik?.values?.addDocument[index]?.exp_date
                      }
                      onChange={(date) => {
                        formik.setFieldValue(`addDocument[${index}].exp_date`, date);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.addDocument?.[index]?.exp_date && formik.errors?.addDocument?.[index]?.exp_date
                      }
                      disabled={!!formik?.values?.addDocument[index]?.no_expiration}
                    />
                  </div>

                  {docs?.document_path ? (
                    <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                      <img src={right} alt='attachment' className='attachment-icon' />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <img
                        src={cross}
                        alt='cross'
                        className='upload-cancel-icon'
                        onClick={() => formik.setFieldValue(`addDocument[${index}].document_path`, null)}
                      />
                    </p>
                  ) : (
                    <div className='ms-2 mt-2 attach-file' style={{ position: 'relative' }}>
                      <label htmlFor='myFile' className='text-style upload'>
                        <span className='upload-icon'>
                          <img src={plus} alt='plus' />
                        </span>
                        <span className='text-style choose-file '>Choose File</span>
                      </label>
                      <ImageDropZone
                        onDrop={(file) => uploadDocument(file, 'document_path', index)}
                        width='100%'
                        height='100%'
                        name='document_path'
                        id='document_path'
                        deletable={false}
                        onBlur={formik.handleBlur}
                        customStyle={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                        }}
                        accept={['application/pdf']}
                      />
                    </div>
                  )}
                  {index > 0 ? (
                    <div className='cross-container-document' onClick={() => remove(index)}>
                      <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M1.79114 0.721559C1.49577 0.426193 1.01689 0.426193 0.721524 0.721559C0.426158 1.01693 0.426159 1.49581 0.721524 1.79117L3.93037 5.00002L0.721555 8.20883C0.426189 8.50419 0.426189 8.98308 0.721555 9.27844C1.01692 9.57381 1.4958 9.57381 1.79117 9.27844L4.99998 6.06963L8.20883 9.27848C8.50419 9.57384 8.98308 9.57384 9.27844 9.27848C9.57381 8.98311 9.57381 8.50423 9.27844 8.20886L6.06959 5.00002L9.27847 1.79114C9.57384 1.49577 9.57384 1.01689 9.27847 0.721524C8.9831 0.426158 8.50422 0.426159 8.20886 0.721524L4.99998 3.9304L1.79114 0.721559Z'
                          fill='#ff0000'
                        />
                      </svg>
                    </div>
                  ) : (
                    <span />
                  )}
                </div>
                <div style={{ marginTop: '4px', marginLeft: '250px' }}>
                  <CustomCheckbox
                    name={`addDocument[${index}].no_expiration`}
                    checked={formik?.values?.addDocument[index]?.no_expiration}
                    onChange={(checked) => {
                      formik.setFieldValue(`addDocument[${index}].no_expiration`, checked);
                    }}
                  >
                    <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                      No Expiration
                    </Typography>
                  </CustomCheckbox>
                </div>
              </div>
              {formik?.touched?.addDocument?.length &&
              formik.touched.addDocument[index]?.document_name &&
              formik.errors?.addDocument?.length &&
              formik.errors?.addDocument[index]?.document_name ? (
                <span className='error-message-staff me-5'>{formik.errors?.addDocument[index]?.document_name}</span>
              ) : null}
            </div>
          );
        })}
        <div
          className='add-document-link'
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray200, palette.boxShadow),
          }}
        >
          <div className='ms-2 '>
            <label
              className='text-style upload'
              onClick={() =>
                formik.setFieldValue('addDocument', [
                  ...(formik.values.addDocument || []),
                  {
                    document_name: '',
                    exp_date: null,
                    document_path: '',
                    no_expiration: false,
                    staff_id: staffId,
                  },
                ])
              }
            >
              <span className='upload-icon'>
                <img src={plus} alt='plus' />
              </span>
              <span className='text-style  choose-file'>Add Document</span>
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button type='button' onClick={formik.handleSubmit} className='next-step'>
                Next Step
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default StaffDocument;
