import React, { useEffect, useState } from 'react';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Radio from 'common/Radio';
import { InputWithIcon } from 'common/Input';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getPaymentSource, getStripeFees, withdrawFunds } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { initialValues } from './Withdraw.data';

const Withdraw = ({ open, onClose }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [setFees] = useState(null);
  const [source, setSource] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        destination_payment_method: values.withdraw_source.stripe_bank_account_id,
        withdraw_type: values.withdraw_type,
        amount: Number(values.amount),
        currency: 'usd',
        description: 'Transfer',
      };
      const response = await withdrawFunds(body);

      if (response?.error) {
        showToaster({ type: 'error', message: response.error });
        return;
      }

      showToaster({ type: 'success', message: 'Success!' });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getFees = async () => {
    try {
      const { data } = await getStripeFees();
      setFees(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getSource = async () => {
    try {
      const { data } = await getPaymentSource();
      setSource([data]);
      handleChange('withdraw_source', data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getFees();
    getSource();
  }, []);

  useEffect(() => {
    if (values.amount && values.withdraw_type) {
      const fee = (Number(values.amount) * Number(values.fee || 0)) / 100;
      const total = Number(values.amount || 0) - fee;
      handleChange('total', total);
    } else {
      handleChange('total', '');
    }
  }, [values.amount, values.fee, values.withdraw_type]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Withdraw from Truckin Digital'
      $bgColor={palette.gray0}
      $maxWidth='520px'
      $minWidth='520px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Withdraw',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-5 mt-2'>
        <div className='mb-3'>
          <div className='mb-2'>
            <Autocomplete
              required
              label='Withdraw Source'
              name='withdraw_source'
              value={values.withdraw_source}
              options={source}
              getOptionLabel={(option) => `${option.bank_name} **** ${option.account_number}`}
              onChange={(e, val) => handleChange('withdraw_source', val)}
              error={touchedErrors.withdraw_source}
            />
          </div>
          <div className='d-flex flex-column gap-2 mt-3'>
            <div className='w-100 d-flex justify-content-between'>
              <Radio
                label='Standard - 3 to 5 business days'
                name='withdraw_type'
                id='type-1'
                value='standard'
                checked={values.withdraw_type === 'standard'}
                onChange={() => setValues({ ...values, withdraw_type: 'standard', fee: 0 })}
                gap='8px'
                labelColor={palette.gray700}
              />
              <Chip label='FREE' bgColor={palette.indigo0} labelColor={palette.indigo500} />
            </div>
            <div className='w-100 d-flex justify-content-between'>
              <Radio
                label='Instant'
                name='withdraw_type'
                id='type-2'
                value='instant'
                checked={values.withdraw_type === 'instant'}
                onChange={() => setValues({ ...values, withdraw_type: 'instant', fee: 0 })}
                gap='8px'
                labelColor={palette.gray700}
              />
              <Chip label='FREE' bgColor={palette.indigo0} labelColor={palette.indigo500} />
            </div>
            <ErrorMessage error={touchedErrors.withdraw_type} />
          </div>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <InputWithIcon
            type='number'
            label='Withdraw Amount'
            placeholder='0.00'
            name='amount'
            value={values.amount}
            onChange={handleChange}
            onKeyDown={blockNonPositiveNumbers}
            error={touchedErrors.amount}
          />
          <InputWithIcon
            type='number'
            label='Fees'
            icon='%'
            placeholder='0.00'
            value={values.fee}
            onChange={handleChange}
            onKeyDown={blockNonPositiveNumbers}
            disabled
          />
          <InputWithIcon
            type='number'
            label='Total Withdraw'
            placeholder='0.00'
            value={values.total}
            onChange={handleChange}
            onKeyDown={blockNonPositiveNumbers}
            disabled
          />
        </div>
      </div>
    </Modal>
  );
};

export default Withdraw;
