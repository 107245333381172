import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import { palette } from 'utils/constants';

export const SCardHolder = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid ${palette.gray100};
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: relative;

  .delete-icon {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 3px;
    padding: 5px;

    :hover {
      background-color: ${palette.indigo50};
    }
  }
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 32px;
    border-bottom: 2px solid ${palette.gray100};
    padding: 0 12px;

    .MuiTab-root {
      padding: 10px 4px;
      margin: 0 8px;
      min-height: 32px;
      min-width: auto;
      text-transform: none;

      :hover {
        color: ${palette.gray900};
      }

      &.Mui-selected {
        color: ${palette.indigo500};
      }
    }

    .MuiTabs-indicator {
      background-color: ${palette.indigo500};
    }
  }
`;

export const SPaper = styled(Paper)`
  .MuiAutocomplete-listbox {
    max-height: 30vh;
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    font-size: 14px;
    color: ${palette.gray900};
    font-weight: 500;
    padding: 0 4px;
    white-space: nowrap;
  }

  td {
    padding: 4px;
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell {
    width: 100px;
  }
`;
