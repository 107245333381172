import React, { useEffect, useState } from 'react';
import { GetHandbook } from 'Api/CompanySettings';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';
import styles from './Handbook.module.css';
import { Typography } from '../Typography';
import HandbookModal from './HandbookModal/HandbookModal';

const Handbook = () => {
  const [handbook, setHandbook] = useState(null);
  const [handbookModal, setHandbookModal] = useState({
    visible: false,
  });

  const getHandbook = () => {
    return GetHandbook().then((res) => {
      setHandbook(res?.data);
    });
  };

  useEffect(() => {
    getHandbook().then();
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h1'>Company Guides & Manuals</Typography>
        </div>
        <div className={styles.body}>
          {handbook?.map((item) => {
            return (
              <div
                key={item?.id}
                className={styles.item_wrapper}
                onClick={() =>
                  setHandbookModal({
                    visible: true,
                    ...item,
                  })
                }
              >
                <div className={styles.left}>
                  <Typography variant='s1'>{item?.title}</Typography>
                  <Typography variant='s3'>{item?.description}</Typography>
                  <div style={{ display: 'flex' }}>
                    <span className={styles.bedge}>{item?.category?.name}</span>
                  </div>
                </div>
                <div>
                  <ArrowRight style={{ fill: '#464F60' }} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <HandbookModal
        show={handbookModal?.visible}
        data={handbookModal || {}}
        onClose={() => {
          setHandbookModal({
            visible: false,
          });
        }}
      />
    </>
  );
};
export default Handbook;
