import styled from 'styled-components';
import Snackbar from '@mui/material/Snackbar';
import { palette } from 'utils/constants';

export const SSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    z-index: 999;
    top: 100px;
    animation-name: movement;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .MuiSnackbarContent-root {
      padding: 12px 16px;
      border-radius: 8px;
      background-color: ${palette.white};
      cursor: pointer;

      .MuiSnackbarContent-message {
        width: 100%;
        padding: 0;
      }
    }

    @keyframes movement {
      0% {
        transform: translate(-50%, 0);
      }
      33% {
        transform: translate(-50%, -6px);
      }
      66% {
        transform: translate(-50%, 6px);
      }
      100% {
        transform: translate(-50%, 0);
      }
    }

    .navigate-button {
      width: fit-content;
      cursor: pointer;
      color: ${palette.indigo500};

      :hover {
        text-decoration: underline;
      }
    }
  }
`;
