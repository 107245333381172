import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import { palette } from '../../../../utils/constants';

export const SAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    .MuiAccordionSummary-root {
      min-height: auto;
      padding: 0;

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }

    .accordion-arrow {
      transform: rotate(180deg);

      path {
        fill: ${palette.gray400};
      }
    }
  }
`;

export const SMenuItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${palette.gray700};
`;
