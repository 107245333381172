import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import AtTheDock from 'assets/icons/at-the-dock.png';
import WaitingForDock from 'assets/icons/waiting-for-dock.png';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as StopWay } from 'assets/icons/stopWayMarker.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-filled.svg';
import { ReactComponent as CurrentStopWay } from 'assets/icons/currentStopWay.svg';
import Tooltip from 'common/Tooltip';
import { palette } from 'utils/constants';
import { convertToCustomerTime } from 'utils/helpers';
import classes from './stopsWay.module.css';
import { Typography } from '../Typography';
import OverLayTriggerHoverWrapper from '../OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';

const StopsWay = ({ dataList, currentData, mainColor, styleContainer, depedencyBgDisabled, status_id }) => {
  const refDisabled = useRef(null);
  const [colorDisabled, setColorDisabled] = useState('');
  const current = { color: mainColor, ...currentData, current: true };
  const allDataList = [...dataList, current].sort((a, b) => Number(a?.percent) - Number(b?.percent));
  const showVehicleIcon = allDataList
    ?.slice(2)
    .every((i) => (!!i.arrival_date && !!i.departure_date) || (!i.arrival_date && !i.departure_date));

  const calc = (percent, color, current) => {
    const calculatedPercent = `calc(${percent}% - 10px)`;
    return `${percent <= currentData.percent ? mainColor : color} ${calculatedPercent}${
      current ? '' : `,transparent ${calculatedPercent}, transparent calc(${percent}% + 10px)`
    }`;
  };

  const gradient = useMemo(() => {
    let colors = '';
    allDataList.forEach((data, index) => {
      const { color, percent } = data || {};

      const colorLine = color || mainColor;
      const prev = `${
        index > 0
          ? `${data.percent <= currentData.percent ? mainColor : colorLine} calc(${
              allDataList[index - 1].percent
            }% - 10px),`
          : ''
      }`;
      const last = `${
        index !== allDataList.length - 1 ? ',' : `,${mainColor} ${allDataList[index - 1].percent}%,${mainColor}`
      }`;
      colors += `${prev}${calc(percent, colorLine, data?.current)}${last}`;
    });
    return `linear-gradient(to right,${colors})`;
  }, [currentData, allDataList, mainColor]);

  useEffect(() => {
    setTimeout(() => {
      const row = refDisabled?.current?.closest('tr');
      const bg = row?.style?.backgroundColor;
      const regex = /\d+/g;
      const match = bg?.match(regex);
      Array.isArray(match)
        ? setColorDisabled(`rgba(${match[0]}, ${match[1]}, ${match[2]}, 0.7)`)
        : setColorDisabled('');
    }, 0);
  }, [refDisabled?.current, depedencyBgDisabled]);

  return (
    <div style={{ ...styleContainer }} className={classes.container}>
      <div className={classes.stopLines} style={{ backgroundImage: gradient }}>
        {allDataList?.map((stop, index) => {
          const {
            id,
            current,
            percent,
            title,
            tick,
            lastUpdate,
            custom,
            arrival_date,
            departure_date,
            reported_dock_status,
          } = stop || {};

          return (
            <div key={id}>
              {current ? (
                <div
                  className={classNames(classes.currentItem)}
                  style={{
                    left: `${percent}%`,
                    transform: 'translateX(-50%)',
                    transition: '1s all linear',
                  }}
                >
                  <div style={{ position: 'relative' }}>
                    {status_id !== '1' && status_id !== '8' && showVehicleIcon && (
                      <OverLayTriggerHoverWrapper
                        placement='top'
                        overLayContainerStyle={{ width: '200px', zIndex: 99999999, background: '#F5F5F5' }}
                        overlay={
                          <div className='d-flex flex-column'>
                            <Typography variant='b2' style={{ color: palette.green500 }}>
                              {title}
                            </Typography>
                            <Typography variant='b2' style={{ color: palette.green500, whiteSpace: 'nowrap' }}>
                              {convertToCustomerTime(lastUpdate, 'MM/DD/YYYY HH:mm')} {custom ? '(M)' : '(A)'}
                            </Typography>
                          </div>
                        }
                      >
                        <CurrentStopWay
                          width={28}
                          height={28}
                          style={{
                            marginTop: 4,
                            borderRadius: '50%',
                            flexShrink: 0,
                            cursor: 'pointer',
                          }}
                        />
                      </OverLayTriggerHoverWrapper>
                    )}
                    {!!title && !lastUpdate && (
                      <div className={classes.hoverBlock} style={{ transform: `translateX(-${percent}%)` }}>
                        <Typography variant='c1' style={{ color: palette.green500 }}>
                          {title}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    left: `${percent}%`,
                    transform: 'translateX(-50%)',
                  }}
                  className={classNames(classes.item)}
                >
                  <div style={{ position: 'relative' }}>
                    {tick ? (
                      !!arrival_date && reported_dock_status !== null && !departure_date ? (
                        +reported_dock_status === 1 ? (
                          <Tooltip
                            title='WAITING FOR DOCK'
                            placement='top'
                            componentsProps={{
                              placement: 'top',
                              tooltip: {
                                sx: {
                                  fontSize: 13,
                                  fontFamily: 'Inter',
                                  whiteSpace: 'nowrap',
                                  maxWidth: 'unset',
                                },
                              },
                            }}
                          >
                            <img src={WaitingForDock} width={20} alt='' />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title='AT THE DOCK'
                            placement='top'
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontSize: 13,
                                  fontFamily: 'Inter',
                                  whiteSpace: 'nowrap',
                                  maxWidth: 'unset',
                                },
                              },
                            }}
                          >
                            <img src={AtTheDock} width={20} alt='' />
                          </Tooltip>
                        )
                      ) : (
                        <div
                          className={classes.tickWrapper}
                          style={{ filter: percent > currentData?.percent ? 'opacity(0.4)' : 'opacity(1)' }}
                        >
                          {index === 0 ? (
                            <span className={classes.arrowIcon}>
                              <ArrowRight width={10} height={8} fill={palette.white} />
                            </span>
                          ) : departure_date ? (
                            <CheckIcon width={16} height={16} fill={palette.green400} />
                          ) : (
                            <TickIcon width={16} height={16} fill={palette.green400} />
                          )}
                        </div>
                      )
                    ) : (
                      <StopWay
                        width={20}
                        height={20}
                        style={{
                          filter: percent > currentData?.percent ? 'opacity(0.4)' : 'opacity(1)',
                          marginTop: 4,
                          borderRadius: '50%',
                        }}
                      />
                    )}

                    {!!title && (
                      <div
                        className={classes.hoverBlock}
                        style={{
                          transform: `translateX(-${percent}%)`,
                          marginBottom: tick ? -8 : 0,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Typography variant='c1'>{title}</Typography>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {!!currentData?.percent && (
        <div
          ref={refDisabled}
          className={classes.opacity}
          style={{
            width: `calc(100% - ${currentData.percent}%)`,
            left: `${currentData.percent}% `,
            backgroundColor: colorDisabled || 'rgba(255 ,255,255,0.5)',
          }}
        />
      )}
    </div>
  );
};

export default StopsWay;
