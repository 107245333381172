export const agingDetailsConverter = (data) => {
  if (!data) {
    return [];
  }

  return Object.keys(data)
    .map((key) => {
      const listData = { title: key, data: [], count: data[key].count, total_amount: data[key].total_amount };

      Object.entries(data[key]).forEach((item) => {
        if (!Number.isNaN(Number(item[0]))) {
          listData.data.push(item[1]);
        }
      });

      return listData;
    })
    .filter((item) => !!item.count);
};

export const transformReportItems = (reportItems, isPdf) => {
  const transformed = [];

  reportItems.forEach((item, index) => {
    const itemWithoutBills = { ...item, isType: true };
    delete itemWithoutBills.data;

    if (index !== 0 && isPdf) {
      transformed.push({ isBlank: true }, { isBlank: true });
    }
    transformed.push(itemWithoutBills);

    item.data.forEach((bill, i) => {
      transformed.push(bill);
      if (i === item.data.length - 1) {
        transformed.push({ title: item.title, total_amount: item.total_amount, isTotal: true });
      }
    });
  });

  return transformed;
};
