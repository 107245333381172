import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/drivers/secondClose.svg';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import Input, { Textarea } from 'common/Input';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { SUploaderWrapper } from 'pages/CompanySettings/pagesComponents/CompanyResource/components/AddMaterial/AddMaterial.styles';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createRule, getIncidentTypes, updateRule } from 'Api/CompanySettings';
import IncidentSettings from './IncidentSettings';
import { validationSchema } from './validationSchema';
import { appliesToOptions, getInitialValues } from './AddRule.data';

const AddRule = ({ open, onClose, rule, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [openIncidentSettings, setOpenIncidentSettings] = useState(false);

  const addEditRule = async (values) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('incident_type_id', values?.incident_types?.map((item) => item.id).toString());
      if (values.policy_attachment && typeof values.policy_attachment !== 'string') {
        formData.append('policy_attachment', values.policy_attachment);
      }
      formData.append('applies_to', values?.applies_to?.map((item) => item.value).toString());

      if (rule) {
        await updateRule(rule.id, formData);
      } else {
        await createRule(formData);
      }
      showToaster({ type: 'success', message: `Rule has been successfully ${rule ? 'updated' : 'added'}!` });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(rule),
    validationSchema,
    onSubmit: addEditRule,
  });

  const getIncidents = async () => {
    try {
      const { data } = await getIncidentTypes();
      setIncidents(data);
    } catch (e) {
      // Do nothing
    }
  };

  const resetUploadedScreenshot = (e) => {
    e.stopPropagation();
    handleChange('policy_attachment', null);
  };

  const uploadDocument = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    handleChange('policy_attachment', file[0]);
  };

  useEffect(() => {
    getIncidents();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`${rule ? 'Update' : 'Add'} Rules and Conduct`}
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: rule ? 'Update Rule' : 'Add Rule',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-5 d-flex flex-column gap-4'>
        <Input
          required
          name='title'
          label='Title'
          value={values.title}
          onChange={handleChange}
          error={touchedErrors.title}
        />
        <Textarea
          required
          rows={3}
          label='Description'
          name='description'
          value={values.description}
          onChange={handleChange}
          error={touchedErrors.description}
        />
        <div>
          <div className='d-flex gap-2'>
            <InputLabel required>Incident Type</InputLabel>
            <div onClick={() => setOpenIncidentSettings(true)}>
              <NoteIcon fill={palette.gray500} />
            </div>
          </div>
          <Autocomplete
            multiple
            id='incident_types'
            name='incident_types'
            labelKey='type'
            placeholder='Select what Incident type this rule is associated with..'
            options={incidents}
            value={values.incident_types}
            onChange={(e, value) => handleChange('incident_types', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            error={touchedErrors.incident_types}
          />
        </div>
        <div>
          <InputLabel>Attach Policy</InputLabel>
          <SUploaderWrapper>
            <div className='upload-label'>
              <UploadIcon width={46} height={42} />
              {values?.policy_attachment ? (
                <div className='d-flex align-items-center gap-2'>
                  <CheckIcon />
                  <Typography variant='s2' style={{ color: palette.green400 }}>
                    File Uploaded
                  </Typography>
                  <CrossIcon className='pointer' onClick={resetUploadedScreenshot} />
                </div>
              ) : (
                <Typography variant='s2'>
                  <span className='upload-text'>Upload Document</span> or drag and drop
                </Typography>
              )}
            </div>
            <ImageDropZone
              onDrop={uploadDocument}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              maxSize={5242880}
              accept={['application/pdf']}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: values.policy_attachment ? -1 : 1,
              }}
            />
          </SUploaderWrapper>
        </div>
        <div>
          <Autocomplete
            multiple
            required
            label='Applies To'
            name='applies_to'
            options={appliesToOptions}
            value={values.applies_to}
            onChange={(e, value) => handleChange('applies_to', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            error={touchedErrors.applies_to}
          />
        </div>
      </div>
      {openIncidentSettings && (
        <IncidentSettings open={openIncidentSettings} onClose={() => setOpenIncidentSettings(false)} />
      )}
    </Modal>
  );
};

export default AddRule;
