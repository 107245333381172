import React, { useMemo } from 'react';
import { OverlayTrigger, Popover, ProgressBar as Progress } from 'react-bootstrap';

function isWhatPercentOf(numA, numB) {
  return (numA / numB) * 100;
}

const ProgressBar = ({ tabs }) => {
  const total = useMemo(() => {
    return tabs?.reduce((acc, cur) => {
      if (cur.label !== 'All' && cur.label !== 'Resolved') {
        acc += cur.count;
      }
      return acc;
    }, 0);
  }, [tabs]);

  return (
    <div className='mt-3 mb-4'>
      <Progress className='w-100' style={{ height: 16, borderRadius: 8 }}>
        {tabs
          ?.filter((item) => item.label !== 'All' && item.label !== 'Resolved')
          ?.map((item, index) => {
            return (
              <OverlayTrigger
                key={item?.color}
                placement='top'
                overlay={
                  <Popover
                    id={`tooltip-${index}`}
                    style={{
                      maxWidth: 500,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3))',
                    }}
                  >
                    <Popover.Body>
                      <div>
                        <span
                          style={{
                            display: 'inline-block',
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: item?.color,
                            marginRight: 6,
                          }}
                        />
                        <span style={{ marginRight: 8 }}>{item?.label}</span>
                        <span
                          style={{
                            marginRight: 40,
                            width: 31,
                            height: 16,
                            backgroundColor: '#E9EDF5',
                            borderRadius: '8px',
                            padding: '0 8px',
                          }}
                        >
                          {item?.count}
                        </span>
                        <span>{isWhatPercentOf(item?.count, total).toFixed(1)}%</span>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Progress
                  isChild
                  style={{ height: '100%', background: item?.color }}
                  now={isWhatPercentOf(item?.count, total)}
                  key={item?.color}
                />
              </OverlayTrigger>
            );
          })}
      </Progress>
    </div>
  );
};

export default ProgressBar;
