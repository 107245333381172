import React from 'react';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { settingOptions } from 'componentsV2/Customer/EdiApi/Connections/Connections.data';
import { SInnerSection } from 'componentsV2/Customer/EdiApi/EdiApi.styles';

const OutgoingSettings = ({ form, index, readOnly }) => {
  const { values, handleChange } = form;

  return (
    <SInnerSection>
      <div className='inner-section-header'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Outgoing Settings
        </Typography>
      </div>
      <div className='setting-options-container'>
        {settingOptions.map((item) => (
          <div className='setting-option' key={item.id}>
            <CustomCheckbox
              type='switch'
              name={`${index}.${item.name}`}
              checked={!!values[index][item.name]}
              onChange={(checked) => handleChange(`${index}.${item.name}`, checked)}
              smail={false}
              disabled={readOnly}
            />
            <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
              {item.label}
            </Typography>
          </div>
        ))}
      </div>
    </SInnerSection>
  );
};

export default OutgoingSettings;
