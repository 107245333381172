import React from 'react';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SWrapper } from './NoRecords.styles';

const NoRecords = () => {
  const { use } = useTheme();

  return (
    <SWrapper>
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        No records to display
      </Typography>
      <Divider />
    </SWrapper>
  );
};

export default NoRecords;
