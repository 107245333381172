import React from 'react';
import { capitalize } from 'lodash';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SDetailItem, SDetailItems } from '../TransactionDetails.styles';

const PaymentMethod = ({ transaction }) => {
  const { payment_method } = transaction?.transaction_details || {};
  const { id, card, type, billing_details, us_bank_account } = payment_method || {};

  return (
    <SDetailItems>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        Payment Method
      </Typography>
      <Divider />
      <div className='d-flex gap-4'>
        <div className='items-wrapper'>
          <SDetailItem>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              ID
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {id || '-'}
            </Typography>
          </SDetailItem>
          <SDetailItem>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              Type
            </Typography>
            <Typography variant='b2' style={{ color: palette.gray900 }}>
              {type === 'card' ? `${capitalize(card.brand)} Credit Card` : type}
            </Typography>
          </SDetailItem>
          {type === 'card' && !!card && (
            <>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Number
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  ••••{card.last4}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Fingerprint
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {card.fingerprint || '-'}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Expires
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {card.exp_month} / {card.exp_year}
                </Typography>
              </SDetailItem>
            </>
          )}
          {type === 'us_bank_account' && !!us_bank_account && (
            <>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Bank name
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {us_bank_account.bank_name || '-'}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Routing Number
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {us_bank_account.routing_number}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Account Number
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  ••••{us_bank_account.last4}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Fingerprint
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {us_bank_account.fingerprint || '-'}
                </Typography>
              </SDetailItem>
            </>
          )}
        </div>
        <div className='items-wrapper'>
          {type === 'card' && (
            <>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Address
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {billing_details?.address?.country || '-'}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Origin
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {card?.country || '-'}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  CVC Check
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {card?.checks?.cvc_check === 'pass' ? 'Passed' : 'Pending'}
                </Typography>
              </SDetailItem>
            </>
          )}
          {type === 'us_bank_account' && (
            <>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Owner
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {billing_details?.name || '-'}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Owner Email
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {billing_details?.email || '-'}
                </Typography>
              </SDetailItem>
              <SDetailItem>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Address
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {billing_details?.address?.country || '-'}
                </Typography>
              </SDetailItem>
            </>
          )}
        </div>
      </div>
    </SDetailItems>
  );
};

export default PaymentMethod;
