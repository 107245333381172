import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SAddStops = styled.div`
  background: ${palette.gray0};
  padding: 14px 20px;
  height: 100%;
  position: relative;

  .add-stop-form-label {
    width: 155px;
    margin-bottom: 0;
  }

  .add-stop-point-modal-body {
    width: 1050px;
  }
`;
