import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorMessage, FastField, Field } from 'formik';
import SignatureCanvas from 'react-signature-canvas';
import { ReactComponent as CalendarIcon } from 'assets/icons/createShipment/calendar.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getEquipmentIdInTrailers } from 'Api/Planner';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import useShowToaster from 'hooks/useShowToaster';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import { validateRequired } from 'components/AddEditModalStops/validates';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import ImageDropZone from 'components/ImgageDropZone';
import useDateFormat from 'hooks/useDateFormat';
import classes from '../checkInOut.module.scss';

const DeliveryInModal = ({ values, touched, errors, stop, setFieldValue, shipment, shipmentSettings }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const signPad = useRef({});
  const { formatDate, formatDateTime, dateFormat } = useDateFormat();
  const { billOfLadingTitle } = useSelector((state) => state.root);
  const [equipmentId, setEquipmentId] = useState([]);

  function onDrop(acceptedFiles, name) {
    setFieldValue(name, acceptedFiles[0]);
  }

  function onDelete(e, name) {
    e.stopPropagation();
    setFieldValue(name, '');
  }

  function getEquipmentId() {
    const length_id = stop?.equipment_type_length_value?.length;
    return getEquipmentIdInTrailers({ status_id: '4,6', length_id, itemsPerPage: 1000 }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `
                                     ${el?.equipment_id || ''}
                                    (${el?.make || ''}
                                     ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setEquipmentId(equipmentId);
      }
    });
  }

  const styles = useMemo(() => {
    return {
      datePicker: {
        width: 206,
        margin: 0,
        justifyContent: 'flex-start',
      },
      labelInput: {
        marginTop: '8',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      nameInput: {
        marginTop: '8',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '360px',
      },
    };
  }, [use, palette]);

  useEffect(() => {
    getEquipmentId().then();
  }, []);

  const onClear = () => {
    setFieldValue('signature', '');
    signPad?.current.clear();
  };

  const shouldUpdateStops = (nextProps, props, name) => {
    const nextError = nextProps.formik.errors?.equipment_id;
    switch (name) {
      case 'equipment_id':
        return nextError?.options !== props?.options;
      case 'drop_id':
        return nextError?.options !== props?.options;
      default:
    }
  };

  const save = () => {
    signPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      setFieldValue('signature', file);
      showToaster({ type: 'success', message: 'Signature has been saved' });
    }, 'image/png');
  };

  return (
    <div className={classes.globalWrapper}>
      <div className={classes.datesWrapper}>
        <div className={classes.datePickerDataWrapper}>
          <Typography variant='s2' className='mb-2'>
            Scheduled Date/Time
          </Typography>
          <div className={classes.datePickerData}>
            <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
            <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
              {stop?.scheduled_date && formatDate(stop.scheduled_date)}, {stop?.from} {stop?.to ? `to ${stop?.to}` : ''}
            </Typography>
          </div>
        </div>

        <div className={classes.errorWrapper}>
          {stop.arrival_date ? (
            <div className={classes.datePickerDataWrapper}>
              <Typography variant='s2' className='mb-2'>
                Check In
              </Typography>
              <div className={classes.datePickerData}>
                <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
                <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
                  {stop.arrival_date ? formatDateTime(stop.arrival_date, true) : ''}
                </Typography>
              </div>
            </div>
          ) : (
            <DateTimePicker
              label='Check In'
              name='arrival_date'
              value={values.arrival_date}
              onChange={(val) => setFieldValue('arrival_date', val)}
              disabled={!!stop.arrival_date}
              disablePast={!shipmentSettings?.allow_past_date}
              error={touched?.arrival_date && errors?.arrival_date}
            />
          )}
        </div>

        {!!stop.departure_date || !stop.arrival_date ? (
          <div className={classes.datePickerDataWrapper}>
            <Typography variant='s2' className='mb-2'>
              Check Out
            </Typography>
            <div className={classes.datePickerData}>
              <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
              <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
                {dateFormat}
              </Typography>
            </div>
          </div>
        ) : (
          <DateTimePicker
            label='Check Out'
            name='departure_date'
            value={values.departure_date}
            onChange={(val) => setFieldValue('departure_date', val)}
            disabled={!!stop.departure_date || !stop.arrival_date}
            minDate={new Date(stop.arrival_date)}
            error={touched?.departure_date && errors?.departure_date}
          />
        )}
      </div>

      <div className={classes.equipmentActionWrapper} style={{ marginBottom: 0 }}>
        <Typography variant='s2'>Equipment Action</Typography>
        <div>
          <FastField
            value='1'
            type='radio'
            name='equipment_action'
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Live Unload
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>

        <div>
          <FastField
            value='2'
            type='radio'
            name='equipment_action'
            labelStyle={{ marginTop: 8 }}
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Drop Trailer{' '}
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>
        {Number(values?.equipment_action) === 2 && (
          <div className={classes.dropHookSubBlock} style={{ margin: '8px 0 0 16px' }}>
            <div className={classes.selectDropHook}>
              <FastField
                name='drop_id'
                component={CustomSelect}
                styles={{ height: 32 }}
                disabled
                options={equipmentId}
                shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'drop_id')}
                defaultText={
                  shipment?.equipment?.hooked_to?.equipment_id
                    ? `${shipment?.equipment?.hooked_to?.equipment_id}  (${
                        shipment?.equipment?.hooked_to?.length?.length || ''
                      }
                       
                       ${shipment?.equipment?.hooked_to?.length?.unit || ''}
                       ${shipment?.equipment?.hooked_to?.equipment_type?.title || ''}) `
                    : ''
                }
              />
            </div>
          </div>
        )}
      </div>

      {!values.check_in && (
        <div>
          <div className={classes.proofWrapper}>
            <Typography variant='s2'>Proof of Delivery</Typography>
            <div>
              <FastField
                name='proof_of_delivery_name'
                component={CustomInput}
                style={{ width: '100%', maxWidth: 320, paddingLeft: 5 }}
                validate={validateRequired}
                placeholder='Full Name'
                labelStyle={styles.nameInput}
                className={classes.customInputField}
                onChange={(e) => {
                  setFieldValue('proof_of_delivery_name', e.target.value);
                  setFieldValue('signature_name', e.target.value);
                }}
              />
              <ErrorMessage
                name='proof_of_delivery_name'
                render={(error) => (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                )}
              />
            </div>
          </div>

          {!!shipment?.shipment_billing?.[0]?.billing_customer?.customer_payment_term?.require_bill_of_lading && (
            <div className={classes.proofWrapper} style={{ marginBottom: 32 }}>
              <Typography variant='s2'>{billOfLadingTitle}</Typography>

              <div>
                <FastField
                  name='bill_of_lading_ref'
                  component={CustomInput}
                  style={{ width: '100%', maxWidth: 320, paddingLeft: 5 }}
                  placeholder='Reference ID'
                  labelStyle={styles.labelInput}
                  className={classes.customInputField}
                />
                <ErrorMessage
                  name='bill_of_lading_ref'
                  render={(error) => (
                    <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                      {error}
                    </Typography>
                  )}
                />
              </div>

              <div
                className={classes.modalAddDocumentUpload}
                style={{ backgroundColor: use(palette.white, palette.dark800), marginBottom: 4 }}
              >
                <Field name='bill_of_lading'>
                  {() => (
                    <ImageDropZone
                      width='100%'
                      height='100%'
                      maxSize={1000000}
                      deletable={false}
                      name='bill_of_lading'
                      deletedComponent
                      accept={['application/pdf']}
                      customStyle={styles.dropZone}
                      onDrop={(a, n) => onDrop(a, n)}
                    >
                      <div className={classes.dropZoneContent}>
                        <UploadIcon />

                        {values?.bill_of_lading ? (
                          <div className={classes.uploadedFiles}>
                            <TickIcon fill={palette.green500} />
                            <Typography variant='s2' style={{ color: palette.green500 }}>
                              File Uploaded
                            </Typography>
                            <div onClick={(e) => onDelete(e, 'bill_of_lading')}>
                              <DeleteIcon fill={palette.indigo500} />
                            </div>
                          </div>
                        ) : (
                          <div className={classes.uploadedFiles}>
                            <Typography variant='s2'>
                              <Typography style={{ color: palette.indigo500 }}>Upload document&nbsp;</Typography>
                              or drag and drop
                            </Typography>
                          </div>
                        )}
                      </div>
                    </ImageDropZone>
                  )}
                </Field>
              </div>
              <ErrorMessage
                name='bill_of_lading'
                render={(error) => (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                )}
              />
            </div>
          )}

          {!!shipment?.shipment_billing?.[0]?.billing_customer?.customer_payment_term?.require_signature_on_stop && (
            <div className={classes.signature_wrapper}>
              <div className={classes.signature_top}>
                <div style={{ marginBottom: 8 }}>
                  <Typography variant='s2'>Signature</Typography>
                </div>
                <div>
                  <span onClick={onClear} className={classes.clear} style={{ marginRight: 10 }}>
                    Clear
                  </span>
                  <span onClick={save} className={classes.clear}>
                    Save
                  </span>
                </div>
              </div>
              <div className={classes.signature_area}>
                <SignatureCanvas canvasProps={{ className: classes.sigCanvas }} penColor='black' ref={signPad} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DeliveryInModal;
