import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { SLoadingWrapper, SBackdrop, SCircularProgress } from './Loader.styles';

const Loader = ({ loading, size = 26, minHeight }) => {
  if (!loading) {
    return null;
  }

  return (
    <SLoadingWrapper $minHeight={minHeight}>
      <CircularProgress size={size} />
    </SLoadingWrapper>
  );
};

export default Loader;

export const SimpleLoader = ({ loading, color, size = 26 }) => {
  if (!loading) {
    return null;
  }

  return <SCircularProgress size={size} $color={color} />;
};

export const BackdropLoader = ({ loading }) => {
  return (
    <SBackdrop open={loading}>
      <CircularProgress size={30} />
    </SBackdrop>
  );
};
