import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './AddNoteModal.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import { notesValidationSchema } from '../Drivers/AddDriver/ValidationSchema';
import ImageDropZone from '../ImgageDropZone/index';

export default function NoteModal({ driverId, createNotes, onSuccess, loading, emails, ...props }) {
  const { use } = useTheme();

  const formik = useFormik({
    initialValues: {
      title: '',
      notes: '',
      note_to_email: '',
      attachment: '',
      department_id: null,
    },
    validationSchema: notesValidationSchema,
  });

  useEffect(() => {
    formik.setFieldValue('title', '');
  }, []);

  const uploadDocument = (file, propertyName) => {
    formik.setFieldValue(propertyName, file[0]);
  };

  const addNotes = () => {
    const notesObj = {
      ...formik.values,
      driver_id: Number(driverId),
    };
    const formData = new FormData();
    formData.append('driver_id', notesObj.driver_id);
    formData.append('department_id', notesObj.department_id);
    formData.append('title', notesObj.title);
    formData.append('notes', notesObj.notes);
    formData.append('note_to_email', notesObj.note_to_email);
    formData.append('department_id', notesObj.department_id);
    formData.append('attachment', notesObj.attachment);
    createNotes(formData, props.onHide, successResponse, 'Notes has been successfully created');
  };

  const successResponse = (msg) => {
    onSuccess(msg);
  };

  return (
    <div>
      <Modal
        {...props}
        dialogClassName='modal-50w'
        enforceFocus={false}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            style={{ color: use(palette.gray900, palette.gray50) }}
            className='heading'
            id='contained-modal-title-vcenter'
          >
            Add Note
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='note-modal-body-box driver-style-wrap'
          style={{ backgroundColor: use(palette.gray0, palette.dark900) }}
        >
          <div className='note-modal-wrap'>
            <div className='note-container-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Title <span className='required'>*</span>
              </p>
              <div>
                <input
                  type='text'
                  className='reason-input'
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray600),
                  }}
                  name='title'
                  id='title'
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div>
                {formik.touched.title && formik.errors.title ? (
                  <span className='note-error-message'>{formik.errors.title}</span>
                ) : null}
              </div>
            </div>
            <div className='note-container-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Notes <span className='required'>*</span>
              </p>
              <div>
                <textarea
                  className='note-text-area'
                  placeholder='Write notes...'
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray50),
                  }}
                  name='notes'
                  id='notes'
                  onChange={formik.handleChange}
                  value={formik.values.notes}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div>
                {formik.touched.notes && formik.errors.notes ? (
                  <span className='note-error-message'>{formik.errors.notes}</span>
                ) : null}
              </div>
            </div>

            <p
              className='attachment-text-style'
              style={{ marginLeft: '0', color: use(palette.gray900, palette.gray50) }}
            >
              Attachment
            </p>
            {formik?.values?.attachment ? (
              <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={() => formik.setFieldValue('attachment', null)}
                />
              </p>
            ) : (
              <div
                className='upload-document-info'
                style={{
                  background: use(palette.white, palette.dark800),
                  color: use(palette.gray900, palette.gray50),
                  borderColor: use(palette.gray200, palette.gray700),
                  position: 'relative',
                }}
              >
                <label htmlFor='myFile' className='text-style upload-label'>
                  <p className='upload-document-icon'>
                    <img src={uploadLicense} alt='' />
                  </p>
                  <p className='upload-document-text'>
                    <span style={{ color: '#4F5AED' }}> Upload Document </span>
                    <span style={{ color: use(palette.gray700, palette.gray200) }}> or drag and drop</span>
                  </p>
                </label>
                <input
                  type='file'
                  id='myFile'
                  name='filename'
                  onChange={(e) => {
                    formik.setFieldValue('attachment', e.currentTarget.files[0]);
                  }}
                  style={{ display: 'none' }}
                />

                <ImageDropZone
                  onDrop={(file) => uploadDocument(file, 'attachment')}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  maxSize={1000000}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['application/pdf', 'image/jpeg', 'image/png']}
                />
              </div>
            )}
            <div className='sub-input-wrap'>
              <div className='note-sub-container-wrapper'>
                <p
                  className='text-style abcense-text-style'
                  style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                >
                  Email This Note to <span className='required'>*</span>
                </p>
                <div>
                  <input
                    type='text'
                    placeholder='example@email.com'
                    className='sub-input sub-input-email-note'
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                    name='note_to_email'
                    id='note_to_email'
                    onChange={formik.handleChange}
                    value={formik.values.note_to_email}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div>
                  {formik.touched.note_to_email && formik.errors.note_to_email ? (
                    <span className='note-error-message'>{formik.errors.note_to_email}</span>
                  ) : null}
                </div>
              </div>

              <div className='note-sub-container-wrapper'>
                <p
                  className='text-style abcense-text-style'
                  style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                >
                  CC Department Email <span className='required'>*</span>
                </p>
                <div
                  className='owner-dropdown'
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray50),
                  }}
                >
                  <select
                    className='text-style ms-0  p-2 select-group'
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                    name='department_id'
                    id='department_id'
                    onChange={formik.handleChange}
                    value={formik.values.department_id}
                  >
                    <option>Select...</option>
                    {emails.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item?.department_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  {formik.touched.cc_department_email && formik.errors.cc_department_email ? (
                    <span className='note-error-message'>{formik.errors.cc_department_email}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark800),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.gray50, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button disabled={!formik.isValid} className='next-step' type='submit' onClick={() => addNotes()}>
                Add Note
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
