import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ExtenderIcon } from 'assets/icons/extender.svg';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import {
  SInputLabel,
  SInput,
  SInputWithIcon,
  SInputWithIconWrapper,
  SInputWrapper,
  STextarea,
  STextareaWrapper,
} from './Input.styles';

const Input = forwardRef(
  ({ label, error, required, className, classNameInput, onClick, labelProps, endAdornment, ...props }, ref) => {
    return (
      <SInputWrapper className={className} onClick={onClick}>
        {label && (
          <InputLabel required={required} {...labelProps}>
            {label}
          </InputLabel>
        )}
        <SInput
          ref={ref}
          onWheel={(e) => e.target.blur()}
          className={classNameInput}
          $endAdornment={!!endAdornment}
          {...props}
        />
        {endAdornment}
        <ErrorMessage error={error} />
      </SInputWrapper>
    );
  }
);

export const InputWithIcon = forwardRef(
  (
    {
      label,
      error,
      required,
      className,
      icon,
      wrapperStyle,
      wrapperClassName,
      disabled,
      onClick,
      width,
      height,
      labelProps,
      left = true,
      ...props
    },
    ref
  ) => {
    const { currency } = useSelector((state) => state.root);

    return (
      <div className={className} onClick={onClick}>
        {label && (
          <InputLabel required={required} {...labelProps}>
            {label}
          </InputLabel>
        )}
        <SInputWithIconWrapper
          style={wrapperStyle}
          className={wrapperClassName}
          $width={width}
          $height={height}
          $disabled={disabled}
        >
          {left && (icon || currency)}
          <SInputWithIcon ref={ref} onWheel={(e) => e.target.blur()} disabled={disabled} $left={left} {...props} />
          {!left && (icon || currency)}
        </SInputWithIconWrapper>
        <ErrorMessage error={error} />
      </div>
    );
  }
);

export const InputWithText = ({
  text,
  style = {},
  left = false,
  wrapperClassName,
  label,
  required,
  bgColor,
  error,
  textWidth,
  labelProps,
  size,
  ...props
}) => {
  const { use } = useTheme();

  return (
    <div className={wrapperClassName}>
      {label && (
        <InputLabel required={required} {...labelProps}>
          {label}
        </InputLabel>
      )}
      <div className='d-flex align-items-center'>
        {left && (
          <SInputLabel $left={left} $bgColor={bgColor} $textWidth={textWidth} $size={size}>
            <Typography variant='s2' style={{ color: use(palette.gray500, palette.gray200), whiteSpace: 'nowrap' }}>
              {text}
            </Typography>
          </SInputLabel>
        )}
        <Input
          size={size}
          style={{ borderRadius: left ? '0 6px 6px 0' : '6px 0 0 6px', verticalAlign: 'top', ...style }}
          onWheel={(e) => e.target.blur()}
          {...props}
        />
        {!left && (
          <SInputLabel $bgColor={bgColor} $textWidth={textWidth} $size={size}>
            <Typography variant='s2' style={{ color: use(palette.gray500, palette.gray200) }}>
              {text}
            </Typography>
          </SInputLabel>
        )}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};

export const InputWithIconAndText = ({ text, wrapperClassName, size, bgColor, ...props }) => {
  const { use } = useTheme();

  return (
    <div className={`d-flex ${wrapperClassName || ''}`}>
      <InputWithIcon
        wrapperStyle={{ borderRadius: '6px 0 0 6px' }}
        onWheel={(e) => e.target.blur()}
        size={size}
        {...props}
      />
      <SInputLabel $bgColor={bgColor} $size={size}>
        <Typography variant='s2' style={{ color: use(palette.gray500, palette.gray200) }}>
          {text}
        </Typography>
      </SInputLabel>
    </div>
  );
};

export const Textarea = ({ label, error, required, className, labelProps, width, ...props }) => {
  return (
    <div className={className}>
      {label && (
        <InputLabel required={required} {...labelProps}>
          {label}
        </InputLabel>
      )}
      <STextareaWrapper>
        <STextarea $width={width} {...props} />
        <ExtenderIcon className='extender-icon' />
      </STextareaWrapper>
      <ErrorMessage error={error} />
    </div>
  );
};

export default Input;
