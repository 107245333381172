import axios from 'services/axios';

export async function getPremiumPlans(params) {
  const { data } = await axios.get('user/registration/plan/carrier/premium', { params });
  return data;
}

export async function getServices() {
  const { data } = await axios.get('get-all-connected-services');
  return data;
}

export async function createLoginAttempt(body) {
  const { data } = await axios.post('attempted-login', body);
  return data;
}

export async function getOnboardingSettings() {
  const { data } = await axios.get('on-boarding-setting');
  return data;
}
