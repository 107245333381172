import React, { useContext, useMemo } from 'react';
import { useTheme } from 'context/themeContext';
import { getAlphabet, palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import useDateFormat from 'hooks/useDateFormat';
import sm from './OptimizeRoutePage.module.css';
import { ShipmentContext } from '../../../Layout';
import { SCHEDULED_DATE_TYPE } from '../constants';

const OptimizeRoutePage = ({ leg, indexPrefix, legsStopsCount }) => {
  const { use } = useTheme();
  const { formatDate, formatTime } = useDateFormat();
  const { stopPoints, createShipmentData } = useContext(ShipmentContext);

  const getStopName = (type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: use(palette.green500, palette.green400) },
      2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500) },
      3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText) },
    };
    return TYPE_STOPS[type];
  };

  const prevStopsCount = useMemo(() => {
    const prevStopsCountArr = legsStopsCount.slice(0, indexPrefix);
    return prevStopsCountArr.length ? prevStopsCountArr.reduce((a, b) => a + b, 0) : 0;
  }, [legsStopsCount, indexPrefix]);
  return (
    <div className={sm.optimize_route_container}>
      {leg?.stops.map((stop, index) => {
        const point = stopPoints.find((point) => Number(point?.key) === +stop.stop_point_id);
        const scheduledType = SCHEDULED_DATE_TYPE.find((type) => Number(type?.key) === +stop.scheduled_type);
        return (
          <div key={stop?.id} className={[sm.optimize_stop_wrapper, sm[use('light', 'dark')]].join(' ')}>
            <Typography
              variant='c1'
              className={sm.pickUp_title}
              style={{ color: getStopName(stop.stop_point_type).color }}
            >
              STOP {getAlphabet(prevStopsCount + index)} {getStopName(stop.stop_point_type).type}
            </Typography>
            <div className={sm.line} />
            <Typography variant='s1'>{point?.location_name}</Typography>
            <div className='d-flex justify-content-between'>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray300) }}>
                {point?.address1}
              </Typography>

              <span style={{ backgroundColor: '#E9EDF5', padding: '2px 8px', borderRadius: 8, fontSize: 11 }}>
                Avg. Wait Time: {getAverageTimeConverted(point?.average_waiting_time)}
              </span>
            </div>

            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray300) }}>
              {point?.address2}
            </Typography>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray300) }}>
              {point?.city} {point?.state} {point?.zipcode}
            </Typography>

            {Number(createShipmentData?.frequency) !== 2 && (
              <div className={sm.date_wrapper}>
                <Typography variant='c1'>{stop?.scheduled_date ? formatDate(stop?.scheduled_date) : ''}</Typography>
                <Typography variant='c1' style={{ marginRight: 5 }}>
                  {stop?.startTime ? `, ${formatTime(stop?.startTime)}` : ''}
                </Typography>
                <Typography variant='c1' style={{ marginRight: 5 }}>
                  {stop?.endTime ? `to ${formatTime(stop?.endTime)}` : ''}
                </Typography>
                <Typography variant='c1'>
                  {Number(scheduledType?.key) === 1 ? '(FCFS)' : scheduledType?.label ? scheduledType?.label : ''}
                </Typography>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OptimizeRoutePage;
