import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SettingsIcon } from 'assets/sidemanu/settings-20px.svg';
import HeaderStar from 'components/HeaderStar';
import { Typography } from 'components/Typography';
import StripeConnection from 'componentsV2/StripeConnection';
import CustomButton from 'components/CustomButton/CustomButton';
import { SEND_PAYMENT_KEYS } from 'components/SendPaymentModal/constants';
import { useTheme } from 'context/themeContext';
import { palette, STRIPE_MODULE } from 'utils/constants';
import { getTopCompany } from 'Api/Payroll';
import { getPayPeriod } from 'Api/PayrollSettings';
import { statusCountConverter, topCompanyConverter } from './components/TopSection/helpers';
import PayPeriod from '../shared/PayPeriod';
import TopSection from './components/TopSection';
import TableSection from './components/TableSection';
import { getCurrentPayPeriod } from './helpers';
import { SHeaderWrapper, SPageWrapper } from './PayrollAndSettlements.styles';
import '../payroll.css';

const buttonStyles = {
  padding: '6px 12px',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 500,
  margin: 0,
  height: '33px',
};

const PayrollAndSettlements = () => {
  const navigate = useNavigate();
  const { use } = useTheme();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [topCompany, setTopCompany] = useState(null);
  const [currentPayPeriod, setCurrentPayPeriod] = useState(null);
  const [checkedDriverTypes, setCheckedDriverTypes] = useState(['company', 'owner']);

  const getTopCompanyData = async () => {
    try {
      setLoading(true);
      const { data } = await getTopCompany({ driver_type: checkedDriverTypes });
      const convertedData = topCompanyConverter(data);
      const statuses = statusCountConverter(data.amounts_by_statuses);
      setStatus(statuses);
      setTopCompany(convertedData);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const getPayPeriodSettings = async () => {
    try {
      const { data } = await getPayPeriod();
      const range = getCurrentPayPeriod(data);
      if (range) {
        setCurrentPayPeriod(range);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onDriverTypeChange = (type) => {
    let newTypes;
    if (checkedDriverTypes.includes(type)) {
      newTypes = checkedDriverTypes.filter((item) => item !== type);
    } else {
      newTypes = [...checkedDriverTypes, type];
    }

    setCheckedDriverTypes(newTypes);
  };

  useEffect(() => {
    getPayPeriodSettings();
    getTopCompanyData();
  }, []);

  const listenEmitter = ({ detail }) => {
    if (detail.activeName === SEND_PAYMENT_KEYS.OPEN_SETTLEMENTS) {
      getTopCompanyData();
    }
  };

  const onGearClick = () => {
    navigate('/company-settings', { state: { tabId: 10, subTabName: 'PayPeriod' } });
  };

  useEffect(() => {
    window.addEventListener('on_print_check_complete', listenEmitter);
    return () => window.removeEventListener('on_print_check_complete', listenEmitter);
  }, []);

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Payroll' />
        <div className='menu-left-section'>
          {currentPayPeriod && (
            <div className='menu-left-section__pay-period'>
              <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray50) }}>
                Current Pay Period:{' '}
              </Typography>
              <PayPeriod start={currentPayPeriod.start} end={currentPayPeriod.end} />
            </div>
          )}
          <StripeConnection module={STRIPE_MODULE.PAYROLL} />
          <CustomButton
            type='secondary'
            title=''
            leftIcon={<SettingsIcon fill={palette.gray700} />}
            styleButton={buttonStyles}
            onClick={onGearClick}
          />
        </div>
      </SHeaderWrapper>
      <TopSection
        topCompany={topCompany}
        loading={loading}
        checkedDriverTypes={checkedDriverTypes}
        onDriverTypeChange={onDriverTypeChange}
        getTopCompanyData={getTopCompanyData}
      />
      <TableSection status={status} updateTopSection={getTopCompanyData} />
    </SPageWrapper>
  );
};

export default PayrollAndSettlements;
