import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SInfoSection = styled.div`
  .plan-table {
    background: ${palette.gray0};
  }

  .faq-card {
    width: 600px;
    max-width: 600px;
    margin: 10px;
  }

  .disclaimer-container {
    width: 100%;

    .disclaimer {
      width: 600px;
      margin: 24px auto 0;
    }
  }
`;
