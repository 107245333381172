import { number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  incident_date: validator.pastRequiredDate(),
  incident_type: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  report_details: string().when('incident_type', {
    is: (incident_type) => incident_type?.type !== 'Accident / Crash',
    then: string().trim().required('Required'),
    otherwise: string().nullable(),
  }),
  action_taken: object().when('canReview', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
