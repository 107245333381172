import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import DatePicker from 'common/DatePicker';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';

export const groupInvoicesByCustomer = (invoices) => {
  const groupedInvoices = new Map();

  invoices.forEach((invoice) => {
    const customerId = invoice.billing_customer.id;
    if (!groupedInvoices.has(customerId)) {
      groupedInvoices.set(customerId, {
        billing_customer: invoice.billing_customer,
        invoices: [],
        totalAmount: 0,
      });
    }
    const customerData = groupedInvoices.get(customerId);
    customerData.invoices.push(invoice);
    customerData.totalAmount += invoice.total_amount;
  });
  return Array.from(groupedInvoices.values());
};

export const paymentMethods = [
  {
    title: 'Online',
    description: 'Credit Card, ACH & Wallets',
    value: 'online',
  },
  {
    title: 'Wire',
    description: 'Offline Funds Transfer',
    value: 'wire',
  },
  {
    title: 'ACH',
    description: 'Offline Funds Transfer',
    value: 'ach',
  },
  {
    title: 'Check',
    description: 'Standard Mail',
    value: 'check',
  },
  {
    title: 'Cash',
    description: 'Collect In Person',
    value: 'cash',
  },
];

export const addOrDeletePaymentMethod = (methodsArray, method, payable_to) => {
  let selectedMethods = methodsArray;
  const index = selectedMethods.indexOf(method);
  if (index !== -1) {
    selectedMethods.splice(index, 1);
  } else {
    if (payable_to === 'own_company' || payable_to === 'brokerage') {
      selectedMethods.push(method);
    } else {
      selectedMethods = [method];
    }
  }
  return selectedMethods;
};

export const getPaymentMethodForFactor = (values) => {
  if (values.payment_method.length === 1 && values.payment_method[0] !== 'online') {
    return values.payment_method;
  }
  return [];
};

export const getPaymentMethodsCombinedValue = (selectedMethods) => {
  const paymentMethods = {
    online: 1,
    ach: 2,
    wire: 4,
    check: 8,
    cash: 16,
  };

  let combinedValue = 0;

  selectedMethods.forEach((method) => {
    if (paymentMethods[method]) {
      combinedValue += paymentMethods[method];
    }
  });

  return combinedValue;
};

export const urlToBlob = (fileUrl) => {
  return fetch(fileUrl).then((response) => response.blob());
};

export const onDownload = (path, fileName) => {
  fetch(path, { mode: 'cors' })
    .then((resp) => resp.arrayBuffer())
    .then((resp) => {
      const file = typeof path === 'string' ? new Blob([resp], { type: 'application/pdf' }) : path;
      const link = document.createElement('a');
      const url = URL.createObjectURL(file);
      link.setAttribute('download', typeof path === 'string' ? 'download' : path.name);
      link.href = url;
      link.download = `${fileName}.pdf`;
      link.click();
    });
};

export const getInvoiceDueDate = (invoice_pay_term, bill_date) => {
  switch (invoice_pay_term) {
    case '1':
      return moment(bill_date).add(30, 'days').toDate();
    case '2':
      return moment(bill_date).add(60, 'days').toDate();
    case '3':
      return moment(bill_date).add(90, 'days').toDate();
    default:
      return moment(bill_date).add(30, 'days').toDate();
  }
};

export const getUniqueCustomersFromInvoices = (invoices) => {
  const uniqueCustomersMap = new Map();
  invoices.forEach((item) => {
    uniqueCustomersMap.set(item.billing_customer?.id, item);
  });
  const uniqueCustomersArray = [...uniqueCustomersMap.values()];

  return uniqueCustomersArray.map((el) => el.billing_customer);
};

export const initialValues = {
  bill_date: new Date(),
  payment_method: [],
  payable_to: 'own_company',
  send_email: false,
  print_mail: false,
  send_edi: false,
  delivery_option: 0,
  mail_to_id: null,
  batch_due_date: null,
  email_to: [],
  is_batch: false,
  attach_statement: false,
  attach_fti: false,
  attach_noa_letter: false,
  batchDueDates: [],
  batchEmailTo: [],
  batchIds: [],
};

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useColumns = ({ billDate, onDateChange }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'bill_to',
      title: <Title title='BILL TO' />,
      render: (row) => (
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='s2'>{row.billing_customer?.company_name}</Typography>
          {(!!row.shipment?.is_edi_api || !!row.shipment?.manual_edi_api) && <CloudIcon fill={palette.green400} />}
        </div>
      ),
    },
    {
      field: 'bill_date',
      title: <Title title='COMPLETE DATE' />,
      render: (row) => (
        <Typography variant='s2'>
          {row.shipment.end_date_time ? formatDate(row.shipment.end_date_time) : '-'}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      title: <Title title='DUE DATE' />,
      render: (row) => (
        <div style={{ paddingRight: '20px' }}>
          <DatePicker
            name='due_date'
            width={200}
            value={row.due_date}
            onChange={(val) => onDateChange(row.id, val)}
            minDate={billDate}
            dateFormat='MM/dd/yyyy'
            error
            required
          />
        </div>
      ),
    },
    {
      field: 'invoice_id',
      title: <Title title='INVOICE ID' />,
      render: (row) => <Typography variant='s2'>{row.invoiceId}</Typography>,
    },
    {
      field: 'reference_id',
      title: <Title title='REFERENCE ID' />,
      render: (row) => <Typography variant='s2'>{row.reference_id}</Typography>,
    },
    {
      field: 'total_charges',
      title: <Title title='TOTAL CHARGES' />,
      render: (row) => (
        <Typography variant='s2'>
          {currency}
          {formatNumber(row.total_amount)}
        </Typography>
      ),
    },
  ];
};

export const useUniqueCustomerColumns = ({ billDate, onDateChange }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'invoice_id',
      title: <Title title='INVOICE ID' />,
      render: (row) => <Typography variant='s2'>{row.invoiceId}</Typography>,
    },
    {
      field: 'bill_date',
      title: <Title title='COMPLETE DATE' />,
      render: (row) => (
        <Typography variant='s2'>
          {row.shipment.end_date_time ? formatDate(row.shipment.end_date_time) : '-'}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      title: <Title title='DUE DATE' />,
      render: (row) => (
        <div style={{ paddingRight: '20px' }}>
          <DatePicker
            name='due_date'
            width='130px'
            value={row.due_date}
            onChange={(val) => onDateChange(row.id, val)}
            minDate={billDate}
            dateFormat='MM/dd/yyyy'
            error
            required
          />
        </div>
      ),
    },
    {
      field: 'reference_id',
      title: <Title title='REFERENCE ID' />,
      render: (row) => <Typography variant='s2'>{row.reference_id}</Typography>,
    },
    {
      field: 'total_charges',
      title: <Title title='TOTAL CHARGES' />,
      render: (row) => (
        <Typography variant='s2'>
          {currency}
          {formatNumber(row.total_amount)}
        </Typography>
      ),
    },
  ];
};
