import * as yup from 'yup';
import { number, object, string } from 'yup';

const validationSchema = object({
  companyName: string().required('Company name is required'),
  phone_number: yup
    .string()
    .min(10, 'Enter valid number')
    .max(12, 'Enter valid number')
    .required('Phone number is required'),
  customer_address: string().required('Address 1 is required').nullable(),
  country_data: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state_data: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city_data: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  customer_zip: string().required('Zip code is required').nullable(),
  account: object()
    .shape({ id: number().required('Account is required') })
    .required('Account is required')
    .nullable(),
});

export default validationSchema;
