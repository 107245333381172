import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Skeleton } from '@material-ui/lab';
import { palette } from 'utils/constants';
import useMediaQuery from '@mui/material/useMediaQuery';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const getOptions = (matches, matches1) => {
  return {
    responsive: true,
    aspectRatio: matches ? 8 : matches1 ? 6 : 4.5,
    borderWidth: 1.5,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
    elements: {
      point: {
        radius: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        suggestedMax: 5,
        beginAtZero: true,
        grid: {
          borderDash: [8, 8],
          color: palette.gray50,
          drawBorder: false,
        },
        ticks: {
          stepSize: 1,
          callback(value) {
            if (value === 0) {
              return value;
            }
            return `$${value}`;
          },
        },
      },
    },
  };
};

const FuelPricesChart = ({ labels, dataset, loading }) => {
  const matches = useMediaQuery('(min-width:2000px)');
  const matches1 = useMediaQuery('(min-width:1500px)');

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: dataset,
        borderColor: '#4F5AED',
        backgroundColor: 'rgba(79, 90, 237, 0.2)',
      },
    ],
  };

  return (
    <div className='d-flex justify-content-center align-items-center' style={{ aspectRatio: '1', maxHeight: '280px' }}>
      {loading ? (
        <Skeleton variant='rect' animation='wave' height='100%' width='100%' style={{ borderRadius: '10px' }} />
      ) : (
        <Line id='chart' data={data} options={getOptions(matches, matches1)} />
      )}
    </div>
  );
};

export default FuelPricesChart;
