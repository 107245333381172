import moment from 'moment/moment';
import { endOfYear, startOfYear } from 'date-fns';

export const initialYearFilters = {
  miles_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  revenue_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  closed_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  closed_month: moment().month() + 1,
  graphReadyAndMissingYear: moment().year(),
  graphOpenReceivablesYear: moment().year(),
  graphOpenReceivablesMonth: moment().month() + 1,
};

export const statTypeRangeMapper = {
  miles: 'miles_range',
  'gross transport revenue': 'revenue_range',
  'closed receivables': 'closed_range',
};

export const initialStats = {
  miles: {},
  'gross transport revenue': {},
  'closed receivables': {},
};

export const statsDataConverter = (data) => {
  const { closedReceivable, miles_data, transport_revenue } = data || {};

  const milesAmountDiff = (miles_data?.current_month || 0) - (miles_data?.last_month || 0);
  const revenuePerMileDiff =
    (transport_revenue?.current_month?.per_mile || 0) - (transport_revenue?.last_month?.per_mile || 0);
  const closedAmountDiff = (closedReceivable?.currentMonth || 0) - (closedReceivable?.lastMonth || 0);

  return {
    miles: { ...miles_data, milesAmountDiff },
    'gross transport revenue': { ...transport_revenue, perMileDiff: revenuePerMileDiff },
    'closed receivables': { ...closedReceivable, amountDiff: closedAmountDiff },
  };
};
