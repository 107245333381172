import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getEquipmentAtStopReport } from 'Api/Reports';
import { SWaitTimeGraph } from './WaitTimeGraph.styles';
import { getOptions, graphDataConverter } from './WaitTimeGraph.data';

const WaitTimeGraph = ({ vehicles, drivers, dateRange, type, filters, setFilters }) => {
  const [reportData, setReportData] = useState([]);
  const [count, setCount] = useState(1);
  const chartRef = useRef();
  const { stopPoint, averageRange } = filters || {};

  const getReportData = async () => {
    try {
      const params = {
        from: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        stop_point_ids: !stopPoint ? undefined : [stopPoint.id],
        driver_ids:
          type !== 'driver' ? undefined : (filters.drivers?.length ? filters.drivers : drivers).map((i) => i.id),
        equipment_ids:
          type !== 'equipment'
            ? undefined
            : (filters.equipment?.length ? filters.equipment : vehicles).map((i) => i.id),
      };
      const { data } = await getEquipmentAtStopReport(params);
      setCount(data.length || 1);
      const convertedData = graphDataConverter(data);
      setReportData(convertedData || null);
    } catch (e) {
      // Do nothing
    }
  };

  const data = {
    labels: (reportData || []).map((item) => [`${item.label}`, `${Math.round((item.count * 100) / count)}%`]),
    datasets: [
      {
        data: (reportData || []).map((item) => item.count),
        backgroundColor: (reportData || []).map((item, index, array) => {
          const hue = (index / (array.length - 1)) * 120;
          const lightness = 50 - 25 * (Math.abs(index - array.length / 2) / (array.length / 2));

          return `hsl(${array.length === 1 ? 113 : hue}, 100%, ${
            averageRange[0] === null || averageRange[0] === item.from ? lightness : 90
          }%)`;
        }),
        borderRadius: 4,
      },
    ],
  };

  const onClick = (event) => {
    const item = getElementAtEvent(chartRef.current, event)[0];

    if (item) {
      const data = reportData[item.index];
      setFilters((prevState) => ({
        ...prevState,
        averageRange: [
          prevState?.averageRange[0] === data.from ? null : data.from,
          prevState?.averageRange[1] === data.to ? null : data.to,
        ],
      }));
    }
  };

  useEffect(() => {
    if (!stopPoint || !type || !vehicles?.length) {
      return;
    }

    getReportData();
  }, [dateRange, filters, type, vehicles]);

  return (
    <SWaitTimeGraph>
      <div className='chart-box'>
        <div className='d-flex align-items-center justify-content-between mb-2'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Avg Wait Time
          </Typography>
        </div>
        <div className='chart-wrapper'>
          <Bar ref={chartRef} options={getOptions(count, reportData.length, type)} data={data} onClick={onClick} />
        </div>
      </div>
    </SWaitTimeGraph>
  );
};

export default WaitTimeGraph;
