export const initialValues = {
  driver_dispute: true,
  driver_dispute_days: 1,
  default_pay_raise: true,
  pay_raise_per_mile_loaded: '',
  pay_raise_per_mile_empty: '',
  pay_raise_per_mile_days: '',
  pay_raise_per_mile_months: '',
  pay_raise_per_mile_years: '',
  pay_raise_percentage: '',
  pay_raise_percentage_days: '',
  pay_raise_percentage_months: '',
  pay_raise_percentage_years: '',
  pay_raise_per_hour: '',
  pay_raise_per_hour_days: '',
  pay_raise_per_hour_months: '',
  pay_raise_per_hour_years: '',
  pay_per_hour_breaks_required: false,
  deduct_break_time: false,
  pay_per_hour_break_time_paid: false,
  pay_per_hour_break_time_allowed: '',
  pay_per_hour_breaks_allowed_in_given_days: '',
  detention_pay_per_hour: '',
  detention_pay_after_hours_passed: '',
  each_layover_pay: null,
  stop_off_pay_each_stop: null,
  stop_off_pay_after_stop: null,
  live_unload_pay: '',
  track_order_not_used_pay: '',
  break_down_pay: null,
  redelivery_pay: '',
  tarp_pay: '',
  paid_time_off_required: false,
  paid_time_off_per_day: '',
  detention: false,
  layover: false,
  stop_off: false,
  live_upload: false,
  truck_order_not_used: false,
  break_down: false,
  redelivery: false,
  tarp: false,
  pay_on_each_shipment: false,
  shipment_addition: [
    {
      payroll_addition: null,
      amount: '',
    },
  ],
};
