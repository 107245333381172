import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import Chip from 'common/Chip';
import Radio from 'common/Radio';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { getStaffById } from 'Api/User';
import { DriverGeneral } from 'Api/Driver';
import { createCardHolder } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { cardHolderTypes, initialValues, requestBodyConverter, shippingMethods } from './CardHolder.data';
import { SPaper, STabs, SCardHolder, STable } from './CardHolder.styles';

const CardHolder = ({ index, driverStaff, company, onSuccess, onRemove, isCreated, fees }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [cardHolderTab, setCardHolderTab] = useState('all');

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = requestBodyConverter(values, company);
      const { data } = await createCardHolder(formData);
      onSuccess({ ...data, ...values });
      showToaster({ type: 'success', message: 'Card holder has been successfully created!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const onCardHolderTypeChange = (val) => {
    if (val.value === 'company') {
      setValues((prevState) => ({
        ...prevState,
        card_holder_type: val,
        card_holder_name: company?.company_name || '',
        address: company.address1 || '',
        address2: company.address2 || '',
        country: company.country_id || null,
        state: company.state_id || null,
        city: company.city_id || null,
        email: company.email || '',
        phone_number: company.phone_number || '',
        first_name: company.first_name || '',
        last_name: company.last_name || '',
        zipcode: company.zip || '',
      }));
    } else {
      handleChange(`card_holder_type`, val);
    }
  };

  const onCardHolderChange = async (val) => {
    handleChange(`card_holder`, val);
    const { data } = val.user_type === 'driver' ? await DriverGeneral({ id: val.id }) : await getStaffById(val.id);
    const userData = data?.driver_details || data;
    setValues((prevState) => ({
      ...prevState,
      card_holder_name: `${userData.first_name || userData.fname} ${userData.last_name || userData.lname}` || '',
      address: userData.address1 || userData.driver_address || '',
      address2: userData.address2 || userData.driver_address2 || '',
      country: userData.country_data || userData.country || null,
      state: userData.state_data || userData.state || null,
      city: userData.city_data || userData.city || null,
      email: userData.email || '',
      phone_number: userData.phone_number || '',
      first_name: userData.first_name || userData.fname || '',
      last_name: userData.last_name || userData.lname || '',
      zipcode: userData.zip || userData.driver_zip || '',
      dob: userData.dob || '',
    }));
  };

  return (
    <SCardHolder>
      {index > 0 && (
        <DeleteIcon width={22} height={22} fill={palette.red500} className='delete-icon' onClick={onRemove} />
      )}
      <div className='d-flex gap-4'>
        <div>
          <Autocomplete
            required
            width='170px'
            label='Card Holder Type'
            name='card_holder_type'
            value={values.card_holder_type}
            options={cardHolderTypes}
            onChange={(e, val) => onCardHolderTypeChange(val, index)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            error={touchedErrors.card_holder_type}
            disabled={isCreated}
          />
        </div>
        {values.card_holder_type?.value === 'individual' && (
          <div className='w-100'>
            <Autocomplete
              required
              open={openPopover}
              labelKey='full_name'
              label='Card Holder'
              value={values.card_holder}
              inputProps={{
                onClick: () => setOpenPopover(true),
              }}
              onChange={(e, val) => {
                onCardHolderChange(val);
                setOpenPopover(false);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={driverStaff.filter((i) => cardHolderTab === 'all' || i.user_type === cardHolderTab)}
              error={touchedErrors.card_holder}
              disabled={isCreated}
              PaperComponent={({ children, ...props }) => {
                return (
                  <ClickAwayListener onClickAway={() => setOpenPopover(false)}>
                    <SPaper {...props}>
                      <STabs
                        value={cardHolderTab}
                        onChange={(e, tab) => setCardHolderTab(tab)}
                        variant='scrollable'
                        scrollButtons={false}
                      >
                        <Tab value='all' label='All' disableRipple />
                        <Tab value='driver' label='Driver' disableRipple />
                        <Tab value='staff' label='Staff' disableRipple />
                      </STabs>
                      {children}
                    </SPaper>
                  </ClickAwayListener>
                );
              }}
            />
          </div>
        )}
      </div>
      <div>
        <Input
          required
          label='Card Holder Name'
          name='card_holder_name'
          placeholder='ACME Corp'
          onChange={handleChange}
          value={values.card_holder_name}
          error={touchedErrors.card_holder_name}
          disabled={isCreated}
        />
      </div>
      {values.card_holder_type?.value === 'individual' && (
        <div className='d-flex align-items-start gap-4'>
          <div>
            <InputLabel className='mb-1' required>
              Government ID Front Side
            </InputLabel>
            <Uploader
              type={3}
              document={values.front}
              onDrop={(files) => handleChange(`front`, files[0])}
              onRemove={isCreated ? null : () => handleChange(`front`, null)}
              error={touchedErrors.front}
            />
          </div>
          <div>
            <InputLabel className='mb-1' required>
              Government ID Back Side
            </InputLabel>
            <Uploader
              type={3}
              document={values.back}
              onDrop={(files) => handleChange(`back`, files[0])}
              onRemove={isCreated ? null : () => handleChange(`back`, null)}
              error={touchedErrors.back}
            />
          </div>
        </div>
      )}
      <div>
        <InputLabel required>Ship To</InputLabel>
        <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} hideLabel />
      </div>
      <div>
        <Autocomplete
          required
          label='Shipping Method'
          name='shipping_method'
          value={values.shipping_method}
          options={shippingMethods}
          onChange={(e, val) => handleChange('shipping_method', val)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          error={touchedErrors.shipping_method}
        />
      </div>
      <STable>
        <thead>
          <tr className='header-row'>
            <th>Card Costs</th>
            <th>Qty</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className='d-flex align-items-center gap-2'>
                <Radio label='One Time' checked readOnly />
                <Chip
                  label={`$${formatNumber(fees?.issuing_card_cost_per_card)}`}
                  labelColor={palette.indigo500}
                  bgColor={palette.indigo50}
                />
              </div>
            </td>
            <td>
              <div className='d-flex align-items-center gap-3'>
                <Input
                  width='56px'
                  placeholder='1'
                  name='quantity'
                  onChange={handleChange}
                  value={values.quantity}
                  error={touchedErrors.quantity}
                />
                <DeleteIcon width={9} height={9} />
              </div>
            </td>
            <td>
              <div className='d-flex justify-content-end'>
                <InputWithIcon
                  width='120px'
                  icon='$'
                  placeholder='3.25'
                  name='cards_amount'
                  value={values.cards_amount}
                  disabled
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className='d-flex justify-content-end'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Shipping
                </Typography>
              </div>
            </td>
            <td />
            <td>
              <div className='d-flex justify-content-end'>
                <InputWithIcon
                  width='120px'
                  icon='$'
                  placeholder='3.25'
                  name='shipping_amount'
                  value={values.shipping_amount}
                  disabled
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className='d-flex justify-content-end'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Total Costs
                </Typography>
              </div>
            </td>
            <td />
            <td>
              <div className='d-flex justify-content-end'>
                <InputWithIcon
                  width='120px'
                  icon='$'
                  placeholder='3.25'
                  name='total_amount'
                  value={values.total_amount}
                  disabled
                />
              </div>
            </td>
          </tr>
        </tbody>
      </STable>
      <div className='d-flex justify-content-end'>
        {isCreated ? (
          <CheckIcon width={24} height={24} className='mt-3' />
        ) : (
          <div className='d-flex align-items-center gap-3'>
            <CustomButton
              type='primary'
              title='Save'
              styleTitle={{ fontSize: 14 }}
              styleButton={{
                padding: '2px 12px',
                margin: '16px 0 0 0',
                justifyContent: 'center',
              }}
              onClick={handleSubmit}
              disabled={loading}
            />
            {index > 0 && (
              <CustomButton
                type='danger'
                title='Remove'
                styleTitle={{ fontSize: 14 }}
                styleButton={{
                  padding: '2px 12px',
                  margin: '16px 0 0 0',
                  justifyContent: 'center',
                }}
                onClick={() =>
                  handleChange(
                    'card_holders',
                    values.card_holders.filter((el, i) => i !== index)
                  )
                }
                disabled={loading}
              />
            )}
          </div>
        )}
      </div>
    </SCardHolder>
  );
};

export default CardHolder;
