import React, { useEffect, useState } from 'react';

import Modal from 'common/Modal';
import useForm from 'hooks/useForm';
import Autocomplete from 'common/Autocomplete';
import useShowToaster from 'hooks/useShowToaster';
import Input, { InputWithIcon } from 'common/Input';
import { getErrorMessage } from 'utils/error';
import { getAccountsList } from 'Api/Accounts';
import { markSettlementPaid } from 'Api/Payroll';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './MarkPaidOne.data';
import { paymentMethods } from '../../../../Accounting/Payables/components/MarkPaid/MarkPaid.data';

const MarkPaidOne = ({ open, onClose, onSuccess, settlement }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        settlements: [
          {
            id: settlement.id,
            reference: values.reference,
            paid_amount: Number(values.paid_amount),
            paid_by_account_id: values.paid_by_account.id,
            payment_method: values.payment_method?.value,
          },
        ],
      };
      await markSettlementPaid(body);
      showToaster({ type: 'success', message: 'Settlement has been successfully paid!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(settlement),
    onSubmit,
    validationSchema,
  });

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => i.account_type.id === ACCOUNT_TYPE.BANK));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      $width='400px'
      $maxWidth='95vw'
      $minWidth='400px'
      headerTitle='Mark Paid'
      $bgColor={palette.gray0}
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Mark Paid',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <InputWithIcon
          required
          type='number'
          label='Amount'
          name='paid_amount'
          value={values.paid_amount}
          onChange={handleChange}
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors.paid_amount}
        />
        <div className='flex-item'>
          <Input
            required
            name='reference'
            label='Reference'
            value={values.reference}
            error={touchedErrors?.reference}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(`reference`, e.target.value)}
          />
        </div>
        <div>
          <Autocomplete
            required
            width='100%'
            label='Method'
            name='payment_method'
            options={paymentMethods}
            value={values.payment_method}
            onChange={(e, val) => handleChange('payment_method', val)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            error={touchedErrors.payment_method}
          />
        </div>
        <div>
          <Autocomplete
            required
            label='Funding Account'
            name='paid_by_account'
            labelKey='account_name'
            options={accounts}
            value={values.paid_by_account}
            onChange={(e, val) => handleChange('paid_by_account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.paid_by_account}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MarkPaidOne;
