import React from 'react';
import { useSelector } from 'react-redux';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Input, { InputWithIcon, InputWithIconAndText } from 'common/Input';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { SAmountWrapper, STableRow } from 'pages/Accounting/Receivables/Receivables.styles';
import { paymentMethods } from '../MarkPaid.data';

const Row = ({ invoice, handleChange, handleBlur, touchedErrors, index, accounts, values }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return (
    <STableRow>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
          {invoice.customer?.company_name || '-'}
        </Typography>
      </td>
      <td>
        <Autocomplete
          width='120px'
          size='small'
          name='payment_method'
          options={paymentMethods}
          value={invoice.payment_method}
          onChange={(e, val) => handleChange(`${index}.payment_method`, val)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          error={touchedErrors[index]?.payment_method}
        />
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {formatDate(invoice.due_date)}
        </Typography>
      </td>
      <td>
        <DatePicker
          size='small'
          name={`${index}.payment_date`}
          value={invoice.payment_date}
          onBlur={handleBlur}
          onChange={(val) => handleChange(`${index}.payment_date`, val)}
          error={touchedErrors?.[index]?.payment_date}
        />
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
          {invoice.invoice_id}
        </Typography>
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {invoice.reference_id || '-'}
        </Typography>
      </td>
      <td>
        <Input
          width='200px'
          size='small'
          name='pay_reference'
          placeholder='ABC'
          value={invoice.pay_reference}
          onChange={(e) => handleChange(`${index}.pay_reference`, e.target.value)}
          error={touchedErrors[index]?.pay_reference}
        />
      </td>
      <td>
        <Autocomplete
          size='small'
          width='180px'
          name='account'
          labelKey='account_name'
          options={accounts}
          value={invoice.account}
          onChange={(e, val) => handleChange(`${index}.account`, val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          error={touchedErrors[index]?.account}
        />
      </td>
      <td>
        <div className='d-flex flex-column gap-1'>
          <SAmountWrapper>
            <div>
              <Autocomplete
                width='40px'
                size='small'
                height='26px'
                labelKey='label'
                options={[
                  { label: '%', value: 'percent' },
                  { label: currency, value: 'amount' },
                ]}
                value={invoice.discount_type}
                onChange={(e, val) => handleChange(`${index}.discount_type`, val)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </div>
            {invoice.discount_type?.value === 'percent' ? (
              <Input
                width='90px'
                type='number'
                name='discount_percent'
                placeholder='0.00'
                className='amount-input'
                value={invoice.discount_percent}
                onChange={(e) => {
                  handleChange(`${index}.discount_percent`, e.target.value);
                  handleChange(
                    `${index}.discount`,
                    Number((values[index].open_balance * Number(e.target.value)) / 100).toFixed(2)
                  );
                }}
                onKeyDown={blockNonPositiveNumbers}
              />
            ) : (
              <Input
                width='90px'
                type='number'
                name='discount'
                placeholder='0.00'
                className='amount-input'
                value={invoice.discount}
                onChange={(e) => {
                  handleChange(`${index}.discount`, e.target.value);
                  handleChange(
                    `${index}.discount_percent`,
                    Number((Number(e.target.value) * 100) / values[index].open_balance).toFixed(2)
                  );
                }}
                onKeyDown={blockNonPositiveNumbers}
              />
            )}
          </SAmountWrapper>
          <ErrorMessage error={touchedErrors[index]?.discount} />
        </div>
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(Number(invoice.open_balance) - Number(invoice.discount)) || '-'}
        </Typography>
      </td>
      <td>
        {invoice.credits > 0 &&
        !values.some(
          (item) => item.id !== invoice.id && item.apply_credit && item.customer.id === invoice.customer.id
        ) ? (
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              checked={invoice.apply_credit}
              onChange={(checked) => handleChange(`${index}.apply_credit`, checked)}
            />
            <InputWithIconAndText
              width='100px'
              size='small'
              bgColor={palette.gray0}
              type='number'
              name='credit_used'
              text={`out of ${currency}${formatNumber(invoice.credits)}`}
              placeholder='0.00'
              value={invoice.credit_used}
              onChange={(e) => handleChange(`${index}.credit_used`, e.target.value)}
              onKeyDown={blockNonPositiveNumbers}
              error={touchedErrors[index]?.credit_used}
              disabled={!invoice.apply_credit}
            />
          </div>
        ) : (
          '-'
        )}
      </td>
      <td>
        <InputWithIcon
          width='120px'
          size='small'
          type='number'
          name='amount_paid'
          placeholder='0.00'
          value={invoice.amount_paid}
          onChange={(e) => handleChange(`${index}.amount_paid`, e.target.value)}
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors[index]?.amount_paid}
        />
      </td>
    </STableRow>
  );
};

export default Row;
