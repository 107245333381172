import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as HosIcon } from 'assets/icons/hos.svg';
import { useNavigate } from 'react-router-dom';
import { statues } from 'pages/Hos-Logbook/CustomCollapsibleRow/CustomCollapsibleRow.data';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import styles from '../tableShipments.module.css';

const Hos = ({ driver, type }) => {
  const navigate = useNavigate();
  const { use } = useTheme();
  const target = useRef(null);
  const [isOpenTopModal, setIsOpenTopModal] = useState(false);

  const closeService = (e) => {
    e.stopPropagation();
    setIsOpenTopModal(false);
  };

  return (
    <>
      {type === 'shipment' ? (
        <div
          ref={target}
          onClick={(e) => {
            e.stopPropagation();
            driver?.driver_connected_services_hos && setIsOpenTopModal(true);
          }}
          style={
            driver?.driver_connected_services_hos?.drive_remaining
              ? {
                  textDecoration: 'underline',
                  color: use(palette.indigo500, palette.gray200),
                }
              : {}
          }
        >
          {!driver?.driver_connected_services_hos?.drive_remaining &&
          !driver?.driver_connected_services_hos?.shift_remaining &&
          !driver?.driver_connected_services_hos?.cycle_remaining_duration ? (
            '-'
          ) : (
            <>
              DR {driver?.driver_connected_services_hos?.drive_remaining || '-'}
              <br />
              SR {driver?.driver_connected_services_hos?.shift_remaining || '-'}
              <br />
              CR {driver?.driver_connected_services_hos?.cycle_remaining_duration || '-'}
            </>
          )}
        </div>
      ) : (
        <div
          ref={target}
          onClick={(e) => {
            e.stopPropagation();
            if (driver?.driver_connected_services_hos) {
              setIsOpenTopModal(true);
            }
          }}
          style={
            driver?.driver_connected_services_hos?.drive_remaining
              ? {
                  textDecoration: 'underline',
                  color: use(palette.indigo500, palette.gray200),
                }
              : {}
          }
        >
          {!driver?.driver_connected_services_hos?.drive_remaining &&
          !driver?.driver_connected_services_hos?.shift_remaining &&
          !driver?.driver_connected_services_hos?.cycle_remaining_duration ? (
            '-'
          ) : (
            <div className='d-flex align-items-center gap-2'>
              <HosIcon height={18} width={18} />
              {`${driver?.driver_connected_services_hos?.drive_remaining || '-'} / 
         ${driver?.driver_connected_services_hos?.shift_remaining || '-'} / 
         ${driver?.driver_connected_services_hos?.cycle_remaining_duration || '-'}`}
            </div>
          )}
        </div>
      )}
      <Overlay
        placement='top'
        rootClose
        show={isOpenTopModal}
        target={target?.current}
        onHide={() => setIsOpenTopModal(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            className={styles.hos_wrapper}
            style={{ ...props.style, backgroundColor: use(palette.white, palette.dark800) }}
          >
            <div className='d-flex justify-content-between'>
              <Typography variant='s1'>HOURS OF SERVICE</Typography>
              <CloseIcon onClick={closeService} />
            </div>
            <div className='d-flex justify-content-between gap-3 mt-2'>
              <Typography variant='s3'>Drive Remaining</Typography>
              <Typography variant='s3'>{driver?.driver_connected_services_hos.drive_remaining}</Typography>
            </div>
            <div className='d-flex justify-content-between gap-3 mt-2'>
              <Typography variant='s3'>Shift Remaining</Typography>
              <Typography variant='s3'>
                <span>{driver?.driver_connected_services_hos.shift_remaining}</span>
              </Typography>
            </div>
            <div className='d-flex  justify-content-between gap-3 mt-2'>
              <Typography variant='s3'>Cycle Remaining</Typography>
              <Typography variant='s3'>
                <span>{driver?.driver_connected_services_hos.cycle_remaining_duration}</span>
              </Typography>
            </div>
            <div className='d-flex justify-content-between  gap-3 mt-2'>
              <Typography variant='s3'>Time Until Break</Typography>
              <Typography variant='s3'>
                <span>{driver?.driver_connected_services_hos.time_until_break}</span>
              </Typography>
            </div>
            <div className='d-flex justify-content-between gap-3 mt-2'>
              <Typography variant='s3'>Duty Status</Typography>
              <Chip
                bgColor={statues[driver?.driver_connected_services_hos?.duty_status]?.bgColor}
                labelColor={statues[driver?.driver_connected_services_hos?.duty_status]?.color}
                label={statues[driver?.driver_connected_services_hos?.duty_status]?.name}
                fontWeight={500}
                fontSize='11px'
                style={{ textTransform: 'uppercase' }}
              />
            </div>
            <CustomButton
              type='primary'
              title='View Logs'
              className='w-100 mt-2'
              styleButton={{ height: 28, justifyContent: 'center' }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/hos-logbook/${driver.id}`);
              }}
            />
          </div>
        )}
      </Overlay>
    </>
  );
};

export default Hos;
