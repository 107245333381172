import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useFormik } from 'formik';
import './OfferVehicle.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import down from 'assets/icons/drivers/down.svg';
import headerIcon from 'assets/icons/vehicles/green.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/expand.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ShipmentIcon } from 'assets/icons/vehicles/shipment.svg';

import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import CustomModal from 'components/CustomModal/CustomModal';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import OfferVehicleMap from 'components/PlanToVehicle/OfferVehicleMap';
import styles from 'components/OpenDocumentModal/OpenDocumentModal.module.css';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import {
  deleteOffer,
  deleteSingleOffer,
  driverAccept,
  getContactsBillingCharges,
  getCostCalculation,
  getFullPrices,
  getOfferState,
  getShipmentStops,
  offerShipment,
  shipmentDispatch,
} from 'Api/Planner';
import { checkAddOnStatus } from 'Api/Equipment';
import { getMultipleShipmentsInfo } from 'Api/Shipment';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { formatAmount } from 'services/IFTAServices';
import ShipmentInfo from 'components/TablePlaner/helpers/ShipmentInfo';
import { useColumns } from 'components/OfferVehicle/OfferMultipleShipments.data';
import { SAccordion, SWrapper } from 'components/DispatchVehicle/DispatchVehicle.styles';
import { getShipmentDispatchSettings, getSMSCommunicationSettings } from 'Api/CompanySettings';
import { DateTimePicker } from 'common/Pickers';

const OfferMultipleShipments = () => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shipmentIds = searchParams.getAll('id');
  const equipmentIds = searchParams.getAll('equipment');
  const selectList = [
    { name: 'Current Location', id: 1 },
    { name: 'Previous Shipment', id: 2 },
    { name: 'Planned Shipment', id: 3 },
    { name: 'Custom Location', id: 4 },
  ];
  const { currency } = useSelector((state) => state.root);
  const [routeSelect, setRouteSelect] = useState(Array(shipmentIds.length).fill('practical'));
  const [locationSelect] = useState(Array(shipmentIds.length).fill('Current Location'));
  const [customerContactBook, setCustomerContactBook] = useState([]);
  const [previousShipment, setPreviousShipment] = useState(null);
  const [customLatLong, setCustomLatLong] = useState(Array(shipmentIds.length).fill(null));
  const [planedShipment, setPlanedShipment] = useState(null);
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(null);
  const [shipmentStops, setShipmentStops] = useState(null);
  const [offerState, setOfferState] = useState(null);
  const [canGoBack, setCanGoBack] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [fuelPrice, setFuelPrice] = useState(null);
  const [showLicence, setShowLicence] = useState(false);
  const [shipmentsInfo, setShipmentsInfo] = useState([]);
  const [expandedShipment, setExpandedShipment] = useState(null);
  const [allData, setAllData] = useState([]);
  const [smsSettings, setSmsSettings] = useState(null);
  const { index } = expandedShipment || {};

  const sendAmountFormik = useFormik({
    initialValues: {
      amount: '',
    },
  });

  const { values, handleChange, setValues } = useForm({
    initialValues: shipmentIds.map(() => ({
      immediately: true,
      in_future: false,
      future_date: null,
      after_specific_shipment_complated: false,
      before_scheduled_pickup: false,
      before_scheduled_pickup_hours: '0',
      send_trucking_updates_to_customer: false,
      send_trucking_link_to_customer: false,
      recipient_customer_contact_book_id: '',
      send_pickup_reminder_to_driver: false,
      send_pickup_reminder_to_driver_before_minutes: '30',
    })),
  });

  const {
    values: mapValues,
    handleChange: handleMapValuesChange,
    setValues: setMapValues,
  } = useForm({
    initialValues: [
      {
        route_type: 'practical',
        hwy_only: 0,
        open_borders: 0,
        haz_mat: 0,
        avoid_tolls: 0,
        show_traffic: 0,
        show_weather: 0,
        vehicle_type: 0,
      },
    ],
  });

  const getMultipleShipmentsData = async () => {
    try {
      const { data } = await getMultipleShipmentsInfo({ shipment_id: shipmentIds });
      const sortedData = shipmentIds.map((id) => data.find((item) => item.shipment_id === id));
      setShipmentsInfo(sortedData);
      setExpandedShipment({ ...sortedData[0], index: 0 });

      const initialMapValues = {
        route_type: 'practical',
        hwy_only: 0,
        open_borders: 0,
        haz_mat: 0,
        avoid_tolls: 0,
        show_traffic: 0,
        show_weather: 0,
        vehicle_type: 0,
      };

      const updatedMapValues = sortedData.map((item) => ({
        ...initialMapValues,
        open_borders: item.shipment_route?.open_borders,
        hwy_only: item.shipment_route?.highway_only,
        haz_mat: item.shipment_route?.hazmat_id,
        route_type: item.shipment_route?.route_type,
        vehicle_type: item.shipment_route?.vehicle_type,
        avoid_tolls: item.shipment_route?.avoid_tolls,
      }));
      setMapValues(updatedMapValues);

      setRouteSelect(sortedData.map((item) => item.shipment_route?.route_type));
    } catch (e) {
      // Do nothing
    }
  };

  const handleRouteSelect = (route, index) => {
    setRouteSelect((prevState) => prevState.map((item, i) => (i === index ? route : item)));
    handleMapValuesChange(`${index}.route_type`, route);
  };

  const revertOffers = async () => {
    try {
      await deleteOffer({ shipment_id: expandedShipment.shipment_id });
      setOfferState([]);
      setCanGoBack(true);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    }
  };

  const revertOfferForVehicle = async (id) => {
    try {
      await deleteSingleOffer(id, { offer_type: 'driver' });
      const { data } = await getOfferState(expandedShipment.shipment_id);
      setOfferState(data);
      if (!offerState?.length) {
        setCanGoBack(true);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const submitAmountModal = async () => {
    try {
      if (sendAmountFormik.values.amount) {
        const offer_date = moment.utc(new Date()).format('YYYY/MM/DD');
        const offer_time = moment.utc(new Date()).format('HH:mm');

        const body = selectedRows.map((item) => {
          return {
            shipment_id: item.shipmentId,
            equipment_id: item.equipment_id,
            offer_date,
            offer_time,
            offer_amount: Number(sendAmountFormik.values.amount),
            empty_miles: data.empty_miles,
          };
        });
        await offerShipment(body);

        setCanGoBack(false);
        setOpenModal(false);
        setSelectedRows([]);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    }
  };

  const onDispatch = () => {
    if (
      !values[index].immediately &&
      !values[index].in_future &&
      !values[index].before_scheduled_pickup &&
      !values[index].after_specific_shipment_complated
    ) {
      showToaster({ type: 'error', message: 'One of the dispatch options should be selected!' });
      return;
    }

    const { estimated_fuel_cost, net_profit, shipmentId, equipment_id, equipment, offer_amount } = checked;
    const params = {
      shipment_id: shipmentId,
      equipment_id,
      assign_driver_from: 1, // Need to be changed
      estimated_fuel_cost,
      net_profit,
      route_type: mapValues[index].route_type,
      hwy_only: Number(mapValues[index].hwy_only),
      open_borders: Number(mapValues[index].open_borders),
      avoid_tolls: Number(mapValues[index].avoid_tolls),
      haz_mat: Number(mapValues[index].haz_mat),
      driver1_amount: offer_amount,
      flat: 1,
      company_acceptance: 1,
      immediately: Number(values[index].immediately),
      in_future: Number(values[index].in_future),
      send_trucking_link_to_customer: Number(values[index].send_trucking_link_to_customer),
      before_scheduled_pickup: Number(values[index].before_scheduled_pickup),
      after_specific_shipment_complated: Number(values[index].after_specific_shipment_complated),
      future_date: values[index].future_date,
      before_scheduled_pickup_hours: values[index].before_scheduled_pickup_hours,
      recipient_customer_contact_book_id: values[index].recipient_customer_contact_book_id,
      send_pickup_reminder_to_driver: Number(values[index].send_pickup_reminder_to_driver),
      send_pickup_reminder_to_driver_before_minutes: Number(
        values[index].send_pickup_reminder_to_driver_before_minutes
      ),
      lane_id: shipmentsInfo[index].lane_id,
    };
    const acceptParams = {
      shipment_id: shipmentId,
      driver_id: equipment?.drivers[0]?.id,
    };
    driverAccept(acceptParams)
      .then(() => {
        shipmentDispatch(params)
          .then(() => {
            const remainedShipments = shipmentsInfo
              .map((shipment, i) => {
                return { ...shipment, index: i };
              })
              .filter((i) => i.shipment_id !== expandedShipment.shipment_id && !i.isDispatched);
            setShipmentsInfo((prevState) =>
              prevState.map((item) =>
                item.shipment_id === expandedShipment.shipment_id ? { ...item, isDispatched: true } : item
              )
            );
            if (remainedShipments.length) {
              handleExpand(remainedShipments[0], remainedShipments[0].index);
            } else {
              navigate('/shipments');
            }
          })
          .catch(() => {
            // Do nothing
          });
      })
      .catch(() => {
        // Do nothing
      });
  };

  const style = useMemo(() => {
    return {
      mapWrapper: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        height: '726px',
      },
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        borderRadius: '8px 0 0 8px',
      },
    };
  }, []);

  const getCostData = async () => {
    if (!expandedShipment || mapValues?.length !== shipmentIds.length) {
      return;
    }

    try {
      const promises = shipmentIds.map((shipmentId, index) => {
        const assign_driver_from = selectList.find((item) => item.name === locationSelect[index])?.id;
        const params = {
          shipment_id: shipmentId,
          equipments: equipmentIds,
          assign_driver_from,
          route_type: mapValues[index].route_type,
          hwy_only: mapValues[index].hwy_only ? 1 : 2,
          open_borders: mapValues[index].open_borders ? 1 : 2,
          avoid_tolls: mapValues[index].avoid_tolls ? 1 : 2,
          assign_driver_from_shipment:
            assign_driver_from === 2 && previousShipment?.shipment_id
              ? previousShipment?.shipment_id
              : assign_driver_from === 3 && planedShipment?.shipment_id
              ? planedShipment?.shipment_id
              : undefined,
          assign_driver_from_shipment_latitude:
            assign_driver_from === 4 && customLatLong[index] ? customLatLong[index]?.latitude : undefined,
          assign_driver_from_shipment_longitude:
            assign_driver_from === 4 && customLatLong[index] ? customLatLong[index]?.longitude : undefined,
        };
        return getCostCalculation(params);
      });

      const responses = await Promise.all(promises);
      const modifiedData = responses.map((item) => item.data);

      const result = modifiedData.map((innerArray, index) => {
        const shipmentId = shipmentIds[index];
        return innerArray.map((obj) => ({ ...obj, shipmentId }));
      });

      setData(result[0]);
      setAllData(result);
    } catch (e) {
      // Do nothing
    }
  };

  const getSentOffersData = async () => {
    try {
      const { data } = await getOfferState(expandedShipment.shipment_id);
      setOfferState(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCostData();
  }, [mapValues]);

  useEffect(() => {
    if (!expandedShipment) {
      return;
    }
    getSentOffersData();

    const intervalId = setInterval(() => {
      getSentOffersData();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [expandedShipment]);

  useEffect(() => {
    if (expandedShipment) {
      getShipmentStops(expandedShipment?.shipment_id).then((res) => {
        setShipmentStops(res.data);
      });
    }
  }, [expandedShipment]);

  useEffect(() => {
    getFullPriceShipment();
  }, [data]);

  function onBackPlanner() {
    if (!canGoBack) {
      showToaster({ type: 'warning', message: 'You must revert offers in order to go back!' });
    } else {
      navigate(`/planner/plan?${searchParams.toString()}`);
    }
  }

  useEffect(() => {
    if (shipmentStops?.shipment_billing[0]?.billing_customer?.id) {
      getContactsBillingCharges(shipmentStops?.shipment_billing[0]?.billing_customer?.id).then((res) => {
        setCustomerContactBook(res.data);
      });
    }
  }, [shipmentStops?.shipment_billing[0]?.billing_customer?.id]);

  const offer = (data, value) => {
    const offer_date = moment.utc(new Date()).format('YYYY/MM/DD');
    const offer_time = moment.utc(new Date()).format('HH:mm');
    const params = {
      shipment_id: data.shipmentId,
      equipment_id: data.equipment_id,
      offer_date,
      offer_time,
      offer_amount: value,
      empty_miles: data.empty_miles,
    };

    offerShipment([params])
      .then(() => {
        setCanGoBack(false);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getFullPriceShipment = () => {
    return getFullPrices().then((res) => {
      if (res && res?.data) {
        setFuelPrice(res.data?.diesel?.price);
      }
      return res;
    });
  };

  const checkAddonStatus = (value, checked, index) => {
    checkAddOnStatus(2, data[index]?.equipment?.id)
      .then((res) => {
        if (res.is_addon_active) {
          handleMapValuesChange(`${index}.${value}`, checked);
        } else {
          setShowLicence(true);
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong!' });
      });
  };

  const setOneEquipmentData = (newData, previousShipment, planedShipment, customLatLong, index) => {
    // const updatedData = data.map((obj) => {
    //   if (obj.equipment_id === newData.equipment_id) {
    //     return newData;
    //   }
    //   return obj;
    // });
    // setData(updatedData);
    setPreviousShipment(previousShipment);
    setPlanedShipment(planedShipment);
    setCustomLatLong((prevState) => prevState.map((item, i) => (i === index ? customLatLong : item)));
  };

  const handleExpand = (shipment, index) => {
    setExpandedShipment({ ...shipment, index });
    setData(allData[index]);
    setSelectedRows([]);
    setChecked(null);
  };

  const getSmsSettings = async () => {
    try {
      const { data } = await getSMSCommunicationSettings();
      setSmsSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDispatchSettings = async () => {
    try {
      const { data } = await getShipmentDispatchSettings();
      if (data) {
        setValues((prevState) =>
          prevState.map((item) => ({
            ...item,
            immediately: !!data.immediately,
            after_specific_shipment_complated: !!data.dispatch_next_in_line,
            before_scheduled_pickup: !!data.dispatch_driver_hours_before_scheduled,
            before_scheduled_pickup_hours: data.dispatch_driver_hours_before_scheduled || '0',
            send_trucking_link_to_customer: !!data.send_tracking_updates,
            send_pickup_reminder_to_driver: !!data.send_driver_reminder,
            send_pickup_reminder_to_driver_before_minutes: data.send_driver_reminder,
          }))
        );
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getMultipleShipmentsData();
    getSmsSettings();
    getDispatchSettings();
  }, []);

  const columns = useColumns({
    offerState,
    selectedRows,
    setSelectedRows,
    data,
    setOpenModal,
    mapValues,
    offer,
    setOneEquipmentData,
    revertOfferForVehicle,
    index,
  });

  return (
    <div className='profile-container'>
      {showLicence && (
        <div className='check-addon-dispatch' onClick={() => setShowLicence(false)}>
          You do not have this feature enabled.
          <span onClick={() => navigate('/billing?addonId=2&planId=34')}> Purchase Addon </span>
        </div>
      )}
      <div className='user-profile-header w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='dispatch-vehicle-container w-100'>
          <div className='plan-vehicle-text-wrapper'>
            <div onClick={onBackPlanner} className='back_plan_to_vehicle'>
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className='back'>
                Plan to Nearby Vehicle
              </Typography>
            </div>
          </div>
          {shipmentStops?.is_edi_api || shipmentStops?.manual_edi_api ? (
            <div className='dispatch-vehicle-text-wrapper'>
              <div className='d-flex'>
                <img src={headerIcon} alt='headerIcon' />
                <p className='dispatch-vehicle-text green-text'>EDI/API SHIPMENT </p>
              </div>
            </div>
          ) : null}

          <div className='d-flex '>
            <div className='dispatch-vehicle-details w-100'>
              <div className='dispatch-vehicle-details-item border-0'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Multi-Shipment Offer
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SWrapper>
        {!!shipmentsInfo.length &&
          shipmentsInfo.map((shipment, index) => (
            <SAccordion
              key={shipment.id}
              expanded={expandedShipment?.shipment_id === shipment?.shipment_id}
              onChange={() => {
                if (shipment?.isDispatched) {
                  return;
                }
                handleExpand(shipment, index);
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowUp width={16} className='accordion-arrow' />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <div className='d-flex align-items-center gap-3'>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    Shipment {shipment?.shipment_id}
                  </Typography>
                  {shipment?.isDispatched && (
                    <Chip label='Dispatched' bgColor={palette.green0} labelColor={palette.green500} />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <ShipmentInfo shipment={shipment} />
                </div>
              </AccordionDetails>
            </SAccordion>
          ))}
      </SWrapper>

      <div className='w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='table-wrap'>
          <div className='sub-table-container-dispatch'>
            {!!expandedShipment && !!allData[index] ? (
              <MaterialTableWrapper
                key={data?.[0]?.driver1_cost}
                columns={columns}
                onRowClick={(event, row) => {
                  const accepted = offerState?.find((item) => item?.equipment_id === row.equipment_id);
                  const canSelect = accepted?.driver_acceptance === 1;
                  if (canSelect) {
                    const dispatchData = {
                      ...row,
                      offer_amount: accepted.offer_amount,
                    };
                    setChecked(dispatchData);
                  } else {
                    setChecked(null);
                  }
                }}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  borderTop: 'none',
                  width: '100%',
                  minHeight: 200,
                }}
                data={data}
                options={{
                  paging: false,
                  sorting: false,
                  toolbar: false,
                  rowStyle: (rowData) => {
                    const decliends = offerState?.filter((item) => item.driver_acceptance === 2);
                    const selected = checked?.equipment_id === rowData?.equipment_id;
                    return {
                      backgroundColor: decliends?.some((item) => item?.equipment_id === rowData?.equipment_id)
                        ? 'rgb(248,229,235)'
                        : selected
                        ? palette.green200
                        : '',
                      borderBottom: 'none',
                      width: '100%',
                    };
                  },

                  headerStyle: {
                    borderTop: 'none',
                    width: '100%',
                  },
                }}
              />
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      </div>
      {!!expandedShipment && !!allData[index] && (
        <div className='d-flex justify-content-end mt-4'>
          <CustomButton
            width={12}
            height={12}
            disabled={!offerState?.length}
            type='secondary'
            title='Revert Offers'
            styleButton={{ padding: '6px 12px', marginRight: '18px' }}
            onClick={revertOffers}
          />
          <CustomButton
            width={12}
            height={12}
            type='primary'
            disabled={!checked}
            title='Dispatch Vehicle'
            leftIcon={<ShipmentIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px' }}
            onClick={onDispatch}
          />
        </div>
      )}
      {!!expandedShipment && !!allData[index] && (
        <div className='dispatch-vehicle-content'>
          <div className='dispatch-vehicle-data'>
            <div>
              <form>
                <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                  Options
                </h6>
                <div className='with-border mt-2' />
                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].immediately}
                    name='immediately'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.in_future`, false);
                        handleChange(`${index}.after_specific_shipment_complated`, false);
                        handleChange(`${index}.before_scheduled_pickup`, false);
                      }
                      handleChange(`${index}.immediately`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text'>Dispatch to Driver Immediately</p>
                </div>
                <div className='with-border mt-2' />

                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].after_specific_shipment_complated}
                    name='after_specific_shipment_complated'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.immediately`, false);
                        handleChange(`${index}.in_future`, false);
                        handleChange(`${index}.before_scheduled_pickup`, false);
                      }
                      handleChange(`${index}.after_specific_shipment_complated`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text me-2'>
                    Pending Dispatch as Next Shipment in Sequential Date Order
                  </p>
                </div>
                <div className='with-border mt-2' />

                <div className='d-flex align-items-center my-2 w-100'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].in_future}
                    name='in_future'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.immediately`, false);
                        handleChange(`${index}.after_specific_shipment_complated`, false);
                        handleChange(`${index}.before_scheduled_pickup`, false);
                      }
                      handleChange(`${index}.in_future`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text me-2'>Pending Dispatch Queue for Dispatch on</p>
                  <DateTimePicker
                    width='190px'
                    name='future_date'
                    value={values[index].future_date}
                    onChange={(value) => handleChange(`${index}.future_date`, value)}
                    onBlur={(e) => {
                      const { value } = e.target;
                      if (value && moment(value).isValid() && moment(value).isBefore(moment())) {
                        handleChange(`${index}.future_date`, new Date());
                      }
                    }}
                    disablePast
                  />
                  <p className='dispatch-vehicle-check-text'>(Based on Specific Date/Time)</p>
                </div>
                <div className='with-border mt-2' />

                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].before_scheduled_pickup}
                    name='before_scheduled_pickup'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.immediately`, false);
                        handleChange(`${index}.in_future`, false);
                        handleChange(`${index}.after_specific_shipment_complated`, false);
                      }
                      handleChange(`${index}.before_scheduled_pickup`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text'>Pending Dispatch Queue for Dispatch</p>
                  <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                    <input
                      type='number'
                      placeholder='0'
                      name='before_scheduled_pickup_hours'
                      value={values[index].before_scheduled_pickup_hours}
                      onChange={(e) => handleChange(`${index}.before_scheduled_pickup_hours`, e.target.value)}
                    />
                  </div>
                  <p className='dispatch-vehicle-check-text'>Hour(s) Prior to Scheduled Pickup</p>
                </div>
                <div className='with-border mt-2' />
                {customerContactBook && (
                  <>
                    <div className='d-flex align-items-center my-2'>
                      <CustomCheckbox
                        type='switch'
                        checked={values[index].send_trucking_link_to_customer}
                        name='send_trucking_link_to_customer'
                        onChange={(checked) => handleChange(`${index}.send_trucking_link_to_customer`, checked)}
                      />
                      <p className='dispatch-vehicle-check-text'>Send Tracking Updates to Customer Contact</p>
                      <CustomSelect
                        options={customerContactBook}
                        defaultValue='Select Contact...'
                        onChange={(value) => handleChange(`${index}.recipient_customer_contact_book_id`, value)}
                        styles={{ maxWidth: 200, marginLeft: '0.5rem', marginTop: 0 }}
                        id='select_list'
                        name='contact_name'
                        propertyName='contact_name'
                        patchProperty='id'
                      />
                    </div>
                    <div className='with-border mt-2' />
                  </>
                )}
                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].send_pickup_reminder_to_driver}
                    name={`${index}.send_pickup_reminder_to_driver`}
                    onChange={(checked) => {
                      if (!smsSettings?.send_sms) {
                        showToaster({
                          type: 'error',
                          message:
                            'Send SMS option is disabled for this account, please enable it from settings before using this option!',
                        });
                        return;
                      }
                      handleChange(`${index}.send_pickup_reminder_to_driver`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text'>Send Driver SMS Reminder</p>
                  <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                    <input
                      type='number'
                      placeholder='0'
                      name='send_pickup_reminder_to_driver_before_minutes'
                      value={values[index].send_pickup_reminder_to_driver_before_minutes}
                      onChange={(e) =>
                        handleChange(`${index}.send_pickup_reminder_to_driver_before_minutes`, e.target.value)
                      }
                    />
                  </div>
                  <p className='dispatch-vehicle-check-text'>Minute(s) Prior to Scheduled Pickup</p>
                </div>
              </form>
              {checked && (
                <>
                  <div>
                    <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                      Cost Estimate
                    </h6>
                    <div className='with-border mt-2' />
                    <div className='dispatch-vehicle-cost'>
                      <div>
                        <p className='dispatch-vehicle-cost-text'>Driver Costs</p>
                        <p className='dispatch-vehicle-cost-text'>
                          Estimated Fuel Costs{' '}
                          <span className='cost-purple-text'> ${(+fuelPrice).toFixed(2)} /GAL DIESEL</span>
                        </p>
                        <p className='dispatch-vehicle-cost-text cost-bold-text'>Estimated Costs</p>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <p className='dispatch-vehicle-cost-text'>
                          {currency}
                          {formatAmount(checked?.driver1_cost)}
                        </p>
                        <p className='dispatch-vehicle-cost-text'>
                          {currency}
                          {formatAmount(checked?.estimated_fuel_cost)}
                        </p>
                        <p className='dispatch-vehicle-cost-text cost-bold-text'>
                          {currency}
                          {formatAmount(checked?.cost)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='with-border mt-2' />
                  <div>
                    <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                      Net Profit
                    </h6>
                    <div className='with-border mt-2' />
                    <div className='dispatch-vehicle-cost'>
                      <div>
                        <p className='dispatch-vehicle-cost-text'>
                          Gross Shipment Charges{' '}
                          <span className='profit-gray-text'>
                            {' '}
                            $
                            {formatAmount(
                              Number(checked?.total_amount || 0) /
                                (Number(Number(checked?.empty_miles || 0).toFixed()) +
                                  Number(Number(checked?.loaded_miles || 0).toFixed()))
                            )}{' '}
                            /MILE EST. GROSS
                          </span>
                        </p>
                        <p className='dispatch-vehicle-cost-text'>
                          Estimated Shipment Costs{' '}
                          <span className='profit-red-text'>
                            $
                            {formatAmount(
                              Number(checked?.cost || 0) /
                                (Number(Number(checked?.empty_miles || 0).toFixed()) +
                                  Number(Number(checked?.loaded_miles || 0).toFixed()))
                            )}{' '}
                            /MILE EST. COST
                          </span>
                        </p>
                        <p className='dispatch-vehicle-cost-text cost-green-text'>
                          Estimated Net Profit{' '}
                          <span className='profit-green-text'>
                            $
                            {formatAmount(
                              Number(checked?.net_profit) /
                                (Number(Number(checked?.empty_miles || 0).toFixed()) +
                                  Number(Number(checked?.loaded_miles || 0).toFixed()))
                            )}{' '}
                            /MILE EST. NET
                          </span>
                        </p>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <p className='dispatch-vehicle-cost-text'>${formatAmount(checked?.total_amount)}</p>
                        <p className='dispatch-vehicle-cost-text'>${formatAmount(checked?.cost)}</p>
                        <p className='dispatch-vehicle-cost-text cost-green-text'>
                          ${formatAmount(checked?.net_profit)}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='dispatch-vehicle-map'>
            <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
              Route
            </h6>
            <div className='with-border mt-2' />
            <div className='d-flex justify-content-between align-items-center my-2'>
              <div className='d-flex align-items-center'>
                <p className='dispatch-vehicle-check-text' style={{ marginLeft: 0, textTransform: 'capitalize' }}>
                  {routeSelect[index]}
                </p>
                <Dropdown className='d-inline mx-2' autoClose='outside'>
                  <Dropdown.Toggle
                    id='dropdown-autoclose-outside'
                    style={{
                      border: 'none',
                      outline: 'none',
                      background: 'none',
                      boxShadow: 'none',
                      marginLeft: '0',
                      paddingLeft: '10x',
                    }}
                  >
                    <img src={down} alt='down' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => handleRouteSelect('practical', index)}
                    >
                      Practical
                    </Dropdown.Item>

                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={() => handleRouteSelect('shortest', index)}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Shortest
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={() => handleRouteSelect('fastest', index)}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Fastest
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <div className='d-flex align-items-center mb-1'>
                  <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem', marginLeft: 0 }}>
                    Open Borders
                  </p>
                  <CustomCheckbox
                    checked={!!mapValues[index].open_borders}
                    onChange={(checked) => handleMapValuesChange(`${index}.open_borders`, checked)}
                  />
                  <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem' }}>
                    Highway Only
                  </p>
                  <CustomCheckbox
                    checked={!!mapValues[index].hwy_only}
                    onChange={(checked) => handleMapValuesChange(`${index}.hwy_only`, checked)}
                  />
                  <p className='dispatch-vehicle-check-text'>HazMat</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].haz_mat}
                    disabled
                    onChange={(checked) => handleMapValuesChange(`${index}.haz_mat`, checked)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                  <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].avoid_tolls}
                    onChange={(checked) => handleMapValuesChange(`${index}.avoid_tolls`, checked)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                  <p className='dispatch-vehicle-check-text'>Traffic</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].show_traffic}
                    onChange={(checked) => checkAddonStatus('show_traffic', checked, index)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                  <p className='dispatch-vehicle-check-text'>Weather</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].show_weather}
                    onChange={(checked) => checkAddonStatus('show_weather', checked, index)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                </div>
              </div>
            </div>
            <div style={style.mapWrapper}>
              {!!shipmentStops && !!expandedShipment && (
                <OfferVehicleMap
                  key={expandedShipment?.id}
                  data={shipmentStops.shipment_stops}
                  selectedDrivers={data[index]?.data}
                  mapValues={mapValues[index]}
                  previousShipment={previousShipment}
                  planedShipment={planedShipment}
                  customLatLong={customLatLong[index]}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <CustomModal
        showModal={openModal}
        styleBody={{ width: 446, backgroundColor: '#F7F9FC' }}
        onHide={() => setOpenModal(false)}
        headerTitle='Send Offer to All'
        customBody={{
          background: use(palette.white, palette.gray900),
          paddingTop: 0,
          paddingBottom: '10px',
        }}
        styleButtons={{ padding: '8px 12px' }}
        buttons={[
          { key: 'close', type: 'secondary', title: 'Cancel' },
          { key: 'submit', type: 'primary', title: 'Send', onClick: () => submitAmountModal(index) },
        ]}
      >
        <div className={styles['document-container']}>
          <div>
            <label className={styles.label_wrapper}>
              <span className={styles.label_required}>Amount</span>
              <input
                type='text'
                name='amount'
                id='amount'
                className={styles['reference-field']}
                onBlur={sendAmountFormik.handleBlur}
                onChange={sendAmountFormik.handleChange}
                value={sendAmountFormik.values.amount}
              />
            </label>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default OfferMultipleShipments;
