import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CircularArrows } from 'assets/icons/circle-arrows.svg';
import { Typography } from 'components/Typography';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';

export const useColumns = () => {
  const navigate = useNavigate();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'account_name',
      render: (row) => (
        <div className='d-flex gap-2'>
          <Typography variant='s2'>
            {row.account_name}
            {row.account_type?.id === ACCOUNT_TYPE.BANK &&
              (!!row.is_connected || row.account_bank_name === 'WEX EFS LLC.') && (
                <CircularArrows
                  fill={row.reconcile_count === 0 ? palette.green400 : palette.orange300}
                  className='ms-1 mb-1'
                />
              )}
          </Typography>
          {row.account_type?.id === ACCOUNT_TYPE.BANK &&
            (!!row.is_connected || row.account_bank_name === 'WEX EFS LLC.') && (
              <Typography
                variant='c3'
                style={{
                  cursor: 'pointer',
                  backgroundColor: row.reconcile_count === 0 ? palette.green0 : palette.goldLight,
                  padding: '2px 6px',
                  color: row.reconcile_count === 0 ? palette.green400 : palette.goldDark,
                  borderRadius: '6px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/accounting/accounts/${row.id}/bank-feed`);
                }}
              >
                {row?.reconcile_count}
              </Typography>
            )}
        </div>
      ),
    },
    {
      field: 'account_balance',
      render: (row) => (
        <Typography as='div' variant='s2' style={{ textAlign: 'end' }}>
          {row.account_balance < 0 && '-'}
          {currency}
          {formatNumber(Math.abs(row.account_balance))}
        </Typography>
      ),
    },
  ];
};
