import jsPDF from 'jspdf';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';

const createFooter = (doc) => {
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

export const generateSeparateReport = (drugAlcoholTest, companyProfile, formatDate) => {
  const { address1, address2, city_id, state_id, zip, company_logo, company_name, phone_number } = companyProfile || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.addImage(`${company_logo}?cache=${Math.random()}`, 'JPEG', pageWidth / 2 - 30, 8, 60, 12, 'Logo', 'FAST');

  // Company Info
  doc.setFontSize(9);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);
  doc.text(`${company_name}`, pageWidth / 2, 26, { align: 'center' });
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, pageWidth / 2, 30, { align: 'center' });
  doc.text(`${city_id?.name}, ${state_id?.short_name} ${zip}`, pageWidth / 2, 34, { align: 'center' });
  doc.text(`${phone}`, pageWidth / 2, 38, { align: 'center' });

  doc.setFontSize(14);
  doc.text('Drug and Alcohol Report', pageWidth / 2, 54, { align: 'center' });

  // Driver Name
  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Driver Name', pageWidth / 2, 74, { align: 'center' });

  doc.setDrawColor(0, 0, 0);
  doc.line(pageWidth / 2 - 12, 75, pageWidth / 2 + 12, 75);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${drugAlcoholTest.driver?.full_name || '-'}`, pageWidth / 2, 80, { align: 'center' });

  // Check Date
  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Check Date', pageWidth / 2, 94, { align: 'center' });

  doc.setDrawColor(0, 0, 0);
  doc.line(pageWidth / 2 - 12, 95, pageWidth / 2 + 12, 95);

  doc.setFont('Inter', 'normal', 400);
  doc.text(
    `${drugAlcoholTest.drug_checked_date ? formatDate(drugAlcoholTest.drug_checked_date) : '-'}`,
    pageWidth / 2,
    100,
    { align: 'center' }
  );

  // Results
  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Results', pageWidth / 2, 114, { align: 'center' });

  doc.setDrawColor(0, 0, 0);
  doc.line(pageWidth / 2 - 12, 115, pageWidth / 2 + 12, 115);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${drugAlcoholTest.drug_check_results || '-'}`, pageWidth / 2, 120, { align: 'center' });

  // Type
  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Type', pageWidth / 2, 134, { align: 'center' });

  doc.setDrawColor(0, 0, 0);
  doc.line(pageWidth / 2 - 12, 135, pageWidth / 2 + 12, 135);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${drugAlcoholTest.type || '-'}`, pageWidth / 2, 140, { align: 'center' });

  // Reason
  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Reason', pageWidth / 2, 154, { align: 'center' });

  doc.setDrawColor(0, 0, 0);
  doc.line(pageWidth / 2 - 12, 155, pageWidth / 2 + 12, 155);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${drugAlcoholTest.reason || '-'}`, pageWidth / 2, 160, { align: 'center' });

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    doc.setFontSize(12);
    createFooter(doc);
  }

  doc.setDocumentProperties({
    title: `Drug and Alcohol Report`,
  });

  const url = doc.output('datauristring', { filename: 'Drug and Alcohol Report' });
  const blob = doc.output('blob');

  return { blob, url };
};
