import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import Origin from './components/Origin';
import ShipmentId from './components/ShipmentId';
import Destination from './components/Destination';
import NoRecords from '../../components/NoRecords';
import DeclineReason from '../../components/DeclineReason';
import DisputedActions from '../../../shared/DisputedActions';
import { STable, SWrapper } from '../Tables.styles';

const BookedShipments = ({ shipments, updateShipments, settlement }) => {
  const { use } = useTheme();
  const { formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [shipmentToDecline, setShipmentToDecline] = useState(null);

  const onDecline = async (reason) => {
    const newShipments = shipments.map((item) =>
      item.shipment_id === shipmentToDecline.shipment_id
        ? { ...item, dispute_declined: true, dispute_declined_reason: reason }
        : item
    );
    updateShipments(newShipments);
    setShipmentToDecline(null);
  };

  const onApprove = (shipment) => {
    const newShipments = shipments.map((item) =>
      item.shipment_id === shipment.shipment_id ? { ...item, dispute_approved: true } : item
    );
    updateShipments(newShipments);
  };

  return (
    <SWrapper>
      <Typography variant='h3'>Shipments</Typography>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>SHIPMENT ID</th>
            <th>ORIGIN</th>
            <th>DESTINATION</th>
            <th>COMPLETED DATE</th>
            <th>STOPS</th>
            <th>LOADED MILES</th>
            <th>EMPTY MILES</th>
            <th>AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {shipments.map((item) => (
            <Fragment key={item.shipment_id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
              >
                <td>
                  <ShipmentId data={item} />
                </td>
                <td>
                  <Origin data={item} />
                </td>
                <td>
                  <Destination data={item} />
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {item?.shipment_stops?.length
                      ? formatDateTime(
                          item.tonu_date_time || item.shipment_stops[item.shipment_stops.length - 1].departure_date
                        )
                      : 'N/A'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {item?.stops_count || 0}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {Math.round(item.loaded_miles || 0)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {Math.round(item.empty_miles || 0)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr className={`disputed-actions ${item.dispute_approved ? 'dispute-approved-actions' : ''}`}>
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setShipmentToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </STable>
      {!shipments.length && <NoRecords />}
      <DeclineReason open={!!shipmentToDecline} onClose={() => setShipmentToDecline(null)} onSubmit={onDecline} />
    </SWrapper>
  );
};

export default BookedShipments;
