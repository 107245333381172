import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as MinimizeIcon } from 'assets/icons/minus.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/download2.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/drivers/dollar.svg';
import { ReactComponent as CircularArrows } from 'assets/icons/circle-arrows.svg';

import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { ACCOUNT_TYPE, palette, SYSTEM_ACCOUNT_TYPE } from 'utils/constants';
import { addMinimizedNotes } from 'store/reducers/root.reducer';
import AccountActions from 'pages/Accounting/Accounts/RightSection/components/AccountActions';
import { importableAccounts } from 'pages/Accounting/Accounts/Accounts.data';
import { SHeaderWrapper } from 'pages/Accounting/Accounts/Accounts.styles';

const AccountHeader = ({
  account,
  onEdit,
  onUpdateStatus,
  onImportCsv,
  onExport,
  dateRange,
  onWithdraw,
  onDeposit,
  onTransfer,
  onConnectBankFeed,
  onDisconnectBankFeed,
  csvDisabled,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id,
    account_name,
    account_bank_name,
    account_type,
    is_connected,
    is_truckindigital,
    reconcile_count,
    system_account_type,
  } = account || {};

  const startDate = dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : null;
  const endDate = dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : null;

  const onMinimize = (e) => {
    e.stopPropagation();
    const styles = { width: 'fit-content', maxWidth: 'unset' };
    dispatch(addMinimizedNotes({ label: 'accounting-account', id: account.id, name: account.account_name, styles }));
  };

  return (
    <SHeaderWrapper>
      <div className='d-flex gap-4 align-items-center'>
        <Typography variant='h3'>{account_name}</Typography>
        {(!!is_connected || account_bank_name === 'WEX EFS LLC.') && !!reconcile_count && (
          <Chip
            onClick={() => navigate(`/accounting/accounts/${account.id}/bank-feed`)}
            label={`RECONCILE ${reconcile_count} ITEMS`}
            labelColor={palette.goldDark}
            bgColor={palette.goldLight}
            fontSize='13px'
            disableRipple
          />
        )}
      </div>
      <div className='right-section'>
        {!!is_truckindigital && (
          <>
            {system_account_type === SYSTEM_ACCOUNT_TYPE.TRUCKIN_DIGITAL_TREASURY && (
              <CustomButton
                title='Deposit'
                type='secondary'
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ padding: '6px 12px', margin: 0 }}
                onClick={onDeposit}
              />
            )}
            {system_account_type === SYSTEM_ACCOUNT_TYPE.TRUCKIN_DIGITAL_CASH_CARD && (
              <CustomButton
                title='Transfer'
                type='secondary'
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ padding: '6px 12px', margin: 0 }}
                onClick={onTransfer}
              />
            )}
            <CustomButton
              title='Withdraw'
              type='secondary'
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              leftIcon={<DollarIcon fill={palette.gray700} className='me-2' />}
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={onWithdraw}
            />
          </>
        )}
        <CustomButton
          type='secondary'
          title='Print Report'
          leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
          styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          onClick={() =>
            navigate(
              `/reports/25?account_id=${id}${startDate ? `&startDate=${startDate}` : ''}${
                endDate ? `&endDate=${endDate}` : ''
              }`
            )
          }
        />
        {(!!is_connected || account_bank_name === 'WEX EFS LLC.') && (
          <div className='position-relative'>
            <CustomButton
              title={`${account.account_type?.account_type || 'Bank'} Feed`}
              type='secondary'
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              leftIcon={<CircularArrows fill={palette.gray700} className='me-2' />}
              styleButton={{ padding: '6px 12px', margin: 0, whiteSpace: 'nowrap' }}
              onClick={() => navigate(`/accounting/accounts/${account.id}/bank-feed`)}
            />
            <span className={`reconcile-badge ${!reconcile_count && 'no-transactions'}`}>{reconcile_count}</span>
          </div>
        )}
        {(account_type?.id === ACCOUNT_TYPE.BANK || importableAccounts.includes(id)) && (
          <Tooltip title='Import from CSV'>
            <CustomButton
              type='secondary'
              leftIcon={<ImportIcon fill={palette.gray700} />}
              styleButton={{ padding: '9px 12px', margin: 0 }}
              onClick={onImportCsv}
            />
          </Tooltip>
        )}
        <Tooltip title='Export CSV'>
          <span>
            <CustomButton
              type='secondary'
              leftIcon={<ExportIcon fill={palette.gray700} />}
              styleButton={{ padding: '9px 12px', margin: 0 }}
              onClick={onExport}
              disabled={csvDisabled}
            />
          </span>
        </Tooltip>
        <AccountActions
          account={account}
          onEdit={onEdit}
          onUpdateStatus={onUpdateStatus}
          onConnectBankFeed={onConnectBankFeed}
          onDisconnectBankFeed={onDisconnectBankFeed}
        />
        <Tooltip title='Minimize'>
          <span className='pointer' onClick={onMinimize}>
            <MinimizeIcon fill={palette.gray700} />
          </span>
        </Tooltip>
      </div>
    </SHeaderWrapper>
  );
};

export default AccountHeader;
