import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ModalBody } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import authHeader from 'services/auth-header';
import useShowToaster from 'hooks/useShowToaster';
import './equipmentconnectedservices.css';

const ConnectModal = ({
  handleClose = () => null,
  profileData,
  getConnectSuccess = () => null,
  ConnectsuccessShow = () => null,
  ConnectErrorModal = () => null,
  ConnectionErrorModal = () => null,
  setSerialId,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const ValidationSchema = Yup.object().shape({
    serial_id: Yup.string().required('Required'),
  });
  const formik = useFormik({
    initialValues: {
      serial_id: '',
      equipment_id: profileData?.id,
      //  hardware_model:"",
      type: 'Vehicle',
      vehicle_or_trailer_id: '1',
      vin: profileData?.vin,
      hardware_model: 'VG54',
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await axios.post(`${API_URL}/connected-services/vehicle`, values, { headers: authHeader() }).then((res) => {
          if (res?.data?.error) {
            handleClose();
            ConnectErrorModal();
          } else {
            handleClose();
            getConnectSuccess(res);
            ConnectsuccessShow();
            setSerialId(values.serial_id);
            showToaster({ type: 'success', message: 'Successfully connected!' });
          }

          setLoading(false);
        });
      } catch (err) {
        setLoading(false);
        ConnectionErrorModal();
      }
    },
  });

  return (
    <div className='equipment-connected-services'>
      <ModalBody className='p-0'>
        <form onSubmit={formik.handleSubmit}>
          <div className='add-driver-modal-header'>
            <h6 className='add-driver-modal-text mt-1 ms-3'>Connect Service</h6>
          </div>
          <div className='add-drivers-modal-body'>
            <p className='drivers-modal-text'>Serial ID *</p>
            <input
              type='text'
              name='serial_id'
              value={formik.values.serial_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className='service-api-key-input'
            />
            {formik.errors.serial_id && formik.touched.serial_id ? (
              <div className='drivers-modal-text' style={{ color: '#D12953', fontSize: '12px', marginTop: '6px' }}>
                {formik.errors.serial_id}{' '}
              </div>
            ) : null}
          </div>
          <div className='connect-service-modal-footer mt-2 d-flex justify-content-end'>
            <button type='button' className='add-driver-modal-cancel-button' onClick={handleClose}>
              Cancel
            </button>
            {loading ? (
              <CircularProgress
                style={{
                  width: '19px',
                  height: '19px',
                  margin: '10px',
                }}
              />
            ) : (
              <button type='submit' className='add-driver-modal-add-driver-button'>
                Connect
              </button>
            )}
          </div>
        </form>
      </ModalBody>
    </div>
  );
};

export default ConnectModal;
