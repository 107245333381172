import React, { useEffect, useState } from 'react';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { ReactComponent as Up } from 'assets/icons/arrow-up.svg';
import { palette } from 'utils/constants';
import { getGraphData } from 'Api/DotMonitoring';
import StatBox from './components/StatBox';
import DriverInspections from './components/DriverInspections';
import VehicleInspections from './components/VehicleInspections';
import { initialStats, statsDataConverter } from './StatsSection.data';
import { SWrapper } from './StatsSection.styles';

const StatsSection = () => {
  const [stats, setStats] = useState(initialStats);
  const [loading, setLoading] = useState(false);
  const [driverInspectionsYear, setDriverInspectionsYear] = useState('All');
  const [vehicleInspectionsYear, setVehicleInspectionsYear] = useState('All');

  const truncateToTwoDecimals = (num) => {
    const multiplier = 100;
    return Math.floor(num * multiplier) / multiplier;
  };

  const getCarrierStats = async () => {
    setLoading(true);
    try {
      const params = {
        year_driver: driverInspectionsYear === 'All' ? null : driverInspectionsYear,
        year_vehicle: vehicleInspectionsYear === 'All' ? null : vehicleInspectionsYear,
      };
      const { data } = await getGraphData(params);
      const convertedData = statsDataConverter(data);
      setStats(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    try {
      const params = {};
      const { data } = await getGraphData(params);
      if (!data?.graphs?.inspections?.length && !data?.graphs?.vehicles?.length) {
        setDriverInspectionsYear('All');
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCarrierStats();
  }, [driverInspectionsYear, vehicleInspectionsYear]);

  const onVehicleInspectionYearChange = (val) => {
    setVehicleInspectionsYear(val);
  };

  const onDriverInspectionYearChange = (val) => {
    setDriverInspectionsYear(val);
  };

  return (
    <SWrapper>
      <div className='scrollable-container'>
        <div className='chart-boxes-wrapper'>
          <DriverInspections
            inspections={stats.driverInspections}
            driverInspectionsYear={driverInspectionsYear}
            onDriverInspectionYearChange={onDriverInspectionYearChange}
          />

          <VehicleInspections
            inspections={stats.vehicleInspections}
            vehicleInspectionsYear={vehicleInspectionsYear}
            onVehicleInspectionYearChange={onVehicleInspectionYearChange}
          />
        </div>
        <div className='stat-boxes-wrapper'>
          {Object.keys(stats.statsSection || {})?.map((key, index) => (
            <StatBox key={key} title={key} index={index} data={stats.statsSection[key]} loading={loading} />
          ))}
        </div>
      </div>
      <div className='w-100'>
        <div
          className='d-flex align-items-center gap-3'
          style={{ width: '80%', borderRight: ' 1px solid #E9EDF5', padding: 16 }}
        >
          {stats?.dot_stats && (
            <div className='d-flex justify-content-between w-100'>
              <div className='w-50 d-flex align-items-center justify-content-around'>
                <div className='d-flex align-items-baseline gap-1 flex-column'>
                  <Typography variant='s2'>NATIONAL AVERAGE</Typography>
                  <Typography variant='h5'>{stats?.dot_stats?.us_driver_national_average}%</Typography>
                </div>
                <div className='d-flex align-items-baseline gap-1 flex-column'>
                  <Typography variant='s2'>YOUR AVERAGE</Typography>
                  <Typography variant='h5'>
                    {truncateToTwoDecimals(stats?.dot_stats?.us_driver_out_of_service_percent)}%
                    {Number(stats?.dot_stats?.us_driver_out_of_service_percent) -
                      Number(stats?.dot_stats?.us_driver_national_average) <
                    0 ? (
                      <Up fill={palette.green400} style={{ transform: 'rotate(180deg)' }} />
                    ) : (
                      <Up fill={palette.red400} />
                    )}
                    <Chip
                      bgColor={
                        Number(stats?.dot_stats?.us_driver_out_of_service_percent) -
                          Number(stats?.dot_stats?.us_driver_national_average) <
                        0
                          ? palette.green0
                          : palette.red0
                      }
                      labelColor={
                        Number(stats?.dot_stats?.us_driver_out_of_service_percent) -
                          Number(stats?.dot_stats?.us_driver_national_average) <
                        0
                          ? palette.green400
                          : palette.red400
                      }
                      label={`${truncateToTwoDecimals(
                        Math.abs(
                          truncateToTwoDecimals(stats?.dot_stats?.us_driver_out_of_service_percent) -
                            truncateToTwoDecimals(stats?.dot_stats?.us_driver_national_average)
                        )?.toFixed(2)
                      )}%`}
                    />{' '}
                  </Typography>
                </div>
              </div>
              <div className='w-50 d-flex align-items-center justify-content-around'>
                <div className='d-flex align-items-baseline gap-1 flex-column'>
                  <Typography variant='s2'>NATIONAL AVERAGE</Typography>
                  <Typography variant='h5'>{stats?.dot_stats?.us_vehicle_national_average}%</Typography>
                </div>
                <div className='d-flex align-items-baseline gap-1 flex-column'>
                  <Typography variant='s2'>YOUR AVERAGE</Typography>
                  <Typography variant='h5'>
                    {truncateToTwoDecimals(stats?.dot_stats?.us_vehicle_out_of_service_percent)}%
                    {Number(stats?.dot_stats?.us_vehicle_out_of_service_percent) -
                      Number(stats?.dot_stats?.us_vehicle_national_average) <
                    0 ? (
                      <Up fill={palette.green400} style={{ transform: 'rotate(180deg)' }} />
                    ) : (
                      <Up fill={palette.red400} />
                    )}
                    <Chip
                      bgColor={
                        Number(stats?.dot_stats?.us_vehicle_out_of_service_percent) -
                          Number(stats?.dot_stats?.us_vehicle_national_average) <
                        0
                          ? palette.green0
                          : palette.red0
                      }
                      labelColor={
                        Number(stats?.dot_stats?.us_vehicle_out_of_service_percent) -
                          Number(stats?.dot_stats?.us_vehicle_national_average) <
                        0
                          ? palette.green400
                          : palette.red400
                      }
                      label={`${truncateToTwoDecimals(
                        Math.abs(
                          truncateToTwoDecimals(stats?.dot_stats?.us_vehicle_out_of_service_percent) -
                            truncateToTwoDecimals(stats?.dot_stats?.us_vehicle_national_average)
                        )?.toFixed(2)
                      )}%`}
                    />{' '}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </SWrapper>
  );
};

export default StatsSection;
