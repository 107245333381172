import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Modal from 'common/Modal';
import Input from 'common/Input';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getInsurancePolicies } from 'Api/Insurance';
import { updateAlert, uploadDriverDocument } from 'Api/AlertCenter';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { validationSchema } from './validationSchema';
import { ALERT_TYPE, statusOptions } from '../TakeActions.data';
import { insuranceSettingsConverter } from './PolicyExpiration.data';

const LiabilityExpiration = ({ open, onClose, alert, onSuccess }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [insuranceSettings, setInsuranceSettings] = useState({});

  const onSubmit = async () => {
    try {
      setLoading(true);

      const attachment = !values.use_from_policy
        ? await uploadDocument(values.attachment)
        : insuranceSettings.attachment;

      const body = {
        status: values.status.value,
      };
      switch (alert.alert_name.alert_name) {
        case ALERT_TYPE.GENERAL_LIABILITY_INSURANCE:
          body.liability_insurance_policy_id = values.policy_id;
          body.liability_insurance_expire_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.liability_insurance_policy_attach = attachment;
          break;
        case ALERT_TYPE.MOTOR_CARGO_INSURANCE:
          body.motor_cargo_insurance_policy_id = values.policy_id;
          body.motor_cargo_insurance_expire_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.motor_cargo_insurance_policy_attach = attachment;
          break;
        case ALERT_TYPE.AUTOMOBILE_LIABILITY:
          body.physical_insurance_policy_id = values.policy_id;
          body.physical_insurance_expire_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.physical_insurance_policy_attach = attachment;
          body.use_from_policy = values.use_from_policy;
          break;
        case ALERT_TYPE.DRIVER_COMPENSATION_POLICY:
        case ALERT_TYPE.STAFF_COMPENSATION_POLICY:
          body.compensation_policy_id = values.policy_id;
          body.compensation_policy_expiry_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.compensation_policy_attachment = attachment;
          break;
        case ALERT_TYPE.PHYSICAL_INSURANCE:
          body.reference_id = values.policy_id;
          body.document_exp_date = moment(values.expire_date).format('YYYY-MM-DD');
          body.document = attachment;
          body.use_from_policy = values.use_from_policy;
          break;
        default:
      }

      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors, setValues } = useForm({
    initialValues: {
      policy_id: '',
      expire_date: null,
      attachment: null,
      use_from_policy: false,
      status: statusOptions.find((i) => i.name === alert?.status) || null,
    },
    validationSchema,
    onSubmit,
  });

  const getInsuranceSettings = async () => {
    try {
      const { data } = await getInsurancePolicies();
      setInsuranceSettings(insuranceSettingsConverter(data, alert.alert_name.alert_name));
    } catch (e) {
      // Do nothing
    }
  };

  const uploadDocument = async (file) => {
    try {
      const formData = new FormData();
      formData.append('driver_id', alert.driver_id || alert.staff_id || alert.equipment_id || alert.for_user_id);
      formData.append('source', 'general');
      formData.append('file_upload', file);

      const { document_path } = await uploadDriverDocument(formData);
      return document_path;
    } catch (e) {
      // Do nothing
    }
  };

  const onUseFromSettings = (checked) => {
    if (checked) {
      setValues((prevState) => ({
        ...prevState,
        policy_id: insuranceSettings.policy_id,
        expire_date: insuranceSettings.expire_date,
        attachment: insuranceSettings.attachment,
        use_from_policy: checked,
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        policy_id: '',
        expire_date: null,
        attachment: null,
        use_from_policy: checked,
      }));
    }
  };

  useEffect(() => {
    if ([ALERT_TYPE.AUTOMOBILE_LIABILITY, ALERT_TYPE.PHYSICAL_INSURANCE].includes(alert.alert_name?.alert_name)) {
      getInsuranceSettings();
    }
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      $maxWidth='650px'
      $minWidth='650px'
      $bgColor={palette.gray0}
      padding='0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        ...(alert.equipment_type && alert.equipment_id
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: () => {
                  navigate(
                    `/equipment-profile/${alert.equipment_type === 1 ? 'vehicle' : 'trailer'}/${alert.equipment_id}`
                  );
                  onClose();
                },
              },
            ]
          : []),
        ...(alert.driver_details || alert.staff_details
          ? [
              {
                key: 'view',
                type: 'primary',
                title: 'View',
                onClick: () => {
                  navigate(
                    alert.driver_details
                      ? `/driver-profile/${alert.driver_details.id}/policies`
                      : `/staff-profile/${alert.staff_details.id}/policies`
                  );
                  onClose();
                },
              },
            ]
          : []),
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-4'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <div className='d-flex flex-column mb-4'>
            <div className='d-flex gap-4 align-items-center'>
              <div className='d-flex'>
                <Input
                  required
                  type='number'
                  name='policy_id'
                  label='Policy ID'
                  placeholder='Policy ID'
                  value={values.policy_id}
                  onChange={handleChange}
                  error={touchedErrors.policy_id}
                  width='250px'
                  style={{ borderRadius: '6px 0 0 6px' }}
                  disabled={values.use_from_policy}
                />
                <DatePicker
                  width='160px'
                  required
                  label='Expiry'
                  name='expire_date'
                  borderRadius='0 6px 6px 0'
                  value={values.expire_date}
                  onBlur={handleBlur}
                  onChange={(val) => handleChange('expire_date', val)}
                  disablePast
                  disabled={values.use_from_policy}
                  error={touchedErrors.expire_date}
                />
              </div>
              <div className='mt-4'>
                <Uploader
                  type={3}
                  document={values.attachment}
                  onDrop={(files) => handleChange('attachment', files[0])}
                  onRemove={() => handleChange('attachment', null)}
                  error={touchedErrors.attachment}
                  disabled={values.use_from_policy}
                />
              </div>
            </div>
            {!!insuranceSettings.isAvailable && (
              <div className='d-flex align-items-center gap-4'>
                <div style={{ width: '400px' }} />
                <CustomCheckbox
                  name='use_from_policy'
                  checked={!!values.use_from_policy}
                  onChange={(checked) => onUseFromSettings(checked)}
                >
                  <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                    Use from Policy
                  </Typography>
                </CustomCheckbox>
              </div>
            )}
          </div>
          <div className='mb-4'>
            <Autocomplete
              required
              width='260px'
              label='Action'
              name='status'
              options={statusOptions}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.status}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LiabilityExpiration;
