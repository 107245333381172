import React, { useState } from 'react';
import ThreeDotActions from '../../../../../../common/ThreeDotActions';

const RowActions = ({ data, setUpdateData, setDepartmentToDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onRowEdit = (data) => {
    setAnchorEl(null);
    setUpdateData(data);
  };

  const onRowDelete = (data) => {
    setAnchorEl(null);
    setDepartmentToDelete(data);
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li onClick={() => onRowEdit(data)}>Edit</li>
      {data.id > 8 && (
        <li className='delete-action' onClick={() => onRowDelete(data)}>
          Delete
        </li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
