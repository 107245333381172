import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { STOP_POINT_TAB } from 'utils/constants';
import { getDrivers } from 'Api/EquipmentProfile';
import { getGroups, getTrailers, getVehicles } from 'Api/OfferedShipments';
import { SWrapper } from './TableFilters.styles';

const TableFilters = ({
  dateRange,
  setDateRange,
  onRangeNameChange,
  search,
  setSearch,
  selectedFilters,
  setSelectedFilters,
}) => {
  const { tab } = useParams();
  const [drivers, setDrivers] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [openAdvanced, setOpenAdvanced] = useState(false);
  const { inventoryEquipmentType, historyEquipmentType } = useSelector((state) => state?.stopPoints);
  const currentTab = useMemo(
    () => (tab === STOP_POINT_TAB.INVENTORY ? historyEquipmentType : inventoryEquipmentType),
    [tab, inventoryEquipmentType, historyEquipmentType]
  );

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getEquipmentTypes = async () => {
    try {
      const { data } = await (currentTab === 1 ? getVehicles() : getTrailers());
      setEquipmentTypes(
        data.map((item) => ({ ...item, title: item.title === 'Truck' ? 'Truck (Sleeper)' : item.title }))
      );
    } catch (e) {
      // Do nothing
    }
  };

  const getGroupOptions = async () => {
    try {
      const { data } = await getGroups();
      setGroups(data.map((item) => ({ ...item, title: item.group_name, id: item.id })));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDriversList();
    getGroupOptions();
  }, []);

  useEffect(() => {
    getEquipmentTypes();
  }, [currentTab]);

  return (
    <SWrapper>
      <div className='d-flex gap-3'>
        {tab !== STOP_POINT_TAB.INVENTORY && (
          <DateRangePicker
            key={inventoryEquipmentType}
            top='-108px'
            initialRangeName={tab === STOP_POINT_TAB.INVENTORY && inventoryEquipmentType === 1 ? 'Today' : 'All Time'}
            dateRange={dateRange}
            setDateRange={setDateRange}
            onRangeNameChange={onRangeNameChange}
            type='full'
          />
        )}
        <Search search={search} onChange={setSearch} className='search-input' width='250px' />
        <CustomButton
          type='primary'
          title=''
          leftIcon={
            openAdvanced ? (
              <MinusIcon fill='#fff' style={{ margin: 8 }} />
            ) : (
              <PlusIcon fill='#fff' style={{ margin: 8 }} />
            )
          }
          className='ms-3'
          styleButton={{ height: 28, margin: 0 }}
          onClick={() => setOpenAdvanced((prevState) => !prevState)}
        />
      </div>

      {openAdvanced && (
        <div className='d-flex flex-wrap gap-3'>
          {currentTab === 1 && (
            <Autocomplete
              multiple
              width='300px'
              limitTags={2}
              disableClearable={false}
              className='filter-dropdown'
              placeholder={selectedFilters.drivers?.length ? '' : 'Select Drivers..'}
              options={drivers}
              value={selectedFilters.drivers || []}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, drivers: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
              getOptionLabel={(option) => `${option.fname} ${option.lname}`}
              getTagLabel={(option) => `${option.fname} ${option.lname}`}
            />
          )}
          <Autocomplete
            multiple
            width='300px'
            limitTags={2}
            labelKey='title'
            disableClearable={false}
            className='filter-dropdown'
            placeholder={selectedFilters.equipment_types?.length ? '' : 'Select Equipment Types..'}
            options={equipmentTypes}
            value={selectedFilters.equipment_types || []}
            onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, equipment_types: val }))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            labelProps={{ className: 'mb-0' }}
          />
          <Autocomplete
            width='300px'
            labelKey='title'
            multiple
            limitTags={2}
            disableClearable={false}
            options={groups}
            placeholder={selectedFilters.groups?.length ? '' : 'Select Tags..'}
            value={selectedFilters.groups}
            onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            labelProps={{ className: 'mb-0' }}
          />
        </div>
      )}
    </SWrapper>
  );
};

export default TableFilters;
