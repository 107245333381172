import React, { useEffect, useState, useContext, useMemo } from 'react';
import axios from 'axios';
import { Modal, Box } from '@mui/material';
import cartIcon from 'assets/images/onboarding/icon.png';
import Tabs from 'common/Tabs';
import Divider from 'common/Divider';
import { LayoutContext } from 'components/layout';
import { Typography } from 'components/Typography';
import CustomizedSnackbars from 'components/toast/Toast';
import CartComponent from 'components/Cart/CartComponent';
import CustomButton from 'components/CustomButton/CustomButton';
import 'components/customerOnboardingComponents/hardware/hardware.css';
import HardwareDescription from 'components/MyHardware/HardwareDetails';
import { palette } from 'utils/constants';
import authHeader from 'services/auth-header';
import { hardwareCart } from 'Api/Equipment';
import { getHardwareList } from 'Api/Hardware';
import { SButtonWrapper } from './PurchaseHardware.styles';

const HardwareCard = ({
  data,
  index,
  ActiveCard,
  handleCardClick,
  addToCart,
  dataId,
  cartItems,
  cartItemsData,
  hardwareList,
}) => {
  const [qty, setQty] = useState(0);
  const [cardModal, setCardModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});

  const handleModal = () => {
    setCardModal(true);
  };

  const handleClick = () => {
    handleCardClick(index, data);
    setSelectedCard(data);
    setCardModal(true);
  };

  const handleChange = (value) => {
    const NumberRegEx = /^[0-9]*$/;
    if (!NumberRegEx.test(value)) return;
    setQty(Number(value));
    addToCart({ ...data, dataId }, Number(value), dataId);
  };

  const onViewPaired = (hardware) => {
    const hardwareToView = hardwareList.find((i) => i.id === hardware.id);

    if (hardwareToView) {
      setSelectedCard(hardwareToView);
    }
  };

  useEffect(() => {
    if (!data) return;
    const CartQty = cartItems?.filter((v) => v.dataId === dataId);
    setQty(CartQty?.length > 0 ? CartQty[0]?.quantity : 0);
  }, [data, cartItems]);

  useEffect(() => {
    if (!data) return;
    const backCartItems = JSON.parse(localStorage.getItem('hardwareCart'));
    if (backCartItems !== null) {
      const ele = backCartItems?.find((ele) => ele.dataId === dataId);
      if (ele !== undefined) setQty(ele?.quantity);
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    const goBackCartItems = JSON.parse(localStorage.getItem('goBackCartItems'));
    if (goBackCartItems !== null && goBackCartItems?.length !== 0) {
      const ele = goBackCartItems?.find((ele) => ele.dataId === dataId);
      if (ele !== undefined) setQty(ele?.quantity);
    }
  }, [cartItemsData]);

  if (!data) return;

  return (
    <>
      <div className={ActiveCard === index ? 'hardware-card active-card' : 'hardware-card'} onClick={handleClick}>
        <div className='hardware-card-img-container'>
          <img className='hardware-img' src={data?.images[0].image} onClick={handleModal} alt='' />
        </div>
        <p onClick={handleModal} className='hardware-name'>
          {data?.name}
        </p>
        <p className='card-price'>
          {data.financed ? (
            <>
              ${(Number(data?.price) / 36).toFixed(2)} <span>Per Month </span>{' '}
            </>
          ) : (
            <>
              ${data.price} <span>Per Unit </span>{' '}
            </>
          )}
        </p>
        <Typography variant='c2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          SEE DETAILS
        </Typography>
      </div>
      {!!cardModal && (
        <HardwareDescription
          open={!!cardModal}
          onClose={() => setCardModal(false)}
          count={qty}
          hardware={selectedCard}
          handleCountChange={handleChange}
          onViewPaired={onViewPaired}
        />
      )}
    </>
  );
};

const Hardware = () => {
  const { cartItemsData } = useContext(LayoutContext);
  const [listIndex, setListIndex] = useState(Date.now());
  const [cartItemQty, setCartItemQty] = useState();
  const [hardware, setHardware] = useState([]);
  const [ActiveCard, setactiveCard] = useState();
  const [addBtn, setaddBtn] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isAddtoCart, setisAddtoCart] = useState(false);
  const [cartItemTotal, setCartItemTotal] = useState();
  const [CartShow, setCartShow] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState('all');

  const filteredHardware = useMemo(() => {
    return hardware.filter((i) => tab === 'all' || i.category === tab);
  }, [tab, hardware]);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const API_URL = process.env.REACT_APP_API_URL;

  const getHardware = async () => {
    try {
      const { data } = await getHardwareList({ itemsPerPage: 1000 });
      const tabs = data.reduce((acc, cur) => {
        if (!cur.category) {
          return acc;
        }

        const tabIndex = acc.findIndex((i) => i.value === cur.category);

        if (tabIndex !== -1) {
          acc[tabIndex].count += 1;
        } else {
          acc.push({ label: cur.category, value: cur.category, count: 1 });
        }

        return acc;
      }, []);
      setTabs([{ label: 'All', value: 'all', count: data.length }, ...tabs]);
      setHardware(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    hardwareCart()
      .then((res) => {
        setCartItemQty((res || []).filter((i) => i.quantity > 0));
      })
      .catch(() => {
        // Do nothing
      });
  }, [cartItemsData]);

  useEffect(() => {
    const items = [];
    if (!cartItemQty) {
      return;
    }
    cartItemQty?.length &&
      cartItemQty?.map((cart) => {
        const data = hardware?.find((v) => v.id === cart.hardware_id);
        if (!items || !items.length) {
          items.push({
            ...data,
            quantity: cart?.quantity,
            dataId: cart?.hardware_id,
            id: cart?.id,
            hardware_id: cart?.hardware_id,
          });
        } else {
          if (!items.some((s) => s.id === cart?.hardware_id)) {
            items.push({
              ...data,
              quantity: cart?.quantity,
              dataId: cart?.hardware_id,
              id: cart?.id,
              hardware_id: cart?.hardware_id,
            });
          }
        }
        return cart;
      });
    setCartItems(items);
  }, [cartItemQty, hardware, cartItemsData]);

  const addToCart = (data, qty) => {
    if (cartItems.length !== 0) {
      const isFound = cartItems.some((element) => {
        return element.dataId === data.id;
      });
      if (isFound) {
        cartItems.forEach((ele) => {
          if (ele.dataId === data.id) {
            ele.quantity = qty;
          }
        });
      } else {
        let items = [];
        items.push({
          ...data,
          quantity: qty,
          dataId: data.id,
          hardware_id: data.id,
        });
        items = [...cartItems, ...items];
        setCartItems(items);
      }
    } else {
      let items = [];

      items.push({ ...data, quantity: qty, dataId: data.id, hardware_id: data.id });
      items = [...cartItems, ...items];
      setCartItems(items);
    }
    if (qty <= 0) {
      const arr = cartItems.filter((ele) => ele.quantity !== 0);
      setCartItems(arr);
    }
    setisAddtoCart(Math.random() * 10);
  };

  const onContinue = () => {
    const hardwareItems = [];
    cartItems?.forEach((data) => {
      hardwareItems.push({ id: data.dataId, quantity: data.quantity });
    });
    localStorage.setItem('hardwareItem', JSON.stringify(hardwareItems));
    localStorage.setItem('hardware', JSON.stringify(cartItems));
    const submitForPrices = {
      payment_method: 'credit-card',
      hardware: hardwareItems,
      applied_coupon: '',
    };
    axios
      .post(`${API_URL}/customer/hardwares/prices`, submitForPrices, {
        headers: authHeader(),
      })
      .then((res) => {
        localStorage.setItem('price-summary', JSON.stringify(res.data));
        axios.post(`${API_URL}/hardware-cart`, submitForPrices, {
          headers: authHeader(),
        });
      });
    setCartShow(true);
  };

  const handleCardClick = (idx) => {
    setactiveCard(idx);
    setaddBtn(true);
  };

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  useEffect(() => {
    const backCartItems = JSON.parse(localStorage.getItem('hardwareCart'));
    if (backCartItems !== null && backCartItems?.length !== 0) {
      setCartItems(backCartItems);
    }
  }, []);

  useEffect(() => {
    const goBackCartItems = JSON.parse(localStorage.getItem('goBackCartItems'));
    if (goBackCartItems !== null && goBackCartItems?.length !== 0) {
      setCartItems(goBackCartItems);
    }
  }, []);

  useEffect(() => {
    getHardware();
  }, []);

  useEffect(() => {
    let cartTotal = 0;
    if (cartItems?.length) {
      cartItems.forEach((value) => {
        cartTotal += Number(value?.quantity);
        setCartItemTotal(cartTotal);
      });
    } else {
      setCartItemTotal(0);
    }
  }, [cartItems, isAddtoCart]);

  return (
    <>
      <div>
        <div>
          <SButtonWrapper>
            <CustomButton
              type='primary'
              title='Continue'
              styleButton={{ padding: '6px 12px', margin: 0 }}
              disabled={!cartItems?.length}
              onClick={onContinue}
              leftIcon={<img src={cartIcon} alt='cart' className='me-2' />}
            />
            {!!cartItemTotal && <span className='items-count'>{cartItemTotal}</span>}
          </SButtonWrapper>

          <Tabs tabs={tabs} activeTab={tab} handleTabChange={handleTabChange} />
        </div>
        <Divider margin='0' />
        <div className='hardware-container' key={listIndex}>
          {filteredHardware.map((data, index) => (
            <HardwareCard
              index={index}
              dataId={data?.id}
              data={data}
              key={data?.id}
              ActiveCard={ActiveCard}
              handleCardClick={handleCardClick}
              addBtn={addBtn}
              setCartItems={setCartItems}
              cartItems={cartItems}
              addToCart={addToCart}
              cartItemsData={cartItemsData}
              hardwareList={hardware}
            />
          ))}
        </div>
      </div>
      <Modal open={CartShow}>
        <Box sx={style}>
          <CartComponent
            handleClose={() => setCartShow(false)}
            CartItemQty={cartItems}
            onSuccess={() => {
              setCartItemTotal(0);
              setListIndex(Date.now());
            }}
          />
        </Box>
      </Modal>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default Hardware;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '1350px',
  minWidth: '1350px',

  borderRadius: '4px',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
};
