import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useParams } from 'react-router-dom';
import './IncidentTab.css';
import add from 'assets/icons/drivers/add.svg';
import calendar from 'assets/icons/drivers/calendar.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import AddIncident from 'components/AddIncidentModal';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import DateRange from 'components/DateRangePicker/DateRangePicker';
import AddStaffIncident from 'componentsV2/Staff/AddStaffIncident';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import IncidentDetailsModal from 'components/ViewIncidentModal';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import RuleDetails from 'pages/RulesAndConduct/components/RuleDetails';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { getRulesAndConduct } from 'Api/CompanySettings';
import { getStaffIncidents, deleteStaffIncident } from 'Api/Incidents';
import { deleteDriverIncident, driverIncidentData } from 'Api/Driver';
import Autocomplete from 'common/Autocomplete';
import { useColumns } from './IncidentTab.data';

const IncidentTabTable = ({ firstName, lastName, isStaff }) => {
  const { use } = useTheme();
  const { id } = useParams();
  const showToaster = useShowToaster();
  const [tab, setTab] = useState(1);
  const [viewData, setViewData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [rules, setRules] = useState([]);
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [ruleToView, setRuleToView] = useState(null);
  const [addModalShow, setAddModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [driverIncident, setDriverIncident] = useState({});
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ field: 'id', sortBy: 'desc' });
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [tabs, setTabs] = useState([]);
  const [allRequest, setAllRequest] = useState([]);
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 10 });
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });
  const debouncedSearch = useDebounce(search, 500);
  const [searchType, setSearchType] = useState('All');

  const getIncidents = async (updatedData) => {
    setLoading(true);
    if (updatedData) {
      setViewData(updatedData);
    }
    try {
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page: selectedFilters.page,
        from_date: moment(dateRange.start).format('DD-MM-YYYY'),
        to_date: moment(dateRange.end).format('DD-MM-YYYY'),
        sortField,
        sortBy: sort.sortBy,
      };
      if (isStaff) {
        params.staff_id = id;
      } else {
        params.driver_id = id;
      }
      if (searchType === 'All') {
        params.query = debouncedSearch || undefined;
      }
      if (searchType === 'Report Number') {
        params['filters[report_number]'] = debouncedSearch || undefined;
      }
      if (searchType === 'Action By') {
        params['filters[added_by]'] = debouncedSearch || undefined;
      }

      const response = isStaff ? await getStaffIncidents(params) : await driverIncidentData(params);
      const tempTabs = [];
      let value = 1;
      for (const req of response?.data || {}) {
        if (!req.action_taken) {
          continue;
        }
        const hasRequestIndex = tempTabs.findIndex((tab) => tab.label === req?.incident_action_taken?.action);
        if (hasRequestIndex === -1) {
          value += 1;
          tempTabs.push({
            label: req?.incident_action_taken?.action,
            count: 1,
            key: req?.incident_action_taken?.action,
            value,
          });
        } else {
          tempTabs[hasRequestIndex].count += 1;
        }
      }
      tempTabs.unshift({ label: 'All', count: response?.data?.length, key: 'all', value: 1 });
      setTabs(tempTabs);
      setDriverIncident(response);
      setAllRequest(response);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const getRules = async () => {
    try {
      const { data } = await getRulesAndConduct();
      setRules(data);
    } catch (e) {
      // Do nothing
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeTab = (tabValue) => {
    setLoading(true);
    let currentTabData = {};
    if (tabValue.value === 1) {
      // show all data
      currentTabData = allRequest;
    } else {
      const reqFilterData = allRequest?.data.filter((req) => req?.incident_action_taken?.action === tabValue.label);
      currentTabData = { ...driverIncident, data: JSON.parse(JSON.stringify(reqFilterData)) };
    }
    setTimeout(() => {
      setDriverIncident(currentTabData);
      setTab(tabValue);
      setLoading(false);
    }, 500);
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  const removeIncident = (item) => {
    const { id } = item;
    deleteDriverIncident(id)
      .then(() => {
        showToaster({ type: 'success', message: 'Incident has been deleted successfully' });
        getIncidents();
        setTab(1);
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
      });
  };

  const removeStaffIncident = (item) => {
    const { id } = item;
    deleteStaffIncident(id)
      .then(() => {
        showToaster({ type: 'success', message: 'Incident has been deleted successfully' });
        getIncidents();
        setTab(1);
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
      });
  };

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const onView = (row) => {
    setViewData(row);
  };

  const onEdit = (rowData) => {
    setEditData(rowData);
    setAddModalShow(true);
  };

  useEffect(() => {
    getIncidents();
  }, [selectedFilters, sort, debouncedSearch, dateRange]);

  useEffect(() => {
    getRules();
  }, []);

  const columns = useColumns({
    sort,
    sortingQuery,
    onView,
    onDelete: isStaff ? removeStaffIncident : removeIncident,
    onEdit,
    rules,
    setRuleToView,
    isStaff,
    hideSensitiveInfo,
  });

  return (
    <div
      className='incident-table-container driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.gray700, palette.gray200),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-header'>
          <p className='heading'>Incidents</p>
          <div className='header-modal-container'>
            {(isStaff || !hideSensitiveInfo) && (
              <PrimaryBtn
                icon={add}
                title='Add Incident'
                onClick={() => {
                  setEditData(null);
                  setAddModalShow(true);
                }}
              />
            )}
          </div>
        </div>
        <div className='serach-wrapper incident-serach-wrapper'>
          <div className='d-flex'>
            <Autocomplete
              width='140px'
              value={searchType}
              options={['All', 'Report Number', 'Action By']}
              onChange={(e, val) => {
                setSearch('');
                setSearchType(val);
              }}
            />
            <div className='position-relative'>
              <input
                type='text'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className='serach-feild garlax'
                placeholder='Search'
                style={{ backgroundColor: use(palette.white, palette.dark800), padding: '4px 24px 4px 35px' }}
              />
              {!!search?.length && (
                <DeleteIcon
                  width={12}
                  height={12}
                  fill={palette.gray300}
                  style={{ position: 'absolute', right: '8px', top: '8px', cursor: 'pointer' }}
                  onClick={() => setSearch('')}
                />
              )}
            </div>
          </div>
          <div className='calender-wrap'>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '117px', top: '-120px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
          </div>
        </div>
      </div>
      <div className='table-wrap'>
        <SwitchRadio
          name='tableTopTabMenu'
          items={tabs}
          value={tab}
          type='tab'
          onChange={(v) => onChangeTab(v)}
          plus={false}
        />
        <div className='sub-table-container'>
          {loading ? (
            <TableSkeleton />
          ) : (
            <MaterialTableWrapper
              data={driverIncident?.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: driverIncident,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                    rowsPerPageOptions: [10, 25, 50, 100, 250],
                  }),
              }}
              columns={columns}
              onRowClick={(e, row) => onView(row)}
            />
          )}
        </div>
        {!!viewData && (
          <IncidentDetailsModal
            open={!!viewData}
            incident={viewData}
            rules={rules}
            setRuleToView={setRuleToView}
            onEdit={onEdit}
            onClose={() => {
              setViewData(null);
            }}
          />
        )}
        {!!addModalShow && !isStaff && (
          <AddIncident
            open={addModalShow}
            onClose={() => {
              setAddModalShow(false);
              setEditData(null);
            }}
            driverId={id}
            firstName={firstName}
            lastName={lastName}
            onSuccess={getIncidents}
            incident={editData}
          />
        )}
        {!!addModalShow && isStaff && (
          <AddStaffIncident
            open={addModalShow}
            onClose={() => {
              setAddModalShow(false);
              setEditData(null);
            }}
            staffId={id}
            firstName={firstName}
            lastName={lastName}
            onSuccess={getIncidents}
            incident={editData}
          />
        )}
      </div>
      {!!ruleToView && <RuleDetails open={!!ruleToView} onClose={() => setRuleToView(null)} rule={ruleToView} />}
    </div>
  );
};

export default IncidentTabTable;
