import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as Icon } from 'assets/icons/drivers/indigoDownload.svg';
import sideLine from 'assets/sideline.png';
import { DriverGroups } from 'Api/Driver';
import upload from 'assets/icons/drivers/upload.png';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import DriverUserModal from '../DriverUserModal/DriverUserModal';
import ImageDropZone from '../../ImgageDropZone/index';
import './AddDriverStyle.css';
import StaffUserImportModal from '../../Staff/StaffUserModal/StaffUserModal';

const ImportCSV = ({ close, getDrivers, importUserStaff, getStaff }) => {
  const { use } = useTheme();
  const [importIsOpen, setImportIsOpen] = useState(false);
  const [staffImportIsOpen, setStaffImportIsOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [document, setDocument] = useState(false);

  const uploadLicenseDocument = (file) => {
    setDocument(file);
  };

  useEffect(() => {
    DriverGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const onSubmit = () => {
    if (importUserStaff) {
      setStaffImportIsOpen(true);
    } else {
      setImportIsOpen(true);
    }
    // close();
  };
  return (
    <div style={{ backgroundColor: use(palette.white, palette.dark600), position: 'relative', borderRadius: '5px' }}>
      <Modal.Header className='add-driver-modal-header driver-style-wrap add-driver-modal-header--csv'>
        <Modal.Title
          className='heading'
          id='contained-modal-title-vcenter'
          style={{ color: use(palette.gray900, palette.gray50) }}
        >
          Import from CSV
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='csv-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className='option1-main'>
          <div className='opt1-left'>
            <div className='count-round'>
              <p>1</p>
            </div>
            <div>
              <img src={sideLine} alt='' />
            </div>
          </div>
          <div className='opt1-right'>
            <div>
              <p className='csv-head'>Download the CSV Template</p>
            </div>
            <div className='text-csv'>
              <p className='csv-import-subline'>First download the CSV template. Then fill out asked</p>
              <p className='csv-import-subline'>data in that template then upload filled template below.</p>
            </div>

            <div>
              <a
                href={
                  importUserStaff
                    ? `${process.env.REACT_APP_BASE_URL}/uploads/csv/ImportStaff.csv?cache=${Date.now()}`
                    : `${process.env.REACT_APP_BASE_URL}/uploads/csv/ImportDriver.csv?cache=${Date.now()}`
                }
                target='_blank'
                style={{ textDecoration: 'auto' }}
                rel='noreferrer'
              >
                <div
                  className='upload-document download-template-driver'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <label htmlFor='myFile' className='text-style'>
                    <span className='upload-icon me-1'>
                      <Icon style={{ marginRight: 10, color: '#4F5AED' }} />
                    </span>
                    <span
                      style={{
                        color: '#4F5AED',
                      }}
                    >
                      Download CSV Template
                    </span>
                  </label>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className='option1-main'>
          <div className='opt1-left'>
            <div className='count-round'>
              <p>2</p>
            </div>
            <div />
          </div>
          <div className='opt1-right'>
            <div>
              <p className='csv-head'>Upload filled CSV file here:</p>
            </div>
            {document ? (
              <p className='attachment-wrapper mt-2' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <img src={cross} alt='cross' className='upload-cancel-icon' onClick={() => setDocument(null)} />
              </p>
            ) : (
              <div
                className='upload-selector csv-upload mt-2'
                style={{
                  position: 'relative',
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <label htmlFor='myFile' className='text-style csv-text-style'>
                  <span className='upload-icon'>
                    <img src={upload} alt='upload' />
                  </span>
                  <span
                    style={{
                      color: '#4F5AED',
                      marginTop: '10px',
                    }}
                  >
                    Upload File <span style={{ color: 'black' }}> or drag and drop</span>
                  </span>
                </label>
                <ImageDropZone
                  onDrop={(file) => uploadLicenseDocument(file)}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['text/csv']}
                />
              </div>
            )}
          </div>

          {!!importIsOpen && (
            <DriverUserModal
              show={!!importIsOpen}
              onHide={() => setImportIsOpen(false)}
              document={document}
              getDrivers={getDrivers}
              groups={groups}
              close={close}
            />
          )}

          {!!staffImportIsOpen && (
            <StaffUserImportModal
              show={!!staffImportIsOpen}
              onHide={() => setStaffImportIsOpen(false)}
              document={document}
              getStaff={getStaff}
              groups={groups}
              close={close}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.white, palette.darkborder),
        }}
      >
        <div className='footer-btn-container'>
          <Button className='cancel-button' onClick={() => close()}>
            Cancel
          </Button>
          <Button disabled={!document} className='cancel-button csv-submit' onClick={() => onSubmit()}>
            Upload
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default ImportCSV;
