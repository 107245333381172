import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from 'utils/constants';

const useHideDriverSensitiveInfo = () => {
  const { permissions } = useSelector((state) => state?.root);

  return useMemo(() => permissions.includes(PERMISSIONS.HIDE_DRIVER_SENSITIVE_INFO.name), [permissions]);
};

export default useHideDriverSensitiveInfo;
