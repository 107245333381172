import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import NoRecords from 'common/NoRecords';
import ColumnHeader from 'common/ColumnHeader';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import useShowToaster from 'hooks/useShowToaster';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { deleteAccountTransaction, getAccountTransactions } from 'Api/Accounts';
import { showBalanceFor } from 'pages/Accounting/Accounts/Accounts.data';

import { getBill } from 'Api/Payables';
import { BackdropLoader } from 'common/Loader';
import AddBill from 'pages/Accounting/Payables/components/AddBill';
import AccountFormItem from './AccountFormItem';
import UpdateTransaction from '../UpdateTransaction';
import { SAddMore, STable, STableHeader, STransactionsTableWrapper } from '../../RightSection.styles';
import ViewPdf from '../../../../../../components/ViewPdf';
import { SEND_PAYMENT_KEYS } from '../../../../../../components/SendPaymentModal/constants';

const AccountTransactions = ({
  transactions,
  setTransactions,
  account,
  accounts,
  payees,
  search,
  filters,
  dateRange,
  getPayees,
  onUpdateSuccess,
  onDeleteSuccess,
  refreshIndex,
  refreshAccountsList,
}) => {
  const { use } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const showToaster = useShowToaster();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [transactionToUpdate, setTransactionToUpdate] = useState(null);
  const [loadingBill, setLoadingBill] = useState(false);
  const [billData, setBillData] = useState(null);
  const [sort, setSort] = useState({ field: 'date', sortBy: 'desc' });
  const firstTransactionInFuture = transactions.data.find((item) => moment(item.date).isAfter(moment()));

  const { account_type } = account || {};

  const openUpdateTransaction = async (transactionId) => {
    try {
      const { data } = await getAccountTransactions({ itemsPerPage: 10000 });
      const transaction = data.find((i) => Number(i.id) === Number(transactionId));

      if (transaction) {
        const convertedTransaction = {
          ...transaction,
          payee:
            (payees || []).find(
              (i) => Number(i.id) === Number(transaction.payee_id) && i.type === transaction.payee_type
            ) || null,
          account: (accounts || []).find((acc) => acc.id === Number(transaction.account)) || null,
        };
        setTransactionToUpdate(convertedTransaction);
      }

      searchParams.delete('transactionId');
      setSearchParams(searchParams);
    } catch (e) {
      // Do nothing
    }
  };

  const getTransactions = async () => {
    try {
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page: filters.page,
        itemsPerPage: filters.itemsPerPage,
        account_id: account.id,
        start_date: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        query: search || undefined,
        [sortField]: sort.sortBy,
        id: sort.field === 'date' && sort.sortBy === 'desc' ? 'desc' : undefined,
      };
      const response = await getAccountTransactions(params);
      const convertedData = response.data.map((item) => ({
        ...item,
        payee: (payees || []).find((i) => Number(i.id) === Number(item.payee_id) && i.type === item.payee_type) || null,
        account: (accounts || []).find((acc) => acc.id === Number(item.account)) || null,
      }));

      setTransactions({ ...response, data: convertedData });
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const deleteTransaction = async () => {
    setLoadingDelete(true);
    try {
      await deleteAccountTransaction(transactionToDelete.id);
      onDeleteSuccess(transactionToDelete.id);
      setTransactionToDelete(null);
      showToaster({ type: 'success', message: 'Transaction has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const addNewForm = () => {
    setForms((prevState) => [{ id: Date.now(), date: new Date() }, ...prevState]);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const onCreateSuccess = (item) => {
    getTransactions();
    deleteFormItem(item.id);
    refreshAccountsList();
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onRowClick = async (item) => {
    if (!item?.account && item.bill?.is_split) {
      try {
        setLoadingBill(true);
        const { data } = await getBill(item.bill.id);
        setBillData(data);
      } catch (e) {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      } finally {
        setLoadingBill(false);
      }
      return;
    }

    setTransactionToUpdate(item);
  };

  const onClickRowItem = ({ field, row }) => {
    if (field === 'reference_id') {
      if (row?.pdf_check_file_link) {
        setPdfUrl(row?.pdf_check_file_link);
      } else {
        showToaster({ type: 'warning', message: "Check doesn't exists" });
      }
    }
  };

  const listenEmitter = ({ detail }) => {
    const keys = [
      SEND_PAYMENT_KEYS.STAFF,
      SEND_PAYMENT_KEYS.VENDORS,
      SEND_PAYMENT_KEYS.DRIVERS,
      SEND_PAYMENT_KEYS.OWNER_OPERATORS,
    ];
    if (keys.includes(detail.activeName)) {
      setLoading(true);
      getTransactions();
      setForms([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTransactions();
    setForms([]);
  }, [account?.id, dateRange, filters, search, sort, refreshIndex]);

  useEffect(() => {
    const transactionId = searchParams.get('transactionId');
    if (transactionId) {
      openUpdateTransaction(transactionId);
    }
  }, [searchParams]);

  useEffect(() => {
    window.addEventListener('on_print_check_complete', listenEmitter);
    return () => window.removeEventListener('on_print_check_complete', listenEmitter);
  }, []);

  if (loading) {
    return <TableSkeleton />;
  }

  return (
    <>
      <STransactionsTableWrapper
        $height={[ACCOUNT_TYPE.LOAN, ACCOUNT_TYPE.CREDIT_CARD].includes(account_type?.id) ? 'calc(100vh - 326px)' : ''}
      >
        <div className='table-container fixed-header'>
          <STable>
            <thead>
              <tr>
                <STableHeader $width='157px'>
                  <ColumnHeader title='DATE' field='date' color={palette.gray700} onClick={sortingQuery} sort={sort} />
                </STableHeader>
                <th>REFERENCE ID</th>
                <STableHeader $width='320px'>
                  <ColumnHeader
                    title='PAYEE'
                    field='payee_id'
                    color={palette.gray700}
                    onClick={sortingQuery}
                    sort={sort}
                  />
                </STableHeader>
                <STableHeader $width='320px'>
                  <ColumnHeader
                    title='ACCOUNT'
                    field='account'
                    color={palette.gray700}
                    onClick={sortingQuery}
                    sort={sort}
                  />
                </STableHeader>
                <th>MEMO</th>
                <th>DEBIT</th>
                <th>CREDIT</th>
                {showBalanceFor.includes(account.account_type?.id) && <th>BALANCE</th>}
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SAddMore onClick={addNewForm}>
                    <PlusIcon />
                    <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                      Add Another...
                    </Typography>
                  </SAddMore>
                </td>
              </tr>
              {forms.map((item) => (
                <AccountFormItem
                  key={item.id}
                  account={account}
                  accounts={accounts}
                  payees={payees}
                  getPayees={getPayees}
                  onDelete={() => deleteFormItem(item.id)}
                  onCreateSuccess={() => onCreateSuccess(item)}
                  form={item}
                />
              ))}
              {transactions.data?.map((item) => (
                <AccountFormItem
                  key={item.id}
                  account={account}
                  accounts={accounts}
                  payees={payees}
                  transaction={item}
                  getPayees={getPayees}
                  onDelete={() => setTransactionToDelete(item)}
                  onCreateSuccess={() => onCreateSuccess(item)}
                  onUpdateSuccess={onUpdateSuccess}
                  firstInFuture={item.id === firstTransactionInFuture?.id}
                  onRowClick={() => onRowClick(item)}
                  onClickRowItem={onClickRowItem}
                />
              ))}
            </tbody>
          </STable>
          {!transactions.data?.length && !forms?.length && !loading && <NoRecords />}
        </div>
        <ConfirmationModal
          open={!!transactionToDelete}
          title='Transaction'
          onClose={() => setTransactionToDelete(null)}
          onConfirm={deleteTransaction}
          disabled={loadingDelete}
        />
        {!!transactionToUpdate && (
          <UpdateTransaction
            open={!!transactionToUpdate}
            onClose={() => setTransactionToUpdate(null)}
            account={account}
            transaction={transactionToUpdate}
            onSuccess={(data) => onUpdateSuccess(data, true)}
          />
        )}
        {!!billData && <AddBill readOnly open={!!billData} onClose={() => setBillData(null)} bill={billData} />}
        <BackdropLoader loading={loadingBill} />
      </STransactionsTableWrapper>
      {pdfUrl && <ViewPdf pdfUrl={pdfUrl} open={!!pdfUrl} onClose={() => setPdfUrl(null)} />}
    </>
  );
};

export default AccountTransactions;
