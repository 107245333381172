import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import DocumentsList from 'componentsV2/Equipment/Documents/DocumentsList';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { bulkCreateEquipmentDocuments, deleteEquipmentDocument, updateEquipmentDocument } from 'Api/Equipment';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './UpdateDocuments.data';

const UpdateDocuments = ({
  open,
  onClose,
  equipmentId,
  equipmentType,
  documents,
  onUpdateSuccess,
  onDeleteSuccess,
}) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [documentsToDelete, setDocumentsToDelete] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const newDocuments = values.documents.filter((i) => i.isNew);
      const existingDocuments = values.documents.filter((i) => !i.isNew);

      if (newDocuments.length) {
        const body = {
          equipment_id: equipmentId,
          equipment_documents: newDocuments.map((item) => ({
            equipment_document_type_id: item.equipment_document_type.id,
            document: item.document,
            document_name: item.document_name || null,
            document_exp_date:
              item.no_expire || !item.document_exp_date ? null : moment(item.document_exp_date).format('YYYY-MM-DD'),
            document_date: null,
            reference_id: item.reference_id,
            use_from_policy: !!item.use_from_policy,
          })),
        };

        await bulkCreateEquipmentDocuments(body);
      }

      if (existingDocuments.length) {
        const promises = existingDocuments.map((item) => {
          const formData = new FormData();
          formData.append('equipment_id', equipmentId);
          formData.append('id', item.id);
          formData.append(
            'document_exp_date',
            item.no_expire || !item.document_exp_date ? '' : moment(item.document_exp_date).format('YYYY-MM-DD')
          );
          formData.append('document', item.document);
          formData.append('equipment_document_type_id', item.equipment_document_type.id);
          formData.append('use_from_policy', Number(item.use_from_policy));
          formData.append('document_name', item.document_name || '');
          formData.append('reference_id', item.reference_id || '');

          return updateEquipmentDocument(formData);
        });

        await Promise.all(promises);
      }

      if (documentsToDelete.length) {
        await Promise.all(documentsToDelete.map((i) => deleteEquipmentDocument(i)));
      }

      showToaster({ type: 'success', message: 'Equipment documents have been successfully updated!' });
      onUpdateSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues: { documents: getInitialValues(documents) },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <>
          Update Equipment Documents
          <Typography as='div' variant='b2' style={{ color: palette.gray500 }}>
            Please upload PDF only and when setting an expiry date, please be sure the Out of Service triggers / alerts
            are correctly set in Company Settings.
          </Typography>
        </>
      }
      $bgColor={palette.gray0}
      $minWidth='1200px'
      $maxWidth='1600px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save',
          onClick: form.handleSubmit,
          disabled: loading || !form.values.documents?.length,
        },
      ]}
    >
      <div className='my-2'>
        <DocumentsList
          isUpdate
          form={form}
          equipmentId={equipmentId}
          equipmentType={equipmentType}
          onDeleteSuccess={onDeleteSuccess}
          documentsToDelete={documentsToDelete}
          setDocumentsToDelete={setDocumentsToDelete}
        />
      </div>
    </Modal>
  );
};

export default UpdateDocuments;
