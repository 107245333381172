import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useForm from 'hooks/useForm';
import Input, { Textarea } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import TableSkeleton from 'common/TableSkeleton';
import { GetEquipmentCargoOnboardTypes, UpdateEquipmentAtStopCargo } from 'Api/StopPoint';
import { addEquipmentAtStopNote, getEquipmentAtStopNote } from 'Api/DetentionShipment';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { DepartmentsEmail } from 'Api/Driver';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import ViewPdf from 'components/ViewPdf';
import { updateEquipmentStatus } from 'Api/Equipment';
import { cargoValidationSchema, useColumns, validationSchema } from './data';
import ImageDropZone from '../../ImgageDropZone';
import TableFooter from '../../TableFooter/TableFooter';
import MaterialTableWrapper from '../../MaterialTableWrapper';
import { Typography } from '../../Typography';
import CustomButton from '../../CustomButton/CustomButton';
import classes from '../../TableShipments/detailsRow/steps/Notes/notes.module.css';

const EquipmentUpdateModal = ({ data, onSuccess = () => null }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [cargoTypes, setCargoTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [notesData, setNotesData] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState(null);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [tableIndex, setTableIndex] = useState(Date.now());

  const updateCargo = (body) => {
    UpdateEquipmentAtStopCargo(data?.id, body)
      .then((res) => {
        if (res) {
          onSuccess(res?.data);
          getNotes();
        }
      })
      .finally(() => setUpdateLoading(false));
  };

  const handleSubmit = (values) => {
    setUpdateLoading(true);

    if (values?.cargo_onboard_id?.id && !values?.shipment?.shipment_id) {
      const body = {
        cargo_onboard_id: values.cargo_onboard_id.id,
      };

      updateCargo(body);
    } else {
      const body = {
        status_id: '6',
        stop_point_id: data?.stop_point_id,
        cargo_onboard_id: values?.cargo_onboard_id?.id,
        shipment_id: data?.shipment_id || undefined,
        arrive_date: data.arrived_date || '',
        drop_trailer_cargo_onboard_id: values?.cargo_onboard_id?.id,
        drop_trailer_shipment_id: values?.shipment?.shipment_id || '',
        drop_trailer_stop_point_id: data?.stop_point_id,
      };
      updateEquipmentStatus(data.equipment_id, body)
        .then((res) => {
          if (res) {
            updateCargo(body);
            getNotes();
          }
        })
        .catch((e) => showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' }))
        .finally(() => setUpdateLoading(false));
    }
  };

  const handleAddNote = async (values) => {
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('notes', values.notes);
      if (values.attachment) {
        formData.append('attachment', values.attachment);
      }
      if (values.note_to_email) {
        formData.append('note_to_email', values.note_to_email);
      }
      if (values.department_id) {
        formData.append('department_id', values.department_id.id);
      }
      await addEquipmentAtStopNote(data.id, formData);
      setTableIndex(Date.now());
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const {
    values,
    setFieldValue,
    handleSubmit: handleFormSubmit,
    touchedErrors,
  } = useForm({
    initialValues: {
      cargo_onboard_id: data?.cargo_onboard_type || null,
      shipment: null,
    },
    onSubmit: handleSubmit,
    validationSchema: cargoValidationSchema,
  });

  const noteForm = useForm({
    initialValues: {
      title: '',
      attachment: '',
      notes: '',
      note_to_email: '',
      department_id: null,
    },
    validationSchema,
    onSubmit: handleAddNote,
  });

  const getNotes = async () => {
    setLoading(true);
    try {
      const response = await getEquipmentAtStopNote(data.id, { page, itemsPerPage: rowPerPage });
      setNotesData(response);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    setPage(1);
  };

  const onPageChange = (event, page) => {
    setPage(page);
  };

  const onDrop = (acceptedFiles) => {
    const attachment = acceptedFiles[0];
    noteForm.setFieldValue('attachment', attachment);
  };

  const onDeleteHandler = (e) => {
    e.stopPropagation();
    noteForm.setFieldValue('attachment', '');
  };

  const onViewNoteAttachment = (url) => {
    setPdfUrl(url);
  };

  useEffect(() => {
    GetEquipmentCargoOnboardTypes().then((res) => {
      setCargoTypes(res?.data);
    });

    DepartmentsEmail().then((res) => res && res?.data && setDepartments(res.data));
  }, []);

  useEffect(() => {
    getNotes();
  }, [tableIndex, page, rowPerPage]);

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={notesData.total}
        lastPage={notesData.last_page}
        totalLength={notesData.data.length}
        currentPage={notesData.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const columns = useColumns(onViewNoteAttachment);

  return (
    <div>
      <form>
        <div
          style={{ display: 'flex', alignItems: 'flex-start', columnGap: '24px', padding: '0 24px', marginTop: '18px' }}
        >
          <div>
            <Autocomplete
              width='400px'
              label='Cargo Onboard'
              name='cargo_onboard_id'
              labelKey='title'
              options={cargoTypes || []}
              value={values.cargo_onboard_id}
              onChange={(e, value) => setFieldValue('cargo_onboard_id', value)}
              isOptionEqualToValue={(option, value) => Number(option.id) === Number(value.id)}
              error={touchedErrors.cargo_onboard_id}
            />
          </div>
          <CustomButton
            type='primary'
            onClick={handleFormSubmit}
            title='Update Status'
            styleButton={{ marginTop: '28px', padding: '6px 12px' }}
            disabled={updateLoading || values.cargo_onboard_id?.id === Number(data?.cargo_onboard_id)}
            styleTitle={{
              fontSize: 12,
              marginTop: 1,
            }}
          />
        </div>
      </form>

      <div className={classes.formikWrapper} style={{ padding: '0 24px', margin: '32px 0 24px' }}>
        <div className={classes.inputUploadWrapper}>
          <div style={{ width: '100%', maxWidth: 206, marginBottom: '12px' }}>
            <Input
              name='title'
              label='Title'
              placeholder='Note Title'
              required
              value={noteForm.values.title}
              onChange={(e) => noteForm.setFieldValue('title', e.target.value)}
              error={noteForm.touchedErrors.title}
            />
          </div>
          <div className={classNames(classes.uploadWrapper, use(classes.light, classes.dark))}>
            <ImageDropZone
              accept={['application/pdf', 'image/jpeg', 'image/png', 'image/svg+xml']}
              onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
              height={32}
              name='attachment'
              deletable={false}
              maxSize={1000000}
              customStyle={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              deletedComponent
            >
              <div style={{ borderRadius: 4 }} className={classes.chooseFileWrapper}>
                <UploadIcon style={{ marginBottom: 6 }} />
                <Typography variant='s2'>
                  {noteForm?.values?.attachment?.name ? (
                    <div className={classes.fileUploadedWrapper}>
                      <TickIcon fill={palette.green400} />
                      <Typography
                        variant='s2'
                        style={{
                          color: palette.green400,
                          margin: '0 6px',
                        }}
                      >
                        File Uploaded
                      </Typography>

                      <div className={classes.deleteIcon} onClickCapture={(e) => onDeleteHandler(e)}>
                        <DeleteIcon />
                      </div>
                    </div>
                  ) : (
                    <Typography variant='s2' style={{ color: palette.indigo500 }}>
                      Upload document&nbsp;
                    </Typography>
                  )}
                </Typography>
              </div>
            </ImageDropZone>
          </div>
        </div>

        <div className={classes.formikWrapperRight} style={{ marginTop: '28px' }}>
          <Textarea
            name='notes'
            placeholder='Notes'
            rows={4}
            value={noteForm.values.notes}
            onChange={(e) => noteForm.setFieldValue('notes', e.target.value)}
            error={noteForm.touchedErrors.notes}
          />
          <div className={classes.smallFieldsContainer} style={{ marginTop: '12px' }}>
            <div className={classes.smallFieldsWrapper}>
              <div className={classes.errorWrapperSelect}>
                <Input
                  className='w-100'
                  name='note_to_email'
                  placeholder='Email note to...'
                  value={noteForm.values.note_to_email}
                  onChange={(e) => noteForm.setFieldValue('note_to_email', e.target.value)}
                />
              </div>
              <div className={classes.optional}>
                <Typography variant='overLine'>OPTIONAL</Typography>
              </div>

              <div className={classes.errorWrapperSelect} style={{ marginLeft: 16 }}>
                <Autocomplete
                  options={departments}
                  name='department_id'
                  labelKey='department_name'
                  placeholder='Select Department'
                  value={noteForm.values.department_id}
                  onChange={(e, val) => noteForm.setFieldValue('department_id', val)}
                />
              </div>
              <div className={classes.optional} style={{ marginLeft: 6 }}>
                <Typography variant='overLine'>OPTIONAL</Typography>
              </div>
            </div>
            <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
              <CustomButton
                type='primary'
                title='Add Note'
                buttonProps={{ type: 'submit' }}
                onClick={noteForm.handleSubmit}
                styleButton={{ padding: '6px 12px', fontSize: '12px', marginRight: 0, marginTop: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <TableSkeleton rowCount={2} />
      ) : (
        <MaterialTableWrapper
          data={notesData.data}
          rowPerPage={rowPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Document' />}
      <div className='customFooter' style={{ background: use(palette.white, palette.dark800), height: '30px' }} />
    </div>
  );
};

export default EquipmentUpdateModal;
