import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { palette, PERMISSIONS } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { DeleteChargeType, GetChargeTypes, GetSettingsDepartments } from 'Api/CompanySettings';
import ItemModal from './ItemModal';
import { SRow, SContentRow } from './ShipmentTypes.styles';
import { SActions, SWrapper } from '../Payroll/Payroll.styles';
import styles from './ShipmentTypes.module.css';
import Input from '../../../../common/Input';
import useDebounce from '../../../../hooks/useDebounce';
import QuickbooksSync from '../../../../componentsV2/QuickbooksSync';

const ShipmentTypes = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage, isModalView }) => {
  const { use } = useTheme();
  const { value } = useAuth();
  const showToaster = useShowToaster();
  const hasQuickbook = useHasQuickbook();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const openCreate = searchParams.get('openCreate');
  const [chargeTypes, setChargeTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [addItemOpen, setAddItemOpen] = useState(openCreate || false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getChargeTypes = async () => {
    GetChargeTypes()
      .then((res) => {
        setChargeTypes(res.data);
      })
      .finally(() => setLoading(false));
  };

  const deleteItem = async (id) => {
    setLoadingDelete(true);
    try {
      await DeleteChargeType(id);
      const newChargeTypes = chargeTypes.filter((item) => item.id !== id);
      setChargeTypes(newChargeTypes);
      showToaster({ type: 'success', message: 'Item has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingDelete(false);
  };

  const onEdit = (item) => {
    setRowToEdit(item);
    setAddItemOpen(true);
  };

  const checkPermissions = async () => {
    try {
      const { data } = await GetSettingsDepartments();
      const userPermissions =
        data.find((department) => department.id === value?.user?.department?.id)?.permissions || [];
      const permissionsList = userPermissions.map((i) => i.permission_name);
      if (!permissionsList.includes(PERMISSIONS.ACCOUNTING.name) && !permissionsList.includes(PERMISSIONS.ITEMS.name)) {
        navigate('/restricted');
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!isConfiguringStep && !isModalView) {
      checkPermissions();
    }

    searchParams.delete('openCreate');
    setSearchParams(searchParams);

    getChargeTypes();
    if (!isModalView) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  const filteredChargeTypes = useMemo(() => {
    if (debouncedSearch === '') return chargeTypes;
    return chargeTypes.filter((el) => el?.title?.toLowerCase().includes(debouncedSearch?.toLowerCase()));
  }, [debouncedSearch, chargeTypes]);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      <div className={styles.header} style={{ justifyContent: isModalView ? 'flex-end' : 'space-between' }}>
        {!isModalView && (
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
              Items and Services
            </Typography>
            <Input
              required
              width='250px'
              value={search}
              placeholder='Search...'
              onChange={(e) => setSearch(e.target.value)}
            />
            <QuickbooksSync action='items' onSuccess={getChargeTypes} />
          </div>
        )}
        <div className={styles.actions}>
          {isConfiguringStep && (
            <>
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('saveAndNext')}
                type='primary'
                title={isConfiguringStep ? 'Save and Next' : 'Save'}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ margin: 0 }}
              />
            </>
          )}
          <CustomButton
            type='secondary'
            title='Add Item'
            leftIcon={<PlusIcon style={{ marginRight: 10, fill: '#464F60' }} />}
            styleButton={{ margin: 0 }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => setAddItemOpen(true)}
          />
        </div>
      </div>
      <SRow>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            CODE
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            NAME
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            TYPE
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            ACCOUNT
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            SALES TAX
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DESCRIPTION
          </Typography>
        </div>
        <div className='settings-header-item' />
      </SRow>
      {filteredChargeTypes?.map((item) => (
        <SContentRow key={item.id}>
          <div className='d-flex align-items-center gap-2 settings-table-item'>
            <Typography
              title={item.code ? item?.code : item?.id}
              variant='b2'
              style={{
                color: use(palette.gray700, palette.gray200),
                maxWidth: 170,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.code ? item?.code : item?.id}
            </Typography>
            {!!item.qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
          </div>
          <div className='settings-table-item'>
            <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
              {item.title}
            </Typography>
          </div>
          <div className='settings-table-item'>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {item.type?.account_type || '-'}
            </Typography>
          </div>
          <div className='settings-table-item'>
            <div className='d-flex align-items-center gap-2'>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {item.account?.account_name || '-'}
              </Typography>
              {!!item.account?.account_code && <Chip label={item.account.account_code} />}
            </div>
          </div>
          <div className='settings-table-item'>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {item.tax_percentage ? `${item.tax_percentage}%` : '-'}
            </Typography>
          </div>
          <div className='settings-table-item'>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {item.desc || '-'}
            </Typography>
          </div>
          <SActions className='settings-table-item settings-table-actions-wrapper'>
            {loadingDelete ? (
              <span className='loading-wrapper'>
                <CircularProgress size={16} />
              </span>
            ) : (
              <div className='d-flex align-items-center gap-2'>
                {item.default !== 1 && (
                  <span className='action-wrapper' onClick={() => deleteItem(item.id)}>
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </span>
                )}
                <span className='action-wrapper' onClick={() => onEdit(item)}>
                  <EditIcon width={11} height={11} />
                </span>
              </div>
            )}
          </SActions>
        </SContentRow>
      ))}
      {addItemOpen && (
        <ItemModal
          open={addItemOpen}
          onClose={() => {
            setAddItemOpen(false);
            setRowToEdit(null);
          }}
          onSuccess={getChargeTypes}
          itemData={rowToEdit}
        />
      )}
    </SWrapper>
  );
};

export default ShipmentTypes;
