import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cross from 'assets/icons/drivers/secondClose.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { DepartmentsEmail } from 'Api/Driver';
import { getErrorMessage } from 'utils/error';
import { ReactComponent as MinimizeIcon } from 'assets/icons/minus.svg';
import { addMinimizedNotes, handleOutside } from 'store/reducers/root.reducer';
import { deleteOperaotNote, operatorCreateNotes, operatorNoteData } from 'Api/OwnerOperator';
import ViewPdf from 'components/ViewPdf';
import useDateFormat from 'hooks/useDateFormat';
import MaterialTableWrapper, { MaterialTableSort } from '../MaterialTableWrapper';
import TableFooter from '../TableFooter/TableFooter';
import NoteModal from '../AddNoteModal/AddNoteModal';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import CustomizedSnackbars from '../toast/Toast';
import AddNotes from '../TableShipments/detailsRow/steps/Notes/AddNotes';
import classes from '../TableRowEditModalTypes/NotesEditModal/notesEditModal.module.css';

const OperatorNoteTable = ({ operator_name }) => {
  const dispatch = useDispatch();
  const { convertToCustomerTime } = useDateFormat();
  const { isOutside } = useSelector((state) => state?.root);
  const { use } = useTheme();
  const [modalShow, setModalShow] = React.useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [noteTab, setNoteTab] = React.useState({});
  const [emails, setEmails] = React.useState({});
  const [user, SetUser] = React.useState({});
  const [open, setOpen] = React.useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const [createLoading, setCreateLoading] = useState(false);
  const [showMessage, setShowMessage] = React.useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 60000);
    }
  };

  const createNotes = (createNotesInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);

    operatorCreateNotes(createNotesInfo)
      .then((res) => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        const tempNotes = [...noteTab.data, res.data.data];
        setNoteTab({ ...noteTab, total: noteTab.total + 1, data: tempNotes });
        setCreateLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setCreateLoading(false);
      });
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        rowPerPage={rowPerPage}
        totalCount={noteTab?.total}
        totalLength={noteTab?.data?.length}
        lastPage={noteTab?.last_page}
        currentPage={noteTab?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setNoteTab(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const removeNote = useCallback(
    (item) => {
      const noteId = item?.id;
      const index = item?.tableData?.id;
      deleteOperaotNote({ noteId })
        .then(() => {
          const tempNote = [...(noteTab?.data || [])];
          tempNote.splice(index, 1);
          setNoteTab({ ...noteTab, data: tempNote });
          onSuccess('Note has been deleted successfully');
          setOpen(null);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    },
    [noteTab]
  );

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    setLoading(true);
    operatorNoteData({ page: noteTab.current_page, itemsPerPage: rowPage, operator_id: id })
      .then((res) => {
        setNoteTab({ ...res });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getNotesData();
    const getUser = JSON.parse(localStorage.getItem('user'));
    SetUser(getUser);
  }, []);

  useEffect(() => {
    if (isOutside) {
      getNotesData();
    }
  }, [isOutside]);

  const getNotesData = async () => {
    setLoading(true);
    operatorNoteData({ page: '1', sort: 'desc', operator_id: id, itemsPerPage: rowPerPage })
      .then((res) => {
        setNoteTab(res || {});
        setLoading(false);
        dispatch(handleOutside(false));
      })
      .catch(() => {
        setLoading(false);
      });
    DepartmentsEmail().then((res) => {
      setEmails(res.data);
    });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'Title',
        title: <MaterialTableSort title='Title' field='Title' />,
        render: (rowData) => (
          <span className='active-text' style={{ color: use(palette.indigo500, palette.indigo400) }}>
            {rowData?.title}
          </span>
        ),
      },
      {
        field: 'Note',
        title: <MaterialTableSort title='NOTE' field='Note' />,
        render: (rowData) => (
          <>
            <p
              className='table-data-text notes-line'
              style={{ color: use(palette.gray700, palette.gray200) }}
              maxLength='10'
            >
              {rowData?.notes}
            </p>
            <div className='sub-wraper'>
              <div className='wrapper-container' style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}>
                <p className='sub-text' style={{ color: use(palette.gray600, palette.gray400) }}>
                  Sent: {rowData?.note_to_email}
                </p>
              </div>
              <div className='wrapper-container' style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}>
                <p className='sub-text' style={{ color: use(palette.gray600, palette.gray400) }}>
                  CC: {rowData?.department?.department_name}
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        field: 'added_by',
        title: <MaterialTableSort title='Added By' field='added_by' />,
        render: (rowData) => (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.added_by?.first_name || '-'} {rowData?.added_by?.last_name || ''}
          </p>
        ),
      },
      {
        field: 'Date',
        title: <MaterialTableSort title='Date' field='Date' />,
        render: (rowData) => (
          <div className='last-row-container-notes'>
            {rowData?.created_at ? convertToCustomerTime(rowData?.created_at) : ''}
          </div>
        ),
      },
      {
        field: 'Attachment',
        title: <MaterialTableSort title='Attachment' field='Attachment' />,
        render: (rowData) => (
          <div className='more-dowpdown-container'>
            {rowData?.attachment ? (
              <p className='View-text' onClick={() => setPdfUrl(rowData?.attachment)}>
                View
              </p>
            ) : (
              <span>-</span>
            )}
          </div>
        ),
      },
    ];
  }, [id, use, removeNote]);

  const handleClickOpen = (item) => {
    setOpen(item);
  };

  const handleClose = () => {
    setOpen(null);
  };

  if (loading) {
    return <TablePreLoader />;
  }

  const onAddNote = (form) => {
    const notesObj = {
      ...form.values,
      operator_id: Number(id),
    };
    const formData = new FormData();
    formData.append('operator_id', notesObj.operator_id);
    formData.append('title', notesObj.title);
    formData.append('notes', notesObj.notes);
    formData.append('note_to_email', notesObj.note_to_email);
    formData.append('department_id', notesObj.department_id);
    if (notesObj.attachment) {
      formData.append('attachment', notesObj.attachment);
    }

    operatorCreateNotes(formData)
      .then(() => {
        setTimeout(() => {
          form.resetForm();
        }, 1000);
        onSuccess('Notes has been successfully created');
        getNotesData();
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setCreateLoading(false);
      });
  };

  function onMinimize(e) {
    e.stopPropagation();
    dispatch(addMinimizedNotes({ label: 'Owner_Operator', shipment_id: id, id, name: operator_name }));
    dispatch(handleOutside(false));
  }

  return (
    <div
      className='notes-table-container driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.gray700, palette.gray200),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='notes-header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Notes
        </p>
        <div onClick={(e) => onMinimize(e)} className={classes.minimize}>
          <MinimizeIcon />
        </div>
      </div>
      <div className='AddNotes-div'>
        <AddNotes onSubmit={onAddNote} />
      </div>
      <div className='table-wrap'>
        <div className='sub-table-container'>
          <MaterialTableWrapper
            data={noteTab?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
            actionsColumnIndex={-1}
            actions={[
              (rowData) => ({
                icon: () => {
                  return (
                    <div onClick={(e) => e.stopPropagation()}>
                      {user?.id === rowData?.added_by?.id ? (
                        <img
                          src={cross}
                          alt='cross'
                          onClick={() => handleClickOpen(rowData)}
                          className='cross-icon-wrap'
                        />
                      ) : null}
                    </div>
                  );
                },
              }),
            ]}
          />
        </div>
        {modalShow && (
          <NoteModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            driverId={id}
            createNotes={createNotes}
            onSuccess={onSuccess}
            loading={createLoading}
            emails={emails}
          />
        )}

        <Dialog
          open={!!open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Confirm Your Action</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>Are you sure you want to remove this?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={handleClose}>
              Cancel
            </Button>
            <Button className='next-step' onClick={() => removeNote(open)} autoFocus>
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Document' />}
    </div>
  );
};

export default OperatorNoteTable;
