import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const InfoItem = ({ title, titleProps, content = '', children }) => {
  return (
    <div className='d-flex flex-column align-items-start gap-2'>
      {title && (
        <Typography variant='button2' style={{ color: palette.indigo700 }} {...titleProps}>
          {title}
        </Typography>
      )}
      {children || (
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {content}
        </Typography>
      )}
    </div>
  );
};

export default InfoItem;
