import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import { palette } from 'utils/constants';

export const SAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    width: ${({ width }) => (width ? '' : '100%')};

    .MuiFormControl-root {
      width: ${({ width }) => width || ''};
    }

    .MuiInputBase-root {
      background-color: ${palette.white};
      box-shadow: ${palette.boxShadow2};
      height: ${({ height }) => height || ''};
      min-height: ${({ size }) => (size === 'small' ? '' : '32px')};
      border-radius: ${({ $borderRadius }) => $borderRadius || ''};
      row-gap: 4px;

      &.MuiOutlinedInput-root {
        padding: ${({ size }) => (size === 'small' ? '0px 24px 0px 8px' : '6px 24px 6px 6px')};
      }

      & .MuiOutlinedInput-notchedOutline {
        display: none;
      }

      .MuiAutocomplete-tag {
        margin: 0 3px;
      }

      span.MuiAutocomplete-tag {
        font-size: 14px;
        line-height: 20px;
      }

      &.Mui-disabled {
        background-color: ${palette.gray0};
      }

      .MuiInputBase-input {
        padding: 0;
        font-size: 14px;

        &::placeholder {
          color: ${palette.gray500};
          font-weight: 500;
          opacity: 1;
        }
      }
    }

    .MuiAutocomplete-clearIndicator {
      .MuiSvgIcon-root {
        width: ${({ size }) => (size === 'small' ? '16px' : '')};
        height: ${({ size }) => (size === 'small' ? '16px' : '')};
      }
    }

    .MuiAutocomplete-popupIndicator {
      svg {
        width: 10px;
        height: 10px;
      }

      svg.MuiCircularProgress-svg {
        color: ${palette.indigo500};
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: #fff;
      }
    }

    .MuiAutocomplete-tag {
      border-radius: 4px;
    }

    .MuiAutocomplete-endAdornment {
      top: ${({ size }) => (size === 'small' ? 0 : '2px')};
    }
  }
`;
