import React, { useEffect, useState } from 'react';
import { useTheme } from 'context/themeContext';
import { getDriverRetentionCount } from 'Api/Dashboard';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import DoughnutChart from 'pages/Dashboard/components/Charts/DoughnutChart';
import { SDateRange } from 'pages/Dashboard/Dashboard.styles';

const labels = {
  0: 'Hired',
  1: 'Quit',
  2: 'Terminated',
};

const DriverRetention = () => {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('year');
  const [driverRetentionCounts, setDriverRetentionCounts] = useState(null);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getDriverRetentionCount({ period });
      setDriverRetentionCounts(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasets = [
    {
      data: [driverRetentionCounts?.hired, driverRetentionCounts?.quite, driverRetentionCounts?.terminated],
      backgroundColor: [palette.indigo400, '#F5BA0B', palette.red400],

      borderColor: [palette.indigo400, '#F5BA0B', palette.red400],
      hoverBackgroundColor: [palette.indigo500, '#E4B118', palette.red500],
      hoverBorderColor: [palette.indigo500, '#E4B118', palette.red500],
      borderWidth: 1,
    },
  ];

  useEffect(() => {
    getCounts();
  }, [period]);

  return (
    <div className='box-content-wrapper'>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={driverRetentionCounts?.total}
        header={
          <div className='d-flex justify-content-between align-items-center'>
            <Typography variant='s2' style={{ color: use(palette.gray700) }}>
              Driver Retention
            </Typography>
            <SDateRange>
              <Typography
                variant='c2'
                className={period === 'month' ? 'range-item_active' : 'range-item'}
                onClick={() => setPeriod('month')}
              >
                This Month
              </Typography>
              <Typography
                variant='c2'
                className={period === 'year' ? 'range-item_active' : 'range-item'}
                onClick={() => setPeriod('year')}
              >
                This Year
              </Typography>
            </SDateRange>
          </div>
        }
      />
    </div>
  );
};

export default DriverRetention;
