import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { palette } from 'utils/constants';
import CustomModal from 'components/CustomModal/CustomModal';

export const SWrapper = styled.div`
  min-height: 300px;
  position: relative;

  .settlement-loader.MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;

export const SBackButton = styled.div`
  margin: 4px 0 12px;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const SLink = styled(Link)`
  color: ${palette.indigo500};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: ${palette.indigo500};
  }
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SButtonsWrapper = styled.div`
  display: flex;
  column-gap: 16px;

  button {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 12px;
    font-family: Inter, sans-serif;

    &.decline-button {
      background-color: ${palette.red500} !important;
      box-shadow: 0 1px 1px rgb(0 0 0 / 14%), 0 0 0 1px ${palette.red200}, 0 2px 5px rgb(79 90 237 / 10%),
        inset 0 1px 0 ${palette.red200};
    }

    svg {
      margin-left: 8px;
    }
  }
`;

export const SCustomModal = styled(CustomModal)`
  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '700px'};
    max-width: ${({ $maxWidth }) => $maxWidth || ''};
    height: ${({ $height }) => $height || 'auto'};

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: 100%;
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      overflow: auto;
      border-radius: 0;

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      border-bottom: 1px solid ${palette.gray50};
    }

    .customFooter {
      padding: 12px 20px;
      border-top: 1px solid ${palette.gray50};

      button {
        padding: 6px 12px !important;
        margin: 0 !important;
        margin-right: 8px !important;

        span {
          font-size: 14px !important;
          font-weight: 500;
          font-family: Inter, sans-serif;
        }
      }

      .modal-cancel-btn {
        margin-right: 16px !important;
      }
    }
  }
`;
