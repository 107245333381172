import React from 'react';
import { useSelector } from 'react-redux';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { formatNumber, palette } from 'utils/constants';
import { STable } from '../SendQuote.styles';

const QuotesList = ({ form, quotes, customerIndex, onPreview }) => {
  const { currency } = useSelector((state) => state.root);

  const { handleChange, touchedErrors } = form;

  const prefix = `customers[${customerIndex}].quotes`;
  const errors = touchedErrors.customers?.[customerIndex]?.quotes;

  return (
    <STable>
      <thead>
        <tr className='header-row'>
          <th>QUOTE ID</th>
          <th>QUOTE DATE</th>
          <th>QUOTE EXPIRES ON</th>
          <th>REFERENCE ID</th>
          <th>TOTAL CHARGES</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {quotes.map((quote, quoteIndex) => (
          <tr className='body-row' key={quote.id}>
            <td>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {quote.quote_id}
              </Typography>
            </td>
            <td>
              <div>
                <DatePicker
                  name={`${prefix}[${quoteIndex}].quote_date`}
                  value={quote.quote_date}
                  onChange={(val) => handleChange(`${prefix}[${quoteIndex}].quote_date`, val)}
                  error={errors?.[quoteIndex]?.quote_date}
                />
              </div>
            </td>
            <td>
              <div>
                <DatePicker
                  name={`${prefix}[${quoteIndex}].expiry_date`}
                  value={quote.expiry_date}
                  onChange={(val) => handleChange(`${prefix}[${quoteIndex}].expiry_date`, val)}
                  error={errors?.[quoteIndex]?.expiry_date}
                  disablePast
                />
              </div>
            </td>
            <td>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {quote.billing.reference_id}
              </Typography>
            </td>
            <td>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {currency}
                {formatNumber(
                  quote.charges.reduce((acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0), 0) +
                    quote.line_items.reduce((acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0), 0)
                )}
              </Typography>
            </td>
            <td>
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px', margin: 0 }}
                type='secondary'
                title='Preview'
                onClick={() => onPreview(quote)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </STable>
  );
};

export default QuotesList;
