import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { STableRow } from '../MatchTransaction.styles';

const MatchTransactionRow = ({
  isMatchTable,
  selected,
  totalAmount,
  transactionAmount,
  rowData,
  handleSelectRow,
  matches,
  onRowClick,
  isReceivable,
  selectedRows,
}) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const { total_amount, reference, payee_name, payee_id, billed_date, due_date } = rowData || {};

  const disabled = isMatchTable && !selected && totalAmount >= transactionAmount;
  const isOpenInvoicesDisabled = isReceivable && !!selectedRows.length && !selectedRows[0].billing;
  const isStandaloneInvoicesDisabled = isReceivable && !!selectedRows.length && !!selectedRows[0]?.billing;

  // If we have already selected bill with some vendor, we can't select another with different vendor
  const noSelectOtherVendor =
    isMatchTable && !selected && !isReceivable ? selectedRows.length && payee_id !== selectedRows[0].payee_id : false;

  const isCheckboxDisabled =
    disabled ||
    noSelectOtherVendor ||
    (!!rowData.billing && isOpenInvoicesDisabled) ||
    (!rowData.billing && isStandaloneInvoicesDisabled);
  return (
    <STableRow $matched={total_amount === transactionAmount} $disabled={disabled} onClick={onRowClick}>
      <td onClick={(e) => e.stopPropagation()}>
        {(isMatchTable || !matches) && (
          <Tooltip
            arrow
            title={!disabled && noSelectOtherVendor ? `Bills with different vendors can't be selected` : ''}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 13,
                  fontFamily: 'Inter',
                },
              },
            }}
          >
            <span>
              <CustomCheckbox
                onChange={(checked) => handleSelectRow(checked, rowData)}
                checked={selected}
                disabled={isCheckboxDisabled}
                labelStyle={{
                  cursor: isCheckboxDisabled ? 'not-allowed' : 'pointer',
                }}
                checkmarkStyle={{
                  backgroundColor: isCheckboxDisabled ? palette.gray50 : '',
                  fill: isCheckboxDisabled ? palette.gray50 : '',
                }}
              />
            </span>
          </Tooltip>
        )}
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {formatDate(billed_date)}
        </Typography>
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {payee_name}
        </Typography>
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {reference}
        </Typography>
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {formatDate(due_date)}
        </Typography>
      </td>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(Math.abs(Number(total_amount || 0)))}
        </Typography>
      </td>
    </STableRow>
  );
};

export default MatchTransactionRow;
