import { array, number, object, string, date } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  documents: array().of(
    object().shape({
      equipment_document_type: object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable(),
      document: string().required('Required').nullable(),
      document_exp_date: date().when(['no_expire', 'equipment_document_type'], {
        is: (no_expire, equipment_document_type) => !no_expire && equipment_document_type?.id !== 8,
        then: validator.futureRequiredDate(),
        otherwise: validator.date(),
      }),
    })
  ),
});
