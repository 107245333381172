import React, { useState, useEffect } from 'react';
import './pushToTalk.css';
import HeaderStar from '../../components/HeaderStar';
import CustomButton from '../../components/CustomButton/CustomButton';
import { ReactComponent as PlusIcon } from '../../assets/icons/pluseIcon.svg';
import CreateChannel from '../../components/PushToTalkComponents/CreateChannelModal/CreateChannelModal';
import DeleteChannel from '../../components/PushToTalkComponents/DeleteChannel/DeleteChannel';
import arrow from '../../assets/icons/equipment/arrowleft.svg';
import DispatchChannel from '../../components/PushToTalkComponents/DispatchChannels/DispatchChannel';
import DispatchUser from '../../components/PushToTalkComponents/DispatchUsers/DispatchUser';
import MenuItem from '../../components/PushToTalkComponents/MenuItem/MenuItem';

const ChannelsList = ({ channel, key, setisDeleteChannel, setDispatchUser, setselctedChannel, selctedChannel }) => {
  const handleClick = () => {
    setDispatchUser(true);
    setselctedChannel(channel);
  };

  return (
    <div key={key} className='channel'>
      <div className='channels ' onClick={handleClick}>
        <p>
          {channel.channel_name}
          {selctedChannel?.id === channel.id && (
            <span style={{ background: '#E1FCEF', color: '#14804A' }}>Connected</span>
          )}
        </p>
      </div>
      <div className='users' onClick={handleClick} />
      <div className='usersMenu'>
        <MenuItem delete setisDeleteChannel={setisDeleteChannel} />
      </div>
    </div>
  );
};

const channel = {
  channel_name: 'dispatch',
};

const PushToTalk = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteChannel, setisDeleteChannel] = useState(false);
  const [dispatchUser, setDispatchUser] = useState(false);
  const channelsData = [channel];
  const [selctedChannel, setselctedChannel] = useState({});

  useEffect(() => {
    setselctedChannel(channelsData ? channelsData[0] : 'null');
  }, [channelsData]);

  return (
    <div className='talk-layout'>
      <div className='section-one' style={{ width: dispatchUser ? '40%' : '80%' }}>
        {dispatchUser && (
          <div className='goBack' onClick={() => setDispatchUser(false)}>
            <img src={arrow} alt='' />
            go back
          </div>
        )}
        <div className='plannerHeader_wrapper' style={{ margin: '30px 0px' }}>
          {isOpen && <CreateChannel setIsOpen={setIsOpen} channelsData={channelsData} />}
          {isDeleteChannel && (
            <DeleteChannel
              heading='Delete Channel'
              message='Are you sure you want to delete this channel? Deleting this channel will remove all users from this channel.'
              deleteBtnName='Delete Channel'
              onCancel={setisDeleteChannel}
            />
          )}
          <HeaderStar title='Channels' />
          {!dispatchUser && (
            <CustomButton
              width={12}
              height={12}
              type='primary'
              title='Create Channel'
              leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
              styleButton={{ padding: '6px 12px' }}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
        </div>
        {!dispatchUser && (
          <div className='channel'>
            <p>Channel</p>
            <p>Active Users</p>
          </div>
        )}
        {channelsData?.map((channel) => (
          <ChannelsList
            channel={channel}
            key={channel?.channel_name}
            setDispatchUser={setDispatchUser}
            setisDeleteChannel={setisDeleteChannel}
            setselctedChannel={setselctedChannel}
            selctedChannel={selctedChannel}
          />
        ))}
      </div>
      <div className='section-two' style={{ width: dispatchUser ? '60%' : '20%' }}>
        {!dispatchUser && <DispatchUser selctedChannel={selctedChannel} />}
        {dispatchUser && <DispatchChannel selctedChannel={selctedChannel} />}
      </div>
    </div>
  );
};

export default PushToTalk;
