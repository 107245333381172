import React, { useState } from 'react';
import Input from 'common/Input';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { addTabs, updateTabs } from 'Api/Driver';
import ModalFooter from './components/ModalFooter';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './AddTab.data';

const AddTab = ({ open, onClose, tab, onSuccess, onDeleteSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        type: !tab ? 'request' : undefined,
        forAll: !tab ? values.forAll : undefined,
        data: {
          name: values.tabName,
          filters: {
            status: values.statuses.length ? values.statuses : undefined,
            type: values.types.length ? values.types : undefined,
            user_type: values.userTypes.length ? values.userTypes : undefined,
          },
        },
      };
      if (tab) {
        await updateTabs(body, tab.tabId);
      } else {
        await addTabs(body);
      }
      onSuccess();
      onClose();
      showToaster({ type: 'success', message: `Tab has been successfully ${tab ? 'updated' : 'created'}!` });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, setValues, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(tab),
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={tab ? 'Update Tab' : 'Add Tab'}
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdrop='static'
      customFooter={
        <ModalFooter
          tab={tab}
          values={values}
          onClose={onClose}
          loading={loading}
          onDeleteSuccess={onDeleteSuccess}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      }
    >
      <div className='d-flex flex-column gap-4'>
        <Input
          required
          name='tabName'
          label='Tab Name'
          onChange={handleChange}
          value={values.tabName}
          error={touchedErrors?.tabName}
        />
        <div className='d-flex flex-column gap-2'>
          <Typography variant='button2'>if User Type</Typography>
          <div className='d-flex flex-column gap-2 ms-2'>
            <CustomCheckbox
              name='userTypes'
              checked={values.userTypes.includes('driver')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    userTypes: [...prevState.userTypes, 'driver'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    userTypes: prevState.userTypes.filter((el) => el !== 'driver'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Driver
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='userTypes'
              checked={values.userTypes.includes('staff')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    userTypes: [...prevState.userTypes, 'staff'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    userTypes: prevState.userTypes.filter((el) => el !== 'staff'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Staff User
              </Typography>
            </CustomCheckbox>
          </div>
        </div>
        <div className='d-flex flex-column gap-2'>
          <Typography variant='button2'>if Request Status</Typography>
          <div className='d-flex flex-column gap-2 ms-2'>
            <CustomCheckbox
              name='statuses'
              checked={values.statuses.includes('Pending')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    statuses: [...prevState.statuses, 'Pending'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    statuses: prevState.statuses.filter((el) => el !== 'Pending'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Pending
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='statuses'
              checked={values.statuses.includes('Approved')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    statuses: [...prevState.statuses, 'Approved'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    statuses: prevState.statuses.filter((el) => el !== 'Approved'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Approved
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='statuses'
              checked={values.statuses.includes('Rejected')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    statuses: [...prevState.statuses, 'Rejected'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    statuses: prevState.statuses.filter((el) => el !== 'Rejected'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Rejected
              </Typography>
            </CustomCheckbox>
          </div>
        </div>
        <div className='d-flex flex-column gap-2'>
          <Typography variant='button2'>if Request Type</Typography>
          <div className='d-flex flex-column gap-2 ms-3'>
            <CustomCheckbox
              name='types'
              checked={values.types.includes('Cash Advance')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    types: [...prevState.types, 'Cash Advance'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    types: prevState.types.filter((el) => el !== 'Cash Advance'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Cash Advance
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='types'
              checked={values.types.includes('Time Off')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    types: [...prevState.types, 'Time Off'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    types: prevState.types.filter((el) => el !== 'Time Off'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Time Off
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='types'
              checked={values.types.includes('Pickup Number')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    types: [...prevState.types, 'Pickup Number'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    types: prevState.types.filter((el) => el !== 'Pickup Number'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Pickup Number
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='types'
              checked={values.types.includes('Delivery Number')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    types: [...prevState.types, 'Delivery Number'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    types: prevState.types.filter((el) => el !== 'Delivery Number'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Delivery Number
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='types'
              checked={values.types.includes('Fuel Advance')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    types: [...prevState.types, 'Fuel Advance'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    types: prevState.types.filter((el) => el !== 'Fuel Advance'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Fuel Advance
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              name='types'
              checked={values.types.includes('Maintenance')}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({
                    ...prevState,
                    types: [...prevState.types, 'Maintenance'],
                  }));
                } else {
                  setValues((prevState) => ({
                    ...prevState,
                    types: prevState.types.filter((el) => el !== 'Maintenance'),
                  }));
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Maintenance
              </Typography>
            </CustomCheckbox>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddTab;
