import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Incidents } from 'pages/CompanySettings/pagesComponents';

const IncidentSettings = ({ open, onClose }) => {
  return (
    <Modal
      showModal={!!open}
      onHide={onClose}
      $width='1200px'
      $bodyMaxHeight='calc(100vh - 250px)'
      $bgColor={palette.gray0}
      headerTitle='Incident Types'
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done', className: 'modal-cancel-btn' }]}
    >
      <Incidents isModalView />
    </Modal>
  );
};

export default IncidentSettings;
