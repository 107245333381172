import { ErrorMessage, Field, useField } from 'formik';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as KeyboardIcon } from 'assets/icons/createShipment/keyboard.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/createShipment/copy.svg';
import sm from '../TypeOfShipment.module.css';
import { Typography } from '../../../Typography';
import CustomRadioButton from '../../../CustomRadioButton';
import CustomSelect from '../../ShipmentStops/helpers/CustomSelect';
import CustomInput from '../../helpers/CustomInput';

const ShipmentDetails = ({
  values,
  detailCopyShipmentData,
  getCopyShipmentSingle,
  updateShipmentData,
  updateSelectedCharges,
  updateSelectedGrandTotal,
}) => {
  const { use } = useTheme();
  const shipment_details_type = values?.shipment_details_type;
  const [, , helpers] = useField('shipment_copy_id');
  const isInitialMount = useRef(true);

  const styles = useMemo(() => {
    return {
      selectStyle: {
        height: 32,
      },

      error: {
        color: use(palette.red500, palette.red800),
      },
      inputStyle: {
        width: 87,
        height: 32,
        borderRadius: 6,
        padding: '0 6px',
      },
      inputLabel: {
        marginTop: 0,
        marginLeft: 8,
      },
      isSearchableCopy: {
        width: '100%',
        height: 32,
        borderRadius: '6px',
      },
    };
  }, [palette, use]);

  const validateRequired = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  useEffect(() => {
    if (+values.shipment_details_type !== 3) {
      helpers.setValue('');
    }
  }, [values.shipment_details_type]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      helpers.setValue('');
    }
  }, [values.type]);

  const initialValueFirstStep = useMemo(() => {
    const { frequency, type, laneEndDate, laneStartDate, leadTime, recurs, shipmentOnly, shipment_copy_id, laneName } =
      values;
    return {
      frequency,
      type,
      recurs,
      laneEndDate,
      laneStartDate,
      leadTime,
      shipmentOnly,
      shipment_copy_id,
      laneName,
      copy_count: '1',
    };
  }, [values]);

  return (
    <div className={sm.shipment_details}>
      <Typography variant='s1'>How do you want to enter shipment details?</Typography>
      <div className={sm.line} />

      <div style={{ marginBottom: 40 }}>
        <div className={sm.type_shipment_child}>
          <Field
            name='shipment_details_type'
            type='radio'
            value='1'
            component={(props) => (
              <CustomRadioButton
                {...props}
                onChange={() => {
                  updateSelectedCharges([]);
                  updateSelectedGrandTotal({});
                  updateShipmentData(initialValueFirstStep);
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  I will enter the shipment data
                </Typography>
              </CustomRadioButton>
            )}
          />
          <KeyboardIcon
            style={{ marginTop: 10 }}
            fill={shipment_details_type === '1' ? palette.indigo500 : palette.gray500}
          />
        </div>

        <div className={sm.line} />
        <div className={sm.type_shipment_child}>
          <Field
            name='shipment_details_type'
            type='radio'
            value='3'
            component={(props) => (
              <CustomRadioButton {...props}>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Copy previous shipment
                </Typography>
              </CustomRadioButton>
            )}
          />
          <CopyIcon
            style={{ marginTop: 10 }}
            fill={shipment_details_type === '3' ? palette.indigo500 : palette.gray500}
          />
        </div>

        {+shipment_details_type === 3 && (
          <div className={sm.detail_copy_wrapper}>
            <div style={{ width: '32%' }}>
              <Field
                isSearchable
                isSearchInValue='key'
                component={CustomSelect}
                name='shipment_copy_id'
                styles={styles.selectStyle}
                validate={validateRequired}
                onChange={(value) => {
                  getCopyShipmentSingle({ ...values, shipment_copy_id: value.shipment_id });
                  updateSelectedGrandTotal({});
                }}
                menuStyles={styles.menuStyles}
                options={detailCopyShipmentData}
                isSearchableStyles={styles.isSearchableCopy}
              />
              <ErrorMessage
                name='shipment_copy_id'
                render={(error) => (
                  <Typography variant='c2' style={styles.error}>
                    {error}
                  </Typography>
                )}
              />
            </div>
            {Number(values.frequency) !== 2 && (
              <div className={sm.detail_copy_count}>
                <Field
                  minCount={1}
                  maxCount={50}
                  type='number'
                  roundNumber
                  name='copy_count'
                  placeholder='Qty'
                  component={CustomInput}
                  style={styles.inputStyle}
                  validate={validateRequired}
                  labelStyle={{ margin: 0 }}
                />
                <ErrorMessage
                  name='copy_count'
                  render={(error) => (
                    <Typography variant='c2' style={styles.error}>
                      {error}
                    </Typography>
                  )}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShipmentDetails;
