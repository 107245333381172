import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Skeleton } from '@material-ui/lab';
import { getFuelPrices } from 'Api/Dashboard';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';
import Chip from 'common/Chip';
import useDateFormat from 'hooks/useDateFormat';
import FuelPricesChart from './FuelPricesChart';

const fuelTypeOptions = ['Diesel', 'Gasoline', 'Natural Gas', 'Electric'];

const FuelPrices = () => {
  const { formatDate } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [fuelType, setFuelType] = useState('Diesel');
  const [fuelPrices, setFuelPrices] = useState(null);
  const [labels, setLabels] = useState([]);
  const [dataset, setDataset] = useState([]);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data, history } = await getFuelPrices({
        from_date: moment().subtract(12, 'month').format('MM/DD/YYYY'),
        to_date: moment().format('MM/DD/YYYY'),
        fuel_type: fuelType,
      });
      setFuelPrices(data);

      const labels = new Array(history.length).fill('');

      const dataset = history.map((item, i) => {
        labels[i] = moment(history[i].date).format('MMM DD,YY');
        return Number(item.price);
      });
      setDataset(dataset);
      setLabels(labels);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounts();
  }, [fuelType]);

  return (
    <div className='box-content-wrapper'>
      <div className='d-flex flex-column w-100 h-100 gap-4'>
        <Typography as='div' variant='s2' style={{ height: '32px', borderBottom: `2px solid ${palette.gray50}` }}>
          Fuel Prices
        </Typography>
        <div
          className='d-flex align-items-center justify-content-between'
          style={{ height: '32px', paddingBottom: '24px', borderBottom: `2px solid ${palette.gray50}` }}
        >
          <div className='d-flex align-items-center justify-content-between gap-1'>
            {loading ? (
              <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '32px' }} />
            ) : (
              <Typography variant='h2'>{fuelPrices?.price ? `$ ${fuelPrices?.price}` : 'N/A'}</Typography>
            )}
            {loading ? (
              <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '100px', height: '32px' }} />
            ) : (
              <Typography variant='s3' style={{ color: palette.gray400 }}>
                (as of {fuelPrices?.date || formatDate()})
              </Typography>
            )}
            {loading ? (
              <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '100px', height: '32px', marginLeft: '150px' }} />
            ) : (
              <>
                <Typography variant='s2' style={{ marginLeft: '150px' }}>
                  {fuelPrices?.unit || ''}
                </Typography>
                {fuelPrices?.type && (
                  <Chip
                    size='medium'
                    bgColor={palette.gray50}
                    labelColor={palette.gray500}
                    label={fuelPrices?.type}
                    fontSize='14px'
                    fontWeight={500}
                  />
                )}
              </>
            )}
          </div>
          <div>
            <Autocomplete
              width='110px'
              placeholder='Select Fuel Type..'
              options={fuelTypeOptions}
              value={fuelType}
              onChange={(e, val) => setFuelType(val)}
            />
          </div>
        </div>
        <FuelPricesChart labels={labels} dataset={dataset} loading={loading} />
      </div>
    </div>
  );
};

export default FuelPrices;
