import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { palette } from 'utils/constants';

export const SLoadingWrapper = styled.div`
  min-height: ${({ $minHeight }) => $minHeight || '300px'};
  position: relative;

  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 9999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${({ $color }) => $color || palette.indigo500};
  }
`;
