import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBodyWrapper = styled.div`
  .section {
    margin-bottom: 32px;
    cursor: pointer;
  }

  .radio-wrapper {
    padding: 12px 50px;
    border-bottom: 1px solid ${palette.gray50};
  }

  .info-items {
    display: flex;
    flex-direction: column;

    .info-item {
      display: flex;
      align-items: center;
      column-gap: 12px;
      padding: 12px 92px;
      border-bottom: 1px solid ${palette.gray50};
    }
  }

  .services-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    padding-left: 80px;
    margin-top: 12px;

    .service-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 14px;
    }
    .service-icon {
      width: 270px;
    }
  }
`;
