import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { ReactComponent as OfferIcon } from 'assets/icons/offer.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';

import Radio from 'common/Radio';
import Divider from 'common/Divider';
import { PusherJs } from 'common/pusher';
import Autocomplete from 'common/Autocomplete';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import AddressAutocomplete from 'common/AddressAutocomplete';
import { InputWithIcon, Textarea } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers, getEquipmentRequiredTitle } from 'utils/helpers';
import { formatNumber, getAlphabet, palette } from 'utils/constants';
import { createBid } from 'Api/OfferedShipments';
import { getEta, getOfferContactUser } from 'Api/PlannerV2';
import { getCarrierContactBookByToken } from 'Api/Carriers';
import { STOP_POINT_TYPES } from 'pages/Shipment/ShipmentBid/ShipmentBid.data';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import ShipmentCommodity from 'componentsV2/Commodity/ShipmentCommodity';
import ContactBook from '../ContactBook';
import DeclineShipment from '../DeclineShipment';
import { validationSchema } from './validationSchema';
import { equipmentAction, getInitialValues } from './SubmitBid.data';
import { SSubmitBid, STimeline, STimelineDot } from './SubmitBid.styles';

const SubmitBid = ({
  shipment,
  onSuccess,
  onDeclineSuccess,
  onStartingLocationChange,
  handleRetractBid,
  loadingRetractBid,
  readOnly,
  isExpired,
}) => {
  const showToaster = useShowToaster();
  const { dot, token, type } = useParams();
  const [contactOptions, setContactOptions] = useState([]);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [openDeclineShipment, setOpenDeclineShipment] = useState(false);
  const [eta, setEta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [cargoToView, setCargoToView] = useState(null);
  const { offer_amount, planner, note, carrier_id, shipment_id, bid, confirmation_url } = shipment || {};
  const emptyMiles = Math.round(eta?.empty_miles || 0);
  const loadedMiles = Math.round(planner?.loaded_miles || 0);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const shipment_stops_schedule_dates = {};
      values.stops.forEach((item) => {
        shipment_stops_schedule_dates[item.id] =
          item.can_handle === 0 ? moment(item.new_eta).format('YYYY-MM-DD HH:mm:ss') : null;
      });

      const body = {
        shipment_id,
        carrier_id,
        selected_contact_user_id: values.contact.id,
        offer_amount: Number(offer_amount),
        bid_amount: Number(values.bid_amount),
        state: values.state.short_name,
        city: values.city.name,
        offer_accepted: 1,
        driver_available_from: moment(values.driver_available_from).format('YYYY-MM-DD HH:mm:ss'),
        shipment_stops_schedule_dates: JSON.stringify(shipment_stops_schedule_dates),
        note: values.note,
        handled: values.stops.every((item) => !!item.can_handle),
      };

      await createBid(body, { dot, token, type: type === 'contact' ? 'contact' : undefined });
      showToaster({ type: 'success', message: 'Bid has been successfully submitted!' });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, handleBlur } = useForm({
    initialValues: getInitialValues(shipment),
    onSubmit,
    validationSchema,
  });

  const getContactUser = async () => {
    try {
      const { data } = await getOfferContactUser({}, { dot, token, type });
      if (data) {
        handleChange('contact', data);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getContactUsers = async () => {
    try {
      const { data } = await getCarrierContactBookByToken(dot, token, type === 'contact' ? 'contact' : undefined);
      setContactOptions(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getGeoLocation = async (zipcode) => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          zip: zipcode,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                state: el?.Address?.State,
                city: el?.Address?.City,
              };
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          showToaster({ type: 'error', message: 'Could not find location' });
          resolve(null);
        },
      });
    });
  };

  const getEtaToStop = async (data, zipcode) => {
    const { state, city } = data || {};

    const body = zipcode ? { shipment_id, zipcode } : { shipment_id, state: state.short_name, city: city.name };

    try {
      const { data, success } = await getEta(body, { dot, token, type });

      if (zipcode) {
        const response = await getGeoLocation(zipcode);
        const { state, city } = response || {};

        if ((!state && !city) || success === false) {
          showToaster({ type: 'error', message: 'Location not found!' });
        }

        if (state) {
          if (city) {
            handleChange('address', `${city} ${state}`);
          } else {
            handleChange('address', `${state}`);
          }
        }
      }

      setEta(data);
      if (data.latitude && data.longitude) {
        onStartingLocationChange({ latitude: data.latitude, longitude: data.longitude, empty_miles: data.empty_miles });
      }
      const estimatedTimeToFirstStop = moment(values.driver_available_from).add(data.minutes, 'minutes');

      const canHandle = estimatedTimeToFirstStop.isBefore(
        `${planner.shipment_stops[0].scheduled_date} ${planner.shipment_stops[0].from}`
      );
      if (!canHandle) {
        handleChange(`stops[0].can_handle`, 0);
        handleChange(`stops[0].new_eta`, estimatedTimeToFirstStop.toDate());
        handleChange(`stops[0].disabled`, true);
        setEstimatedTime(estimatedTimeToFirstStop.toDate());
      } else {
        handleChange(`stops[0].disabled`, false);
        setEstimatedTime(null);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onDriverAvailableChange = (date, index) => {
    if (values.stops[index]?.disabled && estimatedTime) {
      const isBefore = moment(date).isBefore(estimatedTime);
      handleChange(`stops[${index}].new_eta`, isBefore ? estimatedTime : date);
    } else {
      handleChange(`stops[${index}].new_eta`, date);
    }
  };

  const onAddressSelect = ({ state, city }) => {
    handleChange('state', state);
    handleChange('city', city);
    getEtaToStop({ state, city });
  };

  const changeAddress = ({ formatted_address }) => {
    handleChange('address', formatted_address);
  };

  useEffect(() => {
    getContactUsers();
    if (bid?.state) {
      getEtaToStop({ city: bid.city, state: bid.state });
    }

    if (type !== 'contact') {
      return;
    }
    getContactUser();
  }, []);

  useEffect(() => {
    const channelName = `private-shipment-carrier-auto-dispatch-update.${dot}-${values.contact?.id}-${planner?.shipment_id}`;

    if (planner && values.contact?.id) {
      const channel = PusherJs.subscribe(channelName);
      channel.bind('shipment-carrier-auto-dispatch-update', (data) => {
        if (data.message?.data?.url) {
          window.open(data.message?.data?.url, '_self');
        }
      });
    }

    return () => {
      if (!shipment || !values.contact?.id) {
        return;
      }

      PusherJs.unsubscribe(channelName);
    };
  }, [shipment, values.contact]);

  return (
    <SSubmitBid $expanded={isExpired}>
      <div className='form-wrapper'>
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              SELECT CONTACT
            </Typography>
          </div>
          <div className='d-flex gap-3'>
            <div>
              <Autocomplete
                width='180px'
                size='small'
                labelKey='contact_name'
                name='contact'
                options={contactOptions}
                value={values.contact}
                onChange={(e, val) => handleChange('contact', val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={readOnly || isExpired || type === 'contact'}
                error={touchedErrors.contact}
              />
            </div>
            {values.contact?.contact_type_id === 1 && !readOnly && (
              <Typography
                variant='b2'
                style={{ color: palette.gray700 }}
                className='d-flex align-items-center gap-1 nowrap pointer'
                onClick={() => setOpenAddContact(true)}
              >
                <PlusIcon fill={palette.indigo500} /> Manage
              </Typography>
            )}
          </div>
        </div>
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              STARTING LOCATION
            </Typography>
          </div>
          <div className='d-flex flex-wrap gap-2'>
            <div>
              <AddressAutocomplete
                width='200px'
                size='small'
                id='address'
                name='address'
                placeholder='Search State/City'
                onSuccess={(data) => onAddressSelect(data)}
                changeAddress={(data) => changeAddress(data)}
                onChange={handleChange}
                value={values.address}
                options={{
                  types: ['locality', 'administrative_area_level_1', 'country', 'postal_code'],
                  componentRestrictions: { country: ['us', 'ca', 'mx'] },
                }}
                error={touchedErrors.state || touchedErrors.city}
              />
            </div>
          </div>
        </div>
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              BID RATE AMOUNT
            </Typography>
          </div>
          <div>
            <InputWithIcon
              type='number'
              width='140px'
              size='small'
              name='bid_amount'
              placeholder='0.00'
              value={values.bid_amount}
              onChange={handleChange}
              onKeyDown={blockNonPositiveNumbers}
              error={touchedErrors.bid_amount}
              disabled={readOnly || isExpired}
            />
          </div>
        </div>
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              RATE PER MILE
            </Typography>
          </div>
          <div>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              $
              {formatNumber(
                (values?.bid_amount ? Number(values.bid_amount) : offer_amount) / (emptyMiles + loadedMiles)
              )}
            </Typography>{' '}
          </div>
        </div>
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              EMPTY MILES
            </Typography>
          </div>
          <div>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {emptyMiles || 0}
            </Typography>{' '}
          </div>
        </div>
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              LOADED MILES
            </Typography>
          </div>
          <div>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {loadedMiles || 0}
            </Typography>{' '}
          </div>
        </div>
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              SHIPMENT NOTES
            </Typography>
          </div>
          <div className='flex-grow-1'>
            <Typography variant='c2' style={{ color: palette.gray500 }}>
              {note || '-'}
            </Typography>
          </div>
        </div>
        <Divider margin='16px 0' />
        <div>
          <div>
            <STimeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {!!eta && (
                <TimelineItem>
                  <TimelineSeparator>
                    <STimelineDot $bgColor={palette.goldDark}>
                      <div>D</div>
                    </STimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className='timeline-content'>
                      <div className='d-flex flex-column'>
                        <Typography variant='b2' className='nowrap'>
                          {values.city?.name}, {values.state?.short_name}
                        </Typography>
                        {values.driver_available_from && (
                          <Typography variant='c2' className='nowrap' style={{ color: palette.gray700 }}>
                            {moment(values.driver_available_from).format('MM/DD/YYYY HH:mm')} (Truck Available)
                          </Typography>
                        )}
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              )}
              {planner.shipment_stops?.map((stop, index) => (
                <TimelineItem key={stop.id}>
                  <TimelineSeparator>
                    <STimelineDot $bgColor={STOP_POINT_TYPES[stop.stop_point_type].bgColor}>
                      <div>{getAlphabet(index)}</div>
                    </STimelineDot>
                    {index !== planner.shipment_stops.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className='timeline-content'>
                      <div className='d-flex flex-column'>
                        <Typography variant='b2' className='nowrap'>
                          {stop.stop_point?.city?.name}, {stop.stop_point?.state?.short_name}
                        </Typography>
                        <Typography variant='c2' className='nowrap' style={{ color: palette.gray700 }}>
                          {moment(stop.scheduled_date).format('MM/DD/YYYY')} {stop.from}
                          {stop?.to && stop?.to !== stop.from && ` - ${stop.to}`} (
                          {SCHEDULED_DATE_TYPE_OBJ[Number(stop.scheduled_type)]?.shortName})
                        </Typography>
                        {stop.equipment_type_data && (
                          <Typography variant='c2' className='nowrap' style={{ color: palette.gray700 }}>
                            {stop.equipment_type_length_value?.length}{' '}
                            {getEquipmentRequiredTitle(stop.equipment_type_data)?.title} ({equipmentAction(stop)})
                          </Typography>
                        )}
                        {Number(stop.stop_point_type) === 1 && (
                          <Typography variant='c2' className='nowrap' style={{ color: palette.gray700 }}>
                            {stop.weight} LBS{' '}
                            <Typography
                              variant='c2'
                              className='nowrap'
                              style={{
                                color: stop.cargos?.length > 1 ? palette.indigo500 : palette.gray700,
                                cursor: stop.cargos?.length > 1 ? 'pointer' : '',
                              }}
                              onClick={() =>
                                stop.cargos?.length > 1 &&
                                setCargoToView(
                                  stop.cargos.map((item) => ({
                                    ...item,
                                    expected_quantity_type: item.expected_quantity_type_data,
                                    expected_weight_type: item.expected_weight_type_data,
                                    expected_dimension_unit: item.expected_dimension_unit_data,
                                    expected_dimensions:
                                      typeof item.expected_dimensions === 'string'
                                        ? JSON.parse(item.expected_dimensions)
                                        : null,
                                  }))
                                )
                              }
                            >
                              {stop.cargos?.length > 1 ? (
                                <span>
                                  Multiple Commodities - <span className='text-decoration-underline'>See Cargo</span>
                                </span>
                              ) : (
                                `(${stop.commodity_type?.title})`
                              )}
                            </Typography>
                          </Typography>
                        )}
                      </div>
                      <div className='d-flex flex-column gap-2 can-handle-box'>
                        <Typography variant='b2' style={{ color: palette.gray700 }}>
                          Can You Handle This ETA?
                        </Typography>
                        <div className='d-flex gap-3'>
                          <Radio
                            value={1}
                            checked={values.stops[index]?.can_handle === 1}
                            onChange={() => handleChange(`stops[${index}].can_handle`, 1)}
                            label='Yes'
                            labelColor={palette.gray700}
                            disabled={readOnly || isExpired || values.stops[index]?.disabled}
                          />
                          <Radio
                            value={0}
                            checked={values.stops[index]?.can_handle === 0}
                            onChange={() => handleChange(`stops[${index}].can_handle`, 0)}
                            label='No'
                            labelColor={palette.gray700}
                            disabled={readOnly || isExpired}
                          />
                        </div>
                        {values.stops[index]?.can_handle === 0 && (
                          <div className='d-flex gap-2'>
                            <DateTimePicker
                              size='small'
                              name={`stops[${index}].new_eta`}
                              value={values.stops[index]?.new_eta || null}
                              minDate={values.stops[index]?.disabled ? values.stops[index]?.new_eta : moment().toDate()}
                              onChange={(val) => onDriverAvailableChange(val, index)}
                              disabled={readOnly || isExpired}
                              onBlur={handleBlur}
                              error={touchedErrors.stops?.[index]?.new_eta}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </STimeline>
          </div>
        </div>
        <Divider margin='16px 0' />
        <div className='form-row'>
          <div>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              CARRIER NOTES
            </Typography>
          </div>
          <Textarea
            className='w-100'
            required={values.stops.some((item) => item.can_handle === 0)}
            rows={3}
            placeholder='Write your notes here...'
            value={values.note}
            onChange={(e) => handleChange('note', e.target.value)}
            error={touchedErrors.note}
            disabled={readOnly || isExpired}
          />
        </div>
      </div>
      {bid?.offer_accepted === 1 && (
        <div className='submit-bid-buttons'>
          <CustomButton
            type='primary'
            title='Retract Bid'
            styleTitle={{ fontSize: 14, fontWeight: 500, whiteSpace: 'nowrap' }}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            onClick={handleRetractBid}
            disabled={loadingRetractBid}
          />
        </div>
      )}
      {bid?.offer_accepted === 2 && (
        <div className='submit-bid-buttons'>
          <CustomButton
            type='primary'
            title='Retract Decline'
            styleTitle={{ fontSize: 14, fontWeight: 500, whiteSpace: 'nowrap' }}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            onClick={handleRetractBid}
            disabled={loadingRetractBid}
          />
        </div>
      )}
      {!bid?.offer_accepted && !isExpired && (
        <div className='submit-bid-buttons'>
          <CustomButton
            type='secondary'
            title='Decline Shipment'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            onClick={() => setOpenDeclineShipment(true)}
            disabled={loading}
          />
          <CustomButton
            type='primary'
            title='Submit Bid'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            leftIcon={<OfferIcon fill={palette.white} style={{ marginRight: 10 }} />}
            onClick={handleSubmit}
            disabled={loading}
          />
        </div>
      )}
      {bid?.offer_accepted === 4 && !!confirmation_url && (
        <div className='submit-bid-buttons'>
          <CustomButton
            type='primary'
            title='Continue to Confirmation'
            styleTitle={{ fontSize: 14, fontWeight: 500, whiteSpace: 'nowrap' }}
            styleButton={{ margin: 0, padding: '6px 12px' }}
            onClick={() => window.open(confirmation_url, '_self')}
            className='confirmation-button'
          />
        </div>
      )}
      {openAddContact && (
        <ContactBook
          open={openAddContact}
          onClose={() => setOpenAddContact(false)}
          onSuccess={getContactUsers}
          carrierId={carrier_id}
          dot={dot}
          token={token}
          type={type}
        />
      )}
      {openDeclineShipment && (
        <DeclineShipment
          open={openDeclineShipment}
          onClose={() => setOpenDeclineShipment(false)}
          dot={dot}
          token={token}
          type={type}
          shipment={shipment}
          formValues={values}
          onSuccess={onDeclineSuccess}
        />
      )}
      {!!cargoToView && (
        <ShipmentCommodity open={!!cargoToView} onClose={() => setCargoToView(null)} cargo={cargoToView} readOnly />
      )}
    </SSubmitBid>
  );
};

export default SubmitBid;
