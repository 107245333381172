export const getInitialValues = ({ selectedShipment }) => {
  return {
    shipment_related: selectedShipment ? 1 : 0,
    report_shipment: selectedShipment || null,
    shipment_commodities_hauled: '',
    shipment_under_dispatch: '',
    shipment_shipper: null,
    shipment_consignee: null,
    shipment_hazmat: '',
    shipment_hazmat_class: '',
    shipment_hazmat_release: '',
    shipment_hazmat_release_details: '',
    shipment_hazmat_disposal_details: '',
    shipment_hazmat_disposal_attachment: null,
    shipment_cargo_damage: '',
    shipment_cargo_damage_details: '',
  };
};
