import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { getStatsGraph } from 'Api/AccountingReceivables';
import StatBox from 'pages/Accounting/Receivables/StatsSection/components/StatBox';
import { initialStats, initialYearFilters, statTypeRangeMapper, statsDataConverter } from './AccountsReceivable.data';
import { SSectionHeader } from '../../Dashboard.styles';

const AccountsReceivable = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(initialStats);
  const [yearFilters, setYearFilters] = useState(initialYearFilters);
  const didMountRef = useRef(false);

  const getStats = async () => {
    try {
      const params = {
        miles_from_date: moment(yearFilters.miles_range.start).format('YYYY-MM-DD'),
        miles_to_date: moment(yearFilters.miles_range.end).format('YYYY-MM-DD'),
        revenue_from_date: moment(yearFilters.revenue_range.start).format('YYYY-MM-DD'),
        revenue_to_date: moment(yearFilters.revenue_range.end).format('YYYY-MM-DD'),
        closed_from_date: moment(yearFilters.closed_range.start).format('YYYY-MM-DD'),
        closed_to_date: moment(yearFilters.closed_range.end).format('YYYY-MM-DD'),
        graphReadyAndMissingYear: yearFilters.graphReadyAndMissingYear,
        graphOpenReceivablesYear: yearFilters.graphOpenReceivablesYear,
        graphOpenReceivablesMonth: yearFilters.graphOpenReceivablesMonth,
        closed_month: yearFilters.closed_month,
      };

      const { data } = await getStatsGraph(params);

      const convertedData = statsDataConverter(data);
      setStats(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onRangeChange = (key, range) => {
    setYearFilters((prevState) => ({ ...prevState, [key]: range }));
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
      didMountRef.current = true;
    }
    getStats();
  }, [yearFilters]);

  return (
    <div className='d-flex flex-column gap-2'>
      <SSectionHeader>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Accounts Receivable
        </Typography>
      </SSectionHeader>
      <div className='scrollable-container'>
        <div className='stat-boxes-wrapper'>
          {Object.keys(stats || {})?.map((key) => (
            <StatBox
              key={key}
              title={key}
              data={stats[key]}
              loading={loading}
              range={yearFilters[statTypeRangeMapper[key]]}
              onRangeChange={(date) => onRangeChange(statTypeRangeMapper[key], date)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountsReceivable;
