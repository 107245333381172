import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray } from 'formik';
import { useTheme } from 'context/themeContext';
import { getAlphabet, palette } from 'utils/constants';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { getEquipmentDimensions } from 'Api/EquipmentImport';
import sm from '../../ShipmentStops/AddStopPoint/AddStopPoint.module.css';

import PickUpLTL from './PickUpLTL';
import DeliveryLTL from './DeliveryLTL';
import WaypointLTL from './WaypointLTL';
import { Typography } from '../../../Typography';
import { ShipmentContext } from '../../Layout';

import { PopoverCreatShipmentItem } from '../../ShipmentStops/helpers/PopoverCreatShipment';

const AddStopPointLTL = ({
  leg,
  legs,
  namePrefix,
  stopPoints,
  legsStopsCount,
  indexPrefix,
  errors,
  touched,
  optimizeRoute,
  arrayHelpersPrefix,
  createShipmentData,
  billToData,
  values,
  setValues,
  setFieldTouched,
  setFieldValue,
}) => {
  const { use } = useTheme();
  const [dimensions, setDimensions] = useState([]);
  const { equipmentRequired, shipmentSettings } = useContext(ShipmentContext);
  const prevStopsCount = useMemo(() => {
    const prevStopsCountArr = legsStopsCount.slice(0, indexPrefix);
    return prevStopsCountArr.length ? prevStopsCountArr.reduce((a, b) => a + b, 0) : 0;
  }, [legsStopsCount, indexPrefix]);

  const getStopComponent = useCallback((type) => {
    const TYPE_STOPS = {
      1: PickUpLTL,
      2: DeliveryLTL,
      3: WaypointLTL,
    };
    return TYPE_STOPS[type];
  }, []);

  const getStopName = useCallback((type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: use(palette.green500, palette.green400) },
      2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500) },
      3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText) },
    };
    return TYPE_STOPS[type];
  }, []);

  const getDimensions = async () => {
    try {
      const { data } = await getEquipmentDimensions();
      setDimensions(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDimensions();
  }, []);

  return (
    <div className={sm.addStopPoint_container}>
      <div className={sm.legNumber_wrapper} style={{ backgroundColor: use(palette.indigo50, palette.indigo200) }}>
        <Typography variant='s2' style={{ color: palette.indigo500 }}>
          {indexPrefix + 1}
        </Typography>
      </div>
      <FieldArray
        name={`${namePrefix}.stops`}
        render={(arrayHelpers) => {
          return (
            <div className={sm.stops_wrapper} style={{ marginRight: optimizeRoute ? 450 : 0 }}>
              {leg.stops.map((stop, index) => {
                const StopComponent = getStopComponent(stop.stop_point_type);
                const { customer_id } = createShipmentData.shipmentsBilling[indexPrefix];
                const customer = billToData.find((el) => +el.id === +customer_id);
                const lengthOptions = dimensions.reduce((acc, cur) => {
                  if (Number(cur.equipment_type_id) === Number(stop.equipment_type) && cur.length_info) {
                    acc.push({
                      ...cur,
                      key: cur.length_info.id,
                      label: `${cur.length_info.length} ${cur.length_info.unit}`,
                      labelSelected: `${cur.length_info.length} ${cur.length_info.unit}`,
                    });
                  }
                  return acc;
                }, []);
                return (
                  <div
                    key={stop.id}
                    style={{
                      width: 710,
                      borderRadius: '4px 4px 0 0',
                      backgroundColor: use(palette.gray0, palette.dark900),
                    }}
                  >
                    <div
                      className={`
                                             ${sm.pickUp_title_wrapper}
                                             ${
                                               index === 0
                                                 ? sm.first_child
                                                 : leg.stops.length - 1 === index
                                                 ? sm.last_child
                                                 : ''
                                             }
                                            `}
                      style={{
                        backgroundColor: use(palette.gray0, palette.dark900),
                        borderColor: use(palette.gray50, palette.gray400),
                      }}
                    >
                      <Typography
                        variant='c1'
                        className={sm.pickUp_title}
                        style={{ color: getStopName(stop.stop_point_type).color }}
                      >
                        STOP {getAlphabet(prevStopsCount + index)} {getStopName(stop.stop_point_type).type}
                      </Typography>
                      <PopoverCreatShipmentItem
                        stop={stop}
                        legs={legs}
                        id={stop.id}
                        index={index}
                        stops={leg.stops}
                        deletePopover
                        waypointValidate={false}
                        indexPrefix={indexPrefix}
                        arrayHelpers={arrayHelpers}
                        arrayHelpersPrefix={arrayHelpersPrefix}
                        shipmentSettings={shipmentSettings}
                        iconComponent={
                          <ArrowDown
                            height={16}
                            fill={use(palette.gray400, palette.gray700)}
                            style={{ transform: index === 0 && 'translateX(-40px)' }}
                          />
                        }
                      />
                      <Typography variant='s2' className={sm.customerName}>
                        {customer?.company_name}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        borderRight: `1px solid ${palette.gray100}`,
                      }}
                    >
                      <div
                        className={sm.stops_wrapper_item}
                        style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
                      >
                        <StopComponent
                          stop={stop}
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          setValues={setValues}
                          prevStop={leg.stops[index - 1]}
                          index={index}
                          indexPrefix={indexPrefix}
                          allPrevStops={leg.stops}
                          stopPoints={stopPoints}
                          arrayHelpers={arrayHelpers}
                          namePrefix={`${namePrefix}.stops`}
                          touched={touched}
                          errors={errors}
                          lastChild={leg.stops.length - 1}
                          equipmentLengthData={lengthOptions}
                          equipmentRequired={equipmentRequired}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};

export default AddStopPointLTL;
