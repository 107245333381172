import { array, date, number, object, string } from 'yup';
import moment from 'moment';
import {
  validationSchema as cargosValidationSchema,
  singleCargoValidationSchema,
} from 'componentsV2/Commodity/ShipmentCommodity/validationSchema';
import { validator } from 'utils/helpers';

export const validationSchema = (frequency, allowPastDate) =>
  object().shape({
    legs: array().of(
      object().shape({
        stops: array().of(
          object().when([], (_, schema) => {
            if (Number(schema?.value?.stop_point_type) === 1) {
              return object().shape({
                stop_point_id: string().trim().required('Required').nullable(),
                scheduled_type: string().trim().required('Required').nullable(),
                scheduled_date: allowPastDate ? validator.requiredDate() : validator.futureRequiredDate(),
                scheduled_date_to:
                  frequency !== 2
                    ? date().when('scheduled_type', {
                        is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
                        then: validator
                          .requiredDate()
                          .test('Invalid', 'Start date cannot be after end date', (value, context) => {
                            const { scheduled_date, startTime: from, endTime: to } = context.parent || {};
                            if (
                              !scheduled_date ||
                              !from ||
                              !to ||
                              !moment(scheduled_date).isValid() ||
                              !moment(from).isValid() ||
                              !moment(to).isValid()
                            ) {
                              return true;
                            }

                            return moment(
                              `${moment(scheduled_date).format('YYYY-MM-DD')} ${moment(from).format('HH:mm')}`
                            ).isSameOrBefore(`${moment(value).format('YYYY-MM-DD')} ${moment(to).format('HH:mm')}`);
                          }),
                        otherwise: date().nullable(),
                      })
                    : date().nullable(),
                startTime: frequency !== 2 ? validator.requiredDate() : date().nullable(),
                endTime:
                  frequency !== 2
                    ? date().when('scheduled_type', {
                        is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
                        then: validator.requiredDate(),
                        otherwise: date().nullable(),
                      })
                    : date().nullable(),
                // frequency !== 2
                //   ? date().when('scheduled_type', {
                //       is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
                //       then: string()
                //         .trim()
                //         .required('Required')
                //         .test('Invalid', 'Invalid', (value, context) => {
                //           const fromDate = context.parent.scheduled_date;
                //           const fromTime = context.parent.startTime;
                //           const toDate = context.parent.scheduled_date_to;
                //           if (
                //             fromDate &&
                //             fromTime &&
                //             fromTime?.split(':')?.every((i) => !Number.isNaN(Number(i))) &&
                //             toDate
                //           ) {
                //             return (
                //               value?.split(':')?.every((i) => !Number.isNaN(Number(i))) &&
                //               moment(`${fromDate} ${fromTime}`).isSameOrBefore(`${toDate} ${value}`)
                //             );
                //           }
                //           return true;
                //         })
                //         .nullable(),
                //     })
                //   : string().nullable(),
                equipment_action: number().required('Required').typeError('Required').nullable(),
                equipment_type: number().required('Required').typeError('Required').nullable(),
                equipment_id: string().when(['equipment_action', 'equipment_tbd', 'thirdParty'], {
                  is: (equipment_action, equipment_tbd, thirdParty) =>
                    !thirdParty && !equipment_tbd && [2, 3].includes(Number(equipment_action)),
                  then: string().trim().required('Required').nullable(),
                  otherwise: string().nullable(),
                }),
                equipment_type_length: string().when('equipment_type', {
                  is: (equipment_type) => Number(equipment_type) >= 12,
                  then: string().trim().required('Required').nullable(),
                }),
                cargos: array().when([], (array, schema) => {
                  if (schema?.value?.length === 1) {
                    return singleCargoValidationSchema;
                  }
                  return cargosValidationSchema;
                }),
              });
            }

            if ([2, 3].includes(Number(schema?.value?.stop_point_type))) {
              return object().shape({
                stop_point_id: string().trim().required('Required').nullable(),
                scheduled_type: string().trim().required('Required').nullable(),
                scheduled_date: allowPastDate ? validator.requiredDate() : validator.futureRequiredDate(),
                scheduled_date_to:
                  frequency !== 2
                    ? date().when('scheduled_type', {
                        is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
                        then: validator
                          .requiredDate()
                          .test('Invalid', 'Start date cannot be after end date', (value, context) => {
                            const { scheduled_date, startTime: from, endTime: to } = context.parent || {};
                            if (
                              !scheduled_date ||
                              !from ||
                              !to ||
                              !moment(scheduled_date).isValid() ||
                              !moment(from).isValid() ||
                              !moment(to).isValid()
                            ) {
                              return true;
                            }

                            return moment(
                              `${moment(scheduled_date).format('YYYY-MM-DD')} ${moment(from).format('HH:mm')}`
                            ).isSameOrBefore(`${moment(value).format('YYYY-MM-DD')} ${moment(to).format('HH:mm')}`);
                          }),
                        otherwise: date().nullable(),
                      })
                    : date().nullable(),
                startTime: frequency !== 2 ? validator.requiredDate() : date().nullable(),
                endTime:
                  frequency !== 2
                    ? date().when('scheduled_type', {
                        is: (scheduled_type) => [1, 3].includes(Number(scheduled_type)),
                        then: validator.requiredDate(),
                        otherwise: date().nullable(),
                      })
                    : date().nullable(),
                equipment_action: number().required('Required').typeError('Required').nullable(),
                equipment_id:
                  Number(schema?.value?.stop_point_type) === 3
                    ? string().when(['equipment_action', 'equipment_tbd', 'thirdParty'], {
                        is: (equipment_action, equipment_tbd, thirdParty) =>
                          !thirdParty && !equipment_tbd && Number(equipment_action) === 3,
                        then: string().trim().required('Required').nullable(),
                        otherwise: string().nullable(),
                      })
                    : undefined,
              });
            }
          })
        ),
      })
    ),
  });
