import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import Tab from '@mui/material/Tab';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/exportIcon.svg';
import Search from 'common/Search';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import ThreeDotActions from 'common/ThreeDotActions';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import AddNotes from 'pages/Accounting/Receivables/TableSection/shared/AddNotes';
import StandaloneInvoiceNotes from 'pages/Accounting/Receivables/TableSection/shared/StandaloneInvoiceNotes';
import {
  getPaidInvoicesTableData,
  getPaidStandaloneInvoice,
  refundAndRevertPaidInvoices,
} from 'Api/AccountingReceivables';
import { getErrorMessage } from 'utils/error';
import BillingCharges from './components/BillingCharges';
import OtherRevenueTable from './components/OtherRevenueTable';
import ShipmentInvoiceTable from './components/ShipmentInvoiceTable';
import { filterOptions, initialFilters, onExportClick, otherRevenueFilterOptions } from '../TableSection.data';
import { useColumns, useOtherColumns } from './PaidInvoices.data';
import { SFiltersWrapper, SSearchWrapper, STabs } from '../../Receivables.styles';

const PaidInvoices = ({ stopPoints, customers, groups, tableRefreshIndex }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [activeTab, setActiveTab] = useState('shipment');
  const [data, setData] = useState({ data: [] });
  const [otherData, setOtherData] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState('');
  const [openViewPDF, setOpenViewPDF] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [openStandaloneNotes, setOpenStandaloneNotes] = useState(false);
  const [search, setSearch] = useState('');
  const [otherSearch, setOtherSearch] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortByDate, setSortByDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otherLoading, setOtherLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const otherDebouncedSearch = useDebounce(otherSearch, 500);
  const [filterType, setFilterType] = useState(filterOptions[0]);
  const [otherFilterType, setOtherFilterType] = useState(otherRevenueFilterOptions[0]);
  const [sort, setSort] = useState({ field: 'customer_payment_date', sortBy: 'desc' });
  const [otherSort, setOtherSort] = useState({ field: 'age', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [otherSelectedFilters, setOtherSelectedFilters] = useState(initialFilters);
  const [billingCharges, setBillingCharges] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [invoiceToRevert, setInvoiceToRevert] = useState(null);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const getPaidShipmentInvoices = async () => {
    try {
      setLoading(true);
      const { page, itemsPerPage } = selectedFilters || {};
      const filter = `filters[${filterType.value}]`;
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page,
        itemsPerPage,
        [filter]: debouncedSearch || undefined,
        'sort[][end_date_time]': sortByDate || undefined,
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[to]': dateRange.start ? moment(dateRange.end.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[is_brokered]': selectedFilters.is_brokered ? 1 : undefined,
        'filters[customer_id]': selectedFilters.customers?.length
          ? selectedFilters.customers.map((i) => i.id)
          : undefined,
        'filters[group_id]': selectedFilters.groups?.length ? selectedFilters.groups.map((i) => i.id) : undefined,
        'filters[stop_point_id]': selectedFilters.stopPoints?.length
          ? selectedFilters.stopPoints.map((i) => i.id)
          : undefined,
        [sortField]: sort.sortBy,
      };

      const response = await getPaidInvoicesTableData(params);
      setData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getPaidOtherInvoices = async () => {
    try {
      setOtherLoading(true);
      const { page, itemsPerPage, other_customers, groups } = otherSelectedFilters || {};
      const filter = `filters[${otherFilterType.value}]`;
      const sortField = `sort[][${otherSort.field}]`;
      const params = {
        page,
        itemsPerPage,
        [filter]: otherDebouncedSearch || undefined,
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[to]': dateRange.start ? moment(dateRange.end.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[is_brokered]': selectedFilters.is_brokered ? 1 : undefined,
        'filters[customer_id]': other_customers?.length ? other_customers.map((i) => i.id) : undefined,
        'filters[group_id]': groups?.length ? groups.map((i) => i.id) : undefined,
        [sortField]: otherSort.sortBy,
      };

      const response = await getPaidStandaloneInvoice(params);
      setOtherData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setOtherLoading(false);
    }
  };

  const onViewPDF = (row) => {
    setPdfUrl(row.pdf_file_link);
    setOpenViewPDF(true);
  };

  const refundAndRevertInvoice = async () => {
    try {
      setLoadingRevert(true);
      const body = {
        invoice_id: [invoiceToRevert.invoice_id],
      };
      await refundAndRevertPaidInvoices(body);
      showToaster({
        type: 'success',
        message: `Invoice has been successfully ${invoiceToRevert.is_stripe ? 'refunded and reverted' : 'reverted'}!`,
      });
      getPaidShipmentInvoices();
      getPaidOtherInvoices();
      setInvoiceToRevert(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingRevert(false);
    }
  };

  const onRefundAndRevert = (row) => {
    setInvoiceToRevert(row);
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    const otherDirection = otherSort?.sortBy === 'asc' ? 'desc' : 'asc';
    if (activeTab === 'shipment') {
      setSort({ field, sortBy: direction });
    } else {
      setOtherSort({ field, sortBy: otherDirection });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onViewNotes = (row) => {
    setModalData(row);
    if (activeTab === 'shipment') {
      setOpenAddNotes(true);
    } else {
      setOpenStandaloneNotes(true);
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== data?.data?.length ? data?.data : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  useEffect(() => {
    getPaidShipmentInvoices();
  }, [dateRange, selectedFilters, debouncedSearch, sortByDate, sort, tableRefreshIndex]);

  useEffect(() => {
    getPaidOtherInvoices();
  }, [otherSelectedFilters, otherDebouncedSearch, otherSort, tableRefreshIndex]);

  useEffect(() => {
    setSelectedRows([]);
  }, [activeTab]);

  const columns = useColumns({
    onViewPDF,
    sort,
    sortingQuery,
    onViewNotes,
    onRefundAndRevert,
    handleSelectRow,
    handleSelectAll,
    selectedRows,
    data: data?.data,
  });
  const otherColumns = useOtherColumns({ onViewPDF, sort: otherSort, sortingQuery, onViewNotes, onRefundAndRevert });

  return (
    <>
      {selectedRows.length ? (
        <div className='d-flex align-items-center gap-3 mb-4'>
          <CustomButton
            type='secondary'
            title='Export CSV'
            leftIcon={<ExportIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '5px 12px', marginTop: 0 }}
            onClick={() => onExportClick(selectedRows, 'paid')}
          />
        </div>
      ) : (
        <SFiltersWrapper>
          {activeTab === 'shipment' && (
            <>
              <ThreeDotActions
                anchorEl={filterAnchorEl}
                setAnchorEl={setFilterAnchorEl}
                actionButton={
                  <CustomButton
                    type='secondary'
                    title=''
                    leftIcon={
                      <TwoWaysIcon
                        width={16}
                        height={16}
                        fill={palette.gray700}
                        style={{ transform: 'rotate(90deg)' }}
                      />
                    }
                    styleButton={{ padding: '8px 12px', margin: 0 }}
                    onClick={() => null}
                  />
                }
                popoverStyles={{ margin: '10px 0 0 70px' }}
              >
                <div style={{ padding: '0 8px' }}>
                  <CustomRadioButton
                    field={{
                      name: 'sort_by_date',
                      value: 'desc',
                      onChange: () => setSortByDate('desc'),
                      checked: sortByDate === 'desc',
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700) }}>
                      Recent
                    </Typography>
                  </CustomRadioButton>
                  <CustomRadioButton
                    field={{
                      name: 'sort_by_date',
                      value: 'asc',
                      onChange: () => setSortByDate('asc'),
                      checked: sortByDate === 'asc',
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700) }}>
                      Oldest
                    </Typography>
                  </CustomRadioButton>
                </div>
              </ThreeDotActions>
              <DateRangePicker
                top='-168px'
                dateRange={dateRange}
                initialRangeName='All Time'
                setDateRange={setDateRange}
                type='allTime'
              />
            </>
          )}
          {activeTab === 'shipment' ? (
            <SSearchWrapper>
              <div>
                <Autocomplete
                  width='150px'
                  labelKey='label'
                  options={filterOptions}
                  value={filterType}
                  onChange={(e, val) => setFilterType(val)}
                />
              </div>
              {filterType?.value === 'customer' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='company_name'
                  disableClearable={false}
                  options={customers}
                  value={selectedFilters.customers}
                  placeholder={selectedFilters.customers?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, customers: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : filterType?.value === 'stop_point' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='location_name'
                  disableClearable={false}
                  options={stopPoints}
                  value={selectedFilters.stopPoints}
                  placeholder={selectedFilters.stopPoints?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, stopPoints: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : filterType?.value === 'group' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='group_name'
                  disableClearable={false}
                  options={groups}
                  value={selectedFilters.groups}
                  placeholder={selectedFilters.groups?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : (
                <Search search={search} onChange={setSearch} className='search-input' />
              )}
            </SSearchWrapper>
          ) : (
            <SSearchWrapper>
              <div>
                <Autocomplete
                  width='150px'
                  labelKey='label'
                  options={otherRevenueFilterOptions}
                  value={otherFilterType}
                  onChange={(e, val) => {
                    setOtherSearch('');
                    setOtherFilterType(val);
                    setOtherSelectedFilters(initialFilters);
                  }}
                />
              </div>
              {otherFilterType?.value === 'customer' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='company_name'
                  disableClearable={false}
                  options={customers}
                  value={otherSelectedFilters.other_customers}
                  placeholder={otherSelectedFilters.other_customers?.length ? '' : 'Select..'}
                  onChange={(e, val) =>
                    setOtherSelectedFilters((prevState) => ({ ...prevState, other_customers: val }))
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : otherFilterType?.value === 'group' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='group_name'
                  disableClearable={false}
                  options={groups}
                  value={otherSelectedFilters.groups}
                  placeholder={otherSelectedFilters.groups?.length ? '' : 'Select..'}
                  onChange={(e, val) => setOtherSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : (
                <Search search={otherSearch} onChange={setOtherSearch} className='search-input' />
              )}
            </SSearchWrapper>
          )}
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='is_brokered'
              checked={!!selectedFilters.is_brokered}
              onChange={(checked) => setSelectedFilters((prevState) => ({ ...prevState, is_brokered: checked }))}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Brokered Shipment Only
            </Typography>
          </div>
        </SFiltersWrapper>
      )}
      <div>
        <STabs value={activeTab} onChange={handleTabChange}>
          <Tab id='tab-1' value='shipment' label='Shipment' disableRipple />
          <Tab id='tab-2' value='other' label='Other' disableRipple />
        </STabs>
        {activeTab === 'shipment' ? (
          <ShipmentInvoiceTable
            data={data}
            loading={loading}
            columns={columns}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        ) : (
          <OtherRevenueTable
            data={otherData}
            loading={otherLoading}
            columns={otherColumns}
            selectedFilters={otherSelectedFilters}
            setSelectedFilters={setOtherSelectedFilters}
            onRowClick={(row) => setBillingCharges(row.standalone_invoice?.items)}
          />
        )}
      </div>
      {openAddNotes && (
        <AddNotes
          open={openAddNotes}
          onClose={() => setOpenAddNotes(false)}
          data={modalData.billing}
          onSuccess={getPaidShipmentInvoices}
        />
      )}
      {openStandaloneNotes && (
        <StandaloneInvoiceNotes
          open={openStandaloneNotes}
          onClose={() => setOpenStandaloneNotes(false)}
          invoice={modalData}
          onSuccess={getPaidOtherInvoices}
        />
      )}
      {!!billingCharges && (
        <BillingCharges open={!!billingCharges} onClose={() => setBillingCharges(null)} items={billingCharges} />
      )}
      {openViewPDF && (
        <ViewPdf title='View Invoice(s)' open={openViewPDF} pdfUrl={pdfUrl} onClose={() => setOpenViewPDF(false)} />
      )}
      {!!invoiceToRevert && (
        <ConfirmationModal
          width='510px'
          open={!!invoiceToRevert}
          onClose={() => setInvoiceToRevert(null)}
          headerTitle={invoiceToRevert.is_stripe ? 'Refund and Revert' : 'Revert'}
          text={`Are you sure you want to ${invoiceToRevert.is_stripe ? 'refund and revert' : 'revert'} invoice ${
            invoiceToRevert.invoice_id
          }?`}
          onConfirm={refundAndRevertInvoice}
          disabled={loadingRevert}
          buttonProps={{
            title: invoiceToRevert.is_stripe ? 'Refund and Revert' : 'Revert',
          }}
        />
      )}
    </>
  );
};

export default PaidInvoices;
