import * as yup from 'yup';
import { array, date, number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationBasicSchema = yup.object({
  fname: yup.string('').required('First name is required'),
  lname: yup.string().required('Last name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  dob: validator.pastDate(),
  hire_date: validator.pastDate(),
  photo_identification_expiration: validator.date(),
  phone_number: yup
    .string()
    .min(9, 'Enter valid number')
    .max(15, 'Enter valid number')
    .required('Phone number is required')
    .nullable(),
  title: yup.string().required('Job title and Department is required'),
  department_id: yup.string().required('Department is required'),
});

export const CompensationValidationSchema = yup.object({
  tax_id: yup.string().required('Tax ID is required'),
  tax_type_id: yup.string().required('Tax Type is required'),
  account_id: yup.string().required('Expense Account is required'),
  routing_confirmed: yup
    .string()
    .oneOf([yup.ref('routing'), null], 'Bank Routing must match')
    .nullable(),
  account_confirmed: yup
    .number()
    .oneOf([yup.ref('account'), null], 'Bank Account must match')
    .nullable(),
  per_hr_amt: number().when('per_hr', {
    is: (per_hr) => !!per_hr,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  flat_amt: number().when(['each_booked_shipment', 'flat_or_per'], {
    is: (each_booked_shipment, flat_or_per) => !!each_booked_shipment && Number(flat_or_per) === 1,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  percentage: number().when(['each_booked_shipment', 'flat_or_per'], {
    is: (each_booked_shipment, flat_or_per) => !!each_booked_shipment && Number(flat_or_per) === 2,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  commissions: array().when('sales_commission_option', {
    is: true,
    then: array().of(
      object().shape({
        customer: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        type: number().required('Required').nullable(),
        flat_per_shipment: number().when('type', {
          is: 1,
          then: number().required('Required').min(1, 'Required').nullable(),
          otherwise: number().nullable(),
        }),
        percentage_per_shipment: number().when('type', {
          is: 2,
          then: number().required('Required').min(1, 'Required').nullable(),
          otherwise: number().nullable(),
        }),
        percentage_after_driver_pay: number().when('type', {
          is: 3,
          then: number().required('Required').min(1, 'Required').nullable(),
          otherwise: number().nullable(),
        }),
        end_date: date().when('is_active', {
          is: (is_active) => !is_active,
          then: validator.futureRequiredDate(),
          otherwise: validator.date(),
        }),
      })
    ),
  }),
});

export const CompensationValidationSchemaStaffProfile = yup.object({
  tax_id: yup.string().required(''),
  tax_type_id: yup.string().required(''),
  routing: yup
    .string()
    .oneOf([yup.ref('routing_confirmed'), null], 'Bank Routing must match')
    .nullable(),
  routing_confirmed: yup
    .string()
    .oneOf([yup.ref('routing'), null], 'Bank Routing must match')
    .nullable(),
  account: yup
    .number()
    .oneOf([yup.ref('account_confirmed'), null], 'Bank Account must match')
    .nullable(),
  account_confirmed: yup
    .number()
    .oneOf([yup.ref('account'), null], 'Bank Account must match')
    .nullable(),
  per_hr_amt: number().when('per_hr', {
    is: (per_hr) => !!per_hr,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  flat_amt: number().when(['each_booked_shipment', 'flat_or_per'], {
    is: (each_booked_shipment, flat_or_per) => !!each_booked_shipment && Number(flat_or_per) === 1,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  percentage: number().when(['each_booked_shipment', 'flat_or_per'], {
    is: (each_booked_shipment, flat_or_per) => !!each_booked_shipment && Number(flat_or_per) === 2,
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
  account_id: number().required('Required').nullable(),
  commissions: array().when('sales_commission_option', {
    is: true,
    then: array().of(
      object().shape({
        customer: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        type: number().required('Required').nullable(),
        flat_per_shipment: number().when('type', {
          is: 1,
          then: number().required('Required').min(1, 'Required').nullable(),
          otherwise: number().nullable(),
        }),
        percentage_per_shipment: number().when('type', {
          is: 2,
          then: number().required('Required').min(1, 'Required').nullable(),
          otherwise: number().nullable(),
        }),
        percentage_after_driver_pay: number().when('type', {
          is: 3,
          then: number().required('Required').min(1, 'Required').nullable(),
          otherwise: number().nullable(),
        }),
        end_date: date().when('is_active', {
          is: (is_active) => !is_active,
          then: validator.futureRequiredDate(),
          otherwise: validator.date(),
        }),
      })
    ),
  }),
});

export const PolicyValidationSchema = object({
  excused_absences: string().required('Excused Attendance is required'),
  unexcused_absences: string().required('Unexcused Attendance is required'),
  vacations_allowed: string().required('Vacations is required'),
  absence_reset_based_on: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  compensation_policy_expiry_date: validator.date(),
});

export const documentsValidationSchema = object({
  addDocument: array().of(
    object().shape({
      exp_date: validator.date(),
    })
  ),
});
