import React from 'react';

const FirstStep = ({ value, nextStep }) => {
  return (
    <>
      <div className='modal-header-custom'>
        <h1 className='welcome-bg'>
          Welcome, {value.user?.first_name} {value.user?.last_name}!
        </h1>
        <p className='welcome-label'>Let’s get your profile created! Please confirm your details below are correct.</p>
      </div>
      <div className='modal-body-custom' style={{ flexDirection: 'row' }}>
        <div className='labels'>
          <p className='data-label'>Email:</p>
          <p className='data-label'>Company:</p>
          <p className='data-label'>Department:</p>
          <p className='data-label'>Title:</p>
          <p className='data-label'>Mobile Number:</p>
        </div>
        <div className='labels' style={{ alignItems: 'flex-start' }}>
          <p className='data-display'>{value.user.email ? value.user.email : 'N/A'}</p>
          <p className='data-display'>
            {value.user?.customer?.company_name ? value.user?.customer?.company_name : 'N/A'}
          </p>
          <p className='data-display'>
            {value.user?.department?.department_name ? value.user?.department?.department_name : 'Management'}
          </p>
          <p className='data-display'>{value.user?.title ? value.user.title : 'N/A'}</p>
          <p className='data-display'>{value.user?.phone_number ? value?.user?.phone_number : 'N/A'}</p>
        </div>
      </div>
      <div className='modal-footer-custom'>
        <button className='button-footer btn' onClick={nextStep}>
          Continue
        </button>
      </div>
    </>
  );
};

export default FirstStep;
