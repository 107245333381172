import React from 'react';
import { useSelector } from 'react-redux';
import FileCopy from '@material-ui/icons/FileCopy';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getAppUrl } from 'utils/helpers';
import ReferrerInfoActions from './components/ReferrerInfoActions';
import { SInfoActions, STextButton } from './ReferrersTable.styles';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
};

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useReferrersColumns = ({ onDeleteSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);

  const onCopyUrl = async (token) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dot = user?.customer?.dot;
    const url = getAppUrl();

    if (!url || !dot) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
      return;
    }

    try {
      await navigator.clipboard.writeText(`${url}/job-positions/${dot}?token=${token}`);
      showToaster({ type: 'success', message: 'URL copied successfully!' });
    } catch (err) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
    }
  };

  return [
    {
      field: 'referrer_name',
      title: <Title title='NAME' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
          {row.referral_name}
        </Typography>
      ),
    },
    {
      field: 'telephone',
      title: <Title title='PHONE OR EMAIL' />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.telephone || row.email}
          </Typography>
        );
      },
    },
    {
      field: 'commission',
      title: <Title title='COMMISSION' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.commission ? `${currency} ${row.commission}` : '-'}
        </Typography>
      ),
    },
    {
      field: '',
      render: (row) => (
        <SInfoActions>
          <STextButton startIcon={<FileCopy />} onClick={() => onCopyUrl(row.url)}>
            Copy URL
          </STextButton>
          <ReferrerInfoActions onDeleteSuccess={onDeleteSuccess} referrer={row} />
        </SInfoActions>
      ),
    },
  ];
};
