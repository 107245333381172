import React, { useEffect, useState } from 'react';
import { FastField } from 'formik';
import { getEquipmentIdInTrailers } from 'Api/Planner';
import { ReactComponent as CalendarIcon } from 'assets/icons/createShipment/calendar.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import useDateFormat from 'hooks/useDateFormat';
import classes from '../checkInOut.module.scss';

const WayPointInModal = ({ values, touched, errors, stop, setFieldValue, shipmentSettings }) => {
  const { formatDate, formatDateTime, dateFormat } = useDateFormat();
  const [equipmentId, setEquipmentId] = useState([]);

  function getEquipmentId() {
    const length_id = stop?.equipment_type_length_value?.length;
    return getEquipmentIdInTrailers({ status_id: '4,6', length_id, itemsPerPage: 1000 }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `
                                     ${el?.equipment_id || ''}
                                    (${el?.make || ''}
                                     ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setEquipmentId(equipmentId);
      }
    });
  }

  useEffect(() => {
    getEquipmentId().then();
  }, []);

  const shouldUpdateStops = (nextProps, props, name) => {
    const nextError = nextProps.formik.errors?.equipment_id;
    switch (name) {
      case 'equipment_id':
        return nextError?.options !== props?.options;
      case 'drop_id':
        return nextError?.options !== props?.options;
      default:
    }
  };

  return (
    <div className={classes.globalWrapper}>
      <div className={classes.datesWrapper}>
        <div className={classes.datePickerDataWrapper}>
          <Typography variant='s2' className='mb-2'>
            Scheduled Date/Time
          </Typography>
          <div className={classes.datePickerData}>
            <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
            <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
              {stop?.scheduled_date && formatDate(stop.scheduled_date)}, {stop?.from} {stop?.to ? `to ${stop?.to}` : ''}
            </Typography>
          </div>
        </div>

        <div className={classes.errorWrapper}>
          {stop.arrival_date ? (
            <div className={classes.datePickerDataWrapper}>
              <Typography variant='s2' className='mb-2'>
                Check In
              </Typography>
              <div className={classes.datePickerData}>
                <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
                <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
                  {stop?.arrival_date ? formatDateTime(stop.arrival_date, true) : ''}
                </Typography>
              </div>
            </div>
          ) : (
            <DateTimePicker
              label='Check In'
              name='arrival_date'
              value={values.arrival_date}
              onChange={(val) => setFieldValue('arrival_date', val)}
              disabled={!!stop.arrival_date}
              disablePast={!shipmentSettings?.allow_past_date}
              error={touched?.arrival_date && errors?.arrival_date}
            />
          )}
        </div>

        {!!stop.departure_date || !stop.arrival_date ? (
          <div className={classes.datePickerDataWrapper}>
            <Typography variant='s2' className='mb-2'>
              Check Out
            </Typography>
            <div className={classes.datePickerData}>
              <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
              <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
                {dateFormat}
              </Typography>
            </div>
          </div>
        ) : (
          <DateTimePicker
            label='Check Out'
            name='departure_date'
            value={values.departure_date}
            onChange={(val) => setFieldValue('departure_date', val)}
            disabled={!!stop.departure_date || !stop.arrival_date}
            minDate={new Date(stop.arrival_date)}
            error={touched?.departure_date && errors?.departure_date}
          />
        )}
      </div>

      <div className={classes.equipmentActionWrapper}>
        <Typography variant='s2'>Equipment Action</Typography>

        {!!values.equipment_tbd && !values.check_in && (
          <div className='mt-2 mb-1'>
            <InfoIcon fill='#AA5B00FF' />
            <Typography
              variant='s2'
              style={{
                color: '#AA5B00FF',
                marginLeft: 6,
              }}
            >
              You need to determine trailer.
            </Typography>
          </div>
        )}

        <div>
          <FastField
            value='2'
            type='radio'
            name='equipment_action'
            labelStyle={{ marginTop: 8 }}
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Drop Trailer
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>

        {Number(values?.equipment_action) === 2 && (
          <div className={classes.dropHookSubBlock}>
            <Typography variant='s2'>Drop Trailer </Typography>
            <div className={classes.selectDropHook}>
              <FastField
                name='drop_id'
                component={CustomSelect}
                styles={{ height: 32 }}
                isSearchable
                disabled={!!values.check_in}
                options={equipmentId}
                shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'drop_id')}
                defaultText={
                  stop?.equipment?.equipment_id
                    ? `${stop?.equipment?.equipment_id}  (${stop?.equipment?.length?.length || ''} ${
                        stop?.equipment?.length?.unit || ''
                      } ${stop?.equipment?.equipment_type?.title || '-'}) `
                    : ''
                }
              />
            </div>
          </div>
        )}
        <div>
          <FastField
            value='3'
            type='radio'
            name='equipment_action'
            labelStyle={{ marginTop: 8 }}
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Drop & Hook
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>

        {Number(values?.equipment_action) === 3 && (
          <div className={classes.dropHookSubBlock}>
            <Typography variant='s2'>Drop Trailer & Hook to </Typography>
            <div className={classes.selectDropHook}>
              <FastField
                name='equipment_id'
                component={CustomSelect}
                styles={{ height: 32 }}
                isSearchable
                disabled={!!values.check_in}
                options={equipmentId}
                shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_id')}
                defaultText={
                  stop?.equipment?.equipment_id
                    ? `${stop?.equipment?.equipment_id}  (${stop?.equipment?.length?.length || ''} ${
                        stop?.equipment?.length?.unit || ''
                      } ${stop?.equipment?.equipment_type?.title || '-'}) `
                    : ''
                }
              />
            </div>
          </div>
        )}

        <div>
          <FastField
            value='4'
            type='radio'
            name='equipment_action'
            labelStyle={{ marginTop: 8 }}
            component={(props) => (
              <CustomRadioButton {...props} disabled>
                <Typography variant='s2' style={{ marginLeft: 8 }}>
                  Other
                </Typography>
              </CustomRadioButton>
            )}
          />
        </div>
        {Number(values?.equipment_action) === 4 && (
          <div className={classes.dropHookSubBlock} style={{ margin: '8px 0 0 16px' }}>
            <div className={classes.selectDropHook}>
              <FastField name='equipment_id' component={CustomSelect} styles={{ height: 32 }} options={equipmentId} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WayPointInModal;
