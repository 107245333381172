import React, { useEffect, useState } from 'react';
import './Map.css';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import classNames from 'classnames';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import { getAlphabet, palette } from 'utils/constants';

const NAME_TYPES = {
  1: 'PICK UP',
  2: 'DELIVERY',
  3: 'WAYPOINT',
};

const CLASS_TYPES = {
  1: 'pickUp',
  2: 'delivery',
  3: 'wayPoint',
};

const MapCreate = ({ legs, stopPoints, orderStops, onChangeCoords, onMountCoords, mapValues, children }) => {
  const [coords, setCoords] = useState([]);
  const [stops, setStops] = useState([]);
  const [indexForAlphabet, setIndexForAlphabet] = useState([]);
  const [myMap, setMyMap] = useState(null);
  const [stopsRoute, setStopsRoute] = useState(null);

  const onRouteDragAndDrop = (e, route) => {
    const allStopsInOrdered = [...(e?.target?._options?.stops || [])];
    allStopsInOrdered.splice(e.routeLegPositions[0] + 1, 0, e.newStop);
    const newCoords = { latitude: e?.newStop?.lat, longitude: e?.newStop?.lng };
    const defaultCoords = coords.map((cord) => ({ ...cord, stopPoint: true }));
    onChangeCoords({ newCoords, defaultCoords, stops: allStopsInOrdered });
    route.getRouteWithNewStop(e.newStop, e.routeLegPositions[e.routeLegPositions.length - 1]);
  };

  useEffect(() => {
    const allCoords = [];
    const allStops = [];
    const countAll = [];
    const legsStopsCount = [];
    if (!orderStops) {
      legs?.forEach((leg, indexPrefix) => {
        legsStopsCount.push(leg.stops.length);
        leg.stops.forEach((stop, index) => {
          const prevStopsCountArr = legsStopsCount.slice(0, indexPrefix);
          const count = prevStopsCountArr.length ? prevStopsCountArr.reduce((a, b) => a + b, 0) + index : 0 + index;
          allStops.push(stop);
          countAll.push(count);
          const stopData = stopPoints?.find((point) => +point.key === +stop.stop_point_id);
          if (stopData) {
            const cord = {
              latitude: stopData.latitude,
              longitude: stopData.longitude,
              stopPointId: stopData.key,
            };
            allCoords.push(cord);
          }
        });
      });
      setStops([...stops, ...allStops]);
      setCoords([...coords, ...allCoords]);
    } else {
      orderStops?.forEach((stop, index) => {
        const stopData = stopPoints?.find((point) => +point.key === +stop.stop_point_id);
        if (stopData) {
          const cord = {
            latitude: stopData.latitude,
            longitude: stopData.longitude,
            stopPointId: stopData.key,
          };
          countAll.push(index);
          allStops.push(stop);
          allCoords.push(cord);
        }
      });
      setStops([...allStops]);
      setCoords([...allCoords]);
    }
    setIndexForAlphabet([...countAll]);
  }, [stopPoints, legs, orderStops]);

  useEffect(() => {
    async function init() {
      TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
      if (!myMap) {
        const myMap = new TrimbleMaps.Map({
          container: 'myMap',
          style: TrimbleMaps.Common.Style.BASIC,
          center: new TrimbleMaps.LngLat(-96, 35),
          zoom: 3,
          dragRotate: false,
        });
        setMyMap(myMap);
      }

      if (myMap) {
        const routeId = `myRoute-${Math.random()}`;

        // Draw Markers
        coords.forEach((cord, i) => {
          const alphabet = getAlphabet(indexForAlphabet[i]);
          const id = stops[i]?.stop_point_type;
          const svgContent = document.createElement('div');
          let content;
          if (id === null || id === undefined) {
            content = `<div style="display: none"/>`;
          } else {
            content = `
              <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                <img src="${mapMarkerIcon}" alt=''>
                <div class='myMap_marker_text_wrapper'>
                  <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                </div> 
              </div>`;
          }
          svgContent.innerHTML = content;
          new TrimbleMaps.Marker({
            draggable: false,
            element: svgContent,
          })
            .setLngLat([cord.longitude, cord.latitude])
            .addTo(myMap);
        });

        const stopsMap = coords.map((cord) => {
          const latLngObj = new TrimbleMaps.LngLat(cord.longitude, cord.latitude);
          return { ...latLngObj, stopPoint: true };
        });

        const routeOptions = {
          routeId,
          stops: stopsMap,
          showStops: false,
          isDraggable: true,
          routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
          highwayOnly: !!mapValues.highway_only,
          tollRoads: mapValues.avoid_tolls ? 2 : 3,
          hazMatType: mapValues.hazmat_id,
          vehicleType: mapValues.vehicle_type,
          routeColor: palette.indigo500, // optional routeColor
        };
        const myRoute = new TrimbleMaps.Route(routeOptions);
        setStopsRoute(myRoute);

        myRoute.on('stopInserting', (e) => {
          onRouteDragAndDrop(e, myRoute);
        });

        myMap.on('load', async () => {
          myRoute.addTo(myMap);
        });
      }
    }

    init();
  }, [coords, stops, myMap, indexForAlphabet]);

  useEffect(() => {
    const markers = coords.map((cord) => ({ ...cord, stopPoint: true }));
    typeof onMountCoords === 'function' && onMountCoords(markers);
  }, [coords]);

  useEffect(() => {
    if (stopsRoute && myMap && myMap.isStyleLoaded() && mapValues) {
      stopsRoute.remove();
      setStopsRoute(null);

      const stopsMap = coords.map((cord) => {
        const latLngObj = new TrimbleMaps.LngLat(cord.longitude, cord.latitude);
        return { ...latLngObj, stopPoint: true };
      });
      const routeOptions = {
        routeId: 'routeId',
        stops: stopsMap,
        showStops: false,
        routeColor: palette.indigo500, // optional routeColor
        routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
        highwayOnly: !!mapValues.highway_only,
        tollRoads: mapValues.avoid_tolls ? 2 : 3,
        hazMatType: mapValues.hazmat_id,
        vehicleType: mapValues.vehicle_type,
        isDraggable: true,
      };
      const myRoute = new TrimbleMaps.Route(routeOptions);

      myRoute.on('stopInserting', (e) => {
        onRouteDragAndDrop(e, myRoute);
      });

      myRoute.addTo(myMap);
      setStopsRoute(myRoute);
    }
  }, [mapValues, myMap]);

  useEffect(() => {
    return () => {
      setMyMap(null);
    };
  }, []);

  return (
    <div className={classNames('map_container')}>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100%', width: '100%' }} />
        {children}
      </div>
    </div>
  );
};

export default MapCreate;
