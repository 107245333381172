import React, { useEffect, useState } from 'react';
import { endOfYear, startOfYear } from 'date-fns';
import Search from 'common/Search';
import Pagination from 'common/Pagination';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import { getCarrierShipments } from 'Api/Carriers';
import { useNavigate, useParams } from 'react-router-dom';
import { useColumns } from './CarrierShipmentsTab.data';

const CarrierTransactionsTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openInvoices, setOpenInvoices] = useState({ data: [] });
  const [sort, setSort] = useState();
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25 });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [dateRange, setDateRange] = useState({
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  });

  const getOpenInvoices = async () => {
    try {
      setLoading(true);
      const { page, itemsPerPage } = selectedFilters || {};
      const params = {
        page,
        itemsPerPage,
        'filters[all]': debouncedSearch || undefined,
        'filters[from]': moment(dateRange.start.toUTCString()).format('YYYY-MM-DD'),
        'filters[to]': moment(dateRange.end.toUTCString()).format('YYYY-MM-DD'),
      };

      const response = await getCarrierShipments(id, params);
      setOpenInvoices(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getOpenInvoices();
  }, [dateRange, selectedFilters, debouncedSearch, sort]);

  function findNextStop(rowData) {
    const { status_id, shipment_stops } = rowData;

    if (['1', '2', '8', '9'].includes(status_id)) {
      return 0;
    }
    if (status_id === '7') {
      return shipment_stops.length;
    }
    const index = shipment_stops.findIndex((stop) => !stop.departure_date && stop.arrival_date);

    if (index !== -1) {
      return index + 1;
    }

    const firstMissingArrivalIndex = shipment_stops.findIndex((stop) => !stop.arrival_date);

    if (firstMissingArrivalIndex !== -1) {
      return firstMissingArrivalIndex + 1;
    }

    return 0;
  }

  const columns = useColumns({ sort, sortingQuery, findNextStop });

  return (
    <div>
      <div>
        <Typography variant='h2'>Shipments</Typography>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center gap-4'>
          <DateRangePicker
            top='-168px'
            dateRange={dateRange}
            initialRangeName='This Year'
            setDateRange={setDateRange}
          />
          <Search search={search} onChange={setSearch} margin='16px 0' />
        </div>
      </div>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={openInvoices.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: openInvoices,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [25, 50, 100, 150],
              }),
          }}
          columns={columns}
          onRowClick={(event, row) => {
            navigate(`/shipment/${row?.shipment_id}`);
          }}
        />
      )}
    </div>
  );
};

export default CarrierTransactionsTab;
