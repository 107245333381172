import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import { ReactComponent as WarningIcon } from '../../../assets/icons/equipment/warning.svg';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { palette } from '../../../utils/constants';

export const SCustomModal = styled(CustomModal)`
  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '700px'};
    max-width: ${({ $maxWidth }) => $maxWidth || ''};
    height: ${({ $height }) => $height || 'auto'};

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: 100%;
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      min-height: ${({ $minHeight }) => $minHeight || ''};
      overflow: auto;
      border-radius: 0;

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      border-bottom: 1px solid ${palette.gray50};
    }

    .customFooter {
      padding: 12px 20px;
      border-top: 1px solid ${palette.gray50};

      button {
        padding: 6px 12px !important;
        margin: 0 !important;
        margin-right: 8px !important;

        span {
          font-size: 14px !important;
          font-weight: 500;
          font-family: Inter, sans-serif;
        }
      }

      .modal-cancel-btn {
        margin-right: 16px !important;
      }
    }

    & ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    & ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 8px;
    }
  }
`;

export const STable = styled.table`
  width: 100%;

  .MuiAutocomplete-root {
    min-width: 130px;
  }

  .header-row {
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 0 16px 12px;
      white-space: nowrap;

      svg {
        cursor: pointer;
      }
    }

    .settings-gear {
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
      visibility: hidden;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    &.invalid-row {
      background-color: ${palette.red0};
    }

    .disabled-save-icon path {
      fill: ${palette.gray200};
    }

    :hover {
      background: ${palette.gray0};

      .action-buttons {
        visibility: visible;
      }

      .action-wrapper {
        visibility: visible;
      }
    }
  }

  .action-dots {
    cursor: pointer;
  }
`;

export const STableData = styled.td`
  min-width: ${({ width }) => width || ''};
  padding: 10px 16px 16px;

  .date-picker-disabled {
    background-color: ${palette.gray500};
  }

  .import-equipment-input-wrapper {
    height: 25px;
  }

  .bg-gray {
    .MuiInputBase-root {
      background: ${palette.gray50};
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const SActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  svg {
    cursor: pointer;
  }
`;

export const SFlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const SCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;

    .MuiSvgIcon-root {
      fill: ${palette.indigo500};
      fill: ${({ checked }) => (checked ? palette.indigo500 : palette.gray700)};
    }
  }
`;

export const SWarningIcon = styled(WarningIcon)`
  cursor: pointer;

  path {
    fill: ${palette.red500};
  }
`;

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;
