import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Typography } from 'components/Typography';
import styles from './CompanyResourcesWatchModal.module.css';

const CompanyResourceWatchModal = ({ openWatchModal, data, setOpenWatchModal }) => {
  const linkInfo = useMemo(() => {
    let link;
    let videoId;
    let videoStartTime;
    try {
      link = new URL(data?.video);
      videoId = link.searchParams.get('v');
      videoStartTime = link.searchParams.get('t');
    } catch (e) {
      /* empty */
    }

    return { link, videoStartTime, videoId };
  }, [data]);

  return (
    <Modal
      show={openWatchModal}
      onHide={() => {
        setOpenWatchModal({
          visible: false,
        });
      }}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
      centered
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Video</Typography>
        </div>
        <div className={styles.body}>
          <div className={styles.image_wrapper}>
            {/* <video width={'600px'} height={'400px'} controls={true} autoPlay={true} loop muted> */}
            {/*    <source src={data?.video}  type="video/webm"/> */}
            {/* </video> */}
            <iframe
              width='600'
              height='400'
              src={`https://www.youtube.com/embed/${linkInfo.videoId}?start=${linkInfo.videoStartTime}`}
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompanyResourceWatchModal;
