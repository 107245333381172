import React, { useState } from 'react';
import { Textarea } from 'common/Input';
import { SCustomModal } from '../../Settlements.styles';

const DeclineReason = ({ open, onClose, onSubmit, loading }) => {
  const [reason, setReason] = useState('');

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      onExited={() => setReason('')}
      $maxWidth='350px'
      $height='auto'
      headerTitle='Decline Reason'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Submit',
          disabled: loading,
          onClick: () => onSubmit(reason),
        },
      ]}
    >
      <Textarea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder='Type the reason...'
        rows={3}
        required
      />
    </SCustomModal>
  );
};

export default DeclineReason;
