import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import cardsImg from 'assets/images/onboarding/image 18.png';
import { Typography } from 'components/Typography';
import { planType } from 'components/customerOnboardingComponents/data';
import { formatNumber, palette } from 'utils/constants';
import { SPaymentMethod } from './PaymentMethod.styles';

const PaymentMethod = ({
  financingDisabled,
  selectedPaymentMethod,
  onPaymentMethodChange,
  selectedPlanType,
  prices,
  totalPlanAndAddons,
  // processingFee,
  // onCredit,
}) => {
  const { billingDetail } = useSelector((state) => state.billing);

  return (
    <SPaymentMethod>
      <div className='payment-methods'>
        <div
          className={classNames([
            'payment-method',
            { disabled: !!financingDisabled, selected: selectedPaymentMethod === 'financing' },
          ])}
        >
          <div className='payment-method-header'>
            <div className='payment-method-title'>
              <input
                className='pointer'
                type='radio'
                name='payment'
                checked={selectedPaymentMethod === 'financing'}
                onChange={() => onPaymentMethodChange('financing')}
                disabled={financingDisabled}
              />
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                Finance Agreement
              </Typography>
            </div>
          </div>
          {selectedPlanType?.duration === 'monthly' ? (
            <Typography variant='b1'>{planType.monthly.financingText}</Typography>
          ) : (
            <div className='d-flex flex-column gap-2'>
              {!!financingDisabled && (
                <Typography variant='b2'>Transaction must be over $5,000.00 for financing.</Typography>
              )}
              {!financingDisabled &&
                Number(totalPlanAndAddons) !== 0 &&
                billingDetail?.customer_plan?.customer_status !== 'intrial' && (
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    <Typography variant='h5' style={{ color: palette.gray700 }}>
                      ${formatNumber(Number(totalPlanAndAddons) / planType[selectedPlanType?.duration].monthsCount)}
                    </Typography>{' '}
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      /month for {planType[selectedPlanType?.duration].monthsCount} months
                    </Typography>
                  </Typography>
                )}
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                If you choose to pay with the financing option, you will be making monthly payments for{' '}
                {planType[selectedPlanType?.duration].monthsCount} months + finance charges. Varies on credit approval.
              </Typography>
            </div>
          )}
        </div>
        {/* <div */}
        {/*  className={classNames(['payment-method', { selected: selectedPaymentMethod === 'credit-card' && onCredit }])} */}
        {/* > */}
        {/*  <div className='payment-method-header'> */}
        {/*    <div className='payment-method-title'> */}
        {/*      <input */}
        {/*        className='pointer' */}
        {/*        type='radio' */}
        {/*        name='payment' */}
        {/*        checked={selectedPaymentMethod === 'credit-card'} */}
        {/*        onChange={() => onPaymentMethodChange('credit-card')} */}
        {/*      /> */}
        {/*      <Typography variant='h5' style={{ color: palette.gray900 }}> */}
        {/*        Credit Card */}
        {/*      </Typography> */}
        {/*    </div> */}
        {/*    <div> */}
        {/*      <img src={cardsImg} alt='img' /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/*  {selectedPaymentMethod === 'credit-card' && onCredit && paymentMethod?.payment_method === 'credit-card' ? ( */}
        {/*    <div style={{ display: 'flex', columnGap: '16px', marginTop: '12px' }}> */}
        {/*      <div> */}
        {/*        {creditCard[paymentMethod?.payment_method_details?.card?.brand] || <Card width={40} height={40} />} */}
        {/*      </div> */}
        {/*      <div> */}
        {/*        <Typography variant='s2' style={{ color: palette.gray700 }}> */}
        {/*          {paymentMethod?.payment_method_details?.billing_details?.name} */}
        {/*        </Typography> */}
        {/*        <div style={{ display: 'flex', columnGap: 18, marginTop: 8 }}> */}
        {/*          <Typography variant='s2' style={{ color: palette.gray900 }}> */}
        {/*            **** **** **** {paymentMethod?.payment_method_details?.card?.last4} */}
        {/*          </Typography> */}
        {/*          <Typography variant='s2' style={{ color: palette.gray700 }}> */}
        {/*            {paymentMethod?.payment_method_details?.card?.exp_month}/ */}
        {/*            {Number(paymentMethod?.payment_method_details?.card?.exp_year) % 100} */}
        {/*          </Typography> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  ) : billingDetail?.customer_plan?.customer_status !== 'intrial' ? ( */}
        {/*    <div className='d-flex flex-column gap-2'> */}
        {/*      {Number(totalPlanAndAddons + processingFee) !== 0 && ( */}
        {/*        <Typography variant='b2' style={{ color: palette.gray700 }}> */}
        {/*          <span className='payment-subtitle'>${formatNumber(Number(totalPlanAndAddons + processingFee))}</span>{' '} */}
        {/*          billed today then $ */}
        {/*          {formatNumber(totalPlanAndAddons - Number(prices?.addon_prices?.total_setup_fees || 0))} per month */}
        {/*          recurring */}
        {/*        </Typography> */}
        {/*      )} */}
        {/*      <Typography variant='b2' style={{ color: palette.gray700 }}> */}
        {/*        {planType[selectedPlanType?.duration].creditCardText} */}
        {/*      </Typography> */}
        {/*    </div> */}
        {/*  ) : ( */}
        {/*    <div> */}
        {/*      <Typography variant='b2' style={{ color: palette.gray700 }}> */}
        {/*        {planType[selectedPlanType?.duration].creditCardText} */}
        {/*      </Typography> */}
        {/*    </div> */}
        {/*  )} */}
        {/* </div> */}
        <div className={classNames(['payment-method', { selected: selectedPaymentMethod === 'ach' }])}>
          <div className='payment-method-header'>
            <div className='payment-method-title'>
              <input
                className='pointer'
                type='radio'
                name='payment'
                checked={selectedPaymentMethod === 'ach'}
                onChange={() => onPaymentMethodChange('ach')}
              />
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                Credit Card or ACH
              </Typography>
            </div>
            <img src={cardsImg} alt='img' />
          </div>
          {/* {selectedPaymentMethod === 'ach' && paymentMethod?.payment_method === 'ach' && ( */}
          {/*  <div> */}
          {/*    <div> */}
          {/*      <Typography variant='button2'> */}
          {/*        {paymentMethod?.payment_method_details?.us_bank_account?.bank_name} */}
          {/*      </Typography> */}
          {/*    </div> */}
          {/*    <Typography variant='s2' style={{ color: palette.gray700 }}> */}
          {/*      {paymentMethod?.payment_method_details?.billing_details?.name} */}
          {/*    </Typography> */}
          {/*    <div style={{ display: 'flex', columnGap: 18, marginTop: 8 }}> */}
          {/*      <Typography variant='s2' style={{ color: palette.gray900 }}> */}
          {/*        **** **** **** {paymentMethod?.payment_method_details?.us_bank_account?.last4} */}
          {/*      </Typography> */}
          {/*      <Typography variant='s2' style={{ color: palette.gray700 }}> */}
          {/*        {`| ${paymentMethod?.payment_method_details?.us_bank_account?.routing_number}`} */}
          {/*      </Typography> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* )} */}
          {/* {selectedPaymentMethod !== 'ach' && billingDetail?.customer_plan?.customer_status !== 'intrial' && ( */}
          <div className='d-flex flex-column gap-2'>
            {Number(totalPlanAndAddons) !== 0 && (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                <span className='payment-subtitle'>${formatNumber(Number(totalPlanAndAddons))}</span> billed today then
                ${formatNumber(totalPlanAndAddons - Number(prices?.addon_prices?.total_setup_fees || 0))} per month
                recurring
              </Typography>
            )}
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {planType[selectedPlanType?.duration].achText} Account will activate once payment clears.
            </Typography>
          </div>
          {/* )} */}
          {/* {selectedPaymentMethod !== 'ach' && billingDetail?.customer_plan?.customer_status === 'intrial' && ( */}
          {/* <div> */}
          {/*  <Typography variant='b2' style={{ color: palette.gray700 }}> */}
          {/*    {planType[selectedPlanType?.duration].achText} Account will activate once payment clears. */}
          {/*  </Typography> */}
          {/* </div> */}
          {/* )} */}
        </div>
      </div>
    </SPaymentMethod>
  );
};

export default PaymentMethod;
