import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { palette } from 'utils/constants';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { GetEquipmentCargoOnboardTypes } from 'Api/StopPoint';
import { carrierHookOrDropTrailer, dropCargoTrailer } from 'Api/Shipment';

const DropTrailer = ({ open, onClose, shipment, stop }) => {
  const { shipment_id, brokerage_dispatch } = shipment;
  const { carrier } = brokerage_dispatch || {};

  const [value, setValue] = useState();
  const [cargoTypes, setCargoTypes] = useState([]);

  const getCargoTypes = async () => {
    try {
      const { data } = await GetEquipmentCargoOnboardTypes();
      setCargoTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCargoTypes();
  }, []);
  const onSubmit = async () => {
    try {
      let body;
      if (!carrier) {
        body = {
          status_id: '6',
          stop_point_id: stop.id,
          cargo_onboard_id: value?.id,
          shipment_id,
          arrive_date: moment(new Date()).format('YYYY-MM-DD'),
        };

        await dropCargoTrailer(open, body);
      } else {
        body = {
          status: 'drop',
          shipment_id,
          stop_point_id: stop.id,
          cargo_onboard_id: value?.id,
        };
        await carrierHookOrDropTrailer(open, body);
      }

      onClose();
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <Modal
      showModal={!!open}
      onHide={onClose}
      headerTitle='Drop Trailer'
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'submit',
          type: 'primary',
          title: `Submit`,
          onClick: onSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <div>
          <InputLabel variant='s1' style={{ color: 'black', fontWeight: 600 }}>
            Shipment ID
          </InputLabel>
          <Typography variant='s3'>{shipment_id}</Typography>
        </div>
        <div>
          <InputLabel variant='s1' style={{ color: 'black', fontWeight: 600 }}>
            Stop Point to Drop Trailer At
          </InputLabel>
          <Typography variant='s3'>
            {stop?.stop_point?.location_name} ({stop?.stop_point?.address1}, {stop?.stop_point?.city?.name},{' '}
            {stop?.stop_point?.state?.short_name} {stop?.stop_point?.zipcode})
          </Typography>
        </div>
        <div>
          <InputLabel variant='s1' style={{ color: 'black', fontWeight: 600 }}>
            Cargo Onboard
          </InputLabel>
          <Autocomplete
            name='cargo_onboard_id'
            labelKey='title'
            options={cargoTypes || []}
            value={value}
            onChange={(e, value) => setValue(value)}
            isOptionEqualToValue={(option, value) => Number(option.id) === Number(value.id)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DropTrailer;
