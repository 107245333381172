import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateAlert } from 'Api/AlertCenter';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { ALERT_TYPE, statusOptions } from '../TakeActions.data';
import { validationSchema } from './validationSchema';

const pickerLabel = {
  [ALERT_TYPE.MCS_150_BIENNIAL_UPDATE]: 'MCS 150 Biennial Update Due Date',
  [ALERT_TYPE.URS_BIENNIAL_UPDATE]: 'URS Biennial Update Renewal Date',
  [ALERT_TYPE.UCR_ANNUAL_UPDATE]: 'UCR Annual Update Due Date',
};

const ExpirationUpdate = ({ open, onClose, alert, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const body = {
        status: values.status.value,
      };

      switch (alert?.alert_name?.alert_name) {
        case ALERT_TYPE.MCS_150_BIENNIAL_UPDATE:
          body.mcs_biennial_update_date = moment(values.expire_date).format('YYYY-MM-DD');
          break;
        case ALERT_TYPE.URS_BIENNIAL_UPDATE:
          body.urs_biennial_renewal_date = moment(values.expire_date).format('YYYY-MM-DD');
          break;
        case ALERT_TYPE.UCR_ANNUAL_UPDATE:
          body.ucr_annual_due_date = moment(values.expire_date).format('YYYY-MM-DD');
          break;
        default:
          body.due_date = moment(values.expire_date).format('YYYY-MM-DD');
      }

      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: {
      expire_date: null,
      status: statusOptions.find((i) => i.name === alert?.status) || null,
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      padding='0'
      $maxWidth='750px'
      $minWidth='750px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <div className='d-flex gap-4 mb-4 align-items-center'>
            <InputLabel required className='mb-1'>
              {pickerLabel[alert.alert_name?.alert_name] || 'Expiry Date'}
            </InputLabel>
            <div>
              <DatePicker
                width='150px'
                name='expire_date'
                value={values.expire_date}
                onBlur={handleBlur}
                onChange={(val) => handleChange('expire_date', val)}
                disablePast
                error={touchedErrors.expire_date}
              />
            </div>
          </div>
          <div>
            <Autocomplete
              required
              width='260px'
              label='Action'
              name='status'
              options={statusOptions}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.status}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExpirationUpdate;
