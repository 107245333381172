import { palette } from 'utils/constants';

export const timeOfDayConverter = (timeOfDay) => {
  switch (timeOfDay) {
    case 'Midnight - 3AM':
      return 'Midnight - 03:00';
    case '3AM - 6AM':
      return '03:00 - 06:00';
    case '6AM - 9AM':
      return '06:00 - 09:00';
    case '9AM - Noon':
      return '09:00 - Noon';
    case 'Noon - 3PM':
      return 'Noon - 15:00';
    case '3PM - 6PM':
      return '15:00 - 18:00';
    case '6PM - Midnight':
      return '18:00 - Midnight';
    default:
  }
};

export const getOptions = (monthsData) => ({
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 18,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          return `${item[0].label} - ${item[0].raw} ${item[0].raw === 1 ? 'Collision' : 'Collisions'} - ${
            monthsData[item[0].dataIndex].percentage
          }%`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
});

export const monthOfCollisionOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 1.5,
  tension: 0.2,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label}`;
        },
        label(context) {
          let label = context.dataset.label || '';

          if (context.parsed.y !== null) {
            label += `${context.parsed.y} ${context.parsed.y === 1 ? 'Collision' : 'Collisions'}`;
          }
          return label;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      boxPadding: 3,
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      suggestedMax: 5,
      beginAtZero: true,
      grid: {
        borderDash: [8, 8],
        color: palette.gray50,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
});

const paidInvoicesLabels = {
  0: 'Less than 3 months',
  1: '1 - 2 years',
  2: '3 - 5 years',
  3: '3 - 12 months',
  4: '6 - 10 years',
  5: '10+ Years',
};

export const paidInvoicesOptions = {
  cutout: '65%',
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  labels: {
    display: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

export const paidInvoicesPlugins = () => [
  {
    id: 'hoverText',
    afterDraw(chart) {
      const {
        ctx,
        _active,
        chartArea: { width, height },
      } = chart;

      ctx.save();

      if (_active.length) {
        ctx.font = '700 24px Inter';
        ctx.fillStyle = palette.gray700;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${_active[0]?.element?.$context?.raw}`, width / 2, height / 2 - 10);

        ctx.font = '500 16px Inter';
        ctx.fillStyle = palette.gray500;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${paidInvoicesLabels[_active[0]?.index]}`, width / 2, height / 2 + 16);
      }
    },
  },
  {
    id: 'textCenter',
    afterDraw(chart) {
      const {
        ctx,
        _active,
        chartArea: { width, height },
      } = chart;

      const total = chart.data.datasets[0].data?.reduce((acc, cur) => {
        return acc + cur;
      }, 0);

      if (_active.length === 0) {
        ctx.save();
        ctx.font = '700 24px Inter';
        ctx.fillStyle = palette.gray700;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        if (total) {
          ctx.fillText(`${total}`, width / 2, height / 2 - 10);
        }

        ctx.font = '500 16px Inter';
        ctx.fillStyle = palette.gray500;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('Total', width / 2, height / 2 + 16);
      }
    },
  },
];

export const speedLimitOptions = {
  aspectRatio: 2,
  scale: {
    ticks: {
      stepSize: 1,
    },
  },
  scales: {
    r: {
      pointLabels: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const driverExperienceOptions = {
  aspectRatio: 2,
  scale: {
    ticks: {
      stepSize: 2,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const getCompanyVsOwnerOptions = (companyVsOwner) => ({
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 40,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          return `${item[0].label} - ${item[0].raw} ${item[0].raw === 1 ? 'Collision' : 'Collisions'} - ${
            companyVsOwner[item[0].label === 'Owner Operator' ? 'O/O' : 'Company'].percentage
          }%`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
});

export const getAgeGroupOptions = (ageGroups) => ({
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 18,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          return `${item[0].label} - ${item[0].raw} ${item[0].raw === 1 ? 'Collision' : 'Collisions'} - ${
            ageGroups[item[0].dataIndex].percentage
          }%`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
});
