import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;

  .header-row {
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 0 10px 12px;
      white-space: nowrap;

      &.centered {
        text-align: center;
      }
    }

    .settings-gear {
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    &.bg-gray {
      background: ${palette.gray0};
    }

    &.disputed {
      background: ${palette.red0};
      border-bottom: none;
    }

    &.dispute-approved {
      background: ${palette.green0};
      border-bottom: none;
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
      visibility: hidden;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    td {
      padding: 12px 10px;

      &.centered {
        text-align: center;
      }
    }

    :hover {
      background: ${palette.gray0};

      .action-buttons {
        visibility: visible;
      }

      .action-wrapper {
        visibility: visible;
      }
    }
  }

  .total-row {
    td {
      padding: 12px 10px;
    }

    .align-end {
      text-align: end;
    }
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;
