import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { ReactComponent as OneTime } from 'assets/icons/createShipment/oneTime.svg';
import { ReactComponent as RecurringLane } from 'assets/icons/createShipment/recurringLane.svg';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import CustomTooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { equipmentTypeText } from 'components/TableShipments/helpers/constants';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getShipmentType } from 'utils/helpers';
import { formatNumber, palette } from 'utils/constants';
import { getBackhaul } from 'Api/PlannerV2';
import { STable } from 'pages/Planner/Availability/Availability.styles';

const RecommendedBackhaul = ({ driver, shipment, selectedBackhaul, setSelectedBackhaul, filters, search }) => {
  const { currency } = useSelector((state) => state.root);
  const { formatDateTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [backhaul, setBackhaul] = useState([]);
  const controller = useRef(new AbortController());

  const getBackhaulShipments = async () => {
    let aborted = false;

    try {
      if (controller?.current) {
        controller.current.abort();
        controller.current = new AbortController();
      }

      setLoading(true);
      setSelectedBackhaul(null);

      const params = {
        driver_id: driver.id,
        shipment_id: shipment.shipment_id,
        start_date: filters.dateRange[0] ? moment(filters.dateRange[0]).format('YYYY-MM-DD') : undefined,
        end_date: filters.dateRange[1] ? moment(filters.dateRange[1]).format('YYYY-MM-DD') : undefined,
        query: search || undefined,
        driver_ids: filters.drivers.map((i) => i.id),
        equipment_ids: filters.equipment.map((i) => i.id),
        group_ids: filters.groups.map((i) => i.id),
        city: filters.city && filters.state && filters.miles ? filters.city : undefined,
        state: filters.city && filters.state && filters.miles ? filters.state : undefined,
        miles: filters.city && filters.state && filters.miles ? filters.miles : undefined,
        radius: filters.radius ? filters.radius : undefined,
        date_span: filters.date_span ? filters.date_span : 0,
      };

      const { data } = await getBackhaul(params, controller?.current?.signal);
      setBackhaul(
        (data || []).map((shipment) => {
          return {
            ...shipment,
            grossRevenue: shipment.shipment_billing.reduce((acc, cur) => acc + (Number(cur.total_amount) || 0), 0),
            totalMiles: Number(shipment.loaded_miles || 0) + Number(shipment.empty_miles || 0),
          };
        })
      );
    } catch (e) {
      if (e.message === 'canceled') {
        aborted = true;
      }
    } finally {
      if (!aborted) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getBackhaulShipments();
  }, [shipment, driver, filters]);

  return (
    <div className='matched-shipments mb-4'>
      <div className='ps-4 pe-4'>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          Recommended Backhaul
        </Typography>
      </div>
      <Divider />
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <STable>
          <thead>
            <tr className='nowrap no-border'>
              <th />
              <th>SHIPMENT</th>
              <th>EMPTY MILES</th>
              <th>EQUIPMENT TYPE</th>
              <th>ORIGIN</th>
              <th />
              <th>DESTINATION</th>
              <th>DRIVER PAY</th>
            </tr>
          </thead>
          <tbody>
            {backhaul?.map((shipment) => {
              const { shipment_id, shipment_type, shipment_billing, reverted, shipment_stops } = shipment;

              const typeLength = shipment_stops[0]?.equipment_type_length_value?.length;
              const equipmentType = shipment_stops[0]?.equipment_type_data?.title;
              const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;
              const firstNullArrivalDate = shipment_stops.find((stop) => !stop.arrival_date);

              const { stop_point, scheduled_type, scheduled_date, scheduled_date_to, from, to } =
                shipment_stops[0] || {};
              const {
                stop_point: destination_stop_point,
                scheduled_type: destination_scheduled_type,
                scheduled_date: destination_scheduled_date,
                scheduled_date_to: destination_scheduled_date_to,
                from: destination_from,
                to: destination_to,
              } = shipment_stops[shipment_stops.length - 1] || {};

              const originStopName = stop_point?.location_name;
              const originCity = stop_point?.city?.name;
              const originState = stop_point?.state?.short_name;
              const originZipcode = stop_point?.zipcode;
              const originScheduledType = scheduled_type;
              const scheduledOrigin = scheduled_date;
              const scheduledOriginTo = scheduled_date_to || scheduled_date;
              const originFromDate = from;
              const originToDate = to;
              const originFrom = `${scheduledOrigin} ${originFromDate || ''}`;
              const originTo = `${scheduledOriginTo} ${originToDate || ''}`;

              const destinationStopName = destination_stop_point?.location_name;
              const destinationCity = destination_stop_point?.city?.name;
              const destinationState = destination_stop_point?.state?.short_name;
              const destinationZipcode = destination_stop_point?.zipcode;
              const destinationScheduledType = destination_scheduled_type;
              const scheduledDestination = destination_scheduled_date;
              const scheduledDestinationTo = destination_scheduled_date_to || destination_scheduled_date;
              const destinationFromDate = destination_from;
              const destinationToDate = destination_to;
              const destinationFrom = `${scheduledDestination} ${destinationFromDate || ''}`;
              const destinationTo = `${scheduledDestinationTo} ${destinationToDate || ''}`;

              return (
                <tr
                  key={shipment.id}
                  className={classNames(`matched-shipment no-border`, {
                    selected: selectedBackhaul?.id === shipment.id,
                  })}
                  onClick={() => setSelectedBackhaul(shipment)}
                >
                  <td>
                    <span onClick={(e) => e.stopPropagation()}>
                      <CustomCheckbox
                        onChange={(checked) => setSelectedBackhaul(checked ? shipment : null)}
                        checked={selectedBackhaul?.id === shipment.id}
                      />
                    </span>
                  </td>
                  <td>
                    <div className='d-flex flex-column justify-content-center'>
                      <div>
                        <Typography variant='s2' style={{ color: palette.dark800 }}>
                          {shipment_id}
                        </Typography>
                      </div>
                      {(shipment_billing && shipment_type !== 'ltl') ||
                      (shipment_type === 'ltl' && shipment_billing.length === 1) ? (
                        <Typography variant='s3' style={{ color: palette.dark800 }}>
                          {shipment_billing?.[0]?.reference_id}
                        </Typography>
                      ) : (
                        <OverlayTrigger
                          placement='bottom'
                          overlay={
                            <Tooltip id='button-tooltip-2'>
                              {shipment_billing?.map((el, i) => (
                                <p key={el?.id || i} className='m-0'>
                                  {el?.reference_id}
                                </p>
                              ))}
                            </Tooltip>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <div ref={ref} className='ellipsis'>
                              <Typography variant='s3' {...triggerHandler} style={{ color: palette.dark800 }}>
                                Multi-Ref LTL
                              </Typography>
                            </div>
                          )}
                        </OverlayTrigger>
                      )}
                      <div className='d-flex align-items-center gap-1'>
                        <Typography variant='s3'>{getShipmentType(shipment_type)}</Typography>
                        {(!shipment_type || shipment_type === 'ltl') &&
                          (shipment.lane_id ? (
                            <RecurringLane fill={palette.indigo500} />
                          ) : (
                            <OneTime fill={palette.indigo500} />
                          ))}
                        {!!reverted && (
                          <CustomTooltip title='Reverted'>
                            <ReloadIcon fill={palette.red500} />
                          </CustomTooltip>
                        )}
                        {shipment_stops?.some((i) => i.hazardous_materials) && (
                          <Typography variant='c1' style={{ color: palette.orange400 }}>
                            HAZMAT
                          </Typography>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column align-items-start'>
                      <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                        {Math.round(shipment.empty_miles || 0)}{' '}
                        <Typography variant='c3' style={{ fontSize: '10px' }}>
                          EMPTY
                        </Typography>
                      </Typography>
                      <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                        {Math.round(shipment.loaded_miles || 0)}{' '}
                        <Typography variant='c3' style={{ fontSize: '10px' }}>
                          LOADED
                        </Typography>
                      </Typography>
                      <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                        {Math.round(shipment.loaded_miles || 0) + Math.round(shipment.empty_miles || 0)}{' '}
                        <Typography variant='c3' style={{ fontSize: '10px' }}>
                          TOTAL MILES
                        </Typography>
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-center flex-column'>
                      <Typography variant='s3' style={{ color: palette.dark800 }}>
                        {type}
                      </Typography>
                      <Typography variant='s3' style={{ color: palette.dark800 }}>
                        {!firstNullArrivalDate?.stop_point_type
                          ? '-'
                          : equipmentTypeText({
                              type: firstNullArrivalDate.stop_point_type,
                              action: firstNullArrivalDate.equipment_action,
                              equipment: firstNullArrivalDate.equipment,
                              equipment_tbd: firstNullArrivalDate.equipment_tbd,
                            })}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column justify-content-center'>
                      <Typography variant='b3'>{originStopName}</Typography>
                      <Typography variant='s3' style={{ color: palette.dark800 }}>
                        {originCity && `${originCity},`} {originState}
                        {originZipcode && `, ${originZipcode}`}
                      </Typography>
                      <Typography variant='b3'>
                        {formatDateTime(`${originFrom}`)}
                        {!!originToDate && [1, 3].includes(Number(originScheduledType))
                          ? ` to ${formatDateTime(originTo)}`
                          : ''}
                        {originScheduledType ? ` (${SCHEDULED_DATE_TYPE_OBJ[originScheduledType].shortName})` : ''}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <ArrowRight width={18} height={18} fill={palette.gray700} />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column justify-content-center'>
                      <Typography variant='b3'>{destinationStopName}</Typography>
                      <Typography variant='s3' style={{ color: palette.dark800 }}>
                        {destinationCity && `${destinationCity},`} {destinationState}
                        {destinationZipcode && `, ${destinationZipcode}`}
                      </Typography>
                      <Typography variant='b3'>
                        {formatDateTime(`${destinationFrom}`)}
                        {!!destinationToDate && [1, 3].includes(Number(destinationScheduledType))
                          ? ` to ${formatDateTime(destinationTo)}`
                          : ''}
                        {destinationScheduledType
                          ? ` (${SCHEDULED_DATE_TYPE_OBJ[destinationScheduledType].shortName})`
                          : ''}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex flex-column align-items-start'>
                        <Typography variant='s3' style={{ color: palette.dark800 }} className='nowrap'>
                          {shipment.cost === null ? 'Flat' : `${currency}${formatNumber(shipment.cost)}`}
                        </Typography>
                        <Typography
                          className='nowrap'
                          variant='s3'
                          style={{
                            color: shipment.grossRevenue - (shipment.cost || 0) < 0 ? palette.red500 : palette.green500,
                          }}
                        >
                          {currency}
                          {shipment.grossRevenue} ({shipment.grossRevenue - (shipment.cost || 0) < 0 ? '-' : '+'}
                          {formatNumber(((shipment.grossRevenue - (shipment.cost || 0)) / shipment.grossRevenue) * 100)}
                          %)
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </STable>
      )}
      {!loading && !backhaul.length && <NoRecords />}
    </div>
  );
};

export default RecommendedBackhaul;
