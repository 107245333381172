import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 16px 0;
  margin-top: 16px;
  border-bottom: 1px solid ${palette.gray50};
  border-top: 1px solid ${palette.gray50};
`;

export const SRow = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  & > * {
    :not(:first-child) {
      border-left: 1px solid ${palette.gray50};
      padding-left: 20px;
    }

    :last-child {
      min-width: 200px;
      padding-right: 48px;
    }
  }
`;

export const SInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  .pay-type-chip-wrapper {
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
  }
`;

export const SMiles = styled.div`
  display: flex;
  column-gap: 4px;

  .MuiChip-root {
    .MuiChip-label {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

export const SPayTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  color: ${palette.gray300};

  .pay-type-chip-wrapper {
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
  }

  .MuiChip-label {
    font-size: 12px;
    font-weight: 500;
  }
`;
