import React, { useContext, useEffect, useState } from 'react';
import { Channel, Thread, Window } from 'stream-chat-react';
import '@stream-io/stream-chat-css/dist/css/index.css';

import { sendGroupChatMessage } from 'Api/chat';
import { PusherJs } from 'common/pusher';
import { LayoutContext } from 'components/layout';
import ChatPage from 'components/chatAppComponents/Chat/ChatPage';
import ViewAgreement from 'components/Billing/Transactions/ViewAgreement';
import VoiceRecorder from 'components/chatAppComponents/VoiceRecordingCom/VoiceRecorder';
import ChatAppServices from 'services/chatApp';
import SideBar from './components/SideBar';
import MutedComp from './components/MutedComp';
import ChannelsList from './components/ChannelsList';
import MessageInner from './components/MessageInner';
import GroupMembers from './components/GroupMembers';
import AddNotesComp from './components/AddNotesComp';
import PinnedMessage from './components/PinnedMessage';
import CustomChannelHeader from './components/CustomChannelHeader';
import { tabOptions } from './ChatApp.data';
import './chatApp.css';

const smsTwoWayGuidePdf =
  'https://uploads-ssl.webflow.com/622a6c4480e87b07329e6b2d/63e71a4d037f3b5b22b2e2c9_Truckin%20Digital%20-%20Two-Way%20SMS.pdf';

const ChatApp = () => {
  const { users, chatUser, dot_num } = useContext(LayoutContext);
  const [showMember, setShowMember] = useState(false);
  const [channelMembers, setChannelMembers] = useState(null);
  const [activeChannel, setActiveChannel] = useState(null);
  const [voiceRecording, setVoiceRecording] = useState(false);
  const [showScheduleMsg, setShowScheduleMsg] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isNotes, setNotes] = useState(false);
  const [isLicence, setIsLicence] = useState(null);
  const [showLicence, setShowLicence] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [filteredNotesData, setFilteredNotesData] = useState([]);
  const [pinnedMessage, setPinnedMessage] = useState(false);
  const [muted, setMuted] = useState(false);
  const [updateMutedStatus, setUpdateMutedStatus] = useState(null);
  const [showGuide, setShowGuide] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [activeTab, setActiveTab] = useState(tabOptions[0]);
  const [activeInnerTab, setActiveInnerTab] = useState(1);

  const getScheduleMessage = () => {
    ChatAppServices.getSendMessageLater().then((res) => {
      setMessageData(res.data.data);
    });
  };

  const getAllNotes = () => {
    const Id = activeChannel?.data.cid;
    ChatAppServices.getNotes(Id).then((res) => setNotesData(res.data.data));
  };

  const checkSMSLicence = (customSmsMessage) => {
    ChatAppServices.checkSMSLicence().then((res) => {
      setIsLicence(res.data.is_two_way_sms_active);
      setShowLicence(!res.data.is_two_way_sms_active);
      if (res.data.is_two_way_sms_active) {
        let driver_id = null;
        let staff_id = null;
        let phone_number = null;
        const myArray = activeChannel.id.split('_');
        if (activeChannel.id.includes('driver_group')) {
          driver_id = myArray[myArray.length - 1];
        } else if (activeChannel.id.includes('staff_group')) {
          staff_id = myArray[myArray.length - 1];
        } else if (activeChannel.id.includes('anonymous')) {
          phone_number = activeChannel.data.phone_number;
        }

        const data = {
          receiver_id: activeChannel.cid,
          message_text: customSmsMessage,
          sender_id: chatUser.id,
          group_id: null,
          driver_id,
          staff_id,
          phone_number,
        };
        sendGroupChatMessage(data).then(() => {
          const list = document.getElementsByClassName(
            'str-chat__message-text-inner str-chat__message-simple-text-inner'
          );
          Array.prototype.filter.call(list, (testElement) => {
            if (testElement.innerText.includes('[SMS]')) {
              testElement.classList.add('sms');
            }
          });
        });
      }
    });
  };

  useEffect(() => {
    const channel = PusherJs.subscribe(`chat-send-later-channel.${dot_num}`);
    channel.bind('chat-send-later-channel', () => {
      getScheduleMessage();
    });

    return () => {
      channel.unsubscribe(`chat-send-later-channel.${dot_num}`);
    };
  }, []);

  useEffect(() => {
    getAllNotes();
  }, [isNotes, activeChannel]);

  useEffect(() => {
    if (activeChannel !== null) {
      const u = activeChannel?.state.members;
      const memberList = [];
      Object.keys(u).forEach((key) => {
        const value = u[key];
        memberList.push(value);
      });
      setChannelMembers(memberList);
    }
  }, [activeChannel]);

  useEffect(() => {
    if (notesData.length !== 0) {
      setFilteredNotesData(notesData.filter((n) => n.chat_user_id === activeChannel.data.cid));
    } else {
      setFilteredNotesData([]);
    }
  }, [activeChannel, notesData]);

  useEffect(() => {
    getScheduleMessage();
  }, [scheduleMsg]);

  useEffect(() => {
    if (messageData.length !== 0) {
      setMessages(messageData.filter((m) => m.receiver_id === activeChannel?.data?.cid && m.status === 'P'));
    } else {
      setMessages([]);
    }
  }, [messageData, activeChannel]);

  return (
    <div className='chat-app'>
      {voiceRecording && <VoiceRecorder setVoiceRecording={setVoiceRecording} activeChannel={activeChannel} />}
      <SideBar
        open={openSideBar}
        onClose={() => setOpenSideBar(false)}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        activeInnerTab={activeInnerTab}
        setActiveInnerTab={setActiveInnerTab}
      />
      <div className='section-one '>
        <ChannelsList
          updateMutedStatus={updateMutedStatus}
          setUpdateMutedStatus={setUpdateMutedStatus}
          openSideBar={openSideBar}
          setOpenSideBar={setOpenSideBar}
          tab={activeTab}
          activeTab={activeInnerTab}
          setActiveTab={setActiveInnerTab}
        />
      </div>
      <div className='section-two'>
        <div className='chat_area' style={{ width: showMember || isNotes ? '75%' : '100%' }}>
          <ChatPage>
            <Channel>
              <Window>
                <CustomChannelHeader
                  setactiveChannel={setActiveChannel}
                  setShowMember={setShowMember}
                  chatUser={chatUser}
                  setShowShedulemsg={setShowScheduleMsg}
                  messageData={messages}
                  setNotes={setNotes}
                  isLicence={isLicence}
                  showLicence={showLicence}
                  setshowLicence={setShowLicence}
                  notesData={filteredNotesData}
                  checkSMSLicence={checkSMSLicence}
                  setShowGuide={setShowGuide}
                  updateMutedStatus={updateMutedStatus}
                  setUpdateMutedStatus={setUpdateMutedStatus}
                />
                <MessageInner
                  setVoiceRecording={setVoiceRecording}
                  activeChannel={activeChannel}
                  chatUser={chatUser}
                  setsheduleMsg={setScheduleMsg}
                  sheduleMsg={scheduleMsg}
                  checkSMSLicence={checkSMSLicence}
                  setpinnedMessage={setPinnedMessage}
                  setMuted={setMuted}
                  onAddNoteSuccess={getAllNotes}
                />
              </Window>
              <Thread />
            </Channel>
          </ChatPage>
        </div>
        <GroupMembers
          channelMembers={channelMembers}
          showMember={showMember}
          setShowMember={setShowMember}
          users={users}
          chatUser={chatUser}
          dot_num={dot_num}
          showShedulemsg={showScheduleMsg}
          messageData={messages}
          getSeduleMessage={getScheduleMessage}
        />
        <AddNotesComp
          isNotes={isNotes}
          setNotes={setNotes}
          chatUser={chatUser}
          activeChannel={activeChannel}
          notesData={filteredNotesData}
          getAllNotes={getAllNotes}
        />
        <PinnedMessage
          pinnedMessage={pinnedMessage}
          setpinnedMessage={setPinnedMessage}
          activeChannel={activeChannel}
        />
        <MutedComp
          setMuted={setMuted}
          isMuted={muted}
          activeChannel={activeChannel}
          updateMutedStatus={updateMutedStatus}
          setUpdateMutedStatus={setUpdateMutedStatus}
        />
      </div>
      {!!showGuide && (
        <ViewAgreement title='Guide' url={showGuide ? smsTwoWayGuidePdf : null} onClose={() => setShowGuide(false)} />
      )}
    </div>
  );
};

export default ChatApp;
