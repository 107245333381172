import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { GetCompanyProfile, GetSettingsAccounting } from 'Api/CompanySettings';
import { generatePDF } from './helpers';
import { SWrapper, SHeader, SBody } from './InvoiceAppearance.styles';

const InvoiceAppearance = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { formatDate, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [company, setCompany] = useState(null);

  const getAccountData = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountData(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCompanyData = async () => {
    try {
      const res = await GetCompanyProfile();
      setCompany(res);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (!!accountData && !!company) {
      const { url } = generatePDF({ data: accountData, company, currency, formatDate, formatDateTime });
      setPdfUrl(url);
    }
  }, [accountData, company]);

  useEffect(() => {
    getAccountData();
    getCompanyData();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <SWrapper>
      <SHeader>
        <Typography variant='h2' style={{ color: palette.gray900 }}>
          Invoice Appearance
        </Typography>
        <div className='actions'>
          {isConfiguringStep && (
            <>
              {' '}
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('saveAndNext')}
                type='primary'
                title={isConfiguringStep ? 'Save and Next' : 'Save'}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ margin: 0 }}
              />
            </>
          )}
        </div>
      </SHeader>
      <SBody>
        <div className='image-wrapper'>
          <figure className='figure'>{pdfUrl && <iframe title='pdf' className='pdf' src={pdfUrl} />}</figure>
        </div>
      </SBody>
    </SWrapper>
  );
};

export default InvoiceAppearance;
