import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import { Divider } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import { changLog, deleteDocument, sendDocumentViaEmail, sendDocumentViaFax, updateDocumentStatus } from 'Api/Document';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import ConfirmModal from 'common/ConfirmModal';
import Autocomplete from 'common/Autocomplete';
import copy_button from 'assets/icons/documents/copy_button.png';
import fax from 'assets/icons/documents/fax.svg';
import mail_button from 'assets/icons/documents/mail_button.png';
import delete_button from 'assets/icons/documents/delete_button.png';
import xIcon from 'assets/icons/x.png';
import { getStops } from 'Api/Shipment';
import ActivityLogList from '../ActivityLogList';
import CustomModal from '../CustomModal/CustomModal';
import CustomizedSnackbars from '../toast/Toast';
import styles from './OpenDocumentModal.module.css';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';
import ErrorMessage from '../../common/ErrorMessage';

const Status = {
  Rejected: 2,
  Approved: 1,
  Reviewing: 0,
};

const useStyles = makeStyles({
  root: {
    width: 260,
    background: '#E9EDF5',
    borderRadius: 6,
    minHeight: '32px !important',
    padding: 3,
  },
  tabRoot: {
    textTransform: 'none !important',
    fontSize: '14px !important',
    padding: '0px !important',
    minHeight: '28px !important',
    minWidth: '84px !important',
  },
  selected: {
    color: 'var(--indigo-500) !important',
    background: '#FFFFFF !important',
    boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px !important',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && { ...children }}
    </div>
  );
}

const OpenDocumentModal = ({
  data,
  onClose,
  isOpen,
  onSubmit,
  documentTypeList,
  shipmentTypeList,
  onDeleteSuccess,
  loadingUpdate,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [status, setStatus] = useState();
  const [activityLog, setActivityLog] = useState();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [shipmentStops, setShipmentStops] = useState([]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openSendFax, setOpenSendFax] = useState(false);
  const [documentTypeSelected, setDocumentTypeSelected] = useState({});
  const [shipmentIdSelected, setShipmentIdSelected] = useState({});
  const [showReasonError, setShowReasonError] = useState(false);
  const whitelist = ['US', 'CA', 'MX'];

  const sendEmailFormik = useFormik({
    initialValues: {
      email: '',
    },
  });

  const sendFaxFormik = useFormik({
    initialValues: {
      fax_number: '',
    },
  });
  const classes = useStyles();
  const { use } = useTheme();
  const [showMessage, setShowMessage] = useState({
    message: 'Document URL copied to successfully!',
    visible: false,
    type: 'success',
  });

  useEffect(() => {
    if (data.status.status === 'New' || data.status.status === 'Reviewing') {
      setTabValue(0);
      updateDocumentStatus(2, data.id)
        .then(() => {
          setStatus(2);
          changLog(data.id).then((res) => {
            setActivityLog(res.data);
          });
        })
        .catch(() => {
          // Do nothing
        });
    } else {
      setTabValue(Status[data.status?.status]);
      changLog(data.id).then((res) => {
        setActivityLog(res.data);
      });
    }
    if (data.document_type) {
      setDocumentTypeSelected({
        ...data.document_type,
        key: data.document_type.id,
        label: data.document_type.name,
        labelSelected: null,
      });
    }
    if (data.shipment_id) {
      setShipmentIdSelected({
        key: data.shipment_id,
        label: data.shipment_id,
        labelSelected: null,
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      document_type: data.document_type?.id,
      shipment_id: data.shipment_id,
      shipment_stop: null,
      reference_id: data.reference_id,
      status: data.status.value,
      notes: data.notes,
      reason: data.reason,
    },
  });
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      setStatus(2);
    } else if (newValue === 1) {
      setStatus(4);
    } else {
      setStatus(3);
    }
  };

  const deleteDocumentById = () => {
    setOpenDeleteConfirm(false);
    deleteDocument(data.id).then(() => {
      onDeleteSuccess();
    });
  };

  const getInitialStop = async () => {
    try {
      const { data: stopData } = await getStops({ shipment_id: formik.values.shipment_id });
      formik.setFieldValue('shipment_stop', stopData.find((stop) => stop.id === Number(data.shipment_stop_id)) || null);
      setShipmentStops(stopData);
    } catch (e) {
      // Do nothing
    }
  };

  const getShipmentStops = async () => {
    try {
      const { data } = await getStops({ shipment_id: formik.values.shipment_id });
      setShipmentStops(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(data.document_path);
    setShowMessage({
      ...showMessage,
      visible: true,
    });
  };

  const submitEmailModal = (form) => {
    if (form.values) {
      sendDocumentViaEmail({ document_id: data.id, email: form.values.email })
        .then(() => {
          setIsEmailModalOpen(false);
          setShowMessage({
            message: 'Document sent successfully!',
            visible: true,
            type: 'success',
          });
          setTimeout(() => {
            setShowMessage({
              visible: false,
              message: '',
            });
          }, 6000);
        })
        .catch(() => {
          setShowMessage({
            message: 'Document was not sent, there was an error sending!',
            visible: true,
            type: 'error',
          });
          setTimeout(() => {
            setShowMessage({
              visible: false,
              message: '',
            });
          }, 6000);
        });
    }
  };

  const submitFaxModal = (form) => {
    if (form.values) {
      sendDocumentViaFax({ document_id: data.id, fax_number: form.values.fax_number })
        .then(() => {
          setOpenSendFax(false);
          setShowMessage({
            message: 'Document sent successfully!',
            visible: true,
            type: 'success',
          });
          setTimeout(() => {
            setShowMessage({
              visible: false,
              message: '',
            });
          }, 6000);
        })
        .catch((err) => {
          setShowMessage({
            message: err?.response?.data?.message || 'Document was not sent, there was an error sending!',
            visible: true,
            type: 'error',
          });
          setTimeout(() => {
            setShowMessage({
              visible: false,
              message: '',
            });
          }, 6000);
        });
    }
  };

  useEffect(() => {
    if (formik.values.shipment_id && formik.values.shipment_id !== 'No Shipment') {
      getShipmentStops();
    }
  }, [formik.values.shipment_id]);

  useEffect(() => {
    if (data.shipment_id && data.shipment_id !== 'No Shipment' && data.shipment_stop_id) {
      getInitialStop();
    }
  }, []);

  return (
    <>
      <Modal show={isOpen} onHide={onClose} dialogClassName={styles['my-modal']} centered backdrop='static'>
        <Modal.Header
          className='connect-modal-header-footer'
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title id='contained-modal-title-vcenter' style={{ color: use('#171c26', palette.gray200) }}>
            {data?.document_type?.name}
          </Modal.Title>
          <div>
            <img className='m-2' src={fax} alt='' onClick={() => setOpenSendFax(true)} />
            <img className='m-2' src={copy_button} alt='' onClick={onCopy} />
            <a onClick={() => setIsEmailModalOpen(true)}>
              <img className='m-2' src={mail_button} alt='' />
            </a>
            <img className='m-2' src={delete_button} onClick={() => setOpenDeleteConfirm(true)} alt='' />
            <img src={xIcon} alt='x' height={15} onClick={onClose} />
          </div>
        </Modal.Header>
        <Modal.Body
          className='d-flex'
          style={{ backgroundColor: use(palette.gray0, palette.dark800), justifyContent: 'space-between' }}
        >
          <div className={styles.rightBar}>
            <div>
              <label className={styles.label_wrapper}>
                <span>Status</span>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  classes={{ root: classes.root }}
                  aria-label='full width tabs example'
                >
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selected,
                    }}
                    label='Reviewing'
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selected,
                    }}
                    label='Approve'
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selected,
                    }}
                    label='Reject'
                  />
                </Tabs>
                <div>
                  <TabPanel value={tabValue} index={tabValue}>
                    <div>
                      {tabValue === 2 ? (
                        <div style={{ marginTop: '40px' }}>
                          <label className={styles.label_wrapper}>
                            <span>
                              <span style={{ color: 'red' }}>(!)</span> Reject Reason
                            </span>
                            <input
                              type='text'
                              name='reason'
                              id='reason'
                              className={styles['reference-field']}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                formik.setFieldValue('reason', e.target.value);
                                setShowReasonError(false);
                              }}
                              value={formik.values.reason}
                            />
                            {showReasonError ? <ErrorMessage error='Required!' /> : null}
                          </label>
                        </div>
                      ) : null}
                      <div style={{ marginTop: '40px' }}>
                        <label className={styles.label_wrapper}>
                          <span className={styles.label_required}>Document Type</span>
                        </label>
                        <div>
                          <CustomSelect
                            defaultValue='Select...'
                            options={documentTypeList}
                            onChange={(value) => {
                              formik.setFieldValue('document_type', value.id);
                            }}
                            defaultActiveValue={documentTypeSelected}
                            styles={{ height: 32, marginTop: 12 }}
                            onBlur={formik.handleBlur}
                            value={formik.values.document_type}
                            name='name'
                            disabled={
                              data?.document_type?.name === 'Carrier Confirmation' && !data?.document_type?.is_delete
                            }
                          />
                          {formik.touched.document_type && formik.errors.document_type ? (
                            <span className='error-message'>{formik.errors.document_type}</span>
                          ) : null}
                        </div>
                      </div>

                      {data?.document_type?.name !== 'Paper Logbook' && (
                        <div style={{ marginTop: '40px' }}>
                          <label className={styles.label_wrapper}>
                            <span>Shipment ID</span>
                          </label>
                          <div>
                            <CustomSelect
                              defaultValue='Select...'
                              isSearchable
                              options={shipmentTypeList}
                              onChange={(value) => {
                                formik.setValues((prevState) => ({
                                  ...prevState,
                                  shipment_id: value.shipment_id,
                                  shipment_stop: null,
                                }));
                              }}
                              defaultActiveValue={shipmentIdSelected}
                              styles={{ height: 32, marginTop: 12 }}
                              onBlur={formik.handleBlur}
                              value={formik.values.shipment_id}
                              id='shipment_id'
                              name='shipment_id'
                              isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 12 }}
                            />
                          </div>
                        </div>
                      )}

                      {!!formik.values.shipment_id && formik.values.shipment_id !== 'No Shipment' && (
                        <div style={{ marginTop: '40px' }}>
                          <Autocomplete
                            name='shipment_stop'
                            label='Shipment Stop'
                            options={shipmentStops}
                            value={formik.values.shipment_stop}
                            onChange={(e, val) => formik.setFieldValue('shipment_stop', val)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.stop_point.location_name}
                              </li>
                            )}
                            getOptionLabel={(option) => `${option.stop_point.location_name}`}
                          />
                        </div>
                      )}

                      <div style={{ marginTop: '40px' }}>
                        <label className={styles.label_wrapper}>
                          <span>Reference</span>
                          <input
                            type='text'
                            name='reference_id'
                            id='reference_id'
                            className={styles['reference-field']}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.reference_id}
                          />
                        </label>
                      </div>

                      <div style={{ marginTop: '40px' }}>
                        <label className={styles.label_wrapper}>
                          <span>Sender Notes</span>
                          <textarea
                            readOnly
                            name='notes'
                            id='notes'
                            className={styles['note-filed']}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={
                              data?.document_type?.name === 'Paper Logbook' && !!data?.metadata?.shipment_ids?.length
                                ? data.metadata.shipment_ids
                                    .map((id, i) => `${i === 0 ? 'Shipment' : 'shipment'} ${id}`)
                                    .join(', ')
                                : formik.values.notes
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </label>
            </div>
            <div className='mt-4'>
              <p className={styles['activity-text']}>Activity Log</p>
              <Divider />
              <div className={styles['activity-wrapper']}>
                <ActivityLogList activityLog={activityLog} />
              </div>
            </div>
          </div>
          <div className={styles.leftBar}>
            <iframe
              style={{ height: '100%' }}
              src={`${data.document_path}`}
              title='testPdf'
              height='100%'
              width='100%'
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          className='connect-modal-header-footer'
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Button disabled={loadingUpdate} className='Cancel-modal-btn' onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={loadingUpdate}
            className={styles.primary}
            onClick={() => {
              if (tabValue === 2 && !formik.values.reason) {
                setShowReasonError(true);
              } else {
                onSubmit(formik, data, status);
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        open={!!openDeleteConfirm}
        title='Confirm Your Action'
        text='Are you sure you want to delete this document?'
        btnType='primary'
        btnText='Delete'
        onClose={() => setOpenDeleteConfirm(null)}
        onConfirm={() => deleteDocumentById()}
      />
      <CustomModal
        showModal={isEmailModalOpen}
        styleBody={{ width: 446, backgroundColor: '#F7F9FC' }}
        onHide={() => setIsEmailModalOpen(false)}
        headerTitle='Send Document'
        customBody={{
          background: use(palette.white, palette.gray900),
          paddingTop: 0,
          paddingBottom: '20px',
          marginTop: '-30px',
        }}
        styleButtons={{ padding: '8px 12px' }}
        buttons={[
          { key: 'close', type: 'secondary', title: 'Cancel' },
          { key: 'submit', type: 'primary', title: 'Send Document', onClick: () => submitEmailModal(sendEmailFormik) },
        ]}
      >
        <div className={styles['document-container']}>
          <div>
            <label className={styles.label_wrapper}>
              <span className={styles.label_required}>Email</span>
              <input
                type='text'
                name='email'
                id='email'
                className={styles['reference-field']}
                onBlur={sendEmailFormik.handleBlur}
                onChange={sendEmailFormik.handleChange}
                value={sendEmailFormik.values.email}
              />
            </label>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        showModal={openSendFax}
        styleBody={{ width: 446, backgroundColor: '#F7F9FC' }}
        onHide={() => setOpenSendFax(false)}
        headerTitle='Fax Document'
        customBody={{ background: use(palette.white, palette.gray900), paddingTop: 0, marginTop: '-30px' }}
        styleButtons={{ padding: '8px 12px' }}
        buttons={[
          { key: 'close', type: 'secondary', title: 'Cancel' },
          { key: 'submit', type: 'primary', title: 'Fax Document', onClick: () => submitFaxModal(sendFaxFormik) },
        ]}
      >
        <div className={styles['document-container']}>
          <div>
            <label className={styles.label_wrapper}>
              <span className={styles.label_required}>Fax Number </span>
              <PhoneInput
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
                addInternationalOption={false}
                className={styles['reference-field']}
                value={sendFaxFormik.values.fax_number}
                countries={whitelist}
                onChange={(val) => sendFaxFormik.setFieldValue('fax_number', val)}
                defaultCountry='US'
                placeholder='(555) 555-1234'
              />
            </label>
          </div>
        </div>
      </CustomModal>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default OpenDocumentModal;
