import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { useTheme } from 'context/themeContext';
import { getStopPointType } from 'Api/Planner';
import Input from 'common/Input';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { createCustomerContactBook } from 'Api/Customers';
import Tooltip from 'common/Tooltip';
import sm from './ContactBook.module.css';
import { Typography } from '../../Typography';
import CustomInput from '../../CreateShipment/helpers/CustomInput';
import CustomPhoneInput from '../../CustomPhoneInput';
import CustomSelect from '../../CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';
import CustomButton from '../../CustomButton/CustomButton';

const ContactBook = ({ Cancel, nextStep, customer }) => {
  const { use } = useTheme();

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const [onNextloading, setOnNextloading] = useState(false);
  const formRef = useRef();

  const onSkip = () => {
    nextStep();
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      if (formRef.current.isValid && formRef.current.dirty) {
        onNextStep(formRef.current.values.contact_books);
      }
    }
  };
  const defaultValue = {
    contact_name: '',
    contact_email: '',
    phone_number: '',
    contact_type_id: '',
    invoicing: false,
    portal_access: false,
    email_updates: false,
    sms_updates: false,
    extension: '',
  };

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const [typeData, setTypeData] = useState([]);

  const getTypeData = () => {
    getStopPointType()
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              labelSelected: null,
            };
          });
          setTypeData(selectData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const validateEmail = (value) => {
    let error;
    const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!isValidEmail.test(value) && value) {
      error = 'Invalid email address';
    }
    // else if (!value) {
    //   error = 'Email address is required';
    // }
    return error;
  };

  const onNextStep = (values) => {
    const f = new FormData();
    values.forEach((item, index) => {
      f.append(`contact_books[${index}][contact_name]`, item.contact_name);
      f.append(`contact_books[${index}][contact_email]`, item.contact_email);
      f.append(`contact_books[${index}][phone_number]`, item.phone_number);
      if (item.extension) {
        f.append(`contact_books[${index}][extension]`, item.extension);
      }
      f.append(`contact_books[${index}][contact_type_id]`, item.contact_type_id);
      f.append(`contact_books[${index}][contact_type_id]`, item.contact_type_id);
      f.append(`contact_books[${index}][sms_updates]`, item.sms_updates ? 1 : 0);
      f.append(`contact_books[${index}][invoicing]`, item.invoicing ? 1 : 0);
      f.append(`contact_books[${index}][portal_access]`, item.portal_access ? 1 : 0);
      f.append(`contact_books[${index}][email_updates]`, item.email_updates ? 1 : 0);
    });
    f.append(`customer_id`, customer.basicDetails.customer);
    createCustomerContactBook(f)
      .then((res) => {
        if (res?.status === 200) {
          setOnNextloading(false);
          setShowMessage({ ...showMessage, message: 'Contact added successfully!', visible: true });
          nextStep(res.data);
        }
      })
      .catch(() => setOnNextloading(false));
  };

  const initialValue = customer.contactBook.data
    ? { contact_books: customer.contactBook.data }
    : { contact_books: [{ ...defaultValue }] };

  useEffect(() => {
    getTypeData();
  }, []);

  return (
    <>
      <Modal.Body className='add-customer-modal-body'>
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <Typography>Contact Book</Typography>
              <Formik initialValues={initialValue} innerRef={formRef}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <FieldArray
                      name='contact_books'
                      render={(arrayHelpers) => {
                        return (
                          <div className={sm.fieldArrayWrapper}>
                            {values.contact_books.map((el, index) => {
                              const namePrefix = `contact_books[${index}]`;
                              return (
                                <div
                                  key={el?.id}
                                  className={[sm.row, sm.line, sm.fieldsWrapper].join(' ')}
                                  style={{ marginTop: 0 }}
                                >
                                  <div className={sm.field_wrapper}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        Name <span className={sm.required_system}>*</span>
                                      </Typography>
                                    )}
                                    <Field
                                      name={`${namePrefix}[contact_name]`}
                                      component={CustomInput}
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                      style={{ paddingLeft: 10, width: '200px' }}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[contact_name]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div className={sm.field_wrapper}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        Email
                                      </Typography>
                                    )}
                                    <Field
                                      name={`${namePrefix}[contact_email]`}
                                      component={CustomInput}
                                      validate={validateEmail}
                                      labelStyle={styles.labelStyle}
                                      className={sm.input_basic_details}
                                      style={{ paddingLeft: 10, width: '200px' }}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[contact_email]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div className={sm.field_wrapper}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        Phone Number <span className={sm.required_system}>*</span>
                                      </Typography>
                                    )}
                                    <Field
                                      name={`${namePrefix}[phone_number]`}
                                      inputStyle={{ width: '200px' }}
                                      labelStyle={{ marginTop: 0 }}
                                      countryCodeEditable={false}
                                      component={CustomPhoneInput}
                                      validate={validateStopPointInput}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[phone_number]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>
                                  <div className={sm.field_wrapper}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        EXT
                                      </Typography>
                                    )}
                                    <Input
                                      width='70px'
                                      type='number'
                                      name={`${namePrefix}[extension]`}
                                      value={values.contact_books?.[index]?.extension}
                                      onChange={(e) => {
                                        if (Number(e.target.value) > 999) {
                                          return;
                                        }
                                        setFieldValue(`${namePrefix}[extension]`, e.target.value);
                                      }}
                                      onKeyDown={(e) => {
                                        blockNotNumberChars(e);
                                        blockNonPositiveNumbers(e);
                                      }}
                                    />
                                  </div>

                                  <div className={sm.field_wrapper}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        Type <span className={sm.required_system}>*</span>
                                      </Typography>
                                    )}
                                    <div style={{ width: 130 }}>
                                      <Field
                                        name={`${namePrefix}[contact_type_id]`}
                                        options={typeData}
                                        styles={styles.type}
                                        component={CustomSelect}
                                        menuStyles={styles.fullWidthPercent}
                                        validate={validateStopPointInput}
                                      />
                                      <ErrorMessage
                                        name={`${namePrefix}[contact_type_id]`}
                                        render={(error) => (
                                          <Typography variant='c2' style={styles.error}>
                                            {error}
                                          </Typography>
                                        )}
                                      />
                                    </div>
                                  </div>

                                  <div className={sm.field_wrapper2}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        Invoicing
                                      </Typography>
                                    )}
                                    <Field name={`${namePrefix}[invoicing]`}>
                                      {({ field }) => (
                                        <CustomCheckbox
                                          name={`${namePrefix}[invoicing]`}
                                          field={field}
                                          type='switch'
                                          withinForm
                                          checked={!!field.value}
                                        />
                                      )}
                                    </Field>
                                  </div>

                                  <div className={sm.field_wrapper2}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        Portal Access
                                      </Typography>
                                    )}
                                    <Tooltip title='This feature is not available'>
                                      <span>
                                        <Field name={`${namePrefix}[portal_access]`}>
                                          {({ field }) => (
                                            <CustomCheckbox
                                              name={`${namePrefix}[portal_access]`}
                                              field={field}
                                              type='switch'
                                              withinForm
                                              checked={!!field.value}
                                              disabled
                                            />
                                          )}
                                        </Field>
                                      </span>
                                    </Tooltip>
                                  </div>

                                  <div className={sm.field_wrapper2}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        Email Updates
                                      </Typography>
                                    )}
                                    <Field name={`${namePrefix}[email_updates]`}>
                                      {({ field }) => (
                                        <CustomCheckbox
                                          name={`${namePrefix}[email_updates]`}
                                          field={field}
                                          type='switch'
                                          withinForm
                                          checked={!!field.value}
                                        />
                                      )}
                                    </Field>
                                  </div>

                                  <div className={sm.field_wrapper2}>
                                    {index === 0 && (
                                      <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                        SMS Updates
                                      </Typography>
                                    )}
                                    <Field name={`${namePrefix}[sms_updates]`}>
                                      {({ field }) => (
                                        <CustomCheckbox
                                          name={`${namePrefix}[sms_updates]`}
                                          field={field}
                                          type='switch'
                                          withinForm
                                          checked={!!field.value}
                                        />
                                      )}
                                    </Field>
                                  </div>

                                  {values.contact_books.length > 1 && index !== 0 ? (
                                    <DeleteIcon
                                      width={12}
                                      height={12}
                                      fill={palette.indigo500}
                                      style={styles.deleteIcon}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  ) : (
                                    <span style={{ width: 28 }} />
                                  )}
                                </div>
                              );
                            })}

                            <CustomButton
                              type='secondary'
                              title='Add Another'
                              styleTitle={styles.addAnotherTitle}
                              styleButton={styles.addAnotherButton}
                              onClick={() => arrayHelpers.push({ ...defaultValue })}
                              leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                            />
                          </div>
                        );
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button className='cancel-button' onClick={onSkip}>
              Skip
            </Button>
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button onClick={handleSubmit} className='next-step'>
                Next Step
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default ContactBook;
