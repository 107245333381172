export const shouldRender = (tab, channel) => {
  if (tab.id === 2 && channel.type !== 'truckindigital') {
    return false;
  }

  if (tab.id === 3 && channel.type !== 'messaging') {
    return false;
  }

  return !(tab.id === 4 && channel.type !== 'driver_channel');
};
