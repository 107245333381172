export const getInitialValues = (data) => {
  return {
    title: data?.title || '',
    code: data?.code || '',
    account: data?.account_id || null,
    type_id: data?.type?.id || 1,
    desc: data?.desc || '',
    is_taxable: data?.is_taxable || false,
    tax_percentage: data?.tax_percentage || '',
    tax_account: null,
  };
};
