import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { convertToCustomerTime } from 'utils/helpers';
import React from 'react';
import useDateFormat from 'hooks/useDateFormat';

const Header = ({ date }) => {
  const { dateTimeFormat } = useDateFormat();

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        Take Action
      </Typography>
      <Typography variant='s2' style={{ color: palette.gray900 }}>
        {convertToCustomerTime(date, dateTimeFormat)}
      </Typography>
    </div>
  );
};

export default Header;
