import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { plugins, options, defaultPlugins } from './DetailsChart.data';
import { SWrapper } from './DetailsChart.styles';

const DetailsChart = ({ loading, settlement }) => {
  const { amount, benefits_amount, taxes_amount, deductions_amount } = settlement ?? {};
  const noData =
    Number(amount) === 0 &&
    Number(taxes_amount) === 0 &&
    Number(benefits_amount) === 0 &&
    Number(deductions_amount) === 0;

  const data = {
    labels: [],
    datasets: noData
      ? [
          {
            data: [1],
            backgroundColor: [palette.gray200],
            borderColor: [palette.gray200],
            hoverBackgroundColor: [palette.gray300],
            hoverBorderColor: [palette.gray300],
            borderWidth: 1,
          },
        ]
      : [
          {
            data: [
              Number(amount || 0),
              Number(taxes_amount || 0),
              Number(benefits_amount || 0),
              Number(deductions_amount || 0),
            ],
            backgroundColor: [palette.green400, palette.red400, palette.blue400, palette.amber400],
            borderColor: [palette.green400, palette.red400, palette.blue400, palette.amber400],
            hoverBackgroundColor: [palette.green500, palette.red500, palette.blue500, palette.amber500],
            hoverBorderColor: [palette.green500, palette.red500, palette.blue500, palette.amber500],
            borderWidth: 1,
          },
        ],
  };

  return (
    <SWrapper>
      <div className='d-flex align-items-center gap-4'>
        <div className='chart-container'>
          {loading ? (
            <Skeleton variant='circle' height='100%' style={{ aspectRatio: '1' }} />
          ) : (
            <Doughnut data={data} options={options} plugins={noData ? defaultPlugins : plugins} />
          )}
        </div>
        <div className='d-flex flex-column'>
          <Typography variant='b1' style={{ color: palette.gray700 }}>
            Take Home
          </Typography>
          <Typography variant='h3' style={{ color: palette.green400 }}>
            ${formatNumber(amount)}
          </Typography>
        </div>
      </div>
    </SWrapper>
  );
};

export default DetailsChart;
