import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import Divider from 'common/Divider';
import { PusherJs } from 'common/pusher';
import ConfirmModal from 'common/ConfirmModal';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import Map from 'components/CreateShipment/ShipmentStops/helpers/Map';
import classes from 'components/DispatchVehicleMap/dispatchVehicleMap.module.css';
import { formatPhoneNumber } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import { getShipmentDataForTracking } from 'Api/Shipment';
import { SWrapper, SBody, SFooter } from './CellularTracking.styles';

const CellularTracking = () => {
  const showToaster = useShowToaster();
  const { dot, shipmentId } = useParams();
  const [shipment, setShipment] = useState(null);
  const [broker, setBroker] = useState(null);
  const [route, setRoute] = useState(null);
  const [openStopTracking, setOpenStopTracking] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);

  const stops = useMemo(() => {
    return shipment?.shipment_stops || [];
  }, [shipment]);

  const getShipmentDetails = async () => {
    try {
      const { shipment_details, brokerage_setting } = await getShipmentDataForTracking(dot, shipmentId);
      setShipment(shipment_details);
      setBroker(brokerage_setting);
      const trackingStatus = shipment_details?.cellular_tracking_status;
      const carrierId = shipment_details?.brokerage_dispatch?.carrier_id;
      return { trackingStatus, carrierId };
    } catch (e) {
      // Do nothing
    }
  };

  const startTracking = () => {
    const phoneNumber = shipment?.brokerage_dispatch?.driver_phone_number?.startsWith('+')
      ? shipment?.brokerage_dispatch?.driver_phone_number
      : `+${shipment?.brokerage_dispatch?.driver_phone_number}`;

    window.location.href = `cellulartracking://phone_number=${phoneNumber}&dot=${dot}&shipmentId=${shipmentId}&action=start`;

    const timeout = setTimeout(() => {
      if (!isMobile) {
        showToaster({
          type: 'error',
          message: 'You need to download Truckin Digital Cellular App on mobile device in order to track shipments.',
        });
        return;
      }
      setOpenDownload(true);
    }, 3000);

    document.onvisibilitychange = () => {
      if (document.visibilityState === 'hidden') {
        clearTimeout(timeout);
      }
    };
  };

  const stopTracking = () => {
    const phoneNumber = shipment?.brokerage_dispatch?.driver_phone_number?.startsWith('+')
      ? shipment?.brokerage_dispatch?.driver_phone_number
      : `+${shipment?.brokerage_dispatch?.driver_phone_number}`;

    window.location.href = `cellulartracking://phone_number=${phoneNumber}&dot=${dot}&shipmentId=${shipmentId}&action=stop`;
    setOpenStopTracking(false);

    const timeout = setTimeout(() => {
      if (!isMobile) {
        showToaster({
          type: 'error',
          message: 'You need to download Truckin Digital Cellular App on mobile device in order to track shipments.',
        });
        return;
      }
      setOpenDownload(true);
    }, 3000);

    document.onvisibilitychange = () => {
      if (document.visibilityState === 'hidden') {
        clearTimeout(timeout);
      }
    };
  };

  const handleInstallApp = () => {
    if (isAndroid) {
      window.location.href = 'market://details?id=com.truckindigital.cellulartracking';
    }

    if (isIOS) {
      window.location.href = 'itms-apps://itunes.apple.com/app/apple-store/id6477922117';
    }
  };

  const updateStopInRoute = ({ stops, routeArr }) => {
    const route = [];
    let indexStop = null;

    if (routeArr) {
      for (let i = 0; i < routeArr.length; i++) {
        const stopPoint = routeArr[i]?.stopPoint;
        if (stopPoint) {
          indexStop === null ? (indexStop = 0) : (indexStop += 1);
          const {
            geofencing_latitude: latitude,
            geofencing_longitude: longitude,
            location_name,
          } = stops?.[indexStop]?.stop_point || {};
          !!latitude &&
            !!longitude &&
            route.push({
              latitude,
              longitude,
              location_name,
              stop_point_type: stops?.[indexStop]?.stop_point_type,
            });
        } else {
          route.push(routeArr[i]);
        }
      }
    } else {
      for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];
        const {
          geofencing_latitude: latitude,
          geofencing_longitude: longitude,
          location_name,
        } = stop?.stop_point || {};
        !!latitude &&
          !!longitude &&
          route.push({ latitude, longitude, location_name, stop_point_type: stop?.stop_point_type });
      }
    }
    setRoute(route);
  };

  const mapDriverArr = useMemo(() => {
    const lng = shipment?.driver_from_gps_long;
    const lat = shipment?.driver_from_gps_lat;

    return [
      {
        id: shipment?.equipment?.id,
        img: shipment?.assigned_to_1?.image,
        miles: shipment?.empty_miles?.toFixed(),
        routeProps: {},
        marker: { lat, lng, draggable: false },
      },
    ];
  }, [shipment]);

  const bindPusher = () => {
    const channelShipmentProgress = `private-shipmentData-update.${dot}`;

    PusherJs.unsubscribe(channelShipmentProgress);
    const channelShipmentProgress_table = PusherJs.subscribe(channelShipmentProgress);

    channelShipmentProgress_table.bind('shipment-update', (e) => {
      if (e?.message?.data?.id === shipmentId) {
        getShipmentDetails();
      }
    });
  };

  useEffect(() => {
    if (stops.length > 0) {
      if (shipment?.shipment_type === 'slave') {
        updateStopInRoute({ stops, routeArr: null });
      } else {
        if (shipment?.shipment_route_direction?.route) {
          const route = shipment?.shipment_route_direction.route;
          const routeArr = route ? JSON.parse(route) : null;
          updateStopInRoute({ stops, routeArr });
        }
      }
    }
  }, [shipment, stops]);

  useEffect(async () => {
    bindPusher();
    getShipmentDetails();
  }, []);

  return (
    <SWrapper>
      <div className='d-flex align-items-center justify-content-center'>
        <Typography variant='h5'>Tracking Acceptance</Typography>
      </div>
      <SBody>
        <div className='d-flex flex-column gap-2'>
          <Typography variant='h5'>Requested By</Typography>
          <div className='d-flex align-items-center justify-content-between'>
            <Typography variant='s2'>{broker?.company_name}</Typography>
            <Typography variant='s2'>Shipment {shipmentId}</Typography>
          </div>
        </div>
        <Divider />
        <div className='d-flex flex-column gap-2'>
          <Typography variant='h5'>Carrier</Typography>
          <div className='d-flex align-items-center justify-content-between'>
            <Typography variant='s2'>{shipment?.brokerage_dispatch?.carrier?.name}</Typography>
            <div className='d-flex flex-column align-items-end'>
              <Typography variant='s2'>{shipment?.brokerage_dispatch?.driver_name}</Typography>
              <Typography variant='s2'>
                {shipment?.brokerage_dispatch?.driver_phone_number?.startsWith('+')
                  ? formatPhoneNumber(shipment?.brokerage_dispatch?.driver_phone_number)
                  : formatPhoneNumber(`+${shipment?.brokerage_dispatch?.driver_phone_number}`)}
              </Typography>
            </div>
          </div>
        </div>
      </SBody>
      {shipment && (
        <Map
          styleContainer={{
            position: 'static',
            zIndex: 20,
            width: '100%',
            height: 'calc(100% - 40px)',
            marginTop: 15,
          }}
          literalJsx
          popoverClass={classes.popoverDispatchPage}
          driversArr={mapDriverArr}
          stopPointArr={route}
          infoHeader={shipment}
          isShipmentTable
        />
      )}
      {shipment?.cellular_tracking_status === 'requested' && (
        <CustomButton
          type='primary'
          title='Start Tracking'
          onClick={() => startTracking()}
          styleButton={{ padding: '4px 12px', marginTop: 0, justifyContent: 'center', margin: '10px 6px' }}
        />
      )}
      {shipment?.cellular_tracking_status === 'started' && (
        <CustomButton
          type='danger'
          title='Stop Tracking'
          onClick={() => setOpenStopTracking(true)}
          styleButton={{ padding: '4px 12px', marginTop: 0, justifyContent: 'center', margin: '10px 6px' }}
        />
      )}
      {shipment?.cellular_tracking_status === 'stopped' && (
        <CustomButton
          type='secondary'
          title='Tracking Stopped'
          styleButton={{ padding: '4px 12px', marginTop: 0, justifyContent: 'center', margin: '10px 6px' }}
          disabled
        />
      )}
      {shipment?.cellular_tracking_status === 'completed' && (
        <CustomButton
          type='secondary'
          title='Tracking is Stopped & Completed'
          styleButton={{ padding: '4px 12px', marginTop: 0, justifyContent: 'center', margin: '10px 6px' }}
          disabled
        />
      )}
      {shipment?.cellular_tracking_status === 'cancelled' && (
        <CustomButton
          type='secondary'
          title='Tracking Request is Canceled'
          styleButton={{ padding: '4px 12px', marginTop: 0, justifyContent: 'center', margin: '10px 6px' }}
          disabled
        />
      )}
      <Typography className='mt-1 p-2' variant='s2'>
        By clicking the Start Tracking button, you allow Truckin Systems LLC to track your mobile device and provide
        updates to {broker?.company_name}. The tracking uses cellular device data fields, cell tower data, and WiFi
        access point array data to return latitude/longitude coordinates every minute until the shipment is completed.
      </Typography>
      <Typography className='mt-1 p-2' variant='s2'>
        <Typography variant='button2'>DISCLAIMER:</Typography> You can stop tracking at any time after starting.
        Tracking will automatically stop when the shipment is delivered.
      </Typography>
      <SFooter>
        <div className='d-flex align-items-center'>
          <img src={truckinLogo} alt='Truckin digital logo' width={32} height={32} />
          <Typography variant='s2' className='gray-text ml-1'>
            Powered by{' '}
            <a className='blue-link' target='_blank' href='https://www.truckindigital.com' rel='noreferrer'>
              Truckin Digital
            </a>
          </Typography>
        </div>
      </SFooter>
      {openStopTracking && (
        <ConfirmModal
          open={openStopTracking}
          title='Stop Cellular Tracking'
          text={`Are you sure you want to stop cellular tracking for shipment ${shipmentId}?`}
          btnText='Stop Tracking'
          onClose={() => setOpenStopTracking(false)}
          onConfirm={stopTracking}
          width='95vw'
        />
      )}
      {openDownload && (
        <ConfirmModal
          open={openDownload}
          title='Truckin Digital Cellular App'
          text='Please install the Truckin Digital Cellular App in order to track this shipment.'
          btnText='Install'
          btnType='primary'
          onClose={() => setOpenDownload(false)}
          onConfirm={handleInstallApp}
          width='95vw'
        />
      )}
    </SWrapper>
  );
};

export default CellularTracking;
