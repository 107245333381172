import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Modal, Button } from 'react-bootstrap';
import moment from 'moment/moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import Loader from 'common/Loader';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import AddIncident from 'components/AddIncidentModal';
import CustomButton from 'components/CustomButton/CustomButton';
import { ViewIncidentBody } from 'components/ViewIncidentModal/ViewIncidentBody';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { useAuth } from 'context/auth.context';
import { getIncidentById } from 'Api/DotMonitoring';
import { createAccidentReport, getAllIncidents, updateCollision } from 'Api/AccidentsAndCrashes';
import { getErrorMessage } from 'utils/error';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import Grid from '@mui/material/Grid';
import InputLabel from 'common/InputLabel';
import { yesOrNoOptions } from 'pages/AccidentsAndCrashes/AddAccidents/AddAccidents.data';
import Input, { Textarea } from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import { getDrivers } from 'Api/EquipmentProfile';
import { getInitialValues } from './Driver.data';

const Driver = ({
  id,
  Cancel,
  nextStep,
  accidentId,
  accident,
  setAccident,
  selectedIncidentId,
  selectedDriver,
  selectedCollisionDateTime,
}) => {
  const { use } = useTheme();
  const { value: authValue } = useAuth();
  const { user } = authValue;
  const showToaster = useShowToaster();
  const [incident, setIncident] = useState(null);
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [addModalShow, setAddModalShow] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [onSubmitAction, setOnSubmitAction] = useState('next');
  const [value, setValue] = useState(null);

  const onSubmit = async (values) => {
    try {
      const {
        driver,
        person_taking_report_type,
        person_taking_report,
        driver_injured,
        injury_type,
        treating_facility,
        treating_facility_telephone,
        injury_osha_reportable,
        injury_worker_comp_recordable,
      } = values || {};
      setLoading(true);
      const formData = new FormData();
      formData.append('safety_accident_id', accident?.id || id);
      formData.append('report_date_time', moment(values.report_date_time).format('YYYY-MM-DD HH:mm'));
      person_taking_report_type &&
        formData.append(
          'person_taking_report_type',
          person_taking_report_type.name === 'Driver' ? 'App\\Driver' : 'App\\User'
        );
      person_taking_report && formData.append('person_taking_report_id', person_taking_report.id);
      driver && formData.append('driver_id', driver.id);
      driver_injured && formData.append('driver_injured', driver_injured.value);
      injury_type && formData.append('injury_type', injury_type);
      treating_facility && formData.append('treating_facility', treating_facility);
      treating_facility_telephone && formData.append('treating_facility_telephone', treating_facility_telephone);
      injury_osha_reportable && formData.append('injury_osha_reportable', injury_osha_reportable.value);
      injury_worker_comp_recordable &&
        formData.append('injury_worker_comp_recordable', injury_worker_comp_recordable.value);

      await createAccidentReport(accident?.id || id, formData);

      if (onSubmitAction === 'next') {
        nextStep();
        return;
      }

      if (onSubmitAction === 'close') {
        Cancel();
      }
      showToaster({ type: 'success', message: 'Success!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, setValues } = useForm({
    initialValues: getInitialValues({
      user,
      selectedDriver,
    }),
    onSubmit,
  });

  useEffect(async () => {
    if (accident?.incident_id || selectedIncidentId) {
      setLoading(true);
      const { data } = await getIncidentById(accident?.incident_id || selectedIncidentId);
      setIncident(data);
      setLoading(false);
    }
  }, [accident]);

  useEffect(() => {
    if (accident?.driver?.id || selectedDriver?.id) {
      getIncidents();
    }
  }, [accident?.driver?.id]);

  const getIncidents = async () => {
    try {
      const { data } = await getAllIncidents({
        itemsPerPage: 10000,
        driver_id: accident?.driver?.id || selectedDriver?.id,
      });
      setIncidents(data || []);
    } catch (e) {
      // do something
    } finally {
      setLoading(false);
    }
  };

  const onSelectIncident = async (e, val) => {
    setValue(val);
    const data = {
      incident_id: val.id,
      collision_date_time: accident?.collision_date_time || selectedCollisionDateTime,
    };
    await updateCollision(accident?.id || id, data);
    setAccident((prevState) => ({ ...prevState, incident_id: val.id }));
  };

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const create = () => {
    setAddModalShow(true);
  };

  const onSave = () => {
    setOnSubmitAction('save');
    handleSubmit();
  };

  const onSaveAndClose = () => {
    setOnSubmitAction('close');
    handleSubmit();
  };

  const onNext = () => {
    setOnSubmitAction('next');
    handleSubmit();
  };

  useEffect(() => {
    if (accident) {
      const {
        driver,
        driver_injured,
        injury_worker_comp_recordable,
        injury_type,
        treating_facility,
        treating_facility_telephone,
        injury_osha_reportable,
      } = accident || {};

      setDetailView(true);

      setValues((prevState) => ({
        ...prevState,
        driver: driver || null,
        driver_injured: yesOrNoOptions.find((i) => i.value === driver_injured) || null,
        injury_type: injury_type || '',
        treating_facility: treating_facility || '',
        treating_facility_telephone: treating_facility_telephone || '',
        injury_osha_reportable: yesOrNoOptions.find((i) => i.value === injury_osha_reportable) || null,
        injury_worker_comp_recordable: yesOrNoOptions.find((i) => i.value === injury_worker_comp_recordable) || null,
      }));
    }
  }, [accident]);

  useEffect(() => {
    getDriversList();
  }, []);

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-accident-modal-body'>
        {detailView ? (
          <Grid container columnSpacing={2.5} rowSpacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Driver</InputLabel>
              <Typography variant='s3'>
                {accident?.driver?.fname || ' -'} {accident?.driver?.lname || ' -'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Injured</InputLabel>
              <Typography variant='s3'>{accident?.driver_injured ? 'Yes' : 'No'}</Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Injury Type</InputLabel>
              <Typography variant='s3'>{accident?.injury_type}</Typography>
            </Grid>

            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Treating Facility</InputLabel>
              <Typography variant='s3'>{accident?.treating_facility}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Treating Facility Telephone</InputLabel>
              <Typography variant='s3'>{accident?.treating_facility_telephone}</Typography>
            </Grid>

            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>
                Was the injury OSHA Recordable?
              </InputLabel>
              <Typography variant='s3'>{accident?.injury_osha_reportable ? 'Yes' : 'No'}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>
                Was the injury Workers Comp Recordable?
              </InputLabel>
              <Typography variant='s3'>{accident?.injury_worker_comp_recordable ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container columnSpacing={2.5} rowSpacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                label='Select Driver'
                options={drivers}
                value={values.driver}
                onChange={(e, val) => handleChange('driver', val)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                getOptionLabel={(option) => (option ? `${option.fname} ${option.lname}` : '')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                label='Injured'
                options={yesOrNoOptions}
                getOptionLabel={(option) => option.name?.toString()}
                value={values.driver_injured}
                onChange={(e, val) => handleChange('driver_injured', val)}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Textarea
                label='Injury Type'
                rows={3}
                name='injury_type'
                value={values.injury_type}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <Input
                label='Treating Facility'
                name='treating_facility'
                value={values.treating_facility}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <PhoneInput
                label='Treating Facility Telephone'
                name='treating_facility_telephone'
                placeholder='+1 (555) 555-1234'
                onChange={(val) => handleChange('treating_facility_telephone', val)}
                value={values.treating_facility_telephone}
              />
            </Grid>

            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <Autocomplete
                label='Was the injury OSHA Recordable?'
                options={yesOrNoOptions}
                getOptionLabel={(option) => option.name?.toString()}
                value={values.injury_osha_reportable}
                onChange={(e, val) => handleChange('injury_osha_reportable', val)}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                label='Was the injury Workers Comp Recordable?'
                options={yesOrNoOptions}
                getOptionLabel={(option) => option.name?.toString()}
                value={values.injury_worker_comp_recordable}
                onChange={(e, val) => handleChange('injury_worker_comp_recordable', val)}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Grid>
          </Grid>
        )}
        {loading ? (
          <Loader loading={loading} />
        ) : incident ? (
          <div className='d-flex flex-column mt-3'>
            <InputLabel>Driver Incident</InputLabel>
            <ViewIncidentBody incident={incident} />
          </div>
        ) : (
          <div className='d-flex flex-column mt-3'>
            <InputLabel>Driver Incident</InputLabel>
            <div className='d-flex align-items-center gap-2'>
              <Typography variant='s3'>
                Please{' '}
                <Typography
                  variant='s3'
                  style={{ color: palette.indigo500, textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={create}
                >
                  create{' '}
                </Typography>{' '}
                or
              </Typography>
              <Autocomplete
                labelKey='incident_id'
                options={incidents}
                value={value}
                style={{ width: 250 }}
                onChange={onSelectIncident}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Close
            </Button>
          </div>
          <div className='pagination-btn'>
            {!!accidentId && (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='secondary'
                title='Edit'
                disabled={loading}
                leftIcon={<EditIcon style={{ marginRight: 10 }} />}
                onClick={() => setDetailView((prevState) => !prevState)}
              />
            )}
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save'
              disabled={loading}
              onClick={onSave}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save & Go To List'
              disabled={loading}
              onClick={onSaveAndClose}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Next'
              onClick={onNext}
              disabled={loading}
              leftIcon={
                loading ? (
                  <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
        {!!addModalShow && (accident?.driver || selectedDriver) && (
          <AddIncident
            open={addModalShow}
            onClose={() => {
              setAddModalShow(false);
            }}
            driverId={accident?.driver?.id || selectedDriver?.id}
            firstName={accident?.driver?.fname || selectedDriver?.fname}
            lastName={accident?.driver?.lname || selectedDriver?.lname}
            onSuccess={async (data) => {
              const body = {
                incident_id: data.id,
                collision_date_time: accident?.collision_date_time || selectedCollisionDateTime,
              };
              await updateCollision(accident?.id || id, body);
              setAccident((prevState) => ({ ...prevState, incident_id: data.id }));
            }}
          />
        )}
      </Modal.Footer>
    </Form>
  );
};

export default Driver;
