import { number, object, string } from 'yup';
import { validator } from 'utils/helpers';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const websiteRegEx =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
const einRegEx = /^[1-9]\d?-\d{7}$/;

export const basicInfoValidationSchema = object().shape({
  legal_business_name: string().trim().required('This field is required'),
  ein: string()
    .trim()
    .matches(einRegEx, 'EIN is not valid (valid format: 12-3456789).')
    .required('This field is required'),
  address1: string().trim().required('Address 1 is required'),
  country: object()
    .shape({ id: number().required('Country is required') })
    .required('Country is required')
    .nullable(),
  state: object()
    .shape({ id: number().required('State is required') })
    .required('State is required')
    .nullable(),
  city: object()
    .shape({ id: number().required('City is required') })
    .required('City is required')
    .nullable(),
  zipCode: string().trim().required('Zip code is required'),
  business_phone_number: string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  industry: object()
    .shape({ mcc: string().required('This field is required') })
    .required('This field is required')
    .nullable(),
  website: string().trim().matches(websiteRegEx, 'Website is not valid').required('This field is required'),
});

export const verificationValidationSchema = object().shape({
  first_name: string().trim().required('This field is required'),
  last_name: string().trim().required('This field is required'),
  email: string().required('Email is required').email('Invalid Email Address'),
  job_title: string().trim().required('This field is required'),
  date_of_birth: validator.pastRequiredDate(),
  address1: string().trim().required('Address 1 is required'),
  country: object()
    .shape({ id: number().required('Country is required') })
    .required('Country is required')
    .nullable(),
  state: object()
    .shape({ id: number().required('State is required') })
    .required('State is required')
    .nullable(),
  city: object()
    .shape({ id: number().required('City is required') })
    .required('City is required')
    .nullable(),
  zipCode: string().trim().required('Zip code is required'),
  phone_number: string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
  last4: string().required('This field is required'),
});
