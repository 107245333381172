import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  card_provider: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  card_number: string().trim().required('Required').nullable(),
  assign_to: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
