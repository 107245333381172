import styled from 'styled-components';

export const SInsuranceFields = styled.div`
  margin-bottom: 58px;

  .main-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    width: 100%;
    min-width: 1000px;
    max-width: 1200px;
  }
`;
