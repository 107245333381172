import React, { useEffect, useState } from 'react';
import { useTheme } from 'context/themeContext';
import useDebounce from 'hooks/useDebounce';
import { palette } from 'utils/constants';
import { getTransactions } from 'Api/Billing';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import styles from './Transactions.module.css';
import TransactionsHeader from './TransactionsHeader/TransactionsHeader';
import TransactionsSearch from './TransactionsSearch/TransactionsSearch';
import Preloader from '../../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper from '../../MaterialTableWrapper';
import ViewReasonModal from './ViewReasonModal/ViewReasonModal';
import { useTransactionsColumns } from './Transactions.data';
import ViewAgreement from './ViewAgreement';

const Transactions = ({ title, status, updateIndex }) => {
  const { use } = useTheme();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ field: 'id', sortBy: 'desc' });
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const [showReasonModal, setShowReasonModal] = useState({
    visible: false,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    page: 1,
    itemsPerPage: 25,
  });
  const [transactionList, setTransactionList] = useState({ data: [] });

  const getTransactionData = async () => {
    setLoading(true);
    const { page, itemsPerPage } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    try {
      const params = {
        page,
        [sortField]: sort.sortBy,
        itemsPerPage,
        status: status || undefined,
        query: debouncedSearch || undefined,
      };
      const transactions = await getTransactions(params);
      setTransactionList(transactions);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const PaginationComponent = () => {
    return (
      <TableFooter
        rowPerPage={selectedFilters.itemsPerPage}
        totalCount={transactionList?.total}
        totalLength={transactionList?.data?.length}
        lastPage={transactionList?.last_page}
        currentPage={transactionList?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange}
      />
    );
  };

  useEffect(() => {
    getTransactionData().then();
  }, [debouncedSearch, selectedFilters, sort, updateIndex]);

  const columns = useTransactionsColumns({ setPdfUrl, sort, sortingQuery, setShowReasonModal, title });

  return (
    <div className={styles.transactions_wrapper}>
      {title && <TransactionsHeader title={title} />}
      <TransactionsSearch search={search} onChange={setSearch} />
      {loading ? (
        <Preloader />
      ) : (
        <MaterialTableWrapper
          data={transactionList?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: PaginationComponent,
          }}
          columns={columns}
        />
      )}
      <ViewReasonModal
        show={showReasonModal?.visible}
        data={showReasonModal || {}}
        onClose={() => setShowReasonModal({ visible: false })}
      />
      <ViewAgreement title='Agreement' url={pdfUrl} onClose={() => setPdfUrl(null)} />
    </div>
  );
};
export default Transactions;
