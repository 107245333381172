import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { getUpcomingBills } from 'Api/Payables';
import Chip from 'common/Chip';
import UpcomingBillsChart from '../Charts/UpcomingBillsChart';
import { SBoxWrapper } from '../../StatsSection.styles';

const UpcomingBills = ({ onTitleClick }) => {
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [upcomingBills, setUpcomingBills] = useState(null);

  const getUpcomingBillsData = async () => {
    setLoading(true);
    try {
      const { data } = await getUpcomingBills();
      setUpcomingBills(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUpcomingBillsData();
  }, []);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <span className='d-inline-flex align-items-center gap-1 pointer' onClick={onTitleClick}>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            BILLS
          </Typography>
          <ArrowUp fill={palette.gray400} style={{ transform: 'rotate(90deg)', marginRight: '4px' }} />
          <Chip label={upcomingBills?.count} />
        </span>
      </div>
      <div className='d-flex justify-content-between'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <div className='d-flex flex-column'>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {currency}
              {formatNumber(upcomingBills?.current_month)}
            </Typography>
            <Typography variant='c2' style={{ color: palette.gray500 }}>
              This Month
            </Typography>
          </div>
        )}
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <div className='d-flex flex-column'>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {currency}
              {formatNumber(upcomingBills?.next_month)}
            </Typography>
            <Typography variant='c2' style={{ color: palette.gray500 }}>
              Next Month
            </Typography>
          </div>
        )}
      </div>
      <div className='chart-wrapper'>
        <UpcomingBillsChart monthsData={upcomingBills?.monthlyStats} />
      </div>
    </SBoxWrapper>
  );
};

export default UpcomingBills;
