import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

    .download-icon-text {
        display: flex;
        align-items: center;
        column-gap: 8px;

        svg path {
        fill: ${palette.gray700};
        }
    }
    
    .underline-text {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${palette.indigo500};
        white-space: nowrap;

        :hover {
            text-decoration: underline;
            cursor: pointer;
        }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
`;

export const SAttachmentWrapper = styled.div`
  .upload-document-deposit {
    width: 100%;
    position: relative;
    border: 1px dashed ${palette.gray200};
    background: ${palette.white};
    color: ${palette.gray900};
  }
`;
