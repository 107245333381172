import React from 'react';
import Tab from '@mui/material/Tab';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/drivers/upload.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/drivers/secondClose.svg';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import PreviousEmployment from 'componentsV2/PreviousEmployment';

import { palette } from 'utils/constants';
import { uploadDocument } from 'Api/JobPositions';
import useShowToaster from 'hooks/useShowToaster';
import { DatePicker, DateTimePicker } from 'common/Pickers';
import {
  actionFormFields,
  applicantTypes,
  checkResultOptions,
  criminalCheckResultOptions,
} from '../ViewApplication.data';
import MultipleDocuments from './MultipleDocuments';
import { SStatusTabs } from '../../../ApplicantsAndReferrals.styles';
import { SActionsFormWrapper, SFormRow, SUploadWrapper } from '../ViewApplicantModal.styles';

const YourActionsTab = ({
  form,
  selectedStatus,
  statuses,
  applicant,
  handleStatusChange,
  orientationDate,
  setOrientationDate,
}) => {
  const showToaster = useShowToaster();
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;
  const { values, handleChange, setValues, handleBlur, touchedErrors } = form;

  const applicantTypeId = applicant?.job_positing?.applicant_type?.id;

  const handleUploadDocument = async (files, name) => {
    try {
      const response = await uploadDocument(files?.[0], dot);
      if (response?.document_path) {
        handleChange(name, response.document_path);
      }
    } catch (e) {
      showToaster({ type: 'error', message: 'Unable to upload file' });
    }
  };

  return (
    <SActionsFormWrapper>
      <form>
        {applicantTypeId === 1 && (
          <>
            <SFormRow>
              <div style={{ width: '350px' }}>
                <InputLabel>Medical Verification</InputLabel>
                <SUploadWrapper className='mt-0'>
                  {values.attach_medical_verification ? (
                    <div className='file-uploaded' style={{ backgroundColor: palette.indigo0 }}>
                      <CheckIcon />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <DeleteIcon
                        className='delete-icon'
                        onClick={() => handleChange('attach_medical_verification', null)}
                      />
                    </div>
                  ) : (
                    <div className='uploader-content'>
                      <UploadIcon width={14} height={13} />
                      <Typography variant='s2' style={{ color: palette.indigo500 }}>
                        Attach document...
                      </Typography>
                    </div>
                  )}
                  {!values.attach_medical_verification && (
                    <ImageDropZone
                      accept={['application/pdf', 'application/msword']}
                      onDrop={(files) => handleUploadDocument(files, 'attach_medical_verification')}
                      width='100%'
                      height='100%'
                      deletable={false}
                      maxSize={1000000}
                      customStyle={{
                        position: 'absolute',
                        width: '160px',
                        height: '20px',
                        top: 0,
                      }}
                    />
                  )}
                  <ErrorMessage
                    className='attachment-error-message'
                    error={touchedErrors.attach_medical_verification}
                  />
                </SUploadWrapper>
              </div>
              <div>
                <InputLabel>National Registry</InputLabel>
                <SUploadWrapper className='mt-0'>
                  {values.attach_national_registry ? (
                    <div className='file-uploaded' style={{ backgroundColor: palette.indigo0 }}>
                      <CheckIcon />
                      <span className='text-style' style={{ color: '#38A06C' }}>
                        File Uploaded
                      </span>
                      <DeleteIcon
                        className='delete-icon'
                        onClick={() => handleChange('attach_national_registry', null)}
                      />
                    </div>
                  ) : (
                    <div className='uploader-content'>
                      <UploadIcon width={14} height={13} />
                      <Typography variant='s2' style={{ color: palette.indigo500 }}>
                        Attach document...
                      </Typography>
                    </div>
                  )}
                  {!values.attach_national_registry && (
                    <ImageDropZone
                      accept={['application/pdf', 'application/msword']}
                      onDrop={(files) => handleUploadDocument(files, 'attach_national_registry')}
                      width='100%'
                      height='100%'
                      deletable={false}
                      maxSize={1000000}
                      customStyle={{
                        position: 'absolute',
                        width: '160px',
                        height: '20px',
                        top: 0,
                      }}
                    />
                  )}
                  <ErrorMessage className='attachment-error-message' error={touchedErrors.attach_national_registry} />
                </SUploadWrapper>
              </div>
            </SFormRow>
            {actionFormFields.map(({ select, date, attachment }) => (
              <SFormRow key={select.name} $margin={(touchedErrors[select.name] || touchedErrors[date.name]) && '14px'}>
                <div className='select-field'>
                  <Autocomplete
                    label={select.label}
                    name={select.name}
                    options={select.name === 'criminal_check_result' ? criminalCheckResultOptions : checkResultOptions}
                    value={values[select.name]}
                    onChange={(e, value) => {
                      setValues((prevState) => ({
                        ...prevState,
                        [select.name]: value,
                        [date.name]:
                          !values[date.name] && value.name !== 'Pending'
                            ? new Date()
                            : select.name !== 'criminal_check_result'
                            ? prevState[date.name]
                            : null,
                        [attachment.name]:
                          value.name === 'Pending' && select.name === 'criminal_check_result'
                            ? ''
                            : prevState[attachment.name],
                      }));
                    }}
                    error={touchedErrors[select.name]}
                  />
                </div>
                <div className='date-field'>
                  <DatePicker
                    label='Checked Date'
                    name={date.name}
                    onBlur={handleBlur}
                    disableFuture
                    value={values[date.name]}
                    onChange={(val) => handleChange(date.name, val)}
                    error={touchedErrors[date.name]}
                  />
                </div>
                {attachment.name === 'fmcsa_attachment' ? (
                  <MultipleDocuments values={values} handleChange={handleChange} name={attachment.name} />
                ) : (
                  <SUploadWrapper>
                    {values[attachment.name] ? (
                      <div className='file-uploaded' style={{ backgroundColor: palette.indigo0 }}>
                        <CheckIcon />
                        <span className='text-style' style={{ color: '#38A06C' }}>
                          File Uploaded
                        </span>
                        <DeleteIcon className='delete-icon' onClick={() => handleChange(attachment.name, null)} />
                      </div>
                    ) : (
                      <div className='uploader-content'>
                        <UploadIcon width={14} height={13} />
                        <Typography variant='s2' style={{ color: palette.indigo500 }}>
                          Attach document...
                        </Typography>
                      </div>
                    )}
                    {!values[attachment.name] && (
                      <ImageDropZone
                        accept={['application/pdf', 'application/msword']}
                        onDrop={(files) => handleUploadDocument(files, attachment.name)}
                        width='100%'
                        height='100%'
                        deletable={false}
                        maxSize={1000000}
                        customStyle={{
                          position: 'absolute',
                          width: '160px',
                          height: '20px',
                          top: 0,
                        }}
                      />
                    )}
                    <ErrorMessage className='attachment-error-message' error={touchedErrors[attachment.name]} />
                  </SUploadWrapper>
                )}
              </SFormRow>
            ))}
          </>
        )}
        <PreviousEmployment
          userType={applicant?.driver ? 'driver' : applicant?.staff ? 'staff' : 'applicant'}
          userId={applicant?.driver ? applicant.driver.id : applicant?.staff ? applicant.staff.id : applicant.id}
          initialEmployments={applicant.previous_employment || []}
        />
      </form>
      <Typography variant='s2'>Add {applicantTypes?.[applicantTypeId]} Applicant to Fleet?</Typography>
      <div className='d-flex align-items-center gap-3'>
        <SStatusTabs value={selectedStatus} onChange={handleStatusChange}>
          {statuses.map((item) => item.name !== 'New' && <Tab label={item.name} value={item.id} key={item.id} />)}
        </SStatusTabs>
        {selectedStatus === 6 && (
          <div className='d-flex flex-column'>
            <DateTimePicker
              name='orientation_scheduled_date'
              value={orientationDate}
              onChange={(val) => setOrientationDate(val)}
            />
          </div>
        )}
      </div>
    </SActionsFormWrapper>
  );
};

export default YourActionsTab;
