import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PlusIcon from 'assets/icons/plus.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import styles from './SwitchRadio.module.css';
import EditModal from './EditTab/EditModal';
import EquipmentTableModal from './EditTab/EditTabContent';

import tabService from './tabs.service';

const SwitchRadio = ({
  style,
  name,
  defaultValue,
  TabData,
  title,
  OnTabChange,
  onChange,
  EditModalData,
  value,
  type,
  Tabs,
  plus,
  onClickPlus,
  multiSelectOptions,
}) => {
  const { use } = useTheme();
  const [editModal, setEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState();
  const onChangeHandler = (value, key, item) => {
    onChange(name, value);
    OnTabChange(value, key, item);
  };
  useEffect(() => {
    tabService.getTabs().then(() => {});
  }, [title]);

  const editTabs = (e) => {
    setEditModalData(e);
    setEditModal(true);
  };

  return (
    <>
      {type === 'tab' ? (
        <div className={styles.container} style={{ ...style }}>
          <div className={styles.tab_switchRadio} style={{ marginTop: title ? 10 : 14 }}>
            {TabData.map((item) => {
              return (
                <label className={styles.tab_switchRadio_label} key={item.id}>
                  <input
                    type='radio'
                    hidden
                    name={name}
                    checked={value === item.id}
                    value={item.id}
                    defaultChecked={defaultValue !== undefined ? item.id === defaultValue : undefined}
                    onChange={(e) => onChangeHandler(e.target.value, item?.type, item)}
                  />
                  <div className={styles.tab_switchRadio_item_wrap}>
                    <span
                      className={styles.tab_switchRadio_item}
                      style={{
                        color: value === item.id ? '#4F5AED' : use(palette.gray700, palette.white),
                        fontWeight: value === item.id,
                      }}
                    >
                      {item.data?.name}
                      <span
                        className={styles.editTabs}
                        onClick={(e) => {
                          e.stopPropagation();
                          editTabs(item);
                        }}
                      >
                        <EditIcon width={9} height={9} fill={palette.white} />
                      </span>
                    </span>
                    {item.count !== undefined && (
                      <span
                        className={styles.tab_switchRadio_count}
                        style={{
                          backgroundColor: use(palette.indigo0, palette.dark600),
                          color: value === item.id ? '#4F5AED' : use(palette.gray700, palette.white),
                        }}
                      >
                        {item.count}
                      </span>
                    )}
                  </div>
                </label>
              );
            })}

            {plus ? (
              <img
                src={PlusIcon}
                width={20}
                height={20}
                style={{ padding: 3, cursor: 'pointer' }}
                onClick={onClickPlus}
                alt=''
                className={styles.plus_icon}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className={styles.container} style={{ ...style }}>
          <div className={styles.switchRadio} style={{ marginTop: title ? 10 : 14 }}>
            {TabData.map((item) => {
              return (
                <label className={styles.switchRadio_label} key={item.id}>
                  <input
                    type='radio'
                    hidden
                    name={name}
                    checked={value === item.id}
                    value={item.id}
                    defaultChecked={defaultValue !== undefined ? item.id === defaultValue : undefined}
                    onChange={(e) => onChangeHandler(e.target.value)}
                  />
                  <span className={styles.switchRadio_item}>{item.label}</span>
                </label>
              );
            })}
          </div>
        </div>
      )}
      <Modal
        size='lg'
        centered
        show={editModal}
        // onHide={onClose}
        enforceFocus={false}
        className='customModal'
      >
        <EditModal styleBody={{ width: 446 }} onHide={() => setEditModal(false)} headerTitle='Update Tab'>
          <EquipmentTableModal
            onHide={() => setEditModal(false)}
            multiSelectOptions={multiSelectOptions}
            Tabs={Tabs}
            onChange={onChange}
            TabData={TabData}
            OnTabChange={OnTabChange}
            EditModalData={EditModalData}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            editModalData={editModalData}
          />
        </EditModal>
      </Modal>
    </>
  );
};

export default SwitchRadio;
