import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: -15px;
  cursor: pointer;

  img {
    object-fit: cover;
  }
`;

export const SDownloadPopup = styled.div`
  position: absolute;
  top: 50px;
  left: -194px;
  z-index: 999;

  .download-content-wrapper {
    position: relative;
    width: 1000px;
    height: 500px;
    border-radius: 30px;
    box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.25);

    .phone-image {
      border-radius: 30px;
      width: 100%;
      height: 100%;
    }

    .download-left-wrapper {
      position: absolute;
      width: 30%;
      right: 110px;
      top: 74px;
    }

    .triangle-down {
      position: absolute;
      top: -10px;
      left: 195px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 40px solid ${palette.green300};
    }
  }
`;
