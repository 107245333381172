import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './CustomerProfile.css';
import { useNavigate, useParams } from 'react-router-dom';
import BackArrow from 'assets/icons/drivers/arrow-left.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import call from 'assets/icons/drivers/call.svg';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import {
  CustomerGeneral,
  customerStatuses,
  getCustomerCredits,
  updateCustomer,
  updateCustomerStatus,
} from 'Api/Customers';
import { ReactComponent as EditPensleIcon } from 'assets/icons/editPensle.svg';
import Credits from 'components/CustomerProfile/Credits';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomerStatementModal from 'components/CustomerProfile/CustomerStatementModal';
import hexRgb from 'hex-rgb';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import CustomizedSnackbars from '../toast/Toast';
import CustomerProfileTab from '../CustomerProfileTab/CustomerProfileTab';
import StatusChangeOverlay from './StatusChangeOverlay';
import { formatAmount } from '../Customers/helpers/CustomersTableHeaderFilterByShow';
import styles from '../StopPoint/Walmart/Walmart.module.css';
import CustomModal from '../CustomModal/CustomModal';
import IdChangeModal from './IdChangeModal/IdChangeModal';
import { getPaymentTermTitle } from './CustomerProfile.data';
import AddGroups from '../AddGroupModal/AddGroupModal';

const CustomerProfile = () => {
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [customer, setCustomer] = useState();
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [showStatuses, setShowStatuses] = useState(false);
  const [statuses, setStatuses] = useState();
  const [showIdModal, setShowIdModal] = useState(false);
  const [credits, setCredits] = useState([]);
  const [openCredits, setOpenCredits] = useState(false);
  const [openStatement, setOpenStatement] = useState(false);
  const [addGroupModalShow, setAddGroupModalShow] = useState(false);

  const { id } = useParams();
  const { use } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    CustomerGeneral({ id }).then((res) => {
      setCustomer(res.data);
    });
    customerStatuses().then((res) => {
      setStatuses(res.data);
    });
    getCustomerCredits(id).then((res) => {
      setCredits(res.data);
    });
  }, []);

  const onSuccess = (message) => {
    CustomerGeneral({ id }).then((res) => {
      setCustomer(res.data);
    });

    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  function headerOverLayChange(status) {
    const data = {
      status_id: status.id,
    };
    updateCustomerStatus(data, id)
      .then(() => {
        setShowMessage({
          message: `Customer status has been changed to ${status.title} successfully!`,
          visible: true,
          type: 'success',
        });
        setTimeout(() => {
          setShowMessage({
            visible: false,
            message: '',
          });
        }, 6000);
        CustomerGeneral({ id }).then((res) => {
          setCustomer(res.data);
        });
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) });
      });
  }

  const updateGroupProfile = (updatedDriverGroupsInfo, onHide = null, onSuccess = null, successMsg = '') => {
    updateCustomer(id, { group_id: updatedDriverGroupsInfo.map((group) => group.id).join(',') })
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) });
      });
  };

  return (
    <div className='profile-container'>
      <div className='back-navigate-container' onClick={() => navigate(-1)}>
        <img src={BackArrow} alt='Back-Arrow' />
        <span className='navigater-page-name'>Go Back</span>
      </div>
      <div
        className='user-profile-header'
        style={{ borderBottomColor: use(palette.gray50, palette.darkborder), height: '80px' }}
      >
        <div>
          <div className='user-details-container'>
            <div className='user-details'>
              <div className='user-name-and-actions-container'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  {customer?.company_name}
                </h2>
                <div className='call-to-action-icons'>
                  <a href={`tel:${customer?.telephone_number}`}>
                    <img src={call} alt='call-ic on' />
                  </a>
                </div>
              </div>
              {customer?.status && (
                <StatusChangeOverlay
                  show={showStatuses}
                  data={statuses}
                  customer={customer}
                  onChange={headerOverLayChange}
                  onChaneShow={setShowStatuses}
                  defaultSelected={customer?.status}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='user-profile-sub-header ' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>System ID</p>
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {customer?.id}
            </p>
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Tags</p>
            <div
              onClick={() => {
                setAddGroupModalShow(true);
              }}
              className={styles.edit_pensle}
            >
              <EditPensleIcon />
            </div>
            {addGroupModalShow && (
              <AddGroups
                show={addGroupModalShow}
                onHide={() => setAddGroupModalShow(false)}
                onSuccess={onSuccess}
                driverGroupInfo={customer?.customer_group}
                updateGroupProfile={updateGroupProfile}
              />
            )}
          </div>
          <div className='data-group-wraper'>
            {[...(customer?.customer_group || [])].splice(0, 4)?.map((group) => {
              const colorCode = hexRgb(group.color);
              const TextColor =
                colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';
              return (
                <div className='user-data-group' style={{ backgroundColor: group.color }}>
                  <p className='group-text' style={{ color: TextColor }}>
                    {group.group_name}
                  </p>
                </div>
              );
            })}
            {customer?.customer_group?.length > 4 ? <span>...</span> : null}
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head d-flex align-items-center'>Custom ID</p>
            <div
              onClick={() => {
                setShowIdModal(true);
              }}
              className={styles.edit_pensle}
            >
              <EditPensleIcon />
            </div>
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {customer?.customer_id}
            </p>
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Open Balance</p>
          </div>
          <div className='d-flex align-items-center gap-4'>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {currency} {formatAmount(Number(customer?.balance || 0))}
              </span>
            </p>
            <CustomButton
              title='Print'
              type='secondary'
              styleTitle={{ fontSize: 12, fontWeight: 500, whiteSpace: 'nowrap' }}
              leftIcon={<PrintIcon className='me-2' />}
              styleButton={{ padding: '0 12px', margin: 0 }}
              onClick={() => setOpenStatement(true)}
            />
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Credits</p>
          </div>
          <div className='d-flex gap-2'>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              <span>
                {currency} {formatAmount(credits[credits.length - 1]?.balance)}
              </span>
            </p>
            <Typography
              variant='s2'
              className='pointer'
              style={{ color: palette.indigo500 }}
              onClick={() => setOpenCredits(true)}
            >
              View
            </Typography>
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Pay Terms</p>
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {customer && getPaymentTermTitle(customer?.customer_payment_term?.pay_term_types)}
            </p>
          </div>
        </div>
        <div className='user-data-container user-data-container-last'>
          <div className='user-data'>
            <p className='user-data-head'>Aging Average</p>
          </div>
          <div>
            <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
              {customer?.aging_average || '-'}
            </p>
          </div>
        </div>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <CustomerProfileTab loading={false} customer={customer} onSuccess={onSuccess} />
      <CustomModal
        showModal={showIdModal}
        styleBody={{ width: 440, backgroundColor: '#F7F9FC' }}
        onHide={() => setShowIdModal(false)}
        headerTitle='Custom ID'
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <IdChangeModal getStopPointItem={onSuccess} setShowIdModal={setShowIdModal} customer={customer} />
      </CustomModal>
      {openCredits && (
        <Credits open={openCredits} onClose={() => setOpenCredits(false)} credits={credits} customer={customer} />
      )}
      {openStatement && (
        <CustomerStatementModal open={openStatement} onClose={() => setOpenStatement(false)} customer={customer} />
      )}
    </div>
  );
};
export default CustomerProfile;
