import React from 'react';
import GoogleDocsViewer from 'react-google-docs-viewer';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { SCustomModal } from 'pages/ApplicantsAndReferrals/ApplicantsAndReferrals.styles';
import { palette } from 'utils/constants';
import { SDocWrapper } from './ViewAgreement.styles';

const ViewAgreement = ({ url, onClose, title }) => {
  return (
    <SCustomModal
      showModal={!!url}
      onHide={onClose}
      headerTitle={title}
      $height='auto'
      $bodyMaxHeight='calc(100vh - 184px)'
      backdropClassName='double-modal-backdrop'
    >
      <CloseIcon className='close-modal-icon' fill={palette.red500} onClick={onClose} />
      <SDocWrapper>
        <GoogleDocsViewer width='100%' height='calc(100vh - 300px)' minHeight='500px' fileUrl={url} />
      </SDocWrapper>
    </SCustomModal>
  );
};

export default ViewAgreement;
