export const defaultVehicleType = {
  id: 1,
  title: 'Truck',
  vehicle_type_id: 1,
  mph: '50',
  edi_type: null,
  edi_description: 'TR',
  vehicle_type: {
    id: 1,
    name: 'Vehicle',
  },
};

export const getDispatchCarrierInitialValues = (costData, shipmentInfo, amount) => {
  const { shipment_stops } = shipmentInfo || {};
  const firstStop = shipment_stops?.[0];
  const { equipment_type_data, equipment_type_length_value } = firstStop || {};
  const { vehicle_type_id } = equipment_type_data || {};
  return {
    immediately: true,
    in_future: false,
    future_date: null,
    after_specific_shipment_complated: false,
    before_scheduled_pickup: false,
    before_scheduled_pickup_hours: '0',
    send_trucking_updates_to_customer: false,
    send_trucking_link_to_customer: false,
    cellular_tracking: false,
    add_shipment_notes: false,
    shipment_notes: '',
    recipient_customer_contact_book_id: '',
    vehicle_type_id: vehicle_type_id === 1 ? equipment_type_data : defaultVehicleType,
    trailer_type: vehicle_type_id === 2 ? equipment_type_data : null,
    trailer_length: equipment_type_length_value || null,
    vehicle_id: '',
    trailer_id: '',
    driver: '',
    phone: '',
    contact: null,
    flat_rate: amount || '',

    country: null,
    state: null,
    city: null,
    zip: '',
  };
};
