import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '../../../../../../common/Divider';
import { Typography } from '../../../../../../components/Typography';
import { palette } from '../../../../../../utils/constants';
import Autocomplete from '../../../../../../common/Autocomplete';
import { useTheme } from '../../../../../../context/themeContext';
import { paymentForOptions, weekDays } from '../PayPeriod.data';

const WeekSettings = ({ values, handleChange, touchedErrors }) => {
  const { use } = useTheme();

  return (
    <>
      <Grid container item xs={9} columnGap={2}>
        <Grid style={{ marginTop: 4 }}>
          <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
            Last day of the week included in settlement
          </Typography>
        </Grid>
        <Grid style={{ minWidth: '150px' }}>
          <Autocomplete
            name='week_day'
            value={values.week_day}
            onChange={(e, value) => handleChange('week_day', value)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={weekDays}
            error={touchedErrors.week_day?.value || touchedErrors.week_day}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} />
      <Grid container item xs={9} columnGap={1}>
        <Divider margin='16px 0' />
        <Grid style={{ marginTop: 4 }}>
          <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
            Set Payment for
          </Typography>
        </Grid>
        <Grid style={{ minWidth: '284px' }}>
          <Autocomplete
            name='set_payment_for'
            value={values.set_payment_for}
            onChange={(e, value) => handleChange('set_payment_for', value)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={paymentForOptions}
            error={touchedErrors.set_payment_for?.value || touchedErrors.set_payment_for}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WeekSettings;
