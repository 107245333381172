import { object, string } from 'yup';

const einRegEx = /^[1-9]\d?-\d{7}$/;
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchema = (isConfigureStep) =>
  object().shape({
    ein: string().when([], {
      is: () => !isConfigureStep,
      then: string()
        .trim()
        .matches(einRegEx, 'EIN is not valid (valid format: 12-3456789).')
        .required('This field is required'),
      otherwise: string().nullable(),
    }),
    invoice_no: string().trim().required('Required'),
    payable_email: string().trim().required('Required'),
    receivable_email: string().trim().required('Required'),
    billing_tel: string().matches(phoneRegExp, 'Phone number is not valid').required('Required').nullable(),
  });
