import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SStatBoxWrapper } from '../../StatsSection.styles';

const StatBox = ({ title, data, loading }) => {
  const { amountDiff, currentMonth } = data || {};
  // const { setRecordTab, setOpenEdit } = useContext(CarrierContext);
  return (
    <SStatBoxWrapper>
      <div className='d-flex align-items-center justify-content-between mb-2 title-wrapper'>
        <span className='d-inline-flex gap-1'>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '150px', height: '18px' }} />
          ) : (
            <Typography variant='c3' style={{ color: palette.gray700 }}>
              {title.toUpperCase()}
            </Typography>
          )}
        </span>
      </div>
      <div>
        {loading ? (
          <div className='d-flex align-items-center gap-2'>
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          </div>
        ) : (
          <div className='d-flex align-items-center gap-3'>
            <div className='d-flex align-items-baseline gap-1'>
              <Typography
                variant='h5'
                style={{ color: palette.gray900 }}
                onClick={() => {
                  // setOpenEdit('Safety Records');
                  // setRecordTab(index + 1);
                }}
              >
                {formatNumber(currentMonth, 0)}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex mt-3 gap-1'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '150px', height: '18px' }} />
        ) : (
          amountDiff !== 0 && (
            <>
              <ArrowUp
                fill={amountDiff < 0 ? palette.green500 : palette.red500}
                style={{ transform: amountDiff < 0 ? 'rotate(180deg)' : '' }}
              />
              <Typography
                variant='c2'
                style={{
                  color: amountDiff === 0 ? palette.gray700 : amountDiff < 0 ? palette.green500 : palette.red500,
                }}
              >
                {formatNumber(Math.abs(Math.ceil(amountDiff)), 0)} {amountDiff < 0 ? ' less ' : ' more '} than last
                month
              </Typography>
            </>
          )
        )}
      </div>
    </SStatBoxWrapper>
  );
};

export default StatBox;
