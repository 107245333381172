import React, { useState } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { palette } from 'utils/constants';
import { DatePicker } from 'common/Pickers';
import { useTheme } from 'context/themeContext';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import { AddCertification, uploadDriverDocument } from 'Api/Driver';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import { getErrorMessage } from 'utils/error';
import { saveFinishLater } from 'Api/DriverV2';
import ImageDropZone from '../../ImgageDropZone';
import { documentsValidationSchema } from './ValidationSchema';

const CertificationsAgreements = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  stepsDone,
  setStepsDone,
  driverId,
  getDrivers,
  draftDriver,
}) => {
  const { use } = useTheme();
  const [onNextLoading, setOnNextLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      driver_id: driverId,
      has_twic: false,
      twic_exp_date: '',
      twic_path: '',
      has_tanker: false,
      tanker_path: '',
      tanker_exp_date: '',
      has_hazmat: false,
      hazmat_path: '',
      hazmat_exp_date: '',
      certifications: [
        {
          driver_id: driverId,
          name: '',
          certification_exp_date: null,
          certificate_path: '',
          no_expiry: false,
        },
      ],
    },
    validationSchema: documentsValidationSchema,
  });

  const remove = (index) => {
    const temp = formik.values.certifications;
    temp.splice(index, 1);
    formik.setFieldValue('certifications', temp);
  };

  const uploadDocument = (file, type, propertyName, index) => {
    uploadDriverDocument(file[0], driverId)
      .then((res) => {
        formik.setFieldValue(`${type}[${index}].${propertyName}`, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const onPrevStep = () => {
    setStepsDone((prevState) => ({
      ...prevState,
      isPolices: true,
    }));
    prevStep();
  };
  const onNextStep = () => {
    setOnNextLoading(true);
    const data = {
      ...formik.values,
      certifications: formik.values.certifications
        .filter((item) => !!item.name && !!item.certificate_path)
        .map((item) => ({
          driver_id: driverId,
          name: item.name,
          certification_exp_date: item.no_expiry
            ? null
            : item.certification_exp_date
            ? moment(item.certification_exp_date).format('YYYY-MM-DD')
            : null,
          certificate_path: item.certificate_path || null,
        })),
    };

    if (!data.certifications.length) {
      saveAndFinishLater(null);
      setOnNextLoading(false);
      nextStep(formik.values, `Document has been ${stepsDone ? 'updated' : 'created'}`);
      getDrivers();
    }

    AddCertification(data)
      .then(() => {
        saveAndFinishLater(null);
        setOnNextLoading(false);
        nextStep(formik.values, `Document has been ${stepsDone ? 'updated' : 'created'}`);
        getDrivers();
      })
      .catch((error) => {
        setOnNextLoading(false);
        showError(getErrorMessage(error));
      });
  };

  const saveAndFinishLater = (step) => {
    const body = {
      draft_step_number: step,
    };

    saveFinishLater(driverId, body).then(() => {
      getDrivers();
      Cancel();
    });
  };

  return (
    <>
      <Modal.Body
        className='add-driver-modal-body driver-style-wrap'
        style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
      >
        <div>
          <p className='spacial-endorsements mt-4' style={{ color: use(palette.gray900, palette.gray200) }}>
            Documents
          </p>
          <p className='text-style ms-0 text-focus' style={{ color: use(palette.gray700, palette.gray200) }}>
            Attach any special endorsements or certifications below:{' '}
          </p>
        </div>
        {formik.values.certifications.map((certificate, index) => (
          <div className='d-flex align-items-center' key={certificate || index}>
            <div>
              <div className='d-flex'>
                <div className='flex-col'>
                  <p className='mt-3 text-style ms-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Document Name
                  </p>
                  <div className='certification-name-style mt-2'>
                    <input
                      type='text'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                      name={`certifications[${index}].name`}
                      id={`certifications[${index}].name`}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik?.values?.certifications[index]?.name}
                    />
                  </div>
                </div>
                <div>
                  <div className='d-flex'>
                    <div className='flex-col'>
                      <div className='d-flex align-items-center'>
                        <p className='mt-3 text-style ms-3' style={{ color: use(palette.gray700, palette.gray200) }}>
                          Expiry Date
                        </p>
                      </div>
                      <div className='mt-2'>
                        <DatePicker
                          width='170px'
                          name={`certifications[${index}].certification_exp_date`}
                          borderRadius='0 6px 6px 0'
                          value={
                            formik?.values?.certifications[index]?.no_expiry
                              ? null
                              : formik?.values?.certifications[index]?.certification_exp_date
                          }
                          onChange={(date) => {
                            formik.setFieldValue(`certifications[${index}].certification_exp_date`, date);
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched?.certifications?.[index]?.certification_exp_date &&
                            formik.errors?.certifications?.[index]?.certification_exp_date
                          }
                          disabled={!!formik?.values?.certifications[index]?.no_expiry}
                        />
                      </div>
                      <div style={{ marginLeft: '18px', marginTop: '4px' }}>
                        <CustomCheckbox
                          name={`certifications[${index}].no_expiry`}
                          checked={formik?.values?.certifications[index]?.no_expiry}
                          onChange={(checked) => {
                            formik.setFieldValue(`certifications[${index}].no_expiry`, checked);
                          }}
                        >
                          <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                            No Expiration
                          </Typography>
                        </CustomCheckbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {formik?.values?.certifications[index]?.certificate_path ? (
              <p className='attachment-wrapper  mt-4 ms-3 me-3' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={() => formik.setFieldValue(`certifications[${index}].certificate_path`, null)}
                />
              </p>
            ) : (
              <div className='mt-4 ms-2 imageDropZone-Wrap'>
                <ImageDropZone
                  onDrop={(file) => uploadDocument(file, 'certifications', 'certificate_path', index)}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
                />
                <label htmlFor='myFile' className='text-style upload '>
                  <span className='upload-icon '>
                    <PlusIcon />
                  </span>
                  <span className='text-style' style={{ color: use(palette.indigo500, palette.indigo500) }}>
                    Attach Document
                  </span>
                </label>
              </div>
            )}

            {index > 0 ? (
              <div className='mt-4 ms-8'>
                <img src={cross} alt='' onClick={() => remove(index)} />
              </div>
            ) : (
              <span />
            )}
          </div>
        ))}
        <div className='mt-2'>
          <label className='text-style upload'>
            <span className='upload-icon '>
              <PlusIcon />
            </span>
            <span
              className='text-style'
              style={{ color: use(palette.indigo500, palette.indigo500) }}
              onClick={() =>
                formik.setFieldValue(`certifications`, [
                  ...formik.values.certifications,
                  {
                    driver_id: driverId,
                    name: '',
                    certification_exp_date: null,
                    certificate_path: '',
                    no_expiry: false,
                  },
                ])
              }
            >
              Add Another Document
            </span>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={() => saveAndFinishLater(8)}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            {onNextLoading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                type='button'
                onClick={onNextStep}
                className='next-step'
                disabled={!formik.isValid}
                style={{ opacity: formik.isValid ? 1 : 0.7 }}
              >
                Add Driver
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default CertificationsAgreements;
