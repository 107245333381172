import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';

import { useParams } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import { getCharges } from 'Api/Shipment';
import { updateAssignToGroup, updateBillToData } from 'store/reducers/shipments.reducer';
import { getAssignToGroupBillingCharges, getBillToBillingCharges, getContactsBillingCharges } from 'Api/Planner';
import { getChargesTemplateShipment } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import ContactEditModal from 'components/CreateShipment/CreateModals/ContactEditModal';
import BillingChargeChild from './BillingChargeChild';
import classes from './billingCharges.module.css';

const BillingCharges = ({ infoHeader, isRecurring, onUpdate }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [chargeData, setChargeData] = useState([]);
  const [allChargesData, setAllChargesData] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedBillTo, setSelectedBillTo] = useState({});
  const [updateBillData, setUpdateBillData] = useState(null);

  const [contactData, setContactData] = useState({ 0: [] });
  const [showContactEditModal, setShowContactEditModal] = useState(false);

  const updateContactData = (data) => {
    if (!Array.isArray(data)) {
      setContactData(data);
    } else {
      setContactData({ 0: data });
    }
  };

  const onChangeContactEditModal = (value) => {
    setShowContactEditModal(value);
  };

  const updateContactSelected = (value) => {
    setSelectedContact(value);
  };

  const getContacts = (id, first = false) => {
    return getContactsBillingCharges(id).then((res) => {
      if (res && res?.data) {
        const newData = res.data.map((el) => {
          return {
            ...el,
            key: el?.id,
            label: el?.contact_name,
            labelSelected: null,
          };
        });
        setContactData({ 0: newData });
        !!first && setSelectedContact(newData[0]);
        return res.data;
      }
    });
  };

  const mapChargesData = (data) => {
    const mappedData = {
      customer_id: data?.billing_customer?.id,
      reference_id: data?.reference_id,
      contact_user_id: data?.contact_user_id,
      payment_term_id: data?.payment_term?.id,
      group: data?.groups?.id,
    };

    mappedData.billing_charges = data?.billing_charges?.map((charge) => {
      return {
        charge_type: charge?.charge_type,
        quantity: charge?.quantity,
        rate: charge?.rate,
        id: charge?.id,
        detention: charge?.detention,
        ref_id: charge?.ref_id,
        ref_shipment_id: charge?.ref_shipment_id,
      };
    });
    return mappedData;
  };

  const getChargesType = () => {
    getCharges(id).then((res) => {
      if (res?.data) {
        setAllChargesData(res?.data);
        setChargeData(res.data?.map((item) => mapChargesData(item)));
      }
    });
  };

  const getBillTo = () => {
    return getBillToBillingCharges().then((res) => {
      if (res && res?.data) {
        const newData = res.data?.map((item) => {
          return {
            ...item,
            label: item.company_name,
            key: item.id,
            labelSelected: null,
          };
        });
        dispatch(updateBillToData(newData));
        return newData;
      }
    });
  };

  const getAssignToGroup = () => {
    return getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          const newData = res.data?.map((item) => {
            return {
              ...item,
              label: item?.group_name,
              key: item.id,
              labelSelected: null,
            };
          });

          dispatch(updateAssignToGroup(newData));
          return newData;
        }
        return res;
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onSubmit = () => {};

  const updateBillToSelected = (value) => {
    setSelectedBillTo({ 0: value });
  };

  const initialValue = useMemo(() => {
    return {
      billing:
        chargeData.length > 0
          ? chargeData
          : [
              {
                customer_id: '',
                reference_id: '',
                group: '',
                contact_user_id: '',
                billing_charges: [{ ...getChargesTemplateShipment() }],
              },
            ],
    };
  }, [chargeData]);

  useEffect(() => {
    getBillTo().then();
    getAssignToGroup().then();
    if (!isRecurring) {
      getChargesType();
    } else {
      onUpdate();
    }
  }, [updateBillData]);

  useEffect(() => {
    if (isRecurring) {
      setAllChargesData(infoHeader.shipment_billing);
      setChargeData(infoHeader.shipment_billing?.map((item) => mapChargesData(item)));
    }
  }, [isRecurring, infoHeader]);

  function renderBillingFields(billingData) {
    return () => (
      <div>
        {billingData?.map((charge, index) => {
          const uniqueKey = `${charge.id}-${index}`;

          return (
            <div key={uniqueKey} className={classes['billing-wrapper']}>
              <BillingChargeChild
                setUpdateBillData={setUpdateBillData}
                allChargesData={allChargesData}
                indexPrefix={index}
                chargeData={chargeData}
                infoHeader={infoHeader}
                initialValue={charge}
                selectedBillTo={selectedBillTo}
                updateBillToSelected={updateBillToSelected}
                getContacts={getContacts}
                contactData={contactData}
                onChangeContactEditModal={onChangeContactEditModal}
                selectedContact={selectedContact}
                updateContactSelected={updateContactSelected}
                isRecurring={isRecurring}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      {chargeData.length ? (
        <Formik onSubmit={onSubmit} initialValues={initialValue}>
          {({ values }) => (
            <Form
              onKeyDown={(e) => {
                if (e.keyCode === 13) e.preventDefault();
              }}
            >
              <FieldArray name='billing' render={renderBillingFields(values.billing)} />
            </Form>
          )}
        </Formik>
      ) : (
        <SkeletonCharges />
      )}
      <ContactEditModal
        contactData={contactData}
        getContacts={getContacts}
        show={showContactEditModal}
        selectedBillTo={selectedBillTo}
        updateContactData={updateContactData}
        onChangeShow={onChangeContactEditModal}
        activeBillChargeIndex={0}
      />
    </div>
  );
};

export default BillingCharges;

export const SkeletonCharges = () => {
  return (
    <div className={classes.skeletonContainer}>
      <div className={classes.skeletonWrapper}>
        <div className={classNames(classes.dFlex, classes.jcsb)}>
          <Skeleton style={{ width: '13%' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '3%' }} className='mt-3' />
        </div>
        <Skeleton style={{ width: '100%', height: 1 }} className='mt-3 me-5 ' />

        <div style={{ marginTop: 32 }}>
          <div className={classNames(classes.dFlex, classes.jcsb)}>
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
          </div>
          <Skeleton style={{ width: '100%', height: 1 }} className='mt-3 me-5 ' />
          <div className={classNames(classes.dFlex, classes.jcsb)}>
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
          </div>
        </div>
        <Skeleton style={{ width: '100%', height: 1 }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
      </div>

      <div className={classes.skeletonWrapper}>
        <Skeleton style={{ width: '13%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '100%', height: 1 }} className='mt-3 me-5 ' />

        <div style={{ marginTop: 32 }}>
          <div className={classNames(classes.dFlex, classes.jcsb)}>
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
            <Skeleton style={{ width: '15%' }} className='mt-3 ' />
            <Skeleton style={{ width: '35%' }} className='mt-3 ' />
          </div>
          <Skeleton style={{ width: '100%', height: 1 }} className='mt-3 me-5 ' />
          <div className={classNames(classes.dFlex, classes.jcsb)}>
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
            <Skeleton style={{ width: '15%' }} className='mt-1 ' />
            <Skeleton style={{ width: '35%' }} className='mt-1 ' />
          </div>
        </div>
        <Skeleton style={{ width: '100%', height: 1 }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
      </div>
    </div>
  );
};
