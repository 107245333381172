import { array, number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationBasicSchema = object({
  company_name: string('').required('Company name is required'),
  telephone_number: string()
    .min(10, 'Enter valid number')
    .max(12, 'Enter valid number')
    .required('Phone number is required'),
  address: string().required('Address 1 is required'),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().required('Zip code is required'),
  account: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});

export const paymentValidationSchema = object({
  invoice_pay_term: string().required('Invoice Pay Terms is required'),
});

export const fuelSurchargeValidationSchema = object({
  fuel_matrix: array().of(
    object().shape({
      pricingDate: validator.requiredDate(),
    })
  ),
});

export const linehaulValidationSchema = object({
  lenehaulRate: array().of(
    object().shape({
      pricing_date: validator.requiredDate(),
    })
  ),
});
