export const getPlannedDuration = (durations) => {
  let totalMinutes = 0;
  for (const duration of durations) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    const parts = duration.split(' ');
    for (const part of parts) {
      if (part.endsWith('d')) {
        days = parseInt(part, 10);
      } else if (part.endsWith('h')) {
        hours = parseInt(part, 10);
      } else if (part.endsWith('m')) {
        minutes = parseInt(part, 10);
      }
    }
    totalMinutes += days * 24 * 60 + hours * 60 + minutes;
  }
  const days = Math.floor(totalMinutes / (24 * 60));
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return { days, hours, minutes, totalMinutes };
};

export const calculateFullDays = (timeString) => {
  if (!timeString) {
    return 0;
  }

  const components = timeString.split(' ');

  let days = 0;
  let hours = 0;
  let minutes = 0;

  components.forEach((component) => {
    if (component.includes('d')) {
      days = parseInt(component, 10);
    } else if (component.includes('h')) {
      hours = parseInt(component, 10);
    } else if (component.includes('m')) {
      minutes = parseInt(component, 10);
    }
  });

  const totalTimeInDays = days + hours / 24 + minutes / 3600;

  return Math.ceil(totalTimeInDays);
};
