import React, { useMemo, useEffect, useState } from 'react';
import { getMyHardware } from 'Api/Billing';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import Pagination from 'common/Pagination';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import Search from 'pages/ApplicantsAndReferrals/components/Search';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import MaterialTableWrapper from 'components/Equipment/EquipmentTableWrapper/EquipmentTableWrapper';
import { useHardwareColumns, filterTableTop } from './CustomerHardware.data';

const CustomerHardware = () => {
  const showToaster = useShowToaster();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ field: 'device_id', sortBy: 'desc' });
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);
  const [hardwareList, setHardwareList] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState({
    page: 1,
    rowsPerPage: 25,
    tab: 1,
  });

  const getHardwareList = async () => {
    try {
      const queryTab = filterTableTop.find((i) => i.value === selectedFilters.tab)?.key;
      const sortField = `sort[][${sort.field}]`;
      const data = await getMyHardware({
        page: selectedFilters.page,
        itemsPerPage: selectedFilters?.rowsPerPage,
        [sortField]: sort.sortBy,
        query: debouncedSearch || undefined,
        tab: queryTab !== 'all' ? queryTab || undefined : undefined,
      });
      setHardwareList(data);
    } catch (e) {
      setHardwareList({ data: [] });
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onTabChange = (tab) => {
    setSelectedFilters((prevState) => ({ ...prevState, tab: tab.value, page: 1 }));
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const switcherData = useMemo(() => {
    return filterTableTop.map((i) => {
      if (i.value === selectedFilters.tab) {
        i.count = hardwareList?.total;
      }
      return i;
    });
  }, [hardwareList]);

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, rowsPerPage: rowPage }));
  };

  const onNicknameUpdateSuccess = () => {
    getHardwareList();
  };

  useEffect(() => {
    setLoading(true);
    getHardwareList();
  }, [debouncedSearch, selectedFilters, sort]);

  const columns = useHardwareColumns({ tab: selectedFilters.tab, sort, sortingQuery, onNicknameUpdateSuccess });

  return (
    <>
      <Search search={search} onChange={setSearch} />
      <SwitchRadio
        name='tableTopTabMenu'
        items={switcherData}
        value={selectedFilters.tab}
        type='tab'
        onChange={onTabChange}
        plus={false}
      />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: '20px' }} />
      ) : (
        <MaterialTableWrapper
          data={hardwareList?.data}
          rowPerPage={selectedFilters.rowsPerPage}
          components={{
            Pagination: () =>
              Pagination({
                data: hardwareList,
                rowPerPage: selectedFilters.rowsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          columns={columns}
        />
      )}
    </>
  );
};

export default CustomerHardware;
