import React from 'react';
import Chip from 'common/Chip';
import { alertStatus } from 'componentsV2/AlertCenter/TakeActions/TakeActions.data';

const Status = ({ alert }) => {
  const statusData = alertStatus[alert.status];

  if (!statusData) {
    return null;
  }

  return <Chip label={statusData.label} labelColor={statusData.color} bgColor={statusData.bgColor} height='24px' />;
};

export default Status;
