import React, { useState, useEffect } from 'react';
import moment from 'moment';
import down from 'assets/icons/drivers/down.svg';
import { ReactComponent as PolicyIcon } from 'assets/icons/file.svg';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import ViewPdf from 'components/ViewPdf';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import UpdateDocuments from 'componentsV2/Equipment/Documents/UpdateDocuments';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getEquipmentDocuments } from 'Api/Equipment';
import { SDocuments, STable } from './Documents.styles';

const Documents = ({ equipment, onUpdateSuccess }) => {
  const { formatDate } = useDateFormat();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [expand, setExpand] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [documents, setDocuments] = useState([]);
  const { id, equipment_type, refreshIndex } = equipment || {};

  const getDocuments = async () => {
    try {
      const { data } = await getEquipmentDocuments(id);
      setDocuments(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onViewDocument = (doc) => {
    if (!doc) {
      return;
    }

    setPdfUrl(doc);
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    getDocuments();
  }, [id, refreshIndex]);

  return (
    <SDocuments>
      <div className='d-flex justify-content-between align-items-center mt-1 equipment-profile-group-box'>
        <Typography variant='button2' style={{ color: palette.dark800, display: 'inline-block', padding: '10px 0' }}>
          DOCUMENTS
          <img className='ms-2' src={down} alt='' onClick={() => setExpand((prevState) => !prevState)} />
        </Typography>
        {expand && (
          <span className='px-2 pointer' onClick={() => setOpenUpdate(true)}>
            <ActionDots />
          </span>
        )}
      </div>
      {expand && (
        <STable>
          <thead>
            <tr className='header-row'>
              <th>DOCUMENT</th>
              <th>EXPIRY</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((item) => (
              <tr key={item.id} className='body-row'>
                <td>
                  <Typography
                    variant='s2'
                    style={{ color: palette.indigo500 }}
                    className='pointer'
                    onClick={() => onViewDocument(item.document)}
                  >
                    {item.equipment_document_type?.name || '-'} {item.reference_id ? `(${item.reference_id})` : ''}{' '}
                    {!!item.use_from_policy && <PolicyIcon fill={palette.green500} width={18} height={18} />}
                  </Typography>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Typography variant='b2' style={{ color: palette.gray900, textAlign: 'right' }}>
                    {!!item.document_exp_date && moment(item.document_exp_date).isBefore(moment()) && (
                      <WarningIcon fill={palette.red500} />
                    )}{' '}
                    {item.document_exp_date ? formatDate(item.document_exp_date) : '-'}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
      )}
      {expand && !documents.length && <NoRecords />}

      {openUpdate && (
        <UpdateDocuments
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          equipmentId={id}
          equipmentType={Number(equipment_type?.vehicle_type_id) === 1 ? 'vehicle' : 'trailer'}
          documents={documents}
          onUpdateSuccess={() => onUpdateSuccess()}
          onDeleteSuccess={() => onUpdateSuccess()}
        />
      )}
      <ViewPdf title='Document' open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </SDocuments>
  );
};

export default Documents;
