import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Search from 'common/Search';
import { PusherJs } from 'common/pusher';
import TableSkeleton from 'common/TableSkeleton';
import ConfirmationModal from 'common/ConfirmationModal';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { getShipmentInfo, getShipmentOfferOptions, getShipmentStopsList } from 'Api/PlannerV2';
import {
  awardBid,
  cancelShipment,
  getShipmentBids,
  revertAllOffers,
  revertAward,
  revertShipmentOffer,
  updatePreferBid,
} from 'Api/OfferedShipments';
import AddNotes from 'pages/OfferedShipments/ShipmentsTable/components/AddNotes';
import ExtendExpiration from 'pages/OfferedShipments/ShipmentsTable/components/ExtendExpiration';
import BidPreview from 'pages/OfferedShipments/ShipmentDetails/components/BidPreview';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import Stops from './components/Stops';
import Header from './components/Header';
import BidNotes from './components/BidNotes';
import DeclineBid from './components/DeclineBid';
import { bidsConverter, getFilteredBids } from './converters';
import { useColumns } from './ShipmentDetails.data';
import { SPageWrapper } from './ShipmentDetails.styles';

const ShipmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(true);
  const [shipment, setShipment] = useState(null);
  const [offerOptions, setOfferOptions] = useState(null);
  const [legs, setLegs] = useState([]);
  const [bids, setBids] = useState([]);
  const [search, setSearch] = useState('');
  const [bidToView, setBidToView] = useState(null);
  const [action, setAction] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingPreferred, setLoadingPreferred] = useState(false);
  const [bidToDecline, setBidToDecline] = useState(false);
  const [bidToPreview, setBidToPreview] = useState(null);
  const [sort, setSort] = useState({ field: 'margin', sortBy: 'asc' });
  const [hideOffered, setHideOffered] = useState(true);
  const { value: userData } = useAuth();
  const debouncedSearch = useDebounce(search, 500);

  const filteredBids = useMemo(
    () => getFilteredBids(bids, sort, shipment, hideOffered),
    [bids, sort, shipment, hideOffered]
  );

  const getShipment = async () => {
    try {
      const { data } = await getShipmentInfo({ shipment_id: id });
      setShipment(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getOfferOptions = async () => {
    try {
      const { data } = await getShipmentOfferOptions(id);
      setOfferOptions({
        ...data,
        expire_date: moment.utc(data.posted_date).add(data.expire_shipment_offer, data.expire_shipment_offer_unit),
      });
    } catch (e) {
      // Do nothing
    }
  };

  const getBids = async () => {
    try {
      const sortField = `sort[${sort.nested_field || ''}][${sort.field}]`;

      const params = {
        shipment_id: id,
        query: debouncedSearch || undefined,
        [sortField]: sort.sortBy,
      };

      const { data } = await getShipmentBids(params);
      if (!data?.length) {
        navigate('/offered-shipments');
      }

      setBids(bidsConverter(data, shipment));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const orderingInLegsStops = (data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    return legsStopsData;
  };

  const getStopsList = async () => {
    try {
      const { data } = await getShipmentStopsList({ shipment_id: id });
      setLegs(orderingInLegsStops(data));
    } catch (e) {
      // Do nothing
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onAwardBid = async (bid) => {
    setLoadingAction(true);
    try {
      await awardBid(bid?.id);
      await Promise.all([getShipment(), getBids()]);
      showToaster({ type: 'success', message: 'Bid has been successfully awarded!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onDeclineBid = (bid) => {
    setBidToDecline(bid);
  };

  const onRevertOffer = async (bid) => {
    setLoadingAction(true);
    try {
      await revertShipmentOffer(bid.shipment_offer?.id, { offer_type: bid.carrier ? 'carrier' : 'driver' });
      showToaster({ type: 'success', message: 'Offer has been successfully reverted!' });
      getShipment();
      getBids();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onRevertAward = async (bid) => {
    setLoadingAction(true);
    try {
      await revertAward(bid?.id);
      showToaster({ type: 'success', message: 'Award has been successfully reverted!' });
      getShipment();
      getBids();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onNoteClick = (bid) => {
    setBidToView(bid);
  };

  const onViewNotes = async () => {
    setAction('viewNotes');
  };

  const onExtendExpiration = () => {
    setAction('extendExpiration');
  };

  const onRevertAll = async (confirmed) => {
    if (!confirmed) {
      setAction('revertAll');
      return;
    }

    setLoadingAction(true);
    try {
      await revertAllOffers({ shipment_id: shipment.shipment_id });
      showToaster({ type: 'success', message: 'All offers have been successfully reverted!' });
      setAction(null);
      navigate(`/planner/plan?id=${shipment.shipment_id}&tab=External&action=offer`);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onRevertAndResend = async (confirmed) => {
    if (!confirmed) {
      setAction('revertAndResend');
      return;
    }

    setLoadingAction(true);
    try {
      await revertAllOffers({ shipment_id: shipment.shipment_id, resend: 1 });
      showToaster({ type: 'success', message: 'All offers have been successfully revered and resent!' });
      setAction(null);
      getShipment();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onCancelShipment = async (confirmed) => {
    if (!confirmed) {
      setAction('cancelShipment');
      return;
    }

    setLoadingAction(true);
    try {
      await cancelShipment({ ids: [shipment.shipment_id] });
      showToaster({ type: 'success', message: 'Shipment has been successfully canceled!' });
      setAction(null);
      getShipment();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onUpdatePreferBid = async (bid) => {
    if (loadingPreferred) {
      return;
    }

    setLoadingPreferred(true);
    try {
      await updatePreferBid(bid.carrier?.id, { preferred: bid.carrier?.preferred ? 0 : 1 });
      getBids();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingPreferred(false);
    }
  };

  const onRowClick = (row) => {
    if (!row.carrier) {
      return;
    }

    if (bidToPreview?.id === row.id) {
      setBidToPreview(null);
      return;
    }
    setBidToPreview(row);
  };

  const onExtendExpirationSuccess = () => {
    getShipment();
    getOfferOptions();
  };

  useEffect(() => {
    getShipment();
    getStopsList();
    getOfferOptions();
  }, []);

  useEffect(() => {
    if (shipment) {
      const channelName = `private-shipment-offers-update.${userData.user.customer.dot}-${shipment.shipment_id}`;
      const channel = PusherJs.subscribe(channelName);
      channel.bind('shipment-offers-update', () => {
        getBids();
        getShipment();
        getOfferOptions();
        setBidToPreview(null);
      });

      return () => {
        PusherJs.unsubscribe(channelName);
      };
    }
  }, [shipment]);

  useEffect(() => {
    if (!shipment) {
      return;
    }

    setLoading(true);
    getBids();
  }, [debouncedSearch, shipment]);

  const columns = useColumns({
    bids,
    sort,
    sortingQuery,
    shipment,
    onAwardBid,
    onRevertOffer,
    onDeclineBid,
    onRevertAward,
    onNoteClick,
    onUpdatePreferBid,
    offerOptions,
    loadingAction,
  });

  return (
    <SPageWrapper>
      {shipment && (
        <Header
          shipment={shipment}
          offerOptions={offerOptions}
          onViewNotes={() => onViewNotes()}
          onExtendExpiration={() => onExtendExpiration()}
          onRevertAll={() => onRevertAll(false)}
          onCancelShipment={() => onCancelShipment(false)}
          onRevertAndResend={() => onRevertAndResend(false)}
        />
      )}
      <div className='mt-3 mb-3'>
        <Stops
          shipment={shipment}
          legs={legs}
          setLegs={setLegs}
          loading={loading}
          getShipment={getShipment}
          getStops={getStopsList}
        />
      </div>
      <div className='d-flex align-items-center gap-3 mt-3 mb-3'>
        <Search search={search} onChange={setSearch} width='300px' />
        <CustomCheckbox checked={hideOffered} onChange={(checked) => setHideOffered(checked)}>
          <Typography variant='s2' style={{ marginLeft: '8px' }}>
            Show Only Received Bids and Declines
          </Typography>
        </CustomCheckbox>
      </div>
      <div className='d-flex gap-2 mt-4'>
        <div style={{ width: bidToPreview ? '64%' : '100%' }}>
          {loading ? (
            <TableSkeleton />
          ) : (
            <MaterialTableWrapper
              key={filteredBids?.length}
              data={filteredBids}
              rowPerPage={filteredBids.length}
              style={{ backgroundColor: palette.white }}
              columns={columns}
              components={{
                Pagination: () => null,
              }}
              options={{
                toolbar: false,
                sorting: false,
                pageSize: filteredBids.length,
                rowStyle: (row) => ({ background: [2, 6].includes(row.offer_accepted) ? palette.red0 : '' }),
              }}
              onRowClick={(e, row) => onRowClick(row)}
            />
          )}
        </div>
        {!!bidToPreview && (
          <BidPreview
            shipment={shipment}
            bid={bidToPreview}
            offerOptions={offerOptions}
            onClose={() => setBidToPreview(null)}
          />
        )}
      </div>
      {!!bidToView && (
        <BidNotes open={!!bidToView} onClose={() => setBidToView(null)} bid={bidToView} shipment={shipment} />
      )}
      <ConfirmationModal
        width='500px'
        open={action === 'revertAll'}
        onClose={() => setAction(null)}
        headerTitle='Revert Offers'
        text={`Are you sure you want to revert all offers of shipment ${id}?`}
        onConfirm={() => onRevertAll(true)}
        buttonProps={{ type: 'primary', title: 'Revert' }}
        disabled={loadingAction}
      />
      <ConfirmationModal
        width='500px'
        open={action === 'revertAndResend'}
        onClose={() => setAction(null)}
        headerTitle='Revert and Resend Offers'
        text={`Are you sure you want to revert and resend all offers of shipment ${id}?`}
        onConfirm={() => onRevertAndResend(true)}
        buttonProps={{ type: 'primary', title: 'Revert & Resend' }}
        disabled={loadingAction}
      />
      <ConfirmationModal
        width='500px'
        open={action === 'cancelShipment'}
        onClose={() => setAction(null)}
        headerTitle='Cancel Shipment'
        text={`Are you sure you want to cancel shipment ${id}?`}
        onConfirm={() => onCancelShipment(true)}
        buttonProps={{ type: 'danger', title: 'Cancel Shipment' }}
        disabled={loadingAction}
      />
      {action === 'extendExpiration' && (
        <ExtendExpiration open onClose={() => setAction(null)} shipmentId={id} onSuccess={onExtendExpirationSuccess} />
      )}
      {action === 'viewNotes' && <AddNotes open onClose={() => setAction(null)} shipmentId={id} />}
      {!!bidToDecline && (
        <DeclineBid
          open={!!bidToDecline}
          onClose={() => setBidToDecline(null)}
          bid={bidToDecline}
          onSuccess={() => {
            getBids();
            getShipment();
          }}
        />
      )}
    </SPageWrapper>
  );
};

export default ShipmentDetails;
