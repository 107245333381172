export const getAverageTimeConverted = (averageTime) => {
  const timeInMinutes = Math.round(averageTime);
  const averageTimeDays = Math.floor(timeInMinutes / 1440);
  const averageTimeHours = Math.floor((timeInMinutes % 1440) / 60);
  const averageTimeMinutes = timeInMinutes % 60;

  return `${averageTimeDays ? `${averageTimeDays}d` : ''} ${
    averageTimeHours ? `${averageTimeHours}h` : ''
  } ${averageTimeMinutes}m`;
};
