import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { TableContext } from 'context/tableContext';
import { debounce, formatNumber, palette } from 'utils/constants';
import {
  customerStopPoint,
  getAssignToGroupBillingCharges,
  getBillToBillingCharges,
  getEquipmentRequiredTrailers,
  getEquipmentRequiredVehicles,
  getPlannerChild,
  getPlannerPopoverSettings,
  getPlannerTotal,
  getReservation,
  getTabs,
  Planner,
  tableCloudPopover,
} from 'Api/Planner';

import { handleToaster, updateRowEditItem } from 'store/reducers/root.reducer';
import { PusherJs } from 'common/pusher';

import TableBulkActions from 'components/TableBulkActions';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomModal from 'components/CustomModal/CustomModal';
import PlanerModalContent from 'components/PlanerModalContent';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import {
  ALL_TAB_FILTER_ID,
  DEFAULT_DISABLED_TABS,
  INITIAL_VALUE_CREATED_DATE,
  LOCATION_INITIAL_VALUE,
  LOCATION_INITIAL_VALUE_STOP_POINT,
  STATUS_TAB_ADD_MODAL_PENDING,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import MaterialTable from 'material-table';
import { getColumnsWidth, updateColumnsWidth } from 'Api/Shipment';
import useShowToaster from 'hooks/useShowToaster';
import PlanShipment from 'componentsV2/Planner/PlanShipment';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { GetSettingsShipments } from 'Api/CompanySettings';
import { revertAllOffers } from 'Api/OfferedShipments';
import { getErrorMessage } from 'utils/error';
import Pagination from 'common/Pagination';
import { Typography } from 'components/Typography';
import TablePlannerRowEditChildes from './TablePlannerRowEditChildes';
import PendingAssignmentFilter from './PendingAssignmentFilter';
import EdiApiAccept from './EdiApiAccept/EdiApiAccept';
import { DEFAULTWIDTHOFCOLUMNSPLANNER } from '../../TableShipments/helpers/constants';
import { useColumns } from './Planner.data';

let controller = null;

const PendingAssignmentTab = ({ createModalCLose, filterInCreateShipment }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { state: stateLocation } = useLocation();
  const resizingColumn = useRef();
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tablePlannerModalObj, setTablePlannerModalObj] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [rowCheckBoxes, setRowCheckBoxes] = useState([]);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [localFilterCheckboxes, setLocalFilterCheckboxes] = useState({ status: [], groups: [], equipment_types: [] });
  const [getPlanerFilterData, setGetPlanerFilterData] = useState({});
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [stopPointCondition, setStopPointCondition] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [customers, setCustomers] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [planerData, setPlanerData] = useState({ data: [] });
  const [filterByGroupsData, setFilterByGroupsData] = useState([]);
  const [filterByEquipmentTypeData, setFilterByEquipmentTypeData] = useState([]);
  const [animationList, setAnimationList] = useState({});
  const [tabModalEditData, setTabModalEditData] = useState({});
  const [shipmentsTotal, setShipmentsTotal] = useState(null);
  const [activeTabData, setActiveTabData] = useState({
    key: 'all',
    label: 'All',
    value: 1,
    id: ALL_TAB_FILTER_ID,
    count: 0,
  });
  const [disabledFilters, setDisabledFilters] = useState({ ...DEFAULT_DISABLED_TABS });
  const [dateRangeLocation, setDateRangeLocation] = useState([null, null]);
  const [dateRangeStopPoint, setDateRangeStopPoint] = useState([null, null]);
  const [dateRangeCreatedBy, setDateRangeCreatedBy] = useState([null, null]);
  const [unReserveEndPointData, setUnReserveEndPointData] = useState({});
  const [cancel, setCancel] = useState({ visible: false });
  const [columnWidths, setColumnWidths] = useState({ ...DEFAULTWIDTHOFCOLUMNSPLANNER });
  const [triggerAdvancedClear, setTriggerAdvancedClear] = useState(null);
  const [sort, setSort] = useState({ field: '', sortBy: 'desc' });
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [shipmentSettings, setShipmentSettings] = useState(null);
  const hasBrokerage = useHasBrokerage();
  const shipmentTotalsController = useRef(new AbortController());

  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
    allValue: '',
    cloudSwitch: false,
    selectedValues: [],
    locations: LOCATION_INITIAL_VALUE,
    stopPointAll: LOCATION_INITIAL_VALUE_STOP_POINT,
    createdDateAll: INITIAL_VALUE_CREATED_DATE,
    customerSelectData: [],
    created_by: [],
    tableColumn,
    switchRadioButtons: {
      amount: '1',
      date: '1',
      time: '1',
    },
    forAll: false,
    filterTableTop: [],
    applyAllUsersModal: { value: '0' },
  });

  const multiSelectOptions = useMemo(() => {
    return {
      title: 'All',
      id: 999,
      value: 'all',
      key: 9999,
      submenu: [
        {
          title: 'All',
          id: 999,
          value: 'all',
          key: 9999,
        },
        {
          title: 'Customer',
          id: 333,
          value: 'customers',
          key: uuid(),
          disabled: disabledFilters.customers,
        },
        {
          title: 'Location',
          id: 444,
          value: 'locations',
          key: uuid(),
        },
        {
          title: 'Stop Point',
          id: 555,
          value: 'stop_point',
          key: uuid(),
          disabled: disabledFilters.stop_point,
        },
      ],
    };
  }, [filterByGroupsData, filterByEquipmentTypeData, disabledFilters]);

  const handleMouseDown = (columnField) => (e) => {
    resizingColumn.current = {
      columnField,
      startingWidth: columnWidths[columnField],
      startingX: e.clientX,
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!resizingColumn.current) return;
    const delta = e.clientX - resizingColumn.current.startingX;

    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [resizingColumn.current.columnField]: Math.max(resizingColumn.current.startingWidth + delta, 50),
    }));

    const param = {
      table_type: 'planner',
      column_type: resizingColumn.current.columnField,
      width: Math.max(resizingColumn.current.startingWidth + delta, 50),
    };

    updateColumnsWidth(param);
  };

  const handleMouseUp = () => {
    resizingColumn.current = null;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const getBulkActions = () => {
    if (rowCheckBoxes.every((item) => (item.is_edi_api || item.manual_edi_api) && !item.accept_date)) {
      return [
        { id: 18, name: 'Accept', tab: 'Vehicle' },
        { id: 19, name: 'Reject', tab: 'Offer' },
        {
          id: 20,
          name: 'Send to Load Board',
          tab: 'Send to Load Board',
          disabled: true,
          tooltip: 'This feature is not available for your company at this time.',
        },
        { id: 17, name: 'Cancel Shipment', cancel: true },
      ];
    }
    if (rowCheckBoxes.some((item) => (item.is_edi_api || item.manual_edi_api) && !item.accept_date)) {
      return [{ id: 17, name: 'Cancel Shipment', cancel: true }];
    }
    return [
      { id: 15, name: 'Dispatch', tab: 'Vehicle' },
      { id: 16, name: 'Offer', tab: 'Offer' },
      {
        id: 20,
        name: 'Send to Load Board',
        tab: 'Send to Load Board',
        disabled: true,
        tooltip: 'This feature is not available for your company at this time.',
      },
      { id: 17, name: 'Cancel Shipment', cancel: true },
    ];
  };

  const rowReserveHandler = (rowData, item) => {
    // unReserve Shipment
    if (rowData?.shipment_reserve) {
      getReservation({ shipment_id: rowData?.shipment_reserve?.id })
        .then((res) => {
          if (res?.data) {
            setUnReserveEndPointData(res?.data);
            if (item) {
              setTablePlannerModalObj({ item, rowData });
            } else {
              onClickTableRowsEditOption({ id: 112, name: 'Unreserve Shipment' }, null, rowData, 'modal');
            }
          } else {
            dispatch(handleToaster({ visible: true, type: 'warning', message: res?.message }));
          }
        })
        .catch(() => {
          // Do nothing
        });
    } else {
      onClickTableRowsEditOption({ id: 12, name: 'Reserve Shipment' }, null, rowData, 'modal');
    }
  };

  function onClickRowIcon(e, rowData, type) {
    e.stopPropagation();
    switch (type) {
      case 'notes':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 6 } });
        break;
      default:
    }
  }

  function filterShipmentsByIds(ids, shipments) {
    return shipments.filter((shipment) => ids.includes(shipment.shipment_id));
  }

  const onAcceptEdiApi = async (ids) => {
    try {
      await tableCloudPopover({ shipment_ids: ids, acceptOrDecline: 1 });
      showToaster({ type: 'success', message: 'Shipments Accepted Successfully!' });
      const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
      const allFilterData = { ...getPlanerFilterData, ...tabData };
      setShowBulkActions(false);
      setRowCheckBoxes([]);
      getPlanner({ allFilterData });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
  };

  const onDeclineEdiApi = async (ids) => {
    try {
      await tableCloudPopover({ shipment_ids: ids, acceptOrDecline: 2 });
      showToaster({ type: 'success', message: 'Shipments Declined Successfully!' });
      const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
      const allFilterData = { ...getPlanerFilterData, ...tabData };
      setShowBulkActions(false);
      setRowCheckBoxes([]);
      getPlanner({ allFilterData });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
  };

  const revertOffers = async (shipment_id) => {
    try {
      await revertAllOffers({ shipment_id, resend: 0 });
      showToaster({ type: 'success', message: 'All offers have been successfully revered!' });
      const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
      const allFilterData = { ...getPlanerFilterData, ...tabData };
      getPlanner({ allFilterData });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  /** routes Bulk action and rowsEditIcons-OverLay* */
  function onClickBulkAction(item, index, arr) {
    const ids = arr.map((el) => el.shipment_id);
    const rowData = filterShipmentsByIds(ids, planerData.data);
    switch (item?.id) {
      case 15:
      case 16:
        if (arr.length === 1) {
          onRowClick(null, arr[0], true);
        } else {
          const queryParams = new URLSearchParams();
          ids.forEach((id) => {
            queryParams.append('id', id);
          });
          if (item.tab) {
            queryParams.set('tab', item.tab);
          }
          navigate(`/planner/plan?${queryParams.toString()}&action=${item.tab === 'Offer' ? 'offer' : 'dispatch'}`);
        }
        break;
      case 17:
        setTablePlannerModalObj({ item, rowData });
        break;
      case 18:
        onAcceptEdiApi(ids);
        break;
      case 19:
        onDeclineEdiApi(ids);
        break;
      case 20:
        break;
      default:
    }
  }

  function onClickTableRowsEditOption(item, index, rowData, type) {
    if (item?.route) {
      if (item.id === 2 && type === 'modal') {
        navigate(`/shipment/${rowData?.shipment_id}`, { state: rowData });
      } else if (item.id === 16) {
        navigate(`/${item.route}/${rowData.shipment_id}/new`);
      } else {
        navigate(`/planner/${item?.route}?id=${rowData?.id}`);
      }
    } else {
      switch (item?.id) {
        case 1:
          dispatch(updateRowEditItem({ ...rowData, label: 'Shipment' }));
          break;
        case 12:
          setTablePlannerModalObj({ item, rowData });
          break;
        case 5:
          setTablePlannerModalObj({ item, rowData });
          break;
        case 8:
        case 11:
        case 13:
        case 24:
          setTablePlannerModalObj({ item, rowData });
          break;
        case 17:
        case 125:
          setTablePlannerModalObj({ item, rowData: [rowData] });
          break;
        case 112:
          rowReserveHandler(rowData, item);
          break;
        case 20:
          navigate(`/planner/plan?id=${rowData.shipment_id}&action=dispatch`);
          break;
        case 21:
          navigate(`/planner/plan?id=${rowData.shipment_id}&action=offer`);
          break;
        case 22:
          revertOffers(rowData.shipment_id);
          break;
        default:
      }
    }
  }

  const getShipmentSettings = async () => {
    try {
      const { data } = await GetSettingsShipments();
      setShipmentSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onRowClick = (e, value, ignoreSelected, actionType) => {
    try {
      if (!rowCheckBoxes?.length || ignoreSelected) {
        const { id: localId } = JSON.parse(localStorage.getItem('user')) || {};
        const userId = value?.shipment_reserve?.user?.id;
        const shipmentId = value?.shipment_id;
        const { status, pending_dispatch, accept_date, expired_at, declined_at, is_edi_api, manual_edi_api } = value;
        if (
          value?.shipment_type === 'slave' ||
          value?.shipment_type === 'split-parent' ||
          (+localId !== +userId && !Number.isNaN(+userId))
        ) {
          navigate(`/shipment/${shipmentId}`, { state: value });
        } else {
          if (pending_dispatch) {
            navigate(`/planner/pending-dispatch?id=${shipmentId}`);
          } else {
            if (status === 'Offered') {
              if (hasBrokerage) {
                navigate(`/offered-shipments/${shipmentId}`);
              } else {
                navigate(`/planner/offer?id=${shipmentId}`);
              }
            } else if ((is_edi_api || manual_edi_api) && !accept_date && !declined_at && !expired_at) {
              setCancel({ visible: true, data: value });
            } else if ((is_edi_api || manual_edi_api) && (!!declined_at || !!expired_at)) {
              navigate(`/shipment/${shipmentId}`);
            } else {
              if (!actionType && hasBrokerage) {
                if (
                  !!shipmentSettings?.default_shipment_dispatch &&
                  !!shipmentSettings.default_shipment_dispatch_option
                ) {
                  navigate(
                    `/planner/plan?id=${shipmentId}&action=${
                      shipmentSettings.default_shipment_dispatch_option === 1 ? 'dispatch' : 'offer'
                    }`
                  );
                } else {
                  setSelectedShipment(value);
                }

                return;
              }
              navigate(
                actionType ? `/planner/plan?id=${shipmentId}&action=${actionType}` : `/planner/plan?id=${shipmentId}`
              );
            }
          }
        }
      }
    } catch (e) {
      // Do nothing
    }
  };

  function tableRowCheckboxChange(value, arr) {
    if (value === 'all') {
      const cond = rowCheckBoxes.includes('all');
      const all = cond ? [] : ['all'];

      !cond &&
        arr.data.forEach((row) => {
          all.push(row?.id);
        });

      setRowCheckBoxes(all);
    } else {
      let newRowData;
      const activeTr = document.querySelector(`td[value='${value}']`)?.parentElement;
      if (!rowCheckBoxes.some((el) => el.shipment_id === value)) {
        if (activeTr) activeTr.style.background = palette.indigo0;
        newRowData = [...rowCheckBoxes, arr.data.find((el) => el.shipment_id === value)];
        // const allChecked = arr.data.every((el) => newRowData.includes(el?.id));
        // !!allChecked && newRowData.push('all');
        setRowCheckBoxes(newRowData);
      } else {
        if (activeTr) activeTr.style.background = null;
        newRowData = rowCheckBoxes.filter((el) => el.shipment_id !== value);
        setRowCheckBoxes(newRowData);
      }
    }
  }

  const getPlannerTabs = useCallback(({ type }) => {
    return getTabs({ type })
      .then((res) => {
        if (res && res?.data) {
          const newData = res.data.map((el) => {
            return {
              ...el,
              label: el?.data?.name,
              key: uuid(),
              value: uuid(),
            };
          });
          return [
            {
              key: 'all',
              label: 'All',
              value: 1,
              id: ALL_TAB_FILTER_ID,
              count: 0,
            },
            ...newData,
          ];
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterByGroups = useCallback(() => {
    getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: el.group_name,
              id: el.id,
              value: 'groups',
              type: 'checkbox',
              key: uuid(),
              typeData: 'groups',
            };
          });
          setFilterByGroupsData(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterStopPointData = useCallback(() => {
    customerStopPoint().then((res) => {
      if (res && res?.data) {
        const stopPoints = res.data.map((el) => ({
          id: el.id,
          key: el.id,
          label: el?.location_name,
        }));
        setStopPoints([...stopPoints]);
      }
    });
  }, []);

  const getFilterCustomerData = useCallback(() => {
    getBillToBillingCharges().then((res) => {
      const newCustomers = res.data.map((el) => ({
        id: el.id,
        title: el.company_name,
        label: el.company_name,
        key: el.id,
      }));
      setCustomers(newCustomers);
    });
  }, []);

  const getFilterByEquipmentType = useCallback(async () => {
    const filterByEquipmentData = [];

    await Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicle, trailers]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 1) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
        if (trailers && trailers?.data) {
          trailers.data.forEach((el) => {
            const filterByData = {
              title: el.title,
              id: el.id,
              value: 'equipment_types',
              type: 'checkbox',
              key: uuid(),
              typeData: 'trailers',
            };
            filterByEquipmentData.push(filterByData);
          });
        }
      })
      .catch(() => {
        // Do nothing
      });

    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);

  function extractDates(inputStr) {
    const regex = /(\d{2}\/\d{2}\/\d{4}) - (\d{2}\/\d{2}\/\d{4})/;
    const regexStartDate = /(\d{2}\/\d{2}\/\d{4})/;
    const match = inputStr.match(regex);
    const matchStartDate = inputStr.match(regexStartDate);
    if (match) {
      return {
        startDate: match[1],
        endDate: match[2],
      };
    }
    if (matchStartDate) {
      return {
        startDate: matchStartDate[1],
        endDate: matchStartDate[1],
      };
    }
    return null;
  }

  const onApplyFilterCheckboxes = ({
    checkboxes,
    status,
    groups,
    customers,
    stop_points,
    edi_api_statuses,
    equipment_types,
    shipment_id,
    reference_id,
    valuePickupDate,
    valueDeliveryDate,
    origin,
    destination,
    created_by,
  }) => {
    const TYPES = ['status', 'groups', 'equipment_types'];

    const {
      status: statusArr,
      groups: groupsArr,
      equipment_types: equipment_types_Arr,
      customer,
      stop_point,
      edi_api_status,
      shipment_id: shipmentIds,
      reference_id: referenceIds,
    } = checkboxes;

    const { origin_city, origin_state, origin_zipcode, origin_miles } = origin || {};
    const { destination_city, destination_state, destination_zipcode, destination_miles } = destination || {};

    const statusParent = { id: statusArr?.[0]?.id, value: TYPES[0], searchValue: statusArr, title: 'Status' };
    const groupsParent = { id: groupsArr?.[0]?.id, value: TYPES[1], searchValue: groupsArr, title: 'Tags' };
    const customerParent = { id: customer?.[0]?.id, value: TYPES[1], searchValue: customer, title: 'Customer' };
    const shipmentIdsParent = {
      id: shipmentIds?.[0],
      value: TYPES[1],
      searchValue: shipmentIds,
      title: 'Shipment ID',
    };
    const referenceIdsParent = {
      id: referenceIds?.[0],
      value: TYPES[1],
      searchValue: referenceIds,
      title: 'Reference ID',
    };
    const stop_pointParent = {
      id: stop_point?.[0]?.id,
      value: TYPES[1],
      searchValue: stop_point,
      title: 'Stop Point',
    };
    const ediApiParent = {
      id: edi_api_status?.[0]?.id,
      value: TYPES[1],
      searchValue: edi_api_status,
      title: 'EDI / API',
    };
    const equipmentParent = {
      id: equipment_types_Arr?.[0]?.id,
      value: TYPES[2],
      searchValue: equipment_types_Arr,
      title: 'Equipment types',
    };

    const selectedValues = [...statusArr, ...groupsArr, ...equipment_types_Arr]; // filter.selectedValues[state]

    // data in backEnd
    const allFilterData = {
      ...getPlanerFilterData,
      status,
      groups,
      equipment_types,
      customers,
      stop_point: stop_points,
      ediStatus: edi_api_statuses,
      shipment_id: shipment_id?.[0],
      reference_id: reference_id?.[0],
      created_by,
      ...activeTabData?.data?.filters,
    };

    let originParent;
    let searchValue = '';
    if (origin_city && origin_state) {
      searchValue = `${origin_city}, ${origin_state}`;
      allFilterData.origin_city = origin_city;
      allFilterData.origin_state = origin_state;
    } else if (origin_zipcode) {
      searchValue = `${origin_zipcode}`;
      allFilterData.origin_zipcode = origin_zipcode;
    }
    if (origin_zipcode && origin_city && origin_state) {
      searchValue += `, ${origin_zipcode}`;
    }
    if (origin_miles && ((origin_state && origin_city) || origin_zipcode)) {
      allFilterData.origin_miles = origin_miles;
      searchValue += ` (${origin_miles} miles)`;
    }
    if (searchValue) {
      originParent = {
        id: 'origin ',
        value: TYPES[1],
        searchValue,
        title: 'Origin',
      };
    }

    let destinationParent;
    let destinationSearchValue = '';
    if (destination_city && destination_state) {
      destinationSearchValue = `${destination_city}, ${destination_state}`;
      allFilterData.destination_city = destination_city;
      allFilterData.destination_state = destination_state;
    } else if (destination_zipcode) {
      destinationSearchValue = `${destination_zipcode}`;
      allFilterData.destination_zipcode = destination_zipcode;
    }
    if (destination_zipcode && destination_city && destination_state) {
      destinationSearchValue += `, ${destination_zipcode}`;
    }
    if (destination_miles && ((destination_state && destination_city) || destination_zipcode)) {
      allFilterData.destination_miles = destination_miles;
      destinationSearchValue += ` (${destination_miles} miles)`;
    }
    if (destinationSearchValue) {
      destinationParent = {
        id: 'destination ',
        value: TYPES[1],
        searchValue: destinationSearchValue,
        title: 'Destination',
      };
    }

    let pickupDateRangeParent;
    if (valuePickupDate) {
      pickupDateRangeParent = {
        id: 'pickup',
        value: TYPES[1],
        searchValue: valuePickupDate,
        title: 'Pickup Date Range',
      };
      const dates = extractDates(valuePickupDate);
      allFilterData.origin_scheduled_from = dates.startDate;
      allFilterData.origin_scheduled_to = dates.endDate;
    }

    let deliveryDateParent;
    if (valueDeliveryDate) {
      deliveryDateParent = {
        id: 'pickup',
        value: TYPES[1],
        searchValue: valueDeliveryDate,
        title: 'Delivery Date Range',
      };
      const dates = extractDates(valueDeliveryDate);
      allFilterData.destination_scheduled_from = dates.startDate;
      allFilterData.destination_scheduled_to = dates.endDate;
    }

    // show data in user
    const showData = [...showHeaderFilters];
    const filtered = showData.filter((option) => !TYPES.includes(option?.value));

    const newShowData = [...filtered];
    statusParent?.id && newShowData.push(statusParent);
    groupsParent?.id && newShowData.push(groupsParent);
    equipmentParent?.id && newShowData.push(equipmentParent);
    customerParent?.id && newShowData.push(customerParent);
    stop_pointParent?.id && newShowData.push(stop_pointParent);
    ediApiParent?.id && newShowData.push(ediApiParent);
    shipmentIdsParent?.id && newShowData.push(shipmentIdsParent);
    referenceIdsParent?.id && newShowData.push(referenceIdsParent);
    pickupDateRangeParent && newShowData.push(pickupDateRangeParent);
    deliveryDateParent && newShowData.push(deliveryDateParent);
    originParent?.id && newShowData.push(originParent);
    destinationParent?.id && newShowData.push(destinationParent);

    setShowHeaderFilters(newShowData);
    updateFilter({ ...filter, selectedValues });
    setGetPlanerFilterData({ ...getPlanerFilterData, status, groups, equipment_types });
    getPlanner({ allFilterData });
  };

  const onChangeMultiselectSearchBy = useCallback(
    (data) => {
      const values = showHeaderFilters.map((el) => el.value);

      const updateData = { ...filter, searchBy: data };
      if (data.value === 'all' && !values.includes(data.value)) updateData.allValue = '';
      updateFilter(updateData);
      onReset({ newSearchBy: data });
    },
    [showHeaderFilters, filter, updateFilter]
  );

  const onChangeRowPerPage = (rowPage) => {
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };

    setRowPerPage(rowPage);
    getPlanner({ page: 1, itemsPerPage: rowPage, allFilterData });
  };

  const onPageChange = (page) => {
    const lastPage = planerData.last_page;
    const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
    const allFilterData = { ...getPlanerFilterData, ...tabData };

    if (lastPage !== 1) {
      getPlanner({ page, itemsPerPage: rowPerPage, allFilterData });
    }
  };

  function updateFilter(updatedFilter) {
    setFilter(updatedFilter);
  }

  const onClickModalButtons = (isVisible, item, typeClose) => {
    item !== null && onChangeTab(item, typeClose);
    setShowModal(isVisible);
  };

  const implementCountTotal = useCallback(
    (total, activeData, reset, modalTypeData) => {
      const type = modalTypeData?.type;
      const modalUpdatedData = modalTypeData?.data;
      const selectedData = activeData || activeTabData;

      if (selectedData) {
        const filterTableTop = filter.filterTableTop.map((el) => {
          if (+el.id === Number(selectedData?.id) || (selectedData.key === 'all' && +el.id === +selectedData.id)) {
            return {
              ...el,
              count: total,
            };
          }
          return el;
        });

        // reset
        if (reset) {
          setFilter((prevState) => {
            return { ...prevState, ...reset, filterTableTop: [...filterTableTop] };
          });
          setShowHeaderFilters([]);
        } else {
          // modal add and delete
          if (type === 'add' || type === 'delete' || type === 'update') {
            if (type === 'add') {
              const addedData = {
                ...modalUpdatedData,
                count: total,
                label: modalUpdatedData.data.name,
                key: uuid(),
                value: uuid(),
              };
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...prevState.filterTableTop, { ...addedData }],
                };
              });
            } else if (type === 'delete') {
              const id = modalUpdatedData;
              const newFilteredData = filter.filterTableTop.filter((el) => el.id !== id);
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newFilteredData],
                };
              });
            } else if (type === 'update') {
              const id = modalUpdatedData?.id;
              const newUpdatedData = filter.filterTableTop.map((el) => {
                if (el.id === id) {
                  return {
                    ...modalUpdatedData,
                    count: total,
                    label: modalUpdatedData.data.name,
                    key: uuid(),
                    value: uuid(),
                  };
                }
                return el;
              });
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newUpdatedData],
                };
              });
            }
          } else if (filterTableTop?.length > 0) {
            setFilter((prevState) => {
              return { ...prevState, filterTableTop: [...filterTableTop] };
            });
          }
        }
      }
    },
    [activeTabData, filter, updateFilter]
  );

  const getShipmentsTotalData = async (params) => {
    try {
      if (shipmentTotalsController.current) {
        shipmentTotalsController.current.abort();
        shipmentTotalsController.current = new AbortController();
      }

      const { data } = await getPlannerTotal({ ...params, signal: shipmentTotalsController.current.signal });
      setShipmentsTotal(data);
    } catch (e) {
      // Do nothing
    }
  };

  function getPlanner(data) {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    const {
      page = 1,
      reset,
      filtersType,
      sort,
      filtersValue,
      itemsPerPage,
      remainingObj,
      allFilterData,
      getData = false,
      implementActiveData,
      modalTypeData,
      hasALoading = true,
    } = data || {};

    hasALoading && setLoading(true);
    if (!allFilterData) {
      const params = {
        page,
        itemsPerPage: itemsPerPage || rowPerPage,
        filtersType,
        filtersValue,
        sort,
        remainingData: remainingObj
          ? getData
            ? { ...remainingObj, ...getPlanerFilterData }
            : remainingObj
          : getPlanerFilterData,
      };

      getShipmentsTotalData(params);
      return Planner({ ...params, signal: controller.signal })
        .then((res) => {
          bindPusher(res);
          implementCountTotal(res.total, implementActiveData, reset, modalTypeData);
          setPlanerData(res);
          return res;
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const updatedAllFilterData = {
      ...allFilterData,
      origin_miles:
        allFilterData.origin_miles || (allFilterData.origin_state && allFilterData.origin_city ? 100 : undefined),
      destination_miles:
        allFilterData.destination_miles ||
        (allFilterData.destination_state && allFilterData.destination_city ? 100 : undefined),
    };

    const params = {
      page,
      sort,
      itemsPerPage: itemsPerPage || rowPerPage,
      allFilterData: updatedAllFilterData,
    };
    getShipmentsTotalData(params);

    return Planner({
      ...params,
      signal: controller.signal,
    })
      .then((res) => {
        bindPusher(res);
        implementCountTotal(res.total, implementActiveData, reset, modalTypeData);
        setPlanerData(res);
        return res;
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const mapperSettingsData = useCallback((key) => {
    const map = {
      type: 'Type',
      miles: 'Miles',
      stops: 'Stops',
      amount: 'Amount',
      groups: 'Tags',
      status: 'Status',
      customer: 'Customer',
      shipment_id: 'Shipment ID',
      origin_stop_name: 'Origin',
      destination_stop_name: 'Destination',
      created_by: 'Created By',
    };
    return map[key] ? map[key] : key;
  }, []);

  const getSettings = useCallback(() => {
    getPlannerPopoverSettings({ type: 'planner' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });

          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [mapperSettingsData]);

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [columns[0]];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    cols.push(columns[columns.length - 1]);
    return cols;
  };

  function sortingQuery(field, nested_field) {
    controller && controller.abort();
    controller = new AbortController();
    let sortField;
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]:${direction}`;
    } else {
      sortField = `sort[][${field}]:${direction}`;
    }

    setSort({ field, sortBy: direction });
    getPlanner({ sort: [sortField], hasALoading: false });
  }

  const animationRowData = useCallback(
    (id) => {
      // For animation
      setAnimationList({ ...animationList, [id]: true });
      setTimeout(() => {
        setAnimationList({ ...animationList, [id]: false });
      }, 1500);
      // For animation
    },
    [animationList]
  );

  const onEditTableTab = useCallback((item) => {
    const { data, user_id, id, system } = item;
    const { name, filters } = data;
    const { status, groups, equipment_types, customers, stop_point, is_edi, ediStatus, zipcode } = filters;
    const initialValue = {
      name,
      status,
      groups,
      equipment_types,
      customers,
      ediStatus,
      stop_point,
      user_id,
      id,
      system,
      is_edi,
      zipcode,
    };
    setTabModalEditData(initialValue);
    setShowModal(true);
  }, []);

  const onChangeTabHelper = useCallback(
    (disabledFiltersData) => {
      const { customers, groups, status, equipment_types, stop_point } = disabledFiltersData;
      const showedFiltersArrData = showHeaderFilters.filter((el) => {
        const value = el?.value;
        if (value) {
          return disabledFilters.hasOwnProperty(value);
        }
        return Array.isArray(el) && el?.[0].type === 'stop_point';
      });
      const newShowHeaderFilters = showHeaderFilters.filter(
        (el) =>
          (!Array.isArray(el) && !disabledFiltersData[el.value]) ||
          (Array.isArray(el) && el?.[0].type === 'stop_point' && !stop_point)
      );

      setShowHeaderFilters(newShowHeaderFilters);

      const newSelectedValues = filter.selectedValues.filter((el) => !disabledFiltersData[el?.value]);
      const clone = { ...getPlanerFilterData };

      Object.keys(disabledFiltersData).forEach((key) => {
        const value = disabledFiltersData[key];
        if (value) {
          if (key === 'stop_point') {
            delete clone[key];
            delete clone.stop_point_scheduled_from;
            delete clone.stop_point_scheduled_to;
            setDateRangeStopPoint([null, null]);
          } else {
            delete clone[key];
          }
        }
      });

      setGetPlanerFilterData(clone);
      setFilter((prevState) => {
        return {
          ...prevState,
          selectedValues: newSelectedValues,
          customerSelectData: !customers ? prevState.customerSelectData : [],
          stopPointAll: !stop_point ? prevState.stopPointAll : LOCATION_INITIAL_VALUE_STOP_POINT,
        };
      });

      for (let i = 0; i < Object.keys(disabledFiltersData).length; i++) {
        const key = Object.keys(disabledFiltersData)[i];
        const value = Object.values(disabledFiltersData)[i];
        const foundedSelectData = showedFiltersArrData.find(
          (el) => (!Array.isArray(el) && el.value === key) || (Array.isArray(el) && el?.[0].type === 'stop_point')
        );
        if (value && foundedSelectData) {
          setFilter((prevState) => {
            return {
              ...prevState,
              searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
            };
          });
        }
      }
      const newLocalData = {
        groups: groups ? [] : localFilterCheckboxes.groups,
        status: status ? [] : localFilterCheckboxes.status,
        equipment_types: equipment_types ? [] : localFilterCheckboxes.equipment_types,
      };
      setLocalFilterCheckboxes(newLocalData);
      !!groups && setGroups([]);
      !!status && setStatuses([]);
      !!equipment_types && setEquipments([]);
    },
    [showHeaderFilters, disabledFilters, filter]
  );

  const onChangeTab = useCallback(
    (item, modalTypeData) => {
      const allTabData = item?.data?.filters;
      const disabledFiltersData = {};
      const remainingObj = {};
      let allFilterData;
      const activeTabData = { ...item, data: { ...item.data, filters: {} } };
      if (item.key !== 'all') {
        Object.keys(allTabData).forEach((key) => {
          const value = allTabData[key];
          const conditionCloudSwitch = key === 'is_edi' && !!value;
          if (value.length > 0 || conditionCloudSwitch) {
            activeTabData.data.filters[key] = value;
          }
        });
      }
      if (allTabData) {
        const cloneGetPlannerFilterData = { ...getPlanerFilterData };
        Object.keys(allTabData).forEach((key) => {
          const value = allTabData[key];
          if (Array.isArray(value) && value.length > 0) {
            disabledFiltersData[key] = true;
            remainingObj[key] = value;
          } else if (typeof value === 'object' && Object.keys(value).length > 0) {
            disabledFiltersData[key] = true;
            remainingObj[key] = value?.key;
            delete cloneGetPlannerFilterData.stop_point_scheduled_from;
            delete cloneGetPlannerFilterData.stop_point_scheduled_to;
          } else if ((typeof value === 'boolean' && !!value) || typeof value === 'string') {
            remainingObj[key] = value;
          }
        });
        allFilterData = { ...cloneGetPlannerFilterData, ...remainingObj };
      } else {
        if (item.key === 'all') {
          allFilterData = { ...getPlanerFilterData };
        } else if (item) {
          allFilterData = { ...getPlanerFilterData };
        }
      }
      setActiveTabData(activeTabData);
      setDisabledFilters({ ...DEFAULT_DISABLED_TABS, ...disabledFiltersData });
      onChangeTabHelper({ ...DEFAULT_DISABLED_TABS, ...disabledFiltersData });
      setPlanerData({ data: [] });
      setLoading(true);
      getPlanner({ allFilterData, implementActiveData: item, modalTypeData });
    },
    [getPlanerFilterData, onChangeTabHelper, getPlanner, showHeaderFilters]
  );

  function onDeleteDatePickerCreatedBy() {
    const SHOW_ID = 987654;
    const newShowData = showHeaderFilters.filter((el) => Number(el?.[0]?.id) !== +SHOW_ID);
    setShowHeaderFilters(newShowData);
    setDateRangeCreatedBy([null, null]);
    setFilter({ ...filter, createdDateAll: INITIAL_VALUE_CREATED_DATE });

    const cloneGetPlanerFilterData = { ...getPlanerFilterData };
    delete cloneGetPlanerFilterData.created_date_start;
    delete cloneGetPlanerFilterData.created_date_end;
    setGetPlanerFilterData(cloneGetPlanerFilterData);
    getPlanner({ allFilterData: { ...cloneGetPlanerFilterData, ...activeTabData?.data?.filters } });
  }

  function onChangeDatePickerCreatedBy(dates) {
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
    const SHOW_ID = 987654;

    const first = dates[0];
    const last = dates[1];
    const STOP_POINT_FROM = 'created_date_start';
    const STOP_POINT_TO = 'created_date_end';
    const formatDateFirst = moment(first).format('MM/DD/YYYY');
    const formatDateLast = moment(last).format('MM/DD/YYYY');
    const isValidFirst = moment(formatDateFirst).isValid();
    const isValidLast = moment(formatDateLast).isValid();

    setDateRangeCreatedBy(dates);
    setFilter((prev) => {
      return {
        ...prev,
        createdDateAll: {
          ...prev.createdDateAll,
          [STOP_POINT_FROM]: { ...prev.createdDateAll[STOP_POINT_FROM], searchValue: formatDateFirst },
          [STOP_POINT_TO]: { ...prev.createdDateAll[STOP_POINT_TO], searchValue: formatDateLast },
        },
      };
    });

    const foundedShowIndex = showHeaderFilters.findIndex((el) => Array.isArray(el) && +el[0].id === +SHOW_ID);
    const showArr = [
      {
        id: SHOW_ID,
        from: formatDateFirst,
        to: isValidLast ? formatDateLast : '',
        key: uuid(),
        searchValue: `${formatDateFirst} ${isValidLast ? `- ${formatDateLast}` : ''}`,
        title: 'Created Date',
        value: 'created_date',
      },
    ];

    if (foundedShowIndex !== -1) {
      const cloneShow = [...showHeaderFilters];
      cloneShow[foundedShowIndex] = showArr;
      setShowHeaderFilters(cloneShow);
    } else {
      setShowHeaderFilters([...showHeaderFilters, showArr]);
    }
    const cloneGetPlanerFilterData = { ...getPlanerFilterData };
    if (isValidFirst) {
      cloneGetPlanerFilterData.created_date_start = formatDateFirst;
      allFilterData[STOP_POINT_FROM] = formatDateFirst;
    } else {
      delete allFilterData[STOP_POINT_FROM];
      delete cloneGetPlanerFilterData[STOP_POINT_FROM];
    }

    if (isValidLast) {
      cloneGetPlanerFilterData.created_date_end = formatDateLast;
      allFilterData[STOP_POINT_TO] = formatDateLast;
    } else {
      delete allFilterData[STOP_POINT_TO];
      delete cloneGetPlanerFilterData[STOP_POINT_TO];
    }

    setGetPlanerFilterData(cloneGetPlanerFilterData);

    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    getPlanner({ allFilterData, signal: controller.signal });
  }

  /** delete 1)All 2)Customer 3)Crated_By - on click show data X-btn* */
  function onDeleteManyDataItem(item, parent_value) {
    if (item === 'all') {
      const deletedData = showHeaderFilters.filter((el) => el.value !== item);
      const filterType = showHeaderFilters?.find((el) => el.value === item)?.value;
      const remainingObj = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
      delete remainingObj[filterType];
      updateFilter({ ...filter, allValue: '' });
      setGetPlanerFilterData({ ...remainingObj });
      setShowHeaderFilters(deletedData);
      getPlanner({ filtersType: '', filtersValue: '', reset: false, remainingObj });
      return;
    }

    const mapStates = {
      status: { state: statuses, setState: setStatuses },
      groups: { state: groups, setState: setGroups },
      equipment_types: { state: equipments, setState: setEquipments },
    };

    const { id, value } = item || {};
    const parentIndex = showHeaderFilters.findIndex((el) => el.value === parent_value);
    const parent = showHeaderFilters[parentIndex];

    if (parent) {
      const newFilterData = parent.searchValue.filter((el) => +el.id !== +id);
      const empty = newFilterData.length === 0;
      let allFilterNewData;

      if (empty) {
        allFilterNewData = showHeaderFilters.filter((el) => el.value !== parent_value);
      } else {
        const showHeaderFiltersData = [...showHeaderFilters];
        showHeaderFiltersData[parentIndex] = {
          ...showHeaderFiltersData[parentIndex],
          searchValue: newFilterData,
        };
        allFilterNewData = showHeaderFiltersData;
      }
      let activeOption;
      const clonePlannerFilterData = { ...getPlanerFilterData };

      if (parent?.value !== 'customers' && parent?.value !== 'created_by') {
        activeOption = filter.selectedValues.filter((el) => +el.id !== +id);

        const foundedOptionIndex = filter.selectedValues.findIndex((el) => +el.id === +id);
        const foundedOption = filter.selectedValues[foundedOptionIndex];

        const { value: type, id: foundedId } = foundedOption || {};
        const { state, setState } = mapStates[type];
        const newState = state.filter((elId) => +elId !== +foundedId);
        setState(newState);
        setLocalFilterCheckboxes((prevState) => {
          return {
            ...prevState,
            [type]: activeOption,
          };
        });
        setFilter((prevState) => ({ ...prevState, selectedValues: activeOption }));
      } else if (parent?.value === 'customers') {
        activeOption = filter.customerSelectData.filter((el) => +el.id !== +id);
        setFilter((prevState) => ({ ...prevState, customerSelectData: activeOption }));
      } else if (parent?.value === 'created_by') {
        activeOption = filter.created_by.filter((elId) => +elId !== +id);
        setFilter((prevState) => ({ ...prevState, created_by: activeOption }));
      }

      const selectedValues = activeOption?.filter((el) => el.value === value);
      const arrTypeIds = selectedValues.map((el) => (parent?.value === 'created_by' ? el : el.id));
      clonePlannerFilterData[parent.value] = arrTypeIds;
      const allFilterData = { ...clonePlannerFilterData, ...activeTabData?.data?.filters };
      getPlanner({ allFilterData }).then(() => {
        setGetPlanerFilterData(clonePlannerFilterData);
        setShowHeaderFilters(allFilterNewData);
      });
    }
  }

  /** Filter * */

  /** All * */
  const debounceAll = useMemo(() => {
    return debounce((value) => {
      const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
      const showData = [...showHeaderFilters];
      const indexAll = showData.findIndex((el) => Number(el?.key) === 9999);
      const showAllObj = { id: 1, key: 9999, searchValue: value, title: 'All', value: 'all' };
      indexAll !== -1 ? (showData[indexAll] = showAllObj) : showData.push(showAllObj);

      if (!value) {
        delete allFilterData.all;
        setGetPlanerFilterData({ ...allFilterData });
        showData.splice(indexAll, 1);
      } else {
        allFilterData.all = value;
        setGetPlanerFilterData({ ...getPlanerFilterData, all: value });
      }

      setShowHeaderFilters([...showData]);
      if (controller) controller.abort();
      controller = new AbortController();
      setLoading(true);
      getPlanner({ allFilterData, signal: controller.signal });
    }, 350);
  }, [activeTabData, getPlanerFilterData, showHeaderFilters]);

  function onChangeAll(value) {
    const updateData = { ...filter };
    if (filter.searchBy.value === 'name') updateData.allValue = value;
    if (filter.searchBy.value === 'all') updateData.allValue = value;
    setFilter(updateData);
    debounceAll(value);
  }

  /** Customers * */
  function onChangeCustomers(value) {
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };

    const customerSelectData = [...filter.customerSelectData];
    const foundedIndex = customerSelectData.findIndex((data) => data.id === value?.id);
    foundedIndex !== -1 ? customerSelectData.splice(foundedIndex, 1) : customerSelectData.push(value);

    const idsCustomers = customerSelectData.map((el) => el?.id);
    allFilterData.customers = idsCustomers;

    // show_data
    const showData = [...showHeaderFilters];
    const indexShowCustomer = showData.findIndex((el) => Number(el?.id) === +filter.searchBy.id);
    const showCustomerObj = { id: 333, key: uuid(), title: 'Customer', value: 'customers' };

    if (indexShowCustomer !== -1) {
      showCustomerObj.searchValue = [...customerSelectData];
      showData[indexShowCustomer] = showCustomerObj;
    } else {
      showCustomerObj.searchValue = [{ ...value }];
      showData.push(showCustomerObj);
    }
    if (customerSelectData.length === 0) showData.splice(indexShowCustomer, 1);
    setShowHeaderFilters(showData);
    // show_data

    setGetPlanerFilterData({ ...getPlanerFilterData, customers: idsCustomers });
    setFilter({ ...filter, customerSelectData });
    getPlanner({ allFilterData });
  }

  /** Stop-point-select-onChange * */
  function onChangeStopPointSelect(value) {
    const TYPE = 'stop_point';
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
    const foundIndexShow = showHeaderFilters.findIndex((el) => Array.isArray(el) && el[0].type === TYPE);

    if (foundIndexShow !== -1) {
      const stop_point_index = showHeaderFilters[foundIndexShow].findIndex((point) => point.value === TYPE);
      const cloneShowData = [...showHeaderFilters];
      stop_point_index === -1
        ? cloneShowData[foundIndexShow]?.push({ ...value, value: TYPE, type: TYPE, searchValue: value.label })
        : (cloneShowData[foundIndexShow][stop_point_index] = {
            ...value,
            value: TYPE,
            type: TYPE,
            searchValue: value.label,
          });
      setShowHeaderFilters(cloneShowData);
    } else {
      setShowHeaderFilters([
        ...showHeaderFilters,
        [
          {
            ...value,
            value: TYPE,
            type: TYPE,
            searchValue: value.label,
          },
        ],
      ]);
    }

    setGetPlanerFilterData({ ...getPlanerFilterData, stop_point: value?.id });
    allFilterData.stop_point = value?.id;
    getPlanner({ allFilterData });
    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: {
          ...prev.stopPointAll,
          stop_point: { ...prev.stopPointAll.stop_point, ...value, searchValue: value.label },
        },
      };
    });
  }

  /** Stop-point-select-Delete * */
  function onDeleteStopPointSelect() {
    const TYPE = 'stop_point';
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
    const cloneShowData = [...showHeaderFilters];
    const foundIndexShow = cloneShowData.findIndex((el) => Array.isArray(el) && el[0].type === TYPE);
    if (foundIndexShow !== -1) {
      const stop_point_index = cloneShowData[foundIndexShow].findIndex((point) => point.value === TYPE);
      if (cloneShowData[foundIndexShow]?.length === 1) {
        cloneShowData.splice(foundIndexShow, 1);
      } else {
        cloneShowData[foundIndexShow].splice(stop_point_index, 1);
      }

      setShowHeaderFilters(cloneShowData);
    }

    const cloneGetPlanerFilterData = { ...getPlanerFilterData };
    delete cloneGetPlanerFilterData.stop_point;
    setGetPlanerFilterData({ ...cloneGetPlanerFilterData });

    delete allFilterData.stop_point;
    getPlanner({ allFilterData });

    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: { ...prev.stopPointAll, stop_point: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point },
      };
    });
  }

  /** Stop-point-Dates * */
  function onChangeDatePickerStopPoint(dates) {
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
    const TYPE = 'stop_point';
    const first = dates[0];
    const last = dates[1];
    const STOP_POINT_FROM = 'stop_point_scheduled_from';
    const STOP_POINT_TO = 'stop_point_scheduled_to';
    const formatDateFirst = moment(first).format('MM/DD/YYYY');
    const formatDateLast = moment(last).format('MM/DD/YYYY');
    const isValidLast = !!moment(last).isValid();

    const cloneGetPlanerData = { ...getPlanerFilterData };
    const showData = [...showHeaderFilters];

    const foundIndexShow = showData.findIndex((el) => Array.isArray(el) && el?.[0].type === TYPE);
    const from = {
      key: uuid(),
      searchValue: formatDateFirst,
      type: 'stop_point',
      value: 'stop_point_scheduled_from',
    };
    const to = { key: uuid(), searchValue: formatDateLast, type: 'stop_point', value: 'stop_point_scheduled_to' };

    const arrDates = [from];

    if (isValidLast) {
      arrDates.push(to);
      cloneGetPlanerData.stop_point_scheduled_to = formatDateLast;
      allFilterData.stop_point_scheduled_to = formatDateLast;
    } else {
      delete cloneGetPlanerData.stop_point_scheduled_to;
      delete allFilterData?.stop_point_scheduled_to;
    }

    if (foundIndexShow === -1) {
      showData.push(arrDates);
    } else {
      const foundSelectStopPoint = showData?.[foundIndexShow]?.find((el) => el?.value === TYPE);
      !!foundSelectStopPoint && arrDates.push(foundSelectStopPoint);
      showData[foundIndexShow] = arrDates;
    }

    cloneGetPlanerData.stop_point_scheduled_from = formatDateFirst;
    allFilterData.stop_point_scheduled_from = formatDateFirst;

    setGetPlanerFilterData(cloneGetPlanerData);
    setShowHeaderFilters(showData);
    setDateRangeStopPoint(dates);
    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: {
          ...prev.stopPointAll,
          [STOP_POINT_FROM]: { ...prev.stopPointAll[STOP_POINT_FROM], searchValue: formatDateFirst },
          [STOP_POINT_TO]: {
            ...prev.stopPointAll[STOP_POINT_TO],
            searchValue: isValidLast ? formatDateLast : '',
          },
        },
      };
    });
    getPlanner({ allFilterData });
  }

  /** Stop-point-show-data-Delete * */
  function onDeleteStopPointItem(item) {
    const newShowData = [];
    const cloneGetPlannerData = { ...getPlanerFilterData };
    showHeaderFilters.forEach((el) => {
      if (Array.isArray(el) && el[0]?.type === 'stop_point') {
        let newStopPointArr;
        if (item?.value === 'stop_point') {
          newStopPointArr = el.filter((point) => point.value !== item.value);
          delete cloneGetPlannerData[item.value];
          setFilter((prevState) => {
            return {
              ...prevState,
              stopPointAll: {
                ...filter.stopPointAll,
                stop_point: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point,
              },
            };
          });
        } else {
          newStopPointArr = el.filter((point) => point?.value === 'stop_point');
          delete cloneGetPlannerData.stop_point_scheduled_from;
          delete cloneGetPlannerData.stop_point_scheduled_to;
          setDateRangeStopPoint([null, null]);
          setFilter((prevState) => {
            return {
              ...prevState,
              stopPointAll: {
                ...filter.stopPointAll,
                stop_point_scheduled_from: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point_scheduled_from,
                stop_point_scheduled_to: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point_scheduled_to,
              },
            };
          });
        }
        return newStopPointArr.length > 0 && newShowData.push(newStopPointArr);
      }
      newShowData.push(el);
    });
    setShowHeaderFilters(newShowData);
    setGetPlanerFilterData(cloneGetPlannerData);

    const allFilterData = { ...cloneGetPlannerData, ...activeTabData?.data?.filters };
    getPlanner({ allFilterData });
  }

  /** Location-inputs * */
  const debounceLocation = useMemo(() => {
    return debounce((value, name) => {
      const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
      const showData = [...showHeaderFilters];
      const index_show_location = showData.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === 'locations');
      const showLocationObj = {
        key: uuid(),
        searchValue: value,
        title: 'Location',
        valuePrefix: 'locations',
        value: name,
      };

      if (index_show_location === -1) {
        showData.push([showLocationObj]);
      } else {
        const index_loc_item = showData[index_show_location].findIndex((loc) => loc.value === name);
        if (!value) {
          showData[index_show_location].length === 1
            ? showData.splice(index_show_location, 1)
            : showData[index_show_location].splice(index_loc_item, 1);
        } else {
          index_loc_item === -1
            ? showData[index_show_location].push(showLocationObj)
            : (showData[index_show_location][index_loc_item] = showLocationObj);
        }
      }

      if (!value) {
        delete allFilterData[name];
        setGetPlanerFilterData({ ...allFilterData });
      } else {
        allFilterData[name] = value;
        setGetPlanerFilterData({ ...getPlanerFilterData, [name]: value });
      }

      setShowHeaderFilters([...showData]);
      if (controller) controller.abort();
      controller = new AbortController();
      getPlanner({ allFilterData, signal: controller.signal });
    }, 350);
  }, [activeTabData, getPlanerFilterData, showHeaderFilters]);

  function onChangeLocationValues(name, e) {
    const value = { ...filter?.locations, [name]: { ...filter?.locations[name], searchValue: e.target.value } };
    setFilter({ ...filter, [filter.searchBy.value]: value });
    debounceLocation(e.target.value, name);
  }

  /** Location-inputs and date delete * */
  function onDeleteLocationsItems(item) {
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
    if (item.value === 'locations') {
      delete allFilterData?.scheduled_from;
      delete allFilterData?.scheduled_to;
      setDateRangeLocation([null, null]);
    } else {
      delete allFilterData?.[item.value];
    }

    const locations = filter.locations;
    const newLocation = {};

    Object.keys(locations).forEach((key) => {
      const obj = locations[key];

      if (key === item.value) {
        if (item.value === 'locations') {
          newLocation.scheduled_from = { ...obj, value: 'scheduled_from', searchValue: '' };
          newLocation.scheduled_to = { ...obj, value: 'scheduled_to', searchValue: '' };
        } else {
          newLocation[key] = { ...obj, searchValue: '' };
        }
      } else {
        newLocation[key] = { ...obj, searchValue: '' };
      }
    });
    const newShowFilterData = [];
    showHeaderFilters.forEach((el) => {
      if (Array.isArray(el)) {
        const locData = el.filter((loc) => loc.value !== item.value);
        locData.length > 0 && newShowFilterData.push(locData);
      } else {
        newShowFilterData.push(el);
      }
    });

    setFilter((prev) => ({ ...prev, locations: newLocation }));
    if (item.value === 'locations') setDateRangeLocation([null, null]);
    setShowHeaderFilters(newShowFilterData);
    getPlanner({ allFilterData }).then(() => {
      setGetPlanerFilterData(allFilterData);
    });
  }

  /** Location-date-picker * */
  function onChangeLocationDatePicker(dates) {
    const allFilterData = { ...getPlanerFilterData, ...activeTabData?.data?.filters };
    const TYPE = 'locations';
    const first = dates[0];
    const last = dates[1];
    const SCHEDULED_FROM = 'scheduled_from';
    const SCHEDULED_TO = 'scheduled_to';
    const formatDateFirst = moment(first).format('MM/DD/YYYY');
    const formatDateLast = moment(last).format('MM/DD/YYYY');
    const isValidLast = !!moment(last).isValid();

    const cloneGetPlanerData = { ...getPlanerFilterData };
    const showData = [...showHeaderFilters];

    const index_show_location = showData.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === TYPE);
    const showLocationObj = {
      key: uuid(),
      title: 'Location',
      value: TYPE,
      from: formatDateFirst,
      valuePrefix: TYPE,
      to: isValidLast ? formatDateLast : '',
      searchValue: `${formatDateFirst} ${isValidLast ? `- ${formatDateLast}` : ''}`,
    };

    if (index_show_location === -1) {
      showData.push([showLocationObj]);
    } else {
      const index_loc_item = showData[index_show_location].findIndex((loc) => loc.value === TYPE);
      index_loc_item === -1
        ? showData[index_show_location].push(showLocationObj)
        : (showData[index_show_location][index_loc_item] = showLocationObj);
    }

    if (isValidLast) {
      cloneGetPlanerData[SCHEDULED_TO] = formatDateLast;
      allFilterData[SCHEDULED_TO] = formatDateLast;
    } else {
      delete cloneGetPlanerData[SCHEDULED_TO];
      delete allFilterData?.[SCHEDULED_TO];
    }

    cloneGetPlanerData[SCHEDULED_FROM] = formatDateFirst;
    allFilterData[SCHEDULED_FROM] = formatDateFirst;

    setGetPlanerFilterData(cloneGetPlanerData);
    setShowHeaderFilters(showData);
    setDateRangeLocation(dates);
    setFilter((prev) => {
      return {
        ...prev,
        locations: {
          ...prev.locations,
          [SCHEDULED_FROM]: { ...filter?.locations[SCHEDULED_FROM], searchValue: formatDateFirst },
          [SCHEDULED_TO]: {
            ...filter?.locations[SCHEDULED_TO],
            searchValue: isValidLast ? formatDateLast : '',
          },
        },
      };
    });
    getPlanner({ allFilterData });
  }

  /** Clear All * */
  function onReset({ searchBy = false, newSearchBy }) {
    const resetDaya = {
      ...filter,
      searchBy: searchBy ? { id: 1, title: 'All', value: 'all', key: 9999 } : newSearchBy || filter.searchBy,
      allValue: '',
      selectedValues: [],
      locations: LOCATION_INITIAL_VALUE,
      created_by: [],
      createdDateAll: INITIAL_VALUE_CREATED_DATE,
      stopPointAll: LOCATION_INITIAL_VALUE_STOP_POINT,
      customerSelectData: [],
    };
    setTriggerAdvancedClear(new Date());
    setShowHeaderFilters([]);

    setDateRangeLocation([null, null]);
    setDateRangeCreatedBy([null, null]);
    setDateRangeStopPoint([null, null]);

    setGroups([]);
    setStatuses([]);
    setEquipments([]);
    setLocalFilterCheckboxes({ status: [], groups: [], equipment_types: [] });
    setGetPlanerFilterData({});
    setStopPointCondition(true);
    setTimeout(() => {
      setStopPointCondition(false);
    }, 0);
    getPlanner({ allFilterData: { ...activeTabData?.data?.filters }, reset: resetDaya });
  }

  function onResetInCreate(value) {
    const reset = {
      ...filter,
      searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
      allValue: value,
      selectedValues: [],
      locations: LOCATION_INITIAL_VALUE,
      created_by: [],
      createdDateAll: INITIAL_VALUE_CREATED_DATE,
      stopPointAll: LOCATION_INITIAL_VALUE_STOP_POINT,
      customerSelectData: [],
    };

    setDateRangeLocation([null, null]);
    setDateRangeCreatedBy([null, null]);
    setDateRangeStopPoint([null, null]);

    setGroups([]);
    setStatuses([]);
    setEquipments([]);
    setLocalFilterCheckboxes({ status: [], groups: [], equipment_types: [] });

    setFilter((prevState) => {
      return { ...prevState, ...reset };
    });
    setGetPlanerFilterData({ all: value });
    setShowHeaderFilters([{ id: 1, key: 9999, searchValue: value, title: 'All', value: 'all' }]);

    setStopPointCondition(true);
    setTimeout(() => {
      setStopPointCondition(false);
    }, 0);
    getShipmentsTotalData({ allFilterData: { all: value } });
    return Planner({ allFilterData: { all: value } });
  }

  /** Filter * */

  function onSubmitPlannerRowEditChildes(data) {
    switch (data.lastData.item.id) {
      case 11:
        const queryParams = new URLSearchParams();
        data?.res?.forEach((shipmentId) => {
          queryParams.append('id', shipmentId);
        });
        navigate(`/planner?${queryParams.toString()}`);
        break;
      case 13:
        navigate(`/shipment/${data.lastData.rowData.shipment_id}`, { state: { defaultTab: 5 } });
        break;
      case 5:
      case 8:
      case 17:
      case 24:
      case 125:
        setActiveTabData({
          key: 'all',
          label: 'All',
          value: 1,
          id: ALL_TAB_FILTER_ID,
          count: 0,
        });
        getPlanner();
        break;
      default:
    }
  }

  useEffect(async () => {
    getSettings();
    if (
      (typeof filterInCreateShipment === 'object' && Object.keys(filterInCreateShipment).length > 0) ||
      !!stateLocation?.allValue
    ) {
      setLoading(true);
      const widths = await getColumnsWidth('planner');
      if (widths.data.length) {
        const updatedWidthsFromBackend = widths.data.reduce((acc, curr) => {
          acc[curr.column_type] = curr.width;
          return acc;
        }, {});
        setColumnWidths({
          ...DEFAULTWIDTHOFCOLUMNSPLANNER,
          ...updatedWidthsFromBackend,
        });
      }
      const allValue = filterInCreateShipment?.allValue ? filterInCreateShipment?.allValue : stateLocation?.allValue;
      onResetInCreate(allValue)
        .then((res) => {
          getPlannerTabs({ type: 'planner' }).then((tabs) => {
            const newFilterTabsData = tabs?.map((el) => {
              if (el.key === 'all') {
                return {
                  ...el,
                  count: res.total,
                };
              }
              return el;
            });
            setFilter((prevState) => {
              return {
                ...prevState,
                filterTableTop: newFilterTabsData,
              };
            });
          });
          setPlanerData(res);
          bindPusher(res);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      const widths = await getColumnsWidth('planner');
      if (widths.data.length) {
        const updatedWidthsFromBackend = widths.data.reduce((acc, curr) => {
          acc[curr.column_type] = curr.width;
          return acc;
        }, {});
        setColumnWidths({
          ...DEFAULTWIDTHOFCOLUMNSPLANNER,
          ...updatedWidthsFromBackend,
        });
      }
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();

      const shipmentIds = searchParams.getAll('id');

      if (shipmentIds?.length) {
        searchParams.delete('id');
        setSearchParams(searchParams);
      }

      getShipmentsTotalData({ page: 1, itemsPerPage: rowPerPage, shipmentIds });
      Planner({ page: 1, itemsPerPage: rowPerPage, shipmentIds, signal: controller.signal })
        .then((res) => {
          setPlanerData(res);
          bindPusher(res);
          if (filter.filterTableTop.length === 0) {
            getPlannerTabs({ type: 'planner' }).then((tabs) => {
              const newFilterTabsData = tabs?.map((el) => {
                if (el.key === 'all') {
                  return {
                    ...el,
                    count: res.total,
                  };
                }
                return el;
              });
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: newFilterTabsData,
                };
              });
            });
          }
          setLoading(false);
        })
        .catch(() => {
          getPlannerTabs({ type: 'planner' }).then((tabs) => {
            const newFilterTabsData = tabs?.map((el) => {
              if (el.key === 'all') {
                return {
                  ...el,
                  count: 0,
                };
              }
              return el;
            });
            setFilter((prevState) => {
              return {
                ...prevState,
                filterTableTop: newFilterTabsData,
              };
            });
          });
          // Do nothing
        });
    }
  }, [filterInCreateShipment, stateLocation]);

  const bindPusher = useCallback((planerData) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dot = user?.customer?.dot;
    const channelName = `private-plannerStatus-update.${dot}`;
    PusherJs.unsubscribe(channelName);
    const channel_table = PusherJs.subscribe(channelName);

    channel_table.bind('planner-status', (e) => {
      if (e?.message?.data?.id) {
        const id = e?.message?.data?.id;
        const founded = planerData.data.find((el) => Number(el?.id) === +id);
        if (founded) {
          getPlannerChild({ id })
            .then((res) => {
              if (res && res?.data) {
                setPlanerData((prev) => {
                  const updatedPlannerNewData = prev?.data?.map((data) => {
                    if (+data.id === Number(res?.data.id)) {
                      return res?.data;
                    }
                    return data;
                  });
                  return {
                    ...prev,
                    data: updatedPlannerNewData,
                  };
                });
                animationRowData(id);
              }
            })
            .catch(() => {
              // Do nothing
            });
        }
      }
    });
  }, []);

  useEffect(() => {
    getFilterByGroups();
    getFilterByEquipmentType().then();
    getFilterCustomerData();
    getFilterStopPointData();
    getShipmentSettings();
  }, []);

  useEffect(() => {
    !showModal && setTabModalEditData({});
  }, [showModal]);

  const columns = useColumns({
    columnWidths,
    animationList,
    rowReserveHandler,
    getPlanner,
    getPlanerFilterData,
    planerData,
    activeTabData,
    rowCheckBoxes,
    filter,
    tableRowCheckboxChange,
    onClickRowIcon,
    onClickTableRowsEditOption,
    sort,
    sortingQuery,
  });

  return (
    <div>
      <TableContext.Provider
        value={{
          loading,
          triggerAdvancedClear,
          filter,
          dragItem,
          customers,
          tableColumn,
          getSettings,
          updateFilter,
          createModalCLose,
          stopPoints,
          stopPointCondition,
          switchRadioButtons,
          multiSelectOptions,
          onChangeMultiselectSearchBy,
          planerData: planerData.data,
          dateRangeLocation,
          setDateRangeLocation,
          dateRangeStopPoint,
          setDateRangeStopPoint,
          onsStTableColumn: (v) => setTableColumn(v),
          onChangeOrder: (items) => setDragItem(items),
          onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
          /** Filter-helpers* */
          onChangeAll,
          onChangeCustomers,
          onChangeLocationValues,
          onChangeLocationDatePicker,
          onChangeDatePickerStopPoint,
          onChangeStopPointSelect,
          onDeleteStopPointSelect,
          dateRangeCreatedBy,
          onChangeDatePickerCreatedBy,
          onDeleteDatePickerCreatedBy,
          /** table show data* */
          filterByGroupsData,
          onDeleteManyDataItem,
          localFilterCheckboxes,
          onDeleteStopPointItem,
          onDeleteLocationsItems,
          setEquipments,
          disabledFilters,
          onApplyFilterCheckboxes,
          setLocalFilterCheckboxes,
          filterByEquipmentTypeData,
          groups,
          onReset,
          statuses,
          setGroups,
          equipments,
          showHeaderFilters,
          setStatuses,
        }}
      >
        <div>
          <PendingAssignmentFilter />

          <TableBulkActions
            title='Bulk Actions'
            typeBtn='primary'
            overlayStyle={{ width: 160 }}
            placement='bottom-start'
            styleContainer={{ marginTop: 8 }}
            showBulkActions={showBulkActions}
            setShowBulkActions={setShowBulkActions}
            options={getBulkActions()}
            conditionShow={rowCheckBoxes?.length > 0}
            onClickOption={(e, i) => onClickBulkAction(e, i, rowCheckBoxes)}
          />

          <SwitchRadio
            plus
            type='tab'
            items={filter.filterTableTop}
            onChange={onChangeTab}
            onEdit={onEditTableTab}
            name='tableTopTabMenu'
            value={activeTabData.id}
            onClickPlus={() => setShowModal(true)}
          />
          <CustomModal
            footer={false}
            filter={filter}
            showModal={showModal}
            headerTitle='Add Tab'
            styleBody={{ width: 446 }}
            updateFilter={updateFilter}
          >
            <PlanerModalContent
              filter={filter}
              customers={customers}
              typeofDataJson='planner'
              stopPointsData={stopPoints}
              updateFilter={updateFilter}
              initialValue={tabModalEditData}
              onClickButtons={onClickModalButtons}
              filterByGroupsData={filterByGroupsData}
              statusData={STATUS_TAB_ADD_MODAL_PENDING}
              filterByEquipmentTypeData={filterByEquipmentTypeData}
            />
          </CustomModal>

          {loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <div className='tableFixHead table-fixed-header-360'>
              <MaterialTable
                columns={filterTableColumn(columns)}
                data={planerData.data}
                onRowClick={onRowClick}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                }}
                options={{
                  tableLayout: 'fixed',
                  toolbar: false,
                  sorting: false,
                  pageSize: planerData.data.length,
                  rowStyle: (rowDate) => {
                    if (rowDate?.shipment_offers?.find((offer) => offer.driver_acceptance === 1)) {
                      return {
                        backgroundColor: palette.green200,
                      };
                    }

                    if ((rowDate?.is_edi_api || rowDate?.manual_edi_api) && !rowDate?.accept_date) {
                      return {
                        backgroundColor: palette.green200,
                      };
                    }
                  },
                }}
                components={{
                  Header: ({ columns }) => {
                    return (
                      <thead>
                        <tr style={{ borderTop: 'none' }}>
                          {columns.map((column) => (
                            <th
                              key={`${column.field}-${Math.random()}`}
                              style={{
                                width: column.width,
                                borderRight: column.resizable ? '1px solid #ddd' : '0px',
                                boxSizing: 'border-box',
                                padding: '4px 0 4px 12px',
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                background: '#fff',
                              }}
                            >
                              <div className='position-relative'>
                                <div
                                  className='resizable-column-header'
                                  style={{
                                    display: column.center ? 'flex' : 'block',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {column.title}
                                </div>
                                {!!column.resizable && (
                                  <div
                                    style={{
                                      cursor: 'col-resize',
                                      position: 'absolute',
                                      right: '-2px',
                                      top: 0,
                                      bottom: 0,
                                      width: 5,
                                      zIndex: 10,
                                    }}
                                    onMouseDown={handleMouseDown(column.field)}
                                  />
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                    );
                  },
                  Pagination: () => null,
                }}
              />
              <div className='d-flex justify-content-end gap-5 pt-2 pb-2 pe-5'>
                <div className='d-flex align-items-center gap-1'>
                  <Typography variant='h6'>Total Miles:</Typography>{' '}
                  <Typography variant='b2'>{formatNumber(Math.round(shipmentsTotal?.total_miles || 0), 0)}</Typography>
                </div>
                <div className='d-flex align-items-center gap-1'>
                  <Typography variant='h6'>Total Revenue:</Typography>{' '}
                  <Typography variant='b2'>
                    {currency}
                    {formatNumber(shipmentsTotal?.total_revenue)}
                  </Typography>
                </div>
              </div>
              <Pagination
                data={planerData}
                rowPerPage={rowPerPage}
                onPageChange={onPageChange}
                onChangeRowPerPage={onChangeRowPerPage}
                rowsPerPageOptions={[20, 50, 100, 250, 500]}
              />
            </div>
          )}
        </div>
        <TablePlannerRowEditChildes
          data={tablePlannerModalObj}
          onSubmit={onSubmitPlannerRowEditChildes}
          unReserveEndPointData={unReserveEndPointData}
        />
        {!!cancel?.visible && (
          <EdiApiAccept
            open={cancel?.visible}
            data={cancel || {}}
            getPlanner={() => {
              const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
              const allFilterData = { ...getPlanerFilterData, ...tabData };
              getPlanner({ allFilterData });
            }}
            onClose={() => setCancel({ visible: false })}
          />
        )}
        {!!selectedShipment && (
          <PlanShipment
            open={!!selectedShipment}
            onClose={() => setSelectedShipment(null)}
            shipment={selectedShipment}
            onNavigate={(type) => onRowClick(null, selectedShipment, true, type)}
          />
        )}
      </TableContext.Provider>
    </div>
  );
};

export default PendingAssignmentTab;
