import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right.svg';
import person from 'assets/images/person.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';
import { getSlaveShipments } from 'Api/Shipment';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { validateRequired } from 'components/AddEditModalStops/validates';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import classes from './dryRun.module.scss';

const styles = {
  styleBody: {
    maxHeight: 600,
    overflowY: 'auto',
    padding: '20px',
  },
  modal: {
    width: 440,
    top: '50%',
    left: '50%',
    boxShadow: 24,
    borderRadius: '8px',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    bgcolor: palette.white,
  },
  modalBody: {
    padding: 0,
    borderRadius: 6,
    backgroundColor: palette.white,
  },
  labelStyle: {
    margin: 0,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  inputDriver: {
    height: 32,
    width: '100%',
    borderRadius: '4px',
  },
  selectStyle: {
    height: 32,
    width: 221,
    borderRadius: '4px 0 0 4px',
    marginLeft: 36,
  },
  dropDownStyles: {
    flexGrow: 0,
    width: 221,
  },
  menuStyle: {
    width: 221,
    marginLeft: 36,
  },
};

const DryRun = ({ isOpen, setIsOpen, onAddBtn, data, planner }) => {
  const { brokerage_dispatch } = data || {};
  const { carrier } = brokerage_dispatch || {};

  const { use } = useTheme();
  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );
  const [slaves, setSlaves] = useState([]);

  const errorRequired = (error) => {
    return (
      <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
        {error}
      </Typography>
    );
  };

  const initialValue = useMemo(() => {
    return {
      amount: '',
      pay_driver: false,
      driver1_amount: '',
      driver2_amount: '',
      shipment_id: '',
    };
  }, []);

  useEffect(() => {
    const body = {
      shipment_id: data.shipment_id,
      dry_run: 1,
    };
    getSlaveShipments(body).then((res) => {
      if (res) {
        if (res.data && res.data.length) {
          const options = res.data.map((el) => {
            return {
              ...el,
              key: el.shipment_id,
              label: `${el.shipment_id} ${el.shipment_billing?.[0]?.billing_customer?.company_name}`,
              labelSelected: null,
            };
          });
          setSlaves(options);
        }
      }
    });
  }, []);

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Formik initialValues={{ ...initialValue }} enableReinitialize onSubmit={() => {}}>
        {({ values, resetForm, submitForm, validateForm, setFieldValue }) => {
          return (
            <Form>
              <Modal.Body style={styles.modalBody}>
                <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <Typography variant='button2'>Dry Run (TONU)</Typography>
                  <Typography variant='overLine'>{subTitle}</Typography>
                </div>

                <div
                  className={classes.modalBody}
                  style={{
                    backgroundColor: use(palette.gray0, palette.dark800),
                    borderColor: use(palette.gray50, palette.gray700),
                  }}
                >
                  <div className={classes.errorWrapper}>
                    <Field
                      name='amount'
                      type='number'
                      placeholder='0.00'
                      component={CustomInput}
                      label='Dry Run (TONU) Amount'
                      required
                      labelStyle={styles.labelStyle}
                      validate={validateRequired}
                      style={{ width: '100%', borderRadius: 6, height: 32 }}
                      leftIcon={<DollarIcon height={32} />}
                    />
                    <ErrorMessage
                      name='amount'
                      render={(error) => (
                        <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                          {error}
                        </Typography>
                      )}
                    />
                  </div>

                  {data.shipment_type === 'ltl' && (
                    <div className={classes.errorWrapper} style={{ marginTop: '10px' }}>
                      <Field
                        required
                        options={slaves}
                        label='Shipment ID/Customer'
                        component={CustomSelect}
                        name='shipment_id'
                        style={{ paddingLeft: 10 }}
                        styles={{ height: 32 }}
                        labelStyle={styles.labelStyle}
                        validate={validateRequired}
                      />
                      <ErrorMessage
                        name='amount'
                        render={(error) => (
                          <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>
                  )}

                  {/* // switch */}
                  {!planner && (
                    <div>
                      <div className={classes.switcherWrapper}>
                        <div>
                          <Field name='pay_driver'>
                            {({ field, form }) => (
                              <CustomCheckbox
                                name='pay_driver'
                                field={field}
                                form={form}
                                type='switch'
                                smail={false}
                                withinForm
                                checked={!!field.value}
                                style={{ marginTop: -6 }}
                                onChange={(checked) => {
                                  if (checked) {
                                    if (
                                      data.assigned_to_1?.compensation_details?.truck_order_unused_option &&
                                      data.assigned_to_1?.compensation_details?.each_truck_order_unused_amt
                                    ) {
                                      setFieldValue(
                                        'driver1_amount',
                                        data.assigned_to_1?.compensation_details?.each_truck_order_unused_amt
                                      );
                                    }
                                    if (
                                      data.assigned_to_2?.compensation_details?.truck_order_unused_option &&
                                      data.assigned_to_2?.compensation_details?.each_truck_order_unused_amt
                                    ) {
                                      setFieldValue(
                                        'driver2_amount',
                                        data.assigned_to_2?.compensation_details?.each_truck_order_unused_amt
                                      );
                                    }
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        {carrier ? (
                          <Typography variant='s2'>Pay Carrier for Dry Run</Typography>
                        ) : (
                          <Typography variant='s2'>{`Pay Driver${
                            data.assigned_to_2 ? 's' : ''
                          } for Dry Run`}</Typography>
                        )}
                      </div>

                      {values.pay_driver && (
                        <div className={classes.fieldsWrapper}>
                          {carrier ? (
                            <div className='d-flex' style={{ width: 200 }}>
                              <Typography className={classes.name} variant='s2'>
                                {carrier?.name}
                              </Typography>
                              <ArrowRightIcon className={classes.icon} />
                            </div>
                          ) : (
                            <div className='d-flex' style={{ width: 200 }}>
                              <div className={classes.imgWrapper}>
                                <img src={data?.assigned_to_1?.image || person} alt='driver' />
                                <div className={classes.statusImg} />
                              </div>
                              <Typography className={classes.name} variant='s2'>
                                {data?.assigned_to_1?.fname} {data?.assigned_to_1?.lname}
                              </Typography>
                              <ArrowRightIcon className={classes.icon} />
                            </div>
                          )}
                          <div className={classes.inputWrapper}>
                            <FastField
                              type='number'
                              placeholder='0.00'
                              name='driver1_amount'
                              component={CustomInput}
                              style={styles.inputDriver}
                              labelStyle={styles.labelStyle}
                              leftIcon={<DollarIcon height={32} />}
                            />
                            <ErrorMessage name='driver1_amount' render={errorRequired} />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {data.assigned_to_2 && !planner && (
                    <div>
                      {values.pay_driver && (
                        <div className={classes.fieldsWrapper}>
                          <div className='d-flex' style={{ width: 200 }}>
                            <div className={classes.imgWrapper}>
                              <img src={data?.assigned_to_2?.image || person} alt='driver' />
                              <div className={classes.statusImg} />
                            </div>
                            <Typography className={classes.name} variant='s2'>
                              {data?.assigned_to_2?.fname} {data?.assigned_to_2?.lname}
                            </Typography>
                            <ArrowRightIcon className={classes.icon} />
                          </div>
                          <div className={classes.inputWrapper}>
                            <FastField
                              type='number'
                              placeholder='0.00'
                              name='driver2_amount'
                              component={CustomInput}
                              style={styles.inputDriver}
                              labelStyle={styles.labelStyle}
                              leftIcon={<DollarIcon height={32} />}
                            />
                            <ErrorMessage name='driver2_amount' render={errorRequired} />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* // footer */}
                <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <div
                    className={classes.modalFooterLane}
                    style={{ backgroundColor: use(palette.white, palette.dark900) }}
                  >
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title='Add Dry Run'
                      onClick={() => onAddBtn(validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DryRun;
