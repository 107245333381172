import React from 'react';
import Radio from 'common/Radio';
import { palette } from 'utils/constants';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/cross.svg';
import ImageDropZone from 'components/ImgageDropZone';
import { downloadDirectDeposit } from 'utils/helpers';

const PaymentMethod = ({ formik, uploadDocument }) => {
  return (
    <div className='d-flex flex-column gap-2'>
      <div className='d-flex align-items-center gap-4'>
        <Radio
          label='Check'
          name='payment_method'
          value='check'
          checked={formik.values.payment_method === 'check'}
          onChange={() => formik.setFieldValue('payment_method', 'check')}
        />
        <Radio
          label='ACH'
          name='payment_method'
          value='ach'
          checked={formik.values.payment_method === 'ach'}
          onChange={() => formik.setFieldValue('payment_method', 'ach')}
        />

        <Radio
          label='3rd Party'
          name='payment_method'
          value='third_party'
          checked={formik.values.payment_method === 'third_party'}
          onChange={() => formik.setFieldValue('payment_method', 'third_party')}
        />
      </div>
      {formik.values.payment_method === 'ach' && (
        <div className='d-flex mb-2'>
          <div style={{ marginRight: '20px' }}>
            <div className='bank-details-sub mt-0'>
              <p className='bank-details-sub-heading tax-id' style={{ color: palette.gray700 }}>
                Bank Details{' '}
              </p>
            </div>
            <div className='bank-name'>
              <div className='bank-name-conatiner'>
                <p className='bank-name-style' style={{ color: palette.gray500 }}>
                  Bank Name
                </p>
              </div>
              <input
                type='text'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.gray500,
                }}
                name='bank_name'
                id='bank_name'
                onChange={formik.handleChange}
                value={formik.values.bank_name}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.bank_name && formik.errors.bank_name ? (
              <span className='error-message'>{formik.errors.bank_name}</span>
            ) : null}
            <div className='d-flex'>
              <div className='flex-col'>
                <div className='d-flex bank-routing'>
                  <div className='bank-name-conatiner'>
                    <p className='bank-name-style' style={{ color: palette.gray500 }}>
                      Routing #
                    </p>
                  </div>
                  <input
                    type='text'
                    style={{
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                      color: palette.gray500,
                    }}
                    name='bank_routing'
                    id='bank_routing'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bank_routing}
                  />
                </div>
              </div>
              <div className='re-enter-routing'>
                <input
                  type='text'
                  placeholder='Re-enter Routing #'
                  className='re-enter-routing-text'
                  name='confirm_bank_routing'
                  id='confirm_bank_routing'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_bank_routing}
                  style={{
                    backgroundColor: palette.white,
                    borderColor: palette.gray50,
                    color: palette.gray500,
                  }}
                />
              </div>
            </div>
            {(formik.touched.confirm_bank_routing && formik.errors.confirm_bank_routing) ||
            (formik.touched.bank_routing && formik.errors.bank_routing) ? (
              <span className='error-message'>{formik.errors.confirm_bank_routing || formik.errors.bank_routing}</span>
            ) : null}
            <div className='d-flex'>
              <div className='flex-col'>
                <div className='d-flex bank-routing'>
                  <div className='bank-name-conatiner'>
                    <p className='bank-name-style' style={{ color: palette.gray500 }}>
                      Account #
                    </p>
                  </div>
                  <input
                    type='number'
                    style={{
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                      color: palette.gray500,
                    }}
                    name='bank_account'
                    id='bank_account'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bank_account}
                  />
                </div>
              </div>

              <div className='re-enter-routing'>
                <input
                  type='number'
                  placeholder='Re-enter Account #'
                  className='re-enter-routing-text'
                  name='confirm_bank_account'
                  id='confirm_bank_account'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_bank_account}
                  style={{
                    backgroundColor: palette.white,
                    borderColor: palette.gray50,
                    color: palette.gray500,
                  }}
                />
              </div>
            </div>
            {(formik.touched.confirm_bank_account && formik.errors.confirm_bank_account) ||
            (formik.touched.bank_account && formik.errors.bank_account) ? (
              <span className='error-message'>{formik.errors.bank_account || formik.errors.confirm_bank_account}</span>
            ) : null}
          </div>
          <div className='upload-wrapper mt-0' style={{ position: 'relative' }}>
            <p className='text-style' style={{ color: palette.gray700 }}>
              Upload Direct Deposit Form{' '}
              <span className='download-sample' onClick={downloadDirectDeposit}>
                Download Sample
              </span>
            </p>
            {formik?.values?.bank_direct_deposit_form ? (
              <p className='attachment-wrapper' style={{ backgroundColor: '#f0f1fa' }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={() => formik.setFieldValue('bank_direct_deposit_form', null)}
                />
              </p>
            ) : (
              <div
                className='upload-document-deposit'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                }}
              >
                <label htmlFor='myFile' className='text-style upload-label'>
                  <p className='upload-document-icon'>
                    <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M3.46967 4.78033C3.17678 4.48744 3.17678 4.01256 3.46967 3.71967L6.46967 0.71967C6.76256 0.426777 7.23744 0.426777 7.53033 0.71967L10.5303 3.71967C10.8232 4.01256 10.8232 4.48744 10.5303 4.78033C10.2374 5.07322 9.76256 5.07322 9.46967 4.78033L7.75 3.06066L7.75 9.75C7.75 10.1642 7.41421 10.5 7 10.5C6.58579 10.5 6.25 10.1642 6.25 9.75L6.25 3.06066L4.53033 4.78033C4.23744 5.07322 3.76256 5.07322 3.46967 4.78033Z'
                        fill='#E5E5E5'
                      />
                      <path
                        d='M1.5 9.75C1.5 9.33579 1.16421 9 0.75 9C0.335786 9 0 9.33579 0 9.75V11.7461C0 12.7126 0.783502 13.4961 1.75 13.4961H12.25C13.2165 13.4961 14 12.7126 14 11.7461V9.75C14 9.33579 13.6642 9 13.25 9C12.8358 9 12.5 9.33579 12.5 9.75V11.7461C12.5 11.8842 12.3881 11.9961 12.25 11.9961H1.75C1.61193 11.9961 1.5 11.8842 1.5 11.7461V9.75Z'
                        fill='#E5E5E5'
                      />
                    </svg>
                  </p>
                  <p>
                    <span style={{ color: palette.indigo500 }}> Upload document</span>
                    <span style={{ color: palette.gray700 }}> or drag and drop</span>
                  </p>
                </label>
                <ImageDropZone
                  onDrop={(file) => uploadDocument(file, 'bank_direct_deposit_form')}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '120px',
                  }}
                  accept={['application/pdf', 'application/msword']}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {formik.values.payment_method === 'third_party' && (
        <div className='flex-col'>
          <div className='mb-3'>
            <p className='text-style tax-id' style={{ color: palette.gray700 }}>
              3rd Party Name
            </p>
            <div className='bank-name mt-0'>
              <input
                type='text'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.gray500,
                  borderRadius: '6px',
                }}
                name='third_party_name'
                id='third_party_name'
                onChange={formik.handleChange}
                value={formik.values.third_party_name}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.third_party_name && formik.errors.third_party_name ? (
              <span className='error-message'>{formik.errors.third_party_name}</span>
            ) : null}
          </div>
          <div>
            <p className='text-style tax-id' style={{ color: palette.gray700 }}>
              3rd Party Identifier
            </p>
            <div className='bank-name mt-0'>
              <input
                type='text'
                style={{
                  backgroundColor: palette.white,
                  borderColor: palette.gray50,
                  color: palette.gray500,
                  borderRadius: '6px',
                }}
                name='third_party_id'
                id='third_party_id'
                onChange={formik.handleChange}
                value={formik.values.third_party_id}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.third_party_id && formik.errors.third_party_id ? (
              <span className='error-message'>{formik.errors.third_party_id}</span>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
