import { array, number, object, string } from 'yup';

export const validationSchema = array().of(
  object().shape({
    paid_amount: number().required('Required').nullable(),
    reference: string().required('Required').nullable(),
    paid_by_account: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    payment_method: object()
      .shape({ value: string().required('Required') })
      .required('Required')
      .nullable(),
  })
);
