import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import Uploader from 'common/Uploader';
import DatePicker from 'common/DatePicker';
import { deleteSingleDocument } from 'Api/Carriers';
import sm from 'components/Carriers/AddCarrier/ContactBook.module.css';
import { SAddMore } from 'components/Carriers/AddCarrier/AddCarrier.styles';
import { useParams } from 'react-router-dom';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import SplashScreen from '../../components/splashScreen/splashScreen';

const Documents = ({ form, company, loading }) => {
  const { use } = useTheme();
  const { dot, token } = useParams();
  const { values, handleChange, touchedErrors, setValues } = form;
  const {
    liability_minimum_coverage,
    cargo_insurance_minimum_coverage,
    general_liability_minimum_coverage,
    company_name,
    address1,
    address2,
    city,
    state,
    zipcode,
  } = company || {};
  const steps = ['Uploading Documents…', 'Transmitting...', 'Almost there...', 'Wrapping up...'];

  const removeRow = async (item, index) => {
    const newDocuments = values.documents.filter((_, idx) => idx !== index);
    setValues({ ...values, documents: newDocuments });

    if (!item.isNew) {
      await deleteSingleDocument(dot, token, item.id);
    }
  };

  const addRow = () => {
    const newDocuments = [
      ...values.documents,
      {
        name: '',
        expiration_date: '',
        file: '',
        no_expiration: false,
        isNew: true,
      },
    ];
    form.setValues({ ...values, documents: newDocuments });
  };

  return (
    <div className={sm.container_wrapper}>
      <div className={sm.container} style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}>
        <div className={sm.body} style={loading ? { opacity: 0.6 } : {}}>
          <div>
            <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
              W9 <span className='required'>*</span>
            </Typography>
            <Uploader
              type={3}
              uploaderLabel='Attach '
              document={values.w9}
              onDrop={(files) => handleChange('w9', files[0])}
              onRemove={() => handleChange('w9', null)}
              error={touchedErrors.w9}
              accept={['application/pdf']}
            />
          </div>

          <div className='mt-4'>
            <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
              Authority <span className='required'>*</span>
            </Typography>
            <Uploader
              type={3}
              uploaderLabel='Attach '
              document={values.authority}
              onDrop={(files) => handleChange('authority', files[0])}
              onRemove={() => handleChange('authority', null)}
              error={touchedErrors.authority}
              accept={['application/pdf']}
            />
          </div>
          <div className='mt-4'>
            <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
              Reference Sheet <span className='required'>*</span>
            </Typography>
            <Uploader
              type={3}
              uploaderLabel='Attach '
              document={values.reference_sheet}
              onDrop={(files) => handleChange('reference_sheet', files[0])}
              onRemove={() => handleChange('reference_sheet', null)}
              error={touchedErrors.reference_sheet}
              accept={['application/pdf']}
            />
          </div>

          <div
            style={{
              boxShadow: palette.boxShadow2,
              borderRadius: 6,
              marginTop: 10,
              marginBottom: 10,
              background: '#fff',
            }}
          >
            <div className='ms-3 mb-3 mt-4'>
              <Typography variant='title1'>Insurance</Typography>
            </div>
            <div className='d-flex mb-4   ms-3'>
              <div>
                <InputLabel required style={{ fontWeight: 600, color: use(palette.gray700, palette.gray200) }}>
                  Auto Liability Insurance
                </InputLabel>
                <div className='d-flex gap-3 align-items-center'>
                  <div>
                    <DatePicker
                      name='liability_insurance_exp_date'
                      value={values.liability_insurance_exp_date}
                      onChange={(val) => handleChange('liability_insurance_exp_date', val)}
                      minDate={new Date()}
                      dateFormat='MM/dd/yyyy'
                      renderStart={false}
                      allowUserInput
                      placeholderText='Expiry...'
                      style={{ width: 140 }}
                    />
                    <ErrorMessage error={touchedErrors.liability_insurance_exp_date} />
                  </div>
                  <div className='w-100'>
                    <Uploader
                      type={3}
                      document={values.liability_insurance}
                      onDrop={(files) => handleChange('liability_insurance', files[0])}
                      onRemove={() => handleChange('liability_insurance', null)}
                      error={touchedErrors.liability_insurance}
                      accept={['application/pdf']}
                    />
                  </div>
                </div>
                <div className='ms-2 mt-2'>
                  <InfoIcon fill='#AA5B00FF' />
                  <Typography
                    variant='b3'
                    style={{
                      color: '#AA5B00FF',
                      marginLeft: 6,
                    }}
                  >
                    Auto Liability of ${formatNumber(liability_minimum_coverage)} minimum coverage
                  </Typography>
                </div>
              </div>
            </div>

            {loading && (
              <div style={{ height: 0 }}>
                <SplashScreen steps={steps} />
              </div>
            )}

            <div className='d-flex mb-4 mt-4 ms-3'>
              <div>
                <InputLabel required style={{ fontWeight: 600, color: use(palette.gray700, palette.gray200) }}>
                  Cargo Insurance
                </InputLabel>
                <div className='d-flex gap-3 align-items-center'>
                  <div>
                    <DatePicker
                      name='cargo_insurance_exp_date'
                      value={values.cargo_insurance_exp_date}
                      onChange={(val) => handleChange('cargo_insurance_exp_date', val)}
                      minDate={new Date()}
                      dateFormat='MM/dd/yyyy'
                      renderStart={false}
                      allowUserInput
                      placeholderText='Expiry...'
                      style={{ width: 140 }}
                    />
                    <ErrorMessage error={touchedErrors.cargo_insurance_exp_date} />
                  </div>
                  <div className='w-100'>
                    <Uploader
                      type={3}
                      document={values.cargo_insurance}
                      onDrop={(files) => handleChange('cargo_insurance', files[0])}
                      onRemove={() => handleChange('cargo_insurance', null)}
                      error={touchedErrors.cargo_insurance}
                      accept={['application/pdf']}
                    />
                  </div>
                </div>
                <div className='ms-2 mt-2'>
                  <InfoIcon fill='#AA5B00FF' />
                  <Typography
                    variant='b3'
                    style={{
                      color: '#AA5B00FF',
                      marginLeft: 6,
                    }}
                  >
                    Motor Truck Cargo of ${formatNumber(cargo_insurance_minimum_coverage)} minimum coverage
                  </Typography>
                </div>
              </div>
            </div>

            <div className='d-flex mb-4 mt-4 ms-3'>
              <div>
                <InputLabel required style={{ fontWeight: 600, color: use(palette.gray700, palette.gray200) }}>
                  General Liability
                </InputLabel>
                <div className='d-flex gap-3 align-items-center'>
                  <div>
                    <DatePicker
                      name='general_liability_exp_date'
                      value={values.general_liability_exp_date}
                      onChange={(val) => handleChange('general_liability_exp_date', val)}
                      minDate={new Date()}
                      allowUserInput
                      dateFormat='MM/dd/yyyy'
                      renderStart={false}
                      placeholderText='Expiry...'
                      style={{ width: 140 }}
                    />
                    <ErrorMessage error={touchedErrors.general_liability_exp_date} />
                  </div>
                  <div className='w-100'>
                    <Uploader
                      type={3}
                      document={values.general_liability}
                      onDrop={(files) => handleChange('general_liability', files[0])}
                      onRemove={() => handleChange('general_liability', null)}
                      error={touchedErrors.general_liability}
                      accept={['application/pdf']}
                    />
                  </div>
                </div>
                <div className='ms-2 mt-2'>
                  <InfoIcon fill='#AA5B00FF' />
                  <Typography
                    variant='b3'
                    style={{
                      color: '#AA5B00FF',
                      marginLeft: 6,
                    }}
                  >
                    General Liability Coverage of ${formatNumber(general_liability_minimum_coverage)} minimum coverage
                  </Typography>
                </div>
              </div>
            </div>

            <div className='ms-3 mb-3'>
              <Typography variant='title1'>Notice</Typography>
            </div>
            <div className='ms-3 mb-3'>
              <Typography variant='title2'>
                Certificate must have current issue date, current effective-expire policy dates, along with policy
                numbers, a notice of cancellation no more than 30 days and the COl must be signed by an authorized
                representative.
              </Typography>

              <div className='mt-3'>
                <Typography variant='title2'>Certificate holder or additional insured should be listed as:</Typography>
              </div>

              <div className='mt-3'>
                <Typography variant='title2'>{company_name}</Typography>
              </div>
              <div>
                <Typography variant='title2'>
                  {address1}
                  {address2 && <Typography variant='title2'>, {address2}</Typography>}
                </Typography>
              </div>
              <div>
                <Typography variant='title2'>
                  {city?.name}, {state?.short_name} {zipcode}
                </Typography>
              </div>
            </div>
          </div>
          <div className='d-flex  mt-4 '>
            <div>
              <InputLabel>Custom Document(s)</InputLabel>
              {values.documents.map((row, index) => (
                <div className='d-flex gap-3 mb-2 align-items-center'>
                  <div className='d-flex'>
                    <Input
                      name={`documents[${index}].name`}
                      onChange={(e) => handleChange(`documents[${index}].name`, e.target.value)}
                      style={{
                        width: '340px',
                        marginTop: 5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        height: 32,
                      }}
                      placeholder='Document Name'
                      value={row.name}
                      error={touchedErrors.documents?.[index]?.name}
                    />
                    <div>
                      <DatePicker
                        name={`documents[${index}].expiration_date`}
                        value={row.no_expiration ? '' : row.expiration_date}
                        style={{
                          marginTop: 5,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          boxShadow: palette.boxShadow2,
                          width: 140,
                        }}
                        onChange={(val) => handleChange(`documents[${index}].expiration_date`, val)}
                        minDate={new Date()}
                        dateFormat='MM/dd/yyyy'
                        placeholderText='Expiry...'
                        placeholder='Expiry...'
                        renderStart={false}
                        disabled={row.no_expiration}
                      />
                      <ErrorMessage error={touchedErrors.documents?.[index]?.expiration_date} />
                      <CustomCheckbox
                        name={`documents[${index}].no_expiration`}
                        checked={!!values?.documents[index]?.no_expiration}
                        onChange={(checked) => handleChange(`documents[${index}].no_expiration`, checked)}
                      >
                        <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                          No Expiration
                        </Typography>
                      </CustomCheckbox>
                    </div>
                  </div>
                  <div className='w-100'>
                    <Uploader
                      type={3}
                      document={row.file}
                      onDrop={(files) => handleChange(`documents[${index}].file`, files[0])}
                      onRemove={() => handleChange(`documents[${index}].file`, null)}
                      error={touchedErrors.documents?.[index]?.file}
                      accept={['application/pdf']}
                    />
                  </div>
                  <DeleteIcon fill={palette.red500} onClick={() => removeRow(row, index)} />
                </div>
              ))}
              <SAddMore onClick={addRow}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  Add Another
                </Typography>
              </SAddMore>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
