import React, { useMemo, useState } from 'react';
import Autocomplete from 'common/Autocomplete';
import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';

const VendorAutocomplete = ({ value, options, onChange, onAddNewSuccess, error, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const [addVendorOpen, setAddVendorOpen] = useState(false);

  const updatedOptions = useMemo(() => {
    if (inputValue && options.every((i) => i.name !== inputValue)) {
      return [{ name: `+ Add "${inputValue}"`, id: 'add-new', type: '' }, ...options];
    }
    return options;
  }, [inputValue, options]);

  const handleChange = (e, val) => {
    if (val?.id === 'add-new') {
      setAddVendorOpen(inputValue);
      return;
    }

    onChange(e, val);
  };

  return (
    <>
      <Autocomplete
        width='150px'
        size='small'
        name='vendor'
        placeholder='Select Vendor'
        options={updatedOptions}
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(e, val) => setInputValue(val)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        error={error}
        {...props}
      />
      {!!addVendorOpen && (
        <AddPayee
          open={!!addVendorOpen}
          name={addVendorOpen}
          type='vendor'
          onClose={() => setAddVendorOpen(false)}
          onSuccess={onAddNewSuccess}
        />
      )}
    </>
  );
};

export default VendorAutocomplete;
