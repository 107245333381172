import React from 'react';
import { Typography } from 'components/Typography';
import { SCustomModal } from '../../Settlements.styles';

const ActionsDone = ({ open, onClose }) => {
  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      $maxWidth='350px'
      $height='auto'
      headerTitle='Unresolved Disputes'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Go Back',
          onClick: onClose,
        },
      ]}
    >
      <Typography variant='s2'>You still have unresolved disputes. Please resolve them before processing!</Typography>
    </SCustomModal>
  );
};

export default ActionsDone;
