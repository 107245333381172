import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import { palette } from 'utils/constants';

export const SFeaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 18px;
  padding: 0 12px;
  margin: 18px 0 0;
`;

export const SServicesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  padding: 0 12px;
  margin: 18px 0;
`;

export const SAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;
    position: static;
    background: ${palette.gray0};

    &.Mui-expanded {
      margin: 0;
    }

    ::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      padding: 0;
      min-height: unset;
      align-items: center;
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
`;
