import styled from 'styled-components';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;

  .factoring-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row {
    display: flex;
    padding: 16px 8px;
    border-bottom: 1px solid #e9edf5;

    &.top-border {
      border-top: 1px solid #e9edf5;
    }

    .noa-wrapper {
      flex-grow: 1;
    }
  }

  .left {
    width: 270px;
  }

  .right {
    flex-grow: 1;
    max-width: 500px;
  }

  .file-upload-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 24px;
    column-gap: 16px;
  }

  .choose-file-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 24px;
    border-radius: 6px;
    padding: 0 4px;
    background-color: var(--indigo-0);
    transition: 0.2s all ease-in-out;
    
    :hover {
      cursor: pointer;
      background-color: var(--indigo-100);
    }
  }
  }

.underline-text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--indigo-500);
  padding-left: 8px;
  white-space: nowrap;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

  .upload-delete-wrapper {
    display: flex;
    align-items: center;
    margin-top: 4px;
`;

export const SAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const SFlexRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: flex-end;
`;
