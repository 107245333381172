import React, { Fragment, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/createShipment/subRight.svg';
import Chip from 'common/Chip';
import NoRecords from 'common/NoRecords';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import useDateFormat from 'hooks/useDateFormat';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import DriverNotes from 'componentsV2/Driver/DriverNotes';
import CustomButton from 'components/CustomButton/CustomButton';
import UpdateNextAvailable from 'componentsV2/Driver/UpdateNextAvailable';
import { getStatusParamsInStatus } from 'components/TableShipments/helpers/constants';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { absencseStatusColor, CURRENCY, formatNumber, palette, TRUCK_TYPES } from 'utils/constants';
import { filterDrivers } from 'pages/Planner/Availability/helpers';
import { SInnerTable, STable, STableRow } from 'pages/Planner/Availability/Availability.styles';

const AvailabilityTable = ({ drivers, setDrivers, loading, filters, getAvailabilities }) => {
  const [driverToUpdate, setDriverToUpdate] = useState(null);
  const [driverToAddNotes, setDriverToAddNotes] = useState(null);
  const [sort, setSort] = useState({ field: 'distance', sortBy: 'asc' });
  const navigate = useNavigate();
  const { convertToCustomerTime, formatDate, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const filteredDrivers = useMemo(() => {
    return filterDrivers(drivers, { sort });
  }, [drivers, sort]);

  const onRowClick = (index) => {
    setDrivers((prevState) => prevState.map((item, i) => (i === index ? { ...item, expanded: !item.expanded } : item)));
  };

  const onItemClick = (e, id) => {
    if (!id) {
      return;
    }

    e.stopPropagation();
    navigate(`/shipment/${id}`);
  };

  const onUpdateNextAvailableSuccess = () => {
    getAvailabilities();
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort(
      field === sort.field && direction === 'desc' ? { field: 'distance', sortBy: 'asc' } : { field, sortBy: direction }
    );
  };

  return (
    <div className='overflow-auto'>
      {loading ? (
        <TableSkeleton loading={loading} />
      ) : (
        <STable>
          <thead>
            <tr className='nowrap'>
              <th>NAME</th>
              <th>BOOKED (FROM/TO)</th>
              <th>TOTAL MILES</th>
              <th>
                <ColumnHeader title='GROSS REVENUE' field='driver_gross_revenue' sort={sort} onClick={sortingQuery} />
              </th>
              <th>RPM</th>
              <th>NEXT AVAILABLE DATE</th>
              <th>NEXT AVAILABLE LOCATION</th>
              {filters.miles && <th />}
            </tr>
          </thead>
          <tbody>
            {filteredDrivers.map((driver, index) => (
              <Fragment key={driver.id}>
                <STableRow onClick={() => onRowClick(index)}>
                  <td>
                    <div className='d-flex align-items-center gap-2'>
                      <UserInfo
                        size='32px'
                        statusSize='7px'
                        data={{
                          ...driver,
                          name: `${driver.fname} ${driver.lname}`,
                          image: driver.image,
                          status: driver.status_data?.driver_status,
                          user_type: 'driver',
                        }}
                      />
                      {!!driver.equipment?.[0]?.custom_next_available && (
                        <NoteIcon
                          width={14}
                          height={14}
                          className='ms-1'
                          onClick={(e) => {
                            e.stopPropagation();
                            setDriverToAddNotes(driver);
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center gap-1'>
                      {!!driver.booked_from && <Chip label={formatDateTime(driver.booked_from)} />}
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        -
                      </Typography>
                      {!!driver.booked_to && (
                        <Chip
                          label={
                            moment(driver.equipment?.[0]?.next_available_date).isAfter(driver.booked_to)
                              ? convertToCustomerTime(driver.equipment?.[0]?.next_available_date)
                              : formatDateTime(driver.booked_to)
                          }
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {Math.round(Number(driver.total_miles) || 0)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {currency}
                      {formatNumber(driver.driver_gross_revenue)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }} className='nowrap'>
                      {currency}
                      {formatNumber(
                        (Number(driver.driver_gross_revenue) || 0) / (Number(driver.total_miles) || 0)
                      )}{' '}
                      /mile
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {driver.equipment?.[0]?.next_available_date
                        ? convertToCustomerTime(driver.equipment?.[0]?.next_available_date)
                        : '-'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {driver.equipment?.[0]?.next_available_location || '-'}
                    </Typography>
                  </td>
                  {((filters.state && filters.city) || filters.zipcode) && (
                    <td>
                      {driver.distance && (
                        <Chip label={`${Math.round(driver.distance)} Miles Away`} labelColor={palette.indigo500} />
                      )}
                    </td>
                  )}
                  <td>
                    <div className='d-flex align-items-center gap-1'>
                      {!!driver.notes?.length && (
                        <CustomButton
                          type='primary'
                          styleTitle={{ fontSize: 14, whiteSpace: 'nowrap' }}
                          styleButton={{ padding: '2px 12px', margin: 0 }}
                          title='View Notes'
                          onClick={(e) => {
                            e.stopPropagation();
                            setDriverToAddNotes(driver);
                          }}
                        />
                      )}
                      <CustomButton
                        type='primary'
                        styleTitle={{ fontSize: 14 }}
                        styleButton={{ padding: '2px 12px', margin: 0 }}
                        title='Update'
                        onClick={(e) => {
                          e.stopPropagation();
                          setDriverToUpdate(driver);
                        }}
                      />
                    </div>
                  </td>
                </STableRow>
                {!!driver.expanded && (
                  <tr>
                    <td colSpan='7' className='p-0 pb-5'>
                      <SInnerTable>
                        <thead>
                          <tr className='nowrap'>
                            <th>EQUIPMENT</th>
                            <th>STATUS</th>
                            <th>LOADED</th>
                            <th>EMPTY</th>
                            <th>GROSS REVENUE</th>
                            <th>RPM</th>
                            <th>FROM</th>
                            <th>TO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(driver.shipmentsAndAbsences || []).map((shipment) => {
                            const absence = shipment.entryType === 'absence' ? shipment : null;

                            const { loaded_miles, empty_miles, shipment_billing, status } = shipment || {};
                            const paramsInStatus = !absence && getStatusParamsInStatus(status);
                            const { bgStatus, colorStatus, statusName } = paramsInStatus || {};
                            const shipmentCurrency =
                              !absence &&
                              Object.values(CURRENCY).find(
                                (item) => item.id === Number(shipment_billing[0]?.currency_id)
                              );
                            const totalMiles = !absence && Math.round(loaded_miles) + Math.round(empty_miles);
                            const totalCharges =
                              !absence &&
                              shipment_billing.reduce((acc, cur) => acc + (Number(cur.total_amount) || 0), 0);
                            const rpm = totalMiles === 0 ? 0 : totalCharges / totalMiles;

                            const originCity = !absence && shipment.shipment_stops[0]?.stop_point?.city?.name;
                            const originState = !absence && shipment.shipment_stops[0]?.stop_point?.state?.short_name;
                            const originZipcode = !absence && shipment.shipment_stops[0]?.stop_point?.zipcode;

                            const originScheduledType = !absence && shipment.shipment_stops[0]?.scheduled_type;
                            const scheduledOrigin = !absence && shipment.shipment_stops[0].scheduled_date;
                            const scheduledOriginTo =
                              !absence &&
                              (shipment.shipment_stops[0].scheduled_date_to ||
                                shipment.shipment_stops[0].scheduled_date);
                            const originFromDate = !absence && shipment.shipment_stops[0]?.from;
                            const originToDate = !absence && shipment.shipment_stops[0]?.to;
                            const originFrom = !absence && `${scheduledOrigin} ${originFromDate || ''}`;
                            const originTo = !absence && `${scheduledOriginTo} ${originToDate || ''}`;

                            const destinationCity =
                              !absence &&
                              shipment.shipment_stops[shipment.shipment_stops.length - 1]?.stop_point?.city?.name;
                            const destinationState =
                              !absence &&
                              shipment.shipment_stops[shipment.shipment_stops.length - 1]?.stop_point?.state
                                ?.short_name;
                            const destinationZipcode =
                              !absence &&
                              shipment.shipment_stops[shipment.shipment_stops.length - 1]?.stop_point?.zipcode;

                            const destinationScheduledType =
                              !absence && shipment.shipment_stops?.[shipment.shipment_stops.length - 1]?.scheduled_type;
                            const scheduledDestination =
                              !absence && shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date;
                            const scheduledDestinationTo =
                              !absence &&
                              (shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date_to ||
                                shipment.shipment_stops[shipment.shipment_stops.length - 1].scheduled_date);
                            const destinationFromDate =
                              !absence && shipment.shipment_stops[shipment.shipment_stops.length - 1]?.from;
                            const destinationToDate =
                              !absence && shipment.shipment_stops[shipment.shipment_stops.length - 1]?.to;

                            const destinationFrom = !absence && `${scheduledDestination} ${destinationFromDate || ''}`;
                            const destinationTo = !absence && `${scheduledDestinationTo} ${destinationToDate || ''}`;

                            const { equipment_id, equipment_type, hooked_to, truck_type, id } =
                              shipment?.equipment || {};

                            return (
                              <tr key={shipment.id} onClick={(e) => onItemClick(e, shipment.shipment_id)}>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <>
                                      <Typography
                                        variant='b2'
                                        style={{ color: palette.gray700 }}
                                        className='pointer'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(`/equipment-profile/vehicle/${id}`);
                                        }}
                                      >
                                        {equipment_id || 'TBD'} {equipment_type?.title}
                                        {!!truck_type && ` (${TRUCK_TYPES[truck_type]})`}
                                      </Typography>
                                      {!!hooked_to && (
                                        <div
                                          className='d-flex align-items-baseline pointer'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/equipment-profile/trailer/${hooked_to.id}`);
                                          }}
                                        >
                                          <TwoWaysIcon className='ms-1 me-1' />
                                          <Typography variant='b2' style={{ color: palette.gray700 }}>
                                            {hooked_to?.equipment_id || 'TBD'} {hooked_to?.length?.length}&nbsp;
                                            {hooked_to?.length?.unit} {hooked_to?.equipment_type?.title}
                                          </Typography>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      -
                                    </Typography>
                                  )}
                                </td>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <>
                                      <Chip
                                        label={statusName?.toUpperCase()}
                                        labelColor={colorStatus}
                                        bgColor={bgStatus}
                                        fontSize='11px'
                                        fontWeight={500}
                                      />
                                      <br />
                                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                                        ({shipment.shipment_id})
                                      </Typography>
                                    </>
                                  ) : (
                                    <Chip
                                      label={absence?.absence_type?.name?.toUpperCase() || absence?.type?.toUpperCase()}
                                      labelColor={absencseStatusColor[absence?.type]?.color}
                                      bgColor={absencseStatusColor[absence?.type]?.bgColor}
                                      fontSize='11px'
                                      fontWeight={500}
                                    />
                                  )}
                                </td>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      {Math.round(Number(shipment.loaded_miles) || 0)}
                                    </Typography>
                                  ) : (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      -
                                    </Typography>
                                  )}
                                </td>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      {Math.round(Number(shipment.empty_miles) || 0)}
                                    </Typography>
                                  ) : (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      -
                                    </Typography>
                                  )}
                                </td>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      {shipmentCurrency?.symbol || currency}
                                      {formatNumber(totalCharges)}
                                    </Typography>
                                  ) : (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      -
                                    </Typography>
                                  )}
                                </td>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <Typography variant='b2' style={{ color: palette.gray700 }} className='nowrap'>
                                      {shipmentCurrency?.symbol || currency}
                                      {formatNumber(rpm)} /mile
                                    </Typography>
                                  ) : (
                                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                                      -
                                    </Typography>
                                  )}
                                </td>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <>
                                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                                        {originCity && `${originCity},`} {originState}
                                        {originZipcode && `, ${originZipcode}`}
                                      </Typography>
                                      <br />
                                      <Chip
                                        label={`${formatDateTime(`${originFrom}`)}${
                                          !!originToDate && [1, 3].includes(Number(originScheduledType))
                                            ? ` to ${formatDateTime(originTo)}`
                                            : ''
                                        }${
                                          originScheduledType
                                            ? ` (${SCHEDULED_DATE_TYPE_OBJ[originScheduledType].shortName})`
                                            : ''
                                        }`}
                                      />
                                    </>
                                  ) : absence.start_date ? (
                                    <Chip label={formatDate(absence.start_date)} />
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td className='pointer'>
                                  {shipment.entryType === 'shipment' ? (
                                    <>
                                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                                        {destinationCity && `${destinationCity},`} {destinationState}
                                        {destinationZipcode && `, ${destinationZipcode}`}
                                      </Typography>
                                      <br />
                                      <Chip
                                        label={`${formatDateTime(`${destinationFrom}`)}${
                                          !!destinationToDate && [1, 3].includes(Number(destinationScheduledType))
                                            ? ` to ${formatDateTime(destinationTo)}`
                                            : ''
                                        }${
                                          destinationScheduledType
                                            ? ` (${SCHEDULED_DATE_TYPE_OBJ[destinationScheduledType].shortName})`
                                            : ''
                                        }`}
                                      />
                                    </>
                                  ) : absence.end_date ? (
                                    <Chip label={formatDate(absence.end_date)} />
                                  ) : (
                                    '-'
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </SInnerTable>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </STable>
      )}
      {!loading && !drivers?.length && <NoRecords />}
      {!!driverToUpdate && (
        <UpdateNextAvailable
          open={!!driverToUpdate}
          onClose={() => setDriverToUpdate(null)}
          driver={driverToUpdate}
          onSuccess={onUpdateNextAvailableSuccess}
        />
      )}
      {!!driverToAddNotes && (
        <DriverNotes open={!!driverToAddNotes} onClose={() => setDriverToAddNotes(null)} driver={driverToAddNotes} />
      )}
    </div>
  );
};

export default AvailabilityTable;
