import React from 'react';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import { formatNumber } from 'utils/constants';

const PayTypeChip = ({ data }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <div className='pay-type-chip-wrapper'>
      {data.pay_option === 'mile' && (
        <>
          <Chip label={`${currency}${data.mile_loaded_amt} Loaded`} />
          -
          <Chip label={`${currency}${data.mile_empty_amt} Empty`} />
        </>
      )}
      {data.pay_option === 'percentage' && <Chip label={`${data.shipment_percentage}% of shipment`} />}
      {data.pay_option === 'per_hour' && <Chip label={`${currency}${formatNumber(data.per_hour_worked)}`} />}
      {data.pay_option === 'flat_rate' && <Chip label='Flat Per Shipment' />}
      {!!data.per_hr_option && <Chip label={`${currency}${formatNumber(data.per_hr_amt)} Per Hour`} />}
      {!!data.each_booked_shipment && (
        <Chip
          label={
            data.flat_or_per === 1
              ? `${currency}${formatNumber(data.flat_amt)} per shipment`
              : `${data.percentage}% of shipment`
          }
        />
      )}
      {!!data.sales_commission_option && <Chip label='Sales Agent Commission' />}
    </div>
  );
};

export default PayTypeChip;
