import { Overlay } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';

const EditCarrierPaysDot = ({ rowData, onEditRow }) => {
  const target = useRef(null);
  const [isOpenItemEdit, setIsOpenItemEdit] = useState(false);

  function onEdit() {
    onEditRow(rowData);
    setIsOpenItemEdit(false);
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <div
          ref={target}
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <EditIcon style={{ transform: 'rotate(90deg)' }} />
        </div>
      </div>
      <Overlay
        placement='bottom'
        target={target.current}
        show={isOpenItemEdit}
        onHide={() => setIsOpenItemEdit(false)}
        rootCloseEvent='click'
        rootClose
      />
    </>
  );
};

export default EditCarrierPaysDot;
