import React, { useMemo, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

const OverLayTriggerHoverWrapper = ({ overlay: OverLay, placement, delay, overLayContainerStyle, children }) => {
  const { use } = useTheme();
  const [show, setShow] = useState(false);

  const toolTip = ({ placement, arrowProps, show: _show, popper, ...props }) => {
    return (
      <div
        {...props}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          zIndex: 9,
          ...defaultStyle.tooltipContainer,
          ...overLayContainerStyle,
          ...props.style,
        }}
      >
        {typeof OverLay === 'function' ? <OverLay /> : OverLay}
      </div>
    );
  };

  const defaultStyle = useMemo(() => {
    return {
      tooltipContainer: {
        border: `1px solid ${use(palette.gray100, palette.gray400)}`,
        borderRadius: 4,
        padding: 4,
        cursor: 'default',
      },
    };
  }, [use, palette]);

  return (
    <div onMouseLeave={() => setShow(false)}>
      <OverlayTrigger
        placement={placement || 'bottom'}
        trigger={['hover', 'focus']}
        delay={delay || { show: 100, hide: 100 }}
        overlay={toolTip}
        show={show}
        onToggle={(visible) => !!visible && setShow(true)}
      >
        {children}
      </OverlayTrigger>
    </div>
  );
};

export default OverLayTriggerHoverWrapper;
