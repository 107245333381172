import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as GlobeIcon } from 'assets/icons/globePDF.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationPDF.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SCompanyInfo } from '../PublicQuote.styles';

const CompanyInfo = ({ company }) => {
  const { address1, city, state, zipcode, phone_number, email, company_website } = company || {};

  return (
    <SCompanyInfo>
      <div className='address-field-row'>
        <div className='address-field-title'>
          <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-2'>
            <MailIcon fill={palette.gray500} width={18} height={18} /> Email
          </Typography>
        </div>
        <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
          <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
            {email}
          </Typography>
        </a>
      </div>
      <div className='address-field-row'>
        <div className='address-field-title'>
          <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
            <PhoneIcon width={25} height={25} fill={palette.gray500} /> Phone
          </Typography>
        </div>
        <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
          <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
            {formatPhoneNumberIntl(phone_number)}
          </Typography>
        </a>
      </div>
      <div className='address-field-row'>
        <div className='address-field-title'>
          <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
            <LocationIcon width={22} height={25} fill={palette.gray500} /> Address
          </Typography>
        </div>
        <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
          <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
            {address1}
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
            {city?.name}, {state?.short_name || state?.name} {zipcode}
          </Typography>
        </a>
      </div>
      {company_website && (
        <div className='address-field-row'>
          <div className='address-field-title'>
            <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
              <GlobeIcon width={20} height={20} fill={palette.gray500} /> Online
            </Typography>
          </div>
          <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
            <Typography variant='b2' style={{ color: palette.gray500 }} className='d-flex align-items-center gap-1'>
              {company_website}
            </Typography>
          </a>
        </div>
      )}
    </SCompanyInfo>
  );
};

export default CompanyInfo;
