import React, { Fragment } from 'react';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SOtherQuestion } from '../ViewApplicantModal.styles';

const OtherQuestions = ({ data }) => {
  const { use } = useTheme();

  if (!data?.length) {
    return null;
  }

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray200) }}>
        Other
      </Typography>
      <Divider />
      {data.map((item) => (
        <Fragment key={item.id}>
          <SOtherQuestion>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), marginBottom: '8px' }}>
              {item.question.question}
            </Typography>
            <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200), wordWrap: 'break-word' }}>
              {item.answer}
            </Typography>
          </SOtherQuestion>
          <Divider margin='8px 0' />
        </Fragment>
      ))}
    </div>
  );
};

export default OtherQuestions;
