import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray } from 'formik';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useIsMounted from 'hooks/useIsMounted';
import { useTheme } from 'context/themeContext';
import { CURRENCY, numberWithCommas, palette } from 'utils/constants';
import ItemModal from 'pages/CompanySettings/pagesComponents/ShipmentTypes/ItemModal';
import { getGrandTotal, getShipmentChargeType } from 'Api/Planner';
import BillingCharge from 'components/CreateShipment/BillingCharges/BillingCharge';
import { BILLING_GRAND_TOTAL_CURRENCY, getChangesTemplate } from '../ShipmentStops/helpers/constants';
import { BillingChargesContext } from './index';
import PopoverBillingCharges from './PopoverBillingCharges';
import sm from './BillingCharges.module.css';
import { ShipmentContext } from '../Layout';

const BillingChargesBody = () => {
  const { use } = useTheme();
  const isMounted = useIsMounted();

  const [, setDisabledDelete] = useState(true);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [chargeErrors, setChargeErrors] = useState({});

  // shipment-context
  const {
    selectedCharges,
    updateSelectedCharges,
    selectedGrandTotal,
    updateSelectedGrandTotal,
    updateShipmentChargeType,
    createShipmentData,
    updateShipmentData,
  } = useContext(ShipmentContext);

  // billing-context
  const { values, touched, setTouched, setFieldValue, onChangeDisabledNextStep, validateForm } =
    useContext(BillingChargesContext);
  const SELECTED_CHARGES = selectedCharges[0];
  const selectedGrandTotalForm = selectedGrandTotal[0];
  const currencyData = Object.values(CURRENCY).find((item) => item.id === Number(selectedGrandTotalForm?.id));

  const getChargeType = () => {
    return getShipmentChargeType().then((res) => {
      if (res && res?.data) {
        const newData = res.data.map((item) => {
          return {
            ...item,
            label: item?.title,
            key: item.id,
            labelSelected: null,
          };
        });
        updateShipmentChargeType(newData);
        return newData;
      }
      return res;
    });
  };

  const getGrandTotalDefault = () => {
    getGrandTotal()
      .then((res) => {
        if (res && res?.data) {
          switch (res.data?.default_currency) {
            case 'usd':
              if (isMounted()) updateSelectedGrandTotal({ 0: BILLING_GRAND_TOTAL_CURRENCY[0] });
              break;
            case 'cad':
              if (isMounted()) updateSelectedGrandTotal({ 0: BILLING_GRAND_TOTAL_CURRENCY[1] });
              break;
            case 'mxn':
              if (isMounted()) updateSelectedGrandTotal({ 0: BILLING_GRAND_TOTAL_CURRENCY[2] });
              break;
            default:
          }
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onSaveCharge = (charge, index) => {
    if (!charge.charges) {
      setChargeErrors((prevState) => ({ ...prevState, [index]: true }));
      return;
    }
    validateForm().then((errors) => {
      if (!!errors?.billingCharges && !!errors?.billingCharges?.[index]) {
        const array = touched?.billingCharges ? [...touched.billingCharges] : [];
        array[index] = {
          qty: true,
          rate: true,
          subTotal: true,
        };
        setTouched({
          billingCharges: array,
        });
      } else {
        if (values?.billingCharges?.length === Number(SELECTED_CHARGES?.length) + 1) {
          onChangeDisabledNextStep(false);
        }
        const chargeSave = SELECTED_CHARGES
          ? [
              ...SELECTED_CHARGES,
              {
                ...charge,
                charges: typeof charge.charges === 'object' ? charge.charges?.id : charge.charges,
                rate: charge?.charges?.type?.account_type === 'Expense' ? `-${charge.rate}` : charge.rate,
              },
            ]
          : [
              {
                ...charge,
                charges: typeof charge.charges === 'object' ? charge.charges?.id : charge.charges,
                rate: charge?.charges?.type?.account_type === 'Expense' ? `-${charge.rate}` : charge.rate,
              },
            ];
        updateSelectedCharges({ 0: chargeSave });
        updateShipmentData({ ...createShipmentData, billingCharges: values.billingCharges });
      }
    });
  };

  const onEditCharge = (charge) => {
    const filteredData = SELECTED_CHARGES?.filter((item) => {
      return item.id !== charge.id;
    });
    onChangeDisabledNextStep(true);
    updateSelectedCharges({ 0: filteredData });
  };

  const onDeleteCharge = (arrayHelpers, index) => {
    const nextValues = {
      ...values,
      billingCharges: values.billingCharges.filter((iterationCharge, idx) => idx !== index),
    };

    if (nextValues.billingCharges.length === 1) {
      setDisabledDelete(true);
    }

    if (values.billingCharges.length === Number(SELECTED_CHARGES?.length) + 1) {
      onChangeDisabledNextStep(false);
    }

    arrayHelpers.remove(index);
    const deletedItem = values.billingCharges[index];
    const filteredBillCharges = values.billingCharges.filter((el) => el.id !== deletedItem.id);
    updateShipmentData({ ...createShipmentData, billingCharges: filteredBillCharges });

    // if (index === recommendation?.lineHaul?.index) {
    //   resetRecommendation('lineHaul', index);
    // } else if (index === recommendation?.fuel.index) {
    //   resetRecommendation('fuel', index);
    // } else {
    //   resetRecommendation(null, index);
    // }
  };

  const chargesStyles = useMemo(() => {
    return {
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      editIcon: {
        height: 15,
        cursor: 'pointer',
      },
      isSearchableStyles: {
        width: 400,
        borderRadius: 6,
      },
    };
  }, [use, palette]);

  useEffect(() => {
    if (values.billingCharges.length > 1) {
      updateSelectedCharges({
        0: values.billingCharges.map((charge) => ({
          ...charge,
          rate: charge?.charges?.type?.account_type === 'Expense' ? `-${charge.rate}` : charge.rate,
        })),
      });
    }
    getChargeType().then();
    !selectedGrandTotalForm && getGrandTotalDefault();
  }, []);

  useEffect(() => {
    if (SELECTED_CHARGES?.length === 0) {
      onChangeDisabledNextStep(true);
    } else if (SELECTED_CHARGES?.length === values.billingCharges.length) {
      onChangeDisabledNextStep(false);
    }
  }, [SELECTED_CHARGES]);

  return (
    <div>
      <div className={sm.header_titles_wrapper}>
        <div className={sm.titles}>
          <div className='d-flex justify-content-between'>
            <Typography variant='overLine' style={{ minWidth: 456 }}>
              CHARGES
              <GearIcon className='pointer ms-3' onClick={() => setOpenItemModal(true)} />
            </Typography>
          </div>
          <Typography variant='overLine' style={{ minWidth: 80 }}>
            QTY
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 160 }}>
            RATE
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 160 }}>
            SUB-TOTAL
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 120 }} />
        </div>
        <div className={sm.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
      </div>
      <FieldArray
        name='billingCharges'
        render={(arrayHelpers) => {
          return (
            <div style={{ marginBottom: 30 }}>
              {values?.billingCharges?.map((charge, index) => (
                <BillingCharge
                  key={`${index + 1}`}
                  charge={charge}
                  index={index}
                  values={values}
                  setFieldValue={setFieldValue}
                  currencyData={currencyData}
                  onSaveCharge={() => onSaveCharge(charge, index)}
                  onEditCharge={() => onEditCharge(charge)}
                  onDeleteCharge={() => onDeleteCharge(arrayHelpers, index)}
                  chargeErrors={chargeErrors}
                  setChargeErrors={setChargeErrors}
                />
              ))}
              <GrandTotal
                currencyData={currencyData}
                createShipmentData={createShipmentData}
                arrayHelpers={arrayHelpers}
                chargesStyles={chargesStyles}
                selectedCharges={selectedCharges}
                selectedGrandTotalForm={selectedGrandTotalForm}
                updateSelectedGrandTotal={updateSelectedGrandTotal}
                onChangeDisabledNextStep={onChangeDisabledNextStep}
                onSetDisabledDelete={(v) => setDisabledDelete(v)}
              />
            </div>
          );
        }}
      />
      {openItemModal && (
        <ItemModal open={openItemModal} onClose={() => setOpenItemModal(false)} onSuccess={getChargeType} />
      )}
    </div>
  );
};

export default BillingChargesBody;

const GrandTotal = ({
  arrayHelpers,
  currencyData,
  chargesStyles,
  selectedCharges,
  createShipmentData,
  selectedGrandTotalForm,
  updateSelectedGrandTotal,
  onChangeDisabledNextStep,
  onSetDisabledDelete,
}) => {
  const { use } = useTheme();
  const [count, setCount] = useState(0);

  useEffect(() => {
    let subTotal = 0;
    selectedCharges[0]?.forEach((charge) => {
      if (charge?.qty && charge?.rate) {
        subTotal = +subTotal + Number(charge.qty) * Number(charge.rate);
      }
    });
    setCount(numberWithCommas(subTotal.toFixed(2)));
  }, [selectedCharges]);
  useEffect(() => {
    if (!!createShipmentData.currency_id && !selectedGrandTotalForm) {
      const currency = BILLING_GRAND_TOTAL_CURRENCY.find((el) => +el.id === +createShipmentData.currency_id);
      updateSelectedGrandTotal({ 0: currency });
    } else if (!createShipmentData.currency_id && !selectedGrandTotalForm) {
      updateSelectedGrandTotal({ 0: BILLING_GRAND_TOTAL_CURRENCY[1] });
    }
  }, []);

  return (
    <div className={sm.another_wrapper}>
      <CustomButton
        type='secondary'
        title='Add Another'
        leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
        styleButton={chargesStyles.addAnotherButton}
        styleTitle={chargesStyles.addAnotherTitle}
        onClick={() => {
          onSetDisabledDelete(false);
          onChangeDisabledNextStep(true);
          arrayHelpers.push(getChangesTemplate());
        }}
      />
      <div className={sm.grand_total}>
        <Typography variant='s2' style={{ marginBottom: 8 }}>
          Grand Total
        </Typography>
        <div className={sm.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
        <div className={sm.grand_total_bottom_wrapper}>
          <Typography variant='s2'>
            {currencyData?.symbol || '$'}
            {count}
          </Typography>
          <PopoverBillingCharges
            data={BILLING_GRAND_TOTAL_CURRENCY}
            onClick={(item) => updateSelectedGrandTotal({ 0: item })}
            selectedItem={selectedGrandTotalForm}
          />
        </div>
      </div>
    </div>
  );
};
