import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getTotalIncident } from 'Api/Dashboard';
import LineChart from '../../Charts/LineChart';

const TotalIncidents = ({ year, refreshData }) => {
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);
  const [incidentsData, setIncidentsData] = useState([]);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getTotalIncident({
        from_date: moment().subtract(11, 'month').startOf('month').format('MM/DD/YYYY'),
        to_date: moment().endOf('month').format('MM/DD/YYYY'),
      });
      setIncidentsData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const graphData = new Array(13).fill(0);

  const fillArray = (startingPoint) => {
    const arr = [];
    for (let i = 0; i < 12; i++) {
      arr.push((startingPoint + i) % 12 || 12);
    }
    return arr;
  };

  const arr = fillArray(Number(moment().subtract(11, 'month').format('M')));

  incidentsData?.incident_type?.forEach((i) => {
    graphData[arr?.indexOf(i.month)] = i.incident_count;
  });

  const calcPercentage = (this_month, prev_month) => {
    const thisMonthAmount = this_month || 0;
    const prevMonthAmount = prev_month || 0;

    const diff = Math.abs(thisMonthAmount - prevMonthAmount);
    const diffInPercentage = ((diff / prevMonthAmount) * 100).toFixed(2);

    if (thisMonthAmount === 0 || prevMonthAmount === 0) {
      return '-';
    }

    return `${thisMonthAmount >= prevMonthAmount ? '+' : '-'}${diffInPercentage}%`;
  };

  const lastMonth = calcPercentage(graphData[10], graphData[9]);
  const thisMonth = calcPercentage(graphData[11], graphData[10]);

  useEffect(() => {
    getCounts();
  }, [refreshData]);

  if (year) {
    return (
      <LineChart
        total={incidentsData.total_incident}
        graphData={graphData}
        loading={loading}
        header={
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex align-items-center gap-1'>
              <span className='receivable-chart-section-tag-indigo' style={{ margin: 0 }}>
                {incidentsData?.total_incident}
              </span>
              <Typography variant='s2'>Total Incidents</Typography>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-column gap-0'>
                {loading ? (
                  <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '28px' }} />
                ) : (
                  <Typography
                    variant='button2'
                    style={{
                      color:
                        lastMonth === '-'
                          ? use(palette.gray500)
                          : lastMonth.startsWith('+')
                          ? use(palette.red500)
                          : use(palette.green500),
                      textAlign: 'end',
                    }}
                  >
                    {lastMonth}
                  </Typography>
                )}
                <Typography variant='c2' style={{ color: use(palette.gray500) }}>
                  Last Month
                </Typography>
              </div>
              <div className='d-flex flex-column gap-0'>
                {loading ? (
                  <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '28px' }} />
                ) : (
                  <Typography
                    variant='button2'
                    style={{
                      color:
                        thisMonth === '-'
                          ? use(palette.gray500)
                          : thisMonth.startsWith('+')
                          ? use(palette.red500)
                          : use(palette.green500),
                    }}
                  >
                    {thisMonth}
                  </Typography>
                )}
                <Typography variant='c2' style={{ color: use(palette.gray500) }}>
                  Current Month
                </Typography>
              </div>
            </div>
          </div>
        }
      />
    );
  }

  return (
    <div className='box-content-wrapper'>
      <LineChart
        total={incidentsData.total_incident}
        graphData={graphData}
        loading={loading}
        header={
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex align-items-center gap-1'>
              <span className='receivable-chart-section-tag-indigo' style={{ margin: 0 }}>
                {incidentsData?.total_incident}
              </span>
              <Typography variant='s2'>Total Incidents</Typography>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-column gap-0'>
                {loading ? (
                  <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '28px' }} />
                ) : (
                  <Typography
                    variant='button2'
                    style={{
                      color:
                        thisMonth === '-'
                          ? use(palette.gray500)
                          : thisMonth.startsWith('+')
                          ? use(palette.red500)
                          : use(palette.green500),
                    }}
                  >
                    {thisMonth}
                  </Typography>
                )}
                <Typography variant='c2' style={{ color: use(palette.gray500) }}>
                  Current Month
                </Typography>
              </div>
              <div className='d-flex flex-column gap-0'>
                {loading ? (
                  <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '28px' }} />
                ) : (
                  <Typography
                    variant='button2'
                    style={{
                      color:
                        lastMonth === '-'
                          ? use(palette.gray500)
                          : lastMonth.startsWith('+')
                          ? use(palette.red500)
                          : use(palette.green500),
                      textAlign: 'end',
                    }}
                  >
                    {lastMonth}
                  </Typography>
                )}
                <Typography variant='c2' style={{ color: use(palette.gray500) }}>
                  Last Month
                </Typography>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default TotalIncidents;
