import { array, number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = array().of(
  object().shape({
    payment_date: validator.requiredDate(),
    amount_paid: number()
      .required('Required')
      .nullable()
      .test('open_balance', `Can't be more than open balance`, function (value) {
        const { open_balance } = this.parent;
        return value <= open_balance;
      }),
    pay_reference: string().trim().required('Required').nullable(),
    payment_method: object()
      .shape({ value: string().required('Required') })
      .required('Required')
      .nullable(),
    account: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
  })
);
