import React from 'react';
import { Typography } from 'components/Typography';
import EDILogs from 'componentsV2/EDI/EDILogs';
import { SInnerSection, SSection } from 'componentsV2/Customer/EdiApi/EdiApi.styles';

const Logs = ({ customerId }) => {
  return (
    <SSection>
      <div className='section-header'>
        <Typography variant='h4'>EDI/API Logs</Typography>
      </div>
      <div className='section-body'>
        <SInnerSection>
          <div className='inner-section-body'>
            <EDILogs customerId={customerId} />
          </div>
        </SInnerSection>
      </div>
    </SSection>
  );
};

export default Logs;
