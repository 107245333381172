import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  overflow: auto;

  .info-items-wrapper {
    width: 100%;
    display: flex;

    .info-wrapper {
      white-space: nowrap;
      width: 100%;

      .info-header {
        padding: 0 8px;
      }

      .info-box {
        height: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding: 4px 8px 16px;
        border: 1px solid ${palette.gray50};
      }

      :first-child {
        flex-basis: 350px;
        flex-grow: 0;
        flex-shrink: 0;

        .info-header {
          padding: 0;
        }

        .info-box {
          border-left: none;
          padding-left: 0;

          .multi-customer {
            width: fit-content;
          }
        }
      }

      :last-child .info-box {
        border-right: none;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 8px;
  }
`;
