import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './UpdateHireDateModal.css';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { DatePicker } from 'common/Pickers';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { validationSchema } from './validationSchema';

export default function UpdateDateModal({ ...props }) {
  const { use } = useTheme();

  const AddDate = (values) => {
    const { hire_date } = values;
    const date = moment(hire_date).format('MM/DD/YYYY');
    props.updateProfile(
      { ...values, hire_date: date },
      props.onHide,
      props.onSuccess,
      'Driver hire date has been updated successfully'
    );
  };

  const formik = useFormik({
    initialValues: {
      hire_date: new Date(),
    },
    onSubmit: AddDate,
    validationSchema,
  });

  useEffect(() => {
    formik.setFieldValue(
      'hire_date',
      props.driverInfo?.certifications?.hire_date ? new Date(props.driverInfo?.certifications?.hire_date) : new Date()
    );
  }, [props.driverInfo]);

  return (
    <div className='modal-container'>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Update Hire Date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='content-wrapper-date'>
            <DatePicker
              required
              label='Hire Date'
              name='hire_date'
              value={formik.values.hire_date}
              onChange={(date) => formik.setFieldValue('hire_date', date)}
              onBlur={formik.handleBlur}
              disableFuture
              error={formik.touched.hire_date && formik.errors.hire_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' disabled={!formik.isValid} type='submit' onClick={formik.handleSubmit}>
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
