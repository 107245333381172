import React, { useState, useRef, useEffect } from 'react';
import Input from '../Input';
import { SVerificationInputContainer } from './VerificationCode.styles';

const VerificationCode = ({ onChange = () => null, regExp = /[^0-9]/, codeLength = 4, initialValue, className }) => {
  const [code, setCode] = useState(Array(codeLength).fill(''));
  const inputsRef = useRef([]);

  const handleChange = (value, index) => {
    if (regExp.test(value) || !value) {
      return;
    }
    const newCode = [...code];
    newCode[index] = value || '';
    setCode(newCode);
    if (index !== codeLength - 1 && inputsRef.current[index + 1]) {
      inputsRef.current[index + 1].focus();
    }

    onChange(newCode.join(''));
  };

  const handleKeyDown = (e, index) => {
    const canGoLeft = index !== 0 && inputsRef.current[index - 1];
    const canGoRight = index !== codeLength - 1 && inputsRef.current[index + 1];

    if (e.keyCode === 37 && canGoLeft) {
      inputsRef.current[index - 1].focus();
    }

    if (e.keyCode === 39 && canGoRight) {
      inputsRef.current[index + 1].focus();
    }

    if (e.keyCode === 8 || e.keyCode === 46) {
      e.preventDefault();
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);
      onChange(newCode.join(''));

      if (e.keyCode === 8 && canGoLeft) {
        inputsRef.current[index - 1].focus();
      }
      if (e.keyCode === 46 && canGoRight) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handlePast = (val) => {
    const trimmedValue = val.trim();

    if (regExp.test(trimmedValue)) {
      return;
    }

    const newValue = trimmedValue.length > codeLength ? trimmedValue.slice(0, codeLength) : trimmedValue;
    const valueArray =
      newValue.length < codeLength
        ? newValue.split('').concat(new Array(codeLength - newValue.length).fill(''))
        : newValue.split('');
    setCode(valueArray);
    onChange(newValue);

    if (inputsRef.current[newValue.length - 1]) {
      inputsRef.current[newValue.length - 1].focus();
    }
  };

  useEffect(() => {
    if (!initialValue) {
      return;
    }
    setCode(initialValue.toString().split(''));
  }, []);

  return (
    <SVerificationInputContainer className={className}>
      {code.map((val, index) => (
        <Input
          maxLength={1}
          className='verification-input'
          onPaste={(value) => handlePast(value.clipboardData.getData('Text'))}
          key={`code-${index + 1}`}
          value={val}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(ref) => {
            if (ref) {
              inputsRef.current[index] = ref;
            }
          }}
        />
      ))}
    </SVerificationInputContainer>
  );
};

export default VerificationCode;
