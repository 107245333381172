import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { numberWithCommas, palette } from 'utils/constants';
import { statusCountConverter } from './helpers';
import { SSettlementInfo } from './TopSection.styles';

const SettlementsInfo = ({ data }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    const convertedData = statusCountConverter(data);
    setInfo(convertedData);
  }, [data]);

  if (!info) {
    return null;
  }

  return (
    <div className='sub-section'>
      <div className='settlements-card'>
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          Ready Settlements
        </Typography>
        <SSettlementInfo>
          <Typography variant='h1' style={{ color: use(palette.gray900, palette.gray200) }}>
            {info.ready?.status_count || 0}
          </Typography>
          <Typography variant='h5' style={{ color: use(palette.gray700, palette.gray200) }}>
            {currency}
            {numberWithCommas(info.ready?.amount?.toFixed(2)) || '0.00'}
          </Typography>
        </SSettlementInfo>
      </div>

      <div className='settlements-card'>
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          Upcoming Settlements
        </Typography>
        <SSettlementInfo>
          <Typography variant='h1' style={{ color: use(palette.gray900, palette.gray200) }}>
            {info.upcoming?.status_count || 0}
          </Typography>
          <Typography variant='h5' style={{ color: use(palette.gray700, palette.gray200) }}>
            {currency}
            {numberWithCommas(info.upcoming?.amount?.toFixed(2)) || '0.00'}
          </Typography>
        </SSettlementInfo>
      </div>

      <div className='settlements-card'>
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
          Disputed Settlements
        </Typography>
        <SSettlementInfo>
          <Typography variant='h1' style={{ color: use(palette.gray900, palette.gray200) }}>
            {info.disputed?.status_count || 0}
          </Typography>
          <Typography variant='h5' style={{ color: use(palette.gray700, palette.gray200) }}>
            {currency}
            {numberWithCommas(info.disputed?.amount?.toFixed(2)) || '0.00'}
          </Typography>
        </SSettlementInfo>
      </div>
    </div>
  );
};

export default SettlementsInfo;
