import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SChannel = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;

  &.selected {
    background: ${palette.indigo0};
    transition: background 0.1s ease-in-out;
  }

  :hover {
    background-color: ${palette.gray0};
  }

  .channel-item-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    column-gap: 12px;
  }

  .MuiChip-root {
    border-radius: 18px;

    .MuiChip-label {
      line-height: 14px;
    }
  }
`;
