import React from 'react';
import { blockNotNumberChars } from 'utils/helpers';
import Input from '../Input';
import { SWrapper } from './CountInput.styles';

const CountInput = ({ value, handleChange, wrapperClassName, disabled, minValue = 0, ...props }) => {
  const handleDecrease = () => {
    handleChange((Number(value || 0) - 1).toString());
  };

  const handleIncrease = () => {
    handleChange((Number(value || 0) + 1).toString());
  };

  return (
    <SWrapper className={wrapperClassName}>
      <button
        type='button'
        disabled={Number(value) === minValue || disabled}
        onClick={handleDecrease}
        className='decrease-button'
      >
        -
      </button>
      <Input
        type='number'
        onKeyDown={blockNotNumberChars}
        className='count-input'
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        disabled={disabled}
        {...props}
      />
      <button type='button' onClick={handleIncrease} className='increase-button' disabled={disabled}>
        +
      </button>
    </SWrapper>
  );
};

export default CountInput;
