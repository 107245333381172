import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { downloadCsv } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import {
  exportPlannerCsv,
  exportShipmentsCsv,
  exportReferralsCsv,
  exportReferrersCsv,
  exportStopPointsCsv,
  exportApplicantsCsv,
  exportSettlementsCsv,
  exportTimeTrackingCsv,
  exportJobPositionsCsv,
  exportShipmentAuditCsv,
  exportRecurringLanesCsv,
  exportStopPointsInventoryCsv,
  exportShipmentAuditClearedCsv,
  exportShipmentHistoryCsv,
} from 'Api/CsvExports';
import { SBackdrop } from './ExportCsv.styles';

const ExportCsv = ({ type, height, dot, exportParams }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const getCsvString = async () => {
    try {
      switch (type) {
        case 'shipments':
          return await exportShipmentsCsv();
        case 'shipment-audit':
          return await exportShipmentAuditCsv();
        case 'shipment-audit-cleared':
          return await exportShipmentAuditClearedCsv();
        case 'shipment-history':
          return await exportShipmentHistoryCsv();
        case 'planner':
          return await exportPlannerCsv();
        case 'recurring-lanes':
          return await exportRecurringLanesCsv(exportParams);
        case 'stop-points':
          return await exportStopPointsCsv();
        case 'stop-point-inventory':
          return await exportStopPointsInventoryCsv({ type: 'inventory', ...exportParams });
        case 'stop-point-history':
          return await exportStopPointsInventoryCsv({ type: 'history', ...exportParams });
        case 'upcoming-settlements':
          return await exportSettlementsCsv({ type: 'upcoming' });
        case 'ready-settlements':
          return await exportSettlementsCsv({ type: 'ready' });
        case 'processed-settlements':
          return await exportSettlementsCsv({ type: 'processed' });
        case 'disputed-settlements':
          return await exportSettlementsCsv({ type: 'disputed' });
        case 'paid-settlements':
          return await exportSettlementsCsv({ type: 'paid' });
        case 'time-tracking':
          return await exportTimeTrackingCsv(exportParams);
        case 'applicants':
          return await exportApplicantsCsv();
        case 'referrals':
          return await exportReferralsCsv();
        case 'referrers':
          return await exportReferrersCsv();
        case 'job-positions':
          return await exportJobPositionsCsv(dot);
        default:
          return () => null;
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const exportCsv = async () => {
    try {
      setLoading(true);
      const response = await getCsvString();
      if (response && typeof response === 'string') {
        await downloadCsv(
          response,
          `${type}${exportParams?.equipment_type ? `-${exportParams.equipment_type}s` : ''}.csv`
        );
      } else {
        showToaster({ type: 'error', message: 'Something went wrong!' });
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title='Export CSV'>
        <CustomButton
          type='secondary'
          leftIcon={<ExportIcon fill={palette.gray700} />}
          styleButton={{ padding: '6px 12px', margin: 0, height: height || 32 }}
          onClick={exportCsv}
          disabled={loading}
        />
      </Tooltip>
      <SBackdrop open={loading}>
        <CircularProgress size={30} style={{ marginTop: '30px' }} />
        <Typography variant='h1' style={{ color: palette.white }}>
          Processing, please wait
        </Typography>
      </SBackdrop>
    </>
  );
};

export default ExportCsv;
