import React from 'react';
import { useSelector } from 'react-redux';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

export const useColumns = ({ sortingQuery, sort, isStaff = false, isVehicle = false }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);

  return [
    ...(isStaff
      ? [
          {
            field: 'STAFF',
            title: <ColumnHeader title='STAFF' field='name' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.name || '-'}
              </Typography>
            ),
          },
          {
            field: 'HOURLY RATE',
            title: <ColumnHeader title='HOURLY RATE' field='hourly_rate' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.hourly_rate && !!parseFloat(row.hourly_rate) ? `${currency}${row?.hourly_rate}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'TIME TRACKED',
            title: <ColumnHeader title='TIME TRACKED' field='time_tracked' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.time_tracked || '-'}
              </Typography>
            ),
          },
          {
            field: 'PAY PER SHIPMENT',
            title: (
              <ColumnHeader title='PAY PER SHIPMENT' field='pay_per_shipment' onClick={sortingQuery} sort={sort} />
            ),
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.pay_per_shipment && !!parseFloat(row.pay_per_shipment)
                  ? `${row?.flat_or_per === 1 ? `${currency}` : ''}${row?.pay_per_shipment}${
                      row?.flat_or_per === 2 ? '%' : ''
                    }`
                  : '-'}
              </Typography>
            ),
          },
          {
            field: 'BOOKED EMPTY (MI)',
            title: <ColumnHeader title='BOOKED EMPTY (MI)' field='booked_empty' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.booked_empty ? parseInt(row?.booked_empty?.toString(), 10)?.toString() : '-'}
              </Typography>
            ),
          },
          {
            field: 'BOOKED LOADED (MI)',
            title: <ColumnHeader title='BOOKED LOADED (MI)' field='booked_loaded' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.booked_loaded ? parseInt(row?.booked_loaded?.toString(), 10)?.toString() : '-'}
              </Typography>
            ),
          },
          {
            field: 'BOOKED TOTAL (MI)',
            title: <ColumnHeader title='BOOKED TOTAL (MI)' field='booked_total' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.booked_total ? parseInt(row?.booked_total?.toString(), 10)?.toString() : '-'}
              </Typography>
            ),
          },
          {
            field: 'BOOKED SHIPMENTS',
            title: (
              <ColumnHeader title='BOOKED SHIPMENTS' field='booked_shipments' onClick={sortingQuery} sort={sort} />
            ),
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.booked_shipments || '-'}
              </Typography>
            ),
          },
          {
            field: 'GROSS RPM',
            title: <ColumnHeader title='GROSS RPM' field='gross_rpm' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_rpm ? `${currency}${row?.gross_rpm}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'GROSS REVENUE',
            title: <ColumnHeader title='GROSS REVENUE' field='gross_revenue' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_revenue ? `${currency}${row?.gross_revenue}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'GROSS PAY',
            title: <ColumnHeader title='GROSS PAY' field='gross_pay' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_pay ? `${currency}${row?.gross_pay}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'NET PAY',
            title: <ColumnHeader title='NET PAY' field='net_pay' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.net_pay ? `${currency}${row?.net_pay}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'MARGIN %',
            title: <ColumnHeader title='MARGIN %' field='margin' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.margin ? `${row?.margin}%` : '-'}
              </Typography>
            ),
          },
          {
            field: 'GROSS MARGIN',
            title: <ColumnHeader title='GROSS MARGIN' field='gross_margin' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_margin ? `${currency}${row?.gross_margin}` : '-'}
              </Typography>
            ),
          },
        ]
      : [
          {
            field: 'DRIVER',
            title: (
              <ColumnHeader title={isVehicle ? 'VEHICLE' : 'DRIVER'} field='name' onClick={sortingQuery} sort={sort} />
            ),
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.name || '-'}
              </Typography>
            ),
          },
          {
            field: 'FUEL CPM',
            title: <ColumnHeader title='FUEL CPM' field='fuel_cpm' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.fuel_cpm ? `${currency}${row?.fuel_cpm}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'FUEL COSTS',
            title: <ColumnHeader title='FUEL COSTS' field='fuel_costs' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.fuel_costs ? `${currency}${row?.fuel_costs}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'EMPTY (MI)',
            title: <ColumnHeader title='EMPTY (MI)' field='empty' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.empty ? parseInt(row?.empty?.toString(), 10)?.toString() : '-'}
              </Typography>
            ),
          },
          {
            field: 'LOADED (MI)',
            title: <ColumnHeader title='LOADED (MI)' field='loaded' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.loaded ? parseInt(row?.loaded?.toString(), 10)?.toString() : '-'}
              </Typography>
            ),
          },
          {
            field: 'TOTAL (MI)',
            title: <ColumnHeader title='TOTAL (MI)' field='total' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.total ? parseInt(row?.total?.toString(), 10)?.toString() : '-'}
              </Typography>
            ),
          },
          {
            field: 'SHIPMENTS',
            title: <ColumnHeader title='SHIPMENTS' field='shipments' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.shipments || '-'}
              </Typography>
            ),
          },
          {
            field: 'GROSS RPM',
            title: <ColumnHeader title='GROSS RPM' field='gross_rpm' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_rpm ? `${currency}${row?.gross_rpm}` : '-'}
              </Typography>
            ),
          },
          {
            field: 'GROSS REVENUE',
            title: <ColumnHeader title='GROSS REVENUE' field='gross_revenue' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_revenue ? `${currency}${row?.gross_revenue}` : '-'}
              </Typography>
            ),
          },

          {
            field: 'GROSS PAY',
            title: <ColumnHeader title='GROSS PAY' field='gross_pay' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_pay ? `${currency}${row?.gross_pay}` : '-'}
              </Typography>
            ),
          },
          ...(!isVehicle
            ? [
                {
                  field: 'NET PAY',
                  title: <ColumnHeader title='NET PAY' field='net_pay' onClick={sortingQuery} sort={sort} />,
                  render: (row) => (
                    <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {row?.net_pay ? `${currency}${row?.net_pay}` : '-'}
                    </Typography>
                  ),
                },
              ]
            : []),
          {
            field: 'MARGIN %',
            title: <ColumnHeader title='MARGIN %' field='margin' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.margin ? `${row?.margin}%` : '-'}
              </Typography>
            ),
          },
          {
            field: 'GROSS MARGIN',
            title: <ColumnHeader title='GROSS MARGIN' field='gross_margin' onClick={sortingQuery} sort={sort} />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                {row?.gross_margin ? `${currency}${row?.gross_margin}` : '-'}
              </Typography>
            ),
          },
        ]),
  ];
};
