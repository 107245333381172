import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Modal from 'common/Modal';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { getMileageHistory } from 'Api/Equipment';
import { useColumns, useRevenueColumns, initialFilters } from './EquipmentMileage.data';
import { DatePickerWrapper } from './EquipmentMileage.styles';

const EquipmentMileage = ({ open, onClose, equipmentID, isGrossRevenue }) => {
  const { currency } = useSelector((state) => state.root);
  const [mileageData, setMileageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [startDate, endDate] = dateRange;

  const getEquipmentMileageHistory = async () => {
    try {
      setLoading(true);
      const { page, itemsPerPage } = selectedFilters || {};
      const params = {
        page,
        itemsPerPage,
        from_date: startDate ? moment(startDate).format('MM/DD/YYYY') : undefined,
        to_date: endDate ? moment(endDate).format('MM/DD/YYYY') : undefined,
      };
      const { data } = await getMileageHistory({ id: equipmentID, params });
      setMileageData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getEquipmentMileageHistory();
  }, [selectedFilters, endDate]);

  const columns = isGrossRevenue ? useRevenueColumns(currency) : useColumns();

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={isGrossRevenue ? 'Shipment History' : 'On Duty Mileage History'}
      $bgColor={palette.white}
      $maxWidth={isGrossRevenue ? '800px' : '1200px'}
      $minWidth={isGrossRevenue ? '800px' : '1200px'}
      $minHeight='500px'
      className='modified-scrollbar'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <DatePickerWrapper>
        <DatePicker
          selectsRange
          startDate={startDate}
          endDate={endDate}
          placeholderText='Select From - To Date'
          onChange={(update) => setDateRange(update)}
          isClearable
        />
      </DatePickerWrapper>
      {loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          data={mileageData?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white, marginBottom: '8px' }}
          components={{
            Pagination: () =>
              Pagination({
                data: mileageData,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [50, 100, 150],
              }),
          }}
          columns={columns}
        />
      )}
    </Modal>
  );
};
export default EquipmentMileage;
