import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { palette } from 'utils/constants';
import { getOptions } from './Charts.data';

ChartJS.register(ArcElement, Tooltip, Legend);

const backgroundColors = {
  'No Adverse Conditions': palette.green400,
  'Clear / Dry': palette.indigo400,
  Sunny: palette.amber400,
  Cloudy: palette.red400,
  Foggy: palette.orange400,
  'High Winds': palette.red200,
  'Rain Sleet': palette.blue300,
  Snow: palette.dark0,
  Other: palette.gray400,
};

const WeatherFactorsChart = ({ chartData }) => {
  const weatherFactorsWithAccident = (chartData || []).filter((item) => item.total_count !== 0);

  const data = {
    labels: weatherFactorsWithAccident.map((item) => `${item.weather_factor}`),
    datasets: [
      {
        data: weatherFactorsWithAccident.map((item) => item.total_count),
        backgroundColor: weatherFactorsWithAccident.map((item) => backgroundColors[item.weather_factor]),
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getOptions(weatherFactorsWithAccident)} data={data} />;
};

export default WeatherFactorsChart;
