import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MoreInfo } from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as SettingIcon } from 'assets/sidemanu/settings-20px.svg';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import NoRecords from 'common/NoRecords';
import ErrorMessage from 'common/ErrorMessage';
import ActionButtons from 'common/ActionButtons';
import useShowToaster from 'hooks/useShowToaster';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import { palette, PERMISSIONS } from 'utils/constants';
import { getEdiRejectionReasons } from 'Api/Planner';
import { transformYupErrorsIntoObject } from 'utils/helpers';
import {
  createCustomerRejectReason,
  deleteCustomerRejectReason,
  getCustomerRejectReasons,
  updateCustomerRejectReason,
} from 'Api/Customers';
import { validationSchema } from './validationSchema';
import { getInitialValues, initialValues } from './RejectReasonCodes.data';
import { SSection, STable, SAddMore } from '../EdiApi.styles';

const RejectReasonCodes = ({ customerId }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { permissions } = useSelector((state) => state?.root);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [customerRejectReasons, setCustomerRejectReasons] = useState([]);
  const [loadingSave, setLoadingSave] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const readOnly =
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) ||
    permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_READ_ONLY.name);

  const form = useForm({
    initialValues: getInitialValues(customerRejectReasons),
    onSubmit: () => null,
    validationSchema,
    enableReinitialize: true,
  });
  const { values, handleChange, touchedErrors, setValues, setErrors, setTouched } = form;

  const getRejectionReasons = async () => {
    try {
      const { data } = await getEdiRejectionReasons();
      setRejectionReasons(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerRejectionReasons = async () => {
    try {
      const { data } = await getCustomerRejectReasons(customerId);
      setCustomerRejectReasons(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onSaveReason = async (item, index) => {
    try {
      if (isCurrentItemLoading(index)) {
        return;
      }

      await validationSchema.validateAt(`${index}`, values, {
        strict: true,
        abortEarly: false,
      });

      setLoadingSave(index);

      let newItem;

      if (item.isNew) {
        const formData = new FormData();
        formData.append('customer_id', customerId);
        formData.append('reasons[0][reason_id]', item.reason.id);

        const { data } = await createCustomerRejectReason(customerId, formData);
        newItem = data[0];
      } else {
        const body = {
          reason_id: item.reason.id,
        };

        await updateCustomerRejectReason(item.id, body);
      }

      showToaster({
        type: 'success',
        message: `Reject reason has been ${item.isNew ? 'added' : 'updated'} successfully!`,
      });

      handleChange(`${index}`, { ...item, id: newItem?.id || item.id, isNew: false });
    } catch (e) {
      const { validationErrors, validationTouched } = transformYupErrorsIntoObject(e);

      if (Object.keys(validationErrors)?.length) {
        setErrors(validationErrors, { shouldValidate: false });
        setTouched(validationTouched, { shouldValidate: false });
        return;
      }

      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setLoadingSave(null);
    }
  };

  const deleteCustomerRejectionReasons = async (item, index) => {
    try {
      if (isCurrentItemLoading(index)) {
        return;
      }

      if (!item.isNew) {
        setLoadingDelete(index);
        await deleteCustomerRejectReason(item.id);
        showToaster({ type: 'success', message: `Reject reason has been deleted successfully!` });
      }

      setValues(values.filter((item, i) => i !== index));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setLoadingDelete(null);
    }
  };

  const isCurrentItemLoading = (index) => {
    return loadingSave === index || loadingDelete === index;
  };

  const onAddMore = () => {
    handleChange(`${values.length}`, { id: uuid(), ...initialValues });
  };

  useEffect(() => {
    getRejectionReasons();
    getCustomerRejectionReasons();
  }, []);

  if (
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) &&
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_READ_ONLY.name)
  ) {
    return null;
  }

  return (
    <SSection>
      <div className='section-header'>
        <Typography variant='h4' style={{ color: palette.gray900 }}>
          Reject Reason Codes
        </Typography>
        {permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) &&
          (editMode ? (
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '3px 12px', margin: 0 }}
              type='secondary'
              title='Close'
              onClick={() => setEditMode(false)}
            />
          ) : (
            <MoreInfo className='pointer' onClick={() => setEditMode(true)} />
          ))}
      </div>
      <div className='section-body'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>
                REASON
                {!!editMode && (
                  <SettingIcon
                    className='ms-2'
                    onClick={() =>
                      navigate('/company-settings', {
                        state: {
                          tabId: 7,
                          subTabName: 'RejectReasons',
                        },
                      })
                    }
                  />
                )}
              </th>
              <th style={{ width: '100px' }} />
            </tr>
          </thead>
          <tbody>
            {values.map((item, index) => (
              <tr className='body-row' key={item.id}>
                <td>
                  <div>
                    {!editMode ? (
                      <Typography variant='s2' style={{ color: palette.gray900 }}>
                        {item.reason?.title} ({item.reason?.code})
                      </Typography>
                    ) : (
                      <Autocomplete
                        width='400px'
                        name={`${index}.reason`}
                        options={rejectionReasons}
                        value={item.reason}
                        onChange={(e, val) => handleChange(`${index}.reason`, val)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => `${option.title} (${option.code})`}
                      />
                    )}
                    <ErrorMessage error={touchedErrors?.[index]?.reason} />
                  </div>
                </td>
                <td>
                  {!!editMode && (
                    <ActionButtons
                      data={{ ...item, isEdit: true }}
                      onEdit={() => null}
                      onSave={() => onSaveReason(item, index)}
                      onDelete={() => deleteCustomerRejectionReasons(item, index)}
                      loadingSave={loadingSave === index}
                      loadingDelete={loadingDelete === index}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!editMode && !values.length && <NoRecords />}
        {!readOnly && !!editMode && (
          <SAddMore onClick={onAddMore}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        )}
      </div>
    </SSection>
  );
};

export default RejectReasonCodes;
