export const statsDataConverter = (data) => {
  const { Administrative, Fixed, Maintenance, Variable, Total } = data || {};

  const totalAmountDiff = (Total?.current_month?.amount || 0) - (Total?.last_month?.amount || 0);
  const totalPerMileDiff = (Total?.current_month?.per_mile || 0) - (Total?.last_month?.per_mile || 0);
  const variableAmountDiff = (Variable?.current_month?.amount || 0) - (Variable?.last_month?.amount || 0);
  const variablePerMileDiff = (Variable?.current_month?.per_mile || 0) - (Variable?.last_month?.per_mile || 0);
  const fixedAmountDiff = (Fixed?.current_month?.amount || 0) - (Fixed?.last_month?.amount || 0);
  const fixedPerMileDiff = (Fixed?.current_month?.per_mile || 0) - (Fixed?.last_month?.per_mile || 0);
  const maintenanceAmountDiff = (Maintenance?.current_month?.amount || 0) - (Maintenance?.last_month?.amount || 0);
  const maintenancePerMileDiff = (Maintenance?.current_month?.per_mile || 0) - (Maintenance?.last_month?.per_mile || 0);
  const adminAmountDiff = (Administrative?.current_month?.amount || 0) - (Administrative?.last_month?.amount || 0);
  const adminPerMileDiff = (Administrative?.current_month?.per_mile || 0) - (Administrative?.last_month?.per_mile || 0);

  return {
    total: { ...Total, amountDiff: totalAmountDiff, perMileDiff: totalPerMileDiff },
    variable: { ...Variable, amountDiff: variableAmountDiff, perMileDiff: variablePerMileDiff },
    fixed: { ...Fixed, amountDiff: fixedAmountDiff, perMileDiff: fixedPerMileDiff },
    maintenance: { ...Maintenance, amountDiff: maintenanceAmountDiff, perMileDiff: maintenancePerMileDiff },
    admin: { ...Administrative, amountDiff: adminAmountDiff, perMileDiff: adminPerMileDiff },
  };
};
