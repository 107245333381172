import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import NoRecords from 'common/NoRecords';
import Autocomplete from 'common/Autocomplete';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { getAllVehicles } from 'Api/Map';
import { customerStopPoint } from 'Api/Planner';
import { getDrivers } from 'Api/EquipmentProfile';
import { getEquipmentAtStopReport, getEquipmentAtStopDetailedReport } from 'Api/Reports';
import SummaryTable from './components/SummaryTable';
import DetailedTable from './components/DetailedTable';
import WaitTimeGraph from './components/WaitTimeGraph';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { detailedReportConverter, getFilteredReports } from './converters';
import { STableFilters, STableWrapper } from '../ReportDetails.styles';

const AverageWaitTime = ({ stopPoint }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const [searchParams, setSearchParams] = useSearchParams();
  const stopPointId = searchParams.get('stopPointId');
  const { convertToCustomerTime } = useDateFormat();
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [stopPoints, setStopPoints] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [type, setType] = useState('detailed');
  const [filterType, setFilterType] = useState('equipment');
  const [filters, setFilters] = useState({
    stopPoint: null,
    drivers: [],
    equipment: [],
    averageRange: [null, null],
  });
  const [reportData, setReportData] = useState([]);
  const [sort, setSort] = useState({
    field: type === 'summary' ? 'wait_time' : 'arrived_date',
    sortBy: type === 'summary' ? 'asc' : 'desc',
  });
  const [dateRange, setDateRange] = useState({
    start: stopPointId
      ? moment().subtract(30, 'days').toDate()
      : stopPoint
      ? moment().subtract(7, 'days').toDate()
      : null,
    end: stopPointId || stopPoint ? moment().toDate() : null,
  });

  const filteredReports = useMemo(
    () => getFilteredReports(reportData, sort, filters.averageRange, loading, type),
    [reportData, sort, loading, filters.averageRange, type]
  );

  const getReportSummaryData = async () => {
    setLoading(true);
    try {
      const params = {
        from: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        stop_point_ids: !filters.stopPoint ? undefined : [filters?.stopPoint.id],
        driver_ids:
          filterType !== 'driver' ? undefined : (filters.drivers?.length ? filters.drivers : drivers).map((i) => i.id),
        equipment_ids:
          filterType !== 'equipment'
            ? undefined
            : (filters.equipment?.length ? filters.equipment : vehicles).map((i) => i.id),
        average_from: filters.averageRange[0] !== null ? filters.averageRange[0] : undefined,
        average_to: filters.averageRange[1] !== null ? filters.averageRange[1] : undefined,
      };
      const { data } = await getEquipmentAtStopReport(params);
      setReportData(data || []);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      navigate('/reports');
    } finally {
      setLoading(false);
    }
  };

  const getReportDetailedData = async () => {
    setLoading(true);
    try {
      const params = {
        from: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        stop_point_ids: !filters.stopPoint ? undefined : [filters?.stopPoint.id],
        driver_ids:
          filterType !== 'driver' ? undefined : (filters.drivers?.length ? filters.drivers : drivers).map((i) => i.id),
        equipment_ids:
          filterType !== 'equipment'
            ? undefined
            : (filters.equipment?.length ? filters.equipment : vehicles).map((i) => i.id),
      };
      const { data } = await getEquipmentAtStopDetailedReport(params);
      const convertedData = detailedReportConverter(data);
      setReportData(convertedData);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      navigate('/reports');
    } finally {
      setLoading(false);
    }
  };

  const getStopPoints = async () => {
    try {
      const { data } = await customerStopPoint();
      setStopPoints(data);

      if (stopPointId) {
        const stop = data.find((item) => Number(item.id) === Number(stopPointId));
        searchParams.delete('stopPointId');
        setSearchParams(searchParams, { replace: true });
        setFilters((prevState) => ({ ...prevState, stopPoint: stop }));
      }

      if (stopPoint) {
        const stop = data.find((item) => Number(item.id) === Number(stopPoint));
        setFilters((prevState) => ({ ...prevState, stopPoint: stop }));
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data.map((item) => ({ id: item.id, name: `${item.fname} ${item.lname}` })));
    } catch (e) {
      // Do nothing
    }
  };

  const getVehicles = async () => {
    try {
      const { data } = await getAllVehicles(null, null, null, null);
      const convertedVehicles = data.map((item) => ({
        id: item.id,
        name: `${item.equipment_id} ${item.equipment_type?.title}${
          item.equipment_type?.title === 'Truck' ? (item.truck_type === 1 ? ' (Day-Cab)' : ' (Sleeper)') : ''
        }`,
      }));

      setVehicles(convertedVehicles);
    } catch (e) {
      // Do nothing
    }
  };

  const onPdfClick = async (download) => {
    try {
      const additionalData = { dateRange, dateFormat, filterType, filters, type };
      const { url } = await generatePDF(reportData, download, additionalData);
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onExport = () => {
    const report = type === 'summary' ? filteredReports : [];

    if (type === 'detailed') {
      filteredReports.forEach((item) => {
        report.push(...item.data);
      });
    }

    if (!report?.length) {
      return;
    }

    const titles =
      type === 'summary'
        ? {
            location_name: 'LOCATION NAME',
            equipment: filterType === 'equipment' ? 'EQUIPMENT' : 'DRIVER',
            avg_wait_time: 'AVG WAIT TIME',
          }
        : {
            location_name: 'LOCATION NAME',
            equipment: filterType === 'equipment' ? 'EQUIPMENT' : 'DRIVER',
            customer: 'CUSTOMER',
            shipment_id: 'SHIPMENT ID',
            arrived_date: 'ARRIVED DATE',
            departed_date: 'DEPARTED DATE',
            wait_time: 'WAIT TIME',
          };

    const data =
      type === 'summary'
        ? report?.map((item) => ({
            location_name: item.location_name || '',
            equipment:
              filterType === 'equipment'
                ? `${item.equipment?.equipment_id || ''} ${item.equipment?.length?.length || ''}${
                    item.equipment?.length?.unit || ''
                  } ${item.equipment?.equipment_type?.title || ''} ${
                    item.equipment?.equipment_type?.title === 'Truck'
                      ? item.equipment?.truck_type === 1
                        ? '(Day-Cab)'
                        : '(Sleeper)'
                      : ''
                  }`
                : `${item.driver.fname} ${item.driver.lname}`,
            avg_wait_time: `${item.average_waiting_time.days || 0}d ${item.average_waiting_time.hours || 0}h ${
              item.average_waiting_time.minutes || 0
            }m`,
          }))
        : report?.map((item) => ({
            location_name: item.location_name || '',
            equipment:
              filterType === 'equipment'
                ? `${item.equipment?.equipment_id || ''} ${item.equipment?.length?.length || ''}${
                    item.equipment?.length?.unit || ''
                  } ${item.equipment?.equipment_type?.title || ''} ${
                    item.equipment?.equipment_type?.title === 'Truck'
                      ? item.equipment?.truck_type === 1
                        ? '(Day-Cab)'
                        : '(Sleeper)'
                      : ''
                  }`
                : `${item.driver.fname} ${item.driver.lname}`,
            customer: `${item.shipment_billing ? `${item.shipment_billing.billing_customer?.customer_name}` : ''}`,
            shipment_id: `${item.shipment_id || '-'}`,
            arrived_date: `${item.arrived_date ? convertToCustomerTime(item.arrived_date, null, true) : '-'}`,
            departed_date: `${item.departed_date ? convertToCustomerTime(item.departed_date, null, true) : '-'}`,
            wait_time: `${item.duration_days || 0}h ${item.duration_hours || 0}m ${item.duration_minutes || 0}m`,
          }));

    const arrayToConvert = [titles, ...data];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('download', `driver-average-wait-time-${moment().format('YYYY-MM-DD')}`);
    link.href = url;
    link.click();
  };

  const handleSelectChange = (e, value, reason, details, name) => {
    if (details?.option?.id === 'all') {
      if (reason === 'selectOption') {
        setFilters((prevState) => ({
          ...prevState,
          [name]: name === 'drivers' ? drivers : vehicles,
          averageRange: [null, null],
        }));
        return;
      }

      if (reason === 'removeOption') {
        setFilters((prevState) => ({ ...prevState, [name]: [], averageRange: [null, null] }));
        return;
      }
    }

    setFilters((prevState) => ({ ...prevState, [name]: value, averageRange: [null, null] }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onFilterTypeChange = (name, checked) => {
    if (loading) {
      return;
    }

    if (filters.stopPoint) {
      setLoading(true);
    }
    setFilters((prevState) => ({ ...prevState, averageRange: [null, null] }));
    if (name === 'summary') {
      setType(checked ? 'summary' : 'detailed');
      setSort({ field: checked ? 'wait_time' : 'arrived_date', sortBy: 'desc' });
    } else {
      setType(checked ? 'detailed' : 'summary');
      setSort({ field: checked ? 'arrived_date' : 'wait_time', sortBy: 'desc' });
    }
  };

  useEffect(() => {
    if (stopPointId || !filters.stopPoint || !vehicles?.length) {
      return;
    }
    if (type === 'detailed') {
      getReportDetailedData();
    } else {
      getReportSummaryData();
    }
  }, [dateRange, filters, filterType, type, vehicles]);

  useEffect(() => {
    getDriversList();
    getStopPoints();
    getVehicles();
  }, []);

  return (
    <PageWrapper title='Arrivals and Departures' hideTitle={!!stopPoint}>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName={stopPointId ? 'Last 30 Days' : stopPoint ? 'Last 7 Days' : 'All Time'}
              dateRange={dateRange}
              setDateRange={setDateRange}
              type='pastDates'
            />
            {!stopPoint && (
              <Autocomplete
                width='200px'
                labelKey='location_name'
                options={stopPoints}
                placeholder='Select Stop Point..'
                value={filters.stopPoint}
                onChange={(e, val) =>
                  setFilters((prevState) => ({ ...prevState, stopPoint: val, averageRange: [null, null] }))
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            )}
            <div className='d-flex align-items-center gap-1'>
              <CustomCheckbox
                type='switch'
                checked={filterType === 'driver'}
                onChange={(checked) => {
                  setFilters((prevState) => ({ ...prevState, averageRange: [null, null] }));
                  setFilterType(checked ? 'driver' : 'equipment');
                }}
                smail={false}
              />
              <Autocomplete
                multiple
                width='350px'
                placeholder={filters.drivers.length ? '' : 'Select Driver..'}
                limitTags={1}
                options={[{ id: 'all', name: 'All Drivers' }, ...drivers]}
                value={filters.drivers}
                onChange={(e, val, reason, details) => handleSelectChange(e, val, reason, details, 'drivers')}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id || (option.id === 'all' && drivers.length === filters.drivers?.length)
                }
                disabled={filterType !== 'driver'}
                className='multi-select'
                disableClearable={false}
              />
            </div>
            <div className='d-flex align-items-center gap-1'>
              <CustomCheckbox
                type='switch'
                checked={filterType === 'equipment'}
                onChange={(checked) => {
                  setFilters((prevState) => ({ ...prevState, averageRange: [null, null] }));
                  setFilterType(checked ? 'equipment' : 'driver');
                }}
                smail={false}
              />
              <Autocomplete
                multiple
                width='400px'
                placeholder={filters.equipment.length ? '' : 'Select Equipment..'}
                limitTags={1}
                options={[{ id: 'all', name: 'All Equipment' }, ...vehicles]}
                value={filters.equipment}
                onChange={(e, val, reason, details) => handleSelectChange(e, val, reason, details, 'equipment')}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id || (option.id === 'all' && vehicles.length === filters.equipment?.length)
                }
                disabled={filterType !== 'equipment'}
                className='multi-select'
                disableClearable={false}
              />
            </div>
            <div className='d-flex align-items-center gap-4'>
              <div className='d-flex align-items-center gap-1'>
                <CustomCheckbox
                  type='switch'
                  checked={type === 'detailed'}
                  onChange={(checked) => onFilterTypeChange('detailed', checked)}
                  smail={false}
                />
                <Typography variant='s2'>Detailed</Typography>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <CustomCheckbox
                  type='switch'
                  checked={type === 'summary'}
                  onChange={(checked) => onFilterTypeChange('summary', checked)}
                  smail={false}
                />
                <Typography variant='s2'>Summary</Typography>
              </div>
            </div>
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportData?.length === 0 || loading}
          />
        </STableFilters>
        <WaitTimeGraph
          vehicles={vehicles}
          drivers={drivers}
          filters={filters}
          dateRange={dateRange}
          type={filterType}
          setFilters={setFilters}
        />
        <Divider margin='16px 0 36px' />
        <Header
          title={
            filters.stopPoint && !loading
              ? `Result ${filteredReports.length} ${filterType === 'equipment' ? 'Vehicle' : 'Driver'}${
                  filteredReports.length === 1 ? '' : 's'
                }`
              : 'Result'
          }
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={reportData.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        {loading ? (
          <TableSkeleton rowCount={3} />
        ) : (
          <div className='table-container'>
            {type === 'summary' && (
              <SummaryTable reportData={filteredReports} type={filterType} sort={sort} sortingQuery={sortingQuery} />
            )}
            {type === 'detailed' && (
              <DetailedTable reportData={filteredReports} type={filterType} sort={sort} sortingQuery={sortingQuery} />
            )}
          </div>
        )}
      </STableWrapper>
      {!loading && !reportData?.length && (
        <NoRecords text={!filters.stopPoint ? 'Please select stop point!' : 'No Records'} />
      )}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Arrivals and Departures' />
      )}
    </PageWrapper>
  );
};

export default AverageWaitTime;
