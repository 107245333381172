import React, { forwardRef } from 'react';
import searchIcon from '../../assets/icons/header/Search.png';
import classes from './SearchAutoFill.module.scss';
import { SWrapper } from './Search.styles';
import Autocomplete from '../Autocomplete';

const SearchAutoFill = forwardRef(({ search, onChange, margin, options }, ref) => {
  return (
    <SWrapper $margin={margin}>
      <Autocomplete
        options={options || []}
        onInputChange={(e, val) => {
          onChange(val);
        }}
        inputValue={search}
        placeholder='Search'
        className={classes.customSearchAutofill}
        ref={ref}
        disableClearable={false}
      />
      <img src={searchIcon} alt='Search' className='search-icon' />
    </SWrapper>
  );
});

export default SearchAutoFill;
