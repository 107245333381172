import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  addOrUpdateStaticDocsByToken,
  checkCarrierToken,
  createCarrierPayTermByToken,
  createContactBookByToken,
  createSingleDocument,
  getBrokerageSettings,
  getCarrierByToken,
  getCarrierContactBookByToken,
  getCarrierDocumentsByToken,
  getCarrierPayTermsByToken,
  getCarrierStaticDocumentsByToken,
  updateCarrierByToken,
  updateCarrierPayTermByToken,
  updateContactBook,
  updateSingleDocument,
} from 'Api/Carriers';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import Final from './steps/Final';
import BasicInfo from './steps/BasicInfo';
import StepTitle from './components/StepTitle';
import OnboardCarrierHeader from './components/OnboardCarrierHeader';
import OnboardCarrierFooter from './components/OnboardCarrierFooter';
import {
  masterAgreementValidationSchema,
  indemnityValidationSchema,
  contactBookValidationSchema,
  basicInfoValidationSchema,
  payTermValidationSchema,
  documentsValidationSchema,
  operationsValidationSchema,
} from './validationSchemas';
import {
  getBasicInfoInitialValues,
  basicInfoInitialValues,
  getPayTermsInitialValues,
  getDocumentsInitialValues,
  getContactBookInitialValues,
  getIndemityInitialValues,
  getMasterInitialValues,
  getPayTermsDefault,
  getOperationsInitialValues,
} from './OnboardCarrier.data';
import PayTerms from './steps/PayTerms';
import Documents from './steps/Documents';
import ContactBook from './steps/ContactBook';
import MasterAgreement from './steps/MasterAgreement';
import IndemityAgreement from './steps/IndemityAgreement';
import { generateIndemityPdf } from './steps/IndemityAgreement/helper';
import { generateMasterPdf } from './steps/MasterAgreement/helper';
import Operations from './steps/Operations';
import { SWrapper, SStepperContent, ScrollableContainer } from './OnboardCarrier.styles';
import PublicFooter from '../../componentsV2/PublicFooter';

const OnboardCarrier = () => {
  const showToaster = useShowToaster();
  const [company, setCompany] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [contactBook, setContactBook] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [staticDocuments, setStaticDocuments] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { dot, token } = useParams();
  const signPad = useRef({});
  const interchangeSignPad = useRef({});

  const onNext = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const onBack = () => {
    if (currentStep === 0) {
      return;
    }

    setCurrentStep((prevState) => prevState - 1);
  };

  const getCompanyInfo = async () => {
    try {
      const response = await getBrokerageSettings(dot, token);
      setCompany(response.data);

      const currentCarrier = await getCarrierByToken(dot, token);
      setCarrier(currentCarrier?.data);

      const currentStaticDocuments = await getCarrierStaticDocumentsByToken(dot, token, currentCarrier?.data?.id);
      setStaticDocuments(currentStaticDocuments?.data);

      const tokenData = await checkCarrierToken(dot, token);

      if (tokenData?.data?.used) {
        if (currentCarrier.data.require_trailer_interchange) {
          setCurrentStep(7);
        } else {
          setCurrentStep(6);
        }
      }

      const currentDocuments = await getCarrierDocumentsByToken(dot, token, currentCarrier?.data?.id);
      setDocuments(currentDocuments?.data);

      const currentContactBook = await getCarrierContactBookByToken(dot, token);
      setContactBook(currentContactBook?.data);

      const currentPaymentTerms = await getCarrierPayTermsByToken(dot, token, currentCarrier?.data?.id);
      setPaymentTerms(currentPaymentTerms?.data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleOperationsSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        operating_states: values.operating_states?.length ? values.operating_states.map((item) => item.id) : null,
        equipment_types: values.equipment_types?.length ? values.equipment_types.map((item) => item.id) : null,
      };

      updateCarrierByToken(dot, token, data).then(() => {
        onNext();
      });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleBasicInfoSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append('logo', values.logo);
      formData.append('name', values.name);
      formData.append('dot', values.dot);
      formData.append('scac', values.scac);
      formData.append('phone_number', values.phoneNumber);
      formData.append('email', values.email);
      formData.append('address1', values.address1);
      formData.append('address2', values.address2);
      formData.append('city_id', values.city.id);
      formData.append('state_id', values.state.id);
      formData.append('country_id', values.country.id);
      formData.append('mc', values.mc || '');
      formData.append('zipcode', values.zipCode);
      if (values.can_travel_to.length) {
        values.can_travel_to.forEach((item, index) => {
          formData.append(`can_travel_to[${index}]`, item);
        });
      } else {
        formData.append(`can_travel_to`, '');
      }
      if (values.approved_for.length) {
        values.approved_for.forEach((item, index) => {
          formData.append(`approved_for[${index}]`, item);
        });
      } else {
        formData.append(`approved_for`, '');
      }
      if (values.approved_for?.includes('4')) {
        formData.append('approved_for_custom', values.approved_for_custom);
      }
      updateCarrierByToken(dot, token, formData).then((res) => {
        setCarrier((prevState) => {
          return {
            ...prevState,
            ...res.data,
          };
        });
        onNext();
      });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleContactBookSubmit = async (values) => {
    setLoading(true);
    try {
      const contactsToCreate = values.filter((i) => !!i.isNew);
      const contactsToEdit = values.filter((i) => !i.isNew);
      const contacts = contactsToCreate.map((item) => {
        return {
          contact_name: item.contact_name,
          phone_number: item.phone_number,
          contact_type_id: item.contact_type_id?.id,
          contact_email: item.contact_email,
          receive_offers: Number(item.receive_offers),
          check_calls: Number(item.check_calls),
        };
      });

      const data = {
        carrier_id: carrier.id,
        contact_books: contacts,
      };

      if (contactsToCreate.length) {
        await createContactBookByToken(dot, token, data);
      }
      if (contactsToEdit.length) {
        const promises = contactsToEdit.map((item) => {
          const body = {
            contact_name: item.contact_name,
            phone_number: item.phone_number,
            contact_type_id: item.contact_type_id?.id,
            contact_email: item.contact_email,
            receive_offers: Number(item.receive_offers),
            check_calls: Number(item.check_calls),
          };
          return updateContactBook(dot, token, item.id, body);
        });
        await Promise.all(promises);
      }
      // const currentCarrier = await getCarrierByToken(dot, token);
      // setCarrier(currentCarrier?.data);
      const currentContactBook = await getCarrierContactBookByToken(dot, token);
      setContactBook(currentContactBook?.data);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const handlePayTermsSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        carrier_id: carrier.id,
        invoice_pay_term: values.invoicePayTerm?.id,
        pay_factoring: Number(values.payFactoring),
        credit_line_maximum: values.creditLineMaximum,
        factoring_company: values.factoringCompany,
        phone_number: values.phone_number,
        email: values.email,
        address1: values.address,
        address2: values.address2,
        country_id: values.country?.id,
        state_id: values.state?.id,
        city_id: values.city?.id,
        zipcode: values.zipcode,
        notice_of_assignment: values.noticeOfAssignment,
        quick_pay: Number(values.quick_pay),
        payment_method: values.payment_method,
        bank_name: values.bank_name || '',
        bank_routing: values.bank_routing || '',
        bank_account: values.bank_account || '',
        bank_direct_deposit_form: values.bank_direct_deposit_form || '',
        third_party_name: values.third_party_name || '',
        third_party_id: values.third_party_id || '',
        bank_account_verified: values.bank_account_verified,
        bank_account_mismatch_reason: values.bank_account_verified === 2 ? values.bank_account_mismatch_reason : null,
      };

      if (values.payment_method === 'ach' && !values.bank_name) {
        showToaster({ type: 'error', message: 'Connect account to provide' });
        setLoading(false);
        return;
      }

      if (!paymentTerms) {
        createCarrierPayTermByToken(dot, token, data)
          .then((res) => {
            onNext();
            setPaymentTerms(res.data);
          })
          .catch((err) => {
            showToaster({ type: 'error', message: getErrorMessage(err) });
          });
      } else {
        updateCarrierPayTermByToken(dot, token, data, paymentTerms.id)
          .then((res) => {
            onNext();
            setPaymentTerms(res.data);
          })
          .catch((err) => {
            showToaster({ type: 'error', message: getErrorMessage(err) });
          });
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleDocumentsSubmit = async (values) => {
    setLoading(true);
    try {
      const documentsToCreate = values?.documents?.filter((item) => item.isNew);
      const documentsToEdit = values?.documents?.filter((item) => !item.isNew);

      const formData = new FormData();
      formData.append(`carrier_id`, carrier.id);
      formData.append(`w9`, values.w9);
      formData.append(`authority`, values.authority);
      formData.append(`reference_sheet`, values.reference_sheet);
      formData.append(`liability_insurance`, values.liability_insurance);
      formData.append('liability_insurance_exp_date', moment(values.liability_insurance_exp_date).format('YYYY/MM/DD'));
      formData.append(`cargo_insurance`, values.cargo_insurance);
      formData.append('cargo_insurance_exp_date', moment(values.cargo_insurance_exp_date).format('YYYY/MM/DD'));
      formData.append(`general_liability`, values.general_liability);
      formData.append('general_liability_exp_date', moment(values.general_liability_exp_date).format('YYYY/MM/DD'));

      await addOrUpdateStaticDocsByToken(dot, token, formData);

      if (documentsToCreate.length) {
        const promises = documentsToCreate.map((item) => {
          const docFormData = new FormData();

          docFormData.append(`carrier_id`, carrier.id);
          docFormData.append(`name`, item.name);
          docFormData.append(
            `expiration_date`,
            item.no_expiration ? '' : moment(item.expiration_date).format('YYYY/MM/DD')
          );
          docFormData.append(`file`, item.file);

          return createSingleDocument(dot, token, docFormData);
        });
        await Promise.all(promises);
      }
      if (documentsToEdit.length) {
        const promises = documentsToEdit.map((item) => {
          const docFormData = new FormData();

          docFormData.append(`name`, item.name);
          docFormData.append(`expiration_date`, moment(item.expiration_date).format('YYYY/MM/DD'));
          docFormData.append(`file`, item.file);
          docFormData.append(
            `expiration_date`,
            item.no_expiration ? '' : moment(item.expiration_date).format('YYYY/MM/DD')
          );

          return updateSingleDocument(dot, token, item.id, docFormData);
        });
        await Promise.all(promises);
      }

      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const handleMasterSubmit = async (values) => {
    if (!values.signature) {
      showToaster({ type: 'error', message: 'Signature is required!' });
      return;
    }
    setLoading(true);
    signPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const { blob } = await generateMasterPdf({
            company: { ...company, address: company.address1 },
            carrier,
            values: { ...values, signature: reader.result },
          });
          const formData = new FormData();
          formData.append('carrier_id', carrier.id);
          formData.append('master_agreement', blob);
          if (!carrier.require_trailer_interchange) {
            formData.append('last_step', 1);
          }
          await addOrUpdateStaticDocsByToken(dot, token, formData);
          setLoading(false);
          masterForm.handleChange('signature', null);
          onNext();
        } catch (e) {
          showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
          setLoading(false);
        }

        // handleChange('signature', reader.result);
      };
    }, 'image/png');
  };

  const handleIndemitySubmit = async (values) => {
    if (!values.signature) {
      showToaster({ type: 'error', message: 'Signature is required!' });
      return;
    }

    interchangeSignPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          setLoading(true);
          const { blob } = await generateIndemityPdf({
            company: { ...company, address: company.address1 },
            carrier,
            values: { ...values, signature: reader.result },
          });
          const formData = new FormData();
          formData.append('carrier_id', carrier.id);
          formData.append('trailer_interchange_and_indemnity_agreement', blob);
          formData.append('last_step', 1);
          await addOrUpdateStaticDocsByToken(dot, token, formData);
          setLoading(false);
          onNext();
        } catch (e) {
          showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
          setLoading(false);
        }
      };
    }, 'image/png');
  };

  const basicInfoForm = useForm({
    initialValues: carrier ? getBasicInfoInitialValues(carrier) : basicInfoInitialValues,
    validationSchema: basicInfoValidationSchema,
    onSubmit: handleBasicInfoSubmit,
    enableReinitialize: true,
  });

  const operationsForm = useForm({
    initialValues: getOperationsInitialValues(carrier),
    validationSchema: operationsValidationSchema,
    onSubmit: handleOperationsSubmit,
    enableReinitialize: true,
  });

  const contactBookForm = useForm({
    initialValues: getContactBookInitialValues(contactBook),
    validationSchema: contactBookValidationSchema,
    onSubmit: handleContactBookSubmit,
    enableReinitialize: true,
  });

  const payTermsForm = useForm({
    initialValues: paymentTerms ? getPayTermsInitialValues(paymentTerms, company) : getPayTermsDefault(company),
    validationSchema: payTermValidationSchema,
    onSubmit: handlePayTermsSubmit,
    enableReinitialize: true,
  });

  const documentsForm = useForm({
    initialValues: getDocumentsInitialValues(staticDocuments, documents),
    validationSchema: documentsValidationSchema,
    onSubmit: handleDocumentsSubmit,
    enableReinitialize: true,
  });

  const masterForm = useForm({
    initialValues: getMasterInitialValues(carrier),
    onSubmit: handleMasterSubmit,
    validationSchema: masterAgreementValidationSchema,
    enableReinitialize: true,
  });

  const indemityForm = useForm({
    initialValues: getIndemityInitialValues(carrier),
    onSubmit: handleIndemitySubmit,
    validationSchema: indemnityValidationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const steps = [
    {
      key: 'basicInfo',
      title: 'Basic Details',
      onNext: basicInfoForm.handleSubmit,
      Component: <BasicInfo form={basicInfoForm} carrier={carrier} />,
    },
    {
      key: 'operations',
      title: 'Operations',
      onNext: operationsForm.handleSubmit,
      Component: <Operations form={operationsForm} carrier={carrier} />,
    },
    {
      key: 'contactBook',
      title: 'Contact Book',
      Component: <ContactBook form={contactBookForm} />,
      onNext: contactBookForm.handleSubmit,
      width: '80%',
    },
    {
      key: 'payTerms',
      title: 'Payment Terms',
      Component: <PayTerms form={payTermsForm} company={company} carrierId={carrier?.id} carrier={carrier} />,
      onNext: payTermsForm.handleSubmit,
      width: '80%',
    },
    {
      key: 'documents',
      title: 'Documents',
      Component: <Documents form={documentsForm} loading={loading} documents={documents} company={company} />,
      onNext: documentsForm.handleSubmit,
      width: '80%',
    },
    {
      key: 'masterAgreement',
      title: 'Motor Carrier Master Agreement',
      onNext: masterForm.handleSubmit,
      Component: <MasterAgreement company={company} carrier={carrier} form={masterForm} signPad={signPad} />,
    },
    ...(carrier?.require_trailer_interchange
      ? [
          {
            key: 'indemityAgreement',
            title: 'Trailer Interchange and Indemnity Agreement',
            onNext: indemityForm.handleSubmit,
            Component: (
              <IndemityAgreement company={company} carrier={carrier} form={indemityForm} signPad={interchangeSignPad} />
            ),
          },
        ]
      : []),

    { key: 'finalPage', Component: <Final company={company} carrier={carrier} /> },
  ];

  return (
    <SWrapper $bgColor={palette.gray0}>
      <OnboardCarrierHeader
        company={company}
        progress={(currentStep + 1) * (carrier?.require_trailer_interchange ? 12.6 : 14.3)}
      />
      <ScrollableContainer>
        <SStepperContent
          width={steps[currentStep].width}
          $minWidth={steps[currentStep].minWidth}
          $bgColor={currentStep === 5 || currentStep === 6 ? 'white' : ''}
        >
          {steps[currentStep].title && (
            <StepTitle carrier={carrier} currentStep={currentStep} title={steps[currentStep].title} />
          )}
          {steps[currentStep].Component}
        </SStepperContent>
        {currentStep !== steps.length - 1 && (
          <>
            <OnboardCarrierFooter
              step={currentStep}
              stepsCount={steps.length}
              onBack={onBack}
              onNext={steps[currentStep].onNext || onNext}
              disabled={loading}
            />
            <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, marginTop: 10 }}>
              <PublicFooter />
            </div>
          </>
        )}
      </ScrollableContainer>
    </SWrapper>
  );
};

export default OnboardCarrier;
