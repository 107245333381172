import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .header-item:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 160px;
  }

  .header-item:nth-child(2) {
    width: 80px;
  }

  .header-item:nth-child(3) {
    width: 80px;
  }

  .header-item:nth-child(4) {
    width: 100px;
  }
`;

export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid ${palette.gray50};
  background: #f7f9fc;

  .flex-item:nth-child(1) {
    width: 160px;
  }

  .flex-item:nth-child(2) {
    width: 80px;
  }

  .flex-item:nth-child(3) {
    width: 80px;
  }

  .flex-item:nth-child(4) {
    width: 100px;
  }
`;
