import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import { BackdropLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import { formatAmount } from 'utils/helpers';
import { palette } from 'utils/constants';
import { getSettlementPdf } from 'Api/Payroll';
import ViewPdf from 'components/ViewPdf';
import FormRow from './FormRow';
import { STable } from './EditBalance.styles';

const EditBalance = ({ balance, open, onClose, userType, onSuccess }) => {
  const { currency } = useSelector((state) => state.root);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const hasSettlements = !!balance?.deductions?.[0]?.settlement?.length;
  const processedSettlements = balance.deductions?.[0]?.settlement?.filter(
    (settlement) => settlement[`${userType}_settlement`].status_id === 3
  );

  const onOpenSettlementPdf = async (settlementId) => {
    try {
      setLoading(true);
      const { data } = await getSettlementPdf(settlementId);
      setPdfUrl(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Balance Details'
      $bgColor={palette.gray0}
      $maxWidth='1200px'
      $minWidth='1200px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      <div className='d-flex flex-column gap-5'>
        <div className='d-flex align-items-center justify-content-between me-2'>
          <div className='d-flex flex-column'>
            <InputLabel variant='button2'>Name</InputLabel>
            <Typography variant='s2'>{balance.name}</Typography>
          </div>
          <div className='d-flex flex-column'>
            <InputLabel variant='button2'>Type</InputLabel>
            <Typography variant='s2'>{balance.type}</Typography>
          </div>
          <div className='d-flex flex-column'>
            <InputLabel variant='button2'>Original Balance</InputLabel>
            <Typography variant='s2'>
              {currency}
              {formatAmount(balance.original_balance)}
            </Typography>
          </div>
          <div className='d-flex flex-column'>
            <InputLabel variant='button2'>Payment Amount</InputLabel>
            <Typography variant='s2'>
              {currency}
              {formatAmount(balance.payment_amount)}
            </Typography>
          </div>
          <div className='d-flex flex-column'>
            <InputLabel variant='button2'>Payments</InputLabel>
            <Typography variant='s2'>
              {(processedSettlements || []).length} out of {balance.amortized_months}
            </Typography>
          </div>
          <div className='d-flex flex-column'>
            <InputLabel variant='button2'>Term</InputLabel>
            <Typography variant='s2'>
              {balance.month_week_settlement
                ? `Every ${balance.day} of the ${balance.month_week}`
                : `Over ${balance.amortized_months} settlements`}
            </Typography>
          </div>
        </div>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>PAYMENT #</th>
              <th className='centered'>REFERENCE</th>
              <th>PAYMENT</th>
              <th className='centered'>EXTRA PAYMENT</th>
              <th className='centered'>LESS PAYMENT</th>
              <th>STATUS</th>
              <th>BALANCE</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr className='body-row'>
              <td style={{ width: '5%' }}>{!hasSettlements && '-'}</td>
              <td className='centered' style={{ width: '25%' }}>
                {!hasSettlements && '-'}
              </td>
              <td style={{ width: '10%' }}>{!hasSettlements && '-'}</td>
              <td className='centered' style={{ width: '15%' }}>
                {!hasSettlements && '-'}
              </td>
              <td className='centered' style={{ width: '15%' }}>
                {!hasSettlements && '-'}
              </td>
              <td style={{ width: '10%' }}>{!hasSettlements && '-'}</td>
              <td style={{ width: '10%' }}>
                <Typography variant='s2' style={{ display: 'inline-block' }}>
                  {currency}
                  {formatAmount(balance.original_balance)}
                </Typography>
              </td>
              <td style={{ width: '10%' }} />
            </tr>
            {(balance?.deductions?.[0]?.settlement || [])?.map((settlement, index) => (
              <FormRow
                key={settlement.id}
                balance={balance}
                settlement={settlement}
                index={index}
                userType={userType}
                onSuccess={onSuccess}
                onOpenSettlementPdf={onOpenSettlementPdf}
              />
            ))}
          </tbody>
        </STable>
      </div>
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Settlement' />}
      <BackdropLoader loading={loading} />
    </Modal>
  );
};

export default EditBalance;
