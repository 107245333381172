import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import Loader from 'common/Loader';
import Search from 'common/Search';
import ColumnHeader from 'common/ColumnHeader';
import HeaderStar from 'components/HeaderStar';
import { Typography } from 'components/Typography';
import QuickbooksSync from 'componentsV2/QuickbooksSync';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getVendorsList } from 'Api/Vendors';

import VendorCard from '../components/VendorCard';
import AddVendor from '../components/AddVendor';
import { SCardsHeader, SWrapper } from './LeftSection.styles';
import { filterVendors } from './helpers';
import { SHeaderWrapper } from '../Vendors.styles';

const LeftSection = ({ activeVendor, onVendorClick, vendorToEdit, setVendorToEdit, refreshIndex }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const openCreate = searchParams.get('openCreate');
  const [openAddVendor, setOpenAddVendor] = useState(openCreate || false);
  const [sort, setSort] = useState({ field: 'total_balance', sortBy: 'desc' });
  const [filters, setFilters] = useState({ search: '', hideInactive: true });
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);

  const filteredVendors = useMemo(() => {
    const { search, hideInactive } = filters;
    return filterVendors(vendors, { search, sort, hideInactive });
  }, [vendors, filters, sort]);

  const onFilterChange = (key, value) => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  const getVendors = async () => {
    try {
      setLoading(true);
      const { data } = await getVendorsList();
      setVendors(data || []);
      if (data.length > 0) {
        const sortedVendors = data.sort((a, b) => b.total_balance - a.total_balance);
        if (!activeVendor) {
          onVendorClick(sortedVendors[0]);
        } else {
          onVendorClick(sortedVendors.find((item) => item.id === activeVendor.id) || activeVendor);
        }
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getVendors();
  }, [refreshIndex]);

  useEffect(() => {
    searchParams.delete('openCreate');
    setSearchParams(searchParams);
  }, []);

  return (
    <SWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Vendors' />
        <div className='right-section' style={{ marginLeft: '8px' }}>
          {activeVendor && (
            <CustomButton
              title='Print Report'
              type='secondary'
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              leftIcon={<PrintIcon className='me-2' />}
              styleButton={{ padding: '6px 12px', margin: 0 }}
              onClick={() => navigate('/reports/31')}
            />
          )}
          <CustomButton
            title='Add'
            type='primary'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            leftIcon={<PlusIcon fill={palette.white} className='me-2' />}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={() => setOpenAddVendor(true)}
          />
          <QuickbooksSync action='vendor' onSuccess={getVendors} />
        </div>
      </SHeaderWrapper>
      <div className='mb-2'>
        <Search search={filters.search} onChange={(val) => onFilterChange('search', val)} className='w-100' />
      </div>
      <div>
        <CustomCheckbox
          id='hideInactive'
          checked={filters.hideInactive}
          onChange={(checked) => onFilterChange('hideInactive', checked)}
        >
          <Typography variant='s2' style={{ marginLeft: 5 }}>
            Hide Inactive
          </Typography>
        </CustomCheckbox>
      </div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>
          <SCardsHeader>
            <ColumnHeader title='NAME' field='name' sort={sort} onClick={sortingQuery} />
            <ColumnHeader title='BALANCE' field='total_balance' sort={sort} onClick={sortingQuery} />
          </SCardsHeader>
          {!filteredVendors?.length && (
            <div className='text-center mt-1'>
              <Typography variant='s2' style={{ color: palette.gray600 }}>
                No data
              </Typography>
            </div>
          )}
          {filteredVendors?.map((item) => (
            <VendorCard
              data={item}
              key={item.id}
              onClick={() => onVendorClick(item)}
              hideBalances={filters.hideBalances}
              className={activeVendor?.id === item.id ? 'selected' : ''}
            />
          ))}
        </div>
      )}
      {(openAddVendor || !!vendorToEdit) && (
        <AddVendor
          open={openAddVendor || !!vendorToEdit}
          onClose={() => {
            setOpenAddVendor(false);
            setVendorToEdit(null);
          }}
          onSuccess={getVendors}
          vendor={vendorToEdit}
        />
      )}
    </SWrapper>
  );
};

export default LeftSection;
