export const planType = {
  monthly: {
    financingText: 'Financing is not available for monthly plans, only for yearly plans.',
    creditCardText:
      'If you choose to pay with a credit card, you are pre-paid for 1 month of service. If paying with credit card, 2.9% + $0.30 processing fee applies.',
    achText: 'If you choose to pay with Credit Card or ACH, you are pre-paid for 1 month of service.',
    infoText1: 'Monthly plans are pre-paid per staff and driver user.',
    infoText2:
      'Invoice will be emailed to you separately, your 1-month subscription starts when you make payment. At the end of the license period, license will automatically renew every 1-month and you will be charged the then-current price. You may cancel at any time from your account.',
    recurring: 'Recurring Monthly',
    recurring2: 'Recurring Plan - Monthly',
    recurringAddon: 'Recurring Addon - Monthly',
    monthsCount: 1,
    label: 'every month',
    addonBillTitle: 'billed monthly',
  },
  yearly: {
    financingText:
      'If you choose to pay with the financing option, you will be making monthly payments for 1 year. Varies on credit approval.',
    creditCardText:
      'If you choose to pay with a credit card, you are pre-paid for 1 year of service. If paying with credit card, 2.9% + $0.30 processing fee applies.',
    achText: 'If you choose to pay with Credit Card or ACH, you are pre-paid for 1 year of service.',
    infoText1: 'Yearly plans are pre-paid per staff and driver user.',
    infoText2:
      'Invoice will be emailed to you separately, your 1-year subscription starts when you make payment. At the end of the license period, license will automatically renew every 1-year and you will be charged the then-current price. You may cancel at any time from your account.',
    recurring: 'Recurring Yearly',
    recurring2: 'Recurring Plan - 12 Months',
    recurringAddon: 'Recurring Addon - 12 Months',
    monthsCount: 12,
    label: 'every year',
    addonBillTitle: 'per month billed for 1 year',
  },
  '3-Year': {
    financingText:
      'If you choose to pay with the financing option, you will be making monthly payments for 36 months. Varies on credit approval.',
    creditCardText:
      'If you choose to pay with Credit Card, you must pay for the order up front for 36 months. If paying with credit card, 2.9% + $0.30 processing fee applies.',
    achText: 'If you choose to pay with Credit Card or ACH, you must pay for the order up front for 36 months.',
    infoText1: '3-Year plans are pre-paid per staff and driver user.',
    infoText2:
      'Invoice will be emailed to you separately, your 3-year subscription starts when you make payment. At the end of the license period, license will automatically renew every 3-years and you will be charged the then-current price. You may cancel at any time from your account.',
    recurring: 'Recurring Every 3 Years',
    recurring2: 'Recurring Plan - 36 Months',
    recurringAddon: 'Recurring Addon - 36 Months',
    monthsCount: 36,
    label: 'every 3 years',
    addonBillTitle: 'per month billed for 3 years',
  },
};
