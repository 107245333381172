import React, { useState, useEffect, useRef, createContext } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import axios from 'axios';

import { MaintanceCost, ShipmentId } from 'Api/Equipment';
import { Icons } from 'assets/icons';
import useShowToaster from 'hooks/useShowToaster';
import CustomModal from 'components/CustomModal/CustomModal';
import authHeader from 'services/auth-header';
import { motionColors, palette, STOP_POINT_TAB } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import Edit from 'assets/icons/drivers/edit.svg';
import down from 'assets/icons/drivers/down.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as Motion } from 'assets/icons/motion.svg';
import { Typography } from 'components/Typography';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import EquipmentUpdateModal from 'components/EquipmentStopPoint/EquipmentUpdateModal/EquipmentUpdateModal';
import CheckoutTrailer from 'components/TableShipments/detailsRow/steps/Detention/CheckoutTrailer';
import useDateFormat from 'hooks/useDateFormat';
import { formatSeconds } from 'utils/helpers';
import Chip from 'common/Chip';
import { PusherJs } from 'common/pusher';
import { renderTooltip } from 'common/Tooltip';
import { getEquipmentProfile } from 'Api/EquipmentProfile';
import EquipmentLogs from 'pages/landing/Equipment/EquipmentProfile/EquipmentLogs';
import { trailerStatus } from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileTrailer/Trailer.data';
import { onChangeInventoryTab } from 'store/reducers/stopPoint.reducer';
import MotionsLogs from '../MotionsLogs';
import Map from '../EquipmentProfileComponents/Map/Map';
import AddEquipmentNotes from '../EquipmentProfileComponents/AddNotes';
import UpdateEquipmentID from '../../DialogueModals/AddDriver/UpdateEquipmentID';
import CustomizedSnackbars from '../EquipmentProfileComponents/EquipmentDocument/Snackbar';
import Trailer from '../EquipmentProfileComponents/EquipmentMain/Trailer';
import MaintenanceCost from '../EquipmentProfileComponents/MaintenanceCost/MaintenanceCost';
import DateRange from '../EquipmentProfileComponents/EquipmentMain/DateRangePicker/DateRangePicker';
import OutOfServiceStatus from '../EquipmentProfileComponents/EquipmentMain/EquipmentStatus/OutOfService';
import DropTrailerStatus from '../EquipmentProfileComponents/EquipmentMain/EquipmentStatus/DropTrailerStatus';
import '../equipmentdetails.css';
import style from '../EquipmentProfile.module.css';

export const EquipmentDetailsContext2 = createContext();

const EquipmentDetails = () => {
  const params = useParams();
  const { convertToCustomerTime } = useDateFormat();
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const [show, setShow] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mapLayersValues, setMapLayersValues] = useState('BASIC');
  const [profileData, setProfileData] = useState({});
  const [target, setTarget] = useState(null);
  const [hookTo, setHookTo] = useState();
  const [driverSpeed, setDriverSpeed] = useState(0);
  const [connectedServicesData, setConnectedServicesData] = useState();
  const [mapInstance, setMapInstance] = useState(null);
  const [resetMyRoute, setResetMyRoute] = useState(null);
  const [loading, setLoading] = useState();
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [motionLogsOpen, setMotionLogsOpen] = useState(false);
  const [reloadMap, setReloadMap] = useState(null);
  const [updateEquipmentID, setUpdateEquipmentID] = useState();
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [MaintanceCostValue, setMaintananceCostValue] = useState(null);
  const [openoutofservice, setOutOfServiceOpen] = useState(false);
  const [opendroptrailer, setDropTrailerOpen] = useState(false);
  const handleDropTrailerOpen = () => setDropTrailerOpen(true);
  const handleDropTrailerClose = () => setDropTrailerOpen(false);
  const [timeLine, setTimeLine] = useState(false);
  const [openUpdateDropTrailer, setUpdateDropTrailerOpen] = useState(false);
  const handleUpdateDropTrailerOpen = () => setUpdateDropTrailerOpen(true);
  const handleUpdateDropTrailerClose = () => setUpdateDropTrailerOpen(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);

  const Maplayers = (event) => {
    setAnchorEl(event.currentTarget);
    // setOpen((previousOpen) => !previousOpen);
  };
  const timeLineOpen = () => {
    setTimeLine(true);
  };
  const timeLineClose = () => {
    setTimeLine(false);
    setIsCenter(false);
    setRangeName('Live');
    setDateRange({
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
    });
    resetMyRoute?.remove();
  };
  const mapLayersValue = (value) => {
    setMapLayersValues(value);
    MapLayersHandleClose();
  };
  const MapLayersHandleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const map = open ? 'simple-popover' : undefined;
  const [openmaintenencecost, setOpenMaintenanceCost] = useState(false);
  const handleMaintenanceCostOpen = () => setOpenMaintenanceCost(true);
  const handleMaintenanceCostClose = () => setOpenMaintenanceCost(false);
  const [profile, setProfile] = useState();
  const [rangeName, setRangeName] = useState('Live');
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);
  const [isCenter, setIsCenter] = useState(false);
  const { use } = useTheme();
  const API_URL = process.env.REACT_APP_API_URL;
  const [allShipmentId, setAllShipmentId] = useState([]);

  const handleClearOutOfService = () => {
    const value = {
      status_id: profileData.hook_to ? 7 : 4,
      drop_trailer_cargo_onboard_id: null,
      drop_trailer_shipment_id: null,
      drop_trailer_stop_point_id: null,
    };
    try {
      setLoading(true);
      axios
        .put(`${API_URL}/equipment/equipment-status/${profileData?.id}`, value, {
          headers: authHeader(),
        })
        .then(() => {
          init();
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const ref = useRef(null);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  const handleOutOfServiceOpen = () => setOutOfServiceOpen(true);
  const handleOpenEquipmentID = () => {
    setUpdateEquipmentID(true);
  };
  const handleCloseEquipmentID = () => {
    setUpdateEquipmentID(false);
  };
  const handleOutOfServiceClose = () => setOutOfServiceOpen(false);
  const [historyCoords, setHistoryCoords] = useState();
  const [historyData, setHistoryData] = useState([]);
  const { id } = useParams();

  async function init(value, reload) {
    getEquipmentProfile(id).then((res) => {
      setProfileData({ ...(res?.data || {}), refreshIndex: Date.now() });
      if (reload) setReloadMap(new Date().getTime());
    });
  }

  useEffect(() => {
    if (profileData?.hook_to?.id && allShipmentId.length === 0) {
      ShipmentId(profileData?.hook_to?.id).then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              label: el.shipment_id,
              value: el.shipment_id,
              id: el.id,
            };
          });
          setAllShipmentId(filterByData);
        }
      });
    }
  }, [profileData?.hook_to?.id]);

  async function equipmentMaintanance() {
    MaintanceCost(profileData?.id || params?.id).then((res) => {
      setMaintananceCostValue(res?.data);
    });
  }

  const navigateConditionally = () => {
    if (location?.state?.tab) {
      navigate('/equipment', { state: { tab: 'trailer' } });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    equipmentMaintanance().catch(null);
    init().catch(null);

    const dataRaw = localStorage.getItem('user');
    const data = JSON.parse(dataRaw);
    const channel = PusherJs.subscribe(`private-equipmentList-Update.${data?.customer?.dot}`);
    channel.bind('equipment-event', (data) => {
      setProfile(data);
    });

    return () => {
      PusherJs.unsubscribe('private-equipmentList-Update');
      PusherJs.unsubscribe('private-equipmentLocation-Update');
    };
  }, []);

  useEffect(() => {
    if (profileData) {
      const id = profile?.message?.data?.id;
      if (!profile?.message) return;

      const newData = { ...profileData, ...profile?.message?.data, equipment_type: profileData.equipment_type };
      if (profileData?.id === id) {
        setProfileData(newData);
      }

      if (profileData?.hook_to) {
        setHookTo(1);
      }
      if (!profileData?.hook_to) {
        setHookTo(0);
      }
    }
  }, [profile]);

  let latitude;
  let longitude;

  if (profileData?.location_target) {
    // If the location target is "Equipment", check if the equipment_gps object is defined
    if (profileData.location_target === 'Equipment' && profileData.equipment_gps) {
      // If the equipment_gps object is defined, assign its latitude and longitude values
      latitude = profileData.equipment_gps.latitude;
      longitude = profileData.equipment_gps.longitude;
    }
    // If the location target is "Vehicle", check if the hooked_to object is defined
    else if (profileData.location_target === 'Vehicle' && profileData.hooked_to) {
      // If the hooked_to object has a location_target of "Equipment", check if its equipment_gps object is defined
      if (profileData.hooked_to.location_target === 'Equipment' && profileData.hooked_to.equipment_gps) {
        // If the equipment_gps object is defined, assign its latitude and longitude values
        latitude = profileData.hooked_to.equipment_gps.latitude;
        longitude = profileData.hooked_to.equipment_gps.longitude;
      }
      // If the hooked_to object has a location_target of "Driver", check if its drivers array is defined
      else if (profileData.hooked_to.location_target === 'Driver' && profileData.hooked_to.drivers) {
        // Map over the drivers array and return the latitude values as an array of strings
        const driverLatitudes = profileData.hooked_to.drivers.map((driver) => driver?.driver_gps?.latitude?.toString());
        const driverLongitude = profileData.hooked_to.drivers.map((driver) =>
          driver?.driver_gps?.longitude?.toString()
        );
        // If the array of latitude strings is not empty, assign its first value to the latitude variable
        if (driverLatitudes?.length > 0) {
          [latitude] = driverLatitudes;
          [longitude] = driverLongitude;
        }
      }
      // If none of the above conditions are met, assign the last_known_latitude and last_known_longitude values
      if (!latitude) {
        latitude = profileData.hooked_to.last_known_latitude;
        longitude = profileData.hooked_to.last_known_longitude;
      }
    }
  }

  // If none of the above conditions are met, assign the last_known_latitude and last_known_longitude values
  if (!latitude) {
    latitude = profileData?.last_known_latitude;
    longitude = profileData?.last_known_longitude;
  }

  const heading =
    profileData?.location_target === 'Equipment'
      ? profileData?.equipment_gps
        ? profileData?.equipment_gps?.heading
        : profileData?.last_known_heading
      : '' || profileData?.location_target === 'Vehicle'
      ? profileData?.hooked_to?.location_target === 'Equipment'
        ? profileData?.hooked_to?.equipment_gps
          ? profileData?.hooked_to?.equipment_gps?.heading
          : profileData?.hooked_to?.last_known_heading
        : profileData?.hooked_to?.location_target === 'Driver'
        ? profileData?.hooked_to?.drivers
          ? profileData?.hooked_to?.drivers.map((v) =>
              v?.driver_gps?.heading ? v?.driver_gps?.heading.toString() : profileData?.hooked_to?.last_known_heading
            )
          : profileData?.hooked_to?.last_known_heading
        : profileData?.last_known_heading
      : profileData?.last_known_heading;

  const updateStatus = (statusID) => {
    let value;
    if (statusID) {
      value = {
        status_id: statusID,
      };
    }
    axios
      .put(`${API_URL}/equipment/equipment-status/${profileData?.id}`, value, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res) {
          init();
        }
      });
  };

  const [dateRange, setDateRange] = useState({
    start: startDate ? startOfDay(moment(startDate).toDate()) : startOfDay(new Date()),
    end: endDate ? endOfDay(moment(endDate).toDate()) : endOfDay(new Date()),
  });

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const stopPointZoom = (i) => {
    if (historyData[i]) {
      mapInstance.on('load', async function a() {
        mapInstance.zoomTo(17, {
          animate: true,
          duration: 5000,
        });
      });
      mapInstance.setCenter({
        lat: historyData[i].latitude,
        lon: historyData[i].longitude,
      });
      mapInstance.zoomTo(17, { animate: true, duration: 5000 });
      setIsCenter(true);
    }
  };

  function handleViewStop() {
    if (!profileData.equipment_stop_point?.stop_point) {
      return;
    }

    dispatch(onChangeInventoryTab(2));
    navigate(
      `/stop-point/${profileData.equipment_stop_point.stop_point.id}/${STOP_POINT_TAB.INVENTORY}?search=${profileData.equipment_id}`
    );
  }

  useEffect(() => {
    if (startDate) {
      timeLineOpen();
      setRangeName('Custom Range');
    }
  }, []);

  return (
    <>
      <EquipmentDetailsContext2.Provider
        value={{
          dateRange,
          dateSelect,
          rangeName,
          setRangeName,
          setDateRange,
          isShowDatePicker,
          setIsShowDatePicker,
          profileData,
          setHistoryCoords,
          historyCoords,
          setHistoryData,
          historyData,
          stopPointZoom,
          setMapInstance,
          isCenter,
          setIsCenter,
          setResetMyRoute,
          resetMyRoute,
          setDriverSpeed,
          driverSpeed,
          setConnectedServicesData,
          connectedServicesData,
        }}
      >
        <div className='equipment-profile-wrapper' style={{ marginTop: '0px !important' }}>
          <div
            className='d-flex align-items-center mt-1 pointer'
            style={{ maxWidth: 'fit-content' }}
            onClick={navigateConditionally}
          >
            <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M3.46967 0.46967C3.76256 0.176777 4.23744 0.176777 4.53033 0.46967C4.82322 0.762563 4.82322 1.23744 4.53033 1.53033L2.81066 3.25H9C9.41421 3.25 9.75 3.58579 9.75 4C9.75 4.41421 9.41421 4.75 9 4.75H2.81066L4.53033 6.46967C4.82322 6.76256 4.82322 7.23744 4.53033 7.53033C4.23744 7.82322 3.76256 7.82322 3.46967 7.53033L0.46967 4.53033C0.176777 4.23744 0.176777 3.76256 0.46967 3.46967L3.46967 0.46967Z'
                fill='#4F5AED'
              />
            </svg>
            <span className='equipment-heading'>Go Back</span>
          </div>
          <div className='d-flex align-item-center mt-1'>
            <div className='d-flex align-items-center mt-3'>
              <p className='equipment-name-heading'>
                {profileData?.equipment_id} {profileData?.length?.length}
                {profileData?.length?.unit} {profileData?.equipment_type?.title}
                <span className='ms-1' />
              </p>
              <span style={{ height: '20px', width: '20px', marginTop: '-40px' }}>
                <img src={Edit} alt='edit' onClick={handleOpenEquipmentID} />
              </span>
            </div>
            <div className='ms-3 mt-3' style={{ width: '70%' }}>
              <Chip
                fontWeight={500}
                fontSize='11px'
                style={{ textTransform: 'uppercase' }}
                bgColor={profileData?.equipment_status?.background_color}
                label={
                  trailerStatus[profileData?.equipment_status?.equipment_status] ||
                  profileData?.equipment_status?.equipment_status
                }
                labelColor={profileData?.equipment_status?.color}
              />
              <Dropdown className='d-inline mx-2'>
                <Dropdown.Toggle
                  id='dropdown-autoclose-outside'
                  style={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    boxShadow: 'none',
                    marginLeft: '0',
                    paddingLeft: '10x',
                  }}
                >
                  <img src={down} alt='down' />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                  }}
                >
                  {![3, 5, 6].includes(profileData?.equipment_status?.id) && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={handleOutOfServiceOpen}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Out Of Service
                    </Dropdown.Item>
                  )}
                  {![3, 5, 6].includes(profileData?.equipment_status?.id) && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={handleDropTrailerOpen}
                    >
                      Drop Trailer
                    </Dropdown.Item>
                  )}
                  {profileData?.equipment_status?.id === 5 && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => updateStatus(profileData?.hooked_to ? 7 : 4)}
                    >
                      Active
                    </Dropdown.Item>
                  )}
                  {profileData.equipment_status?.id === 3 && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => handleClearOutOfService()}
                    >
                      Set In Service
                    </Dropdown.Item>
                  )}
                  {profileData.equipment_status?.id === 6 && !profileData?.equipment_stop_point?.departed_date && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => setCheckoutOpen(true)}
                    >
                      Checkout
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              {profileData?.equipment_ownership?.owner_operator === '4' && (
                <Chip size='small' label='3rd Party' labelColor='#B48909' bgColor='#FFFAEB' />
              )}
              {profileData?.equipment_status?.id === 6 ? (
                <>
                  <div style={{ display: 'inline' }}>
                    <button className={style.ClearOOSButton} onClick={() => handleUpdateDropTrailerOpen()}>
                      Update
                    </button>
                  </div>
                  {loading ? (
                    <div style={{ display: 'inline' }}>
                      <CircularProgress size={20} />
                    </div>
                  ) : (
                    ' '
                  )}
                </>
              ) : (
                ''
              )}
              {profileData?.equipment_status?.id === 6 && (
                <div style={{ display: 'inline' }}>
                  <button className={style.ClearOOSButton} onClick={handleViewStop}>
                    Check Cargo Status
                  </button>
                </div>
              )}
              {profileData?.equipment_status?.equipment_status === 'Dropped Trailer' &&
              !!profileData?.equipment_stop_point ? (
                <div>
                  <span className='reasonNote'>
                    TRAILER DROPPED - SHIPMENT {profileData?.equipment_stop_point?.shipment_stop?.shipment_id} ON{' '}
                    {profileData?.equipment_stop_point?.drop_trailer_date
                      ? convertToCustomerTime(profileData?.equipment_stop_point?.drop_trailer_date)
                      : ''}{' '}
                    - {profileData?.equipment_stop_point?.shipment_stop?.stop_point?.location_name}{' '}
                    {profileData?.equipment_stop_point?.shipment_stop?.stop_point?.address1},{' '}
                    {profileData?.equipment_stop_point?.shipment_stop?.stop_point?.city?.name},{' '}
                    {profileData?.equipment_stop_point?.shipment_stop?.stop_point?.state?.name},{' '}
                    {profileData?.equipment_stop_point?.shipment_stop?.stop_point?.zipcode}
                  </span>
                </div>
              ) : (
                ''
              )}

              {profileData?.equipment_status?.equipment_status === 'Out of Service' ? (
                <div className='reasonNote'>
                  Equipment is Out of service{' '}
                  {profileData?.next_available_date
                    ? ` ${convertToCustomerTime(profileData?.next_available_date, null, true)}`
                    : ''}
                  . Note : {profileData?.reason}{' '}
                </div>
              ) : (
                ''
              )}

              {profileData?.location_target === 'Vehicle' &&
                profileData?.hooked_to &&
                profileData?.hooked_to?.location_target === 'Equipment' &&
                profileData?.hooked_to?.equipment_gps?.motion?.motion && (
                  <Chip
                    fontWeight={500}
                    fontSize='11px'
                    bgColor={motionColors[profileData?.hooked_to?.equipment_gps?.motion?.motion]?.bgColor}
                    labelColor={motionColors[profileData?.hooked_to?.equipment_gps?.motion?.motion]?.color}
                    style={{ textTransform: 'uppercase' }}
                    label={`${profileData?.hooked_to?.equipment_gps?.motion?.motion} ${formatSeconds(
                      profileData?.hooked_to?.equipment_gps?.motion?.duration
                    )}`}
                  />
                )}

              {profileData?.location_target === 'Vehicle' &&
                profileData?.hooked_to &&
                profileData?.hooked_to?.location_target === 'Driver' &&
                profileData?.hooked_to?.drivers?.length && (
                  <Chip
                    fontWeight={500}
                    fontSize='11px'
                    bgColor={motionColors[profileData?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.motion]?.bgColor}
                    labelColor={motionColors[profileData?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.motion]?.color}
                    style={{ textTransform: 'uppercase' }}
                    label={`${profileData?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                      profileData?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.duration
                    )}`}
                  />
                )}

              {profileData?.location_target === 'Equipment' && profileData?.equipment_gps?.motion?.motion && (
                <Chip
                  fontWeight={500}
                  fontSize='11px'
                  bgColor={motionColors[profileData?.equipment_gps?.motion?.motion]?.bgColor}
                  labelColor={motionColors[profileData?.equipment_gps?.motion?.motion]?.color}
                  style={{ textTransform: 'uppercase' }}
                  label={`${profileData?.equipment_gps?.motion?.motion} ${formatSeconds(
                    profileData?.equipment_gps?.motion?.duration
                  )}`}
                />
              )}
              {profileData?.location_target === 'Driver' && profileData?.drivers?.length && (
                <Chip
                  fontWeight={500}
                  fontSize='11px'
                  bgColor={motionColors[profileData?.drivers?.[0]?.driver_gps?.motion?.motion]?.bgColor}
                  labelColor={motionColors[profileData?.drivers?.[0]?.driver_gps?.motion?.motion]?.color}
                  style={{ textTransform: 'uppercase' }}
                  label={`${profileData?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                    profileData?.drivers?.[0]?.driver_gps?.motion?.duration
                  )}`}
                />
              )}
            </div>
          </div>
          <div className='mt-3 map-wrapper' ref={ref}>
            {!!Object.keys(profileData).length && (
              <Map
                longitude={longitude}
                latitude={latitude}
                mapLayersValues={mapLayersValues}
                profileData={profileData}
                heading={heading}
                trailerID='trailer'
                hookTo={hookTo}
                reloadMap={reloadMap}
              />
            )}
            <div className='equipment-tracing-history' style={{ position: 'absolute', right: '70px', top: '10px' }}>
              <div style={{ position: 'relative' }}>
                {isShowDatePicker && (
                  <DateRange
                    resetMyRoute={resetMyRoute}
                    title='All Time'
                    dateTimeRange={dateRange}
                    dateSelect={dateSelect}
                    rangeName={rangeName}
                    setRangeName={setRangeName}
                    setIsShowDatePicker={setIsShowDatePicker}
                    timeLineOpen={timeLineOpen}
                    setIsCenter={setIsCenter}
                  />
                )}
              </div>
              <PrimaryBtn icon={Icons.calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
            </div>
            <div className='equipment-tracing-history' style={{ position: 'absolute', right: '70px', top: '46px' }}>
              <PrimaryBtn icon={Icons.Info} title='Logs' onClick={() => setLogsOpen(true)} />
            </div>

            <div className='map-zooming-buttons' id='myMap'>
              <div className='map-zooming-feature-button-plus mb-2' aria-describedby={map} onClick={Maplayers}>
                <img src={Icons.MapLayers} alt='' />
              </div>
              <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Notes')}>
                <div className='map-stops-buttons mb-2'>
                  <div
                    className='map-zooming-feature-button-plus'
                    aria-describedby={map}
                    onClick={() => {
                      setOpenAddNotes(true);
                    }}
                  >
                    {profileData.notes_count ? <NoteIcon width={15} /> : <NoteIcon fill={palette.gray500} width={15} />}
                  </div>
                </div>
              </OverlayTrigger>
              <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Motions')}>
                <div className='map-stops-buttons mb-2'>
                  <div
                    className='map-zooming-feature-button-plus'
                    aria-describedby={map}
                    onClick={() => {
                      setMotionLogsOpen(true);
                    }}
                  >
                    <Motion fill={palette.gray500} width={15} />
                  </div>
                </div>
              </OverlayTrigger>
            </div>

            <button
              className={show ? 'overlay-toggle-button' : 'toggle-button-on-hide'}
              ref={target}
              onClick={handleClick}
              style={{ top: 0, position: 'absolute' }}
            >
              <img className='overlay-menu-icon' src={show ? Icons.menuOndisplay : Icons.menuOnhide} alt='menu' />
            </button>

            {show === true ? (
              <div className='map-overlay' style={{ zIndex: '9999' }}>
                <div className='d-flex' style={{ margin: '26px 0 0 -75px', height: '10px' }}>
                  <Trailer
                    profileData={profileData}
                    equipmentId={id}
                    init={init}
                    timeLine={timeLine}
                    timeLineClose={timeLineClose}
                  />
                  <div className='d-flex ms-3'>
                    <div className=' overlay-equipment-details-container-second ms-3'>
                      <div className='d-flex'>
                        <div className='trend-icon'>
                          <img src={Icons.doller} alt='trend-icon' className='ms-2' />
                        </div>
                        <div className='ms-2'>
                          <p className='overlay-content mb-1'>Maintenance costs</p>
                          <h6 className='overlay-numbers'>
                            {MaintanceCostValue?.allSum
                              ? Number(MaintanceCostValue?.allSum).toFixed(2)
                              : MaintanceCostValue?.allSum}
                          </h6>
                          <p className=''>
                            <span className='overlay-sub-content'>{MaintanceCostValue?.percentageIncrease} %</span>
                            <span className='mileage-text ms-2'>from previous month</span>
                            <span
                              onClick={handleMaintenanceCostOpen}
                              className='d-flex justify-content-center'
                              style={{
                                display: 'block',
                                position: 'absolute',
                                bottom: '10px',
                                left: '50%',
                              }}
                            >
                              <img src={down} alt='down' />
                            </span>
                            <Modal
                              open={openmaintenencecost}
                              onClose={handleMaintenanceCostClose}
                              style={{ zIndex: 1055 }}
                              aria-labelledby='modal-modal-title'
                              aria-describedby='modal-modal-description'
                            >
                              <MaintenanceCost
                                profileData={profileData}
                                equipmentMaintanance={equipmentMaintanance}
                                handleMaintenanceCostClose={handleMaintenanceCostClose}
                                trailerID='trailer'
                              />
                            </Modal>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ' '
            )}
          </div>
        </div>
      </EquipmentDetailsContext2.Provider>
      <Popover
        id={map}
        open={open}
        anchorEl={anchorEl}
        onClose={MapLayersHandleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <p
            onClick={() => mapLayersValue('TRANSPORTATION_DARK')}
            className='mapLayersMenu'
            style={{
              cursor: 'pointer',
              color: mapLayersValues === 'TRANSPORTATION_DARK' ? '#4F5AED' : '',
              padding: '2px 6px',
            }}
          >
            Dark
          </p>
          <p
            onClick={() => mapLayersValue('BASIC')}
            className='mapLayersMenu'
            style={{
              cursor: 'pointer',
              color: mapLayersValues === 'BASIC' ? '#4F5AED' : '',
              padding: '2px 6px',
            }}
          >
            {' '}
            Light
          </p>
          <p
            onClick={() => mapLayersValue('SATELLITE')}
            className='mapLayersMenu'
            style={{
              cursor: 'pointer',
              color: mapLayersValues === 'SATELLITE' ? '#4F5AED' : '',
              padding: '2px 6px',
            }}
          >
            Satellite
          </p>
        </Typography>
      </Popover>
      <Modal
        open={opendroptrailer}
        onClose={handleDropTrailerClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <DropTrailerStatus
          handleDropTrailerClose={handleDropTrailerClose}
          id={profileData?.hook_to?.id}
          trailerID={profileData?.id}
          init={init}
        />
      </Modal>
      <CustomModal
        showModal={openUpdateDropTrailer}
        styleBody={{ width: 1050, backgroundColor: '#F7F9FC', padding: '0 20px !important' }}
        onHide={handleUpdateDropTrailerClose}
        headerTitle={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Update{' '}
              {profileData?.equipment_stop_point?.equipment?.equipment_type?.vehicle_type_id === 1
                ? 'Vehicle'
                : 'Trailer'}{' '}
              {profileData?.equipment_stop_point?.equipment?.equipment_id}
            </span>
            <CloseIcon
              height={15}
              width={15}
              style={{ cursor: 'pointer' }}
              fill={palette.red500}
              onClick={handleUpdateDropTrailerClose}
            />
          </div>
        }
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <EquipmentUpdateModal
          data={profileData?.equipment_stop_point}
          onSuccess={(data) => {
            setProfileData((prevState) => ({
              ...prevState,
              equipment_stop_point: {
                ...prevState.equipment_stop_point,
                cargo_onboard_id: data?.cargo_onboard_id,
                note: data?.note,
              },
            }));
            showToaster({ type: 'success', message: 'Trailer status has been successfully updated!' });
          }}
          onClose={handleUpdateDropTrailerClose}
        />
      </CustomModal>
      {checkoutOpen && (
        <CheckoutTrailer
          open={checkoutOpen}
          onClose={() => setCheckoutOpen(null)}
          data={profileData?.equipment_stop_point}
          onSuccess={() => init()}
        />
      )}
      <Modal
        open={openoutofservice}
        onClose={handleOutOfServiceClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <OutOfServiceStatus handleOutOfServiceClose={handleOutOfServiceClose} id={profileData?.id} init={init} />
      </Modal>

      <Modal
        open={updateEquipmentID}
        onClose={handleCloseEquipmentID}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {/* <Fade in={reserveEquipment}> */}
        <Box sx={styles}>
          <UpdateEquipmentID
            id={profileData?.id}
            handleClose={handleCloseEquipmentID}
            setShowMessage={setShowMessage}
            equipmentID={profileData?.equipment_id}
            init={(value) => init(value)}
          />
        </Box>
      </Modal>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      {openAddNotes && (
        <AddEquipmentNotes
          open={openAddNotes}
          onClose={() => {
            setOpenAddNotes(false);
            init();
          }}
          data={profileData}
          onSuccess={() => {
            init();
          }}
        />
      )}
      {logsOpen && <EquipmentLogs open={logsOpen} profileData={profileData} onClose={() => setLogsOpen(false)} />}
      {motionLogsOpen && (
        <MotionsLogs open={motionLogsOpen} profileData={profileData} onClose={() => setMotionLogsOpen(false)} />
      )}
    </>
  );
};

export default EquipmentDetails;
const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  outline: 0,
};
