import styled from 'styled-components';

export const SWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;

  .checkbox-wrapper {
    :not(:last-child) {
      margin-bottom: 16px;
    }

    display: flex;
    flex-direction: column;
  }
`;
