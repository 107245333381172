import { object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  photo_identification: string().trim().required('Required').nullable(),
  photo_identification_expiration: validator.futureRequiredDate(),
  photo_identification_type: object()
    .shape({ id: string().required('Required') })
    .required('Required')
    .nullable(),
  status: object()
    .shape({ value: string().required('Required') })
    .required('Required')
    .nullable(),
});
