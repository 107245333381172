import React from 'react';
import { useNavigate } from 'react-router-dom';
import Chip from 'common/Chip';
import { palette } from 'utils/constants';
import { statues } from 'pages/Hos-Logbook/CustomCollapsibleRow/CustomCollapsibleRow.data';
import './HourOfService.css';

const HourOfService = ({ hourService }) => {
  const navigate = useNavigate();

  return (
    <div
      className='hours-activity-wrapper driver-style-wrap'
      style={{
        backgroundColor: palette.white,
        borderColor: palette.gray50,
      }}
    >
      <div className='hours-activity-header-container' style={{ borderColor: palette.gray50 }}>
        <h2 className='heading' style={{ color: palette.gray900 }}>
          Hours of Service
        </h2>
        <div className='select-month-container' />
      </div>
      <div className='Service-wrapper'>
        <div className='Sub-service-wrapper'>
          <div className='Sub-service-wrapper-head'>
            <p className='heading-service'>Drive Remaining</p>
            <p className='heading-service'>Shift Remaining</p>
            <p className='heading-service'>Cycle Remaining</p>
            <p className='heading-service'>Time Until Break</p>
            <p className='heading-service'>Duty Status</p>
          </div>
          <div className='Sub-service-wrapper-prop' onClick={() => navigate(`/hos-logbook/${hourService.driver_id}`)}>
            <p className='prop-service'>{hourService?.drive_remaining}</p>
            <p className='prop-service'>{hourService?.shift_remaining}</p>
            <p className='prop-service'>{hourService?.cycle_remaining_duration}</p>
            <p className='prop-service'>{hourService?.time_until_break}</p>
            <Chip
              bgColor={statues[hourService?.duty_status]?.bgColor}
              labelColor={statues[hourService?.duty_status]?.color}
              label={statues[hourService?.duty_status]?.name}
              fontWeight={500}
              fontSize='11px'
              style={{ textTransform: 'uppercase' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HourOfService;
