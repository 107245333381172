import React, { useMemo } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import { NavLink, useNavigate } from 'react-router-dom';
import MuiTooltip from '@mui/material/Tooltip';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MobileIcon } from 'assets/icons';
import cellularTracking from 'assets/icons/cellular-tracking.png';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as FuelIcon } from 'assets/icons/fuel.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as EnginOnIcon } from 'assets/icons/enginOn.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as WarningIcon, ReactComponent as DetentionIcon } from 'assets/icons/detention.svg';
import Chip from 'common/Chip';
import Garlax from 'common/Tooltip';
import StopsWay from 'components/StopsWay';
import Progress from 'components/Progress';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import UserInfo from 'components/StopPoint/UserInfo';
import TableRowsEdit from 'components/TableRowsEdit';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import useDateFormat from 'hooks/useDateFormat';
import { CURRENCY, motionColors, nFormatter, palette, STOP_POINT_TAB, TRUCK_TYPES } from 'utils/constants';
import { formatAmount, formatPhoneNumber, formatSeconds, getShipmentType } from 'utils/helpers';
import {
  DRY_RUN_ACTIONS,
  PENDING_DISPATCH_ACTIONS,
  SCHEDULED_DATE_TYPE_OBJ,
  SHIPMENT_ROWS_EDIT_DATA,
  SHIPMENT_ROWS_EDIT_DATA_CANCELED,
  SHIPMENT_ROWS_EDIT_DATA_COMPLETED,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import useShipmentLocked from 'hooks/useShipmentLocked';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import useGetShipmentWeight from 'hooks/useGetShipmentWeight';
import ShipmentSummary from 'componentsV2/Shipment/ShipmentSummary';
import Hos from './helpers/Hos';
import UserInfoRowAssignedTo from './helpers/UserInfoRowAssignedTo';
import { equipmentTypeText, getStatusParamsInStatus, getUTCTimeDifference, timeDifference } from './helpers/constants';
import classes from './tableShipments.module.css';

const abbreviateString = (str) => {
  const words = str.split(' ');

  const capitalizedWords = words.map((word) => word?.toUpperCase());

  const numWords = capitalizedWords.length;

  if (numWords === 1) {
    return capitalizedWords[0].substring(0, 6);
  }

  if (numWords === 2) {
    const firstPart = capitalizedWords[0].substring(0, 3);
    const secondPart = capitalizedWords[1].substring(0, 3);
    return `${firstPart + secondPart}`;
  }

  if (numWords === 3) {
    const firstPart = capitalizedWords[0].substring(0, 3);
    const secondPart = capitalizedWords[1].substring(0, 2);
    const thirdPart = capitalizedWords[2].substring(0, 2);
    return `${firstPart + secondPart + thirdPart}`;
  }

  const firstPart = capitalizedWords[0].substring(0, 3);
  const secondPart = capitalizedWords[1].charAt(0);
  const thirdPart = capitalizedWords[2].charAt(0);
  const fourthPart = capitalizedWords[3].charAt(0);
  return `${firstPart + secondPart + thirdPart + fourthPart}`;
};

const NavLinkWrapper = ({ deleteDefaultStyles = false, to, onClick, children, style, ...rest }) => {
  const defaultStyles = useMemo(() => {
    return {
      top: 0,
      left: 0,
      inset: 0,
      color: 'initial',
      position: 'absolute',
      background: 'initial',
      textDecoration: 'none',
      padding: '8px 8px 30px 8px',
    };
  }, []);
  return (
    <NavLink
      style={deleteDefaultStyles ? style : { ...defaultStyles, ...style }}
      to={to}
      onClick={(e) => {
        e.stopPropagation();
        // e.preventDefault();
        onClick?.(e);
      }}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

export const useColumns = ({
  user,
  columnWidths,
  rowCheckBoxes,
  refs,
  tableRowCheckboxChange,
  shipmentsData,
  shipmentSettingsProgressBar,
  animationList,
  onClickRowIcon,
  sortingQuery,
  findNextStop,
  setTableShipmentModalObj,
  filter,
  refsEditIcon,
  checkNullOrUndefined,
  onClickPlanned,
  onClickTableRowsEditOption,
  sort,
}) => {
  const navigate = useNavigate();
  const { isShipmentLocked } = useShipmentLocked();
  const { formatDate, formatDateTime, formatTime, convertToCustomerTime, dateFormat, timeFormat } = useDateFormat();
  const { getShipmentWeight } = useGetShipmentWeight();

  const navLinkInnerClickableElement = { position: 'relative', zIndex: 1, width: 'fit-content' };
  const navLinkStyles = useMemo(() => {
    return { textDecoration: 'none', backgroundColor: 'initial' };
  }, []);

  return [
    {
      field: 'checkbox',
      resizable: false,
      width: columnWidths.checkbox,
      render: (rowData) => {
        const { driver_from_city: city, driver_from_state: state, created_at, equipment } = rowData || {};
        const stops = rowData.shipment_stops;
        const statusId = rowData.status?.id;
        const { loaded_miles, empty_miles, total_miles_remain } = rowData;
        const miles = +loaded_miles + +empty_miles;
        let formula = 100 - (+total_miles_remain * 100) / miles;
        if (formula < 0) {
          formula = 0;
        }

        let milesLength = empty_miles !== undefined && empty_miles !== null ? +empty_miles : 0;

        for (let i = 0; i < stops.length; i++) {
          const stop = stops[i];
          const { distance_to_next_stop } = stop || {};

          if (stops.length - 1 !== i) {
            if (distance_to_next_stop === null) {
              milesLength = null;
              break;
            }
            milesLength += +distance_to_next_stop;
          }
        }

        const dataList = stops.map((el, index) => {
          const {
            stop_point,
            arrival_date,
            scheduled_date,
            distance_to_next_stop,
            from,
            to,
            reported_dock_status,
            departure_date,
            eta,
            scheduled_type,
            scheduled_date_to,
          } = el || {};
          const date = arrival_date !== null ? arrival_date : scheduled_date;

          const isETADelayed = moment(convertToCustomerTime(eta)).isAfter(
            [1, 3].includes(Number(scheduled_type))
              ? `${scheduled_date_to || scheduled_date} ${to || from}`
              : `${scheduled_date} ${from}`
          );
          const isArrivalDelayed = moment(arrival_date).isAfter(
            [1, 3].includes(Number(scheduled_type))
              ? `${scheduled_date_to || scheduled_date} ${to || from}`
              : `${scheduled_date} ${from}`
          );

          const datePlusHour =
            from && to
              ? `${formatTime(`${date} ${from}`)} - ${formatTime(`${date} ${to}`)}`
              : from
              ? formatTime(`${date} ${from}`)
              : date;

          const title = arrival_date
            ? `${stop_point.city?.name},${stop_point.state?.short_name} 
                                      (${formatDateTime(arrival_date)} )`
            : `${stop_point.city?.name},${stop_point.state?.short_name} 
                                      (${formatDate(date)}  , ${datePlusHour})`;

          const delayed = !!eta && !departure_date ? (arrival_date ? isArrivalDelayed : isETADelayed) : false;

          const color =
            +statusId === 4 || delayed ? palette.red500 : +statusId === 1 ? palette.gray500 : palette.indigo500;
          const deadHeadResult = (100 / milesLength) * empty_miles;
          return index === stops.length - 1
            ? {
                id: el?.id,
                percent: 100,
                color,
                title,
                tick: !!el?.arrival_date,
                reported_dock_status,
                arrival_date,
                departure_date,
                eta,
              }
            : {
                id: el?.id,
                percent:
                  milesLength === null
                    ? (100 / stops.length) * (index + 1)
                    : index === 0
                    ? deadHeadResult < 1
                      ? 1
                      : deadHeadResult
                    : (100 / milesLength) * distance_to_next_stop,
                color,
                title,
                tick: !!el?.arrival_date,
                reported_dock_status,
                departure_date,
                arrival_date,
                eta,
              };
        });

        dataList.unshift({
          id: uuid(),
          percent: 0,
          tick: !![3, 4, 5, 7].includes(Number(rowData?.status?.id)),
          title: `${city}, ${state} (${convertToCustomerTime(created_at)})`,
        });
        return (
          <div ref={refs[rowData.id]}>
            <div className={classes.itemCheckboxes} onClick={(e) => e.stopPropagation()}>
              <CustomCheckbox
                labelStyle={{ marginTop: 5 }}
                checked={rowCheckBoxes.includes(rowData?.shipment_id)}
                onChange={() => tableRowCheckboxChange(rowData?.shipment_id, shipmentsData)}
              />
            </div>
            {!!shipmentSettingsProgressBar && +rowData.status_id !== 6 && +rowData.status_id !== 7 && (
              <div className={classes.shipmentLineStops}>
                <StopsWay
                  status_id={rowData.status_id}
                  depedencyBgDisabled={rowCheckBoxes}
                  mainColor={palette.indigo500}
                  dataList={dataList}
                  currentData={{
                    id: 14,
                    percent: +formula > 100 ? 100 : formula,
                    title:
                      equipment?.location_target === 'Driver'
                        ? rowData?.equipment?.drivers?.[0]?.driver_gps?.location
                        : rowData?.equipment?.equipment_gps?.location,
                    lastUpdate:
                      equipment?.location_target === 'Driver'
                        ? rowData?.equipment?.drivers?.[0]?.driver_gps?.updated_at
                        : rowData?.equipment?.equipment_gps?.updated_at,
                    custom:
                      equipment?.location_target === 'Driver'
                        ? rowData?.equipment?.drivers?.[0]?.driver_gps?.custom
                        : rowData?.equipment?.equipment_gps?.custom,
                  }}
                  styleContainer={{ width: `calc(100% - 36px)` }}
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'shipment_id',
      resizable: true,
      width: columnWidths.shipment_id,
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const type = rowData?.shipment_type;
        const shipmentBilling = rowData.shipment_billing;
        const { shipment_notes, detentions, status_id } = rowData || {};

        return (
          <div>
            <div className={classes.textShipment}>
              <Typography variant='s3' style={{ color: palette.dark800 }}>
                {rowData.shipment_id}
                <ShipmentSummary shipmentId={rowData.shipment_id} className='ms-1' />
              </Typography>
              {Array.isArray(shipment_notes) && shipment_notes?.length > 0 && (
                <div onClick={(e) => onClickRowIcon(e, rowData, 'notes')}>
                  <NotesIcon width={14} height={14} fill={palette.goldDark} style={{ marginLeft: 4, flexShrink: 0 }} />
                </div>
              )}
              {+status_id === 4 && (
                <Garlax title='Delayed'>
                  <WarningIcon width={14} height={14} style={{ marginLeft: 4, flexShrink: 0 }} fill='#C97A20' />
                </Garlax>
              )}
              {Array.isArray(detentions) && detentions?.length > 0 && (
                <OverlayTrigger
                  placement='right'
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Detention warning</Tooltip>}
                >
                  <div onClick={(e) => onClickRowIcon(e, rowData, 'detention')}>
                    <DetentionIcon
                      width={14}
                      height={14}
                      fill={palette.red400}
                      style={{ marginLeft: 4, flexShrink: 0 }}
                    />
                  </div>
                </OverlayTrigger>
              )}
              {(!!rowData.is_edi_api || !!rowData.manual_edi_api) && (
                <CloudIcon fill={palette.green400} className='ms-2' />
              )}
            </div>
            {type !== 'ltl' || (type === 'ltl' && shipmentBilling.length === 1) ? (
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                {shipmentBilling?.[0]?.reference_id}
              </Typography>
            ) : (
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='button-tooltip-2'>
                    {shipmentBilling.map((el, i) => (
                      <p key={el.id || i} style={{ margin: 0 }}>
                        {el?.reference_id}
                      </p>
                    ))}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div ref={ref} className={classes.ellipsis}>
                    <Typography
                      variant='s3'
                      {...triggerHandler}
                      className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                      style={{ color: palette.dark800 }}
                    >
                      Multi-Ref LTL
                    </Typography>
                  </div>
                )}
              </OverlayTrigger>
            )}
            <div className='d-flex gap-2'>
              <Typography variant='s3'>{getShipmentType(rowData.shipment_type)}</Typography>
              {rowData.shipment_stops?.some((i) => i.hazardous_materials) && (
                <Typography variant='c1' style={{ color: palette.orange400 }}>
                  HAZMAT
                </Typography>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'customer',
      resizable: true,
      width: columnWidths.customer,
      title: <ColumnHeader title='CUSTOMER' field='customer' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const shipmentBilling = rowData.shipment_billing;
        const {
          contact_name: name,
          contact_email: email,
          phone_number,
        } = rowData.shipment_billing?.[0]?.contact_user || {};
        const contactInfo = {
          name,
          email,
          phone_number,
        };

        return shipmentBilling.length === 1 ? (
          <NavLinkWrapper
            to={`/customer-profile/${shipmentBilling?.[0]?.billing_customer?.id}`}
            className='d-flex flex-column align-items-start'
          >
            <div className='d-flex align-items-start flex-column' style={{ margin: 'auto 0' }}>
              <Typography
                variant='b3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                {shipmentBilling?.[0]?.billing_customer?.company_name?.toUpperCase()}&nbsp;
              </Typography>
              <UserInfo data={contactInfo} />
            </div>
          </NavLinkWrapper>
        ) : shipmentBilling.length > 1 ? (
          <OverLayTriggerHoverWrapper
            overlay={
              <div>
                {shipmentBilling.map((el, i) => (
                  <NavLinkWrapper
                    deleteDefaultStyles
                    style={navLinkStyles}
                    to={`/customer-profile/${el?.billing_customer?.id}`}
                  >
                    <Typography
                      key={el.id || i}
                      as='p'
                      variant='s2'
                      style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                    >
                      {el?.billing_customer?.company_name?.toUpperCase()}
                    </Typography>
                  </NavLinkWrapper>
                ))}
              </div>
            }
          >
            <div>
              <Typography
                variant='b3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                MULTI-CUSTOMER LTL
              </Typography>
            </div>
          </OverLayTriggerHoverWrapper>
        ) : null;
      },
    },
    {
      field: 'assigned_to',
      resizable: true,
      width: columnWidths.assigned_to,
      title: <ColumnHeader title='DRIVER' field='assigned_to' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <div style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
              {rowData.assigned_to1 || rowData.brokerage_dispatch ? (
                <UserInfoRowAssignedTo data={rowData} animationList={animationList} />
              ) : (
                '-'
              )}
            </div>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'equipment',
      resizable: true,
      width: columnWidths.equipment,
      title: <ColumnHeader title='EQUIPMENT' field='equipment' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const { equipment_id, equipment_type, hooked_to, id, truck_type } = rowData?.equipment || {};
        return (
          <>
            {rowData.equipment ? (
              <div className={classes.equipmentColWrapper}>
                <NavLinkWrapper
                  deleteDefaultStyles
                  style={{ ...navLinkStyles, position: 'relative', zIndex: 1 }}
                  to={id ? `/equipment-profile/vehicle/${id}` : ''}
                  className={classes.equipmentVehicleWrapper}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (id) {
                      navigate(`/equipment-profile/vehicle/${id}`);
                    }
                  }}
                >
                  <Typography
                    variant='s3'
                    className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                    style={{ color: palette.dark800 }}
                  >
                    {equipment_id || 'TBD'} {equipment_type?.title}
                    {!!truck_type && ` (${TRUCK_TYPES[truck_type]})`}
                  </Typography>
                </NavLinkWrapper>

                {!!hooked_to && (
                  <div className='d-flex align-items-baseline'>
                    <TwoWaysIcon style={{ margin: '0 4px' }} />
                    <NavLinkWrapper
                      deleteDefaultStyles
                      className={classes.hookToWrapper}
                      style={{ ...navLinkStyles, position: 'relative', zIndex: 1 }}
                      to={hooked_to.id ? `/equipment-profile/trailer/${hooked_to.id}` : ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (hooked_to.id) {
                          navigate(`/equipment-profile/trailer/${hooked_to.id}`);
                        }
                      }}
                    >
                      <Typography
                        variant='s3'
                        className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                        style={{ color: palette.dark800, marginLeft: 5 }}
                      >
                        {hooked_to?.equipment_id || 'TBD'} {hooked_to?.length?.length}&nbsp;
                        {hooked_to?.length?.unit} {hooked_to?.equipment_type?.title}
                      </Typography>
                    </NavLinkWrapper>
                  </div>
                )}
              </div>
            ) : (
              '-'
            )}
            <NavLinkWrapper
              state={rowData}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'status',
      resizable: true,
      center: true,
      width: columnWidths.status,
      title: <ColumnHeader title='STATUS' field='status' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const status = rowData?.status;
        const paramsInStatus = getStatusParamsInStatus(status);
        const { bgStatus, colorStatus, statusName } = paramsInStatus || {};
        const stops = rowData.shipment_stops.length;

        return (
          <>
            <div
              className={`d-flex flex-column gap-1 align-items-center ${
                animationList[rowData.id] ? classes.rowData_wrapper : ''
              }`}
            >
              <div className={classes.statusWrapper}>
                <span className={classes.statusColumns} style={{ backgroundColor: bgStatus, color: colorStatus }}>
                  {statusName}
                </span>
              </div>
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                {findNextStop(rowData)} of {stops}
              </Typography>
            </div>
            <NavLinkWrapper
              state={rowData}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'groups',
      resizable: true,
      width: columnWidths.groups,
      title: <ColumnHeader title='TAGS' field='groups' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const shipmentBilling = rowData.shipment_billing;
        return shipmentBilling?.map((el, i) => (
          <>
            <div
              key={el.id || i}
              className={`flex-row d-flex table_planer_groups_wrapper ${
                animationList[rowData.id] ? classes.rowData_wrapper : ''
              }`}
            >
              <div
                className={`${classes.shortName}`}
                style={{
                  color: palette.gray900,
                  backgroundColor: el?.groups?.color,
                }}
              >
                {el?.groups?.short_name}
              </div>
            </div>
            <NavLinkWrapper
              state={rowData}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        ));
      },
    },
    {
      field: 'scheduled_origin',
      resizable: true,
      width: columnWidths.scheduled_origin,
      title: <Typography variant='c3'>SCHEDULED ORIGIN</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;
        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOrigin} ${toData || ''}`;
        return (
          <div>
            <Typography variant='b3'>{formatDate(scheduledOrigin)} </Typography>
            <br />
            <span
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
              style={{ color: palette.dark800 }}
            >
              {fromData ? ` ${formatTime(from)}` : ''}
              {toData && [1, 3].includes(Number(typeId)) ? ` to ${formatTime(to)}` : ''}
              {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
            </span>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
              style={navLinkStyles}
            />
          </div>
        );
      },
    },
    {
      field: 'origin_stop_name',
      resizable: true,
      width: columnWidths.origin_stop_name,
      title: <ColumnHeader title='ORIGIN' field='origin' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const originStopName = rowData.shipment_stops[0]?.stop_point?.location_name;
        const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
        const id = rowData.shipment_stops[0]?.stop_point?.id;
        const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[0]?.stop_point?.zipcode;

        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const scheduledOriginTo = rowData.shipment_stops[0].scheduled_date_to;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;
        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOriginTo} ${toData || ''}`;

        return (
          <>
            <NavLinkWrapper
              deleteDefaultStyles
              to={`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`}
              style={{ ...navLinkStyles, position: 'relative', zIndex: 1 }}
            >
              <Typography variant='b3' className={classes.originNameWrapper}>
                {originStopName?.toUpperCase()}{' '}
              </Typography>
            </NavLinkWrapper>
            <br />
            <span
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
              style={{ color: palette.dark800 }}
            >
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
              <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
              {!!toData && [1, 3].includes(Number(typeId)) && (
                <>
                  <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                  <Typography variant='b2'>{formatTime(to)}</Typography>
                </>
              )}
              <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
            </>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'current_location',
      resizable: true,
      width: columnWidths.current_location,
      title: <Typography variant='c3'>CURRENT LOCATION</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        if (!rowData.equipment) {
          return '-';
        }

        const { carrier_gps_latest } = rowData || {};
        const {
          equipment: { location_target },
        } = rowData;
        const total =
          location_target === 'Driver'
            ? rowData?.equipment?.drivers?.[0]?.driver_gps?.location
            : location_target === 'carrier'
            ? carrier_gps_latest?.location
            : rowData?.equipment?.equipment_gps?.location;

        const lastUpdate =
          location_target === 'Driver'
            ? rowData?.equipment?.drivers?.[0]?.driver_gps?.updated_at
            : location_target === 'carrier'
            ? carrier_gps_latest?.updated_at
            : rowData?.equipment?.equipment_gps?.updated_at;

        const custom =
          location_target === 'Driver'
            ? rowData?.equipment?.drivers?.[0]?.driver_gps?.custom
            : location_target === 'carrier'
            ? rowData?.carrier_gps_latest?.source === 'manual'
            : rowData?.equipment?.equipment_gps?.custom;
        return (
          <>
            <div className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}>
              <Typography
                variant='s3'
                style={{ color: palette.dark800, position: 'relative', zIndex: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTableShipmentModalObj({ item: { id: 43, name: 'Update Driver Location' }, rowData });
                }}
                className={classes.currentLocationWrapper}
              >
                {total}
              </Typography>
              <br />
              <Typography variant='b3'>
                {convertToCustomerTime(lastUpdate, dateFormat)} {convertToCustomerTime(lastUpdate, timeFormat)}{' '}
                {custom ? '(M)' : '(A)'}
              </Typography>
            </div>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'next_stop',
      resizable: true,
      width: columnWidths.next_stop,
      title: <Typography variant='c3'>NEXT STOP</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const statusSixOrSeven = Number(rowData?.status_id) === 6 || Number(rowData?.status_id) === 7;
        const eta = rowData?.eta;
        const miles_remaining = rowData?.miles_remaining;
        const etaDate = rowData?.estimated_date_time_arrival;

        const lastStop = rowData.shipment_stops[rowData.shipment_stops.length - 1];
        const ccoxStop = rowData.shipment_stops?.find(
          (item) => item.stop_point?.id === Number(rowData.next_stop) && !item.arrival_date
        );

        const isETADelayed = ccoxStop?.eta
          ? moment(convertToCustomerTime(ccoxStop.eta)).isAfter(
              [1, 3].includes(Number(ccoxStop.scheduled_type))
                ? `${ccoxStop.scheduled_date_to || ccoxStop.scheduled_date} ${ccoxStop.to || ccoxStop.from}`
                : `${ccoxStop.scheduled_date} ${ccoxStop.from}`
            )
          : false;

        const { city, state, zipcode, location_name, id } = rowData?.next_stop_info || {};

        return (
          <div
            className={`${classes['next-stop-cell']} ${
              !lastStop.arrival_date && ccoxStop && !statusSixOrSeven && isETADelayed
                ? ccoxStop?.delay_reason
                  ? classes['haveli-garlax-animacia']
                  : classes['garlax-animacia']
                : ''
            }`}
            style={{ backgroundColor: ccoxStop?.custom_eta && !isETADelayed ? palette.indigo100 : '' }}
          >
            <div className={` ${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}>
              <NavLinkWrapper
                deleteDefaultStyles
                to={`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`}
                style={{ ...navLinkStyles, position: 'relative', zIndex: 1 }}
              >
                <Typography variant='b3' className={classes.nextStopWrapper}>
                  {location_name}{' '}
                </Typography>
              </NavLinkWrapper>
              <br />
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                style={{ color: palette.dark800 }}
              >
                {city && `${city?.name},`} {state && `${state?.name},`} {zipcode}
              </span>
              <br />
              {!statusSixOrSeven && (
                <>
                  <Typography variant='b3'>
                    {statusSixOrSeven ? '-' : etaDate ? `${formatDate(etaDate)}` : ''}{' '}
                  </Typography>
                  <span
                    className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                    style={{ color: palette.dark800 }}
                  >
                    {statusSixOrSeven ? '-' : etaDate ? `${formatTime(etaDate)} (${eta})` : ''}
                  </span>
                  <Typography variant='b3'>, {Number(miles_remaining)?.toFixed()} miles remain</Typography>
                </>
              )}
            </div>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </div>
        );
      },
    },
    {
      field: 'total_eta',
      resizable: true,
      width: columnWidths.total_eta,
      title: (
        <ColumnHeader title='TOTAL ETA' field='total_estimated_date_time_final' onClick={sortingQuery} sort={sort} />
      ),
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const statusSixOrSeven = Number(rowData?.status_id) === 6 || Number(rowData?.status_id) === 7;
        const { total_miles_remain } = rowData;
        const { loaded_miles, empty_miles } = rowData;
        const miles = Math.round(loaded_miles + empty_miles);
        const lastStop = rowData.shipment_stops[rowData.shipment_stops.length - 1];

        const totalEta = lastStop?.eta ? timeDifference(lastStop?.eta) : null;

        const etaDate = lastStop?.eta ? convertToCustomerTime(lastStop.eta, dateFormat) : null;
        const etaTime = lastStop?.eta ? convertToCustomerTime(lastStop.eta, timeFormat) : null;

        return (
          <>
            <Typography variant='b3'>{statusSixOrSeven ? '-' : etaDate} </Typography>
            <br />
            <span
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
              style={{ color: palette.dark800 }}
            >
              {statusSixOrSeven ? '-' : `${etaTime || ''} ${totalEta ? `(${totalEta})` : ''} `}
            </span>
            <br />
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            >
              {Number(total_miles_remain).toFixed()} / {miles}
            </Typography>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'rpm',
      resizable: true,
      width: columnWidths.rpm,
      title: <ColumnHeader title='RPM' field='rpm' />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const { loaded_miles, empty_miles, shipment_billing } = rowData;
        const currency = Object.values(CURRENCY).find(
          (item) => item.id === Number(rowData?.shipment_billing[0]?.currency_id)
        );
        const totalMiles = Math.round(loaded_miles + empty_miles);
        const totalCharges = shipment_billing.reduce((acc, el) => +acc + Number(el?.total_amount), 0);

        const rpm = formatAmount(totalCharges / totalMiles, 2);

        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            >
              {currency?.symbol || '$'}
              {rpm}
            </Typography>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'amount',
      resizable: true,
      width: columnWidths.amount,
      title: <ColumnHeader title='AMOUNT' field='amount' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        let totalAmount = 0;

        rowData?.shipment_billing?.forEach((el) => {
          totalAmount += Number(el?.total_amount);
        });
        const amount = +filter.switchRadioButtons.amount;
        const formattedAmount = amount === 2 ? nFormatter(totalAmount) : formatAmount(totalAmount.toFixed(2));
        const currency = Object.values(CURRENCY).find(
          (item) => item.id === Number(rowData?.shipment_billing[0]?.currency_id)
        );

        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800, margin: 'auto 0' }}
            >
              {currency?.symbol || '$'}
              {formattedAmount}
            </Typography>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'hours_remaining',
      resizable: true,
      width: columnWidths.hours_remaining,
      title: (
        <>
          <Typography variant='c3'>HOS </Typography>
          <Garlax
            title='Drive remaining/Shift remaining/Cycle remaining'
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 13,
                  fontFamily: 'Inter',
                  whiteSpace: 'nowrap',
                  maxWidth: 'unset',
                },
              },
            }}
          >
            <InfoIcon />
          </Garlax>
        </>
      ),
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            >
              <Hos driver={rowData?.assigned_to_1} type='shipment' />
            </Typography>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'billing_of_lading',
      resizable: true,
      width: columnWidths.billing_of_lading,
      title: <Typography variant='c3'>BILL OF LADING</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const data = [];
        rowData.shipment_stops.forEach((stop) => {
          if (stop?.bill_of_lading_id) {
            data.push(stop?.bill_of_lading_id);
          }
        });
        return (
          <>
            {data.length === 0 ? (
              '-'
            ) : data.length === 1 ? (
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                {data[0]}
              </Typography>
            ) : (
              <OverLayTriggerHoverWrapper
                overlay={
                  <div style={{ padding: 4 }}>
                    {data.map((el) => (
                      <Typography as='p' style={{ marginBottom: 0 }} variant='s2'>
                        {el}
                      </Typography>
                    ))}
                  </div>
                }
              >
                <div>
                  <Typography variant='s2'>Multi-BOL</Typography>
                </div>
              </OverLayTriggerHoverWrapper>
            )}
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'previous_stop',
      resizable: true,
      width: columnWidths.previous_stop,
      title: <ColumnHeader title='PREVIOUS STOP' field='previous_stop' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            />
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'commodity',
      resizable: true,
      width: columnWidths.commodity,
      title: <Typography variant='c3'>COMMODITY</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const firstPickUp = rowData.shipment_stops.find((stop) => +stop.stop_point_type === 1);

        return (
          <NavLinkWrapper
            to={
              Number(rowData.status_id) === 8
                ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                : `/shipment/${rowData?.shipment_id}`
            }
            className='d-flex align-items-start justify-content-center flex-column'
          >
            <Garlax
              title={checkNullOrUndefined(firstPickUp?.commodity_type?.title)}
              arrow={false}
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 13,
                    fontFamily: 'Inter',
                    whiteSpace: 'nowrap',
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              <span>
                <Typography
                  variant='s3'
                  className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                  style={{ color: palette.dark800 }}
                >
                  {abbreviateString(checkNullOrUndefined(firstPickUp?.commodity_type?.title))}
                </Typography>
              </span>
            </Garlax>
          </NavLinkWrapper>
        );
      },
    },
    {
      field: 'next_equipment_action',
      resizable: true,
      width: columnWidths.next_equipment_action,
      title: <ColumnHeader title='TYPE' field='equipment_type' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const typeLength = rowData.shipment_stops[0]?.equipment_type_length_value?.length;
        const equipmentType = rowData.shipment_stops[0]?.equipment_type_data?.title;
        const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;

        const index = findNextStop(rowData) === 0 ? 0 : findNextStop(rowData) - 1;
        const firstNullArrivalDate = rowData.shipment_stops[index];
        return (
          <>
            <div className='d-flex flex-column'>
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                style={{ color: palette.dark800 }}
              >
                {type}
              </span>
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                {!firstNullArrivalDate?.stop_point_type
                  ? '-'
                  : equipmentTypeText({
                      type: firstNullArrivalDate.stop_point_type,
                      action: firstNullArrivalDate.equipment_action,
                      equipment: firstNullArrivalDate.equipment,
                      equipment_tbd: firstNullArrivalDate.equipment_tbd,
                    })}
              </Typography>
            </div>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'empty_miles',
      resizable: true,
      width: columnWidths.empty_miles,
      title: <ColumnHeader title='EMPTY MILES' field='empty_miles' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const { empty_miles } = rowData;
        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            >
              {Number(empty_miles).toFixed()}
            </Typography>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'loaded_miles',
      resizable: true,
      width: columnWidths.loaded_miles,
      title: <ColumnHeader title='LOADED MILES' field='loaded_miles' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const { loaded_miles } = rowData;
        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            >
              {Number(loaded_miles).toFixed()}
            </Typography>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'deliver_earliest',
      resizable: true,
      width: columnWidths.deliver_earliest,
      title: <ColumnHeader title='DELIVER EARLIEST' field='deliver_earliest' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            />
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'motion',
      resizable: true,
      width: columnWidths.motion,
      title: <ColumnHeader title='MOTION' field='motion' nested_field='gps' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <div>
              {rowData?.equipment?.location_target === 'Equipment' &&
              rowData?.equipment?.equipment_gps?.motion?.motion ? (
                <>
                  <div>
                    <Chip
                      fontWeight={500}
                      fontSize='11px'
                      bgColor={motionColors[rowData?.equipment?.equipment_gps.motion.motion].bgColor}
                      labelColor={motionColors[rowData?.equipment?.equipment_gps.motion.motion].color}
                      style={{ textTransform: 'uppercase' }}
                      label={`${rowData?.equipment?.equipment_gps?.motion?.motion} ${formatSeconds(
                        rowData?.equipment?.equipment_gps?.motion.duration
                      )}`}
                    />
                  </div>
                  <Typography variant='c3'>
                    Updated {getUTCTimeDifference(rowData?.equipment?.equipment_gps?.motion?.updated_at)} ago
                  </Typography>
                </>
              ) : rowData?.equipment?.location_target === 'Driver' &&
                rowData?.equipment?.drivers?.length &&
                rowData?.equipment?.drivers?.[0]?.driver_gps?.motion?.motion ? (
                <>
                  <div>
                    <Chip
                      fontWeight={500}
                      fontSize='11px'
                      bgColor={motionColors[rowData?.equipment?.drivers?.[0]?.driver_gps?.motion?.motion].bgColor}
                      labelColor={motionColors[rowData?.equipment?.drivers?.[0]?.driver_gps?.motion?.motion].color}
                      style={{ textTransform: 'uppercase' }}
                      label={`${rowData?.equipment?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                        rowData?.equipment?.drivers?.[0]?.driver_gps?.motion?.duration
                      )}`}
                    />
                  </div>
                  <Typography variant='c3'>
                    Updated {getUTCTimeDifference(rowData?.equipment?.drivers?.[0]?.driver_gps?.motion?.updated_at)} ago
                  </Typography>
                </>
              ) : (
                <span>-</span>
              )}
            </div>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'deliver_latest',
      resizable: true,
      width: columnWidths.deliver_latest,
      title: <ColumnHeader title='DELIVER LATEST' field='deliver_latest' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            />
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'destination_stop_name',
      resizable: true,
      width: columnWidths.destination_stop_name,
      title: <ColumnHeader title='DESTINATION' field='destination' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const destinationStopName =
          rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.location_name;
        const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;
        const id = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.id;

        const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
        const scheduledDestinationTo = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date_to;
        const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
        const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;

        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestinationTo} ${toData || ''}`;

        return (
          <>
            <NavLinkWrapper
              deleteDefaultStyles
              style={{ ...navLinkStyles, position: 'relative', zIndex: 1 }}
              to={`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`}
            >
              <Typography variant='b3' className={classes.destinationNameWrapper}>
                {destinationStopName?.toUpperCase()}{' '}
              </Typography>
            </NavLinkWrapper>
            <br />
            <span
              className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
              style={{ color: palette.dark800 }}
            >
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
              <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
              {!!toData && [1, 3].includes(Number(typeId)) && (
                <>
                  <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                  <Typography variant='b2'>{formatTime(to)}</Typography>
                </>
              )}
              <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
            </>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'weight',
      resizable: true,
      width: columnWidths.weight,
      title: <ColumnHeader title='WEIGHT' field='weight' />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <>
            <Typography
              variant='s3'
              className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
              style={{ color: palette.dark800 }}
            >
              {getShipmentWeight(rowData.shipment_stops).expectedWeightWithUnit} (Scheduled)
              <br />
              {getShipmentWeight(rowData.shipment_stops).actualWeightWithUnit} (Actual)
            </Typography>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'speed',
      resizable: true,
      width: columnWidths.speed,
      title: <Typography variant='c3'>TELEMATICS</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const { status_id } = rowData;
        let speed_mph =
          rowData?.equipment?.location_target === 'Equipment'
            ? rowData?.equipment?.equipment_connected_services_device?.speed_mph
            : rowData.equipment?.drivers?.[0]?.driver_gps?.mph;
        let classNameContainer =
          typeof +speed_mph === 'number' ? (+speed_mph <= 0.5 ? 'headerGrayWrapper' : 'headerGreenWrapper') : '';

        const engine_state = rowData?.equipment?.equipment_connected_services_device?.engine_state;
        const isEquipmentGps = rowData?.equipment?.equipment_connected_services_device;

        if (status_id === '1' || status_id === '7') {
          speed_mph = null;
          classNameContainer = 'headerGrayWrapper';
        }
        return (
          <>
            <div className='d-flex gap-1 align-items-center'>
              {status_id !== 7 && (
                <div className={classes.flexAlign}>
                  {rowData?.equipment?.location_target === 'Driver' ? (
                    <span className='mb-1'> {MobileIcon}</span>
                  ) : (
                    !rowData?.brokerage_dispatch?.carrier && (
                      <>
                        <EnginOnIcon
                          fill={engine_state === 'On' ? palette.green400 : palette.gray400}
                          className={classes.iconNoHover}
                        />
                        <SignalIcon
                          fill={
                            rowData?.equipment?.equipment_connected_services_device?.hardware_model
                              ? palette.green400
                              : palette.gray400
                          }
                          className={classes.iconNoHover}
                        />
                      </>
                    )
                  )}

                  {rowData?.brokerage_dispatch?.carrier && rowData?.cellular_tracking_status === 'started' ? (
                    <MuiTooltip
                      placement='bottom'
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: palette.white,
                            border: '1px solid #dadde9',
                          },
                        },
                      }}
                      title={
                        <div className='d-flex flex-column'>
                          <Typography variant='s2' style={{ color: palette.gray700 }}>
                            Cell Tracking Transmission Active
                          </Typography>
                          <div>
                            <Typography variant='s2' style={{ color: palette.gray700 }}>
                              {rowData?.brokerage_dispatch?.driver_phone_number?.startsWith('+')
                                ? formatPhoneNumber(rowData?.brokerage_dispatch?.driver_phone_number)
                                : formatPhoneNumber(`+${rowData?.brokerage_dispatch?.driver_phone_number}`)}
                            </Typography>
                            <span
                              className={classes.redUnderlineText}
                              onClick={(e) => {
                                e.stopPropagation();
                                onClickTableRowsEditOption({ id: 45 }, null, rowData);
                              }}
                            >
                              Stop Tracking
                            </span>
                          </div>
                        </div>
                      }
                    >
                      <div className='ms-1 mb-1'>
                        <img src={cellularTracking} width={20} height={20} alt='tracking' />
                      </div>
                    </MuiTooltip>
                  ) : (
                    <Typography variant='s3' style={{ color: palette.dark800 }}>
                      -
                    </Typography>
                  )}
                </div>
              )}

              {!rowData?.brokerage_dispatch?.carrier &&
                (isEquipmentGps ? (
                  <Progress
                    icon={<FuelIcon fill={palette.green400} />}
                    percent={isEquipmentGps?.fuel_percent !== null ? isEquipmentGps?.fuel_percent : '1'}
                    colorPercent={palette.green200}
                  />
                ) : (
                  <FuelIcon />
                ))}

              {!rowData?.brokerage_dispatch?.carrier && (
                <div className={classes[classNameContainer]}>
                  {!speed_mph ? (
                    '-'
                  ) : (
                    <Typography
                      variant='s3'
                      className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                      style={{ color: palette.dark800 }}
                    >
                      {Number(speed_mph).toFixed(1) <= 0 ? 0 : Number(speed_mph).toFixed()} MPH
                    </Typography>
                  )}
                </div>
              )}
            </div>
            <NavLinkWrapper
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'planned',
      resizable: true,
      width: columnWidths.planned,
      title: <ColumnHeader title='PLANNED' field='planned_count' onClick={sortingQuery} sort={sort} />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const planned_count = rowData?.planned_count;
        return (
          <>
            {planned_count ? (
              <div className={classes.centered}>
                <Typography
                  onClick={(e) => onClickPlanned(e, rowData, planned_count)}
                  variant='s3'
                  className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                  style={{
                    ...navLinkInnerClickableElement,
                    color: palette.indigo500,
                    textDecoration: 'underline',
                  }}
                >
                  {planned_count}
                </Typography>
              </div>
            ) : (
              <div className={classes.centered}>
                <Typography variant='s3' className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}>
                  -
                </Typography>
              </div>
            )}
            <NavLinkWrapper
              state={rowData}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </>
        );
      },
    },
    {
      field: 'threeDotes',
      resizable: false,
      width: columnWidths.threeDotes,
      title: '',
      render: (rowData) => {
        let options = rowData?.shipment_stops?.[0].arrival_date
          ? SHIPMENT_ROWS_EDIT_DATA.filter((item) =>
              rowData.shipment_type === 'ltl' || rowData.shipment_type === 'slave' ? item.id !== 8 : true
            )
          : SHIPMENT_ROWS_EDIT_DATA.filter((item) => {
              if (item.id === 97) return false;
              return !((rowData.shipment_type === 'ltl' || rowData.shipment_type === 'slave') && item.id === 8);
            });

        if (+rowData.status_id === 6) {
          options = SHIPMENT_ROWS_EDIT_DATA_CANCELED.filter((item) =>
            rowData.shipment_type === 'ltl' || rowData.shipment_type === 'slave' ? item.id !== 8 : item.id !== 2
          );
        } else if (+rowData.status_id === 7) {
          options = SHIPMENT_ROWS_EDIT_DATA_COMPLETED.filter((item) =>
            rowData.shipment_type === 'ltl' || rowData.shipment_type === 'slave'
              ? item.id !== 8
              : rowData?.invoiced
              ? item.id !== 19 && item.id !== 97
              : true
          );
        }

        if (rowData.status_id !== '5') {
          options = options.filter((item) => item.id !== 61);
        }

        if (rowData?.brokerage_dispatch?.carrier) {
          options = options.filter((item) => item.id !== 14 && item.id !== 3);
        }

        if (Number(rowData.status_id) === 8) {
          options = PENDING_DISPATCH_ACTIONS;
        }

        if (Number(rowData.status_id) === 6) {
          options = DRY_RUN_ACTIONS;
        }

        if (
          !rowData?.brokerage_dispatch?.carrier ||
          !rowData?.brokerage_dispatch?.driver_phone_number ||
          !user?.customer?.cellular_tracking ||
          rowData?.cellular_tracking_status === 'started' ||
          rowData?.cellular_tracking_status === 'completed'
        ) {
          options = options.filter((item) => item.id !== 44);
        }

        if (!rowData?.brokerage_dispatch?.carrier || rowData?.cellular_tracking_status !== 'started') {
          options = options.filter((item) => item.id !== 45);
        }

        if (
          !rowData.shipment_stops?.[0]?.arrival_date ||
          (rowData.shipment_stops?.[0]?.arrival_date && rowData.shipment_stops?.[0]?.departure_date)
        ) {
          options = options.filter((el) => +el.id !== 5);
        }

        if (isShipmentLocked(rowData)) {
          return <ActionLocked shipment={rowData} size={16} />;
        }

        return (
          <div
            className='table_edit'
            ref={refsEditIcon[rowData.id]}
            onClick={() => {
              const element = refsEditIcon[rowData.id];
              if (element.current) element.current.style.opacity = 1;
            }}
          >
            <TableRowsEdit
              onHide={() => {
                const element = refsEditIcon[rowData.id];
                if (element.current) element.current.style.removeProperty('opacity');
              }}
              placement='bottom-end'
              overlayStyle={{ width: 210 }}
              options={options}
              onClickOption={(e, i) => onClickTableRowsEditOption(e, i, rowData)}
            />
          </div>
        );
      },
    },
  ];
};
