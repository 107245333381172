import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import { getAccountReceivableCount } from 'Api/Dashboard';
import DoughnutChart from 'pages/Dashboard/components/Charts/DoughnutChart';

const labels = {
  0: 'Missing Paperwork',
  1: 'Ready to Invoice',
  2: 'Open',
  3: 'Past Due',
};

const AccountsReceivable = () => {
  const [loading, setLoading] = useState(false);
  const [accountsReceivableCounts, setAccountsReceivableCounts] = useState(null);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getAccountReceivableCount();
      setAccountsReceivableCounts(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasets = [
    {
      data: [
        accountsReceivableCounts?.missing_paperwork,
        accountsReceivableCounts?.ready,
        accountsReceivableCounts?.open,
        accountsReceivableCounts?.past_due,
      ],
      backgroundColor: ['#F5BA0B', palette.green400, palette.indigo400, palette.red400],
      borderColor: ['#F5BA0B', palette.green400, palette.indigo400, palette.red400],
      hoverBackgroundColor: ['#E4B118', palette.green500, palette.indigo500, palette.red500],
      hoverBorderColor: ['#E4B118', palette.green500, palette.indigo500, palette.red500],
      borderWidth: 1,
    },
  ];

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={Number(accountsReceivableCounts?.total || 0)}
        title='Accounts Receivable'
      />
    </div>
  );
};

export default AccountsReceivable;
