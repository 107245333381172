import styled from 'styled-components';
import { palette } from 'utils/constants';
import Input from 'common/Input';

export const SPaymentOptions = styled.div`
  input[type='number'],
  input[type='text'] {
    height: auto !important;
    box-shadow: none !important;
    width: 100% !important;
  }

  .sales-agent-table-wrapper {
    margin: 0 24px;
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .date-picker-wrapper {
    width: 150px;
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: pointer;

  :hover span {
    text-decoration: underline;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 12px;
    white-space: nowrap;
  }

  td {
    padding: 12px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.blue-border td {
      border-top: 1px solid ${palette.indigo500};
    }
    &.red-background {
      background-color: ${palette.red0};
    }
    :has(+ tr.blue-border) td {
      border-bottom: 1px solid ${palette.indigo500};
    }

    &.clickable {
      cursor: pointer;

      :hover {
        background-color: ${palette.gray0};
      }
    }
  }
`;

export const STableData = styled.td`
  width: ${({ $width }) => $width || '100%'};
`;

export const SMergedInputs = styled.div`
  display: flex;

  input {
    border-radius: 6px 0 0 6px;
  }
`;

export const SInput = styled(Input)`
  input {
    width: ${({ width }) => width || '120px'} !important;
    padding: 2px 12px;
    height: 32px; !important;
  }
`;
