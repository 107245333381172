import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { palette } from 'utils/constants';
import { getAssignToGroupBillingCharges, getBillToBillingCharges } from 'Api/Planner';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { useTheme } from 'context/themeContext';
import sm from '../../BillingCharges/BillingCharges.module.css';
import { ShipmentContext } from '../../Layout';

import { Typography } from '../../../Typography';
import { getChangesTemplate } from '../../ShipmentStops/helpers/constants';
import BillingChargesLTLFooter from './footer';
import BillingChargesLTLOneCharge from './BillingChargesLTLOneCharge';

const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === 'object') {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `[name='${fieldErrorNames[0].replace(/.\d+/g, (match) => {
        return `[${match.slice(1)}]`;
      })}']`
    );
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);
  return null;
};

const BillingChargesLTL = () => {
  const { use } = useTheme();
  const { updateShipmentData, createShipmentData, updateBillToData, updateAssignToGroup, shipmentChargeType } =
    useContext(ShipmentContext);
  const [disableNextStep, setDisableNextStep] = useState(false);

  const onChangeDisabledNextStep = (value) => {
    setDisableNextStep(value);
  };

  const onNext = (values) => {
    updateShipmentData({
      ...createShipmentData,
      ...{
        ...values,
        shipmentsBilling: values.shipmentsBilling.map((billing) => {
          return {
            ...billing,
            billingCharges: billing.billingCharges.map((charge) =>
              charge?.charges?.type?.account_type === 'Expense' ? { ...charge, rate: `-${charge.rate}` } : charge
            ),
          };
        }),
      },
    });
  };

  const getBillTo = () => {
    return getBillToBillingCharges().then((res) => {
      if (res && res?.data) {
        const newData = res.data.map((item) => {
          return {
            ...item,
            label: item.company_name,
            key: item.id,
            labelSelected: null,
          };
        });
        updateBillToData(newData);
        return newData;
      }
    });
  };

  const getAssignToGroup = () => {
    return getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          const newData = res.data.map((item) => {
            return {
              ...item,
              label: item?.group_name,
              key: item.id,
              labelSelected: null,
            };
          });
          updateAssignToGroup(newData);
          return newData;
        }
        return res;
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onAddBilling = (arrayHelpers) => {
    onChangeDisabledNextStep(true);
    arrayHelpers.push({ ...initialValue });
  };

  const initialValue = {
    customer_id: createShipmentData?.customer_id ? createShipmentData?.customer_id : '',
    reference_id: createShipmentData?.reference_id ? createShipmentData?.reference_id : '',
    group: createShipmentData?.group ? createShipmentData?.group : '',
    contact_user_id: createShipmentData?.contact_user_id ? createShipmentData?.contact_user_id : '',
    payment_term_id: createShipmentData?.payment_term_id ? createShipmentData?.payment_term_id : '',
    billingCharges: createShipmentData?.billingCharges?.length
      ? createShipmentData.billingCharges.map((item) => ({
          ...item,
          charges: shipmentChargeType.find((el) => el.id === (item.charges?.id || item.charges)),
          rate: Math.abs(item.rate),
        }))
      : [{ ...getChangesTemplate() }],
  };

  const initialValueForm = useMemo(() => {
    return {
      shipmentsBilling:
        Array.isArray(createShipmentData?.shipmentsBilling) && createShipmentData?.shipmentsBilling.length > 0
          ? createShipmentData?.shipmentsBilling?.map((billing) => {
              return {
                ...billing,
                billingCharges: billing.billingCharges.map((item) => ({
                  ...item,
                  charges: shipmentChargeType.find((el) => el.id === item.charges),
                  rate: Math.abs(item.rate),
                })),
              };
            })
          : [{ ...initialValue }],
    };
  }, [createShipmentData]);

  useEffect(() => {
    getBillTo().then();
    getAssignToGroup().then();
  }, []);

  return (
    <div className={sm.billingLTL}>
      <Formik onSubmit={onNext} initialValues={initialValueForm}>
        {({ values, errors, touched, submitForm, setFieldValue, setFieldTouched, setTouched, validateForm }) => {
          let indexBilling = 0;
          return (
            <Form>
              <ScrollToFieldError />
              <FieldArray
                name='shipmentsBilling'
                render={(arrayHelpers) => {
                  const { shipmentsBilling } = values;
                  return (
                    <div className={sm.shipmentsBilling_filedArr_wrapper}>
                      {shipmentsBilling.map((el, index) => {
                        indexBilling = index;
                        return (
                          <div className={sm.map_wrapper} key={el.id}>
                            <div
                              className={sm.chargeMapWrapper}
                              style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
                            >
                              <div className={sm.start_count}>
                                <Typography variant='s2' style={{ color: palette.indigo600 }}>
                                  {index + 1}
                                </Typography>
                              </div>
                              <BillingChargesLTLOneCharge
                                values={el}
                                errors={errors}
                                touched={touched}
                                valuesForm={values}
                                indexPrefix={index}
                                setTouched={setTouched}
                                validateForm={validateForm}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                arrayHelpersPrefix={arrayHelpers}
                                namePrefix={`shipmentsBilling[${index}]`}
                                onChangeDisabledNextStep={onChangeDisabledNextStep}
                              />
                            </div>
                          </div>
                        );
                      })}

                      <div className={sm.addWrapper}>
                        <div className={sm.start_count_add}>
                          <Typography variant='s2' style={{ color: palette.indigo600 }}>
                            {shipmentsBilling.length + 1}
                          </Typography>
                        </div>
                        <div
                          className={sm.addClickZone}
                          style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
                          onClick={() => onAddBilling(arrayHelpers, indexBilling + 1)}
                        >
                          <PlusIcon fill={palette.indigo500} />
                          <Typography variant='s2' style={{ color: palette.indigo500, marginLeft: 8 }}>
                            Add another...
                          </Typography>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
              <BillingChargesLTLFooter
                values={values}
                submitForm={submitForm}
                validateForm={validateForm}
                disableNextStep={disableNextStep}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BillingChargesLTL;
