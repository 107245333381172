import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { getYearToDateSummary } from 'Api/Payroll';
import YTDTaxes from '../DetailsComponents/YTDTaxes';
import YTDBenefits from '../DetailsComponents/YTDBenefits';
import YTDTakeHome from '../DetailsComponents/YTDTakeHome';
import YTDDeductions from '../DetailsComponents/YTDDeductions';
import { SBoxWrapper } from './YearToDate.styles';

const YearToDate = ({ settlement }) => {
  const [loading, setLoading] = useState(false);
  const [yearToDate, setYearToDate] = useState(null);
  const { pay_period_end, user_id } = settlement ?? {};

  const getYearToDateData = async () => {
    try {
      setLoading(true);
      const { data } = await getYearToDateSummary({ user_type: 1, user_id, to: pay_period_end });
      setYearToDate(data || null);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getYearToDateData();
  }, [settlement.id]);

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!yearToDate) {
    return null;
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <Typography variant='h5'>{moment(pay_period_end).format('MMM D, YYYY')}</Typography>
      </div>
      <div className='mb-4'>
        <SBoxWrapper>
          <div className='d-flex flex-column justify-content-center'>
            <Typography variant='b1' style={{ color: palette.gray700 }}>
              Gross
            </Typography>
            <Typography variant='h3' style={{ color: palette.green400 }}>
              ${formatNumber(yearToDate.gross)}
            </Typography>
          </div>
        </SBoxWrapper>
      </div>
      <div className='d-flex flex-column gap-2' key={settlement.id}>
        <YTDTaxes taxes={yearToDate.taxes} gross={yearToDate.gross} />
        <YTDBenefits benefits={yearToDate.benefits} gross={yearToDate.gross} />
        <YTDDeductions data={yearToDate.other} gross={yearToDate.gross} />
        <YTDTakeHome yearToDate={yearToDate} gross={yearToDate.gross} />
      </div>
    </div>
  );
};

export default YearToDate;
