import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import ViewPdf from 'components/ViewPdf';
import { createBillOfLading, updateBillOfLading } from 'Api/Shipment';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment/moment';
import Input, { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Autocomplete from 'common/Autocomplete';
import Chip from 'common/Chip';
import { blockNonPositiveNumbers } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { SAddMore } from 'pages/Accounting/Payables/components/AddBill/AddBill.styles';
import CustomTimeInput from 'components/CustomTimeInput';
import { getBillToBillingCharges } from 'Api/Planner';
import { getCustomerContactBook } from 'Api/Customers';
import { generateBillOfLading } from './generatePdf';
import classes from './BillForm.module.scss';
import { SDatePickerWrapper } from '../../InfoBox.styles';
import { QUANTITY_TYPE } from '../../../../CreateShipment/ShipmentStops/helpers/constants';

const BillForm = ({ form, info, billNumber, existingBol }) => {
  const { values, handleChange, touchedErrors } = form;
  const { id, bolId } = useParams();
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const [pickUpStops, setPickUpStops] = useState([]);
  const [deliveryStops, setDeliveryStops] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customerContacts, setCustomerContacts] = useState([]);

  useEffect(() => {
    if (info) {
      const newPickUpStops = [];
      const newDeliveryStops = [];

      fillDateAndBillNumber();
      info?.shipment_stops.forEach((stop) => {
        if (stop.stop_point_type === '1' && stop.bill_type === '1') {
          newPickUpStops.push(stop);
        }
        if (stop.stop_point_type === '2' && stop.bill_type === '1') {
          newDeliveryStops.push(stop);
        }
      });
      setPickUpStops(newPickUpStops);
      setDeliveryStops(newDeliveryStops);
    }
    getCustomers();
  }, []);

  const fillDateAndBillNumber = () => {
    handleChange('blNumber', billNumber);
    handleChange(`ebolDate`, moment(new Date()).format('MM/DD/YYYY'));
  };

  const getCustomers = async () => {
    try {
      const { data } = await getBillToBillingCharges();
      setCustomers(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onChangePickUp = (e, val) => {
    handleChange('pickupLocationName', val.stop_point.location_name);
    handleChange('pickupAddress', val.stop_point.address1);
    handleChange('pickupCity', val.stop_point.city?.name);
    handleChange('pickupState', val.stop_point.state?.short_name);
    handleChange('pickupZip', val.stop_point.zipcode);
    handleChange('pickup', val);
    handleChange('trailerNumber', val.equipment?.equipment_id);
    handleChange('shipperNumber', info.shipment_billing?.[0].reference_id);

    const index = parseSubtractGetLastDigitSubOne(val.slave_stop?.shipment_id);
    handleChange('totalCharges', info.shipment_billing[index]?.total_amount);

    if (info.shipment_billing[index].payment_term?.title === 'Cash on Delivery') {
      handleChange('CODFee', 2);
      handleChange('CODAmount', info.shipment_billing[index]?.total_amount || '');
      handleChange('collect', true);
    } else {
      handleChange('CODFee', 1);
    }
    fillTable(e, val);
  };

  const onChangeCustomer = async (e, val) => {
    handleChange('contactPhone', null);
    handleChange('contactName', null);

    const { data } = await getCustomerContactBook({ id: val.id });
    setCustomerContacts(data);
    handleChange('invoice', val);
    handleChange('customerAddress1', val.address1);
    handleChange('customerAddress2', val.address2);
    handleChange('customerCity', val.city?.name);
    handleChange('customerState', val.state?.short_name);
    handleChange('customerZipCode', val.zipcode);
  };

  const onChangeCustomerContact = (e, val) => {
    handleChange('contactPhone', val.phone_number);
    handleChange('contactName', val);
  };

  function parseSubtractGetLastDigitSubOne(input) {
    if (input) {
      const numbers = input.split('-').map(Number);
      if (!Number.isNaN(numbers[1])) {
        return numbers[1] - 1;
      }
    }
    return 0;
  }

  const fillTable = (e, val) => {
    const index = 0;
    let dimensions;
    try {
      dimensions = JSON.parse(val.dimensions);
    } catch (error) {
      dimensions = null;
    }
    handleChange(`pickUpDetails[${index}].pickUpUnity`, 1);
    handleChange(`pickUpDetails[${index}].pickUpKind`, QUANTITY_TYPE[val.quantity_type].label);
    handleChange(`pickUpDetails[${index}].pickUpQty`, val.quantity);
    handleChange(`pickUpDetails[${index}].pickUpMeasure`, 'FT');
    handleChange(`pickUpDetails[${index}].pickUpHm`, !!val.hazardous_materials);
    handleChange(`pickUpDetails[${index}].pickUpDescription`, val.commodity_type?.title);
    handleChange(`pickUpDetails[${index}].pickUpNMFCItem`, '');
    handleChange(`pickUpDetails[${index}].pickUpClass`, '');
    handleChange(`pickUpDetails[${index}].pickUpWeight`, Number(val.weight));
    handleChange(`pickUpDetails[${index}].pickUpLength`, dimensions?.l);
    handleChange(`pickUpDetails[${index}].pickUpWidth`, dimensions?.w);
    handleChange(`pickUpDetails[${index}].pickUpHeight`, dimensions?.h);
  };

  const onChangeDelivery = (e, val) => {
    handleChange('deliveryLocationName', val.stop_point.location_name);
    handleChange('deliveryAddress', val.stop_point.address1);
    handleChange('deliveryCity', val.stop_point.city?.name);
    handleChange('deliveryState', val.stop_point.state?.short_name);
    handleChange('deliveryZip', val.stop_point.zipcode);
    handleChange('delivery', val);
  };

  const handleGenerate = async () => {
    setLoading(true);
    const { blob } = await generateBillOfLading({ data: values, shipment_id: id });
    if (bolId !== 'new' && existingBol) {
      const updateData = new FormData();
      updateData.append('data', JSON.stringify(values));
      updateData.append('file', blob);
      updateData.append('customer_invoice', values.invoice?.company_name || '');
      updateBillOfLading(bolId, updateData)
        .then(() => {
          navigate(`/shipment/${id}`, { state: { defaultTab: 9 } });
          setLoading(false);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
          setLoading(false);
        });
    } else {
      const formData = new FormData();
      formData.append('shipment_id', id);
      formData.append('bol_id', values.blNumber);
      formData.append('shipper_stop', values.pickup?.id);
      formData.append('receiver_stop', values.delivery?.id);
      formData.append('customer_invoice', values.invoice?.company_name || '');
      formData.append('file', blob);
      formData.append('data', JSON.stringify(values));
      createBillOfLading(formData)
        .then(() => {
          setLoading(false);
          navigate(`/shipment/${id}`, { state: { defaultTab: 9 } });
        })
        .catch((err) => {
          setLoading(false);
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const viewPdf = async () => {
    const { url } = await generateBillOfLading({ data: values, shipment_id: id });
    setPdfUrl(url);
  };

  const addRow = () => {
    const newPickUpDetails = [
      ...values.pickUpDetails,
      {
        pickUpUnity: '',
        pickUpKind: '',
        pickUpQty: '',
        pickUpMeasure: 'FT',
        pickUpHm: '',
        pickUpDescription: '',
        pickUpNMFCItem: '',
        pickUpClass: '',
        pickUpWeight: '',
        pickUpLength: '',
        pickUpWidth: '',
        pickUpHeight: '',
      },
    ];
    form.setValues({ ...values, pickUpDetails: newPickUpDetails });
  };

  const removeRow = (index) => {
    const newPickUpDetails = values.pickUpDetails.filter((_, idx) => idx !== index);
    form.setValues({ ...values, pickUpDetails: newPickUpDetails });
  };

  const totalPickUpUnity = useMemo(() => {
    return values.pickUpDetails.reduce((sum, row) => sum + (Number(row.pickUpUnity) || 0), 0);
  }, [values.pickUpDetails]);

  const totalQty = useMemo(() => {
    return values.pickUpDetails.reduce((sum, row) => sum + (Number(row.pickUpQty) || 0), 0);
  }, [values.pickUpDetails]);

  const totalWeight = useMemo(() => {
    return values.pickUpDetails.reduce((sum, row) => sum + (Number(row.pickUpWeight) || 0), 0);
  }, [values.pickUpDetails]);

  return (
    info && (
      <form>
        <div className='w-100 d-flex justify-content-between mb-3'>
          <div className='d-flex align-items-center gap-3'>
            <Typography variant='s2'>Date</Typography>
            <SDatePickerWrapper>
              <DatePicker
                value={values.ebolDate}
                showYearDropdown
                dateFormat='MM/dd/yyyy'
                placeholderText='MM/DD/YYYY'
                onChange={(val) => handleChange(`ebolDate`, moment(val).format('MM/DD/YYYY'))}
              />
            </SDatePickerWrapper>
          </div>
          <div className='d-flex align-items-center gap-3'>
            <Typography variant='s2'>B/L Number</Typography>
            <Input
              onChange={(e) => handleChange(`blNumber`, e.target.value)}
              value={values.blNumber}
              error={touchedErrors?.blNumber}
            />
          </div>
        </div>
        <Grid container columnSpacing={2.5} rowSpacing={3}>
          <Grid item xs={6}>
            <div className={classes.formBox}>
              <div className='d-flex flex-column gap-1 mb-2 w-50'>
                <Typography variant='c1'>Pickup</Typography>
                <Autocomplete
                  options={pickUpStops}
                  value={values.pickup}
                  onChange={(e, val) => onChangePickUp(e, val)}
                  getOptionLabel={(option) => {
                    return option
                      ? `${option.stop_point.location_name} - ${
                          info.shipment_billing?.[parseSubtractGetLastDigitSubOne(option.slave_stop?.shipment_id)]
                            ?.billing_customer?.company_name
                        }`
                      : '';
                  }}
                  renderTags={(values, getTagProps) =>
                    values.map((option, index) => (
                      <Chip
                        size='medium'
                        label={`${option.stop_point.location_name}`}
                        labelColor={palette.indigo500}
                        fontWeight={500}
                        bgColor={palette.indigo0}
                        deleteIcon={<DeleteIcon />}
                        height='20px'
                        limitTags={2}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </div>
              <div className='d-flex gap-3 w-100'>
                <div className='w-100'>
                  <Typography variant='c1'>Shipper Number</Typography>
                  <Input
                    size='small'
                    name='shipperNumber'
                    onChange={(e) => handleChange(`shipperNumber`, e.target.value)}
                    value={values.shipperNumber}
                    error={touchedErrors?.shipperNumber}
                  />
                </div>
                <div className='w-100'>
                  <Typography variant='c1'>Trailer Number</Typography>
                  <Input
                    size='small'
                    placeholder='Trailer Number'
                    name='trailer_number'
                    onChange={(e) => handleChange(`trailerNumber`, e.target.value)}
                    value={values.trailerNumber}
                    error={touchedErrors?.trailerNumber}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-3'>
                <div className='w-100'>
                  <Typography variant='c1'>Shipper Name</Typography>
                  <Input
                    size='small'
                    placeholder='Shipper Name'
                    name='pickupLocationName'
                    onChange={(e) => handleChange(`pickupLocationName`, e.target.value)}
                    value={values.pickupLocationName}
                    error={touchedErrors?.pickupLocationName}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-3'>
                <div className='w-100'>
                  <Typography variant='c1'>Address, City, State, Zip Code</Typography>
                  <Input
                    size='small'
                    name='pickupAddress'
                    placeholder='Address'
                    onChange={(e) => handleChange(`pickupAddress`, e.target.value)}
                    value={values.pickupAddress}
                    error={touchedErrors?.pickupAddress}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-2'>
                <div className='w-50'>
                  <Input
                    size='small'
                    name='pickupCity'
                    placeholder='City'
                    onChange={(e) => handleChange(`pickupCity`, e.target.value)}
                    value={values.pickupCity}
                    error={touchedErrors?.pickupCity}
                  />
                </div>
                <div className='w-25'>
                  <Input
                    size='small'
                    name='pickupState'
                    placeholder='State'
                    onChange={(e) => handleChange(`pickupState`, e.target.value)}
                    value={values.pickupState}
                    error={touchedErrors?.pickupState}
                  />
                </div>
                <div className='w-25'>
                  <Input
                    size='small'
                    name='pickupZip'
                    placeholder='Zip Code'
                    onChange={(e) => handleChange(`pickupZip`, e.target.value)}
                    value={values.pickupZip}
                    error={touchedErrors?.pickupZip}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.formBox}>
              <div className='d-flex flex-column gap-1 mb-2 w-50'>
                <Typography variant='c1'>Delivery</Typography>
                <Autocomplete
                  options={deliveryStops}
                  value={values.delivery}
                  onChange={(e, val) => onChangeDelivery(e, val)}
                  getOptionLabel={(option) => {
                    return option
                      ? `${option.stop_point.location_name} - ${
                          info.shipment_billing?.[parseSubtractGetLastDigitSubOne(option.slave_stop?.shipment_id)]
                            ?.billing_customer?.company_name
                        }`
                      : '';
                  }}
                  renderTags={(values, getTagProps) =>
                    values.map((option, index) => (
                      <Chip
                        size='medium'
                        label={`${option.stop_point.location_name}`}
                        labelColor={palette.indigo500}
                        fontWeight={500}
                        bgColor={palette.indigo0}
                        deleteIcon={<DeleteIcon />}
                        height='20px'
                        limitTags={2}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                />
              </div>
              <div className='d-flex gap-3 w-100'>
                <div className='w-100'>
                  <Typography variant='c1'>Receiver Number</Typography>
                  <Input
                    size='small'
                    name='deliveryNumber'
                    placeholder='Receiver Number'
                    onChange={(e) => handleChange(`deliveryNumber`, e.target.value)}
                    value={values.deliveryNumber}
                    error={touchedErrors?.deliveryNumber}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-3'>
                <div className='w-100'>
                  <Typography variant='c1'>Receiver Name</Typography>
                  <Input
                    size='small'
                    name='deliveryLocationName'
                    placeholder='Receiver Name'
                    onChange={(e) => handleChange(`deliveryLocationName`, e.target.value)}
                    value={values.deliveryLocationName}
                    error={touchedErrors?.deliveryLocationName}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-3'>
                <div className='w-100'>
                  <Typography variant='c1'>Address, City, State, Zip Code</Typography>
                  <Input
                    size='small'
                    name='deliveryAddress'
                    placeholder='Address'
                    onChange={(e) => handleChange(`deliveryAddress`, e.target.value)}
                    value={values.deliveryAddress}
                    error={touchedErrors?.deliveryAddress}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-2'>
                <div className='w-50'>
                  <Input
                    size='small'
                    name='deliveryCity'
                    placeholder='City'
                    onChange={(e) => handleChange(`deliveryCity`, e.target.value)}
                    value={values.deliveryCity}
                    error={touchedErrors?.deliveryCity}
                  />
                </div>
                <div className='w-25'>
                  <Input
                    size='small'
                    name='deliveryState'
                    placeholder='State'
                    onChange={(e) => handleChange(`deliveryState`, e.target.value)}
                    value={values.deliveryState}
                    error={touchedErrors?.deliveryState}
                  />
                </div>
                <div className='w-25'>
                  <Input
                    size='small'
                    name='deliveryZip'
                    placeholder='Zip Code'
                    onChange={(e) => handleChange(`deliveryZip`, e.target.value)}
                    value={values.deliveryZip}
                    error={touchedErrors?.deliveryZip}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.formBox}>
              <div className='d-flex gap-3 w-100'>
                <div className='w-100'>
                  <Typography variant='c1'>Customer Invoice</Typography>
                  <Autocomplete
                    size='small'
                    name='customer'
                    labelKey='company_name'
                    options={customers}
                    value={values.invoice}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => onChangeCustomer(e, value)}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-3'>
                <div className='w-100'>
                  <Typography variant='c1'>Address, City, State, Zip Code</Typography>
                  <Input
                    size='small'
                    name='customerAddress1'
                    placeholder='Address'
                    onChange={(e) => handleChange(`customerAddress1`, e.target.value)}
                    value={values.customerAddress1}
                    error={touchedErrors?.customerAddress1}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-2'>
                <div className='w-100'>
                  <Input
                    size='small'
                    name='customerAddress2'
                    placeholder='Address 2'
                    onChange={(e) => handleChange(`customerAddress2`, e.target.value)}
                    value={values.customerAddress2}
                    error={touchedErrors?.customerAddress2}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-2'>
                <div className='w-50'>
                  <Input
                    size='small'
                    name='customerCity'
                    placeholder='City'
                    onChange={(e) => handleChange(`customerCity`, e.target.value)}
                    value={values.customerCity}
                    error={touchedErrors?.customerCity}
                  />
                </div>
                <div className='w-25'>
                  <Input
                    size='small'
                    name='customerState'
                    placeholder='State'
                    onChange={(e) => handleChange(`customerState`, e.target.value)}
                    value={values.customerState}
                    error={touchedErrors?.customerState}
                  />
                </div>
                <div className='w-25'>
                  <Input
                    size='small'
                    name='customerZipCode'
                    placeholder='ZipCode'
                    onChange={(e) => handleChange(`customerZipCode`, e.target.value)}
                    value={values.customerZipCode}
                    error={touchedErrors?.customerZipCode}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.formBox}>
              <div className='d-flex gap-3 w-100'>
                <div className='w-100'>
                  <Typography variant='c1'>Customer Number</Typography>
                  <Input
                    size='small'
                    name='customerNumber'
                    placeholder='Customer Number'
                    onChange={(e) => handleChange(`customerNumber`, e.target.value)}
                    value={values.customerNumber}
                    error={touchedErrors?.customerNumber}
                  />
                </div>
                <div className='w-100'>
                  <Typography variant='c1'>Store Number</Typography>
                  <Input
                    size='small'
                    name='storeNumber'
                    placeholder='Store Number'
                    onChange={(e) => handleChange(`storeNumber`, e.target.value)}
                    value={values.storeNumber}
                    error={touchedErrors?.storeNumber}
                  />
                </div>
                <div className='w-100'>
                  <Typography variant='c1'>Department</Typography>
                  <Input
                    size='small'
                    name='department'
                    placeholder='Department'
                    onChange={(e) => handleChange(`department`, e.target.value)}
                    value={values.department}
                    error={touchedErrors?.department}
                  />
                </div>
              </div>

              <div className='d-flex gap-3 w-100 mt-3'>
                <div className='w-100'>
                  <Typography variant='c1'>P.O Number</Typography>
                  <Input
                    size='small'
                    name='poNumber'
                    placeholder='P.O Number'
                    onChange={(e) => handleChange(`poNumber`, e.target.value)}
                    value={values.poNumber}
                    error={touchedErrors?.poNumber}
                  />
                </div>
              </div>
              <div className='d-flex gap-3 w-100 mt-3'>
                <div className='w-100'>
                  <Typography variant='c1'>Special Instruction</Typography>
                  <Input
                    size='small'
                    name='specialInstruction'
                    placeholder='Special Instruction'
                    onChange={(e) => handleChange(`specialInstruction`, e.target.value)}
                    value={values.specialInstruction}
                    error={touchedErrors?.specialInstruction}
                  />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.formBox}>
              <div className='d-flex gap-3 align-items-center'>
                <div className='d-flex gap-1'>
                  <Typography variant='c1'>Contact Name</Typography>
                  <Autocomplete
                    size='small'
                    name='contactName'
                    labelKey='contact_name'
                    options={customerContacts}
                    value={values.contactName}
                    style={{ width: 200 }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => onChangeCustomerContact(e, value)}
                  />
                </div>
                <div className='d-flex gap-1'>
                  <Typography variant='c1'>Contact Phone</Typography>
                  <Input
                    size='small'
                    name='contactPhone'
                    onChange={(e) => handleChange(`contactPhone`, e.target.value)}
                    value={values.contactPhone}
                    error={touchedErrors?.contactPhone}
                  />
                </div>
              </div>

              <div className={classes.guaranteed}>
                <div className={classes.guaranteedItem}>
                  <Typography variant='overline'>STANDARD GUARANTEED</Typography>
                </div>
                <div className={classes.guaranteedSecontItem}>
                  <Typography variant='overline'>EXPEDITED GUARANTEED</Typography>
                </div>
              </div>

              <div className={classes.guarantedWrapper}>
                <div className={classes.standard}>
                  <CustomCheckbox
                    checked={values.guaranteedStandard}
                    onChange={(e) => handleChange('guaranteedStandard', e)}
                  >
                    <Typography variant='s2' style={{ marginLeft: '8px' }}>
                      Guaranteed Standard Service by 5 p.m. or end of business day
                    </Typography>
                  </CustomCheckbox>
                  <div className='d-flex gap-2 align-items-center'>
                    <CustomCheckbox
                      checked={values.guaranteedMultiday}
                      onChange={(e) => handleChange('guaranteedMultiday', e)}
                    >
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        Guaranteed Multiday Window Between
                      </Typography>
                    </CustomCheckbox>
                    <SDatePickerWrapper>
                      <DatePicker
                        value={values.timeFrom}
                        showYearDropdown
                        dateFormat='MM/dd/yyyy'
                        placeholderText='MM/DD/YYYY'
                        onChange={(val) => handleChange(`timeFrom`, moment(val).format('MM/DD/YYYY'))}
                      />
                    </SDatePickerWrapper>
                    <ArrowRight />
                    <SDatePickerWrapper>
                      <DatePicker
                        value={values.timeTo}
                        showYearDropdown
                        dateFormat='MM/dd/yyyy'
                        placeholderText='MM/DD/YYYY'
                        onChange={(val) => handleChange(`timeTo`, moment(val).format('MM/DD/YYYY'))}
                      />
                    </SDatePickerWrapper>
                  </div>
                  <div className={classes.standardFooter}>
                    <Typography variant='s-2'>Quote ID: {info.shipment_id}</Typography>
                  </div>
                </div>
                <div className={classes.expedited}>
                  <div className='d-flex gap-2'>
                    <CustomCheckbox checked={values.deliveryBy} onChange={(e) => handleChange('deliveryBy', e)}>
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        Time-Critical, Delivery By
                      </Typography>
                    </CustomCheckbox>
                    <SDatePickerWrapper>
                      <DatePicker
                        value={values.deliveryByDate}
                        showYearDropdown
                        dateFormat='MM/dd/yyyy'
                        placeholderText='MM/DD/YYYY'
                        onChange={(val) => handleChange(`deliveryByDate`, moment(val).format('MM/DD/YYYY'))}
                      />
                    </SDatePickerWrapper>
                  </div>
                  <div className='d-flex gap-2 mt-2 ms-4 align-items-center'>
                    <SubRight />
                    <CustomRadioButton
                      field={{
                        name: 'driverByTime',
                        value: values.driverByTime,
                        onChange: () => handleChange(`driverByTime`, 1),
                        checked: values.driverByTime === 1,
                      }}
                    >
                      <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                        By noon
                      </Typography>
                    </CustomRadioButton>

                    <CustomRadioButton
                      field={{
                        name: 'driverByTime',
                        value: values.driverByTime,
                        onChange: () => handleChange(`driverByTime`, 2),
                        checked: values.driverByTime === 2,
                      }}
                    >
                      <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                        By 5 PM or end of business day
                      </Typography>
                    </CustomRadioButton>
                  </div>

                  <div className='d-flex gap-2 mt-2'>
                    <CustomCheckbox checked={values.deliverOn} onChange={(e) => handleChange('deliverOn', e)}>
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        Time-Critical Hour Window, Deliver on
                      </Typography>
                    </CustomCheckbox>
                    <SDatePickerWrapper>
                      <DatePicker
                        value={values.deliverOnDate}
                        showYearDropdown
                        dateFormat='MM/dd/yyyy'
                        placeholderText='MM/DD/YYYY'
                        onChange={(val) => handleChange(`deliverOnDate`, moment(val).format('MM/DD/YYYY'))}
                      />
                    </SDatePickerWrapper>
                  </div>

                  <div className='d-flex gap-2 mt-2 ms-4 align-items-center'>
                    <SubRight />

                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                      Between
                    </Typography>

                    <CustomTimeInput
                      name='betweenFrom'
                      onChange={(v) => handleChange(`betweenFrom`, v)}
                      value={values.betweenFrom}
                      style={{ width: '80px' }}
                    />

                    <ArrowRight />

                    <CustomTimeInput
                      name='betweenTo'
                      onChange={(v) => handleChange(`betweenTo`, v)}
                      value={values.betweenTo}
                      style={{ width: '80px' }}
                    />
                  </div>

                  <div className='d-flex gap-2 mt-2'>
                    <CustomCheckbox checked={values.noDeliveryDate} onChange={(e) => handleChange('noDeliveryDate', e)}>
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        Time-Critical (fastest ground delivery – no delivery date required)
                      </Typography>
                    </CustomCheckbox>
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.dimensionsWrapper}>
              <div className={classes.CODWrapper}>
                <div className='d-flex gap-2 align-items-baseline'>
                  <Typography variant='button2'>COD fee:</Typography>
                  <CustomRadioButton
                    field={{
                      name: 'CODFee',
                      value: values.CODFee,
                      onChange: () => handleChange(`CODFee`, 1),
                      checked: values.CODFee === 1,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Prepaid
                    </Typography>
                  </CustomRadioButton>

                  <CustomRadioButton
                    field={{
                      name: 'CODFee',
                      value: values.CODFee,
                      onChange: () => handleChange(`CODFee`, 2),
                      checked: values.CODFee === 2,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Collect
                    </Typography>
                  </CustomRadioButton>
                </div>
                <div className='d-flex gap-2 align-items-baseline'>
                  <Typography variant='button2'>COD Amount:</Typography>
                  <InputWithIcon
                    size='small'
                    width='150px'
                    type='number'
                    placeholder='0.00'
                    name='CODAmount'
                    value={values.CODAmount}
                    onChange={(e) => handleChange(`CODAmount`, e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                </div>
                <div className='d-flex gap-2 align-items-baseline'>
                  <Typography variant='button2'>Customer check OK for COD amount?</Typography>
                  <CustomRadioButton
                    field={{
                      name: 'CODCheck',
                      value: values.CODCheck,
                      onChange: () => handleChange(`CODCheck`, 1),
                      checked: values.CODCheck === 1,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Yes
                    </Typography>
                  </CustomRadioButton>

                  <CustomRadioButton
                    field={{
                      name: 'CODCheck',
                      value: values.CODCheck,
                      onChange: () => handleChange(`CODCheck`, 2),
                      checked: values.CODCheck === 2,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      No
                    </Typography>
                  </CustomRadioButton>
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <th style={{ width: '90px' }}>Shipping units #</th>
                    <th style={{ width: '90px' }}>Kind of package</th>
                    <th style={{ width: '100px' }}>Qty #</th>
                    <th style={{ width: '90px' }}>Unit of measure</th>
                    <th style={{ width: '90px' }}>HM</th>
                    <th>Description of articles, special marks and exceptions</th>
                    <th style={{ width: '120px' }}>NMFC item #</th>
                    <th style={{ width: '100px' }}>Class</th>
                    <th style={{ width: '135px' }}>Weight (lb) Subject to correction</th>
                    <th colSpan='3' style={{ padding: 0, width: '300px' }}>
                      <table style={{ width: '100%', border: 'none' }}>
                        <tbody>
                          <tr>
                            <th colSpan='3' style={{ border: 'none' }}>
                              Shipment dimensions
                            </th>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <th
                              style={{
                                width: '33.33%',
                                border: 'none',
                                borderRight: '1px solid #D5DAE5',
                                borderTop: '1px solid #D5DAE5',
                              }}
                            >
                              Length
                            </th>
                            <th
                              style={{
                                width: '33.33%',
                                border: 'none',
                                borderRight: '1px solid #D5DAE5',
                                borderTop: '1px solid #D5DAE5',
                              }}
                            >
                              Width
                            </th>
                            <th
                              style={{
                                width: '33.33%',
                                border: 'none',
                                borderTop: '1px solid #D5DAE5',
                              }}
                            >
                              Height
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {values.pickUpDetails.map((row, index) => (
                    <tr>
                      <td>
                        <Input
                          size='small'
                          type='number'
                          name={`pickUpDetails[${index}].pickUpUnity`}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpUnity`, e.target.value)}
                          value={row.pickUpUnity}
                          error={touchedErrors?.pickUpUnity}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpKind`}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpKind`, e.target.value)}
                          value={row.pickUpKind}
                          error={touchedErrors?.pickUpKind}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          type='number'
                          name={`pickUpDetails[${index}].pickUpQty`}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpQty`, e.target.value)}
                          value={row.pickUpQty}
                          error={touchedErrors?.pickUpQty}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpMeasure`}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpMeasure`, e.target.value)}
                          value={row.pickUpMeasure}
                          error={touchedErrors?.pickUpMeasure}
                        />
                      </td>
                      <td>
                        <CustomCheckbox
                          checked={row.pickUpHm}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpHm`, e)}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpDescription`}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpDescription`, e.target.value)}
                          value={row.pickUpDescription}
                          error={touchedErrors?.pickUpDescription}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpNMFCItem`}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpNMFCItem`, e.target.value)}
                          value={row.pickUpNMFCItem}
                          error={touchedErrors?.pickUpNMFCItem}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpClass`}
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpClass`, e.target.value)}
                          value={row.pickUpClass}
                          error={touchedErrors?.pickUpClass}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpWeight`}
                          type='number'
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpWeight`, e.target.value)}
                          value={row.pickUpWeight}
                          error={touchedErrors?.pickUpWeight}
                        />
                      </td>

                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpLength`}
                          type='number'
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpLength`, e.target.value)}
                          value={row.pickUpLength}
                          error={touchedErrors?.pickUpLength}
                        />
                      </td>
                      <td>
                        <Input
                          size='small'
                          name={`pickUpDetails[${index}].pickUpWidth`}
                          type='number'
                          onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpWidth`, e.target.value)}
                          value={row.pickUpWidth}
                          error={touchedErrors?.pickUpWidth}
                        />
                      </td>
                      <td>
                        <div className='d-flex align-items-center gap-2'>
                          <Input
                            size='small'
                            name={`pickUpDetails[${index}].pickUpHeight`}
                            type='number'
                            onChange={(e) => handleChange(`pickUpDetails[${index}].pickUpHeight`, e.target.value)}
                            value={row.pickUpHeight}
                            error={touchedErrors?.pickUpHeight}
                          />

                          <DeleteIcon fill={palette.red500} onClick={() => removeRow(index)} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>{totalPickUpUnity}</td>
                    <td />
                    <td>{totalQty}</td>
                    <td />
                    <td />
                    <td>GRAND TOTAL</td>
                    <td />
                    <td />
                    <td>{totalWeight}</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                </tfoot>
              </table>
              <div className={classes.addAnother}>
                <SAddMore onClick={addRow}>
                  <PlusIcon />
                  <Typography variant='s2' style={{ color: palette.indigo500 }}>
                    Add Another
                  </Typography>
                </SAddMore>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ color: '#687182', fontStyle: 'italic', fontSize: 12 }}>
              “HM” stands for hazardous materials.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.chargesWrapper}>
              <div className={classes.emergencyWrapper}>
                <Typography variant='overline'>EMERGENCY CONTACT</Typography>
                <div className='d-flex gap-3'>
                  <div className='d-flex gap-1 align-items-center'>
                    <Typography variant='c1'>Phone</Typography>
                    <Input
                      size='small'
                      name='phone'
                      onChange={(e) => handleChange(`phone`, e.target.value)}
                      value={values.phone}
                      error={touchedErrors?.phone}
                    />
                  </div>
                  <div className='d-flex gap-1 align-items-center'>
                    <Typography variant='c1'>Name</Typography>
                    <Input
                      size='small'
                      name='name'
                      onChange={(e) => handleChange(`name`, e.target.value)}
                      value={values.name}
                      error={touchedErrors?.name}
                    />
                  </div>
                  <div className='d-flex gap-1 align-items-center'>
                    <Typography variant='c1'>Contract #</Typography>
                    <Input
                      size='small'
                      name='contract'
                      onChange={(e) => handleChange(`contract`, e.target.value)}
                      value={values.contract}
                      error={touchedErrors?.contract}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.collectWrapper}>
                <div className={classes.firstChild}>
                  <Typography variant='c1'>Shipment charges are prepaid, unless marked collect:</Typography>
                  <CustomCheckbox checked={values.collect} onChange={(e) => handleChange('collect', e)}>
                    <Typography variant='s2' style={{ marginLeft: '8px' }}>
                      Collect
                    </Typography>
                  </CustomCheckbox>
                </div>
                <div className={classes.secondChild}>
                  <Typography variant='c1'>Total charges</Typography>
                  <InputWithIcon
                    size='small'
                    width='150px'
                    type='number'
                    placeholder='0.00'
                    name='totalCharges'
                    value={values.totalCharges}
                    onChange={(e) => handleChange(`totalCharges`, e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.notesWrapper}>
              <div className='d-flex w-100 gap-2 align-items-baseline'>
                <div className='nowrap'>
                  <Typography variant='b3'>Note 1:</Typography>
                </div>
                <div className='d-flex flex-column gap-2 w-75 notes'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Where the rate depends on value, shippers must state specifically in writing the agreed or declared
                    value of the property as follows:
                  </Typography>
                  <Typography variant='b2' style={{ display: 'flex', color: palette.gray700 }}>
                    The agreed or declared value of the property is hereby specifically stated by the shipper to be not
                    exceeding &nbsp;
                    <Input
                      size='small'
                      name='note1'
                      onChange={(e) => handleChange(`note1`, e.target.value)}
                      value={values.note1}
                      error={touchedErrors?.note1}
                      style={{ width: '160px' }}
                    />
                    &nbsp; per &nbsp;
                    <Input
                      size='small'
                      name='note2'
                      onChange={(e) => handleChange(`note2`, e.target.value)}
                      value={values.note2}
                      error={touchedErrors?.note2}
                      style={{ width: '160px' }}
                    />
                  </Typography>
                </div>
              </div>
              <hr />

              <div className='d-flex w-100 gap-2 align-items-baseline'>
                <div className='nowrap'>
                  <Typography variant='b3'>Note 2:</Typography>
                </div>
                <div className='d-flex flex-column gap-2 w-75 notes'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Liability limitation for loss or damage on this shipment may be applicable.&nbsp;
                    <Typography variant='c1'>See 49 U.S.C. §14706 (c)(1)(A) and (B).</Typography>
                  </Typography>
                </div>
                <hr />
              </div>
              <hr />

              <div className='d-flex w-100 gap-2 align-items-baseline'>
                <div className='nowrap'>
                  <Typography variant='b3'>Note 3:</Typography>
                </div>
                <div className='d-flex flex-column gap-2 w-75 notes'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Products requiring special or additional care or attention in handling or stowing must be so marked
                    and packaged as to ensure safe transportation with ordinary care.
                  </Typography>
                  <Typography variant='c1' style={{ display: 'flex' }}>
                    See Sec. 2(e) of NMFC item 360.
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={10}>
            <div className={classes.notesWrapper}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                If this shipment is to be delivered to the consignee without recourse on the consignor, the consignor
                shall sign the following statement:
              </Typography>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                The carrier may decline to make delivery of this shipment without payment of freight and all other
                lawful charges.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.consignorWrapper}>
              <span className={classes.sign}>Signature of consignor</span>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.notesWrapper}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                Received subject to individually determined rates or written contracts that have been agreed on in
                writing between the carrier and shipper, if applicable, otherwise to rates, classifications and rules
                that have been established by the carrier and are available to the shipper on request.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.notesWrapper}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                The property described above is in apparent good order, except as noted (contents and condition of
                contents of packages unknown), marked, consigned, and destined, as indicated above which said carrier
                (the word carrier being understood throughout this contract as meaning any person or corporation in
                possession of the property under the contract) agrees to carry its usual place of delivery of said
                destination, if on its route, otherwise to deliver to another carrier on the route to said destination.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.notesWrapper}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                It is mutually agreed as to each carrier of all or any of said property over all or any portion of said
                route to destination and as to each party at any time interested in all or any of said property, that
                every service to be performed hereunder shall be subject to all the conditions not prohibited by law,
                whether printed or written, herein contained, including the conditions on the back hereof, which are
                hereby agreed to by the shipper and accepted for himself and his assigns.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.notesWrapper}>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                I hereby declare that the contents of this consignment are fully and accurately described above by the
                proper shipping name and are classified, packaged, marked and labeled/placarded and are in all respects
                in proper condition for transport according to applicable international and national governmental
                regulations.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.shipperCompanyWrapper}>
              <div className={classes.shipperCompany}>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Shipper company name
                </Typography>
                <Typography variant='s1' style={{ color: palette.gray900 }}>
                  {values.pickupLocationName}
                </Typography>
              </div>
              <div className={classes.carrier}>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Carrier
                </Typography>
                <Input
                  name='carrier'
                  onChange={(e) => handleChange(`carrier`, e.target.value)}
                  value={values.carrier}
                  error={touchedErrors?.carrier}
                  style={{ width: '350px' }}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.footerWrapper}>
              <div>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex gap-2 align-items-baseline'>
                    <Typography variant='c1' style={{ color: palette.gray700 }}>
                      Trailer or Vehicle #
                    </Typography>
                    <Input
                      size='small'
                      name='trailerOrVehicleNumber'
                      onChange={(e) => handleChange(`trailerOrVehicleNumber`, e.target.value)}
                      value={values.trailerOrVehicleNumber}
                      error={touchedErrors?.trailerOrVehicleNumber}
                    />
                    <Typography variant='c1' style={{ color: palette.gray700 }}>
                      Date
                    </Typography>
                    <SDatePickerWrapper>
                      <DatePicker
                        value={values.signDate}
                        showYearDropdown
                        dateFormat='MM/dd/yyyy'
                        placeholderText='MM/DD/YYYY'
                        onChange={(val) => handleChange(`signDate`, moment(val).format('MM/DD/YYYY'))}
                      />
                    </SDatePickerWrapper>
                    <Typography variant='c1' style={{ color: palette.gray700 }}>
                      H/U received:
                    </Typography>
                    <Input
                      size='small'
                      name='huReceived'
                      onChange={(e) => handleChange(`huReceived`, e.target.value)}
                      value={values.huReceived}
                      error={touchedErrors?.huReceived}
                    />
                  </div>
                  <div className='d-flex gap-2 align-items-baseline'>
                    <Typography variant='button2'>Trailer loaded by:</Typography>
                    <CustomRadioButton
                      field={{
                        name: 'trailerLoadedBy',
                        value: values.trailerLoadedBy,
                        onChange: () => handleChange(`trailerLoadedBy`, 1),
                        checked: values.trailerLoadedBy === 1,
                      }}
                    >
                      <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                        Shipper
                      </Typography>
                    </CustomRadioButton>

                    <CustomRadioButton
                      field={{
                        name: 'trailerLoadedBy',
                        value: values.trailerLoadedBy,
                        onChange: () => handleChange(`trailerLoadedBy`, 2),
                        checked: values.trailerLoadedBy === 2,
                      }}
                    >
                      <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                        Driver
                      </Typography>
                    </CustomRadioButton>
                  </div>
                </div>
              </div>
              <div>
                <div className='d-flex gap-2 align-items-baseline'>
                  <Typography variant='button2'>Freight counted by:</Typography>
                  <CustomRadioButton
                    field={{
                      name: 'freightCountedBy',
                      value: values.freightCountedBy,
                      onChange: () => handleChange(`freightCountedBy`, 1),
                      checked: values.freightCountedBy === 1,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Shipper
                    </Typography>
                  </CustomRadioButton>

                  <CustomRadioButton
                    field={{
                      name: 'freightCountedBy',
                      value: values.freightCountedBy,
                      onChange: () => handleChange(`freightCountedBy`, 2),
                      checked: values.freightCountedBy === 2,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Driver: Loose pieces
                    </Typography>
                  </CustomRadioButton>

                  <CustomRadioButton
                    field={{
                      name: 'freightCountedBy',
                      value: values.freightCountedBy,
                      onChange: () => handleChange(`freightCountedBy`, 3),
                      checked: values.freightCountedBy === 3,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Driver: pallets said to contain
                    </Typography>
                  </CustomRadioButton>

                  <CustomRadioButton
                    field={{
                      name: 'freightCountedBy',
                      value: values.freightCountedBy,
                      onChange: () => handleChange(`freightCountedBy`, 4),
                      checked: values.freightCountedBy === 4,
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Driver: pallets containing
                    </Typography>
                  </CustomRadioButton>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column'>
                  <Typography variant='button2'>Shipper Signature</Typography>
                  <div className={classes.signature} />
                  <div className={classes.name}>
                    <Typography variant='b2' style={{ color: palette.gray700, paddingLeft: 12 }}>
                      Name
                    </Typography>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <Typography variant='button2'>Receiver Signature</Typography>
                  <div className={classes.signature} />
                  <div className={classes.name}>
                    <Typography variant='b2' style={{ color: palette.gray700, paddingLeft: 12 }}>
                      Name
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className='d-flex justify-content-end mb-5 gap-3'>
              <CustomButton
                type='secondary'
                title='View'
                styleButton={{ justifyContent: 'center', padding: '6px 12px', margin: 0 }}
                styleTitle={{ fontSize: 14 }}
                onClick={viewPdf}
              />
              <CustomButton
                type='primary'
                disabled={!values.pickup?.id || !values.delivery?.id || loading}
                title={existingBol ? 'Regenerate Bill of Lading' : 'Generate Bill of Lading'}
                leftIcon={
                  loading ? (
                    <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                  ) : (
                    <div />
                  )
                }
                styleButton={{ justifyContent: 'center', padding: '6px 12px', margin: 0 }}
                styleTitle={{ fontSize: 14 }}
                onClick={handleGenerate}
              />
            </div>
          </Grid>
        </Grid>
        <ViewPdf open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
      </form>
    )
  );
};

export default BillForm;
