import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import AddressAutocomplete from 'common/AddressAutocomplete';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getCities, getCountries, getStates } from 'Api/JobPositions';
import { getUserProfile, updateUserProfile } from 'Api/User';
import { getStaffOnboarding } from 'Api/CompanySettings';
import { getErrorMessage } from 'utils/error';
import { validationSchema } from './validationSchema';
import { SAddressWrapper, SFlexRow } from './AddressStep.styles';

const AddressStep = ({ nextStep, value, sendOtp, handleSubmission }) => {
  const showToaster = useShowToaster();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onboardingSettings, setOnboardingSettings] = useState(null);

  const getSettings = async () => {
    try {
      const { data } = await getStaffOnboarding();
      setOnboardingSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('address1', values.address1);
      if (values.address2) {
        formData.append('address2', values.address2);
      }
      formData.append('country', values.country?.id);
      formData.append('state', values.state?.id);
      formData.append('city', values.city?.id);
      formData.append('zip', values.zip);

      const { data } = await updateUserProfile(formData);

      const user = localStorage.getItem('user');
      if (user) {
        const userData = JSON.parse(user);
        const updatedUserData = {
          ...userData,
          address1: data.address1,
          address2: data.address2,
          country: data.country,
          state: data.state,
          city: data.city,
          zip: data.zip,
        };
        localStorage.setItem('user', JSON.stringify(updatedUserData));
      }
      if (!onboardingSettings?.require_identification_photo) {
        if (value.user?.customer?.send_otp_to_user) {
          sendOtp();
          nextStep(true);
        } else {
          handleSubmission({ preventDefault: () => null }, true);
        }
      } else {
        nextStep();
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors, setValues } = useForm({
    initialValues: {
      address1: '',
      address2: '',
      country: null,
      state: null,
      city: null,
      zip: '',
    },
    validationSchema,
    onSubmit,
  });

  const getUser = async () => {
    try {
      const { data } = await getUserProfile();
      if (data) {
        setValues({
          ...values,
          address1: data.address1 || '',
          address2: data.address2 || '',
          country: data.country || null,
          state: data.state || null,
          city: data.city || null,
          zip: data.zip || '',
        });
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getCountriesList = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const onAddressSelect = (data) => {
    handleChange('country', data.country);
    handleChange('state', data.state);
    handleChange('city', data.city);
  };

  const changeAddress = ({ address, zip }) => {
    handleChange('address1', address);
    handleChange('zip', zip || values.zip);
  };

  useEffect(() => {
    getUser();
    getCountriesList();
  }, []);

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  return (
    <>
      <div className='modal-header-custom'>
        <h1 className='welcome-bg'>Address</h1>
        <p className='welcome-label'>Please fill out your address details!</p>
      </div>
      <div className='modal-body-custom'>
        <SAddressWrapper>
          <AddressAutocomplete
            required
            label='Address'
            name='address1'
            id='address1'
            onSuccess={onAddressSelect}
            changeAddress={changeAddress}
            onChange={handleChange}
            value={values.address1}
            error={touchedErrors?.address1}
          />
          <Input
            name='address2'
            className='w-100'
            placeholder='Address 2'
            onChange={handleChange}
            value={values.address2}
          />
          <SFlexRow>
            <div className='w-100'>
              <Autocomplete
                name='country'
                placeholder='Select Country..'
                value={values.country}
                onChange={(e, value) => {
                  handleChange('country', value);
                  handleChange('state', null);
                  handleChange('city', null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={countries}
                disableClearable={false}
                error={touchedErrors?.country}
              />
            </div>
            <div className='w-100'>
              <Autocomplete
                name='state'
                placeholder='Select State..'
                value={values.state}
                onChange={(e, value) => {
                  handleChange('state', value);
                  handleChange('city', null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={states}
                disableClearable={false}
                error={touchedErrors?.state}
              />
            </div>
          </SFlexRow>
          <SFlexRow>
            <div className='w-100'>
              <Autocomplete
                name='city'
                placeholder='Select City..'
                value={values.city}
                onChange={(e, value) => handleChange('city', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={cities}
                disableClearable={false}
                error={touchedErrors?.city}
              />
            </div>
            <div className='w-100'>
              <Input
                name='zip'
                className='w-100'
                placeholder='Zip Code'
                onChange={handleChange}
                value={values.zip}
                error={touchedErrors?.zip}
              />
            </div>
          </SFlexRow>
        </SAddressWrapper>
      </div>
      <div className='modal-footer-custom'>
        <button className='button-footer btn' onClick={handleSubmit} disabled={loading}>
          Continue
        </button>
      </div>
    </>
  );
};

export default AddressStep;
