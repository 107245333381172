import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/drivers/calendar.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/createShipment/copyBg.svg';
import Input from 'common/Input';
import Loader from 'common/Loader';
import DatePicker from 'common/DatePicker';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNotNumberChars } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import {
  connectEquipmentService,
  createEquipments,
  getEquipmentDimensions,
  getTrailers,
  validateEquipment,
} from 'Api/EquipmentImport';
import { getVendorsList } from 'Api/Vendors';
import useDateFormat from 'hooks/useDateFormat';
import { SYearMonthPicker } from 'common/DatePicker/DatePicker.styles';
import TableFooter from '../../../ApplicantsAndReferrals/components/TableFooter';
import OwnerFields from '../components/OwnerFields';
import AlertPopover from '../components/AlertPopover';
import { isValidRow } from './helpers';
import { getGVWRClass } from '../ImportSamsaraVehicles/helpers';
import { tableDataConverter, trailersConverter, connectServiceConverter } from './converters';
import { createEquipmentsConverter } from '../ImportSamsaraVehicles/converters';
import {
  cargoMeasuringUnits,
  disabledIds,
  liftGate,
  measuringUnits,
  trailerRequiredFieldsMapper,
} from '../Equipment.data';
import {
  SActionsWrapper,
  SCheckbox,
  SCustomModal,
  SFlexCenter,
  STable,
  STableData,
  SWarningIcon,
  SBackdrop,
} from '../Equipment.styles';

const ImportSamsaraTrailers = ({ open, onClose, data, states, ownerOperators, onCreateSuccess, isSamsara }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alertItem, setAlertItem] = useState(null);
  const [pagination, setPagination] = useState({ rowPerPage: 500, page: 1 });
  const [currentData, setCurrentData] = useState([]);
  const [vendors, setVendors] = useState([]);

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getRelatedData = async () => {
    try {
      const p1 = getTrailers();
      const p2 = getEquipmentDimensions();

      const [{ value: trailers }, { value: dimensions }] = await Promise.allSettled([p1, p2]);
      const convertedVehicles = trailersConverter(trailers?.data, dimensions?.data);
      setTrailers(convertedVehicles);
      return { trailers: convertedVehicles };
    } catch (e) {
      // Do nothing
    }
  };

  const validateRow = async (items) => {
    try {
      const body = createEquipmentsConverter(items, 'trailer');
      const { data } = await validateEquipment(body);
      const newTableData = [...items];
      data?.equipmentIdCheck?.forEach((item, index) => {
        if (item === 1) {
          newTableData[index]?.alerts?.push('Equipment ID already exists');
        }
      });
      return newTableData;
    } catch (e) {
      return items;
    }
  };

  const connectService = async (equipments) => {
    try {
      const batchCount = Math.ceil(equipments.length / 25);
      const batches = Array(batchCount)
        .fill(null)
        .map((item, index) => equipments.slice(index * 25, index * 25 + 25));

      const promises = batches.map((items) => {
        const body = { equipment: connectServiceConverter(items) };
        return connectEquipmentService(body);
      });

      const timeoutId = setTimeout(() => {
        showToaster({ type: 'success', message: 'Connecting service, please wait!' });
      }, 7000);

      await Promise.all(promises);
      clearTimeout(timeoutId);
      setCreateLoading(false);
      onCreateSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      setCreateLoading(false);
    }
  };

  const createEquipment = async () => {
    setCreateLoading(true);
    let createdEquipmentsWithSerialId;
    try {
      const body = createEquipmentsConverter(selectedRows, 'trailer');
      const { data } = await createEquipments(body);
      if (isSamsara) {
        createdEquipmentsWithSerialId = data?.createdEquipments?.map((item) => {
          const equipment = selectedRows.find((i) => i.equipment_id === item.equipment_id);
          return { ...item, serial_id: equipment.serial_id || null };
        });
      }
      if (data?.successCount > 0) {
        showToaster({
          type: 'success',
          message: `Successfully imported ${data.successCount} trailers! ${
            isSamsara ? 'It may take 5 to 15 minutes for all samsara connections to appear.' : ''
          }`,
        });
      } else {
        showToaster({ type: 'error', message: 'Failed to import trailers!' });
        setCreateLoading(false);
        return;
      }

      const filteredTableData = tableData.filter(
        (item) => !(data?.createdEquipments || []).some((i) => i.equipment_id === item.equipment_id)
      );
      const filteredSelectedRows = selectedRows.filter(
        (item) => !(data?.createdEquipments || []).some((i) => i.equipment_id === item.equipment_id)
      );
      const filteredCurrentData = currentData.filter(
        (item) => !(data?.createdEquipments || []).some((i) => i.equipment_id === item.equipment_id)
      );
      setTableData(filteredTableData);
      setSelectedRows(filteredSelectedRows);
      setCurrentData(filteredCurrentData);

      if (!createdEquipmentsWithSerialId?.length) {
        onCreateSuccess();
        setCreateLoading(false);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      setCreateLoading(false);
    }

    if (createdEquipmentsWithSerialId?.length) {
      connectService(createdEquipmentsWithSerialId);
    } else {
      setCreateLoading(false);
    }
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setRowToEdit((prevState) => ({ ...prevState, [name]: value }));
  };

  const onEquipmentTypeChange = (value) => {
    setRowToEdit((prevState) => ({
      ...prevState,
      equipment_type_id: value,
      length: null,
      width: '',
      height: '',
      cargo_maximum_capacity_weight: '',
    }));
  };

  const onGrossWeightChange = (value) => {
    const gvwrClass = getGVWRClass(value, rowToEdit.gross_vehicle_measuring_unit);

    setRowToEdit((prevState) => ({
      ...prevState,
      gross_vehicle_weight: value,
      gvwr_class: gvwrClass || prevState.gvwr_class,
    }));
  };

  const onGrossWeightUnitChange = (value) => {
    const gvwrClass = getGVWRClass(rowToEdit.gross_vehicle_weight, value);

    setRowToEdit((prevState) => ({
      ...prevState,
      gross_vehicle_measuring_unit: value,
      gvwr_class: gvwrClass || prevState.gvwr_class,
    }));
  };

  const onLengthChange = (value) => {
    setRowToEdit((prevState) => ({
      ...prevState,
      length: value,
      width: value.width || '',
      height: value.height || '',
      cargo_maximum_capacity_weight: value.cargo_payload_weight || '',
    }));
  };

  const onLicensePlateNoExpirationChange = (checked) => {
    setRowToEdit((prevState) => ({
      ...prevState,
      license_plate_no_expiry: checked,
      license_plate_expire_date: null,
    }));
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== currentData.length ? currentData : []);
  };

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const onRowEditConfirm = async () => {
    setConfirmLoading(true);
    const updatedTableData = tableData.map((item) => (item.id === rowToEdit.id ? { ...rowToEdit, alerts: [] } : item));
    const existsInSelected = selectedRows.some((item) => item.id === rowToEdit.id);
    if (existsInSelected) {
      const updatedSelectedRows = selectedRows.map((item) =>
        item.id === rowToEdit.id ? { ...rowToEdit, alerts: [] } : item
      );
      setSelectedRows(updatedSelectedRows);
    }

    const validatedTableData = await validateRow(updatedTableData);
    const current = validatedTableData.slice(
      (pagination.page - 1) * pagination.rowPerPage,
      pagination.page * pagination.rowPerPage
    );
    setCurrentData(current);
    setTableData(validatedTableData);
    setRowToEdit(null);
    setConfirmLoading(false);
  };

  const onColumnCopy = (key) => {
    const value = currentData[0]?.[key];

    if (value) {
      const updatedCurrentData = currentData.map((item) => {
        const newData = {
          ...item,
          [key]: value,
          alerts: item.alerts.filter((i) => i !== trailerRequiredFieldsMapper[key]),
        };

        if (key === 'owner_operator') {
          newData.owner = currentData[0]?.owner;
          newData.leasing_company_name = currentData[0]?.leasing_company_name;
          newData.lease_or_credit_payment = currentData[0]?.lease_or_credit_payment;
        }

        if (key === 'gross_vehicle_weight') {
          newData.gvwr_class = getGVWRClass(value, item.gross_vehicle_measuring_unit);
        }

        if (key === 'license_plate_no_expiry') {
          newData.license_plate_expire_date = null;
          newData.license_plate_no_expiry = true;
        }

        return newData;
      });
      setCurrentData(updatedCurrentData);

      const updatedSelectedRows = selectedRows.map((item) => {
        const newData = {
          ...item,
          [key]: value,
          alerts: item.alerts.filter((i) => i !== trailerRequiredFieldsMapper[key]),
        };

        if (key === 'owner_operator') {
          newData.owner = currentData[0]?.owner;
          newData.leasing_company_name = currentData[0]?.leasing_company_name;
          newData.lease_or_credit_payment = currentData[0]?.lease_or_credit_payment;
        }

        if (key === 'gross_vehicle_weight') {
          newData.gvwr_class = getGVWRClass(value, item.gross_vehicle_measuring_unit);
        }

        if (key === 'license_plate_no_expiry') {
          newData.license_plate_expire_date = null;
          newData.license_plate_no_expiry = true;
        }

        return newData;
      });
      setSelectedRows(updatedSelectedRows);

      const updatedTableData = tableData.map((item) => {
        const newData = {
          ...item,
          [key]: value,
          alerts: item.alerts.filter((i) => i !== trailerRequiredFieldsMapper[key]),
        };

        if (key === 'owner_operator') {
          newData.owner = currentData[0]?.owner;
          newData.leasing_company_name = currentData[0]?.leasing_company_name;
          newData.lease_or_credit_payment = currentData[0]?.lease_or_credit_payment;
        }

        if (key === 'gross_vehicle_weight') {
          newData.gvwr_class = getGVWRClass(value, item.gross_vehicle_measuring_unit);
        }

        if (key === 'license_plate_no_expiry') {
          newData.license_plate_expire_date = null;
          newData.license_plate_no_expiry = true;
        }

        return newData;
      });
      setTableData(updatedTableData);
    }
  };

  const resetState = () => {
    setTableData([]);
    setTrailers([]);
    setSelectedRows([]);
    setRowToEdit(null);
    setPagination({ rowPerPage: 500, page: 1 });
    setCurrentData([]);
    setAlertItem(null);
  };

  const onPageChange = (page) => {
    setSelectedRows([]);
    setRowToEdit(null);
    setPagination((prevState) => ({ ...prevState, page }));
    setCurrentData(tableData.slice((page - 1) * pagination.rowPerPage, page * pagination.rowPerPage));
  };

  const onRowPerPageChange = (rowPerPage) => {
    setSelectedRows([]);
    setRowToEdit(null);
    setPagination({ page: 1, rowPerPage });
    setCurrentData(tableData.slice(0, rowPerPage));
  };

  useEffect(() => {
    (async () => {
      if (open) {
        setLoading(true);
        getVendors();
        const relatedData = await getRelatedData();
        const convertedData = tableDataConverter(data, { ...(relatedData || {}), states });
        const newTableData = await validateRow(convertedData);
        setTableData(newTableData);
        const current = newTableData.slice(0, pagination.rowPerPage);
        setCurrentData(current);
        setLoading(false);
      }
    })();
  }, [open]);

  const [anchorEl, setAnchorEl] = useState(null);
  const alertPopupOpen = Boolean(anchorEl);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      onExited={resetState}
      headerTitle='Import Trailers'
      $minWidth='80vw'
      $maxWidth='80vw'
      $height='auto'
      $minHeight='60vh'
      $bodyMaxHeight='calc(100vh - 184px)'
      backdrop='static'
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', className: 'modal-cancel-btn' },
        {
          key: 'submit',
          type: 'primary',
          title: `Import ${selectedRows?.length ? `${selectedRows.length} Trailer` : ''}${
            selectedRows?.length > 1 ? 's' : ''
          }`,
          className: 'modal-submit-btn',
          onClick: () => createEquipment(),
          disabled: createLoading || !selectedRows?.length || selectedRows.some((item) => !!item?.alerts?.length),
        },
      ]}
    >
      <Loader loading={loading} />
      {!loading && (
        <STable>
          <thead>
            <tr className='header-row'>
              <th>
                <SCheckbox onChange={handleSelectAll} checked={selectedRows.length === currentData.length} />
              </th>
              <th>ACTIONS</th>
              <th />
              <th>EQUIPMENT ID</th>
              <th>EQUIPMENT TYPE</th>
              <th>MAKE</th>
              <th>MODEL</th>
              <th>MANUFACTURED DATE</th>
              {isSamsara && <th>SAMSARA SERIAL</th>}
              <th>VIN</th>
              <th>TIRE SIZE</th>
              <th>AXLES</th>
              <th>COLOR</th>
              <th>GROSS VEHICLE WEIGHT</th>
              <th>GROSS VEHICLE UNIT</th>
              <th>GVWR CLASS</th>
              <th>LENGTH</th>
              <th>WIDTH</th>
              <th>HEIGHT</th>
              <th>CARGO PAYLOAD</th>
              <th>CARGO CAPACITY UNIT</th>
              <th>LIFT GATE</th>
              <th>EQUIPMENT OWNER</th>
              <th>LICENSE PLATE NUMBER</th>
              <th>PLATE STATE</th>
              <th>PLATE EXPIRY DATE</th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item, index) => (
              <tr className={`body-row ${item?.alerts?.length ? 'invalid-row' : ''}`} key={item.id}>
                <STableData>
                  <SCheckbox
                    onChange={(e) => handleSelectRow(e, item)}
                    checked={selectedRows.some((i) => i.id === item.id)}
                  />
                </STableData>
                <STableData>
                  <SFlexCenter>
                    {rowToEdit?.id === item.id ? (
                      <SActionsWrapper>
                        {confirmLoading ? (
                          <CircularProgress size={14} />
                        ) : (
                          <CheckIcon
                            className={isValidRow(rowToEdit) ? '' : 'disabled-save-icon'}
                            width={14}
                            height={14}
                            onClick={onRowEditConfirm}
                          />
                        )}
                        <CancelIcon width={14} height={14} onClick={() => setRowToEdit(null)} />
                      </SActionsWrapper>
                    ) : (
                      <ActionDots className='action-dots' title='Edit' onClick={() => setRowToEdit(item)} />
                    )}
                  </SFlexCenter>
                </STableData>
                <STableData>
                  {!!item.alerts?.length && (
                    <SWarningIcon
                      onClick={(e) => {
                        setAlertItem(item);
                        setAnchorEl(e.currentTarget);
                      }}
                    />
                  )}
                </STableData>
                <STableData>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='equipment_id'
                        onChange={onInputChange}
                        value={rowToEdit.equipment_id}
                        error={!rowToEdit.equipment_id && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.equipment_id}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='200px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Autocomplete
                        size='small'
                        name='equipment_type_id'
                        options={trailers}
                        labelKey='title'
                        value={rowToEdit.equipment_type_id || null}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, value) => onEquipmentTypeChange(value)}
                        error={!rowToEdit.equipment_type_id && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.equipment_type_id?.title}{' '}
                        {!!item.equipment_type_id && index === 0 && (
                          <CopyIcon onClick={() => onColumnCopy('equipment_type_id')} />
                        )}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='150px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='make'
                        onChange={onInputChange}
                        value={rowToEdit.make}
                        error={!rowToEdit.make && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.make} {!!item.make && index === 0 && <CopyIcon onClick={() => onColumnCopy('make')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='140px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='model_id'
                        onChange={onInputChange}
                        value={rowToEdit.model_id}
                        error={!rowToEdit.model_id && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.model_id}{' '}
                        {!!item.model_id && index === 0 && <CopyIcon onClick={() => onColumnCopy('model_id')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='120px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <SYearMonthPicker $width='150px'>
                        <CalendarIcon />
                        <ReactDatePicker
                          size='small'
                          dateFormat='MM/yyyy'
                          placeholderText='MM/YYYY'
                          showYearDropdown
                          showMonthYearPicker
                          customInput={<Input />}
                          selected={moment(rowToEdit.year).isValid() ? moment(rowToEdit.year).toDate() : null}
                          onChange={(value) => onInputChange({ target: { name: 'year', value } })}
                          maxDate={new Date()}
                        />
                      </SYearMonthPicker>
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {moment(item.year, 'MM/YYYY').isValid() ? moment(item.year).format('MM/YYYY') : ''}{' '}
                        {!!item.year && index === 0 && <CopyIcon onClick={() => onColumnCopy('year')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                {isSamsara && (
                  <STableData width='150px'>
                    <div className='import-equipment-input-wrapper'>
                      {rowToEdit?.id === item.id ? (
                        <Input size='small' name='serial_id' onChange={onInputChange} value={rowToEdit.serial_id} />
                      ) : (
                        <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                          {item.serial_id}
                        </Typography>
                      )}
                    </div>
                  </STableData>
                )}
                <STableData width='190px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input size='small' name='vin' onChange={onInputChange} value={rowToEdit.vin} />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.vin}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='120px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input size='small' name='tire_size' onChange={onInputChange} value={rowToEdit.tire_size} />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.tire_size}{' '}
                        {!!item.tire_size && index === 0 && <CopyIcon onClick={() => onColumnCopy('tire_size')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='120px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input size='small' type='number' name='axles' onChange={onInputChange} value={rowToEdit.axles} />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.axles} {!!item.axles && index === 0 && <CopyIcon onClick={() => onColumnCopy('axles')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='120px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='color'
                        onChange={onInputChange}
                        value={rowToEdit.color}
                        error={!rowToEdit.color && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.color}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        type='number'
                        size='small'
                        name='gross_vehicle_weight'
                        onChange={(e) => onGrossWeightChange(e.target.value)}
                        value={rowToEdit.gross_vehicle_weight}
                        onKeyDown={blockNotNumberChars}
                        error={!rowToEdit.gross_vehicle_weight && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.gross_vehicle_weight}{' '}
                        {!!item.gross_vehicle_weight && index === 0 && (
                          <CopyIcon onClick={() => onColumnCopy('gross_vehicle_weight')} />
                        )}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Autocomplete
                        size='small'
                        name='gross_vehicle_measuring_unit'
                        labelKey='label'
                        options={measuringUnits}
                        value={rowToEdit.gross_vehicle_measuring_unit || null}
                        onChange={(e, value) => onGrossWeightUnitChange(value)}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.gross_vehicle_measuring_unit?.label}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input size='small' name='gvwr_class' value={rowToEdit.gvwr_class} disabled />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.gvwr_class}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='100px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Autocomplete
                        size='small'
                        name='length'
                        options={rowToEdit.equipment_type_id?.dimensions || []}
                        labelKey='length'
                        value={rowToEdit.length || null}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, value) => onLengthChange(value)}
                        disabled={
                          !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                        }
                        className={
                          !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                            ? 'bg-gray'
                            : ''
                        }
                        error={
                          !!rowToEdit.equipment_type_id?.id &&
                          !disabledIds.includes(rowToEdit.equipment_type_id?.id) &&
                          !rowToEdit.length &&
                          'Required'
                        }
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.length?.length}{' '}
                        {!!item.length && index === 0 && <CopyIcon onClick={() => onColumnCopy('length')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='100px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='width'
                        onChange={onInputChange}
                        value={rowToEdit.width}
                        disabled
                        style={{
                          background:
                            !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                              ? palette.gray50
                              : palette.white,
                        }}
                        error={
                          !!rowToEdit.equipment_type_id?.id &&
                          !disabledIds.includes(rowToEdit.equipment_type_id?.id) &&
                          !rowToEdit.width &&
                          'Required'
                        }
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.width} {!!item.width && index === 0 && <CopyIcon onClick={() => onColumnCopy('width')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='100px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='height'
                        onChange={onInputChange}
                        value={rowToEdit.height}
                        disabled
                        style={{
                          background:
                            !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                              ? palette.gray50
                              : palette.white,
                        }}
                        error={
                          !!rowToEdit.equipment_type_id?.id &&
                          !disabledIds.includes(rowToEdit.equipment_type_id?.id) &&
                          !rowToEdit.height &&
                          'Required'
                        }
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.height}{' '}
                        {!!item.height && index === 0 && <CopyIcon onClick={() => onColumnCopy('height')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='cargo_maximum_capacity_weight'
                        onChange={onInputChange}
                        value={rowToEdit.cargo_maximum_capacity_weight}
                        disabled={
                          !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                        }
                        style={{
                          background:
                            !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                              ? palette.gray50
                              : palette.white,
                        }}
                        error={
                          !!rowToEdit.equipment_type_id?.id &&
                          !disabledIds.includes(rowToEdit.equipment_type_id?.id) &&
                          !rowToEdit.cargo_maximum_capacity_weight &&
                          'Required'
                        }
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.cargo_maximum_capacity_weight}{' '}
                        {!!item.cargo_maximum_capacity_weight && index === 0 && (
                          <CopyIcon onClick={() => onColumnCopy('cargo_maximum_capacity_weight')} />
                        )}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Autocomplete
                        size='small'
                        name='cargo_capacity_measuring_unit'
                        labelKey='label'
                        options={cargoMeasuringUnits}
                        value={rowToEdit.cargo_capacity_measuring_unit}
                        onChange={(e, value) =>
                          onInputChange({ target: { name: 'cargo_capacity_measuring_unit', value } })
                        }
                        disabled={
                          !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                        }
                        className={
                          !rowToEdit.equipment_type_id?.id || disabledIds.includes(rowToEdit.equipment_type_id?.id)
                            ? 'bg-gray'
                            : ''
                        }
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.cargo_capacity_measuring_unit?.label}{' '}
                        {!!item.cargo_capacity_measuring_unit && index === 0 && (
                          <CopyIcon onClick={() => onColumnCopy('cargo_capacity_measuring_unit')} />
                        )}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='100px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Autocomplete
                        size='small'
                        name='lift_gate'
                        options={liftGate}
                        labelKey='label'
                        value={rowToEdit.lift_gate || null}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(e, value) => onInputChange({ target: { name: 'lift_gate', value } })}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.lift_gate?.label}{' '}
                        {!!item.lift_gate && index === 0 && <CopyIcon onClick={() => onColumnCopy('lift_gate')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData
                  width={
                    rowToEdit?.owner_operator?.value === '2'
                      ? '422px'
                      : rowToEdit?.owner_operator?.value === '3'
                      ? '340px'
                      : '220px'
                  }
                >
                  {rowToEdit?.id === item.id ? (
                    <OwnerFields
                      rowToEdit={rowToEdit}
                      onInputChange={onInputChange}
                      ownerOperators={ownerOperators}
                      vendors={vendors}
                      onAddVendorSuccess={(newVendor) => {
                        getVendors();
                        onInputChange({ target: { name: 'leasing_company_name', value: newVendor.name } });
                      }}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                      {item.owner_operator?.label}{' '}
                      {!!item.owner_operator && index === 0 && (
                        <CopyIcon onClick={() => onColumnCopy('owner_operator')} />
                      )}
                    </Typography>
                  )}
                </STableData>
                <STableData>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Input
                        size='small'
                        name='license_plate_number'
                        onChange={onInputChange}
                        value={rowToEdit.license_plate_number}
                        error={!rowToEdit.license_plate_number && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.license_plate_number}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData width='150px'>
                  <div className='import-equipment-input-wrapper'>
                    {rowToEdit?.id === item.id ? (
                      <Autocomplete
                        size='small'
                        name='state'
                        labelKey='label'
                        placeholder='Select State..'
                        value={rowToEdit.state || null}
                        onChange={(e, value) => onInputChange({ target: { name: 'state', value } })}
                        options={states || []}
                        error={!rowToEdit.state && 'Required'}
                      />
                    ) : (
                      <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.state?.label}{' '}
                        {!!item.state && index === 0 && <CopyIcon onClick={() => onColumnCopy('state')} />}
                      </Typography>
                    )}
                  </div>
                </STableData>
                <STableData>
                  {rowToEdit?.id === item.id ? (
                    <>
                      <DatePicker
                        size='small'
                        className={rowToEdit.license_plate_no_expiry ? 'disabled-date-picker' : ''}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={10}
                        name='license_plate_expire_date'
                        value={rowToEdit.license_plate_expire_date}
                        minDate={new Date()}
                        onChange={(value) => onInputChange({ target: { name: 'license_plate_expire_date', value } })}
                        disabled={rowToEdit.license_plate_no_expiry}
                      />
                      <CustomCheckbox
                        name='license_plate_no_expiry'
                        type='checkbox'
                        checked={rowToEdit.license_plate_no_expiry}
                        onChange={(value) => onLicensePlateNoExpirationChange(value)}
                      >
                        <Typography
                          variant='s2'
                          style={{ color: use(palette.gray700, palette.gray50), marginLeft: '8px' }}
                        >
                          No Expiration
                        </Typography>
                      </CustomCheckbox>
                    </>
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray900, palette.gray50) }}>
                      {!!item.license_plate_no_expiry && 'No Expiration'}
                      {!item.license_plate_no_expiry &&
                        item.license_plate_expire_date &&
                        formatDate(item.license_plate_expire_date)}{' '}
                      {!!item.license_plate_expire_date && index === 0 && (
                        <CopyIcon onClick={() => onColumnCopy('license_plate_expire_date')} />
                      )}
                      {!!item.license_plate_no_expiry && index === 0 && (
                        <CopyIcon onClick={() => onColumnCopy('license_plate_no_expiry')} />
                      )}
                    </Typography>
                  )}
                </STableData>
              </tr>
            ))}
          </tbody>
        </STable>
      )}
      <TableFooter
        rowPerPage={pagination.rowPerPage}
        totalCount={tableData.length}
        totalLength={currentData.length}
        lastPage={Math.ceil(tableData.length / pagination.rowPerPage)}
        currentPage={pagination.page}
        onChangeRowPerPage={onRowPerPageChange}
        onPageChange={onPageChange}
      />
      <AlertPopover
        open={alertPopupOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAlertItem(null);
          setAnchorEl(null);
        }}
        data={alertItem}
      />
      <SBackdrop open={!!createLoading}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default ImportSamsaraTrailers;
