import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 30%;
  min-width: 480px;
  max-width: 600px;
  border-right: 1px solid ${palette.gray100};
  padding: 24px 20px 0 0;
`;

export const SCardsWrapper = styled.div`
  height: 74vh;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
