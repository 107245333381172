import axios from '../services/axios';

// Pay period
export async function getPayPeriod(params) {
  const { data } = await axios.get('payroll/pay-period', {
    params,
  });
  return data;
}
export async function updatePayPeriod(body) {
  const { data } = await axios.put(`payroll/pay-period`, body);
  return data;
}

// Deductions
export async function getDeductionsList() {
  const { data } = await axios.get(`payroll/deductions`);
  return data;
}
export async function createDeduction(body) {
  const { data } = await axios.post(`payroll/deduction`, body);
  return data;
}
export async function updateDeduction(id, body) {
  const { data } = await axios.put(`payroll/deduction/${id}`, body);
  return data;
}
export async function deleteDeduction(id) {
  const { data } = await axios.delete(`payroll/deduction/${id}`);
  return data;
}
export async function attachTaxes(id, body) {
  const { data } = await axios.post(`payroll/${id}/deductions-taxes`, body);
  return data;
}

// Additions
export async function getAdditionsList() {
  const { data } = await axios.get(`payroll/additions`);
  return data;
}
export async function createAddition(body) {
  const { data } = await axios.post(`payroll/addition`, body);
  return data;
}
export async function updateAddition(id, body) {
  const { data } = await axios.put(`payroll/addition/${id}`, body);
  return data;
}
export async function deleteAddition(id) {
  const { data } = await axios.delete(`payroll/addition/${id}`);
  return data;
}

// Taxes
export async function getTaxesList() {
  const { data } = await axios.get(`payroll/taxes`);
  return data;
}
export async function createTax(body) {
  const { data } = await axios.post(`payroll/tax`, body);
  return data;
}
export async function updateTax(id, body) {
  const { data } = await axios.put(`payroll/tax/${id}`, body);
  return data;
}
export async function deleteTax(id) {
  const { data } = await axios.delete(`payroll/tax/${id}`);
  return data;
}

// Benefits
export async function getBenefitsList() {
  const { data } = await axios.get(`payroll/benefits`);
  return data;
}
export async function createBenefit(body) {
  const { data } = await axios.post(`payroll/benefit`, body);
  return data;
}
export async function updateBenefit(id, body) {
  const { data } = await axios.put(`payroll/benefit/${id}`, body);
  return data;
}
export async function deleteBenefit(id) {
  const { data } = await axios.delete(`payroll/benefit/${id}`);
  return data;
}

// Notification
export async function getNotificationSettings() {
  const { data } = await axios.get(`payroll/notification-setting`);
  return data;
}

export async function updateNotificationSettings(body) {
  const { data } = await axios.put(`payroll/notification-setting`, body);
  return data;
}

export async function getCheckStartingNumber(params) {
  const { data } = await axios.get(`/payroll/get-number`, { params });
  return data;
}
