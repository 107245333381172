import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PhoneIcon } from 'assets/icons/equipment/Phone.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { STable, SLabel, SText, SIconWrapper } from './VendorDetails.styles';

const VendorDetails = ({ vendor }) => {
  const { currency } = useSelector((state) => state.root);
  const {
    name,
    vendor_id,
    email,
    phone_number,
    website,
    account_custom_id,
    payment_term,
    bank_name,
    bank_account_number,
    routing_number,
    tax_id,
    account,
    open_balance,
    total_balance,
    add_to_report,
  } = vendor || {};

  return (
    <div>
      <STable>
        <tbody>
          <tr>
            <td>
              <SLabel>Vendor ID</SLabel>
              <SText>{vendor_id}</SText>
            </td>
            <td>
              <SLabel>Contact Name</SLabel>
              <div className='d-flex align-items-center gap-1'>
                <SText>{name}</SText>
                <SIconWrapper href={`tel:${phone_number}`}>
                  <PhoneIcon fill={palette.indigo400} height={11} />
                </SIconWrapper>
                <SIconWrapper href={`mailto:${email}`}>
                  <MailIcon fill={palette.indigo400} height={10} />
                </SIconWrapper>
                {website && (
                  <SIconWrapper href={`//${website}`} target='_blank'>
                    <WebsiteIcon fill={palette.indigo400} height={12} />
                  </SIconWrapper>
                )}
              </div>
            </td>
            <td>
              <SLabel>Account Reference</SLabel>
              <SText>{account_custom_id || '-'}</SText>
            </td>
            <td>
              <SLabel>Payment Term</SLabel>
              <SText>{payment_term.id === 4 ? 'Due on Discount' : `Net ${payment_term.net_day}`}</SText>
            </td>
          </tr>
          <tr>
            <td>
              <SLabel>Custom ID</SLabel>
              <SText>{account_custom_id || '-'}</SText>
            </td>
            <td>
              <SLabel>Bank Name</SLabel>
              <SText>{bank_name || '-'}</SText>
            </td>
            <td>
              <SLabel>Bank Account Number</SLabel>
              <SText>{bank_account_number ? `****${bank_account_number.slice(-4)}` : '-'}</SText>
            </td>
            <td>
              <SLabel>Routing Number</SLabel>
              <SText>{routing_number || '-'}</SText>
            </td>
          </tr>
          <tr>
            <td>
              <SLabel>Tax ID</SLabel>
              <Chip
                label={tax_id ? (tax_id[2] !== '-' ? `${tax_id.slice(0, 2)}-${tax_id.slice(2)}` : tax_id) : '-'}
                labelColor={palette.gray900}
                bgColor='#F0F2F7'
                fontSize='14px'
                fontWeight='500'
              />
            </td>
            <td>
              <SLabel>Account</SLabel>
              <SText>{account.account_name}</SText>
            </td>
            <td>
              <SLabel>Open Balance</SLabel>
              <SText>
                {currency}
                {formatNumber(open_balance)}
              </SText>
            </td>
            <td>
              <SLabel>Total Transactions</SLabel>
              <SText>
                {currency}
                {formatNumber(total_balance)}
              </SText>
            </td>
          </tr>
        </tbody>
      </STable>
      {!!add_to_report && (
        <Typography variant='c2' style={{ color: palette.gray700 }}>
          Tax ID added to 1099-MISC Reporting
        </Typography>
      )}
    </div>
  );
};

export default VendorDetails;
