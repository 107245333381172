import moment from 'moment';
import { mixed, object, string } from 'yup';
import { phoneRegex, validator } from 'utils/helpers';

export const confirmationValidationSchema = object().shape({
  driver_phone_number: string()
    .trim()
    .matches(phoneRegex, 'Enter a valid phone number')
    .required('Driver Phone number is required'),
  driver_name: string().required('Driver name is required'),
  signature: mixed().required('Signature is required'),
  signature_first_name: string().required('First, Last Name is required'),
  signature_date: validator.requiredDate(),
  truck_id: string().required('Truck # is required'),
  trailer_id: string().required('Trailer # is required'),
});

export const confirmationValidationSchemaVehicle = object().shape({
  driver_phone_number: string()
    .trim()
    .matches(phoneRegex, 'Enter a valid phone number')
    .required('Driver Phone number is required'),
  driver_name: string().required('Driver name is required'),
  signature: mixed().required('Signature is required'),
  signature_first_name: string().required('First, Last Name is required'),
  signature_date: validator.requiredDate(),
  truck_id: string().required('Truck # is required'),
});

export const initialValues = {
  driver_phone_number: '',
  driver_name: '',

  signature: null,
  signature_first_name: '',
  signature_last_name: '',
  signature_date: moment().toDate(),

  truck_id: '',
  trailer_id: '',
  reference_id: '',
};

export const getConfirmationInitialValues = (shipment) => {
  const { brokerage_dispatch, shipment_id } = shipment || {};
  const { driver_name, driver_phone_number, vehicle_id, trailer_id } = brokerage_dispatch || {};
  const today = moment().format('MM/DD/YYYY HH:MM');
  const garlaxDate = today.replace(/[^0-9]/g, '');
  const barNumber = shipment_id ? `${shipment_id}-${garlaxDate}` : null;

  return {
    driver_phone_number: driver_phone_number || '',
    driver_name: driver_name || '',

    signature: null,
    signature_first_name: '',
    signature_last_name: '',
    signature_date: moment().toDate(),

    truck_id: vehicle_id || '',
    trailer_id: trailer_id || '',
    reference_id: barNumber || '',
  };
};
