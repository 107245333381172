import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Toast } from 'react-bootstrap';
import { Box, Modal } from '@mui/material';
import { useFormik } from 'formik';
import NoRecords from 'common/NoRecords';
import ConnectModal from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/EquipmentConnectedServices/ConnectModal';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { ConnectedServices } from 'Api/Equipment';
import { deleteConnectedServices } from 'Api/EquipmentImport';
import { palette } from 'utils/constants';
import style from './Services.module.css';

const ConnectedServicesTab = ({ nextStep, Cancel, response }) => {
  const showToaster = useShowToaster();
  const [show, setShow] = useState(false);
  const [connectedServices, setConnectedServices] = useState([]);
  const [connectSuccess, setConnectSuccess] = useState(false);
  const [ConnectError, setConnectError] = useState(false);
  const [ConnectionError, setConnectionError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [serialId, setSerialId] = useState(null);
  const [loading, setLoading] = useState(false);

  async function ConnectedServicesApi() {
    setLoading(true);
    ConnectedServices()
      .then((res) => {
        setConnectedServices(res?.data);
      })
      .finally(() => setLoading(false));
  }

  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 0,
    padding: '10px 16px',
  };

  const ConnectsuccessShow = () => {
    setConnectSuccess(true);
  };

  const ConnectErrorModal = () => {
    setConnectError(true);
    setTimeout(() => {
      ConnectErrorModalClose();
    }, 2000);
  };

  const ConnectErrorModalClose = () => {
    setConnectError(false);
  };

  const ConnectionErrorModal = () => {
    setConnectionError(true);
    setTimeout(() => {
      ConnectionErrorModalClose();
    }, 2000);
  };

  const ConnectionErrorModalClose = () => {
    setConnectionError(false);
  };

  const removeConnectedServices = async (id, serialId) => {
    try {
      const params = {
        serial_id: serialId,
      };

      await deleteConnectedServices(id, params);
      showToaster({ type: 'success', message: 'Successfully removed!' });
      setConnectSuccess(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const successClose = () => {
    setSuccess(false);
  };
  const ConnecthandleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      driverType: '',
      email: '',
      file: '',
    },

    onSubmit: () => {
      nextStep();
    },
  });

  useEffect(() => {
    ConnectedServicesApi();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ background: '#fff' }} className={style['equipment-services-modal-body']}>
          <div className={style['connected-services-filter']}>
            <div className={style['filter-tab-wrapper']}>
              <div className={style['tab-content']} id='myTabContent'>
                <Tabs defaultActiveKey='home' id='uncontrolled-tab-example'>
                  <Tab
                    eventKey='home'
                    title={
                      <p className='d-flex'>
                        <span className={style['tabs-text']}> All </span>
                        <span className={style['tabs-values']}>{connectedServices?.length || 0}</span>
                      </p>
                    }
                    className={style['tab-container']}
                  />
                  <Tab
                    disabled
                    title={
                      <p className='d-flex ms-4'>
                        <span className={style['connected-services-tab-text']}> Email & Storage </span>
                        <span className={style['tabs-values']}>0</span>
                      </p>
                    }
                  />
                  <Tab
                    eventKey='outofserives'
                    disabled
                    title={
                      <p className='d-flex ms-4'>
                        <span className={style['connected-services-tab-text']}> Fuel & Cash Card</span>
                        <span className={style['tabs-values']}>0</span>
                      </p>
                    }
                  />
                  <Tab
                    eventKey='outofserives'
                    disabled
                    title={
                      <p className='d-flex ms-4'>
                        <span className={style['connected-services-tab-text']}>Payroll</span>
                        <span className={style['tabs-values']}>0</span>
                      </p>
                    }
                  />
                  <Tab
                    eventKey='outofserives'
                    disabled
                    title={
                      <p className='d-flex ms-4'>
                        <span className={style['connected-services-tab-text']}>EDI</span>
                        <span className={style['tabs-values']}>0</span>
                      </p>
                    }
                  />
                  <Tab
                    eventKey='outofserives'
                    disabled
                    title={
                      <p className='d-flex ms-4'>
                        <span className={style['connected-services-tab-text']}>Telematics</span>
                        <span className={style['tabs-values']}>{connectedServices?.length || 0}</span>
                      </p>
                    }
                  />
                </Tabs>
              </div>
            </div>
          </div>
          <table className='table table-hover'>
            <tr className={style['connected-services-table-row']}>
              <th className={style['connected-services-table-head']}>Services</th>
              <th className={style['connected-services-table-head']}>Type</th>
              {connectSuccess && <th className={style['connected-services-table-head']}>Connected To</th>}
              <th />
            </tr>
            {connectedServices?.map((value) => (
              <tr className={style['connected-services-table-row']}>
                <td className='d-flex '>
                  <span>
                    <img src={value.logo} alt='' />
                    <span className={style['table-content']}>{value?.provider_name}</span>
                  </span>
                  <span className={style['table-content']}>
                    <span className='ms-2'>{value.services}</span>
                  </span>
                </td>
                <td>
                  <span className={style['table-content']}>{value.type}</span>
                </td>
                {connectSuccess && (
                  <td>
                    <span className={style['table-content']}>{serialId}</span>
                  </td>
                )}
                <td className='d-flex justify-content-end me-2'>
                  {connectSuccess ? (
                    <div className='disconnect-button' onClick={() => removeConnectedServices(response?.id, serialId)}>
                      Remove
                    </div>
                  ) : (
                    <div className='connect-button' onClick={handleShow}>
                      Connect
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </table>
          {!loading && !connectedServices?.length && (
            <div style={{ height: '300px' }} className='d-flex align-items-center justify-content-center'>
              <NoRecords text='You do not have any Connected Services available for activation.' />
            </div>
          )}
        </div>
        <div style={{ justifyContent: 'space-between', padding: '20px 20px', display: 'flex' }}>
          <button className={style['cancel-button']} onClick={Cancel}>
            Cancel
          </button>
          <div className='d-flex'>
            <button
              className={style['cancel-button']}
              style={{ color: palette.gray700, marginLeft: '16px' }}
              onClick={() => nextStep()}
            >
              Skip
            </button>
            <button className={style['next-step']} type='submit' style={{ color: '#fff', backgroundColor: '#4F5AED' }}>
              Next Step
            </button>
          </div>
        </div>
      </form>
      <Modal open={show} disableEnforceFocus onClose={ConnecthandleClose} className='service-connect-modal'>
        <Box sx={style2}>
          <ConnectModal
            handleClose={ConnecthandleClose}
            ConnectsuccessShow={ConnectsuccessShow}
            ConnectErrorModal={ConnectErrorModal}
            profileData={response}
            ConnectionErrorModal={ConnectionErrorModal}
            setSerialId={setSerialId}
          />
        </Box>
      </Modal>
      <Toast show={success} onClose={successClose} className='equipment-connected-success-msg'>
        <div>Successfully removed</div>
      </Toast>
      <Toast show={ConnectError} onClose={ConnectErrorModalClose} className='equipment-connected-error-msg'>
        <div>Error, there is a mismatch with VIN!</div>
      </Toast>

      <Toast show={ConnectionError} onClose={ConnectErrorModalClose} className='equipment-connected-error-msg'>
        <div> Error ! </div>
      </Toast>
    </>
  );
};

export default ConnectedServicesTab;
