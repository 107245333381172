import jsPDF from 'jspdf';
import backId from 'assets/icons/back-id.png';
import frontId from 'assets/icons/front-id.png';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 8, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createMedicalTableHeader = (doc) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Reason', 8, 40);
  doc.text('Result', 60, 40);
  doc.text('Date Checked', 118, 40);
  doc.text('Next Check Date', 142, 40);
  doc.text('Expiry Date', pageWidth - 8, 40, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, 42, pageWidth - 8, 42);
};

const createMedicalTableRow = (doc, data, top, formatDate) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  if (data.isCategory) {
    doc.setFont('Inter', 'normal', 700);
  } else {
    doc.setFont('Inter', 'normal', 400);
  }
  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  if (!data.isBlank) {
    if (data.isCategory) {
      doc.text(`${data.title || '-'}`, 8, top);
    } else {
      if (data.reason) {
        doc.text(`${data.reason}`, 8, top);
      }

      if (data.mvr_check_results) {
        doc.text(`${data.mvr_check_results}`, 60, top);
      } else if (data.drug_check_results) {
        doc.text(`${data.drug_check_results}`, 60, top);
      } else if (data.criminal_check_results) {
        doc.text(`${data.criminal_check_results}`, 60, top);
      }

      if (data.mvr_check_date) {
        doc.text(`${formatDate(data.mvr_check_date)}`, 118, top);
      } else if (data.drug_checked_date) {
        doc.text(`${formatDate(data.drug_checked_date)}`, 118, top);
      } else if (data.fmcsa_checked_date) {
        doc.text(`${formatDate(data.fmcsa_checked_date)}`, 118, top);
      } else if (data.criminal_check_date) {
        doc.text(`${formatDate(data.criminal_check_date)}`, 118, top);
      } else if (data.psp_check_date) {
        doc.text(`${formatDate(data.psp_check_date)}`, 118, top);
      }

      if (data.mvr_next_check_date) {
        doc.text(`${formatDate(data.mvr_next_check_date)}`, 142, top);
      } else if (data.drug_next_check_date) {
        doc.text(`${formatDate(data.drug_next_check_date)}`, 142, top);
      } else if (data.fmcsa_next_check_date) {
        doc.text(`${formatDate(data.fmcsa_next_check_date)}`, 142, top);
      } else if (data.criminal_next_check_date) {
        doc.text(`${formatDate(data.criminal_next_check_date)}`, 142, top);
      }
      doc.text(`${data.expiry_date ? formatDate(data.expiry_date) : '-'}`, pageWidth - 8, top, { align: 'right' });
    }
  }
};

const createPrevEmploymentTableHeader = (doc) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Attempt #', 8, 40);
  doc.text('Request Date', 60, 40);
  doc.text('Request Type', 118, 40);
  doc.text('Transmit Method', 142, 40);
  doc.text('Response Status', pageWidth - 8, 40, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, 42, pageWidth - 8, 42);
};

const createPrevEmploymentTableRow = (doc, data, top, index, formatDate) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  if (data.isEmployer) {
    doc.setFont('Inter', 'normal', 700);
  } else {
    doc.setFont('Inter', 'normal', 400);
  }
  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  if (!data.isBlank) {
    if (data.isEmployer) {
      doc.text(`Employer - ${data.name || ''}`, 8, top);
      doc.text(
        `${data.start_date && data.end_date ? `${formatDate(data.start_date)} - ${formatDate(data.end_date)}` : '-'}`,
        60,
        top
      );
    } else {
      doc.text(`Attempt #${data.attemptNumber}`, 8, top);
      doc.text(`${data.request_date ? `${formatDate(data.request_date)}` : '-'}`, 60, top);
      doc.text(`${data.request_type || '-'}`, 118, top);
      doc.text(`${data.request_form_source || '-'}`, 142, top);
      doc.text(`${data.response || '-'}`, pageWidth - 8, top, { align: 'right' });
    }
  }
};

const createPrevEmploymentTableRows = (doc, records, formatDate) => {
  if (!records?.length) {
    return;
  }

  const top = 46;

  records.forEach((record, index) => {
    createPrevEmploymentTableRow(doc, record, top + index * 5, index, formatDate);
  });

  return top + records.length * 5;
};

const createMedicalTableRows = (doc, records, formatDate) => {
  if (!records?.length) {
    return;
  }

  const top = 46;

  records.forEach((record, index) => {
    createMedicalTableRow(doc, record, top + index * 5, formatDate);
  });

  return top + records.length * 5;
};

export const generatePDF = async (driverGeneral, medicalData, prevEmployment, formatDate, convertToCustomerTime) => {
  const { fname, lname, certifications, dob, driver_licence_upload, driver_licence_back_upload } = driverGeneral;
  const { hire_date } = certifications || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(`${fname} ${lname}`, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Driver Qualification File', pageWidth / 2, 22, { align: 'center' });
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  doc.setFontSize(10);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text('Date of Birth', 16, 38);
  doc.text('Hire Date', pageWidth / 2, 38);

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(`${dob ? formatDate(dob) : '-'}`, 16, 42);
  doc.text(`${hire_date ? formatDate(hire_date) : '-'}`, pageWidth / 2, 42);

  doc.addImage(
    `${driver_licence_upload || frontId}?cache=${Math.random()}`,
    'JPEG',
    16,
    60,
    90,
    50,
    `image-driver_licence_upload`,
    'FAST'
  );

  doc.addImage(
    `${driver_licence_back_upload || backId}?cache=${Math.random()}`,
    'JPEG',
    pageWidth / 2,
    60,
    90,
    50,
    `image-driver_licence_back_upload`,
    'FAST'
  );

  doc.addPage();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(`${fname} ${lname}`, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Previous Employers', pageWidth / 2, 22, { align: 'center' });
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  createPrevEmploymentTableHeader(doc);
  createPrevEmploymentTableRows(doc, prevEmployment, formatDate);

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  doc.addPage();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(`${fname} ${lname}`, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Medical and Background', pageWidth / 2, 22, { align: 'center' });
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  createMedicalTableHeader(doc);
  createMedicalTableRows(doc, medicalData, formatDate);

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
  }

  doc.setDocumentProperties({
    title: `Driver Qualification File`,
  });

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  return { blob, url };
};
