import moment from 'moment';

export const initialValues = {
  date: null,
  distance_travelled: '',
  shipments: [],
  trailers: [],
  vehicles: [],
  paper_log_attach: null,
};

export const getStartAndEndDate = (selectedDate) => {
  const selectedMoment = moment(selectedDate);

  const startDate = selectedMoment.startOf('day');

  const endDate = selectedMoment.clone().add(1, 'days').startOf('day');

  const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
  const endDateString = endDate.format('YYYY-MM-DD HH:mm:ss');

  return {
    start_date: startDateString,
    end_date: endDateString,
  };
};
