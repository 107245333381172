import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SMergedInputs = styled.div`
  display: flex;

  input,
  .merged-input {
    border-radius: 6px 0 0 6px;
    vertical-align: top;
  }

  .MuiAutocomplete-root.left-autocomplete .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 6px 0 0 6px;
    vertical-align: top;
  }

  .MuiAutocomplete-root.right-autocomplete .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 6px 6px 0;
    height: 25px;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;

export const STable = styled.table`
  width: 100%;
  table-layout: fixed;

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    :first-child {
      border-top: 1px solid ${palette.gray50};
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
      visibility: hidden;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    td {
      padding: 4px 8px;

      :last-child {
        width: 80px;
      }
    }

    :hover {
      background: ${palette.gray0};

      .action-buttons {
        visibility: visible;
      }

      .action-wrapper {
        visibility: visible;
      }
    }
  }
`;
