import React, { useState } from 'react';
import './dispatchUser.css';
import xpng from '../../../assets/icons/x.png';
import micIcon from '../../../assets/icons/mic.svg';
import DeleteChannel from '../DeleteChannel/DeleteChannel';

const DispatchUser = ({ selctedChannel }) => {
  const [removeUser, setremoveUser] = useState(false);

  return (
    <div className='dispatchUser'>
      <p className='group-heading'> {selctedChannel?.channel_name} Users</p>
      {[].map((user) => (
        <div key={user?.id} className='active-users'>
          {removeUser && (
            <DeleteChannel
              heading='Remove User'
              element={
                <p className='channel-modal-text'>
                  Are you sure you want to remove
                  <span style={{ fontWeight: 'bold', color: '#171C26' }}> {user.name} </span>
                  from channel
                  <span style={{ fontWeight: 'bold', color: '#171C26' }}> {selctedChannel?.channel_name} ?</span>
                </p>
              }
              deleteBtnName='Remove User'
              onCancel={setremoveUser}
            />
          )}
          <div className='active-user-img'>
            <img src={user.avatar_url} alt='' />
            <p>{user.name}</p>
          </div>

          <div className='icons'>
            <img src={micIcon} alt='' style={{ marginRight: '20px', height: '15px' }} />
            <img src={xpng} alt='' onClick={() => setremoveUser(true)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default DispatchUser;
