import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;
  table-layout: fixed;

  .body-row {
    &.bg-gray {
      background: ${palette.gray0};
    }

    &.row-to-delete {
      background: ${palette.red0};

      td:not(:last-child) {
        opacity: 0.5;
      }
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    td {
      padding: 6px 8px;

      :last-child {
        width: 86px;
      }
    }

    :hover {
      background: ${palette.gray0};
    }
  }
`;

export const SMergedInputs = styled.div`
  display: flex;

  input {
    border-radius: 0;
  }

  .last-input {
    border-radius: 0 6px 6px 0;
  }

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 6px 0 0 6px;

    .MuiInputBase-input {
      height: 21px;
    }
  }

  .MuiInputBase-root {
    height: 33px;
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: default;

  :hover span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;
