import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import NoRecords from 'common/NoRecords';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from 'components/Typography';
import { getErrorMessage } from 'utils/error';
import CustomButton from 'components/CustomButton/CustomButton';
import { formatNumber, palette } from 'utils/constants';
import { getFleetPerMonthReport } from 'Api/Reports';
import Autocomplete from 'common/Autocomplete';
import Loader from 'common/Loader';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { STableFilters, STableWrapper, STable } from '../ReportDetails.styles';

const FleetSafestYear = ({ title }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [reportItems, setReportItems] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const didMountRef = useRef(false);

  const getFleetPerMonthReportList = async () => {
    try {
      setLoading(true);
      const monthlyReports = [];

      for (let month = 1; month <= 12; month++) {
        const monthMoment = moment(`${year}-${month}`, 'YYYY-MM');
        const fromDate = moment(`${year}-${month}`, 'YYYY-MM').startOf('month').format('YYYY-MM-DD HH:mm:ss');
        let toDate = moment(`${year}-${month}`, 'YYYY-MM').endOf('month').format('YYYY-MM-DD HH:mm:ss');

        if (month === 12) {
          toDate = moment(`${year}-12-31`).endOf('month').add(1, 'month').format('YYYY-MM-DD HH:mm:ss'); // Adjust for January of the next year
        }

        const params = {
          'filters[from_date]': fromDate,
          'filters[to_date]': toDate,
        };

        const [reportResponse] = await Promise.all([getFleetPerMonthReport(params)]);
        const reportData = reportResponse.data ? reportResponse.data : [];
        const monthName = monthMoment.format('MMMM');

        monthlyReports.push({
          month,
          monthName,
          reportData,
        });
      }

      const arr = monthlyReports.map((report) => ({
        ...report.reportData,
        monthName: report.monthName,
      }));

      setReportItems(arr);
      const totals = {
        miles_driven: 0,
        power_unit_count: 0,
        total_collision_count: 0,
        collision_frequency: 0,
        collision_frequency_per_million_miles: 0,
      };
      arr.forEach((item) => {
        totals.miles_driven += +item.miles_driven;
        totals.power_unit_count += +item.power_unit_count;
        totals.total_collision_count += +item.total_collision_count;
        totals.collision_frequency += +item.collision_frequency;
        totals.collision_frequency_per_million_miles += +item.collision_frequency_per_million_miles;
      });

      setTotalData(totals);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      navigate('/reports');
    } finally {
      setLoading(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const data = { reportItems, totalData, title };
      const { url } = await generatePDF(data, download, year, dateFormat);
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const onExport = () => {
    if (!reportItems?.length) {
      return;
    }

    const titles = {
      'MONTH OF COLLISION': 'MONTH OF COLLISION',
      'NUMBER OF POWER UNITS PER MONTH': 'NUMBER OF POWER UNITS PER MONTH',
      'NUMBER OF MILES DRIVEN': 'NUMBER OF MILES DRIVEN',
      'NUMBER OF COLLISIONS': 'NUMBER OF COLLISIONS',
      'COLLISION FREQUENCY BY MILLION MILES': 'COLLISION FREQUENCY BY MILLION MILES',
    };

    const vendors = reportItems.map((vendor) => ({
      'MONTH OF COLLISION': vendor.monthName || '',
      'NUMBER OF POWER UNITS PER MONTH': vendor.power_unit_count || 0,
      'NUMBER OF MILES DRIVEN': vendor.miles_driven || 0,
      'NUMBER OF COLLISIONS': vendor.total_collision_count || 0,
      'COLLISION FREQUENCY BY MILLION MILES': vendor.collision_frequency_per_million_miles || 0,
    }));

    const total = [
      {
        'MONTH OF COLLISION': 'Total',
        'NUMBER OF POWER UNITS PER MONTH': totalData.power_unit_count || 0,
        'NUMBER OF MILES DRIVEN': totalData.miles_driven || 0,
        'NUMBER OF COLLISIONS': totalData.total_collision_count || 0,
        'COLLISION FREQUENCY BY MILLION MILES': totalData.collision_frequency_per_million_miles || 0,
      },
    ];

    const arrayToConvert = [titles, ...vendors, ...total];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('download', 'Fleet-Safest-Year');
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getFleetPerMonthReportList();
    didMountRef.current = true;
  }, [year]);

  return (
    <PageWrapper title={title}>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <Autocomplete
              options={['2023', '2024']}
              style={{ width: '90px' }}
              value={year}
              onChange={(e, val) => {
                setYear(val);
              }}
              isOptionEqualToValue={(option, value) => option === value}
            />
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportItems.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={reportItems.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <STable>
            <thead>
              <tr className='header-row'>
                <th>MONTH OF COLLISION</th>
                <th>NUMBER OF POWER UNITS PER MONTH</th>
                <th>NUMBER OF MILES DRIVEN</th>
                <th>NUMBER OF COLLISIONS</th>
                <th>COLLISION FREQUENCY BY MILLION MILES</th>
              </tr>
            </thead>
            <tbody>
              {reportItems.map((item) => (
                <tr key={item.monthName} className='body-row'>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.monthName}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.power_unit_count}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {formatNumber(item.miles_driven)}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.total_collision_count}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {formatNumber(item.collision_frequency_per_million_miles)}
                    </Typography>
                  </td>
                </tr>
              ))}
              {reportItems.length !== 0 && (
                <tr className='body-row'>
                  <td>
                    <Typography variant='button2'>Total</Typography>
                  </td>
                  <td>
                    <Typography variant='button2'>{totalData.power_unit_count || 0}</Typography>
                  </td>
                  <td>
                    <Typography variant='button2'>{formatNumber(totalData.miles_driven || 0)}</Typography>
                  </td>
                  <td>
                    <Typography variant='button2'>{totalData.total_collision_count || 0}</Typography>
                  </td>
                  <td>
                    <Typography variant='button2'>
                      {formatNumber(totalData.collision_frequency_per_million_miles || 0)}
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </STable>
        )}
      </STableWrapper>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Fleet Safest Year' />
      )}
    </PageWrapper>
  );
};

export default FleetSafestYear;
