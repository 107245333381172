import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { convertToCustomerTime } from 'utils/helpers';

import { statusColor } from 'pages/Accounting/Accounts/BankFeed/components/Transactions/Transactions.data';
import { SStatusDot, STable } from 'pages/Accounting/Accounts/BankFeed/BankFeed.styles';
import { STableWrapper } from '../MatchTransaction.styles';

const Transaction = ({ transaction }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <STableWrapper>
      <Typography variant='s2'>Transaction</Typography>
      <STable>
        <thead>
          <tr>
            <th>STATUS</th>
            <th>TYPE</th>
            <th>DATE</th>
            <th>REFERENCE</th>
            <th>DOWNLOADED AS</th>
            <th>PAYEE</th>
            <th>ACCOUNT</th>
            <th>MEMO</th>
            <th>PAYMENT</th>
            <th>DEPOSIT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className='text-center'>
                <SStatusDot color={statusColor[transaction.status]} />
              </div>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {transaction.type}
              </Typography>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {convertToCustomerTime(transaction.date, 'MM/DD/YYYY')}
              </Typography>
            </td>
            <td>
              <Tooltip
                arrow
                title={transaction.reference_id}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: 13,
                      fontFamily: 'Inter',
                    },
                  },
                }}
              >
                <span style={{ color: palette.gray700 }}>*****{transaction.reference_id?.slice(-5)}</span>
              </Tooltip>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {transaction.description || '-'}
              </Typography>
            </td>
            <td onClick={(e) => e.stopPropagation()}>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {transaction.payee?.name || '-'}
              </Typography>
            </td>
            <td onClick={(e) => e.stopPropagation()}>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {transaction.account?.account_name || '-'}
              </Typography>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {transaction.memo || '-'}
              </Typography>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {currency}
                {formatNumber(transaction.payment)}
              </Typography>
            </td>
            <td>
              <Typography variant='s3' style={{ color: palette.gray700 }}>
                {currency}
                {formatNumber(transaction.deposit)}
              </Typography>
            </td>
          </tr>
        </tbody>
      </STable>
    </STableWrapper>
  );
};

export default Transaction;
