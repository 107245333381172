import { object } from 'yup';
import { validator } from 'utils/helpers';

export const actionValidationSchema = object().shape({
  criminal_checked_date: validator.pastDate(),
  mvr_checked_date: validator.pastDate(),
  drug_checked_date: validator.pastDate(),
  fmcsa_checked_date: validator.pastDate(),
  // prevEmployments: array().of(
  //   object().shape({
  //     request_date: validator.pastRequiredDate('Request date is required'),
  //     start_date: validator.pastRequiredDate('Start date is required'),
  //     end_date: validator.pastRequiredDate('End date is required'),
  //   })
  // ),
});
