export const createEquipmentDataConverter = (value, equipmentType) => {
  const formData = new FormData();
  formData.append('equipment_type_id', value.equipment_type_id);
  if (value.equipment_type_id === 1) {
    formData.append('truck_type', value.truck_type);
  }
  formData.append('equipment_id', value.equipment_id);
  if (value.make) {
    formData.append('make', value.make);
  }
  if (value.gross_vehicle_weight_rating) {
    formData.append('gross_vehicle_weight', value.gross_vehicle_weight_rating);
  }
  formData.append('type', value.type);
  if (value.year && value.year !== 'Invalid date') {
    formData.append('year', value.year);
  }
  formData.append('lift_gate', value.lift_gate ? '1' : '0');
  formData.append('tire_size', value.tire_size);

  if (value.cargo_maximum_capacity_weight) {
    formData.append('cargo_maximum_capacity_weight', value.cargo_maximum_capacity_weight);
  }
  if (equipmentType === 'trailer') {
    formData.append('cargo_capacity_measuring_unit', value.cargo_capacity_measuring_unit);
    formData.append('length_id', value.length || '');
    formData.append('width', value.width);
    formData.append('height', value.height);
    formData.append('model_id', value?.model || '');
  }
  if (equipmentType === 'vehicle') {
    formData.append('fuel_type_id', value?.fuel_type_id || '1');
    formData.append('fuel_gallon_capacity', value.fuel_gallon_capacity);
    formData.append('start_odometer', value.start_odometer);
    formData.append('model_id', value?.model || '');
    formData.append('length_id', value.length || '');
    formData.append('width', value.width);
    formData.append('height', value.height);
    if (value.transmission_type) {
      formData.append('transmission_type', value.transmission_type);
    }

    if (value.transmission_type === 2) {
      formData.append('transmission_speed', value.transmission_speed || '');
    }
  }
  if (value.vin) {
    formData.append('vin', value.vin);
  }
  formData.append('color', value.color);
  formData.append('gross_vehicle_measuring_unit', value.gross_vehicle_measuring_unit);
  formData.append('gvwr_class', value.GVWR);
  if (value.axles) {
    formData.append('axles', value.axles);
  }
  if (value.owner_operator) {
    formData.append('equipment_ownership[operator_id]', value.owner_operator?.value);
  }
  if (value.purchase_cost) {
    formData.append('equipment_ownership[purchase_cost]', value.purchase_cost);
  }
  formData.append('equipment_ownership[leasing_company_name]', value.leasing_company_name);
  formData.append('equipment_ownership[lease_or_credit_payment]', value.lease_or_credit_payment);
  formData.append('equipment_ownership[owner]', Number(value.owner_operator_type) === 4 ? '3rd Party' : value.ownedBy);
  formData.append('equipment_ownership[state]', value.state);
  formData.append('equipment_ownership[owner_operator]', value.owner_operator_type);

  return formData;
};
