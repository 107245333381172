import React from 'react';
import uuid from 'react-uuid';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import Input, { InputWithText } from 'common/Input';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomTimeInput from 'components/CustomTimeInput';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getAlphabet, palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import CopyRecurring from './CopyRecurring';
import { STable } from './Schedule.styles';

const scheduledTypes = [
  { name: 'FCFS', id: 1 },
  { name: 'APPT', id: 2 },
  { name: 'TW', id: 3 },
];

const Recurs = ({ form, stops, createMode, shipmentData, updateShipmentData }) => {
  const { values, handleChange, touchedErrors } = form;

  const onRecursChange = (checked, index) => {
    if (!checked) {
      handleChange(`recurs[${index}]`, {
        ...values.recurs[index],
        amount: '',
        checked: Number(checked),
      });
      return;
    }

    handleChange(`recurs[${index}].checked`, Number(checked));
  };

  const onCopyFromRow = (id, index) => {
    const data = values.recurs.find((i) => i.id === id);
    handleChange(`recurs[${index}].dateSettings`, data.dateSettings);
    handleChange(`recurs[${index}].amount`, data.amount);
  };

  const onAmountChange = (amount, index, increase) => {
    const fields = stops.map(() => '');

    if (!amount || Number(amount) < 1 || Number(amount) > 150) {
      handleChange(`recurs[${index}].amount`, 1);
      handleChange(`recurs[${index}].dateSettings`, [
        {
          id: uuid(),
          day_wise_pickup_time: '',
          day_wise_pickup_time_to: '',
          day_wise_delivery_time_after: fields,
          delivery_time: fields,
          delivery_time_to: fields,
        },
      ]);
      return;
    }
    handleChange(`recurs[${index}].amount`, amount);
    handleChange(
      `recurs[${index}].dateSettings`,
      increase
        ? [
            ...values.recurs[index].dateSettings,
            {
              id: uuid(),
              day_wise_pickup_time: '',
              day_wise_pickup_time_to: '',
              day_wise_delivery_time_after: fields,
              delivery_time: fields,
              delivery_time_to: fields,
            },
          ]
        : Array(Number(amount))
            .fill(0)
            .map(
              (_, i) =>
                values.recurs[index].dateSettings?.[i] || {
                  id: uuid(),
                  day_wise_pickup_time: '',
                  day_wise_pickup_time_to: '',
                  day_wise_delivery_time_after: fields,
                  delivery_time: fields,
                  delivery_time_to: fields,
                }
            )
    );
  };

  const onRemoveDateRow = (outerIndex, innerIndex) => {
    handleChange(`recurs[${outerIndex}].amount`, values.recurs[outerIndex].amount - 1);
    handleChange(
      `recurs[${outerIndex}].dateSettings`,
      values.recurs[outerIndex].dateSettings.filter((el, i) => i !== innerIndex)
    );
  };

  const onScheduledTypeChange = (value, stop) => {
    const newStops = (shipmentData.legs || shipmentData.shipmentsLegs).map((leg) => {
      return {
        ...leg,
        stops: leg.stops.map((item) => {
          if (item.id === stop.id) {
            return { ...item, scheduled_type: value.id.toString() };
          }
          return item;
        }),
      };
    });

    if (Number(shipmentData.type) === 1) {
      updateShipmentData({ ...shipmentData, legs: newStops });
    } else {
      updateShipmentData({ ...shipmentData, shipmentsLegs: newStops });
    }
  };

  return (
    <div className='d-flex flex-column mt-5'>
      <Typography variant='s2' style={{ marginBottom: 8 }}>
        Recurs on the following days
      </Typography>
      <div className='d-flex flex-column overflow-auto pb-2'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th style={{ width: '130px' }} />
              <th style={{ width: '130px' }}>QTY</th>
              {stops.map((stop, index) => (
                <th
                  style={{
                    width:
                      index === 0
                        ? [1, 3].includes(Number(stop?.scheduled_type))
                          ? '240px'
                          : '212px'
                        : [1, 3].includes(Number(stop?.scheduled_type))
                        ? '330px'
                        : '240px',
                  }}
                >
                  <div className='d-flex gap-2'>
                    <div className='d-flex flex-column flex-wrap'>
                      <Typography
                        variant='c1'
                        style={{
                          color:
                            Number(stop.stop_point_type) === 1
                              ? palette.green500
                              : Number(stop.stop_point_type) === 2
                              ? palette.red500
                              : palette.blueText,
                        }}
                      >
                        STOP {getAlphabet(index)}:{' '}
                        {Number(stop.stop_point_type) === 1
                          ? 'PICK UP'
                          : Number(stop.stop_point_type) === 2
                          ? 'DELIVERY'
                          : 'WAYPOINT'}
                      </Typography>
                      <Typography
                        variant='c3'
                        style={{
                          color: palette.gray700,
                          width: '100px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {stop?.stop_point?.location_name}
                      </Typography>
                    </div>
                    {!!createMode && (
                      <Autocomplete
                        size='small'
                        width='82px'
                        placeholder=''
                        options={scheduledTypes}
                        value={scheduledTypes.find((i) => i.id === Number(stop.scheduled_type)) || null}
                        onChange={(e, val) => onScheduledTypeChange(val, stop)}
                      />
                    )}
                  </div>
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {values.recurs.map((item, index) => {
              const { title, id, checked } = item;

              return (
                <tr key={id} className='body-row'>
                  <td>
                    <CustomCheckbox
                      checked={!!checked}
                      name='recurs'
                      onChange={(checked) => onRecursChange(checked, index)}
                    >
                      <Typography
                        variant='s2'
                        style={{
                          marginLeft: 8,
                          color: palette.gray700,
                        }}
                      >
                        {title}
                      </Typography>
                    </CustomCheckbox>
                  </td>
                  <td>
                    <Input
                      type='number'
                      width='100px'
                      size='small'
                      name={`recurs[${index}].amount`}
                      value={values?.recurs?.[index]?.amount || ''}
                      onChange={(e) => {
                        if (e.target.value > 150) {
                          return;
                        }
                        handleChange(`recurs[${index}].amount`, e.target.value);
                      }}
                      onKeyDown={blockNotNumberChars}
                      onBlur={() => onAmountChange(values.recurs[index].amount, index)}
                      disabled={!checked}
                      error={touchedErrors.recurs?.[index]?.amount}
                    />
                  </td>
                  {stops.map((stop, stopIndex) => (
                    <td key={stop.id}>
                      <div className='d-flex flex-column gap-1'>
                        {values.recurs?.[index]?.dateSettings?.map((el, i) => (
                          <div className='d-flex align-items-center gap-4' key={el.id || i}>
                            {stopIndex === 0 ? (
                              <div className='d-flex align-items-center gap-4'>
                                <div className='d-flex align-items-center gap-2'>
                                  <div>
                                    <CustomTimeInput
                                      name={`recurs[${index}].dateSettings[${i}].day_wise_pickup_time`}
                                      style={{
                                        margin: 0,
                                        justifyContent: 'center',
                                        width: '76px',
                                        height: '25px',
                                        borderRadius: '6px',
                                        background: !checked ? palette.gray0 : palette.white,
                                      }}
                                      value={values?.recurs?.[index]?.dateSettings?.[i]?.day_wise_pickup_time || ''}
                                      onChange={(val) =>
                                        handleChange(`recurs[${index}].dateSettings[${i}].day_wise_pickup_time`, val)
                                      }
                                      disabled={!checked}
                                    />
                                    <ErrorMessage
                                      error={touchedErrors.recurs?.[index]?.dateSettings?.[i]?.day_wise_pickup_time}
                                    />
                                  </div>
                                  {[1, 3].includes(Number(stop?.scheduled_type)) && (
                                    <>
                                      <Typography variant='s2' style={{ color: palette.gray700 }}>
                                        to
                                      </Typography>
                                      <div>
                                        <CustomTimeInput
                                          name={`recurs[${index}].dateSettings[${i}].day_wise_pickup_time_to`}
                                          style={{
                                            margin: 0,
                                            justifyContent: 'center',
                                            width: '76px',
                                            height: '25px',
                                            borderRadius: '6px',
                                            background: !checked ? palette.gray0 : palette.white,
                                          }}
                                          value={
                                            values?.recurs?.[index]?.dateSettings?.[i]?.day_wise_pickup_time_to || ''
                                          }
                                          onChange={(val) =>
                                            handleChange(
                                              `recurs[${index}].dateSettings[${i}].day_wise_pickup_time_to`,
                                              val
                                            )
                                          }
                                          disabled={!checked}
                                        />
                                        <ErrorMessage
                                          error={
                                            touchedErrors.recurs?.[index]?.dateSettings?.[i]?.day_wise_pickup_time_to
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                                <Typography variant='s2' style={{ color: palette.gray700 }}>
                                  +
                                </Typography>
                              </div>
                            ) : (
                              <div className='d-flex gap-2'>
                                <InputWithText
                                  type='number'
                                  text='days'
                                  size='small'
                                  width='50px'
                                  name={`recurs.[${index}].dateSettings[${i}].day_wise_delivery_time_after[${stopIndex}]`}
                                  value={
                                    values?.recurs?.[index]?.dateSettings?.[i]?.day_wise_delivery_time_after?.[
                                      stopIndex
                                    ] || ''
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      `recurs.[${index}].dateSettings[${i}].day_wise_delivery_time_after[${stopIndex}]`,
                                      e.target.value
                                    )
                                  }
                                  onKeyDown={(e) => {
                                    blockNotNumberChars(e);
                                    blockNonPositiveNumbers(e);
                                  }}
                                  disabled={!checked}
                                  error={
                                    touchedErrors.recurs?.[index]?.dateSettings?.[i]?.day_wise_delivery_time_after?.[
                                      stopIndex
                                    ]
                                  }
                                />
                                <div className='d-flex align-items-center gap-2'>
                                  <div>
                                    <CustomTimeInput
                                      name={`recurs[${index}].dateSettings[${i}].delivery_time[${stopIndex}]`}
                                      style={{
                                        margin: 0,
                                        justifyContent: 'center',
                                        width: '76px',
                                        height: '25px',
                                        borderRadius: '6px',
                                        background: !checked ? palette.gray0 : palette.white,
                                      }}
                                      value={values?.recurs?.[index]?.dateSettings[i]?.delivery_time?.[stopIndex] || ''}
                                      onChange={(val) =>
                                        handleChange(
                                          `recurs[${index}].dateSettings[${i}].delivery_time[${stopIndex}]`,
                                          val
                                        )
                                      }
                                      disabled={!checked}
                                    />
                                    <ErrorMessage
                                      error={
                                        touchedErrors.recurs?.[index]?.dateSettings?.[i]?.delivery_time?.[stopIndex]
                                      }
                                    />
                                  </div>
                                  {[1, 3].includes(Number(stop?.scheduled_type)) && (
                                    <>
                                      <Typography variant='s2' style={{ color: palette.gray700 }}>
                                        to
                                      </Typography>
                                      <div>
                                        <CustomTimeInput
                                          name={`recurs[${index}].dateSettings[${i}].delivery_time_to[${stopIndex}]`}
                                          style={{
                                            margin: 0,
                                            justifyContent: 'center',
                                            width: '76px',
                                            height: '25px',
                                            borderRadius: '6px',
                                            background: !checked ? palette.gray0 : palette.white,
                                          }}
                                          value={
                                            values?.recurs?.[index]?.dateSettings?.[i]?.delivery_time_to?.[stopIndex] ||
                                            ''
                                          }
                                          onChange={(val) =>
                                            handleChange(
                                              `recurs[${index}].dateSettings[${i}].delivery_time_to[${stopIndex}]`,
                                              val
                                            )
                                          }
                                          disabled={!checked}
                                        />
                                        <ErrorMessage
                                          error={
                                            touchedErrors.recurs?.[index]?.dateSettings?.[i]?.delivery_time_to?.[
                                              stopIndex
                                            ]
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                                {stopIndex !== stops.length - 1 && (
                                  <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                                    +
                                  </Typography>
                                )}
                                {stopIndex === stops.length - 1 && i === 0 && (
                                  <div className='d-flex gap-4 align-items-center ms-2'>
                                    <PlusIcon
                                      fill={checked ? palette.indigo500 : palette.gray200}
                                      className='pointer'
                                      onClick={() =>
                                        checked && onAmountChange(Number(values.recurs[index].amount) + 1, index, true)
                                      }
                                    />
                                  </div>
                                )}
                                {stopIndex === stops.length - 1 && i > 0 && (
                                  <MinusIcon
                                    fill={checked ? palette.red300 : palette.gray200}
                                    className='pointer mt-2 ms-2'
                                    onClick={() => checked && onRemoveDateRow(index, i)}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </td>
                  ))}
                  <td>
                    <div style={{ width: '100px' }}>
                      <CopyRecurring
                        id={item.id}
                        onCopyFromRow={(id) => onCopyFromRow(id, index)}
                        disabled={!checked}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </STable>
      </div>
    </div>
  );
};

export default Recurs;
