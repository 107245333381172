import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import HeaderStar from 'components/HeaderStar';
import StripeConnection from 'componentsV2/StripeConnection';
import { SEND_PAYMENT_KEYS } from 'components/SendPaymentModal/constants';
import { COST_TYPE, STRIPE_MODULE } from 'utils/constants';
import { getAccountCounts } from 'Api/Payables';
import StatsSection from './StatsSection';
import TableSection from './TableSection';
import Costs from './TableSection/Tables/Costs';
import OpenBills from './TableSection/Tables/OpenBills';
import PaidBills from './TableSection/Tables/PaidBills';
import PastDueBills from './TableSection/Tables/PastDueBills';
import RecurringBills from './TableSection/Tables/RecurringBills';
import { SHeaderWrapper, SPageWrapper } from './Payables.styles';

const Payables = () => {
  const location = useLocation();
  const { defaultTab } = location?.state || {};
  const [statuses, setStatuses] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [statsRefreshIndex, setStatsRefreshIndex] = useState(Date.now());

  const tabs = useMemo(() => {
    return [
      {
        value: 0,
        key: 'openBills',
        label: 'Open Bills',
        count: statuses?.open_bills_count || 0,
        component: ({ refreshStats, getCounts }) => <OpenBills refreshStats={refreshStats} getCounts={getCounts} />,
      },
      {
        value: 1,
        key: 'pastDueBills',
        label: 'Past Due',
        count: statuses?.past_duo_count || 0,
        component: ({ refreshStats, getCounts }) => <PastDueBills refreshStats={refreshStats} getCounts={getCounts} />,
      },
      {
        value: 2,
        key: 'paidBills',
        label: 'Paid Bills',
        component: ({ refreshStats }) => <PaidBills refreshStats={refreshStats} />,
      },
      {
        value: 3,
        key: 'recurringBills',
        label: 'Recurring Bills',
        component: ({ refreshStats }) => <RecurringBills refreshStats={refreshStats} />,
      },
      {
        value: 4,
        key: 'variable',
        label: 'Variable Cost',
        component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.VARIABLE} refreshStats={refreshStats} />,
      },
      {
        value: 5,
        key: 'fixed',
        label: 'Fixed Cost',
        component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.FIXED} refreshStats={refreshStats} />,
      },
      {
        value: 6,
        key: 'maintenance',
        label: 'Maintenance Cost',
        component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.MAINTENANCE} refreshStats={refreshStats} />,
      },
      {
        value: 7,
        key: 'admin',
        label: 'Administrative Cost',
        component: ({ refreshStats }) => <Costs costTypeId={COST_TYPE.ADMINISTRATIVE} refreshStats={refreshStats} />,
      },
    ];
  }, [statuses]);

  const [activeTab, setActiveTab] = useState(defaultTab || tabs[0].value);

  const handleTabChange = (tab) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
    setActiveTab(tab);
  };

  const getStatuses = async () => {
    try {
      const { data } = await getAccountCounts();
      data && setStatuses(data);
    } catch (e) {
      // Do nothing
    }
  };

  const listenEmitter = ({ detail }) => {
    if (detail.activeName === SEND_PAYMENT_KEYS.OPEN_BILLS) {
      getStatuses();
    }
  };

  useEffect(() => {
    getStatuses();
    window.addEventListener('on_print_check_complete', listenEmitter);
    return () => window.removeEventListener('on_print_check_complete', listenEmitter);
  }, []);

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Payables' />
        <StripeConnection module={STRIPE_MODULE.ACCOUNTS_PAYABLE} />
      </SHeaderWrapper>
      <StatsSection changeTab={handleTabChange} statsRefreshIndex={statsRefreshIndex} />
      <TableSection
        tabs={tabs}
        activeTab={activeTab}
        getCounts={getStatuses}
        handleTabChange={handleTabChange}
        refreshStats={() => setStatsRefreshIndex(Date.now())}
      />
    </SPageWrapper>
  );
};

export default Payables;
