import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SListWrapper = styled.div`
  margin-top: 8px;
  padding-top: 4px;
  border-top: 1px solid ${palette.gray50};
  overflow: auto;
  height: calc(100% - 56px);

  .list-item {
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    padding: 6px 2px;
    cursor: pointer;
    border-radius: 4px;

    :hover {
      background-color: ${palette.indigo0};
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
