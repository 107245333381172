export const getInitialValues = (settings) => {
  const {
    current_location_update,
    pto_update,
    shipments_update,
    consider_operating_states,
    consider_allowed_equipment,
  } = settings || {};

  return {
    current_location_update: !!current_location_update,
    pto_update: !!pto_update,
    shipments_update: !!shipments_update,
    consider_operating_states: !!consider_operating_states,
    consider_allowed_equipment: !!consider_allowed_equipment,
  };
};
