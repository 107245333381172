import React from 'react';
import { Dropdown } from 'react-bootstrap';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import CustomPagination from '../CustomPagination/CustomPagination';

const ROWS_PER_PAGE = [25, 50, 100, 250, 500];

const TableFooter = ({
  totalCount,
  lastPage,
  totalLength,
  rowPerPage,
  onPageChange,
  currentPage,
  onChangeRowPerPage,
  style,
  rowsPerPageOptions,
}) => {
  const { use } = useTheme();
  const from = (currentPage - 1) * rowPerPage + 1;
  const to = totalLength + from - 1;

  const pageChangeHandler = (page) => {
    if (lastPage === 1) {
      return;
    }
    onPageChange(page);
  };

  return (
    <td className='d-flex mt-2 justify-content-between align-items-center' style={style}>
      <div className='d-flex'>
        <span className='tablePlaner_rowsPerPage'>
          {from || 1}-{to || 0} of {totalCount || 0} | Rows per page
        </span>
        <Dropdown
          className='dropdown'
          onSelect={(value) => {
            onChangeRowPerPage(Number(value));
          }}
        >
          <Dropdown.Toggle
            placement='top'
            variant='outline-secondary'
            id='dropdown-basic'
            className='tablePlaner_dropdownToggle'
            style={{
              color: use(palette.gray700, palette.gray200),
              backgroundColor: use(palette.white, palette.dark800),
            }}
          >
            {rowPerPage}
            <div className='tablePlaner_arrows_wrapper'>
              <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className='tablePlaner_menu' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            {(rowsPerPageOptions || ROWS_PER_PAGE).map((el) => {
              return (
                <Dropdown.Item
                  eventKey={String(el)}
                  active={+rowPerPage === el}
                  style={{
                    color:
                      +rowPerPage === el ? use(palette.white, palette.white) : use(palette.gray700, palette.gray200),
                  }}
                  key={el}
                >
                  {el}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='customPagination'>
        <CustomPagination
          lastPage={lastPage || 1}
          pageSize={rowPerPage || 0}
          totalCount={totalCount || 0}
          currentPage={currentPage || 1}
          onPageChange={pageChangeHandler}
        />
      </div>
    </td>
  );
};

export default TableFooter;
