import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { addMinimizedNotes } from 'store/reducers/root.reducer';
import { driverNoteData, driverCreateNotes, deleteNote as deleteDriverNote } from 'Api/Driver';
import { staffNoteData, staffCreateNotes, deleteStaffNote } from 'Api/Staff';
import { addNote, deleteNote, getShipmentNotes } from 'Api/Shipment';

import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as MinimizeIcon } from 'assets/icons/minus.svg';
import { deleteOperaotNote, operatorCreateNotes, operatorNoteData } from 'Api/OwnerOperator';
import ViewPdf from 'components/ViewPdf';
import useDateFormat from 'hooks/useDateFormat';
import AddNotes from 'components/TableShipments/detailsRow/steps/Notes/AddNotes';
import MaterialTableWrapper, { MaterialTableSort } from 'components/MaterialTableWrapper';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import CustomButton from 'components/CustomButton/CustomButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import { Typography } from 'components/Typography';
import TableFooter from 'components/TableFooter/TableFooter';
import classes from './notesEditModal.module.css';

const NotesEditModal = ({ isOpen, setIsOpen, minimizeLabel, id }) => {
  const { use, theme } = useTheme();
  const dispatch = useDispatch();
  const { convertToCustomerTime } = useDateFormat();
  const { activeRowEditItem } = useSelector((state) => state?.root);

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [notesData, setNotesData] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState(null);

  function close(e) {
    e.stopPropagation();
    setIsOpen('');
  }

  function onMinimize(e) {
    e.stopPropagation();
    dispatch(
      addMinimizedNotes({
        label: minimizeLabel,
        shipment_id: activeRowEditItem?.shipment_id,
        id: activeRowEditItem?.shipment_id,
      })
    );
    setIsOpen('');
  }

  /** add notes * */
  const createNote = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => values[key] !== '' && formData.append(key, values[key]));
    return addNote(formData);
  };

  const onAddNote = (form) => {
    if (activeRowEditItem.label === 'Driver') {
      setLoadingCreate(true);
      const notesObj = {
        ...form.values,
        driver_id: Number(id),
      };
      const formData = new FormData();
      formData.append('driver_id', notesObj.driver_id);
      formData.append('department_id', notesObj.department_id);
      formData.append('title', notesObj.title);
      formData.append('notes', notesObj.notes);
      formData.append('note_to_email', notesObj.note_to_email);
      formData.append('department_id', notesObj.department_id);
      formData.append('attachment', notesObj.attachment);
      driverCreateNotes(formData)
        .then((res) => {
          if (res && res?.data) {
            form.resetForm();
            getNotes();
          }
        })
        .finally(() => {
          setLoadingCreate(false);
        });
    } else if (activeRowEditItem.label === 'Staff') {
      setLoadingCreate(true);
      const notesObj = {
        ...form.values,
        driver_id: Number(id),
      };

      const formData = new FormData();
      formData.append('staff_id', notesObj.driver_id);
      formData.append('department_id', notesObj.department_id);
      formData.append('title', notesObj.title);
      formData.append('notes', notesObj.notes);
      formData.append('note_to_email', notesObj.note_to_email);
      formData.append('department_id', notesObj.department_id);
      formData.append('attachment', notesObj.attachment);
      staffCreateNotes(formData)
        .then((res) => {
          if (res && res?.data) {
            form.resetForm();
            getNotes();
          }
        })
        .finally(() => {
          setLoadingCreate(false);
        });
    } else if (activeRowEditItem.label === 'Owner_Operator') {
      setLoadingCreate(true);
      const notesObj = {
        ...form.values,
        driver_id: Number(id),
      };

      const formData = new FormData();
      formData.append('operator_id', notesObj.driver_id);
      formData.append('title', notesObj.title);
      formData.append('attachment', notesObj.attachment);
      formData.append('note_to_email', notesObj.note_to_email);
      formData.append('department_id', notesObj.department_id);
      if (notesObj.attachment) {
        formData.append('attachment', notesObj.attachment);
      }
      operatorCreateNotes(formData)
        .then((res) => {
          if (res && res?.data) {
            form.resetForm();
            getNotes();
          }
        })
        .finally(() => {
          setLoadingCreate(false);
        });
    } else if (activeRowEditItem.label === 'Shipment') {
      const { validateForm, submitForm, values } = form;
      submitForm();
      validateForm().then((errors) => {
        const isValid = Object.keys(errors).length === 0;
        if (isValid) {
          setLoadingCreate(true);
          createNote({ ...values, shipment_id: activeRowEditItem?.shipment_id })
            .then((res) => {
              if (res && res?.data) {
                form.resetForm();
                getNotes();
              }
            })
            .finally(() => {
              setLoadingCreate(false);
            });
        }
      });
    }
  };

  /** table components* */
  const columns = useMemo(() => {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
    return [
      {
        field: '#',
        title: <MaterialTableSort title='#' field='#' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>{Number(rowData?.tableData?.id) + 1}</span>
          );
        },
      },
      {
        field: 'title',
        title: <MaterialTableSort title='TITLE' field='title' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.title}</span>;
        },
      },
      {
        field: 'note',
        title: <MaterialTableSort title='NOTE' field='note' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <div className={classes.columns_notes_wrapper}>
              <div className={classes.columns_notes}>
                <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.notes}</span>
              </div>
              <div className={classes.notes_bottom_wrapper}>
                {!!rowData?.department?.department_email && (
                  <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                    <Typography variant='c1'>{rowData?.department?.department_email}</Typography>
                  </div>
                )}
                {!!rowData?.department?.department_name && (
                  <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                    <Typography variant='c1'>{rowData?.department?.department_name}</Typography>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        field: 'added_by',
        title: <MaterialTableSort title='ADDED BY' field='added_by' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const { first_name, last_name } = rowData.added_by || {};
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData.added_by === null ? '-' : `${first_name && first_name} ${last_name && last_name}`}
            </span>
          );
        },
      },
      {
        field: 'date',
        title: <MaterialTableSort title='DATE' field='date' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData?.created_at && convertToCustomerTime(rowData?.created_at)}
            </span>
          );
        },
      },
      {
        field: 'attachment',
        title: <MaterialTableSort title='ATTACHMENT' field='attachment' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return rowData.attachment ? (
            <div onClick={() => setPdfUrl(rowData.attachment.document)}>
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                View
              </Typography>
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        field: '',
        title: '',
        render: (rowData) => {
          const userId = user?.id ? user?.id : null;
          return (
            <div className={classes.deleteRowWrapper}>
              {userId !== null && userId === rowData?.added_by?.id && (
                <div onClick={(e) => onDeleteRowItem(e, rowData)} className={classes.deleteRow}>
                  <DeleteIcon fill={palette.indigo500} />
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }, [notesData.data, theme, sortData, onDeleteRowItem]);

  function onDeleteRowItem(e, rowData) {
    e.stopPropagation();
    if (activeRowEditItem.label === 'Driver') {
      deleteDriverNote({ noteId: rowData.id }).then(() => getNotes());
    } else if (activeRowEditItem.label === 'Staff') {
      deleteStaffNote({ noteId: rowData.id }).then(() => {
        getNotes();
      });
    } else if (activeRowEditItem.label === 'Owner_Operator') {
      deleteOperaotNote({ noteId: rowData.id }).then(() => {
        getNotes();
      });
    } else {
      deleteNote(rowData.id).then((res) => !!res?.success && getNotes());
    }
  }

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    getNotes({ page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
    const lastPage = notesData.last_page;
    if (lastPage !== 1) getNotes({ page, itemsPerPage: rowPerPage });
  };

  function sortingQuery(field, sortBy) {
    let newSortedData;
    if (sortData.find((el) => el.type === field)) {
      newSortedData = sortData.filter((el) => el.type !== field);
      newSortedData.unshift({ type: field, sortBy });
      setSortData(newSortedData);
    } else {
      newSortedData = [{ type: field, sortBy }, ...sortData];
      setSortData((p) => [{ type: field, sortBy }, ...p]);
    }
    getNotes({ sort: newSortedData, hasALoading: false });
  }

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        style={{ padding: '0 20px' }}
        totalCount={notesData.total}
        lastPage={notesData.last_page}
        totalLength={notesData.data.length}
        currentPage={notesData.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  function getNotes(params) {
    const { hasALoading = true, page = 1, sort, itemsPerPage } = params || {};
    if (activeRowEditItem?.label === 'Driver') {
      if (hasALoading) setLoading(true);
      driverNoteData({
        page,
        sort,
        driver_id: activeRowEditItem.id,
      })
        .then((res) => setNotesData(res))
        .finally(() => setLoading(false));
    }
    if (activeRowEditItem?.label === 'Staff') {
      if (hasALoading) setLoading(true);
      staffNoteData({
        page,
        sort,
        driver_staff_id: activeRowEditItem.id,
      })
        .then((res) => setNotesData(res))
        .finally(() => setLoading(false));
    }
    if (activeRowEditItem?.label === 'Owner_Operator') {
      if (hasALoading) setLoading(true);
      operatorNoteData({
        page,
        sort,
        operator_id: activeRowEditItem?.id,
      })
        .then((res) => setNotesData(res))
        .finally(() => setLoading(false));
    } else if (activeRowEditItem?.label === 'Shipment') {
      if (hasALoading) setLoading(true);
      getShipmentNotes({
        page,
        itemsPerPage: itemsPerPage || rowPerPage,
        sort: sort || sortData,
        shipment_id: activeRowEditItem?.shipment_id,
      })
        .then((res) => setNotesData(res))
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    if (isOpen) getNotes();
  }, [isOpen, id]);

  const styles = useMemo(() => {
    return {
      modalBody: {
        maxHeight: 360,
        overflowY: 'auto',
        padding: 0,
        backgroundColor: use(palette.white, palette.dark900),
      },
      modalFooter: {
        backgroundColor: use(palette.white, palette.dark900),
      },
      styleClose: {
        padding: '3px 8px',
        marginRight: 0,
      },
    };
  }, [use, palette]);

  return (
    <div>
      <ModalWrapper
        width='100%'
        isOpen={isOpen}
        dialogClassName={classes.modalDialog}
        styleBody={styles.modalBody}
        onHide={() => setIsOpen('')}
        header={
          <div className={classes.header}>
            <Typography variant='button2'>Notes</Typography>
            <div className={classes.headerRight}>
              <div className={classes.rightIconHeader} onClick={(e) => onMinimize(e)}>
                <MinimizeIcon />
              </div>
            </div>
          </div>
        }
        footer={
          <div style={styles.modalFooter} className={classes.modalFooterLane}>
            <CustomButton title='Close' type='secondary' onClick={(e) => close(e)} styleButton={styles.styleClose} />
          </div>
        }
      >
        <div className={classes.tableWrapper}>
          <div style={{ margin: '0 20px' }}>
            <AddNotes onSubmit={onAddNote} loading={loadingCreate} />
          </div>

          {loading ? (
            <EquipmentPreloaderSkeleton />
          ) : (
            <MaterialTableWrapper
              columns={columns}
              data={notesData.data}
              rowPerPage={rowPerPage}
              components={{ Pagination: PaginationComponent }}
              style={{ backgroundColor: use(palette.white, palette.dark900) }}
            />
          )}
          {!!pdfUrl && (
            <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Document' />
          )}
        </div>
      </ModalWrapper>
    </div>
  );
};

export default NotesEditModal;
