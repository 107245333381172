import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import './PendingDispatch.css';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import down from 'assets/icons/drivers/down.svg';
import headerIcon from 'assets/icons/vehicles/green.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ShipmentIcon } from 'assets/icons/vehicles/shipment.svg';

import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { formatAmount } from 'services/IFTAServices';
import TableRowsEdit from 'components/TableRowsEdit';
import { PENDING_DISPATCH_ACTIONS } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { updateRowEditItem } from 'store/reducers/root.reducer';
import TableShipmentRowEditChildes from 'components/TableShipments/TableShipmentRowEditChildes';
import {
  deleteDispatch,
  getContactsBillingCharges,
  getFullPrices,
  getPendingDispatch,
  getPlannedShipments,
  getPreviousShipments,
  getShipmentStops,
} from 'Api/Planner';
import { DateTimePicker } from 'common/Pickers';
import PendingDispatchMap from './PendingDispatchMap';
import { useColumns } from './PendingDispatch.data';

export default function PendingDispatch() {
  const { use } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const [searchParams] = useSearchParams();
  const shipmentId = searchParams.get('id');
  const selectList = {
    1: 'Current Location',
    2: 'Previous Shipment',
    3: 'Planned Shipment',
  };
  const { currency } = useSelector((state) => state.root);
  const [routeSelect, setRouteSelect] = useState('practical');
  const [locationSelect, setLocationSelect] = useState('1');
  const [customerContactBook, setCustomerContactBook] = useState([]);
  const [previousShipments, setPreviousShipments] = useState([]);
  const [previousShipment, setPreviousShipment] = useState(null);
  const [planedShipments, setPlanedShipments] = useState([]);
  const [planedShipment, setPlanedShipment] = useState(null);
  const [data, setData] = useState(null);
  const [flatRateChecked] = useState(false);
  const [flatRate, setFlatRate] = useState();
  const [shipmentStops, setShipmentStops] = useState(null);
  const [fuelPrice, setFuelPrice] = useState(null);
  const [shipmentData, setShipmentData] = useState({});
  const { formatDateTime } = useDateFormat();
  const matches = useMediaQuery('(min-width:1600px)');

  const {
    values,
    handleChange,
    setFieldValue: setOptionsForm,
  } = useForm({
    initialValues: {
      immediately: false,
      in_future: false,
      future_date: null,
      after_specific_shipment_complated: false,
      before_scheduled_pickup: false,
      before_scheduled_pickup_hours: '0',
      send_trucking_updates_to_customer: false,
      send_trucking_link_to_customer: false,
      recipient_customer_contact_book_id: '',
      send_pickup_reminder_to_driver: false,
      send_pickup_reminder_to_driver_before_minutes: '',
    },
  });

  const { values: mapValues, setFieldValue } = useForm({
    initialValues: {
      route_type: 'practical',
      hwy_only: false,
      open_borders: false,
      haz_mat: false,
      avoid_tolls: false,
      show_traffic: false,
      show_weather: false,
      truck_restriction: true,
    },
  });

  useEffect(() => {
    setFieldValue('hwy_only', !!data?.shipment_route?.highway_only);
    setFieldValue('open_borders', !!data?.shipment_route?.open_borders);
    setFieldValue('haz_mat', !!data?.shipment_route?.hazmat_id);
    setFieldValue('avoid_tolls', !!data?.shipment_route?.avoid_tolls);
    setFieldValue('route_type', data?.shipment_route?.route_type);
    setRouteSelect(data?.shipment_route?.route_type);

    setOptionsForm('immediately', !!data?.shipment_option?.immediately);
    setOptionsForm('in_future', !!data?.shipment_option?.in_future);
    data?.shipment_option?.future_date &&
      setOptionsForm('future_date', moment(data.shipment_option.future_date).toDate());
    setOptionsForm('after_specific_shipment_complated', !!data?.shipment_option?.after_specific_shipment_complated);
    data?.shipment_option?.specific_shipment_id &&
      setOptionsForm('specific_shipment_id', data?.shipment_option?.specific_shipment_id);
    setOptionsForm('before_scheduled_pickup', !!data?.shipment_option?.before_scheduled_pickup);
    data?.shipment_option?.before_scheduled_pickup_hours &&
      setOptionsForm('before_scheduled_pickup_hours', data?.shipment_option?.before_scheduled_pickup_hours);
    setOptionsForm('send_trucking_updates_to_customer', !!data?.shipment_option?.send_trucking_updates_to_customer);
    data?.shipment_option?.recipient_customer_contact_book_id &&
      setOptionsForm('recipient_customer_contact_book_id', data?.shipment_option?.recipient_customer_contact_book_id);
    setOptionsForm('send_pickup_reminder_to_driver', !!data?.shipment_option?.send_driver_reminder);
    data?.shipment_option?.send_driver_reminder &&
      setOptionsForm('send_pickup_reminder_to_driver_before_minutes', data?.shipment_option?.send_driver_reminder);

    setLocationSelect(data?.assign_driver_from);
    setPreviousShipment(data?.driver_from_shipment);
    setPlanedShipment(data?.driver_from_shipment);
  }, [data]);

  const revertDispatch = () => {
    deleteDispatch({ shipment_id: shipmentId }).then(() => {
      if (data?.brokerage_dispatch) {
        navigate(`/planner/carrier-dispatch?id=${shipmentId}&carrier=${data.brokerage_dispatch.carrier_id}`);
      } else {
        navigate(`/planner/dispatch?id=${shipmentId}&equipment=${data.equipment_id}`);
      }
    });
  };

  const getStatusParamsInStatus = useCallback((status, pendingDispatch) => {
    let bgStatus;
    let colorStatus;
    let statusName;
    if (pendingDispatch) {
      bgStatus = '#F0F2F7';
      colorStatus = '#5A6376';
      statusName = `Pending Dispatch`;
    }

    return { bgStatus, colorStatus, statusName };
  }, []);

  const style = useMemo(() => {
    return {
      mapWrapper: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        height: '726px',
      },
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        borderRadius: '8px 0 0 8px',
      },
    };
  }, []);

  useEffect(() => {
    getPendingDispatch(shipmentId).then((res) => {
      setData(res.data);
    });
  }, [shipmentId]);

  useEffect(() => {
    getShipmentStops(shipmentId).then((res) => {
      setShipmentStops(res.data);
    });
    getFullPriceShipment();
  }, []);

  useEffect(() => {
    if (data?.driver1?.id) {
      const params = {
        driver_id: data?.driver1?.id,
      };
      getPlannedShipments(params).then((res) => {
        setPlanedShipments(res.data);
      });
      getPreviousShipments(params).then((res) => {
        setPreviousShipments(res.data);
      });
    }
  }, [data]);

  const showWarning = () => {
    showToaster({ type: 'warning', message: 'You must revert dispatch in order to go back!' });
  };

  useEffect(() => {
    if (shipmentStops?.shipment_billing[0]?.billing_customer?.id) {
      getContactsBillingCharges(shipmentStops?.shipment_billing[0]?.billing_customer?.id).then((res) => {
        setCustomerContactBook(res.data);
      });
    }
  }, [shipmentStops?.shipment_billing[0]?.billing_customer?.id]);

  const renderTooltip = (driver) => {
    if (data?.equipment?.driver_with_gps.length > 1) {
      return (
        <Tooltip>
          {driver.fname} {driver.lname}
        </Tooltip>
      );
    }
    return <span />;
  };

  const getFullPriceShipment = () => {
    return getFullPrices().then((res) => {
      if (res && res?.data) {
        setFuelPrice(res.data?.diesel?.price);
      }
      return res;
    });
  };

  function onOptionClick(item) {
    if (item.route) {
      +item.id === 18 && navigate(`/${item.route}/${data.shipment_id}/dispatch`);
      +item.id === 16 && navigate(`/${item.route}/${data.shipment_id}/new`);
      return;
    }

    switch (item?.id) {
      case 1:
        dispatch(updateRowEditItem({ ...data, label: 'Shipment' }));
        break;
      case 9:
        navigate(`/shipment/${data?.shipment_id}`, { state: data });
        break;
      case 4:
      case 5:
      case 6:
      case 7:
      case 13:
      case 43:
      case 61:
      case 97:
        setShipmentData({ item, rowData: data });
        break;
      case 8:
      case 11:
      case 12:
      case 14:
        setShipmentData({ item, rowData: data });
        break;
      case 19:
        setShipmentData({ item, rowData: [data] });
        break;
      case 17:
        setShipmentData({ item, rowData: [data] });
        break;
      case 20:
        navigate(`/planner/plan?id=${data.shipment_id}&action=dispatch`);
        break;
      case 21:
        navigate(`/planner/plan?id=${data.shipment_id}&action=offer`);
        break;
      case 23:
        revertDispatch();
        break;
      default:
    }
  }

  const columns = useColumns({
    data,
    renderTooltip,
    flatRateChecked,
    flatRate,
    setFlatRate,
    previousShipments,
    previousShipment,
    showWarning,
    selectList,
    locationSelect,
    planedShipment,
    planedShipments,
  });

  return (
    <div className='profile-container'>
      <div className='user-profile-header w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='dispatch-vehicle-container w-100'>
          <div className='plan-vehicle-text-wrapper'>
            <div onClick={() => navigate(-1)} className='back_plan_to_vehicle'>
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className='back'>
                Back
              </Typography>
            </div>
          </div>
          {shipmentStops?.is_edi_api || shipmentStops?.manual_edi_api ? (
            <div className='dispatch-vehicle-text-wrapper'>
              <div className='d-flex'>
                <img src={headerIcon} alt='headerIcon' />
                <p className='dispatch-vehicle-text green-text'>EDI/API SHIPMENT </p>
              </div>
            </div>
          ) : null}

          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between align-items-center mb2'>
              <div className='dispatch-vehicle-details w-100'>
                <div className='dispatch-vehicle-details-item'>
                  <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Pending Dispatch
                  </h2>
                </div>
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text'>
                    <span className='bold-text'>{shipmentStops?.shipment_id}</span>
                    (REF: {shipmentStops?.shipment_billing[0]?.reference_id})
                  </p>
                </div>
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text'>
                    {shipmentStops?.shipment_billing[0]?.billing_customer?.company_name}
                  </p>
                </div>
                {shipmentStops?.shipment_billing[0]?.groups && (
                  <div className='dispatch-vehicle-details-item'>
                    <div
                      className='tablePlaner-shortName'
                      style={{
                        color: use(palette.gray900, palette.gray700),
                        backgroundColor: shipmentStops?.shipment_billing[0]?.groups?.color,
                      }}
                    >
                      {shipmentStops?.shipment_billing[0]?.groups?.short_name}
                    </div>
                  </div>
                )}

                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text'>{shipmentStops?.shipment_stops_count} Stops</p>
                </div>
                <div className='dispatch-vehicle-details-item'>
                  <div className='d-flex justify-content-start}'>
                    <div className='tablePlaner_status-wrapper'>
                      <span
                        className='tablePlaner_status'
                        style={{
                          backgroundColor: getStatusParamsInStatus(
                            shipmentStops?.status,
                            shipmentStops?.pending_dispatch
                          )?.bgStatus,
                          color: getStatusParamsInStatus(shipmentStops?.status, shipmentStops?.pending_dispatch)
                            ?.colorStatus,
                        }}
                      >
                        {getStatusParamsInStatus(shipmentStops?.status, shipmentStops?.pending_dispatch)?.statusName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text'>
                    $ {formatAmount(shipmentStops?.shipment_billing[0]?.shipment_amount)} USD
                  </p>
                </div>
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text'>
                    {Math.round(Number(data?.empty_miles || 0) + Number(data?.loaded_miles || 0))} Miles
                  </p>
                </div>
                <div className='plan-vehicle-details-item'>
                  <p className='plan-details-text'>
                    {!data?.shipment_type || data?.shipment_type === 'tl' ? 'TL' : 'LTL'}
                  </p>
                </div>
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text d-flex'>
                    Route Duration: <span className='cost-purple-text ms-2'>{data?.planned_duration}</span>
                  </p>
                </div>
              </div>
              <TableRowsEdit
                placement='bottom-end'
                overlayStyle={{ width: 210 }}
                options={PENDING_DISPATCH_ACTIONS.filter((i) => i.id !== 16)}
                onClickOption={(e, i) => onOptionClick(e, i)}
                targetStyle={{ width: 20, height: 20 }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20, color: '#AA5B00FF' }}>
              <InfoIcon fill='#AA5B00FF' />
              <Typography
                variant='s2'
                style={{
                  color: '#AA5B00FF',
                  marginLeft: 6,
                }}
              >
                {!!data?.shipment_option?.in_future && !!data?.shipment_option?.future_date && (
                  <span>
                    Note: This shipment will be dispatched at {formatDateTime(data?.shipment_option?.future_date)}.
                  </span>
                )}
                {!!data?.shipment_option?.after_specific_shipment_complated && (
                  <span>Note: This shipment will be dispatched when next in line (based on time - auto dispatch)</span>
                )}
                {!!data?.shipment_option?.before_scheduled_pickup_hours &&
                  !!data?.shipment_option?.before_scheduled_pickup && (
                    <span>
                      Note: This shipment will be dispatched after {data.shipment_option.before_scheduled_pickup_hours}{' '}
                      hours before scheduled pickup.
                    </span>
                  )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className='w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='table-wrap'>
          <div className='sub-table-container-dispatch'>
            {!!data && typeof data === 'object' && Object.keys(data).length > 0 ? (
              <MaterialTableWrapper
                columns={columns}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  borderTop: 'none',
                  width: '100%',
                  minHeight: 200,
                }}
                data={[data]}
                options={{
                  paging: false,
                  sorting: false,
                  toolbar: false,
                  rowStyle: {
                    borderBottom: 'none',
                    width: '100%',
                  },
                  headerStyle: {
                    borderTop: 'none',
                    width: '100%',
                  },
                }}
              />
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      </div>
      <div className='dispatch-vehicle-content'>
        <div className='dispatch-vehicle-data'>
          <div>
            <form>
              <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Options
              </h6>
              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.immediately}
                  name='immediately'
                  onChange={() => showWarning()}
                />
                <p className='dispatch-vehicle-check-text'>Dispatch to Driver Immediately</p>
              </div>
              <div className='with-border mt-2' />

              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.after_specific_shipment_complated}
                  name='after_specific_shipment_complated'
                  onChange={() => showWarning()}
                />
                <p className='dispatch-vehicle-check-text me-2'>
                  Pending Dispatch as Next Shipment in Sequential Date Order
                </p>
              </div>
              <div className='with-border mt-2' />

              <div className='d-flex align-items-center my-2 w-100'>
                <CustomCheckbox
                  type='switch'
                  checked={values.in_future}
                  name='in_future'
                  onChange={() => showWarning()}
                />
                <p className='dispatch-vehicle-check-text me-2'>Pending Dispatch Queue for Dispatch on</p>
                <DateTimePicker
                  width='190px'
                  name='future_date'
                  value={values.future_date}
                  onChange={(value) => handleChange('future_date', value)}
                  onBlur={(e) => {
                    const { value } = e.target;
                    if (value && moment(value).isValid() && moment(value).isBefore(moment())) {
                      handleChange('future_date', new Date());
                    }
                  }}
                  disablePast
                />
                <p className='dispatch-vehicle-check-text'>(Based on Specific Date/Time)</p>
              </div>
              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.before_scheduled_pickup}
                  name='before_scheduled_pickup'
                  onChange={() => showWarning()}
                />
                <p className={`dispatch-vehicle-check-text ${matches ? 'nowrap' : ''}`}>
                  Pending Dispatch Queue for Dispatch
                </p>
                <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                  <input
                    type='number'
                    placeholder='0'
                    name='before_scheduled_pickup_hours'
                    value={values.before_scheduled_pickup_hours}
                    onChange={showWarning}
                  />
                </div>
                <p className='dispatch-vehicle-check-text'>Hour(s) Prior to Scheduled Pickup</p>
              </div>
              <div className='with-border mt-2' />
              {customerContactBook && (
                <>
                  <div className='d-flex align-items-center my-2'>
                    <CustomCheckbox
                      type='switch'
                      checked={values.send_trucking_updates_to_customer}
                      name='send_trucking_updates_to_customer'
                      onChange={() => showWarning}
                    />
                    <p className='dispatch-vehicle-check-text'>Send Tracking Updates to Customer Contact</p>
                    <CustomSelect
                      options={customerContactBook}
                      defaultValue='Select Contact...'
                      styles={{ maxWidth: 200, marginLeft: '0.5rem', marginTop: 0 }}
                      id='select_list'
                      name='contact_name'
                      propertyName='contact_name'
                      patchProperty='id'
                    />
                  </div>
                  <div className='with-border mt-2' />
                </>
              )}
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.send_pickup_reminder_to_driver}
                  name='send_pickup_reminder_to_driver'
                  onChange={() => showWarning()}
                />
                <p className='dispatch-vehicle-check-text'>Send Driver SMS Reminder</p>
                <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                  <input
                    type='number'
                    placeholder='0'
                    name='send_pickup_reminder_to_driver_before_minutes'
                    value={values.send_pickup_reminder_to_driver_before_minutes}
                    onChange={handleChange}
                  />
                </div>
                <p className='dispatch-vehicle-check-text'>Minute(s) Prior to Scheduled Pickup</p>
              </div>
            </form>

            <div className='with-border mt-2' />
            <div>
              <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Cost Estimate
              </h6>
              <div className='with-border mt-2' />
              <div className='dispatch-vehicle-cost'>
                <div>
                  <p className='dispatch-vehicle-cost-text'>Driver Costs</p>
                  <p className='dispatch-vehicle-cost-text'>
                    Estimated Fuel Costs{' '}
                    <span className='cost-purple-text'> ${(+fuelPrice).toFixed(2)} /GAL DIESEL</span>
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-bold-text'>Estimated Costs</p>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {formatAmount(data?.driver1_amount)}
                  </p>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {formatAmount(data?.estimated_fuel_cost)}
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-bold-text'>
                    {currency}
                    {formatAmount(Number(data?.driver1_amount || 0) + Number(data?.estimated_fuel_cost || 0))}
                  </p>
                </div>
              </div>
            </div>
            <div className='with-border mt-2' />
            <div>
              <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Net Profit
              </h6>
              <div className='with-border mt-2' />
              <div className='dispatch-vehicle-cost'>
                <div>
                  <p className='dispatch-vehicle-cost-text'>
                    Gross Shipment Charges{' '}
                    <span className='profit-gray-text'>
                      {' '}
                      $
                      {formatAmount(
                        Number(data?.total_amount || 0) /
                          (Number(data?.empty_miles || 0).toFixed() + Number(data?.loaded_miles || 0).toFixed())
                      )}{' '}
                      /MILE EST. GROSS
                    </span>
                  </p>
                  <p className='dispatch-vehicle-cost-text'>
                    Estimated Shipment Costs{' '}
                    <span className='profit-red-text'>
                      $
                      {formatAmount(
                        (Number(data?.driver1_amount) + Number(data?.estimated_fuel_cost)) /
                          (Number(data?.empty_miles || 0).toFixed() + Number(data?.loaded_miles || 0).toFixed())
                      )}{' '}
                      /MILE EST. COST
                    </span>
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-green-text'>
                    Estimated Net Profit{' '}
                    <span className='profit-green-text'>
                      $
                      {formatAmount(
                        Number(data?.net_profit) /
                          (Number(data?.empty_miles || 0).toFixed() + Number(data?.loaded_miles || 0).toFixed())
                      )}{' '}
                      /MILE EST. NET
                    </span>
                  </p>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <p className='dispatch-vehicle-cost-text'>${formatAmount(data?.total_amount)}</p>
                  <p className='dispatch-vehicle-cost-text'>
                    ${formatAmount(Number(data?.driver1_amount) + Number(data?.estimated_fuel_cost))}
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-green-text'>${formatAmount(data?.net_profit)}</p>
                </div>
              </div>
            </div>
            <div className='with-border mt-2' />
            <div className='d-flex justify-content-end mt-4 mb-4'>
              <CustomButton
                width={12}
                height={12}
                type='secondary'
                title='Revert Dispatch'
                styleButton={{ padding: '6px 12px', marginRight: '18px' }}
                onClick={revertDispatch}
              />
              <CustomButton
                width={12}
                height={12}
                type='primary'
                disabled
                title='Dispatch Vehicle'
                leftIcon={<ShipmentIcon style={{ marginRight: 10 }} />}
                styleButton={{ padding: '6px 12px' }}
              />
            </div>
          </div>
        </div>
        <div className='dispatch-vehicle-map'>
          <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
            Route
          </h6>
          <div className='with-border mt-2' />
          <div className='d-flex justify-content-between align-items-center my-2'>
            <div className='d-flex align-items-center'>
              <p className='dispatch-vehicle-check-text' style={{ marginLeft: 0, textTransform: 'capitalize' }}>
                {routeSelect}
              </p>
              <Dropdown className='d-inline mx-2' autoClose='outside'>
                <Dropdown.Toggle
                  id='dropdown-autoclose-outside'
                  style={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    boxShadow: 'none',
                    marginLeft: '0',
                    paddingLeft: '10x',
                  }}
                >
                  <img src={down} alt='down' />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                    onClick={() => showWarning()}
                  >
                    Practical
                  </Dropdown.Item>

                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => showWarning()}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Shortest
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => showWarning()}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Fastest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <div className='d-flex align-items-center mb-1'>
                <CustomCheckbox checked={!!mapValues.open_borders} onChange={() => showWarning()}>
                  <p className='dispatch-vehicle-check-text'>Open Borders</p>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={!!mapValues.hwy_only}
                  onChange={() => showWarning()}
                  style={{ marginLeft: '0.5rem' }}
                >
                  <p className='dispatch-vehicle-check-text'>Highway Only</p>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={!!mapValues.haz_mat}
                  onChange={() => showWarning()}
                  style={{ marginLeft: '0.5rem' }}
                >
                  <p className='dispatch-vehicle-check-text'>HazMat</p>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={!!mapValues.avoid_tolls}
                  onChange={() => showWarning()}
                  style={{ marginLeft: '0.5rem' }}
                >
                  <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={!!mapValues.show_traffic}
                  onChange={() => showWarning()}
                  style={{ marginLeft: '0.5rem' }}
                >
                  <p className='dispatch-vehicle-check-text'>Traffic</p>
                </CustomCheckbox>
                <CustomCheckbox
                  checked={!!mapValues.show_weather}
                  onChange={() => showWarning()}
                  style={{ marginLeft: '0.5rem' }}
                >
                  <p className='dispatch-vehicle-check-text'>Weather</p>
                </CustomCheckbox>
              </div>
              <div className='d-flex align-items-center justify-content-between' />
            </div>
          </div>
          <div style={style.mapWrapper}>
            {shipmentStops && (
              <PendingDispatchMap data={shipmentStops?.shipment_stops} mapValues={mapValues} shipment={data} />
            )}
          </div>
        </div>
      </div>
      <TableShipmentRowEditChildes data={shipmentData} onSubmit={() => null} />
    </div>
  );
}
