import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SRowActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${palette.red50};

  margin: 0 16px;
  padding: 6px 0;
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const SButtonsWrapper = styled.div`
  display: flex;
  column-gap: 12px;

  button {
    padding: 3px 12px;
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    margin: 0;

    &.decline-button {
      background-color: ${palette.red500} !important;
      box-shadow: 0 1px 1px rgb(0 0 0 / 14%), 0 0 0 1px ${palette.red200}, 0 2px 5px rgb(79 90 237 / 10%),
        inset 0 1px 0 ${palette.red200};
    }
  }
`;
