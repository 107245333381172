export const DEFAULT_CHECK = {
  name: 'Carter Levin',
  amount: '1000',
  address: 'Address Line 1',
  address2: 'Address Line 2',
  city: {
    name: 'City',
  },
  state: {
    short_name: 'State',
  },
  zipcode: 'Zip Code',
};
