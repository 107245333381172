import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackArrow from 'assets/icons/drivers/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRightWhite.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/detention.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getDriverHos } from 'Api/HOS';
import Tooltip from 'common/Tooltip';
import Loader from 'common/Loader';
import Chip from 'common/Chip';
import { statues } from 'pages/Hos-Logbook/CustomCollapsibleRow/CustomCollapsibleRow.data';
import classes from './LogbookDetails.module.scss';
import LogbookList from '../LogbookList';
import { SPageWrapper } from '../Hos-Logbook.styles';
import UserInfo from '../../../components/UserInfo';

const LogbookDetail = ({ isDriverProfile }) => {
  const { id } = useParams();
  const { use } = useTheme();
  const navigate = useNavigate();
  const [driverHos, setDriverHost] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDriverHoursOfServiceData = async () => {
    try {
      setLoading(true);
      const { data } = await getDriverHos(id);
      setDriverHost(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    getDriverHoursOfServiceData();
  }, []);

  return (
    <SPageWrapper>
      {!isDriverProfile && (
        <div className={classes.backNavigateContainer} onClick={() => navigate(-1)}>
          <img src={BackArrow} alt='Back-Arrow' />
          <span className={classes.navigatPageName}>Go Back</span>
        </div>
      )}

      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {!isDriverProfile && (
            <div className={classes.carrierProfileHeader}>
              <div>
                <div className={classes.userDetailsContainer}>
                  <div>
                    <div className={classes.userNameAndActionsContainer}>
                      <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray50) }}>
                        Hours of Service Report
                      </Typography>

                      {driverHos?.driver && (
                        <UserInfo
                          data={{
                            ...driverHos.driver,
                            name: `${driverHos.driver?.fname} ${driverHos.driver?.lname}`,
                            user_type: 'driver',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={classes.boxesWrapper} style={{ borderTop: !isDriverProfile ? '1px solid #E9EDF5' : 'none' }}>
            <div className={classes.boxesWrapperItem}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Time in current status
              </Typography>
              <br />
              <div className='d-flex align-items-center gap-5'>
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  {driverHos?.time_in_current_status || '-'}
                </Typography>
                {!!driverHos?.duty_status && (
                  <Chip
                    bgColor={statues[driverHos?.duty_status]?.bgColor}
                    labelColor={statues[driverHos?.duty_status]?.color}
                    label={statues[driverHos?.duty_status]?.name}
                    fontWeight={500}
                    fontSize='11px'
                    style={{ textTransform: 'uppercase' }}
                  />
                )}
              </div>
            </div>
            <div className={classes.boxesWrapperItem}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Vehicle Name
              </Typography>
              <br />
              {driverHos?.equipment_id ? (
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  <div>
                    {!!driverHos?.equipment_id &&
                      driverHos?.equipment_id !== driverHos?.driver?.equipment?.[0]?.equipment_id && (
                        <p className='table-data-text' style={{ color: palette.gray700 }}>
                          <span>{driverHos?.equipment_id}</span>
                        </p>
                      )}
                    {driverHos?.equipment_id === driverHos?.driver?.equipment?.[0]?.equipment_id && (
                      <div className='d-flex gap-2 align-items-center'>
                        <Typography
                          variant='s3'
                          style={{ textDecoration: 'underline', color: palette.green500 }}
                          onClick={() =>
                            navigate(`/equipment-profile/vehicle/${driverHos?.driver?.equipment?.[0]?.id}`)
                          }
                        >
                          {driverHos?.driver?.equipment?.[0]?.equipment_id}{' '}
                          {driverHos?.driver?.equipment?.[0]?.equipment_type?.title}
                        </Typography>
                        <Tooltip
                          title='Vehicle matches our system assignment for this driver.'
                          placement='top'
                          componentsProps={{
                            placement: 'top',
                            tooltip: {
                              sx: {
                                fontSize: 13,
                                fontFamily: 'Inter',
                                whiteSpace: 'nowrap',
                                maxWidth: 'unset',
                                cursor: 'pointer',
                              },
                            },
                          }}
                        >
                          <CheckIcon />
                        </Tooltip>
                      </div>
                    )}
                    {!!driverHos?.driver?.equipment &&
                      driverHos?.equipment_id !== driverHos?.driver?.equipment?.[0]?.equipment_id && (
                        <div className='d-flex gap-2 align-items-center'>
                          <ArrowRight fill={palette.red400} />
                          <Typography
                            variant='s3'
                            onClick={() =>
                              navigate(`/equipment-profile/vehicle/${driverHos?.driver?.equipment?.[0]?.id}`)
                            }
                            style={{ textDecoration: 'underline', color: palette.red400, cursor: 'pointer' }}
                          >
                            {driverHos?.driver?.equipment?.[0]?.equipment_id}{' '}
                            {driverHos?.driver?.equipment?.[0]?.equipment_type?.title}
                          </Typography>
                          <Tooltip
                            title='Driver HOS vehicle does not match what the driver is assigned in the system'
                            placement='top'
                            componentsProps={{
                              placement: 'top',
                              tooltip: {
                                sx: {
                                  fontSize: 13,
                                  fontFamily: 'Inter',
                                  whiteSpace: 'nowrap',
                                  maxWidth: 'unset',
                                  cursor: 'pointer',
                                },
                              },
                            }}
                          >
                            <WarningIcon fill={palette.red400} />
                          </Tooltip>
                        </div>
                      )}
                  </div>
                </Typography>
              ) : (
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  -
                </Typography>
              )}
            </div>
            <div className={classes.boxesWrapperItem}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Time until break
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {driverHos?.time_in_current_status || '-'}
              </Typography>
            </div>
            <div className={classes.boxesWrapperItem}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Drive remaining
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {driverHos?.drive_remaining || '-'}
              </Typography>
            </div>

            <div className={classes.boxesWrapperItem}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Shift remaining
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {driverHos?.shift_remaining || '-'}
              </Typography>
            </div>

            <div className={classes.boxesWrapperItem}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Cycle remaining
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {driverHos?.cycle_remaining_duration || '-'}
              </Typography>
            </div>
            <div className={classes.boxesWrapperItem}>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Cycle tomorrow
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {driverHos?.cycle_tomorrow_duration || '-'}
              </Typography>
            </div>
          </div>
        </>
      )}

      <LogbookList getDriverHoursOfServiceData={getDriverHoursOfServiceData} />
    </SPageWrapper>
  );
};

export default LogbookDetail;
