import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SFormWrapper = styled.div`
  .line-wrapper {
    width: 100%;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9edf5;
  }

  .navigate-button {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${palette.indigo500};
    cursor: pointer;
    white-space: nowrap;
  }

  .navigate-button:hover {
    text-decoration: underline;
  }
`;
