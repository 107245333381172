import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Icons } from 'assets/icons';
import { ViewMainTenanceRecord } from 'Api/Equipment';
import useDateFormat from 'hooks/useDateFormat';
import './MaintenanceRecordInspection.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1200px',
  minHeight: 400,
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  p: 4,
  padding: 0,
  borderRadius: '8px',
};

const MaintenanceRecordInspection = ({ id, handleMaintenanceRecordInspectionClose }) => {
  const { convertToCustomerTime, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [viewData, setViewData] = useState();
  async function ViewMaintenanceRecord() {
    ViewMainTenanceRecord({ id }).then((res) => {
      setViewData(res?.data);
    });
  }
  useEffect(() => {
    ViewMaintenanceRecord();
  }, []);

  const onButtonClick = (data) => {
    fetch(data).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const alink = document.createElement('a');
        alink.href = url;
        alink.download = 'Repair-Receipt.pdf';
        alink.click();
      });
    });
  };
  return (
    <div className='container'>
      <Box sx={style}>
        <div className='mt-3 ms-2 mb-3'>
          <span className='maintenance-record-header'>Maintenance Record : Inspection</span>
        </div>
        <div className='container'>
          <table className='table inspection-table'>
            <thead>
              <tr>
                <td className='maintenance-record-title'>Title</td>
                <td className='maintenance-record-data'>{viewData?.record_type?.type}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='maintenance-record-title'>Description</td>
                <td className='maintenance-record-data'>{viewData?.description}</td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Interval</td>
                <td className='maintenance-record-data'>
                  {viewData?.interval} {viewData?.interval_type}
                </td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Equipment ID</td>
                <td className='maintenance-record-data'>{viewData?.equipment?.equipment_id} </td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Service Date + Time</td>
                <td className='maintenance-record-data'>{formatDateTime(viewData?.service_date_time)}</td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Serviced At</td>
                <td className='maintenance-record-data'>
                  {viewData?.service_at?.name} {viewData?.service_at?.address} {viewData?.service_at?.city?.name}{' '}
                  {viewData?.service_at?.state?.name} {viewData?.service_at?.zipcode}
                </td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Cost</td>
                <td className='maintenance-record-data'>
                  {' '}
                  {currency}
                  {viewData?.cost}
                </td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Mechanic Notes</td>
                <td className='maintenance-record-data'>{viewData?.mechanic_notes}</td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Repair Receipt</td>
                <td
                  className='maintenance-record-data'
                  style={{
                    color: '#4F5AED',
                    cursor: 'pointer',
                    wordBreak: 'break-all',
                  }}
                >
                  <span
                    className='ms-1 me-1 repair-receipt '
                    style={{
                      background: '#F0F1FA',
                      borderRadius: '4px',
                      padding: '2px 8px',
                    }}
                  >
                    <span
                      className='repair-receipt-download'
                      onClick={() => onButtonClick(viewData?.repair_receipt_attach)}
                    >
                      <img src={Icons.Downloads} alt='' />
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Updated By</td>
                <td className='maintenance-record-data'>{viewData?.added_by}</td>
              </tr>
              <tr>
                <td className='maintenance-record-title'>Date/time</td>
                <td className='maintenance-record-data'>{convertToCustomerTime(viewData?.created_at)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='maintenance-record-modal-done-button'
            onClick={handleMaintenanceRecordInspectionClose}
          >
            Done
          </button>
        </div>
      </Box>
    </div>
  );
};

export default MaintenanceRecordInspection;
