export const getInitialValues = (transaction, form) => {
  const { date, reference_id, payee, account, memo, debit, credit } = transaction || {};

  return {
    date: date || form?.date ? new Date(date || form.date) : null,
    reference_id: reference_id || '',
    payee: payee || null,
    account: account || null,
    memo: memo || '',
    debit: debit || '',
    credit: credit || '',
  };
};
