import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  min-width: 350px;
  padding: 24px 0 0 20px;
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: pointer;

  :hover span {
    text-decoration: underline;
  }
`;

export const STable = styled.table`
  width: 100%;
  margin-top: 12px;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 6px;
  }

  td {
    padding: 12px 6px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }
`;

export const SText = styled.p`
  color: ${palette.gray700};
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0;

  .dots {
    font-size: 9px;
  }
`;

export const SFiltersWrapper = styled.div`
  display: flex;
  column-gap: 24px;
  margin-top: 16px;
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .transaction-search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
