import React, { useState } from 'react';
import { ModalBody } from 'react-bootstrap';
import axios from 'axios';
import './adddriver.css';
import { useFormik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import * as Yup from 'yup';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import authHeader from 'services/auth-header';

const AddDriver = ({ handleClose, id, setShowMessage, equipmentID, init }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { use } = useTheme();
  const [loading, setLoading] = useState(false);

  const validationSchema1 = Yup.object().shape({
    equipment_id: Yup.string().required('Required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      equipment_id: equipmentID,
    },
    validationSchema: validationSchema1,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .put(`${API_URL}/equipment/change-equipment-id/${id}`, values, { headers: authHeader() })
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            window.history.replaceState(null, 'New Page Title', `/equipment-profile/vehicle/${values.equipment_id}`);
            init(values.equipment_id);
            handleClose();
            setShowMessage({
              message: 'Equipment ID updated successfully',
              visible: true,
              type: 'success',
            });
          }, 3000);
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <ModalBody className='p-0' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <div className='equipment-list-add-driver'>
        <form onSubmit={formik.handleSubmit}>
          <div className='add-driver-modal-header'>
            <h6 className='add-driver-modal-text mt-3 ms-3' style={{ color: use(palette.dark800, palette.gray200) }}>
              Update Equipment ID
            </h6>
          </div>
          <div className='add-drivers-modal-body' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <p className='drivers-modal-text' style={{ color: use(palette.dark800, palette.gray200) }}>
              {' '}
              Equipment ID*
            </p>
            <input
              type='text'
              name='equipment_id'
              value={formik.values.equipment_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className='InputUpdate'
            />
            {formik.errors.equipment_id && formik.touched.equipment_id ? (
              <div
                className='add-equipment-validation'
                style={{
                  color: '#D12953',
                  fontSize: '12px',
                  marginTop: '12px',
                }}
              >
                {formik.errors.equipment_id}
              </div>
            ) : null}
          </div>
          <div className='add-driver-modal-footer d-flex justify-content-end'>
            <button
              type='button'
              className='add-driver-modal-cancel-button'
              onClick={handleClose}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              Cancel
            </button>
            {loading ? (
              <CircularProgress style={{ height: '18px', width: '18px', margin: '11px 10px' }} />
            ) : (
              <button type='submit' className='add-driver-modal-add-driver-button'>
                Update
              </button>
            )}
          </div>
        </form>
      </div>
    </ModalBody>
  );
};

export default AddDriver;
