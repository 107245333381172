import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { palette } from 'utils/constants';
import { getStopPointType } from 'Api/Planner';
import sm from './addStopPointModal.module.css';

import { Typography } from '../../../Typography';
import HeaderProgress from '../../helpers/HeaderProgres';
import StopPointModalStep1 from './step1';
import StopPointModalStep2 from './step2';
import StopPointModalStep3 from './step3';

export const StopPointModalContext = React.createContext();

const AddStopPointModal = ({ show, onChangeShow, getCustomerStopPoint, containerClassName, wrapperClassName }) => {
  const [step, setStep] = useState(1);
  const [stopPointId, setStopPointId] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const progressData = useMemo(() => {
    return [
      { id: 1, title: 'Basic Details' },
      { id: 2, title: 'Contact Book' },
      { id: 3, title: 'Working Hours' },
    ];
  }, []);

  const getTypeData = () => {
    getStopPointType()
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              labelSelected: null,
            };
          });
          updateTypeData(selectData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const updateCityData = (data) => {
    setCityData(data);
  };

  const updateStateData = (data) => {
    setStateData(data);
  };

  const updateTypeData = (data) => {
    setTypeData(data);
  };

  const updateStopPointId = (v) => {
    setStopPointId(v);
  };

  const validateEmail = (value) => {
    let error;
    const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!isValidEmail.test(value) && value) {
      error = 'Invalid email address';
    }
    return error;
  };

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const currentStep = useMemo(() => {
    switch (step) {
      case 1:
        return <StopPointModalStep1 />;
      case 2:
        return <StopPointModalStep2 />;
      case 3:
        return <StopPointModalStep3 />;
      default:
    }
  }, [step]);

  return (
    <StopPointModalContext.Provider
      value={{
        step,
        setStep: (v) => setStep(v),
        onChangeShow,
        cityData,
        stateData,
        updateCityData,
        updateStateData,
        validateEmail,
        validateStopPointInput,
        stopPointId,
        getTypeData,
        typeData,
        updateTypeData,
        updateStopPointId,
        getCustomerStopPoint,
      }}
    >
      {show && (
        <div className={`${sm.container_wrapper} ${wrapperClassName || ''}`}>
          <div className={classNames([sm.container, containerClassName])} style={{ backgroundColor: palette.gray0 }}>
            <div className={sm.header} style={{ backgroundColor: palette.white }}>
              <Typography variant='h5'>Add Stop Point</Typography>
              <HeaderProgress step={step} progressData={progressData} />
            </div>
            <div className={sm.body}>{currentStep}</div>
          </div>
        </div>
      )}
    </StopPointModalContext.Provider>
  );
};

export default AddStopPointModal;
