export const timeOptions = [
  { value: 'minute', name: 'minute(s)' },
  { value: 'hour', name: 'hour(s)' },
  { value: 'day', name: 'day(s)' },
];

export const autoAwardTimeOptions = [
  { value: 'minute', name: 'minute(s)' },
  { value: 'hour', name: 'hour(s)' },
  { value: 'day', name: 'day(s)' },
  { value: null, name: 'Disabled' },
];

export const getInitialValues = (data) => {
  const {
    expire_shipment_offer,
    expire_shipment_offer_unit,
    auto_repost,
    retract_carrier_award,
    retract_carrier_award_unit,
    auto_award_first_lowest_amount,
    auto_award_first_lowest_amount_unit,
    require_minimum_margin,
    require_minimum_margin_percent,
    require_handling,
    auto_dispatch,
    offer_pending_carrier,
    enable_audio_accepted_award,
    enable_audio_bid,
  } = data || {};

  return {
    expire_shipment_offer: expire_shipment_offer || '1',
    expire_shipment_offer_unit: timeOptions.find((i) => i.value === expire_shipment_offer_unit) || timeOptions[2],
    auto_repost: !!auto_repost,
    retract_carrier_award: retract_carrier_award || '30',
    retract_carrier_award_unit: timeOptions.find((i) => i.value === retract_carrier_award_unit) || timeOptions[0],
    auto_award_first_lowest_amount: auto_award_first_lowest_amount || '',
    auto_award_first_lowest_amount_unit:
      autoAwardTimeOptions.find((i) => i.value === auto_award_first_lowest_amount_unit) || autoAwardTimeOptions[3],
    auto_dispatch: !!auto_dispatch,
    require_minimum_margin: !auto_award_first_lowest_amount_unit ? false : !!require_minimum_margin,
    require_minimum_margin_percent: require_minimum_margin_percent || '',
    require_handling: !!require_handling,
    offer_pending_carrier: !!offer_pending_carrier,
    enable_audio_accepted_award: !!enable_audio_accepted_award,
    enable_audio_bid: !!enable_audio_bid,
  };
};
