import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { palette } from 'utils/constants';
import MuiModal from '@mui/material/Modal';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import Modal from 'common/Modal';
import Radio from 'common/Radio';
import DatePicker from 'common/DatePicker';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { InputWithIcon, InputWithText, Textarea } from 'common/Input';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';

import AddVendor from 'pages/Accounting/Vendors/components/AddVendor';
import { ManageType } from 'Api/Equipment';
import { getVendorsList } from 'Api/Vendors';
import { getAllTrailers, getAllVehicles } from 'Api/Map';
import { scheduleMaintenance, updateScheduledMaintenance } from 'Api/EquipmentProfile';
import ManageTypes from '../ManageTypes/ManageTypes';
import { dateIntervalTypeOptions, getInitialValues } from './ScheduleMaintenance.data';
import { validationSchema } from './validationSchema';

const ScheduleMaintenance = ({ open, onClose, equipment, equipmentType, maintenance, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [openAddVendor, setOpenAddVendor] = useState(false);
  const [openManageTypes, setOpenManageTypes] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [trailers, setTrailers] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        equipment_id: values.equipment?.id,
        record_type_id: values.record_type_id?.id || '',
        description: values.description || '',
        record_serviced_id: values.record_serviced_id?.id || '',
        cost: values.cost || '',
        odometer_interval_mode: Number(values.interval_type === 'odometer'),
        odometer_type: values.odometer_type || '',
        odometer_interval: Number(values.odometer_interval) || 0,
        date_interval_mode: Number(values.interval_type === 'date'),
        date_interval_type: values.date_interval_type?.id || 1,
        date_interval: Number(values.date_interval) || 0,
        one_time: Number(values.interval_type === 'one_time'),
        one_time_datetime: values.one_time_datetime
          ? moment(values.one_time_datetime).format('YYYY-MM-DD HH:mm')
          : undefined,
      };

      if (maintenance) {
        await updateScheduledMaintenance(maintenance.id, body);
      } else {
        await scheduleMaintenance(body);
      }

      showToaster({
        type: 'success',
        message: maintenance ? 'Record Updated Successfully' : 'Maintenance scheduled successfully',
      });
      onSuccess(Date.now());
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(maintenance, equipment),
    onSubmit,
    validationSchema,
  });

  const getTypeOptions = async () => {
    try {
      const { data } = await ManageType();
      setTypeOptions(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getVehicles = async () => {
    try {
      const { data } = await getAllVehicles();
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getTrailers = async () => {
    try {
      const { data } = await getAllTrailers();
      setTrailers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getTypeOptions();
    getVendors();

    if (!equipment) {
      getVehicles();
      getTrailers();
    }
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={maintenance ? 'Update Scheduled Service' : 'Schedule Service'}
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: maintenance ? 'Update' : 'Schedule',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-3'>
        {!equipment && (
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Vehicle
            </Typography>
            <CustomCheckbox
              type='switch'
              name='isTrailer'
              checked={values.isTrailer}
              onChange={(checked) => {
                handleChange('isTrailer', checked);
                handleChange('equipment', null);
              }}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Trailer
            </Typography>
          </div>
        )}
        <div>
          <Autocomplete
            required
            label={equipment ? '' : values.isTrailer ? 'Trailer' : 'Vehicle'}
            name='equipment'
            value={values.equipment}
            onChange={(e, value) => handleChange('equipment', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={values.isTrailer ? trailers : vehicles}
            placeholder={values.isTrailer ? 'Select Trailer..' : 'Select Vehicle..'}
            getOptionLabel={(option) => {
              return option ? `${option.equipment_id} (${option.make} ${option.model_id})` : '';
            }}
            error={touchedErrors.equipment}
            disabled={equipment}
          />
        </div>
        <div>
          <div className='d-flex justify-content-between'>
            <InputLabel required>Type</InputLabel>
            <Typography
              variant='s2'
              onClick={() => setOpenManageTypes(true)}
              style={{ cursor: 'pointer', color: palette.indigo500 }}
            >
              Manage Types
            </Typography>
          </div>
          <Autocomplete
            required
            labelKey='type'
            name='record_type_id'
            value={values.record_type_id}
            onChange={(e, value) => handleChange('record_type_id', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={typeOptions}
            placeholder='Select Type..'
            error={touchedErrors.record_type_id}
          />
        </div>
        <Textarea
          rows={3}
          label='Description'
          name='description'
          value={values.description}
          onChange={handleChange}
          error={touchedErrors.description}
        />
        <div>
          <div className='d-flex justify-content-between'>
            <InputLabel>Where will this equipment be serviced?</InputLabel>
            <Typography
              variant='s2'
              onClick={() => setOpenAddVendor(true)}
              style={{ cursor: 'pointer', color: palette.indigo500 }}
            >
              + Add Vendor
            </Typography>
          </div>
          <Autocomplete
            name='record_serviced_id'
            value={values.record_serviced_id}
            onChange={(e, value) => handleChange('record_serviced_id', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={vendors}
            error={touchedErrors.record_serviced_id}
          />
        </div>
        <InputWithIcon
          type='number'
          name='cost'
          className='w-100'
          onChange={handleChange}
          value={values.cost}
          label='Estimated Cost'
          placeholder='0.00'
          error={touchedErrors.cost}
          onKeyDown={blockNonPositiveNumbers}
        />
        <div>
          <InputLabel>Interval</InputLabel>
          <div className='d-flex flex-column gap-2'>
            <Radio
              name='interval_type'
              value='date'
              checked={values.interval_type === 'date'}
              onChange={() => handleChange('interval_type', 'date')}
              label='By Date'
            />
            {values.interval_type === 'date' && (
              <div className='mb-2'>
                <div className='d-flex ms-2'>
                  <ArrowIcon className='mt-2' />
                  <InputWithText
                    left
                    width='100px'
                    text='Every'
                    type='number'
                    name='date_interval'
                    onChange={handleChange}
                    value={values.date_interval}
                    placeholder='0'
                    style={{ borderRadius: 0 }}
                    wrapperClassName='ms-2'
                    onKeyDown={(e) => {
                      blockNotNumberChars(e);
                      blockNonPositiveNumbers(e);
                    }}
                  />
                  <Autocomplete
                    required
                    labelKey='type'
                    name='date_interval_type'
                    value={values.date_interval_type}
                    onChange={(e, value) => handleChange('date_interval_type', value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={dateIntervalTypeOptions}
                    width='88px'
                    height='33px'
                    borderRadius='0 4px 4px 0'
                  />
                </div>
                <ErrorMessage className='ms-4' error={touchedErrors.date_interval} />
              </div>
            )}
            {equipment?.location_target === 'Equipment' && equipmentType !== 'trailer' && (
              <>
                <Radio
                  name='interval_type'
                  value='odometer'
                  checked={values.interval_type === 'odometer'}
                  onChange={() => handleChange('interval_type', 'odometer')}
                  label='By Odometer'
                />
                {values.interval_type === 'odometer' && (
                  <div>
                    <div className='d-flex ms-2'>
                      <ArrowIcon className='mt-2' />
                      <InputWithText
                        left
                        type='number'
                        width='60px'
                        text='Every'
                        name='odometer_interval'
                        onChange={handleChange}
                        value={values.odometer_interval}
                        placeholder='0'
                        style={{ borderRadius: 0 }}
                        wrapperClassName='ms-2'
                        onKeyDown={(e) => {
                          blockNotNumberChars(e);
                          blockNonPositiveNumbers(e);
                        }}
                      />
                      <Autocomplete
                        required
                        name='odometer_interval_type'
                        value={values.odometer_type}
                        onChange={(e, value) => handleChange('odometer_interval_type', value)}
                        options={['KM', 'Miles']}
                        width='88px'
                        height='33px'
                        borderRadius='0 4px 4px 0'
                      />
                    </div>
                    <ErrorMessage className='ms-4' error={touchedErrors.odometer_interval} />
                  </div>
                )}
              </>
            )}
            <Radio
              name='interval_type'
              value='one_time'
              checked={values.interval_type === 'one_time'}
              onChange={() => handleChange('interval_type', 'one_time')}
              label='One Time'
            />
            {values.interval_type === 'one_time' && (
              <div className='mb-2'>
                <div className='d-flex ms-2'>
                  <ArrowIcon className='mt-2 me-2' />
                  <DatePicker
                    width='200px'
                    name='one_time_datetime'
                    value={values.one_time_datetime}
                    dateFormat='MM/dd/yyyy h:mm aa'
                    showTimeInput
                    minDate={new Date()}
                    onChange={(val) => {
                      handleChange('one_time_datetime', val);
                    }}
                  />
                </div>
                <ErrorMessage className='ms-4' error={touchedErrors.one_time_datetime} />
              </div>
            )}
          </div>
        </div>
      </div>
      {openAddVendor && (
        <AddVendor open={openAddVendor} onClose={() => setOpenAddVendor(false)} onSuccess={getVendors} />
      )}
      <MuiModal
        open={openManageTypes}
        onClose={() => setOpenManageTypes(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ManageTypes
          handleManageTypesClose={() => setOpenManageTypes(false)}
          manageTypes={typeOptions}
          getManageType={getTypeOptions}
        />
      </MuiModal>
    </Modal>
  );
};

export default ScheduleMaintenance;
