import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as CheckIcon } from 'assets/icons/checkBoxDark.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Input, { InputWithIcon } from 'common/Input';
import useForm from 'hooks/useForm';
import Autocomplete from 'common/Autocomplete';
import { numberWithCommas, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { SCustomModal } from 'pages/Payroll/Settlement/Settlements.styles';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import InputLabel from 'common/InputLabel';
import { getCustomerPaymentTerms } from 'Api/StopPoint';
import { createStandaloneInvoices } from 'Api/AccountingReceivables';
import { deleteBillDetention, updateBillDetention } from 'Api/DetentionShipment';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import DatePicker from 'common/DatePicker';
import ErrorMessage from 'common/ErrorMessage';
import CustomButton from 'components/CustomButton/CustomButton';
import { updateDetentionDate } from 'Api/Equipment';
import { customerTimeToUtc } from 'utils/helpers';
import { billDetentionConverter } from './converters';
import { getInitialValues, rateParams } from './BillDetention.data';
import { SInputWrapper } from './BillDetention.styles';

const BillDetention = ({ open, onClose, onSuccess, data }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const isTrailer = data?.equipment?.equipment_type?.vehicle_type_id === 2;
  const {
    duration_days,
    duration_hours,
    duration_minutes,
    arrived_date,
    departed_date,
    shipment_stop,
    shipment_billing,
    id,
    billed_at,
    rate,
    bill_rate_type_id,
    duration,
    total_sum,
    charge,
  } = data || {};

  const { values, handleChange, touchedErrors, setFieldValue, setValues } = useForm({
    initialValues: getInitialValues(data, convertToCustomerTime),
  });

  const getPaymentTerms = async () => {
    if (billed_at) {
      setValues({
        ...values,
        rate: rate.toString(),
        bill_rate_type_id: bill_rate_type_id === 1 ? rateParams[0] : rateParams[1],
        duration: Number(duration).toFixed(2).toString(),
        total_sum: total_sum.toString(),
        add_charge: !!charge,
      });
    }

    try {
      const customer_id = data?.shipment_billing?.billing_customer?.id;
      const { data: paymentTermsData } = await getCustomerPaymentTerms({ customer_id });
      setPaymentTerms(paymentTermsData);
      const rate = isTrailer ? paymentTermsData.detention_trailer_dropped_billed_at : paymentTermsData.billed_at;
      const daysHours = isTrailer ? paymentTermsData.detention_trailer_dropped_start_after_per : 'HOURS';
      const duration =
        daysHours === 'HOURS'
          ? (duration_days * 24 + duration_hours + duration_minutes / 60).toFixed(2)
          : (duration_days + duration_hours / 24 + duration_minutes / 1440).toFixed(2);
      if (billed_at) {
        return;
      }
      setValues({
        ...values,
        rate,
        bill_rate_type_id: daysHours === 'DAYS' ? rateParams[1] : rateParams[0],
        duration,
        total_sum: duration * rate,
      });
    } catch (e) {
      // Do nothing
    }
  };

  const updateDate = async () => {
    try {
      if (moment(values.departed_date).isSameOrBefore(values.arrived_date)) {
        showToaster({ type: 'error', message: 'End date cannot be before start date!' });
        return;
      }

      setLoadingDate(true);
      const body = {
        arrived_date: customerTimeToUtc(
          moment(values.arrived_date).format('YYYY-MM-DD HH:mm:ss'),
          'YYYY-MM-DD HH:mm:ss'
        ),
        departed_date: customerTimeToUtc(
          moment(values.departed_date).format('YYYY-MM-DD HH:mm:ss'),
          'YYYY-MM-DD HH:mm:ss'
        ),
      };

      const { data: updatedData } = await updateDetentionDate(data.id, body);
      const { duration_days, duration_hours, duration_minutes } = updatedData || data || {};
      const rate = isTrailer ? paymentTerms.detention_trailer_dropped_billed_at : paymentTerms.billed_at;
      const daysHours = isTrailer ? paymentTerms.detention_trailer_dropped_start_after_per : 'HOURS';
      const duration =
        daysHours === 'HOURS'
          ? (duration_days * 24 + duration_hours + duration_minutes / 60).toFixed(2)
          : (duration_days + duration_hours / 24 + duration_minutes / 1440).toFixed(2);

      setValues((prevState) => ({
        ...prevState,
        duration,
        total_sum: duration * rate,
      }));
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingDate(false);
    }
  };

  const billDetention = async () => {
    setLoading(true);
    try {
      if (!values.add_charge) {
        const formData = new FormData();

        formData.append('bill_date', moment(Date.now()).format('YYYY-MM-DD'));
        formData.append(
          'due_date',
          moment(Date.now())
            .add(paymentTerms.pay_term_types?.net_day || 30, 'days')
            .format('YYYY-MM-DD')
        );
        formData.append('is_auto_debt_enabled', '0');
        formData.append('customer_customer_id', data.shipment_billing?.customer_id);
        formData.append('reference_id', shipment_billing?.reference_id);
        formData.append('terms', paymentTerms.id || 1);

        formData.append(`items[0][item_id]`, 6);
        formData.append(
          `items[0][desc]`,
          `Detention (${shipment_stop?.stop_point?.location_name}) - ${convertToCustomerTime(
            arrived_date,
            null,
            true
          )} to ${convertToCustomerTime(departed_date, null, true)}`
        );
        formData.append(`items[0][quantity]`, Number(values.duration));
        formData.append(`items[0][price]`, Number(values.rate));
        formData.append(`items[0][is_taxable]`, '0');
        formData.append(`items[0][total_amount]`, Number(values.total_sum));

        formData.append('is_recurring', '0');

        formData.append(
          'note',
          `Detention itemized and billed separately for Shipment ${shipment_billing?.shipment_id} / Reference ${shipment_billing?.reference_id}`
        );
        formData.append('is_draft', '0');

        await createStandaloneInvoices(formData);
      }

      const body = billDetentionConverter(values);
      await updateBillDetention({ id, body });
      showToaster({ type: 'success', message: 'Detention has been successfully billed!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const removeBill = async () => {
    setLoading(true);
    try {
      await deleteBillDetention(id);
      showToaster({ type: 'success', message: 'Detention bill has been successfully reverted!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const onRateTypeChange = (e, val) => {
    setFieldValue('bill_rate_type_id', val);
  };

  const handleRateChange = (e) => {
    const { value } = e.target;

    handleChange('rate', value);
    handleChange('total_sum', (values.duration * value).toFixed(2));
  };

  const handleDurationChange = (e) => {
    const { value } = e.target;

    handleChange('duration', value);
    handleChange('total_sum', values.rate * value);
  };

  useEffect(() => {
    getPaymentTerms();
  }, [open]);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle={data?.billed_at ? 'Revert Bill' : 'Bill Detention'}
      $maxWidth='460px'
      $minWidth='460px'
      $bgColor={palette.gray0}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', className: 'modal-cancel-btn' },
        {
          key: 'submit',
          type: 'primary',
          title: billed_at ? 'Revert Bill' : 'Bill',
          className: 'modal-submit-btn',
          onClick: billed_at ? removeBill : billDetention,
          disabled: loading,
        },
      ]}
    >
      <form>
        <SInputWrapper>
          <InputWithIcon
            name='rate'
            onChange={handleRateChange}
            value={values.rate}
            error={touchedErrors.rate}
            label='Bill Rate'
            wrapperStyle={{ borderRadius: '6px 0 0 6px' }}
            required
            className='w-100'
            disabled={billed_at}
          />
          <Autocomplete
            width='120px'
            options={rateParams}
            value={values.bill_rate_type_id}
            onChange={onRateTypeChange}
            disabled
          />
        </SInputWrapper>
        <Typography variant='s2' as='p' style={{ color: use(palette.gray700, palette.gray50), marginBottom: '14px' }}>
          Detention starts after{' '}
          {isTrailer ? paymentTerms?.detention_trailer_dropped_starts_after : paymentTerms?.detention_starts_after_hour}{' '}
          {isTrailer && paymentTerms?.detention_trailer_dropped_start_after_per === 'DAYS' ? 'day(s)' : 'hour(s)'}
        </Typography>
        <div className='d-flex gap-2 mb-4'>
          <div className='d-flex flex-column w-100'>
            <InputLabel required>Start</InputLabel>
            <div className='d-flex flex-column'>
              <DatePicker
                showTimeInput
                shouldCloseOnSelect={false}
                placeholderText='Select Date'
                dateFormat='MM/dd/yyyy h:mm aa'
                timeInputLabel='Time:'
                name='arrived_date'
                value={values.arrived_date}
                onChange={(val) => handleChange('arrived_date', val)}
                renderStart={false}
              />
              <ErrorMessage error={touchedErrors.arrived_date} />
            </div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <ArrowRight fill={palette.indigo500} />
          </div>
          <div className='d-flex flex-column w-100'>
            <InputLabel required>End</InputLabel>
            <div className='d-flex flex-column'>
              <DatePicker
                showTimeInput
                shouldCloseOnSelect={false}
                placeholderText='Select Date'
                dateFormat='MM/dd/yyyy h:mm aa'
                timeInputLabel='Time:'
                name='departed_date'
                value={values.departed_date}
                onChange={(val) => handleChange('departed_date', val)}
                renderStart={false}
              />
              <ErrorMessage error={touchedErrors.departed_date} />
            </div>
          </div>
          <div style={{ marginTop: '29px' }}>
            <CustomButton
              type='primary'
              title=''
              leftIcon={<CheckIcon fill={palette.white} />}
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '9px 12px', margin: 0 }}
              onClick={updateDate}
              disabled={loadingDate}
            />
          </div>
        </div>
        <SInputWrapper>
          <Input
            type='number'
            name='duration'
            onChange={handleDurationChange}
            value={values.duration}
            error={touchedErrors.duration}
            label='Detention Duration'
            required
            style={{ borderRadius: '6px 0 0 6px' }}
            className='w-100'
            disabled={billed_at}
          />
          <Autocomplete
            width='120px'
            options={rateParams}
            value={values.bill_rate_type_id}
            onChange={onRateTypeChange}
            disabled
          />
        </SInputWrapper>
        <div style={{ marginBottom: '24px' }}>
          <InputLabel>Total</InputLabel>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
            ${numberWithCommas(Number(values.total_sum).toFixed(2) || '0.00')}
          </Typography>
        </div>
        {!data.shipment_billing?.customer_billed && !data?.billed_at && (
          <div className='d-flex align-items-center gap-2'>
            <CustomRadioButton
              field={{
                name: 'add_charge',
                value: 1,
                onChange: () => handleChange('add_charge', 1),
                checked: values.add_charge === 1,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                Add Charges to Shipment
              </Typography>
            </CustomRadioButton>
          </div>
        )}
        {!data?.billed_at && (
          <div className='d-flex align-items-center gap-2'>
            <CustomRadioButton
              field={{
                name: 'add_charge',
                value: 0,
                onChange: () => handleChange('add_charge', 0),
                checked: values.add_charge === 0,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                Create Separate Invoice
              </Typography>
            </CustomRadioButton>
          </div>
        )}
      </form>
    </SCustomModal>
  );
};

export default BillDetention;
