import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Tooltip from 'common/Tooltip';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { absenceBasedOnOptions, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { GetInsurance } from 'Api/Insurance';
import { saveFinishLater } from 'Api/StaffV2';
import { getStaffAbsence } from 'Api/CompanySettings';
import { createStaffPolicies, uploadDriverDocument } from 'Api/Staff';
import { PolicyValidationSchema } from '../ValidationSchemaStaff';
import { SMergedInputs } from './AddStaff.styles';

const Policies = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  stepsDone,
  setStepsDone,
  staffId,
  policy = {},
  draftDriver,
  getStaff,
}) => {
  const { use } = useTheme();
  const [onNextLoading, setOnNextLoading] = useState(false);
  const [absencePolicySettings, setAbsencePolicySettings] = useState(null);
  const [insuranceSettings, setInsuranceSettings] = useState(null);
  const {
    staff_id,
    excused_absences,
    unexcused_absences,
    vacations_allowed,
    absencePolicy,
    use_absence_policy,
    absence_reset_based_on,
    compensation_policy_id,
    compensation_policy_expiry_date,
    compensation_policy_attachment,
    use_default_worker_compensation,
  } = policy || {};

  const onNextStep = (values) => {
    setOnNextLoading(true);
    createStaffPolicies({
      ...values,
      use_default_worker_compensation: Number(values.use_default_worker_compensation),
      absence_reset_based_on: values.absence_reset_based_on?.id,
      use_absence_policy: Number(values.use_absence_policy),
      compensation_policy_expiry_date: values.compensation_policy_expiry_date
        ? moment(values.compensation_policy_expiry_date).format('YYYY-MM-DD')
        : undefined,
    })
      .then(() => {
        setOnNextLoading(false);
        nextStep(values, `Policies has been ${stepsDone ? 'updated' : 'created'}`);
      })
      .catch((error) => {
        setOnNextLoading(false);
        showError(getErrorMessage(error));
      });
  };

  const formik = useFormik({
    initialValues: {
      staff_id: staff_id || staffId,
      excused_absences: excused_absences || 0,
      unexcused_absences: unexcused_absences || 0,
      vacations_allowed: vacations_allowed || 0,
      absencePolicy: absencePolicy || 'Company',
      use_absence_policy: use_absence_policy || false,
      absence_reset_based_on: absence_reset_based_on || null,
      use_default_worker_compensation: use_default_worker_compensation || false,
      compensation_policy_id: compensation_policy_id || '',
      compensation_policy_expiry_date: compensation_policy_expiry_date
        ? moment(compensation_policy_expiry_date).toDate()
        : null,
      compensation_policy_attachment: compensation_policy_attachment || '',
    },
    onSubmit: onNextStep,
    validationSchema: PolicyValidationSchema,
  });

  const getInsuranceSettings = async () => {
    try {
      const { data } = await GetInsurance();
      setInsuranceSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getAbsenceSettings = async () => {
    try {
      const { data } = await getStaffAbsence();
      setAbsencePolicySettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const uploadDocument = (file) => {
    uploadDriverDocument(file, staffId).then((res) => {
      formik.setFieldValue('compensation_policy_attachment', res.data.full_path);
    });
  };

  useEffect(() => {
    if (!stepsDone) {
      formik.setFieldValue('use_absence_policy', false);
    }

    getInsuranceSettings();
    getAbsenceSettings();
  }, []);

  const onPrevStep = () => {
    setStepsDone((prevState) => ({
      ...prevState,
      isCompensation: true,
    }));
    prevStep();
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 5,
    };

    saveFinishLater(staffId, body).then(() => {
      getStaff();
      Cancel();
    });
  };

  const onApplyInsuranceFromSettings = (checked) => {
    if (checked && insuranceSettings?.workers_compensation_and_employers_liability_policy_active) {
      formik.setValues((prevState) => ({
        ...prevState,
        compensation_policy_id: insuranceSettings.workers_compensation_and_employers_liability_policy_id,
        compensation_policy_expiry_date: insuranceSettings.workers_compensation_and_employers_liability_expire_date
          ? moment(insuranceSettings.workers_compensation_and_employers_liability_expire_date).toDate()
          : null,
        compensation_policy_attachment:
          insuranceSettings.workers_compensation_and_employers_liability_policy_attach || '',
        use_default_worker_compensation: checked,
      }));
    } else {
      formik.setValues((prevState) => ({
        ...prevState,
        compensation_policy_id,
        compensation_policy_expiry_date,
        compensation_policy_attachment,
        use_default_worker_compensation: checked,
      }));
    }
  };

  const onApplyAbsenceFromSettings = (checked) => {
    if (checked && absencePolicySettings) {
      formik.setValues((prevState) => ({
        ...prevState,
        excused_absences: absencePolicySettings.excused_absences,
        unexcused_absences: absencePolicySettings.unexcused_absences,
        vacations_allowed: absencePolicySettings.vacations_allowed,
        absence_reset_based_on:
          absenceBasedOnOptions.find((i) => i.id === absencePolicySettings.reset_based_on) || null,
        use_absence_policy: checked,
      }));
    } else {
      formik.setValues((prevState) => ({
        ...prevState,
        excused_absences: excused_absences || '',
        unexcused_absences: unexcused_absences || '',
        vacations_allowed: vacations_allowed || '',
        absence_reset_based_on: absence_reset_based_on || null,
        use_absence_policy: checked,
      }));
    }
  };

  return (
    <>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        <div className='mt-4'>
          <div className='d-flex align-items-center gap-4 mb-3'>
            <Typography className='s1' style={{ color: palette.gray700 }}>
              Attendance Policy
            </Typography>
            <CustomCheckbox
              checked={formik.values.use_absence_policy}
              onChange={(checked) => onApplyAbsenceFromSettings(checked)}
              disabled={!absencePolicySettings}
            >
              <Typography variant='c1' style={{ marginLeft: '8px' }}>
                Apply from Company Policy - Attendance
              </Typography>
              {!absencePolicySettings && (
                <Tooltip title='Please, provide absence policy info in company settings to enable this option!'>
                  <InfoIcon width={14} height={14} className='ms-2' />
                </Tooltip>
              )}
            </CustomCheckbox>
          </div>
          <div className='d-flex gap-4'>
            <div className='w-25'>
              <InputWithIcon
                left
                required
                type='number'
                icon='#'
                label='Allowed Excused Attendance'
                name='excused_absences'
                value={formik.values.excused_absences}
                onChange={(e) => formik.setFieldValue('excused_absences', e.target.value)}
                error={formik.touched?.excused_absences && formik.errors.excused_absences}
              />
            </div>
            <div className='w-25'>
              <InputWithIcon
                left
                required
                type='number'
                icon='#'
                label='Allowed Vacations'
                name='unexcused_absences'
                value={formik.values.unexcused_absences}
                onChange={(e) => formik.setFieldValue('unexcused_absences', e.target.value)}
                error={formik.touched?.unexcused_absences && formik.errors.unexcused_absences}
              />
            </div>
            <div className='w-25'>
              <InputWithIcon
                left
                required
                type='number'
                icon='#'
                label='Allowed Excused Attendance'
                name='vacations_allowed'
                value={formik.values.vacations_allowed}
                onChange={(e) => formik.setFieldValue('vacations_allowed', e.target.value)}
                error={formik.touched?.vacations_allowed && formik.errors.vacations_allowed}
              />
            </div>
            <div className='w-25'>
              <Autocomplete
                required
                label='Reset Based On'
                name='absence_reset_based_on'
                placeholder='Based on..'
                options={absenceBasedOnOptions}
                value={formik.values.absence_reset_based_on}
                onChange={(e, value) => formik.setFieldValue('absence_reset_based_on', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={formik.touched?.absence_reset_based_on && formik.errors.absence_reset_based_on}
              />
            </div>
          </div>
          <div className='mt-5'>
            <div className='d-flex align-items-center gap-4 mb-3'>
              <Typography className='s1' style={{ color: palette.gray700 }}>
                Workers Compensation and Employers Liability
              </Typography>
              <CustomCheckbox
                checked={formik.values.use_default_worker_compensation}
                onChange={(checked) => onApplyInsuranceFromSettings(checked)}
                disabled={!insuranceSettings?.workers_compensation_and_employers_liability_policy_active}
              >
                <Typography variant='c1' style={{ marginLeft: '8px' }}>
                  Apply from Company Policy - Workers Compensation
                </Typography>
                {!insuranceSettings?.workers_compensation_and_employers_liability_policy_active && (
                  <Tooltip title='Please, enable and provide insurance policy info in company settings to enable this option!'>
                    <InfoIcon width={14} height={14} className='ms-2' />
                  </Tooltip>
                )}
              </CustomCheckbox>
            </div>
            <div className='d-flex align-items-center gap-3'>
              <SMergedInputs>
                <Input
                  width='230px'
                  label='Policy ID'
                  name='compensation_policy_id'
                  placeholder='Policy ID'
                  onChange={(e) => formik.setFieldValue('compensation_policy_id', e.target.value)}
                  value={formik.values.compensation_policy_id}
                />
                <div>
                  <InputLabel>Expiration</InputLabel>
                  <DatePicker
                    width='150px'
                    borderRadius='0 6px 6px 0'
                    onBlur={formik.handleBlur}
                    name='compensation_policy_expiry_date'
                    value={formik.values.compensation_policy_expiry_date}
                    onChange={(val) => formik.setFieldValue('compensation_policy_expiry_date', val)}
                    disabled={formik.values.use_default_worker_compensation}
                    error={
                      formik.touched?.compensation_policy_expiry_date && formik.errors?.compensation_policy_expiry_date
                    }
                  />
                </div>
              </SMergedInputs>
              <div className='mt-4'>
                <Uploader
                  type={3}
                  document={formik.values.compensation_policy_attachment}
                  onDrop={(files) => uploadDocument(files[0])}
                  onRemove={() => formik.setFieldValue('compensation_policy_attachment', null)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            <Button className='cancel-button ms-0' onClick={nextStep}>
              Skip
            </Button>
            {onNextLoading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                type='button'
                onClick={formik.handleSubmit}
                className='next-step'
                disabled={!formik.values.use_absence_policy && !formik.isValid}
              >
                Next Step
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default Policies;
