import axios from 'axios';
import axiosConfigured from 'services/axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function Drivers(filter) {
  const params = {
    ...filter,
    'sort[][drivers.fname]': 'asc',
  };
  const { data } = await axios.get(`${API_URL}/drivers/list`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function getDrivers(params) {
  const { data } = await axiosConfigured.get('/drivers/list', { params });
  return data;
}

export async function DriverGeneral({ id }) {
  const url = `${API_URL}/driver/general/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverCompansationData({ id }) {
  const url = `${API_URL}/driver/compensation/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverConnectedService({ driver_id }) {
  const url = `${API_URL}/customer/get-driver-connected-service/${driver_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverDocumentData({ driver_id, page, itemsPerPage, sortField, sortBy }) {
  const url = `${API_URL}/driver/documents/${driver_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      itemsPerPage,
      [sortField]: sortBy,
      driver_id,
    },
  });
  return data;
}

export async function DriverAddDocumentData(addDocumentsData) {
  const url = `${API_URL}/driver/document`;
  const { data } = await axios.post(
    url,
    {
      ...addDocumentsData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function DriverUpdateDocumentData(updateDocumentsData, driverId) {
  const url = `${API_URL}/driver/update-document/${driverId}`;
  const { data } = await axios.put(
    url,
    {
      ...updateDocumentsData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function DriverRemoveDocumentData(driverId, body) {
  const url = `${API_URL}/driver/remove-document/${driverId}`;
  const { data } = await axios.put(url, body, { headers: { ...authHeader() } });
  return data;
}

export async function driverMedicalData({ id }) {
  const url = `${API_URL}/driver/medical-background/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function hourOfService({ id }) {
  const url = `${API_URL}/driver/hours-of-service/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function hourOfServiceAll() {
  const url = `${API_URL}/driver/hours-of-service`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function DepartmentsEmail() {
  const url = `${API_URL}/customer/departments`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverIncidentData({
  page,
  query,
  from_date,
  to_date,
  sortField = 'sort[][id]',
  sortBy = 'desc',
  driver_id,
  ...params
}) {
  const url = `${API_URL}/driver/incidents`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      query,
      from_date,
      to_date,
      [sortField]: sortBy,
      driver_id,
      ...params,
    },
  });
  return data;
}

export async function getPreviousRecordData(token, dot, user_type, attempt_id) {
  const url = `${API_URL}/get-previous-employment-history-record-data`;
  const { data } = await axios.get(url, {
    params: {
      token,
      dot,
      'user-type': user_type,
      attempt_id,
    },
  });
  return data;
}

export async function getValidateData(validatedata) {
  const url = `${API_URL}/driver/import-drivers-validation`;
  const { data } = await axios.post(
    url,
    {
      ...validatedata,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function getValidateDataStaff(validatedata) {
  const url = `${API_URL}/staff/import-staff-validation`;
  const { data } = await axios.post(
    url,
    {
      ...validatedata,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function forwardPrevData(forwardData) {
  const url = `${API_URL}/forward-previous-employment-verification`;
  const { data } = await axios.post(url, {
    ...forwardData,
  });
  return data;
}
export async function convertImage(image, dot) {
  const url = `${API_URL}/convert-image`;
  const { data } = await axios.post(url, {
    image,
    dot,
  });
  return data;
}

export async function PreviousEmolymentVerificationData(prevData) {
  const url = `${API_URL}/previous-employment-verification-response`;
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
    },
    url,
    data: prevData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function submitStaffPreviousEmploymentVerification(prevData) {
  const url = `${API_URL}/staff/previous-employment-verification-response`;
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
    },
    url,
    data: prevData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function submitApplicantPreviousEmploymentVerification(prevData) {
  const url = `${API_URL}/applicant/previous-employment-verification-response`;
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
    },
    url,
    data: prevData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function driverRemoveIncidentData(EmployID) {
  const url = `${API_URL}/previous-employment/driver/${EmployID}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteDriverIncident(incidentId) {
  const url = `${API_URL}/driver/incident/${incidentId}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverRemoveRequestData(requestId) {
  const url = `${API_URL}/driver/request/${requestId}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverRemoveTransactionData(transactionId) {
  const url = `${API_URL}/driver/transaction/${transactionId}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverRemoveAbsenceData(absenceID) {
  const url = `${API_URL}/driver/absence/${absenceID}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverNoteData({ page, sort = 'asc', driver_id, itemsPerPage }) {
  const url = `${API_URL}/notes/driver/${driver_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      driver_id,
      'sort[][id]': sort,
      itemsPerPage,
    },
  });
  return data;
}

export async function driverCreateNotes(notesData) {
  const url = `${API_URL}/notes/driver`;
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url,
    data: notesData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function driverLogData({
  page,
  query,
  sortField = 'sort[][id]',
  sortBy,
  itemsPerPage,
  driver_id,
  from_date,
  to_date,
}) {
  const url = `${API_URL}/driver/change-log`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      query,
      driver_id,
      itemsPerPage,
      [sortField]: sortBy,
      from_date,
      to_date,
    },
  });
  return data;
}

export async function driverAbsenceData({
  page,
  query,
  // start_date,
  // end_date,
  year,
  sort = 'desc',
  driver_id,
  itemsPerPage,
}) {
  const url = `${API_URL}/driver/absences`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      query,
      driver_id,
      itemsPerPage,
      'sort[][id]': sort,
      year,
      // start_date,
      // end_date,
    },
  });
  return data;
}

export async function driverCreateAbsenceData(absenceData) {
  const url = `${API_URL}/driver/absence`;
  const { data } = await axios.post(
    url,
    {
      ...absenceData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function driverUpdateAbsenceData(absenceData, absenceId) {
  const url = `${API_URL}/driver/absence/${absenceId}`;
  const { data } = await axios.put(
    url,
    {
      ...absenceData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function driverRequestData({ page, query, sort = 'desc', itemsPerPage, driver_id, from_date, to_date }) {
  const url = `${API_URL}/driver/requests`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      query,
      driver_id,
      itemsPerPage,
      'sort[][id]': sort,
      from_date,
      to_date,
    },
  });
  return data;
}

export async function driverPreviousEmployment({
  page,
  sortField = 'sort[][id]',
  sortBy = 'desc',
  itemsPerPage,
  driver_id,
}) {
  const url = `${API_URL}/previous-employment/driver/${driver_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      [sortField]: sortBy,
      itemsPerPage,
    },
  });
  return data;
}

export async function driverTimeTrackingData(params) {
  const url = `${API_URL}/driver/time-tracking`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function ViewPreviousEmploy({ id }) {
  const url = `${API_URL}/driver-employment-history/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getStaffPrevEmploymentVerification({ id }) {
  const url = `${API_URL}/staff-employment-history/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getDriverShipments({ id }) {
  const url = `${API_URL}/customer/driver/${id}/shipments?page=1&itemsPerPage=500`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function driverTrackingData(params) {
  const url = `${API_URL}/driver/tracking-history`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function AddAndUpdateBasicDetail(driver) {
  const url = `${API_URL}/driver/save-basic-details`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function importBasicDriverData(driver) {
  const url = `${API_URL}/driver/import-drivers`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function importBasicStaffData(driver) {
  const url = `${API_URL}/staff/import-staff`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddRequestDetail(request) {
  const url = `${API_URL}/driver/request`;
  const { data } = await axios.post(
    url,
    {
      ...request,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddBasicOwnerDetail(driver) {
  const url = `${API_URL}/operator`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateBasicOwnerDetail(driver, operatorId) {
  const url = `${API_URL}/operator/${operatorId}`;
  const { data } = await axios.put(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateStatus(driverStatus, id) {
  const url = `${API_URL}/driver/status/${id}`;
  const { data } = await axios.put(
    url,
    {
      ...driverStatus,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddMedicalBackground(driver) {
  const url = `${API_URL}/driver/save-medical-background`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddUpdatePreviousEmployment(employer, isUpdate) {
  const url = isUpdate
    ? `${API_URL}/update-previous-employment-verification-request`
    : `${API_URL}/send-previous-employment-verification-request`;
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url,
    data: employer,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function AddCompensation(driver) {
  const url = `${API_URL}/driver/save-compensation`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddCompensationOwner(driver) {
  const url = `${API_URL}/operator/compensation`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateCompensationOwner(driver, operatorId) {
  const url = `${API_URL}/operator/compensation/${operatorId}`;
  const { data } = await axios.put(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddAndUpdatePolicies(driver) {
  const url = `${API_URL}/driver/save-policies`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function getRequestType() {
  const url = `${API_URL}/driver/request-types`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getTabs(type) {
  const url = `${API_URL}/tabs`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      type,
    },
  });
  return data;
}

export async function addTabs(tab) {
  const url = `${API_URL}/tabs`;
  const { data } = await axios.post(
    url,
    {
      ...tab,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function updateTabs(tab, tabID) {
  const url = `${API_URL}/tabs/${tabID}`;
  const { data } = await axios.put(
    url,
    {
      ...tab,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function getDriverCompanyPolicy() {
  const url = `${API_URL}/customer/settings/driver-compensation`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getAddition({ id }) {
  const url = `${API_URL}/driver/additions/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getDeduction({ id }) {
  const url = `${API_URL}/driver/deductions/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getDriverBalances(id) {
  const { data } = await axiosConfigured.get(`driver/balances/${id}`);
  return data;
}

export async function deleteDriverBalance(id) {
  const { data } = await axiosConfigured.delete(`driver/balances/${id}`);
  return data;
}

export async function createDriverBalance(body) {
  const { data } = await axiosConfigured.post('driver/balances', body);
  return data;
}

export async function updateBalanceDeduction(id, body) {
  const { data } = await axiosConfigured.put(`driver/balance/${id}`, body);
  return data;
}

export async function getTaxes({ id }) {
  const url = `${API_URL}/driver/taxes/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getWorkingSchedule({ id }) {
  const url = `${API_URL}/driver/work-schedules/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getBenefits({ id }) {
  const url = `${API_URL}/driver/benefits/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function AddCertification(driver) {
  const url = `${API_URL}/driver/save-certification-agreement`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddAdditions(driver, signal) {
  const url = `${API_URL}/driver/additions`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function AddDeductions(driver, signal) {
  const url = `${API_URL}/driver/deductions`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function AddTaxes(driver, signal) {
  const url = `${API_URL}/driver/taxes`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function AddBenefits(driver, signal) {
  const url = `${API_URL}/driver/benefits`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function AddWorkingSchedule(driver) {
  const url = `${API_URL}/driver/work-schedule`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddDocument(driver) {
  const url = `${API_URL}/operator/document`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function OwnerOperator(searchText) {
  const url = `${API_URL}/operators/list`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      query: searchText,
    },
  });
  return data;
}

export async function PositionAndApplication(params) {
  const url = `${API_URL}/job-application`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function checkLicense() {
  const url = `${API_URL}/customer/check-license-users`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function LicenseType(params) {
  const url = `${API_URL}/driver/licence-types`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function DriverGroups(params) {
  const url = `${API_URL}/group`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function searchCountries(searchText) {
  const url = `${API_URL}/search-countries?country_name=${searchText || ''}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function searchStates(searchText, country_id) {
  const url = `${API_URL}/search-states?country_id=${country_id}&state_name=${searchText}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function searchCities(searchText, state_id) {
  const url = `${API_URL}/search-cities?state_id=${state_id}&city_name=${searchText}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getstates(countryId) {
  const url = `${API_URL}/states/country/${countryId}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getCities(countryId) {
  const url = `${API_URL}/cities/state/${countryId}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getStatus({ id }) {
  const url = `${API_URL}/driver/status/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getDriverGroup({ id }) {
  const url = `${API_URL}/group/relation/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getDriverHeaderStats(id, params) {
  const { data } = await axiosConfigured.get(`driver/${id}/stats`, { params });
  return data;
}

export async function getDriverShipmentStats({ id }) {
  const currentMonth = new Date().getMonth() + 1;
  const url = `${API_URL}/driver/${id}/shipment-stats-graph?month_of_year=${currentMonth}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function uploadDriverDocument(file, driverId = null) {
  // const dataUri= await fileToDataUri(event.target.files[0]);
  if (!file) {
    return;
  }
  const formData = new FormData();
  formData.append('driver_id', driverId || '');
  formData.append('source', 'general');
  formData.append('file_upload', file);
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url: `${API_URL}/driver/upload-document`,
    data: formData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function AddGroup({ group_name, color, short_name }) {
  const url = `${API_URL}/group`;
  const { data } = await axios.post(
    url,
    {
      group_name,
      color,
      short_name,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function GetConnectedSeries() {
  const url = `${API_URL}/customer/customer-connected-services?type=driver`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function GetAllConnectedSeries() {
  const url = `${API_URL}/customer/all-connected-services`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getTaxType() {
  const url = `${API_URL}/driver/tax-types`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function activeService({ driver_id, serviceId, provider_id, isAllService = false, fields }) {
  let url = '';
  let payload = {};
  if (!isAllService) {
    payload = {
      driver_id,
      provider_id,
    };
    url = `${API_URL}/connected-services/hours-of-service`;
  } else {
    payload = {
      connected_service_id: serviceId,
      fields,
    };
    url = `${API_URL}/customer/connect-connected-service`;
  }
  const { data } = await axios.post(url, payload, { headers: { ...authHeader() } });
  return data;
}

export async function deactivateService({ driver_id, serviceId }) {
  let url = '';
  if (serviceId === 51) {
    url = `${API_URL}/connected-services/hours-of-service/${driver_id}`;
  }
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deactivateAllService(payload) {
  const url = `${API_URL}/customer/remove-connected-service`;
  const { data } = await axios.post(url, payload, { headers: { ...authHeader() } });
  return data;
}

export async function deleteTab({ tab }) {
  const url = `${API_URL}/tabs/${tab}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteDeduction({ deId }) {
  const url = `${API_URL}/driver/deductions/${deId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteAddition({ addId }) {
  const url = `${API_URL}/driver/additions/${addId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}
export async function deleteTax({ taxId }) {
  const url = `${API_URL}/driver/taxes/${taxId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteSchedule({ workingId }) {
  const url = `${API_URL}/driver/work-schedule/${workingId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteBenefit({ beneId }) {
  const url = `${API_URL}/driver/benefits/${beneId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteNote({ noteId }) {
  const url = `${API_URL}/notes/driver/${noteId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function resetPin(driverId, login_pin) {
  const url = `${API_URL}/driver/reset-pin/${driverId}`;
  const { data } = await axios.put(
    url,
    {
      login_pin,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function updateGeneDriver(updatedDriverInfo, id) {
  const url = `${API_URL}/driver/general/${id}`;
  const { data } = await axios.put(
    url,
    {
      ...updatedDriverInfo,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function driverShipmentData({ page, query, itemsPerPage, driver_id, from_date, to_date, sort }) {
  const url = `${API_URL}/customer/driver/${driver_id}/shipments`;
  const params = {
    page,
    itemsPerPage,
    'filters[all]': query,
    'filters[from]': from_date,
    'filters[to]': to_date,
  };

  if (sort?.field) {
    params[`sort[0][${sort.field}]`] = sort.sortBy;
  }

  const { data } = await axios.get(url, {
    headers: authHeader(),
    params,
  });
  return data;
}
export async function updateAbsenceAllowence(updatedAllowence, id) {
  const url = `${API_URL}/driver/update-absence-allowance/${id}`;
  const { data } = await axios.put(
    url,
    {
      ...updatedAllowence,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}
