import React from 'react';
import { useSelector } from 'react-redux';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';

export const useColumns = ({ sort, sortingQuery }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'invoice_id',
      title: <ColumnHeader title='INVOICE #' field='invoice_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.invoice_id}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'customer',
      title: <ColumnHeader title='CUSTOMER' field='customer' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.customer ? row.customer.company_name : '-'}
        </Typography>
      ),
    },
    {
      field: 'reference_id',
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.reference_id || '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'customer_billed_date',
      title: <ColumnHeader title='SENT DATE' field='customer_billed_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.customer_billed_date ? formatDate(row.customer_billed_date) : '-'}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      title: <ColumnHeader title='DUE DATE' field='due_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.due_date ? formatDate(row.due_date) : '-'}
        </Typography>
      ),
    },
    {
      field: 'total_amount',
      title: <ColumnHeader title='AMOUNT' field='total_amount' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.total_amount ? `${currency} ${formatNumber(row.total_amount)}` : '-'}
        </Typography>
      ),
    },
  ];
};
