import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import { statusColor } from 'utils/constants';
import { getSlaveShipments } from 'Api/Shipment';
import { SContent, SImageContainer, SWrapper } from './DriverPopup.styles';
import { SUnderlineText } from '../../../Receivables.styles';

export const driverStatusIdMapper = {
  1: 'Available',
  2: 'Not Available',
  3: 'On Shipment',
  4: 'Out of Service',
  5: 'Terminated',
  6: 'Quit',
};

const DriversPopup = ({ shipmentId, masterShipmentDriver }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllSlaveShipments = async () => {
    try {
      setLoading(true);
      const { data } = await getSlaveShipments({ shipment_id: shipmentId });
      const allDrivers = data.map((shipment) => {
        return {
          shipment_id: shipment.shipment_id,
          assigned_to_1: shipment.assigned_to_1,
          assigned_to_2: shipment.assigned_to_2,
        };
      });
      setDrivers(allDrivers);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (anchorEl) {
      getAllSlaveShipments();
    }
  }, [anchorEl]);

  return (
    <SWrapper onClick={(e) => e.stopPropagation()}>
      <ThreeDotActions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        className='multiple-drivers'
        actionButton={<SUnderlineText>Multiple Drivers</SUnderlineText>}
      >
        <SContent>
          {loading ? (
            <div className='d-flex justify-content-center'>
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className='drivers-wrapper'>
              {drivers.length ? (
                drivers.map((driver) => {
                  return (
                    <div className='d-flex flex-column'>
                      {driver.assigned_to_1 && (
                        <div className='driver-row'>
                          <SImageContainer>
                            <img
                              className='driver-image'
                              src={driver.assigned_to_1.image ? driver.assigned_to_1.image : userDefault}
                              alt='driver'
                            />
                            <div
                              className='image-indicator'
                              style={{
                                backgroundColor:
                                  statusColor[driverStatusIdMapper[driver.assigned_to_1.status || 6]]?.color,
                              }}
                            />
                          </SImageContainer>
                          <Typography variant='overLine2'>
                            {driver.assigned_to_1.fname || ''} {driver.assigned_to_1.lname || ''} - {driver.shipment_id}
                          </Typography>
                        </div>
                      )}
                      {driver.assigned_to_2 && (
                        <div className='driver-row'>
                          <SImageContainer>
                            <img
                              className='driver-image'
                              src={driver.assigned_to_2.image ? driver.assigned_to_2.image : userDefault}
                              alt='driver'
                            />
                            <div
                              className='image-indicator'
                              style={{
                                backgroundColor:
                                  statusColor[driverStatusIdMapper[driver.assigned_to_2.status || 6]]?.color,
                              }}
                            />
                          </SImageContainer>
                          <Typography variant='overLine2'>
                            {driver.assigned_to_2.fname || ''} {driver.assigned_to_2.lname || ''} - {driver.shipment_id}
                          </Typography>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className='driver-row'>
                  <SImageContainer>
                    <img
                      className='driver-image'
                      src={masterShipmentDriver.image ? masterShipmentDriver.image : userDefault}
                      alt='driver'
                    />
                    <div
                      className='image-indicator'
                      style={{
                        backgroundColor:
                          statusColor[masterShipmentDriver.status_data?.driver_status || 'Logged Off']?.color,
                      }}
                    />
                  </SImageContainer>
                  <Typography variant='overLine2'>
                    {masterShipmentDriver.fname || ''} {masterShipmentDriver.lname || ''} - {shipmentId}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </SContent>
      </ThreeDotActions>
    </SWrapper>
  );
};

export default DriversPopup;
