import React, { useContext, useMemo, useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import uuid from 'react-uuid';
import { RemoveFavorites } from 'Api/Layout';
import './leftMenu.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as EditIcon } from 'assets/sidemanu/edit.svg';
import { Typography } from '../Typography';
import { LayoutContext } from '../layout';

const FavoritesPopover = ({ Icon, getActivePath, el, subItems, editable, indigoItems, parentTitle, onClick }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { onDeleteFavorites } = useContext(LayoutContext);
  const [className, setClassName] = useState('');
  const [activeId, setActiveId] = useState(null);
  const [edit, setEdit] = useState(false);

  const stylesObj = useMemo(() => {
    return {
      buttonText: {
        fontWeight: 700,
        margin: '0 0 8px 0',
        color: use(palette.dark800, palette.gray200),
      },
      editItems: {
        backgroundColor: use(palette.gray700, palette.gray200),
      },
      editIcon: {
        margin: '0 0 8px 15px',
      },
    };
  }, [use, palette, subItems]);
  const [hoveredFavoriteIndex, setHoveredFavoriteIndex] = useState(-1);
  const onClickPopoverItem = (path, state) => {
    if (state) {
      navigate(path, { state });
      return;
    }
    path && path !== '' && navigate(path);
  };

  const onMouseOver = (e) => {
    const item = e.target.closest('.leftMenuClosedPopover_item');

    setHoveredFavoriteIndex(item.dataset.id || -1);
    setClassName(use('light', 'dark', 'primary'));
  };

  const onMouseLeave = () => {
    setClassName('');
  };

  const deleteItemHandler = (id) => {
    RemoveFavorites(id).then(() => {
      onDeleteFavorites(id);
    });
  };

  return (
    <ThreeDotActions
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      popoverStyles={{ marginLeft: '25px' }}
      handleClose={() => setEdit(false)}
      actionButton={
        <OverlayTrigger
          key={uuid()}
          placement='right'
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>{parentTitle}</Tooltip>}
        >
          <Icon
            width={19}
            height={19}
            fill={
              getActivePath(el)
                ? use(palette.indigo500, palette.indigo400, palette.white)
                : use(palette.gray500, palette.gray400, palette.indigo200)
            }
          />
        </OverlayTrigger>
      }
    >
      <div style={{ padding: '16px', minWidth: '180px' }}>
        <div className='leftMenuClosedPopover_title_wrapper'>
          <Typography variant='s2' className='button-text' style={stylesObj.buttonText}>
            {parentTitle}
          </Typography>
          {!!editable && (
            <EditIcon
              fill={use(palette.gray700, palette.gray500)}
              onClick={() => setEdit((p) => !p)}
              style={stylesObj.editIcon}
            />
          )}
        </div>
        {subItems?.map((el, index) => {
          const { id, subTitle, Icon, path, state } = el;
          const subTitleStyle = {
            marginLeft: Icon && 4,
            fontWeight: indigoItems ? 700 : 500,
            color: indigoItems
              ? use(palette.indigo500, palette.indigo400, palette.indigo500)
              : use(palette.gray700, palette.gray200),
          };
          return (
            <div
              key={uuid()}
              data-id={index}
              onClick={(e) => {
                e.stopPropagation();
                setActiveId(id);
                onClick();
                setAnchorEl(null);
                setEdit(false);
              }}
              className={`leftMenuClosedPopover_item ${+hoveredFavoriteIndex === index ? className : ''} 
                        ${id === activeId ? use('light_pressed', 'dark_pressed') : ''}
                        ${indigoItems ? 'favorites' : ''}
                        `}
            >
              {!indigoItems && (
                <div className='leftMenuClosedPopover_line_wrapper'>
                  <div className='leftMenuClosedPopover_line' />
                </div>
              )}

              <div
                className='leftMenuClosedPopover_block'
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                style={{
                  marginLeft: indigoItems ? 0 : 16,
                }}
              >
                {Icon && <Icon fill={use(palette.gray700, palette.gray200)} width={18} />}

                <Typography
                  variant='s2'
                  onClick={() => onClickPopoverItem(path, state)}
                  className='popover_text'
                  style={subTitleStyle}
                >
                  {subTitle}
                </Typography>

                <div
                  className={`edit_items_delete_wrapper ${edit ? 'show' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteItemHandler(id);
                  }}
                >
                  <span className='edit_items' style={stylesObj.editItems} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </ThreeDotActions>
  );
};

export default FavoritesPopover;
