import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import BasicDetails from './StaffBasicDetail';
import StaffPreviousEmployment from './StaffPreviousEmployment';

import Policies from './Policies';
import RestrictionShift from './RestrictionShift';
import StaffCompensation from './StaffCompensation';
import StaffDocument from './StaffDocument';
import StaffConnectedServices from './StaffConnectedServices';
import NewDriverAdded from '../../Drivers/AddDriver/DriverEditedModal';

const AddStaff = ({ close, getStaff, openStep, currentDriverId }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [status, setStatus] = useState(1);
  const [formData, setFormData] = useState({});
  const [modalShow, setModalShow] = useState(true);

  const [stepsDone, setStepsDone] = useState({
    isBasicDetailsSaved: false,
    isCompensation: false,
    isRestrictShift: false,
    isPreviousEmployement: false,
    isDocuments: false,
    isPolicies: false,
    isConnectedSeries: false,
  });
  const [drvier, setDrvier] = useState({
    basicDetails: {},
    basicDetailsStates: {},
    policy: {},
    compensation: {},
    restrictShift: {},
    previousEmployement: null,
    documents: {},
    connectedSeries: {},
  });
  const statusHandler = (status) => {
    setStatus(status);
  };

  const nextStep = (formValue, message) => {
    if (status === 1) {
      const { formikData, stateData, staffId, previousEmployment } = formValue || {};

      setDrvier({
        ...drvier,
        basicDetails: formikData,
        basicDetailsStates: stateData,
        staffId: staffId || null,
        previousEmployement: previousEmployment || drvier.previousEmployement,
      });
    }
    if (status === 2) {
      setDrvier({
        ...drvier,
        compensation: formValue,
      });
    }
    if (status === 3) {
      setDrvier({
        ...drvier,
        policy: formValue,
      });
    }
    if (status === 4) {
      setDrvier({
        ...drvier,
        restrictShift: formValue,
      });
    }
    if (status === 5) {
      setDrvier({
        ...drvier,
        previousEmployement: formValue,
      });
    }
    if (status === 6) {
      const { formikData, documentId } = formValue || {};
      setDrvier({
        ...drvier,
        documents: formikData,
        documentId: documentId || null,
      });
    }
    if (status === 7) {
      setDrvier({
        ...drvier,
        connectedSeries: formValue,
      });
    }
    setStatus(status + 1);
    if (message) {
      showToaster({ type: 'success', message });
    }
  };

  const prevStep = () => {
    if (status <= 5) {
      document.getElementById('driver-container').scrollLeft -= 400;
    }
    setStatus(status - 1);
  };

  const Cancel = () => {
    close();
  };

  const showError = (message) => {
    showToaster({ type: 'error', message });
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  useEffect(() => {
    if (openStep) {
      setStatus(openStep);
    }
  }, []);

  return (
    <>
      {status !== 8 && (
        <div
          className='main-modal-wrapper'
          style={{ backgroundColor: use(palette.white, palette.dark600), position: 'relative' }}
        >
          <div style={{ position: 'absolute', top: '0.7rem', margin: '0 0 20px' }}>
            <p className='add-driver-modal-text' style={{ color: use('#171c26', palette.gray200) }}>
              Add Staff
            </p>
          </div>

          <Modal.Header className='add-driver-modal-header driver-style-wrap'>
            <div className='steps-wrapper' id='driver-container'>
              <div
                className={status >= 1 ? 'step-heading-active' : 'steps-heading'}
                // style={{height: '25px'}}
              >
                {status > 1 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Basic Details</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={!(status >= 1)}
                      checked={status >= 1}
                      onClick={() => statusHandler(1)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Basic Details</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 1 ? 'incomplete-progressbar' : status > 1 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 2 ? 'step-heading-active' : 'steps-heading'}>
                {status > 2 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Compensation</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 2}
                      checked={status === 2}
                      onClick={() => statusHandler(2)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Compensation</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 2 ? 'incomplete-progressbar' : status > 2 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 3 ? 'step-heading-active' : 'steps-heading'}>
                {status > 3 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Policies</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 3}
                      checked={status === 3}
                      onClick={() => statusHandler(3)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Policies</span>
                  </div>
                )}
                <div className='progress'>
                  <div
                    className={status === 3 ? 'incomplete-progressbar' : status > 3 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 4 ? 'step-heading-active' : 'steps-heading'}>
                {status > 4 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Restrictions & Shift</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 4}
                      checked={status === 4}
                      onClick={() => statusHandler(4)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Restrictions & Shift</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 4 ? 'incomplete-progressbar' : status > 4 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 5 ? 'step-heading-active' : 'steps-heading'}>
                {status > 5 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Previous Employment</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 5}
                      checked={status === 5}
                      onClick={() => statusHandler(5)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Previous Employment</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 5 ? 'incomplete-progressbar' : status > 5 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 6 ? 'step-heading-active' : 'steps-heading'}>
                {status > 6 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Documents</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 6}
                      checked={status === 6}
                      onClick={() => statusHandler(6)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Documents</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 6 ? 'incomplete-progressbar' : status > 6 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 7 ? 'step-heading-active' : 'steps-heading'}>
                {status > 7 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span> Connected Services</span>
                  </div>
                ) : (
                  <div>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 7}
                      checked={status === 7}
                      onClick={() => statusHandler(7)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span> Connected Services</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 7 ? 'incomplete-progressbar' : status > 7 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
            </div>
          </Modal.Header>
        </div>
      )}
      {status === 1 && (
        <div>
          <BasicDetails
            nextStep={nextStep}
            showError={showError}
            prevStep={prevStep}
            Cancel={Cancel}
            handleFormData={handleInputData}
            values={formData}
            basicDetails={drvier.basicDetails}
            basicDetailsStates={drvier.basicDetailsStates}
            stepsDone={stepsDone.isBasicDetailsSaved}
            getStaff={getStaff}
            staffId={drvier.staffId || currentDriverId}
          />
        </div>
      )}
      {status === 2 && (
        <div>
          <StaffCompensation
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            compensation={drvier.compensation}
            stepsDone={stepsDone.isCompensation}
            setStepsDone={setStepsDone}
            staffId={drvier.staffId || currentDriverId}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 3 && (
        <div>
          <Policies
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isPolices}
            setStepsDone={setStepsDone}
            staffId={drvier.staffId || currentDriverId}
            policy={drvier.policy}
            draftDriver={currentDriverId}
            getStaff={getStaff}
          />
        </div>
      )}
      {status === 4 && (
        <div>
          <RestrictionShift
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isRestrictShift}
            setStepsDone={setStepsDone}
            staffId={drvier.staffId || currentDriverId}
            restrictShift={drvier.restrictShift}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 5 && (
        <div>
          <StaffPreviousEmployment
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isPreviousEmployement}
            setStepsDone={setStepsDone}
            staffId={drvier.staffId || currentDriverId}
            previousEmployment={drvier.previousEmployement}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 6 && (
        <div>
          <StaffDocument
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isDocuments}
            setStepsDone={setStepsDone}
            staffId={drvier.staffId || currentDriverId}
            documents={drvier.documents}
            documentId={drvier.documentId}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 7 && (
        <div>
          <StaffConnectedServices
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isConnectedSeries}
            setStepsDone={setStepsDone}
            staffId={drvier.staffId || currentDriverId}
            getStaff={getStaff}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 8 && (
        <div className={modalShow ? 'fade modal-backdrop show' : 'modal'}>
          <NewDriverAdded
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              close();
            }}
            name='Profile Created '
            desc='Staff has been created successfully!'
          />
        </div>
      )}
    </>
  );
};

export default AddStaff;
