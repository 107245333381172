import React from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';

const Contains = ({ editMode, data, type, onChange, onDelete, onBlur, error }) => {
  return (
    <>
      {editMode && data.isNew && (
        <Input
          type={type === 'amount' ? 'number' : 'text'}
          width='88px'
          size='small'
          value={data.value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={type === 'amount' ? blockNonPositiveNumbers : undefined}
          error={error}
        />
      )}
      {editMode && !data.isNew && (
        <Chip
          padding='4px 8px 4px 12px'
          label={data.value}
          labelColor={palette.indigo500}
          bgColor={palette.indigo0}
          deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
          onDelete={onDelete}
        />
      )}
      {!editMode && !data.isNew && (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {data.value}
        </Typography>
      )}
    </>
  );
};

export default Contains;
