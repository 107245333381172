import React from 'react';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { STitleWrapper } from '../OnboardCarrier.styles';

const StepTitle = ({ title, currentStep, carrier }) => {
  const { use } = useTheme();

  return (
    <STitleWrapper $currentStep={currentStep} $hasInterCharge={carrier?.require_trailer_interchange}>
      <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray200) }}>
        {title}
      </Typography>
    </STitleWrapper>
  );
};

export default StepTitle;
