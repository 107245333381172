import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const Actions = ({ section, widget, departments, values, handleChange, sectionIndex }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const index = section.widgets.findIndex((i) => i.id === widget.id);

  const onCheckboxChange = (checked, department) => {
    let newDepartments = values[sectionIndex].widgets[index].departments;
    if (checked) {
      newDepartments.push({ id: department.id, department_name: department.department_name });
    } else {
      newDepartments = newDepartments.filter((i) => i.id !== department.id);
    }

    handleChange(`${sectionIndex}.widgets[${index}].departments`, newDepartments);
    handleChange(`${sectionIndex}.widgets[${index}].isUpdated`, true);
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <Typography variant='button2'>Can View</Typography>
      {departments.map((item) => (
        <div key={item.id} className='p-1'>
          <CustomCheckbox
            checked={values[sectionIndex].widgets[index]?.departments?.some((i) => i.id === item.id)}
            onChange={(checked) => onCheckboxChange(checked, item)}
          >
            <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
              {item.department_name}
            </Typography>
          </CustomCheckbox>
        </div>
      ))}
    </ThreeDotActions>
  );
};

export default Actions;
