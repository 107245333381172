import React, { useState } from 'react';
import eyeIcon from 'assets/icons/eye.png';
import ErrorMessage from 'common/message/errorMessage';
import { regExp } from 'utils/helpers';

const ThirdStep = ({ nextStep, pwd, repeatPwd, step, setPwd, setRepeatPwd, isStaff, saveCutomerData }) => {
  const [isPwdMismatched, setIsPwdMismatched] = useState(false);
  const [isPwdInvalid, setIsPwdInvalid] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);

  const checkPasswordInput = () => {
    setIsPwdInvalid(false);
    setIsPwdMismatched(false);

    if (regExp.PASSWORD.test(pwd) === false) {
      setIsPwdInvalid(true);
      if (pwd === repeatPwd) {
        setIsPwdMismatched(false);
      }
    }

    if (regExp.PASSWORD.test(pwd) && pwd !== repeatPwd) {
      setIsPwdInvalid(false);
      setIsPwdMismatched(true);
    }

    if (regExp.PASSWORD.test(pwd) && pwd === repeatPwd) {
      setIsPwdInvalid(false);
      setIsPwdMismatched(false);
      if (!isStaff) {
        saveCutomerData();
      }
      nextStep();
    }
  };

  return (
    <>
      <div className='modal-header-custom'>
        <h1 className='welcome-bg'>Create Password</h1>
        <p className='welcome-label'>
          It is highly recommended to enter a strong password. It must be at least 8 characters long and must contain 1
          uppercase letter, 1 lowercase letter, 1 number, 1 special character.
        </p>
      </div>

      {step === 2 && (
        <div className='create-password-error'>
          <ErrorMessage
            message='Your password did not meet security requirements!'
            close={() => setIsPwdInvalid(false)}
            show={isPwdInvalid}
            left
          />
          <ErrorMessage
            message='Password must be 8 characters long with at least 1 uppercase letter, 1 number and 1 special character.'
            close={() => setIsPwdInvalid(false)}
            show={isPwdInvalid}
            left
          />
          <ErrorMessage
            message='Passwords do not match!'
            close={() => setIsPwdMismatched(false)}
            show={isPwdMismatched}
            left
          />
        </div>
      )}
      <div className='modal-body-custom '>
        <div className='password-set'>
          <div className='input-pwd'>
            <p className='data-display'>New Password</p>
            <p className='data-display'>Confirm Password</p>
          </div>
          <div className='input-pwd'>
            <input
              name='password_confirmation'
              type={!showPwd ? 'password' : 'text'}
              className='input-style form-control'
              onChange={(e) => setRepeatPwd(e.target.value)}
            />
            <div className='input-eye-icon'>
              <img src={eyeIcon} alt='Eye' onClick={() => setShowPwd((prevState) => !prevState)} />
            </div>
            <input
              name='password'
              type={!showConfirmPwd ? 'password' : 'text'}
              className=' form-control input-style'
              onChange={(e) => setPwd(e.target.value)}
            />
            <div className='input-eye-icon'>
              <img src={eyeIcon} alt='Eye' onClick={() => setShowConfirmPwd((prevState) => !prevState)} />
            </div>
          </div>
        </div>
      </div>

      <div className='modal-footer-custom'>
        <button className='button-footer btn' onClick={checkPasswordInput}>
          Continue
        </button>
      </div>
    </>
  );
};

export default ThirdStep;
