import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { handleToaster } from 'store/reducers/root.reducer';
import useShowToaster from 'hooks/useShowToaster';
import sm from '../../BillingCharges/BillingCharges.module.css';
import { ShipmentContext } from '../../Layout';

import CustomButton from '../../../CustomButton/CustomButton';

const BillingChargesLTLFooter = ({ disableNextStep, submitForm, validateForm, values }) => {
  const { use } = useTheme();
  const dispatch = useDispatch();
  const { setStep, onCloseModal } = useContext(ShipmentContext);
  const showToaster = useShowToaster();

  const validateSubTotal = (shipmentsBilling) => {
    const foundedZeroSubTotal = { isValidSubTotals: true };
    outer_loop: for (let i = 0; i < shipmentsBilling.length; i++) {
      const charges = shipmentsBilling[i]?.billingCharges;
      for (let idx = 0; idx < charges.length; idx++) {
        const charge = charges[idx];
        const { qty, rate } = charge || {};
        const subTotal = (+qty * +rate).toFixed(2);
        if (+subTotal === 0) {
          foundedZeroSubTotal.isValidSubTotals = false;
          foundedZeroSubTotal.number = i + 1;
          break outer_loop;
        }
      }
    }
    return foundedZeroSubTotal;
  };

  const onNext = () => {
    submitForm();
    validateForm().then((res) => {
      const isValid = Object.keys(res).length === 0;
      const { isValidSubTotals } = validateSubTotal(values?.shipmentsBilling);
      if (!!isValid && !isValidSubTotals) {
        const message = `Charge amount cannot be 0`;
        dispatch(handleToaster({ message, visible: true, type: 'error' }));
      }
      if (values.shipmentsBilling.length === 1) {
        showToaster({ type: 'error', message: 'Please add at least 2 customers in order to proceed !' });
      }
      !!isValid && !!isValidSubTotals && values.shipmentsBilling.length > 1 && setStep((p) => p + 1);
    });
  };

  return (
    <div className={sm.billing_charges_footer} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <div>
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onCloseModal}
        />
      </div>

      <div className={sm.right_buttons}>
        <CustomButton
          type='secondary'
          title='Back'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={() => {
            setStep((p) => p - 1);
          }}
        />

        <CustomButton
          type='primary'
          title='Next Step'
          disabled={disableNextStep}
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onNext}
          buttonProps={{
            type: 'submit',
          }}
        />
      </div>
    </div>
  );
};

export default BillingChargesLTLFooter;
