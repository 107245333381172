import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import UserInfo from 'components/UserInfo';
import { DateTimePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import Input, { Textarea } from 'common/Input';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { customerTimeToUtc } from 'utils/helpers';
import { DepartmentsEmail } from 'Api/Driver';
import { updateNextAvailability } from 'Api/Equipment';
import { getCities, getStates } from 'Api/JobPositions';
import { initialValues } from './UpdateNextAvailable.data';
import { validationSchema } from './validationSchema';

export const COUNTRIES = [
  { key: 'USA', name: 'United States', id: 231, phonecode: 1 },
  { key: 'CAN', name: 'Canada', id: 38, phonecode: 1 },
  { key: 'MX', name: 'Mexico', id: 142, phonecode: 52 },
];

const UpdateNextAvailable = ({ open, onClose, driver, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        equipment_id: driver.equipment[0].id,
        city_id: values.city?.id || null,
        state_id: values.state?.id || null,
        country_id: values.country?.id || null,
        zipcode: values.zipcode || null,
        next_available_date: customerTimeToUtc(moment(values.next_available_date).format('YYYY-MM-DD HH:mm:ss')),
        notes: values.notes || null,
        note_to_email: values.note_to_email || null,
        department_id: values.department?.id || null,
      };

      const { data } = await updateNextAvailability(body);
      showToaster({ type: 'success', message: 'Next availability has been successfully updated!' });
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, handleBlur, setValues } = useForm({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getDepartments = async () => {
    try {
      const { data } = await DepartmentsEmail();
      setDepartments(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <div className='d-flex flex-column gap-1'>
          <Typography variant='button2'>Update Next Availability</Typography>
          <div className='d-flex align-items-center gap-1'>
            <UserInfo
              size='32px'
              statusSize='7px'
              data={{
                ...driver,
                name: `${driver.fname} ${driver.lname}`,
                image: driver.image,
                status: driver.status_data?.driver_status,
                user_type: 'driver',
              }}
            />{' '}
            <Typography variant='s2'>- Vehicle {driver.equipment[0].equipment_id}</Typography>
          </div>
        </div>
      }
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='600px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      backdrop='static'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div>
        <div className='d-flex flex-column w-50 mb-4'>
          <DateTimePicker
            required
            label='Next Available Date'
            name='next_available_date'
            value={values.next_available_date}
            onBlur={handleBlur}
            onChange={(val) => handleChange('next_available_date', val)}
            error={touchedErrors.next_available_date}
          />
        </div>
        <div className='mb-4'>
          <InputLabel required>Next Available Location</InputLabel>
          <div>
            <div className='d-flex gap-2'>
              <div className='w-100'>
                <Autocomplete
                  name='country'
                  placeholder='Select Country..'
                  value={values.country}
                  onChange={(e, value) =>
                    setValues((prevState) => ({ ...prevState, country: value, state: null, city: null }))
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={COUNTRIES}
                  disableClearable={false}
                  error={touchedErrors?.country}
                />
              </div>
              <div className='w-100'>
                <Autocomplete
                  name='state'
                  placeholder='Select State..'
                  value={values.state}
                  onChange={(e, value) => setValues((prevState) => ({ ...prevState, state: value, city: null }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={states}
                  disableClearable={false}
                  error={touchedErrors?.state}
                />
              </div>
              <div className='w-100'>
                <Autocomplete
                  name='city'
                  placeholder='Select City..'
                  value={values.city}
                  onChange={(e, val) => handleChange('city', val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={cities}
                  disableClearable={false}
                  error={touchedErrors?.city}
                />
              </div>
            </div>
            <Divider className='mt-2 mb-2'>
              <Typography variant='s2' style={{ color: palette.gray500 }}>
                or
              </Typography>
            </Divider>
            <div className='d-flex justify-content-center'>
              <Input name='zipcode' placeholder='Zip Code' onChange={handleChange} value={values.zipcode} />
            </div>
            <div className='d-flex justify-content-center'>
              <ErrorMessage error={touchedErrors.zipcode} />
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <Textarea
            rows={3}
            required
            label='Notes'
            placeholder='Add notes'
            onChange={(e) => handleChange('notes', e.target.value)}
            value={values.notes}
            error={touchedErrors.notes}
          />
        </div>
        <div>
          <InputLabel label='Email note to or select department for CC' />
          <div className='d-flex gap-2'>
            <div className='w-100'>
              <Input
                type='email'
                name='note_to_email'
                placeholder='Email note to...'
                value={values.note_to_email}
                onChange={handleChange}
                error={touchedErrors.note_to_email}
              />
            </div>
            <div className='w-100'>
              <Autocomplete
                options={departments}
                labelKey='department_name'
                name='department'
                placeholder='Select Department'
                value={values.department}
                onChange={(e, val) => handleChange('department', val)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateNextAvailable;
