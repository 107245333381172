import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Modal from 'common/Modal';
import NoRecords from 'common/NoRecords';
import ConfirmationModal from 'common/ConfirmationModal';
import ViewPdf from 'components/ViewPdf';
import UserInfo from 'components/UserInfo';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { deletePrevEmploymentNote, getPrevEmploymentNotes } from 'Api/PreviousEmployment';
import { generatePDF } from './generatePdf';
import AddPreviousEmploymentNote from './AddPreviousEmploymentNote';
import { SAddMore, STable } from './PreviousEmploymentNotes.styles';

const PreviousEmploymentNotes = ({ open, onClose, attempt, userType, employer }) => {
  const showToaster = useShowToaster();
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const [notes, setNotes] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [noteToRemoveId, setNoteToRemoveId] = useState(null);

  const getNotes = async () => {
    try {
      setLoading(true);
      const { data } = await getPrevEmploymentNotes({ id: attempt.id, userType });
      setNotes(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(true);
    }
  };

  const removeNote = async () => {
    setLoadingDelete(true);
    try {
      await deletePrevEmploymentNote({ id: noteToRemoveId, userType });
      setNotes((prevState) => prevState.filter((item) => item.id !== noteToRemoveId));
      setNoteToRemoveId(null);
      showToaster({ type: 'success', message: 'Note has been deleted successfully!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingDelete(false);
  };

  const onPrintPdf = async () => {
    try {
      const { url } = await generatePDF(employer, notes, convertToCustomerTime, formatDate);
      setPdfUrl(url);
    } catch (e) {
      /* empty */
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <div className='d-flex align-items-center justify-content-between'>
          <Typography variant='h5' style={{ color: palette.green900 }}>
            Previous Employment Notes for {employer.name}
          </Typography>
          <PrintIcon width={20} height={20} className='pointer' fill={palette.indigo500} onClick={() => onPrintPdf()} />
        </div>
      }
      $bgColor={palette.gray0}
      $maxWidth='1100px'
      $minWidth='1100px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <div className='d-flex flex-column'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>DATE</th>
              <th>TITLE</th>
              <th>NOTE</th>
              <th>ADDED BY</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {notes?.map((item) => (
              <tr key={item.id} className='body-row'>
                <td style={{ width: '10%' }}>
                  <Typography variant='s2'>{formatDate(item.date)}</Typography>
                </td>
                <td style={{ width: '15%' }}>
                  <Typography variant='b2'>{item.title}</Typography>
                </td>
                <td style={{ width: '50%' }}>
                  <Typography variant='b2'>{item.notes}</Typography>
                </td>
                <td style={{ width: '20%' }}>
                  {item.added_by ? (
                    <UserInfo
                      size='36px'
                      data={{
                        ...(item.added_by || {}),
                        name: `${item.added_by?.first_name || ''} ${item.added_by?.last_name || ''}`,
                        image: item.added_by?.profile_logo,
                        status: item.added_by?.login_status,
                        user_type: 'staff',
                      }}
                      hidePopup
                    />
                  ) : (
                    <Typography variant='b2'>-</Typography>
                  )}
                </td>
                <td style={{ width: '5%' }}>
                  <span className='action-wrapper' onClick={() => setNoteToRemoveId(item.id)}>
                    <DeleteIcon width={9} height={9} fill={palette.red500} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!loading && !notes.length && <NoRecords />}
        <div>
          <SAddMore onClick={() => setOpenAddNote(true)}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add {notes.length ? 'Another...' : 'Note'}
            </Typography>
          </SAddMore>
        </div>
      </div>
      {!!openAddNote && (
        <AddPreviousEmploymentNote
          userType={userType}
          open={!!openAddNote}
          onClose={() => setOpenAddNote(false)}
          employmentId={employer.id}
          attemptId={attempt.id}
          onSuccess={getNotes}
        />
      )}
      {!!noteToRemoveId && (
        <ConfirmationModal
          width='510px'
          open={!!noteToRemoveId}
          onClose={() => setNoteToRemoveId(null)}
          headerTitle='Delete Note'
          text='Are you sure you want to delete this note?'
          onConfirm={removeNote}
          disabled={loadingDelete}
        />
      )}
      {!!pdfUrl && (
        <ViewPdf
          open={!!pdfUrl}
          onClose={() => setPdfUrl(null)}
          pdfUrl={pdfUrl}
          title={`Previous Employment Notes for ${employer.name}`}
        />
      )}
    </Modal>
  );
};

export default PreviousEmploymentNotes;
