import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import { OverlayTrigger } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette, statusColorInStatusId } from 'utils/constants';
import person from 'assets/images/person.svg';

import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';

import { Typography } from '../../Typography';
import classes from '../tableShipments.module.css';
import OverLayTriggerHoverWrapper from '../../OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';

const UserInfoRowAssignedTo = ({ data }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const condOneDriver = !!data?.assigned_to_1 && !data?.assigned_to_2;
  const { fname, lname, image, email, phone_number, status, id } = data?.assigned_to_1 || {};
  const { brokerage_dispatch } = data || {};
  const { carrier } = brokerage_dispatch || {};
  const { logo_path } = carrier || {};
  const {
    fname: fname2,
    lname: lname2,
    image: image2,
    email: email2,
    phone_number: phone_number2,
    status: status2,
    id: id2,
  } = data?.assigned_to_2 || {};

  return (
    <div className={classes.assignWrapper}>
      {condOneDriver ? (
        fname ? (
          <OverLayTriggerHoverWrapper
            overLayContainerStyle={{ border: 'none', backgroundColor: 'transparent' }}
            overlay={({ placement, arrowProps, show: _show, popper, ...props }) => {
              return (
                <div {...props} onClick={(e) => e.stopPropagation()} style={{ zIndex: 99, ...props.style }}>
                  <div className={classNames(classes.userInfoOverLay, use(classes.light, classes.dark))}>
                    <div className={classes.header}>
                      <div className={classes.assignImgWrapper}>
                        {carrier ? (
                          logo_path ? (
                            <img src={logo_path} width='100%' height='100%' alt='img' />
                          ) : (
                            <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                          )
                        ) : (
                          <img src={image || person} width='100%' height='100%' alt='img' />
                        )}
                        {!carrier && (
                          <div
                            className={classes.avatarStatus}
                            style={{
                              backgroundColor: use(
                                statusColorInStatusId[status]?.color,
                                statusColorInStatusId[status]?.darkColor
                              ),
                            }}
                          />
                        )}
                      </div>
                      <Typography variant='s2'>
                        {fname} {lname}
                      </Typography>
                    </div>
                    <div className={classes.body}>
                      <div className={classes.block}>
                        <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
                          <ContactIcon fill={palette.gray400} />
                          <Typography variant='s2'>{phone_number}</Typography>
                        </a>
                      </div>
                      {!!email && (
                        <div onClick={(e) => e.stopPropagation()} className={classes.block}>
                          <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
                            <MailIcon fill={palette.gray400} />
                            <Typography variant='s2'>{email}</Typography>
                          </a>
                        </div>
                      )}
                      {!!id && (
                        <NavLink
                          to='/chat'
                          className={classes.block}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/chat`);
                          }}
                        >
                          <ChatIcon fill={palette.gray400} />
                          <Typography variant='s2'>Chat</Typography>
                        </NavLink>
                      )}
                      {!!id && (
                        <NavLink
                          to={`/driver-profile/${id}/general`}
                          className={classes.block}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/driver-profile/${id}/general`);
                          }}
                        >
                          <ProfileIcon fill={palette.gray400} />
                          <Typography variant='s2'>Profile</Typography>
                        </NavLink>
                      )}
                    </div>
                  </div>
                </div>
              );
            }}
          >
            <div>
              {fname && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <div className={classes.assignImgWrapper}>
                    {carrier ? (
                      logo_path ? (
                        <img src={logo_path} width='100%' height='100%' alt='img' />
                      ) : (
                        <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                      )
                    ) : (
                      <img src={image || person} width='100%' height='100%' alt='img' />
                    )}
                    {!carrier && (
                      <div
                        className={classes.avatarStatus}
                        style={{
                          backgroundColor: use(
                            statusColorInStatusId[status]?.color,
                            statusColorInStatusId[status]?.darkColor
                          ),
                        }}
                      />
                    )}
                  </div>
                  <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {fname} {lname}
                  </Typography>
                </div>
              )}

              {!!carrier && (
                <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                  {carrier?.name}
                </Typography>
              )}
            </div>
          </OverLayTriggerHoverWrapper>
        ) : (
          <div>
            {fname && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div className={classes.assignImgWrapper}>
                  {carrier ? (
                    logo_path ? (
                      <img src={logo_path} width='100%' height='100%' alt='img' />
                    ) : (
                      <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                    )
                  ) : (
                    <img src={image || person} width='100%' height='100%' alt='img' />
                  )}
                  {!carrier && (
                    <div
                      className={classes.avatarStatus}
                      style={{
                        backgroundColor: use(
                          statusColorInStatusId[status]?.color,
                          statusColorInStatusId[status]?.darkColor
                        ),
                      }}
                    />
                  )}
                </div>
                <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                  {fname} {lname}
                </Typography>
              </div>
            )}

            {!!carrier && (
              <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                {carrier?.name}
              </Typography>
            )}
          </div>
        )
      ) : (
        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '5px' }}>
          <div onMouseLeave={() => setShow(false)}>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement='top-start'
              delay={{ show: 200, hide: 350 }}
              show={show}
              onToggle={(v) => !!v && setShow(true)}
              overlay={({ placement, arrowProps, show: _show, popper, ...props }) => {
                return (
                  <div {...props} onClick={(e) => e.stopPropagation()} style={{ zIndex: 99, ...props.style }}>
                    <div className={classNames(classes.userInfoOverLay, use(classes.light, classes.dark))}>
                      <div className={classes.header}>
                        <div className={classes.assignImgWrapper}>
                          {carrier ? (
                            logo_path ? (
                              <img src={logo_path} width='100%' height='100%' alt='img' />
                            ) : (
                              <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                            )
                          ) : (
                            <img src={image || person} width='100%' height='100%' alt='img' />
                          )}
                          <div
                            className={classes.avatarStatus}
                            style={{
                              backgroundColor: use(
                                statusColorInStatusId[status]?.color,
                                statusColorInStatusId[status]?.darkColor
                              ),
                            }}
                          />
                        </div>
                        <Typography variant='s2'>
                          {fname} {lname}
                        </Typography>
                      </div>
                      <div className={classes.body}>
                        <div className={classes.block}>
                          <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
                            <ContactIcon fill={palette.gray400} />
                            <Typography variant='s2'>{phone_number}</Typography>
                          </a>
                        </div>
                        <div className={classes.block}>
                          <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
                            <MailIcon fill={palette.gray400} />
                            <Typography variant='s2'>{email}</Typography>
                          </a>
                        </div>
                        <NavLink
                          to='/chat'
                          className={classes.block}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/chat`);
                          }}
                        >
                          <ChatIcon fill={palette.gray400} />
                          <Typography variant='s2'>Chat</Typography>
                        </NavLink>
                        <NavLink
                          to={`/driver-profile/${id}/general`}
                          className={classes.block}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/driver-profile/${id}/general`);
                          }}
                        >
                          <ProfileIcon fill={palette.gray400} />
                          <Typography variant='s2'>Profile</Typography>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div className={classes.assignImgWrapper}>
                  {carrier ? (
                    logo_path ? (
                      <img src={logo_path} width='100%' height='100%' alt='img' />
                    ) : (
                      <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                    )
                  ) : (
                    <img src={image || person} width='100%' height='100%' alt='img' />
                  )}
                  {!carrier && (
                    <div
                      className={classes.avatarStatus}
                      style={{
                        backgroundColor: use(
                          statusColorInStatusId[status]?.color,
                          statusColorInStatusId[status]?.darkColor
                        ),
                      }}
                    />
                  )}
                </div>
                <Typography variant='s2'>
                  {fname} {lname}
                </Typography>
              </div>
            </OverlayTrigger>
          </div>

          <div onMouseLeave={() => setShow2(false)}>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement='top-start'
              delay={{ show: 200, hide: 350 }}
              show={show2}
              onToggle={(v) => !!v && setShow2(true)}
              overlay={({ placement, arrowProps, show: _show, popper, ...props }) => {
                return (
                  <div {...props} onClick={(e) => e.stopPropagation()} style={{ zIndex: 99, ...props.style }}>
                    <div className={classNames(classes.userInfoOverLay, use(classes.light, classes.dark))}>
                      <div className={classes.header}>
                        <div className={classes.assignImgWrapper}>
                          <img src={image2 || person} width='100%' height='100%' alt='img' />
                          <div
                            className={classes.avatarStatus}
                            style={{
                              backgroundColor: use(
                                statusColorInStatusId[status2]?.color,
                                statusColorInStatusId[status2]?.darkColor
                              ),
                            }}
                          />
                        </div>
                        <Typography variant='s2'>
                          {fname2} {lname2}
                        </Typography>
                      </div>
                      <div className={classes.body}>
                        <div className={classes.block}>
                          <a href={`tel:${phone_number}`} style={{ textDecoration: 'none' }}>
                            <ContactIcon fill={palette.gray400} />
                            <Typography variant='s2'>{phone_number2}</Typography>
                          </a>
                        </div>
                        <div className={classes.block}>
                          <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
                            <MailIcon fill={palette.gray400} />
                            <Typography variant='s2'>{email2}</Typography>
                          </a>
                        </div>
                        <NavLink
                          to='/chat'
                          className={classes.block}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/chat`);
                          }}
                        >
                          <ChatIcon fill={palette.gray400} />
                          <Typography variant='s2'>Chat</Typography>
                        </NavLink>
                        <NavLink
                          to={`/driver-profile/${id2}/general`}
                          className={classes.block}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/driver-profile/${id2}/general`);
                          }}
                        >
                          <ProfileIcon fill={palette.gray400} />
                          <Typography variant='s2'>Profile</Typography>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div className={classes.assignImgWrapper}>
                  <img src={image2 || person} width='100%' height='100%' alt='img' />
                  <div
                    className={classes.avatarStatus}
                    style={{
                      backgroundColor: use(
                        statusColorInStatusId[status2]?.color,
                        statusColorInStatusId[status2]?.darkColor
                      ),
                    }}
                  />
                </div>
                <Typography variant='s2'>
                  {fname2} {lname2}
                </Typography>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfoRowAssignedTo;
