import React from 'react';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 800,
  minHeight: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: 0,
  padding: 0,
};

const EditMaintenanceRecordViewDocument = ({ handleClosePreviewEditRecord, link }) => {
  return (
    <div>
      <Box sx={style}>
        <div className='container'>
          <div className='preview-title d-flex justify-content-between' />
          <div className='preview-body' style={{ backgroundColor: '#E5E5E5' }}>
            <div style={{ height: '85vh', maxWidth: '60vw' }}>
              <iframe src={link} style={{ height: '100%', width: '100%' }} title='Doc' />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end p-2'>
          <button type='button' className='preview-modal-done-button' onClick={handleClosePreviewEditRecord}>
            Done
          </button>
        </div>
      </Box>
    </div>
  );
};

export default EditMaintenanceRecordViewDocument;
