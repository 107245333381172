import uuid from 'react-uuid';

export const LOCATION_INITIAL_VALUE = {
  city: { searchValue: '', value: 'city', key: uuid() },
  state: { searchValue: '', value: 'state', key: uuid() },
  postCode: { searchValue: '', value: 'postCode', key: uuid() },
  radius: { searchValue: '50', value: 'radius', key: uuid() },
  scheduled_from: { searchValue: '', value: 'scheduled_from', key: uuid() },
  scheduled_to: { searchValue: '', value: 'scheduled_to', key: uuid() },
};
