import React, { useState } from 'react';
import { Modal, Toast } from 'react-bootstrap';
import Popover from '@mui/material/Popover';
import UserInfo from 'components/UserInfo';
import AddDriver from 'pages/landing/Equipment/DialogueModals/AddDriver/AddDriver';
import DriverInfo from 'pages/landing/Equipment/DialogueModals/DriverInfo/DriverInfo';
import RemoveDriver from 'pages/landing/Equipment/DialogueModals/DriverInfo/RemoveDriver';
import AddDriverSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddDriverMsg';
import RemovedDriverMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/RemoveDriverSuccess';
import style from '../../EquipmentProfile.module.css';

const EquipmentProfileDrivers = ({ profileData, init }) => {
  const [addDriver, setAddDriver] = useState(false);
  const RemoveDriverModalClose = () => setRemovedDrivermsg(false);
  const [delDriver, setDeleteDriver] = useState(false);
  const addDriverModalClose = () => setAddDriver(false);
  const [removeDrivermsg, setRemovedDrivermsg] = useState(false);
  const DriversAddedmsgModalClose = () => setDriverAddedmsg(false);
  const [driverAddedmsg, setDriverAddedmsg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const deleteDriverModal = (id, rowId, fname, lname, Equipment_id, Equipment_title, status, equipment_id) => {
    handleClose();
    setDeleteDriver(true);
    setRemoveDriverData([id, rowId, fname, lname, Equipment_id, Equipment_title, status, equipment_id]);
  };
  const deleteDriverModalClose = () => {
    setDeleteDriver(false);
  };

  const driver = 'equipment_profile Driver';

  const DriversAddedmsgModal = () => {
    setDriverAddedmsg(true);
    init(null, true);
    setTimeout(() => {
      DriversAddedmsgModalClose();
    }, 2000);
  };
  const RemoveDriverModal = () => {
    setRemovedDrivermsg(true);
    init(null, true);
    setTimeout(() => {}, 4000);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const addDriverModalShow = () => {
    setAddDriver(true);
  };

  const open = Boolean(anchorEl);

  const Popup = open ? 'simple-popover' : undefined;

  const [removeDriverData, setRemoveDriverData] = useState({ data: [] });
  return (
    <div>
      <div className='equipment-drivers-column fade-in-row mt-2 '>
        <div>
          {profileData?.drivers &&
            profileData?.drivers.map((d) => {
              return (
                <div className='d-flex align-items-center justify-content-between'>
                  <UserInfo
                    key={d.id}
                    size='26px'
                    statusSize='6px'
                    data={{
                      id: d.id,
                      image: d.image,
                      name: `${d.fname} ${d.lname}`,
                      email: d.email,
                      phone_number: d.phone_number,
                      status: d.status_data?.driver_status,
                      user_type: 'driver',
                    }}
                  />
                  <div
                    className={style['equipment-profile-driver-remove']}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();

                      deleteDriverModal(
                        d?.id,
                        profileData?.id,
                        d?.fname,
                        d?.lname,
                        profileData?.id,
                        profileData?.equipment_type?.title,
                        profileData.equipment_status.equipment_status,
                        profileData?.equipment_id
                      );
                    }}
                  >
                    Remove
                  </div>
                </div>
              );
            })}
          <div className='equipment-profile-group-box mt-2'>
            <div className='row-style mb-2 '>
              {!profileData?.drivers || profileData.drivers.length === 1 ? (
                <div className='add-driver'>
                  <div
                    className='d-flex '
                    onClick={($event) => {
                      addDriverModalShow(profileData.id);
                      $event.stopPropagation();
                    }}
                  >
                    <div className='add-driver-profile' style={{ border: '1px dashed #4F5AED' }}>
                      <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                          fill='#4f5aed'
                        />
                      </svg>
                    </div>
                    <span className='add-driver-content'>Add</span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal enforceFocus={false} show={addDriver} centered>
        <AddDriver
          vehicle={profileData}
          handleClose={addDriverModalClose}
          DriversAddedmsgModal={(id) => DriversAddedmsgModal(id)}
          drivers={profileData?.drivers?.map((i) => ({ value: i.id, label: `${i.fname} ${i.lname}` }))}
        />
      </Modal>
      <Modal enforceFocus={false} show={delDriver} centered className='remove-driver-dialogue-modal'>
        <RemoveDriver
          RemoveDriverData={removeDriverData}
          deleteDriverModalClose={deleteDriverModalClose}
          RemoveDriverModal={(id) => RemoveDriverModal(id)}
          RemoveDriverModalClose={RemoveDriverModalClose}
        />
      </Modal>
      <Popover
        id={Popup}
        anchorPosition={{ top: 200, left: 400 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DriverInfo
          driverData={{ data: [] }}
          PopupHandleClose={handleClose}
          deleteDriverModal={deleteDriverModal}
          driver={driver}
        />
      </Popover>
      <Toast show={driverAddedmsg} onClose={DriversAddedmsgModalClose} className='equipment-success-msg'>
        <div>
          <AddDriverSuccess DriversAddedmsgModalClose={DriversAddedmsgModalClose} />
        </div>
      </Toast>
      <Toast show={removeDrivermsg} onClose={RemoveDriverModalClose} className='equipment-success-msg'>
        <div>
          <RemovedDriverMsg RemoveDriverModalClose={RemoveDriverModalClose} />
        </div>
      </Toast>
    </div>
  );
};

export default EquipmentProfileDrivers;
