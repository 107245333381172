import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import AddStopPointModal from 'components/CreateShipment/CreateModals/addStopPointModal';
import { palette } from 'utils/constants';
import { customerStopPoint } from 'Api/Planner';

const StopPointAutocomplete = ({ options, label, labelProps, showAddStop, onAddStopPoint, ...props }) => {
  const [openAddStopPoint, setOpenAddStopPoint] = useState(false);
  const [stopPoints, setStopPoints] = useState([]);

  const getStopPoints = async () => {
    try {
      const { data } = await customerStopPoint(undefined, { only_active: 1 });
      setStopPoints(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (!options) {
      getStopPoints();
    }
  }, []);

  return (
    <div>
      {!!label && (
        <div className='d-flex justify-content-between align-items-center gap-3 mb-2'>
          <InputLabel className='mb-0' {...labelProps}>
            {label}
          </InputLabel>
          {!!showAddStop && (
            <Typography
              variant='s2'
              style={{ color: palette.indigo500 }}
              onClick={() => setOpenAddStopPoint(true)}
              className='pointer'
            >
              <PlusIcon /> Add Stop Point
            </Typography>
          )}
        </div>
      )}
      <Autocomplete
        placeholder='Select Stop Point'
        options={options || stopPoints}
        getOptionLabel={(option) =>
          `${option.location_name} - ${option.address1 || ''}, ${option.city?.name || ''}, ${
            option.state?.short_name || ''
          } ${option.zipcode || ''}`
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.location_name} - {option.address1 || ''}, {option.city?.name || ''},{' '}
            {option.state?.short_name || ''} {option.zipcode || ''}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        {...props}
      />
      {!!openAddStopPoint && (
        <AddStopPointModal
          show={openAddStopPoint}
          getCustomerStopPoint={onAddStopPoint}
          onChangeShow={setOpenAddStopPoint}
          wrapperClassName='position-fixed'
          containerClassName='add-stop-point-modal-body'
        />
      )}
    </div>
  );
};

export default StopPointAutocomplete;
