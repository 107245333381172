import React, { useState, useMemo, useEffect, useRef } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './DispatchVehicle.css';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import down from 'assets/icons/drivers/down.svg';
import headerIcon from 'assets/icons/vehicles/green.svg';
import subRight from 'assets/icons/vehicles/sub-right.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/expand.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ShipmentIcon } from 'assets/icons/vehicles/shipment.svg';

import Input from 'common/Input';
import UserInfo from 'components/UserInfo';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import AddressAutocomplete from 'common/AddressAutocomplete';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { formatAmount } from 'services/IFTAServices';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getMultipleShipmentsInfo, createMultipleDriverPay } from 'Api/Shipment';
import {
  getContactsBillingCharges,
  getCostCalculation,
  getDriverCost,
  getFullPrices,
  getPlannedShipments,
  getPreviousShipments,
  getShipmentStops,
  shipmentDispatch,
} from 'Api/Planner';
import { checkAddOnStatus } from 'Api/Equipment';
import { getShipmentDispatchSettings, getSMSCommunicationSettings } from 'Api/CompanySettings';
import ErrorMessage from 'common/ErrorMessage';
import ShipmentInfo from 'components/TablePlaner/helpers/ShipmentInfo';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { DateTimePicker } from 'common/Pickers';
import CostModal from './CostModal';
import DispatchMap from './DispatchMap';
import { dispatchFromOptions } from './helpers';
import { SAccordion, SWrapper } from './DispatchVehicle.styles';

const DispatchMultipleShipments = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [searchParams] = useSearchParams();
  const shipmentIds = searchParams.getAll('id');
  const equipmentId = searchParams.get('equipment');
  const selectList = [
    { name: 'Current Location', id: 1 },
    { name: 'Previous Shipment', id: 2 },
    { name: 'Planned Shipment', id: 3 },
    { name: 'Custom Location', id: 4 },
  ];
  const { currency } = useSelector((state) => state.root);
  const [routeSelect, setRouteSelect] = useState(Array(shipmentIds.length).fill('practical'));
  const [locationSelect, setLocationSelect] = useState(Array(shipmentIds.length).fill('Current Location'));
  const [customAddress, setCustomAddress] = useState(Array(shipmentIds.length).fill(''));
  const [customLatLong, setCustomLatLong] = useState(Array(shipmentIds.length).fill(null));
  const [customerContactBook, setCustomerContactBook] = useState([]);
  const [previousShipments, setPreviousShipments] = useState([]);
  const [previousShipment, setPreviousShipment] = useState(Array(shipmentIds.length).fill(null));
  const [planedShipments, setPlanedShipments] = useState([]);
  const [planedShipment, setPlanedShipment] = useState(Array(shipmentIds.length).fill(null));
  const [data, setData] = useState([]);
  const [shipmentStops, setShipmentStops] = useState(null);
  const [fuelPrice, setFuelPrice] = useState(null);
  const [loadingDispatch, setLoadingDispatch] = useState(false);
  const [showLicence, setShowLicence] = useState(false);
  const [shipmentsInfo, setShipmentsInfo] = useState([]);
  const [expandedShipment, setExpandedShipment] = useState(null);
  const { index } = expandedShipment || {};
  const [allowStaticMiles, setAllowStaticMiles] = useState(false);
  const [openCostModal, setOpenCostModal] = useState(false);
  const [costForms, setCostForms] = useState(Array(shipmentIds.length).fill([]));
  const initialRequestRef = useRef(false);
  const [smsSettings, setSmsSettings] = useState(null);

  const { values, handleChange, setValues } = useForm({
    initialValues: shipmentIds.map(() => ({
      immediately: true,
      in_future: false,
      future_date: null,
      after_specific_shipment_complated: false,
      before_scheduled_pickup: false,
      before_scheduled_pickup_hours: '0',
      send_trucking_updates_to_customer: false,
      send_trucking_link_to_customer: false,
      recipient_customer_contact_book_id: '',
      send_pickup_reminder_to_driver: false,
      send_pickup_reminder_to_driver_before_minutes: '30',
    })),
  });

  const {
    values: mapValues,
    handleChange: handleMapValuesChange,
    setValues: setMapValues,
  } = useForm({
    initialValues: [
      {
        route_type: 'practical',
        hwy_only: 0,
        open_borders: 0,
        haz_mat: 0,
        avoid_tolls: 0,
        show_traffic: 0,
        show_weather: 0,
        vehicle_type: 0,
      },
    ],
  });

  const handleRouteSelect = (route, index) => {
    setRouteSelect((prevState) => prevState.map((item, i) => (i === index ? route : item)));
    handleMapValuesChange(`${index}.route_type`, route);
  };

  const handleLocationSelect = (location, index) => {
    setPreviousShipment((prevState) => prevState.map((item, i) => (i === index ? null : item)));
    setPlanedShipment((prevState) => prevState.map((item, i) => (i === index ? null : item)));
    setCustomAddress((prevState) => prevState.map((item, i) => (i === index ? '' : item)));
    setCustomLatLong((prevState) => prevState.map((item, i) => (i === index ? null : item)));
    setLocationSelect((prevState) => prevState.map((item, i) => (i === index ? location : item)));
  };

  const onDispatch = async () => {
    if (
      values.some(
        (i) => !i.immediately && !i.in_future && !i.before_scheduled_pickup && !i.after_specific_shipment_complated
      )
    ) {
      showToaster({ type: 'error', message: 'One of the dispatch options should be selected!' });
      return;
    }

    if (data.some((item) => item.flatRateChecked && !item.flatRate)) {
      showToaster({
        type: 'error',
        message:
          'Please ensure that the driver cost is specified for each shipment when operating in multi-dispatch mode!',
      });
      setData((prevState) =>
        prevState.map((item) => (item.flatRateChecked && !item.flatRate ? { ...item, flatRateError: true } : item))
      );
      return;
    }

    if (
      locationSelect.some(
        (item, index) =>
          (item === 'Previous Shipment' && !previousShipment[index]?.shipment_id) ||
          (item === 'Planned Shipment' && !planedShipment[index]?.shipment_id) ||
          (item === 'Custom Location' && (!customLatLong[index]?.latitude || !customLatLong[index]?.longitude))
      )
    ) {
      showToaster({
        type: 'error',
        message: 'Please select "assign driver from" option for all shipments prior to dispatching!',
      });
      return;
    }

    try {
      setLoadingDispatch(true);
      const promises = data.map((data, index) => {
        const assign_driver_from = selectList.find((item) => item.name === locationSelect[index]).id;
        const { estimated_fuel_cost, net_profit, total_amount, empty_miles, loaded_miles } = data;

        const params = {
          shipment_id: shipmentIds[index],
          equipment_id: equipmentId,
          assign_driver_from,
          estimated_fuel_cost,
          net_profit: data.flatRateChecked
            ? +total_amount - (Number(data.flatRate) + Number(data?.estimated_fuel_cost))
            : net_profit,
          route_type: mapValues[index].route_type,
          hwy_only: Number(mapValues[index].hwy_only),
          open_borders: Number(mapValues[index].open_borders),
          avoid_tolls: Number(mapValues[index].avoid_tolls),
          haz_mat: Number(mapValues[index].haz_mat),
          driver1_amount:
            data.flatRateChecked && data?.driver2_cost
              ? Number(data.flatRate) / 2
              : data.flatRateChecked && !data?.driver2_cost
              ? Number(data.flatRate)
              : data?.driver1_cost,
          driver2_amount:
            data.flatRateChecked && data?.driver2_cost
              ? Number(data.flatRate) / 2
              : !data.flatRateChecked && data.driver2_cost
              ? data.driver2_cost
              : undefined,
          flat: data.flatRateChecked ? 1 : 0,
          company_acceptance: 1,
          immediately: Number(values[index].immediately),
          in_future: Number(values[index].in_future),
          send_trucking_link_to_customer: Number(values[index].send_trucking_link_to_customer),
          before_scheduled_pickup: Number(values[index].before_scheduled_pickup),
          after_specific_shipment_complated: Number(values[index].after_specific_shipment_complated),
          future_date: moment(values[index].future_date).format('YYYY-MM-DD HH:mm:ss'),
          before_scheduled_pickup_hours: values[index].before_scheduled_pickup_hours,
          recipient_customer_contact_book_id: values[index].recipient_customer_contact_book_id,
          send_pickup_reminder_to_driver: Number(values[index].send_pickup_reminder_to_driver),
          send_pickup_reminder_to_driver_before_minutes: Number(
            values[index].send_pickup_reminder_to_driver_before_minutes
          ),
          assign_driver_from_shipment:
            assign_driver_from === 2
              ? previousShipment[index]?.shipment_id
              : assign_driver_from === 3
              ? planedShipment[index]?.shipment_id
              : undefined,
          assign_driver_from_shipment_latitude:
            assign_driver_from === 4 && customLatLong[index] ? customLatLong[index]?.latitude : undefined,
          assign_driver_from_shipment_longitude:
            assign_driver_from === 4 && customLatLong[index] ? customLatLong[index]?.longitude : undefined,
          empty_miles: empty_miles || 0,
          loaded_miles: loaded_miles || 0,
          lane_id: shipmentsInfo[index].lane_id,
        };

        return shipmentDispatch(params);
      });

      await Promise.all(promises);

      const filteredShipmentIds = [];
      const filteredCostForms = [];
      costForms.forEach((form, index) => {
        if (form.length) {
          filteredShipmentIds.push(shipmentIds[index]);
          filteredCostForms.push(form);
        }
      });

      const costPromises = filteredCostForms.map((form, index) => {
        const body = {
          shipment_id: filteredShipmentIds[index],
          driver_id: new Array(form.length).fill(form[0]?.driver_id),
          type: form.map((el) => el.type),
          quantity: form.map((el) => el.qty),
          rate: form.map((el) => el.rate),
          total: form.map((el) => Number(el.qty) * Number(el.rate)),
          addition_id: form.map((el) => el.addition.id || null),
        };

        return createMultipleDriverPay(body);
      });

      await Promise.all(costPromises);

      setLoadingDispatch(false);
      const queryParams = new URLSearchParams();

      shipmentIds.forEach((id) => {
        queryParams.append('id', id);
      });

      navigate(`/shipments?${queryParams.toString()}`, { replace: true });
    } catch (err) {
      setLoadingDispatch(false);
      showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong' });
    }
  };

  const style = useMemo(() => {
    return {
      mapWrapper: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        height: '726px',
      },
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        borderRadius: '8px 0 0 8px',
      },
    };
  }, []);

  const getCostData = async () => {
    try {
      const promises = shipmentIds.map((shipmentId, index) => {
        const assign_driver_from = selectList.find((item) => item.name === locationSelect[index])?.id;
        const assignedFrom =
          (assign_driver_from === 2 && !previousShipment[index]?.shipment_id) ||
          (assign_driver_from === 3 && !planedShipment[index]?.shipment_id) ||
          (assign_driver_from === 4 && !customLatLong[index])
            ? 1
            : assign_driver_from;

        const params = {
          shipment_id: shipmentId,
          equipments: [equipmentId],
          assign_driver_from: assignedFrom,
          route_type: mapValues[index].route_type,
          hwy_only: mapValues[index].hwy_only ? 1 : 2,
          open_borders: mapValues[index].open_borders ? 1 : 2,
          avoid_tolls: mapValues[index].avoid_tolls ? 1 : 2,
          assign_driver_from_shipment:
            assignedFrom === 2 && previousShipment[index]?.shipment_id
              ? previousShipment[index]?.shipment_id
              : assignedFrom === 3 && planedShipment[index]?.shipment_id
              ? planedShipment[index]?.shipment_id
              : undefined,
          assign_driver_from_shipment_latitude:
            assignedFrom === 4 && customLatLong[index] ? customLatLong[index]?.latitude : undefined,
          assign_driver_from_shipment_longitude:
            assignedFrom === 4 && customLatLong[index] ? customLatLong[index]?.longitude : undefined,
        };
        return getCostCalculation(params) || null;
      });

      const responses = await Promise.all(promises);
      setData(
        responses.map((item, index) => ({
          ...item.data[0],
          flatRateChecked: !initialRequestRef.current
            ? item.data[0].equipment?.drivers[0]?.compensation_details?.pay_option === 'flat_rate'
            : data[index]?.flatRateChecked,
        }))
      );
      initialRequestRef.current = false;
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCostData();
  }, [shipmentIds?.length, equipmentId, mapValues, locationSelect, previousShipment, planedShipment, customLatLong]);

  const getFullPriceShipment = () => {
    return getFullPrices().then((res) => {
      if (res && res?.data) {
        setFuelPrice(res.data?.diesel?.price);
      }
      return res;
    });
  };

  const getShipmentSettings = async () => {
    try {
      setAllowStaticMiles(true);
    } catch (e) {
      // Do nothing
    }
  };

  const getSmsSettings = async () => {
    try {
      const { data } = await getSMSCommunicationSettings();
      setSmsSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDispatchSettings = async () => {
    try {
      const { data } = await getShipmentDispatchSettings();
      if (data) {
        setValues((prevState) =>
          prevState.map((item) => ({
            ...item,
            immediately: !!data.immediately,
            after_specific_shipment_complated: !!data.dispatch_next_in_line,
            before_scheduled_pickup: !!data.dispatch_driver_hours_before_scheduled,
            before_scheduled_pickup_hours: data.dispatch_driver_hours_before_scheduled || '0',
            send_trucking_link_to_customer: !!data.send_tracking_updates,
            send_pickup_reminder_to_driver: !!data.send_driver_reminder,
            send_pickup_reminder_to_driver_before_minutes: data.send_driver_reminder,
          }))
        );

        if (data?.driver_from_location) {
          setLocationSelect((prevState) =>
            prevState.map(() => dispatchFromOptions[Number(data?.driver_from_location)])
          );
        }
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    setCostForms((prevState) => prevState.map((item, i) => (i === index ? [] : item)));
  }, [data[index]?.flatRateChecked]);

  useEffect(() => {
    getMultipleShipmentsData();
    getFullPriceShipment();
    getShipmentSettings();
    getSmsSettings();
    getDispatchSettings();
  }, []);

  useEffect(() => {
    if (!expandedShipment) {
      return;
    }
    setShipmentStops(null);
    getShipmentStops(expandedShipment.shipment_id).then((res) => {
      setShipmentStops(res.data);
    });
  }, [expandedShipment]);

  useEffect(() => {
    if (data[0]?.equipment?.drivers[0]?.id) {
      const params = {
        driver_id: data[0]?.equipment?.drivers[0]?.id,
      };
      getPlannedShipments(params).then((res) => {
        setPlanedShipments(res.data);
      });
      getPreviousShipments(params).then((res) => {
        setPreviousShipments(res.data);
        if (locationSelect[index] === 'Previous Shipment') {
          setPreviousShipment((prevState) => prevState.map(() => res.data?.[0] || null));
        }
      });
    }
  }, [data[0]?.equipment?.drivers[0]?.id]);

  const getMultipleShipmentsData = async () => {
    try {
      const { data } = await getMultipleShipmentsInfo({ shipment_id: shipmentIds });
      const sortedData = shipmentIds.map((id) => data.find((item) => item.shipment_id === id));
      setShipmentsInfo(sortedData);
      setExpandedShipment({ ...sortedData[0], index: 0 });

      const initialMapValues = {
        route_type: 'practical',
        hwy_only: 0,
        open_borders: 0,
        haz_mat: 0,
        avoid_tolls: 0,
        show_traffic: 0,
        show_weather: 0,
        vehicle_type: 0,
      };

      const updatedMapValues = sortedData.map((item) => ({
        ...initialMapValues,
        open_borders: item.shipment_route?.open_borders,
        hwy_only: item.shipment_route?.highway_only,
        haz_mat: item.shipment_route?.hazmat_id,
        route_type: item.shipment_route?.route_type,
        vehicle_type: item.shipment_route?.vehicle_type,
        avoid_tolls: item.shipment_route?.avoid_tolls,
      }));
      setMapValues(updatedMapValues);

      setRouteSelect(sortedData.map((item) => item.shipment_route?.route_type));
    } catch (e) {
      // Do nothing
    }
  };

  function onBackPlanner() {
    navigate(`/planner/plan?${searchParams.toString()}`);
  }

  useEffect(() => {
    if (shipmentStops?.shipment_billing[0]?.billing_customer?.id) {
      getContactsBillingCharges(shipmentStops?.shipment_billing[0]?.billing_customer?.id).then((res) => {
        setCustomerContactBook(res.data);
      });
    }
  }, [shipmentStops?.shipment_billing[0]?.billing_customer?.id]);

  useEffect(() => {
    if (costForms[index]?.length && costForms[index]?.[0]?.type === 'empty_miles') {
      setData((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
                ...item,
                empty_miles: Number(costForms[index]?.[0].qty),
                loaded_miles: Number(costForms[index]?.[1].qty),
                driver_cost: costForms[index].reduce((acc, cur) => acc + Number(cur.total), 0),
              }
            : item
        )
      );
    }

    if (
      (costForms[index]?.length && costForms[index]?.[0]?.type === 'per_hour') ||
      (costForms[index]?.length && costForms[index]?.[0]?.type === 'percentage')
    ) {
      setData((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
                ...item,
                driver_cost: costForms[index].reduce((acc, cur) => acc + Number(cur.total), 0),
              }
            : item
        )
      );
    }

    if (costForms[index]?.length && costForms[index]?.[0]?.type === 'flat_rate') {
      setData((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
                ...item,
                flatRate: costForms[index].reduce((acc, cur) => acc + Number(cur.total), 0),
              }
            : item
        )
      );
    }
  }, [costForms[index]]);

  const renderTooltip = (driver, data) => {
    if (data?.equipment?.drivers.length > 1) {
      return (
        <Tooltip>
          {driver.fname} {driver.lname}
        </Tooltip>
      );
    }
    return <span />;
  };

  const checkAddonStatus = (value, checked, index) => {
    checkAddOnStatus(2, data[index]?.equipment?.id)
      .then((res) => {
        if (res.is_addon_active) {
          handleMapValuesChange(`${index}.${value}`, checked);
        } else {
          setShowLicence(true);
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong!' });
      });
  };

  const onAddressSelect = async (data, index) => {
    const res = await getGeoLocation(data);
    if (res.latitude && res.longitude) {
      setCustomLatLong((prevState) => prevState.map((item, i) => (i === index ? res : item)));
    }
  };

  const getGeoLocation = async (value) => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          addr: value.address,
          city: value.city?.name,
          state: value.state.name,
          zip: value.zip,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                latitude: el.Coords?.Lat,
                longitude: el.Coords?.Lon,
              };
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          showToaster({ type: 'error', message: 'Could not find location' });
          resolve(null);
        },
      });
    });
  };

  const changeAddress = ({ formatted_address }, index) => {
    setCustomAddress((prevState) => prevState.map((item, i) => (i === index ? formatted_address : item)));
  };

  const calculateDriverCost = async (params) => {
    try {
      const { data } = await getDriverCost(params);
      setData((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
                ...item,
                driver_cost: Number(data?.driver_cost?.total.replaceAll(',', '')) || 0,
                estimated_fuel_cost: Number(data?.estimated_fuel_cost),
              }
            : item
        )
      );
    } catch (e) {
      // Do nothing
    }
  };

  const onEmptyMilesChange = (e) => {
    if (Number(e.target.value) !== data[index].empty_miles) {
      setData((prevState) =>
        prevState.map((item, i) => (i === index ? { ...item, empty_miles: Number(e.target.value) } : item))
      );
      const params = {
        driver_id: data[index].equipment.drivers[0].id,
        equipments: [equipmentId],
        loaded_miles: data[index].loaded_miles,
        empty_miles: Number(e.target.value),
        shipment_amount: data[index].total_amount,
        total_eta: data[index].total_eta,
      };
      calculateDriverCost(params);
    }
  };

  const onLoadedMilesChange = (e) => {
    if (Number(e.target.value) !== data[index].loaded_miles) {
      setData((prevState) =>
        prevState.map((item, i) => (i === index ? { ...item, loaded_miles: Number(e.target.value) } : item))
      );
      const params = {
        driver_id: data[index].equipment.drivers[0].id,
        equipments: [equipmentId],
        loaded_miles: Number(e.target.value),
        empty_miles: data[index].empty_miles,
        shipment_amount: data[index].total_amount,
        total_eta: data[index].total_eta,
      };
      calculateDriverCost(params);
    }
  };

  const handleExpand = (shipment, index) => {
    setExpandedShipment({ ...shipment, index });
  };

  return (
    <div className='profile-container'>
      {showLicence && (
        <div className='check-addon-dispatch' onClick={() => setShowLicence(false)}>
          You do not have this feature enabled.
          <span onClick={() => navigate('/billing?addonId=2&planId=34')}> Purchase Addon </span>
        </div>
      )}
      <div className='user-profile-header w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='dispatch-vehicle-container w-100'>
          <div className='plan-vehicle-text-wrapper'>
            <div onClick={onBackPlanner} className='back_plan_to_vehicle'>
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className='back'>
                Plan to Vehicle
              </Typography>
            </div>
          </div>
          {shipmentStops?.is_edi_api || shipmentStops?.manual_edi_api ? (
            <div className='dispatch-vehicle-text-wrapper'>
              <div className='d-flex'>
                <img src={headerIcon} alt='headerIcon' />
                <p className='dispatch-vehicle-text green-text'>EDI/API SHIPMENT </p>
              </div>
            </div>
          ) : null}

          <div className='d-flex '>
            <div className='dispatch-vehicle-details w-100'>
              <div className='dispatch-vehicle-details-item border-0'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Multi-Shipment Dispatch to Vehicle
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SWrapper>
        {!!shipmentsInfo.length &&
          shipmentsInfo.map((shipment, index) => (
            <SAccordion
              key={shipment.id}
              expanded={expandedShipment?.shipment_id === shipment.shipment_id}
              onChange={() => handleExpand(shipment, index)}
            >
              <AccordionSummary
                expandIcon={<ArrowUp width={16} className='accordion-arrow' />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                Shipment {shipment.shipment_id}
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <ShipmentInfo shipment={shipment} />
                </div>
              </AccordionDetails>
            </SAccordion>
          ))}
      </SWrapper>

      <div className='w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='table-wrap'>
          <div className='sub-table-container-dispatch'>
            {!!expandedShipment && !!data[index] ? (
              <MaterialTableWrapper
                columns={[
                  {
                    title: 'Vehicle',
                    field: 'VehicleID',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Trailer',
                    field: 'Trailer',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                      minWidth: '200px',
                    },
                  },
                  {
                    title: 'Driver',
                    field: 'Driver',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Compensation',
                    field: 'Compensation',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Assign Driver From',
                    field: 'AssignDriverFrom',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Empty Miles',
                    field: 'EmptyMiles',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Loaded Miles',
                    field: 'LoadedMiles',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'ETA to stop a',
                    field: 'ETA',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                  {
                    title: 'Cost',
                    field: 'Cost',
                    headerStyle: {
                      borderTop: 'none',
                    },
                    cellStyle: {
                      verticalAlign: 'top',
                    },
                  },
                ]}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  borderTop: 'none',
                  width: '100%',
                  minHeight: 200,
                }}
                data={[
                  {
                    VehicleID: (
                      <div>
                        <p className='shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                          {data[index]?.equipment?.equipment_id}
                          <small> {data[index]?.equipment?.equipment_type?.title}</small>
                        </p>
                      </div>
                    ),
                    Trailer: (
                      <div className='d-flex flex-column'>
                        <div className='equipment-container d-flex align-items-center'>
                          <span
                            className='dispatch-details-text'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                            }}
                          >
                            {' '}
                            {data[index]?.equipment?.hooked_to?.equipment_number}
                          </span>
                          &nbsp;
                          <span className='dispatch-details-text'>
                            {' '}
                            {data[index]?.equipment?.hooked_to?.equipment_type?.title}{' '}
                          </span>
                        </div>
                      </div>
                    ),
                    Driver: data[index]?.equipment?.drivers.map((d) => {
                      return (
                        <UserInfo
                          key={d.id}
                          data={{
                            ...d,
                            name: `${d.fname} ${d.lname}`,
                            status: d.status_data?.driver_status,
                            user_type: 'driver',
                          }}
                        />
                      );
                    }),
                    Compensation: (
                      <>
                        {data[index]?.equipment?.drivers.map(
                          (driver) =>
                            driver?.compensation_details?.pay_option === 'mile' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Pay Per Mile</Typography>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.mile_loaded_amt)} Loaded
                                  </span>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.mile_empty_amt)} Empty
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )
                        )}
                        {data[index]?.equipment?.drivers.map(
                          (driver) =>
                            driver?.compensation_details?.pay_option === 'percentage' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Percentage</Typography>
                                  <span>{driver?.compensation_details?.shipment_percentage}% of Shipment</span>
                                </div>
                              </OverlayTrigger>
                            )
                        )}

                        {data[index]?.equipment?.drivers.map(
                          (driver) =>
                            driver?.compensation_details?.pay_option === 'per_hour' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Per Hour</Typography>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.per_hour_worked)} Per Hour
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )
                        )}
                        {data[index]?.equipment?.drivers.map(
                          (driver) =>
                            driver?.compensation_details?.pay_option === 'flat_rate' && (
                              <OverlayTrigger
                                key={uuid()}
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(driver)}
                              >
                                <div className='d-flex flex-column'>
                                  <Typography variant='s2'>Flat Rate</Typography>
                                  <span>
                                    {currency}
                                    {formatAmount(driver?.compensation_details?.flat_rate_amount)}
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )
                        )}
                      </>
                    ),
                    AssignDriverFrom: (
                      <div style={{ minHeight: 120, minWidth: 280 }}>
                        <div className='d-flex align-items-center'>
                          <Dropdown className='d-inline'>
                            <Dropdown.Toggle
                              // className="AvailableButton btn btn-link"
                              id='dropdown-autoclose-outside'
                              style={{
                                border: 'none',
                                outline: 'none',
                                background: 'none',
                                boxShadow: 'none',
                                marginLeft: '0',
                                paddingLeft: '0',
                                height: 40,
                              }}
                            >
                              <div className='d-flex align-items-center'>
                                <p
                                  className='table-data-text'
                                  style={{
                                    color: use(palette.gray700, palette.gray200),
                                    fontSize: 14,
                                  }}
                                >
                                  {locationSelect[index]}
                                </p>
                                <img src={down} className='ms-2' alt='down' />
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className='dropdown-location'
                              style={{ backgroundColor: use(palette.white, palette.dark800) }}
                            >
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                style={{ color: use(palette.dark800, palette.gray200) }}
                                onClick={() => handleLocationSelect('Current Location', index)}
                              >
                                Current Location
                              </Dropdown.Item>
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                onClick={() => handleLocationSelect('Previous Shipment', index)}
                                style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                Previous Shipment
                              </Dropdown.Item>
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                onClick={() => handleLocationSelect('Planned Shipment', index)}
                                style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                Planned Shipment
                              </Dropdown.Item>
                              <Dropdown.Item
                                className='outofserviceDropDownItemsList'
                                onClick={() => handleLocationSelect('Custom Location', index)}
                                style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                Custom Location
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {locationSelect[index] === 'Previous Shipment' && (
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={subRight}
                                className='sub-right-icon'
                                alt={subRight}
                                style={{ display: 'inline-block' }}
                              />
                              <Autocomplete
                                options={previousShipments}
                                value={previousShipment[index]}
                                onChange={(e, value) => {
                                  setPreviousShipment((prevState) =>
                                    prevState.map((item, i) => (i === index ? value : item))
                                  );
                                }}
                                getOptionLabel={(option) =>
                                  `Shipment ${option.shipment_id} (${
                                    option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                                  }, ${
                                    option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state
                                      ?.short_name
                                  })`
                                }
                                id='select_list'
                                name='list'
                              />
                            </div>
                            <div className='mt-2'>
                              {previousShipment[index]?.shipment_stops?.length && (
                                <>
                                  <p className='dispatch-details-text'>
                                    {previousShipment[index].shipment_stops[
                                      previousShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.address1
                                      ? `${
                                          previousShipment[index].shipment_stops[
                                            previousShipment[index].shipment_stops.length - 1
                                          ].stop_point.address1
                                        },`
                                      : ''}
                                    {previousShipment[index].shipment_stops[
                                      previousShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.address2
                                      ? `${
                                          previousShipment[index].shipment_stops[
                                            previousShipment[index].shipment_stops.length - 1
                                          ].stop_point.address2
                                        },`
                                      : ''}
                                  </p>
                                  <p className='dispatch-details-text'>
                                    {previousShipment[index].shipment_stops[
                                      previousShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.state?.name
                                      ? `${
                                          previousShipment[index].shipment_stops[
                                            previousShipment[index].shipment_stops.length - 1
                                          ].stop_point.state.name
                                        },`
                                      : ''}
                                    {previousShipment[index].shipment_stops[
                                      previousShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.city?.name
                                      ? `${
                                          previousShipment[index].shipment_stops[
                                            previousShipment[index].shipment_stops.length - 1
                                          ].stop_point.city.name
                                        },`
                                      : ''}
                                    {previousShipment[index].shipment_stops[
                                      previousShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.zipcode
                                      ? previousShipment[index].shipment_stops[
                                          previousShipment[index].shipment_stops.length - 1
                                        ].stop_point.zipcode
                                      : ''}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {locationSelect[index] === 'Planned Shipment' && (
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={subRight}
                                className='sub-right-icon'
                                alt={subRight}
                                style={{ display: 'inline-block' }}
                              />
                              <Autocomplete
                                options={planedShipments}
                                value={planedShipment[index]}
                                onChange={(e, value) => {
                                  setPlanedShipment((prevState) =>
                                    prevState.map((item, i) => (i === index ? value : item))
                                  );
                                }}
                                getOptionLabel={(option) =>
                                  `Shipment ${option.shipment_id} (${
                                    option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                                  }, ${
                                    option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state
                                      ?.short_name
                                  })`
                                }
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <Typography variant='c1'>
                                      {`Shipment ${option.shipment_id} (${
                                        option?.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.city?.name
                                      },${
                                        option.shipment_stops[option.shipment_stops.length - 1]?.stop_point?.state
                                          ?.short_name
                                      })`}
                                    </Typography>
                                  </li>
                                )}
                                id='select_list'
                                name='list'
                              />
                            </div>
                            <div className='mt-2'>
                              {planedShipment[index]?.shipment_stops?.length && (
                                <>
                                  <p className='dispatch-details-text'>
                                    {planedShipment[index].shipment_stops[
                                      planedShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.address1
                                      ? `${
                                          planedShipment[index].shipment_stops[
                                            planedShipment[index].shipment_stops.length - 1
                                          ].stop_point.address1
                                        },`
                                      : ''}
                                    {planedShipment[index].shipment_stops[
                                      planedShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.address2
                                      ? `${
                                          planedShipment[index].shipment_stops[
                                            planedShipment[index].shipment_stops.length - 1
                                          ].stop_point.address2
                                        },`
                                      : ''}
                                  </p>
                                  <p className='dispatch-details-text'>
                                    {planedShipment[index].shipment_stops[
                                      planedShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.state?.name
                                      ? `${
                                          planedShipment[index].shipment_stops[
                                            planedShipment[index].shipment_stops.length - 1
                                          ].stop_point.state.name
                                        },`
                                      : ''}
                                    {planedShipment[index].shipment_stops[
                                      planedShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.city?.name
                                      ? `${
                                          planedShipment[index].shipment_stops[
                                            planedShipment[index].shipment_stops.length - 1
                                          ].stop_point.city.name
                                        },`
                                      : ''}
                                    {planedShipment[index].shipment_stops[
                                      planedShipment[index].shipment_stops.length - 1
                                    ]?.stop_point?.zipcode
                                      ? planedShipment[index].shipment_stops[
                                          planedShipment[index].shipment_stops.length - 1
                                        ].stop_point.zipcode
                                      : ''}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {locationSelect[index] === 'Current Location' && (
                          <p className='dispatch-details-text'>
                            {data[index]?.equipment?.location_target === 'Equipment'
                              ? `${data[index].equipment?.equipment_gps?.location}`
                              : `${data[index]?.equipment?.drivers[0]?.driver_gps?.location}`}
                          </p>
                        )}
                        {locationSelect[index] === 'Custom Location' && (
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={subRight}
                                className='sub-right-icon'
                                alt={subRight}
                                style={{ display: 'inline-block' }}
                              />
                              <AddressAutocomplete
                                placeholder='Search Custom Location'
                                onSuccess={(data) => onAddressSelect(data, index)}
                                changeAddress={(data) => changeAddress(data, index)}
                                onChange={(e) => {
                                  setCustomAddress((prevState) =>
                                    prevState.map((item, i) => (i === index ? e.target.value : item))
                                  );
                                }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                  }
                                }}
                                value={customAddress[index]}
                                options={{
                                  types: ['geocode', 'establishment'],
                                  componentRestrictions: { country: ['us', 'ca', 'mx'] },
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                    EmptyMiles: (
                      <div>
                        {allowStaticMiles ? (
                          <Input
                            size='small'
                            type='number'
                            name='equipment_id'
                            onChange={onEmptyMilesChange}
                            value={Number(data[index]?.empty_miles).toFixed()}
                            onKeyDown={blockNonPositiveNumbers}
                          />
                        ) : (
                          <Typography variant='s2'>{Number(data[index]?.empty_miles).toFixed()}</Typography>
                        )}
                      </div>
                    ),
                    LoadedMiles: (
                      <div>
                        {allowStaticMiles ? (
                          <Input
                            size='small'
                            type='number'
                            name='equipment_id'
                            onChange={onLoadedMilesChange}
                            value={Number(data[index]?.loaded_miles).toFixed()}
                            onKeyDown={blockNonPositiveNumbers}
                          />
                        ) : (
                          <Typography variant='s2'>{Number(data[index]?.loaded_miles).toFixed()}</Typography>
                        )}
                      </div>
                    ),
                    ETA: (
                      <div>
                        <p className='dispatch-details-text'>{data[index]?.eta}</p>
                        <p className='dispatch-details-text'>
                          {data[index]?.current_location?.stop_point?.address1
                            ? `${data[index]?.current_location?.stop_point?.address1},`
                            : ''}
                          {data[index]?.current_location?.stop_point?.address2
                            ? `${data[index]?.current_location?.stop_point?.address2},`
                            : ''}
                        </p>
                        <p className='dispatch-details-text'>
                          {data[index]?.current_location?.stop_point?.state?.name
                            ? `${data[index]?.current_location?.stop_point?.state?.name},`
                            : ''}
                          {data[index]?.current_location?.stop_point?.city?.name
                            ? `${data[index]?.current_location?.stop_point?.city?.name},`
                            : ''}
                          {data[index]?.current_location?.stop_point?.zipcode
                            ? data[index]?.current_location?.stop_point?.zipcode
                            : ''}
                        </p>
                      </div>
                    ),
                    Cost: (
                      <div>
                        {data[index]?.equipment.drivers[0].compensation_details.pay_option === 'flat_rate' ||
                        data[index].flatRateChecked ? (
                          <div>
                            <div className='d-flex align-items-center gap-2'>
                              <CustomInput
                                value={data[index]?.flatRate || ''}
                                labelStyle={{ margin: 0 }}
                                onChange={(e) =>
                                  setData((prevState) =>
                                    prevState.map((item, i) =>
                                      i === index ? { ...item, flatRate: e.target.value, flatRateError: false } : item
                                    )
                                  )
                                }
                                style={{ width: 100, borderRadius: 6, height: 30 }}
                              />
                              {allowStaticMiles && <EditIcon onClick={() => setOpenCostModal(true)} />}
                            </div>
                            <ErrorMessage error={data[index]?.flatRateError && 'Required'} />
                          </div>
                        ) : (
                          <div className='d-flex align-items-center gap-2'>
                            <p
                              className='dispatch-details-text'
                              style={{ color: use(palette.gray700, palette.gray200) }}
                            >
                              {currency}
                              {formatAmount(
                                Number(
                                  data[index]?.driver_cost ||
                                    Number(data[index]?.driver1_cost) + Number(data[index]?.driver2_cost)
                                ).toFixed(2)
                              )}
                            </p>
                            {allowStaticMiles && <EditIcon onClick={() => setOpenCostModal(true)} />}
                          </div>
                        )}

                        <div>
                          <CustomCheckbox
                            checked={data[index]?.flatRateChecked}
                            onChange={(value) =>
                              setData((prevState) =>
                                prevState.map((item, i) => (i === index ? { ...item, flatRateChecked: value } : item))
                              )
                            }
                            disabled={
                              data[index]?.equipment?.drivers[0]?.compensation_details?.pay_option === 'flat_rate'
                            }
                          >
                            <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                              Flat Rate
                            </Typography>
                          </CustomCheckbox>
                        </div>
                      </div>
                    ),
                  },
                ]}
                options={{
                  paging: false,
                  sorting: false,
                  toolbar: false,
                  rowStyle: {
                    borderBottom: 'none',
                    width: '100%',
                  },
                  headerStyle: {
                    borderTop: 'none',
                    width: '100%',
                  },
                }}
              />
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      </div>
      {!!expandedShipment && !!data[index] && (
        <div className='dispatch-vehicle-content'>
          <div className='dispatch-vehicle-data'>
            <div>
              <form>
                <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                  Options
                </h6>
                <div className='with-border mt-2' />
                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].immediately}
                    name='immediately'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.in_future`, false);
                        handleChange(`${index}.after_specific_shipment_complated`, false);
                        handleChange(`${index}.before_scheduled_pickup`, false);
                      } else if (
                        !values[index].in_future &&
                        !values[index].after_specific_shipment_complated &&
                        !values[index].before_scheduled_pickup
                      ) {
                        return;
                      }
                      handleChange(`${index}.immediately`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text'>Dispatch to Driver Immediately</p>
                </div>
                <div className='with-border mt-2' />

                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].after_specific_shipment_complated}
                    name='after_specific_shipment_complated'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.immediately`, false);
                        handleChange(`${index}.in_future`, false);
                        handleChange(`${index}.before_scheduled_pickup`, false);
                      } else if (
                        !values[index].immediately &&
                        !values[index].in_future &&
                        !values[index].before_scheduled_pickup
                      ) {
                        return;
                      }
                      handleChange(`${index}.after_specific_shipment_complated`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text me-2'>
                    Pending Dispatch as Next Shipment in Sequential Date Order
                  </p>
                </div>
                <div className='with-border mt-2' />

                <div className='d-flex align-items-center my-2 w-100'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].in_future}
                    name='in_future'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.immediately`, false);
                        handleChange(`${index}.after_specific_shipment_complated`, false);
                        handleChange(`${index}.before_scheduled_pickup`, false);
                      } else if (
                        !values[index].immediately &&
                        !values[index].after_specific_shipment_complated &&
                        !values[index].before_scheduled_pickup
                      ) {
                        return;
                      }
                      handleChange(`${index}.in_future`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text me-2'>Pending Dispatch Queue for Dispatch on</p>
                  <DateTimePicker
                    width='190px'
                    name='future_date'
                    value={values[index].future_date}
                    onChange={(value) => handleChange(`${index}.future_date`, value)}
                    onBlur={(e) => {
                      const { value } = e.target;
                      if (value && moment(value).isValid() && moment(value).isBefore(moment())) {
                        handleChange(`${index}.future_date`, new Date());
                      }
                    }}
                    disablePast
                  />
                  <p className='dispatch-vehicle-check-text'>(Based on Specific Date/Time)</p>
                </div>
                <div className='with-border mt-2' />

                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].before_scheduled_pickup}
                    name='before_scheduled_pickup'
                    onChange={(checked) => {
                      if (checked) {
                        handleChange(`${index}.immediately`, false);
                        handleChange(`${index}.in_future`, false);
                        handleChange(`${index}.after_specific_shipment_complated`, false);
                      } else if (
                        !values[index].immediately &&
                        !values[index].in_future &&
                        !values[index].after_specific_shipment_complated
                      ) {
                        return;
                      }
                      handleChange(`${index}.before_scheduled_pickup`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text'>Pending Dispatch Queue for Dispatch</p>
                  <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                    <input
                      type='number'
                      placeholder='0'
                      name='before_scheduled_pickup_hours'
                      value={values[index].before_scheduled_pickup_hours}
                      onChange={(e) => handleChange(`${index}.before_scheduled_pickup_hours`, e.target.value)}
                    />
                  </div>
                  <p className='dispatch-vehicle-check-text'>Hour(s) Prior to Scheduled Pickup</p>
                </div>
                <div className='with-border mt-2' />
                {customerContactBook && (
                  <>
                    <div className='d-flex align-items-center my-2'>
                      <CustomCheckbox
                        type='switch'
                        checked={values[index].send_trucking_link_to_customer}
                        name='send_trucking_link_to_customer'
                        onChange={(checked) => handleChange(`${index}.send_trucking_link_to_customer`, checked)}
                      />
                      <p className='dispatch-vehicle-check-text'>Send Tracking Updates to Customer Contact</p>
                      <CustomSelect
                        options={customerContactBook}
                        defaultValue='Select Contact...'
                        onChange={(value) => handleChange(`${index}.recipient_customer_contact_book_id`, value)}
                        styles={{ maxWidth: 200, marginLeft: '0.5rem', marginTop: 0 }}
                        id='select_list'
                        name='recipient_customer_contact_book_id'
                        propertyName='contact_name'
                        patchProperty='id'
                      />
                    </div>
                    <div className='with-border mt-2' />
                  </>
                )}
                <div className='d-flex align-items-center my-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={values[index].send_pickup_reminder_to_driver}
                    name={`${index}.send_pickup_reminder_to_driver`}
                    onChange={(checked) => {
                      if (!smsSettings?.send_sms) {
                        showToaster({
                          type: 'error',
                          message:
                            'Send SMS option is disabled for this account, please enable it from settings before using this option!',
                        });
                        return;
                      }
                      handleChange(`${index}.send_pickup_reminder_to_driver`, checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text'>Send Driver SMS Reminder</p>
                  <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                    <input
                      type='number'
                      placeholder='0'
                      name='send_pickup_reminder_to_driver_before_minutes'
                      value={values[index].send_pickup_reminder_to_driver_before_minutes}
                      onChange={(e) =>
                        handleChange(`${index}.send_pickup_reminder_to_driver_before_minutes`, e.target.value)
                      }
                    />
                  </div>
                  <p className='dispatch-vehicle-check-text'>Minute(s) Prior to Scheduled Pickup</p>
                </div>
              </form>

              <div className='with-border mt-2' />
              <div>
                <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                  Cost Estimate
                </h6>
                <div className='with-border mt-2' />
                <div className='dispatch-vehicle-cost'>
                  <div>
                    <p className='dispatch-vehicle-cost-text'>Driver Costs</p>
                    <p className='dispatch-vehicle-cost-text'>
                      Estimated Fuel Costs{' '}
                      <span className='cost-purple-text'> ${(+fuelPrice).toFixed(2)} /GAL DIESEL</span>
                    </p>
                    <p className='dispatch-vehicle-cost-text cost-bold-text'>Estimated Costs</p>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <p className='dispatch-vehicle-cost-text'>
                      {currency}
                      {data[index].flatRateChecked && data[index].flatRate
                        ? formatAmount(data[index].flatRate)
                        : formatAmount(
                            data[index]?.driver_cost ??
                              Number(data[index]?.driver1_cost) + Number(data[index]?.driver2_cost)
                          )}
                    </p>
                    <p className='dispatch-vehicle-cost-text'>
                      {currency}
                      {formatAmount(data[index]?.estimated_fuel_cost)}
                    </p>
                    <p className='dispatch-vehicle-cost-text cost-bold-text'>
                      {currency}
                      {data[index].flatRateChecked && data[index].flatRate
                        ? formatAmount(+data[index].flatRate + Number(data[index]?.estimated_fuel_cost))
                        : formatAmount(data[index]?.cost)}
                    </p>
                  </div>
                </div>
              </div>
              <div className='with-border mt-2' />
              <div>
                <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                  Net Profit
                </h6>
                <div className='with-border mt-2' />
                <div className='dispatch-vehicle-cost'>
                  <div>
                    <p className='dispatch-vehicle-cost-text'>
                      Gross Shipment Charges{' '}
                      <span className='profit-gray-text'>
                        {currency}
                        {formatAmount(
                          Number(data[index]?.empty_miles) || Number(data[index]?.loaded_miles)
                            ? Number(data[index]?.total_amount) /
                                (Number(data[index]?.empty_miles) + Number(data[index]?.loaded_miles))
                            : 0
                        )}{' '}
                        /MILE EST. GROSS
                      </span>
                    </p>
                    <p className='dispatch-vehicle-cost-text'>
                      Estimated Shipment Costs{' '}
                      <span className='profit-red-text'>
                        {currency}
                        {data[index]?.flatRateChecked && data[index]?.flatRate
                          ? formatAmount(
                              Number(data[index]?.empty_miles) || Number(data[index]?.loaded_miles)
                                ? (Number(data[index]?.flatRate) + Number(data[index]?.estimated_fuel_cost)) /
                                    (Number(data[index]?.empty_miles) + Number(data[index]?.loaded_miles))
                                : 0
                            )
                          : formatAmount(
                              Number(data[index]?.empty_miles) || Number(data[index]?.loaded_miles)
                                ? (Number(data[index]?.driver_cost ?? data[index]?.cost) +
                                    Number(data[index]?.estimated_fuel_cost)) /
                                    (Number(data[index]?.empty_miles) + Number(data[index]?.loaded_miles))
                                : 0
                            )}{' '}
                        /MILE EST. COST
                      </span>
                    </p>
                    <p className='dispatch-vehicle-cost-text cost-green-text'>
                      Estimated Net Profit{' '}
                      <span className='profit-green-text'>
                        {currency}
                        {data[index]?.flatRateChecked && data[index]?.flatRate
                          ? formatAmount(
                              Number(data[index]?.empty_miles) || Number(data[index]?.loaded_miles)
                                ? (Number(data[index]?.total_amount) -
                                    (Number(data[index]?.flatRate) + Number(data[index]?.estimated_fuel_cost))) /
                                    (Number(data[index]?.empty_miles) + Number(data[index]?.loaded_miles))
                                : 0
                            )
                          : formatAmount(
                              Number(data[index]?.empty_miles) || Number(data[index]?.loaded_miles)
                                ? Number(data[index]?.net_profit) /
                                    (Number(data[index]?.empty_miles) + Number(data[index]?.loaded_miles))
                                : 0
                            )}{' '}
                        /MILE EST. NET
                      </span>
                    </p>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <p className='dispatch-vehicle-cost-text'>
                      {currency}
                      {formatAmount(data[index]?.total_amount)}
                    </p>
                    <p className='dispatch-vehicle-cost-text'>
                      {currency}
                      {data[index]?.flatRateChecked && data[index]?.flatRate
                        ? formatAmount(+data[index].flatRate + Number(data[index]?.estimated_fuel_cost))
                        : formatAmount(data[index]?.cost)}
                    </p>
                    <p className='dispatch-vehicle-cost-text cost-green-text'>
                      {currency}
                      {data[index]?.flatRateChecked && data[index]?.flatRate
                        ? formatAmount(
                            Number(data[index]?.total_amount) -
                              (+data[index].flatRate + Number(data[index]?.estimated_fuel_cost))
                          )
                        : formatAmount(data[index]?.net_profit)}
                    </p>
                  </div>
                </div>
              </div>
              <div className='with-border mt-2' />
              <div className='d-flex justify-content-end mt-4 mb-4'>
                <CustomButton
                  width={12}
                  height={12}
                  type='secondary'
                  title='Back'
                  styleButton={{ padding: '6px 12px', marginRight: '18px' }}
                  onClick={() => navigate(-1)}
                />
                <CustomButton
                  width={12}
                  height={12}
                  type='primary'
                  title='Dispatch Vehicle'
                  leftIcon={
                    loadingDispatch ? (
                      <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                    ) : (
                      <ShipmentIcon style={{ marginRight: 10 }} />
                    )
                  }
                  disabled={loadingDispatch}
                  styleButton={{ padding: '6px 12px' }}
                  onClick={onDispatch}
                />
              </div>
            </div>
          </div>
          <div className='dispatch-vehicle-map'>
            <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
              Route
            </h6>
            <div className='with-border mt-2' />
            <div className='d-flex justify-content-between align-items-center my-2'>
              <div className='d-flex align-items-center'>
                <p className='dispatch-vehicle-check-text' style={{ marginLeft: 0, textTransform: 'capitalize' }}>
                  {routeSelect[index]}
                </p>
                <Dropdown className='d-inline mx-2'>
                  <Dropdown.Toggle
                    // className="AvailableButton btn btn-link"
                    id='dropdown-autoclose-outside'
                    style={{
                      border: 'none',
                      outline: 'none',
                      background: 'none',
                      boxShadow: 'none',
                      marginLeft: '0',
                      paddingLeft: '10x',
                    }}
                  >
                    <img src={down} alt='down' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => handleRouteSelect('practical', index)}
                    >
                      Practical
                    </Dropdown.Item>

                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={() => handleRouteSelect('shortest', index)}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Shortest
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={() => handleRouteSelect('fastest', index)}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Fastest
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <div className='d-flex align-items-center mb-1'>
                  <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem', marginLeft: 0 }}>
                    Open Borders
                  </p>
                  <CustomCheckbox
                    checked={!!mapValues[index].open_borders}
                    onChange={(checked) => handleMapValuesChange(`${index}.open_borders`, checked)}
                  />
                  <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem' }}>
                    Highway Only
                  </p>
                  <CustomCheckbox
                    checked={!!mapValues[index].hwy_only}
                    onChange={(checked) => handleMapValuesChange(`${index}.hwy_only`, checked)}
                  />
                  <p className='dispatch-vehicle-check-text'>HazMat</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].haz_mat}
                    disabled
                    onChange={(checked) => handleMapValuesChange(`${index}.haz_mat`, checked)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                  <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].avoid_tolls}
                    onChange={(checked) => handleMapValuesChange(`${index}.avoid_tolls`, checked)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                  <p className='dispatch-vehicle-check-text'>Traffic</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].show_traffic}
                    onChange={(checked) => checkAddonStatus('show_traffic', checked, index)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                  <p className='dispatch-vehicle-check-text'>Weather</p>
                  <CustomCheckbox
                    checked={!!mapValues[index].show_weather}
                    onChange={(checked) => checkAddonStatus('show_weather', checked, index)}
                    style={{ marginLeft: '0.5rem' }}
                  />
                </div>
              </div>
            </div>
            <div style={style.mapWrapper}>
              {!!shipmentStops?.shipment_stops.length &&
                !!data[index]?.equipment &&
                !!mapValues &&
                !!expandedShipment && (
                  <DispatchMap
                    key={expandedShipment?.id}
                    data={shipmentStops?.shipment_stops}
                    mapValues={mapValues[index]}
                    selectedVehicle={data[index]?.equipment}
                    empty_miles={data[index]?.empty_miles}
                    previousShipment={previousShipment[index]}
                    planedShipment={planedShipment[index]}
                    customLatLong={customLatLong[index]}
                  />
                )}
            </div>
          </div>
        </div>
      )}
      {openCostModal && (
        <CostModal
          open={openCostModal}
          costFormValues={costForms[index]}
          onClose={() => setOpenCostModal(false)}
          data={data[index]}
          flatRate={data[index]?.flatRate}
          flatRateChecked={data[index]?.flatRateChecked}
          onApply={(values) => {
            setCostForms((prevState) => prevState.map((item, i) => (i === index ? values : item)));
            setOpenCostModal(false);
          }}
        />
      )}
    </div>
  );
};

export default DispatchMultipleShipments;
