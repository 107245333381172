import styled from 'styled-components';

export const SInsuranceFields = styled.div`
  margin-bottom: 58px;

  .main-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    width: 80%;
    min-width: 890px;
    max-width: 1100px;
  }
`;
