import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Style from './CustomSelect.module.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CustomState = ({ stopPointsData, routeCoords, AddStopPointData, stopPointCoords, firstCoords }) => {
  const [totalCost, setTotalCost] = useState();

  const abc = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'O',
    'p',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  useEffect(() => {
    let EstimatedFuelCost = 0;
    let DriverCost = 0;
    if (stopPointsData.length) {
      if (stopPointsData.length && stopPointsData[stopPointsData.length - 1]?.estimatedFuelCost) {
        EstimatedFuelCost = Math.round(
          stopPointsData.length && stopPointsData[stopPointsData.length - 1]?.estimatedFuelCost
        );
      }
      if (stopPointsData.length && stopPointsData[stopPointsData.length - 1]?.driver_cost) {
        DriverCost = Math.round(stopPointsData.length && stopPointsData[stopPointsData.length - 1]?.driver_cost);
      }
      setTotalCost(EstimatedFuelCost + DriverCost);
    }
  }, [stopPointsData]);
  const navigate = useNavigate();

  const CreateShipment = () => {
    navigate('/planner', { state: { openCreate: true } });
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return AddStopPointData.radius || AddStopPointData.length === 0 ? (
    ''
  ) : (
    <div>
      {stopPointCoords.length && firstCoords.length
        ? routeCoords?.map((v, i) => (
            <>
              <p className={Style.CustomStateContent}>
                <span className={Style.CustomStateTextBold}>
                  {i === 0 ? (
                    <span className={Style.CurrentLocationStyle}>Dead Head: {v?.current_location}</span>
                  ) : (
                    <span className={Style.StopPointStyle}>
                      <span> Stop {abc[i - 1]}</span>: <span>{v?.city ? `${v?.city},` : ' '}</span>{' '}
                      <span>{v?.state ? `${v?.state} ` : ' '}</span>
                      <span>{v?.zipcode ? `${v?.zipcode}` : ' '}</span>
                    </span>
                  )}
                </span>
              </p>
              <div className='d-flex'>
                {i < routeCoords.length - 1 ? <div className={Style['live-tracking-left-section']} /> : ''}
                <p className={Style.CustomStateContent} style={{ marginLeft: '20px' }} id='Counting'>
                  {i === 0 ? (
                    stopPointsData[0]?.emptyMiles ? (
                      <span>
                        <CountUp end={parseInt(stopPointsData[0]?.emptyMiles, 10)} duration={7} /> empty miles
                      </span>
                    ) : (
                      '0 empty miles'
                    )
                  ) : stopPointsData[i]?.loadedMiles ? (
                    <span>
                      <CountUp end={parseInt(stopPointsData[i]?.loadedMiles, 10)} duration={7} /> loaded miles
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </>
          ))
        : ''}

      {stopPointsData.length && firstCoords.length ? (
        <>
          <p className={Style.CustomStateDuration}>
            <span className='me-1'>Duration</span>
            <span className={Style.CustomStateBackgroundColor}>
              {stopPointsData.length && stopPointsData[stopPointsData.length - 1]?.totalDuration}
            </span>
          </p>
          <div>
            <div className={Style.CostEstimateMainHeading}>
              <p>Cost Estimate</p>
            </div>
            <div className={Style.CostEstimateBottomLine} />
            <div className={Style.CostEstimateSubHeading}>
              <div>Driver Cost </div>
              <div>
                {' '}
                <span>
                  ${' '}
                  {stopPointsData.length && stopPointsData[stopPointsData.length - 1]?.driver_cost
                    ? stopPointsData.length && (
                        <CountUp end={stopPointsData[stopPointsData.length - 1]?.driver_cost} duration={7} />
                      )
                    : 0}
                </span>
              </div>
            </div>
            <div className={Style.CostEstimateSubHeading}>
              <div>
                Estimated Fuel Cost <span className={Style.FuelCostSubText}>$4.57/GAL DIESEL</span>{' '}
              </div>
              <div>
                {' '}
                <span>
                  ${' '}
                  {stopPointsData.length && stopPointsData[stopPointsData.length - 1]?.estimatedFuelCost
                    ? stopPointsData.length && (
                        <CountUp end={stopPointsData[stopPointsData.length - 1]?.estimatedFuelCost} duration={7} />
                      )
                    : 0}
                </span>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <div className={Style.CostEstimateSubMainHeading}>Estimated Cost</div>
              <div>
                $ <CountUp end={totalCost} duration={7} />
              </div>
            </div>
          </div>
          <Accordion flush className={Style.CustomStateAccordionShadow}>
            <Accordion.Item eventKey='0'>
              <Accordion.Header className={Style.CustomStateAccordian}>Select Action</Accordion.Header>
              <Accordion.Body>
                <div className='d-flex'>
                  <div>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                      <button className={Style.CustomStateButton} onClick={handleClick}>
                        Create Quote
                      </button>
                      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity='info' sx={{ width: '100%' }}>
                          This feature is not available for your organization.
                        </Alert>
                      </Snackbar>
                    </Stack>
                  </div>
                  <div>
                    <button className={Style.CustomStateButton} onClick={() => CreateShipment()}>
                      Create Shipment
                    </button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default CustomState;
