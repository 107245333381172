import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatNumber, palette } from 'utils/constants';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import PaidInvoicesChart from '../Charts/PaidInvoicesChart';
import { SBoxWrapper, SVerticalDivider } from '../../StatsSection.styles';

const PaidInvoices = ({ paidInvoices, loading }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex align-items-center justify-content-around mb-2'>
        <span className='d-flex flex-column align-items-center gap-2'>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            90+ DAYS
          </Typography>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          ) : (
            <Typography variant='h5' style={{ color: palette.red500 }}>
              {currency}
              {formatNumber(paidInvoices?.nintyPlus?.amount)}
            </Typography>
          )}
        </span>
        <SVerticalDivider />
        <span className='d-flex flex-column align-items-center gap-2'>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            61-90 DAYS
          </Typography>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          ) : (
            <Typography variant='h5' style={{ color: '#BC900F' }}>
              {currency}
              {formatNumber(paidInvoices?.sixtyOneToNinety?.amount)}
            </Typography>
          )}
        </span>
        <SVerticalDivider />
        <span className='d-flex flex-column align-items-center gap-2'>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            31-60 DAYS
          </Typography>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          ) : (
            <Typography variant='h5' style={{ color: palette.indigo500 }}>
              {currency}
              {formatNumber(paidInvoices?.thirtyOneToSixty?.amount)}
            </Typography>
          )}
        </span>
        <SVerticalDivider />
        <span className='d-flex flex-column align-items-center gap-2'>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            0-30 DAYS
          </Typography>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          ) : (
            <Typography variant='h5' style={{ color: palette.indigo200 }}>
              {currency}
              {formatNumber(paidInvoices?.zeroToThirty?.amount)}
            </Typography>
          )}
        </span>
      </div>
      <Divider />
      <div className='chart-wrapper'>
        <PaidInvoicesChart invoicesData={paidInvoices} />
      </div>
    </SBoxWrapper>
  );
};

export default PaidInvoices;
