import React, { useEffect, useState } from 'react';
import { clearShipmentAuditData } from 'Api/ShipmentAudit';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import classes from './completedShipments.module.css';
import CustomButton from '../CustomButton/CustomButton';

const RevertShipments = ({ shipmentData, getShipments, setMasterChecked }) => {
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const showToaster = useShowToaster();

  useEffect(() => {
    const data = shipmentData?.filter((item) => item?.isSelected);
    setSelectedData(data);
  }, [shipmentData]);

  const clearShipment = () => {
    setLoading(true);
    const data = selectedData.map((item) => item?.shipment_id);
    clearShipmentAuditData({ revert_shipments: data })
      .then(() => {
        showToaster({ message: 'Shipments has been reverted successfully!', type: 'success' });
        const allFilterData = {
          status: [1],
        };
        getShipments({ allFilterData });
        setMasterChecked(false);
      })
      .catch((error) => {
        showToaster({ message: getErrorMessage(error), type: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    !!selectedData.length && (
      <div className={classes.headerWrapper}>
        <div className='shipment-audit--header-wrap mt-3'>
          <p>{`${selectedData?.length} Selected: `}</p>
          <CustomButton
            width={12}
            height={12}
            type='primary'
            title='Revert Cleared'
            styleButton={{ padding: '6px 12px' }}
            onClick={clearShipment}
            disabled={loading}
          />
        </div>
      </div>
    )
  );
};

export default RevertShipments;
