export const WORKING_HOURS = [
  {
    id: 1,
    title: 'First Come First Serve',
  },
  {
    id: 2,
    title: 'By Appointment',
  },
  {
    id: 3,
    title: 'By Time Window',
  },
];

export const STATUSES = [
  {
    id: 1,
    title: 'Active',
  },
  {
    id: 2,
    title: 'Inactive',
  },
];
