import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { ReactComponent as DownloadIcon } from 'assets/icons/drivers/indigoDownload.svg';
import Loader from 'common/Loader';
import NoRecords from 'common/NoRecords';
import YearPicker from 'common/YearPicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { LayoutContext } from 'components/layout';
import { getUserSettlements, markSettlementSeen } from 'Api/Payroll';
import SettlementCard from '../components/SettlementCard';
import { SCardsWrapper, SWrapper } from './Settlements.styles';

const Settlements = ({ activeSettlement, setActiveSettlement }) => {
  const { value } = useAuth();
  const { setUnreadSettlementsCount } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(new Date());
  const [settlements, setSettlements] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const filteredSettlements = useMemo(() => {
    return settlements.filter((item) =>
      moment(item.pay_period_end).isBetween(moment(year).startOf('year'), moment(year).endOf('year'))
    );
  }, [settlements, year]);

  const getSettlements = async () => {
    try {
      setLoading(true);
      const { data } = await getUserSettlements({ user_type: 1, user_id: value.user.id });
      setSettlements(data.filter((item) => [3, 5].includes(item.status_id)));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const handleSelectItem = (checked, settlement) => {
    if (checked) {
      setSelectedItems((prevState) => [...prevState, settlement]);
    } else {
      const updatedSelectedRows = selectedItems.filter((item) => item.id !== settlement.id);
      setSelectedItems(updatedSelectedRows);
    }
  };

  const onDownload = () => {
    selectedItems.forEach((settlement) => {
      fetch(settlement.pdf_file_link, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(file);
          link.setAttribute('download', 'download');
          link.href = url;
          link.download = `settlement-${settlement.id}.pdf`;
          link.click();
        });
    });
  };

  const onCardClick = async (settlement) => {
    setActiveSettlement(settlement);

    if (!settlement.settlement_seen) {
      try {
        await markSettlementSeen(settlement.id);
        setSettlements((prevState) =>
          prevState.map((i) => (i.id === settlement.id ? { ...i, settlement_seen: 1 } : i))
        );
        setUnreadSettlementsCount((prevState) => (prevState === 0 ? 0 : prevState - 1));
      } catch (e) {
        // Do nothing
      }
    }
  };

  useEffect(() => {
    getSettlements();
  }, [year]);

  return (
    <SWrapper>
      <div className='d-flex justify-content-between align-items-center mb-2 ps-2 pe-2'>
        <YearPicker year={year} onChange={setYear} />
        {!!selectedItems.length && (
          <CustomButton
            styleTitle={{ fontSize: 14 }}
            styleButton={{ padding: '2px 12px', margin: 0 }}
            type='secondary'
            title='Download'
            onClick={onDownload}
            leftIcon={<DownloadIcon fill={palette.gray500} className='me-2' />}
          />
        )}
      </div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <SCardsWrapper>
          {filteredSettlements.map((item) => (
            <SettlementCard
              key={item.id}
              settlement={item}
              selected={selectedItems.some((i) => i.id === item.id)}
              handleSelect={handleSelectItem}
              onClick={() => onCardClick(item)}
              className={activeSettlement?.id === item.id ? 'active' : ''}
            />
          ))}
          {!loading && !filteredSettlements?.length && <NoRecords />}
        </SCardsWrapper>
      )}
    </SWrapper>
  );
};

export default Settlements;
