import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { createReferrer } from 'Api/Applicants';
import ReferrerInfoForm from './components/ReferrerInfoForm';
import { validationSchema } from './validationSchemas';
import { SAddMore } from './AddReferrer.styles';
import { SCustomModal } from '../../ApplicantsAndReferrals.styles';
import { createReferrerConvertor } from './AddReferrer.convertors';

const initialValues = [{ referral_name: '', contact_information: '', commission: '', id: Math.random() }];

const AddReferrer = ({ open, onClose, onSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    const data = createReferrerConvertor(values);

    try {
      await Promise.all(data.map((item) => createReferrer(item)));
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  const { values, setValues, handleChange, handleSubmit, resetForm, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const addNewForm = () => {
    handleChange(values.length, { ...initialValues[0], id: Math.random() });
  };

  const deleteForm = (index) => {
    const newValues = values.filter((item, i) => i !== index);
    setValues(newValues);
  };

  const resetState = () => {
    setLoading(false);
    resetForm();
  };

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      $minWidth='700px'
      $height='auto'
      $bgColor={palette.gray0}
      onExited={resetState}
      headerTitle='Referrers for Job Positions'
      buttons={[
        {
          key: 'submit',
          type: 'primary',
          title: 'Save',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <CloseIcon className='close-modal-icon' onClick={onClose} />
      <Grid container columnSpacing={2}>
        <Grid item xs={4}>
          <Typography variant='overLine' style={{ color: use(palette.gray500, palette.gray200) }}>
            NAME
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='overLine' style={{ color: use(palette.gray500, palette.gray200) }}>
            PHONE OR EMAIL
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='overLine' style={{ color: use(palette.gray500, palette.gray200) }}>
            COMMISSION
          </Typography>
        </Grid>
      </Grid>
      {values.map((value, index) => (
        <ReferrerInfoForm
          key={value.id}
          values={value}
          handleChange={handleChange}
          touchedErrors={touchedErrors[index]}
          onDelete={() => deleteForm(index)}
          hideDelete={index === 0}
          index={index}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
          Add More...
        </Typography>
      </SAddMore>
    </SCustomModal>
  );
};

export default AddReferrer;
