import React, { useEffect, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import person from 'assets/icons/drivers/user-profile.svg';
import locationFilled from 'assets/icons/location-filled.svg';
import { getAlphabet, palette, statusColor } from 'utils/constants';
import './PlanMap.css';
import { getAverageTimeConverted } from '../../StopPoint/Walmart/helpers';

const NAME_TYPES = {
  1: 'PICK UP',
  2: 'DELIVERY',
  3: 'WAYPOINT',
};

const CLASS_TYPES = {
  1: 'pickUp',
  2: 'delivery',
  3: 'wayPoint',
};

const PlanMap = ({
  data,
  selectedVehicle,
  stopPointArr,
  infoHeader,
  children,
  mapValues,
  setDriverRouteIncludesCountries,
  setStopsRouteIncludesCountries,
}) => {
  const [driverCoords, setDriverCoords] = useState([]);
  const [coords, setCoords] = useState([]);
  const [stops, setStops] = useState([]);
  const [indexForAlphabet, setIndexForAlphabet] = useState([]);

  useEffect(() => {
    if (Array.isArray(stopPointArr)) {
      const allCoords = [];
      const allStops = [];
      const countAll = [];
      stopPointArr.forEach((stop, index) => {
        const { city, state, zipcode, location_name, average_waiting_time, stop_point_id } = stop;
        const latitude = stop?.latitude ? stop?.latitude : stop?.lat;
        const longitude = stop?.longitude ? stop?.longitude : stop?.lng;
        if (latitude && longitude) {
          const cord = {
            latitude,
            longitude,
            city,
            state,
            zipcode,
            location_name,
            average_waiting_time,
            stop_point_id,
          };
          countAll.push(index);
          allStops.push(stop);
          allCoords.push(cord);
        }
      });
      setStops([...allStops]);
      setCoords([...allCoords]);
      setIndexForAlphabet([...countAll]);

      if (data) {
        const startPoint = data.find((item) => item.stop_point_type === '1');

        setDriverCoords([
          {
            latitude:
              selectedVehicle?.location_target === 'Driver'
                ? selectedVehicle?.drivers[0]?.driver_gps?.latitude
                : selectedVehicle?.equipment_gps?.latitude,
            longitude:
              selectedVehicle?.location_target === 'Driver'
                ? selectedVehicle?.drivers[0]?.driver_gps?.longitude
                : selectedVehicle?.equipment_gps?.longitude,
          },
          {
            latitude: startPoint?.stop_point?.geofencing_latitude,
            longitude: startPoint?.stop_point?.geofencing_longitude,
          },
        ]);
      }
    } else {
      const startPoint = data.find((item) => item.stop_point_type === '1');

      setDriverCoords([
        {
          latitude:
            selectedVehicle?.location_target === 'Driver'
              ? selectedVehicle?.drivers[0]?.driver_gps?.latitude
              : selectedVehicle?.equipment_gps?.latitude,
          longitude:
            selectedVehicle?.location_target === 'Driver'
              ? selectedVehicle?.drivers[0]?.driver_gps?.longitude
              : selectedVehicle?.equipment_gps?.longitude,
        },
        {
          latitude: startPoint?.stop_point?.geofencing_latitude,
          longitude: startPoint?.stop_point?.geofencing_longitude,
        },
      ]);

      const coords = data.map((item) => ({
        latitude: item?.stop_point?.geofencing_latitude,
        longitude: item.stop_point?.geofencing_longitude,
      }));
      setCoords(coords);

      setStops(data);
    }
  }, [data, stopPointArr, selectedVehicle]);

  const popover = `<div class="vehicle-popup-wrapper">
      <div class="d-flex justify-content-between align-items-center vehicle-popup">
            <span class="vehicle-popup-driver">${selectedVehicle?.drivers[0]?.fname} ${
    selectedVehicle?.drivers[0]?.lname
  }</span>
            <div class="vehicle-popup-status" style="background-color: ${
              statusColor[selectedVehicle?.drivers[0]?.status_data?.driver_status]?.bgColor
            }" >
              <span style="color: ${statusColor[selectedVehicle?.drivers[0]?.status_data?.driver_status]?.color}">${
    selectedVehicle?.drivers[0]?.status_data?.driver_status
  }</span>
            </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-1">
        <div class="d-flex">
          <span class="number-truck me-1">${selectedVehicle?.equipment_number}</span> <span class="type-truck">${
    selectedVehicle?.equipment_type?.title
  }</span>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-1">
        <span class="miles-away">${selectedVehicle?.empty_miles?.toFixed()} Miles Away</span>
        <span class="miles-away">${selectedVehicle?.distance_time}</span>
      </div>
      <div class="d-flex align-items-center gap-3 mt-1">
        <div>
          <img src="${locationFilled}" alt="">
        </div>
        <div class="d-flex flex-column">
          <span class="vehicle-popup-address">${
            selectedVehicle?.location_target === 'Driver'
              ? selectedVehicle?.drivers[0]?.driver_gps?.location
              : selectedVehicle?.equipment_gps?.location
          }</span>
        </div>
      </div>
    </div>
    `;

  useEffect(() => {
    async function init() {
      TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
      const myMap = new TrimbleMaps.Map({
        container: 'myMap',
        style: TrimbleMaps.Common.Style.BASIC,
        zoom: 7,
        dragRotate: true,
      });

      const routeId = 'myRoute';
      const driverRouteId = 'driverRoute';
      const driverRouteElem = document.getElementById('driverRoute');
      /// ///// Marker //////////
      const markers = [];
      // Create a marker with SVG content

      coords.forEach((cord, i) => {
        const alphabet = getAlphabet(indexForAlphabet[i]);
        const id = stops[i]?.stop_point_type;
        const svgContent = document.createElement('div');
        let content;
        if (id === null || id === undefined) {
          content = `<div style="display: none"/>`;
        } else {
          content = `
            <div class='myMap_marker_container ${CLASS_TYPES[+id]}'  id="stop-${cord.stop_point_id}">
              <img src="${mapMarkerIcon}">
                <div class='myMap_marker_text_wrapper'>
                  <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                </div> 
            </div>
          `;
        }
        svgContent.innerHTML = content;

        const popup = new TrimbleMaps.Popup({ anchor: 'left', closeButton: true, closeOnClick: false }).setLngLat([
          Number(cord?.longitude),
          Number(cord?.latitude),
        ]).setHTML(`
                        <div class="vehicle-popup-wrapper">
                              <div class="d-flex align-items-center vehicle-popup">
                                    ${cord.location_name}
                              </div>
                             <div class="d-flex align-items-center vehicle-popup">
                                       <img src="${locationFilled}" alt="" class="me-2">
                                    ${cord.city?.name},  ${cord.state?.name} ${cord.zipcode}
                              </div>
                            <div class="d-flex align-items-center vehicle-popup">
                                   Avg. Wait Time:  ${getAverageTimeConverted(cord.average_waiting_time)}
                              </div>
                        </div>    
                        `);
        if (cord?.longitude && cord?.latitude) {
          const marker = new TrimbleMaps.Marker({
            draggable: false,
            element: svgContent,
          })
            .setLngLat([cord?.longitude, cord?.latitude])
            .setPopup(popup)
            .addTo(myMap);

          markers.push(marker);

          marker.getElement().addEventListener('mouseenter', () => {
            marker.togglePopup();
          });

          marker.getElement().addEventListener('mouseleave', () => {
            marker.togglePopup();
          });
        }
      });

      const svgContent = document.createElement('div');
      svgContent.innerHTML = `
                            <div class='myMap_driver_marker_container'>
                                 <img src='${selectedVehicle?.drivers[0]?.image || person}'>
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                   <span>DEAD HEAD ${selectedVehicle?.empty_miles.toFixed()} Miles</span>
                                 </div> 
                             </div>
      `;
      const stopsMap = coords.map((cord) => new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude));

      if (driverCoords && driverCoords.length && driverCoords[0]?.longitude) {
        const popup = new TrimbleMaps.Popup({ anchor: 'left', closeButton: true, closeOnClick: false })
          .setLngLat([Number(driverCoords[0]?.longitude), Number(driverCoords[0]?.latitude)])
          .setHTML(popover);

        const driverMarker = new TrimbleMaps.Marker({
          draggable: false,
          element: svgContent,
        })
          .setLngLat([driverCoords[0]?.longitude, driverCoords[0]?.latitude])
          .addTo(myMap)
          .setPopup(popup);
        markers.push(driverMarker);

        driverMarker.getElement().addEventListener('mouseenter', () => {
          driverMarker.togglePopup();
        });

        driverMarker.getElement().addEventListener('mouseleave', () => {
          driverMarker.togglePopup();
        });
      }

      let driverRoute;
      if (driverCoords && driverCoords.length && driverCoords[0]?.longitude) {
        const driverStops = driverCoords.map((cord) => {
          if (cord?.longitude && cord?.latitude) {
            return new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude);
          }
          return null;
        });

        /// ///// Marker //////////
        const driverRouteOptions = {
          routeId: driverRouteId,
          stops: driverStops.includes(undefined) ? undefined : driverStops,
          routeColor: palette.blue300, // optional routeColor,
          routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
          highwayOnly: !!mapValues.hwy_only,
          tollRoads: mapValues.avoid_tolls ? 2 : 3,
          hazMatType: mapValues.haz_mat ? 1 : 0,
          vehicleType: mapValues.vehicle_type,
          bordersOpen: !!mapValues.open_borders,
          reportType: ['State'],
        };
        driverRoute = new TrimbleMaps.Route(driverRouteOptions);

        driverRoute.on('report', function (reports) {
          const states = reports?.['0']?.StateReportLines?.map((item) => item.StCntry);
          const routeIncludesCountries = [];

          if (states?.includes('US')) {
            routeIncludesCountries.push(231);
          }
          if (states?.includes('Canada')) {
            routeIncludesCountries.push(38);
          }
          if (states?.includes('Mexico')) {
            routeIncludesCountries.push(142);
          }

          setDriverRouteIncludesCountries(routeIncludesCountries);
        });
        driverRoute.on('stopInserting', (e) => {
          driverRouteElem.innerHTML = `Mouse down on ${e.routeLegPositions.length} route leg(s)`;
          driverRoute.getRouteWithNewStop(e.newStop, e.routeLegPositions[e.routeLegPositions.length - 1]);
        });
      }

      const routeOptions = {
        routeId,
        stops: stopsMap,
        showStops: false,
        routeColor: palette.indigo500, // optional routeColor,
        routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
        highwayOnly: !!mapValues.hwy_only,
        tollRoads: mapValues.avoid_tolls ? 2 : 3,
        hazMatType: mapValues.haz_mat ? 1 : 0,
        vehicleType: mapValues.vehicle_type,
        bordersOpen: !!mapValues.open_borders,
        reportType: ['State'],
      };
      const myRoute = new TrimbleMaps.Route(routeOptions);
      myRoute.on('report', function (reports) {
        const states = reports?.['0']?.StateReportLines?.map((item) => item.StCntry);
        const routeIncludesCountries = [];

        if (states?.includes('US')) {
          routeIncludesCountries.push(231);
        }
        if (states?.includes('Canada')) {
          routeIncludesCountries.push(38);
        }
        if (states?.includes('Mexico')) {
          routeIncludesCountries.push(142);
        }

        setStopsRouteIncludesCountries(routeIncludesCountries);
      });

      const center = driverCoords[0]?.latitude
        ? [Number(driverCoords[0]?.longitude), driverCoords[0]?.latitude]
        : [-96, 35];
      if (driverCoords[0]?.latitude) {
        myMap.setCenter(center);
      }

      myMap.on('load', async () => {
        myRoute.addTo(myMap);
        driverRoute && driverRoute.addTo(myMap);
      });
    }

    init();
  }, [coords, stops, infoHeader, driverCoords, mapValues]);

  return (
    <div className='plan_map_container'>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100%', width: '100%' }} />
        {children}
      </div>
    </div>
  );
};

export default PlanMap;
