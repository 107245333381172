import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import EventsTable from './components/EventsTable';

const QuoteEvents = ({ open, onClose, quote }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`Quote ${quote.quote_id} Event Log`}
      $bgColor={palette.gray0}
      $maxWidth='1000px'
      $minWidth='1000px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
        },
      ]}
    >
      <div>
        <EventsTable quoteId={quote.id} />
      </div>
    </Modal>
  );
};

export default QuoteEvents;
