import styled from 'styled-components';

export const SChannelItem = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 14px 0;
  padding: 6px 8px;
  border-radius: 6px;

  &.selected {
    background: #f0f1fa;
    transition: background 0.1s ease-in-out;
  }

  .channel-item-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    column-gap: 12px;
  }

  .channel-image {
    .profile-image {
      position: relative;

      img {
        vertical-align: middle;
        object-fit: cover;
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }

      .profile-status-indicator {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        position: absolute;
        bottom: -2px;
        right: 4px;
        border: 1px solid white;
      }
    }
  }

  .channel-status-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
`;
