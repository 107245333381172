import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import calendar from 'assets/icons/drivers/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { CircularProgress } from '@mui/material';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import plus from 'assets/icons/drivers/plus.svg';
import { palette } from 'utils/constants';
import { uploadDriverDocument } from 'Api/Driver';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import ImageDropZone from '../ImgageDropZone';

export default function UpdateDocument({
  updateDocumentData,
  setUpdateDocumentData,
  onSuccess,
  loading,
  AddUpdateDocuments,
  id,
  ...props
}) {
  const { index } = updateDocumentData;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({ ...updateDocumentData, count: 1 });
  }, []);

  const setDoc = async (index, type, value) => {
    if (type === 'expiry_date') {
      value = moment(value).format('YYYY-MM-DD');
    }
    if (type === 'expiry_date' && updateDocumentData.expiry_date === value) {
      // Do nothing
    } else if (type === 'expiry_date' && updateDocumentData.expiry_date !== value && data.count === 1) {
      const temUpdatedData = { ...updateDocumentData, [type]: value };
      setUpdateDocumentData(temUpdatedData);
      setData({ ...updateDocumentData, count: data.count + 1 });
    } else {
      const temUpdatedData = { ...updateDocumentData, [type]: value };
      setUpdateDocumentData(temUpdatedData);
    }
  };

  const updateDocuments = () => {
    if (!updateDocumentData.document_name) {
      props.showError('Document Name is required!');
      return;
    }
    if (!updateDocumentData.expiry_date && !updateDocumentData.no_expiry) {
      props.showError('Document Expiry is required!');
      return;
    }
    if (!updateDocumentData.document_path) {
      props.showError('Document is required!');
      return;
    }
    AddUpdateDocuments(
      { ...updateDocumentData, expiry_date: updateDocumentData?.no_expiry ? null : updateDocumentData?.expiry_date },
      'update',
      props.onHide,
      successResponse,
      'Documents has been successfully updated'
    );
  };

  const uploadDocument = (file, propertyName, index) => {
    uploadDriverDocument(file[0], id).then((res) => {
      setDoc(index, 'document_path', res.data.document_path);
    });
  };

  const successResponse = (msg) => {
    onSuccess(msg);
  };

  return (
    <div className='modal-container'>
      <Modal {...props} enforceFocus={false} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title className='heading' id='contained-modal-title-vcenter'>
            Update Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-box driver-style-wrap'>
          <div style={{ marginBottom: '12px' }}>
            <div className='kastum-driver-document-header'>
              <p className='text-style' style={{ marginLeft: '0', color: palette.gray700 }}>
                Document Name
              </p>
              <div className='d-flex align-items-center'>
                <p className='text-style' style={{ marginLeft: '0', color: palette.gray700 }}>
                  Expiry
                </p>
              </div>
            </div>
            <div className='d-flex update-expiry-container'>
              <div className='d-flex flex-column'>
                <div className='expiry-input'>
                  <input
                    type='text'
                    value={updateDocumentData?.document_name}
                    onChange={(e) => {
                      setDoc(index, 'document_name', e.target.value);
                    }}
                  />
                </div>
                {updateDocumentData.document_path ? (
                  <p className='attachment-wrapper' style={{ marginTop: '4px' }}>
                    <img src={right} alt='attachment' className='attachment-icon' />
                    <span className='text-style' style={{ color: '#38A06C' }}>
                      File Uploaded
                    </span>
                    <img
                      src={cross}
                      alt='cross'
                      className='upload-cancel-icon'
                      onClick={() => setDoc(index, 'document_path', null)}
                    />
                  </p>
                ) : (
                  <div className='ms-2 mt-2 attach-file' style={{ position: 'relative' }}>
                    <label htmlFor='myFile' className='text-style upload'>
                      <span className='upload-icon'>
                        <img src={plus} alt='plus' />
                      </span>
                      <span className='text-style choose-file '>Choose File</span>
                    </label>
                    <ImageDropZone
                      onDrop={(file) => uploadDocument(file, 'attachment', index)}
                      width='100%'
                      height='100%'
                      name='attachment'
                      id='attachment'
                      deletable={false}
                      // onBlur={formik.handleBlur}
                      maxSize={1000000}
                      customStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                      accept={['application/pdf']}
                    />
                  </div>
                )}
              </div>
              <div className='d-flex flex-column'>
                <div className='select-expiry-type test'>
                  <img src={calendar} alt='caleder' />
                  <DatePicker
                    selected={
                      updateDocumentData?.no_expiry
                        ? ''
                        : updateDocumentData?.expiry_date
                        ? new Date(moment(new Date(updateDocumentData?.expiry_date), 'MM-DD-YYYY'))
                        : ''
                    }
                    placeholderText='mm/dd/yyyy'
                    className='text-style ms-0'
                    value={
                      updateDocumentData?.no_expiry
                        ? ''
                        : updateDocumentData?.expiry_date
                        ? new Date(moment(new Date(updateDocumentData?.expiry_date), 'MM-DD-YYYY'))
                        : ''
                    }
                    onChange={(date) => {
                      setDoc(index, 'expiry_date', date);
                    }}
                    disabled={updateDocumentData?.no_expiry}
                    highlightDates={new Date()}
                    showYearDropdown
                    showMonthDropdown
                  />
                </div>
                <div style={{ marginTop: '4px' }}>
                  <CustomCheckbox
                    name='no_expiry'
                    checked={updateDocumentData?.no_expiry}
                    onChange={(checked) => {
                      setDoc(index, 'no_expiry', checked);
                    }}
                  >
                    <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                      No Expiration
                    </Typography>
                  </CustomCheckbox>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button className='next-step' type='submit' onClick={updateDocuments}>
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
