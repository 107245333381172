import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/drivers/upload.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/drivers/secondClose.svg';
import Input from 'common/Input';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { uploadDocument } from 'Api/JobPositions';
import { photoIdentificationTypes } from './StaffLicense.data';
import { SUploadWrapper, SFileUploaded } from '../Steps.styles';

const StaffLicense = ({ form, companyId }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState();
  const uploaderRef = useRef(null);

  const { values, handleChange, handleBlur, touchedErrors } = form;

  const uploadLicense = async (files) => {
    try {
      const response = await uploadDocument(files?.[0], companyId);
      if (response?.document_path) {
        handleChange('photo_id_attachment', response.document_path);
      }
    } catch (e) {
      showToaster({ type: 'error', message: 'Unable to upload file' });
    }
  };

  const onImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage({ blob: reader?.result });
      setCropModalOpen(true);
    };
    reader.readAsDataURL(file[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadLicense([blob]);
        setCropModalOpen(false);
      });
    }
  };

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        <Grid item xs={12}>
          <Input
            name='photo_id_number'
            label='Photo Identification #'
            onChange={handleChange}
            value={values.photo_id_number}
            error={touchedErrors.photo_id_number}
            required
          />
          <SUploadWrapper>
            {values.photo_id_attachment ? (
              <SFileUploaded style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                <CheckIcon />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <DeleteIcon className='delete-icon' onClick={() => handleChange('photo_id_attachment', null)} />
              </SFileUploaded>
            ) : (
              <div
                className='uploader-content'
                onClick={() => {
                  uploaderRef.current.click();
                  uploaderRef.current.value = null;
                }}
              >
                <UploadIcon width={14} height={13} />
                <Typography variant='s2' style={{ color: use(palette.indigo500, palette.white) }}>
                  Attach photo identification document...
                </Typography>
              </div>
            )}
            {!values.photo_id_attachment && (
              <input
                ref={uploaderRef}
                accept='image/jpeg,image/png'
                type='file'
                onChange={(e) => onImageUpload(e.target.files)}
                className='d-none'
              />
            )}
          </SUploadWrapper>
          <ErrorMessage error={touchedErrors.photo_id_attachment} />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            required
            width='100%'
            name='photo_id_expiration'
            label='Photo Identification Expiration'
            disablePast
            onBlur={handleBlur}
            value={values.photo_id_expiration}
            onChange={(val) => handleChange('photo_id_expiration', val)}
            error={touchedErrors.photo_id_expiration}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name='photo_id_type'
            label='Photo Identification Type'
            placeholder='Select type..'
            value={values.photo_id_type}
            onChange={(e, value) => handleChange('photo_id_type', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={photoIdentificationTypes}
            error={touchedErrors.photo_id_type}
            required
          />
        </Grid>
      </Grid>
      {!!cropModalOpen && (
        <ImageCropModal
          show={cropModalOpen}
          onHide={() => setCropModalOpen(false)}
          image={image}
          getCropData={getCropData}
          setCropper={setCropper}
          backdrop='static'
        />
      )}
    </form>
  );
};

export default StaffLicense;
