import React, { useEffect, useRef, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import classNames from 'classnames';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import person from 'assets/images/person.svg';
import locationFilled from 'assets/icons/location-filled.svg';
import { getAlphabet, palette, statusColor } from 'utils/constants';
import './DispatchMap.css';
import { getAverageTimeConverted } from '../../StopPoint/Walmart/helpers';

const NAME_TYPES = {
  1: 'PICK UP',
  2: 'DELIVERY',
  3: 'WAYPOINT',
};

const CLASS_TYPES = {
  1: 'pickUp',
  2: 'delivery',
  3: 'wayPoint',
};

const PendingDispatch = ({ data, mapValues, shipment, children }) => {
  const [driverCoords, setDriverCoords] = useState([]);
  const [coords, setCoords] = useState([]);
  const [myMap, setMyMap] = useState(null);
  const [divContent, setDivContent] = useState(null);
  const [driverIconContentState, setDriverIconContentState] = useState(null);
  const [stops, setStops] = useState([]);
  const [indexForAlphabet] = useState([]);
  const route = useRef(null);

  const {
    equipment: selectedVehicle,
    driver1: driver,
    empty_miles,
    brokerage_dispatch,
    driver_from_gps_lat,
    driver_from_gps_long,
    driver_from_city,
    driver_from_state,
  } = shipment || {};

  useEffect(() => {
    const startPoint = data.find((item) => item.stop_point_type === '1');

    setDriverCoords([
      {
        latitude: brokerage_dispatch
          ? driver_from_gps_lat
          : selectedVehicle?.location_target === 'Driver'
          ? selectedVehicle?.driver_with_gps[0]?.driver_gps?.latitude
          : selectedVehicle?.equipment_gps?.latitude,
        longitude: brokerage_dispatch
          ? driver_from_gps_long
          : selectedVehicle?.location_target === 'Driver'
          ? selectedVehicle?.driver_with_gps[0]?.driver_gps?.longitude
          : selectedVehicle?.equipment_gps?.longitude,
      },
      {
        latitude: startPoint?.stop_point?.geofencing_latitude,
        longitude: startPoint?.stop_point?.geofencing_longitude,
      },
    ]);

    const coords = data.map((item) => ({
      latitude: item?.stop_point?.geofencing_latitude,
      longitude: item.stop_point?.geofencing_longitude,
      average_waiting_time: item.stop_point?.average_waiting_time,
      city: item.stop_point?.city,
      state: item.stop_point?.state,
      zipcode: item.stop_point?.zipcode,
      location_name: item.stop_point?.location_name,
    }));
    setCoords(coords);

    setStops(data);
  }, [data, selectedVehicle]);

  const popover = `
    <div class="vehicle-popup-wrapper">
      <div class="d-flex justify-content-between align-items-center vehicle-popup">
        <span class="vehicle-popup-driver">
          ${brokerage_dispatch?.driver_name || ''} ${driver?.fname || ''} ${driver?.lname || ''}
        </span>
        <div class="vehicle-popup-status" style="background-color: ${
          statusColor[driver?.status_data?.driver_status]?.bgColor
        }; display: ${brokerage_dispatch ? 'none' : 'block'}" >
              <span style="color: ${statusColor[driver?.status_data?.driver_status]?.color}">${
    driver?.status_data?.driver_status
  }</span>
            </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-1">
        <div class="d-flex">
          <span class="number-truck me-1">${selectedVehicle?.equipment_id}</span> <span class="type-truck">${
    selectedVehicle?.equipment_type?.title
  }</span>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-1">
      </div>
      <div class="d-flex align-items-center gap-3 mt-1">
        <div>
          <img src="${locationFilled}" alt="">
        </div>
        <div class="d-flex flex-column">
          <span class="vehicle-popup-address">${
            brokerage_dispatch
              ? `${driver_from_city || ''}, ${driver_from_state}`
              : selectedVehicle?.location_target === 'Driver'
              ? selectedVehicle?.driver_with_gps[0]?.driver_gps?.location
              : selectedVehicle?.equipment_gps?.location
          }</span>
        </div>
      </div>
    </div>
    `;

  useEffect(() => {
    async function init() {
      if (!myMap) {
        TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
        const myMap = new TrimbleMaps.Map({
          container: 'myMap',
          style: TrimbleMaps.Common.Style.BASIC,
          center: new TrimbleMaps.LngLat(-96, 35),
          zoom: 3,
        });
        setMyMap(myMap);
      }
      if (myMap) {
        setTimeout(() => {
          const routeId = 'myRoute';
          const driverRouteId = 'driverRoute';
          const driverRouteElem = document.getElementById('driverRoute');
          /// ///// Marker //////////
          const markers = [];
          // Create a marker with SVG content

          coords.forEach((cord, i) => {
            const alphabet = getAlphabet(indexForAlphabet[i]);
            const id = stops[i]?.stop_point_type;
            const svgContent = document.createElement('div');
            let label;
            const content = `
              <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                <img src="${mapMarkerIcon}" alt=''>
                  <div class='myMap_marker_text_wrapper'>
                    <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                  </div> 
                  <div class="map_popover ${!label ? 'noneLAbel' : ''}" style="opacity: ${1}">
                   
                  </div>
                </div>
              `;

            if (!divContent) {
              svgContent.innerHTML = content;
              setDivContent(content);
            }

            const popup = new TrimbleMaps.Popup({
              anchor: 'left',
              closeButton: true,
              closeOnClick: false,
            }).setLngLat([Number(cord?.longitude), Number(cord?.latitude)]).setHTML(`
                                <div class="vehicle-popup-wrapper">
                                      <div class="d-flex align-items-center vehicle-popup">
                                            ${cord.location_name}
                                      </div>
                                     <div class="d-flex align-items-center vehicle-popup">
                                               <img src="${locationFilled}" alt="" class="me-2">
                                            ${cord.city?.name},  ${cord.state?.name} ${cord.zipcode}
                                      </div>
                                    <div class="d-flex align-items-center vehicle-popup">
                                           Avg. Wait Time:  ${getAverageTimeConverted(cord.average_waiting_time)}
                                      </div>
                                </div>    
                                `);

            const marker = new TrimbleMaps.Marker({
              draggable: false,
              element: svgContent,
            })
              .setLngLat([cord?.longitude, cord?.latitude])
              .addTo(myMap)
              .setPopup(popup);

            markers.push(marker);

            marker.getElement().addEventListener('mouseenter', () => {
              marker.togglePopup();
            });

            marker.getElement().addEventListener('mouseleave', () => {
              marker.togglePopup();
            });
          });

          const svgContent = document.createElement('div');
          const driverIconContent = `
            <div class='myMap_driver_marker_container'>
              <img src="${driver?.image || person}" alt='chatlax'>
                <div class='myMap_marker_text_wrapper driver_marker'>
                  <span>DEAD HEAD ${empty_miles && empty_miles.toFixed()} Miles</span>
                </div> 
            </div>
          `;
          if (!driverIconContentState) {
            svgContent.innerHTML = driverIconContent;
            setDriverIconContentState(driverIconContent);
          }
          const stopsMap = coords.map((cord) => new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude));

          const popoverMap = new TrimbleMaps.Popup({ anchor: 'left', closeButton: true, closeOnClick: false }).setHTML(
            popover
          );
          if (driverCoords[0]?.longitude) {
            const driverMarker = new TrimbleMaps.Marker({
              draggable: false,
              element: svgContent,
            })
              .setLngLat([driverCoords[0]?.longitude, driverCoords[0]?.latitude])
              .addTo(myMap)
              .setPopup(popoverMap);
            markers.push(driverMarker);

            driverMarker.getElement().addEventListener('mouseenter', () => {
              driverMarker.togglePopup();
            });

            driverMarker.getElement().addEventListener('mouseleave', () => {
              driverMarker.togglePopup();
            });
          }

          let driverRoute;
          if (driverCoords && driverCoords.length) {
            const driverStops = driverCoords.map((cord) => {
              if (cord?.longitude && cord?.latitude) {
                return new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude);
              }
              return null;
            });

            if (route.current) {
              route.current?.remove();
            }

            /// ///// Marker //////////
            const driverRouteOptions = {
              routeId: driverRouteId,
              stops: driverStops,
              routeColor: palette.blue300, // optional routeColor,
              routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
              highwayOnly: mapValues.highwayOnly,
              tollRoads: mapValues.avoid_tolls ? 2 : 3,
              hazMatType: mapValues.haz_mat ? 1 : 0,
            };
            driverRoute = new TrimbleMaps.Route(driverRouteOptions);
            route.current = driverRoute;

            driverRoute.on('stopInserting', (e) => {
              driverRouteElem.innerHTML = `Mouse down on ${e.routeLegPositions.length} route leg(s)`;
              driverRoute.getRouteWithNewStop(e.newStop, e.routeLegPositions[e.routeLegPositions.length - 1]);
            });
          }

          const trafficIncident = new TrimbleMaps.TrafficIncident();

          const routeOptions = {
            routeId,
            stops: stopsMap,
            routeColor: palette.indigo500, // optional routeColor,
          };
          const myRoute = new TrimbleMaps.Route(routeOptions);

          myMap.on('load', async () => {
            myRoute.addTo(myMap);
            driverRoute && driverRoute.addTo(myMap);
            trafficIncident.addTo(myMap);
            myMap.setWeatherRadarVisibility(false);
            myMap.setTrafficVisibility(false);
          });
        }, 500);
      }
    }

    init();
  }, [coords, stops, mapValues, myMap]);

  useEffect(() => {
    if (myMap && mapValues) {
      myMap.setWeatherRadarVisibility(!!mapValues.show_weather);
      myMap.setTrafficVisibility(!!mapValues.show_traffic);
    }
  }, [mapValues.show_weather, mapValues.show_traffic]);

  useEffect(() => {
    return () => {
      setMyMap(null);
      setDivContent(null);
      setDriverIconContentState(null);
    };
  }, []);

  return (
    <div className={classNames('plan_map_container')}>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100%', width: '100%' }} />
        {children}
      </div>
    </div>
  );
};

export default PendingDispatch;
