import React from 'react';
import PlusIcon from 'assets/icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as BellIcon } from 'assets/icons/header/notification-20px.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import styles from './SwitchRadio.module.css';

const SwitchRadio = ({
  style,
  items,
  name,
  defaultValue,
  color,
  title,
  onChange,
  value,
  type,
  plus,
  onClickPlus,
  onEdit,
}) => {
  const { use } = useTheme();
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

  const onChangeHandler = (value) => {
    onChange(name, value);
  };

  const onChangeTabClick = (value) => {
    onChange(value);
  };

  return type === 'tab' ? (
    <div className={[styles.container, styles.container_tab].join(' ')} style={{ ...style }}>
      <span>{title || ''}</span>
      <div className={styles.tab_switchRadio} style={{ marginTop: title ? 10 : 14 }}>
        {items?.map((item) => {
          const valueItem = item?.id || item?.value;
          const system = item?.system !== undefined ? item.system : null;
          const userLocalId = user?.id;
          const departmentName = user?.department?.department_name;
          let showEditIcon = false;
          if (system === null) showEditIcon = false;
          else if (+system === 1) showEditIcon = false;
          else if (+system === 0 && departmentName === 'Management') showEditIcon = true;
          else if (+system === 0 && departmentName !== 'Management' && +userLocalId === item.user_id)
            showEditIcon = true;

          return (
            <label className={styles.tab_switchRadio_label} style={{ marginTop: title ? 10 : 0 }} key={item.key}>
              <input
                hidden
                name={name}
                type='radio'
                value={valueItem}
                checked={value === valueItem || value?.value === valueItem}
                onChange={() => onChangeTabClick(item)}
                defaultChecked={defaultValue !== undefined ? valueItem === defaultValue : undefined}
              />
              <div className={styles.tab_switchRadio_item_wrap}>
                {!!color && (
                  <span
                    style={{
                      width: 10,
                      height: 10,
                      marginRight: 6,
                      borderRadius: '50%',
                      display: 'inline-block',
                      backgroundColor: item.color,
                    }}
                  />
                )}
                <span
                  className={styles.tab_switchRadio_item}
                  style={{
                    color:
                      value === valueItem || value?.value === valueItem
                        ? '#4F5AED'
                        : use(palette.gray700, palette.white),
                    fontWeight: value === valueItem || value?.value === valueItem,
                  }}
                >
                  {item.label}
                </span>

                <span
                  style={{
                    color:
                      value === valueItem || value?.value === valueItem
                        ? '#4F5AED'
                        : use(palette.gray700, palette.white),
                    backgroundColor: use(palette.indigo0, palette.dark600),
                  }}
                >
                  {item.totalCount}
                </span>
                {item.count !== undefined && item.label !== 'Audited' && item.label !== 'inactive' && (
                  <span
                    className={styles.tab_switchRadio_count}
                    style={{
                      color:
                        value === valueItem || value?.value === valueItem
                          ? '#4F5AED'
                          : use(palette.gray700, palette.white),
                      backgroundColor: use(palette.indigo0, palette.dark600),
                    }}
                  >
                    {item.count}
                  </span>
                )}

                {!!item.alert && (
                  <div className={styles.alertIconWrapper}>
                    <BellIcon width={18} height={18} fill='#ffbc00' />
                  </div>
                )}

                {typeof onEdit === 'function' && showEditIcon && (
                  <div
                    className={styles.tab_edit_wrapper}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onEdit(item);
                    }}
                  >
                    <EditIcon width={9} height={9} fill={palette.white} />
                  </div>
                )}
              </div>
            </label>
          );
        })}
        {!!plus && (
          <img
            alt='plus'
            width={20}
            height={20}
            src={PlusIcon}
            onClick={onClickPlus}
            className={styles.plus_icon}
            style={{ padding: 3, cursor: 'pointer' }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className={styles.container} style={{ ...style }}>
      <span>{title || ''}</span>
      <div className={styles.switchRadio} style={{ marginTop: 14 }}>
        {items.map((item) => {
          return (
            <label className={styles.switchRadio_label} key={item.key}>
              <input
                type='radio'
                hidden
                name={name}
                checked={+value === +item.value}
                value={item.value}
                defaultChecked={defaultValue !== undefined ? item.value === defaultValue : undefined}
                onChange={(e) => onChangeHandler(e.target.value)}
              />
              <span className={[styles.switchRadio_item, styles.switcher].join(' ')}>{item.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default SwitchRadio;
