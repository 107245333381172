import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { getSpeedLimitGraph } from 'Api/AccidentsAndCrashes';
import SpeedLimitChart from '../Charts/SpeedLimitChart';
import { SBoxWrapper } from '../../StatsSection.styles';

const SpeedLimit = ({ statsRefreshIndex }) => {
  const [speedLimit, setSpeedLimit] = useState([]);

  const getChartData = async () => {
    try {
      const { data } = await getSpeedLimitGraph();
      setSpeedLimit(data.speed_limit);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getChartData();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex flex-column gap-1'>
        <Typography variant='s2'>SPEED LIMIT</Typography>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          ROAD OR PLACE SPEED LIMITS
        </Typography>
      </div>
      <div className='chart-wrapper'>
        <SpeedLimitChart speedLimit={speedLimit} />
      </div>
    </SBoxWrapper>
  );
};

export default SpeedLimit;
