import styled from 'styled-components';
import { palette } from 'utils/constants';
import CircularProgress from '@mui/material/CircularProgress';

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 8px 0;
  border-bottom: 1px solid ${palette.gray50};
`;

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .header-item:nth-child(1) {
    width: 10%;
  }

  .header-item:nth-child(2) {
    width: 30%;
  }

  .header-item:nth-child(3) {
    width: 35%;
  }

  .header-item:nth-child(4) {
    width: 15%;
  }

  .header-item:nth-child(5) {
    width: 7%;
  }
  .header-item:nth-child(6) {
    width: 3%;
  }
`;

export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};
  transition: background 0.3s;

  .table-item:nth-child(1) {
    width: 10%;
  }

  .table-item:nth-child(2) {
    width: 30%;
  }

  .table-item:nth-child(3) {
    width: 35%;
  }

  .table-item:nth-child(4) {
    width: 15%;
  }

  .table-item:nth-child(5) {
    width: 7%;
  }

  .table-item:nth-child(6) {
    width: 3%;
  }

  .table-actions-wrapper {
    align-items: center;
    visibility: hidden;
  }

  :hover {
    background: #f7f9fc;

    .table-actions-wrapper {
      visibility: visible;
    }
  }
`;

export const SCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action_wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 24px;
    height: 24px;
  }

  .action_wrapper:hover {
    background-color: ${palette.indigo50};
  }

  .action-delete:hover {
    background-color: ${palette.red0};
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;

export const SUnderlineText = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  color: #4f5aed;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  :hover {
    text-decoration: underline;
  }
`;
