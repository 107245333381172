import React from 'react';

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    if (error?.name?.includes('ChunkLoadError')) {
      window.location.reload();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
