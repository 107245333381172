import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getAgeGroupGraph } from 'Api/AccidentsAndCrashes';
import AgeGroupChart from '../Charts/AgeGroupChart';
import { SBoxWrapper } from '../../StatsSection.styles';

export const AgeGroup = ({ statsRefreshIndex }) => {
  const [ageGroup, setAgeGroup] = useState([]);

  const getChartData = async () => {
    try {
      const { data } = await getAgeGroupGraph();

      const extractAge = (age_group) => {
        const ageBounds = age_group.split('-');
        return parseInt(ageBounds[0], 10);
      };

      const sortedData = data.age_groups.sort((a, b) => {
        const ageA = extractAge(a.age_group);
        const ageB = extractAge(b.age_group);
        return ageB - ageA;
      });
      setAgeGroup(sortedData);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getChartData();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex flex-column gap-1'>
        <Typography variant='s2'>AGE GROUP</Typography>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          DRIVER AGE IN COMPANY POOL
        </Typography>
      </div>
      <div className='chart-wrapper'>
        <AgeGroupChart ageGroup={ageGroup} />
      </div>
    </SBoxWrapper>
  );
};
