import React, { useEffect, useState } from 'react';
import { getDriverIncidentCount } from 'Api/Dashboard';
import { palette } from 'utils/constants';
import DoughnutChart from '../../Charts/DoughnutChart';

const labels = {
  0: 'Low Risk',
  1: 'High Risk',
};

const IncidentsRiskLevel = ({ year }) => {
  const [loading, setLoading] = useState(false);
  const [newIncidentsCounts, setNewIncidentsCounts] = useState(null);

  const getCounts = async () => {
    try {
      setLoading(true);
      const { data } = await getDriverIncidentCount();
      setNewIncidentsCounts(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const datasets = [
    {
      data: [newIncidentsCounts?.low_risk, newIncidentsCounts?.high_risk],
      backgroundColor: [palette.green400, palette.red400],

      borderColor: [palette.green400, palette.red400],
      hoverBackgroundColor: [palette.green500, palette.red500],
      hoverBorderColor: [palette.green500, palette.red500],
      borderWidth: 1,
    },
  ];

  useEffect(() => {
    getCounts();
  }, [year]);

  return (
    <div className='box-content-wrapper'>
      <DoughnutChart
        loading={loading}
        datasets={datasets}
        labels={labels}
        total={newIncidentsCounts?.total}
        title='Incidents - High Risk vs Low Risk'
      />
    </div>
  );
};

export default IncidentsRiskLevel;
