import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc, top, isStaff = false, isVehicle = false) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  if (isStaff) {
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 700);
    doc.setFontSize(6);
    // doc.text(tableName, 16, top - 4);
    doc.setFontSize(3.8);

    doc.text('STAFF', 16, top);
    doc.text('HOURLY RATE', 30, top);
    doc.text('TIME TRACKED', 42, top);
    doc.text('PAY PER SHIPMENT', 54, top);
    doc.text('BOOKED EMPTY (MI)', 69, top);
    doc.text('BOOKED LOADED (MI)', 85, top);
    doc.text('BOOKED TOTAL (MI)', 102, top);
    doc.text('BOOKED SHIPMENTS', 118, top);
    doc.text('GROSS RPM', 134, top);
    doc.text('GROSS REVENUE', 144, top);
    doc.text('GROSS PAY', 158, top);
    doc.text('NET PAY', 169, top);
    doc.text('MARGIN %', 181, top);
    doc.text('GROSS MARGIN', pageWidth - 16, top, { align: 'right' });

    doc.setDrawColor(188, 194, 206);
    doc.setLineWidth(0.1);
    doc.line(16, top + 2, pageWidth - 16, top + 2);
  } else if (isVehicle) {
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 700);
    doc.setFontSize(6);
    // doc.text(tableName, 16, top - 4);
    doc.setFontSize(4.4);
    doc.text(isVehicle ? 'VEHICLE' : 'DRIVER', 16, top);
    doc.text('FUEL CPM', 39.75, top);
    doc.text('FUEL COSTS', 52.75, top);
    doc.text('EMPTY (MI)', 70.75, top);
    doc.text('LOADED (MI)', 83.75, top);
    doc.text('TOTAL (MI)', 97.75, top);
    doc.text('SHIPMENTS', 110.75, top);
    doc.text('GROSS RPM', 122.75, top);
    doc.text('GROSS REVENUE', 135.75, top);
    doc.text('GROSS PAY', 155.75, top);
    doc.text('MARGIN %', 175, top);
    doc.text('GROSS MARGIN', pageWidth - 16, top, { align: 'right' });

    doc.setDrawColor(188, 194, 206);
    doc.setLineWidth(0.1);
    doc.line(16, top + 2, pageWidth - 16, top + 2);
  } else {
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 700);
    doc.setFontSize(6);
    // doc.text(tableName, 16, top - 4);
    doc.setFontSize(4.4);
    doc.text(isVehicle ? 'VEHICLE' : 'DRIVER', 16, top);
    doc.text('FUEL CPM', 38, top);
    doc.text('FUEL COSTS', 51, top);
    doc.text('EMPTY (MI)', 64, top);
    doc.text('LOADED (MI)', 77, top);
    doc.text('TOTAL (MI)', 91, top);
    doc.text('SHIPMENTS', 103, top);
    doc.text('GROSS RPM', 116, top);
    doc.text('GROSS REVENUE', 130, top);
    doc.text('GROSS PAY', 148, top);
    doc.text('NET PAY', 162, top);
    doc.text('MARGIN %', 175, top);
    doc.text('GROSS MARGIN', pageWidth - 16, top, { align: 'right' });

    doc.setDrawColor(188, 194, 206);
    doc.setLineWidth(0.1);
    doc.line(16, top + 2, pageWidth - 16, top + 2);
  }

  const lineHeight = doc.internal.getLineHeight();
  const newY = top + lineHeight;

  return newY;
};

const createTableRow = (doc, data, top, isStaff = false, isVehicle = false, currency) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  if (isStaff) {
    doc.setFontSize(3.8);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);
    doc.text(data?.name?.toString() || '-', 16, top);
    doc.text(data?.hourly_rate && !!parseFloat(data.hourly_rate) ? `${currency}${data?.hourly_rate}` : '-', 30, top);
    doc.text(data?.time_tracked?.toString() || '-', 42, top);
    doc.text(
      data?.pay_per_shipment && !!parseFloat(data.pay_per_shipment)
        ? `${data?.flat_or_per === 1 ? `${currency}` : ''}${data?.pay_per_shipment}${
            data?.flat_or_per === 2 ? '%' : ''
          }`
        : '-',
      54,
      top
    );
    doc.text(parseInt(data?.booked_empty?.toString(), 10)?.toString() || '-', 69, top);
    doc.text(parseInt(data?.booked_loaded?.toString(), 10)?.toString() || '-', 85, top);
    doc.text(parseInt(data?.booked_total?.toString(), 10)?.toString() || '-', 102, top);
    doc.text(data?.booked_shipments?.toString() || '-', 118, top);
    doc.text(data?.gross_rpm ? `${currency}${data?.gross_rpm}` : '-', 134, top);
    doc.text(data?.gross_revenue ? `${currency}${data?.gross_revenue}` : '-', 144, top);
    doc.text(data?.gross_pay ? `${currency}${data?.gross_pay}` : '-', 158, top);
    doc.text(data?.net_pay ? `${currency}${data?.net_pay}` : '-', 169, top);
    doc.text(data?.margin ? `${data?.margin}%` : '-', 181, top);
    doc.text(data?.gross_margin ? `${currency}${data?.gross_margin}` : '-', pageWidth - 16, top, { align: 'right' });
  } else if (isVehicle) {
    doc.setFontSize(4.4);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);
    doc.text(data?.name?.toString() || '-', 16, top);
    doc.text(data?.fuel_cpm ? `${currency}${data?.fuel_cpm}` : '-', 39.75, top);
    doc.text(data?.fuel_costs ? `${currency}${data?.fuel_costs}` : '-', 52.75, top);
    doc.text(parseInt(data?.empty?.toString(), 10)?.toString() || '-', 70.75, top);
    doc.text(parseInt(data?.loaded?.toString(), 10)?.toString() || '-', 83.75, top);
    doc.text(parseInt(data?.total?.toString(), 10)?.toString() || '-', 97.75, top);
    doc.text(data?.shipments?.toString() || '-', 110.75, top);
    doc.text(data?.gross_rpm ? `${currency}${data?.gross_rpm}` : '-', 122.75, top);
    doc.text(data?.gross_revenue ? `${currency}${data?.gross_revenue}` : '-', 135.75, top);
    doc.text(data?.gross_pay ? `${currency}${data?.gross_pay}` : '-', 155.75, top);
    doc.text(data?.margin ? `${data?.margin}%` : '-', 175, top);
    doc.text(data?.gross_margin ? `${currency}${data?.gross_margin}` : '-', pageWidth - 16, top, { align: 'right' });
  } else {
    doc.setFontSize(4.4);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);
    doc.text(data?.name?.toString() || '-', 16, top);
    doc.text(data?.fuel_cpm ? `${currency}${data?.fuel_cpm}` : '-', 38, top);
    doc.text(data?.fuel_costs ? `${currency}${data?.fuel_costs}` : '-', 51, top);
    doc.text(parseInt(data?.empty?.toString(), 10)?.toString() || '-', 64, top);
    doc.text(parseInt(data?.loaded?.toString(), 10)?.toString() || '-', 77, top);
    doc.text(parseInt(data?.total?.toString(), 10)?.toString() || '-', 91, top);
    doc.text(data?.shipments?.toString() || '-', 103, top);
    doc.text(data?.gross_rpm ? `${currency}${data?.gross_rpm}` : '-', 116, top);
    doc.text(data?.gross_revenue ? `${currency}${data?.gross_revenue}` : '-', 130, top);
    doc.text(data?.gross_pay ? `${currency}${data?.gross_pay}` : '-', 148, top);
    doc.text(data?.net_pay ? `${currency}${data?.net_pay}` : '-', 162, top);
    doc.text(data?.margin ? `${data?.margin}%` : '-', 175, top);
    doc.text(data?.gross_margin ? `${currency}${data?.gross_margin}` : '-', pageWidth - 16, top, { align: 'right' });
  }

  const lineHeight = doc.internal.getLineHeight();
  const newY = top + lineHeight;

  return newY;
};

export const generatePDF = async (
  data,
  download,
  dateRange,
  dateFormat,
  isStaff = false,
  isVehicle = false,
  currency
) => {
  const { reportItems, title } = data || {};
  if (!reportItems?.length) {
    return;
  }

  const doc = new jsPDF({ format: 'letter' });
  const { start, end } = dateRange;
  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.height;

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text(title || '', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  doc.text(`${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`, pageWidth / 2, 28, {
    align: 'center',
  });
  doc.setFont('Inter', 'normal', 400);
  doc.text(dateFormat.formatDateTime(), pageWidth - 16, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(16, 30, pageWidth - 16, 30);

  let index = 0;
  let page = 1;
  let top = 40;

  // let firstHeader = true;
  // let pageElementIndex = 0;
  // let headersHeight = 0;

  for (const reportItem of reportItems) {
    doc.setFont('Inter', 'normal', 700);

    // createRows
    // if (!reportItem?.length) return;

    index === 0 && createTableHeader(doc, top, isStaff, isVehicle);
    const lastRowHeight = createTableRow(doc, reportItem, top + 6 + index * 5, isStaff, isVehicle, currency);
    if (lastRowHeight > pageHeight - 15) {
      index = 0;
      page += 1;
      top = 0;
      doc.addPage(page);
      createTableHeader(doc, top + 6, isStaff, isVehicle);
    }

    // let topTableValue = reports.length * 5;

    // reports.forEach((report, idx) => {
    //   pageElementIndex += 1;
    //
    //   if (idx === 0) {
    //     if (firstHeader) {
    //       createTableHeader(doc, name, top, isStaff);
    //     } else {
    //       headersHeight += 12;
    //       let y = createTableHeader(doc, name, top + headersHeight + pageElementIndex * 5, isStaff);
    //       if (y > pageHeight - 15) {
    //         const notLastRow = report.length - 1 !== index;
    //         pageElementIndex = 0;
    //         page += 1;
    //         top = 16;
    //         doc.addPage(page);
    //         topTableValue = 0;
    //         headersHeight = 0;
    //         notLastRow && createTableHeader(doc, name, top, isStaff);
    //       }
    //     }
    //     firstHeader = false;
    //   }
    //
    //   let lastRowHeight = createTableRow(doc, report, top + headersHeight + 6 + pageElementIndex * 5, isStaff);
    //
    //   if (lastRowHeight > pageHeight - 15) {
    //     const notLastRow = report.length - 1 !== index;
    //     pageElementIndex = 0;
    //     page += 1;
    //     top = 16;
    //     doc.addPage(page);
    //     topTableValue = 0;
    //     headersHeight = 0;
    //     notLastRow && createTableHeader(doc, name, top, isStaff);
    //   }
    // });

    index += 1;
  }

  /** FOOTER * */
  const totalPages = doc.internal.getNumberOfPages();
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber;
    doc.setFontSize(12);
    createFooter(doc, page, totalPages);
  }

  if (download) {
    doc.save(`Asset-Performance.pdf`);
  }

  const url = doc.output('datauristring', { filename: 'Asset Performance' });
  const blob = doc.output('blob', { filename: 'Asset Performance' });
  // window.open(URL.createObjectURL(blob));

  return { blob, url };
};
