import React from 'react';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';

export const sortByResolvedStatusToEnd = (a, b) => {
  if (a.status === 'Resolved') return 1;
  if (b.status === 'Resolved') return -1;
  return 0;
};

export const backgroundColors = {
  'Bug or Issue': { color: palette.red400, bgColor: palette.red0 },
  'Question / Training': { color: palette.indigo400, bgColor: palette.indigo0 },
  'Integration Request': { color: palette.amber400, bgColor: palette.amber0 },
  'Feature Request': { color: palette.orange500, bgColor: palette.orange0 },
  Billing: { color: palette.green400, bgColor: palette.green0 },
  Low: { color: palette.green400, bgColor: palette.green0 },
  Medium: { color: palette.indigo400, bgColor: palette.indigo0 },
  High: { color: palette.orange500, bgColor: palette.orange0 },
  Urgent: { color: palette.red500, bgColor: palette.red0 },
};

export const useColumns = ({ sort, sortingQuery }) => {
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'ticket_id',
      title: <ColumnHeader title='TICKET ID' field='ticket_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const ticketId = rowData?.ticket_id;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='s2' style={{ color: palette.dark800 }}>
              {ticketId}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'title',
      title: <ColumnHeader title='TITLE' field='title' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const title = rowData?.title;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: palette.dark800 }}>
              {title}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'description',
      title: <ColumnHeader title='DESCRIPTION' field='description' />,
      render: (rowData) => {
        const description = rowData?.description;
        return (
          <div style={{ display: 'flex', inlineSize: 400, whiteSpace: 'initial' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {description}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'status_id',
      title: <ColumnHeader title='STATUS' field='status_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const status = rowData?.status?.status;
        const color = rowData?.status?.color;
        const bgColor = rowData?.status?.background_color;
        return (
          <div className='d-flex align-items-center justify-content-between'>
            <Chip label={status} labelColor={color} bgColor={bgColor} fontSize='14px' />
          </div>
        );
      },
    },
    {
      field: 'type_id',
      title: <ColumnHeader title='TYPE' field='type_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const type = rowData?.type?.name;
        const color = backgroundColors?.[rowData?.type?.name]?.color;
        const bgColor = backgroundColors?.[rowData?.type?.name]?.bgColor;
        return (
          <div className='d-flex align-items-center justify-content-between'>
            {type ? (
              <Chip label={type} labelColor={color} bgColor={bgColor} fontSize='14px' />
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                -
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'priority_id',
      title: <ColumnHeader title='PRIORITY' field='priority_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const priority = rowData?.priority?.name;
        const color = backgroundColors?.[rowData?.priority?.name]?.color;
        const bgColor = backgroundColors?.[rowData?.priority?.name]?.bgColor;
        return (
          <div className='d-flex align-items-center justify-content-between'>
            {priority ? (
              <Chip label={priority} labelColor={color} bgColor={bgColor} fontSize='14px' />
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                -
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'time_estimate',
      title: <ColumnHeader title='TIME ESTIMATE' field='time_estimate' />,
      render: (rowData) => {
        const timeEstimate = rowData?.time_estimate;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {timeEstimate || <span style={{ marginLeft: 42 }}> - </span>}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='CREATED ON' field='created_at' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const createdOn = rowData?.created_at;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {createdOn ? convertToCustomerTime(createdOn) : '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'created_by',
      title: <ColumnHeader title='CREATED BY' field='created_by' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const createdBy = rowData?.created_by;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {createdBy}
            </Typography>
          </div>
        );
      },
    },
  ];
};
