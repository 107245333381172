import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Tabs from 'common/Tabs';
import Search from 'common/Search';
import Divider from 'common/Divider';
import Pagination from 'common/Pagination';
import HeaderStar from 'components/HeaderStar';
import TableSkeleton from 'common/TableSkeleton';
import SendQuote from 'componentsV2/Planner/Quotes/SendQuote';
import CustomButton from 'components/CustomButton/CustomButton';
import CreateQuote from 'componentsV2/Planner/Quotes/CreateQuote';
import QuoteEvents from 'componentsV2/Planner/Quotes/QuoteEvents';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { getAppUrl } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { getTabs } from 'Api/User';
import { acceptQuote, getQuote, getQuotes, updateQuote } from 'Api/Shipment';
import ViewPdf from 'components/ViewPdf';
import { initialFilters, useColumns } from './QuotesTable.data';
import { SPageWrapper, SHeaderWrapper, SFiltersWrapper } from './QuotesTable.styles';

const QuotesTable = () => {
  const showToaster = useShowToaster();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabs, setTabs] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState({ data: [] });
  const [openCreate, setOpenCreate] = useState(false);
  const [quoteToViewLogs, setQuoteToViewLogs] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [quoteToEdit, setQuoteToEdit] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [quotesToSend, setQuotesToSend] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingMultipleQuotes, setLoadingMultipleQuotes] = useState(false);
  const { value: userData } = useAuth();
  const controller = useRef(new AbortController());

  const getQuotesList = async () => {
    try {
      if (controller?.current) {
        controller.current.abort();
        controller.current = new AbortController();
      }

      setLoading(true);
      const sortField = filters.sort?.field ? `sort[][${filters.sort?.field}]` : null;
      const params = {
        page: filters.page,
        itemsPerPage: filters.itemsPerPage,
        'filters[query]': search || undefined,
        [sortField]: sortField ? filters.sort?.sortBy : undefined,
      };

      Object.entries(tabs[filters.tab]?.filters || {}).forEach(([key, value]) => {
        params[`filters[${key}]`] = value;
      });

      const response = await getQuotes(params, controller?.current?.signal);
      getTabsData(response);
      setQuotes(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getTabsData = async (shipmentsData) => {
    try {
      const { data } = await getTabs({ type: 'quote' });
      setTabs((prevState) => [
        {
          key: 'all',
          label: 'All',
          value: 0,
          count: filters.tab === 0 ? shipmentsData.total : prevState[0]?.count || 0,
        },
        ...data.map((tab, index) => ({
          key: tab.data.name,
          label: tab.data.name,
          value: index + 1,
          count: tab.count,
          filters: tab.data.filters,
        })),
      ]);
    } catch (e) {
      // Do nothing
    }
  };

  const getSingleQuote = async (quoteId) => {
    try {
      const { data } = await getQuote(quoteId);
      return data;
    } catch (e) {
      // Do nothing
    }
  };

  const getMultipleQuotes = async (quoteIds) => {
    try {
      setLoadingMultipleQuotes(true);
      const responses = await Promise.all(quoteIds.map((id) => getQuote(id)));
      return responses.map((response) => response.data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingMultipleQuotes(false);
    }
  };

  const cancelQuote = async (quoteId) => {
    try {
      await updateQuote(quoteId, { status_id: 6 });
      showToaster({ type: 'success', message: `Quote has been successfully canceled!` });
      getQuotesList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
  };

  const onAcceptQuote = async (quote) => {
    try {
      await acceptQuote({ quote_id: quote.id });
      showToaster({ type: 'success', message: `Quote has been successfully accepted!` });
      getQuotesList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
  };

  // const sortingQuery = (field) => {
  //   const direction = filters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
  //   setFilters((prevState) => ({ ...prevState, sort: { field, sortBy: direction } }));
  // };

  const onSendQuotesSuccess = () => {
    setSelectedRows([]);
    getQuotesList();
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (event, page) => {
    setFilters((prevState) => ({ ...prevState, page }));
  };

  const handleTabChange = (tab) => {
    setSearch('');
    setFilters((prevState) => ({ ...prevState, tab }));
  };

  const onEdit = async (quote) => {
    const data = await getSingleQuote(quote.id);

    if (data) {
      setQuoteToEdit(data);
      setOpenCreate(true);
    }
  };

  const onSendQuote = async (quote) => {
    const data = await getSingleQuote(quote.id);

    if (data) {
      setQuotesToSend([data]);
    }
  };

  const onSendMultipleQuotes = async (quotes) => {
    const quotesData = await getMultipleQuotes(quotes.map((quote) => quote.id));

    if (quotesData?.length) {
      setQuotesToSend(quotesData);
    }
  };

  const onPreviewQuote = async (quote) => {
    if (quote?.quote_pdf_path) {
      setPdfUrl(quote.quote_pdf_path);
    }
  };

  const onViewLogs = (quote) => {
    setQuoteToViewLogs(quote);
  };

  const onShareLink = (quote) => {
    navigator.clipboard.writeText(`${getAppUrl()}/${userData.user.customer.dot}/quote/${quote.hash}`);
    showToaster({ type: 'success', message: 'Link has been copied to clipboard!' });
  };

  const onCancelQuote = async (quote) => {
    cancelQuote(quote.id);
  };

  useEffect(() => {
    getQuotesList();
  }, [search, filters]);

  useEffect(() => {
    const open = searchParams.get('openCreate');

    if (open) {
      setOpenCreate(true);
      searchParams.delete('openCreate');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const columns = useColumns({
    handleSelectRow,
    selectedRows,
    onEdit,
    onViewLogs,
    onPreviewQuote,
    onSendQuote,
    onShareLink,
    onAcceptQuote,
    onCancelQuote,
  });

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Quotes' />
        <CustomButton
          title='Create Quote'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14, fontWeight: 500 }}
          leftIcon={<PlusIcon fill={palette.white} className='me-2' />}
          onClick={() => setOpenCreate(true)}
        />
      </SHeaderWrapper>
      <SFiltersWrapper>
        <Search search={search} onChange={setSearch} className='search-input' />
      </SFiltersWrapper>
      {!!selectedRows.length && (
        <div>
          <CustomButton
            type='primary'
            title={`Send Quotes (${selectedRows.length})`}
            styleButton={{ padding: '4px 12px', margin: 0 }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => onSendMultipleQuotes(selectedRows)}
            disabled={loadingMultipleQuotes}
          />
        </div>
      )}
      <Tabs tabs={tabs} activeTab={filters.tab} handleTabChange={handleTabChange} />
      <Divider margin='0' />
      <div>
        {loading ? (
          <TableSkeleton />
        ) : (
          <MaterialTableWrapper
            data={quotes?.data}
            rowPerPage={1000}
            style={{ backgroundColor: palette.white }}
            components={{
              Pagination: () =>
                Pagination({
                  data: quotes,
                  rowPerPage: filters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                }),
            }}
            columns={columns}
          />
        )}
      </div>
      {openCreate && (
        <CreateQuote
          open={openCreate}
          onClose={() => {
            setOpenCreate(false);
            setQuoteToEdit(null);
          }}
          onSuccess={getQuotesList}
          quote={quoteToEdit}
        />
      )}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />}
      {!!quoteToViewLogs && (
        <QuoteEvents open={!!quoteToViewLogs} onClose={() => setQuoteToViewLogs(null)} quote={quoteToViewLogs} />
      )}
      {!!quotesToSend.length && (
        <SendQuote
          open={!!quotesToSend?.length}
          onClose={() => setQuotesToSend([])}
          quotes={quotesToSend}
          onSuccess={onSendQuotesSuccess}
        />
      )}
    </SPageWrapper>
  );
};

export default QuotesTable;
