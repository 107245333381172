import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Shipment } from 'Api/Shipment';
import PlannedLoadsTable from './PlannedLoadsTable';

const PlannedLoads = ({ open, onClose, onSuccess, shipmentData, hideView }) => {
  const { equipment_id, equipment_type, id } = shipmentData?.equipment || {};
  const { brokerage_dispatch } = shipmentData || {};
  const { carrier } = brokerage_dispatch || {};
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const getShipments = async () => {
    let queryData;
    try {
      if (carrier) {
        queryData = {
          page: 1,
          itemsPerPage: 100,
          allFilterData: {
            status: [1, 2, 8],
            carrier_ids: [carrier.id],
          },
          sort: ['sort[][status]:desc'],
        };
      } else {
        queryData = {
          page: 1,
          itemsPerPage: 100,
          allFilterData: {
            status: [1, 2, 8],
            equipment: [id],
          },
          sort: ['sort[][status]:desc'],
        };
      }

      const { data } = await Shipment(queryData);
      data.sort((a, b) => {
        const dateA = a.shipment_stops[0]?.scheduled_date || '';
        const timeA = a.shipment_stops[0]?.from || '';
        const dateB = b.shipment_stops[0]?.scheduled_date || '';
        const timeB = b.shipment_stops[0]?.from || '';

        const dateTimeA = `${dateA} ${timeA}`;
        const dateTimeB = `${dateB} ${timeB}`;

        return new Date(dateTimeA).getTime() - new Date(dateTimeB).getTime();
      });

      setShipments(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== shipments.length ? shipments : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.shipment_id !== row.shipment_id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  useEffect(() => {
    setLoading(true);
    getShipments();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <div>
          {carrier
            ? `Planned Shipments for ${carrier.name}`
            : `Planned Shipments for ${equipment_id} ${equipment_type?.title || ''}`}
        </div>
      }
      $bgColor={palette.gray0}
      $maxWidth='1000px'
      $minWidth='1000px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        ...(!hideView
          ? [
              {
                key: 'close',
                type: 'secondary',
                title: 'View Selected',
                onClick: () => onSuccess(selectedRows),
                disabled: !selectedRows.length,
              },
            ]
          : []),
        {
          key: 'done',
          type: 'primary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      {loading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <CircularProgress size={30} />
        </div>
      ) : (
        <PlannedLoadsTable
          shipments={shipments}
          selectedRows={selectedRows}
          handleSelectRow={handleSelectRow}
          handleSelectAll={handleSelectAll}
          allSelected={shipments.length === selectedRows.length}
          hideView={hideView}
        />
      )}
    </Modal>
  );
};

export default PlannedLoads;
