import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import CustomModal from 'components/CustomModal/CustomModal';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  padding-top: 16px;
`;

export const STableWrapper = styled.div`
  padding-bottom: 24px;
`;

export const SCustomModal = styled(CustomModal)`
  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '860px'};
    max-width: ${({ $maxWidth }) => $maxWidth || ''};
    height: ${({ $height }) => $height || 'calc(100vh - 46px)'};

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: 100%;
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      overflow: auto;
      border-radius: 0;

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      border-bottom: 1px solid ${palette.gray50};
    }

    .customFooter {
      padding: 12px 20px;
      border-top: 1px solid ${palette.gray50};

      button {
        padding: 6px 12px !important;
        margin: 0 !important;

        span {
          font-size: 14px !important;
          font-weight: 500;
          font-family: Inter, sans-serif;
        }
      }

      .modal-cancel-btn {
        margin-right: 16px !important;
      }

      .footer_buttons {
        justify-content: ${({ $footerJustify }) => $footerJustify || 'flex-end'};
      }
    }
  }
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0;
    margin-top: 12px;

    .MuiTabs-flexContainer {
      column-gap: 8px;
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      min-height: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      padding: 3px 10px;
      border-radius: 24px;
      text-transform: none;
      color: ${palette.gray900};

      &.Mui-selected {
        background-color: ${palette.indigo500};
        color: ${palette.white};
      }
    }
  }
`;

export const SStatusTabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0;

    .MuiTabs-flexContainer {
      display: inline-flex;
      background-color: ${palette.gray50};
      border-radius: 4px;
      padding: 2px;
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiButtonBase-root {
      min-height: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      border-radius: 4px;
      padding: 4px 10px;
      text-transform: none;
      color: ${palette.gray700};

      &.Mui-selected {
        background-color: ${palette.white};
        color: ${palette.indigo500};
      }
    }
  }
`;

export const SNavigation = styled.div`
  position: absolute;
  width: calc(100% - 16px);
  top: 96px;
  left: 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #ffffff 23.6%, rgba(255, 255, 255, 0) 100%);
  padding: 12px;
`;

export const SImage = styled.img`
  max-width: 360px;
  aspect-ratio: 3/2;
  box-shadow: 0 0 0 1px rgba(152, 161, 178, 0.1), 0 10px 15px -3px rgba(17, 24, 38, 0.1),
    0 5px 10px -2px rgba(0, 0, 0, 0.06);
`;
