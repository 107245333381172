import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import useDebounce from 'hooks/useDebounce';
import { formatNumber, numberWithCommas, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { toggleManageModalIsVisible } from 'store/reducers/billing.reducer';
import { GetBillingManageList, RemoveManageStaff, RemoveManageDriver } from 'Api/Billing';
import { getErrorMessage } from 'utils/error';
import Pagination from 'common/Pagination';
import { Typography } from 'components/Typography';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { DocumentTableSort } from 'components/DocumentsTableSort/DocumentsTableSort';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import { useAuth } from 'context/auth.context';
import styles from './ManageModale.module.css';
import ManageSearch from './ManageSearch/ManageSearch';
import ManageRemoveModal from './ManageRemoveModal/ManageRemoveModal';

const ManageModal = () => {
  const { theme, use } = useTheme();
  const { value } = useAuth();
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const elementsRef = useRef();
  const { billingDetail, manageModalIsVisible } = useSelector((state) => state.billing);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const [showTool, setShowTool] = useState({});
  const [removeModal, setRemoveModal] = useState({
    visible: false,
  });
  const [manageList, setManageList] = useState({ data: [] });
  const [filter, setFilter] = useState({
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    filterTableTop: [],
    selectedValues: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    tab: 0,
    page: 1,
    itemsPerPage: 25,
  });

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        width: '5%',
        title: <DocumentTableSort title='NAME' field='name' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const name = rowData?.name;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='s2' style={{ color: use(palette.dark800, palette.gray200) }}>
                {name || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'type',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            TYPE
          </Typography>
        ),
        render: (rowData) => {
          const type = rowData?.type;
          return (
            <div style={{ display: 'flex', whiteSpace: 'initial' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {type || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'date_added',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DATE ADDED
          </Typography>
        ),
        render: (rowData) => {
          const date_added = rowData?.date_added;
          return (
            <div style={{ display: 'flex', whiteSpace: 'initial' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {date_added || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'date_expires',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DATE RENEWS
          </Typography>
        ),
        render: (rowData) => {
          const date_expire = rowData?.date_expire;
          return (
            <div style={{ display: 'flex', whiteSpace: 'initial' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {date_expire || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'cost',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            COST
          </Typography>
        ),
        render: (rowData) => {
          const price = Number(rowData?.amount);
          const duration = rowData?.duration;
          return rowData.status === 5 ? (
            <div>No license</div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
              <Typography variant='s3'>${numberWithCommas(price?.toFixed(2))}</Typography>
              <span className={styles.duration}>{duration}</span>
            </div>
          );
        },
      },
      {
        field: 'remove',
        title: <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }} />,
        render: (rowData) => {
          return (
            <div className={styles.lastRow}>
              {rowData.id !== value?.user?.id && (
                <div onMouseLeave={() => setShowTool((prev) => ({ ...prev, [rowData.id]: false }))}>
                  <div
                    ref={elementsRef?.current?.[rowData.id]}
                    className={classNames([styles.editIconBox, { [styles.show]: showTool[rowData.id] }])}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowTool((prev) => ({ ...prev, [rowData.id]: true }));
                    }}
                  >
                    {rowData.status === 5 ? (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          onAssignLicense(rowData);
                        }}
                        className={styles.add}
                      >
                        Add
                      </span>
                    ) : (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemoveModal({
                            visible: true,
                            ...rowData,
                          });
                        }}
                        className={styles.remove}
                      >
                        Remove
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }, [manageList?.data, showTool, elementsRef, theme]);

  const onAssignLicense = async (user) => {
    try {
      if (user.type === 'Driver') {
        await RemoveManageDriver(user.id, {
          status: 1,
          status_title: 'Available',
        });
      }
      if (user.type === 'Staff') {
        await RemoveManageStaff(user.id, {
          login_status: 1,
          status: {
            id: 1,
            staff_status: 'Available',
          },
        });
      }
      await getManageList();
      showToaster({ type: 'success', message: 'User License has been successfully assigned!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    }
  };

  const getManageList = async () => {
    setLoading(true);
    const { page, itemsPerPage, types, ...rest } = selectedFilters || {};
    const tabIndex = selectedFilters?.tab;
    const tab = tabIndex === 0 ? 'drivers,staff' : tabIndex === 1 ? 'drivers' : 'staff';
    try {
      const params = {
        page,
        itemsPerPage,
        query: debouncedSearch || undefined,
        ...rest,
        tab,
      };
      const manages = await GetBillingManageList(params);
      setManageList(manages);
      setFilter((prevState) => {
        return {
          ...prevState,
          filterTableTop: [
            {
              key: 'driver',
              label: 'Driver',
              value: 1,
              id: 1,
              count: manages?.driverCount || 0,
            },
            {
              key: 'staff',
              label: 'Staff',
              value: 2,
              id: 2,
              count: manages?.staffCount,
            },
          ],
        };
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getManageList();
  }, [debouncedSearch, selectedFilters]);

  function sortingQuery(field, sortBy, nested_field) {
    let sortField;
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]`;
    } else {
      sortField = `sort[][${field}]`;
    }

    setSelectedFilters({ ...selectedFilters, [sortField]: sortBy });
  }

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters({ ...selectedFilters, page });
  };

  const onChangeTab = (n) => {
    setSelectedFilters({ ...selectedFilters, tab: +n.value });
  };

  const duration = billingDetail?.customer_plan?.plan?.duration;

  return (
    <>
      <div className={styles.manage_wrapper}>
        <div className={styles.body_wrapper}>
          <div className={styles.manage_header}>
            <div className={styles.info_block}>
              <div className={styles.info_item}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Plan Type
                </Typography>
                {billingDetail && (
                  <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                    {planMapper[duration]?.title}
                  </Typography>
                )}
              </div>
              <div className={styles.item_line}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Available License(s)
                </Typography>
                <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                  {billingDetail?.customer_plan?.user_available_count || ''}
                </Typography>
              </div>
              <div className={styles.item_line}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Assigned License(s)
                </Typography>
                <div>
                  <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                    {billingDetail?.customer_plan?.user_licensed || ''}
                  </Typography>
                </div>
              </div>
              <div className={styles.item_line}>
                <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
                  Recurring
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                    ${formatNumber(billingDetail?.customer_plan?.amount)}
                  </Typography>
                  <span className={styles.duration}>
                    {duration === 'monthly' ? 'MONTHLY' : duration === 'yearly' ? 'YEARLY' : 'EVERY 3 YEARS'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.search_wrapper}>
            <ManageSearch serarch={search} onChange={setSearch} />
          </div>
          <div className={styles.table_wrapper}>
            <SwitchRadio
              color
              name='tableTopTabMenu'
              items={[
                {
                  key: 'all',
                  label: 'All',
                  value: 0,
                  id: 0,
                  count: Number(manageList?.driverCount) + Number(manageList?.staffCount) || 0,
                },
                ...filter.filterTableTop,
              ]}
              value={selectedFilters.tab}
              type='tab'
              onChange={onChangeTab}
              plus={false}
            />
          </div>
          <div className={styles.table_wrapper}>
            <div style={{ display: loading ? 'block' : 'none' }}>
              <Preloader />
            </div>
            <div style={{ display: !loading ? 'block' : 'none' }}>
              <MaterialTableWrapper
                data={manageList?.data}
                rowPerPage={1000}
                style={{ backgroundColor: use(palette.white, palette.dark800) }}
                components={{
                  Pagination: () =>
                    Pagination({
                      data: manageList,
                      rowPerPage: selectedFilters.itemsPerPage,
                      onChangeRowPerPage,
                      onPageChange,
                    }),
                }}
                columns={columns}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer_buttons}>
          <CustomButton
            type='secondary'
            onClick={() => dispatch(toggleManageModalIsVisible(!manageModalIsVisible))}
            title='Done'
            styleButton={{ margin: 10, padding: '6px 12px' }}
            styleTitle={{
              fontSize: 12,
              marginTop: 1,
            }}
          />
        </div>
      </div>

      {!!removeModal?.visible && (
        <ManageRemoveModal
          show={removeModal?.visible}
          data={removeModal || {}}
          getManageList={getManageList}
          onClose={() => setRemoveModal({ visible: false })}
        />
      )}
    </>
  );
};
export default ManageModal;
