import React from 'react';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { SFooter, SPrimaryButton, SSecondaryButton } from '../CarrierConfirmation.styles';

const CarrierConfirmationFooter = ({ step, stepsCount, onBack, onNext, disabled }) => {
  return (
    <SFooter step={step}>
      {step !== 0 && (
        <SSecondaryButton onClick={onBack} disabled={disabled}>
          <ArrowLeft />
          Back
        </SSecondaryButton>
      )}
      <div className='next-actions'>
        {step === stepsCount - 2 ? (
          <SPrimaryButton onClick={onNext} disabled={disabled}>
            Submit
          </SPrimaryButton>
        ) : (
          <SPrimaryButton onClick={onNext} disabled={disabled} className='icon-button'>
            Next
            <ArrowRight />
          </SPrimaryButton>
        )}
      </div>
    </SFooter>
  );
};

export default CarrierConfirmationFooter;
