import React from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import './DriverLicense.css';

const DriverLicense = ({ picture }) => {
  const { use } = useTheme();

  return (
    <div
      className='driver-license-container driver-style-wrap'
      style={{
        backgroundColor: use(palette.gray0, palette.dark600),
        borderColor: use(palette.gray50, palette.dark900),
      }}
    >
      <iframe title='document' src={picture} className='picture-contain' />
    </div>
  );
};

export default DriverLicense;
