import React, { useState } from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/createShipment/download.svg';
import sm from '../Documents.module.css';

const FileActions = ({ onDelete, onDownload, ...props }) => {
  const { use } = useTheme();
  const [active, setActive] = useState(false);

  const onDownloadHandler = (e) => {
    e.stopPropagation();
    onDownload(e);
  };

  const onDeleteHandler = (e) => {
    e.stopPropagation();
    onDelete(e);
  };

  const onMouseOver = () => {
    setActive(true);
  };

  const onMouseLeave = () => {
    setActive(false);
  };

  return (
    <div style={{ ...props.style }} className={sm.deleted_component_dropZone}>
      <div
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onDownloadHandler}
        className={sm.delete_icon_wrapper}
        style={{ borderLeftColor: use(palette.indigo50, palette.indigo100) }}
      >
        <DownloadIcon
          fill={active ? palette.indigo400 : palette.gray500}
          width={12}
          height={14}
          style={{ marginBottom: 4, transition: '0.2s all ease' }}
        />
      </div>
      <div
        onClick={onDeleteHandler}
        className={sm.delete_icon_wrapper}
        style={{ borderLeftColor: use(palette.indigo50, palette.indigo100) }}
      >
        <DeleteIcon
          fill={use(palette.red400, palette.red500)}
          width={12}
          height={14}
          style={{ margin: '0 1px 1px 0', transition: '0.2s all ease' }}
        />
      </div>
    </div>
  );
};

export default FileActions;
