const medicalTitlesMapper = {
  medicalExaminersResults: 'Medical Examiners Results',
  mvrCheckResults: 'MVR Check Results',
  drugTestResults: 'Drug & Alcohol Results',
  criminalCheckResults: 'Criminal Check Results',
  pspResults: 'PSP Results',
  fmcsaResults: 'FMCSA Clearinghouse Result',
};

export const transformPrevEmploymentPdf = (prevEmployment) => {
  const transformed = [];

  prevEmployment.forEach((item, index) => {
    const itemWithoutAttempts = { ...item, isEmployer: true };
    delete itemWithoutAttempts.driver_employment_attempts;

    if (index !== 0) {
      transformed.push({ isBlank: true }, { isBlank: true });
    }
    transformed.push(itemWithoutAttempts);

    item.driver_employment_attempts.forEach((attempt, i) => {
      transformed.push({ ...attempt, attemptNumber: i + 1 });
    });
  });

  return transformed;
};

export const medicalBackgroundConverter = (data) => {
  if (!data) {
    return [];
  }

  return Object.keys(data).map((key) => {
    const listData = { title: medicalTitlesMapper[key], data: [] };

    data[key].forEach((item) => {
      listData.data.push(item);
    });

    return listData;
  });
};

export const transformReportItems = (medicalData) => {
  const transformed = [];

  medicalData.forEach((item, index) => {
    const categoryWithoutItems = { ...item, isCategory: true };
    delete categoryWithoutItems.data;

    if (index !== 0) {
      transformed.push({ isBlank: true }, { isBlank: true });
    }
    transformed.push(categoryWithoutItems);

    item.data.forEach((record) => {
      transformed.push(record);
    });
  });

  return transformed;
};
