import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { getAlphabet, palette, statusColorInStatusId, TRUCK_TYPES } from 'utils/constants';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { getShipmentInfo, updateCellularTracking } from 'Api/Shipment';
import { getSMSCommunicationSettings } from 'Api/CompanySettings';
import { ReactComponent as WarningIcon } from 'assets/icons/detention.svg';
import { ReactComponent as SubRight } from 'assets/icons/vehicles/sub-right.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/closeRed.svg';
import { deleteDispatch, UpdateShare } from 'Api/Planner';
import { PusherJs } from 'common/pusher';
import ConfirmModal from 'common/ConfirmModal';
import { updateRowEditItem } from 'store/reducers/root.reducer';
import person from 'assets/images/person.svg';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import RestrictedCheckIn from 'components/TableShipments/components/RestrictedCheckIn';
import RequestCellularTracking from 'components/TableShipments/components/RequestCellularTracking';
import { getErrorMessage } from 'utils/error';
import { formatPhoneNumber } from 'utils/helpers';
import { getAverageTimeConverted } from '../StopPoint/Walmart/helpers';
import TableShipmentRowEditChildes from '../TableShipments/TableShipmentRowEditChildes';
import { equipmentTypeText, getStatusParamsInStatus } from '../TableShipments/helpers/constants';
import {
  MAP_DISPATCH_EDIT,
  MAP_DISPATCH_EDIT_COMPLETED,
  MAPPER_STOPS,
  PENDING_DISPATCH_ACTIONS,
  DRY_RUN_ACTIONS,
} from '../CreateShipment/ShipmentStops/helpers/constants';
import TableRowsEdit from '../TableRowsEdit';
import Map from '../CreateShipment/ShipmentStops/helpers/Map';
import { Typography } from '../Typography';
import classes from './dispatchVehicleMap.module.css';

const DispatchVehicleMap = () => {
  const { value } = useAuth();
  const { user } = value;
  const { use } = useTheme();
  const { id } = useParams();
  const showToaster = useShowToaster();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatDate, formatDateTime } = useDateFormat();
  const [infoHeader, setInfoHeader] = useState({});
  const [mobileAppState, setMobileAppState] = useState();
  const [route, setRoute] = useState([]);
  const [tableShipmentModalObj, setTableShipmentModalObj] = useState({});
  const [preventBack, setPreventBack] = useState(false);
  const [openRestrictedCheckIn, setOpenRestrictedCheckIn] = useState(false);
  const [openRequestTracking, setOpenRequestTracking] = useState(false);
  const [openStopTracking, setOpenStopTracking] = useState(false);
  const [openConfirmCarrierCheckin, setOpenConfirmCarrierCheckin] = useState(null);

  const { shipment_stops } = infoHeader || {};
  const stops = shipment_stops || [];

  const {
    status,
    equipment,
    assigned_to_1,
    assigned_to1,
    assigned_to_2,
    assigned_to2,
    miles_remaining,
    brokerage_dispatch,
    cellular_tracking_status,
  } = infoHeader || {};

  const { carrier, contact_user, confirmation_signed } = brokerage_dispatch || {};
  const { logo_path } = carrier || {};
  const styleStatus = useMemo(() => status && status?.id && getStatusParamsInStatus(status), [infoHeader]);

  const updateStopInRoute = ({ stops, routeArr }) => {
    const route = [];
    let indexStop = null;

    if (routeArr) {
      for (let i = 0; i < routeArr.length; i++) {
        const stopPoint = routeArr[i]?.stopPoint;
        if (stopPoint) {
          indexStop === null ? (indexStop = 0) : (indexStop += 1);
          const {
            geofencing_latitude: latitude,
            geofencing_longitude: longitude,
            location_name,
          } = stops?.[indexStop]?.stop_point || {};
          !!latitude &&
            !!longitude &&
            route.push({
              latitude,
              longitude,
              location_name,
              stop_point_type: stops?.[indexStop]?.stop_point_type,
            });
        } else {
          route.push(routeArr[i]);
        }
      }
    } else {
      for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];
        const {
          geofencing_latitude: latitude,
          geofencing_longitude: longitude,
          location_name,
        } = stop?.stop_point || {};
        !!latitude &&
          !!longitude &&
          route.push({ latitude, longitude, location_name, stop_point_type: stop?.stop_point_type });
      }
    }
    setRoute(route);
  };

  const revertDispatch = async () => {
    try {
      await deleteDispatch({ shipment_id: infoHeader.shipment_id });
      if (infoHeader?.brokerage_dispatch) {
        navigate(
          `/planner/carrier-dispatch?id=${infoHeader.shipment_id}&carrier=${infoHeader.brokerage_dispatch.carrier_id}`
        );
      } else {
        navigate(`/planner/dispatch?id=${infoHeader.shipment_id}&equipment=${infoHeader.equipment?.id}`);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onEditWidget = (el) => {
    if (el?.route) {
      if (el.id === 2) {
        navigate(`/shipment/${infoHeader?.shipment_id}`);
      }
    } else {
      switch (el?.id) {
        case 1:
          dispatch(updateRowEditItem({ ...infoHeader, label: 'Shipment' }));
          break;
        case 2:
          const dropTrailerRequired =
            infoHeader?.shipment_stops &&
            !infoHeader?.shipment_stops?.[0]?.arrival_date &&
            Number(infoHeader?.shipment_stops?.[0]?.equipment_action) === 2 &&
            infoHeader.equipment?.hooked_to;

          const shouldConfirm =
            infoHeader.status?.id === 1 && infoHeader.dispatch_type === 'external_carrier' && !el.checkInConfirmed;
          if (shouldConfirm) {
            setOpenConfirmCarrierCheckin(el);
            return;
          }

          if (dropTrailerRequired && !carrier) {
            setOpenRestrictedCheckIn(true);
            return;
          }

          setTableShipmentModalObj({ item: { ...el }, rowData: infoHeader });
          break;
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          navigate(`/shipment/${infoHeader?.shipment_id}`);
          break;
        case 11:
        case 12:
        case 13:
        case 14:
        case 43:
        case 61:
        case 97:
          setTableShipmentModalObj({ item: { ...el }, rowData: infoHeader });
          break;
        case 19:
          setTableShipmentModalObj({ item: { ...el }, rowData: [infoHeader] });
          break;
        case 44:
          setOpenRequestTracking(true);
          break;
        case 45:
          setOpenStopTracking(true);
          break;
        case 23:
          revertDispatch();
          break;
        default:
      }
    }
  };

  const getHeaderInfo = () => {
    getShipmentInfo(id)
      .then((res) => {
        getSMSCommunicationSettings().then((result) => {
          setMobileAppState(result.data);
          setInfoHeader(res.data);
        });
      })
      .catch(() => {
        // Do nothing
      });
  };

  function addTimeToDate(date, days = 0, hours = 0, minutes = 0) {
    const totalMilliseconds = days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    return new Date(new Date(date).getTime() + totalMilliseconds);
  }

  useEffect(() => {
    if (location.state?.fromDispatch) {
      setPreventBack(true);
    }
  }, [location.state?.fromDispatch]);

  function parseTime(timeStr) {
    // Split the time string into components
    const components = timeStr.split(/\s+/);

    // Create a new Date object with the current date and the parsed time components
    const date = new Date();
    for (const component of components) {
      if (component.endsWith('d')) {
        date.setDate(date.getDate() + parseInt(component, 10));
      } else if (component.endsWith('h')) {
        date.setHours(date.getHours() + parseInt(component, 10));
      } else if (component.endsWith('m')) {
        date.setMinutes(date.getMinutes() + parseInt(component, 10));
      } else if (component.endsWith('s')) {
        date.setSeconds(date.getSeconds() + parseInt(component, 10));
      }
    }
    return date;
  }

  function parseDateTime(timeStr) {
    // Split the time string into components
    const timeParts = timeStr.split(/\s+/);
    let days = 0;
    let hours = 0;
    let minutes = 0;
    for (let i = 0; i < timeParts.length; i++) {
      if (timeParts[i].endsWith('d')) {
        days = parseInt(timeParts[i], 10);
      } else if (timeParts[i].endsWith('h')) {
        hours = parseInt(timeParts[i], 10);
      } else if (timeParts[i].endsWith('m')) {
        minutes = parseInt(timeParts[i], 10);
      }
    }
    return { days, hours, minutes };
  }

  function createDate(dateString, timeString) {
    const dateParts = dateString.split('-');
    const timeParts = timeString.split(':');

    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    return new Date(year, month, day, hours, minutes);
  }

  function getDuration(date1, date2) {
    const diffMillis = Math.abs(date2.getTime() - date1.getTime());
    const durationMinutes = Math.floor(diffMillis / (1000 * 60));
    const durationHours = Math.floor(durationMinutes / 60);
    const durationDays = Math.floor(durationHours / 24);
    const remainingMinutes = durationMinutes % 60;
    const remainingHours = durationHours % 24;
    let durationString = '';
    if (durationDays > 0) {
      durationString += `${durationDays}d `;
    }
    if (remainingHours > 0) {
      durationString += `${remainingHours}h `;
    }
    if (remainingMinutes > 0 || durationString === '') {
      durationString += `${remainingMinutes}m`;
    }

    return durationString;
  }

  const mapDriverArr = useMemo(() => {
    const lng = infoHeader?.driver_from_gps_long;
    const lat = infoHeader?.driver_from_gps_lat;

    return [
      {
        id: infoHeader?.equipment?.id,
        img: infoHeader?.assigned_to_1?.image,
        miles: infoHeader?.empty_miles?.toFixed(),
        routeProps: {},
        marker: { lat, lng, draggable: false },
      },
    ];
  }, [infoHeader]);

  const createStopData = (index, stopPointType, allDataStop) => {
    const { color, background, name } = MAPPER_STOPS[+stopPointType];
    return { index, stopPointType: +stopPointType, color, background, allDataStop, name };
  };

  const activeStop = useMemo(() => {
    let firstStopWithoutArrival;

    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      const { arrival_date: arrival, stop_point_type: stopPointType, departure_date: departure } = stop || {};

      if (arrival && !departure) {
        return createStopData(i, stopPointType, stop);
      }
      if (!firstStopWithoutArrival && !arrival) {
        firstStopWithoutArrival = createStopData(i, stopPointType, stop);
      }
    }

    return firstStopWithoutArrival || {};
  }, [stops]);

  const stopCellularTracking = async () => {
    try {
      const body = {
        customer_dot: user?.customer?.dot,
        carrier_id: brokerage_dispatch?.carrier_id,
        shipment_id: id,
        status: 'stopped',
      };
      await updateCellularTracking(body);
      setOpenStopTracking(false);
      getHeaderInfo();
      showToaster({ type: 'success', message: 'Tracking has been stopped!' });
    } catch (e) {
      showToaster({ type: 'success', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const delayTime = useMemo(() => {
    if (infoHeader?.eta && activeStop?.allDataStop) {
      const { scheduled_date, from, to } = activeStop?.allDataStop || {};
      let timeZone;
      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      const passedEta = parseDateTime(infoHeader.eta);
      const currentToScheduledDiff = addTimeToDate(zoneTz, passedEta.days, passedEta.hours, passedEta.minutes);
      const scheduledCurrentDate = createDate(scheduled_date, from || to);
      return getDuration(currentToScheduledDiff, scheduledCurrentDate);
    }
  }, [infoHeader, activeStop]);

  const elapsedDay = useMemo(() => {
    if (infoHeader.shipment_stops) {
      const cloneStops = [...infoHeader.shipment_stops];
      const reversStops = Array.isArray(cloneStops) ? cloneStops.reverse() : [];
      const foundedIndex = reversStops.findIndex((el) => !!el?.arrival_date);
      const foundedStopHaveArrival = cloneStops[foundedIndex];
      let timeZone;

      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      return !!zoneTz && moment(zoneTz).diff(foundedStopHaveArrival?.arrival_date, 'minute');
    }
  }, [infoHeader, activeStop]);

  const latestDepartureBy = useMemo(() => {
    if (elapsedDay && infoHeader) {
      let timeZone;
      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      const estimatedTimeRemaining = calculateDurationDifference(
        getAverageTimeConverted(elapsedDay),
        getAverageTimeConverted(activeStop?.allDataStop?.stop_point?.average_waiting_time)
      );
      const passedEta = parseDateTime(estimatedTimeRemaining);
      return addTimeToDate(zoneTz, passedEta.days, passedEta.hours, passedEta.minutes);
    }
  }, [infoHeader, activeStop, elapsedDay]);

  const lastStop = useMemo(() => {
    if (stops.length) {
      const stop = stops[stops.length - 1];
      const { stop_point_type } = stop || {};
      const lastStopData = {};
      lastStopData.index = stops.length - 1;
      lastStopData.stopPointType = +stop_point_type;
      lastStopData.color = MAPPER_STOPS[+stop_point_type].color;
      lastStopData.background = MAPPER_STOPS[+stop_point_type].background;
      lastStopData.name = MAPPER_STOPS[+stop_point_type].name;
      lastStopData.allDataStop = stop;
      return lastStopData;
    }
  }, [stops]);

  const style = useMemo(() => {
    return {
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        marginTop: 15,
      },
    };
  }, []);

  const optionsEditDots = useMemo(() => {
    let options = MAP_DISPATCH_EDIT.filter((item) => {
      if (item.id === 61) return infoHeader.status_id === '5';

      if (!!carrier && item.id === 14) return false;

      return !((infoHeader.shipment_type === 'ltl' || infoHeader.shipment_type === 'slave') && item.id === 8);
    });

    if (
      !brokerage_dispatch?.carrier ||
      !brokerage_dispatch?.driver_phone_number ||
      cellular_tracking_status === 'started' ||
      cellular_tracking_status === 'completed' ||
      !user?.customer?.cellular_tracking
    ) {
      options = options.filter((item) => item.id !== 44);
    }

    if (!brokerage_dispatch?.carrier || cellular_tracking_status !== 'started') {
      options = options.filter((item) => item.id !== 45);
    }

    if (
      !infoHeader.shipment_stops?.[0]?.arrival_date ||
      (infoHeader.shipment_stops?.[0]?.arrival_date && infoHeader.shipment_stops?.[0]?.departure_date)
    ) {
      options = options.filter((el) => +el.id !== 5);
    }

    return options;
  }, [infoHeader]);

  const eventsJsx = useMemo(() => {
    const statusId = Number(infoHeader?.status_id);
    const {
      driver_confirm_date,
      shipment_option,
      driver_acknowledged,
      is_cellular_tracking_enable,
      cellular_tracking_status,
      brokerage_dispatch,
    } = infoHeader || {};
    const { customer_contact_book } = shipment_option || {};
    const { send_sms_on_dispatch } = mobileAppState || {};

    if (activeStop?.allDataStop) {
      switch (statusId) {
        case 1:
        case 2:
        case 8:
          return (
            <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
              {!carrier && (
                <div className={classes.iconWrapper}>
                  {statusId === 8 || !send_sms_on_dispatch ? <MinusIcon /> : <SuccessIcon />}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Shipment Text Message to Driver
                  </Typography>
                </div>
              )}

              {!!carrier && (
                <div className={classes.iconWrapper}>
                  {statusId === 8 ? <div className={classes.widgetSpinner} /> : <SuccessIcon />}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Shipment Confirmation Emailed to Carrier{' '}
                    {contact_user?.contact_email && (
                      <a href={`mailto:${contact_user?.contact_email}`} style={{ textDecoration: 'none' }}>
                        ({contact_user?.contact_email})
                      </a>
                    )}
                  </Typography>
                </div>
              )}

              {!carrier && (
                <div className={classes.iconWrapper}>
                  {driver_acknowledged ? <SuccessIcon /> : <div className={classes.widgetSpinner} />}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Shipment Push Notification to Driver App
                  </Typography>
                </div>
              )}

              {!!carrier && (
                <div className={classes.iconWrapper}>
                  {cellular_tracking_status === 'requested' ||
                  (!!is_cellular_tracking_enable && !cellular_tracking_status) ? (
                    <div className={classes.widgetSpinner} />
                  ) : cellular_tracking_status === 'started' ? (
                    <SuccessIcon />
                  ) : (
                    <MinusIcon />
                  )}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Cellular Tracking Request Sent to Driver{' '}
                    {brokerage_dispatch?.driver_phone_number && (
                      <Typography variant='b2'>
                        (
                        {brokerage_dispatch?.driver_phone_number?.startsWith('+')
                          ? formatPhoneNumber(brokerage_dispatch?.driver_phone_number)
                          : formatPhoneNumber(`+${brokerage_dispatch?.driver_phone_number}`)}
                        )
                      </Typography>
                    )}
                  </Typography>
                </div>
              )}

              <div className={classes.iconWrapper}>
                {!customer_contact_book ? <MinusIcon /> : <SuccessIcon />}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Shipment Tracking to Customer
                </Typography>
              </div>

              {!carrier && (
                <div className={classes.iconWrapper}>
                  {driver_confirm_date ? <SuccessIcon /> : <div className={classes.widgetSpinner} />}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Driver Shipment Acceptance
                  </Typography>
                </div>
              )}

              {!!carrier && (
                <div className={classes.iconWrapper}>
                  {confirmation_signed ? <SuccessIcon /> : <div className={classes.widgetSpinner} />}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Carrier Signed Confirmation and Accepted Shipment
                  </Typography>
                </div>
              )}

              <div className={classes.iconWrapper}>
                {carrier ? (
                  confirmation_signed ? (
                    <SuccessIcon />
                  ) : (
                    <div className={classes.widgetSpinner} />
                  )
                ) : driver_confirm_date ? (
                  <SuccessIcon />
                ) : (
                  <div className={classes.widgetSpinner} />
                )}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Successfully Dispatched
                </Typography>
              </div>
            </div>
          );
        case 3:
        case 4:
          return (
            <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
              {!!carrier && (
                <div className={classes.flexAlign}>
                  {cellular_tracking_status === 'requested' ||
                  (!!is_cellular_tracking_enable && !cellular_tracking_status) ? (
                    <div className={classes.widgetSpinner} />
                  ) : cellular_tracking_status === 'started' ? (
                    <SuccessIcon />
                  ) : (
                    <MinusIcon />
                  )}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Cellular Tracking Request Sent to Driver{' '}
                    {brokerage_dispatch?.driver_phone_number && (
                      <Typography variant='b2'>
                        (
                        {brokerage_dispatch?.driver_phone_number?.startsWith('+')
                          ? formatPhoneNumber(brokerage_dispatch?.driver_phone_number)
                          : formatPhoneNumber(`+${brokerage_dispatch?.driver_phone_number}`)}
                        )
                      </Typography>
                    )}
                  </Typography>
                </div>
              )}

              <div className={classes.flexAlign}>
                <SuccessIcon />
                <Typography variant='b2' style={{ margin: ' 0 0 0 8px' }}>
                  Driver is on route to {activeStop?.name}
                </Typography>
              </div>

              {statusId === 4 && (
                <div style={{ marginLeft: 20 }} className={classes.flexAlign}>
                  <WarningIcon fill='#C97A20' />
                  <Typography variant='b2' style={{ margin: '0 0 0 8px' }}>
                    Driver is delayed by {delayTime}
                  </Typography>
                </div>
              )}

              <div className={classes.flexAlign}>
                <div className={classes.widgetSpinnerWrapper}>
                  <div className={classes.widgetSpinner} />
                </div>
                <Typography variant='b2' style={{ margin: ' 0 0 0 8px' }}>
                  Driver has arrived to {activeStop?.name}
                </Typography>
              </div>

              <div className={classes.flexAlign}>
                <div className={classes.widgetSpinnerWrapper}>
                  <div className={classes.widgetSpinner} />
                </div>
                <Typography variant='b2' style={{ margin: ' 0 0 0 8px' }}>
                  Successfully Departed
                </Typography>
              </div>
            </div>
          );
        case 5:
          return (
            <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
              {!!carrier && (
                <div className={classes.flexAlign}>
                  {cellular_tracking_status === 'requested' ||
                  (!!is_cellular_tracking_enable && !cellular_tracking_status) ? (
                    <div className={classes.widgetSpinner} />
                  ) : cellular_tracking_status === 'started' ? (
                    <SuccessIcon />
                  ) : (
                    <MinusIcon />
                  )}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Cellular Tracking Request Sent to Driver{' '}
                    {brokerage_dispatch?.driver_phone_number && (
                      <Typography variant='b2'>
                        (
                        {brokerage_dispatch?.driver_phone_number?.startsWith('+')
                          ? formatPhoneNumber(brokerage_dispatch?.driver_phone_number)
                          : formatPhoneNumber(`+${brokerage_dispatch?.driver_phone_number}`)}
                        )
                      </Typography>
                    )}
                  </Typography>
                </div>
              )}

              <div className={classes.flexAlign}>
                <SuccessIcon />
                <Typography variant='b2' style={{ marginLeft: '8px' }}>
                  Driver has arrived and checked in
                </Typography>
              </div>

              {activeStop?.allDataStop?.reported_dock_status !== null &&
                (activeStop?.allDataStop?.stop_point_type === '1' ||
                  activeStop?.allDataStop?.stop_point_type === '2') && (
                  <div className={classes.flexAlign}>
                    <SuccessIcon />
                    <Typography variant='b2' style={{ marginLeft: 8 }}>
                      Driver is{' '}
                      {activeStop?.allDataStop?.reported_dock_status === 2
                        ? 'at the shipment dock'
                        : 'waiting for dock'}
                    </Typography>
                  </div>
                )}

              {activeStop?.allDataStop?.stop_point_type === '1' && (
                <div className={classes.flexAlign}>
                  {activeStop.allDataStop?.cargos?.every((i) => i.scanned) ? (
                    <SuccessIcon />
                  ) : (
                    <div className={classes.widgetSpinnerWrapper}>
                      <div className={classes.widgetSpinner} />
                    </div>
                  )}

                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Driver cargo scanned
                  </Typography>
                </div>
              )}

              {activeStop?.allDataStop?.stop_point_type === '1' && (
                <div className={classes.flexAlign}>
                  {activeStop.allDataStop?.reported_quantity ? (
                    <SuccessIcon />
                  ) : (
                    <div className={classes.widgetSpinnerWrapper}>
                      <div className={classes.widgetSpinner} />
                    </div>
                  )}

                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Driver received paperwork and is heading out
                  </Typography>
                </div>
              )}

              {activeStop?.allDataStop?.stop_point_type === '2' && (
                <div className={classes.flexAlign}>
                  {activeStop.allDataStop?.document_id ? (
                    <SuccessIcon />
                  ) : (
                    <div className={classes.widgetSpinnerWrapper}>
                      <div className={classes.widgetSpinner} />
                    </div>
                  )}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Driver scanned and sent paperwork
                  </Typography>
                </div>
              )}

              {activeStop?.allDataStop?.stop_point_type === '1' && (
                <div className={classes.flexAlign}>
                  {activeStop.allDataStop?.reported_quantity ? (
                    <SuccessIcon />
                  ) : (
                    <div className={classes.widgetSpinnerWrapper}>
                      <div className={classes.widgetSpinner} />
                    </div>
                  )}
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Driver entered shipment data
                    {!!activeStop?.allDataStop?.reported_quantity && (
                      <span>
                        (Bill of Lading {activeStop.allDataStop?.bill_of_lading_id}, Pieces{' '}
                        {activeStop.allDataStop?.reported_quantity}, Weight {activeStop.allDataStop?.reported_weight}{' '}
                        {activeStop?.allDataStop?.reported_weight_type?.unit})
                      </span>
                    )}
                  </Typography>
                </div>
              )}

              <div className={classes.flexAlign}>
                <div className={classes.widgetSpinnerWrapper}>
                  <div className={classes.widgetSpinner} />
                </div>
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Successfully Departed
                </Typography>
              </div>
            </div>
          );
        default:
          return (
            <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
              <div className={classes.flexAlign}>
                <SuccessIcon />
                <Typography variant='b2' style={{ marginLeft: '8px' }}>
                  Driver has arrived and checked in
                </Typography>
              </div>

              {/* <div */}
              {/*    style={{marginLeft: 20}} */}
              {/*    className={classes.flexAlign}> */}
              {/*    <ErrorIcon/> */}
              {/*    <Typography */}
              {/*        variant={'b2'} */}
              {/*        style={{margin: '0 0 8px 8px'}}> */}
              {/*        Truck Order Not Used for $200.00 */}
              {/*    </Typography> */}
              {/* </div> */}

              <div className={classes.flexAlign}>
                <div className={classes.widgetSpinnerWrapper}>
                  <div className={classes.widgetSpinner} />
                </div>
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Successfully Departed
                </Typography>
              </div>
            </div>
          );
      }
    }
  }, [infoHeader, activeStop]);

  const timeTextAndLineJsx = useMemo(() => {
    const statusId = Number(infoHeader?.status_id);
    const { eta, shipment_stops } = infoHeader || {};

    const defaultHtml = (
      <div className={classes.widgetHeader}>
        <Typography variant='h1'>{eta}</Typography>
        <Typography style={{ margin: '10px 0 0 8px' }} variant='overLine'>
          until arrival to
        </Typography>
        <div className={classes.headerGreenWrapper} style={{ background: activeStop?.background }}>
          <Typography variant='overLine' style={{ color: activeStop?.color }}>
            {activeStop?.name}
          </Typography>
        </div>
      </div>
    );

    if (statusId === 1 || statusId === 2 || statusId === 3 || statusId === 4) {
      return defaultHtml;
    }
    // else if (statusId === 4) {
    //     const cloneStops = [...shipment_stops]
    //     const reversStops = Array.isArray(cloneStops) ? cloneStops.reverse() : []
    //     const foundedIndex = reversStops.findIndex(el => !!el?.departure_date)
    //     const foundedStopHaveDeparture = cloneStops[foundedIndex]
    //     let index =  cloneStops.length - 1 - foundedIndex
    //     let timeZone;
    //     if (!foundedStopHaveDeparture) {
    //         return defaultHtml
    //     }
    //
    //     try {
    //         timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone)
    //     } catch (e) {
    //     }
    //     const zoneTz = !!timeZone?.zone_name && moment.tz(timeZone?.zone_name)
    //     const diff = !!zoneTz && moment(zoneTz).diff(foundedStopHaveDeparture,'minutes')
    //     const hours = Math.floor(diff / 60)
    //     const minutes = diff - (hours * 60)
    //     return (
    //         <div className={classes.widgetHeader}>
    //             <Typography variant={'h1'}>{hours}h {minutes}m</Typography>
    //             <Typography style={{margin: '10px 0 0 8px'}} variant={'overLine'}>
    //                 elapsed from departure to
    //             </Typography>
    //             <div
    //                 className={classes.headerGreenWrapper}
    //                 style={{background: MAPPER_STOPS[+foundedStopHaveDeparture?.stop_point_type].background}}
    //             >
    //                 <Typography
    //                     variant={'overLine'}
    //                     style={{color: MAPPER_STOPS[+foundedStopHaveDeparture?.stop_point_type].color}}
    //                 >
    //                     Stop {getAlphabet(index)}
    //                 </Typography>
    //             </div>
    //         </div>
    //     )
    // }
    if (statusId === 5) {
      const cloneStops = [...shipment_stops];
      const reversStops = Array.isArray(cloneStops) ? cloneStops.reverse() : [];
      const foundedIndex = reversStops.findIndex((el) => !!el?.arrival_date);
      const foundedStopHaveArrival = cloneStops[foundedIndex];
      const index = cloneStops.length - 1 - foundedIndex;
      let timeZone;

      if (!foundedStopHaveArrival) {
        return defaultHtml;
      }

      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      const diff = !!zoneTz && moment(zoneTz).diff(foundedStopHaveArrival?.arrival_date, 'minute');

      return (
        <div className={classes.widgetHeader}>
          <Typography variant='h1'>{getAverageTimeConverted(diff)}</Typography>
          <Typography style={{ margin: '10px 0 0 8px' }} variant='overLine'>
            elapsed since arrival to
          </Typography>
          <div
            className={classes.headerGreenWrapper}
            style={{ background: MAPPER_STOPS[Number(foundedStopHaveArrival?.stop_point_type)].background }}
          >
            <Typography
              variant='overLine'
              style={{ color: MAPPER_STOPS[Number(foundedStopHaveArrival?.stop_point_type)].color }}
            >
              Stop {getAlphabet(index)}
            </Typography>
          </div>
        </div>
      );
    }
  }, [infoHeader]);

  useEffect(() => {
    getHeaderInfo();
  }, []);

  useEffect(() => {
    bindPusher();
  });

  const bindPusher = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dot = user?.customer?.dot;
    const channelShipmentProgress = `private-shipmentData-update.${dot}`;

    PusherJs.unsubscribe(channelShipmentProgress);
    const channelShipmentProgress_table = PusherJs.subscribe(channelShipmentProgress);

    channelShipmentProgress_table.bind('shipment-update', (e) => {
      if (e?.message?.data?.id === id) {
        getHeaderInfo();
      }
    });
  };

  function onSubmitShipmentRowEditChildes(data) {
    const ids = data?.lastData?.item.id;
    const res = { data };
    switch (+ids) {
      case 2:
      case 5:
      case 61:
        getHeaderInfo();
        break;
      case 8:
        navigate('/planner');
        break;
      case 6:
        getHeaderInfo();
        break;
      case 11:
        const queryParams = new URLSearchParams();
        data?.res?.forEach((shipmentId) => {
          queryParams.append('id', shipmentId);
        });
        navigate(`/planner?${queryParams.toString()}`);
        break;
      case 13:
        navigate(`/shipment/${id}`, { state: { defaultTab: 5 } });
        break;
      case 14:
        getHeaderInfo();
        break;
      case 19:
        navigate('/planner', { state: { allValue: data.lastData.rowData[0].shipment_id } });
        break;
      case 7:
        UpdateShare(id, res.data.res.customer || undefined)
          .then((result) => {
            showToaster({ type: 'success', message: result.data?.message || 'Success!' });
            getHeaderInfo();
          })
          .catch((err) => {
            if (err.response) {
              showToaster({ type: 'error', message: err.response.data.error });
            }
          });
        break;
      case 97:
        getHeaderInfo();
        break;
      default:
    }
  }

  function calculateDurationDifference(time1, time2) {
    const date1 = parseTime(time1);
    const date2 = parseTime(time2);

    const totalSeconds = Math.abs((date1 - date2) / 1000);

    // Calculate the number of days, hours, and minutes in the difference
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor(totalSeconds / 3600) % 24;
    const minutes = Math.floor(totalSeconds / 60) % 60;

    // Return the duration difference as a string
    return `${days}d ${hours}h ${minutes}m`;
  }

  useEffect(() => {
    if (stops.length > 0) {
      if (infoHeader?.shipment_type === 'slave') {
        updateStopInRoute({ stops, routeArr: null });
      } else {
        if (infoHeader?.shipment_route_direction?.route) {
          const route = infoHeader?.shipment_route_direction.route;
          const routeArr = route ? JSON.parse(route) : null;
          updateStopInRoute({ stops, routeArr });
        }
      }
    }
  }, [infoHeader, stops]);
  const { stop_point, scheduled_date, from, to, scheduled_type } = activeStop?.allDataStop || {};
  const { state, zipcode, city, address1, location_name } = stop_point || {};

  return (
    <div className={classNames(classes.header, use(classes.light, classes.dark))}>
      {!preventBack && (
        <div onClick={() => navigate(-1)} className={classes.backWrapper}>
          <ArrowLeft width={10} height={10} />
          <Typography variant='c1'>Go Back</Typography>
        </div>
      )}

      {preventBack && (
        <div
          className='d-flex justify-content-end pointer'
          style={{ position: 'absolute', zIndex: 1, right: '20px', top: '20px' }}
        >
          <CloseIcon width={20} height={20} onClick={() => navigate(`/planner`)} />
        </div>
      )}
      <div style={style.mapWrapper}>
        {!!Object.keys(infoHeader).length && route.length && mapDriverArr.length && (
          <Map
            styleContainer={style.map}
            literalJsx
            popoverClass={classes.popoverDispatchPage}
            driversArr={mapDriverArr}
            stopPointArr={route}
            infoHeader={infoHeader}
            isShipmentTable
          >
            {!!activeStop.allDataStop && (
              <div
                className={classes.mapWidget}
                style={{ background: use('rgba(255, 255, 255, 0.88)', 'rgba(44,44,44,0.88)') }}
              >
                {/* // header */}
                <div className={classes.widgetUserData}>
                  <div className={classes.userDataTop}>
                    <div className={classes.userDataLeft}>
                      <Typography style={{ whiteSpace: 'nowrap' }} variant='h3'>
                        {`Shipment ${id}`}
                      </Typography>

                      <div className={classes.flexAlign}>
                        <div className={classes.headerStatus} style={{ backgroundColor: styleStatus?.bgStatus }}>
                          <Typography variant='overLine' style={{ color: styleStatus?.colorStatus }}>
                            {styleStatus?.statusName}
                          </Typography>
                        </div>
                      </div>
                    </div>

                    <TableRowsEdit
                      placement='bottom-end'
                      overlayStyle={{ width: 230 }}
                      options={
                        Number(infoHeader?.status_id) === 6
                          ? DRY_RUN_ACTIONS
                          : Number(infoHeader?.status_id) === 8
                          ? PENDING_DISPATCH_ACTIONS.filter((i) => i.id !== 18 && i.id !== 16)
                          : optionsEditDots
                      }
                      onClickOption={onEditWidget}
                    />
                  </div>

                  <div className={classes.twoWaysWrapper}>
                    <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                      {equipment?.equipment_id}&nbsp;
                      <Typography variant='overLine'>{equipment?.equipment_type?.title}</Typography>{' '}
                      {!!equipment?.truck_type && (
                        <Typography variant='overLine'>({TRUCK_TYPES[equipment.truck_type]})</Typography>
                      )}
                    </Typography>

                    {!!equipment?.hooked_to && (
                      <>
                        <TwoWaysIcon style={{ margin: '0 8px' }} />
                        <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                          {equipment?.hooked_to?.equipment_id}&nbsp;
                          <Typography variant='overLine'>
                            {equipment?.hooked_to?.length?.length}-{equipment?.hooked_to?.equipment_type?.title}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </div>

                  {/* driver-1 */}
                  <div className={classes.driver}>
                    <div
                      className={classes.flexAlign}
                      style={{ cursor: 'pointer' }}
                      onClick={() => !!assigned_to1 && navigate(`/driver-profile/${assigned_to1}/general`)}
                    >
                      {assigned_to_1?.fname && (
                        <div className={classes.avatar}>
                          {carrier ? (
                            logo_path ? (
                              <img src={logo_path} width='100%' height='100%' alt='img' />
                            ) : (
                              <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                            )
                          ) : (
                            <img src={assigned_to_1?.image || person} alt='assigned to' />
                          )}
                          {!carrier && (
                            <div
                              className={classes.avatarStatus}
                              style={{
                                backgroundColor: use(
                                  statusColorInStatusId[assigned_to_1?.status]?.color,
                                  statusColorInStatusId[assigned_to_1?.status]?.darkColor
                                ),
                              }}
                            />
                          )}
                        </div>
                      )}

                      <div className='d-flex flex-column gap-2'>
                        <Typography variant='overLine2' className={classes.nameUser}>
                          {assigned_to_1?.fname}&nbsp;
                          {assigned_to_1?.lname}
                        </Typography>

                        {!!brokerage_dispatch && (
                          <Typography variant='overLine2' className={classes.nameUser}>
                            {carrier.name}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* driver-2 */}
                  {!!assigned_to2 && !!assigned_to_2 && (
                    <div className={classes.driver} style={{ margin: '4px 0' }}>
                      <div
                        className={classes.flexAlign}
                        style={{ cursor: 'pointer' }}
                        onClick={() => !!assigned_to2 && navigate(`/driver-profile/${assigned_to2}/general`)}
                      >
                        <div className={classes.avatar}>
                          <img src={assigned_to_2?.image || person} alt='assigned to' />
                          <div
                            className={classes.avatarStatus}
                            style={{
                              backgroundColor: use(
                                statusColorInStatusId[assigned_to_2?.status]?.color,
                                statusColorInStatusId[assigned_to_2?.status]?.darkColor
                              ),
                            }}
                          />
                        </div>

                        <Typography variant='overLine2' className={classes.nameUser}>
                          {assigned_to_2?.fname}&nbsp;
                          {assigned_to_2?.lname}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>

                {timeTextAndLineJsx}
                <div className={classes.progressWidget}>
                  {[...stops].map((el) => {
                    return (
                      <div key={el.id} className={classes.progressItem}>
                        <div
                          style={{
                            height: '100%',
                            backgroundColor: status.id === 4 ? palette.red500 : palette.green400,
                            width: `${el.arrival_date ? '100%' : '0%'}`,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={classes.widgetBody}>
                  <div className={classes.flexColumn}>
                    {status?.id !== 5 ? (
                      <>
                        <Typography variant='s2'>
                          {miles_remaining && Number(miles_remaining).toFixed()}
                          <span> </span>
                          Miles Remaining Until Arrival for {activeStop?.name}
                        </Typography>
                        <Typography variant='b2'>
                          Latest arrival by {formatDateTime(infoHeader?.estimated_date_time_arrival)}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant='s2'>
                          Driver has arrived and checked in Stop {getAlphabet(activeStop?.index)}
                        </Typography>
                        <Typography variant='b2'>
                          {calculateDurationDifference(
                            getAverageTimeConverted(elapsedDay),
                            getAverageTimeConverted(activeStop?.allDataStop?.stop_point?.average_waiting_time)
                          )}{' '}
                          estimated time remaining until departure
                        </Typography>
                        <Typography variant='b2'>
                          Latest departure by&nbsp;
                          {formatDateTime(latestDepartureBy)}
                        </Typography>
                      </>
                    )}
                  </div>

                  {eventsJsx}

                  <div className={classes.line} />
                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Equipment Action</Typography>
                    </div>

                    <Typography variant='b2'>
                      {equipmentTypeText({
                        type: activeStop?.allDataStop?.stop_point_type,
                        action: activeStop?.allDataStop?.equipment_action,
                        equipment: activeStop?.allDataStop?.equipment,
                        equipment_tbd: activeStop?.allDataStop?.equipment_tbd,
                      })}
                    </Typography>
                  </div>

                  <div className={classes.line} />
                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Stop Address</Typography>
                      <div className={classes.headerGreenWrapper} style={{ background: activeStop.background }}>
                        <Typography variant='overLine' style={{ color: activeStop.color }}>
                          Stop {getAlphabet(activeStop.index)}
                        </Typography>
                      </div>
                    </div>

                    <Typography variant='b2'>{location_name}</Typography>
                    <Typography variant='b2'>{address1 && `${address1},`}</Typography>
                    <Typography variant='b2'>
                      {city?.name && city.name}
                      {state?.short_name && `, ${state.short_name}`} {zipcode && `${zipcode}`}
                    </Typography>
                  </div>

                  <div className={classes.line} />

                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Current Location</Typography>
                    </div>

                    <Typography variant='b2'>
                      {infoHeader?.equipment?.location_target === 'Driver'
                        ? infoHeader?.equipment?.drivers?.[0]?.driver_gps?.location
                        : infoHeader?.equipment?.location_target === 'carrier'
                        ? infoHeader?.carrier_gps_latest?.location
                        : infoHeader?.equipment?.equipment_gps?.location}
                    </Typography>
                  </div>

                  <div className={classes.line} />

                  <div style={{ marginTop: 16 }}>
                    <Typography variant='s2'>Scheduled</Typography>
                  </div>

                  <div className={classes.flexColumn}>
                    <Typography variant='b2'>
                      {scheduled_date && `${formatDate(scheduled_date)},`}&nbsp;
                      {from} {[1, 3].includes(+scheduled_type) ? ` to ${to}` : ` to ${from}`}
                    </Typography>
                  </div>
                </div>
              </div>
            )}

            {!activeStop.allDataStop && (
              <div
                className={classes.mapWidget}
                style={{ background: use('rgba(255, 255, 255, 0.88)', 'rgba(44,44,44,0.88)') }}
              >
                {/* // header */}
                <div className={classes.widgetUserData}>
                  <div className={classes.userDataTop}>
                    <div className={classes.userDataLeft}>
                      <Typography style={{ whiteSpace: 'nowrap' }} variant='h3'>
                        {`Shipment ID ${id}`}
                      </Typography>

                      <div className={classes.flexAlign}>
                        <div className={classes.headerStatus} style={{ backgroundColor: styleStatus?.bgStatus }}>
                          <Typography variant='overLine' style={{ color: styleStatus?.colorStatus }}>
                            {styleStatus?.statusName}
                          </Typography>
                        </div>
                      </div>
                    </div>

                    <TableRowsEdit
                      placement='bottom-end'
                      overlayStyle={{ width: 210 }}
                      options={MAP_DISPATCH_EDIT_COMPLETED.filter((item) =>
                        infoHeader?.invoiced ? item.id !== 19 && item.id !== 97 : true
                      )}
                      onClickOption={onEditWidget}
                    />
                  </div>

                  <div className={classes.twoWaysWrapper}>
                    <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                      {equipment?.equipment_id}&nbsp;
                      <Typography variant='overLine'>{equipment?.equipment_type?.title}</Typography>{' '}
                      {!!equipment?.truck_type && (
                        <Typography variant='overLine'>({TRUCK_TYPES[equipment.truck_type]})</Typography>
                      )}
                    </Typography>

                    {!!equipment?.hooked_to && (
                      <>
                        <TwoWaysIcon style={{ margin: '0 8px' }} />
                        <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                          {equipment?.hooked_to?.equipment_id}&nbsp;
                          <Typography variant='overLine'>
                            {equipment?.hooked_to?.length?.length}-{equipment?.hooked_to?.equipment_type?.title}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </div>

                  {/* driver-1 */}
                  <div className={classes.driver}>
                    <div
                      className={classes.flexAlign}
                      style={{ cursor: 'pointer' }}
                      onClick={() => !!assigned_to1 && navigate(`/driver-profile/${assigned_to1}/general`)}
                    >
                      <div className={classes.avatar}>
                        <img src={assigned_to_1?.image || person} alt='assigned to' />
                        <div
                          className={classes.avatarStatus}
                          style={{
                            backgroundColor: use(
                              statusColorInStatusId[assigned_to_1?.status]?.color,
                              statusColorInStatusId[assigned_to_1?.status]?.darkColor
                            ),
                          }}
                        />
                      </div>

                      <Typography variant='overLine2' className={classes.nameUser}>
                        {assigned_to_1?.fname}&nbsp;
                        {assigned_to_1?.lname}
                      </Typography>
                    </div>
                  </div>

                  {/* driver-2 */}
                  {!!assigned_to2 && !!assigned_to_2 && (
                    <div className={classes.driver} style={{ margin: '4px 0' }}>
                      <div
                        className={classes.flexAlign}
                        style={{ cursor: 'pointer' }}
                        onClick={() => !!assigned_to2 && navigate(`/driver-profile/${assigned_to2}/general`)}
                      >
                        <div className={classes.avatar}>
                          <img src={assigned_to_2?.image || person} alt='assigned to' />
                          <div
                            className={classes.avatarStatus}
                            style={{
                              backgroundColor: use(
                                statusColorInStatusId[assigned_to_2?.status]?.color,
                                statusColorInStatusId[assigned_to_2?.status]?.darkColor
                              ),
                            }}
                          />
                        </div>

                        <Typography variant='overLine2' className={classes.nameUser}>
                          {assigned_to_2?.fname}&nbsp;
                          {assigned_to_2?.lname}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>

                <div className={classes.progressWidget}>
                  {[...stops].map((el) => {
                    return (
                      <div key={el.id} className={classes.progressItem}>
                        <div
                          style={{
                            height: '100%',
                            backgroundColor: palette.green400,
                            width: `${el.arrival_date ? '100%' : '0%'}`,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={classes.widgetBody}>
                  <div className={classes.flexColumn}>
                    <Typography variant='s2'>
                      {infoHeader?.loaded_miles?.toFixed()} Loaded Miles , {infoHeader?.empty_miles?.toFixed()} Empty
                      Miles
                    </Typography>
                    <Typography variant='b2'>
                      Arrived Destination At {formatDateTime(lastStop?.allDataStop?.arrival_date)}
                    </Typography>
                    <Typography variant='b2'>
                      Departed Destination At {formatDateTime(lastStop?.allDataStop?.departure_date)}
                    </Typography>
                  </div>

                  <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Arrived At Destination
                      </Typography>
                    </div>

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver is waiting for Shipment Dock
                      </Typography>
                    </div>

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver is at Shipment Dock
                      </Typography>
                    </div>

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver uploaded paperwork
                      </Typography>
                    </div>
                    {lastStop?.allDataStop?.proof_of_delivery && (
                      <div style={{ marginLeft: 20 }} className={classes.flexAlign}>
                        <SubRight />
                        <Typography variant='b2' style={{ margin: '0 0 0 8px' }}>
                          Driver scanned and sent proof of delivery by {lastStop?.allDataStop?.proof_of_delivery}
                        </Typography>
                      </div>
                    )}

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver Completed Shipment
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.line} />
                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Stop Address</Typography>
                      <div className={classes.headerGreenWrapper} style={{ background: lastStop?.background }}>
                        <Typography variant='overLine' style={{ color: lastStop?.color }}>
                          Stop {getAlphabet(lastStop?.index)}
                        </Typography>
                      </div>
                    </div>

                    <Typography variant='b2'>{lastStop?.allDataStop?.stop_point?.location_name}</Typography>
                    <Typography variant='b2'>
                      {lastStop?.allDataStop?.stop_point?.address1 && `${lastStop?.allDataStop?.stop_point?.address1},`}
                    </Typography>
                    <Typography variant='b2'>
                      {lastStop?.allDataStop?.city?.name && lastStop?.allDataStop?.stop_point?.city.name}
                      {lastStop?.allDataStop?.state?.stop_point?.short_name &&
                        `, ${lastStop?.allDataStop?.stop_point?.state.short_name}`}{' '}
                      {lastStop?.allDataStop?.stop_point?.zipcode && `${lastStop?.allDataStop?.stop_point?.zipcode}`}
                    </Typography>
                  </div>

                  <div className={classes.line} />

                  <div style={{ marginTop: 16 }}>
                    <Typography variant='s2'>Scheduled</Typography>
                  </div>

                  <div className={classes.flexColumn}>
                    <Typography variant='b2'>
                      {lastStop?.allDataStop?.scheduled_date && `${formatDate(lastStop?.allDataStop?.scheduled_date)},`}
                      &nbsp;
                      {lastStop?.allDataStop?.from}{' '}
                      {![1, 3].includes(Number(lastStop?.allDataStop?.scheduled_type))
                        ? `to ${lastStop?.allDataStop?.from}`
                        : `to ${lastStop?.allDataStop?.to}`}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </Map>
        )}
      </div>

      <TableShipmentRowEditChildes data={tableShipmentModalObj} onSubmit={onSubmitShipmentRowEditChildes} />
      {openRestrictedCheckIn && (
        <RestrictedCheckIn
          open={openRestrictedCheckIn}
          onClose={() => setOpenRestrictedCheckIn(false)}
          shipment={infoHeader}
          onSuccess={getHeaderInfo}
        />
      )}
      {openRequestTracking && (
        <RequestCellularTracking
          open={openRequestTracking}
          onClose={() => setOpenRequestTracking(false)}
          shipment={infoHeader}
          onSuccess={getHeaderInfo}
        />
      )}
      {openStopTracking && (
        <ConfirmModal
          open={openStopTracking}
          title='Stop Cellular Tracking'
          text={`Are you sure you want to stop cellular tracking for shipment ${id}?`}
          btnText='Stop Tracking'
          onClose={() => setOpenStopTracking(false)}
          onConfirm={stopCellularTracking}
        />
      )}
      {!!openConfirmCarrierCheckin && (
        <ConfirmModal
          open={!!openConfirmCarrierCheckin}
          title='Warning!'
          width='560px'
          text={
            <div>
              <Typography variant='s1' as='p' style={{ color: palette.gray700 }}>
                The shipment has not yet been confirmed by {infoHeader?.brokerage_dispatch?.carrier?.name}.
              </Typography>
              <Typography variant='s1' as='p' style={{ color: palette.gray700 }}>
                Are you sure you want to proceed without a signed confirmation?
              </Typography>
            </div>
          }
          btnType='primary'
          btnText='Proceed'
          onClose={() => setOpenConfirmCarrierCheckin(null)}
          onConfirm={() => {
            onEditWidget({ ...openConfirmCarrierCheckin, checkInConfirmed: true });
            setOpenConfirmCarrierCheckin(null);
          }}
        />
      )}
    </div>
  );
};

export default DispatchVehicleMap;
