const getCoordsX = (index, length) => {
  switch (index) {
    case 0:
    case 3:
    case 6:
    case 9:
    case 12:
    case 16:
      return 8;
    case 1:
    case 4:
    case 7:
    case 10:
    case 13:
    case 17:
      return length < 3 ? 114 : 75;
    case 2:
    case 5:
    case 8:
    case 11:
    case 14:
    case 18:
      return 145;
    default:
      return 145;
  }
};

const getCoordsY = (index) => {
  switch (index) {
    case 0:
    case 1:
    case 2:
      return 62;
    case 3:
    case 4:
    case 5:
      return 97;
    case 6:
    case 7:
    case 8:
      return 132;
    case 9:
    case 10:
    case 11:
      return 167;
    case 12:
    case 13:
    case 14:
      return 202;
    case 15:
    case 16:
    case 17:
      return 237;
    default:
      return 202;
  }
};

export const generateShipmentDetails = (doc, pageWidth, shippersAndConsignees, formatDateTime) => {
  let end;
  const length = shippersAndConsignees.length;

  [...(shippersAndConsignees || [])].slice(0, 15)?.forEach((item, index, array) => {
    doc.setFontSize(10);
    doc.setTextColor('#21467b');
    doc.setFont('Inter', 'normal', 700);
    doc.text(`${item.type}`, getCoordsX(index, length), getCoordsY(index));

    doc.setFontSize(8);
    doc.setTextColor('#000000');
    doc.setFont('Inter', 'normal', 400);
    doc.text(`${item.stop_point.location_name}`, getCoordsX(index, length), getCoordsY(index) + 4);
    doc.text(
      `${item.stop_point.address1 || ''}${item.stop_point.address2 ? `, ${item.stop_point.address2}` : ''}`,
      getCoordsX(index, length),
      getCoordsY(index) + 8
    );
    doc.text(
      `${item.stop_point.city?.name || ''}, ${item.stop_point.state?.short_name || ''} ${
        item.stop_point.zipcode || ''
      }`,
      getCoordsX(index, length),
      getCoordsY(index) + 12
    );
    doc.setFont('Inter', 'normal', 700);
    doc.text('Arrive:', getCoordsX(index, length), getCoordsY(index) + 16);
    doc.text('Depart:', getCoordsX(index, length), getCoordsY(index) + 20);
    if (item.type === 'Consignee') {
      doc.text('Proof of Delivery:', getCoordsX(index, length), getCoordsY(index) + 24);
    }
    doc.setFont('Inter', 'normal', 400);
    doc.text(
      item.arrival_date ? `${formatDateTime(item.arrival_date)}` : '-',
      getCoordsX(index, length) + 12,
      getCoordsY(index) + 16
    );
    doc.text(
      item.departure_date ? `${formatDateTime(item.departure_date)}` : '-',
      getCoordsX(index, length) + 12,
      getCoordsY(index) + 20
    );
    if (item.type === 'Consignee') {
      doc.text(
        item.proof_of_delivery ? `${item.proof_of_delivery}` : '-',
        getCoordsX(index, length) + 26,
        getCoordsY(index) + 24
      );
    }

    if (index === array.length - 1) {
      end = getCoordsY(index) + 28;
    }
  });

  return end || 90;
};
