import { number, object, string } from 'yup';

export const validationSchema = object({
  deductionType: object()
    .shape({ id: number().required('Deduction Type is required!') })
    .required('Deduction Type is required!')
    .nullable(),
  type: string().trim().required('Type is required!').nullable(),
  original_balance: number().required('Original Balance is required!').nullable(),
  payment_amount: number().when('term', {
    is: (term) => term !== 'amortized',
    then: number().required('Payment Amount is required!').nullable(),
    otherwise: number().nullable(),
  }),
  term: string().trim().required('Term is required!').nullable(),
  monthDay: string().when('term', {
    is: 'month',
    then: string().trim().required('Required').nullable(),
    otherwise: string().trim().nullable(),
  }),
  weekDay: string().when('term', {
    is: 'week',
    then: string().trim().required('Required').nullable(),
    otherwise: string().trim().nullable(),
  }),
  amortized_months: number().when('term', {
    is: 'amortized',
    then: number().required('Required').nullable(),
    otherwise: number().nullable(),
  }),
});
