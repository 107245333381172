import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import {
  deleteDeduction,
  deleteAddition,
  deleteTax,
  deleteBenefit,
  getAddition,
  getDeduction,
  getTaxes,
  getBenefits,
  AddAdditions,
  AddDeductions,
  AddTaxes,
  AddBenefits,
  getStaffCompanyPolicy,
  getStaffTaxType,
  getStaffBalances,
  uploadDriverDocument,
  getSalesCommissions,
} from 'Api/Staff';
import { getAdditionsList, getBenefitsList, getDeductionsList, getTaxesList } from 'Api/PayrollSettings';
import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import { formatNumber, palette, PERMISSIONS } from 'utils/constants';
import ViewPdf from 'components/ViewPdf';
import EditBalance from 'componentsV2/EditBalance';
import { useTheme } from 'context/themeContext';
import { formatAmount } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import { CompensationContent } from '../Staff/AddStaff/StaffCompensation';
import ImageDropZone from '../ImgageDropZone/index';
import { CompensationValidationSchemaStaffProfile } from '../Staff/ValidationSchemaStaff';
import {
  additionsValidationSchema,
  benefitsValidationSchema,
  deductionsValidationSchema,
  taxesValidationSchema,
} from '../DriverCompansation/validationSchema';
import { STable } from '../Staff/AddStaff/AddStaff.styles';
import { convertBalanceDetails } from './StaffCompensation.data';

const StaffCompansation = ({
  updateCompensation,
  showError,
  onSuccess,
  loading,
  staffCompansation,
  commissionsIndex,
}) => {
  const { convertToCustomerTime, formatDateTime } = useDateFormat();

  const { permissions } = useSelector((state) => state?.root);

  const { currency } = useSelector((state) => state.root);
  const [toggle, setToggle] = React.useState(false);
  const [taxOption, setTaxOption] = useState([]);
  const [companyPolicy, setCompanyPolicy] = useState([]);
  const [deductionType, setDeductionType] = useState([]);
  const [addtionType, setAddtionType] = useState([]);
  const [benefitType, setBenefitType] = useState([]);
  const [taxesType, setTaxesType] = useState([]);
  const [balances, setBalances] = useState([]);
  const [salesCommissions, setSalesCommissions] = useState([]);
  const [week, setWeek] = useState('Mon');
  const [recurringModalShow, setRecurringModalShow] = useState(false);
  const [recurringModalData, setRecurringModalData] = useState({});
  const [deduction, setDeduction] = useState([]);
  const [balanceToEdit, setBalanceToEdit] = useState(null);
  const [addtion, setAddtion] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { id } = useParams();
  const { use } = useTheme();

  const didMountRefDeductions = useRef(false);
  const didMountRefAdditions = useRef(false);
  const didMountRefTaxes = useRef(false);
  const didMountRefBenefits = useRef(false);

  const additionsController = useRef(new AbortController());
  const deductionsController = useRef(new AbortController());
  const taxesController = useRef(new AbortController());
  const benefitsController = useRef(new AbortController());

  const getOptions = () => {
    getAdditionsList().then((res) => {
      const types = res?.data.map((item) => ({ id: item.id, amount: item.amount, type: item.addition_type }));
      setAddtionType(types);
    });

    getBenefitsList().then((res) => {
      const types = res?.data.map((item) => ({ id: item.id, amount: item.amount, type: item.benefit_type }));
      setBenefitType(types);
    });

    getDeductionsList().then((res) => {
      const types = res?.data.map((item) => ({ id: item.id, amount: item.amount, type: item.deduction_type }));
      setDeductionType(types);
    });

    getTaxesList().then((res) => {
      setTaxesType(res?.data);
    });
  };

  useEffect(() => {
    getStaffTaxType().then((res) => {
      setTaxOption(res?.data);
    });

    getStaffCompanyPolicy()
      .then((res) => {
        setCompanyPolicy(res?.data);
      })
      .catch(() => {
        // Do nothing
      });
    getOptions();
  }, []);

  const taxDetails = [
    {
      property: 'Tax ID',
      value: staffCompansation?.staff_compensation?.tax_id,
    },
    {
      property: 'Tax Type',
      value: staffCompansation?.staff_compensation?.tax_type?.name,
    },
    {
      property: 'Expense Account',
      value: staffCompansation?.staff_compensation?.expense_account?.account_name,
    },
  ];

  const bankDetails = [
    {
      property: 'Bank Name',
      value: staffCompansation?.staff_compensation?.bank_name,
    },
    {
      property: 'Direct Deposit Form',
      value: staffCompansation?.staff_compensation?.deposit_form_path ? 'View' : '-',
    },
    {
      property: 'Routing Number',
      value: staffCompansation?.staff_compensation?.routing,
    },
    {
      property: 'Account Number',
      value: staffCompansation?.staff_compensation?.account,
    },
  ];

  const getBalances = async () => {
    try {
      const { data } = await getStaffBalances(id);
      setBalances(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDeductions = () => {
    didMountRefDeductions.current = false;
    getDeduction({ id }).then((res) => {
      const filtered = res?.data?.filter((deduction) => {
        if (!deduction.one_time_deduction) {
          if (deduction.is_balance_deduction) {
            return !!deduction.balance_id;
          }
          return true;
        }
        return false;
      });

      setDeduction(filtered);

      deductionFormik.setValues({
        deductions: filtered.map((deduction) => {
          const processedSettlements = (deduction.settlement || [])?.filter(
            (settlement) => settlement.staff_settlement.status_id === 3
          );
          const isFulfilledBalance = processedSettlements.length >= deduction.amortized_months;

          return {
            date: new Date(`${deduction.date} ${deduction.time}`),
            time: deduction.time,
            deduction_type_id: deduction.deduction_type?.id,
            quantity: Number(deduction.quantity) || 0,
            per_quantity_amount: Number(deduction.per_quantity_amount) || 0,
            amount: deduction.amount,
            every_settlement: deduction.every_settlement,
            month_week_settlement: deduction.month_week_settlement,
            amortized_settlement: deduction.amortized_settlement,
            day: deduction.day,
            month_week: deduction.month_week,
            amortized_total: deduction.amortized_total,
            amortized_month: deduction.amortized_month,
            amortized_months: deduction.amortized_months,
            deduction_id: deduction.id,
            is_balance_deduction: deduction.is_balance_deduction,
            isFulfilledBalance,
            balanceName: deduction.balance_type?.name || '',
            balanceId: deduction.balance_id || null,
          };
        }),
      });

      didMountRefDeductions.current = true;
    });
  };
  const getAddtion = () => {
    didMountRefAdditions.current = false;
    getAddition({ id }).then((res) => {
      setAddtion(res?.data);

      Additionsformik.setValues({
        additions: (res?.data || []).map((addition) => {
          return {
            date: new Date(`${addition.date} ${addition.time}`),
            time: addition.time,
            addition_type_id: addition.addition_type?.id,
            quantity: Number(addition.quantity) || 0,
            per_quantity_amount: Number(addition.per_quantity_amount) || 0,
            amount: addition.amount,
            every_settlement: addition.every_settlement,
            month_week_settlement: addition.month_week_settlement,
            day: addition.day,
            month_week: addition.month_week,
            addition_id: addition.id,
          };
        }),
      });

      didMountRefAdditions.current = true;
    });
  };

  const getBenefit = () => {
    didMountRefBenefits.current = false;
    getBenefits({ id }).then((res) => {
      setBenefit(res?.data);

      Benefitformik.setValues({
        benefits: (res?.data || []).map((benefit) => {
          return {
            date: new Date(`${benefit.date} ${benefit.time}`),
            time: benefit.time,
            benefit_type_id: benefit.benefit_type?.id,
            quantity: Number(benefit.quantity) || 0,
            per_quantity_amount: Number(benefit.per_quantity_amount) || 0,
            amount: benefit.amount,
            every_settlement: benefit.every_settlement,
            month_week_settlement: benefit.month_week_settlement,
            day: benefit.day,
            month_week: benefit.month_week,
            benefit_id: benefit.id,
          };
        }),
      });

      didMountRefBenefits.current = true;
    });
  };

  const getAllTaxes = () => {
    didMountRefTaxes.current = false;
    getTaxes({ id }).then((res) => {
      setTaxes(res?.data);

      taxesformik.setValues({
        taxes: (res?.data || []).map((tax) => {
          return {
            date: new Date(`${tax.date} ${tax.time}`),
            time: tax.time,
            tax_type_id: tax.tax_type?.id,
            percent: tax.percent,
            auto_deduct: tax.auto_deduct,
            tax_id: tax.id,
          };
        }),
      });

      didMountRefTaxes.current = true;
    });
  };

  const getCommissions = async () => {
    try {
      const { data } = await getSalesCommissions(id);
      setSalesCommissions(data);
      if (data.length) {
        formik.setFieldValue(
          'commissions',
          data.map((item) => ({
            id: item.id,
            customer: item.customer || null,
            type: item.type,
            flat_per_shipment: item.type === 1 ? Number(item.flat_per_shipment).toString() || '' : '',
            percentage_per_shipment: item.type === 2 ? Number(item.percentage_per_shipment).toString() || '' : '',
            percentage_after_driver_pay:
              item.type === 3 ? Number(item.percentage_after_driver_pay).toString() || '' : '',
            is_active: !!item.is_active,
            end_date: !item.is_active ? moment(item.end_date || new Date()).toDate() : null,
          }))
        );
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (!staffCompansation) {
      formik.setFieldValue('tax_id', '');
      return;
    }
    const { staff_compensation } = staffCompansation;
    formik.setFieldValue('account_id', staff_compensation?.expense_account?.id || '');
    formik.setFieldValue('tax_id', staff_compensation?.tax_id || '');
    formik.setFieldValue('payment_method', staff_compensation?.payment_method || 'check');
    formik.setFieldValue('third_party_name', staff_compensation?.third_party_name || '');
    formik.setFieldValue('third_party_id', staff_compensation?.third_party_id || '');
    formik.setFieldValue('tax_type_id', staff_compensation?.tax_type?.id);
    formik.setFieldValue('routing_confirmed', staff_compensation?.routing);
    formik.setFieldValue('bank_name', staff_compensation?.bank_name);
    formik.setFieldValue('routing', staff_compensation?.routing);
    formik.setFieldValue('account', Number(staff_compensation?.account) || '');
    formik.setFieldValue('deposit_form_path', staff_compensation?.deposit_form_path);
    formik.setFieldValue('account_confirmed', Number(staff_compensation?.account) || '');
    formik.setFieldValue('compensation_option', staff_compensation?.compensation_option);
    formik.setFieldValue('per_hr', staff_compensation?.per_hr_option);
    formik.setFieldValue('per_hr_amt', staff_compensation?.per_hr_amt);
    formik.setFieldValue('each_booked_shipment', staff_compensation?.each_booked_shipment);
    formik.setFieldValue('flat_or_per', String(staff_compensation?.flat_or_per || 0));
    formik.setFieldValue('percentage', staff_compensation?.percentage);
    formik.setFieldValue('flat_amt', staff_compensation?.flat_amt);
    formik.setFieldValue('sales_commission_option', !!staff_compensation?.sales_commission_option);
  }, [staffCompansation]);

  useEffect(() => {
    getCommissions();
  }, [commissionsIndex]);

  const formik = useFormik({
    initialValues: {
      tax_id: '',
      tax_type_id: null,
      bank_name: '',
      payment_method: 'check',
      third_party_name: '',
      third_party_id: '',
      routing: '',
      routing_confirmed: '',
      account: '',
      account_confirmed: '',
      deposit_form_path: '',
      compensation_option: '1',
      per_hr: false,
      per_hr_amt: '',
      each_booked_shipment: false,
      flat_or_per: null,
      percentage: '',
      flat_amt: '',
      sales_commission_option: false,
      account_id: null,
      commissions: [
        {
          id: Math.random(),
          customer: null,
          type: 1,
          flat_per_shipment: '',
          percentage_per_shipment: '',
          percentage_after_driver_pay: '',
          is_active: false,
          end_date: null,
        },
      ],
    },
    validationSchema: CompensationValidationSchemaStaffProfile,
  });

  const deductionFormik = useFormik({
    initialValues: {
      deductions: [
        {
          staff_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          deduction_type_id: 0,
          quantity: 0,
          per_quantity_amount: 0,
          amount: 0,
          every_settlement: 0,
          month_week_settlement: 1,
          amortized_settlement: 0,
          day: 'Monday',
          month_week: 'Week',
          amortized_total: 0,
          amortized_month: 0,
          amortized_months: 0,
          deduction_id: 'new',
          type: 'deductions',
          is_balance_deduction: 0,
          balanceName: '',
          balanceId: null,
        },
      ],
    },
    validationSchema: deductionsValidationSchema,
  });

  const Additionsformik = useFormik({
    initialValues: {
      additions: [
        {
          staff_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          addition_type_id: null,
          quantity: 0,
          per_quantity_amount: 0,
          amount: 0,
          every_settlement: 0,
          month_week_settlement: 1,
          day: 'Monday',
          month_week: 'Week',
          addition_id: 'new',
          type: 'additions',
        },
      ],
    },
    validationSchema: additionsValidationSchema,
  });

  const taxesformik = useFormik({
    initialValues: {
      taxes: [
        {
          staff_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          tax_type_id: null,
          percent: 0,
          auto_deduct: 0,
          tax_id: 'new',
        },
      ],
    },
    validationSchema: taxesValidationSchema,
  });

  const Benefitformik = useFormik({
    initialValues: {
      benefits: [
        {
          staff_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          benefit_type_id: null,
          quantity: 0,
          per_quantity_amount: 0,
          amount: 0,
          every_settlement: 0,
          month_week_settlement: 1,
          day: 'Monday',
          month_week: 'Week',
          benefit_id: 'new',
          type: 'benefits',
        },
      ],
    },
    validationSchema: benefitsValidationSchema,
  });

  const saveCompensation = () => {
    if (formik.values.tax_type_id === 'Select') {
      showError('Please Select Tax type');
    } else {
      const payload = { ...(staffCompansation?.staff_compensation || {}), ...formik.values };
      updateCompensation(payload, null, successResponse, 'Compensation has been updated successfully');
    }
  };

  const saveCompensationData = (val) => {
    if (val === 'taxes') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        tax_type_id: [],
        tax_id: [],
        percent: [],
        auto_deduct: [],
      };
      for (const val of taxesformik.values.taxes) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.tax_type_id.push(+val.tax_type_id);
        requestPostData.percent.push(+val.percent);
        requestPostData.tax_id.push(val.tax_id);
        requestPostData.auto_deduct.push(+val.auto_deduct);
      }
      AddTaxes(requestPostData)
        .then(() => {
          onSuccess('Taxes has been added successfully');
          getAllData();
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    } else if (val === 'deductions') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        deduction_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        deduction_id: [],
        amortized_settlement: [],
        amortized_total: [],
        amortized_month: [],
        amortized_months: [],
        one_time_deduction: [],
      };
      for (const val of deductionFormik.values.deductions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.deduction_type_id.push(+val.deduction_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.deduction_id.push(val.deduction_id);
        requestPostData.amortized_settlement.push(+val.amortized_settlement);
        requestPostData.amortized_total.push(val.amortized_total);
        requestPostData.amortized_month.push(val.amortized_month);
        requestPostData.amortized_months.push(val.amortized_months);
        requestPostData.one_time_deduction.push(val.one_time_deduction || 0);
      }
      AddDeductions(requestPostData)
        .then(() => {
          onSuccess('Deduction has been added successfully');
          getAllData();
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    } else if (val === 'additions') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        addition_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        addition_id: [],
        one_time_addition: [],
      };
      for (const val of Additionsformik.values.additions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.addition_type_id.push(+val.addition_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.addition_id.push(val.addition_id);
        requestPostData.one_time_addition.push(val.one_time_addition || 0);
      }
      AddAdditions(requestPostData).then(() => {
        onSuccess('Additions has been added successfully');
        getAllData();
      });
    } else if (val === 'benefits') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        benefit_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        benefit_id: [],
      };
      for (const val of Benefitformik.values.benefits) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.benefit_type_id.push(+val.benefit_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.benefit_id.push(val.benefit_id);
      }
      AddBenefits(requestPostData)
        .then(() => {
          onSuccess('Benefits has been added successfully');
          getAllData();
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    }
  };

  const saveStaffCompensation = (val) => {
    if (val === 'taxes') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        tax_type_id: [],
        tax_id: [],
        percent: [],
        auto_deduct: [],
      };
      for (const val of taxesformik.values.taxes) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.tax_type_id.push(+val.tax_type_id);
        requestPostData.percent.push(+val.percent);
        requestPostData.tax_id.push(val.tax_id);
        requestPostData.auto_deduct.push(+val.auto_deduct);
      }
      if (taxesController?.current) {
        taxesController?.current?.abort();
        taxesController.current = new AbortController();
      }

      AddTaxes(requestPostData, taxesController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            taxesformik.setValues((prevState) => ({
              ...prevState,
              taxes: prevState.taxes.map((item) => {
                if (item.tax_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, tax_id: currentItem.id };
                }

                return item;
              }),
            }));
          }
          onSuccess('Taxes have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    } else if (val === 'deductions') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        deduction_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        deduction_id: [],
        amortized_settlement: [],
        amortized_total: [],
        amortized_month: [],
        amortized_months: [],
        one_time_deduction: [],
      };
      for (const val of deductionFormik.values.deductions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.deduction_type_id.push(+val.deduction_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.deduction_id.push(val.deduction_id);
        requestPostData.amortized_settlement.push(+val.amortized_settlement);
        requestPostData.amortized_total.push(val.amortized_total);
        requestPostData.amortized_month.push(val.amortized_month);
        requestPostData.amortized_months.push(val.amortized_months);
        requestPostData.one_time_deduction.push(val.one_time_deduction || 0);
      }
      if (deductionsController?.current) {
        deductionsController?.current?.abort();
        deductionsController.current = new AbortController();
      }

      AddDeductions(requestPostData, deductionsController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            deductionFormik.setValues((prevState) => ({
              ...prevState,
              deductions: prevState.deductions.map((item) => {
                if (item.deduction_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, deduction_id: currentItem.id };
                }

                return item;
              }),
            }));
          }

          onSuccess('Deductions have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    } else if (val === 'additions') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        addition_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        addition_id: [],
        one_time_addition: [],
      };
      for (const val of Additionsformik.values.additions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.addition_type_id.push(+val.addition_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.addition_id.push(val.addition_id);
        requestPostData.one_time_addition.push(val.one_time_addition || 0);
      }
      if (additionsController?.current) {
        additionsController?.current?.abort();
        additionsController.current = new AbortController();
      }

      AddAdditions(requestPostData, additionsController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            Additionsformik.setValues((prevState) => ({
              ...prevState,
              additions: prevState.additions.map((item) => {
                if (item.addition_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, addition_id: currentItem.id };
                }

                return item;
              }),
            }));
          }
          onSuccess('Additions have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    } else if (val === 'benefits') {
      const requestPostData = {
        staff_id: +id,
        date: [],
        time: [],
        benefit_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        benefit_id: [],
      };
      for (const val of Benefitformik.values.benefits) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.benefit_type_id.push(+val.benefit_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.benefit_id.push(val.benefit_id);
      }
      if (benefitsController?.current) {
        benefitsController?.current?.abort();
        benefitsController.current = new AbortController();
      }

      AddBenefits(requestPostData, benefitsController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            Benefitformik.setValues((prevState) => ({
              ...prevState,
              benefits: prevState.benefits.map((item) => {
                if (item.benefit_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, benefit_id: currentItem.id };
                }

                return item;
              }),
            }));
          }
          onSuccess('Benefits have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const successResponse = (msg) => {
    onSuccess(msg);
    setToggle(!toggle);
  };

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], id)
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const getAllData = () => {
    getBalances();
    getDeductions();
    getAddtion();
    getBenefit();
    getAllTaxes();
  };

  useEffect(() => {
    getAllData();
  }, []);

  const setReccuring = (recurringInfo) => {
    const { index } = recurringInfo;
    if (recurringInfo?.type === 'deductions') {
      if (recurringInfo?.reccurring?.every_settlement === 0) {
        deductionFormik.setFieldValue(`deductions[${index}].day`, recurringInfo?.reccurring?.day);
        deductionFormik.setFieldValue(`deductions[${index}].every_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].amortized_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].month_week_settlement`, 1);
        deductionFormik.setFieldValue(`deductions[${index}].month_week`, recurringInfo?.reccurring?.month_week);
      } else if (recurringInfo?.reccurring?.every_settlement === 1) {
        deductionFormik.setFieldValue(`deductions[${index}].every_settlement`, 1);
        deductionFormik.setFieldValue(`deductions[${index}].amortized_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].month_week_settlement`, 0);
      } else if (recurringInfo?.reccurring?.every_settlement === 2) {
        deductionFormik.setFieldValue(
          `deductions[${index}].amortized_month`,
          recurringInfo?.reccurring?.amortized_month
        );
        deductionFormik.setFieldValue(
          `deductions[${index}].amortized_months`,
          recurringInfo?.reccurring?.amortized_months
        );
        deductionFormik.setFieldValue(
          `deductions[${index}].amortized_total`,
          recurringInfo?.reccurring?.amortized_total
        );
        deductionFormik.setFieldValue(`deductions[${index}].amortized_settlement`, 1);
        deductionFormik.setFieldValue(`deductions[${index}].every_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].month_week_settlement`, 0);
      }
    } else if (recurringInfo?.type === 'additions') {
      if (recurringInfo?.reccurring?.every_settlement === 0) {
        Additionsformik.setFieldValue(`additions[${index}].day`, recurringInfo?.reccurring?.day);
        Additionsformik.setFieldValue(`additions[${index}].every_settlement`, 0);
        Additionsformik.setFieldValue(`additions[${index}].month_week_settlement`, 1);
        Additionsformik.setFieldValue(`additions[${index}].month_week`, recurringInfo?.reccurring?.month_week);
      } else if (recurringInfo?.reccurring?.every_settlement === 1) {
        Additionsformik.setFieldValue(`additions[${index}].every_settlement`, 1);
        Additionsformik.setFieldValue(`additions[${index}].month_week_settlement`, 0);
      }
    } else if (recurringInfo?.type === 'benefits') {
      if (recurringInfo?.reccurring?.every_settlement === 0) {
        Benefitformik.setFieldValue(`benefits[${index}].day`, recurringInfo?.reccurring?.day);
        Benefitformik.setFieldValue(`benefits[${index}].every_settlement`, 0);
        Benefitformik.setFieldValue(`benefits[${index}].month_week_settlement`, 1);
        Benefitformik.setFieldValue(`benefits[${index}].month_week`, recurringInfo?.reccurring?.month_week);
      } else if (recurringInfo?.reccurring?.every_settlement === 1) {
        Benefitformik.setFieldValue(`benefits[${index}].every_settlement`, 1);
        Benefitformik.setFieldValue(`benefits[${index}].month_week_settlement`, 0);
      }
    }
    setRecurringModalShow(false);
    setRecurringModalData({});
  };

  const remove = (index, data) => {
    if (data === 'pay_raise') {
      const temp = formik.values.pay_raise;
      temp.splice(index, 1);
      formik.setFieldValue('pay_raise', temp);
    } else if (data === 'deductions') {
      if (deductionFormik.values.deductions[index]?.deduction_id === 'new') {
        const temp = deductionFormik.values.deductions;
        temp.splice(index, 1);
        deductionFormik.setFieldValue('deductions', temp);
      } else {
        const deId = deductionFormik.values.deductions[index]?.deduction_id;
        deleteDeduction({ deId })
          .then(() => {
            const temp = deductionFormik.values.deductions;
            temp.splice(index, 1);
            deductionFormik.setFieldValue('deductions', temp);
            onSuccess('Deduction has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    } else if (data === 'additions') {
      if (Additionsformik.values.additions[index]?.addition_id === 'new') {
        const temp = Additionsformik.values.additions;
        temp.splice(index, 1);
        Additionsformik.setFieldValue('additions', temp);
      } else {
        const addId = Additionsformik.values.additions[index]?.addition_id;
        deleteAddition({ addId })
          .then(() => {
            const temp = Additionsformik.values.additions;
            temp.splice(index, 1);
            Additionsformik.setFieldValue('additions', temp);
            onSuccess('Addition has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    } else if (data === 'benefits') {
      if (Benefitformik.values.benefits[index]?.benefit_id === 'new') {
        const temp = Benefitformik.values.benefits;
        temp.splice(index, 1);
        Benefitformik.setFieldValue('benefits', temp);
      } else {
        const beneId = Benefitformik.values.benefits[index]?.benefit_id;
        deleteBenefit({ beneId })
          .then(() => {
            const temp = Benefitformik.values.benefits;
            temp.splice(index, 1);
            Benefitformik.setFieldValue('benefits', temp);
            onSuccess('Benefits has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    } else if (data === 'taxes') {
      if (taxesformik.values.taxes[index]?.tax_id === 'new') {
        const temp = taxesformik.values.taxes;
        temp.splice(index, 1);
        taxesformik.setFieldValue('taxes', temp);
      } else {
        const taxId = taxesformik.values.taxes[index]?.tax_id;
        deleteTax({ taxId })
          .then(() => {
            const temp = taxesformik.values.taxes;
            temp.splice(index, 1);
            taxesformik.setFieldValue('taxes', temp);
            onSuccess('Tax has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    }
  };

  const onClickView = (path1) => {
    setPdfUrl(path1);
  };

  const onViewBalanceDetails = (balance) => {
    setBalanceToEdit(convertBalanceDetails(balance));
  };

  const onEditBalanceSuccess = (settlement) => {
    setBalanceToEdit((prevState) => convertBalanceDetails({ ...prevState, deductions: [{ settlement }] }));
  };

  useEffect(() => {
    if (!didMountRefDeductions.current) {
      return;
    }
    saveStaffCompensation('deductions');
  }, [deductionFormik?.values]);

  useEffect(() => {
    if (!didMountRefAdditions.current) {
      return;
    }
    saveStaffCompensation('additions');
  }, [Additionsformik?.values]);

  useEffect(() => {
    if (!didMountRefTaxes.current) {
      return;
    }
    saveStaffCompensation('taxes');
  }, [taxesformik?.values]);

  useEffect(() => {
    if (!didMountRefBenefits.current) {
      return;
    }
    saveStaffCompensation('benefits');
  }, [Benefitformik?.values]);

  return (
    <div
      className='Driver-compansation-wrapper driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.dark600),
      }}
    >
      <div className='header' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Compensation
        </p>

        {permissions.includes(PERMISSIONS.STAFF.name) ? (
          !toggle ? (
            <div>
              <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
            </div>
          ) : (
            <div>
              <button onClick={() => setToggle(!toggle)} className='driver-compansation-cancel-btn'>
                Cancel
              </button>

              {loading ? (
                <CircularProgress size={30} />
              ) : (
                <button
                  style={{ opacity: formik.isValid ? 1 : 0.7 }}
                  disabled={!formik.isValid}
                  onClick={() => saveCompensation()}
                  className='driver-compansation-save-btn'
                >
                  Save
                </button>
              )}
            </div>
          )
        ) : null}
      </div>

      {permissions.includes(PERMISSIONS.STAFF.name) ? (
        toggle ? (
          <div className='driver-compansation-edit-mode'>
            <CompensationContent
              formik={formik}
              use={use}
              taxOption={taxOption}
              uploadDocument={uploadDocument}
              companyPolicy={companyPolicy}
              driver_id={id}
              driverId={id}
              staffId={id}
              remove={remove}
              isDriverProfile
              setRecurringModalShow={setRecurringModalShow}
              recurringModalShow={recurringModalShow}
              deductionFormik={deductionFormik}
              setWeek={setWeek}
              week={week}
              deductionType={deductionType}
              addtionType={addtionType}
              benefitType={benefitType}
              taxesType={taxesType}
              balances={balances}
              getBalances={getBalances}
              saveCompensationData={saveCompensationData}
              setRecurringModalData={setRecurringModalData}
              recurringModalData={recurringModalData}
              setReccuring={setReccuring}
              Additionsformik={Additionsformik}
              Benefitformik={Benefitformik}
              taxesformik={taxesformik}
              getDeductions={getDeductions}
              getAddtions={getAddtion}
              getBenefits={getBenefit}
              getAllTaxes={getAllTaxes}
              getCommissions={getCommissions}
              ImageDropZone={ImageDropZone}
              refreshOptions={getOptions}
              onViewBalanceDetails={onViewBalanceDetails}
            />
          </div>
        ) : !staffCompansation ? (
          <div className='Driver-compansation'>
            <div className='d-flex justify-content-center align-items-center'>
              <p style={{ color: use(palette.gray400, palette.gray50) }}>No Compensation added for this profile</p>
            </div>
          </div>
        ) : (
          <div className='Driver-compansation'>
            <div>
              <div
                className='compansation-name-container'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  tax details
                </p>
              </div>
              <div className='details-container'>
                {taxDetails.map((item, index) => {
                  return (
                    <div key={item.value || index} className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {item.property}
                      </p>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.value}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            {staffCompansation?.staff_compensation?.payment_method && (
              <div>
                <div
                  className='compansation-name-container'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray200) }}>
                    Payout Method
                  </p>
                </div>
                <div className='details-container'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Payout Method
                    </p>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      {staffCompansation?.staff_compensation?.payment_method === 'check' && 'Check'}
                      {staffCompansation?.staff_compensation?.payment_method === 'ach' && 'ACH'}
                      {staffCompansation?.staff_compensation?.payment_method === 'third_party' && '3rd Party'}
                    </p>
                  </div>
                  {staffCompansation?.staff_compensation?.payment_method === 'ach' &&
                    bankDetails.map((item) => {
                      return (
                        <div key={item.value} className='Driver-compansation-details'>
                          <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                            {item.property}
                          </p>
                          {item.value === 'View' ? (
                            <p
                              className='details-value details-value-text'
                              style={{ color: '#4F5AED' }}
                              onClick={() => onClickView(staffCompansation?.staff_compensation?.deposit_form_path)}
                            >
                              View
                            </p>
                          ) : (
                            <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                              {item.value}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  {staffCompansation?.staff_compensation?.payment_method === 'third_party' && (
                    <>
                      <div className='Driver-compansation-details'>
                        <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                          3rd Party Name
                        </p>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          {staffCompansation?.staff_compensation?.third_party_name}
                        </p>
                      </div>
                      <div className='Driver-compansation-details'>
                        <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                          3rd Party Identifier
                        </p>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          {staffCompansation?.staff_compensation?.third_party_id}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {staffCompansation?.staff_compensation?.per_hr_option === 1 && (
              <div className='details-container-payemnt'>
                <div
                  className='compansation-name-container'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Per Hour
                  </p>
                </div>
                <div
                  className='Driver-compansation-details-payment Staff-compansation-details-payment'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Per Hour
                  </p>
                  <div className='payment-per-hour' style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}>
                    <p className='payment-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                      {currency} {staffCompansation?.staff_compensation?.per_hr_amt}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {staffCompansation?.staff_compensation?.each_booked_shipment === 1 && (
              <div className='details-container-payemnt'>
                <div
                  className='compansation-name-container'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Per Each Booked Shipment
                  </p>
                </div>
                {staffCompansation?.staff_compensation?.flat_or_per === 1 ? (
                  <div
                    className='Driver-compansation-details-payment Staff-compansation-details-payment'
                    style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                  >
                    <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                      By Flat
                    </p>
                    <div className='payment-per-hour' style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}>
                      <p className='payment-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {currency} {staffCompansation?.staff_compensation?.flat_amt}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className='Driver-compansation-details-payment Staff-compansation-details-payment'
                    style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                  >
                    <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                      By Percentage
                    </p>
                    <div className='payment-per-hour' style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}>
                      <p className='payment-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {' '}
                        {staffCompansation?.staff_compensation?.percentage} %
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!!staffCompansation?.staff_compensation?.sales_commission_option && (
              <div className='details-container-payemnt'>
                <div
                  className='compansation-name-container'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name mb-2' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Sales Agent commission
                  </p>
                </div>
                <div>
                  <div className='mb-5'>
                    <STable>
                      <thead>
                        <tr>
                          <th>CUSTOMER</th>
                          <th>FLAT PER SHIPMENT</th>
                          <th>% OF SHIPMENT GROSS</th>
                          <th>% OF SHIPMENT AFTER DRIVER PAY</th>
                          <th>END DATE</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {salesCommissions.map((commission) => (
                          <tr key={commission.id}>
                            <td>
                              <Typography variant='s3' style={{ color: palette.gray700 }}>
                                {commission.customer?.company_name}
                              </Typography>
                            </td>
                            <td>
                              {commission.type === 1 ? (
                                <Typography variant='s3' style={{ color: palette.gray700 }}>
                                  {currency}
                                  {formatNumber(commission.flat_per_shipment)}
                                </Typography>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {commission.type === 2 ? (
                                <Typography variant='s3' style={{ color: palette.gray700 }}>
                                  {formatNumber(commission.percentage_per_shipment)}%
                                </Typography>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {commission.type === 3 ? (
                                <Typography variant='s3' style={{ color: palette.gray700 }}>
                                  {formatNumber(commission.percentage_after_driver_pay)}%
                                </Typography>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              <Typography variant='s3' style={{ color: palette.gray700 }}>
                                {commission.is_active
                                  ? 'While Active'
                                  : moment(commission.end_date).format('MM/DD/YYYY')}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </STable>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <div className='d-flex align-items-center gap-3'>
                  <p className='compansation-name ' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Balances
                  </p>
                  <Tooltip
                    placement='right'
                    title={
                      <Typography variant='s2' style={{ color: palette.gray700 }}>
                        Truckin Digital Balances is a feature that enables companies to manage and track financial
                        balances for their drivers or staff members. Users can add specific balances for each driver or
                        staff member, allowing the company to maintain a clear overview of any deductions or outstanding
                        balances.
                      </Typography>
                    }
                    tooltipStyles={{ bgcolor: palette.white, border: '1px solid #dadde9', maxWidth: '600px' }}
                  >
                    <InfoIcon width={16} height={16} />
                  </Tooltip>
                </div>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date Created
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Name
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Type
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Payments
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Payment Amount
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Term
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Original Balance
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Balance Remain
                </p>
              </div>
              {balances?.map((item) => {
                const processedSettlements = item.deductions?.[0]?.settlement?.filter(
                  (settlement) => settlement.staff_settlement.status_id === 3
                );
                const paidAmount = (processedSettlements || []).reduce((acc, cur) => acc + Number(cur.amount), 0);

                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode balance-row'
                    onClick={() => onViewBalanceDetails(item)}
                  >
                    <div className='compensation-amount-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {convertToCustomerTime(item.created_at)}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {item.name}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap' style={{ display: 'flex' }}>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item.type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {(processedSettlements || []).length} out of {item.amortized_months}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.payment_amount)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item.month_week_settlement
                          ? `Every ${item.day} of the ${item.month_week}`
                          : `Over ${item.amortized_months} settlements`}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.original_balance)}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.original_balance - paidAmount)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name ' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Deductions
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
                <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>Deduction Type</p>
                <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Quantity</p>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Amount Per Quantity</p>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Total</p>
                <p className='compensation-feilds-header-head'>Set Interval</p>
              </div>
              {deduction?.map((item) => {
                const processedSettlements = (item.settlement || [])?.filter(
                  (settlement) => settlement.staff_settlement.status_id === 3
                );

                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.deduction_type?.deduction_type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.quantity ? `${item?.quantity}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.per_quantity_amount ? `${currency} ${item?.per_quantity_amount}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      {!!item.is_balance_deduction && processedSettlements.length >= item.amortized_months ? (
                        <Chip label='BALANCE FULFILLED' labelColor={palette.green500} bgColor={palette.green0} />
                      ) : (
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          {item?.amount ? `- ${currency}${item?.amount}` : ''}
                        </p>
                      )}
                    </div>
                    {item?.month_week_settlement === 1 ? (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p className='compensation-feilds-header-head mb-0 me-0'>Every</p>
                        <div
                          className='details-value-highlight'
                          style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{ color: use(palette.gray900, palette.gray400) }}
                          >
                            {item?.day}
                          </p>
                        </div>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          of the {item?.month_week}
                        </p>
                      </div>
                    ) : item?.every_settlement === 1 ? (
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          Every Settlement
                        </p>
                      </div>
                    ) : item?.amortized_settlement === 1 ? (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p className='compensation-feilds-header-head mb-0 me-0'>Amortized</p>
                        <div
                          className='details-value-highlight'
                          style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{ color: use(palette.gray900, palette.gray400) }}
                          >
                            {currency}
                            {item?.amortized_total}
                          </p>
                        </div>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          over {item?.amortized_months} settlements, deducted on each settlement.
                        </p>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Additions
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
                <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>Addition Type</p>
                <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Quantity</p>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Amount Per Quantity</p>
                <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Total</p>
                <p className='compensation-feilds-header-head'>Set Interval</p>
              </div>
              {addtion?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.addition_type?.addition_type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.quantity ? `${item?.quantity}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.per_quantity_amount ? `${currency} ${item?.per_quantity_amount}` : ''}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.amount ? `${currency}${item?.amount}` : ''}
                      </p>
                    </div>
                    {item?.every_settlement === 0 ? (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p className='compensation-feilds-header-head mb-0 me-0'>Every</p>
                        <div
                          className='details-value-highlight'
                          style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{ color: use(palette.gray900, palette.gray400) }}
                          >
                            {item?.day}
                          </p>
                        </div>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          of the {item?.month_week}
                        </p>
                      </div>
                    ) : (
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          Every Settlement
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Taxes
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
                <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>Tax Description</p>
                <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Percent</p>
                <p className='compensation-feilds-header-head'>Set Interval</p>
              </div>
              {taxes?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property  mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.tax_type?.tax_type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.percent ? `${item?.percent}%` : ''}
                      </p>
                    </div>
                    <div className='compensation-recurring-wraper'>
                      <input type='checkbox' checked={item?.auto_deduct === 1} />
                      <p className='taxes-compensation-subline  mb-0'>Auto deduct taxes on each settlement</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Benefits
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Date / Time</p>
                <p className='compensation-feilds-header-head compensation-custom-select-main-wrap'>
                  Benefit Description
                </p>
                <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Quantity</p>
                <p className='compensation-feilds-header-head compensation-datepicker-main-wrap'>Amount Per Quantity</p>
                <p className='compensation-feilds-header-head compensation-amount-main-wrap'>Total</p>
                <p className='compensation-feilds-header-head'>Set Interval</p>
              </div>
              {benefit?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.benefit_type?.benefit_type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.quantity ? `${item?.quantity}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.per_quantity_amount ? `${currency} ${item?.per_quantity_amount}` : ''}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                        {item?.amount ? `- ${currency}${item?.amount}` : ''}
                      </p>
                    </div>
                    {item?.every_settlement === 0 ? (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p className='compensation-feilds-header-head mb-0 me-0'>Every</p>
                        <div
                          className='details-value-highlight'
                          style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{ color: use(palette.gray900, palette.gray400) }}
                          >
                            {item?.day}
                          </p>
                        </div>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          of the {item?.month_week}
                        </p>
                      </div>
                    ) : (
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          Every Settlement
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <p style={{ color: use(palette.gray400, palette.gray50) }} className='mb-0 mt-2'>
            You are not authorized to view this information, attempt is logged.
          </p>
        </div>
      )}
      <ViewPdf title='View Document' open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
      {!!balanceToEdit && (
        <EditBalance
          open={!!balanceToEdit}
          balance={balanceToEdit}
          onClose={() => {
            setBalanceToEdit(null);
            getBalances();
            getDeductions();
          }}
          userType='staff'
          onSuccess={onEditBalanceSuccess}
        />
      )}
    </div>
  );
};

export default StaffCompansation;
