import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { getCostFormInitialValues } from 'components/DispatchVehicle/helpers';
import AdditionsSettings from 'pages/Payroll/Settlement/Tables/Additions/components/AdditionsSettings';
import { palette } from 'utils/constants';
import useForm from 'hooks/useForm';
import { getAdditionsList } from 'Api/PayrollSettings';
import ItemForm from './ItemForm';
import { validationSchema } from './validationSchema';
import { SRow, SAddMore } from './CostModal.styles';

const CostModal = ({ open, onClose, data, flatRate, flatRateChecked, onApply, costFormValues }) => {
  const [additions, setAdditions] = useState([]);
  const [openSettings, setOpenSettings] = useState(false);

  const { values, handleChange, setValues, handleSubmit, touchedErrors } = useForm({
    initialValues:
      costFormValues.length > 0 ? costFormValues : getCostFormInitialValues(data, flatRate, flatRateChecked),
    validationSchema,
    onSubmit: onApply,
  });

  const getAdditions = async () => {
    try {
      const { data } = await getAdditionsList();
      setAdditions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const addNewForm = () => {
    handleChange(values.length, {
      id: Math.random(),
      isNew: true,
      isEdit: true,
      addition: null,
      qty: '',
      rate: '',
      total: '',
      type: 'addition',
    });
  };

  const deleteFormItem = (id) => {
    const newValues = values.filter((item) => item.id !== id);
    setValues(newValues);
  };

  const onSettingsClose = () => {
    getAdditions();
    setOpenSettings(false);
  };

  useEffect(() => {
    getAdditions();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Edit Cost'
      className='modified-scrollbar'
      $bgColor={palette.gray0}
      $maxWidth='950px'
      $minWidth='950px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Apply',
          onClick: handleSubmit,
        },
      ]}
    >
      <SRow>
        <div className='header-item'>
          <Typography variant='overLine' style={{ color: palette.gray700 }}>
            DESCRIPTION
          </Typography>
          <GearIcon onClick={() => setOpenSettings(true)} />
        </div>
        <div className='header-item'>
          <div className='d-flex justify-content-end'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              DRIVER
            </Typography>
          </div>
        </div>
        <div className='header-item'>
          <div className='d-flex justify-content-end'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              QTY
            </Typography>
          </div>
        </div>
        <div className='header-item'>
          <div className='d-flex justify-content-end'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              RATE
            </Typography>
          </div>
        </div>
        <div className='header-item'>
          <div className='d-flex justify-content-end'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              TOTAL
            </Typography>
          </div>
        </div>
        <div className='header-item' />
      </SRow>
      {values.map((item, index) => (
        <ItemForm
          key={item.id}
          values={values}
          item={item}
          index={index}
          additions={additions}
          handleChange={handleChange}
          touchedErrors={touchedErrors}
          onDelete={() => deleteFormItem(item.id)}
          driver={data.equipment.drivers[0]}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          Add Another...
        </Typography>
      </SAddMore>
      <AdditionsSettings open={openSettings} onClose={onSettingsClose} />
    </Modal>
  );
};

export default CostModal;
