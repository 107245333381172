import React, { useEffect, useState } from 'react';
import { UpdatePlannerPopoverSettings } from 'Api/Planner';
import styles from './PopoverSettings.module.css';
import TableColumn from './TableColumn';
import ColumnOrder from './ColumnOrder';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomButton from '../CustomButton/CustomButton';
import { amountSwitch } from './constants';
import { Typography } from '../Typography';

const PopoverSettings = ({
  onChangeOrder,
  filter,
  is,
  updateFilter,
  setShowPopover,
  switchRadioButtons,
  onsStTableColumn,
  onSetSwitchRadioButtons,
  dragItem,
  tableColumn,
  typeDataJson,
  progressBar,
  onChangeProgressBar,
}) => {
  const IS_SHIPMENT = is === 'shipment';
  const [localState, setLocalState] = useState([]);
  const [localDragItem, setLocalDragItem] = useState([]);
  const [switchRadioButtonsLocal, setSwitchRadioButtonsLocal] = useState({});
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

  function UpdateTableSettings(dataJson) {
    return UpdatePlannerPopoverSettings(dataJson).then((res) => res);
  }

  const onChangeHandler = (name, value) => {
    setSwitchRadioButtonsLocal({ ...switchRadioButtonsLocal, [name]: value });
  };

  const onChangeTick = (value) => {
    updateFilter({ ...filter, forAll: value });
  };

  const onCancel = () => {
    setShowPopover(false);
  };

  const onClickApply = () => {
    const { forAll } = filter;
    const { amount, date, time } = switchRadioButtonsLocal;

    updateFilter({ ...filter, tableColumn: localState, switchRadioButtons: switchRadioButtonsLocal });
    onsStTableColumn([...localState]);
    onSetSwitchRadioButtons({ ...switchRadioButtonsLocal });
    onChangeOrder(localDragItem);
    const updateData = {
      type: typeDataJson,
      forAll,
      data: {
        amount: +amount,
        date: +date,
        time: +time,
        columns: {},
        columns_order: {},
      },
    };
    if (IS_SHIPMENT) updateData.data.shipment_progress_bar = progressBar;
    localState.forEach((el) => {
      const { value, checked } = el;
      updateData.data.columns[value] = checked;
    });

    updateData.data.columns_order = localDragItem.map((el) => el.value);

    UpdateTableSettings(updateData).then((res) => res && res?.data && setShowPopover(false));
  };

  useEffect(() => {
    setSwitchRadioButtonsLocal(switchRadioButtons);
  }, [switchRadioButtons]);

  useEffect(() => {
    setLocalDragItem(dragItem);
  }, [dragItem]);

  return (
    <div>
      <div className={styles.popoverSettings_title_wrapper}>
        <Typography variant='s2'>Table Settings</Typography>
      </div>

      <TableColumn
        localState={localState}
        tableColumn={tableColumn}
        setLocalState={(param) => setLocalState(param)}
        localDragItem={localDragItem}
        onChangeOrderLocal={(items) => setLocalDragItem(items)}
      />

      <ColumnOrder localDragItem={localDragItem} onChangeOrderLocal={(items) => setLocalDragItem(items)} />
      {IS_SHIPMENT && (
        <CustomCheckbox
          style={{ marginLeft: 10 }}
          labelStyle={{ marginTop: 10 }}
          checked={progressBar}
          onChange={onChangeProgressBar}
        >
          <Typography variant='s2' style={{ marginLeft: 8 }}>
            Show Shipment Progress Bar
          </Typography>
        </CustomCheckbox>
      )}
      <div style={{ marginBottom: 24 }} />
      <div className={styles.radio_buttons_wrapper}>
        <SwitchRadio
          style={{ marginLeft: 10, position: 'static' }}
          name='amount'
          items={amountSwitch}
          title='Amount'
          value={+switchRadioButtonsLocal.amount}
          onChange={onChangeHandler}
        />
      </div>
      <div style={{ borderBottom: '1px solid #D5DBE5', margin: '16px 0', height: '1px' }} />
      <div className={styles.popoverSettings_footer}>
        {user?.department?.department_name === 'Management' && (
          <CustomCheckbox style={{ marginLeft: 10 }} checked={filter.forAll} onChange={onChangeTick}>
            <span className={styles.footer_title_checkbox}>Apply to all users</span>
          </CustomCheckbox>
        )}

        <div className={styles.footer_right}>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '2px 8px', marginRight: 16 }}
            onClick={onCancel}
          />

          <CustomButton type='primary' title='Apply' styleButton={{ padding: '2px 8px' }} onClick={onClickApply} />
        </div>
      </div>
    </div>
  );
};

export default PopoverSettings;
