import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import InfoItem from './InfoItem';

const Header = ({ shipment }) => {
  const { currency } = useSelector((state) => state.root);
  const {
    shipment_id,
    shipment_billing,
    is_edi_api,
    manual_edi_api,
    type,
    driver_amount,
    net_revenue,
    loaded_miles,
    empty_miles,
  } = shipment || {};
  const grossRevenue = shipment_billing.reduce((acc, current) => acc + (current.total_amount || 0), 0);

  return (
    <div className='d-flex justify-content-between'>
      <div>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Shipment Summary
        </Typography>
        <div className='d-flex align-items-center gap-4'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Shipment {shipment_id} -{' '}
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              {shipment_billing[0].billing_customer.company_name}
            </Typography>
          </Typography>

          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {type === 'ltl' ? 'LTL' : 'TL'}
            {(!!is_edi_api || !!manual_edi_api) && <CloudIcon fill={palette.green400} className='ms-2' />}
          </Typography>

          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Reference:{' '}
            {shipment_billing.length > 1 ? (
              <Tooltip
                title={
                  <div className='d-flex flex-column gap-1'>
                    {shipment_billing.map((billing) => (
                      <Typography variant='b2' style={{ color: palette.white }}>
                        {billing.reference_id}
                      </Typography>
                    ))}
                  </div>
                }
              >
                <span style={{ color: palette.gray700, fontSize: '14px', fontWeight: 400 }}>Multi-Ref</span>
              </Tooltip>
            ) : (
              shipment_billing[0].reference_id || '-'
            )}
          </Typography>
        </div>
      </div>
      <div className='d-flex align-items-center gap-4'>
        <InfoItem title='Gross Revenue' content={`${currency}${formatNumber(grossRevenue)}`} />
        <InfoItem title='Costs' content={`${currency}${formatNumber(driver_amount)}`} />
        <InfoItem title='Net' content={`${currency}${net_revenue || 0}`} />
        <InfoItem
          title='RPM'
          content={`${currency}${formatNumber(
            shipment_billing.reduce((acc, cur) => Number(acc) + Number(cur.total_amount || 0), 0) /
              (Math.round(loaded_miles || 0) + Math.round(empty_miles || 0))
          )}`}
        />
      </div>
    </div>
  );
};

export default Header;
