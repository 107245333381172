import React, { useMemo, useState } from 'react';
import Tab from '@mui/material/Tab';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import styles from 'components/Billing/PurchaseAddOnsModal/PurchaseAddOnsModal.module.css';
import { STabs } from 'components/Billing/PurchaseAddOnsModal/PurchaseAddOnsModal.styles';
import { ADDON_PLAN_ID, formatNumber, numberWithCommas, palette } from 'utils/constants';
import AddonDetails from 'components/Billing/SelectPlanModal/Addons/AddonDetails';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { SAddons } from './Addons.styles';

const Addons = ({ open, onClose, addons, selectedAddons, handleSubmit, selectedPlanType, prices }) => {
  const showToaster = useShowToaster();
  const { value: userData } = useAuth();
  const [cartItems, setCartItems] = useState(selectedAddons || []);
  const [tab, setTab] = useState('All');
  const [activeId, setActiveId] = useState(null);
  const [viewGuideModal, setViewGuideModal] = useState(null);
  const [addonToView, setAddonToView] = useState(null);
  const { duration } = selectedPlanType || {};
  const isBroker = userData?.user?.customer?.customer_type === 'carrier_broker';

  const tabs = useMemo(() => {
    return addons?.reduce(
      (acc, cur) => {
        if (!cur.category || acc.some((item) => item === cur.category)) {
          return acc;
        }
        return [...acc, cur.category];
      },
      ['All']
    );
  }, [addons]);

  const onSubmit = () => {
    handleSubmit(cartItems);
    onClose();
  };

  const addToCart = (data) => {
    const foundItem = cartItems.find((item) => item.id === data.id);

    if (foundItem) {
      setCartItems((prevState) => prevState.map((item) => (item.id === foundItem.id ? data : item)));
      showToaster({ type: 'success', message: 'Successfully added to cart!' });
      return;
    }

    setCartItems((prevState) => [...prevState, data]);
    showToaster({ type: 'success', message: 'Successfully added to cart!' });
  };

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  const onViewGuide = (addon) => {
    if (addon.guide_type === 'pdf') {
      setViewGuideModal(addon.guide);
      return;
    }

    window.open(addon.guide, '_blank');
  };

  const onAddToCart = (item) => {
    const active = item.plans?.length === 1 ? item.plans[0].id : activeId;

    if (item?.assigned_type?.id !== 4 || !!item?.add_custom_fields?.length) {
      setActiveId(active);
      setAddonToView(item);
      return;
    }

    const activePlan = item.plans?.find((i) => i.id === active);
    addToCart({ ...item, activePlan, quantity: 1 });
    setActiveId(null);
    showToaster({ type: 'success', message: 'Successfully added to cart!' });
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Add-Ons'
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='80vw'
      $minHeight='500px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update Cart',
          onClick: onSubmit,
        },
      ]}
    >
      <SAddons>
        <STabs value={tab} onChange={handleTabChange} variant='scrollable' visibleScrollbar dissableRipple>
          {tabs.map((item) => (
            <Tab
              value={item}
              label={
                <span style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                  {item}{' '}
                  <Chip
                    label={item === 'All' ? addons.length : addons?.filter((i) => i.category === item)?.length || 0}
                    style={{ borderRadius: '12px' }}
                  />
                </span>
              }
              key={item}
              disableRipple
            />
          ))}
        </STabs>
        {(tab === 'All' ? addons : addons.filter((i) => i.category === tab))?.map((item) => {
          return (
            <div key={item?.id} className={styles.item_wrapper}>
              <div className={styles.image_wrapper}>
                <figure className={styles.figure}>
                  <img className={styles.image} src={item?.image} alt='Img' />
                </figure>
              </div>
              <div className={styles.info_wrapper}>
                <div className={styles.center}>
                  <div className={styles.title_subtitle}>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {item?.name}
                    </Typography>
                    <Typography variant='b2'>{item?.description}</Typography>
                  </div>
                  {item?.plans?.length > 1 ? (
                    <div className={styles.plans}>
                      {item?.plans?.map((plan) => {
                        return (
                          <div key={plan.id} className={styles.plan}>
                            <label className={styles.label}>
                              <CustomCheckbox
                                checked={activeId === plan.id}
                                name={`addon_plan_${plan?.id}`}
                                disabled={item.status === 0 || cartItems?.some((i) => i.id === item.id)}
                                checkmarkStyle={{
                                  cursor:
                                    item.status === 0 || cartItems?.some((i) => i.id === item.id)
                                      ? 'not-allowed'
                                      : 'pointer',
                                }}
                                onChange={() => setActiveId(plan?.id)}
                              >
                                <Typography
                                  variant='s2'
                                  style={{
                                    color: palette.gray700,
                                    marginLeft: 5,
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {plan?.plan_title},
                                  <Typography
                                    variant='s2'
                                    style={{
                                      color: palette.gray500,
                                      marginLeft: 5,
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {Number(plan?.monthly_amount) === 0
                                      ? `Included with plan`
                                      : `${formatNumber(Number(plan?.monthly_amount))} ${
                                          planMapper[duration]?.addonBillTitle
                                        }`}{' '}
                                    {!!plan?.setup_one_time_fee &&
                                    plan?.setup_one_time_fee !== '0.00' &&
                                    plan?.setup_one_time_fee !== '0'
                                      ? `+ One Time Setup Fee $${numberWithCommas(
                                          Number(plan?.setup_one_time_fee).toFixed(2)
                                        )}`
                                      : ''}
                                  </Typography>
                                </Typography>
                              </CustomCheckbox>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className={styles.plans}>
                      {item?.plans?.map((plan) => {
                        return (
                          <div key={plan.id} className={styles.plan}>
                            {/* <label className={styles.label}> */}
                            {/*  <CustomCheckbox */}
                            {/*    checked={activeId === plan.id} */}
                            {/*    name={`addon_plan_${plan?.id}`} */}
                            {/*    disabled={item.status === 0 || cartItems?.some((i) => i.id === item.id)} */}
                            {/*    checkmarkStyle={{ */}
                            {/*      cursor: */}
                            {/*        item.status === 0 || cartItems?.some((i) => i.id === item.id) */}
                            {/*          ? 'not-allowed' */}
                            {/*          : 'pointer', */}
                            {/*    }} */}
                            {/*    onChange={() => setActiveId(plan?.id)} */}
                            {/*  > */}
                            <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                              {plan?.plan_title},
                              <Typography
                                variant='s2'
                                style={{
                                  color: palette.gray500,
                                  marginLeft: 5,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {Number(plan?.monthly_amount) === 0
                                  ? `Included with plan`
                                  : `${formatNumber(Number(plan?.monthly_amount))} ${
                                      planMapper[duration]?.addonBillTitle
                                    }`}{' '}
                                {!!plan?.setup_one_time_fee &&
                                plan?.setup_one_time_fee !== '0.00' &&
                                plan?.setup_one_time_fee !== '0'
                                  ? `+ One Time Setup Fee $${numberWithCommas(
                                      Number(plan?.setup_one_time_fee).toFixed(2)
                                    )}`
                                  : ''}
                              </Typography>
                            </Typography>
                            {/*  </CustomCheckbox> */}
                            {/* </label> */}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                {(!isBroker || item.plan_id !== ADDON_PLAN_ID.BROKERAGE) && (
                  <div className='actions-container'>
                    {item.status !== 0 ? (
                      cartItems?.some((i) => i.id === item.id) ? (
                        <CustomButton
                          type='secondary'
                          title='Remove from Cart'
                          styleButton={{ padding: '6px 12px', margin: 0 }}
                          styleTitle={{ fontSize: 14 }}
                          onClick={() => setCartItems((prevState) => prevState.filter((i) => i.id !== item.id))}
                        />
                      ) : (
                        <CustomButton
                          type='secondary'
                          title='Add to Cart'
                          styleButton={{ padding: '6px 12px', margin: 0 }}
                          styleTitle={{ fontSize: 14 }}
                          onClick={() => onAddToCart(item)}
                          disabled={item.plans?.length > 1 ? item.plans.every((i) => i.id !== activeId) : false}
                        />
                      )
                    ) : (
                      <CustomButton
                        type='secondary'
                        title='NOT AVAILABLE'
                        styleButton={{ padding: '6px 12px', margin: 0 }}
                        styleTitle={{ fontSize: 14 }}
                        disabled
                      />
                    )}
                    {item.guide && (
                      <CustomButton
                        type='secondary'
                        title='View Guide'
                        styleButton={{ padding: '6px 12px', margin: 0 }}
                        styleTitle={{ fontSize: 14 }}
                        onClick={() => onViewGuide(item)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </SAddons>
      {!!addonToView && (
        <AddonDetails
          open={!!addonToView}
          onClose={() => setAddonToView(null)}
          addon={addonToView}
          activeId={activeId}
          handleAddToCart={addToCart}
          onSuccess={() => setActiveId(null)}
          selectedPlanType={selectedPlanType}
          prices={prices}
        />
      )}
      <ViewPdf open={!!viewGuideModal} title='Guide' pdfUrl={viewGuideModal} onClose={() => setViewGuideModal(null)} />
    </Modal>
  );
};

export default Addons;
