import jsPDF from 'jspdf';
import moment from 'moment';
import { formatPhoneNumber } from 'utils/helpers';
import { DATE_FORMAT, palette, TIME_FORMAT } from 'utils/constants';
import locationIcon from 'assets/icons/location.png';
import globeIcon from 'assets/icons/globe.png';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import { generateShipmentCharges } from './shipmentCharges';
import { generateShipmentDetails } from './shipmentDetails';

const currencies = {
  $: 'USD',
  C$: 'CAD',
  '₱': 'MXN',
};

const createFooter = (
  doc,
  company,
  values,
  shippersAndConsignees,
  brokerage_dispatch,
  page = 1,
  totalPages = 1,
  barcodeImage
) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const { address1, city, state, zipcode, phone_number, company_email, website, date_time_format_settings } =
    company || {};
  const { vehicle_type, trailer_length, trailer_type } = brokerage_dispatch || {};
  const {
    signature,
    signature_first_name,
    signature_last_name,
    trailer_id,
    truck_id,
    vehicle_id,
    driver_name,
    driver_phone_number,
  } = values || {};
  const dateFormat = DATE_FORMAT[date_time_format_settings?.date_format] || DATE_FORMAT[1];
  const timeFormat = TIME_FORMAT[date_time_format_settings?.time_format] || TIME_FORMAT[1];

  if (page === 1) {
    doc.addImage(barcodeImage, 'JPEG', pageWidth - 56, 18, 48, 18);
  } else {
    doc.addImage(barcodeImage, 'JPEG', pageWidth - 56, 4, 48, 20);
  }

  if ((page === 1 && shippersAndConsignees.length < 5) || (page === 2 && shippersAndConsignees.length > 4)) {
    // Summary
    doc.setFillColor(209, 41, 83);
    doc.rect(8, 200, pageWidth / 2 - 10, 6, 'F');
    doc.rect(pageWidth / 2 + 6, 200, pageWidth / 2 - 14, 6, 'F');

    doc.setTextColor(palette.white);
    doc.setFont('Inter', 'normal', 700);
    doc.text('Carrier Signature', 10, 204);
    doc.text('Date', 74, 204);

    doc.text('Driver Name', pageWidth / 2 + 10, 204);
    doc.text('Driver Telephone', 180, 204);

    if (!signature) {
      doc.roundedRect(8, 210, 60, 35, 2, 2);
    }
    if (signature) {
      doc.addImage(`${signature}`, 'JPEG', 14, 212, 50, 30);
    }
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`${moment().tz(currentTimezone).format(`${dateFormat} ${timeFormat} z`)}`, 74, 210);
    doc.text(`${driver_name || ''}`, pageWidth / 2 + 10, 210);
    doc.text(`${driver_phone_number ? formatPhoneNumber(driver_phone_number) : ''}`, 180, 210);
    doc.text(`${signature_first_name || ''} ${signature_last_name || ''}`, 10, 250);

    doc.setFillColor(209, 41, 83);
    doc.setTextColor(palette.white);
    doc.setFont('Inter', 'normal', 700);
    doc.rect(pageWidth / 2 + 6, 222, pageWidth / 2 - 14, 6, 'F');
    doc.text('Truck', pageWidth / 2 + 10, 226);
    doc.text('Trailer', 148, 226);
    doc.text('Equipment Type', 180, 226);

    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`${truck_id || vehicle_id || ''}`, pageWidth / 2 + 10, 232);
    doc.text(`${trailer_id || ''}`, 148, 232);
    doc.text(
      trailer_type
        ? `${trailer_length?.length || ''} ${trailer_length?.unit || ''} ${trailer_type?.title || ''}`
        : `${vehicle_type?.id === 1 ? 'Truck (Power Only)' : vehicle_type?.title || ''}`,
      180,
      232
    );
  }

  doc.setDrawColor(161, 169, 184);
  doc.line(0, pageHeight - 24, pageWidth, pageHeight - 24);
  doc.setFillColor(247, 249, 252);
  doc.rect(0, pageHeight - 24, pageWidth, 24, 'F');

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5, { align: 'center' });

  doc.setDrawColor(233, 237, 245);
  doc.line(51, pageHeight - 20, 51, pageHeight - 10);
  doc.line(109, pageHeight - 20, 109, pageHeight - 10);
  doc.line(174, pageHeight - 20, 174, pageHeight - 10);

  doc.setFontSize(10);
  doc.addImage(locationIcon, 'png', 8, pageHeight - 20, 3, 3);
  doc.addImage(globeIcon, 'png', 56, pageHeight - 20, 3, 3);
  doc.addImage(mailIcon, 'png', 114, pageHeight - 20, 4, 3);
  doc.addImage(phoneIcon, 'png', 179, pageHeight - 20, 3, 3);
  doc.text('Address', 13, pageHeight - 17.5);
  doc.text('Online', 61, pageHeight - 17.5);
  doc.text('Email', 119, pageHeight - 17.5);
  doc.text('Phone', 184, pageHeight - 17.5);

  doc.setFontSize(8);
  doc.setTextColor('#171C26');
  doc.text(`${address1}`, 8, pageHeight - 13);
  doc.text(`${city?.name}, ${state?.short_name || state?.name} ${zipcode}`, 8, pageHeight - 9, { maxWidth: 45 });

  doc.text(`${website || '-'}`, 56, pageHeight - 13);
  doc.text(`${company_email || '-'}`, 114, pageHeight - 13);
  doc.text(`${formatPhoneNumber(phone_number)}`, 179, pageHeight - 13);
};

export const generatePDF = async ({
  shipment,
  company,
  shippersAndConsignees,
  currency,
  barcodeImage,
  pickupStops,
  total_weight,
  total_qty,
  values,
}) => {
  if (!company) {
    return null;
  }

  const { shipment_stops, brokerage_dispatch, shipment_id, dispatched_by_user } = shipment || {};
  const { first_name, last_name } = dispatched_by_user || {};
  const { carrier, contact_user, vehicle_id } = brokerage_dispatch || {};
  const {
    logo,
    company_name,
    address1,
    address2,
    city,
    state,
    country,
    zipcode,
    terms_and_conditions,
    customer_timezone,
    date_time_format_settings,
  } = company || {};
  const { contact_name, contact_email, phone_number } = contact_user || {};
  const dateFormat = DATE_FORMAT[date_time_format_settings?.date_format] || DATE_FORMAT[1];
  const timeFormat = TIME_FORMAT[date_time_format_settings?.time_format] || TIME_FORMAT[1];

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.addImage(`${logo}?r=${Math.random()}`, 'JPEG', 8, 8, 60, 12, 'Logo', 'FAST');

  doc.setFontSize(8);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);

  doc.text(`${company_name}`, 8, 28);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 8, 32);
  doc.text(`${city?.name}, ${state?.short_name} ${zipcode}`, 8, 36);
  doc.text(`${country.sortname === 'US' ? 'USA' : country.sortname}`, 8, 40);

  doc.setFont('Inter', 'normal', 700);
  doc.text('Telephone Number:', 8, 48);
  doc.text('Dispatched By:', 8, 52);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${phone_number ? formatPhoneNumber(phone_number) : ''}`, 37, 48);
  doc.text(`${first_name} ${last_name}`, 30, 52);

  doc.setFontSize(11);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Load Tender and Rate Agreement', pageWidth - 100, 10);

  doc.setFontSize(8);
  doc.text('Confirmation Date:', pageWidth - 100, 17);
  doc.text('Reference ID:', pageWidth - 100, 21);
  doc.text('Shipment Date:', pageWidth - 100, 25);
  doc.text('Delivery Date:', pageWidth - 100, 29);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${moment().tz(customer_timezone)?.format(`${dateFormat} ${timeFormat} z`)}`, pageWidth - 72, 17);
  doc.text(`${shipment_id}`, pageWidth - 80, 21);
  doc.text(`${moment(shipment_stops?.[0]?.scheduled_date).format(dateFormat)}`, pageWidth - 76, 25);
  doc.text(
    `${moment(shipment_stops?.[shipment_stops.length - 1]?.scheduled_date).format(dateFormat)}`,
    pageWidth - 78,
    29
  );

  // doc.addImage(barcodeImage, 'JPEG', pageWidth - 56, 18, 48, 20);

  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 104, 38, 96, 5, 'F');
  doc.setTextColor('#FFFFFF');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Carrier', pageWidth - 100, 41.5);
  doc.text('Carrier Contact', pageWidth - 52, 41.5);

  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${carrier?.name || ''}`, pageWidth - 100, 48);
  doc.text(`${carrier?.address1 || ''}`, pageWidth - 100, 52);
  if (carrier) {
    doc.text(`${carrier?.city?.name}, ${carrier?.state?.short_name} ${carrier?.zipcode}`, pageWidth - 100, 56);
  }

  doc.text(`${contact_name || ''}`, pageWidth - 52, 48);
  doc.text(`${phone_number ? formatPhoneNumber(phone_number) : ''}`, pageWidth - 52, 52);
  doc.text(`${contact_email || ''}`, pageWidth - 52, 56);

  const detailsEnd = generateShipmentDetails(doc, pageWidth, shippersAndConsignees, vehicle_id, company);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 700);

  doc.text('Item', 8, detailsEnd + 10);
  doc.text('Description', 40, detailsEnd + 10);
  doc.text('Quantity', 132, detailsEnd + 10);
  doc.text('Rate', 162, detailsEnd + 10);
  doc.text(`Amount ${currencies[currency]}`, 188, detailsEnd + 10);
  doc.line(8, detailsEnd + 11.5, pageWidth - 8, detailsEnd + 11.5);

  generateShipmentCharges({
    doc,
    pageWidth,
    chargesStart: detailsEnd + 11,
    currency,
    pickupStops,
    total_weight,
    total_qty,
    shipment,
  });

  if (terms_and_conditions) {
    doc.addPage();
    doc.setFont('Inter', 'normal', 700);
    doc.text('Terms and Conditions', 8, 30);

    const wrappedHtmlString = `
    <div id="vazgulya" style="font-size: 10px; width: 200px">
        <style>
        #vazgulya h2, h4, li, ul, h1, h3, h5, h6 {
         font-size: 3.8px;
         line-height: 4px;
         font-family: 'Inter', sans-serif;
         letter-spacing: 0;
         font-weight: 500;
         word-spacing: -0.5px;
         margin-bottom: 3px;
        }      
        
        #vazgulya figure {
          display: unset;
          margin-block-start: unset;
          margin-block-end: unset;
          margin-inline-start: unset;
          margin-inline-end: unset;
          unicode-bidi: unset;
          border: none;
      }
      
      #vazgulya figure table {
          border-collapse: collapse;
          border: none;
      }
      
      #vazgulya figure table tbody {
         border: none;
      }
      
      #vazgulya figure table tr {
          border: none;
      }
      
      #vazgulya figure table td {
          font-size: 3.8px;
          line-height: 4px;
          font-family: 'Inter', sans-serif;
          letter-spacing: 0;
          font-weight: 500;
          padding: 2px;
          border: 0.1px solid black;
      }
        
        #vazgulya strong {
          font-size: 3.8px;
          line-height: 4px;
          font-family: 'Inter', sans-serif;
          letter-spacing: 0;
          font-weight: 700;
          vertical-align: super;
        }
        
         #vazgulya p {
         word-spacing: -0.5px;
         font-weight: 500;
         font-size: 3.8px;
         line-height: 4px;
         font-family: 'Inter', sans-serif;
         letter-spacing: 0;
         margin-bottom: 3px;
         padding: 0;
        }      
        
        </style>
       ${terms_and_conditions}
    </div>`;

    doc.html(wrappedHtmlString, {
      x: 0,
      y: shippersAndConsignees.length < 5 ? 274 : 519,
      margin: [8, 8, 25, 8],
      width: pageWidth,
      autoPaging: 'text',
    });
    doc.setDrawColor(161, 169, 184);
    doc.setLineWidth(0.3);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    createFooter(
      doc,
      company,
      values,
      shippersAndConsignees,
      brokerage_dispatch,
      currentPage,
      pagesCount,
      barcodeImage
    );
  }

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  return { blob, url };
};
