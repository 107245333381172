import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: [],
  onboardingSettings: null,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updatePlans: (state, action) => {
      state.plans = action.payload;
    },
    updateSettings: (state, action) => {
      state.onboardingSettings = action.payload;
    },
  },
});

export const { updatePlans, updateSettings } = onboardingSlice.actions;

export default onboardingSlice.reducer;
