import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 12px 10px;
  background-color: ${palette.white};
  border-bottom: 1px solid ${palette.gray50};
  transition: background-color 0.25s;
  cursor: pointer;

  &.selected {
    background-color: ${palette.indigo0};
  }

  :hover {
    background-color: ${palette.gray0};
  }
`;

export const SFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
`;
