import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Modal } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import Radio from 'common/Radio';
import Loader from 'common/Loader';
import Uploader from 'common/Uploader';
import ViewPdf from 'components/ViewPdf';
import InputLabel from 'common/InputLabel';
import Input, { Textarea } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { getStops } from 'Api/Shipment';
import { getShipmentsList } from 'Api/Planner';
import { createAccidentReport } from 'Api/AccidentsAndCrashes';
import { SUnderlineText } from '../../AccidentsAndCrashes.styles';
import { getInitialValues } from './Shipment.data';

const Shipment = ({ Cancel, nextStep, accident, loadingData, id, accidentId, selectedShipment }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [detailView, setDetailView] = useState(!!accidentId);
  const [onSubmitAction, setOnSubmitAction] = useState('next');
  const [pdfUrl, setPdfUrl] = useState('');
  const [shipments, setShipments] = useState([]);
  const [shipper, setShipper] = useState([]);
  const [consignee, setConsignee] = useState([]);

  const onSubmit = async (values) => {
    try {
      const {
        shipment_related,
        report_shipment,
        shipment_commodities_hauled,
        shipment_under_dispatch,
        shipment_shipper,
        shipment_consignee,
        shipment_hazmat,
        shipment_hazmat_class,
        shipment_hazmat_release,
        shipment_hazmat_release_details,
        shipment_hazmat_disposal_details,
        shipment_hazmat_disposal_attachment,
        shipment_cargo_damage,
        shipment_cargo_damage_details,
      } = values || {};
      setLoading(true);
      const formData = new FormData();
      formData.append('safety_accident_id', accident?.id || id);
      formData.append('shipment_related', shipment_related);
      report_shipment && formData.append('report_shipment_id', report_shipment.shipment_id);
      shipment_commodities_hauled && formData.append('shipment_commodities_hauled', shipment_commodities_hauled);
      formData.append('shipment_under_dispatch', shipment_under_dispatch);
      shipment_shipper && formData.append('shipment_shipper_id', shipment_shipper.stop_point?.id);
      shipment_consignee && formData.append('shipment_consignee_id', shipment_consignee.stop_point?.id);
      formData.append('shipment_hazmat', shipment_hazmat);
      shipment_hazmat_class && formData.append('shipment_hazmat_class', shipment_hazmat_class);
      formData.append('shipment_hazmat_release', shipment_hazmat_release);
      shipment_hazmat_release_details &&
        formData.append('shipment_hazmat_release_details', shipment_hazmat_release_details);
      shipment_hazmat_disposal_details &&
        formData.append('shipment_hazmat_disposal_details', shipment_hazmat_disposal_details);
      shipment_hazmat_disposal_attachment &&
        formData.append('shipment_hazmat_disposal_attachment', shipment_hazmat_disposal_attachment);
      formData.append('shipment_cargo_damage', shipment_cargo_damage);
      shipment_cargo_damage_details && formData.append('shipment_cargo_damage_details', shipment_cargo_damage_details);

      await createAccidentReport(accident?.id || id, formData);

      if (onSubmitAction === 'next') {
        nextStep();
        return;
      }

      if (onSubmitAction === 'close') {
        Cancel();
      }
      showToaster({ type: 'success', message: 'Success!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, setValues } = useForm({
    initialValues: getInitialValues({
      selectedShipment,
    }),
    onSubmit,
  });

  const getShipments = async () => {
    try {
      const { data } = await getShipmentsList();
      setShipments(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getShipmentStopsData = async (shipmentId) => {
    try {
      const { data } = await getStops({ shipment_id: shipmentId });
      const pickupStops = data.filter((stop) => Number(stop.stop_point_type) === 1);
      const deliveryStops = data.filter((stop) => Number(stop.stop_point_type) === 2);
      setShipper(pickupStops);
      handleChange('shipment_shipper', pickupStops?.[0] || null);
      setConsignee(deliveryStops);
      handleChange('shipment_consignee', deliveryStops?.[0] || null);
    } catch (e) {
      // Do nothing
    }
  };

  const onSave = () => {
    setOnSubmitAction('save');
    handleSubmit();
  };

  const onSaveAndClose = () => {
    setOnSubmitAction('close');
    handleSubmit();
  };

  const onNext = () => {
    setOnSubmitAction('next');
    handleSubmit();
  };

  useEffect(() => {
    if (accident) {
      const {
        shipment_related,
        report_shipment,
        shipment_commodities_hauled,
        shipment_under_dispatch,
        shipment_shipper,
        shipment_consignee,
        shipment_hazmat,
        shipment_hazmat_class,
        shipment_hazmat_release,
        shipment_hazmat_release_details,
        shipment_hazmat_disposal_details,
        shipment_hazmat_disposal_attachment,
        shipment_cargo_damage,
        shipment_cargo_damage_details,
      } = accident || {};

      setValues((prevState) => ({
        ...prevState,
        shipment_related: shipment_related || '',
        report_shipment: report_shipment || null,
        shipment_commodities_hauled: shipment_commodities_hauled || '',
        shipment_under_dispatch: shipment_under_dispatch || '',
        shipment_shipper: shipment_shipper || null,
        shipment_consignee: shipment_consignee || null,
        shipment_hazmat: shipment_hazmat || '',
        shipment_hazmat_class: shipment_hazmat_class || '',
        shipment_hazmat_release: shipment_hazmat_release || '',
        shipment_hazmat_release_details: shipment_hazmat_release_details || '',
        shipment_hazmat_disposal_details: shipment_hazmat_disposal_details || '',
        shipment_hazmat_disposal_attachment: shipment_hazmat_disposal_attachment || null,
        shipment_cargo_damage: shipment_cargo_damage || '',
        shipment_cargo_damage_details: shipment_cargo_damage_details || '',
      }));

      if (report_shipment?.shipment_id) {
        getShipmentStopsData(report_shipment.shipment_id);
      }
    } else {
      if (selectedShipment) {
        getShipmentStopsData(selectedShipment.shipment_id);
      }
    }
  }, [accident]);

  useEffect(() => {
    getShipments();
  }, []);

  return (
    <>
      <Modal.Body className='add-accident-modal-body'>
        {loadingData ? (
          <Loader loading={loadingData} />
        ) : detailView ? (
          <Grid container columnSpacing={2.5} rowSpacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Shipment</InputLabel>
              <Typography variant='s3'>{accident?.shipment_related ? 'Yes' : 'No'}</Typography>

              {!!values.shipment_related && (
                <>
                  <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Shipment</InputLabel>
                  <Typography variant='s3'>{accident?.report_shipment?.shipment_id}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Commodities Being Hauled</InputLabel>
              <Typography variant='s3'>{accident?.shipment_commodities_hauled || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Under Dispatch</InputLabel>
              <Typography variant='s3'>{accident?.shipment_under_dispatch ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Shipper</InputLabel>
              <Typography variant='s3'>{accident?.shipment_shipper?.location_name || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Consignee</InputLabel>
              <Typography variant='s3'>{accident?.shipment_consignee?.location_name || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Hazmat</InputLabel>
              <Typography variant='s3'>{accident?.shipment_hazmat ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Hazmat Class</InputLabel>
              <Typography variant='s3'>{accident?.shipment_hazmat_class || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>
                Was there any release of Hazmat?
              </InputLabel>
              <Typography variant='s3'>{accident?.shipment_hazmat_release ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className='d-flex gap-3'>
                <div>
                  <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>
                    Where was Hazmat disposed of?
                  </InputLabel>
                  <div className='d-flex align-items-center gap-2'>
                    <Typography variant='s3'>{accident?.shipment_hazmat_disposal_details || '-'}</Typography>
                    {!!accident?.shipment_hazmat_disposal_attachment && (
                      <SUnderlineText onClick={() => setPdfUrl(accident.shipment_hazmat_disposal_attachment)}>
                        View Attachment
                      </SUnderlineText>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Was there any cargo damage?</InputLabel>
              <Typography variant='s3'>{accident?.shipment_cargo_damage ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              {!!accident?.shipment_cargo_damage && (
                <div>
                  <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Explain cargo damage</InputLabel>
                  <Typography variant='s3'>{accident?.shipment_cargo_damage_details || '-'}</Typography>
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container columnSpacing={2.5} rowSpacing={2} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} sm={4}>
              <InputLabel>Shipment</InputLabel>
              <div className='d-flex gap-4 align-items-center' style={{ minHeight: 33 }}>
                <Radio
                  id='shipment-0'
                  label='No'
                  name='shipment_related'
                  value={0}
                  checked={values.shipment_related === 0}
                  onChange={() => handleChange('shipment_related', 0)}
                />
                <Radio
                  id='shipment-1'
                  label='Yes'
                  name='shipment_related'
                  value={1}
                  checked={values.shipment_related === 1}
                  onChange={() => handleChange('shipment_related', 1)}
                />
                {!!values.shipment_related && (
                  <Autocomplete
                    name='report_shipment'
                    labelKey='shipment_id'
                    placeholder='Select Shipment'
                    options={shipments}
                    value={values.report_shipment}
                    onChange={(e, value) => {
                      getShipmentStopsData(value.shipment_id);
                      handleChange('report_shipment', value);
                    }}
                    disableClearable={false}
                    isOptionEqualToValue={(option, value) => option.shipment_id === value.shipment_id}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4}>
              <Input
                label='Commodities Being Hauled'
                name='shipment_commodities_hauled'
                value={values.shipment_commodities_hauled}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel>Under Dispatch</InputLabel>
              <div className='d-flex gap-4 align-items-center' style={{ minHeight: 33 }}>
                <Radio
                  id='shipment-under-dispatch-0'
                  label='No'
                  name='shipment_under_dispatch'
                  value={0}
                  checked={values.shipment_under_dispatch === 0}
                  onChange={() => handleChange('shipment_under_dispatch', 0)}
                />
                <Radio
                  id='shipment-under-dispatch-1'
                  label='Yes'
                  name='shipment_under_dispatch'
                  value={1}
                  checked={values.shipment_under_dispatch === 1}
                  onChange={() => handleChange('shipment_under_dispatch', 1)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <Autocomplete
                getOptionLabel={(option) => {
                  return option?.stop_point ? `${option?.stop_point?.location_name}` : '';
                }}
                label='Shipper'
                name='shipment_shipper'
                options={shipper}
                value={values.shipment_shipper}
                onChange={(e, value) => handleChange('shipment_shipper', value)}
                disableClearable={false}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                getOptionLabel={(option) => {
                  return option?.stop_point ? `${option?.stop_point?.location_name}` : '';
                }}
                label='Consignee'
                name='shipment_consignee'
                options={consignee}
                value={values.shipment_consignee}
                onChange={(e, value) => handleChange('shipment_consignee', value)}
                disableClearable={false}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel>Hazmat</InputLabel>
              <div className='d-flex gap-4 align-items-center' style={{ minHeight: 33 }}>
                <Radio
                  id='shipment-hazmat-0'
                  label='No'
                  name='shipment_hazmat'
                  value={0}
                  checked={values.shipment_hazmat === 0}
                  onChange={() => handleChange('shipment_hazmat', 0)}
                />
                <Radio
                  id='shipment-hazmat-1'
                  label='Yes'
                  name='shipment_hazmat'
                  value={1}
                  checked={values.shipment_hazmat === 1}
                  onChange={() => handleChange('shipment_hazmat', 1)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                label='Hazmat Class'
                name='shipment_hazmat_class'
                value={values.shipment_hazmat_class}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel>Was there any release of Hazmat</InputLabel>
              <div className='d-flex gap-4 align-items-center' style={{ minHeight: 33 }}>
                <Radio
                  id='shipment-hazmat-release-0'
                  label='No'
                  name='shipment_hazmat_release'
                  value={0}
                  checked={values.shipment_hazmat_release === 0}
                  onChange={() => handleChange('shipment_hazmat_release', 0)}
                />
                <Radio
                  id='shipment-hazmat-release-1'
                  label='Yes'
                  name='shipment_hazmat_release'
                  value={1}
                  checked={values.shipment_hazmat_release === 1}
                  onChange={() => handleChange('shipment_hazmat_release', 1)}
                />
              </div>
              {!!values.shipment_hazmat_release && (
                <Textarea
                  label='Explain type and amounts of hazmat'
                  rows={3}
                  name='shipment_hazmat_release_details'
                  value={values.shipment_hazmat_release_details}
                  onChange={handleChange}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className='d-flex gap-3'>
                <Textarea
                  className='flex-grow-1'
                  label='Where was Hazmat disposed of?'
                  rows={3}
                  name='shipment_hazmat_disposal_details'
                  value={values.shipment_hazmat_disposal_details}
                  onChange={handleChange}
                />
                <Uploader
                  label='Attach Report'
                  type={3}
                  uploaderLabel='Attach'
                  document={values.shipment_hazmat_disposal_attachment}
                  onDrop={(files) => handleChange('shipment_hazmat_disposal_attachment', files[0])}
                  onRemove={() => handleChange('shipment_hazmat_disposal_attachment', null)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={4}>
              <InputLabel>Was there any cargo damage?</InputLabel>
              <div className='d-flex gap-4 align-items-center' style={{ minHeight: 33 }}>
                <Radio
                  id='shipment-hazmat-release-0'
                  label='No'
                  name='shipment_cargo_damage'
                  value={0}
                  checked={values.shipment_cargo_damage === 0}
                  onChange={() => handleChange('shipment_cargo_damage', 0)}
                />
                <Radio
                  id='shipment-hazmat-release-1'
                  label='Yes'
                  name='shipment_cargo_damage'
                  value={1}
                  checked={values.shipment_cargo_damage === 1}
                  onChange={() => handleChange('shipment_cargo_damage', 1)}
                />
              </div>
              {!!values.shipment_cargo_damage && (
                <Textarea
                  label='Explain cargo damage'
                  rows={3}
                  name='shipment_cargo_damage_details'
                  value={values.shipment_cargo_damage_details}
                  onChange={handleChange}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Close
            </Button>
          </div>
          <div className='pagination-btn'>
            {!!accidentId && (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='secondary'
                title='Edit'
                disabled={loading}
                leftIcon={<EditIcon style={{ marginRight: 10 }} />}
                onClick={() => setDetailView((prevState) => !prevState)}
              />
            )}
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save'
              disabled={loading}
              onClick={onSave}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save & Go To List'
              disabled={loading}
              onClick={onSaveAndClose}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Next'
              onClick={onNext}
              disabled={loading}
              leftIcon={
                loading ? (
                  <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
      </Modal.Footer>
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='' />}
    </>
  );
};

export default Shipment;
