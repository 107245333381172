import {
  cargoMeasuringUnits,
  liftGate,
  measuringUnits,
  trailerRequiredFieldsMapper,
  equipmentOwnerOptions,
} from '../Equipment.data';

export const tableDataConverter = (data, relatedData) => {
  return (data || []).map((item, index) => {
    const alerts = [];

    const equipment_type_id = relatedData?.trailers?.find((i) => i.title === item.type);
    const state = relatedData?.states?.find((i) => i.label === item.state);
    const length = equipment_type_id?.dimensions?.find((i) => i.length === item.length);
    const owner_operator = equipmentOwnerOptions.find((i) => i.label === item.equipment_owner);

    const convertedData = {
      ...item,
      id: index,
      equipment_id: item.equipment_id || '',
      equipment_type_id: equipment_type_id || null,
      cargo_capacity_measuring_unit: item.cargo_capacity_measuring_unit || cargoMeasuringUnits[0],
      gross_vehicle_measuring_unit: item.gross_vehicle_measuring_unit || measuringUnits[0],
      color: item.color || 'Blue',
      make: item.make || '',
      model_id: item.model_id || '',
      year: item.year || '',
      vin: item.vin || '',
      tire_size: item.tire_size || '',
      length: length || null,
      width: length?.width || '',
      height: length?.height || '',
      gross_vehicle_weight: item.gross_vehicle_weight || '',
      gvwr_class: item.gvwr_class || '',
      cargo_maximum_capacity_weight: item.cargo_maximum_capacity_weight || '',
      axles: item.axles || '',
      lift_gate:
        item.lift_gate?.toLowerCase() === 'true' || item.lift_gate?.toLowerCase() === 'yes' ? liftGate[0] : liftGate[1],
      license_plate_number: item.license_plate_number || '',
      license_plate_expire_date:
        item.license_plate_expire_date === 'No Expiration' ? null : item.license_plate_expire_date || '',
      license_plate_no_expiry: item.license_plate_expire_date === 'No Expiration',
      state: state || null,
      owner_operator: owner_operator || null,
    };

    Object.keys(trailerRequiredFieldsMapper).forEach((key) => {
      if (!convertedData[key]) {
        alerts.push(trailerRequiredFieldsMapper[key]);
      }
    });
    convertedData.alerts = alerts;

    return convertedData;
  });
};

export const trailersConverter = (trailers, dimensions) => {
  const dimensionsById = dimensions?.reduce((acc, cur) => {
    const item = {
      ...cur,
      length: cur?.length_info ? `${cur?.length_info?.length} ${cur?.length_info?.unit}` : null,
      length_id: cur?.length_info?.id || null,
    };

    if (!acc[cur.equipment_type_id]) {
      acc[cur.equipment_type_id] = [item];
    } else {
      acc[cur.equipment_type_id].push(item);
    }

    return acc;
  }, {});

  return trailers?.map((item) => ({ ...item, dimensions: dimensionsById[item.id] || [] })) || [];
};

export const connectServiceConverter = (equipments) => {
  return equipments
    .map((equipment) => {
      if (!equipment.serial_id) {
        return null;
      }

      return {
        serial_id: equipment.serial_id,
        equipment_id: equipment.id,
        hardware_model: 'AG46P',
        type: 'Trailer',
        trailer_id: equipment.equipment_id,
        vin: equipment.vin,
      };
    })
    .filter((i) => !!i);
};
