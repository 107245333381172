import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';
import { createFilterOptions } from '@mui/material';
import { Person, DirectionsCar, Group } from '@material-ui/icons';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import { Textarea } from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import Autocomplete from 'common/Autocomplete';
import { LayoutContext } from 'components/layout';
import { Typography } from 'components/Typography';
import ChatAppServices from 'services/chatApp';
import { useAuth } from 'context/auth.context';
import { getStaffUsers, sendAnnouncementMessage, sendSmSChatMessage } from 'Api/chat';
import { getLastIdFromString, phoneRegex, sortAndConcatenate } from 'utils/helpers';
import { palette } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as Announcement } from 'assets/icons/announcement.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import {
  SBigButton,
  SButtonWrapper,
  SOption,
  SInputWrapper,
  SWarning,
  SPhoneAutocomplete,
} from './AddNewChannel.styles';

const sort = { last_message_at: -1 };

const filterOptionsStaff = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => `${option.staff?.first_name} ${option.staff?.last_name}`,
});

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.data?.name || '',
});

const filterOptionsAnnouncement = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option?.name || '',
});

const AddNewChannel = ({ open, onClose, onSuccess }) => {
  const showToaster = useShowToaster();
  const { chatUser, dot_num } = useContext(LayoutContext);
  const { value } = useAuth();
  const navigate = useNavigate();
  const { client } = useChatContext();
  const [type, setType] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [smsStaffList, setSmsStaffList] = useState([]);
  const [smsDriverList, setSmsDriverList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [message, setMessage] = useState('');
  const [isLicense, setIsLicense] = useState(false);
  const [selectedChannelError, setSelectedChannelError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [announcementError, setAnnouncementError] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [country] = useState('');
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [showPhoneInput, setShowPhoneInput] = useState(false);

  const filtersGroups = {
    type: 'truckindigital',
    members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
  };

  const filtersDrivers = {
    type: 'driver_channel',
    // members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
  };

  const getStaffList = async () => {
    try {
      const { data } = await getStaffUsers();
      setStaffList(data.filter((i) => !!i.staff && i.staff.id !== value.user.id));
      const sendSms = data
        .filter((i) => !!i.staff && i.staff.id !== value.user.id)
        .map((i) => {
          return {
            id: i.staff.id,
            name: `${i.staff.first_name} ${i.staff.last_name}`,
          };
        });
      setSmsStaffList(sendSms);
    } catch (e) {
      // Do nothing
    }
  };

  const getChannelDrivers = async () => {
    const channels = await client.queryChannels(filtersDrivers, sort, {
      watch: true,
      state: true,
    });
    setChannelList(channels);
    const driverSmsList = channels.map((driver) => {
      return {
        id: getLastIdFromString(driver.data.id),
        name: driver.data.name,
      };
    });
    setSmsDriverList(driverSmsList);
  };

  function extractIds(array) {
    return array.map((item) => item.id);
  }

  const getChannelGroups = async () => {
    const channels = await client.queryChannels(filtersGroups, sort, {
      watch: true,
      state: true,
    });

    setChannelList(channels);
  };

  const onTypeClick = (type) => {
    setType(type);
  };

  const onChannelSelect = (val) => {
    setSelectedChannel(val);
    setSelectedChannelError(false);
    setIsLicense(false);
  };

  const onMessageChange = (val) => {
    setMessage(val);
    setMessageError(false);
    setAnnouncementError(false);
  };

  const createChannel = async () => {
    if (!selectedChannel && !inputValue && inputValue && !phoneRegex.test(inputValue)) {
      setSelectedChannelError(true);
    }

    if (!message) {
      setMessageError(true);
    }

    if ((!selectedChannel && !inputValue && inputValue && !phoneRegex.test(inputValue)) || !message) {
      return;
    }

    if (type === 'announcement') {
      const driver_ids = extractIds(selectedDrivers);
      const staff_ids = extractIds(selectedStaff);
      if (!driver_ids.length && !staff_ids.length) {
        setAnnouncementError(true);
        return;
      }
      ChatAppServices.checkSMSLicence()
        .then((res) => {
          setIsLicense(!res.data.is_two_way_sms_active);
          const params = {
            message_text: message,
            driver_ids,
            staff_ids,
          };
          sendAnnouncementMessage(params).then(
            (res) => {
              if (res.error) {
                showToaster({ type: 'error', message: res.message });
              } else {
                onClose();
                showToaster({ type: 'success', message: 'Message send successfully.' });
              }
            },
            (error) => {
              showToaster({ type: 'error', message: getErrorMessage(error) });
            }
          );
        })
        .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
    } else if (inputValue && phoneRegex.test(inputValue) && !selectedChannel) {
      ChatAppServices.checkSMSLicence().then((res) => {
        setIsLicense(!res.data.is_two_way_sms_active);
        if (res.data.is_two_way_sms_active) {
          const data = {
            to: inputValue,
            body: message,
          };
          sendSmSChatMessage(data).then(() => {
            onClose();
            if (onSuccess) {
              onSuccess();
            }
          });
        }
      });
    } else if (selectedChannel.data) {
      await selectedChannel.sendMessage({
        text: message,
      });
      await selectedChannel.updatePartial({ set: { open_chat: true } });
      onClose();
      if (onSuccess) {
        onSuccess();
      }
    } else {
      const { customer, id } = JSON.parse(localStorage.getItem('user')) || {};
      const { dot } = customer || {};
      const myId = chatUser.id;
      const CID = `${dot}_${selectedChannel.fname ? 'driver' : 'staff'}_${
        selectedChannel.fname ? `${selectedChannel?.id}` : `${selectedChannel?.staff.id}`
      }`;
      const chatId = sortAndConcatenate(myId, CID);
      const chatChannel = await client.channel('messaging', chatId, {
        name: !selectedChannel.fname
          ? `${selectedChannel.staff?.first_name} ${selectedChannel.staff?.last_name}`
          : `${selectedChannel.fname} ${selectedChannel.lname}`,
        image: selectedChannel.profile_logo || selectedChannel.image,
        members: [
          `${dot}_${selectedChannel.fname ? 'driver' : 'staff'}_${
            selectedChannel.fname ? `${selectedChannel?.id}` : `${selectedChannel?.staff.id}`
          }`,
          `${dot}_staff_${id}`,
        ],
        email: selectedChannel.email || selectedChannel?.staff?.email,
        phone_number: selectedChannel.phone_number || selectedChannel?.staff?.phone_number,
        organization_id: dot,
        open_chat: true,
        chat_closed_by: null,
        chat_closed_date_time: null,
        is_group: 0,
      });
      chatChannel.watch();
      setTimeout(async () => {
        await chatChannel.sendMessage({
          text: message,
        });
      }, 1000);
      onClose(false);
    }
  };

  useEffect(() => {
    // const parsedNumber = parsePhoneNumberFromString(inputValue);
    // setCountry(parsedNumber?.country?.toLowerCase() || '');

    const onlyNumbers = /^[0-9 +]+$/.test(inputValue);
    setShowPhoneInput(!!onlyNumbers);
  }, [inputValue]);

  useEffect(() => {
    switch (type) {
      case 'staff':
        getStaffList();
        break;
      case 'driver':
        getChannelDrivers();
        break;
      case 'group':
        getChannelGroups();
        break;
      case 'announcement':
        getStaffList();
        getChannelDrivers();
        break;
      default:
    }
  }, [type]);

  const handleSelectChange = (e, value, reason, details) => {
    setAnnouncementError(false);

    if (details?.option?.name === 'Select All') {
      setSelectedStaff(selectedStaff.length === smsStaffList.length ? [] : smsStaffList);
      return;
    }
    setSelectedStaff(value);
  };

  const handleDriverSelectChange = (e, value, reason, details) => {
    setAnnouncementError(false);
    if (details?.option?.name === 'Select All') {
      setSelectedDrivers(selectedDrivers.length === smsDriverList.length ? [] : smsDriverList);
      return;
    }
    setSelectedDrivers(value);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='New Message'
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      $minHeight='300px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', className: 'modal-cancel-btn' },
        ...(type
          ? [
              {
                key: 'submit',
                type: 'primary',
                title: 'Send Message',
                onClick: createChannel,
                disabled: isLicense,
              },
            ]
          : []),
      ]}
    >
      {!type && (
        <SButtonWrapper>
          <SBigButton onClick={() => onTypeClick('staff')}>
            <Person />
            User
          </SBigButton>
          <SBigButton onClick={() => onTypeClick('driver')}>
            <DirectionsCar />
            Driver
          </SBigButton>
          <SBigButton onClick={() => onTypeClick('group')}>
            <Group />
            Group
          </SBigButton>
          <SBigButton onClick={() => onTypeClick('announcement')}>
            <Announcement fill='currentColor' />
            Announcement
          </SBigButton>
        </SButtonWrapper>
      )}
      {isLicense && (
        <SWarning onClick={() => setIsLicense(false)}>
          {isLicense === 0 ? (
            <>
              You do not have a number assigned to this group. &nbsp; You do not have the "Two-Way SMS" add-on license.
              Please purchase the license first.
            </>
          ) : (
            <>
              You do not have the "Two-Way SMS" add-on license. Please purchase the license first.
              <span onClick={() => navigate('/billing?openAddon=1')}> Enable </span>
            </>
          )}
        </SWarning>
      )}
      {!!type && (
        <SInputWrapper>
          <div>
            {type === 'staff' && (
              <div className='position-relative'>
                {showPhoneInput ? (
                  <PhoneInput
                    label='To'
                    name='phone_number'
                    placeholder='+1 (555) 555-1234'
                    onChange={(val) => {
                      setInputValue(val);
                      setSelectedChannelError(false);
                    }}
                    value={inputValue}
                    autoFocus
                  />
                ) : (
                  <SPhoneAutocomplete
                    $country={!!country}
                    label='To'
                    placeholder='Type name or number'
                    clearOnBlur={false}
                    disableClearable={false}
                    filterOptions={filterOptionsStaff}
                    options={staffList}
                    value={selectedChannel}
                    error={selectedChannelError && 'Required'}
                    onChange={(e, val) => onChannelSelect(val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    inputValue={inputValue}
                    onInputChange={(e, val) => {
                      setInputValue(val);
                      setSelectedChannelError(false);
                    }}
                    renderOption={(props, option) => (
                      <SOption {...props}>
                        <img
                          src={option.staff?.profile_logo || userDefault}
                          alt={`${option.staff?.first_name} ${option.staff?.last_name}`}
                        />
                        <Typography>
                          {option.staff?.first_name} {option.staff?.last_name}
                        </Typography>
                      </SOption>
                    )}
                    getOptionLabel={(option) => `${option.staff?.first_name} ${option.staff?.last_name}`}
                    inputProps={{ autoFocus: true }}
                  />
                )}
              </div>
            )}
            {type === 'driver' && (
              <div className='position-relative'>
                {showPhoneInput ? (
                  <PhoneInput
                    label='To'
                    name='phone_number'
                    placeholder='+1 (555) 555-1234'
                    onChange={(val) => {
                      setInputValue(val);
                      setSelectedChannelError(false);
                    }}
                    value={inputValue}
                    autoFocus
                  />
                ) : (
                  <SPhoneAutocomplete
                    $country={!!country}
                    label='To'
                    placeholder='Type name or number'
                    clearOnBlur={false}
                    disableClearable={false}
                    filterOptions={filterOptions}
                    options={channelList}
                    value={selectedChannel}
                    onChange={(e, val) => onChannelSelect(val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    error={selectedChannelError && 'Required'}
                    inputValue={inputValue}
                    onInputChange={(e, val) => {
                      setInputValue(val);
                      setSelectedChannelError(false);
                    }}
                    renderOption={(props, option) => (
                      <SOption {...props}>
                        <img src={option.data?.image || userDefault} alt={`${option.data?.name}`} />
                        <Typography>{option?.data?.name}</Typography>
                      </SOption>
                    )}
                    getOptionLabel={(option) => `${option?.data?.name}`}
                  />
                )}
              </div>
            )}
            {type === 'group' && (
              <Autocomplete
                label='To'
                filterOptions={filterOptions}
                options={channelList}
                value={selectedChannel}
                onChange={(e, val) => onChannelSelect(val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={selectedChannelError && 'Required'}
                renderOption={(props, option) => (
                  <SOption {...props}>
                    <img
                      src={option.data?.image || userDefault}
                      alt={`${option.data?.first_name} ${option.staff?.last_name}`}
                    />
                    <Typography>{option?.data?.name}</Typography>
                  </SOption>
                )}
                getOptionLabel={(option) => `${option?.data?.name}`}
              />
            )}
            {type === 'announcement' && (
              <div>
                <div>
                  <Autocomplete
                    multiple
                    name='assign_to'
                    label='Staff'
                    filterOptions={filterOptionsAnnouncement}
                    options={[{ id: 'all', name: 'Select All' }, ...smsStaffList]}
                    value={selectedStaff}
                    onChange={handleSelectChange}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id ||
                      (option.name === 'Select All' && smsStaffList?.length === selectedStaff.length)
                    }
                    disableClearable={false}
                    renderTags={() => null}
                    error={announcementError && 'Please select Driver or Staff User in order to send the announcement.'}
                  />
                  <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', marginTop: '8px' }}>
                    {selectedStaff.map((item) => (
                      <Chip
                        key={item.id}
                        padding='4px 8px 4px 12px'
                        label={item.name}
                        labelColor={palette.indigo500}
                        bgColor={palette.indigo0}
                        deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                        onDelete={() => {
                          setSelectedStaff((prevState) => prevState.filter((i) => i.id !== item.id));
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className='mt-2'>
                  <Autocomplete
                    multiple
                    name='driver'
                    label='Driver'
                    filterOptions={filterOptionsAnnouncement}
                    options={[{ id: 'all', name: 'Select All' }, ...smsDriverList]}
                    value={selectedDrivers}
                    onChange={handleDriverSelectChange}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id ||
                      (option.name === 'Select All' && smsDriverList?.length === selectedDrivers.length)
                    }
                    disableClearable={false}
                    error={announcementError && 'Please select Driver or Staff User in order to send the announcement.'}
                    renderTags={() => null}
                  />
                  <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', marginTop: '8px' }}>
                    {selectedDrivers.map((item) => (
                      <Chip
                        key={item.id}
                        padding='4px 8px 4px 12px'
                        label={item.name}
                        labelColor={palette.indigo500}
                        bgColor={palette.indigo0}
                        deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                        onDelete={() => {
                          setSelectedDrivers((prevState) => prevState.filter((i) => i.id !== item.id));
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Textarea
            required
            rows={4}
            label='Write Message'
            name='internal_notes'
            placeholder='Type message...'
            value={message}
            onChange={(e) => onMessageChange(e.target.value)}
            error={messageError && 'Required'}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                createChannel();
              }
            }}
          />
          {type === 'announcement' && (
            <div style={{ display: 'flex', alignItems: 'start', marginBottom: 20, color: '#AA5B00FF' }}>
              <InfoIcon fill='#AA5B00FF' className='mt-1' />
              <Typography
                variant='s2'
                style={{
                  color: '#AA5B00FF',
                  marginLeft: 6,
                }}
              >
                The system will send a bulk message to staff or drivers selected. The message will not be stored in your
                chat messages, however the message will be sent in bulk to all recipients. Message and data rates may
                apply.
              </Typography>
            </div>
          )}
        </SInputWrapper>
      )}
    </Modal>
  );
};

export default AddNewChannel;
