import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import uuid from 'react-uuid';
import Col from 'react-bootstrap/Col';
import Popover from '@mui/material/Popover';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as IdleIcon } from 'assets/icons/idling.svg';
import { ReactComponent as FuelIcon } from 'assets/sidemanu/fuel.svg';
import { ReactComponent as ParkedIcon } from 'assets/icons/parked.svg';
import { ReactComponent as MovingIcon } from 'assets/icons/moving.svg';
import { ReactComponent as StopIcon } from 'assets/icons/StopPointIcon.svg';
import { ReactComponent as MapLayersIcon } from 'assets/icons/mapLayers.svg';
import { ReactComponent as ShipmentIcon } from 'assets/sidemanu/shipment.svg';
import { ReactComponent as NextLocation } from 'assets/icons/nextLocation.svg';
import { ReactComponent as PlannerIcon } from 'assets/sidemanu/plannerNewIcon.svg';

import {
  Equipments,
  EquipmentsMap,
  getEquipmentRequiredTrailers,
  getEquipmentRequiredVehicles,
  Groups,
  StopPoints,
  Trailer,
  TrailerMap,
} from 'Api/Equipment';
import { getMapTabs, getVehicleNextAvailableStops } from 'Api/Map';
import { getDrivers } from 'Api/EquipmentProfile';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { renderTooltip } from 'common/Tooltip';
import Shipments from 'pages/Dashboard/components/Shipments';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import { LOCATION_INITIAL_VALUE } from '../Equipment/EquipmentFilters/constant';
import TrailerContainerMap from './MapComponents/TrailerContainerMap';
import VehicleContainerMap from './MapComponents/VehicleContainerMap';
import HeaderStar from '../HeaderStar';
import styles from './MapComponent.module.css';
import VehicleContainer from './VehicleContainer';
import TrailerContainer from './TrailerContainer';
import MapHeader from './MapHeader/MapHeader';
import { MAP_FILTER_SEARCH_BY_TRAILER, MAP_FILTER_SEARCH_BY_VEHICLE } from './constat';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const MapContext = createContext(null);

const MapMain = () => {
  const showToaster = useShowToaster();
  const query = useQuery();
  const navigate = useNavigate();
  const [vehicleData, setVehicleData] = useState({ data: [] });
  const [vehicleTabData, setVehicleTabData] = useState([]);
  const [vehicle] = useState();
  const [trailer] = useState();
  const [tabName, setTabName] = useState('vehicle');
  const [tabIndexName, setTabIndexName] = useState('Vehicles');
  const [getCoords, setGetCoords] = useState();
  const [myCustomMap, setMyCustomMap] = useState(null);
  const [, setSelectedVehicle] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowPerPage] = useState(12);
  const [totalVehicle, setTotalVehicle] = useState();
  const [totalTrailer, setTotalTrailer] = useState();
  const [mapLayersValues, setMapLayersValues] = useState('BASIC');
  const [ismapstyleChange] = useState(false);
  const [visibleTrafic] = useState(false);
  const [visibleWeather] = useState(false);
  const [stopPointVisible, setStopPointVisible] = useState(false);
  const [POI, setPOI] = useState(false);
  const [loadingTab, setLoadingTab] = useState(false);
  const [zoomId, setZoomId] = useState(null);
  const [queryParameterData, setQueryParameterData] = useState();
  const [trailerData, setTrailerData] = useState({ data: [] });
  const [, setStatuses] = useState([]);
  const [, setGroups] = useState([]);
  const [, setCanTravelTo] = useState([]);
  const [, setDrivers] = useState([]);
  const [, setEquipments] = useState([]);
  const [, setFilterByGroupsData] = useState([]);
  const [filterByDriversData, setFilterByDriversData] = useState([]);
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [AddStopPointData, setAddStopPointData] = useState();
  const [routeCoords, setRouteCoords] = useState([]);
  const [firstCoords, setFirstCoords] = useState([]);
  const [radius, setRadius] = useState('');
  const [zoomData, setZoomData] = useState([]);
  const [zoomDataTrailer, setZoomDataTrailer] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [routeTypeMap] = useState('0');
  const [pusherLoading] = useState(false);
  const [getEquipmentFilterData, setGetEquipmentFilterData] = useState({});
  const [stopPointCoords, setStopPointCoords] = useState([]);
  const [routeMarkers, setRouteMarkers] = useState([]);
  const [routeStopContent, setRouteStopContent] = useState([]);
  const [stopPointAll, setStopPointsAll] = useState([]);
  const [status, setStatus] = useState('');
  const [vehicleLoading, setVehicleLoading] = useState(true);
  const [stopPointsData, setStopPointsData] = useState([]);
  const [stopPointAllData, setStopPointAllData] = useState();
  const [resetAllInputValue, setResetAllInputValue] = useState(false);
  const [emptyMiles, setEmptyMiles] = useState();
  const [onEquipmentSelect, setOnEquipmentSelect] = useState(false);
  const [markerAdded, setMarkerAdded] = useState(false);
  const [updatePoint, setUpdatePoint] = useState(null);
  const [locationFilterData, setLocationFilterData] = useState(null);
  const [optionFilterData, setOptionFilterData] = useState(null);
  const [lastCenter, setLastCenter] = useState(new TrimbleMaps.LngLat(-96.7026, 40.8136));
  const [lastZoom, setLastZoom] = useState(4.6);
  const [showStop, setShowStop] = useState(false);
  const [nextLocations, setNextLocations] = useState([]);

  const [, setFilterByEquipmentTypeData] = useState([]);
  const [, setLocalFilterCheckboxes] = useState({
    status: [],
    groups: [],
    equipment_types: [],
    drivers: [],
    can_travel_to: [],
  });
  const [page, setPage] = useState(2);
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'Equipment', value: 'equipment_id', key: 9999 },
    searchValue: '',
    selectedValues: [],
    location: LOCATION_INITIAL_VALUE,
    route: false,
    filterTableTop: [
      { key: 'vehicle', label: `Vehicles`, value: 1 },
      { key: 'trailer', label: `Trailers`, value: 2 },
    ],
  });
  const [firstCoordsFound, setFirstCoordsFound] = useState(false);
  const init = useCallback((query = '') => {
    if (query) {
      setQueryParameterData(query);
    } else {
      setQueryParameterData('');
    }
    setLoading(true);
    setVehicleLoading(true);
    if (query?.allFilterData) {
      try {
        Equipments({
          page: query.page ? query.page : vehicleData?.current_page ? vehicleData?.current_page : 1,
          itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
          allFilterData: query?.allFilterData,
          statusFilter: query.statusFilter,
        }).then((res) => {
          if (res && res.data && res.data.length) {
            setVehicleData(res);
            setVehicleLoading(false);

            setLoading(false);
            if (!totalVehicle) {
              setTotalVehicle(res?.total);
            }
            updateTotalCount(res?.total, 'Vehicles');
          } else {
            setVehicleData({ data: [] });
            setVehicleLoading(false);
            setLoading(false);
            updateTotalCount(0, 'Vehicles');
          }
        });
      } catch (error) {
        setVehicleData({ data: [] });
        setVehicleLoading(false);
        setLoading(false);
      }
    } else if (query?.QueryAllData) {
      try {
        Equipments({
          page: query.page ? query.page : vehicleData.current_page,
          itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
          QueryAllData: query?.QueryAllData,
          statusFilter: query.statusFilter,
        }).then((res) => {
          if (res && res.data && res.data.length) {
            setVehicleLoading(false);
            setVehicleData(res);

            setLoading(false);

            if (!totalVehicle) {
              setTotalVehicle(res?.total);
            }
            updateTotalCount(res?.total, 'Vehicles');
          } else {
            setVehicleData({ data: [] });
            setVehicleLoading(false);
            setLoading(false);
            updateTotalCount(0, 'Vehicles');
          }
        });
      } catch (error) {
        setVehicleData({ data: [] });
        setVehicleLoading(false);
        setLoading(false);
      }
    } else {
      try {
        Equipments({
          page: query.page ? query.page : vehicleData?.current_page ? vehicleData?.current_page : 1,
          itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
          query,
          statusFilter: query.statusFilter,
        }).then((res) => {
          if (res && res.data && res.data.length) {
            setVehicleData(res);
            setVehicleLoading(false);
            setLoading(false);
            if (!totalVehicle) {
              setTotalVehicle(res?.total);
            }
            updateTotalCount(res?.total, 'Vehicles');
          } else {
            setVehicleData({ data: [] });
            setVehicleLoading(false);
            setLoading(false);
            updateTotalCount(0, 'Vehicles');
          }
        });
      } catch (error) {
        setVehicleData({ data: [] });
        setVehicleLoading(false);
        setLoading(false);
      }
    }
  }, []);

  const updateTotalCount = (newCount, tabName) => {
    setVehicleTabData((prevState) => {
      return prevState.map((item) => {
        if (item.data.name === tabName) {
          return { ...item, count: newCount };
        }
        return item;
      });
    });
  };

  const ZoomToEquipment = (i, id) => {
    setZoomId(id);
    if (!AddStopPointData?.radius) {
      setFirstCoordsFound(true);
      const MapCoords = zoomData?.filter((v) => Number(v?.id) === Number(vehicleData?.data[i]?.id));
      const QueryAllData = { equipment_id_exact: vehicleData?.data[i]?.equipment_id };
      init({ QueryAllData });
      setAddStopPointData({
        equipment_id: QueryAllData?.equipment_id_exact,
        id: vehicleData.data[i]?.id,
      });
      const Array = [...MapCoords, ...routeCoords];
      setRouteCoords(Array);
      setStopPointCoords([...routeCoords]);
      if (MapCoords) {
        setTimeout(() => {
          ZoomToVehicleSelect(MapCoords);
        }, 3000);
      }
    } else {
      setPerticularVehicle(i);
    }
  };
  const setPerticularVehicle = (i) => {
    if (vehicleData?.data[i]?.equipment_id) {
      const QueryAllData = { equipment_id: vehicleData?.data[i]?.equipment_id };
      const value = getCoords?.filter((v) => v.equipment_id === vehicleData?.data[i].equipment_id);
      const coords = value.map((el) => {
        return {
          latitude: el.latitude,
          longitude: el?.longitude,
          current_location: el?.current_location,
        };
      });
      setRadius('');
      const Array = [...coords, ...routeCoords];
      setFirstCoords(coords);
      setRouteCoords(Array);
      if (tabName === 'vehicle') {
        init({ QueryAllData });
      } else {
        const allFilterData = {
          equipment_id: vehicleData?.data[i]?.equipment_id,
        };
        trailersTab({ allFilterData });
      }
      setAddStopPointData({
        equipment_id: QueryAllData?.equipment_id,
        id: vehicleData?.data[i]?.id,
      });
      // addStopPoint();
    }
  };

  const ZoomsearchedEqipment = () => {
    if (filter?.equipment_id?.equipment_id?.searchValue) {
      const value = getCoords?.filter((v) => v.equipment_id === filter?.equipment_id?.equipment_id?.searchValue);
      const coords = value.map((el) => {
        return {
          latitude: el.latitude,
          longitude: el?.longitude,
          current_location: el?.current_location,
        };
      });
      setAddStopPointData({
        equipment_id: value[0]?.equipment_id,
        id: value[0]?.id,
      });
      setFirstCoords(value);
      setTimeout(() => {
        ZoomToVehicleSelect(coords);
      }, 3000);
    }
  };
  useEffect(() => {
    if (filter?.onEquipmentSearch) ZoomsearchedEqipment();
  }, [filter?.onEquipmentSearch]);

  const ZoomToVehicleSelect = (coords) => {
    if (!myCustomMap) return;
    if (coords && coords.length && coords[0]?.latitude && coords[0]?.longitude) {
      myCustomMap.on('load', async () => {
        myCustomMap.zoomTo(14, {
          animate: !ismapstyleChange,
          duration: 5000,
        });
      });
      const sourceData = myCustomMap.getSource('randomPoints')?._data;
      if (sourceData && zoomId) {
        const foundItem = sourceData?.features?.find((item) => item.properties.id === zoomId);
        if (foundItem) {
          const updatedPoints = {
            features: [foundItem],
          };
          setUpdatePoint(updatedPoints);
        }
      }

      myCustomMap.setCenter({
        lat: coords[0]?.latitude,
        lon: coords[0]?.longitude,
      });
      myCustomMap.zoomTo(14, { animate: !ismapstyleChange, duration: 5000 });
      if (!filter?.equipment_id?.equipment_id?.searchValue) {
        setFirstCoords(coords);
      }
    }
  };

  const ZoomToTrailer = (i, id) => {
    setZoomId(id);
    if (!AddStopPointData?.radius) {
      setFirstCoordsFound(true);
      const MapCoords = zoomDataTrailer?.filter((v) => Number(v?.id) === Number(trailerData?.data[i]?.id));
      const allFilterData = { equipment_id_exact: trailerData?.data[i]?.equipment_id };
      trailersTab({ allFilterData });
      setAddStopPointData({
        equipment_id: allFilterData?.equipment_id_exact,
        id: trailerData.data[i]?.id,
      });
      const Array = [...MapCoords, ...routeCoords];
      setRouteCoords(Array);
      setStopPointCoords([...routeCoords]);
      if (filter.route) return;
      if (MapCoords) {
        setTimeout(() => {
          ZoomToTrailerSelect(MapCoords, id);
        }, 3000);
      }
    } else {
      setPerticularTrailer(i);
    }
  };

  const handleChange = (value) => {
    if (value) {
      setShowStop(false);
    }
    setStatus(value);
    value ? navigate(`/map?status=${value}`) : navigate(`/map`);
    if (tabName === 'vehicle') {
      init({ statusFilter: value, page: 1 });
    } else {
      trailersTab({ statusFilter: value, page: 1 });
    }
    setPage(2);
  };

  const ZoomToTrailerSelect = (coords, id) => {
    if (!myCustomMap) return;
    if (coords.length) {
      myCustomMap.on('load', async () => {
        myCustomMap.zoomTo(15, {
          animate: !ismapstyleChange,
          duration: 5000,
        });
      });

      const sourceData = myCustomMap.getSource('randomPoints')?._data;
      if (sourceData && id) {
        const foundItem = sourceData?.features?.find((item) => item.properties.id === id);
        if (foundItem) {
          const updatedPoints = {
            features: [foundItem],
          };
          setUpdatePoint(updatedPoints);
          myCustomMap.getSource('randomPoints').setData(updatedPoints);
        }
      }
      myCustomMap.setCenter({
        lat: coords[0]?.latitude,
        lon: coords[0]?.longitude,
      });
      myCustomMap.zoomTo(15, { animate: !ismapstyleChange, duration: 5000 });
      setFirstCoords(coords);
    }
  };

  const setPerticularTrailer = (i) => {
    if (trailerData?.data[i]?.equipment_id) {
      const QueryAllData = { equipment_id: trailerData?.data[i]?.equipment_id };
      const value = getCoords?.filter((v) => v.equipment_id === trailerData?.data[i].equipment_id);
      const coords = value.map((el) => {
        return {
          latitude: el.latitude,
          longitude: el?.longitude,
          current_location: el?.current_location,
        };
      });
      setRadius('');
      const Array = [...coords, ...routeCoords];
      setFirstCoords(coords);
      setRouteCoords(Array);

      if (tabName === 'trailer') {
        const allFilterData = {
          equipment_id: trailerData?.data[i]?.equipment_id,
        };
        trailersTab({ allFilterData });
      }
      setAddStopPointData({
        equipment_id: QueryAllData?.equipment_id,
        id: trailerData?.data[i].id,
      });
    }
  };

  const getStopPoints = () => {
    StopPoints({ map: 1 }).then((res) => {
      if (res && res.data && res.data.length) {
        setStopPointAllData(res.data);
        const filterByData = res.data.map((el) => {
          return {
            label: `${el?.location_name}`,
            id: el?.id,
            value: {
              latitude: `${el.geofencing_latitude}`,
              longitude: `${el.geofencing_longitude}`,
              current_location: `${el?.location_name ? el?.location_name : '-'}`,
              address1: `${el?.address1 ? el?.address1 : '-'}`,
              address2: `${el?.address2 ? el?.address2 : '-'}`,
              city: `${el?.city?.name ? el?.city?.name : '-'}`,
              state: `${el?.state?.name ? el?.state?.name : '-'}`,
              zipcode: `${el?.zipcode ? el?.zipcode : '-'}`,
              country: `${el?.country?.name ? el?.country?.name : '-'}`,
              stop_point_trailers_count: el.stop_point_trailers_count,
              stop_point_vehicles_count: el.stop_point_vehicles_count,
            },
            city: el.city.name,
            state: el.state.name,
          };
        });
        setStopPointsAll(filterByData);
      }
    });
  };

  useEffect(() => {
    const statusFromUrl = query.get('status');
    if (statusFromUrl) {
      setStatus(statusFromUrl);
      if (tabName === 'vehicle') {
        init({ statusFilter: statusFromUrl, page: 1 });
      } else {
        trailersTab({ statusFilter: statusFromUrl, page: 1 });
      }
      setPage(2);
    }
  }, []);

  useEffect(() => {
    getStopPoints();
  }, []);

  async function Tabs() {
    setPage(2);
    getMapTabs()
      .then((res) => {
        if (res) {
          const updatedData = res.data.map((el) => {
            return {
              ...el,
              label: el.data.name,
              key: uuid(),
              value: uuid(),
            };
          });
          setVehicleTabData(updatedData);
          setTabIndexName(updatedData?.[0]?.id);
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  }

  const OnTabChange = (value, key, item) => {
    let tab;
    setPOI(false);
    onReset();
    if (value.data.name === 'Trailers') {
      tab = 'trailer';
    } else {
      tab = 'vehicle';
    }

    filterTab(tab, item);
    setTabName(tab);
    setTabIndexName(value.id);
  };

  // const onSelectValue = (data) => {
  //   if (data?.equipment_id) {
  //     const QueryAllData = { equipment_id: data?.equipment_id };
  //     const value = zoomData?.filter((v) => v.equipment_id === data.equipment_id);
  //     const coords = value.map((el) => {
  //       return {
  //         latitude: el.latitude,
  //         longitude: el?.longitude,
  //         current_location: el?.current_location,
  //       };
  //     });
  //
  //     const valueTrailer = zoomDataTrailer?.filter((v) => v.equipment_id === data.equipment_id);
  //     const coordsTrailer = valueTrailer.map((el) => {
  //       return {
  //         latitude: el.latitude,
  //         longitude: el?.longitude,
  //         current_location: el?.current_location,
  //       };
  //     });
  //     if (tabName === 'vehicle') {
  //       setFirstCoords(coords);
  //       init({ QueryAllData });
  //       setTimeout(() => {
  //         ZoomToVehicleSelect(coords);
  //       }, 3000);
  //     } else {
  //       setFirstCoords(coordsTrailer);
  //       setTimeout(() => {
  //         ZoomToTrailerSelect(coordsTrailer);
  //       }, 3000);
  //       const allFilterData = { equipment_id: data?.equipment_id };
  //       trailersTab({ allFilterData });
  //     }
  //     setAddStopPointData(data);
  //   }
  //   if (data?.radius) {
  //     setAddStopPointData(data);
  //     setRadius(data?.radius);
  //   }
  // };

  const getVehicleNextLocations = async () => {
    try {
      const { data } = await getVehicleNextAvailableStops();
      setNextLocations(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onSearchLocations = useCallback(
    (locations) => {
      const activeData = {};
      const showData = [...locations];
      locations.forEach((el) => {
        if (el.value === 'locations') {
          activeData[el.value] = el.searchValue;
        } else {
          activeData[el.value] = el.searchValue;
        }
      });
      const clone = { ...getEquipmentFilterData };
      delete clone.city;
      delete clone.state;
      delete clone.postCode;
      delete clone.radius;

      const allFilterData = { ...clone, ...activeData };
      setGetEquipmentFilterData({ ...clone, ...activeData });

      if (locations.length > 0) {
        const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
        const index = filtered.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === 'locations');
        filtered.splice(index, 0, [...showData]);
        setShowHeaderFilters([...filtered]);
      } else {
        const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
        setShowHeaderFilters([...filtered]);
      }
      TrimbleMaps.Geocoder.geocode({
        address: {
          addr: '',
          city: allFilterData?.city,
          state: allFilterData?.state,
          zip: allFilterData?.postCode,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success(response) {
          setLocationFilterData({ allFilterData });
          if (tabName === 'vehicle') {
            init({ allFilterData });
          } else {
            trailersTab({ allFilterData });
          }
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                latitude: `${el.Coords?.Lat}`,
                longitude: `${el.Coords?.Lon}`,
                city: `${el?.Address?.City ? el?.Address?.City : '-'}`,
                state: `${el?.Address?.State ? el?.Address?.State : '-'}`,
                zip: `${el?.Address?.Zip ? el?.Address?.Zip : '-'}`,
              };
            });
            setRouteCoords(filterByData);
          }
        },
        failure() {
          setLocationFilterData({ allFilterData });
        },
      });
    },
    [init, getEquipmentFilterData, showHeaderFilters, filter]
  );

  // const onDeleteManyDataItem = (item, parent_value) => {
  //   const mapStates = {
  //     status: { state: statuses, setState: setStatuses },
  //     groups: { state: groups, setState: setGroups },
  //     drivers: { state: drivers, setState: setDrivers },
  //     can_travel_to: { state: canTravelto, setState: setCanTravelTo },
  //     equipment_types: { state: equipments, setState: setEquipments },
  //   };
  //
  //   const { id, value } = item || {};
  //   // const tabFiltersInBack = activeTabData?.data?.filters || activeTabData.key === 'all' && {}
  //   const parentIndex = showHeaderFilters.findIndex((el) => el.value === parent_value);
  //   const parent = showHeaderFilters[parentIndex];
  //
  //   if (parent) {
  //     const newFilterData = parent.searchValue.filter((el) => +el.id !== +id);
  //     const empty = newFilterData.length === 0;
  //     let allFilterNewData;
  //
  //     if (empty) {
  //       allFilterNewData = showHeaderFilters.filter((el) => el.value !== parent_value);
  //     } else {
  //       const showHeaderFiltersData = [...showHeaderFilters];
  //       showHeaderFiltersData[parentIndex] = {
  //         ...showHeaderFiltersData[parentIndex],
  //         searchValue: newFilterData,
  //       };
  //       allFilterNewData = showHeaderFiltersData;
  //     }
  //     let activeOption;
  //     const cloneEquipmentFilterData = { ...getEquipmentFilterData };
  //
  //     if (parent?.value !== 'customers') {
  //       activeOption = filter.selectedValues.filter((el) => +el.id !== +id);
  //
  //       const foundedOptionIndex = filter.selectedValues.findIndex((el) => +el.id === +id);
  //       const foundedOption = filter.selectedValues[foundedOptionIndex];
  //       const { value: type, id: foundedId } = foundedOption || {};
  //       const { state, setState } = mapStates[type];
  //       const newState = state.filter((elId) => +elId !== +foundedId);
  //       setState(newState);
  //       setLocalFilterCheckboxes((prevState) => {
  //         return {
  //           ...prevState,
  //           [type]: activeOption,
  //         };
  //       });
  //       setFilter((prevState) => ({
  //         ...prevState,
  //         selectedValues: activeOption,
  //       }));
  //     } else {
  //       activeOption = filter.customerSelectData.filter((el) => +el.id !== +id);
  //       setFilter((prevState) => ({
  //         ...prevState,
  //         customerSelectData: activeOption,
  //       }));
  //     }
  //
  //     const selectedValues = activeOption?.filter((el) => el.value === value);
  //     const arrTypeIds = selectedValues.map((el) => el.id);
  //     cloneEquipmentFilterData[parent.value] = arrTypeIds;
  //     const data = [];
  //     if (cloneEquipmentFilterData?.can_travel_to.includes(1)) {
  //       data.push('usa');
  //     }
  //     if (cloneEquipmentFilterData?.can_travel_to.includes(2)) {
  //       data.push('canada');
  //     }
  //     if (cloneEquipmentFilterData?.can_travel_to.includes(3)) {
  //       data.push('mexico');
  //     }
  //     const cloneEquipmentFilterDataCopy = {
  //       driver_id: cloneEquipmentFilterData?.drivers,
  //       equipment_type_id: cloneEquipmentFilterData?.equipment_types,
  //       group_id: cloneEquipmentFilterData?.groups,
  //       status_id: cloneEquipmentFilterData?.status,
  //       equipment_id: cloneEquipmentFilterData?.equipment_id,
  //       can_travel_to: data,
  //     };
  //
  //     const allFilterData = { ...cloneEquipmentFilterDataCopy };
  //     if (tabName === 'vehicle') {
  //       init({ allFilterData }).then(() => {
  //         setGetEquipmentFilterData(cloneEquipmentFilterData);
  //         setShowHeaderFilters(allFilterNewData);
  //       });
  //     }
  //     if (tabName === 'trailer') {
  //       trailersTab({ allFilterData }).then(() => {
  //         setGetEquipmentFilterData(cloneEquipmentFilterData);
  //         setShowHeaderFilters(allFilterNewData);
  //       });
  //     }
  //   }
  // };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const onReset = useCallback(() => {
    navigate(`/map`);
    setVehicleData({ data: [] });
    const resetDaya = {
      ...filter,
      allValue: '',
      searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
      searchValue: '',
      selectedValues: [],
      locations: LOCATION_INITIAL_VALUE,
    };

    setOnEquipmentSelect(false);
    if (tabName === 'vehicle') {
      init({ reset: resetDaya });
    }
    if (tabName === 'trailer') {
      trailersTab({ reset: resetDaya });
    }
    setLocationFilterData(null);
    setOptionFilterData(null);
    setStatus('');
    setUpdatePoint(null);
    setZoomId(null);
    setGroups([]);
    setMarkerAdded(false);
    setStatuses([]);
    setDrivers([]);
    setEquipments([]);
    setCanTravelTo([]);
    setShowHeaderFilters([]);
    setLocalFilterCheckboxes({
      status: [],
      groups: [],
      equipment_types: [],
      drivers: [],
      can_travel_to: [],
    });
    setGetEquipmentFilterData({});
    setRouteCoords([]);
    setSelectedVehicle([]);
    setStopPointsData([]);
    setFirstCoords([]);
    setStopPointCoords([]);
    setFirstCoordsFound(false);
    setResetAllInputValue(true);
    setAddStopPointData();
    setRouteInstance();
    setEmptyMiles(false);
    setLastCenter(new TrimbleMaps.LngLat(-96.7026, 40.8136));
    setLastZoom(4.6);
  }, [filter]);

  // const onDeleteFilterByItem = useCallback(
  //   (activeValue) => {
  //     const deletedData = showHeaderFilters.filter((el) => el.value !== activeValue);
  //     const filterType = showHeaderFilters?.find((el) => el.value === activeValue)?.value;
  //     const remainingObj = { ...getEquipmentFilterData };
  //     delete remainingObj[filterType];
  //
  //     setGetEquipmentFilterData({ ...remainingObj });
  //
  //     // if (activeValue === 'customers') {
  //     //     updateFilter({...filter, customerSelectData: []})
  //     // } else if (activeValue === 'all') {
  //     //     updateFilter({...filter, allValue: ''})
  //     // } else {
  //     updateFilter({ ...filter, selectedValues: [] });
  //     setLocalFilterCheckboxes((prevState) => {
  //       return {
  //         ...prevState,
  //         [activeValue]: [],
  //       };
  //     });
  //     switch (activeValue) {
  //       case 'status':
  //         setStatuses([]);
  //         break;
  //       case 'groups':
  //         setGroups([]);
  //         break;
  //       case 'drivers':
  //         setDrivers([]);
  //         break;
  //       case 'equipment_types':
  //         setEquipments([]);
  //         break;
  //       default:
  //     }
  //     // }
  //     const remainingObjCopy = {
  //       driver_id: remainingObj?.drivers,
  //       equipment_type_id: remainingObj?.equipment_types,
  //       group_id: remainingObj?.groups,
  //       status_id: remainingObj?.status,
  //       city: remainingObj?.city,
  //       radius: remainingObj?.radius,
  //       postCode: remainingObj?.postCode,
  //       state: remainingObj?.state,
  //     };
  //     const allFilterData = { ...remainingObjCopy };
  //     setShowHeaderFilters(deletedData);
  //     if (tabName === 'vehicle') {
  //       init({ allFilterData });
  //     }
  //     if (tabName === 'trailer') {
  //       trailersTab({ allFilterData });
  //     }
  //   },
  //   [init, updateFilter, filter, getEquipmentFilterData, showHeaderFilters]
  // );
  // const onApplyFilterCheckboxes = useCallback(
  //   ({ checkboxes, status, groups, equipment_types, drivers, can_travel_to }) => {
  //     const TYPES = ['status', 'groups', 'equipment_types', 'drivers', 'can_travel_to'];
  //
  //     const {
  //       status: statusArr,
  //       groups: groupsArr,
  //       equipment_types: equipment_types_Arr,
  //       drivers: driversArr,
  //       can_travel_to: CantravelArr,
  //     } = checkboxes;
  //     const statusParent = {
  //       id: statusArr?.[0]?.id,
  //       value: TYPES[0],
  //       searchValue: statusArr,
  //       title: 'Status',
  //     };
  //     const groupsParent = {
  //       id: groupsArr?.[0]?.id,
  //       value: TYPES[1],
  //       searchValue: groupsArr,
  //       title: 'Groups',
  //     };
  //     const driversParent = {
  //       id: driversArr?.[0]?.id,
  //       value: TYPES[3],
  //       searchValue: driversArr,
  //       title: 'Drivers',
  //     };
  //
  //     const equipmentParent = {
  //       id: equipment_types_Arr?.[0]?.id,
  //       value: TYPES[2],
  //       searchValue: equipment_types_Arr,
  //       title: 'Vehicle types',
  //     };
  //     const CantravelParent = {
  //       id: CantravelArr?.[0]?.id,
  //       value: TYPES[4],
  //       searchValue: CantravelArr,
  //       title: 'Can Travel To',
  //     };
  //
  //     const selectedValues = [...statusArr, ...groupsArr, ...equipment_types_Arr, ...driversArr, ...CantravelArr]; // filter.selectedValues[state]
  //     const data = [];
  //     if (can_travel_to?.includes(1)) {
  //       data.push('usa');
  //     }
  //     if (can_travel_to?.includes(2)) {
  //       data.push('canada');
  //     }
  //     if (can_travel_to?.includes(3)) {
  //       data.push('mexico');
  //     }
  //     const allFilterData = {
  //       ...getEquipmentFilterData,
  //       status_id: status,
  //       group_id: groups,
  //       equipment_type_id: equipment_types,
  //       driver_id: drivers,
  //       can_travel_to: data,
  //     }; // data in backEnd
  //     // show data in user
  //     const showData = [...showHeaderFilters];
  //     const filtered = showData.filter((option) => !TYPES.includes(option?.value));
  //
  //     const newShowData = [...filtered];
  //     statusParent?.id && newShowData.push(statusParent);
  //     groupsParent?.id && newShowData.push(groupsParent);
  //     driversParent?.id && newShowData.push(driversParent);
  //     CantravelParent?.id && newShowData.push(CantravelParent);
  //
  //     equipmentParent?.id && newShowData.push(equipmentParent);
  //     setShowHeaderFilters(newShowData);
  //     updateFilter({ ...filter, selectedValues });
  //     setGetEquipmentFilterData({
  //       ...getEquipmentFilterData,
  //       status,
  //       groups,
  //       equipment_types,
  //       drivers,
  //       can_travel_to,
  //     });
  //     setOptionFilterData({ allFilterData });
  //     if (tabName === 'vehicle') {
  //       init({ allFilterData });
  //     }
  //     if (tabName === 'trailer') {
  //       trailersTab({ allFilterData });
  //     }
  //   },
  //   [getEquipmentFilterData, showHeaderFilters, filter]
  // );

  async function trailersTab(query = '', preventLoading = false) {
    if (!preventLoading) {
      setLoadingTab(true);
    }
    if (query) {
      setQueryParameterData(query);
    } else {
      setQueryParameterData('');
    }

    if (query?.allFilterData) {
      Trailer({
        page: trailerData.current_page,
        itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
        allFilterData: query?.allFilterData,
        statusFilter: query.statusFilter,
      })
        // getAllTrailers()
        .then((res) => {
          if (res && res.data && res.data.length) {
            setTrailerData(res);
            if (!totalTrailer) {
              setTotalTrailer(res?.total);
            }
            updateTotalCount(res?.total, 'Trailers');
          } else {
            setTrailerData({ data: [] });
            updateTotalCount(0, 'Trailers');
          }

          setLoadingTab(false);
        });
    } else {
      Trailer({
        page: trailerData.current_page,
        itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
        query,
        statusFilter: query.statusFilter,
      })
        // getAllTrailers()
        .then((res) => {
          if (res && res.data && res.data.length) {
            setTrailerData(res);
            if (!totalTrailer) {
              setTotalTrailer(res?.total);
            }
            updateTotalCount(res?.total, 'Trailers');
          } else {
            setTrailerData({ data: [] });
            updateTotalCount(0, 'Trailers');
          }

          setLoadingTab(false);
        });
    }
  }

  useEffect(() => {
    Tabs();
    getVehicleNextLocations();
  }, []);

  useEffect(() => {
    const statusFromUrl = query.get('status');
    if (tabName === 'vehicle') {
      if (!statusFromUrl) {
        init();
      }
    }
    if (tabName === 'trailer') {
      trailersTab().catch();
    }
    return () => {};
  }, [tabName]);

  useEffect(() => {
    if (
      queryParameterData?.allFilterData?.radius ||
      queryParameterData?.allFilterData?.city ||
      queryParameterData?.allFilterData?.state
    ) {
      return;
    }
    if (vehicleData?.data) {
      const id = vehicle?.message?.data?.id;
      if (!vehicle?.message) return;

      const newData = vehicle?.message?.data;
      const copyData = { ...vehicleData };

      const index = copyData.data.findIndex((i) => i.id === id);
      if (index === -1) return;
      copyData.data[index] = newData;
      setVehicleData(copyData);
    }
  }, [vehicle]);
  useEffect(() => {
    if (
      queryParameterData?.allFilterData?.radius ||
      queryParameterData?.allFilterData?.city ||
      queryParameterData?.allFilterData?.state
    ) {
      return;
    }
    if (trailerData?.data) {
      const id = trailer?.message?.data?.id;
      if (!trailer?.message) return;

      const newData = trailer?.message?.data;
      const copyData = { ...trailerData };

      const index = copyData.data.findIndex((i) => i.id === id);
      if (index === -1) return;
      copyData.data[index] = newData;
    }
  }, [trailer]);

  const filterTab = (tab, item) => {
    let query = '';
    if (item?.data?.filters?.status) {
      if (!query) {
        query += `status_id=${item?.data?.filters?.status}`;
      } else {
        query += `&status_id=${item?.data?.filters?.status}`;
      }
    }
    if (item?.data?.filters?.equipment_type_id) {
      if (!query) {
        query += `equipment_type_id=${item?.data?.filters?.equipment_type_id}`;
      } else {
        query += `&equipment_type_id=${item?.data?.filters?.equipment_type_id}`;
      }
    }
    if (item?.data?.filters?.group_id) {
      if (!query) {
        query += `group_id=${item?.data?.filters?.group_id}`;
      } else {
        query += `&group_id=${item?.data?.filters?.group_id}`;
      }
    }

    if (tabName === 'vehicle') {
      init(query);
    }
    if (tabName === 'trailer') {
      trailersTab(query);
    }
    // }
  };
  async function moreData() {
    if (!zoomId) {
      const city = document.getElementById('EquipmentCity')?.value;
      const state = document.getElementById('EquipmentState')?.value;
      const radiusMap = document.getElementById('EquipmentMiles')?.value;
      const postCode = document.getElementById('EquipmentPostCode')?.value;
      EquipmentsMap({
        page,
        itemsPerPage: 12,
        radius: radiusMap,
        state,
        city,
        postCode,
        statusFilter: status,
        allFilterData: convertFilterData(filteredValues),
      }).then((res) => {
        const data = [...(vehicleData?.data || [])];
        const copyData = [...data, ...(res?.data || [])];
        const finalData = [];
        copyData.forEach((element) => {
          if (!finalData.includes(element)) {
            finalData.push(element);
          }
        });
        setVehicleData({ ...vehicleData, data: finalData });
        setPage(res?.last_page > page ? page + 1 : Number(res?.last_page) + 1);

        setVehicleLoading(false);
      });
    }
  }
  async function moreDataTrailer() {
    if (!zoomId) {
      const city = document.getElementById('EquipmentCity')?.value;
      const state = document.getElementById('EquipmentState')?.value;
      const radiusMap = document.getElementById('EquipmentMiles')?.value;
      const postCode = document.getElementById('EquipmentPostCode')?.value;
      TrailerMap({
        page,
        itemsPerPage: 12,
        radius: radiusMap,
        state,
        city,
        postCode,
        statusFilter: status,
        allFilterData: convertFilterData(filteredValues),
      }).then((res) => {
        const data = [...(trailerData?.data || [])];
        const copyData = [...data, ...(res?.data || [])];
        const finalData = [];
        copyData.forEach((element) => {
          if (!finalData.includes(element)) {
            finalData.push(element);
          }
        });
        setTrailerData({ ...trailerData, data: finalData });
        setPage(res?.last_page > page ? page + 1 : Number(res?.last_page) + 1);

        setLoading(false);
      });
    }
  }

  const getFilterByGroups = useCallback(() => {
    Groups()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: el.group_name,
              id: el.id,
              value: 'groups',
              type: 'checkbox',
              key: uuid(),
              typeData: 'groups',
            };
          });
          setFilterByGroupsData(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);
  const getFilterDrivers = useCallback(() => {
    getDrivers().then((res) => {
      if (res && res?.data) {
        const filterByData = res.data.map((el) => {
          return {
            title: `${el.fname} ${el.lname}`,
            id: el.id,
            value: 'drivers',
            type: 'checkbox',
            typeData: 'drivers',
            key: uuid(),

            // key: el.id
          };
        });
        setFilterByDriversData(filterByData);
      }
    });
  }, []);
  const getFilterByEquipmentType = useCallback(async () => {
    const filterByEquipmentData = [];
    await Promise.all([getEquipmentRequiredVehicles()])
      .then(([vehicle]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 0) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
      })
      .catch(() => {
        // Do nothing
      });

    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);
  const getFilterByTrailerType = useCallback(async () => {
    const filterByEquipmentData = [];

    await Promise.all([getEquipmentRequiredTrailers()])
      .then(([vehicle]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 0) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
      })
      .catch(() => {
        // Do nothing
      });

    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);
  useEffect(() => {
    getFilterByGroups();
    if (tabName === 'vehicle') {
      getFilterByEquipmentType().then();
    }
    if (tabName === 'trailer') {
      getFilterByTrailerType().then();
    }
    getFilterDrivers();
  }, [tabName]);

  const selectVehicle = (object) => {
    setSelectedVehicle(object?.equipment_id);

    setZoomId(object?.id);
    const coords = [
      {
        latitude: object.latitude,
        longitude: object.longitude,
        current_location: object.current_location,
      },
    ];
    setFirstCoords(coords);

    if (tabName === 'vehicle') {
      const QueryAllData = { equipment_id_exact: object?.equipment_id };

      init({ QueryAllData });
      setTimeout(() => {
        ZoomToVehicleSelect();
      }, 3000);
    } else {
      const allFilterData = { equipment_id_exact: object?.equipment_id };
      trailersTab({ allFilterData });
      ZoomToTrailerSelect();
    }
    setAddStopPointData({ ...object, equipment_id: object.equipment_id_exact });
  };
  const Maplayers = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const mapLayersValue = (value) => {
    setAnchorEl(null);
    setMapLayersValues(value);
  };

  const open = Boolean(anchorEl);
  const map = open ? 'simple-popover' : undefined;
  const [routeChange] = useState(false);
  const [routeInstance, setRouteInstance] = useState(null);

  const onSearchEquipment = useCallback(
    (equipment_id) => {
      const activeData = {};
      const showData = [...equipment_id];
      equipment_id.forEach((el) => {
        if (el.valuePrefix === 'equipment_id') {
          activeData[el.valuePrefix] = el.searchValue;
        } else {
          activeData[el.value] = el.searchValue;
        }
      });
      let clone = { ...getEquipmentFilterData };
      const data = [];
      if (clone.can_travel_to && clone.can_travel_to.length) {
        if (clone.can_travel_to.includes(1)) {
          data.push('usa');
        }
        if (clone.can_travel_to.includes(2)) {
          data.push('canada');
        }
        if (clone.can_travel_to.includes(3)) {
          data.push('mexico');
        }
        clone = { ...clone, can_travel_to: data };
      }
      const allFilterData = { ...clone, ...activeData };
      setGetEquipmentFilterData({ ...clone, ...activeData });

      if (equipment_id.length > 0) {
        const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
        const index = filtered.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === 'equipment_id');

        filtered.splice(index, 0, [...showData]);
        setShowHeaderFilters([...filtered]);
      } else {
        const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
        setShowHeaderFilters([...filtered]);
      }
      if (tabName === 'vehicle') {
        setTimeout(() => {
          init({ allFilterData });
        }, 2000);
      }
      if (tabName === 'trailer') {
        trailersTab({ allFilterData });
      }
    },
    [init, getEquipmentFilterData, showHeaderFilters, filter]
  );

  const onSearchDriver = useCallback(
    (driver) => {
      const activeData = {};
      const showData = [...driver];
      driver.forEach((el) => {
        if (el.valuePrefix === 'driver') {
          activeData[el.valuePrefix] = el.value;
        } else {
          activeData[el.value] = el.value;
        }
      });
      const clone = { ...getEquipmentFilterData };

      const allFilterData = { ...clone, driver_id: driver[0]?.value };
      setGetEquipmentFilterData({ ...clone });

      if (driver.length > 0) {
        const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
        const index = filtered.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === 'driver');

        filtered.splice(index, 0, [...showData]);
        setShowHeaderFilters([...filtered]);
      } else {
        const filtered = showHeaderFilters.filter((el) => el?.[0]?.valuePrefix !== filter.searchBy.value);
        setShowHeaderFilters([...filtered]);
      }
      setOptionFilterData({ allFilterData });
      init({ allFilterData });
    },
    [init, getEquipmentFilterData, showHeaderFilters, filter]
  );

  // const onDeleteLocationsItems = useCallback(
  //   (item) => {
  //     let allFilterData = { ...getEquipmentFilterData };
  //
  //     if (item.title === 'Location') {
  //       delete allFilterData?.[item.value];
  //     }
  //     if (item.title === 'Equipment') {
  //       delete allFilterData?.[item.valuePrefix];
  //     }
  //
  //     const newLocation = {};
  //
  //     const newShowFilterData = [];
  //
  //     showHeaderFilters.forEach((el) => {
  //       if (Array.isArray(el)) {
  //         const locData = el.filter((loc) => loc.id !== item.id);
  //         const newArray = locData.map((data) => {
  //           return {
  //             ...data,
  //             value: data?.value?.filter((id) => id !== data.id),
  //           };
  //         });
  //         newArray.length > 0 && newShowFilterData.push(newArray);
  //       } else {
  //         newShowFilterData.push(el);
  //       }
  //     });
  //     const data = [];
  //     if (allFilterData.can_travel_to && allFilterData.can_travel_to.length) {
  //       if (allFilterData.can_travel_to.includes(1)) {
  //         data.push('usa');
  //       }
  //       if (allFilterData.can_travel_to.includes(2)) {
  //         data.push('canada');
  //       }
  //       if (allFilterData.can_travel_to.includes(3)) {
  //         data.push('mexico');
  //       }
  //       allFilterData = { ...allFilterData, can_travel_to: data };
  //     }
  //
  //     if (tabName === 'vehicle') {
  //       init({ allFilterData });
  //     }
  //     if (tabName === 'trailer') {
  //       trailersTab({ allFilterData });
  //     }
  //
  //     updateFilter({ ...filter, location: newLocation });
  //     setShowHeaderFilters(newShowFilterData);
  //     setGetEquipmentFilterData(allFilterData);
  //   },
  //   [getEquipmentFilterData, filter, showHeaderFilters, init, updateFilter]
  // );

  const [resetFilerOnTabChange, setResetFilerOnTabChange] = useState(false);

  const handleStopClick = () => {
    if (status !== '') {
      handleChange('');
    }
    setShowStop(!showStop);
  };

  useEffect(() => {
    if (!filter?.searchBy?.id) return;
    if (resetFilerOnTabChange) {
      const resetDaya = {
        ...filter,
        allValue: '',
        searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
        searchValue: '',
        selectedValues: [],
        locations: LOCATION_INITIAL_VALUE,
      };
      if (tabName === 'vehicle') {
        init({ reset: resetDaya });
      }
      if (tabName === 'trailer') {
        trailersTab({ reset: resetDaya });
      }
      setGroups([]);
      setStatuses([]);
      setDrivers([]);
      setEquipments([]);
      setCanTravelTo([]);
      setShowHeaderFilters([]);
      setLocalFilterCheckboxes({
        status: [],
        groups: [],
        equipment_types: [],
        drivers: [],
        can_travel_to: [],
      });
      setGetEquipmentFilterData({});
      setRouteCoords([]);
      setSelectedVehicle([]);
      setStopPointsData([]);
      setFirstCoords([]);
      setStopPointCoords([]);
      setFirstCoordsFound(false);
      setAddStopPointData();
      setRouteInstance();
      setEmptyMiles(false);
    }
    setResetFilerOnTabChange(true);
  }, [filter?.searchBy?.id]);

  /** ADVANCED FILTER AND FILTER WORKED HERE* */
  const [searchBy, setSearchBy] = useState(
    tabName === 'vehicle' ? MAP_FILTER_SEARCH_BY_VEHICLE[0] : MAP_FILTER_SEARCH_BY_TRAILER[0]
  );
  const [filteredValues, setFilteredValues] = useState({});

  const onSearch = (data) => {
    const filterObject = data || filteredValues;
    if (tabName === 'vehicle') {
      GetVehicles({ data: filterObject }).catch();
    } else {
      GetTrailers({ data: filterObject }).catch();
    }

    // data param have when delete items
    if (!!filterObject?.city || !!filterObject?.state || !!filterObject?.postCode || data) {
      const address = {
        addr: '',
        region: TrimbleMaps.Common.Region.NA,
        ...(!!filterObject?.city && { city: filterObject?.city }),
        ...(!!filterObject?.state && { state: filterObject?.state }),
        ...(!!filterObject?.postCode && { zip: filterObject?.postCode }),
      };

      TrimbleMaps.Geocoder.geocode({
        address,
        listSize: 1,
        success(response) {
          setLocationFilterData({ allFilterData: convertFilterData(filterObject) });
          // if (tabName === 'vehicle') {
          //   init({ allFilterData:filterObject });
          // } else {
          //   trailersTab({ allFilterData:filterObject });
          // }
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                latitude: `${el.Coords?.Lat}`,
                longitude: `${el.Coords?.Lon}`,
                city: `${el?.Address?.City ? el?.Address?.City : '-'}`,
                state: `${el?.Address?.State ? el?.Address?.State : '-'}`,
                zip: `${el?.Address?.Zip ? el?.Address?.Zip : '-'}`,
              };
            });
            if (!!filterByData?.latitude && !!filterByData?.longitude) {
              setRouteCoords(filterByData);
            }
          }
        },
        failure() {
          setLocationFilterData({ allFilterData: convertFilterData(filterObject) });
        },
      });
    }
  };

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        requestData[key] = value.map((el) => el.id).toString();
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const GetTrailers = async ({ data = {} }) => {
    try {
      setLoadingTab(true);
      const requestData = convertFilterData(data);
      const options = {
        page: 1,
        itemsPerPage: 12,
        allFilterData: requestData,
        statusFilter: query.statusFilter,
      };
      const res = await Trailer(options);
      if (res && res.data && res.data.length) {
        setTrailerData(res);
        if (!totalTrailer) {
          setTotalTrailer(res?.total);
        }
        updateTotalCount(res?.total, 'Trailers');
      } else {
        setTrailerData({ data: [] });
        updateTotalCount(0, 'Trailers');
      }
      setPage(2);
      return res;
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingTab(false);
    }
  };

  const GetVehicles = async ({ data = {} }) => {
    try {
      setVehicleLoading(true);
      const requestData = convertFilterData(data);
      const options = {
        page: 1,
        itemsPerPage: 12,
        allFilterData: requestData,
        statusFilter: query.statusFilter,
      };
      const res = await Equipments(options);
      if (res && res.data && res.data.length) {
        setVehicleData(res);
        setLoading(false);
        setVehicleLoading(false);
        if (!totalVehicle) {
          setTotalVehicle(res?.total);
        }
        updateTotalCount(res?.total, 'Vehicles');
      } else {
        setVehicleData({ data: [] });
        setLoading(false);
        setVehicleLoading(false);
        updateTotalCount(0, 'Vehicles');
      }
      setPage(2);
      return res;
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setVehicleLoading(false);
    }
  };

  /** ADVANCED FILTER AND FILTER WORKED HERE* */

  return (
    <MapContext.Provider
      value={{
        filter,
        updateFilter,
        onSearchEquipment,
        filterByDriversData,
        onSearchLocations,
        resetAllInputValue,
        setResetAllInputValue,
        onSearchDriver,
        setFilter,
        firstCoords,
      }}
    >
      <div className='equipment-table-header mt-3 d-flex'>
        <div className='d-flex mb-3'>
          <HeaderStar title='Map' />
        </div>
      </div>
      <div style={{ height: '90vh' }}>
        <div className='d-flex flex-direction-row align-items-baseline'>
          <MapHeader
            onSearch={onSearch}
            searchBy={searchBy}
            setSearchBy={setSearchBy}
            filter={filteredValues}
            setFilter={setFilteredValues}
            driverSelectData={filterByDriversData}
            isVehicle={tabName === 'vehicle'}
          />
        </div>
        {/* <div className='mt-4 mb-4'> */}
        {/*  {tabName === 'vehicle' ? ( */}
        {/*    <Filter */}
        {/*      groups={groups} */}
        {/*      onReset={onReset} */}
        {/*      statuses={statuses} */}
        {/*      setGroups={setGroups} */}
        {/*      drivers={drivers} */}
        {/*      setDrivers={setDrivers} */}
        {/*      equipments={equipments} */}
        {/*      data={showHeaderFilters} */}
        {/*      setStatuses={setStatuses} */}
        {/*      setEquipments={setEquipments} */}
        {/*      disabledFilters={disabledFilters} */}
        {/*      onDeleteItem={onDeleteFilterByItem} */}
        {/*      filterByGroupsData={filterByGroupsData} */}
        {/*      filterByDriversData={filterByDriversData} */}
        {/*      onDeleteManyDataItem={onDeleteManyDataItem} */}
        {/*      localFilterCheckboxes={localFilterCheckboxes} */}
        {/*      onDeleteLocationsItems={onDeleteLocationsItems} */}
        {/*      onApplyFilterCheckboxes={onApplyFilterCheckboxes} */}
        {/*      setLocalFilterCheckboxes={setLocalFilterCheckboxes} */}
        {/*      filterByEquipmentTypeData={filterByEquipmentTypeData} */}
        {/*      onSelectValue={onSelectValue} */}
        {/*      radius={radius} */}
        {/*      canTravelto={canTravelto} */}
        {/*      setCanTravelTo={setCanTravelTo} */}
        {/*      MapComponent={MapComponent} */}
        {/*    /> */}
        {/*  ) : ( */}
        {/*    '' */}
        {/*  )} */}
        {/*  {tabName === 'trailer' ? ( */}
        {/*    <FilterForTrailer */}
        {/*      groups={groups} */}
        {/*      onReset={onReset} */}
        {/*      statuses={statuses} */}
        {/*      setGroups={setGroups} */}
        {/*      drivers={drivers} */}
        {/*      setDrivers={setDrivers} */}
        {/*      equipments={equipments} */}
        {/*      data={showHeaderFilters} */}
        {/*      setStatuses={setStatuses} */}
        {/*      setEquipments={setEquipments} */}
        {/*      disabledFilters={disabledFilters} */}
        {/*      onDeleteItem={onDeleteFilterByItem} */}
        {/*      filterByGroupsData={filterByGroupsData} */}
        {/*      filterByDriversData={filterByDriversData} */}
        {/*      onDeleteManyDataItem={onDeleteManyDataItem} */}
        {/*      localFilterCheckboxes={localFilterCheckboxes} */}
        {/*      onDeleteLocationsItems={onDeleteLocationsItems} */}
        {/*      onApplyFilterCheckboxes={onApplyFilterCheckboxes} */}
        {/*      setLocalFilterCheckboxes={setLocalFilterCheckboxes} */}
        {/*      filterByEquipmentTypeData={filterByEquipmentTypeData} */}
        {/*      onSelectValue={onSelectValue} */}
        {/*      radius={radius} */}
        {/*      addStopPoint={addStopPoint} */}
        {/*      MapComponent */}
        {/*    /> */}
        {/*  ) : ( */}
        {/*    '' */}
        {/*  )} */}
        {/* </div> */}

        <div className={styles.MainContainer}>
          <Row style={{ minHeight: '100%' }}>
            <Col xs={3}>
              {/* <SwitchRadio */}
              {/*  name={"tableTopTabMenu"} */}
              {/*  items={vehicleTabData} */}
              {/*  title="vehicle" */}
              {/*  value={tab} */}
              {/*  type={'tab'} */}
              {/*  OnTabChange={OnTabChange} */}
              {/*  onChange={(n, v) => setTab(+v)} */}
              {/* /> */}
              <div onClick={() => onReset()}>
                <SwitchRadio
                  type='tab'
                  items={vehicleTabData}
                  onChange={OnTabChange}
                  name='tableTopTabMenu'
                  value={tabIndexName}
                />
              </div>
              <div
                className={styles.mainContentContainer}
                id='mainContentContainer'
                // onScroll={onScroll}
              >
                {tabName === 'vehicle' ? (
                  <VehicleContainer
                    filterStatus={status}
                    vehicleData={vehicleData}
                    setVehicleData={setVehicleData}
                    vehicleLoading={vehicleLoading}
                    ZoomToEquipment={ZoomToEquipment}
                    // onScroll={onScroll}
                    AddStopPointData={AddStopPointData}
                    stopPointsData={stopPointsData}
                    routeCoords={routeCoords}
                    page={page}
                    moreData={moreData}
                    stopPointCoords={stopPointCoords}
                    firstCoords={firstCoords}
                  />
                ) : (
                  <TrailerContainer
                    vehicleData={trailerData}
                    setVehicleData={setTrailerData}
                    loading={loading}
                    loadingTab={loadingTab}
                    ZoomToEquipment={ZoomToTrailer}
                    // onScroll={onScrollTrailer}
                    AddStopPointData={AddStopPointData}
                    stopPointsData={stopPointsData}
                    routeCoords={routeCoords}
                    moreDataTrailer={moreDataTrailer}
                    page={page}
                    stopPointCoords={stopPointCoords}
                    firstCoords={firstCoords}
                  />
                )}
              </div>
            </Col>

            <Col xs={9} style={{ position: 'relative' }}>
              {status === 'on_shipment' && (
                <div
                  className='d-flex justify-content-center'
                  style={{
                    background: 'none',
                    zIndex: 9,
                    position: 'absolute',
                    cursor: 'pointer',
                    width: 'calc(100% - 100px)',
                  }}
                >
                  <Col xs={10} style={{ maxWidth: '50%' }}>
                    <Shipments isMapsPage />
                  </Col>
                </div>
              )}

              <div className={styles['map-zooming-buttons']}>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Map Type')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className={styles['map-zooming-feature-button-plus']}
                      aria-describedby={map}
                      onClick={Maplayers}
                    >
                      <MapLayersIcon />
                    </div>
                  </div>
                </OverlayTrigger>
                {tabName === 'vehicle' && (
                  <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Idling')}>
                    <div className='map-stops-buttons mb-2'>
                      <div
                        className={styles['map-zooming-feature-button-plus']}
                        aria-describedby={map}
                        onClick={() => handleChange(status !== 'idle' ? 'idle' : '')}
                      >
                        {status === 'idle' ? (
                          <IdleIcon width='15' height='15' fill='#4F5AED' />
                        ) : (
                          <IdleIcon width='15' height='15' fill='#687182' />
                        )}
                      </div>
                    </div>
                  </OverlayTrigger>
                )}
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Moving')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className={styles['map-zooming-feature-button-plus']}
                      aria-describedby={map}
                      onClick={() => handleChange(status !== 'moving' ? 'moving' : '')}
                    >
                      {status === 'moving' ? (
                        <MovingIcon width='20' height='17' fill='#4F5AED' />
                      ) : (
                        <MovingIcon width='20' height='17' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Parked')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className={styles['map-zooming-feature-button-plus']}
                      aria-describedby={map}
                      onClick={() => handleChange(status !== 'parked' ? 'parked' : '')}
                    >
                      {status === 'parked' ? (
                        <ParkedIcon width='16' height='16' fill='#4F5AED' />
                      ) : (
                        <ParkedIcon width='16' height='16' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='left'
                  delay={{ show: 100, hide: 200 }}
                  overlay={renderTooltip('On Shipment')}
                >
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className={styles['map-zooming-feature-button-plus']}
                      aria-describedby={map}
                      onClick={() => handleChange(status !== 'on_shipment' ? 'on_shipment' : '')}
                    >
                      {status === 'on_shipment' ? (
                        <ShipmentIcon width='15' height='15' fill='#4F5AED' />
                      ) : (
                        <ShipmentIcon width='15' height='15' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='left'
                  delay={{ show: 100, hide: 200 }}
                  overlay={renderTooltip('Stop Points')}
                >
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className={styles['map-zooming-feature-button-plus']}
                      aria-describedby={map}
                      onClick={() => setStopPointVisible(!stopPointVisible)}
                    >
                      {stopPointVisible ? (
                        <StopIcon width='16' height='16' fill='#4F5AED' />
                      ) : (
                        <StopIcon width='16' height='16' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Fuel Stops')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className={styles['map-zooming-feature-button-plus']}
                      aria-describedby={map}
                      onClick={() => setPOI(!POI)}
                    >
                      {POI ? (
                        <FuelIcon width='16' height='13' fill='#4F5AED' />
                      ) : (
                        <FuelIcon width='16' height='13' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                {tabName === 'vehicle' && (
                  <OverlayTrigger
                    placement='left'
                    delay={{ show: 100, hide: 200 }}
                    overlay={renderTooltip('Create Route')}
                  >
                    <div className='map-stops-buttons mb-2'>
                      <div className='map-zooming-feature-button-plus' aria-describedby={map} onClick={handleStopClick}>
                        <PlannerIcon fill='#687182' />
                      </div>
                    </div>
                  </OverlayTrigger>
                )}
                {tabName === 'vehicle' && (
                  <OverlayTrigger
                    placement='left'
                    delay={{ show: 100, hide: 200 }}
                    overlay={renderTooltip('Next Available')}
                  >
                    <div className='map-stops-buttons mb-2'>
                      <div
                        className={styles['map-zooming-feature-button-plus']}
                        aria-describedby={map}
                        onClick={() => handleChange(status !== 'available' ? 'available' : '')}
                      >
                        {status === 'available' ? (
                          <NextLocation width='15' height='15' fill='#4F5AED' />
                        ) : (
                          <NextLocation width='15' height='15' fill='#687182' />
                        )}
                      </div>
                    </div>
                  </OverlayTrigger>
                )}
              </div>

              {tabName === 'vehicle' ? (
                <VehicleContainerMap
                  firstCoordsFound={firstCoordsFound}
                  selectVehicle={selectVehicle}
                  routeInstance={routeInstance}
                  setRouteInstance={setRouteInstance}
                  mapLayersValues={mapLayersValues}
                  setGetCoords={setGetCoords}
                  setMyCustomMap={setMyCustomMap}
                  myCustomMap={myCustomMap}
                  loading={loading}
                  markerAdded={markerAdded}
                  setMarkerAdded={setMarkerAdded}
                  routeCoords={routeCoords}
                  updatePoint={updatePoint}
                  visibleTrafic={visibleTrafic}
                  visibleWeather={visibleWeather}
                  stopPointVisible={stopPointVisible}
                  status={status}
                  POI={POI}
                  radius={radius}
                  zoomId={zoomId}
                  routeTypeMap={routeTypeMap}
                  routeChange={routeChange}
                  setLastCenter={setLastCenter}
                  setLastZoom={setLastZoom}
                  lastZoom={lastZoom}
                  lastCenter={lastCenter}
                  pusherLoading={pusherLoading}
                  setZoomData={setZoomData}
                  zoomData={zoomData}
                  routeMarkers={routeMarkers}
                  setRouteMarkers={setRouteMarkers}
                  routeStopContent={routeStopContent}
                  setRouteStopContent={setRouteStopContent}
                  stopPointAllData={stopPointAllData}
                  stopPointAll={stopPointAll}
                  stopPointCoords={stopPointCoords}
                  stopPointsData={stopPointsData}
                  firstCoords={firstCoords}
                  setEmptyMiles={setEmptyMiles}
                  emptyMiles={emptyMiles}
                  ismapstyleChange={ismapstyleChange}
                  showHeaderFilters={showHeaderFilters}
                  onEquipmentSelect={onEquipmentSelect}
                  locationFilterData={locationFilterData}
                  optionFilterData={optionFilterData}
                  showStop={showStop}
                  setShowStop={setShowStop}
                  nextLocations={nextLocations}
                />
              ) : (
                <TrailerContainerMap
                  firstCoordsFound={firstCoordsFound}
                  selectVehicle={selectVehicle}
                  setGetCoords={setGetCoords}
                  setMyCustomMap={setMyCustomMap}
                  updatePoint={updatePoint}
                  loading={loading}
                  visibleTrafic={visibleTrafic}
                  visibleWeather={visibleWeather}
                  stopPointVisible={stopPointVisible}
                  status={status}
                  POI={POI}
                  mapLayersValues={mapLayersValues}
                  setLastCenter={setLastCenter}
                  setLastZoom={setLastZoom}
                  lastZoom={lastZoom}
                  lastCenter={lastCenter}
                  radius={radius}
                  routeTypeMap={routeTypeMap}
                  routeChange={routeChange}
                  routeCoords={routeCoords}
                  setRouteInstance={setRouteInstance}
                  setZoomDataTrailer={setZoomDataTrailer}
                  zoomDataTrailer={zoomDataTrailer}
                  routeMarkers={routeMarkers}
                  setRouteMarkers={setRouteMarkers}
                  routeStopContent={routeStopContent}
                  setRouteStopContent={setRouteStopContent}
                  stopPointAllData={stopPointAllData}
                  stopPointAll={stopPointAll}
                  stopPointCoords={stopPointCoords}
                  stopPointsData={stopPointsData}
                  firstCoords={firstCoords}
                  setEmptyMiles={setEmptyMiles}
                  emptyMiles={emptyMiles}
                  ismapstyleChange={ismapstyleChange}
                  locationFilterData={locationFilterData}
                  optionFilterData={optionFilterData}
                />
              )}
              {/* </div> */}
            </Col>
          </Row>
        </div>
        <Popover
          id={map}
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className='p-3'>
            <p
              onClick={() => mapLayersValue('TRANSPORTATION_DARK')}
              className='mapLayersMenu'
              style={{
                cursor: 'pointer',
                color: mapLayersValues === 'TRANSPORTATION_DARK' ? '#4F5AED' : '',
              }}
            >
              Dark
            </p>
            <p
              onClick={() => mapLayersValue('BASIC')}
              className='mapLayersMenu'
              style={{
                cursor: 'pointer',
                color: mapLayersValues === 'BASIC' ? '#4F5AED' : '',
              }}
            >
              {' '}
              Light
            </p>
            <p
              onClick={() => mapLayersValue('SATELLITE')}
              className='mapLayersMenu'
              style={{
                cursor: 'pointer',
                color: mapLayersValues === 'SATELLITE' ? '#4F5AED' : '',
              }}
            >
              Satellite
            </p>
          </div>
        </Popover>
      </div>
    </MapContext.Provider>
  );
};

export default MapMain;
