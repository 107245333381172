import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import moment from 'moment/moment';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import globeIcon from 'assets/icons/globe.png';
import locationIcon from 'assets/icons/location.png';

const currencies = {
  $: 'USD',
  C$: 'CAD',
  '₱': 'MXN',
};

const getBarcode = (id, displayValue = true) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id, { displayValue });

  return canvas.toDataURL('image/jpeg');
};

const createFooter = ({ doc, data, company, page = 1, totalPages = 1, currency, formatDate }) => {
  if (!data || !company) {
    return null;
  }

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const { website, show_website, show_billing, receivable_email, is_combined } = data || {};
  const { address1, address2, city_id, state_id, country_id, zip, company_name, phone_number } = company || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  // background
  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 26, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5.5, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4, pageHeight - 20, pageWidth / 4, pageHeight - 10);
    doc.line(pageWidth / 2 + 1, pageHeight - 20, pageWidth / 2 + 1, pageHeight - 10);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 8, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 5, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3);
    doc.text('Address', 13, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 10, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);

    doc.setFontSize(8);
    doc.setTextColor('#171C26');
    doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 8, pageHeight - 13);
    doc.text(`${city_id?.name}, ${state_id?.name} ${zip}`, 8, pageHeight - 9, { maxWidth: 45 });

    if (show_website === 1) {
      doc.text(`${website}`, pageWidth / 4 + 5, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 4 + 5, pageHeight - 13);
    }
    if (show_billing) {
      doc.text(`${receivable_email}`, pageWidth / 2 + 6, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 2 + 6, pageHeight - 13);
    }
    doc.text(`${phone}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);

    // Summary section
    doc.setDrawColor(0, 0, 0);
    doc.setLineDash([3, 3], 0);
    doc.line(8, pageHeight - 63, pageWidth - 8, pageHeight - 63);
    doc.line(8, pageHeight - 63.1, pageWidth - 8, pageHeight - 63.1);

    // Right side
    doc.setFillColor(209, 41, 83);
    doc.rect(114, pageHeight - 59, 94, 6, 'F');

    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#FFFFFF');
    doc.text('Total Due', 125, pageHeight - 55);
    doc.text('Payment Due Date', 175, pageHeight - 55);

    doc.setTextColor('#000000');
    doc.text(`${currency}5,915.00`, 125, pageHeight - 48.5);
    doc.text(`${formatDate('01/30/2023')}`, 180, pageHeight - 48.5);

    doc.setDrawColor(188, 194, 206);
    doc.setLineDash(0);
    doc.line(125, pageHeight - 47, pageWidth - 8, pageHeight - 47);
    doc.setDrawColor(0, 0, 0);

    doc.text('Customer', 125, pageHeight - 43);
    doc.text('Invoice Number', 125, pageHeight - 39);
    doc.text('Amount Enclosed', 125, pageHeight - 35);

    doc.setFont('Inter', 'normal', 400);
    doc.text('Customer Inc.', 160, pageHeight - 43);
    doc.text(`123456789 ${is_combined ? '- 12345' : ''}`, 160, pageHeight - 39);
    doc.text('(if by Check)', 125, pageHeight - 31);
    doc.text('Enter the amount you are paying above', 155, pageHeight - 30);
    doc.line(154, pageHeight - 33.5, pageWidth - 8, pageHeight - 33.5);

    // Left side
    doc.setFont('Inter', 'normal', 700);
    doc.text('Payable to:', 8, pageHeight - 55);

    doc.setFont('Inter', 'normal', 400);
    doc.text(`${company_name}`, 28, pageHeight - 55);
    doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 28, pageHeight - 51);
    doc.text(`${city_id?.name}, ${state_id?.name}, ${zip}`, 28, pageHeight - 47);
    doc.text(`${country_id.sortname === 'US' ? 'USA' : country_id.sortname}`, 28, pageHeight - 43);

    doc.setFont('Inter', 'normal', 700);
    doc.text('Make Payment:', 8, page !== 1 ? 37 : pageHeight - 36);

    doc.setFont('Inter', 'normal', 400);
    doc.text('Credit Card', 34, page !== 1 ? 37 : pageHeight - 36);
  }
};

export const generatePDF = ({ data, company, currency, formatDate, formatDateTime }) => {
  if (!data || !company) {
    return null;
  }

  const { is_factoring, notice, invoice_terms, is_combined } = data || {};
  const { address1, address2, city_id, state_id, country_id, zip, company_logo, company_name } = company || {};
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  const logo = company_logo?.startsWith('https://') ? company_logo : `https://${dot}.s3.amazonaws.com/${company_logo}`;

  doc.addImage(logo, 'JPEG', 8, 8, 60, 12, 'Logo', 'FAST');

  doc.setFontSize(22);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Invoice', 8, 30);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);

  doc.text('Customer Inc.', 8, 38);
  doc.text(`Attention: John Doe`, 8, 42);
  doc.text('123 Main St., 16C', 8, 46);
  doc.text('Any Town, Michigan 65555', 8, 50);
  doc.text('USA', 8, 54);

  doc.setFont('Inter', 'normal', 700);
  doc.text('Invoice Number', pageWidth - 80, 16);
  doc.text('Invoice Date', pageWidth - 80, 24);
  doc.text('Shipment ID', pageWidth - 80, 32);
  doc.text('Reference', pageWidth - 80, 40);
  doc.text('Terms', pageWidth - 80, 48);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`123456789 ${is_combined ? '- 12345' : ''}`, pageWidth - 80, 19.5);
  doc.text(`${moment('01/01/2023').format('MMM D, YYYY')}`, pageWidth - 80, 27.5);
  doc.text('12345', pageWidth - 80, 35.5);
  doc.text('123456789', pageWidth - 80, 43.5);
  doc.text('30 Days Net', pageWidth - 80, 51.5);

  doc.addImage(getBarcode(`123456789 ${is_combined ? '- 12345' : ''}`, true), 'JPEG', pageWidth - 42, 8, 35, 15);

  doc.text(`${company_name}`, pageWidth - 42, 30);
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, pageWidth - 42, 33);
  doc.text(`${city_id?.name}, ${state_id?.short_name} ${zip}`, pageWidth - 42, 36, { maxWidth: 40 });

  const addressLineLength = Number(city_id?.name.length) + Number(state_id?.short_name.length) + Number(zip?.length);

  doc.text(
    `${country_id.sortname === 'US' ? 'USA' : country_id.sortname}`,
    pageWidth - 42,
    addressLineLength > 24 ? 42 : 39
  );

  // Shipper
  doc.setFontSize(10);
  doc.setTextColor('#21467b');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Shipper', 8, 62);

  doc.setFontSize(8);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 400);
  doc.text('AAA Products', 8, 66);
  doc.text('1616 Oak St.', 8, 70);
  doc.text('Anytown, Michigan 12345', 8, 74);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Arrive:', 8, 78);
  doc.text('Depart:', 8, 82);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${formatDateTime('01/01/2023 13:00')}`, 20, 78);
  doc.text(`${formatDateTime('01/01/2023 14:00')}`, 20, 82);

  // Consignee
  doc.setFontSize(10);
  doc.setTextColor('#21467b');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Consignee', 114, 62);

  doc.setFontSize(8);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 400);
  doc.text('ABC Services', 114, 66);
  doc.text('1515 Water St.', 114, 70);
  doc.text('Anytown, Michigan 12345', 114, 74);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Arrive:', 114, 78);
  doc.text('Depart:', 114, 82);
  doc.text('Proof of Delivery:', 114, 86);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${formatDateTime('01/01/2023 21:00')}`, 126, 78);
  doc.text(`${formatDateTime('01/01/2023 22:00')}`, 126, 82);

  doc.text('John Smith', 140, 86);

  // Shipment Charges
  doc.setFont('Inter', 'normal', 700);

  doc.text('Description', 8, 97);
  doc.text('Quantity', 132, 97);
  doc.text('Rate', 162, 97);
  doc.text(`Amount ${currencies[currency]}`, 188, 97);
  doc.line(8, 98.5, pageWidth - 8, 98.5);

  doc.setFont('Inter', 'normal', 400);

  doc.text('Commodity Description Line 1', 8, 103);
  doc.text('1.00', 132, 103);
  doc.text('100.00', 162, 103);
  doc.text('100.00', 188, 103);
  doc.setDrawColor(188, 194, 206);
  doc.line(8, 104.5, pageWidth - 8, 104.5);

  doc.text('Commodity Description Line 2', 8, 108);
  doc.text('3.00', 132, 108);
  doc.text('200.00', 162, 108);
  doc.text('600.00', 188, 108);
  doc.line(8, 109.5, pageWidth - 8, 109.5);

  doc.text('Accessorial 1', 8, 113);
  doc.text('10.00', 132, 113);
  doc.text('300.00', 162, 113);
  doc.text('3,000.00', 188, 113);
  doc.line(8, 114.5, pageWidth - 8, 114.5);

  doc.text('Fuel Surcharge', 8, 118);
  doc.text('5.00', 132, 118);
  doc.text('150.00', 162, 118);
  doc.text('750.00', 188, 118);
  doc.line(8, 119.5, pageWidth - 8, 119.5);

  doc.text('Linehaul', 8, 123);
  doc.text('20.00', 132, 123);
  doc.text('200.00', 162, 123);
  doc.text('4,000.00', 188, 123);

  // Total Amount
  doc.setDrawColor(0, 0, 0);
  doc.line(132, 126, pageWidth - 8, 126);
  doc.text('Subtotal', 132, 130);
  doc.text('Discount', 132, 135);
  doc.setFont('Inter', 'normal', 700);
  doc.text(`TOTAL ${currencies[currency]}`, 132, 140);
  doc.setFont('Inter', 'normal', 400);
  doc.text('8,450.00', 188, 130);
  doc.text('30.00%', 162, 135);
  doc.text('2,535.00', 188, 135);
  doc.text('5,915.00', 188, 140);

  doc.setDrawColor(188, 194, 206);
  doc.line(132, 131, pageWidth - 8, 131);
  doc.line(132, 136, pageWidth - 8, 136);

  // Total Pieces
  doc.setFont('Inter', 'normal', 700);
  doc.text('Total Pieces', 132, 146);
  doc.text('Total Weight', 188, 146);

  doc.setDrawColor(0, 0, 0);
  doc.line(132, 147, pageWidth - 8, 147);
  doc.setFont('Inter', 'normal', 400);
  doc.text('39.00', 132, 151);
  doc.text('1,500.00 LBS', 188, 151);

  const chargesEnd = 146;
  let termsEnd;

  // Invoice Terms
  if (invoice_terms) {
    doc.setFont('Inter', 'normal', 700);
    doc.text('Invoice Terms', 8, chargesEnd + 12);

    doc.setFont('Inter', 'normal', 400);
    doc.text(`${invoice_terms}`, 8, chargesEnd + 16, { maxWidth: pageWidth - 16 });
    termsEnd = chargesEnd + 16;
  }

  // Shipment notes
  doc.setFont('Inter', 'normal', 700);
  doc.text('Invoice Notes', 8, (termsEnd || chargesEnd) + 12);
  doc.setFont('Inter', 'normal', 400);

  doc.text('This is an example note.', 8, (termsEnd || chargesEnd) + 16, { maxWidth: pageWidth - 16 });
  doc.text('This is another example note.', 8, (termsEnd || chargesEnd) + 20, { maxWidth: pageWidth - 16 });
  const notesEnd = (termsEnd || chargesEnd) + 16;

  // Notice of Assignment
  if (is_factoring === 1 && notice) {
    doc.setFont('Inter', 'normal', 700);
    doc.text('Notice of Assignment', 8, notesEnd + 12);

    doc.setFont('Inter', 'normal', 400);
    doc.text(`${notice.length > 1000 ? `${notice.slice(0, 1000)}...` : notice}`, 8, notesEnd + 16, {
      maxWidth: pageWidth - 16,
    });
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter({ doc, data, company, page: currentPage, totalPages: pagesCount, currency, formatDate });
  }

  const url = doc.output('datauristring');
  const blob = doc.output('blob');

  return { blob, url };
};
