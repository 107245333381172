import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Modal from 'common/Modal';
import Loader from 'common/Loader';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import { createRule, deleteRule, getAccountsList, getPayeeList, getRules, updateRule } from 'Api/Accounts';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { SAddMore } from 'pages/Accounting/Accounts/RightSection/RightSection.styles';
import { convertRuleData } from 'pages/Accounting/Accounts/components/Rules/Rules.data';
import RulesRow from './RulesRow';
import { STable } from './Rules.styles';

const Rules = ({ open, onClose, title, account }) => {
  const showToaster = useShowToaster();
  const [rules, setRules] = useState([]);
  const [payees, setPayees] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getOptions = async () => {
    try {
      const [{ data: payees }, { data: accounts }] = await Promise.all([getPayeeList(), getAccountsList()]);
      const filteredAccounts = accounts?.filter((i) => i.id !== account.id);
      setPayees(payees || []);
      setAccounts(filteredAccounts || []);
      return { accounts: filteredAccounts, payees };
    } catch (e) {
      return {};
    }
  };

  const getRulesList = async () => {
    setLoading(true);
    try {
      const { accounts, payees } = await getOptions();
      const { data } = await getRules(account.id);
      setRules((data || []).map((rule) => convertRuleData(rule, accounts || [], payees || [])));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const createNewRule = async (values, index) => {
    setLoadingCreate(true);
    try {
      const body = {
        account_id: account.id,
        contains_type: values.contains_type,
        contains: values.contains.map((item) => item.value).toString(),
        itemized_as_account_id: values.account.id,
        payee_type: values.payee.type,
        payee_id: values.payee.id,
        is_exact: Number(values.match_pattern === 'is exact'),
        memo: values.memo || null,
      };

      const { data } = await createRule(body);
      const newRule = convertRuleData(data, accounts, payees);
      setRules((prevState) => prevState.map((rule, i) => (i === index ? newRule : rule)));

      showToaster({ type: 'success', message: 'Rule has been successfully created!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingCreate(false);
    }
  };

  const updateExistingRule = async (values, index) => {
    if (
      !values.contains?.length ||
      !values.account ||
      !values.payee ||
      !values.contains_type ||
      !values.match_pattern
    ) {
      return;
    }

    try {
      const body = {
        contains_type: values.contains_type,
        contains: values.contains.map((item) => item.value).toString(),
        itemized_as_account_id: values.account.id,
        payee_type: values.payee.type,
        payee_id: values.payee.id,
        is_exact: Number(values.match_pattern === 'is exact'),
        memo: values.memo || null,
      };

      const { data } = await updateRule(values.id, body);
      const newRule = convertRuleData(data, accounts, payees);
      setRules((prevState) => prevState.map((rule, i) => (i === index ? newRule : rule)));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const deleteExistingRule = async (rule, index) => {
    try {
      await deleteRule(rule.id);
      showToaster({ type: 'success', message: 'Rule has been successfully deleted!' });
      setRules((prevState) => prevState.filter((rule, i) => i !== index));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onAddNewRule = () => {
    setRules((prevState) => [...prevState, { id: Date.now(), isNew: true }]);
  };
  const onNewRowDelete = (index) => {
    setRules((prevState) => prevState.filter((rule, i) => i !== index));
  };

  useEffect(() => {
    getRulesList();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={title || 'Rules'}
      $bgColor={palette.white}
      $width='90vw'
      $maxWidth='1600px'
      $minWidth='1000px'
      $minHeight='350px'
      className='modified-scrollbar'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      <Loader loading={loading} />
      {!loading && (
        <div>
          <STable>
            <thead>
              <tr>
                <th>WHEN...</th>
                <th>...DO THIS</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {rules.map((rule, index) => (
                <RulesRow
                  key={rule.id}
                  rule={rule}
                  accounts={accounts}
                  payees={payees}
                  onCreate={(values) => createNewRule(values, index)}
                  updateExistingRule={(values) => updateExistingRule(values, index)}
                  onExistingRuleDelete={() => deleteExistingRule(rule, index)}
                  onNewRowDelete={() => onNewRowDelete(index)}
                  loadingCreate={loadingCreate}
                />
              ))}
            </tbody>
          </STable>
          {!rules.length && <NoRecords />}
          <SAddMore className='mt-2' onClick={onAddNewRule}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add New Rule...
            </Typography>
          </SAddMore>
        </div>
      )}
    </Modal>
  );
};

export default Rules;
