import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  display: flex;
  padding: 24px 0 8px;
  justify-content: space-between;
  border-bottom: 1px solid ${palette.gray50};

  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const SBody = styled.div`
  padding: 16px 0 100px;
  display: flex;
  height: 100%;

  .image-wrapper {
    display: flex;
    width: 100%;
    height: 1099px;
  }

  .figure {
    display: flex;
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .pdf {
    display: flex;
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
