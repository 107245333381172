import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import useShowToaster from 'hooks/useShowToaster';
import { sendEmail } from 'Api/TimeTracking';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import { SCustomModal } from 'pages/ApplicantsAndReferrals/ApplicantsAndReferrals.styles';
import { generatePDF } from './helpers';
import { sendEmailConverter } from '../../TimeTracking.convertors';
import { SDotsWrapper, SPopover } from './TableActions.styles';

const TableActions = ({ data, company, dateRange }) => {
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const handleView = () => {
    setAnchorEl(null);
    const { url } = generatePDF(data, company, dateRange, dateFormat);
    setPdfUrl(url);
  };

  const handleSendEmail = async () => {
    setAnchorEl(null);
    try {
      const { blob } = generatePDF(data, company, dateRange, dateFormat);
      const formData = sendEmailConverter({ ...data, dateRange, blob });
      await sendEmail(formData);
      showToaster({ type: 'success', message: `Email sent to ${data.first_name} ${data.last_name} successfully!` });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  return (
    <SDotsWrapper className='action-dots' onClick={(e) => e.stopPropagation()}>
      <span
        className='dots'
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <ActionDots width={13} height={13} />
      </span>
      <SPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='actions-wrapper'>
          <li onClick={handleView}>View</li>
          <li onClick={handleSendEmail}>Email</li>
        </div>
      </SPopover>
      <SCustomModal
        showModal={!!pdfUrl}
        onHide={() => setPdfUrl(null)}
        headerTitle='Time Tracking Statement'
        $minWidth='80vw'
        $height='auto'
        $bodyMaxHeight='calc(100vh - 184px)'
        buttons={[]}
      >
        <CloseIcon className='close-modal-icon' onClick={() => setPdfUrl(null)} />
        <div>
          <iframe
            src={pdfUrl}
            title='testPdf'
            id='pdf-iframe'
            height='458px'
            width='100%'
            style={{ height: 'calc(100vh - 224px)' }}
          />
        </div>
      </SCustomModal>
    </SDotsWrapper>
  );
};

export default TableActions;
