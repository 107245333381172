import { Dropdown } from 'react-bootstrap';
import React from 'react';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import { GetShipmentDetention } from 'Api/DetentionShipment';
import CustomPagination from 'components/CustomPagination/CustomPagination';
import styles from 'components/EquipmentStopPoint/EquipmentStopPointFooter/EquipmentStopPointFooter.module.css';

const DetentionFooter = ({
  totalCount,
  lastPage,
  totalLength,
  rowPerPage,
  onPageChange,
  currentPage,
  onChangeRowPerPage,
  setDetention,
  setLoading,
}) => {
  const { use } = useTheme();
  const pageChangeHandler = (page) => {
    if (lastPage !== 1) {
      onPageChange(page);
      setLoading(true);
      GetShipmentDetention({ page, itemsPerPage: rowPerPage })
        .then((res) => setDetention(res))
        .finally(() => setLoading(false));
    }
  };

  return (
    <td className='d-flex mt-2 justify-content-between align-items-center'>
      <div className='d-flex'>
        <span className={styles.equipmentStopPoint_rowsPerPage}>
          1-{totalLength} of {totalCount} | Rows per page
        </span>
        <Dropdown
          className={styles.dropdown}
          onSelect={(value) => {
            onChangeRowPerPage(Number(value));
          }}
        >
          <Dropdown.Toggle
            placement='top'
            variant='outline-secondary'
            id='dropdown-basic'
            className={styles.equipmentStopPoint_dropdownToggle}
            style={{
              color: use(palette.gray700, palette.gray200),
              backgroundColor: use(palette.white, palette.dark800),
            }}
          >
            {rowPerPage}
            <div className={styles.equipmentStopPoint_arrows_wrapper}>
              <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            className={styles.equipmentStopPoint_menu}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
          >
            {[25, 50, 100, 250, 500].map((el) => {
              return (
                <Dropdown.Item
                  eventKey={String(el)}
                  active={+rowPerPage === el}
                  style={{
                    color:
                      +rowPerPage === el ? use(palette.white, palette.white) : use(palette.gray700, palette.gray200),
                  }}
                  key={el}
                >
                  {el}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={styles.customPagination}>
        <CustomPagination
          lastPage={lastPage}
          pageSize={rowPerPage}
          totalCount={totalCount}
          currentPage={currentPage}
          onPageChange={pageChangeHandler}
        />
      </div>
    </td>
  );
};

export default DetentionFooter;
