import React, { useEffect, useState } from 'react';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import CustomButton from 'components/CustomButton/CustomButton';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as UpdateIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import { getActionTaken } from 'Api/Incidents';
import { getIncidentTypes, createIncidentType, updateIncidentType, deleteIncidentType } from 'Api/CompanySettings';
import ItemForm from './ItemForm';
import RecommendedActions from './RecommendedActions';
import ActionTaken from '../ActionTaken';
import {
  SWrapper,
  SHeader,
  SHeaderActions,
  SActions,
  SAddMore,
  SContentRow,
  SRow,
  SCircularProgress,
} from './Incidents.styles';

const riskMapper = {
  L: 'Low Risk',
  H: 'High Risk',
};

const infractionMapper = {
  1: 'Safety',
  2: 'Conduct',
};

const Incidents = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage, isModalView }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [incidents, setIncidents] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [actionTaken, setActionTaken] = useState([]);
  const [loadingActionTaken, setLoadingActionTaken] = useState(false);
  const [forms, setForms] = useState([]);
  const [editItems, setEditItems] = useState([]);
  const [recommendedActions, setRecommendedActions] = useState([]);
  const [recdActionsTitle, setRecActionsTitle] = useState('');

  const getActionTakenList = async () => {
    try {
      const { data } = await getActionTaken();
      const updatedData = data.filter((item) => item.id !== 1 && item.id !== 2);
      setActionTaken(updatedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingActionTaken(false);
    }
  };

  const getIncidents = async () => {
    setLoading(true);
    try {
      const { data } = await getIncidentTypes();
      setIncidents(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const createIncident = async (values, id) => {
    setLoadingCreate(true);
    try {
      const body = {
        type: values.type,
        type_for: values.type_for ? 'driver' : null,
        risk_level: values.risk_level.risk_level,
        infraction_type_id: values.infraction_type.id,
        action_id: values.recommended_actions?.filter((action) => !!action.id).map((action) => action.id),
      };

      await createIncidentType(body);

      deleteFormItem(id);
      getIncidents();
      showToaster({ type: 'success', message: 'Incident type has been successfully added!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingCreate(false);
    }
  };

  const updateIncident = async (values, id) => {
    setLoadingUpdate(true);

    try {
      const body = {
        type: values?.type,
        type_for: values.type_for ? 'driver' : null,
        risk_level: values?.risk_level.risk_level,
        infraction_type_id: values?.infraction_type.id,
        action_id: values?.recommended_actions?.filter((action) => !!action.id)?.map((action) => action.id),
      };

      await updateIncidentType(id, body);
      getIncidents();
      onCancel(id);
      showToaster({ type: 'success', message: 'Incident type has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const deleteIncident = async (incident) => {
    try {
      const body = {
        type: incident?.type,
        type_for: incident?.type_for,
        risk_level: incident?.risk_level,
      };

      await deleteIncidentType(incident.id, body);
      const newIncidents = incidents.filter((item) => item.id !== incident.id);
      setIncidents(newIncidents);
      showToaster({ type: 'success', message: 'Incident type has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const toggleEdit = (id) => {
    setEditItems([...editItems, id]);
  };

  const onCancel = (id) => {
    const newEditItems = editItems.filter((item) => item !== id);
    setEditItems(newEditItems);
  };

  useEffect(() => {
    getIncidents();
    setLoadingActionTaken(true);
    getActionTakenList();
  }, []);

  return (
    <SWrapper>
      {!isModalView && (
        <SHeader>
          <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
            Incidents
          </Typography>
          <SHeaderActions>
            {isConfiguringStep && (
              <>
                <CustomButton
                  onClick={onCancelPrevPage}
                  type='secondary'
                  title='Back'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
                <CustomButton
                  onClick={() => onSaveAndNext('skip')}
                  type='secondary'
                  title='Skip'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
                <CustomButton
                  onClick={() => onSaveAndNext('saveAndNext')}
                  type='primary'
                  title={isConfiguringStep ? 'Save and Next' : 'Save'}
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
              </>
            )}
          </SHeaderActions>
        </SHeader>
      )}
      {loading ? (
        <SCircularProgress size={26} />
      ) : (
        <>
          <SRow>
            <div className='settings-header-item'>
              <Typography variant='overLine'>INCIDENT TYPE</Typography>
            </div>
            <div className='settings-header-item'>
              <Typography variant='overLine'>RISK LEVEL</Typography>
            </div>
            <div className='settings-header-item'>
              <Typography variant='overLine'>RECOMMENDED ACTION</Typography>
            </div>
            <div className='settings-header-item'>
              <Typography variant='overLine'>INFRACTION TYPE</Typography>
            </div>
            <div className='settings-header-item'>
              <Typography variant='overLine'>IS VISIBLE TO DRIVER</Typography>
            </div>
            <div className='settings-header-item'>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography />
              </div>
            </div>
          </SRow>
          {incidents?.map((item) =>
            editItems.includes(item.id) ? (
              <ItemForm
                item={item}
                key={item.id}
                defaultItem={!!item?.default}
                onCancel={() => onCancel(item?.id)}
                onSubmit={(values) => updateIncident(values, item.id)}
                loading={loadingUpdate}
                actionTakenOptions={actionTaken}
              />
            ) : (
              <SContentRow key={item.id}>
                <div className='settings-table-item'>
                  <Typography variant='s2'>{item.type}</Typography>
                </div>
                <div className='settings-table-item'>
                  <Typography variant='s2'>{riskMapper[item?.risk_level]}</Typography>
                </div>
                <div className='settings-table-item'>
                  {!!item?.incident_actions?.length && (
                    <SAddMore
                      onClick={() => {
                        setRecActionsTitle(item?.type);
                        setRecommendedActions(item?.incident_actions || []);
                      }}
                    >
                      <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
                        View
                      </Typography>
                    </SAddMore>
                  )}
                </div>
                <div className='settings-table-item'>
                  <Typography variant='s2'>{infractionMapper[item?.infraction_type_id]}</Typography>
                </div>
                <div className='settings-table-item d-flex align-items-center justify-content-center pe-4'>
                  {item.type_for === 'driver' ? (
                    <CheckIcon width={11} height={11} />
                  ) : (
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  )}
                </div>
                <SActions className='settings-table-item settings-table-actions-wrapper'>
                  {item.default !== 1 && (
                    <span className='action_wrapper' onClick={() => deleteIncident(item)}>
                      <DeleteIcon width={11} height={11} fill={palette.red500} />
                    </span>
                  )}
                  <span className='action_wrapper' onClick={() => toggleEdit(item.id)}>
                    <UpdateIcon width={11} height={11} fill={palette.indigo500} />
                  </span>
                </SActions>
              </SContentRow>
            )
          )}
          {forms.map((item) => (
            <ItemForm
              key={item.id}
              onSubmit={(values) => createIncident(values, item.id)}
              onCancel={() => deleteFormItem(item.id)}
              loading={loadingCreate}
              actionTakenOptions={actionTaken}
            />
          ))}
          <SAddMore onClick={addNewForm}>
            <PlusIcon fill='#4F5AED' />
            <Typography variant='s2' style={{ color: '#4F5AED', cursor: 'pointer' }}>
              Add Incident Type...
            </Typography>
          </SAddMore>
        </>
      )}
      {!isModalView && (
        <ActionTaken loading={loadingActionTaken} actionTaken={actionTaken} setActionTaken={setActionTaken} />
      )}
      {!!recommendedActions?.length && (
        <RecommendedActions
          title={recdActionsTitle}
          open={!!recommendedActions?.length}
          onClose={() => {
            setRecommendedActions([]);
            setRecActionsTitle('');
          }}
          recommendedActions={recommendedActions}
        />
      )}
    </SWrapper>
  );
};

export default Incidents;
