import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 33%;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
  margin: 0 5px 0 0;
  padding: 12px;

  :last-child {
    margin: 0;
  }

  &.active-option {
    box-shadow: 0 0 0 2px ${palette.indigo500}, 0px 15px 35px -5px rgba(17, 24, 38, 0.2),
      0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 312px;
    background: ${palette.white};
  }

  .most-popular {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background: ${palette.indigo500};
    border-radius: 10px;
    height: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: -22px;
  }

  .price-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 86%;
    padding: 8px 12px;
    height: 36px;
    left: 460px;
    top: 665px;
    background-color: #f0f1fa;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: none;
    letter-spacing: 0.02em;
    color: ${palette.indigo500};
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;
