import React, { useEffect, useState } from 'react';
import { getCardHolders } from 'Api/CardManagement';
import { SAutocomplete } from './CardHolderSelect.styles';

const CardHolderSelect = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);

  const getOptions = async () => {
    try {
      const { data } = await getCardHolders();
      setOptions(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <SAutocomplete
      multiple
      width='300px'
      name='card-holders'
      labelKey='card_holder'
      options={options}
      limitTags={1}
      value={value}
      isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
      onChange={(e, values) => onChange(values)}
      placeholder='Select Card Holders'
    />
  );
};

export default CardHolderSelect;
