import React, { useState } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/drivers/indigoDownload.svg';
import { ReactComponent as UploadIcon } from 'assets/icon.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/createShipment/attachment.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import { importCustomersCsv } from 'Api/Customers';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { getHeaders } from 'utils/helpers';
import { checkBooleanValue, convertPayTerm, convertStartsAfterPer, customersMapper } from './ImportCsv.data';
import { SAttachedDoc, SCountWrapper, SDownload, SDownloadWrapper } from './ImportCsv.styles';

const ImportCsv = ({ open, onClose, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [data, setData] = useState(null);

  function csvToArray(str) {
    const arr = getHeaders(str, customersMapper);

    const importCustomersData = {
      customer_id: [],
      general_ledger_id: [],
      company_name: [],
      telephone_number: [],
      company_dot_mc: [],
      address1: [],
      address2: [],
      country: [],
      state: [],
      city: [],
      zipcode: [],
      invoice_pay_term: [],
      invoice_note: [],
      require_signature_on_stop: [],
      require_bill_of_lading: [],
      require_confirmations: [],
      detention_starts_after_hour: [],
      billed_at: [],
      detention_trailer_dropped_starts_after: [],
      detention_trailer_dropped_start_after_per: [],
      detention_trailer_dropped_billed_at: [],
      credit_line_maximum: [],
      account: [],
    };
    if (arr?.length) {
      for (let i = 0; i < arr?.length; i++) {
        importCustomersData.customer_id.push(arr[i]?.customer_id);
        importCustomersData.general_ledger_id.push(arr[i]?.general_ledger_id);
        importCustomersData.company_name.push(arr[i]?.company_name);
        importCustomersData.telephone_number.push(arr[i]?.telephone_number);
        importCustomersData.company_dot_mc.push(arr[i]?.company_dot_mc);
        importCustomersData.address1.push(arr[i]?.address1);
        importCustomersData.address2.push(arr[i]?.address2);
        importCustomersData.country.push(arr[i]?.country);
        importCustomersData.state.push(arr[i]?.state);
        importCustomersData.city.push(arr[i]?.city);
        importCustomersData.zipcode.push(arr[i]?.zipcode);
        importCustomersData.invoice_pay_term.push(convertPayTerm(arr[i]?.invoice_pay_term));
        importCustomersData.invoice_note.push(arr[i]?.invoice_note);
        importCustomersData.require_signature_on_stop.push(checkBooleanValue(arr[i]?.require_signature_on_stop));
        importCustomersData.require_bill_of_lading.push(checkBooleanValue(arr[i]?.require_bill_of_lading));
        importCustomersData.require_confirmations.push(checkBooleanValue(arr[i]?.require_confirmations));
        importCustomersData.detention_starts_after_hour.push(arr[i]?.detention_starts_after_hour);
        importCustomersData.billed_at.push(arr[i]?.billed_at);
        importCustomersData.detention_trailer_dropped_starts_after.push(arr[i]?.detention_trailer_dropped_starts_after);
        importCustomersData.detention_trailer_dropped_start_after_per.push(
          convertStartsAfterPer(arr[i]?.detention_trailer_dropped_start_after_per)
        );
        importCustomersData.detention_trailer_dropped_billed_at.push(arr[i]?.detention_trailer_dropped_billed_at);
        importCustomersData.credit_line_maximum.push(arr[i]?.credit_line_maximum);
        importCustomersData.account.push(arr[i]?.account);
      }
    }

    setData(importCustomersData);
  }

  const importCustomers = async () => {
    try {
      setLoading(true);
      await importCustomersCsv(data);
      showToaster({ type: 'success', message: 'Customers have been successfully imported!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  const onImport = (file) => {
    setImportFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        csvToArray(text);
      };
      reader.readAsText(file);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Import from CSV'
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='600px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'Upload',
          type: 'primary',
          title: 'Upload',
          onClick: importCustomers,
          disabled: !importFile || loading,
        },
      ]}
    >
      <div className='d-flex flex-column gap-5'>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>1</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2'>
            <Typography as='div' variant='s2'>
              Download the CSV Template
            </Typography>
            <Typography as='div' variant='s2' style={{ color: palette.gray500 }}>
              First download the CSV template. Then fill out asked data in that template.
            </Typography>
            <SDownloadWrapper href={`${process.env.REACT_APP_BASE_URL}/uploads/csv/ImportCustomer.csv`} target='_blank'>
              <SDownload>
                <DownloadIcon />
                <Typography variant='s2' style={{ color: palette.indigo500, textDecoration: 'none' }}>
                  Download CSV Template
                </Typography>
              </SDownload>
            </SDownloadWrapper>
          </div>
        </div>
        <div className='d-flex gap-3'>
          <SCountWrapper>
            <Typography variant='s2'>2</Typography>
          </SCountWrapper>
          <div className='d-flex flex-column gap-2 w-100'>
            <Typography as='div' variant='s2'>
              Upload filled CSV file here:
            </Typography>
            {importFile ? (
              <SAttachedDoc>
                <AttachmentIcon fill={palette.indigo500} />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  {importFile.name}
                </Typography>
                <CancelIcon fill={palette.red500} style={{ cursor: 'pointer' }} onClick={() => setImportFile(null)} />
              </SAttachedDoc>
            ) : (
              <div
                className='upload-selector csv-upload'
                style={{ position: 'relative', backgroundColor: palette.white, borderColor: palette.gray50 }}
              >
                <label htmlFor='myFile' className='text-style csv-text-style'>
                  <UploadIcon width={30} height={30} fill={palette.gray300} />
                  <Typography variant='s2' style={{ color: palette.indigo500, marginTop: '10px' }}>
                    Upload File
                    <Typography variant='s2'> or drag and drop</Typography>
                  </Typography>
                </label>
                <ImageDropZone
                  onDrop={(files) => onImport(files[0])}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['text/csv']}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportCsv;
