import PDFMerger from 'pdf-merger-js';
import { generatePDF } from './helpers';

const urlToBlob = (fileUrl) => {
  return fetch(fileUrl).then((response) => response.blob());
};

const getInvoicesDocuments = async (attachmentUrls) => {
  try {
    const documentPaths = [];
    for (const item of attachmentUrls) {
      const blob = await urlToBlob(item);
      if (blob.type !== 'application/xml') {
        documentPaths.push(blob);
      }
    }
    return documentPaths;
  } catch (e) {
    return [];
  }
};

export const getMergedUrl = async (docs, invoiceId = '') => {
  try {
    const merger = new PDFMerger();

    for (const file of docs) {
      await merger.add(file);
    }

    await merger.setMetadata({
      title: `Invoice ${invoiceId}`,
    });
    const mergedPdf = await merger.saveAsBlob();
    return URL.createObjectURL(mergedPdf);
  } catch (e) {
    if (e.message?.includes('but got instance of undefined')) {
      throw new Error(
        `There is an issue with one of your uploaded documents (Invoice ID - ${invoiceId}). E-signature and secure documents are not permitted.`
      );
    }
    throw e;
  }
};

export const generateStandaloneInvoice = async ({
  companyProfile,
  invoiceData,
  accountingSettings,
  instructions,
  payable_to = null,
  email_to = null,
  send_email = null,
  payment_method = null,
  attach_noa_letter,
  stripePaymentLink,
  currency,
  formatDate,
}) => {
  const attachmentUrls = (invoiceData.attachments || []).map((attachment) => attachment.path);
  const attachmentFiles = await getInvoicesDocuments(attachmentUrls);

  const { blob } = generatePDF({
    companyProfile,
    invoiceData,
    accountingSettings,
    instructions,
    payable_to,
    email_to,
    send_email,
    payment_method,
    stripePaymentLink,
    currency,
    formatDate,
  });

  const docs = attach_noa_letter
    ? [blob, accountingSettings.notice_letter, ...attachmentFiles]
    : [blob, ...attachmentFiles];

  return getMergedUrl(docs, invoiceData.invoice_id);
};
