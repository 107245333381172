import React, { useState } from 'react';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import VerificationCode from 'common/VerificationCode';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { setCardPin } from 'Api/CardManagement';
import { SCustomModal } from '../../Cards.styles';

const SetPin = ({ open, onClose, onSuccess, data }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [step, setStep] = useState(1);
  const [initialPin, setInitialPin] = useState(null);
  const [confirmedPin, setConfirmedPin] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setPin = async () => {
    setLoading(true);
    try {
      const body = {
        provider_id: data.card_provider.id,
        card_number: data.card_number,
        pin: confirmedPin,
      };

      await setCardPin(body);
      showToaster({
        type: 'success',
        message: `You have successfully set the PIN for card ${data.card_number.slice(-4)}`,
      });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (val) => {
    setInitialPin(val);
  };

  const handleConfirmChange = (val) => {
    setConfirmedPin(val);
  };

  const onBack = () => {
    setStep(1);
  };

  const handleSubmit = async () => {
    if (step === 1) {
      setStep(2);
    }

    if (step === 2) {
      if (initialPin !== confirmedPin) {
        setError(true);
      } else {
        await setPin();
      }
    }
  };

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <>
          Set PIN
          <Typography as='div' variant='b2' style={{ color: use(palette.gray500) }}>
            You are setting a PIN for card {data?.card_number?.slice(-4)}
          </Typography>
        </>
      }
      $bgColor={palette.gray0}
      $maxWidth='440px'
      $minWidth='440px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        ...(step === 2
          ? [
              {
                key: 'back',
                type: 'secondary',
                title: 'Back',
                className: 'me-2',
                onClick: onBack,
              },
            ]
          : []),
        {
          key: 'submit',
          type: 'primary',
          title: step === 1 ? 'Continue' : 'Set PIN',
          disabled: step === 1 ? initialPin?.length !== 4 : confirmedPin?.length !== 4 || loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex justify-content-sm-around align-items-center'>
        <Typography variant='s2' style={{ color: use(palette.gray700), paddingBottom: '16px' }}>
          {step === 1 ? 'Please enter a new 4-digit PIN' : 'Please re-enter a new 4-digit PIN'}
        </Typography>
      </div>
      <div>
        {step === 1 && <VerificationCode onChange={handleChange} />}
        {step === 2 && (
          <div className='d-flex flex-column align-items-center'>
            <VerificationCode onChange={handleConfirmChange} />
            <ErrorMessage error={error && 'PIN does not match'} />
          </div>
        )}
      </div>
    </SCustomModal>
  );
};

export default SetPin;
