import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import CustomPagination from './EquipmentPagination';

const ROWS_PER_PAGE = [25, 50, 100];

const TableFooter = ({
  rowPerPage,
  totalCount,
  totalLength,
  lastPage,
  currentPage,
  onPageChange,
  onChangeRowPerPage,
}) => {
  const pageChangeHandler = (page) => {
    onPageChange(page);
  };

  return (
    <td className='d-flex mt-2 justify-content-between'>
      <div className='d-flex'>
        <span className='tablePlaner_rowsPerPage'>
          {(currentPage - 1) * rowPerPage + 1 || 0} -{' '}
          {(currentPage === lastPage ? totalCount : currentPage * totalLength) || 0} of {totalCount || 0}
        </span>
        <Dropdown className='dropdown' onSelect={(value) => onChangeRowPerPage(Number(value))}>
          <Dropdown.Toggle
            placement='top'
            variant='outline-secondary'
            id='dropdown-basic'
            className='tablePlaner_dropdownToggle'
            style={{
              color: palette.gray700,
              backgroundColor: palette.white,
            }}
          >
            {rowPerPage}
            <div className='tablePlaner_arrows_wrapper'>
              <Arrows width={10} height={10} fill={palette.gray500} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className='tablePlaner_menu' style={{ backgroundColor: palette.white }}>
            {ROWS_PER_PAGE.map((el) => {
              return (
                <Dropdown.Item
                  eventKey={String(el)}
                  active={+rowPerPage === el}
                  style={{
                    color: +rowPerPage === el ? palette.white : palette.gray700,
                  }}
                  key={el}
                >
                  {el}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='customPagination'>
        <CustomPagination
          hideDropdown
          lastPage={lastPage}
          pageSize={rowPerPage}
          totalCount={totalCount}
          currentPage={currentPage}
          onPageChange={pageChangeHandler}
        />
      </div>
    </td>
  );
};

export default TableFooter;
