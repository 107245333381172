import React from 'react';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const InfoItem = ({ title, titleProps, content = '', children }) => {
  return (
    <div className='d-flex flex-column align-items-start gap-1'>
      {title && (
        <InputLabel variant='c3' {...titleProps}>
          {title}
        </InputLabel>
      )}
      {children || (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {content}
        </Typography>
      )}
    </div>
  );
};

export default InfoItem;
