import React from 'react';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { SWrapper } from './IntroCheckboxes.styles';

const IntroCheckboxes = ({ company, values, handleChange }) => {
  return (
    <SWrapper>
      <div className='checkbox-wrapper'>
        <Typography variant='b1'>
          Yes, I agree to receive information concerning future opportunities or promotions from {company?.company_name}{' '}
          by email or other commercial electronic communications.
        </Typography>
        <CustomCheckbox
          checked={!!values[values.length - 2]}
          onChange={(checked) => handleChange(values.length - 2, checked)}
        >
          <Typography variant='b1' style={{ marginLeft: 8, color: palette.gray700 }}>
            Yes
          </Typography>
        </CustomCheckbox>
      </div>
      <div className='checkbox-wrapper'>
        <Typography variant='b1'>
          Would you like to receive communication from {company?.company_name} via text message?
        </Typography>
        <Typography variant='c2'>
          By participating, you consent to receive text messages sent by an automatic telephone dialing system, which
          may contain recruiting/advertising messages. Consent to these terms is not a condition of being hired,
          contracted, or leased. You may opt out at any time by texting STOP to unsubscribe. You also agree that{' '}
          {company?.company_name}'s service provider receives in real time and logs your text messages with{' '}
          {company?.company_name}.
        </Typography>
        <CustomCheckbox
          checked={!!values[values.length - 1]}
          onChange={(checked) => handleChange(values.length - 1, checked)}
        >
          <Typography variant='b1' style={{ marginLeft: 8, color: palette.gray700 }}>
            Yes
          </Typography>
        </CustomCheckbox>
      </div>
    </SWrapper>
  );
};

export default IntroCheckboxes;
