import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getMotionLogs } from 'Api/EquipmentProfile';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactComponent as CalendarIcon } from 'assets/icons/drivers/calendar.svg';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import MotionLogsTable from './MotionsLogsTable';
import classes from './MotionsLogs.module.scss';
import { MOTIONDATA } from '../../../../../components/Equipment/EquipmentHeader/EquipmentHeaderFilterByShow';
import Search from '../../../../ApplicantsAndReferrals/components/Search';
import DateRange from '../../../../../components/DateRangePicker/DateRangePicker';

import CustomButton from '../../../../../components/CustomButton/CustomButton';

const MotionsLogs = ({ open, onClose, profileData }) => {
  const { id } = useParams();
  const { equipment_id, equipment_type } = profileData;
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [statuses, setStatuses] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);
  const [rangeName, setRangeName] = useState('Today');
  const [dateRange, setDateRange] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  });

  const getLogs = async () => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');

    try {
      const tableData = await getMotionLogs({
        page,
        itemsPerPage: rowPerPage,
        'filters[equipment_id]': id,
        'filters[query]': debouncedSearch || undefined,
        'filters[from]': startFormat,
        'filters[to]': endFormat,
      });
      let result = [];
      let lastMotion = null;
      const filterStatus = statuses.map((item) => item.title);

      tableData.data.forEach((data) => {
        if (data.motion !== lastMotion) {
          result.push(data);
          lastMotion = data.motion;
        }
      });
      if (filterStatus.length) {
        const filteredLogs = result.filter((item) => filterStatus.includes(item.motion));
        result = [...filteredLogs];
      }

      setLogs(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getLogs();
  }, [rowPerPage, page, debouncedSearch, statuses.length, dateRange]);

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <>
          Motion Logs for {equipment_id} {equipment_type?.title}
        </>
      }
      $bgColor={palette.gray0}
      $minWidth='90vw'
      $height='90vw'
      $maxWidth='1400px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
      }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <>
        <div className={classes.motionFilterWrapper}>
          <div style={{ width: 320 }}>
            <Search search={search} onChange={setSearch} margin='0px' />
          </div>

          <div className=' mt-2'>
            <Autocomplete
              labelKey='title'
              multiple
              limitTags={3}
              options={MOTIONDATA}
              placeholder={statuses.length ? ' ' : 'Select Status'}
              value={statuses}
              onChange={(e, val) => setStatuses(val)}
              isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
              style={{ width: 330 }}
            />
          </div>
          <div style={{ width: 250 }}>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '117px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <CustomButton
              leftIcon={<CalendarIcon />}
              style={{ width: 280 }}
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              title={rangeName}
              type='secondary'
              onClick={() => setIsShowDatePicker(true)}
            />
          </div>
        </div>
        {loading ? (
          <div className='d-flex align-items-center justify-content-center'>
            <CircularProgress size={30} />
          </div>
        ) : (
          <MotionLogsTable
            logs={logs}
            rowPerPage={rowPerPage}
            setRowPerPage={setRowPerPage}
            page={page}
            setPage={setPage}
          />
        )}
      </>
    </Modal>
  );
};

export default MotionsLogs;
