import { Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import React, { useState } from 'react';
import LogTable from 'components/LogTab/ownerLogTab';
import OwnerActivity from 'components/OwnerActivity/OwnerActivity';
import OwnerDocument from 'components/OwnerDocument/OwnerDocument';
import OwnerEquipment from 'components/OwnerEuipment/OwnerEquipment';
import OwnerContractor from 'components/OwnerContractor/OwnerContractor';
import OperatorNoteTable from 'components/OperatorNoteTable/OperatorNoteTable';
import OwnerShipmentTabTable from 'components/OwnerShipmentTabTable/OwnerShipmentTabTable';
import OwnerGeneralInformation from 'components/OwnerGeneralInformation/OwnerGeneralInformation';
import OwnerOperatorCompensation from 'components/OwnerOperatorCompensation/OwnerOperatorCompensation';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import './OwnerProfileTab.css';

export default function DriverProfileTab({
  email_id,
  staffInfo,
  updateCompensation,
  showError,
  updateProfile,
  onSuccess,
  loading,
  staffStatsData,
  operatorCompansation,
  operator_name,
}) {
  const [key, setKey] = useState('General');
  const { use } = useTheme();
  const isDarkMode = use(palette.white, palette.dark800) !== '#FFFFFF';

  return (
    <div className='modal-container-tab driver-style-wrap owner-profile-tab'>
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className={`mb-3 profile-tab ${isDarkMode ? 'dark-mode-tab' : ''}`}
        style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
      >
        <Tab eventKey='General' className='profile-tab-content' unmountOnExit title='General'>
          <div className='Driver-general' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <OwnerGeneralInformation
              staffInfo={staffInfo}
              updateGeneralProfile={updateProfile}
              onSuccess={onSuccess}
              loading={loading}
            />
            <div className='activity-container'>
              <div>
                <OwnerActivity staffStatsData={staffStatsData} />
              </div>
              <div className='activity-container-bottom'>
                <div className='contractor'>
                  <OwnerContractor />
                </div>
                <div className='equipment'>
                  <OwnerEquipment />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey='Compensation'
          unmountOnExit
          className='profile-tab-content'
          title='Compensation'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <OwnerOperatorCompensation
              email_id={email_id}
              showError={showError}
              updateCompensation={updateCompensation}
              operatorCompansation={operatorCompansation}
              onSuccess={onSuccess}
              loading={loading}
            />
          </div>
        </Tab>
        <Tab eventKey='Documents' unmountOnExit className='profile-tab-content' title='Documents'>
          <OwnerDocument />
        </Tab>
        <Tab
          eventKey='Shipments'
          unmountOnExit
          className='profile-tab-content'
          title='Shipments'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <OwnerShipmentTabTable />
          </div>
        </Tab>
        <Tab eventKey='log' unmountOnExit className='profile-tab-content' title='Log'>
          <LogTable />
        </Tab>

        <Tab eventKey='notes' unmountOnExit className='profile-tab-content' title='Notes'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <OperatorNoteTable operator_name={operator_name} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}
