import React, { useState } from 'react';
import { Textarea } from 'common/Input';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

const AddReason = ({ open, onClose, onSuccess }) => {
  const { use } = useTheme();
  const [reason, setReason] = useState('');

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<>Add Reason</>}
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'done',
          type: 'primary',
          disabled: !reason,
          title: 'Submit',
          onClick: () => onSuccess(reason),
        },
      ]}
    >
      <div className='mt-2'>
        <Typography variant='s2' style={{ color: use(palette.gray400, palette.gray200) }}>
          Reason
        </Typography>
        <Textarea
          rows={3}
          style={{ width: '100%' }}
          placeholder='Reason'
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        />
      </div>
    </Modal>
  );
};

export default AddReason;
