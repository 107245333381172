export const getInitialValues = (transaction) => {
  const { date, reference_id, payee, account, memo, debit, credit } = transaction || {};

  return {
    date: date ? new Date(date) : null,
    reference_id: reference_id || '',
    payee: payee || null,
    account: account || null,
    memo: memo || '',
    amount: (debit || credit || '').toString(),
  };
};
