import styled from 'styled-components';

export const SHardware = styled.div`
  .hardware-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
`;
