import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { getDayOfWeekGraph } from 'Api/AccidentsAndCrashes';
import { SBoxWrapper } from '../../StatsSection.styles';
import DayOfWeekChart from '../Charts/DayOfWeekChart';

const DayOfWeek = ({ statsRefreshIndex }) => {
  const [revenue, setRevenue] = useState(null);

  const getChartData = async () => {
    try {
      const { data } = await getDayOfWeekGraph();
      setRevenue(data.days_data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getChartData();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <Typography variant='s2'>DAY OF WEEK</Typography>
      <div className='chart-wrapper'>
        <DayOfWeekChart monthsData={revenue} />
      </div>
    </SBoxWrapper>
  );
};

export default DayOfWeek;
