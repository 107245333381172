import React, { useState } from 'react';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { assignUser } from 'Api/CardManagement';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import RowActions from './RowActions';
import SetRestriction from '../SetRestriction';
import { days } from './TimeRestrictions.data';
import { SBackdrop, SCustomModal } from '../../Cards.styles';
import { STable, SText } from '../CardLimits/CardLimits.styles';
import { SHeaderWrapper } from '../CardDetails/CardDetails.styles';

const TimeRestrictions = ({ open, onClose, data, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [openUpdateRestriction, setOpenUpdateRestriction] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState(null);
  const { time_limits, card_number } = data || {};

  const onUpdate = (item) => {
    setRowToUpdate(item);
    setOpenUpdateRestriction(true);
  };

  const onRemove = async (item) => {
    setLoading(true);
    try {
      const newRestrictions = time_limits.filter((i) => i.id !== item.id);

      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');

      if (data.user) {
        formData.append(`user_id`, data.user?.id || null);
        formData.append(`user_type`, data.user_type === 'Driver' ? 2 : data.user_type === 'Staff' ? 1 : null);
      }

      if (newRestrictions?.length) {
        newRestrictions.forEach((item, index) => {
          formData.append(`timeRestrictions[${index}][day]`, item.day);
          formData.append(`timeRestrictions[${index}][startTime]`, item.beginTime);
          formData.append(`timeRestrictions[${index}][endTime]`, item.endTime);
        });
      } else {
        formData.append(`timeRestrictions[]`, '');
      }
      await assignUser(formData);
      showToaster({ type: 'success', message: 'Card limits have been successfully updated' });
      onSuccess(card_number);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <SHeaderWrapper>
          <Typography variant='h5'>Time Restrictions</Typography>
          <CustomButton
            type='secondary'
            title='Add New'
            onClick={() => setOpenUpdateRestriction(true)}
            className='edit-button'
            styleButton={{ padding: '6px 12px', marginTop: 0 }}
          />
        </SHeaderWrapper>
      }
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='800px'
      padding='24px 0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <div style={{ padding: '0 24px' }}>
        <STable>
          <thead>
            <tr>
              <th>DAY OF WEEK</th>
              <th>BEGIN TIME</th>
              <th>END TIME</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {time_limits?.map((item) => (
              <tr key={item.id}>
                <td>
                  <SText>{days[item.day]}</SText>
                </td>
                <td>
                  <SText>{moment.parseZone(item.beginTime).format('HH:mm')}</SText>
                </td>
                <td>
                  <SText>{moment.parseZone(item.endTime).format('HH:mm')}</SText>
                </td>
                <td>
                  <RowActions onUpdate={() => onUpdate(item)} onRemove={() => onRemove(item)} />
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!time_limits?.length && (
          <div className='text-center mt-2'>
            <Typography>No Records</Typography>
          </div>
        )}
        {openUpdateRestriction && (
          <SetRestriction
            open={openUpdateRestriction}
            onClose={() => {
              setOpenUpdateRestriction(false);
              setRowToUpdate(null);
            }}
            data={data}
            onSuccess={() => onSuccess(card_number)}
            timeDataToUpdate={rowToUpdate}
            defaultType={1}
          />
        )}
      </div>
      <SBackdrop open={loading}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default TimeRestrictions;
