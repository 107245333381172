import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import northAmericaMap from 'assets/combined-us-canada-mexico-with-states-provinces.geojson';
import Tooltip from 'common/Tooltip';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { getStats } from 'Api/Carriers';
import { DriverGeneral, updateGeneDriver } from 'Api/Driver';

const TravelArea = () => {
  const { id } = useParams();
  const showToaster = useShowToaster();
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [selectedStates, setSelectedStates] = useState([]);
  const [states, setStates] = useState([]);
  const [tooltipContent, setTooltipContent] = useState('');
  const [driver, setDriver] = useState(null);

  const getDriver = async () => {
    try {
      const { data } = await DriverGeneral({ id });
      const details = data.driver_details;
      setDriver(details);
      setSelectedStates(details.operating_states || []);

      const countryIds = [];
      if (details.certifications?.can_travel_usa) {
        countryIds.push('231');
      }
      if (details.certifications?.can_travel_canada) {
        countryIds.push('38');
      }
      if (details.certifications?.can_travel_mexico) {
        countryIds.push('142');
      }

      const params = {
        'country_id[]': countryIds,
      };

      getStats(params).then((res) => {
        const statesWithCountries = res.data
          .map((item) => {
            if (item.id && item.short_name) {
              return item;
            }
            return null;
          })
          .filter((item) => item !== null);

        if (statesWithCountries) {
          if (details.certifications?.can_travel_usa) {
            statesWithCountries.unshift({ short_name: 'All United States', id: 'usa' });
          }
          if (details.certifications?.can_travel_canada) {
            statesWithCountries.unshift({ short_name: 'All Canada', id: 'canada' });
          }
          if (details.certifications?.can_travel_mexico) {
            statesWithCountries.unshift({ short_name: 'All Mexico', id: 'mexico' });
          }
        }

        setStates(statesWithCountries);

        if (details?.all_states_usa) {
          setSelectedStates((prevState) => [{ short_name: 'All United States', id: 'usa' }, ...prevState]);
        }
        if (details?.all_states_canada) {
          setSelectedStates((prevState) => [{ short_name: 'All Canada', id: 'canada' }, ...prevState]);
        }
        if (details?.all_states_mexico) {
          setSelectedStates((prevState) => [{ short_name: 'All Mexico', id: 'mexico' }, ...prevState]);
        }
      });
    } catch (e) {
      // Do nothing
    }
  };

  const updateTravelArea = async (states) => {
    try {
      const params = {
        operating_states: states,
      };
      const { data } = await updateGeneDriver(params, driver.id);
      setDriver((prevState) => ({ ...prevState, operating_states: data.operating_states }));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Some' });
    }
  };

  const toggleState = (stateId) => {
    const partToCheck = stateId.includes('-') ? stateId.split('-').pop() : stateId;
    const country = stateId.includes('-') ? stateId.split('-')?.[0] : stateId;

    const checkCountryId = country === 'US' ? 1 : country === 'CA' ? 2 : 3;

    if (
      (driver?.certifications?.can_travel_usa && checkCountryId === 1) ||
      (driver?.certifications?.can_travel_canada && checkCountryId === 2) ||
      (driver?.certifications?.can_travel_mexico && checkCountryId === 3)
    ) {
      const currentState = states.find((state) => state.short_name === partToCheck);
      setSelectedStates((prev) => {
        const isStatePresent = prev.some((item) => item.id === currentState.id);

        const newState = isStatePresent ? prev.filter((item) => item.id !== currentState.id) : [...prev, currentState];

        const stateIds = newState?.map((item) => item.id)?.filter((i) => Number(i));
        updateTravelArea(stateIds);
        return isStatePresent ? prev.filter((item) => item.id !== currentState.id) : [...prev, currentState];
      });
    } else {
      showToaster({ type: 'error', message: 'Driver can not travel to that country' });
    }
  };

  const handleStateChange = (value, reason, details) => {
    const updateStatesList = (countryId, countryName, country_id) => {
      if (reason === 'selectOption' && details.option.id === countryId) {
        return states.filter(
          (item) => item.country?.name === countryName && selectedStates.every((i) => item.id !== i.id)
        );
      }
      if (reason === 'removeOption' && details.option.id === countryId) {
        value = value.filter((item) => (item.country_id || item.country?.id) !== country_id);
        return [];
      }
      return [];
    };

    const allUsaStates = updateStatesList('usa', 'United States', 231);
    const allCanadaStates = updateStatesList('canada', 'Canada', 38);
    const allMexicoStates = updateStatesList('mexico', 'Mexico', 142);

    const mergedValues = [...value, ...allUsaStates, ...allCanadaStates, ...allMexicoStates];
    setSelectedStates(mergedValues);
    const stateIds = mergedValues.map((item) => item.id).filter((i) => Number(i));
    updateTravelArea(stateIds);
  };

  useEffect(() => {
    getDriver();
  }, []);

  return (
    <div className='d-flex flex-column gap-4 mt-4'>
      <div className='mt-2'>
        <Autocomplete
          width='500px'
          label='Please select where the driver operates'
          labelKey='short_name'
          multiple
          options={states}
          placeholder={selectedStates?.length ? '' : 'Select States'}
          value={selectedStates}
          onChange={(e, val, reason, details) => handleStateChange(val, reason, details)}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          disabled={hideSensitiveInfo}
        />
      </div>
      <ComposableMap
        projection='geoMercator'
        projectionConfig={{
          scale: 200,
          center: [-100, 60],
        }}
        style={{
          width: '100%',
          height: 'auto',
          margin: '0',
          display: 'block',
        }}
      >
        <Tooltip
          title={tooltipContent}
          placement='left'
          arrow={false}
          componentsProps={{
            placement: 'left',
            tooltip: {
              sx: {
                fontSize: 16,
                fontFamily: 'Inter',
                whiteSpace: 'nowrap',
                maxWidth: 'unset',
              },
            },
          }}
        >
          <Geographies geography={northAmericaMap}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const partToCheck = geo.id?.includes('-') ? geo.id?.split('-').pop() : geo?.id;
                const isStateSelected = selectedStates.some((item) => item.short_name === partToCheck);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => {
                      if (hideSensitiveInfo) {
                        return;
                      }
                      toggleState(geo.id);
                    }}
                    onMouseEnter={() => {
                      const { name } = geo.properties;
                      setTooltipContent(name);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                    style={{
                      default: {
                        fill: isStateSelected ? palette.indigo500 : palette.gray700,
                        stroke: '#FFF',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                      hover: {
                        fill: palette.indigo200,
                        stroke: '#FFF',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                      pressed: {
                        fill: palette.indigo500,
                        stroke: '#FFF',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </Tooltip>
      </ComposableMap>
    </div>
  );
};

export default TravelArea;
