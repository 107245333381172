import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPickerWrapper = styled.div`
  width: ${({ $width }) => $width || '100%'};

  .react-datepicker-wrapper {
    width: 100% !important;

    .custom-date-picker-input {
      width: 100%;
      justify-content: flex-start;
      column-gap: 8px;
      margin: 0;
      height: ${({ size }) => (size === 'small' ? '26px' : '32px')};
      background-color: ${({ $disabled }) => ($disabled ? `${palette.gray0} !important` : '')};
      border-radius: ${({ $borderRadius }) => $borderRadius || '6px'};
    }
  }

  .react-datepicker-popper button.react-datepicker__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 24px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
    border-radius: 4px;
    margin: 4px 20px 0;
    background-color: var(--white);
  }

  .disabled-date-picker {
    background-color: ${palette.gray0} !important;

    span {
      color: ${palette.gray400} !important;
    }
  }

  .react-datepicker__year-dropdown {
    background-color: ${palette.white};

    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 8px;
    }

    .react-datepicker__year-option:first-of-type,
    .react-datepicker__year-option:last-of-type {
      height: 22px;
      position: relative;
    }

    .react-datepicker__year-option {
      background-color: ${palette.white};
      padding: 2px 4px;
      font-size: 14px;

      &:hover {
        background-color: ${palette.gray50};
      }

      &.react-datepicker__year-option--selected_year {
        background-color: ${palette.indigo500};
        color: ${palette.white};
      }
    }

    .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation {
      width: 20px;
      height: 16px;
      background-size: 14px !important;
    }
  }

  .react-datepicker__input-time-container {
    margin: 0;

    input.react-datepicker-time__input {
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid ${palette.gray100};
    }
  }

  .react-datepicker__day--today {
    border-radius: 6px;
    background-color: ${palette.indigo0};
    color: ${palette.indigo500};
  }

  .react-datepicker__day--selected {
    color: ${palette.white};
  }
`;

export const SYearMonthPicker = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: ${({ $width }) => $width || '100%'};
  box-shadow: ${palette.boxShadow2};
  border-radius: ${({ $borderRadius }) => $borderRadius || '6px'};
  font-size: 14px;
  padding: 2px 8px;
  background: ${palette.white};

  .react-datepicker-wrapper {
    width: 100% !important;

    input {
      padding: 0;
      width: 100% !important;
      box-shadow: none;
    }
  }
`;
