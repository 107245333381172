import React, { useState } from 'react';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import { updateCard, updateCardInfos } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { typeOptions } from './UpdateInfos.data';
import { SCustomModal } from '../../Cards.styles';

const UpdateInfos = ({ open, onClose, onSuccess, cardDetails, data }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const { cardNumber, infos } = cardDetails || {};
  const { user, require_dr_id, require_eq_id, require_dr_name } = data || {};

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const driverIdInfo = infos?.find((i) => i.infoId === 'DRID');
      const unitInfo = infos?.find((i) => i.infoId === 'UNIT');
      const nameInfo = infos?.find((i) => i.infoId === 'NAME');
      const updateCardBody = {
        card_number: cardNumber,
        require_dr_id: values.type.key === 'user_id' ? 1 : require_dr_id,
        require_eq_id: values.type.key === 'unit' ? 1 : require_eq_id,
        require_dr_name: values.type.key === 'name' ? 1 : require_dr_name,
      };
      const body = {
        cardNumber,
        user_id: driverIdInfo?.matchValue || null,
        unit: unitInfo?.matchValue || null,
        name: nameInfo?.matchValue || null,
        [values.type.key]: values.value,
      };
      await updateCard(updateCardBody);
      await updateCardInfos(body);
      showToaster({ type: 'success', message: 'Card required ids has been successfully updated' });
      onSuccess(cardNumber);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      type: null,
      value: '',
    },
    onSubmit,
    validationSchema,
  });

  const onTypeChange = (val) => {
    if (user) {
      if (val.key === 'user_id') {
        handleChange('value', user.custom_id?.toString() || '');
      }
      if (val.key === 'name') {
        handleChange('value', `${user.first_name} ${user.last_name}` || '');
      }
      if (val.key === 'unit') {
        handleChange('value', user.equipment?.[0]?.equipment_id?.toString() || '');
      }
    }
    handleChange('type', val);
  };

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Update Required IDs'
      $bgColor={palette.gray0}
      $maxWidth='440px'
      $minWidth='440px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <div>
          <Autocomplete
            required
            name='type'
            label='Type'
            options={typeOptions}
            value={values.type}
            onChange={(e, val) => onTypeChange(val)}
            error={touchedErrors.type}
          />
        </div>
        <Input
          required
          name='value'
          label='Exact Value'
          onChange={handleChange}
          value={values.value}
          placeholder='Exact Value'
          error={touchedErrors.value}
        />
      </div>
    </SCustomModal>
  );
};

export default UpdateInfos;
