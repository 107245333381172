import moment from 'moment';
import { palette } from 'utils/constants';

export const appearance = {
  theme: 'flat',

  variables: {
    colorPrimary: palette.indigo500,
    colorBackground: palette.white,
    colorText: palette.gray900,
    colorDanger: palette.red500,
    fontFamily: 'Inter, sans-serif',
    spacingUnit: '3px',
    borderRadius: '6px',
  },
};

export const getDueDate = (date) => {
  if (moment().startOf('day').isAfter(moment(date).startOf('day'))) {
    const diff = moment().diff(moment(date), 'days');

    return `${diff} day${diff === 1 ? '' : 's'} overdue`;
  }

  return `Due on ${moment(date).format('MM/DD/YYYY')}`;
};
