import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { getAccountsList } from 'Api/Accounts';
import { createTax, deleteTax, getTaxesList, updateTax } from 'Api/PayrollSettings';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import FormItem from '../FormItem';
import { SWrapper, SAddMore, SActions, STableRow, STable } from '../Payroll.styles';

const Taxes = ({ isModalView, isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [accounts, setAccounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const getTaxes = async () => {
    try {
      const { data: accounts } = await getAccountsList();
      const { data } = await getTaxesList();
      setTaxes(data.map((item) => ({ ...item, account: accounts.find((i) => i.id === item.account_id) || null })));
      setAccounts(accounts.filter((i) => i.account_type.id === ACCOUNT_TYPE.CURRENT_LIABILITY));
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createNewTax = async (values, id) => {
    setLoadingCreate(true);
    try {
      const data = { tax_type: values.type, percent: values.amount, account_id: values.account?.id || 0 };
      await createTax(data);
      await getTaxes();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Tax has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const editTax = async (values, id) => {
    setLoadingCreate(true);
    try {
      const data = { tax_type: values.type, percent: values.amount, account_id: values.account?.id || 0 };
      await updateTax(id, data);
      await getTaxes();
      showToaster({ type: 'success', message: 'Tax has been successfully updated!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const deleteItem = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteTax(id);
      const newTaxes = taxes.filter((item) => item.id !== id);
      setTaxes(newTaxes);
      showToaster({ type: 'success', message: 'Tax has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingDelete(false);
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const onEditClick = (id, isCancel) => {
    setTaxes((prevState) => prevState.map((item) => (item.id === id ? { ...item, isEdit: !isCancel } : item)));
  };

  useEffect(() => {
    if (!isModalView) {
      window.scrollTo(0, 0);
    }
    setLoading(true);
    getTaxes();
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      {!isModalView && (
        <PageHeader
          hideSave
          title='Taxes'
          isConfiguringStep={isConfiguringStep}
          onCancelPrevPage={onCancelPrevPage}
          onSaveAndNext={onSaveAndNext}
        />
      )}
      <STable>
        <thead>
          <tr>
            <th>TYPE OF TAX</th>
            <th>PERCENT</th>
            <th>ACCOUNT</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {taxes.map((item) =>
            item.isEdit ? (
              <FormItem
                key={item.id}
                data={item}
                accounts={accounts}
                onSubmit={(values) => editTax(values, item.id)}
                onDelete={() => deleteFormItem(item.id)}
                loading={loadingCreate}
                showPercent
              />
            ) : (
              <STableRow key={item.id}>
                <td>
                  <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                    {item.tax_type}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    {item.percent}%
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    {item.account?.account_name || '-'}
                  </Typography>
                </td>
                <td className='actions-cell'>
                  <SActions>
                    {loadingDelete ? (
                      <span className='loading-wrapper'>
                        <CircularProgress size={16} />
                      </span>
                    ) : (
                      <>
                        <span className='action-wrapper' onClick={() => onEditClick(item.id)}>
                          <EditIcon width={13} height={13} />
                        </span>
                        <span className='action-wrapper' onClick={() => deleteItem(item.id)}>
                          <DeleteIcon width={11} height={11} fill={palette.red500} />
                        </span>
                      </>
                    )}
                  </SActions>
                </td>
              </STableRow>
            )
          )}
          {forms.map((item) => (
            <FormItem
              key={item.id}
              accounts={accounts}
              onSubmit={(values) => createNewTax(values, item.id)}
              onDelete={() => deleteFormItem(item.id)}
              loading={loadingCreate}
              showPercent
            />
          ))}
        </tbody>
      </STable>
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
          Add Another...
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default Taxes;
