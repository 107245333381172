export const getRequiredDocs = (requiredDocs, shipmentStops) => {
  if (!requiredDocs.length) {
    return [];
  }

  const bolDocument = requiredDocs.find((doc) => doc.type === 'Bill of Lading' || doc.type === 'Proof of Delivery');

  if (!bolDocument) {
    return requiredDocs;
  }

  const docsWithoutBol = requiredDocs.filter(
    (doc) => doc.type !== 'Bill of Lading' && doc.type !== 'Proof of Delivery'
  );

  const deliveries = shipmentStops.filter((el) => Number(el.stop_point_type) === 2);

  const bolDocumentsForDeliveries = deliveries.map((delivery) => ({
    ...bolDocument,
    type: `${bolDocument.type} - ${delivery.stop_point.location_name}`,
    stopId: delivery.id,
  }));

  return [...docsWithoutBol, ...bolDocumentsForDeliveries];
};
