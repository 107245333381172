import React, { useState } from 'react';
import { ModalBody, Toast } from 'react-bootstrap';
import axios from 'axios';
import authHeader from 'services/auth-header';
import Select from 'react-select';

import { useQuery } from 'react-query';
import { useFormik } from 'formik';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import './addhook.css';

const AddHook = ({
  handleClose,
  id,
  SuccessShow,
  HookedErrShow,
  HookedErrClose,
  GetHookedError,
  SuccessClose,
  onSuccess = () => null,
}) => {
  const [showA, setShowA] = useState(false);
  const [resErr, setresErr] = useState();
  const toggleClose = () => setShowA(false);
  const { use } = useTheme();
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      height: 32,
      minHeight: 32,
      minWidth: 250,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
    }),
  };
  GetHookedError(resErr);
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      try {
        await axios
          .put(`${API_URL}/equipment/${id}`, values, {
            headers: authHeader(),
          })
          .then((res) => {
            if (res?.status === 200) {
              onSuccess();
              handleClose();
              SuccessShow(res.data.data);
              setTimeout(() => {
                SuccessClose(id);
              }, 3000);
            }
          });
      } catch (err) {
        if (err?.response?.data?.response?.hooked_to_id) {
          setresErr(err?.response?.data?.response?.hooked_to_id.join(' '));
          handleClose();
          HookedErrShow();
        }
        setTimeout(() => {
          HookedErrClose();
        }, 3000);
      }
    },
  });

  const API_URL = process.env.REACT_APP_API_URL;
  async function Trailer() {
    const { data } = await axios.get(`${API_URL}/equipment/hook-to/${id}`, {
      headers: authHeader(),
    });
    return data;
  }

  const { data, isError, isLoading, error } = useQuery('hooked', Trailer);
  if (isLoading) {
    return <span className='text-style'>Loading...</span>;
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }
  let arr = [];
  if (data) {
    const vehicleData = data?.data?.filter((v) => {
      return ['Available', 'Not Assigned'].includes(v?.equipment_status?.equipment_status);
    });
    arr = vehicleData.map((t) => ({
      label: `${t.equipment_id} (${t.make ? t.make : ''} ${t.model_id ? t.model_id : ''})`,
      value: t.id,
    }));
  }
  const handleChange = (e) => {
    // const val = e.map(v=>(v.value))
    // const t =val[0]
    formik.setFieldValue('hooked_to_id', e?.value);
  };
  return (
    <>
      <div className='equipment-list-add-hook' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        <ModalBody className='p-0'>
          <form onSubmit={formik.handleSubmit}>
            <div className='hook-trailer-modal-header'>
              <h6
                className='hook-trailer-modal-heading mt-3 ms-3'
                style={{ color: use(palette.dark800, palette.gray200) }}
              >
                Hook Vehicle
              </h6>
            </div>
            <div
              className='hook-trailer-modal-body'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              <p className='hook-trailer-modal-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {' '}
                Vehicle*
              </p>
              <div className='select-trailer-list mt-0'>
                <Select options={arr} type='checkbox' name='arr' onChange={handleChange} styles={customStyles} />
              </div>
            </div>
            <div className='add-driver-modal-footer d-flex justify-content-end'>
              <button
                type='button'
                className='hook-trailer-modal-cancel-button'
                onClick={handleClose}
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  color: use(palette.dark800, palette.gray200),
                }}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='hook-trailer-modal-hook-vehicle-button'
                // onClick={()=>handleClick()}
              >
                Hook Vehicle
              </button>
            </div>
          </form>
        </ModalBody>
      </div>

      <Toast show={showA} onClose={toggleClose} className='errToast'>
        <Toast.Header className='errText'>{resErr}</Toast.Header>
      </Toast>
    </>
  );
};

export default AddHook;
