import React, { useState } from 'react';
import { BackdropLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { updateVendorStatus } from 'Api/Vendors';
import Bills from './Bills';
import VendorDetails from './VendorDetails';
import VendorActions from './VendorActions';
import { SWrapper } from './RightSection.styles';
import { SHeaderWrapper } from '../Vendors.styles';

const RightSection = ({ vendor, onEdit, refreshList }) => {
  const showToaster = useShowToaster();
  const { id, name, active } = vendor ?? {};
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onUpdateStatus = async () => {
    setLoadingUpdate(true);
    try {
      const formData = new FormData();
      formData.append('active', Number(!active));
      await updateVendorStatus(id, formData);
      showToaster({ type: 'success', message: 'Vendor status has been successfully updated!' });
      refreshList();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  return (
    <SWrapper>
      {vendor && (
        <>
          <SHeaderWrapper>
            <div className='d-flex gap-4 align-items-center'>
              <Typography variant='h3'>{name}</Typography>
            </div>
            <div className='right-section'>
              <VendorActions vendor={vendor} onEdit={onEdit} onUpdateStatus={onUpdateStatus} />
            </div>
          </SHeaderWrapper>
          <VendorDetails vendor={vendor} />
          <Bills vendor={vendor} refreshList={refreshList} key={vendor?.id} />
        </>
      )}
      <BackdropLoader loading={loadingUpdate} />
    </SWrapper>
  );
};

export default RightSection;
