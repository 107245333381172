import React, { useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import Chip from 'common/Chip';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette } from 'utils/constants';
import { getDueDate } from '../../InvoicePayment.data';
import { SWrapper } from './InvoiceInfo.styles';

const InvoiceInfo = ({ companyInfo, invoice }) => {
  const showToaster = useShowToaster();
  const matches = useMediaQuery('(max-width:1000px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoiceToShow, setInvoiceToShow] = useState(null);
  const tryAgain = searchParams.get('retry');
  const invoiceData = invoice?.invoice || invoice?.batch;

  const isPaid = invoice?.due_amount === 0;
  const isFiled = !isPaid && tryAgain !== '1' && invoiceData?.stripe_payment_status === 'requires_payment_method';
  const isProcessing = !isPaid && invoiceData?.stripe_payment_status === 'processing';

  const onPdfClick = (invoice) => {
    if (matches) {
      fetch(invoice.pdf_file_link, { mode: 'cors' })
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp]);
          const link = document.createElement('a');
          const url = URL.createObjectURL(file);
          link.setAttribute('download', `${invoice.invoice_id}.pdf`);
          link.href = url;
          link.click();
        });
      return;
    }

    setInvoiceToShow(invoice);
  };

  const onTryAgain = () => {
    searchParams.set('retry', '1');
    setSearchParams(searchParams);
  };

  return (
    <SWrapper>
      <div>
        <div className='mb-4'>
          {companyInfo?.logo_path && <img src={companyInfo.logo_path} alt='Company logo' width={120} height={48} />}
        </div>
        <Typography variant='s2' style={{ color: palette.gray500 }}>
          Pay {companyInfo?.company_name}
        </Typography>
        {isPaid && (
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-2 mt-2'>
              <Chip
                size='medium'
                label={`Paid on ${moment(invoiceData?.processed_at).format('MM/DD/YYYY')} ${
                  invoiceData.pay_reference ? `(Reference *****${invoiceData.pay_reference?.slice(-5)})` : ''
                }`}
                bgColor={palette.green0}
                labelColor={palette.green500}
                fontSize='14px'
                fontWeight={500}
                padding='4px 12px'
              />
              {!!invoiceData.pay_reference && (
                <CopyIcon
                  width={12}
                  height={12}
                  fill={palette.gray500}
                  className='pointer'
                  onClick={() => {
                    navigator.clipboard.writeText(invoiceData.pay_reference).then(() => {
                      showToaster({ type: 'success', message: 'Reference ID is successfully copied!' });
                    });
                  }}
                />
              )}
            </div>
            <div
              className='print-wrapper'
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <PrintIcon />
            </div>
          </div>
        )}
        {isProcessing && (
          <div className='mt-2'>
            <Chip
              size='medium'
              label='Processing ACH Payment! This may take 1 to 2 business days to process'
              bgColor={palette.indigo50}
              labelColor={palette.indigo500}
              fontSize='14px'
              fontWeight={500}
              padding='4px 12px'
            />
          </div>
        )}
        {isFiled && (
          <div className='d-flex align-items-center gap-2 mt-2'>
            <Chip
              size='medium'
              label='Payment Declined'
              bgColor={palette.red0}
              labelColor={palette.red500}
              fontSize='14px'
              fontWeight={500}
              padding='4px 12px'
            />
            <span className='try-again' onClick={onTryAgain}>
              Try Again
            </span>
          </div>
        )}
        {!isPaid && !isProcessing && (
          <div className='d-flex flex-column align-items-start gap-1 mt-2'>
            <Typography variant='h1'>{`$${formatNumber(invoice?.due_amount)}`}</Typography>
            <Typography variant='c3'>{`${getDueDate(invoiceData?.due_date)}`}</Typography>
          </div>
        )}

        <div className='invoices-wrapper'>
          {invoice?.batch_id && (
            <div className='mb-3 ps-2 pe-2'>
              <Typography variant='s2'>Batch {invoice.batch_id}</Typography>
            </div>
          )}
          {(invoice?.invoices || [invoice])?.map((item) => (
            <div
              className='d-flex justify-content-between align-items-center invoice-wrapper'
              onClick={() => onPdfClick(item.invoice)}
              key={item.invoice_id}
            >
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='s2'>Invoice {item.invoice_id}</Typography>{' '}
                <Typography variant='button2' style={{ color: palette.red400, cursor: 'pointer' }}>
                  PDF
                </Typography>
              </div>
              <Typography variant='s2'>${formatNumber(isPaid ? item.paid_amount : item.due_amount)}</Typography>
            </div>
          ))}
        </div>
        {isPaid && (
          <div className='d-flex justify-content-between align-items-center ms-2 me-2 mb-2'>
            <Typography variant='s2'>Payment</Typography>{' '}
            <Typography variant='s2'>-${formatNumber(invoice?.paid_amount)}</Typography>
          </div>
        )}
        <div className='d-flex justify-content-between align-items-center ms-2 me-2'>
          <Typography variant='s2'>Total Due</Typography>{' '}
          <Typography variant='s2'>${formatNumber(invoice?.due_amount)}</Typography>
        </div>
        <div className='invoice-info-footer'>
          <img src={truckinLogo} alt='Truckin digital logo' width={32} height={32} />
          <Typography variant='s2' className='gray-text ml-1'>
            Powered by{' '}
            <a className='blue-link' href='https://www.truckindigital.com'>
              Truckin Digital
            </a>
          </Typography>
        </div>
      </div>
      <ViewPdf
        open={!!invoiceToShow}
        title={`Invoice ${invoiceToShow?.invoice_id}`}
        pdfUrl={invoiceToShow?.pdf_file_link}
        onClose={() => setInvoiceToShow(null)}
      />
    </SWrapper>
  );
};

export default InvoiceInfo;
