import React from 'react';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/exportIcon.svg';
import { palette } from 'utils/constants';
import CustomButton from 'components/CustomButton/CustomButton';
import { onExportClick } from 'pages/Accounting/Receivables/TableSection/TableSection.data';
import { SWrapper } from './BulkActions.styles';

const BulkActions = ({ data, onBulkSendInvoice }) => {
  return (
    <SWrapper>
      <CustomButton
        type='primary'
        title='Send Invoices'
        onClick={onBulkSendInvoice}
        leftIcon={<SendIcon fill={palette.white} style={{ marginRight: 10 }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
      />
      <CustomButton
        type='secondary'
        title='Export CSV'
        leftIcon={<ExportIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
        onClick={() => onExportClick(data, 'ready')}
      />
    </SWrapper>
  );
};

export default BulkActions;
