import moment from 'moment';

export const getSettingsData = (settings, documentType, checked) => {
  switch (documentType) {
    case 2:
      return {
        document_exp_date: settings.physical_insurance_expire_date
          ? moment(settings.physical_insurance_expire_date).toDate()
          : null,
        no_expire: !settings.physical_insurance_expire_date,
        document: settings.physical_insurance_policy_attach,
        reference_id: settings.physical_insurance_policy_id,
        use_from_policy: checked,
      };
    case 3:
      return {
        document_exp_date: settings.liability_insurance_expire_date
          ? moment(settings.liability_insurance_expire_date).toDate()
          : null,
        no_expire: !settings.liability_insurance_expire_date,
        document: settings.liability_insurance_policy_attach,
        reference_id: settings.liability_insurance_policy_id,
        use_from_policy: checked,
      };
    case 4:
      return {
        document_exp_date: settings.motor_cargo_insurance_expire_date
          ? moment(settings.motor_cargo_insurance_expire_date).toDate()
          : null,
        no_expire: !settings.motor_cargo_insurance_expire_date,
        document: settings.motor_cargo_insurance_policy_attach,
        reference_id: settings.motor_cargo_insurance_policy_id,
        use_from_policy: checked,
      };

    case 5:
      return {
        document_exp_date: settings.general_liability_insurance_expire_date
          ? moment(settings.general_liability_insurance_expire_date).toDate()
          : null,
        no_expire: !settings.general_liability_insurance_expire_date,
        document: settings.general_liability_insurance_policy_attach,
        reference_id: settings.general_liability_insurance_policy_id,
        use_from_policy: checked,
      };

    case 6:
      return {
        document_exp_date: settings.ifta_sticker_expire_date
          ? moment(settings.ifta_sticker_expire_date).toDate()
          : null,
        no_expire: !settings.ifta_sticker_expire_date,
        document: settings.ifta_sticker_attach,
        reference_id: settings.ifta_sticker_no,
        use_from_policy: checked,
      };

    case 7:
      return {
        document_exp_date: settings.highway_tax_expire_date ? moment(settings.highway_tax_expire_date).toDate() : null,
        no_expire: !settings.highway_tax_expire_date,
        document: settings.highway_tax_date_attach,
        use_from_policy: checked,
      };

    case 8:
      return {
        document: settings.kyu_attach,
        reference_id: settings.kyu_id,
        use_from_policy: checked,
      };
    default:
      return {};
  }
};

export const hasNoSettings = [1, 9, 10];
