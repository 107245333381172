export const customersMapper = {
  'Custom ID': 'customer_id',
  'General Ledger ID': 'general_ledger_id',
  'Company Name': 'company_name',
  'Phone Number': 'telephone_number',
  DOT: 'company_dot_mc',
  Address1: 'address1',
  Address2: 'address2',
  Country: 'country',
  State: 'state',
  City: 'city',
  Zip: 'zipcode',
  'Invoice Pay Terms': 'invoice_pay_term',
  'Invoice Notes': 'invoice_note',
  'Require Signature on Stops': 'require_signature_on_stop',
  'Require Bill of Lading': 'require_bill_of_lading',
  'Require Confirmations': 'require_confirmations',
  'Detention Start After Hour': 'detention_starts_after_hour',
  'Billed At': 'billed_at',
  'Detention Trailer Dropped Start After': 'detention_trailer_dropped_starts_after',
  'Detention Trailer Dropped Start After Per': 'detention_trailer_dropped_start_after_per',
  'Detention Trailer Dropped Billed At': 'detention_trailer_dropped_billed_at',
  'Credit Line Maximum': 'credit_line_maximum',
  Account: 'account',
};

export const checkBooleanValue = (val) => {
  if (val.toLowerCase().trim() === 'yes' || val === '1') {
    return 1;
  }
  return 0;
};

export const convertPayTerm = (val) => {
  const modifiedVal = val?.replace(/\s/g, '').toLowerCase();
  if (modifiedVal === '30days' || modifiedVal === '30') {
    return 1;
  }
  if (modifiedVal === '60days' || modifiedVal === '60') {
    return 2;
  }
  if (modifiedVal === '90days' || modifiedVal === '90') {
    return 3;
  }
  return 1;
};

export const convertStartsAfterPer = (val) => {
  const modifiedVal = val?.replace(/\s/g, '').toLowerCase();
  if (modifiedVal === 'day' || modifiedVal === 'days') {
    return 'DAYS';
  }
  if (modifiedVal === 'hour' || modifiedVal === 'hours') {
    return 'HOURS';
  }
  return 'HOURS';
};
