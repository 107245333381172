import React, { useEffect, useState } from 'react';
import XIcon from '../../../assets/icons/x.png';
import SMSModal from '../../../components/chatAppComponents/SMSModal';
import AddNewChannel from '../../../components/chatAppComponents/AddNewChannel/AddNewChannel';
import MessageComponent from './MessageComponent';
import MembersComp from './MembersComp';

const GroupMembers = ({
  channelMembers,
  setShowMember,
  showMember,
  users,
  showShedulemsg,
  messageData,
  getSeduleMessage,
  chatUser,
  dot_num,
}) => {
  const [addnewChannelModal, setaddnewChannelModal] = useState(false);
  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [user, setuser] = useState(false);

  const userList = users?.filter((u) => u.role === 'staff');

  useEffect(() => {
    if (showShedulemsg && messageData.length === 0) {
      setShowMember(false);
    }
  }, [channelMembers, showMember, showShedulemsg]);

  return (
    <>
      {addnewChannelModal && (
        <AddNewChannel
          setAddNewModal={setaddnewChannelModal}
          chatUser={chatUser}
          userList={userList}
          selectedStaff={selectedStaff}
          dot_num={dot_num}
          staff={user}
          setstaff={setuser}
          setSmsModalOpen={setSmsModalOpen}
        />
      )}
      {smsModalOpen && <SMSModal setOpen={setSmsModalOpen} />}

      <div
        className='group_members'
        style={{
          width: showMember ? '25%' : '0px',
          padding: showMember ? '' : '0px',
        }}
      >
        <p className='group_member_heading'>
          {!showShedulemsg && showMember ? 'Group Members' : ' Scheduled Message(s)'}
          <img src={XIcon} alt='close' onClick={() => setShowMember(false)} />
        </p>

        {!showShedulemsg && showMember && (
          <div className='group_user_list'>
            {channelMembers?.map((m) => (
              <div className='group_user' key={m.id}>
                <MembersComp
                  m={m}
                  users={users}
                  setaddnewChannelModal={setaddnewChannelModal}
                  setSelectedStaff={setSelectedStaff}
                  setuser={setuser}
                />
              </div>
            ))}
          </div>
        )}
        {showShedulemsg && (
          <div className='group_user_list'>
            {messageData.map((m) => (
              <div className='scheduled_msg' key={m.id}>
                <MessageComponent m={m} getSeduleMessage={getSeduleMessage} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default GroupMembers;
