import moment from 'moment';

export const addEmploymentDataConverter = (data, userType, userId) => {
  const formData = new FormData();
  formData.append('driver_staff_id', userId);
  formData.append('user_type', userType);
  if (!data.isNew && data.id) {
    formData.append('id', data.id);
  }
  formData.append('name[]', data.name);
  formData.append('start_date[]', moment(data.start_date).format('MM/DD/YYYY'));
  formData.append('end_date[]', moment(data.end_date).format('MM/DD/YYYY'));

  data.attempts.forEach((attempt, i) => {
    if (!attempt.isNew && Number(attempt.id)) {
      formData.append(`attempts[${i}][id]`, attempt.id);
    }
    formData.append(`attempts[${i}][request_date]`, moment(attempt.request_date).format('MM/DD/YYYY'));
    formData.append(`attempts[${i}][request_form_source]`, attempt.request_form_source);
    formData.append(`attempts[${i}][response]`, attempt.response);
    if (attempt.request_form_source === 'Email') {
      formData.append(`attempts[${i}][email]`, attempt.email);
    }
    if (attempt.request_form_source === 'Fax') {
      formData.append(`attempts[${i}][fax]`, attempt.fax);
    }
    formData.append(`attempts[${i}][request_type]`, attempt.request_type);
    if (attempt.request_type === 'Pdf') {
      formData.append(`attempts[${i}][request_form_path]`, attempt.request_form_path);
    }
    if (attempt.response === 'Verified') {
      formData.append(`attempts[${i}][response_path]`, attempt.response_path);
    }
    if (attempt.haveNote) {
      formData.append(`attempts[${i}][notes][0][title]`, attempt.noteTitle);
      formData.append(`attempts[${i}][notes][0][date]`, moment(attempt.noteDate).format('MM/DD/YYYY'));
      formData.append(`attempts[${i}][notes][0][notes]`, attempt.noteNotes);
    }
  });

  return formData;
};
