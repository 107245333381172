import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { APPLICANT_REQUIREMENTS, palette } from 'utils/constants';
import {
  createOther,
  updateOther,
  createBasicInfo,
  updateBasicInfo,
  getCustomerInfo,
  createJobPosition,
  updateJobPosition,
  getOpenJobPosition,
  createStaffLicense,
  createDriverLicense,
  updateStaffLicense,
  updateDriverLicense,
  createApplicantInfo,
  updateApplicantInfo,
  createMilitaryHistory,
  updateMilitaryHistory,
  createPreviousEmployment,
  updatePreviousEmployment,
  createMedicalExaminerCard,
  updateMedicalExaminerCard,
  updateEducation,
  createEducation,
  updateTrafficAccident,
  createTrafficAccident,
} from 'Api/JobPositions';
import Other from './steps/Other';
import Final from './steps/Final';
import Position from './steps/Position';
import Education from './steps/Education';
import BasicInfo from './steps/BasicInfo';
import MedicalCard from './steps/MedicalCard';
import StaffLicense from './steps/StaffLicense';
import Requirements from './steps/Requirements';
import ApplicantInfo from './steps/ApplicantInfo';
import DriverLicense from './steps/DriverLicense';
import Intro, { infoOptions } from './steps/Intro';
import MilitaryHistory from './steps/MilitaryHistory';
import TrafficAccidents from './steps/TrafficAccidents';
import PreviousEmployment from './steps/PreviousEmployment';
import StepTitle from './components/StepTitle';
import ApplyJobHeader from './components/ApplyJobHeader';
import ApplyJobFooter from './components/ApplyJobFooter';
import {
  otherConvertor,
  positionConvertor,
  basicInfoConvertor,
  medicalCardConvertor,
  staffLicenseConvertor,
  applicantInfoConvertor,
  driverLicenseConvertor,
  createMilitaryConvertor,
  updateMilitaryConvertor,
  previousEmploymentConvertor,
  updateEducationConvertor,
  createEducationConvertor,
  createTrafficAccidentConvertor,
  updateTrafficAccidentConvertor,
} from './ApplyJob.convertors';
import {
  otherValidationSchema,
  militaryValidationSchema,
  positionValidationSchema,
  basicInfoValidationSchema,
  medicalCardValidationSchema,
  applicantInfoValidationSchema,
  staffLicenseValidationSchema,
  driverLicenseValidationSchema,
  previousEmploymentValidationSchema,
  educationValidationSchema,
  trafficAccidentsValidationSchema,
} from './validationSchemas';
import {
  introInitialValues,
  otherInitialValues,
  militaryInitialValues,
  positionInitialValues,
  basicInfoInitialValues,
  medicalCardInitialValues,
  applicantInfoInitialValues,
  staffLicenseInitialValues,
  driverLicenseInitialValues,
  previousEmploymentInitialValues,
  checkHomeAddressDates,
  educationInitialValues,
  trafficAccidentsInitialValues,
} from './ApplyJob.data';
import { SWrapper, SStepperContent, ScrollableContainer } from './ApplyJob.styles';

const ApplyJob = () => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const matches = useMediaQuery('(max-width:900px)');
  const [job, setJob] = useState(null);
  const [requirements, setRequirements] = useState([]);
  const [company, setCompany] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [jobApplication, setJobApplication] = useState(null);
  const [jobPosition, setJobPosition] = useState(null);
  const [applicantInfo, setApplicantInfo] = useState(null);
  const [driverLicense, setDriverLicense] = useState(null);
  const [staffLicense, setStaffLicense] = useState(null);
  const [medicalCard, setMedicalCard] = useState(null);
  const [previousEmployments, setPreviousEmployments] = useState(null);
  const [militaryHistory, setMilitaryHistory] = useState(null);
  const [education, setEducation] = useState(null);
  const [trafficAccidents, setTrafficAccidents] = useState(null);
  const [introCheckboxes, setIntroCheckboxes] = useState(introInitialValues);
  const [introOptions, setIntroOptions] = useState([]);
  const [other, setOther] = useState(null);
  const [questionIds, setQuestionIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { companyId, id } = useParams();
  const [searchParams] = useSearchParams();
  const referral_token = searchParams.get('token');

  const onNext = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const onBack = () => {
    if (currentStep === 0) {
      return;
    }

    setCurrentStep((prevState) => prevState - 1);
  };

  const getPosition = async () => {
    try {
      const response = await getOpenJobPosition(companyId, id);
      setJob(response.data);

      const optionsForIntro = [];
      if (response?.data?.federal_frca_summary_of_rights_acknowledgement) {
        optionsForIntro.push(infoOptions[0]);
      }
      if (response?.data?.psp_disclosure_and_authorization) {
        optionsForIntro.push(infoOptions[1]);
      }
      if (response?.data?.frca_disclosure) {
        optionsForIntro.push(infoOptions[2]);
      }
      if (response?.data?.frca_authorization) {
        optionsForIntro.push(infoOptions[3]);
      }
      if (response?.data?.employment_verification_acknowledgement) {
        optionsForIntro.push(infoOptions[4]);
      }

      setIntroOptions(optionsForIntro);
      setIntroCheckboxes(Array(optionsForIntro.length + 2).fill(false));
      setRequirements(response?.data?.requirements || []);
    } catch (e) {
      showToaster({ message: 'Job is not found', type: 'error' });
      navigate(`/job-positions/${companyId}`);
    }
  };

  const getCompanyInfo = async () => {
    try {
      const response = await getCustomerInfo(companyId);
      setCompany(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleBasicInfoSubmit = async (values) => {
    setLoading(true);
    try {
      if (requirements?.some((requirement) => requirement.title === APPLICANT_REQUIREMENTS.HOME_ADDRESS)) {
        const datePairs = values.addresses
          .filter((address) => !address.noPreviousAddress)
          .map((address) => ({
            start_date: address.start_date,
            end_date: address.end_date,
          }));

        const datesAreCovered = checkHomeAddressDates(datePairs);

        if (!datesAreCovered) {
          showToaster({
            type: 'error',
            message: 'We require 3 years of home address history, please correct your application.',
          });
          return;
        }
      }

      const data = basicInfoConvertor({
        ...values,
        is_send_email: introCheckboxes[introCheckboxes.length - 2] ? 1 : 0,
        is_send_text: introCheckboxes[introCheckboxes.length - 1] ? 1 : 0,
      });
      if (jobApplication) {
        const response = await updateBasicInfo(data, jobApplication.id, companyId);
        setJobApplication(response.data);
      } else {
        const response = await createBasicInfo({ ...data, referral_token }, companyId);
        setJobApplication(response.data);
      }
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const handlePositionSubmit = async (values) => {
    setLoading(true);
    try {
      const data = positionConvertor({ ...values, jobId: jobApplication?.id });
      const response = jobPosition
        ? await updateJobPosition(data, jobPosition.id, companyId)
        : await createJobPosition(data, companyId);
      setJobPosition(response.data);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleApplicantInfoSubmit = async (values) => {
    setLoading(true);
    try {
      const data = applicantInfoConvertor({ ...values, jobId: jobApplication?.id });
      const response = applicantInfo
        ? await updateApplicantInfo(data, applicantInfo.id, companyId)
        : await createApplicantInfo(data, companyId);
      setApplicantInfo(response.data);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleDriverLicenseSubmit = async (values) => {
    setLoading(true);
    try {
      const data = driverLicenseConvertor({ ...values, jobId: jobApplication?.id });
      const response = driverLicense
        ? await updateDriverLicense(data, driverLicense.id, companyId)
        : await createDriverLicense(data, companyId);
      setDriverLicense(response.data);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleStaffLicenseSubmit = async (values) => {
    setLoading(true);
    try {
      const data = staffLicenseConvertor({ ...values, jobId: jobApplication?.id });
      const response = staffLicense
        ? await updateStaffLicense(data, staffLicense.id, companyId)
        : await createStaffLicense(data, companyId);
      setStaffLicense(response.data);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleMedicalCardSubmit = async (values) => {
    setLoading(true);
    try {
      const data = medicalCardConvertor({ ...values, jobId: jobApplication?.id });
      const response = medicalCard
        ? await updateMedicalExaminerCard(data, medicalCard.id, companyId)
        : await createMedicalExaminerCard(data, companyId);
      setMedicalCard(response.data);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handlePreviousEmploymentsSubmit = async (values) => {
    setLoading(true);
    try {
      const { formData, updated, shouldCreate } = previousEmploymentConvertor(
        values,
        jobApplication?.id,
        previousEmployments?.previous_employment
      );
      let response;

      if (shouldCreate) {
        response = await createPreviousEmployment(formData, companyId);
      }
      if (updated?.id?.length) {
        response = await updatePreviousEmployment(updated, companyId);
      }

      if (response) {
        setPreviousEmployments(response.data);
      }
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleMilitarySubmit = async (values) => {
    setLoading(true);
    try {
      let response;

      if (militaryHistory) {
        const data = updateMilitaryConvertor({ ...values, jobId: jobApplication?.id, id: militaryHistory.id });
        response = await updateMilitaryHistory(data, companyId);
      } else {
        const formData = createMilitaryConvertor({ ...values, jobId: jobApplication?.id });
        response = await createMilitaryHistory(formData, companyId);
      }

      setMilitaryHistory(response.data.military);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const handleEducationSubmit = async (values) => {
    setLoading(true);
    try {
      let response;

      if (education) {
        const data = updateEducationConvertor({ ...values, jobId: jobApplication?.id, id: education.id });
        response = await updateEducation(data, companyId);
      } else {
        const formData = createEducationConvertor({ ...values, jobId: jobApplication?.id });
        response = await createEducation(formData, companyId);
      }

      setEducation(response.data.education);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const handleTrafficAccidentsSubmit = async (values) => {
    setLoading(true);
    try {
      let response;

      if (trafficAccidents) {
        const body = updateTrafficAccidentConvertor({ ...values, jobId: jobApplication?.id });
        response = await updateTrafficAccident(body, companyId, trafficAccidents.id);
      } else {
        const formData = createTrafficAccidentConvertor({ ...values, jobId: jobApplication?.id });
        response = await createTrafficAccident(formData, companyId);
      }

      setTrafficAccidents(response.data.history);
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const handleOtherSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.questions?.length > 0) {
        const data = otherConvertor({ ...values, jobId: jobApplication?.id, questionIds });
        const response = other ? await updateOther(data, companyId) : await createOther(data, companyId);
        setOther(response.data);
      }
      onNext();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
    setLoading(false);
  };

  const handleIntroCheckboxChange = (index, checked) => {
    const updatedValues = [...introCheckboxes];
    updatedValues[index] = checked;
    setIntroCheckboxes(updatedValues);
  };

  const basicInfoForm = useForm({
    initialValues: basicInfoInitialValues,
    validationSchema: basicInfoValidationSchema,
    onSubmit: handleBasicInfoSubmit,
  });
  const positionForm = useForm({
    initialValues: positionInitialValues,
    validationSchema: positionValidationSchema,
    onSubmit: handlePositionSubmit,
  });
  const applicantInfoForm = useForm({
    initialValues: applicantInfoInitialValues,
    validationSchema: applicantInfoValidationSchema,
    onSubmit: handleApplicantInfoSubmit,
  });
  const driverLicenseForm = useForm({
    initialValues: driverLicenseInitialValues,
    validationSchema: driverLicenseValidationSchema,
    onSubmit: handleDriverLicenseSubmit,
  });
  const staffLicenseForm = useForm({
    initialValues: staffLicenseInitialValues,
    validationSchema: staffLicenseValidationSchema,
    onSubmit: handleStaffLicenseSubmit,
  });
  const medicalCardForm = useForm({
    initialValues: medicalCardInitialValues,
    validationSchema: medicalCardValidationSchema,
    onSubmit: handleMedicalCardSubmit,
  });

  const previousEmploymentForm = useForm({
    initialValues: previousEmploymentInitialValues,
    validationSchema: previousEmploymentValidationSchema,
    onSubmit: handlePreviousEmploymentsSubmit,
  });

  const militaryForm = useForm({
    initialValues: militaryInitialValues,
    validationSchema: militaryValidationSchema,
    onSubmit: handleMilitarySubmit,
  });

  const educationForm = useForm({
    initialValues: educationInitialValues,
    validationSchema: educationValidationSchema,
    onSubmit: handleEducationSubmit,
  });

  const trafficAccidentsForm = useForm({
    initialValues: trafficAccidentsInitialValues,
    validationSchema: trafficAccidentsValidationSchema,
    onSubmit: handleTrafficAccidentsSubmit,
  });

  const otherForm = useForm({
    initialValues: otherInitialValues,
    validationSchema: otherValidationSchema,
    onSubmit: handleOtherSubmit,
  });

  useEffect(() => {
    getPosition();
    getCompanyInfo();
  }, []);

  const steps = [
    {
      key: 'intro',
      Component: (
        <Intro
          company={company}
          jobName={job?.name}
          introOptions={introOptions}
          requirements={requirements}
          introCheckboxes={introCheckboxes}
          handleChange={handleIntroCheckboxChange}
        />
      ),
    },
    ...(requirements?.length
      ? [
          {
            key: 'requirements',
            title: 'Requirements',
            Component: <Requirements requirements={requirements} companyName={company?.company_name} />,
          },
        ]
      : []),
    {
      key: 'basicInfo',
      title: 'Basic Information',
      onNext: basicInfoForm.handleSubmit,
      Component: <BasicInfo form={basicInfoForm} />,
    },
    {
      key: 'position',
      title: 'Position & Experience',
      Component: <Position form={positionForm} companyId={companyId} positionId={id} />,
      onNext: positionForm.handleSubmit,
      width: '30%',
      minWidth: '330px',
    },
    {
      key: 'applicantInfo',
      title: 'Applicant Information',
      Component: <ApplicantInfo form={applicantInfoForm} applicantInfo={applicantInfo} {...basicInfoForm.values} />,
      onNext: applicantInfoForm.handleSubmit,
    },
    ...(positionForm?.values?.applicantType?.id === 1
      ? [
          {
            key: 'license',
            title: 'Driver License',
            Component: <DriverLicense form={driverLicenseForm} companyId={companyId} />,
            onNext: driverLicenseForm.handleSubmit,
            width: '30%',
            minWidth: '330px',
          },
        ]
      : [
          {
            key: 'staffLicense',
            title: 'License',
            Component: <StaffLicense form={staffLicenseForm} companyId={companyId} />,
            onNext: staffLicenseForm.handleSubmit,
            width: '30%',
            minWidth: '330px',
          },
        ]),
    ...(job?.require_medical_examiners_card
      ? [
          {
            key: 'medCard',
            title: 'Medical Examiner’s Card',
            Component: <MedicalCard form={medicalCardForm} companyId={companyId} />,
            onNext: medicalCardForm.handleSubmit,
            width: '30%',
            minWidth: '330px',
          },
        ]
      : []),
    {
      key: 'prevEmployment',
      title: 'Previous Employment',
      Component: (
        <PreviousEmployment
          form={previousEmploymentForm}
          employments={previousEmployments?.previous_employment}
          onDelete={(id) =>
            setPreviousEmployments((prevState) => ({
              ...prevState,
              previous_employment: prevState.previous_employment.filter((item) => item.id !== id),
            }))
          }
          companyId={companyId}
        />
      ),
      onNext: previousEmploymentForm.handleSubmit,
      width: '90%',
      minWidth: matches ? '300px' : '840px',
    },
    ...(requirements?.some((requirement) => requirement.title === APPLICANT_REQUIREMENTS.MILITARY_HISTORY)
      ? [
          {
            key: 'military',
            title: 'Military History',
            Component: <MilitaryHistory form={militaryForm} />,
            onNext: militaryForm.handleSubmit,
            width: '30%',
            minWidth: '330px',
          },
        ]
      : []),
    ...(requirements?.some((requirement) => requirement.title === APPLICANT_REQUIREMENTS.EDUCATION)
      ? [
          {
            key: 'education',
            title: 'Education',
            Component: <Education form={educationForm} companyId={companyId} />,
            onNext: educationForm.handleSubmit,
            width: '60%',
            minWidth: '330px',
          },
        ]
      : []),
    ...(requirements?.some((requirement) => requirement.title === APPLICANT_REQUIREMENTS.HISTORY_OF_TRAFFIC)
      ? [
          {
            key: 'trafficAccidents',
            title: 'History of Traffic Accidents, Violations and/or Convictions',
            Component: <TrafficAccidents form={trafficAccidentsForm} />,
            onNext: trafficAccidentsForm.handleSubmit,
            width: '75%',
            minWidth: '330px',
          },
        ]
      : []),
    ...(job?.questions?.length
      ? [
          {
            key: 'other',
            title: 'Other',
            Component: (
              <Other
                form={otherForm}
                onGetQuestions={setQuestionIds}
                questions={job?.questions}
                currentEmployment={previousEmploymentForm.values.find((i) => !!i.present)}
              />
            ),
            onNext: otherForm.handleSubmit,
          },
        ]
      : []),
    { key: 'finalPage', Component: <Final /> },
  ];

  return (
    <SWrapper $bgColor={currentStep > 1 && palette.gray0}>
      <ApplyJobHeader company={company} progress={(currentStep + 1) * (100 / steps.length)} />
      <ScrollableContainer>
        <SStepperContent width={steps[currentStep].width} $minWidth={steps[currentStep].minWidth}>
          {steps[currentStep].title && <StepTitle title={steps[currentStep].title} />}
          {steps[currentStep].Component}
        </SStepperContent>
        {currentStep !== steps.length - 1 && (
          <ApplyJobFooter
            step={currentStep}
            currentStep={steps[currentStep]}
            stepsCount={steps.length}
            onBack={onBack}
            onSkip={onNext}
            onNext={onNext}
            disabled={loading || (currentStep === 0 && introCheckboxes.includes(false))}
          />
        )}
      </ScrollableContainer>
    </SWrapper>
  );
};

export default ApplyJob;
