import React, { useContext, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import moment from 'moment';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { PostStopPointStepThree } from 'Api/Planner';
import { ReactComponent as CopyIcon } from 'assets/icons/createShipment/copyBg.svg';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomRadioButton from 'components/CustomRadioButton';
import sm from '../addStopPointModal.module.css';

import CustomInput from '../../../helpers/CustomInput';
import AddStopPointFooter from '../addStopPointFooter';
import { StopPointModalContext } from '../index';
import { AppendToFormData, SCHEDULED_DATE_TYPE } from '../../../ShipmentStops/helpers/constants';

const StopPointModalStep3 = () => {
  const { step, setStep, onChangeShow, stopPointId, getCustomerStopPoint } = useContext(StopPointModalContext);
  const { use } = useTheme();
  const [weekDays, setWeekDays] = useState([0]);
  const [loading, setLoading] = useState(false);
  const checkboxes = useMemo(() => {
    return [
      { label: 'Mon', longName: 'Monday', week_day: 0 },
      { label: 'Tue', longName: 'Tuesday', week_day: 1 },
      { label: 'Wed', longName: 'Wednesday', week_day: 2 },
      { label: 'Thu', longName: 'Thursday', week_day: 3 },
      { label: 'Fri', longName: 'Friday', week_day: 4 },
      { label: 'Sat', longName: 'Saturday', week_day: 5 },
      { label: 'Sun', longName: 'Sunday', week_day: 6 },
    ];
  }, []);

  const getMomentByTime = (time) => {
    if (time) {
      return moment(`${moment().format('YYYY-MM-DD')} ${time}`);
    }
    return time;
  };

  const isAfterTime = (a, b) => {
    if (a.isSame(b, 'hour')) {
      return a.isAfter(b, 'minute');
    }
    return a.isAfter(b, 'hour');
  };

  const validateStartTime = (value, end) => {
    if (!value) {
      return 'Required';
    }
    if (value !== '' && end !== '') {
      const startDate = getMomentByTime(value);
      const endDate = getMomentByTime(end);

      if (isAfterTime(startDate, endDate)) {
        return 'does not match';
      }
    }
  };

  const validateEndTime = (value, start) => {
    if (!value) {
      return 'Required';
    }
    if (start !== '' && value !== '') {
      const startDate = getMomentByTime(start);
      const endDate = getMomentByTime(value);

      if (isAfterTime(startDate, endDate)) {
        return 'does not match';
      }
    }
  };

  const onNext = (values) => {
    setLoading(true);
    const params = {
      working_hour_by: +values.working_hour_by,
      stop_point_id: stopPointId,
    };
    params.week_days = {};
    values.week_days.forEach((d, i) => {
      params.week_days[i] = { ...d, week_day: d.week_day.toString() };
    });
    const formData = new FormData();
    const updateData = AppendToFormData({}, values, null, ['name'], true);
    updateData.stop_point_id = stopPointId;
    Object.keys(updateData).forEach((key) => formData.append(key, updateData[key]));

    PostStopPointStepThree(params)
      .then((res) => {
        if (res && +res.status === 200) {
          onChangeShow(false);
          getCustomerStopPoint();
          setStep(1);
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickCopy = (el, index, formValues, getFieldHelpers, errors) => {
    const activeStart = el.start;
    const activeEnd = el.end;
    const activeError = errors?.week_days && errors?.week_days?.[index];

    formValues.week_days.forEach((item, i) => {
      const formStart = getFieldHelpers(`week_days[${i}].start`);
      const formEnd = getFieldHelpers(`week_days[${i}].end`);
      if (i === index) return null;
      if (!activeError) {
        formStart.setValue(activeStart);
        formEnd.setValue(activeEnd);
      }
    });
  };

  const styles = useMemo(() => {
    return {
      time: {
        width: 107,
        height: 32,
        borderRadius: 8,
        paddingLeft: 2,
      },
      day: {
        margin: '15px 10px 0 -10px',
        width: 80,
        textAlign: 'end',
      },
      to: {
        margin: '15px 10px 0',
      },
      copy: {
        margin: '15px 10px 0',
        color: palette.indigo500,
        cursor: 'pointer',
      },
      copyIcon: {
        marginRight: 8,
      },
    };
  }, [palette, use]);

  const initialValue = useMemo(() => {
    return {
      working_hour_by: '1',
      week_days: [
        {
          start: '',
          end: '',
          week_day: 0,
          name: 'Monday',
        },
      ],
    };
  }, []);

  const toggleWeekDay = (week_day) => {
    const newWeekDays = [...weekDays];
    if (newWeekDays.includes(week_day)) {
      const index = newWeekDays.indexOf(week_day);
      newWeekDays.splice(index, 1);
    } else {
      newWeekDays.push(week_day);
    }
    setWeekDays(newWeekDays);
  };

  return (
    <div>
      <Formik onSubmit={onNext} initialValues={initialValue}>
        {({ values, submitForm, errors, getFieldHelpers }) => {
          return (
            <Form>
              {Array(3)
                .fill(0)
                .map((item, index) => {
                  return (
                    <div key={item}>
                      <Field
                        type='radio'
                        name='working_hour_by'
                        value={String(index + 1)}
                        component={(props) => (
                          <CustomRadioButton {...props}>
                            <Typography variant='s2' style={{ marginLeft: 8 }}>
                              {SCHEDULED_DATE_TYPE[index].label}
                            </Typography>
                          </CustomRadioButton>
                        )}
                      />
                    </div>
                  );
                })}

              <FieldArray
                name='week_days'
                render={(arrayHelpers) => {
                  return (
                    <div className={sm.times_wrapper}>
                      {/* ///days */}
                      <div style={{ marginLeft: 25 }} className={sm.input_checkbox}>
                        {checkboxes.map((el) => {
                          return (
                            <div key={uuid()}>
                              <CustomCheckbox
                                type='button'
                                checked={weekDays.includes(el.week_day)}
                                onChange={() => {
                                  const foundedIndex = values?.week_days.findIndex(
                                    (formItem) => formItem.week_day === el.week_day
                                  );
                                  if (foundedIndex !== -1) {
                                    arrayHelpers.remove(foundedIndex);
                                  } else {
                                    arrayHelpers.insert(el.week_day, {
                                      start: '',
                                      end: '',
                                      week_day: el.week_day,
                                      name: el.longName,
                                    });
                                  }
                                  toggleWeekDay(el.week_day);
                                }}
                                style={{ margin: '-22px 8px 0 0' }}
                              >
                                {el.label}
                              </CustomCheckbox>
                            </div>
                          );
                        })}
                      </div>

                      {values?.week_days?.map((el, index) => {
                        const namePrefix = `week_days[${index}]`;
                        return (
                          <div key={namePrefix} className={sm.times}>
                            <Typography variant='s2' style={styles.day}>
                              {el.name}
                            </Typography>
                            <div className={sm.error_wrapper}>
                              <Field
                                name={`${namePrefix}.start`}
                                type='text'
                                inputType='time'
                                style={styles.time}
                                component={CustomInput}
                                validate={(value) => validateStartTime(value, el?.end)}
                              />
                              <ErrorMessage
                                name={`${namePrefix}.start`}
                                render={(error) => (
                                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                                    {error}
                                  </Typography>
                                )}
                              />
                            </div>

                            <Typography variant='s2' style={styles.to}>
                              to
                            </Typography>

                            <div className={sm.error_wrapper}>
                              <Field
                                name={`${namePrefix}.end`}
                                type='text'
                                inputType='time'
                                style={styles.time}
                                component={CustomInput}
                                validate={(value) => validateEndTime(value, el?.start)}
                              />
                              <ErrorMessage
                                name={`${namePrefix}.end`}
                                render={(error) => (
                                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                                    {error}
                                  </Typography>
                                )}
                              />
                            </div>

                            <Typography
                              variant='s2'
                              style={styles.copy}
                              className={sm.copy_times_all_days}
                              onClick={() => onClickCopy(el, index, values, getFieldHelpers, errors)}
                            >
                              <CopyIcon style={styles.copyIcon} fill={palette.indigo500} />
                              Copy time to all days
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />

              <AddStopPointFooter
                step={step}
                setStep={setStep}
                getCustomerStopPoint={getCustomerStopPoint}
                submitForm={submitForm}
                onChangeShow={onChangeShow}
                loading={loading}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default StopPointModalStep3;
