import React, { useState } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import call from 'assets/icons/equipment/call.svg';
import ChatIcon from 'assets/icons/header/chat-20px.svg';
import profile from 'assets/icons/drivers/user-profile.svg';
import mailfilled from 'assets/icons/equipment/mail-filled.svg';
import { statusColor } from 'utils/constants';
import { staffStatus } from 'pages/Payroll/TimeTracking/TimeTracking.data';

const MembersComp = ({ m, setaddnewChannelModal, setSelectedStaff, setuser }) => {
  const [show, setshow] = useState(false);

  const handleSelectUser = (user) => {
    setaddnewChannelModal(true);
    setSelectedStaff(user);
    setuser(true);
    setshow(false);
  };

  const popoverProfile = (
    <Popover>
      <Popover.Body>
        <div className='popover-profile-body'>
          <div className='popover-content'>
            <div className='avtar_box '>
              <div className='status' style={{ background: statusColor[staffStatus[m.user?.status]]?.color }} />
              <img src={m.user.image || profile} alt='' className='group_user_avatar' />
            </div>
            <p>{m.user.name}</p>
          </div>
          <div className='popover-content-two'>
            {m?.user?.phone_number && (
              <div className='member-detail'>
                <img src={call} alt='' />
                <a href={`tel:${m.user.phone_number}`}>
                  <p>{m.user.phone_number}</p>
                </a>
              </div>
            )}
            {m?.user?.email && (
              <div className='member-detail'>
                <img src={mailfilled} alt='' />
                <a href={`mailto:${m.user.email}`}>
                  <p>{m.user.email}</p>
                </a>
              </div>
            )}
            {m.user.role === 'staff' && (
              <div className='member-detail' onClick={() => handleSelectUser(m.user)}>
                <img src={ChatIcon} alt='' />
                <p style={{ margin: '0px 15px', cursor: 'pointer' }}>Send Message</p>
              </div>
            )}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className='avtar_box '>
        <div className='status' style={{ background: statusColor[staffStatus[m.user?.status]]?.color }} />
        <img src={m.user.image || profile} alt='' className='group_user_avatar' />
      </div>
      <OverlayTrigger
        trigger='click'
        placement='left'
        rootClose={show}
        onToggle={setshow}
        show={show}
        overlay={popoverProfile}
      >
        <p>{m.user.name}</p>
      </OverlayTrigger>
    </>
  );
};

export default MembersComp;
