import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as FailedIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warningOutline.svg';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import {
  getDocsFiles,
  getDocumentRequired,
  updateDocuments,
  deleteShipmentDocument,
  putShipmentDocument,
} from 'Api/Shipment';
import { documentType } from 'Api/Document';
import ItemForm from './ItemForm';
import { getRequiredDocs } from './UploadDocuments.data';
import { validationSchema } from './ItemForm/validationSchema';
import { SContentRow, SRow, SRowUpload, SAddMore } from './UploadDocumnet.styles';

const initialValues = [
  {
    id: 1,
    reference_id: '',
    type: null,
    doc: null,
    shipment_stop: null,
  },
];

const UploadDocument = ({ open, onClose, onSuccess = () => null, shipmentData }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoadingSubmit(true);
      const formData = new FormData();

      formData.append('shipment_id', shipmentData?.shipment?.shipment_id);

      const newDocs = values.filter((row) => typeof row.doc === 'object');
      const oldDocs = values.filter((row) => typeof row.doc === 'string');

      newDocs.forEach((row, i) => {
        formData.append(`reference_id[${i}]`, row?.reference_id);
        formData.append(`type[${i}]`, row?.type.id);
        formData.append(`doc[${i}]`, row?.doc);

        if (row?.shipment_stop) {
          formData.append(`shipment_stop_id[${i}]`, row?.shipment_stop?.id);
        }
      });

      for (const doc of oldDocs) {
        const editFormData = new FormData();

        editFormData.append(`reference_id`, doc?.reference_id);
        editFormData.append(`type`, doc?.type.id);
        if (doc?.shipment_stop) {
          editFormData.append(`shipment_stop_id`, doc?.shipment_stop.id);
        }
        putShipmentDocument({ formData: editFormData, id: doc?.document_id });
      }

      if (newDocs.length > 0) {
        await updateDocuments(formData);
      }
      showToaster({ type: 'success', message: 'The documents have been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const { handleChange, handleSubmit, values, touchedErrors, setValues } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const getRequiredDocuments = async () => {
    try {
      const { data } = await getDocumentRequired(shipmentData?.shipment?.shipment_id);
      const requiredDocs = getRequiredDocs(data, shipmentData.shipment.shipment_stops);
      setRequiredDocuments(requiredDocs);
    } catch (e) {
      // Do nothing
    }
  };

  const getUploadedDocuments = async () => {
    try {
      const { data } = await getDocsFiles(shipmentData?.shipment?.shipment_id);
      setUploadedDocuments(data);
      data.forEach((doc, index) => {
        handleChange(index, {
          id: Math.random(),
          document_id: doc.id,
          reference_id: doc.reference_id ?? '',
          shipment_stop_id: doc.shipment_stop_id ?? null,
          shipment_stop: doc.shipment_stop ?? null,
          type: doc.doc_type ?? null,
          doc: doc.path,
        });
      });
    } catch (e) {
      // Do nothing
    }
  };

  const deleteDocument = async (file) => {
    try {
      const docId = uploadedDocuments.find((doc) => doc.path === file).id;
      await deleteShipmentDocument({ id: docId });
      onSuccess();
      showToaster({ type: 'success', message: 'The documents have been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getDocumentTypes = async () => {
    try {
      const { data } = await documentType({ shipment_id_required: 1 });
      setDocumentTypes(data.filter((el) => !el.hidden));
    } catch (e) {
      // Do nothing
    }
  };

  const addNewForm = () => {
    handleChange(values.length, {
      id: Math.random(),
      reference_id: '',
      type: null,
      doc: null,
    });
  };

  const deleteFormItem = (index) => {
    const newValues = values.filter((item, i) => i !== index);
    setValues(newValues);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getRequiredDocuments(), getUploadedDocuments(), getDocumentTypes()]).finally(() => setLoading(false));
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <>
          Upload Document
          <Typography as='div' variant='b2' style={{ color: use(palette.gray500) }}>
            Shipment {shipmentData?.shipment?.shipment_id} - {shipmentData?.billing_customer?.company_name}
          </Typography>
        </>
      }
      $bgColor={palette.gray0}
      $maxWidth='1300px'
      $minWidth='1300px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Back',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Upload Documents',
          disabled: loadingSubmit || values.length === 0,
          onClick: handleSubmit,
        },
      ]}
    >
      {loading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <CircularProgress size={30} />
        </div>
      ) : (
        <div className='d-flex flex-column align-items-start'>
          <SRowUpload>
            <div className='table-upload-header-item'>
              <Typography variant='s2'>
                Reference <span style={{ color: use(palette.indigo500) }}>*</span>
              </Typography>
            </div>
            <div className='table-upload-header-item'>
              <Typography variant='s2'>Shipment Stop</Typography>
            </div>
            <div className='table-upload-header-item'>
              <Typography variant='s2'>
                Document Type <span style={{ color: use(palette.indigo500) }}>*</span>
              </Typography>
            </div>
            <div className='table-upload-header-item' />
            <div className='table-upload-header-item' />
          </SRowUpload>
          {values.map((item, i) => (
            <ItemForm
              key={item.id}
              index={i}
              values={item}
              types={documentTypes}
              handleChange={handleChange}
              onCancel={() => deleteFormItem(i)}
              onFileDelete={deleteDocument}
              stops={shipmentData.shipment?.shipment_stops || []}
              touchedErrors={touchedErrors?.[i]}
            />
          ))}
          <SAddMore onClick={addNewForm}>
            <PlusIcon fill='#4F5AED' />
            <Typography variant='s2' style={{ color: '#4F5AED', cursor: 'pointer' }}>
              Add Another
            </Typography>
          </SAddMore>
          {requiredDocuments.length > 0 && (
            <>
              <Typography variant='h5' style={{ color: use(palette.gray900), marginTop: '40px' }}>
                Required Documents
              </Typography>
              <div className='d-flex align-items-center justify-content-start gap-2 mb-3'>
                <WarningIcon />
                <Typography variant='b2' style={{ color: palette.orange500 }}>
                  These documents are required by customer.
                </Typography>
              </div>
              <SRow>
                <div className='table-header-item'>
                  <Typography variant='overLine'>DOCUMENT TYPE</Typography>
                </div>
                <div className='table-header-item'>
                  <Typography variant='overLine'>RECEIVED</Typography>
                </div>
              </SRow>
              {requiredDocuments.map((el) => {
                const founded = values.find(
                  (val) =>
                    Number(el?.type_id) === Number(val?.type?.id) &&
                    !!val.doc &&
                    (!el.stopId || el.stopId === val.shipment_stop?.id)
                );
                const icon = founded ? <TickIcon fill={palette.green500} /> : <FailedIcon fill={palette.red500} />;

                return (
                  <SContentRow key={el.id}>
                    <div className='table-row-item'>
                      <Typography variant='s2'>{el.type}</Typography>
                    </div>
                    <div className='table-row-item'>{icon}</div>
                  </SContentRow>
                );
              })}
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default UploadDocument;
