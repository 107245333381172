import React, { useState } from 'react';

const UserList = ({ idx, channel }) => {
  const [isShownUser, setIsShownUser] = useState(false);
  return (
    <>
      {idx === 5 && (
        <p onMouseEnter={() => setIsShownUser(true)} onMouseLeave={() => setIsShownUser(false)}>
          +{channel.user.length - 5}
          {isShownUser && (
            <ul
              style={{ zIndex: 10 }}
              className='channelUserList '
              onMouseEnter={() => setIsShownUser(true)}
              onMouseLeave={() => setIsShownUser(false)}
            >
              <li>user</li>
              <li>user</li>
            </ul>
          )}
        </p>
      )}
      {null}
    </>
  );
};

export default UserList;
