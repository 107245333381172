import React, { useState } from 'react';
import Divider from 'common/Divider';
import PageHeader from './components/PageHeader';
import ReferrersTable from './components/ReferrersTable';
import { SPageWrapper } from './ApplicantsAndReferrals.styles';

export const Referrers = () => {
  const [addReferrerOpen, setAddReferrerOpen] = useState(false);

  return (
    <SPageWrapper>
      <PageHeader title='Referrers' showCopyUrl={false} onAddReferrer={() => setAddReferrerOpen(true)} />

      <Divider />
      <ReferrersTable addReferrerOpen={addReferrerOpen} onAddReferrerClose={() => setAddReferrerOpen(false)} />
    </SPageWrapper>
  );
};
