import { useSelector } from 'react-redux';
import { PERMISSIONS } from 'utils/constants';

const useShipmentLocked = () => {
  const { permissions } = useSelector((state) => state?.root);

  const isShipmentLocked = (shipment) => {
    if (shipment?.audited) {
      return shipment?.invoiced
        ? true
        : !permissions.includes(PERMISSIONS.COMPLETED_AUDIT.name) && !permissions.includes(PERMISSIONS.ACCOUNTING.name);
    }

    return [6, 7].includes(Number(shipment?.status_id)) && !!shipment?.invoiced;
  };

  return { isShipmentLocked };
};

export default useShipmentLocked;
