import React, { forwardRef, useMemo, useState } from 'react';
import MaterialTable from 'material-table';
import '../TablePlaner/TablePlaner.css';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';

import { ReactComponent as TopArrow } from 'assets/icons/tableSortArrowTop.svg';
import { ReactComponent as TopArrowActive } from 'assets/icons/tableSortArrowTopActive.svg';
import { ReactComponent as DownArrow } from 'assets/icons/tableSortArrowDown.svg';
import { ReactComponent as DownArrowActive } from 'assets/icons/tabelSortArrowDownActive.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MaterialTableWrapper = ({ ...props }) => {
  const { use } = useTheme();
  return (
    <div className='planner_table_wrapper'>
      <MaterialTable
        icons={tableIcons}
        options={{
          pageSize: Number(props.rowPerPage),
          pageSizeOptions: [25, 50, 100, 250, 500],
          emptyRowsWhenPaging: false,
          toolbar: false,
          sorting: false,
          thirdSortClick: false,
          draggable: false,
          tableLayout: props.tableLayout ? props.tableLayout : 'auto',
          columnResizable: false,
          paging: !!props.rowPerPage,
          actionsColumnIndex: props?.actionsColumnIndex,
          ...props.options,
        }}
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
        {...props}
      />
    </div>
  );
};

export default MaterialTableWrapper;

export const MaterialTableSort = ({ title, field, sortingQuery, nested_field, isSorting = true }) => {
  const [sortBy, setSortBy] = useState('desc');
  const { use } = useTheme();

  const style = useMemo(() => {
    return {
      mb: {
        marginBottom: 1,
      },
      mt: {
        marginTop: 1,
      },
    };
  }, []);

  return (
    <div
      className='columns_item_wrapper'
      onClick={() => {
        setSortBy((p) => (p === 'asc' ? 'desc' : 'asc'));
        sortingQuery(field, sortBy, nested_field);
      }}
    >
      <span className='materialTableSort_title' style={{ color: use(palette.gray900, palette.white) }}>
        {title.toUpperCase()}
      </span>
      {isSorting === true ? (
        <span className='material_table_custom_icons'>
          {sortBy !== 'asc'
            ? use(<TopArrowActive style={style.mb} />, <TopArrow style={style.mb} />)
            : use(
                <TopArrow style={style.mb} />,
                <TopArrowActive fill={use(palette.dark800, palette.gray700)} style={style.mb} />
              )}
          {sortBy !== 'asc'
            ? use(
                <DownArrow style={style.mt} />,
                <DownArrowActive fill={use(palette.dark800, palette.gray700)} style={style.mb} />
              )
            : use(<DownArrowActive style={style.mb} />, <DownArrow style={style.mt} />)}
        </span>
      ) : null}
    </div>
  );
};
