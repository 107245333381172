import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  min-width: 800px;
`;

export const SBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .no-records {
    padding: 16px 0 64px;
  }
`;

export const SDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${palette.gray200};
  min-width: 1000px;

  .details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: ${palette.gray50};
    border-bottom: 1px solid ${palette.gray200};
  }

  .details-body {
    display: flex;
    column-gap: 48px;
    padding: 16px 20px;
  }

  .action-button {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 12px;
    font-family: Inter, sans-serif;
  }
`;
