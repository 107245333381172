import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import ViewPdf from 'components/ViewPdf';
import ConfirmationModal from 'common/ConfirmationModal';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getFullPrices } from 'Api/Customers';
import { GetCompanyProfile } from 'Api/CompanySettings';
import { createQuote, updateQuote, uploadQuoteDocument } from 'Api/Shipment';

import Billing from './components/Billing';
import Schedule from './components/Schedule';
import NetProfit from './components/NetProfit';
import LineItems from './components/LineItems';
import PaymentTerms from './components/PaymentTerms';
import CostEstimate from './components/CostEstimate';
import ShipmentStops from './components/ShipmentStops';
import { generatePdf } from './generatePdf';
import { createQuoteConverter, quoteValuesConverter } from './converters';
import { validationSchema, validationSchemaDraft } from './validationSchema';
import { getInitialValues, hasUnsavedData } from './CreateQuote.data';
import { SCreateQuoteForm } from './CreateQuote.styles';

const CreateQuote = ({ open, onClose, onSuccess, quote }) => {
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [submitType, setSubmitType] = useState('save');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [fuelPrice, setFuelPrice] = useState(0);

  const onSubmit = async (values) => {
    try {
      if (quote && hasUnsavedData(values)) {
        setOpenConfirm(true);
        return;
      }

      setLoading(true);

      const { blob } = generatePdf({ quote: values, companyProfile, dateFormat }, false);

      const formData = new FormData();
      formData.append('file_upload', blob);
      const response = await uploadQuoteDocument(formData);

      if (quote) {
        const body = quoteValuesConverter(values, submitType, response.full_path);
        await updateQuote(quote.id, body);
      } else {
        const body = createQuoteConverter(values, submitType, response.full_path);
        await createQuote(body);
      }
      showToaster({ type: 'success', message: `Quote has been successfully ${quote ? 'updated' : 'created'}!` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(quote),
    onSubmit,
    validationSchema: submitType === 'draft' ? validationSchemaDraft : validationSchema,
  });

  const onPreview = () => {
    const { url } = generatePdf({ quote: form.values, companyProfile, dateFormat });
    setPdfUrl(url);
  };

  const getCompanyProfile = async () => {
    try {
      const company = await GetCompanyProfile();
      setCompanyProfile(company);
    } catch (e) {
      // Do nothing
    }
  };

  const getFuelPrice = async () => {
    try {
      const { data } = await getFullPrices();
      setFuelPrice(Number(data.diesel?.price || 0));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getCompanyProfile();
    getFuelPrice();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={quote ? 'Update Quote' : 'Create Quote'}
      $bgColor={palette.gray0}
      $width='98vw'
      $minWidth='1200px'
      $maxWidth='1600px'
      backdrop='static'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
        },
        {
          key: 'draft',
          type: 'secondary',
          buttonProps: { type: 'submit', form: 'create-quote-form', onClick: () => setSubmitType('draft') },
          title: 'Save Draft',
          disabled: loading,
        },
        {
          key: 'preview',
          type: 'secondary',
          title: 'Preview',
          onClick: onPreview,
          disabled: loading,
        },
        {
          key: 'create',
          type: 'primary',
          buttonProps: {
            type: 'submit',
            form: 'create-quote-form',
            onClick: () => setSubmitType('save'),
          },
          title: quote ? 'Update Quote' : 'Create Quote',
          disabled: loading,
        },
      ]}
    >
      <SCreateQuoteForm id='create-quote-form' onSubmit={form.handleSubmit}>
        <Billing form={form} quote={quote} shouldSave={shouldSave} />
        <LineItems form={form} quote={quote} shouldSave={shouldSave} />
        <ShipmentStops form={form} quote={quote} shouldSave={shouldSave} />
        <div className='d-flex gap-5 mb-4'>
          <Schedule form={form} />
          <CostEstimate form={form} fuelPrice={fuelPrice} />
        </div>
        <div className='d-flex gap-5'>
          <PaymentTerms form={form} />
          <NetProfit form={form} fuelPrice={fuelPrice} />
        </div>
      </SCreateQuoteForm>
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Quote' />
      {!!openConfirm && (
        <ConfirmationModal
          width='510px'
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          headerTitle='Update Quote'
          text='You have unsaved data! If you proceed now the unsaved data will be lost.'
          onConfirm={() => {
            onSubmit(form.values, true);
            setOpenConfirm(false);
          }}
          buttonProps={{ type: 'primary', title: 'Proceed Without Save' }}
          cancelProps={{
            title: 'Stay and Save',
            onClick: () => {
              setOpenConfirm(false);
              setShouldSave(true);
            },
          }}
        />
      )}
    </Modal>
  );
};

export default CreateQuote;
