import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import Skeleton from '@mui/material/Skeleton';
import { ChannelList } from 'stream-chat-react';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import { LayoutContext } from 'components/layout';
import { Typography } from 'components/Typography';
import { getAllChannelFilters, tabOptions } from 'pages/chatApp/ChatApp.data';
import { palette } from 'utils/constants';
import Channel from './Channel';
import { SDrawer, SSidebarOptions } from './SideBar.styles';

const sort = { last_message_at: -1 };

const SideBar = ({ open, activeTab, onTabChange, activeInnerTab, setActiveInnerTab }) => {
  const { chatUser, dot_num } = useContext(LayoutContext);
  const [tabs, setTabs] = useState(tabOptions);

  const onTabClick = (tab) => {
    onTabChange(tab);
  };

  const onCollapseArrowClick = (e, tab) => {
    e.stopPropagation();
    setTabs((prevState) =>
      prevState.map((item) => (item.id === tab.id ? { ...item, collapsed: !item.collapsed } : item))
    );
  };

  return (
    <SDrawer variant='persistent' anchor='left' open={open}>
      <div>
        <SSidebarOptions>
          {tabs.map((tab) => (
            <li key={tab.id} onClick={() => onTabClick(tab)} className={tab.id === 1 ? 'mb-2' : ''}>
              <div className={classNames('channel-category-title', { active: tab.id === activeTab.id })}>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  {tab.title}
                </Typography>
                {tab.collapsable && (
                  <ArrowUp
                    width={10}
                    height={10}
                    className={classNames('collapse-arrow', { collapsed: tab.collapsed })}
                    onClick={(e) => onCollapseArrowClick(e, tab)}
                  />
                )}
              </div>
              {tab.id !== 1 && (
                <div className={classNames('channel-list-collapse', { open: !tab.collapsed })}>
                  <ChannelList
                    loading
                    Preview={(props) => Channel({ ...props, chatUser, tab, activeInnerTab, setActiveInnerTab })}
                    EmptyStateIndicator={() => null}
                    LoadingIndicator={() => (
                      <>
                        <Skeleton style={{ width: '120px', height: '32px', marginLeft: '24px' }} />
                        <Skeleton style={{ width: '120px', height: '32px', marginLeft: '24px' }} />
                        <Skeleton style={{ width: '120px', height: '32px', marginLeft: '24px' }} />
                      </>
                    )}
                    filters={getAllChannelFilters({ tab, chatUser, dot_num })}
                    sort={sort}
                  />
                </div>
              )}
            </li>
          ))}
        </SSidebarOptions>
      </div>
    </SDrawer>
  );
};

export default SideBar;
