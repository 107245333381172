import jsPDF from 'jspdf';
import moment from 'moment/moment';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import locationIcon from 'assets/icons/location.png';
import globeIcon from 'assets/icons/globe.png';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import { DATE_FORMAT, TIME_FORMAT } from 'utils/constants';

const createFooter = ({ doc, company, page = 1, totalPages = 1 }) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const { address, address2, city, state, zipcode, phone_number, website, show_website, company_email } = company || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  // background
  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 24, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5.5, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(51, pageHeight - 20, 51, pageHeight - 10);
    doc.line(109, pageHeight - 20, 109, pageHeight - 10);
    doc.line(174, pageHeight - 20, 174, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 8, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', 56, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', 114, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', 179, pageHeight - 20, 3, 3);
    doc.text('Address', 13, pageHeight - 17.5);
    doc.text('Online', 61, pageHeight - 17.5);
    doc.text('Email', 119, pageHeight - 17.5);
    doc.text('Phone', 184, pageHeight - 17.5);

    doc.setFontSize(8);
    doc.setTextColor('#171C26');
    doc.text(`${address}${address2 ? `, ${address2}` : ''}`, 8, pageHeight - 13);
    doc.text(`${city?.name}, ${state?.short_name || state?.name} ${zipcode}`, 8, pageHeight - 9, { maxWidth: 45 });

    if (show_website && website) {
      doc.text(`${website}`, 56, pageHeight - 13);
    } else {
      doc.text('-', 56, pageHeight - 13);
    }
    if (company_email) {
      doc.text(`${company_email || '-'}`, 114, pageHeight - 13);
    } else {
      doc.text('-', 114, pageHeight - 13);
    }
    doc.text(`${phone}`, 179, pageHeight - 13);
  }
};

export const generateMasterPdf = async ({ company, values, carrier }) => {
  const {
    logo,
    company_name,
    signature_first_name,
    signature_last_name,
    master_agreement,
    master_agreement_title,
    address1,
    // address2,
    address,
    city,
    state,
    // country,
    zipcode,
    phone_number,
    receivable_email,
    signature,
    date_time_format_settings,
  } = company || {};

  const {
    name,
    address1: carrierAddress1,
    city: carrierCity,
    state: carrierState,
    zipcode: carrierZipcode,
    email: carrierEmail,
  } = carrier || {};

  const { title, printedName, phone, signature: carrierSignature } = values || {};

  const dateFormat = DATE_FORMAT[date_time_format_settings?.date_format] || DATE_FORMAT[1];
  const timeFormat = TIME_FORMAT[date_time_format_settings?.time_format] || TIME_FORMAT[1];

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.addImage(`${logo}?r=${Math.random()}boz`, 'JPEG', 8, 2, 40, 10, 'Logo', 'FAST');

  doc.setFontSize(14);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Motor Carrier Master Agreement', pageWidth / 2, 17, { align: 'center' });

  // doc.text(`${company_name || '-'}`, 170, 6);
  // doc.text(`${address}${address2 ? `, ${address2}` : ''}`, 170, 9);
  // doc.text(`${city?.name}, ${state?.short_name || state?.name} ${zipcode}`, 170, 12);
  // doc.text(`${country.sortname === 'US' ? 'USA' : country.sortname}`, 170, 15);

  const masterAgreementHtml = `
<p>This Agreement made this ${moment().format('Do')} day of ${moment().format(
    'MMMM YYYY'
  )} by and between ${company_name} located at ${address1 || address}, ${city?.name}, ${
    state?.short_name
  } ${zipcode} and ${name || 'Carrier'} located at ${carrierAddress1}, ${carrierCity?.name}, ${
    carrierState?.short_name
  } ${carrierZipcode} (hereafter Carrier).</p>
<p>WHEREAS, Carrier owns or controls motor vehicles, and is ready, willing and able to operate the vehicles for motor cargo transportation services (hereinafter referred to as "Services");</p>
<p>WHEREAS, ${company_name} licensed by the U.S. Department of Transportation Federal Motor Carrier Safety Administration as a Broker of for the Transportation of Freight (excluding household goods) and, as such, requires cargo transportation services to meet the shipment needs of its customers; and</p>
<p>WHEREAS, the parties hereto are desirous of entering into this Agreement for the provision of and receipt of cargo transportation services according to the terms set forth.</p>
<p>NOW THEREFORE, IN CONSIDERATION of the mutual covenants contained herein, ${name} and ${company_name} agree as follows:</p>
${master_agreement ? `${master_agreement}` : ''}
      `;

  const wrappedHtmlString = `
    <div id="vazgulya" style="font-size: 10px; width: 200px">
        <style>
        #vazgulya h2, h4, li, ul, h1, h3, h5, h6 {
         font-size: 3.8px;
         line-height: 4px;
         font-family: 'Inter', sans-serif;
         letter-spacing: 0;
         font-weight: 500;
         word-spacing: -0.5px;
         margin-bottom: 3px;
        }      
        
        #vazgulya figure {
          display: unset;
          margin-block-start: unset;
          margin-block-end: unset;
          margin-inline-start: unset;
          margin-inline-end: unset;
          unicode-bidi: unset;
          border: none;
      }
      
      #vazgulya figure table {
          border-collapse: collapse;
          border: none;
      }
      
      #vazgulya figure table tbody {
         border: none;
      }
      
      #vazgulya figure table tr {
          border: none;
      }
      
      #vazgulya figure table td {
          font-size: 3.8px;
          line-height: 4px;
          font-family: 'Inter', sans-serif;
          letter-spacing: 0;
          font-weight: 500;
          padding: 2px;
          border: 0.1px solid black;
      }
        
        #vazgulya strong {
          font-size: 3.8px;
          line-height: 4px;
          font-family: 'Inter', sans-serif;
          letter-spacing: 0;
          font-weight: 700;
          vertical-align: super;
        }
        
         #vazgulya p {
         word-spacing: -0.5px;
         font-weight: 500;
         font-size: 3.8px;
         line-height: 4px;
         font-family: 'Inter', sans-serif;
         letter-spacing: 0;
         margin-bottom: 3px;
         padding: 0;
        }      
        
        </style>
       ${masterAgreementHtml}
    </div>`;

  await doc.html(wrappedHtmlString, {
    x: 0,
    y: 18,
    margin: [8, 8, 20, 8],
    width: pageWidth,
    autoPaging: 'text',
  });

  doc.addPage();
  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 400);
  doc.setLineWidth(0.3);
  doc.setDrawColor(188, 194, 206);

  doc.text('IN WITNESS WHEREOF, we have signed this Agreement the date and year first shown above.', 8, 14, {
    maxWidth: pageWidth - 16,
  });

  doc.text(company_name || '', 8, 21);
  doc.text(name || '', pageWidth - 78, 21);

  // Signature
  if (!signature) {
    doc.roundedRect(8, 23, 70, 35, 2, 2);
  }
  if (!carrierSignature) {
    doc.roundedRect(pageWidth - 78, 23, 70, 35, 2, 2);
  }

  doc.addImage(`${signature}?r=${Math.random()}boz`, 'JPEG', 16, 28, 40, 20);
  if (carrierSignature) {
    doc.addImage(`${carrierSignature}`, 'JPEG', pageWidth - 70, 26, 50, 30);
  }

  doc.setTextColor('#687182');
  doc.text('Broker, Authorized Signature', 8, 62);
  doc.text('Carrier, Authorized Signature', pageWidth - 78, 62);

  // Printed Name
  doc.text('Printed Name', 10, 70);
  doc.text('Printed Name', pageWidth - 76, 70);

  doc.setTextColor('#000000');
  doc.text(`${signature_first_name || ''} ${signature_last_name || ''}`, 10, 75);
  doc.text(printedName || '', pageWidth - 76, 75);

  // Title
  doc.setTextColor('#687182');
  doc.text('Title', 10, 86);
  doc.text('Title', pageWidth - 76, 86);

  doc.setTextColor('#000000');
  doc.text(master_agreement_title, 10, 91);
  doc.text(title || '', pageWidth - 76, 91);

  // Company Address
  doc.setTextColor('#687182');
  doc.text('Company Address', 10, 102);
  doc.text('Company Address', pageWidth - 76, 102);

  doc.setTextColor('#000000');
  if (address) {
    doc.text(`${address}`, 10, 107);
    doc.text(`${city?.name}, ${state?.short_name} ${zipcode}`, 10, 112);
  }
  if (carrierAddress1) {
    doc.text(`${carrierAddress1}`, pageWidth - 76, 107);
    doc.text(`${carrierCity?.name}, ${carrierState?.short_name} ${carrierZipcode}`, pageWidth - 76, 112);
  }

  // Phone
  doc.setTextColor('#687182');
  doc.text('Phone', 10, 118);
  doc.text('Phone', pageWidth - 76, 118);

  doc.setTextColor('#000000');
  doc.text(formatPhoneNumberIntl(phone_number), 10, 123);
  doc.text(phone ? formatPhoneNumberIntl(phone) : '', pageWidth - 76, 123);

  // Email
  doc.setTextColor('#687182');
  doc.text('Email', 10, 134);
  doc.text('Email', pageWidth - 76, 134);

  doc.setTextColor('#000000');
  doc.text(receivable_email || '', 10, 139);
  doc.text(carrierEmail || '', pageWidth - 76, 139);

  const addressResponse = await fetch('https://api.ipify.org?format=json');
  const data = await addressResponse.json();

  // Ip Address
  doc.setTextColor('#687182');
  doc.text('IP Address', pageWidth - 76, 150);

  doc.setTextColor('#000000');
  doc.text(data.ip || '', pageWidth - 76, 155);

  // Date
  doc.setTextColor('#687182');
  doc.text('Date', 10, 150);
  doc.text('Date', pageWidth - 76, 166);
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  doc.setTextColor('#000000');
  doc.text(moment().tz(currentTimezone).format(`${dateFormat} ${timeFormat} z`), 10, 155);
  doc.text(moment().tz(currentTimezone).format(`${dateFormat} ${timeFormat} z`), pageWidth - 76, 171);

  const totalPages = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter({
      doc,
      company,
      page,
      totalPages,
    });
  }

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  return { blob, url };
};
