import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .table-settings-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    width: 40px;
    height: 32px;
    background: ${palette.white};
    box-shadow: ${palette.boxShadow2};
    border-radius: 6px;
    transition: 0.2s all ease-in-out;

    :hover {
      box-shadow: 0 0 3px 1px ${palette.gray400};

      img {
        filter: opacity(1);
      }
    }
  }
`;
