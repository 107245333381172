import React, { useState, useEffect, useRef, createContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './equipmentdetails.css';
import { Box } from '@mui/material';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import { MaintanceCost, EquipmentGrossRevenue, checkAddOnStatus, StopPoints } from 'Api/Equipment';
import { Icons } from 'assets/icons';
import { getAlphabet, motionColors, palette, TRUCK_TYPES } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import authHeader from 'services/auth-header';
import Chip from 'common/Chip';
import Edit from 'assets/icons/drivers/edit.svg';
import down from 'assets/icons/drivers/down.svg';
import inventoryIcon from 'assets/icons/inventory.png';
import { ReactComponent as MapLayersIcon } from 'assets/icons/mapLayers.svg';
import { ReactComponent as WeatherIcon } from 'assets/icons/weather.svg';
import { ReactComponent as TrafficIcon } from 'assets/icons/traffic.svg';
import { ReactComponent as StopIcon } from 'assets/icons/StopPointIcon.svg';
import { ReactComponent as FuelIcon } from 'assets/sidemanu/fuel.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as Motion } from 'assets/icons/motion.svg';
import { ReactComponent as DotMonitoringIcon } from 'assets/icons/dot-monitoring.svg';
import { ReactComponent as ShipmentIcon } from 'assets/sidemanu/shipment.svg';
import { ReactComponent as PlannerIcon } from 'assets/sidemanu/plannerNewIcon.svg';

import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatAmount, formatSeconds } from 'utils/helpers';
import { addCustomStop, getEquipmentProfile } from 'Api/EquipmentProfile';
import useDateFormat from 'hooks/useDateFormat';
import { PusherJs } from 'common/pusher';
import { Typography } from 'components/Typography';
import PlannedLoads from 'components/TableShipments/components/PlannedLoads';
import { getEquipmentRecordsCount } from 'Api/DotMonitoring';
import Vehicle from './EquipmentProfileComponents/EquipmentMain/Vehicle';
import Map from './EquipmentProfileComponents/Map/Map';
import MaintenanceCost from './EquipmentProfileComponents/MaintenanceCost/MaintenanceCost';
import OutofserviceStatus from './EquipmentProfileComponents/EquipmentMain/EquipmentStatus/OutOfService';
import CustomStopPoint from './CustomStopPoint/CustomStopPoint';
import CustomizedSnackbars from './EquipmentProfileComponents/EquipmentDocument/Snackbar';
import EquipmentMileage from './EquipmentProfileComponents/EquipmentMilage';
import style from './EquipmentProfile.module.css';
import UpdateEquipmentID from '../DialogueModals/AddDriver/UpdateEquipmentID';
import DateRange from './EquipmentProfileComponents/EquipmentMain/DateRangePicker/DateRangePicker';
import DriverCustomCost from './DriverCustomCost/DriverCustomCost';
import EquipmentLogs from './EquipmentLogs';
import AddEquipmentNotes from './EquipmentProfileComponents/AddNotes';
import MotionsLogs from './MotionsLogs';
import DotRecords from '../components/DotRecords';
import InventoryList from '../components/InventoryList';

export const EquipmentDetailsContext = createContext();

const EquipmentDetails = () => {
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const showToaster = useShowToaster();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const { currency } = useSelector((state) => state.root);
  const [show, setShow] = useState(true);
  const [showStop, setShowStop] = useState(false);
  const [equipmentID, setEquipmentID] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mapLayersValues, setMapLayersValues] = useState('BASIC');
  const [profileData, setProfileData] = useState({});
  const [target, setTarget] = useState(null);
  const [MaintanceCostValue, setMaintananceCostValue] = useState();
  const [openGrossRevenue, setOpenGrossRevenue] = useState(false);
  const [openoutofservice, setOutOfServiceOpen] = useState(false);
  const handleOutOfServiceOpen = () => setOutOfServiceOpen(true);
  const handleOutOfServiceClose = () => setOutOfServiceOpen(false);
  const [rangeName, setRangeName] = useState('Live');
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [motionLogsOpen, setMotionLogsOpen] = useState(false);
  const [ismapstyleChange, setIsMapStyleChange] = useState(false);
  const [timeLine, setTimeLine] = useState(false);
  const [isCenter, setIsCenter] = useState(false);
  const [resetMyRoute, setResetMyRoute] = useState(null);
  const [resetMyMap, setresetMyMap] = useState(null);
  const [connectedServicesData, setConnectedServicesData] = useState();
  const [, setDeleteSuccess] = useState();
  const [hookTo, setHookTo] = useState();
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [updateEquipmentID, setUpdateEquipmentID] = useState();
  const [showLicence, setShowLicence] = useState(false);
  const [reloadMap, setReloadMap] = useState(null);
  const [stopPointValues, setStopPointValues] = useState(null);
  const [stopPointVisible, setStopPointVisible] = useState(false);
  const [POI, setPOI] = useState(false);
  const [stopPointAll, setStopPointsAll] = useState([]);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [unacknowledgedCount, setUnacknowledgedCount] = useState(0);
  const [dotMonitoringOpen, setDotMonitoringOpen] = useState(0);
  const [openInventoryList, setOpenInventoryList] = useState(false);

  const [visibleTraffic, setVisibleTraffic] = useState(false);
  const [visibleWeather, setVisibleWeather] = useState(false);
  const [weatherCtrlVisible, setWeatherCtrlVisible] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: startDate ? startOfDay(moment(startDate).toDate()) : startOfDay(new Date()),
    end: endDate ? endOfDay(moment(endDate).toDate()) : endOfDay(new Date()),
  });
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [addressValues, setAddressVales] = useState([]);
  const [loadingStopPoints, setLoadingStopPoints] = useState(false);

  const [routeValues, setRouteValues] = useState({
    open_borders: false,
    highway_only: false,
    haz_mat: false,
    avoid_tolls: false,
    route_type: { name: 'Practical', value: '0' },
  });

  const renderTooltip = (title) => {
    return <Tooltip>{title}</Tooltip>;
  };

  const { use } = useTheme();
  const handleGrossRevenueOpen = (id) => {
    setOpenGrossRevenue(true);
    setEquipmentID(id);
  };
  const handleEquipmentMileageOpen = (id) => {
    setOpenEquipmentMileage(true);
    setEquipmentID(id);
  };
  const handleOpenEquipmentID = () => {
    setUpdateEquipmentID(true);
  };
  const handleCloseEquipmentID = () => {
    setUpdateEquipmentID(false);
  };

  const Maplayers = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const timeLineOpen = () => {
    setTimeLine(true);
    setIsCenter(true);
  };
  const timeLineClose = () => {
    setTimeLine(false);
    setIsCenter(false);
    setRangeName('Live');
    setDateRange({
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
    });
    resetMyRoute?.remove();
  };
  const mapLayersValue = (value) => {
    setIsMapStyleChange(true);
    setMapLayersValues(value);
    MapLayersHandleClose();
  };
  const mapOptionsValue = (value) => {
    checkAddOnStatus(2, profileData?.id)
      .then((res) => {
        if (res.is_addon_active) {
          const trafficVisible = mapInstance.isTrafficVisible();
          const radarVisible = mapInstance.isWeatherRadarVisible();
          if (value === 'Traffic') {
            if (!trafficVisible) {
              mapInstance.setTrafficVisibility(true);
              setVisibleTraffic(true);
            } else if (trafficVisible) {
              mapInstance.setTrafficVisibility(false);
              setVisibleTraffic(false);
            }
          } else if (!radarVisible) {
            mapInstance.setWeatherRadarVisibility(true);
            mapInstance.setWeatherAlertVisibility(true);
            setVisibleWeather(true);
            const ctrl = new TrimbleMaps.WeatherAlertClickControl();
            mapInstance.addControl(ctrl);
            setWeatherCtrlVisible(ctrl);
          } else if (radarVisible) {
            mapInstance.setWeatherRadarVisibility(false);
            mapInstance.setWeatherAlertVisibility(false);
            weatherCtrlVisible && mapInstance.removeControl(weatherCtrlVisible);
            setVisibleWeather(false);
          }
        } else {
          setShowLicence(true);
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong!' });
      });
  };
  const MapLayersHandleClose = () => {
    setAnchorEl(null);
  };
  const getDeleteSuccess = (data) => {
    setDeleteSuccess(data);
  };
  const open = Boolean(anchorEl);
  const map = open ? 'simple-popover' : undefined;
  const [openmaintenencecost, setOpenMaintenanceCost] = useState(false);
  const [historyCoords, setHistoryCoords] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [driverSpeed, setDriverSpeed] = useState(0);
  const [driverLocation, setDriverLocation] = useState(0);

  const handleMaintenanceCostOpen = () => setOpenMaintenanceCost(true);
  const handleMaintenanceCostClose = () => setOpenMaintenanceCost(false);

  const [openEquipmentMileage, setOpenEquipmentMileage] = useState(false);
  const [profile, setProfile] = useState();
  const [GrossRevenueData, setOpenGrossRevenueData] = useState();
  const [routeInstance, setRouteInstance] = useState(null);
  const [routeStopContent, setRouteStopContent] = useState(null);
  const [customStopsData, setCustomStopsData] = useState(null);
  const [driverCustomCost, setDriverCustomCost] = useState(null);

  const handleClearOutOfService = () => {
    const value = {
      status_id: profileData.drivers?.length ? 1 : 4,
    };
    try {
      axios
        .put(`${API_URL}/equipment/equipment-status/${profileData?.id}`, value, {
          headers: authHeader(),
        })
        .then(() => {
          init();
        });
    } catch (error) {
      // Do nothing
    }
  };

  const isWide = {
    width: customStopsData && driverCustomCost ? '33vw' : '46vw',
  };

  const ref = useRef(null);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleStopClick = () => {
    setShowStop(!showStop);
  };

  const month = new Date().getMonth() + 1;
  const navigate = useNavigate();
  const { id } = useParams();

  async function init(value, reload) {
    if (value) {
      getEquipmentProfile(id).then((res) => {
        setProfileData({ ...(res?.data || {}), refreshIndex: Date.now() });
        if (reload) setReloadMap(new Date().getTime());
        if (res) {
          EquipmentGrossRevenue(res.data.id, month).then((res) => {
            setOpenGrossRevenueData(res?.data);
          });
        }
      });
    } else {
      getEquipmentProfile(id).then((res) => {
        setProfileData({ ...(res?.data || {}), refreshIndex: Date.now() });
        if (reload) setReloadMap(new Date().getTime());
        if (res) {
          EquipmentGrossRevenue(res.data.id, month).then((res) => {
            setOpenGrossRevenueData(res?.data);
          });
        }
      });
    }
  }

  const API_URL = process.env.REACT_APP_API_URL;

  const flag =
    profileData?.drivers?.length === 2
      ? profileData?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length >= 2
      : '' || profileData?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length > 0;

  async function equipmentMaintanance() {
    MaintanceCost(profileData?.id).then((res) => {
      setMaintananceCostValue(res?.data);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const navigateConditionally = () => {
    if (location?.state?.tab) {
      navigate('/equipment', { state: { tab: 'vehicles' } });
    } else {
      navigate(-1);
    }
  };

  const getStopPoints = () => {
    StopPoints({ map: 1 }).then((res) => {
      if (res && res.data && res.data.length) {
        const filterByData = res.data.map((el) => {
          return {
            label: `${el?.location_name}`,
            id: el.id,
            value: {
              latitude: `${el.geofencing_latitude}`,
              longitude: `${el.geofencing_longitude}`,
              current_location: `${el?.location_name ? el?.location_name : '-'}`,
              address1: `${el?.address1 ? el?.address1 : '-'}`,
              address2: `${el?.address2 ? el?.address2 : '-'}`,
              city: `${el?.city?.name ? el?.city?.name : '-'}`,
              state: `${el?.state?.name ? el?.state?.name : '-'}`,
              zipcode: `${el?.zipcode ? el?.zipcode : '-'}`,
              country: `${el?.country?.name ? el?.country?.name : '-'}`,
              stop_point_trailers_count: el.stop_point_trailers_count,
              stop_point_vehicles_count: el.stop_point_vehicles_count,
            },
            city: el.city.name,
            state: el.state.name,
          };
        });
        setStopPointsAll(filterByData);
      }
    });
  };

  useEffect(() => {
    getStopPoints();
    init().catch(null);

    const dataRaw = localStorage.getItem('user');
    const data = JSON.parse(dataRaw);
    const channel = PusherJs.subscribe(`private-equipmentList-Update.${data?.customer?.dot}`);
    channel.bind('equipment-event', (data) => {
      if (isCenter) return;
      setProfile(data);
    });

    return () => {
      PusherJs.unsubscribe(`private-equipmentList-Update.${data?.customer?.dot}`);
    };
  }, []);

  useEffect(() => {
    if (profileData?.equipment_id) {
      equipmentMaintanance().catch(null);
    }
    if (profileData?.hook_to) {
      setHookTo(1);
    } else {
      setHookTo(0);
    }
  }, [profileData?.equipment_id]);

  useEffect(() => {
    if (profileData) {
      const id = profile?.message?.data?.id;
      if (!profile?.message) return;

      const pusherData = profile?.message?.data;
      const newData = {
        ...profileData,
        ...profile?.message?.data,
        drivers: pusherData.drivers ? pusherData.drivers : null,
        equipment_group: pusherData.equipment_group ? pusherData.equipment_group : null,
        equipment_reserve: pusherData.equipment_reserve ? pusherData.equipment_reserve : null,
        equipment_type: profileData.equipment_type,
      };
      if (profileData?.id === id) {
        setProfileData(newData);
      }
      if (profileData?.hook_to) {
        setHookTo(1);
      } else {
        setHookTo(0);
      }
    }
  }, [profile]);

  let latitude;
  let longitude;
  let heading;

  if (profileData?.location_target === 'Equipment') {
    latitude = profileData?.equipment_gps?.latitude
      ? profileData?.equipment_gps?.latitude
      : profileData?.last_known_latitude;
    longitude = profileData?.equipment_gps?.longitude
      ? profileData?.equipment_gps?.longitude
      : profileData?.last_known_longitude;
    heading = profileData?.equipment_gps?.heading
      ? profileData?.equipment_gps?.heading
      : profileData?.last_known_heading;
  } else if (profileData?.location_target === 'Driver') {
    const driverGPS = profileData?.drivers?.[0]?.driver_gps;
    latitude = driverGPS?.latitude ? driverGPS?.latitude : profileData?.last_known_latitude;
    longitude = driverGPS?.longitude ? driverGPS?.longitude : profileData?.last_known_longitude;
    heading = driverGPS?.heading ? driverGPS?.heading : profileData?.last_known_heading;
  }

  const updateStatus = (statusID) => {
    let value;
    if (statusID) {
      value = {
        status_id: statusID,
      };
    }
    axios
      .put(`${API_URL}/equipment/equipment-status/${profileData?.id}`, value, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res) {
          init();
        }
      });
  };

  function numberWithCommas(value, miles) {
    let x;
    if (miles === 'miles') {
      x = parseInt(value, 10).toString();
    } else {
      x = Number(value).toString();
    }

    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
    return x;
  }

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const stopPointZoom = (i) => {
    const element = document.getElementById(historyData[i]?.id);
    element?.scrollIntoView({ behavior: 'smooth' });

    if (historyData[i]) {
      mapInstance.on('load', function a() {
        mapInstance.zoomTo(17, {
          animate: true,
          duration: 5000,
        });
      });
      mapInstance.setCenter({
        lat: historyData[i].latitude,
        lon: historyData[i].longitude,
      });
      mapInstance.zoomTo(17, { animate: true, duration: 5000 });
      setIsCenter(true);
    }
  };

  const routeContent = [];
  const addStopPointsRoutes = (data, addresses, options) => {
    const pointsData = data.stopPointLatLong?.map((item) => ({
      ...item,
    }));
    const routeCoords = [{ latitude, longitude }, ...pointsData];
    if (routeCoords.length >= 2) {
      setTimeout(() => {
        routeCoords?.map((tracking, i) => {
          // - 1 because first item is driver data
          const city = addresses[i - 1]?.city;
          const state = addresses[i - 1]?.state;
          const zipcode = addresses[i - 1]?.zipcode;
          const streetAddress = addresses[i - 1]?.streetAddress;

          const el = document.createElement('div');
          el?.classList?.add(`black-marker${i}`);
          const el2 = document.createElement('div');
          el?.classList?.add(`<black-marker1>i+30</black-marker1>`);

          if (i !== 0) {
            const htmlContent = `<div id="image" style="background: url(${Icons.steps}) no-repeat; height: 26px; width: 26px;"/>`;

            const htmlContent2 = `<div class="equipment-profile-address-wrapper"><span class="equipment-profile-address-inner"> ${
              i !== 0 ? `Stop ${getAlphabet(i - 1)}` : ''
            }: </span>
                <span class="equipment-profile-address">
                ${streetAddress ? `${streetAddress}, ` : ''}
                ${city ? `${city}, ` : ''}
                ${state ? `${state}, ` : ''}
                ${zipcode ? `${zipcode}` : ''}</span></div>`;
            el.innerHTML = htmlContent;
            el2.innerHTML = htmlContent2;
          } else {
            el.innerHTML = `<div class="equipment-profile-deadhead-wrapper">
                                        <span class="equipment-profile-deadhead-inner">Dead Head - ${Number(
                                          data.emptyMiles
                                        )?.toFixed()} Miles</span>
                                  </div>`;
          }

          const marker = new TrimbleMaps.Marker({
            color: 'blue',
            element: el,
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])
            .addTo(mapInstance);

          const marker2 = new TrimbleMaps.Marker({
            color: 'blue',
            element: el2,
          })
            .setLngLat([tracking?.longitude, tracking?.latitude])
            .addTo(mapInstance);

          routeContent.push(marker);
          routeContent.push(marker2);
          setRouteStopContent(routeContent);
          return marker;
        });
      }, 300);
      let determinedRouteType = options?.route_type?.value;
      switch (routeValues.route_type?.value) {
        case '0':
          determinedRouteType = TrimbleMaps.Common.RouteType.PRACTICAL;
          break;
        case '1':
          determinedRouteType = TrimbleMaps.Common.RouteType.SHORTEST;
          break;
        case '2':
          determinedRouteType = TrimbleMaps.Common.RouteType.FASTEST;
          break;
        default:
          determinedRouteType = TrimbleMaps.Common.RouteType.PRACTICAL;
      }
      const myRoute = new TrimbleMaps.Route({
        routeId: `myRoute_${Math.random()}`,
        isDraggable: false,
        stops: routeCoords.map((tracking) => new TrimbleMaps.LngLat(tracking?.longitude, tracking?.latitude)),
        frameRoute: true,
        showStops: false,
        routeType: determinedRouteType,
        highwayOnly: !!options?.highway_only,
        tollRoads: options?.avoid_tolls ? 2 : 3,
        bordersOpen: !!options?.open_borders,
        hazMatType: options?.haz_mat ? 1 : 0,
        routeColor: '#4F5AED',
        routeWidth: 5,
        destinationIcon: {
          size: 1,
          url: 'https://developer.trimblemaps.com/maps-sdk/img/marker_blue.png',
          offset: [0, -6],
        },
      });
      setRouteInstance(myRoute);
      myRoute.addTo(mapInstance);
      setLoadingStopPoints(false);
      // setCostOpen(true);
    }
  };

  const clearRoute = () => {
    if (routeStopContent?.length) {
      routeStopContent.map((m) => m?.remove());
    }
    if (routeInstance) {
      routeInstance.remove();
    }
    // setRouteOptions({
    //   open_borders: false,
    //   highway_only: false,
    //   haz_mat: false,
    //   avoid_tolls: false,
    // })
    setDriverCustomCost(null);
    setCustomStopsData(null);
    setRouteInstance(null);
    setRouteStopContent(null);
  };

  const addStopPoint = async (data, options) => {
    clearRoute();
    setCustomStopsData(data);
    let CustomData = null;
    const values = {
      equipment_id: id,
      stop_points: data,
      radius: null,
      mode:
        options.route_type?.value === '0' ? 'practical' : options.route_type?.value === '1' ? 'shortest' : 'fastest',
      open_borders: options?.open_borders,
      highway_only: options?.highway_only,
      hazmat: options?.haz_mat,
      avoid_tolls: options?.avoid_tolls,
    };
    try {
      await addCustomStop(values)
        .then()
        .then((res) => {
          let data;
          if (res?.data?.data) {
            data = res?.data?.data;
          } else {
            data = res?.data;
          }
          CustomData = data;
        })
        .catch((e) => {
          showToaster({ type: 'error', message: getErrorMessage(e) });
          setLoadingStopPoints(false);
        });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
      setLoadingStopPoints(false);
    }
    setDriverCustomCost(CustomData);
    addStopPointsRoutes(CustomData, values.stop_points, options);
  };

  useEffect(() => {
    if (startDate) {
      timeLineOpen();
      setRangeName('Custom Range');
    }
  }, []);

  useEffect(() => {
    getDotRecordsCount();
  }, []);

  const getDotRecordsCount = async () => {
    const { data } = await getEquipmentRecordsCount(id);
    setUnacknowledgedCount(data.total || 0);
  };

  return (
    <>
      {showLicence && (
        <div className='check-addon' onClick={() => setShowLicence(false)}>
          You do not have this feature enabled.
          <span onClick={() => navigate('/billing?addonId=2&planId=34')}> Purchase Addon </span>
        </div>
      )}
      <EquipmentDetailsContext.Provider
        value={{
          dateRange,
          dateSelect,
          rangeName,
          setRangeName,
          setDateRange,
          isShowDatePicker,
          setIsShowDatePicker,
          profileData,
          setHistoryCoords,
          historyCoords,
          selectedHistoryItem,
          setSelectedHistoryItem,
          setHistoryData,
          historyData,
          stopPointZoom,
          setMapInstance,
          isCenter,
          setResetMyRoute,
          resetMyRoute,
          setresetMyMap,
          resetMyMap,
          setDriverSpeed,
          setDriverLocation,
          driverLocation,
          driverSpeed,
          setConnectedServicesData,
          connectedServicesData,
        }}
      >
        <div className='equipment-profile-wrapper' style={{ marginTop: '0px !important' }}>
          <div
            className='d-flex align-items-center mt-1 pointer'
            style={{ maxWidth: 'fit-content' }}
            onClick={navigateConditionally}
          >
            <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M3.46967 0.46967C3.76256 0.176777 4.23744 0.176777 4.53033 0.46967C4.82322 0.762563 4.82322 1.23744 4.53033 1.53033L2.81066 3.25H9C9.41421 3.25 9.75 3.58579 9.75 4C9.75 4.41421 9.41421 4.75 9 4.75H2.81066L4.53033 6.46967C4.82322 6.76256 4.82322 7.23744 4.53033 7.53033C4.23744 7.82322 3.76256 7.82322 3.46967 7.53033L0.46967 4.53033C0.176777 4.23744 0.176777 3.76256 0.46967 3.46967L3.46967 0.46967Z'
                fill='#4F5AED'
              />
            </svg>
            <span className='equipment-heading'>Go Back</span>
          </div>
          <div className='d-flex align-item-center mt-1'>
            <div className='d-flex align-items-center mt-3'>
              <p className='equipment-name-heading' style={{ color: use(palette.dark800, palette.gray200) }}>
                {profileData?.equipment_id}
                <span className='ms-1'>{profileData?.equipment_type?.title} </span>
                {!!profileData?.truck_type && <span>({TRUCK_TYPES[profileData?.truck_type]})</span>}
              </p>
              <span style={{ height: '20px', width: '20px', marginTop: '-40px' }}>
                <img src={Edit} alt='edit' onClick={handleOpenEquipmentID} />
              </span>
            </div>

            <div className='ms-3 mt-3'>
              {!flag ? (
                <Chip
                  fontWeight={500}
                  fontSize='11px'
                  bgColor={profileData?.equipment_status?.background_color}
                  label={profileData?.equipment_status?.equipment_status}
                  labelColor={profileData?.equipment_status?.color}
                  style={{ textTransform: 'uppercase' }}
                />
              ) : (
                <span className={style['available-status']} style={{ color: '#5A6376', backgroundColor: '#F0F2F7' }}>
                  Not Available{' '}
                </span>
              )}
              <Dropdown className='d-inline mx-2'>
                <Dropdown.Toggle
                  id='dropdown-autoclose-outside'
                  style={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    boxShadow: 'none',
                    marginLeft: '0',
                    paddingLeft: '10x',
                  }}
                >
                  <img src={down} alt='down' />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                  }}
                >
                  {!!profileData.drivers?.length &&
                    Number(profileData.equipment_status?.id) !== 1 &&
                    profileData.equipment_status?.id !== 5 && (
                      <Dropdown.Item
                        className='outofserviceDropDownItemsList'
                        style={{ color: use(palette.dark800, palette.gray200) }}
                        onClick={() => updateStatus(1)}
                      >
                        Available
                      </Dropdown.Item>
                    )}

                  {![3, 5].includes(Number(profileData.equipment_status?.id)) && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      onClick={handleOutOfServiceOpen}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      Out Of Service
                    </Dropdown.Item>
                  )}
                  {profileData.equipment_status?.id === 5 && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => updateStatus(profileData?.drivers?.length ? 1 : 4)}
                    >
                      Active
                    </Dropdown.Item>
                  )}
                  {profileData.equipment_status?.id === 3 && (
                    <Dropdown.Item
                      className='outofserviceDropDownItemsList'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                      onClick={() => handleClearOutOfService()}
                    >
                      Set In Service
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {profileData?.equipment_status?.equipment_status === 'Out of Service' ? (
                <div className='reasonNote'>
                  Equipment is Out of Service
                  {profileData?.next_available_date
                    ? ` ${convertToCustomerTime(profileData?.next_available_date)}`
                    : ''}
                  . Note:
                  {profileData?.reason}{' '}
                </div>
              ) : (
                ''
              )}
              {flag ? (
                <div className='reasonNote'>
                  Driver is Not available Until{' '}
                  {formatDate(profileData?.drivers?.map((v) => v.driver_not_available_until_date)[0])}{' '}
                  {profileData?.drivers?.map((v) => v.driver_not_available_until_time)[0]}. Note:{' '}
                  {profileData?.drivers?.map((v) => v.note_not_available)[0]}{' '}
                </div>
              ) : (
                ''
              )}
              {profileData?.location_target === 'Equipment' && profileData?.equipment_gps?.motion?.motion && (
                <Chip
                  fontWeight={500}
                  fontSize='11px'
                  bgColor={motionColors[profileData?.equipment_gps.motion.motion].bgColor}
                  labelColor={motionColors[profileData?.equipment_gps.motion.motion].color}
                  style={{ textTransform: 'uppercase' }}
                  label={`${profileData?.equipment_gps?.motion?.motion} ${formatSeconds(
                    profileData?.equipment_gps?.motion.duration
                  )}`}
                />
              )}
              {profileData?.location_target === 'Driver' &&
                profileData?.drivers?.length &&
                profileData?.drivers?.[0]?.driver_gps?.motion && (
                  <Chip
                    fontWeight={500}
                    fontSize='11px'
                    bgColor={motionColors[profileData?.drivers?.[0]?.driver_gps?.motion?.motion]?.bgColor}
                    labelColor={motionColors[profileData?.drivers?.[0]?.driver_gps?.motion?.motion]?.color}
                    style={{ textTransform: 'uppercase' }}
                    label={`${profileData?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                      profileData?.drivers?.[0]?.driver_gps?.motion?.duration
                    )}`}
                  />
                )}
            </div>
          </div>
          <div className='mt-3 map-wrapper' ref={ref}>
            {!!Object.keys(profileData).length && (
              <Map
                longitude={longitude}
                latitude={latitude}
                mapLayersValues={mapLayersValues}
                profileData={profileData}
                heading={heading}
                hookTo={hookTo}
                ismapstyleChange={ismapstyleChange}
                resetMyMap={resetMyMap}
                reloadMap={reloadMap}
                stopPointVisible={stopPointVisible}
                POI={POI}
                stopPointAll={stopPointAll}
              />
            )}
            <div className='map-zooming-buttons d-flex gap-3' id='myMap'>
              <div className='d-flex flex-column gap-2'>
                <div className='equipment-tracing-history'>
                  <div style={{ position: 'relative' }}>
                    {isShowDatePicker && (
                      <DateRange
                        title='All Time'
                        dateTimeRange={dateRange}
                        dateSelect={dateSelect}
                        resetMyRoute={resetMyRoute}
                        setresetMyMap={setresetMyMap}
                        rangeName={rangeName}
                        setRangeName={setRangeName}
                        setIsShowDatePicker={setIsShowDatePicker}
                        timeLineOpen={timeLineOpen}
                        setIsCenter={setIsCenter}
                        isCenter={isCenter}
                      />
                    )}
                  </div>
                  <PrimaryBtn icon={Icons.calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
                </div>
                <div className='equipment-tracing-history'>
                  <PrimaryBtn icon={Icons.Info} title='Logs' onClick={() => setLogsOpen(true)} />
                </div>
              </div>
              <div>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Map Type')}>
                  <div className='map-zooming-feature-button-plus mb-2' aria-describedby={map} onClick={Maplayers}>
                    <MapLayersIcon />
                  </div>
                </OverlayTrigger>

                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Weather')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => mapOptionsValue('Weather')}
                    >
                      {visibleWeather ? (
                        <WeatherIcon fill='#4F5AED' width='16' height='16' />
                      ) : (
                        <WeatherIcon width='16' height='16' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Traffic')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => mapOptionsValue('Traffic')}
                    >
                      {visibleTraffic ? (
                        <TrafficIcon width='16' height='16' fill='#4F5AED' />
                      ) : (
                        <TrafficIcon width='16' height='16' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='left'
                  delay={{ show: 100, hide: 200 }}
                  overlay={renderTooltip('Stop Points')}
                >
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => setStopPointVisible(!stopPointVisible)}
                    >
                      {stopPointVisible ? (
                        <StopIcon width='16' height='16' fill='#4F5AED' />
                      ) : (
                        <StopIcon width='16' height='16' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Fuel Stops')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => setPOI(!POI)}
                    >
                      {POI ? (
                        <FuelIcon width='16' height='13' fill='#4F5AED' />
                      ) : (
                        <FuelIcon width='16' height='13' fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement='left'
                  delay={{ show: 100, hide: 200 }}
                  overlay={renderTooltip('Create Route')}
                >
                  <div className='map-stops-buttons mb-2'>
                    <div className='map-zooming-feature-button-plus' aria-describedby={map} onClick={handleStopClick}>
                      {driverCustomCost && customStopsData ? (
                        <PlannerIcon fill='#14804A' />
                      ) : (
                        <PlannerIcon fill='#687182' />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Notes')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => {
                        setOpenAddNotes(true);
                      }}
                    >
                      {profileData.notes_count ? (
                        <NoteIcon width={15} />
                      ) : (
                        <NoteIcon fill={palette.gray500} width={15} />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger placement='left' delay={{ show: 100, hide: 200 }} overlay={renderTooltip('Motions')}>
                  <div className='map-stops-buttons mb-2'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => {
                        setMotionLogsOpen(true);
                      }}
                    >
                      <Motion fill={palette.gray500} width={15} />
                    </div>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='left'
                  delay={{ show: 100, hide: 200 }}
                  overlay={renderTooltip('Planned Shipments')}
                >
                  <div className='map-stops-buttons mb-2 position-relative'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => setAddActionOpen(true)}
                    >
                      <ShipmentIcon fill={palette.gray500} width={15} />
                    </div>
                    {!!profileData?.planned_count && (
                      <Typography variant='c1' className='count-badge' style={{ color: palette.white }}>
                        {profileData.planned_count}
                      </Typography>
                    )}
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='left'
                  delay={{ show: 100, hide: 200 }}
                  overlay={renderTooltip('DOT Records')}
                >
                  <div className='map-stops-buttons mb-2 position-relative'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => setDotMonitoringOpen(true)}
                    >
                      <DotMonitoringIcon fill={palette.gray500} width={15} />
                    </div>
                    {!!unacknowledgedCount && (
                      <Typography variant='c1' className='count-badge' style={{ color: palette.white }}>
                        {unacknowledgedCount}
                      </Typography>
                    )}
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='left'
                  delay={{ show: 100, hide: 200 }}
                  overlay={renderTooltip('Equipment Inventory List')}
                >
                  <div className='map-stops-buttons mb-2 position-relative'>
                    <div
                      className='map-zooming-feature-button-plus'
                      aria-describedby={map}
                      onClick={() => setOpenInventoryList(true)}
                    >
                      <img src={inventoryIcon} alt='inventory' width={15} />
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <button
              className={show ? 'overlay-toggle-button' : 'toggle-button-on-hide'}
              ref={target}
              onClick={handleClick}
              style={{
                top: 0,
                position: 'absolute',
                backgroundColor: use(palette.white, palette.dark800),
              }}
            >
              <img className='overlay-menu-icon' src={show ? Icons.menuOndisplay : Icons.menuOnhide} alt='menu' />
            </button>

            {show === true ? (
              <div className='map-overlay' style={{ zIndex: '9999' }}>
                <div className='d-flex' style={{ margin: '26px 0 0 -75px', height: '10px' }}>
                  <Vehicle
                    profileData={profileData}
                    equipmentId={id}
                    init={init}
                    timeLine={timeLine}
                    timeLineClose={timeLineClose}
                    getDeleteSuccess={getDeleteSuccess}
                  />
                  <div className='d-flex flex-column gap-5' style={{ width: '55vw' }}>
                    {!!showStop && (
                      <div className='d-flex ms-3'>
                        <div className='overlay-equipment-details-container-stops' style={isWide}>
                          <div className='d-flex justify-content-between'>
                            <p
                              className='overlay-content mb-1'
                              style={{
                                color: use(palette.dark800, palette.gray200),
                              }}
                            >
                              Create Route
                            </p>
                            <img
                              onClick={() => setShowStop(false)}
                              className='overlay-menu-icon'
                              style={{
                                position: 'absolute',
                                right: '6px',
                                top: '0',
                              }}
                              src={Icons.menuOndisplay}
                              alt='menu'
                            />
                          </div>
                          <CustomStopPoint
                            addressValues={addressValues}
                            setAddressVales={setAddressVales}
                            addStopPoint={addStopPoint}
                            stopPointValues={stopPointValues}
                            setStopPointValues={setStopPointValues}
                            loadingStopPoints={loadingStopPoints}
                            setLoadingStopPoints={setLoadingStopPoints}
                            clearRoute={clearRoute}
                            routeInstance={routeInstance}
                            routeValues={routeValues}
                            setRouteValues={setRouteValues}
                          />
                        </div>
                        {customStopsData && driverCustomCost && (
                          <div
                            style={{ width: '21vw', marginLeft: '16px' }}
                            className='overlay-equipment-details-container-stops'
                          >
                            <div className='d-flex justify-content-between'>
                              <p
                                className='overlay-content mb-1'
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                }}
                              >
                                TRIP SUMMARY
                              </p>
                              <img
                                onClick={() => setShowStop(false)}
                                className='overlay-menu-icon'
                                style={{
                                  position: 'absolute',
                                  right: '6px',
                                  top: '0',
                                }}
                                src={Icons.menuOndisplay}
                                alt='menu'
                              />
                            </div>
                            <DriverCustomCost customStopsData={customStopsData} driverCustomCost={driverCustomCost} />
                          </div>
                        )}
                      </div>
                    )}
                    {!showStop && (
                      <div className='d-flex ms-3'>
                        <div
                          className='overlay-equipment-details-container-second '
                          style={{
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                        >
                          <div className='d-flex'>
                            <div
                              className='trend-icon'
                              style={{
                                backgroundColor: use(palette.gray50, palette.gray100),
                              }}
                            >
                              <img src={Icons.trend} alt='trend-icon' />
                            </div>
                            <div className='ms-2'>
                              <p
                                className='overlay-content mb-1'
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                }}
                              >
                                Mileage
                              </p>
                              <h6
                                className='overlay-numbers'
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {GrossRevenueData?.total_miles
                                  ? numberWithCommas(GrossRevenueData?.total_miles, 'miles')
                                  : 0}
                              </h6>
                              <p className=''>
                                <span
                                  onClick={() => handleEquipmentMileageOpen(profileData?.id)}
                                  className='d-flex justify-content-center'
                                  style={{
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '50%',
                                  }}
                                >
                                  <img src={down} alt='down' />
                                </span>
                              </p>
                            </div>
                            {(openEquipmentMileage || openGrossRevenue) && (
                              <EquipmentMileage
                                equipmentID={equipmentID}
                                open={openEquipmentMileage || openGrossRevenue}
                                onClose={() => {
                                  setOpenEquipmentMileage(false);
                                  setOpenGrossRevenue(false);
                                }}
                                isGrossRevenue={openGrossRevenue}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className='overlay-equipment-details-container-second ms-3'
                          style={{
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                        >
                          <div className='d-flex'>
                            <div className='trend-icon '>
                              <img src={Icons.data} alt='trend-icon' className='ms-1' />
                            </div>
                            <div className='ms-2'>
                              <p
                                className='overlay-content mb-1'
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                }}
                              >
                                Gross revenue
                              </p>
                              <h6
                                className='overlay-numbers'
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {currency}{' '}
                                {GrossRevenueData?.gross_revanue ? formatAmount(GrossRevenueData?.gross_revanue) : 0}
                              </h6>
                              <p className=''>
                                <span
                                  onClick={() => handleGrossRevenueOpen(profileData?.id)}
                                  className='d-flex justify-content-center'
                                  style={{
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '50%',
                                  }}
                                >
                                  <img src={down} alt='down' />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className=' overlay-equipment-details-container-second ms-3'
                          style={{
                            backgroundColor: use(palette.white, palette.dark800),
                          }}
                        >
                          <div className='d-flex'>
                            <div className='trend-icon maintenance-cost-icon'>
                              {/* <img src={Icons.doller} alt='trend-icon' className='ms-2' /> */}
                              {currency}
                            </div>
                            <div className='ms-2'>
                              <p
                                className='overlay-content mb-1'
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                }}
                              >
                                Maintenance costs
                              </p>
                              <h6
                                className='overlay-numbers'
                                style={{
                                  color: use(palette.dark800, palette.gray200),
                                }}
                              >
                                {MaintanceCostValue?.allSum ? formatAmount(MaintanceCostValue?.allSum) : 0}
                              </h6>
                              <p className=''>
                                <span className='overlay-sub-content'>
                                  {MaintanceCostValue
                                    ? MaintanceCostValue?.percentageIncrease
                                      ? Number(MaintanceCostValue?.percentageIncrease).toFixed(2)
                                      : MaintanceCostValue?.percentageIncrease
                                    : 0}
                                  %
                                </span>
                                <span
                                  className='mileage-text ms-2'
                                  style={{
                                    color: use(palette.dark800, palette.gray200),
                                  }}
                                >
                                  from previous month
                                </span>
                                <span
                                  onClick={handleMaintenanceCostOpen}
                                  className='d-flex justify-content-center'
                                  style={{
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '50%',
                                  }}
                                >
                                  <img src={down} alt='down' />
                                </span>
                                <Modal
                                  open={openmaintenencecost}
                                  onClose={handleMaintenanceCostClose}
                                  aria-labelledby='modal-modal-title'
                                  aria-describedby='modal-modal-description'
                                  style={{ zIndex: 1055 }}
                                >
                                  <MaintenanceCost
                                    profileData={profileData}
                                    handleMaintenanceCostClose={handleMaintenanceCostClose}
                                    equipmentMaintanance={equipmentMaintanance}
                                  />
                                </Modal>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ' '
            )}
          </div>
        </div>
      </EquipmentDetailsContext.Provider>
      <Popover
        id={map}
        open={open}
        anchorEl={anchorEl}
        onClose={MapLayersHandleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <p
            onClick={() => mapLayersValue('TRANSPORTATION_DARK')}
            className='mapLayersMenu'
            style={{
              cursor: 'pointer',
              color: mapLayersValues === 'TRANSPORTATION_DARK' ? '#4F5AED' : '',
              padding: '2px 6px',
            }}
          >
            Dark
          </p>
          <p
            onClick={() => mapLayersValue('BASIC')}
            className='mapLayersMenu'
            style={{
              cursor: 'pointer',
              color: mapLayersValues === 'BASIC' ? '#4F5AED' : '',
              padding: '2px 6px',
            }}
          >
            {' '}
            Light
          </p>
          <p
            onClick={() => mapLayersValue('SATELLITE')}
            className='mapLayersMenu'
            style={{
              cursor: 'pointer',
              color: mapLayersValues === 'SATELLITE' ? '#4F5AED' : '',
              padding: '2px 6px',
            }}
          >
            Satellite
          </p>
        </Typography>
      </Popover>
      <Modal open={openoutofservice} onClose={handleOutOfServiceClose}>
        <OutofserviceStatus handleOutOfServiceClose={handleOutOfServiceClose} id={profileData?.id} init={init} />
      </Modal>

      <Modal
        open={updateEquipmentID}
        onClose={handleCloseEquipmentID}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {/* <Fade in={reserveEquipment}> */}
        <Box sx={styles}>
          <UpdateEquipmentID
            id={profileData?.id}
            handleClose={handleCloseEquipmentID}
            setShowMessage={setShowMessage}
            equipmentID={profileData?.equipment_id}
            init={(value) => init(value)}
          />
        </Box>
      </Modal>

      {logsOpen && <EquipmentLogs open={logsOpen} profileData={profileData} onClose={() => setLogsOpen(false)} />}
      {motionLogsOpen && (
        <MotionsLogs open={motionLogsOpen} profileData={profileData} onClose={() => setMotionLogsOpen(false)} />
      )}

      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      {openAddNotes && (
        <AddEquipmentNotes
          open={openAddNotes}
          onClose={() => {
            setOpenAddNotes(false);
            init();
          }}
          data={profileData}
          onSuccess={() => {
            init();
          }}
        />
      )}
      {addActionOpen && (
        <PlannedLoads
          open={addActionOpen}
          shipmentData={{ equipment: profileData }}
          onClose={() => setAddActionOpen(false)}
          hideView
        />
      )}
      {!!dotMonitoringOpen && (
        <DotRecords open={!!dotMonitoringOpen} onClose={() => setDotMonitoringOpen(false)} hideView />
      )}
      {!!openInventoryList && (
        <InventoryList
          open={openInventoryList}
          equipmentId={profileData?.id}
          idOfEquipment={profileData?.equipment_id}
          equipmentType={profileData?.equipment_type?.title}
          onClose={() => setOpenInventoryList(false)}
        />
      )}
    </>
  );
};

export default EquipmentDetails;

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  outline: 0,
};
