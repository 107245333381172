import styled from 'styled-components';

export const SCarrierNameImage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .carrier-image {
    border-radius: 50%;
    object-fit: cover;
  }
`;
