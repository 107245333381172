import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Search from 'common/Search';
import { PusherJs } from 'common/pusher';
import TableSkeleton from 'common/TableSkeleton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { getShipmentOfferOptions } from 'Api/PlannerV2';
import BidNotes from 'pages/OfferedShipments/ShipmentDetails/components/BidNotes';
import { getShipmentBids, updatePreferBid } from 'Api/OfferedShipments';
import { bidsConverter, getFilteredBids } from './converters';
import { useColumns } from './BidHistory.data';

const BidHistory = ({ shipment }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(true);
  const [offerOptions, setOfferOptions] = useState(null);
  const [bids, setBids] = useState([]);
  const [search, setSearch] = useState('');
  const [bidToView, setBidToView] = useState(null);
  const [loadingPreferred, setLoadingPreferred] = useState(false);
  const [sort, setSort] = useState({ field: 'margin', sortBy: 'asc' });
  const { value: userData } = useAuth();
  const debouncedSearch = useDebounce(search, 500);

  const filteredBids = useMemo(() => getFilteredBids(bids, sort, shipment), [bids, sort, shipment]);

  const getOfferOptions = async () => {
    try {
      const { data } = await getShipmentOfferOptions(id);
      setOfferOptions({
        ...data,
        expire_date: moment.utc(data.posted_date).add(data.expire_shipment_offer, data.expire_shipment_offer_unit),
      });
    } catch (e) {
      // Do nothing
    }
  };

  const getBids = async () => {
    try {
      const sortField = `sort[${sort.nested_field || ''}][${sort.field}]`;

      const params = {
        shipment_id: id,
        query: debouncedSearch || undefined,
        [sortField]: sort.sortBy,
      };

      const { data } = await getShipmentBids(params);
      if (!data?.length) {
        navigate('/offered-shipments');
      }

      setBids(bidsConverter(data, shipment));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onNoteClick = (bid) => {
    setBidToView(bid);
  };

  const onUpdatePreferBid = async (bid) => {
    if (loadingPreferred) {
      return;
    }

    setLoadingPreferred(true);
    try {
      await updatePreferBid(bid.carrier?.id, { preferred: bid.carrier?.preferred ? 0 : 1 });
      getBids();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingPreferred(false);
    }
  };

  useEffect(() => {
    getOfferOptions();
  }, []);

  useEffect(() => {
    if (shipment) {
      const channelName = `private-shipment-offers-update.${userData.user.customer.dot}-${shipment.shipment_id}`;
      const channel = PusherJs.subscribe(channelName);
      channel.bind('shipment-offers-update', () => {
        getBids();
      });

      return () => {
        PusherJs.unsubscribe(channelName);
      };
    }
  }, [shipment]);

  useEffect(() => {
    if (!shipment) {
      return;
    }

    setLoading(true);
    getBids();
  }, [debouncedSearch, shipment]);

  const columns = useColumns({
    shipment,
    onNoteClick,
    sort,
    sortingQuery,
    onUpdatePreferBid,
    offerOptions,
  });

  return (
    <div className='mt-4'>
      <div className='d-flex align-items-center gap-3 mt-3 mb-3'>
        <Search search={search} onChange={setSearch} width='300px' />
      </div>
      <div className='d-flex gap-2 mt-4'>
        <div className='w-100'>
          {loading ? (
            <TableSkeleton />
          ) : (
            <MaterialTableWrapper
              key={filteredBids?.length}
              data={filteredBids}
              rowPerPage={filteredBids.length}
              style={{ backgroundColor: palette.white }}
              columns={columns}
              components={{
                Pagination: () => null,
              }}
              options={{
                toolbar: false,
                sorting: false,
                pageSize: filteredBids.length,
                rowStyle: (row) => ({ background: [2, 6].includes(row.offer_accepted) ? palette.red0 : '' }),
              }}
            />
          )}
        </div>
      </div>
      {!!bidToView && (
        <BidNotes open={!!bidToView} onClose={() => setBidToView(null)} bid={bidToView} shipment={shipment} />
      )}
    </div>
  );
};

export default BidHistory;
