import axios from '../services/axios';

export async function getAccountTypes(params) {
  const { data } = await axios.get('account/get-all-account-type', { params });
  return data;
}

export async function getCostTypes(params) {
  const { data } = await axios.get('account/all-cost-type', { params });
  return data;
}

export async function getAccountsList(params) {
  const { data } = await axios.get('account/get-all-account-list', { params });
  return data;
}

export async function createAccountType(body) {
  const { data } = await axios.post('account/create-account-type', body);
  return data;
}

export async function createAccount(body) {
  const { data } = await axios.post('account/create-account-list', body);
  return data;
}

export async function updateAccountType(id, body) {
  const { data } = await axios.put(`account/update-account-type/${id}`, body);
  return data;
}

export async function updateAccount(id, body) {
  const { data } = await axios.post(`account/update-account-list/${id}`, body);
  return data;
}

export async function deleteAccountType(id) {
  const { data } = await axios.delete(`account/delete-account-type/${id}`);
  return data;
}

export async function deleteAccount(id) {
  const { data } = await axios.delete(`account/delete-account-type/${id}`);
  return data;
}

export async function getBankAccountTransactions(params) {
  const { data } = await axios.get(`account/get-all-accounting-account-bank`, { params });
  return data;
}

export async function createBankAccountTransaction(body) {
  const { data } = await axios.post(`account/create-accounting-account-bank`, body);
  return data;
}

export async function updateBankAccountTransaction(id, body) {
  const { data } = await axios.put(`account/update-accounting-account-bank/${id}`, body);
  return data;
}

export async function deleteBankAccountTransaction(id) {
  const { data } = await axios.delete(`account/delete-accounting-account-bank/${id}`);
  return data;
}

export async function getAccountTransactions(params) {
  const { data } = await axios.get(`account/get-all-accounting-account`, { params });
  return data;
}

export async function createAccountTransaction(body) {
  const { data } = await axios.post(`account/create-accounting-account`, body);
  return data;
}

export async function updateAccountTransaction(id, body) {
  const { data } = await axios.put(`account/update-accounting-account/${id}`, body);
  return data;
}

export async function deleteAccountTransaction(id) {
  const { data } = await axios.delete(`account/delete-accounting-account/${id}`);
  return data;
}

export async function getFcaSessionId(params) {
  const { data } = await axios.get(`account/generate-fca-session-id`, { params });
  return data;
}

export async function syncBankFeed(params) {
  const { data } = await axios.get(`account/fetch-bank-feed-data`, { params });
  return data;
}

export async function getBankFeed(params) {
  const { data } = await axios.get(`account/get-bank-feed-data`, { params });
  return data;
}

export async function reconcileBankFeed(body) {
  const { data } = await axios.post(`account/reconcile-bank-feed-data`, body);
  return data;
}

export async function getPayeeList(params) {
  const { data } = await axios.get(`account/all-payee-list`, { params });
  return data;
}

export async function createCustomer(body) {
  const { data } = await axios.post(`customer/customer`, body);
  return data;
}

export async function getBillingData(params) {
  const { data } = await axios.get(`account/get-billing-data`, { params });
  return data;
}

export async function applyPaymentToShipment(body) {
  const { data } = await axios.post(`account/apply-payment-to-shipment`, body);
  return data;
}

export async function getRules(accountId, params) {
  const { data } = await axios.get(`account/get-all-rules/${accountId}`, { params });
  return data;
}

export async function createRule(body) {
  const { data } = await axios.post('account/create-rule', body);
  return data;
}

export async function updateRule(id, body) {
  const { data } = await axios.put(`account/update-rule/${id}`, body);
  return data;
}

export async function deleteRule(id) {
  const { data } = await axios.delete(`account/delete-rule/${id}`);
  return data;
}

export async function importBankFeedCsv(body) {
  const { data } = await axios.post(`account/import-bank-feed-data`, body);
  return data;
}

export async function importTransactionsCsv(body) {
  const { data } = await axios.post(`account/import-accounting-account-data`, body);
  return data;
}

export async function deleteBankFeedTransactions(body) {
  const { data } = await axios.post(`account/delete-bank-feed-data`, body);
  return data;
}

export async function getPlaidItemToken(body) {
  const { data } = await axios.post(`account/plaid-update-item`, body);
  return data;
}

export async function updatePlaidItem(body) {
  const { data } = await axios.post(`account/plaid-update-done`, body);
  return data;
}

export async function getTransactionDetails(body) {
  const { data } = await axios.post(`account/get-payment-details`, body);
  return data;
}
