import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { formatAmount } from 'services/IFTAServices';
import { quarterRange } from '../../IFTA.data';

const ExportData = ({ jurisdictionData, year, quarter, fuelType }) => {
  const [anchorTag, setAnchorTag] = useState('');
  let totalMiles = 0;
  let totalGallons = 0;

  const handleDownloadPdf = () => {
    if (!jurisdictionData?.data?.length) {
      return;
    }

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.setFontSize(20);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#171C26');
    doc.text('ACME CORP', pageWidth / 2, 15, { align: 'center' });
    doc.setDrawColor(188, 194, 206);
    doc.line(16, 33, pageWidth - 16, 33);
    doc.setFontSize(12);
    doc.text(`IFTA ${quarter}, ${year}`, pageWidth / 2, 22, { align: 'center' });
    doc.setFont('Inter', 'normal');
    doc.setTextColor('#171C26');
    doc.setFontSize(10);
    doc.text(`${fuelType?.type} - Fleet By Jurisdiction`, 16, 31);
    doc.setFontSize(11);
    doc.setTextColor('#464F60');
    doc.text(`${quarterRange[quarter]}, ${year}`, pageWidth / 2, 31, { align: 'center' });
    doc.setFontSize(12);
    doc.setTextColor('#171C26');
    doc.text('Jurisdiction', 30, 50);
    doc.text('Miles', 100, 50);
    doc.text('Gallons', 150, 50);
    doc.setDrawColor(233, 237, 245);
    doc.line(30, 53, 165, 53);
    doc.setFont('Inter', 'normal', 400);

    const addListItem = (list, page, totalPages) => {
      const isFirst = page === 1;
      const isLast = page === totalPages;

      doc.setFontSize(10);
      doc.setTextColor('#464F60');
      doc.text(`${page} of ${totalPages}`, 16, 284);
      doc.setTextColor('#171C26');
      doc.setFontSize(11);

      if (!isFirst) {
        doc.setFontSize(12);
        doc.setTextColor('#171C26');
        doc.text('Jurisdiction', 30, 20);
        doc.text('Miles', 100, 20);
        doc.text('Gallons', 150, 20);
        doc.setDrawColor(233, 237, 245);
        doc.line(30, 23, 165, 23);
        doc.setFont('Inter', 'normal', 400);
        doc.setFontSize(11);
      }

      let i = 0;
      list?.forEach((data, idx) => {
        i += 8;

        totalMiles += Number(data.total);
        totalGallons += Number(data.gallons);

        doc.text(`${data.jurisdiction}`, 38, (isFirst ? 50 : 20) + i);
        doc.text(`${formatAmount(data.total)}`, 100, (isFirst ? 50 : 20) + i);
        doc.text(`${formatAmount(data.gallons)}`, 150, (isFirst ? 50 : 20) + i);
        if (isLast && idx === list.length - 1) {
          doc.setFontSize(12);
          doc.line(30, (isFirst ? 53 : 23) + i, 165, (isFirst ? 53 : 23) + i);
          doc.setFont('Inter', 'normal', 700);
          doc.text('Total', 36, (isFirst ? 60 : 30) + i);
          doc.setFont('Inter', 'normal', 400);
          doc.text(`${formatAmount(totalMiles)}`, 100, (isFirst ? 60 : 30) + i);
          doc.text(`${formatAmount(totalGallons)}`, 150, (isFirst ? 60 : 30) + i);
        }
      });
    };

    const firstArr = jurisdictionData.data.slice(0, 25);
    const rest = jurisdictionData.data.slice(25);
    const totalPages = Math.ceil(rest.length / 30) + 1;
    addListItem(firstArr, 1, totalPages);

    if (rest.length) {
      for (let i = 0; i < totalPages - 1; i++) {
        const newArray = rest.slice(i * 30, i * 30 + 30);

        if (newArray.length > 0) {
          doc.addPage();
          addListItem(newArray, i + 2, totalPages);
        }
      }
    }

    doc.setFontSize(10);
    doc.setTextColor('#464F60');
    doc.text('Powered by Truckin Digital', 150, 284);

    doc.save('jurisdiction.pdf');
  };

  const exportInCSV = () => {
    const jsonObject = JSON.stringify(jurisdictionData?.data);

    function ConvertToCSV(objArray) {
      const titles = { vehicle: 'Vehicle', state: 'State', gallons: 'Gallons', miles: 'Miles' };
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      const arrayToConvert = [titles, ...array];

      let str = '';
      for (let i = 0; i < arrayToConvert.length; i++) {
        let line = '';
        for (const index in arrayToConvert[i]) {
          if (line !== '') line += ',';

          line += arrayToConvert[i][index];
        }
        str += `${line}\r\n`;
      }
      return str;
    }

    const str = ConvertToCSV(jsonObject);
    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const objUrl = URL.createObjectURL(blob);
    setAnchorTag(objUrl);
  };

  useEffect(() => {
    if (jurisdictionData.data !== undefined) {
      exportInCSV();
    }
  }, [jurisdictionData]);

  return (
    <div className='export-data'>
      <span className='export-text'>Export:</span>
      <span
        className={`export-by-btn ${!jurisdictionData?.data?.length ? 'non-clickable' : ''}`}
        onClick={handleDownloadPdf}
      >
        PDF
      </span>
      <a href={anchorTag} download className={`export-by-btn ${!anchorTag ? 'non-clickable' : ''}`}>
        CSV
      </a>
    </div>
  );
};

export default ExportData;
