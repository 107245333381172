import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { addDays, startOfDay } from 'date-fns';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Loader from 'common/Loader';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { customerTimeToUtc } from 'utils/helpers';
import { getDriverHosDailyLogs, getTrailerList, getVehicleList } from 'Api/HOS';
import UploadPaperLog from '../UploadPaperLog';
import { CollapsibleTable } from '../CollapsiableTable';
import { SPageWrapper } from '../Hos-Logbook.styles';

const LogbookList = ({
  getDriverHoursOfServiceData = () => null,
  isModalView = false,
  driverId,
  startTime,
  endTime,
}) => {
  const { id } = useParams();
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -7)),
    end: startOfDay(addDays(new Date(), +1)),
  });
  const [dailyLogs, setDailyLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openUploadPaperLog, setOpenUploadPaperLog] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [trailers, setTrailers] = useState([]);

  const getDailyLogs = async () => {
    try {
      setLoading(true);
      const dailyLogsParams = {
        'filters[from_date]': startTime
          ? moment(startTime).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          : dateRange.start
          ? customerTimeToUtc(dateRange.start, 'YYYY-MM-DD HH:mm:ss')
          : undefined,
        'filters[to_date]': endTime
          ? moment(endTime).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          : dateRange.end
          ? customerTimeToUtc(dateRange.end, 'YYYY-MM-DD HH:mm:ss')
          : undefined,
      };
      const { data } = await getDriverHosDailyLogs(id || driverId, dailyLogsParams);
      setDailyLogs(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getVehicles = async () => {
    try {
      const { data } = await getVehicleList();
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getTrailers = async () => {
    try {
      const { data } = await getTrailerList();
      setTrailers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDailyLogs();
  }, [dateRange]);

  useEffect(() => {
    getVehicles();
    getTrailers();
  }, []);

  return (
    <SPageWrapper>
      {!isModalView && (
        <div className='d-flex align-items-center justify-content-between mt-2 mb-2'>
          <DateRangePicker top='-108px' dateRange={dateRange} setDateRange={setDateRange} type='full' />
          <CustomButton
            title='Upload Paper Log'
            styleButton={{ margin: 0, padding: '6px 12px' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            leftIcon={<PlusIcon fill={palette.white} className='me-2' />}
            onClick={() => setOpenUploadPaperLog(true)}
          />
        </div>
      )}

      {loading ? (
        <Loader loading={loading} />
      ) : (
        <CollapsibleTable
          dailyLogs={dailyLogs}
          vehicles={vehicles}
          trailers={trailers}
          onDeleteSuccess={() => {
            getDailyLogs();
            getDriverHoursOfServiceData();
          }}
          driverId={driverId}
        />
      )}
      {!!openUploadPaperLog && (
        <UploadPaperLog
          open={openUploadPaperLog}
          onClose={() => setOpenUploadPaperLog(false)}
          onSuccess={() => {
            getDailyLogs();
            getDriverHoursOfServiceData();
          }}
          driverId={id}
          vehicles={vehicles}
          trailers={trailers}
        />
      )}
    </SPageWrapper>
  );
};

export default LogbookList;
