import React from 'react';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { STitleWrapper } from '../ApplyJob.styles';

const StepTitle = ({ title }) => {
  const { use } = useTheme();

  return (
    <STitleWrapper>
      <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray200) }}>
        {title}
      </Typography>
    </STitleWrapper>
  );
};

export default StepTitle;
