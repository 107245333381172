import jsPDF from 'jspdf';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';
import { formatPhoneNumber } from 'utils/helpers';

const sectionHeaders = {
  1: 'General Information',
  2: 'Driver',
  3: 'Equipment',
  4: 'Shipment',
};

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createHeader = (doc, formatDateTime, accident, currentPage) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(`Report ${accident.report_number || ''}`, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(12);
  doc.text(`${sectionHeaders[currentPage] || ''}`, pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`Date/Time of Report: ${formatDateTime(accident.report_date_time)}`, 16, 22);
  doc.text(
    `Person Taking Report: ${accident.person_taking_report.fname || accident.person_taking_report.first_name} ${
      accident.person_taking_report.lname || accident.person_taking_report.last_name
    }`,
    pageWidth - 16,
    22,
    {
      align: 'right',
    }
  );

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(16, 24, pageWidth - 16, 24);
};

export const generatePdf = async ({ accident, formatDateTime }) => {
  const doc = new jsPDF({ format: 'letter' });
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  // General Information
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);

  doc.text('Date and Time of Accident', 35, 34, { align: 'center' });
  doc.text('Location of Accident (City & State)', pageWidth / 2, 34, { align: 'center' });
  doc.text('Police Report', pageWidth - 32, 34, { align: 'center' });

  doc.text('Police Report Number', 35, 50, { align: 'center' });
  doc.text('Were Any Citations Issues?', pageWidth / 2, 50, { align: 'center' });

  doc.text('Driver', 35, 66, { align: 'center' });
  doc.text('Vehicle', pageWidth / 2, 66, { align: 'center' });
  doc.text('Trailer', pageWidth - 32, 66, { align: 'center' });

  doc.text('Incident', 35, 82, { align: 'center' });
  doc.text('Report Number', pageWidth / 2, 82, { align: 'center' });
  doc.text('Type of Crash', pageWidth - 32, 82, { align: 'center' });

  doc.text('Control Device', 35, 98, { align: 'center' });
  doc.text('Shipment', pageWidth / 2, 98, { align: 'center' });
  doc.text('Specific Stop', pageWidth - 32, 98, { align: 'center' });

  doc.text('Speed Limit', 35, 114, { align: 'center' });
  doc.text('Road Type', pageWidth / 2, 114, { align: 'center' });
  doc.text('Road Control', pageWidth - 32, 114, { align: 'center' });

  doc.text('Injuries', 35, 130, { align: 'center' });
  doc.text('Fatalities', pageWidth / 2, 130, { align: 'center' });
  doc.text('Weather', pageWidth - 32, 130, { align: 'center' });

  doc.text('Light Condition', 35, 146, { align: 'center' });
  doc.text('Road Condition', pageWidth / 2, 146, { align: 'center' });
  doc.text('Towing', pageWidth - 32, 146, { align: 'center' });

  doc.text('Hazmat', 35, 162, { align: 'center' });
  doc.text('Preventable', pageWidth / 2, 162, { align: 'center' });

  doc.text('Drivers Family Notified', 35, 182, { align: 'center' });
  if (accident.driver_family_notified) {
    doc.text('Name', 18, 192);
    doc.text('Telephone', 70, 192);
    doc.text('Date/Time Notified', 120, 192);
  }

  doc.text('Tow Service Notified', 35, 212, { align: 'center' });
  if (accident.tow_service_notified) {
    doc.text('Name', 18, 222);
    doc.text('Address of Tow', 70, 222);
    doc.text('Telephone', 170, 222);
    doc.text('Date/Time Notified', 18, 235);
  }

  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${accident?.collision_date_time ? formatDateTime(accident?.collision_date_time) : '-'}`, 35, 38, {
    align: 'center',
  });
  doc.text(`${accident?.accident_location || '-'}`, pageWidth / 2, 38, { align: 'center' });
  doc.text(`${accident?.police_report ? 'Yes' : 'No'}`, pageWidth - 32, 38, { align: 'center' });

  doc.text(`${accident?.police_report_number || '-'}`, 35, 54, { align: 'center' });
  doc.text(`${accident?.citation_issued ? 'Yes' : 'No'}`, pageWidth / 2, 54, { align: 'center' });

  doc.text(`${accident?.driver?.fname || '-'} ${accident?.driver?.lname || ''}`, 35, 70, { align: 'center' });
  doc.text(
    `${accident?.vehicle?.equipment_id || '-'} ${accident?.vehicle?.equipment_type?.title || ''}`,
    pageWidth / 2,
    70,
    { align: 'center' }
  );
  doc.text(
    `${accident?.trailer?.equipment_id || '-'} ${accident?.trailer?.equipment_type?.title || ''}`,
    pageWidth - 32,
    70,
    { align: 'center' }
  );

  doc.text(`${accident?.incident?.incident_id || '-'}`, 35, 86, { align: 'center' });
  doc.text(`${accident?.report_number || '-'}`, pageWidth / 2, 86, { align: 'center' });
  doc.text(`${accident?.type_of_crash?.name || '-'}`, pageWidth - 32, 86, { align: 'center' });

  doc.text(`${accident?.control_device_type?.name || '-'}`, 35, 102, { align: 'center' });
  doc.text(`${accident?.shipment?.shipment_id || '-'}`, pageWidth / 2, 102, { align: 'center' });
  doc.text(`${accident.shipment_stop?.stop_point?.location_name || '-'}`, pageWidth - 32, 102, { align: 'center' });

  doc.text(`${accident.speed_limit || '-'}`, 35, 118, { align: 'center' });
  doc.text(`${accident.road_type?.name || '-'}`, pageWidth / 2, 118, { align: 'center' });
  doc.text(`${accident.road_control?.name || '-'}`, pageWidth - 32, 118, { align: 'center' });

  doc.text(`${accident.injuries || '-'}`, 35, 134, { align: 'center' });
  doc.text(`${accident.fatalities || '-'}`, pageWidth / 2, 134, { align: 'center' });
  doc.text(`${accident.weather?.name || '-'}`, pageWidth - 32, 134, { align: 'center' });

  doc.text(`${accident.light_condition?.name || '-'}`, 35, 150, { align: 'center' });
  doc.text(`${accident.road_condition?.name || '-'}`, pageWidth / 2, 150, { align: 'center' });
  doc.text(`${accident.towing ? 'Yes' : 'No'}`, pageWidth - 32, 150, { align: 'center' });

  doc.text(`${accident.hazmat ? 'Yes' : 'No'}`, 35, 166, { align: 'center' });
  doc.text(`${accident.preventable ? 'Yes' : 'No'}`, pageWidth / 2, 166, { align: 'center' });

  doc.text(`${accident.driver_family_notified ? 'Yes' : 'No'}`, 35, 187, { align: 'center' });
  if (accident.driver_family_notified) {
    doc.text(
      `${accident.driver_family_notified_to_first_name || ''} ${accident.driver_family_notified_to_last_name || ''}`,
      18,
      197
    );
    doc.text(
      `${
        accident.driver_family_notified_to_telephone
          ? formatPhoneNumber(accident.driver_family_notified_to_telephone)
          : '-'
      }`,
      70,
      197
    );
    doc.text(
      `${accident.driver_family_notified_datetime ? formatDateTime(accident.driver_family_notified_datetime) : '-'}`,
      120,
      197
    );
  }

  doc.text(`${accident.tow_service_notified ? 'Yes' : 'No'}`, 35, 217, { align: 'center' });
  if (accident.tow_service_notified) {
    doc.text(`${accident.tow_service_notified_name || '-'}`, 18, 227);
    doc.text(`${accident.tow_service_notified_address || '-'}`, 70, 227);
    doc.text(
      `${accident.tow_service_notified_telephone ? formatPhoneNumber(accident.tow_service_notified_telephone) : '-'}`,
      170,
      227
    );
    doc.text(
      `${accident.tow_service_notified_datetime ? formatDateTime(accident.tow_service_notified_datetime) : '-'}`,
      18,
      240
    );
  }

  doc.addPage();

  // Driver
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);

  doc.text('Driver Name', 35, 34, { align: 'center' });
  doc.text('Driver Injured', pageWidth / 2, 34, { align: 'center' });
  doc.text('Injury Type', pageWidth - 32, 34, { align: 'center' });

  doc.text('Treating Facility', 35, 50, { align: 'center' });
  doc.text('Treating Facility Telephone', pageWidth / 2, 50, { align: 'center' });
  doc.text('Alcohol Test Conducted (2 to 8 Hours Post Max)', pageWidth - 32, 50, { align: 'center', maxWidth: 40 });

  doc.text('Was the injury OSHA Recordable?', 35, 66, { align: 'center' });
  doc.text('Was the injury Workers Comp Recordable?', pageWidth / 2, 66, { align: 'center' });
  doc.text('Drug Test Conducted (32 Hours Post Max)', pageWidth - 32, 66, { align: 'center', maxWidth: 38 });

  doc.setFontSize(11);
  doc.text('Driver Incident', pageWidth / 2, 90, { align: 'center' });

  doc.setFontSize(9);
  doc.text('Incident ID', 35, 96, { align: 'center' });
  doc.text('Report Number', pageWidth / 2, 96, { align: 'center' });
  doc.text('Incident Date / Time', pageWidth - 32, 96, { align: 'center' });

  doc.text('Type of Incident', 35, 112, { align: 'center' });
  doc.text('Location of Incident', pageWidth / 2, 112, { align: 'center' });

  doc.text('Action Taken', 35, 128, { align: 'center' });
  doc.text('Action By', pageWidth / 2, 128, { align: 'center' });

  doc.text('Report Details', 20, 149);
  doc.text('Reason(s) given by Person Implicated', 20, 179);
  doc.text('Notes', 20, 209);
  if (accident?.incident?.signature) {
    doc.text('Driver Signature', 20, 234);
  }

  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${accident?.driver?.fname || '-'} ${accident?.driver?.lname || ''}`, 35, 38, {
    align: 'center',
  });
  doc.text(`${accident?.driver_injured ? 'Yes' : 'No'}`, pageWidth / 2, 38, { align: 'center' });
  doc.text(`${accident?.injury_type || '-'}`, pageWidth - 32, 38, { align: 'center' });

  doc.text(`${accident?.treating_facility || '-'}`, 35, 54, { align: 'center' });
  doc.text(
    `${accident?.treating_facility_telephone ? formatPhoneNumber(accident?.treating_facility_telephone) : '-'}`,
    pageWidth / 2,
    54,
    { align: 'center' }
  );
  doc.text(`${accident.alcohol_test_conducted ? 'Yes' : 'No'}`, pageWidth - 32, 59, { align: 'center' });

  doc.text(`${accident?.injury_osha_reportable ? 'Yes' : 'No'}`, 35, 70, { align: 'center' });
  doc.text(`${accident?.injury_worker_comp_recordable ? 'Yes' : 'No'}`, pageWidth / 2, 70, { align: 'center' });
  doc.text(`${accident.drug_test_conducted ? 'Yes' : 'No'}`, pageWidth - 32, 75, { align: 'center' });

  doc.text(`${accident?.incident?.incident_id || '-'}`, 35, 101, { align: 'center' });
  doc.text(`${accident?.incident?.report_number || '-'}`, pageWidth / 2, 101, { align: 'center' });
  doc.text(
    `${
      accident?.incident?.incident_date
        ? `${formatDateTime(`${accident?.incident?.incident_date} ${accident?.incident?.incident_time}`)}`
        : '-'
    }`,
    pageWidth - 32,
    101,
    { align: 'center' }
  );

  doc.text(
    `${
      accident?.incident?.custom_incident_type
        ? accident?.incident?.custom_incident_type
        : accident?.incident?.incident_type?.type
        ? accident?.incident?.incident_type?.type
        : '-'
    }`,
    35,
    117,
    { align: 'center' }
  );
  doc.text(
    accident?.incident?.city?.name || accident?.incident?.state?.name || accident?.incident?.country?.name
      ? `${accident?.incident?.city?.name || ''}${accident?.incident?.city?.name ? ',' : ''} ${
          accident?.incident?.state?.name || ''
        }${accident?.incident?.state?.name ? ',' : ''} ${accident?.incident?.country?.name || ''}`
      : '-',
    pageWidth / 2,
    117,
    { align: 'center' }
  );

  doc.text(`${accident?.incident?.incident_action_taken?.action || '-'}`, 35, 133, { align: 'center' });
  doc.text(
    accident?.incident?.added_by?.first_name
      ? `${accident?.incident?.added_by?.first_name} ${accident?.incident?.added_by?.last_name || ' '}`
      : '-',
    pageWidth / 2,
    133,
    { align: 'center' }
  );

  doc.text(`${accident?.incident?.report_details || '-'}`, 20, 154, { maxWidth: pageWidth - 32 });
  doc.text(`${accident?.incident?.reason || '-'}`, 20, 184, { maxWidth: pageWidth - 32 });
  doc.text(`${accident?.incident?.notes || '-'}`, 20, 214, { maxWidth: pageWidth - 32 });
  if (accident?.incident?.signature) {
    doc.addImage(`${accident?.incident?.signature}?r=${Math.random()}`, 'JPEG', 20, 239, 40, 20);
  }

  doc.addPage();

  // Equipment
  doc.setFontSize(11);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);

  doc.text('Vehicle', 50, 40, { align: 'center' });
  doc.text('Trailer', pageWidth - 50, 40, { align: 'center' });

  doc.setFontSize(9);
  doc.text('Unit Number', 50, 50, { align: 'center' });
  doc.text('Unit Number', pageWidth - 50, 50, { align: 'center' });

  doc.text('Year', 50, 66, { align: 'center' });
  doc.text('Year', pageWidth - 50, 66, { align: 'center' });

  doc.text('Make', 50, 82, { align: 'center' });
  doc.text('Make', pageWidth - 50, 82, { align: 'center' });

  doc.text('Owner', 50, 98, { align: 'center' });
  doc.text('Owner', pageWidth - 50, 98, { align: 'center' });

  doc.text('Damage', 50, 114, { align: 'center' });
  doc.text('Damage', pageWidth - 50, 114, { align: 'center' });

  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${accident?.vehicle_unit_number || '-'}`, 50, 55, { align: 'center' });
  doc.text(`${accident?.trailer_unit_number || '-'}`, pageWidth - 50, 55, { align: 'center' });

  doc.text(`${accident?.vehicle_year || '-'}`, 50, 71, { align: 'center' });
  doc.text(`${accident?.trailer_year || '-'}`, pageWidth - 50, 71, { align: 'center' });

  doc.text(`${accident?.vehicle_make || '-'}`, 50, 86, { align: 'center' });
  doc.text(`${accident?.trailer_make || '-'}`, pageWidth - 50, 86, { align: 'center' });

  doc.text(`${accident?.vehicle_owner || '-'}`, 50, 103, { align: 'center' });
  doc.text(`${accident?.trailer_owner || '-'}`, pageWidth - 50, 103, { align: 'center' });

  doc.text(`${accident?.vehicle_damage || '-'}`, 50, 119, { align: 'center', maxWidth: pageWidth / 2 - 10 });
  doc.text(`${accident?.trailer_damage || '-'}`, pageWidth - 50, 119, {
    align: 'center',
    maxWidth: pageWidth / 2 - 10,
  });

  doc.addPage();

  // Shipment
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);

  doc.text('Shipment ID', 35, 34, { align: 'center' });
  doc.text('Under Dispatch', pageWidth / 2, 34, { align: 'center' });

  doc.text('Commodities Being Hauled', 35, 50, { align: 'center' });
  doc.text('Shipper', pageWidth / 2, 50, { align: 'center' });
  doc.text('Consignee', pageWidth - 32, 50, { align: 'center' });

  doc.text('Hazmat', 35, 66, { align: 'center' });
  doc.text('Hazmat Class', pageWidth / 2, 66, { align: 'center' });
  doc.text('Was there any release of Hazmat?', pageWidth - 32, 66, { align: 'center' });

  doc.text('Where was Hazmat disposed of?', 20, 82);

  doc.text('Explain type and amounts of hazmat', 20, 122);

  doc.text('Was there any cargo damage?', 20, 162);
  doc.text('Explain cargo damage', 20, 178);

  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${accident?.report_shipment?.shipment_id || '-'}`, 35, 39, { align: 'center' });
  doc.text(`${accident?.shipment_under_dispatch ? 'Yes' : 'No'}`, pageWidth / 2, 39, { align: 'center' });

  doc.text(`${accident?.shipment_commodities_hauled || '-'}`, 35, 55, { align: 'center' });
  doc.text(`${accident?.shipment_shipper?.location_name || '-'}`, pageWidth / 2, 55, { align: 'center' });
  doc.text(`${accident?.shipment_consignee?.location_name || '-'}`, pageWidth - 32, 55, { align: 'center' });

  doc.text(`${accident?.shipment_hazmat ? 'Yes' : 'No'}`, 35, 71, { align: 'center' });
  doc.text(`${accident?.shipment_hazmat_class || '-'}`, pageWidth / 2, 71, { align: 'center' });
  doc.text(`${accident?.shipment_hazmat_release ? 'Yes' : 'No'}`, pageWidth - 32, 71, { align: 'center' });

  doc.text(`${accident?.shipment_hazmat_disposal_details || '-'}`, 20, 87);

  doc.text(`${accident?.shipment_hazmat_release_details || '-'}`, 20, 127);

  doc.text(`${accident?.shipment_cargo_damage ? 'Yes' : 'No'}`, 20, 167);
  doc.text(`${accident?.shipment_cargo_damage_details || '-'}`, 20, 183);

  if (accident.incident?.incident_record_images?.length) {
    accident.incident?.incident_record_images?.forEach((img, i) => {
      doc.addPage();
      doc.addImage(
        `${img.image_path}?cache=${Math.random()}`,
        'JPEG',
        16,
        16,
        pageWidth - 32,
        pageHeight - 50,
        `image-${i}`,
        'FAST'
      );
    });
  }

  if (
    accident.alcohol_test_attachment?.endsWith('.png') ||
    accident.alcohol_test_attachment?.endsWith('.jpg') ||
    accident.alcohol_test_attachment?.endsWith('.jpeg')
  ) {
    doc.addPage();
    doc.addImage(
      `${accident.alcohol_test_attachment}?cache=${Math.random()}`,
      'JPEG',
      16,
      16,
      pageWidth - 32,
      pageHeight - 50,
      'FAST'
    );
  }

  if (
    accident.drug_test_attachment?.endsWith('.png') ||
    accident.drug_test_attachment?.endsWith('.jpg') ||
    accident.drug_test_attachment?.endsWith('.jpeg')
  ) {
    doc.addPage();
    doc.addImage(
      `${accident.drug_test_attachment}?cache=${Math.random()}`,
      'JPEG',
      16,
      16,
      pageWidth - 32,
      pageHeight - 50,
      'FAST'
    );
  }

  if (accident.logbook?.length) {
    accident.logbook.forEach((logbook, i) => {
      if (logbook.logbook_attachment) {
        doc.addPage();
        doc.addImage(
          `${logbook.logbook_attachment}?cache=${Math.random()}`,
          'JPEG',
          16,
          16,
          pageWidth - 32,
          pageHeight - 50,
          `image-${i}`,
          'FAST'
        );
      }
    });
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    if (currentPage < 5) {
      createHeader(doc, formatDateTime, accident, currentPage);
    }
    createFooter(doc, currentPage, pagesCount);
  }

  doc.setDocumentProperties({ title: `Accident Report` });

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  return { blob, url };
};
