import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { getAlphabet, palette } from 'utils/constants';
import { STable } from './BidNotes.styles';

const BidNotes = ({ open, onClose, bid, shipment }) => {
  const { formatDateTime, formatDate, formatTime } = useDateFormat();
  const { shipment_stops_schedule_dates, note } = bid;
  const stopsData = shipment_stops_schedule_dates;

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Notes'
      $bgColor={palette.gray0}
      $maxWidth='800px'
      $minWidth='800px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Done',
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th />
              <th>SCHEDULED / PROTECT</th>
              <th>CARRIER ETA</th>
            </tr>
          </thead>
          <tbody>
            {shipment.shipment_stops?.map((stop, index) => (
              <tr key={stop.id} className='body-row'>
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    ETA TO STOP {getAlphabet(index)}
                  </Typography>
                </td>
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatDate(stop.scheduled_date)} {formatTime(`${stop.scheduled_date} ${stop.from}`)}{' '}
                    {stop.from !== stop.to ? `- ${formatTime(`${stop.scheduled_date} ${stop.to}`)}` : ''}
                  </Typography>
                </td>
                <td className='nowrap'>
                  <div className='d-flex flex-column gap-2'>
                    {stopsData[stop.id] ? (
                      <Typography variant='b2' style={{ color: palette.red500 }}>
                        {formatDateTime(stopsData[stop.id])}
                      </Typography>
                    ) : (
                      <Typography variant='b2' style={{ color: palette.gray700 }}>
                        {formatDate(stop.scheduled_date)} {formatTime(`${stop.scheduled_date} ${stop.from}`)}{' '}
                        {stop.from !== stop.to ? `- ${formatTime(`${stop.scheduled_date} ${stop.to}`)}` : ''}
                      </Typography>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!!note && (
          <div className='d-flex flex-column gap-2 mt-4'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Notes
            </Typography>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              {note}
            </Typography>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BidNotes;
