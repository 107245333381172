import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import AddressAutocomplete from 'common/AddressAutocomplete';
import { getCities, getCountries, getStates } from 'Api/JobPositions';
import { SFlexRow, SAddressWrapper } from './Factoring.styles';

const AddressFields = ({ values, handleChange, touchedErrors }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const getCountriesList = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const onAddressSelect = (data) => {
    handleChange('country', data.country);
    handleChange('state', data.state);
    handleChange('city', data.city);
  };

  const changeAddress = ({ address, zip }) => {
    handleChange('address_line1', address);
    handleChange('zip', zip || values.zip);
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  return (
    <SAddressWrapper>
      <AddressAutocomplete
        name='address_line1'
        id='address_line1'
        onSuccess={onAddressSelect}
        changeAddress={changeAddress}
        onChange={handleChange}
        value={values.address_line1}
        error={touchedErrors?.address_line1}
      />
      <Input
        name='address_line2'
        className='w-100'
        placeholder='Address 2'
        onChange={handleChange}
        value={values.address_line2}
      />
      <SFlexRow>
        <div className='w-100'>
          <Autocomplete
            name='country'
            placeholder='Select Country..'
            value={values.country}
            onChange={(e, value) => {
              handleChange('country', value);
              handleChange('state', null);
              handleChange('city', null);
            }}
            options={countries}
            disableClearable={false}
            error={touchedErrors?.country}
          />
        </div>
        <div className='w-100'>
          <Autocomplete
            name='state'
            placeholder='Select State..'
            value={values.state}
            onChange={(e, value) => {
              handleChange('state', value);
              handleChange('city', null);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={states}
            disableClearable={false}
            error={touchedErrors?.state}
          />
        </div>
      </SFlexRow>
      <SFlexRow>
        <div className='w-100'>
          <Autocomplete
            name='city'
            placeholder='Select City..'
            value={values.city}
            onChange={(e, value) => handleChange('city', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={cities}
            disableClearable={false}
            error={touchedErrors?.city}
          />
        </div>
        <div className='w-100'>
          <Input
            name='zip'
            className='w-100'
            placeholder='Zip Code'
            onChange={handleChange}
            value={values.zip}
            error={touchedErrors?.zip}
          />
        </div>
      </SFlexRow>
    </SAddressWrapper>
  );
};

export default AddressFields;
