import React, { useEffect, useState } from 'react';
import NoRecords from 'common/NoRecords';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getOutOfServiceTrigger, updateOutOfServiceTrigger } from 'Api/CompanySettings';
import Autocomplete from 'common/Autocomplete';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { STable, STableRow, SWrapper } from './OutOfServiceTriggers.styles';
import {
  advanceNotice,
  carrierEquipmentExpiryAction,
  customerEquipmentExpiryAction,
  customerExpiryAction,
  driverAndEquipmentExpiryAction,
  staffAndOwnerOperatorEquipmentExpiryAction,
} from './OutOfServiceTriggers.data';

const OutOfServiceTriggers = () => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(true);
  const [triggers, setTriggers] = useState([]);

  const getOutOfServiceTriggerData = async () => {
    try {
      const { data } = await getOutOfServiceTrigger();
      setTriggers(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOutOfServiceTriggerData();
  }, []);

  const getActionsOptions = (type, functions) => {
    if (type === 'Driver' || type === 'Equipment') {
      return driverAndEquipmentExpiryAction;
    }
    if (type === 'Staff' || type === 'Owner Operator') {
      return staffAndOwnerOperatorEquipmentExpiryAction;
    }
    if (type === 'Carrier') {
      return carrierEquipmentExpiryAction;
    }
    if (type === 'Customer' && functions === 'If aging is above 90 days') {
      return customerExpiryAction;
    }
    if (type === 'Customer') {
      return customerEquipmentExpiryAction;
    }
  };

  const onChangeNotice = (value, item) => {
    const body = {
      action_expiry: item.action_expiry,
      advance_notice: value,
    };
    updateOutOfServiceTrigger(item.id, body)
      .then(() => {
        showToaster({ type: 'success', message: 'Changes have been saved' });
        setTriggers((prevItems) => {
          return prevItems.map((i) => (i === item ? { ...i, advance_notice: value } : i));
        });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const onChangeAction = (value, item) => {
    const body = {
      action_expiry: value.name,
      advance_notice: item.advance_notice,
    };
    updateOutOfServiceTrigger(item.id, body)
      .then(() => {
        showToaster({ type: 'success', message: 'Changes have been saved' });
        setTriggers((prevItems) => {
          return prevItems.map((i) => (i === item ? { ...i, action_expiry: value.name } : i));
        });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  return (
    <SWrapper>
      <div className='mb-3'>
        <Typography variant='h2' style={{ color: palette.gray900 }}>
          Out of Service Triggers
        </Typography>
      </div>
      <div />
      {loading ? (
        <TableSkeleton />
      ) : (
        <STable>
          <thead>
            <tr>
              <th>PROFILE TYPE</th>
              <th>FUNCTION</th>
              <th>EXPIRY ACTION</th>
              <th>ADVANCE NOTICE</th>
            </tr>
          </thead>
          <tbody>
            {triggers.map((item) => (
              <STableRow key={item.id}>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.profile_types}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.functions}
                  </Typography>
                </td>
                <td>
                  <Autocomplete
                    labelKey='name'
                    options={getActionsOptions(item.profile_types, item.functions) || []}
                    value={item.action_expiry}
                    onChange={(e, val) => onChangeAction(val, item)}
                    isOptionEqualToValue={(option, value) => option.name === value}
                    style={{ width: 350 }}
                    disabled={item.functions === 'If rating is under 1 of 5 stars'}
                  />
                </td>
                <td>
                  <Autocomplete
                    options={advanceNotice || []}
                    value={item.advance_notice}
                    onChange={(e, val) => onChangeNotice(val, item)}
                    isOptionEqualToValue={(option, value) => option === value}
                    disabled={item.functions === 'If rating is under 1 of 5 stars' || item.action_expiry === 'Ignore'}
                  />
                </td>
              </STableRow>
            ))}
          </tbody>
        </STable>
      )}
      {!loading && !triggers?.length && <NoRecords />}
    </SWrapper>
  );
};

export default OutOfServiceTriggers;
