import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Input, { InputWithIcon } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { getShipmentOfferOptions, createShipmentOfferOptions } from 'Api/PlannerV2';
import { getShipmentOfferSettings } from 'Api/CompanySettings';
import { getInitialValues, autoAwardTimeOptions, timeOptions } from './OfferOptions.data';
import { SMergedInputs, SWrapper } from './OfferOptions.styles';

const OfferOptions = ({ open, onClose, shipment, onSuccess, offerTo }) => {
  const showToaster = useShowToaster();
  const [offerOptions, setOfferOptions] = useState(null);
  const [offerSettings, setOfferSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const hasBrokerage = useHasBrokerage();
  const { shipment_id } = shipment || {};

  const onSubmit = async (values) => {
    setLoadingSave(true);
    try {
      const body = {
        expire_shipment_offer: values.expire_shipment_offer || null,
        expire_shipment_offer_unit: values.expire_shipment_offer_unit.value || null,
        auto_repost: values.auto_repost,
        retract_carrier_award: values.retract_carrier_award || null,
        retract_carrier_award_unit: values.retract_carrier_award_unit.value || null,
        auto_award_first_lowest_amount: values.auto_award_first_lowest_amount || null,
        auto_award_first_lowest_amount_unit: values.auto_award_first_lowest_amount_unit.value || null,
        auto_dispatch: values.auto_dispatch,
        require_minimum_margin: values.require_minimum_margin,
        require_minimum_margin_percent: values.require_minimum_margin_percent,
      };
      await createShipmentOfferOptions(shipment_id, body);
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSave(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(offerOptions, offerSettings),
    onSubmit,
    enableReinitialize: true,
  });

  const getOptions = async () => {
    setLoading(true);
    try {
      const { data } = await getShipmentOfferOptions(shipment_id);
      if (!data) {
        const { data: settingsData } = await getShipmentOfferSettings(shipment_id);
        setOfferSettings(settingsData);
      }
      setOfferOptions(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOptions();
  }, [shipment_id]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Offer Options'
      $bgColor={palette.gray0}
      $width='700px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Submit',
          onClick: handleSubmit,
          disabled: loading || loadingSave,
        },
      ]}
    >
      <SWrapper>
        <div className='form-wrapper'>
          <div className='form-field-row'>
            <Typography variant='s2'>Expire Shipment Offer After</Typography>
            <SMergedInputs>
              <Input
                width='120px'
                type='number'
                name='expire_shipment_offer'
                value={values.expire_shipment_offer}
                onChange={handleChange}
                error={touchedErrors.expire_shipment_offer}
                onKeyDown={(e) => {
                  blockNotNumberChars(e);
                  blockNonPositiveNumbers(e);
                }}
              />
              <Autocomplete
                width='100px'
                name='expire_shipment_offer_unit'
                options={timeOptions}
                value={values.expire_shipment_offer_unit}
                onChange={(e, val) => handleChange('expire_shipment_offer_unit', val)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                error={touchedErrors.expire_shipment_offer_unit}
              />
            </SMergedInputs>
          </div>
          {!!hasBrokerage && offerTo === 'carrier' && (
            <div className='form-field-row'>
              <Typography variant='s2'>Retract Carrier Award if Not Confirmed After</Typography>
              <SMergedInputs>
                <Input
                  width='120px'
                  name='retract_carrier_award'
                  value={values.retract_carrier_award}
                  onChange={handleChange}
                  error={touchedErrors.retract_carrier_award}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                />
                <Autocomplete
                  width='100px'
                  name='retract_carrier_award_unit'
                  options={timeOptions}
                  value={values.retract_carrier_award_unit}
                  onChange={(e, val) => handleChange('retract_carrier_award_unit', val)}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  error={touchedErrors.retract_carrier_award_unit}
                />
              </SMergedInputs>
            </div>
          )}
          {!!hasBrokerage && offerTo === 'carrier' && (
            <div className='form-field-row'>
              <Typography variant='s2'>Auto Award First Lowest Amount After</Typography>
              <SMergedInputs>
                <Input
                  width='120px'
                  name='auto_award_first_lowest_amount'
                  value={values.auto_award_first_lowest_amount}
                  onChange={handleChange}
                  error={touchedErrors.auto_award_first_lowest_amount}
                  disabled={values.auto_award_first_lowest_amount_unit?.value === null}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                />
                <Autocomplete
                  width='100px'
                  name='auto_award_first_lowest_amount_unit'
                  options={autoAwardTimeOptions}
                  value={values.auto_award_first_lowest_amount_unit}
                  onChange={(e, val) => {
                    handleChange('auto_award_first_lowest_amount_unit', val);
                    if (!val?.value) {
                      handleChange('require_minimum_margin', false);
                      handleChange('require_minimum_margin_percent', '');
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  error={touchedErrors.auto_award_first_lowest_amount_unit}
                />
              </SMergedInputs>
            </div>
          )}
          {!!hasBrokerage && offerTo === 'carrier' && (
            <div className='form-field-row'>
              <CustomCheckbox
                name='require_minimum_margin'
                type='switch'
                smail={false}
                checked={values.require_minimum_margin}
                onChange={(checked) => handleChange('require_minimum_margin', checked)}
                disabled={!values.auto_award_first_lowest_amount_unit?.value}
              />
              <Typography variant='s2'>Require Minimum Margin</Typography>
              <InputWithIcon
                icon='%'
                left={false}
                width='120px'
                name='require_minimum_margin_percent'
                value={values.require_minimum_margin_percent}
                onChange={handleChange}
                error={touchedErrors.require_minimum_margin_percent}
                disabled={!values.require_minimum_margin}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          )}
          <div className='form-field-row'>
            <CustomCheckbox
              name='auto_repost'
              type='switch'
              smail={false}
              checked={values.auto_repost}
              onChange={(checked) => handleChange('auto_repost', checked)}
            />
            <Typography variant='s2'>Auto Repost After Offer is Expired</Typography>
          </div>
          {!!hasBrokerage && offerTo === 'carrier' && (
            <div className='form-field-row'>
              <CustomCheckbox
                name='auto_dispatch'
                type='switch'
                smail={false}
                checked={values.auto_dispatch}
                onChange={(checked) => handleChange('auto_dispatch', checked)}
              />
              <Typography variant='s2'>Auto Dispatch After Award is Accepted</Typography>
            </div>
          )}
        </div>
      </SWrapper>
    </Modal>
  );
};

export default OfferOptions;
