import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as PLusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { Typography } from 'components/Typography';
import AddEditModalDetails from 'components/AddEditModalStops';
import { MAPPER_STOPS } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import SettingsModal from 'components/TableShipments/detailsRow/steps/OverView/SettingsModal';
import OverViewStops from 'components/TableShipments/detailsRow/steps/OverView/OverViewStops';
import LaneMap from 'components/TablePlaner/helpers/RecurrningDetails/steps/OverView/LaneMap';
import OverLayStopsPlus from 'components/TablePlaner/helpers/RecurrningDetails/helpers/OverLayStopsPlus';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { createCargoConverter } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { UpdateStopPoint } from 'Api/StopPoint';
import { addRecurringLaneStop, deleteRecurringLaneStop, updateCargo, updateRecurringLaneStop } from 'Api/Shipment';
import classes from './OverView.module.css';

const OverView = ({ isShipmentTable, infoHeader, getRecurringData, onCheckInOutClick, onDryRunClick }) => {
  const { id } = useParams();
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [selectModalType, setSelectModalType] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const legsStopsCount = [];
  const [stops, setStops] = useState([]);
  const [legs, setLegs] = useState([]);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAddLegVisible, setIsAddLegVisible] = useState(false);
  const { status } = infoHeader || {};

  const updateStop = (params, stopId) => {
    setLoading(true);
    updateRecurringLaneStop(stopId, params)
      .then(() => {
        getRecurringData();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      })
      .finally(() => setLoading(false));
  };

  const onClickItemSettingsIcon = async (stop) => {
    setIsOpenSettingsModal(stop?.stop_point?.id);
  };

  const onDeleteStop = async (stopId) => {
    try {
      await deleteRecurringLaneStop(stopId);
      getRecurringData();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
  };

  function onUpdateSettings(id, validateForm, resetForm, submitForm, values) {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        const backData = {};
        Object.keys(values).forEach((key) => {
          const value = values[key];
          if (typeof value === 'boolean') {
            backData[key] = value ? 1 : 0;
          } else {
            backData[key] = value;
          }
        });
        UpdateStopPoint(id, backData).then(() => setIsOpenSettingsModal(''));
      }
    });
  }

  const orderingInLegsStops = useCallback((data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    const stopsAll = [];
    legsStopsData.forEach((leg) => {
      leg?.stops?.forEach((stop) => {
        stopsAll.push(stop);
      });
    });
    setStops(stopsAll);
    return legsStopsData;
  }, []);

  const onAddEditModalDetails = (values, type, item) => {
    delete values?.id;
    if (type === 'edit') {
      const formData = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (key === 'to' || (val !== '' && !['arrival_time', 'departure_time'].includes(key))) {
          if (key === 'dimensions' && val?.l && val?.h && val?.w) {
            formData.append(`dimensions[l]`, val?.l || '');
            formData.append(`dimensions[h]`, val?.h || '');
            formData.append(`dimensions[w]`, val?.w || '');
          } else if (key === 'arrival_date') {
            if (val) {
              formData.append(
                'arrival_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.arrival_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'departure_date') {
            if (val) {
              formData.append(
                'departure_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.departure_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'bill_type') {
            formData.append('bill_type', values.bill_type ? '1' : '2');
          } else if (key === 'scheduled_date') {
            formData.append('scheduled_date', moment(val).format('YYYY-MM-DD'));
          } else if (key === 'scheduled_date_to') {
            formData.append('scheduled_date_to', moment(val || values?.scheduled_date).format('YYYY-MM-DD'));
          } else if (key === 'from') {
            formData.append('from', moment(values?.from).format('HH:mm'));
          } else if (key === 'to') {
            formData.append('to', moment(val || values?.from).format('HH:mm'));
          } else if (key === 'reported_quantity_type' && !!val) {
            formData.append(
              'reported_quantity_type',
              values?.reported_quantity_type?.id || values?.reported_quantity_type
            );
          } else if (key === 'reported_weight_type' && !!val) {
            formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
          } else if (key === 'connected_cargos' && val?.length) {
            val.forEach((cargo, index) => {
              formData.append(`connected_cargos[${index}]`, cargo.id);
            });
          } else if (key === 'cargos') {
            // Do nothing
          } else {
            let value = val;
            if (typeof val === 'boolean') {
              if (key === 'bill_type') {
                return;
              }
              value = val ? 1 : 0;
            }
            if (key === 'dimensions') {
              return;
            }
            formData.append(key, value);
          }
        }
      });
      updateStop(formData, item?.id);

      if (Number(values.stop_point_type) === 1 && values.cargos?.length === 1) {
        const body = createCargoConverter(values.cargos[0]);
        updateCargo(values.cargos[0].id, body);
      }
    } else {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'id') {
          return;
        }
        let value = values[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        if (key === 'bill_type') {
          formData.append('bill_type', !values.bill_type ? '1' : '2');
        }

        if (key === 'dimensions' && typeof value === 'object') {
          Object.keys(value).forEach((dimensionKey) => {
            formData.append(`dimensions[${dimensionKey}]`, value[dimensionKey] || '');
          });
        } else if (key === 'reported_quantity_type' && values?.reported_quantity_type) {
          formData.append(
            'reported_quantity_type',
            values?.reported_quantity_type?.id || values?.reported_quantity_type
          );
        } else if (key === 'reported_weight_type' && values?.reported_weight_type) {
          formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
        } else if (key === 'scheduled_date') {
          formData.append('scheduled_date', moment(values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'scheduled_date_to') {
          formData.append('scheduled_date_to', moment(value || values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'from') {
          formData.append('from', moment(values?.from).format('HH:mm'));
        } else if (key === 'to') {
          formData.append('to', moment(value || values?.from).format('HH:mm'));
        } else {
          formData.append(key, value);
        }
      });
      formData.append('leg', selectModalType.legIndex);
      formData.append('order_in_leg', selectModalType.order_in_leg);
      formData.append('shipment_planner_template_id', id);

      addRecurringLaneStop(formData)
        .then(() => {
          getRecurringData();
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const onUpdateSuccess = () => {
    getRecurringData();
  };

  const createStopData = (index, stopPointType, allDataStop) => {
    const { color, background, name } = MAPPER_STOPS[+stopPointType];
    return { index, stopPointType: +stopPointType, color, background, allDataStop, name };
  };

  const activeStop = useMemo(() => {
    let firstStopWithoutArrival;

    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      const { arrival_date: arrival, stop_point_type: stopPointType, departure_date: departure } = stop || {};

      if (arrival && !departure) {
        return createStopData(i, stopPointType, stop);
      }
      if (!firstStopWithoutArrival && !arrival) {
        firstStopWithoutArrival = createStopData(i, stopPointType, stop);
      }
    }

    return firstStopWithoutArrival || {};
  }, [stops]);

  function onChangePlusModal(el) {
    const map = {
      1: { ...el, color: palette.green500 },
      2: { ...el, color: palette.red500 },
      3: { ...el, color: palette.blueText },
    };

    const allStops = legs.reduce((acc, cur) => [...acc, ...(cur.stops || [])], []);

    setSelectModalType({ ...map[el.id], index: allStops.length, legIndex: legs.length + 1, order_in_leg: 1 });
    setIsOpen({ type: 'add', open: true });
  }

  useEffect(() => {
    setLegs(orderingInLegsStops(infoHeader.shipment_stops));
  }, [id, infoHeader]);

  return (
    <div className={`${classes.chiefContainer} mb-3`}>
      <div style={{ width: '100%', marginBottom: 10 }}>
        {loading ? (
          <SkeletonStops />
        ) : (
          <>
            {legs.map((leg, index) => {
              legsStopsCount.push(leg.stops.length);
              return (
                <div key={leg.id || index} className={classes.legCountContainer}>
                  <div className={classes.countLegWrapper}>
                    <Typography variant='s2' style={{ color: palette.indigo600 }}>
                      {leg?.number}
                    </Typography>
                  </div>
                  <OverViewStops
                    leg={leg}
                    legs={legs}
                    setIsOpenModal={() => null}
                    setLegs={setLegs}
                    indexPrefix={index}
                    setIsOpen={setIsOpen}
                    infoHeader={infoHeader}
                    legsStopsCount={legsStopsCount}
                    onSelectPlus={setSelectModalType}
                    isShipmentTable={isShipmentTable}
                    deleteStop={onDeleteStop}
                    onClickSettingsIcon={onClickItemSettingsIcon}
                    isRecurring
                    onCheckInOutClick={onCheckInOutClick}
                    onDryRunClick={onDryRunClick}
                    onUpdateSuccess={onUpdateSuccess}
                  />
                </div>
              );
            })}
          </>
        )}
        {status?.id !== 6 && status?.id !== 7 && infoHeader?.shipment_type !== 'split-parent' && (
          <div className='d-flex'>
            <div className={classes.countPlusWrapper} onClick={() => setIsAddLegVisible(!isAddLegVisible)}>
              <Typography variant='s2' style={{ color: palette.indigo600 }}>
                {!isAddLegVisible ? <PLusIcon /> : <MinusIcon />}
              </Typography>
            </div>
            {isAddLegVisible && (
              <div className={classes.addLeg} style={{ backgroundColor: use(palette.gray0, palette.dark700) }}>
                <OverLayStopsPlus index={1} addInleg onChange={(el) => onChangePlusModal(el)} />
              </div>
            )}
          </div>
        )}
      </div>

      {!!infoHeader?.shipment_stops?.length && <LaneMap stops={infoHeader?.shipment_stops} />}
      {!!isOpen?.open && (
        <AddEditModalDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={selectModalType}
          setType={setSelectModalType}
          onAdd={onAddEditModalDetails}
          isShipmentTable={isShipmentTable}
          isActiveStopPoint={!!activeStop?.allDataStop?.id && activeStop?.allDataStop?.id === selectModalType?.stop?.id}
          onUpdateSuccess={onUpdateSuccess}
          isRecurring
          shipmentId={id}
          loading={loading}
          legs={legs}
        />
      )}
      <SettingsModal isOpen={isOpenSettingsModal} setIsOpen={setIsOpenSettingsModal} onUpdate={onUpdateSettings} />
    </div>
  );
};

export default OverView;

export const SkeletonStops = () => {
  return (
    <div className={classes.skeletonContainer}>
      <div className={classes.skeletonWrapper}>
        <Skeleton style={{ width: '40%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '60%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
      </div>
      <div className={classes.skeletonWrapper}>
        <Skeleton style={{ width: '40%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '60%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
      </div>
      <div className={classes.skeletonWrapper}>
        <Skeleton style={{ width: '40%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '60%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
      </div>
    </div>
  );
};
