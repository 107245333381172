/* eslint-disable array-callback-return */
import React from 'react';
import { useParams } from 'react-router-dom';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import ConnectedServices from 'components/Staff/AddStaff/StaffConnectedServices';
import './StaffConnectedServices.css';

const ConnectedServicesTab = () => {
  const { use } = useTheme();
  const { id } = useParams();
  return (
    <div className='connected-series-main-wraper-top driver-style-wrap'>
      <div
        className='request-table-container staff-request driver-style-wrap'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div
          className='header-container header-container-staff-connected-service'
          style={{ borderColor: use(palette.gray50, palette.darkborder) }}
        >
          <div className='sub-header'>
            <p
              className='heading staff-connected-service-heading'
              style={{ color: use(palette.gray700, palette.gray200) }}
            >
              Connected Services
            </p>
          </div>
        </div>
        <div className='table-wrap'>
          <ConnectedServices
            isDriverProfile
            nextStep=''
            prevStep=''
            Cancel=''
            staffId={id}
            stepsDone=''
            setStepsDone=''
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectedServicesTab;
