import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CircularArrows } from 'assets/icons/circle-arrows.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Chip from 'common/Chip';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';
import { getAccountsList } from 'Api/Accounts';
import { SBoxWrapper } from 'pages/Accounting/Payables/StatsSection/StatsSection.styles';
import { SListWrapper } from './Accounts.styles';

const Accounts = () => {
  const navigate = useNavigate();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const getAccounts = async () => {
    setLoading(true);
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => i.account_type.id === ACCOUNT_TYPE.BANK));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <SBoxWrapper $minWidth='380px'>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <span className='d-inline-flex gap-1 pointer'>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            ACCOUNTS
          </Typography>
        </span>
      </div>
      {loading ? (
        <Loader loading={loading} minHeight='calc(100% - 58px)' />
      ) : (
        <SListWrapper>
          {accounts?.map((item) => (
            <div
              key={item.id}
              className='list-item'
              onClick={() => navigate(`/accounting/accounts/${item.id}/bank-feed`)}
            >
              <div className='d-flex gap-2'>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {item.account_name}
                  <CircularArrows
                    fill={item.reconcile_count === 0 ? palette.green400 : palette.orange300}
                    className='ms-1 mb-1'
                  />
                </Typography>
                <Chip
                  label={`${item.reconcile_count}`}
                  labelColor={item.reconcile_count === 0 ? palette.green400 : palette.goldDark}
                  bgColor={item.reconcile_count === 0 ? palette.green0 : palette.goldLight}
                  fontSize='13px'
                />
              </div>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {item.account_balance < 0 && '-'}
                {currency}
                {formatNumber(Math.abs(item.account_balance))}
              </Typography>
            </div>
          ))}
        </SListWrapper>
      )}
      <CustomButton
        type='secondary'
        title='Manage Accounts'
        leftIcon={<GearIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
        styleButton={{ padding: '3px 12px', margin: 0, marginTop: 12, fontSize: '14px', whiteSpace: 'nowrap' }}
        onClick={() => navigate('/accounting/accounts')}
      />
    </SBoxWrapper>
  );
};

export default Accounts;
