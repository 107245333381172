import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import ImageDropZone from 'components/ImgageDropZone';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/createShipment/attachment.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download2.svg';
import FileActions from 'components/CreateShipment/Documents/helpers/FileActions';
import ViewAgreement from 'components/Billing/Transactions/ViewAgreement';
import { palette } from 'utils/constants';
import classes from 'components/TableRowEditModalTypes/UploadShipmentDocumnets/uploadDocument.module.scss';
import { SFormWrapper, SActions, SAttachDoc, SAttachedDoc } from '../UploadShipmentDocuments.styles';

const ItemForm = ({ values, handleChange, onCancel, types, index, touchedErrors, shipmentType, slaves, stops }) => {
  const [attachment, setAttachment] = useState(null);

  const onTypeSelect = (value) => {
    handleChange(`${index}.type`, value);
  };

  const onDrop = (acceptedFiles) => {
    handleChange(`${index}.doc`, acceptedFiles[0]);
  };

  const onDelete = () => {
    handleChange(`${index}.doc`, null);
  };

  const onDownload = (path) => {
    fetch(path, { mode: 'cors' })
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = typeof path === 'string' ? new Blob([resp], { type: 'application/pdf' }) : path;
        const link = document.createElement('a');
        const url = URL.createObjectURL(file);
        link.setAttribute('download', typeof path === 'string' ? 'download' : path.name);
        link.href = url;
        link.click();
      });
  };

  useEffect(() => {
    handleChange(`${index}.shipment_type`, shipmentType);
  }, []);

  return (
    <SFormWrapper>
      <div className='flex-item'>
        <Input
          name='reference_id'
          onChange={(e) => handleChange(`${index}.reference_id`, e.target.value)}
          value={values.reference_id}
          error={touchedErrors?.reference_id}
          style={{ width: '200px', marginRight: '28px' }}
        />
      </div>
      <div className='flex-item'>
        <Autocomplete
          width='200px'
          name='shipment_stop'
          options={stops}
          value={values.shipment_stop}
          onChange={(e, val) => handleChange(`${index}.shipment_stop`, val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.stop_point.location_name}
            </li>
          )}
          getOptionLabel={(option) => `${option.stop_point.location_name}`}
        />
      </div>
      {shipmentType === 'ltl' && (
        <div className='flex-item'>
          <Autocomplete
            name='type'
            options={slaves}
            labelKey='label'
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.shipment_id}
            onChange={(e, value) => handleChange(`${index}.shipment_id`, value)}
            width='200px'
            error={touchedErrors?.shipment_id}
            required
          />
        </div>
      )}
      <div className='flex-item'>
        <Autocomplete
          name='type'
          options={types}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.type}
          onChange={(e, value) => onTypeSelect(value)}
          error={touchedErrors?.type}
          width='200px'
        />
      </div>
      <div className='flex-item'>
        <ImageDropZone
          height={32}
          accept={['application/pdf']}
          onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
          name={`${index}.doc`}
          deletable
          deletedComponent={
            <div className={classes.downloadAndDelete}>
              <FileActions
                onDrop={(acceptedFiles, name) => onDrop(acceptedFiles, name)}
                style={{ opacity: values?.documents?.[index]?.file ? 1 : 0 }}
                onDelete={() => onDelete(values.doc)}
                onDownload={() => onDownload(values.doc)}
              />
            </div>
          }
        >
          {values.doc ? (
            <SAttachedDoc>
              <div
                className='uploaded-file'
                onClick={(e) => {
                  e.stopPropagation();
                  if (typeof values.doc === 'object') {
                    return;
                  }
                  setAttachment(values.doc);
                }}
              >
                <AttachmentIcon className='attach-icon' />
                <span>{values.doc.name}</span>
              </div>
              <div className='action-icons'>
                <div
                  className='download-wrapper'
                  onClick={(e) => {
                    e.stopPropagation();
                    onDownload(values.doc);
                  }}
                >
                  <DownloadIcon className='download-icon' />
                </div>
                <div
                  className='cancel-wrapper'
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(values.doc);
                  }}
                >
                  <CancelIcon className='cancel-icon' />
                </div>
              </div>
            </SAttachedDoc>
          ) : (
            <SAttachDoc>
              <PlusIcon className='plus-icon' />
              <span>Attach Document</span>
            </SAttachDoc>
          )}
        </ImageDropZone>
        <ErrorMessage error={touchedErrors?.doc} />
      </div>
      <SActions className='flex-item'>
        {index !== 0 && (
          <div className='action_wrapper' onClick={onCancel}>
            <CancelIcon width={12} height={12} fill={palette.indigo500} />
          </div>
        )}
      </SActions>
      {attachment && <ViewAgreement title='Document' url={attachment} onClose={() => setAttachment(null)} />}
    </SFormWrapper>
  );
};

export default ItemForm;
