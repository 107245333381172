import { array, mixed, number, object, string } from 'yup';

export const validationSchema = array().of(
  object().shape({
    reference_id: string().trim().required('Reference is required'),
    type: object()
      .shape({ id: number().required('Type is required') })
      .required('Type is required')
      .nullable(),
    doc: mixed().required('File is required'),
    shipment_id: object().when('shipment_type', {
      is: (response) => response === 'ltl',
      then: object()
        .shape({ id: number().required('Shipment ID/Customer is required') })
        .required('Shipment ID/Customer is required')
        .nullable(),
      otherwise: object().nullable(),
    }),
  })
);
