import React, { useState, useEffect, useMemo } from 'react';
import './CustomerLogTab.css';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getCustomerLogs } from 'Api/Customers';
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRightWhite.svg';
import ColumnHeader from 'common/ColumnHeader';
import useDateFormat from 'hooks/useDateFormat';
import { getCarrierLogs } from 'Api/Carriers';
import MaterialTableWrapper from '../MaterialTableWrapper';
import Preloader from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import CustomerLogFilters from './CustomerLogFilters';
import TableFooter from '../TableFooter/TableFooter';
import { Typography } from '../Typography';

const CustomerLogTab = ({ type }) => {
  const { theme, use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [logs, setLogs] = useState({ data: [] });
  const [dayFilter, setDayFilter] = useState();
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, 500);
  const { id } = useParams();

  const columns = useMemo(() => {
    return [
      {
        title: <ColumnHeader title='CHANGED SECTION' field='change_description' />,
        field: 'change_description',
        render: (row) => <Typography variant='b2'>{row.change_description}</Typography>,
      },
      {
        title: <ColumnHeader title='CHANGED FROM / TO' field='change_source' />,
        field: 'change_source',
        render: (rowData) => {
          const { change_from } = rowData;
          const { change_to } = rowData;
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              <span style={{ color: use(palette.dark800, palette.gray200) }}>
                <span
                  className='tablePlaner_status'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    backgroundColor: '#F0F2F7',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    marginRight: 10,
                  }}
                >
                  {change_from || '-'}
                </span>
              </span>
              <ArrowRight />
              <span style={{ color: use(palette.dark800, palette.gray200) }}>
                <span
                  className='tablePlaner_status'
                  style={{
                    color: use(palette.gray600, palette.gray200),
                    backgroundColor: '#F0F2F7',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    marginLeft: 10,
                  }}
                >
                  {change_to || '-'}
                </span>
              </span>
            </span>
          );
        },
      },
      {
        title: <ColumnHeader title='CHANGED BY' field='created_at' />,
        field: 'created_at',
        render: (rowData) => {
          const user = rowData?.change_by_user_details;

          return rowData?.change_by_user_id === -1 ? (
            <Typography>FMCSA</Typography>
          ) : rowData?.change_by_user_id === -2 ? (
            <Typography>Carrier</Typography>
          ) : (
            <Typography variant='b2'>
              {user?.first_name} {user?.last_name} on {convertToCustomerTime(rowData?.created_at)}
            </Typography>
          );
        },
      },
    ];
  }, [logs.data, theme]);

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    setLoading(true);
    type === 'carrier'
      ? getCarrierLogs({
          page: logs.current_page,
          itemsPerPage: rowPage,
          carrier_id: id,
          'sort[][id]': 'desc',
          ...dayFilter,
        })
          .then((res) => setLogs(res))
          .finally(() => setLoading(false))
      : getCustomerLogs({
          page: logs.current_page,
          itemsPerPage: rowPage,
          customer_id: id,
          'sort[][id]': 'desc',
          ...dayFilter,
        })
          .then((res) => setLogs(res))
          .finally(() => setLoading(false));
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);

    if (logs.last_page !== 1) {
      type === 'carrier'
        ? getCarrierLogs({ page, itemsPerPage: rowPerPage, carrier_id: id, 'sort[][id]': 'desc', ...dayFilter })
            .then((res) => setLogs(res))
            .finally(() => setLoading(false))
        : getCustomerLogs({ page, itemsPerPage: rowPerPage, customer_id: id, 'sort[][id]': 'desc', ...dayFilter })
            .then((res) => setLogs(res))
            .finally(() => setLoading(false));
    }
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={logs.total}
        lastPage={logs.last_page}
        totalLength={logs.data?.length}
        currentPage={logs.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  useEffect(() => {
    setLoading(true);

    async function init() {
      try {
        const logs =
          type === 'carrier'
            ? await getCarrierLogs({
                page: 1,
                itemsPerPage: rowPerPage,
                query: debouncedSearch,
                carrier_id: id,
                'sort[][id]': 'desc',
                ...dayFilter,
              })
            : await getCustomerLogs({
                page: 1,
                itemsPerPage: rowPerPage,
                query: debouncedSearch,
                customer_id: id,
                'sort[][id]': 'desc',
                ...dayFilter,
              });
        setLogs(logs);
      } catch (e) {
        // Do nothing
      }
      setLoading(false);
    }

    init();
  }, [debouncedSearch, dayFilter]);

  const onSelectDate = (dates) => {
    const { start, end } = dates;
    const startFormat = moment(start).format('DD-MM-YYYY');
    const endFormat = moment(end).format('DD-MM-YYYY');
    setDayFilter({
      from_date: startFormat,
      to_date: endFormat,
    });
  };

  return (
    <div>
      <div className='customer-log-tab'>
        <CustomerLogFilters search={search} onChange={setSearch} onSelectDate={onSelectDate} />
        {loading ? (
          <Preloader />
        ) : (
          <MaterialTableWrapper
            data={logs?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerLogTab;
