import styled from 'styled-components';
import { palette } from '../../utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.gray50};
    color: ${palette.gray700};
    font-size: 18px;
    outline: none;
    width: 20px;
    height: 30px;
    border: none;
    transition: background-color 0.3s;

    :disabled {
      background-color: ${palette.gray0};
    }

    :hover:not(:disabled) {
      background-color: ${palette.gray50}AA;
    }

    &.decrease-button {
      border-radius: 6px 0 0 6px;
    }

    &.increase-button {
      border-radius: 0 6px 6px 0;
    }
  }

  .count-input {
    width: 40px;

    input {
      border-radius: 0;
      padding: 4px;
      box-shadow: none;
      height: 30px;
      border-top: 1px solid ${palette.gray50};
      border-bottom: 1px solid ${palette.gray50};
      text-align: center;
    }
  }
`;
