import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import locationIcon from 'assets/icons/location.png';
import globeIcon from 'assets/icons/globe.png';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import { getCustomerInfo } from 'Api/JobPositions';
import { getNotificationSettings } from 'Api/PayrollSettings';
import { getYearToDateSummary } from 'Api/Payroll';
import { formatNumber } from 'utils/constants';
import {
  createAdditionsTable,
  createBenefitsTable,
  createBalancesTable,
  createDeductionsTable,
  createTaxesTable,
  createTotalSettlementSection,
} from './compensationHelpers';

const getBarcode = (id, displayValue = true) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id, { displayValue });

  return canvas.toDataURL('image/jpeg');
};

const createTrackingTableHeader = (doc, top = 123) => {
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('ID', 16, top);
  doc.text('Tracked', 25, top);
  doc.text('Start Time', 43, top);
  doc.text('Start Location', 63, top);
  doc.text('End Time', 113, top);
  doc.text('End Location', 133, top);
  doc.text('Amount', 186, top);
};

const createShipmentsTableHeader = (doc, top = 123) => {
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Shipment ID', 16, top);
  doc.text('Origin', 40, top);
  doc.text('Destination', 80, top);
  doc.text('Stops', 112, top);
  doc.text('Loaded Miles', 126, top);
  doc.text('Empty Miles', 152, top);
  doc.text('Amount', 181, top);
};

const createShipmentsTableHeaderPerHour = (doc, top = 123) => {
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Shipment ID', 16, top);
  doc.text('Origin', 40, top);
  doc.text('Destination', 90, top);
  doc.text('Stops', 138, top);
  doc.text('Loaded Miles', 152, top);
  doc.text('Empty Miles', 178, top);
};

const createStaffShipmentsTableHeader = (doc, top = 123) => {
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Shipment ID', 16, top);
  doc.text('Origin', 39, top);
  doc.text('Destination', 80, top);
  doc.text('Stops', 119, top);
  doc.text('Loaded Miles', 132, top);
  doc.text('Empty Miles', 158, top);
  doc.text('Amount', 183, top);
};

const createCommissionsTableHeader = (doc, top = 123) => {
  doc.setFontSize(10);
  doc.setTextColor('#171C26');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Shipment ID', 16, top);
  doc.text('Pay Type', 54, top);
  doc.text('Shipment Gross', 108, top);
  doc.text('Rate', 150, top);
  doc.text('Amount', 183, top);
};

const createTrackingRow = (doc, data, index, userData, top = 129) => {
  const { dateFormat, currency } = userData;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const y = top + index * 16;

  doc.setFontSize(9);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);

  // ID
  doc.text(`${data.id}`, 16, y);

  // Tracked
  doc.text(`${data.hours_tracked || 0}h ${data.minutes_tracked || 0}m`, 26, y);

  // Start time
  doc.text(`${dateFormat.convertToCustomerTime(data.start_time, dateFormat.dateFormat)}`, 43, y);
  doc.text(`${dateFormat.convertToCustomerTime(data.start_time, dateFormat.timeFormat)}`, 43, y + 4);

  // Start location
  if (data.start_time_street) {
    doc.text(
      `${data.start_time_street.length > 30 ? `${data.start_time_street.slice(0, 30)}...` : data.start_time_street},`,
      63,
      y
    );
    doc.text(`${data.start_time_city}, ${data.start_time_state_code}`, 63, y + 4);
    doc.text(`${data.start_time_country}`, 63, y + 8);
  } else {
    doc.text('-', 74, y);
  }

  // End time
  if (data.end_time) {
    doc.text(`${dateFormat.convertToCustomerTime(data.end_time, dateFormat.dateFormat)}`, 113, y);
    doc.text(`${dateFormat.convertToCustomerTime(data.end_time, dateFormat.timeFormat)}`, 113, y + 4);
  } else {
    doc.text('-', 116, y);
  }

  // End location
  if (data.end_time_street) {
    doc.text(
      `${data.end_time_street.length > 30 ? `${data.end_time_street.slice(0, 30)}...` : data.end_time_street},`,
      133,
      y
    );
    doc.text(`${data.end_time_city}, ${data.end_time_state_code}`, 133, y + 4);
    doc.text(`${data?.end_time_country}`, 133, y + 8);
  } else {
    doc.text('-', 138, y);
  }

  // Amount
  doc.text(`${currency}${formatNumber(data.amount)}`, 186, y);
  doc.setDrawColor(233, 237, 245);
  doc.line(16, y + 12, pageWidth - 16, y + 12);
};

const createShipmentRow = (doc, data, index, userData, top = 129) => {
  const { shipment_id, stops_count, loaded_miles, empty_miles, driver_amount, driver_pay } = data;
  const { currency, dateFormat } = userData;

  const origin = data?.shipment_stops?.find((item) => item.stop_point_type === '1');
  const originLocationName = `${
    origin?.stop_point?.location_name.length > 15
      ? `${origin?.stop_point?.location_name?.slice(0, 15)}...`
      : origin?.stop_point?.location_name
  }`;
  const originAddress = `${origin?.stop_point?.city?.name}, ${origin?.stop_point?.state?.short_name} ${origin?.stop_point?.zipcode}`;

  const destination = data?.shipment_stops?.findLast((item) => item.stop_point_type === '2');
  const destinationLocationName = `${
    destination?.stop_point?.location_name.length > 15
      ? `${destination?.stop_point?.location_name?.slice(0, 15)}...`
      : destination?.stop_point?.location_name
  }`;
  const destinationAddress = `${destination?.stop_point?.city?.name}, ${destination?.stop_point?.state?.short_name} ${destination?.stop_point?.zipcode}`;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const y = top + index * 16;

  doc.setFontSize(9);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${shipment_id}`, 16, y);

  // Origin
  if (!origin) {
    doc.text(`-`, 40, y);
  } else {
    doc.text(originLocationName, 40, y);
    doc.text(`${originAddress.length > 22 ? `${originAddress.slice(0, 22)}...` : originAddress}`, 40, y + 4);
    doc.setTextColor('#4F5AED');
    doc.text(`${origin.departure_date ? dateFormat.formatDate(origin.departure_date) : ''}`, 40, y + 8);
    doc.setTextColor('#464F60');
  }

  // Destination
  if (!destination) {
    doc.text(`-`, 80, y);
  } else {
    doc.text(destinationLocationName, 80, y);
    doc.text(
      `${destinationAddress.length > 20 ? `${destinationAddress.slice(0, 20)}...` : destinationAddress}`,
      80,
      y + 4
    );
    doc.setTextColor('#4F5AED');
    doc.text(`${destination.arrival_date ? dateFormat.formatDate(destination.arrival_date) : ''}`, 80, y + 8);
    doc.setTextColor('#464F60');
  }

  // Stops
  doc.text(`${stops_count}`, 116, y);

  // Loaded miles
  doc.text(`${Math.ceil(loaded_miles || 0)}`, 135, y);

  // Empty miles
  doc.text(`${Math.ceil(empty_miles || 0)}`, 160, y);

  // Amount
  doc.text(`${currency}${(Number(driver_amount) || 0).toFixed(2)}`, 181, y);
  if (driver_pay?.[0]?.type === 'flat_rate') {
    doc.text(`Flat Rate`, 181, y + 4);
  }

  doc.setDrawColor(233, 237, 245);
  doc.line(16, y + 12, pageWidth - 16, y + 12);
};

const createShipmentRowPerHour = (doc, data, index, userData, top = 129) => {
  const { shipment_id, stops_count, loaded_miles, empty_miles } = data;
  const { dateFormat } = userData;

  const origin = data?.shipment_stops?.find((item) => item.stop_point_type === '1');
  const originLocationName = `${
    origin?.stop_point?.location_name.length > 22
      ? `${origin?.stop_point?.location_name?.slice(0, 22)}...`
      : origin?.stop_point?.location_name
  }`;
  const originAddress = `${origin?.stop_point?.city?.name}, ${origin?.stop_point?.state?.short_name} ${origin?.stop_point?.zipcode}`;

  const destination = data?.shipment_stops?.findLast((item) => item.stop_point_type === '2');
  const destinationLocationName = `${
    destination?.stop_point?.location_name.length > 22
      ? `${destination?.stop_point?.location_name?.slice(0, 22)}...`
      : destination?.stop_point?.location_name
  }`;
  const destinationAddress = `${destination?.stop_point?.city?.name}, ${destination?.stop_point?.state?.short_name} ${destination?.stop_point?.zipcode}`;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const y = top + index * 16;

  doc.setFontSize(9);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${shipment_id}`, 16, y);

  // Origin
  if (!origin) {
    doc.text(`-`, 40, y);
  } else {
    doc.text(originLocationName, 40, y);
    doc.text(`${originAddress.length > 27 ? `${originAddress.slice(0, 27)}...` : originAddress}`, 40, y + 4);
    doc.setTextColor('#4F5AED');
    doc.text(`${origin.departure_date ? dateFormat.formatDate(origin.departure_date) : ''}`, 40, y + 8);
    doc.setTextColor('#464F60');
  }

  // Destination
  if (!destination) {
    doc.text(`-`, 90, y);
  } else {
    doc.text(destinationLocationName, 90, y);
    doc.text(
      `${destinationAddress.length > 27 ? `${destinationAddress.slice(0, 27)}...` : destinationAddress}`,
      90,
      y + 4
    );
    doc.setTextColor('#4F5AED');
    doc.text(`${destination.arrival_date ? dateFormat.formatDate(destination.arrival_date) : ''}`, 90, y + 8);
    doc.setTextColor('#464F60');
  }

  // Stops
  doc.text(`${stops_count}`, 142, y);

  // Loaded miles
  doc.text(`${Math.ceil(loaded_miles || 0)}`, 161, y);

  // Empty miles
  doc.text(`${Math.ceil(empty_miles || 0)}`, 186, y);

  doc.setDrawColor(233, 237, 245);
  doc.line(16, y + 12, pageWidth - 16, y + 12);
};

const createStaffShipmentRow = (doc, data, index, userData, top = 129) => {
  const { shipment_id, stops_count, loaded_miles, empty_miles, amount } = data;
  const { currency, dateFormat } = userData;

  const origin = data?.shipment_stops?.find((item) => item.stop_point_type === '1');
  const originLocationName = `${
    origin?.stop_point?.location_name.length > 18
      ? `${origin?.stop_point?.location_name?.slice(0, 18)}...`
      : origin?.stop_point?.location_name
  }`;
  const originAddress = `${origin?.stop_point?.city?.name}, ${origin?.stop_point?.state?.short_name} ${origin?.stop_point?.zipcode}`;

  const destination = data?.shipment_stops?.findLast((item) => item.stop_point_type === '2');
  const destinationLocationName = `${
    destination?.stop_point?.location_name.length > 18
      ? `${destination?.stop_point?.location_name?.slice(0, 18)}...`
      : destination?.stop_point?.location_name
  }`;
  const destinationAddress = `${destination?.stop_point?.city?.name}, ${destination?.stop_point?.state?.short_name} ${destination?.stop_point?.zipcode}`;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const y = top + index * 16;

  doc.setFontSize(9);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${shipment_id}`, 16, y);

  // Origin
  if (!origin) {
    doc.text(`-`, 39, y);
  } else {
    doc.text(originLocationName, 39, y);
    doc.text(`${originAddress.length > 22 ? `${originAddress.slice(0, 22)}...` : originAddress}`, 39, y + 4);
    doc.setTextColor('#4F5AED');
    doc.text(`${origin.departure_date ? dateFormat.formatDate(origin.departure_date) : ''}`, 39, y + 8);
    doc.setTextColor('#464F60');
  }

  // Destination
  if (!destination) {
    doc.text(`-`, 80, y);
  } else {
    doc.text(destinationLocationName, 80, y);
    doc.text(
      `${destinationAddress.length > 22 ? `${destinationAddress.slice(0, 22)}...` : destinationAddress}`,
      80,
      y + 4
    );
    doc.setTextColor('#4F5AED');
    doc.text(`${destination.arrival_date ? dateFormat.formatDate(destination.arrival_date) : ''}`, 80, y + 8);
    doc.setTextColor('#464F60');
  }

  // Stops
  doc.text(`${stops_count}`, 123, y);

  // Loaded miles
  doc.text(`${Math.ceil(loaded_miles || 0)}`, 141, y);

  // Empty miles
  doc.text(`${Math.ceil(empty_miles || 0)}`, 165, y);

  // Amount
  doc.text(`${currency}${formatNumber(amount)}`, 183, y);

  doc.setDrawColor(233, 237, 245);
  doc.line(16, y + 12, pageWidth - 16, y + 12);
};

const createCommissionRow = (doc, data, index, userData, top = 129) => {
  const { billing, pay_type, pay_rate, pay_amount } = data;
  const { currency } = userData;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const y = top + index * 8;

  doc.setFontSize(9);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);

  // Shipment ID
  doc.text(`${billing?.shipment_id || '-'}`, 16, y);

  // Pay Type
  if (Number(pay_type) === 1) {
    doc.text(`Flat Per Shipment`, 54, y);
  }
  if (Number(pay_type) === 2) {
    doc.text(`% of Shipment Gross`, 54, y);
  }
  if (Number(pay_type) === 3) {
    doc.text(`% of Shipment After Driver Pay`, 54, y);
  }

  // Shipment Gross
  doc.text(`${currency}${formatNumber(billing?.total_amount)}`, 108, y);

  // Rate
  doc.text(Number(pay_type) === 1 ? `${currency}${formatNumber(pay_rate)}` : `${Number(pay_rate)}%`, 150, y);

  // Amount
  doc.text(`${currency}${formatNumber(pay_amount)}`, 183, y);

  doc.setDrawColor(233, 237, 245);
  doc.line(16, y + 3, pageWidth - 16, y + 3);
};

const createFooter = (doc, company, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const { address1, city, state, zip, phone_number, email, notification_email, company_website } = company || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.line(0, pageHeight - 24, pageWidth, pageHeight - 24);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 24, pageWidth, 24, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 2, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4 + 4, pageHeight - 20, pageWidth / 4 + 4, pageHeight - 8);
    doc.line(pageWidth / 2, pageHeight - 20, pageWidth / 2, pageHeight - 8);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 8);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 13, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 9, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3);
    doc.text('Address', 18, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 14, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);
    doc.setFontSize(9);
    doc.setTextColor('#171C26');
    doc.text(`${address1}`, 13, pageHeight - 13);
    doc.text(`${city.name}, ${state.name} - ${zip}`, 13, pageHeight - 9, { maxWidth: 44 });
    doc.text(`${company_website || '-'}`, pageWidth / 4 + 9, pageHeight - 13);
    doc.text(`${notification_email || email || '-'}`, pageWidth / 2 + 6, pageHeight - 13);
    doc.text(`${phone}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);
  }
};

const createPaymentSummary = (doc, data) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const { totalHours, totalMinutes, settlementDetails, shipments, currency, dateFormat, user_type } = data || {};
  const { empty_miles, loaded_miles } = settlementDetails || {};
  const { compensation_details } = settlementDetails?.user || {};

  if (!compensation_details) {
    return;
  }

  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 110, 47, pageWidth - (pageWidth - 94), 7, 'F');
  doc.setFontSize(10);
  doc.setTextColor('#FFFFFF');
  doc.text('Pay Statement Summary', pageWidth - 106, 52);
  doc.setFontSize(10.5);

  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#000000');

  doc.text(`Pay Period:`, pageWidth - 108, 60);
  doc.setFont('Inter', 'normal', 400);
  doc.text(
    `${dateFormat.formatDate(settlementDetails.pay_period_start)} to ${dateFormat.formatDate(
      settlementDetails.pay_period_end
    )}`,
    pageWidth - 84,
    60
  );

  switch (compensation_details.pay_option) {
    case 'per_hour':
      doc.setFont('Inter', 'normal', 700);
      doc.text(`Hours Worked:`, pageWidth - 108, 66);
      doc.text(`Pay Per Hour:`, pageWidth - 108, 72);

      doc.setFont('Inter', 'normal', 400);
      doc.text(`${totalHours}h ${totalMinutes}m`, pageWidth - 78, 66);
      doc.text(`${currency}${Number(compensation_details.per_hour_worked)?.toFixed(2)}`, pageWidth - 80, 72);
      break;
    case 'mile':
      doc.setFont('Inter', 'normal', 700);
      doc.text(`Shipments Completed:`, pageWidth - 108, 66);
      doc.text(`Pay Per Mile:`, pageWidth - 108, 72);

      doc.setFont('Inter', 'normal', 400);
      doc.text(`${shipments?.length || 0}`, pageWidth - 62, 66);
      doc.text(
        `${currency}${compensation_details.mile_loaded_amt?.toFixed(
          2
        )} Loaded, ${currency}${compensation_details.mile_empty_amt?.toFixed(2)} Empty`,
        pageWidth - 81,
        72
      );
      break;
    case 'percentage':
      doc.setFont('Inter', 'normal', 700);
      doc.text(`Shipments Completed:`, pageWidth - 108, 66);
      doc.text(`Pay By Percentage:`, pageWidth - 108, 72);

      doc.setFont('Inter', 'normal', 400);
      doc.text(`${shipments?.length || 0}`, pageWidth - 62, 66);
      doc.text(`${compensation_details.shipment_percentage}% of Shipment`, pageWidth - 69, 72);
      break;
    case 'flat_rate':
      doc.setFont('Inter', 'normal', 700);
      doc.text(`Shipments Completed:`, pageWidth - 108, 66);
      doc.text(`Pay By Flat Rate:`, pageWidth - 108, 72);

      doc.setFont('Inter', 'normal', 400);
      doc.text(`${shipments?.length || 0}`, pageWidth - 62, 66);
      doc.text(`Flat Per Shipment`, pageWidth - 74, 72);
      break;
    default:
  }

  if (user_type === 1) {
    let top = 66;
    if (compensation_details.per_hr_option) {
      doc.setFont('Inter', 'normal', 700);
      doc.text(`Hours Worked:`, pageWidth - 108, 66);
      doc.text(`Pay Per Hour:`, pageWidth - 108, 72);

      doc.setFont('Inter', 'normal', 400);
      doc.text(`${totalHours}h ${totalMinutes}m`, pageWidth - 78, 66);
      doc.text(`${currency}${formatNumber(compensation_details.per_hr_amt)}`, pageWidth - 80, 72);
      top = 78;
    }
    if (compensation_details.each_booked_shipment) {
      doc.setFont('Inter', 'normal', 700);
      doc.text(`Per Each Booked Shipment:`, pageWidth - 108, top);

      doc.setFont('Inter', 'normal', 400);
      doc.text(
        Number(compensation_details.flat_or_per) === 1
          ? `${currency}${formatNumber(compensation_details.flat_amt)}`
          : `${compensation_details.percentage}%`,
        pageWidth - 55,
        top
      );
    }
  }

  if (user_type === 2) {
    doc.setFont('Inter', 'normal', 700);
    doc.text(`Total Empty Miles:`, pageWidth - 108, 78);
    doc.text(`Total Loaded Miles:`, pageWidth - 108, 84);
    doc.text(`Total Miles:`, pageWidth - 108, 90);

    doc.setFont('Inter', 'normal', 400);
    doc.text(`${empty_miles ? Math.ceil(empty_miles) : '0'}`, pageWidth - 72, 78);
    doc.text(`${loaded_miles ? Math.ceil(loaded_miles) : '0'}`, pageWidth - 70, 84);
    doc.text(`${Math.ceil(empty_miles || 0) + Math.ceil(loaded_miles || 0)}`, pageWidth - 84, 90);
  }
};

export const generatePDF = async (data) => {
  if (!data) {
    return;
  }

  const userData = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(userData.customer.dot);
  const { data: notificationSettings } = await getNotificationSettings();
  const notification_email = notificationSettings?.correspondence_email;

  const { address1, address2, city, state, zip, logo_path } = company || {};
  const {
    additions,
    deductions,
    benefits,
    taxes,
    absences,
    detention,
    balances,
    overtime,
    transactions,
    commission,
    time_trackings,
    oneTimeAdditions,
    settlementDetails,
    shipments,
    currency,
    dateFormat,
  } = data || {};

  const { id, set_payment_date, user, user_type, pay_period_start } = settlementDetails || {};
  const { compensation_details, app } = settlementDetails.user;
  const showTimeTracking = compensation_details.pay_option === 'per_hour' || compensation_details.per_hr_option === 1;

  const { data: yearToDate } = await getYearToDateSummary({
    user_type,
    user_id: user.id,
    to: pay_period_start,
  });
  const isOwnerOperator = app?.driver_type === 'owner';
  const isStaff = user_type === 1;
  const user_name = isStaff
    ? `${user.first_name} ${user.last_name}`
    : isOwnerOperator
    ? app?.operator?.owner_operator_name
    : `${user.fname} ${user.lname}`;
  const user_address1 = isStaff ? user.address1 : isOwnerOperator ? app?.operator?.address1 : user.driver_address;
  const user_address2 = isStaff ? user.address2 : isOwnerOperator ? app?.operator?.address2 : user.driver_address2;
  const user_city = isStaff ? user.city?.name : isOwnerOperator ? app?.operator?.city?.name : user.city_data?.name;
  const user_state = isStaff ? user.state?.name : isOwnerOperator ? app?.operator?.state?.name : user.state_data?.name;
  const user_country = isStaff
    ? user.country?.name
    : isOwnerOperator
    ? app?.operator?.country?.name
    : user.country_data?.name;
  const user_zip = isStaff ? user.zip : isOwnerOperator ? app?.operator?.zip : user.driver_zip;

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.addImage(`${logo_path}?cache=${Math.random()}`, 'JPEG', 16, 16, 60, 12);
  doc.setFontSize(10.5);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#171C26');
  doc.text(address1, 16, 38);
  if (address2) {
    doc.text(address2, 16, 42);
  }
  doc.text(`${city.name}, ${state.name} ${zip}`, 16, address2 ? 46 : 43);

  doc.text(`${user_name}`, 16, 59);
  doc.text(`${user_address1 || ''}`, 16, 64);
  if (user_address2) {
    doc.text(`${user_address2}`, 16, 69);
  }

  doc.text(`${user_city ? `${user_city},` : ''} ${user_state || ''} ${user_zip || ''}`, 16, user_address2 ? 74 : 69);
  doc.text(`${user_country || ''}`, 16, user_address2 ? 79 : 74);

  if (user_zip) {
    if (user_zip.length === 5 && /^\d+$/.test(user_zip)) {
      doc.addImage(
        `https://bwipjs-api.metafloor.com/?bcid=postnet&text=${user_zip}`,
        'JPEG',
        16,
        user_address2 ? 82 : 77,
        35,
        8
      );
    } else {
      doc.addImage(getBarcode(user_zip, false), 'JPEG', 15, user_address2 ? 82 : 77, 40, 12);
    }
  }

  doc.setDrawColor(188, 194, 206);
  doc.line(16, 93, pageWidth - 16, 93);
  doc.line(16, 93.1, pageWidth - 16, 93.1);
  doc.setFontSize(24);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Settlement', 16, 101);
  doc.setFontSize(9.3);
  doc.setTextColor('#464F60');
  doc.setFont('Inter', 'normal', 400);
  doc.text(
    'This pay statement shows your completed shipments, deductions and any additional additions for the above pay period.',
    16,
    107,
    { maxWidth: pageWidth - 24 }
  );

  // Paystub ID
  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 110, 16, pageWidth - (pageWidth - 94), 7, 'F');
  doc.setFontSize(10);
  doc.setTextColor('#FFFFFF');
  doc.text('Pay Statement ID', pageWidth - 106, 21);
  doc.setFontSize(10.5);
  doc.text(`${id}`, pageWidth - 70, 21);

  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#000000');
  doc.text(`Set Pay Date:`, pageWidth - 108, 28);
  doc.text(`Approved by:`, pageWidth - 108, 34);
  doc.setFont('Inter', 'normal', 400);
  doc.text(set_payment_date ? `${dateFormat.formatDate(set_payment_date)}` : 'N/A', pageWidth - 81, 28);
  doc.text(`${userData.first_name} ${userData.last_name}`, pageWidth - 81, 34);
  doc.addImage(getBarcode(`${id}`, false), 'JPEG', pageWidth - 45, 24, 30, 15);

  createPaymentSummary(doc, data);

  if (time_trackings?.length && showTimeTracking) {
    doc.setFontSize(11);
    doc.setTextColor('#4F5AED');
    doc.setFont('Inter', 'normal', 400);
    doc.text('Hours Worked', 16, 115);
    doc.line(16, 118, pageWidth - 16, 118);
    createTrackingTableHeader(doc);

    const firstPageData = time_trackings.slice(0, 8);
    const restData = time_trackings.slice(8);
    const totalPages = Math.ceil(restData.length / 14) + 1;

    firstPageData.forEach((item, index) => {
      createTrackingRow(doc, item, index, data, 129);
    });

    if (restData.length) {
      for (let i = 0; i < totalPages - 1; i++) {
        const newArray = restData.slice(i * 14, i * 14 + 14);

        if (newArray.length > 0) {
          doc.addPage();
          doc.line(16, 16, pageWidth - 16, 16);
          createTrackingTableHeader(doc, 20);

          newArray.forEach((item, index) => {
            createTrackingRow(doc, item, index, data, 26);
          });
        }
      }
    }
  }

  if (shipments?.length && !showTimeTracking && !isStaff) {
    doc.setFontSize(11);
    doc.setTextColor('#4F5AED');
    doc.setFont('Inter', 'normal', 400);
    doc.text('Shipments', 16, 115);
    doc.line(16, 118, pageWidth - 16, 118);
    createShipmentsTableHeader(doc);

    const firstPageData = shipments.slice(0, 8);
    const restData = shipments.slice(8);
    const totalPages = Math.ceil(restData.length / 14) + 1;

    firstPageData.forEach((item, index) => {
      createShipmentRow(doc, item, index, data, 129);
    });

    if (restData.length) {
      for (let i = 0; i < totalPages - 1; i++) {
        const newArray = restData.slice(i * 14, i * 14 + 14);

        if (newArray.length > 0) {
          doc.addPage();
          doc.line(16, 16, pageWidth - 16, 16);
          createShipmentsTableHeader(doc, 20);

          newArray.forEach((item, index) => {
            createShipmentRow(doc, item, index, data, 26);
          });
        }
      }
    }
  }

  if (shipments?.length && showTimeTracking && !isStaff) {
    doc.addPage();
    doc.setFontSize(11);
    doc.setTextColor('#4F5AED');
    doc.text('Shipments', 16, 16);
    doc.line(16, 19, pageWidth - 16, 19);
    createShipmentsTableHeaderPerHour(doc, 24);

    const firstPageData = shipments.slice(0, 8);
    const restData = shipments.slice(8);
    const totalPages = Math.ceil(restData.length / 14) + 1;

    firstPageData.forEach((item, index) => {
      createShipmentRowPerHour(doc, item, index, data, 30);
    });

    if (restData.length) {
      for (let i = 0; i < totalPages - 1; i++) {
        const newArray = restData.slice(i * 14, i * 14 + 14);

        if (newArray.length > 0) {
          doc.addPage();
          doc.line(16, 16, pageWidth - 16, 16);
          createShipmentsTableHeaderPerHour(doc, 20);

          newArray.forEach((item, index) => {
            createShipmentRowPerHour(doc, item, index, data, 26);
          });
        }
      }
    }
  }

  if (isStaff && shipments?.length && !!compensation_details.each_booked_shipment) {
    const newPage = time_trackings?.length && showTimeTracking;

    if (newPage) {
      doc.addPage();
    }
    doc.setFontSize(11);
    doc.setTextColor('#4F5AED');
    doc.text('Shipments', 16, newPage ? 16 : 115);
    doc.line(16, newPage ? 19 : 118, pageWidth - 16, newPage ? 19 : 118);
    createStaffShipmentsTableHeader(doc, newPage ? 24 : 123);

    const firstPageData = shipments.slice(0, 8);
    const restData = shipments.slice(8);
    const totalPages = Math.ceil(restData.length / 14) + 1;

    firstPageData.forEach((item, index) => {
      createStaffShipmentRow(doc, item, index, data, newPage ? 30 : 129);
    });

    if (restData.length) {
      for (let i = 0; i < totalPages - 1; i++) {
        const newArray = restData.slice(i * 14, i * 14 + 14);

        if (newArray.length > 0) {
          doc.addPage();
          doc.line(16, 16, pageWidth - 16, 16);
          createStaffShipmentsTableHeader(doc, 20);

          newArray.forEach((item, index) => {
            createStaffShipmentRow(doc, item, index, data, 26);
          });
        }
      }
    }
  }

  if (commission?.length && isStaff && !!compensation_details.sales_commission_option) {
    const newPage =
      (time_trackings?.length && showTimeTracking) ||
      (shipments?.length && !!compensation_details.each_booked_shipment);

    if (newPage) {
      doc.addPage();
    }
    doc.setFontSize(11);
    doc.setTextColor('#4F5AED');
    doc.text('Shipment Commission', 16, newPage ? 16 : 115);
    doc.line(16, newPage ? 19 : 118, pageWidth - 16, newPage ? 19 : 118);

    createCommissionsTableHeader(doc, newPage ? 24 : 123);
    const firstPageData = commission.slice(0, 16);
    const restData = commission.slice(16);
    const totalPages = Math.ceil(restData.length / 14) + 1;

    firstPageData.forEach((item, index) => {
      createCommissionRow(doc, item, index, data, newPage ? 30 : 129);
    });

    if (restData.length) {
      for (let i = 0; i < totalPages - 1; i++) {
        const newArray = restData.slice(i * 14, i * 14 + 14);

        if (newArray.length > 0) {
          doc.addPage();
          doc.line(16, 16, pageWidth - 16, 16);
          createCommissionsTableHeader(doc, 20);

          newArray.forEach((item, index) => {
            createCommissionRow(doc, item, index, data, 26);
          });
        }
      }
    }
  }

  // Compensation
  doc.addPage();
  const balancesEnd = createBalancesTable(doc, {
    balances,
    currency,
    userType: isStaff ? 'staff' : 'driver',
    settlementId: id,
  });
  const deductionsTop = balancesEnd + 10;
  const deductionsEnd = createDeductionsTable(doc, { deductions, currency, transactions }, deductionsTop);
  const additionsTop = deductionsEnd + 10;
  const additionsEnd = createAdditionsTable(
    doc,
    {
      additions,
      absences,
      detention,
      overtime,
      oneTimeAdditions,
      currency,
      dateFormat,
    },
    additionsTop
  );
  const taxesTop = additionsEnd + 10;
  const taxesEnd = createTaxesTable(
    doc,
    { taxes, additions, deductions, currency, gross_revenue: Number(settlementDetails.gross) || 0 },
    taxesTop
  );
  const benefitsTop = taxesEnd + 10;
  const benefitsEnd = createBenefitsTable(doc, { benefits, currency }, benefitsTop);

  if (benefitsEnd > 190) {
    doc.addPage();
  }
  createTotalSettlementSection(doc, { ...data, yearToDate }, benefitsEnd > 190 ? 16 : benefitsEnd + 8);

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, { ...company, notification_email }, currentPage, pagesCount);
  }

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  // doc.save(`paystub.pdf`);
  return { blob, url };
};
