import React from 'react';
import { usePagination } from 'hooks/usePagination';
import './equipmentPagination.css';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';

import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';

const CustomPagination = ({ pageSize, totalCount, currentPage, onPageChange, lastPage, hideDropdown = true }) => {
  const { use } = useTheme();
  const { paginationRange } = usePagination({ totalCount, pageSize });

  const onNext = () => {
    const page = currentPage >= lastPage ? 1 : currentPage + 1;
    onPageChange(page);
  };

  const onPrevious = () => {
    const page = currentPage <= 1 ? lastPage : currentPage - 1;
    onPageChange(page);
  };

  return (
    <ul className='pagination-container'>
      <li
        className='pagination-item'
        style={{ boxShadow: use('0 0 0 1px rgba(70, 79, 96, 0.16)', '0 0 0 1px rgba(70, 79, 96, 1)') }}
        onClick={onPrevious}
      >
        <div className='arrow left' />
      </li>
      {!hideDropdown && (
        <Dropdown
          className='dropdown'
          onSelect={(value) => {
            onPageChange(Number(value));
          }}
        >
          <Dropdown.Toggle
            placement='top'
            variant='outline-secondary'
            id='dropdown-basic'
            className='tablePlaner_dropdownToggle'
            style={{
              color: use(palette.gray700, palette.gray200),
              backgroundColor: use(palette.white, palette.dark800),
            }}
          >
            {currentPage}
            <div className='tablePlaner_arrows_wrapper'>
              <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className='tablePlaner_menu' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            {paginationRange.map((el) => {
              return (
                <Dropdown.Item
                  eventKey={String(el)}
                  active={+currentPage === el}
                  style={{
                    color:
                      +currentPage === el ? use(palette.white, palette.white) : use(palette.gray700, palette.gray200),
                  }}
                  key={el}
                >
                  {el}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
      <li
        className='pagination-item'
        style={{ boxShadow: use('0 0 0 1px rgba(70, 79, 96, 0.16)', '0 0 0 1px rgba(70, 79, 96, 1)') }}
        onClick={onNext}
      >
        <div className='arrow right' />
      </li>
    </ul>
  );
};

export default CustomPagination;
