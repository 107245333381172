import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';
import { getAverageTimeConverted } from './converters';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createSummaryTableHeader = (doc, filterType) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Location Name', 16, top);
  doc.text(filterType === 'equipment' ? 'Equipment' : 'Driver', 106, top);
  doc.text('Avg Wait Time', pageWidth - 16, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
};

const createDetailedTableHeader = (doc, filterType) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Location Name', 16, top);
  doc.text(filterType === 'equipment' ? 'Equipment' : 'Driver', 54, top);
  doc.text('Customer', 81, top);
  doc.text('Shipment', 101, top);
  doc.text('Arrived Date', 117, top);
  doc.text('Departed Date', 150, top);
  doc.text('Wait Time', pageWidth - 16, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
};

const createSummaryTableRow = (doc, data, top, additionalData) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const { filterType } = additionalData;

  doc.setFontSize(7);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);

  doc.text(data.location_name, 16, top, { maxWidth: 102, lineHeightFactor: 0.85 });
  if (filterType === 'equipment') {
    doc.text(
      `${data.equipment?.equipment_id || ''}${
        data.equipment?.length?.length ? ` ${data.equipment?.length?.length}` : ''
      }${data.equipment?.length?.unit || ''} ${data.equipment?.equipment_type?.title || ''} ${
        data.equipment?.equipment_type?.title === 'Truck'
          ? data.equipment?.truck_type === 1
            ? ' (Day-Cab)'
            : ' (Sleeper)'
          : ''
      }`,
      106,
      top
    );
  } else {
    doc.text(`${data.driver.fname} ${data.driver.lname}`, 106, top);
  }
  doc.text(
    `${data.average_waiting_time?.days || 0}d ${data.average_waiting_time?.hours || 0}h ${
      data.average_waiting_time?.minutes || 0
    }m`,
    pageWidth - 16,
    top,
    { align: 'right' }
  );
};

const createDetailedTableRow = (doc, data, top, additionalData) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const { dateFormat, filterType } = additionalData;

  if (data.isTotal) {
    doc.setFontSize(7);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 700);
    doc.text(`${data.owner} Average Wait Time`, 16, top - 0.5);
    doc.text(
      `(Visited ${data.visitedTimes} Time${data.visitedTimes > 1 ? 's' : ''}) ${getAverageTimeConverted(
        data.totalWaitTimeMinutes / data.divider
      )}`,
      pageWidth - 16,
      top - 0.5,
      {
        align: 'right',
      }
    );
  } else {
    doc.setFontSize(7);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);

    doc.text(data.location_name, 16, top, { maxWidth: 42, lineHeightFactor: 0.85 });
    if (filterType === 'equipment') {
      doc.text(
        `${data.equipment?.equipment_id || ''}${
          data.equipment?.length?.length ? ` ${data.equipment?.length?.length}` : ''
        }${data.equipment?.length?.unit || ''} ${data.equipment?.equipment_type?.title || ''} ${
          data.equipment?.equipment_type?.title === 'Truck'
            ? data.equipment?.truck_type === 1
              ? ' (Day-Cab)'
              : ' (Sleeper)'
            : ''
        }`,
        54,
        top
      );
    } else {
      doc.text(`${data.driver.fname} ${data.driver.lname}`, 54, top);
    }
    doc.text(`${data.shipment_billing ? `${data.shipment_billing.billing_customer?.customer_name}` : '-'}`, 81, top, {
      maxWidth: 24,
      lineHeightFactor: 0.85,
    });
    doc.text(`${data.shipment_id || '-'}`, 108, top, { align: 'center' });
    doc.text(`${data.arrived_date ? dateFormat.convertToCustomerTime(data.arrived_date, null, true) : '-'}`, 117, top);
    doc.text(
      `${data.departed_date ? dateFormat.convertToCustomerTime(data.departed_date, null, true) : '-'}`,
      150,
      top
    );
    doc.text(
      `${data.duration_days || 0}h ${data.duration_hours || 0}m ${data.duration_minutes || 0}m`,
      pageWidth - 16,
      top,
      { align: 'right' }
    );
  }
};

const createTableRows = (doc, data, additionalData) => {
  if (!data?.length) {
    return;
  }

  const { type } = additionalData;

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  data.forEach((item, index) => {
    if (type === 'summary') {
      createSummaryTableRow(doc, item, top + index * 5, additionalData);
    } else {
      createDetailedTableRow(doc, item, top + index * 5, additionalData);
    }
  });

  return top + data.length * 5;
};

export const generatePDF = async (data, download, additionalData) => {
  const { dateRange, dateFormat, filterType, type } = additionalData;

  const reportItems = type === 'detailed' ? [] : data || [];

  if (type === 'detailed') {
    data.forEach((item) => {
      reportItems.push(...item.data, {
        isTotal: true,
        totalWaitTimeMinutes: item.totalWaitTimeMinutes,
        divider: item.data.length,
        visitedTimes: item.data.length,
        owner: `${
          filterType === 'equipment'
            ? `${item.data[0]?.equipment?.equipment_id || ''}${
                item.data[0]?.equipment?.length?.length ? ` ${item.data[0]?.equipment?.length?.length}` : ''
              }${item.data[0]?.equipment?.length?.unit || ''} ${item.data[0]?.equipment?.equipment_type?.title || ''} ${
                item.data[0]?.equipment?.equipment_type?.title === 'Truck'
                  ? item.data[0]?.equipment?.truck_type === 1
                    ? ' (Day-Cab)'
                    : ' (Sleeper)'
                  : ''
              }`
            : `${item.data[0]?.driver?.fname} ${item.data[0]?.driver?.lname}`
        }`,
      });
    });
  }

  if (!reportItems?.length) {
    return;
  }

  const { start, end } = dateRange;

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Average Wait Time', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  doc.text(
    !start || !end
      ? `All Time`
      : `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
    pageWidth / 2,
    28,
    {
      align: 'center',
    }
  );
  doc.setFont('Inter', 'normal', 400);
  doc.text(dateFormat.convertToCustomerTime(), pageWidth - 16, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(16, 30, pageWidth - 16, 30);

  const firstPageData = reportItems.slice(0, 40);
  const restData = reportItems.slice(40);

  createTableRows(doc, firstPageData, additionalData);

  for (let i = 0; i < (restData?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageData = restData.slice(i * 45, (i + 1) * 45);
    createTableRows(doc, currentPageData, additionalData);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    if (type === 'summary') {
      createSummaryTableHeader(doc, filterType);
    } else {
      createDetailedTableHeader(doc, filterType);
    }
  }

  doc.setDocumentProperties({ title: `Arrivals and Departures` });

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  if (download) {
    doc.save(`driver-average-wait-time-${moment().format('YYYY-MM-DD')}`);
  }
  return { blob, url };
};
