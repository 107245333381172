import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './firstTimeLogin.style.css';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/auth.service';
import { useAuth } from 'context/auth.context';
import { setBrowserInfo } from 'common/BrowserVarify';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import ThirdStep from './components/ThirdStep';
import ForthStep from './components/ForthStep';
import AddressStep from './components/AddressStep';
import PhotoIdentificationStep from './components/PhotoIdentificationStep';
import SplashScreen from '../splashScreen/splashScreen';

const FirstTimeLogin = ({ isOpen, onlyOtp, setOpenModal, setIsOpenConfigure }) => {
  const { value, setAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [pwd, setPwd] = useState();
  const [repeatPwd, setRepeatPwd] = useState('');
  const [verifyCode, setVerifyCode] = useState();
  const [imgFile, setImgFile] = useState();
  const [blobToFile, setBlobToFile] = useState();
  const [errorCode, setErrorCode] = useState('');
  const [displayErrorCode, setDisplayErrorCode] = useState(false);
  const [otpTimer, setOtpTimer] = useState(60);
  const [successMsg, setsuccessMsg] = useState('');
  const [displayOtpSuccessMsg, setdisplayOtpSuccessMsg] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const navigate = useNavigate();

  const nextStep = (passIdentificationStep = false) => {
    if (passIdentificationStep) {
      setStep(step + 2);
    } else {
      setStep(step + 1);
    }
  };

  const sendOtp = () => {
    setOtpTimer(60);
    AuthService.sendOtp()
      .then(() => {
        setsuccessMsg('SMS Verification Code Sent!');
        setdisplayOtpSuccessMsg(!displayOtpSuccessMsg);
      })
      .catch((error) => {
        if (error.response?.data) {
          if (error.response?.data?.error !== undefined) {
            setErrorCode('Phone number is not correct');
            setDisplayErrorCode(!displayErrorCode);
          } else {
            setErrorCode(error.response.data.message);
            setDisplayErrorCode(!displayErrorCode);
          }
        }

        if (error.response?.error) {
          setErrorCode(error.response.error.message);
          setDisplayErrorCode(!displayErrorCode);
        }
      });
  };

  const verifyOtp = () => {
    AuthService.verifyOtp(verifyCode)
      .then((response) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (response.data?.chat_token) {
          localStorage.setItem('chatToken', JSON.stringify(response.data.chat_token));
        }
        if (response.data?.department && user) {
          localStorage.setItem('user', JSON.stringify({ ...user, department: response.data?.department }));
        }
        if (onlyOtp) {
          setBrowserInfo();
          setOpenModal(false);
          navigate('/dashboard');
        } else {
          if (value.user.is_password_set !== 1) {
            const formData = new FormData();
            let saveimg;
            if (imgFile) {
              saveimg = imgFile;
            } else if (blobToFile) {
              const f = new FormData();
              f.append('save', blobToFile);
              saveimg = f.get('save');
            }
            if (saveimg) {
              formData.append('profile_logo', saveimg);
            }
            formData.append('password', pwd);
            formData.append('password_confirmation', repeatPwd);
            formData.append('_method', 'PUT');
            setBrowserInfo();
            AuthService.loginSetPwd(formData)
              .then((response) => {
                const profile_logo = response?.data?.path;
                localStorage.setItem('userAvatar', JSON.stringify(response.data));
                const savedUser = JSON.parse(localStorage.getItem('user'));
                const User = {
                  ...savedUser,
                  is_password_set: 1,
                  profile_logo: profile_logo || null,
                };
                localStorage.setItem('user', JSON.stringify(User));
                AuthService.getCurrentUser();
                setAuth({
                  user: User,
                  isLoggedIn: true,
                  error: null,
                  loading: false,
                  shouldPassConfigureModal: true,
                });
                if (User?.email === User?.customer?.email) {
                  setIsOpenConfigure(true);
                }
                setTimeout(() => setOpenModal(false), 0);
              })
              .catch(() => {
                // Do nothing
              });
          }
        }
      })
      .catch((error) => {
        setErrorCode(error.response?.data?.message || 'Something went wrong');
        setDisplayErrorCode(!displayErrorCode);
      });
  };

  const saveCustomerData = () => {
    if (value.user.is_password_set !== 1) {
      const formData = new FormData();
      let saveimg;
      if (imgFile) {
        saveimg = imgFile;
      } else if (blobToFile) {
        const f = new FormData();
        f.append('save', blobToFile);
        saveimg = f.get('save');
      }
      if (saveimg) {
        formData.append('profile_logo', saveimg);
      }
      formData.append('password', pwd);
      formData.append('password_confirmation', repeatPwd);
      formData.append('_method', 'PUT');
      setBrowserInfo();
      AuthService.loginSetPwd(formData)
        .then((response) => {
          const profile_logo = response?.data?.path;
          localStorage.setItem('userAvatar', JSON.stringify(response.data));
          const savedUser = JSON.parse(localStorage.getItem('user'));
          const User = {
            ...savedUser,
            is_password_set: 1,
            profile_logo: profile_logo || null,
          };
          localStorage.setItem('user', JSON.stringify(User));
          AuthService.getCurrentUser();
          setAuth({
            user: User,
            isLoggedIn: true,
            error: null,
            loading: false,
            shouldPassConfigureModal: true,
          });
          if (User?.email === User?.customer?.email) {
            setIsOpenConfigure(true);
          }
          setTimeout(() => setOpenModal(false), 0);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const handleCodeChange = (verifyCode) => {
    setVerifyCode(verifyCode);
  };

  const handleSubmission = (e, noOtp) => {
    e.preventDefault();
    if (!noOtp) {
      verifyOtp();
    }
    if (value.user.is_password_set !== 1) {
      const formData = new FormData();
      let saveimg;
      if (imgFile) {
        saveimg = imgFile;
      } else if (blobToFile) {
        const f = new FormData();
        f.append('save', blobToFile);
        saveimg = f.get('save');
      }
      formData.append('password_confirmation', repeatPwd);
      if (saveimg) {
        formData.append('profile_logo', saveimg);
      }
      formData.append('password', pwd);
      formData.append('_method', 'PUT');
      setBrowserInfo();
      AuthService.loginSetPwd(formData)
        .then((response) => {
          const profile_logo = response?.data?.path;
          localStorage.setItem('userAvatar', JSON.stringify(response.data));
          const savedUser = JSON.parse(localStorage.getItem('user'));
          const User = {
            ...savedUser,
            is_password_set: 1,
            profile_logo: profile_logo || null,
          };
          localStorage.setItem('user', JSON.stringify(User));
          AuthService.getCurrentUser();
          setAuth({
            user: User,
            isLoggedIn: true,
            error: null,
            loading: false,
            shouldPassConfigureModal: true,
          });
          if (onlyOtp || savedUser?.email !== savedUser?.customer?.email) {
            navigate('/dashboard');
          } else {
            setIsOpenConfigure(true);
          }
          setTimeout(() => setOpenModal(false), 0);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const handleResendOtp = () => {
    if (otpTimer === 0) {
      sendOtp();
    }
  };

  useEffect(() => {
    const time = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => {
      clearTimeout(time);
    };
  }, [value, imgFile, step, blobToFile]);

  useEffect(() => {
    if (step === 0 && onlyOtp && value.user?.customer?.send_otp_to_user) {
      sendOtp();
    }
  }, [onlyOtp]);

  useEffect(() => {
    if (otpTimer <= 0) {
      /* empty */
    } else {
      const interval = setInterval(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [otpTimer, onlyOtp]);

  useEffect(() => {
    if (verifyCode?.length === 5) {
      verifyOtp();
    }
  }, [verifyCode]);

  useEffect(() => {
    const myFunc = (num) => Number(num);
    if (value?.user?.phone_number) {
      const intArr = Array.from(String(value.user.phone_number.replace(/\D+/g, '')), myFunc);
      const phone = intArr.map((elm, idx) => {
        if (idx === 0) {
          return '+';
        }
        if (idx > 0 && idx < 7) {
          return '*';
        }
        return `${elm}`;
      });
      setPhoneNumber(phone.join(''));
    }
  }, [value]);

  const steps = [
    <FirstStep nextStep={nextStep} value={value} />,
    <SecondStep nextStep={nextStep} imgFile={imgFile} setImgFile={setImgFile} setBlobToFile={setBlobToFile} />,
    <ThirdStep
      nextStep={nextStep}
      pwd={pwd}
      repeatPwd={repeatPwd}
      setPwd={setPwd}
      setRepeatPwd={setRepeatPwd}
      sendOtp={sendOtp}
      saveCutomerData={saveCustomerData}
      step={step}
    />,
    // <ForthStep
    //   handleSubmission={handleSubmission}
    //   handleResendOtp={handleResendOtp}
    //   otpTimer={otpTimer}
    //   phoneNumber={phoneNumber}
    //   errorCode={errorCode}
    //   setDisplayErrorCode={setDisplayErrorCode}
    //   displayErrorCode={displayErrorCode}
    //   successMsg={successMsg}
    //   setdisplayOtpSuccessMsg={setdisplayOtpSuccessMsg}
    //   displayOtpSuccessMsg={displayOtpSuccessMsg}
    //   verifyCode={verifyCode}
    //   handleCodeChange={handleCodeChange}
    // />,
  ];

  const staffSteps = [
    <FirstStep nextStep={nextStep} value={value} />,
    <SecondStep nextStep={nextStep} imgFile={imgFile} setImgFile={setImgFile} setBlobToFile={setBlobToFile} />,
    <ThirdStep
      nextStep={nextStep}
      pwd={pwd}
      repeatPwd={repeatPwd}
      setPwd={setPwd}
      setRepeatPwd={setRepeatPwd}
      sendOtp={sendOtp}
      step={step}
      isStaff
    />,
    <AddressStep value={value} sendOtp={sendOtp} handleSubmission={handleSubmission} nextStep={nextStep} />,
    <PhotoIdentificationStep value={value} nextStep={nextStep} sendOtp={sendOtp} handleSubmission={handleSubmission} />,
    <ForthStep
      handleSubmission={handleSubmission}
      handleResendOtp={handleResendOtp}
      otpTimer={otpTimer}
      phoneNumber={phoneNumber}
      errorCode={errorCode}
      setDisplayErrorCode={setDisplayErrorCode}
      displayErrorCode={displayErrorCode}
      successMsg={successMsg}
      setdisplayOtpSuccessMsg={setdisplayOtpSuccessMsg}
      displayOtpSuccessMsg={displayOtpSuccessMsg}
      verifyCode={verifyCode}
      handleCodeChange={handleCodeChange}
    />,
  ];

  return onlyOtp ? (
    <Modal isOpen={isOpen} className='modal-custom' overlayClassName='Overlay newBrowserOverlay' ariaHideApp={false}>
      <ForthStep
        handleSubmission={handleSubmission}
        handleResendOtp={handleResendOtp}
        otpTimer={otpTimer}
        phoneNumber={phoneNumber}
        errorCode={errorCode}
        setDisplayErrorCode={setDisplayErrorCode}
        displayErrorCode={displayErrorCode}
        successMsg={successMsg}
        setdisplayOtpSuccessMsg={setdisplayOtpSuccessMsg}
        displayOtpSuccessMsg={displayOtpSuccessMsg}
        verifyCode={verifyCode}
        handleCodeChange={handleCodeChange}
      />
    </Modal>
  ) : (
    <>
      <div className='video-cover' style={{ display: loading ? '' : 'none' }}>
        <video
          autoPlay
          loop
          muted
          playsInline
          data-wf-ignore
          data-object-fit='cover'
          id='video'
          poster='assets/images/image 23.png'
        >
          <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.mp4`} data-wf-ignore='true' />
          <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.webm`} data-wf-ignore='true' />
        </video>
      </div>
      <div className='purple-filter' style={{ display: loading ? '' : 'none' }} />
      {loading ? (
        <SplashScreen />
      ) : (
        <Modal isOpen={isOpen} className='modal-custom' overlayClassName='Overlay' ariaHideApp={false}>
          {value?.user?.email !== value?.user?.customer?.email ? staffSteps[step] : steps[step]}
        </Modal>
      )}
    </>
  );
};

export default FirstTimeLogin;
