import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';

import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { createDelayReason, deleteDelayReason, getDelayReasonsList } from 'Api/CompanySettings';
import sm from './DelayReasons.module.css';
import ItemForm from './ItemForm';
import PageHeader from '../../components/PageHeader';
import { SActions, SAddMore, SCircularProgress, SContentRow, SRow, SWrapper } from '../Payroll/Payroll.styles';

const DelayReasons = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [delayReasons, setDelayReasons] = useState([]);
  const [forms, setForms] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDelayReasons = async () => {
    try {
      const { data } = await getDelayReasonsList();
      setDelayReasons(data);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const createNewDelayReason = async (values, id) => {
    setLoadingCreate(true);
    try {
      const data = { title: values?.type, code: values.code };
      await createDelayReason(data);
      await getDelayReasons();
      deleteFormItem(id);
      showToaster({ type: 'success', message: 'Delay reason has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingCreate(false);
  };

  const deleteItem = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteDelayReason(id);
      const newAdditions = delayReasons.filter((item) => item.id !== id);
      setDelayReasons(newAdditions);
      showToaster({ type: 'success', message: 'Delay reason has been successfully deleted!' });
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingDelete(false);
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getDelayReasons();
  }, []);

  if (loading) {
    return (
      <SWrapper>
        <SCircularProgress size={26} />
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      <PageHeader
        title='Delay Reason Codes'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={() => onSaveAndNext('saveAndNext')}
        loading={false}
        hideSave
      />
      <Divider margin='8px 0' />
      <SRow>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            TYPE
          </Typography>
        </div>
        <div className='settings-header-item'>
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            Code
          </Typography>
        </div>
        <div className='settings-header-item'>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
              ACTIONS
            </Typography>
          </div>
        </div>
      </SRow>
      {delayReasons.map((item) => (
        <SContentRow key={item.id}>
          <div className='settings-table-item'>
            <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
              {item.title}
            </Typography>
          </div>
          <div className='settings-table-item'>
            <p className={sm['list-text']}>
              <span className={sm['list-styled-text']} style={{ margin: 0 }}>
                {item.code}
              </span>
            </p>
          </div>
          <SActions className='settings-table-item settings-table-actions-wrapper'>
            {loadingDelete ? (
              <span className='loading-wrapper'>
                <CircularProgress size={16} />
              </span>
            ) : (
              !item.is_default && (
                <span className='action-wrapper' onClick={() => deleteItem(item.id)}>
                  <DeleteIcon width={11} height={11} fill={palette.red500} />
                </span>
              )
            )}
          </SActions>
        </SContentRow>
      ))}
      {forms.map((item) => (
        <ItemForm
          key={item.id}
          onSubmit={(values) => createNewDelayReason(values, item.id)}
          onDelete={() => deleteFormItem(item.id)}
          loading={loadingCreate}
        />
      ))}
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200), cursor: 'pointer' }}>
          Add Type...
        </Typography>
      </SAddMore>
    </SWrapper>
  );
};

export default DelayReasons;
