import React from 'react';
import './CustomSelect.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import upDown from 'assets/icons/drivers/up-down.png';

const CustomSelect = ({
  onClick,
  onBlur,
  onChange,
  value,
  name,
  id,
  defaultValue,
  defaultSecondValue,
  custom,
  options = [],
  disabled,
  propertyName = 'name',
  patchProperty = 'id',
  styles,
}) => {
  const { use } = useTheme();
  return (
    <div
      className='select-custom-dropdown-id driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark600),
        borderColor: use(palette.gray50, palette.darkborder),
        color: use(palette.gray500, palette.gray200),
        ...styles,
      }}
    >
      <select
        disabled={disabled}
        onBlur={onBlur}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        id={id}
        name={name}
        onClick={onClick}
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          color: disabled ? 'gray' : use(palette.gray500, palette.gray200),
        }}
      >
        {defaultValue && <option value={null}>{defaultValue}</option>}
        {defaultSecondValue && <option value={0}>{defaultSecondValue}</option>}
        {custom && <option value='custom'>{custom}</option>}
        {options.map((option) => (
          <option
            key={typeof option === 'string' ? option : option[patchProperty]}
            value={typeof option === 'string' ? option : option[patchProperty]}
          >
            {typeof option !== 'string' ? option[propertyName] : option || ''}
          </option>
        ))}
      </select>
      <img src={upDown} className='up-down-icon' alt='updown' />
    </div>
  );
};

CustomSelect.defaultProps = {
  type: 'primary',
};

export default CustomSelect;
