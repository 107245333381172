import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .content-wrapper {
    width: 400px;
    margin: 0;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  @media (max-width: 450px) {
    padding: 0 16px;
    margin-bottom: 24px;
  }
`;

export const SInputWrapper = styled.div`
  display: flex;

  div:first-child {
    input {
      border-radius: 6px 0 0 6px;
    }
  }

  div:nth-child(2) {
    input {
      border-radius: 0 6px 6px 0;
    }
  }
`;

export const SDotLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .spinner {
    height: 4rem;
    width: 4rem;
    border: 5px solid whitesmoke;
    border-left: 5px solid lightgray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin infinite 1.5s linear;
  }

  .spinner-text {
    position: absolute;
    top: 30%;
    font-size: 8px;
    color: black;
  }
`;
