import React, { useState } from 'react';
import Modal from 'common/Modal';
import { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette, SYSTEM_ACCOUNT_TYPE } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { transferFunds } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';

const Transfer = ({ open, onClose, accounts }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const treasury = accounts.find((i) => i.system_account_type === SYSTEM_ACCOUNT_TYPE.TRUCKIN_DIGITAL_TREASURY);
  const cashCard = accounts.find((i) => i.system_account_type === SYSTEM_ACCOUNT_TYPE.TRUCKIN_DIGITAL_CASH_CARD);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        amount: Number(values.amount),
        currency: 'usd',
      };
      const response = await transferFunds(body);

      if (response?.error) {
        showToaster({ type: 'error', message: response.error });
        return;
      }

      showToaster({ type: 'success', message: 'Success!' });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { amount: '' },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Transfer'
      $bgColor={palette.gray0}
      $maxWidth='520px'
      $minWidth='520px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Transfer',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-4'>
        <div className='d-flex flex-column gap-3'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            From: {cashCard?.account_name}
          </Typography>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            To: {treasury?.account_name}
          </Typography>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <InputWithIcon
            required
            type='number'
            label='Amount'
            placeholder='0.00'
            name='amount'
            value={values.amount}
            onChange={handleChange}
            onKeyDown={blockNonPositiveNumbers}
            error={touchedErrors.amount}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Transfer;
