import React from 'react';
import Modal from 'common/Modal';
import AddressFields from 'common/AddressFields';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';

export default function EditAddressModal({ open, onClose, addressData, updateAddress }) {
  const onSubmit = async (values) => {
    updateAddress({ index: addressData?.index, value: values });
    onClose();
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      country: addressData?.country || null,
      state: addressData?.state || null,
      city: addressData?.city,
    },
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Edit Address'
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='600px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'update',
          type: 'primary',
          title: 'Edit Address',
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='mt-3 mb-3'>
        <AddressFields
          isOneRow
          hideAddress
          hideZipcode
          values={values}
          handleChange={handleChange}
          touchedErrors={touchedErrors}
        />
      </div>
    </Modal>
  );
}
