import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import { DatePicker } from 'common/Pickers';
import { palette, PERMISSIONS } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { requestValidationSchema } from '../Drivers/AddDriver/ValidationSchema';

export default function RequestUpdateModal({ createLoading, id, name, udpdateRequestData, updateRequest, ...props }) {
  const { value } = useAuth();
  const { user } = value;
  const { use } = useTheme();
  const { currency, permissions } = useSelector((state) => state.root);

  const saveRequest = (values) => {
    const { req_start_datetime, req_end_datetime, request_amount } = values;
    const startDate = req_start_datetime ? moment(req_start_datetime || new Date()).format('MM-DD-YYYY') : new Date();
    const endDate = req_end_datetime ? moment(req_end_datetime || new Date()).format('MM-DD-YYYY') : new Date();
    const driverId = +id;
    const requestId = udpdateRequestData?.id;
    const amount = request_amount === '' ? null : +request_amount;
    const index = udpdateRequestData?.tableData?.id;
    let payload = {
      ...values,
      req_start_datetime: startDate,
      req_end_datetime: endDate,
      driver_id: driverId,
      request_amount: amount,
      requestId,
      index,
    };
    if (!values.request_type === 'Time Off') {
      payload = { ...payload, req_start_datetime: null, req_end_datetime: null };
    }
    if (values.request_type === 'Time Off' && values.req_status === 'Approved') {
      const absencePayload = {
        user_type: 'staff',
        absence_start_date: moment(req_start_datetime).format('MM/DD/YYYY'),
        absence_end_date: moment(req_end_datetime).format('MM/DD/YYYY'),
        absence_details: values.note,
        type: values.absenceType,
        reason_title: null,
        request_id: null,
        internal_notes: values.internal_notes,
        absence_doc_id: null,
        absence_document: null,
        amount: null,
        driver_staff_id: driverId,
      };

      updateRequest(
        {
          ...payload,
          req_start_datetime: moment(req_start_datetime).format('MM/DD/YYYY'),
          req_end_datetime: moment(req_start_datetime).format('MM/DD/YYYY'),
          type: values.absenceType,
        },
        absencePayload
      );
    } else if (values.request_type === 'Time Off' && values.req_status === 'Rejected') {
      updateRequest({ ...payload, rej_reason: values.rej_reason });
    } else {
      delete payload.absenceType;
      updateRequest({ ...payload, rej_reason: values.rej_reason });
    }
  };

  const formik = useFormik({
    initialValues: {
      req_start_datetime: null,
      req_end_datetime: null,
      note: '',
      req_status: '',
      rej_reason: '',
      driver_staff_id: id,
      user_details: null,
      request_type: '',
      request_amount: null,
      shipment_id: null,
      type: null,
      absenceType: null,
      user_type: 'staff',
      internal_notes: null,
    },
    validationSchema: requestValidationSchema,
    onSubmit: saveRequest,
  });

  useEffect(() => {
    formik.setFieldValue(
      'req_start_datetime',
      udpdateRequestData?.req_start_datetime ? new Date(udpdateRequestData?.req_start_datetime) : new Date()
    );
    formik.setFieldValue(
      'req_end_datetime',
      udpdateRequestData?.req_end_datetime ? new Date(udpdateRequestData?.req_end_datetime) : new Date()
    );
    formik.setFieldValue('note', udpdateRequestData?.note);
    formik.setFieldValue(
      'req_status',
      udpdateRequestData?.req_status === 'P' ? 'Pending' : udpdateRequestData?.req_status
    );
    formik.setFieldValue('request_type', udpdateRequestData?.request_type);
    formik.setFieldValue('request_amount', udpdateRequestData?.request_amount || null);
    formik.setFieldValue('shipment_id', udpdateRequestData?.shipment_id || null);
  }, [udpdateRequestData]);

  return (
    <div>
      <Modal
        {...props}
        dialogClassName='modal-80w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        enforceFocus={false}
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='request-modal-body-box driver-style-wrap'
          style={{
            background: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='request-modal-wrap'>
            <div className='request-container-wrapper request-type-wrapper'>
              <p
                className='text-style abcense-text-style'
                style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
              >
                Request Type <span className='required'>*</span>
              </p>
              <div className='request-type-opt'>
                <p className='payment-option'>
                  <input
                    checked={formik.values.request_type === 'Time Off'}
                    value={formik.values.request_type}
                    type='radio'
                    name='request_type'
                    onChange={() => formik.setFieldValue('request_type', 'Time Off')}
                    className='radio-button'
                    defaultChecked
                    disabled={user.id !== Number(id) || !permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Time Off
                  </span>
                </p>
                <p className='payment-option'>
                  <input
                    type='radio'
                    name='request_type'
                    checked={formik.values.request_type === 'Cash Advance'}
                    value={formik.values.request_type}
                    onChange={() => formik.setFieldValue('request_type', 'Cash Advance')}
                    className='radio-button'
                    disabled={user.id !== Number(id) || !permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Cash Advance{' '}
                  </span>
                </p>
              </div>
            </div>
            {formik.values.request_type === 'Time Off' ? (
              <div>
                <div className='request-container-wrapper'>
                  <DatePicker
                    required
                    label='Request Start Date'
                    name='req_start_datetime'
                    value={formik.values.req_start_datetime}
                    onChange={(date) => {
                      formik.setFieldValue('req_start_datetime', date);
                      if (moment(date).isValid() && moment(formik.values.req_end_datetime).isValid()) {
                        if (date.getTime() > formik.values.req_end_datetime.getTime()) {
                          formik.setFieldValue('req_end_datetime', date);
                        }
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.req_start_datetime && formik.errors.req_start_datetime}
                  />
                </div>
                <div className='request-container-wrapper'>
                  <DatePicker
                    required
                    label='Request End Date'
                    name='req_end_datetime'
                    value={formik.values.req_end_datetime}
                    onChange={(date) => {
                      formik.setFieldValue('req_end_datetime', date);
                      if (moment(date).isValid() && moment(formik.values.req_start_datetime).isValid()) {
                        if (date.getTime() < formik.values.req_start_datetime.getTime()) {
                          formik.setFieldValue('req_start_datetime', date);
                        }
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.req_end_datetime && formik.errors.req_end_datetime}
                  />
                </div>
              </div>
            ) : null}
            {formik.values.request_type === 'Cash Advance' && (
              <div className='request-container-wrapper'>
                <p
                  className='text-style abcense-text-style'
                  style={{
                    marginLeft: '0',
                    color:
                      formik.values.request_type === 'Time Off'
                        ? use(palette.gray300, palette.gray200)
                        : formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number'
                        ? use(palette.gray300, palette.gray200)
                        : use(palette.gray700, palette.gray200),
                  }}
                >
                  Amount <span className='required'>*</span>
                </p>
                <div>
                  <input
                    type='number'
                    className='reason-input'
                    style={{
                      backgroundColor: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray600),
                    }}
                    id='request_amount'
                    name='request_amount'
                    placeholder={`${currency} Amount`}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue('request_amount', e.target.value);
                    }}
                    value={formik.values.request_amount}
                  />
                </div>
              </div>
            )}

            <div className='request-container-wrapper'>
              <p className='text-type' style={{ color: use(palette.gray700, palette.gray200) }}>
                Status
              </p>
              <div className='request-card-type card-type'>
                <div className='card-type-container'>
                  <input
                    type='radio'
                    name='req_status'
                    // defaultChecked
                    onChange={formik.handleChange}
                    value='Pending'
                    checked={formik.values.req_status === 'Pending' || formik.values.req_status === 'P'}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <p className='card-type-name'>Pending</p>
                </div>
                <div className='card-type-container'>
                  <input
                    type='radio'
                    name='req_status'
                    onChange={formik.handleChange}
                    value='Approved'
                    checked={formik.values.req_status === 'Approved' || formik.values.req_status === 'A'}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <p className='card-type-name'>Accepted</p>
                </div>
                <div className='card-type-container'>
                  <input
                    type='radio'
                    name='req_status'
                    onChange={formik.handleChange}
                    value='Rejected'
                    checked={formik.values.req_status === 'Rejected' || formik.values.req_status === 'R'}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                  <p className='card-type-name'>Rejected</p>
                </div>
              </div>
            </div>
            {formik.values?.req_status === 'Approved' && formik.values?.request_type !== 'Cash Advance' ? (
              <div className='absence-container-wrapper'>
                <p className='text-type' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Type
                </p>
                <div className='absence-card-type card-type'>
                  <div className='card-type-container'>
                    <input
                      type='radio'
                      name='absenceType'
                      onChange={formik.handleChange}
                      value='1'
                      checked={formik.values.absenceType === '1'}
                      disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                    />
                    <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Excused
                    </p>
                  </div>
                  <div className='card-type-container'>
                    <input
                      type='radio'
                      name='absenceType'
                      onChange={formik.handleChange}
                      value='2'
                      checked={formik.values.absenceType === '2'}
                      disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                    />
                    <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Unexcused
                    </p>
                  </div>
                  <div className='card-type-container'>
                    <input
                      type='radio'
                      name='absenceType'
                      onChange={formik.handleChange}
                      value='3'
                      checked={formik.values.absenceType === '3'}
                      disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                    />
                    <p className='card-type-name' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Paid Time Off (PTO)
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <span />
            )}
            {formik.values?.req_status === 'Approved' && formik.values?.request_type !== 'Cash Advance' ? (
              <div className='request-container-wrapper'>
                <p
                  className='text-style abcense-text-style'
                  style={{
                    marginLeft: '0',
                    color:
                      formik.values.request_type === 'Time Off'
                        ? use(palette.gray700, palette.gray200)
                        : formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                        ? use(palette.gray700, palette.gray200)
                        : formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number'
                        ? use(palette.gray700, palette.gray200)
                        : use(palette.gray300, palette.gray200),
                  }}
                >
                  Internal Notes <span className='required'>*</span>
                </p>
                <div>
                  <textarea
                    className='request-text-area'
                    id='internal_notes'
                    name='internal_notes'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.internal_notes}
                    placeholder='Write request notes...'
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                </div>
              </div>
            ) : null}
            {formik.values?.req_status === 'Rejected' ? (
              <div className='request-container-wrapper'>
                <p
                  className='text-style abcense-text-style'
                  style={{
                    marginLeft: '0',
                    color:
                      formik.values.request_type === 'Time Off'
                        ? use(palette.gray700, palette.gray200)
                        : formik.values.request_type === 'Cash Advance' || formik.values.request_type === 'Fuel Advance'
                        ? use(palette.gray700, palette.gray200)
                        : formik.values.request_type === 'Pickup Number' ||
                          formik.values.request_type === 'Delivery Number'
                        ? use(palette.gray700, palette.gray200)
                        : use(palette.gray300, palette.gray200),
                  }}
                >
                  Rejection Reason <span className='required'>*</span>
                </p>
                <div>
                  <textarea
                    className='request-text-area'
                    id='rej_reason'
                    name='rej_reason'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.rej_reason}
                    placeholder='Write request notes...'
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                    disabled={!permissions.includes(PERMISSIONS.REQUESTS.name)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark600),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.gray50, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {createLoading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button onClick={formik.handleSubmit} className='next-step' type='submit'>
                Update Request
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
