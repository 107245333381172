import React from 'react';
import classNames from 'classnames';
import styles from './progres.module.css';
import { Typography } from '../Typography';

const Progress = ({ icon, percent, colorPercent, ...props }) => {
  return (
    <div className={styles.wrapper}>
      {icon}
      <div {...props} className={classNames(styles.loading_wrapper, { [styles.loading100]: +percent === 100 })}>
        <div className={styles.loading}>
          <div style={{ backgroundColor: colorPercent, width: percent ? `${percent}%` : 0 }} />
          <Typography variant='c1'>{percent && `${percent}%`}</Typography>
        </div>
      </div>
    </div>
  );
};

export default Progress;
