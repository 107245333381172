import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import Input from 'common/Input';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import { InputWithText } from 'pages/CompanySettings/pagesComponents/Policies/shared/InputWithText';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { getFuelTypes } from 'Api/Equipment';
import { useSelector } from 'react-redux';
import style from './EquipmentInfo.module.css';

const fuelCapacityMapper = {
  1: '150',
  3: '26',
  4: '24',
  5: '22',
  9: '30',
  10: '15',
  11: '30',
};

const EquipmentInfo = ({
  nextStep,
  getVinData,
  equipmentInfo,
  Cancel,
  prevStep,
  equipment,
  dimensionData,
  lengthByVehicle,
  selectedVehicleLenghtData,
}) => {
  const { weightUnit } = useSelector((state) => state.root);
  const [GVWRUnit, setGVWRUnit] = useState(weightUnit.toUpperCase());
  const {
    equipment_id,
    make,
    model,
    gross_vehicle_weight_rating,
    cargo_maximum_capacity_weight,
    lift_gate,
    start_odometer,
    cargo_capacity_measuring_unit,
    year,
    width,
    height,
    color,
    model_id,
    tire_size,
    odometer_unit,
    gross_vehicle_measuring_unit,
    axles,
    fuel_type_id,
    truck_type,
    fuel_gallon_capacity,
    GVWR,
    transmission_type,
    transmission_speed,
  } = equipmentInfo;
  const [colorValue, setColorValue] = useState(color || '');
  const [GVWRUnitVal, setGVWRUnitVal] = useState();
  const [gvwrPredefine, setGvwrPredefine] = useState(getVinData?.GVWR);
  const [gvwrAutofillClass, setGVWRAutofillClass] = useState(GVWR);
  const [fuelTypes, setFuelTypes] = useState([]);

  const validationSchema1 = Yup.object().shape({
    equipment_id: Yup.string().required('Equipment ID is required!').nullable(),
    fuel_type_id: Yup.number().required('Fuel Type is required!').nullable(),
    gross_vehicle_weight_rating: Yup.string().required('Gross Vehicle Weight is required!').nullable(),
  });

  const validationSchema2 = Yup.object().shape({
    equipment_id: Yup.string().required('Equipment ID is required!').nullable(),
    fuel_type_id: Yup.number().required('Required').nullable(),
    width: Yup.string().required('Width is required!').nullable(),
    height: Yup.string().required('Height is required!').nullable(),
    gross_vehicle_weight_rating: Yup.string().required('Gross Vehicle Weight is required!').nullable(),
  });

  useEffect(() => {
    formik.validateForm();
    if (gvwrPredefine && gvwrPredefine.search('Class') > -1) {
      const CopyString = gvwrPredefine.split(':');
      const GVWRString = CopyString[1];
      const CopyGVWRString = GVWRString.split(' ');

      setGVWRUnitVal(CopyGVWRString[1]);
      setGvwrPredefine(CopyGVWRString[1]);
      formik.setFieldValue('gross_vehicle_weight_rating', CopyGVWRString[1]);
    }
  }, []);

  const lengthStyle = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      minWidth: 200,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      marginTop: '8px',
    }),
    menu: (base) => ({
      ...base,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,
    }),
  };

  useEffect(() => {
    if (!gross_vehicle_weight_rating) {
      if (GVWRUnit === 'KGS' && GVWRUnitVal) {
        const v = Number(GVWRUnitVal.split(',').join('')) / 2.205;
        const Weight = Number(v).toFixed(2).toLocaleString('en-US');
        setGVWRUnitVal(Weight);
        formik.setFieldValue('gross_vehicle_weight_rating', Weight);
      } else if (GVWRUnit === 'LBS' && GVWRUnitVal) {
        const v = Number(GVWRUnitVal.split(',').join('')) * 2.205;
        const Weight = parseInt(v, 10).toLocaleString('en-US');
        setGVWRUnitVal(Weight);
        formik.setFieldValue('gross_vehicle_weight_rating', Weight);
      }
    }
  }, [GVWRUnit]);

  useEffect(() => {
    if (GVWRUnitVal) {
      if (GVWRUnit === 'LBS') {
        if (Number(GVWRUnitVal.split(',').join('')) < 6000) {
          setGVWRAutofillClass('Class 1');
        } else if (Number(GVWRUnitVal.split(',').join('')) > 6001 && Number(GVWRUnitVal.split(',').join('')) <= 10000) {
          setGVWRAutofillClass('Class 2');
        } else if (
          Number(GVWRUnitVal.split(',').join('')) > 10001 &&
          Number(GVWRUnitVal.split(',').join('')) <= 14000
        ) {
          setGVWRAutofillClass('Class 3');
        } else if (
          Number(GVWRUnitVal.split(',').join('')) > 14001 &&
          Number(GVWRUnitVal.split(',').join('')) <= 16000
        ) {
          setGVWRAutofillClass('Class 4');
        } else if (
          Number(GVWRUnitVal.split(',').join('')) > 16001 &&
          Number(GVWRUnitVal.split(',').join('')) <= 19500
        ) {
          setGVWRAutofillClass('Class 5');
        } else if (
          Number(GVWRUnitVal.split(',').join('')) > 19501 &&
          Number(GVWRUnitVal.split(',').join('')) <= 26000
        ) {
          setGVWRAutofillClass('Class 6');
        } else if (
          Number(GVWRUnitVal.split(',').join('')) > 26001 &&
          Number(GVWRUnitVal.split(',').join('')) <= 33000
        ) {
          setGVWRAutofillClass('Class 7');
        } else if (Number(GVWRUnitVal.split(',').join('')) >= 33001) {
          setGVWRAutofillClass('Class 8');
        }
      }
      if (GVWRUnit === 'KGS') {
        if (Number(GVWRUnitVal.split(',').join('')) <= 2721.54) {
          setGVWRAutofillClass('Class 1');
        } else if (Number(GVWRUnitVal.split(',').join('')) > 2722 && Number(GVWRUnitVal.split(',').join('')) <= 4535) {
          setGVWRAutofillClass('Class 2');
        } else if (Number(GVWRUnitVal.split(',').join('')) > 4536 && Number(GVWRUnitVal.split(',').join('')) <= 6350) {
          setGVWRAutofillClass('Class 3');
        } else if (Number(GVWRUnitVal.split(',').join('')) > 6351 && Number(GVWRUnitVal.split(',').join('')) <= 7257) {
          setGVWRAutofillClass('Class 4');
        } else if (Number(GVWRUnitVal.split(',').join('')) > 7258 && Number(GVWRUnitVal.split(',').join('')) <= 8845) {
          setGVWRAutofillClass('Class 5');
        } else if (Number(GVWRUnitVal.split(',').join('')) > 8846 && Number(GVWRUnitVal.split(',').join('')) <= 11794) {
          setGVWRAutofillClass('Class 6');
        } else if (
          Number(GVWRUnitVal.split(',').join('')) > 11795 &&
          Number(GVWRUnitVal.split(',').join('')) <= 14968
        ) {
          setGVWRAutofillClass('Class 7');
        } else if (Number(GVWRUnitVal.split(',').join('')) >= 14969) {
          setGVWRAutofillClass('Class 8');
        }
      }
    }
  }, [GVWRUnitVal]);

  function getTruckType() {
    return equipment?.equipment_type_id === 1 ? truck_type || 2 : null;
  }

  const formik = useFormik({
    initialValues: {
      equipment_id: equipment_id || '',
      model_id: model_id || getVinData?.ModelID,
      make: make || getVinData?.Make,
      lift_gate: !!lift_gate,
      year: year || getVinData?.ModelYear,
      model: model || getVinData?.Model,
      start_odometer: start_odometer || '',
      tire_size: tire_size || '',
      color: colorValue,
      gross_vehicle_weight_rating: gross_vehicle_weight_rating || GVWRUnitVal,
      gross_vehicle_measuring_unit: gross_vehicle_measuring_unit || weightUnit.toUpperCase(),
      axles,
      fuel_type_id: fuel_type_id || getVinData?.FuelTypePrimary || '1',
      fuel_gallon_capacity: fuel_gallon_capacity || fuelCapacityMapper[equipment?.equipment_type_id] || '',
      cargo_maximum_capacity_weight: cargo_maximum_capacity_weight || dimensionData?.cargo_payload_weight || '',
      width: width || dimensionData?.width || '',
      height: height || dimensionData?.height || '',
      odometer_unit: odometer_unit || 'Miles',
      cargo_capacity_measuring_unit: cargo_capacity_measuring_unit || weightUnit.toUpperCase(),
      truck_type: getTruckType(),
      transmission_type: transmission_type || null,
      transmission_speed: transmission_speed || '',
    },
    validationSchema:
      equipment?.equipment_type_id === 3 ||
      equipment?.equipment_type_id === 11 ||
      equipment?.equipment_type_id === 9 ||
      equipment?.equipment_type_id === 4 ||
      equipment?.equipment_type_id === 8 ||
      equipment?.equipment_type_id === 5
        ? validationSchema2
        : validationSchema1,
    onSubmit: (values) => {
      if (grossVehicleWeightRating) {
        values = {
          ...values,
          GVWR: gvwrAutofillClass,
          gross_vehicle_weight_rating: GVWRUnitVal,
        };
        nextStep({ values });
      }
    },
  });

  const getFuelTypeOptions = async () => {
    try {
      const { data } = await getFuelTypes();
      setFuelTypes(data);
      if (!fuel_type_id && getVinData?.FuelTypePrimary) {
        const fuelType = data.find((i) => i.type === getVinData.FuelTypePrimary);
        formik.setFieldValue('fuel_type_id', fuelType ? fuelType.id?.toString() : '1');
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getFuelTypeOptions();
  }, []);

  const [grossVehicleWeightRating, setGrossVehicleWeightRating] = useState('');

  const Validate = (id) => {
    setGrossVehicleWeightRating(id || GVWRUnitVal || gross_vehicle_weight_rating);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body className={style['equipment-info-modal-body']}>
        <div className='d-flex'>
          <div>
            <p className={style['equipment-info-text']}>Equipment ID *</p>
            <div className={style['equipment-info-input-wrapper']} style={{ borderRadius: '6px' }}>
              <input
                type='text'
                className={style['equipment-info-input']}
                name='equipment_id'
                value={formik.values.equipment_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.errors.equipment_id && formik.touched.equipment_id ? (
              <div
                className={style['add-equipment-validation']}
                style={{
                  color: '#D12953',
                  fontSize: '12px',
                }}
              >
                {formik.errors.equipment_id}
              </div>
            ) : null}
          </div>
          <div className='ms-3'>
            <p className={style['equipment-info-text']}>Make</p>
            <div className='d-flex'>
              <span>
                <input
                  type='text'
                  className={style.manufacturer}
                  placeholder='Make'
                  name='make'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.make}
                />
                {formik.errors.make && formik.touched.make ? (
                  <div
                    className={style['add-equipment-validation']}
                    style={{
                      color: '#D12953',
                      fontSize: '12px',
                    }}
                  >
                    {formik.errors.make}
                  </div>
                ) : null}
              </span>

              <span>
                <input
                  type='text'
                  className={style.model}
                  placeholder='Model'
                  name='model'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values?.model}
                />
                {formik.errors?.model && formik.touched?.model ? (
                  <div
                    className={style['add-equipment-validation']}
                    style={{
                      color: '#D12953',
                      fontSize: '12px',
                    }}
                  >
                    {formik.errors?.model}
                  </div>
                ) : null}
              </span>
              <span>
                <input
                  type='text'
                  className={style.year}
                  placeholder='Year'
                  name='year'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.year}
                />
                {formik.errors.year && formik.touched.year ? (
                  <div
                    className={style['add-equipment-validation']}
                    style={{
                      color: '#D12953',
                      fontSize: '12px',
                    }}
                  >
                    {formik.errors.year}
                  </div>
                ) : null}
              </span>
              {equipment?.equipment_type_id !== 1 ? (
                <div className='ms-3 d-flex align-items-center '>
                  <input
                    type='checkbox'
                    id='Lift Gate'
                    name='lift_gate'
                    defaultChecked={formik.values.lift_gate}
                    onChange={(e) => formik.setFieldValue('lift_gate', e.target.checked)}
                    className={style['lift-gate']}
                  />
                  <label
                    htmlFor='Lift Gate'
                    style={{ margin: '0px 10px' }}
                    className={`$style["equipment-info-text"]} ${'ms-1'}`}
                  >
                    Lift Gate
                  </label>
                </div>
              ) : (
                ''
              )}
            </div>
            {/* {formik.errors.equipment_id && formik.touched.equipment_id ? (
                <div
                  className={style["add-equipment-validation"]}
                  style={{
                    color: "#D12953",
                    fontSize: "12px",
                    marginTop: "12px",
                  }}
                >
                  {formik.errors.equipment_id}
                </div>
              ) : null} */}
          </div>
        </div>
        <div className='d-flex mt-3 '>
          <div>
            <p className={style['equipment-info-text']}>Current Odometer</p>
            <div className={style['gross-select']}>
              <input
                type='text'
                className={style['equipment-info-input-field']}
                name='start_odometer'
                onBlur={formik.handleBlur}
                value={formik.values.start_odometer}
                onChange={formik.handleChange}
                style={{ width: '260px', borderRadius: '6px 0px 0px 6px' }}
              />
              <select
                className={style['weight-select']}
                style={{
                  // paddingRight: "25px",
                  paddingLeft: '10px',
                  margin: 0,
                  width: '80px',
                }}
                name='odometer_unit'
                defaultValue={formik.values.odometer_unit}
                onChange={(e) => {
                  setGVWRUnit(e.target.value);
                  formik.setFieldValue('odometer_unit', e.target.value);
                }}
              >
                <option className={style['select-kgs']} value='Miles'>
                  Miles
                </option>
                <option className={style['select-kgs']} value='KMS'>
                  KMS
                </option>
              </select>
            </div>
            {formik.errors.start_odometer && formik.touched.start_odometer ? (
              <div
                className={style['add-equipment-validation']}
                style={{
                  color: '#D12953',
                  fontSize: '12px',
                }}
              >
                {formik.errors.start_odometer}
              </div>
            ) : null}
          </div>

          <div className='ms-3'>
            <span>
              <p className={style['equipment-info-text']}>Tire Size</p>
              <input
                type='text'
                className={style['equipment-info-input-field']}
                name='tire_size'
                value={formik.values.tire_size}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </span>
            {formik.errors.tire_size && formik.touched.tire_size ? (
              <div
                className={style['add-equipment-validation']}
                style={{
                  color: '#D12953',
                  fontSize: '12px',
                }}
              >
                {formik.errors.tire_size}
              </div>
            ) : null}
          </div>
          <div className='ms-3'>
            <span>
              <p className={style['equipment-info-text']}>Color </p>
              <input
                type='text'
                name='color'
                value={colorValue}
                onChange={(e) => {
                  setColorValue(e.target.value);
                  formik.setFieldValue('color', e.target.value);
                }}
                className={style['equipment-info-input-color-field']}
              />
              {formik.errors.color && formik.touched.color ? (
                <div
                  className={style['add-equipment-validation']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                  }}
                >
                  {formik.errors.color}
                </div>
              ) : null}
            </span>
          </div>
        </div>
        <div className='d-flex mt-3 '>
          <div>
            <p className={style['equipment-info-text']}>Gross Vehicle Weight *</p>
            <div className={style['gross-select']}>
              <input
                type='text'
                className={style['equipment-info-input-field']}
                name='gross_vehicle_weight_rating'
                value={formik.values.gross_vehicle_weight_rating}
                onChange={(e) => {
                  setGVWRUnitVal(e.target.value);
                  Validate(e.target.value);
                  formik.setFieldValue('gross_vehicle_weight_rating', e.target.value);
                }}
                onBlur={formik.handleBlur}
                style={{ width: '260px', borderRadius: '6px 0px 0px 6px' }}
              />
              <select
                className={style['weight-select']}
                style={{
                  paddingRight: '25px',
                  paddingLeft: '10px',
                  marginRight: '16px',
                  marginTop: 0,
                }}
                name='gross_vehicle_measuring_unit'
                defaultValue={formik.values.gross_vehicle_measuring_unit}
                onChange={(e) => {
                  setGVWRUnit(e.target.value);
                  formik.setFieldValue('gross_vehicle_measuring_unit', e.target.value);
                }}
              >
                <option className={style['select-kgs']} value='LBS'>
                  LBS
                </option>
                <option value='KGS'>KGS</option>
              </select>
            </div>
            {formik.errors.gross_vehicle_weight_rating && formik.touched.gross_vehicle_weight_rating ? (
              <div
                className={style['add-equipment-validation']}
                style={{
                  color: '#D12953',
                  fontSize: '12px',
                }}
              >
                {formik.errors.gross_vehicle_weight_rating}
              </div>
            ) : null}
          </div>
          <div>
            <p className={style['equipment-info-text']}>GVWR Class*</p>
            <input
              className={style['equipment-info-input-field']}
              style={{
                color: '#464F60',
                fontSize: '14px',
                fontWeight: '500',
                padding: '0px 10px',
              }}
              name='GVWR'
              type='text'
              value={gvwrAutofillClass}
            />
          </div>
          {formik.errors.GVWR && formik.touched.GVWR ? (
            <div
              className={style['add-equipment-validation']}
              style={{
                color: '#D12953',
                fontSize: '12px',
              }}
            >
              {formik.errors.GVWR}
            </div>
          ) : null}
          <div className='ms-3'>
            <span>
              <p className={style['equipment-info-text']}>Axles</p>
              <input
                name='axles'
                value={formik.values.axles}
                onChange={formik.handleChange}
                type='text'
                className={style['equipment-info-input-field']}
              />
              {formik.errors.axles && formik.touched.axles ? (
                <div
                  className={style['add-equipment-validation']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                  }}
                >
                  {formik.errors.axles}
                </div>
              ) : null}
            </span>
          </div>
        </div>

        <div className='d-flex'>
          <div className='d-flex align-items-center'>
            <div className='mt-4'>
              <p className={style['equipment-info-text']}>Fuel Type *</p>
              <div className={style['select-equipment-class']}>
                <select
                  className={style['equipment-headings']}
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    paddingLeft: '8px',
                    background: '#fff',
                  }}
                  placeholder='Select...'
                  name='fuel_type_id'
                  value={formik.values.fuel_type_id}
                  onChange={(e) => formik.setFieldValue('fuel_type_id', e.target.value)}
                >
                  {fuelTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.type}
                    </option>
                  ))}
                </select>
              </div>
              {formik.errors.fuel_type_id && formik.touched.fuel_type_id ? (
                <div
                  className={style['add-equipment-validation']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                  }}
                >
                  {formik.errors.fuel_type_id}
                </div>
              ) : null}
            </div>
            <div className='ms-4' style={{ marginTop: '30px' }}>
              <InputLabel>Fuel Capacity</InputLabel>
              <InputWithText
                text='gallons'
                type='number'
                onKeyDown={(e) => {
                  blockNonPositiveNumbers(e);
                  blockNotNumberChars(e);
                }}
                name='fuel_gallon_capacity'
                placeholder='0'
                value={formik.values.fuel_gallon_capacity}
                onChange={(e) => {
                  if (e.target.value?.length > 3) {
                    return;
                  }
                  formik.setFieldValue('fuel_gallon_capacity', e.target.value);
                }}
              />
            </div>
            {equipment.equipment_type_id === 1 && (
              <div className='ms-4' style={{ marginTop: '30px' }}>
                <InputLabel>Truck Type</InputLabel>
                <div className='d-flex gap-3 mt-2 nowrap'>
                  <Radio
                    id='day-cab'
                    name='truck_type'
                    label='Day-Cab'
                    value={1}
                    checked={formik.values.truck_type === 1}
                    onChange={() => formik.setFieldValue('truck_type', 1)}
                  />
                  <Radio
                    id='sleeper'
                    name='truck_type'
                    label='Sleeper'
                    value={2}
                    checked={formik.values.truck_type === 2}
                    onChange={() => formik.setFieldValue('truck_type', 2)}
                  />
                </div>
              </div>
            )}
            <div className='ms-4' style={{ marginTop: '30px' }}>
              <InputLabel>Transmission Type</InputLabel>
              <div className='d-flex gap-3 align-items-center mt-2 nowrap' style={{ height: 33 }}>
                <Radio
                  id='automatic'
                  name='transmission_type'
                  label='Automatic'
                  value={1}
                  checked={formik.values.transmission_type === 1}
                  onChange={() => formik.setFieldValue('transmission_type', 1)}
                />
                <Radio
                  id='manual'
                  name='transmission_type'
                  label='Manual'
                  value={2}
                  checked={formik.values.transmission_type === 2}
                  onChange={() => formik.setFieldValue('transmission_type', 2)}
                />
                {formik.values.transmission_type === 2 && (
                  <Input
                    type='number'
                    name='transmission_speed'
                    placeholder='Speed'
                    value={formik.values.transmission_speed}
                    onChange={(e) => formik.setFieldValue('transmission_speed', e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                )}
              </div>
            </div>
          </div>
          {equipment.equipment_type_id === 3 ||
          equipment.equipment_type_id === 11 ||
          equipment.equipment_type_id === 9 ||
          equipment.equipment_type_id === 4 ||
          equipment.equipment_type_id === 8 ||
          equipment.equipment_type_id === 5 ? (
            <div className='ms-3 mt-4'>
              <p className={style['equipment-info-text']}> Cargo Payload </p>
              <div className={style['cargo-weight-select']}>
                <input
                  type='text'
                  className={style['equipment-info-input-field']}
                  name='cargo_maximum_capacity_weight'
                  value={formik.values.cargo_maximum_capacity_weight}
                  onChange={(e) => {
                    formik.setFieldValue('cargo_maximum_capacity_weight', e.target.value);
                  }}
                  style={{ width: '260px', borderRadius: '6px 0px 0px 6px' }}
                />
                <select
                  className={style['weight-select']}
                  style={{
                    paddingRight: '25px',
                    paddingLeft: '10px',
                    marginRight: '16px',
                    marginTop: 0,
                  }}
                  name='cargo_capacity_measuring_unit'
                  defaultValue={formik.values.cargo_capacity_measuring_unit}
                  onChange={(e) => {
                    formik.setFieldValue('cargo_capacity_measuring_unit', e.target.value);
                  }}
                >
                  {/* <option className={style["select-kgs"]} value="KGS">KGSds</option> */}

                  <option className={style['select-kgs']} value='LBS'>
                    LBS
                  </option>
                  <option className={style['select-kgs']} value='LBS'>
                    KGS
                  </option>
                </select>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        {equipment.equipment_type_id === 3 ||
        equipment.equipment_type_id === 11 ||
        equipment.equipment_type_id === 9 ||
        equipment.equipment_type_id === 4 ||
        equipment.equipment_type_id === 8 ||
        equipment.equipment_type_id === 5 ? (
          <div className='d-flex mt-4'>
            <div className=''>
              <span>
                {formik.errors.length && formik.touched.length ? (
                  <div
                    className={style['add-equipment-validation']}
                    style={{
                      color: '#D12953',
                      fontSize: '12px',
                    }}
                  >
                    {formik.errors.length}
                  </div>
                ) : null}
              </span>
            </div>
            <div className=''>
              <p className={style['equipment-info-text']}>Width *</p>
              <div className={style['trailer-width-selector']}>
                <input
                  type='text'
                  value={formik.values.width}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='width'
                  placeholder='0'
                  className='px-2'
                />
                <span className={style['equipment-info-text']}>Inches</span>
              </div>
              {formik.errors.width && formik.touched.width ? (
                <div
                  className={style['add-equipment-validation']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                  }}
                >
                  {formik.errors.width}
                </div>
              ) : null}
            </div>

            <div className='ms-4'>
              <p className={style['equipment-info-text']}>Height *</p>
              <div className={style['trailer-width-selector']}>
                <input
                  type='text'
                  placeholder='0'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.height}
                  name='height'
                  className='px-2'
                />
                <span className={style['equipment-info-text']}>Inches</span>
              </div>
              {formik.errors.height && formik.touched.height ? (
                <div
                  className={style['add-equipment-validation']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                  }}
                >
                  {formik.errors.height}
                </div>
              ) : null}
            </div>

            <div className='ms-4'>
              <div className={style['equipment-info-text']}>
                <span className='mb-3'>Length</span>
                <Select
                  options={lengthByVehicle}
                  type='checkbox'
                  name='length'
                  isDisabled='true'
                  defaultValue={selectedVehicleLenghtData}
                  onBlur={formik.handleBlur}
                  styles={lengthStyle}
                />
              </div>
              <span>
                {formik.errors.length && formik.touched.length ? (
                  <div
                    className={style['add-equipment-validation']}
                    style={{
                      color: '#D12953',
                      fontSize: '12px',
                    }}
                  >
                    {formik.errors.length}
                  </div>
                ) : null}
              </span>
            </div>
          </div>
        ) : (
          ''
        )}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between', padding: '5px 20px' }}>
        <button className={style['type-cancel-button']} onClick={Cancel}>
          Cancel
        </button>
        <div className='d-flex'>
          <button className={style['type-cancel-button']} onClick={prevStep}>
            Back
          </button>
          <button
            className={style['add-equipment-next-step']}
            type='submit'
            onClick={() => Validate()}
            disabled={!formik.isValid}
          >
            Next Step
          </button>
        </div>
      </Modal.Footer>
    </Form>
  );
};

export default EquipmentInfo;
