import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Modal from 'common/Modal';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';
import { updateBillStatus } from 'Api/Payables';
import { getErrorMessage } from 'utils/error';
import { getAccountsList } from 'Api/Accounts';
import { validationSchema } from './validationSchema';
import { getInitialValues, paymentMethods } from './MarkPaid.data';
import { STable, STableRow } from '../../Payables.styles';

const MarkPaid = ({ open, onClose, onSuccess, bills }) => {
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        billing_id: [],
        payment_date: [],
        amount_paid: [],
        payment_method: [],
        pay_reference: [],
        account_id: [],
        status: [],
      };
      values.forEach((bill) => {
        body.billing_id.push(bill.id);
        body.payment_date.push(moment(bill.payment_date).format('YYYY-MM-DD'));
        body.amount_paid.push(bill.amount_paid);
        body.payment_method.push(bill.payment_method?.value);
        body.pay_reference.push(bill.pay_reference);
        body.account_id.push(bill.account?.id || null);
        body.status.push('1');
      });
      const { data } = await updateBillStatus(body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: getInitialValues(bills),
    onSubmit,
    validationSchema,
  });

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => [ACCOUNT_TYPE.BANK, ACCOUNT_TYPE.CREDIT_CARD].includes(i.account_type.id)));
    } catch (e) {
      // Do nothing
    }
  };

  const totalDue = useMemo(() => {
    return bills.reduce((acc, cur) => acc + Number(cur.open_balance) || 0, 0);
  }, [bills]);
  const totalPaid = useMemo(() => {
    return values.reduce((acc, cur) => acc + Number(cur.amount_paid) || 0, 0);
  }, [values]);

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Mark Paid'
      $bgColor={palette.white}
      $width='2000px'
      $maxWidth='95vw'
      $minWidth='800px'
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Mark Paid',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div>
        <STable>
          <thead>
            <tr className='nowrap'>
              <th>RECIPIENT</th>
              <th>DUE DATE</th>
              <th>AGING</th>
              <th>PAID DATE</th>
              <th>REFERENCE ID</th>
              <th>PAYMENT REFERENCE</th>
              <th>METHOD</th>
              <th>FUNDING ACCOUNT</th>
              <th>OPEN BALANCE</th>
              <th>AMOUNT PAID</th>
            </tr>
          </thead>
          <tbody>
            {values.map((bill, index) => (
              <STableRow key={bill.id}>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                    {bill.vendor?.name || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {formatDate(bill.bill_due_date)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {bill.aging}
                  </Typography>
                </td>
                <td>
                  <DatePicker
                    size='small'
                    name='payment_date'
                    value={bill.payment_date}
                    onChange={(val) => handleChange(`${index}.payment_date`, val)}
                    error={touchedErrors[index]?.payment_date}
                  />
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {bill.reference_id}
                  </Typography>
                </td>
                <td>
                  <Input
                    width='200px'
                    size='small'
                    name='pay_reference'
                    placeholder='ABC'
                    value={bill.pay_reference}
                    onChange={(e) => handleChange(`${index}.pay_reference`, e.target.value)}
                    error={touchedErrors[index]?.pay_reference}
                  />
                </td>
                <td>
                  <Autocomplete
                    width='180px'
                    size='small'
                    name='payment_method'
                    options={paymentMethods}
                    value={bill.payment_method}
                    onChange={(e, val) => handleChange(`${index}.payment_method`, val)}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    error={touchedErrors[index]?.payment_method}
                  />
                </td>
                <td>
                  <Autocomplete
                    size='small'
                    width='180px'
                    name='account'
                    labelKey='account_name'
                    options={accounts}
                    value={bill.account}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.account_name}
                      </li>
                    )}
                    onChange={(e, val) => handleChange(`${index}.account`, val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    error={touchedErrors[index]?.account}
                  />
                </td>
                <td>
                  <Typography variant='s3' style={{ color: palette.gray700 }}>
                    {currency}
                    {formatNumber(bill.open_balance)}
                  </Typography>
                </td>
                <td>
                  <InputWithIcon
                    width='120px'
                    size='small'
                    type='number'
                    name='amount_paid'
                    placeholder='0.00'
                    value={bill.amount_paid}
                    onChange={(e) => handleChange(`${index}.amount_paid`, e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                    error={touchedErrors[index]?.amount_paid}
                  />
                </td>
              </STableRow>
            ))}
            <tr className='no-border'>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  Total
                </Typography>
              </td>
              <td>
                <Typography variant='button2' style={{ color: palette.gray900 }}>
                  {currency}
                  {formatNumber(totalDue)}
                </Typography>
              </td>
              <td>
                <Typography variant='button2' style={{ color: palette.gray900 }}>
                  {currency}
                  {formatNumber(totalPaid)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </STable>
      </div>
    </Modal>
  );
};

export default MarkPaid;
