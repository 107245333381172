import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as PencileIcon } from 'assets/icons/editPensle.svg';
import { onChangeActiveTab as onChangeRecurringActiveTab } from 'store/reducers/planner.reducer';
import { formatAmount } from 'utils/helpers';
import { CURRENCY, palette, statusColor } from 'utils/constants';

import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import { RECURRING_TABS } from 'components/TablePlaner/constats';
import classes from 'components/TableShipments/detailsRow/details.module.css';
import UpdateGroup from 'components/TableShipments/components/UpdateGroup';
import UpdateReference from 'components/TableShipments/components/UpdateReference';
import LaneName from 'components/TablePlaner/helpers/RecurrningDetails/helpers/LaneName';
import LaneStatus from 'components/TablePlaner/helpers/RecurrningDetails/helpers/LaneStatus';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateRecurringLaneBilling } from 'Api/Shipment';
import hexRgb from 'hex-rgb';
import useDateFormat from 'hooks/useDateFormat';
import { SWrapper } from './DetailsHeader.styles';

const mapper = {
  1: { type: 'Arrived', name: 'Pick Up' },
  2: { type: 'Out of Service', name: 'Delivery' },
  3: { type: 'On Shipment', name: 'Waypoint' },
};

const DetailsHeader = ({ data, onUpdateSuccess, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const { activeTabRecurring } = useSelector((state) => state?.planner);
  const [updateGroupsOpen, setUpdateGroupsOpen] = useState(false);
  const [updateReferenceOpen, setUpdateReferenceOpen] = useState(false);

  const { shipment_billing, shipment_type, stops_count } = data || {};
  const currencyData = Object.values(CURRENCY).find((item) => item.id === Number(shipment_billing?.[0]?.currency_id));

  const onChangeTab = (tab) => {
    if (+activeTabRecurring.id !== +tab.id) {
      dispatch(onChangeRecurringActiveTab(tab));
    }
  };

  const updateReference = async (values) => {
    try {
      const promises = values.map((billing) => {
        const body = {
          reference_id: billing.reference_id,
          customer_id: billing.billing_customer?.id,
          currency_id: billing.currency_id,
          contact_user_id: billing.contact_user?.id,
          group_id: billing.groups?.id,
        };
        return updateRecurringLaneBilling(billing.id, body);
      });

      await Promise.all(promises);
      showToaster({ type: 'success', message: 'Success' });
      onUpdateSuccess();
      setUpdateReferenceOpen(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const updateGroups = async (values) => {
    try {
      const promises = values.map((billing) => {
        const body = {
          reference_id: billing.reference_id,
          customer_id: billing.billing_customer?.id,
          currency_id: billing.currency_id,
          contact_user_id: billing.contact_user?.id,
          group_id: billing.groups?.id,
        };
        return updateRecurringLaneBilling(billing.id, body);
      });

      await Promise.all(promises);
      showToaster({ type: 'success', message: 'Success' });
      onUpdateSuccess();
      setUpdateGroupsOpen(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const activeTab = params.get('activeTab');
    if (activeTab) {
      dispatch(onChangeRecurringActiveTab(RECURRING_TABS.find((item) => item.id === Number(activeTab))));
    }
  }, [dispatch]);

  return (
    <SWrapper>
      <div onClick={() => navigate(-1)} className='pointer mb-2'>
        <ArrowLeft width={10} height={10} className='me-2' />
        <Typography variant='c1' style={{ color: palette.indigo500 }}>
          Go Back
        </Typography>
      </div>
      <div className='title-wrapper'>
        <div className='d-flex flex-column gap-1'>
          <LaneName data={data} onUpdateSuccess={onUpdateSuccess} />
          <div>
            <LaneStatus data={data} onSuccess={onUpdateSuccess} />
          </div>
        </div>
      </div>
      <div className={classes.blockWrapperTop} style={{ marginTop: 12, borderBottom: `1px solid ${palette.gray50}` }}>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            Lane ID
          </Typography>
          <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
            {data.id}
          </Typography>
        </div>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            Type
          </Typography>
          <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
            {data.shipment_type ? data.shipment_type.toUpperCase() : 'TL'}
          </Typography>
        </div>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            Shipments Created
          </Typography>
          <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
            {data.shipment_lane?.shipments_created} of {data.shipment_lane?.shipments_count}
          </Typography>
        </div>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            End Date
          </Typography>
          <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
            {data.shipment_lane?.end_date ? formatDate(data.shipment_lane.end_date) : '-'}
          </Typography>
        </div>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            Customer
          </Typography>
          {shipment_type !== 'ltl' || (shipment_type === 'ltl' && shipment_billing?.length === 1) ? (
            <Typography
              className={classes?.customerId_text_single}
              variant='overLine2'
              onClick={() => navigate(`/customer-profile/${shipment_billing?.[0]?.billing_customer?.id}`)}
            >
              {shipment_billing?.[0]?.billing_customer?.company_name}
              {!!shipment_billing?.[0]?.billing_customer?.customer_id &&
                ` - ${shipment_billing?.[0]?.billing_customer?.customer_id}`}
              &nbsp;
            </Typography>
          ) : (
            <OverLayTriggerHoverWrapper
              overlay={shipment_billing?.map((el) => {
                return (
                  <Typography
                    as='p'
                    key={el?.id}
                    variant='s2'
                    style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                    onClick={() => navigate(`/customer-profile/${el?.billing_customer?.id}`)}
                  >
                    {el?.billing_customer?.company_name}
                    {el?.billing_customer?.customer_id ? ` - ${el.billing_customer.customer_id} ` : ''}&nbsp;
                  </Typography>
                );
              })}
            >
              <div className={classes.multi}>
                <Typography as='div' variant='overLine2'>
                  Multi-Customer
                </Typography>
              </div>
            </OverLayTriggerHoverWrapper>
          )}
        </div>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            Stops
          </Typography>
          <div className={classes.blockItemStopsWrapper}>
            {stops_count && (
              <div className={classes.stopCountWrapper}>
                <Typography variant='overLine'>{stops_count}</Typography>
              </div>
            )}
            <div className={classes.stopsContainer}>
              {data?.shipment_stops?.map((el) => {
                const stopPointType = Number(el?.stop_point_type);
                const type = mapper?.[stopPointType]?.type;
                const name = mapper?.[stopPointType]?.name;
                return (
                  <div
                    key={el?.id}
                    className={classes.stopCountWrapper}
                    style={{ backgroundColor: statusColor[type].bgColor }}
                  >
                    <Typography style={{ color: statusColor[type].color }} variant='overLine'>
                      {name.toUpperCase()}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            Total Miles
          </Typography>
          <Typography variant='overLine2' style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
            {Math.round(Number(data?.loaded_miles) + Number(data?.empty_miles))}
          </Typography>
        </div>
        <div className={classes.blockItem}>
          <Typography variant='overLine2' style={{ fontWeight: 300 }}>
            Billing Charges
          </Typography>
          {Array.isArray(shipment_billing) && shipment_billing?.length > 0 && (
            <Typography variant='overLine2'>
              {currencyData?.symbol || '$'}
              {formatAmount(
                shipment_billing.reduce((acc, el) => +acc + Number(el?.total_amount), 0),
                2
              )}
            </Typography>
          )}
        </div>
        <div className={classes.blockItem}>
          <div className='d-flex justify-content-between align-items-end w-100'>
            <Typography variant='overLine2' style={{ fontWeight: 300 }}>
              Reference
            </Typography>
            <PencileIcon width={14} height={14} className='pointer' onClick={() => setUpdateReferenceOpen(true)} />
          </div>
          {shipment_type !== 'ltl' || (shipment_type === 'ltl' && shipment_billing?.length === 1) ? (
            <Typography variant='overLine2'>{shipment_billing?.[0]?.reference_id}</Typography>
          ) : (
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='button-tooltip-2' className={classes.toolTip}>
                  <div className={classes.multiReferenceToolTip}>
                    {shipment_billing?.map((el, i) => (
                      <span key={el?.id || i}>{shipment_billing?.[i]?.reference_id}</span>
                    ))}
                  </div>
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div ref={ref} className={classes.multi}>
                  <Typography {...triggerHandler} variant='overLine2'>
                    Multi-Ref ID
                  </Typography>
                </div>
              )}
            </OverlayTrigger>
          )}
        </div>
        <div className={classes.blockItem}>
          <div className='d-flex justify-content-between align-items-end w-100'>
            <Typography variant='overLine2' style={{ fontWeight: 300 }}>
              Tags
            </Typography>
            <PencileIcon width={14} height={14} className='pointer' onClick={() => setUpdateGroupsOpen(true)} />
          </div>
          <div className='d-flex gap-2'>
            {shipment_billing
              .filter((i) => !!i.groups)
              .map((billing) => {
                const colorCode = hexRgb(billing.groups?.color);
                const textColor =
                  colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                    ? palette.white
                    : palette.gray900;
                return (
                  <Typography
                    variant='overLine2'
                    key={billing.id}
                    style={{
                      backgroundColor: billing.groups?.color,
                      borderRadius: '10px',
                      padding: '0 3px',
                      fontSize: 13,
                      color: textColor,
                    }}
                  >
                    {billing?.groups?.short_name || '-'}
                  </Typography>
                );
              })}
          </div>
        </div>
      </div>
      {children}
      <div className={classes.tabsContainer}>
        {RECURRING_TABS.filter((el) => el.id !== 8).map((tab) => {
          const active = Number(activeTabRecurring?.id) === Number(tab.id);
          return (
            <div key={tab.id} className={classNames(classes.tabsWrapper, { [classes.hasAHover]: !active })}>
              <CustomButton
                hover={false}
                title={tab.title}
                className={classes.tabs}
                onClick={() => onChangeTab(tab)}
                styleTitle={{ padding: '2px 10px' }}
                type={active ? 'primary' : 'secondary'}
              />
            </div>
          );
        })}
      </div>
      {updateReferenceOpen && (
        <UpdateReference
          open={updateReferenceOpen}
          shipmentData={data}
          onClose={() => setUpdateReferenceOpen(false)}
          onSuccess={updateReference}
        />
      )}
      {updateGroupsOpen && (
        <UpdateGroup
          open={updateGroupsOpen}
          shipmentData={data}
          onClose={() => setUpdateGroupsOpen(false)}
          onSuccess={updateGroups}
        />
      )}
    </SWrapper>
  );
};

export default DetailsHeader;
