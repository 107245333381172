import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getAppUrl } from 'utils/helpers';
import Popover from '@mui/material/Popover';
import Chip from 'common/Chip';
import { ReactComponent as ActionDots } from 'assets/icons/action-dots.svg';
import PositionApplicationsServices from 'services/companySettingsServices';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
};

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useJobPositionColumns = ({ setTableIndex, onUpdate }) => {
  const { use } = useTheme();

  return [
    {
      field: 'id',
      title: <Title title='ID' />,
      render: (job) => (
        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
          {job.id}
        </Typography>
      ),
    },
    {
      field: 'name',
      title: <Title title='POSITION NAME' />,
      render: (job) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {job.name}
        </Typography>
      ),
    },
    {
      field: 'type',
      title: <Title title='APPLICANT TYPE' />,
      render: (job) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {job.job_applicant_type?.name}
        </Typography>
      ),
    },
    {
      field: 'states',
      title: <Title title='STATES' />,

      render: (job) => <Chip label={job.state_count || 0} className='ms-2' size='small' />,
    },
    {
      field: 'status',
      title: <Title title='STATUS' />,
      render: (job) => <span className={job.status.name || ''}>{job.status.name?.toUpperCase()}</span>,
    },
    {
      field: 'Actions',
      width: '5%',
      title: (
        <div className='d-flex justify-content-end'>
          <Title title='ACTIONS' />
        </div>
      ),
      render: (job) => (
        <div className='d-flex justify-content-end'>
          <ThreeDotOption job={job} getJobPosition={() => setTableIndex(Date.now())} onUpdate={onUpdate} />
        </div>
      ),
    },
  ];
};

const ThreeDotOption = ({ job, getJobPosition, onUpdate }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteJobPosition = (id) => {
    setAnchorEl(null);
    PositionApplicationsServices.deleteJobPosition(id).then(() => getJobPosition());
  };

  const copyJobPosition = () => {
    if (loading) {
      return;
    }

    setAnchorEl(null);
    const { name, states, description } = job;
    const statesIds = states.map((item) => item.state.id);

    const data = {
      name,
      description,
      status_id: 2,
      state_id: statesIds,
    };

    setLoading(true);
    PositionApplicationsServices.addJobPosition(data)
      .then(() => getJobPosition())
      .finally(() => setLoading(false));
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className='dots-wrapper' onClick={(event) => setAnchorEl(event.currentTarget)}>
        <ActionDots />
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='update-delete'>
          <li
            onClick={() => {
              setAnchorEl(null);
              setTimeout(() => {
                onUpdate(job);
              }, 100);
            }}
          >
            Update
          </li>
          <li onClick={copyJobPosition}>Copy</li>
          <li
            onClick={() => {
              setAnchorEl(null);
              if (dot) {
                const url = getAppUrl();
                window.open(`${url}/job-positions/${dot}/${job.id}`, '_blank');
              }
            }}
          >
            Public View
          </li>
          <li onClick={() => deleteJobPosition(job.id)} className='delete-action'>
            Delete
          </li>
        </div>
      </Popover>
    </div>
  );
};
