import React, { useEffect, useMemo, useState } from 'react';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import Autocomplete from 'common/Autocomplete';
import Divider from 'common/Divider';
import {
  GetCompanyProfile,
  getDataRetention,
  getLogbookDataRetention,
  UpdateDataRetention,
  updateHostDataRetention,
} from 'Api/CompanySettings';
import PageHeader from '../../components/PageHeader';
import styles from './DataRetention.module.css';

const retentionData = [
  { id: 1, label: '30 Days', data_retention: 30 },
  { id: 2, label: '60 Days', data_retention: 60 },
];

const hoursOfServiceLogs = [
  { id: 1, label: '1 Month', data_retention: 30 },
  { id: 2, label: '6 Months', data_retention: 180 },
  { id: 3, label: '1 Year', data_retention: 365 },
  { id: 4, label: '2 Years', data_retention: 730 },
  { id: 5, label: '3 Years', data_retention: 1095 },
];

const DataRetention = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [companyProfile, setCompanyProfile] = useState(null);
  const [data, setData] = useState(retentionData[0]);
  const [dataRetention, setDataRetention] = useState(null);

  const companyProfileStyle = useMemo(() => {
    return {
      globalLabel: {
        width: 300,
        margin: '10px 0 0 0',
        color: use(palette.gray700, palette.gray200),
      },
      select: {
        width: 160,
        height: 32,
        marginTop: 8,
      },
    };
  }, [palette, use]);

  const getRetention = async (id) => {
    try {
      const { data } = await getDataRetention(id);
      const item = retentionData.find((i) => i.data_retention === data.data_retention);
      if (item) {
        setData(item);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const logBookRetention = async () => {
    const { data } = await getLogbookDataRetention();
    const selected = hoursOfServiceLogs.find((n) => n.data_retention === data.hos_logbooks_retention_days);
    setDataRetention(selected || hoursOfServiceLogs[1]);
  };

  useEffect(() => {
    logBookRetention();
    GetCompanyProfile()
      .then((res) => {
        setCompanyProfile(res);
        getRetention(res.id);
      })
      .catch(() => {
        // Do nothing
      });

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleSubmit = () => {
    const id = companyProfile?.id;
    UpdateDataRetention(id, { data_retention: data.data_retention }).then((res) => {
      if (res && !!res.data) {
        showToaster({ type: 'success', message: 'Changes have been successfully updated!' });
        if (isConfiguringStep) {
          onSaveAndNext('saveAndNext');
        }
      }
    });
    updateHostDataRetention({ hos_logbooks_retention_days: dataRetention.data_retention });
  };

  return (
    <div style={{ padding: '24px 0' }}>
      <PageHeader
        title='Data Retention'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        // loading={loadingUpdate}
      />
      <Divider />
      <div className={styles.basic_wrapper}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            columnGap: 48,
          }}
        >
          <Typography variant='s2' style={companyProfileStyle.globalLabel}>
            Equipment Tracking History
          </Typography>
          <div>
            <Autocomplete
              name='data_retention'
              placeholder='Select Days...'
              labelKey='label'
              options={retentionData || []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={data}
              onChange={(e, value) => setData(value)}
              width='180px'
            />
            <Typography variant='c1' style={{ color: use('#687182', palette.gray200) }}>
              You may change the retention period for your company's data. Changes to these settings will take effect
              within 24 hours.
            </Typography>
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            width: '100%',
            display: 'flex',
            columnGap: 48,
          }}
        >
          <Typography variant='s2' style={companyProfileStyle.globalLabel}>
            Hours of Service Logs
          </Typography>
          <div>
            <Autocomplete
              name='data_retention'
              placeholder='Select Days...'
              labelKey='label'
              options={hoursOfServiceLogs || []}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              value={dataRetention}
              onChange={(e, value) => setDataRetention(value)}
              width='180px'
            />
            <Typography variant='c1' style={{ color: use('#687182', palette.gray200) }}>
              You may change the retention period for your company's data. Changes to these settings will take effect
              within 24 hours.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataRetention;
