import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { palette } from 'utils/constants';

export const SHIPMENT_DETAILS_TABS = [
  { title: 'Overview', id: 1 },
  { title: 'Shipment Events', id: 2 },
  { title: 'Bid History', id: 10, statuses: ['Offered'] },
  { title: 'Tracking History', id: 3 },
  { title: 'Billing Charges', id: 4 },
  { title: 'Driver Pay', id: 8, secondTitle: 'Carrier Pay' },
  { title: 'Cargo', id: 11 },
  { title: 'Documents', id: 5 },
  { title: 'Notes', id: 6 },
  { title: 'Detention', id: 7 },
  { title: 'eBOL', id: 9, icon: 'lightning.svg' },
];

export const DEFINEDS = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -31)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
};

export const DEFAULTWIDTHOFCOLUMNS = {
  checkbox: 25,
  shipment_id: 100,
  customer: 150,
  assigned_to: 150,
  equipment: 150,
  status: 100,
  groups: 80,
  scheduled_origin: 150,
  origin_stop_name: 150,
  current_location: 150,
  next_stop: 150,
  total_eta: 120,
  rpm: 80,
  weight: 172,
  amount: 100,
  last_gps_update: 150,
  hours_remaining: 60,
  billing_of_lading: 120,
  previous_stop: 150,
  commodity: 100,
  next_equipment_action: 100,
  empty_miles: 100,
  loaded_miles: 100,
  deliver_earliest: 100,
  deliver_latest: 100,
  destination_stop_name: 150,
  speed: 110,
  motion: 150,
  planned: 75,
  threeDotes: 30,
};

export const DEFAULTWIDTHOFCOLUMNSPLANNER = {
  checkbox: 25,
  shipment_id: 100,
  customer: 150,
  type: 100,
  amount: 100,
  groups: 80,
  stops: 60,
  status: 130,
  origin_stop_name: 170,
  destination_stop_name: 170,
  miles: 70,
  threeDotes: 30,
  created_by: 150,
};

export const DEFAULTWIDTHOFCOLUMNSAUDIT = {
  checkbox: 25,
  shipment_id: 120,
  status: 100,
  stops: 60,
  origin: 150,
  destination: 150,
  departure_date: 150,
  empty_miles: 100,
  loaded_miles: 100,
  planned_duration: 150,
  scheduled_duration: 150,
  actual_duration: 150,
  assigned_driver: 200,
  driver_amount: 150,
  pw: 80,
  revenue: 120,
  weight: 172,
  gross_revenue: 130,
  customer: 150,
  equipment: 150,
  margin: 150,
  threeDotes: 30,
  audited_by: 170,
};

export const SIDEBAR_DEFAULT = [
  {
    label: 'Live',
    range: () => {
      return {
        startDate: DEFINEDS.startOfToday,
        endDate: DEFINEDS.endOfToday,
        label: 'Live',
        key: 'selection',
      };
    },
  },
  {
    label: 'Yesterday',
    range: () => {
      return {
        startDate: DEFINEDS.startOfYesterday,
        endDate: DEFINEDS.endOfYesterday,
        label: 'Yesterday',
        key: 'selection',
      };
    },
  },
  {
    label: 'This Weak',
    range: () => {
      return {
        startDate: DEFINEDS.startOfWeek,
        endDate: DEFINEDS.startOfToday,
        label: 'This Weak',
        key: 'selection',
      };
    },
  },
  {
    label: 'Last 7 Days',
    range: () => {
      return {
        startDate: DEFINEDS.startOfLastSevenDay,
        endDate: DEFINEDS.endOfToday,
        label: 'Last 7 Days',
        key: 'selection',
      };
    },
  },
  {
    label: 'This Month',
    range: () => {
      return {
        startDate: DEFINEDS.startOfMonth,
        endDate: DEFINEDS.startOfToday,
        label: 'This Month',
        key: 'selection',
      };
    },
  },
  {
    label: 'Last 30 Days',
    range: () => {
      return {
        startDate: DEFINEDS.startOfLastThirtyDay,
        endDate: DEFINEDS.endOfToday,
        label: 'Last 30 Days',
        key: 'selection',
      };
    },
  },
  {
    label: 'This Year',
    range: () => {
      return {
        startDate: DEFINEDS.startOfYear,
        endDate: DEFINEDS.endOfToday,
        label: 'This Year',
        key: 'selection',
      };
    },
  },
  {
    label: 'Last Year',
    range: () => {
      return {
        startDate: DEFINEDS.startOflastYear,
        endDate: DEFINEDS.endOflastYear,
        label: 'Last Year',
        key: 'selection',
      };
    },
  },
  {
    label: 'Custom Range',
    range: () => {
      return {
        startDate: DEFINEDS.startOfToday,
        endDate: DEFINEDS.endOfToday,
        label: 'Custom Range',
        key: 'selection',
      };
    },
  },
];

export const TRUCKING_HISTORY_DATEPICKER_SIDEBAR = [
  {
    label: 'Live',
    range: () => {
      return {
        startDate: DEFINEDS.startOfToday,
        endDate: DEFINEDS.endOfToday,
        label: 'Live',
        key: 'selection',
      };
    },
  },
  {
    label: 'Yesterday',
    range: () => {
      return {
        startDate: DEFINEDS.startOfYesterday,
        endDate: DEFINEDS.endOfYesterday,
        label: 'Yesterday',
        key: 'selection',
      };
    },
  },
  {
    label: 'This Weak',
    range: () => {
      return {
        startDate: DEFINEDS.startOfWeek,
        endDate: DEFINEDS.endOfToday,
        label: 'This Weak',
        key: 'selection',
      };
    },
  },
  {
    label: 'Last 7 Days',
    range: () => {
      return {
        startDate: DEFINEDS.startOfLastSevenDay,
        endDate: DEFINEDS.endOfToday,
        label: 'Last 7 Days',
        key: 'selection',
      };
    },
  },
  {
    label: 'This Month',
    range: () => {
      return {
        startDate: DEFINEDS.startOfMonth,
        endDate: DEFINEDS.endOfToday,
        label: 'This Month',
        key: 'selection',
      };
    },
  },
  {
    label: 'Last 30 Days',
    range: () => {
      return {
        startDate: DEFINEDS.startOfLastThirtyDay,
        endDate: DEFINEDS.endOfToday,
        label: 'Last 30 Days',
        key: 'selection',
      };
    },
  },
  {
    label: 'This Year',
    range: () => {
      return {
        startDate: DEFINEDS.startOfYear,
        endDate: DEFINEDS.endOfToday,
        label: 'This Year',
        key: 'selection',
      };
    },
  },
  {
    label: 'Last Year',
    range: () => {
      return {
        startDate: DEFINEDS.startOflastYear,
        endDate: DEFINEDS.endOflastYear,
        label: 'Last Year',
        key: 'selection',
      };
    },
  },
];

// const target = "2023-09-27T12:46:48.000000Z";
export function timeDifference(targetDate) {
  const now = new Date();
  const endDate = new Date(targetDate);

  let delta = Math.abs(endDate - now) / 1000;

  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;

  let result = '';
  if (days > 0) result += `${days}d `;
  if (hours > 0) result += `${hours}h `;
  if (minutes > 0) result += `${minutes}m`;

  return result.trim();
}

export function getTimeDifference(targetTime) {
  const targetDate = new Date(targetTime);
  const currentDate = new Date();
  const timeDifference = targetDate - currentDate;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  let result = '';
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || result === '') {
    result += `${minutes}m`;
  }

  return result.trim();
}

export function getUTCTimeDifference(targetTime) {
  const targetDate = new Date(targetTime);

  const currentDate = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes()
    )
  );

  let timeDifference = currentDate - targetDate;
  if (timeDifference < 0) {
    timeDifference = 0;
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  let result = '';

  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || result === '') {
    result += `${minutes}m`;
  }

  return result.trim();
}

export const getPlannerStatus = (rowData) => {
  const {
    status,
    pending_dispatch,
    canceled_at,
    shipment_type,
    accept_date,
    expired_at,
    declined_at,
    is_edi_api,
    manual_edi_api,
    edi_timelimit_date_time,
    shipment_offers,
  } = rowData || {};

  let bgStatus;
  let colorStatus;
  let statusName;

  if ((is_edi_api || manual_edi_api) && !accept_date && !expired_at && !declined_at) {
    bgStatus = '#F0F2F7';
    colorStatus = '#5A6376';
    statusName = `Awaiting Acceptance (${getTimeDifference(edi_timelimit_date_time)})`;
  } else if (is_edi_api && expired_at) {
    bgStatus = palette.red0;
    colorStatus = palette.red500;
    statusName = `Expired`;
  } else if (is_edi_api && declined_at) {
    bgStatus = palette.red0;
    colorStatus = palette.red500;
    statusName = `Rejected`;
  } else if (pending_dispatch) {
    bgStatus = '#F0F2F7';
    colorStatus = '#5A6376';
    statusName = `Pending Dispatch`;
  } else if (canceled_at) {
    bgStatus = '#FAF0F3';
    colorStatus = '#D12953';
    statusName = 'Canceled';
  } else {
    if (shipment_offers?.find((offer) => offer.driver_acceptance === 1)) {
      bgStatus = '#F0F2F7';
      colorStatus = '#5A6376';
      statusName = `OFFER ACCEPTED`;
    } else if (status === 'Offered') {
      bgStatus = '#F0F2F7';
      colorStatus = '#5A6376';
      statusName = `Offered`;
    } else if (shipment_type === 'split-parent' || shipment_type === 'slave') {
      bgStatus = '#FFFAEB';
      colorStatus = '#B48909';
      statusName = 'IN PROGRESS';
    } else if (status >= 24) {
      const daysCount = Math.floor(status / 24);
      bgStatus = '#EBF0FA';
      colorStatus = '#2264E6';
      statusName = `+${daysCount} DAYS SCHEDULED`;
    } else if (status >= 4 && status < 24) {
      bgStatus = '#EBF0FA';
      colorStatus = '#2264E6';
      statusName = 'SCHEDULED WITHIN 24H';
    } else if (status >= 0 && status < 4) {
      bgStatus = '#FFFAEB';
      colorStatus = '#B48909';
      statusName = 'SCHEDULED WITHIN 24H';
    } else if (status < 0) {
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      statusName = 'BEHIND SCHEDULE';
    }
  }

  return { bgStatus, colorStatus, statusName };
};

export const getStatusParamsInStatus = (status) => {
  const id = status?.id;
  let bgStatus;
  let colorStatus;
  let statusName = status?.title;

  switch (+id) {
    case 1:
      bgStatus = '#F0F2F7';
      colorStatus = palette.gray600;
      statusName = 'PLANNED';
      break;
    case 2:
      bgStatus = '#F0F2F7';
      colorStatus = palette.gray600;
      break;
    case 3:
      bgStatus = palette.indigo0;
      colorStatus = palette.indigo500;
      break;
    case 4:
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      break;
    case 5:
      bgStatus = palette.green0;
      colorStatus = palette.green500;
      break;
    case 6:
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      break;
    case 7:
      bgStatus = palette.green0;
      colorStatus = palette.green500;
      break;
    case 8:
      bgStatus = '#F0F2F7';
      colorStatus = palette.gray600;
      statusName = 'Pending Dispatch';
      break;
    case 10:
      bgStatus = '#FAF0F3';
      colorStatus = '#D12953';
      break;
    default:
  }

  return { bgStatus, colorStatus, statusName, id };
};

export const equipmentTypeText = ({ type, action, equipment, equipment_tbd }) => {
  if (+type === 1) {
    const map = {
      1: 'Live Load',
      2: equipment_tbd ? `Hook (TBD)` : `Hook to ${equipment?.equipment_id}`,
      3: equipment_tbd ? `Drop Trailer & Hook (TBD)` : `Drop Trailer & Hook to ${equipment?.equipment_id}`,
    };
    return map[action];
  }
  if (+type === 2) {
    const map = {
      1: 'Live Unload',
      2: 'Drop Trailer',
    };
    return map[action];
  }
  if (+type === 3) {
    const map = {
      1: 'Live Unload',
      2: `Drop Trailer`,
      3: equipment_tbd ? `Drop Trailer & Hook (TBD)` : `Drop Trailer & Hook to ${equipment?.equipment_id}`,
      4: 'Other',
    };
    return map[action];
  }
};
