import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getDocType } from 'Api/Planner';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/createShipment/attachment.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import ImageDropZone from 'components/ImgageDropZone';
import CustomizedSnackbars from 'components/toast/Toast';
import sm from './Documents.module.css';
import CustomInput from '../helpers/CustomInput';
import FileActions from './helpers/FileActions';
import CustomSelect from '../ShipmentStops/helpers/CustomSelect';
import DocumentsFooter from './DocumentsFooter';
import { ShipmentContext } from '../Layout';
import { getDocumentTemplate } from '../ShipmentStops/helpers/constants';

const Documents = () => {
  const { use } = useTheme();
  const [planAssign, setPlanAssign] = useState(false);
  const [offerAssign, setOfferAssign] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: 'Confirmation document upload is required per customer terms!',
    visible: false,
    type: 'error',
  });
  const [errorsArr, setErrorsArr] = useState([]);
  const {
    createShipmentData,
    setStep,
    onCloseModal,
    documentsUploadFiles,
    updateDocumentsUploadFiles,
    docType,
    updateDocType,
    selectedCharges,
    updateSelectedTotalAmount,
    onCreate,
    paymentTermData,
    shipmentCreateLoading,
  } = useContext(ShipmentContext);

  const getDocumentType = () => {
    getDocType({ shipment_id_required: 1 }).then((res) => {
      if (res && res?.data) {
        const selectData = res.data
          .filter((el) => !el.hidden)
          .map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.name,
              labelSelected: null,
            };
          });
        updateDocType(selectData);
      }
    });
  };

  function onClickPlanAssign(type, submitForm) {
    if (type === 'plan_assign') {
      setPlanAssign(!planAssign);
    } else {
      setOfferAssign(!offerAssign);
    }
    setTimeout(() => submitForm(), 0);
  }

  const onNext = (values, form) => {
    const requireConfirmations = paymentTermData[0]?.require_confirmations;
    const documents = values.documents;
    const errorArr = [];
    documents.forEach((el, index) => {
      const file = documentsUploadFiles[index];

      const { documentName, documentReferenceId } = el;
      if (documentName && !documentReferenceId && !!file) {
        errorArr.push({ documentReferenceId: 'documentReferenceId', index });
      }
      if (!documentName && !!file && documentReferenceId) {
        errorArr.push({ documentName: 'documentName', index });
      }
      if (documentName && documentReferenceId && !file) {
        errorArr.push({ index, file: 'Required' });
      }
      if (documentName && !documentReferenceId && !file) {
        errorArr.push({ documentReferenceId: 'documentReferenceId', index, file: 'Required' });
      }
      if (!documentName && documentReferenceId && !file) {
        errorArr.push({ documentName: 'documentName', index, file: 'Required' });
      }
      if (!documentName && !documentReferenceId && !!file) {
        errorArr.push({ documentName: 'documentName', documentReferenceId: 'documentReferenceId', index });
      }
    });
    errorArr.forEach((el) => {
      const { index } = el;
      form.setFieldError(`documents[${index}].${el?.documentName || el?.documentReferenceId}`, 'Required');
    });
    setErrorsArr(errorArr);
    if (errorArr.length === 0) {
      if (+requireConfirmations === 1) {
        const foundedConfirmations = documents.find((doc) => +doc.documentName === 4);
        foundedConfirmations
          ? (() => {
              onCreate({ lastStepData: values, planAssign, offerAssign });
              setShowLoading(true);
            })()
          : setShowMessage({ ...showMessage, visible: true });
      } else {
        onCreate({ lastStepData: values, planAssign, offerAssign });
        setShowLoading(true);
      }
    }
  };

  const onDrop = (acceptedFiles, name, index) => {
    const foundedError = errorsArr.find((el) => el.index === index);
    if (foundedError) {
      delete foundedError.file;
    }
    updateDocumentsUploadFiles({ ...documentsUploadFiles, [index]: acceptedFiles[0] });
  };

  const onDelete = (index, rowDeleted = false) => {
    const prevDocuments = { ...documentsUploadFiles };
    delete prevDocuments[index];
    if (!rowDeleted) {
      updateDocumentsUploadFiles(prevDocuments);
    } else {
      const data = {};
      Object.keys(prevDocuments).forEach((key) => {
        const value = prevDocuments[key];
        if (key > index) {
          data[key - 1] = value;
        } else {
          data[key] = value;
        }
      });
      updateDocumentsUploadFiles(data);
    }
  };

  const onDownload = (index) => {
    const prevDocuments = { ...documentsUploadFiles };
    const currentFile = prevDocuments[index];
    if (currentFile) {
      const link = document.createElement('a');
      const url = URL.createObjectURL(currentFile);
      link.setAttribute('download', currentFile.name);
      link.href = url;
      link.click();
    }
  };

  const documentsStyles = useMemo(() => {
    return {
      select: {
        height: 32,
        width: 400,
        borderRadius: '6px 0 0 6px',
        borderRight: `1px solid ${use(palette.gray200, palette.dark700)}`,
      },
      input: {
        width: 200,
        height: 32,
        borderRadius: '0 6px 6px 0',
        paddingLeft: 12,
      },
      chooseFile: {
        whiteSpace: 'nowrap',
        marginLeft: 8,
        color: use(palette.indigo500, palette.indigo500),
      },
      addADocument: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        width: 400,
        border: `1px dashed ${use(palette.gray200, palette.gray400)}`,
        cursor: 'copy',
      },
      addADocumentTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
    };
  }, [palette, use]);

  const initialValue = useMemo(() => {
    return {
      documents: createShipmentData?.documents ? [...createShipmentData.documents] : [{ ...getDocumentTemplate() }],
    };
  }, [createShipmentData]);

  function onChangeDocumentSelect(index) {
    const clone = errorsArr;
    if (clone?.[index]?.documentName) {
      clone[index].documentName = '';
      setErrorsArr(clone);
    }
  }

  function onChangeReferenceId(index) {
    const clone = errorsArr;
    if (clone?.[index]?.documentReferenceId) {
      clone[index].documentReferenceId = '';
      setErrorsArr(clone);
    }
  }

  function onDeleteSelect(name, setFieldValue, index) {
    setFieldValue(name, '');
    const cloneErr = [...errorsArr];
    const foundedIndex = cloneErr.findIndex((err) => err?.index === index);
    if (
      typeof cloneErr[foundedIndex] === 'object' &&
      !Array.isArray(cloneErr[foundedIndex]) &&
      Object.keys(cloneErr[foundedIndex]).length > 2
    ) {
      cloneErr.splice(foundedIndex, 1);
      setErrorsArr(cloneErr);
    }
  }

  useEffect(() => {
    getDocumentType();
  }, []);

  useEffect(() => {
    const subTotal = { 0: 0 };
    selectedCharges[0]?.forEach((charge) => {
      if (charge?.qty && charge?.rate) {
        subTotal[0] = +subTotal[0] + Number(charge.qty) * Number(charge.rate);
      }
    });
    updateSelectedTotalAmount(subTotal);
  }, [selectedCharges]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <Formik onSubmit={onNext} initialValues={initialValue}>
        {({ values, submitForm, setFieldValue }) => {
          return (
            <Form>
              <FieldArray
                name='documents'
                render={(arrayHelpers) => {
                  return (
                    <div className={sm.documents_container}>
                      {values?.documents.map((documentItem, index) => {
                        const namePrefix = `documents[${index}]`;
                        const foundedError = errorsArr.find((el) => el.index === index);

                        return (
                          <div key={namePrefix} className={sm.documents_wrapper}>
                            <div className={sm.error_wrapper}>
                              <Field
                                name={`${namePrefix}.documentName`}
                                component={CustomSelect}
                                menuStyles={{ width: 400 }}
                                deleted
                                onDelete={() => onDeleteSelect(`${namePrefix}.documentName`, setFieldValue, index)}
                                options={docType}
                                onChange={() => onChangeDocumentSelect(index)}
                                styles={documentsStyles.select}
                              />
                              {foundedError?.documentName === 'documentName' && (
                                <Typography
                                  variant='c2'
                                  style={{
                                    fontSize: 12,
                                    color: use(palette.red500, palette.red800),
                                  }}
                                >
                                  Required
                                </Typography>
                              )}
                            </div>

                            <div>
                              <Field
                                name={`${namePrefix}.documentReferenceId`}
                                component={CustomInput}
                                style={documentsStyles.input}
                                labelStyle={{ margin: 0 }}
                                placeholder='Reference ID'
                                onChange={() => onChangeReferenceId(index)}
                                value={values.documents?.[index].documentReferenceId}
                              />
                              {foundedError?.documentReferenceId === 'documentReferenceId' && (
                                <Typography
                                  variant='c2'
                                  style={{
                                    fontSize: 12,
                                    color: use(palette.red500, palette.red800),
                                  }}
                                >
                                  Required
                                </Typography>
                              )}
                            </div>

                            <div>
                              <div className={sm.upload_wrapper}>
                                <ImageDropZone
                                  onDrop={(acceptedFiles, name) => onDrop(acceptedFiles, name, index)}
                                  height={32}
                                  accept={['application/pdf']}
                                  name='small'
                                  deletable
                                  onDelete={onDelete}
                                  deletedComponent={
                                    documentsUploadFiles?.[index] ? (
                                      <FileActions
                                        onDelete={() => onDelete(index)}
                                        onDownload={() => onDownload(index)}
                                      />
                                    ) : (
                                      <div />
                                    )
                                  }
                                >
                                  <div className={sm.chooseFile_wrapper}>
                                    {documentsUploadFiles?.[index]?.name ? (
                                      <>
                                        <AttachmentIcon fill={use(palette.indigo500, palette.indigo500)} />
                                        <Typography variant='s2' style={documentsStyles.chooseFile}>
                                          {documentsUploadFiles?.[index]?.name}
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <PlusIcon fill={use(palette.indigo500, palette.indigo500)} />
                                        <Typography variant='s2' style={documentsStyles.chooseFile}>
                                          Choose File
                                        </Typography>
                                      </>
                                    )}
                                  </div>
                                </ImageDropZone>
                              </div>
                              {foundedError?.file && (
                                <Typography
                                  variant='c2'
                                  style={{
                                    marginLeft: 20,
                                    fontSize: 12,
                                    color: use(palette.red500, palette.red800),
                                  }}
                                >
                                  {foundedError?.file}
                                </Typography>
                              )}
                            </div>

                            {values.documents.length > 1 && (
                              <MinusIcon
                                onClick={() => {
                                  onDelete(index, true);
                                  values.documents.length > 1 && arrayHelpers.remove(index);
                                }}
                                className={sm.minusIcon}
                              />
                            )}
                          </div>
                        );
                      })}
                      <CustomButton
                        type='secondary'
                        title='Add Document'
                        leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                        styleButton={documentsStyles.addADocument}
                        styleTitle={documentsStyles.addADocumentTitle}
                        onClick={() => {
                          arrayHelpers.push(getDocumentTemplate());
                        }}
                      />
                      {showLoading && (
                        <Box sx={{ width: '80%', marginTop: '30px', border: '1px solid #4F5AED' }}>
                          <LinearProgress variant='determinate' value={progress} style={{ padding: '5px' }} />
                        </Box>
                      )}
                    </div>
                  );
                }}
              />

              <DocumentsFooter
                setStep={setStep}
                submitForm={submitForm}
                onCloseModal={onCloseModal}
                onClickPlanAssign={onClickPlanAssign}
                shipmentCreateLoading={shipmentCreateLoading}
                updateDocumentsUploadFiles={updateDocumentsUploadFiles}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Documents;
