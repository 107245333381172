import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

export const RowActions = ({ row, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'editEmployer':
        onEdit(row);
        break;
      case 'deleteEmployer':
        onDelete(row.id);
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      {onEdit && <li onClick={(event) => onActionClick(event, 'editEmployer')}>Edit</li>}
      {onDelete && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'deleteEmployer')}>
          Delete
        </li>
      )}
    </ThreeDotActions>
  );
};

export const SubRowActions = ({ row, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'deleteAttempt':
        onDelete(row.id);
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      {onDelete && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'deleteAttempt')}>
          Delete
        </li>
      )}
    </ThreeDotActions>
  );
};
