import styled from 'styled-components';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;
`;

export const SItemRow = styled.div`
  display: flex;
  column-gap: 24px;
  padding: 16px 0;

  label {
    margin-top: 5px;
  }
`;
