import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { formatNumber, palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('List', 16, top);
  doc.text('Current', 62, top);
  doc.text('1-30', 88, top);
  doc.text('31-60', 110, top);
  doc.text('61-90', 132, top);
  doc.text('91+', 160, top);
  doc.text('Total', pageWidth - 16, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(16, top + 2, pageWidth - 16, top + 2);
};

const createTableRow = (doc, data, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${data.vendor <= 30 ? data.vendor || '' : data.vendor?.substring(0, 30) || ''}`, 16, top);
  doc.text(formatNumber(data.current.total_amount), 62, top);
  doc.text(formatNumber(data['1-30'].total_amount), 88, top);
  doc.text(formatNumber(data['31-60'].total_amount), 110, top);
  doc.text(formatNumber(data['61-90'].total_amount), 132, top);
  doc.text(formatNumber(data['91+'].total_amount), 160, top);
  doc.text(formatNumber(data.total), pageWidth - 16, top, { align: 'right' });
};

const createTotalRow = (doc, vendors, totalData, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(16, top, pageWidth - 16, top);

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Total', 16, top + 5);
  doc.text(formatNumber(totalData.Current?.total_amount || 0), 62, top + 5);
  doc.text(formatNumber(totalData['1-30']?.total_amount || 0), 88, top + 5);
  doc.text(formatNumber(totalData['31-60']?.total_amount || 0), 110, top + 5);
  doc.text(formatNumber(totalData['61-90']?.total_amount || 0), 132, top + 5);
  doc.text(formatNumber(totalData['91+']?.total_amount || 0), 160, top + 5);
  doc.text(formatNumber(vendors.reduce((acc, cur) => acc + cur.total, 0)), pageWidth - 16, top + 5, { align: 'right' });

  doc.setLineWidth(0.1);
  doc.line(16, top + 8, pageWidth - 16, top + 8);
};

const createTableRows = (doc, vendors) => {
  if (!vendors?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  vendors.forEach((vendor, index) => {
    createTableRow(doc, vendor, top + index * 5);
  });

  return top + vendors.length * 5;
};

export const generatePDF = async (data, download, dateRange, dateFormat) => {
  const { reportItems, totalData, title } = data || {};
  if (!reportItems?.length) {
    return;
  }

  const { start, end } = dateRange;

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text(title || 'Vendor List', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      28,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, 28, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(dateFormat.convertToCustomerTime(), pageWidth - 16, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(16, 30, pageWidth - 16, 30);

  const firstPageVendors = reportItems.slice(0, 40);
  const restVendors = reportItems.slice(40);
  let totalRowStart;

  totalRowStart = createTableRows(doc, firstPageVendors);

  for (let i = 0; i < (restVendors?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageVendors = restVendors.slice(i * 45, (i + 1) * 45);
    totalRowStart = createTableRows(doc, currentPageVendors);
  }

  createTotalRow(doc, reportItems, totalData, totalRowStart);

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc);
  }

  doc.setDocumentProperties({
    title: `${title} - ${
      start && end ? `${dateFormat.formatDate(start)} - ${dateFormat.formatDate(end)}` : 'All Time'
    }`,
  });

  const url = doc.output('datauristring', { filename: title });
  const blob = doc.output('blob', { filename: title });

  if (download) {
    doc.save(
      `${title} - ${start && end ? `${dateFormat.formatDate(start)} - ${dateFormat.formatDate(end)}` : 'All Time'}.pdf`
    );
  }
  return { blob, url };
};
