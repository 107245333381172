import React, { useCallback, useEffect, useState } from 'react';
import './TruckHistoryMap.css';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import mapMarkerRedIcon from 'assets/icons/createShipment/mapMarkerRed.svg';
import mapMarkerBlueIcon from 'assets/icons/createShipment/mapMarkerBlue.svg';
import carrierIcon from 'assets/icons/carrier.svg';
import { getAlphabet, palette } from 'utils/constants';
import person from 'assets/images/person.svg';

const NAME_TYPES = {
  1: 'PICK UP',
  2: 'DELIVERY',
  3: 'WAYPOINT',
};

const CLASS_TYPES = {
  1: 'pickUp',
  2: 'delivery',
  3: 'wayPoint',
};

let mapInstance = null;
const svgCurrentContent = null;

const TruckHistoryMap = ({
  isDraggable = false,
  stopPointArr,
  literalJsx,
  getLiteralItemJsx,
  hoverPopover = true,
  styleContainer,
  classContainer,
  type,
  children,
  onChangeCoords,
  onMountCoords,
  routeOptionsOutSide,
  markerToolTip = true,
  dragRotate = false,
  stopIconColor = false,
  driversArr = [],
  secondRouteArr,
  setMapInstance,
  infoHeader,
}) => {
  const navigate = useNavigate();
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [coords, setCoords] = useState([]);
  const [stops, setStops] = useState([]);
  const [indexForAlphabet, setIndexForAlphabet] = useState([]);
  const { brokerage_dispatch } = infoHeader || {};
  const { carrier } = brokerage_dispatch || {};
  const { logo_path } = carrier || {};

  const [markerAdded, setMarkerAdded] = useState(false);
  const onMapLoad = () => {
    setMapIsLoaded(true);
    setMarkerAdded(true);
  };

  const updateMap = useCallback(
    (params) => {
      const myMap = mapInstance;
      if (myMap) {
        const {
          coords,
          stops,
          indexForAlphabet,
          literalJsx,
          getLiteralItemJsx,
          routeOptionsOutSide,
          stopIconColor,
          secondRouteArr,
          getSecondsRoutes,
        } = params;
        const markers = [];
        // Create a marker with SVG content

        // Draw Markers
        if (!markerAdded) {
          coords.forEach((cord, i) => {
            const alphabet = getAlphabet(indexForAlphabet[i]);
            const id = stops[i]?.stop_point_type;
            const svgContent = document.createElement('div');
            let content;
            if (id === null || id === undefined) {
              content = `<div style="display: none"/>`;
            } else if (literalJsx === undefined && getLiteralItemJsx === undefined) {
              if (markerToolTip) {
                content = `
                               <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                                   <img src="${mapMarkerIcon}">
                                   <div class='myMap_marker_text_wrapper'>
                                     <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                                   </div>
                               </div>
                               `;
              } else {
                if (stopIconColor) {
                  id === 1
                    ? (content = `<div class='myMap_marker_container ${
                        CLASS_TYPES[+id]
                      }'><img src="${mapMarkerIcon}"></div>`)
                    : id === 2
                    ? (content = `<div class='myMap_marker_container ${
                        CLASS_TYPES[+id]
                      }'><img src="${mapMarkerRedIcon}"></div>`)
                    : (content = `<div class='myMap_marker_container ${
                        CLASS_TYPES[+id]
                      }'><img src="${mapMarkerBlueIcon}"></div>`);
                } else {
                  content = `<div class='myMap_marker_container ${
                    CLASS_TYPES[+id]
                  }'><img src="${mapMarkerIcon}"></div>`;
                }
              }
            } else {
              let label;
              if (literalJsx) {
                label = literalJsx;
              } else if (typeof getLiteralItemJsx === 'function') {
                label = getLiteralItemJsx(stops?.[i], stops, i);
              }
              content = `
                               <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                                   <img src="${mapMarkerIcon}">
                                   <div class='myMap_marker_text_wrapper'>
                                     <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                                   </div>
                                   <div
                                    class="map_popover ${!label ? 'noneLAbel' : ''}"
                                    style="opacity: ${hoverPopover ? 0 : 1}"
                                    >
                                    ${label}
                                   </div>
                               </div>
                               `;
            }
            svgContent.innerHTML = content;
            const marker = new TrimbleMaps.Marker({
              draggable: false,
              element: svgContent,
            })
              .setLngLat([cord.longitude, cord.latitude])
              .addTo(myMap);
            markers.push(marker);
          });
        }

        /** drivers * */
        const routes = [];
        const driverMarkers = [];

        driversArr?.forEach((driver) => {
          const svgContent = document.createElement('div');
          const driverIconContent = `
                                <div class='myMap_driver_marker_container' id="">
                                   <img src="${carrier ? logo_path || carrierIcon : driver?.img || person}">
                                   <div class='myMap_marker_text_wrapper driver_marker'>
                                     <span>DEAD HEAD ${driver?.miles} Miles</span>
                                   </div>
                               </div>
        `;
          const { routeProps } = driver || {};
          const { lat, lng, ...restDriver } = driver?.marker || {};

          if (!!lat && !!lng) {
            svgContent.innerHTML = driverIconContent;
            const driverMarker = new TrimbleMaps.Marker({
              draggable: false,
              element: svgContent,
              ...restDriver,
            }).setLngLat([lng, lat]);

            driverMarkers.push(driverMarker);
            markers.push(driverMarker);

            const startPoint = stops.find((item) => +item.stop_point_type === 1);

            if (startPoint) {
              const route = new TrimbleMaps.Route({
                routeId: `${driver.id}-${Math.random()}`,
                stops: [
                  { ...new TrimbleMaps.LngLat(+lng, +lat), stopPoint: true },
                  {
                    ...new TrimbleMaps.LngLat(Number(startPoint?.longitude), Number(startPoint?.latitude)),
                    stopPoint: true,
                  },
                ],
                routeColor: palette.blue300,
                showStops: false,
                stopPoint: true,
                ...routeProps,
              });
              routes.push(route);
            }
          }
        });
        /** drivers * */

        const stopsMap = coords.map((cord) => {
          const latLngObj = new TrimbleMaps.LngLat(cord.longitude, cord.latitude);
          return { ...latLngObj, stopPoint: true };
        });
        /// ///// Marker //////////
        const routeOptions = {
          // bordersOpen default = true
          routeId: `myRoute-${Math.random()}`,
          stops: stopsMap,
          showStops: false,
          isDraggable,
          routeColor: palette.indigo500, // optional routeColor
          vehicleType: infoHeader.shipment_route?.vehicle_type,
          routeType:
            infoHeader.shipment_route?.route_type === 'practical'
              ? 0
              : infoHeader.shipment_route?.route_type === 'shortest'
              ? 1
              : 2,
          tollRoads: infoHeader.shipment_route?.avoid_tolls ? 2 : 3,
          highwayOnly: !!infoHeader.shipment_route?.highway_only,
          hazMatType: infoHeader.shipment_route?.hazmat_id,
          ...routeOptionsOutSide,
        };
        // coords.forEach(cord=> routeOptions.stops.push(new TrimbleMaps.LngLat(cord.longitude, cord.latitude)))
        const myRoute = new TrimbleMaps.Route(routeOptions);

        const onRouteStopInsertingHandler = (e) => {
          const allStopsInOrdered = [...(e?.target?._options?.stops || [])];
          allStopsInOrdered.splice(e.routeLegPositions[0] + 1, 0, e.newStop);
          const newCoords = { latitude: e?.newStop?.lat, longitude: e?.newStop?.lng };
          const defaultCoords = coords.map((cord) => ({ ...cord, stopPoint: true }));
          typeof onChangeCoords === 'function' &&
            onChangeCoords({
              newCoords,
              defaultCoords,
              stops: allStopsInOrdered,
            });
          // myRouteElem.innerHTML = `Mouse down on ${e.routeLegPositions.length} route leg(s)`;
          myRoute.getRouteWithNewStop(e.newStop, e.routeLegPositions[e.routeLegPositions.length - 1]);
        };
        myRoute.on('stopInserting', onRouteStopInsertingHandler);

        myRoute.addTo(myMap);

        const mapRoutesArr = getSecondsRoutes(secondRouteArr);
        mapRoutesArr?.forEach((i) => {
          i.route.addTo(myMap);
          i.marker.forEach((m) => m.addTo(myMap));
        });

        routes?.forEach((item) => item.addTo(myMap));
        driverMarkers?.forEach((item) => item.addTo(myMap));

        return {
          unSubscribe() {
            myRoute?.remove();

            routes.forEach((route) => {
              route?.remove();
            });
            svgCurrentContent?.removeEventListener('click', onCurrentHandler);
            document.getElementById('current-wrapper')?.remove();
            mapRoutesArr?.forEach((i) => {
              i.route?.remove();
              i.marker.forEach((m) => m?.remove());
            });
          },
        };
      }
    },
    [secondRouteArr]
  );

  const getSecondsRoutes = useCallback((routesArr) => {
    if (!secondRouteArr) return [];
    const mapRoutesArr = routesArr.map((item, i) => {
      const routeId = `myRoute${i}-${Math.random()}`;
      const { stops, routeOptions } = item || {};

      const Marker = stops?.map((el) => {
        const mapIcon = {
          1: mapMarkerIcon,
          2: mapMarkerRedIcon,
          3: mapMarkerBlueIcon,
        };
        const icon = !el?.stop_point_type ? mapMarkerIcon : mapIcon?.[Number(el?.stop_point_type)];
        const div = document.createElement('div');
        const content =
          el?.stopPoint === false || el?.stopPoint === undefined
            ? `<div style="display: none"/>`
            : `<div class='myMap_marker_container}'><img src="${icon}"></div>`;
        div.innerHTML = content;
        return new TrimbleMaps.Marker({
          draggable: false,
          element: div,
          // ...restDriver
        }).setLngLat([el.longitude, el.latitude]);
      });

      const stopsMap = stops.map((el) => {
        const latLng = new TrimbleMaps.LngLat(el.longitude, el.latitude);
        return { ...latLng, stopPoint: true };
      });
      const routeOpt = {
        routeId,
        stops: stopsMap,
        showStops: false,
        isDraggable: false,
        routeColor: palette.indigo500, // optional routeColor\
        ...routeOptions,
      };
      return { route: new TrimbleMaps.Route(routeOpt), marker: Marker };
    });
    return mapRoutesArr;
  }, []);

  function onCurrentHandler() {
    navigate(`/equipment-profile/vehicle/${infoHeader?.equipment?.id}`);
  }

  useEffect(() => {
    let response;
    const params = {
      mapIsLoaded,
      coords,
      stops,
      indexForAlphabet,
      type,
      literalJsx,
      getLiteralItemJsx,
      routeOptionsOutSide,
      dragRotate,
      markerToolTip,
      driversArr,
      stopIconColor,
      secondRouteArr,
      getSecondsRoutes,
      updateMap,
    };

    const onLoad = () => {
      response = updateMap(params);
    };

    if (mapIsLoaded) {
      response = updateMap(params);
    } else {
      mapInstance?.on('load', onLoad);
    }

    return () => {
      mapInstance?.off('load', onLoad);
      response?.unSubscribe();
    };
  }, [mapIsLoaded, coords, stops, getSecondsRoutes, updateMap]);

  useEffect(() => {
    const markers = coords.map((cord) => ({ ...cord, stopPoint: true }));
    typeof onMountCoords === 'function' && onMountCoords(markers);
  }, [coords]);

  useEffect(() => {
    const allCoords = [];
    const allStops = [];
    const countAll = [];
    stopPointArr?.forEach((stop, index) => {
      const latitude = stop?.latitude ? stop?.latitude : stop?.lat;
      const longitude = stop?.longitude ? stop?.longitude : stop?.lng;
      const cord = { latitude, longitude };
      countAll.push(index);
      allStops.push(stop);
      allCoords.push(cord);
    });
    setStops([...allStops]);
    setCoords([...allCoords]);
    setIndexForAlphabet([...countAll]);
  }, [stopPointArr]);

  useEffect(() => {
    TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
    const myMap = new TrimbleMaps.Map({
      container: 'myMap',
      style: type ? TrimbleMaps.Common.Style[type] : TrimbleMaps.Common.Style.BASIC,
      center: new TrimbleMaps.LngLat(-96, 35),
      zoom: 3,
      dragRotate,
    });
    if (!!myMap && typeof setMapInstance === 'function') {
      setMapInstance(myMap);
      mapInstance = myMap;
    }
    myMap.on('load', onMapLoad);
    return () => {
      setMapIsLoaded(false);
      myMap.off('load', onMapLoad);
    };
  }, []);

  return (
    <div className={classNames('map_container_truck_history', classContainer)} style={{ ...styleContainer }}>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100%', width: '100%' }} />
        {children}
      </div>
    </div>
  );
};

export default TruckHistoryMap;
