import React from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { getOptions } from './Charts.data';

const MoneyChart = ({ monthsData }) => {
  const { currency } = useSelector((state) => state.root);

  const data = {
    labels: (monthsData || []).map((item) => `${item.month} ${item.year || ''}`),
    datasets: [
      {
        data: (monthsData || []).map((item) => item.amount),
        backgroundColor: palette.indigo400,
        hoverBackgroundColor: palette.indigo500,
        borderRadius: 4,
        barThickness: 52,
      },
    ],
  };

  const hasData = data.datasets?.[0].data?.some((el) => el > 0);

  return <Bar options={getOptions(currency, hasData)} data={data} />;
};

export default MoneyChart;
