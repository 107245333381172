import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { subYears } from 'date-fns';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import HeaderStar from 'components/HeaderStar';
import TableWrapper from 'components/TableWrapper';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { generatePDF } from 'pages/Reports/ReportDetails/AccidentRegister/generatePdf';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { getAccidents } from 'Api/AccidentsAndCrashes';
import { useColumns } from './AccidentRegister.data';
import { SHeaderWrapper, SPageWrapper } from './AccidentRegister.styles';

const AccidentRegister = () => {
  const { formatDate, formatDateTime, convertToCustomerTime } = useDateFormat();
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [accidentRegister, setAccidentRegister] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 50 });
  const [dateRange, setDateRange] = useState({
    start: subYears(new Date(), 3),
    end: new Date(),
  });

  const getAccidentRegister = async () => {
    try {
      setLoading(true);
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'filters[from_date]': dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        'filters[to_date]': dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        'filters[type]': 'Reportable',
        'sort[][collision_date_time]': 'desc',
      };

      const response = await getAccidents(params);
      setAccidentRegister(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const onPrintReport = async () => {
    try {
      setLoadingReport(true);
      const params = {
        page: 1,
        itemsPerPage: 10000,
        'filters[from_date]': dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        'filters[to_date]': dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        'filters[type]': 'Reportable',
        'sort[][collision_date_time]': 'desc',
      };

      const { data } = await getAccidents(params);

      const { url } = await generatePDF(data, false, dateRange, formatDate, formatDateTime, convertToCustomerTime);
      setPdfUrl(url);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    getAccidentRegister();
  }, [selectedFilters, dateRange]);

  const columns = useColumns();

  return (
    <SPageWrapper>
      <SHeaderWrapper>
        <HeaderStar title='Accident Register' />
      </SHeaderWrapper>
      <div>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <DateRangePicker
            top='-108px'
            initialRangeName='Last 3 Years'
            dateRange={dateRange}
            setDateRange={setDateRange}
            type='allTime'
          />
          <CustomButton
            type='secondary'
            title='Print Report'
            leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={onPrintReport}
            disabled={loading || loadingReport}
          />
        </div>
        {loading ? (
          <TablePreLoader styleWrapper={{ marginTop: 0 }} />
        ) : (
          <div className='tableFixHead table-fixed-header-300'>
            <TableWrapper
              data={accidentRegister?.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: palette.white }}
              components={{
                Pagination: () =>
                  Pagination({
                    accidentRegister,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                    rowsPerPageOptions: [25, 50, 100, 150],
                  }),
              }}
              columns={columns}
            />
          </div>
        )}
      </div>
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Accident Register' />
      )}
    </SPageWrapper>
  );
};

export default AccidentRegister;
