import React, { useMemo, useState } from 'react';
import Autocomplete from 'common/Autocomplete';

const ReportNumberAutocomplete = ({ value, options, onChange, onAddNew, error, ...props }) => {
  const [inputValue, setInputValue] = useState('');

  const updatedOptions = useMemo(() => {
    if (inputValue && options.every((item) => item !== inputValue)) {
      return [`+ Add "${inputValue}"`, ...options];
    }
    return options;
  }, [inputValue, options]);

  const handleChange = (e, val) => {
    if (val?.startsWith('+ Add ')) {
      onAddNew(inputValue);
      return;
    }

    onChange(e, val);
  };

  return (
    <Autocomplete
      name='report_number'
      options={updatedOptions}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(e, val) => setInputValue(val)}
      error={error}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {option}
        </li>
      )}
      {...props}
    />
  );
};

export default ReportNumberAutocomplete;
