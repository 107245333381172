export const getInitialValues = (settlement, balance) => {
  const paymentAmount = balance?.payment_amount;

  const isLessPayment = paymentAmount - Number(settlement.amount) > 0;
  const isExtraPayment = Number(settlement.amount) - paymentAmount > 0;

  const lessOrExtraPayment = Math.abs(paymentAmount - Number(settlement.amount));

  return {
    less_payment: isLessPayment ? lessOrExtraPayment : '',
    extra_payment: isExtraPayment ? lessOrExtraPayment : '',
    edit: false,
  };
};

export const getPaymentAmount = (amount, lessPayment, extraPayment) => {
  const extraPaymentAmount = Number(extraPayment);
  const lessPaymentAmount = Number(lessPayment);

  return Number(amount) - extraPaymentAmount + lessPaymentAmount;
};
