import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Chip from 'common/Chip';
import NoRecords from 'common/NoRecords';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import UserInfoRowAssignedTo from 'components/TableShipments/helpers/UserInfoRowAssignedTo';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { palette, TRUCK_TYPES } from 'utils/constants';
import { getEDILogs, updateLogAcknowledgement } from 'Api/CompanySettings';
import TableHeader from './components/TableHeader';
import { initialFilters, LOG_STATUS } from './EDILogs.data';
import { STable, STableRow } from './EDILogs.styles';

const EDILogs = ({ customerId }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [changeLog, setChangeLog] = useState({ data: [] });
  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const debouncedSearch = useDebounce(search, 500);
  const controller = useRef(new AbortController());
  const [loadingAcknowledgement, setLoadingAcknowledgement] = useState(false);

  const getLogData = async () => {
    try {
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const sortField = selectedFilters.sort?.field ? `sort[][${selectedFilters.sort?.field}]` : null;
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        query: debouncedSearch || undefined,
        customer_id: customerId || undefined,
        shipment_id: selectedFilters.shipment_id || undefined,
        status_ids: selectedFilters.statuses?.length ? selectedFilters.statuses.map((i) => i.id) : undefined,
        customer_ids: selectedFilters.customers?.length ? selectedFilters.customers.map((i) => i.id) : undefined,
        driver_ids: selectedFilters.drivers?.length ? selectedFilters.drivers.map((i) => i.id) : undefined,
        vehicle_ids: selectedFilters.vehicles?.length ? selectedFilters.vehicles.map((i) => i.id) : undefined,
        trailer_ids: selectedFilters.trailers?.length ? selectedFilters.trailers.map((i) => i.id) : undefined,
        from: selectedFilters.range[0] ? moment(selectedFilters.range[0]).format('YYYY-MM-DD') : undefined,
        to: selectedFilters.range[1] ? moment(selectedFilters.range[1]).format('YYYY-MM-DD') : undefined,
        acknowledged:
          selectedFilters.acknowledged === 'Yes' ? 1 : selectedFilters.acknowledged === 'No' ? 0 : undefined,
        [sortField]: sortField ? selectedFilters.sort?.sortBy : undefined,
      };

      const response = await getEDILogs(params, controller.current?.signal);
      setChangeLog(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const updateAcknowledged = async (checked, id) => {
    if (loadingAcknowledgement) {
      return;
    }

    try {
      setLoadingAcknowledgement(true);
      const { data } = await updateLogAcknowledgement(id, { acknowledged: checked ? 1 : 0 });
      setChangeLog((prevState) => ({
        ...prevState,
        data: prevState.data.map((item) => (item.id === id ? data : item)),
      }));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
      getLogData();
    } finally {
      setLoadingAcknowledgement(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getLogData();
  }, [selectedFilters, debouncedSearch]);

  return (
    <div>
      <TableHeader
        search={search}
        setSearch={setSearch}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        loading={loading}
      />
      <div className='mt-4 overflow-auto'>
        {loading ? (
          <TableSkeleton />
        ) : (
          <STable>
            <thead>
              <tr>
                <th>DATE</th>
                <th>SHIPMENT ID</th>
                <th>VEHICLE ID</th>
                <th>TRAILER ID</th>
                <th>DRIVER</th>
                <th>CUSTOMER</th>
                <th>STATUS</th>
                <th>EVENT</th>
                <th>EVENT DESCRIPTION</th>
                <th>ACKNOWLEDGED</th>
                <th>ACKNOWLEDGED BY</th>
              </tr>
            </thead>
            <tbody>
              {changeLog.data.map((item) => (
                <STableRow key={item.id}>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {convertToCustomerTime(item.created_at)}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      variant='b2'
                      style={{ color: palette.gray700 }}
                      className={item.shipment?.shipment_id ? 'pointer' : ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.shipment?.shipment_id) {
                          navigate(`/shipment/${item.shipment?.shipment_id}`);
                        }
                      }}
                    >
                      {item.shipment_id}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      variant='b2'
                      style={{ color: palette.gray700 }}
                      className={item.shipment?.equipment?.id ? 'pointer' : ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.shipment?.equipment?.id) {
                          navigate(`/equipment-profile/vehicle/${item.shipment?.equipment?.id}`);
                        }
                      }}
                    >
                      {item.shipment?.equipment?.equipment_id} {item.shipment?.equipment?.equipment_type?.title}
                      {!!item.shipment?.equipment?.truck_type &&
                        ` (${TRUCK_TYPES[item.shipment?.equipment?.truck_type]})`}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      variant='b2'
                      style={{ color: palette.gray700 }}
                      className={item.shipment?.equipment?.hooked_to?.id ? 'pointer' : ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.shipment?.equipment?.hooked_to?.id) {
                          navigate(`/equipment-profile/trailer/${item.shipment.equipment.hooked_to.id}`);
                        }
                      }}
                    >
                      {!item.shipment?.equipment?.hooked_to && '-'}
                      {item.shipment?.equipment?.hooked_to?.equipment_id}{' '}
                      {item.shipment?.equipment?.hooked_to?.length?.length}&nbsp;
                      {item.shipment?.equipment?.hooked_to?.length?.unit}{' '}
                      {item.shipment?.equipment?.hooked_to?.equipment_type?.title}
                    </Typography>
                  </td>
                  <td>
                    <div>
                      {item.shipment?.assigned_to1 || item.shipment?.brokerage_dispatch ? (
                        <UserInfoRowAssignedTo data={item.shipment} />
                      ) : (
                        '-'
                      )}
                    </div>
                  </td>
                  <td>
                    <Typography
                      variant='b2'
                      style={{ color: palette.gray700 }}
                      className={item?.shipment?.shipment_billing?.[0]?.billing_customer?.id ? 'pointer' : ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item?.shipment?.shipment_billing?.[0]?.billing_customer?.id) {
                          navigate(`/customer-profile/${item.shipment.shipment_billing[0].billing_customer.id}`);
                        }
                      }}
                    >
                      {item.shipment?.shipment_billing?.[0]?.billing_customer?.company_name || '-'}
                    </Typography>
                  </td>
                  <td>
                    <Chip label={LOG_STATUS[item.status_id]?.toUpperCase()} fontSize='11px' fontWeight={500} />
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.type?.title}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.event_description}
                    </Typography>
                  </td>
                  <td>
                    <div style={{ marginLeft: '32px' }}>
                      <CustomCheckbox
                        type='switch'
                        name='acknowledged'
                        checked={item.acknowledged}
                        onChange={(checked) => updateAcknowledged(checked, item.id)}
                        smail={false}
                      />
                    </div>
                  </td>
                  <td>
                    {!!item.acknowledged && !!item.acknowledged_by ? (
                      <div className='d-flex flex-column'>
                        <Typography variant='b2' style={{ color: palette.gray700 }}>
                          {item.acknowledged_by?.first_name} {item.acknowledged_by?.last_name}
                        </Typography>
                        {!!item.acknowledged_date && (
                          <Typography variant='b2' style={{ color: palette.gray700 }}>
                            {convertToCustomerTime(item.acknowledged_date)}
                          </Typography>
                        )}
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                </STableRow>
              ))}
            </tbody>
          </STable>
        )}
      </div>
      {!loading && !changeLog.data?.length && <NoRecords />}
      <Pagination
        data={changeLog}
        rowPerPage={selectedFilters.itemsPerPage}
        onPageChange={onPageChange}
        onChangeRowPerPage={onChangeRowPerPage}
        rowsPerPageOptions={[25, 50, 100, 150]}
      />
    </div>
  );
};

export default EDILogs;
