import React, { useRef } from 'react';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import MultiDropdownItem from './MultiDropdownItem';
import styles from './MultiDropdown.module.css';

const MultiDropdown = ({ submenus, dropdown, depthLevel }) => {
  const { use } = useTheme();
  depthLevel += 1;
  const dropdownClass = depthLevel > 1 ? styles['dropdown-submenu'] : '';
  const ref = useRef();

  return (
    <ul
      className={`${styles.dropdown} ${dropdownClass} 
                        ${dropdown ? styles.show : ''}
                        ${depthLevel > 1 ? styles.multiDropDown : ''}`}
      style={{ backgroundColor: use(palette.white, palette.dark800) }}
      ref={ref}
    >
      {submenus.map((data, index) => {
        return <MultiDropdownItem data={data} key={data.id || index} depthLevel={depthLevel} />;
      })}
    </ul>
  );
};

export default MultiDropdown;
