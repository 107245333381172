export const cardStatuses = [
  { title: 'Active', id: 1 },
  { title: 'Inactive', id: 2 },
  { title: 'Lost/Stolen', id: 3 },
];

export const serviceProviders = [
  { title: 'WEX EFS Fleet Card', id: 44 },
  { title: 'Truckin Digital Cash Card', id: 222 }, // Update id when ready
];
