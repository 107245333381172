import React from 'react';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { SInnerSection } from 'componentsV2/Customer/EdiApi/EdiApi.styles';

const IncomingSettings = ({ form, index, readOnly }) => {
  const { values, handleChange } = form;

  return (
    <SInnerSection>
      <div className='inner-section-header'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Incoming Settings
        </Typography>
      </div>
      <div className='setting-options-container'>
        <div className='setting-option'>
          <CustomCheckbox
            type='switch'
            name={`${index}.auto_approve_all_incoming_shipments`}
            checked={!!values[index].auto_approve_all_incoming_shipments}
            onChange={(checked) => handleChange(`${index}.auto_approve_all_incoming_shipments`, checked)}
            smail={false}
            disabled={readOnly}
          />
          <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
            Auto approve all incoming shipments
          </Typography>
        </div>
      </div>
    </SInnerSection>
  );
};

export default IncomingSettings;
