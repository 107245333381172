import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { Typography } from 'components/Typography';
import Loader, { BackdropLoader } from 'common/Loader';
import { formatNumber, palette } from 'utils/constants';
import { getBill, getPastDueBills } from 'Api/Payables';
import AddBill from 'pages/Accounting/Payables/components/AddBill';
import { SBoxWrapper } from '../../StatsSection.styles';
import { SListWrapper } from './PastDueBills.styles';

const PastDueBills = ({ onTitleClick }) => {
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [pastDueBills, setPastDueBills] = useState(null);
  const [billToEdit, setBillToEdit] = useState(null);
  const [loadingBill, setLoadingBill] = useState(false);
  const { total, totalAmount, data } = pastDueBills || {};

  const getPastDueBillsData = async () => {
    setLoading(true);
    try {
      const response = await getPastDueBills({ itemsPerPage: 1000 });
      const totalAmount = response?.data.reduce((acc, cur) => {
        acc += Number(cur.amount_due);
        return acc;
      }, 0);
      setPastDueBills({ ...response, totalAmount });
    } finally {
      setLoading(false);
    }
  };

  const getBillDetails = async (id) => {
    setLoadingBill(true);
    try {
      const { data } = await getBill(id);
      setBillToEdit(data || null);
    } finally {
      setLoadingBill(false);
    }
  };

  useEffect(() => {
    getPastDueBillsData();
  }, []);

  return (
    <SBoxWrapper $minWidth='300px'>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <span className='d-inline-flex gap-1 pointer' onClick={onTitleClick}>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            PAST DUE BILLS
          </Typography>
          <ArrowUp fill={palette.gray400} style={{ transform: 'rotate(90deg)' }} />
        </span>
      </div>
      <div className='d-flex justify-content-between'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <div>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {total} Bills
            </Typography>
          </div>
        )}
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <div>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {currency}
              {formatNumber(totalAmount)}
            </Typography>
          </div>
        )}
      </div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <SListWrapper>
          {data?.map((item) => (
            <div key={item.id} className='list-item' onClick={() => getBillDetails(item.id)}>
              <Typography variant='b2' style={{ color: palette.gray900 }}>
                {item.reference_id}
              </Typography>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {currency}
                {formatNumber(item.amount_due)}
              </Typography>
            </div>
          ))}
        </SListWrapper>
      )}
      {!!billToEdit && (
        <AddBill
          open={!!billToEdit}
          onClose={() => setBillToEdit(null)}
          onSuccess={getPastDueBillsData}
          bill={billToEdit}
        />
      )}
      <BackdropLoader loading={loadingBill} />
    </SBoxWrapper>
  );
};

export default PastDueBills;
