import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPlannerMatch = styled.div`
  display: flex;
  column-gap: 8px;

  .table-wrapper {
    flex: 1;
    height: calc(100vh - 332px);
    overflow: auto;

    ::-webkit-scrollbar {
      width: 6px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${palette.gray300};
      border-radius: 8px;
    }
  }

  .matched-shipments-wrapper {
    flex: 1;
    height: calc(100vh - 332px);
  }

  .matched-shipments {
    position: relative;
    height: calc(100% - 24px);
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;
    border: 1px solid ${palette.gray50};
    background-color: ${palette.indigo0};
    padding: 18px 0 0;

    .matched-shipment {
      padding: 6px 26px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:nth-child(even) {
        background-color: ${palette.gray0};
      }

      &:hover {
        background-color: ${palette.white};
      }

      &.selected {
        background-color: ${palette.white};
      }
    }
  }

  .matched-shipments-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
    padding: 18px 12px;
    background-color: ${palette.indigo0};
    border-top: 1px solid ${palette.gray100};
  }
`;
