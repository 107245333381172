import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .MuiTable-root {
    .MuiTableCell-root:first-child {
      width: 48px !important;
      display: ${({ $showCheckboxes }) => (!$showCheckboxes ? 'none' : '')};
    }
  }

  .bulk-resolve-wrapper {
    margin: 14px 0;
    height: 0;
    transition: 0.3s;
    opacity: 0;

    &.button-visible {
      height: 33px;
      opacity: 1;
    }
  }

  .tabs-wrapper {
    display: flex;
    align-items: baseline;
    gap: 16px;

    .add-tab-btn {
      width: 14px;
      height: 14px;
      cursor: pointer;
      transform: scale(0);
      transition: transform 0.3s ease;
    }

    :hover {
      .add-tab-btn {
        transform: scale(1);
      }
    }
  }
`;

export const SSearchWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .delete-icon {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }
`;

export const SDatePicker = styled(DatePicker)`
  width: 250px;
  font-size: 14px;
  box-shadow: ${palette.boxShadow2};
  border-radius: 0 6px 6px 0;
  padding: 5px 12px 6px;
`;

export const SImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .figure {
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-top: 8px;
  }

  .image {
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
`;
