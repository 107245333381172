export const vendorsListConverter = (data) => {
  const result = [];
  for (const vendor in data) {
    if (data.hasOwnProperty(vendor)) {
      result.push({
        vendor,
        current: data[vendor].Current || { count: 0, total_amount: 0 },
        '1-30': data[vendor]['1-30'] || { count: 0, total_amount: 0 },
        '31-60': data[vendor]['31-60'] || { count: 0, total_amount: 0 },
        '61-90': data[vendor]['61-90'] || { count: 0, total_amount: 0 },
        '91+': data[vendor]['91+'] || { count: 0, total_amount: 0 },
        total:
          (data[vendor].Current?.total_amount || 0) +
          (data[vendor]['1-30']?.total_amount || 0) +
          (data[vendor]['31-60']?.total_amount || 0) +
          (data[vendor]['61-90']?.total_amount || 0) +
          (data[vendor]['91+']?.total_amount || 0),
      });
    }
  }
  return result;
};
