export const updateFavorite = (sub_category, categories) => {
  return categories.map((category) => {
    if (sub_category.category_id === category.id) {
      const newSubcategories = category.sub_category.map((sub) => {
        if (sub_category.id === sub.id) {
          return { ...sub, is_favourite: sub.is_favourite ? 0 : 1 };
        }
        return sub;
      });
      return { ...category, sub_category: newSubcategories };
    }
    return category;
  });
};
