import { getCustomerInfo } from 'Api/JobPositions';
import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 8, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Accident Date/Time', 8, top);
  doc.text('Location of Accident', 60, top, { align: 'center' });
  doc.text('No. of Deaths', 95, top, { align: 'center' });
  doc.text('No. of Non-Fatal Injuries', 125, top, { align: 'center' });
  doc.text('H/M', 150, top, { align: 'center' });
  doc.text('Driver', 170, top, { align: 'center' });
  doc.text('Report Number', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createTableRow = (doc, data, top, formatDateTime) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${data.collision_date_time ? formatDateTime(data.collision_date_time) : '-'}`, 8, top);
  doc.text(`${data.accident_location || '-'}`, 60, top, { align: 'center' });
  doc.text(`${data.fatalities || '0'}`, 95, top, { align: 'center' });
  doc.text(`${data.injuries || '0'}`, 125, top, { align: 'center' });
  doc.text(`${data.hazmat ? 'Yes' : 'No'}`, 150, top, { align: 'center' });
  doc.text(`${data.driver ? `${data.driver?.fname} ${data?.driver?.lname}` : '-'}`, 170, top, { align: 'center' });
  doc.text(`${data.report_number || '-'}`, pageWidth - 8, top, { align: 'right' });
};

const createTableRows = (doc, records, formatDateTime) => {
  if (!records?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  records.forEach((record, index) => {
    createTableRow(doc, record, top + index * 5, formatDateTime);
  });

  return top + records.length * 5;
};

export const generatePDF = async (
  accidentRegister,
  download,
  dateRange,
  formatDate,
  formatDateTime,
  convertToCustomerTime
) => {
  if (!accidentRegister?.length) {
    return;
  }

  const { start, end } = dateRange;

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text('Accident Register', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      28,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, 28, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const firstPageRecords = accidentRegister.slice(0, 40);
  const restRecords = accidentRegister.slice(40);

  createTableRows(doc, firstPageRecords, formatDateTime);

  for (let i = 0; i < (restRecords?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageRecords = restRecords.slice(i * 45, (i + 1) * 45);
    createTableRows(doc, currentPageRecords, formatDateTime);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc);
  }

  doc.setDocumentProperties({
    title: `Accident Register - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}`,
  });

  const url = doc.output('datauristring', { filename: 'Accident Register' });
  const blob = doc.output('blob');

  if (download) {
    doc.save(`Accident Register - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}.pdf`);
  }
  return { blob, url };
};
