export const getDriverCompensationConverter = (data) => {
  return {
    driver_dispute: !!data.driver_dispute,
    driver_dispute_days: data.driver_dispute_days,
    default_pay_raise: !!data.default_pay_raise,
    pay_raise_per_mile_loaded: data.pay_raise_per_mile_loaded || '',
    pay_raise_per_mile_empty: data.pay_raise_per_mile_empty || '',
    pay_raise_per_mile_days: data.pay_raise_per_mile_days || '',
    pay_raise_per_mile_months: data.pay_raise_per_mile_months || '',
    pay_raise_per_mile_years: data.pay_raise_per_mile_years || '',
    pay_raise_percentage: data.pay_raise_percentage || '',
    pay_raise_percentage_days: data.pay_raise_percentage_days || '',
    pay_raise_percentage_months: data.pay_raise_percentage_months || '',
    pay_raise_percentage_years: data.pay_raise_percentage_years || '',
    pay_raise_per_hour: data.pay_raise_per_hour || '',
    pay_raise_per_hour_days: data.pay_raise_per_hour_days || '',
    pay_raise_per_hour_months: data.pay_raise_per_hour_months || '',
    pay_raise_per_hour_years: data.pay_raise_per_hour_years || '',
    pay_per_hour_breaks_required: !!data.pay_per_hour_breaks_required,
    deduct_break_time: !!data.deduct_break_time,
    pay_per_hour_break_time_paid: !!data.pay_per_hour_break_time_paid,
    pay_per_hour_break_time_allowed: data.pay_per_hour_break_time_allowed || '',
    pay_per_hour_breaks_allowed_in_given_days: data.pay_per_hour_breaks_allowed_in_given_days || '',
    detention_pay_per_hour: data.detention_pay_per_hour || '',
    detention_pay_after_hours_passed: data.detention_pay_after_hours_passed || '',
    each_layover_pay: data.each_layover_pay || '',
    stop_off_pay_each_stop: data.stop_off_pay_each_stop || '',
    stop_off_pay_after_stop: data.stop_off_pay_after_stop || '',
    live_unload_pay: data.live_unload_pay || '',
    track_order_not_used_pay: data.track_order_not_used_pay || '',
    break_down_pay: data.break_down_pay || '',
    redelivery_pay: data.redelivery_pay || '',
    tarp_pay: data.tarp_pay || '',
    detention: !!data.detention_pay_per_hour && !!data.detention_pay_after_hours_passed,
    layover: !!data.each_layover_pay,
    stop_off: !!data.stop_off_pay_each_stop && !!data.stop_off_pay_after_stop,
    live_upload: !!data.live_unload_pay,
    truck_order_not_used: !!data.track_order_not_used_pay,
    break_down: !!data.break_down_pay,
    redelivery: !!data.redelivery_pay,
    tarp: !!data.tarp_pay,
    paid_time_off_required: !!data.paid_time_off_required,
    paid_time_off_per_day: data.paid_time_off_per_day || '',
    pay_on_each_shipment: !!data.pay_on_each_shipment || false,
    shipment_addition:
      data.pay_on_each_shipment && data.shipment_additions?.length
        ? data.shipment_additions.map((el) => ({
            amount: el.amount,
            payroll_addition: { id: el.payroll_addition.id, type: el.payroll_addition.addition_type },
          }))
        : [
            {
              payroll_addition: null,
              amount: '',
            },
          ],
  };
};

export const updateDriverCompensationConverter = (data) => {
  return {
    driver_dispute: data.driver_dispute ? 1 : 0,
    driver_dispute_days: data.driver_dispute_days,
    default_pay_raise: data.default_pay_raise ? 1 : 0,
    pay_raise_per_mile_loaded: data.pay_raise_per_mile_loaded ? Number(data.pay_raise_per_mile_loaded) : null,
    pay_raise_per_mile_empty: data.pay_raise_per_mile_empty ? Number(data.pay_raise_per_mile_empty) : null,
    pay_raise_per_mile_days: data.pay_raise_per_mile_days ? Number(data.pay_raise_per_mile_days) : null,
    pay_raise_per_mile_months: data.pay_raise_per_mile_months ? Number(data.pay_raise_per_mile_months) : null,
    pay_raise_per_mile_years: data.pay_raise_per_mile_years ? Number(data.pay_raise_per_mile_years) : null,
    pay_raise_percentage: data.pay_raise_percentage ? Number(data.pay_raise_percentage) : null,
    pay_raise_percentage_days: data.pay_raise_percentage_days ? Number(data.pay_raise_percentage_days) : null,
    pay_raise_percentage_months: data.pay_raise_percentage_months ? Number(data.pay_raise_percentage_months) : null,
    pay_raise_percentage_years: data.pay_raise_percentage_years ? Number(data.pay_raise_percentage_years) : null,
    pay_raise_per_hour: data.pay_raise_per_hour ? Number(data.pay_raise_per_hour) : null,
    pay_raise_per_hour_days: data.pay_raise_per_hour_days ? Number(data.pay_raise_per_hour_days) : null,
    pay_raise_per_hour_months: data.pay_raise_per_hour_months ? Number(data.pay_raise_per_hour_months) : null,
    pay_raise_per_hour_years: data.pay_raise_per_hour_years ? Number(data.pay_raise_per_hour_years) : null,
    pay_per_hour_breaks_required: data.pay_per_hour_breaks_required ? 1 : 0,
    deduct_break_time: data.deduct_break_time ? 1 : 0,
    pay_per_hour_break_time_paid: data.pay_per_hour_break_time_paid ? 1 : 0,
    pay_per_hour_break_time_allowed: data.pay_per_hour_break_time_allowed
      ? Number(data.pay_per_hour_break_time_allowed)
      : null,
    pay_per_hour_breaks_allowed_in_given_days: data.pay_per_hour_breaks_allowed_in_given_days
      ? Number(data.pay_per_hour_breaks_allowed_in_given_days)
      : null,
    detention_pay_per_hour: data.detention ? data.detention_pay_per_hour : null,
    detention_pay_after_hours_passed: data.detention ? data.detention_pay_after_hours_passed : null,
    each_layover_pay: data.layover ? data.each_layover_pay : null,
    stop_off_pay_each_stop: data.stop_off ? data.stop_off_pay_each_stop : null,
    stop_off_pay_after_stop: data.stop_off ? data.stop_off_pay_after_stop : null,
    live_unload_pay: data.live_upload ? data.live_unload_pay : null,
    track_order_not_used_pay: data.truck_order_not_used ? data.track_order_not_used_pay : null,
    break_down_pay: data.break_down ? data.break_down_pay : null,
    redelivery_pay: data.redelivery ? data.redelivery_pay : null,
    tarp_pay: data.tarp ? data.tarp_pay : null,
    paid_time_off_required: data.paid_time_off_required ? 1 : 0,
    paid_time_off_per_day: data.paid_time_off_per_day ? Number(data.paid_time_off_per_day) : 0,
    pay_on_each_shipment: data.pay_on_each_shipment ? 1 : 0,
    shipment_addition:
      data.pay_on_each_shipment && data.shipment_addition?.length
        ? data.shipment_addition.map((el) => ({
            amount: el.amount,
            payroll_addition_id: el.payroll_addition.id,
          }))
        : [],
  };
};
