import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${palette.gray0};
  padding: 8px;
`;

export const SBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const SFooter = styled.div`
  min-height: 50px;
  padding: 10px 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.gray0};
  backdrop-filter: blur(4px);

  .gray-text {
    color: ${palette.gray700};
  }

  .blue-link {
    color: ${palette.indigo500};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    padding: 10px 24px;
  }
`;
