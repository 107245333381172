import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import PhoneInput from 'react-phone-number-input';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import useForm from 'hooks/useForm';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import AddressFields from 'common/AddressFields';
import AddGroup from 'componentsV2/Group/AddGroup';
import CustomMultiSelect from 'common/CustomMultiSelect/CustomMultiSelect';
import { getErrorMessage } from 'utils/error';
import { getAccountsList } from 'Api/Accounts';
import { getDriverGroups } from 'Api/DriverV2';
import { createCustomer, updateCustomer } from 'Api/Customers';
import { validationBasicSchema } from './ValidationSchema';

const AddCustomerStep1 = ({ nextStep, Cancel, showError, basicDetails = {}, customer }) => {
  const { use } = useTheme();
  const [onNextloading, setOnNextloading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [openAddGroup, setOpenAddGroup] = useState(false);

  const {
    telephone_number,
    company_name,
    customer_id,
    general_ledger_id,
    company_dot_mc,
    address,
    address2,
    country,
    state,
    city,
    zipcode,
    account,
    group_id,
  } = basicDetails || {};

  const onNextStep = (values) => {
    const { address, country, state, city, account, group_id } = values;
    const customerInfo = {
      ...values,
      address1: address,
      country_id: country?.id,
      state_id: state?.id,
      city_id: city?.id,
      status_id: 2,
      account_id: account?.id,
      group_id: group_id.map((item) => item.id),
    };
    setOnNextloading(true);
    if (!customer.basicDetails.customer) {
      createCustomer(customerInfo)
        .then((res) => {
          setOnNextloading(false);
          nextStep({ formikData: { ...values, customer: res.data.id } }, `Customer has been created successfully`);
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      const patchData = Object.keys(customerInfo).filter((item) => !!customerInfo[item]);
      updateCustomer(customer.basicDetails.customer, patchData)
        .then((res) => {
          setOnNextloading(false);
          nextStep({ formikData: { ...values, customer: res.data[0].id } }, `Customer has been updated successfully`);
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  const { values, handleChange, handleBlur, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      company_name: company_name || '',
      customer_id: customer_id || '',
      general_ledger_id: general_ledger_id || undefined,
      telephone_number: telephone_number || '',
      company_dot_mc: company_dot_mc || '',
      address: address || '',
      address2: address2 || '',
      country: country || null,
      state: state || null,
      city: city || null,
      zipcode: zipcode || '',
      group_id: group_id || [],
      account: account || accounts?.find((account) => account?.account_name === 'Transportation Revenue') || null,
    },
    onSubmit: onNextStep,
    validationSchema: validationBasicSchema,
    enableReinitialize: true,
  });
  const whitelist = ['US', 'CA', 'MX'];

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => i.account_type.id === ACCOUNT_TYPE.INCOME));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getAccounts();
    getGroups();
  }, []);

  const getGroups = () => {
    getDriverGroups().then((res) => {
      setGroups(res.data);
    });
  };

  const saveGroup = (val) => {
    setGroups([...groups, val]);
  };
  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className='basic-container driver-style-wrap'>
          <div className='d-flex gap-4'>
            <div>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Custom ID
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name company-name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='customer_id'
                    id='customer_id'
                    className='input-field'
                    placeholder='Custom ID'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customer_id}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                <ErrorMessage errors={touchedErrors.customer_id} />
              </div>
            </div>
            <div>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                General Ledger ID
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name company-name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='general_ledger_id'
                    id='general_ledger_id'
                    className='input-field'
                    placeholder='System ID'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.general_ledger_id}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                <ErrorMessage errors={touchedErrors.general_ledger_id} />
              </div>
            </div>
            <div>
              <Autocomplete
                required
                width='252px'
                label='Account'
                name='account'
                labelKey='account_name'
                options={accounts}
                value={values.account}
                onChange={(e, val) => handleChange('account', val)}
                disableClearable={false}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>
          </div>
          <div className='d-flex gap-4' style={{ marginTop: '20px' }}>
            <div>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Company Name <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name company-name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='company_name'
                    id='company_name'
                    className='input-field'
                    placeholder='Company Name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company_name}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                <ErrorMessage errors={touchedErrors.company_name} />
              </div>
            </div>
            <div>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Phone Number <span className='required'>*</span>
              </p>
              <div
                className='phone-number'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <PhoneInput
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  addInternationalOption={false}
                  className='input-field-phone'
                  value={values.telephone_number}
                  countries={whitelist}
                  onChange={(val) => handleChange('telephone_number', val)}
                  defaultCountry='US'
                  placeholder='(555) 555-1234'
                />
              </div>
              <ErrorMessage errors={touchedErrors.telephone_number} />
            </div>
            <div>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                DOT
              </p>
              <div
                className='selector-name email-selector-owner'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  name='company_dot_mc'
                  id='company_dot_mc'
                  className='input-field'
                  placeholder='Optional'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company_dot_mc}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
            </div>
          </div>
          <div className='mt-4' style={{ width: '800px' }}>
            <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} isOneRow />
          </div>

          <div className='d-flex flex-column mt-4'>
            <div
              className='d-flex mt-4 justify-content-between align-items-center'
              style={{ marginTop: '4px', width: '300px' }}
            >
              <p
                style={{
                  color: use(palette.gray700, palette.gray200),
                }}
                className='text-style'
              >
                Add Tag(s)
              </p>
              <p
                className='text-style choose-file'
                style={{
                  color: use(palette.gray700, palette.gray200),
                }}
                onClick={() => setOpenAddGroup(true)}
              >
                <PlusIcon fill={palette.indigo500} /> New Tags
              </p>
            </div>
            <div style={{ marginRight: '12px' }}>
              <div className='custom-select-wrap-contain-sub'>
                <CustomMultiSelect
                  placeholder='Select tags'
                  selectedValues={values.group_id}
                  formik={{ setFiledValue: handleChange }}
                  patchProperty='group_id'
                  propertyName='group_name'
                  options={groups}
                />
              </div>
              <div style={{ marginRight: '12px' }} className='d-flex justify-content-center align-items-center' />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Button type='button' className='cancel-button' onClick={Cancel}>
          Cancel
        </Button>
        <div className='Basic-detail-footer-btn'>
          {onNextloading ? (
            <div className='ms-2'>
              <CircularProgress size={30} />
            </div>
          ) : (
            <button type='button' onClick={handleSubmit} className='next-step-btn'>
              Next Step
            </button>
          )}
        </div>
      </Modal.Footer>
      {!!openAddGroup && <AddGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={saveGroup} />}
    </Form>
  );
};

export default AddCustomerStep1;
