import React from 'react';
import { Icons } from 'assets/icons';
import Phone_Hardware from 'assets/icons/equipment/Phone_Hardware.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import DeviceNickname from './DeviceNickname';

export const filterTableTop = [
  { key: 'all', label: `all`, value: 1 },
  { key: 'equipment', label: `Equipment Device`, value: 2 },
  { key: 'driver', label: `Driver Device`, value: 3 },
];

export const useHardwareColumns = ({ tab, sort, sortingQuery, onNicknameUpdateSuccess }) => {
  return [
    {
      field: 'device_id',
      title: <ColumnHeader title='DEVICE ID' field='device_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            <p
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
              }}
            >
              {rowData?.device_id ? rowData?.device_id : ''}
            </p>
          </div>
        );
      },
    },
    {
      field: 'hardware',
      title: <ColumnHeader title='HARDWARE' field='hardware' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: '200px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={rowData?.image === 'Phone_Hardware' ? Phone_Hardware : rowData?.image}
              alt=''
              style={{ width: '50px', height: '50px' }}
            />{' '}
            <p>
              <span className='ms-2'>{rowData?.hardware}</span>
            </p>
          </div>
        );
      },
    },
    ...(tab === 3
      ? [
          {
            field: 'nickname',
            title: <Typography variant='c3'>DEVICE NAME</Typography>,
            render: (rowData) => {
              return (
                <div>
                  <DeviceNickname rowData={rowData} onSuccess={onNicknameUpdateSuccess} />
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: 'hardware_make',
      title: <ColumnHeader title='HARDWARE MAKE' field='hardware_make' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            <p> {rowData?.hardware_make ? rowData?.hardware_make : ''}</p>
          </div>
        );
      },
    },
    {
      field: 'hardware_model',
      title: <ColumnHeader title='HARDWARE MODEL' field='hardware_model' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            <p> {rowData?.hardware_model ? rowData?.hardware_model : ''}</p>
          </div>
        );
      },
    },
    {
      field: 'serial',
      title: <ColumnHeader title='SERIAL' field='serial' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            <p>{rowData?.serial ? rowData?.serial : '-'}</p>
          </div>
        );
      },
    },
    {
      field: 'added_on',
      title: <ColumnHeader title='ADDED DATE' field='added_on' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            <p>{rowData?.added_on ? rowData?.added_on : '-'}</p>
          </div>
        );
      },
    },

    {
      field: 'assigned_to',
      title: <ColumnHeader title='ASSIGNED TO' field='assigned_to' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            <p>{rowData?.assignTo ? rowData?.assignTo : '-'}</p>
          </div>
        );
      },
    },
    {
      field: 'location',
      title: <ColumnHeader title='LOCATION' field='location' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div style={{ width: '150px', whiteSpace: 'normal' }}>
            <p>{rowData?.location ? rowData?.location : '-'}</p>
          </div>
        );
      },
    },
    {
      field: 'signal',
      title: <ColumnHeader title='SIGNAL' field='signal' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            <span>
              {rowData?.signal === 'poor' ? (
                <div className='equipment-signalstrenth  me-1' style={{ width: '36px' }}>
                  <img src={Icons.PoorSignal} alt='' style={{ marginTop: '-8px' }} />
                </div>
              ) : rowData?.signal === 'good' ? (
                <div className='equipment-profile-signalstrenth ' style={{ width: '36px' }}>
                  <img src={Icons.GoodSignal} alt='' style={{ marginTop: '-8px' }} />
                </div>
              ) : rowData?.signal === 'great' ? (
                <div className='equipment-signalstrenth  ' style={{ width: '36px' }}>
                  <img src={Icons.StrongSignal} alt='' style={{ marginTop: '-8px' }} />
                </div>
              ) : (
                '-'
              )}
            </span>
          </div>
        );
      },
    },
    {
      field: 'battery',
      title: <ColumnHeader title='BATTERY' field='battery' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        return (
          <div>
            {rowData?.battery ? (
              <div className='Battery'>
                <img
                  src={
                    Number(rowData?.battery) > 0 && Number(rowData?.battery) <= 25
                      ? Icons.LowBattery
                      : Number(rowData?.battery) > 25 && Number(rowData?.battery) <= 79
                      ? Icons.MediumBattery
                      : Icons.Battery
                  }
                  alt=''
                />
                <span className='equipment-profile-connected-hardware'>{rowData?.battery} %</span>
              </div>
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
  ];
};
