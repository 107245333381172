import { number, object } from 'yup';

export const validationSchema = object().shape({
  dispatch_driver_hours_before_scheduled: number().when('is_dispatch_driver_hours_before_scheduled', {
    is: (is_dispatch_driver_hours_before_scheduled) => !!is_dispatch_driver_hours_before_scheduled,
    then: number().required('Required'),
  }),
  send_driver_reminder: number().when('is_send_driver_reminder', {
    is: (is_send_driver_reminder) => !!is_send_driver_reminder,
    then: number().required('Required'),
  }),
});
