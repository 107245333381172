import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomTextArea from 'components/CustomTextArea';
import { DatePicker, TimePicker } from 'common/Pickers';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getAllTrailers } from 'Api/Map';
import { createQuickEquipment } from 'Api/Shipment';
import { GetEquipmentTrailer } from 'Api/StopPoint';
import {
  EQUIPMENT_ACTION_WAYPOINT,
  SCHEDULED_DATE_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import sm from 'components/CreateShipment/ShipmentStops/AddStopPoint/AddStopPoint.module.css';
import CustomInput from '../../CreateShipment/helpers/CustomInput';
import classes from '../addEditModalStops.module.css';
import { shouldUpdateStops } from '../validates';

const WayPoint = ({
  stop,
  errors,
  touched,
  stopPoints,
  setEquipmentId,
  currentStopAddValue,
  onChangeAddStopPointModal,
  getCustomerStopPoint,
  editMood,
  isShipmentTable,
  equipmentRequired,
  equipmentLengthData,
  setFieldValue,
  isSplit,
  shipmentSettings,
  setFieldTouched,
  stopInfo,
}) => {
  const ref = useRef(null);
  const showToaster = useShowToaster();
  const controller = new AbortController();
  const [localEquipmentIdData, setLocalEquipmentIdData] = useState([]);
  const [droppedTrailerIds, setDroppedTrailerIds] = useState([]);
  const [thirdPartyLoading, setThirdPartyLoading] = useState(false);

  const [customTrailerId, setCustomTrailerId] = useState(null);
  const [customTrailerType, setCustomTrailerType] = useState(null);
  const [customTrailerLength, setCustomTrailerLength] = useState(null);

  const [thirdParty, setThirdParty] = useState(false);
  const [toBeDetermined, setToBeDetermined] = useState(false);

  const onChangeTimeValidate = (date, field) => {
    setFieldValue(field, date);

    if (field === 'scheduled_date') {
      const startDate = moment(date);
      const endDate = moment(stop.scheduled_date_to);
      if (endDate.isBefore(startDate)) {
        setFieldValue(`scheduled_date_to`, startDate.toDate());
      }
    }

    if (field === 'scheduled_date_to') {
      setFieldValue(`to`, '');
      setFieldTouched(`to`, false);
    } else {
      setFieldValue(`from`, '');
      setFieldTouched(`from`, false);
    }
  };

  const onChangeStopPointId = (key, first = false) => {
    const selectedStopPointId = stopPoints.find((el) => +el.key === +key);
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      !first && onChangeTimeValidate(date, 'scheduled_date');
    }
    if (selectedStopPointId?.working_hour_by) {
      setFieldValue(`scheduled_type`, selectedStopPointId?.working_hour_by?.toString());
      onChangeScheduledType(SCHEDULED_DATE_TYPE.find((i) => i.key === selectedStopPointId?.working_hour_by));
    }
  };

  const onChangeScheduledType = () => {
    setFieldValue(`to`, '');
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, 'scheduled_date');
    }

    if (stop.scheduled_date_to) {
      const date = moment(stop.scheduled_date_to, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, 'scheduled_date_to');
    }
  };

  useEffect(() => {
    getEquipmentIdInType();
  }, []);

  const getEquipmentIdInType = (length_id) => {
    getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8', length_id } }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `
                                     ${el?.equipment_id || ''}
                                    (${el?.make || ''}
                                     ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setLocalEquipmentIdData([...equipmentId]);
        setEquipmentId([...equipmentId]);
      }
    });
  };

  const onChangeAction = (obj) => {
    const key = +obj.key;
    setFieldValue('equipment_action', obj.key);
    key === 3 && getEquipmentIdInType();
  };

  const getTrailers = async () => {
    try {
      const { data } = await GetEquipmentTrailer({
        'sort[][equipment_id]': 'desc',
        stop_point_id: Number(stop.stop_point_id),
      });
      setDroppedTrailerIds(data.map((i) => i.equipment_id));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (stop?.stop_point_id) {
      onChangeStopPointId(stop?.stop_point_id, true);
      getTrailers();
    }
    getCustomerStopPoint(controller.signal);
  }, [stop?.stop_point_id]);

  useEffect(() => {
    setToBeDetermined(stop?.equipment_tbd);
  }, [stop?.equipment_tbd]);

  const onAddCustomTrailer = () => {
    if (customTrailerType && customTrailerId && customTrailerLength) {
      const body = {
        equipment_type_id: customTrailerType.key,
        equipment_id: customTrailerId,
        length_id: customTrailerLength.key,
      };

      setThirdPartyLoading(true);
      createQuickEquipment(body)
        .then((res) => {
          const label = `${res?.data?.equipment_id || ''}  (${res?.data?.make || ''}  ${res?.data?.model_id || ''})`;
          const newValue = {
            ...res.data,
            key: res.data.id,
            label,
            labelSelected: null,
          };
          setLocalEquipmentIdData([...localEquipmentIdData, newValue]);
          showToaster({ type: 'success', message: 'Success' });
          setCustomTrailerId('');
          setCustomTrailerLength(null);
          setCustomTrailerType(null);
          ref.current.click();
          setFieldValue(`equipment_id`, res.data.id);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        })
        .finally(() => {
          setThirdPartyLoading(false);
        });
    }
  };

  useEffect(() => {
    if (currentStopAddValue) {
      setFieldValue(`stop_point_id`, currentStopAddValue);
    }
  }, [currentStopAddValue]);

  return (
    <div className={classes.allFiledWrapper} ref={ref}>
      {/* Scheduled Date ID */}
      <div className={classes.fieldErrorWrapper}>
        <CustomSelect
          field={{
            name: `stop_point_id`,
            value: stop.stop_point_id,
          }}
          form={{ setFieldValue }}
          isSearchable
          options={stopPoints}
          menuStyles={{ maxWidth: 480 }}
          defaultText='Select Stop Point'
          onChange={(value) => onChangeStopPointId(value?.key)}
          onAddStopPoint={() => onChangeAddStopPointModal(true)}
          styles={{ maxWidth: 480, height: 32, margin: '8px 0 4px' }}
          shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'stop_point_id')}
          isSearchableStyles={{ maxWidth: 480, width: '100%', margin: '8px 0 4px', height: 32 }}
        />
        <ErrorMessage error={touched?.stop_point_id && errors?.stop_point_id} />
      </div>

      {/* Scheduled Date Type */}
      <div className={classes.fieldWrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Scheduled Date Type
        </Typography>
        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{ name: `scheduled_type`, value: stop.scheduled_type }}
            form={{ setFieldValue }}
            menuStyles={{ width: 400 }}
            options={SCHEDULED_DATE_TYPE}
            styles={{ maxWidth: 400, height: 22 }}
            onChange={(e) => onChangeScheduledType(e)}
            shouldUpdate={() => true}
          />
          <ErrorMessage error={touched?.scheduled_type && errors?.scheduled_type} />
        </div>
      </div>

      {/* Scheduled Date/Time */}
      <div className='d-flex justify-content-start align-items-start mt-2'>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start' }}>
          Scheduled Date/Time
        </Typography>
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex justify-content-start align-items-start gap-2'>
            <div>
              <DatePicker
                width='130px'
                size='small'
                name='scheduled_date'
                value={stop.scheduled_date}
                onChange={(date) => onChangeTimeValidate(date, 'scheduled_date')}
                error={touched?.scheduled_date && errors?.scheduled_date}
                disablePast={!shipmentSettings?.allow_past_date}
              />
            </div>
            <div>
              <TimePicker
                size='small'
                width='100px'
                value={stop.from}
                onChange={(time) => setFieldValue('from', time)}
                error={touched?.from && errors?.from}
              />
            </div>
          </div>
          {[1, 3].includes(+stop.scheduled_type) && (
            <div className='d-flex justify-content-start align-items-start gap-2'>
              <Typography variant='s2'>to</Typography>
              <div>
                <DatePicker
                  width='130px'
                  size='small'
                  name='scheduled_date_to'
                  value={stop.scheduled_date_to}
                  onChange={(date) => onChangeTimeValidate(date, 'scheduled_date_to')}
                  error={touched?.scheduled_date_to && errors?.scheduled_date_to}
                  minDate={moment(stop.scheduled_date).toDate()}
                />
              </div>
              <div>
                <TimePicker
                  size='small'
                  width='100px'
                  value={stop.to}
                  onChange={(time) => setFieldValue('to', time)}
                  error={touched?.to && errors?.to}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Arrival Date Type */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <div className={classes.fieldWrapperInput}>
            <Typography
              variant='s2'
              style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}
            >
              Arrival Date/Time
            </Typography>
            <div className={classes.fieldErrorWrapper} style={{ marginTop: 10 }}>
              <DatePicker
                width='130px'
                size='small'
                name='arrival_date'
                value={stop.arrival_date}
                onChange={(date) => setFieldValue('arrival_date', date)}
                error={touched?.arrival_date && errors?.arrival_date}
                disabled={!stopInfo?.stop?.arrival_date}
              />
            </div>
          </div>

          {/* /*Arrival Start Time*!/ */}
          <div className={classes.fieldWrapperInput} style={{ marginLeft: 12, marginTop: 10 }}>
            <div className={classes.fieldErrorWrapper}>
              <TimePicker
                size='small'
                width='100px'
                value={stop.arrival_time}
                onChange={(time) => setFieldValue('arrival_time', time)}
                error={touched?.arrival_time && errors?.arrival_time}
                disabled={!stopInfo?.stop?.arrival_date}
              />
            </div>
          </div>
        </div>
      )}

      {/* Departure Date Type */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <div className={classes.fieldWrapperInput}>
            <Typography
              variant='s2'
              style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}
            >
              Departure Date/Time
            </Typography>
            <div className={classes.fieldErrorWrapper} style={{ marginTop: 10 }}>
              <DatePicker
                width='130px'
                size='small'
                name='departure_date'
                value={stop.departure_date}
                onChange={(date) => setFieldValue('departure_date', date)}
                error={touched?.departure_date && errors?.departure_date}
                disabled={!stopInfo?.stop?.departure_date}
              />
            </div>
          </div>

          {/* Departure Start Time */}
          <div className={classes.fieldWrapperInput} style={{ marginLeft: 12, marginTop: 10 }}>
            <div className={classes.fieldErrorWrapper}>
              <TimePicker
                size='small'
                width='100px'
                value={stop.departure_time}
                onChange={(time) => setFieldValue('departure_time', time)}
                error={touched?.departure_time && errors?.departure_time}
                disabled={!stopInfo?.stop?.departure_date}
              />
            </div>
          </div>
        </div>
      )}

      {/* Equipment Action */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Action
        </Typography>

        <div style={{ flexGrow: 1, maxWidth: 400 }}>
          <Autocomplete
            size='small'
            name='equipment_action'
            labelKey='label'
            isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
            value={EQUIPMENT_ACTION_WAYPOINT.find((i) => i.key === Number(stop.equipment_action)) || null}
            onChange={(e, val) => onChangeAction(val)}
            options={EQUIPMENT_ACTION_WAYPOINT}
          />
          <ErrorMessage error={touched.equipment_action && errors.equipment_action} />
        </div>
      </div>

      {/* Equipment */}

      {+stop.equipment_action === 3 && (
        <div className={classes.fieldWrapper}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
            Equipment
          </Typography>
          <SubRight style={{ width: 20 }} />
          <div style={{ flexGrow: 1 }}>
            <CustomSelect
              field={{ name: `equipment_id`, value: stop.equipment_id }}
              form={{ setFieldValue }}
              isSearchable
              disabled={toBeDetermined}
              // validate={!toBeDetermined ? validateRequired : noValidate}
              styles={{ maxWidth: 380, height: 22 }}
              menuStyles={{ width: 380 }}
              isSearchableStyles={{ width: 380, height: 22 }}
              options={
                isSplit ? localEquipmentIdData.filter((i) => droppedTrailerIds.includes(i.id)) : localEquipmentIdData
              }
              shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_id')}
            />
            <ErrorMessage error={touched?.equipment_id && errors?.equipment_id} />

            {+stop.equipment_action === 3 && (
              <div>
                <div style={{ maxWidth: '380px', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                  <CustomCheckbox
                    checked={thirdParty}
                    onChange={(checked) => {
                      if (checked) {
                        setThirdParty(true);
                        setToBeDetermined(false);
                        setFieldValue(`equipment_id`, '');
                      } else {
                        setThirdParty(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      3rd party
                    </Typography>
                  </CustomCheckbox>
                  <CustomCheckbox
                    name='equipment_tbd'
                    checked={toBeDetermined}
                    onChange={(checked) => {
                      if (checked) {
                        setFieldValue(`equipment_tbd`, 1);
                        setToBeDetermined(true);
                        setThirdParty(false);
                        setFieldValue(`equipment_id`, '');
                      } else {
                        setFieldValue(`equipment_tbd`, 0);
                        setToBeDetermined(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      To be determined (TBD)
                    </Typography>
                  </CustomCheckbox>
                </div>
                {thirdParty && (
                  <div className='d-flex gap-3 mb-2' style={{ width: 400, marginTop: 10 }}>
                    <CustomInput
                      value={customTrailerId}
                      labelStyle={{ margin: 0 }}
                      style={{
                        borderRadius: 6,
                        paddingLeft: 8,
                        width: 90,
                      }}
                      placeholder='ID'
                      onChange={(e) => {
                        setCustomTrailerId(e.target.value);
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Type'
                      options={equipmentRequired.filter((item) => item.flag === 'trailers')}
                      value={customTrailerType}
                      onChange={(e, value) => {
                        setCustomTrailerType(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Length'
                      options={equipmentLengthData}
                      value={customTrailerLength}
                      onChange={(e, value) => {
                        setCustomTrailerLength(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <div className='d-flex gap-2'>
                      <CustomButton
                        type='secondary'
                        leftIcon={<DeleteIcon fill={palette.red500} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', margin: 0 }}
                        onClick={() => {
                          setCustomTrailerId('');
                          setCustomTrailerLength(null);
                          setCustomTrailerType(null);
                          setThirdParty(false);
                          setFieldValue(`thirdParty`, false);
                          setFieldValue(`equipment_id`, '');
                        }}
                        disabled={thirdPartyLoading}
                      />
                      <CustomButton
                        type='primary'
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddCustomTrailer();
                        }}
                        leftIcon={<CheckIcon fill={palette.white} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', marginTop: 0 }}
                        disabled={thirdPartyLoading}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Stop Notes */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 114 }}>
          Stop Notes
        </Typography>
        <CustomTextArea
          field={{ value: stop.stop_notes }}
          styleLabel={{ marginLeft: 36 }}
          name='stop_notes'
          styles={{ maxWidth: 400, height: 22 }}
          value={stop.stop_notes}
          onChange={(value) => setFieldValue(`stop_notes`, value)}
        />
      </div>

      {/* {bill_type} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.bill_type}
          name='bill_type'
          onChange={(checked) => setFieldValue(`bill_type`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Billable Move
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {dock_high} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.dock_high}
          name='dock_high'
          onChange={(checked) => setFieldValue(`dock_high`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Dock High
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {labor_required} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.labor_required}
          name='labor_required'
          onChange={(checked) => setFieldValue(`labor_required`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Labor Required (Loading)
          </Typography>
        </CustomCheckbox>
      </div>
    </div>
  );
};

export default WayPoint;
