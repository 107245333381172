import React, { useEffect, useState } from 'react';
import Divider from 'common/Divider';
import { InputWithIcon } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { CreateSettingsShipments, GetSettingsShipments } from 'Api/CompanySettings';
import {
  getInitialValues,
  shipmentDispatchOptions,
  shipmentTypes,
} from 'pages/CompanySettings/pagesComponents/ShipmentsGeneral/ShipmentGeneral.data';
import { getCommodity, getEquipmentRequiredTrailers, getEquipmentRequiredVehicles } from 'Api/Planner';
import {
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  EQUIPMENT_ACTION_WAYPOINT,
  SCHEDULED_DATE_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import { validationSchema } from './validationSchema';
import styles from './ShipmentsGeneral.module.css';

const ShipmentsGeneral = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [shipments, setShipments] = useState(null);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([]);
  const [commodityTypes, setCommodityTypes] = useState([]);

  const onSubmit = async (values) => {
    try {
      const { data } = await CreateSettingsShipments({
        shipment_id: values.shipment_id,
        audit_req_on_complete: values.audit_req_on_complete,
        revert_if_not_available: values.revert_if_not_available,
        revert_if_attendance: values.revert_if_attendance,
        allow_past_date: values.allow_past_date,
        require_actual_data: values.require_actual_data,
        default_global_data: values.default_global_data,
        hide_qty: values.hide_qty,
        hide_dimensions: values.hide_dimensions,
        hide_value: values.hide_value,
        hide_dock_high: values.hide_dock_high,
        hide_stackable: values.hide_stackable,
        hide_liftgate_service: values.hide_liftgate_service,
        hide_labor_required: values.hide_labor_required,
        hide_hazardous_materials: values.hide_hazardous_materials,
        default_shipment_type: values.default_shipment_type?.id || null,
        default_shipment_dispatch: values.default_shipment_dispatch,
        default_shipment_dispatch_option: values.default_shipment_dispatch_option?.id || null,
        default_pickup_schedule_date_type: values.default_pickup_schedule_date_type?.key || null,
        default_pickup_equipment_type: values.default_pickup_equipment_type?.key || null,
        default_pickup_equipment_action: values.default_pickup_equipment_action?.key || null,
        default_pickup_commodity: values.default_pickup_commodity?.key || null,
        default_pickup_bill_type: values.default_pickup_bill_type,
        default_pickup_dock_high: values.default_pickup_dock_high,
        default_pickup_stackable: values.default_pickup_stackable,
        default_pickup_liftgate_service: values.default_pickup_liftgate_service,
        default_pickup_labor_required: values.default_pickup_labor_required,
        default_pickup_hazardous_materials: values.default_pickup_hazardous_materials,
        default_delivery_schedule_date_type: values.default_delivery_schedule_date_type?.key || null,
        default_delivery_equipment_action: values.default_delivery_equipment_action?.key || null,
        default_delivery_bill_type: values.default_delivery_bill_type,
        default_delivery_dock_high: values.default_delivery_dock_high,
        default_delivery_labor_required: values.default_delivery_labor_required,
        default_waypoint_schedule_date_type: values.default_waypoint_schedule_date_type?.key || null,
        default_waypoint_equipment_action: values.default_waypoint_equipment_action?.key || null,
        default_waypoint_bill_type: values.default_waypoint_bill_type,
        default_waypoint_dock_high: values.default_waypoint_dock_high,
        default_waypoint_labor_required: values.default_waypoint_labor_required,
        hide_validation: values.hide_validation,
      });
      if (data) {
        showToaster({ type: 'success', message: 'Changes have been successfully saved' });
        if (isConfiguringStep) {
          onSaveAndNext('saveAndNext');
        }
      }
      getSettingsShipments();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: getInitialValues(shipments, commodityTypes, equipmentTypeOptions),
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const getSettingsShipments = async () => {
    try {
      const { data } = await GetSettingsShipments();
      setShipments(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getEquipmentRequired = async () => {
    try {
      const [vehicles, trailers] = await Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()]);
      const data = [];

      if (vehicles?.data) {
        const addFlagVehicles = vehicles?.data.map((el) => {
          if ([1, 2, 3, 4, 5, 10].includes(+el.id)) {
            return {
              key: el.id,
              label: el.id === 1 ? 'Truck (Power Only)' : el.title,
              flag: 'vehicles',
              noLengthField: true,
              type: el?.vehicle_type_id,
            };
          }
          return {
            key: el.id,
            label: el.title,
            flag: 'vehicles',
            type: el?.vehicle_type_id,
          };
        });
        data.push(...addFlagVehicles);
      }

      if (trailers?.data) {
        const addFlagTrailers = trailers?.data.map((el) => {
          return {
            key: el.id,
            label: el.title,
            flag: 'trailers',
            type: el?.vehicle_type_id,
          };
        });
        data.push(...addFlagTrailers);
      }
      data.sort((a, b) => a.key - b.key);

      setEquipmentTypeOptions(data);
      return data;
    } catch (e) {
      // Do nothing
    }
  };

  const getCommodityTypes = async () => {
    const { data } = await getCommodity();
    const newCommodity = data?.map((commodity) => {
      return {
        ...commodity,
        key: commodity?.id,
        label: commodity?.title,
        labelSelected: null,
      };
    });
    setCommodityTypes(newCommodity);
  };

  useEffect(() => {
    getSettingsShipments();
    getEquipmentRequired();
    getCommodityTypes();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={styles.companyProfile_wrapper}>
      <div className={styles.shipmentsGeneral_header}>
        <Typography variant='h2' style={{ color: palette.gray900 }}>
          Shipments
        </Typography>
        <div className={styles.actions}>
          {isConfiguringStep && (
            <CustomButton
              onClick={onCancelPrevPage}
              type='secondary'
              title='Back'
              styleButton={{ margin: 0 }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
            />
          )}
          <CustomButton
            onClick={handleSubmit}
            type='primary'
            title={isConfiguringStep ? 'Save and Next' : 'Save'}
            styleButton={{ margin: 0 }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.text}>
          <Typography variant='s2'>Shipment ID Starting Number</Typography>
        </div>
        <div>
          <InputWithIcon
            type='number'
            icon='#'
            text='/year'
            name='shipment_id'
            onChange={handleChange}
            value={values.shipment_id}
            error={touchedErrors.shipment_id}
            onKeyDown={(e) => {
              blockNotNumberChars(e);
              blockNonPositiveNumbers(e);
            }}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex  gap-2'>
          <div className={styles.check_wrapper}>
            <CustomCheckbox
              type='switch'
              name='audit_req_on_complete'
              checked={values.audit_req_on_complete}
              onChange={(checked) => handleChange('audit_req_on_complete', checked)}
              smail={false}
            />
          </div>
          <div className={styles.text}>
            <Typography variant='s2'>Shipment Audit Required Prior to Billing</Typography>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex  gap-2'>
          <div className={styles.check_wrapper}>
            <CustomCheckbox
              type='switch'
              name='revert_if_not_available'
              checked={values.revert_if_not_available}
              onChange={(checked) => handleChange('revert_if_not_available', checked)}
              smail={false}
            />
          </div>
          <div className={styles.text}>
            <Typography variant='s2'>Revert Driver from Shipment if Goes Not Available Status</Typography>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex  gap-2'>
          <div className={styles.check_wrapper}>
            <CustomCheckbox
              type='switch'
              name='revert_if_attendance'
              checked={values.revert_if_attendance}
              onChange={(checked) => handleChange('revert_if_attendance', checked)}
              smail={false}
            />
          </div>
          <div className={styles.text}>
            <Typography variant='s2'>Revert Driver from Shipment if Attendance Date Range is Approved</Typography>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex  gap-2'>
          <div className={styles.check_wrapper}>
            <CustomCheckbox
              type='switch'
              name='allow_past_date'
              checked={values.allow_past_date}
              onChange={(checked) => handleChange('allow_past_date', checked)}
              smail={false}
            />
          </div>
          <div className={styles.text}>
            <Typography variant='s2'>Allow past date when creating stops</Typography>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex  gap-2'>
          <div className={styles.check_wrapper}>
            <CustomCheckbox
              type='switch'
              name='require_actual_data'
              checked={values.require_actual_data}
              onChange={(checked) => handleChange('require_actual_data', checked)}
              smail={false}
            />
          </div>
          <div className={styles.text}>
            <Typography variant='s2'>Require Actual Qty, Weight, BOL on Checkout</Typography>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex align-items-center gap-2'>
          <CustomCheckbox
            type='switch'
            name='default_global_data'
            checked={values.default_global_data}
            onChange={(checked) => handleChange('default_global_data', checked)}
            smail={false}
          />
          <Typography variant='s2' className='nowrap'>
            Create Shipment - Skip type of shipment and preselect type
          </Typography>
          <div>
            <Autocomplete
              width='180px'
              name='default_shipment_type'
              options={shipmentTypes}
              value={values.default_shipment_type}
              onChange={(e, val) => handleChange('default_shipment_type', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={!values.default_global_data}
              error={touchedErrors.default_shipment_type}
            />
          </div>
          <Typography variant='s2' className='nowrap'>
            and enter shipment data manually
          </Typography>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex align-items-center gap-2'>
          <CustomCheckbox
            type='switch'
            name='default_shipment_dispatch'
            checked={values.default_shipment_dispatch}
            onChange={(checked) => handleChange('default_shipment_dispatch', checked)}
            smail={false}
          />
          <Typography variant='s2' className='nowrap'>
            Dispatch Shipment - Auto select
          </Typography>
          <div>
            <Autocomplete
              width='180px'
              name='default_shipment_dispatch_option'
              options={shipmentDispatchOptions}
              value={values.default_shipment_dispatch_option}
              onChange={(e, val) => handleChange('default_shipment_dispatch_option', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={!values.default_shipment_dispatch}
              error={touchedErrors.default_shipment_dispatch_option}
            />
          </div>
          <Typography variant='s2' className='nowrap'>
            and proceed
          </Typography>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center gap-2'>
            <div className='d-flex gap-2 flex-column'>
              <Typography variant='s2' className='nowrap'>
                Hide Optional Stop Details
              </Typography>
              <Typography variant='s2'>
                (When this option is ON the following info will be hidden from the shipment stop view)
              </Typography>
            </div>
          </div>
          <div className='d-flex ms-3 gap-4'>
            <div className='d-flex flex-column  gap-2'>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_qty}
                  onChange={(checked) => handleChange('hide_qty', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Qty
                  </Typography>
                </div>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_dimensions}
                  onChange={(checked) => handleChange('hide_dimensions', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Dimensions
                  </Typography>
                </div>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_value}
                  onChange={(checked) => handleChange('hide_value', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Value
                  </Typography>
                </div>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_dock_high}
                  onChange={(checked) => handleChange('hide_dock_high', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Dock High
                  </Typography>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column gap-2'>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_stackable}
                  onChange={(checked) => handleChange('hide_stackable', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Stackable
                  </Typography>
                </div>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_liftgate_service}
                  onChange={(checked) => handleChange('hide_liftgate_service', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Liftgate Service
                  </Typography>
                </div>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_labor_required}
                  onChange={(checked) => handleChange('hide_labor_required', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Labor Required
                  </Typography>
                </div>
              </div>
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='hide_qty'
                  checked={values.hide_hazardous_materials}
                  onChange={(checked) => handleChange('hide_hazardous_materials', checked)}
                  smail={false}
                />
                <div className='d-flex gap-2 flex-column'>
                  <Typography variant='s2' className='nowrap'>
                    Hazardous Materials
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className='d-flex  gap-2'>
          <div className={styles.check_wrapper}>
            <CustomCheckbox
              type='switch'
              name='hide_validation'
              checked={values.hide_validation}
              onChange={(checked) => handleChange('hide_validation', checked)}
              smail={false}
            />
          </div>
          <div className={styles.text}>
            <Typography variant='s2'>Hide Validation in Shipment Creation Process </Typography>
          </div>
        </div>
      </div>
      <div className='mt-5 mb-5'>
        <Typography variant='s1' className='nowrap'>
          Predefined Shipment Stop Creation
        </Typography>
        <div className={styles.stop_settings}>
          <div>
            <Typography variant='s2' className='nowrap' style={{ fontSize: '15px' }}>
              Pick Up
            </Typography>
            <div className='d-flex gap-5'>
              <div className='d-flex flex-column gap-4 mt-2'>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Scheduled Date Type'
                    name='default_pickup_schedule_date_type'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_pickup_schedule_date_type}
                    onChange={(e, val) => handleChange('default_pickup_schedule_date_type', val)}
                    options={SCHEDULED_DATE_TYPE}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Equipment Required'
                    name='default_pickup_equipment_type'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_pickup_equipment_type}
                    onChange={(e, val) =>
                      setValues((prevState) => ({
                        ...prevState,
                        default_pickup_equipment_type: val,
                        default_pickup_equipment_action: null,
                      }))
                    }
                    options={equipmentTypeOptions}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Equipment Action'
                    name='default_pickup_equipment_action'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_pickup_equipment_action}
                    onChange={(e, val) => handleChange('default_pickup_equipment_action', val)}
                    options={
                      values.default_pickup_equipment_type?.flag === 'trailers'
                        ? EQUIPMENT_ACTION
                        : values.default_pickup_equipment_type?.flag === 'vehicles'
                        ? [EQUIPMENT_ACTION[0]]
                        : []
                    }
                  />
                </div>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Commodity'
                    name='default_pickup_commodity'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_pickup_commodity}
                    onChange={(e, val) => handleChange('default_pickup_commodity', val)}
                    options={commodityTypes}
                  />
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mt-4'>
                <CustomCheckbox
                  name='default_pickup_bill_type'
                  checked={values.default_pickup_bill_type}
                  onChange={(checked) => handleChange('default_pickup_bill_type', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Billable Move
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_pickup_dock_high'
                  checked={values.default_pickup_dock_high}
                  onChange={(checked) => handleChange('default_pickup_dock_high', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Dock High
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_pickup_liftgate_service'
                  checked={values.default_pickup_liftgate_service}
                  onChange={(checked) => handleChange('default_pickup_liftgate_service', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Liftgate Service
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_pickup_labor_required'
                  checked={values.default_pickup_labor_required}
                  onChange={(checked) => handleChange('default_pickup_labor_required', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Labor Required (Loading)
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_pickup_hazardous_materials'
                  checked={values.default_pickup_hazardous_materials}
                  onChange={(checked) => handleChange('default_pickup_hazardous_materials', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Hazardous Materials
                  </Typography>
                </CustomCheckbox>
              </div>
            </div>
          </div>
          <Divider margin='24px 0' />
          <div>
            <Typography variant='s2' className='nowrap' style={{ fontSize: '15px' }}>
              Delivery
            </Typography>
            <div className='d-flex gap-5'>
              <div className='d-flex flex-column gap-4 mt-2'>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Scheduled Date Type'
                    name='default_delivery_schedule_date_type'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_delivery_schedule_date_type}
                    onChange={(e, val) => handleChange('default_delivery_schedule_date_type', val)}
                    options={SCHEDULED_DATE_TYPE}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Equipment Action'
                    name='default_delivery_equipment_action'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_delivery_equipment_action}
                    onChange={(e, val) => handleChange('default_delivery_equipment_action', val)}
                    options={EQUIPMENT_ACTION_DELIVERY}
                  />
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mt-4'>
                <CustomCheckbox
                  name='default_delivery_bill_type'
                  checked={values.default_delivery_bill_type}
                  onChange={(checked) => handleChange('default_delivery_bill_type', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Billable Move
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_delivery_dock_high'
                  checked={values.default_delivery_dock_high}
                  onChange={(checked) => handleChange('default_delivery_dock_high', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Dock High
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_delivery_labor_required'
                  checked={values.default_delivery_labor_required}
                  onChange={(checked) => handleChange('default_delivery_labor_required', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Labor Required (Loading)
                  </Typography>
                </CustomCheckbox>
              </div>
            </div>
          </div>
          <Divider margin='24px 0' />
          <div>
            <Typography variant='s2' className='nowrap' style={{ fontSize: '15px' }}>
              Waypoint
            </Typography>
            <div className='d-flex gap-5'>
              <div className='d-flex flex-column gap-4 mt-2'>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Scheduled Date Type'
                    name='default_waypoint_schedule_date_type'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_waypoint_schedule_date_type}
                    onChange={(e, val) => handleChange('default_waypoint_schedule_date_type', val)}
                    options={SCHEDULED_DATE_TYPE}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='250px'
                    label='Equipment Action'
                    name='default_waypoint_equipment_action'
                    labelKey='label'
                    isOptionEqualToValue={(option, value) => option.key === Number(value.key)}
                    value={values.default_waypoint_equipment_action}
                    onChange={(e, val) => handleChange('default_waypoint_equipment_action', val)}
                    options={EQUIPMENT_ACTION_WAYPOINT}
                  />
                </div>
              </div>
              <div className='d-flex flex-column gap-2 mt-4'>
                <CustomCheckbox
                  name='default_waypoint_bill_type'
                  checked={values.default_waypoint_bill_type}
                  onChange={(checked) => handleChange('default_waypoint_bill_type', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Billable Move
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_waypoint_dock_high'
                  checked={values.default_waypoint_dock_high}
                  onChange={(checked) => handleChange('default_waypoint_dock_high', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Dock High
                  </Typography>
                </CustomCheckbox>
                <CustomCheckbox
                  name='default_waypoint_labor_required'
                  checked={values.default_waypoint_labor_required}
                  onChange={(checked) => handleChange('default_waypoint_labor_required', checked)}
                >
                  <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                    Labor Required (Loading)
                  </Typography>
                </CustomCheckbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentsGeneral;
