import React from 'react';
import { Skeleton } from '@material-ui/lab';

const Table = [
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
  {
    column1: '100px',
    column2: '30px',
    column3: '70px',
    column4: '10px',
    column5: '50px',
    column6: '100px',
    column7: '130px',
    column8: '80px',
    column9: '20px',
    column10: '90px',
    column11: '100px',
  },
];

const EquipmentPreloaderSkeleton = () => {
  return (
    <>
      <div>
        <div className='d-flex justify-content-around'>
          <Skeleton style={{ width: '100px', height: '25px' }} className='mt-3 me-5' />
          <Skeleton style={{ width: '70px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '10px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '30px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '100px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '130px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '80px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '100px' }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: '100px' }} className='mt-3 me-5 ' />
        </div>
        <Skeleton style={{ width: '100%', height: '2px' }} className='mt-2 me-5 ' />
      </div>
      {Table.map((v, index) => (
        <div key={v?.id || index} className='d-flex justify-content-around'>
          <Skeleton style={{ width: `${v.column1}` }} className='mt-3 me-5  ' />
          <div className='d-flex'>
            {' '}
            <Skeleton style={{ width: `${v.column2}` }} className='mt-3 me-1 ' />
            <Skeleton style={{ width: `${v.column3}` }} className='mt-3 me-5 ' />
          </div>
          <Skeleton style={{ width: `${v.column4}` }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: `${v.column5}` }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: `${v.column6}` }} className='mt-3 me-5 ' />
          <Skeleton style={{ width: `${v.column7}` }} className='mt-3 me-5 ms-2' />
          <Skeleton style={{ width: `${v.column8}` }} className='mt-3 me-5 ms-4' />
          <div className='d-flex'>
            {' '}
            <Skeleton variant='circle' style={{ width: `${v.column9}`, height: '20px' }} className='mt-3' />
            <Skeleton style={{ width: `${v.column10}` }} className='mt-3 me-5 ms-2' />
          </div>
          <Skeleton style={{ width: `${v.column11}` }} className='mt-3 me-5 ms-2' />
        </div>
      ))}
    </>
  );
};

export default EquipmentPreloaderSkeleton;
