import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Collapse, IconButton } from '@mui/material';
import { ReactComponent as DownIcon } from 'assets/icons/header/down.svg';
import { formatAmount, millisecondsToInterval } from 'utils/helpers';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { Typography } from 'components/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { deletePaperLog, getDriverHosDetailedLogs } from 'Api/HOS';
import Loader from 'common/Loader';
import Chip from 'common/Chip';
import Divider from 'common/Divider';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import RowActions from 'pages/Hos-Logbook/CustomCollapsibleRow/RowActions';
import { TimeLineChart } from '../TimeLineChart';
import { STable, SUnderlineText } from './CustomCollapsibleRow.styles';
import { statues } from './CustomCollapsibleRow.data';

export const CustomCollapsibleRow = ({ row, vehicles, trailers, onDeleteSuccess, driverId }) => {
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const { convertToCustomerTime, formatDate, dateFormat } = useDateFormat();
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const [detailedLogs, setDetailedLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const params = {
      'filters[from_date]': row.start_time,
      'filters[to_date]': row.end_time,
    };

    try {
      setLoading(true);
      const detailed = await getDriverHosDetailedLogs(id || driverId, params);
      setDetailedLogs(detailed?.data);
    } catch (e) {
      // do nothing
    } finally {
      setLoading(false);
    }
  }, []);

  const onPaperLogDelete = async (id) => {
    try {
      await deletePaperLog(id);
      showToaster({ type: 'success', message: 'Logbook has been successfully removed!' });
      onDeleteSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const calculateDuration = (start_time, end_time) => {
    const startDate = new Date(start_time);
    const endDate = new Date(end_time);

    const durationMs = endDate - startDate;

    return millisecondsToInterval(durationMs);
  };

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Box
        onClick={() => setOpen(!open)}
        sx={{ cursor: 'pointer', borderBottom: 1, borderColor: 'divider', padding: '20px', display: 'flex' }}
      >
        <Typography style={{ width: '3%', textAlign: 'center' }}>
          <IconButton size='small'>
            {' '}
            {open ? <DownIcon style={{ transform: 'rotate(180deg)' }} /> : <DownIcon />}
          </IconButton>
        </Typography>
        <div className='d-flex align-items-center flex-column gap-2' style={{ width: '5%', textAlign: 'center' }}>
          <Typography variant='s2'>
            {!row.is_paper_log ? convertToCustomerTime(row?.start_time, dateFormat) : formatDate(row?.start_time)}{' '}
          </Typography>
          {!!row.is_paper_log && (
            <div style={{ width: 'fit-content', textAlign: 'center' }}>
              <Chip label='PAPER LOG' labelColor={palette.red500} bgColor={palette.red0} />
            </div>
          )}
        </div>
        <Typography variant='s2' style={{ width: '7%', textAlign: 'center' }}>
          {row.shipment_ids?.map((item) => (
            <SUnderlineText
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/shipment/${item}`);
              }}
            >
              {item}
            </SUnderlineText>
          ))}
          {!row.shipment_ids?.length && <span>-</span>}
        </Typography>
        <Typography variant='s2' style={{ width: '6%', textAlign: 'center' }}>
          {row.active_duration || '-'}
        </Typography>
        <Typography variant='s2' style={{ width: '6%', textAlign: 'center' }}>
          {row.drive_duration || '-'}
        </Typography>
        <Typography variant='s2' style={{ width: '14%', textAlign: 'center' }}>
          {row.violations?.length
            ? row.violations.map((item) => (
                <p>
                  {item.description} ({millisecondsToInterval(item.duration_ms)})
                </p>
              ))
            : '-'}
        </Typography>
        {!row.is_paper_log ? (
          <Typography variant='s2' style={{ width: '27%', textAlign: 'center' }}>
            {row.start_address ? `${row.start_address},` : ''} {row.start_city?.name ? `${row.start_city?.name},` : ''}{' '}
            {row.start_state?.name ? `${row.start_state?.name},` : ''} {row.start_country?.name}
          </Typography>
        ) : (
          <Typography variant='s2' style={{ width: '27%', textAlign: 'center' }}>
            -
          </Typography>
        )}
        {!row.is_paper_log ? (
          <Typography variant='s2' style={{ width: '27%', textAlign: 'center' }}>
            {row.end_address ? `${row.end_address},` : ''} {row.end_city?.name ? `${row.end_city?.name},` : ''}{' '}
            {row.end_state?.name ? `${row.end_state?.name},` : ''} {row.end_country?.name || '-'}
          </Typography>
        ) : (
          <Typography variant='s2' style={{ width: '27%', textAlign: 'center' }}>
            -
          </Typography>
        )}
        <div style={{ width: '5%', textAlign: 'center' }}>
          {!!row.is_paper_log && <RowActions onDelete={() => onPaperLogDelete(row.paper_logs[0].daily_log_id)} />}
        </div>
      </Box>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Box p={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <div className='d-flex flex-column ms-3'>
              {!row.is_paper_log && (
                <>
                  <div className='d-flex gap-2'>
                    <Typography variant='s2'>Rulset-break:</Typography>
                    <Typography variant='s2' style={{ color: palette.gray500 }}>
                      {row?.ruleset_break || '-'}
                    </Typography>
                  </div>
                  <div className='d-flex gap-1'>
                    <Typography variant='s2'>Rulset-cycle:</Typography>
                    <Typography variant='s2' style={{ color: palette.gray500 }}>
                      {row?.ruleset_cycle || '-'}
                    </Typography>
                  </div>
                  <div className='d-flex gap-1'>
                    <Typography variant='s2'>Rulset-restart:</Typography>
                    <Typography variant='s2' style={{ color: palette.gray500 }}>
                      {row?.ruleset_restart || '-'}
                    </Typography>
                  </div>
                  <div className='d-flex gap-1'>
                    <Typography variant='s2'>Rulset-Shift:</Typography>
                    <Typography variant='s2' style={{ color: palette.gray500 }}>
                      {row?.ruleset_shift || '-'}
                    </Typography>
                  </div>
                </>
              )}
              <div className='d-flex align-items-center gap-1'>
                <Typography variant='s2'>Vehicle:</Typography>
                <div>
                  {row?.vehicle_equipment_ids?.map((equipment_id, index) => {
                    const id = vehicles.find((vehicle) => vehicle.equipment_id === equipment_id)?.id;

                    if (id) {
                      return (
                        <span>
                          <SUnderlineText
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/equipment-profile/vehicle/${id}`);
                            }}
                          >
                            {equipment_id}
                          </SUnderlineText>
                          {index !== row.vehicle_equipment_ids.length - 1 && <span>,&nbsp;</span>}
                        </span>
                      );
                    }

                    return (
                      <Typography variant='s2' style={{ color: palette.gray500 }}>
                        {equipment_id}
                        {index !== row.vehicle_equipment_ids.length - 1 && <span>,</span>}
                      </Typography>
                    );
                  })}
                  {!row?.vehicle_equipment_ids?.length && (
                    <Typography variant='s2' style={{ color: palette.gray500 }}>
                      -
                    </Typography>
                  )}
                </div>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <Typography variant='s2'>Trailer:</Typography>
                {row?.trailer_equipment_ids?.map((equipment_id, index) => {
                  const id = trailers.find((trailer) => trailer.equipment_id === equipment_id)?.id;

                  if (id) {
                    return (
                      <span>
                        <SUnderlineText
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/equipment-profile/trailer/${id}`);
                          }}
                        >
                          {equipment_id}
                        </SUnderlineText>
                        {index !== row.trailer_equipment_ids.length - 1 && <span>,&nbsp;</span>}
                      </span>
                    );
                  }

                  return (
                    <Typography variant='s2' style={{ color: palette.gray500 }}>
                      {equipment_id}
                      {index !== row.trailer_equipment_ids.length - 1 && <span>,&nbsp;</span>}
                    </Typography>
                  );
                })}
                {!row?.trailer_equipment_ids?.length && (
                  <Typography variant='s2' style={{ color: palette.gray500 }}>
                    -
                  </Typography>
                )}
              </div>
              <div className='d-flex gap-1'>
                <Typography variant='s2'>Shipment:</Typography>
                {row?.shipment_ids?.map((item, index) => (
                  <Typography
                    variant='s2'
                    style={{ color: palette.gray500, cursor: 'pointer' }}
                    onClick={() => navigate(`/shipment/${item}`)}
                  >
                    {item}
                    {index !== row.shipment_ids.length - 1 && <span>,</span>}
                  </Typography>
                ))}
                {!row?.shipment_ids?.length && (
                  <Typography variant='s2' style={{ color: palette.gray500 }}>
                    -
                  </Typography>
                )}
              </div>
              <div className='d-flex gap-1'>
                <Typography variant='s2'>Distance (Miles):</Typography>
                <Typography variant='s2' style={{ color: palette.gray500 }}>
                  {row?.distance_travelled
                    ? formatAmount(!row.is_paper_log ? row.distance_travelled / 1609 : row.distance_travelled, 0)
                    : '-'}
                </Typography>
              </div>
            </div>

            <Divider />

            {!row.is_paper_log && <TimeLineChart detailedLogs={detailedLogs} />}

            <div className='mt-4'>
              {!row.is_paper_log ? (
                <STable>
                  <thead>
                    <tr className='header-row'>
                      <th>
                        <span className='column-title'>TIME</span>
                      </th>
                      <th>
                        <span className='column-title'>DURATION</span>
                      </th>
                      <th>
                        <span className='column-title'>STATUS</span>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center'>
                          <span className='column-title'>REMARK</span>
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center'>
                          <span className='column-title'>VEHICLE</span>
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center'>
                          <span className='column-title'>ODOMETER (MILES)</span>
                        </div>
                      </th>
                      <th>
                        <span className='column-title'>LOCATION</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailedLogs?.map((item) => (
                      <Fragment key={item.id}>
                        <tr className='body-row'>
                          <td>
                            <Typography variant='s2' style={{ display: 'inline-block' }}>
                              {moment(item.start_time).format('HH:mm')}
                            </Typography>
                          </td>
                          <td>
                            <Typography variant='s2' style={{ display: 'inline-block' }}>
                              {calculateDuration(item.start_time, item.end_time)}
                            </Typography>
                          </td>
                          <td>
                            <Typography variant='s2' style={{ display: 'inline-block' }}>
                              <Chip
                                bgColor={statues[item?.hos_status_type]?.bgColor}
                                labelColor={statues[item?.hos_status_type]?.color}
                                label={statues[item?.hos_status_type]?.name}
                                fontWeight={500}
                                fontSize='11px'
                                style={{ textTransform: 'uppercase' }}
                              />
                            </Typography>
                          </td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <Typography variant='s2'>{item.remarks || '-'}</Typography>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <Typography variant='s2'>{item.equipment_id || '-'}</Typography>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <Typography variant='s2'>
                                {item.odometer_meters ? formatAmount(item.odometer_meters / 1609, 0) : '-'}
                              </Typography>
                            </div>
                          </td>
                          <td>
                            <Typography variant='s2' style={{ display: 'inline-block' }}>
                              {item.address} {item.city?.name} {item.state?.name} {item.zip_code}
                            </Typography>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </STable>
              ) : (
                <img className='paper-log-image' src={row.paper_logs[0].paper_log_path} alt='paper_log' />
              )}
            </div>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
