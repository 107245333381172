import styled from 'styled-components';

export const SWrapper = styled.div`
  margin: 64px 0 48px;
`;

export const SFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 16px 0 28px;
`;
