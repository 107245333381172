import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import moment from 'moment';
import './RequestTable.css';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { driverRequestData, getRequestType, getDriverShipments, driverCreateAbsenceData } from 'Api/Driver';
import { useParams } from 'react-router-dom';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import calendar from 'assets/icons/drivers/calendar.svg';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import useShowToaster from 'hooks/useShowToaster';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { UpdateStaffRequest } from 'Api/Staff';
import RowActions from 'components/RequestTable/RowActions';
import DateRange from 'components/DateRangePicker/DateRangePicker';
import CustomizedSnackbars from 'components/toast/Toast';
import RequestUpdateModal from 'components/UpdateRequestModal';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import TableFooter from 'components/TableFooter/TableFooter';
import MaterialTableWrapper, { MaterialTableSort } from 'components/MaterialTableWrapper';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import useDateFormat from 'hooks/useDateFormat';

const statusMapper = {
  P: 'Pending',
  Pending: 'Pending',
  A: 'Approved',
  Approved: 'Approved',
  R: 'Rejected',
  Rejected: 'Rejected',
};

const RequestTable = ({ driverInfo }) => {
  const [tab, setTab] = useState(1);
  const myTableRef = useRef(null).current;
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [driverRequest, setDriverRequest] = useState({});
  const [rowPerPage, setRowPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [allRequest, setAllRequest] = useState([]);
  const [requestType, setRequestType] = useState([]);
  const [shipmentList, setShipmentList] = useState([]);

  const [udpdateRequestData, setUdpdateRequestData] = useState([]);
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });
  const { id } = useParams();
  const { use } = useTheme();
  const [createLoading, setCreateLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [search, setSearch] = useState('');

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const updateRequest = (updatedRequestInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);
    const payload = { ...updatedRequestInfo, driver_staff_id: updatedRequestInfo.driver_id, user_type: 'driver' };
    const rowIndex = updatedRequestInfo?.index;
    delete payload.requestId;
    delete payload.driver_id;
    delete payload.index;
    UpdateStaffRequest(payload, updatedRequestInfo?.requestId)
      .then((res) => {
        const updatedData = { ...updatedRequestInfo, ...res?.data };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        const tempReq = [...driverRequest.data];
        tempReq[rowIndex] = updatedData;
        setDriverRequest({ ...driverRequest, data: tempReq });
        getRequestData();
        onChangeTab(tabs.find((tab) => tab.key === 'all'));
        setCreateLoading(false);
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) || 'Something went wrong!' });
        setCreateLoading(false);
      });
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        myTableRef={myTableRef}
        rowPerPage={rowPerPage}
        totalCount={driverRequest?.total}
        totalLength={driverRequest?.data?.length}
        lastPage={driverRequest?.last_page}
        currentPage={driverRequest?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setDriverRequest(data)} // setDriverRequest(data)
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onPageChange = (event, page) => {
    setLoading(true);
    event.onChangePage(event, page - 1);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('DD-MM-YYYY');
    const endFormat = moment(end).format('DD-MM-YYYY');
    const filter = { page, itemsPerPage: rowPerPage, from_date: startFormat, to_date: endFormat };
    if (search) {
      filter.query = search;
    }
    driverRequestData(filter)
      .then((res) => {
        setDriverRequest({ ...res, data: res.request_data?.date });
        setLoading(false);
        setIsShowDatePicker(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRequestData = useCallback(
    (searchText = '') => {
      const { start, end } = dateRange;
      const startFormat = moment(start).format('DD-MM-YYYY');
      const endFormat = moment(end).format('DD-MM-YYYY');
      const filter = {
        page: '1',
        sort: 'desc',
        from_date: startFormat,
        to_date: endFormat,
        itemsPerPage: rowPerPage,
        driver_id: id,
      };
      if (searchText) {
        filter.query = searchText;
      }
      driverRequestData(filter)
        .then((res) => {
          setLoading(false);
          setIsShowDatePicker(false);
          const tempTabs = [];
          let value = 1;
          const data = res?.request_data?.data?.map((i) => ({
            ...i,
            req_status: statusMapper[i.req_status] || i.req_status,
          }));

          for (const req of data || {}) {
            const hasRequestIndex = tempTabs.findIndex((tab) => tab.label === req.req_status);
            if (hasRequestIndex === -1) {
              value += 1;
              tempTabs.push({ label: req.req_status, count: 1, key: req.req_status, value });
            } else {
              tempTabs[hasRequestIndex].count += 1;
            }
          }
          tempTabs.unshift({ label: 'All', count: res?.request_data?.data?.length, key: 'all', value: 1 });
          setTabs(tempTabs);
          setDriverRequest({ ...res?.request_data, data });
          setAllRequest({ ...res?.request_data, data });
        })
        .catch(() => {
          setLoading(false);
          setIsShowDatePicker(false);
        });
    },
    [dateRange, id, rowPerPage]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getRequestData(search);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, getRequestData]);

  useEffect(() => {
    setLoading(true);
    getRequestData(search);

    getRequestType().then((res) => {
      setRequestType(res?.data);
    });

    getDriverShipments({ id }).then((res) => {
      setShipmentList(res?.data);
    });
  }, [dateRange, getRequestData]);

  const onChangeTab = (tabValue) => {
    setTab(tabValue);
    if (tabValue.value === 1) {
      // show all data
      setDriverRequest(allRequest);
    } else {
      const getSelectedRequest = tabs.find((req) => req.value === tabValue?.value);
      const reqFilterData = allRequest?.data.filter((req) => req?.req_status === getSelectedRequest.label);
      setDriverRequest({ ...driverRequest, data: reqFilterData });
    }
  };

  function sortingQuery(field, sortBy) {
    const sortField = `sort[][${field}]`;
    getSortedDocuments(sortField, sortBy);
  }

  const getSortedDocuments = (sortField, sortBy) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('DD-MM-YYYY');
    const endFormat = moment(end).format('DD-MM-YYYY');
    const payload = {
      page: '1',
      sort: 'asc',
      from_date: startFormat,
      to_date: endFormat,
      itemsPerPage: rowPerPage,
      driver_id: id,
      sortField,
      sortBy,
    };
    if (search) {
      payload.query = search;
    }
    driverRequestData(payload).then((res) => {
      setDriverRequest(res?.request_data);
    });
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    setLoading(true);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('DD-MM-YYYY');
    const endFormat = moment(end).format('DD-MM-YYYY');
    const payload = { page: 1, itemsPerPage: rowPage, from_date: startFormat, to_date: endFormat };
    if (search) {
      payload.query = search;
    }
    driverRequestData(payload)
      .then((res) => {
        setDriverRequest({ ...res, data: res.request_data?.data });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        title: <MaterialTableSort title='ID' field='request_id' sortingQuery={sortingQuery} />,
        render: (rowData) => <span style={{ color: use(palette.gray900, palette.white) }}>{rowData?.request_id}</span>,
      },
      {
        field: 'req_start_datetime',
        title: <MaterialTableSort title='REQUEST DATE(S)' field='req_start_datetime' sortingQuery={sortingQuery} />,
        render: (rowData) => (
          <div className='sub-wraper'>
            <div
              className='date-container'
              style={{
                backgroundColor: use('#F0F2F7', palette.dark600),
                color: use(palette.gray600, palette.gray400),
              }}
            >
              <p className='sub-text'>{formatDate(rowData?.req_start_datetime)}</p>
            </div>
            <span>-</span>
            <div
              className='date-container'
              style={{
                backgroundColor: use('#F0F2F7', palette.dark600),
                color: use(palette.gray600, palette.gray400),
              }}
            >
              <p className='sub-text'>{formatDate(rowData?.req_end_datetime)}</p>
            </div>
          </div>
        ),
      },
      {
        field: 'request_type',
        title: (
          <span
            className='materialTableSort_title request-table-header-titile'
            style={{ color: use(palette.gray900, palette.white) }}
          >
            REQUEST TYPE
          </span>
        ),
        render: (rowData) => (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.request_type}
          </p>
        ),
      },
      {
        field: 'req_status',
        title: (
          <span
            className='materialTableSort_title request-table-header-titile'
            style={{ color: use(palette.gray900, palette.white) }}
          >
            STATUS
          </span>
        ),

        render: (rowData) => (
          <div className='request-status'>
            {rowData?.req_status === 'Pending' || rowData?.req_status === 'P' ? (
              <div
                className='break-container'
                style={{ backgroundColor: use(palette.orangeBackground1, palette.orangeBackground) }}
              >
                <p className='break-text'>
                  {rowData?.req_status === 'P' ? 'PENDING' : rowData?.req_status?.toUpperCase()}
                </p>
              </div>
            ) : rowData?.req_status === 'Approved' || rowData?.req_status === 'A' ? (
              <div className='d-flex flex-column gap-2'>
                <div
                  className='success-container'
                  style={{ backgroundColor: use(palette.lightgreen, palette.darkgreen) }}
                >
                  <p className='success-text'>
                    {rowData?.req_status === 'A' ? 'APPROVED' : rowData?.req_status?.toUpperCase()}
                  </p>
                </div>
                {rowData?.updated_by && (
                  <p
                    className='table-data-text mt-1'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                    }}
                  >
                    By {`${rowData?.updated_by?.first_name} ${rowData?.updated_by?.last_name}`}
                  </p>
                )}
              </div>
            ) : rowData?.req_status === 'Rejected' || rowData?.req_status === 'R' ? (
              <div className='d-flex flex-column gap-2'>
                <div className='warning-container' style={{ backgroundColor: palette.red0 }}>
                  <p className='warning-text'>
                    {rowData?.req_status === 'R' ? 'REJECTED' : rowData?.req_status?.toUpperCase()}
                  </p>
                </div>
                {rowData?.updated_by && (
                  <p
                    className='table-data-text mt-1'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                    }}
                  >
                    By {`${rowData?.updated_by?.first_name} ${rowData?.updated_by?.last_name}`}
                  </p>
                )}
              </div>
            ) : null}
          </div>
        ),
      },
      {
        field: 'note',
        title: (
          <span
            className='materialTableSort_title request-table-header-titile'
            style={{ color: use(palette.gray900, palette.white) }}
          >
            DRIVER NOTES
          </span>
        ),
        render: (rowData) => (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.note}
          </p>
        ),
      },
      {
        field: '',
        render: (rowData) =>
          !hideSensitiveInfo ? (
            <RowActions
              onEdit={() => {
                setUpdateModalShow(true);
                setUdpdateRequestData(rowData);
              }}
            />
          ) : null,
      },
    ];
  }, [use, driverRequest]);

  const updateAbsence = (absence, onHide = null, onSuccess = null, successMsg = '') => {
    driverCreateAbsenceData(absence)
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setCreateLoading(false);
      });
  };

  if (loading) {
    return <TablePreLoader />;
  }

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  return (
    <div
      className='request-table-container absence-staff driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-header'>
          <p className='heading' style={{ color: use(palette.gray700, palette.gray200) }}>
            Requests
          </p>
        </div>
        <div className='serach-wrapper'>
          <div className='position-relative'>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='serach-feild'
              placeholder='Search'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray400, palette.white),
                borderColor: use(palette.gray50, palette.darkborder),
                padding: '4px 24px 4px 35px',
              }}
            />
            {!!search?.length && (
              <DeleteIcon
                width={12}
                height={12}
                fill={palette.gray300}
                style={{ position: 'absolute', right: '8px', top: '8px', cursor: 'pointer' }}
                onClick={() => setSearch('')}
              />
            )}
          </div>
          <div className='calender-wrap'>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '143px', bottom: '57px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
            {/* <DateRange dateTimeRange={dateRange} dateSelect={dateSelect} rangeName={rangeName} setRangeName={setRangeName} /> */}
          </div>
        </div>
      </div>
      <div className='table-wrap'>
        <SwitchRadio
          name='tableTopTabMenu'
          items={tabs}
          value={tab}
          type='tab'
          onChange={(v) => onChangeTab(v)}
          plus={false}
        />
        <div className={driverRequest?.data?.length < 2 ? 'single-setting sub-table-container' : 'sub-table-container'}>
          <MaterialTableWrapper
            data={driverRequest?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
            onRowClick={(e, rowData) => {
              if (hideSensitiveInfo) {
                return;
              }
              setUpdateModalShow(true);
              setUdpdateRequestData(rowData);
            }}
          />
        </div>
        {!!updateModalShow && (
          <RequestUpdateModal
            show={!!updateModalShow}
            onHide={() => setUpdateModalShow(null)}
            title='Update Request'
            createLoading={createLoading}
            requestType={requestType}
            id={id}
            name={`${driverInfo?.fname} ${driverInfo?.lname}`}
            udpdateRequestData={udpdateRequestData}
            updateRequest={updateRequest}
            onSuccess={onSuccess}
            shipmentList={shipmentList}
            updateAbsence={updateAbsence}
          />
        )}
        {showMessage && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      </div>
    </div>
  );
};

export default RequestTable;
