import styled from 'styled-components';
import { palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';

export const SRadio = styled.input`
  background-color: #fff;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 1px solid ${palette.gray50};
  border-radius: 50%;
  cursor: pointer;
`;

export const SAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    .MuiInputBase-root {
      min-height: unset;
      height: 24px;
      max-width: 300px;

      &.MuiOutlinedInput-root {
        padding: 2px 24px 2px 8px;
      }

      .MuiAutocomplete-endAdornment {
        top: calc(50% - 12px);
      }
    }
  }
`;
