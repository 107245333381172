import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from 'components/Typography';
import { getCustomerInfo, getOpenJobPositions } from 'Api/JobPositions';
import useDebounce from 'hooks/useDebounce';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import PositionsFilter from './components/PositionsFilter';
import PositionCard from './components/PositionCard';
import Footer from './components/Footer';
import {
  SContent,
  SFilterWrapper,
  SGradientBox,
  SHeader,
  SPageWrapper,
  SPositionsList,
  SPositionsListWrapper,
} from './PublicPositions.styles';

const PositionsList = () => {
  const { use } = useTheme();
  const matches = useMediaQuery('(max-width:768px)');
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [filterBy, setFilterBy] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [selectedStates, setSelectedStates] = useState([]);
  const [departmentValues, setDepartmentValues] = useState([]);
  const debouncedSearch = useDebounce(searchValue, 500);
  const referral_token = searchParams.get('token');

  const filteredPositions = useMemo(() => {
    if (selectedStates.length && data?.length) {
      return data?.filter((item) => {
        return item.states.some((state) => selectedStates.some((i) => i.name?.includes(state?.state?.name)));
      });
    }

    return data;
  }, [selectedStates, data]);

  const getPositions = async (params) => {
    try {
      const response = await getOpenJobPositions(companyId, params);
      const publicJobs = (response?.data || []).filter((item) => item.status.name === 'Public');
      setData(publicJobs);
    } catch (e) {
      // Do nothing
    }
  };

  const getCompanyInfo = async () => {
    try {
      const response = await getCustomerInfo(companyId);
      setCompanyInfo(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const onCardClick = (id) => {
    if (referral_token) {
      navigate(`./${id}?token=${referral_token}`);
      return;
    }
    navigate(`./${id}`);
  };

  const onDepartmentChange = (value) => {
    if (filterBy !== 'department') {
      setFilterBy('department');
      setSearchValue('');
      setSelectedStates([]);
    }

    if (departmentValues.some((item) => item.key === value.key)) {
      const newValues = departmentValues.filter((item) => item.key !== value.key);
      setDepartmentValues(newValues);
      if (!newValues.length) {
        setFilterBy('all');
      }
      return;
    }

    setDepartmentValues((prevState) => [...prevState, value]);
  };

  const clearFilterValues = () => {
    setSearchValue('');
    setSelectedStates([]);
    setDepartmentValues([]);
  };

  useEffect(() => {
    let params;

    switch (filterBy) {
      case 'all':
        params = { query: searchValue || undefined };
        break;
      // case 'location':
      //   params = { location: selectedStates.map((item) => item.id) };
      //   break;
      default:
    }

    getPositions(params);
  }, [filterBy, debouncedSearch, departmentValues]);

  useEffect(() => {
    getCompanyInfo();
  }, []);

  return (
    <div>
      <SGradientBox />
      <SPageWrapper>
        <SHeader>
          {companyInfo?.logo_path && <img src={companyInfo.logo_path} alt='Company logo' height={48} />}
        </SHeader>
        <SContent>
          <div className='text-center' style={{ margin: matches ? '40px 0' : '80px 0' }}>
            <Typography variant={matches ? 'h3' : 'h1'} style={{ color: use(palette.gray900, palette.gray200) }}>
              Open Positions with {companyInfo?.company_name}
            </Typography>
          </div>
          <SFilterWrapper>
            <Typography variant='c3' style={{ color: palette.indigo500 }}>
              OPEN POSITIONS
            </Typography>
            <PositionsFilter
              filterBy={filterBy}
              onFilterChange={setFilterBy}
              searchValue={searchValue}
              onSearchValueChange={setSearchValue}
              selectedStates={selectedStates}
              onStatesChange={setSelectedStates}
              departmentValues={departmentValues}
              onDepartmentChange={onDepartmentChange}
              clearFilterValues={clearFilterValues}
            />
          </SFilterWrapper>
          <SPositionsListWrapper>
            <SPositionsList>
              {filteredPositions?.map((item) => (
                <PositionCard data={item} key={item.id} onClick={() => onCardClick(item.id)} />
              ))}
            </SPositionsList>
          </SPositionsListWrapper>
        </SContent>
        <Footer />
      </SPageWrapper>
    </div>
  );
};

export default PositionsList;
