import React, { forwardRef, useState } from 'react';
import { Fade, Popper } from '@mui/material';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';
import { ReactComponent as UserIcon } from 'assets/sidemanu/UserFilled.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { SPaper, SUserInfo } from './UserInfo.styles';

const UserInfo = forwardRef(({ data }, ref) => {
  const { use } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const onMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const { name, phone_number, email, contact_name } = data || {};

  return (
    <SUserInfo onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onMouseEnter}>
      <Popper open={open} anchorEl={anchorEl} placement='top-start' transition style={{ zIndex: 2 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <SPaper ref={ref} onClick={(e) => e.stopPropagation()}>
              <div className='user-info-content'>
                <ul>
                  {!!contact_name && (
                    <li>
                      <UserIcon width={22} height={12} fill={palette.indigo400} />
                      <a>{contact_name}</a>
                    </li>
                  )}
                  {!!phone_number && (
                    <li onClick={(e) => e.stopPropagation()}>
                      <PhoneIcon width={22} height={22} fill={palette.indigo400} />
                      <a href={`tel:${phone_number}`}>{phone_number}</a>
                    </li>
                  )}
                  {!!email && (
                    <li onClick={(e) => e.stopPropagation()}>
                      <MailIcon width={22} height={12} fill={palette.indigo400} />
                      <a href={`mailto:${email}`}>{email}</a>
                    </li>
                  )}
                </ul>
              </div>
            </SPaper>
          </Fade>
        )}
      </Popper>
      <div className='user-name-wrapper'>
        <Typography variant='s3' className='user-name-info' style={{ color: use(palette.dark800) }}>
          {name}
        </Typography>
      </div>
    </SUserInfo>
  );
});

export default UserInfo;
