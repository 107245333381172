import React, { useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { createActionTaken } from 'Api/Incidents';
import { validationSchema } from './validationSchema';

const AddActionTaken = ({ open, onClose, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        action: values.action,
      };

      const { data } = await createActionTaken(body);
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors } = useForm({
    initialValues: {
      action: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Add Custom Action'
      $bgColor={palette.gray0}
      $maxWidth='520px'
      $minWidth='520px'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Add',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-4'>
        <Input
          required
          label='Action Type'
          name='action'
          value={values.action}
          onChange={handleChange}
          error={touchedErrors.action}
        />
      </div>
    </Modal>
  );
};

export default AddActionTaken;
