import React, { useEffect, useState } from 'react';
import { ErrorMessage, FastField, Field } from 'formik';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right.svg';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { validateRequired } from 'components/AddEditModalStops/validates';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { getAllVehicles } from 'Api/Map';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import classes from '../replaceDriver.module.scss';

const Vehicle = ({ name, setValues, data, values }) => {
  const { use } = useTheme();
  const [local, setLocal] = useState({});
  const [option, setOption] = useState({});

  const errorRequired = (error) => {
    return (
      <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
        {error}
      </Typography>
    );
  };

  useEffect(() => {
    getAllVehicles(null, null, null, { allFilterData: { status_id: 1 } }).then((res) => {
      const selectData = res.data.map((el) => {
        return {
          ...el,
          key: el.id,
          label: `${el.equipment_id} ${el.equipment_type?.title}`,
          labelSelected: null,
        };
      });
      setOption(selectData);
    });
  }, [data?.equipment?.id]);

  function addInitialValue(name, formInitialValue) {
    const i = { ...formInitialValue };
    const { drivers_names } = local || {};
    i[name].drivers_names = drivers_names || '';
    return i;
  }

  function onChangeSwitchers(name, v, setValues, values) {
    if (v) {
      setValues({ [name]: addInitialValue(name, values), ...values });
    } else {
      setLocal({ ...values[name] });
      setValues({ ...values, [name]: {} });
    }
  }

  return (
    <div>
      <div className={classes.switchWrapper}>
        <Field name={name}>
          {({ field, form }) => (
            <CustomCheckbox
              name={name}
              field={field}
              form={form}
              type='switch'
              smail={false}
              withinForm
              checked={Object.keys(field?.value || {}).length !== 0 || false}
              style={{ marginTop: -8 }}
              onChange={(v) => onChangeSwitchers(name, v, setValues, values)}
            />
          )}
        </Field>
        <div className={classes.switchTextWrapper}>
          <Typography variant='s2'>Replace Vehicle</Typography>
        </div>
      </div>

      {typeof values?.[name] === 'object' && Object.keys(values?.[name] || {}).length > 0 && (
        <div style={{ marginLeft: 35, minHeight: 120 }}>
          <div className={classes.childContainer}>
            <div style={{ marginTop: 5 }}>
              <Typography variant='s2'>{data.equipment?.equipment_id}</Typography>
              {data?.equipment?.equipment_type && (
                <Typography variant='s2'> -&nbsp;{data?.equipment.equipment_type.title}</Typography>
              )}
            </div>
            <ArrowRightIcon className={classes.icon} />

            <div style={{ width: '100%', maxWidth: 220 }}>
              <FastField
                name={`${name}.equipment_id`}
                validate={validateRequired}
                isSearchable
                component={CustomSelect}
                styles={{ height: 32, borderRadius: 6 }}
                options={option || []}
              />
              <ErrorMessage name={`${name}.equipment_id`} render={errorRequired} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Vehicle;
