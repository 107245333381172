import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import plus from 'assets/icons/drivers/plus.svg';
import right from 'assets/icons/drivers/right.svg';
import calendar from 'assets/icons/drivers/calendar.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { saveFinishLater } from 'Api/OwnerOperatorV2';
import { AddDocument, uploadDriverDocument } from 'Api/Driver';

const AddDriverListStep3 = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  stepsDone,
  setStepsDone,
  operatorId,
  save,
  operatorName,
  module,
  draftDriver,
}) => {
  const { use } = useTheme();
  const [onNextloading, setOnNextloading] = useState(false);

  const formik = useFormik({
    initialValues: {
      documents: [
        {
          operator_id: operatorId,
          document_name: '',
          expiry_date: '',
          document_path: '',
          no_expiry: '',
        },
      ],
    },
  });

  const onPrevStep = () => {
    setStepsDone({
      ...stepsDone,
      isCompensation: true,
    });
    prevStep();
  };

  const remove = (index) => {
    const temp = formik.values.documents;
    temp.splice(index, 1);
    formik.setFieldValue('documents', temp);
  };

  const uploadDocument = (file, type, propertyName, index) => {
    uploadDriverDocument(file[0])
      .then((res) => {
        formik.setFieldValue(`${type}[${index}].${propertyName}`, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const onNextStep = () => {
    if (
      formik.values.documents.length === 1 &&
      !formik.values.documents[0].document_path &&
      !formik.values.documents[0].expiry_date &&
      !formik.values.documents[0].document_name
    ) {
      saveAndFinishLater(null);
      nextStep();
      return;
    }

    let isSubmitted = true;
    formik.values.documents.forEach((item) => {
      if (!item.document_name) {
        isSubmitted = false;
        return showError('Document Name is required!');
      }
      if (!item.expiry_date && !item.no_expiry) {
        isSubmitted = false;
        return showError('Document Expiry is required!');
      }
      if (!item.document_path) {
        isSubmitted = false;
        return showError('Document is required!');
      }
    });
    if (!isSubmitted) {
      return;
    }
    setOnNextloading(true);
    AddDocument(formik.values.documents)
      .then((res) => {
        saveAndFinishLater(null);
        setOnNextloading(false);
        if (!module) {
          save({ id: operatorId, owner_operator_name: operatorName });
        }
        nextStep({ documents: res.data }, `Documents has been ${stepsDone ? 'updated' : 'created'}`);
      })
      .catch((error) => {
        setOnNextloading(false);
        showError(getErrorMessage(error));
      });
  };

  const saveAndFinishLater = (step) => {
    const body = {
      draft_step_number: step,
    };

    saveFinishLater(operatorId, body).then(() => {
      if (step) {
        Cancel();
      }
    });
  };

  return (
    <div>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div
          className='driver-style-wrap mt-2'
          style={{ backgroundColor: use(palette.whiteBackground, palette.dark800) }}
        >
          {formik.values.documents.map((cartificate, index) => (
            <div key={cartificate || index} style={{ marginBottom: '12px' }}>
              <div className='kastum-driver-document-header'>
                <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                  Document Name
                </p>
                <div className='d-flex align-items-center'>
                  <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                    Expiry
                  </p>
                </div>
              </div>
              <div
                className='d-flex expiry-container'
                style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}
              >
                <div className='expiry-input' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                  <input
                    type='text'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                    name={`documents[${index}].document_name`}
                    id={`documents[${index}].document_name`}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik?.values?.documents[index]?.document_name}
                  />
                </div>
                {formik?.touched?.documents?.length &&
                formik?.touched?.documents[index]?.document_name &&
                formik.errors?.documents?.length &&
                formik.errors?.documents[index]?.document_name ? (
                  <span className='error-message'>{formik.errors?.documents[index]?.document_name}</span>
                ) : null}
                <div className='select-expiry-type' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                  <img src={calendar} alt='calendar p-1' />
                  <DatePicker
                    selected={formik?.values?.documents[index]?.expiry_date}
                    showYearDropdown
                    placeholderText='mm/dd/yyyy'
                    className='text-style ms-0'
                    id={`documents[${index}].expiry_date`}
                    name={`documents[${index}].expiry_date`}
                    onBlur={formik.handleBlur}
                    value={
                      formik?.values?.documents?.[index]?.no_expiry
                        ? ''
                        : formik?.values?.documents?.[index]?.expiry_date
                    }
                    onChange={(date) => {
                      formik.setFieldValue(`documents[${index}].expiry_date`, date);
                    }}
                    disabled={!!formik?.values?.documents?.[index]?.no_expiry}
                  />
                </div>
                {formik?.touched?.documents?.length &&
                formik?.touched?.documents[index]?.expiry_date &&
                formik.errors?.documents?.length &&
                formik.errors?.documents[index]?.expiry_date ? (
                  <span className='ms-3 error-message'>{formik.errors?.documents[index].expiry_date}</span>
                ) : null}
                {formik?.values?.documents[index]?.document_path ? (
                  <p
                    className='attachment-wrapper  mt-2 ms-3 me-3'
                    style={{ backgroundColor: use('#f0f1fa', '#20223F') }}
                  >
                    <img src={right} alt='attachment' className='attachment-icon' />
                    <span className='text-style' style={{ color: '#38A06C' }}>
                      File Uploaded
                    </span>
                    <img
                      src={cross}
                      alt='cross'
                      className='upload-cancel-icon'
                      onClick={() => formik.setFieldValue(`documents[${index}].document_path`, null)}
                    />
                  </p>
                ) : (
                  <div className='mt-1 ms-2 imageDropZone-Wrap'>
                    <ImageDropZone
                      onDrop={(file) => uploadDocument(file, 'documents', 'document_path', index)}
                      width='100%'
                      height='100%'
                      name='small'
                      deletable={false}
                      customStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                      accept={['application/pdf']}
                    />
                    <label htmlFor='myFile' className='text-style upload '>
                      <span className='upload-icon '>
                        <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                            fill='#4F5AED'
                          />
                        </svg>
                      </span>
                      <span className='text-style' style={{ color: use(palette.indigo500, palette.indigo500) }}>
                        Choose File
                      </span>
                    </label>
                  </div>
                )}

                {index > 0 ? (
                  <div className='ms-8'>
                    <img src={cross} alt='' onClick={() => remove(index)} />
                  </div>
                ) : (
                  <span />
                )}
              </div>
              <div style={{ marginTop: '4px', marginLeft: '250px' }}>
                <CustomCheckbox
                  name={`documents[${index}].no_expiry`}
                  checked={formik?.values?.documents[index]?.no_expiry}
                  onChange={(checked) => {
                    formik.setFieldValue(`documents[${index}].no_expiry`, checked);
                    if (checked) {
                      formik.setFieldValue(`documents[${index}].expiry_date`, null);
                    }
                  }}
                >
                  <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                    No Expiration
                  </Typography>
                </CustomCheckbox>
              </div>
            </div>
          ))}
          <div
            onClick={() =>
              formik.setFieldValue(`documents`, [
                ...formik.values.documents,
                { operator_id: operatorId, name: '', expiry_date: '', document_path: '' },
              ])
            }
            className='add-document-link'
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              borderColor: use(palette.gray200, palette.boxShadow),
            }}
          >
            <div className='ms-2  attach-file'>
              <label htmlFor='myFile' className='text-style upload'>
                <span className='upload-icon'>
                  <img src={plus} alt='plus' />
                </span>
                <span className='text-style choose-file '>Add Document</span>
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={() => saveAndFinishLater(3)}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <button
                type='button'
                onClick={onNextStep}
                className='next-step-btn next-step-btn-owner-add'
                disabled={!formik.isValid}
                style={{ opacity: formik.isValid ? 1 : 0.7 }}
              >
                Add Owner Operator
              </button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default AddDriverListStep3;
