import React, { useMemo, useState } from 'react';
import Autocomplete from 'common/Autocomplete';

const ReportNumberAutocomplete = ({ value, options, setOptions, onChange, error, ...props }) => {
  const [inputValue, setInputValue] = useState('');

  const updatedOptions = useMemo(() => {
    if (inputValue && options.every((i) => i !== inputValue)) {
      return [`+ Add "${inputValue}"`, ...options];
    }
    return options;
  }, [inputValue, options]);

  const handleChange = (e, val) => {
    if (val?.startsWith('+ Add ')) {
      setOptions((prevState) => [inputValue, ...prevState]);
      onChange(inputValue);
      return;
    }

    onChange(val);
  };

  return (
    <Autocomplete
      label='Report Number'
      name='report_number'
      placeholder='Select Report Number'
      options={updatedOptions}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(e, val) => setInputValue(val)}
      error={error}
      {...props}
    />
  );
};

export default ReportNumberAutocomplete;
