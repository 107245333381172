import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import AddCustomerContact from 'pages/Accounting/Receivables/TableSection/shared/AddCustomerContact';
import { CUSTOMER_CONTACT_TYPE, palette } from 'utils/constants';
import { getCustomerContactBook } from 'Api/Customers';
import { SContactAutocomplete } from './ContactAutocomplete.styles';

const ContactAutocomplete = ({
  width,
  customer,
  options,
  label,
  labelProps,
  showAddContact,
  onAddContactSuccess,
  ...props
}) => {
  const [customerContacts, setCustomerContacts] = useState([]);
  const [openAddContact, setOpenAddContact] = useState(false);

  const getCustomerContacts = async (customerId) => {
    try {
      const { data } = await getCustomerContactBook({ id: customerId });
      setCustomerContacts(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onUpdateContactsSuccess = () => {
    if (onAddContactSuccess) {
      onAddContactSuccess();
    }

    if (!options) {
      getCustomerContacts(customer.id);
    }
  };

  useEffect(() => {
    if (!options) {
      getCustomerContacts(customer.id);
    }
  }, []);

  return (
    <SContactAutocomplete $width={width}>
      {!!label && (
        <div className='d-flex justify-content-between align-items-center gap-3 mb-2'>
          <InputLabel className='mb-0' {...labelProps}>
            {label}
          </InputLabel>
          {!!showAddContact && (
            <EditIcon
              width={13}
              height={13}
              fill={palette.gray500}
              className='pointer'
              onClick={() => setOpenAddContact(true)}
            />
          )}
        </div>
      )}
      <Autocomplete
        width='100%'
        options={options || customerContacts}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          option ? `${CUSTOMER_CONTACT_TYPE[option.contact_type_id]} - ${option?.contact_name}` : ''
        }
        {...props}
      />
      {openAddContact && (
        <AddCustomerContact
          open={openAddContact}
          onClose={() => setOpenAddContact(false)}
          customer={customer}
          onSuccess={onUpdateContactsSuccess}
        />
      )}
    </SContactAutocomplete>
  );
};

export default ContactAutocomplete;
