import { array, object, string } from 'yup';
import { emailRegex, phoneRegex } from '../../../../utils/helpers';

export const validationSchema = array().of(
  object().shape({
    referral_name: string().trim().required('Name is required'),
    contact_information: string()
      .trim()
      .required('Phone/Email is required')
      .test('contact_information', 'Enter Valid Phone/Email', (value) => {
        const isValidEmail = emailRegex.test(value);
        const isValidPhone = phoneRegex.test(value);
        return !(!isValidEmail && !isValidPhone);
      }),
  })
);
