import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .scrollable-container {
    display: flex;
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .chart-boxes-wrapper {
    width: 80%;
    height: 366px;
    display: flex;
  }

  .stat-boxes-wrapper {
    width: 20%;
    display: flex;
    flex-direction: column;
  }
`;

export const SBoxWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-left: none;
  border-bottom: 1px solid ${palette.gray50};
  padding: 8px 2px 16px 16px;
  min-width: ${({ $minWidth }) => $minWidth || ''};
  max-width: ${({ $maxWidth }) => $maxWidth || ''};

  :last-child {
    border-right: 1px solid ${palette.gray50};
  }

  .chart-wrapper {
    height: calc(100% - 60px);
  }

  canvas {
    height: 100% !important;
  }
`;

export const SStatBoxWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-bottom: none;
  padding: 16px;
  min-width: ${({ $minWidth }) => $minWidth || ''};
  max-width: ${({ $maxWidth }) => $maxWidth || ''};

  .title-wrapper {
    min-height: 25px;
  }
`;
