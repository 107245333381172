import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  height: 100%;
  font-family: 'Inter', sans-serif;
  margin: 2px 0 16px;

  .award-button {
    background: ${palette.green500};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);

    :hover {
      background: ${palette.green600} !important;
    }
  }

  .decline-button {
    background: ${palette.red500};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);

    :hover {
      background: ${palette.red600} !important;
    }
  }

  .cross-out-line {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-bottom: 1px solid ${palette.gray500};
  }

  .MuiTableRow-root {
    position: relative;
  }
`;

export const SCarrierNameImage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .carrier-image {
    border-radius: 50%;
    object-fit: cover;
  }
`;
