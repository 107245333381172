import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const ConfirmationModal = ({
  open,
  onClose,
  title,
  headerTitle,
  text,
  onConfirm,
  disabled,
  width,
  buttonProps,
  cancelProps,
}) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={headerTitle || `Delete ${title}`}
      $maxWidth={width || '470px'}
      $minWidth={width || '470px'}
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', ...cancelProps },
        {
          key: 'submit',
          type: 'danger',
          title: 'Delete',
          onClick: onConfirm,
          disabled,
          ...buttonProps,
        },
      ]}
    >
      <Typography variant='s1' as='p' className='mt-3 mb-3' style={{ color: palette.gray700 }}>
        {text || `Are you sure you want to delete this ${title.toLowerCase()}?`}
      </Typography>
    </Modal>
  );
};

export default ConfirmationModal;
