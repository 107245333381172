import React from 'react';
import Grid from '@mui/material/Grid';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';

const MilitaryHistory = ({ form }) => {
  const { values, handleChange, touchedErrors, handleBlur } = form;

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={3}>
        <Grid item xs={12}>
          <Input
            required
            name='service_branch'
            label='Branch of Service'
            onChange={handleChange}
            value={values.service_branch}
            error={touchedErrors.service_branch}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel required>Dates of Service</InputLabel>
          <div className='d-flex'>
            <div>
              <DatePicker
                required
                width='160px'
                borderRadius='6px 0 0 6px'
                name='service_from'
                value={values.service_from}
                onChange={(date) => handleChange('service_from', date)}
                onBlur={handleBlur}
                disableFuture
                error={touchedErrors.service_from}
              />
            </div>
            <div>
              <DatePicker
                required
                width='160px'
                borderRadius='0 6px 6px 0'
                name='service_to'
                value={values.service_to}
                onChange={(date) => handleChange('service_to', date)}
                onBlur={handleBlur}
                disableFuture
                error={touchedErrors.service_to}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            name='discharge_rank'
            label='Rank at Discharge'
            onChange={handleChange}
            value={values.discharge_rank}
            error={touchedErrors.discharge_rank}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            name='discharge_type'
            label='Type of Discharge'
            onChange={handleChange}
            value={values.discharge_type}
            error={touchedErrors.discharge_type}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            name='mos'
            label='Military Occupational Specialty (MOS)'
            onChange={handleChange}
            value={values.mos}
            error={touchedErrors.mos}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default MilitaryHistory;
