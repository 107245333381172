import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckFilled } from 'assets/icons/check-filled.svg';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as Download } from 'assets/icons/download2.svg';
import PDFMerger from 'pdf-merger-js';
import { useParams } from 'react-router-dom';
import { getCarrierShipment } from 'Api/Carriers';
import { SFlexWrapper, SWrapper } from './Final.styles';
import { SPrimaryButton } from '../../CarrierConfirmation.styles';

const Final = () => {
  const { use } = useTheme();
  const { dot, token, shipment_id } = useParams();
  const [path, setPath] = useState(null);
  const [shipment, setShipment] = useState(null);

  useEffect(() => {
    getShipmentInfo();
  }, []);

  const getShipmentInfo = async () => {
    try {
      const params = {
        shipment_id,
      };
      const info = await getCarrierShipment(dot, token, params);
      setShipment(info.data);
      if (info?.data?.confirmation_document) {
        setPath(info.data.confirmation_document);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getMergedUrl = async (docs) => {
    try {
      const merger = new PDFMerger();

      for (const file of docs) {
        await merger.add(file);
      }

      const mergedPdf = await merger.saveAsBlob();
      return URL.createObjectURL(mergedPdf);
    } catch (e) {
      // Do nothing
    }
  };

  const onDownload = (path) => {
    fetch(path, { mode: 'cors' })
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = typeof path === 'string' ? new Blob([resp], { type: 'application/pdf' }) : path;
        const link = document.createElement('a');
        const url = URL.createObjectURL(file);
        link.setAttribute('download', typeof path === 'string' ? 'download' : path.name);
        link.href = url;
        link.download = `${shipment?.confirmation_id}.pdf`;
        link.click();
      });
  };

  const downLoad = async () => {
    const docs = [];
    if (path) {
      docs.push(path);
    }
    const mergedUrl = await getMergedUrl(docs);
    onDownload(mergedUrl);
  };

  return (
    <SWrapper>
      <SFlexWrapper>
        <CheckFilled />
        <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray200) }} className='success-title'>
          Success!
        </Typography>
        <Typography variant='b1' style={{ color: use(palette.gray700, palette.gray200) }}>
          Your signed document has been successfully submitted.
        </Typography>
        <SPrimaryButton onClick={downLoad} className='icon-button mt-4'>
          <Download />
          Download Signed Confirmation
        </SPrimaryButton>
      </SFlexWrapper>
    </SWrapper>
  );
};

export default Final;
