export const measuringUnits = [
  { label: 'LBS', value: 'LBS' },
  { label: 'KGS', value: 'KGS' },
];
export const cargoMeasuringUnits = [
  { label: 'LBS', value: 'LBS' },
  { label: 'KGS', value: 'KGS' },
];
export const odometerUnits = [
  { label: 'Miles', value: 'miles' },
  { label: 'KMS', value: 'kms' },
];
export const liftGate = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
export const equipmentOwnerOptions = [
  { label: 'Company Owned', value: '1' },
  { label: 'Leasing Owned', value: '2' },
  { label: 'Owner Operator', value: '3' },
];
export const fuelTypes = [
  { label: 'Diesel', value: '1' },
  { label: 'Gasoline', value: '2' },
  { label: 'Natural Gas', value: '3' },
  { label: 'Electric', value: '4' },
];
export const fuelCapacityMapper = {
  1: '150',
  3: '26',
  4: '24',
  5: '22',
  9: '30',
  10: '15',
  11: '30',
};

export const vehicleRequiredFieldsMapper = {
  equipment_id: 'Equipment ID is required',
  equipment_type_id: 'Equipment Type is required',
  make: 'Make is required',
  model_id: 'Model is required',
  fuel_type_id: 'Fuel Type is required',
  owner_operator: 'Equipment Owner is required',
  state: 'Plate State is required',
  gross_vehicle_weight: 'Gross Vehicle Weight is required',
  license_plate_number: 'License Plate Number is required',
};

export const trailerRequiredFieldsMapper = {
  equipment_id: 'Equipment ID is required',
  equipment_type_id: 'Equipment Type is required',
  make: 'Make is required',
  model_id: 'Model is required',
  owner_operator: 'Equipment Owner is required',
  state: 'Plate State is required',
  gross_vehicle_weight: 'Gross Vehicle Weight is required',
  license_plate_number: 'License Plate Number is required',
};

export const disabledIds = [1, 10];
