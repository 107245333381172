import React, { useEffect, useState } from 'react';
import hexRgb from 'hex-rgb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Loader from 'common/Loader';
import ViewPdf from 'components/ViewPdf';
import UserInfo from 'components/UserInfo';
import { Typography } from 'components/Typography';
import ShipmentNotes from 'componentsV2/Shipment/ShipmentSummary/components/ShipmentNotes';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import { sumDurations } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { palette, TRUCK_TYPES } from 'utils/constants';
import { getShipmentInfo, getShipmentNotes, getShipmentsEvents } from 'Api/Shipment';
import Header from './components/Header';
import InfoItem from './components/InfoItem';
import ShipmentStops from './components/ShipmentStops';
import ShipmentEvents from './components/ShipmentEvents';
import { generatePDF } from './generatePdf';
import { SInfoSection } from './ShipmentSummary.styles';

const SummaryModal = ({ open, onClose, shipmentId }) => {
  const navigate = useNavigate();
  const dateFormat = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [pdfUrl, setPdfUrl] = useState('');
  const [shipment, setShipment] = useState(null);
  const [events, setEvents] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);

  const {
    planned_duration,
    shipment_billing,
    shipment_stops,
    empty_miles,
    loaded_miles,
    deadhead_drive_time,
    shipment_drive_time,
    scheduled_duration,
    actual_duration,
    equipment,
    assigned_to_1,
    brokerage_dispatch,
  } = shipment || {};

  const printSummary = () => {
    const { url } = generatePDF(
      { ...shipment, shipment_events: events, shipment_notes: notes },
      { currency, dateFormat }
    );
    setPdfUrl(url);
  };

  const getShipment = async () => {
    try {
      setLoading(true);
      const { data } = await getShipmentInfo(shipmentId);
      setShipment(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getEvents = async () => {
    try {
      setLoadingEvents(true);
      const { data } = await getShipmentsEvents({ shipment_id: shipmentId, params: { page: 1, itemsPerPage: 1000 } });
      setEvents(data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingEvents(false);
    }
  };

  const getNotes = async () => {
    try {
      setLoadingNotes(true);
      const { data } = await getShipmentNotes({ shipment_id: shipmentId, page: 1, itemsPerPage: 1000 });
      setNotes(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingNotes(false);
    }
  };

  useEffect(() => {
    if (!shipmentId) {
      return;
    }

    getShipment();
    getNotes();
    getEvents();
  }, [shipmentId]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={shipment ? <Header shipment={shipment} /> : null}
      $bgColor={palette.gray0}
      $width='98vw'
      $minWidth='1200px'
      $maxWidth='1600px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'Upload',
          type: 'primary',
          title: 'Print',
          onClick: printSummary,
          disabled: loading || loadingEvents || loadingNotes,
        },
      ]}
    >
      {loading || !shipment ? (
        <Loader loading />
      ) : (
        <div>
          <div className='mb-5'>
            <SInfoSection>
              <div className='d-flex justify-content-between align-items-center gap-4'>
                <InfoItem title='Stops'>
                  <Chip label={shipment_stops.length} labelColor={palette.gray900} />
                </InfoItem>
                <InfoItem title='Empty' content={Math.round(empty_miles || 0)} />
                <InfoItem title='Loaded' content={Math.round(loaded_miles || 0)} />
                <InfoItem title='Total miles' content={Math.round(empty_miles || 0) + Math.round(loaded_miles || 0)} />
                <InfoItem
                  title='Route Duration'
                  content={
                    !assigned_to_1
                      ? planned_duration || '-'
                      : sumDurations([
                          deadhead_drive_time || '0m',
                          shipment_drive_time || '0m',
                          ...[
                            ...(shipment_stops || []).map((item) =>
                              getAverageTimeConverted(item.stop_point.average_waiting_time || 0)
                            ),
                          ],
                        ])
                  }
                />
                <InfoItem
                  title='Scheduled Duration'
                  content={scheduled_duration ? getAverageTimeConverted(scheduled_duration) : '-'}
                />
                <InfoItem title='Actual Duration' content={actual_duration || '-'} />
                <InfoItem title='Tags'>
                  {shipment_billing.some((i) => i.groups) ? (
                    <div className='d-flex align-items-center gap-1'>
                      {shipment_billing.map((item) => {
                        if (!item.groups) {
                          return null;
                        }

                        const colorCode = item.groups.color ? hexRgb(item.groups.color) : null;
                        const textColor =
                          colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                            ? palette.white
                            : palette.gray900;

                        return (
                          <Chip
                            key={item.id}
                            label={item.groups.short_name}
                            labelColor={textColor || palette.gray900}
                            bgColor={item.groups.color}
                            radius='20px'
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <Typography variant='s2'>-</Typography>
                  )}
                </InfoItem>
                <InfoItem title='Equipment'>
                  {equipment ? (
                    <div className='d-flex align-items-center gap-1'>
                      <Typography
                        variant='c3'
                        style={{ color: palette.gray500 }}
                        className='pointer'
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/equipment-profile/vehicle/${equipment.id}`);
                        }}
                      >
                        <Typography variant='s2' style={{ color: palette.gray900 }}>
                          {equipment.equipment_id || 'TBD'}
                        </Typography>{' '}
                        {equipment.equipment_type?.title}
                        {!!equipment.truck_type && ` (${TRUCK_TYPES[equipment.truck_type]})`}
                      </Typography>
                      {!!equipment.hooked_to && (
                        <>
                          <TwoWaysIcon />
                          <Typography
                            variant='c3'
                            style={{ color: palette.gray500 }}
                            className='pointer'
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/equipment-profile/trailer/${equipment.hooked_to.id}`);
                            }}
                          >
                            <Typography variant='s2' style={{ color: palette.gray900 }}>
                              {equipment.hooked_to?.equipment_id || 'TBD'}
                            </Typography>{' '}
                            {equipment.hooked_to?.length?.length}&nbsp;
                            {equipment.hooked_to?.length?.unit} {equipment.hooked_to?.equipment_type?.title}
                          </Typography>
                        </>
                      )}
                    </div>
                  ) : (
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      -
                    </Typography>
                  )}
                </InfoItem>
                <InfoItem title='Driver'>
                  {brokerage_dispatch || assigned_to_1 ? (
                    <UserInfo
                      size='32px'
                      statusSize='7px'
                      data={{
                        ...(brokerage_dispatch || assigned_to_1),
                        name: brokerage_dispatch
                          ? brokerage_dispatch.driver_name
                          : `${assigned_to_1.fname} ${assigned_to_1.lname}`,
                        phone_number: brokerage_dispatch
                          ? brokerage_dispatch.driver_phone_number &&
                            brokerage_dispatch.driver_phone_number.startsWith('+')
                            ? brokerage_dispatch.driver_phone_number
                            : `+${brokerage_dispatch.driver_phone_number}`
                          : assigned_to_1.phone_number,
                        status: assigned_to_1.status_data?.driver_status,
                        user_type: brokerage_dispatch ? 'carrier' : 'driver',
                      }}
                    />
                  ) : (
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      -
                    </Typography>
                  )}
                </InfoItem>
              </div>
            </SInfoSection>
          </div>
          <div className='mb-5'>
            <ShipmentStops stops={shipment_stops} />
          </div>
          <div className='mb-5'>
            <ShipmentNotes shipment={shipment} notes={notes} location={loadingNotes} />
          </div>
          <div className='mb-2'>
            <ShipmentEvents events={events} location={loadingEvents} />
          </div>
        </div>
      )}
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </Modal>
  );
};

export default SummaryModal;
