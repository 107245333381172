import React from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { getShipmentType } from 'utils/helpers';
import { formatNumber, palette, STOP_POINT_TAB, TRUCK_TYPES } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import right from 'assets/icons/drivers/right.svg';
import { SCHEDULED_DATE_TYPE_OBJ } from '../../../CreateShipment/ShipmentStops/helpers/constants';
import classes from '../../../TableShipments/tableShipments.module.css';
import { getStatusParamsInStatus } from '../../../TableShipments/helpers/constants';
import { MaterialTableSort } from '../../../MaterialTableWrapper';
import Garlax from '../../../../common/Tooltip';

export const useColumns = ({ sort, sortingQuery, findNextStop }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const { formatDate, formatTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'shipment_id',
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => <Typography variant='s2'>{row.shipment_id || '-'}</Typography>,
    },
    {
      field: 'shipment_type',
      title: <ColumnHeader title='SHIPMENT TYPE' field='shipment_type' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {getShipmentType(row.billing?.shipment.shipment_type)}
        </Typography>
      ),
    },
    {
      field: 'equipment',
      title: <ColumnHeader title='EQUIPMENT' field='equipment' />,
      render: (rowData) => {
        const { equipment_id, equipment_type, hooked_to, id, truck_type } = rowData?.equipment || {};
        return (
          <div className={classes.equipmentColWrapper}>
            <div className={classes.equipmentVehicleWrapper}>
              <Typography
                variant='s3'
                style={{ color: use(palette.dark800, palette.gray200) }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/equipment-profile/vehicle/${id}`);
                }}
              >
                {equipment_id || 'TBD'} {equipment_type?.title}
                {!!truck_type && ` (${TRUCK_TYPES[truck_type]})`}
              </Typography>
            </div>

            {!!hooked_to && (
              <div className='d-flex align-items-baseline'>
                <TwoWaysIcon style={{ margin: '0 4px' }} />
                <div className={classes.hookToWrapper}>
                  <Typography
                    variant='s3'
                    style={{ color: use(palette.dark800, palette.gray200), marginLeft: 5 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/equipment-profile/trailer/${hooked_to.id}`);
                    }}
                  >
                    {hooked_to?.equipment_id || 'TBD'} {hooked_to?.length?.length}&nbsp;
                    {hooked_to?.length?.unit} {hooked_to?.equipment_type?.title}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'stops',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='STOPS' field='stops' />,
      render: (row) => {
        const stops = row.shipment_stops.length;

        return (
          <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
            {findNextStop(row)} of {stops}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      resizable: true,
      center: true,
      title: <ColumnHeader title='STATUS' field='status' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const status = rowData?.status;
        const paramsInStatus = getStatusParamsInStatus(status);
        const { bgStatus, colorStatus, statusName } = paramsInStatus || {};

        return (
          <div>
            <div className={classes.statusWrapper}>
              <span className={classes.statusColumns} style={{ backgroundColor: bgStatus, color: colorStatus }}>
                {statusName}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'origin',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='ORIGIN' field='origin' />,
      render: (rowData) => {
        const originStopName = rowData.shipment_stops[0]?.stop_point?.location_name;
        const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
        const id = rowData.shipment_stops[0]?.stop_point?.id;
        const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;

        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const scheduledOriginTo = rowData.shipment_stops[0].scheduled_date_to;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;
        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOriginTo} ${toData || ''}`;
        return (
          <>
            <Typography
              variant='b3'
              className={classes.originNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {originStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
            <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
            {!!toData && [1, 3].includes(Number(typeId)) && (
              <>
                <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                <Typography variant='b2'>{formatTime(to)}</Typography>
              </>
            )}
            <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
          </>
        );
      },
    },
    {
      field: 'destination',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DESTINATION' field='destination' />,
      render: (rowData) => {
        const destinationStopName =
          rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.location_name;
        const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;
        const id = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.id;

        const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
        const scheduledDestinationTo = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date_to;
        const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
        const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;
        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestinationTo} ${toData || ''}`;

        return (
          <>
            <Typography
              variant='b3'
              className={classes.destinationNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {destinationStopName}{' '}
            </Typography>
            <br />
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <Typography variant='b3'>{formatDate(from)}</Typography>{' '}
            <Typography variant='b2'>{formatTime(from)}</Typography>{' '}
            {!!toData && [1, 3].includes(Number(typeId)) && (
              <>
                <Typography variant='b3'>to {formatDate(to)}</Typography>{' '}
                <Typography variant='b2'>{formatTime(to)}</Typography>
              </>
            )}
            <Typography variant='b2'>{!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`} </Typography>
          </>
        );
      },
    },
    {
      field: 'pw',
      resizable: true,
      title: <MaterialTableSort title='Pw' field='pw' sortingQuery={sortingQuery} isSorting={false} />,
      render: (rowData) => {
        const isPW = !(rowData.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
        return isPW ? <img src={right} alt='' /> : <DeleteIcon fill={palette.red500} />;
      },
    },
    {
      field: 'miles',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: (
        <>
          <Typography variant='c3'>TOTAL ETA </Typography>
          <Garlax
            title={
              <>
                {'Total ETA Date (Final total estimated date) \n Total ETA Time (with Total ETA) \n Total Miles Remaining/Total miles'
                  .split('\n')
                  .map((line) => (
                    <React.Fragment key={line}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 13,
                  fontFamily: 'Inter',
                  whiteSpace: 'nowrap',
                  maxWidth: 'unset',
                },
              },
            }}
          >
            <InfoIcon />
          </Garlax>
        </>
      ),
      render: (rowData) => {
        const statusSixOrSeven = Number(rowData?.status_id) === 6 || Number(rowData?.status_id) === 7;
        const totalEta = rowData?.total_eta;
        const totalEtaDate = rowData?.total_estimated_date_time_final;

        const { total_miles_remain } = rowData;
        const { loaded_miles, empty_miles } = rowData;
        const miles = Math.round(loaded_miles + empty_miles);

        return (
          <>
            <Typography variant='b3'>
              {statusSixOrSeven ? '-' : totalEtaDate ? `${formatDate(totalEtaDate)}` : ''}{' '}
            </Typography>
            <br />
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {statusSixOrSeven ? '-' : totalEtaDate ? `${formatTime(totalEtaDate)} (${totalEta})` : ''}
            </span>
            <br />
            <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
              {Number(total_miles_remain).toFixed()} / {miles && miles}
            </Typography>
          </>
        );
      },
    },

    {
      field: 'gross',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='CARRIER PAY' field='open_balance' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency} {formatNumber(row?.driver_amount)}
        </Typography>
      ),
    },
  ];
};
