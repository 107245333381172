import { palette } from 'utils/constants';
import React from 'react';
import person from 'assets/images/person.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import uuid from 'react-uuid';
import { Typography } from 'components/Typography';
import { formatAmount } from 'services/IFTAServices';
import { useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import down from 'assets/icons/drivers/down.svg';
import subRight from 'assets/icons/vehicles/sub-right.svg';
import Autocomplete from 'common/Autocomplete';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';

export const useColumns = ({
  data,
  renderTooltip,
  flatRateChecked,
  flatRate,
  setFlatRate,
  previousShipments,
  previousShipment,
  showWarning,
  selectList,
  locationSelect,
  planedShipment,
  planedShipments,
}) => {
  const { currency } = useSelector((state) => state.root);

  return [
    {
      title: 'Vehicle',
      field: 'VehicleID',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (data) => (
        <div>
          <p className='shipment-id' style={{ color: palette.gray900 }}>
            {data?.equipment?.equipment_id}
            <small> {data?.equipment?.equipment_type?.title}</small>
          </p>
        </div>
      ),
    },
    {
      title: 'Trailer',
      field: 'Trailer',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
        minWidth: '200px',
      },
      render: (data) => (
        <div className='d-flex flex-column'>
          <div className='equipment-container d-flex align-items-center'>
            <span
              className='dispatch-details-text'
              style={{
                color: palette.gray700,
              }}
            >
              {' '}
              {data?.equipment?.hooked_to?.equipment_id}
            </span>
            &nbsp;
            <span className='dispatch-details-text'> {data?.equipment?.hooked_to?.equipment_type?.title} </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Driver',
      field: 'Driver',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (data) => (
        <div className='dispatch-driver-header mb-3'>
          {!data?.brokerage_dispatch && <img src={data?.driver1?.image || person} alt='avatar' />}
          <p className='table-data-text' style={{ color: palette.gray700 }}>
            {data?.brokerage_dispatch
              ? data?.brokerage_dispatch?.driver_name
              : data?.driver1
              ? `${data?.driver1?.fname} ${data?.driver1?.lname}`
              : ''}
          </p>
        </div>
      ),
    },
    ...(data?.brokerage_dispatch
      ? []
      : [
          {
            title: 'Compensation',
            field: 'Compensation',
            headerStyle: {
              borderTop: 'none',
            },
            cellStyle: {
              verticalAlign: 'top',
            },
            render: (data) => (
              <>
                {data?.driver1?.compensation_details?.pay_option === 'mile' && (
                  <OverlayTrigger
                    key={uuid()}
                    placement='right'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(data?.driver1)}
                  >
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Pay Per Mile</Typography>
                      <span>
                        {currency}
                        {formatAmount(data?.driver1?.compensation_details?.mile_loaded_amt)} Loaded
                      </span>
                      <span>
                        {currency}
                        {formatAmount(data?.driver1?.compensation_details?.mile_empty_amt)} Empty
                      </span>
                    </div>
                  </OverlayTrigger>
                )}

                {data?.driver1?.compensation_details?.pay_option === 'percentage' && (
                  <OverlayTrigger
                    key={uuid()}
                    placement='right'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(data?.driver1)}
                  >
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Percentage</Typography>
                      <span>{data?.driver1?.compensation_details?.shipment_percentage}% of Shipment</span>
                    </div>
                  </OverlayTrigger>
                )}

                {data?.driver1?.compensation_details?.pay_option === 'per_hour' && (
                  <OverlayTrigger
                    key={uuid()}
                    placement='right'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(data?.driver1)}
                  >
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Per Hour</Typography>
                      <span>
                        {currency}
                        {formatAmount(data?.driver1?.compensation_details?.per_hour_worked)} Per Hour
                      </span>
                    </div>
                  </OverlayTrigger>
                )}
                {data?.driver1?.compensation_details?.pay_option === 'flat_rate' && (
                  <OverlayTrigger
                    key={uuid()}
                    placement='right'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(data?.driver1)}
                  >
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Flat Rate</Typography>
                      <span>
                        {currency}
                        {formatAmount(data?.driver1?.compensation_details?.flat_rate_amount)}
                      </span>
                    </div>
                  </OverlayTrigger>
                )}
              </>
            ),
          },
        ]),
    {
      title: 'Assign Driver From',
      field: 'AssignDriverFrom',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (data) =>
        data?.brokerage_dispatch ? (
          'Current Location'
        ) : (
          <div style={{ minHeight: 100, minWidth: 280 }}>
            <div className='d-flex align-items-center'>
              <Dropdown className='d-inline'>
                <Dropdown.Toggle
                  // className="AvailableButton btn btn-link"
                  id='dropdown-autoclose-outside'
                  style={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    boxShadow: 'none',
                    marginLeft: '0',
                    paddingLeft: '0',
                    height: 40,
                  }}
                >
                  <div className='d-flex align-items-center'>
                    <p
                      className='table-data-text'
                      style={{
                        color: palette.gray700,
                        fontSize: 14,
                      }}
                    >
                      {selectList[locationSelect]}
                    </p>
                    <img src={down} className='ms-2' alt='down' />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-location' style={{ backgroundColor: palette.white }}>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    style={{ color: palette.dark800 }}
                    onClick={() => showWarning()}
                  >
                    Current Location
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => showWarning()}
                    style={{ color: palette.dark800 }}
                  >
                    Previous Shipment
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => showWarning()}
                    style={{ color: palette.dark800 }}
                  >
                    Planned Shipment
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {locationSelect === '2' && (
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <img src={subRight} className='sub-right-icon' alt={subRight} style={{ display: 'inline-block' }} />
                  <Autocomplete
                    options={previousShipments}
                    value={previousShipment}
                    onChange={() => showWarning()}
                    getOptionLabel={(option) => `Shipment ${option.shipment_id}(${data?.customer_name})`}
                    id='select_list'
                    name='list'
                  />
                </div>
                <div className='mt-2'>
                  <p className='dispatch-details-text'>
                    {previousShipment?.first_stop?.stop_point?.address1
                      ? `${previousShipment?.first_stop?.stop_point?.address1},`
                      : ''}
                    {previousShipment?.first_stop?.stop_point?.address2
                      ? `${previousShipment?.first_stop?.stop_point?.address2},`
                      : ''}
                  </p>
                  <p className='dispatch-details-text'>
                    {previousShipment?.first_stop?.stop_point?.state?.name
                      ? `${previousShipment?.first_stop?.stop_point?.state?.name},`
                      : ''}
                    {previousShipment?.first_stop?.stop_point?.city?.name
                      ? `${previousShipment?.first_stop?.stop_point?.city?.name},`
                      : ''}
                    {previousShipment?.first_stop?.stop_point?.zipcode
                      ? previousShipment?.first_stop?.stop_point?.zipcode
                      : ''}
                  </p>
                </div>
              </div>
            )}
            {locationSelect === '3' && (
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <img src={subRight} className='sub-right-icon' alt={subRight} style={{ display: 'inline-block' }} />
                  <Autocomplete
                    options={planedShipments}
                    value={planedShipment}
                    onChange={() => showWarning()}
                    getOptionLabel={(option) => `Shipment (${option.shipment_id} ${data?.customer_name})`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography variant='c1'>
                          Shipment ({option.shipment_id} {data?.customer_name})
                        </Typography>
                      </li>
                    )}
                    id='select_list'
                    name='list'
                  />
                </div>
                <div className='mt-2'>
                  <p className='dispatch-details-text'>
                    {planedShipment?.first_stop?.stop_point?.address1
                      ? `${planedShipment?.first_stop?.stop_point?.address1},`
                      : ''}
                    {planedShipment?.first_stop?.stop_point?.address2
                      ? `${planedShipment?.first_stop?.stop_point?.address2},`
                      : ''}
                  </p>
                  <p className='dispatch-details-text'>
                    {planedShipment?.first_stop?.stop_point?.state?.name
                      ? `${planedShipment?.first_stop?.stop_point?.state?.name},`
                      : ''}
                    {planedShipment?.first_stop?.stop_point?.city?.name
                      ? `${planedShipment?.first_stop?.stop_point?.city?.name},`
                      : ''}
                    {planedShipment?.first_stop?.stop_point?.zipcode
                      ? planedShipment?.first_stop?.stop_point?.zipcode
                      : ''}
                  </p>
                </div>
              </div>
            )}
          </div>
        ),
    },
    {
      title: 'Empty MIles',
      field: 'EmptyMIles',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (data) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {Number(data?.empty_miles).toFixed()}
        </p>
      ),
    },
    {
      title: 'Loaded Miles',
      field: 'LoadedMiles',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (data) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {Number(data?.loaded_miles).toFixed()}
        </p>
      ),
    },
    {
      title: 'ETA to stop a',
      field: 'ETA',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (data) => (
        <div>
          <p className='dispatch-details-text'>{data?.eta}</p>
          <p className='dispatch-details-text'>
            {data?.current_location?.stop_point?.address1 ? `${data?.current_location?.stop_point?.address1},` : ''}
            {data?.current_location?.stop_point?.address2 ? `${data?.current_location?.stop_point?.address2},` : ''}
          </p>
          <p className='dispatch-details-text'>
            {data?.current_location?.stop_point?.state?.name
              ? `${data?.current_location?.stop_point?.state?.name},`
              : ''}
            {data?.current_location?.stop_point?.city?.name ? `${data?.current_location?.stop_point?.city?.name},` : ''}
            {data?.current_location?.stop_point?.zipcode ? data?.current_location?.stop_point?.zipcode : ''}
          </p>
        </div>
      ),
    },
    {
      title: 'Cost',
      field: 'Cost',
      headerStyle: {
        borderTop: 'none',
      },
      cellStyle: {
        verticalAlign: 'top',
      },
      render: (data) => (
        <div>
          {flatRateChecked ? (
            <CustomInput
              value={flatRate}
              labelStyle={{ margin: 0 }}
              onChange={(e) => setFlatRate(e.target.value)}
              style={{
                width: 100,
                borderRadius: 6,
                marginLeft: 8,
                height: 30,
              }}
            />
          ) : (
            <p className='dispatch-details-text' style={{ color: palette.gray700 }}>
              {currency}
              {data?.brokerage_dispatch
                ? formatAmount(data?.shipment_cost?.cost)
                : formatAmount(Number(data?.driver1_amount) + Number(data?.driver2_amount))}
            </p>
          )}
        </div>
      ),
    },
  ];
};
