import axios from '../services/axios';

export async function GetInsurance() {
  const { data } = await axios.get(`/customer/insurance-policies`);
  return data;
}

export async function validateCSV(body) {
  const { data } = await axios.post('equipment-import/create-validation', body);
  return data;
}

// New
export async function getInsurancePolicies() {
  const { data } = await axios.get(`/customer/insurance-policies`);
  return data;
}

export async function updateInsurancePolicies(body) {
  const { data } = await axios.post(`/customer/update-insurance-policies`, body);
  return data;
}

export async function getHighwayTaxPolicy() {
  const { data } = await axios.get(`/customer/highway-tax`);
  return data;
}

export async function updateHighwayTaxPolicy(body) {
  const { data } = await axios.post(`/customer/update-highway-tax`, body);
  return data;
}

export async function getIFTAPolicy() {
  const { data } = await axios.get(`/customer/ifta`);
  return data;
}

export async function updateIFTAPolicy(body) {
  const { data } = await axios.post(`/customer/update-ifta`, body);
  return data;
}

export async function getKYUPolicy() {
  const { data } = await axios.get(`/customer/kyu`);
  return data;
}

export async function updateKYUPolicy(body) {
  const { data } = await axios.post(`/customer/update-kyu`, body);
  return data;
}

export async function getUsers(params) {
  const { data } = await axios.get(`/customer/policy/user-list`, { params });
  return data;
}

export async function applyPolicyToUsers(body) {
  const { data } = await axios.post(`/customer/policy/apply-to-users`, body);
  return data;
}

export async function removePolicyFromUsers(body) {
  const { data } = await axios.post(`/customer/policy/remove-from-users`, body);
  return data;
}

export async function getAppliedEquipmentTypes(params) {
  const { data } = await axios.get(`/customer/policy/applied-to`, { params });
  return data;
}

export async function applyPolicyToEquipmentType(body) {
  const { data } = await axios.post(`/customer/policy/applied-to`, body);
  return data;
}

export async function removePolicyFromEquipmentType(id) {
  const { data } = await axios.delete(`customer/policy/applied-to/${id}`);
  return data;
}
