import React, { useContext, useEffect, useRef, useState } from 'react';
import './GeneralInformationTab.css';
import moment from 'moment';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';
import Chip from 'common/Chip';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { formatPhoneNumber } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import { CarrierContext } from 'context/tableContext';
import { updateCarrierGeneralInfo } from 'Api/Carriers';
import StatsSection from './StatsSection';
import { detailsBasicValidationSchema } from '../../AddCarrier/ValidationSchema';
import styles from '../../../Profile/Profile.module.css';

const whitelist = ['US', 'CA', 'MX'];

const GeneralInformationTab = ({ carrierInfo, onSuccess, loading }) => {
  const { openEdit, setOpenEdit } = useContext(CarrierContext);
  const [imageUrl, setImageUrl] = useState(false);

  const addressRef = useRef(null);
  const { id } = useParams();
  const [toggle, setToggle] = useState(false);
  const showToaster = useShowToaster();

  const saveGeneral = (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('logo', values.logo);
    formData.append('dot', values.dot);
    formData.append('phone_number', values.phone_number);
    formData.append('email', values.email);
    formData.append('address1', values.address);
    formData.append('address2', values.address2 || '');
    formData.append('country_id', values.country.id);
    formData.append('state_id', values.state.id);
    formData.append('city_id', values.city.id);
    formData.append('zipcode', values.zipcode);
    formData.append('mc', values.mc || '');
    formData.append('scac', values.scac || '');
    if (values.can_travel_to.length) {
      values.can_travel_to.forEach((item, index) => {
        formData.append(`can_travel_to[${index}]`, item);
      });
    } else {
      formData.append(`can_travel_to`, '');
    }
    if (values.approved_for.length) {
      values.approved_for.forEach((item, index) => {
        formData.append(`approved_for[${index}]`, item);
      });
    } else {
      formData.append(`approved_for`, '');
    }
    formData.append('approved_for_custom', values.approved_for?.includes('4') ? values.approved_for_custom : '');

    // formData.append('approved_for', values.approved_for)
    formData.append('preferred', Number(values.preferred));
    formData.append('require_trailer_interchange', Number(values.require_trailer_interchange));
    formData.append('trailer_interchange', values.require_trailer_interchange ? values.trailer_interchange : '');
    formData.append(
      'trailer_interchange_from',
      values.require_trailer_interchange ? moment(values.trailer_interchange_from).format('YYYY/MM/DD') : ''
    );
    formData.append(
      'trailer_interchange_expires',
      values.require_trailer_interchange ? moment(values.trailer_interchange_expires).format('YYYY/MM/DD') : ''
    );

    updateCarrierGeneralInfo(formData, id)
      .then(() => {
        onSuccess();
        setToggle(!toggle);
        setOpenEdit('');
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      dot: '',
      phone_number: '',
      address: '',
      email: '',
      mc: '',
      scac: '',
      state: null,
      city: null,
      zipcode: '',
      address2: '',
      country: null,
      can_travel_to: [],
      approved_for: [],
      approved_for_custom: '',
      preferred: false,
      require_trailer_interchange: false,
      trailer_interchange: '',
      trailer_interchange_from: null,
      trailer_interchange_expires: null,
      logo: '',
    },
    onSubmit: saveGeneral,
    validationSchema: detailsBasicValidationSchema,
  });

  useEffect(() => {
    if (openEdit) {
      setToggle(true);
    }
  }, [openEdit]);

  useEffect(() => {
    return () => {
      setOpenEdit('');
    };
  }, []);

  const handleFieldChange = (event, value) => {
    if (event?.target) {
      const { name, value } = event.target;
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(event, value);
    }
  };

  useEffect(() => {
    formik.setFieldValue('name', carrierInfo?.name);
    formik.setFieldValue('logo', carrierInfo?.logo_path);
    setImageUrl(carrierInfo?.logo_path);
    formik.setFieldValue('dot', carrierInfo?.dot);
    formik.setFieldValue('phone_number', carrierInfo?.phone_number);
    formik.setFieldValue('email', carrierInfo?.email);
    formik.setFieldValue('mc', carrierInfo?.mc || '');
    formik.setFieldValue('scac', carrierInfo?.scac || '');
    formik.setFieldValue('address', carrierInfo?.address1);
    formik.setFieldValue('address2', carrierInfo?.address2);
    formik.setFieldValue('city', carrierInfo?.city);
    formik.setFieldValue('country', carrierInfo?.country);
    formik.setFieldValue('state', carrierInfo?.state);
    formik.setFieldValue('zipcode', carrierInfo?.zipcode);
    formik.setFieldValue('can_travel_to', carrierInfo?.can_travel_to ? carrierInfo.can_travel_to : []);
    formik.setFieldValue('approved_for', carrierInfo?.approved_for ? carrierInfo.approved_for : []);
    formik.setFieldValue('approved_for_custom', carrierInfo?.approved_for_custom || '');
    formik.setFieldValue('preferred', !!carrierInfo?.preferred);

    formik.setFieldValue('require_trailer_interchange', !!carrierInfo?.require_trailer_interchange);
    formik.setFieldValue('trailer_interchange', carrierInfo?.trailer_interchange);
    formik.setFieldValue(
      'trailer_interchange_from',
      carrierInfo?.trailer_interchange_from ? moment(carrierInfo?.trailer_interchange_from).toDate() : null
    );
    formik.setFieldValue(
      'trailer_interchange_expires',
      carrierInfo?.trailer_interchange_expires ? moment(carrierInfo?.trailer_interchange_expires).toDate() : null
    );
  }, [carrierInfo]);

  function handleChange(event, countryCode) {
    formik.setFieldValue(
      'can_travel_to',
      event
        ? [...formik.values.can_travel_to, countryCode]
        : formik.values.can_travel_to.filter((code) => code !== countryCode)
    );
  }
  function handleApprovedForChange(event, doc) {
    formik.setFieldValue(
      'approved_for',
      event ? [...formik.values.approved_for, doc] : formik.values.approved_for.filter((code) => code !== doc)
    );
  }

  const handleDrop = (files) => {
    if (files && files[0]) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      formik.setFieldValue('logo', file);
    }
  };

  return (
    <div>
      <div
        className='generalInformationTab'
        style={{
          backgroundColor: palette.white,
          borderColor: palette.gray50,
        }}
      >
        <div className='header' style={{ borderBottomColor: palette.gray50 }}>
          <Typography variant='h4' style={{ color: palette.gray900 }}>
            General Information
          </Typography>
          {!toggle ? (
            <div>
              <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
            </div>
          ) : (
            <div>
              <button
                onClick={() => {
                  setToggle(!toggle);
                  setOpenEdit('');
                }}
                className='driver-compansation-cancel-btn'
              >
                Cancel
              </button>
              {loading ? (
                <CircularProgress size={30} />
              ) : (
                <button onClick={formik.handleSubmit} className='driver-compansation-save-btn'>
                  Save
                </button>
              )}
            </div>
          )}
        </div>
        {toggle ? (
          <div className='d-flex ' style={{ backgroundColor: palette.gray0 }}>
            <div className='information-edit-mode-tab'>
              <div className='information-edit-mode-sections' style={{ borderBottomColor: palette.gray50 }}>
                <Typography variant='s2' style={{ color: palette.gray400 }}>
                  Carrier Name <span className='required'>*</span>
                </Typography>
                <Input
                  name='name'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  placeholder='Carrier Name'
                  style={{ maxWidth: 600, marginTop: 8 }}
                />
              </div>
              {formik.touched.name && formik.errors.name ? (
                <span className='tab-error-message'>{formik.errors.name}</span>
              ) : null}
              <div className='information-edit-mode-sections' style={{ borderBottomColor: palette.gray50 }}>
                <Typography variant='s2' style={{ color: palette.gray400 }}>
                  Phone Number <span className='required'>*</span>
                </Typography>
                <PhoneInput
                  style={{
                    backgroundColor: palette.white,
                    borderColor: palette.gray50,
                  }}
                  addInternationalOption={false}
                  className='input-type'
                  value={formik.values.phone_number}
                  countries={whitelist}
                  onChange={(val) => formik.setFieldValue('phone_number', val, true)}
                  defaultCountry='US'
                  placeholder='(555) 555-1234'
                />
                {formik.touched.phone_number && formik.errors.phone_number ? (
                  <span className='tab-error-message'>{formik.errors.phone_number}</span>
                ) : null}
              </div>
              <div className='information-edit-mode-sections' style={{ borderBottomColor: palette.gray50 }}>
                <Typography variant='s2' style={{ color: palette.gray400 }}>
                  Email <span className='required'>*</span>
                </Typography>
                <Input
                  name='email'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  placeholder='Email'
                  style={{ maxWidth: 600, marginTop: 8 }}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span className='tab-error-message'>{formik.errors.email}</span>
                ) : null}
              </div>
              <div
                className='d-flex justify-content-between gap-2'
                style={{ maxWidth: 600, borderBottomColor: palette.gray50 }}
              >
                <div className='w-100'>
                  <Typography variant='s2' style={{ color: palette.gray400 }}>
                    Carrier DOT <span className='required'>*</span>
                  </Typography>
                  <Input
                    name='dot'
                    onChange={formik.handleChange}
                    value={formik.values.dot}
                    onBlur={formik.handleBlur}
                    placeholder='Carrier DOT'
                    style={{ marginTop: 8 }}
                  />
                  {formik.touched.dot && formik.errors.dot ? (
                    <span className='tab-error-message'>{formik.errors.dot}</span>
                  ) : null}
                </div>
                <div className='w-100'>
                  <Typography variant='s2' style={{ color: palette.gray400 }}>
                    MC
                  </Typography>
                  <Input
                    name='mc'
                    onChange={formik.handleChange}
                    value={formik.values.mc}
                    onBlur={formik.handleBlur}
                    placeholder='Carrier MC'
                    style={{ marginTop: 8 }}
                  />
                  {formik.touched.mc && formik.errors.mc ? (
                    <span className='tab-error-message'>{formik.errors.mc}</span>
                  ) : null}
                </div>
                <div className='w-100'>
                  <Typography variant='s2' style={{ color: palette.gray400 }}>
                    SCAC
                  </Typography>
                  <Input
                    name='scac'
                    onChange={formik.handleChange}
                    value={formik.values.scac}
                    onBlur={formik.handleBlur}
                    placeholder='SCAC'
                    style={{ marginTop: 8 }}
                  />
                  {formik.touched.mc && formik.errors.mc ? (
                    <span className='tab-error-message'>{formik.errors.SCAC}</span>
                  ) : null}
                </div>
              </div>
              <div
                className='information-edit-mode-sections mt-2'
                style={{ borderBottomColor: palette.gray50, maxWidth: 600, marginTop: 8 }}
              >
                <div className='d-flex justify-content-between'>
                  <Typography variant='s2' style={{ color: palette.gray400 }}>
                    Set as preferred carrier?
                  </Typography>
                  <div className='d-flex gap-3'>
                    <CustomCheckbox
                      checked={formik.values.preferred}
                      onChange={(event) => formik.setFieldValue('preferred', event)}
                    />
                  </div>
                </div>
              </div>
              <div
                className='information-edit-mode-sections mt-2'
                style={{ borderBottomColor: palette.gray50, maxWidth: 600, marginTop: 8 }}
              >
                <div className='d-flex justify-content-between'>
                  <Typography variant='s2' style={{ color: palette.gray400 }}>
                    Can Travel To?
                  </Typography>
                  <div className='d-flex gap-3'>
                    <CustomCheckbox
                      checked={formik.values.can_travel_to?.includes('1')}
                      onChange={(event) => handleChange(event, '1')}
                    >
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        USA
                      </Typography>
                    </CustomCheckbox>
                    <CustomCheckbox
                      checked={formik.values.can_travel_to?.includes('2')}
                      onChange={(event) => handleChange(event, '2')}
                    >
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        Canada
                      </Typography>
                    </CustomCheckbox>
                    <CustomCheckbox
                      checked={formik.values.can_travel_to?.includes('3')}
                      onChange={(event) => handleChange(event, '3')}
                    >
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        Mexico
                      </Typography>
                    </CustomCheckbox>
                  </div>
                </div>
              </div>
              <div
                className='information-edit-mode-sections mt-2'
                style={{ borderBottomColor: palette.gray50, maxWidth: 600, marginTop: 8 }}
              >
                <div className='d-flex align-items-center justify-content-between' style={{ minHeight: 33 }}>
                  <Typography variant='s2' style={{ color: palette.gray400 }}>
                    Approved For
                  </Typography>
                  <div className='d-flex gap-3'>
                    <CustomCheckbox
                      checked={formik.values.approved_for?.includes('1')}
                      onChange={(event) => handleApprovedForChange(event, '1')}
                    >
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        FAST
                      </Typography>
                    </CustomCheckbox>
                    <CustomCheckbox
                      checked={formik.values.approved_for?.includes('2')}
                      onChange={(event) => handleApprovedForChange(event, '2')}
                    >
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        TSA
                      </Typography>
                    </CustomCheckbox>
                    <CustomCheckbox
                      checked={formik.values.approved_for?.includes('3')}
                      onChange={(event) => handleApprovedForChange(event, '3')}
                    >
                      <Typography variant='s2' style={{ marginLeft: '8px' }}>
                        TWIC
                      </Typography>
                    </CustomCheckbox>
                    <div className='d-flex align-items-center gap-3'>
                      <CustomCheckbox
                        checked={formik.values.approved_for?.includes('4')}
                        onChange={(event) => handleApprovedForChange(event, '4')}
                      >
                        <Typography variant='s2' style={{ marginLeft: '8px' }}>
                          Custom
                        </Typography>
                      </CustomCheckbox>
                      {formik.values.approved_for?.includes('4') && (
                        <Input
                          width='120px'
                          name='approved_for_custom'
                          value={formik.values.approved_for_custom}
                          onChange={(e) => formik.setFieldValue('approved_for_custom', e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='information-edit-mode-tab'>
              <Typography variant='s2' style={{ color: palette.gray400 }}>
                Address <span className='required'>*</span>
              </Typography>
              <AddressFields
                values={formik.values}
                handleChange={handleFieldChange}
                hideLabel
                ref={addressRef}
                limitCountries
              />
              <div className='mt-4'>
                <div className='d-flex align-items-center gap-2'>
                  <CustomCheckbox
                    type='switch'
                    checked={formik.values.require_trailer_interchange}
                    name='require_trailer_interchange'
                    onChange={(checked) => {
                      formik.setFieldValue('require_trailer_interchange', checked);
                    }}
                  />

                  <Typography
                    variant='s2'
                    style={{
                      color: palette.gray700,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Require Trailer Interchange
                  </Typography>
                </div>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-baseline flex-wrap gap-2' style={{ marginTop: 10 }}>
                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                      Carrier Trailer Interchange
                    </Typography>
                    <InputWithIcon
                      width='100px'
                      name='trailer_interchange'
                      type='number'
                      disabled={!formik.values.require_trailer_interchange}
                      value={formik.values.trailer_interchange}
                      onChange={(e) => formik.setFieldValue('trailer_interchange', e.target.value)}
                      leftIcon={<DollarIcon height={32} />}
                      error={formik.touched.trailer_interchange && formik.errors.trailer_interchange}
                    />
                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                      is required for owned equipment usage. Agreement is from
                    </Typography>
                    <div>
                      <DatePicker
                        width='135px'
                        name='trailer_interchange_from'
                        value={formik.values.trailer_interchange_from}
                        onChange={(val) => formik.setFieldValue('trailer_interchange_from', val)}
                        maxDate={
                          formik.values.trailer_interchange_expires ? formik.values.trailer_interchange_expires : null
                        }
                        disablePast
                        onBlur={formik.handleBlur}
                        disabled={!formik.values.require_trailer_interchange}
                        error={formik.touched.trailer_interchange_from && formik.errors.trailer_interchange_from}
                      />
                    </div>

                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                      and expires
                    </Typography>
                    <div>
                      <DatePicker
                        width='135px'
                        name='trailer_interchange_expires'
                        value={formik.values.trailer_interchange_expires}
                        onChange={(val) => formik.setFieldValue('trailer_interchange_expires', val)}
                        minDate={
                          formik.values.trailer_interchange_from ? formik.values.trailer_interchange_from : new Date()
                        }
                        onBlur={formik.handleBlur}
                        disablePast
                        disabled={!formik.values.require_trailer_interchange || !formik.values.trailer_interchange_from}
                        error={formik.touched.trailer_interchange_expires && formik.errors.trailer_interchange_expires}
                      />
                    </div>
                  </div>

                  <div className='d-flex flex-column mt-4 gap-2'>
                    <Typography variant='s2' style={{ color: palette.gray400 }}>
                      Logo
                    </Typography>
                    <div className='d-flex  gap-4 align-items-start'>
                      <Uploader
                        type={2}
                        uploaderLabel='Upload Logo'
                        document={formik.values.logo}
                        onDrop={handleDrop}
                        style={{ marginTop: 8 }}
                        onRemove={() => {
                          setImageUrl(null);
                          formik.setFieldValue('logo', '');
                        }}
                      />
                      <label className={styles.avatar_wrapper}>
                        <figure className={styles.profile_image_wrapper}>
                          {!formik.values.logo ? (
                            <span />
                          ) : (
                            <img
                              src={imageUrl}
                              alt='img'
                              width={100}
                              height={100}
                              style={{ borderRadius: '50%', objectFit: 'contain' }}
                            />
                          )}
                        </figure>
                      </label>
                    </div>
                  </div>

                  {/* <ErrorMessage error={touched.trailer_interchange && errors.trailer_interchange} /> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex'>
              <div className='information'>
                <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
                  <Typography variant='b2' style={{ color: palette.gray500 }}>
                    Company Name
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {carrierInfo?.name || '-'}
                  </Typography>
                </div>

                <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
                  <Typography variant='b2' style={{ color: palette.gray500 }}>
                    Phone Number
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.indigo500 }}>
                    <a href={`tel:${carrierInfo?.phone_number}`}> {formatPhoneNumber(carrierInfo?.phone_number)} </a>
                  </Typography>
                </div>
                <div className='info-container border-block' style={{ borderBottomColor: palette.gray50 }}>
                  <Typography variant='b2' style={{ color: palette.gray500 }}>
                    Email
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.indigo500 }}>
                    <a href={`mailto:${carrierInfo?.email}`}> {carrierInfo?.email || '-'}</a>
                  </Typography>
                </div>
              </div>
              <div className='information'>
                <div className='info-container address w-100' style={{ borderBottomColor: palette.gray50 }}>
                  <Typography variant='b2' style={{ color: palette.gray500 }}>
                    Address
                  </Typography>
                  <div className='address-details'>
                    <Typography variant='b2' style={{ color: palette.gray900 }}>
                      {carrierInfo?.address1}
                    </Typography>
                    {carrierInfo?.address2 && (
                      <Typography variant='b2' style={{ color: palette.gray900 }}>
                        {carrierInfo?.address2}
                      </Typography>
                    )}
                    <Typography variant='b2' style={{ color: palette.gray900 }}>
                      {carrierInfo?.state?.name}, {carrierInfo?.city?.name}, {carrierInfo?.zipcode}
                    </Typography>
                    <Typography variant='b2' style={{ color: palette.gray900 }}>
                      {carrierInfo?.country?.name}
                    </Typography>
                  </div>
                </div>

                <div
                  className='d-flex flex-column border-block align-self-start'
                  style={{ borderBottomColor: palette.gray50 }}
                >
                  <Typography variant='b2' style={{ color: palette.gray500 }}>
                    Tax ID
                  </Typography>
                  <Chip
                    label={
                      carrierInfo?.ein
                        ? carrierInfo.ein[2] !== '-'
                          ? `${carrierInfo.ein.slice(0, 2)}-${carrierInfo.ein.slice(2)}`
                          : carrierInfo?.ein
                        : '-'
                    }
                    labelColor={palette.gray900}
                    bgColor='#F0F2F7'
                    fontSize='14px'
                    fontWeight='500'
                  />
                </div>
              </div>
            </div>
            <StatsSection carrierId={id} carrier={carrierInfo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralInformationTab;
