import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SCreateQuoteForm = styled.form`
  margin-bottom: 24px;

  .create-quote-section {
    background-color: ${palette.white};
    border-radius: 8px;
    box-shadow: rgba(152, 161, 178, 0.1) 0 0 0 1px, rgb(143 145 149 / 25%) 0 30px 70px -10px,
      rgb(44 43 43 / 20%) 0 6px 30px 0;
    padding: 20px;
  }

  .unsaved-animation {
    background-color: ${palette.red0};

    animation: unsaved 1s;
    animation-iteration-count: infinite;
  }

  @keyframes unsaved {
    50% {
      background-color: #fff;
    }
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    color: ${palette.gray700};
    white-space: nowrap;
    font-family: Inter, sans-serif;
    padding: 12px 8px;
    border-bottom: 1px solid ${palette.gray50};
  }

  td {
    padding: 6px 8px;
    border: none;
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const SBilling = styled.div`
  margin-bottom: 52px;

  .billing-info-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    margin-bottom: 28px;
  }
`;

export const SSchedule = styled.div`
  margin-bottom: 52px;

  .empty-label {
    height: 24px;
  }

  .shipment-leg {
    position: relative;
    padding-left: 12px;
    margin-bottom: 64px;

    &:last-of-type {
      margin-bottom: 12px;
    }
  }

  .leg-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    border-radius: 0 16px 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    background: ${palette.indigo100};
    box-shadow: ${palette.white} 8px 0px 20px 4px;
  }

  .shipment-stop {
    display: flex;
    column-gap: 24px;

    .shipment-stop-column {
      &:not(:first-child) {
        flex-grow: 1;
        flex-basis: 0;
      }

      &:first-child {
        width: 350px;
      }
    }

    .shipment-stop-input-label {
      width: 150px;
      flex-shrink: 0;
      margin-bottom: 0;
      margin-top: 2px;

      &.shipment-stop-left-input-label {
        width: 96px;
      }
    }
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  :hover span {
    text-decoration: underline;
  }
`;

export const SMergedInputs = styled.div`
  width: 100%;
  display: flex;

  input,
  .merged-input {
    border-radius: 6px 0 0 6px;
    vertical-align: top;
  }

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    width: 100px;
    border-radius: 0 6px 6px 0;
    height: 25px;
  }

  > div:first-child {
    width: 100%;
  }
`;

export const SAddStopIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid ${palette.gray100};
  border-radius: 12px;
  background-color: ${palette.white};

  :hover {
    background-color: ${palette.indigo0};
  }
`;
