import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPageWrapper = styled.div`
  padding-top: 16px;
  margin-bottom: 42px;

  .page-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .box-content-wrapper {
    height: 100%;
    border: 1px solid ${palette.gray50};
    padding: 16px 8px 16px 20px;
  }

  .scrollable-container {
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .stat-boxes-wrapper {
    min-width: 1554px;
    display: flex;
  }
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const SWidgetContainer = styled.div`
  min-width: 300px;
`;

export const SCountBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;
  width: 100%;
  border: 1px solid ${palette.gray50};

  padding: 16px 8px 16px 20px;
  cursor: pointer;
  transition: 0.4s;
  min-height: 170px;

  :hover {
    background-color: ${palette.indigo0};

    .left-banner {
      background-image: linear-gradient(to right, ${palette.indigo0}, transparent);
    }

    .right-banner {
      background-image: linear-gradient(to left, ${palette.indigo0}, transparent);
    }
  }
`;

export const SBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;
  width: 100%;

  @media (min-width: 1800px) {
    max-width: 500px !important;
  }

  @media (min-width: 2000px) {
    max-width: 800px !important;

    canvas {
      max-height: 380px !important;
    }
  }

  .download-text {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    justify-content: flex-end;
    margin-right: 8px;
  }

  .download-text span {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #4f5aed;
    border-bottom: transparent;
  }

  .download-text:hover span {
    text-decoration: underline;
  }

  .MuiTableCell-root {
    padding: 8px 4px;
    border-bottom: none;
  }

  .MuiTableRow-root.MuiTableRow-head th {
    height: 32px !important;
  }

  .MuiTableRow-root.MuiTableRow-head {
    border-top: none;
    border-bottom: 1px solid rgb(224, 224, 224);
  }

  .receivable-chart-section-tag-indigo {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${palette.indigo500};
    padding: 2px 10px;
    background: ${palette.indigo0};
    border-radius: 10px;
    margin-left: 10px;
  }

  .receivable-chart-section-tag-red {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${palette.red500};
    padding: 2px 10px;
    background: ${palette.red0};
    border-radius: 10px;
    margin-left: 10px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${palette.gray200};
    border-radius: 8px;
  }

  .export-csv-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: ${palette.indigo500};
    padding: 2px 8px;
    text-decoration: none;
  }

  .export-csv-btn.non-clickable {
    pointer-events: none;
  }

  .export-csv-btn:hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${palette.indigo500};
  }
`;

export const SAvatarGroupWrapper = styled.div`
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SBannerWrapper = styled.div`
  position: relative;
  transition: 0.4s;

  .left-banner {
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(to right, #fff, transparent);
    width: 20px;
    height: 100%;
    z-index: 2;
  }

  .right-banner {
    position: absolute;
    right: 0;
    top: 0;
    background-image: linear-gradient(to left, #fff, transparent);
    width: 20px;
    height: 100%;
    z-index: 2;
  }
`;

export const SSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 40px;

  .underline-text:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SSmallChartBox = styled.div`
  padding: ${({ $mapsPage }) => ($mapsPage ? '0' : '16px 8px 16px 20px')};
  width: 16.67%;
  border-top: ${({ $mapsPage }) => ($mapsPage ? '' : `1px solid ${palette.gray50}`)};
  border-bottom: ${({ $mapsPage }) => ($mapsPage ? '' : `1px solid ${palette.gray50}`)};

  border-left: 1px solid ${palette.gray50};
  transition: 0.4s;
  background: #ffffff;
  background: ${({ $mapsPage }) => ($mapsPage ? 'none' : '#ffffff')};

  margin: ${({ $mapsPage }) => ($mapsPage ? '10px' : '')};
  border-radius: ${({ $mapsPage }) => ($mapsPage ? '50%' : '')};

  :first-child {
    border-left: none;
  }
`;

export const SDateRange = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 2px 5px rgba(89, 96, 120, 0.1);

  .range-item {
    padding: 1px 6px;
    color: ${palette.gray500} !important;
    border-radius: 4px;
    cursor: default;
    transition: 0.4s;
  }

  .range-item:hover {
    background-color: ${palette.indigo0} !important;
    color: ${palette.gray700} !important;
  }

  .range-item_active {
    padding: 1px 6px;
    color: ${palette.indigo500} !important;
    border-radius: 4px;
    cursor: default;
  }
`;

export const SActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .underline-text:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
