import React, { useState, useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatNumber, getDaysNumber, palette } from 'utils/constants';
import { getGrandTotal, getMeasurementTypes, getQuantityTypes, getWeightTypes } from 'Api/Planner';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomTextArea from 'components/CustomTextArea';
import { DatePicker, TimePicker } from 'common/Pickers';
import { getAllTrailers } from 'Api/Map';
import useDateFormat from 'hooks/useDateFormat';
import { getCargoTotals } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { getDeliveryCargos } from 'components/CreateShipment/helpers';
import Autocomplete from 'common/Autocomplete';
import { SMergedInputs } from 'components/CreateShipment/CreateShipment.styles';
import Input, { InputWithText } from 'common/Input';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import ErrorMessage from 'common/ErrorMessage';
import sm from '../../ShipmentStops/AddStopPoint/AddStopPoint.module.css';

import CustomInput from '../../helpers/CustomInput';
import CustomSelect from '../../ShipmentStops/helpers/CustomSelect';
import { ShipmentContext } from '../../Layout';
import { PopoverCreatShipmentItem } from '../../ShipmentStops/helpers/PopoverCreatShipment';
import {
  EQUIPMENT_ACTION_DELIVERY,
  QUANTITY,
  SCHEDULED_DATE_TYPE,
  WEIGHT_TYPE,
} from '../../ShipmentStops/helpers/constants';

const DeliveryLTL = ({
  stop,
  values,
  setFieldValue,
  namePrefix,
  index,
  arrayHelpers,
  stopPoints,
  errors,
  touched,
  indexPrefix,
  lastChild,
  setFieldTouched,
  // allPrevStops,
}) => {
  const { formatTime } = useDateFormat();
  const scrollRef = useRef(null);
  const endDateScrollRef = useRef(null);
  const {
    updateEquipmentId,
    onChangeAddStopPointModal,
    setCurrentStopAddIndex,
    currentStopAddIndex,
    currentStopAddValue,
    shipmentSettings,
    createShipmentData,
  } = useContext(ShipmentContext);

  const PLACE = `${namePrefix}[${index}]`;
  const PLACE_ERRORS = errors?.shipmentsLegs && errors?.shipmentsLegs?.[indexPrefix]?.stops?.[index];
  const PLACE_TOUCHED = touched?.shipmentsLegs && touched?.shipmentsLegs?.[indexPrefix]?.stops?.[index];
  const prefix = `shipmentsLegs[${indexPrefix}].stops[${index}]`;
  const currentStop = values.shipmentsLegs[indexPrefix].stops[index];

  const cargos = getDeliveryCargos(values.shipmentsLegs, currentStop);
  const totalValues = getCargoTotals(currentStop.connected_cargos);

  const [show, setShow] = useState(false);
  const [condInfoIcon, setCondInfoIcon] = useState({});
  const [selectedStopPointId, setSelectedStopPointId] = useState('');
  const [localEquipmentIdData, setLocalEquipmentIdData] = useState([]);

  const [weightTypes, setWeightTypes] = useState([]);
  const [, setMeasurementTypes] = useState([]);
  const [quantityTypes, setQuantityTypes] = useState([]);

  const getEquipmentIdInType = () => {
    getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8' } }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `
                                     ${el?.equipment_id || ''}
                                    (${el?.make || ''}
                                     ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setLocalEquipmentIdData([...equipmentId]);
        updateEquipmentId([...equipmentId]);
      }
    });
  };

  useEffect(() => {
    getEquipmentIdInType();
  }, []);

  const onChangeAction = (obj) => {
    const key = +obj.key;
    key === 3 && getEquipmentIdInType();
  };

  const getAdditionalData = async () => {
    try {
      const [{ data: measurement }, { data: quantity }, { data: weight }] = await Promise.all([
        getMeasurementTypes(),
        getQuantityTypes(),
        getWeightTypes(),
      ]);
      setWeightTypes(weight || []);
      setMeasurementTypes(measurement || []);
      setQuantityTypes(quantity || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onChangeTimes = (value, name, pointId) => {
    setFieldValue(`${prefix}.${name}`, value);

    if (value) {
      const completedChangeTimeInput = moment(value).isValid();
      const stopPointIdData = pointId || selectedStopPointId;

      if (completedChangeTimeInput && stopPointIdData) {
        const date = moment(stop.scheduled_date).toDate();
        const dayMoment = moment(date).day();
        const day = getDaysNumber(dayMoment);
        const workingHour = stopPointIdData?.stop_point_working_hour || [];
        const thisDay = workingHour.find((el) => +el.week_day === +day);

        const recommendedStart = thisDay?.start
          ? moment(`${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${thisDay?.start}`)
          : null;
        const recommendedEnd = thisDay?.end
          ? moment(`${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${thisDay?.end}`)
          : null;

        if ([1, 3].includes(Number(stop?.scheduled_type))) {
          const statTimeValue = name === 'startTime' ? value : stop.startTime;
          const endTimeValue = name === 'endTime' ? value : stop.endTime;
          const timeDateStart = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(statTimeValue).format('HH:mm')}`
          );
          const timeDateEnd = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(endTimeValue).format('HH:mm')}`
          );

          if (
            timeDateStart.isBefore(recommendedStart) ||
            timeDateStart.isAfter(recommendedEnd) ||
            timeDateEnd.isBefore(recommendedStart) ||
            timeDateEnd.isAfter(recommendedEnd)
          ) {
            setCondInfoIcon({ start: recommendedStart, end: recommendedEnd });
          } else {
            setCondInfoIcon({});
          }
        } else if (Number(stop?.scheduled_type) === 2 || Number(stop?.scheduled_type) === 1 || !stop?.scheduled_type) {
          const timeDateInput = moment(
            `${moment(stop?.scheduled_date).format('YYYY-MM-DD')} ${moment(value).format('HH:mm')}`
          );
          if (timeDateInput.isBefore(recommendedStart) || timeDateInput.isAfter(recommendedEnd)) {
            setCondInfoIcon({ start: recommendedStart, end: recommendedEnd });
          } else {
            setCondInfoIcon({});
          }
        }
      }
    } else {
      setCondInfoIcon({});
    }
  };

  const onChangeTimeValidate = (date, scheduled_type, field, selectedStop) => {
    if (Number(createShipmentData?.frequency) === 2) {
      return;
    }

    setFieldValue(`${prefix}.${field}`, date);

    if (field === 'scheduled_date') {
      const startDate = moment(date);
      const endDate = moment(currentStop.scheduled_date_to);
      if (endDate.isBefore(startDate)) {
        setFieldValue(`${prefix}.scheduled_date_to`, startDate.toDate());
      }
    }

    const dayMoment = moment(date).day();
    const day = getDaysNumber(dayMoment);
    const type = scheduled_type || stop.scheduled_type;
    const workingHour = (selectedStop || selectedStopPointId)?.stop_point_working_hour || [];
    const thisDay = workingHour.find((el) => +el.week_day === +day);
    if (!!thisDay && [1, 3].includes(+type) && !!(selectedStop || selectedStopPointId)) {
      const end = thisDay?.end || '';
      const start = thisDay?.start || '';
      if (field === 'scheduled_date_to') {
        setFieldValue(`${prefix}.endTime`, moment(end, 'HH:mm').toDate());
      } else {
        setFieldValue(`${prefix}.startTime`, moment(start, 'HH:mm').toDate());
      }
    } else {
      if (field === 'scheduled_date_to') {
        setFieldValue(`${prefix}.endTime`, null);
        setFieldTouched(`${prefix}.endTime`, false);
      } else {
        setFieldValue(`${prefix}.startTime`, null);
        setFieldTouched(`${prefix}.startTime`, false);
      }
    }
  };

  const onChangeStopPointId = (key, updateScheduledType) => {
    const selectedStopPointId = stopPoints.find((el) => +el.key === +key);
    setSelectedStopPointId(selectedStopPointId);
    if (selectedStopPointId?.working_hour_by && updateScheduledType) {
      setFieldValue(`${PLACE}.scheduled_type`, selectedStopPointId?.working_hour_by?.toString());
      onChangeScheduledType(
        SCHEDULED_DATE_TYPE.find((i) => i.key === selectedStopPointId?.working_hour_by),
        selectedStopPointId
      );
    }
  };

  const onChangeScheduledType = (e, selectedStopPointId) => {
    if (Number(createShipmentData?.frequency) !== 2) {
      setFieldValue(`${prefix}.endTime`, null);
    }
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, e.key, 'scheduled_date', selectedStopPointId);
    }
    if (stop.scheduled_date_to) {
      const date = moment(stop.scheduled_date_to, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, e.key, 'scheduled_date_to', selectedStopPointId);
    }
  };

  const shouldUpdateStops = (nextProps, props, name) => {
    const next = nextProps.formik.values?.shipmentsLegs?.[indexPrefix]?.stops?.[index];
    const current = props.formik.values?.shipmentsLegs?.[indexPrefix]?.stops?.[index];
    const nextError = nextProps.formik.errors?.shipmentsLegs?.[indexPrefix]?.stops?.[index];
    const currentError = props.formik.errors?.shipmentsLegs?.[indexPrefix]?.stops?.[index];
    const nextTouched = nextProps.formik.touched?.shipmentsLegs?.[indexPrefix]?.stops?.[index];
    const currentTouched = props.formik.touched?.shipmentsLegs?.[indexPrefix]?.stops?.[index];

    switch (name) {
      case 'stop_point_id':
        return true;
      case 'scheduled_type':
        return next?.stop_point_id !== current?.stop_point_id;
      case 'scheduled_date':
        return next?.scheduled_type !== current?.scheduled_date;
      case 'endTime':
        return (
          nextError?.startTime !== currentError?.startTime ||
          nextTouched?.endTime === currentTouched?.endTime ||
          !!props?.formik.submitForm
        );
      case 'startTime':
        return (
          nextError?.endTime !== currentError?.endTime ||
          nextTouched?.startTime === currentTouched?.startTime ||
          !!props?.formik.submitForm
        );
      default:
    }
  };

  const getGrandTotalDefault = () => {
    getGrandTotal()
      .then((res) => {
        if (res && res?.data) {
          const qtyObj = {
            pieces: QUANTITY[0].key,
            units: QUANTITY[1].key,
            qty: QUANTITY[2].key,
          };
          const weightObj = {
            lbs: WEIGHT_TYPE[0].key,
            kgs: WEIGHT_TYPE[1].key,
          };

          setFieldValue(`${prefix}.quantity_type`, String(qtyObj[res.data?.default_qty_type]));
          setFieldValue(`${prefix}.weight_type`, String(weightObj[res.data?.default_weight]));
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  useEffect(() => {
    getAdditionalData();
    Number(stop?.equipment_action) === 3 && getEquipmentIdInType();
    (!stop.weight_type || !stop.quantity_type) && getGrandTotalDefault();
    const pointId = stopPoints?.find((el) => +el.key === +stop.stop_point_id);
    onChangeTimes(stop.startTime, 'startTime', pointId);
  }, []);

  useEffect(() => {
    !!stop?.stop_point_id && onChangeStopPointId(stop.stop_point_id, false);
  }, [stop.stop_point_id]);

  useEffect(() => {
    if (Array.isArray(stopPoints) && stopPoints.length > 0) {
      const pointId = stopPoints?.find((el) => +el.key === +stop.stop_point_id);
      !!pointId && setSelectedStopPointId(pointId);
    }
  }, [stopPoints, stop]);

  useEffect(() => {
    if (currentStopAddValue && currentStopAddIndex === PLACE) {
      setFieldValue(`${prefix}.stop_point_id`, currentStopAddValue);
    }
  }, [currentStopAddValue]);

  return (
    <div
      className={`
            ${sm.pickUp_container}
            ${index === 0 ? sm.first : lastChild === index ? sm.last : ''}`}
    >
      {/* Scheduled Date Id */}
      <div className={sm.field_error_wrapper}>
        <CustomSelect
          field={{
            name: `${prefix}.stop_point_id`,
            value: currentStop.stop_point_id,
          }}
          form={{ setFieldValue }}
          isSearchable
          options={stopPoints}
          menuStyles={{ maxWidth: 480 }}
          defaultText='Select Stop Point'
          onChange={(value) => onChangeStopPointId(value?.key, true)}
          onAddStopPoint={() => {
            onChangeAddStopPointModal(true);
            setCurrentStopAddIndex(PLACE);
          }}
          styles={{ maxWidth: 480, height: 32, margin: '8px 0 4px' }}
          shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'stop_point_id')}
          isSearchableStyles={{ maxWidth: 480, width: '100%', margin: '8px 0 4px', height: 32 }}
        />
        <ErrorMessage error={PLACE_TOUCHED?.stop_point_id && PLACE_ERRORS?.stop_point_id} />
      </div>

      {/* Scheduled Date Type */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Scheduled Date Type
        </Typography>
        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{
              name: `${prefix}.scheduled_type`,
              value: currentStop.scheduled_type,
            }}
            form={{ setFieldValue }}
            menuStyles={{ width: 400 }}
            options={SCHEDULED_DATE_TYPE}
            styles={{ maxWidth: 400, height: 22 }}
            onChange={(e) => onChangeScheduledType(e)}
            shouldUpdate={() => true}
          />
          <ErrorMessage error={PLACE_TOUCHED?.scheduled_type && PLACE_ERRORS?.scheduled_type} />
        </div>
      </div>

      {/* Scheduled Date/Time */}
      {Number(createShipmentData?.frequency) !== 2 && (
        <div className='d-flex justify-content-start align-items-start mt-2'>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start' }}>
            Scheduled Date/Time
          </Typography>
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex justify-content-start align-items-start gap-2'>
              <div ref={scrollRef}>
                <DatePicker
                  width='130px'
                  size='small'
                  name={`${prefix}.scheduled_date`}
                  value={currentStop.scheduled_date}
                  onChange={(date) => onChangeTimeValidate(date, null, 'scheduled_date')}
                  disablePast={!shipmentSettings?.allow_past_date}
                  error={PLACE_TOUCHED?.scheduled_date && PLACE_ERRORS?.scheduled_date}
                />
              </div>
              <div>
                <TimePicker
                  size='small'
                  width='100px'
                  value={currentStop.startTime}
                  onChange={(time) => onChangeTimes(time, 'startTime')}
                  error={PLACE_TOUCHED?.startTime && PLACE_ERRORS?.startTime}
                />
              </div>
            </div>

            {[1, 3].includes(+stop.scheduled_type) && (
              <div className='d-flex justify-content-start align-items-start gap-2'>
                <Typography variant='s2' className='mt-1'>
                  to
                </Typography>
                <div ref={endDateScrollRef}>
                  <DatePicker
                    width='130px'
                    size='small'
                    name={`${prefix}.scheduled_date_to`}
                    value={currentStop.scheduled_date_to}
                    onChange={(date) => onChangeTimeValidate(date, null, 'scheduled_date_to')}
                    minDate={moment(currentStop.scheduled_date).toDate()}
                    error={PLACE_TOUCHED?.scheduled_date_to && PLACE_ERRORS?.scheduled_date_to}
                  />
                </div>
                <div>
                  <TimePicker
                    size='small'
                    width='100px'
                    value={currentStop.endTime}
                    onChange={(time) => onChangeTimes(time, 'endTime')}
                    error={PLACE_TOUCHED?.endTime && PLACE_ERRORS?.endTime}
                  />
                </div>
              </div>
            )}
          </div>
          {Object.keys(condInfoIcon).length > 0 && (
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => (
                <Tooltip id={`${indexPrefix}-${index}-recommended`} {...props}>
                  <span>
                    Stop point may be closed on arrival. Working hours are&nbsp;
                    {moment(condInfoIcon?.start).isValid() && formatTime(condInfoIcon?.start)}&nbsp; to&nbsp;
                    {moment(condInfoIcon?.end).isValid() && formatTime(condInfoIcon?.end)}
                  </span>
                </Tooltip>
              )}
            >
              <div style={{ marginLeft: '12px' }}>
                <WarningIcon width={15} height={15} style={{ cursor: 'pointer' }} />
              </div>
            </OverlayTrigger>
          )}
        </div>
      )}

      {/* Equipment Action */}
      <div className={sm.field_wrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Action
        </Typography>

        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{
              name: `${prefix}.equipment_action`,
              value: currentStop.equipment_action,
            }}
            form={{ setFieldValue }}
            onChange={onChangeAction}
            menuStyles={{ width: 400 }}
            styles={{ maxWidth: 400, height: 22 }}
            options={EQUIPMENT_ACTION_DELIVERY}
          />
          <ErrorMessage error={PLACE_TOUCHED?.equipment_action && PLACE_ERRORS?.equipment_action} />
        </div>
      </div>

      {+stop.equipment_action === 3 && (
        <div className={sm.field_wrapper}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
            Equipment
          </Typography>
          <SubRight style={{ width: 20 }} />
          <CustomSelect
            field={{
              name: `${prefix}.equipment_id`,
              value: currentStop.equipment_id,
            }}
            form={{ setFieldValue }}
            isSearchable
            styles={{ maxWidth: 380, height: 22 }}
            menuStyles={{ width: 380 }}
            isSearchableStyles={{ width: 380, height: 22 }}
            options={localEquipmentIdData}
          />
          <ErrorMessage error={PLACE_TOUCHED?.equipment_id && PLACE_ERRORS?.equipment_id} />
        </div>
      )}

      {/* Delivery Number */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Delivery Number
        </Typography>
        <div className={sm.field_error_wrapper}>
          <CustomInput
            name={`${prefix}.number`}
            className={sm.customInputField}
            value={currentStop.number}
            onChange={(e) => setFieldValue(`${prefix}.number`, e.target.value)}
          />
        </div>
      </div>

      {/* Commodity */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Commodity
        </Typography>
        <div style={{ marginTop: '10px' }}>
          <Autocomplete
            multiple
            width='400px'
            placeholder={currentStop?.connected_cargos?.length ? '' : 'Select Cargo for Delivery'}
            labelKey='title'
            options={cargos}
            getOptionLabel={(option) =>
              `Stop ${option.stopIndex} - ${option.commodity?.title}${
                option.sku_barcode ? ` (${option.sku_barcode})` : ''
              }`
            }
            getTagLabel={(option) =>
              `Stop ${option.stopIndex} - ${option.commodity?.title}${
                option.sku_barcode ? ` (${option.sku_barcode})` : ''
              }`
            }
            value={stop.connected_cargos || []}
            onChange={(e, value) => setFieldValue(`${prefix}.connected_cargos`, value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>
      </div>

      {/* Qty */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Qty
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {!currentStop.connected_cargos?.length ? (
              <>
                <div className={sm.field_error_wrapper}>
                  <CustomInput
                    name={`${prefix}.quantity`}
                    type='number'
                    className={sm.customInputField}
                    style={{ width: 200, borderRadius: '4px 0 0 4px' }}
                    value={currentStop.quantity}
                    onChange={(e) => setFieldValue(`${prefix}.quantity`, e.target.value)}
                    labelStyle={{ marginBottom: -0.6 }}
                  />
                </div>

                <div className={sm.field_error_wrapper}>
                  <CustomSelect
                    field={{
                      name: `${prefix}.quantity_type`,
                      value: currentStop.quantity_type,
                    }}
                    form={{ setFieldValue }}
                    name={`${prefix}.quantity_type`}
                    styles={{ width: 200, height: 22, borderRadius: '0 4px 4px 0' }}
                    menuStyles={{ width: 200 }}
                    options={QUANTITY}
                  />
                </div>
              </>
            ) : (
              <div style={{ marginTop: '10px' }}>
                <SMergedInputs>
                  <Input
                    width='250px'
                    type='number'
                    size='small'
                    value={totalValues.expected_quantity}
                    onKeyDown={(e) => {
                      blockNotNumberChars(e);
                      blockNonPositiveNumbers(e);
                    }}
                    disabled
                  />
                  <div>
                    <Autocomplete
                      width='150px'
                      size='small'
                      name='cargos[0].expected_quantity_type'
                      options={quantityTypes}
                      value={currentStop.connected_cargos?.[0]?.expected_quantity_type || null}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled
                    />
                  </div>
                </SMergedInputs>
              </div>
            )}
          </div>
          {!!errors?.legs &&
            !currentStop.connected_cargos?.length &&
            PLACE_TOUCHED?.quantity &&
            PLACE_ERRORS?.quantity && (
              <Typography variant='c2' style={{ color: palette.red500 }}>
                {!!errors?.legs && PLACE_ERRORS.quantity}
              </Typography>
            )}
        </div>
      </div>

      {/* Dimensions */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Dimensions
        </Typography>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {!currentStop.connected_cargos?.length ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={currentStop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='L'
                name={`${prefix}.dimensions.l`}
                value={currentStop.dimensions.l}
                onChange={(e) => setFieldValue(`${prefix}.dimensions.l`, e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          ) : currentStop.connected_cargos?.length === 1 ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={currentStop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='L'
                name='connected_cargos[0].expected_dimensions.l'
                value={Number(totalValues.length || 0).toFixed(2)}
                onKeyDown={blockNonPositiveNumbers}
                disabled
              />
            </div>
          ) : null}

          {(!currentStop.connected_cargos?.length || currentStop.connected_cargos?.length === 1) && (
            <div className={sm.dimensions_x}>x</div>
          )}
          {!currentStop.connected_cargos?.length ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={currentStop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='W'
                name={`${prefix}.dimensions.w`}
                value={currentStop.dimensions.w}
                onChange={(e) => setFieldValue(`${prefix}.dimensions.w`, e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          ) : currentStop.connected_cargos?.length === 1 ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={currentStop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='W'
                name='connected_cargos[0].expected_dimensions.w'
                value={Number(totalValues.width || 0).toFixed(2)}
                onKeyDown={blockNonPositiveNumbers}
                disabled
              />
            </div>
          ) : null}

          {(!currentStop.connected_cargos?.length || currentStop.connected_cargos?.length === 1) && (
            <div className={sm.dimensions_x}>x</div>
          )}
          {!currentStop.connected_cargos?.length ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={currentStop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='H'
                name={`${prefix}.dimensions.h`}
                value={currentStop.dimensions.h}
                onChange={(e) => setFieldValue(`${prefix}.dimensions.h`, e.target.value)}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
          ) : currentStop.connected_cargos?.length === 1 ? (
            <div style={{ marginTop: '10px' }}>
              <InputWithText
                width={currentStop.connected_cargos?.length > 1 ? '60px' : '90px'}
                type='number'
                size='small'
                text='H'
                name='connected_cargos[0].expected_dimensions.h'
                value={Number(totalValues.height || 0).toFixed(2)}
                onKeyDown={blockNonPositiveNumbers}
                disabled
              />
            </div>
          ) : null}

          {currentStop.connected_cargos?.length > 1 && (
            <div style={{ marginTop: '10px' }} className='ms-2'>
              <Typography variant='b2 nowrap'>
                {formatNumber(totalValues.volumeInCubicFeet)} <Typography variant='c3'>CU. FT.</Typography>
              </Typography>
            </div>
          )}
        </div>
      </div>

      {/* Weight */}
      <div className={sm.field_wrapper_input}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Weight
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {!currentStop.connected_cargos?.length ? (
              <>
                <div className={sm.field_error_wrapper}>
                  <CustomInput
                    name={`${prefix}.weight`}
                    type='number'
                    className={sm.customInputField}
                    style={{ width: 200, borderRadius: '4px 0 0 4px' }}
                    value={currentStop.weight}
                    onChange={(e) => setFieldValue(`${prefix}.weight`, e.target.value)}
                    labelStyle={{ marginBottom: -0.6 }}
                  />
                </div>

                <div className={sm.field_error_wrapper}>
                  <CustomSelect
                    field={{
                      name: `${prefix}.weight_type`,
                      value: currentStop.weight_type,
                    }}
                    form={{ setFieldValue }}
                    name={`${prefix}.weight_type`}
                    styles={{ width: 200, height: 22, borderRadius: '0 4px 4px 0' }}
                    menuStyles={{ width: 200 }}
                    options={WEIGHT_TYPE}
                  />
                </div>
              </>
            ) : (
              <div style={{ marginTop: '10px' }}>
                <SMergedInputs>
                  <Input
                    width='250px'
                    type='text'
                    size='small'
                    name='cargos[0].expected_weight'
                    value={formatNumber(totalValues.expected_weight)}
                    onKeyDown={blockNonPositiveNumbers}
                    disabled
                    error={PLACE_TOUCHED?.cargos?.[0]?.expected_weight && PLACE_ERRORS?.cargos?.[0]?.expected_weight}
                  />
                  <div>
                    <Autocomplete
                      width='150px'
                      size='small'
                      labelKey='unit'
                      name='connected_cargos[0].expected_weight_type'
                      options={weightTypes}
                      value={currentStop.connected_cargos?.[0]?.expected_weight_type || null}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled
                    />
                  </div>
                </SMergedInputs>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Stop Notes */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Stop Notes
        </Typography>
        <CustomTextArea
          field={{ value: currentStop.stop_notes }}
          styleLabel={{ marginLeft: 36 }}
          name={`${prefix}.stop_notes`}
          styles={{ maxWidth: 400, height: 22 }}
          value={currentStop.stop_notes}
          onChange={(value) => setFieldValue(`${prefix}.stop_notes`, value)}
        />
      </div>

      {/* {bill_type} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.bill_type}
          name={`${prefix}.bill_type`}
          onChange={(checked) => setFieldValue(`${prefix}.bill_type`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Billable Move
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {dock_high} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.dock_high}
          name={`${prefix}.dock_high`}
          onChange={(checked) => setFieldValue(`${prefix}.dock_high`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Dock High
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {labor_required} */}
      <div className={sm.field_wrapper_textArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={currentStop.labor_required}
          name={`${prefix}.labor_required`}
          onChange={(checked) => setFieldValue(`${prefix}.labor_required`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Labor Required (Loading)
          </Typography>
        </CustomCheckbox>
      </div>

      <PopoverCreatShipmentItem
        isLTL
        show={show}
        id={stop.id}
        index={index}
        setShow={(v) => setShow(v)}
        arrayHelpers={arrayHelpers}
        shipmentSettings={shipmentSettings}
      />

      <div className={sm.line_fixed} style={{ backgroundColor: palette.gray50 }} />
    </div>
  );
};

export default DeliveryLTL;
