import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useShowToaster from 'hooks/useShowToaster';
import { assignUser, setCardPin, updateCard, updateCardInfos } from 'Api/CardManagement';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import SetLimit from '../SetLimit';
import UpdateInfos from '../UpdateInfos';
import TimeRestrictions from '../TimeRestrictions';
import LocationRestrictions from '../LocationRestrictions';
import { statusMapper } from '../../Cards.data';
import { SBackdrop, SCustomModal } from '../../Cards.styles';
import {
  SAction,
  SFlexWrapper,
  SHeaderWrapper,
  SInput,
  SLabel,
  SPhoneInput,
  STable,
  SText,
} from './CardDetails.styles';

const CardDetails = ({ open, onClose, cardDetails, rowData, onSetPin, onViewSpendLimit, onUpdateSuccess }) => {
  const { formatDateTime } = useDateFormat();

  const { header, infos, limits } = cardDetails || {};
  const {
    card_provider,
    deduct_all_on_payroll,
    deduct_cash_advance_on_payroll,
    user,
    card_number,
    status,
    pin,
    user_type,
    require_dr_id,
    require_dr_name,
    require_eq_id,
  } = rowData || {};
  const currentUser = JSON.parse(localStorage.getItem('user'));

  const showToaster = useShowToaster();
  const [allowAtm, setAllowAtm] = useState(!!limits?.some((i) => i.limitId === 'ATM'));
  const [openSetLimit, setOpenSetLimit] = useState(false);
  const [openTimeRestrictions, setOpenTimeRestrictions] = useState(false);
  const [openLocationRestrictions, setOpenLocationRestrictions] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [openUpdateInfos, setOpenUpdateInfos] = useState(false);

  const handleSubmit = () => {
    onClose();
  };

  const updateCardData = async (key, checked) => {
    setShowBackdrop(true);
    try {
      const body = {
        card_number,
        deduct_all_on_payroll,
        deduct_cash_advance_on_payroll,
        [key]: checked,
      };
      await updateCard(body);
      showToaster({ type: 'success', message: 'Card details have been successfully updated' });
      onUpdateSuccess(card_number);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setShowBackdrop(false);
    }
  };

  const onChangeAllowAtm = async (checked) => {
    try {
      const formData = new FormData();
      formData.append('cardNumber', card_number);
      formData.append('provider_id', card_provider?.id || '');

      if (user) {
        formData.append(`user_id`, user?.id || null);
        formData.append(`user_type`, user_type === 'Driver' ? 2 : user_type === 'Staff' ? 1 : null);
      }

      if (!checked) {
        setShowBackdrop(true);
        limits
          .filter((i) => i.limitId !== 'ATM')
          .forEach((item, index) => {
            formData.append(`limits[${index}][limitId]`, item.limitId);
            formData.append(`limits[${index}][limit]`, item.limit);
            formData.append(`limits[${index}][hours]`, item.hours);
            formData.append(`limits[${index}][minHours]`, item.minHours);
            formData.append(`limits[${index}][autoRollMap]`, item.autoRollMap);
            formData.append(`limits[${index}][autoRollMax]`, item.autoRollMax);
          });
        await assignUser(formData);
        onUpdateSuccess(card_number);
        setAllowAtm(false);
        showToaster({ type: 'success', message: 'Allow ATM has been successfully updated' });
      } else {
        setOpenSetLimit(true);
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setShowBackdrop(false);
    }
  };

  const onRemoveId = async (key) => {
    setShowBackdrop(true);
    const driverIdInfo = infos?.find((i) => i.infoId === 'DRID');
    const unitInfo = infos?.find((i) => i.infoId === 'UNIT');
    const nameInfo = infos?.find((i) => i.infoId === 'NAME');
    try {
      const updateCardBody = {
        card_number,
        deduct_all_on_payroll,
        deduct_cash_advance_on_payroll,
        require_dr_id: key === 'user_id' ? 0 : require_dr_id,
        require_eq_id: key === 'unit' ? 0 : require_eq_id,
        require_dr_name: key === 'name' ? 0 : require_dr_name,
      };
      const body = {
        cardNumber: card_number,
        user_id: driverIdInfo?.matchValue || null,
        unit: unitInfo?.matchValue || null,
        name: nameInfo?.matchValue || null,
        [key]: null,
      };
      await updateCard(updateCardBody);
      await updateCardInfos(body);
      showToaster({ type: 'success', message: 'Card required ids has been successfully updated' });
      onUpdateSuccess(card_number);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setShowBackdrop(false);
    }
  };

  const setPin = async () => {
    setShowBackdrop(true);
    try {
      const body = {
        provider_id: rowData.card_provider?.id || 44,
        card_number,
        pin: '0000',
      };

      await setCardPin(body);
      showToaster({ type: 'success', message: `Require PIN has been successfully updated` });
      onUpdateSuccess(card_number);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setShowBackdrop(false);
    }
  };

  const onSetLimitSuccess = () => {
    setAllowAtm(true);
    onUpdateSuccess(card_number);
  };

  const onChangeRequirePin = async (checked) => {
    if (checked) {
      onSetPin(rowData);
      return;
    }

    setPin();
  };

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <SHeaderWrapper>
          <div className='flex-left'>
            <Typography variant='h5'>Card: {card_number?.slice(-4)}</Typography>
            <Chip
              size='small'
              label={statusMapper[status?.id]?.label || status?.status}
              labelColor={statusMapper[status?.id]?.color}
              bgColor={statusMapper[status?.id]?.bgColor}
            />
          </div>
          {/* <CustomButton */}
          {/*  type='secondary' */}
          {/*  title='Edit' */}
          {/*  onClick={onEdit} */}
          {/*  className='edit-button' */}
          {/*  styleButton={{ padding: '6px 12px', marginTop: 0 }} */}
          {/* /> */}
        </SHeaderWrapper>
      }
      $bgColor={palette.white}
      $maxWidth='1000px'
      $minWidth='300px'
      padding='24px 0'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'submit',
          type: 'secondary',
          title: 'Close',
          onClick: handleSubmit,
        },
      ]}
    >
      <div>
        <Typography variant='s1' style={{ margin: '0 20px' }}>
          Card Summary
        </Typography>
        <STable>
          <tbody>
            <tr>
              <td>
                <SLabel>Card Number</SLabel>
                <SText>
                  <span className='dots'>●●●● ●●●● ●●●●</span> {cardDetails?.cardNumber?.slice(-4)}
                </SText>
              </td>
              <td>
                <SLabel>Equipment ID (Vehicle)</SLabel>
                <SText>{user?.equipment?.[0]?.equipment_id || 'N/A'}</SText>
              </td>
              <td>
                <SLabel>User ID</SLabel>
                <SText>{user?.custom_id || user?.id || 'N/A'}</SText>
              </td>
              <td>
                <SLabel>Expiry Date</SLabel>
                <SText>N/A</SText>
              </td>
            </tr>
            <tr>
              <td>
                <SLabel>Card Holder Name</SLabel>
                <SText>{user ? `${user.first_name} ${user.last_name}` : currentUser.customer?.company_name}</SText>
              </td>
              <td>
                <SLabel>SMS Fraud Alerts</SLabel>
                <CustomCheckbox
                  type='switch'
                  name='fraud_alerts'
                  smail={false}
                  checked={false}
                  onChange={() => null}
                  labelStyle={{ marginTop: '2px' }}
                  disabled
                />
              </td>
              <td>
                <SLabel>SMS Number</SLabel>
                <SPhoneInput disabled />
              </td>
              <td>
                <SLabel>Zip Code</SLabel>
                <SInput disabled />
              </td>
            </tr>
            <tr>
              <td>
                <SLabel>Last Used Date</SLabel>
                <SText>{header?.lastUsedDate ? formatDateTime(header?.lastUsedDate) : 'N/A'}</SText>
              </td>
              <td>
                <SLabel>Last Transaction ID</SLabel>
                <SText>{header?.lastTransaction || 'N/A'}</SText>
              </td>
              <td>
                <SLabel>Card Type</SLabel>
                <SText>{card_provider?.provider_name || 'N/A'}</SText>
              </td>
              <td>
                <SLabel>PIN Code</SLabel>
                {pin && pin !== '0000' ? (
                  <SText>{pin}</SText>
                ) : (
                  <SAction onClick={() => onSetPin(rowData)}>Set PIN</SAction>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <SLabel>Deduct All Transactions on Payroll</SLabel>
                <CustomCheckbox
                  type='switch'
                  name='deduct_all_on_payroll'
                  smail={false}
                  checked={!!deduct_all_on_payroll}
                  onChange={(checked) => updateCardData('deduct_all_on_payroll', Number(checked))}
                  labelStyle={{ marginTop: '2px' }}
                />
              </td>
              <td>
                <SLabel>Deduct Cash Advance on Payroll</SLabel>
                <CustomCheckbox
                  type='switch'
                  name='deduct_cash_advance_on_payroll'
                  smail={false}
                  checked={!!deduct_cash_advance_on_payroll}
                  onChange={(checked) => updateCardData('deduct_cash_advance_on_payroll', Number(checked))}
                  labelStyle={{ marginTop: '2px' }}
                />
              </td>
              <td>
                <SLabel>Allow ATM</SLabel>
                <CustomCheckbox
                  type='switch'
                  name='allow_atm'
                  smail={false}
                  checked={allowAtm}
                  onChange={onChangeAllowAtm}
                  labelStyle={{ marginTop: '2px' }}
                />
              </td>
              <td>
                <SLabel>PIN Set</SLabel>
                <SText>{pin && pin !== '0000' ? 'True' : 'N/A'}</SText>
              </td>
            </tr>
          </tbody>
        </STable>
      </div>
      <div style={{ margin: '24px 0 12px' }}>
        <Typography variant='s1' style={{ margin: '0 20px' }}>
          Limits and Restrictions
        </Typography>
        <STable>
          <tbody>
            <tr>
              <td>
                <SLabel>Limit Reset</SLabel>
                <SAction onClick={() => onViewSpendLimit(rowData)}>View</SAction>
              </td>
              <td>
                <SLabel>Spend Limit</SLabel>
                <SAction onClick={() => onViewSpendLimit(rowData)}>View</SAction>
              </td>
              <td>
                <SLabel>Allow - Begin Time</SLabel>
                <SAction onClick={() => setOpenTimeRestrictions(true)}>View</SAction>
              </td>
              <td>
                <SLabel>Allow - End Time</SLabel>
                <SAction onClick={() => setOpenTimeRestrictions(true)}>View</SAction>
              </td>
            </tr>
            <tr>
              <td>
                <SLabel>Require PIN</SLabel>
                <CustomCheckbox
                  type='switch'
                  name='require-pin'
                  smail={false}
                  checked={!!(pin && pin !== '0000')}
                  onChange={onChangeRequirePin}
                  labelStyle={{ marginTop: '2px' }}
                />
              </td>
              <td>
                <SLabel>Require ID</SLabel>
                <SFlexWrapper>
                  {!!require_dr_id && (
                    <Chip
                      label='DRIVER ID'
                      onDelete={() => onRemoveId('user_id')}
                      deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                    />
                  )}
                  {!!require_dr_name && (
                    <Chip
                      label='NAME'
                      onDelete={() => onRemoveId('name')}
                      deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                    />
                  )}
                  {!!require_eq_id && (
                    <Chip
                      label='VEHICLE ID'
                      onDelete={() => onRemoveId('unit')}
                      deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                    />
                  )}
                  {(!require_dr_id || !require_dr_name || !require_eq_id) && (
                    <SAction onClick={() => setOpenUpdateInfos(true)}>Add</SAction>
                  )}
                </SFlexWrapper>
              </td>
              <td>
                <SLabel>Restrict Day</SLabel>
                <SAction onClick={() => setOpenTimeRestrictions(true)}>View</SAction>
              </td>
              <td>
                <SLabel>Restrict to Locations</SLabel>
                <SAction onClick={() => setOpenLocationRestrictions(true)}>View</SAction>
              </td>
            </tr>
          </tbody>
        </STable>
      </div>
      {openSetLimit && (
        <SetLimit
          open={openSetLimit}
          onClose={() => setOpenSetLimit(false)}
          data={rowData}
          cardDetails={cardDetails}
          onSuccess={onSetLimitSuccess}
          limitToUpdate={{ limitId: 'ATM' }}
        />
      )}
      {openTimeRestrictions && (
        <TimeRestrictions
          open={openTimeRestrictions}
          onClose={() => setOpenTimeRestrictions(false)}
          data={rowData}
          cardDetails={cardDetails}
          onSuccess={onUpdateSuccess}
        />
      )}
      {openLocationRestrictions && (
        <LocationRestrictions
          open={openLocationRestrictions}
          onClose={() => setOpenLocationRestrictions(false)}
          data={rowData}
          cardDetails={cardDetails}
          onSuccess={onUpdateSuccess}
        />
      )}
      {openUpdateInfos && (
        <UpdateInfos
          open={openUpdateInfos}
          onClose={() => setOpenUpdateInfos(false)}
          data={rowData}
          cardDetails={cardDetails}
          onSuccess={onUpdateSuccess}
        />
      )}
      <SBackdrop open={showBackdrop}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default CardDetails;
