import { array, mixed, object, string } from 'yup';

export const documentsValidationSchema = object().shape({
  invoice: mixed().required('Attachment is required'),
  invoiceReference: mixed().required('Invoice Number is required'),
  documents: array().of(
    object().shape({
      reference_id: string().trim().required('Required').nullable(),
      type: mixed().required('Required').nullable(),
      file: string().trim().required('Required').nullable(),
    })
  ),
  bill_of_lading_array: array().of(
    object().shape({
      bill_of_lading: string().trim().required('Attachment is required').nullable(),
      billOfLadingReference: mixed().required('Invoice Number is required').nullable(),
    })
  ),
});

export const getDocumentsInitialValues = () => {
  return {
    invoice: '',
    invoiceReference: '',
    bill_of_lading_array: [
      {
        shipment_stop_id: '',
        bill_of_lading: '',
        billOfLadingReference: '',
      },
    ],
    documents: [],
  };
};
