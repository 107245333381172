import moment from 'moment';
import { weekdaysBetween } from 'utils/helpers';
import { initialRecurs } from 'components/CreateShipment/Schedule/Schedule.data';

export const getInitialValues = (data) => {
  const { name, start_date, end_date, lead_time_days, recurs } = data || {};

  return {
    name: name || '',
    start_date: start_date ? moment(start_date).toDate() : null,
    end_date: start_date ? moment(end_date).toDate() : null,
    lead_time_days: lead_time_days ?? '',
    recurs: recurs || initialRecurs,
  };
};

export const updateRequestBodyConverter = (values, data) => {
  let totalShipmentsCount = 0;
  const formData = new URLSearchParams();
  formData.append('name', values.name);
  formData.append('start_date', moment(values.start_date).format('YYYY-MM-DD'));
  formData.append('end_date', moment(values.end_date).format('YYYY-MM-DD'));
  formData.append('lead_time_days', values.lead_time_days);
  formData.append('status', data.shipment_lane.status);

  values.recurs.forEach((item, index) => {
    if (item.checked) {
      const daysCount = weekdaysBetween(values.start_date, values.end_date, index + 1);
      const occurrence = (item.amount || 0) * (daysCount || 0);

      totalShipmentsCount += occurrence;

      formData.append(`days[${item.title}][recurring_shipments_per_day]`, item.amount);

      item.dateSettings.forEach((el, i) => {
        const pickupTimeArray = el.day_wise_pickup_time.split(':');

        formData.append(`days[${item.title}][day_wise_pickup_time][${i}]`, el.day_wise_pickup_time);
        formData.append(`days[${item.title}][day_wise_pickup_time_to][${i}]`, el.day_wise_pickup_time_to);

        el.delivery_time.slice(1).forEach((time, timeIndex, arr) => {
          const deliveryTimeArray = time.split(':');
          const prevStopStartTimeArray = timeIndex === 0 ? pickupTimeArray : arr[timeIndex - 1].split(':');
          const day_wise_delivery_time_after = Number(el.day_wise_delivery_time_after[timeIndex + 1]) * 1440;

          const minutesToAdd =
            (deliveryTimeArray[0] - prevStopStartTimeArray[0]) * 60 +
            (deliveryTimeArray[1] - prevStopStartTimeArray[1]);
          formData.append(
            `days[${item.title}][day_wise_delivery_time_after][${i}][${timeIndex}]`,
            day_wise_delivery_time_after + minutesToAdd
          );
        });

        (el.delivery_time_to || el.delivery_time).slice(1).forEach((time, timeIndex) => {
          const deliveryTimeArray = time.split(':');
          const deliveryFrom = el.delivery_time[timeIndex + 1].split(':');

          const minutesToAdd = time
            ? (deliveryTimeArray[0] - deliveryFrom[0]) * 60 + (deliveryTimeArray[1] - deliveryFrom[1])
            : 0;
          formData.append(
            `days[${item.title}][day_wise_delivery_time_after_to][${i}][${timeIndex}]`,
            minutesToAdd || 0
          );
        });
      });
    }
  });
  formData.append(`shipments_count`, totalShipmentsCount);

  return formData;
};
