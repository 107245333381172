import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Pagination from 'common/Pagination';
import ViewPdf from 'components/ViewPdf';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import AddNotesTable from 'components/TableShipments/detailsRow/steps/Notes/AddNotes';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import {
  getStandaloneInvoiceNotes,
  createStandaloneInvoiceNote,
  deleteStandaloneInvoiceNote,
} from 'Api/AccountingReceivables';
import TableSkeleton from 'common/TableSkeleton';
import { initialFilters } from '../../TableSection.data';
import { useColumns } from '../AddNotes/AddNotes.data';

const StandaloneInvoiceNotes = ({ open, onClose, invoice, onSuccess }) => {
  const showToaster = useShowToaster();
  const [notes, setNotes] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  const getNotes = async () => {
    setLoading(true);
    try {
      const res = await getStandaloneInvoiceNotes(invoice.invoice_id);
      setNotes(res);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const createNote = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => values[key] !== '' && formData.append(key, values[key]));
    return createStandaloneInvoiceNote(formData);
  };

  const onAddNote = (form) => {
    const { validateForm, submitForm, values } = form;
    submitForm();
    validateForm().then((res) => {
      const isValid = Object.keys(res).length === 0;
      if (isValid) {
        const dataBack = { ...values, invoice_id: invoice.invoice_id };
        createNote(dataBack).then((res) => {
          if (res && res?.data) {
            form.resetForm();
            getNotes();
            onSuccess();
          }
        });
      }
    });
  };

  const onDeleteNote = async (rowData) => {
    try {
      await deleteStandaloneInvoiceNote(rowData.id);
      getNotes();
      onSuccess();
      showToaster({ type: 'success', message: 'Note has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const onOpenNoteDocument = (url) => {
    setPdfUrl(url);
  };

  useEffect(() => {
    getNotes();
  }, []);

  const columns = useColumns({ onDeleteRowItem: onDeleteNote, onOpenNoteDocument });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Notes'
      $bgColor={palette.white}
      $maxWidth='1100px'
      $minWidth='1100px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <AddNotesTable
        onSubmit={onAddNote}
        styleFormWrapper={{ backgroundColor: palette.white, marginTop: '-20px', width: '200px' }}
      />
      {loading ? (
        <TableSkeleton />
      ) : (
        <MaterialTableWrapper
          data={notes?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: notes,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [25, 50, 100, 150],
              }),
          }}
          columns={columns}
        />
      )}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl('')} pdfUrl={pdfUrl} title='View Document' />}
    </Modal>
  );
};

export default StandaloneInvoiceNotes;
