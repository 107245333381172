import React, { useMemo, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as ArrowDown } from 'assets/sidemanu/arrowDown.svg';
import Search from 'common/Search';
import ThreeDotActions from 'common/ThreeDotActions';
import CustomButton from 'components/CustomButton/CustomButton';
import { dutyStatuses } from 'pages/Hos-Logbook/TableSection/Tables/HOS/HOS.data';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SWrapper, SSearchWrapper } from './TableFilters.styles';

const TableFilters = ({ search, setSearch, setDutyStatuses }) => {
  const [statuses, setStatuses] = useState(dutyStatuses);
  const [anchorEl, setAnchorEl] = useState(null);

  const activeStatuses = useMemo(() => {
    return statuses.filter((item) => item.active);
  }, [statuses]);

  const onCheckboxChange = (statusId, checked) => {
    const newStatuses = statuses.map((item) => {
      if (item.id === statusId) {
        return { ...item, active: checked };
      }

      return item;
    });

    setStatuses(newStatuses);
    setDutyStatuses(newStatuses.filter((status) => status.active).map((status) => status.value));
  };

  return (
    <SWrapper>
      <div className='filters-row'>
        <div style={{ width: 'fit-content' }}>
          <ThreeDotActions
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            actionButton={
              <CustomButton
                type='secondary'
                title='Duty Status'
                rightIcon={<ArrowDown style={{ marginLeft: 10 }} />}
                styleButton={{ padding: '6px 12px', margin: 0 }}
                styleTitle={{ fontFamily: 'Inter', fontSize: '14px', lineHeight: '20px' }}
                onClick={() => null}
              />
            }
            popoverStyles={{ margin: '10px 0 0 100px' }}
          >
            {statuses.map((status) => (
              <div key={status.id} className='p-1'>
                <CustomCheckbox checked={status.active} onChange={(checked) => onCheckboxChange(status.id, checked)}>
                  <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                    {status.name}
                  </Typography>
                </CustomCheckbox>
              </div>
            ))}
          </ThreeDotActions>
        </div>
        <SSearchWrapper>
          <Search search={search} onChange={setSearch} />
        </SSearchWrapper>
      </div>
      <div className='d-flex align-items-center gap-1'>
        {!!activeStatuses.length && (
          <>
            {activeStatuses.map((status) => {
              return (
                <div className='status-wrapper' key={status.id}>
                  <Typography variant='c2'>{status.name}</Typography>
                  <DeleteIcon
                    className='remove-status-icon'
                    width={10}
                    height={10}
                    fill={palette.red500}
                    onClick={() => onCheckboxChange(status.id, false)}
                  />
                </div>
              );
            })}
            <span
              className='underline-text'
              onClick={() => {
                setStatuses(dutyStatuses);
                setDutyStatuses([]);
              }}
            >
              Clear All
            </span>
          </>
        )}
      </div>
    </SWrapper>
  );
};

export default TableFilters;
