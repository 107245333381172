import { paymentForOptions, weekDays } from './PayPeriod/PayPeriod.data';

export const getPayPeriodConverter = (data) => {
  if (!data) {
    return null;
  }

  const { payperiod_type, week_day, set_payment_for } = data;

  const weekDay = weekDays.find((item) => item.value === week_day);
  const setPaymentFor = paymentForOptions.find((item) => item.value === set_payment_for);

  return {
    payperiod_type: payperiod_type || null,
    week_day: weekDay || null,
    set_payment_for: setPaymentFor || null,
  };
};

export const updatePayPeriodConverter = (values) => {
  if (!values) {
    return null;
  }

  const { payperiod_type, week_day, set_payment_for } = values;

  return {
    payperiod_type,
    week_day: payperiod_type !== 3 ? week_day?.value : undefined,
    set_payment_for: set_payment_for?.value || undefined,
  };
};

export const updateNotificationSettingsConverter = (values) => {
  if (!values) {
    return null;
  }

  const { settlement_done_email_driver, settlement_done_push__driver, correspondence_email } = values;

  return {
    settlement_done_email_driver: Number(settlement_done_email_driver),
    settlement_done_push__driver: Number(settlement_done_push__driver),
    correspondence_email: correspondence_email || null,
  };
};
