import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, FastField, FieldArray } from 'formik';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import Error from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import ItemModal from 'pages/CompanySettings/pagesComponents/ShipmentTypes/ItemModal';
import useIsMounted from 'hooks/useIsMounted';
import { useTheme } from 'context/themeContext';
import { CURRENCY, numberWithCommas, palette } from 'utils/constants';
import { getGrandTotal, getShipmentChargeType } from 'Api/Planner';
import styles from 'components/TablePlaner/helpers/RecurrningDetails/steps/BillingCharges/billingCharges.module.css';
import sm from '../../BillingCharges/BillingCharges.module.css';
import { ShipmentContext } from '../../Layout';
import CustomInput from '../../helpers/CustomInput';
import PopoverBillingCharges from '../../BillingCharges/PopoverBillingCharges';
import { BILLING_GRAND_TOTAL_CURRENCY, getChangesTemplate } from '../../ShipmentStops/helpers/constants';

const BillingChargesLtlOneChargeBody = ({
  values,
  valuesForm,
  touched,
  setFieldTouched,
  onChangeDisabledNextStep,
  validateForm,
  indexPrefix,
  namePrefix,
  setFieldValue,
}) => {
  const { use } = useTheme();
  const isMounted = useIsMounted();

  const [openItemModal, setOpenItemModal] = useState(false);
  const [chargeErrors, setChargeErrors] = useState({});

  const {
    selectedCharges,
    updateSelectedCharges,
    selectedGrandTotal,
    updateSelectedGrandTotal,
    shipmentChargeType,
    updateShipmentChargeType,
    createShipmentData,
    updateShipmentData,
  } = useContext(ShipmentContext);
  const currencyData = Object.values(CURRENCY).find((item) => item.id === Number(selectedGrandTotal?.[0]?.id));

  const SELECTED_CHARGES = selectedCharges?.[indexPrefix];

  const getChargeType = () => {
    return getShipmentChargeType().then((res) => {
      if (res && res?.data) {
        const newData = res.data.map((item) => {
          return {
            ...item,
            label: item?.title,
            key: item.id,
            labelSelected: null,
          };
        });
        updateShipmentChargeType(newData);
        return newData;
      }
      return res;
    });
  };

  const getGrandTotalDefault = () => {
    getGrandTotal()
      .then((res) => {
        if (res && res?.data) {
          switch (res.data?.default_currency) {
            case 'usd':
              if (isMounted())
                updateSelectedGrandTotal({
                  ...selectedGrandTotal,
                  [indexPrefix]: BILLING_GRAND_TOTAL_CURRENCY[0],
                });
              break;
            case 'cad':
              if (isMounted())
                updateSelectedGrandTotal({
                  ...selectedGrandTotal,
                  [indexPrefix]: BILLING_GRAND_TOTAL_CURRENCY[1],
                });
              break;
            case 'mxn':
              if (isMounted())
                updateSelectedGrandTotal({
                  ...selectedGrandTotal,
                  [indexPrefix]: BILLING_GRAND_TOTAL_CURRENCY[2],
                });
              break;
            default:
          }
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const validateScheduledDate = (value) => {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  };

  const onSaveCharge = (charge, index) => {
    if (!charge.charges) {
      setChargeErrors((prevState) => ({ ...prevState, [index]: true }));
      return;
    }
    validateForm().then((err) => {
      const errors = err?.shipmentsBilling?.[indexPrefix];
      if (!!errors?.billingCharges && !!errors?.billingCharges?.[index]) {
        const array = touched[indexPrefix]?.billingCharges ? [...touched[indexPrefix].billingCharges] : [];
        array[index] = {
          qty: true,
          rate: true,
          subTotal: true,
        };
        setFieldTouched(`${namePrefix}billingCharges[${index}].qty`, true);
        setFieldTouched(`${namePrefix}billingCharges[${index}].rate`, true);
        setFieldTouched(`${namePrefix}billingCharges[${index}].charges`, true);
      } else {
        if (values?.billingCharges?.length === Number(SELECTED_CHARGES?.length) + 1) {
          onChangeDisabledNextStep(false);
        }
        const chargeSave = SELECTED_CHARGES
          ? [
              ...SELECTED_CHARGES,
              {
                ...charge,
                charges: typeof charge.charges === 'object' ? charge.charges?.id : charge.charges,
                rate: charge?.charges?.type?.account_type === 'Expense' ? `-${charge.rate}` : charge.rate,
              },
            ]
          : [
              {
                ...charge,
                charges: typeof charge.charges === 'object' ? charge.charges?.id : charge.charges,
                rate: charge?.charges?.type?.account_type === 'Expense' ? `-${charge.rate}` : charge.rate,
              },
            ];
        updateSelectedCharges({ ...selectedCharges, [indexPrefix]: chargeSave });
        const updateShipment = {
          ...createShipmentData,
          shipmentsBilling: createShipmentData?.shipmentsBilling
            ? createShipmentData.shipmentsBilling.map((item, index) =>
                index === indexPrefix ? { ...item, billingCharges: values.billingCharges } : item
              )
            : [],
        };
        updateShipmentData(updateShipment);
      }
    });
  };

  const onEditCharge = (charge) => {
    const filteredData = SELECTED_CHARGES?.filter((item) => {
      return item.id !== charge.id;
    });
    onChangeDisabledNextStep(true);
    updateSelectedCharges({ ...selectedCharges, [indexPrefix]: filteredData });
  };

  const onDeleteCharge = (arrayHelpers, index) => {
    if (values.billingCharges.length === Number(SELECTED_CHARGES?.length) + 1) {
      onChangeDisabledNextStep(false);
    }

    arrayHelpers.remove(index);
    const deletedItem = values.billingCharges[index];
    const filteredBillCharges = values.billingCharges.filter((el) => el.id !== deletedItem.id);

    const updateShipment = { ...createShipmentData };
    if (updateShipment.shipmentsBilling?.[indexPrefix]?.billingCharges) {
      updateShipment.shipmentsBilling[indexPrefix].billingCharges = filteredBillCharges;
      updateShipmentData(updateShipment);
    }
  };

  const chargesStyles = useMemo(() => {
    return {
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      editIcon: {
        height: 15,
        cursor: 'pointer',
      },
      isSearchableStyles: {
        width: 400,
        borderRadius: 6,
      },
    };
  }, [use, palette]);

  useEffect(() => {
    const valuesBilling = valuesForm?.shipmentsBilling?.[indexPrefix]?.billingCharges;
    if (valuesBilling.length > 1) {
      updateSelectedCharges({ ...selectedCharges, [indexPrefix]: [...valuesBilling] });
      updateSelectedCharges({
        ...selectedCharges,
        [indexPrefix]: valuesBilling.map((charge) => ({
          ...charge,
          rate: charge?.charges?.type?.account_type === 'Expense' ? `-${charge.rate}` : charge.rate,
        })),
      });
    }
    getChargeType().then();
    !selectedGrandTotal[indexPrefix] && getGrandTotalDefault();
  }, []);

  useEffect(() => {
    let countSelectedChargesAll = 0;
    let countFormCharges = 0;
    Object.keys(selectedCharges).forEach((key) => {
      const value = selectedCharges[key];
      countSelectedChargesAll += Number(value?.length);
    });

    valuesForm.shipmentsBilling.forEach((el) => {
      countFormCharges += Number(el?.billingCharges?.length);
    });
    onChangeDisabledNextStep(countSelectedChargesAll !== countFormCharges);
  }, [selectedCharges, valuesForm]);

  useEffect(() => {
    if (!!createShipmentData.currency_id && !selectedGrandTotal[indexPrefix]) {
      const currency = BILLING_GRAND_TOTAL_CURRENCY.find((el) => +el.id === +createShipmentData.currency_id);
      updateSelectedGrandTotal({ ...selectedGrandTotal, [indexPrefix]: currency });
    }
  }, [indexPrefix]);

  return (
    <div>
      <div className={sm.header_titles_wrapper}>
        <div className={sm.titles}>
          <div className='d-flex justify-content-between'>
            <Typography variant='overLine' style={{ minWidth: 410 }}>
              CHARGES
            </Typography>
            <GearIcon className='pointer' onClick={() => setOpenItemModal(true)} />
          </div>
          <Typography variant='overLine' style={{ minWidth: 80 }}>
            QTY
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 160 }}>
            RATE
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 160 }}>
            SUB-TOTAL
          </Typography>
          <Typography variant='overLine' style={{ minWidth: 120 }} />
        </div>
        <div className={sm.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
      </div>
      <FieldArray
        name={`${namePrefix}billingCharges`}
        render={(arrayHelpers) => {
          return (
            <div>
              {values?.billingCharges?.map((charge, index) => {
                const PLACE = `${namePrefix}billingCharges[${index}]`;
                const savedCharge = SELECTED_CHARGES?.find((item) => item.id === charge.id);
                const selectInput = shipmentChargeType.find(
                  (el) => el.key === (Number(charge.charges?.id) || +charge.charges)
                );
                const selectValue =
                  typeof values.billingCharges[index].charges === 'object' &&
                  values.billingCharges[index].charges !== null
                    ? values.billingCharges[index].charges || null
                    : (shipmentChargeType || []).find((i) => i.id === Number(values.billingCharges[index].charges));
                if (savedCharge) {
                  return (
                    <div key={charge.charges?.id} className={sm.charge_wrapper}>
                      <Typography variant='s2' style={{ width: 424 }}>
                        {selectInput?.label}
                      </Typography>
                      <Typography variant='s2' style={{ width: 80 }}>
                        {charge?.qty}
                      </Typography>
                      <Typography variant='s2' style={{ width: 160 }}>
                        {charge?.charges?.type?.account_type === 'Expense' ? '-' : ''} {charge?.rate}
                      </Typography>
                      <Typography variant='s2' style={{ width: 160 }}>
                        {`${charge?.charges?.type?.account_type === 'Expense' ? '-' : ''} ${
                          currencyData?.symbol || '$'
                        }`}
                        {numberWithCommas((charge.qty * charge.rate).toFixed(2))}
                      </Typography>
                      <Typography variant='s2' style={{ width: 120, display: 'flex', justifyContent: 'flex-end' }}>
                        <EditIcon
                          style={chargesStyles.editIcon}
                          onClick={() => {
                            onEditCharge(charge);
                          }}
                        />
                      </Typography>
                    </div>
                  );
                }
                return (
                  <div key={charge.id} className={sm.charge_container}>
                    <div className={sm.charge_wrapper}>
                      <div className='charge_input_wrapper'>
                        <div className={sm.charges_container}>
                          <Autocomplete
                            width='424px'
                            name={`${PLACE}.charges`}
                            labelKey='title'
                            options={shipmentChargeType || []}
                            value={selectValue || null}
                            onChange={(e, val) => {
                              setFieldValue(`${PLACE}.charges`, val);
                              setFieldValue(`${PLACE}.qty`, '');
                              setFieldValue(`${PLACE}.rate`, '');
                              setChargeErrors((prevState) => ({ ...prevState, [index]: false }));
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                          />
                        </div>
                        <Error error={!!chargeErrors?.[index] && 'Required'} />
                      </div>

                      <div className='charge_input_wrapper'>
                        <FastField
                          name={`${PLACE}.qty`}
                          type='number'
                          component={CustomInput}
                          labelStyle={{ margin: 0 }}
                          validate={validateScheduledDate}
                          value={values.billingCharges?.[index].qty}
                          style={{
                            width: 80,
                            borderRadius: 6,
                            height: 32,
                            padding: '0 6px',
                          }}
                        />
                        <ErrorMessage
                          name={`${PLACE}.qty`}
                          render={(error) => (
                            <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>

                      <div className={styles.charge_input_wrapper}>
                        <FastField
                          name={`${PLACE}.rate`}
                          // fixed={2}
                          type='number'
                          placeholder='0.00'
                          component={CustomInput}
                          labelStyle={{ margin: 0 }}
                          validate={validateScheduledDate}
                          style={{ width: 160, borderRadius: 6, height: 32 }}
                          leftIcon={
                            <span style={{ color: palette.gray400 }}>
                              {`${currencyData?.symbol || '$'} ${
                                charge?.charges?.type?.account_type === 'Expense' ? '-' : ''
                              }`}
                            </span>
                          }
                        />
                        <ErrorMessage
                          name={`${PLACE}.rate`}
                          render={(error) => (
                            <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>

                      <div className={sm.subTotal_wrapper}>
                        <Typography variant='s2'>
                          {`${charge?.charges?.type?.account_type === 'Expense' ? '-' : ''} ${
                            currencyData?.symbol || '$'
                          }`}
                          {numberWithCommas(
                            ((charge?.qty ? charge.qty : 0) * (charge?.rate ? charge.rate : 0)).toFixed(2)
                          )}
                        </Typography>
                      </div>

                      <div className={sm.control_buttons}>
                        <CustomButton
                          type='secondary'
                          leftIcon={
                            <DeleteIcon fill={use(palette.gray700, palette.gray700)} style={{ width: 9, height: 9 }} />
                          }
                          styleButton={{ padding: '7px 12px' }}
                          styleTitle={{ fontSize: 14 }}
                          onClick={() => onDeleteCharge(arrayHelpers, index, charge)}
                        />

                        <CustomButton
                          type='primary'
                          leftIcon={
                            <TickIcon fill={use(palette.white, palette.white)} style={{ width: 9, height: 9 }} />
                          }
                          styleButton={{ padding: '7px 12px' }}
                          styleTitle={{ fontSize: 14 }}
                          onClick={() => onSaveCharge(charge, index)}
                        />
                      </div>
                    </div>
                    <div className={sm.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
                  </div>
                );
              })}
              <div style={{ marginBottom: 50 }}>
                <GrandTotal
                  values={values}
                  indexPrefix={indexPrefix}
                  currencyData={currencyData}
                  createShipmentData={createShipmentData}
                  arrayHelpers={arrayHelpers}
                  chargesStyles={chargesStyles}
                  selectedCharges={selectedCharges}
                  selectedGrandTotal={selectedGrandTotal}
                  updateSelectedGrandTotal={updateSelectedGrandTotal}
                  onChangeDisabledNextStep={onChangeDisabledNextStep}
                  onSetDisabledDelete={() => null}
                />
              </div>
            </div>
          );
        }}
      />
      {openItemModal && (
        <ItemModal open={openItemModal} onClose={() => setOpenItemModal(false)} onSuccess={getChargeType} />
      )}
    </div>
  );
};

export default BillingChargesLtlOneChargeBody;

const GrandTotal = ({
  arrayHelpers,
  currencyData,
  chargesStyles,
  selectedCharges,
  createShipmentData,
  selectedGrandTotal,
  updateSelectedGrandTotal,
  onChangeDisabledNextStep,
  onSetDisabledDelete,
  indexPrefix,
}) => {
  const { use } = useTheme();
  const [count, setCount] = useState(0);

  useEffect(() => {
    let subTotal = 0;
    selectedCharges?.[indexPrefix]?.forEach((charge) => {
      if (charge?.qty && charge?.rate) {
        subTotal += Number(charge.qty) * Number(charge.rate);
      }
    });

    subTotal && setCount(numberWithCommas(subTotal.toFixed(2)));
  }, [selectedCharges, indexPrefix]);

  useEffect(() => {
    if (!!createShipmentData.currency_id && !selectedGrandTotal?.[indexPrefix]) {
      const currency = BILLING_GRAND_TOTAL_CURRENCY.find((el) => +el.id === +createShipmentData.currency_id);
      updateSelectedGrandTotal({ ...selectedGrandTotal, [indexPrefix]: currency });
    } else if (!createShipmentData.currency_id && !selectedGrandTotal?.[indexPrefix]) {
      if (!indexPrefix) {
        updateSelectedGrandTotal({ ...selectedGrandTotal, 0: BILLING_GRAND_TOTAL_CURRENCY[0] });
      } else {
        updateSelectedGrandTotal({ ...selectedGrandTotal, [indexPrefix]: BILLING_GRAND_TOTAL_CURRENCY[0] });
      }
    }
  }, [indexPrefix, selectedGrandTotal]);

  return (
    <div className={sm.another_wrapper}>
      <CustomButton
        type='secondary'
        title='Add Another'
        leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
        styleButton={chargesStyles.addAnotherButton}
        styleTitle={chargesStyles.addAnotherTitle}
        onClick={() => {
          onSetDisabledDelete(false);
          onChangeDisabledNextStep(true);
          arrayHelpers.push(getChangesTemplate());
        }}
      />
      <div className={sm.grand_total}>
        <Typography variant='s2' style={{ marginBottom: 8 }}>
          Grand Total
        </Typography>
        <div className={sm.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
        <div className={sm.grand_total_bottom_wrapper}>
          <Typography variant='s2'>
            {currencyData?.symbol || '$'}
            {count}
          </Typography>
          <PopoverBillingCharges
            data={BILLING_GRAND_TOTAL_CURRENCY}
            selectedItem={selectedGrandTotal[indexPrefix]}
            onClick={(item) => updateSelectedGrandTotal({ ...selectedGrandTotal, [indexPrefix]: item })}
          />
        </div>
      </div>
    </div>
  );
};
