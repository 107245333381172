import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Input from 'common/Input';
import PhoneInput from 'common/PhoneInput';
import ErrorMessage from 'common/ErrorMessage';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import AddressFields from '../../components/AddressFields';
import Uploader from '../../../../common/Uploader';
import styles from '../../../../components/Profile/Profile.module.css';
import InputLabel from '../../../../common/InputLabel';

const BasicInfo = ({ form, carrier }) => {
  const { values, handleChange, touchedErrors } = form;
  const { use } = useTheme();
  const [imageUrl, setImageUrl] = useState(carrier?.logo_path);

  const handleCanTravelChange = (event, countryCode) => {
    handleChange(
      'can_travel_to',
      event ? [...values.can_travel_to, countryCode] : values.can_travel_to.filter((code) => code !== countryCode)
    );
  };

  useEffect(() => {
    if (carrier?.logo_path) {
      setImageUrl(carrier.logo_path);
    }
  }, [carrier]);

  const handleApprovedForChange = (event, doc) => {
    handleChange(
      'approved_for',
      event ? [...values.approved_for, doc] : values.approved_for.filter((code) => code !== doc)
    );
  };

  const handleDrop = (files) => {
    if (files && files[0]) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      handleChange('logo', file);
    }
  };

  return (
    <form>
      <Grid container columnSpacing={2.5} rowSpacing={2}>
        <Grid item xs={12} sm={3}>
          <Input
            name='dot'
            label='Carrier DOT'
            onChange={handleChange}
            value={values.dot}
            error={touchedErrors.dot}
            required
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input name='mc' label='Carrier MC' onChange={handleChange} value={values.mc} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input name='scac' label='SCAC' onChange={handleChange} value={values.scac} error={touchedErrors.scac} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PhoneInput
            name='phoneNumber'
            placeholder='+1 (555) 555-1234'
            label='Phone Number'
            onChange={(val) => handleChange('phoneNumber', val)}
            value={values.phoneNumber}
            error={touchedErrors.phoneNumber}
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            name='name'
            placeholder='ABC Corporation'
            label='Carrier Name'
            onChange={handleChange}
            value={values.name}
            error={touchedErrors.name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name='email'
            placeholder='example@email.com'
            label='Email Address'
            onChange={handleChange}
            value={values.email}
            error={touchedErrors.email}
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <div className='d-flex  gap-4 align-items-start'>
            <div className='d-flex flex-column'>
              <InputLabel>Upload Logo</InputLabel>

              <div className='d-flex align-items-start gap-4'>
                <Uploader
                  type={2}
                  uploaderLabel='Select Attachment'
                  document={values.logo}
                  onDrop={handleDrop}
                  style={{ marginTop: 8 }}
                  onRemove={() => {
                    setImageUrl(null);
                    handleChange('logo', '');
                  }}
                  accept={['application/pdf']}
                />

                <label className={styles.avatar_wrapper}>
                  <figure className={styles.profile_image_wrapper}>
                    {!values.logo ? (
                      <div />
                    ) : (
                      <img
                        src={imageUrl}
                        alt='img'
                        width={100}
                        height={100}
                        style={{ borderRadius: '50%', objectFit: 'contain' }}
                      />
                    )}
                  </figure>
                </label>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            Can Travel To?
          </Typography>
          <div className='d-flex gap-3'>
            <CustomCheckbox
              checked={values.can_travel_to?.includes('1')}
              onChange={(event) => handleCanTravelChange(event, '1')}
            >
              <Typography variant='s2' style={{ marginLeft: '8px' }}>
                USA
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              checked={values.can_travel_to?.includes('2')}
              onChange={(event) => handleCanTravelChange(event, '2')}
            >
              <Typography variant='s2' style={{ marginLeft: '8px' }}>
                Canada
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              checked={values.can_travel_to?.includes('3')}
              onChange={(event) => handleCanTravelChange(event, '3')}
            >
              <Typography variant='s2' style={{ marginLeft: '8px' }}>
                Mexico
              </Typography>
            </CustomCheckbox>
          </div>
          <ErrorMessage error={touchedErrors.can_travel_to} />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            Approved For
          </Typography>
          <div className='d-flex gap-3' style={{ minHeight: 33 }}>
            <CustomCheckbox
              checked={values.approved_for?.includes('1')}
              onChange={(event) => handleApprovedForChange(event, '1')}
            >
              <Typography variant='s2' style={{ marginLeft: '8px' }}>
                FAST
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              checked={values.approved_for?.includes('2')}
              onChange={(event) => handleApprovedForChange(event, '2')}
            >
              <Typography variant='s2' style={{ marginLeft: '8px' }}>
                TSA
              </Typography>
            </CustomCheckbox>
            <CustomCheckbox
              checked={values.approved_for?.includes('3')}
              onChange={(event) => handleApprovedForChange(event, '3')}
            >
              <Typography variant='s2' style={{ marginLeft: '8px' }}>
                TWIC
              </Typography>
            </CustomCheckbox>
            <div className='d-flex align-items-center gap-3'>
              <CustomCheckbox
                checked={values.approved_for?.includes('4')}
                onChange={(event) => handleApprovedForChange(event, '4')}
              >
                <Typography variant='s2' style={{ marginLeft: '8px' }}>
                  Custom
                </Typography>
              </CustomCheckbox>
              {values.approved_for?.includes('4') && (
                <Input
                  width='120px'
                  name='approved_for_custom'
                  value={values.approved_for_custom}
                  onChange={(e) => handleChange('approved_for_custom', e.target.value)}
                />
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={9}>
          <AddressFields form={form} />
        </Grid>
      </Grid>
    </form>
  );
};

export default BasicInfo;
