import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: ${({ $margin }) => $margin || ''};

  .search-icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const SSearchInput = styled.input`
  width: 320px;
  background: ${palette.white};
  height: 32px;
  box-shadow: ${palette.boxShadow2};
  border-radius: 6px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 30px;
  color: ${palette.gray500};
`;
