import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPublicQuote = styled.div`
  display: flex;
  justify-content: center;

  .public-quote-content {
    width: 100%;
    max-width: 1400px;
    justify-content: center;
    display: flex;
    gap: 12px;
    padding: 0 12px;

    .public-quote-left-section {
      width: 600px;
      max-width: 100%;
    }

    .public-quote-right-section {
      margin-top: 54px;
      width: calc(100% - 600px);
      padding: 16px;
      background-color: ${palette.indigo0};
    }
  }

  @media (max-width: 1000px) {
    .public-quote-content {
      .public-quote-left-section {
        width: 100%;
      }
    }
  }
`;

export const SHeader = styled.div`
  .company-info {
    display: flex;
    justify-content: space-between;
    background-color: ${palette.white};
    align-items: center;
    padding: 20px 32px 16px;
    min-height: 116px;
    border-bottom: 1px solid #e9edf5;

    .logo-wrapper {
      min-height: 48px;
      margin-bottom: 12px;

      img {
        object-fit: contain;
      }
    }

    & > * {
      flex: 1;
    }

    @media (max-width: 768px) {
      padding: 20px 8px 16px;
      .logo-wrapper {
        width: 50%;

        img {
          width: 100%;
        }
      }

      & > * {
        flex: unset;
      }
    }
  }
`;

export const ScrollableContainer = styled.div`
  overflow: auto;
  height: calc(100vh - 174px);
  padding-right: 12px;
`;

export const SCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px 56px;
  margin-bottom: 24px;

  .address-field-row {
    display: flex;
    align-items: center;
    column-gap: 54px;

    .address-field-title {
      width: 100px;
    }
  }

  @media (max-width: 768px) {
    span {
      font-size: 12px !important;
    }
  }
`;

export const SContent = styled.div`
  .quote-content-title,
  .quote-summary-title {
    padding: 24px 56px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .quote-summary-content {
    padding: 20px 56px;
  }
`;
