export const IDLE_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds
export const IDLE_TIMEOUT_60MIN = 15 * 60 * 1000; // 30 minutes in milliseconds
export const IDLE_PROMPT = 1000;
export const LBS_TO_KGS = 0.453592;
export const KGS_TO_LBS = 2.20462;
export const IDLE_MESSAGES = {
  available: {
    title: 'Hey, are you there?',
    description: 'It seems you were away for more than 15 minutes.',
    buttonTitle: 'Yes, I’m Available',
    isLogout: false,
  },
  logOuted: {
    title: 'You have been inactive!',
    description: 'It seems you were away for more than 30 minutes, so we logged you off for security reasons!',
    buttonTitle: 'I’m Back!',
    isLogout: true,
  },
};
export const IDLE_EVENTS = {
  all: [
    'click',
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
  ],
  click: ['click'],
};

function capitalize(string, loweCaseRest = false) {
  let result = string[0].toUpperCase();
  result += loweCaseRest ? string.slice(1) : string.slice(1).toLowerCase();

  return result;
}

export { capitalize };

export function nFormatter(value) {
  if (typeof value !== 'number') {
    value = parseFloat(value);
    if (Number.isNaN(value)) return 'Invalid input';
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = Math.floor(Math.log10(Math.abs(value)) / 3);
  if (suffixNum > 4) suffixNum = 4; // to prevent overflow
  let shortValue = parseFloat((suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(3));
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}

export function numberWithCommas(x, y = ',') {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, y);
}

export const formatNumber = (number, digitsCount = 2) => {
  return numberWithCommas(Number(number || 0).toFixed(digitsCount));
};

export function formatWithCommas(numStr) {
  const num = parseFloat(numStr);
  if (Number.isNaN(num)) return 'Invalid input';

  return num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const getAlphabet = (n) => {
  const ordA = 'A'.charCodeAt(0);
  const ordZ = 'Z'.charCodeAt(0);
  const len = ordZ - ordA + 1;

  let s = '';
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }
  return s;
};

export const getDaysNumber = (day) => {
  switch (+day) {
    case 0:
      return 6;
    case 1:
      return 0;
    case 2:
      return 1;
    case 3:
      return 2;
    case 4:
      return 3;
    case 5:
      return 4;
    case 6:
      return 5;
    default:
  }
};

export const debounce = (fn, ms = 350) => {
  let timeout;
  return (...rest) => {
    const fnCall = () => {
      fn.apply(this, rest);
    };
    clearTimeout(timeout);
    timeout = setTimeout(fnCall, ms);
  };
};

export const palette = {
  indigo0: '#F0F1FA',
  indigo50: '#E9EAFF',
  indigo100: '#DADCFF',
  indigo200: '#C1C5FF',
  indigo300: '#9EA5FF',
  indigo400: '#767FFB',
  indigo500: '#4F5AED',
  indigo600: '#3B44BE',
  indigo700: '#2D3395',
  indigo800: '#242A80',
  indigo900: '#14163E',
  orange0: '#FCF2E6',
  orange50: '#FCEAD6',
  orange100: '#EFCDA6',
  orange200: '#E2B076',
  orange300: '#D5944A',
  orange400: '#C97A20',
  orange500: '#AA5B00',
  orange600: '#995201',
  orange700: '#854700',
  orange800: '#6B3900',
  orange900: '#3A2103',
  gray0: '#F7F9FC',
  gray50: '#E9EDF5',
  gray100: '#D5DBE5',
  gray200: '#BCC2CE',
  gray300: '#A1A9B8',
  gray400: '#868FA0',
  gray500: '#687182',
  gray600: '#5A6376',
  gray700: '#464F60',
  gray800: '#333B4A',
  gray900: '#171C26',
  green0: '#E1FCEF',
  green50: '#CDFAE3',
  green100: '#A9EBCA',
  green200: '#81D6AB',
  green300: '#5FBF8F',
  green400: '#38A06C',
  green500: '#14804A',
  green600: '#0C6C3C',
  green700: '#01572C',
  green800: '#004322',
  green900: '#032C18',
  blueText: '#2264E5',
  blue300: '#80AAFF',
  blue400: '#4B85FA',
  blue500: '#2264E6',
  red0: '#FAF0F3',
  red50: '#F5D4DC',
  red100: '#F1B9C7',
  red200: '#EB88A1',
  red300: '#E56081',
  red400: '#DC4067',
  red500: '#D12953',
  red600: '#B52348',
  red700: '#9B1133',
  red800: '#73152C',
  red900: '#3B0210',
  amber0: '#FCFBF7',
  amber50: '#FFFAEB',
  amber100: '#FEF0C7',
  amber200: '#FDE08A',
  amber300: '#FCD04D',
  amber400: '#FBC524',
  amber500: '#F5BA0B',
  amber600: '#D9A406',
  amber700: '#B48909',
  amber800: '#8E6E0D',
  amber900: '#785E0F',
  goldDark: '#B48909',
  goldLight: '#FFFAEB',
  white: '#FFFFFF',
  dark0: '#20223F',
  dark1: '#212529',
  dark500: '#10172A',
  dark600: '#222B42',
  dark700: '#1D253B',
  dark800: '#161D32',
  dark900: '#10172A',
  pinkLight: '#F1B9C7',
  darkgreen: '#032C18',
  lightgreen: '#E1FCEF',
  lightgrey: '#d3d3d3',
  lightgrey50: '#808080',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.24), 0px 0px 0px 1px rgba(70, 79, 96, 0.4);',
  boxShadow2: '0px 1px 1px rgb(0 0 0 / 10%), 0px 0px 0px 1px rgb(70 79 96 / 16%), 0px 2px 5px rgb(89 96 120 / 10%)',
  boxShadow3: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(104, 113, 130, 0.16)',
  darkborder: '#222B42',
  lightborder: '#dee2e6',
  orangeBackground: '#331C03',
  orangeBackground1: '#FCF2E6',
  whiteBackground: '#f7f9fc',
  black: '#000',
};

export const statusColor = {
  'Logged Off': {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  Available: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  Arrived: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  'Not Available': {
    bgColor: '#FAF0F3',
    color: '#fa5c7c',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  'On Shipment': {
    bgColor: '#F0F1FA',
    color: '#4F5AED',
    darkBgColor: '#3A1921',
    darkColor: '#DC4067',
  },
  'Out of Service': {
    bgColor: '#faf0f3',
    color: 'rgb(220, 64, 103)',
    darkBgColor: '',
    darkColor: '',
  },
  'Dropped Trailer': {
    bgColor: '#F0F1FA',
    color: '#D12953',
    darkBgColor: '',
    darkColor: '',
  },
  Terminated: {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  Disabled: {
    bgColor: '#FAF0F3',
    color: '#D12953',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  Quit: {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  undefined: {
    bgColor: '#0000004d',
    color: '#000000',
    darkBgColor: '',
    darkColor: '',
  },
  Approved: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  Pending: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  Rejected: {
    bgColor: '#FAF0F3',
    color: '#D12953',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  Active: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  Away: {
    bgColor: '#fbb90029',
    color: '#ffbc00',
    darkBgColor: '#fbb90029',
    darkColor: '#ffbc00',
  },
  EX: {
    bgColor: '#F1B9C7',
    color: 'black',
  },
  'Do Not Disturb': {
    bgColor: '#FAF0F3',
    color: '#fa5c7c',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
};

export const LoginStatusColor = {
  'SUCCESSFUL LOGIN': {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  'INCORRECT PASSWORD OR ATTEMPT': {
    bgColor: '#FAF0F3',
    color: '#D12953',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  'UNAUTHORIZED SHIFT ATTEMPT': {
    bgColor: '#FAF0F3',
    color: '#fa5c7c',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
};

export const statusColorInStatusId = {
  1: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  }, // Available
  2: {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  }, // Not Available
  3: {
    bgColor: '#F0F1FA',
    color: '#4F5AED',
    darkBgColor: '#3A1921',
    darkColor: '#DC4067',
  }, // On Shipment
  4: {
    bgColor: '#faf0f3',
    color: 'rgb(220, 64, 103)',
    darkBgColor: '',
    darkColor: '',
  }, // Out of Service
  5: {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  }, // Terminated
  6: {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  }, // Quit
};

export const actionTaken = {
  Suspended: {
    bgColor: '#FAF0F3',
    color: '#D12953',
  },
  Terminated: {
    bgColor: '#FAF0F3',
    color: '#D12953',
  },
  '1st Warning': {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
  },
  '2nd Warning': {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
  },
  '3rd Warning': {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
  },
};

export const statusColorTransaction = {
  Active: {
    bgColor: '#F0F1FA',
    color: '#4F5AED',
    darkBgColor: '#3A1921',
    darkColor: '#DC4067',
  },
  Capture: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  'Cash Advance': {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
  },
  Failed: {
    bgColor: '#FAF0F3',
    color: '#D12953',
  },
};

// TODO: Remove all and leave only ids when everything is ready
export const absencseStatusColor = {
  UNEXCUSED: {
    bgColor: '#FAF0F3',
    color: '#D12953',
    darkBgColor: '#FAF0F3',
    darkColor: '#D12953',
  },
  EXCUSED: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#E1FCEF',
    darkColor: '#14804A',
  },
  VACATION: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkBgColor: '#FCF2E6',
    darkColor: '#AA5B00',
  },
  unexcused: {
    bgColor: '#FAF0F3',
    color: '#D12953',
    darkBgColor: '#FAF0F3',
    darkColor: '#D12953',
  },
  excused: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#E1FCEF',
    darkColor: '#14804A',
  },
  vacation: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkBgColor: '#FCF2E6',
    darkColor: '#AA5B00',
  },
  'Paid Time Off (PTO)': {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkBgColor: '#FCF2E6',
    darkColor: '#AA5B00',
  },
  undefined: {
    bgColor: '#0000004d',
    color: '#000000',
    darkBgColor: '',
    darkColor: '',
  },
  1: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#E1FCEF',
    darkColor: '#14804A',
  },
  2: {
    bgColor: '#FAF0F3',
    color: '#D12953',
    darkBgColor: '#FAF0F3',
    darkColor: '#D12953',
  },
  3: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkBgColor: '#FCF2E6',
    darkColor: '#AA5B00',
  },
};
export const months = [
  undefined,
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const cargoOnboardColors = {
  1: {
    bgColor: 'rgba(97, 138, 253, 0.2)',
    color: '#365dcc',
    darkBgColor: '#3A1921',
    darkColor: '#DC4067',
  },

  2: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  3: {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  4: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkBgColor: '#FCF2E6',
    darkColor: '#AA5B00',
  },
};

export const motionColors = {
  Moving: {
    bgColor: '#E1FCEF',
    color: '#14804A',
    darkBgColor: '#032C18',
    darkColor: '#14804A',
  },
  'Not Moving': {
    bgColor: '#F0F2F7',
    color: '#5A6376',
    darkBgColor: '#F0F2F7',
    darkColor: '#5A6376',
  },
  Idling: {
    bgColor: '#FCF2E6',
    color: '#AA5B00',
    darkBgColor: '#FCF2E6',
    darkColor: '#AA5B00',
  },
};

export const ACCOUNT_TYPE = {
  INCOME: 1,
  EXPENSE: 2,
  FIXED_ASSET: 3,
  EQUITY: 4,
  BANK: 5,
  LOAN: 6,
  CURRENT_LIABILITY: 7,
  CREDIT_CARD: 8,
  COST_OF_GOODS_SOLD: 9,
  LONG_TERM_LIABILITY: 10,
  OTHER_CURRENT_ASSET: 11,
  OTHER_EXPENSE: 12,
  ACCOUNT_RECEIVABLE: 13,
};

export const CUSTOMER_CONTACT_TYPE = {
  1: 'Primary',
  2: 'Secondary',
  3: 'Dispatch',
  4: 'Broker',
  5: 'Support',
  6: 'Operations',
  7: 'Manager',
  8: 'Technical',
  9: 'Sales',
  10: 'Corporate',
  11: 'Emergency',
  12: 'Tracking',
  13: 'After Hours',
  14: 'Accounting',
};

export const STOP_POINT_TAB = {
  GENERAL: 'general',
  CONTACT_BOOK: 'contact-book',
  WORKING_HOURS: 'working-hours',
  EQUIPMENT_HISTORY: 'equipment-history',
  INVENTORY: 'inventory',
  AVERAGE_WAIT_TIME: 'average-wait-time',
};

export const COST_TYPE = {
  FIXED: 1,
  VARIABLE: 2,
  ADMINISTRATIVE: 3,
  MAINTENANCE: 4,
};

export const COST_TYPE_REPORT_ID = {
  [COST_TYPE.FIXED]: 19,
  [COST_TYPE.VARIABLE]: 16,
  [COST_TYPE.ADMINISTRATIVE]: 12,
};

export const ADDON_PLAN_ID = {
  PAYROLL: 'lOaIsp',
  ACCOUNTING: 'AflQ6N',
  QUICKBOOKS: '7pJkcZ',
  SAFETY: 'kbfYBN',
  IFTA: 'ADy9CN',
  REPAIR_SHOP: 'sGvARD',
  BROKERAGE: 'qkxwy6',
};

export const RESTRICTION_TYPE = {
  ADDON: 'addon',
  DEPARTMENT_PERMISSION: 'department-permission',
  SETTINGS_REQUIRED: 'settings-required',
};

export const PERMISSIONS = {
  FLEET_STAFF: { name: 'Fleet & Staff' },
  FLEET_STAFF_EQUIPMENT: { name: 'Fleet & Staff (Equipment)' },
  FLEET_STAFF_DRIVERS_LIST: { name: 'Fleet & Staff (Drivers List Only)' },
  CHAT: { name: 'Chat' },
  PLANNER: { name: 'Planner' },
  SHIPMENTS: { name: 'Shipments' },
  SAFETY: { name: 'Safety' },
  STOP_POINTS: { name: 'Stop Points' },
  CUSTOMERS: { name: 'Customers' },
  ACCOUNTING: { name: 'Accounting' },
  MAP: { name: 'Map' },
  DOCUMENTS: { name: 'Documents' },
  IFTA: { name: 'IFTA' },
  REPORTS: { name: 'Reports' },
  PAYROLL: { name: 'Payroll' },
  BILLING: { name: 'Billing' },
  HARDWARE: { name: 'Hardware' },
  PROFILE: { name: 'Profile' },
  SETTINGS: { name: 'Settings' },
  CONNECTED_SERVICES: { name: 'Connected Services' },
  SECURITY_LOG: { name: 'Security Log' },
  SUPPORT: { name: 'Support' },
  COMPLETED_AUDIT: { name: 'Completed Audit' },
  SHIPMENT_HISTORY: { name: 'Shipment History' },
  ACCOUNTS: { name: 'Accounting - Chart of Accounts' },
  RECEIVABLES: { name: 'Accounting - Receivables' },
  PAYABLES: { name: 'Accounting - Payables' },
  VENDORS: { name: 'Accounting - Vendors' },
  ITEMS: { name: 'Accounting - Items And Services' },
  SEND_PAYMENT: { name: 'Accounting - Send Payment' },
  CARD_MANAGEMENT: { name: 'Card Management' },
  DRIVERS: { name: 'Fleet & Staff (Drivers)' },
  OWNER_OPERATORS: { name: 'Fleet & Staff (Owner Operators)' },
  REQUESTS: { name: 'Requests (Read and Write)' },
  REQUESTS_READ_ONLY: { name: 'Requests (Read Only)' },
  APPLICANTS_REFERRALS: { name: 'Fleet & Staff (Applicants & Referrals)' },
  ABSENCES: { name: 'Fleet & Staff (Absences)' },
  STAFF: { name: 'Fleet & Staff (Staff)' },
  DETENTION: { name: 'Detention' },
  RESOLVE_ALL_ALERTS: { name: 'Alert Center - Resolve All Option' },
  CARRIERS: { name: 'Carriers List' },
  CARRIER_PROFILE: { name: 'Carrier Profile' },
  CREATE_CARRIER: { name: 'Carrier Create' },
  CAN_REVIEW_INCIDENTS: { name: 'Can Review and Approve Incidents' },
  JOB_POSITIONS: { name: 'Job Positions' },
  APPLICANTS: { name: 'Applicants' },
  REFERRALS: { name: 'Referrals' },
  REFERRERS: { name: 'Referrers' },
  HIDE_DRIVER_SENSITIVE_INFO: { name: 'Hide Sensitive Information' },
  EDI_CUSTOMER_PROFILES_READ_ONLY: { name: 'EDI/API Customer Profiles (Read Only)' },
  EDI_CUSTOMER_PROFILES_EDIT: { name: 'EDI/API Customer Profiles (Edit)' },
};

export const CREATE_PERMISSIONS = {
  FLEET_STAFF: [
    PERMISSIONS.FLEET_STAFF.name,
    PERMISSIONS.DRIVERS.name,
    PERMISSIONS.FLEET_STAFF_DRIVERS_LIST.name,
    PERMISSIONS.FLEET_STAFF_EQUIPMENT.name,
    PERMISSIONS.STAFF.name,
    PERMISSIONS.OWNER_OPERATORS.name,
    PERMISSIONS.ABSENCES.name,
    PERMISSIONS.REQUESTS.name,
    PERMISSIONS.REQUESTS_READ_ONLY.name,
  ],
  DRIVER: [PERMISSIONS.FLEET_STAFF_DRIVERS_LIST.name, PERMISSIONS.DRIVERS.name, PERMISSIONS.FLEET_STAFF.name],
  EQUIPMENT: [PERMISSIONS.FLEET_STAFF_EQUIPMENT.name, PERMISSIONS.FLEET_STAFF.name],
  STAFF: [PERMISSIONS.STAFF.name, PERMISSIONS.FLEET_STAFF.name],
  OWNER_OPERATOR: [PERMISSIONS.OWNER_OPERATORS.name, PERMISSIONS.FLEET_STAFF.name],
  ATTENDANCE: [PERMISSIONS.ABSENCES.name, PERMISSIONS.FLEET_STAFF.name],
  REQUEST: [PERMISSIONS.REQUESTS_READ_ONLY.name, PERMISSIONS.REQUESTS.name, PERMISSIONS.FLEET_STAFF.name],
  DOCUMENT: [PERMISSIONS.DOCUMENTS.name],
  REPAIR_SHOP: [PERMISSIONS.FLEET_STAFF_EQUIPMENT.name, PERMISSIONS.FLEET_STAFF.name],
  SHIPMENT: [PERMISSIONS.PLANNER.name],
  CARRIER: [PERMISSIONS.CREATE_CARRIER.name],
  CUSTOMER: [PERMISSIONS.CUSTOMERS.name],
  STOP_POINT: [PERMISSIONS.STOP_POINTS.name],
  SAFETY: [PERMISSIONS.SAFETY.name],
  ACCOUNTING: [
    PERMISSIONS.ACCOUNTING.name,
    PERMISSIONS.RECEIVABLES.name,
    PERMISSIONS.PAYABLES.name,
    PERMISSIONS.ACCOUNTS.name,
    PERMISSIONS.VENDORS.name,
    PERMISSIONS.SEND_PAYMENT.name,
  ],
  ACCOUNT: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.ACCOUNTS.name],
  INVOICE: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.RECEIVABLES.name],
  VENDOR: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.VENDORS.name],
  ITEM_SERVICE: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.ITEMS.name],
  BILL: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.PAYABLES.name],
  SEND_PAYMENT: [PERMISSIONS.ACCOUNTING.name, PERMISSIONS.SEND_PAYMENT.name],
};

export const CURRENCY = {
  usd: { symbol: '$', id: 1 },
  cad: { symbol: 'C$', id: 2 },
  mxn: { symbol: '₱', id: 3 },
};

export const VALUE_TYPES = [
  { id: 1, name: 'Per Qty' },
  { id: 2, name: 'Flat' },
];

export const DIMENSION_UNIT_TO_FEET = {
  1: 1,
  2: 0.0833333,
  3: 3.28083858268,
  4: 0.0032808385826799996328,
};

export const DATE_FORMAT = {
  1: 'MM/DD/YYYY',
  2: 'DD/MM/YYYY',
  3: 'YYYY/MM/DD',
  4: 'YYYY/DD/MM',
};

export const TIME_FORMAT = {
  1: 'hh:mm A',
  2: 'HH:mm',
};

export const TRANSMISSION_TYPE = {
  1: 'Automatic',
  2: 'Manual',
  3: 'Any',
};

export const SYSTEM_ACCOUNT_TYPE = {
  WEX: 1,
  TRUCKIN_DIGITAL_CASH_CARD: 2,
  TRUCKIN_DIGITAL_TREASURY: 3,
};

export const STRIPE_MODULE = {
  ACCOUNTS_RECEIVABLES: 'accounts_receivables',
  ACCOUNTS_PAYABLE: 'accounts_payable',
  PAYROLL: 'payroll',
  CARD_MANAGEMENT: 'card_management',
};

export const TIME_APP_VIDEO_LINK = 'https://player.vimeo.com/video/859128663?h=90396e92d1';

export const TRUCK_TYPES = {
  1: 'Day-Cab',
  2: 'Sleeper',
};

export const absenceBasedOnOptions = [
  { id: 1, name: 'Based on hire date' },
  { id: 2, name: 'Based on current year' },
];

export const APPLICANT_REQUIREMENTS = {
  HISTORY_OF_TRAFFIC:
    'History of traffic accidents, violations and/or convictions from the last 3 years (including DUI or reckless driving conviction and license suspension)',
  HOME_ADDRESS: 'Home address history for the past 3 years',
  MILITARY_HISTORY: 'Military history',
  SOCIAL_SECURITY_NUMBER: 'Social Security Number',
  EDUCATION: 'Education',
};
