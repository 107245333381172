import React from 'react';
import { Typography } from 'components/Typography';
import { SCustomModal } from './MessagePopup.styles';

const MessagePopup = ({ open, onClose, title, message, customBody }) => {
  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      $maxWidth='350px'
      $height='auto'
      headerTitle={title}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Okay',
          onClick: onClose,
        },
      ]}
    >
      {customBody || <Typography variant='s2'>{message}</Typography>}
    </SCustomModal>
  );
};

export default MessagePopup;
