import React, { useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import { ReactComponent as FilterDark } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkBoxLight.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getStates } from 'Api/JobPositions';
import {
  SArrow,
  SAutocomplete,
  SContainer,
  SFilterOptions,
  SInput,
  SPopover,
  SSearchTitle,
  SWrapper,
} from './PositionsFilter.styles';

const filterOptions = [
  { key: 'all', label: 'All', className: 'space-between' },
  { key: 'location', label: 'Location', className: 'space-between' },
];

const PositionsFilter = ({
  filterBy,
  onFilterChange,
  searchValue,
  onSearchValueChange,
  selectedStates,
  onStatesChange,
  departmentValues,
  onDepartmentChange,
  clearFilterValues,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [departmentsAnchor, setDepartmentsAnchor] = useState(null);
  const [hoveredCheckbox, setHoveredCheckbox] = useState(null);
  const [states, setStates] = useState([]);
  const divRef = useRef(null);
  const { use } = useTheme();

  const getStatesList = async () => {
    try {
      const response = await getStates({ country_id: [231, 38, 142] });
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getStatesList();
  }, []);

  return (
    <SContainer ref={divRef}>
      <SWrapper onClick={(e) => setAnchorEl(e.currentTarget)}>
        <FilterDark width={12} height={14} fill={use(palette.gray700, palette.gray200)} />
        <SSearchTitle>{filterOptions.find((item) => item.key === filterBy)?.label || 'All'}</SSearchTitle>
        <SArrow />
      </SWrapper>
      {(filterBy === 'all' || filterBy === 'department') && (
        <SInput value={searchValue} onChange={(e) => onSearchValueChange(e.target.value)} placeholder='Search' />
      )}
      {filterBy === 'location' && (
        <SAutocomplete
          multiple
          options={states}
          renderTags={() => null}
          value={selectedStates}
          onChange={(e, values) => onStatesChange(values)}
          placeholder='Select or Enter...'
        />
      )}
      <SPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        container={divRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SFilterOptions>
          {filterOptions.map(({ key, label, subOptions, icon, className }) => (
            <React.Fragment key={key}>
              <li
                className={`${key === filterBy ? 'selected' : ''} ${className || ''}`}
                onClick={
                  subOptions
                    ? undefined
                    : () => {
                        onFilterChange(key);
                        setAnchorEl(null);
                        clearFilterValues();
                      }
                }
                onMouseEnter={subOptions ? (e) => setDepartmentsAnchor(e.currentTarget) : undefined}
              >
                {label} {icon} {!subOptions && filterBy === key && <CheckIcon />}
              </li>
              {subOptions && (
                <Popover
                  open={Boolean(departmentsAnchor)}
                  anchorEl={departmentsAnchor}
                  onClose={() => setDepartmentsAnchor(null)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <SFilterOptions onMouseOut={() => setHoveredCheckbox(null)}>
                    {subOptions.map((option) => (
                      <li
                        key={option.key}
                        className={`flex ${option.className || ''}`}
                        onClick={option.subOptions ? undefined : () => onDepartmentChange(option)}
                        onMouseOver={() => setHoveredCheckbox(option.key)}
                      >
                        <CustomCheckbox
                          checked={departmentValues.some((item) => item.key === option.key)}
                          hovered={option.key === hoveredCheckbox}
                        />{' '}
                        {option.label} {option.icon}
                      </li>
                    ))}
                  </SFilterOptions>
                </Popover>
              )}
            </React.Fragment>
          ))}
        </SFilterOptions>
      </SPopover>
    </SContainer>
  );
};

export default PositionsFilter;
