import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px;
    white-space: nowrap;
  }

  td {
    padding: 8px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const ScrollableContainer = styled.div`
  margin-top: 144px;
  overflow: auto;
  height: calc(100vh - 192px);
  background: ${palette.gray0};

  .contact-book-table {
    padding: 24px 36px;
  }
`;

export const SAddressFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    span {
      font-size: 12px !important;
    }
  }
`;

export const SHeader = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
`;

export const SCompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${palette.white};
  align-items: center;
  padding: 20px 40px 16px;
  min-height: 116px;
  border-bottom: 1px solid #e9edf5;

  .logo-wrapper {
    min-height: 48px;

    img {
      object-fit: contain;
    }
  }

  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    padding: 20px 8px 16px;
    .logo-wrapper {
      width: 50%;

      img {
        width: 100%;
      }
    }

    & > * {
      flex: unset;
    }
  }
`;
