import React, { forwardRef, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListItemText, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { palette, PERMISSIONS } from 'utils/constants';
import { useTheme } from 'context/themeContext';

import arrowRight from 'assets/icons/arrowRight.png';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as Billing } from 'assets/icons/billing.svg';

import { ReactComponent as Addon } from 'assets/icons/addon.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Profile } from 'assets/icons/profile.svg';
import { ReactComponent as Support } from 'assets/icons/support.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Bulb } from 'assets/icons/bulb.svg';
import { ReactComponent as Policy } from 'assets/icons/policy.svg';
import { ReactComponent as Handbook } from 'assets/icons/handbook.svg';
import { ReactComponent as Hardware } from 'assets/icons/hardware.svg';
import { ReactComponent as CartIcon } from 'assets/icons/equipment/cart.svg';
import { ReactComponent as StatusIcon } from 'assets/icons/system-status.svg';
import { ReactComponent as Security } from 'assets/icons/security.svg';

import AuthService from 'services/auth.service';
import { UpdateUserStatus } from 'Api/Header';
import { useAuth } from 'context/auth.context';
import styles from './DropDownAvatar.module.css';
import { LayoutContext } from '../layout';
import { Typography } from '../Typography';
import packageJson from '../../../package.json';

export const DROPDOWN_IDS = {
  Available: 1,
  Billing: 2,
  Hardware: 3,
  MyHardware: 11,
  Addons: 9,
  Profile: 4,
  Security_Log: 5,
  Support: 6,
  System_Status: 10,
  Knowledge_Base: 12,
  Feature_Request: 14,
  Company_Guidance_Manuals: 7,
  Rules_Conduct: 13,
  Logout: 8,
};

export const LOGIN_STATUSES = {
  1: { id: 10, title: 'Available', className: styles.collapse_item1, status: 1 },
  3: { id: 11, title: 'Away', className: styles.collapse_item2, status: 3 },
  4: { id: 12, title: 'Do Not Disturb', className: styles.collapse_item3, status: 4 },
};

const DropDownAvatar = forwardRef(({ className, setToggleNotif }, ref) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { userStatus, userStatusUpdate } = useContext(LayoutContext);
  const { permissions } = useSelector((state) => state?.root);
  const [open, setOpen] = useState(false);
  const storageData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

  const avatarDropDownData = useMemo(() => {
    return [
      {
        id: DROPDOWN_IDS.Available,
        title: 'Available',
        color: 'green',
        icon: arrowRight,
        items: Object.values(LOGIN_STATUSES),
      },
      {
        id: DROPDOWN_IDS.Billing,
        title: 'Billing',
        color: use(palette.gray900, palette.gray50),
        icon: <Billing />,
        permission: PERMISSIONS.BILLING.name,
      },
      {
        id: DROPDOWN_IDS.Hardware,
        title: 'Web Store',
        color: use(palette.gray900, palette.gray50),
        icon: <CartIcon fill={palette.indigo400} width={18} height={18} />,
        permission: PERMISSIONS.HARDWARE.name,
      },
      {
        id: DROPDOWN_IDS.MyHardware,
        title: 'My Hardware',
        color: use(palette.gray900, palette.gray50),
        icon: <Hardware />,
        permission: PERMISSIONS.HARDWARE.name,
      },
      {
        id: DROPDOWN_IDS.Addons,
        title: 'Addons',
        color: use(palette.gray900, palette.gray50),
        icon: <Addon />,
        permission: PERMISSIONS.BILLING.name,
      },
      {
        id: DROPDOWN_IDS.Profile,
        title: 'Profile',
        color: use(palette.gray900, palette.gray50),
        icon: <Profile />,
        permission: PERMISSIONS.PROFILE.name,
      },
      {
        id: DROPDOWN_IDS.Support,
        title: 'Support',
        color: use(palette.gray900, palette.gray50),
        icon: <Support />,
        permission: PERMISSIONS.SUPPORT.name,
      },
      {
        id: DROPDOWN_IDS.Security_Log,
        title: 'Security',
        color: use(palette.gray900, palette.gray50),
        icon: <Security />,
        permission: PERMISSIONS.SETTINGS.name,
      },
      {
        id: DROPDOWN_IDS.System_Status,
        title: 'System Status',
        color: use(palette.gray900, palette.gray50),
        icon: <StatusIcon />,
      },
      {
        id: DROPDOWN_IDS.Knowledge_Base,
        title: 'Knowledge Base',
        color: use(palette.gray900, palette.gray50),
        icon: <Info width={15} height={15} />,
      },
      {
        id: DROPDOWN_IDS.Feature_Request,
        title: 'Feature Request',
        color: use(palette.gray900, palette.gray50),
        icon: <Bulb width={23} height={23} />,
      },
      {
        id: DROPDOWN_IDS.Company_Guidance_Manuals,
        title: 'Company Guides',
        color: use(palette.gray900, palette.gray50),
        icon: <Handbook />,
      },
      {
        id: DROPDOWN_IDS.Rules_Conduct,
        title: 'Rules and Conduct',
        color: use(palette.gray900, palette.gray50),
        icon: <Policy />,
      },
      { id: DROPDOWN_IDS.Logout, title: 'Logout', color: '#D12953', icon: <Logout /> },
    ];
  }, []);

  const getColorTitle = () => {
    switch (userStatus?.status) {
      case 1:
        return palette.green400;
      case 3:
        return palette.goldDark;
      case 4:
        return palette.red400;
      default:
    }
  };

  const onClickMenuItem = (id) => {
    switch (id) {
      case DROPDOWN_IDS.Billing:
        navigate('/billing');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Profile:
        navigate('/profile');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Security_Log:
        navigate('/company-settings', { state: { tabId: 4, subTabName: 'Security' } });
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Hardware:
        navigate('/hardware');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.MyHardware:
        navigate('/my-hardware');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Addons:
        navigate('/billing?openAddon=true');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Support:
        navigate('/support');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.System_Status:
        window.open('https://truckindigitalapp.statuspage.io/', '_blank');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Knowledge_Base:
        window.open('https://support.truckindigital.com/support/solutions', '_blank');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Feature_Request:
        window.open('https://support.truckindigital.com/support/discussions/forums/154000331201', '_blank');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Company_Guidance_Manuals:
        navigate('/handbook');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Rules_Conduct:
        navigate('/rules-conduct');
        setToggleNotif(false);
        break;
      case DROPDOWN_IDS.Logout:
        AuthService.logout().then(() => {
          localStorage.clear();
          localStorage.setItem('version', packageJson.version);
          setAuth({
            user: {},
            isLoggedIn: false,
            error: '',
            loading: false,
          });
          navigate('/login');
        });
        break;
      default:
    }
  };

  const updateUserStatus = (el) => {
    const user = JSON.parse(localStorage.getItem('user'));

    UpdateUserStatus(el.status)
      .then((res) => {
        if (res && res.success) {
          const newUserData = { ...user, login_status: el.status };
          localStorage.setItem('user', JSON.stringify(newUserData));

          userStatusUpdate(el);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  return (
    <div ref={ref} className={className}>
      <div className={styles.dropDownAvatar_header} style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <div className={styles.header_wrapper}>
          <span className={styles.title} style={{ color: use(palette.gray900, palette.white) }}>
            {storageData?.first_name} {storageData?.last_name}
          </span>
          <span className={styles.description}>{storageData?.customer?.company_name}</span>
          <span className={styles.description}>{storageData?.department?.department_name}</span>
        </div>
      </div>
      <div className={styles.dropDownAvatar_body} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        {avatarDropDownData
          .filter((el) => !el.permission || permissions.includes(el.permission))
          .map((el) => {
            const { id, title, icon, color, items } = el;
            if (id === 1) {
              return (
                <React.Fragment key={id}>
                  <div className={styles.body_item} onClick={() => setOpen((p) => !p)}>
                    <ListItemText primary={userStatus?.title} sx={{ color: getColorTitle() }} />
                    <img
                      src={icon}
                      alt='#'
                      width={8}
                      height={12}
                      style={{ transform: open ? 'rotate(90deg)' : 'rotate(0deg)', marginRight: 7 }}
                    />
                  </div>

                  <Collapse in={open} timeout='auto' unmountOnExit>
                    <ul className={styles.collapse_wrapper}>
                      {items &&
                        items.map((el) => {
                          return (
                            <div onClick={() => updateUserStatus(el)} key={el.id}>
                              <li style={{ color: use(palette.gray700, palette.gray200) }} className={el.className}>
                                {el.title}
                              </li>
                              {userStatus.id === el.id && (
                                <TickIcon style={{ width: 11, height: 11, marginRight: 4 }} />
                              )}
                            </div>
                          );
                        })}
                    </ul>
                  </Collapse>
                </React.Fragment>
              );
            }
            return (
              <div className={styles.body_item} key={id} onClick={() => onClickMenuItem(id)}>
                <Typography variant='s2' style={{ color }}>
                  {title}
                </Typography>
                <span className={styles.icon_block}>{icon}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default DropDownAvatar;
