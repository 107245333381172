import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import './company.css';
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select';
import OnboardingService from 'services/onboardingService';
import CountryStateCityService from 'services/countryStateCity.service';
import { useTheme } from 'context/themeContext';
import Input from 'common/Input';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import ErrorMessage from 'common/message/errorMessage';
import AddressAutocomplete from 'common/AddressAutocomplete';
import SplashScreen from 'components/splashScreen/splashScreen';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getCities } from 'Api/JobPositions';
import { PostBillingCheckPricesCoupon } from 'Api/Billing';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import AuthService from 'services/auth.service';
import { useAuth } from 'context/auth.context';
import InfoSection from './InfoSection';
import ShippingAddressModal from './ShippingAddressModal';
import { SAddressWrapper, SCompany } from './Compnay.styles';

const countriesOptions = [
  {
    id: 231,
    sortname: 'US',
    name: 'United States',
    phonecode: 1,
  },
  {
    id: 38,
    sortname: 'CA',
    name: 'Canada',
    phonecode: 1,
  },
  {
    id: 142,
    sortname: 'MX',
    name: 'Mexico',
    phonecode: 52,
  },
];

const whitelist = ['US', 'CA', 'MX'];
const API_URL = process.env.REACT_APP_API_URL;
const titles = ['CEO', 'President', 'Vice President', 'IT Manager', 'Manager', 'Operations Director'];
const steps = ['Creating your account..', 'Creating database..', 'Creating storage bucket..', 'Finishing up..'];

const Company = () => {
  const { use } = useTheme();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { plans, onboardingSettings } = useSelector((state) => state.onboarding);
  const accountVal = JSON.parse(localStorage.getItem('account'));
  const dotNumber = JSON.parse(localStorage.getItem('dot-num'));
  const matchingCountry = accountVal.country
    ? countriesOptions.find(
        (item) => item.sortname === accountVal.country || item.name.toLowerCase() === accountVal.country.toLowerCase()
      ) || null
    : null;
  const [showMobileAlert, setShowMobileAlert] = useState(false);
  const [phone, setPhone] = useState(`${accountVal?.phone}`);
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    matchingCountry ? { name: matchingCountry.name, label: matchingCountry.name, id: matchingCountry.id } : null
  );
  const [loading, setLoading] = useState(false);
  const brokerInfo = JSON.parse(localStorage.getItem('brokerInfo'));
  const [selectedState, setSelectedState] = useState(null);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [dataErrors, setdataErrors] = useState({});
  const [displayTitle, setdisplayTitle] = useState(false);
  const [title, setTitle] = useState(titles[0]);
  const [shippingAddressOpen, setShippingAddressOpen] = useState(false);
  const [refreshCities, setRefreshCities] = useState(0);
  const isDefaultAddressUsed = useRef(false);
  const [appliedPromoCode, setAppliedPromoCode] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [isInvalidPromoCode, setIsInvalidPromoCode] = useState(false);
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches);
  const [data, setFormVals] = useState({
    email: accountVal.email,
    first_name: accountVal.name,
    last_name: accountVal.last_name,
    company_name: accountVal.company_name,
    address1: accountVal.address1 || '',
    address2: '',
    dot: dotNumber,
    zip: accountVal.zip || '',
    terms_and_conditions: false,
    privacy_policy: false,
    billing_policy: false,
  });
  const scrollRef = useRef(null);
  const plan = JSON.parse(localStorage.getItem('plan-selected'));
  const skipSelectPlan = !!plans?.[0]?.plan_types?.length && plans?.[0]?.plan_types?.length <= 1;
  const skipPayment = !!onboardingSettings && !onboardingSettings.is_require_payment_enable;

  let countryOptions = [];
  let stateOptions = [];
  let cityOptions = [];

  countryOptions = countriesOptions.map((data) => ({
    name: data.name,
    label: data.name,
    id: data.id,
  }));

  stateOptions = state?.map((state) => ({
    name: state.name,
    label: state.name,
    id: state.id,
  }));

  cityOptions = city.data?.map((city) => ({
    name: city.name,
    label: city.name,
    id: city.id,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormVals((prevState) => ({
      ...prevState,
      [name]: name === 'email' ? value.toLowerCase() : value,
    }));

    if (Object.keys(dataErrors).length !== 0) {
      setdataErrors({});
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormVals((prevState) => ({ ...prevState, [name]: checked }));

    if (Object.keys(dataErrors).length !== 0) {
      setdataErrors({});
    }
  };

  const handleShipmentAddressSubmit = (values) => {
    setFormVals((prevState) => ({
      ...prevState,
      shipping_address1: values?.shipping_address1,
      shipping_address2: values?.shipping_address2,
      shipping_country: values?.shipping_country?.id || null,
      shipping_state: values?.shipping_state?.id || null,
      shipping_city: values?.shipping_city?.id || null,
      shipping_zip: values?.shipping_zip,
    }));
  };

  const createAccount = async (customerData) => {
    const submitForPrices = {
      plan_type_membership_id: plan.membership_plan_id,
      payment_method: 'credit-card',
      number_of_users: Number(accountVal.workers || 0),
      state_id: data?.shipping_state || selectedState?.id,
      hardware: [],
      applied_coupon: appliedPromoCode?.code || null,
      source: 'onboarding',
    };
    const { total } = await PostBillingCheckPricesCoupon(submitForPrices);

    const paymentData = {
      agreement: {
        terms_and_conditions: data.terms_and_conditions ? 'on' : '',
        privacy_policy: data.privacy_policy ? 'on' : '',
        billing_policy: data.billing_policy ? 'on' : '',
      },
      partner_referral_link: accountVal.partner_referral_link || undefined,
      plan_type_membership_id: plan.membership_plan_id,
      payment_method: null,
      number_of_users: Number(accountVal.workers || 0),
      applied_coupon: appliedPromoCode?.code || null,
      customer_data: {
        ...customerData,
        send_sms: 1,
        customer_type: brokerInfo ? 'carrier_broker' : 'carrier',
      },
      hardware: [],
    };

    setLoading(true);
    axios
      .post(`${API_URL}/user/registration/carrier/checkout`, {
        ...paymentData,
        broker_profile: brokerInfo
          ? {
              company_name: brokerInfo.company_name || null,
              dot: brokerInfo.dot || null,
              company_email: brokerInfo.email || null,
              address1: brokerInfo.address || null,
              address2: brokerInfo.address2 || null,
              phone_number: brokerInfo.phone_number || null,
              country_id: brokerInfo.country?.id || null,
              state_id: brokerInfo.state?.id || null,
              city_id: brokerInfo.city?.id || null,
              zipcode: brokerInfo.zipcode || null,
            }
          : null,
        stripe_token: null,
        total: total || 0,
        payment_method_bypass: 1,
        payment_method: null,
      })
      .then((res) => {
        if (!matches) {
          setShowMobileAlert(true);
          setLoading(false);
          return;
        }
        if (res.data.token.access_token) {
          res.data.user.accessToken = res.data.token.access_token;
          setAuth({
            user: res.data.user,
            isLoggedIn: true,
            error: null,
            loading: false,
          });
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem('accountStatus', JSON.stringify(res.data.customer_status_data));
          if (res.data?.chat_token) {
            localStorage.setItem('chatToken', JSON.stringify(res.data.chat_token));
          }
          AuthService.getCurrentUser();
        }

        localStorage.removeItem('hardwareCart');
        localStorage.removeItem('goBackCartItems');
        localStorage.removeItem('goBackFromCompany');
        localStorage.removeItem('price-summary');

        if (isMobile) {
          window.open('https://www.truckindigital.com/app-manager-install', '_self');
        } else {
          navigate('/dashboard', { replace: true });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const message = getErrorMessage(err);
        if (err.response.data?.errors) {
          setErrMsg(err.response.data?.errors?.customer_data[0]);
          setDisplayError(true);
          return;
        }
        setErrMsg(message || 'Something went wrong');
        setDisplayError(true);
      });
  };

  const handleSubmission = (e) => {
    e.preventDefault();

    const zip = data?.zip?.split('-')?.[0];
    const Obj = {
      ...data,
      title,
      city_id: selectedCity?.id,
      state_id: selectedState?.id,
      country_id: selectedCountry?.id,
      zip,
      phone_number: phone,
      fax_number: phone,
      mobile: phoneNumber,
      shipping_address1: data?.shipping_address1 || data?.address1,
      shipping_address2: data?.shipping_address2 || data?.address2,
      shipping_country: data?.shipping_country || selectedCountry?.id,
      shipping_state: data?.shipping_state || selectedState?.id,
      shipping_city: data?.shipping_city || selectedCity?.id,
      shipping_zip: data?.shipping_zip || zip,
    };
    const errors = vailidate(Obj);
    if (Object.keys(errors).length !== 0) {
      setdataErrors(errors);
      if (errors?.mobile) {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }
    OnboardingService.validateAccount(Obj)
      .then((res) => {
        if (res.data?.message) {
          if (res.data.errors?.email) {
            setErrMsg(res.data.errors?.email[0]);
            setDisplayError(true);
            return;
          }
          if (res.data.errors?.dot) {
            setErrMsg(res.data.errors?.dot[0]);
            setDisplayError(true);
            return;
          }
          if (res.data.errors?.zip) {
            setErrMsg(res.data.errors?.zip[0]);
            setDisplayError(true);
            return;
          }

          if (res.data.errors?.company_name) {
            setErrMsg(res.data.errors?.company_name[0]);
            setDisplayError(true);
            return;
          }

          setErrMsg(res.data.message);
          setDisplayError(true);
          return;
        }
        if (appliedPromoCode) {
          localStorage.setItem('coupon', JSON.stringify(appliedPromoCode));
        }
        localStorage.setItem('accountValid', JSON.stringify(Obj));
        if (skipPayment) {
          createAccount(Obj);
        } else {
          navigate('/onboarding/account-summary');
        }
      })
      .catch((err) => {
        setErrMsg(err.message);
        setDisplayError(true);
      });

    const objectTwo = {
      ...data,
      title,
      city: selectedCity,
      state: selectedState,
      country: selectedCountry,
      phone_number: phone,
      fax_number: phone,
      mobile: phoneNumber,
    };

    localStorage.setItem('goBackFromCompany', JSON.stringify(objectTwo));
  };

  const vailidate = (val) => {
    // const zipRegEx = /^[a-zA-Z0-9 ]+$/;
    const errors = {};
    if (!val.address1) {
      errors.address1 = 'Address is required';
    }
    if (!val.city_id) {
      errors.city = 'Please select city';
    }
    if (!val.mobile || val.mobile?.length < 6) {
      errors.mobile = 'Please enter  mobile number';
    }
    if (!val.company_name) {
      errors.company = 'Please enter company name';
    }
    if (!val.last_name) {
      errors.last_name = 'Please enter lastname';
    }

    if (!val.country_id) {
      errors.country = 'Please select company';
    }
    if (!val.dot) {
      errors.dot = 'Please enter dot';
    }
    if (!val.email) {
      errors.email = 'Please enter email';
    }
    if (!val.first_name) {
      errors.name = 'Please enter your name';
    }
    if (!val.phone_number) {
      errors.phone_number = 'Please enter your  phone number';
    }
    if (!val.state_id) {
      errors.state = 'Please select state';
    }
    if (!val.zip) {
      errors.zip = 'Please enter zipcode ';
    }
    if (!val.terms_and_conditions) {
      errors.terms_and_conditions = 'Required';
    }
    if (!val.privacy_policy) {
      errors.privacy_policy = 'Required';
    }
    if (!val.billing_policy) {
      errors.billing_policy = 'Required';
    }
    // else if (!zipRegEx.test(val.zip)) {
    //   errors.zip = 'This is not valid zipcode';
    // }
    return errors;
  };

  const handleTitle = (data) => {
    setTitle(data);
    setdisplayTitle(!displayTitle);
  };

  const handleGoBack = () => {
    const Obj = {
      ...data,
      title,
      city: selectedCity,
      state: selectedState,
      country: selectedCountry,
      phone_number: phone,
      fax_number: phone,
      mobile: phoneNumber,
    };
    localStorage.setItem('goBackFromCompany', JSON.stringify(Obj));
    navigate(skipSelectPlan ? '/onboarding/account' : '/onboarding/select-plan', { replace: true });
  };

  const onAddressSelect = (data) => {
    setSelectedCountry({ name: data.country.name, label: data.country.name, id: data.country.id });
    setSelectedState({ name: data.state.name, label: data.state.name, id: data.state.id });
    setSelectedCity({ name: data.city.name, label: data.city.name, id: data.city.id });
  };

  const changeAddress = ({ address, zip }) => {
    setFormVals((prevState) => ({ ...prevState, address1: address, zip: zip || data.zip }));
  };

  const customStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? palette.gray0 : null,
        color: '#333333',
      };
    },
  };

  const handleApplyCoupon = async () => {
    try {
      const body = {
        plan_type_membership_id: plan.membership_plan_id,
        payment_method: 'credit-card',
        number_of_users: Number(accountVal.workers || 0),
        state_id: data?.shipping_state || selectedState?.id,
        hardware: [],
        applied_coupon: promoCode,
        source: 'onboarding',
      };
      const { plan_prices } = await PostBillingCheckPricesCoupon(body);
      if (plan_prices.promo_code) {
        setAppliedPromoCode(plan_prices.promo_code || null);
        setPromoCode('');
      } else {
        setAppliedPromoCode(null);
        setIsInvalidPromoCode(true);
      }
    } catch (e) {
      showToaster({ type: 'error' });
    }
  };

  const onCancelCoupon = () => {
    setAppliedPromoCode(null);
  };

  useEffect(() => {
    if (selectedState?.id !== undefined) {
      const getCity = () => {
        CountryStateCityService.getCity(selectedState?.id)
          .then(async (res) => {
            setCity(res.data);
            if (accountVal.city && !isDefaultAddressUsed.current) {
              const matchingCity = res.data.data.find(
                (item) => item.name.toLowerCase() === accountVal.city.toLowerCase()
              );
              if (matchingCity) {
                setSelectedCity({ name: matchingCity.name, label: matchingCity.name, id: matchingCity.id });
                isDefaultAddressUsed.current = true;
              } else if (refreshCities < 3) {
                await getCities({
                  state_id: selectedState?.id,
                  city_name: accountVal.city ? capitalize(accountVal.city) : undefined,
                });
                setRefreshCities((prevState) => prevState + 1);
              }
            }
          })
          .catch((err) => {
            setErrMsg(err);
            setDisplayError(true);
          });
      };
      getCity();
    }
  }, [selectedState, refreshCities]);

  useEffect(() => {
    if (selectedCountry?.id !== undefined) {
      const getState = () => {
        CountryStateCityService.getState(selectedCountry?.id)
          .then((res) => {
            setState(res.data.data);
            if (accountVal.state && !isDefaultAddressUsed.current) {
              const matchingState = res.data.data.find(
                (item) =>
                  item.short_name === accountVal.state || item.name.toLowerCase() === accountVal.state.toLowerCase()
              );
              if (matchingState) {
                setSelectedState({ name: matchingState.name, label: matchingState.name, id: matchingState.id });
              }
            }
          })
          .catch((err) => {
            setErrMsg(err);
            setDisplayError(true);
          });
      };

      getState();
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (Object.keys(dataErrors).length !== 0) {
      setdataErrors({});
    }
  }, [phone, phoneNumber, selectedCity, selectedCountry, selectedState]);

  useEffect(() => {
    const companeyGobackData = JSON.parse(localStorage.getItem('goBackFromCompany'));
    if (companeyGobackData !== null) {
      setPhoneNumber(companeyGobackData.mobile);
      setTitle(companeyGobackData.title);
      setSelectedCity(companeyGobackData.city);
      setSelectedCountry(companeyGobackData.country);
      setSelectedState(companeyGobackData.state);
      data.zip = companeyGobackData.zip;
      data.address1 = companeyGobackData.address1;
      data.address2 = companeyGobackData.address2;
      data.first_name = companeyGobackData.first_name;
      data.last_name = companeyGobackData.last_name;
    }
  }, []);

  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <SCompany className='company'>
      {showMobileAlert && <MobileScreenAlert setShowMobileAlert={setShowMobileAlert} />}
      <div className='company-content-wrapper'>
        <div className='company-container'>
          <h5 className='header-h5'>Your Details</h5>
          <p className='body-b2 text-left'>Please fill in the details below to create your account.</p>
          <Divider color={palette.gray50} margin='0' />
          <form>
            <div ref={scrollRef} />
            <div className='mb-3'>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  Company Name
                </label>{' '}
                <label className='star-required'>*</label>
              </div>
              <input
                type='text'
                name='company_name'
                className='form-control onboarding-input'
                onChange={handleChange}
                value={data.company_name}
                id='exampleFormControlInput1'
                placeholder='Company Name'
              />
              <p className='requiredMessage'>{dataErrors.company}</p>
            </div>
            <div style={{ display: 'flex' }}>
              <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2 '>
                Your Name
              </label>{' '}
              <label className='star-required'>*</label>
            </div>
            <div className='input-group input-left-sign '>
              {displayTitle && (
                <ul className='titleBtn'>
                  {titles.map((data) => (
                    <li key={data} onClick={() => handleTitle(data)}>
                      {data}
                    </li>
                  ))}
                </ul>
              )}

              <button
                className='dropdown-toggle'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                onClick={() => setdisplayTitle(!displayTitle)}
              >
                {title}
              </button>

              <input
                type='text'
                name='first_name'
                className='form-control input-left-sign-area '
                placeholder='First Name'
                aria-label='Username'
                value={data.first_name}
                onChange={handleChange}
                aria-describedby='basic-addon1'
              />
              <input
                type='text'
                name='last_name'
                className='form-control input-left-sign-area your-name'
                placeholder='Last Name'
                aria-label='Username'
                value={data.last_name}
                onChange={handleChange}
                aria-describedby='basic-addon1'
              />

              <p className='requiredMessage'>
                {dataErrors.name}
                {dataErrors.last_name}
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                Mobile Number
              </label>{' '}
              <label className='star-required'>*</label>
            </div>
            <div className='mb-3'>
              <PhoneInput
                style={{ border: 'none!important' }}
                className='form-control input-left-sign-area phoneInput'
                countries={whitelist}
                defaultCountry='US'
                onChange={setPhoneNumber}
                value={phoneNumber}
                placeholder='(555) 555-1234'
              />
              <p className='requiredMessage'>{dataErrors.mobile}</p>
            </div>
            <div className='mb-3'>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  Email ID
                </label>{' '}
                <label className='star-required'>*</label>
              </div>
              <input
                type='text'
                onChange={handleChange}
                value={data.email}
                name='email'
                className='form-control  onboarding-input '
                id='exampleFormControlInput1'
                placeholder='Email'
              />
              <p className='requiredMessage'>{dataErrors.email}</p>
            </div>
            <div className='mb-3'>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  DOT Number
                </label>{' '}
                <label className='star-required'>*</label>
              </div>
              <input
                type='text'
                className='form-control  onboarding-input'
                id='exampleFormControlInput1'
                placeholder='DOT Number'
                onChange={handleChange}
                value={data.dot}
                name='dot'
              />
              <p className='requiredMessage'>{dataErrors.dot}</p>
            </div>

            <div style={{ display: 'flex' }}>
              <label htmlFor='exampleFormControlInput1  ' className='form-label subtitle-s2'>
                Company Phone
              </label>
              <label className='star-required'>*</label>
            </div>
            <div className='mb-3'>
              <PhoneInput
                style={{ border: 'none!important' }}
                className='form-control input-left-sign-area phoneInput'
                countries={whitelist}
                defaultCountry='US'
                onChange={setPhone}
                value={phone}
                placeholder='(555) 555-1234'
              />
              <p className='requiredMessage'>{dataErrors.phone_number}</p>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '6px' }}>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  Address
                </label>{' '}
                <label className='star-required'>*</label>
              </div>
              <Typography
                variant='b2'
                style={{ color: use(palette.indigo500, palette.gray200) }}
                className='shipping-address-question'
                onClick={() => setShippingAddressOpen(true)}
              >
                Shipping Address different then main?
              </Typography>
            </div>

            <SAddressWrapper>
              <AddressAutocomplete
                name='address1'
                onSuccess={onAddressSelect}
                changeAddress={changeAddress}
                onChange={handleChange}
                value={data.address1}
              />
              <p className='requiredMessage'>{dataErrors.address1}</p>
            </SAddressWrapper>

            <div className='mb-3'>
              <input
                type='text'
                className='form-control  onboarding-input'
                id='exampleFormControlInput1'
                placeholder='Address 2'
                name='address2'
                value={data.address2}
                onChange={handleChange}
              />
              <p className='requiredMessage'>{dataErrors.address2}</p>
            </div>

            <div className='mb-3'>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  Country
                </label>{' '}
                <label className='star-required'>*</label>
              </div>
              <Select
                styles={customStyles}
                placeholder={selectedCountry?.label}
                value={selectedCountry}
                onChange={setSelectedCountry}
                options={countryOptions}
                className='selectBoxClass'
              />
              <p style={{ marginTop: '10px' }} className='requiredMessage'>
                {dataErrors.country}
              </p>
            </div>

            <div className='mb-3'>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  State
                </label>{' '}
                <label className='star-required'>*</label>
              </div>
              <Select
                styles={customStyles}
                placeholder={selectedState?.label}
                value={selectedState}
                onChange={setSelectedState}
                options={stateOptions}
                className='selectBoxClass'
              />
              <p style={{ marginTop: '10px' }} className='requiredMessage'>
                {dataErrors.state}
              </p>
            </div>
            <div className='mb-3'>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  City
                </label>
                <label className='star-required'>*</label>
              </div>
              <div className='selectBox-container'>
                <Select
                  styles={customStyles}
                  placeholder={selectedCity?.label}
                  value={selectedCity}
                  onChange={setSelectedCity}
                  options={cityOptions}
                  className='selectBoxClass'
                />
              </div>

              <p style={{ marginTop: '10px' }} className='requiredMessage'>
                {dataErrors.city}
              </p>
            </div>

            <div className='mb-3'>
              <div style={{ display: 'flex' }}>
                <label htmlFor='exampleFormControlInput1' className='form-label subtitle-s2'>
                  Zip Code
                </label>
                <label className='star-required'>*</label>
              </div>
              <input
                type='text'
                className='form-control  onboarding-input'
                id='exampleFormControlInput1'
                placeholder='Zip Code'
                value={data.zip}
                name='zip'
                onChange={handleChange}
              />
              <p className='requiredMessage'>{dataErrors.zip}</p>
            </div>
            {errMsg.length > 0 ? (
              <ErrorMessage message={errMsg} close={() => setDisplayError(false)} show={displayError} left />
            ) : (
              ''
            )}
            {!!skipPayment && (
              <>
                <div className='promo-code'>
                  {appliedPromoCode ? (
                    <>
                      <Typography variant='s2' style={{ color: palette.green500 }}>
                        Successfully applied {appliedPromoCode.extended_trial_days || 14} Days Free Trial
                        {appliedPromoCode.type === 'percentage' && appliedPromoCode.value
                          ? ` and ${appliedPromoCode.value}% off license!`
                          : ` license!`}
                      </Typography>
                      <CustomButton
                        type='secondary'
                        title='Cancel'
                        styleButton={{ padding: '2px 8px', margin: 0 }}
                        onClick={onCancelCoupon}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                        Promo Code:
                      </Typography>
                      <Input
                        width='160px'
                        placeholder='Enter Code'
                        value={promoCode}
                        onChange={(e) => {
                          setIsInvalidPromoCode(false);
                          setPromoCode(e.target.value);
                        }}
                        error={isInvalidPromoCode && 'Please enter a valid promocode!'}
                      />
                      <CustomButton
                        type='secondary'
                        title='Apply'
                        styleButton={{ padding: '2px 8px', margin: 0 }}
                        onClick={handleApplyCoupon}
                      />
                    </>
                  )}
                </div>
                <div className='mt-4 mb-4'>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    By clicking continue, you must also check and agree to the following below:
                  </Typography>
                  <div className='checkbox-terms'>
                    <input
                      type='checkbox'
                      name='terms_and_conditions'
                      checked={data.terms_and_conditions}
                      onChange={handleCheckboxChange}
                    />
                    <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                      I agree to the{' '}
                      <a
                        className='AccountSummaryTermsConditions'
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.truckindigital.com/legal/terms-and-conditions'
                      >
                        Terms & Conditions
                      </a>
                    </Typography>
                  </div>
                  <div className='checkbox-terms'>
                    <input type='checkbox' name='privacy_policy' onChange={handleCheckboxChange} />
                    <Typography
                      variant='s2'
                      style={{ color: palette.gray700 }}
                      checked={data.privacy_policy}
                      className='ms-2'
                    >
                      I agree to the{' '}
                      <a
                        className='AccountSummaryTermsConditions'
                        href='https://www.truckindigital.com/legal/privacy-policy'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Privacy Policy.
                      </a>
                    </Typography>
                  </div>
                  <div className='checkbox-terms'>
                    <input
                      type='checkbox'
                      name='billing_policy'
                      checked={data.billing_policy}
                      onChange={handleCheckboxChange}
                    />
                    <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                      I agree to the{' '}
                      <a
                        className='AccountSummaryTermsConditions'
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.truckindigital.com/legal/billing-policy'
                      >
                        Billing Policy.
                      </a>
                    </Typography>
                  </div>
                  <p className='requiredMessage'>
                    {(!!dataErrors?.terms_and_conditions ||
                      !!dataErrors?.privacy_policy ||
                      !!dataErrors?.privacy_policy) &&
                      'Required'}
                  </p>
                </div>
              </>
            )}
            <div className='d-flex flex-column gap-3'>
              <CustomButton
                type='primary'
                title='Continue'
                styleButton={{ padding: '6px 16px', margin: 0, justifyContent: 'center' }}
                onClick={handleSubmission}
              />
              <CustomButton
                type='secondary'
                title='Go Back'
                styleButton={{ padding: '6px 16px', margin: 0, justifyContent: 'center' }}
                onClick={handleGoBack}
              />
            </div>
          </form>
        </div>
        {!!skipSelectPlan && <InfoSection />}
      </div>
      {!!shippingAddressOpen && (
        <ShippingAddressModal
          open={shippingAddressOpen}
          onClose={() => setShippingAddressOpen(false)}
          onSuccess={handleShipmentAddressSubmit}
        />
      )}
      {loading ? <SplashScreen steps={steps} /> : ''}
    </SCompany>
  );
};

export default Company;

const MobileScreenAlert = ({ setShowMobileAlert }) => {
  const navigate = useNavigate();

  const handleOnclick = () => {
    navigate('/login', { replace: true });
    setShowMobileAlert(false);
  };

  return (
    <div className='mobile-screen-msg'>
      <div>
        <p className='body-b2  mobile-screen-msg-text'>
          Please use a web browser to access Truckin Digital. Mobile devices are not supported at this time. Please
          check your email inbox for login instructions.
        </p>
        <button className='ok-btn' onClick={handleOnclick}>
          Done
        </button>
      </div>
    </div>
  );
};
