import React, { useState } from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import ViewAttachment from 'components/ViewAttachment';
import { palette } from 'utils/constants';
import {
  SAddMore,
  SFlexWrapper,
  SAttachmentWrapper,
} from 'componentsV2/Staff/AddStaffIncident/AddStaffIncident.styles';

const Photos = ({ values, handleChange, type }) => {
  const [imageLink, setImageLink] = useState('');

  const uploadIncidentPhoto = (file) => {
    handleChange(`${type}_collision_photos`, [...values[`${type}_collision_photos`], file]);
  };

  const onAttachmentDelete = (e, index) => {
    e.stopPropagation();
    const newImages = values[`${type}_collision_photos`].filter((item, i) => i !== index);
    handleChange(`${type}_collision_photos`, newImages);
  };

  const onAttachmentClick = (file) => {
    if (typeof file.photo_path === 'string') {
      setImageLink(file.photo_path);
    }
  };

  return (
    <SAttachmentWrapper>
      <InputLabel> Photos</InputLabel>
      <div>
        <SFlexWrapper>
          {values?.[`${type}_collision_photos`]?.map((image, index) => (
            <div key={image?.id || index}>
              <p
                className='attachment-wrapper'
                onClick={() => onAttachmentClick(image)}
                style={{ backgroundColor: palette.indigo0 }}
              >
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: palette.green400 }}>
                  Image {index + 1}
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={(e) => onAttachmentDelete(e, index)}
                />
              </p>
            </div>
          ))}
          {!!values?.[`${type}_collision_photos`]?.length && (
            <SAddMore>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
                Add Another...
              </Typography>
              <ImageDropZone
                multiple
                onDrop={(files) => uploadIncidentPhoto(files)}
                width='100%'
                height='100%'
                name='small'
                deletable={false}
                customStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                }}
                accept={['image/jpeg', 'image/png']}
              />
            </SAddMore>
          )}
        </SFlexWrapper>
        {!values?.[`${type}_collision_photos`]?.length && (
          <div className='upload-document-deposit'>
            <div className='text-style upload-label'>
              <p className='upload-document-icon'>
                <img src={uploadLicense} alt='' />
              </p>
              <p className='upload-document-text'>
                <span style={{ color: palette.indigo500 }}> Upload Image </span>
                <span style={{ color: palette.gray700 }}> or drag and drop</span>
              </p>
            </div>
            <ImageDropZone
              multiple
              onDrop={(files) => uploadIncidentPhoto(files)}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              accept={['image/jpeg', 'image/png']}
            />
          </div>
        )}
      </div>
      {!!imageLink && (
        <ViewAttachment title='Image' open={!!imageLink} onClose={() => setImageLink('')} attachment={imageLink} />
      )}
    </SAttachmentWrapper>
  );
};

export default Photos;
