import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  withdraw_source: object()
    .shape({ stripe_bank_account_id: string().trim().required('Required') })
    .required('Required')
    .nullable(),
  withdraw_type: string().required('Required').nullable(),
  amount: number().required('Required').nullable(),
});
