import React, { useEffect, useRef, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { PusherJs } from 'common/pusher';
import List from '@mui/material/List';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { headerDocuments } from 'Api/Document';
import { ReactComponent as DocIcon } from 'assets/icons/header/doc-20px.svg';
import { Typography } from '../Typography';
import { palette } from '../../utils/constants';

const NewDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [toggleNotify, setToggleNotify] = useState(false);
  const navigate = useNavigate();
  const [documentCount, setDocumentCount] = useState(0);
  const documentDropdownRef = useRef(null);

  useEffect(() => {
    const dataRaw = localStorage.getItem('user');
    const data = JSON.parse(dataRaw);
    const channel = PusherJs.subscribe(`private-document-creation.${data?.customer?.dot}`);

    channel.bind('document-creation', (data) => {
      setDocumentCount(data.message.data.new_document_count);
      headerDocuments()
        .then((res) => {
          if (res) {
            setDocuments(res?.data);
          }
        })
        .catch(() => {
          // Do nothing
        });
    });
  }, []);

  useEffect(() => {
    headerDocuments()
      .then((res) => {
        if (res) {
          setDocuments(res?.data);
          setDocumentCount(res?.data?.length);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  useEffect(() => {
    window.addEventListener('click', onClickOutDocumentDropdown);
    return () => {
      window.removeEventListener('click', onClickOutDocumentDropdown);
    };
  }, [documentDropdownRef]);

  const onClickOutDocumentDropdown = (e) => {
    if (documentDropdownRef && !documentDropdownRef?.current?.contains(e.target)) {
      setToggleNotify(false);
    }
  };

  const goToDocument = (item, index) => {
    navigate('/documents', { state: { newDocumentId: item.id } });
    documents.splice(index, 1);
    const newCount = documentCount - 1;
    setDocumentCount(newCount);
  };

  return (
    <div
      ref={documentDropdownRef}
      onClick={() => (documentCount ? setToggleNotify(!toggleNotify) : navigate('/documents'))}
    >
      <div style={{ position: 'relative' }}>
        <div className='badge' style={{ display: documentCount !== 0 ? 'flex' : 'none' }}>
          {documentCount !== 0 ? documentCount : ''}
        </div>
      </div>
      <DocIcon />
      <div className='document-dropdown' style={{ display: toggleNotify ? 'flex' : 'none' }}>
        <div className='mb-2'>
          <span className='document-dropdown-header'>New Documents</span>
        </div>
        <List sx={{ width: '100%' }}>
          {documents.map((item, index) => (
            <div className='document-dropdown-header-item' key={item.id} onClick={() => goToDocument(item, index)}>
              <ListItem alignItems='flex-start' style={{ paddingLeft: 0 }}>
                <ListItemAvatar>
                  <Avatar alt={item?.sender_data?.image} src={item?.sender_data?.image} />
                </ListItemAvatar>
                <ListItemText
                  sx={{ fontSize: '0.9rem' }}
                  primary={item?.sender_data?.name}
                  secondary={`${item?.document_type?.name}  (${item?.reference_id})`}
                />
                <ListItemText
                  sx={{ fontSize: '5px', textAlign: 'end' }}
                  secondary={moment(item?.created_at)?.fromNow()}
                />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
        <div className='document-dropdown-footer'>
          <Typography variant='b3' style={{ color: palette.indigo500 }} onClick={() => navigate('/documents')}>
            View All
          </Typography>
        </div>
      </div>
    </div>
  );
};
export default NewDocuments;
