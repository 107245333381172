import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createBid, getDeclineReasons } from 'Api/OfferedShipments';
import { validationSchema } from 'pages/Shipment/ShipmentBid/components/DeclineShipment/validationSchema';

const DeclineShipment = ({ open, onClose, dot, token, type, shipment, formValues, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [reasonOptions, setReasonOptions] = useState([]);
  const { offer_amount, carrier_id, shipment_id } = shipment || {};

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        shipment_id,
        carrier_id,
        selected_contact_user_id: formValues.contact.id,
        offer_amount: Number(offer_amount),
        state: formValues.state?.short_name || null,
        city: formValues.city?.name || null,
        offer_accepted: 2,
        note: formValues.note || null,
        decline_reason_id: values.decline_reason.id,
      };

      await createBid(body, { dot, token, type: type === 'contact' ? 'contact' : undefined });
      showToaster({ type: 'success', message: 'Shipment is successfully declined!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { decline_reason: null },
    onSubmit,
    validationSchema,
  });

  const getDeclineReasonOptions = async () => {
    try {
      const { data } = await getDeclineReasons({}, { dot, token, type });
      setReasonOptions(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDeclineReasonOptions();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Decline Shipment'
      $maxWidth='450px'
      $minWidth='450px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Decline Shipment',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <Autocomplete
          required
          label='Decline Reason'
          name='decline_reason'
          placeholder='Select Reason..'
          options={reasonOptions}
          value={values.decline_reason}
          onChange={(e, val) => handleChange('decline_reason', val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          error={touchedErrors.decline_reason}
        />
      </div>
    </Modal>
  );
};

export default DeclineShipment;
