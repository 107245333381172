import styled from 'styled-components';
import { palette } from '../../../../../../utils/constants';

export const SUploaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${palette.white};
  border: 1px dashed ${palette.gray200};

  .upload-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;
    margin: 0;
  }

  .upload-text {
    color: ${palette.indigo500};
  }
`;
