import moment from 'moment';

export const markPaidConverter = (values) => {
  const body = {
    invoice_id: [],
    customer_payment_date: [],
    amount_paid: [],
    payment_method: [],
    discount: [],
    pay_reference: [],
    bank_account_id: [],
    credit_used: [],
    over_payment: [],
  };

  const overpaid = [];

  values.forEach((invoice) => {
    const {
      open_balance,
      discount,
      invoice_id,
      payment_date,
      amount_paid,
      payment_method,
      pay_reference,
      account,
      credit_used,
      apply_credit,
    } = invoice;

    const over_payment = amount_paid - (open_balance - (discount || 0) - (apply_credit ? credit_used : 0));

    if (over_payment > 0 && apply_credit) {
      overpaid.push({ ...invoice, over_payment });
    }

    body.invoice_id.push(invoice_id);
    body.customer_payment_date.push(moment(payment_date).format('YYYY-MM-DD'));
    body.amount_paid.push(amount_paid);
    body.payment_method.push(payment_method?.value);
    body.discount.push(discount || 0);
    body.pay_reference.push(pay_reference);
    body.bank_account_id.push(account?.id || null);
    body.credit_used.push(Number(credit_used) || 0);
    body.over_payment.push(over_payment > 0 ? over_payment : 0);
  });

  return { body, overpaid };
};
