import { number, string, object, ref } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  reason_title: string().trim().required('Reason title is required!'),
  driver_staff_id: object().required('Driver or staff user is required!').nullable(),
  absence_start_date: validator
    .requiredDate('Start date is required!')
    .max(ref('absence_end_date'), "Start date can't be after end date!")
    .nullable(),
  absence_end_date: validator
    .requiredDate('End date is required!')
    .min(ref('absence_start_date'), "End date can't be before start date!")
    .nullable(),
  absence_details: string().trim().required('Attendance details field is required!'),
  internal_notes: string().trim().required('Internal notes field is required!'),
  type: number().required('Attendance type is required!'),
});
