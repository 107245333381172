import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { OwnerOperator } from 'Api/Driver';
import AddOwnerOperator from 'components/Drivers/AddOwnerOperator/AddOwnerOperator';

const OwnerAutocomplete = ({
  name = 'owner_operator',
  value,
  onChange,
  error,
  onGetOperators,
  hideLabel,
  ...props
}) => {
  const [ownerOperators, setOwnerOperators] = useState([]);
  const [openAddOperator, setOpenAddOperator] = useState(false);
  const [loading, setLoading] = useState(false);

  const getOwnerOperators = async () => {
    try {
      setLoading(true);
      const { data } = await OwnerOperator();
      setOwnerOperators(data);
      if (onGetOperators) {
        onGetOperators(data);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onOperatorCreateSuccess = (newOperator) => {
    if (newOperator) {
      setOwnerOperators((prevState) => [...prevState, newOperator]);
      onChange(name, newOperator);
    }
  };

  useEffect(() => {
    getOwnerOperators();
  }, []);

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        {!hideLabel ? (
          <InputLabel required className='mb-0'>
            Owner Operator
          </InputLabel>
        ) : (
          <span />
        )}
        <Typography
          variant='s2'
          style={{ color: palette.indigo500, cursor: 'pointer' }}
          className='d-flex align-items-center gap-1'
          onClick={() => setOpenAddOperator(true)}
        >
          <PlusIcon /> New
        </Typography>
      </div>
      <Autocomplete
        name={name}
        placeholder='Owner Operator..'
        labelKey='owner_operator_name'
        options={ownerOperators}
        value={value}
        onChange={onChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        error={error}
        loading={loading}
        disableClearable={false}
        {...props}
      />
      <Modal show={openAddOperator} onHide={() => setOpenAddOperator(false)} centered id='add-driver-modal'>
        <AddOwnerOperator
          open={openAddOperator}
          close={() => setOpenAddOperator(false)}
          save={onOperatorCreateSuccess}
        />
      </Modal>
    </div>
  );
};

export default OwnerAutocomplete;
