export const CURRENCIES = [
  { label: 'USD', id: 1, value: 'usd' },
  { label: 'CAD', id: 2, value: 'cad' },
  { label: 'MXN', id: 3, value: 'mxn' },
];
export const WEIGHTS = [
  { label: 'LBS', id: 1, value: 'lbs' },
  { label: 'KGS', id: 2, value: 'kgs' },
];
export const QTY_TYPES = [
  { label: 'Pieces', id: 1, value: 'pieces' },
  { label: 'Units', id: 2, value: 'units' },
  { label: 'Qty', id: 3, value: 'qty' },
];

export const getInitialValues = (data) => {
  const { default_currency, default_weight, default_qty_type } = data || {};

  return {
    default_currency: default_currency || CURRENCIES[0].value,
    default_weight: default_weight || WEIGHTS[0]?.value,
    default_qty_type: default_qty_type || QTY_TYPES[0]?.value,
  };
};
