import styled from 'styled-components';
import CustomModal from 'components/CustomModal/CustomModal';
import { palette } from 'utils/constants';

export const SCustomModal = styled(CustomModal)`
  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '700px'};
    height: ${({ $height }) => $height || 'auto'};

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: 100%;
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      overflow: auto;
      border-radius: 0;

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      border-bottom: 1px solid ${palette.gray50};
    }

    .customFooter {
      padding: 12px 20px;
      border-top: 1px solid ${palette.gray50};

      button {
        padding: 6px 12px !important;
        margin: 0 !important;

        span {
          font-size: 14px !important;
          font-weight: 500;
          font-family: Inter, sans-serif;
        }
      }

      .modal-cancel-btn {
        margin-right: 16px !important;
      }
    }
  }
`;

export const SRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-bottom: 12px;
`;

export const SRadio = styled.input`
  background-color: #fff;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 1px solid ${palette.gray50};
  border-radius: 50%;
  cursor: pointer;
`;

export const SInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 2px 5px rgba(89, 96, 120, 0.1);
  border-radius: 6px;
  padding: 0 8px;
`;
