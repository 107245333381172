import React, { useEffect, useState } from 'react';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { getInventorySettings, updateInventorySettings } from 'Api/CompanySettings';
import { getInitialValues, updateInventoryConverter } from './Inventory.data';
import { SRow, SWrapper } from '../StopPoints.styles';

const Inventory = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    try {
      const body = updateInventoryConverter(values);
      await updateInventorySettings(body);

      showToaster({ type: 'success', message: 'Successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
  });

  const getSettings = async () => {
    try {
      const { data } = await getInventorySettings();
      setSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Inventory & History'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
        hideSave={loading}
      />
      <Divider />
      <div>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div>
            <SRow>
              <CustomCheckbox
                type='switch'
                name='real_time_locations'
                checked={!!values.real_time_locations}
                onChange={(checked) => handleChange('real_time_locations', checked)}
                smail={false}
              />
              <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                Show Real-Time Vehicle(s) Locations on Stop Point Inventory and History
              </Typography>
            </SRow>
            <SRow>
              <CustomCheckbox
                type='switch'
                name='checked_in_locations'
                checked={!!values.checked_in_locations}
                onChange={(checked) => handleChange('checked_in_locations', checked)}
                smail={false}
              />
              <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                Show Checked-In Vehicle(s) on Stop Point Inventory and History
              </Typography>
            </SRow>
          </div>
        )}
      </div>
    </SWrapper>
  );
};

export default Inventory;
