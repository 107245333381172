import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import { palette } from 'utils/constants';
import { SContentRow } from './TimeApp.styles';

const Row = ({ data, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (e, type) => {
    e.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'edit':
        onEdit();
        break;
      case 'delete':
        onDelete();
        break;
      default:
    }
  };

  return (
    <SContentRow>
      <div className='settings-table-item'>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {data.location_name || ''}
        </Typography>
      </div>
      <div className='settings-table-item'>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {data.pin || ''}
        </Typography>
      </div>
      <div className='settings-table-item'>
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {data.time?.zone_name || '-'}
        </Typography>
      </div>
      <div className='settings-table-item'>
        <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl} className='settings-table-actions-wrapper'>
          <li onClick={(e) => onActionClick(e, 'edit')}>Edit</li>
          <li className='delete-action' onClick={(e) => onActionClick(e, 'delete')}>
            Delete
          </li>
        </ThreeDotActions>
      </div>
    </SContentRow>
  );
};

export default Row;
