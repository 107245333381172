import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import classNames from 'classnames';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import ViewAttachment from 'components/ViewAttachment';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { CreateSupportComment, GetSupportComment, uploadSupportAttachment } from 'Api/Support';
import { backgroundColors } from '../Support.data';
import classes from './SupportItemModal.module.css';

const findLinks = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const matches = text.match(urlRegex);

  return matches || [];
};

const renderTextWithLinks = (text) => {
  const links = findLinks(text);

  if (links.length === 0) {
    return <Typography variant='c2'>{text}</Typography>;
  }

  const parts = text.split(/(https?:\/\/[^\s]+)/);

  return (
    <span>
      {parts.map((part) =>
        links.includes(part) ? (
          <a href={part} target='_blank' rel='noopener noreferrer'>
            <Typography variant='c2' style={{ color: palette.indigo500 }}>
              {part}
            </Typography>
          </a>
        ) : (
          <Typography variant='c2'>{part}</Typography>
        )
      )}
    </span>
  );
};

const SupportItemModal = ({ isOpen, setIsOpen, modalData, onAddAttachmentSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [data, setData] = useState(modalData);
  const [value, setValue] = useState('');
  const [comments, setComment] = useState(null);
  const [openAttachment, setOpenAttachment] = useState(null);

  const getSupportComment = (id) => {
    GetSupportComment(id).then((res) => {
      setComment(res?.data);
    });
  };

  const user = useMemo(() => {
    return JSON.parse(localStorage.getItem('user'));
  }, []);

  const onSend = () => {
    if (!value) {
      return;
    }
    setValue('');
    const jsonData = {
      support_id: data?.id.toString(),
      comment: value,
      image: user?.profile_logo,
    };
    CreateSupportComment(jsonData).then((res) => {
      if (res) {
        getSupportComment(data?.id);
      }
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSend();
    }
  };

  const uploadAttachment = async (file) => {
    try {
      if (file[0].size > 20971520) {
        showToaster({ type: 'error', message: 'Please upload file under 20MB in size.' });
        return;
      }

      const formData = new FormData();
      formData.append('support_id', data.id);
      formData.append('image', file[0]);
      const { paths } = await uploadSupportAttachment(formData);
      showToaster({ type: 'success', message: 'Attachment has been successfully uploaded!' });
      setData((prevState) => ({
        ...prevState,
        support_image: [...(prevState?.support_image || []), { upload: paths }],
      }));
      onAddAttachmentSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const styles = useMemo(() => {
    return {
      add: {
        padding: '2px 8px',
        marginRight: 0,
        marginTop: 0,
      },
      cancel: {
        padding: '2px 8px',
        marginTop: 0,
      },
      modalBody: {
        maxHeight: 620,
        overflowY: 'auto',
        padding: 0,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      },
      modalBody2: {
        maxHeight: 620,
        overflowY: 'auto',
        padding: 0,
        borderRadius: 0,
      },
      modalFooter: {
        backgroundColor: use(palette.white, palette.dark900),
        height: '100%',
      },
      styleFooterWrapper: {
        padding: 0,
        overflow: 'hidden',
      },
    };
  }, [use, palette]);

  const header = useMemo(() => {
    return (
      <div className={classes.headerModalWrapper}>
        <div className={classes.headerLeft}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray200) }}>
              Support Ticket {data?.ticket_id}
            </Typography>
            <div style={{ display: 'flex', marginLeft: '6px' }}>
              <Typography
                variant='b2'
                style={{
                  color: `${data?.status?.color}`,
                  padding: '2px 6px',
                  backgroundColor: `${data?.status?.background_color}`,
                  borderRadius: '4px',
                }}
              >
                {data?.status?.status}
              </Typography>
            </div>
          </div>
          <div className={classes.subtitle}>
            <Typography variant='s2'>Ticket ID {data?.ticket_id} </Typography>
            {data?.time_estimate && <span className={classes.line} />}
            {data?.time_estimate && <Typography variant='s2'>Time Estimated {data?.time_estimate}</Typography>}
          </div>
        </div>
        <div
          onClick={() => {
            data.hasOwnProperty(isOpen) ? setIsOpen({ ...data, isOpen: false }) : setIsOpen(false);
          }}
          className={classNames(classes.headerRight, use(classes.light, classes.dark))}
        >
          <CloseIcon fill={palette.gray500} />
        </div>
      </div>
    );
  }, [data, palette]);

  useEffect(() => {
    getSupportComment(data?.id);
  }, []);

  return (
    <ModalWrapper
      width={1059}
      isOpen={isOpen}
      styleBody={styles.modalBody}
      onHide={() => setIsOpen(false)}
      dialogClassName={classNames('dialogClassName', classes.modalDialogClassName)}
      header={header}
      footer={null}
      footerContainer={false}
      styleFooterWrapper={styles.styleFooterWrapper}
    >
      <div className={classes.body}>
        <div className={classes.bodyLeft}>
          <div className={classes.bodyLeftInfo}>
            <div className='d-flex align-items-center gap-5'>
              {!!data?.type?.name && (
                <div style={{ marginBottom: 32, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='button2'>Type</Typography>
                  <Chip
                    label={data.type.name}
                    labelColor={backgroundColors?.[data.type.name]?.color || palette.gray500}
                    bgColor={backgroundColors?.[data.type.name]?.bgColor || palette.gray0}
                    fontSize='14px'
                  />
                </div>
              )}
              {!!data?.priority?.name && (
                <div style={{ marginBottom: 32, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='button2'>Priority</Typography>
                  <Chip
                    label={data.priority.name}
                    labelColor={backgroundColors?.[data.priority.name]?.color || palette.gray500}
                    bgColor={backgroundColors?.[data.priority.name]?.bgColor || palette.gray0}
                    fontSize='14px'
                  />
                </div>
              )}
            </div>
            <div style={{ marginBottom: 32, display: 'flex', flexDirection: 'column' }}>
              <Typography variant='button2'>Description</Typography>
              <Typography variant='overLine2'>{data?.description}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='d-flex align-items-center gap-5'>
                <Typography variant='button2'>Attachments</Typography>
                <div className={classes.add_attachment}>
                  <ImageDropZone
                    onDrop={(file) => uploadAttachment(file)}
                    width='100%'
                    height='100%'
                    name='small'
                    deletable={false}
                    customStyle={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    accept={['image/jpg', 'image/jpeg', 'image/png']}
                  />
                  <PlusIcon fill={palette.indigo500} />
                </div>
              </div>
              <div className={classes.attachmentFiles}>
                {data?.support_image?.map((item) => {
                  return (
                    <div className={classes.fileWrapper}>
                      <div className={classes.file}>
                        <figure>
                          <img
                            id='img'
                            src={item?.upload}
                            alt='upload'
                            onClick={() => setOpenAttachment(item?.upload)}
                          />
                        </figure>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.leftFooter}>
            <Typography variant='s2'>
              Created On {formatDate(data?.created_at)} by {data?.created_by}
            </Typography>
          </div>
        </div>
        <div className={classes.bodyRight}>
          <div className={classes.messagesWrapper}>
            <Typography variant='button2'>Comments</Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              {comments?.map((item, idx) => {
                return (
                  <div key={item?.id || idx} className={classes.card}>
                    <div className={classes.cardImg}>
                      <figure className={classes.cardImgWrapper}>
                        <img
                          src={item?.added_by_user_type === 3 ? truckinLogo : item?.added_by_user_details?.profile_logo}
                          alt='avatar'
                        />
                      </figure>
                    </div>
                    <div className={classes.info}>
                      <div className={classes.infoHeader}>
                        <Typography variant='s2' style={{ marginBottom: 4 }}>
                          {item?.added_by_user_type === 3
                            ? 'Truckin Digital Support'
                            : `${item?.added_by_user_details?.first_name} ${item?.added_by_user_details?.last_name}`}
                        </Typography>
                        <Typography variant='overLine'>{moment(item?.created_at).fromNow()}</Typography>
                      </div>
                      <div className={classes.infoMessage}>{renderTextWithLinks(item?.comment)}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.footerRight}>
            <input
              className={classes.input}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder='Enter comment...'
              onKeyDown={handleKeyPress}
            />
            <div onClick={onSend} className={classes.send} style={{ cursor: !value ? 'not-allowed' : 'pointer' }}>
              <SendIcon fill={value ? palette.indigo500 : palette.indigo200} />
            </div>
          </div>
        </div>
      </div>
      {!!openAttachment && (
        <ViewAttachment open={openAttachment} onClose={() => setOpenAttachment(null)} attachment={openAttachment} />
      )}
    </ModalWrapper>
  );
};
export default SupportItemModal;
