import React from 'react';
import Chip from 'common/Chip';
import { palette } from 'utils/constants';

export const planMapper = {
  monthly: {
    title: 'Month to Month',
    commitmentLabel: <Chip label='NO COMMITMENT' />,
    monthsCount: 1,
    billTitle: 'Billed monthly',
    billingHeaderTitle: 'Monthly Recurring',
    addonsRecurring: 'Per Month Recurring',
    addonBillTitle: 'billed monthly',
    recurring: 'Recurring Monthly',
    per: 'month',
  },
  yearly: {
    title: '12 Month Agreement',
    commitmentLabel: <Chip bgColor={palette.green0} labelColor={palette.green500} label='SAVE 10% PER MONTH' />,
    monthsCount: 12,
    billTitle: 'Billed every 1 year',
    billingHeaderTitle: 'Yearly Recurring',
    addonsRecurring: 'Per Year Recurring',
    addonBillTitle: 'per month billed for 1 year',
    recurring: 'Recurring Yearly',
    per: 'year',
  },
  '3-Year': {
    title: '36 Month Agreement',
    commitmentLabel: <Chip bgColor={palette.green0} labelColor={palette.green500} label='SAVE 25% PER MONTH' />,
    monthsCount: 36,
    billTitle: 'Billed every 3 years',
    billingHeaderTitle: '3-Year Recurring',
    addonsRecurring: 'Per 3-Years Recurring',
    addonBillTitle: 'per month billed for 3 years',
    recurring: 'Recurring Every 3 Years',
    per: 'every 3 years',
  },
};
