import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  background-color: ${({ $bgColor }) => $bgColor || palette.white};
`;

export const SHeader = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
`;

export const SCompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${palette.white};
  align-items: center;
  padding: 20px 40px 16px;
  min-height: 116px;

  .logo-wrapper {
    min-height: 48px;

    img {
      object-fit: contain;
    }
  }

  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    padding: 20px 8px 16px;
    .logo-wrapper {
      width: 50%;

      img {
        width: 100%;
      }
    }

    & > * {
      flex: unset;
    }
  }
`;

export const SFooter = styled.div`
  background-color: ${palette.white};
  border-top: 1px solid ${palette.gray50};
  min-height: 50px;
  padding: 16px 40px 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ step }) => (step === 0 ? 'row-reverse' : 'row')};
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .next-actions {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ScrollableContainer = styled.div`
  margin-top: 144px;
  overflow: auto;
  height: calc(100vh - 180px);
`;

export const SStepperContent = styled.div`
  width: ${({ width }) => width || '60%'};
  min-width: ${({ $minWidth }) => $minWidth || '672px'};
  margin: 0 auto;
  padding: ${({ $bgColor }) => ($bgColor ? '24px 42px 64px' : '24px 0 64px')};
  min-height: calc(100vh - 214px);
  background-color: ${({ $bgColor }) => $bgColor || ''};

  & .number-input {
    width: 50px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 16px 64px;
    //margin-bottom: 66px;
    min-height: calc(100vh - 190px);
    min-width: 300px;
  }
`;

export const SAddressFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    span {
      font-size: 12px !important;
    }
  }
`;

export const SProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .MuiLinearProgress-root {
    width: 90%;
    height: 8px;
    border-radius: 8px;
    background-color: ${palette.gray50};

    .MuiLinearProgress-bar {
      background-color: ${({ last }) => (last ? palette.green400 : palette.indigo500)};
    }
  }

  :after {
    content: '';
    position: absolute;
    top: 50%;
    z-index: -1;
    width: 100%;
    border-bottom: 1px solid ${palette.gray50};
  }
`;

export const SSecondaryButton = styled(Button)`
  && {
    width: ${({ width }) => width || '120px'};
    padding: 6px 12px;
    border-radius: 6px;
    background-color: ${palette.white};
    color: ${palette.gray700};
    box-shadow: ${palette.boxShadow2};
    margin: 0 16px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: ${({ $justify }) => $justify || 'space-between'};

    &:hover,
    &:active {
      color: ${palette.gray700};
      background-color: ${palette.white};
      box-shadow: ${palette.boxShadow};
    }

    &:focus {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 0 0 4px rgba(1, 178, 237, 0.36),
        0 2px 5px rgba(89, 96, 120, 0.1);
    }

    svg {
      path {
        fill: ${palette.gray700};
      }
    }
  }
`;

export const SPrimaryButton = styled(Button)`
  && {
    width: 120px;
    padding: 6px 12px;
    border-radius: 6px;
    background-color: ${palette.indigo500};
    color: ${palette.white};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 0 1px #4f5aed, 0 2px 5px rgba(79, 90, 237, 0.1),
      inset 0 1px 0 #767ffb;
    margin: 0 16px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.icon-button {
      justify-content: space-between;
    }

    &:hover {
      background-color: ${palette.indigo600};
    }

    &:focus {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 0 0 4px rgba(1, 178, 237, 0.36),
        0 2px 5px rgba(89, 96, 120, 0.1);
    }

    svg {
      path {
        fill: ${palette.white};
      }
    }
  }
`;

export const STitleWrapper = styled.div`
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${palette.gray50};
  text-align: ${({ $currentStep, $hasInterCharge }) =>
    $currentStep === 5 || ($currentStep === 6 && $hasInterCharge) ? 'center' : 'left'};
`;
