import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import Input, { Textarea } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import AddVendor from 'pages/Accounting/Vendors/components/AddVendor';
import { createBill, deleteBill, revertBillToUnpaid, updateBill, updateRecurringBill } from 'Api/Payables';
import { getVendorsList } from 'Api/Vendors';

import Recurring from './components/Recurring';
import ItemsForm from './components/ItemsForm';
import Attachment from './components/Attachment';
import GeneralInfo from './components/GeneralInfo';
import AppliedPayments from './components/AppliedPayments';
import { validationSchema } from './validationSchema';
import { getInitialValues, getRequestBody } from './AddBill.data';
import { SBodyWrapper } from './AddBill.styles';

const AddBill = ({ open, onClose, onSuccess, bill, isRecurring, vendor, onMarkPaid, readOnly }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [openAddVendor, setOpenAddVendor] = useState(false);
  const isPaid = !!bill && bill.status === '1' && bill.amount_due === bill.amount_paid;

  const onSubmit = async (values) => {
    if (
      values.is_recurring &&
      values.due_day_category?.value === 'current_month' &&
      values.next_renew_date?.getDate() > Number(values.due_day)
    ) {
      showToaster({ type: 'error', message: 'You cannot set the Due Date to precede the Bill Date.' });
      return;
    }
    setLoading(true);

    try {
      const body = getRequestBody(values);

      if (bill) {
        if (isRecurring) {
          await updateRecurringBill(bill.id, body);
        } else {
          await updateBill(bill.id, body);
        }
      } else {
        await createBill(body);
      }

      showToaster({ type: 'success', message: `Bill has been successfully ${bill ? 'updated' : 'added'}!` });
      onSuccess(values.is_recurring, values.vendor.id);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors, dirty } = useForm({
    initialValues: getInitialValues(bill, vendor, isRecurring),
    onSubmit,
    validationSchema,
  });

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onRevertToUnpaid = async () => {
    try {
      await revertBillToUnpaid(bill.id);
      showToaster({ type: 'success', message: 'Bill has been successfully reverted!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onDelete = async () => {
    setLoadingRevert(true);
    try {
      await deleteBill(bill.id);
      showToaster({ type: 'success', message: 'Bill has been successfully deleted!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingRevert(false);
    }
  };

  const onVendorChange = (vendor) => {
    handleChange('vendor', vendor);

    if (vendor?.payment_term) {
      handleChange('payment_term', vendor.payment_term);
    }
  };

  const onAddVendorSuccess = (vendor) => {
    getVendors();
    onVendorChange(vendor);
  };

  const buttons = readOnly
    ? []
    : isPaid
    ? [
        {
          key: 'delete',
          type: 'danger',
          title: 'Revert and Delete',
          disabled: loadingRevert,
          onClick: onDelete,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Revert to Open',
          disabled: loadingRevert,
          onClick: onRevertToUnpaid,
        },
      ]
    : [
        ...(bill && !isRecurring && !!onMarkPaid
          ? [
              {
                key: 'pay',
                type: 'primary',
                title: 'Make Payment',
                disabled: true,
                onClick: () => null,
                tooltip: 'Feature not enabled',
              },
              {
                key: 'mark-paid',
                type: 'primary',
                title: 'Mark Paid',
                disabled: loading,
                onClick: onMarkPaid,
              },
            ]
          : []),
        {
          key: 'submit',
          type: 'primary',
          title: bill ? 'Update Bill' : 'Add Bill',
          disabled: loading || !dirty,
          onClick: handleSubmit,
        },
      ];

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={isPaid || readOnly ? `Bill ${bill.reference_id}` : bill ? 'Update Bill' : 'Add Bill'}
      $bgColor={palette.gray0}
      $width='1400px'
      $maxWidth='90vw'
      $minWidth='800px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', margin: 10, fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: readOnly ? 'Done' : 'Cancel',
          onClick: onClose,
        },
        ...buttons,
      ]}
    >
      <SBodyWrapper>
        <div className='first-line'>
          <div>
            <div className='d-flex justify-content-between'>
              <InputLabel required>Vendor</InputLabel>
              {!vendor && (
                <Typography
                  variant='s2'
                  style={{ color: palette.indigo500 }}
                  className='add-action'
                  onClick={() => setOpenAddVendor(true)}
                >
                  <PlusIcon fill={palette.indigo500} />
                  Add Vendor
                </Typography>
              )}
            </div>
            <Autocomplete
              name='vendor'
              options={vendors}
              value={values.vendor}
              onChange={(e, val) => onVendorChange(val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.vendor}
              disabled={!!vendor || isPaid || readOnly}
            />
          </div>
          <Input
            required
            name='reference_id'
            label='Bill Reference ID'
            onChange={handleChange}
            value={values.reference_id}
            error={touchedErrors.reference_id}
            disabled={isPaid || readOnly}
          />
        </div>
        <ItemsForm
          values={values}
          handleChange={handleChange}
          setValues={setValues}
          touchedErrors={touchedErrors}
          readOnly={isPaid || readOnly}
        />
        <GeneralInfo
          bill={bill}
          values={values}
          handleChange={handleChange}
          touchedErrors={touchedErrors}
          readOnly={isPaid || readOnly}
        />
        <Recurring
          values={values}
          handleChange={handleChange}
          setValues={setValues}
          touchedErrors={touchedErrors}
          readOnly={isPaid || readOnly}
          isRecurring={isRecurring}
        />
        <Attachment values={values} handleChange={handleChange} readOnly={isPaid || readOnly} />
        <Textarea
          rows={3}
          label='Memo'
          name='memo'
          placeholder='Type...'
          value={values.memo}
          onChange={handleChange}
          error={touchedErrors.memo}
          disabled={isPaid || readOnly}
        />
        {!!bill?.payments?.length && <AppliedPayments bill={bill} />}
      </SBodyWrapper>
      {openAddVendor && (
        <AddVendor open={openAddVendor} onClose={() => setOpenAddVendor(false)} onSuccess={onAddVendorSuccess} />
      )}
    </Modal>
  );
};

export default AddBill;
