import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import CustomerStatementModal from 'components/CustomerProfile/CustomerStatementModal';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import MarkPaid from 'pages/Accounting/Receivables/TableSection/shared/MarkPaid';
import AddNotes from 'pages/Accounting/Receivables/TableSection/shared/AddNotes';
import RevertInvoice from 'pages/Accounting/Receivables/TableSection/shared/RevertInvoice';
import UploadDocument from 'pages/Accounting/Receivables/TableSection/shared/UploadDocument';
import StandaloneInvoiceNotes from 'pages/Accounting/Receivables/TableSection/shared/StandaloneInvoiceNotes';
import { getCustomerTransactions } from 'Api/Reports';
import { refundAndRevertPaidInvoices } from 'Api/AccountingReceivables';
import Tab from '@mui/material/Tab';
import TableFilters from './TableFilters';
import { getTransactionDetails, useColumns } from './CustomerTransactionsTab.data';
import { STabs } from '../../pages/Accounting/Receivables/Receivables.styles';

const CustomerTransactionsTab = ({ customer }) => {
  const showToaster = useShowToaster();
  const [activeTab, setActiveTab] = useState('shipment');
  const [openInvoices, setOpenInvoices] = useState({ data: [] });
  const [sort, setSort] = useState({ field: 'customer_billed_date', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25 });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [driverSearch, setDriverSearch] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [openViewPdf, setOpenViewPdf] = useState(false);
  const [openStatement, setOpenStatement] = useState(false);
  const [openMarkPaid, setOpenMarkPaid] = useState(false);
  const [openRevertInvoice, setOpenRevertInvoice] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState('shipment');
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalData, setModalData] = useState({});
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [openStandaloneNotes, setOpenStandaloneNotes] = useState(false);
  const [invoiceToRevert, setInvoiceToRevert] = useState(null);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const debouncedSearchDriver = useDebounce(driverSearch, 500);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const getTransactions = async () => {
    setLoading(true);
    try {
      const { page, itemsPerPage } = selectedFilters || {};
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page,
        itemsPerPage,
        type: activeTab,
        'filters[all]': debouncedSearch || undefined,
        'filters[driver]': debouncedSearchDriver || undefined,
        start_date: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.start ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        filter: 'activity',
        [sortField]: sort.sortBy,
      };
      const response = await getCustomerTransactions(customer.id, params);
      setOpenInvoices(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const refundAndRevertInvoice = async () => {
    try {
      setLoadingRevert(true);
      const body = {
        invoice_id: [invoiceToRevert.invoice_id],
      };
      await refundAndRevertPaidInvoices(body);
      showToaster({
        type: 'success',
        message: `Invoice has been successfully ${invoiceToRevert.is_stripe ? 'refunded and reverted' : 'reverted'}!`,
      });
      getTransactions();
      setInvoiceToRevert(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingRevert(false);
    }
  };

  const onViewPdf = (row) => {
    setPdfUrl(row.pdf_file_link);
    setOpenViewPdf(true);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onMarkPaid = (row) => {
    setSelectedRows([row]);
    setOpenMarkPaid(true);
  };

  const onRevertInvoice = (row) => {
    setSelectedRows([row]);
    setOpenRevertInvoice(true);
  };

  const onUploadDocuments = (row) => {
    setModalData(row);
    setOpenUploadDocument(true);
  };

  const onViewShipmentNotes = (row) => {
    setModalData(row);
    setOpenAddNotes(true);
  };

  const onViewStandaloneNotes = (row) => {
    setModalData(row);
    setOpenStandaloneNotes(true);
  };

  const onRefundAndRevert = (row) => {
    setInvoiceToRevert(row);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    getTransactions();
  }, [dateRange, selectedFilters, debouncedSearch, debouncedSearchDriver, sort, activeTab]);

  const columns = useColumns({
    sort,
    sortingQuery,
    onViewPdf,
    onMarkPaid,
    onRevertInvoice,
    onUploadDocuments,
    onRefundAndRevert,
    onViewShipmentNotes,
    onViewStandaloneNotes,
    setSelectedTransactionType,
  });

  return (
    <div>
      <div>
        <Typography variant='h2'>Transactions</Typography>
      </div>
      <div className='d-flex align-items-center justify-content-between mt-3 mb-3'>
        <TableFilters
          dateRange={dateRange}
          setDateRange={setDateRange}
          search={search}
          setSearch={setSearch}
          driverSearch={driverSearch}
          setDriverSearch={setDriverSearch}
        />
        <CustomButton
          title='Print Statement'
          type='secondary'
          styleTitle={{ fontSize: 14, fontWeight: 500, whiteSpace: 'nowrap' }}
          leftIcon={<PrintIcon className='me-2' />}
          styleButton={{ padding: '6px 12px', margin: 0 }}
          onClick={() => setOpenStatement(true)}
        />
      </div>
      <STabs value={activeTab} onChange={handleTabChange}>
        <Tab
          id='tab-1'
          value='shipment'
          label='Shipment'
          iconPosition='end'
          // icon={<span>{data?.total}</span>}
          disableRipple
        />
        <Tab
          id='tab-2'
          value='other'
          label='Other'
          iconPosition='end'
          // icon={<span>{otherData?.total}</span>}
          disableRipple
        />
      </STabs>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={openInvoices?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: openInvoices,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [25, 50, 100, 150],
              }),
          }}
          onRowClick={(e, rowData) => {
            const { isPayed, openBalance } = getTransactionDetails(rowData);
            if (!isPayed) {
              setSelectedRows([{ ...rowData, open_balance: openBalance }]);
              setOpenMarkPaid(true);
            }
          }}
          columns={columns}
        />
      )}
      {openMarkPaid && (
        <MarkPaid
          open={openMarkPaid}
          onClose={() => setOpenMarkPaid(false)}
          invoices={selectedRows}
          onSuccess={() => {
            getTransactions();
            setSelectedRows([]);
          }}
        />
      )}
      {openRevertInvoice && (
        <RevertInvoice
          open={openRevertInvoice}
          onClose={() => setOpenRevertInvoice(false)}
          invoices={selectedRows}
          tab={selectedTransactionType}
          onSuccess={() => {
            getTransactions();
            setSelectedRows([]);
          }}
        />
      )}
      {openUploadDocument && (
        <UploadDocument
          open={openUploadDocument}
          onClose={() => setOpenUploadDocument(false)}
          shipmentData={modalData.billing}
        />
      )}
      {openAddNotes && (
        <AddNotes
          open={openAddNotes}
          onClose={() => setOpenAddNotes(false)}
          data={modalData.billing}
          onSuccess={getTransactions}
        />
      )}
      {openStandaloneNotes && (
        <StandaloneInvoiceNotes
          open={openStandaloneNotes}
          onClose={() => setOpenStandaloneNotes(false)}
          invoice={modalData}
          onSuccess={getTransactions}
        />
      )}
      {openViewPdf && (
        <ViewPdf title='View Invoice(s)' open={openViewPdf} pdfUrl={pdfUrl} onClose={() => setOpenViewPdf(false)} />
      )}
      {openStatement && (
        <CustomerStatementModal open={openStatement} onClose={() => setOpenStatement(false)} customer={customer} />
      )}
      {!!invoiceToRevert && (
        <ConfirmationModal
          width='510px'
          open={!!invoiceToRevert}
          onClose={() => setInvoiceToRevert(null)}
          headerTitle={invoiceToRevert.is_stripe ? 'Refund and Revert' : 'Revert'}
          text={`Are you sure you want to ${invoiceToRevert.is_stripe ? 'refund and revert' : 'revert'} invoice ${
            invoiceToRevert.invoice_id
          }?`}
          onConfirm={refundAndRevertInvoice}
          disabled={loadingRevert}
          buttonProps={{
            title: invoiceToRevert.is_stripe ? 'Refund and Revert' : 'Revert',
          }}
        />
      )}
    </div>
  );
};

export default CustomerTransactionsTab;
