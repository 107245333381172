import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setBrowserInfo } from 'common/BrowserVarify';
import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import { useTheme } from './themeContext';

const AuthContext = createContext(null);
const useAuth = () => {
  const { setTheme } = useTheme();
  const history = useNavigate();
  const [isAuth, setIsAuth] = useContext(AuthContext);

  const handleLogin = (email, password, redirect, savedState = {}) => {
    setIsAuth({
      loading: true,
      ...savedState,
    });
    return AuthService.login(email, password, redirect).then(
      (response) => {
        if (!response) {
          return;
        }

        const { user, accountStatus } = response || {};
        const appTheme = user.theme_settings?.theme || user.customer?.theme_settings?.theme;
        const condensed = user.theme_settings?.menu_style || user.customer?.theme_settings?.menu_style;
        setTheme((prev) => {
          return {
            ...prev,
            appTheme,
            condensed: condensed === 1,
          };
        });
        setIsAuth({
          user,
          isLoggedIn: true,
          error: null,
          loading: true,
        });
        if (!user.customer?.send_otp_to_user || ['trialexpired', 'pastDue'].includes(accountStatus?.account_status)) {
          setBrowserInfo();
        }
        history('/dashboard');
        return user;
      },
      (error) => {
        const resMessage =
          error.response.status === 429
            ? `Too many login attempts. Please try again in ${Math.ceil(
                error.response.data.retry_after / 60
              )} minute(s)!`
            : error.toString();
        setIsAuth({ error: resMessage, ...savedState });
        return error;
      }
    );
  };
  const handleFirstTimeLogin = (email) => {
    AuthService.firstTimeLogin(email).then(
      (response) => {
        if (response.status !== 204 && response.status === 200) {
          localStorage.setItem('user', JSON.stringify(response.data.email));
          handleLogin(email, '');
          return response;
        }
      },
      () => {}
    );
  };

  const getUser = (id) => {
    UserService.getCurrentUser(id).then(
      (response) => {
        const savedUser = JSON.parse(localStorage.getItem('user'));
        if (response.data.data.id === savedUser.id) {
          const User = {
            ...savedUser,
            is_password_set: response.data.data.is_password_set,
          };
          localStorage.setItem('user', JSON.stringify(User));
        }
        setIsAuth({
          user: response.data.data,
          isLoggedIn: true,
          error: null,
        });
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data.password[0] && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setIsAuth({ error: resMessage });
      }
    );
  };
  return {
    value: isAuth,
    login: handleLogin,
    firstLogin: handleFirstTimeLogin,
    getUser,
    setAuth: setIsAuth,
  };
};

const AuthProvider = ({ children }) => {
  const initState = getInitialState();
  const [isAuth, setIsAuth] = useState(initState);

  function getInitialState() {
    const empty = {
      user: {},
      isLoggedIn: false,
      error: '',
      loading: false,
    };

    try {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && user.accessToken) {
        return { user, isLoggedIn: true };
      }
      return empty;
    } catch (e) {
      return empty;
    }
  }

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && user.accessToken) {
        setIsAuth({
          user,
          isLoggedIn: true,
        });
      }
    } catch (e) {
      /* empty */
    }
  }, []);

  return <AuthContext.Provider value={[isAuth, setIsAuth]}>{children}</AuthContext.Provider>;
};
export { AuthProvider, useAuth };
