import React from 'react';
import './DriverProfileButton.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

const PrimaryBtn = ({ title, icon, onClick, right }) => {
  const { use } = useTheme();
  return (
    <div>
      <button
        className='PrimaryBtn driver-style-wrap'
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
        onClick={onClick}
      >
        {!right && !!icon && <img src={icon} alt='icon' />}
        <p style={{ color: use(palette.gray900, palette.gray50) }} className='Primary-btn-title'>
          {title}
        </p>
        {right && !!icon && <img src={icon} alt='icon' />}
      </button>
    </div>
  );
};

export default PrimaryBtn;
