import React, { useEffect, useState } from 'react';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import './StaffLicense.css';
import userDefualt from 'assets/icons/drivers/userDefault.svg';
import editIcon from 'assets/icons/drivers/edit-white-icon.png';
import { CircularProgress } from '@mui/material';
import button from 'assets/icons/drivers/button.svg';
import close from 'assets/icons/drivers/close.svg';
import { useFormik } from 'formik';
import { UpdateStaffBasicDetail, uploadDriverDocument } from 'Api/Staff';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import ImageCropModal from '../ImageCropModal/ImageCropModal';
import ImageDropZone from '../ImgageDropZone/index';

const StaffShipmentActivity = ({ staffInfo, driverOtherData, setRefreshIndex }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [cropData, setCropData] = useState('');
  const [image, setImage] = React.useState();
  const [cropper, setCropper] = useState();
  const [cropModalShow, setCropModalShow] = React.useState();
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullPath, setFullPath] = useState(null);

  const formik = useFormik({
    initialValues: {
      upload_identification_card: '',
    },
  });

  useEffect(() => {
    formik.setFieldValue('upload_identification_card', driverOtherData?.upload_identification_card);
    setFullPath(driverOtherData?.upload_identification_card || null);
  }, [staffInfo, driverOtherData]);

  const imageSelected = (file) => {
    setCropModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadLicenseDocument([blob], 'upload_identification_card');
        setCropModalShow(false);
      });
    }
  };

  const uploadLicenseDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], staffInfo?.id).then((res) => {
      setFullPath(res.data.full_path);
      formik.setFieldValue(propertyName, res.data.document_path);
      setCropData(cropper.getCroppedCanvas().toDataURL());

      if (!driverOtherData?.upload_identification_card) {
        onSubmit(res.data.document_path);
      }
    });
  };

  const onSubmit = async (path) => {
    setLoading(true);
    try {
      await UpdateStaffBasicDetail({ upload_identification_card: path }, staffInfo.id);
      setRefreshIndex(Date.now());
      showToaster({ type: 'success', message: 'Photo identification has been successfully updated!' });
      setToggle(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={
        toggle
          ? 'shipment-activity-wrapper staff-user driver-style-wrap mt-0'
          : 'shipment-activity-wrapper staff-user driver-style-wrap mt-0 '
      }
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      {!!cropModalShow && (
        <ImageCropModal
          show={cropModalShow}
          onHide={() => setCropModalShow(false)}
          image={image}
          getCropData={getCropData}
          setCropper={setCropper}
        />
      )}
      <div
        className=' shipment-activity-header-container'
        style={{ borderColor: use(palette.gray50, palette.darkborder) }}
      >
        <div className='d-flex align-items-center justify-content-between w-100'>
          <h2 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            Photo Identification
          </h2>
          {!toggle ? (
            <div>
              <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
            </div>
          ) : (
            <div className='d-flex align-items-center gap-2'>
              <img src={close} alt='' onClick={() => setToggle(!toggle)} className='tick-close' />
              {loading ? (
                <CircularProgress size={30} />
              ) : (
                <img
                  src={button}
                  alt=''
                  onClick={() => onSubmit(formik.values.upload_identification_card)}
                  className='tick-close'
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className='upload-wrapper'
        style={{
          borderBottomColor: use(palette.gray50, palette.darkborder),
          borderBottom: toggle ? '1px solid #E9EDF5' : 'none',
        }}
      >
        {toggle ? (
          <div
            className='license-view'
            style={{ backgroundColor: use(palette.white, palette.dark900), position: 'relative' }}
          >
            <img
              src={cropData || fullPath || userDefualt}
              style={{ width: '100%', height: '100%' }}
              alt='License'
              className='license-image'
            />
            <div className='license-view-edit-icon'>
              <img
                src={editIcon}
                alt='editIcon'
                className='license-image'
                onChange={() =>
                  formik.setFieldValue('upload_identification_card', formik.values.upload_identification_card)
                }
              />
            </div>
            <ImageDropZone
              onDrop={(file) => imageSelected(file)}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              maxSize={1000000}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
              accept={['image/jpeg', 'image/png']}
            />
          </div>
        ) : (
          <div className='add-incident-container-wrapper '>
            <div className='staff-doc-wrap'>
              {formik?.values?.upload_identification_card ? (
                <div
                  className='license-view'
                  style={{ backgroundColor: use(palette.white, palette.dark900), position: 'relative' }}
                >
                  <img
                    src={fullPath}
                    style={{ width: '100%', height: '100%' }}
                    alt='License'
                    className='license-image'
                  />
                </div>
              ) : (
                <div
                  className='upload-document-deposit'
                  style={{
                    background: use(palette.white, palette.dark800),
                    color: use(palette.gray900, palette.gray50),
                    position: 'relative',
                  }}
                >
                  <label htmlFor='myFile' className='text-style upload-label'>
                    <p className='upload-document-icon'>
                      <img src={uploadLicense} alt='' />
                    </p>
                    <p className='upload-document-text'>
                      <span style={{ color: '#4F5AED' }}> Upload Document </span>
                      <span style={{ color: use(palette.gray700, palette.gray200) }}> or drag and drop</span>
                    </p>
                  </label>
                  <ImageDropZone
                    onDrop={(file) => imageSelected(file)}
                    width='100%'
                    height='100%'
                    name='small'
                    deletable={false}
                    maxSize={1000000}
                    customStyle={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    accept={['image/jpeg', 'image/png']}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffShipmentActivity;
