import React from 'react';
import { SWrapper } from './TimeWorked.styles';

const TimeWorked = ({ data, onClick }) => {
  return (
    <SWrapper onClick={onClick}>
      <p className='time-worked'>
        {data?.totalHours}h {data?.totalMinutes}m
      </p>
      <div className='time-worked-wrap'>
        <div className='time-worked-wrap-sub' style={{ width: `${Number(data?.totalHours) * 0.4}%` }} />
      </div>
    </SWrapper>
  );
};

export default TimeWorked;
