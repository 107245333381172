import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import HardwareDetails from '../HardwareDetails';

const HardwareCard = ({ data, index, activeCard, handleCardClick, initialQuantity, addToCart, hardwareList }) => {
  const [quantity, setQuantity] = useState(initialQuantity || 0);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleClick = () => {
    handleCardClick(index, data);
    setSelectedCard(data);
  };

  const handleCountChange = (value) => {
    setQuantity(value);
  };

  const handleAddToCart = () => {
    addToCart(data, Number(quantity));
  };

  const onViewPaired = (hardware) => {
    const hardwareToView = hardwareList.find((i) => i.id === hardware.id);

    if (hardwareToView) {
      setSelectedCard(hardwareToView);
    }
  };

  return (
    <>
      <div className={activeCard === index ? 'hardware-card active-card' : 'hardware-card'} onClick={handleClick}>
        <div className='hardware-card-img-container'>
          <img
            className='hardware-img'
            alt='hardware'
            src={data?.images[0].image}
            onClick={() => setSelectedCard(data)}
          />
        </div>
        <p onClick={() => setSelectedCard(data)} className='hardware-name'>
          {data?.name}
        </p>
        {data.financed ? (
          <p className='card-price'>
            ${(Number(data?.price) / 36).toFixed(2)} <span>Per Month </span>{' '}
          </p>
        ) : (
          <p className='card-price'>
            ${data.price} <span>Per Unit </span>{' '}
          </p>
        )}
        <Typography variant='c2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
          SEE DETAILS
        </Typography>
      </div>
      {!!selectedCard && (
        <HardwareDetails
          open={!!selectedCard}
          onClose={() => setSelectedCard(null)}
          count={quantity}
          hardware={selectedCard}
          handleCountChange={handleCountChange}
          onViewPaired={onViewPaired}
          handleAddToCart={handleAddToCart}
        />
      )}
    </>
  );
};

export default HardwareCard;
