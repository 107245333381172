import { number, object } from 'yup';

export const validationSchema = object().shape({
  excused_absences: number().required('Required').nullable(),
  unexcused_absences: number().required('Required').nullable(),
  vacations_allowed: number().required('Required').nullable(),
  absence_reset_based_on: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
