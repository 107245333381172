import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { palette } from '../../utils/constants';
import { useTheme } from '../../context/themeContext';

export default function AlertDialog({ onHide, ...props }) {
  const { use } = useTheme();
  const navigate = useNavigate();
  return (
    <div className='modal-container'>
      <Modal onHide={onHide} {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            color: use(palette.gray50, palette.gray800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Add More User Licenses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{
            backgroundColor: use(palette.gray0, palette.dark900),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <p className='text-style' style={{ color: use(palette.gray700, palette.gray50), marginLeft: '0' }}>
            You have reached your license limit, please add additional user licenses.
          </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={onHide}>
              Cancel
            </Button>
            <Button className='next-step' onClick={() => navigate('/billing')}>
              Add License
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
