import React from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as FileIcon } from 'assets/icons/createShipment/attachment.svg';
import Divider from 'common/Divider';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import ApplyEquipment from '../ApplyEquipment';
import { SInsuranceFields } from './InsuranceFields.styles';

const InsuranceFields = ({ field, form }) => {
  const { values, handleChange, touchedErrors, setValues, handleBlur } = form;

  return (
    <SInsuranceFields>
      <div className='main-fields'>
        <div className='d-flex align-items-center gap-2'>
          <CustomCheckbox
            type='switch'
            name={field.active}
            checked={!!values[field.active]}
            onChange={(checked) => handleChange(field.active, checked)}
            smail={false}
          />
          <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
            {field.title}
          </Typography>
        </div>
        {!!field.policy_id && (
          <div>
            <InputWithIcon
              width='120px'
              left
              icon='#'
              name={field.policy_id}
              value={values[field.policy_id]}
              onChange={handleChange}
              error={touchedErrors[field.policy_id]}
              disabled={!values[field.active]}
            />
          </div>
        )}

        {!!field.expire_date && (
          <div className='d-flex align-items-center gap-2'>
            <InputLabel className='mb-0'>Expiry</InputLabel>
            <div>
              <DatePicker
                width='150px'
                name={field.expire_date}
                value={values[field.expire_date]}
                onChange={(val) => handleChange(field.expire_date, val)}
                onBlur={handleBlur}
                disablePast
                disabled={!values[field.active] || values[field.no_expire]}
                error={touchedErrors[field.expire_date]}
              />
            </div>
            <div>
              <CustomCheckbox
                name={field.no_expire}
                checked={!!values[field.no_expire]}
                disabled={!values[field.active]}
                onChange={(checked) => {
                  setValues((prevState) => ({
                    ...prevState,
                    [field.no_expire]: checked,
                    [field.expire_date]: checked ? null : prevState.expire_date,
                  }));
                }}
              >
                <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2 nowrap'>
                  No Expiry
                </Typography>
              </CustomCheckbox>
            </div>
          </div>
        )}
        <div className='d-flex gap-3'>
          <Uploader
            type={3}
            accept={['application/pdf']}
            uploaderLabel='Upload Document'
            uploadedLabel={`${field.title} Attachment`}
            document={values[field.attachment]}
            onDrop={(files) => handleChange(field.attachment, files[0])}
            uploadedColor={palette.indigo500}
            uploadedBgColor={palette.indigo50}
            uploadedIcon={<FileIcon fill={palette.indigo500} />}
            viewPdf
            disabled={!values[field.active]}
          />
          {values[field.attachment] && !!values[field.active] && (
            <Uploader
              type={3}
              allowReplace
              accept={['application/pdf']}
              uploadedLabel='Replace Document'
              uploadedIcon={<span />}
              uploadedBgColor='transparent'
              document={values[field.attachment]}
              onDrop={(files) => handleChange(field.attachment, files[0])}
              disabled={!values[field.active]}
            />
          )}
        </div>
      </div>
      <Divider />
      <div className='d-flex align-items-start gap-3'>
        <div className='d-flex align-items-center gap-2 ms-5 mt-1'>
          <ArrowIcon />
          <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
            Select Equipment Policy Will Apply:
          </Typography>
        </div>
        <ApplyEquipment policyType={field.policy_type} />
      </div>
    </SInsuranceFields>
  );
};

export default InsuranceFields;
