import axios from 'services/axios';
import axios2 from 'axios';
import authHeader from 'services/auth-header';

export async function getAppLatestVersion(params) {
  const { data } = await axios.get('get-last-version', { params });
  return data;
}

export async function getUserProfile(params) {
  const { data } = await axios.get('user/profile', { params });
  return data;
}

export async function updateUserProfile(body) {
  const { data } = await axios.post('user/update-profile', body);
  return data;
}

export async function getStaffById(id) {
  const { data } = await axios.get(`users/${id}`);
  return data;
}

export async function getStaffBasic(id) {
  const { data } = await axios.get(`user/staff/basic/${id}`);
  return data;
}

export async function updateStaffBasic(id, body) {
  const { data } = await axios.put(`user/staff/basic/${id}`, body);
  return data;
}

export async function updateSetupComplete() {
  const { data } = await axios.put(`users/mark-complete-flag`);
  return data;
}

export async function getAppSettings() {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/get-app-setting`;
    const { data } = await axios2.get(url, { headers: authHeader() });
    return data;
  } catch (e) {
    // Do nothing
  }
}

export async function getReviewSettings() {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/get-review-setting`;
    const { data } = await axios2.get(url, { headers: authHeader() });
    return data;
  } catch (e) {
    // Do nothing
  }
}

export async function getReview() {
  const { data } = await axios.get(`customer/review`);
  return data;
}

export async function createReview(body) {
  const { data } = await axios.post(`customer/review`, body);
  return data;
}

export async function getTableSettings(params) {
  const { data } = await axios.get(`/table-settings`, { params });
  return data;
}

export async function updateTableSettings(body) {
  const { data } = await axios.put('/table-settings', body);
  return data;
}

export async function getTabs(params) {
  const { data } = await axios.get(`/tabs`, { params });
  return data;
}

export async function getRequirePayment() {
  const { data } = await axios.get(`/require-payment`);
  return data;
}

export async function cancelCustomer(id, body) {
  const { data } = await axios.put(`/customer/cancel-customer/${id}`, body);
  return data;
}

export async function addCity(body) {
  const { data } = await axios.post(`/cities`, body);
  return data;
}

export async function sendResetPasswordEmail(body) {
  const { data } = await axios.post(`/user/password/email`, body);
  return data;
}

export async function resetPassword(body) {
  const { data } = await axios.post(`/user/password/reset`, body);
  return data;
}
