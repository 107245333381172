import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import classes from 'components/TableRowEditModalTypes/Detention/detention.module.scss';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import sm from 'components/CreateShipment/CreateModals/addStopPointModal/addStopPointModal.module.css';
import { validateRequired } from 'components/AddEditModalStops/validates';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { STOP_POINT_COUNTRY } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getStopPointCity, getStopPointState } from 'Api/Planner';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { GetStopPointsItem } from 'Api/StopPoint';

const SettingsModal = ({ isOpen, setIsOpen, onUpdate }) => {
  const { use } = useTheme();
  const [stopPoints, setStopPoints] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const getStopPoint = (id) => {
    return GetStopPointsItem(id).then((res) => {
      if (res && res?.data) {
        setStopPoints(res?.data);
        return res?.data;
      }
    });
  };

  const getCities = (id) => {
    return getStopPointCity({ id }).then((res) => {
      if (res && res?.data) {
        setCities(res?.data);
        return res?.data;
      }
    });
  };

  const getStates = (id) => {
    return getStopPointState({ id }).then((res) => {
      if (res && res?.data) {
        setStates(res?.data);
        return res?.data;
      }
    });
  };

  const onChangeCountry = ({ e, first, values, setValues }) => {
    if (first) {
      getStates(e.id).then((resData) => {
        const eState = resData?.find((oneData) => Number(oneData?.id) === Number(first?.stop_point?.state_id));
        setTimeout(() => onChangeState({ e: eState, first: true }), 0);
      });
    } else {
      setCities(null);
      setValues({ ...values, state_id: '', city_id: '' });
      getStates(e.id).then((data) => setStates(data));
    }

    return false;
  };

  const onChangeState = ({ e, first, values, setValues }) => {
    if (first) {
      getCities(e?.id).then((data) => {
        const eCity = data?.find((el) => Number(el?.id) === Number(e?.id));
        setTimeout(() => onChangeCity({ e: eCity, first: true }), 0);
      });
    } else {
      setValues({ ...values, city_id: '' });
      getCities(e?.id).then((data) => setCities(data));
    }
    return false;
  };

  const onChangeCity = ({ e, first, values, setValues }) => {
    if (!first) setValues({ ...values, city_id: e?.id });
    return false;
  };

  const initialValue = useMemo(() => {
    const {
      location_name,
      stop_point_id,
      address1,
      address2,
      zipcode,
      city_id,
      country_id,
      state_id,
      require_scale_tickets,
      require_lumper_receipt,
    } = stopPoints?.stop_point || {};

    return {
      location_name: location_name || '',
      stop_point_id: stop_point_id || '',
      address1: address1 || '',
      address2: address2 || '',
      country_id: country_id || '',
      state_id: state_id || '',
      city_id: city_id || '',
      zipcode: zipcode || '',
      require_scale_tickets: +require_scale_tickets === 1,
      require_lumper_receipt: +require_lumper_receipt === 1,
    };
  }, [stopPoints]);

  const styles = useMemo(() => {
    return {
      modal: {
        width: 640,
        overflow: 'hidden',
        maxHeight: '90%',
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      labelStyleAddress2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 4,
      },
      styleAddress2: {
        paddingLeft: 10,
        borderRadius: '6px',
      },
      styleAddress1: {
        paddingLeft: 10,
        borderRadius: '6px',
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      state: {
        width: '100%',
        height: 32,
        borderRadius: '6px ',
      },
      city: {
        width: '100%',
        height: 32,
        borderRadius: '6px',
      },
      inputStyleAddress: {
        width: '100%',
        paddingLeft: 10,
        borderRadius: ' 6px',
      },
      labelStyleAddress: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap',
        marginTop: 0,
      },
      country: {
        width: '100%',
        height: 32,
        marginTop: 0,
      },
      fullWidthPercent: {
        width: '100%',
      },
      width400: {
        width: 400,
      },
      width400_2: {
        width: 400,
        marginTop: 3,
      },
      width194: {
        width: 194,
      },
      width25: {
        width: '25%',
      },
      phoneSelect: {
        width: '100%',
        height: 32,
        borderRadius: '6px 0 0 6px',
      },
      isSearchableCity: {
        width: '100%',
        height: 32,
        borderRadius: '6px 0 0 6px',
      },
      isSearchableState: {
        width: '100%',
        height: 32,
        borderRadius: 0,
      },
    };
  }, [use, palette]);

  useEffect(() => {
    if (isOpen) {
      getStopPoint(isOpen).then((data) => {
        if (Object.keys(data).length > 0) {
          const e = STOP_POINT_COUNTRY.find((s) => Number(s?.id) === Number(data?.stop_point?.country_id));
          onChangeCountry({ e, first: data });
        }
      });
    }
  }, [isOpen]);

  return (
    <Modal
      size='lg'
      centered
      show={!!isOpen}
      onHide={() => setIsOpen('')}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Formik initialValues={{ ...initialValue }} enableReinitialize onSubmit={() => {}}>
        {({ values, resetForm, submitForm, validateForm, setValues }) => {
          return (
            <Form>
              <Modal.Body style={styles.modalBody}>
                <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <Typography variant='button2'>Update Stop Point</Typography>
                </div>

                <div
                  className={classes.modalBody}
                  style={{
                    backgroundColor: use(palette.gray0, palette.dark800),
                    borderColor: use(palette.gray50, palette.gray700),
                  }}
                >
                  <div className={sm.row2} style={{ marginTop: 0 }}>
                    <div style={styles.width400}>
                      <Field
                        name='location_name'
                        required
                        component={CustomInput}
                        label='Location Name'
                        style={{ paddingLeft: 10 }}
                        labelStyle={{ ...styles.labelStyle }}
                        validate={validateRequired}
                        className={sm.input_basic_details}
                      />
                      <ErrorMessage
                        name='location_name'
                        render={(error) => (
                          <Typography variant='c2' style={styles.error}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>

                    <div style={styles.width400_2}>
                      <Field
                        label='Custom ID'
                        name='stop_point_id'
                        component={CustomInput}
                        style={{ paddingLeft: 10 }}
                        labelStyle={styles.labelStyle}
                        className={sm.input_basic_details}
                      />
                    </div>
                  </div>

                  <div className={sm.address1_address2}>
                    <div style={styles.width400}>
                      <Field
                        name='address1'
                        required
                        component={CustomInput}
                        label='Address 1'
                        style={styles.styleAddress1}
                        labelStyle={{ ...styles.labelStyle, marginTop: 1 }}
                        validate={validateRequired}
                        className={sm.input_basic_details}
                      />
                      <ErrorMessage
                        name='address1'
                        render={(error) => (
                          <Typography variant='c2' style={styles.error}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>

                    <div style={styles.width400}>
                      <Field
                        name='address2'
                        label='Address 2'
                        component={CustomInput}
                        style={styles.styleAddress2}
                        labelStyle={styles.labelStyleAddress2}
                        className={sm.input_basic_details}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
                    <div style={styles.width194}>
                      <div className={sm.select_wrapper}>
                        <Field
                          name='country_id'
                          keyName='id'
                          styles={styles.country}
                          component={CustomSelect}
                          options={STOP_POINT_COUNTRY}
                          validate={validateRequired}
                          menuStyles={styles.fullWidthPercent}
                          onChange={(e) =>
                            onChangeCountry({
                              e,
                              first: false,
                              values,
                              setValues,
                            })
                          }
                        />
                        <ErrorMessage
                          name='country_id'
                          render={(error) => (
                            <Typography variant='c2' style={styles.error}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>
                    </div>
                    <div style={styles.width194}>
                      <div className={sm.select_wrapper}>
                        <Field
                          name='state_id'
                          keyName='id'
                          labelName='name'
                          options={states}
                          isSearchable
                          styles={styles.state}
                          component={CustomSelect}
                          defaultText='State...'
                          validate={validateRequired}
                          menuStyles={styles.fullWidthPercent}
                          isSearchableStyles={styles.isSearchableState}
                          onChange={(e) =>
                            onChangeState({
                              e,
                              first: false,
                              values,
                              setValues,
                            })
                          }
                        />
                        <ErrorMessage
                          name='state_id'
                          render={(error) => (
                            <Typography variant='c2' style={styles.error}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>
                    </div>

                    <div style={styles.width194}>
                      <div className={sm.select_wrapper}>
                        <Field
                          name='city_id'
                          keyName='id'
                          labelName='name'
                          options={cities}
                          isSearchable
                          styles={styles.city}
                          defaultText='City...'
                          disabled={!values.state_id}
                          component={CustomSelect}
                          validate={validateRequired}
                          menuStyles={styles.fullWidthPercent}
                          isSearchableStyles={styles.isSearchableCity}
                          onChange={(e) => onChangeCity({ e, first: false, values, setValues })}
                        />
                        <ErrorMessage
                          name='city_id'
                          render={(error) => (
                            <Typography variant='c2' style={styles.error}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>
                    </div>
                    <div style={styles.width194}>
                      <div className={sm.select_wrapper}>
                        <Field
                          name='zipcode'
                          component={CustomInput}
                          placeholder='Zip Code'
                          style={styles.inputStyleAddress}
                          labelStyle={styles.labelStyleAddress}
                          validate={validateRequired}
                          className={sm.input_basic_details}
                        />
                        <ErrorMessage
                          name='zipcode'
                          render={(error) => (
                            <Typography variant='c2' style={styles.error}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={sm.col}>
                    <Field name='require_scale_tickets'>
                      {({ field }) => (
                        <div>
                          <Typography variant='s2' />
                          <CustomCheckbox
                            field={field}
                            withinForm
                            checked={!!field.value}
                            style={{ marginTop: 22 }}
                            name='require_scale_tickets'
                          >
                            <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                              Require Scale/Tickets
                            </Typography>
                          </CustomCheckbox>
                        </div>
                      )}
                    </Field>

                    <Field name='require_lumper_receipt'>
                      {({ field }) => (
                        <div>
                          <Typography variant='s2' />
                          <CustomCheckbox
                            field={field}
                            withinForm
                            checked={!!field.value}
                            style={{ marginTop: 22 }}
                            name='require_lumper_receipt'
                          >
                            <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                              Require Lumper Receipt
                            </Typography>
                          </CustomCheckbox>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <div
                    className={classes.modalFooterLane}
                    style={{ backgroundColor: use(palette.white, palette.dark900) }}
                  >
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen('');
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title='Update'
                      onClick={() => onUpdate(isOpen, validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SettingsModal;
