export const getInitialValues = (vendor) => {
  const { city, state, country, address, address2, zipcode } = vendor || {};

  return {
    name: vendor?.name || '',
    phone_number: vendor?.phone_number || '',
    email: vendor?.email || '',
    website: vendor?.website || '',
    payment_term: vendor?.payment_term
      ? {
          ...vendor.payment_term,
          name: vendor.payment_term.id === 4 ? 'Due on Discount' : `Net ${vendor.payment_term.net_day}`,
        }
      : null,
    account: vendor?.account || null,
    account_custom_id: vendor?.account_custom_id || '',
    bank_name: vendor?.bank_name || '',
    bank_account_number: vendor?.bank_account_number || '',
    routing_number: vendor?.routing_number || '',
    add_to_report: vendor?.add_to_report || false,
    tax_id: vendor?.tax_id || '',
    address: address || '',
    address2: address2 || '',
    city: city || null,
    state: state || null,
    country: country || null,
    zipcode: zipcode || '',
  };
};
