import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import { palette } from 'utils/constants';

const RowActions = ({ row, onView, onDelete, onDownload }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'view':
        onView();
        break;
      case 'delete':
        onDelete();
        break;
      case 'download':
        onDownload(row);
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li onClick={(event) => onActionClick(event, 'view')}>View</li>
      <li className='delete-action' onClick={(event) => onActionClick(event, 'delete')}>
        Delete
      </li>
      <li onClick={(event) => onActionClick(event, 'download')}>
        <Typography variant='s2' style={{ color: row?.absence_document ? palette.gray900 : palette.gray200 }}>
          Download
        </Typography>
      </li>
    </ThreeDotActions>
  );
};

export default RowActions;
