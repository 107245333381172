import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import CityAutocomplete from 'componentsV2/CityAutocomplete';
import AddressAutocomplete from 'common/AddressAutocomplete';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getStopPointCity, getStopPointState, PostStopPointStepOne } from 'Api/Planner';
import sm from '../addStopPointModal.module.css';

import CustomInput from '../../../helpers/CustomInput';
import CustomSelect from '../../../ShipmentStops/helpers/CustomSelect';
import AddStopPointFooter from '../addStopPointFooter';
import { STOP_POINT_COUNTRY } from '../../../ShipmentStops/helpers/constants';
import { StopPointModalContext } from '../index';

const styles = {
  labelStyle: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 0,
  },
  labelStyleAddress2: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 4,
  },
  styleAddress2: {
    paddingLeft: 10,
    paddingRight: 26,
    borderRadius: '6px',
  },
  styleAddress1: {
    paddingLeft: 10,
    paddingRight: 26,
    borderRadius: '6px',
  },
  error: {
    color: palette.red500,
  },
  state: {
    width: '100%',
    height: 32,
    borderRadius: '6px ',
  },
  city: {
    width: '100%',
    height: 32,
    borderRadius: '6px',
  },
  inputStyleAddress: {
    width: '100%',
    paddingLeft: 10,
    borderRadius: ' 6px',
  },
  labelStyleAddress: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    marginTop: 0,
  },
  country: {
    width: '100%',
    height: 32,
    marginTop: 0,
  },
  fullWidthPercent: {
    width: '100%',
  },
  width400: {
    width: 400,
  },
  width400_2: {
    width: 400,
    marginTop: 3,
  },
  width194: {
    width: 194,
  },
  width25: {
    width: '25%',
  },
  phoneSelect: {
    width: '100%',
    height: 32,
    borderRadius: 6,
  },
  isSearchableCity: {
    width: '100%',
    height: 32,
    borderRadius: 6,
  },
  isSearchableState: {
    width: '100%',
    height: 32,
    borderRadius: 6,
  },
};

const StopPointModalStep1 = () => {
  const { setStep, updateStopPointId } = useContext(StopPointModalContext);
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onNext = (values) => {
    if (!values.address1) {
      return;
    }
    setLoading(true);
    const postData = { status_id: 1, equipment_count: 0 };
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (typeof value === 'boolean') {
        postData[key] = value ? '1' : '0';
      } else if (key === 'city') {
        postData.city_id = value.id.toString();
      } else {
        if ((typeof value === 'string' && value?.trim() !== '') || value) {
          postData[key] = value;
        }
      }
    });
    PostStopPointStepOne(postData)
      .then((res) => {
        if (res && res?.data) {
          showToaster({ type: 'success', message: 'Stop Point added successfully' });
          const id = Number(res?.data?.id);
          if (id) {
            updateStopPointId(id);
            setStep((p) => p + 1);
          }
        }
      })
      .catch((e) => showToaster({ type: 'error', message: getErrorMessage(e) }))
      .finally(() => setLoading(false));
  };

  const initialValue = useMemo(() => {
    return {
      stop_point_id: '',
      location_name: '',
      country_id: '',
      city: null,
      state_id: '',
      address1: '',
      address2: '',
      zipcode: '',
      require_scale_tickets: false,
      require_lumper_receipt: false,
    };
  }, []);

  return (
    <div className={sm.step1}>
      <Formik onSubmit={onNext} initialValues={initialValue}>
        {({ values, submitForm, getFieldHelpers, setFieldValue, validateField, ...props }) => {
          return (
            <StopDepComponent
              values={values}
              submitForm={submitForm}
              getFieldHelpers={getFieldHelpers}
              setFieldValue={setFieldValue}
              validateField={validateField}
              loading={loading}
              {...props}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default StopPointModalStep1;

const StopDepComponent = (props) => {
  const { values, submitForm, getFieldHelpers, setFieldValue, loading } = props;

  const { step, setStep, onChangeShow, cityData, updateCityData, stateData, updateStateData, validateStopPointInput } =
    useContext(StopPointModalContext);
  const [selectFirst, setSelectFirst] = useState(true);
  const [activeOption] = useState({});
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [selectedState, setSelectedState] = useState(null);

  const getCity = (id) => {
    return getStopPointCity({ id }).then((res) => {
      if (res && res?.data) {
        const selectData = res.data.map((el, i, arr) => {
          const prevCities = arr.slice(0, i);
          const similarNames = prevCities.filter((item) => item.name === el.name);
          return {
            ...el,
            name: similarNames.length ? `${el.name} (${similarNames.length})` : el.name,
          };
        });
        updateCityData(selectData);
        return selectData;
      }
    });
  };

  const getState = (id) => {
    return getStopPointState({ id }).then((res) => {
      if (res && res?.data) {
        const selectData = res.data.map((el) => {
          return {
            ...el,
            key: el?.name,
            label: el?.name,
            id: el?.id,
          };
        });
        updateStateData(selectData);
        return selectData;
      }
    });
  };

  function onChangeCountry({ value, getFieldHelpers, first }) {
    if (first) {
      const country_id = getFieldHelpers('country_id');
      const zipcode = getFieldHelpers('zipcode');
      country_id.setValue(value.id);
      zipcode.setValue(activeOption?.Zip);
      getState(value.id).then((data) => {
        const fValueState = data?.find((el) => el?.short_name === activeOption?.State);
        !!fValueState && onChangeState({ value: fValueState, getFieldHelpers, first: true });
      });
    } else {
      const state_id = getFieldHelpers('state_id');
      const city = getFieldHelpers('city');
      city.setValue(null);
      state_id.setValue('');
      setSelectedState(null);
      getState(value.id).then();
    }
  }

  function onChangeState({ value, getFieldHelpers, first }) {
    if (first) {
      const state_id = getFieldHelpers('state_id');
      state_id.setValue(value?.id);
      setSelectedState(value);
      getCity(value.id).then();
    } else {
      const city = getFieldHelpers('city');
      city.setValue(null);
      setSelectedState(value);
      getCity(value.id).then();
    }
  }

  const onAddressSelect = (data) => {
    setFieldValue('country_id', data.country?.id.toString());
    onChangeCountry({ value: data.country, getFieldHelpers, first: false });
    setFieldValue('state_id', data.state?.id.toString());
    setSelectedState(data.state);
    onChangeState({ value: data.state, getFieldHelpers, first: false });
    setFieldValue('city', data.city || null);
  };

  const changeAddress = ({ address, zip }) => {
    setInvalidAddress(false);
    setFieldValue('address1', address);
    setFieldValue('zipcode', zip || values.zipcode);
  };

  useEffect(() => {
    if (typeof activeOption === 'object' && Object.keys(activeOption).length > 0) {
      let foundActiveCountry;
      for (let i = 0; i < STOP_POINT_COUNTRY.length; i++) {
        if (STOP_POINT_COUNTRY[i].key === activeOption.Country) {
          foundActiveCountry = STOP_POINT_COUNTRY[i];
          break;
        }
      }
      if (foundActiveCountry) {
        onChangeCountry({ value: foundActiveCountry, getFieldHelpers, first: true });
      }
    }
  }, [activeOption]);

  return (
    <Form>
      <div className={sm.row2} style={{ marginTop: 0 }}>
        <div style={styles.width400}>
          <Field
            name='location_name'
            required
            component={CustomInput}
            label='Location Name'
            style={{ paddingLeft: 10 }}
            labelStyle={{ ...styles.labelStyle }}
            validate={validateStopPointInput}
            className={sm.input_basic_details}
          />
          <ErrorMessage
            name='location_name'
            render={(error) => (
              <Typography variant='c2' style={styles.error}>
                {error}
              </Typography>
            )}
          />
        </div>

        <div style={styles.width400_2}>
          <Field
            label='Custom ID'
            name='stop_point_id'
            component={CustomInput}
            style={{ paddingLeft: 10 }}
            labelStyle={styles.labelStyle}
            className={sm.input_basic_details}
          />
          <ErrorMessage
            name='stop_point_id'
            render={(error) => (
              <Typography variant='c2' style={styles.error}>
                {error}
              </Typography>
            )}
          />
        </div>
      </div>

      <div className={sm.address1_address2}>
        <div>
          <InputLabel required style={{ margin: '8px 0 0', color: palette.gray700 }}>
            Address 1
          </InputLabel>
          <AddressAutocomplete
            width='400px'
            name='address1'
            id='address1'
            onSuccess={onAddressSelect}
            changeAddress={changeAddress}
            onChange={(e) => {
              setInvalidAddress(true);
              setFieldValue('address1', e.target.value);
            }}
            value={values.address1}
            options={{ componentRestrictions: { country: ['us', 'ca', 'mx'] } }}
            error={
              (invalidAddress && !values.address1 && 'Required') ||
              (invalidAddress && !!values.address1 && 'Invalid Address')
            }
          />
        </div>
        <div style={styles.width400}>
          <Field
            name='address2'
            label='Address 2'
            component={CustomInput}
            style={styles.styleAddress2}
            labelStyle={styles.labelStyleAddress2}
            className={sm.input_basic_details}
          />
          <ErrorMessage
            name='address2'
            render={(error) => (
              <Typography variant='c2' style={styles.error}>
                {error}
              </Typography>
            )}
          />
        </div>
      </div>

      <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
        <div style={styles.width194}>
          <div className={sm.select_wrapper}>
            <Field
              name='country_id'
              keyName='id'
              styles={styles.country}
              component={CustomSelect}
              selectFirst={selectFirst}
              options={STOP_POINT_COUNTRY}
              validate={validateStopPointInput}
              menuStyles={styles.fullWidthPercent}
              onChange={(value) => {
                setSelectFirst(false);
                onChangeCountry({ value, getFieldHelpers });
              }}
            />
            <ErrorMessage
              name='country_id'
              render={(error) => (
                <Typography variant='c2' style={styles.error}>
                  {error}
                </Typography>
              )}
            />
          </div>
        </div>
        <div style={styles.width194}>
          <div className={sm.select_wrapper}>
            <Field
              name='state_id'
              keyName='id'
              options={stateData}
              isSearchable
              styles={styles.state}
              component={CustomSelect}
              defaultText='State...'
              validate={validateStopPointInput}
              menuStyles={styles.fullWidthPercent}
              isSearchableStyles={styles.isSearchableState}
              onChange={(value) => onChangeState({ value, getFieldHelpers })}
            />
            <ErrorMessage
              name='state_id'
              render={(error) => (
                <Typography variant='c2' style={styles.error}>
                  {error}
                </Typography>
              )}
            />
          </div>
        </div>
        <div style={styles.width194}>
          <div className={sm.select_wrapper}>
            <CityAutocomplete
              values={values}
              cities={cityData}
              selectedState={selectedState}
              setFieldValue={setFieldValue}
              getCity={getCity}
            />
          </div>
        </div>
        <div style={styles.width194}>
          <div className={sm.select_wrapper}>
            <Field
              name='zipcode'
              component={CustomInput}
              placeholder='Zip Code'
              style={styles.inputStyleAddress}
              labelStyle={styles.labelStyleAddress}
              validate={validateStopPointInput}
              className={sm.input_basic_details}
            />
            <ErrorMessage
              name='zipcode'
              render={(error) => (
                <Typography variant='c2' style={styles.error}>
                  {error}
                </Typography>
              )}
            />
          </div>
        </div>
      </div>

      {/* /// col 5 */}
      <div className={sm.col}>
        <Field name='require_scale_tickets'>
          {({ field }) => (
            <div>
              <Typography variant='s2' />
              <CustomCheckbox
                field={field}
                withinForm
                checked={!!field.value}
                style={{ marginTop: 22 }}
                name='require_scale_tickets'
              >
                <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                  Require Scale/Tickets
                </Typography>
              </CustomCheckbox>
            </div>
          )}
        </Field>

        <Field name='require_lumper_receipt'>
          {({ field }) => (
            <div>
              <Typography variant='s2' />
              <CustomCheckbox
                field={field}
                withinForm
                checked={!!field.value}
                style={{ marginTop: 22 }}
                name='require_lumper_receipt'
              >
                <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
                  Require Lumper Receipt
                </Typography>
              </CustomCheckbox>
            </div>
          )}
        </Field>
      </div>
      <AddStopPointFooter
        step={step}
        setStep={setStep}
        submitForm={() => {
          setInvalidAddress(!values.address1);
          submitForm();
        }}
        onChangeShow={onChangeShow}
        loading={loading}
      />
    </Form>
  );
};
