import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { logStatuses } from 'componentsV2/EDI/EDILogs/EDILogs.data';
import { initialValues } from './AdvancedFilter.data';
import { SDatePicker, SWrapper } from './AdvancedFilter.styles';

const AdvancedFilter = ({ filters, updateFilters, loading, customers, drivers, vehicles, trailers }) => {
  const [range, setRange] = useState(filters.range);

  const onSubmit = (values) => {
    updateFilters((prevState) => ({ ...prevState, ...values, range }));
  };

  const { values, handleChange, handleSubmit, resetForm } = useForm({
    initialValues: {
      shipment_id: filters.shipment_id || '',
      statuses: filters.statuses || [],
      customers: filters.customers || [],
      drivers: filters.drivers || [],
      vehicles: filters.vehicles || [],
      trailers: filters.trailers || [],
      acknowledged: filters.acknowledged || 'All',
    },
    onSubmit,
    enableReinitialize: true,
  });

  const onRemoveRange = () => {
    setRange([null, null]);
  };

  const onClearAll = () => {
    updateFilters((prevState) => ({ ...prevState, ...initialValues }));
    resetForm();
    onRemoveRange();
  };

  return (
    <SWrapper>
      <div className='d-flex gap-4'>
        <div>
          <div className='w-100 d-flex flex-column mt-2'>
            <InputLabel className='mb-0'>Date</InputLabel>
            <div className='position-relative'>
              <SDatePicker
                selectsRange
                renderStart={false}
                shouldCloseOnSelect={false}
                startDate={range[0]}
                endDate={range[1]}
                placeholderText='MM/DD/YYYY - MM/DD/YYYY'
                onChange={(dates) => setRange(dates)}
              />
              <DeleteIcon className='delete-icon' onClick={() => onRemoveRange('delivery')} />
            </div>
          </div>
          <div className='w-100 mt-2'>
            <Input
              label='Shipment ID'
              width='250px'
              name='shipment_id'
              placeholder='Shipment ID'
              onChange={handleChange}
              value={values.shipment_id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
          <div className='w-100 mt-2'>
            <Autocomplete
              label='Status'
              width='250px'
              labelKey='title'
              multiple
              limitTags={2}
              placeholder={values.statuses?.length ? '' : 'Select Status'}
              options={logStatuses}
              value={values.statuses}
              onChange={(e, val) => handleChange('statuses', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
        </div>
        <div>
          <div className='w-100 mt-2'>
            <Autocomplete
              width='250px'
              label='Drivers'
              multiple
              limitTags={2}
              placeholder={values.drivers?.length ? '' : 'Select Drivers..'}
              options={drivers}
              value={values.drivers || []}
              onChange={(e, val) => handleChange('drivers', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
              getOptionLabel={(option) => `${option.fname} ${option.lname}`}
              getTagLabel={(option) => `${option.fname} ${option.lname}`}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.fname} {option.lname}
                </li>
              )}
            />
          </div>
          <div className='w-100 mt-2'>
            <Autocomplete
              width='250px'
              label='Customers'
              multiple
              limitTags={2}
              labelKey='company_name'
              options={customers}
              placeholder={values.customers?.length ? '' : 'Select Customer'}
              value={values.customers}
              onChange={(e, val) => handleChange('customers', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.company_name}
                </li>
              )}
            />
          </div>
          <div className='w-100 mt-2'>
            <Autocomplete
              label='Acknowledged'
              width='250px'
              options={['All', 'Yes', 'No']}
              value={values.acknowledged}
              onChange={(e, val) => handleChange('acknowledged', val)}
              labelProps={{ className: 'mb-0' }}
            />
          </div>
        </div>
        <div>
          <div className='w-100 mt-2'>
            <Autocomplete
              width='250px'
              label='Vehicles'
              multiple
              limitTags={2}
              placeholder={values.vehicles?.length ? '' : 'Select Vehicles..'}
              options={vehicles}
              value={values.vehicles || []}
              onChange={(e, val) => handleChange('vehicles', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
              getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
              getTagLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
            />
          </div>
          <div className='w-100 mt-2'>
            <Autocomplete
              width='250px'
              label='Trailers'
              multiple
              limitTags={2}
              placeholder={values.trailers?.length ? '' : 'Select Trailers..'}
              options={trailers}
              value={values.trailers || []}
              onChange={(e, val) => handleChange('trailers', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              labelProps={{ className: 'mb-0' }}
              getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
              getTagLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
            />
          </div>
        </div>
      </div>
      <div className='w-100 d-flex justify-content-center mt-3'>
        <CustomButton
          type='secondary'
          title='Clear All'
          className='ms-3'
          onClick={onClearAll}
          styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
          disabled={loading}
        />
        <CustomButton
          type='primary'
          onClick={handleSubmit}
          disabled={loading}
          leftIcon={
            loading ? (
              <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
            ) : (
              <div />
            )
          }
          title='Search'
          className='ms-3'
          styleButton={{ padding: '5px 15px', margin: 0, fontSize: 14 }}
        />
      </div>
    </SWrapper>
  );
};

export default AdvancedFilter;
