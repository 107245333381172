import moment from 'moment';
import uuid from 'react-uuid';

export const attemptInitialValues = {
  isNew: true,
  id: uuid(),
  request_date: new Date(),
  request_form_path: '',
  request_form_source: 'Email',
  response: '',
  email: '',
  fax: '',
  request_type: 'Pdf',
  response_path: '',
  haveNote: false,
  noteTitle: '',
  noteDate: new Date(),
  noteNotes: '',
  sent_date: null,
};

export const getInitialValues = (previousEmployments, applicantPrevEmployment) => {
  if (previousEmployments?.length) {
    return previousEmployments.map((previousEmployment) => {
      const {
        id,
        start_date,
        end_date,
        name,
        staff_employment_attempts,
        driver_employment_attempts,
        applicant_employment_attempts,
      } = previousEmployment || {};
      const attempts = staff_employment_attempts || driver_employment_attempts || applicant_employment_attempts;

      return {
        id,
        name: name || '',
        start_date: start_date ? moment(start_date).toDate() : null,
        end_date: end_date ? moment(end_date).toDate() : null,
        attempts: attempts
          ? attempts.map((attempt) => ({
              id: attempt.id,
              request_date: attempt.request_date ? moment(attempt.request_date).toDate() : new Date(),
              request_form_path: attempt.request_form_path || '',
              request_form_source: attempt.request_form_source || 'Email',
              response: attempt.response || '',
              email: attempt.email || '',
              fax: attempt.fax || '',
              request_type: attempt.request_type || 'Pdf',
              response_path: attempt.response_path || '',
              haveNote: false,
              noteTitle: '',
              noteDate: new Date(),
              noteNotes: '',
              sent_date: attempt.sent_date ? moment(attempt.sent_date).toDate() : null,
            }))
          : [attemptInitialValues],
      };
    });
  }

  if (applicantPrevEmployment?.length) {
    return applicantPrevEmployment.map((previousEmployment) => {
      const {
        employer_name,
        request_date,
        start_date,
        end_date,
        email,
        fax,
        phone_no,
        request_form_path,
        request_type,
        response,
        response_path,
      } = previousEmployment || {};

      return {
        isNew: true,
        name: employer_name || '',
        start_date: start_date ? moment(start_date).toDate() : null,
        end_date: end_date ? moment(end_date).toDate() : null,
        attempts: [
          {
            id: uuid(),
            request_date: request_date ? moment(request_date).toDate() : new Date(),
            request_form_path: request_form_path || '',
            request_form_source: 'Email',
            response: response || '',
            email: email || '',
            fax: fax || phone_no || '',
            request_type: request_type || 'Pdf',
            response_path: response_path || '',
            haveNote: false,
            noteTitle: '',
            noteDate: new Date(),
            noteNotes: '',
            sent_date: null,
          },
        ],
      };
    });
  }

  return [
    {
      isNew: true,
      name: '',
      start_date: null,
      end_date: null,
      attempts: [attemptInitialValues],
    },
  ];
};
