import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'context/themeContext';
import { getAlphabet, palette, statusColorInStatusId } from 'utils/constants';
import { Modal } from 'react-bootstrap';
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import person from 'assets/images/person.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';
import { InputWithIcon } from 'common/Input';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { validateRequired } from 'components/AddEditModalStops/validates';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right.svg';
import { driverCompansationData } from 'Api/Driver';
import { convertToCustomerTime } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import classes from './Layover.module.scss';

const styles = {
  styleBody: {
    maxHeight: 600,
    overflowY: 'auto',
    padding: '20px',
  },
  modal: {
    width: 440,
    top: '50%',
    left: '50%',
    boxShadow: 24,
    borderRadius: '8px',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
  },
  modalBody: {
    padding: 0,
    borderRadius: 6,
    backgroundColor: palette.white,
  },
  labelStyle: {
    margin: 0,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  inputDriver: {
    height: 32,
    width: '100%',
    borderRadius: '4px',
  },
  selectStyle: {
    height: 32,
    width: 221,
    borderRadius: '4px 0 0 4px',
    marginLeft: 36,
  },
  dropDownStyles: {
    flexGrow: 0,
    width: 221,
  },
  menuStyle: {
    width: 221,
    marginLeft: 36,
  },
  datePicker: {
    minWidth: 221,
    margin: 0,
    justifyContent: 'flex-start',
  },
};

const Layover = ({ isOpen, setIsOpen, onAddBtn, data, activeStop, loading }) => {
  const { brokerage_dispatch } = data || {};
  const { carrier } = brokerage_dispatch || {};
  const { use } = useTheme();
  const { formatDateTime } = useDateFormat();
  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );

  const [formValues, setFormValues] = useState({
    amount: '',
    pay_driver: false,
    driver: '',
    date_switch: false,
    date_to: new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
    select_stop: '1',
    shipment_stop: activeStop?.id,
    driver_pays: [
      { driver_id: data?.assigned_to_1?.id, driver_number: '' },
      ...(data?.assigned_to_2 ? [{ driver_id: data?.assigned_to_2?.id, driver_number: '' }] : []),
    ],
  });

  const errorRequired = (error) => {
    return (
      <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
        {error}
      </Typography>
    );
  };

  useEffect(() => {
    if (data?.assigned_to_1?.id) {
      driverCompansationData({ id: data.assigned_to_1.id }).then((res) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          driver_pays: [
            { ...prevValues.driver_pays[0], driver_number: res.data.layover_amt },
            ...(prevValues.driver_pays?.[1] ? [prevValues.driver_pays?.[1]] : []),
          ],
        }));
      });
    }
    if (data?.assigned_to_2?.id) {
      driverCompansationData({ id: data.assigned_to_2.id }).then((res) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          driver_pays: [
            prevValues.driver_pays[0],
            { ...prevValues.driver_pays[1], driver_number: res.data.layover_amt },
          ],
        }));
      });
    }
  }, [data?.assigned_to_1?.id]);

  const getStopName = (type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: use(palette.green500, palette.green400), title: 'pick up' },
      2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500), title: 'Delivery' },
      3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText), title: 'Waypoint' },
    };
    return TYPE_STOPS[type];
  };

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Formik initialValues={{ ...formValues }} enableReinitialize onSubmit={() => {}}>
        {({ values, errors, touched, resetForm, submitForm, validateForm, setFieldValue }) => {
          return (
            <Form>
              <Modal.Body style={styles.modalBody}>
                <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <Typography variant='button2'>Add Layover to Shipment</Typography>
                  <Typography variant='overLine'>{subTitle}</Typography>
                </div>

                <div
                  className={classes.modalBody}
                  style={{
                    backgroundColor: use(palette.gray0, palette.dark800),
                    borderColor: use(palette.gray50, palette.gray700),
                  }}
                >
                  <div className={classes.equipmentActionWrapper}>
                    <div>
                      <Typography variant='s2'>Stops</Typography>

                      <div>
                        {data.shipment_stops.map((item, index) => {
                          return (
                            <FastField
                              value={index}
                              dissabled
                              type='radio'
                              name='select_stop'
                              component={(props) => (
                                <CustomRadioButton
                                  {...props}
                                  field={{
                                    checked: index === activeStop?.index,
                                  }}
                                >
                                  <Typography variant='s2' style={{ marginLeft: 8 }}>
                                    Stop {getAlphabet(index)} {getStopName(item.stop_point_type)?.type}
                                  </Typography>
                                </CustomRadioButton>
                              )}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className={classes.errorWrapper}>
                    <Field
                      name='amount'
                      type='number'
                      placeholder='0.00'
                      component={CustomInput}
                      label='Layover Amount'
                      required
                      labelStyle={styles.labelStyle}
                      validate={validateRequired}
                      style={{ width: '100%', borderRadius: 6, height: 32 }}
                      leftIcon={<DollarIcon height={32} />}
                    />
                    <ErrorMessage
                      name='amount'
                      render={(error) => (
                        <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                          {error}
                        </Typography>
                      )}
                    />
                  </div>

                  <div className={classes.switcherWrapper}>
                    <div>
                      <Field name='pay_driver'>
                        {({ field, form }) => (
                          <CustomCheckbox
                            name='pay_driver'
                            field={field}
                            form={form}
                            type='switch'
                            smail={false}
                            withinForm
                            checked={!!field.value}
                            style={{ marginTop: -6 }}
                          />
                        )}
                      </Field>
                    </div>
                    {carrier ? (
                      <Typography variant='s2'>Pay Carrier for Layover</Typography>
                    ) : (
                      <Typography variant='s2'>Pay Driver for Layover</Typography>
                    )}
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    {values.driver_pays.map((item, index) => (
                      <div className={classes.fieldsWrapper}>
                        {carrier ? (
                          <div className={classes.errorWrapper}>
                            <Typography className={classes.name} variant='s2'>
                              {carrier.name}
                            </Typography>
                            <ArrowRightIcon className={classes.icon} />
                          </div>
                        ) : (
                          <div className={classes.errorWrapper}>
                            <div className={classes.imgWrapper}>
                              <img src={data?.[`assigned_to_${index + 1}`]?.image || person} alt='driver' />
                              <div
                                className={classes.statusImg}
                                style={{
                                  backgroundColor:
                                    statusColorInStatusId[data?.[`assigned_to_${index + 1}`]?.status]?.color,
                                }}
                              />
                            </div>
                            <Typography className={classes.name} variant='s2'>
                              {data?.[`assigned_to_${index + 1}`]?.fname} {data?.[`assigned_to_${index + 1}`]?.lname}
                            </Typography>
                            <ArrowRightIcon className={classes.icon} />
                          </div>
                        )}
                        <div className={classes.inputWrapper}>
                          <InputWithIcon
                            type='number'
                            placeholder='0.00'
                            name={`driver_pays[${index}].driver_number`}
                            value={values.driver_pays[index].driver_number}
                            onChange={(e) => setFieldValue(`driver_pays[${index}].driver_number`, e.target.value)}
                            disabled={!values.pay_driver}
                          />
                          <ErrorMessage name={`driver_pays[${index}].driver_number`} render={errorRequired} />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className={classes.switcherWrapper}>
                    <div>
                      <Typography variant='s2'>
                        Reschedule date from {formatDateTime(`${activeStop?.scheduled_date} ${activeStop?.from}`)} to
                      </Typography>
                    </div>
                  </div>
                  <DateTimePicker
                    name='date_to'
                    value={values.date_to}
                    onChange={(date) => setFieldValue('start_date', date)}
                    error={touched?.start_date && errors?.start_date}
                    minDate={new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm'))}
                  />
                </div>

                {/* // footer */}
                <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <div
                    className={classes.modalFooterLane}
                    style={{ backgroundColor: use(palette.white, palette.dark900) }}
                  >
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title='Add Layover'
                      onClick={() => onAddBtn(validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                      disabled={loading}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Layover;
