import React from 'react';
import moment from 'moment';
import Chip from 'common/Chip';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { absencseStatusColor, palette } from 'utils/constants';
import RowActions from './components/RowActions';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  tab: 0,
  sort: { field: '', sortBy: 'desc' },
};

export const settingsDataMapper = (key) => {
  const map = {
    staff: 'Name',
    absence_date: 'Absence Date',
    reason_title: 'Reason',
    type: 'Type',
    internal_notes: 'Internal Notes',
    updated_by: 'Updated By',
  };
  return map[key] ? map[key] : key;
};

export const useColumns = ({ sort, sortingQuery }) => {
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'staff',
      title: <ColumnHeader title='Name' field='name' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <UserInfo
          size='46px'
          data={{
            ...(rowData || {}),
            id: rowData.driver_staff_id,
          }}
        />
      ),
    },
    {
      field: 'absence_date',
      title: <ColumnHeader title='ATTENDANCE DATE' field='absence_start_date' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => {
        const a = moment(rowData?.absence_start_date);
        const b = moment(rowData?.absence_end_date);
        const day = Math.round(moment.duration(b.diff(a)).asDays());
        return (
          <div>
            <div className='mb-1'>
              <Chip label={formatDate(`${rowData?.absence_start_date}`)} /> -{' '}
              <Chip label={formatDate(`${rowData?.absence_end_date}`)} />
            </div>
            <Typography variant='c2' style={{ color: palette.gray700 }}>
              {day === 0 ? '1 Day' : day ? `${day + 1} Days` : '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'reason_title',
      title: <ColumnHeader title='REASON' field='reason_title' />,
      render: (rowData) => (
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {rowData?.request_status === 'Time Off' ? rowData?.driver_notes : rowData?.reason ? rowData?.reason : '-'}
        </Typography>
      ),
    },

    {
      field: 'type',
      title: <ColumnHeader title='TYPE' field='type' />,
      render: (rowData) => (
        <Chip
          label={rowData.absence_type?.name?.toUpperCase() || rowData?.type?.toUpperCase()}
          labelColor={absencseStatusColor[rowData?.type]?.color}
          bgColor={absencseStatusColor[rowData?.type]?.bgColor}
        />
      ),
    },
    {
      field: 'internal_notes',
      title: <ColumnHeader title='INTERNAL NOTES' field='internal_notes' />,
      render: (rowData) => (
        <p
          className='table-data-text'
          style={{
            color: palette.gray700,
            whiteSpace: 'break-spaces',
            minWidth: rowData?.internal_notes?.length > 40 ? '440px' : '',
          }}
        >
          {rowData?.internal_notes || '-'}
        </p>
      ),
    },
    {
      field: 'updated_by',
      title: <ColumnHeader title='UPDATED BY' field='updated_by' />,
      render: (rowData) => (
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {rowData?.user_details ? `By ${rowData?.user_details}` : ''}
        </Typography>
      ),
    },
  ];
};

export const actionsColumn = ({ onView, onDelete, onDownload }) => {
  return {
    field: 'actions',
    render: (rowData) => (
      <RowActions
        row={rowData}
        onView={() => onView(rowData)}
        onDelete={() => onDelete(rowData)}
        onDownload={() => onDownload(rowData)}
      />
    ),
  };
};
