import React, { useState, useMemo, useEffect, Fragment } from 'react';
import { Formik, Form } from 'formik';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import './PositionApplications.css';
import uuid from 'react-uuid';
import CustomizedSnackbars from 'components/toast/Toast';
import upDown from 'assets/icons/drivers/up-down.png';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import cross from 'assets/icons/drivers/secondClose.svg';
import CustomButton from 'components/CustomButton/CustomButton';
import PositionApplicationsServices from 'services/companySettingsServices';
import { createJobRequirement, deleteJobRequirement, getJobRequirements } from 'Api/JobPositions';
import { SAddMore } from 'components/Carriers/AddCarrier/AddCarrier.styles';
import Input from 'common/Input';
import styles from '../companyProfile/CompanyProfile.module.css';

const PositionsApplications = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  return (
    <Formik
      onSubmit={() => {}}
      initialValues={{
        company_name: 'CERA Enterprises Corp',
        dot_number: '777999',
        telephone_number: '15555551234',
        fax_number: '',
        address1: '',
        address2: '',
      }}
    >
      {(props) => (
        <PositionsApplicationsForm
          {...props}
          isConfiguringStep={isConfiguringStep}
          onSaveAndNext={onSaveAndNext}
          onCancelPrevPage={onCancelPrevPage}
        />
      )}
    </Formik>
  );
};

function PositionsApplicationsForm({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) {
  const [QuestionComp, setQuestionComp] = useState([]);

  const [settingsData, setSettingsData] = useState(null);
  const [applicantage, setApplicantage] = useState('');
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [handleQuestionInput, setHandleQuestionInput] = useState({});
  const [requirements, setRequirements] = useState([]);

  const [showErr, setshowErr] = useState(false);
  const [showMessage, setShowMessage] = useState({ message: '', visible: false, type: 'success' });

  const companyProfileStyle = useMemo(() => {
    return {
      globalLabel: {
        width: 350,
        margin: 0,
        color: palette.gray700,
      },
      badge: {
        color: palette.gray600,
      },
    };
  }, [palette]);

  const updateJobApplicationSettings = () => {
    const data = {
      applicant_age: applicantage,
    };
    PositionApplicationsServices.updateJobApplicationSettings(data).then((res) => {
      setSettingsData(res.data.data);
      setShowMessage({ message: 'Changes have been successfully saved!', visible: true, type: 'success' });
    });
  };

  const getRequirements = async () => {
    try {
      const { data } = await getJobRequirements();
      setRequirements(data);
    } catch (e) {
      // Do nothing
    }
  };

  const createRequirements = async () => {
    const requirementsToCreate = requirements.filter((i) => !!i.title);

    if (!requirementsToCreate.length) {
      return;
    }

    try {
      const body = requirementsToCreate.map((item) => ({ id: item.isNew ? 'new' : item.id, title: item.title }));
      const { data } = await createJobRequirement(body);
      setRequirements(data);
    } catch (e) {
      // Do nothing
    }
  };

  const deleteRequirement = async (item) => {
    try {
      if (!item.isNew) {
        await deleteJobRequirement(item.id);
      }
      setRequirements((prevRequirements) => prevRequirements.filter((i) => i.id !== item.id));
    } catch (e) {
      // Do nothing
    }
  };

  const saveQuestions = (data) => {
    PositionApplicationsServices.addQuestionnaire(data)
      .then(() => {
        getQuestionnaire();
        setHandleQuestionInput({});
        setQuestionComp([]);
      })
      .catch(() => setshowErr(true));
  };

  const handleSubmit = () => {
    const keys = Object.keys(handleQuestionInput);
    keys.forEach((k) => {
      const val = handleQuestionInput[k];
      if (val === null) {
        setshowErr(true);
      }
    });
    if (!showErr) {
      updateJobApplicationSettings();
      saveQuestions(handleQuestionInput);
      createRequirements();
      if (isConfiguringStep) {
        onSaveAndNext();
      }
    }
  };

  const handleDelete = (idx) => {
    setQuestionComp(QuestionComp.filter((e) => e !== idx));
  };

  const addQuestions = () => {
    const Id = uuid();
    const obj = {};
    obj[`id_${questionnaireData.length + QuestionComp.length + 1}`] = 'new';
    obj[`question_${questionnaireData.length + QuestionComp.length + 1}`] = null;
    obj[`option_${questionnaireData.length + QuestionComp.length + 1}`] = null;
    setHandleQuestionInput({ ...handleQuestionInput, ...obj });
    setQuestionComp([...QuestionComp, Id]);
    setshowErr(false);
  };

  const getQuestionnaire = () => {
    PositionApplicationsServices.getQuestionnaire().then((res) => {
      setQuestionnaireData(res.data.data);
      const obj = {};
      res.data?.data.forEach((item, i) => {
        obj[`id_${i + 1}`] = item.id;
        obj[`question_${i + 1}`] = item.question;
        obj[`option_${i + 1}`] = item.option;
      });
      setHandleQuestionInput(obj);
    });
  };

  const getSettings = () => {
    PositionApplicationsServices.getJobApplicationSettings().then((res) => setSettingsData(res.data.data));
  };

  const handleDeleteEmptyBox = (data, key) => {
    setQuestionComp(QuestionComp.filter((ele) => ele !== data));
    const newHandleQuestionInput = handleQuestionInput;

    key.forEach((k) => {
      delete newHandleQuestionInput[k];
    });
    setHandleQuestionInput(newHandleQuestionInput);
    setshowErr(false);
  };

  useEffect(() => {
    getQuestionnaire();
    getSettings();
    getRequirements();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (settingsData !== null) {
      setApplicantage(settingsData.applicant_age);
    }
  }, [settingsData]);

  return (
    <Form>
      <div className={`${styles.companyProfile_wrapper} mb-5`}>
        <div className={styles.companyProfile_header}>
          <Typography variant='h2' style={{ color: palette.gray900 }}>
            Positions and Applications
          </Typography>

          <div className={styles.actions}>
            {isConfiguringStep && (
              <>
                {' '}
                <CustomButton
                  onClick={onCancelPrevPage}
                  type='secondary'
                  title='Back'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
                <CustomButton
                  onClick={onSaveAndNext}
                  type='secondary'
                  title='Skip'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
              </>
            )}
            <CustomButton
              onClick={handleSubmit}
              type='primary'
              title={isConfiguringStep ? 'Save and Next' : 'Save'}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ margin: 0 }}
            />
          </div>
        </div>
        <div className={styles.logo_wrapper}>
          <Typography variant='s2' style={companyProfileStyle.globalLabel}>
            Minimum Applicant Age to Apply
          </Typography>
          <div className='apply-age'>
            <input
              type='text'
              placeholder='age'
              value={applicantage}
              onChange={(e) => setApplicantage(e.target.value)}
              className='age'
            />{' '}
            years
          </div>
        </div>
        <div className={styles.companyProfile_header}>
          <Typography variant='h2' style={{ color: palette.gray900 }}>
            Requirements
          </Typography>
        </div>
        {requirements.map((item) => (
          <Fragment key={item.id}>
            {item.isNew || item.isEdit ? (
              <div
                className={styles.basic_wrapper}
                style={{ padding: '6px 4px', display: 'flex', justifyContent: 'space-between', columnGap: '32px' }}
                key={item.id}
              >
                <Input
                  className='w-50'
                  placeholder='Requirement'
                  value={item.title}
                  onChange={(e) =>
                    setRequirements((prevState) =>
                      prevState.map((i) => (i.id === item.id ? { ...i, title: e.target.value } : i))
                    )
                  }
                />
                <DeleteIcon
                  fill={item.isNew ? palette.red500 : palette.gray700}
                  width={12}
                  height={12}
                  className='pointer'
                  onClick={() =>
                    !item.isNew
                      ? setRequirements((prevState) =>
                          prevState.map((i) => (i.id === item.id ? { ...i, isEdit: false } : i))
                        )
                      : deleteRequirement(item)
                  }
                />
              </div>
            ) : (
              <div
                className={styles.basic_wrapper}
                style={{ padding: '12px 4px', display: 'flex', justifyContent: 'space-between', columnGap: '32px' }}
                key={item.id}
              >
                <Typography variant='s2'>{item.title}</Typography>
                {!item.default && (
                  <div className='d-flex align-items-center gap-3'>
                    <PencilIcon
                      width={14}
                      height={14}
                      className='pointer'
                      onClick={() =>
                        setRequirements((prevState) =>
                          prevState.map((i) => (i.id === item.id ? { ...i, isEdit: true } : i))
                        )
                      }
                    />
                    <DeleteIcon
                      fill={palette.red500}
                      width={12}
                      height={12}
                      className='pointer'
                      onClick={() => deleteRequirement(item)}
                    />
                  </div>
                )}
              </div>
            )}
          </Fragment>
        ))}
        {/* <SAddMore */}
        {/*  onClick={() => setRequirements((prevState) => [...prevState, { isNew: true, title: '', id: uuid() }])} */}
        {/* > */}
        {/*  <PlusIcon /> */}
        {/*  <Typography variant='s2' style={{ color: palette.indigo500 }}> */}
        {/*    Add Another */}
        {/*  </Typography> */}
        {/* </SAddMore> */}

        <div className={styles.companyProfile_header}>
          <Typography variant='h2' style={{ color: palette.gray900 }}>
            Applicant Questions
          </Typography>
        </div>
        <div className={styles.companyProfile_header}>
          <Typography variant='s2' style={{ color: palette.gray900 }}>
            Question
          </Typography>
          <Typography
            variant='s2'
            style={{
              color: palette.gray900,
              width: '20.5%',
              textAlign: 'left',
            }}
          >
            Option
          </Typography>
        </div>
        <div>
          <div>
            {questionnaireData.map((ele, index) => (
              <Fragment key={ele.id}>
                {ele.default ? (
                  <div className={styles.companyProfile_header}>
                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                      {ele.question}
                    </Typography>
                    <Typography variant='s2' style={{ color: palette.gray700, width: '20.5%', textAlign: 'left' }}>
                      {ele.option}
                    </Typography>
                  </div>
                ) : (
                  <AddQuestions
                    data={ele}
                    handleDelete={handleDelete}
                    getQuestionnaire={getQuestionnaire}
                    setHandleQuestionInput={setHandleQuestionInput}
                    handleIndex={index + 1}
                    showErr={showErr}
                    setshowErr={setshowErr}
                  />
                )}
              </Fragment>
            ))}
            {QuestionComp.map((ele, idx) => (
              <AddQuestions
                data={ele}
                handleDelete={handleDelete}
                getQuestionnaire={getQuestionnaire}
                addques
                handleDeleteEmptyBox={handleDeleteEmptyBox}
                setHandleQuestionInput={setHandleQuestionInput}
                handleIndex={questionnaireData.length + idx + 1}
                showErr={showErr}
                setshowErr={setshowErr}
              />
            ))}
          </div>

          <SAddMore onClick={addQuestions}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another
            </Typography>
          </SAddMore>
        </div>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </Form>
  );
}

export default PositionsApplications;

const AddQuestions = ({
  getQuestionnaire,
  data,
  addques,
  handleDeleteEmptyBox,
  setHandleQuestionInput,
  handleIndex,
  showErr,
  setshowErr,
}) => {
  const [inputVal, setinputVal] = useState(addques ? '' : data?.question);
  const [option, setOption] = useState(null);

  const handleDelete = () => {
    PositionApplicationsServices.deleteQuestionnaire(data?.id).then(() => getQuestionnaire());
  };

  const handleSelect = (value) => {
    setOption(value);
    if (addques) setshowErr(false);
  };

  useEffect(() => {
    setHandleQuestionInput((prevState) => ({
      ...prevState,
      [`question_${handleIndex}`]: inputVal,
    }));
    setshowErr(false);
  }, [inputVal]);

  useEffect(() => {
    setHandleQuestionInput((prevState) => ({
      ...prevState,
      [`option_${handleIndex}`]: option,
    }));
  }, [option]);

  return (
    <>
      <div className='question-box'>
        <input type='text' onChange={(e) => setinputVal(e.target.value)} value={inputVal} />
        <SelectBox
          options={['Yes or No', 'Line Reply', 'Multi-Line Reply']}
          onChange={handleSelect}
          autoSelect={data?.option}
        />
        {!addques && <img src={cross} alt='' onClick={handleDelete} />}

        {addques &&
          (inputVal?.length === 0 ? (
            <img
              src={cross}
              alt=''
              onClick={() =>
                handleDeleteEmptyBox(data, [`option_${handleIndex}`, `question_${handleIndex}`, `id_${handleIndex}`])
              }
            />
          ) : (
            <img
              src={cross}
              alt=''
              onClick={() =>
                handleDeleteEmptyBox(data, [`option_${handleIndex}`, `question_${handleIndex}`, `id_${handleIndex}`])
              }
            />
          ))}
      </div>
      {showErr && inputVal === null && option !== null && (
        <p className='requiredMessage'>Please complete this question or remove it.</p>
      )}
      {showErr && option === null && inputVal !== null && (
        <p className='requiredMessage'>Please complete this question or remove it.</p>
      )}
      {showErr && option === null && inputVal === null && (
        <p className='requiredMessage'>Please complete this question or remove it.</p>
      )}
    </>
  );
};

const SelectBox = ({ options, onChange, autoSelect }) => {
  const [showOption, setshowOption] = useState(false);
  const [option, setoption] = useState(autoSelect || 'Select Options ...');
  const handleClick = (opt) => {
    setoption(opt);
    onChange(opt);
    setshowOption(!showOption);
  };

  return (
    <div className='company-setting-select'>
      <div
        className='company-setting-select-box'
        onClick={() => setshowOption(!showOption)}
        style={{ width: '270px', borderRadius: '0px 6px 6px 0px' }}
      >
        <span className='selected-option'>{option}</span>
        <img src={upDown} alt='' className='updown-img' />
      </div>
      {showOption && (
        <ul className='options'>
          {options.map((o) => (
            <li onClick={() => handleClick(o)}>{o}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
