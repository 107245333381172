import React from 'react';
import Divider from 'common/Divider';
import PageHeader from './components/PageHeader';
import ReferralsTable from './components/ReferralsTable';
import { SPageWrapper } from './ApplicantsAndReferrals.styles';

export const Referrals = () => {
  return (
    <SPageWrapper>
      <PageHeader title='Referrals' showCopyUrl={false} hideAction />

      <Divider />
      <ReferralsTable />
    </SPageWrapper>
  );
};
