import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 18px;

  input {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 1;
    cursor: pointer;
  }

  label {
    margin: 0;
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .disabled {
    cursor: no-drop;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: ${({ $disabled }) => ($disabled ? palette.gray0 : palette.white)};
    border-radius: 50%;
    margin-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
    transition: 0.2s all ease-in-out;
  }

  :not(.disabled):hover .checkmark:after {
    background-color: ${palette.gray100};
    opacity: 1;
  }

  :hover input:checked ~ .checkmark:after {
    background-color: ${palette.white};
  }

  input:checked ~ .checkmark {
    background-color: ${({ $disabled }) => ($disabled ? palette.gray100 : palette.indigo500)};
    box-shadow: ${({ $disabled }) =>
      $disabled
        ? ''
        : '0 1px 1px rgba(0, 0, 0, 0.14), 0 0 0 1px #4f5aed, 0 2px 5px rgba(79, 90, 237, 0.1), inset 0 1px 0 #767ffb'};
  }

  input:checked ~ .checkmark:after {
    opacity: 1;
  }

  .checkmark:after {
    content: '';
    opacity: 0;
    overflow: hidden;
    transition: 0.2s all ease-in-out;
  }

  input:checked ~ .checkmark:after {
    display: flex;
  }

  .checkmark:after {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${palette.white};
  }
`;
