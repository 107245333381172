import { array, mixed, number, object, string } from 'yup';

export const validationSchema = array().of(
  object().shape({
    reference_id: string().trim().required('Reference is required'),
    type: object()
      .shape({ id: number().required('Type is required') })
      .required('Type is required')
      .nullable(),
    doc: mixed().required('File is required'),
  })
);
