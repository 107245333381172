import React from 'react';
import { ReactComponent as Delete } from 'assets/icons/xClose.svg';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, formatAmount } from 'utils/helpers';
import { SActions, SWrapper } from '../CostModal.styles';

const ItemForm = ({ additions, onDelete, item, driver, values, handleChange, touchedErrors, index }) => {
  return (
    <SWrapper>
      {item.isNew ? (
        <div className='flex-item'>
          <Autocomplete
            name='addition_type'
            labelKey='addition_type'
            options={additions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values[index].addition}
            onChange={(e, value) => {
              handleChange(`${index}`, {
                ...values[index],
                addition: value,
                qty: 1,
                rate: Number(value.amount),
                total: Number(value.amount),
              });
            }}
            error={touchedErrors[index]?.addition}
          />
        </div>
      ) : (
        <div className='flex-item'>
          <Typography variant='s2' style={{ color: palette.gray900 }}>
            {item.addition.addition_type}
          </Typography>
        </div>
      )}
      <div className='flex-item'>
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {driver.fname} {driver.lname}
        </Typography>
      </div>
      <div className='flex-item'>
        <Input
          type='number'
          name='qty'
          onChange={(e) => {
            handleChange(`${index}`, {
              ...values[index],
              qty: Number(e.target.value),
              total: Number(e.target.value) * Number(values[index].rate),
            });
          }}
          value={values[index].qty}
          placeholder='Qty'
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors[index]?.qty}
        />
      </div>
      <div className='flex-item'>
        <Input
          type='number'
          name='rate'
          onChange={(e) => {
            handleChange(`${index}`, {
              ...values[index],
              rate: Number(e.target.value),
              total: Number(e.target.value) * Number(values[index].qty),
            });
          }}
          value={values[index].rate}
          placeholder='Rate'
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors[index]?.rate}
        />
      </div>
      <div className='flex-item'>
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {formatAmount(values[index].total || 0)}
        </Typography>
      </div>
      <SActions className='flex-item'>
        {!values[index].isDefault && (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title=''
            leftIcon={<Delete style={{ fill: '#464F60' }} />}
            styleButton={{ padding: '7px 8px', margin: 0 }}
            onClick={onDelete}
          />
        )}
      </SActions>
    </SWrapper>
  );
};

export default ItemForm;
