import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, FastField, Field } from 'formik';
import { getAlphabet, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as CalendarIcon } from 'assets/icons/createShipment/calendar.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { validateRequired } from 'components/AddEditModalStops/validates';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import classes from '../../detention.module.scss';

const DetentionStop = ({ values, setValues, data, name, detention }) => {
  const { use } = useTheme();
  const isTrailer = data?.equipment?.equipment_type?.vehicle_type_id === 2;
  const [options] = useState([
    { key: 1, label: 'Hour(s)', labelSelected: null },
    { key: 2, label: 'DAYS(s)', labelSelected: null },
  ]);
  const { duration_days, duration_hours, duration_minutes } = detention || {};

  const getStopName = (type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: use(palette.green500, palette.green400) },
      2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500) },
      3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText) },
    };
    return TYPE_STOPS[type];
  };
  useEffect(() => {
    getPaymentTerms();
  }, []);

  const getPaymentTerms = async () => {
    try {
      const rate = isTrailer
        ? data?.shipment_billing?.[0]?.billing_customer?.customer_payment_term?.detention_trailer_dropped_billed_at
        : data?.shipment_billing?.[0].billing_customer?.customer_payment_term?.billed_at;
      const daysHours = isTrailer
        ? data?.shipment_billing?.[0]?.billing_customer?.customer_payment_term
            ?.detention_trailer_dropped_start_after_per
        : 'HOURS';
      const duration =
        daysHours === 'HOURS'
          ? (duration_days * 24 + duration_hours + duration_hours / 60).toFixed(2)
          : (duration_days + duration_hours / 24 + duration_minutes / 1440).toFixed(2);

      setValues((prevValues) => ({
        ...prevValues,
        [name]: {
          ...prevValues[name],
          bill_rate: rate,
          bill_hour: duration,
          bill_total: duration * rate,
          bill_rate_time: daysHours === 'DAYS' ? options[1].key : options[0].key,
        },
      }));
    } catch (e) {
      // Do nothing
    }
  };

  const styles = useMemo(() => {
    return {
      datePicker: {
        width: 206,
        margin: 0,
        justifyContent: 'flex-start',
      },
      labelInput: {
        marginTop: '8',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    };
  }, [use, palette]);

  const eventsJsx = useMemo(() => {
    const shipmentStop = data.shipment_stops.find((item) => item.id === detention.shipment_stop);
    const shipmentStopIndex = data.shipment_stops.findIndex((item) => item.id === detention.shipment_stop);
    return (
      <div className={classes.switchTextWrapper}>
        <Typography variant='s1'>
          <Typography variant='s1' style={{ color: getStopName(shipmentStop.stop_point_type).color }}>
            STOP {getAlphabet(shipmentStopIndex)}
            {getStopName(shipmentStop.stop_point_type).type}
          </Typography>
          <Typography variant='s1' style={{ color: palette.red500 }}>
            {' '}
            ({detention?.duration_days}d {detention?.duration_hours}h {detention?.duration_minutes}m)
          </Typography>
        </Typography>
        <Typography variant='b2'>
          {shipmentStop?.stop_point?.location_name} ({shipmentStop?.stop_point?.address1},{' '}
          {shipmentStop?.stop_point?.city?.name}, {shipmentStop?.stop_point?.state?.short_name}{' '}
          {shipmentStop?.stop_point?.zipcode})
        </Typography>
      </div>
    );
  }, [data]);

  return (
    <div>
      <div className={classes.switchWrapper}>
        <Field name={name}>
          {({ field, form }) => (
            <CustomCheckbox
              name={`${name}.checked`}
              field={field}
              form={form}
              type='switch'
              smail={false}
              withinForm
              style={{ marginTop: -6 }}
              // onChange={(v) => setValues({...values, checked: v})}
            />
          )}
        </Field>
        {eventsJsx}
      </div>
      {values?.[name]?.checked && (
        <div className={classes.childContainer}>
          <div className={classes.datesWrapper}>
            <div className={classes.datePickerDataWrapper} style={{ marginRight: 24 }}>
              <Typography variant='s2'>Scheduled Date/Time</Typography>
              <div className={classes.datePickerData}>
                <CalendarIcon style={{ flexShrink: 0, marginRight: 8 }} />
                <Typography style={{ whiteSpace: 'nowrap' }} variant='c1'>
                  03/12/2022, 17:00
                </Typography>
              </div>
            </div>

            <div className={classes.errorWrapper} style={{ marginRight: 23 }}>
              <FastField name={`${name}.arrival_date`}>
                {({ field, form }) => (
                  <CustomDatePicker
                    field={field}
                    form={form}
                    style={styles.datePicker}
                    label='Arrival Date/Time'
                    disabled
                    // minDate={moment().toDate()}
                    // onChange={onChangeTimeValidate}
                    // onCalendarOpen={()=>scrollRef?.current?.scrollIntoView({behavior: "smooth", block: "start"})}
                  />
                )}
              </FastField>
              <ErrorMessage
                name={`${name}.arrival_date`}
                render={(error) => (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                )}
              />
            </div>

            <FastField name={`${name}.departure_date`}>
              {({ field, form }) => (
                <CustomDatePicker
                  field={field}
                  form={form}
                  label='Departure Date/Time'
                  style={styles.datePicker}
                  disabled
                />
              )}
            </FastField>
          </div>

          {/* // Bill Detention */}
          <div className={classes.detentionWrapper}>
            <div className={classes.switcherWrapper}>
              <div>
                <Field name={`${name}.bill_detention`}>
                  {({ field, form }) => (
                    <CustomCheckbox
                      name={`${name}.bill_detention`}
                      field={field}
                      form={form}
                      type='switch'
                      smail={false}
                      disabled={!values?.[name].departure_date}
                      withinForm
                      checked={!!field.value}
                      style={{ marginTop: -6 }}
                      // onChange={(v) => onChangeBillDetention(name, v, setValues, values)}
                    />
                  )}
                </Field>
              </div>
              <Typography variant='s2'>Bill Detention</Typography>
            </div>
            {values?.[name]?.bill_detention && (
              <>
                <div className={classes.topWrapperDetention}>
                  <div className={classes.twoFieldsWrapper}>
                    <div className={classes.detentionFields}>
                      <div className={classes.errorWrapper} style={{ width: '100%', maxWidth: 206 }}>
                        <FastField
                          name={`${name}.bill_rate`}
                          required
                          type='number'
                          label='Bill Rate'
                          component={CustomInput}
                          validate={validateRequired}
                          labelStyle={styles.labelInput}
                          className={classes.customInputField}
                          leftIcon={<DollarIcon height={32} />}
                          style={{ borderRadius: '6px 0 0 6px', width: 114 }}
                        />
                        <ErrorMessage
                          name={`${name}.bill_rate`}
                          render={(error) => (
                            <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                              {error}
                            </Typography>
                          )}
                        />
                      </div>
                    </div>
                    <div className={classes.detentionTimeSelect}>
                      <FastField
                        name={`${name}.bill_rate_time`}
                        component={CustomSelect}
                        styles={{ height: 32, borderRadius: '0 6px 6px 0' }}
                        options={options}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={classes.errorWrapper} style={{ marginTop: -16, marginLeft: 20 }}>
                    <FastField
                      required
                      name={`${name}.bill_hour`}
                      component={CustomInput}
                      label='Detention Hour'
                      validate={validateRequired}
                      labelStyle={styles.labelInput}
                      style={{ width: 195 }}
                      className={classes.customInputField}
                    />
                    <ErrorMessage
                      name={`${name}.bill_hour`}
                      render={(error) => (
                        <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                          {error}
                        </Typography>
                      )}
                    />
                  </div>
                  <div className={classes.errorWrapper} style={{ marginTop: -16, marginLeft: 20 }}>
                    <FastField
                      required
                      label='Total'
                      type='number'
                      style={{ width: 195 }}
                      component={CustomInput}
                      validate={validateRequired}
                      name={`${name}.bill_total`}
                      disabled
                      labelStyle={styles.labelInput}
                      leftIcon={<DollarIcon height={32} />}
                      className={classes.customInputField}
                    />
                    <ErrorMessage
                      name={`${name}.bill_total`}
                      render={(error) => (
                        <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                          {error}
                        </Typography>
                      )}
                    />
                  </div>
                </div>
                <div className={classes.warning}>
                  <WarningIcon />
                  <Typography variant='s2'>There is a holiday detected in time span of detention.</Typography>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetentionStop;
