import { array, number, object, string } from 'yup';

export const validationSchema = array().of(
  object().shape({
    contact_name: string().trim().required('Required'),
    contact_email: string().trim().required('Required').email('Enter a valid email'),
    phone_number: string().trim().required('Required'),
    contact_type_id: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
  })
);
