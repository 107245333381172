import { emailRegex } from 'utils/helpers';

export const createReferrerConvertor = (values) => {
  if (!values) {
    return null;
  }

  return values.map((item) => {
    const { referral_name, contact_information, commission } = item;
    const isEmail = emailRegex.test(contact_information);

    return {
      referral_name,
      commission,
      contact_information: !isEmail && contact_information[0] !== '+' ? `+${contact_information}` : contact_information,
    };
  });
};
