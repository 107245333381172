import React from 'react';

import DriverCount from './components/CountsSection/DriverCount';
import EquipmentCount from './components/CountsSection/EquipmentCount';
import StaffCount from './components/CountsSection/StaffCount';
import OwnerOperatorsCount from './components/CountsSection/OwnerOperatorsCount';

import OverviewActions from './components/Overview/Actions';
import DriverStatus from './components/Overview/DriverStatus';
import EquipmentStatus from './components/Overview/EquipmentStatus';
import ReadyToInvoice from './components/Overview/ReadyToInvoice';
import TotalOpenReceivables from './components/Overview/TotalOpenReceivables';
import AccountsReceivableOverview from './components/Overview/AccountsReceivable';
import IncidentsRiskLevel from './components/Overview/IncidentsRiskLevel';
import Revenue from './components/Overview/Revenue';
import UpcomingBills from './components/Overview/UpcomingBills';
import DriverRetention from './components/Overview/DriverRetention';
import PastDueBills from './components/Overview/PastDueBills';
import TotalIncidents from './components/Overview/TotalIncidents';
import IncidentSummary from './components/Overview/IncidentSummary';
import FuelPrices from './components/Overview/FuelPrices';
import PayrollOverview from './components/Overview/Payroll';
import Accounts from './components/Overview/Accounts';

import Applicants from './components/Applicants';
import Actions from './components/Applicants/Actions';

import EquipmentMaintenance from './components/EquipmentMaintenance';

import AccountsReceivable from './components/AccountsReceivable';

import AccountsPayable from './components/AccountsPayable';

import Payroll from './components/Payroll';

import Shipments from './components/Shipments';

export const dashboardWidgets = [
  {
    id: 1,
    order: 1,
    title: '',
    widgets: [
      {
        id: 1,
        order: 1,
        active: 1,
        width: 25,
        title: 'Equipment',
        component: <EquipmentCount />,
      },
      {
        id: 2,
        order: 2,
        active: 1,
        width: 25,
        title: 'Staff',
        component: <StaffCount />,
      },
      {
        id: 3,
        order: 3,
        active: 1,
        width: 25,
        title: 'Driver',
        component: <DriverCount />,
      },
      {
        id: 4,
        order: 4,
        active: 1,
        width: 25,
        title: 'Owner Operators',
        component: <OwnerOperatorsCount />,
      },
    ],
  },
  {
    id: 2,
    order: 2,
    title: 'Overview',
    actionButtons: <OverviewActions />,
    widgets: [
      {
        id: 5,
        order: 1,
        active: 1,
        width: 25,
        title: 'Driver Status',
        component: <DriverStatus />,
      },
      {
        id: 6,
        order: 2,
        active: 1,
        width: 25,
        title: 'Equipment Status',
        component: <EquipmentStatus />,
      },
      {
        id: 7,
        order: 3,
        active: 1,
        width: 25,
        title: 'Ready to Invoice',
        component: <ReadyToInvoice />,
      },
      {
        id: 8,
        order: 4,
        active: 1,
        width: 25,
        title: 'Total Open Receivables',
        component: <TotalOpenReceivables />,
      },
      {
        id: 9,
        order: 5,
        active: 1,
        width: 25,
        title: 'Accounts Receivable Graph',
        component: <AccountsReceivableOverview />,
      },
      {
        id: 10,
        order: 6,
        active: 1,
        width: 25,
        title: 'Incidents - High Risk vs Low Risk',
        component: <IncidentsRiskLevel />,
      },
      {
        id: 11,
        order: 7,
        active: 1,
        width: 25,
        title: 'Revenue',
        component: <Revenue />,
      },
      {
        id: 12,
        order: 8,
        active: 1,
        width: 25,
        title: 'Upcoming Bills',
        component: <UpcomingBills />,
      },
      {
        id: 13,
        order: 9,
        active: 1,
        width: 25,
        title: 'Driver Retention',
        component: <DriverRetention />,
      },
      {
        id: 14,
        order: 10,
        active: 1,
        width: 25,
        title: 'Past Due Bills',
        component: <PastDueBills />,
      },
      {
        id: 15,
        order: 11,
        active: 1,
        width: 25,
        title: 'Total Incidents',
        component: <TotalIncidents />,
      },
      {
        id: 16,
        order: 12,
        active: 1,
        width: 25,
        title: 'Incident Summary',
        component: <IncidentSummary />,
      },
      {
        id: 17,
        order: 13,
        active: 1,
        width: 100,
        title: 'Fuel Prices',
        component: <FuelPrices />,
      },
      {
        id: 20,
        order: 14,
        active: 1,
        width: 25,
        title: 'Payroll',
        component: <PayrollOverview />,
      },
      {
        id: 21,
        order: 15,
        active: 1,
        width: 25,
        title: 'Accounts',
        component: <Accounts />,
      },
    ],
  },
  {
    id: 3,
    order: 3,
    title: 'Applicants',
    actionButtons: <Actions />,
    widgets: [
      {
        id: 22,
        order: 1,
        active: 1,
        width: 100,
        title: 'Applicants',
        component: <Applicants />,
      },
    ],
  },
  {
    id: 4,
    order: 4,
    title: '',
    widgets: [
      {
        id: 23,
        order: 1,
        active: 1,
        width: 100,
        title: 'Equipment Maintenance',
        component: <EquipmentMaintenance />,
      },
    ],
  },
  {
    id: 5,
    order: 5,
    title: '',
    widgets: [
      {
        id: 24,
        order: 1,
        active: 1,
        width: 100,
        title: 'Accounts Receivable',
        component: <AccountsReceivable />,
      },
    ],
  },
  {
    id: 8,
    order: 6,
    title: '',
    widgets: [
      {
        id: 25,
        order: 1,
        active: 1,
        width: 100,
        title: 'Accounts Payable',
        component: <AccountsPayable />,
      },
    ],
  },
  {
    id: 6,
    order: 7,
    title: '',
    widgets: [
      {
        id: 27,
        order: 1,
        active: 1,
        width: 100,
        title: 'Payroll',
        component: <Payroll />,
      },
    ],
  },
  {
    id: 7,
    order: 8,
    title: '',
    widgets: [
      {
        id: 28,
        order: 1,
        active: 1,
        width: 100,
        title: 'Shipments',
        component: <Shipments />,
      },
    ],
  },
];

export const updateWidgets = (initialWidgets, userWidgetSettings, departments) => {
  const sortedWidgets = userWidgetSettings.sort((a, b) => a.order - b.order);

  return sortedWidgets.map((item) => {
    const innerSortedWidgets = item.widgets.sort((a, b) => a.order - b.order);
    const initialInnerWidgets = initialWidgets.find((i) => i.id === item.id);
    return {
      ...initialInnerWidgets,
      order: item.order,
      widgets: innerSortedWidgets.map((widget) => ({
        ...initialInnerWidgets.widgets.find((el) => el.id === widget.id),
        active: widget.active,
        order: widget.order,
        departments: departments.find((i) => i.id === widget.id).dashboard_tab_permission,
      })),
    };
  });
};
