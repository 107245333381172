import React, { useRef } from 'react';
import classNames from 'classnames';
import { Collapse, Overlay } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import Tooltip from 'common/Tooltip';
import classes from './tableBulkActions.module.css';
import CustomButton from '../CustomButton/CustomButton';
import { Typography } from '../Typography';

const TableBulkActions = ({ ...props }) => {
  const { use } = useTheme();
  const {
    conditionShow,
    options,
    title,
    styleContainer,
    onClickOption,
    overlayStyle,
    labelName,
    typeBtn,
    showBulkActions,
    setShowBulkActions,
    placement = 'bottom',
  } = props;
  const target = useRef(null);
  const LABEL_NAME = labelName || 'name';

  return (
    <div style={styleContainer}>
      <Collapse in={(conditionShow !== undefined && !!conditionShow) || conditionShow === undefined}>
        <div style={{ padding: 1 }}>
          <CustomButton
            ref={target}
            type={typeBtn}
            title={title}
            rightIcon={<ArrowDown fill={palette.white} style={{ marginLeft: 7 }} />}
            styleButton={{ padding: '2px 8px', margin: 0 }}
            onClick={() => setShowBulkActions(true)}
          />
        </div>
      </Collapse>

      <Overlay
        placement={placement}
        target={target.current}
        show={showBulkActions}
        onHide={() => setShowBulkActions(false)}
        rootCloseEvent='click'
        rootClose
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => {
          return (
            <div
              {...props}
              className={classNames(classes.overlay, use(classes.light, classes.dark))}
              style={{
                ...props.style,
                backgroundColor: use(palette.white, palette.dark800),
                ...overlayStyle,
              }}
            >
              {options.map((el, idx) => {
                return el.tooltip ? (
                  <Tooltip title={el.tooltip}>
                    <div className={classes.item} key={el.id || idx} onClick={() => onClickOption(el, idx)}>
                      <Typography
                        variant='s2'
                        style={{
                          color: el.disabled
                            ? palette.gray500
                            : el?.cancel
                            ? palette.red500
                            : el?.green
                            ? palette.green500
                            : el?.yellow
                            ? palette.goldDark
                            : use(palette.gray900, palette.gray50),
                        }}
                      >
                        {el[LABEL_NAME]}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <div className={classes.item} key={el.id || idx} onClick={() => onClickOption(el, idx)}>
                    <Typography
                      variant='s2'
                      style={{
                        color: el.disabled
                          ? palette.gray500
                          : el?.cancel
                          ? palette.red500
                          : el?.green
                          ? palette.green500
                          : el?.yellow
                          ? palette.goldDark
                          : use(palette.gray900, palette.gray50),
                      }}
                    >
                      {el[LABEL_NAME]}
                    </Typography>
                  </div>
                );
              })}
            </div>
          );
        }}
      </Overlay>
    </div>
  );
};

export default TableBulkActions;
