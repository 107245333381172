import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import HeaderStar from 'components/HeaderStar';
import CustomButton from 'components/CustomButton/CustomButton';
import { getStats } from 'Api/CardManagement';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import StripeConnection from 'componentsV2/StripeConnection';
import { formatNumber, palette, STRIPE_MODULE } from 'utils/constants';
import Cards from './Cards';
import AddProvider from './AddProvider';
import Transactions from './Transactions';
import FeatureNotAvailable from './FeatureNotAvailable';
import './CardManagement.scss';

const CardManagement = () => {
  const [addProviderOpen, setAddProviderOpen] = useState(false);
  const [openFeatureNotAvailable, setOpenFeatureNotAvailable] = useState(false);

  return (
    <div className='card-management-layout'>
      <div className='card-management-header'>
        <HeaderStar title='Card Management' />
        <div className='d-flex gap-3'>
          <CustomButton
            type='secondary'
            title='Add Existing Provider'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={() => setAddProviderOpen(true)}
          />
          <StripeConnection module={STRIPE_MODULE.CARD_MANAGEMENT} />
        </div>
      </div>
      <TopSection />
      <div className='d-flex mt-2'>
        <div style={{ width: '35%' }} />
        <div style={{ width: '65%', display: 'flex', alignItems: 'center', columnGap: '6px' }}>
          <div style={{ backgroundColor: palette.indigo400, height: '10px', width: '10px', borderRadius: '50%' }} />
          <Typography variant='c2' style={{ color: palette.gray500, paddingRight: '6px' }}>
            This year
          </Typography>
          <div style={{ backgroundColor: palette.gray300, height: '10px', width: '10px', borderRadius: '50%' }} />
          <Typography variant='c2' style={{ color: palette.gray500 }}>
            Last year
          </Typography>
        </div>
      </div>
      <Cards />
      <Transactions />
      {addProviderOpen && <AddProvider open={addProviderOpen} onClose={() => setAddProviderOpen(false)} />}
      {openFeatureNotAvailable && (
        <FeatureNotAvailable
          open={openFeatureNotAvailable}
          onClose={() => setOpenFeatureNotAvailable(false)}
          text='This feature is only available with the Truckin Digital Cash Card.'
        />
      )}
    </div>
  );
};

export default CardManagement;

const ChartOption = ({ stats }) => {
  const { currency } = useSelector((state) => state.root);

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title(item) {
            const data = item?.[0];
            if (!data) {
              return null;
            }
            return `${moment().month(data.dataIndex).format('MMMM')}, ${
              data.dataset.label === 'current' ? moment().year() : moment().subtract(1, 'year').year()
            }`;
          },
          footer(item) {
            if (!item?.[0]) {
              return null;
            }
            return `${currency}${formatNumber(item[0].raw)}`;
          },
        },
        backgroundColor: palette.white,
        titleColor: '#000000',
        bodyColor: '#000000',
        footerColor: '#000000',
        titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
        bodyFont: { size: 0 },
        displayColors: false,
        yAlign: 'bottom',
        borderWidth: 1,
        borderColor: '#e0e0e0',
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        suggestedMax: 3000,
        ticks: {
          stepSize: 1000,
          callback(value) {
            if (value === 0) {
              return value;
            }
            return `${currency}${value / 1000}k`;
          },
        },
      },
    },
  };

  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const thisYearValues = Object.entries(stats?.amounts?.[moment().get('year')])
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map((i) => i[1]);
  const prevYearValues = Object.entries(stats?.amounts?.[moment().subtract(1, 'year').get('year')])
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map((i) => i[1]);

  const data = {
    labels,
    datasets: [
      {
        label: 'current',
        data: thisYearValues || [],
        backgroundColor: '#767FFB',
        categoryPercentage: 0.3,
        borderRadius: {
          topRight: 2,
          topLeft: 2,
        },
      },
      {
        label: 'prev',
        data: prevYearValues || [],
        backgroundColor: '#A1A9B8',
        barPercentage: 0.5,
        categoryPercentage: 0.3,
        borderRadius: {
          topRight: 2,
          topLeft: 2,
        },
      },
    ],
  };
  return <Bar width={70} options={options} data={data} />;
};

const TopSection = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const { currency } = useSelector((state) => state.root);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllStats = async () => {
    setLoading(true);
    try {
      const { data } = await getStats();
      setStats(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllStats();
  }, []);

  return (
    <div className='card-management-page-top-section'>
      <div className='sub-section'>
        <div className='d-flex flex-column gap-2'>
          <Typography as='div' variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            Available Balance
          </Typography>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '28px' }} />
          ) : (
            <Typography as='div' variant='h4' style={{ color: use(palette.gray900, palette.gray50) }}>
              {currency}
              {formatNumber(stats?.balance)}
            </Typography>
          )}
          <Typography
            as='div'
            variant='c1'
            className='manage-balance'
            style={{ color: use(palette.indigo500, palette.gray200) }}
            onClick={() => navigate(`/manage-balance`)}
          >
            Manage Card Balance
          </Typography>
        </div>

        <div className='d-flex flex-column gap-2' style={{ borderTop: '1px solid #E9EDF5' }}>
          <Typography as='div' variant='s2' style={{ color: use(palette.gray700, palette.gray200), marginTop: '16px' }}>
            Active Cards
          </Typography>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '28px' }} />
          ) : (
            <Typography as='div' variant='h4' style={{ color: use(palette.gray900, palette.gray50) }}>
              {stats?.active_cards || 0}
            </Typography>
          )}
        </div>
      </div>

      <div className='sub-section'>
        {loading ? (
          <>
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ height: '28px' }} />
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ height: '28px' }} />
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ height: '28px' }} />
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ height: '28px' }} />
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ height: '28px' }} />
          </>
        ) : (
          stats && <ChartOption stats={stats} />
        )}
      </div>
    </div>
  );
};
