import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';
import profile from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as MuteIcon } from 'assets/icons/mute.svg';
import { ReactComponent as UnmuteIcon } from 'assets/icons/unmute.svg';
import Avatar from 'common/Avatar';
import { SimpleLoader } from 'common/Loader';
import { staffStatus } from 'components/UserInfo';
import { Typography } from 'components/Typography';
import { driverStatusMapping } from 'components/StatusUpdateModal/StatusUpdateModal';
import { statusColor } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import ChatAppServices from 'services/chatApp';
import useShowToaster from 'hooks/useShowToaster';
import { shouldRender } from 'pages/chatApp/components/SideBar/Channel/helpers';
import { SChannelItem } from './ChannelItem.styles';

const ChannelItem = ({
  tab,
  channel,
  setActiveChannel,
  watchers,
  activeTab,
  chatUser,
  filterByName,
  updateMutedStatus,
  setUpdateMutedStatus,
}) => {
  const showToaster = useShowToaster();
  const { channel: activeChannel } = useChatContext();
  const [showClear, setShowClear] = useState(false);
  const [wait, setWait] = useState(false);
  const [muted, setMuted] = useState(channel.muteStatus().muted);
  const [loading, setLoading] = useState(false);
  const [textMessage, SetTextMessage] = useState('');
  const navigate = useNavigate();

  const selected = channel?.id === activeChannel?.id;
  let groupName = [];
  if (channel.data.group_short_names) {
    channel.data.group_short_names.split(',');
    groupName = channel.data.group_names.split(',');
  }

  const renderMessageText = () => {
    const lastMessageText = channel?.state?.messages[Number(channel?.state?.messages?.length) - 1]?.text;
    const text = lastMessageText || 'message text';
    return text?.length < 15
      ? lastMessageText?.replaceAll('[SMS]', '')
      : `${text?.replaceAll('[SMS]', '')?.slice(0, 15)}...`;
  };

  const renderChanelName = () => {
    if (channel && chatUser) {
      const { type } = channel;
      if (type === 'messaging') {
        const members = Object.values(channel?.state?.members);
        const sender = members?.find((member) => member.user.id === chatUser.id);
        const receiver = members.find((member) => member.user.id !== chatUser.id);
        if (chatUser.id === sender?.user.id) {
          return receiver?.user?.name;
        }
        return sender?.user?.name;
      }
      return channel.data.name;
    }
  };

  const getGroupImages = () => {
    const members = Object.values(channel?.state?.members);
    const memberImages = members.map((i) => i.user.image);

    if (memberImages.length > 1) {
      return memberImages;
    }

    return null;
  };

  const getChannelImage = () => {
    if (channel && chatUser) {
      const { type } = channel;
      if (type === 'messaging') {
        const members = Object.values(channel?.state?.members);
        const sender = members?.find((member) => member.user.id === chatUser.id);
        const receiver = members.find((member) => member.user.id !== chatUser.id);

        if (chatUser.id === sender?.user.id) {
          return { image: receiver?.user?.image, status: receiver?.user?.status, role: receiver?.user?.role };
        }
        return { image: sender?.user?.image, status: sender?.user?.status, role: sender?.user?.role };
      }
      return channel.data.image
        ? { image: channel.data.image, status: channel.data.status, role: channel?.user?.role }
        : { image: profile, status: channel.data.status, role: channel?.user?.role };
    }
  };

  const muteChannel = async () => {
    try {
      setLoading(true);
      await channel.mute();
      setUpdateMutedStatus(Date.now());
      showToaster({ type: 'success', message: `${renderChanelName() || 'Channel'} has been muted!` });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const unmuteChannel = async () => {
    try {
      setLoading(true);
      await channel.unmute();
      setUpdateMutedStatus(Date.now());
      showToaster({ type: 'success', message: `${renderChanelName() || 'Channel'} has been unmuted!` });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const OpenChat = async (val) => {
    if (val) {
      await channel.updatePartial({
        set: {
          open_chat: val,
          chat_open_date_time: new Date(),
        },
      });
    } else {
      await channel.updatePartial({
        set: {
          open_chat: val,
          chat_closed_by: chatUser.name,
          chat_closed_date_time: new Date(),
        },
      });
    }
    setWait(false);
  };

  if (!channel?.data.open_chat && channel?.state.unreadCount > 0) {
    OpenChat(true);
  }

  const markAsAllRead = async () => {
    setWait(true);
    SetTextMessage('Please Wait');
    const members = await channel.queryMembers({});
    const membersId = [];
    members.members?.map((m) => {
      if (m.user.role === 'staff') {
        membersId.push(m.user.id);
        return m;
      }
      return m;
    });
    ChatAppServices.closeNotification({
      channel_id: channel.data.cid,
      user_id: membersId,
    })
      .then(() => {
        if (activeTab === 2) {
          OpenChat(true);
        } else {
          OpenChat(false);
        }
      })
      .catch(() => {
        SetTextMessage('Server Error');
      });
  };

  if (filterByName?.length !== 0) {
    const result = filterByName.filter((n) => groupName.indexOf(n) !== -1);
    const found = result.some((e) => groupName.includes(e));
    const checkChannelName = filterByName.includes(channel.data.name);
    if (!found && !checkChannelName) {
      return null;
    }
  }

  const handleClick = () => {
    navigate(`/chat/${channel.id}`);
    setActiveChannel(channel, watchers);
  };

  useEffect(() => {
    setMuted(channel.muteStatus().muted);
  }, [updateMutedStatus]);

  if (
    !shouldRender(tab, channel) ||
    (activeTab === 2 && channel?.data?.open_chat) ||
    (activeTab === 1 && !channel?.data?.open_chat)
  ) {
    return null;
  }

  return (
    <SChannelItem className={selected ? 'selected' : ''}>
      {wait && (
        <div className='wait-for-res' onClick={() => setWait(false)}>
          <p style={{ fontSize: '14px' }}>{textMessage} ...</p>
        </div>
      )}
      <div className='channel-item-content' onClick={() => handleClick()}>
        <div className='channel-image'>
          {channel.type === 'truckindigital' && getGroupImages()?.length > 1 ? (
            <Avatar images={getGroupImages()} />
          ) : (
            <div className='profile-image'>
              <img src={getChannelImage()?.image || profile} alt='img' />
              <div
                className='profile-status-indicator'
                style={{
                  backgroundColor:
                    getChannelImage().role === 'staff'
                      ? statusColor[staffStatus[getChannelImage()?.status]]?.color
                      : statusColor[driverStatusMapping[getChannelImage()?.status]]?.color,
                }}
              />
            </div>
          )}
        </div>
        <div className='custome_channel-name'>
          <div>
            <div className='d-flex align-items-center'>
              <p className='channel-preview__content-name'>
                {renderChanelName() || 'Channel'}
                {channel?.state.unreadCount !== 0 && (
                  <p className='channel-unread-count-number ms-2'>{channel?.state.unreadCount}</p>
                )}
              </p>
              <div className='ms-2'>
                {loading ? (
                  <SimpleLoader loading={loading} size={16} />
                ) : muted ? (
                  <div
                    className='d-flex align-items-center justify-content-center'
                    onClick={(e) => {
                      e.preventDefault();
                      unmuteChannel();
                    }}
                  >
                    <MuteIcon />
                  </div>
                ) : (
                  <div
                    className='d-flex align-items-center justify-content-center'
                    onClick={(e) => {
                      e.preventDefault();
                      muteChannel();
                    }}
                  >
                    <UnmuteIcon />
                  </div>
                )}
              </div>
            </div>
            <p className='channel-preview__content-name'>{channel.data?.subtitle}</p>
            <p className='channel-preview__content-message'>{renderMessageText()}</p>
          </div>
          {/* <div className='custome-channel-icons'> */}
          {/*  {groupTags !== 0 && ( */}
          {/*    <div className='tags-div'> */}
          {/*      {groupTags.map((g, idx) => ( */}
          {/*        <GroupHoover g={g} groupName={groupName[idx]} /> */}
          {/*      ))} */}
          {/*    </div> */}
          {/*  )} */}
          {/* </div> */}

          {activeTab === 1 && channel?.data.updated_at && (
            <Typography
              variant='c3'
              style={{
                whiteSpace: 'nowrap',
                marginRight: 4,
              }}
            >
              {moment(channel?.data.updated_at).fromNow()}
            </Typography>
          )}
          {activeTab !== 3 && (
            <div className='channel-status-btn' onClick={() => setShowClear(!showClear)}>
              <span className='clear-notification' style={{ cursor: 'pointer' }} onClick={markAsAllRead}>
                {activeTab === 2 ? 'Reopen' : 'Close'}
              </span>
            </div>
          )}
        </div>
      </div>
    </SChannelItem>
  );
};

export default ChannelItem;
