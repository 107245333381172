import React, { useState } from 'react';
import Tooltip from 'common/Tooltip';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onPayBill, onMarkPaid, onEdit, onView, onDelete, onRevertAndDelete, onRevertToUnpaid }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'payBill':
        onPayBill();
        break;
      case 'markPaid':
        onMarkPaid();
        break;
      case 'edit':
        onEdit();
        break;
      case 'view':
        onView();
        break;
      case 'delete':
        onDelete();
        break;
      case 'revertToUnpaid':
        onRevertToUnpaid();
        break;
      case 'revertAndDelete':
        onRevertAndDelete();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      {onPayBill && (
        <Tooltip title='Feature not enabled' placement='top'>
          <li onClick={(event) => onActionClick(event, 'payBill')} className='disabled'>
            Pay Bill
          </li>
        </Tooltip>
      )}
      {onMarkPaid && <li onClick={(event) => onActionClick(event, 'markPaid')}>Mark Paid</li>}
      {onRevertToUnpaid && <li onClick={(event) => onActionClick(event, 'revertToUnpaid')}>Revert to Open</li>}
      {onEdit && <li onClick={(event) => onActionClick(event, 'edit')}>Edit</li>}
      {onView && <li onClick={(event) => onActionClick(event, 'view')}>View</li>}
      {onDelete && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'delete')}>
          Delete
        </li>
      )}
      {onRevertAndDelete && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'revertAndDelete')}>
          Revert to Open and Delete
        </li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
