import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .arrow-icon {
    transform: rotate(90deg);
  }
`;

export const SListItem = styled.li`
  &.selected {
    background-color: ${palette.indigo500};
    color: ${palette.white};

    :hover {
      color: ${palette.gray900};
    }
  }
`;
