import React from 'react';
import { Bar } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { getOptions } from './Charts.data';

const DayOfWeekChart = ({ monthsData }) => {
  const data = {
    labels: (monthsData || []).map((item) => `${item.day}`),
    datasets: [
      {
        data: (monthsData || []).map((item) => item.accidents),
        backgroundColor: palette.green400,
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getOptions(monthsData)} data={data} />;
};

export default DayOfWeekChart;
