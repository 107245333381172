import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .scrollable-container {
    display: flex;
    overflow: auto;
  }
`;

export const SBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  .export-csv-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: ${palette.indigo500};
    padding: 2px 8px;
    text-decoration: none;
  }

  .export-csv-btn.non-clickable {
    pointer-events: none;
  }

  .export-csv-btn:hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${palette.indigo500};
  }

  .MuiTableCell-root {
    padding: 8px 4px;
    border-bottom: none;
  }

  .MuiTableRow-root.MuiTableRow-head th {
    height: 32px !important;
  }

  .MuiTableRow-root.MuiTableRow-head {
    border-top: none;
    border-bottom: 1px solid rgb(224, 224, 224);
  }

  .receivable-chart-section-tag-indigo {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${palette.indigo500};
    padding: 2px 10px;
    background: ${palette.indigo0};
    border-radius: 10px;
    margin-left: 10px;
  }
`;
