import React, { useState } from 'react';
import Modal from 'common/Modal';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createPrevEmployment, updatePrevEmployment } from 'Api/PreviousEmployment';
import AddUpdatePrevEmployment from '../AddUpdatePrevEmployment';
import { addEmploymentDataConverter } from './AddUpdateModal.data';

const AddUpdateModal = ({ open, onClose, onSuccess, previousEmployment, userType, userId, initialEmployments }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSave = async (values) => {
    try {
      setLoading(true);
      const promises = values.map((employer) => {
        const formData = addEmploymentDataConverter(employer, userType, userId);
        if (previousEmployment) {
          return updatePrevEmployment(formData);
        }
        return createPrevEmployment(formData);
      });
      await Promise.all(promises);
      showToaster({
        type: 'success',
        message: `Previous Employment has been successfully ${previousEmployment ? 'updated' : 'added'}!`,
      });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={`${previousEmployment ? 'Update' : 'Add'} Previous Employer`}
      $width='1100px'
      $maxWidth='90vw'
      $minWidth='800px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', margin: 10, fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: `${previousEmployment ? 'Update' : 'Add'} Employer`,
          buttonProps: { type: 'submit', form: 'previous-employment-form', onClick: undefined },
          disabled: loading,
        },
      ]}
    >
      <AddUpdatePrevEmployment
        previousEmployments={previousEmployment ? [previousEmployment] : []}
        onSave={onSave}
        userType={userType}
        userId={userId}
        applicantPrevEmployment={previousEmployment ? null : initialEmployments}
      />
    </Modal>
  );
};

export default AddUpdateModal;
