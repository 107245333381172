import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';

import Info from './Info';
import { SColumn, SSection } from '../ViewApplicantModal.styles';

const Position = ({ data }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  const { created_at, job_positing } = data || {};
  const equipmentTypes = job_positing?.equipment_types_id?.length
    ? job_positing.equipment_types_id.map((i) => i.title).join(', ')
    : '-';

  return (
    <div className='info-section'>
      <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray200) }}>
        Position & Experience
      </Typography>
      <SSection>
        <SColumn>
          <Info label='Date Applied' infoText={formatDate(created_at)} />
        </SColumn>
        <SColumn>
          <Info label='Position' infoText={job_positing?.job_position_type?.name || '-'} />
        </SColumn>
        {job_positing?.equipment_types?.title && (
          <SColumn>
            <Info label='Equipment' infoText={job_positing?.equipment_types?.title || '-'} />
          </SColumn>
        )}
        <SColumn>
          <Info
            label='Experience'
            infoText={
              (job_positing?.experience_year || job_positing?.experience_month) &&
              `${job_positing?.experience_year ? `${job_positing?.experience_year} years ` : ''} ${
                job_positing?.experience_month ? `${job_positing?.experience_month} months ` : ''
              }`
            }
          />
        </SColumn>
        <SColumn>
          <Info label='Equipment Experience' infoText={equipmentTypes} />
        </SColumn>
      </SSection>
    </div>
  );
};

export default Position;
