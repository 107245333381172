import React, { useState, useEffect, useMemo } from 'react';
import './jurisdiction.css';
import moment from 'moment';
import IFTAServices, { getSamsaraJurisdiction, formatAmount } from 'services/IFTAServices';
import TopArrowActive from 'assets/icons/tableSortArrowTopActive.svg';
import TopArrow from 'assets/icons/tableSortArrowTop.svg';
import DownArrow from 'assets/icons/tableSortArrowDown.svg';
import DownArrowActive from 'assets/icons/tabelSortArrowDownActive.svg';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { quarters } from '../IFTA.data';
import QuarterYearFilter from '../QuarterYearFilter';
import ExportData from './ExportData';

const Jurisdiction = ({ connectedService }) => {
  const showToaster = useShowToaster();
  const [jurisdictionData, setJurisdictionData] = useState([]);
  const [startDate, setStartDate] = useState('01/01/2022');
  const [endDate, setEndDate] = useState('03/31/2022');
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [fuelTypeData, setFuelTypeData] = useState([]);
  const [selected, setSelected] = useState(fuelTypeData[0]);
  const [quarter, setQuarter] = useState(quarters[0]);
  const [year, setYear] = useState('');
  const [loading, setLoading] = useState(false);

  const getSamsaraJurisdictionData = async (params) => {
    try {
      setLoading(true);
      const data = await getSamsaraJurisdiction(params);
      setJurisdictionData(data);
    } catch (e) {
      setJurisdictionData([]);
      const message = getErrorMessage(e);
      if (message) {
        showToaster({ message, type: 'error' });
      }
    }
    setLoading(false);
  };

  const getJurisdiction = (data) => {
    setLoading(true);
    IFTAServices.getJurisdiction(data)
      .then((res) => setJurisdictionData(res.data))
      .catch(() => {
        setJurisdictionData([]);
      })
      .finally(() => setLoading(false));
  };

  const getFuelTypes = () => {
    IFTAServices.getFuelTypes().then((res) => {
      const fuelTypes = res?.data?.data || [];

      setFuelTypeData(fuelTypes);
      setSelected(
        fuelTypes.length
          ? { ...fuelTypes[0], key: fuelTypes[0].id, label: fuelTypes[0].type, labelSelected: null }
          : undefined
      );
    });
  };

  useEffect(() => {
    if (!selected?.id) {
      return;
    }

    const data = {
      from_date: moment(startDate).format('MM/DD/Y'),
      to_date: moment(endDate).format('MM/DD/Y'),
      fuel_type: selected.id,
      sortingOrder,
    };

    if (connectedService) {
      if (connectedService?.success) {
        const params = {
          quarter: quarter?.key,
          year,
          fuel_type: selected.id,
          'sort[][jurisdiction]': sortingOrder,
          page: 1,
          itemsPerPage: 1000,
        };

        getSamsaraJurisdictionData(params);
        return;
      }
      getJurisdiction(data);
    }
  }, [startDate, endDate, sortingOrder, selected, connectedService]);

  useEffect(() => {
    getFuelTypes();
  }, []);

  const fuelTypeOptions = useMemo(() => {
    return (fuelTypeData || [])
      .filter((item) => ![2, 4].includes(item.id))
      .map((option) => ({ ...option, key: option.id, label: option.type, labelSelected: null }));
  }, [fuelTypeData]);

  return (
    <div className='fuel-price-layout'>
      <div className='fuel-price-header'>
        <div className='fuel-inner-header'>
          <div>
            <QuarterYearFilter
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              quarter={quarter}
              setQuarter={setQuarter}
              onYearChange={setYear}
            />
          </div>
          <div>
            <CustomSelect
              value={selected}
              defaultActiveValue={fuelTypeOptions[0]}
              onChange={setSelected}
              options={fuelTypeOptions}
              styles={{ height: 32, width: 140 }}
            />
          </div>
        </div>
        <ExportData jurisdictionData={jurisdictionData} year={year} quarter={quarter?.key} fuelType={selected} />
      </div>
      {!jurisdictionData?.data && loading ? (
        <TablePreLoader styleWrapper={{ marginTop: '30px' }} />
      ) : (
        <ShortTable
          jurisdictionData={jurisdictionData}
          setSortingOrder={setSortingOrder}
          sortingOrder={sortingOrder}
          connectedService={connectedService}
        />
      )}
    </div>
  );
};

export default Jurisdiction;

const ShortTable = ({ jurisdictionData, setSortingOrder, sortingOrder, connectedService }) => {
  const { use } = useTheme();

  const handleShortingByDate = () => {
    if (sortingOrder === 'asc') {
      setSortingOrder('desc');
    } else if (sortingOrder === 'desc') {
      setSortingOrder('asc');
    }
  };

  return (
    <div style={{ padding: '30px 0' }}>
      <div className='jurisdiction-heading-top'>
        <span className='title'>{formatAmount(jurisdictionData?.total_miles, 0)}</span>
        <span className='subtitle'>Total IFTA Miles</span>
        {connectedService?.success && connectedService?.data && (
          <span className='service-info'>
            IFTA data is provided by
            <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200), marginLeft: '4px' }}>
              {connectedService.data.provider_name}
            </Typography>
            {connectedService.data?.logo && (
              <img src={connectedService.data.logo} alt={connectedService.data.provider_name} height={46} />
            )}
          </span>
        )}
      </div>
      <div className='fuel-table-font'>
        <ul className='fuel-price-history-table-header upperCase' style={{ marginTop: '20px' }}>
          <li className='sorting-option'>
            Jurisdiction
            <div className='arrow-btn' onClick={handleShortingByDate}>
              <img src={sortingOrder === 'asc' ? TopArrowActive : TopArrow} alt='' />
              <img src={sortingOrder === 'desc' ? DownArrowActive : DownArrow} alt='' />
            </div>
          </li>
          <li>Miles</li>
          <li>Gallons</li>
        </ul>
        {jurisdictionData?.data?.map((data) => (
          <ul className='fuel-price-history-table-header fuel-table-text' key={data.jurisdiction}>
            <li>{data.jurisdiction} </li>
            <li>{formatAmount(Math.ceil(data.total), 0)}</li>
            <li>{data.gallons}</li>
          </ul>
        ))}
      </div>
    </div>
  );
};
