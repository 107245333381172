import { array, number, object, ref, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  customer: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  reference_id: string().trim().required('Required'),
  terms: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  bill_date: validator.requiredDate().max(ref('due_date'), "Billed date can't be after due date!").nullable(),
  due_date: validator.requiredDate().min(ref('bill_date'), '').nullable(),
  items: array()
    .of(
      object({
        item: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        desc: string().trim().required('Required'),
        quantity: number().required('Required').nullable(),
        price: number().required('Required').nullable(),
      })
    )
    .min(1, 'Required'),
});
