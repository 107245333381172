import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SApprovedFor = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  min-height: 33px;
`;

export const STable = styled.table`
  width: 100%;

  .header-row {
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 0 10px 12px;
      white-space: nowrap;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    &.bg-gray {
      background: ${palette.gray0};
    }

    &.total-row {
      td {
        padding: 10px 10px 22px;
      }
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    td {
      padding: 12px 10px;
    }

    :hover {
      background: ${palette.gray0};
    }
  }

  .attachment-thumbnail {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 6px;
    object-fit: cover;
    cursor: pointer;
  }
`;
