import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import authHeader from 'services/auth-header';
import '../AddHook/addhook.css';

const API_URL = process.env.REACT_APP_API_URL;

const RemoveGroup = ({ handleClose, RemoveGroupData, RemoveGroupSuccessModal, onSuccess = () => null }) => {
  const { use } = useTheme();

  const [id, eq_id, row_id, title, group_name] = RemoveGroupData;

  const removeGroup = (g_id) => {
    axios
      .delete(`${API_URL}/group/${g_id}/${row_id}`, {
        headers: authHeader(),
      })
      .then(() => {
        handleClose();
        onSuccess();
      }, RemoveGroupSuccessModal(row_id))
      .catch(() => {
        // Do nothing
      });
  };
  return (
    <div className='equipment-list-unhooked-trailer' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <Modal.Body className='remove-driver-dailogue-body'>
        <div className='remove-driver-dailogue-wrapper'>
          <h6 className='remove-modal-header' style={{ color: use(palette.dark800, palette.gray200) }}>
            Remove Tag
          </h6>
          <p className='remove-driver-paragraph' style={{ color: use(palette.dark800, palette.gray200) }}>
            Are you sure you want to remove {title} {eq_id} from {group_name}?
          </p>
          <div className='d-flex justify-content-end remove-driver-footer mt-3'>
            <button
              type='button'
              className='cancel-button'
              onClick={handleClose}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.dark800, palette.gray200),
              }}
            >
              Cancel
            </button>
            <button className='unhooked-button' type='submit' onClick={() => removeGroup(id, eq_id)}>
              Yes, Remove
            </button>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default RemoveGroup;
