import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { endOfYear, startOfYear } from 'date-fns';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';
import Search from 'common/Search';
import Tooltip from 'common/Tooltip';
import Pagination from 'common/Pagination';
import { BackdropLoader } from 'common/Loader';
import TableSkeleton from 'common/TableSkeleton';
import TableWrapper from 'components/TableWrapper';
import DateRangePicker from 'common/DateRangePicker';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomButton from 'components/CustomButton/CustomButton';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { downloadCsv } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { deleteBill, exportBillsCsv, getBill, getBills, revertBillToUnpaid } from 'Api/Payables';
import AddBill from 'pages/Accounting/Payables/components/AddBill';
import TableFilters from 'pages/Accounting/Payables/TableSection/shared/TableFilters';
import { useColumns } from './PaidBills.data';
import { initialFilters } from '../../TableSection.data';
import ViewPdf from '../../../../../../components/ViewPdf';

const PaidBills = ({ vendor, refreshStats = () => null }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loadingBill, setLoadingBill] = useState(false);
  const [billToView, setBillToView] = useState(null);
  const [billToDelete, setBillToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [paidBills, setPaidBills] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ field: 'paid_date', sortBy: 'desc' });
  const [dateRange, setDateRange] = useState({
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  });
  const [pdfUrl, setPdfUrl] = useState(null);

  const debouncedSearch = useDebounce(search, 500);
  const didMountRef = useRef(false);

  const getPaidBills = async () => {
    try {
      const sortField = `sort[][${sort.field}]`;
      const params = {
        status: 1,
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        vendor_id: vendor?.id || selectedFilters.vendor?.id || undefined,
        from: moment(dateRange.start).format('YYYY-MM-DD'),
        to: moment(dateRange.end).format('YYYY-MM-DD'),
        all: debouncedSearch || undefined,
        [sortField]: sort.sortBy,
      };

      const response = await getBills(params);
      setPaidBills(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onExportCsv = async () => {
    if (!selectedRows?.length) {
      return;
    }
    setLoadingExport(true);
    try {
      const accounting_bills = selectedRows.map((i) => i.id);
      const response = await exportBillsCsv({ accounting_bills });
      if (response && typeof response === 'string') {
        await downloadCsv(response, 'paid-bills.csv');
      } else {
        showToaster({ type: 'error', message: 'Something went wrong!' });
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingExport(false);
    }
  };

  const deleteRow = async () => {
    if (!billToDelete?.id) {
      return;
    }
    setLoadingDelete(true);
    try {
      await deleteBill(billToDelete.id);
      showToaster({ type: 'success', message: 'Bill has been successfully deleted!' });
      getPaidBills();
      setBillToDelete(null);
      refreshStats();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onRevertToUnpaid = async (bill) => {
    try {
      await revertBillToUnpaid(bill.id);
      showToaster({ type: 'success', message: 'Bill has been successfully reverted!' });
      getPaidBills();
      refreshStats();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== paidBills.data.length ? paidBills.data : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onView = async (row) => {
    setLoadingBill(true);
    try {
      const { data } = await getBill(row.id);
      setBillToView(data || null);
    } finally {
      setLoadingBill(false);
    }
  };

  const onRevertSuccess = () => {
    getPaidBills();
    refreshStats();
  };

  const onDelete = (row) => {
    setBillToDelete(row);
  };

  const onClickRowItem = ({ filed, row }) => {
    if (filed === 'payment_reference') {
      if (row?.pdf_check_file_link) {
        setPdfUrl(row?.pdf_check_file_link);
      } else {
        showToaster({ type: 'warning', message: "Check doesn't exists" });
      }
    }
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getPaidBills();
    didMountRef.current = true;
  }, [selectedFilters, dateRange, debouncedSearch, sort]);

  const columns = useColumns({
    selectedRows,
    handleSelectRow,
    handleSelectAll,
    allSelected: paidBills.data.length === selectedRows.length,
    hideSelectAll: !paidBills.data.length,
    sort,
    sortingQuery,
    onView,
    onDelete,
    onRevertToUnpaid,
    onClickRowItem,
  });

  return (
    <div>
      {selectedRows.length ? (
        <div className='d-flex mb-3 gap-2 ms-3'>
          <Tooltip title='Export CSV'>
            <span>
              <CustomButton
                type='secondary'
                styleButton={{ padding: '9px 12px', margin: 0 }}
                leftIcon={<ExportIcon fill={palette.gray700} />}
                onClick={onExportCsv}
                disabled={loadingExport}
              />
            </span>
          </Tooltip>
        </div>
      ) : (
        <div className='d-flex justify-content-between align-items-center mb-3'>
          {vendor ? (
            <div className='d-flex align-items-center gap-3'>
              <DateRangePicker
                top='-108px'
                initialRangeName='This Year'
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
              <Search search={search} onChange={setSearch} className='search-input' />
            </div>
          ) : (
            <TableFilters
              dateRange={dateRange}
              setDateRange={setDateRange}
              search={search}
              setSearch={setSearch}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          )}
        </div>
      )}
      {loading ? (
        <TableSkeleton />
      ) : (
        <div className='tableFixHead table-fixed-header-300'>
          <TableWrapper
            data={paidBills.data}
            rowPerPage={1000}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            columns={columns}
            onRowClick={(e, row) => onView(row)}
            components={{
              Pagination: () =>
                Pagination({
                  data: paidBills,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                  rowsPerPageOptions: [25, 50, 100, 150],
                }),
            }}
          />
        </div>
      )}
      {!!billToView && (
        <AddBill
          open={!!billToView}
          onClose={() => setBillToView(null)}
          onSuccess={onRevertSuccess}
          bill={billToView}
          vendor={vendor}
        />
      )}
      {!!billToDelete && (
        <ConfirmationModal
          title='Bill'
          open={!!billToDelete}
          onClose={() => setBillToDelete(null)}
          onConfirm={deleteRow}
          disabled={loadingDelete}
        />
      )}
      <BackdropLoader loading={loadingBill} />
      {pdfUrl && <ViewPdf pdfUrl={pdfUrl} open={!!pdfUrl} onClose={() => setPdfUrl(null)} />}
    </div>
  );
};

export default PaidBills;
