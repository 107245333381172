import React, { useMemo } from 'react';
import Select from 'react-select';
import { palette } from 'utils/constants';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';

export const MultiSelect = ({
  className,
  classContainer,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  multiValueLabelStyle,
  multiValueRemoveStyle,
  multiValueStyle,
  ...props
}) => {
  const onChange = (option) => {
    const valueSelect = isMulti ? option.map((item) => item?.value) : option?.value;
    form && form.setFieldValue(field?.name, valueSelect);
    typeof props?.onChange === 'function' && props.onChange(option, valueSelect);
  };

  const getValue = () => {
    if (options) {
      if (field?.value) {
        return isMulti
          ? options.filter((option) => field.value.indexOf(option.value) >= 0)
          : options.find((option) => option.value === field.value);
      }
      if (props?.value) {
        return isMulti
          ? options.filter((option) => props?.value.indexOf(option?.value) >= 0)
          : options.find((option) => option?.value === props?.value);
      }
    } else {
      return isMulti ? [] : '';
    }
  };

  const styles = useMemo(() => {
    return {
      container: (styles) => ({
        ...styles,
        height: '100%',
      }),
      control: (styles) => ({
        ...styles,
        borderRadius: 8,
        'div[class*="IndicatorsContainer"]': {
          display: 'none',
          opacity: '0',
          pointerEvents: 'none',
        },
      }),
    };
  }, [multiValueLabelStyle, multiValueRemoveStyle, multiValueStyle, palette]);

  return (
    <div className={classContainer} style={{ position: 'relative', width: '100%' }}>
      <Select
        {...field}
        {...props}
        className={className}
        name={field?.name}
        value={getValue()}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        styles={styles}
        isDisabled={props?.disabled}
        isSearchable={false}
      />
      <Arrows style={{ position: 'absolute', right: '10px', top: '35%', pointerEvents: 'none' }} />
    </div>
  );
};

export default MultiSelect;
