import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;

  .footer-right {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .vertical-divide {
    border: 1px solid ${palette.gray100};
    height: 20px;
    margin: 0 8px;
  }

  .powered-by {
    white-space: nowrap;
  }

  @media (max-width: 560px) {
    padding: 8px 2px;

    img {
      width: 24px;
      height: 24px;
    }

    .powered-by {
      font-size: 11px !important;
    }

    .vertical-divide {
      margin: 0 2px;
    }

    .footer-right span {
      font-size: 8px !important;
    }
  }
`;
