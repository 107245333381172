import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 54px;
  height: 60%;
  justify-content: center;

  @media (max-width: 768px) {
    row-gap: 18px;
    margin-bottom: 24px;

    .onboarding-title {
      font-size: 16px !important;
    }
  }
`;

export const SMessageBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 550px;
  background-color: ${palette.white};
  box-shadow: ${palette.boxShadow2};
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 8px 8px 8px 40px;
  text-align: left;

  svg {
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  @media (max-width: 768px) {
    width: 300px;
    max-width: 98%;
  }
`;

export const SAccountTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  width: 648px;

  .account-type-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 200px;
    height: 112px;
    justify-content: flex-end;
    background-color: ${palette.white};
    box-shadow: ${palette.boxShadow2};
    border-radius: 12px;
    transition: background-color 0.4s;

    span {
      color: ${palette.indigo500} !important;
    }

    &.selected {
      background-color: ${palette.indigo400};

      span {
        color: ${palette.white} !important;
      }
    }

    :hover {
      span {
        color: ${palette.gray900} !important;
      }

      &.selected {
        background-color: ${palette.indigo500}DD;
        span {
          color: ${palette.white} !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 424px;
  }

  @media (max-width: 450px) {
    width: 200px;
    padding: 0 16px;
  }
`;
