import React, { useState, useEffect } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Modal from 'common/Modal';
import Input from 'common/Input';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { STOP_POINT_COUNTRY } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import {
  createPosition,
  getJobPositionStatuses,
  getJobRequirements,
  getQuestionnaireList,
  getStates,
  updatePosition,
} from 'Api/JobPositions';
import 'pages/CompanySettings/pagesComponents/modals/addjobPosition/addJobPosition.css';
import { validationSchema } from './validationSchema';
import { acknowledgements, applicantTypes, createPositionConverter, getInitialValues } from './AddJobPosition.data';

const AddJobPosition = ({ open, onClose, onSuccess, data }) => {
  const showToaster = useShowToaster();
  const [positionStatus, setPositionStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [editorState, setEditorState] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(STOP_POINT_COUNTRY[0]);
  const { value: userData } = useAuth();

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = createPositionConverter(values);

      if (data) {
        await updatePosition(data.id, body);
      } else {
        await createPosition(body);
      }
      showToaster({ type: 'success', message: `Job position has been successfully ${data ? 'updated' : 'created'}!` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, resetForm } = useForm({
    initialValues: getInitialValues(data),
    validationSchema,
    onSubmit,
  });

  const getPositionStatus = async () => {
    try {
      const { data } = await getJobPositionStatuses();
      setPositionStatus(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getQuestionary = async () => {
    try {
      const { data } = await getQuestionnaireList(userData?.user?.customer?.dot);
      setQuestions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRequirements = async () => {
    try {
      const { data } = await getJobRequirements();
      setRequirements(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getStatesData = async (countryId) => {
    try {
      const { data } = await getStates({ 'country_id[]': [countryId] });
      setStates(data);
    } catch (e) {
      // Do nothing
    }
  };

  const handleSelectState = (e, value, reason, details) => {
    if (details?.option?.name === 'Select All') {
      handleChange('selectedStates', values.selectedStates?.length === states.length ? [] : states);
      return;
    }
    handleChange('selectedStates', value);
  };

  const onEditorStateChange = (state) => {
    setEditorState(state);
    const value = draftToHtml(convertToRaw(state.getCurrentContent()));
    handleChange('description', value);
  };

  const resetState = () => {
    setSelectedCountry(STOP_POINT_COUNTRY[0]);
    setPositionStatus([]);
    setStates([]);
    resetForm();
  };

  useEffect(() => {
    getPositionStatus();
    getRequirements();
    getQuestionary();
  }, []);

  useEffect(() => {
    if (!selectedCountry) {
      return;
    }

    getStatesData(selectedCountry.id);
  }, [selectedCountry]);

  useEffect(() => {
    if (data?.description) {
      const contentBlock = htmlToDraft(data.description);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [data?.description]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      onExited={resetState}
      headerTitle={data ? 'Update Job Position' : 'Add Job Position'}
      $bgColor={palette.gray0}
      $maxWidth='96vw'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
        },
        {
          key: 'submit',
          type: 'primary',
          title: data ? 'Update Job Position' : 'Add Job Position',
          className: 'modal-submit-btn',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div>
        <div className='mb-4'>
          <div className='job-position-form-row'>
            <Input
              required
              name='name'
              label='Position Name'
              value={values.name}
              onChange={handleChange}
              error={touchedErrors.name}
            />
          </div>
          <div className='job-position-form-row'>
            <div style={{ width: '25%' }}>
              <Autocomplete
                required
                label='Applicant Type'
                name='job_applicant_type'
                options={applicantTypes}
                value={values.job_applicant_type}
                onChange={(e, val) => handleChange('job_applicant_type', val)}
                placeholder='Select Applicant Type'
                error={touchedErrors.job_applicant_type}
              />
            </div>
          </div>
          <div className='company-setting-select job-position-form-row'>
            <div style={{ display: 'flex', columnGap: '16px' }}>
              <div style={{ width: '25%' }}>
                <InputLabel required>Country</InputLabel>
                <CustomSelect
                  value={selectedCountry}
                  onChange={(value) => {
                    setSelectedCountry(value);
                    handleChange('selectedStates', []);
                  }}
                  defaultActiveValue={selectedCountry}
                  options={STOP_POINT_COUNTRY}
                  styles={{ height: 32 }}
                />
              </div>
              <div style={{ flex: '1' }}>
                <Autocomplete
                  required
                  multiple
                  label='States'
                  name='selectedStates'
                  options={[{ name: 'Select All', id: 'all' }, ...states]}
                  limitTags={4}
                  value={values.selectedStates}
                  onChange={handleSelectState}
                  placeholder='Select State(s)'
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id ||
                    (option.name === 'Select All' && states?.length === values.selectedStates?.length)
                  }
                  error={touchedErrors.selectedStates}
                />
              </div>
            </div>
          </div>
          <div className='job-position-form-row'>
            <div className='position-status'>
              <InputLabel className='mb-0'>Status:</InputLabel>
              {positionStatus.map((status) => (
                <Radio
                  key={status.id}
                  label={status.name}
                  value={status.id}
                  checked={values.status === status.id}
                  onChange={() => handleChange('status', status.id)}
                />
              ))}
            </div>
            <ErrorMessage error={touchedErrors.status} />
          </div>
          <InputLabel>Description</InputLabel>
          <Editor
            editorState={editorState}
            toolbarClassName='toolbarClassName'
            wrapperClassName='wrapperClassName'
            editorClassName='editorClassName'
            editorStyle={{ height: '300px' }}
            onEditorStateChange={onEditorStateChange}
          />
          <ErrorMessage error={touchedErrors.description} />
        </div>

        <div className='mb-5'>
          <Typography variant='h5'>Acknowledgements</Typography>
          {acknowledgements.map((item) => (
            <div className='d-flex align-items-center gap-2 mt-2' key={item.id}>
              <CustomCheckbox
                name={item.name}
                type='switch'
                smail={false}
                checked={values[item.name]}
                onChange={(checked) => handleChange(item.name, checked)}
              />
              <Typography variant='s2'>{item.label}</Typography>
            </div>
          ))}
        </div>

        <div className='mb-5'>
          <Typography variant='h5'>Requirements</Typography>
          {requirements.map((item) => (
            <div className='d-flex align-items-center gap-2 mt-2' key={item.id}>
              <CustomCheckbox
                name={`requirement-${item.id}`}
                type='switch'
                smail={false}
                checked={values.requirement_ids.includes(item.id)}
                onChange={(checked) =>
                  handleChange(
                    'requirement_ids',
                    checked ? [...values.requirement_ids, item.id] : values.requirement_ids.filter((i) => i !== item.id)
                  )
                }
              />
              <Typography variant='s2'>{item.title}</Typography>
            </div>
          ))}
        </div>

        <div className='mb-5'>
          <Typography variant='h5'>Applicant Questions</Typography>
          {questions.map((item) => (
            <div className='d-flex align-items-center gap-2 mt-2' key={item.id}>
              <CustomCheckbox
                name={`questions-${item.id}`}
                type='switch'
                smail={false}
                checked={values.question_ids.includes(item.id)}
                onChange={(checked) =>
                  handleChange(
                    'question_ids',
                    checked ? [...values.question_ids, item.id] : values.question_ids.filter((i) => i !== item.id)
                  )
                }
              />
              <Typography variant='s2'>{item.question}</Typography>
            </div>
          ))}
        </div>

        <div>
          <Typography variant='h5'>Medical Examiners Card</Typography>
          <div className='d-flex align-items-center gap-2 mt-2'>
            <CustomCheckbox
              name='require_medical_examiners_card'
              type='switch'
              smail={false}
              checked={values.require_medical_examiners_card}
              onChange={(checked) => handleChange('require_medical_examiners_card', checked)}
            />
            <Typography variant='s2'>Require Medical Examiners Card</Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddJobPosition;
