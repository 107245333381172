import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { getReview, getReviewSettings, getStaffBasic } from 'Api/User';
import RateUsModal from './RateUsModal';
import { SSnackbar } from './RateUs.styles';

const RateUs = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [openRateUs, setOpenRateUs] = useState(false);
  const [reviewSettings, setReviewSettings] = useState(null);
  const [review, setReview] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const { value } = useAuth();

  const getSettings = async () => {
    try {
      const { data } = await getReviewSettings();

      if (data?.[0]) {
        setReviewSettings(data?.[0] || null);
        const { review, user_setting } = await getReview();

        if (user_setting.review_count > 2) {
          return;
        }

        setReview(review);
        if (!user_setting.next_review_date || moment().isAfter(moment(user_setting.next_review_date).startOf('day'))) {
          setOpenNotification(true);
        }
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getUserDetails = async () => {
    try {
      const { data } = await getStaffBasic(value.user?.id);
      setUserDetails(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  useEffect(() => {
    getSettings();
    getUserDetails();
  }, []);

  if (
    !reviewSettings ||
    !reviewSettings?.is_on ||
    review ||
    (userDetails?.hire_date && moment().diff(userDetails.hire_date, 'days') < 14)
  ) {
    return null;
  }

  return (
    <div>
      <SSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotification}
        onClose={onSnackbarClose}
        onClick={() => setOpenRateUs(true)}
        message={
          <div className='d-flex justify-content-between align-items-center gap-3'>
            <StarIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              {reviewSettings.display_text}
            </Typography>
            <StarIcon />
          </div>
        }
      />
      {openRateUs && (
        <RateUsModal
          open={openRateUs}
          onClose={() => setOpenRateUs(false)}
          onSuccess={() => {
            getSettings();
            setOpenNotification(false);
          }}
          reviewSettings={reviewSettings}
        />
      )}
    </div>
  );
};

export default RateUs;
