import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { InputWithIcon, Textarea } from 'common/Input';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { LicenseType } from 'Api/Driver';
import { updateAlert, uploadDriverDocument } from 'Api/AlertCenter';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { statusOptions } from '../TakeActions.data';
import { validationSchema } from './validationSchema';

const LicenseExpiration = ({ open, onClose, alert, onSuccess }) => {
  const showToaster = useShowToaster();
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const attachment =
        typeof values.driver_licence_upload === 'string'
          ? values.driver_licence_upload
          : await uploadDocument(values.driver_licence_upload);

      const backAttachment =
        typeof values.driver_licence_back_upload === 'string'
          ? values.driver_licence_back_upload
          : await uploadDocument(values.driver_licence_back_upload);

      const body = {
        driver_id: alert.driver_id,
        driver_licence_upload: attachment,
        driver_licence_back_upload: backAttachment,
        license_exp_dt: moment(values.license_exp_dt).format('YYYY-MM-DD'),
        license_no: values.license_no,
        license_type: values.license_type.name,
        alert_notes: values.alert_notes,
        status: values.status.value,
      };
      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Driver license has been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: {
      license_no: alert?.license_no || '',
      license_exp_dt: moment().isBefore(moment(alert?.license_exp_dt)) ? new Date(alert.license_exp_dt) : null,
      license_type: null,
      driver_licence_upload: alert?.driver_licence_upload || '',
      driver_licence_back_upload: alert?.driver_licence_back_upload || '',
      alert_notes: alert?.alert_notes || '',
      status: statusOptions.find((i) => i.name === alert?.status) || null,
    },
    validationSchema,
    onSubmit,
  });

  const getLicenseTypes = async () => {
    try {
      const { data } = await LicenseType();
      setLicenseTypes(data);

      if (alert?.license_type) {
        handleChange(
          'license_type',
          data.find((i) => i.name === alert.license_type)
        );
      }
    } catch (e) {
      // Do nothing
    }
  };

  const uploadDocument = async (file) => {
    try {
      const formData = new FormData();
      formData.append('driver_id', alert.driver_id);
      formData.append('source', 'general');
      formData.append('file_upload', file);

      const { document_path } = await uploadDriverDocument(formData);
      return document_path;
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getLicenseTypes();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      $maxWidth='800px'
      $minWidth='800px'
      $bgColor={palette.gray0}
      padding='0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <div className='d-flex gap-4 mb-4'>
            <InputWithIcon
              required
              icon='#'
              type='number'
              name='license_no'
              label='License Number'
              value={values.license_no}
              onChange={handleChange}
              error={touchedErrors.license_no}
              className='w-100'
            />
            <div>
              <DatePicker
                required
                width='150px'
                label='New Expiration Date'
                name='license_exp_dt'
                onBlur={handleBlur}
                value={values.license_exp_dt}
                onChange={(val) => handleChange('license_exp_dt', val)}
                disablePast
                error={touchedErrors.license_exp_dt}
              />
            </div>
            <div className='w-100'>
              <Autocomplete
                required
                width='100%'
                label='License Type'
                name='license_type'
                options={licenseTypes}
                value={values.license_type}
                onChange={(e, val) => handleChange('license_type', val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors.license_type}
              />
            </div>
          </div>
          <div className='mb-4'>
            <InputLabel> License</InputLabel>
            <div className='d-flex gap-4'>
              <Uploader
                required
                width='300px'
                label='Front Side'
                document={values.driver_licence_upload}
                onDrop={(files) => handleChange('driver_licence_upload', files[0])}
                onRemove={() => handleChange('driver_licence_upload', null)}
                accept={['application/pdf', 'image/jpeg', 'image/png']}
                error={touchedErrors.driver_licence_upload}
              />
              <Uploader
                required
                width='300px'
                label='Back Side'
                document={values.driver_licence_back_upload}
                onDrop={(files) => handleChange('driver_licence_back_upload', files[0])}
                onRemove={() => handleChange('driver_licence_back_upload', null)}
                accept={['application/pdf', 'image/jpeg', 'image/png']}
                error={touchedErrors?.driver_licence_back_upload}
              />
            </div>
          </div>
          <div className='mb-4'>
            <Autocomplete
              required
              width='300px'
              label='Action'
              name='status'
              options={statusOptions}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.status}
            />
          </div>
          <Textarea rows={3} label='Notes' name='alert_notes' value={values.alert_notes} onChange={handleChange} />
        </div>
      </div>
    </Modal>
  );
};

export default LicenseExpiration;
