import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { getDriverStaffList, updateDriverTransaction } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { SBackdrop, SCustomModal, SPaper, STabs } from './LinkTransaction.styles';

const LinkTransaction = ({ open, onClose, assignData, onSuccess }) => {
  const showToaster = useShowToaster();
  const [driverStaff, setDriverStaff] = useState([]);
  const [assignToTab, setAssignToTab] = useState('all');
  const [openPopover, setOpenPopover] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('user_id', values.assign_to.id);
      const body = {
        driver_id: values.assign_to.id,
      };

      await updateDriverTransaction(assignData.id, body);

      showToaster({ type: 'success', message: 'User has been successfully assigned' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      assign_to: null,
    },
    onSubmit,
    validationSchema,
  });

  const getDriverStaff = async () => {
    try {
      const { data } = await getDriverStaffList({});
      setDriverStaff(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDriverStaff();
  }, []);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Assign Transaction'
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Assign Transaction',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        <div>
          <Autocomplete
            required
            open={openPopover}
            labelKey='full_name'
            label='Assign To'
            value={values.assign_to}
            inputProps={{
              onClick: () => setOpenPopover(true),
            }}
            onChange={(e, val) => {
              handleChange('assign_to', val);
              setOpenPopover(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={driverStaff.filter((i) =>
              [44, 62].includes(assignData.provider_id)
                ? i.user_type === 'driver'
                : assignToTab === 'all' || i.user_type === assignToTab
            )}
            error={touchedErrors.assign_to}
            PaperComponent={({ children, ...props }) => {
              return (
                <ClickAwayListener onClickAway={() => setOpenPopover(false)}>
                  <SPaper {...props}>
                    {assignData.provider_id !== 44 && (
                      <STabs
                        value={assignToTab}
                        onChange={(e, tab) => setAssignToTab(tab)}
                        variant='scrollable'
                        scrollButtons={false}
                      >
                        <Tab value='all' label='All' disableRipple />
                        <Tab value='driver' label='Driver' disableRipple />
                        <Tab value='staff' label='Staff' disableRipple />
                      </STabs>
                    )}
                    {children}
                  </SPaper>
                </ClickAwayListener>
              );
            }}
          />
        </div>
      </div>
      <SBackdrop open={loading}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default LinkTransaction;
