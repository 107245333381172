import { array, number, object, string } from 'yup';

export const validationSchema = object({
  contains_type: string().trim().required('Required'),
  match_pattern: string().trim().required('Required'),
  contains: array().of(
    object({
      value: string().trim().required('Required'),
    })
  ),
  account: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  payee: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
});
