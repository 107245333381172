import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { blockNotNumberChars } from 'utils/helpers';
import Origin from './components/Origin';
import Paperwork from './components/Paperwork';
import DriverPay from './components/DriverPay';
import ShipmentId from './components/ShipmentId';
import { compensationTypes } from '../../helpers';
import Destination from './components/Destination';
import NoRecords from '../../components/NoRecords';
import DeclineReason from '../../components/DeclineReason';
import DisputedActions from '../../../shared/DisputedActions';
import { SInput, STable, SWrapper } from '../Tables.styles';

const Shipments = ({ shipments, updateShipments, settlement }) => {
  const { use } = useTheme();
  const { formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const { pay_option } = settlement?.user?.compensation_details || {};
  const canEdit = pay_option !== 'per_hour';
  const [actionsVisible, setActionsVisible] = useState(false);
  const [shipmentToDecline, setShipmentToDecline] = useState(null);
  const [shipmentToEdit, setShipmentToEdit] = useState(null);

  const onMouseEnter = (row) => {
    setActionsVisible(row.id);
    const newShipments = shipments.map((item) =>
      item.shipment_id === row.shipment_id ? { ...item, edit: true } : { ...item, edit: false }
    );
    updateShipments(newShipments);
  };

  const onMouseLeave = (row) => {
    setActionsVisible(null);
    const newShipments = shipments.map((item) =>
      item.shipment_id === row.shipment_id ? { ...item, edit: false } : item
    );
    updateShipments(newShipments);
  };

  const onLoadedMilesChange = (shipment_id, value) => {
    const newShipments = shipments.map((item) => {
      const newDriverPay = item.driver_pay.map((el) =>
        el.type === 'loaded_miles' ? { ...el, quantity: Number(value), total: Number(value) * el.rate } : el
      );
      const newDriverAmount = newDriverPay
        .filter((el) => compensationTypes.includes(el.type))
        .reduce((acc, cur) => acc + cur.total, 0);

      if (item.shipment_id === shipment_id) {
        return {
          ...item,
          loaded_miles: value,
          driver_amount: newDriverAmount,
          driver_pay: newDriverPay,
        };
      }
      return item;
    });
    updateShipments(newShipments);
  };

  const onEmptyMilesChange = (shipment_id, value) => {
    const newShipments = shipments.map((item) => {
      const newDriverPay = item.driver_pay.map((el) =>
        el.type === 'empty_miles' ? { ...el, quantity: Number(value), total: Number(value) * el.rate } : el
      );
      const newDriverAmount = newDriverPay
        .filter((el) => compensationTypes.includes(el.type))
        .reduce((acc, cur) => acc + cur.total, 0);

      if (item.shipment_id === shipment_id) {
        return {
          ...item,
          empty_miles: value,
          driver_amount: newDriverAmount,
          driver_pay: newDriverPay,
        };
      }
      return item;
    });
    updateShipments(newShipments);
  };

  const onDriverPayChange = (shipment_id, driver_pay, driver_amount) => {
    const newEmptyMiles = driver_pay.find((el) => el.type === 'empty_miles');
    const newLoadedMiles = driver_pay.find((el) => el.type === 'loaded_miles');

    const newShipments = shipments.map((item) => {
      if (item.shipment_id === shipment_id) {
        return {
          ...item,
          driver_amount,
          driver_pay,
          loaded_miles: newLoadedMiles?.quantity || item.loaded_miles,
          empty_miles: newEmptyMiles?.quantity || item.empty_miles,
        };
      }
      return item;
    });
    updateShipments(newShipments);
    setShipmentToEdit(false);
  };

  const onDecline = async (reason) => {
    const newShipments = shipments.map((item) =>
      item.shipment_id === shipmentToDecline.shipment_id
        ? { ...item, dispute_declined: true, dispute_declined_reason: reason }
        : item
    );
    updateShipments(newShipments);
    setShipmentToDecline(null);
  };

  const onApprove = (shipment) => {
    const newShipments = shipments.map((item) =>
      item.shipment_id === shipment.shipment_id ? { ...item, dispute_approved: true } : item
    );
    updateShipments(newShipments);
  };

  return (
    <SWrapper>
      <Typography variant='h3'>Shipments</Typography>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>SHIPMENT ID</th>
            <th>PAPERWORK</th>
            <th>ORIGIN</th>
            <th>DESTINATION</th>
            <th>COMPLETED DATE</th>
            <th>STOPS</th>
            <th>LOADED MILES</th>
            <th>EMPTY MILES</th>
            {canEdit && <th>AMOUNT</th>}
          </tr>
        </thead>
        <tbody>
          {shipments.map((item) => (
            <Fragment key={item.shipment_id}>
              <tr
                className={`body-row ${item.disputed && settlement.status_id === 4 ? 'disputed' : ''} ${
                  item.dispute_approved ? 'dispute-approved' : ''
                }`}
                onMouseEnter={() => onMouseEnter(item)}
                onMouseLeave={() => onMouseLeave(item)}
              >
                <td style={{ width: '8%' }}>
                  <ShipmentId data={item} />
                </td>
                <td style={{ width: '10%' }}>
                  <Paperwork data={item} />
                </td>
                <td style={{ width: '20%' }}>
                  <Origin data={item} />
                </td>
                <td style={{ width: '18%' }}>
                  <Destination data={item} />
                </td>
                <td style={{ width: '12%' }}>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {item?.shipment_stops?.length
                      ? formatDateTime(
                          item.tonu_date_time || item.shipment_stops[item.shipment_stops.length - 1].departure_date
                        )
                      : 'N/A'}
                  </Typography>
                </td>
                <td style={{ width: '6%' }}>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                    {item?.stops_count || 0}
                  </Typography>
                </td>
                <td style={{ width: canEdit ? '8%' : '15%' }}>
                  {item.edit && !item.tonu_date_time ? (
                    <SInput
                      type='number'
                      value={Math.round(item.loaded_miles || 0)}
                      onChange={(e) => onLoadedMilesChange(item.shipment_id, e.target.value)}
                      onKeyDown={blockNotNumberChars}
                    />
                  ) : (
                    <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {Math.round(item.loaded_miles || 0)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: canEdit ? '8%' : '15%' }}>
                  {item.edit && !item.tonu_date_time ? (
                    <SInput
                      type='number'
                      value={Math.round(item.empty_miles || 0)}
                      onChange={(e) => onEmptyMilesChange(item.shipment_id, e.target.value)}
                      onKeyDown={blockNotNumberChars}
                    />
                  ) : (
                    <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {Math.round(item.empty_miles || 0)}
                    </Typography>
                  )}
                </td>
                {canEdit && (
                  <td style={{ width: '10%' }}>
                    {item.edit && !item.tonu_date_time ? (
                      <div className='d-flex align-items-center gap-1'>
                        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                          {currency}
                          {item.driver_amount ? `${Number(item.driver_amount).toFixed(2)}` : ''}
                        </Typography>
                        <EditIcon width='12' height='12' className='pointer' onClick={() => setShipmentToEdit(item)} />
                      </div>
                    ) : (
                      <div className='d-flex flex-column'>
                        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                          {currency}
                          {item.driver_amount ? `${Number(item.driver_amount).toFixed(2)}` : ''}
                        </Typography>
                        {item.driver_pay?.[0]?.type === 'flat_rate' && (
                          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
                            Flat Rate
                          </Typography>
                        )}
                      </div>
                    )}
                  </td>
                )}
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''} ${
                    item.dispute_approved ? 'dispute-approved-actions' : ''
                  }`}
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={() => onMouseLeave(item)}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setShipmentToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </STable>
      {!shipments.length && <NoRecords />}
      <DeclineReason open={!!shipmentToDecline} onClose={() => setShipmentToDecline(null)} onSubmit={onDecline} />
      {!!shipmentToEdit && (
        <DriverPay
          open={!!shipmentToEdit}
          onClose={() => setShipmentToEdit(null)}
          shipment={shipmentToEdit}
          onApply={onDriverPayChange}
        />
      )}
    </SWrapper>
  );
};

export default Shipments;
