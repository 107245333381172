import React, { useEffect, useState } from 'react';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import useDebounce from 'hooks/useDebounce';
import { palette } from 'utils/constants';
import { getReferrersList } from 'Api/Applicants';
import { initialFilters, useReferrersColumns } from './ReferrersTable.data';

import Search from '../Search';
import TableFooter from '../TableFooter';
import { STableWrapper } from '../../ApplicantsAndReferrals.styles';
import AddReferrer from '../AddReferrer';

const ReferrersTable = ({ addReferrerOpen, onAddReferrerClose }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [referrers, setReferrers] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [tableIndex, setTableIndex] = useState(Date.now());
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getReferrers = async () => {
    setLoading(true);
    const { page, itemsPerPage, ...rest } = selectedFilters || {};

    try {
      const params = {
        page,
        itemsPerPage,
        query: debouncedSearch || undefined,
        ...rest,
      };

      const response = await getReferrersList(params);
      setReferrers(response);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
    setLoading(false);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const onDeleteSuccess = () => {
    if (selectedFilters.page > 1 && referrers.data.length === 1) {
      setSelectedFilters((prevState) => ({ ...selectedFilters, page: prevState.page - 1 }));
      return;
    }
    setTableIndex(Date.now());
  };

  useEffect(() => {
    getReferrers();
  }, [selectedFilters, debouncedSearch, tableIndex]);

  const PaginationComponent = (event) => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.itemsPerPage}
      totalCount={referrers.total}
      totalLength={referrers.data.length}
      lastPage={referrers.last_page}
      currentPage={referrers.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  const columns = useReferrersColumns({ onDeleteSuccess });

  return (
    <STableWrapper>
      <Search search={search} onChange={setSearch} margin='0 0 24px' />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={referrers?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
      {!!addReferrerOpen && (
        <AddReferrer open={addReferrerOpen} onClose={onAddReferrerClose} onSuccess={() => setTableIndex(Date.now())} />
      )}
    </STableWrapper>
  );
};

export default ReferrersTable;
