import { palette } from 'utils/constants';
import uuid from 'react-uuid';
import moment from 'moment';
import {
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  SCHEDULED_DATE_TYPE_OBJ,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';

export const getStopName = (type) => {
  const TYPE_STOPS = {
    1: { type: ' : PICK UP', color: palette.green500 },
    2: { type: ' : DELIVERY', color: palette.red500 },
    3: { type: ' : WAYPOINT', color: palette.blueText },
  };
  return TYPE_STOPS[type];
};

export const getInitialValues = (sections, dimensions) => {
  const initialStops = [];
  const allStops = sections.flat();
  const pickup = allStops.find((i) => !i?.isEmpty && Number(i.stop_point_type) === 1);
  const delivery = allStops.find((i) => !i?.isEmpty && Number(i.stop_point_type) === 2);

  sections.forEach((section, index) => {
    section.forEach((stop, i) => {
      if (stop?.isEmpty) {
        initialStops.push({
          id: stop.id || uuid(),
          stop_point_type: stop.stop_point_type,
          scheduled_type:
            (Number(stop.stop_point_type) === 1
              ? SCHEDULED_DATE_TYPE_OBJ[pickup.scheduled_type]
              : SCHEDULED_DATE_TYPE_OBJ[delivery.scheduled_type]) || null,
          scheduled_date:
            (Number(stop.stop_point_type) === 1
              ? moment(pickup.scheduled_date).toDate()
              : moment(delivery.scheduled_date).toDate()) || null,
          scheduled_date_to:
            (Number(stop.stop_point_type) === 1
              ? moment(pickup.scheduled_date_to).toDate()
              : moment(delivery.scheduled_date_to).toDate()) || null,
          from: (Number(stop.stop_point_type) === 1 ? pickup.from : delivery.from) || '',
          to: (Number(stop.stop_point_type) === 1 ? pickup.to : delivery.to) || '',
          equipment_type: (Number(stop.stop_point_type) === 1 ? pickup.equipment_type_data : null) || null,
          equipment_type_length:
            (Number(stop.stop_point_type) === 1
              ? dimensions.find(
                  (item) =>
                    item.length_info?.id === Number(pickup.equipment_type_length) &&
                    item.equipment_type_id === pickup.equipment_type_data?.id
                )
              : null) || null,
          equipment_action:
            (Number(stop.stop_point_type) === 1
              ? EQUIPMENT_ACTION.find((i) => i.key === Number(pickup.equipment_action))
              : EQUIPMENT_ACTION_DELIVERY.find((i) => i.key === Number(delivery.equipment_action))) || null,
          equipment_id: (Number(stop.stop_point_type) === 1 ? pickup.equipment : null) || null,
          stopIndex: i,
          sectionIndex: index,
          custom_trailer_id: '',
          custom_trailer_type: null,
          custom_trailer_length: null,
        });
      }
    });
  });

  return {
    stop_point: null,
    stops: initialStops,
  };
};

export const createStopConverter = (stop, stopPoint, delivery, shipmentId, allStops) => {
  const formData = new FormData();
  formData.append('for_split', 1);
  formData.append('stop_point_id', stopPoint.id);
  formData.append('shipment_id', shipmentId);
  formData.append('leg', 1);
  formData.append('order_in_leg', allStops.findIndex((i) => i.id === stop.id) + 1);

  // Data copied from delivery (non billable)
  if (delivery.stop_notes) {
    formData.append('stop_notes', delivery.stop_notes);
  }

  switch (Number(stop.stop_point_type)) {
    case 1:
      formData.append('stop_point_type', '1');
      formData.append('scheduled_type', stop.scheduled_type.key);
      formData.append('scheduled_date', moment(stop.scheduled_date).format('YYYY-MM-DD'));
      formData.append('from', stop.from);
      formData.append('to', stop.to || stop.from);
      formData.append('equipment_type', stop.equipment_type.id);
      formData.append('equipment_type_length', stop.equipment_type_length?.id || '');
      formData.append('equipment_id', stop.equipment_id?.id || '');
      formData.append('equipment_action', stop.equipment_action?.key || '');
      formData.append('equipment_tbd', stop.equipment_tbd ? 1 : 0);
      formData.append('bill_type', 2);
      formData.append('dock_high', delivery.dock_high || 0);
      formData.append('stackable', delivery.stackable || 0);
      formData.append('liftgate_service', delivery.liftgate_service || 0);
      formData.append('labor_required', delivery.labor_required || 0);
      formData.append('hazardous_materials', delivery.hazardous_materials || 0);
      return formData;
    case 2:
      formData.append('stop_point_type', '2');
      formData.append('scheduled_type', stop.scheduled_type.key);
      formData.append('scheduled_date', moment(stop.scheduled_date).format('YYYY-MM-DD'));
      formData.append('from', stop.from);
      formData.append('to', stop.to || stop.from);
      formData.append('equipment_action', stop.equipment_action?.key || '');
      formData.append('equipment_tbd', stop.equipment_tbd ? 1 : 0);
      formData.append('bill_type', 2);
      formData.append('dock_high', delivery.dock_high || 0);
      formData.append('labor_required', delivery.labor_required || 0);
      return formData;
    default:
  }
};
