import styled from 'styled-components';

export const SDisconnectQuickbooks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .loading-logo {
    animation: leaves 2s ease-in-out infinite alternate;
    z-index: 5;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    transform-origin: 0 0;
    margin: 0 auto 30px;
  }

  @keyframes leaves {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`;
