import React, { useEffect, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import InputLabel from 'common/InputLabel';
import { DateTimePicker } from 'common/Pickers';
import AddressFields from 'common/AddressFields';
import ActionButtons from 'common/ActionButtons';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import StopPointAutocomplete from 'componentsV2/StopPoint/StopPointAutocomplete';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { transformYupErrorsIntoObject } from 'utils/helpers';
import { calculateStopsDistance, createQuoteLeg, deleteQuoteLeg, updateQuoteLeg } from 'Api/Shipment';
import InfoItem from 'componentsV2/Planner/Quotes/CreateQuote/components/InfoItem';
import { legValuesConverter } from 'componentsV2/Planner/Quotes/CreateQuote/converters';
import { validationSchema } from 'componentsV2/Planner/Quotes/CreateQuote/validationSchema';

const Deadhead = ({ form, leg, legIndex, quote, stopPoints, getStopPoints }) => {
  const showToaster = useShowToaster();
  const { formatDateTime } = useDateFormat();
  const [updateLegLoading, setUpdateLegLoading] = useState(false);
  const [deleteLegLoading, setDeleteLegLoading] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [loadingDistance, setLoadingDistance] = useState(false);
  const controller = useRef(new AbortController());

  const editMode = !!quote;

  const { values, handleChange, touchedErrors, setErrors, setTouched } = form;
  const errors = touchedErrors?.legs?.[legIndex];

  const onSaveLeg = async (leg) => {
    try {
      if (updateLegLoading || deleteLegLoading) {
        return;
      }

      await validationSchema.validateAt(
        `legs[${legIndex}]`,
        { legs: values.legs.map((item) => ({ ...item, stops: [] })) },
        {
          strict: true,
          abortEarly: false,
        }
      );

      setUpdateLegLoading(true);

      let newItem;

      if (leg.isNew) {
        const body = { quote_id: quote.id, ...legValuesConverter(leg, legIndex) };

        const { data } = await createQuoteLeg(body);
        newItem = data;
      } else {
        const body = legValuesConverter(leg, legIndex);

        await updateQuoteLeg(leg.id, body);
      }

      showToaster({
        type: 'success',
        message: `Leg has been ${leg.isNew ? 'added' : 'updated'} successfully!`,
      });

      handleChange(`legs[${legIndex}]`, { ...leg, id: newItem?.id || leg.id, isEdit: false, isNew: false });
    } catch (e) {
      const { validationErrors, validationTouched } = transformYupErrorsIntoObject(e);

      if (Object.keys(validationErrors)?.length) {
        setErrors(validationErrors, { shouldValidate: false });
        setTouched(validationTouched, { shouldValidate: false });
        return;
      }

      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setUpdateLegLoading(false);
    }
  };

  const onDeleteLeg = async (leg, confirmed) => {
    try {
      if (updateLegLoading || deleteLegLoading) {
        return;
      }

      if (!leg.isNew) {
        if (!confirmed) {
          setOpenDeleteConfirm(true);
          return;
        }

        setDeleteLegLoading(true);
        await deleteQuoteLeg(leg.id);
        showToaster({ type: 'success', message: `Leg has been deleted successfully!` });
      }

      handleChange(
        `legs`,
        values.legs.filter((i) => i.id !== leg.id)
      );
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e.message) });
    } finally {
      setDeleteLegLoading(null);
    }
  };

  const onEditLeg = () => {
    handleChange(`legs[${legIndex}].isEdit`, true);
  };

  const handleAddressChange = (event, value) => {
    const addressFieldsMapper = {
      country: `legs[${legIndex}].deadhead_country`,
      state: `legs[${legIndex}].deadhead_state`,
      city: `legs[${legIndex}].deadhead_city`,
      zipcode: `legs[${legIndex}].deadhead_zipcode`,
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  const calculateCurrentAndNextStopDistance = async () => {
    try {
      const nextStop = values.legs[legIndex].stops[0];

      if (
        !nextStop ||
        (!leg.deadhead_stop_point &&
          (!leg.deadhead_country || !leg.deadhead_state || !leg.deadhead_city || !leg.deadhead_zipcode)) ||
        (!nextStop?.stop_point && (!nextStop?.country || !nextStop?.state || !nextStop?.city || !nextStop?.zipcode))
      ) {
        return;
      }

      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      setLoadingDistance(true);

      const body = {
        stops: [
          {
            stop_point_id: leg.deadhead_stop_point ? leg.deadhead_stop_point.id : null,
            country: !leg.deadhead_stop_point && leg.deadhead_country ? leg.deadhead_country.name : null,
            state: !leg.deadhead_stop_point && leg.deadhead_state ? leg.deadhead_state.short_name : null,
            city: !leg.deadhead_stop_point && leg.deadhead_city ? leg.deadhead_city.name : null,
            zipcode: !leg.deadhead_stop_point && leg.deadhead_zipcode ? leg.deadhead_zipcode : null,
          },
          {
            stop_point_id: nextStop.stop_point ? nextStop.stop_point.id : null,
            country: !nextStop.stop_point && nextStop.country ? nextStop.country.name : null,
            state: !nextStop.stop_point && nextStop.state ? nextStop.state.short_name : null,
            city: !nextStop.stop_point && nextStop.city ? nextStop.city.name : null,
            zipcode: !nextStop.stop_point && nextStop.zipcode ? nextStop.zipcode : null,
          },
        ],
      };

      const data = await calculateStopsDistance(body);
      handleChange(`legs[${legIndex}].distance_to_next_stop`, data?.loaded_miles);
    } catch (e) {
      handleChange(`legs[${legIndex}].distance_to_next_stop`, null);
      // Do nothing
    } finally {
      setLoadingDistance(false);
    }
  };

  useEffect(() => {
    calculateCurrentAndNextStopDistance();
  }, [
    leg.deadhead_stop_point,
    leg.deadhead_country,
    leg.deadhead_state,
    leg.deadhead_city,
    leg.deadhead_zipcode,
    values.legs[legIndex].stops[0]?.stop_point,
    values.legs[legIndex].stops[0]?.country,
    values.legs[legIndex].stops[0]?.state,
    values.legs[legIndex].stops[0]?.city,
    values.legs[legIndex].stops[0]?.zipcode,
  ]);

  return (
    <div>
      <div className='d-flex align-items-center justify-content-end'>
        <div style={{ marginTop: '-12px', marginRight: '-12px' }} className='mb-1'>
          {editMode && (
            <ActionButtons
              data={leg}
              onEdit={() => onEditLeg()}
              onSave={() => onSaveLeg(leg)}
              onDelete={legIndex > 0 ? () => onDeleteLeg(leg) : undefined}
              loadingSave={updateLegLoading}
              loadingDelete={deleteLegLoading}
            />
          )}
        </div>
      </div>
      <div className='d-flex justify-content-between gap-4 mb-3'>
        {editMode && !leg.isEdit && !leg.isNew ? (
          <InfoItem
            title='DEADHEAD'
            titleProps={{ variant: 'c3', style: { color: palette.orange500 } }}
            content={
              leg.deadhead_stop_point
                ? `${leg.deadhead_stop_point.location_name} - ${leg.deadhead_stop_point.address1 || ''}, ${
                    leg.deadhead_stop_point.city?.name || ''
                  }, ${leg.deadhead_stop_point.state?.short_name || ''} ${leg.deadhead_stop_point.zipcode || ''}`
                : leg.deadhead_country || leg.deadhead_state || leg.deadhead_city || leg.deadhead_zipcode
                ? `${leg.deadhead_country?.name ? `${leg.deadhead_country?.name},` : ''}${
                    leg.deadhead_city?.name ? ` ${leg.deadhead_city?.name},` : ''
                  } ${leg.deadhead_state?.short_name || ''} ${leg.deadhead_zipcode || ''}`
                : '-'
            }
          />
        ) : (
          <>
            <StopPointAutocomplete
              showAddStop
              size='small'
              width='500px'
              label='DEADHEAD'
              labelProps={{ variant: 'c3', style: { color: palette.orange500 } }}
              options={stopPoints}
              onAddStopPoint={() => getStopPoints(true)}
              name={`legs[${legIndex}].deadhead_stop_point`}
              value={leg.deadhead_stop_point}
              onChange={(e, val) => handleChange(`legs[${legIndex}].deadhead_stop_point`, val)}
              error={
                errors?.deadhead_stop_point ||
                errors?.deadhead_country ||
                errors?.deadhead_state ||
                errors?.deadhead_city ||
                errors?.deadhead_zipcode
                  ? 'One of stop point or country/state/city/zipcode is required'
                  : null
              }
            />
            <Typography variant='c1' style={{ color: palette.gray700 }} className='align-self-center'>
              OR
            </Typography>
            <div className='w-100'>
              <div className='empty-label' />
              <AddressFields
                gap='8px'
                hideLabel
                hideAddress
                limitCountries
                size='small'
                values={{
                  country: leg.deadhead_country,
                  state: leg.deadhead_state,
                  city: leg.deadhead_city,
                  zipcode: leg.deadhead_zipcode,
                }}
                handleChange={(event, value) => handleAddressChange(event, value)}
                touchedErrors={errors}
              />
            </div>
          </>
        )}
        <div>
          {editMode && !leg.isEdit && !leg.isNew ? (
            <InfoItem
              title='Start Date'
              titleProps={{ variant: 's2' }}
              content={leg.deadhead_start_date ? formatDateTime(leg.deadhead_start_date) : '-'}
            />
          ) : (
            <>
              <div className='empty-label' />
              <DateTimePicker
                size='small'
                width='200px'
                name={`legs[${legIndex}].deadhead_start_date`}
                value={leg.deadhead_start_date}
                onChange={(date) => handleChange(`legs[${legIndex}].deadhead_start_date`, date)}
                error={errors?.deadhead_start_date}
              />
            </>
          )}
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center gap-3'>
        <InputLabel className='mb-0' variant='c2'>
          DISTANCE TO STOP A
        </InputLabel>
        {loadingDistance ? (
          <Skeleton width={80} height={18} />
        ) : (
          <Typography variant='c2'>
            {leg.distance_to_next_stop || leg.distance_to_next_stop === 0
              ? Math.round(leg.distance_to_next_stop)
              : '???'}{' '}
            MILES
          </Typography>
        )}
      </div>
      {openDeleteConfirm && (
        <ConfirmationModal
          width='360px'
          open={!!openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
          headerTitle='Remove Lane'
          text={`Are you sure you want to remove lane ${legIndex + 1}?`}
          onConfirm={() => {
            onDeleteLeg(leg, true);
            setOpenDeleteConfirm(false);
          }}
          buttonProps={{ title: 'Delete' }}
        />
      )}
    </div>
  );
};

export default Deadhead;
