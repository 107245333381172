import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Input from 'common/Input';
import Divider from 'common/Divider';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { getNotificationSettings, updateNotificationSettings } from 'Api/PayrollSettings';
import PageHeader from '../../../components/PageHeader';
import { updateNotificationSettingsConverter } from '../converters';
import { initialValues } from './NotificationAndEmail.data';
import { notificationAndEmailValidationSchema } from '../validationSchemas';
import { SCircularProgress, SWrapper } from '../Payroll.styles';

const NotificationsAndEmail = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const updateSettings = async (values) => {
    try {
      setLoadingUpdate(true);
      const body = updateNotificationSettingsConverter(values);
      await updateNotificationSettings(body);
      showToaster({ type: 'success', message: 'Settings have been successfully updated' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingUpdate(false);
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues,
    validationSchema: notificationAndEmailValidationSchema,
    onSubmit: updateSettings,
  });

  const getSettings = async () => {
    try {
      setLoading(true);
      const { data } = await getNotificationSettings();
      handleChange('settlement_done_email_driver', data.settlement_done_email_driver);
      handleChange('settlement_done_push__driver', data.settlement_done_push__driver);
      handleChange('correspondence_email', data.correspondence_email || '');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSettings();
  }, []);

  if (loading) {
    return (
      <SWrapper>
        <SCircularProgress size={26} />
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      <PageHeader
        title='Payroll Notifications'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
      />
      <Divider />
      <Grid container alignItems='center'>
        <CustomCheckbox
          type='switch'
          name='settlement_done_email_driver'
          checked={!!values.settlement_done_email_driver}
          onChange={(checked) => handleChange('settlement_done_email_driver', checked)}
          smail={false}
        />
        <Grid item>
          <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
            Send settlement to driver email upon processing
          </Typography>
        </Grid>
      </Grid>
      <Divider margin='16px 0' />
      <Grid container alignItems='center'>
        <CustomCheckbox
          type='switch'
          name='settlement_done_push__driver'
          checked={!!values.settlement_done_push__driver}
          onChange={(checked) => handleChange('settlement_done_push__driver', checked)}
          smail={false}
        />
        <Grid item>
          <Typography variant='s2' style={{ marginLeft: 16, color: use(palette.gray700, palette.gray200) }}>
            Send push notification to driver app upon processing
          </Typography>
        </Grid>
      </Grid>
      <Divider margin='16px 0' />
      <Grid container>
        <Grid item style={{ marginTop: '4px' }}>
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), marginRight: 16 }}>
            Payroll Correspondence Email
          </Typography>
        </Grid>
        <Input
          type='text'
          name='correspondence_email'
          value={values.correspondence_email}
          onChange={handleChange}
          error={touchedErrors.correspondence_email}
        />
      </Grid>
    </SWrapper>
  );
};

export default NotificationsAndEmail;
