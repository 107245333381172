import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .owner-equip-container {
    border: none;
  }

  .owner-equip-container .header-container {
    height: max-content;
  }

  .owner-equip-container .table-wrap {
    margin-top: 0;
    overflow: auto;
    max-height: 470px;

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 8px;
    }
  }
`;

export const SListWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  height: 100%;

  .list-item {
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;

    :hover {
      background-color: ${palette.indigo0};
      & > div > span,
      & > span {
        color: ${palette.indigo500} !important;
      }
    }
  }
`;
