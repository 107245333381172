import jsPDF from 'jspdf';
import moment from 'moment';
import JsBarcode from 'jsbarcode';
import mailIcon from 'assets/icons/mail.png';
import globeIcon from 'assets/icons/globe.png';
import phoneIcon from 'assets/icons/phone.png';
import locationIcon from 'assets/icons/location.png';
import { CURRENCY, formatNumber, palette } from 'utils/constants';
import { getCargoTotals } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { getQuoteStartEndDate, getQuoteTotalDistance } from 'componentsV2/Planner/Quotes/CreateQuote/CreateQuote.data';
import { millisecondsToInterval } from 'utils/helpers';

const drawTextWithBackground = (doc, text, x, y, options) => {
  const textWidth = doc.getTextWidth(text?.length > 120 ? `${text.slice(0, 120)}...` : text) + 2;

  doc.setFillColor(palette.gray50);

  doc.roundedRect(x - 2, y - 3, textWidth + 1, 4, 0.7, 0.7, 'F');

  doc.setTextColor(palette.gray500);
  doc.text(text?.length > 120 ? `${text.slice(0, 120)}...` : text, x, y, options);
};

const getBarcode = (id, displayValue = true) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id, { displayValue });

  return canvas.toDataURL('image/jpeg');
};

const createFooter = (doc, company, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const { address1, city, state, zip, phone_number, email, company_website } = company || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.line(0, pageHeight - 24, pageWidth, pageHeight - 24);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 24, pageWidth, 24, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 2, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4 + 4, pageHeight - 20, pageWidth / 4 + 4, pageHeight - 8);
    doc.line(pageWidth / 2, pageHeight - 20, pageWidth / 2, pageHeight - 8);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 8);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 13, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 9, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3);
    doc.text('Address', 18, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 14, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);
    doc.setFontSize(9);
    doc.setTextColor('#171C26');
    doc.text(`${address1}`, 13, pageHeight - 13);
    doc.text(`${city}, ${state} - ${zip}`, 13, pageHeight - 9, { maxWidth: 44 });
    doc.text(`${company_website || '-'}`, pageWidth / 4 + 9, pageHeight - 13);
    doc.text(`${email || '-'}`, pageWidth / 2 + 6, pageHeight - 13);
    doc.text(`${phone}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);
  }
};

const createChargesTableHeader = (doc, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(8);
  doc.setTextColor(palette.indigo700);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Description of Charges', 8, top);
  doc.text('Qty', 115, top);
  doc.text('Rate', 150, top);
  doc.text('Amount', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createChargesTableRow = (doc, charge, top, totalCharges, isLast, currency) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(8);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${charge.charge_type?.title || '-'}`, 8, top);
  doc.text(`${charge.quantity || '-'}`, 115, top);
  doc.text(`${charge.rate ? `${currency} ${formatNumber(charge.rate)}` : '-'}`, 150, top);
  doc.text(
    `${
      charge.rate && charge.quantity
        ? `${currency} ${formatNumber(Number(charge.rate) * Number(charge.quantity))}`
        : '-'
    }`,
    pageWidth - 8,
    top,
    { align: 'right' }
  );
  if (isLast) {
    doc.setTextColor(palette.gray900);
    doc.text(`${currency} ${formatNumber(totalCharges)}`, pageWidth - 8, top + 4, { align: 'right' });
  }
};

const createChargesTableRows = (doc, data, top, currency, totalCharges) => {
  let rowTop = top;
  let multiplier = 0;

  data.forEach((item, i) => {
    const isLast = i === data.length - 1;

    if (rowTop + multiplier * 4 > 260) {
      doc.addPage();
      createChargesTableHeader(doc, 16);
      multiplier = 0;
      rowTop = 23;
    }

    createChargesTableRow(doc, item, rowTop + multiplier * 4, totalCharges, isLast, currency);
    multiplier++;
  });

  return rowTop + multiplier * 4;
};

const createStopsTableHeader = (doc, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(8);
  doc.setTextColor(palette.indigo700);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Date', 8, top);
  doc.text('Type', 60, top);
  doc.text('City, State', 80, top);
  doc.text('Mileage', 115, top);
  doc.text('Weight', 135, top);
  doc.text('Equipment Action', 155, top);
  doc.text('Equipment Type', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createStopsTableRow = (doc, stop, top, dateFormat) => {
  const totalValues = stop.stop_point_type !== 3 ? getCargoTotals(stop.cargos || []) : null;

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(8);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text(
    `${stop.scheduled_date ? moment(stop.scheduled_date).format('MMMM Do, YYYY') : ''} ${
      stop.from ? dateFormat.formatTime(stop.from) : ''
    }`,
    8,
    top,
    { maxWidth: 50 }
  );
  if (stop.scheduled_type?.label !== 'By Appointment') {
    doc.text(
      `${stop.scheduled_date_to ? `to ${moment(stop.scheduled_date_to).format('MMMM Do, YYYY')}` : ''} ${
        stop.to ? dateFormat.formatTime(stop.to) : ''
      }`,
      8,
      top + 4,
      { maxWidth: 50 }
    );
  }
  if (stop.notes_text) {
    drawTextWithBackground(doc, `NOTE: ${stop.notes_text}`, 10, top + 8);
  }
  doc.text(
    `${
      stop.stop_point_type === 1
        ? 'Pick Up'
        : stop.stop_point_type === 2
        ? 'Delivery'
        : stop.stop_point_type === 3
        ? 'Waypoint'
        : '-'
    }`,
    60,
    top,
    { maxWidth: 62 }
  );
  if (stop.stop_point) {
    doc.text(
      `${
        stop.stop_point
          ? `${stop.stop_point.city.name}, ${stop.stop_point.state.short_name} ${stop.stop_point.zipcode} ${stop.stop_point.country.name}`
          : '-'
      }`,
      80,
      top,
      { maxWidth: 30 }
    );
  } else {
    doc.text(
      `${stop.city?.name ? `${stop.city?.name},` : ''} ${stop.state?.short_name || ''} ${stop.zipcode || ''} ${
        stop.country?.name || '-'
      }`,
      80,
      top,
      { maxWidth: 30 }
    );
  }
  doc.text(`-`, 115, top);
  if (stop.stop_point_type === 1) {
    doc.text(
      `${
        stop.cargos?.length > 1
          ? `${formatNumber(totalValues.expected_weight)}`
          : `${totalValues.expected_weight || '-'}`
      } ${stop.cargos[0]?.expected_weight_type?.unit || ''}`,
      135,
      top,
      { maxWidth: 30 }
    );
  } else if (stop.stop_point_type === 2) {
    doc.text(
      `${stop.cargos.length ? `${formatNumber(totalValues.expected_weight)}` : `${stop.weight || '-'}`} ${
        stop.cargos.length ? `${stop.cargos?.[0]?.expected_weight_type?.unit || ''}` : `${stop.weight_type?.unit || ''}`
      }`,
      135,
      top,
      { maxWidth: 30 }
    );
  }
  doc.text(`${stop.equipment_action ? `${stop.equipment_action.label}` : '-'}`, 155, top, { maxWidth: 35 });
  doc.text(
    `${
      stop.equipment_type_length?.length_info
        ? `${stop.equipment_type_length.length_info.length} ${stop.equipment_type_length.length_info.unit}`
        : ''
    } ${stop.equipment_type ? `${stop.equipment_type.title}` : ''}`,
    pageWidth - 8,
    top,
    { align: 'right' }
  );
};

const createStopsTableRows = (doc, data, top, dateFormat) => {
  let rowTop = top;
  let multiplier = 0;

  data.forEach((item) => {
    if (rowTop + multiplier * 16 > 260) {
      doc.addPage();
      createStopsTableHeader(doc, 16);
      multiplier = 0;
      rowTop = 23;
    }

    createStopsTableRow(doc, item, rowTop + multiplier * 16, dateFormat);
    multiplier++;
  });

  return rowTop + multiplier * 16;
};

export const generatePdf = ({ quote, companyProfile, dateFormat }, download) => {
  const { address1, address2, city, state, country, zip, company_logo, company_name } = companyProfile || {};
  const { legs, line_items, charges } = quote || {};

  const lineItemsTotal = (line_items || []).reduce(
    (acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0),
    0
  );
  const chargesTotal = (charges || []).reduce((acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0), 0);
  const { startDate, endDate, totalDuration } = getQuoteStartEndDate(quote);
  const totalDistance = getQuoteTotalDistance(quote);

  let pickupsCount = 0;
  let deliveriesCount = 0;

  legs.forEach((leg) => {
    leg.stops.forEach((stop) => {
      if (Number(stop.stop_point_type) === 1) {
        pickupsCount++;
      }
      if (Number(stop.stop_point_type) === 2) {
        deliveriesCount++;
      }
    });
  });

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#171C26');
  doc.text(`${company_name}`, 8, 25);
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 8, 30);
  doc.text(`${city}, ${state} ${zip}`, 8, 35);

  doc.addImage(`${company_logo}?cache=${Math.random()}`, 'JPEG', 8, 8, 60, 12, 'Logo', 'FAST');

  // Invoice ID
  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 110, 13, pageWidth - (pageWidth - 102), 7, 'F');
  doc.setFontSize(11);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#FFFFFF');
  doc.text('Quote ID', pageWidth - 108, 18);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`123456789`, pageWidth - 69, 18);
  doc.setFontSize(12);
  doc.addImage(getBarcode(`123456789`, true), 'JPEG', pageWidth - 42, 21, 35, 15);

  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#000000');
  doc.setFontSize(10);
  doc.text(`Reference ID:`, pageWidth - 108, 28);
  doc.text(`Quote Date:`, pageWidth - 108, 34);
  doc.text(`Expires On:`, pageWidth - 108, 40);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${quote.billing?.reference_id || '-'}`, pageWidth - 82, 28);
  doc.text(`${dateFormat.formatDate(quote.quote_date || new Date())}`, pageWidth - 82, 34);
  doc.text(`${quote.expiry_date ? `${dateFormat.formatDate(quote.expiry_date)}` : '-'}`, pageWidth - 82, 40);

  doc.setFont('Inter', 'normal', 700);
  doc.setFontSize(20);
  doc.setTextColor('#000000');
  doc.text(`QUOTE`, pageWidth - 8, 46, { align: 'right' });

  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 110, 53, pageWidth - (pageWidth - 102), 7, 'F');
  doc.setFontSize(11);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#FFFFFF');
  doc.text('Make Payable To', pageWidth - 108, 58);

  doc.setFont('Inter', 'normal', 400);
  doc.setFontSize(10);
  doc.setTextColor(palette.gray900);

  if (quote.billing?.customer) {
    const { company_name, address1, address2, zipcode, city, state, country } = quote.billing.customer || {};

    doc.text(`${company_name}`, 8, 66);

    doc.setTextColor(palette.gray500);
    doc.text(`${address1}`, 8, 70);
    if (address2) {
      doc.text(`${address2}`, 8, 74);
    }
    doc.text(`${city?.name || ''}, ${state?.name || ''} ${zipcode || ''}`, 8, address2 ? 78 : 74);
    doc.text(`${country?.name || ''}`, 8, address2 ? 82 : 78);
  }

  doc.setTextColor(palette.gray900);
  doc.text(`${company_name}`, pageWidth - 107, 66);
  doc.setTextColor(palette.gray500);
  doc.text(`${address1}`, pageWidth - 107, 70);
  if (address2) {
    doc.text(`${address2}`, pageWidth - 107, 74);
  }
  doc.text(`${city}, ${state} ${zip}`, pageWidth - 107, address2 ? 78 : 74);
  doc.text(`${country}`, pageWidth - 107, address2 ? 82 : 78);

  doc.setDrawColor(188, 194, 206);
  doc.line(8, 90, pageWidth - 8, 90);
  doc.line(8, 90.2, pageWidth - 8, 90.2);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Schedule`, 8, 96);

  createStopsTableHeader(doc, 102);
  let legStart = 110;
  let legEnd;

  quote?.legs?.forEach((leg, i) => {
    doc.setFillColor(palette.indigo50);
    doc.roundedRect(2, legStart - 5, 4, leg.stops.length * 16, 0.7, 0.7, 'F');
    doc.roundedRect(pageWidth - 7, legStart - 5, 4, leg.stops.length * 16, 0.7, 0.7, 'F');

    doc.setTextColor(palette.indigo700);
    doc.setFont('Inter', 'normal', 700);
    doc.text(`${i + 1}`, 3, legStart + (leg.stops.length * 16) / 2);
    doc.text(`QTY ${leg.quantity}`, pageWidth - 6, legStart + (leg.stops.length * 16) / 2 - 5, {
      angle: -90,
    });

    legEnd = createStopsTableRows(doc, leg?.stops || [], legStart, dateFormat);
    legStart = legEnd + 5;
  });

  const chargesStart = legEnd + 5;

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Charges`, 8, chargesStart);

  createChargesTableHeader(doc, chargesStart + 4);
  const currencySymbol = CURRENCY[quote.billing?.currency?.title?.toLowerCase()]?.symbol;
  const chargesEnd = createChargesTableRows(doc, quote?.charges || [], chargesStart + 10, currencySymbol, chargesTotal);

  const lineItemsStart = chargesEnd + 5;

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Additional Line Items`, 8, lineItemsStart);

  createChargesTableHeader(doc, lineItemsStart + 4);
  const lineItemCurrencySymbol = CURRENCY[quote.line_items_currency?.title?.toLowerCase()]?.symbol;
  const lineItemsEnd = createChargesTableRows(
    doc,
    quote.line_items || [],
    lineItemsStart + 10,
    lineItemCurrencySymbol,
    lineItemsTotal
  );

  // Summary
  let summaryStart = lineItemsEnd + 5;
  if (summaryStart > 230) {
    summaryStart = 16;
    doc.addPage();
  }

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Summary`, 8, summaryStart);

  doc.setFontSize(8);
  doc.setTextColor(palette.indigo700);
  doc.setFont('Inter', 'normal', 700);
  // First Line Headers
  doc.text('Total Pick Ups', 20, summaryStart + 4, { align: 'center' });
  doc.text('Additional Charges', 70, summaryStart + 4, { align: 'center' });
  doc.text('Charge Per Unit', 110, summaryStart + 4, { align: 'center' });
  doc.text('Total Power Units', 160, summaryStart + 4, { align: 'center' });
  doc.text('Grand Total', pageWidth - 8, summaryStart + 4, { align: 'right' });
  // Second Line Headers
  doc.text('Total Deliveries', 20, summaryStart + 18, { align: 'center' });
  doc.text('Start Date', 70, summaryStart + 18, { align: 'center' });
  doc.text('End Date', 110, summaryStart + 18, { align: 'center' });
  doc.text('Total Distance', 160, summaryStart + 18, { align: 'center' });
  doc.text('Total Duration', pageWidth - 8, summaryStart + 18, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, summaryStart + 6, pageWidth - 8, summaryStart + 6);
  doc.line(8, summaryStart + 20, pageWidth - 8, summaryStart + 20);

  doc.setFontSize(8);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  // First Line Data
  doc.text(`${pickupsCount}`, 20, summaryStart + 10, { align: 'center' });
  doc.text(`${lineItemCurrencySymbol}${formatNumber(lineItemsTotal)}`, 70, summaryStart + 10, { align: 'center' });
  doc.text(
    `${currencySymbol}${formatNumber(chargesTotal / Number(quote.billing?.total_power_unit || 1))}`,
    110,
    summaryStart + 10,
    {
      align: 'center',
    }
  );
  doc.text(`${quote.billing?.total_power_unit || '-'}`, 160, summaryStart + 10, { align: 'center' });
  doc.text(`${currencySymbol}${formatNumber(chargesTotal)}`, pageWidth - 8, summaryStart + 10, { align: 'right' });
  // Second Line Data
  doc.text(`${deliveriesCount}`, 20, summaryStart + 24, { align: 'center' });
  doc.text(startDate ? `${dateFormat.formatDate(startDate)}` : '-', 70, summaryStart + 24, { align: 'center' });
  doc.text(endDate ? `${dateFormat.formatDate(endDate)}` : '-', 110, summaryStart + 24, { align: 'center' });
  doc.text(totalDistance ? `${Math.round(totalDistance)} miles` : '-', 160, summaryStart + 24, { align: 'center' });
  doc.text(
    totalDuration ? `${millisecondsToInterval(totalDuration * 60 * 1000)}` : '-',
    pageWidth - 8,
    summaryStart + 24,
    { align: 'right' }
  );

  // Payment Terms
  let termsStart = summaryStart + 30;
  if (termsStart > 250) {
    termsStart = 16;
    doc.addPage();
  }

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor(palette.gray900);
  doc.text(`Payment Terms`, 8, termsStart);

  doc.setFontSize(8);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${quote.payment_term_notes}`, 8, termsStart + 6, { maxWidth: pageWidth - 20 });

  doc.setDrawColor(233, 237, 245);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#464F60');

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, companyProfile, currentPage, pagesCount);
  }

  doc.setDocumentProperties({ title: `Quote${quote.quote_id ? ` ${quote.quote_id}` : ''}` });

  const url = doc.output('datauristring');
  const blob = doc.output('blob');

  if (download) {
    doc.save(`quote-${quote.quote_id}.pdf`);
  }

  return { blob, url };
};
