import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import uuid from 'react-uuid';
import MaterialTable from 'material-table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as WarningIcon, ReactComponent as DetentionIcon } from 'assets/icons/detention.svg';

import { Typography } from 'components/Typography';
import UserInfo from 'components/StopPoint/UserInfo';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import TableFooter from 'components/TableFooter/TableFooter';
import ShipmentSummary from 'componentsV2/Shipment/ShipmentSummary';
import { MaterialTableSort } from 'components/MaterialTableWrapper';
import CustomTooltip from 'common/Tooltip';
import ColumnHeader from 'common/ColumnHeader';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { ALL_TAB_FILTER_ID, SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { formatAmount, getShipmentType } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { TableShipmentsContext } from 'context/tableContext';
import useGetShipmentWeight from 'hooks/useGetShipmentWeight';
import { CURRENCY, nFormatter, palette, STOP_POINT_TAB } from 'utils/constants';
import { getShipmentPopoverSettings, getColumnsWidth, updateColumnsWidth, shipmentHistory } from 'Api/Shipment';
import {
  getEquipmentRequiredVehicles,
  customerStopPoint,
  getAssignToGroupBillingCharges,
  getBillToBillingCharges,
  getEquipmentRequiredTrailers,
  getVehicleData,
} from 'Api/Planner';
import { getStatusParamsInStatus } from 'components/TableShipments/helpers/constants';
import { DEFAULT_WIDTH_OF_COLUMNS, getPlannerStatusParamsInStatus } from './helpers/constants';
import classes from './shipmentHistory.module.css';
import ShipmentsFilter from './ShipmentsFilter';
import ShipmentHistoryHeader from './HeaderShipments';
import UserInfoRowAssignedTo from '../TableShipments/helpers/UserInfoRowAssignedTo';

let controller = null;

const ShipmentHistory = () => {
  const navigate = useNavigate();
  const { getShipmentWeight } = useGetShipmentWeight();
  const { formatDate, formatTime, convertToCustomerTime } = useDateFormat();
  const [searchParams, setSearchParams] = useSearchParams();
  const [refs, setRefs] = useState({});
  const [, setRefEditIcon] = useState({});
  const [isOpen] = useState(false);
  const [rowCheckBoxes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [localFilterCheckboxes, setLocalFilterCheckboxes] = useState({ status: [], groups: [], equipment_types: [] });
  const [getShipmentFilterData, setGetShipmentFilterData] = useState({});
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const myTableRef = useRef(null).current;
  const resizingColumn = useRef();
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [customers, setCustomers] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [shipmentHistoryData, setShipmentHistoryData] = useState({ data: [] });
  const [filterByGroupsData, setFilterByGroupsData] = useState([]);
  const [filterByEquipmentTypeData, setFilterByEquipmentTypeData] = useState([]);
  const [animationList] = useState({});
  const [columnWidths, setColumnWidths] = useState({ ...DEFAULT_WIDTH_OF_COLUMNS });
  const [triggerAdvancedClear, setTriggerAdvancedClear] = useState(null);
  const [activeTabData] = useState({
    key: 'all',
    label: 'All',
    value: 1,
    id: ALL_TAB_FILTER_ID,
    count: 0,
  });
  const [dateRangeLocation, setDateRangeLocation] = useState([null, null]);
  const [dateRangeStopPoint, setDateRangeStopPoint] = useState([null, null]);
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
    allValue: '',
    cloudSwitch: false,
    selectedValues: [],
    tableColumn,
    switchRadioButtons: {
      amount: '1',
      date: '1',
      time: '1',
    },
    forAll: false,
    filterTableTop: [],
  });

  function findNextStop(rowData) {
    const { status_id, shipment_stops, assigned_to_1, brokerage_dispatch } = rowData;

    if (['1', '2', '8', '9'].includes(status_id) || (!assigned_to_1 && !brokerage_dispatch)) {
      return 0;
    }

    if (shipment_stops?.every((stop) => !!stop.departure_date && !!stop.arrival_date)) {
      return shipment_stops.length;
    }

    const index = shipment_stops?.findIndex((stop) => !stop.departure_date && stop.arrival_date);

    if (index !== -1) {
      return index + 1;
    }

    const firstMissingArrivalIndex = shipment_stops?.findIndex((stop) => !stop.arrival_date);

    if (firstMissingArrivalIndex !== -1) {
      return firstMissingArrivalIndex + 1;
    }

    return 0;
  }

  const equipmentTypeText = ({ type, action, equipment }) => {
    if (+type === 1) {
      const map = {
        1: 'Live Load',
        2: `Hook to ${equipment?.equipment_id}`,
        3: `Drop Trailer & Hook to ${equipment?.equipment_id}`,
      };
      return map[action];
    }
    if (+type === 2) {
      const map = { 1: 'Live Unload', 2: 'Drop Trailer' };
      return map[action];
    }
    if (+type === 3) {
      const map = {
        1: 'Live Unload',
        2: `Hook to ${equipment?.equipment_id}`,
        3: `Drop Trailer & Hook to ${equipment?.equipment_id}`,
        4: 'Other',
      };
      return map[action];
    }
  };

  function extractDates(inputStr) {
    const regex = /(\d{2}\/\d{2}\/\d{4}) - (\d{2}\/\d{2}\/\d{4})/;
    const regexStartDate = /(\d{2}\/\d{2}\/\d{4})/;
    const match = inputStr.match(regex);
    const matchStartDate = inputStr.match(regexStartDate);
    if (match) {
      return {
        startDate: match[1],
        endDate: match[2],
      };
    }
    if (matchStartDate) {
      return {
        startDate: matchStartDate[1],
        endDate: matchStartDate[1],
      };
    }
    return null;
  }

  const handleMouseDown = (columnField) => (e) => {
    resizingColumn.current = {
      columnField,
      startingWidth: columnWidths[columnField],
      startingX: e.clientX,
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!resizingColumn.current) return;
    const delta = e.clientX - resizingColumn.current.startingX;

    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [resizingColumn.current.columnField]: Math.max(resizingColumn.current.startingWidth + delta, 50),
    }));

    const param = {
      table_type: 'shipment-history',
      column_type: resizingColumn.current.columnField,
      width: Math.max(resizingColumn.current.startingWidth + delta, 50),
    };

    updateColumnsWidth(param);
  };

  const handleMouseUp = () => {
    resizingColumn.current = null;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'shipment_id',
        resizable: true,
        width: columnWidths.shipment_id,
        cellStyle: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
        title: <MaterialTableSort title='Shipment ID' field='shipment_id' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const type = rowData?.shipment_type;
          const shipmentBilling = rowData.shipment_billing;

          const { shipment_notes, detentions, status_id, delay_reason_required } = rowData || {};
          return (
            <div
              key={rowData.id}
              className={`${classes.shipmentWrapper} 
                                        ${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
            >
              <div className={classes.textShipment}>
                <Typography variant='s3' style={{ color: palette.dark800 }}>
                  {rowData.shipment_id}
                </Typography>
                <ShipmentSummary shipmentId={rowData.shipment_id} className='ms-1' />
                {Array.isArray(shipment_notes) && shipment_notes?.length > 0 && (
                  <div onClick={(e) => onClickRowIcon(e, rowData, 'notes')}>
                    <NotesIcon width={14} height={14} style={{ marginLeft: 4, flexShrink: 0 }} />
                  </div>
                )}
                {+status_id === 4 && delay_reason_required && (
                  <CustomTooltip title='Delayed'>
                    <WarningIcon width={14} height={14} style={{ marginLeft: 4, flexShrink: 0 }} fill='#C97A20' />
                  </CustomTooltip>
                )}
                {Array.isArray(detentions) && detentions?.length > 0 && (
                  <OverlayTrigger
                    placement='right'
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Detention warning</Tooltip>}
                  >
                    <div onClick={(e) => onClickRowIcon(e, rowData, 'detention')}>
                      <DetentionIcon
                        width={14}
                        height={14}
                        fill={palette.red400}
                        style={{ marginLeft: 4, flexShrink: 0 }}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
              {type !== 'ltl' || (type === 'ltl' && shipmentBilling.length === 1) ? (
                <Typography
                  variant='s3'
                  className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                  style={{ color: palette.dark800 }}
                >
                  {shipmentBilling?.[0]?.reference_id}
                </Typography>
              ) : (
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip id='button-tooltip-2'>
                      {shipmentBilling.map((el, i) => (
                        <p key={el.id || i} style={{ margin: 0 }}>
                          {el?.reference_id}
                        </p>
                      ))}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div ref={ref} className={classes.ellipsis}>
                      <Typography
                        variant='s3'
                        {...triggerHandler}
                        className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                        style={{ color: palette.dark800 }}
                      >
                        Multi-Ref LTL
                      </Typography>
                    </div>
                  )}
                </OverlayTrigger>
              )}
              <Typography variant='s3'>{getShipmentType(rowData.shipment_type)}</Typography>
            </div>
          );
        },
      },
      {
        field: 'customer',
        resizable: true,
        width: columnWidths.customer,
        title: <MaterialTableSort title='Customer' field='customer' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const shipmentBilling = rowData.shipment_billing;
          const {
            contact_name: name,
            contact_email: email,
            phone_number,
          } = rowData.shipment_billing?.[0]?.contact_user || {};
          const contactInfo = {
            name,
            email,
            phone_number,
          };

          return shipmentBilling.length === 1 ? (
            <div className='d-flex flex-column align-items-start'>
              <Typography
                variant='b3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/customer-profile/${shipmentBilling?.[0]?.billing_customer?.id}`);
                }}
              >
                {shipmentBilling?.[0]?.billing_customer?.company_name}&nbsp;
              </Typography>
              <UserInfo data={contactInfo} />
            </div>
          ) : shipmentBilling.length > 1 ? (
            <OverLayTriggerHoverWrapper
              overlay={
                <div>
                  {shipmentBilling.map((el, i) => (
                    <>
                      <Typography
                        key={el.id || i}
                        as='p'
                        variant='s2'
                        style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/customer-profile/${el?.billing_customer?.id}`);
                        }}
                      >
                        {el?.billing_customer?.company_name}
                      </Typography>
                      <UserInfo
                        data={{
                          name: el.contact_user?.contact_name,
                          email: el.contact_user?.contact_email,
                          phone_number: el.contact_user?.phone_number,
                        }}
                      />
                    </>
                  ))}
                </div>
              }
            >
              <div>
                <Typography
                  variant='b3'
                  className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                  style={{ color: palette.dark800 }}
                >
                  Multi-Customer LTL
                </Typography>
              </div>
            </OverLayTriggerHoverWrapper>
          ) : null;
        },
      },
      {
        field: 'assigned_to',
        resizable: true,
        width: columnWidths.assigned_to,
        title: <MaterialTableSort title='Driver' field='assigned_to' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return rowData.assigned_to_1 ? <UserInfoRowAssignedTo data={rowData} animationList={animationList} /> : '-';
        },
      },
      {
        field: 'equipment',
        resizable: true,
        width: columnWidths.equipment,
        title: <MaterialTableSort title='equipment' field='equipment' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const { equipment_id, equipment_type, hooked_to, id } = rowData?.equipment || {};
          return (
            <div className={classes.equipmentColWrapper}>
              <div className={classes.equipmentVehicleWrapper}>
                <Typography
                  variant='s3'
                  className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                  style={{ color: palette.dark800 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/equipment-profile/vehicle/${id}`);
                  }}
                >
                  {equipment_id || '-'} {equipment_type?.title}
                </Typography>
              </div>

              {!!hooked_to && (
                <div className='d-flex align-items-baseline'>
                  <TwoWaysIcon style={{ margin: '0 4px' }} />
                  <div className={classes.hookToWrapper}>
                    <Typography
                      variant='s3'
                      className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                      style={{ color: palette.dark800, marginLeft: 5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/equipment-profile/trailer/${hooked_to.id}`);
                      }}
                    >
                      {hooked_to?.equipment_id} {hooked_to?.length?.length}&nbsp;
                      {hooked_to?.length?.unit} {hooked_to?.equipment_type?.title}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
      {
        field: 'stops',
        resizable: true,
        center: true,
        width: columnWidths.stops,
        title: <MaterialTableSort title='Stops' field='status' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const stops = rowData.shipment_stops.length;
          let count = 0;

          for (let i = 0; i < rowData.shipment_stops.length; i++) {
            const stop = rowData.shipment_stops[i];
            if (stop?.arrival_date === null) break;
            count++;
          }

          if (count === stops) {
            count = stops;
          } else if (count) {
            count += 1;
          } else if (!count) {
            count = 0;
          }
          return (
            <div className='d-flex flex-column gap-1 align-items-center'>
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                {findNextStop(rowData)} of {stops}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'status',
        resizable: true,
        center: true,
        width: columnWidths.status,
        title: <MaterialTableSort title='Status' field='status' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const status = rowData?.status;

          const scheduledDate = rowData.shipment_stops[0].scheduled_date;
          const pendingDispatch = rowData?.pending_dispatch;
          const canceled_date = rowData?.canceled_at;
          const type = rowData?.shipment_type;
          const paramsInStatus =
            rowData.assigned_to_1 || rowData.brokerage_dispatch
              ? getStatusParamsInStatus(status)
              : getPlannerStatusParamsInStatus(scheduledDate, status, pendingDispatch, canceled_date, type);
          const { bgStatus, colorStatus, statusName } = paramsInStatus || {};

          return (
            <div
              className={`d-flex flex-column gap-1 align-items-center ${
                animationList[rowData.id] ? classes.rowData_wrapper : ''
              }`}
            >
              <div className={classes.statusWrapper}>
                <span className={classes.statusColumns} style={{ backgroundColor: bgStatus, color: colorStatus }}>
                  {statusName}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        field: 'origin_stop_name',
        resizable: true,
        width: columnWidths.origin_stop_name,
        title: <Typography variant='c3'>ORIGIN</Typography>,
        render: (rowData) => {
          const originStopName = rowData.shipment_stops[0]?.stop_point?.location_name;
          const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
          const id = rowData.shipment_stops[0]?.stop_point?.id;
          const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
          const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;

          const typeId = rowData.shipment_stops[0]?.scheduled_type;
          const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
          const fromData = rowData.shipment_stops[0]?.from;
          const toData = rowData.shipment_stops[0]?.to;
          const from = `${scheduledOrigin} ${fromData || ''}`;
          const to = `${scheduledOrigin} ${toData || ''}`;

          return (
            <>
              <Typography
                variant='b3'
                className={classes.originNameWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
                }}
              >
                {originStopName}{' '}
              </Typography>
              <br />
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                style={{ color: palette.dark800 }}
              >
                {city && `${city},`} {state}
                {zipcode && `, ${zipcode}`}
              </span>
              <br />
              <>
                <Typography variant='b3'>{formatDate(scheduledOrigin)} </Typography>
                <span
                  className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                  style={{ color: palette.dark800 }}
                >
                  {fromData ? ` ${formatTime(from)}` : ''}
                  {toData ? ` to ${formatTime(to)}` : ''}
                  {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
                </span>
              </>
            </>
          );
        },
      },
      {
        field: 'destination_stop_name',
        resizable: true,
        width: columnWidths.destination_stop_name,
        title: <Typography variant='c3'>DESTINATION</Typography>,
        render: (rowData) => {
          const destinationStopName =
            rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.location_name;
          const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
          const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
          const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;
          const id = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.id;

          const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
          const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
          const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
          const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;

          const from = `${scheduledDestination} ${fromData || ''}`;
          const to = `${scheduledDestination} ${toData || ''}`;

          return (
            <>
              <Typography
                variant='b3'
                className={classes.destinationNameWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
                }}
              >
                {destinationStopName}{' '}
              </Typography>
              <br />
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                style={{ color: palette.dark800 }}
              >
                {city && `${city},`} {state}
                {zipcode && `, ${zipcode}`}
              </span>
              <br />
              <>
                <Typography variant='b3'>{formatDate(scheduledDestination)} </Typography>
                <span
                  className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                  style={{ color: palette.dark800 }}
                >
                  {fromData ? `${formatTime(from)}` : ''}
                  {toData ? ` to ${formatTime(to)}` : ''}
                  {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
                </span>
              </>
            </>
          );
        },
      },
      {
        field: 'weight',
        resizable: true,
        width: columnWidths.weight,
        title: <ColumnHeader title='WEIGHT' field='weight' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              {getShipmentWeight(rowData.shipment_stops).expectedWeightWithUnit} (Scheduled)
              <br />
              {getShipmentWeight(rowData.shipment_stops).actualWeightWithUnit} (Actual)
            </Typography>
          );
        },
      },
      {
        field: 'total_eta',
        resizable: true,
        width: columnWidths.total_eta,
        title: <MaterialTableSort title='MILES' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const { loaded_miles, empty_miles } = rowData;
          const miles = Math.round(loaded_miles + empty_miles);

          return (
            <Typography variant='s3' className='d-flex justify-content-center' style={{ color: palette.dark800 }}>
              {miles || '-'}
            </Typography>
          );
        },
      },
      {
        field: 'groups',
        resizable: true,
        width: columnWidths.groups,
        title: <MaterialTableSort title='Tags' field='groups' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const shipmentBilling = rowData.shipment_billing;
          return shipmentBilling?.map((el, i) => (
            <div
              key={el.id || i}
              className={`flex-row d-flex table_planer_groups_wrapper ${
                animationList[rowData.id] ? classes.rowData_wrapper : ''
              }`}
            >
              {el?.groups?.short_name ? (
                <div
                  className={`${classes.shortName}`}
                  style={{
                    color: palette.gray900,
                    backgroundColor: el?.groups?.color,
                  }}
                >
                  {el?.groups?.short_name}
                </div>
              ) : (
                <div className={`${classes.shortName}`}>-</div>
              )}
            </div>
          ));
        },
      },
      {
        field: 'next_equipment_action',
        resizable: true,
        width: columnWidths.next_equipment_action,
        title: <Typography variant='c3'>TYPE</Typography>,
        render: (rowData) => {
          const typeLength = rowData.shipment_stops[0]?.equipment_type_length_value?.length;
          const equipmentType = rowData.shipment_stops[0]?.equipment_type_data?.title;
          const type = typeLength ? `${typeLength}ft ${equipmentType || ''}` : `${equipmentType || ''}`;

          const firstNullArrivalDate = rowData.shipment_stops.find((stop) => !stop.arrival_date);
          return (
            <div className='d-flex flex-column'>
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}
                style={{ color: palette.dark800 }}
              >
                {type}
              </span>
              <Typography
                variant='s3'
                className={`${animationList[rowData.id] ? classes.rowData_wrapper : ''}`}
                style={{ color: palette.dark800 }}
              >
                {!firstNullArrivalDate?.stop_point_type
                  ? '-'
                  : equipmentTypeText({
                      type: firstNullArrivalDate.stop_point_type,
                      action: firstNullArrivalDate.equipment_action,
                      equipment: firstNullArrivalDate.equipment,
                    })}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'amount',
        resizable: true,
        width: columnWidths.amount,
        title: <MaterialTableSort title='amount' field='amount' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          let totalAmount = 0;
          rowData?.shipment_billing?.forEach((el) => {
            totalAmount += Number(el?.total_amount);
          });
          const amount = +filter.switchRadioButtons.amount;
          const formattedAmount = amount === 2 ? nFormatter(totalAmount) : formatAmount(totalAmount.toFixed(2));
          const currency = Object.values(CURRENCY).find(
            (item) => item.id === Number(rowData?.shipment_billing[0]?.currency_id)
          );

          return (
            <Typography variant='s3' style={{ color: palette.dark800 }}>
              {currency?.symbol || '$'}
              {formattedAmount}
            </Typography>
          );
        },
      },
      {
        field: 'created_by',
        resizable: true,
        width: columnWidths.created_by,
        title: <Typography variant='c3'>CREATED BY</Typography>,
        render: (rowData) => {
          const { created_by } = rowData;
          return (
            <div className='d-flex flex-column'>
              <span
                className={`${animationList[rowData.id] ? 'rowData_wrapper' : 'createdByWrapper'}`}
                style={{ color: palette.dark800 }}
                onClick={(e) => {
                  if (created_by.id) {
                    e.stopPropagation();
                    navigate(`/staff-profile/${created_by.id}/general`);
                  }
                }}
              >
                {created_by?.first_name} {created_by?.last_name}
              </span>
              <div className={`flex-row d-flex ${animationList[rowData.id] ? 'rowData_wrapper' : ''}`}>
                <span style={{ color: palette.dark800 }}>{convertToCustomerTime(rowData.created_at)}</span>
                <div className='tablePlaner_cloud_container' />
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    shipmentHistoryData,
    sortData,
    tableColumn,
    dragItem,
    animationList,
    switchRadioButtons,
    rowCheckBoxes,
    columnWidths,
    refs,
    onClickRowIcon,
  ]);

  function onClickRowIcon(e, rowData, type) {
    e.stopPropagation();
    switch (type) {
      case 'notes':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 6 } });
        break;
      case 'detention':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 7 } });
        break;
      default:
    }
  }

  const getFilterByGroups = useCallback(() => {
    getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: el.group_name,
              id: el.id,
              value: 'groups',
              type: 'checkbox',
              key: uuid(),
              typeData: 'groups',
            };
          });
          setFilterByGroupsData(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterStopPointData = useCallback(() => {
    customerStopPoint().then((res) => {
      if (res && res?.data) {
        const stopPoints = res.data.map((el) => ({
          id: el.id,
          key: el.id,
          label: el?.location_name,
        }));
        setStopPoints([...stopPoints]);
      }
    });
  }, []);

  const getFilterCustomerData = useCallback(() => {
    getBillToBillingCharges().then((res) => {
      const newCustomers = res.data.map((el) => ({
        id: el.id,
        title: el.company_name,
        label: el.company_name,
        key: el.id,
      }));
      setCustomers(newCustomers);
    });
  }, []);

  const getFilterEquipmentData = useCallback(() => {
    getVehicleData().then((res) => {
      if (res && res?.data) {
        const newEquipments = res.data.map((el) => {
          const label = `${el.equipment_type?.title} ${el.equipment_id} ${
            el.drivers ? `(${el.drivers?.map((item) => `${item?.fname} ${item?.lname} `) || ''})` : ''
          }`;
          return {
            id: el.id,
            title: label,
            label,
            searchValue: label,
            key: el.id,
          };
        });
        setEquipmentData(newEquipments);
      }
    });
  }, []);

  const getFilterByEquipmentType = useCallback(async () => {
    const filterByEquipmentData = [];

    await Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicle, trailers]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 1) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
        if (trailers && trailers?.data) {
          trailers.data.forEach((el) => {
            const filterByData = {
              title: el.title,
              id: el.id,
              value: 'equipment_types',
              type: 'checkbox',
              key: uuid(),
              typeData: 'trailers',
            };
            filterByEquipmentData.push(filterByData);
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);

  const onApplyFilterCheckboxes = useCallback(
    ({
      checkboxes,
      groups,
      customers,
      vehicles,
      carrier_ids,
      trailers,
      drivers,
      stop_points,
      equipment_types,
      shipment_id,
      reference_id,
      bill_of_lading_id,
      valuePickupDate,
      valueDeliveryDate,
      origin,
      destination,
    }) => {
      setShowTable(true);

      const TYPES = ['status', 'groups', 'equipment_types'];

      const {
        status: statusArr,
        groups: groupsArr,
        equipment_types: equipment_types_Arr,
        customer,
        vehicle,
        trailer,
        driver,
        carrier,
        stop_point,
        shipment_id: shipmentIds,
        reference_id: referenceIds,
        bill_of_lading_id: billOfLadingId,
      } = checkboxes;

      const { origin_city, origin_state, origin_zipcode, origin_miles } = origin || {};
      const { destination_city, destination_state, destination_zipcode, destination_miles } = destination || {};

      const statusParent = { id: statusArr?.[0]?.id, value: TYPES[0], searchValue: statusArr, title: 'Status' };
      const groupsParent = { id: groupsArr?.[0]?.id, value: TYPES[1], searchValue: groupsArr, title: 'Tags' };
      const customerParent = { id: customer?.[0]?.id, value: TYPES[1], searchValue: customer, title: 'Customer' };
      const carrierParent = {
        id: carrier?.[0]?.id,
        value: TYPES[1],
        searchValue: carrier.map((el) => ({ ...el, title: el.name })),
        title: 'Carrier',
      };
      const vehicleParent = { id: vehicle?.[0]?.id, value: TYPES[1], searchValue: vehicle, title: 'Vehicle' };
      const trailerParent = { id: trailer?.[0]?.id, value: TYPES[1], searchValue: trailer, title: 'Trailer' };
      const driverParent = { id: driver?.[0]?.id, value: TYPES[1], searchValue: driver, title: 'Driver' };
      const shipmentIdsParent = {
        id: shipmentIds?.[0],
        value: TYPES[1],
        searchValue: shipmentIds,
        title: 'Shipment ID',
      };
      const billOfLadingIdParent = {
        id: billOfLadingId?.[0],
        value: TYPES[1],
        searchValue: billOfLadingId,
        title: 'Bill of Lading ID',
      };
      const referenceIdsParent = {
        id: referenceIds?.[0],
        value: TYPES[1],
        searchValue: referenceIds,
        title: 'Reference ID',
      };
      const stop_pointParent = {
        id: stop_point?.[0]?.id,
        value: TYPES[1],
        searchValue: stop_point,
        title: 'Stop Point',
      };
      const equipmentParent = {
        id: equipment_types_Arr?.[0]?.id,
        value: TYPES[2],
        searchValue: equipment_types_Arr,
        title: 'Equipment types',
      };

      const selectedValues = [...statusArr, ...groupsArr, ...equipment_types_Arr]; // filter.selectedValues[state]

      // data in backEnd
      const allFilterData = {
        ...getShipmentFilterData,
        groups,
        equipment_types,
        customers,
        stop_point: stop_points,
        shipment_id: shipment_id?.[0],
        reference_id: reference_id?.[0],
        bill_of_lading_id: bill_of_lading_id?.[0],
        equipment: vehicles,
        trailer_id: trailers,
        driver_id: drivers,
        carrier_ids,
        ...activeTabData?.data?.filters,
      };

      if (statusArr.length) {
        const planner_status = [];
        const shipment_status = [];
        statusArr.forEach((item) => {
          if (item.type === 'planner') {
            planner_status.push(item.id);
          } else {
            shipment_status.push(item.id);
          }
        });
        allFilterData.shipment_status = shipment_status;
        allFilterData.planner_status = planner_status;
      }

      let originParent;
      let searchValue = '';
      if (origin_city && origin_state) {
        searchValue = `${origin_city}, ${origin_state}`;
        allFilterData.origin_city = origin_city;
        allFilterData.origin_state = origin_state;
      } else if (origin_zipcode) {
        searchValue = `${origin_zipcode}`;
        allFilterData.origin_zipcode = origin_zipcode;
      }
      if (origin_zipcode && origin_city && origin_state) {
        searchValue += `, ${origin_zipcode}`;
      }
      if (origin_miles && ((origin_state && origin_city) || origin_zipcode)) {
        allFilterData.origin_miles = origin_miles;
        searchValue += ` (${origin_miles} miles)`;
      }
      if (searchValue) {
        originParent = {
          id: 'origin ',
          value: TYPES[1],
          searchValue,
          title: 'Origin',
        };
      }

      let destinationParent;
      let destinationSearchValue = '';
      if (destination_city && destination_state) {
        destinationSearchValue = `${destination_city}, ${destination_state}`;
        allFilterData.destination_city = destination_city;
        allFilterData.destination_state = destination_state;
      } else if (destination_zipcode) {
        destinationSearchValue = `${destination_zipcode}`;
        allFilterData.destination_zipcode = destination_zipcode;
      }
      if (destination_zipcode && destination_city && destination_state) {
        destinationSearchValue += `, ${destination_zipcode}`;
      }
      if (destination_miles && ((destination_state && destination_city) || destination_zipcode)) {
        allFilterData.destination_miles = destination_miles;
        destinationSearchValue += ` (${destination_miles} miles)`;
      }
      if (destinationSearchValue) {
        destinationParent = {
          id: 'destination ',
          value: TYPES[1],
          searchValue: destinationSearchValue,
          title: 'Destination',
        };
      }

      let pickupDateRangeParent;
      if (valuePickupDate) {
        pickupDateRangeParent = {
          id: 'pickup',
          value: TYPES[1],
          searchValue: valuePickupDate,
          title: 'Pickup Date Range',
        };
        const dates = extractDates(valuePickupDate);
        allFilterData.origin_scheduled_from = dates.startDate;
        allFilterData.origin_scheduled_to = dates.endDate;
      }

      let deliveryDateParent;
      if (valueDeliveryDate) {
        deliveryDateParent = {
          id: 'pickup',
          value: TYPES[1],
          searchValue: valueDeliveryDate,
          title: 'Delivery Date Range',
        };
        const dates = extractDates(valueDeliveryDate);
        allFilterData.destination_scheduled_from = dates.startDate;
        allFilterData.destination_scheduled_to = dates.endDate;
      }

      // show data in user
      const showData = [...showHeaderFilters];
      const filtered = showData.filter((option) => !TYPES.includes(option?.value));

      const newShowData = [...filtered];
      statusParent?.id && newShowData.push(statusParent);
      groupsParent?.id && newShowData.push(groupsParent);
      equipmentParent?.id && newShowData.push(equipmentParent);
      customerParent?.id && newShowData.push(customerParent);
      vehicleParent?.id && newShowData.push(vehicleParent);
      trailerParent?.id && newShowData.push(trailerParent);
      driverParent?.id && newShowData.push(driverParent);
      carrierParent?.id && newShowData.push(carrierParent);
      stop_pointParent?.id && newShowData.push(stop_pointParent);
      shipmentIdsParent?.id && newShowData.push(shipmentIdsParent);
      referenceIdsParent?.id && newShowData.push(referenceIdsParent);
      billOfLadingIdParent?.id && newShowData.push(billOfLadingIdParent);
      pickupDateRangeParent && newShowData.push(pickupDateRangeParent);
      deliveryDateParent && newShowData.push(deliveryDateParent);
      originParent?.id && newShowData.push(originParent);
      destinationParent?.id && newShowData.push(destinationParent);

      setShowHeaderFilters(newShowData);
      updateFilter({ ...filter, selectedValues });
      setGetShipmentFilterData({ ...allFilterData });

      getShipmentHistory({ allFilterData });
    },
    [activeTabData, updateFilter, showHeaderFilters, getShipmentFilterData, filter]
  );

  const onChangeRowPerPage = (rowPage) => {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    getShipmentHistory({ page: 1, itemsPerPage: rowPage, allFilterData });
  };

  const onPageChange = (event) => {
    const lastPage = shipmentHistoryData.last_page;
    const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
    const allFilterData = { ...getShipmentFilterData, ...tabData };

    if (lastPage !== 1) {
      getShipmentHistory({ page: event, itemsPerPage: rowPerPage, allFilterData });
    }
  };

  function updateFilter(updatedFilter) {
    setFilter(updatedFilter);
  }

  const implementCountTotal = useCallback(
    (total, activeData, reset, modalTypeData) => {
      const type = modalTypeData?.type;
      const modalUpdatedData = modalTypeData?.data;
      const selectedData = activeData || activeTabData;

      if (selectedData) {
        const filterTableTop = filter.filterTableTop.map((el) => {
          if (+el.id === Number(selectedData?.id) || (selectedData.key === 'all' && +el.id === +selectedData.id)) {
            return {
              ...el,
              count: total,
            };
          }
          return el;
        });

        // reset
        if (reset) {
          setFilter((prevState) => {
            return { ...prevState, ...reset, filterTableTop: [...filterTableTop] };
          });
          setShowHeaderFilters([]);
        } else {
          // modal add and delete
          if (type === 'add' || type === 'delete' || type === 'update') {
            if (type === 'add') {
              const addedData = {
                ...modalUpdatedData,
                count: total,
                label: modalUpdatedData.data.name,
                key: uuid(),
                value: uuid(),
              };
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...prevState.filterTableTop, { ...addedData }],
                };
              });
            } else if (type === 'delete') {
              const id = modalUpdatedData;
              const newFilteredData = filter.filterTableTop.filter((el) => el.id !== id);
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newFilteredData],
                };
              });
            } else if (type === 'update') {
              const id = modalUpdatedData?.id;
              const newUpdatedData = filter.filterTableTop.map((el) => {
                if (el.id === id) {
                  const updateData = {
                    ...modalUpdatedData,
                    count: total,
                    label: modalUpdatedData.data.name,
                    key: uuid(),
                    value: uuid(),
                  };
                  return updateData;
                }
                return el;
              });
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newUpdatedData],
                };
              });
            }
          } else if (filterTableTop?.length > 0) {
            setFilter((prevState) => {
              return { ...prevState, filterTableTop: [...filterTableTop] };
            });
          }
        }
      }
    },
    [activeTabData, filter, updateFilter]
  );

  function getShipmentHistory(data) {
    controller && controller.abort();
    controller = new AbortController();

    const {
      page = 1,
      reset,
      filtersType,
      sort,
      filtersValue,
      itemsPerPage,
      remainingObj,
      allFilterData,
      getData = false,
      implementActiveData,
      modalTypeData,
      hasALoading = true,
    } = data || {};

    hasALoading && setLoading(true);

    const fetchParams = {
      signal: controller.signal,
      page,
      itemsPerPage: itemsPerPage || rowPerPage,
      sort: sort || sortData,
      remainingData: remainingObj
        ? getData
          ? { ...remainingObj, ...getShipmentFilterData }
          : remainingObj
        : getShipmentFilterData,
    };

    if (allFilterData) {
      fetchParams.allFilterData = allFilterData;
    } else {
      fetchParams.filtersType = filtersType;
      fetchParams.filtersValue = filtersValue;
    }

    return shipmentHistory(fetchParams)
      .then((res) => {
        setShipmentHistoryData(res);
        implementCountTotal(res.total, implementActiveData, reset, modalTypeData);
        return res;
      })
      .finally(() => setLoading(false));
  }

  const mapperSettingsData = (key) => {
    const map = {
      shipment_id: 'Shipment ID',
      customer: 'Customer',
      assigned_to: 'Driver',
      equipment: 'Equipment',
      stops: 'Stops',
      status: 'Status',
      origin_stop_name: 'Origin',
      destination_stop_name: 'Destination',
      total_eta: 'Total ETA',
      groups: 'Tags',
      next_equipment_action: 'Type',
      amount: 'Amount',
      weight: 'Weight',
      created_by: 'Created By',
    };
    return map[key] ? map[key] : key;
  };

  const getSettings = useCallback(() => {
    getShipmentPopoverSettings({ type: 'shipments-history' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });

          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [mapperSettingsData]);

  useEffect(async () => {
    try {
      getSettings();
      const widths = await getColumnsWidth('shipment-history');
      if (widths.data.length) {
        const updatedWidthsFromBackend = widths.data.reduce((acc, curr) => {
          acc[curr.column_type] = curr.width;
          return acc;
        }, {});
        setColumnWidths({
          ...DEFAULT_WIDTH_OF_COLUMNS,
          ...updatedWidthsFromBackend,
        });
      }

      const shipmentIds = searchParams.getAll('id');
      const params = {
        page: 1,
        itemsPerPage: rowPerPage,
      };

      if (shipmentIds?.length) {
        params.allFilterData = { shipment_ids: shipmentIds };
        searchParams.delete('id');
        setSearchParams(searchParams);
      }
      setFilter({
        ...filter,
        filterTableTop: [
          {
            key: 'all',
            label: 'All',
            value: 1,
            id: ALL_TAB_FILTER_ID,
            count: 0,
          },
        ],
      });
    } catch (e) {
      // Do nothing
    }
  }, []);

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({
        ...col,
      });
    });

    return cols;
  };

  function sortingQuery(field, sortBy) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    let newSortedData;
    if (sortData.find((el) => el.type === field)) {
      newSortedData = sortData.filter((el) => el.type !== field);
      newSortedData.unshift({ type: field, sortBy });
      setSortData(newSortedData);
    } else {
      newSortedData = [{ type: field, sortBy }, ...sortData];
      setSortData((p) => [{ type: field, sortBy }, ...p]);
    }

    getShipmentHistory({ sort: newSortedData, allFilterData, hasALoading: false });
  }

  const onRowClick = (e, value) => {
    const shipmentId = value?.shipment_id;
    navigate(`/shipment/${shipmentId}`, { state: value });
  };

  function onReset() {
    const resetDaya = {
      ...filter,
      allValue: '',
      selectedValues: [],
      customerSelectData: [],
      equipmentSelectedData: [],
    };
    setTriggerAdvancedClear(new Date());
    setShowHeaderFilters([]);
    setShowTable(false);
    implementCountTotal(0, activeTabData, undefined, undefined);
    setShipmentHistoryData({ data: [], total: 0 });
    setFilter((prevState) => {
      return { ...prevState, ...resetDaya, ...activeTabData?.data?.filters };
    });
    setDateRangeLocation([null, null]);
    setDateRangeStopPoint([null, null]);

    setGroups([]);
    setStatuses([]);
    setEquipments([]);
    setLocalFilterCheckboxes({ status: [], groups: [], equipment_types: [] });
    setGetShipmentFilterData({});
  }

  useEffect(() => {
    getFilterByGroups();
    getFilterByEquipmentType().then();
    getFilterCustomerData();
    getFilterStopPointData();
    getFilterEquipmentData();
  }, []);

  useEffect(() => {
    const refs = {};
    shipmentHistoryData?.data.forEach((d) => {
      refs[d.id] = React.createRef();
    });
    setRefs(refs);
    setRefEditIcon(refs);
  }, [shipmentHistoryData?.data]);

  useEffect(() => {
    Object.keys(refs).forEach((id) => {
      const ref = refs[id];
      if (ref && ref.current) {
        if (rowCheckBoxes.includes(+id)) {
          const row = ref.current.closest('tr');
          if (row) row.style.backgroundColor = palette.indigo50;
        } else {
          const row = ref.current.closest('tr');
          if (row) row.style.removeProperty('background-color');
        }
      }
    });
  }, [refs, rowCheckBoxes, palette]);

  return (
    <TableShipmentsContext.Provider
      value={{
        loading,
        triggerAdvancedClear,
        filter,
        dragItem,
        customers,
        tableColumn,
        getSettings,
        updateFilter,
        stopPoints,
        equipmentData,
        switchRadioButtons,
        dateRangeLocation,
        setDateRangeLocation,
        dateRangeStopPoint,
        setDateRangeStopPoint,
        onsStTableColumn: (v) => setTableColumn(v),
        onChangeOrder: (items) => setDragItem(items),
        onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
        filterByGroupsData,
        localFilterCheckboxes,
        setEquipments,
        onApplyFilterCheckboxes,
        setLocalFilterCheckboxes,
        filterByEquipmentTypeData,
        groups,
        onReset,
        statuses,
        setGroups,
        equipments,
        showHeaderFilters,
        setStatuses,
      }}
    >
      <div className={classes.shipmentsContainer}>
        <ShipmentHistoryHeader />

        <ShipmentsFilter />

        <div className={classes.switchRadioWrapper}>
          <div>
            <SwitchRadio type='tab' items={filter.filterTableTop} name='tableTopTabMenu' value={activeTabData.id} />
          </div>
        </div>
        <div className={classes.tableDrawerWrapper}>
          {loading ? (
            <EquipmentPreloaderSkeleton />
          ) : !showTable ? (
            <div className='d-flex justify-content-center'>
              <Typography variant='s1' style={{ color: palette.gray700 }}>
                Please enter your search criteria to show shipment data.
              </Typography>
            </div>
          ) : (
            <div className='planner_table_wrapper d-flex'>
              <MaterialTable
                columns={filterTableColumn(columns)}
                data={shipmentHistoryData.data}
                onRowClick={onRowClick}
                style={{
                  backgroundColor: palette.white,
                  width: isOpen ? 'calc(100% - 330px)' : '100%',
                  transition: '0.4s all cubic-bezier(0.42, 0.0, 1.0, 1.0)',
                }}
                options={{
                  tableLayout: 'fixed',
                  toolbar: false,
                  sorting: false,
                  pageSize: shipmentHistoryData.data.length,
                  pageSizeOptions: [25, 50, 100, 250, 500],
                }}
                components={{
                  Header: ({ columns }) => {
                    return (
                      <thead>
                        <tr>
                          {columns.map((column) => (
                            <th
                              key={`${column.field}`}
                              style={{
                                width: column.width,
                                borderRight: column.resizable ? '1px solid #ddd' : '0px',
                                boxSizing: 'border-box',
                                padding: '4px 0 4px 12px',
                              }}
                            >
                              <div className='position-relative'>
                                <div
                                  className='resizable-column-header'
                                  style={{
                                    display: column.center ? 'flex' : 'block',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {column.title}
                                </div>
                                {!!column.resizable && (
                                  <div
                                    style={{
                                      cursor: 'col-resize',
                                      position: 'absolute',
                                      right: '-2px',
                                      top: 0,
                                      bottom: 0,
                                      width: 5,
                                      zIndex: 10,
                                    }}
                                    onMouseDown={handleMouseDown(column.field)}
                                  />
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                    );
                  },
                  Pagination: () => {
                    return (
                      <TableFooter
                        myTableRef={myTableRef}
                        rowPerPage={rowPerPage}
                        totalCount={shipmentHistoryData.total}
                        lastPage={shipmentHistoryData.last_page}
                        totalLength={shipmentHistoryData.data.length}
                        currentPage={shipmentHistoryData.current_page}
                        onChangeRowPerPage={onChangeRowPerPage}
                        onPageChange={onPageChange}
                      />
                    );
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </TableShipmentsContext.Provider>
  );
};

export default ShipmentHistory;
