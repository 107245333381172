import React, { useState } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { Icons } from 'assets/icons';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import authHeader from 'services/auth-header';
import CustomizedSnackbars from 'components/toast/Toast';
import { validateCSV } from 'Api/Insurance';
import styles from './ImportCSV.module.css';

const ImportCSV = ({ handleImportCSVClose, handleImportVehicleOpen, getValue, tab }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [fileName, setFileName] = useState();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const validate = async (body) => {
    try {
      await validateCSV(body);
    } catch (e) {
      // Do nothing
    }
  };

  const getData = async () => {
    const ColumnData = [];
    const connected_service_id = tab === 'vehicles' ? 47 : 50;
    const formData = new FormData();
    formData.append('import_file', file);
    formData.append('connected_service_id', connected_service_id);

    setLoading(true);
    try {
      const res = await axios.post(`${API_URL}/equipment-import/csv`, formData, {
        headers: authHeader(),
      });
      if (res && res.data) {
        const validateBody = res.data.data.map((item) => {
          return {
            equipment_type_id: null,
            equipment_id: item.equipment_id,
            equipment_ownership: {
              owner: null,
              state: null,
              license_plate_number: item.license_plate_number,
              license_plate_expire_date: item.license_plate_expire_date,
            },
            make: item.make,
            model_id: item.model_id,
            gross_vehicle_weight: item.gross_vehicle_weight,
            year: item.year,
            start_odometer: null,
            odometer_unit: null,
            vin: item.vin,
            color: null,
            tire_size: null,
            gross_vehicle_measuring_unit: null,
            cargo_maximum_capacity_weight: null,
            cargo_capacity_measuring_unit: null,
            gvwr_class: item.gvwr_class,
            fuel_type_id: null,
          };
        });
        validate(validateBody);
        setLoading(false);
        getValue(ColumnData, res?.data?.data);
        handleImportVehicleOpen();
      }
    } catch (err) {
      setShowMessage({
        message: err.response.data.message ? err.response.data.message : 'Error !',
        visible: true,
        type: 'error',
      });
      setLoading(false);
    }
  };

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileName();
  };

  const downloadFile = () => {
    let Url;
    if (tab === 'vehicles') {
      Url = `${process.env.REACT_APP_BASE_URL}/uploads/csv/ImportVehicles.csv`;
    } else {
      Url = `${process.env.REACT_APP_BASE_URL}/uploads/csv/ImportTrailers.csv`;
    }
    const a = document.createElement('a');
    a.setAttribute('href', Url);
    a.style.visibility = 'hidden';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <div className={styles.PopUp}>
        <div className={styles.Header}>
          <h5 className={styles.textContent}>Import from CSV</h5>
        </div>
        <div className={styles.body}>
          <div className='d-flex items-center p-2 ms-3'>
            <div className='mt-3'>
              <span className={styles.leftNumbers}>1</span>
              <div
                style={{
                  borderLeft: '1px solid #D5DAE5',
                  height: '100%',
                  marginLeft: '8px',
                }}
              />
              <span className={styles.leftNumbers}>2</span>
            </div>
            <div>
              <div className={styles.CSVTemplate}>
                <span className={styles.textCSV}>
                  Download the {tab === 'vehicles' ? 'Vehicles' : 'Trailers'} CSV Template
                </span>

                <p className={styles.textContent2}>
                  First download the CSV template. Fill out asked data in that template then upload filled template
                  below.
                </p>
                <button className={styles.button} onClick={downloadFile}>
                  <img src={Icons.DownloadIcon} alt='Download' />
                  Download {tab === 'vehicles' ? 'Vehicles' : 'Trailers'} CSV Template
                </button>
              </div>
            </div>
          </div>

          <div className={styles.uploadFiles}>
            <span className={styles.uploadText}>Upload filled CSV file here:</span>
            <div className={!fileName ? styles.uploadFiles2 : styles.uploadFile}>
              <label className={styles.recordFormRepairReceipt}>
                <span>
                  <input
                    name='repair_receipt_attach'
                    onChange={(e) => {
                      setFileName(e.currentTarget.files?.[0]?.name);
                      setFile(e.currentTarget.files[0]);
                    }}
                    type='file'
                    accept='.csv'
                    style={{ display: 'none' }}
                  />

                  {fileName ? (
                    <div className='d-flex'>
                      <div className={styles.attachfile}>
                        <img src={Icons?.attachfile} alt='' style={{ width: '15px', height: '15px' }} />
                        <span className={styles.recordFormHeader}>{fileName}</span>
                      </div>
                      <span onClick={handleRemove}>
                        <img
                          src={Icons.CrossRedIcon}
                          alt=''
                          className='ms-2'
                          style={{ height: '10px', width: '10px' }}
                        />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className='d-flex justify-content-center'>
                        <img src={uploadLicense} alt='' />
                      </div>
                      <p className='d-flex justify-content-center '>
                        <span style={{ color: '#4F5AED', marginRight: '2px' }}>Upload file</span>
                        <span className={styles.recordFormHeader}> or drag and drop</span>
                      </p>
                    </>
                  )}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className={styles.action}>
          <button className={styles.buttonCancel} onClick={handleImportCSVClose}>
            Cancel
          </button>
          {loading ? (
            <CircularProgress style={{ height: '20px', width: '20px', marginTop: '16px', marginRight: '14px' }} />
          ) : (
            <button className={styles.buttonUploadText} onClick={getData}>
              Upload
            </button>
          )}
        </div>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default ImportCSV;
