import React, { useMemo, useState } from 'react';
import Autocomplete from 'common/Autocomplete';

const PayeeAutocomplete = ({ value, options, onChange, onAddNew, error, ...props }) => {
  const [inputValue, setInputValue] = useState('');

  const updatedOptions = useMemo(() => {
    if (inputValue && options.every((i) => i.name !== inputValue)) {
      return [{ name: `+ Add "${inputValue}"`, id: 'add-new', type: '' }, ...options];
    }
    return options;
  }, [inputValue, options]);

  const handleChange = (e, val) => {
    if (val?.id === 'add-new') {
      onAddNew(inputValue);
      return;
    }

    onChange(e, val);
  };

  return (
    <Autocomplete
      size='small'
      name='payee'
      placeholder='Select Payee'
      options={updatedOptions}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(e, val) => setInputValue(val)}
      isOptionEqualToValue={(option, value) => option.id === value.id && option.type === value.type}
      error={error}
      renderOption={(props, option) => (
        <li {...props} key={`${option.id}-${option.type}`}>
          {option.name}
        </li>
      )}
      {...props}
    />
  );
};

export default PayeeAutocomplete;
