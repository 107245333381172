import React, { useEffect, useState } from 'react';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import AddJobPosition from 'componentsV2/Recruiting/AddJobPosition';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import useDebounce from 'hooks/useDebounce';
import { palette } from 'utils/constants';
import { getJobPositionsList } from 'Api/Applicants';
import { initialFilters, useJobPositionColumns } from './JobPositionTable.data';

import Search from '../Search';
import TableFooter from '../TableFooter';
import { STableWrapper } from '../../ApplicantsAndReferrals.styles';

export const JobPositionTable = ({ open, setOpen }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [referrers, setReferrers] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [tableIndex, setTableIndex] = useState(Date.now());
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [search, setSearch] = useState('');
  const [jobToUpdate, setJobToUpdate] = useState(null);
  const debouncedSearch = useDebounce(search, 500);

  const getJobPositions = async () => {
    setLoading(true);
    const { page, itemsPerPage, ...rest } = selectedFilters || {};

    try {
      const params = {
        page,
        itemsPerPage,
        'filters[query]': debouncedSearch || undefined,
        ...rest,
      };

      const response = await getJobPositionsList(params);
      setReferrers(response);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong' });
    }
    setLoading(false);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    getJobPositions();
  }, [selectedFilters, debouncedSearch, tableIndex]);

  const PaginationComponent = (event) => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.itemsPerPage}
      totalCount={referrers.total}
      totalLength={referrers.data.length}
      lastPage={referrers.last_page}
      currentPage={referrers.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  const onUpdate = (row) => {
    setJobToUpdate(row);
    setOpen(true);
  };

  const columns = useJobPositionColumns({ setTableIndex, onUpdate });

  return (
    <STableWrapper>
      <Search search={search} onChange={setSearch} margin='0 0 24px' />
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={referrers?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
          onRowClick={(e, row) => onUpdate(row)}
        />
      )}
      {!!open && (
        <AddJobPosition
          open={open}
          onClose={() => {
            setOpen(false);
            setJobToUpdate(null);
          }}
          onSuccess={() => setTableIndex(Date.now())}
          data={jobToUpdate}
        />
      )}
    </STableWrapper>
  );
};
