import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { uploadDriverDocument } from 'Api/Driver';
import {
  uploadMvrFile,
  deleteMvrRecord,
  deletePspRecord,
  deleteFmcsaRecord,
  uploadDrugTestFile,
  uploadPspRecordFile,
  deleteCriminalRecord,
  deleteDrugTestRecord,
  uploadFmcsaRecordFile,
  deleteMedicalExaminers,
  uploadCriminalRecordFile,
  uploadMedicalExaminersFile,
} from 'Api/DriverV2';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import MultipleDocuments from './MultipleDocuments';
import {
  getInitialValues,
  criminalCheckResultOptions,
  resultOptions,
  mvrReasonOptions,
  fmcsaReasonOptions,
  drugTestReasonOptions,
} from './MedicalAndBackgroundTab.data';
import { validationSchema } from './validationSchema';
import { SEditWrapper, SAddMore } from './MedicalAndBackgroundTab.styles';

const MedicalAndBackgroundEdit = ({ onSave, driverId, medicalData, applicantMedicalAndBackground }) => {
  const showToaster = useShowToaster();

  const { values, handleChange, setValues, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(medicalData, applicantMedicalAndBackground),
    validationSchema,
    onSubmit: onSave,
  });

  const onFileUpload = async (field, file, type) => {
    try {
      const formData = new FormData();
      formData.append('driver_id', driverId);
      if (type === 'medicalCard') {
        formData.append('attach_card', file);
        const { data } = await uploadMedicalExaminersFile(formData);
        handleChange(field, data.file_path.attach_card);
        return;
      }

      if (type === 'longForm') {
        formData.append('attach_long_form', file);
        const { data } = await uploadMedicalExaminersFile(formData);
        handleChange(field, data.file_path.attach_long_form);
        return;
      }

      if (type === 'medicalVerification') {
        formData.append('attach_medical_verification', file);
        const { data } = await uploadMedicalExaminersFile(formData);
        handleChange(field, data.file_path.attach_medical_verification);
        return;
      }

      if (type === 'nationalRegistry') {
        formData.append('attach_national_registry', file);
        const { data } = await uploadMedicalExaminersFile(formData);
        handleChange(field, data.file_path.attach_national_registry);
        return;
      }

      if (type === 'mvr') {
        formData.append('attach_mvr_check_result', file);
        const { data } = await uploadMvrFile(formData);
        handleChange(field, data.file_path);
        return;
      }

      if (type === 'drug') {
        formData.append('attach_drug_check_result', file);
        const { data } = await uploadDrugTestFile(formData);
        handleChange(field, data.file_path);
        return;
      }

      if (type === 'drugCustomForm') {
        const { data } = await uploadDriverDocument(file, driverId);
        handleChange(field, data.full_path);
        return;
      }

      if (type === 'criminal') {
        formData.append('attach_criminal_result', file);
        const { data } = await uploadCriminalRecordFile(formData);
        handleChange(field, data.file_path);
        return;
      }

      if (type === 'fmcsa') {
        formData.append('attach_fmcsa_result', file);
        const { data } = await uploadFmcsaRecordFile(formData);
        handleChange(field, data.file_path);
        return;
      }

      if (type === 'psp') {
        formData.append('attach_psp_check_result', file);
        const { data } = await uploadPspRecordFile(formData);
        handleChange(field, data.file_path);
        return;
      }

      const { data } = await uploadDriverDocument(file, driverId);
      handleChange(field, data.document_path);
    } catch (e) {
      // Do nothing
    }
  };

  const deleteMedicalExaminersRecord = async (recordId) => {
    try {
      await deleteMedicalExaminers(recordId);
      showToaster({ type: 'success', message: 'Medical examiners record has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const deleteCriminalCheckRecord = async (recordId) => {
    try {
      await deleteCriminalRecord(recordId);
      showToaster({ type: 'success', message: 'Criminal check record has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const deletePspResult = async (recordId) => {
    try {
      await deletePspRecord(recordId);
      showToaster({ type: 'success', message: 'Psp result has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const deleteFmcsa = async (recordId) => {
    try {
      await deleteFmcsaRecord(recordId);
      showToaster({ type: 'success', message: 'FMCSA result has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const deleteMvrCheckRecord = async (recordId) => {
    try {
      await deleteMvrRecord(recordId);
      showToaster({ type: 'success', message: 'MVR check record has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const deleteDrugTest = async (recordId) => {
    try {
      await deleteDrugTestRecord(recordId);
      showToaster({ type: 'success', message: 'Drug test record has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onAddMore = (type) => {
    if (type === 'medicalExaminers') {
      handleChange(`medicalExaminersResults[${values.medicalExaminersResults.length}]`, {
        isNew: true,
        id: Math.random(),
        expiry_date: null,
        attach_card: '',
        attach_long_form: '',
        attach_medical_verification: '',
        attach_national_registry: '',
      });
    }

    if (type === 'psp') {
      handleChange(`pspResults[${values.pspResults.length}]`, {
        isNew: true,
        id: Math.random(),
        psp_check_date: null,
        attach_psp_check_result: '',
      });
    }

    if (type === 'fmcsa') {
      handleChange(`fmcsaResults[${values.fmcsaResults.length}]`, {
        isNew: true,
        id: Math.random(),
        reason: '',
        fmcsa_checked_date: null,
        fmcsa_next_check_date: null,
        attach_fmcsa_result: [],
        no_expiry: false,
      });
    }

    if (type === 'criminal') {
      handleChange(`criminalCheckResults[${values.criminalCheckResults.length}]`, {
        isNew: true,
        id: Math.random(),
        criminal_check_results: '',
        criminal_check_date: null,
        attach_criminal_result: '',
      });
    }

    if (type === 'mvr') {
      handleChange(`mvrCheckResults[${values.mvrCheckResults.length}]`, {
        isNew: true,
        id: Math.random(),
        mvr_check_results: '',
        mvr_check_date: null,
        mvr_next_check_date: null,
        attach_mvr_check_result: '',
      });
    }

    if (type === 'drug') {
      handleChange(`drugTestResults[${values.drugTestResults.length}]`, {
        isNew: true,
        id: Math.random(),
        type: '',
        reason: '',
        drug_check_results: '',
        drug_checked_date: null,
        attach_drug_check_result: '',
        drug_test_custom_form: '',
      });
    }
  };

  return (
    <SEditWrapper>
      <form id='medical-and-background-form' onSubmit={handleSubmit}>
        <div className='edit-box'>
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            Medical Examiners Results
          </Typography>
          <div className='d-flex flex-column gap-2'>
            {values.medicalExaminersResults.map((item, index) => {
              return (
                <div key={item.id} className='d-flex align-items-end gap-5'>
                  <DatePicker
                    width='200px'
                    label={index === 0 ? 'Expiry Date' : null}
                    name={`medicalExaminersResults[${index}].expiry_date`}
                    value={item.expiry_date}
                    onChange={(val) => handleChange(`medicalExaminersResults[${index}].expiry_date`, val)}
                    error={touchedErrors.medicalExaminersResults?.[index]?.expiry_date}
                  />
                  <div className='uploader-wrapper mb-1'>
                    <Uploader
                      type={3}
                      label={index === 0 ? 'Card' : null}
                      document={item.attach_card}
                      onDrop={(files) =>
                        onFileUpload(`medicalExaminersResults[${index}].attach_card`, files[0], 'medicalCard')
                      }
                      onRemove={() => handleChange(`medicalExaminersResults[${index}].attach_card`, '')}
                      error={touchedErrors.medicalExaminersResults?.[index]?.attach_card}
                    />
                  </div>
                  <div className='uploader-wrapper mb-1'>
                    <Uploader
                      type={3}
                      label={index === 0 ? 'Long Form' : null}
                      document={item.attach_long_form}
                      onDrop={(files) =>
                        onFileUpload(`medicalExaminersResults[${index}].attach_long_form`, files[0], 'longForm')
                      }
                      onRemove={() => handleChange(`medicalExaminersResults[${index}].attach_long_form`, '')}
                      error={touchedErrors.medicalExaminersResults?.[index]?.attach_long_form}
                    />
                  </div>
                  <div className='uploader-wrapper mb-1'>
                    <Uploader
                      type={3}
                      label={index === 0 ? 'Medical Verification' : null}
                      document={item.attach_medical_verification}
                      onDrop={(files) =>
                        onFileUpload(
                          `medicalExaminersResults[${index}].attach_medical_verification`,
                          files[0],
                          'medicalVerification'
                        )
                      }
                      onRemove={() => handleChange(`medicalExaminersResults[${index}].attach_medical_verification`, '')}
                      error={touchedErrors.medicalExaminersResults?.[index]?.attach_medical_verification}
                    />
                  </div>
                  <div className='uploader-wrapper mb-1'>
                    <Uploader
                      type={3}
                      label={index === 0 ? 'National Registry' : null}
                      document={item.attach_national_registry}
                      onDrop={(files) =>
                        onFileUpload(
                          `medicalExaminersResults[${index}].attach_national_registry`,
                          files[0],
                          'nationalRegistry'
                        )
                      }
                      onRemove={() => handleChange(`medicalExaminersResults[${index}].attach_national_registry`, '')}
                      error={touchedErrors.medicalExaminersResults?.[index]?.attach_national_registry}
                    />
                  </div>
                  <div
                    className='mb-1 pointer'
                    onClick={() => {
                      if (!item.isNew) {
                        deleteMedicalExaminersRecord(item.id);
                      }
                      setValues((prevState) => ({
                        ...prevState,
                        medicalExaminersResults: prevState.medicalExaminersResults.filter((el) => el.id !== item.id),
                      }));
                    }}
                  >
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </div>
                </div>
              );
            })}
          </div>
          <SAddMore onClick={() => onAddMore('medicalExaminers')}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        </div>

        <div className='edit-box'>
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            Criminal Check Results
          </Typography>
          <div className='d-flex flex-column gap-2'>
            {values.criminalCheckResults.map((item, index) => {
              return (
                <div key={item.id} className='d-flex align-items-end gap-5'>
                  <div>
                    <Autocomplete
                      width='200px'
                      label={index === 0 ? 'Results' : ''}
                      name={`criminalCheckResults[${index}].criminal_check_results`}
                      options={criminalCheckResultOptions}
                      value={item.criminal_check_results}
                      onChange={(e, val) => handleChange(`criminalCheckResults[${index}].criminal_check_results`, val)}
                      error={touchedErrors.criminalCheckResults?.[index]?.criminal_check_results}
                    />
                  </div>
                  <DatePicker
                    width='200px'
                    label={index === 0 ? 'Checked' : null}
                    name={`criminalCheckResults[${index}].criminal_check_date`}
                    value={item.criminal_check_date}
                    onChange={(val) => handleChange(`criminalCheckResults[${index}].criminal_check_date`, val)}
                    error={touchedErrors.criminalCheckResults?.[index]?.criminal_check_date}
                    disableFuture
                  />
                  <div className='mb-1'>
                    <Uploader
                      width='200px'
                      type={3}
                      uploaderLabel='Attach Results'
                      document={item.attach_criminal_result}
                      onDrop={(files) =>
                        onFileUpload(`criminalCheckResults[${index}].attach_criminal_result`, files[0], 'criminal')
                      }
                      onRemove={() => handleChange(`criminalCheckResults[${index}].attach_criminal_result`, '')}
                      error={touchedErrors.criminalCheckResults?.[index]?.attach_criminal_result}
                    />
                  </div>
                  <div
                    className='mb-1 pointer'
                    onClick={() => {
                      if (!item.isNew) {
                        deleteCriminalCheckRecord(item.id);
                      }
                      setValues((prevState) => ({
                        ...prevState,
                        criminalCheckResults: prevState.criminalCheckResults.filter((el) => el.id !== item.id),
                      }));
                    }}
                  >
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </div>
                </div>
              );
            })}
          </div>
          <SAddMore onClick={() => onAddMore('criminal')}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        </div>
        <div className='edit-box'>
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            MVR Check Results
          </Typography>
          <div className='d-flex flex-column gap-2'>
            {values.mvrCheckResults.map((item, index) => {
              return (
                <div key={item.id} className='d-flex align-items-end gap-5'>
                  <div>
                    <Autocomplete
                      width='200px'
                      label={index === 0 ? 'Reason' : ''}
                      name={`mvrCheckResults[${index}].mvr_check_results`}
                      options={mvrReasonOptions}
                      value={item.mvr_check_results}
                      onChange={(e, val) => handleChange(`mvrCheckResults[${index}].mvr_check_results`, val)}
                      error={touchedErrors.mvrCheckResults?.[index]?.mvr_check_results}
                    />
                  </div>
                  <div className='d-flex'>
                    <div>
                      {index === 0 && <InputLabel>Checked</InputLabel>}
                      <DatePicker
                        width='150px'
                        name={`mvrCheckResults[${index}].mvr_check_date`}
                        value={item.mvr_check_date}
                        borderRadius='6px 0 0 6px'
                        onChange={(val) => handleChange(`mvrCheckResults[${index}].mvr_check_date`, val)}
                        error={touchedErrors.mvrCheckResults?.[index]?.mvr_check_date}
                        disableFuture
                      />
                    </div>
                    <div>
                      {index === 0 && <InputLabel>Next Check Date</InputLabel>}
                      <DatePicker
                        width='150px'
                        name={`mvrCheckResults[${index}].mvr_next_check_date`}
                        value={item.mvr_next_check_date}
                        borderRadius='0 6px 6px 0'
                        onChange={(val) => handleChange(`mvrCheckResults[${index}].mvr_next_check_date`, val)}
                        error={touchedErrors.mvrCheckResults?.[index]?.mvr_next_check_date}
                        minDate={item.mvr_check_date}
                      />
                    </div>
                  </div>
                  <div className='mb-1'>
                    <Uploader
                      width='200px'
                      type={3}
                      uploaderLabel='Attach Results'
                      document={item.attach_mvr_check_result}
                      onDrop={(files) =>
                        onFileUpload(`mvrCheckResults[${index}].attach_mvr_check_result`, files[0], 'mvr')
                      }
                      onRemove={() => handleChange(`mvrCheckResults[${index}].attach_mvr_check_result`, '')}
                      error={touchedErrors.mvrCheckResults?.[index]?.attach_mvr_check_result}
                    />
                  </div>
                  <div
                    className='mb-1 pointer'
                    onClick={() => {
                      if (!item.isNew) {
                        deleteMvrCheckRecord(item.id);
                      }
                      setValues((prevState) => ({
                        ...prevState,
                        mvrCheckResults: prevState.mvrCheckResults.filter((el) => el.id !== item.id),
                      }));
                    }}
                  >
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </div>
                </div>
              );
            })}
          </div>
          <SAddMore onClick={() => onAddMore('mvr')}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        </div>
        <div className='edit-box'>
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            Drug & Alcohol Results
          </Typography>
          <div className='d-flex flex-column gap-2'>
            {values.drugTestResults.map((item, index) => {
              return (
                <div key={item.id} className='d-flex align-items-end gap-5'>
                  <div>
                    <Autocomplete
                      width='150px'
                      label={index === 0 ? 'Type' : ''}
                      name={`drugTestResults[${index}].type`}
                      options={['Drug Test', 'Alcohol']}
                      value={item.type}
                      onChange={(e, val) => handleChange(`drugTestResults[${index}].type`, val)}
                      error={touchedErrors.drugTestResults?.[index]?.type}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      width='200px'
                      label={index === 0 ? 'Reason' : ''}
                      name={`drugTestResults[${index}].reason`}
                      options={drugTestReasonOptions}
                      value={item.reason}
                      onChange={(e, val) => handleChange(`drugTestResults[${index}].reason`, val)}
                      error={touchedErrors.drugTestResults?.[index]?.reason}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      width='200px'
                      label={index === 0 ? 'Results' : ''}
                      name={`drugTestResults[${index}].drug_check_results`}
                      options={resultOptions}
                      value={item.drug_check_results}
                      onChange={(e, val) => handleChange(`drugTestResults[${index}].drug_check_results`, val)}
                      error={touchedErrors.drugTestResults?.[index]?.drug_check_results}
                    />
                  </div>
                  <DatePicker
                    width='140px'
                    label={index === 0 ? 'Checked' : null}
                    name={`drugTestResults[${index}].drug_checked_date`}
                    value={item.drug_checked_date}
                    onChange={(val) => handleChange(`drugTestResults[${index}].drug_checked_date`, val)}
                    error={touchedErrors.drugTestResults?.[index]?.drug_checked_date}
                    disableFuture
                  />
                  <div className='uploader-wrapper mb-1'>
                    <Uploader
                      type={3}
                      uploaderLabel='Attach Results'
                      document={item.attach_drug_check_result}
                      onDrop={(files) =>
                        onFileUpload(`drugTestResults[${index}].attach_drug_check_result`, files[0], 'drug')
                      }
                      onRemove={() => handleChange(`drugTestResults[${index}].attach_drug_check_result`, '')}
                      error={touchedErrors.drugTestResults?.[index]?.attach_drug_check_result}
                    />
                  </div>
                  <div className='uploader-wrapper mb-1'>
                    {item.type !== 'Alcohol' && (
                      <Uploader
                        type={3}
                        uploaderLabel='Drug Test Custom Form'
                        document={item.drug_test_custom_form}
                        onDrop={(files) =>
                          onFileUpload(`drugTestResults[${index}].drug_test_custom_form`, files[0], 'drugCustomForm')
                        }
                        onRemove={() => handleChange(`drugTestResults[${index}].drug_test_custom_form`, '')}
                        error={touchedErrors.drugTestResults?.[index]?.drug_test_custom_form}
                      />
                    )}
                  </div>
                  <div
                    className='mb-1 pointer'
                    onClick={() => {
                      if (!item.isNew) {
                        deleteDrugTest(item.id);
                      }
                      setValues((prevState) => ({
                        ...prevState,
                        drugTestResults: prevState.drugTestResults.filter((el) => el.id !== item.id),
                      }));
                    }}
                  >
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </div>
                </div>
              );
            })}
          </div>
          <SAddMore onClick={() => onAddMore('drug')}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        </div>

        <div className='edit-box'>
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            FMCSA Clearinghouse Results
          </Typography>
          <div className='d-flex flex-column gap-2'>
            {values.fmcsaResults.map((item, index) => {
              return (
                <div key={item.id} className='d-flex align-items-end gap-5'>
                  <div>
                    <Autocomplete
                      width='200px'
                      label={index === 0 ? 'Reason' : ''}
                      name={`fmcsaResults[${index}].reason`}
                      options={fmcsaReasonOptions}
                      value={item.reason}
                      onChange={(e, val) => handleChange(`fmcsaResults[${index}].reason`, val)}
                      error={touchedErrors.fmcsaResults?.[index]?.reason}
                    />
                  </div>

                  <div className='d-flex'>
                    <div>
                      {index === 0 && <InputLabel>Checked</InputLabel>}
                      <DatePicker
                        width='150px'
                        name={`fmcsaResults[${index}].fmcsa_checked_date`}
                        value={item.fmcsa_checked_date}
                        borderRadius='6px 0 0 6px'
                        onChange={(val) => handleChange(`fmcsaResults[${index}].fmcsa_checked_date`, val)}
                        error={touchedErrors.fmcsaResults?.[index]?.fmcsa_checked_date}
                        disableFuture
                      />
                    </div>
                    <div>
                      {index === 0 && <InputLabel>Next Check Date</InputLabel>}
                      <DatePicker
                        width='150px'
                        name={`fmcsaResults[${index}].fmcsa_next_check_date`}
                        value={item.fmcsa_next_check_date}
                        borderRadius='0 6px 6px 0'
                        onChange={(val) => handleChange(`fmcsaResults[${index}].fmcsa_next_check_date`, val)}
                        error={touchedErrors.fmcsaResults?.[index]?.fmcsa_next_check_date}
                        minDate={item.fmcsa_checked_date}
                        disabled={item.no_expiry}
                      />
                    </div>
                    <div className='ms-3' style={{ marginTop: index === 0 ? '30px' : '0px' }}>
                      <CustomCheckbox
                        name={`fmcsaResults[${index}].no_expiry`}
                        checked={item.no_expiry}
                        onChange={(checked) => {
                          handleChange(`fmcsaResults[${index}].no_expiry`, checked);
                          if (checked) {
                            handleChange(`fmcsaResults[${index}].fmcsa_next_check_date`, null);
                          }
                        }}
                      >
                        <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                          No Expiry
                        </Typography>
                      </CustomCheckbox>
                    </div>
                  </div>
                  <div className='mb-1'>
                    <MultipleDocuments values={values} handleChange={handleChange} index={index} driverId={driverId} />
                  </div>
                  <div
                    className='mb-1 pointer'
                    onClick={() => {
                      if (!item.isNew) {
                        deleteFmcsa(item.id);
                      }
                      setValues((prevState) => ({
                        ...prevState,
                        fmcsaResults: prevState.fmcsaResults.filter((el) => el.id !== item.id),
                      }));
                    }}
                  >
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </div>
                </div>
              );
            })}
          </div>
          <SAddMore onClick={() => onAddMore('fmcsa')}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        </div>

        <div className='edit-box'>
          <Typography variant='s1' style={{ color: palette.gray700 }}>
            PSP Results
          </Typography>
          <div className='d-flex flex-column gap-2'>
            {values.pspResults.map((item, index) => {
              return (
                <div key={item.id} className='d-flex align-items-end gap-5'>
                  <DatePicker
                    width='200px'
                    label={index === 0 ? 'Checked' : null}
                    name={`pspResults[${index}].psp_check_date`}
                    value={item.psp_check_date}
                    onChange={(val) => handleChange(`pspResults[${index}].psp_check_date`, val)}
                    error={touchedErrors.pspResults?.[index]?.psp_check_date}
                    disableFuture
                  />
                  <div className='mb-1'>
                    <Uploader
                      width='200px'
                      type={3}
                      uploaderLabel='Attach Results'
                      document={item.attach_psp_check_result}
                      onDrop={(files) => onFileUpload(`pspResults[${index}].attach_psp_check_result`, files[0], 'psp')}
                      onRemove={() => handleChange(`pspResults[${index}].attach_psp_check_result`, '')}
                      error={touchedErrors.pspResults?.[index]?.attach_psp_check_result}
                    />
                  </div>
                  <div
                    className='mb-1 pointer'
                    onClick={() => {
                      if (!item.isNew) {
                        deletePspResult(item.id);
                      }
                      setValues((prevState) => ({
                        ...prevState,
                        pspResults: prevState.pspResults.filter((el) => el.id !== item.id),
                      }));
                    }}
                  >
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </div>
                </div>
              );
            })}
          </div>
          <SAddMore onClick={() => onAddMore('psp')}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        </div>
      </form>
    </SEditWrapper>
  );
};

export default MedicalAndBackgroundEdit;
