import { useSelector } from 'react-redux';
import { COUNTRIES } from 'pages/PublicPositions/ApplyJob/ApplyJob.data';
import { PERMISSIONS } from 'utils/constants';

export const generateId = (firstName, lastName, dateTime) => {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  const [datePart, timePart] = dateTime.split(' ');
  const formattedDate = datePart.replace(/\//g, '');
  const formattedTime = timePart.replace(/:/g, '');

  return `${initials}${formattedDate}${formattedTime}`;
};

export const getInitialValues = (incident, customerTime, firstName, lastName, dateTime) => {
  const { permissions } = useSelector((state) => state?.root);

  const {
    incident_id,
    report_number,
    incident_date,
    incident_time,
    country,
    city,
    state,
    incident_type,
    incident_record_documents,
    incident_record_images,
    incident_action_taken,
    notes,
    report_details,
    reason,
    added_by,
  } = incident || {};

  return {
    staff: null,
    incident_id: incident_id || (firstName ? generateId(firstName, lastName, dateTime) : ''),
    report_number: report_number || '',
    incident_date:
      incident_date && incident_time ? new Date(`${incident_date} ${incident_time}`) : new Date(customerTime),
    country: country || COUNTRIES[0],
    city: city || null,
    state: state || null,
    incident_type: incident_type || null,
    document_path: incident_record_documents || [],
    document_path_name: [],
    incident_record_images: incident_record_images?.map((image) => image.image_path) || [],
    action_taken: incident_action_taken || null,
    notes: notes || '',
    added_by: added_by || null,
    report_details: report_details || '',
    reason: reason || '',
    canReview: permissions.includes(PERMISSIONS.CAN_REVIEW_INCIDENTS.name) || false,
  };
};
