import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { ErrorMessage, Field } from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/createShipment/recurringLane.svg';
import Error from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { numberWithCommas, palette } from 'utils/constants';
import styles from 'components/TablePlaner/helpers/RecurrningDetails/steps/BillingCharges/billingCharges.module.css';
import { BillingChargesContext } from 'components/TableShipments/detailsRow/steps/BillingCharges/BillingChargeChild';

const BillingCharge = ({
  charge,
  index,
  currencyData,
  chargeErrors,
  setChargeErrors,
  miles,
  fuelPrice,
  disabledDelete,
  loadingDeleteChargeItem,
  onDeleteCharge,
}) => {
  const { values, shipmentChargeType, setFieldValue } = useContext(BillingChargesContext);
  const { billToData } = useSelector((state) => state?.shipments);
  const [recommendationApplied, setRecommendationApplied] = useState({ linehaul: false, fuelSurcharge: false });
  const didMountRef = useRef(false);

  const showIconCondition =
    (Number(charge.charge_type?.id || charge.charge_type) === 1 && recommendationApplied.linehaul) ||
    (Number(charge.charge_type?.id || charge.charge_type) === 2 && recommendationApplied.fuelSurcharge);
  const type =
    Number(charge.charge_type?.id || charge.charge_type) === 1
      ? 'lineHaul'
      : Number(charge.charge_type?.id || charge.charge_type) === 2
      ? 'fuel'
      : null;

  const namePrefix = `billing_charges[${index}]`;
  const isExpense =
    charge.charge_type?.type?.account_type === 'Expense' ||
    shipmentChargeType.find((el) => Number(el.id) === Number(charge.charge_type))?.type?.account_type === 'Expense';

  const selectValue =
    typeof values.billing_charges[index].charge_type === 'object' && values.billing_charges[index].charge_type !== null
      ? values.billing_charges[index].charge_type || null
      : (shipmentChargeType || []).find((i) => i.id === Number(values.billing_charges[index].charge_type));

  const validateRequired = (value) => {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  };

  const checkRecommendation = (formValues, isCustomerChange) => {
    const charge = formValues.billing_charges?.[index];
    const selectedCustomer = billToData.find((customer) => Number(customer.id) === Number(formValues.customer_id));
    const { customer_linehaul_rate_matrix, customer_fuel_surcharge_matrices } = selectedCustomer || {};

    if (!selectedCustomer || (!customer_fuel_surcharge_matrices?.length && !customer_linehaul_rate_matrix?.length)) {
      setRecommendationApplied({ linehaul: false, fuelSurcharge: false });

      if (
        Number(charge?.charge_type?.id || charge.charge_type) === 1 ||
        Number(charge?.charge_type?.id || charge.charge_type) === 2
      ) {
        setFieldValue(`${namePrefix}.rate`, '');
        setFieldValue(`${namePrefix}.quantity`, '');
      }

      return;
    }

    if (customer_linehaul_rate_matrix?.length) {
      if (
        !isCustomerChange &&
        !!charge.quantity &&
        !!charge.rate &&
        Number(charge?.charge_type?.id || charge.charge_type) !== 1
      ) {
        setRecommendationApplied({ linehaul: false, fuelSurcharge: false });
      }

      if (Number(charge?.charge_type?.id || charge.charge_type) === 1) {
        const matchingLinehaulMatrix = customer_linehaul_rate_matrix.find((el) => {
          const start = el?.loaded_miles_start;
          const end = el?.loaded_miles_end;
          return Number(miles) > Number(start) && Number(miles) < Number(end);
        });

        if (matchingLinehaulMatrix?.rate_per_mile) {
          setFieldValue(`${namePrefix}.quantity`, matchingLinehaulMatrix?.rate_type === 'flat' ? 1 : Math.round(miles));
          setFieldValue(`${namePrefix}.rate`, matchingLinehaulMatrix.rate_per_mile);

          setRecommendationApplied((prevState) => ({ ...prevState, linehaul: true }));
        }
      }
    }

    if (customer_fuel_surcharge_matrices?.length) {
      const price = fuelPrice?.diesel?.price;

      if (
        !isCustomerChange &&
        !!charge.quantity &&
        !!charge.rate &&
        Number(charge?.charge_type?.id || charge.charge_type) !== 2
      ) {
        setRecommendationApplied({ linehaul: false, fuelSurcharge: false });
      }

      if (Number(charge?.charge_type?.id || charge.charge_type) === 2) {
        const matchingFuelMatrix = customer_fuel_surcharge_matrices.find((el) => {
          const { from, to } = el;
          return Number(price) > Number(from) && Number(price) < Number(to);
        });

        if (matchingFuelMatrix?.fsc) {
          setFieldValue(`${namePrefix}.quantity`, Math.round(miles));
          setFieldValue(`${namePrefix}.rate`, matchingFuelMatrix.fsc);
          setRecommendationApplied((prevState) => ({ ...prevState, fuelSurcharge: true }));
        }
      }
    }
  };

  const resetRecommendation = (charge) => {
    if (
      Number(charge?.charge_type?.id || charge.charge_type === 1) ||
      Number(charge?.charge_type?.id || charge.charge_type === 2)
    ) {
      setRecommendationApplied((prevState) => ({
        linehaul:
          Number(charge?.charge_type?.id || charge.charge_type) === 1 && prevState.linehaul
            ? false
            : prevState.linehaul,
        fuelSurcharge:
          Number(charge?.charge_type?.id || charge.charge_type) === 2 && prevState.fuelSurcharge
            ? false
            : prevState.fuelSurcharge,
      }));
    }
  };

  const renderTooltip = (props, type) => {
    return (
      <Tooltip id='button-tooltip' {...props}>
        <div>
          {type === 'lineHaul'
            ? 'Applied rules from Linehaul Rate Matrix'
            : 'Applied rules from Fuel Surcharge Rate Matrix'}
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (!values?.customer_id || !didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    checkRecommendation(values, true);
  }, [values?.customer_id]);

  return (
    <div key={charge.id}>
      <div className={styles.body_child_container}>
        <div className='d-flex align-items-center gap-2'>
          <div className={styles.charges_container}>
            <Autocomplete
              width='400px'
              name={`${namePrefix}.charge_type`}
              labelKey='title'
              options={shipmentChargeType || []}
              value={selectValue || null}
              onChange={(e, val) => {
                setFieldValue(`${namePrefix}.charge_type`, val);
                if (val.type.account_type === 'Expense' && Number(charge.rate) > 0) {
                  setFieldValue(`${namePrefix}.rate`, `-${charge.rate}`);
                }
                if (val.type.account_type !== 'Expense' && Number(charge.rate) < 0) {
                  setFieldValue(`${namePrefix}.rate`, `${Math.abs(charge.rate)}`);
                }

                checkRecommendation(
                  {
                    ...values,
                    billing_charges: values.billing_charges.map((item, i) =>
                      i === index ? { ...item, charge_type: val, quantity: '', rate: '' } : item
                    ),
                  },
                  setFieldValue
                );
                setChargeErrors((prevState) => ({ ...prevState, [index]: false }));
              }}
              isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
            />
            <Error error={!!chargeErrors?.[index] && 'Required'} />
          </div>
          {showIconCondition ? (
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => {
                return renderTooltip(props, type);
              }}
            >
              <RefreshIcon fill={palette.green400} style={{ marginLeft: 8 }} />
            </OverlayTrigger>
          ) : (
            <div style={{ width: 24 }} />
          )}
        </div>
        <div className='charge_input_wrapper'>
          <Field
            name={`${namePrefix}.quantity`}
            type='number'
            component={CustomInput}
            labelStyle={{ margin: 0 }}
            validate={validateRequired}
            onChange={() => resetRecommendation(charge)}
            style={{
              width: 80,
              borderRadius: 6,
              height: 32,
              padding: '0 6px',
            }}
          />
          <ErrorMessage
            name={`${namePrefix}.quantity`}
            render={(error) => (
              <Typography variant='c2' style={{ color: palette.red500 }}>
                {error}
              </Typography>
            )}
          />
        </div>

        <div className={styles.charge_input_wrapper}>
          <Field
            name={`${namePrefix}.rate`}
            type='number'
            placeholder='0.00'
            component={CustomInput}
            labelStyle={{ margin: 0 }}
            isNegative={isExpense}
            validate={validateRequired}
            onChange={() => resetRecommendation(charge)}
            style={{ width: 160, borderRadius: 6, height: 32 }}
            leftIcon={<span style={{ color: palette.gray400 }}>{currencyData?.symbol || '$'}</span>}
          />
          <ErrorMessage
            name={`${namePrefix}.rate`}
            render={(error) => (
              <Typography variant='c2' style={{ color: palette.red500 }}>
                {error}
              </Typography>
            )}
          />
        </div>

        <div className={styles.subTotal_wrapper}>
          <Typography variant='s2'>
            {currencyData?.symbol || '$'}
            {numberWithCommas(((charge?.quantity ? charge.quantity : 0) * (charge?.rate ? charge.rate : 0)).toFixed(2))}
          </Typography>
        </div>

        <div className={styles.control_buttons}>
          <CustomButton
            type='secondary'
            disabled={disabledDelete && typeof charge.id === 'number'}
            leftIcon={
              <div className={styles.tick_leftIcon_wrapper}>
                <DeleteIcon fill={palette.gray700} style={{ width: 9, height: 9 }} />
                {loadingDeleteChargeItem !== false && +loadingDeleteChargeItem === +index && (
                  <ReactLoading
                    className={classNames(styles.reactLoading)}
                    type='spin'
                    color={palette.blue300}
                    height={16}
                    width={16}
                  />
                )}
              </div>
            }
            styleButton={{ padding: '7px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => onDeleteCharge()}
          />
        </div>
      </div>
      <div className={styles.line} style={{ margin: '4px 0 4px -20px' }} />
    </div>
  );
};

export default BillingCharge;
