import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './StatusUpdateModal.css';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

export default function OwnerOperatorUpdateModal({ ...props }) {
  const { use } = useTheme();
  const formik = useFormik({
    initialValues: {
      status: '',
    },
  });

  const statusUpdate = () => {
    props.updateDriverStatus(formik.values, props.onHide, props.onSuccess, 'Status has been updated successfully');
  };

  useEffect(() => {
    if (props.driverStatus) {
      formik.setFieldValue('status', props.driverStatus?.id);
    }
  }, [props.driverStatus]);

  return (
    <div className='modal-container'>
      <Modal {...props} dialogClassName='modal-dialog-box' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
          closeButton
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Update Status for {props.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{ backgroundColor: use(palette.white, palette.dark900) }}
        >
          <p className='subline' style={{ color: use(palette.gray900, palette.gray200) }}>
            Change status
          </p>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='status'
              onChange={() => {
                formik.setFieldValue('status', 1);
              }}
              value={formik.values.status}
              checked={formik.values.status === 1}
            />

            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Active
            </p>
          </div>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='status'
              onChange={() => {
                formik.setFieldValue('status', 2);
              }}
              value={formik.values.status}
              checked={formik.values.status === 2}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Disabled
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark800),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.white, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' onClick={statusUpdate}>
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
