import { useSelector } from 'react-redux';
import { formatNumber, KGS_TO_LBS, LBS_TO_KGS } from 'utils/constants';

const useGetShipmentWeight = () => {
  const { weightUnit } = useSelector((state) => state.root);

  const convertWeight = (weight, currentUnit, targetUnit) => {
    if (currentUnit === targetUnit) {
      return weight || 0;
    }
    return currentUnit === 'kgs' ? (weight || 0) * KGS_TO_LBS : (weight || 0) * LBS_TO_KGS;
  };

  const getShipmentWeight = (stops) => {
    const pickups = stops.filter((stop) => Number(stop.stop_point_type) === 1);

    const { expectedWeight, actualWeight } = pickups.reduce(
      (acc, current) => {
        const expectedCurrentWeightUnit = current.weight_types?.id === 1 ? 'lbs' : 'kgs';
        const convertedExpectedWeight = convertWeight(Number(current.weight), expectedCurrentWeightUnit, weightUnit);

        const actualCurrentWeightUnit = current.reported_weight_types?.id === 1 ? 'lbs' : 'kgs';
        const convertedActualWeight = convertWeight(
          Number(current.reported_weight),
          actualCurrentWeightUnit,
          weightUnit || 'kgs'
        );

        return {
          expectedWeight: acc.expectedWeight + convertedExpectedWeight,
          actualWeight: acc.actualWeight + convertedActualWeight,
        };
      },
      { expectedWeight: 0, actualWeight: 0 }
    );

    const weightUnitUpperCase = (weightUnit || 'kgs').toUpperCase();
    const expectedWeightWithUnit = `${formatNumber(expectedWeight)} ${weightUnitUpperCase}`;
    const actualWeightWithUnit = `${formatNumber(actualWeight)} ${weightUnitUpperCase}`;

    return { expectedWeight, actualWeight, expectedWeightWithUnit, actualWeightWithUnit };
  };

  return { getShipmentWeight };
};

export default useGetShipmentWeight;
