export const quarters = [
  { key: 'Q1', label: 'Q1', labelSelected: 'Q1' },
  { key: 'Q2', label: 'Q2', labelSelected: 'Q2' },
  { key: 'Q3', label: 'Q3', labelSelected: 'Q3' },
  { key: 'Q4', label: 'Q4', labelSelected: 'Q4' },
];

export const quarterRange = {
  Q1: 'January 1 through March 31',
  Q2: 'April 1 through June 30',
  Q3: 'July 1 through September 30',
  Q4: 'October 1 through December 31',
};
