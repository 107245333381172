import React, { useEffect, useMemo, useState } from 'react';
import { Collapse, OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as DeleteItemIcon } from 'assets/icons/delete.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';

import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { serviceProviders } from './TransactionFilters.data';
import sm from './TransactionFilters.module.css';

const TransactionFilters = ({ filter, updateFilter, resetSearch }) => {
  const { use } = useTheme();
  const [show, setShow] = useState(false);
  const [collapses, setCollapses] = useState({ types: false, provider: false });
  const [localFilter, setLocalFilter] = useState(filter);

  const styles = useMemo(() => {
    return {
      container: {
        marginTop: 18,
        borderColor: use(palette.gray200, palette.gray700),
      },
      title: {
        color: use(palette.indigo500, palette.indigo500),
        marginTop: 4,
        background: palette.indigo0,
        padding: '0 6px 0 6px',
      },
      reset: {
        marginLeft: 8,
        marginTop: 4,
        cursor: 'pointer',
        color: use(palette.indigo500, palette.indigo500),
      },
      searchValue: {
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: use(palette.indigo700, palette.indigo700),
      },
      additionalText: {
        marginTop: 4,
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: use(palette.indigo700, palette.indigo700),
      },
      options: {
        cursor: 'pointer',
      },
      checkboxes: {
        margin: '0 0 5px 10px',
        width: 'calc(50% - 30px)',
      },
      checkboxesText: {
        color: use(palette.gray700, palette.gray200),
        marginLeft: 5,
        whiteSpace: 'nowrap',
      },
    };
  }, [use, palette]);

  function onToggleCollapse(name) {
    setCollapses((prevState) => {
      return {
        types: false,
        [name]: !prevState[name],
      };
    });
  }

  function onTickCheckboxes(type, id) {
    const updatedValue = [...localFilter[type]];
    if (updatedValue.includes(id)) {
      const index = updatedValue.findIndex((itemId) => itemId === id);
      updatedValue.splice(index, 1);
    } else {
      updatedValue.push(id);
    }
    const newState = {
      ...localFilter,
      [type]: updatedValue,
    };
    setLocalFilter(newState);

    return newState;
  }

  const deleteFilterHandler = (type, id) => {
    const localFilter = onTickCheckboxes(type, id);
    applyHandler(localFilter);
  };

  const deleteTextFilterHandler = (key) => {
    updateFilter({ ...filter, [key]: null });
    setLocalFilter((prevState) => ({ ...prevState, [key]: null }));
  };

  const applyHandler = (localFilter) => {
    setShow(false);
    updateFilter({ ...filter, ...localFilter });
  };

  const onReset = () => {
    const filterResetState = {
      merchant: null,
      category: null,
      status: null,
      cardHolders: [],
      providers: [],
    };
    setLocalFilter(filterResetState);
    updateFilter({ ...filter, ...filterResetState });
    resetSearch();
  };

  // const statusCount = (filter.types.length ? 1 : 0);
  const providerCount = filter?.providers?.length ? 1 : 0;

  const optionsCount = providerCount;

  const content = (
    <Popover
      style={{
        minWidth: 510,
        backgroundColor: use(palette.white, palette.dark800),
      }}
    >
      <div className={sm.overlay_header} style={{ borderColor: use(palette.gray200, palette.gray700) }}>
        <Typography variant='h5'>Options</Typography>
      </div>

      <div className={sm.overlay_body} style={{ backgroundColor: use(palette.gray0, palette.dark700) }}>
        <div className={sm.overlay_status_wrapper}>
          <div
            className={`${sm.closed}`}
            onClick={() => {
              onToggleCollapse('payTerm');
            }}
          >
            <Typography variant='s1'>Service Provider</Typography>
            <ArrowDown style={{ transform: `rotateX(${collapses.type ? 180 : 0}deg)` }} />
          </div>
          <Collapse in={collapses.payTerm}>
            <div className={sm.opened}>
              {serviceProviders?.map((currentType) => {
                const { id, title } = currentType;
                return (
                  <CustomCheckbox
                    key={id}
                    style={styles.checkboxes}
                    checked={localFilter.providers?.includes(id)}
                    onChange={() => onTickCheckboxes('providers', id)}
                  >
                    <Typography variant='s2' style={styles.checkboxesText}>
                      {title}
                    </Typography>
                  </CustomCheckbox>
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
      <div className={sm.overlay_footer} style={{ borderColor: use(palette.gray200, palette.gray700) }}>
        <CustomButton
          title='Cancel'
          type='secondary'
          onClick={() => setShow(false)}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
        />
        <CustomButton
          title='Apply'
          type='primary'
          onClick={applyHandler.bind(null, localFilter)}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
        />
      </div>
    </Popover>
  );

  return (
    <div className={sm.TableHeaderFilterByShow_container} style={styles.container}>
      <div className={sm.title_wrapper}>
        <OverlayTrigger show={show} trigger='click' rootClose placement='right' overlay={content} onToggle={setShow}>
          <div className={sm.overlay_trigger_wrapper}>
            <Typography variant='overLine' style={styles.options}>
              <FilterIcon
                width={11}
                height={11}
                style={{ marginRight: 4 }}
                fill={use(palette.gray700, palette.gray200)}
              />
              Options: {optionsCount}
            </Typography>
          </div>
        </OverlayTrigger>
        <Typography variant='overLine' style={styles.reset} onClick={onReset}>
          Clear All
        </Typography>
      </div>
      <div className={sm.mapped_data_container}>
        <SelectedTextFilter
          title='Merchant'
          value={filter.merchant}
          styles={styles}
          onDelete={() => deleteTextFilterHandler('merchant')}
        />
        <SelectedSingleFilter
          title='Category'
          filterKey='description'
          value={filter.category}
          styles={styles}
          onDelete={() => deleteTextFilterHandler('category')}
        />
        <SelectedSingleFilter
          title='Type'
          filterKey='name'
          value={filter.status}
          styles={styles}
          onDelete={() => deleteTextFilterHandler('status')}
        />
        <SelectedFilter
          title='Service Provider'
          selectedTitleKey='title'
          selectedIds={filter.providers}
          allData={serviceProviders}
          styles={styles}
          onDelete={(id) => deleteFilterHandler('providers', id)}
        />
      </div>
    </div>
  );
};

const SelectedFilter = ({ title, selectedTitleKey, selectedIds, allData, styles, onDelete }) => {
  const [dataMap, setDataMap] = useState([]);

  useEffect(() => {
    const newDataMap = selectedIds?.map((id) => {
      return allData?.find((data) => data.id === id);
    });
    setDataMap(newDataMap || []);
  }, [selectedIds]);

  if (dataMap?.length === 0) {
    return null;
  }

  return (
    <div className={sm.mapped_data_wrapper}>
      <Typography variant='c1' style={styles.title}>
        {title}:
      </Typography>
      {dataMap?.map((item, index) => (
        <div key={item.id} className={sm.many_data_show_wrapper}>
          <Typography variant='c1' style={styles.searchValue}>
            {item[selectedTitleKey]}
            {index !== dataMap.length - 1 && ','}
            &nbsp;
          </Typography>
          <div className={sm.delete_item_wrapper} onClick={() => onDelete(item.id)}>
            <DeleteItemIcon width={8} height={8} />
          </div>
        </div>
      ))}
    </div>
  );
};

const SelectedSingleFilter = ({ title, value, filterKey, styles, onDelete }) => {
  if (!value) {
    return null;
  }

  return (
    <div className={sm.mapped_data_wrapper}>
      <Typography variant='c1' style={styles.title}>
        {title}:
      </Typography>
      <div className={sm.many_data_show_wrapper}>
        <Typography variant='c1' style={styles.searchValue}>
          {value[filterKey]}
          &nbsp;
        </Typography>
        <div className={sm.delete_item_wrapper} onClick={onDelete}>
          <DeleteItemIcon width={8} height={8} />
        </div>
      </div>
    </div>
  );
};

const SelectedTextFilter = ({ title, value, styles, onDelete }) => {
  if (!value) {
    return null;
  }

  return (
    <div className={sm.mapped_data_wrapper}>
      <Typography variant='c1' style={styles.title}>
        {title}:
      </Typography>
      <div className={sm.many_data_show_wrapper}>
        <Typography variant='c1' style={styles.searchValue}>
          {value}
          &nbsp;
        </Typography>
        <div className={sm.delete_item_wrapper} onClick={onDelete}>
          <DeleteItemIcon width={8} height={8} />
        </div>
      </div>
    </div>
  );
};

export default TransactionFilters;
