import { object, string, number } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  date: validator.requiredDate(),
  reference_id: string().trim().required('Required'),
  account: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  memo: string().trim().required('Required'),
  debit: number(),
  credit: number(),
});
