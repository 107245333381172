import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useDateFormat from 'hooks/useDateFormat';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { formatNumber, palette } from 'utils/constants';
import RowActions from 'pages/Accounting/Payables/TableSection/shared/RowActions';

export const useColumns = ({
  allSelected,
  selectedRows,
  hideSelectAll,
  handleSelectAll,
  handleSelectRow,
  sort,
  sortingQuery,
  onEdit,
  onDelete,
  onMarkPaid,
}) => {
  const { formatDate } = useDateFormat();
  const hasQuickbook = useHasQuickbook();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: '',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: !hideSelectAll ? <CustomCheckbox onChange={handleSelectAll} checked={allSelected} /> : '',
      render: (row) => (
        <span onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.id === row.id)}
          />
        </span>
      ),
    },
    {
      field: 'vendor',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='VENDOR' field='vendor_id' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {row.vendor?.name || '-'}
          </Typography>
          {!!row.qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
        </div>
      ),
    },
    {
      field: 'due_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DUE DATE' field='bill_due_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.bill_due_date)}
        </Typography>
      ),
    },
    {
      field: 'aging',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AGING' field='aging' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.aging > 0
            ? `Past due ${row.aging} day${Math.abs(row.aging) > 1 ? 's' : ''}`
            : row.aging < 0
            ? `Due in ${Math.abs(row.aging)} day${Math.abs(row.aging) > 1 ? 's' : ''}`
            : '0'}
        </Typography>
      ),
    },
    {
      field: 'reference_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='BILL REFERENCE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.reference_id}
        </Typography>
      ),
    },
    {
      field: 'memo',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='MEMO' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.memo}
        </Typography>
      ),
    },
    {
      field: 'amount_due',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AMOUNT DUE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.amount_due || 0)}
        </Typography>
      ),
    },
    {
      field: 'amount_paid',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AMOUNT PAID' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.amount_paid)}
        </Typography>
      ),
    },
    {
      field: 'open_balance',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='OPEN BALANCE' field='open_balance' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.open_balance)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.bill_due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='' />,
      render: (row) => (
        <RowActions
          onPayBill={() => null}
          onEdit={() => onEdit(row)}
          onDelete={() => onDelete(row)}
          onMarkPaid={() => onMarkPaid(row)}
        />
      ),
    },
  ];
};
