import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;

  .flex-row {
    display: flex;
    padding: 16px 8px;
    border-bottom: 1px solid #e9edf5;
  }

  .left {
    width: 270px;
  }

  .right {
    flex-grow: 1;
    max-width: 500px;
  }

  .underline-text {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${palette.indigo500};
    padding-left: 8px;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
