import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import {
  createCustomerLinehaulRateMatrix,
  deleteCustomersLinehaulRateMatrix,
  getCustomerLinehaulRateMatrix,
  updateCustomersLinehaulRateMatrix,
} from 'Api/Customers';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import ColumnHeader from 'common/ColumnHeader';
import useDateFormat from 'hooks/useDateFormat';
import { DatePicker } from 'common/Pickers';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import Autocomplete from 'common/Autocomplete';
import { SMergedInputs } from 'components/CreateShipment/CreateShipment.styles';
import sm from './LinehaulRateMatrixTab.module.css';
import { Typography } from '../Typography';
import CustomInput from '../CreateShipment/helpers/CustomInput';
import CustomButton from '../CustomButton/CustomButton';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';
import MaterialTableWrapper from '../MaterialTableWrapper';
import CustomizedSnackbars from '../toast/Toast';

const LinehaulRateMatrixTab = () => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [linehaulRateMatrix, setLinehaulRateMatrix] = useState();
  const [changedIndexes, setChangedIndexes] = useState([]);
  const showToaster = useShowToaster();
  const [weightUnities] = useState([
    { id: 1, name: 'LBS', key: 1, label: 'LBS', labelSelected: null },
    {
      id: 2,
      name: 'KG',
      key: 2,
      label: 'KG',
      labelSelected: null,
    },
  ]);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });
  const [toggle, setToggle] = React.useState(false);
  const [refetchIndex, setRefetchIndex] = React.useState(Date.now());
  const formRef = useRef();
  const { id } = useParams();
  const { use } = useTheme();

  const defaultValue = {
    pricing_date: null,
    loaded_miles_start: '',
    loaded_miles_end: '',
    weight_start: '',
    weight_end: '',
    weight_unit_id: '',
    rate_per_mile: '',
    rate_type: 'mile',
  };
  const titlesOption = useMemo(() => {
    return [
      { key: 1, label: 'Pricing Date', width: '10%', required: true },
      { key: 2, label: 'Loaded Miles Start', width: '10%', required: true },
      { key: 3, label: 'Loaded Miles End', width: '10%', required: true },
      { key: 4, label: 'Weight Start', width: '10%', required: true },
      { key: 5, label: 'Weight End', width: '10%', required: true },
      { key: 6, label: 'Weight Unit', width: '10%', required: true },
      { key: 7, label: 'Linehaul Rate', width: '10%', required: true },
      { key: 8, label: '', width: '5%' },
    ];
  }, []);

  const onUpdate = (el, index, values, errors, getFieldHelpers, arrayHelpers) => {
    if (changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
    const pricing_date = getFieldHelpers(`linehaulRate[${index}][pricing_date]`);
    const loaded_miles_start = getFieldHelpers(`linehaulRate[${index}][loaded_miles_start]`);
    const loaded_miles_end = getFieldHelpers(`linehaulRate[${index}][loaded_miles_end]`);
    const weight_start = getFieldHelpers(`linehaulRate[${index}][weight_start]`);
    const weight_unit_id = getFieldHelpers(`linehaulRate[${index}][weight_unit_id]`);
    const rate_per_mile = getFieldHelpers(`linehaulRate[${index}][rate_per_mile]`);
    const rate_type = getFieldHelpers(`linehaulRate[${index}][rate_type]`);
    pricing_date.setTouched('Required');
    loaded_miles_start.setTouched('Required');
    loaded_miles_end.setTouched('Required');
    weight_start.setTouched('Required');
    weight_unit_id.setTouched('Required');
    rate_per_mile.setTouched('Required');
    rate_type.setTouched('Required');
    if (el?.id) {
      updateLinehaulRateMatrixUpdate(el, values);
    } else {
      createLinehaulRateMatrixUpdate(el, values, index, arrayHelpers);
    }
  };

  const createLinehaulRateMatrixUpdate = (el, values, index) => {
    const f = new FormData();
    f.append(`linehaul_rate_matrix[${index}][loaded_miles_start]`, el.loaded_miles_start);
    f.append(`linehaul_rate_matrix[${index}][loaded_miles_end]`, el.loaded_miles_end);
    if (moment(el.pricing_date).isValid()) {
      f.append(`linehaul_rate_matrix[${index}][pricing_date]`, moment(el.pricing_date).format('YYYY-MM-DD'));
    }
    f.append(`linehaul_rate_matrix[${index}][weight_start]`, el.weight_start);
    f.append(`linehaul_rate_matrix[${index}][weight_end]`, el.weight_end);
    f.append(`linehaul_rate_matrix[${index}][weight_unit_id]`, el.weight_unit_id);
    f.append(`linehaul_rate_matrix[${index}][rate_per_mile]`, el.rate_per_mile);
    f.append(`linehaul_rate_matrix[${index}][rate_type]`, el.rate_type);
    f.append(`customer_id`, id);
    createCustomerLinehaulRateMatrix(f)
      .then((res) => {
        if (res?.status === 200) {
          setShowMessage({ ...showMessage, message: 'Linehaul Rate Matrix created successfully!', visible: true });
        }
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) });
      });
  };

  const updateLinehaulRateMatrixUpdate = (el) => {
    const updateData = {
      customer_id: el.customer_id,
      loaded_miles_start: el.loaded_miles_start,
      pricing_date: moment(el.pricing_date).isValid ? moment(el.pricing_date).format('YYYY-MM-DD') : null,
      loaded_miles_end: el.loaded_miles_end,
      weight_start: el.weight_start,
      weight_end: el.weight_end,
      weight_unit_id: el.weight_unit_id,
      rate_per_mile: el.rate_per_mile,
      rate_type: el.rate_type,
    };

    updateCustomersLinehaulRateMatrix(updateData, el.id)
      .then((res) => {
        if (res && res.data) {
          setShowMessage({ ...showMessage, message: 'Linehaul Rate Matrix updated successfully', visible: true });
        }
      })
      .catch((e) => {
        showToaster({ type: 'error', message: getErrorMessage(e) });
      });
  };

  const onDelete = (el, index, arrayHelpers) => {
    if (el?.id) {
      deleteCustomersLinehaulRateMatrix(el.id).then(() => {
        arrayHelpers.remove(index);
        setShowMessage({ ...showMessage, message: 'Linehaul Rate Matrix deleted successfully', visible: true });
      });
    } else {
      arrayHelpers.remove(index);
    }
  };

  const onCancel = () => {
    setToggle(!toggle);
    setRefetchIndex(Date.now());
    setShowMessage({
      ...showMessage,
      message: '',
      visible: false,
    });
    setChangedIndexes([]);
  };

  useEffect(() => {
    getCustomerLinehaulRateMatrix({ id }).then((res) => {
      setLinehaulRateMatrix({ linehaulRate: res });
    });
  }, [refetchIndex]);

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = linehaulRateMatrix?.linehaulRate?.data?.length
    ? {
        linehaulRate: linehaulRateMatrix?.linehaulRate?.data.map((item) => ({
          ...item,
          pricing_date: moment(item.pricing_date).toDate(),
        })),
      }
    : { linehaulRate: [{ ...defaultValue }] };

  const handleSelectChange = (index) => {
    if (!changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    }
  };

  const handleFieldChange = (index, fieldName, values, setChangedIndexes, changedIndexes) => (event) => {
    const newValue = event?.target?.value || event;
    const oldValue = values.linehaulRate[index][fieldName];
    const isChanged = newValue !== oldValue;

    if (isChanged && !changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    } else if (!isChanged && changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }
  };

  return (
    <div
      className={sm.linehaulRateMatrixTab}
      style={{
        backgroundColor: use(palette.gray0, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div
        className={sm['linehaulRateMatrixTab-header']}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <p className={sm.heading} style={{ color: use(palette.gray900, palette.gray50) }}>
          Linehaul Rate Matrix
        </p>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={onCancel} className='driver-compansation-cancel-btn'>
              Close
            </button>
          </div>
        )}
      </div>
      {toggle ? (
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <Formik initialValues={initialValue} innerRef={formRef}>
                {({ values, getFieldHelpers, setFieldValue, errors, touched }) => (
                  <Form>
                    <FieldArray
                      name='linehaulRate'
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            <div className={sm.row}>
                              {titlesOption.map((el, index) => {
                                const namePrefix = `${index}text`;
                                return (
                                  <Typography
                                    key={namePrefix}
                                    variant='s2'
                                    style={{ width: el.width, whiteSpace: 'nowrap' }}
                                  >
                                    {el.label}
                                    {el.required && <span className={sm.required_system}>*</span>}
                                  </Typography>
                                );
                              })}
                            </div>

                            {values.linehaulRate.map((el, index) => {
                              const namePrefix = `linehaulRate[${index}]`;
                              return (
                                <div key={namePrefix} className={[sm.row, sm.line].join(' ')} style={{ marginTop: 0 }}>
                                  <div style={{ width: '10%' }}>
                                    <DatePicker
                                      name={`${namePrefix}.pricing_date`}
                                      value={values.linehaulRate[index].pricing_date}
                                      onChange={(val) => {
                                        setFieldValue(`${namePrefix}.pricing_date`, val);
                                        handleSelectChange(index);
                                      }}
                                      disablePast
                                      error={
                                        touched?.linehaulRate?.[index]?.pricing_date &&
                                        errors?.linehaulRate?.[index]?.pricing_date
                                      }
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[loaded_miles_start]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'left' }}
                                      type='number'
                                      onChange={handleFieldChange(
                                        index,
                                        'loaded_miles_start',
                                        values,
                                        setChangedIndexes,
                                        changedIndexes
                                      )}
                                      labelStyle={styles.labelStyle}
                                      inputStyle={{ width: '100%' }}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[loaded_miles_start]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[loaded_miles_end]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'left' }}
                                      type='number'
                                      inputStyle={{ width: '100%' }}
                                      onChange={handleFieldChange(
                                        index,
                                        'loaded_miles_end',
                                        values,
                                        setChangedIndexes,
                                        changedIndexes
                                      )}
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[loaded_miles_end]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[weight_start]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'left' }}
                                      type='number'
                                      inputStyle={{ width: '100%' }}
                                      onChange={handleFieldChange(
                                        index,
                                        'weight_start',
                                        values,
                                        setChangedIndexes,
                                        changedIndexes
                                      )}
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[weight_start]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[weight_end]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, textAlign: 'left' }}
                                      type='number'
                                      onChange={handleFieldChange(
                                        index,
                                        'weight_end',
                                        values,
                                        setChangedIndexes,
                                        changedIndexes
                                      )}
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[weight_end]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <Field
                                      name={`${namePrefix}[weight_unit_id]`}
                                      options={weightUnities}
                                      styles={styles.type}
                                      component={CustomSelect}
                                      menuStyles={styles.fullWidthPercent}
                                      validate={validateStopPointInput}
                                      onChange={() => {
                                        handleSelectChange(index);
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[weight_unit_id]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <SMergedInputs>
                                      <Field
                                        name={`${namePrefix}[rate_per_mile]`}
                                        component={CustomInput}
                                        style={{
                                          marginTop: 0,
                                          height: '32px',
                                          borderRadius: '8px 0 0 8px',
                                          paddingLeft: 10,
                                        }}
                                        labelStyle={{
                                          alignItems: 'flex-start',
                                          marginTop: 0,
                                        }}
                                        onChange={handleFieldChange(
                                          index,
                                          'rate_per_mile',
                                          values,
                                          setChangedIndexes,
                                          changedIndexes
                                        )}
                                        validate={validateStopPointInput}
                                        className={sm.input_basic_details}
                                      />
                                      <Autocomplete
                                        name={`${namePrefix}.rate_type`}
                                        value={values.linehaulRate[index].rate_type}
                                        options={['mile', 'flat']}
                                        onChange={(e, val) => {
                                          handleFieldChange(
                                            index,
                                            'rate_type',
                                            values,
                                            setChangedIndexes,
                                            changedIndexes
                                          )(val);
                                          setFieldValue(`${namePrefix}.rate_type`, val);
                                        }}
                                      />
                                    </SMergedInputs>
                                    <ErrorMessage
                                      name={`${namePrefix}[rate_per_mile]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>
                                  <div
                                    style={{ width: '5%', height: 32 }}
                                    className='d-flex justify-content-center align-items-center gap-2'
                                  >
                                    <DeleteIcon
                                      width={12}
                                      height={12}
                                      fill={palette.indigo500}
                                      style={styles.deleteIcon}
                                      onClick={() => onDelete(el, index, arrayHelpers)}
                                    />

                                    {!changedIndexes.includes(index) ? (
                                      <TickIcon
                                        fill={use(palette.green500, palette.white)}
                                        style={{ margin: '12px 0 0 0' }}
                                      />
                                    ) : (
                                      <CustomButton
                                        type='primary'
                                        title=''
                                        styleButton={{
                                          padding: '6px 12px',
                                          margin: '6px 0 0 0',
                                        }}
                                        styleTitle={{ fontSize: 14 }}
                                        onClick={() => onUpdate(el, index, values, errors, getFieldHelpers)}
                                        leftIcon={
                                          <TickIcon
                                            fill={use(palette.white, palette.white)}
                                            style={{ width: 9, height: 9 }}
                                          />
                                        }
                                        buttonProps={{
                                          type: 'submit',
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}

                            <CustomButton
                              type='secondary'
                              title='Add Another'
                              styleTitle={styles.addAnotherTitle}
                              styleButton={styles.addAnotherButton}
                              onClick={() => arrayHelpers.push({ ...defaultValue })}
                              leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                            />
                          </div>
                        );
                      }}
                    />
                  </Form>
                )}
              </Formik>
              {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
            </div>
          </div>
        </div>
      ) : (
        <div className={sm['table-wrap']}>
          <div className='sub-table-container'>
            <MaterialTableWrapper
              columns={[
                {
                  title: <ColumnHeader title='PRICING DATE' field='pricing_date' />,
                  field: 'pricing_date',
                  render: (rowData) => {
                    return (
                      <p className={sm['list-text']}>{rowData.pricing_date ? formatDate(rowData.pricing_date) : '-'}</p>
                    );
                  },
                },
                {
                  title: <ColumnHeader title='LOADED MILES START' field='loaded_miles_start' />,
                  field: 'loaded_miles_start',
                  render: (rowData) => {
                    return <p className={sm['list-text']}>{rowData.loaded_miles_start}</p>;
                  },
                },
                {
                  title: <ColumnHeader title='LOADED MILES END' field='loaded_miles_end' />,
                  field: 'loaded_miles_end',
                  render: (rowData) => {
                    return <p className={sm['list-text']}>{rowData.loaded_miles_end}</p>;
                  },
                },
                {
                  title: <ColumnHeader title='WEIGHT START' field='weight_start' />,
                  field: 'weight_start',
                  render: (rowData) => {
                    return <p className={sm['list-text']}>{rowData.weight_start}</p>;
                  },
                },
                {
                  title: <ColumnHeader title='WEIGHT END' field='weight_end' />,
                  field: 'weight_end',
                  render: (rowData) => {
                    return <p className={sm['list-text']}>{rowData.weight_end}</p>;
                  },
                },
                {
                  title: <ColumnHeader title='WEIGHT UNIT' field='weight_unit_id' />,
                  field: 'weight_unit_id',
                  render: (rowData) => {
                    const weight_unit = rowData.weight_unit;
                    return <p className={sm['list-text']}>{weight_unit?.unit}</p>;
                  },
                },
                {
                  title: <ColumnHeader title='LINEHAUL RATE' field='rate_per_mile' />,
                  field: 'rate_per_mile',
                  render: (rowData) => {
                    return (
                      <p className={sm['list-text']}>
                        {currency}
                        {formatNumber(rowData.rate_per_mile)}/{rowData.rate_type || 'mile'}
                      </p>
                    );
                  },
                },
              ]}
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
              data={linehaulRateMatrix?.linehaulRate?.data}
              options={{
                sorting: false,
                toolbar: false,
                draggable: false,
                paging: false,
                rowStyle: {
                  borderBottom: 'none',
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LinehaulRateMatrixTab;
