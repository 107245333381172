import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import added from 'assets/icons/drivers/success-filled.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

export default function NewDriverAdded({ name, desc, ...props }) {
  const { use } = useTheme();
  return (
    <Modal
      {...props}
      enforceFocus={false}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='custom-modal-width'
    >
      <Modal.Body
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='driver-added driver-style-wrap'>
          <div className='added-icon'>
            <img src={added} alt='' />
          </div>
          <p className='driver-success'>{name}</p>
          <p className='driver-success-subline' style={{ color: use(palette.gray700, palette.gray200) }}>
            {desc}
          </p>
          <Button
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              borderColor: use(palette.gray50, palette.darkborder),
              color: use(palette.gray700, palette.gray200),
            }}
            className='driver-added-btn'
            onClick={props.onHide}
          >
            Okay
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
