import React, { useState } from 'react';
import { ReactComponent as CancelIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as PencileIcon } from 'assets/icons/editPensle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/drivers/right.svg';
import Input from 'common/Input';
import { SimpleLoader } from 'common/Loader';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateRecurringLane } from 'Api/Shipment';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const LaneName = ({ data, onUpdateSuccess }) => {
  const showToaster = useShowToaster();
  const [isEditLaneName, setIsEditLaneName] = useState(false);
  const [laneName, setLaneName] = useState(data.shipment_lane?.name || '');
  const [laneNameError, setLaneNameError] = useState('');
  const [loading, setLoading] = useState(false);

  const onLaneNameUpdate = async () => {
    if (!laneName) {
      setLaneNameError('Required');
      return;
    }

    setLoading(true);
    try {
      const formData = new URLSearchParams();
      formData.append('name', laneName);
      await updateRecurringLane(data.shipment_lane.id, formData);
      onUpdateSuccess();
      setIsEditLaneName(false);
      showToaster({ type: 'success', message: 'Lane name has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='d-flex align-items-center gap-3'>
      {!isEditLaneName ? (
        <>
          <Typography variant='h1' style={{ height: '33px' }}>
            {data.shipment_lane?.name}
          </Typography>
          <PencileIcon width={14} height={14} className='pointer' onClick={() => setIsEditLaneName(true)} />
        </>
      ) : (
        <>
          <Input
            value={laneName}
            onChange={(e) => {
              setLaneNameError('');
              setLaneName(e.target.value);
            }}
            error={laneNameError}
          />
          <CancelIcon
            width={14}
            height={14}
            fill={palette.red500}
            className='pointer'
            onClick={() => {
              setIsEditLaneName(false);
              setLaneName(data.shipment_lane?.name);
              setLaneNameError('');
            }}
          />
          {loading ? (
            <SimpleLoader loading size={14} />
          ) : (
            <CheckIcon width={14} height={14} className='pointer' onClick={onLaneNameUpdate} />
          )}
        </>
      )}
    </div>
  );
};

export default LaneName;
