import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkBoxDark.svg';
import Chip from 'common/Chip';
import { SimpleLoader } from 'common/Loader';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { applyPolicyToUsers, getUsers, removePolicyFromUsers } from 'Api/Insurance';
import { typeOptions } from 'pages/CompanySettings/pagesComponents/StaffDriver/StaffDriver.data';
import CircularProgress from '@mui/material/CircularProgress';
import { validationSchema } from './validationSchema';
import { SActions, SMergedInputs, STable } from './AppliedUsers.styles';

const ApplyUsers = ({ policyType }) => {
  const showToaster = useShowToaster();
  const [appliedUsers, setAppliedUsers] = useState([]);
  const [notAppliedUsers, setNotAppliedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const driverIds = [];
      const staffIds = [];

      values.users.forEach((item) => {
        if (item.type === 'driver') {
          driverIds.push(item.id);
        }
        if (item.type === 'staff') {
          staffIds.push(item.id);
        }
      });

      const body = {
        policy_name: policyType,
        users: [],
      };

      if (driverIds.length) {
        body.users.push({ type: 'driver', id: driverIds });
      }
      if (staffIds.length) {
        body.users.push({ type: 'staff', id: staffIds });
      }

      await applyPolicyToUsers(body);
      showToaster({ type: 'success', message: 'User has been successfully added!' });
      getAppliedUsers();
      getNotAppliedUsers();
      resetForm();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, resetForm } = useForm({
    initialValues: { type: null, users: [] },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const getAppliedUsers = async () => {
    try {
      const { data } = await getUsers({
        policy_type: policyType,
        is_applied: 1,
        'filters[type]': typeOptions.map((i) => i.id),
      });
      setAppliedUsers(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getNotAppliedUsers = async () => {
    try {
      const { data } = await getUsers({
        policy_type: policyType,
        is_applied: 0,
        'filters[type]': values.type ? [values.type.id] : typeOptions.map((i) => i.id),
      });
      setNotAppliedUsers(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const removeUser = async (users) => {
    try {
      // setLoadingDelete(true);
      const driverIds = [];
      const staffIds = [];

      users.forEach((item) => {
        if (item.type === 'driver') {
          driverIds.push(item.id);
        }
        if (item.type === 'staff') {
          staffIds.push(item.id);
        }
      });

      const body = {
        policy_name: policyType,
        users: [],
      };

      if (driverIds.length) {
        body.users.push({ type: 'driver', id: driverIds });
      }
      if (staffIds.length) {
        body.users.push({ type: 'staff', id: staffIds });
      }

      await removePolicyFromUsers(body);
      showToaster({ type: 'success', message: 'User has been successfully removed!' });
      getAppliedUsers();
      getNotAppliedUsers();
      resetForm();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    getAppliedUsers();
    getNotAppliedUsers();
  }, [policyType, values.type]);

  return (
    <div style={{ width: '564px' }}>
      <div className='d-flex align-items-center gap-3'>
        <SMergedInputs>
          <div>
            <Autocomplete
              width='170px'
              name='type'
              className='left-autocomplete'
              placeholder='Select Type'
              options={typeOptions}
              value={values.type}
              onChange={(e, val) => handleChange('type', val)}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              error={touchedErrors.type}
              disableClearable
            />
          </div>
          <div>
            <Autocomplete
              multiple
              limitTags={1}
              width='350px'
              name='users'
              className='right-autocomplete'
              labelKey='full_name'
              placeholder={values.users.length ? '' : 'Select User'}
              options={notAppliedUsers}
              value={values.users}
              onChange={(e, val) => handleChange('users', val)}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getTagLabel={(option) => `${option.full_name}`}
              error={touchedErrors.users}
            />
          </div>
        </SMergedInputs>
        <SActions>
          {loading ? (
            <SimpleLoader loading={loading} />
          ) : (
            <CustomButton
              type='primary'
              leftIcon={<CheckIcon fill={palette.white} />}
              styleButton={{ padding: '7px 8px', margin: 0 }}
              onClick={handleSubmit}
              disabled={loading || !values.type || !values.users?.length}
            />
          )}
        </SActions>
      </div>
      <div className='mt-2'>
        <STable>
          <tbody>
            {appliedUsers.slice(0, showMore ? undefined : 10).map((user) => (
              <tr className='body-row' key={user.id}>
                <td>
                  <Chip
                    label={
                      user.type === 'staff'
                        ? 'STAFF'
                        : user?.app?.driver_type === 'company'
                        ? 'COMPANY DRIVER'
                        : 'CONTRACT DRIVER'
                    }
                  />
                </td>
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    {user.full_name}
                  </Typography>
                </td>
                <td>
                  <SActions>
                    {loadingDelete ? (
                      <span className='loading-wrapper'>
                        <CircularProgress size={16} />
                      </span>
                    ) : (
                      <span className='action-wrapper' onClick={() => removeUser([user])}>
                        <DeleteIcon width={9} height={9} fill={palette.red500} />
                      </span>
                    )}
                  </SActions>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {appliedUsers.length > 10 && (
          <div className='d-flex justify-content-center mt-1'>
            <Typography
              variant='c1'
              style={{ color: palette.indigo500, cursor: 'pointer' }}
              onClick={() => setShowMore((prevState) => !prevState)}
            >
              Show {showMore ? 'Less' : 'More'}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplyUsers;
