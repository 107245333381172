import { object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  next_available_date: validator.requiredDate(),
  zipcode: string().test('zipcode', 'Either provide zipcode or country, state, and city', (value, context) => {
    const { zipcode, country, state, city } = context.parent;

    return !!zipcode || (!!country && !!state && !!city);
  }),
  notes: string().trim().required('Required').nullable(),
  note_to_email: string().email('Invalid Email Address').nullable(),
});
