import React from 'react';
import moment from 'moment';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SHeader } from '../PublicQuote.styles';

const Header = ({ company, quote }) => {
  const { logo_path } = company || {};
  const { quote_id, expiry_date, accept_date, decline_date, decline_reason, status_id } = quote || {};

  return (
    <SHeader>
      <div className='company-info'>
        <div>
          <div className='logo-wrapper'>{logo_path && <img src={logo_path} alt='Company logo' height={48} />}</div>
          <div className='d-flex justify-content-between align-items-center'>
            <Typography variant='h2'>QUOTE {quote_id}</Typography>
            {!!expiry_date && status_id !== 3 && status_id !== 4 && (
              <Chip label={`EXPIRES ON ${moment(expiry_date).format('MMMM DD, YYYY').toUpperCase()}`} />
            )}
            {(status_id === 3 || status_id === 4) && (
              <div className='d-flex flex-column align-items-start gap-1'>
                <Chip
                  label={
                    status_id === 3
                      ? accept_date
                        ? `ACCEPTED ON ${moment(accept_date).format('MM/DD/YYYY')}`
                        : `ACCEPTED`
                      : decline_date
                      ? `DECLINED ON ${moment(decline_date).format('MM/DD/YYYY')}`
                      : `DECLINED`
                  }
                  labelColor={status_id === 3 ? palette.green500 : palette.red500}
                  bgColor={status_id === 3 ? palette.green0 : palette.red0}
                  fontWeight={500}
                />
                {status_id === 4 && decline_reason && (
                  <Typography variant='c3' style={{ color: palette.gray700, maxWidth: '300px' }}>
                    {decline_reason}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </SHeader>
  );
};

export default Header;
