import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { SBoxContent } from 'pages/Dashboard/Dashboard.styles';
import { getAccountsList } from 'Api/Accounts';
import AddAccount from 'pages/Accounting/Accounts/components/AddAccount';
import { useColumns } from './Accounts.data';
import { SWrapper } from './Accounts.styles';

const Accounts = () => {
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [openAddAccount, setOpenAddAccount] = useState(false);

  const getAccounts = async () => {
    try {
      setLoadingAccounts(true);
      const { data } = await getAccountsList();
      setAccounts(data.filter((acc) => acc.account_type.id === ACCOUNT_TYPE.BANK));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingAccounts(false);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const columns = useColumns();

  return (
    <div className='box-content-wrapper'>
      <SBoxContent>
        <SWrapper>
          <div className='d-flex flex-column gap-2'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Accounts
            </Typography>
            {!loadingAccounts && (
              <div className='accounts-table-wrapper' style={{ maxHeight: '320px', overflowY: 'auto' }}>
                <MaterialTableWrapper data={accounts} style={{ backgroundColor: palette.white }} columns={columns} />
              </div>
            )}
          </div>
          <div style={{ borderTop: `1px solid ${palette.gray50}` }}>
            <div className='add-account' onClick={() => setOpenAddAccount(true)}>
              <PlusIcon fill={palette.indigo500} />
              <span>Add Account</span>
            </div>
          </div>
        </SWrapper>
        {openAddAccount && (
          <AddAccount
            open={openAddAccount}
            onClose={() => {
              setOpenAddAccount(false);
            }}
            onSuccess={getAccounts}
          />
        )}
      </SBoxContent>
    </div>
  );
};

export default Accounts;
