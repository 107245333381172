import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { DATE_FORMAT, TIME_FORMAT } from 'utils/constants';
import { updateDateTimeFormat } from 'store/reducers/root.reducer';
import { getDateTimeSettings, createDateTimeSettings } from 'Api/CompanySettings';
import { validationSchema } from './validationSchema';
import { dateFormats, getInitialValues, timeFormats } from './DateFormat.data';
import { SWrapper, SItemRow } from './DateFormat.styles';

const DateFormat = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const dispatch = useDispatch();
  const [settingsData, setSettingsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSubmit = async (values) => {
    setLoadingUpdate(true);
    try {
      const body = {
        date_format: values.date_format.id || null,
        time_format: values.time_format.id || null,
      };
      const response = await createDateTimeSettings(body);
      dispatch(
        updateDateTimeFormat({
          dateFormat: DATE_FORMAT[response.date_format] || DATE_FORMAT[1],
          timeFormat: TIME_FORMAT[response.time_format] || TIME_FORMAT[1],
        })
      );
      setSettingsData(response);
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: getInitialValues(settingsData),
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const getDateTimeFormat = async () => {
    try {
      const response = await getDateTimeSettings();
      setSettingsData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDateTimeFormat();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      <PageHeader
        hideSkip
        title='Date and Time Format'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
      />
      <Divider />
      <div>
        <SItemRow>
          <div>
            <Typography variant='s2'>Date Format</Typography>
          </div>
          <div>
            {dateFormats.map((item) => (
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='date_format'
                  checked={values.date_format?.id === item.id}
                  onChange={() => handleChange('date_format', item)}
                  smail={false}
                />
                <Typography variant='s2'>{item.name}</Typography>
              </div>
            ))}
          </div>
        </SItemRow>
        <SItemRow>
          <div>
            <Typography variant='s2'>Time Format</Typography>
          </div>
          <div>
            {timeFormats.map((item) => (
              <div className='d-flex align-items-center gap-2'>
                <CustomCheckbox
                  type='switch'
                  name='time_format'
                  checked={values.time_format?.id === item.id}
                  onChange={() => handleChange('time_format', item)}
                  smail={false}
                />
                <Typography variant='s2'>{item.name}</Typography>
              </div>
            ))}
          </div>
        </SItemRow>
      </div>
    </SWrapper>
  );
};

export default DateFormat;
