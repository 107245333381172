import React, { useState } from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { hookOrDropTrailer } from 'Api/Shipment';

const RestrictedCheckIn = ({ open, onClose, shipment, onSuccess }) => {
  const { equipment } = shipment || {};
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const body = { hooked_to_id: null };
      await hookOrDropTrailer(equipment.id, body);
      onSuccess();
      onClose();
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Restricted'
      $maxWidth='470px'
      $minWidth='470px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Okay' },
        {
          key: 'submit',
          type: 'primary',
          title: `Unhook Trailer ${equipment?.hooked_to?.equipment_id}`,
          onClick: onSubmit,
          disabled: loading,
        },
      ]}
    >
      <Typography variant='s1' as='p' className='mt-3 mb-3' style={{ color: palette.gray700 }}>
        This shipment requires you to hook a trailer upon arrival of Stop A but you currently have a trailer hooked.
      </Typography>
    </Modal>
  );
};

export default RestrictedCheckIn;
