import React from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { formatNumber, palette } from 'utils/constants';

const getOptions = (currency) => ({
  responsive: true,
  aspectRatio: 1.5,
  borderWidth: 1.5,
  tension: 0.2,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          switch (item[0]?.dataIndex) {
            case 0:
              return moment().subtract(11, 'month').format('MMM YYYY');
            case 1:
              return moment().subtract(10, 'month').format('MMM YYYY');
            case 2:
              return moment().subtract(9, 'month').format('MMM YYYY');
            case 3:
              return moment().subtract(8, 'month').format('MMM YYYY');
            case 4:
              return moment().subtract(7, 'month').format('MMM YYYY');
            case 5:
              return moment().subtract(6, 'month').format('MMM YYYY');
            case 6:
              return moment().subtract(5, 'month').format('MMM YYYY');
            case 7:
              return moment().subtract(4, 'month').format('MMM YYYY');
            case 8:
              return moment().subtract(3, 'month').format('MMM YYYY');
            case 9:
              return moment().subtract(2, 'month').format('MMM YYYY');
            case 10:
              return moment().subtract(1, 'month').format('MMM YYYY');
            case 11:
              return moment().format('MMM YYYY');
            default:
          }
        },
        label(context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += `${currency}${formatNumber(context.parsed.y)}`;
          }
          return label;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      boxPadding: 3,
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value, index, values) {
          if (index === 0) {
            return moment().subtract(11, 'month').format('MMM YYYY');
          }
          if (index === values.length - 1) {
            return moment().format('MMM YYYY');
          }
        },
      },
    },
    y: {
      suggestedMax: 3000,
      beginAtZero: true,
      grid: {
        borderDash: [8, 8],
        color: palette.gray50,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1000,
        callback(value) {
          if (value === 0) {
            return value;
          }
          return `${currency}${value / 1000}k`;
        },
      },
    },
  },
});

const ReadyToInvoice = ({ monthsData }) => {
  const { currency } = useSelector((state) => state.root);

  const data = {
    labels: (monthsData || []).map((item) => `${item.month}`),
    datasets: [
      {
        fill: true,
        label: 'Ready to Invoice',
        data: monthsData?.map((i) => i.ready) || [],
        borderColor: palette.indigo500,
        pointBackgroundColor: palette.indigo500,
        backgroundColor: 'rgba(79, 90, 237, 0.2)',
      },
      {
        fill: false,
        label: 'Missing Paperwork',
        data: monthsData?.map((i) => i.missing) || [],
        borderColor: palette.red500,
        pointBackgroundColor: palette.red500,
      },
    ],
  };

  return <Line id='chart' data={data} options={getOptions(currency)} />;
};

export default ReadyToInvoice;
