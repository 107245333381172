import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Search from 'common/Search';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useShowToaster from 'hooks/useShowToaster';
import { numberWithCommas, palette } from 'utils/constants';
import { getCategoryDetails, getSubcategoryReport } from 'Api/Reports';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import Header from 'pages/Reports/ReportDetails/shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { STable, STableFilters, STableWrapper } from '../ReportDetails.styles';

const NEC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [reportItems, setReportItems] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year().toString());
  const didMountRef = useRef(false);

  const getReportDetails = async (year) => {
    try {
      const params = {
        pay_period_start: selectedYear ? moment(`${year}-01-01`).startOf('year').format('YYYY-MM-DD') : undefined,
        pay_period_end: selectedYear ? moment(`${year}-01-01`).endOf('year').format('YYYY-MM-DD') : undefined,
      };
      const { data } = await getCategoryDetails(id, params);
      setReportItems(data?.report_data || []);
      setFilteredReports(data?.report_data || []);
      setYears((data?.years || []).map((item) => item.toString()));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      navigate('/reports');
    }
    setLoading(false);
  };

  const onPdfClick = async (download) => {
    try {
      const params = {
        pay_period_start: selectedYear
          ? moment(`${selectedYear}-01-01`).startOf('year').format('YYYY-MM-DD')
          : undefined,
        pay_period_end: selectedYear ? moment(`${selectedYear}-01-01`).endOf('year').format('YYYY-MM-DD') : undefined,
      };
      const { data } = await getSubcategoryReport(24, params);
      const checkedData = (data || []).filter((item) => checkedRows.includes(item.id));
      const { url } = await generatePDF(checkedData, download, dateFormat);
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const handleSelectAll = () => {
    if (checkedRows.length === filteredReports.length) {
      setCheckedRows([]);
      return;
    }
    setCheckedRows(filteredReports.map((item) => item.id));
  };

  const handleRowCheck = (checked, id) => {
    if (checked) {
      setCheckedRows((prevState) => [...prevState, id]);
      return;
    }

    setCheckedRows((prevState) => prevState.filter((checkedId) => checkedId !== id));
  };

  const onSearch = (value) => {
    setCheckedRows([]);
    setSearch(value);

    const filtered = reportItems.filter((item) =>
      (item?.name || '').toLowerCase().includes((value || '').toLowerCase())
    );
    setFilteredReports(filtered);
  };

  const onYearChange = (value) => {
    setCheckedRows([]);
    setSelectedYear(value);
    setSearch('');
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getReportDetails(selectedYear);
    didMountRef.current = true;
  }, [selectedYear]);

  return (
    <PageWrapper loading={loading} title='1099-NEC'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <Autocomplete
              width='120px'
              options={years}
              value={selectedYear}
              onChange={(e, value) => onYearChange(value)}
            />
            <Search search={search} onChange={onSearch} />
          </div>
          <CustomButton
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            className='action-button'
            disabled={checkedRows.length === 0}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header onDownloadPdf={() => onPdfClick(true)} disabled={checkedRows.length === 0} />
        <Divider margin='8px 0 20px' />
        <STable>
          <thead>
            <tr className='header-row'>
              <th>
                {!loading && !!filteredReports.length && (
                  <CustomCheckbox
                    labelStyle={{ marginTop: 0 }}
                    checked={checkedRows.length === filteredReports.length}
                    onChange={handleSelectAll}
                  />
                )}
              </th>
              <th>NAME</th>
              <th>ADDRESS</th>
              <th>TAX ID</th>
              <th>COMPENSATION</th>
              <th>FEDERAL TAX</th>
              <th>STATE TAX</th>
            </tr>
          </thead>
          <tbody>
            {filteredReports.map((item) => (
              <tr key={item.id} className='body-row'>
                <td style={{ width: '3%' }}>
                  <CustomCheckbox
                    labelStyle={{ marginTop: 0 }}
                    checked={checkedRows.some((id) => id === item.id)}
                    onChange={(checked) => handleRowCheck(checked, item.id)}
                  />
                </td>
                <td style={{ width: '22%' }}>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.name}
                  </Typography>
                </td>
                <td style={{ width: '29%' }}>
                  {item.address_details ? (
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.address_details?.address}, {item.address_details?.city?.name},{' '}
                      {item.address_details.state?.short_name || item.address_details?.state?.name}{' '}
                      {item.address_details?.zip} -{' '}
                      {item.address_details?.country?.sortname || item.address_details?.country?.name}
                    </Typography>
                  ) : (
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      -
                    </Typography>
                  )}
                </td>
                <td style={{ width: '10%' }}>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {item.tax_id}
                  </Typography>
                </td>
                <td style={{ width: '12%' }}>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {numberWithCommas(item.compensation.toFixed(2))}
                  </Typography>
                </td>
                <td style={{ width: '12%' }}>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {numberWithCommas(item.federal_tax.toFixed(2))}
                  </Typography>
                </td>
                <td style={{ width: '12%' }}>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {numberWithCommas(item.state_tax.toFixed(2))}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
      </STableWrapper>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Vendor List' />}
    </PageWrapper>
  );
};

export default NEC;
