import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import bigDecimal from 'js-big-decimal';
import { ADDON_PLAN_ID, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { getAdditionalServices } from 'store/actions/billing.actions';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import { GetPurchaseAddonList, cancelAddonDowngrade } from 'Api/Billing';
import { useAuth } from 'context/auth.context';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import AdditionalServicesHeader from '../AdditionalServicesHeader/AdditionalServicesHeader';
import CustomModal from '../../CustomModal/CustomModal';
import PurchaseAddOnsModal from '../PurchaseAddOnsModal/PurchaseAddOnsModal';
import Preloader from '../../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper from '../../MaterialTableWrapper';
import AddonsCancelModal from './AddonsCancelModal/AddonsCancelModal';
import AddonsManageModal from './AdditionalServicesFooter/AddonsManageModal/AddonsManageModal';
import PurchaseAddOnsModalFinal from '../PurchaseAddOnsModalFinal/PurchaseAddOnsModalFinal';
import { brokerageAddon, useColumns } from './AdditionalServices.data';

const AdditionalServices = ({ onPurchaseSuccess }) => {
  const { use } = useTheme();
  const { value } = useAuth();
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
  const showToaster = useShowToaster();
  const elementsRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const openAddon = searchParams.get('openAddon');
  const [data, setData] = useState([]);
  const { additionalServicesListStatus, additionalServicesList, billingDetail } = useSelector((state) => state.billing);
  const [purchaseAddOnsModal, setPurchaseAddOnsModal] = useState(false);
  const [purchaseAddons, setPurchaseAddons] = useState(null);
  const [manage, setManage] = useState({ visible: false });
  const [cancel, setCancel] = useState({ visible: false });
  const [showTool, setShowTool] = useState({});
  const [activePurchase, setActivePurchase] = useState({});
  const [sort, setSort] = useState({ field: 'next_renewal_date', sortBy: 'asc' });
  const [selectedFilters, setSelectedFilters] = useState({
    page: 1,
    itemsPerPage: 25,
    showCancelled: false,
  });
  const { customer_plan, promo_code } = billingDetail || {};
  const brokerageAmount = useMemo(() => {
    const brokerage = (purchaseAddons || []).find((item) => item.plan_id === ADDON_PLAN_ID.BROKERAGE);
    const amount =
      (brokerage?.plans?.[0]?.monthly_amount || 0) * (planMapper[customer_plan?.plan?.duration]?.monthsCount || 0);
    return promo_code?.value
      ? bigDecimal.subtract(amount.toString(), ((amount * promo_code.value) / 100).toString())
      : amount;
  }, [purchaseAddons, billingDetail]);

  const getServices = async () => {
    const { page, itemsPerPage, showCancelled } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    try {
      const params = {
        page,
        itemsPerPage,
        [sortField]: sort.sortBy,
        tab: showCancelled ? 'cancelled' : undefined,
      };

      dispatch(getAdditionalServices(params));
    } catch (e) {
      // Do nothing
    }
  };

  const cancelDowngrade = async (addon_plan_id) => {
    try {
      await cancelAddonDowngrade({ customer_addon_plan_id: addon_plan_id });
      showToaster({ type: 'success', message: 'Addon license downgrade has been successfully canceled!' });
      onPurchaseSuccess();
      getServices();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getTotalQuantity = (planId) => {
    return data.reduce((acc, cur) => {
      if (cur.addon_plan_id === planId) {
        acc += cur.license_cnt;
      }
      return acc;
    }, 0);
  };

  const getPurchaseAddons = async () => {
    GetPurchaseAddonList().then((res) => {
      const addonId = searchParams.get('addonId');
      const planId = searchParams.get('planId');
      const data = res?.data;
      setPurchaseAddons(data);
      if (addonId && planId) {
        const addon = data?.find((item) => item.id === Number(addonId));
        const plan = addon?.plans?.find((item) => item.id === Number(planId));
        if (addon && plan) {
          setPurchaseAddOnsModal(true);
          setActivePurchase({ ...addon, activePlan: plan });
        }
        navigate('.');
      }
      if (openAddon) {
        setPurchaseAddOnsModal(true);
        navigate('.', { replace: true });
      }
    });
  };

  const handleUpgradeDowngrade = (rowData) => {
    const addon = purchaseAddons?.find((i) => i.id === rowData?.addon_plan?.addon_id);
    setActivePurchase({ ...addon, activePlan: rowData.addon_plan });
  };

  useEffect(() => {
    getPurchaseAddons().then();
  }, [openAddon]);

  useEffect(() => {
    getServices().then();
  }, [selectedFilters, sort]);

  useEffect(() => {
    const data = additionalServicesList?.data ? additionalServicesList.data.map((o) => ({ ...o })) : [];
    const brokerageIndex = data.findIndex((item) => item.addon_plan?.addon?.plan_id === ADDON_PLAN_ID.BROKERAGE);
    if (
      accountStatus?.account_status === 'intrial' &&
      value?.user?.customer?.customer_type === 'carrier_broker' &&
      customer_plan &&
      brokerageAmount &&
      brokerageIndex === -1
    ) {
      data.unshift({
        ...brokerageAddon,
        created_at: customer_plan?.created_at,
        next_renewal_date: customer_plan?.next_renewal_date,
        amount: brokerageAmount,
        duration: customer_plan?.plan?.duration,
      });
    }
    setData(data);
  }, [additionalServicesList?.data, brokerageAmount, customer_plan]);

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
  };

  const PaginationComponent = (event) => (
    <TableFooter
      rowPerPage={selectedFilters?.itemsPerPage}
      totalCount={additionalServicesList?.total}
      totalLength={additionalServicesList?.data.length}
      lastPage={additionalServicesList?.last_page}
      currentPage={additionalServicesList?.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onClosePurchaseAddons = () => {
    setPurchaseAddOnsModal(false);
  };

  useEffect(() => {
    const refs = {};
    additionalServicesList?.data?.forEach((item) => {
      refs[item.id] = createRef();
    });
    elementsRef.current = refs;
  }, [additionalServicesList]);

  const columns = useColumns({
    sort,
    sortingQuery,
    elementsRef,
    showTool,
    setShowTool,
    setManage,
    setCancel,
    cancelDowngrade,
    handleUpgradeDowngrade,
  });

  return (
    <>
      <div>
        <AdditionalServicesHeader
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setPurchaseAddOnsModal={setPurchaseAddOnsModal}
          totalRecurring={additionalServicesList?.addonRecurringTotal}
          brokerageAmount={
            accountStatus?.account_status === 'intrial' && value?.user?.customer?.customer_type === 'carrier_broker'
              ? brokerageAmount
              : null
          }
        />

        <div style={{ display: additionalServicesListStatus === 'LOADING' ? 'block' : 'none' }}>
          <Preloader />
        </div>
        <div style={{ display: additionalServicesListStatus !== 'LOADING' ? 'block' : 'none' }}>
          <MaterialTableWrapper
            data={data}
            rowPerPage={selectedFilters?.itemsPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
          />
        </div>
      </div>
      <CustomModal
        showModal={purchaseAddOnsModal}
        styleBody={{ width: '80vw', maxWidth: '1300px', minWidth: '800px', backgroundColor: '#FFFFFF!important' }}
        onHide={onClosePurchaseAddons}
        headerTitle='Purchase Add-Ons'
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <PurchaseAddOnsModal
          purchaseAddons={purchaseAddons}
          getPurchaseAddons={getPurchaseAddons}
          onClosePurchaseAddons={onClosePurchaseAddons}
          onPurchase={(i) => setActivePurchase(i)}
          purchasedAddons={data}
        />
      </CustomModal>
      <PurchaseAddOnsModalFinal
        data={activePurchase}
        show={Object.keys(activePurchase).length > 0}
        onClose={() => setActivePurchase({})}
        onSuccess={() => {
          getServices();
          onPurchaseSuccess();
          setActivePurchase({});
          setPurchaseAddOnsModal(false);
        }}
      />
      {!!cancel?.visible && (
        <AddonsCancelModal
          show={cancel?.visible}
          data={cancel || {}}
          getServices={getServices}
          onClose={() => setCancel({ visible: false })}
        />
      )}
      {!!manage?.visible && (
        <AddonsManageModal
          show={manage?.visible}
          data={manage || {}}
          quantity={getTotalQuantity(manage.addon_plan_id)}
          onClose={() => setManage({ visible: false })}
        />
      )}
    </>
  );
};
export default AdditionalServices;
