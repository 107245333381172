import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

const Label = ({ children }) => {
  const { use } = useTheme();

  return (
    <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200) }}>
      {children}
    </Typography>
  );
};

export default Label;
