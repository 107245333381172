import React, { useEffect } from 'react';
import styles from './PopoverSettings.module.css';

const TableColumn = ({ localState, setLocalState, onChangeOrderLocal, localDragItem, tableColumn }) => {
  const onClickHandler = (item) => {
    const localColumn = [...localState];
    const mapped = localColumn.map((el) => {
      if (item.value === el.value) {
        return {
          ...el,
          checked: !el.checked,
        };
      }
      return el;
    });
    if (item.checked) {
      const filteredDragItem = localDragItem.filter((el) => item.value !== el.value);
      onChangeOrderLocal(filteredDragItem);
    } else {
      const order = localDragItem.reduce((acc, el) => {
        return +acc > +el.order ? +acc : +el.order;
      }, 0);

      const addDragItem = { title: item.title, value: item.value, order: order + 1, id: order + 1 };
      onChangeOrderLocal([...localDragItem, addDragItem]);
    }

    setLocalState(mapped);
  };

  useEffect(() => {
    setLocalState(tableColumn);
  }, [tableColumn]);

  return (
    <>
      <span className={styles.table_column_title}>Table Column</span>
      <div className={`${styles.tableColumn}`}>
        {localState?.map((item) => {
          return (
            <div
              key={item.title}
              onClick={() => onClickHandler(item)}
              className={`${styles.tableColumn_button} 
                                        ${item.checked ? '' : styles.active}`}
            >
              <span
                className={`${styles.tableColumn_text}
                                            ${item.checked ? '' : styles.active}`}
              >
                {item.title}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TableColumn;
