import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import useShowToaster from 'hooks/useShowToaster';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import { getHookToTrailers, updateEquipment } from 'Api/Equipment';
import { validationSchema } from './validationSchema';

const HookTrailer = ({ open, onClose, vehicleId, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [loadingTrailers, setLoadingTrailers] = useState(false);
  const [trailers, setTrailers] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = { hooked_to_id: values.hooked_to.id };
      const { data } = await updateEquipment(vehicleId, body);
      showToaster({ type: 'success', message: 'Trailer hooked successfully!' });
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { hooked_to: null },
    onSubmit,
    validationSchema,
  });

  const getTrailers = async () => {
    try {
      setLoadingTrailers(true);
      const { data } = await getHookToTrailers(vehicleId);
      setTrailers(data.filter((i) => i?.equipment_status?.equipment_status === 'Not Assigned'));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingTrailers(false);
    }
  };

  useEffect(() => {
    getTrailers();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Hook Trailer'
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Hook Trailer',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='mt-3 mb-3'>
        <Autocomplete
          required
          label='Trailer'
          name='hooked_to'
          placeholder='Select Trailer..'
          options={trailers}
          value={values.hooked_to}
          onChange={(e, val) => handleChange('hooked_to', val)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          error={touchedErrors.hooked_to}
          loading={loadingTrailers}
          getOptionLabel={(option) =>
            `${option.equipment_id} ( ${option.make ? option.make : ''} ${option.model_id ? option.model_id : ''} )`
          }
        />
      </div>
    </Modal>
  );
};

export default HookTrailer;
