import React from 'react';
import truckinLogo from 'assets/icons/logo/truckinUserPlan.png';
import Input from 'common/Input';
import Divider from 'common/Divider';
import CountInput from 'common/CountInput';
import { Typography } from 'components/Typography';
import { planType } from 'components/customerOnboardingComponents/data';
import { useAuth } from 'context/auth.context';
import { ADDON_PLAN_ID, formatNumber, palette } from 'utils/constants';
import styles from 'components/Billing/UpgradeModal/UpgradeModal.module.css';
import Addon from './Addon';
import Hardware from './Hardware';
import { SOrderSummary } from './OrderSummary.styles';

const OrderSummary = ({
  values,
  handleChange,
  onApplyCoupon,
  showCouponErr,
  selectedPlanType,
  selectedHardware,
  selectedAddons,
  onChangeAddonQuantity,
  onChangeHardwareQuantity,
  onLicenseCountChange,
  onDeleteAddon,
  onDeleteHardware,
  paymentMethod,
  prices,
  numberOfUser,
}) => {
  const { value: userData } = useAuth();
  const { duration, amount } = selectedPlanType || {};
  const { hardware_price, addon_prices, plan_price, promo_code } = prices || {};
  const totalPlanAndAddons = (plan_price?.total_amount || 0) + (addon_prices?.total_amount || 0);
  const totalPlanAndAddonsDiscount = (plan_price?.total_discount || 0) + (addon_prices?.total_discount || 0);
  const processingFee = !Number(totalPlanAndAddons) ? 0 : Number(totalPlanAndAddons || 0) * 0.0299 + 0.3;
  const isBroker = userData?.user?.customer?.customer_type === 'carrier_broker';

  return (
    <SOrderSummary>
      <div className='account-summary-order'>
        <div className='account-summary-plan'>
          <div className='summary-item summary-item-license'>
            <div className='summary-item-image'>
              <img src={truckinLogo} alt='img' width={60} height={64} />
            </div>
            <div className='summary-item-body'>
              <div className='d-flex flex-column'>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  Month to Month User License Plan
                </Typography>
                <Typography variant='c1' style={{ color: palette.indigo500 }}>
                  ${duration === 'monthly' && `${amount}.00 /month /per user`}
                  {duration === 'yearly' &&
                    `${amount / planType.yearly.monthsCount} /per month /per user for 1 year license `}
                  {duration === '3-Year' &&
                    `${amount / planType['3-Year'].monthsCount} /per month /per user for 36 months license `}
                </Typography>
              </div>
            </div>
            <div className='summary-item-amount'>
              <CountInput value={numberOfUser} handleChange={(val) => onLicenseCountChange(val)} minValue={1} />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                ${duration === 'monthly' ? formatNumber(amount * numberOfUser) : formatNumber(amount * numberOfUser)}
              </Typography>
            </div>
          </div>
          <Divider margin='0' />
          {selectedHardware?.map((hardware) => (
            <div key={hardware.id}>
              <Hardware
                prices={prices}
                hardware={hardware}
                onDelete={onDeleteHardware}
                onQuantityChange={onChangeHardwareQuantity}
              />
              <Divider margin='0' />
            </div>
          ))}
          {selectedAddons?.map((addon) => (
            <div key={addon.id}>
              <Addon
                addon={addon}
                prices={prices}
                onDelete={onDeleteAddon}
                selectedPlanType={selectedPlanType}
                onQuantityChange={onChangeAddonQuantity}
                readOnly={isBroker && addon?.plan_id === ADDON_PLAN_ID.BROKERAGE}
              />
              <Divider margin='0' />
            </div>
          ))}
          <div className='d-flex flex-column align-items-end p-2'>
            <div className={styles.coupon_code}>
              {prices?.promo_code ? (
                <>
                  <div>
                    Promo Code "{prices.promo_code?.code}"{' '}
                    {prices.promo_code?.type === 'percentage'
                      ? `-${prices.promo_code?.value}%`
                      : prices.promo_code?.type === 'amount'
                      ? `-$${prices.promo_code?.value}`
                      : ''}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant='button1'
                      style={{ color: palette.red500, cursor: 'pointer' }}
                      onClick={() => onApplyCoupon(true)}
                    >
                      Cancel
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Input
                      name='coupon_code'
                      placeholder='Promo Code'
                      labelStyle={{ margin: 0 }}
                      style={{ width: 246, height: 32, boxShadow: 'none', padding: 0 }}
                      value={values.coupon_code}
                      onChange={(e) => handleChange('coupon_code', e.target.value)}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant='button1'
                      style={{ color: palette.indigo500, cursor: 'pointer' }}
                      onClick={() => onApplyCoupon(false)}
                    >
                      Apply
                    </Typography>
                  </div>
                </>
              )}
            </div>
            {showCouponErr && (
              <Typography variant='c2' style={{ color: palette.red500 }}>
                Please enter valid promo code!
              </Typography>
            )}
          </div>
          <Divider margin='0' />
          <div className='d-flex flex-column gap-2 mb-2 pt-2 pb-2'>
            {totalPlanAndAddonsDiscount > 0 && promo_code && (
              <div className='totals'>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Discounts Promo Code: "{promo_code?.code}"{' '}
                  {promo_code?.type === 'percentage'
                    ? `-${promo_code?.value}%`
                    : promo_code?.type === 'amount'
                    ? `-$${promo_code?.value}`
                    : ''}
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  -${formatNumber(totalPlanAndAddonsDiscount)}
                </Typography>
              </div>
            )}
            <div className='totals'>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {planType[selectedPlanType.duration]?.recurring2}
              </Typography>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                ${formatNumber(plan_price?.total_amount || 0)}
              </Typography>
            </div>
            {!!addon_prices?.total_setup_fees && (
              <div className='totals'>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  One Time Setup Fee
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  ${formatNumber(addon_prices?.total_setup_fees || 0)}
                </Typography>
              </div>
            )}
            <div className='totals'>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {planType[selectedPlanType.duration]?.recurringAddon}
              </Typography>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                ${formatNumber((addon_prices?.total_amount || 0) - (addon_prices?.total_setup_fees || 0))}
              </Typography>
            </div>
            {paymentMethod === 'credit-card' && (
              <div className='totals'>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  Processing Fee (2.9% + $0.30)
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  ${formatNumber(processingFee)}
                </Typography>
              </div>
            )}
            <div className='totals'>
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                Plan & Addons - Due Now
              </Typography>
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                ${formatNumber(totalPlanAndAddons + (paymentMethod === 'credit-card' ? processingFee : 0))}
              </Typography>
            </div>
          </div>
          {!!selectedHardware?.length && (
            <>
              <Divider margin='0' />
              <div className='d-flex flex-column gap-2 mb-2 mt-2'>
                <div className='totals'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Hardware Total
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    ${formatNumber(hardware_price?.hardware_total)}
                  </Typography>
                </div>
                <div className='totals'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Discounts Promo Code: "{promo_code?.code}"{' '}
                    {promo_code?.type === 'percentage'
                      ? `-${promo_code?.value}%`
                      : promo_code?.type === 'amount'
                      ? `-$${promo_code?.value}`
                      : ''}
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    -${formatNumber(hardware_price?.total_discount)}
                  </Typography>
                </div>
                <div className='totals'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Shipping + Handling
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    ${formatNumber(hardware_price?.total_shipping)}
                  </Typography>
                </div>
                <div className='totals'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Sales Tax
                  </Typography>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    ${formatNumber(hardware_price?.tax)}
                  </Typography>
                </div>
                <div className='totals'>
                  <Typography variant='h5' style={{ color: palette.gray900 }}>
                    Hardware - Due Now
                  </Typography>
                  <Typography variant='h5' style={{ color: palette.gray900 }}>
                    ${formatNumber(Number(hardware_price?.total_amount || 0))}
                  </Typography>
                </div>
              </div>
              <p className='totals email-agrement' style={{ paddingBottom: '10px' }}>
                *Hardware License Order Form Agreement and Invoice will be emailed to you separately. You will not be
                charged upon continue.
              </p>
            </>
          )}
        </div>
      </div>
    </SOrderSummary>
  );
};

export default OrderSummary;
