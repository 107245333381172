import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'common/Modal';
import Input, { InputWithIcon, InputWithIconAndText } from 'common/Input';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, getAppUrl } from 'utils/helpers';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';
import { getAccountsList } from 'Api/Accounts';
import {
  getBrokerageSetting,
  GetChargeTypes,
  GetCompanyProfile,
  getFundsTransferInstructions,
  getFundsTransferInstructionsBrokerage,
  GetSettingsAccounting,
} from 'Api/CompanySettings';
import { markInvoicePaid, updateInvoicePdf } from 'Api/AccountingReceivables';
import { SAmountWrapper, STable } from 'pages/Accounting/Receivables/Receivables.styles';
import { generateShipmentInvoice } from '../GenerateShipmentInvoice';
import { urlToBlob } from '../SendInvoice/SendInvoice.data';
import Row from './Row';
import ConfirmOverpayment from './ConfirmOverpayment';
import { markPaidConverter } from './converters';
import { validationSchema } from './validationSchema';
import { getInitialValues, paymentMethods, getSelectedMethods } from './MarkPaid.data';

const MarkPaid = ({ open, onClose, onSuccess, invoices }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;
  const url = getAppUrl();
  const showToaster = useShowToaster();
  const hasBrokerage = useHasBrokerage();
  const { formatDate, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [overpaidInvoices, setOverpaidInvoices] = useState([]);
  const [openOverpayConfirm, setOpenOverpayConfirm] = useState(false);
  const [chargeTypes, setChargeTypes] = useState(null);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [accountingSettings, setAccountingSettings] = useState(null);
  const [instructions, setInstructions] = useState(null);
  const [brokerageSettings, setBrokerageSettings] = useState({});
  const [instructionsBrokerage, setInstructionsBrokerage] = useState({});

  const onSubmit = async (values, confirmed) => {
    setLoading(true);
    try {
      const { body, overpaid } = markPaidConverter(values, invoices);

      if (overpaid.length && !confirmed) {
        setOverpaidInvoices(overpaid);
        setOpenOverpayConfirm(true);
        return;
      }

      setOpenOverpayConfirm(false);
      setOverpaidInvoices([]);

      const updateInvoicePromises = [];
      const invoicesWithDiscount = values.filter((invoice) => !!Number(invoice.discount));

      values.forEach((invoice, index) => {
        if (invoice.discount) {
          let stripePaymentLink;

          const paymentMethods = getSelectedMethods(Number(invoices[index].payment_method));
          const send_email = ['2', '6', '10', '14'].includes(invoices[index].delivery_option);

          if ((paymentMethods || []).includes('online')) {
            stripePaymentLink = `${url}/${dot}/p/${invoices[index].batch?.batch_id || invoice.invoice_id}/${
              invoices[index].batch?.batch_id ? '1' : '0'
            }`;
          }

          updateInvoicePromises.push(
            generateShipmentInvoice({
              companyProfile:
                invoices[index].payable_to === 'brokerage' && !!hasBrokerage
                  ? {
                      ...companyProfile,
                      company_name: brokerageSettings.company_name,
                      company_logo: brokerageSettings.logo,
                      phone_number: brokerageSettings.phone_number,
                      address1: brokerageSettings.address1,
                      address2: brokerageSettings.address2,
                      city_id: brokerageSettings.city,
                      state_id: brokerageSettings.state,
                      country_id: brokerageSettings.country,
                      zip: brokerageSettings.zipcode,
                    }
                  : companyProfile,
              chargeTypes,
              shipmentData: {
                ...invoices[index].billing,
                billing_charges: [
                  ...invoices[index].billing.billing_charges,
                  {
                    charge_type_name: { title: 'Discount' },
                    quantity: 1,
                    rate: -Number(invoice.discount),
                    sub_total: -Number(invoice.discount),
                  },
                ],
              },
              accountingSettings:
                invoices[index].payable_to === 'brokerage' && !!hasBrokerage
                  ? {
                      ...accountingSettings,
                      show_billing: true,
                      website: brokerageSettings.website,
                      show_website: brokerageSettings.show_website,
                      receivable_email: brokerageSettings.receivable_email,
                    }
                  : accountingSettings,
              instructions:
                invoices[index].payable_to === 'brokerage' && !!hasBrokerage ? instructionsBrokerage : instructions,
              billDate: invoices[index].customer_billed_date,
              dueDate: invoices[index].due_date,
              payable_to: invoices[index].payable_to,
              email_to: invoices[index].email_to,
              send_email,
              payment_method: paymentMethods,
              stripePaymentLink,
              currency,
              formatDate,
              formatDateTime,
              existingInvoiceId: invoice.invoice_id,
            })
          );
        }
      });

      if (invoicesWithDiscount.length) {
        const newInvoicePdfUrls = await Promise.all(updateInvoicePromises);
        const promises = newInvoicePdfUrls.map((el) => urlToBlob(el));
        const newInvoicePdfFiles = await Promise.all(promises);
        const formData = new FormData();
        invoicesWithDiscount.forEach((invoice, index) => {
          formData.append(`invoices[${index}][invoice_id]`, invoice.invoice_id);
          formData.append(`invoices[${index}][pdf_file]`, newInvoicePdfFiles[index]);
        });

        await updateInvoicePdf(formData);
      }

      await markInvoicePaid(body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, touchedErrors } = useForm({
    initialValues: getInitialValues(invoices),
    onSubmit,
    validationSchema,
  });

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(
        data.filter(
          (i) => [ACCOUNT_TYPE.BANK, ACCOUNT_TYPE.CREDIT_CARD].includes(i.account_type.id) && i.account_status === 1
        )
      );
    } catch (e) {
      // Do nothing
    }
  };

  const totalCharges = useMemo(() => {
    return invoices.reduce((acc, cur) => acc + Number(cur.billing?.open_balance) || Number(cur.open_balance) || 0, 0);
  }, [invoices]);
  const totalPaid = useMemo(() => {
    return values.reduce((acc, cur) => acc + (Number(cur.amount_paid) || 0), 0);
  }, [values]);
  const totalDiscount = useMemo(() => {
    return values.reduce((acc, cur) => acc + parseFloat(cur.discount || 0), 0);
  }, [values]);
  const totalCreditsUsed = useMemo(() => {
    return values.reduce((acc, cur) => {
      return acc + (cur.apply_credit ? Number(cur.credit_used || 0) : 0);
    }, 0);
  }, [values]);

  const onOverpayConfirmClose = () => {
    setOpenOverpayConfirm(false);
    setOverpaidInvoices([]);
  };

  const getCompanyProfile = async () => {
    try {
      const company = await GetCompanyProfile();
      setCompanyProfile(company);
    } catch (e) {
      // Do nothing
    }
  };

  const getBrokerSettings = async () => {
    try {
      const { data } = await getBrokerageSetting();
      setBrokerageSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getChargeTypes = async () => {
    try {
      const { data } = await GetChargeTypes();
      setChargeTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getAccountingSettings = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountingSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getInstructions = async () => {
    try {
      const { data } = await getFundsTransferInstructions();
      setInstructions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getInstructionsBrokerage = async () => {
    try {
      const { data } = await getFundsTransferInstructionsBrokerage();
      setInstructionsBrokerage(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getAccounts();
    getCompanyProfile();
    getChargeTypes();
    getAccountingSettings();
    getInstructions();

    if (hasBrokerage) {
      getBrokerSettings();
      getInstructionsBrokerage();
    }
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Mark Paid'
      $bgColor={palette.white}
      $width={invoices.length > 1 ? '2100px' : '800px'}
      $maxWidth='98vw'
      $minWidth='800px'
      className='modified-scrollbar'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Mark Paid',
          disabled: loading || values.some((el) => el.amount_paid < 0),
          onClick: handleSubmit,
        },
      ]}
    >
      <div>
        {invoices.length > 1 ? (
          <STable>
            <thead>
              <tr>
                <th>BILL TO</th>
                <th>METHOD</th>
                <th>DUE DATE</th>
                <th>PAID DATE</th>
                <th>INVOICE ID</th>
                <th>REFERENCE ID</th>
                <th>PAY REFERENCE</th>
                <th>DEPOSIT TO ACCOUNT</th>
                <th>DISCOUNT</th>
                <th>OPEN BALANCE</th>
                <th>APPLY CREDITS</th>
                <th>AMOUNT PAID</th>
              </tr>
            </thead>
            <tbody>
              {values.map((invoice, index) => (
                <Row
                  key={invoice.id}
                  invoice={invoice}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touchedErrors={touchedErrors}
                  accounts={accounts}
                  values={values}
                  index={index}
                />
              ))}
              <tr className='no-border'>
                <td colSpan={7} />
                <td>
                  <Typography variant='s2' style={{ color: palette.gray900 }}>
                    Total
                  </Typography>
                </td>
                <td>
                  <Typography variant='button2' style={{ color: palette.gray900 }}>
                    {currency}
                    {formatNumber(totalDiscount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='button2' style={{ color: palette.gray900 }}>
                    {currency}
                    {formatNumber(totalCharges - totalDiscount)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='button2' style={{ color: palette.gray900, whiteSpace: 'nowrap' }}>
                    {currency}
                    {formatNumber(totalCreditsUsed)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='button2' style={{ color: palette.gray900, whiteSpace: 'nowrap' }}>
                    {currency}
                    {formatNumber(totalPaid + totalCreditsUsed)}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </STable>
        ) : (
          <div className='d-flex w-100'>
            <div className='d-flex flex-column w-50 gap-3'>
              <div className='d-flex flex-column gap-1'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Bill To
                </Typography>
                <Typography variant='s3' style={{ color: palette.gray700 }}>
                  {values[0].customer?.company_name || '-'}
                </Typography>
              </div>
              <div className='d-flex flex-column gap-1'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Reference ID
                </Typography>
                <Typography variant='s3' style={{ color: palette.gray700 }}>
                  {values[0].reference_id || '-'}
                </Typography>
              </div>
              <div>
                <Autocomplete
                  width='150px'
                  label='Payment Method'
                  required
                  size='small'
                  name='payment_method'
                  options={paymentMethods}
                  value={values[0].payment_method}
                  onChange={(e, val) => handleChange('0.payment_method', val)}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  error={touchedErrors[0]?.payment_method}
                />
              </div>
              <div>
                <InputLabel required>Pay Reference</InputLabel>
                <Input
                  width='200px'
                  size='small'
                  name='pay_reference'
                  placeholder='ABC'
                  value={values[0].pay_reference}
                  onChange={(e) => handleChange('0.pay_reference', e.target.value)}
                  error={touchedErrors[0]?.pay_reference}
                />
              </div>
              <div className='d-flex flex-column gap-1'>
                <div>
                  <InputLabel required>Discount</InputLabel>
                  <SAmountWrapper>
                    <div>
                      <Autocomplete
                        width='40px'
                        size='small'
                        height='26px'
                        labelKey='label'
                        options={[
                          { label: '%', value: 'percent' },
                          { label: currency, value: 'amount' },
                        ]}
                        value={values[0].discount_type}
                        onChange={(e, val) => handleChange('0.discount_type', val)}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                      />
                    </div>
                    {values[0].discount_type?.value === 'percent' ? (
                      <Input
                        width='90px'
                        type='number'
                        name='discount_percent'
                        placeholder='0.00'
                        className='amount-input'
                        value={values[0].discount_percent}
                        onChange={(e) => {
                          handleChange(`0.discount_percent`, e.target.value);
                          handleChange(
                            `0.discount`,
                            Number((values[0].open_balance * Number(e.target.value)) / 100).toFixed(2)
                          );
                        }}
                        onKeyDown={blockNonPositiveNumbers}
                      />
                    ) : (
                      <Input
                        width='90px'
                        type='number'
                        name='discount'
                        placeholder='0.00'
                        className='amount-input'
                        value={values[0].discount}
                        onChange={(e) => {
                          handleChange(`0.discount`, e.target.value);
                          handleChange(
                            `0.discount_percent`,
                            Number((Number(e.target.value) * 100) / values[0].open_balance).toFixed(2)
                          );
                        }}
                        onKeyDown={blockNonPositiveNumbers}
                      />
                    )}
                  </SAmountWrapper>
                </div>
                <ErrorMessage error={touchedErrors[0]?.discount} />
              </div>
              <div>
                <InputLabel>Apply Credits</InputLabel>
                {values[0].credits > 0 &&
                !values.some(
                  (item) => item.id !== values[0].id && item.apply_credit && item.customer.id === values[0].customer.id
                ) ? (
                  <div className='d-flex align-items-center gap-2'>
                    <CustomCheckbox
                      checked={values[0].apply_credit}
                      onChange={(checked) => handleChange(`0.apply_credit`, checked)}
                    />
                    <InputWithIconAndText
                      width='100px'
                      size='small'
                      bgColor={palette.gray0}
                      type='number'
                      name='credit_used'
                      text={`out of ${currency}${formatNumber(values[0].credits)}`}
                      placeholder='0.00'
                      value={values[0].credit_used}
                      onChange={(e) => handleChange(`0.credit_used`, e.target.value)}
                      onKeyDown={blockNonPositiveNumbers}
                      error={touchedErrors[0]?.credit_used}
                      disabled={!values[0].apply_credit}
                    />
                  </div>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className='d-flex flex-column w-50 gap-3'>
              <div className='d-flex flex-column gap-1'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Invoice ID
                </Typography>
                <Typography variant='s3' style={{ color: palette.gray700 }}>
                  {values[0].invoice_id || '-'}
                </Typography>
              </div>
              <div className='d-flex flex-column gap-1'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Due Date
                </Typography>
                <Typography variant='s3' style={{ color: palette.gray700 }}>
                  {formatDate(values[0].due_date)}
                </Typography>
              </div>
              <div>
                <DatePicker
                  size='small'
                  width='150px'
                  label='Paid Date'
                  onBlur={handleBlur}
                  name='0.payment_date'
                  value={values[0].payment_date}
                  onChange={(val) => handleChange('0.payment_date', val)}
                  error={touchedErrors?.[0]?.payment_date}
                />
              </div>
              <div>
                <Autocomplete
                  size='small'
                  width='200px'
                  name='account'
                  labelKey='account_name'
                  label='Deposit to Account'
                  options={accounts}
                  value={values[0].account}
                  onChange={(e, val) => handleChange('0.account', val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  error={touchedErrors[0]?.account}
                />
              </div>
              <div className='d-flex flex-column gap-2'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Open Balance
                </Typography>
                <Typography variant='s3' style={{ color: palette.gray700 }}>
                  {currency}
                  {formatNumber(Number(values[0].open_balance) - Number(values[0].discount)) || '-'}
                </Typography>
              </div>
              <div>
                <InputLabel required>Amount Paid</InputLabel>
                <InputWithIcon
                  width='120px'
                  size='small'
                  type='number'
                  name='amount_paid'
                  placeholder='0.00'
                  value={values[0].amount_paid}
                  onChange={(e) => handleChange(`0.amount_paid`, e.target.value)}
                  onKeyDown={blockNonPositiveNumbers}
                  error={touchedErrors[0]?.amount_paid}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {openOverpayConfirm && (
        <ConfirmOverpayment
          open={openOverpayConfirm}
          onClose={onOverpayConfirmClose}
          invoices={overpaidInvoices}
          onSubmit={() => onSubmit(values, true)}
        />
      )}
    </Modal>
  );
};

export default MarkPaid;
