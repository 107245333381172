import axios from 'services/axios';

// Bills
export async function getBills(params) {
  const { data } = await axios.get('account/bills', { params });
  return data;
}

export async function getBill(id) {
  const { data } = await axios.get(`account/bills/${id}`);
  return data;
}

export async function createBill(body) {
  const { data } = await axios.post('account/bills', body);
  return data;
}

export async function updateBill(id, body) {
  const { data } = await axios.post(`account/bills/${id}`, body, { params: { _method: 'PUT' } });
  return data;
}

export async function deleteBill(id) {
  const { data } = await axios.delete(`account/bills/${id}`);
  return data;
}

export async function updateBillStatus(body) {
  const { data } = await axios.post('account/bills/update-status', body);
  return data;
}

export async function revertBillToUnpaid(id) {
  const { data } = await axios.get(`account/bills/revert-status/${id}`);
  return data;
}

export async function exportBillsCsv(params) {
  const { data } = await axios.get(`accounts/bills/export-csv`, { params });
  return data;
}

// Recurring Bills
export async function getRecurringBills(params) {
  const { data } = await axios.get('account/recurring-bills', { params });
  return data;
}

export async function getVendorRecurringBills(params) {
  const { data } = await axios.get('account/recurring-bill-vendors', { params });
  return data;
}

export async function getRecurringBill(id) {
  const { data } = await axios.get(`account/recurring-bill/${id}`);
  return data;
}

export async function updateRecurringBill(id, body) {
  const { data } = await axios.post(`account/recurring-bills/${id}`, body, { params: { _method: 'PUT' } });
  return data;
}

export async function deleteRecurringBill(id) {
  const { data } = await axios.delete(`account/recurring-bills/${id}`);
  return data;
}

export async function getPayableStats(params) {
  const { data } = await axios.get(`accounts-payable/get-stats-graph`, { params });
  return data;
}

export async function getAccountCosts(id, params) {
  const { data } = await axios.get(`accounts-payable/costs/${id}`, { params });
  return data;
}

export async function getUpcomingBills(params) {
  const { data } = await axios.get(`accounts-payable/get-upcoming-bills`, { params });
  return data;
}

export async function getPastDueBills(params) {
  const { data } = await axios.get(`accounts-payable/get-past-due-bills`, { params });
  return data;
}

export async function getRevenues(params) {
  const { data } = await axios.get(`accounts-payable/get-revenue-list`, { params });
  return data;
}

export async function getAccountCounts() {
  const { data } = await axios.get(`/account/count`);
  return data;
}
