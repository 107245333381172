import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CheckBoxLight } from 'assets/icons/checkBoxLight.svg';
import { ReactComponent as CheckBoxDark } from 'assets/icons/checkBoxDark.svg';
import { palette } from 'utils/constants';
import styles from './CustomCheckbox.module.css';
import { Typography } from '../Typography';

const CustomCheckbox = ({
  field,
  checked,
  defaultChecked,
  onChange,
  name,
  style,
  children,
  hovered,
  withinForm,
  type,
  labelStyle,
  checkmarkStyle,
  disabled,
  smail = true,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [defaultCheck, setDefaultCheck] = useState(!!defaultChecked);
  const [active, setActive] = useState(false);

  const onChangeHandler = useCallback(
    (event) => {
      event.stopPropagation();
      const isChecked = checked !== undefined ? !checked : event.target.checked;
      if (withinForm) {
        field.onChange(event);
        if (typeof onChange === 'function') {
          onChange(isChecked);
        }
        setDefaultCheck(!!isChecked);
      } else {
        if (typeof onChange === 'function') {
          setDefaultCheck(!!isChecked);
          onChange(isChecked);
        }
      }
    },
    [checked, onChange, field]
  );

  const onMouseOver = () => {
    if (checked || !!defaultCheck) {
      setActive(true);
      setIsFocused(false);
    } else {
      setActive(false);
      !hovered && setIsFocused(true);
    }
  };

  const onMouseLeave = () => {
    !hovered && setIsFocused(false);
    setActive(false);
  };

  const Icon = useMemo(() => {
    if (hovered !== undefined) {
      return hovered ? CheckBoxDark : CheckBoxLight;
    }
    return isFocused ? CheckBoxDark : CheckBoxLight;
  }, [hovered, isFocused]);
  return type !== 'button' && type !== 'switch' ? (
    <div style={{ ...style }} className={styles.checkboxWrapper}>
      <label className={styles.container} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} style={labelStyle}>
        <input
          {...field}
          name={name}
          type='checkbox'
          checked={checked}
          onChange={onChangeHandler}
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
        <Icon
          width={10}
          height={10}
          className={`${styles.checkmark}`}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            backgroundColor: active && checked ? palette.indigo600 : active && !checked && palette.white,
            boxShadow:
              active && checked
                ? `0 1px 1px rgba(0, 0, 0, 0.14),0 0 0 1px ${palette.indigo600},0 2px 5px rgba(79, 90, 237, 0.1),inset 0px 1px 0px ${palette.indigo600}`
                : active &&
                  !checked &&
                  `0 1px 1px rgba(0, 0, 0, 0.1),0 0 0 1px rgba(70, 79, 96, 0.16),0 2px 5px rgba(89, 96, 120, 0.1)`,
            ...checkmarkStyle,
          }}
        />
        {!!children && children}
      </label>
    </div>
  ) : type === 'button' ? (
    <div style={{ ...style }} className={styles.checkboxWrapper}>
      <label className={styles.container}>
        <input
          {...field}
          name={name}
          type='checkbox'
          checked={checked}
          onChange={onChangeHandler}
          defaultChecked={defaultChecked}
        />
        <div className={`${styles.type_button_wrapper} ${checked ? styles.indigo : ''}`}>
          <Typography variant='s2'>{!!children && children}</Typography>
        </div>
      </label>
    </div>
  ) : (
    <div style={{ ...style }}>
      <label className={styles.switch_label} style={labelStyle}>
        <input
          {...field}
          name={name}
          type='checkbox'
          checked={checked}
          onChange={onChangeHandler}
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
        <span
          className={classNames(styles.switch, { [styles.tick]: checked }, { [styles.smail]: !!smail })}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        />
      </label>
    </div>
  );
};

export default CustomCheckbox;
