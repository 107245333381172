import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { Typography } from 'components/Typography';
import styles from './CompanyResourceViewModal.module.css';

const CompanyResourceViewModal = ({ openViewModal, data, setOpenViewModal }) => {
  return (
    <Modal
      show={openViewModal}
      onHide={() => {
        setOpenViewModal({
          visible: false,
        });
      }}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
      centered
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Document</Typography>
        </div>
        <div className={styles.body}>
          <div className={styles.image_wrapper}>
            <figure className={styles.figure}>
              <iframe title='Document' className={styles.image} src={data?.document} />
            </figure>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompanyResourceViewModal;
