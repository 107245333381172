import { number, object, string } from 'yup';
import { validator } from 'utils/helpers';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = object().shape({
  fullName: string().trim().required('This field is required'),
  socialNumber: string()
    .trim()
    .required('This field is required')
    .test('a', 'Must be exactly 9 characters', (val) => val && val.toString().length === 9),
  phoneNumber: string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
  emergency_contact_number: string().matches(phoneRegExp, 'Phone number is not valid'),
  email: string().required('Email is required').email('Invalid Email Address'),
  birthDate: validator.pastRequiredDate('This field is required'),
  country: object()
    .shape({ id: number().required('Country is required') })
    .required('Country is required')
    .nullable(),
  state: object()
    .shape({ id: number().required('State is required') })
    .required('State is required')
    .nullable(),
  city: object()
    .shape({ id: number().required('City is required') })
    .required('City is required')
    .nullable(),
  address1: string().trim().required('Address 1 is required'),
  address2: string().trim().nullable(),
  zipcode: string().trim().required('Zip code is required'),
});
