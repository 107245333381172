import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './TrackingDetailModal.css';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import warn from 'assets/icons/drivers/warn.svg';
import Autocomplete from 'common/Autocomplete';
import { DateTimePicker } from 'common/Pickers';
import AddressFields from 'common/AddressFields';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getStaffUsers } from 'Api/chat';
import { getDrivers } from 'Api/EquipmentProfile';
import { validationSchema, validationSchemaTime } from './validationSchema';
import { SModal } from './TrackinDetailModal.styles';

export default function TrackingDetailsModal({
  trackingDetails,
  updateTimeTracking,
  onSuccess,
  loading,
  onHide,
  userType,
  hideUserSelect,
  ...props
}) {
  const { use } = useTheme();
  const [usersList, setUsersList] = useState([]);
  const [usersListLoading, setUsersListLoading] = useState(false);

  const saveTimeTracking = (values) => {
    const {
      start_time,
      end_time,
      end_time_city,
      end_time_country,
      end_time_state_code,
      start_time_state_code,
      start_time_country,
      start_time_city,
    } = values;
    let startTime = start_time;
    let endTime = end_time;
    if (startTime === 'Invalid date') {
      startTime = null;
    }
    if (endTime === 'Invalid date') {
      endTime = null;
    }
    const payload = {
      ...values,
      end_time_city: typeof end_time_city === 'object' ? end_time_city?.name : end_time_city,
      end_time_country: typeof end_time_country === 'object' ? end_time_country?.name : end_time_country,
      end_time_state_code: typeof end_time_state_code === 'object' ? end_time_state_code?.name : end_time_state_code,
      start_time: startTime,
      end_time: endTime,
      start_time_state_code:
        typeof start_time_state_code === 'object' ? start_time_state_code?.name : start_time_state_code,
      start_time_country: typeof start_time_country === 'object' ? start_time_country?.name : start_time_country,
      start_time_city: typeof start_time_city === 'object' ? start_time_city?.name : start_time_city,
    };
    updateTimeTracking(payload);
  };

  const { setFieldValue, values, touched, errors, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      user: null,
      timetracking_id: trackingDetails?.id,
      end_time: new Date(),
      end_time_city: null,
      end_time_country: null,
      end_time_state_code: null,
      end_time_street: '',
      end_time_zip: '',
      hours_tracked: '',
      minutes_tracked: '',
      start_time: new Date(),
      start_time_city: null,
      start_time_country: null,
      start_time_state_code: null,
      start_time_street: '',
      start_time_zip: '',
      start_location_lat: '',
      start_location_long: '',
      end_location_lat: '',
      end_location_long: '',
    },
    validationSchema: !trackingDetails?.id && !hideUserSelect ? validationSchema : validationSchemaTime,
    onSubmit: saveTimeTracking,
  });

  useEffect(() => {
    if (trackingDetails?.id) {
      setFieldValue('end_time', trackingDetails?.end_time ? new Date(trackingDetails.end_time) : '');
      // setFieldValue('end_time_city', trackingDetails?.end_time_city);
      // setFieldValue('end_time_country', trackingDetails?.end_time_country);
      // setFieldValue('end_time_state_code', trackingDetails?.end_time_state_code);
      setFieldValue('end_time_city', null);
      setFieldValue('end_time_country', null);
      setFieldValue('end_time_state_code', null);
      setFieldValue('end_time_street', trackingDetails?.end_time_street);
      setFieldValue('end_time_zip', trackingDetails?.end_time_zip);
      setFieldValue('hours_tracked', trackingDetails?.hours_tracked);
      setFieldValue('start_time', new Date(trackingDetails?.start_time));
      setFieldValue('minutes_tracked', trackingDetails?.minutes_tracked);

      // setFieldValue('start_time_city', trackingDetails?.start_time_city);
      // setFieldValue('start_time_country', trackingDetails?.start_time_country);
      // setFieldValue('start_time_state_code', trackingDetails?.start_time_state_code);
      setFieldValue('start_time_city', null);
      setFieldValue('start_time_country', null);
      setFieldValue('start_time_state_code', null);
      setFieldValue('start_time_street', trackingDetails?.start_time_street);
      setFieldValue('start_time_zip', trackingDetails?.start_time_zip);
      setFieldValue('timetracking_id', trackingDetails?.id);

      setFieldValue('start_location_lat', trackingDetails?.start_location_lat);
      setFieldValue('start_location_long', trackingDetails?.start_location_long);
      setFieldValue('end_location_lat', trackingDetails?.end_location_lat);
      setFieldValue('end_location_long', trackingDetails?.end_location_long);
    }
  }, [trackingDetails]);

  const getDriversList = async () => {
    try {
      setUsersListLoading(true);
      const { data } = await getDrivers({ driver_compensation: 'per_hour' });
      setUsersList(data.map((item) => ({ id: item.id, name: `${item.fname} ${item.lname}` })));
    } catch (e) {
      // Do nothing
    } finally {
      setUsersListLoading(false);
    }
  };

  const getStaffList = async () => {
    try {
      setUsersListLoading(true);
      const { data } = await getStaffUsers({ per_hour: 1 });
      setUsersList(
        data.map((item) => ({ id: item.staff.id, name: `${item.staff.first_name} ${item.staff.last_name}` }))
      );
    } catch (e) {
      // Do nothing
    } finally {
      setUsersListLoading(false);
    }
  };

  useEffect(() => {
    if (values?.start_location_lat && trackingDetails?.user_type === 2) {
      TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
      const map = new TrimbleMaps.Map({
        container: 'map',
        style: TrimbleMaps.Common.Style.TRANSPORTATION,
        zoom: 3,
        center: new TrimbleMaps.LngLat(values?.start_location_long, values?.start_location_lat),
      });
      const markers = [];

      // Create the popup
      let popup = new TrimbleMaps.Popup({
        offset: 24,
        isOpen: true,
      }).setText(values?.start_time_street);
      // Create a marker with SVG content
      let content =
        trackingDetails?.break === 0
          ? `
        <div class='map-point-label' style="background-color:${use('#E1FCEF', palette.darkgreen)}">
          <p>CLOCKED IN</p>
        </div>
      `
          : `
        <div class='map-point-label' style="background-color:${use('#FCF2E6', '#FCF2E6')}">
          <p style="color:${use('#AA5B00', '#AA5B00')}">BREAK START</p>
        </div>
      `;

      let svgContent = document.createElement('div');
      svgContent.innerHTML = content;
      let marker2 = new TrimbleMaps.Marker({
        element: svgContent,
      })
        .setLngLat([values?.start_location_long, values?.start_location_lat])
        .setPopup(popup)
        .addTo(map);
      markers.push(marker2);

      // // Create the popup
      if (values?.end_location_long && values?.start_location_lat && trackingDetails?.end_time) {
        popup = new TrimbleMaps.Popup({
          offset: 24,
        }).setText(values?.end_time_street);

        content =
          trackingDetails?.break === 0
            ? `
          <div class='map-point-label' style="background-color:#faf0f3">
            <p style="color:${use('rgb(220, 64, 103)', palette.red400)}">CLOCKED OUT</p>
          </div>
         `
            : `
          <div class='map-point-label' style="background-color:#AA5B00">
            <p style="color:${use('#FCF2E6', palette.red400)}">BREAK END</p>
          </div>
         `;

        svgContent = document.createElement('div');
        svgContent.innerHTML = content;
        marker2 = new TrimbleMaps.Marker({
          element: svgContent,
        })
          .setLngLat([values?.end_location_long, values?.end_location_lat])
          .setPopup(popup)
          .addTo(map);
        markers.push(marker2);
      }

      // Animmate + zoom on initial
      map.on('load', async () => {
        map.zoomTo(15, {
          animate: true,
          duration: 5000,
        });
        map.current?.addControl(new TrimbleMaps.FullscreenControl());
        map.current?.addControl(new TrimbleMaps.NavigationControl());
      });

      const routeId = 'myRoute';
      const myRouteElem = document.getElementById('myRoute');
      const myRoute = new TrimbleMaps.Route({
        routeId,
        isDraggable: false,
        showStops: false,
        stops: [
          new TrimbleMaps.LngLat(values?.start_location_long, values?.start_location_lat),
          new TrimbleMaps.LngLat(values?.end_location_long, values?.end_location_lat),
        ],
      });
      myRoute.on('stopInserting', (e) => {
        myRouteElem.innerHTML = `Mouse down on ${e.routeLegPositions.length} route leg(s)`;
        myRoute.getRouteWithNewStop(e.newStop, e.routeLegPositions[e.routeLegPositions.length - 1]);
      });
      map.on('load', () => {
        myRoute.addTo(map);
      });
    }
  }, [values.start_location_lat, values.start_location_long, values.end_location_lat, values.end_location_long]);

  const defaultStartAddress = useMemo(() => {
    return {
      state: trackingDetails?.start_time_state_code || '',
      city: trackingDetails?.start_time_city || '',
      country: trackingDetails?.start_time_country || '',
    };
  }, [trackingDetails]);

  const defaultEndAddress = useMemo(() => {
    return {
      state: trackingDetails?.end_time_state_code || '',
      city: trackingDetails?.end_time_city || '',
      country: trackingDetails?.end_time_country || '',
    };
  }, [trackingDetails]);

  useEffect(() => {
    if (!trackingDetails?.id && !hideUserSelect) {
      if (userType === 'staff') {
        getStaffList();
      } else {
        getDriversList();
      }
    }
  }, []);

  return (
    <SModal
      className={trackingDetails?.user_type === 2 ? '' : 'without-map'}
      dialogClassName='modal-90w'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      {...props}
    >
      <Modal.Header
        style={{
          backgroundColor: use(palette.whiteBackground, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.dark600),
        }}
      >
        <Modal.Title
          className='heading'
          id='contained-modal-title-vcenter'
          style={{ color: use(palette.gray900, palette.gray50) }}
        >
          {trackingDetails?.id ? 'Tracking Details' : 'Add Time'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='tracking-modal-body-box driver-style-wrap'
        style={{ backgroundColor: use(palette.whiteBackground, palette.dark800) }}
      >
        <div className='Tracking-wrapper'>
          <div className='Tracking-details'>
            {!trackingDetails?.id && !hideUserSelect && (
              <div className='mb-3'>
                <div>
                  <Autocomplete
                    required
                    label={userType === 'staff' ? 'Select Staff User' : 'Select Driver'}
                    width='250px'
                    name='user'
                    options={usersList}
                    loading={usersListLoading}
                    value={values.user}
                    onChange={(e, val) => setFieldValue('user', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    error={touched?.user && errors?.user}
                  />
                </div>
              </div>
            )}
            <div className='mb-3'>
              <p className='sub-heading' style={{ color: use(palette.gray900, palette.gray50) }}>
                Start Details
              </p>
              <DateTimePicker
                required
                label='Date/Time'
                name='start_time'
                value={values.start_time}
                onChange={(date) => setFieldValue('start_time', date)}
                onBlur={handleBlur}
                disableFuture
                maxDate={values?.end_time || new Date()}
                error={touched.start_time && errors.start_time}
              />
              <div className='mt-2' />
              <AddressFields
                required
                hideAddress2
                values={{
                  address: values.start_time_street,
                  country: values.start_time_country,
                  city: values.start_time_city,
                  state: values.start_time_state_code,
                  zipcode: values.start_time_zip,
                }}
                handleChange={(e, val) => {
                  const map = {
                    city: 'start_time_city',
                    state: 'start_time_state_code',
                    country: 'start_time_country',
                    address: 'start_time_street',
                    zipcode: 'start_time_zip',
                    lat: 'start_location_lat',
                    lng: 'start_location_long',
                  };
                  if (typeof e === 'string') {
                    setFieldValue(map[e], val);
                  } else if (e?.target.name === 'zipcode') {
                    const fieldName = e.target.name;
                    setFieldValue(map[fieldName], e.target.value);
                  } else {
                    const fieldName = e.target.name;
                    setFieldValue(map[fieldName], val);
                  }
                }}
                setLatLng
                defaultAddress={defaultStartAddress}
              />
            </div>
            <div>
              <p className='sub-heading' style={{ color: use(palette.gray900, palette.gray50) }}>
                End Details
              </p>
              <DateTimePicker
                required
                label='Date/Time'
                name='start_time'
                value={values.end_time}
                onChange={(date) => setFieldValue('end_time', date)}
                onBlur={handleBlur}
                disableFuture
                minDate={values?.start_time}
                error={touched.end_time && errors.end_time}
              />
              <div className='mt-2' />
              <AddressFields
                required
                hideAddress2
                values={{
                  address: values.end_time_street,
                  country: values.end_time_country,
                  city: values.end_time_city,
                  state: values.end_time_state_code,
                  zipcode: values.end_time_zip,
                }}
                handleChange={(e, val) => {
                  const map = {
                    city: 'end_time_city',
                    state: 'end_time_state_code',
                    country: 'end_time_country',
                    address: 'end_time_street',
                    zipcode: 'end_time_zip',
                    lat: 'end_location_lat',
                    lng: 'end_location_long',
                  };
                  if (typeof e === 'string') {
                    setFieldValue(map[e], val);
                  } else if (e?.target.name === 'zipcode') {
                    const fieldName = e.target.name;
                    setFieldValue(map[fieldName], e.target.value);
                  } else {
                    const fieldName = e.target.name;
                    setFieldValue(map[fieldName], val);
                  }
                }}
                setLatLng
                defaultAddress={defaultEndAddress}
              />
            </div>
          </div>
          {trackingDetails?.user_type === 2 && (
            <div className='Tracking-map-container'>
              <div className='tracking-history-right-section'>
                <div id='map' />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.whiteBackground, palette.dark800),
          borderTopColor: use(palette.gray50, palette.dark600),
        }}
      >
        <div className='tracking-footer'>
          <div className='footer-line-container'>
            <img src={warn} alt='' />
            <p className='footer-line'>If you make changes to start and end details, you cannot revert after.</p>
          </div>
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.white, palette.boxShadow2),
              }}
              onClick={onHide}
            >
              Cancel
            </Button>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button onClick={handleSubmit} className='next-step' type='submit'>
                {trackingDetails?.id ? 'Update' : 'Add Time'}
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </SModal>
  );
}
