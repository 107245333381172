import React, { useState, useRef } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';
import { AudioRecorder } from 'react-audio-voice-recorder';
import XIcon from 'assets/icons/x.png';
import './voiceRecording.css';

const VoiceRecorder = ({ setVoiceRecording, activeChannel }) => {
  const [audioFile, setAudioFile] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());

  const transcode = async (blob) => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd';
    const ffmpeg = ffmpegRef.current;

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
    setLoaded(true);

    await ffmpeg.writeFile('input.webm', await fetchFile(blob));
    await ffmpeg.exec(['-i', 'input.webm', 'output.mp4']);
    const data = await ffmpeg.readFile('output.mp4');
    return new Blob([data.buffer], { type: 'video/mp4' });
  };

  const addAudioElement = async (blob) => {
    try {
      const file = await transcode(blob);
      const response = await activeChannel.sendFile(file, 'audio/mp4');
      setAudioFile(response.file);
    } catch (e) {
      // Do nothing
    }
  };

  const sendVoiceMessage = async () => {
    await activeChannel.sendMessage({
      text: '',
      attachments: [
        {
          type: 'audio',
          asset_url: audioFile,
        },
      ],
    });
    setVoiceRecording(false);
  };

  return (
    <div className='modalContainer add-new-channel'>
      <div className='addon-modal-area voice-recording' style={{ width: '250px', height: '250px' }}>
        <img className='close-Icon' src={XIcon} alt='close' onClick={() => setVoiceRecording(false)} />
        <div className='audio-recorder-icon'>
          <AudioRecorder onRecordingComplete={addAudioElement} />
        </div>
        {audioFile !== null && loaded && (
          <div className='addon-footer-btn'>
            <button onClick={sendVoiceMessage}>Send</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VoiceRecorder;
