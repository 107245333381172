import React from 'react';
import moment from 'moment/moment';
import UserInfo from 'components/UserInfo';
import { DocumentTableSort } from 'components/DocumentsTableSort/DocumentsTableSort';
import { palette } from 'utils/constants';
import Chip from 'common/Chip';

const formatDateString = (inputString, dateFormat) => {
  const dateRegex = /\b\d{1,4}([./-])\d{1,2}\1\d{1,4}\b/;

  const matches = inputString?.match(dateRegex);

  if (matches) {
    const isValidDate = moment(matches[0]).isValid();

    if (isValidDate) {
      const formattedDate = moment(matches[0]).format(dateFormat);
      return inputString.replace(matches[0], formattedDate);
    }
  }

  return inputString;
};

export const useColumns = ({ sortingQuery, dateFormat, convertToCustomerTime }) => {
  return [
    {
      field: 'sender_data',
      title: <DocumentTableSort title='SENDER' field='name' nested_field='sender_data' sortingQuery={sortingQuery} />,
      render: (rowData) => (
        <UserInfo
          size='38px'
          data={{
            ...(rowData.sender_data || {}),
            id: rowData.sender_id,
            user_type:
              rowData.sender_type?.type === 'Customer'
                ? 'staff'
                : rowData.sender_type?.type === 'Driver'
                ? 'driver'
                : 'carrier',
          }}
        />
      ),
    },
    {
      field: 'reference_id',
      title: <DocumentTableSort title='REFERENCE ID' field='reference_id' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const reference = rowData.reference_id;
        return <span style={{ color: palette.dark800 }}>{formatDateString(reference, dateFormat)}</span>;
      },
    },
    {
      field: 'status',
      title: <DocumentTableSort title='STATUS' field='status' nested_field='status' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const status = rowData.status.status;
        const bgStatus =
          status === 'New'
            ? '#EBF0FA'
            : status === 'Reviewing'
            ? '#FFFAEB'
            : status === 'Rejected'
            ? '#FAF0F3'
            : status === 'Approved'
            ? '#E1FCEF'
            : '';
        const colorStatus =
          status === 'New'
            ? '#2264E5'
            : status === 'Reviewing'
            ? '#B48909'
            : status === 'Rejected'
            ? '#D12953'
            : status === 'Approved'
            ? '#14804A'
            : '';
        return (
          <Chip
            label={status.toUpperCase()}
            bgColor={bgStatus}
            labelColor={colorStatus}
            fontSize='11px'
            fontWeight={500}
          />
        );
      },
    },
    {
      field: 'document_type',
      title: <DocumentTableSort title='TYPE' field='name' nested_field='document_type' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const type = rowData.document_type?.name;
        return <span style={{ color: palette.dark800 }}>{type}</span>;
      },
    },
    {
      field: 'shipment_id',
      title: <DocumentTableSort title='SHIPMENT ID' field='shipment_id' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const shipmentId = rowData.shipment_id;

        if (rowData.document_type?.name === 'Paper Logbook') {
          return <span style={{ color: palette.dark800 }}>-</span>;
        }

        return <span style={{ color: palette.dark800 }}>{shipmentId === '0' ? 'No Shipment' : shipmentId}</span>;
      },
    },
    {
      field: 'created_at',
      title: <DocumentTableSort title='DATE RECEIVED' field='created_at' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <div className='flex-row d-flex'>
            <span style={{ color: palette.dark800 }}>{convertToCustomerTime(rowData.created_at)}</span>
            <div onClick={() => {}} className='tablePlaner_cloud_container' />
          </div>
        );
      },
    },
    {
      field: 'notes',
      title: <DocumentTableSort title='NOTES' field='notes' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const notes = rowData.notes;
        return <span style={{ color: palette.dark800 }}>{formatDateString(notes, dateFormat)}</span>;
      },
    },
  ];
};
