import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0 48px;
  min-height: 300px;
  position: relative;

  .setting-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .absence-input {
    width: 100px;
    color: ${palette.gray200};
  }
`;
