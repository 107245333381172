import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { getShipmentOfferSettings, updateShipmentOfferSettings } from 'Api/CompanySettings';
import { autoAwardTimeOptions, getInitialValues, timeOptions } from './Offers.data';
import { SMergedInputs, SWrapper } from './Offers.styles';

const Offers = ({ isConfiguringStep, onCancelPrevPage, onSaveAndNext }) => {
  const showToaster = useShowToaster();
  const [loadingSave, setLoadingSave] = useState(false);
  const [settings, setSettings] = useState(null);
  const hasBrokerage = useHasBrokerage();

  const onSubmit = async (values) => {
    setLoadingSave(true);
    try {
      const body = {
        expire_shipment_offer: values.expire_shipment_offer || null,
        expire_shipment_offer_unit: values.expire_shipment_offer_unit.value || null,
        auto_repost: values.auto_repost,
        retract_carrier_award: values.retract_carrier_award || null,
        retract_carrier_award_unit: values.retract_carrier_award_unit.value || null,
        auto_award_first_lowest_amount: values.auto_award_first_lowest_amount || null,
        auto_award_first_lowest_amount_unit: values.auto_award_first_lowest_amount_unit.value || null,
        require_minimum_margin: values.require_minimum_margin,
        require_minimum_margin_percent: values.require_minimum_margin_percent || '',
        require_handling: values.require_handling,
        auto_dispatch: values.auto_dispatch,
        offer_pending_carrier: values.offer_pending_carrier,
        enable_audio_accepted_award: values.enable_audio_accepted_award,
        enable_audio_bid: values.enable_audio_bid,
      };
      await updateShipmentOfferSettings(body);
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingSave(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(settings),
    onSubmit,
    enableReinitialize: true,
  });

  const getSettings = async () => {
    try {
      const { data } = await getShipmentOfferSettings();
      setSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <SWrapper>
      <PageHeader
        title='Offers'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingSave}
      />
      <Divider margin='8px 0 24px' />
      <div className='form-wrapper'>
        <div className='form-field-row'>
          <Typography variant='s2'>Default Expire Shipment Offers After</Typography>
          <SMergedInputs>
            <Input
              width='120px'
              type='number'
              name='expire_shipment_offer'
              value={values.expire_shipment_offer}
              onChange={handleChange}
              error={touchedErrors.expire_shipment_offer}
              onKeyDown={(e) => {
                blockNotNumberChars(e);
                blockNonPositiveNumbers(e);
              }}
            />
            <Autocomplete
              width='100px'
              name='expire_shipment_offer_unit'
              options={timeOptions}
              value={values.expire_shipment_offer_unit}
              onChange={(e, val) => handleChange('expire_shipment_offer_unit', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.expire_shipment_offer_unit}
            />
          </SMergedInputs>
        </div>
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <Typography variant='s2'>Default Retract Carrier Award if Not Confirmed After</Typography>
            <SMergedInputs>
              <Input
                width='120px'
                name='retract_carrier_award'
                value={values.retract_carrier_award}
                onChange={handleChange}
                error={touchedErrors.retract_carrier_award}
                onKeyDown={(e) => {
                  blockNotNumberChars(e);
                  blockNonPositiveNumbers(e);
                }}
              />
              <Autocomplete
                width='100px'
                name='retract_carrier_award_unit'
                options={timeOptions}
                value={values.retract_carrier_award_unit}
                onChange={(e, val) => handleChange('retract_carrier_award_unit', val)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                error={touchedErrors.retract_carrier_award_unit}
              />
            </SMergedInputs>
          </div>
        )}
        <div className='form-field-row'>
          <Typography variant='s2'>Default Auto Award First Lowest Amount After</Typography>
          <SMergedInputs>
            <Input
              width='120px'
              name='auto_award_first_lowest_amount'
              value={values.auto_award_first_lowest_amount}
              onChange={handleChange}
              error={touchedErrors.auto_award_first_lowest_amount}
              disabled={values.auto_award_first_lowest_amount_unit?.value === null}
              onKeyDown={(e) => {
                blockNotNumberChars(e);
                blockNonPositiveNumbers(e);
              }}
            />
            <Autocomplete
              width='100px'
              name='auto_award_first_lowest_amount_unit'
              options={autoAwardTimeOptions}
              value={values.auto_award_first_lowest_amount_unit}
              onChange={(e, val) => {
                handleChange('auto_award_first_lowest_amount_unit', val);
                if (!val?.value) {
                  handleChange('require_minimum_margin', false);
                  handleChange('require_minimum_margin_percent', '');
                }
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.auto_award_first_lowest_amount_unit}
            />
          </SMergedInputs>
        </div>
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <CustomCheckbox
              name='require_minimum_margin'
              type='switch'
              smail={false}
              checked={values.require_minimum_margin}
              onChange={(checked) => handleChange('require_minimum_margin', checked)}
              disabled={!values.auto_award_first_lowest_amount_unit?.value}
            />
            <Typography variant='s2'>Require Minimum Margin</Typography>
            <InputWithIcon
              icon='%'
              left={false}
              width='120px'
              name='require_minimum_margin_percent'
              value={values.require_minimum_margin_percent}
              onChange={handleChange}
              error={touchedErrors.require_minimum_margin_percent}
              disabled={!values.require_minimum_margin}
              onKeyDown={blockNonPositiveNumbers}
            />
          </div>
        )}
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <CustomCheckbox
              name='require_handling'
              type='switch'
              smail={false}
              checked={values.require_handling}
              onChange={(checked) => handleChange('require_handling', checked)}
            />
            <Typography variant='s2'>Do not auto award if carrier cannot handle ETA</Typography>
          </div>
        )}
        <div className='form-field-row'>
          <CustomCheckbox
            name='auto_repost'
            type='switch'
            smail={false}
            checked={values.auto_repost}
            onChange={(checked) => handleChange('auto_repost', checked)}
          />
          <Typography variant='s2'>Auto Repost After Offer is Expired</Typography>
        </div>
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <CustomCheckbox
              name='auto_dispatch'
              type='switch'
              smail={false}
              checked={values.auto_dispatch}
              onChange={(checked) => handleChange('auto_dispatch', checked)}
            />
            <Typography variant='s2'>Auto Dispatch After Award is Accepted</Typography>
          </div>
        )}
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <CustomCheckbox
              name='offer_pending_carrier'
              type='switch'
              smail={false}
              checked={values.offer_pending_carrier}
              onChange={(checked) => handleChange('offer_pending_carrier', checked)}
            />
            <Typography variant='s2'>Allow Offers to Pending Status Carriers</Typography>
          </div>
        )}
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <CustomCheckbox
              name='enable_audio_accepted_award'
              type='switch'
              smail={false}
              checked={values.enable_audio_accepted_award}
              onChange={(checked) => handleChange('enable_audio_accepted_award', checked)}
            />
            <Typography variant='s2'>Enable Audio Alert for Awarded Shipments</Typography>
          </div>
        )}
        {!!hasBrokerage && (
          <div className='form-field-row'>
            <CustomCheckbox
              name='enable_audio_bid'
              type='switch'
              smail={false}
              checked={values.enable_audio_bid}
              onChange={(checked) => handleChange('enable_audio_bid', checked)}
            />
            <Typography variant='s2'>Enable Audio Alert for New Bids</Typography>
          </div>
        )}
      </div>
    </SWrapper>
  );
};

export default Offers;
