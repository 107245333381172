import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { UpdateStopPoint } from 'Api/StopPoint';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import styles from './IdChangeModal.module.css';

const IdChangeModal = ({ setShowIdModal, getStopPointItem, stopPointsItem }) => {
  const { use } = useTheme();
  const { id } = useParams();

  const validateRequired = (value) => {
    let error;
    if (value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const handleSubmit = async (values) => {
    UpdateStopPoint(id, values).then(async (res) => {
      if (res) {
        await getStopPointItem(id);
        setShowIdModal(false);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        stop_point_id: stopPointsItem?.stop_point?.stop_point_id,
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => {
        return (
          <Form>
            <div className={styles.container}>
              <div className={styles.label_wrapper}>
                <span className={styles.label_required}>Stop Point ID</span>
                <Field
                  type='text'
                  name='stop_point_id'
                  validate={validateRequired}
                  component={CustomInput}
                  style={{ width: 400, padding: '6px 12px' }}
                  labelStyle={{ margin: 0 }}
                />
                <ErrorMessage
                  name='stop_point_id'
                  render={(error) => (
                    <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                      {error}
                    </Typography>
                  )}
                />
              </div>
            </div>

            <div className='customFooter' style={{ background: use(palette.white, palette.dark800) }}>
              <div className='footer_buttons'>
                <CustomButton
                  type='secondary'
                  onClick={() => setShowIdModal(false)}
                  title='Cancel'
                  styleButton={{ margin: 10, padding: '6px 12px' }}
                  styleTitle={{
                    fontSize: 12,
                    marginTop: 1,
                  }}
                />
                <CustomButton
                  type='primary'
                  onClick={submitForm}
                  title='Update'
                  styleButton={{ margin: '10px 4px 10px 0', padding: '6px 12px' }}
                  styleTitle={{
                    fontSize: 12,
                    marginTop: 1,
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IdChangeModal;
