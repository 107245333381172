import styled from 'styled-components';
import { palette } from 'utils/constants';
import CircularProgress from '@mui/material/CircularProgress';

export const SHeader = styled.div`
  padding: 24px 0 8px 0;
  margin-bottom: 24px;
`;

export const SCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${palette.indigo500};
  }
`;

export const SRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};

  .header-item:nth-child(1) {
    width: 10%;
  }

  .header-item:nth-child(2) {
    width: 40%;
  }

  .header-item:nth-child(3) {
    width: 50%;
  }
`;

export const SContentRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 28px;
  cursor: pointer;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid ${palette.gray50};
  transition: background 0.3s;

  .table-item:nth-child(1) {
    width: 10%;
  }

  .table-item:nth-child(2) {
    width: 40%;
  }

  .table-item:nth-child(3) {
    width: 50%;
  }

  :hover {
    background: #f7f9fc;
  }
`;
