import React from 'react';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { getQuoteTotalDistance } from 'componentsV2/Planner/Quotes/CreateQuote/CreateQuote.data';

const CostEstimate = ({ form, fuelPrice }) => {
  const { currency } = useSelector((state) => state.root);
  const { values, handleChange } = form;

  const totalDistance = getQuoteTotalDistance(values);

  return (
    <div className='w-100'>
      <div className='mb-2'>
        <Typography variant='s1'>Cost Estimate</Typography>
      </div>
      <div className='create-quote-section'>
        <div>
          <div className='d-flex justify-content-between align-items-center gap-3 mb-2'>
            <div className='d-flex align-items-center gap-4'>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                Estimated Costs
              </Typography>
              <Input
                type='number'
                size='small'
                width='100px'
                placeholder='1'
                name='estimated_cost_count'
                value={values.estimated_cost_count}
                onChange={handleChange}
                onKeyDown={(e) => {
                  blockNonPositiveNumbers(e);
                  blockNotNumberChars(e);
                }}
              />
              <InputWithIcon
                type='number'
                size='small'
                width='130px'
                placeholder='0.00'
                name='estimated_cost_rate'
                value={values.estimated_cost_rate}
                onChange={handleChange}
                onKeyDown={blockNonPositiveNumbers}
              />
            </div>
            <Typography variant='s2'>
              {currency}
              {formatNumber(Number(values.estimated_cost_count || 0) * Number(values.estimated_cost_rate || 0))}
            </Typography>
          </div>
          <div className='d-flex justify-content-between align-items-center gap-3 mb-2'>
            <div className='d-flex align-items-center gap-4'>
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                Estimated Fuel Costs
              </Typography>
              <Chip
                label={`$${formatNumber(fuelPrice)} /GAL DIESEL`}
                labelColor={palette.indigo500}
                bgColor={palette.indigo0}
              />
            </div>
            <Typography variant='s2'>
              {currency}
              {formatNumber((Math.round(totalDistance) / 6) * Number(fuelPrice || 0))}
            </Typography>
          </div>
          <div className='d-flex justify-content-between align-items-center gap-3'>
            <Typography variant='s1'>Estimated Costs</Typography>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {currency}
              {formatNumber(
                Number(values.estimated_cost_count || 0) * Number(values.estimated_cost_rate || 0) +
                  (Math.round(totalDistance) / 6) * Number(fuelPrice || 0)
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostEstimate;
