export const initialStats = {
  total: {},
  variable: {},
  fixed: {},
  maintenance: {},
  admin: {},
};

export const statTypeYearMapper = {
  variable: 'variable_cost_year',
  fixed: 'fixed_cost_year',
  admin: 'admin_cost_year',
  maintenance: 'maintenance_cost_year',
};

export const initialYearFilters = {
  variable_cost_year: new Date(),
  fixed_cost_year: new Date(),
  admin_cost_year: new Date(),
  maintenance_cost_year: new Date(),
};
