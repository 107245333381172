import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  GetConnectedSeries,
  GetAllConnectedSeries,
  activeService,
  deactivateService,
  driverConnectedService,
} from 'Api/Driver';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { getCards, removeConnectService } from 'Api/CardManagement';
import { saveFinishLater } from 'Api/DriverV2';
import SwitchRadio from '../../SwitchRadio/SwitchRadio';
import CustomizedSnackbars from '../../toast/Toast';
import TablePreLoader from '../../TablePreLoader/TablePreLoader';
import ConnectWEX from './ConnectWEX';

function MyVerticallyCenteredModal({ modalProps, selectValue, driverId, setShowMessage }) {
  const { use } = useTheme();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    GetAllConnectedSeries().then((res) => {
      const getFields = res.data.find((service) => service?.id === selectValue?.id)?.fields || [];
      setFields(getFields.filter((field) => field.field_belongs_to === 'customer_fields'));
    });
  }, [selectValue]);

  const onChangeFieldValue = (value, index) => {
    const tempFields = [...fields];
    tempFields[index].value = value;
    setFields(tempFields);
  };

  const connect = () => {
    let hasError = '';
    for (const field of fields) {
      if (!field.value) {
        hasError = `Please fill ${field.field_name}`;
        break;
      }
    }
    if (hasError) {
      setShowMessage({
        message: hasError,
        visible: true,
        type: 'error',
      });
      return;
    }
    activeService({ driver_id: driverId, provider_id: fields[0].value, serviceId: 51 })
      .then((resp) => {
        if (resp?.error) {
          setShowMessage({
            message: resp?.error,
            visible: true,
            type: 'error',
          });
          return;
        }
        setShowMessage({
          message: 'Connection to Samsara established!',
          visible: true,
          type: 'success',
        });
        setTimeout(() => {
          setShowMessage({
            visible: false,
            message: '',
          });
        }, 6000);
        modalProps.onHide(selectValue);
      })
      .catch((error) => {
        setShowMessage({
          message: getErrorMessage(error),
          visible: true,
          type: 'error',
        });
        setTimeout(() => {
          setShowMessage({
            visible: false,
            message: '',
          });
        }, 6000);
      });
  };

  return (
    <Modal {...modalProps} size='md' enforceFocus={false} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header
        closeButton
        className='connect-modal-header-footer'
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title id='contained-modal-title-vcenter' style={{ color: use('#171c26', palette.gray200) }}>
          {selectValue?.provider_name || ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: use(palette.gray0, palette.dark800) }} className='driver-style-wrap'>
        {fields.map((field, index) => (
          <div key={field.field_name}>
            <p className='text-style' style={{ marginLeft: '0', color: use(palette.dark700, palette.gray200) }}>
              {field.field_name} <span className='required'>*</span>
            </p>
            <input
              type='text'
              value={field.value}
              onChange={(event) => onChangeFieldValue(event.target.value, index)}
              className='connect-modal-input'
              style={{
                backgroundColor: use(palette.gray0, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer
        className='connect-modal-header-footer'
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Button className='Cancel-modal-btn' onClick={() => modalProps.onHide()}>
          Cancel
        </Button>
        <Button className='Connect-modal-btn' onClick={() => connect()}>
          Connect
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ConnectedServices = ({
  nextStep,
  prevStep,
  Cancel,
  driverId,
  setStepsDone,
  isDriverProfile = false,
  draftDriver,
  getDrivers,
}) => {
  const { use } = useTheme();
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [tabServices, setTabServices] = useState([]);
  const [selectedService, setSelectedService] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [tableIndex, setTableIndex] = useState(Date.now());
  const [driverCard, setDriverCard] = useState(null);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  useEffect(() => {
    if (isDriverProfile) {
      setLoading(true);
    }
    Promise.allSettled([GetConnectedSeries(), driverConnectedService({ driver_id: driverId })])
      .then(async (res) => {
        if (res[0].status === 'fulfilled') {
          const tempTabs = [];
          const tempServices = [...(res[0].value?.data || [])];
          let value = 1;
          for (const service of tempServices) {
            const hasServiceIndex = tempTabs.findIndex((tab) => tab.label === service.type);
            if (hasServiceIndex === -1) {
              value += 1;
              tempTabs.push({ label: service.type, count: 1, key: service.type, value });
            } else {
              tempTabs[hasServiceIndex].count += 1;
            }
            if (res[1].status === 'fulfilled' && res[1].value?.data) {
              const data = res[1].value?.data;
              const hasService = data.find((s) => s?.connected_service?.id === service?.id);
              if (hasService) {
                service.isConnected = true;
              }
              if (data.some((i) => i.provider_id === 44)) {
                const params = {
                  page: 1,
                  itemsPerPage: 1000,
                };
                const { data: cards } = await getCards(params);
                const card = cards?.find((i) => i?.user?.id === Number(driverId));

                if (card) {
                  setDriverCard(card);
                }
              }
            }
          }
          tempTabs.unshift({ label: 'All', count: tempServices?.length, key: 'all', value: 1 });
          setTabs(tempTabs);
          setTabServices(tempServices);
          setAllServices(tempServices);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [tableIndex]);

  const onChangeTab = (tabValue) => {
    setTab(tabValue);
    if (tabValue.value === 1) {
      // show all data
      setTabServices([...allServices]);
    } else {
      const getSelectedTab = tabs.find((service) => service.value === tabValue.value);
      setTabServices(allServices.filter((service) => service.type === getSelectedTab.label));
    }
  };

  const removeWexService = async () => {
    try {
      const body = {
        driver_id: driverId,
        connected_service_id: 44,
      };
      await removeConnectService(body);
      setTableIndex(Date.now());
      setOpen(false);
      setDriverCard(null);
      setShowMessage({
        message: 'Service has been removed',
        visible: true,
        type: 'success',
      });
    } catch (e) {
      // Do nothing
    }
  };

  const onHide = (val) => {
    actionButtons(val, true);
  };

  const remove = (val) => {
    if (val?.id === 44) {
      removeWexService();
      return;
    }

    deactivateService({ driver_id: driverId, serviceId: 51 })
      .then(() => {
        actionButtons(val, false);
        setShowMessage({
          message: 'Service has been removed',
          visible: true,
          type: 'success',
        });
        setTimeout(() => {
          setShowMessage({
            visible: false,
            message: '',
          });
        }, 6000);
        setOpen(null);
      })
      .catch((error) => {
        setShowMessage({
          message: getErrorMessage(error),
          visible: true,
          type: 'error',
        });
        setTimeout(() => {
          setShowMessage({
            visible: false,
            message: '',
          });
        }, 6000);
      });
  };

  const actionButtons = (val, action) => {
    if (val) {
      const tempAllServices = [...allServices];
      const tempTabServices = [...tabServices];
      const getAllServiceIndex = allServices.findIndex((service) => service.id === val.id);
      const tabServiceIndex = tabServices.findIndex((service) => service.id === val.id);
      tempAllServices[getAllServiceIndex].isConnected = action;
      tempTabServices[tabServiceIndex].isConnected = action;
      setAllServices(tempAllServices);
      setTabServices(tempTabServices);
    }
    setSelectedService(null);
  };

  const handleClickOpen = (val) => {
    setOpen(val);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onPrevStep = () => {
    setStepsDone((prevState) => ({
      ...prevState,
      isTravelArea: true,
    }));
    prevStep();
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 7,
    };

    saveFinishLater(driverId, body).then(() => {
      getDrivers();
      Cancel();
    });
  };

  if (loading) {
    return <TablePreLoader />;
  }

  if (isDriverProfile) {
    return (
      <ConnectedServicesContent
        use={use}
        tab={tab}
        tabs={tabs}
        open={open}
        remove={remove}
        onHide={onHide}
        driverId={driverId}
        driverCard={driverCard}
        setDriverCard={setDriverCard}
        showMessage={showMessage}
        onChangeTab={onChangeTab}
        tabServices={tabServices}
        handleClose={handleClose}
        setTableIndex={setTableIndex}
        setShowMessage={setShowMessage}
        selectedService={selectedService}
        handleClickOpen={handleClickOpen}
        setSelectedService={setSelectedService}
      />
    );
  }

  return (
    <>
      <Modal.Body
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
        className='add-driver-modal-body driver-style-wrap'
      >
        <ConnectedServicesContent
          use={use}
          tab={tab}
          open={open}
          tabs={tabs}
          onHide={onHide}
          remove={remove}
          driverId={driverId}
          driverCard={driverCard}
          setDriverCard={setDriverCard}
          onChangeTab={onChangeTab}
          showMessage={showMessage}
          tabServices={tabServices}
          handleClose={handleClose}
          setTableIndex={setTableIndex}
          setShowMessage={setShowMessage}
          selectedService={selectedService}
          handleClickOpen={handleClickOpen}
          setSelectedService={setSelectedService}
        />
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            <Button className='cancel-button ms-0' onClick={nextStep}>
              Skip
            </Button>
            <Button className='next-step' onClick={nextStep} type='submit'>
              Next Step
            </Button>
          </div>
        </div>
      </Modal.Footer>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </>
  );
};

export default ConnectedServices;

export const ConnectedServicesContent = ({
  driverId,
  selectedService,
  use,
  onHide,
  setShowMessage,
  tabs,
  tab,
  onChangeTab,
  open,
  tabServices,
  setSelectedService,
  handleClickOpen,
  handleClose,
  remove,
  showMessage,
  setTableIndex,
  driverCard,
  setDriverCard,
}) => (
  <>
    <div className={selectedService ? 'fade modal-backdrop show' : 'modal'}>
      {selectedService?.id === 51 && (
        <MyVerticallyCenteredModal
          modalProps={{
            show: !!selectedService,
            onHide,
            backdrop: true,
          }}
          selectValue={selectedService}
          driverId={driverId}
          setShowMessage={setShowMessage}
        />
      )}
    </div>
    <div>
      <SwitchRadio
        name='tableTopTabMenu'
        items={tabs}
        value={tab}
        type='tab'
        onChange={(v) => onChangeTab(v)}
        plus={false}
      />
      <table className='table table-hover' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <tr
          className='connected-services-table-row'
          style={{
            backgroundColor: use(palette.white, palette.dark600),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <th
            className='connected-services-table-head table-head service'
            style={{ color: use(palette.gray700, palette.gray200) }}
          >
            Services
          </th>
          <th
            className='connected-services-table-head table-head'
            style={{ color: use(palette.gray700, palette.gray200) }}
          >
            Type
          </th>
          <th />
        </tr>
        {tabServices.map((value) => (
          <tr className='connected-services-table-row' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
            <td className='d-flex align-items-center service-provider'>
              <span className='servie-logo'>
                <img src={value.logo} alt='' className='service-logo-image' />
              </span>
              <span className='table-service ms-2' style={{ color: use(palette.dark900, palette.gray200) }}>
                {value.provider_name}
                {value.id === 44 && !!driverCard && (
                  <span style={{ marginLeft: '32px' }}>
                    ( <span style={{ fontSize: '8px' }}>●●●●</span> {driverCard.card_number.slice(-4)} )
                  </span>
                )}
              </span>
            </td>
            <td>
              <span className='table-content' style={{ color: use(palette.dark700, palette.gray200) }}>
                {value.type}
              </span>
            </td>
            <td className='service-action'>
              {!value.isConnected ? (
                [44, 51].includes(value.id) ? (
                  <button onClick={() => setSelectedService(value)} className='connect-button'>
                    Connect
                  </button>
                ) : (
                  <span>N/A</span>
                )
              ) : (
                <button onClick={() => handleClickOpen(value)} className='disconnect-button'>
                  Remove
                </button>
              )}
            </td>
          </tr>
        ))}
      </table>
      {selectedService?.id === 44 && (
        <ConnectWEX
          open={selectedService?.id === 44}
          onClose={() => setSelectedService(null)}
          driverId={driverId}
          onSuccess={(card_number) => {
            setTableIndex(Date.now());
            setDriverCard({ card_number });
          }}
        />
      )}
      <Dialog
        open={!!open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirm Your Action</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Are you sure you want to remove this?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={handleClose}>
            Cancel
          </Button>
          <Button className='next-step' onClick={() => remove(open)} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </div>
  </>
);
