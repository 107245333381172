import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import { palette } from 'utils/constants';

export const SChip = styled(Chip)`
  &.MuiChip-root {
    border-radius: ${({ $radius }) => $radius || '4px'};
    background-color: ${({ $bgColor, color }) => (!color ? $bgColor || '#F0F2F7' : '')};
    height: ${({ height }) => height || 'auto'};
    padding: ${({ padding, size }) => padding || (size === 'medium' ? '4px 8px' : '2px 8px')};

    .MuiChip-label {
      font-family: Inter, sans-serif;
      font-size: ${({ $fontSize }) => $fontSize || ''};
      font-weight: ${({ $fontWeight }) => $fontWeight || ''};
      color: ${({ $labelColor, color }) => (!color ? $labelColor || palette.gray600 : '')};
      line-height: 16px;
      padding: 0;
    }

    .MuiChip-deleteIcon {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.9s;
      font-size: 20px;
      margin: 0 0 0 4px;
      fill: ${palette.red500};
    }

    :hover {
      .MuiChip-deleteIcon {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;
