import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  background: ${palette.white};
  border: 1px solid ${palette.gray50};
  border-radius: 8px;
  margin-bottom: 40px;

  .policy-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid ${palette.gray50};

    .edit-icon {
      cursor: pointer;
    }
  }

  .policy-content {
    padding: 16px 20px 24px;
    min-height: 400px;
  }

  .section-wrapper {
    width: 280px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .view-attachment {
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }
  }

  .uploader-wrapper {
    margin-top: 32px;
  }
`;

export const SMergedInputs = styled.div`
  display: flex;

  input {
    border-radius: 6px 0 0 6px;
  }
`;
