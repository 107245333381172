import React, { Fragment, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrows.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { formatNumber, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { deleteBenefit } from 'Api/Payroll';
import { getBenefitsList } from 'Api/PayrollSettings';
import DisputedActions from 'pages/Payroll/shared/DisputedActions';
import useDateFormat from 'hooks/useDateFormat';
import { calcAppliedAmount } from 'pages/Payroll/Settlement/helpers';
import Recurring from './components/Recurring';
import DeclineReason from '../../components/DeclineReason';
import BenefitsSettings from './components/BenefitsSettings';
import { userTypes } from '../Tables.data';
import { getInitialValues } from './Benefits.data';
import { STable, SWrapper, SAddMore, SCheckboxWrapper, SPickerWrapper, SAutocomplete, SInput } from '../Tables.styles';

const Benefits = ({ benefits, user_type, updateBenefits, settlement }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [loadingDeleteId, setLoadingDeleteId] = useState(null);
  const [benefitTypes, setBenefitsTypes] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [benefitToDecline, setBenefitToDecline] = useState(null);

  const totalAppliedBenefits = useMemo(() => {
    return calcAppliedAmount(benefits);
  }, [benefits]);

  const getBenefitTypes = async () => {
    try {
      const { data } = await getBenefitsList();
      setBenefitsTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onMouseEnter = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(row.id);
    const newBenefits = benefits.map((item) =>
      item.id === row.id ? { ...item, edit: true } : { ...item, edit: false }
    );
    updateBenefits(newBenefits);
  };

  const onMouseLeave = (row) => {
    if (row.isNew) {
      return;
    }

    setActionsVisible(null);
    const newBenefits = benefits.map((item) => (item.id === row.id ? { ...item, edit: false } : item));
    updateBenefits(newBenefits);
  };

  const onDateChange = (id, value) => {
    const date = moment(value).format('MM/DD/YYYY');
    const time = moment(value).format('HH:mm:ss');

    const newDeductions = benefits.map((item) => (item.id === id ? { ...item, date, time } : item));
    updateBenefits(newDeductions);
  };

  const onTypeChange = (id, value) => {
    const newBenefits = benefits.map((item) =>
      item.id === id
        ? {
            ...item,
            benefit_type: value,
            quantity: 1,
            per_quantity_amount: value.amount,
            amount: value.amount,
          }
        : item
    );
    updateBenefits(newBenefits);
  };

  const onQuantityChange = (id, value) => {
    const newBenefits = benefits.map((item) =>
      item.id === id ? { ...item, quantity: value, amount: Number(item.per_quantity_amount) * Number(value) } : item
    );
    updateBenefits(newBenefits);
  };

  const onAmountChange = (id, value) => {
    const newBenefits = benefits.map((item) =>
      item.id === id ? { ...item, per_quantity_amount: value, amount: Number(item.quantity) * Number(value) } : item
    );
    updateBenefits(newBenefits);
  };

  const onDayChange = (id, value) => {
    const newBenefits = benefits.map((item) => (item.id === id ? { ...item, day: value } : item));
    updateBenefits(newBenefits);
  };

  const onMonthWeekChange = (id, value) => {
    const newBenefits = benefits.map((item) => {
      return item.id === id ? { ...item, month_week: value, day: value === 'Month' ? '1st' : 'Monday' } : item;
    });
    updateBenefits(newBenefits);
  };

  const onApplyChange = (id, checked) => {
    const newBenefits = benefits.map((item) => {
      return item.id === id ? { ...item, apply: checked } : item;
    });
    updateBenefits(newBenefits);
  };

  const handleSubmit = (id, newData) => {
    const newBenefits = benefits.map((item) => {
      return item.id === id ? newData : item;
    });
    updateBenefits(newBenefits);
  };

  const removeBenefit = async (row) => {
    if (row.isNew) {
      const newDeductions = benefits.filter((item) => item.id !== row.id);
      updateBenefits(newDeductions);
      return;
    }
    setLoadingDeleteId(row.id);
    try {
      await deleteBenefit({ user_type: userTypes[user_type], id: row.id });
      updateBenefits((prevState) => prevState.filter((item) => item.id !== row.id));
      showToaster({ type: 'success', message: 'Benefit has been deleted successfully' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoadingDeleteId(null);
  };

  const onAddMore = () => {
    updateBenefits((prevState) => [...prevState, { ...getInitialValues(), id: Date.now() }]);
  };

  const onSettingsClose = () => {
    getBenefitTypes();
    setSettingsOpen(false);
  };

  const onDecline = async (reason) => {
    const newBenefits = benefits.map((item) =>
      item.id === benefitToDecline.id ? { ...item, dispute_declined: true, dispute_declined_reason: reason } : item
    );
    updateBenefits(newBenefits);
    setBenefitToDecline(null);
  };

  const onApprove = (benefit) => {
    const newBenefits = benefits.map((item) => (item.id === benefit.id ? { ...item, dispute_approved: true } : item));
    updateBenefits(newBenefits);
  };

  useEffect(() => {
    getBenefitTypes();
  }, []);

  return (
    <SWrapper>
      <Typography variant='h3'>Benefits</Typography>
      <Divider margin='8px 0 20px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>DATE / TIME</th>
            <th>
              BENEFIT TYPE
              <GearIcon onClick={() => setSettingsOpen(true)} className='settings-gear' />
            </th>
            <th>QUANTITY</th>
            <th>AMOUNT PER QUANTITY</th>
            <th>TOTAL</th>
            <th>SET INTERVAL</th>
            <th>APPLY TO SETTLEMENT</th>
          </tr>
        </thead>
        <tbody>
          {benefits?.map((item) => (
            <Fragment key={item.id}>
              <tr
                className={`body-row ${item.isNew ? 'bg-gray' : ''} ${
                  item.disputed && settlement.status_id === 4 ? 'disputed' : ''
                } ${item.dispute_approved ? 'dispute-approved' : ''}`}
                onMouseEnter={() => onMouseEnter(item)}
                onMouseLeave={() => onMouseLeave(item)}
              >
                <td style={{ width: '16%' }}>
                  {item.edit || item.isNew ? (
                    <SPickerWrapper>
                      <DatePicker
                        showTimeInput
                        showYearDropdown
                        selected={item.date && item.time ? new Date(`${item.date} ${item.time}`) : ''}
                        placeholderText='mm/dd/yyyy hh:mm'
                        dateFormat='MM/dd/yyyy h:mm aa'
                        onChange={(val) => onDateChange(item.id, val)}
                      />
                      <ArrowIcon className='arrow-icon' width={11} height={11} />
                    </SPickerWrapper>
                  ) : (
                    <Typography variant='s2' style={{ display: 'inline-block', width: '164px' }}>
                      {formatDateTime(`${item.date} ${item.time}`)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '25%' }}>
                  {item.edit || item.isNew ? (
                    <SAutocomplete
                      labelKey='benefit_type'
                      value={item.benefit_type || null}
                      onChange={(e, value) => onTypeChange(item.id, value)}
                      isOptionEqualToValue={(option, value) => option.benefit_type === value.benefit_type}
                      options={benefitTypes}
                    />
                  ) : (
                    <Typography variant='b2'>{item.benefit_type?.benefit_type || 'N/A'}</Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  {item.edit || item.isNew ? (
                    <SInput
                      type='number'
                      value={item.quantity || ''}
                      onChange={(e) => onQuantityChange(item.id, e.target.value)}
                      onKeyDown={(e) => {
                        blockNonPositiveNumbers(e);
                        blockNotNumberChars(e);
                      }}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {item.quantity || 0}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  {item.edit || item.isNew ? (
                    <SInput
                      type='number'
                      value={item.per_quantity_amount || ''}
                      onChange={(e) => onAmountChange(item.id, e.target.value)}
                      onKeyDown={blockNonPositiveNumbers}
                    />
                  ) : (
                    <Typography variant='s3' style={{ color: use(palette.gray700, palette.gray50) }}>
                      {currency}
                      {formatNumber(item.per_quantity_amount || 0)}
                    </Typography>
                  )}
                </td>
                <td style={{ width: '8%' }}>
                  <Typography variant='button2' className='nowrap'>
                    - {currency}
                    {formatNumber(item.amount)}
                  </Typography>
                </td>
                <td style={{ width: '20%' }}>
                  <Recurring
                    data={item}
                    onDayChange={onDayChange}
                    onMonthWeekChange={onMonthWeekChange}
                    handleSubmit={handleSubmit}
                  />
                </td>
                <td style={{ width: '10%' }}>
                  <SCheckboxWrapper>
                    <CustomCheckbox checked={!!item.apply} onChange={(value) => onApplyChange(item.id, value)} />
                    <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Deduct Benefit Cost
                    </Typography>
                  </SCheckboxWrapper>
                </td>
                <td style={{ width: '5%' }}>
                  {loadingDeleteId === item.id ? (
                    <span className='loading-wrapper'>
                      <CircularProgress size={16} />
                    </span>
                  ) : (
                    <span className='action-wrapper' onClick={() => removeBenefit(item)}>
                      <DeleteIcon width={9} height={9} fill={palette.red500} />
                    </span>
                  )}
                </td>
              </tr>
              {!!item.disputed && settlement.status_id === 4 && (
                <tr
                  className={`disputed-actions ${actionsVisible === item.id ? 'actions-visible' : ''} ${
                    item.dispute_approved ? 'dispute-approved-actions' : ''
                  }`}
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={() => onMouseLeave(item)}
                >
                  <td colSpan='9'>
                    <DisputedActions
                      data={item}
                      onApprove={() => onApprove(item)}
                      onReject={() => setBenefitToDecline(item)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          <tr className='total-row'>
            <td>
              <SAddMore onClick={onAddMore}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                  Add Another...
                </Typography>
              </SAddMore>
            </td>
            <td />
            <td colSpan='2' className='align-end'>
              <Typography variant='button2' className='nowrap'>
                Total applied amount:
              </Typography>
            </td>
            <td>
              <Typography variant='button2' className='nowrap'>
                - {currency}
                {formatNumber(totalAppliedBenefits || 0)}
              </Typography>
            </td>
            <td colSpan='3' />
          </tr>
        </tbody>
      </STable>
      <BenefitsSettings open={settingsOpen} onClose={onSettingsClose} />
      <DeclineReason open={!!benefitToDecline} onClose={() => setBenefitToDecline(null)} onSubmit={onDecline} />
    </SWrapper>
  );
};

export default Benefits;
