import React from 'react';
import moment from 'moment';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import rightArrow from 'assets/icons/drivers/table-logo/right-arrow.svg';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { LoginStatusColor, palette } from 'utils/constants';
import { SUnderlineText } from './StaffLogTab.styles';

export const initialFilters = { page: 1, itemsPerPage: 25 };

export const initialDateRange = {
  start: startOfDay(addDays(new Date(), -31)),
  end: endOfDay(new Date()),
};

export const useLoginLogColumns = ({ sort, sortingQuery }) => {
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'device_name',
      title: <ColumnHeader title='DEVICE' field='device_name' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {Number(row.device_name) === 0 ? '' : row.device_name} {row.device_type}
          </Typography>
        );
      },
    },
    {
      field: 'location',
      title: <ColumnHeader title='LOCATION' field='location' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.location || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'login_attempt_status_id',
      title: <ColumnHeader title='TYPE' field='login_attempt_status_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Chip
            bgColor={LoginStatusColor[row?.login_attempt_status?.status]?.bgColor}
            labelColor={LoginStatusColor[row?.login_attempt_status?.status]?.color}
            label={row.login_attempt_status?.status}
          />
        );
      },
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='DATE' field='created_at' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row?.created_at ? convertToCustomerTime(row?.created_at) : '-'}
          </Typography>
        );
      },
    },
  ];
};

export const useChangeLogColumns = ({ onViewAttachment, sort, sortingQuery }) => {
  const { formatDate, formatDateTime, convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'change_description',
      title: <ColumnHeader title='CHANGED SECTION' field='change_description' />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.change_description}
          </Typography>
        );
      },
    },
    {
      field: 'change_from',
      title: <ColumnHeader title='CHANGED FROM/TO' field='change_from' />,
      render: (row) => {
        const changeFromIsValidDate = moment(row.change_from, 'YYYY-MM-DD', true).isValid();
        const changeFromIsValidDateTime = moment(row.change_from, 'YYYY-MM-DD HH:mm:ss', true).isValid();
        const changeToIsValidDate = moment(row.change_to, 'YYYY-MM-DD', true).isValid();
        const changeToIsValidDateTime = moment(row.change_to, 'YYYY-MM-DD HH:mm:ss', true).isValid();

        return (
          <div className='d-flex align-items-center gap-2'>
            {row.change_from.startsWith('https://') ? (
              <SUnderlineText
                onClick={(e) => {
                  e.stopPropagation();
                  onViewAttachment(row.change_from);
                }}
              >
                View Old Attachment
              </SUnderlineText>
            ) : (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                {changeFromIsValidDate
                  ? formatDate(row.change_from)
                  : changeFromIsValidDateTime
                  ? formatDateTime(row.change_from, true)
                  : row.change_from}
              </Typography>
            )}
            {row.change_to && (
              <>
                <img src={rightArrow} alt='changed_to' />
                {row.change_to.startsWith('https://') ? (
                  <SUnderlineText
                    onClick={(e) => {
                      e.stopPropagation();
                      onViewAttachment(row.change_to);
                    }}
                  >
                    View New Attachment
                  </SUnderlineText>
                ) : (
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {changeToIsValidDate
                      ? formatDate(row.change_to)
                      : changeToIsValidDateTime
                      ? formatDateTime(row.change_to, true)
                      : row.change_to}
                  </Typography>
                )}
              </>
            )}
            {!row.change_from && !row.change_to && (
              <Typography variant='b2' style={{ color: palette.gray700 }}>
                No data
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='CHANGED BY' field='created_at' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            By {row?.change_by_user_details?.first_name} {row?.change_by_user_details?.last_name} on{' '}
            {convertToCustomerTime(row.created_at)}
          </Typography>
        );
      },
    },
  ];
};
