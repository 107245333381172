import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SRowUpload = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 10px;

  .table-upload-header-item {
    width: 200px;
    margin-right: 28px;
  }
`;

export const SFormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid ${palette.gray50};
  background: #f7f9fc;

  .flex-item {
    width: 200px;
    margin-right: 28px;
  }

  .flex-item:nth-last-child(1) {
    width: 300px;
    margin-right: 28px;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action_wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .action_wrapper:hover {
    background-color: ${palette.indigo50};
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const SAttachDoc = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;

  span {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${palette.indigo500};
    white-space: nowrap;
  }

  .plus-icon {
    fill: ${palette.indigo500};
  }

  :hover {
    background-color: ${palette.indigo50};

    span {
      color: ${palette.gray900};
    }

    .plus-icon {
      fill: ${palette.gray900};
    }
  }
`;

export const SAttachedDoc = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  background-color: ${palette.indigo0};

  span {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${palette.indigo500};
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
  }

  .uploaded-file {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .attach-icon {
    fill: ${palette.indigo400};
  }

  .action-icons {
    display: none;
  }

  :hover {
    background-color: ${palette.indigo50};

    span {
      color: ${palette.indigo700};
    }

    .attach-icon {
      fill: ${palette.indigo500};
    }

    .action-icons {
      display: flex;
      align-items: center;

      .download-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 24px;
        border-left: 1px solid ${palette.indigo100};
        border-right: 1px solid ${palette.indigo100};

        :hover {
          background-color: ${palette.indigo100};

          .download-icon {
            fill: ${palette.indigo700};
          }
        }
      }

      .cancel-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;

        :hover {
          background-color: ${palette.indigo100};

          .cancel-icon {
            fill: ${palette.red700};
          }
        }
      }

      .cancel-icon {
        fill: ${palette.red400};
      }
    }
  }
`;
