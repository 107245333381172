import React from 'react';
import ColumnHeader from 'common/ColumnHeader';
import { palette } from 'utils/constants';
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRightWhite.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/detention.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import UserInfo from 'components/UserInfo';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import Tooltip from 'common/Tooltip';
import { statues } from '../../../CustomCollapsibleRow/CustomCollapsibleRow.data';
import classes from './HOS.module.scss';

export const dutyStatuses = [
  { id: 1, name: 'Driving', value: 'driving', active: false },
  { id: 2, name: 'On Duty', value: 'onDuty', active: false },
  { id: 3, name: 'Yard Move', value: 'yardMove', active: false },
  { id: 4, name: 'Off Duty', value: 'offDuty', active: false },
  { id: 5, name: 'Personal Conveyance', value: 'personalConveyance', active: false },
  { id: 6, name: 'Sleeper Berth', value: 'sleeperBed', active: false },
  { id: 7, name: 'Disconnected', value: 'disconnected', active: false },
  { id: 8, name: 'Waiting Time', value: 'waitingTime', active: false },
  { id: 9, name: 'Int Location', value: 'intLocation', active: false },
];

export const useColumns = ({ sort, sortingQuery, navigateToEquipmentProfile, setAssignToDriverId }) => {
  return [
    {
      field: 'driver',
      title: <ColumnHeader title='Driver' field='driver_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <UserInfo
          size='28px'
          data={{
            ...(rowData.user_type === 'staff' ? rowData?.staff?.staff : rowData.driver),
            id: rowData.user_type === 'staff' ? rowData?.staff?.staff?.id : rowData.driver?.id,
            name:
              rowData.user_type === 'staff'
                ? `${rowData?.staff?.staff?.first_name} ${rowData?.staff?.staff?.last_name}`
                : `${rowData.driver?.fname} ${rowData.driver?.lname}`,
            image: rowData.user_type === 'staff' ? rowData?.staff?.staff?.profile_logo : rowData.driver?.image,
            user_type: rowData.user_type,
            status: rowData.user_type === 'staff' ? rowData?.staff?.staff?.status_data : rowData.driver?.status_data,
          }}
        />
      ),
    },
    {
      field: 'duty_status',
      title: <ColumnHeader title='DUTY STATUS' field='duty_status' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Chip
          bgColor={statues[row?.duty_status]?.bgColor}
          labelColor={statues[row?.duty_status]?.color}
          label={statues[row?.duty_status]?.name}
          fontWeight={500}
          fontSize='11px'
          style={{ textTransform: 'uppercase' }}
        />
      ),
    },
    {
      field: 'time_in_current_status',
      title: (
        <ColumnHeader
          title='TIME IN CURRENT STATUS'
          field='time_in_current_status'
          sort={sort}
          onClick={sortingQuery}
        />
      ),
      render: (row) => (
        <p className='incident-date' style={{ color: palette.gray900 }}>
          {row?.time_in_current_status || '-'}
        </p>
      ),
    },
    {
      field: 'vehicle',
      title: <ColumnHeader title='VEHICLE' field='equipment_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <div>
          {!!rowData?.equipment_id && rowData?.equipment_id !== rowData.driver?.equipment?.[0]?.equipment_id && (
            <p className='table-data-text' style={{ color: palette.gray700 }}>
              <span>{rowData?.equipment_id}</span>
            </p>
          )}
          {rowData?.equipment_id === rowData.driver?.equipment?.[0]?.equipment_id && (
            <div className='d-flex gap-2 align-items-center'>
              <Typography
                variant='s3'
                style={{ textDecoration: 'underline', color: palette.green500 }}
                onClick={(e) => navigateToEquipmentProfile(e, rowData.driver?.equipment?.[0]?.id)}
              >
                {rowData.driver?.equipment?.[0]?.equipment_id} {rowData.driver?.equipment?.[0]?.equipment_type?.title}
              </Typography>
              <Tooltip
                title='Vehicle matches our system assignment for this driver.'
                placement='top'
                componentsProps={{
                  placement: 'top',
                  tooltip: {
                    sx: {
                      fontSize: 13,
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                      maxWidth: 'unset',
                      cursor: 'pointer',
                    },
                  },
                }}
              >
                <CheckIcon />
              </Tooltip>
            </div>
          )}
          {!!rowData.driver?.equipment && rowData?.equipment_id !== rowData.driver?.equipment?.[0]?.equipment_id && (
            <div className='d-flex gap-2 align-items-center'>
              <ArrowRight fill={palette.red400} />
              <Typography
                variant='s3'
                style={{ textDecoration: 'underline', color: palette.red400 }}
                onClick={(e) => navigateToEquipmentProfile(e, rowData.driver?.equipment?.[0]?.id)}
              >
                {rowData.driver?.equipment?.[0]?.equipment_id} {rowData.driver?.equipment?.[0]?.equipment_type?.title}
              </Typography>
              <Tooltip
                title='Driver HOS vehicle does not match what the driver is assigned in the system'
                placement='top'
                componentsProps={{
                  placement: 'top',
                  tooltip: {
                    sx: {
                      fontSize: 13,
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                      maxWidth: 'unset',
                      cursor: 'pointer',
                    },
                  },
                }}
              >
                <WarningIcon fill={palette.red400} />
              </Tooltip>
            </div>
          )}
          {!rowData.driver?.equipment && (
            <p
              className={classes.underline_text}
              onClick={(e) => {
                e.stopPropagation();
                setAssignToDriverId(rowData.driver_id);
              }}
            >
              Assign Vehicle
            </p>
          )}
        </div>
      ),
    },
    {
      field: 'time_until_break',
      title: <ColumnHeader title='Time until brake' field='time_until_break' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.time_until_break || '-'}
        </p>
      ),
    },
    {
      field: 'drive_remaining',
      title: <ColumnHeader title='Drive Remaining' field='drive_remaining' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.drive_remaining || '-'}
        </p>
      ),
    },
    {
      field: 'shift_remaining',
      title: <ColumnHeader title='Shift Remaining' field='shift_remaining' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.shift_remaining || '-'}
        </p>
      ),
    },
    {
      field: 'cycle_remaining_duration',
      title: (
        <ColumnHeader title='Cylcle Remaining' field='cycle_remaining_duration' sort={sort} onClick={sortingQuery} />
      ),
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.cycle_remaining_duration || '-'}
        </p>
      ),
    },
    {
      field: 'cycle_tomorrow_duration',
      title: (
        <ColumnHeader title='Cylcle Tomorrow' field='cycle_tomorrow_duration' sort={sort} onClick={sortingQuery} />
      ),
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.cycle_tomorrow_duration || '-'}
        </p>
      ),
    },
    {
      field: 'shift_driving_violation_duration',
      title: (
        <ColumnHeader
          title='Driving In Violation'
          field='shift_driving_violation_duration'
          sort={sort}
          onClick={sortingQuery}
        />
      ),
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.shift_driving_violation_duration ? rowData?.shift_driving_violation_duration : '-'}
        </p>
      ),
    },
  ];
};
