import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  min-width: 350px;
  padding: 24px 0 0 20px;

  .manage-balance {
    cursor: pointer;
    width: fit-content;
    margin-right: 8px;
  }

  .manage-balance:hover {
    text-decoration: underline;
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  :hover span {
    text-decoration: underline;
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 12px;
    white-space: nowrap;
  }

  td {
    padding: 12px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.blue-border td {
      border-top: 1px solid ${palette.indigo500};
    }
    &.red-background {
      background-color: ${palette.red0};
    }
    :has(+ tr.blue-border) td {
      border-bottom: 1px solid ${palette.indigo500};
    }

    &.clickable {
      cursor: pointer;

      :hover {
        background-color: ${palette.gray0};
      }
    }
  }

  .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root.Mui-disabled,
  .react-datepicker-wrapper .custom-date-picker-input {
    background-color: ${palette.white} !important;
  }

  .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root.Mui-disabled .MuiInputBase-input {
    -webkit-text-fill-color: ${palette.gray700};
  }

  input {
    &:not(input[type='number']) {
      :disabled {
        background-color: ${palette.white};
      }
    }
  }
`;
export const STableHeader = styled.th`
  width: ${({ $width }) => $width || ''};
`;
export const STableData = styled.td`
  width: ${({ $width }) => $width || ''};

  .amount-disabled input {
    background-color: ${palette.gray0};
  }

  .split-input {
    display: flex;
    border-radius: 6px;
    width: 296px;
    padding: 2px 8px;
    box-shadow: ${palette.boxShadow2};
  }
`;

export const SText = styled.p`
  color: ${palette.gray700};
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0;

  .dots {
    font-size: 9px;
  }
`;

export const SFiltersWrapper = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .transaction-search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const STransactionsTableWrapper = styled.div`
  overflow: auto;

  .table-container {
    width: max-content;
    min-width: 100%;

    &.fixed-header {
      height: ${({ $height }) => $height || 'calc(100vh - 294px)'};

      thead {
        th {
          box-shadow: inset 0 -3px 0 -2px ${palette.gray50};
          background-color: ${palette.white};
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }
  }

  .scroll-div {
    width: 10px;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 8px;
  }
`;

export const SActions = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  column-gap: 8px;

  .loading-wrapper {
    display: inline-block;
    height: 27px;
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }

  .MuiCircularProgress-root {
    margin: 5px 5px 0 0;
  }
`;
