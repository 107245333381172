import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SMapWidget = styled.div`
  top: 10px;
  left: 10px;
  width: ${({ $minimize }) => ($minimize ? '38px' : '626px')};
  max-width: 50%;
  min-width: 576px;
  height: ${({ $minimize }) => ($minimize ? '34px' : 'calc(100% - 28px)')};
  overflow-y: auto;
  padding: 24px 20px;
  position: absolute;
  border-radius: 8px;
  overflow-x: hidden;
  background: ${palette.white}BB;
  backdrop-filter: blur(4px);
  box-shadow: 0 0 0 1px rgba(152, 161, 178, 0.1), 0 1px 4px rgba(69, 75, 87, 0.12), 0 0 2px rgba(0, 0, 0, 0.08);
  z-index: 3;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    min-width: auto;
    max-width: 95%;
    padding: ${({ $minimize }) => ($minimize ? '0' : '24px 12px')};
  }
`;

export const SMinimizeButton = styled.div`
  position: absolute;
  top: 0;
  left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`;
