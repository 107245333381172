import React from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { palette } from 'utils/constants';
import { uploadFmcsaRecordFile } from 'Api/DriverV2';
import { SAddMore, SFlexWrapper } from './MedicalAndBackgroundTab.styles';

const MultipleDocuments = ({ values, handleChange, driverId, index }) => {
  const uploadDocument = async (file) => {
    try {
      const formData = new FormData();
      formData.append('driver_id', driverId);
      formData.append('attach_fmcsa_result', file[0]);
      const { data } = await uploadFmcsaRecordFile(formData);
      handleChange(`fmcsaResults[${index}].attach_fmcsa_result`, [
        ...(values?.fmcsaResults?.[index]?.attach_fmcsa_result || []),
        data.file_path,
      ]);
    } catch (e) {
      // Do nothing
    }
  };

  const onAttachmentDelete = (e, index) => {
    e.stopPropagation();
    const newDocs = values?.fmcsaResults?.[index]?.attach_fmcsa_result.filter((item, i) => i !== index);
    handleChange(`fmcsaResults[${index}].attach_fmcsa_result`, newDocs);
  };

  return (
    <div>
      <div>
        <SFlexWrapper>
          {values?.fmcsaResults?.[index]?.attach_fmcsa_result?.map((image, index) => (
            <div key={image?.id || index}>
              <p className='attachment-wrapper' style={{ backgroundColor: palette.indigo0 }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: palette.green400 }}>
                  File {index + 1}
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={(e) => onAttachmentDelete(e, index)}
                />
              </p>
            </div>
          ))}
          <SAddMore className='mt-2'>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
              {values?.fmcsaResults?.[index]?.attach_fmcsa_result?.length ? 'Add Another...' : 'Attach Results'}
            </Typography>
            <ImageDropZone
              multiple
              onDrop={(files) => uploadDocument(files)}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              accept={['application/pdf', 'image/jpeg', 'image/png']}
            />
          </SAddMore>
        </SFlexWrapper>
      </div>
    </div>
  );
};

export default MultipleDocuments;
