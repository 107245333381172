import React from 'react';
import { useNavigate } from 'react-router-dom';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { SHeaderWrapper } from './PaystubModal.styles';

const Header = ({ settlement }) => {
  const navigate = useNavigate();
  const { formatDate } = useDateFormat();

  const { user } = settlement || {};

  const onUserClick = () => {
    if (!user?.id) {
      return null;
    }

    navigate(`/driver-profile/${user.id}/general`);
  };

  return (
    <SHeaderWrapper>
      <div className='profile-image-container' onClick={onUserClick}>
        <img
          src={settlement?.user?.image || settlement?.user?.profile_logo || userDefault}
          alt=''
          width={32}
          height={32}
        />
      </div>
      <Typography variant='button2' style={{ cursor: 'pointer' }} onClick={onUserClick}>
        {settlement?.user?.fname || settlement?.user?.first_name}{' '}
        {settlement?.user?.lname || settlement?.user?.last_name}
      </Typography>
      -<Typography variant='button2'>{settlement?.id}</Typography>-
      <Typography variant='button2'>
        {formatDate(settlement?.pay_period_start)} to {formatDate(settlement?.pay_period_end)}
      </Typography>
      {settlement?.user?.email && (
        <Typography variant='button2' style={{ position: 'absolute', right: '54px' }}>
          <NoteIcon fill={palette.gray500} /> System has sent this settlement via email to {settlement.user.email}
        </Typography>
      )}
    </SHeaderWrapper>
  );
};

export default Header;
