import axios from 'services/axios';

export async function getShipmentInfo(params) {
  const { data } = await axios.get(`/customer/shipments/info`, { params });
  return data;
}

export async function getMultipleShipmentsInfo(params) {
  const { data } = await axios.get(`/customer/multiple-shipments/info`, { params });
  return data;
}

export async function getShipmentStops(params) {
  const { data } = await axios.get(`/shipment/shipment-stops`, { params });
  return data;
}

export async function getShipmentStopsList(params) {
  const { data } = await axios.get(`/customer/shipments/stops`, { params });
  return data;
}
export async function addShipmentStop(body) {
  const { data } = await axios.post(`customer/shipment-stop`, body);
  return data;
}

export async function swapShipmentStops(body) {
  const { data } = await axios.post(`customer/shipment-stops/swap`, body);
  return data;
}

export async function updateShipmentStop(id, body) {
  const { data } = await axios.put(`/customer/shipment-stop/${id}`, body);
  return data;
}

export async function deleteShipmentStop(id) {
  const { data } = await axios.delete(`customer/shipment-stop/${id}`);
  return data;
}

export async function getAbsenceData(driverId) {
  const { data } = await axios.get(`/absence-data/driver/${driverId}`);
  return data;
}

export async function nearbyVehicles(params, cancelToken) {
  const { data } = await axios.get('/shipments/nearby-vehicles', { params, cancelToken });
  return data;
}

export async function createShipmentOfferOptions(shipmentId, body) {
  const { data } = await axios.post(`/customer/shipments/${shipmentId}/offer-options`, body);
  return data;
}

export async function getShipmentOfferOptions(shipmentId) {
  const { data } = await axios.get(`/customer/shipments/${shipmentId}/offer-options`);
  return data;
}

export async function sendCarrierOffer(body) {
  const { data } = await axios.post(`/customer/shipments/shipment-carrier-offers`, body);
  return data;
}

export async function getShipmentCarrierOffers(params) {
  const { data } = await axios.get(`/customer/shipments/shipment-carrier-offers`, { params });
  return data;
}

export async function getShipmentOffer(params, headers) {
  const { data } = await axios.get(`/carrier/shipment/offer`, { params, headers });
  return data;
}

export async function getOfferContactUser(params, headers) {
  const { data } = await axios.get(`/carrier/shipment/offer/contact-user`, { params, headers });
  return data;
}

export async function getStates(params) {
  const { data } = await axios.get(`/search-states`, { params });
  return data;
}

export async function getCities(params) {
  const { data } = await axios.get(`/search-cities`, { params });
  return data;
}

export async function getEta(params, headers) {
  const { data } = await axios.get(`/carrier/shipment/offer/calculation`, { params, headers });
  return data;
}

export async function getDriverAvailability(params, signal) {
  const { data } = await axios.get(`/customer/drivers-availability`, { params, signal });
  return data;
}

export async function getAvailableDrivers(params, signal) {
  const { data } = await axios.get(`/customer/drivers-available`, { params, signal });
  return data;
}

export async function getBackhaul(params, signal) {
  const { data } = await axios.get(`/customer/backhaul-shipments`, { params, signal });
  return data;
}
