import React from 'react';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { formatAmount } from 'services/IFTAServices';
import useDateFormat from 'hooks/useDateFormat';

const fuelTypeUnit = {
  Electric: '/KILOWATTHOUR',
  'Natural Gas': '/MCF',
};

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useColumns = ({ sortingQuery, sort }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'date',
      title: <ColumnHeader title='DATE' field='date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          {formatDate(row.date)}
        </Typography>
      ),
    },
    {
      field: 'price',
      title: <ColumnHeader title='PRICE' field='price' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          ${formatAmount(row.price)} {fuelTypeUnit[row.type] || '/GAL'}
        </Typography>
      ),
    },
    {
      field: 'type',
      title: <Title title='TYPE' />,
      render: (row) => <Chip label={row.type} />,
    },
    {
      field: 'series',
      title: <Title title='SERIES' />,
    },
    {
      field: 'product',
      title: <Title title='PRODUCT' />,
    },
  ];
};
