import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

const AlreadyDispatchedModal = ({ open, onClose, headerTitle, text, disabled, width, buttonProps }) => {
  const navigate = useNavigate();
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={headerTitle || `Error`}
      $maxWidth={width || '470px'}
      $minWidth={width || '470px'}
      backdrop='static'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'submit',
          type: 'secondary',
          title: 'Okay',
          onClick: () => navigate('/planner'),
          disabled,
          ...buttonProps,
        },
      ]}
    >
      <Typography variant='s1' as='p' className='mt-3 mb-3' style={{ color: palette.gray700 }}>
        {text || `<user name> has already dispatched this shipment, we are now exiting the dispatch process!}?`}
      </Typography>
    </Modal>
  );
};

export default AlreadyDispatchedModal;
