import { array, number, object, string } from 'yup';

export const validationSchema = object().shape({
  cargos: array().of(
    object().shape({
      reported_quantity: number().required('Required').nullable(),
      reported_weight: number().required('Required').nullable(),
    })
  ),
  bill_lading_id: string().trim().required('Required').nullable(),
});
