import styled from 'styled-components';

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
