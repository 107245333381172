import React from 'react';
import { palette } from 'utils/constants';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download2.svg';
import { SCustomModal } from './DriverLicenseModal.styles';

const DriverLicenseModal = ({ open, onClose, license, title = 'Driver License' }) => {
  const onDownload = () => {
    fetch(license, { mode: 'no-cors' })
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = new Blob([resp], { type: 'application/png' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(file);
        link.setAttribute('download', 'download');
        link.href = url;
        link.download = `${title}.png`;
        link.click();
      });
  };

  return (
    <SCustomModal showModal={open} onHide={onClose} headerTitle={title}>
      {title === 'Driver License' && (
        <DownloadIcon className='download-modal-icon' fill={palette.gray700} onClick={onDownload} />
      )}
      <CloseIcon className='close-modal-icon' fill={palette.red500} onClick={onClose} />
      <div
        style={{
          display: 'flex',
          height: '60vh',
          backgroundImage: `url(${license})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderRadius: '6px',
        }}
      />
    </SCustomModal>
  );
};

export default DriverLicenseModal;
