import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Chart } from 'react-chartjs-2';
import moment from 'moment';
import { millisecondsToInterval } from 'utils/helpers';
import { palette } from 'utils/constants';
import { statues } from '../CustomCollapsibleRow/CustomCollapsibleRow.data';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  Legend
);

const hosStatusTypes = {
  offDuty: 'OFF',
  sleeperBed: 'SB',
  driving: 'D',
  onDuty: 'ON',
  yardMove: 'ON',
  personalConveyance: 'OFF',
  disconnected: 'OFF',
  waitingTime: 'OFF',
  intLocation: 'OFF',
};

const sortLogEntriesByStartTime = (a, b) => {
  return moment(a.start_time).diff(moment(b.start_time));
};

const calculateDuration = (start_time, end_time) => {
  const startDate = new Date(start_time);
  const endDate = new Date(end_time);

  const durationMs = endDate - startDate;

  return millisecondsToInterval(durationMs);
};

const processData = (logEntries) => {
  const sortedLogEntries = logEntries.sort(sortLogEntriesByStartTime);

  const hosEntries = sortedLogEntries.flatMap((entry) => {
    const status = hosStatusTypes[entry.hos_status_type] || 'Unknown';
    return [
      {
        x: moment(entry.start_time).toISOString(),
        y: status,
        status: entry.hos_status_type,
        duration: calculateDuration(entry.start_time, entry.end_time),
      },
      {
        x: moment(entry.end_time).toISOString(),
        y: status,
        status: entry.hos_status_type,
        duration: calculateDuration(entry.start_time, entry.end_time),
      },
    ];
  });

  const filteredHosEntries = [];
  for (let i = 0; i < hosEntries.length - 1; i++) {
    if (
      hosEntries[i].x === hosEntries[i + 1].x &&
      hosEntries[i].y === hosEntries[i + 1].y &&
      hosEntries[i].status === hosEntries[i + 1].status &&
      hosEntries[i].duration !== hosEntries[i + 1].duration
    ) {
      filteredHosEntries.push(hosEntries[i]);
      i++;
    } else {
      filteredHosEntries.push(hosEntries[i]);
    }
  }

  filteredHosEntries.push(hosEntries[hosEntries.length - 1]);

  return filteredHosEntries;
};

const findDateBounds = (logEntries) => {
  if (!logEntries.length) return { start: moment().startOf('day'), end: moment().startOf('day').add(1, 'day') };

  const sortedLogEntries = logEntries.sort(sortLogEntriesByStartTime);
  const start = moment(sortedLogEntries[0].start_time).startOf('day');
  const end = moment(sortedLogEntries[0].start_time).add(1, 'day').startOf('day');

  return { start, end };
};

export function TimeLineChart({ detailedLogs }) {
  const { start, end } = findDateBounds(detailedLogs);

  const options = {
    scales: {
      x: {
        type: 'time',
        position: 'top',
        time: {
          unit: 'hour',
          tooltipFormat: 'HH:mm',
          displayFormats: {
            hour: 'HH:mm',
          },
        },
        min: start.toISOString(),
        max: end.toISOString(),
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        type: 'category',
        labels: ['OFF', 'SB', 'D', 'ON'],
        title: {
          display: true,
          text: '',
        },
      },
    },
    plugins: {
      fillBackgroundPlugin: true,
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label(context) {
            let label = '';

            if (context.dataIndex !== null) {
              const status = context.dataset.data?.[context.dataIndex]?.status;
              label += `\n${statues[status]?.name || ''}`;
            }
            if (context.dataIndex !== null) {
              const duration = context.dataset.data?.[context.dataIndex]?.duration;
              label += `\n${duration || ''}`;
            }
            return label;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  const dynamicData = {
    datasets: [
      {
        label: 'Driver Status',
        data: processData(detailedLogs),
        borderColor: palette.indigo500,
        backgroundColor: palette.indigo0,
      },
    ],
  };
  return (
    <div style={{ width: '100%', height: '300px' }}>
      <Chart type='line' data={dynamicData} options={options} />
    </div>
  );
}
