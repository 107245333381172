import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import styles from './CustomButton.module.css';

const CustomButton = forwardRef(
  (
    {
      field,
      onClick,
      type,
      title,
      rightIcon,
      leftIcon,
      disabled,
      styleButton,
      styleTitle,
      buttonProps,
      hover = true,
      ...props
    },
    ref
  ) => {
    const { use } = useTheme();

    const onClickHandler = (e) => {
      e.preventDefault();
      if (onClick && typeof onClick === 'function') {
        !disabled && onClick(e);
      }
    };

    const className = useMemo(() => {
      const cls = [styles.button_wrapper, styles[type]];
      !!disabled && cls.push(styles.disabled);
      return cls.join(' ');
    }, [type, disabled]);

    return (
      <button
        ref={ref}
        {...field}
        {...props}
        disabled={disabled}
        onClick={onClickHandler}
        className={classNames([className, props.className, { [styles.hovered]: !!hover }])}
        style={{
          background: type === 'secondary' && use(palette.white, palette.dark800),
          boxShadow:
            type === 'secondary' &&
            use(
              ' 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1)',
              '0 -1px 0 rgba(255, 255, 255, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.52), 0 2px 4px rgba(0, 0, 0, 0.16)'
            ),
          ...styleButton,
        }}
        {...buttonProps}
      >
        {leftIcon && leftIcon}
        <span
          className={styles.button_title}
          style={{
            color: type === 'secondary' && use(palette.gray700, palette.white),
            ...styleTitle,
          }}
        >
          {title}
        </span>
        {rightIcon && rightIcon}
      </button>
    );
  }
);

CustomButton.defaultProps = {
  type: 'primary',
};

export default CustomButton;
