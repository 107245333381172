import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  margin-bottom: 24px;

  .accordion-arrow {
    transform: rotate(180deg);

    path {
      fill: ${palette.gray400};
    }
  }
`;

export const SAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    .MuiAccordionSummary-root {
      flex-direction: row-reverse;
      min-height: auto;
      width: fit-content;
      padding: 0;

      .MuiAccordionSummary-content {
        margin: 4px 0 4px 12px;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0 0 0 20px;
    }
  }
`;

export const STableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${palette.gray50};
`;
