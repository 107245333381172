import styled from 'styled-components';

export const SWrapper = styled.div`
  display: flex;

  .column-title {
    cursor: pointer;
    user-select: none;
  }

  .sort-icons {
    opacity: 0;
    display: flex;
    margin-left: 5px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 2px;
    transition: 0.2s all ease-in-out;
  }

  :hover .sort-icons {
    opacity: 1;
  }
`;
