import { object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  license_no: string().trim().required('Required'),
  license_exp_dt: validator.futureRequiredDate(),
  status: object()
    .shape({ value: string().required('Required') })
    .required('Required')
    .nullable(),
  driver_licence_upload: string().required('Required').nullable(),
  driver_licence_back_upload: string().required('Required').nullable(),
});
