import { array, number, object } from 'yup';

export const validationSchema = object({
  shipment_addition: array().when(['pay_on_each_shipment'], {
    is: (pay_on_each_shipment) => !!pay_on_each_shipment,
    then: array().of(
      object().shape({
        payroll_addition: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        amount: number()
          .required('Required')
          .nullable()
          .test('Required', 'Required', (value) => value > 0),
      })
    ),
  }),
  // pay_raise_per_mile_loaded: number().required('Required').nullable(),
  // paid_time_off_amount: number().when('paid_time_off_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // detention_amt: number().when('detention_pay_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // layover_amt: number().when('layover_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // per_stop_amt: number().when('stopoff_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // unload_amt: number().when('live_unload_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // each_truck_order_unused_amt: number().when('truck_order_unused_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // after_breakdown_hrs: number().when('breakdown_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // each_stop_amt: number().when('tarp_pay_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // after_first_attempt_amt: number().when('redelivery_option', {
  //   is: true,
  //   then: number().required('Required').test('Required', 'Required', (value) => value > 0).nullable(),
  // }),
  // pay_raise: array().when('is_pay_raise', {
  //   is: true,
  //   then: array().of(
  //     object().shape({
  //       empty: number().required('Required').nullable().test('Required', 'Required', (value) => value > 0).nullable(),
  //       loaded: number().required('Required').nullable().test('Required', 'Required', (value) => value > 0).nullable(),
  //       pay_raise_schedule_days: number().required('Required').nullable()
  //         .test('Required', 'Required', (value) => value > 0).nullable(),
  //       pay_raise_schedule_months: number().required('Required').nullable()
  //         .test('Required', 'Required', (value) => value > 0).nullable(),
  //       pay_raise_schedule_years: number().required('Required').nullable()
  //         .test('Required', 'Required', (value) => value > 0).nullable(),
  //     })),
  // }),
});
