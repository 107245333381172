import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SBoxContent } from 'pages/Dashboard/Dashboard.styles';
import { getPastDueBills } from 'Api/Payables';
import { SListWrapper } from './PastDueBills.styles';

const PastDueBills = () => {
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [pastDueBills, setPastDueBills] = useState([]);
  const { total, totalAmount, data } = pastDueBills || {};

  const getPastDueBillsData = async () => {
    setLoading(true);
    try {
      const response = await getPastDueBills({ itemsPerPage: 1000 });
      const totalAmount = response?.data.reduce((acc, cur) => {
        acc += Number(cur.amount_due);
        return acc;
      }, 0);
      setPastDueBills({ ...response, totalAmount });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPastDueBillsData();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <SBoxContent style={{ rowGap: '8px', maxHeight: '450px', overflowY: 'scroll' }}>
        <div className='mb-2'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Past Due Bills
          </Typography>
        </div>
        <div className='d-flex justify-content-between'>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          ) : (
            <div>
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                {total} Bills
              </Typography>
            </div>
          )}
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          ) : (
            <div>
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                {currency}
                {formatNumber(totalAmount)}
              </Typography>
            </div>
          )}
        </div>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <SListWrapper>
            {data?.map((item) => (
              <div key={item.id} className='list-item'>
                <Typography variant='b2' style={{ color: palette.gray900 }}>
                  {item.reference_id}
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {currency}
                  {formatNumber(item.amount_due)}
                </Typography>
              </div>
            ))}
          </SListWrapper>
        )}
      </SBoxContent>
    </div>
  );
};

export default PastDueBills;
