import React, { useState } from 'react';
import moment from 'moment/moment';
import Modal from 'common/Modal';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { UpdateStatus } from 'Api/Driver';
import { validationSchema } from './validationSchema';

const DriverEmploymentStatus = ({ open, onClose, setDriverStatus, driverId, driverStatus }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      if (values.employment_status === 'Active') {
        const body = { status: 1, status_title: 'Available' };
        const { data } = await UpdateStatus(body, driverId);
        setDriverStatus(data);
      } else {
        if (values.status === 5) {
          const body = {
            status: values.status,
            status_title: values.status_title,
            terminated_note: values.note,
            terminated_date_time: moment(values.date_time || new Date()).format('YYYY-MM-DD HH:mm:ss'),
            rehire_status: values.rehire_status,
          };
          const { data } = await UpdateStatus(body, driverId);
          setDriverStatus(data);
        } else {
          const body = {
            status: values.status,
            status_title: values.status_title,
            quit_note: values.note,
            quite_date_time: moment(values.date_time || new Date()).format('YYYY-MM-DD HH:mm:ss'),
            rehire_status: values.rehire_status,
          };
          const { data } = await UpdateStatus(body, driverId);
          setDriverStatus(data);
        }
      }
      showToaster({ type: 'success', message: 'Driver Employment Status has been successfully updated!' });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, touchedErrors, setValues, handleBlur } = useForm({
    initialValues: {
      employment_status:
        driverStatus?.status_title === 'Terminated' || driverStatus?.status_title === 'Quit' ? 'Inactive' : 'Active',
      status: 5,
      status_title: 'Terminated',
      note: '',
      date_time: new Date(),
      rehire_status: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Update Employment Status'
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdrop='static'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex flex-column'>
          <InputLabel className='mb-0' required>
            Employment Status
          </InputLabel>
          <CustomRadioButton
            field={{
              name: 'employment_status',
              value: 'Active',
              onChange: () => handleChange('employment_status', 'Active'),
              checked: values.employment_status === 'Active',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Active
            </Typography>
          </CustomRadioButton>
          <CustomRadioButton
            field={{
              name: 'employment_status',
              value: 'Active',
              onChange: () => handleChange('employment_status', 'Inactive'),
              checked: values.employment_status === 'Inactive',
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Inactive
            </Typography>
          </CustomRadioButton>
        </div>
        {values.employment_status === 'Inactive' && (
          <>
            <div className='d-flex flex-column'>
              <InputLabel className='mb-0' required>
                Driver Status
              </InputLabel>
              <CustomRadioButton
                field={{
                  name: 'status',
                  value: 5,
                  onChange: () => {
                    setValues((prevState) => ({
                      ...prevState,
                      status: 5,
                      status_title: 'Terminated',
                    }));
                  },
                  checked: values.status === 5,
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  Terminated
                </Typography>
              </CustomRadioButton>
              <CustomRadioButton
                field={{
                  name: 'status',
                  value: 6,
                  onChange: () => {
                    setValues((prevState) => ({
                      ...prevState,
                      status: 6,
                      status_title: 'Quit',
                    }));
                  },
                  checked: values.status === 6,
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  Quit
                </Typography>
              </CustomRadioButton>
              <ErrorMessage error={touchedErrors.status} />
            </div>
            <div>
              <InputLabel className='mb-0' required>
                {values.status === 5 ? 'Termination Date' : 'Quit Date'}
              </InputLabel>
              <DateTimePicker
                name='date_time'
                value={values.date_time}
                onChange={(date) => handleChange('date_time', date)}
                onBlur={handleBlur}
                disablePast
                error={touchedErrors.date_time}
              />
            </div>
            <div>
              <InputLabel className='mb-0' required>
                Notes
              </InputLabel>
              <textarea
                className='text-area'
                name='note'
                id='note'
                onChange={handleChange}
                value={values.note}
                style={{
                  background: palette.white,
                  color: palette.gray900,
                }}
              />
              <ErrorMessage error={touchedErrors.note} />
            </div>
            <div className='d-flex flex-column'>
              <InputLabel className='mb-0' required>
                Eligible for rehire?
              </InputLabel>
              <CustomRadioButton
                field={{
                  name: 'rehire_status',
                  value: 'Yes',
                  onChange: () => handleChange('rehire_status', 'Yes'),
                  checked: values.rehire_status === 'Yes',
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  Yes
                </Typography>
              </CustomRadioButton>
              <CustomRadioButton
                field={{
                  name: 'rehire_status',
                  value: 'No',
                  onChange: () => handleChange('rehire_status', 'No'),
                  checked: values.rehire_status === 'No',
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  No
                </Typography>
              </CustomRadioButton>
              <CustomRadioButton
                field={{
                  name: 'rehire_status',
                  value: 'Upon Review',
                  onChange: () => handleChange('rehire_status', 'Upon Review'),
                  checked: values.rehire_status === 'Upon Review',
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  Upon Review
                </Typography>
              </CustomRadioButton>
              <ErrorMessage error={touchedErrors.rehire_status} />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DriverEmploymentStatus;
