import { getInitialValues as getQuoteInitialValues } from 'componentsV2/Planner/Quotes/CreateQuote/CreateQuote.data';

export const getInitialValues = (quotes) => {
  const groupedQuotes = [];

  quotes.forEach((quote) => {
    const { billing, quote_id, id } = quote;

    const { customer } = billing || {};

    const quoteData = {
      ...getQuoteInitialValues(quote),
      id,
      quote_id,
      quote_date: new Date(),
    };

    const data = {
      customer,
      quotes: [quoteData],
    };

    const customerIndex = groupedQuotes.findIndex((item) => item.customer.id === customer.id);

    if (customerIndex === -1) {
      groupedQuotes.push(data);
    } else {
      groupedQuotes[customerIndex].quotes.push(quoteData);
    }
  });

  return {
    send_email: false,
    print_mail: false,
    customers: groupedQuotes,
  };
};
