import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getAppUrl } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { SShipmentsWarning } from './ShipmentsWarning.styles';

const ShipmentsWarning = ({ shipments }) => {
  const { formatDateTime } = useDateFormat();

  const onShipmentClick = (shipment) => {
    const url = getAppUrl();
    window.open(`${url}/shipment/${shipment.shipment_id}`, '_blank');
  };

  return (
    <SShipmentsWarning>
      <div className='mb-4'>
        <Typography variant='h4' style={{ color: palette.red400 }}>
          Warning!
        </Typography>
      </div>
      <div className='mb-5'>
        <Typography variant='s2'>
          This driver has {shipments.length} shipment{shipments.length > 1 ? 's' : ''} planned. By approving this
          request the system will revert the shipment{shipments.length > 1 ? 's' : ''} back to planner and remove the
          driver from the shipment{shipments.length > 1 ? 's' : ''}.
        </Typography>
      </div>
      <div>
        <Typography variant='h5' className='d-inline-block mb-2'>
          Planned Shipments
        </Typography>
        {shipments.map((shipment) => (
          <div key={shipment.id} className='mb-1'>
            <Typography variant='b2' onClick={() => onShipmentClick(shipment)} className='pointer'>
              Shipment {shipment.shipment_id} -{' '}
              {formatDateTime(`${shipment.shipment_stops[0].scheduled_date} ${shipment.shipment_stops[0].from}`)} -{' '}
              {shipment.stops_count} stops - {shipment.shipment_stops[0]?.stop_point?.city?.name},{' '}
              {shipment.shipment_stops[0]?.stop_point?.state?.short_name} to{' '}
              {shipment.shipment_stops[shipment.shipment_stops.length - 1]?.stop_point?.city?.name},{' '}
              {shipment.shipment_stops[shipment.shipment_stops.length - 1]?.stop_point?.state?.short_name}
            </Typography>
          </div>
        ))}
      </div>
    </SShipmentsWarning>
  );
};

export default ShipmentsWarning;
