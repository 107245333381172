import React, { useMemo, useState } from 'react';
import { usePagination } from 'hooks/usePagination';
import './CustomPagination.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';

const CustomPagination = ({ pageSize, totalCount, currentPage, onPageChange, lastPage, hideDropdown = true }) => {
  const { use } = useTheme();

  const [isFocused, setIsFocused] = useState(false);
  const { paginationRange } = usePagination({ totalCount, pageSize });
  const paginationRangeWithClones = useMemo(() => {
    return [...paginationRange.slice(-5), ...paginationRange, ...paginationRange.slice(0, 5)];
  }, [paginationRange]);

  const translateStyles = useMemo(() => {
    const count = lastPage > 9 ? 2 : 1;
    const translateX = (currentPage + count) * 32;

    return {
      transition: `${currentPage === 1 || currentPage === lastPage ? 0 : 0.2}s all ease`,
      transform: `translateX(-${translateX}px)`,
    };
  }, [currentPage, lastPage]);

  const onNext = () => {
    const page = currentPage >= lastPage ? 1 : currentPage + 1;
    onPageChange(page);
  };

  const onPrevious = () => {
    const page = currentPage <= 1 ? lastPage : currentPage - 1;
    onPageChange(page);
  };

  const onMouseOver = () => {
    setIsFocused(true);
  };

  const onMouseLeave = () => {
    setIsFocused(false);
  };

  return (
    <ul className='pagination-container' onMouseLeave={onMouseLeave} onMouseOver={onMouseOver}>
      <li
        className='pagination-item'
        style={{ boxShadow: use('0 0 0 1px rgba(70, 79, 96, 0.16)', '0 0 0 1px rgba(70, 79, 96, 1)') }}
        onClick={onPrevious}
      >
        <div className='arrow left' />
      </li>
      {!hideDropdown && (
        <div style={{ display: 'flex', alignItems: 'center' }} className='hover_container'>
          {!isFocused && (
            <span className='text_pagination' style={{ color: use(palette.gray900, palette.white) }}>
              {currentPage}
              <span className='pagination_count'>/{lastPage}</span>
            </span>
          )}

          <div
            className={`hidden_wrapper  ${totalCount / pageSize > 10 ? 'small_width' : ''}`}
            style={{ width: isFocused ? lastPage * 32 : 0 }}
          >
            <div className='transform_container' style={translateStyles}>
              {paginationRangeWithClones.map((pageNumber) => {
                return (
                  <li
                    key={pageNumber}
                    className={`pagination-item  ${pageNumber === currentPage ? 'selected' : 'doestSelect'}`}
                    style={{ color: pageNumber !== currentPage && use(palette.gray700, palette.white) }}
                    onClick={() => onPageChange(pageNumber)}
                  >
                    {pageNumber}
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <li
        className='pagination-item'
        style={{ boxShadow: use('0 0 0 1px rgba(70, 79, 96, 0.16)', '0 0 0 1px rgba(70, 79, 96, 1)') }}
        onClick={onNext}
      >
        <div className='arrow right' />
      </li>
    </ul>
  );
};

export default CustomPagination;
