import React from 'react';
import { WhiteAlert } from 'assets/icons';

const ReservedEquipmentMsg = ({ handleClose, reserveMsgData }) => {
  return (
    <div className='d-flex '>
      <div className='ms-3'>
        <div className='d-flex main-wrapper-eq-reserved mt-3 align-item-center'>
          <span className='me-2'>{WhiteAlert}</span>
          <div>
            <p className='eq-reserved ms-2 mb-0 '>Equipment Reserved</p>
            <p className='mt-0 eq-reserved-para mb-0'>
              {' '}
              Reserved by {reserveMsgData?.data?.user?.first_name} {reserveMsgData?.data?.user?.last_name} for{' '}
              {reserveMsgData?.data?.duration} {reserveMsgData?.data?.type}
            </p>
          </div>
        </div>
      </div>
      <div>
        <button className='eq-reserved-okay-msg mt-4 ms-2' onClick={handleClose}>
          Okay
        </button>
      </div>
    </div>
  );
};

export default ReservedEquipmentMsg;
