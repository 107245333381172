import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SDocuments = styled.div`
  padding: 16px 0;

  .documents-title {
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    background-color: ${palette.white};
  }

  .suggestions {
    margin-top: 56px;
  }
`;
