import styled from 'styled-components';

export const SNavigationWrapper = styled.div`
  height: ${({ $isCondensed }) => ($isCondensed ? 'calc(100% - 176px)' : 'calc(100% - 225px)')};
  overflow: auto;
  width: 100%;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;
