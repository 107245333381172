import moment from 'moment';
import axios from '../services/axios';

export async function Planner({
  page,
  itemsPerPage,
  sort,
  filtersType,
  filtersValue,
  remainingData,
  allFilterData,
  shipmentIds,
  signal,
}) {
  const params = {
    page,
    itemsPerPage,
  };
  if (!allFilterData) {
    if (filtersValue) params[`filters[${filtersType}]`] = filtersValue;
    if (typeof remainingData === 'object') {
      Object.keys(remainingData).forEach((key) => {
        if (filtersType !== key) {
          const value = remainingData[key];
          if (value) params[`filters[${key}]`] = value;
        }
      });
    }
  } else {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      params[`filters[${key}]`] = value;
    });
  }

  if (sort?.length > 0) {
    sort.forEach((el) => {
      const arr = el.split(':');
      params[`${arr[0]}`] = arr[1];
    });
  }

  if (shipmentIds?.length) {
    params[`filters[shipment_ids]`] = shipmentIds;
  }

  const { data } = await axios.get('/customer/shipments', {
    signal,
    params,
  });
  return data;
}

export async function getPlannerTotal({
  page,
  itemsPerPage,
  sort,
  filtersType,
  filtersValue,
  remainingData,
  allFilterData,
  shipmentIds,
  signal,
}) {
  const params = {
    page,
    itemsPerPage,
  };
  if (!allFilterData) {
    if (filtersValue) params[`filters[${filtersType}]`] = filtersValue;
    if (typeof remainingData === 'object') {
      Object.keys(remainingData).forEach((key) => {
        if (filtersType !== key) {
          const value = remainingData[key];
          if (value) params[`filters[${key}]`] = value;
        }
      });
    }
  } else {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      params[`filters[${key}]`] = value;
    });
  }

  if (sort?.length > 0) {
    sort.forEach((el) => {
      const arr = el.split(':');
      params[`${arr[0]}`] = arr[1];
    });
  }

  if (shipmentIds?.length) {
    params[`filters[shipment_ids]`] = shipmentIds;
  }

  const { data } = await axios.get('/customer/results/planner', {
    signal,
    params,
  });
  return data;
}

export async function getPlannerPopoverSettings({ type }) {
  const { data } = await axios.get('/table-settings', {
    params: {
      type,
    },
  });
  return data;
}

export async function UpdatePlannerPopoverSettings(dataJson) {
  const { data } = await axios.put('/table-settings', dataJson);
  return data;
}

export async function getPlannerChild({ id }) {
  const { data } = await axios.get(`/customer/shipment/${id}`);
  return data;
}

export async function AddTab(dataJson) {
  const { data } = await axios.post('/tabs', dataJson);
  return data;
}

export async function UpdateTab({ id, dataJson }) {
  const { data } = await axios.put(`/tabs/${id}`, dataJson);
  return data;
}

export async function DeleteTab(id) {
  const { data } = await axios.delete(`/tabs/${id}`);
  return data;
}

export async function getTabs({ type }) {
  const { data } = await axios.get('/tabs', {
    params: { type },
  });
  return data;
}

export async function tableCloudPopover(body) {
  const { data } = await axios.post(`/customer/edi-shipment`, body);
  return data;
}

// shipment create shipment details copy
export async function getShipmentsList(params) {
  const { data } = await axios.get('/customer/shipments-list', { params });
  return data;
}

export async function getShipmentsSingle({ shipment_copy_id }) {
  if (shipment_copy_id) {
    const { data } = await axios.get(`/customer/single-shipment/${shipment_copy_id}`, {});
    return data;
  }
  return Promise.reject(new Error('Missing shipment_copy_id'));
}

export async function customerStopPoint(signal, params) {
  const { data } = await axios.get('/customer/stop-point', {
    params,
    signal,
  });
  return data;
}

export async function getBillToBillingCharges() {
  const { data } = await axios.get('/customer/customer');
  return data;
}

export async function getVehicleData() {
  const { data } = await axios.get('/equipment/vehicle/list');
  return data;
}

export async function getContactsBillingCharges(customer_id) {
  const { data } = await axios.get(`/customer/customer/${customer_id}/customer-contact-book`);
  return data;
}

export async function getAssignToGroupBillingCharges() {
  const { data } = await axios.get('/group');
  return data;
}

export async function getShipmentChargeType(params) {
  const { data } = await axios.get('/customer/shipment-charge-types', { params });
  return data;
}

export async function getEquipmentRequiredLength() {
  const { data } = await axios.get('/customer/length');
  return data;
}

export async function getEquipmentRequiredVehicles() {
  const { data } = await axios.get(`/vehicles`);
  return data;
}

export async function getEquipmentRequiredTrailers() {
  const { data } = await axios.get('/trailers');
  return data;
}

export async function getEquipmentIdInTrailers(params) {
  const { data } = await axios.get('/equipment/trailer', { params });
  return data;
}

export async function getGrandTotal() {
  const { data } = await axios.get('customer/settings/currency-metrics');
  return data;
}

export async function getCommodity() {
  const { data } = await axios.get('/customer/shipment-commodity-types');
  return data;
}

export async function getMeasurementTypes() {
  const { data } = await axios.get('/dimension-units');
  return data;
}

export async function getWeightTypes() {
  const { data } = await axios.get('/weight-types');
  return data;
}

export async function getQuantityTypes() {
  const { data } = await axios.get('/quantity-types');
  return data;
}

export async function getDocType(params) {
  const { data } = await axios.get(`/document/document_type`, { params });
  return data;
}

// create shipment
export function CreateShipmentPost(data) {
  const config = {
    method: 'post',
    url: '/customer/shipment',
    data,
  };
  return axios(config).then((response) => {
    return response;
  });
}

// create shipment LTL
export function CreateShipmentPostLTL(data) {
  const config = {
    method: 'post',
    url: '/customer/shipment-ltl',
    data,
  };
  return axios(config).then((response) => {
    return response;
  });
}

// add stop point
export async function getStopPointCity({ id }) {
  const { data } = await axios.get(`/search-cities`, {
    params: {
      state_id: id,
    },
  });
  return data;
}

export async function getStopPointState({ id }) {
  const { data } = await axios.get(`/search-states`, {
    params: {
      country_id: id,
    },
  });
  return data;
}

// StopPointStep 1
export async function PostStopPointStepOne(body) {
  const { data } = await axios.post(`/customer/stop-point`, {
    ...body,
  });
  return data;
}

// StopPointStep 2
export async function PostStopPointStepTwo(data) {
  const config = {
    method: 'post',
    url: '/customer/stop-point-contact-book',
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

// StopPointStep 3
export async function PostStopPointStepThree(data) {
  const config = {
    method: 'post',
    url: '/customer/stop-point-working-hours',
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

// StopPointStep 2 and StopPointStep 3-types
export async function getStopPointType() {
  const { data } = await axios.get('/customer/contact-book-type');
  return data;
}

// commodity modal
export async function PostCommodityType(body) {
  const { data } = await axios.post(`/customer/shipment-commodity-type`, body);
  return data;
}

export async function UpdateCommodityType(id, body) {
  if (id) {
    const { data } = await axios.put(`/customer/shipment-commodity-type/${id}`, body);
    return data;
  }
}

export async function UpdateShare(shipment_id, recipient_customer_contact_book_id) {
  const { data } = await axios.put(`/customer/shipments/share`, {
    shipment_id,
    recipient_customer_contact_book_id,
  });
  return data;
}

export async function revertLastAction(shipment_ids) {
  const { data } = await axios.put(`/customer/shipments/revert-last-action`, {
    shipment_ids,
  });
  return data;
}

export async function CancelShipment(ids) {
  const { data } = await axios.put(`/customer/shipments/cancel`, { ids });
  return data;
}

export async function RevertCancelShipment(ids) {
  const { data } = await axios.put(`/customer/shipments/revert-cancel`, { ids });
  return data;
}

export async function DeleteCommodityType({ id }) {
  if (id) {
    const { data } = await axios.delete(`/customer/shipment-commodity-type/${id}`);
    return data;
  }
}

// contact edit modal
export function CreateCustomerContactBook(data) {
  const config = {
    method: 'post',
    url: `/customer/customer/customer-contact-book`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export function UpdateCustomerContactBook({ id, data }) {
  if (id) {
    const config = {
      method: 'put',
      url: `/customer/customer/customer-contact-book/${id}`,
      data,
    };

    return axios(config).then((response) => {
      return response;
    });
  }
}

export async function getFullPrices() {
  const { data } = await axios.get('/fuel-price/fuel-prices');
  return data;
}

export async function nearbyVehicles(params) {
  const { id, radius, type, query, shipment_for_operating_states, cancelToken } = params;

  const { data } = await axios.get('/shipments/nearby-vehicles', {
    params: {
      ...params,
      shipment_id: id,
      radius,
      required_equipment_type: type ? 1 : 0,
      shipment_for_operating_states,
      query,
    },
    cancelToken,
  });
  return data;
}

export async function shipmentDispatch(options) {
  const formData = new FormData();
  formData.append('shipment_id', options.shipment_id);
  formData.append('equipment_id', options.equipment_id);
  formData.append('net_profit', options.net_profit);
  formData.append('estimated_fuel_cost', options.estimated_fuel_cost || 0);
  formData.append('assign_driver_from', options.assign_driver_from);
  formData.append('driver1_amount', options.driver1_amount || '0');
  if (options.driver2_amount) {
    formData.append('driver2_amount', options.driver2_amount || '0');
  }
  formData.append('company_acceptance', options.company_acceptance);
  formData.append('avoid_tolls', options.avoid_tolls);
  formData.append('highway_only', options.hwy_only);
  formData.append('open_borders', options.open_borders);
  formData.append('hazmat_id', options.haz_mat);
  formData.append('route_type', options.route_type);
  formData.append('immediately', options.immediately);
  formData.append('in_future', options.in_future);
  // need to be removed
  formData.append('add_shipment_notes', 0);
  // need to be removed
  formData.append('send_trucking_link_to_customer', options.send_trucking_link_to_customer);
  formData.append('before_scheduled_pickup', options.before_scheduled_pickup);
  formData.append('after_specific_shipment_complated', options.after_specific_shipment_complated);
  options.in_future &&
    options.future_date &&
    moment(options.future_date).isValid() &&
    formData.append('future_date', moment(options.future_date).format('YYYY-MM-DD HH:mm:ss'));
  options.before_scheduled_pickup &&
    formData.append('before_scheduled_pickup_hours', options.before_scheduled_pickup_hours);
  options.send_trucking_link_to_customer &&
    formData.append('recipient_customer_contact_book_id', options.recipient_customer_contact_book_id);
  options.after_specific_shipment_complated && formData.append('specific_shipment_id', options.specific_shipment_id);
  options.assign_driver_from_shipment &&
    formData.append('assign_driver_from_shipment', options.assign_driver_from_shipment);
  options.assign_driver_from_shipment_longitude &&
    formData.append('assign_driver_from_shipment_longitude', options.assign_driver_from_shipment_longitude);
  options.assign_driver_from_shipment_latitude &&
    formData.append('assign_driver_from_shipment_latitude', options.assign_driver_from_shipment_latitude);
  formData.append('flat', options.flat);
  formData.append('empty_miles', options.empty_miles);
  formData.append('loaded_miles', options.loaded_miles);
  formData.append('send_pickup_reminder_to_driver', options.send_pickup_reminder_to_driver);
  formData.append(
    'send_pickup_reminder_to_driver_before_minutes',
    options.send_pickup_reminder_to_driver_before_minutes
  );
  if (options.lane_id) {
    formData.append('lane_id', options.lane_id);
  }

  const { data } = await axios.post('/customer/shipment/dispatch', formData);
  return data;
}

export async function getCostCalculation(params, signal) {
  const { data } = await axios.get(`/customer/shipments/cost`, { params, signal });
  return data;
}

export async function getDriverCost(params, signal) {
  const { data } = await axios.get('customer/shipments/driver-cost', { params, signal });
  return data;
}

export async function getPendingDispatch(shipment_id) {
  const { data } = await axios.get(`/customer/shipments/pending-dispatch`, {
    params: { shipment_id },
  });
  return data;
}

export async function offerShipment(options) {
  const formData = new FormData();
  options.forEach((item, index) => {
    formData.append(`offers[${index}][shipment_id]`, item.shipment_id);
    formData.append(`offers[${index}][equipment_id]`, item.equipment_id);
    formData.append(`offers[${index}][offer_amount]`, item.offer_amount);
    formData.append(`offers[${index}][offer_date]`, item.offer_date);
    formData.append(`offers[${index}][offer_time]`, item.offer_time);
    if (item.empty_miles) {
      formData.append(`offers[${index}][empty_miles]`, item.empty_miles);
    }
  });

  const { data } = await axios.post('/customer/shipments/shipment-offers', formData);
  return data;
}

export async function deleteOffer(params) {
  const { data } = await axios.delete(`customer/shipments/shipment-offers/revert-offers`, { params });
  return data;
}

export async function deleteSingleOffer(id, params) {
  const { data } = await axios.delete(`customer/shipments/shipment-offers/revert-offers/${id}`, { params });
  return data;
}

export async function deleteDispatch(params) {
  const { data } = await axios.delete(`customer/shipments/pending-dispatch/revert`, { data: params });
  return data;
}

export async function driverAccept(options) {
  const formData = new FormData();
  formData.append('shipment_id', options.shipment_id);
  formData.append('driver_id', options.driver_id);
  formData.append('accept_offer', 1);

  const { data } = await axios.post('/customer/shipments/shipment-offers/driver-accept', formData);
  return data;
}

export async function getOfferState(shipment_id) {
  const { data } = await axios.get(`/customer/shipments/shipment-offers`, {
    params: {
      shipment_id,
    },
  });
  return data;
}

export async function getPlannedShipments(params) {
  const { data } = await axios.get(`/customer/shipments/planned-shipment`, {
    params,
  });
  return data;
}

export async function getPreviousShipments(params) {
  const { data } = await axios.get(`/customer/shipments/previous-shipment`, {
    params,
  });
  return data;
}

export async function getTotalMiles(params) {
  const { data } = await axios.get(`/customer/stop-point/calculate-distance`, { params });
  return data;
}

export async function getShipmentStops(shipment_id) {
  const { data } = await axios.get(`/shipment/shipment-stops`, {
    params: {
      shipment_id,
    },
  });
  return data;
}

export async function getCustomerPaymentTerm({ customer_id }) {
  const { data } = await axios.get(`/customer/customer/${customer_id}/customer-payment-term`);
  return data;
}

export async function getEquipmentList({ equipment_type, equipment_length }) {
  const { data } = await axios.get(`/customer/shipment-validation/equipment-list`, {
    params: {
      equipment_type,
      ...(!!equipment_length && { equipment_length }),
    },
  });
  return data;
}

export async function getStaffList() {
  const { data } = await axios.get(`/user/staff/list`);
  return data;
}

export async function TrimbleSearchData({ region = 'NA', query }) {
  const params = new URLSearchParams({
    authToken: process.env.REACT_APP_PC_MILER_KEY,
    query,
  });
  try {
    const data = await fetch(`https://singlesearch.alk.com/${region}/api/search?${params}`);
    return data.json();
  } catch (e) {
    // Do nothing
  }
}

export async function getReservation({ shipment_id }) {
  const { data } = await axios.get(`/shipment-reserve/${shipment_id}`);
  return data;
}

export async function getAbsenceData({ driver_id }) {
  const { data } = await axios.get(`/absence-data/driver/${driver_id}`);
  return data;
}

export async function postReservation({ shipment_id, duration, type }) {
  const { data } = await axios.post(`/shipment-reserve/${shipment_id}`, {
    duration,
    type,
  });
  return data;
}

export async function addReasonForShipmentDelay(body) {
  const { data } = await axios.post(`/customer/shipment/delay-reason`, body);
  return data;
}

export async function deleteReservation({ reserve_id }) {
  const { data } = await axios.delete(`/shipment-reserve/${reserve_id}`);
  return data;
}

export async function trackingHistoryData(id, start, end) {
  const { data } = await axios.get(
    `/equipment-tracking-history-list/${id}?from_date=${start}&to_date=${end}&shipment=true`
  );
  return data;
}

export async function splitShipment(params) {
  const { data } = await axios.post(`/customer/shipments/split`, params);
  return data;
}

export async function unsplitShipment(params) {
  const { data } = await axios.post('customer/shipments/unsplit', params);
  return data;
}

export async function updateCurrentLocation(id, body) {
  const { data } = await axios.put(`/customer/equipment/${id}/current-location`, body);
  return data;
}

export async function updateCarrierCurrentLocation(id, body) {
  const { data } = await axios.put(`/customer/shipment/${id}/current-location`, body);
  return data;
}

export async function getCarrierGps(id) {
  const { data } = await axios.get(`/customer/shipment/${id}/gps-history`);
  return data;
}

export async function getRecurringLanes(params) {
  const { data } = await axios.get(`customer/shipments/recurring-lanes`, { params });
  return data;
}

export async function getRecurringLane(id) {
  const { data } = await axios.get(`customer/shipments/recurring-lanes/${id}`);
  return data;
}

export async function createRecurringLaneNotes(id, body) {
  const { data } = await axios.post(`customer/shipments/planner-lane-notes/${id}`, body);
  return data;
}

export async function dispatchCarrier(body) {
  const { data } = await axios.post(`customer/shipment/brokerage/dispatch`, body);
  return data;
}

export async function getRecurringLaneNotes(id, params) {
  const { data } = await axios.get(`customer/shipments/planner-lane-notes/${id}`, { params });
  return data;
}

export async function getEquipmentDimensions(params) {
  const { data } = await axios.get(`/equipment-dimensions`, { params });
  return data;
}

export async function getCarrierPayTypes(params) {
  const { data } = await axios.get('/customer/shipment-charge-types', { params });
  return data;
}

export async function getEdiRejectionReasons(params) {
  const { data } = await axios.get('/customer/edi-rejection-reasons', { params });
  return data;
}

export async function createEdiRejectionReasons(body) {
  const { data } = await axios.post('/customer/edi-rejection-reasons', body);
  return data;
}

export async function updateEdiRejectionReasons(id, body) {
  const { data } = await axios.put(`/customer/edi-rejection-reasons/${id}`, body);
  return data;
}

export async function deleteEdiRejectionReasons(id) {
  const { data } = await axios.delete(`/customer/edi-rejection-reasons/${id}`);
  return data;
}
