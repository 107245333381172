import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { getStaffList } from 'Api/Planner';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import Autocomplete from 'common/Autocomplete';
import CustomDatePicker from 'components/CustomDatePicker';

const CreatedFilter = ({ filter, onChangeDatePicker, dateRangeCreatedBy, values, handleChange }) => {
  const [options, setOptions] = useState([]);

  const getStaffData = () => {
    getStaffList().then((res) => {
      const selectData = [{ id: 0, label: 'System', profile_logo: truckinLogo, searchValue: 'System edi recurring' }];
      res?.data.forEach((el) => {
        if (typeof el?.staff === 'object') {
          selectData.push({
            ...el?.staff,
            key: el?.staff?.id,
            label: `${el?.staff?.first_name} ${el?.staff?.last_name}`,
            searchValue: `${el?.staff?.first_name} ${el?.staff?.last_name}`,
          });
        }
      });
      setOptions(selectData);
    });
  };

  const valueDatePicker = useMemo(() => {
    const fromValue = filter.createdDateAll?.created_date_start?.searchValue;
    const toValue = filter.createdDateAll?.created_date_end?.searchValue;
    const isValidTo = !moment(toValue).isValid();

    if (fromValue && isValidTo) {
      return fromValue;
    }

    if (!fromValue && !toValue) {
      return '';
    }

    return fromValue && toValue ? `${fromValue} - ${toValue}` : '';
  }, [filter?.createdDateAll]);

  useEffect(() => {
    getStaffData();
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <CustomDatePicker
          selectsRange
          renderStart={false}
          style={{
            minWidth: 220,
            margin: '0 8px 0 0',
            height: 32,
            transform: 'translateY(1px)',
            borderRadius: 6,
            justifyContent: 'flex-start',
          }}
          valueToShow={valueDatePicker}
          shouldCloseOnSelect={false}
          labelStyle={{
            marginTop: 0,
          }}
          endDate={dateRangeCreatedBy[1]}
          startDate={dateRangeCreatedBy[0]}
          placeholderText='MM/DD/YYYY - MM/DD/YYYY'
          onChange={(dates) => onChangeDatePicker(dates)}
        />
        <div>
          {/* <CustomSelect */}
          {/*  multi */}
          {/*  img */}
          {/*  valueMultiArr={filter?.created_by} */}
          {/*  isSearchable */}
          {/*  options={options} */}
          {/*  isSearchInValue='searchValue' */}
          {/*  onChange={onChange} */}
          {/*  imgName='profile_logo' */}
          {/*  imgDefault={profile} */}
          {/*  styles={{ height: 32, marginTop: 1 }} */}
          {/*  dropDownStyles={{ flexGrow: 0, minWidth: '100%' }} */}
          {/*  isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 1 }} */}
          {/* /> */}
          <Autocomplete
            multiple
            width='300px'
            value={values.created_by}
            labelKey='label'
            options={options}
            onChange={(e, val) => handleChange('created_by', val)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            limitTags={2}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <div className='d-flex gap-2'>
                  <img
                    style={{ width: '22px', height: '22px', borderRadius: '50%', objectFit: 'cover' }}
                    src={option.profile_logo}
                    alt={option.profile_logo}
                  />{' '}
                  {option.label}
                </div>
              </li>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatedFilter;
