import React from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/drivers/edit.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { SFlexColumnGap } from '../HoursTracked.styles';

const EndTime = ({ data }) => {
  const { use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();

  return (
    <SFlexColumnGap>
      <div className='edit-icon-wrapper'>
        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
          {convertToCustomerTime(data?.end_time || new Date())}
        </Typography>
        <EditIcon />
      </div>
      <div>
        {data?.break === 1 ? (
          <Chip
            size='small'
            label='BREAK END'
            labelColor={use(palette.orangeBackground1, palette.red400)}
            bgColor={palette.orange500}
            fontSize='11px'
            fontWeight={500}
          />
        ) : (
          <Chip
            size='small'
            label='CLOCKED OUT'
            labelColor={use(palette.red400, palette.red400)}
            bgColor={palette.red0}
            fontSize='11px'
            fontWeight={500}
          />
        )}
      </div>
    </SFlexColumnGap>
  );
};

export default EndTime;
