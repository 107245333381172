import uuid from 'react-uuid';
import moment from 'moment';
import { palette } from 'utils/constants';
import {
  getInitialValues as getCargoInitialValues,
  initialValues as cargoInitialValues,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';

export const PROGRESS_TL = [
  { id: 1, title: 'Type of Shipment' },
  { id: 2, title: 'Shipment Stops' },
  { id: 3, title: 'Billing Charges' },
  { id: 4, title: 'Validation' },
  { id: 5, title: 'Documents' },
];
export const PROGRESS_LTL = [
  { id: 1, title: 'Type of Shipment' },
  { id: 2, title: 'Billing Charges' },
  { id: 3, title: 'Shipment Stops' },
  { id: 4, title: 'Validation' },
  { id: 5, title: 'Documents' },
];
export const PROGRESS_RECURRING_TL = [
  { id: 1, title: 'Type of Shipment' },
  { id: 2, title: 'Shipment Stops' },
  { id: 3, title: 'Schedule' },
  { id: 4, title: 'Billing Charges' },
  { id: 5, title: 'Validation' },
  { id: 6, title: 'Plan Ahead' },
];
export const PROGRESS_RECURRING_LTL = [
  { id: 1, title: 'Type of Shipment' },
  { id: 2, title: 'Billing Charges' },
  { id: 3, title: 'Shipment Stops' },
  { id: 4, title: 'Schedule' },
  { id: 5, title: 'Validation' },
  { id: 6, title: 'Plan Ahead' },
];

export function AppendToFormData(updatedData, values, inIndex, ignoreName = [], inArray) {
  let levelOne = 0;

  function recursiveFunction(data, namePrefix = '') {
    if (Array.isArray(data)) {
      if (data[0] && typeof data[0] === 'object') {
        data.forEach((object, objectIndex) => {
          const name = `${namePrefix}[${objectIndex}]`;
          if (inIndex) {
            inIndex === objectIndex && recursiveFunction(object, name);
          } else {
            recursiveFunction(object, name);
          }
        });
      }
    } else if (typeof data === 'object' && data !== null) {
      levelOne += 1;
      const keys = Object.keys(data);
      keys.forEach((key) => {
        const value = data[key];
        const name = inArray
          ? Array.isArray(value) || levelOne === 1
            ? namePrefix + key
            : `${namePrefix}[${key}]`
          : namePrefix + key;
        !ignoreName.includes(key) && recursiveFunction(value, name);
      });
    } else {
      updatedData[namePrefix] = data;
    }
  }

  recursiveFunction(values);
  return updatedData;
}

export function fillStopPointData(stopType, data, isRecurring) {
  switch (+stopType) {
    case 1:
      return {
        stop_point_type: Number(data?.stop_point_type),
        stop_point_id: data?.stop_point?.id,
        scheduled_type: data?.scheduled_type ? data?.scheduled_type : '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_type: String(data?.equipment_type_data?.id),
        equipment_type_length: String(data.equipment_type_length),
        equipment_id: '',
        equipment_tbd: data?.equipment_tbd ? 1 : 0,
        equipment_action: String(data.equipment_action),
        number: data?.number ? String(data.number) : '',
        quantity: data?.quantity,
        quantity_type: data?.quantity_type ? String(data.quantity_type) : '',
        dimensions: data?.dimensions ? JSON.parse(data.dimensions) : { l: '', w: '', h: '' },
        weight: data?.weight ? String(data.weight) : '',
        weight_type: data?.weight_type ? String(data.weight_type) : '',
        value: data?.value ? String(data.value) : '',
        commodity: data?.commodity_type?.id ? String(data?.commodity_type?.id) : '',
        stop_notes: data?.notes_text ? String(data.notes_text) : '',
        cargos: getCargoInitialValues(data?.cargos),
        bill_type: { 1: true, 2: false }[+data.bill_type],
        dock_high: { 1: true, 0: false }[+data.dock_high],
        stackable: { 1: true, 0: false }[+data.stackable],
        liftgate_service: { 1: true, 0: false }[+data.liftgate_service],
        labor_required: { 1: true, 0: false }[+data.labor_required],
        hazardous_materials: { 1: true, 0: false }[+data.hazardous_materials],
        startTime: isRecurring ? moment().toDate() : data?.from ? moment(data.from, 'HH:mm').toDate() : '',
        endTime: isRecurring
          ? moment().toDate()
          : ![1, 3].includes(Number(data?.scheduled_type))
          ? ''
          : data?.to
          ? moment(data.to, 'HH:mm').toDate()
          : '',
        id: uuid(),
      };
    case 2:
      return {
        number: data?.number ? String(data.number) : '',
        stop_point_type: +data.stop_point_type,
        stop_point_id: data.stop_point?.id,
        scheduled_type: data?.scheduled_type ? data?.scheduled_type : '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        dimensions: data?.dimensions ? JSON.parse(data.dimensions) : { l: '', w: '', h: '' },
        weight: data?.weight ? String(data.weight) : '',
        quantity: data?.quantity ? data?.quantity : '',
        quantity_type: data?.quantity_type ? String(data.quantity_type) : '',
        equipment_action: String(data.equipment_action),
        connected_cargos: data?.connected_cargos?.length ? getCargoInitialValues(data?.connected_cargos) : [],
        stop_notes: data?.notes_text ? String(data.notes_text) : '',
        bill_type: { 1: true, 2: false }[+data.bill_type],
        dock_high: { 1: true, 0: false }[+data.dock_high],
        labor_required: { 1: true, 0: false }[+data.labor_required],
        startTime: isRecurring ? moment().toDate() : data?.from ? moment(data.from, 'HH:mm').toDate() : '',
        endTime: isRecurring
          ? moment().toDate()
          : ![1, 3].includes(Number(data?.scheduled_type))
          ? ''
          : data?.to
          ? moment(data.to, 'HH:mm').toDate()
          : '',
        id: uuid(),
      };
    case 3:
      return {
        stop_point_type: +data.stop_point_type,
        stop_point_id: data.stop_point?.id,
        scheduled_type: data?.scheduled_type ? data?.scheduled_type : '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_action: String(data.equipment_action),
        equipment_tbd: data?.equipment_tbd ? 1 : 0,
        equipment_id: '',
        stop_notes: data?.notes_text ? String(data.notes_text) : '',
        bill_type: { 1: true, 2: false }[+data.bill_type],
        dock_high: { 1: true, 0: false }[+data.dock_high],
        labor_required: { 1: true, 0: false }[+data.labor_required],
        startTime: isRecurring ? moment().toDate() : data?.from ? moment(data.from, 'HH:mm').toDate() : '',
        endTime: isRecurring
          ? moment().toDate()
          : ![1, 3].includes(Number(data?.scheduled_type))
          ? ''
          : data?.to
          ? moment(data.to, 'HH:mm').toDate()
          : '',
        id: uuid(),
      };
    default:
  }
}

export function getStopTemplateDataOverView(stopType) {
  switch (Number(stopType)) {
    case 1:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_type: '',
        equipment_type_length: '',
        equipment_id: '',
        equipment_tbd: false,
        equipment_action: '',
        number: '',
        quantity: '',
        quantity_type: '',
        dimensions: { l: '', w: '', h: '' },
        weight: '',
        weight_types: '',
        value: '',
        commodity: '',
        cargos: [{ ...cargoInitialValues, id: uuid() }],
        notes_text: '', // ???
        bill_type: true, // 1-yes, 2-no
        dock_high: false,
        stackable: false,
        liftgate_service: false,
        labor_required: false,
        hazardous_materials: false, // ???
        from: null,
        to: null,
        id: uuid(),
      };

    case 2:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        dimensions: { l: '', w: '', h: '' },
        weight: '',
        quantity: '',
        quantity_type: '',
        weight_types: '',
        equipment_action: '',
        connected_cargos: [],
        number: '',
        notes_text: '', // ???
        bill_type: true, // 1-yes, 2-no
        dock_high: false,
        labor_required: false,
        from: null,
        to: null,
        id: uuid(),
      };

    case 3:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_tbd: false,
        equipment_action: 4,
        notes_text: '', // ???
        bill_type: false, // 1-yes, 2-no
        dock_high: false,
        labor_required: false,
        from: null,
        to: null,
        id: uuid(),
      };
    default:
  }
}

export function fillStopPointDataOverView(stopType, data, isShipmentTable) {
  switch (+stopType) {
    case 1:
      const casePickUp = {
        shipment_stop_id: data?.id,
        reported_quantity: Number(data?.reported_quantity) ? +data.reported_quantity : '',
        reported_weight: Number(data?.reported_weight) ? +data.reported_weight : '',
        reported_weight_type: data?.reported_weight_types || data?.reported_weight_type || null,
        reported_quantity_type: data?.reported_quantity_types || data?.reported_quantity_type || null,
        bill_of_lading_id: Number(data?.bill_of_lading_id) ? +data.bill_of_lading_id : '',
        reported_seal_id: Number(data?.reported_seal_id) ? +data.reported_seal_id : '',
        stop_point_type: Number(data?.stop_point_type),
        stop_point_id: data?.stop_point?.id,
        scheduled_type: data?.scheduled_type ? data?.scheduled_type : '',
        scheduled_date: data?.scheduled_date ? moment(data.scheduled_date, 'YYYY-MM-DD').toDate() : moment().toDate(),
        scheduled_date_to: data?.scheduled_date_to
          ? moment(data.scheduled_date_to, 'YYYY-MM-DD').toDate()
          : moment().toDate(),
        equipment_type: data?.equipment_type_data ? String(data?.equipment_type_data?.id) : '',
        equipment_tbd: data?.equipment_tbd ? 1 : 0,
        arrival_date: data?.arrival_date ? moment(data?.arrival_date).toDate() : null,
        arrival_time: data?.arrival_date ? moment(data?.arrival_date).toDate() : null,
        departure_date: data?.departure_date ? moment(data?.departure_date).toDate() : null,
        departure_time: data?.departure_date ? moment(data?.departure_date).toDate() : null,
        equipment_type_length: String(data.equipment_type_length_value?.id || data.equipment_type_length),
        equipment_id: data?.equipment?.id ? data.equipment.id : '',
        equipment_action:
          data?.equipment_action && EQUIPMENT_ACTION.some((item) => +item.key === +data.equipment_action)
            ? String(data.equipment_action)
            : '',
        number: data?.number ? String(data.number) : '',
        quantity: data?.quantity ? data?.quantity : '',
        quantity_type: data?.quantity_type ? String(data.quantity_type) : '',
        dimensions: data?.dimensions ? JSON.parse(data.dimensions) : { l: '', w: '', h: '' },
        weight: data?.weight ? String(data.weight) : '',
        value: data?.value ? String(data.value) : '',
        commodity: data?.commodity_type?.id ? String(data?.commodity_type?.id) : '',
        cargos: getCargoInitialValues(data?.cargos),
        notes_text: data?.notes_text ? String(data.notes_text) : '',
        bill_type: { 1: true, 2: false }[+data.bill_type],
        dock_high: { 1: true, 0: false }[+data.dock_high],
        stackable: { 1: true, 0: false }[+data.stackable],
        liftgate_service: { 1: true, 0: false }[+data.liftgate_service],
        labor_required: { 1: true, 0: false }[+data.labor_required],
        hazardous_materials: { 1: true, 0: false }[+data.hazardous_materials],
        from: data?.from ? moment(data.from, 'HH:mm').toDate() : null,
        to: ![1, 3].includes(Number(data?.scheduled_type))
          ? moment(data.from, 'HH:mm').toDate()
          : data?.to
          ? moment(data.to, 'HH:mm').toDate()
          : null,
        id: uuid(),
      };
      if (!isShipmentTable) {
        delete casePickUp?.reported_quantity;
        delete casePickUp?.arrival_date;
        delete casePickUp?.arrival_time;
        delete casePickUp?.departure_date;
        delete casePickUp?.departure_time;
        delete casePickUp?.reported_weight;
        delete casePickUp?.reported_weight_type;
        delete casePickUp.reported_seal_id;
        delete casePickUp.bill_of_lading_id;
      }
      return casePickUp;
    case 2:
      const caseDelivery = {
        quantity: data?.quantity ? data?.quantity : '',
        quantity_type: data?.quantity_type ? String(data.quantity_type) : '',
        dimensions: data?.dimensions ? JSON.parse(data.dimensions) : { l: '', w: '', h: '' },
        weight: data?.weight ? String(data.weight) : '',
        number: data?.number ? String(data.number) : '',
        stop_point_type: +data.stop_point_type,
        stop_point_id: data?.stop_point?.id,
        scheduled_type: data?.scheduled_type ? data?.scheduled_type : '',
        scheduled_date: data?.scheduled_date ? moment(data.scheduled_date, 'YYYY-MM-DD').toDate() : moment().toDate(),
        scheduled_date_to: data?.scheduled_date_to
          ? moment(data.scheduled_date_to, 'YYYY-MM-DD').toDate()
          : moment().toDate(),
        arrival_date: data?.arrival_date ? moment(data?.arrival_date).format('MM/DD/YYYY') : '',
        arrival_time: data?.arrival_date ? moment(data?.arrival_date).format('HH:mm') : '',
        departure_date: data?.departure_date ? moment(data?.departure_date).format('MM/DD/YYYY') : '',
        departure_time: data?.departure_date ? moment(data?.departure_date).format('HH:mm') : '',
        equipment_action:
          data?.equipment_action && EQUIPMENT_ACTION_DELIVERY.some((item) => +item.key === +data.equipment_action)
            ? String(data.equipment_action)
            : '',
        connected_cargos: data?.connected_cargos?.length ? getCargoInitialValues(data?.connected_cargos) : [],
        notes_text: data?.notes_text ? String(data.notes_text) : '',
        bill_type: { 1: true, 2: false }[+data.bill_type],
        dock_high: { 1: true, 0: false }[+data.dock_high],
        labor_required: { 1: true, 0: false }[+data.labor_required],
        from: data?.from ? moment(data.from, 'HH:mm').toDate() : null,
        to: ![1, 3].includes(Number(data?.scheduled_type))
          ? moment(data.from, 'HH:mm').toDate()
          : data?.to
          ? moment(data.to, 'HH:mm').toDate()
          : null,
        id: uuid(),
      };
      if (!isShipmentTable) {
        delete caseDelivery?.arrival_date;
        delete caseDelivery?.arrival_time;
        delete caseDelivery?.departure_date;
        delete caseDelivery?.departure_time;
      }
      return caseDelivery;
    case 3:
      const caseWayPoint = {
        stop_point_type: +data.stop_point_type,
        stop_point_id: data?.stop_point?.id,
        scheduled_type: data?.scheduled_type ? data?.scheduled_type : '',
        scheduled_date: data?.scheduled_date ? moment(data.scheduled_date, 'YYYY-MM-DD').toDate() : moment().toDate(),
        scheduled_date_to: data?.scheduled_date_to
          ? moment(data.scheduled_date_to, 'YYYY-MM-DD').toDate()
          : moment().toDate(),
        arrival_date: data?.arrival_date ? moment(data?.arrival_date).format('MM/DD/YYYY') : '',
        arrival_time: data?.arrival_date ? moment(data?.arrival_date).format('HH:mm') : '',
        departure_date: data?.departure_date ? moment(data?.departure_date).format('MM/DD/YYYY') : '',
        departure_time: data?.departure_date ? moment(data?.departure_date).format('HH:mm') : '',
        equipment_action:
          data?.equipment_action && EQUIPMENT_ACTION_WAYPOINT.some((item) => +item.key === +data.equipment_action)
            ? data.equipment_action
            : '',
        equipment_id: data?.equipment?.id ? data.equipment.id : '',
        equipment_tbd: data?.equipment_tbd ? 1 : 0,
        notes_text: data?.notes_text ? String(data.notes_text) : '',
        bill_type: { 1: true, 2: false }[+data.bill_type],
        dock_high: { 1: true, 0: false }[+data.dock_high],
        labor_required: { 1: true, 0: false }[+data.labor_required],
        from: data?.from ? moment(data.from, 'HH:mm').toDate() : null,
        to: ![1, 3].includes(Number(data?.scheduled_type))
          ? moment(data.from, 'HH:mm').toDate()
          : data?.to
          ? moment(data.to, 'HH:mm').toDate()
          : null,
        id: uuid(),
      };

      if (!isShipmentTable) {
        delete caseWayPoint?.arrival_date;
        delete caseWayPoint?.arrival_time;
        delete caseWayPoint?.departure_date;
        delete caseWayPoint?.departure_time;
      }
      return caseWayPoint;
    default:
  }
}

export function getStopTemplate(stopType, isRecurring) {
  switch (Number(stopType)) {
    case 1:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_type: '',
        equipment_type_length: '',
        equipment_id: '',
        equipment_tbd: false,
        equipment_action: '',
        number: '',
        quantity: '',
        quantity_type: '',
        dimensions: { l: '', w: '', h: '' },
        weight: '',
        weight_type: '',
        value: '',
        commodity: '',
        cargos: [{ ...cargoInitialValues, id: uuid() }],
        stop_notes: '', // ???
        bill_type: true, // 1-yes, 2-no
        dock_high: false,
        stackable: false,
        liftgate_service: false,
        labor_required: false,
        hazardous_materials: false, // ???
        startTime: isRecurring ? moment().toDate() : null,
        endTime: isRecurring ? moment().toDate() : null,
        id: uuid(),
      };

    case 2:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        dimensions: { l: '', w: '', h: '' },
        weight: '',
        quantity: '',
        quantity_type: '',
        weight_type: '',
        equipment_action: '',
        connected_cargos: [],
        number: '',
        stop_notes: '', // ???
        bill_type: true, // 1-yes, 2-no
        dock_high: false,
        labor_required: false,
        startTime: isRecurring ? moment().toDate() : null,
        endTime: isRecurring ? moment().toDate() : null,
        id: uuid(),
      };

    case 3:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_tbd: false,
        // equipment_type: '',
        // equipment_type_length: '',
        equipment_id: '',
        equipment_action: 4,
        // number: '',
        stop_notes: '', // ???
        bill_type: false, // 1-yes, 2-no
        dock_high: false,
        labor_required: false,
        startTime: isRecurring ? moment().toDate() : null,
        endTime: isRecurring ? moment().toDate() : null,
        id: uuid(),
      };
    default:
  }
}

export function getLegTemplate(stopType) {
  switch (Number(stopType)) {
    case 1:
      return [
        {
          stop_point_type: Number(stopType),
          stop_point_id: '',
          scheduled_type: '',
          scheduled_date: moment().toDate(),
          scheduled_date_to: moment().toDate(),
          equipment_type: '',
          equipment_type_length: '',
          equipment_id: '',
          equipment_action: '',
          number: '',
          quantity: '',
          quantity_type: '',
          weight: '',
          weight_type: '',
          dimensions: { l: '', w: '', h: '' },
          value: '',
          commodity: '',
          cargos: [{ ...cargoInitialValues, id: uuid() }],
          stop_notes: '', // ???
          bill_type: true, // 1-yes, 2-no
          dock_high: false,
          stackable: false,
          liftgate_service: false,
          labor_required: false,
          hazardous_materials: false, // ???
          startTime: null,
          endTime: null,
          id: uuid(),
        },
      ];

    case 2:
      return [
        {
          stop_point_type: Number(stopType),
          stop_point_id: '',
          scheduled_type: '',
          scheduled_date: moment().toDate(),
          scheduled_date_to: moment().toDate(),
          quantity: '',
          quantity_type: '',
          weight: '',
          weight_type: '',
          equipment_action: '',
          connected_cargos: '',
          number: '',
          stop_notes: '', // ???
          bill_type: true, // 1-yes, 2-no
          dock_high: false,
          labor_required: false,
          startTime: null,
          endTime: null,
          id: uuid(),
        },
      ];

    case 3:
      return [
        {
          stop_point_type: Number(stopType),
          stop_point_id: '',
          scheduled_type: '',
          scheduled_date: moment().toDate(),
          scheduled_date_to: moment().toDate(),
          // equipment_type: '',
          // equipment_type_length: '',
          // equipment_id:'',
          equipment_action: 4,
          // number: '',
          stop_notes: '', // ???
          bill_type: false, // 1-yes, 2-no
          dock_high: false,
          labor_required: false,
          startTime: null,
          endTime: null,
          id: uuid(),
        },
      ];
    default:
  }
}

export function getStopTemplateLTL(stopType, isRecurring) {
  switch (Number(stopType)) {
    case 1:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_type: '',
        equipment_type_length: '',
        equipment_id: '',
        equipment_action: '',
        number: '',
        quantity: '',
        quantity_type: '',
        dimensions: { l: '', w: '', h: '' },
        weight: '',
        weight_type: '',
        value: '',
        commodity: '',
        cargos: [{ ...cargoInitialValues, id: uuid() }],
        stop_notes: '', // ???
        bill_type: true, // 1-yes, 2-no
        dock_high: false,
        stackable: false,
        liftgate_service: false,
        labor_required: false,
        hazardous_materials: false, // ???
        startTime: isRecurring ? moment().toDate() : null,
        endTime: isRecurring ? moment().toDate() : null,
        id: uuid(),
      };

    case 2:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        quantity: '',
        quantity_type: '',
        dimensions: { l: '', w: '', h: '' },
        weight: '',
        weight_type: '',
        equipment_action: '',
        connected_cargos: [],
        number: '',
        stop_notes: '', // ???
        bill_type: true, // 1-yes, 2-no
        dock_high: false,
        labor_required: false,
        startTime: isRecurring ? moment().toDate() : null,
        endTime: isRecurring ? moment().toDate() : null,
        id: uuid(),
      };

    case 3:
      return {
        stop_point_type: Number(stopType),
        stop_point_id: '',
        scheduled_type: '',
        scheduled_date: moment().toDate(),
        scheduled_date_to: moment().toDate(),
        equipment_action: 4,
        stop_notes: '', // ???
        bill_type: false, // 1-yes, 2-no
        dock_high: false,
        labor_required: false,
        startTime: isRecurring ? moment().toDate() : null,
        endTime: isRecurring ? moment().toDate() : null,
        id: uuid(),
      };
    default:
  }
}

export function getChangesTemplate() {
  return {
    charges: '',
    qty: '',
    rate: '',
    id: uuid(),
  };
}

export function getChargesTemplateShipment() {
  return {
    charge_type: '',
    quantity: '',
    rate: '',
    id: uuid(),
  };
}

export function fillBillChargesData(charge) {
  return {
    charges: charge?.charge_type, // charge.charge_type
    qty: charge?.quantity,
    rate: charge?.rate,
    id: uuid(),
  };
}

export function getContactBookTemplate() {
  return {
    name: '',
    email: '',
    phone_number: '',
    contact_type_id: '',
    portal_access: false,
    email_updates: false,
    sms_updates: false,
  };
}

export function getDocumentTemplate() {
  return {
    documentName: '',
    documentReferenceId: '',
    shipment_id: '',
  };
}

export function getAddDocumentsRecurring() {
  return {
    documentName: '',
    date: '',
    file: '',
  };
}

export function getAddDocumentsShipment() {
  return {
    doc: '',
    reference_id: '',
    type: '',
    shipment_id: '',
    shipment_stop: null,
  };
}

export function fillAddDocumentsShipment(value) {
  return {
    doc: value?.doc ? value.doc : '',
    reference_id: value?.reference_id ? value.reference_id : '',
    type: value?.type ? value.type : '',
    shipment_id: value?.shipment_id ? value?.shipment_id : null,
    shipment_stop: value?.shipment_stop ? value.shipment_stop : null,
  };
}

export const SCHEDULED_DATE_TYPE = [
  { label: 'First Come First Serve (FCFS)', key: 1, labelSelected: null, shortName: 'FCFS' },
  { label: 'By Appointment', key: 2, labelSelected: null, shortName: 'APT' },
  { label: 'By Time Window', key: 3, labelSelected: null, shortName: 'TW' },
];

export const SCHEDULED_DATE_TYPE_OBJ = {
  1: { label: 'First Come First Serve (FCFS)', key: 1, labelSelected: null, shortName: 'FCFS' },
  2: { label: 'By Appointment', key: 2, labelSelected: null, shortName: 'APT' },
  3: { label: 'By Time Window', key: 3, labelSelected: null, shortName: 'TW' },
};

export const EQUIPMENT_ACTION = [
  { label: 'Live Load', key: 1, labelSelected: null },
  { label: 'Hook Trailer', key: 2, labelSelected: null },
  { label: 'Drop & Hook Trailer', key: 3, labelSelected: null },
];

export const EQUIPMENT_ACTION_WAYPOINT = [
  { label: 'Drop Trailer', key: 2, labelSelected: null },
  { label: 'Drop & Hook Trailer', key: 3, labelSelected: null },
  { label: 'Other', key: 4, labelSelected: null },
];

export const EQUIPMENT_ACTION_DELIVERY = [
  { label: 'Live Unload', key: 1, labelSelected: null },
  { label: 'Drop Trailer', key: 2, labelSelected: null },
];

export const BILLING_GRAND_TOTAL_CURRENCY = [
  { id: 1, title: 'USD' },
  { id: 2, title: 'CAD' },
  { id: 3, title: 'MXN' },
];

export const WEIGHT_TYPE = [
  { key: 1, label: 'LBS', flag: 'weight', labelSelected: null },
  { key: 2, label: 'KGS', flag: 'weight', labelSelected: null },
];

export const WEIGHT_TYPE_OBJ = {
  1: { key: 1, label: 'LBS', flag: 'weight', labelSelected: null },
  2: { key: 2, label: 'KGS', flag: 'weight', labelSelected: null },
};

export const QUANTITY = [
  { key: 1, label: 'Pieces', flag: 'qty', labelSelected: null },
  { key: 2, label: 'Units', flag: 'qty', labelSelected: null },
  { key: 3, label: 'Qty', flag: 'qty', labelSelected: null },
  { key: 4, label: 'Pallets', flag: 'qty', labelSelected: null },
];

export const QUANTITY_TYPE = {
  1: { key: 1, label: 'Pieces', flag: 'qty' },
  2: { key: 2, label: 'Units', flag: 'qty' },
  3: { key: 3, label: 'Qty', flag: 'qty' },
  4: { key: 4, label: 'Pallets', flag: 'qty' },
};

export const STOP_POINT_COUNTRY = [
  { key: 'US', label: 'United States', id: 231, phonecode: 1, labelSelected: null },
  { key: 'CA', label: 'Canada', id: 38, phonecode: 1, labelSelected: null },
  { key: 'MX', label: 'Mexico', id: 142, phonecode: 52, labelSelected: null },
];

export const LOCATION_INITIAL_VALUE = {
  origin_city: { searchValue: '', value: 'origin_city', key: uuid() },
  origin_state: { searchValue: '', value: 'origin_state', key: uuid() },
  destination_city: { searchValue: '', value: 'destination_city', key: uuid() },
  destination_state: { searchValue: '', value: 'destination_state', key: uuid() },
  scheduled_from: { searchValue: '', value: 'scheduled_from', key: uuid() },
  scheduled_to: { searchValue: '', value: 'scheduled_to', key: uuid() },
};

export const LOCATION_INITIAL_VALUE_STOP_POINT = {
  stop_point: { searchValue: '', value: 'stop_point', key: uuid() },
  stop_point_scheduled_from: { searchValue: '', value: 'stop_point_scheduled_from', key: uuid() },
  stop_point_scheduled_to: { searchValue: '', value: 'stop_point_scheduled_to', key: uuid() },
};

export const INITIAL_VALUE_CREATED_DATE = {
  created_date: { searchValue: '', value: 'created_date', key: uuid() },
  created_date_end: { searchValue: '', value: 'created_date_start', key: uuid() },
  created_date_start: { searchValue: '', value: 'created_date_end', key: uuid() },
};

export const ALL_TAB_FILTER_ID = 989898;
export const DEFAULT_DISABLED_TABS = {
  customers: false,
  groups: false,
  status: false,
  equipment_types: false,
  stop_point: false,
};

export const STATUS_TAB_ADD_MODAL_PENDING = [
  { id: 1, title: ' Scheduled Ahead' },
  { id: 2, title: 'Scheduled For Today' },
  { id: 3, title: 'Behind Scheduled' },
  { id: 4, title: 'Canceled' },
  { id: 5, title: 'Offered' },
];

export const ALL_STATUES = [
  { title: 'Scheduled Ahead', id: 1, type: 'planner', value: 1 },
  { title: 'Scheduled For Today', id: 2, type: 'planner', value: 2 },
  { title: 'Behind Schedule', id: 3, type: 'planner', value: 3 },
  { title: 'Canceled', id: 4, type: 'planner', value: 4 },
  { title: 'Offered', id: 5, type: 'planner', value: 5 },
  { title: 'Pending Dispatch', id: 6, type: 'planner', value: 6 },

  { id: 1, title: 'Dispatched', type: 'shipment', value: 7 },
  { id: 2, title: 'Confirmed', type: 'shipment', value: 8 },
  { id: 3, title: 'On Route', type: 'shipment', value: 9 },
  { id: 4, title: 'Delayed', type: 'shipment', value: 10 },
  { id: 5, title: 'Arrived', type: 'shipment', value: 11 },
  { id: 7, title: 'Completed', type: 'shipment', value: 12 },
  { id: 8, title: 'Offered', type: 'shipment', value: 13 },
  { id: 6, title: 'Dry Run (TONU)', type: 'shipment', value: 14 },
  { id: 10, title: 'Layover', type: 'shipment', value: 15 },
];

export const EDI_API_STATUS = [
  { title: 'Awaiting Acceptance', id: 1 },
  { title: 'Accepted', id: 2 },
  { title: 'Rejected', id: 3 },
  { title: 'Expired', id: 4 },
];

export const STATUS_FILTER_DATA_PENDING = [
  {
    title: 'Scheduled Ahead',
    id: 1,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Scheduled For Today',
    id: 2,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Behind Schedule',
    id: 3,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Canceled',
    id: 4,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Offered',
    id: 5,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Pending Dispatch',
    id: 6,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
];

export const STATUS_TAB_ADD_MODAL_SHIPMENT = [
  { id: 1, title: 'Planned' },
  { id: 2, title: 'Confirmed' },
  { id: 3, title: 'On Route' },
  { id: 4, title: 'Delayed' },
  { id: 5, title: 'Arrived' },
  { id: 7, title: 'Completed' },
  { id: 8, title: 'Pending Dispatch' },
  { id: 6, title: 'Dry Run (TONU)' },
  { id: 10, title: 'Layover' },
];
export const STATUS_FILTER_DATA_SHIPMENT = [
  {
    title: 'Pending Dispatch',
    id: 8,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Planned',
    id: 1,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Confirmed',
    id: 2,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'On Route',
    id: 3,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Delayed',
    id: 4,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Arrived',
    id: 5,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Completed',
    id: 7,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Dry Run (TONU)',
    id: 6,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Layover',
    id: 10,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
];

export const STATUS_FILTER_DATA_SHIPMENT_AUDIT = [
  {
    title: 'Completed',
    id: 7,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Dry Run',
    id: 6,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
];

export const STATUS_TAB_ADD_MODAL_RECURRING = [
  { id: 1, title: 'Active' },
  { id: 2, title: 'Expires on 03/12/2022' },
  { id: 3, title: 'Canceled' },
];
export const STATUS_FILTER_DATA_RECURRING = [
  {
    title: 'Active',
    id: 1,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Expires on 03/12/2022',
    id: 2,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Canceled',
    id: 3,
    value: 'status',
    type: 'checkbox',
    key: uuid(),
  },
];

export const PLANNER_PENDING_BULK_ACTIONS = [
  { id: 15, name: 'Dispatch', tab: 'Vehicle' },
  { id: 16, name: 'Offer', tab: 'Offer' },
  { id: 17, name: 'Cancel Shipment', cancel: true },
];

export const PLANNER_PENDING_EDIT = [
  { id: 2, name: 'View Shipment', route: 'shipment' },
  { id: 20, name: 'Dispatch' },
  { id: 21, name: 'Offer' },
  { id: 22, name: 'Post to Bid Board', disabled: true, tooltip: 'You are not configured for Post to Bid Board.' },
  { id: 1, name: 'Notes' },
  { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' }, // corrected id
  { id: 11, name: 'Copy Shipment' },
  { id: 8, name: 'Split Shipment' },
  { id: 24, name: 'Unsplit Shipment' },
  { id: 13, name: 'Upload Documents' },
  { id: 12, name: 'Reserve Shipment' },
  { id: 112, name: 'Unreserve Shipment' },
  { id: 22, name: 'Revert Offers', yellow: true },
  { id: 5, name: 'Dry Run (TONU)', cancel: true },
  { id: 17, name: 'Cancel Shipment', cancel: true },
];

export const PLANNER_VIEW = [
  { id: 20, name: 'Dispatch' },
  { id: 21, name: 'Offer' },
  { id: 1, name: 'Notes' },
  { id: 11, name: 'Copy Shipment' },
  { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' }, // corrected id
  { id: 8, name: 'Split Shipment' },
  { id: 24, name: 'Unsplit Shipment' },
  { id: 13, name: 'Upload Documents' },
  { id: 12, name: 'Reserve Shipment' },
  { id: 112, name: 'Unreserve Shipment' },
  { id: 22, name: 'Revert Offers', yellow: true },
  { id: 5, name: 'Dry Run (TONU)', cancel: true },
  { id: 17, name: 'Cancel Shipment', cancel: true },
];

export const PLANNER_VIEW_MASTER_SPLIT = [
  { id: 1, name: 'Notes' },
  { id: 11, name: 'Copy Shipment' },
  { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' }, // corrected id
  { id: 13, name: 'Upload Documents' },
];

export const PLANNER_PENDING_EDIT_CANCELED = [
  { id: 2, name: 'View Shipment', route: 'shipment' },
  { id: 1, name: 'Notes' },
  { id: 11, name: 'Copy Shipment' },
  { id: 13, name: 'Upload Documents' },
  { id: 112, name: 'Unreserve Shipment' },
  { id: 125, name: 'Revert Cancel', green: true },
];

export const PLANNER_ROWS_EDIT_DATA_MASTER_SPLIT = [
  { id: 2, name: 'View Shipment', route: 'shipment' },
  { id: 1, name: 'Notes' },
  { id: 13, name: 'Upload Documents' },
  { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' },
  { id: 11, name: 'Copy Shipment' },
  { id: 13, name: 'Upload Documents' },
];

export const SHIPMENT_ROWS_EDIT_DATA = [
  { id: 9, name: 'View Shipment' }, // corrected id
  { id: 1, name: 'Notes' }, // corrected id
  { id: 2, name: 'Check In/Out' }, // corrected id
  { id: 61, name: 'Dock Status' },
  { id: 8, name: 'Split Shipment' },
  { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' },
  { id: 3, name: 'Chat with Driver', route: 'chat' }, // corrected id
  { id: 6, name: 'Layover' }, // corrected id
  { id: 7, name: 'Share Updates' }, // corrected id
  { id: 11, name: 'Copy Shipment' }, // corrected id
  { id: 13, name: 'Upload Documents' }, // corrected id
  { id: 14, name: 'Replace Driver' }, // corrected id
  { id: 43, name: 'Update Location' }, // corrected id
  // { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' }, // corrected id
  { id: 18, name: 'View Progress', route: 'shipment' }, // corrected id
  { id: 44, name: 'Request Cellular Tracking' },
  { id: 45, name: 'Stop Cellular Tracking', cancel: true },
  { id: 97, name: 'Revert Last Action', yellow: true }, // corrected id
  { id: 19, name: 'Revert to Planner', cancel: true }, // corrected id
  { id: 5, name: 'Dry Run (TONU)', cancel: true }, // corrected id
];

export const SHIPMENT_ROWS_EDIT_DATA_COMPLETED = [
  { id: 9, name: 'View Shipment' }, // corrected id
  { id: 1, name: 'Notes' }, // corrected id
  { id: 13, name: 'Upload Documents' }, // corrected id
  { id: 97, name: 'Revert Last Action', yellow: true },
  { id: 19, name: 'Revert to Planner', cancel: true },
];

export const SHIPMENT_ROWS_EDIT_DATA_CANCELED = [
  { id: 9, name: 'View Shipment' }, // corrected id
  { id: 1, name: 'Notes' }, // corrected id
  { id: 2, name: 'Check In/Out' }, // corrected id
  { id: 61, name: 'Dock Status' },
  { id: 3, name: 'Chat with Driver', route: 'chat' }, // corrected id
  { id: 11, name: 'Copy Shipment' }, // corrected id
  { id: 7, name: 'Share Updates' }, // corrected id
  { id: 13, name: 'Upload Documents' }, // corrected id
  { id: 14, name: 'Replace Driver' }, // corrected id
  // { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' }, // corrected id
  { id: 18, name: 'View Progress', route: 'shipment' }, // corrected id
  { id: 19, name: 'Revert to Planner', cancel: true }, // corrected id
];

export const PENDING_DISPATCH_ACTIONS = [
  { id: 9, name: 'View Shipment' }, // corrected id
  { id: 1, name: 'Notes' }, // corrected id
  { id: 18, name: 'View Progress', route: 'shipment' }, // corrected id
  { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' },
  { id: 13, name: 'Upload Documents' }, // corrected id
  { id: 23, name: 'Revert Dispatch', cancel: true }, // corrected id
];

export const DRY_RUN_ACTIONS = [
  { id: 1, name: 'Notes' },
  { id: 13, name: 'Upload Documents' },
  { id: 97, name: 'Revert Last Action', yellow: true },
  { id: 19, name: 'Revert to Planner', cancel: true },
];

export const SHIPMENT_ROWS_EDIT_DATA_DETAIL = [
  { id: 18, name: 'View Progress', route: 'shipment' },
  { id: 1, name: 'Notes' }, // corrected id
  { id: 2, name: 'Check In/Out' },
  { id: 3, name: 'Chat with Driver', route: 'chat' }, // corrected id
  { id: 6, name: 'Layover' },
  { id: 7, name: 'Share Updates' },
  { id: 11, name: 'Copy Shipment' },
  { id: 13, name: 'Upload Documents' }, // corrected id
  { id: 14, name: 'Replace Driver' },
  { id: 16, name: 'Generate Bill of Lading', route: 'bill-of-lading' },
  { id: 61, name: 'Dock Status' },
  { id: 8, name: 'Split Shipment' },
  { id: 43, name: 'Update Location' }, // corrected id
  { id: 44, name: 'Request Cellular Tracking' },
  { id: 45, name: 'Stop Cellular Tracking', cancel: true },
  { id: 97, name: 'Revert Last Action', yellow: true },
  { id: 19, name: 'Revert to Planner', cancel: true },
  { id: 5, name: 'Dry Run (TONU)', cancel: true },
];

export const SHIPMENT_ROWS_EDIT_DATA_DETAIL_COMPLETED = [
  { id: 1, name: 'Notes' },
  { id: 13, name: 'Upload Documents' },
  { id: 97, name: 'Revert Last Action', yellow: true },
  { id: 19, name: 'Revert to Planner', cancel: true },
];

export const SHIPMENT_BULK_ACTIONS_DATA = [
  { id: 19, name: 'Revert to Planner', cancel: true },
  { id: 97, name: 'Revert Last Action', yellow: true },
];

export const MAP_DISPATCH_EDIT = [
  { id: 14, name: 'Replace Driver' },
  { id: 2, name: 'Check In/Out' },
  { id: 61, name: 'Dock Status' },
  { id: 1, name: 'Add Notes' },
  { id: 2, name: 'View Shipment', route: 'shipment' },
  { id: 6, name: 'Layover' },
  { id: 8, name: 'Split Shipment' },
  { id: 7, name: 'Share Updates' },
  { id: 11, name: 'Copy Shipment' }, // corrected id
  { id: 13, name: 'Upload Documents' },
  { id: 43, name: 'Update Location' }, // corrected id
  { id: 44, name: 'Request Cellular Tracking' },
  { id: 45, name: 'Stop Cellular Tracking', cancel: true },
  { id: 97, name: 'Revert Last Action', yellow: true },
  { id: 19, name: 'Revert to Planner', cancel: true },
  { id: 5, name: 'Dry Run (TONU)', cancel: true },
];

export const MAP_DISPATCH_EDIT_COMPLETED = [
  { id: 1, name: 'Add Notes' },
  { id: 2, name: 'View Shipment', route: 'shipment' },
  { id: 13, name: 'Upload Documents' },
  { id: 97, name: 'Revert Last Action', yellow: true },
  { id: 19, name: 'Revert to Planner', cancel: true },
];

export const PLAN_TO_VEHICLE = [
  { id: 46, name: 'View Shipment' },
  { id: 1, name: 'Notes', route: 'notes' },
  { id: 8, name: 'Split Shipment' },
  { id: 13, name: 'Upload Documents' },
  { id: 21, name: 'Offer' },
  { id: 17, name: 'Cancel Shipment', cancel: true },
];

export const OFFER_TO_VEHICLE = [
  { id: 46, name: 'View Shipment' },
  { id: 1, name: 'Notes', route: 'notes' },
  { id: 8, name: 'Split Shipment' },
  { id: 20, name: 'Dispatch' },
  { id: 13, name: 'Upload Documents' },
  { id: 99, name: 'Offer Options' },
  { id: 17, name: 'Cancel Shipment', cancel: true },
];

export const PLAN_TO_VEHICLE_CANCELED = [
  { id: 46, name: 'View Shipment' },
  { id: 1, name: 'Notes', route: 'notes' },
  { id: 8, name: 'Split Shipment' },
  { id: 13, name: 'Upload Documents' },
  { id: 99, name: 'Offer Options' },
  { id: 125, name: 'Revert Cancel', green: true },
];

export const MAPPER_STOPS = {
  1: {
    color: palette.green500,
    background: palette.green0,
    name: 'Pick Up',
  },
  2: {
    color: palette.red400,
    background: palette.red0,
    name: 'Delivery',
  },
  3: {
    color: '#2264E6',
    background: '#EBF0FA',
    name: 'Waypoint',
  },
};
