import { date, number, object } from 'yup';

export const validationSchema = object().shape({
  record_type_id: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  equipment: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  // record_serviced_id: object()
  //   .shape({ id: number().required('Required') })
  //   .required('Required')
  //   .nullable(),
  // description: string().trim().required('Required'),
  // cost: number().required('Required').nullable(),
  date_interval: number().when('interval_type', {
    is: 'date',
    then: number().required('Required').min(1, 'Required'),
  }),
  odometer_interval: number().when('interval_type', {
    is: 'odometer',
    then: number().required('Required').min(100, 'Minimum 100'),
  }),
  one_time_datetime: date().when('one_time', {
    is: (one_time) => !!one_time,
    then: date().required('Required').nullable(),
    otherwise: date().nullable(),
  }),
});
