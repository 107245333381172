import React from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import Radio from 'common/Radio';
import CountInput from 'common/CountInput';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import styles from 'components/Billing/PurchaseAddOnsModalFinal/PurchaseAddOnsModalFinal.module.css';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import { getInitialValues, getValidationSchema } from './AddonDetails.data';
import { SBodyWrapper } from './AddonDetails.styles';

const AddonDetails = ({ open, onClose, addon, handleAddToCart, activeId, onSuccess, selectedPlanType, prices }) => {
  const { convertToCustomerTime, dateFormat } = useDateFormat();
  const { duration } = selectedPlanType || {};
  const activePlan = addon.plans.find((item) => item.id === activeId);

  const addAddonToCart = async (values) => {
    const data = { ...addon, quantity: Number(values.count), activePlan };

    if (addon.add_custom_fields?.length) {
      const add_custom_fields = {};

      addon.add_custom_fields?.forEach((item) => {
        if (item?.field_name) {
          add_custom_fields[item.field_name] = values[item.field_name];
        }
      });
      data.add_custom_fields = add_custom_fields;
    }

    handleAddToCart(data);
    onClose();
    onSuccess();
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(addon),
    validationSchema: getValidationSchema(addon),
    onSubmit: addAddonToCart,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={addon.name}
      $maxWidth='660vw'
      $minWidth='660px'
      $bgColor={palette.white}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Add to Cart',
          onClick: handleSubmit,
        },
      ]}
    >
      <SBodyWrapper>
        <div className={styles.right} style={{ margin: '0 auto', width: '538px' }}>
          <div className={styles.right_title}>
            <Typography variant='h5'>Order Summary</Typography>
          </div>
          <div className={styles.rightDoc_wrapper}>
            <div className={styles.item1}>
              <div className={styles.image_wrapper}>
                <figure className={styles.figure}>
                  <img className={styles.image} src={addon?.image} alt='Img' />
                </figure>
              </div>
              <div className={styles.info1}>
                <div className={styles.info1_left}>
                  <div className={styles.info1_lt}>
                    <Typography variant='b2' style={{ color: palette.gray900 }}>
                      {addon?.name}
                    </Typography>
                    <Typography variant='c1' style={{ color: palette.gray500 }}>
                      Add-On - {activePlan?.plan_title}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                      $
                      {formatNumber(
                        Number(activePlan?.monthly_amount || 0) * Number(planMapper[duration]?.monthsCount)
                      )}
                    </Typography>
                    <Typography variant='c1' className='nowrap'>
                      Per {planMapper[duration]?.per}
                    </Typography>
                    {!!activePlan?.setup_one_time_fee && (
                      <Typography variant='c1' className='nowrap'>
                        + One Time Setup Fee{' '}
                        <Typography variant='s2' style={{ color: palette.gray900 }} className='nowrap'>
                          ${formatNumber(activePlan.setup_one_time_fee)}
                        </Typography>
                      </Typography>
                    )}
                  </div>
                </div>
                <div style={{ marginLeft: '8px' }}>
                  <Typography variant='c1' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                    {addon?.assigned_type?.type} License(s)
                  </Typography>
                  {addon?.assigned_type?.id !== 4 ? (
                    <div className='mt-1'>
                      <CountInput
                        value={values.count}
                        handleChange={(val) => handleChange('count', val)}
                        minValue={1}
                      />
                    </div>
                  ) : (
                    <div className='text-center mt-2'>
                      <Typography variant='s2'>1</Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.item2}>
              <div className={styles.wrapper2}>
                <Typography variant='b2'>
                  License will automatically renew on{' '}
                  {convertToCustomerTime(prices?.addon_prices?.next_renewal_date, dateFormat)}.
                </Typography>
              </div>
            </div>
            <div className={styles.item2}>
              <div className={styles.wrapper2}>
                <Typography variant='b2'>All prices are in US Dollars (USD).</Typography>
              </div>
            </div>
            <div className={styles.item2}>
              <div className={styles.wrapper3}>
                <Typography variant='b2'>Cancel Subscription at anytime</Typography>
              </div>
            </div>
          </div>
          {!!addon.add_custom_fields?.length && (
            <div className={styles.custom_fields_wrapper}>
              {addon.add_custom_fields.map((field) =>
                field.field_type === 'radio' ? (
                  <div className='mb-3'>
                    <InputLabel required>{field.field_name}</InputLabel>
                    <div className='d-flex gap-3'>
                      <Radio
                        name={field.field_name}
                        value='1'
                        checked={values[field.field_name] === 'Yes'}
                        onChange={() => handleChange(field.field_name, 'Yes')}
                        label='Yes'
                      />
                      <Radio
                        name={field.field_name}
                        value='1'
                        checked={values[field.field_name] === 'No'}
                        onChange={() => handleChange(field.field_name, 'No')}
                        label='No'
                      />
                    </div>
                    <ErrorMessage error={touchedErrors[field.field_name]} />
                  </div>
                ) : (
                  <Input
                    key={field.name}
                    name={field.field_name}
                    label={field.field_name}
                    value={values[field.field_name]}
                    onChange={(e) => handleChange(field.field_name, e.target.value)}
                    error={touchedErrors[field.field_name]}
                    required
                    className='mb-2'
                  />
                )
              )}
            </div>
          )}
        </div>
      </SBodyWrapper>
    </Modal>
  );
};

export default AddonDetails;
