import { STRIPE_MODULE } from 'utils/constants';

export const ACTIVATION_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  PENDING: 2,
  DEACTIVATED: 3,
};

export const moduleMapper = {
  [STRIPE_MODULE.ACCOUNTS_RECEIVABLES]: { buttonTitle: 'Enable Online Payments from Customers' },
  [STRIPE_MODULE.ACCOUNTS_PAYABLE]: { buttonTitle: 'Enable Bill Pay' },
  [STRIPE_MODULE.PAYROLL]: { buttonTitle: 'Enable Payroll Payments' },
  [STRIPE_MODULE.CARD_MANAGEMENT]: { buttonTitle: 'Sign up for Truckin Digital Cash Card' },
};
