import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import classes from './cancelModal.module.scss';
import { useTheme } from '../../../context/themeContext';
import { palette } from '../../../utils/constants';
import { Typography } from '../../Typography';
import CustomButton from '../../CustomButton/CustomButton';

const RevertCancel = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();
  const subTitle = useMemo(
    () =>
      data.map((item) => {
        return `Shipment ${item?.shipment_id} ${item?.shipment_billing?.[0]?.billing_customer?.company_name} \n`;
      }),
    []
  );

  const styles = useMemo(() => {
    return {
      modal: {
        width: 360,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      styleButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 16,
      },
    };
  }, [use, palette]);

  function getIdsString(objects) {
    return objects.map((obj) => obj.shipment_id);
  }

  const onClickCancel = () => {
    onAddBtn(null, null, null, getIdsString(data));
  };

  const onClickNeverMind = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      backdropClassName={classes.backDropModal}
      dialogClassName='dialogClassName'
    >
      <div className={classNames(classes.modalWrapper, use(classes.light, classes.dark))}>
        <div className={classes.header}>
          <Typography variant='s2'>Cancel Shipment</Typography>
          <Typography variant='s3'>{subTitle}</Typography>
        </div>
        <div className={classes.line} />
        <div className={classes.body}>
          <Typography variant='s3'>
            Are you sure you want to Revert Canceled {data.map((item) => `“Shipment ${item?.shipment_id}” `)}?
          </Typography>
        </div>
        <div className={classes.line} />
        <div className={classes.buttons}>
          <CustomButton
            type='primary'
            title='Yes, Cancel Shipment'
            styleButton={styles.styleButton}
            onClick={onClickCancel}
          />

          <CustomButton
            type='secondary'
            title='Nevermind'
            styleButton={styles.styleButton}
            onClick={onClickNeverMind}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RevertCancel;
