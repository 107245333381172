import React from 'react';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { MaterialTableSort } from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { ProgressBar } from 'react-bootstrap';

export const exportInCSV = (data, setAnchorTag) => {
  const transformedData = data.map((item) => ({
    type: item.type,
    incident_count: item.incident_count,
  }));

  const jsonObject = JSON.stringify(transformedData, null, 2);

  function ConvertToCSV(objArray) {
    const titles = { type: 'Incident Type', incident_count: 'Count' };
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    const arrayToConvert = [titles, ...array];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }
    return str;
  }

  const str = ConvertToCSV(jsonObject);
  const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);
  setAnchorTag(objUrl);
};

const Title = ({ title }) => {
  const { use } = useTheme();

  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useColumns = (total, sortingQuery) => {
  return [
    {
      field: 'type',
      title: <Title title='INCIDENT TYPE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {row?.type}
        </Typography>
      ),
    },
    {
      field: 'incident_count',
      title: <MaterialTableSort title='Count' field='incident_count' sortingQuery={sortingQuery} />,
      render: (row) => (
        <Typography as='div' variant='s2' style={{ textAlign: 'start' }}>
          {row?.incident_count}
        </Typography>
      ),
    },
    {
      field: 'progress',
      title: <Title title='' />,
      render: (row) => (
        <div>
          <ProgressBar now={row?.incident_count} max={total} />
        </div>
      ),
    },
  ];
};
