export const initialValues = {
  driver_based_on: null,
  driver_excused_absences: '',
  driver_unexcused_absences: '',
  driver_vacations_allowed: '',
  driver_excused_reset_after_month: '',
  driver_unexcused_reset_after_month: '',
  driver_vacations_reset_after_month: '',
  staff_based_on: null,
  staff_excused_absences: '',
  staff_unexcused_absences: '',
  staff_vacations_allowed: '',
  staff_excused_reset_after_month: '',
  staff_unexcused_reset_after_month: '',
  staff_vacations_reset_after_month: '',
};
