import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/drivers/warn.svg';
import Radio from 'common/Radio';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import PhoneInput from 'common/PhoneInput';
import { DatePicker } from 'common/Pickers';
import Input, { Textarea } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { uploadStaffDocument } from 'Api/StaffV2';
import { uploadDriverDocument } from 'Api/Driver';
import Chip from 'common/Chip';
import { SAddMore } from '../../PreviousEmployment.styles';

const Attempt = ({ form, attempt, employerIndex, index, userId, userType }) => {
  const { values, handleChange, handleBlur, touchedErrors } = form;

  const deleteAttempt = () => {
    handleChange(
      `${employerIndex}.attempts`,
      values[employerIndex].attempts.filter((el, i) => i !== index)
    );
  };

  const uploadDocument = (file, field) => {
    switch (userType) {
      case 'driver':
        uploadDriverDocument(file, userId).then((res) => {
          handleChange(field, res.data.document_path);
        });
        break;
      case 'staff':
        uploadStaffDocument(file, userId).then((data) => {
          handleChange(field, data.document_path);
        });
        break;
      case 'applicant':
        uploadStaffDocument(file, userId).then((data) => {
          handleChange(field, data.document_path);
        });
        break;
      default:
        uploadStaffDocument(file, userId).then((data) => {
          handleChange(field, data.document_path);
        });
    }
  };

  return (
    <div className='attempt-box'>
      <div className='d-flex align-items-center justify-content-between'>
        <Typography variant='s1' style={{ color: palette.gray700 }}>
          Attempt #{index + 1}
        </Typography>
        {index !== 0 && attempt.isNew && <DeleteIcon fill={palette.red500} onClick={deleteAttempt} />}
      </div>
      <div className='d-flex align-items-center gap-4'>
        <div>
          <DatePicker
            required
            width='160px'
            label='Requested Date'
            name={`${employerIndex}.attempts.${index}.request_date`}
            value={attempt.request_date}
            onChange={(date) => handleChange(`${employerIndex}.attempts.${index}.request_date`, date)}
            onBlur={handleBlur}
            disableFuture
            error={touchedErrors?.[employerIndex]?.attempts?.[index]?.request_date}
          />
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex gap-3'>
            <InputLabel required>Request Method:</InputLabel>
            <Radio
              id={`Electronic-${index}-${employerIndex}`}
              label='Electronic'
              value='Electronic'
              checked={attempt.request_type === 'Electronic'}
              onChange={() => {
                handleChange(`${employerIndex}.attempts.${index}`, {
                  ...attempt,
                  request_type: 'Electronic',
                  request_form_source: 'Email',
                });
              }}
              disabled={attempt.response === 'Verified' || attempt.sent_date}
            />
            <Radio
              id={`PDF-${index}-${employerIndex}`}
              label='PDF'
              value='Pdf'
              onChange={() => handleChange(`${employerIndex}.attempts.${index}.request_type`, 'Pdf')}
              checked={attempt.request_type === 'Pdf'}
              disabled={attempt.response === 'Verified' || attempt.sent_date}
            />
          </div>
          {attempt.request_type === 'Electronic' ? (
            <div className='d-flex align-items-center gap-2'>
              <WarningIcon />
              <Typography variant='s2' style={{ color: palette.orange500 }}>
                Link to electronic form will be emailed.
              </Typography>
            </div>
          ) : (
            <Uploader
              type={2}
              document={attempt.request_form_path}
              onDrop={(files) => uploadDocument(files[0], `${employerIndex}.attempts.${index}.request_form_path`)}
              onRemove={() => handleChange(`${employerIndex}.attempts.${index}.request_form_path`, '')}
              error={touchedErrors?.[employerIndex]?.attempts?.[index]?.request_form_path}
              accept={['application/pdf']}
            />
          )}
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex gap-3'>
            <InputLabel required>Transmit Method:</InputLabel>
            <Radio
              id={`Email-${index}-${employerIndex}`}
              label='Email'
              value='Email'
              checked={attempt.request_form_source === 'Email'}
              onChange={() => handleChange(`${employerIndex}.attempts.${index}.request_form_source`, 'Email')}
              disabled={attempt.response === 'Verified' || attempt.sent_date}
            />
            {attempt.request_type !== 'Electronic' && (
              <>
                <Radio
                  id={`Fax-${index}-${employerIndex}`}
                  label='Fax'
                  value='Fax'
                  checked={attempt.request_form_source === 'Fax'}
                  onChange={() => handleChange(`${employerIndex}.attempts.${index}.request_form_source`, 'Fax')}
                  disabled={attempt.response === 'Verified' || attempt.sent_date}
                />
                <Radio
                  id={`None-${index}-${employerIndex}`}
                  label='None'
                  value='None'
                  checked={attempt.request_form_source === 'None'}
                  onChange={() => handleChange(`${employerIndex}.attempts.${index}.request_form_source`, 'None')}
                  disabled={attempt.response === 'Verified' || attempt.sent_date}
                />
              </>
            )}
          </div>
          {attempt.request_form_source === 'Email' && (
            <Input
              name={`${employerIndex}.attempts.${index}.email`}
              placeholder='example@email.com'
              onChange={handleChange}
              value={attempt.email}
              error={touchedErrors?.[employerIndex]?.attempts?.[index]?.email}
            />
          )}
          {attempt.request_form_source === 'Fax' && (
            <PhoneInput
              name={`${employerIndex}.attempts.${index}.fax`}
              placeholder='+1 (555) 555-1234'
              onChange={(val) => handleChange(`${employerIndex}.attempts.${index}.fax`, val)}
              value={attempt.fax}
              error={touchedErrors?.[employerIndex]?.attempts?.[index]?.fax}
            />
          )}
        </div>
      </div>
      <div className='d-flex align-items-center gap-4'>
        <div>
          <Autocomplete
            width='250px'
            required
            label='Previous Employer Response'
            name={`${employerIndex}.attempts.${index}.response`}
            value={attempt.response}
            onChange={(e, val) => handleChange(`${employerIndex}.attempts.${index}.response`, val)}
            options={['Pending Verification', 'Verified', 'Not Verified/Inaccurate']}
            error={touchedErrors?.[employerIndex]?.attempts?.[index]?.response}
          />
        </div>
        {attempt.response === 'Verified' && (
          <Uploader
            type={2}
            required
            label='Verified Response Form'
            document={attempt.response_path}
            onDrop={(files) => uploadDocument(files[0], `${employerIndex}.attempts.${index}.response_path`)}
            onRemove={() => handleChange(`${employerIndex}.attempts.${index}.response_path`, '')}
            error={touchedErrors?.[employerIndex]?.attempts?.[index]?.response_path}
            accept={['application/pdf']}
          />
        )}
      </div>
      <div>
        {!attempt.haveNote ? (
          <SAddMore onClick={() => handleChange(`${employerIndex}.attempts.${index}.haveNote`, true)}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Note
            </Typography>
          </SAddMore>
        ) : (
          <div className='d-flex align-items-center justify-content-between gap-3 mt-2'>
            <div>
              <DatePicker
                required
                width='135px'
                label='Date'
                name={`${employerIndex}.attempts.${index}.noteDate`}
                value={attempt.noteDate}
                onChange={(val) => handleChange(`${employerIndex}.attempts.${index}.noteDate`, val)}
                onBlur={handleBlur}
                disableFuture
                error={touchedErrors?.[employerIndex]?.attempts?.[index]?.noteDate}
              />
            </div>
            <Input
              width='250px'
              required
              name={`${employerIndex}.attempts.${index}.noteTitle`}
              label='Title'
              onChange={(e) => handleChange(`${employerIndex}.attempts.${index}.noteTitle`, e.target.value)}
              value={attempt.noteTitle}
              error={touchedErrors?.[employerIndex]?.attempts?.[index]?.noteTitle}
            />
            <Textarea
              className='w-100'
              rows={1}
              required
              label='Note'
              name={`${employerIndex}.attempts.${index}.noteNotes`}
              placeholder='Notes...'
              value={attempt.noteNotes}
              onChange={(e) => handleChange(`${employerIndex}.attempts.${index}.noteNotes`, e.target.value)}
              error={touchedErrors?.[employerIndex]?.attempts?.[index]?.noteNotes}
            />
            <DeleteIcon
              className='pointer mt-3'
              fill={palette.red500}
              onClick={() => handleChange(`${employerIndex}.attempts.${index}.haveNote`, false)}
            />
          </div>
        )}
        {attempt.response !== 'Verified' && (
          <div className='d-flex justify-content-end mt-2' style={{ marginBottom: '-10px' }}>
            <Chip
              label={attempt.sent_date ? 'SENT' : 'PENDING'}
              labelColor={attempt.sent_date ? palette.green500 : palette.gray500}
              bgColor={attempt.sent_date ? palette.green0 : palette.gray50}
              fontSize='11px'
              fontWeight={500}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Attempt;
