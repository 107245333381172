import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  driver_type: string().trim().required('Required').nullable(),
  operator: object().when('driver_type', {
    is: 'owner',
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});
