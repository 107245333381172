import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import ConfirmCancelModal from 'components/Billing/ConfirmCancelModal/ConfirmCancelModal';
import { validationSchema } from 'components/Billing/CancelAccountModal/validationSchema';
import { getErrorMessage } from 'utils/error';
import { cancelCustomer } from 'Api/User';
import { useAuth } from 'context/auth.context';
import packageJson from '../../../../package.json';
import styles from './CancelAccountModal.module.css';

const CancelAccountModal = ({ data, show, onClose, onSuccess }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const [ipAddress, setIpAddress] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const signPad = useRef({});
  const user = JSON.parse(localStorage.getItem('user'));
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
  const cancelDate = moment(data?.customer_plan?.next_renewal_date).toDate();
  const purgeDate = moment(cancelDate).add(30, 'd').toDate();
  const { setAuth, value } = useAuth();

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        signature_image: values.signature_image,
        password: values.password,
      };

      await cancelCustomer(user.customer.id, body);
      showToaster({ type: 'success', message: 'You have successfully canceled your account!' });
      setOpenConfirm(false);
      onClose();
      setAuth({
        ...value,
        user: { ...value.user, customer: { ...value.user.customer, pending_cancellation: 1 } },
      });

      const updatedUser = { ...user, customer: { ...user.customer, pending_cancellation: 1 } };
      localStorage.setItem('user', JSON.stringify(updatedUser));

      if (accountStatus?.account_status === 'trialexpired') {
        localStorage.clear();
        localStorage.setItem('version', packageJson.version);
        navigate('../login', { replace: true });
      }
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: {
      signature_image: '',
      password: '',
    },
    onSubmit,
    validationSchema,
  });

  const getIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const { ip } = await response.json();
      setIpAddress(ip);
    } catch (e) {
      // Do nothing
    }
  };

  const onSaveSignature = () => {
    signPad.current.getTrimmedCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const base64String = e.target.result;
          handleChange('signature_image', base64String);
          showToaster({ type: 'success', message: 'Signature has been saved' });
        };

        reader.readAsDataURL(file);
      }
    }, 'image/png');
  };

  const onClear = () => {
    handleChange('signature_image', '');
    signPad?.current.clear();
  };

  const onCancelAccount = () => {
    if (!values.signature_image) {
      showToaster({ type: 'error', message: 'Please save signature before proceeding!' });
      return;
    }

    setOpenConfirm(true);
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      backdrop='static'
      backdropClassName='cancel-modal-backdrop'
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant='h5'>Cancel Account</Typography>
        </div>
        <div className={styles.body_wrapper}>
          <div className={styles.top}>
            <div className={styles.top_left}>
              <Typography variant='s2' style={{ color: palette.gray500 }}>
                Company Name
              </Typography>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {data?.company_name}
              </Typography>
            </div>
            <div className={styles.item_right}>
              <Typography variant='s2' style={{ color: palette.gray500 }}>
                DOT
              </Typography>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {data?.dot}
              </Typography>
            </div>
          </div>
          <div className={styles.dates_wrapper}>
            <DatePicker label='Effective Cancel Date' name='cancel_date' value={cancelDate} disabled />
            <DatePicker label='Purge Date' name='purge_date' value={purgeDate} disabled />
          </div>
          <div className={styles.info_wrapper}>
            <div className={styles.item}>
              <Typography variant='s2' style={{ color: palette.gray500 }}>
                User Name
              </Typography>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {user?.first_name} {user?.last_name}
              </Typography>
            </div>
            <div className={styles.item_line}>
              <Typography variant='s2' style={{ color: palette.gray500 }}>
                Title
              </Typography>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {user?.title}
              </Typography>
            </div>
            <div className={styles.item_line}>
              <Typography variant='s2' style={{ color: palette.gray500 }}>
                Date
              </Typography>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {convertToCustomerTime(Date.now())}
              </Typography>
            </div>
          </div>
          <div className={styles.description}>
            <Typography variant='b1'>
              I wish to cancel my account ({data?.company_name}), effective ({formatDate(cancelDate)}). I understand
              that on ({formatDate(cancelDate)}) my account will no longer be accessible. I understand there are no
              refunds on licenses for term(s) purchased. I understand the cancellation here-under shall not operate to
              relieve {data?.company_name} of any financial or other obligations in these terms or otherwise. I
              understand that for a period of 30 days | shall be entitled to have access of {data?.company_name} data by
              reactivating my account by contacting support@truckindigital.com. Reactivating an account requires a
              purchased license. After 30 days following the effective cancellation date, TruckinDigital.com has the
              right to purge all the customer's data from the TruckinDigital.com system. As of ({formatDate(purgeDate)})
              the account will no longer be available, and all data will be deleted. I acknowledge and agree to the
              above cancellation request.
            </Typography>
          </div>
          <div className={styles.signature_wrapper}>
            <div className={styles.signature_top}>
              <div style={{ marginBottom: 8 }}>
                <span className={styles.label_required}>Signature</span>
              </div>
              <div>
                <span onClick={onSaveSignature} className={`${styles.clear} me-3`}>
                  Save
                </span>
                <span onClick={onClear} className={styles.clear}>
                  Clear
                </span>
              </div>
            </div>
            <div className={styles.signature_area}>
              <SignatureCanvas canvasProps={{ className: styles.sigCanvas }} penColor='black' ref={signPad} />
            </div>
          </div>
        </div>
        <div className={styles.footer_wrapper}>
          <div className={styles.ip_wrapper}>
            <Typography variant='s2'>IP Address: {ipAddress || '-'}</Typography>
          </div>
          <div className={styles.buttons_wrapper}>
            <CustomButton
              type='secondary'
              title='Cancel'
              leftIcon={false}
              styleButton={{ padding: '2px 6px', margin: 0 }}
              onClick={onClose}
            />
            <CustomButton
              type='danger'
              title='Cancel Account'
              leftIcon={false}
              styleButton={{ padding: '2px 6px', margin: 0 }}
              onClick={onCancelAccount}
            />
          </div>
        </div>
      </div>
      {openConfirm && (
        <ConfirmCancelModal
          show={openConfirm}
          data={data}
          onClose={() => setOpenConfirm(false)}
          values={values}
          handleChange={handleChange}
          onConfirm={handleSubmit}
          loading={loading}
        />
      )}
    </Modal>
  );
};

export default CancelAccountModal;
