import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class CountryStateCityService {
  getState(CountryId) {
    return axios.get(`${API_URL}/search-states?country_id=${CountryId}`).then((response) => {
      return response;
    });
  }

  getCity(StateId) {
    return axios.get(`${API_URL}/search-cities?state_id=${StateId}`).then((response) => {
      return response;
    });
  }
}

export default new CountryStateCityService();
