import { object, string } from 'yup';

export const validationSchema = object().shape({
  scheduled_date: string().trim().required('Required').typeError('Required').nullable(),
  from: string().trim().required('Required').typeError('Required').nullable(),
  to: string().when('scheduled_type', {
    is: (scheduled_type) => Number(scheduled_type) === 3,
    then: string().trim().required('Required').typeError('Required').nullable(),
    otherwise: string().nullable(),
  }),
});
