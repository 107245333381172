import { Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './DriverProfileTab.css';
import Policies from 'components/Policies';
import LogTab from 'components/LogTab/LogTab';
import TravelArea from 'components/TravelArea';
import NoteTable from 'components/NoteTab/NoteTab';
import AbsenceTable from 'components/AbsenceTable/AbsenceTable';
import RequestTable from 'components/RequestTable/RequestTable';
import PreviousEmployment from 'componentsV2/PreviousEmployment';
import ShipmentTabTable from 'components/ShipmentTab/ShipmentTab';
import IncidentTabTable from 'components/IncidentTab/IncidentTab';
import HourOfService from 'components/HourOfService/HourOfService';
import DriverDocument from 'components/DriverDocument/DriverDocument';
import DriverActivity from 'components/DriverActivity/DriverActivity';
import TransactionTabTable from 'components/TransationTab/TransactionTab';
import MedicalAndBackgroundTab from 'componentsV2/MedicalAndBackgroundTab';
import ShipmentActivity from 'components/ShipmentActivity/ShipmentActivity';
import TimeTracking from 'components/DriverTimeTracking/DriverTimeTracking';
import ConnectedServices from 'components/ConnectedService/ConnectedServices';
import DriverCompansation from 'components/DriverCompansation/DriverCompansation';
import GeneralInformation from 'components/GeneralInformation/GeneralInformation';
import DriverAbsenceAndRequest from 'components/DriverAbsenceAndRequest/DriverAbsenceAndRequest';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { getDriverRecordsCount } from 'Api/DotMonitoring';
import LogbookDetail from 'pages/Hos-Logbook/LogbookDetails';
import SafetyRecords from '../../pages/DotMonitoring/TableSection/SafetyRecords/SafetyRecords';

export default function DriverProfileTab({
  absenceDetails,
  driverInfo,
  setDriverInfo,
  setIsChange,
  updateAbsenceAllowanceData,
  updateCompensation,
  driverAbsence,
  showError,
  driverRequest = { driverRequest },
  updateProfile,
  onSuccess,
  loading,
  driverShipmentData,
  hourService,
  driverCompansation,
  driver_name,
  setGenerateIdModalShow,
}) {
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [key, setKey] = useState(slug || 'general');
  const [unacknowledgedCount, setUnacknowledgedCount] = useState(0);
  const { use } = useTheme();
  const isDarkMode = use(palette.white, palette.dark800) !== '#FFFFFF';

  useEffect(() => {
    navigate(`/driver-profile/${id}/${key}`, { replace: true });
  }, [id, key]);

  useEffect(async () => {
    const { data } = await getDriverRecordsCount(id);
    setUnacknowledgedCount(data.total || 0);
  }, []);

  return (
    <div className='modal-container-tab driver-style-wrap'>
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className={`mb-3 profile-tab ${isDarkMode ? 'dark-mode-tab' : ''}`}
        style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
      >
        <Tab eventKey='general' className='profile-tab-content' unmountOnExit title='General'>
          <div className='Driver-general' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <GeneralInformation
              driverInfo={driverInfo}
              updateGeneralProfile={updateProfile}
              onSuccess={onSuccess}
              loading={loading}
              setGenerateIdModalShow={setGenerateIdModalShow}
            />
            <div className='activity-container'>
              <DriverActivity driverId={id} />
              <ShipmentActivity driverShipmentData={driverShipmentData} />
              {driverInfo?.id === hourService?.driver_connected_services_hos?.driver_id && (
                <HourOfService hourService={hourService?.driver_connected_services_hos} />
              )}
            </div>
          </div>
        </Tab>
        <Tab
          eventKey='compensation'
          unmountOnExit
          className='profile-tab-content'
          title='Compensation'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <DriverCompansation
              driverInfo={driverInfo}
              showError={showError}
              updateCompensation={updateCompensation}
              driverCompansation={driverCompansation}
              onSuccess={onSuccess}
              loading={loading}
            />
          </div>
        </Tab>
        {!hideSensitiveInfo && (
          <Tab eventKey='documents' unmountOnExit className='profile-tab-content' title='Documents'>
            <DriverDocument driverInfo={driverInfo} updateProfile={updateProfile} loading={loading} />
          </Tab>
        )}
        <Tab
          eventKey='time-tracking'
          unmountOnExit
          className='profile-tab-content'
          title='Time Tracking'
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div>
            <TimeTracking />
          </div>
        </Tab>
        <Tab
          eventKey='travel-area'
          unmountOnExit
          className='profile-tab-content'
          title='Travel Area'
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div>
            <TravelArea driver={driverInfo} setDriver={setDriverInfo} />
          </div>
        </Tab>
        <Tab
          eventKey='absences'
          unmountOnExit
          className='profile-tab-content'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          title='Attendance'
        >
          <div>
            <DriverAbsenceAndRequest data={driverAbsence} />
            <AbsenceTable
              absenceDetails={absenceDetails}
              setIsChange={setIsChange}
              absLoading={loading}
              absence_allowance={driverAbsence?.absence_allowance}
              updateAbsenceAllowanceData={updateAbsenceAllowanceData}
            />
          </div>
        </Tab>
        <Tab
          eventKey='requests'
          unmountOnExit
          className='profile-tab-content'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          title='Requests'
        >
          <div>
            <DriverAbsenceAndRequest data={driverRequest} isRequest />
            <RequestTable driverInfo={driverInfo} isRequest />
          </div>
        </Tab>
        <Tab
          eventKey='shipments'
          unmountOnExit
          className='profile-tab-content'
          title='Shipments'
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
        >
          <div>
            <ShipmentTabTable />
          </div>
        </Tab>
        <Tab eventKey='incidents' unmountOnExit className='profile-tab-content' title='Incidents'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <IncidentTabTable firstName={driverInfo?.fname} lastName={driverInfo?.lname} />
          </div>
        </Tab>
        <Tab
          eventKey='dot-records'
          unmountOnExit
          className='profile-tab-content'
          title={
            <>
              DOT Records
              {!!unacknowledgedCount && <span className='carrier-bills-count'>{unacknowledgedCount} </span>}
            </>
          }
        >
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <SafetyRecords driverId={id} />
          </div>
        </Tab>

        {!hideSensitiveInfo && (
          <Tab eventKey='medical-background' unmountOnExit className='profile-tab-content' title='Medical & Background'>
            <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
              <MedicalAndBackgroundTab />
            </div>
          </Tab>
        )}
        {!hideSensitiveInfo && (
          <Tab eventKey='previous-employment' unmountOnExit className='profile-tab-content' title='Previous Employment'>
            <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
              <PreviousEmployment userType='driver' userId={id} />
            </div>
          </Tab>
        )}
        <Tab eventKey='transactions' unmountOnExit className='profile-tab-content' title='Transactions'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <TransactionTabTable />
          </div>
        </Tab>
        <Tab eventKey='hoursOfService' unmountOnExit className='profile-tab-content' title='Hours of Service'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <LogbookDetail isDriverProfile />
          </div>
        </Tab>
        <Tab eventKey='connected-services' unmountOnExit className='profile-tab-content' title='Connected Services'>
          <ConnectedServices />
        </Tab>
        <Tab eventKey='policies' unmountOnExit className='profile-tab-content' title='Policies'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <Policies userType='driver' userId={id} />
          </div>
        </Tab>
        <Tab eventKey='notes' unmountOnExit className='profile-tab-content' title='Notes'>
          <div style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <NoteTable driver_name={driver_name} driverId={id} />
          </div>
        </Tab>
        <Tab eventKey='log' unmountOnExit className='profile-tab-content' title='Log'>
          <LogTab />
        </Tab>
      </Tabs>
    </div>
  );
}
