import React, { useCallback, useEffect, useRef, useState, useMemo, useContext } from 'react';
import { FastField, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import CloseIcon from 'assets/icons/x.png';
import { ReactComponent as NotepadIcon } from 'assets/icons/header/notepad.svg';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { LayoutContext } from 'components/layout';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { GetNotepad, PostNotepad, UpdateNotepad } from 'Api/Notepad';
import styles from './Notepad.module.css';

const Notepad = () => {
  const { use } = useTheme();
  const notepadRef = useRef(null);
  const { showNotepad, setShowNotepad } = useContext(LayoutContext);
  const [notepadList, setNotepadList] = useState([]);
  const [showCompleted, setShowCompleted] = useState(true);
  const onCheckedCheckboxes = () => {
    setShowCompleted((prev) => !prev);
  };
  useEffect(() => {
    window.addEventListener('click', onClickOutNotepadDropdown);
    return () => {
      window.removeEventListener('click', onClickOutNotepadDropdown);
    };
  }, [notepadRef]);

  useEffect(() => {
    GetNotepad()
      .then((res) => {
        setNotepadList(res.data);
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const toggleNotepad = useCallback(() => {
    setShowNotepad(true);
  }, []);

  const onClickOutNotepadDropdown = useCallback((e) => {
    const classList = [...e.target.classList];
    if (!notepadRef?.current?.contains(e.target) && !classList.some((el) => el === 'underline-text')) {
      onCloseNotepad();
    }
  }, []);

  const onCloseNotepad = () => {
    setShowNotepad(false);
    setShowCompleted(true);
  };

  const onAddHandler = ({ note }, form) => {
    PostNotepad(note).then((res) => {
      form.resetForm();
      setNotepadList((prev) => [...prev, { ...res.data, is_checked: 0 }]);
    });
  };

  const onUpdateHandler = (id, is_checked) => {
    UpdateNotepad(id, is_checked).then(() => {
      setNotepadList((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, is_checked };
          }
          return item;
        });
      });
    });
  };

  const totalUnchecked = useMemo(() => {
    const uncheckedCount = notepadList?.filter((item) => item.is_checked === 0);
    return uncheckedCount?.length || 0;
  }, [notepadList]);

  return (
    <div ref={notepadRef} onClick={toggleNotepad}>
      {totalUnchecked ? (
        <div style={{ position: 'relative' }}>
          <div className='badge' style={{ display: 'flex' }}>
            {totalUnchecked}
          </div>
        </div>
      ) : null}
      <NotepadIcon />
      <Modal
        show={showNotepad}
        className={classNames([styles.modal, { [styles.show]: showNotepad }])}
        contentClassName={styles.contentClassName}
        dialogClassName={styles.dialogClassName}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.notepad_wrapper}
        >
          <div className={styles.notepad_header}>
            <Typography variant='h5' style={{ color: use(palette.gray900, palette.gray200) }}>
              Notepad
            </Typography>
            <figure onClick={onCloseNotepad}>
              <img src={CloseIcon} alt='img' />
            </figure>
          </div>
          <div className={styles.notepad_body_top}>
            <Typography variant='button2' style={{ color: use(palette.gray900, palette.gray200) }}>
              {`${totalUnchecked} of ${notepadList?.length} remaining`}
            </Typography>
            <label style={{ display: 'flex', textAlign: 'center', margin: 0 }}>
              <Typography variant='s2'>Show Completed</Typography>
              <CustomCheckbox
                name='completedCheckbox'
                style={{ marginLeft: 10 }}
                checked={showCompleted}
                onChange={() => onCheckedCheckboxes('show')}
              />
            </label>
          </div>
          <div className={styles.notepad_body}>
            {notepadList
              ?.filter((item) => showCompleted || item.is_checked === 0)
              ?.map((item) => {
                return (
                  <label key={item?.id} style={{ display: 'flex', alignItems: 'start' }}>
                    <CustomCheckbox
                      style={{ marginRight: 8 }}
                      checked={item.is_checked}
                      onChange={() => onUpdateHandler(item.id, item.is_checked ? 0 : 1)}
                    />
                    <Typography
                      variant='s2'
                      style={{ textDecoration: item.is_checked === 1 ? 'line-through' : 'none' }}
                    >
                      {item.note}
                    </Typography>
                  </label>
                );
              })}
          </div>
          <Formik
            onSubmit={onAddHandler}
            initialValues={{
              note: '',
            }}
          >
            {({ values, submitForm }) => {
              return (
                <Form>
                  <div className={styles.notepad_bottom}>
                    <FastField
                      name='note'
                      type='text'
                      placeholder='Add new note...'
                      component={CustomInput}
                      value={values.note}
                      style={{
                        width: 311,
                        borderTopLeftRadius: 6,
                        borderBottomLeftRadius: 6,
                        height: 32,
                        padding: '6px 12px',
                        margin: 0,
                      }}
                    />
                    <CustomButton
                      type='primary'
                      title='Add'
                      styleButton={{
                        width: 52,
                        height: 32,
                        marginTop: 10,
                        padding: '6px 12px',
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                      }}
                      onClick={submitForm}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};
export default Notepad;
