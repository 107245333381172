import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-filled.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/createShipment/copyBg.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as UnexcusedIcon } from 'assets/icons/createShipment/unexcused.svg';
import { ReactComponent as BillableMoveIcon } from 'assets/icons/createShipment/billableMove.svg';

import Tooltip from 'common/Tooltip';
import { SimpleLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import UpdateETA from 'componentsV2/Shipment/UpdateETA';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import CustomButton from 'components/CustomButton/CustomButton';
import ShipmentCommodity from 'componentsV2/Commodity/ShipmentCommodity';
import OverLayTopStops from 'components/TablePlaner/helpers/RecurrningDetails/helpers/OverLayTopStops';
import OverLayStopsPlus from 'components/TablePlaner/helpers/RecurrningDetails/helpers/OverLayStopsPlus';
import {
  QUANTITY_TYPE,
  SCHEDULED_DATE_TYPE_OBJ,
  WEIGHT_TYPE_OBJ,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getErrorMessage } from 'utils/error';
import { getEquipmentRequiredTitle } from 'utils/helpers';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import { formatNumber, getAlphabet, numberWithCommas, palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import useShipmentLocked from 'hooks/useShipmentLocked';
import { GetSettingsShipments } from 'Api/CompanySettings';
import { swapRecurringLaneStops, swapShipmentStops } from 'Api/Shipment';
import { getCargoTotals } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import StopInfo from './StopInfo';
import UpdateStopSchedule from './UpdateStopSchedule';
import OverViewStopActions from './OverViewStopActions';
import classes from './overView.module.css';

const OverViewStop = ({
  leg,
  legs,
  setLegs,
  indexPrefix,
  legsStopsCount,
  onSelectPlus,
  setIsOpen,
  onClickSettingsIcon,
  infoHeader,
  isShipmentTable,
  deleteStop,
  isRecurring,
  onCheckInOutClick,
  onDryRunClick,
  dragDisabled,
  readOnly,
  onUpdateSuccess,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDateTime, convertToCustomerTime } = useDateFormat();
  const { isShipmentLocked } = useShipmentLocked();
  const { currency, billOfLadingTitle } = useSelector((state) => state.root);
  const [swapingStop, setSwapingStop] = useState(null);
  const [shipmentSettings, setShipmentSettings] = useState(null);
  const [cargosToView, setCargosToView] = useState(null);
  const [stopToUpdateEta, setStopToUpdateEta] = useState(null);

  function getCheckInOutStop() {
    const index = leg?.stops?.findIndex((el) => el?.departure_date === null);
    return infoHeader?.shipment_stops?.[index]
      ? {
          ...infoHeader.shipment_stops?.[index],
          index,
          shipment_billing: infoHeader?.shipment_billing,
          shipment_stops: infoHeader?.shipment_stops,
        }
      : {};
  }

  const stopToCheckInOut = useMemo(() => getCheckInOutStop(), [leg, infoHeader]);

  const { require_signature_on_stop } =
    infoHeader?.shipment_billing?.[0]?.billing_customer?.customer_payment_term || {};
  const getStopName = (type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: use(palette.green500, palette.green400), title: 'Pick up' },
      2: { type: ' : DELIVERY', color: use(palette.red500, palette.red500), title: 'Delivery' },
      3: { type: ' : WAYPOINT', color: use(palette.blueText, palette.blueText), title: 'Waypoint' },
    };
    return TYPE_STOPS[type];
  };

  const prevStopsCount = useMemo(() => {
    const prevStopsCountArr = legsStopsCount?.slice(0, indexPrefix);
    return prevStopsCountArr.length ? prevStopsCountArr.reduce((a, b) => a + b, 0) : 0;
  }, [indexPrefix, legsStopsCount, leg]);

  function canDeleteStop(array, stopId) {
    for (const obj of array) {
      const stopIndex = obj.stops.findIndex((stop) => stop.id === stopId);
      if (stopIndex === -1) {
        continue;
      }
      const remainingStopsOfSameType = obj.stops.filter(
        (stop, index) => stop.stop_point_type === obj.stops[stopIndex].stop_point_type && index !== stopIndex
      );

      return remainingStopsOfSameType.length !== 0;
    }

    return false;
  }

  function onDeleteStop(stop, index, stops) {
    if (Number(stop.stop_point_type) !== 3 && leg.stops.length > 1 && !canDeleteStop(legs, stop.id)) {
      showToaster({ type: 'warning', message: 'At least one Pick Up and Delivery is required' });
      return;
    }

    if (index === 0 && Number(stop.stop_point_type) === 1 && Number(stops[1].stop_point_type) !== 1) {
      showToaster({
        type: 'warning',
        message:
          'You must have another pickup in the first position before deleting this pick up. Please drag and drop or add another pickup to the first position before deleting this one.',
      });
      return;
    }

    if (
      index === stops.length - 1 &&
      Number(stop.stop_point_type) === 2 &&
      Number(stops[stops.length - 2].stop_point_type) !== 2
    ) {
      showToaster({
        type: 'warning',
        message:
          'You must have another delivery in the last position before deleting this delivery. Please drag and drop or add another delivery to the last position before deleting this one.',
      });
      return;
    }

    deleteStop(stop.id);
  }

  const swapStop = (newLegs) => {
    const formData = new FormData();
    newLegs.forEach((leg, i) => {
      leg.stops.forEach((stop, j) => {
        formData.append(`legs[${i + 1}][${j + 1}]`, stop.id);
      });
    });
    if (isRecurring) {
      formData.append('shipment_planner_template_id', infoHeader.shipment_id);
    } else {
      formData.append('shipment_id', infoHeader.shipment_id);
    }

    (isRecurring ? swapRecurringLaneStops(formData) : swapShipmentStops(formData))
      .then(() => {
        showToaster({ type: 'success', message: 'Great! The rearrangement has been successful.' });
        onUpdateSuccess();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  function onChangePlusModal(el, i) {
    const map = {
      1: { ...el, color: palette.green500 },
      2: { ...el, color: palette.red500 },
      3: { ...el, color: palette.blueText },
    };

    onSelectPlus({ ...map[el.id], index: i + prevStopsCount, legIndex: indexPrefix + 1, order_in_leg: i + 1 });
    setIsOpen({ type: 'add', open: true });
    // setIsOpenModal(true);
  }

  function onEditStop(stop, index) {
    const map = {
      1: { id: 1, type: 'pickup', title: 'Pick Up', color: palette.green500 },
      2: { id: 2, type: 'delivery', title: 'Delivery', color: palette.red500 },
      3: { id: 3, type: 'waypoint', title: 'Waypoint', color: palette.blueText },
    };
    const idx = index + prevStopsCount;
    const id = stop.stop_point_type;
    onSelectPlus({ ...map[id], index: idx, legIndex: indexPrefix + 1, stop });
    setIsOpen({ type: 'edit', open: true });
    // setIsOpenModal(true);
  }

  function checkNullOrUndefined(value) {
    return value !== undefined && value !== null;
  }

  const equipmentTypeText = ({ type, action, equipment, equipment_tbd }) => {
    if (+type === 1) {
      const map = {
        1: 'Live Load',
        2: equipment_tbd ? `Hook (TBD)` : `Hook to ${equipment?.equipment_id}`,
        3: equipment_tbd ? `Drop Trailer & Hook (TBD)` : `Drop Trailer & Hook to ${equipment?.equipment_id}`,
      };
      return map[action];
    }
    if (+type === 2) {
      const map = {
        1: 'Live Unload',
        2: 'Drop Trailer',
      };
      return map[action];
    }
    if (+type === 3) {
      const map = {
        1: 'Live Unload',
        2: `Drop Trailer`,
        3: equipment_tbd ? `Drop Trailer & Hook (TBD)` : `Drop Trailer & Hook to ${equipment?.equipment_id}`,
        4: 'Other',
      };
      return map[action];
    }
  };

  function parseSubtractGetLastDigitSubOne(input) {
    if (input) {
      const numbers = input.split('-').map(Number);
      return numbers[1] - 1;
    }
    return 0;
  }

  function getBilling(planner_ref) {
    if (planner_ref) {
      const billing = infoHeader.shipment_billing.find((i) => i.slave_planner_ref === planner_ref);
      return billing || infoHeader.shipment_billing[0];
    }
    return infoHeader.shipment_billing[0];
  }

  const style = useMemo(() => {
    return {
      ellipsis: {
        maxWidth: 150,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      ellipsis60: {
        marginLeft: 4,
        maxWidth: 60,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      childOne: {
        width: 150,
        overflow: 'hidden',
      },
      childTwo: {
        maxWidth: 340,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        // overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      notes: {
        display: 'inline-block',
      },
    };
  }, [use, palette]);

  const handleOnDragEnd = (result) => {
    if (!result.destination || result.source?.index === result.destination?.index) return;

    const newLegs = [...legs];

    // Find the index of the `leg` you're working with.
    const legIndex = newLegs.findIndex((l) => l.number === leg.number);
    if (legIndex < 0) return;

    // Prevent moving stop if it has stop_point_type 2 and is being moved to the first position
    if (
      result.source.index !== 0 &&
      result.destination.index === 0 &&
      (newLegs[legIndex].stops[result.source.index].stop_point_type === '2' ||
        newLegs[legIndex].stops[result.source.index].stop_point_type === '3')
    ) {
      showToaster({
        type: 'error',
        message: `You must change Stop ${getAlphabet(
          result.source.index
        )} to Pickup type first before dragging to first stop.`,
      });
      return;
    }

    if (
      result.source.index !== newLegs[legIndex].stops.length - 1 &&
      result.destination.index === newLegs[legIndex].stops.length - 1 &&
      (newLegs[legIndex].stops[result.source.index].stop_point_type === '1' ||
        newLegs[legIndex].stops[result.source.index].stop_point_type === '3')
    ) {
      showToaster({
        type: 'error',
        message: `You must change Stop ${getAlphabet(
          result.source.index
        )} to Delivery type first before dragging to last stop.`,
      });
      return;
    }

    const items = Array.from(newLegs[legIndex].stops);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSwapingStop({ newLegs, legIndex, items, destinationIndex: result.destination.index });
  };

  const onChangeStopPointType = (newTpe, stop, index) => {
    const newStop = { ...stop, stop_point_type: String(newTpe) };
    onEditStop(newStop, index);
  };

  const formatAddress = (address1, state, city, zipcode) => {
    let fullAddress = '';
    if (address1) fullAddress += `${address1}, `;
    if (city?.name) fullAddress += `${city.name}, `;
    if (state?.name) fullAddress += `${state.name}, `;
    if (zipcode) fullAddress += zipcode;
    return fullAddress;
  };

  const handleCopy = (address1, state, city, zipcode) => {
    navigator.clipboard.writeText(formatAddress(address1, state, city, zipcode));
    showToaster({ type: 'success', message: 'Address has been successfully copied to clipboard!' });
  };

  const onConfirmSwap = () => {
    swapingStop.newLegs[swapingStop.legIndex].stops = swapingStop.items;
    swapStop(swapingStop.newLegs);
    setLegs(swapingStop.newLegs);
    setSwapingStop(null);
  };

  const editDisabled =
    isRecurring ||
    infoHeader?.shipment_type === 'split-parent' ||
    readOnly ||
    Number(infoHeader?.status_id) === 6 ||
    (Number(infoHeader?.status_id) === 7 && infoHeader?.invoiced);

  const addStopDisabled =
    isRecurring ||
    infoHeader?.shipment_type === 'split-parent' ||
    readOnly ||
    Number(infoHeader?.status_id) === 6 ||
    Number(infoHeader?.status_id) === 7;

  const getSettingsShipments = async () => {
    try {
      const { data } = await GetSettingsShipments();
      setShipmentSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getSettingsShipments();
  }, []);

  return (
    <div className='w-100 position-relative'>
      {!addStopDisabled && (
        <OverLayStopsPlus isShipmentTable={isShipmentTable} index={0} onChange={(el) => onChangePlusModal(el, 0)} />
      )}
      <div className={classes.legContainer} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='stops' direction='horizontal' isDragDisabled={editDisabled}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex' }}>
                {leg?.stops?.map((stop, index) => {
                  const {
                    stop_point,
                    bill_type,
                    scheduled_date,
                    scheduled_date_to,
                    from,
                    to,
                    equipment_type_data,
                    equipment_action,
                    number,
                    dock_high,
                    quantity,
                    notes_text,
                    departure_date,
                    arrival_date,
                    delay_reason,
                    equipment_type_length_value,
                    quantity_type,
                    stop_point_type,
                    dimensions,
                    stackable,
                    weight,
                    weight_types,
                    value,
                    liftgate_service,
                    labor_required,
                    reported_dock_status,
                    signature,
                    equipment,
                    bill_of_lading_id,
                    reported_seal_id,
                    reported_weight,
                    signature_name,
                    reported_quantity,
                    proof_of_delivery,
                    scheduled_type,
                    hazardous_materials,
                    slave_planner_ref,
                    slave_stop,
                    id,
                    equipment_tbd,
                    cargos,
                    connected_cargos,
                    eta,
                    custom_eta,
                  } = stop || {};
                  const { location_name, address1, city, state, zipcode, average_waiting_time, primary_contact_book } =
                    stop_point || {};

                  const isETADelayed = moment(convertToCustomerTime(eta)).isAfter(
                    [1, 3].includes(Number(scheduled_type))
                      ? `${scheduled_date_to || scheduled_date} ${to || from}`
                      : `${scheduled_date} ${from}`
                  );
                  const isArrivalDelayed = moment(arrival_date).isAfter(
                    [1, 3].includes(Number(scheduled_type))
                      ? `${scheduled_date_to || scheduled_date} ${to || from}`
                      : `${scheduled_date} ${from}`
                  );

                  const { name, phone_number, email } = primary_contact_book || {};

                  const contactInfo = {
                    name,
                    email,
                    phone_number,
                    location_name,
                  };
                  const { title } = equipment_type_data || {};
                  const { l, w, h } = typeof dimensions === 'string' ? JSON.parse(dimensions) : dimensions || {};
                  const totalValues =
                    Number(stop_point_type) === 1
                      ? getCargoTotals(
                          cargos?.map((item) => ({
                            ...item,
                            expected_quantity_type: item.expected_quantity_type_data,
                            expected_weight_type: item.expected_weight_type_data,
                            expected_dimension_unit: item.expected_dimension_unit_data,
                            expected_dimensions:
                              typeof item.expected_dimensions === 'string'
                                ? JSON.parse(item.expected_dimensions)
                                : null,
                          }))
                        )
                      : Number(stop_point_type) === 2
                      ? getCargoTotals(
                          connected_cargos?.map((item) => ({
                            ...item,
                            expected_quantity_type: item.expected_quantity_type_data,
                            expected_weight_type: item.expected_weight_type_data,
                            expected_dimension_unit: item.expected_dimension_unit_data,
                            expected_dimensions:
                              typeof item.expected_dimensions === 'string'
                                ? JSON.parse(item.expected_dimensions)
                                : null,
                          }))
                        )
                      : null;

                  return (
                    <Draggable
                      key={id}
                      draggableId={String(id)}
                      index={index}
                      isDragDisabled={
                        editDisabled ||
                        dragDisabled ||
                        (index === 0 && Number(stop_point_type) === 1) ||
                        (index === leg.stops.length - 1 && Number(stop_point_type) === 2)
                      }
                    >
                      {(provided) => (
                        <div
                          style={{ height: '500px' }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={index + 1}
                            className={classes.stopContainer}
                            style={{
                              backgroundColor: use(palette.white, palette.dark800),
                              position: 'relative',
                            }}
                          >
                            {infoHeader && infoHeader.shipment_type === 'ltl' && (
                              <div className={classNames(classes.stopType, { [classes.dontFirst]: index !== 0 })}>
                                <Typography variant='s1'>
                                  {isRecurring
                                    ? getBilling(slave_planner_ref)?.billing_customer?.company_name
                                    : infoHeader.shipment_billing?.[
                                        parseSubtractGetLastDigitSubOne(slave_stop?.shipment_id)
                                      ]?.billing_customer?.company_name}
                                </Typography>
                              </div>
                            )}
                            <div className={classNames(classes.stopType, { [classes.dontFirst]: index !== 0 })}>
                              <div className={classes.stopNameContainer}>
                                <div>
                                  <Typography variant='overLine' style={{ color: getStopName(stop_point_type)?.color }}>
                                    STOP {getAlphabet(prevStopsCount + index)}
                                    {getStopName(stop_point_type)?.type}
                                  </Typography>
                                  {!editDisabled && index !== 0 && index !== (leg.stops || []).length - 1 && (
                                    <OverLayTopStops
                                      activeStopPointType={+stop_point_type}
                                      changeType
                                      deleted={false}
                                      onChangeStopType={(newTpe) => onChangeStopPointType(newTpe, stop, index)}
                                    />
                                  )}
                                </div>
                                <span
                                  style={{
                                    backgroundColor: '#E9EDF5',
                                    padding: '2px 8px',
                                    borderRadius: 8,
                                    fontSize: 11,
                                  }}
                                >
                                  Avg. Wait {getAverageTimeConverted(average_waiting_time)}
                                </span>

                                {!!arrival_date && reported_dock_status !== null && !departure_date && (
                                  <div
                                    className={classNames(classes.dock, {
                                      [classes.waiting]: +reported_dock_status === 1,
                                    })}
                                  >
                                    <Typography variant='overLine'>
                                      {+reported_dock_status === 1 ? 'WAITING FOR DOCK' : 'AT THE DOCK'}
                                    </Typography>
                                  </div>
                                )}
                                {!!arrival_date && !!departure_date && (
                                  <div
                                    className={classNames(classes.dock, {
                                      [classes.waiting]: +reported_dock_status === 1,
                                    })}
                                  >
                                    <Typography variant='overLine'>DEPARTED</Typography>
                                  </div>
                                )}
                              </div>
                            </div>

                            {leg.stops.length - 1 !== index && <div className={classes.verticalLineStops} />}
                            {!addStopDisabled && (
                              <OverLayStopsPlus
                                index={index + 1}
                                lastIndex={leg.stops.length - 1 === index}
                                onChange={(el) => onChangePlusModal(el, index + 1)}
                              />
                            )}

                            <div className={classes.stopPointContainer}>
                              <div className={classes.stopPointWrapper}>
                                <div className={classes.stopPointAlign}>
                                  <StopInfo data={contactInfo} onClickSettingsIcon={onClickSettingsIcon} stop={stop} />
                                </div>
                                {Number(infoHeader?.status_id) === 6 || isRecurring ? (
                                  <LockIcon width={16} height={16} />
                                ) : isShipmentLocked(infoHeader) ? (
                                  <ActionLocked shipment={infoHeader} size={16} />
                                ) : (
                                  !editDisabled && (
                                    <OverViewStopActions
                                      onEditStop={() => onEditStop(stop, index)}
                                      onDeleteStop={() => onDeleteStop(stop, index, leg.stops)}
                                    />
                                  )
                                )}
                              </div>

                              <div className={classes.stopPointWrapper}>
                                <div className={classes.cityDataWrapper}>
                                  <Typography variant='b2' style={style.ellipsis}>
                                    {address1 && `${address1},`}
                                  </Typography>
                                  <Typography variant='b2' style={style.ellipsis60}>
                                    {city?.name && `${city.name},`}
                                  </Typography>
                                  <Typography variant='b2' style={style.ellipsis60}>
                                    {state?.name && state?.name}
                                  </Typography>
                                  <Typography variant='b2' style={style.ellipsis60}>
                                    {zipcode && zipcode}
                                  </Typography>

                                  <CopyIcon
                                    className={classes.copyAddress}
                                    onClick={() => handleCopy(address1, state, city, zipcode)}
                                  />
                                </div>
                                <div className={classes.stopPointAlign}>
                                  <Typography variant='c2'>
                                    {+bill_type === 1 ? 'Billable Move' : 'Not Billable Move'}
                                  </Typography>
                                  {+bill_type === 1 ? <BillableMoveIcon /> : <UnexcusedIcon />}
                                </div>
                              </div>
                              <div className={classes.line} style={{ margin: '8px 0' }} />

                              <div
                                className={classes.stopCenterWrapper}
                                style={
                                  shipmentSettings?.hide_qty ||
                                  shipmentSettings?.hide_dimensions ||
                                  shipmentSettings?.hide_value ||
                                  shipmentSettings?.hide_stackable ||
                                  shipmentSettings?.hide_liftgate_service ||
                                  shipmentSettings?.hide_labor_required ||
                                  shipmentSettings?.hide_dock_high
                                    ? { minHeight: 200 }
                                    : { minHeight: 300 }
                                }
                              >
                                <div className={classes.stopChildWrapper}>
                                  <Typography variant='c1' style={style.childOne}>
                                    Scheduled Date Type
                                  </Typography>
                                  <Typography variant='c1' style={style.childTwo}>
                                    {SCHEDULED_DATE_TYPE_OBJ?.[scheduled_type]?.label}
                                  </Typography>
                                </div>

                                {!isRecurring && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Scheduled Date/Time
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {scheduled_date && `${formatDateTime(`${scheduled_date} ${from}`)}`}&nbsp;
                                      {to &&
                                        [1, 3].includes(+scheduled_type) &&
                                        ` to ${formatDateTime(`${scheduled_date_to} ${to}`)}`}
                                    </Typography>
                                  </div>
                                )}
                                {!!isShipmentTable && !!eta && (
                                  <div className={classes.stopChildWrapper}>
                                    <span style={style.childOne} className='d-flex align-items-center gap-2'>
                                      <ArrowIcon />
                                      <Typography variant='c1'>ETA</Typography>
                                      {!custom_eta && !arrival_date && <SimpleLoader loading size={14} />}
                                      {(!!arrival_date || !!delay_reason) && (
                                        <Tooltip
                                          title={
                                            delay_reason && !arrival_date ? `${delay_reason.reason?.title || ''}` : ''
                                          }
                                        >
                                          <CheckIcon width={14} height={14} fill={palette.indigo500} />
                                        </Tooltip>
                                      )}
                                      {!arrival_date && !!isETADelayed && !delay_reason && (
                                        <Tooltip title={delay_reason ? `${delay_reason.reason?.title || ''}` : ''}>
                                          <WarningIcon fill={palette.red500} />
                                        </Tooltip>
                                      )}
                                    </span>
                                    <Typography
                                      variant='c1'
                                      style={{
                                        ...style.childTwo,
                                        color:
                                          !arrival_date && isETADelayed
                                            ? delay_reason
                                              ? palette.indigo500
                                              : palette.red500
                                            : palette.gray900,
                                      }}
                                    >
                                      {eta ? convertToCustomerTime(eta) : ''}
                                      {!arrival_date && (
                                        <Typography
                                          variant='c1'
                                          style={{ color: palette.indigo500 }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setStopToUpdateEta(stop);
                                          }}
                                          className='ms-3'
                                        >
                                          {isETADelayed
                                            ? `${delay_reason ? 'Update' : 'Provide'} Reason`
                                            : 'Update ETA'}
                                        </Typography>
                                      )}
                                    </Typography>
                                  </div>
                                )}
                                {!!isShipmentTable && (
                                  <div className={classes.stopChildWrapper}>
                                    <span style={style.childOne} className='d-flex align-items-center gap-2'>
                                      <Typography variant='c1'>Arrival Date/Time</Typography>
                                      {!!arrival_date && !!isArrivalDelayed && (
                                        <Tooltip title={delay_reason ? `${delay_reason.reason?.title || ''}` : ''}>
                                          {delay_reason ? (
                                            <CheckIcon width={14} height={14} fill={palette.indigo500} />
                                          ) : (
                                            <WarningIcon fill={palette.red500} />
                                          )}
                                        </Tooltip>
                                      )}
                                    </span>
                                    <Typography
                                      variant='c1'
                                      style={{
                                        ...style.childTwo,
                                        color:
                                          !!arrival_date && isArrivalDelayed
                                            ? delay_reason
                                              ? palette.indigo500
                                              : palette.red500
                                            : palette.gray900,
                                      }}
                                    >
                                      {arrival_date ? formatDateTime(arrival_date, true) : ''}
                                      {!!arrival_date && !!isArrivalDelayed && (
                                        <Typography
                                          variant='c1'
                                          style={{ color: palette.indigo500 }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setStopToUpdateEta(stop);
                                          }}
                                          className='ms-3'
                                        >
                                          {delay_reason ? 'Update' : 'Provide'} Reason
                                        </Typography>
                                      )}
                                    </Typography>
                                  </div>
                                )}

                                {!!isShipmentTable && (
                                  <div className={classes.stopChildWrapper}>
                                    <span style={style.childOne} className='d-flex align-items-center gap-2'>
                                      <ArrowIcon />
                                      <Typography variant='c1'>Departure Date/Time</Typography>
                                    </span>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {departure_date ? formatDateTime(departure_date, true) : ''}
                                    </Typography>
                                  </div>
                                )}

                                {!!title && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Equipment Required
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {equipment_type_length_value?.length}{' '}
                                      {getEquipmentRequiredTitle(equipment_type_data)?.title}
                                    </Typography>
                                  </div>
                                )}

                                <div className={classes.stopChildWrapper}>
                                  <Typography variant='c1' style={style.childOne}>
                                    Equipment Action
                                  </Typography>
                                  <Typography variant='c1' style={style.childTwo}>
                                    {equipmentTypeText({
                                      type: stop_point_type,
                                      action: equipment_action,
                                      equipment,
                                      equipment_tbd,
                                    })}
                                  </Typography>
                                </div>

                                <div className={classes.stopChildWrapper}>
                                  <Typography variant='c1' style={style.childOne}>
                                    {getStopName(stop_point_type).title} Number
                                  </Typography>
                                  <Typography variant='c1' style={style.childTwo}>
                                    {number}
                                  </Typography>
                                </div>

                                {/* // wrong data */}
                                {+stop_point_type === 1 && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Seal ID
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {reported_seal_id || '-'}
                                    </Typography>
                                  </div>
                                )}

                                {/* // wrong data */}
                                {+stop_point_type === 2 && !!isShipmentTable && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Proof of Delivery
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {proof_of_delivery || '-'}
                                    </Typography>
                                    {/* <div className={classes.proofDelivery}> */}
                                    {/*    <Typography */}
                                    {/*        variant={'c1'} */}
                                    {/*        style={{...style.childTwo, color: palette.indigo500}}> */}
                                    {/*        pod_1233.pdf */}
                                    {/*    </Typography> */}
                                    {/* </div> */}
                                  </div>
                                )}

                                {checkNullOrUndefined(dock_high) && !shipmentSettings?.hide_dock_high && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Dock High
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {+dock_high === 0 ? 'No' : 'Yes'}
                                    </Typography>
                                  </div>
                                )}

                                {!!cargos?.length && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Commodity
                                    </Typography>
                                    <Typography
                                      variant='c1'
                                      style={{
                                        ...style.childTwo,
                                        color: cargos?.length > 1 ? palette.indigo500 : '',
                                        cursor: cargos?.length > 1 ? 'pointer' : '',
                                      }}
                                      onClick={() =>
                                        cargos?.length > 1 &&
                                        setCargosToView(
                                          cargos.map((item) => ({
                                            ...item,
                                            shipment_stop: { stop_point },
                                            expected_quantity_type: item.expected_quantity_type_data,
                                            expected_weight_type: item.expected_weight_type_data,
                                            expected_dimension_unit: item.expected_dimension_unit_data,
                                            expected_dimensions:
                                              typeof item.expected_dimensions === 'string'
                                                ? JSON.parse(item.expected_dimensions)
                                                : null,
                                          }))
                                        )
                                      }
                                    >
                                      {cargos?.length > 1 ? (
                                        <span>
                                          Multiple Commodities -{' '}
                                          <span style={{ textDecoration: 'underline' }}>See Cargo</span>
                                        </span>
                                      ) : (
                                        `${cargos?.[0]?.commodity?.title || ''}`
                                      )}
                                    </Typography>
                                  </div>
                                )}

                                {!!connected_cargos?.length && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Commodity
                                    </Typography>
                                    <Typography
                                      variant='c1'
                                      style={{
                                        ...style.childTwo,
                                        color: connected_cargos?.length > 1 ? palette.indigo500 : '',
                                        cursor: connected_cargos?.length > 1 ? 'pointer' : '',
                                      }}
                                      onClick={() =>
                                        connected_cargos?.length > 1 &&
                                        setCargosToView(
                                          connected_cargos.map((item) => ({
                                            ...item,
                                            delivery_stop: { stop_point },
                                            expected_quantity_type: item.expected_quantity_type_data,
                                            expected_weight_type: item.expected_weight_type_data,
                                            expected_dimension_unit: item.expected_dimension_unit_data,
                                            expected_dimensions:
                                              typeof item.expected_dimensions === 'string'
                                                ? JSON.parse(item.expected_dimensions)
                                                : null,
                                          }))
                                        )
                                      }
                                    >
                                      {connected_cargos?.length > 1 ? (
                                        <span>
                                          Multiple Commodities -{' '}
                                          <span style={{ textDecoration: 'underline' }}>See Cargo</span>
                                        </span>
                                      ) : (
                                        `${connected_cargos?.[0]?.commodity?.title || ''}`
                                      )}
                                    </Typography>
                                  </div>
                                )}

                                {checkNullOrUndefined(quantity) && !shipmentSettings?.hide_qty && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Qty
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {quantity}&nbsp;
                                      {QUANTITY_TYPE[quantity_type]?.label}
                                    </Typography>
                                  </div>
                                )}

                                {checkNullOrUndefined(quantity) && +stop_point_type === 1 && !!isShipmentTable && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Actual Qty
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {reported_quantity
                                        ? `${reported_quantity} ${QUANTITY_TYPE[quantity_type]?.label}`
                                        : '-'}
                                    </Typography>
                                  </div>
                                )}

                                {(Number(stop_point_type) === 1 || Number(stop_point_type) === 2) &&
                                  !shipmentSettings?.hide_dimensions && (
                                    <div className={classes.stopChildWrapper}>
                                      <Typography variant='c1' style={style.childOne}>
                                        Dimensions
                                      </Typography>
                                      <Typography variant='c1' style={style.childTwo}>
                                        {((Number(stop_point_type) === 1 && cargos?.length <= 1) ||
                                          (Number(stop_point_type) === 2 && connected_cargos?.length < 1)) && (
                                          <>
                                            {!l && !w && !h ? '-' : ''}
                                            {!!l && `${formatNumber(l)}L x`} {!!w && `${formatNumber(w)}W x`}{' '}
                                            {!!h && `${formatNumber(h)}H`}
                                          </>
                                        )}
                                        {((Number(stop_point_type) === 1 && cargos?.length > 1) ||
                                          (Number(stop_point_type) === 2 && connected_cargos?.length > 1)) &&
                                        totalValues.volumeInCubicFeet ? (
                                          <>
                                            {' '}
                                            {formatNumber(totalValues.volumeInCubicFeet)}{' '}
                                            <Typography variant='c3'>CU. FT.</Typography>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </Typography>
                                    </div>
                                  )}

                                {checkNullOrUndefined(stackable) && !shipmentSettings?.hide_stackable && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Stackable
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {+stackable === 0 ? 'No' : 'Yes'}
                                    </Typography>
                                  </div>
                                )}

                                {checkNullOrUndefined(weight) && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Weight
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {formatNumber(weight)}{' '}
                                      {typeof weight_types === 'object' ? weight_types?.unit : ''}
                                    </Typography>
                                  </div>
                                )}

                                {+stop_point_type === 1 && !!isShipmentTable && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Actual Weight
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {reported_weight
                                        ? `${formatNumber(reported_weight)} ${
                                            typeof weight_types === 'object'
                                              ? WEIGHT_TYPE_OBJ[weight_types?.id].label
                                              : ''
                                          }`
                                        : '-'}
                                    </Typography>
                                  </div>
                                )}

                                {+stop_point_type === 1 && !!isShipmentTable && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      {billOfLadingTitle}
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {bill_of_lading_id || '-'}
                                    </Typography>
                                  </div>
                                )}

                                {+stop_point_type === 1 &&
                                  checkNullOrUndefined(value) &&
                                  !shipmentSettings?.hide_value && (
                                    <div className={classes.stopChildWrapper}>
                                      <Typography variant='c1' style={style.childOne}>
                                        Value
                                      </Typography>
                                      <Typography variant='c1' style={style.childTwo}>
                                        {currency}
                                        {numberWithCommas(value)}
                                      </Typography>
                                    </div>
                                  )}

                                {+stop_point_type === 1 &&
                                  checkNullOrUndefined(liftgate_service) &&
                                  !shipmentSettings?.hide_liftgate_service && (
                                    <div className={classes.stopChildWrapper}>
                                      <Typography variant='c1' style={style.childOne}>
                                        Liftgate Service
                                      </Typography>
                                      <Typography variant='c1' style={style.childTwo}>
                                        {+liftgate_service === 0 ? 'No' : 'Yes'}
                                      </Typography>
                                    </div>
                                  )}

                                {checkNullOrUndefined(labor_required) && !shipmentSettings?.hide_labor_required && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography variant='c1' style={style.childOne}>
                                      Labor Required
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {+labor_required === 0 ? 'No' : 'Yes'}
                                    </Typography>
                                  </div>
                                )}

                                {checkNullOrUndefined(hazardous_materials) &&
                                  !shipmentSettings?.hide_hazardous_materials &&
                                  +stop_point_type === 1 && (
                                    <div className={classes.stopChildWrapper}>
                                      <Typography variant='c1' style={style.childOne}>
                                        Hazardous Materials
                                      </Typography>
                                      <Typography variant='c1' style={style.childTwo}>
                                        {+hazardous_materials === 0 ? 'No' : 'Yes'}
                                      </Typography>
                                    </div>
                                  )}

                                {+require_signature_on_stop === 1 && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography style={style.childOne} variant='c1'>
                                      Signature
                                    </Typography>
                                    <Typography variant='c1' style={style.childTwo}>
                                      {signature_name || '-'}
                                    </Typography>
                                  </div>
                                )}

                                {!!signature && +require_signature_on_stop === 1 && (
                                  <div className={classes.stopChildWrapper}>
                                    <Typography style={style.childOne} variant='c1'>
                                      Signature
                                    </Typography>
                                    <div className={classes.signature}>
                                      <img src={signature} alt='signature' />
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className={classes.stopNotesWrapper}>
                                <div className={classes.line} style={{ margin: '8px 0' }} />
                                <Typography variant='button1' style={{ fontWeight: '600' }}>
                                  Stop Notes
                                </Typography>
                                <Typography variant='c1' style={style.notes}>
                                  {notes_text}
                                </Typography>
                              </div>
                              <div style={{ height: '30px' }} className='d-flex justify-content-end gap-3'>
                                {Number(stopToCheckInOut?.id) === Number(id) &&
                                  isShipmentTable &&
                                  Number(infoHeader?.status_id) !== 6 &&
                                  Number(infoHeader?.status_id) !== 8 && (
                                    <div className='d-flex justify-content-end' style={{ marginTop: 8 }}>
                                      <CustomButton
                                        styleTitle={{ fontSize: 14 }}
                                        styleButton={{ padding: '1px 12px', margin: 0 }}
                                        type='secondary'
                                        title={arrival_date ? 'Check Out' : 'Check In'}
                                        onClick={onCheckInOutClick}
                                      />
                                    </div>
                                  )}

                                {infoHeader?.shipment_stops?.[0]?.id === id &&
                                  !!isShipmentTable &&
                                  Number(infoHeader?.status_id) !== 6 &&
                                  Number(infoHeader?.status_id) !== 8 &&
                                  !!arrival_date &&
                                  !departure_date && (
                                    <div className='d-flex justify-content-end' style={{ marginTop: 8 }}>
                                      <CustomButton
                                        styleTitle={{ fontSize: 14 }}
                                        styleButton={{ padding: '1px 12px', margin: 0 }}
                                        type='danger'
                                        title='Dry Run'
                                        onClick={onDryRunClick}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {!!swapingStop && (
        <UpdateStopSchedule
          open={!!swapingStop}
          onClose={() => setSwapingStop(null)}
          swapData={swapingStop}
          onSuccess={onConfirmSwap}
          isRecurring={isRecurring}
        />
      )}
      {!!stopToUpdateEta && (
        <UpdateETA
          open={!!stopToUpdateEta}
          onClose={() => setStopToUpdateEta(null)}
          shipment={infoHeader}
          stop={stopToUpdateEta}
          onSuccess={onUpdateSuccess}
        />
      )}
      {!!cargosToView && (
        <ShipmentCommodity open={!!cargosToView} onClose={() => setCargosToView(null)} cargo={cargosToView} readOnly />
      )}
    </div>
  );
};

export default OverViewStop;
