import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getCustomerInfo } from 'Api/JobPositions';
import { getNotificationSettings } from 'Api/PayrollSettings';
import TableAccordion from '../TableAccordion';
import { STableHeaderWrapper } from '../../TimeTracking.styles';

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

const AllUsersTitle = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='button2' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useTimeTrackingColumns = ({ toggleAccordion, onUpdateTimeTracking, dateRange, onDelete }) => {
  const [company, setCompany] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));

  const getCompanyInfo = async () => {
    try {
      const response = await getCustomerInfo(user.customer.dot);
      const { data: notificationSettings } = await getNotificationSettings();
      setCompany({ ...response.data, email: notificationSettings?.correspondence_email || response?.data?.email });
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);

  return [
    {
      field: 'full_name',
      title: (
        <STableHeaderWrapper>
          <Title title='USER' />
          <Title title='TIME TRACKED' />
          <Title title='PAY PER HOUR' />
          <Title title='ESTIMATED COST' />
          <div />
        </STableHeaderWrapper>
      ),
      cellStyle: (e, row) => (row.isActive ? { backgroundColor: palette.indigo0 } : {}),
      render: (row) =>
        row.type === 'allUsers' ? (
          <STableHeaderWrapper>
            <AllUsersTitle title='All Users' />
            {/* <AllUsersTitle title={row.total_time_worked || `${row.totalHours}h ${row.totalMinutes}m`} /> */}
            {/* <AllUsersTitle title={`$${row.total_pay_per_hours || row.payPerHour}`} /> */}
            {/* <AllUsersTitle title={`$${row.estimatedCost.toFixed(2)}`} /> */}
            {/* TODO: Remove comments when data from backend will be ready */}
            <AllUsersTitle title={`${row.totalHours}h ${row.totalMinutes}m`} />
            <AllUsersTitle title={`$${row.payPerHour}`} />
            <AllUsersTitle title={`$${row.estimatedCost.toFixed(2)}`} />
            <div />
          </STableHeaderWrapper>
        ) : (
          <TableAccordion
            row={row}
            toggleAccordion={toggleAccordion}
            onUpdateTimeTracking={onUpdateTimeTracking}
            company={company}
            dateRange={dateRange}
            onDelete={onDelete}
          />
        ),
    },
  ];
};
