import React from 'react';

const Notification = ({ icon, Notification }) => {
  return (
    <div style={{ position: 'relative' }}>
      {Notification !== 0 && Notification !== undefined && (
        <div className='badge' style={{ display: 'flex' }}>
          {Notification}
        </div>
      )}
      <img src={icon} alt='notification' />
    </div>
  );
};

export default Notification;
