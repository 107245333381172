import React from 'react';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import ErrorMessage from 'common/ErrorMessage';

const DeliveryOptions = ({ form }) => {
  const { values, handleChange, touchedErrors } = form;

  return (
    <div className='d-flex flex-column gap-2 mb-4'>
      <div className='d-flex flex-column gap-1'>
        <InputLabel required variant='button2' className='mb-0'>
          Delivery Options
        </InputLabel>
        <ErrorMessage
          error={(touchedErrors.send_email || touchedErrors.print_mail) && 'At least one delivery option is required!'}
        />
      </div>
      <div>
        <div>
          <CustomCheckbox
            name='send_email'
            checked={!!values.send_email}
            onChange={(checked) => handleChange('send_email', checked)}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Send Email
            </Typography>
          </CustomCheckbox>
          <Typography variant='c1' style={{ color: palette.gray400, marginLeft: 24 }}>
            Emails will include quote link with quote PDF and quote accept or decline including any comments.
          </Typography>
        </div>
      </div>
      <div className='delivery-option'>
        <div>
          <CustomCheckbox
            name='print_mail'
            checked={!!values.print_mail}
            onChange={(checked) => handleChange('print_mail', checked)}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
              Print & Mail
            </Typography>
          </CustomCheckbox>
          <Typography variant='c1' style={{ color: palette.gray400, marginLeft: 24 }}>
            Documents will be saved to your computer and you can print / mail.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOptions;
