import { array, number, object } from 'yup';

export const validationSchema = array().of(
  object().shape({
    reason: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
  })
);
