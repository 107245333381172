import React from 'react';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { SHeaderWrapper, SBackButton, SLink } from 'pages/Accounting/Accounts/BankFeed/BankFeed.styles';

const Header = ({ account, onRefresh, onRulesClick, loadingRefresh }) => {
  const { account_name, reconcile_count } = account || {};

  return (
    <SHeaderWrapper>
      <SBackButton>
        <ArrowLeft width={10} height={8} />
        <SLink to={`/accounting/accounts?id=${account?.id}`}>Back</SLink>
      </SBackButton>
      <div className='actions-title-wrapper'>
        <div className='d-flex align-items-center gap-4'>
          <Typography variant='h3'>Reconcile {account_name}</Typography>
          {!!reconcile_count && (
            <Chip
              label={`RECONCILE ${reconcile_count} ITEMS`}
              labelColor={palette.goldDark}
              bgColor={palette.goldLight}
              fontSize='13px'
            />
          )}
        </div>
        <div className='actions-wrapper'>
          <CustomButton
            title='Rules'
            type='secondary'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={onRulesClick}
          />
          <CustomButton
            title='Refresh Feed'
            type='secondary'
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={onRefresh}
            disabled={loadingRefresh}
          />
        </div>
      </div>
    </SHeaderWrapper>
  );
};

export default Header;
