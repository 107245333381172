import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import { palette } from 'utils/constants';
import Backdrop from '@mui/material/Backdrop';
import CustomModal from 'components/CustomModal/CustomModal';

export const SInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 6px 6px 0;
    height: 33px;
  }

  .MuiAutocomplete-hasPopupIcon {
    width: 150px;
  }
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 32px;
    border-bottom: 1px solid ${palette.gray100};
    padding: 0 12px;

    .MuiTab-root {
      padding: 10px 4px;
      margin: 0 8px;
      min-height: 32px;
      min-width: auto;
      text-transform: none;

      :hover {
        color: ${palette.gray900};
      }

      &.Mui-selected {
        color: ${palette.indigo500};
      }
    }

    .MuiTabs-indicator {
      background-color: ${palette.indigo500};
    }
  }
`;

export const SPaper = styled(Paper)`
  .MuiAutocomplete-listbox {
    max-height: 30vh;
  }
`;

export const SCustomModal = styled(CustomModal)`
  .modal-content {
    min-width: ${({ $minWidth }) => $minWidth || '700px'};
    max-width: ${({ $maxWidth }) => $maxWidth || ''};
    height: ${({ $height }) => $height || 'auto'};

    .info-section {
      margin-top: 32px;

      .iframe-wrapper {
        margin-top: 12px;
      }
    }

    .customBody {
      background: ${({ $bgColor }) => $bgColor || palette.white} !important;
      padding: ${({ padding }) => padding || '16px 20px'} !important;
      height: 100%;
      max-height: ${({ $bodyMaxHeight }) => $bodyMaxHeight || 'calc(100vh - 200px)'};
      overflow: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .close-modal-icon {
        position: absolute;
        top: 22px;
        right: 18px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100% !important;
      position: relative;
    }

    .customHeader {
      border-bottom: 1px solid ${palette.gray50};
    }

    .customFooter {
      border-top: 1px solid ${palette.gray50};
    }
  }
`;

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 9999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;
