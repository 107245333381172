import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
`;

export const SAttachmentWrapper = styled.div`
  .upload-document-deposit {
    width: 100%;
    position: relative;
    border: 1px dashed ${palette.gray200};
    background: ${palette.white};
    color: ${palette.gray900};
  }
`;
