import styled from 'styled-components';

export const SInputWrapper = styled.div`
  width: 100px;
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const SSearchInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 14px;
  margin-top: 12px;
`;
