import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { STable, SText } from '../CardLimits/CardLimits.styles';
import { SCustomModal } from '../../Cards.styles';

const typeMapper = {
  LOAD: 'Load',
  RMVE: 'Remove',
};

const CashHistory = ({ open, onClose, data }) => {
  const { cash_history } = data || {};
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Cash History'
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='800px'
      padding='24px 0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <div style={{ padding: '0 24px' }}>
        <STable>
          <thead>
            <tr>
              <th>DATE</th>
              <th>TYPE</th>
              <th>AMOUNT</th>
              <th>TRANSACTION ID</th>
              <th>REFERENCE ID</th>
              <th>LOADED BY</th>
            </tr>
          </thead>
          <tbody>
            {cash_history?.map((item) => (
              <tr key={item.id}>
                <td>
                  <SText>{formatDate(item.date)}</SText>
                </td>
                <td>
                  <SText>{typeMapper[item.cash_id]}</SText>
                </td>
                <td>
                  <SText>
                    {currency}
                    {formatNumber(item.amount)}
                  </SText>
                </td>
                <td>
                  <SText>{item.transaction_id || 0}</SText>
                </td>
                <td>
                  <SText>{item.ref_number || '-'}</SText>
                </td>
                <td>
                  <SText>{item.name}</SText>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!cash_history?.length && (
          <div className='text-center mt-2'>
            <Typography>No Records</Typography>
          </div>
        )}
      </div>
    </SCustomModal>
  );
};

export default CashHistory;
