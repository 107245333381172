import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/drivers/inbox.svg';
import { ReactComponent as UsaFlag } from 'assets/icons/drivers/usaFlag.svg';
import { ReactComponent as Tsa } from 'assets/icons/drivers/tsa.svg';
import { ReactComponent as Fast } from 'assets/icons/drivers/fast.svg';
import { ReactComponent as Twic } from 'assets/icons/drivers/twic.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import PhoneInput from 'common/PhoneInput';
import Autocomplete from 'common/Autocomplete';
import PublicFooter from 'componentsV2/PublicFooter';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import {
  createContactBookByToken,
  getBrokerageSettings,
  getCarrierByToken,
  getCarrierContactBookByToken,
  getContactBookTypes,
  updateContactBook,
} from 'Api/Carriers';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import call from 'assets/icons/drivers/call.svg';
import Chip from 'common/Chip';
import CanadaFlag from 'assets/icons/drivers/turkeyFlag.svg';
import Mexico from 'assets/icons/drivers/mexico.png';
import EquipmentTypes from 'components/Carriers/CarrierProfile/EquipmentTypes';
import { STable, ScrollableContainer } from './CarrierContactBook.styles';
import { getContactBookInitialValues, initialValues } from './CarrierContactBook.data';
import { validationSchema } from './validationSchema';
import Header from './components/Header';

const CarrierContactBook = () => {
  const showToaster = useShowToaster();
  const { dot, token } = useParams();
  const { use } = useTheme();
  const [typeData, setTypeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactBook, setContactBook] = useState(null);
  const [company, setCompany] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const equipmentTypes = carrier?.equipment_types || [];

  const onSubmit = async (values) => {
    try {
      const contactsToCreate = values.filter((i) => !!i.isNew);
      const contactsToEdit = values.filter((i) => !i.isNew);
      const contacts = contactsToCreate.map((item) => {
        return {
          contact_name: item.contact_name,
          phone_number: item.phone_number,
          contact_type_id: item.contact_type_id?.id,
          contact_email: item.contact_email,
          receive_offers: Number(item.receive_offers),
          check_calls: Number(item.check_calls),
          inactive: Number(!item.inactive),
        };
      });

      const data = {
        carrier_id: carrier.id,
        contact_books: contacts,
      };

      if (contactsToCreate.length) {
        await createContactBookByToken(dot, token, data);
      }
      if (contactsToEdit.length) {
        const promises = contactsToEdit.map((item) => {
          const body = {
            contact_name: item.contact_name,
            phone_number: item.phone_number,
            contact_type_id: item.contact_type_id?.id,
            contact_email: item.contact_email,
            receive_offers: Number(item.receive_offers),
            check_calls: Number(item.check_calls),
            inactive: Number(!item.inactive),
          };
          return updateContactBook(dot, token, item.id, body);
        });
        await Promise.all(promises);
        showToaster({ type: 'success', message: 'Changes have been saved' });
      }
    } catch (e) {
      setLoading(false);
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const { values, handleChange, touchedErrors } = useForm({
    initialValues: getContactBookInitialValues(contactBook),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const getTypeData = async () => {
    try {
      const { data } = await getContactBookTypes(dot, token);
      setTypeData(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCompanyInfo = async () => {
    try {
      const { data } = await getBrokerageSettings(dot, token);
      setCompany(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCarrierData = async () => {
    try {
      const { data } = await getCarrierByToken(dot, token);
      setCarrier(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getTypeData();
    getCompanyInfo();
    getCarrierData();
  }, []);

  useEffect(async () => {
    const currentContactBook = await getCarrierContactBookByToken(dot, token);
    setContactBook(currentContactBook?.data);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div>
      <Header company={company} />
      <ScrollableContainer>
        <div className='profile-container' style={{ padding: '24px 36px' }}>
          <div className='carrier-profile-header'>
            <div>
              <div className='user-details-container'>
                <div>
                  {carrier && (
                    <div className='user-name-and-actions-container'>
                      {carrier?.logo_path ? (
                        <img
                          src={carrier.logo_path}
                          alt='img'
                          width={30}
                          height={30}
                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                        />
                      ) : (
                        <CarrierIcon width={30} height={30} style={{ borderRadius: '50%', objectFit: 'cover' }} />
                      )}
                      <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {carrier?.name}
                      </Typography>
                      <div className='action-icons'>
                        <a href={`mailto:${carrier?.email}`}>
                          <EmailIcon />
                        </a>
                        <a href={`tel:${carrier?.phone_number}`}>
                          <img src={call} alt='call-ic on' />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='boxes-wrapper mt-3'>
            <div className='boxes-wrapper-item'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                MC
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {carrier?.mc || '-'}
              </Typography>
            </div>
            <div className='boxes-wrapper-item'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                DOT
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {carrier?.dot}
              </Typography>
            </div>
            <div className='boxes-wrapper-item'>
              <div className='d-flex justify-content-between me-2'>
                <Typography variant='b2' style={{ color: palette.gray500 }}>
                  Can Travel To
                </Typography>
              </div>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {!!carrier?.can_travel_to?.includes('1') && <UsaFlag />}
                {!!carrier?.can_travel_to?.includes('2') && (
                  <img src={CanadaFlag} alt='mexico-Flag' className='mexico-flag-icon' />
                )}
                {!!carrier?.can_travel_to?.includes('3') && (
                  <img src={Mexico} alt='mexico-Flag' className='mexico-flag-icon' />
                )}
                {!carrier?.can_travel_to?.length && '-'}
              </Typography>
            </div>
            <div className='boxes-wrapper-item'>
              <div className='d-flex justify-content-between me-2'>
                <Typography variant='b2' style={{ color: palette.gray500 }}>
                  Approved For
                </Typography>
              </div>
              <div className='d-flex gap-2'>
                {!!carrier?.approved_for?.includes('1') && <Tsa width={30} height={30} />}
                {!!carrier?.approved_for?.includes('2') && <Fast width={40} height={30} />}
                {!!carrier?.approved_for?.includes('3') && <Twic width={40} height={30} />}
                {!!carrier?.approved_for?.includes('4') && (
                  <Typography>{carrier?.approved_for_custom || '-'}</Typography>
                )}
                {!carrier?.approved_for?.length && '-'}
              </div>
            </div>

            <div className='boxes-wrapper-item'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Power Units
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {carrier?.power_unit || '-'}
              </Typography>
            </div>
            <div className='boxes-wrapper-item'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                FMCSA Op. Status
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {carrier?.operating_status === 1 ? 'Authorized' : 'Not Authorized'}
              </Typography>
            </div>
            <div className='boxes-wrapper-item'>
              <div className='d-flex justify-content-between me-2'>
                <Typography variant='b2' style={{ color: palette.gray500 }}>
                  Equipment Type
                </Typography>
              </div>
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                <Typography variant='s2' style={{ color: palette.gray900 }}>
                  {equipmentTypes.slice(0, 2).map((item) => (
                    <Chip
                      key={item.id}
                      size='medium'
                      label={item?.title}
                      labelColor={palette.indigo500}
                      fontWeight={500}
                      bgColor={palette.indigo0}
                      height='20px'
                      style={{ marginRight: '5px' }}
                    />
                  ))}
                  {equipmentTypes.length > 2 && (
                    <Typography variant='c1' style={{ color: palette.indigo500 }} onClick={() => setShowMoreOpen(true)}>
                      Show More
                    </Typography>
                  )}
                </Typography>
              </Typography>
            </div>
            <div className='boxes-wrapper-item'>
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                Cargo Carried
              </Typography>
              <br />
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {carrier?.cargo_carried || '-'}
              </Typography>
            </div>
          </div>
        </div>
        <div className='contact-book-table'>
          <STable>
            <thead>
              <tr className='header-row'>
                <th>
                  <InputLabel required>Name</InputLabel>
                </th>
                <th>
                  <InputLabel required>Email</InputLabel>
                </th>
                <th>
                  <InputLabel required>Phone Number</InputLabel>
                </th>
                <th>
                  <InputLabel required>Type</InputLabel>
                </th>
                <th>
                  <InputLabel>Receive Offers</InputLabel>
                </th>
                <th>
                  <InputLabel>Allow Check Calls</InputLabel>
                </th>
                <th>
                  <InputLabel>Receive Communications</InputLabel>
                </th>
              </tr>
            </thead>
            <tbody>
              {values.map((item, index) => (
                <tr key={item.id}>
                  <td>
                    <Input
                      name={`${index}.contact_name`}
                      onChange={handleChange}
                      value={values[index].contact_name}
                      error={touchedErrors[index]?.contact_name}
                    />
                  </td>
                  <td>
                    <Input
                      name={`${index}.contact_email`}
                      onChange={handleChange}
                      value={values[index].contact_email}
                      error={touchedErrors[index]?.contact_email}
                    />
                  </td>
                  <td>
                    <PhoneInput
                      name={`${index}.phone_number`}
                      placeholder='+1 (555) 555-1234'
                      onChange={(val) => handleChange(`${index}.phone_number`, val)}
                      value={values[index].phone_number}
                      error={touchedErrors[index]?.phone_number}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      width='150px'
                      labelKey='title'
                      name={`${index}.contact_type_id`}
                      value={values[index].contact_type_id}
                      options={typeData}
                      onChange={(e, val) => handleChange(`${index}.contact_type_id`, val)}
                      error={touchedErrors[index]?.contact_type_id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </td>
                  <td>
                    <CustomCheckbox
                      name={`${index}.receive_offers`}
                      type='switch'
                      smail={false}
                      checked={values[index].receive_offers}
                      onChange={(checked) => handleChange(`${index}.receive_offers`, checked)}
                    />
                  </td>
                  <td>
                    <CustomCheckbox
                      name={`${index}.check_calls`}
                      type='switch'
                      smail={false}
                      checked={values[index].check_calls}
                      onChange={(checked) => handleChange(`${index}.check_calls`, checked)}
                    />
                  </td>
                  <td>
                    <div className='d-flex align-items-center gap-2'>
                      <Typography variant='overLine'>UNSUBSCRIBE</Typography>
                      <CustomCheckbox
                        name={`${index}.inactive`}
                        type='switch'
                        smail={false}
                        checked={values[index].inactive}
                        onChange={(checked) => handleChange(`${index}.inactive`, checked)}
                      />
                      <Typography variant='overLine'>ACTIVE</Typography>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </STable>
          <CustomButton
            type='secondary'
            title='Add Another'
            styleTitle={{ fontSize: 14, color: palette.indigo500, marginLeft: 8 }}
            styleButton={{ padding: '7px 12px', boxShadow: 'none', backgroundColor: 'transparent', paddingLeft: 0 }}
            onClick={() => handleChange(values.length, { ...initialValues, id: Date.now() })}
            leftIcon={<PlusIcon fill={palette.indigo500} />}
          />
          <div className='d-flex justify-content-end'>
            <CustomButton
              type='primary'
              title='Save Changes'
              styleButton={{ padding: '7px 12px', boxShadow: 'none' }}
              onClick={() => onSubmit(values)}
            />
          </div>
        </div>
        {showMoreOpen && (
          <EquipmentTypes open={showMoreOpen} onClose={() => setShowMoreOpen(false)} equipmentTypes={equipmentTypes} />
        )}
      </ScrollableContainer>
      <PublicFooter />
    </div>
  );
};

export default CarrierContactBook;
