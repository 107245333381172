import React, { useEffect, useState } from 'react';
import { Collapse, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as DeleteItemIcon } from 'assets/icons/delete.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';

import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { getTrailers, getVehicles } from 'Api/OfferedShipments';
import { SPopover, SWrapper } from './FilterOptions.styles';

const FilterOptions = ({ filter, updateFilter, groups }) => {
  const [show, setShow] = useState(false);
  const [collapses, setCollapses] = useState({ groups: false, equipmentTypes: false });
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [localFilter, setLocalFilter] = useState({
    groups: [...filter.groups],
    equipmentTypes: [...filter.equipmentTypes],
  });

  function onToggleCollapse(name) {
    setCollapses((prevState) => {
      return {
        types: false,
        [name]: !prevState[name],
      };
    });
  }

  function onTickCheckboxes(type, title) {
    const updatedValue = [...localFilter[type]];
    if (updatedValue.includes(title)) {
      const index = updatedValue.findIndex((itemTitle) => itemTitle === title);
      updatedValue.splice(index, 1);
    } else {
      updatedValue.push(title);
    }
    const newState = {
      ...localFilter,
      [type]: updatedValue,
    };
    setLocalFilter(newState);

    return newState;
  }

  const deleteFilterHandler = (type, title) => {
    const localFilter = onTickCheckboxes(type, title);
    applyHandler(localFilter);
  };

  const applyHandler = () => {
    setShow(false);
    updateFilter({ ...filter, ...localFilter });
  };

  const onReset = () => {
    const filterResetState = {
      groups: [],
      equipmentTypes: [],
    };
    setLocalFilter(filterResetState);
    updateFilter({ ...filter, ...filterResetState });
  };

  const getEquipmentTypeOptions = async () => {
    try {
      const [{ data: vehicles }, { data: trailers }] = await Promise.all([getVehicles(), getTrailers()]);
      setEquipmentTypes([
        ...vehicles.map((item) => ({ title: item.title === 'Truck' ? 'Truck (Power Only)' : item.title, id: item.id })),
        ...trailers.map((item) => ({ title: item.title, id: item.id })),
      ]);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getEquipmentTypeOptions();
  }, []);

  const groupsCount = filter?.groups?.length || 0;
  const equipmentTypesCount = filter?.equipmentTypes?.length || 0;
  const optionsCount = groupsCount + equipmentTypesCount;

  const content = (
    <SPopover>
      <div className='overlay-header'>
        <Typography variant='h5'>Options</Typography>
      </div>

      <div className='overlay-body'>
        <div className='overlay-status-wrapper'>
          <div className={`${'closed'}`} onClick={() => onToggleCollapse('groups')}>
            <Typography variant='s1'>Tags</Typography>
            <ArrowDown style={{ transform: `rotateX(${collapses.groups ? 180 : 0}deg)` }} />
          </div>
          <Collapse in={collapses.groups}>
            <div className='opened'>
              {groups?.map((currentType) => {
                const { id, title } = currentType;
                return (
                  <CustomCheckbox
                    key={id}
                    style={{ margin: '0 0 5px 10px', width: 'calc(50% - 30px)' }}
                    checked={localFilter.groups?.includes(id)}
                    onChange={() => onTickCheckboxes('groups', id)}
                  >
                    <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                      {title}
                    </Typography>
                  </CustomCheckbox>
                );
              })}
            </div>
          </Collapse>
        </div>
        <div className='overlay-status-wrapper'>
          <div className={`${'closed'}`} onClick={() => onToggleCollapse('equipmentTypes')}>
            <Typography variant='s1'>Equipment Type</Typography>
            <ArrowDown style={{ transform: `rotateX(${collapses.equipmentTypes ? 180 : 0}deg)` }} />
          </div>
          <Collapse in={collapses.equipmentTypes}>
            <div className='opened'>
              {equipmentTypes?.map((currentType) => {
                const { id, title } = currentType;
                return (
                  <CustomCheckbox
                    key={id}
                    style={{ margin: '0 0 5px 10px', width: 'calc(50% - 30px)' }}
                    checked={localFilter.equipmentTypes?.includes(id)}
                    onChange={() => onTickCheckboxes('equipmentTypes', id)}
                  >
                    <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                      {title}
                    </Typography>
                  </CustomCheckbox>
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
      <div className='overlay-footer'>
        <CustomButton
          title='Cancel'
          type='secondary'
          onClick={() => setShow(false)}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
        />
        <CustomButton
          title='Apply'
          type='primary'
          onClick={() => applyHandler()}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
        />
      </div>
    </SPopover>
  );

  return (
    <SWrapper>
      <div className='title-wrapper'>
        <OverlayTrigger show={show} trigger='click' rootClose placement='right' overlay={content} onToggle={setShow}>
          <div>
            <Typography variant='overLine' className='pointer'>
              <FilterIcon width={11} height={11} style={{ marginRight: 4 }} fill={palette.gray700} />
              Options: {optionsCount}
            </Typography>
          </div>
        </OverlayTrigger>
        <Typography
          variant='overLine'
          style={{ marginLeft: 8, marginTop: 4, cursor: 'pointer', color: palette.indigo500 }}
          onClick={onReset}
        >
          Clear All
        </Typography>
      </div>
      <div className='mapped-data-container'>
        <SelectedFilter
          title='Tags'
          selectedTitleKey='title'
          selectedIds={filter.groups}
          allData={groups}
          onDelete={(title) => deleteFilterHandler('groups', title)}
        />
        <SelectedFilter
          title='Equipment Type'
          selectedTitleKey='title'
          selectedIds={filter.equipmentTypes}
          allData={equipmentTypes}
          onDelete={(title) => deleteFilterHandler('equipmentTypes', title)}
        />
      </div>
    </SWrapper>
  );
};

const SelectedFilter = ({ title, selectedTitleKey, selectedIds, allData, onDelete }) => {
  const [dataMap, setDataMap] = useState([]);

  useEffect(() => {
    const newDataMap = selectedIds?.map((id) => {
      return allData?.find((data) => data.id === id);
    });
    setDataMap(newDataMap || []);
  }, [selectedIds]);

  if (dataMap?.length === 0) {
    return null;
  }

  return (
    <div className='mapped-data-wrapper'>
      <Typography
        variant='c1'
        style={{ color: palette.indigo500, marginTop: 4, background: palette.indigo0, padding: '0 6px 0 6px' }}
      >
        {title}:
      </Typography>
      {dataMap?.map((item, index) => (
        <div key={item.id} className='many-data-show-wrapper'>
          <Typography
            variant='c1'
            style={{ whiteSpace: 'nowrap', background: palette.indigo0, color: palette.indigo700 }}
          >
            {item[selectedTitleKey]}
            {index !== dataMap.length - 1 && ','}
            &nbsp;
          </Typography>
          <div className='delete-item-wrapper' onClick={() => onDelete(item.title)}>
            <DeleteItemIcon width={8} height={8} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilterOptions;
