import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import wexIcon from 'assets/images/wex.png';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/drivers/table-logo/right-arrow.svg';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { GetAllConnectedSeries } from 'Api/Driver';
import { SUnderlineText } from './AddProvider.styles';
import { SCustomModal } from '../Cards/Cards.styles';

const AddProvider = ({ open, onClose }) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [isFleetCardConnected, setIsFleetCardConnected] = useState(false);
  const [isFleetOneConnected, setIsFleetOneConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  const getConnectedServices = async () => {
    setLoading(true);
    try {
      const { data } = await GetAllConnectedSeries();
      const wexFleetCard = data?.find((i) => i.id === 44);
      const wexFleetOne = data?.find((i) => i.id === 62);

      if (wexFleetCard?.connected) {
        setIsFleetCardConnected(true);
      }
      if (wexFleetOne?.connected) {
        setIsFleetOneConnected(true);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConnectedServices();
  }, []);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Use Current Card Provider'
      $maxWidth='470px'
      $minWidth='470px'
    >
      <CloseIcon className='close-modal-icon' fill={palette.indigo500} onClick={onClose} />
      <div>
        <Typography variant='button2'>Use Current Card Provider</Typography>
      </div>
      <div style={{ marginBottom: '24px' }}>
        <Typography variant='overLine2'>
          If you already have a fleet card, you can connect your provider to Truckin Digital! Manage, set limits and
          view transactions in one platform.
        </Typography>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <Typography variant='s2'>Supported providers:</Typography>
      </div>
      <div
        style={{
          border: `1px solid ${palette.gray50}`,
          borderRadius: '8px',
          display: 'flex',
          padding: '19px 16px',
          marginBottom: '12px',
          gap: '16px',
        }}
      >
        <div>
          <img src={wexIcon} alt='wex' />
        </div>
        <div>
          <div>
            <Typography variant='button2'>WEX EFS Fleet Card</Typography>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Typography variant='overLine2'>
              Connect your existing WEX EFS account with Truckin Digital! Set purchase limits in real-time, down to the
              individual card level all from your Truckin Digital Card Management module. Consolidate transactions
              giving you one place to manage purchases, cash advances, and more.
            </Typography>
          </div>
          {!loading &&
            (isFleetCardConnected ? (
              <Typography variant='button1' style={{ color: use(palette.green500, palette.indigo50) }}>
                Connected
              </Typography>
            ) : (
              <SUnderlineText onClick={() => navigate('/connectedservicesediapiintegrations')}>
                <Typography variant='button1' style={{ color: use(palette.indigo500, palette.indigo50) }}>
                  Get Started
                </Typography>
                <ArrowIcon fill={palette.indigo500} />
              </SUnderlineText>
            ))}
        </div>
      </div>
      <div
        style={{
          border: `1px solid ${palette.gray50}`,
          borderRadius: '8px',
          display: 'flex',
          padding: '19px 16px',
          gap: '16px',
        }}
      >
        <div>
          <img src={wexIcon} alt='wex' />
        </div>
        <div>
          <div>
            <Typography variant='button2'>WEX Fleet One</Typography>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Typography variant='overLine2'>
              Connect your existing WEX Fleet One account with Truckin Digital! Set purchase limits in real-time, down
              to the individual card level all from your Truckin Digital Card Management module. Consolidate
              transactions giving you one place to manage purchases, cash advances, and more. Important: You must only
              make changes in Truckin Digital Card Management as your go to source for changes, making changes on WEX
              such as card assignments etc. is not recommended and may cause sync issues.
            </Typography>
          </div>
          {!loading &&
            (isFleetOneConnected ? (
              <Typography variant='button1' style={{ color: use(palette.green500, palette.indigo50) }}>
                Connected
              </Typography>
            ) : (
              <SUnderlineText onClick={() => navigate('/connectedservicesediapiintegrations')}>
                <Typography variant='button1' style={{ color: use(palette.indigo500, palette.indigo50) }}>
                  Get Started
                </Typography>
                <ArrowIcon fill={palette.indigo500} />
              </SUnderlineText>
            ))}
        </div>
      </div>
    </SCustomModal>
  );
};

export default AddProvider;
