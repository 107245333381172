import React from 'react';
import LoadBoardIcon from 'assets/icons/loadBoard.png';
import { ReactComponent as DatIcon } from 'assets/icons/dat.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import Modal from 'common/Modal';
import Radio from 'common/Radio';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import Tooltip from 'common/Tooltip';
import { initialValues } from './PlanShipment.data';
import { SBodyWrapper } from './PlanShipment.styles';

const PlanShipment = ({ open, onClose, onNavigate }) => {
  const showToaster = useShowToaster();

  const onSubmit = (values) => {
    if (!values.type) {
      showToaster({ type: 'error', message: 'Please select one of the options to proceed' });
      return;
    }

    onNavigate(values.type);
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues,
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Plan Shipment'
      $bgColor={palette.gray0}
      $width='1200px'
      $height='700px'
      $bodyMaxHeight='calc(100vh - 170px)'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Next Step',
          onClick: handleSubmit,
        },
      ]}
    >
      <SBodyWrapper>
        <div className='section' onClick={() => handleChange('type', 'dispatch')}>
          <div className='radio-wrapper'>
            <Radio
              label='Dispatch'
              name='type'
              value='dispatch'
              checked={values.type === 'dispatch'}
              onChange={() => handleChange('type', 'dispatch')}
            />
          </div>
          <div className='info-items'>
            <div className='info-item'>
              <ArrowIcon />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Dispatch to Driver
              </Typography>
            </div>
            <div className='info-item'>
              <ArrowIcon />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Dispatch to In-Network Carrier
              </Typography>
            </div>
            <div className='info-item'>
              <ArrowIcon />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Dispatch to External Network Carrier
              </Typography>
            </div>
          </div>
        </div>
        <div className='section' onClick={() => handleChange('type', 'offer')}>
          <div className='radio-wrapper'>
            <Radio
              label='Offer'
              name='type'
              value='offer'
              checked={values.type === 'offer'}
              onChange={() => handleChange('type', 'offer')}
            />
          </div>
          <div className='info-items'>
            <div className='info-item'>
              <ArrowIcon />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Offer to Drivers
              </Typography>
            </div>
            <div className='info-item'>
              <ArrowIcon />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Offer to In-Network Carriers
              </Typography>
            </div>
            <div className='info-item'>
              <ArrowIcon />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Offer to External Network Carriers
              </Typography>
            </div>
          </div>
        </div>
        <div className='section'>
          <div className='radio-wrapper'>
            <Radio
              label='Post to Load Boards'
              name='type'
              value='loadBoard'
              checked={values.type === 'loadBoard'}
              onChange={() =>
                showToaster({ type: 'error', message: 'This feature is not available for your company at this time.' })
              }
            />
          </div>
          <div className='info-items'>
            <div className='info-item'>
              <ArrowIcon />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Requires Connected Service:
              </Typography>
            </div>
            <div className='services-wrapper'>
              <div className='service-item'>
                <div className='d-flex align-items-center'>
                  <div className='service-icon'>
                    <DatIcon height={32} />
                  </div>
                  <Typography variant='s2'>DAT Freight & Analytics</Typography>
                </div>
                <Tooltip title='This feature is not available for your company at this time.'>
                  <span>
                    <Typography variant='b2' style={{ color: palette.indigo500 }} className='not-allowed'>
                      Connect
                    </Typography>
                  </span>
                </Tooltip>
              </div>
              <Divider margin='0' />
              <div className='service-item'>
                <div className='d-flex align-items-center'>
                  <div className='service-icon'>
                    <img src={LoadBoardIcon} alt='load board' width={134} height={32} />
                  </div>
                  <Typography variant='s2'>123Loadboard</Typography>
                </div>
                <Tooltip title='This feature is not available for your company at this time. '>
                  <span>
                    <Typography variant='b2' style={{ color: palette.indigo500 }} className='not-allowed'>
                      Connect
                    </Typography>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </SBodyWrapper>
    </Modal>
  );
};

export default PlanShipment;
