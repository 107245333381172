import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Input, { Textarea } from 'common/Input';
import { DateTimePicker } from 'common/Pickers';
import useForm from 'hooks/useForm';
import { uploadDriverDocument } from 'Api/Driver';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { palette } from 'utils/constants';
import Tooltip from 'common/Tooltip';
import Divider from 'common/Divider';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { getIncidentTypes, getRulesAndConduct } from 'Api/CompanySettings';
import { getCities, getStates } from 'Api/JobPositions';
import {
  createIncident,
  getActionTaken,
  getDriverShipments,
  getIncidentRecommendedAction,
  updateIncident,
} from 'Api/Incidents';
import { COUNTRIES } from 'pages/PublicPositions/ApplyJob/ApplyJob.data';
import { getDrivers } from 'Api/EquipmentProfile';
import { getReportNumberList } from 'Api/DotMonitoring';
import { validationSchema } from './validationSchema';
import AddActionTaken from './components/AddActionTaken';
import IncidentPhotos from './components/IncidentPhotos';
import AddIncidentType from './components/AddIncidentType';
import AccidentQuestions from './components/AccidentQuestions';
import ReportNumberAutocomplete from './components/ReportNumberAutocomplete';
import RoadsideInspectionQuestions from './components/RoadsideInspectionQuestions';
import { generateId, getInitialValues } from './AddIncidentModal.data';
import { SAddressWrapper } from '../TransationTab/components/AddTransaction/AddTransaction.styles';

const AddIncidentModal = ({ open, onClose, driverId, firstName, lastName, incident, onSuccess, dotMonitoring }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const { convertToCustomerTime, formatDateTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [actionTaken, setActionTaken] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [addTypeOpen, setAddTypeOpen] = useState(false);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [recommendedAction, setRecommendedAction] = useState(null);
  const [reportNumbers, setReportNumbers] = useState([]);
  const [rules, setRules] = useState([]);
  const [driverShipments, setDriverShipments] = useState([]);
  const [loadingShipments, setLoadingShipments] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      let updatedData;

      if (incident && !dotMonitoring) {
        const body = {
          driver_id: values.driver?.id || driverId,
          incident_id: values.incident_id || '',
          report_number: values.report_number,
          incident_date: values.incident_date ? moment(values.incident_date).format('YYYY-MM-DD') : null,
          incident_time: values.incident_date ? moment(values.incident_date).format('HH:mm:ss') : null,
          address: `${values.country.name}, ${values.state.name}, ${values.city.name}`,
          country_id: values.country.id,
          city_id: values.city.id,
          state_id: values.state.id,
          incident_type: values.incident_type.id,
          incident_record_documents: values.document_path || [],
          statement_document: values.statement_document || null,
          incident_record_images: values.incident_record_images || [],
          incident_record_video: values.incident_record_video || [],
          action_taken: values.action_taken?.id
            ? values.action_taken?.id
            : !values.canReview
            ? actionTaken?.[0].id
            : undefined,
          report_details: values.report_details || '',
          reason: values.reason || '',
          notes: values.notes || null,
          added_by_id: values.added_by.id,

          vehicles_involved: values.vehicles_involved,
          are_you_injured: values.are_you_injured,
          anyone_injured: values.anyone_injured,
          how_many_individuals_injured: values.how_many_individuals_injured,
          is_there_fuel_split: values.is_there_fuel_split,
          direction_of_travel: values.direction_of_travel,
          doing_at_accident_time: values.doing_at_accident_time,
          jack_knifed_turn_over: values.jack_knifed_turn_over,
          is_vehicle_drivable: values.is_vehicle_drivable,
          need_to_be_towed: values.need_to_be_towed,
          is_freight_damage_trailer: values.is_freight_damage_trailer,
          police_arrived_at_the_scene: values.police_arrived_at_the_scene,
          police_department: values.police_department,
          are_you_on_the_shipment: values.are_you_on_the_shipment,
          shipment_number: values.shipment_number,

          inspection_level: values.inspection_level,
          inspection_status: values.inspection_status,
          inspection_out_of_service: values.inspection_out_of_service,
        };

        const { data } = await updateIncident(incident.id, body);
        updatedData = data;
      } else {
        const formData = new FormData();
        formData.append('driver_id', values.driver?.id || driverId);
        if (values.incident_id) {
          formData.append('incident_id', values.incident_id);
        }
        if (values.report_number) {
          formData.append('report_number', values.report_number);
        }
        if (values.incident_date) {
          formData.append('incident_date', moment(values.incident_date).format('YYYY-MM-DD'));
          formData.append('incident_time', moment(values.incident_date).format('HH:mm:ss'));
        }
        formData.append('address', `${values.country.name}, ${values.state.name}, ${values.city.name}`);
        formData.append('country_id', values.country.id);
        formData.append('city_id', values.city.id);
        formData.append('state_id', values.state.id);
        formData.append('incident_type', values.incident_type.id);
        formData.append(
          'action_taken',
          values.action_taken?.id ? values.action_taken?.id : !values.canReview ? actionTaken[0] : null
        );
        if (values.report_details) {
          formData.append('report_details', values.report_details);
        }
        if (values.notes) {
          formData.append('notes', values.notes);
        }
        if (values.reason) {
          formData.append('reason', values.reason);
        }
        formData.append('added_by_id', values.added_by.id);
        if (values.statement_document) {
          formData.append('statement_document', values.statement_document);
        }
        values.incident_record_images.forEach((image, i) => {
          formData.append(`incident_record_images[${i}]`, image);
        });
        values.incident_record_video.forEach((video, i) => {
          formData.append(`incident_record_video[${i}]`, video);
        });
        values.document_path.forEach((doc, i) => {
          formData.append(`document_path[${i}][name]`, doc.name);
          formData.append(`document_path[${i}][path]`, doc.path);
        });

        formData.append('vehicles_involved', values.vehicles_involved);
        formData.append('are_you_injured', values.are_you_injured);
        formData.append('anyone_injured', values.anyone_injured);
        formData.append('how_many_individuals_injured', values.how_many_individuals_injured);
        formData.append('is_there_fuel_split', values.is_there_fuel_split);
        formData.append('direction_of_travel', values.direction_of_travel);
        formData.append('doing_at_accident_time', values.doing_at_accident_time);
        formData.append('jack_knifed_turn_over', values.jack_knifed_turn_over);
        formData.append('is_vehicle_drivable', values.is_vehicle_drivable);
        formData.append('need_to_be_towed', values.need_to_be_towed);
        formData.append('is_freight_damage_trailer', values.is_freight_damage_trailer);
        formData.append('police_arrived_at_the_scene', values.police_arrived_at_the_scene);
        formData.append('police_department', values.police_department);
        formData.append('are_you_on_the_shipment', values.are_you_on_the_shipment);
        formData.append('shipment_number', values.shipment_number);

        formData.append('inspection_level', values.inspection_level);
        formData.append('inspection_status', values.inspection_status);
        formData.append('inspection_out_of_service', values.inspection_status);

        const createData = await createIncident(formData);
        updatedData = createData.data;
      }
      showToaster({
        type: 'success',
        message: `Incident has been successfully ${
          incident ? 'updated' : values.canReview ? 'created' : 'submitted for review'
        }!`,
      });
      onSuccess(updatedData);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, handleBlur } = useForm({
    initialValues: getInitialValues(
      incident,
      convertToCustomerTime(),
      firstName,
      lastName,
      convertToCustomerTime(new Date(), 'MM/DD/YYYY HH:mm')
    ),
    validationSchema,
    onSubmit,
  });

  const uploadDocument = (file, field) => {
    uploadDriverDocument(file[0], driverId).then((res) => {
      handleChange(field, res.data.document_path);
    });
  };

  const getIncidentTypeOptions = async () => {
    try {
      const { data } = await getIncidentTypes();
      setIncidentTypes([...data, { id: 'add-custom', type: '+ Add Custom' }]);
    } catch (e) {
      // Do nothing
    }
  };

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDriverShipmentsList = async () => {
    try {
      setLoadingShipments(true);
      const { data } = await getDriverShipments(values.driver?.id || driverId);
      setDriverShipments(data.map((shipment) => shipment.shipment_id));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingShipments(false);
    }
  };

  const getRules = async () => {
    try {
      const { data } = await getRulesAndConduct();
      setRules(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getActionTakenOptions = async () => {
    try {
      const { data } = await getActionTaken();
      setActionTaken([...data, { id: 'add-custom', action: '+ Add Custom' }]);
    } catch (e) {
      // Do nothing
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRecommendedAction = async (incident_type, user_id) => {
    try {
      const params = {
        user_type: 'driver',
        user_id,
        incident_type,
      };
      const data = await getIncidentRecommendedAction(params);
      setRecommendedAction(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getReportNumbers = async () => {
    try {
      const { data } = await getReportNumberList();
      setReportNumbers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (values.driver?.id || driverId) {
      getDriverShipmentsList();
    }
  }, [driverId, values.driver?.id]);

  useEffect(() => {
    if (values.canReview && recommendedAction?.recommended_action?.length) {
      handleChange('action_taken', recommendedAction.recommended_action[0]);
    }
  }, [recommendedAction]);

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    handleChange('added_by', user);
    getIncidentTypeOptions();
    getActionTakenOptions();
    getRules();
    if (!driverId) {
      getDriversList();
    }
    getReportNumbers();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={incident && !dotMonitoring ? 'Update Incident' : 'Add Incident'}
      $bgColor={palette.gray0}
      $maxWidth='520px'
      $minWidth='520px'
      backdrop='static'
      className='modified-scrollbar'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: incident ? 'Update' : values.canReview ? 'Add Incident' : 'Submit for Review',
          disabled: loading || (!values.driver?.id && !driverId),
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-4'>
        {!driverId && (
          <div>
            <Autocomplete
              required
              label='Driver'
              name='driver'
              placeholder='Select Driver'
              options={drivers}
              value={values.driver}
              onChange={(e, value) => {
                handleChange('driver', value);
                handleChange(
                  'incident_id',
                  generateId(value.fname, value?.lname, convertToCustomerTime(new Date(), 'MM/DD/YYYY HH:mm'))
                );

                if (values.canReview && values.incident_type) {
                  getRecommendedAction(values.incident_type.id, value.id);
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => {
                return option ? `${option.fname} ${option.lname}` : '';
              }}
              error={touchedErrors.driver}
            />
          </div>
        )}
        <div>
          <Tooltip
            open={!!recommendedAction}
            title={
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center gap-2'>
                  <InfoIcon width={16} height={16} />
                  <Typography variant='s1'>Recommended Action</Typography>
                </div>
                <Divider margin='2px 0 16px 0' />
                {rules?.some((rule) => rule?.incident_types?.some((type) => type?.id === values.incident_type?.id)) && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      maxWidth: '500px',
                      marginBottom: '16px',
                    }}
                  >
                    <Typography variant='s2'>Related to:</Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '4px',
                        maxWidth: '425px',
                      }}
                    >
                      {rules
                        ?.filter((rule) => rule?.incident_types?.some((type) => type?.id === values.incident_type?.id))
                        .map((rule) => {
                          return (
                            <div
                              key={rule.id}
                              onClick={() => {
                                onClose();
                                navigate('/rules-conduct');
                              }}
                            >
                              <Chip
                                label={rule.title}
                                bgColor={palette.indigo0}
                                labelColor={palette.indigo500}
                                sx={{
                                  '& .MuiChip-label': {
                                    cursor: 'pointer',
                                  },
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                <Typography variant='s2' style={{ maxWidth: '500px', whiteSpace: 'normal', marginBottom: '16px' }}>
                  Based on this {values.incident_type?.type} incident and {firstName || values.driver?.fname}{' '}
                  {lastName || values.driver?.lname}'s record, our recommendation is to{' '}
                  {recommendedAction?.recommended_action?.length ? 'issue' : ''}{' '}
                  {recommendedAction?.recommended_action?.length ? (
                    <Typography variant='button2'>{recommendedAction?.recommended_action?.[0]?.action}</Typography>
                  ) : (
                    'review record (high risk - too many same incidents)'
                  )}
                  .
                </Typography>
                <Typography variant='s2' style={{ maxWidth: '500px', whiteSpace: 'normal', marginBottom: '8px' }}>
                  Reason for our recommendation is that there{' '}
                  {recommendedAction?.old_incidents?.length === 1 ? 'is' : 'are'}
                  {recommendedAction?.old_incidents?.length ? ` ${recommendedAction.old_incidents.length}` : ' no'}{' '}
                  record{recommendedAction?.old_incidents?.length !== 1 ? 's' : ''} for this type of incident on file
                  {recommendedAction?.old_incidents?.length ? ':' : '.'}
                </Typography>
                {!!recommendedAction?.old_incidents?.length && (
                  <div style={{ maxWidth: '500px', whiteSpace: 'normal' }}>
                    <ul>
                      {recommendedAction?.old_incidents.map((incident) => {
                        return (
                          <li style={{ marginBottom: '4px' }}>
                            {incident?.incident_type?.type} occurred on{' '}
                            {formatDateTime(`${incident?.incident_date} ${incident?.incident_time}`)}
                            <ul>
                              <li>Action Taken: {incident?.incident_action_taken?.action}</li>
                              <li>
                                Action By: {incident?.added_by?.first_name} {incident?.added_by?.last_name}
                              </li>
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            }
            placement='right-end'
            arrow={false}
            componentsProps={{
              tooltip: {
                sx: {
                  color: palette.gray700,
                  bgcolor: palette.white,
                  fontSize: 14,
                  fontFamily: 'Inter',
                  whiteSpace: 'nowrap',
                  maxWidth: 'unset',
                  border: '1px solid #dadde9',
                  marginLeft: '40px !important',
                  marginTop: '100px !important',
                },
              },
            }}
          >
            <Autocomplete
              required
              label='Type of Incident'
              name='incident_type'
              labelKey='type'
              value={values.incident_type}
              onChange={(e, value) => {
                if (value?.id === 'add-custom') {
                  setAddTypeOpen(true);
                  return;
                }
                handleChange('incident_type', value);

                if (values.canReview && (values.driver || driverId)) {
                  getRecommendedAction(value.id, values.driver?.id || driverId);
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={incidentTypes}
              error={touchedErrors.incident_type}
            />
          </Tooltip>
        </div>
        {values.incident_type?.type === 'Accident / Crash' && (
          <AccidentQuestions
            values={values}
            handleChange={handleChange}
            driverShipments={driverShipments}
            loadingShipments={loadingShipments}
          />
        )}
        <Input
          required
          label='Incident ID'
          name='incident_id'
          value={values.incident_id}
          onChange={handleChange}
          disabled
        />
        <div>
          <ReportNumberAutocomplete
            value={values.report_number}
            options={reportNumbers}
            setOptions={setReportNumbers}
            onChange={(val) => handleChange('report_number', val)}
            error={touchedErrors.report_number}
            disableClearable={false}
          />
        </div>
        <div>
          <DateTimePicker
            required
            label='Date + Time'
            name='incident_date'
            value={values.incident_date}
            onChange={(val) => handleChange('incident_date', val)}
            disableFuture
            onBlur={handleBlur}
            error={touchedErrors.incident_date}
          />
        </div>
        <div>
          <InputLabel required>Location of Incident</InputLabel>
          <SAddressWrapper>
            <div className='flex-grow-1'>
              <Autocomplete
                name='country'
                placeholder='Country..'
                value={values.country}
                onChange={(e, value) => {
                  handleChange('country', value);
                  handleChange('state', null);
                  handleChange('city', null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={COUNTRIES}
                error={touchedErrors.country}
              />
            </div>
            <div className='flex-grow-1'>
              <Autocomplete
                name='state'
                placeholder='Select State..'
                value={values.state}
                onChange={(e, value) => {
                  handleChange('state', value);
                  handleChange('city', null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={states}
                error={touchedErrors.state}
              />
            </div>
            <div className='flex-grow-1'>
              <Autocomplete
                name='city'
                placeholder='Select City..'
                value={values.city}
                onChange={(e, value) => handleChange('city', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={cities}
                error={touchedErrors.city}
              />
            </div>
          </SAddressWrapper>
        </div>
        {values.incident_type?.type === 'Road Side Inspection' && (
          <RoadsideInspectionQuestions values={values} handleChange={handleChange} />
        )}
        <Textarea
          required={values.incident_type?.type !== 'Accident / Crash'}
          rows={3}
          label='Report Details'
          name='report_details'
          value={values.report_details}
          onChange={handleChange}
          error={touchedErrors.report_details}
        />
        <Textarea
          rows={3}
          label='Reason(s) given by Person Implicated'
          name='reason'
          value={values.reason}
          onChange={handleChange}
        />
        <IncidentPhotos
          values={values}
          handleChange={handleChange}
          userId={driverId}
          userType='driver'
          name='document_path'
        />
        <div>
          <InputLabel>Upload Driver Statement</InputLabel>
          <div>
            {values?.statement_document ? (
              <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: '#38A06C' }}>
                  File Uploaded
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={() => handleChange('statement_document', null)}
                />
              </p>
            ) : (
              <div
                className='upload-document-deposit'
                style={{
                  background: use(palette.white, palette.dark800),
                  color: use(palette.gray900, palette.gray50),
                  borderColor: use(palette.gray200, palette.gray700),
                  position: 'relative',
                  width: '100%',
                }}
              >
                <label htmlFor='myFile' className='text-style upload-label'>
                  <p className='upload-document-icon'>
                    <img src={uploadLicense} alt='' />
                  </p>
                  <p className='upload-document-text'>
                    <span style={{ color: '#4F5AED' }}> Upload Document </span>
                    <span style={{ color: use(palette.gray700, palette.gray200) }}> or drag and drop</span>
                  </p>
                </label>
                <input
                  type='file'
                  id='myFile'
                  name='filename'
                  onChange={(file) => {
                    uploadDocument(file, 'statement_document');
                  }}
                  style={{ display: 'none' }}
                />
                <ImageDropZone
                  onDrop={(file) => uploadDocument(file, 'statement_document')}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  maxSize={1000000}
                  customStyle={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                  accept={['application/pdf']}
                />
              </div>
            )}
          </div>
        </div>
        <IncidentPhotos
          values={values}
          handleChange={handleChange}
          userId={driverId}
          userType='driver'
          name='incident_record_images'
        />
        <IncidentPhotos
          values={values}
          handleChange={handleChange}
          userId={driverId}
          userType='driver'
          name='incident_record_video'
        />
        <div className='position-relative'>
          <Autocomplete
            required={values.canReview}
            label='Action Taken'
            name='action_taken'
            labelKey='action'
            value={!values.canReview ? actionTaken[0] : values.action_taken}
            onChange={(e, value) => {
              if (value?.id === 'add-custom') {
                setAddActionOpen(true);
                return;
              }
              handleChange('action_taken', value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  <div className='d-flex align-items-center gap-2'>
                    <Typography variant='s2'>{option.action}</Typography>
                    {recommendedAction?.recommended_action?.[0]?.id === option.id && (
                      <Chip label='RECOMMENDED' bgColor={palette.orange0} labelColor={palette.orange500} size='small' />
                    )}
                  </div>
                </li>
              );
            }}
            options={actionTaken}
            disabled={!values.canReview}
            error={touchedErrors.action_taken}
          />
          {!!recommendedAction?.recommended_action?.length &&
            recommendedAction?.recommended_action?.[0]?.id === values.action_taken?.id && (
              <div className='position-absolute' style={{ top: '29px', left: '110px' }}>
                <Chip label='RECOMMENDED' bgColor={palette.orange0} labelColor={palette.orange500} size='small' />
              </div>
            )}
        </div>
        <Textarea
          rows={3}
          label='Notes'
          name='notes'
          value={values.notes}
          disabled={!values.canReview}
          onChange={handleChange}
          error={touchedErrors.notes}
        />
        <Input label='Action By' value={`${values.added_by?.first_name} ${values.added_by?.last_name}`} disabled />
      </div>
      {addTypeOpen && (
        <AddIncidentType
          open={addTypeOpen}
          onClose={() => setAddTypeOpen(false)}
          onSuccess={(newType) => {
            getIncidentTypeOptions();
            if (newType) {
              handleChange('incident_type', newType);
            }
          }}
        />
      )}
      {addActionOpen && (
        <AddActionTaken
          open={addActionOpen}
          onClose={() => setAddActionOpen(false)}
          onSuccess={(newAction) => {
            getActionTakenOptions();
            if (newAction) {
              handleChange('action_taken', newAction);
            }
          }}
        />
      )}
    </Modal>
  );
};

export default AddIncidentModal;
