import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Loader from 'common/Loader';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomButton from 'components/CustomButton/CustomButton';
import useShowToaster from 'hooks/useShowToaster';
import { deleteLocationPin, getLocationPins } from 'Api/CompanySettings';
import { palette } from 'utils/constants';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import { getErrorMessage } from 'utils/error';
import TableRow from './TableRow';
import CreatePin from './components/CreatePin';
import { SWrapper, SRow } from './TimeApp.styles';

const TimeApp = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [createPinOpen, setCreatePinOpen] = useState(false);
  const [deletePinOpen, setDeletePinOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationToUpdate, setLocationToUpdate] = useState(null);

  const getLocationsData = async () => {
    setLoading(true);
    try {
      const { data } = await getLocationPins();
      setLocations(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const deleteLocationData = async () => {
    setLoadingDelete(true);
    try {
      await deleteLocationPin(locationToUpdate.id);
      showToaster({ type: 'success', message: `PIN has been successfully deleted!` });
      getLocationsData();
      onModalClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onEdit = (location) => {
    setLocationToUpdate(location);
    setCreatePinOpen(true);
  };

  const onDelete = (location) => {
    setLocationToUpdate(location);
    setDeletePinOpen(true);
  };

  const onModalClose = () => {
    setLocationToUpdate(null);
    setCreatePinOpen(false);
    setDeletePinOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getLocationsData();
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      <PageHeader
        hideSave
        title='Truckin Digital Time App'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={() => onSaveAndNext('saveAndNext')}
      />
      <div className='mt-3'>
        <div className='d-flex justify-content-end'>
          <CustomButton
            type='secondary'
            title='Add Location'
            leftIcon={<PlusIcon style={{ marginRight: 10, fill: '#464F60' }} />}
            styleButton={{ margin: 0, padding: '4px 12px' }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => setCreatePinOpen(true)}
          />
        </div>
        <SRow>
          <div className='settings-header-item'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              LOCATION NAME
            </Typography>
          </div>
          <div className='settings-header-item'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              PIN
            </Typography>
          </div>
          <div className='settings-header-item'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              TIMEZONE
            </Typography>
          </div>
          <div className='settings-header-item' />
        </SRow>
        {locations?.map((item) => (
          <TableRow data={item} key={item.id} onEdit={() => onEdit(item)} onDelete={() => onDelete(item)} />
        ))}
        {!locations.length && <NoRecords />}
      </div>
      {createPinOpen && (
        <CreatePin
          open={createPinOpen}
          onClose={onModalClose}
          onSuccess={getLocationsData}
          location={locationToUpdate}
        />
      )}
      {deletePinOpen && (
        <ConfirmationModal
          open={deletePinOpen}
          onClose={onModalClose}
          onConfirm={deleteLocationData}
          disabled={loadingDelete}
          title='PIN'
          text='Are you sure you want to delete this PIN?'
        />
      )}
    </SWrapper>
  );
};

export default TimeApp;
