import React, { useState, useEffect } from 'react';
import './addNewChannel.css';
import { useChatContext } from 'stream-chat-react';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import profile from 'assets/icons/drivers/user-profile.svg';
import { getStaffUsers, sendSmSChatMessage } from 'Api/chat';
import ChatAppServices from 'services/chatApp';

const sendTo = ['User', 'Group', 'Driver', 'SMS'];
const whitelist = ['US', 'CA', 'MX'];

const AddNewChannel = ({ chatUser, setAddNewModal, selectedStaff, dot_num, staff, setstaff, setactiveTab }) => {
  const { client } = useChatContext();
  const navigate = useNavigate();
  const [searchBy, setsearchBy] = useState(sendTo[0]);
  const [showList, setshowList] = useState(false);
  const [channelList, setchannelList] = useState(null);
  const [userList, setUserList] = useState(null);
  const [searchInput, setsearchInput] = useState('');
  const [searchResult, setsearchResult] = useState([]);
  const [selctedChannel, setselctedChannel] = useState(selectedStaff !== null ? selectedStaff : null);
  const [showResult, setshowResult] = useState(false);
  const [message, setmessage] = useState('');
  const [isLicence, setIsLicence] = useState(null);

  const filtersGroups = {
    type: 'truckindigital',
    members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
  };

  const filtersDrivers = {
    type: 'driver_channel',
    members: { $in: [`${chatUser.id}`] },
    organization_id: dot_num,
  };
  const sort = { last_message_at: -1 };

  const HandleSearchBy = (searchBy) => {
    setsearchInput('');
    setsearchResult([]);
    setselctedChannel(null);
    setchannelList([]);
    setsearchBy(searchBy);
  };

  const handleSelectChannel = (channel) => {
    setselctedChannel(channel);
    setshowResult(false);
  };

  const ctreateChannel = async () => {
    if (searchBy === 'SMS') {
      ChatAppServices.checkSMSLicence().then((res) => {
        setIsLicence(!res.data.is_two_way_sms_active);
        if (res.data.is_two_way_sms_active) {
          const data = {
            to: searchInput,
            body: message,
          };
          sendSmSChatMessage(data).then(() => {
            setAddNewModal(false);
            if (setactiveTab) {
              setactiveTab(0);
            }
          });
        }
      });
    } else {
      if (selctedChannel.data) {
        await selctedChannel.sendMessage({
          text: message,
        });
        await selctedChannel.updatePartial({ set: { open_chat: true } });
        setAddNewModal(false);
        setactiveTab(0);
      } else {
        const { customer, id } = JSON.parse(localStorage.getItem('user')) || {};
        const { dot } = customer || {};
        const chatChannel = await client.channel(
          'messaging',
          `${dot}_${selctedChannel.fname ? 'driver' : 'staff'}_group_${
            selctedChannel.fname ? `${selctedChannel?.id}` : `${selctedChannel?.staff.id}`
          }`,
          {
            name: !selctedChannel.fname
              ? `${selctedChannel.staff?.first_name} ${selctedChannel.staff?.last_name}`
              : `${selctedChannel.fname} ${selctedChannel.lname}`,
            image: selctedChannel.profile_logo || selctedChannel.image,
            members: [
              `${dot}_${selctedChannel.fname ? 'driver' : 'staff'}_${
                selctedChannel.fname ? `${selctedChannel?.id}` : `${selctedChannel?.staff.id}`
              }`,
              `${dot}_staff_${id}`,
            ],
            email: selctedChannel.email || selctedChannel?.staff?.email,
            phone_number: selctedChannel.phone_number || selctedChannel?.staff?.phone_number,
            organization_id: dot,
            open_chat: true,
            chat_closed_by: null,
            chat_closed_date_time: null,
            is_group: 0,
          }
        );
        chatChannel.watch();
        setTimeout(async () => {
          await chatChannel.sendMessage({
            text: message,
          });
        }, 1000);
        setAddNewModal(false);
        setactiveTab(0);
      }
    }
  };

  const handleSearchInput = (e) => {
    setsearchInput(e.target.value);
    setstaff(false);
  };

  const handlePhoneInput = (e) => {
    setsearchInput(e);
    setstaff(false);
  };

  useEffect(() => {
    const getChannelGroups = async () => {
      const channels = await client.queryChannels(filtersGroups, sort, {
        watch: true,
        state: true,
      });
      setchannelList(channels);
    };
    const getChannelDriver = async () => {
      const channels = await client.queryChannels(filtersDrivers, sort, {
        watch: true,
        state: true,
      });
      setchannelList(channels);
    };

    const getUsersList = async () => {
      const users = await getStaffUsers();
      setUserList(users.data);
    };

    if (searchBy === sendTo[1]) {
      getChannelGroups();
    } else if (searchBy === sendTo[0]) {
      getUsersList();
    } else if (searchBy === sendTo[2]) {
      getChannelDriver();
    }
  }, [searchBy]);

  useEffect(() => {
    if (selctedChannel !== null && searchBy === sendTo[0]) {
      setsearchInput(`${selctedChannel?.staff?.first_name} ${selctedChannel?.staff?.last_name}`);
    } else if (selctedChannel !== null && searchBy === sendTo[2]) {
      if (selctedChannel?.data?.name) {
        setsearchInput(selctedChannel?.data?.name);
      }
    } else if (selctedChannel !== null && searchBy === sendTo[1]) {
      setsearchInput(selctedChannel?.data?.name);
    }
  }, [selctedChannel, searchBy]);

  useEffect(() => {
    if (searchBy === sendTo[0]) {
      const searchResult = [];
      userList?.forEach((u) => {
        if (u.staff) {
          const name = u.staff?.first_name?.toLowerCase();
          if (name?.includes(searchInput?.toLowerCase())) {
            searchResult.push(u);
            return u;
          }
        }
      });
      setsearchResult(searchResult);
    } else if (searchBy === sendTo[1]) {
      const searchResult = [];
      channelList?.forEach((c) => {
        if (c.data.name) {
          const name = c.data.name.toLowerCase();
          if (name?.includes(searchInput?.toLowerCase())) {
            searchResult.push(c);
            return c;
          }
        }
      });
      setsearchResult(searchResult);
    } else if (searchBy === sendTo[2]) {
      const searchResult = [];
      channelList?.forEach((c) => {
        if (c?.data?.name) {
          const name = c?.data?.name.toLowerCase();
          if (name?.includes(searchInput?.toLowerCase())) {
            searchResult.push(c);
            return c;
          }
        }
      });
      setsearchResult(searchResult);
    }
  }, [searchInput, searchBy]);

  useEffect(() => {
    if (searchResult.length === 0 || staff) {
      setshowResult(false);
    } else {
      setshowResult(true);
    }
  }, [searchInput, searchResult]);

  useEffect(() => {
    if (selectedStaff && staff) {
      if (selectedStaff.role === 'staff') {
        setsearchBy(sendTo[0]);
      } else if (selectedStaff?.data?.type === 'driver_channel') {
        setsearchBy(sendTo[2]);
      } else if (selectedStaff?.data?.type === 'truckindigital') {
        setsearchBy(sendTo[1]);
      }
    }
  }, [selectedStaff]);

  const onEnterPress = async (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      await ctreateChannel();
    }
  };

  const selectPhoneNumber = () => {
    navigate('/billing');
  };

  return (
    <div className='modalContainer add-new-channel'>
      <div className='addon-modal-area' style={{ width: '500px' }}>
        <div
          className='modal-footer-custom'
          style={{
            justifyContent: 'space-between',
            borderRadius: '8px 8px 0px 0px ',
          }}
        >
          <p>New Message</p>
        </div>
        <div className='modal-body-custom add-new-channel-body '>
          {isLicence && (
            <div className='check-licence-message-new-chat' onClick={() => setIsLicence(false)}>
              {isLicence === 0 ? (
                <>
                  You do not have a number assigned to this group. &nbsp; You do not have the "Two-Way SMS" add-on
                  license. Please purchase the license first.
                </>
              ) : (
                <>
                  You do not have the "Two-Way SMS" add-on license. Please purchase the license first.
                  <span onClick={selectPhoneNumber}> Enable </span>
                </>
              )}
            </div>
          )}
          <p>To</p>
          <div className='sendto-input'>
            <div className='select-box' onClick={() => setshowList(!showList)}>
              <p>{searchBy}</p>
              {showList && (
                <ul>
                  {sendTo.map((l, idx) => (
                    <li key={l?.id || idx} onClick={() => HandleSearchBy(l)}>
                      {l}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {searchBy === 'SMS' ? (
              <PhoneInput
                style={{ border: 'none!important', marginLeft: 20, width: '100%' }}
                value={searchInput}
                countries={whitelist}
                className='input-field-phone'
                addInternationalOption={false}
                onChange={handlePhoneInput}
                defaultCountry='US'
                placeholder='(555) 555-1234'
              />
            ) : (
              <input
                type='text'
                placeholder={`Search ${searchBy}`}
                name='search'
                id='search'
                value={searchInput}
                onChange={handleSearchInput}
              />
            )}
            {showResult && (
              <div className='serach-result'>
                {searchResult?.map((c, idx) => (
                  <div key={c?.id || idx}>
                    <ResultList c={c} handleSelectChannel={handleSelectChannel} />
                  </div>
                ))}
              </div>
            )}
          </div>
          <p>Write Message</p>
          <textarea
            name=''
            id=''
            placeholder='Type Message'
            cols='30'
            rows='5'
            className='type-message'
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            onKeyDown={onEnterPress}
          />
        </div>
        <div className='modal-footer-custom' style={{ justifyContent: 'space-between' }}>
          <div className='addon-footer-btn'>
            <button className='addon-btn ' style={{ marginRight: '30px' }} onClick={() => setAddNewModal(false)}>
              Cancel
            </button>
            <button
              className='addon-btn '
              style={{
                background: '#4F5AED',
                color: '#ffff',
                border: 'none',
              }}
              onClick={ctreateChannel}
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewChannel;

const ResultList = ({ c, handleSelectChannel }) => {
  let image;
  if (c.data !== undefined) {
    image = c.data.image === undefined || c.data.image === null ? profile : c.data.image;
  } else {
    image = c.image === null ? profile : c.image;
  }
  return (
    <p onClick={() => handleSelectChannel(c)}>
      <img src={image || c?.staff?.profile_logo || profile} alt='' className='result-profile' />
      {c.fname || c?.staff?.first_name || c?.data?.name} {c.lname || c?.staff?.last_name}
    </p>
  );
};
