import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function ThemeSettingsChange({ theme, menuStyle }) {
  const { data } = await axios.post(
    `${API_URL}/customer/user/theme-settings`,
    {
      theme: theme && theme,
      menu_style: menuStyle && menuStyle,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export function ThemeSettingsChangeFiles(data) {
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url: `${API_URL}/customer/theme-settings`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export async function ThemeSettingsChangeFilesDelete(name) {
  const { data } = await axios.post(
    `${API_URL}/customer/theme-settings`,
    {
      [name]: null,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}
