import moment from 'moment/moment';
import { endOfYear, startOfYear } from 'date-fns';
import { transformArray } from 'pages/Accounting/Receivables/StatsSection/StatsSection.data';

export const yearFilters = {
  miles_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  revenue_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  closed_range: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  closed_month: moment().month() + 1,
  graphReadyAndMissingYear: moment().year(),
  graphOpenReceivablesYear: moment().year(),
  graphOpenReceivablesMonth: moment().month() + 1,
};

export const initialCountsData = { readyAndMissing: {} };

export const countsDataConverter = (data) => {
  const { readyAndMissing } = data || {};

  const ready_and_missing = transformArray(readyAndMissing?.graph);
  ready_and_missing.shift();

  return {
    readyAndMissing: { ...readyAndMissing, monthsData: ready_and_missing },
  };
};
