import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .detentions-table-wrapper {
    display: flex;
    flex-direction: column;
    background-color: ${palette.white};
    border: 1px solid ${palette.gray100};
    border-radius: 6px;
    gap: 16px;
    padding: 0 8px 8px;

    .total-due-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 72px;
      padding-right: 70px;
    }

    .MuiPaper-root > div {
      position: static !important;
    }
  }

  .delivery-option {
    display: flex;
    gap: 30px;
    padding: 8px 0;
    border-top: 1px solid ${palette.gray50};
    min-height: 70px;
  }

  .MuiTableCell-root {
    padding: 8px 4px;
    border-bottom: none;
  }

  .MuiTableRow-root.MuiTableRow-head th {
    height: 32px !important;
  }

  .MuiTableRow-root.MuiTableRow-head {
    border-top: none;
    border-bottom: 1px solid rgb(224, 224, 224);
  }
`;
