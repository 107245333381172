import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { DIMENSION_UNIT_TO_FEET, formatNumber, getAlphabet, numberWithCommas, palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { getCargoTotals } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { MAPPER_STOPS, QUANTITY_TYPE, WEIGHT_TYPE } from '../ShipmentStops/helpers/constants';
import { ShipmentContext } from '../Layout';
import styles from './validationpage.module.css';

const ValidationPage = () => {
  const {
    setStep,
    onCloseModal,
    equipmentLengthData,
    equipmentRequired,
    optimizeLTLOrder,
    createShipmentData,
    stopPoints,
  } = useContext(ShipmentContext);
  const stops =
    Number(createShipmentData?.type) === 1
      ? createShipmentData?.legs.reduce((acc, cur) => [...acc, ...(cur.stops || [])], [])
      : optimizeLTLOrder;

  const columns = useMemo(() => {
    return [
      {
        field: 'stop_name',
        title: 'STOP NAME',
        cellStyle: (e, row) => ({ padding: row.lastChild ? '10px 16px' : '' }),
        render: (rowData) => {
          const data = stopPoints.find((el) => +el.key === +rowData.stop_point_id);
          const { location_name, address1, city, state, zipcode } = data || {};

          if (rowData.lastChild) {
            return <Typography variant='button2'>{rowData?.title}</Typography>;
          }
          return (
            <div className={styles.customer}>
              <Typography variant='s2'>{location_name}</Typography>
              <div>
                <Typography variant='overLine' style={{ color: palette.gray700 }}>
                  {address1},
                </Typography>
                <Typography variant='overLine' style={{ color: palette.gray700 }}>
                  {city}&nbsp;
                </Typography>
                <Typography variant='overLine' style={{ color: palette.gray700 }}>
                  {state && `${state},`}
                  {zipcode}
                </Typography>
              </div>
            </div>
          );
        },
      },
      {
        field: 'stop_point_type',
        title: 'TYPE',
        cellStyle: (e, row) => ({ padding: row.lastChild ? '10px 16px' : '' }),
        render: (rowData) => {
          const type = +rowData.stop_point_type;
          const { name } = MAPPER_STOPS[Number(rowData?.stop_point_type)] || {};
          const alpha = rowData?.index;
          if (rowData.lastChild) {
            return null;
          }

          return (
            <div
              style={{ backgroundColor: type === 1 ? palette.green500 : type === 2 ? palette.red500 : palette.blue500 }}
              className={styles.map_style_stop_type}
            >
              <Typography variant='c1'>
                STOP {getAlphabet(alpha)} : {name}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'equipment_type',
        title: 'EQUIPMENT TYPE',
        cellStyle: (e, row) => ({ padding: row.lastChild ? '10px 16px' : '' }),
        render: (rowData) => {
          const { equipment_type_length, equipment_type } = rowData;
          const lengthLabel = equipmentLengthData.find((el) => Number(el?.key) === +equipment_type_length)?.label;
          const typeLabel = equipmentRequired.find((el) => Number(el?.key) === +equipment_type)?.label;
          if (rowData.lastChild) {
            return null;
          }
          return typeLabel !== undefined || lengthLabel !== undefined ? (
            <Typography variant='s3'>
              {lengthLabel}&nbsp;
              {typeLabel}
            </Typography>
          ) : (
            <Typography variant='s3'>-</Typography>
          );
        },
      },
      {
        field: 'quantity',
        title: 'QUANTITY',
        cellStyle: (e, row) => ({ padding: row.lastChild ? '10px 16px' : '' }),
        render: (rowData) => {
          const { stop_point_type, quantity, quantity_type, lastChild } = rowData || {};
          const totalValues =
            +stop_point_type === 1
              ? getCargoTotals(rowData.cargos)
              : +stop_point_type === 2
              ? getCargoTotals(rowData.connected_cargos)
              : null;

          if (lastChild) {
            return (
              <Typography className={styles.total_cargo} variant='button2'>
                {rowData?.qty} {rowData?.qty_type?.name}
              </Typography>
            );
          }
          return +stop_point_type === 1 ? (
            <Typography variant='s3' style={{ color: totalValues?.expected_quantity && '#14804A' }}>
              {totalValues?.expected_quantity
                ? `+ ${totalValues.expected_quantity} ${rowData.cargos?.[0]?.expected_quantity_type?.name}`
                : '-'}
            </Typography>
          ) : +stop_point_type === 2 ? (
            <Typography variant='s3' style={{ color: (!!totalValues?.expected_quantity || !!quantity) && '#D12953' }}>
              {totalValues?.expected_quantity
                ? `- ${totalValues.expected_quantity} ${rowData.connected_cargos?.[0]?.expected_quantity_type?.name}`
                : quantity
                ? `- ${quantity} ${QUANTITY_TYPE[quantity_type]?.label}`
                : '-'}
            </Typography>
          ) : (
            <Typography variant='s3'>
              {quantity ? `- ${quantity} ${QUANTITY_TYPE[quantity_type]?.label}` : '-'}
            </Typography>
          );
        },
      },
      {
        field: 'dimensions',
        title: 'CU. FT.',
        cellStyle: (e, row) => ({ padding: row.lastChild ? '10px 16px' : '' }),
        render: (rowData) => {
          const type = rowData.stop_point_type;
          const style = { color: +type === 1 ? palette.green500 : palette.red500 };

          const { l, w, h } = rowData?.dimensions || {};
          const totalValues =
            +type === 1
              ? getCargoTotals(rowData.cargos)
              : +type === 2
              ? getCargoTotals(rowData.connected_cargos)
              : null;
          const lCount = rowData.lastChild
            ? Number(rowData?.l) * DIMENSION_UNIT_TO_FEET['2']
            : +l * DIMENSION_UNIT_TO_FEET['2'];
          const wCount = rowData.lastChild
            ? Number(rowData?.w) * DIMENSION_UNIT_TO_FEET['2']
            : +w * DIMENSION_UNIT_TO_FEET['2'];
          const hCount = rowData.lastChild
            ? Number(rowData?.h) * DIMENSION_UNIT_TO_FEET['2']
            : +h * DIMENSION_UNIT_TO_FEET['2'];

          if (rowData.lastChild) {
            return (
              <Typography className={styles.total_cargo} variant='button2'>
                {formatNumber(rowData?.totalFit)}
              </Typography>
            );
          }

          if (+type === 1) {
            return (
              <Typography variant='s3' style={style} className={styles.wrapper_delivery_data}>
                +{formatNumber(totalValues.volumeInCubicFeet)}
              </Typography>
            );
          }

          if (+type === 2) {
            return (
              <Typography variant='s3' style={style} className={styles.wrapper_delivery_data}>
                {rowData?.connected_cargos?.length
                  ? `- ${formatNumber(totalValues.volumeInCubicFeet)}`
                  : l && w && h
                  ? `- ${(lCount * wCount * hCount * Number(rowData?.quantity)).toFixed(2)}`
                  : '-'}
              </Typography>
            );
          }

          return (
            <Typography variant='s3' style={style} className={styles.wrapper_delivery_data}>
              {l && w && h ? (lCount * wCount * hCount * Number(rowData?.quantity)).toFixed(2) : '-'}
            </Typography>
          );
        },
      },
      {
        field: 'stackable',
        title: 'STACKABLE',
        cellStyle: (e, row) => ({ padding: row.lastChild ? '10px 16px' : '' }),
        render: (rowData) => {
          const stackable = rowData?.stackable ? 'Yes' : 'No';
          if (rowData.lastChild) {
            return null;
          }
          return <Typography variant='s3'>{stackable}</Typography>;
        },
      },
      {
        field: 'weight',
        title: 'WEIGHT',
        cellStyle: (e, row) => ({ padding: row.lastChild ? '10px 16px' : '' }),
        render: (rowData) => {
          const type = +rowData.stop_point_type;
          const { weight, weight_type } = rowData || {};
          const symbol = +rowData.stop_point_type === 1 ? '+' : '-';
          const weightTypeLabel = !Number.isNaN(Number(weight_type))
            ? WEIGHT_TYPE?.find((el) => Number(el?.key) === +weight_type)?.label
            : weight_type?.unit;
          const style = { color: type === 1 ? palette.green500 : palette.red500 };
          const totalValues =
            +type === 1
              ? getCargoTotals(rowData.cargos)
              : +type === 2
              ? getCargoTotals(rowData.connected_cargos)
              : null;

          if (rowData.lastChild) {
            return (
              <Typography className={styles.total_cargo_weight} variant='button2'>
                {numberWithCommas(Math.ceil(rowData?.weight))} {weightTypeLabel}
              </Typography>
            );
          }

          if (+type === 1) {
            return (
              <Typography variant='s3' style={style}>
                {symbol}&nbsp;
                {totalValues?.expected_weight && formatNumber(totalValues?.expected_weight)}&nbsp;
                {rowData.cargos?.[0]?.expected_weight_type?.unit}
              </Typography>
            );
          }

          if (+type === 2) {
            return rowData?.connected_cargos?.length ? (
              <Typography variant='s3' style={style}>
                {symbol}&nbsp;
                {totalValues?.expected_weight && formatNumber(totalValues?.expected_weight)}&nbsp;
                {rowData.connected_cargos?.[0]?.expected_weight_type?.unit}
              </Typography>
            ) : (
              <Typography variant='s3' style={style}>
                {symbol}&nbsp;
                {weight && numberWithCommas(weight)}&nbsp;
                {weightTypeLabel}
              </Typography>
            );
          }

          return (
            <Typography variant='s3' style={style}>
              {symbol}&nbsp;
              {weight && numberWithCommas(weight)}&nbsp;
              {weightTypeLabel}
            </Typography>
          );
        },
      },
    ];
  }, [equipmentLengthData, equipmentRequired, getAlphabet]);

  const getActiveTotal = (data) => {
    const initialValue = { l: 0, w: 0, h: 0, weight: 0, qty: 0, totalFit: 0 };
    return data.reduce((acc, el) => {
      if (Number(el?.stop_point_type) === 1) {
        const totalValues = getCargoTotals(el.cargos);

        const newL = acc.l + Number(totalValues?.length || 0);
        const newW = acc.w + Number(totalValues?.width || 0);
        const newH = acc.h + Number(totalValues?.height || 0);
        const newQty = acc.qty + Number(totalValues?.expected_quantity || 0);
        const newWeight = acc.weight + Number(totalValues?.expected_weight || 0);
        const weight_type = el.cargos?.[0]?.expected_weight_type;
        const qty_type = el.cargos?.[0]?.expected_quantity_type;

        const newTotalFit = (parseFloat(acc.totalFit) + totalValues.volumeInCubicFeet).toFixed(2);

        return {
          l: newL,
          w: newW,
          h: newH,
          qty: newQty,
          totalFit: newTotalFit,
          weight: newWeight,
          weight_type,
          qty_type,
        };
      }

      return acc;
    }, initialValue);
  };

  const mapHelper = useMemo(() => {
    let count = 0;
    let weightDelivery = 0;
    let prevPickupIndex = 0;

    const data = [];

    stops.forEach((stop, index, array) => {
      const { stop_point_type, weight, weight_type, qty_type } = stop || {};

      if (+stop_point_type === 1) {
        count += 1;
        const elWeightInLBS = +weight;
        weightDelivery += Number(elWeightInLBS);

        if (index !== 0) {
          const activeTotal = getActiveTotal(array.slice(prevPickupIndex, index));
          const pickupStopPoint = stopPoints.find((el) => +el.key === +array[prevPickupIndex].stop_point_id);
          const deliveryStopPoint =
            array[index - 1].stop_point_type === 2
              ? stopPoints.find((el) => +el.key === +array[index - 1].stop_point_id)
              : null;
          const { location_name } = deliveryStopPoint || {};

          data.push({
            lastChild: true,
            title: deliveryStopPoint
              ? `Cargo total from ${pickupStopPoint.location_name} to ${location_name}`
              : `Cargo total for ${pickupStopPoint.location_name}`,
            ...activeTotal,
          });
        }

        data.push({ ...stop, count, index, weight_type, qty_type });
        prevPickupIndex = index;
        return;
      }
      if (+stop_point_type === 3) {
        count += 1;
        data.push({ ...stop, count, index });
      }
      if (+stop_point_type === 2) {
        const newDelivery = { ...stop, weightDelivery, index };
        weightDelivery = 0;
        data.push(newDelivery);
      }

      return null;
    });

    const activeTotal = getActiveTotal(stops.slice(prevPickupIndex));
    const pickupStopPoint = stopPoints.find((el) => +el.key === +stops[prevPickupIndex].stop_point_id);
    const deliveryStopPoint = stopPoints.find((el) => +el.key === +stops[stops.length - 1].stop_point_id);
    const { location_name } = deliveryStopPoint || {};

    data.push({
      lastChild: true,
      title: `Cargo total from ${pickupStopPoint.location_name} to ${location_name}`,
      ...activeTotal,
    });

    return data;
  }, [optimizeLTLOrder, createShipmentData]);

  return (
    <div className={classNames(styles.validation_container, styles.light)}>
      <div className={styles.table_wrapper}>
        <div className={styles.numbers_wrapper}>
          <div style={{ height: '50px' }} />
          {mapHelper.map((el) => {
            return (
              <div key={el?.id} style={{ height: el.lastChild ? '46px' : '' }}>
                {!el?.lastChild && (
                  <div className={styles.count_wrapper_item}>
                    <div className={styles.count_wrapper}>
                      <Typography variant='s2'>{getAlphabet(el.index)}</Typography>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className={styles.top_container_wrapper} style={{ flexGrow: 1, marginRight: 20 }}>
          <MaterialTableWrapper data={mapHelper} style={{ backgroundColor: palette.white }} columns={columns} />
        </div>
      </div>

      <div className={styles.footer} style={{ backgroundColor: palette.white }}>
        <div>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '6px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={onCloseModal}
          />
        </div>

        <div className={styles.right_buttons}>
          <CustomButton
            type='secondary'
            title='Back'
            styleButton={{ padding: '6px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => setStep((p) => p - 1)}
          />
          <CustomButton
            type='primary'
            title='Next Step'
            styleButton={{ padding: '6px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => setStep((p) => p + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default ValidationPage;
