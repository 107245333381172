import React, { useContext, useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import HeaderStar from 'components/HeaderStar';
import CustomModal from 'components/CustomModal/CustomModal';
import UpdateDocumentType from 'components/UpdateDocumentType';
import CustomButton from 'components/CustomButton/CustomButton';
import AddDocumentModalContent from 'components/AddDocumentModalContent';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { TableContext } from 'context/tableContext';
import { addDocument, documentType, shipmentIds } from 'Api/Document';
import styles from './DocumentsHeader.module.css';

const DocumentsHeader = ({ documentTypeList, shipmentTypeList, onListUpdate, onShipmentUpdate }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { use } = useTheme();
  const { updateOnCreate, usedStorage, documentStatusesList } = useContext(TableContext);

  const showToaster = useShowToaster();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDocType, setIsOpenDocType] = useState(false);
  const [deletedTypeList, setDeletedTypeList] = useState([]);
  const [dependency, setDependency] = useState(0);
  const [showLicence, setShowLicence] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmitAddModalModal = (values) => {
    setLoading(true);
    const data = values;

    if (values.shipment_stop) {
      data.shipment_stop_id = values.shipment_stop.id;
    }

    addDocument(data)
      .then(() => {
        updateOnCreate((p) => !p);
        setIsOpen(false);
        showToaster({ type: 'success', message: 'Document was uploaded successfully!' });
      })
      .catch((err) => {
        if (getErrorMessage(err) === 'Maximum storage for documents reached') {
          setShowLicence(true);
          setIsOpen(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (location?.state?.openCreate) {
      setIsOpen(true);
    }
  }, [location?.state]);

  useEffect(() => {
    async function init() {
      documentType().then((res) => {
        if (res) {
          if (res.data && res.data.length) {
            const options = res.data.map((el) => {
              return {
                ...el,
                key: el.id,
                label: el.name,
                labelSelected: null,
              };
            });
            onListUpdate(options);
          }
        }
      });
      shipmentIds().then((res) => {
        if (res) {
          if (res.data && res.data.length) {
            const any = [{ id: 0, label: 'No Shipment', shipment_id: 'No Shipment' }];
            const options = any.concat(res.data).map((el) => {
              return {
                ...el,
                key: el.shipment_id,
                label: el.shipment_id,
                labelSelected: null,
              };
            });
            onShipmentUpdate(options);
          }
        }
      });
    }

    init().catch(null);
  }, [dependency]);

  const resetState = () => {
    onListUpdate(documentTypeList.filter((item) => item.id).concat(deletedTypeList));
    setDeletedTypeList([]);
  };

  return (
    <>
      {showLicence && (
        <div className={styles['check-addon-document']} onClick={() => setShowLicence(false)}>
          You have exceed your maximum storage limit, please upgrade.
          <span onClick={() => navigate('/billing?openAddon=1')}> Purchase Addon </span>
        </div>
      )}
      <div className={styles.documentHeader_wrapper}>
        <HeaderStar title='Documents' />
        <div className='d-flex align-items-end'>
          <CustomButton
            width={12}
            height={12}
            type='primary'
            title='Add Document'
            leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px' }}
            onClick={() => setIsOpen(true)}
          />
          <div
            className='top_popover'
            style={{ backgroundColor: use(palette.white, palette.dark800), marginBottom: 0, height: 36 }}
            onClick={() => setIsOpenDocType(true)}
          >
            <img src={moreInfo} alt='' />
          </div>
        </div>
      </div>

      <div className='progress_wrapper'>
        <ProgressBar now={usedStorage.percent} />
        <span className={styles.percentage}>{usedStorage.storage_used} GB used</span>
      </div>

      <ProgressBar className='w-100' style={{ height: 16, borderRadius: 8 }}>
        {documentStatusesList.map((item) => {
          return <ProgressBar style={{ background: item.color }} now={item.status_count * 100} key={item.color} />;
        })}
      </ProgressBar>
      {isOpen && (
        <AddDocumentModalContent
          open={isOpen}
          onSubmit={onSubmitAddModalModal}
          documentTypeList={documentTypeList}
          shipmentTypeList={shipmentTypeList}
          onClose={() => setIsOpen(false)}
          loading={loading}
        />
      )}
      <CustomModal
        showModal={isOpenDocType}
        styleBody={{ width: 844, backgroundColor: '#F7F9FC' }}
        onHide={() => setIsOpenDocType(false)}
        onExited={resetState}
        headerTitle='Update Document Type'
        styleButtons={{ padding: '6px 12px' }}
        buttons={[{ key: 'close', type: 'secondary', title: 'Done' }]}
      >
        <UpdateDocumentType onListUpdate={() => setDependency(Date.now())} />
      </CustomModal>
    </>
  );
};

export default DocumentsHeader;
