import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as ArrowIcon } from 'assets/sidemanu/arrowDown.svg';
import { Typography } from '../Typography';
import { onChangeIsOpenSendPayment } from '../../store/reducers/header.reducer';

const LeftMenuOpenCollapse = ({ parentItem, subItems, setActiveId, ParentIcon, menuStyleLocal, openInNewTab }) => {
  const navigate = useNavigate();
  const { use } = useTheme();
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state?.root);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { id, path, title, titleStyles, iconStyles } = parentItem;

  const onClickPopoverItem = (e, path, state, item) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(state);
    const fullPath = searchParams.size !== 0 ? `${path}?${searchParams}` : path;
    if (openInNewTab && fullPath && +item.id !== 86) {
      window.open(fullPath);
      return;
    }
    +item.id === 86 && dispatch(onChangeIsOpenSendPayment({ open: true }));
    path && path !== '' && navigate(path, { state });
  };

  const getActivePath = (item) => {
    if (item.path) {
      return pathname.startsWith(item.path);
    }
    if (item.collapse?.subItems) {
      const activeSubItem = item.collapse.subItems.find((subItem) => subItem.path && pathname.startsWith(subItem.path));
      return activeSubItem ? pathname.startsWith(activeSubItem.path) : '';
    }
    return '';
  };

  const filteredSubItems = subItems.filter(
    (item) =>
      parentItem.title === 'Favorites' ||
      !item.subMenuPermissions?.length ||
      item.subMenuPermissions?.some((permission) => permissions.includes(permission))
  );

  return (
    <>
      <div
        className={`icon_wrapper icon_wrapper_leftMenuOpenCollapse ${use('light', 'dark', 'primary')}`}
        onClick={() => {
          setActiveId(id);
          path && navigate(path);
          setIsOpen((p) => !p);
        }}
      >
        <div key={id} style={{ display: 'flex', alignItems: 'center' }}>
          <div className='icon-container' style={{ ...iconStyles }}>
            <ParentIcon
              width={19}
              height={19}
              fill={
                getActivePath(parentItem)
                  ? use(palette.indigo500, palette.indigo400, palette.white)
                  : use(palette.gray500, palette.gray400, palette.indigo200)
              }
            />
          </div>
          <p
            className='button-text'
            style={{
              display: menuStyleLocal ? 'none' : 'block',
              color: getActivePath(parentItem)
                ? use(palette.indigo500, palette.indigo400, palette.white)
                : use(palette.gray500, palette.gray400, palette.indigo200),
              ...titleStyles,
            }}
          >
            {title}
          </p>
          <ArrowIcon
            width={8}
            height={6}
            fill={
              getActivePath(parentItem)
                ? use(palette.indigo500, palette.indigo400, palette.white)
                : use(palette.gray500, palette.gray400, palette.indigo200)
            }
            style={{ transform: isOpen ? 'rotateX(180deg)' : '', transition: '0.5s all ease' }}
          />
        </div>
      </div>

      <Collapse in={isOpen} timeout='auto'>
        <div className={`leftMenuOpenCollapse_wrapper ${use('light', 'dark', 'primary')}`}>
          <div
            className='leftMenuOpenCollapse_line'
            style={{
              height: 44 * filteredSubItems.length,
              backgroundColor: use(palette.gray200, palette.dark700, palette.indigo600),
            }}
          />
          <div className='items_wrapper'>
            {filteredSubItems.map((item) => {
              const { id, subTitle, path, Icon, state } = item;
              const TagName = item?.path ? NavLink : 'div';
              const searchParams = new URLSearchParams(state);
              const fullPath = searchParams?.size !== 0 ? `${path}?${searchParams}` : path;
              return (
                <TagName
                  key={id}
                  {...(fullPath && { to: fullPath })}
                  className='leftMenuOpenCollapse_container'
                  onClick={(e) => onClickPopoverItem(e, path, state, item)}
                >
                  {Icon && <Icon fill={use(palette.indigo200, palette.gray200, palette.indigo200)} width={20} />}
                  <Typography
                    variant='s2'
                    style={
                      subTitle === 'Equipment (Vehicle or Trailer)'
                        ? {
                            marginLeft: '-21px',
                            color: use(palette.gray500, palette.gray200, palette.indigo200),
                            textAlign: 'left',
                          }
                        : {
                            marginLeft: '4px',
                            color: use(palette.gray500, palette.gray200, palette.indigo200),
                            textAlign: 'left',
                          }
                    }
                  >
                    {subTitle}
                  </Typography>
                </TagName>
              );
            })}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default LeftMenuOpenCollapse;
