import React, { memo, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Formik, FastField } from 'formik';
import Camera from 'assets/icons/camera.svg';
import DefaultAvatar from 'assets/icons/profile.svg';
import { palette } from 'utils/constants';
import { getDepartments } from 'Api/Customers';
import AddressAutocomplete from 'common/AddressAutocomplete';
import { searchCities, searchCountries, searchStates } from 'Api/Driver';
import useShowToaster from 'hooks/useShowToaster';
import styles from './Profile.module.css';

import { Typography } from '../Typography';
import ImageDropZone from '../ImgageDropZone';
import CustomPhoneInput from '../CustomPhoneInput';
import CustomButton from '../CustomButton/CustomButton';
import ChangePassword from '../ChangePassword/ChangePassword';
import CustomInput from '../CreateShipment/helpers/CustomInput';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';

const ProfileEdit = ({ userData, onSubmit, bindSubmitForm }) => {
  const showToaster = useShowToaster();
  const [countries, setCountries] = useState(null);
  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(userData?.profile_logo);

  const uploadDocument = (file) => {
    if (file.length) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(file[0]);
        setImageUrl(reader?.result);
      };
      reader.readAsDataURL(file[0]);
    } else {
      showToaster({
        type: 'error',
        message: `Your picture is too large. We allow only up to 5mb for your profile picture. Please resize and try again!`,
      });
    }
  };

  const getCities = (state_id) => {
    searchCities('', state_id).then((res) => {
      setCities(res.data);
    });
  };

  const getStates = (country_id) => {
    searchStates('', country_id).then((res) => {
      setStates(res.data);
    });
  };

  useEffect(() => {
    searchCountries('').then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    getDepartments().then((res) => {
      setDepartments(res.data);
    });
  }, []);

  const onRemove = () => {
    setImageUrl(null);
  };

  const validateRequired = (value) => {
    let error;
    if (value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const profileStyle = useMemo(() => {
    return {
      dropZone: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      firstName: {
        width: 320,
        borderRadius: 6,
        height: 32,
        marginRight: 8,
        padding: '0 6px',
      },
      lastName: {
        width: 320,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      phoneNumberCountry: {
        height: 32,
        width: 68,
        marginTop: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        padding: '0 6px',
      },
      phoneNumber: {
        width: 252,
        borderRadius: 6,
        height: 32,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: '0 6px',
      },
      email: {
        width: 320,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      address: {
        width: 400,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      department: {
        height: 32,
        width: 320,
        marginTop: 10,
      },
      title: {
        width: 320,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      zip: {
        width: 194,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      globalLabel: {
        width: 240,
        margin: 'auto 0',
        color: palette.gray700,
      },
    };
  }, [palette]);

  const handleSubmit = (values) => {
    const body = { ...values };
    if (image) {
      body.profile_logo = image;
    }
    if (!image && !imageUrl) {
      body.profile_logo = 'delete'; // This is because backend cannot handle empty string or null
    }
    onSubmit(body);
  };

  const cache = useMemo(() => Date.now().toString(), [imageUrl, userData]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        first_name: userData?.first_name ? userData?.first_name : '',
        last_name: userData?.last_name ? userData?.last_name : '',
        phoneNumber_country: userData.country.sortname ? userData.country.sortname : '',
        phone_number: userData.phone_number ? userData.phone_number : '',
        email: userData.email ? userData.email : '',
        address1: userData?.address1 ? userData?.address1 : '',
        address2: userData?.address2 ? userData?.address2 : '',
        zip: userData?.zip ? userData.zip : '',
        department: userData?.department?.id ? userData?.department?.id : '',
        title: userData?.title ? userData?.title : '',
      }}
    >
      {({ values, submitForm, setFieldValue }) => {
        bindSubmitForm(submitForm);
        const onAddressSelect = (data) => {
          getStates(data.country?.id);
          getCities(data.state?.id);
          setFieldValue('country', data.country?.id.toString());
          setFieldValue('state', data.state?.id.toString());
          setFieldValue('city', data.city?.id.toString());
        };

        const changeAddress = ({ address, zip }) => {
          setFieldValue('address1', address);
          setFieldValue('zip', zip || values.zip);
        };

        return (
          <div className={styles.editProfile_wrapper}>
            <div className={styles.editProf_pictureWrapper}>
              <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
                Picture
              </Typography>
              <label className={styles.avatar_wrapper}>
                <figure className={styles.profile_image_wrapper}>
                  {imageUrl === null ? (
                    <img src={DefaultAvatar} alt='img' width={48} height={48} style={{ borderRadius: '50%' }} />
                  ) : (
                    <img
                      src={imageUrl?.includes('base64') ? imageUrl : `${imageUrl}?cache=${cache}`}
                      alt='img'
                      width={48}
                      height={48}
                      style={{ borderRadius: '50%' }}
                    />
                  )}
                </figure>
                <ImageDropZone
                  onDrop={uploadDocument}
                  width='100%'
                  height='100%'
                  name='small'
                  deletable={false}
                  maxSize={5242880} // 5MB
                  customStyle={profileStyle.dropZone}
                />
                <figure className={styles.camera}>
                  <img src={Camera} alt='img' width={14} height={14} style={{ borderRadius: '50%' }} />
                </figure>
              </label>
              <Typography className={styles.remove} variant='s2' style={{ color: palette.red500 }} onClick={onRemove}>
                Remove
              </Typography>
            </div>
            <div className={styles.edit_basic_wrapper}>
              <Typography variant='s2' style={profileStyle.globalLabel}>
                Name
              </Typography>
              <div className={styles.error_wrapper}>
                <FastField
                  name='first_name'
                  type='text'
                  component={CustomInput}
                  value={values.first_name}
                  validate={validateRequired}
                  style={profileStyle.firstName}
                />
                <ErrorMessage
                  name='first_name'
                  render={(error) => (
                    <Typography variant='c2' style={{ color: palette.red500 }}>
                      {error}
                    </Typography>
                  )}
                />
              </div>
              <div className={styles.error_wrapper}>
                <FastField
                  name='last_name'
                  type='text'
                  component={CustomInput}
                  value={values.last_name}
                  validate={validateRequired}
                  style={profileStyle.lastName}
                />
                <ErrorMessage
                  name='last_name'
                  render={(error) => (
                    <Typography variant='c2' style={{ color: palette.red500 }}>
                      {error}
                    </Typography>
                  )}
                />
              </div>
            </div>
            <div className={styles.edit_basic_wrapper}>
              <Typography variant='s2' style={profileStyle.globalLabel}>
                Phone Number
              </Typography>
              <div className={styles.phoneNumber_wrapper}>
                <Field
                  name='phone_number'
                  inputStyle={{ width: 320 }}
                  labelStyle={{ marginTop: 10 }}
                  countryCodeEditable={false}
                  component={CustomPhoneInput}
                />
              </div>
            </div>
            <div className={styles.edit_basic_wrapper}>
              <Typography variant='s2' style={profileStyle.globalLabel}>
                Email Address
              </Typography>
              <div className={styles.error_wrapper}>
                <FastField
                  name='email'
                  type='text'
                  component={CustomInput}
                  validate={validateRequired}
                  value={values.email_address}
                  style={profileStyle.email}
                />
                <ErrorMessage
                  name='email'
                  render={(error) => (
                    <Typography variant='c2' style={{ color: palette.red500 }}>
                      {error}
                    </Typography>
                  )}
                />
              </div>
            </div>
            <div className={styles.edit_basic_wrapper}>
              <Typography variant='s2' style={profileStyle.globalLabel}>
                Current Password
              </Typography>
              <CustomButton
                type='secondary'
                title='Change Password'
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                className={styles.change_password}
                onClick={() => setOpenPasswordModal(true)}
              />
            </div>
            <div className={styles.physical_wrapper}>
              <Typography variant='s2' style={{ color: palette.gray700, width: 240 }}>
                Physical Address
              </Typography>

              <div className={styles.edit_address_info_wrapper}>
                <div className={styles.error_wrapper}>
                  <AddressAutocomplete
                    name='address1'
                    onSuccess={onAddressSelect}
                    changeAddress={changeAddress}
                    onChange={(e) => setFieldValue('address1', e.target.value)}
                    value={values.address1}
                    width='400px'
                  />
                </div>
                <Field
                  name='address2'
                  type='text'
                  component={CustomInput}
                  placeholder={userData.address2}
                  style={profileStyle.address}
                />
                <div className={styles.address_bottom_wrapper}>
                  <div className={styles.left_side}>
                    <div className={styles.control}>
                      <Field
                        name='country'
                        defaultText={userData?.country?.name}
                        options={countries}
                        styles={{ height: 32, width: 194, marginRight: 8 }}
                        component={CustomSelect}
                        keyName='id'
                        labelName='name'
                        menuStyles={{ width: 194 }}
                        isSearchable
                        isSearchableStyles={{ width: 194, height: 32 }}
                        onChange={(e) => {
                          getStates(e.id);
                        }}
                      />
                    </div>
                    <div className={styles.control}>
                      <Field
                        name='city'
                        defaultText={userData?.city.name}
                        options={cities}
                        styles={{ height: 32, width: 194, marginRight: 8 }}
                        component={CustomSelect}
                        keyName='id'
                        labelName='name'
                        menuStyles={{ width: 194 }}
                        isSearchable
                        isSearchableStyles={{ width: 194, height: 32 }}
                      />
                    </div>
                  </div>
                  <div className={styles.right_side}>
                    <div className={styles.control}>
                      <Field
                        name='state'
                        defaultText={userData.state.name}
                        options={states}
                        styles={{ height: 32, width: 194, marginRight: 8 }}
                        component={CustomSelect}
                        keyName='id'
                        labelName='name'
                        menuStyles={{ width: 194 }}
                        isSearchable
                        isSearchableStyles={{ width: 194, height: 32 }}
                        onChange={(e) => {
                          getCities(e.id);
                        }}
                      />
                    </div>
                    <div className={styles.error_wrapper}>
                      <FastField
                        name='zip'
                        type='text'
                        component={CustomInput}
                        validate={validateRequired}
                        placeholder='Zip Code'
                        style={profileStyle.zip}
                      />
                      <ErrorMessage
                        name='address1'
                        render={(error) => (
                          <Typography variant='c2' style={{ color: palette.red500 }}>
                            {error}
                          </Typography>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.edit_basic_wrapper}>
              <Typography variant='s2' style={profileStyle.globalLabel}>
                Department
              </Typography>
              <div className={styles.edit_department}>
                <Field
                  name='department'
                  options={departments}
                  styles={profileStyle.department}
                  disabled={userData?.department?.department_name !== 'Management'}
                  component={CustomSelect}
                  keyName='id'
                  labelName='department_name'
                />
              </div>
            </div>
            <div className={styles.edit_basic_wrapper}>
              <Typography variant='s2' style={profileStyle.globalLabel}>
                Title
              </Typography>
              <div className={styles.error_wrapper}>
                <FastField
                  name='title'
                  type='text'
                  component={CustomInput}
                  value={values.title}
                  validate={validateRequired}
                  style={profileStyle.title}
                />
                <ErrorMessage
                  name='first_name'
                  render={(error) => (
                    <Typography variant='c2' style={{ color: palette.red500 }}>
                      {error}
                    </Typography>
                  )}
                />
              </div>
            </div>
            {openPasswordModal && (
              <ChangePassword open={openPasswordModal} onClose={() => setOpenPasswordModal(false)} />
            )}
          </div>
        );
      }}
    </Formik>
  );
};
export default memo(ProfileEdit);
