import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  address1: string().trim().required('Required').nullable(),
  address2: string().trim().nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zip: string().trim().required('Required'),
});
