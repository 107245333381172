export const getInitialValues = () => {
  const brokerInfo = JSON.parse(localStorage.getItem('brokerInfo'));
  const carrierInfo = JSON.parse(localStorage.getItem('account'));

  return {
    company_name: brokerInfo?.company_name || '',
    dot: brokerInfo?.dot || '',
    email: brokerInfo?.email || carrierInfo?.email || '',
    phone_number: brokerInfo?.phone_number || '',
    address: brokerInfo?.address || '',
    address2: brokerInfo?.address2 || '',
    country: brokerInfo?.country || null,
    state: brokerInfo?.state || null,
    city: brokerInfo?.city || null,
    zipcode: brokerInfo?.zipcode || null,
  };
};
