import styled from 'styled-components';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

export const STypography = styled(Typography)`
  &.label {
    margin-bottom: 8px;
  }

  &.label-required:after {
    content: '*';
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: ${palette.indigo500};
    margin-left: 4px;
  }
`;
