import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import { useParams } from 'react-router-dom';
import { addInventoryTab, deleteTab, updateTab } from 'Api/StopPoint';
import styles from './AddTab.module.css';

const AddTabModal = (props) => {
  const { use } = useTheme();
  const { id } = useParams();
  const { onClickButtons, initialValue, customers, stopPointsData, type, equipmentType } = props;

  const [alert, setAlert] = useState(false);

  const [selectDataCustomer, setSelectDataCustomer] = useState(null);
  const [stopPointSelected, setStopPointSelected] = useState(null);
  const [stopPointBSelected, setStopPointBSelected] = useState(null);

  const addTab = (dataJson) => {
    addInventoryTab(dataJson).then(() => {
      onClickButtons(false, true);
    });
  };

  const updateTabModal = (dataJson) => {
    updateTab({ id: initialValue.tabId, dataJson }).then(() => {
      onClickButtons(false, true);
    });
  };

  function onChangeMultiselectCustomers(value) {
    setSelectDataCustomer(value);
  }

  function onChangeStopPont(value) {
    setStopPointSelected(value);
    if (value.id !== +id) {
      setStopPointBSelected(stopPointsData.find((item) => item.id === +id));
    }
  }

  function onChangeStopPontB(value) {
    setStopPointBSelected(value);
    if (value.id !== +id) {
      setStopPointSelected(stopPointsData.find((item) => item.id === +id));
    }
  }

  function onDelete() {
    deleteTab(initialValue.tabId).then((res) => {
      if (res && res?.data) {
        onClickButtons(false, true);
      }
    });
  }

  function onSubmit(values) {
    const { tab_name, count } = values;
    const dataJson = {
      type,
      name: tab_name,
      stop_point_id: id,
      customer_id: selectDataCustomer?.id,
      first_stop: stopPointSelected?.id,
      second_stop: stopPointBSelected?.id,
      equipment_type: equipmentType,
      alert: Number(alert),
      max_quantity: count,
    };

    !initialValue?.name ? addTab(dataJson) : updateTabModal(dataJson);
  }

  const style = useMemo(() => {
    return {
      labelNameInput: {
        margin: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 10,
      },
      styleNameInput: {
        width: 414,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      styleCountInput: {
        width: 414,
        borderRadius: 6,
        height: 32,
        padding: '0 6px',
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
    };
  }, [palette, use]);

  const validateRequired = (value) => {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  };

  useEffect(() => {
    const { name, customer, first_stop, second_stop, alert } = initialValue || {};

    if (name) {
      if (customer) {
        setSelectDataCustomer(customers.find((item) => item.id === customer));
      }
      if (first_stop) {
        setStopPointSelected(stopPointsData.find((item) => item.id === first_stop));
      }
      if (second_stop) {
        setStopPointBSelected(stopPointsData.find((item) => item.id === second_stop));
      }
      setAlert(!!alert);
    }
  }, [initialValue]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        tab_name: initialValue.name ? initialValue.name : '',
        count: initialValue.count ? initialValue.count : '',
      }}
    >
      {({ submitForm }) => (
        <Form>
          <div className={styles.container}>
            <div className={styles.filed_error_wrapper}>
              <Field
                required
                name='tab_name'
                label='Tab name'
                component={CustomInput}
                validate={validateRequired}
                style={style.styleNameInput}
                labelStyle={style.labelNameInput}
              />
              <ErrorMessage
                name='tab_name'
                render={(error) => (
                  <Typography variant='c2' style={style.error}>
                    {error}
                  </Typography>
                )}
              />
            </div>

            <div className={styles.select_wrapper}>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                Customer
              </Typography>

              <CustomSelect
                isSearchable
                options={customers}
                dropDownStyles={{ flexGrow: 0 }}
                styles={{ height: 32, marginTop: 6 }}
                onChange={onChangeMultiselectCustomers}
                defaultActiveValue={selectDataCustomer}
                isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 6 }}
              />

              <div className={styles.tab_stopPoint}>
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  Origin
                </Typography>

                <CustomSelect
                  isSearchable
                  options={stopPointsData}
                  onChange={onChangeStopPont}
                  styles={{ height: 32, marginTop: 6 }}
                  defaultActiveValue={stopPointSelected}
                  dropDownStyles={{ flexGrow: 0, minWidth: '100%' }}
                  isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 6 }}
                />
              </div>

              <div className={styles.tab_stopPoint}>
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  Destination
                </Typography>

                <CustomSelect
                  isSearchable
                  options={stopPointsData}
                  onChange={onChangeStopPontB}
                  styles={{ height: 32, marginTop: 6 }}
                  defaultActiveValue={stopPointBSelected}
                  dropDownStyles={{ flexGrow: 0, minWidth: '100%' }}
                  isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 6 }}
                />
              </div>

              <div className={styles.filed_error_wrapper}>
                <Field
                  required
                  name='count'
                  label='Limit on inventory count'
                  component={CustomInput}
                  validate={validateRequired}
                  style={style.styleNameInput}
                  labelStyle={style.labelNameInput}
                />
                <ErrorMessage
                  name='count'
                  render={(error) => (
                    <Typography variant='c2' style={style.error}>
                      {error}
                    </Typography>
                  )}
                />
              </div>
            </div>

            <CustomCheckbox checked={alert} style={{ marginBottom: 30 }} onChange={(checked) => setAlert(checked)}>
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200), marginLeft: 8 }}>
                Alert me if over this count
              </Typography>
            </CustomCheckbox>
          </div>

          <div className={styles.footer} style={{ background: use(palette.white, palette.dark800) }}>
            {initialValue.name ? (
              <CustomButton
                title='Remove'
                type='danger'
                styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                onClick={onDelete}
              />
            ) : (
              <div />
            )}

            <div className={styles.footer_right}>
              <CustomButton
                title='Cancel'
                type='secondary'
                onClick={() => onClickButtons(false, false)}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
              />
              <CustomButton
                title={initialValue.name ? 'Update Tab' : 'Add Tab'}
                type='primary'
                onClick={submitForm}
                styleTitle={{ fontSize: 12, marginTop: 1 }}
                styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTabModal;
