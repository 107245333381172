import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { ReactComponent as PLusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/detention.svg';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment-timezone';
import { formatPhoneNumber } from 'utils/helpers';
import { getAlphabet, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import {
  addRecurringLaneStop,
  addShipmentStop,
  deleteRecurringLaneStop,
  deleteShipmentStop,
  getStops,
  updateCargo,
  updateRecurringLaneStop,
  UpdateStopPointStop,
} from 'Api/Shipment';
import { UpdateStopPoint } from 'Api/StopPoint';
import { getSMSCommunicationSettings } from 'Api/CompanySettings';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import { ReactComponent as SubRight } from 'assets/icons/vehicles/sub-right.svg';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from 'components/Typography';
import AddEditModalDetails from 'components/AddEditModalStops';

import { MAPPER_STOPS } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import Map from 'components/CreateShipment/ShipmentStops/helpers/Map';
import OverLayStopsPlus from 'components/TablePlaner/helpers/RecurrningDetails/helpers/OverLayStopsPlus';
import {
  createCargoConverter,
  createCargoFormDataConverter,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import SettingsModal from './SettingsModal';
import OverViewStop from './OverViewStops';
import classes from './overView.module.css';
import { equipmentTypeText } from '../../../helpers/constants';

const OverView = ({
  isShipmentTable,
  infoHeader,
  getHeaderInfo,
  isRecurring,
  getRecurringData,
  onCheckInOutClick,
  onDryRunClick,
}) => {
  const { id } = useParams();
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { formatDate, formatDateTime } = useDateFormat();
  const [selectModalType, setSelectModalType] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const legsStopsCount = [];
  const [stops, setStops] = useState([]);
  const [legs, setLegs] = useState([]);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState('');
  const [route, setRoute] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mobileAppState, setMobileAppState] = useState();
  const [key, setKey] = useState(0);
  const [, setIsOpenModal] = useState();
  const [isAddLegVisible, setIsAddLegVisible] = useState(false);
  const { status, brokerage_dispatch } = infoHeader || {};
  const { carrier, contact_user, confirmation_signed, driver_phone_number } = brokerage_dispatch || {};

  const updateStop = (params, stopId) => {
    (isRecurring ? updateRecurringLaneStop(stopId, params) : UpdateStopPointStop({ params, id: stopId }))
      .then((res) => {
        if (res?.data) {
          getStopsData(true);
          getHeaderInfo();
          if (isRecurring) {
            getRecurringData();
          }
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      })
      .finally(() => setLoading(false));
  };

  function onClickItemSettingsIcon(stop) {
    setIsOpenSettingsModal(stop?.stop_point?.id);
  }

  function onDeleteStop(stopId) {
    (isRecurring ? deleteRecurringLaneStop(stopId) : deleteShipmentStop(stopId))
      .then(() => {
        if (isRecurring) {
          getRecurringData();
        } else {
          getHeaderInfo();
          getStopsData();
        }
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  }

  function onUpdateSettings(id, validateForm, resetForm, submitForm, values) {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        const backData = {};
        Object.keys(values).forEach((key) => {
          const value = values[key];
          if (typeof value === 'boolean') {
            backData[key] = value ? 1 : 0;
          } else {
            backData[key] = value;
          }
        });
        UpdateStopPoint(id, backData).then(() => setIsOpenSettingsModal(''));
      }
    });
  }

  const orderingInLegsStops = useCallback((data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    const stopsAll = [];
    legsStopsData.forEach((leg) => {
      leg?.stops?.forEach((stop) => {
        stopsAll.push(stop);
      });
    });
    setStops(stopsAll);
    return legsStopsData;
  }, []);

  const getStopsInitialsData = (loading = true) => {
    if (loading) setLoading(true);
    getStops({ shipment_id: id })
      .then((res) => {
        setLegs(orderingInLegsStops(res?.data));
        if (orderingInLegsStops(res?.data).length > 0) {
          if (infoHeader?.shipment_type === 'slave') {
            updateStopInRoute({ legs: orderingInLegsStops(res?.data), routeArr: null, soft: false });
          } else {
            if (infoHeader?.shipment_route_direction?.route) {
              const route = infoHeader?.shipment_route_direction.route;
              const routeArr = route ? JSON.parse(route) : null;
              updateStopInRoute({ legs: orderingInLegsStops(res?.data), routeArr, soft: false });
            }
          }
        }
      })
      .finally(() => setLoading(false));
    getSMSCommunicationSettings().then((result) => {
      setMobileAppState(result.data);
    });
  };

  const getStopsData = (loading = true) => {
    if (loading) {
      setLoading(true);
    }
    getStops({ shipment_id: id })
      .then((res) => {
        setLegs(orderingInLegsStops(res?.data));
        if (orderingInLegsStops(res?.data).length > 0) {
          if (infoHeader?.shipment_type === 'slave') {
            updateStopInRoute({ legs: orderingInLegsStops(res?.data), routeArr: null, soft: true });
          } else {
            if (infoHeader?.shipment_route_direction?.route) {
              const route = infoHeader?.shipment_route_direction.route;
              const routeArr = route ? JSON.parse(route) : null;
              updateStopInRoute({ legs: orderingInLegsStops(res?.data), routeArr, soft: true });
            }
          }
        }
      })
      .finally(() => setLoading(false));
    getSMSCommunicationSettings().then((result) => {
      setMobileAppState(result.data);
    });
  };

  const updateStopInRoute = ({ legs, routeArr, soft }) => {
    const route = [];
    const stops = [];
    let indexStop = null;

    legs.forEach((el) => {
      if (el?.stops) el.stops.forEach((stop) => stops.push(stop));
    });

    if (routeArr) {
      for (let i = 0; i < routeArr.length; i++) {
        const stopPoint = routeArr[i]?.stopPoint;
        if (stopPoint) {
          indexStop === null ? (indexStop = 0) : (indexStop += 1);
          const {
            geofencing_latitude: latitude,
            geofencing_longitude: longitude,
            location_name,
          } = stops[indexStop]?.stop_point || {};
          route.push({ latitude, longitude, location_name, stop_point_type: stops[indexStop]?.stop_point_type });
        } else {
          route.push(routeArr[i]);
        }
      }
    } else {
      for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];
        const {
          geofencing_latitude: latitude,
          geofencing_longitude: longitude,
          location_name,
        } = stop?.stop_point || {};
        route.push({ latitude, longitude, location_name, stop_point_type: stop?.stop_point_type });
      }
    }
    setRoute(route);
    if (soft) {
      setKey((prevState) => prevState + 1);
    }
  };

  const onAddEditModalDetails = (values, type, item) => {
    delete values?.id;
    if (type === 'edit') {
      const formData = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (key === 'to' || (val !== '' && !['arrival_time', 'departure_time'].includes(key))) {
          if (key === 'dimensions' && val?.l && val?.h && val?.w) {
            formData.append(`dimensions[l]`, val?.l || '');
            formData.append(`dimensions[h]`, val?.h || '');
            formData.append(`dimensions[w]`, val?.w || '');
          } else if (key === 'arrival_date') {
            if (val) {
              formData.append(
                'arrival_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.arrival_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'departure_date') {
            if (val) {
              formData.append(
                'departure_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.departure_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'bill_type') {
            formData.append('bill_type', values.bill_type ? '1' : '2');
          } else if (key === 'scheduled_date') {
            formData.append('scheduled_date', moment(val).format('YYYY-MM-DD'));
          } else if (key === 'scheduled_date_to') {
            formData.append('scheduled_date_to', moment(val || values?.scheduled_date).format('YYYY-MM-DD'));
          } else if (key === 'from') {
            formData.append('from', moment(values?.from).format('HH:mm'));
          } else if (key === 'to') {
            formData.append('to', moment(val || values?.from).format('HH:mm'));
          } else if (key === 'reported_quantity_type' && !!val) {
            formData.append(
              'reported_quantity_type',
              values?.reported_quantity_type?.id || values?.reported_quantity_type
            );
          } else if (key === 'reported_weight_type' && !!val) {
            formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
          } else if (key === 'connected_cargos' && val?.length) {
            val.forEach((cargo, index) => {
              formData.append(`connected_cargos[${index}]`, cargo.id);
            });
          } else if (key === 'cargos') {
            // Do nothing
          } else {
            let value = val;
            if (typeof val === 'boolean') {
              if (key === 'bill_type') {
                return;
              }
              value = val ? 1 : 0;
            }
            if (key === 'dimensions') {
              return;
            }
            formData.append(key, value);
          }
        }
      });
      updateStop(formData, item?.id);

      if (Number(values.stop_point_type) === 1 && values.cargos?.length === 1) {
        const body = createCargoConverter(values.cargos[0]);
        updateCargo(values.cargos[0].id, body);
      }
    } else {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'id') {
          return;
        }
        let value = values[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        if (key === 'bill_type') {
          formData.append('bill_type', !values.bill_type ? '1' : '2');
        }

        if (key === 'dimensions' && typeof value === 'object') {
          Object.keys(value).forEach((dimensionKey) => {
            formData.append(`dimensions[${dimensionKey}]`, value[dimensionKey] || '');
          });
        } else if (key === 'cargos' && !isRecurring) {
          const cargos = values.cargos?.length ? values.cargos.filter((i) => !!i?.commodity) : [];
          createCargoFormDataConverter(cargos, formData);
        } else if (key === 'reported_quantity_type' && values?.reported_quantity_type) {
          formData.append(
            'reported_quantity_type',
            values?.reported_quantity_type?.id || values?.reported_quantity_type
          );
        } else if (key === 'reported_weight_type' && values?.reported_weight_type) {
          formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
        } else if (key === 'connected_cargos' && value.length && !isRecurring) {
          value.forEach((cargo, index) => {
            formData.append(`connected_cargos[${index}]`, cargo.id);
          });
        } else if (key === 'scheduled_date') {
          formData.append('scheduled_date', moment(values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'scheduled_date_to') {
          formData.append('scheduled_date_to', moment(value || values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'from') {
          formData.append('from', moment(values?.from).format('HH:mm'));
        } else if (key === 'to') {
          formData.append('to', moment(value || values?.from).format('HH:mm'));
        } else {
          formData.append(key, value);
        }
      });
      formData.append('leg', selectModalType.legIndex);
      formData.append('order_in_leg', selectModalType.order_in_leg);
      if (isRecurring) {
        formData.append('shipment_planner_template_id', id);
      } else {
        formData.append('shipment_id', id);
      }

      (isRecurring ? addRecurringLaneStop(formData) : addShipmentStop(formData))
        .then(() => {
          if (isRecurring) {
            getRecurringData();
          } else {
            getStopsData();
            getHeaderInfo();
          }
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const onUpdateSuccess = () => {
    if (isRecurring) {
      getRecurringData();
    } else {
      getStopsData(false);
      getHeaderInfo();
    }
  };

  const createStopData = (index, stopPointType, allDataStop) => {
    const { color, background, name } = MAPPER_STOPS[+stopPointType];
    return { index, stopPointType: +stopPointType, color, background, allDataStop, name };
  };

  const activeStop = useMemo(() => {
    let firstStopWithoutArrival;

    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      const { arrival_date: arrival, stop_point_type: stopPointType, departure_date: departure } = stop || {};

      if (arrival && !departure) {
        return createStopData(i, stopPointType, stop);
      }
      if (!firstStopWithoutArrival && !arrival) {
        firstStopWithoutArrival = createStopData(i, stopPointType, stop);
      }
    }

    return firstStopWithoutArrival || {};
  }, [stops]);

  const mapDriverArr = useMemo(() => {
    const lng = infoHeader?.driver_from_gps_long;
    const lat = infoHeader?.driver_from_gps_lat;

    return [
      {
        id: infoHeader?.equipment?.id,
        img: infoHeader?.start_date_time ? mapMarkerIcon : infoHeader?.assigned_to_1?.image,
        miles: infoHeader?.empty_miles?.toFixed(),
        routeProps: {},
        marker: { lat, lng, draggable: false },
      },
    ];
  }, [infoHeader]);

  function addTimeToDate(date, days = 0, hours = 0, minutes = 0) {
    const totalMilliseconds = days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    return new Date(new Date(date).getTime() + totalMilliseconds);
  }

  const elapsedDay = useMemo(() => {
    if (infoHeader.shipment_stops) {
      const cloneStops = [...infoHeader.shipment_stops];
      const reversStops = Array.isArray(cloneStops) ? cloneStops.reverse() : [];
      const foundedIndex = reversStops.findIndex((el) => !!el?.arrival_date);
      const foundedStopHaveArrival = cloneStops[foundedIndex];
      let timeZone;

      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      return !!zoneTz && moment(zoneTz).diff(foundedStopHaveArrival?.arrival_date, 'minute');
    }
  }, [infoHeader, activeStop]);

  function parseTime(timeStr) {
    const components = timeStr.split(/\s+/);
    const date = new Date();
    for (const component of components) {
      if (component.endsWith('d')) {
        date.setDate(date.getDate() + parseInt(component, 10));
      } else if (component.endsWith('h')) {
        date.setHours(date.getHours() + parseInt(component, 10));
      } else if (component.endsWith('m')) {
        date.setMinutes(date.getMinutes() + parseInt(component, 10));
      }
    }
    return date;
  }

  function parseDateTime(timeStr) {
    const timeParts = timeStr.split(/\s+/);
    let days = 0;
    let hours = 0;
    let minutes = 0;
    for (let i = 0; i < timeParts.length; i++) {
      if (timeParts[i].endsWith('d')) {
        days = parseInt(timeParts[i], 10);
      } else if (timeParts[i].endsWith('h')) {
        hours = parseInt(timeParts[i], 10);
      } else if (timeParts[i].endsWith('m')) {
        minutes = parseInt(timeParts[i], 10);
      }
    }
    return { days, hours, minutes };
  }

  function createDate(dateString, timeString) {
    const dateParts = dateString.split('-');
    const timeParts = timeString.split(':');

    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    return new Date(year, month, day, hours, minutes);
  }

  function getDuration(date1, date2) {
    const diffMillis = Math.abs(Number(date2?.getTime()) - Number(date1?.getTime()));
    const durationMinutes = Math.floor(diffMillis / (1000 * 60));
    const durationHours = Math.floor(durationMinutes / 60);
    const durationDays = Math.floor(durationHours / 24);
    const remainingMinutes = durationMinutes % 60;
    const remainingHours = durationHours % 24;
    let durationString = '';
    if (durationDays > 0) {
      durationString += `${durationDays}d `;
    }
    if (remainingHours > 0) {
      durationString += `${remainingHours}h `;
    }
    if (remainingMinutes > 0 || durationString === '') {
      durationString += `${remainingMinutes}m`;
    }

    return durationString;
  }

  function calculateDurationDifference(time1, time2) {
    const date1 = parseTime(time1);
    const date2 = parseTime(time2);

    const totalSeconds = Math.abs((date1 - date2) / 1000);

    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor(totalSeconds / 3600) % 24;
    const minutes = Math.floor(totalSeconds / 60) % 60;

    return `${days}d ${hours}h ${minutes}m`;
  }

  const lastStop = useMemo(() => {
    if (stops.length) {
      const stop = stops[stops.length - 1];
      const { stop_point_type } = stop || {};
      const lastStopData = {};
      lastStopData.index = stops.length - 1;
      lastStopData.stopPointType = +stop_point_type;
      lastStopData.color = MAPPER_STOPS[+stop_point_type].color;
      lastStopData.background = MAPPER_STOPS[+stop_point_type].background;
      lastStopData.name = MAPPER_STOPS[+stop_point_type].name;
      lastStopData.allDataStop = stop;
      return lastStopData;
    }
  }, [stops]);

  const delayTime = useMemo(() => {
    if (infoHeader?.eta && activeStop?.allDataStop) {
      const { scheduled_date, from, to } = activeStop?.allDataStop || {};
      let timeZone;
      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      const passedEta = parseDateTime(infoHeader.eta);
      const currentToScheduledDiff = addTimeToDate(zoneTz, passedEta.days, passedEta.hours, passedEta.minutes);
      const scheduledCurrentDate = createDate(scheduled_date, from || to);
      return getDuration(currentToScheduledDiff, scheduledCurrentDate);
    }
  }, [infoHeader, activeStop]);

  const latestDepartureBy = useMemo(() => {
    if (elapsedDay && infoHeader) {
      let timeZone;
      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      const estimatedTimeRemaining = calculateDurationDifference(
        getAverageTimeConverted(elapsedDay),
        getAverageTimeConverted(activeStop?.allDataStop?.stop_point?.average_waiting_time)
      );
      const passedEta = parseDateTime(estimatedTimeRemaining);
      return addTimeToDate(zoneTz, passedEta.days, passedEta.hours, passedEta.minutes);
    }
  }, [infoHeader, activeStop]);

  const eventsJsx = useMemo(() => {
    const statusId = Number(infoHeader?.status_id);
    const {
      driver_confirm_date,
      shipment_option,
      driver_acknowledged,
      cellular_tracking_status,
      is_cellular_tracking_enable,
    } = infoHeader || {};

    const { customer_contact_book } = shipment_option || {};
    const { send_sms_on_dispatch } = mobileAppState || {};

    switch (statusId) {
      case 1:
      case 2:
        return (
          <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
            {!carrier && (
              <div className={classes.iconWrapper}>
                {!send_sms_on_dispatch ? <MinusIcon /> : <SuccessIcon />}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Shipment Text Message to Driver
                </Typography>
              </div>
            )}

            {!!carrier && (
              <div className={classes.iconWrapper}>
                <SuccessIcon />
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Shipment Confirmation Emailed to Carrier{' '}
                  {contact_user?.contact_email && (
                    <a href={`mailto:${contact_user?.contact_email}`} style={{ textDecoration: 'none' }}>
                      ({contact_user?.contact_email})
                    </a>
                  )}
                </Typography>
              </div>
            )}

            {!carrier && (
              <div className={classes.iconWrapper}>
                {driver_acknowledged ? <SuccessIcon /> : <div className={classes.widgetSpinner} />}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Shipment Push Notification to Driver App
                </Typography>
              </div>
            )}

            {!!carrier && (
              <div className={classes.iconWrapper}>
                {cellular_tracking_status === 'requested' ||
                (!!is_cellular_tracking_enable && !cellular_tracking_status) ? (
                  <div className={classes.widgetSpinner} />
                ) : cellular_tracking_status === 'started' ? (
                  <SuccessIcon />
                ) : (
                  <MinusIcon />
                )}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Cellular Tracking Request Sent to Driver{' '}
                  {driver_phone_number && (
                    <Typography variant='b2'>
                      (
                      {driver_phone_number?.startsWith('+')
                        ? formatPhoneNumber(driver_phone_number)
                        : formatPhoneNumber(`+${driver_phone_number}`)}
                      )
                    </Typography>
                  )}
                </Typography>
              </div>
            )}

            <div className={classes.iconWrapper}>
              {!customer_contact_book ? <MinusIcon /> : <SuccessIcon />}
              <Typography variant='b2' style={{ marginLeft: 8 }}>
                Shipment Tracking to Customer
              </Typography>
            </div>

            {!carrier && (
              <div className={classes.iconWrapper}>
                {driver_confirm_date ? <SuccessIcon /> : <div className={classes.widgetSpinner} />}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Driver Shipment Acceptance
                </Typography>
              </div>
            )}

            {!!carrier && (
              <div className={classes.iconWrapper}>
                {confirmation_signed ? <SuccessIcon /> : <div className={classes.widgetSpinner} />}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Carrier Signed Confirmation and Accepted Shipment
                </Typography>
              </div>
            )}

            <div className={classes.iconWrapper}>
              {carrier ? (
                confirmation_signed ? (
                  <SuccessIcon />
                ) : (
                  <div className={classes.widgetSpinner} />
                )
              ) : driver_confirm_date ? (
                <SuccessIcon />
              ) : (
                <div className={classes.widgetSpinner} />
              )}
              <Typography variant='b2' style={{ marginLeft: 8 }}>
                Successfully Dispatched
              </Typography>
            </div>
          </div>
        );
      case 3:
      case 4:
        return (
          <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
            {!!carrier && (
              <div className={classes.flexAlign}>
                {cellular_tracking_status === 'requested' ||
                (!!is_cellular_tracking_enable && !cellular_tracking_status) ? (
                  <div className={classes.widgetSpinner} />
                ) : cellular_tracking_status === 'started' ? (
                  <SuccessIcon />
                ) : (
                  <MinusIcon />
                )}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Cellular Tracking Request Sent to Driver{' '}
                  {driver_phone_number && (
                    <Typography variant='b2'>
                      (
                      {driver_phone_number?.startsWith('+')
                        ? formatPhoneNumber(driver_phone_number)
                        : formatPhoneNumber(`+${driver_phone_number}`)}
                      )
                    </Typography>
                  )}
                </Typography>
              </div>
            )}
            <div className={classes.flexAlign}>
              <SuccessIcon />
              <Typography variant='b2' style={{ margin: ' 0 0 0 8px' }}>
                Driver is on route to {activeStop?.name}
              </Typography>
            </div>

            {statusId === 4 && (
              <div style={{ marginLeft: 20 }} className={classes.flexAlign}>
                <WarningIcon fill='#C97A20' />
                <Typography variant='b2' style={{ margin: '0 0 0 8px' }}>
                  Driver is delayed by {delayTime}
                </Typography>
              </div>
            )}

            <div className={classes.flexAlign}>
              <div className={classes.widgetSpinnerWrapper}>
                <div className={classes.widgetSpinner} />
              </div>
              <Typography variant='b2' style={{ margin: ' 0 0 0 8px' }}>
                Driver has arrived to {activeStop?.name}
              </Typography>
            </div>

            <div className={classes.flexAlign}>
              <div className={classes.widgetSpinnerWrapper}>
                <div className={classes.widgetSpinner} />
              </div>
              <Typography variant='b2' style={{ margin: ' 0 0 0 8px' }}>
                Successfully Departed
              </Typography>
            </div>
          </div>
        );
      case 5:
        return (
          <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
            {!!carrier && (
              <div className={classes.flexAlign}>
                {cellular_tracking_status === 'requested' ||
                (!!is_cellular_tracking_enable && !cellular_tracking_status) ? (
                  <div className={classes.widgetSpinner} />
                ) : cellular_tracking_status === 'started' ? (
                  <SuccessIcon />
                ) : (
                  <MinusIcon />
                )}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Cellular Tracking Request Sent to Driver{' '}
                  {driver_phone_number && (
                    <Typography variant='b2'>
                      (
                      {driver_phone_number?.startsWith('+')
                        ? formatPhoneNumber(driver_phone_number)
                        : formatPhoneNumber(`+${driver_phone_number}`)}
                      )
                    </Typography>
                  )}
                </Typography>
              </div>
            )}
            <div className={classes.flexAlign}>
              <SuccessIcon />
              <Typography variant='b2' style={{ marginLeft: '8px' }}>
                Driver has arrived and checked in
              </Typography>
            </div>

            {activeStop?.allDataStop?.reported_dock_status !== null &&
              (activeStop?.allDataStop?.stop_point_type === '1' ||
                activeStop?.allDataStop?.stop_point_type === '2') && (
                <div className={classes.flexAlign}>
                  <SuccessIcon />
                  <Typography variant='b2' style={{ marginLeft: 8 }}>
                    Driver is{' '}
                    {activeStop?.allDataStop?.reported_dock_status === 2 ? 'at the shipment dock' : 'waiting for dock'}
                  </Typography>
                </div>
              )}

            {activeStop?.allDataStop?.stop_point_type === '1' && (
              <div className={classes.flexAlign}>
                {activeStop.allDataStop?.cargos?.every((i) => i.scanned) ? (
                  <SuccessIcon />
                ) : (
                  <div className={classes.widgetSpinnerWrapper}>
                    <div className={classes.widgetSpinner} />
                  </div>
                )}

                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Driver cargo scanned
                </Typography>
              </div>
            )}

            {activeStop?.allDataStop?.stop_point_type === '1' && (
              <div className={classes.flexAlign}>
                {activeStop.allDataStop?.reported_quantity ? (
                  <SuccessIcon />
                ) : (
                  <div className={classes.widgetSpinnerWrapper}>
                    <div className={classes.widgetSpinner} />
                  </div>
                )}

                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Driver received paperwork and is heading out
                </Typography>
              </div>
            )}

            {activeStop?.allDataStop?.stop_point_type === '2' && (
              <div className={classes.flexAlign}>
                {activeStop.allDataStop?.document_id ? (
                  <SuccessIcon />
                ) : (
                  <div className={classes.widgetSpinnerWrapper}>
                    <div className={classes.widgetSpinner} />
                  </div>
                )}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Driver scanned and sent paperwork
                </Typography>
              </div>
            )}

            {activeStop?.allDataStop?.stop_point_type === '1' && (
              <div className={classes.flexAlign}>
                {activeStop.allDataStop?.reported_quantity ? (
                  <SuccessIcon />
                ) : (
                  <div className={classes.widgetSpinnerWrapper}>
                    <div className={classes.widgetSpinner} />
                  </div>
                )}
                <Typography variant='b2' style={{ marginLeft: 8 }}>
                  Driver entered shipment data
                  {!!activeStop?.allDataStop?.reported_quantity && (
                    <span>
                      (Bill of Lading {activeStop.allDataStop?.bill_of_lading_id}, Pieces{' '}
                      {activeStop.allDataStop?.reported_quantity}, Weight {activeStop.allDataStop?.reported_weight}{' '}
                      {activeStop?.allDataStop?.reported_weight_type?.unit})
                    </span>
                  )}
                </Typography>
              </div>
            )}

            <div className={classes.flexAlign}>
              <div className={classes.widgetSpinnerWrapper}>
                <div className={classes.widgetSpinner} />
              </div>
              <Typography variant='b2' style={{ marginLeft: 8 }}>
                Successfully Departed
              </Typography>
            </div>
          </div>
        );
      default:
        return (
          <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
            <div className={classes.flexAlign}>
              <SuccessIcon />
              <Typography variant='b2' style={{ marginLeft: '8px' }}>
                Driver has arrived and checked in
              </Typography>
            </div>
            <div className={classes.flexAlign}>
              <div className={classes.widgetSpinnerWrapper}>
                <div className={classes.widgetSpinner} />
              </div>
              <Typography variant='b2' style={{ marginLeft: 8 }}>
                Successfully Departed
              </Typography>
            </div>
          </div>
        );
    }
  }, [infoHeader, activeStop]);

  const timeTextAndLineJsx = useMemo(() => {
    const statusId = Number(infoHeader?.status_id);
    const { eta, shipment_stops } = infoHeader || {};

    const defaultHtml = (
      <div className={classes.widgetHeader}>
        <Typography variant='h1'>{eta}</Typography>
        <Typography style={{ margin: '10px 0 0 8px' }} variant='overLine'>
          until arrival to
        </Typography>
        <div className={classes.headerGreenWrapper} style={{ background: activeStop?.background }}>
          <Typography variant='overLine' style={{ color: activeStop?.color }}>
            {activeStop?.name}
          </Typography>
        </div>
      </div>
    );

    if (statusId === 1 || statusId === 2 || statusId === 3 || statusId === 4) {
      return defaultHtml;
    }

    if (statusId === 5) {
      const cloneStops = [...shipment_stops];
      const reversStops = Array.isArray(cloneStops) ? cloneStops.reverse() : [];
      const foundedIndex = reversStops.findIndex((el) => !!el?.arrival_date);
      const foundedStopHaveArrival = cloneStops[foundedIndex];
      const index = cloneStops.length - 1 - foundedIndex;
      let timeZone;

      if (!foundedStopHaveArrival) {
        return defaultHtml;
      }

      try {
        timeZone = JSON.parse(JSON.parse(localStorage.getItem('user'))?.customer?.timezone);
      } catch (e) {
        // Do nothing
      }
      const zoneTz =
        !!timeZone?.zone_name &&
        moment(new Date()).tz(timeZone?.zone_name?.replace(/ - /g, '/')).format('YYYY-MM-DD HH:mm');
      const diff = !!zoneTz && moment(zoneTz).diff(foundedStopHaveArrival?.arrival_date, 'minute');

      return (
        <div className={classes.widgetHeader}>
          <Typography variant='h1'>{getAverageTimeConverted(diff)}</Typography>
          <Typography style={{ margin: '10px 0 0 8px' }} variant='overLine'>
            elapsed since arrival to
          </Typography>
          <div
            className={classes.headerGreenWrapper}
            style={{ background: MAPPER_STOPS[Number(foundedStopHaveArrival?.stop_point_type)].background }}
          >
            <Typography
              variant='overLine'
              style={{ color: MAPPER_STOPS[Number(foundedStopHaveArrival?.stop_point_type)].color }}
            >
              Stop {getAlphabet(index)}
            </Typography>
          </div>
        </div>
      );
    }
  }, [infoHeader, activeStop]);

  const style = useMemo(() => {
    return {
      mapWrapper: {
        width: '100%',
        height: '100%',
        borderRadius: '10px',
      },
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        borderRadius: '8px 0 0 8px',
      },
    };
  }, []);

  function onChangePlusModal(el) {
    const map = {
      1: { ...el, color: palette.green500 },
      2: { ...el, color: palette.red500 },
      3: { ...el, color: palette.blueText },
    };

    const allStops = legs.reduce((acc, cur) => [...acc, ...(cur.stops || [])], []);

    setSelectModalType({ ...map[el.id], index: allStops.length, legIndex: legs.length + 1, order_in_leg: 1 });
    setIsOpen({ type: 'add', open: true });
  }

  useEffect(() => {
    if (isRecurring) {
      setLegs(orderingInLegsStops(infoHeader.shipment_stops));
    } else {
      getStopsInitialsData();
    }
  }, [id, infoHeader]);

  const { stop_point, scheduled_date, from, to, scheduled_type } = activeStop?.allDataStop || {};
  const { state, zipcode, city, address1, location_name } = stop_point || {};

  return (
    <div className={classes.chiefContainer}>
      <div style={{ width: '100%', marginBottom: 10 }}>
        {loading ? (
          <SkeletonStops />
        ) : (
          <>
            {legs.map((leg, index) => {
              legsStopsCount.push(leg.stops.length);
              return (
                <div key={leg.id} className={classes.legCountContainer}>
                  <div className={classes.countLegWrapper}>
                    <Typography variant='s2' style={{ color: palette.indigo600 }}>
                      {leg?.number}
                    </Typography>
                  </div>
                  <OverViewStop
                    leg={leg}
                    legs={legs}
                    setIsOpenModal={setIsOpenModal}
                    setLegs={setLegs}
                    indexPrefix={index}
                    setIsOpen={setIsOpen}
                    infoHeader={infoHeader}
                    legsStopsCount={legsStopsCount}
                    onSelectPlus={setSelectModalType}
                    isShipmentTable={isShipmentTable}
                    deleteStop={onDeleteStop}
                    onClickSettingsIcon={onClickItemSettingsIcon}
                    isRecurring={isRecurring}
                    onCheckInOutClick={onCheckInOutClick}
                    onDryRunClick={onDryRunClick}
                    onUpdateSuccess={onUpdateSuccess}
                  />
                </div>
              );
            })}
          </>
        )}
        {status?.id !== 6 && status?.id !== 7 && infoHeader?.shipment_type !== 'split-parent' && (
          <div className='d-flex'>
            <div className={classes.countPlusWrapper} onClick={() => setIsAddLegVisible(!isAddLegVisible)}>
              <Typography variant='s2' style={{ color: palette.indigo600 }}>
                {!isAddLegVisible ? <PLusIcon /> : <MinusIcon />}
              </Typography>
            </div>
            {isAddLegVisible && (
              <div className={classes.addLeg} style={{ backgroundColor: use(palette.gray0, palette.dark700) }}>
                <OverLayStopsPlus index={1} addInleg onChange={(el) => onChangePlusModal(el)} />
              </div>
            )}
          </div>
        )}
      </div>

      <div style={style.mapWrapper}>
        {!!Object.keys(infoHeader).length && mapDriverArr.length && (
          <Map
            key={key}
            styleContainer={style.map}
            literalJsx
            popoverClass={classes.popoverDispatchPage}
            driversArr={mapDriverArr}
            stopPointArr={route}
            infoHeader={infoHeader}
            isShipmentTable={!!isShipmentTable}
          >
            {!!isShipmentTable && !!activeStop.allDataStop && status?.id !== 6 && (
              <div
                className={classes.mapWidget}
                style={{ background: use('rgba(255, 255, 255, 0.88)', 'rgba(44,44,44,0.88)') }}
              >
                {timeTextAndLineJsx}
                <div className={classes.progressWidget}>
                  {stops.map((el) => {
                    return (
                      <div key={el.id} className={classes.progressItem}>
                        <div
                          style={{
                            height: '100%',
                            backgroundColor: status.id === 4 ? palette.red500 : palette.green400,
                            width: `${el.arrival_date ? '100%' : '0%'}`,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={classes.widgetBody}>
                  <div className={classes.flexColumn}>
                    {status?.id !== 5 ? (
                      <>
                        <Typography variant='s2'>
                          {infoHeader?.miles_remaining && Number(infoHeader.miles_remaining).toFixed()}
                          <span> </span>
                          Miles Remaining Until Arrival for {activeStop?.name}
                        </Typography>
                        <Typography variant='b2'>
                          Latest arrival by {formatDateTime(infoHeader?.estimated_date_time_arrival)}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant='s2'>
                          Driver has arrived and checked in Stop {getAlphabet(activeStop?.index)}
                        </Typography>
                        <Typography variant='b2'>
                          {calculateDurationDifference(
                            getAverageTimeConverted(elapsedDay),
                            getAverageTimeConverted(activeStop?.allDataStop?.stop_point?.average_waiting_time)
                          )}{' '}
                          estimated time remaining until departure
                        </Typography>
                        <Typography variant='b2'>
                          Latest departure by&nbsp;
                          {formatDateTime(latestDepartureBy)}
                        </Typography>
                      </>
                    )}
                  </div>

                  {eventsJsx}

                  <div className={classes.line} />
                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Equipment Action</Typography>
                    </div>

                    <Typography variant='b2'>
                      {equipmentTypeText({
                        type: activeStop?.allDataStop?.stop_point_type,
                        action: activeStop?.allDataStop?.equipment_action,
                        equipment: activeStop?.allDataStop?.equipment,
                        equipment_tbd: activeStop?.allDataStop?.equipment_tbd,
                      })}
                    </Typography>
                  </div>

                  <div className={classes.line} />
                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Stop Address</Typography>
                      <div className={classes.headerGreenWrapper} style={{ background: activeStop.background }}>
                        <Typography variant='overLine' style={{ color: activeStop.color }}>
                          Stop {getAlphabet(activeStop.index)}
                        </Typography>
                      </div>
                    </div>

                    <Typography variant='b2'>{location_name}</Typography>
                    <Typography variant='b2'>{address1 && `${address1},`}</Typography>
                    <Typography variant='b2'>
                      {city?.name && city.name}
                      {state?.short_name && `, ${state.short_name}`} {zipcode && ` ${zipcode}`}
                    </Typography>
                  </div>

                  <div className={classes.line} />

                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Current Location</Typography>
                    </div>

                    <Typography variant='b2'>
                      {infoHeader?.equipment?.location_target === 'Driver'
                        ? infoHeader?.equipment?.drivers?.[0]?.driver_gps?.location
                        : infoHeader?.equipment?.location_target === 'carrier'
                        ? infoHeader?.carrier_gps_latest?.location
                        : infoHeader?.equipment?.equipment_gps?.location}
                    </Typography>
                  </div>

                  <div className={classes.line} />
                  <div style={{ marginTop: 16 }}>
                    <Typography variant='s2'>Scheduled</Typography>
                  </div>

                  <div className={classes.flexColumn}>
                    <Typography variant='b2'>
                      {scheduled_date && `${formatDate(scheduled_date)},`}&nbsp;
                      {from} {[1, 3].includes(+scheduled_type) ? ` to ${to}` : ` to ${from}`}
                    </Typography>
                  </div>
                </div>
              </div>
            )}

            {(!activeStop.allDataStop || status?.id === 6) && (
              <div
                className={classes.mapWidget}
                style={{ background: use('rgba(255, 255, 255, 0.88)', 'rgba(44,44,44,0.88)') }}
              >
                {/* // header */}
                {timeTextAndLineJsx}

                <div className={classes.progressWidget}>
                  {stops.map((el) => {
                    return (
                      <div key={el.id} className={classes.progressItem}>
                        <div
                          style={{
                            height: '100%',
                            backgroundColor: palette.green400,
                            width: `${el.arrival_date ? '100%' : '0%'}`,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={classes.widgetBody}>
                  <div className={classes.flexColumn}>
                    <Typography variant='s2'>
                      {infoHeader?.loaded_miles?.toFixed()} Loaded Miles , {infoHeader?.empty_miles?.toFixed()} Empty
                      Miles
                    </Typography>
                    <Typography variant='b2'>
                      Arrived Destination At {formatDateTime(lastStop?.allDataStop?.arrival_date)}
                    </Typography>
                    <Typography variant='b2'>
                      Departed Destination At {formatDateTime(lastStop?.allDataStop?.departure_date)}
                    </Typography>
                  </div>

                  <div className={classNames(classes.flexColumn, classes.widgetStatuses)}>
                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Arrived At Destination
                      </Typography>
                    </div>

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver is waiting for Shipment Dock
                      </Typography>
                    </div>

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver is at Shipment Dock
                      </Typography>
                    </div>

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver uploaded paperwork
                      </Typography>
                    </div>
                    {lastStop?.allDataStop?.proof_of_delivery && (
                      <div style={{ marginLeft: 20 }} className={classes.flexAlign}>
                        <SubRight />
                        <Typography variant='b2' style={{ margin: '0 0 0 8px' }}>
                          Driver scanned and sent proof of delivery by {lastStop?.allDataStop?.proof_of_delivery}
                        </Typography>
                      </div>
                    )}

                    <div className={classes.iconWrapper}>
                      <SuccessIcon />
                      <Typography variant='b2' style={{ marginLeft: 8 }}>
                        Driver Completed Shipment
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.line} />
                  <div style={{ margin: '16px 0' }} className={classes.flexColumn}>
                    <div className={classes.flexAlign}>
                      <Typography variant='s2'>Stop Address</Typography>
                      <div className={classes.headerGreenWrapper} style={{ background: lastStop?.background }}>
                        <Typography variant='overLine' style={{ color: lastStop?.color }}>
                          Stop {getAlphabet(lastStop?.index)}
                        </Typography>
                      </div>
                    </div>

                    <Typography variant='b2'>{lastStop?.allDataStop?.stop_point?.location_name}</Typography>
                    <Typography variant='b2'>
                      {lastStop?.allDataStop?.stop_point?.address1 && `${lastStop?.allDataStop?.stop_point?.address1},`}
                    </Typography>
                    <Typography variant='b2'>
                      {lastStop?.allDataStop?.city?.name && lastStop?.allDataStop?.stop_point?.city.name}
                      {lastStop?.allDataStop?.state?.stop_point?.short_name &&
                        `, ${lastStop?.allDataStop?.stop_point?.state.short_name}`}{' '}
                      {lastStop?.allDataStop?.stop_point?.zipcode && `${lastStop?.allDataStop?.stop_point?.zipcode}`}
                    </Typography>
                  </div>

                  <div className={classes.line} />

                  <div style={{ marginTop: 16 }}>
                    <Typography variant='s2'>Scheduled</Typography>
                  </div>

                  <div className={classes.flexColumn}>
                    <Typography variant='b2'>
                      {lastStop?.allDataStop?.scheduled_date && `${formatDate(lastStop?.allDataStop?.scheduled_date)},`}
                      &nbsp;
                      {lastStop?.allDataStop?.from}{' '}
                      {![1, 3].includes(Number(lastStop?.allDataStop?.scheduled_type))
                        ? `to ${lastStop?.allDataStop?.from}`
                        : `to ${lastStop?.allDataStop?.to}`}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </Map>
        )}
      </div>
      {!!isOpen?.open && (
        <AddEditModalDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={selectModalType}
          setType={setSelectModalType}
          onAdd={onAddEditModalDetails}
          isShipmentTable={isShipmentTable}
          isActiveStopPoint={!!activeStop?.allDataStop?.id && activeStop?.allDataStop?.id === selectModalType?.stop?.id}
          onUpdateSuccess={onUpdateSuccess}
          isRecurring={isRecurring}
          shipmentId={id}
          loading={loading}
          legs={legs}
        />
      )}
      <SettingsModal isOpen={isOpenSettingsModal} setIsOpen={setIsOpenSettingsModal} onUpdate={onUpdateSettings} />
    </div>
  );
};

export default OverView;

export const SkeletonStops = () => {
  return (
    <div className={classes.skeletonContainer}>
      <div className={classes.skeletonWrapper}>
        <Skeleton style={{ width: '40%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '60%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
      </div>
      <div className={classes.skeletonWrapper}>
        <Skeleton style={{ width: '40%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '60%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
      </div>
      <div className={classes.skeletonWrapper}>
        <Skeleton style={{ width: '40%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '60%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '80%' }} className='mt-3 me-5 ' />
        <Skeleton style={{ width: '90%' }} className='mt-3 me-5 ' />
      </div>
    </div>
  );
};
