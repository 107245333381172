import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import TableWrapper from 'components/TableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getSettlements } from 'Api/Payroll';
import Header from '../../components/PaystubModal/Header';
import { useSettlementsColumns } from '../Tables.data';
import useShowToaster from '../../../../../hooks/useShowToaster';

const initialFilters = {
  page: 1,
  page_size: 100,
};

const PaidSettlements = ({ search, dateRange }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const location = useLocation();
  const { openSettlement } = location?.state || {};
  const [settlements, setSettlements] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({ field: 'paid_at', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedSettlement, setSelectedSettlement] = useState(null);

  const getSettlementsList = async () => {
    setLoading(true);
    try {
      const { page, page_size } = selectedFilters || {};
      const params = {
        page,
        page_size,
        query: search || undefined,
        status_id: 5,
        from: dateRange?.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        to: dateRange?.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        [`sort[${sort.field}]`]: sort.sortBy,
      };
      const response = await getSettlements(params);
      setSettlements(response);
    } catch (e) {
      // Do nothing
    }
    setLoading(false);
  };

  const onRowClick = async (e, row) => {
    if (row.pdf_file_link) {
      setSelectedSettlement(row);
      setPdfUrl(row.pdf_file_link);
    }
  };

  const onClickRowItem = ({ filed, row }) => {
    if (filed === 'reference') {
      if (row?.pdf_check_file_link) {
        setPdfUrl(row?.pdf_check_file_link);
        setSelectedSettlement(row);
      } else {
        showToaster({ type: 'warning', message: "Check doesn't exists" });
      }
    }
  };

  const onModalClose = () => {
    setPdfUrl(null);
    setSelectedSettlement(null);
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getSettlementsList();
  }, [selectedFilters, sort, search, dateRange]);

  useEffect(() => {
    if (openSettlement && settlements?.data?.length) {
      const row = settlements.data.find((item) => item.id === Number(openSettlement));
      onRowClick(null, row);
      navigate('.', { state: {} });
    }
  }, [settlements]);

  const columns = useSettlementsColumns({ sort, sortingQuery, isPaid: true, onClickRowItem });

  return (
    <div>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <div className='tableFixHead table-fixed-header-300'>
          <TableWrapper
            data={settlements?.data}
            rowPerPage={selectedFilters.page_size}
            onRowClick={onRowClick}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: () =>
                Pagination({
                  data: settlements,
                  rowPerPage: selectedFilters.page_size,
                  onChangeRowPerPage,
                  onPageChange,
                }),
            }}
            columns={columns}
          />
        </div>
      )}
      {!!pdfUrl && !!selectedSettlement && (
        <ViewPdf open title={<Header settlement={selectedSettlement} />} pdfUrl={pdfUrl} onClose={onModalClose} />
      )}
    </div>
  );
};

export default PaidSettlements;
