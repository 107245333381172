import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icons } from 'assets/icons';
import Select from 'react-select';
import { palette } from 'utils/constants';
import moment from 'moment';
import './Datepicker.css';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { useFormik } from 'formik';
import { OwnerOperator, uploadEquipmentDocument } from 'Api/Equipment';
import DatePicker from 'react-datepicker';
import { InputWithIcon } from 'common/Input';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getVendorsList } from 'Api/Vendors';
import VendorAutocomplete from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/VendorAutocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { validationSchema } from './validationSchema';
import style from './Ownership.module.css';

const ownerType = {
  1: 'companyOwned',
  2: 'LeasingOwned',
  3: 'OwnerOperator',
  4: '3rdParty',
};

const Ownership = ({ nextStep, Cancel, prevStep, plateState, ownership, vehicleType }) => {
  const {
    license_plate_number,
    state,
    license_plate_expire_date,
    upload_registration,
    owner_operator,
    leasing_company_name,
    lease_or_credit_payment,
    purchase_cost,
    owner_operator_type,
    isCheckboxChecked,
    fileName: initialFileName,
  } = ownership;

  const { currency } = useSelector((state) => state.root);
  const [vendors, setVendors] = useState([]);

  const [equipmentOwner, setEquipmentOnwer] = useState(ownerType?.[ownership?.owner_operator_type] || 'companyOwned');
  const [ownerOperator, setOwnerOperator] = useState([]);
  const [fileName, setFileName] = useState(initialFileName || undefined);
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: palette.white,
      color: palette.dark800,
      height: 32,
      minHeight: 32,
      minWidth: 50,
      border: 'none',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      boxShadow: palette.boxShadow3,
      borderRadius: 0,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: palette.white,
      color: palette.dark800,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: palette.white,
      color: palette.dark800,
    }),
  };
  const customStyles2 = {
    control: (base) => ({
      ...base,
      backgroundColor: palette.white,
      color: palette.dark800,
      height: 32,
      minHeight: 32,
      minWidth: 50,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: palette.white,
      color: palette.dark800,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: palette.white,
      color: palette.dark800,
    }),
  };

  const OwnerOperatorID = async () => {
    OwnerOperator().then((res) => {
      const OwnerOp = res.data.map((v) => {
        return {
          label: v.owner_operator_name,
          value: v.id,
        };
      });
      setOwnerOperator(OwnerOp);
    });
  };

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    OwnerOperatorID();
    getVendors();
  }, []);
  const user = JSON.parse(localStorage.getItem('user'));

  const formik = useFormik({
    initialValues: {
      license_plate_number: license_plate_number || '',
      state: state || '',
      license_plate_expire_date: license_plate_expire_date ? moment(license_plate_expire_date).toDate() : null,
      no_expiration: !!isCheckboxChecked,
      upload_registration: upload_registration || '',
      owner_operator: owner_operator || null,
      leasing_company_name: leasing_company_name || '',
      lease_or_credit_payment: lease_or_credit_payment || '',
      purchase_cost: purchase_cost || '',
      owner_operator_type: owner_operator_type || 1,
    },
    validationSchema,

    onSubmit: async (values) => {
      let ownedBy;
      if (equipmentOwner === 'companyOwned') {
        ownedBy = user?.customer?.company_name;
      } else if (equipmentOwner === 'LeasingOwned') {
        ownedBy = values.leasing_company_name;
      } else if (equipmentOwner === 'OwnerOperator') {
        ownedBy = values.owner_operator.label;
      }

      values = {
        ...values,
        ownedBy,
        isCheckboxChecked: values.no_expiration,
        license_plate_expire_date: !values.no_expiration
          ? moment(values.license_plate_expire_date).format('YYYY-MM-DD')
          : null,
        fileName,
      };
      nextStep({ values });
    },
  });

  useEffect(() => {
    if (equipmentOwner === 'companyOwned') {
      formik.setFieldValue('owner_operator_type', 1);
    } else if (equipmentOwner === 'LeasingOwned') {
      formik.setFieldValue('owner_operator_type', 2);
    } else if (equipmentOwner === '3rdParty') {
      formik.setFieldValue('owner_operator_type', 4);
    } else {
      formik.setFieldValue('owner_operator_type', 3);
    }
  }, [equipmentOwner]);

  const onDocumentUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('equipment_document_type_id', 9);
      formData.append('document', file);

      const { data } = await uploadEquipmentDocument(formData);
      formik.setFieldValue(`upload_registration`, data.document_path);
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={style['equipment-ownership-modal-body']}>
        <div className='mt-4'>
          <div>
            <p className={style['equipment-type-text']}>Owner of Equipment</p>
            {/* <button onClick={handleAddOwnerOperatorOpen}>Open Modal</button> */}
          </div>
          <div id='my-radio-group'>
            <div className='d-flex' role='group' aria-labelledby='my-radio-group'>
              <div>
                <p>
                  <input
                    type='radio'
                    name='equipmentOwner'
                    style={style.equipmentType}
                    value='vehicle'
                    onClick={() => setEquipmentOnwer('companyOwned')}
                    checked={equipmentOwner === 'companyOwned'}
                  />
                  <span className={style['equipment-headings']}>Company Owned</span>
                </p>
              </div>
              <div className='ms-4'>
                <p>
                  <input
                    type='radio'
                    name='equipmentOwner'
                    value='vehicle'
                    onClick={() => setEquipmentOnwer('LeasingOwned')}
                    checked={equipmentOwner === 'LeasingOwned'}
                  />
                  <span className={style['equipment-headings']}>Leasing Owned</span>
                </p>
              </div>
              <div className='ms-4'>
                <p>
                  <input
                    type='radio'
                    name='equipmentOwner'
                    value='trailer'
                    onClick={() => setEquipmentOnwer('OwnerOperator')}
                    checked={equipmentOwner === 'OwnerOperator'}
                  />
                  <span className={style['equipment-headings']}>Owner Operator</span>
                </p>
              </div>
              {vehicleType && (
                <div className='ms-4'>
                  <p>
                    <input
                      type='radio'
                      name='equipmentOwner'
                      value='trailer'
                      onClick={() => setEquipmentOnwer('3rdParty')}
                      checked={equipmentOwner === '3rdParty'}
                    />
                    <span className={style['equipment-headings']}>3rd Party</span>
                  </p>
                </div>
              )}
            </div>
            {equipmentOwner === 'LeasingOwned' ? (
              <div className='d-flex'>
                <div>
                  <p className={style['equipment-info-text']}>Leasing Company Name*</p>
                  <VendorAutocomplete
                    size='medium'
                    width='250px'
                    value={vendors.find((i) => i.name === formik.values.leasing_company_name) || null}
                    options={vendors || []}
                    onChange={(e, val) => formik.setFieldValue('leasing_company_name', val?.name)}
                    onAddNewSuccess={(newVendor) => {
                      getVendors();
                      formik.setFieldValue('leasing_company_name', newVendor?.name || '');
                    }}
                    error={formik.touched.leasing_company_name && formik.errors.leasing_company_name}
                  />
                </div>
                <div className='ms-3'>
                  <p className={style['equipment-info-text']}>Lease or Credit Payment*</p>
                  <div className={style['equipment-leasing-payment']}>
                    <span className='ms-2 mt-1 me-2'>{currency}</span>
                    <input
                      type='text'
                      className={style['leasing-payment-selector']}
                      placeholder='0.00'
                      name='lease_or_credit_payment'
                      value={formik.values.lease_or_credit_payment}
                      onChange={formik.handleChange}
                    />
                    <span className='ms-3'>/month</span>
                  </div>
                </div>
              </div>
            ) : equipmentOwner === 'OwnerOperator' ? (
              <div className='d-flex mt-3'>
                <div>
                  <div className='d-flex'>
                    <p className={style['equipment-info-text']}>Owner Operator*</p>
                    <span className='ms-4 mt-2'>
                      <img src={Icons.Refresh} alt='' />
                    </span>
                  </div>
                  <div className={style['select-owner-operator']}>
                    <Select
                      options={ownerOperator}
                      type='checkbox'
                      name='arr'
                      styles={customStyles2}
                      value={formik.values.owner_operator}
                      onChange={(e) => formik.setFieldValue('owner_operator', e)}
                    />
                    <ErrorMessage error={formik.touched.owner_operator && formik.errors.owner_operator} />
                  </div>
                </div>
                <div className='ms-3' />
              </div>
            ) : (
              ''
            )}
            {equipmentOwner === 'companyOwned' && (
              <InputWithIcon
                width='200px'
                type='number'
                className='mt-2'
                name='purchase_cost'
                label='Purchase Cost'
                value={formik.values.purchase_cost}
                onChange={formik.handleChange}
                onKeyDown={blockNonPositiveNumbers}
              />
            )}
            <div className='mt-3'>
              <p className={style['equipment-type-text']} />
            </div>
            <div className={style['company-owned-equipment']} style={{ display: 'flex' }}>
              <div>
                <label className={style['equipment-type-text']}>License Plate Number</label>
                <input
                  type='text'
                  className={style['company-owned-input']}
                  name='license_plate_number'
                  onBlur={formik.handleBlur}
                  value={formik.values.license_plate_number}
                  onChange={formik.handleChange}
                />
                <span>
                  {formik.errors.license_plate_number && formik.touched.license_plate_number ? (
                    <div
                      className={style['add-equipment-validation']}
                      style={{
                        color: '#D12953',
                        fontSize: '12px',
                      }}
                    >
                      {formik.errors.license_plate_number}
                    </div>
                  ) : null}
                </span>
              </div>
              <div className={style['company-owned-select']}>
                <div>
                  <label className={style['equipment-type-text']}>State</label>
                  {plateState.length ? (
                    <Select
                      options={plateState}
                      type='checkbox'
                      name='state'
                      defaultValue={
                        plateState?.filter((v) => (v?.value === state ? { label: v.label, value: v.value } : ''))[0]
                      }
                      onBlur={formik.handleBlur}
                      styles={customStyles}
                      onChange={(e) => formik.setFieldValue('state', e?.value)}
                    />
                  ) : (
                    'Loading'
                  )}
                  <span>
                    {formik.errors.state && formik.touched.state ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                        }}
                      >
                        {formik.errors.state}
                      </div>
                    ) : null}
                  </span>
                </div>
              </div>
              <div>
                <label className={style['equipment-type-text']}>Registration Expiry Date</label>
                <div
                  className={
                    style[formik.values.no_expiration ? 'add-record-DatePicker-disable' : 'add-record-DatePicker']
                  }
                  style={{ borderRadius: '0px 6px 6px 0px' }}
                >
                  <DatePicker
                    placeholderText='mm/dd/yyyy'
                    className='text-style ms-0'
                    highlightDates={new Date()}
                    showYearDropdown
                    selected={formik.values.license_plate_expire_date}
                    style={{ backgroundColor: palette.white }}
                    id='license_plate_expire_date'
                    name='license_plate_expire_date'
                    onBlur={formik.handleBlur}
                    value={formik.values.no_expiration ? '' : formik.values.license_plate_expire_date}
                    onChange={(date) => {
                      formik.setFieldValue('license_plate_expire_date', date);
                    }}
                    disabled={formik.values.no_expiration}
                  />
                </div>
                <div className='d-flex align-items-center gap-3' style={{ marginTop: '4px' }}>
                  {vehicleType && (
                    <CustomCheckbox
                      name='no_expiration'
                      checked={formik?.values?.no_expiration}
                      onChange={(checked) => {
                        formik.setValues((prevState) => ({
                          ...prevState,
                          no_expiration: checked,
                          license_plate_expire_date: checked ? null : prevState.license_plate_expire_date,
                        }));
                      }}
                    >
                      <Typography variant='s2' style={{ color: palette.gray700 }} className='ms-2'>
                        No Expiration
                      </Typography>
                    </CustomCheckbox>
                  )}
                  <span>
                    {formik.errors.license_plate_expire_date && formik.touched.license_plate_expire_date ? (
                      <div
                        className={style['add-equipment-validation']}
                        style={{
                          color: '#D12953',
                          fontSize: '12px',
                        }}
                      >
                        {formik.errors.license_plate_expire_date}
                      </div>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
            <div className='ms-2 mt-4' style={{ width: '300px', display: 'flex' }}>
              <label>
                <div>
                  <p type='text'>
                    <input
                      id='upload_registration'
                      name='upload_registration'
                      onChange={(e) => {
                        setFileName(e.currentTarget.files[0].name);
                        onDocumentUpload(e.currentTarget.files[0]);
                      }}
                      type='file'
                      accept='application/pdf'
                      value=''
                      style={{ display: 'none' }}
                    />
                    {fileName || upload_registration ? (
                      <p style={{ color: '#4F5AED' }} className={style['equipment-info-text']}>
                        <img className='me-1' src={Icons.attachfile} alt='' />
                        {fileName || (upload_registration?.name ? upload_registration?.name : '')}
                      </p>
                    ) : (
                      <p>
                        <img className='me-2' src={Icons.Add} alt='' />
                        <span style={{ color: '#4F5AED' }} className={style['equipment-info-text']}>
                          Attach Registration
                        </span>
                        <span className='d-flex'>
                          {formik.errors.upload_registration && formik.touched.upload_registration ? (
                            <div
                              className={style['add-equipment-validation']}
                              style={{
                                color: '#D12953',
                                fontSize: '12px',
                              }}
                            >
                              {formik.errors.upload_registration}
                            </div>
                          ) : null}
                        </span>
                      </p>
                    )}
                  </p>
                </div>
              </label>
              {fileName && (
                <span
                  style={{ margin: '14px 0 0 8px', cursor: 'pointer' }}
                  onClick={() => {
                    setFileName('');
                    formik.setFieldValue('upload_registration', '');
                  }}
                >
                  <DeleteIcon width={11} height={11} />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px 20px',
        }}
      >
        <button className={style['type-cancel-button']} onClick={Cancel}>
          Cancel
        </button>
        <div className='d-flex'>
          <button className={style['type-cancel-button']} onClick={prevStep}>
            Back
          </button>
          <button
            className={style['add-equipment-next-step']}
            type='submit'
            style={{ color: '#fff', backgroundColor: '#4F5AED' }}
          >
            Next Step
          </button>
        </div>
      </div>
    </form>
  );
};

export default Ownership;
