import React, { useEffect, useMemo, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import { Box } from '@material-ui/core';
import { Modal } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as TickIcon } from 'assets/icons/check-filled.svg';
import { MicrencBinary } from 'assets/fonts/micrenc';
import Radio from 'common/Radio';
import useForm from 'hooks/useForm';
import { createChecks } from 'Api/Checks';
import SpinnerLoadingMultipleTexts from 'components/SpinnerLoadingMultipleTexts/SpinerLoadingTwoTexts';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import styles from './ChecksToPrintTemplate/ChecksToPrintTemplate.module.scss';
import ChecksToPrintTemplate from './ChecksToPrintTemplate/ChecksToPrintTemplate';
import { SEND_PAYMENT_KEYS } from '../../constants';
import classes from './PrintChecksModal.module.scss';

const PrintChecksModal = ({
  bank,
  checks,
  onClose,
  company,
  billType,
  printChecks,
  defaultSettings,
  onFinish,
  activeSelectName,
}) => {
  const signPad = useRef({});
  const [pageIndex, setPageIndex] = useState(0);
  const [successPageOpen, setSuccessPageOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [printOpenedAndClosed, setPrintOpenedAndClosed] = useState(false);
  const [pdfBlobs, setPdfBlobs] = useState([]);
  const formik = useForm({
    initialValues: {
      check_style: 1,
      is_company_logo_shown: true,
      is_bank_logo_show: true,
      is_signature_shown: false,
      signature: null,
    },
    onSubmit,
  });

  const getBlobPdf = async (checkElement) => {
    return new Promise((resolve, reject) => {
      const doc = new jsPDF('p', 'px', 'letter');
      const html = document.createElement('div');
      html.className = styles.forJSPDF;
      html.innerHTML = checkElement;
      doc?.addFileToVFS('micrenc.ttf', MicrencBinary);

      if (doc) {
        doc?.html(html.outerHTML, {
          html2canvas: {
            async: true,
            proxy: null,
            logging: true,
            useCORS: false,
            allowTaint: true,
            imageTimeout: 15000,
            removeContainer: true,
            javascriptEnabled: true,
            foreignObjectRendering: false,
          },
          autoPaging: true,
          callback: (document) => {
            const blob = document.output('blob', { filename: 'Checks' });
            resolve(blob);
          },
        });
      } else {
        reject();
      }
    });
  };

  const getCheck = (check) => {
    return printChecks.find((el) => {
      return String(el?.id) === String(check?.detail?.id);
    });
  };

  const handleProcessComplete = async () => {
    setLoading(true);
    try {
      const keys = [
        SEND_PAYMENT_KEYS.VENDORS,
        SEND_PAYMENT_KEYS.DRIVERS,
        SEND_PAYMENT_KEYS.STAFF,
        SEND_PAYMENT_KEYS.OWNER_OPERATORS,
      ];
      const formData = new FormData();
      formData.append('account_id', bank?.id);
      checks.forEach((check, index) => {
        formData.append(`checks[${index}][bill_id]`, check?.detail?.id);
        formData.append(`checks[${index}][bill_type]`, billType);
        formData.append(`checks[${index}][memo]`, getCheck(check)?.memoValue || '');
        formData.append(`checks[${index}][check_number]`, getCheck(check)?.checkNumber || '');
        pdfBlobs?.[index] && formData.append(`checks[${index}][pdf_check_file_link]`, pdfBlobs[index]);
        if (keys.includes(activeSelectName)) {
          formData.append(`checks[${index}][amount]`, getCheck(check)?.amount || '');
        }
      });

      const data = await createChecks(formData);
      setLoading(false);
      setSuccessPageOpen(!!data.success);
    } catch (e) {
      setLoading(false);
    }
  };

  const renderCheckForPdfBlob = async ({ memoValue, check, checkNumber, amount }) => {
    return new Promise((resolve, reject) => {
      const checkElementBlob = document.createElement('div');
      checkElementBlob.style.paddingTop = '32px';
      ReactDOM.render(
        <ChecksToPrintTemplate
          bank={bank}
          amount={amount}
          company={company}
          memoValue={memoValue}
          check={check?.detail}
          checkNumber={checkNumber}
          signatureUrl={formik.values.signature}
          useBankLogo={formik.values.is_bank_logo_show}
          useSignature={formik.values.is_signature_shown}
          useCompanyLogo={formik.values.is_company_logo_shown}
          checkStyleVoucher={1}
        />,
        checkElementBlob,
        () => {
          getBlobPdf(checkElementBlob.innerHTML)
            .then((...rest) => {
              resolve(...rest);
            })
            .catch((e) => {
              reject(e);
            });
        }
      );
    });
  };

  const content = () => {
    const renderedElementsArr = [];
    const renderedComponents = document.createElement('div');

    for (let i = 0; i < checks?.length; i++) {
      const check = checks[i];
      const { checkNumber, memoValue, amount } = getCheck(check) || {};
      const checkElement = document.createElement('div');

      if (formik.values.check_style === 2) {
        checkElement.style.height = 'calc(33.3% - 16px)';
      }

      ReactDOM.render(
        <ChecksToPrintTemplate
          bank={bank}
          amount={amount}
          company={company}
          memoValue={memoValue}
          check={check?.detail}
          checkNumber={checkNumber}
          useBankLogo={formik.values.is_bank_logo_show}
          useSignature={formik.values.is_signature_shown}
          useCompanyLogo={formik.values.is_company_logo_shown}
          checkStyleVoucher={+formik.values.check_style === 1}
          signatureUrl={formik.values.signature}
        />,
        checkElement,
        () => {
          +formik.values.check_style === 2
            ? renderedElementsArr.push(checkElement)
            : renderedComponents.appendChild(checkElement);
        }
      );
    }

    if (+formik.values.check_style === 2) {
      for (let i = 0; i < renderedElementsArr.length; i += 3) {
        const pageStandard = document.createElement('div');
        const elements = renderedElementsArr.slice(i, i + 3);
        elements.forEach((div) => pageStandard.appendChild(div));

        pageStandard.style.gap = '16px';
        pageStandard.style.display = 'flex';
        pageStandard.style.paddingTop = '24px';
        pageStandard.style.flexDirection = 'column';
        pageStandard.style.height = '100vh';
        // pageStandard.style.justifyContent = 'space-between';

        renderedComponents.appendChild(pageStandard);
      }
    }
    return renderedComponents;
  };

  const handlePrint = useReactToPrint({
    content,
    removeAfterPrint: true,
    onAfterPrint: () => setPrintOpenedAndClosed(true),
  });

  function onSubmit() {}

  const printHandler = async () => {
    setPdfBlobs([]);
    setLoading(true);
    try {
      await (async function () {
        const blobs = [];
        // For PDF blob
        for (const check of checks ?? []) {
          const { checkNumber, memoValue, amount } = getCheck(check) || {};
          const blob = await renderCheckForPdfBlob({ memoValue, check, checkNumber, amount });
          blobs.push(blob);
        }
        setPdfBlobs(blobs);
      })();
      setLoading(false);
      handlePrint();
    } catch (e) {
      setLoading(false);
    }
  };

  const styleBackDrop = useMemo(() => {
    return {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }, []);

  useEffect(() => {
    if (defaultSettings) {
      formik.setFieldValue('check_style', defaultSettings?.check_style);
      formik.setFieldValue('is_bank_logo_show', !!Number(defaultSettings?.is_bank_logo_show));
      formik.setFieldValue('is_company_logo_shown', !!Number(defaultSettings?.is_company_logo_shown));
      formik.setFieldValue('is_signature_shown', !!Number(defaultSettings?.is_signature_shown));
      formik.setFieldValue('signature', defaultSettings?.signature);
    }
  }, [defaultSettings]);

  useEffect(() => {
    if (signPad?.current && formik.values?.signature && typeof formik.values?.signature === 'string') {
      fetch(formik.values?.signature)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            signPad.current.fromDataURL(reader.result);
          };
          reader.readAsDataURL(blob);
        })
        .catch(() => {
          // Do nothing
        });
    }
  }, [signPad?.current]);

  return (
    <Modal open>
      <Box sx={styleBackDrop}>
        <div className={classes.modal}>
          <div className={classes.header}>
            <span>Print Checks</span>
          </div>
          <div className={classes.body}>
            <div className={classes.quickViewOptions}>
              <div className={classes.radioButtons}>
                <Typography variant='s2'>Check Style:</Typography>
                <div className='radio-wrapper'>
                  <Radio
                    id='check_style_1'
                    value={1}
                    label='Voucher'
                    name='check_style'
                    checked={+formik.values.check_style === 1}
                    onChange={() => {
                      setPageIndex(0);
                      formik.handleChange('check_style', 1);
                    }}
                  />
                </div>
                <div className='radio-wrapper'>
                  <Radio
                    id='check_style_2'
                    value={2}
                    label='Standard'
                    name='check_style'
                    checked={+formik.values.check_style === 2}
                    onChange={() => {
                      setPageIndex(0);
                      formik.handleChange('check_style', 2);
                    }}
                  />
                </div>
              </div>

              <div>
                <div className='mt-4'>
                  <CustomCheckbox
                    name='is_company_logo_shown'
                    checked={formik.values.is_company_logo_shown}
                    onChange={(checked) => formik.handleChange('is_company_logo_shown', checked)}
                  >
                    <Typography variant='s2' className='ms-2'>
                      Use Company Logo
                    </Typography>
                  </CustomCheckbox>
                </div>

                <div className='mt-2'>
                  <CustomCheckbox
                    name='is_bank_logo_show'
                    checked={formik.values.is_bank_logo_show}
                    onChange={(checked) => formik.handleChange('is_bank_logo_show', checked)}
                  >
                    <Typography variant='s2' className='ms-2'>
                      Use Bank Logo
                    </Typography>
                  </CustomCheckbox>
                </div>

                <div className='mt-2'>
                  <CustomCheckbox
                    name='is_signature_shown'
                    checked={formik.values.is_signature_shown}
                    onChange={(checked) => formik.handleChange('is_signature_shown', checked)}
                  >
                    <Typography variant='s2' className='ms-2'>
                      Add Signature
                    </Typography>
                  </CustomCheckbox>
                </div>
              </div>

              {+formik.values.check_style === 1 ? (
                <div className={classes.info}>
                  <div className={classes.infoHeader}>
                    <InfoIcon width={12} height={12} fill='rgb(33, 37, 41)' />{' '}
                    <span style={{ marginLeft: '4px' }}>Set Printer Settings</span>
                    <span className={classes.important}>IMPORTANT</span>
                  </div>
                  <div className={classes.infoBody}>
                    <Typography>
                      The following settings should be set on printer settings before you print your checks. This will
                      be located on the print preview menu after you click print button below.
                    </Typography>
                    <ol>
                      <li>
                        <Typography>Layout ➝ Portrait</Typography>
                      </li>
                      <li>
                        <Typography>Margins ➝ Minimum</Typography>
                      </li>
                      <li>
                        <Typography>Paper size ➝ Letter 8.5" x 11" 22x28cm</Typography>
                      </li>
                      <li>
                        <Typography>Scale ➝ Set to Custom and enter 85</Typography>
                      </li>
                    </ol>
                  </div>
                </div>
              ) : (
                <div className={classes.info}>
                  <div className={classes.infoHeader}>
                    <InfoIcon width={12} height={12} fill='rgb(33, 37, 41)' />{' '}
                    <span style={{ marginLeft: '4px' }}>Set Printer Settings</span>
                    <span className={classes.important}>IMPORTANT</span>
                  </div>
                  <div className={classes.infoBody}>
                    <Typography>
                      The following settings should be set on printer settings before you print your checks. This will
                      be located on the print preview menu after you click print button below.
                    </Typography>
                    <ol>
                      <li>
                        <Typography>Layout ➝ Portrait</Typography>
                      </li>
                      <li>
                        <Typography>Margins ➝ Minimum</Typography>
                      </li>
                      <li>
                        <Typography>Paper size ➝ Letter 8.5" x 11" 22x28cm</Typography>
                      </li>
                      <li>
                        <Typography>Scale ➝ Set to Custom and enter 95</Typography>
                      </li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            <div className={classes.quickViewWrapper}>
              <div className={classes.quickView}>
                <div className={classes.pdfDecoration}>
                  {formik.values.check_style === 2 ? (
                    checks?.slice(pageIndex, pageIndex + 3)?.map((check, index) => {
                      return (
                        <ChecksToPrintTemplate
                          key={`${check?.id || index}`}
                          bank={bank}
                          company={company}
                          check={check.detail}
                          amount={getCheck(check)?.amount || ''}
                          memoValue={getCheck(check)?.memoValue || ''}
                          useBankLogo={formik.values.is_bank_logo_show}
                          useSignature={formik.values.is_signature_shown}
                          checkNumber={getCheck(check)?.checkNumber || ''}
                          checkStyleVoucher={+formik.values.check_style === 1}
                          useCompanyLogo={formik.values.is_company_logo_shown}
                          signatureUrl={formik.values.signature}
                        />
                      );
                    })
                  ) : (
                    <ChecksToPrintTemplate
                      bank={bank}
                      company={company}
                      check={checks?.[pageIndex]?.detail}
                      amount={getCheck(checks?.[pageIndex])?.amount || ''}
                      useBankLogo={formik.values.is_bank_logo_show}
                      useSignature={formik.values.is_signature_shown}
                      useCompanyLogo={formik.values.is_company_logo_shown}
                      checkStyleVoucher={+formik.values.check_style === 1}
                      memoValue={getCheck(checks?.[pageIndex])?.memoValue || ''}
                      checkNumber={getCheck(checks?.[pageIndex])?.checkNumber || ''}
                      signatureUrl={formik.values.signature}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Button style={{ margin: 0 }} className='cancel-button' onClick={onClose}>
              Go Back
            </Button>
            {pageIndex > 0 && (
              <Button
                style={{ marginRight: 0 }}
                className='next-step px-lg-2'
                onClick={() => setPageIndex((p) => (formik.values.check_style === 2 ? p - 3 : p - 1))}
              >
                Previous Page
              </Button>
            )}
            {((formik.values.check_style === 2 && pageIndex < Number(checks?.length) - 3) ||
              (formik.values.check_style === 1 && pageIndex < Number(checks?.length) - 1)) && (
              <Button
                style={{ marginRight: 0 }}
                className='next-step px-lg-2'
                onClick={() => setPageIndex((p) => (formik.values.check_style === 2 ? p + 3 : p + 1))}
              >
                Next Page
              </Button>
            )}
            <Button disabled={loading} style={{ marginRight: 0 }} className='next-step px-lg-3' onClick={printHandler}>
              Print {printOpenedAndClosed && 'Again'}
            </Button>
            {printOpenedAndClosed && (
              <Button
                disabled={loading}
                style={{ marginRight: 0 }}
                className={`px-lg-3 ${classes.awardButton}`}
                onClick={handleProcessComplete}
              >
                Process & Complete
              </Button>
            )}
          </div>

          {successPageOpen && (
            <div className={classes.successLayer}>
              <div className={classes.successLayerInfo}>
                <TickIcon />
                <span>Success!</span>
                <Typography variant='s3'>
                  You have successfully processed {checks?.length} {checks?.length === 1 ? 'check' : 'checks'}!
                </Typography>
                <Button style={{ marginRight: 0 }} className='next-step px-lg-3' onClick={onFinish}>
                  Finish
                </Button>
              </div>
            </div>
          )}

          <SpinnerLoadingMultipleTexts
            isVisible={loading}
            fullScreen={false}
            texts={['Generating checks.. please stand by..', "Still working on your checks.. don't go anywhere.. "]}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default PrintChecksModal;
