import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Delete } from 'assets/icons/xClose.svg';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import useForm from 'hooks/useForm';
import Input from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import { SWrapper } from '../../Payroll/FormItem/FormItem.styles';
import { validationSchema } from './validationSchema';
import { SActions } from '../../Payroll/Payroll.styles';

const initialValues = {
  title: '',
  code: '',
};

const ItemForm = ({ onSubmit, onDelete, loading, editable, onCancel }) => {
  const { handleChange, handleSubmit, values, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <SWrapper>
      <div className='flex-item'>
        <Input
          name='type'
          onChange={handleChange}
          value={values.type}
          placeholder='Delay Reason'
          error={touchedErrors.type}
        />
      </div>
      <div className='flex-item'>
        <Input name='code' onChange={handleChange} value={values.code} placeholder='Code' error={touchedErrors.code} />
      </div>
      <SActions className='flex-item'>
        {!!editable && (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '1px 6px', margin: 0 }}
            onClick={onCancel}
          />
        )}
        {loading ? (
          <span className='loading-wrapper'>
            <CircularProgress size={16} />
          </span>
        ) : (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            title=''
            leftIcon={<Delete style={{ fill: '#464F60' }} />}
            styleButton={{ padding: '7px 8px', margin: 0 }}
            onClick={onDelete}
          />
        )}
        <CustomButton
          width={12}
          height={12}
          type='primary'
          leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={handleSubmit}
        />
      </SActions>
    </SWrapper>
  );
};

export default ItemForm;
