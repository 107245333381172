import React, { useCallback, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import ReactLoading from 'react-loading';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import sm from './CustomInput.module.css';
import { Typography } from '../../../Typography';

const CustomInput = ({
  field,
  form,
  className,
  leftIcon,
  leftIconStyle,
  rightIcon,
  rightIconStyle,
  rightParentText,
  rightParentTextStyle,
  type,
  style,
  label,
  required,
  labelStyle,
  labelTextStyle,
  fixed,
  inputType = 'text',
  minCount,
  maxCount,
  roundNumber = false,
  oneNumber,
  disabled,
  options,
  autoCloseOptions = true,
  onEnter,
  loading,
  loadingProps,
  optionsKeyName = 'key',
  optionsShowName = 'label',
  isNegative,
  ...props
}) => {
  const { use } = useTheme();
  const ref = useRef(null);
  const portalRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [clientRectInput, setClientRectInput] = useState({});
  const [prevIndex, setPrevIndex] = useState(null);

  const onChangeHandler = useCallback(
    (e) => {
      if (options) {
        for (let i = 0; i < options.length; i++) {
          const value = options[i][optionsKeyName];
          const isMatch = value?.toLowerCase().indexOf(e.target.value?.toLowerCase()) > -1;
          if (isMatch) {
            setIsFocused(true);
            break;
          } else {
            setIsFocused(false);
          }
        }
        setActiveIndex(null);
      }

      const value = e.target?.value;
      if (type === 'number') {
        const numberValue = e.target?.value?.startsWith('-') ? e.target?.value?.substring(1) : e.target?.value;
        let count = numberValue.match(/^[0-9]{1,}([.])?([0-9]{1,})?/g)?.[0];
        if (roundNumber) count = !!count && count.split('.').join('');

        if (minCount !== undefined) {
          if (maxCount) {
            if (+count >= +minCount && count <= maxCount) {
              form && form.setFieldValue(field.name, count || '');
              props?.onChange && props.onChange(e, count);
            } else {
              form && form.setFieldValue(field.name, '');
              props?.onChange && props.onChange(e, e.target.value);
            }
          } else {
            if (+count >= +minCount) {
              form && form.setFieldValue(field.name, count || '');
              props?.onChange && props.onChange(e, count);
            } else {
              form && form.setFieldValue(field.name, '');
              props?.onChange && props.onChange(e, e.target.value);
            }
          }
        } else if (oneNumber) {
          const val = value?.replace(/\D/g, '');
          const lastValue = val[val.length - 1];

          form && form.setFieldValue(field.name, lastValue || '');
          props?.onChange && props.onChange(e, lastValue);
        } else {
          form && form.setFieldValue(field.name, isNegative && count ? `-${count}` : count || '');
          props?.onChange && props.onChange(e, count);
        }
      } else {
        form && form.setFieldValue(field.name, value);
        props?.onChange && props.onChange(e, e?.target?.value);
      }
    },
    [type, roundNumber, minCount, maxCount, form, field?.name, field?.value, props?.onChange, oneNumber, options]
  );

  const onDown = () => {
    setPrevIndex(activeIndex);
    if (activeIndex === null) {
      setActiveIndex(0);
    } else {
      if (Number(options?.length) === +activeIndex + 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex((prev) => prev + 1);
      }
    }
  };

  const onUp = () => {
    setPrevIndex(activeIndex);

    if (activeIndex === null) {
      setActiveIndex(options.length - 1);
    } else {
      if (activeIndex === 0) {
        setActiveIndex(options.length - 1);
      } else {
        setActiveIndex((prev) => prev - 1);
      }
    }
  };

  const onKeyDown = (e) => {
    e?.keyCode === 13 && e.preventDefault();
    if (options) {
      if (e?.keyCode === 13) {
        setIsFocused(null);
        typeof onEnter === 'function' && onEnter(e, options[activeIndex]?.[optionsKeyName], options[activeIndex]);
      }
      if (e.keyCode === 40) onDown(e);
      if (e.keyCode === 38) onUp(e);
    }
  };

  const onClick = useCallback(
    (e) => {
      const value = props?.value !== undefined ? props?.value : field?.value;
      if (!!fixed && value !== '') {
        const val = Math.round(+value);
        props.onChange && props.onChange(String(val));
        form && form.setFieldValue(field.name, String(val));
      }
      props?.onClick && props.onClick(e);
    },
    [props?.value, field?.value, fixed, props?.onChange, form, field?.name, props?.onClick]
  );

  const onBlur = useCallback(() => {
    const value = props?.value !== undefined ? props?.value : field?.value;
    if (!!fixed && value !== '') {
      const val = parseFloat(value).toFixed(fixed);
      props.onChange && props.onChange(val);
      form && form.setFieldValue(field.name, val);
    }
  }, [props?.value, field?.value, fixed, props?.onChange, form, field?.name]);

  const getScrollParent = (node) => {
    if (node == null) return null;
    if (node.scrollHeight > node.clientHeight) {
      return node;
    }
    return getScrollParent(node.parentNode);
  };

  const calculatePosition = () => {
    const positionInput = ref?.current?.getBoundingClientRect();
    positionInput && setClientRectInput(positionInput);
  };

  useEffect(() => {
    if (Array.isArray(options) && options.length > 0 && !!isFocused) {
      const positionInput = ref?.current?.getBoundingClientRect();
      positionInput && setClientRectInput(positionInput);

      const element = getScrollParent(ref.current);
      element && element.addEventListener('scroll', calculatePosition);
      return () => {
        element && element.removeEventListener('scroll', calculatePosition);
      };
    }
  }, [options, isFocused, form?.errors]);

  useEffect(() => {
    const cond = activeIndex > prevIndex || (activeIndex === 0 && prevIndex === null);
    const activeElement = document.querySelector(`.${sm.active_item}`);
    const positionInput = ref?.current?.getBoundingClientRect();
    const bottomElem = Number(activeElement?.getBoundingClientRect()?.bottom) + 2;
    const portalElem = Number(portalRef?.current?.getBoundingClientRect()?.bottom);

    if (Number(activeElement?.getBoundingClientRect()?.top) < Number(positionInput?.bottom)) {
      activeElement && activeElement.scrollIntoView({ block: 'start' });
    } else if (cond && portalElem <= bottomElem) {
      activeElement && activeElement.scrollIntoView({ block: 'end' });
    }
  }, [activeIndex, prevIndex, ref?.current, portalRef?.current]);

  useEffect(() => {
    if (Array.isArray(options) && options?.length > 0 && (!!field?.value || props?.value) && isFocused !== null) {
      ref.current.focus();
      setIsFocused(true);
    } else {
      isFocused === null ? setIsFocused(null) : setIsFocused(false);
    }
  }, [options, field?.value, props?.value]);

  return (
    <div className={!!leftIcon || !!rightIcon ? sm.customInput_wrapper : ''}>
      {leftIcon && (
        <div className={sm.left_position} style={{ ...leftIconStyle }}>
          {leftIcon}
        </div>
      )}
      <label className={sm.right_parent_wrapper} style={{ ...labelStyle }}>
        <div style={{ ...labelTextStyle }}>
          {!!label && (
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {label}
            </Typography>
          )}
          {!!required && <span className={sm.input_required}>*</span>}
        </div>
        <div className='w-100 position-relative'>
          <input
            onKeyDown={onKeyDown}
            {...field}
            {...props}
            ref={ref}
            type={inputType}
            disabled={disabled}
            autoComplete={options ? 'off' : 'on'}
            value={props?.value !== undefined ? props?.value : field?.value ? field?.value : ''}
            onChange={(e) => onChangeHandler(e)}
            onBlur={(e) => {
              field?.onBlur(e);
              onBlur();
              !isHovered && setIsFocused(false);
            }}
            onFocus={() => setIsFocused(true)}
            onClick={onClick}
            className={classNames([sm.customInput, className, { [sm.has_a_icon]: leftIcon }, { disabled }])}
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              color: use(palette.dark800, palette.gray200),
              ...style,
            }}
          />
          {!!loading && (
            <ReactLoading
              className={classNames(sm.reactLoading)}
              type={loadingProps?.type ? loadingProps.type : 'spinningBubbles'}
              color={loadingProps?.color ? loadingProps.color : palette.indigo500}
              height={loadingProps?.height ? loadingProps.height : 16}
              width={loadingProps?.width ? loadingProps.width : 16}
            />
          )}
        </div>
        {rightParentText && (
          <div
            className={sm.right_parent}
            style={{
              height: props?.style?.height ? props?.style?.height : 22,
              backgroundColor: use(palette.white, palette.dark800),
              ...rightParentTextStyle,
            }}
          >
            {rightParentText}
          </div>
        )}
        {rightIcon && (
          <div className={sm.right_position} style={{ ...rightIconStyle }}>
            {rightIcon}
          </div>
        )}
      </label>

      {Array.isArray(options) &&
        options.length > 0 &&
        !!isFocused &&
        createPortal(
          <div
            onMouseOver={() => ref.current.focus()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classNames(sm.suggestions, use(sm.light, sm.dark))}
            style={{
              top: clientRectInput?.bottom,
              left: clientRectInput?.left,
              width: clientRectInput.width,
            }}
            ref={portalRef}
          >
            {options?.map((option, index) => {
              const key = option?.[optionsKeyName];
              const showData = option?.[optionsKeyName];
              const valueInput = props?.value !== undefined ? props?.value : field?.value ? field?.value : '';
              const isMatch = showData?.toLowerCase().indexOf(valueInput.toLowerCase()) > -1;
              return (
                <div key={key}>
                  {isMatch && (
                    <div
                      className={classNames(sm.suggestions_item, { [sm.active_item]: index === activeIndex })}
                      onClick={() => {
                        ref.current.focus();
                        !!autoCloseOptions && setIsFocused(null);
                        form && form.setFieldValue(field.name, key);
                        props?.onChange && props.onChange(ref?.current, key, option);
                      }}
                    >
                      <Typography style={{ maxWidth: clientRectInput.width }} variant='s2'>
                        {option?.[optionsShowName]}
                      </Typography>
                    </div>
                  )}
                </div>
              );
            })}
          </div>,
          document.body
        )}
    </div>
  );
};

export default React.memo(CustomInput);
