import React, { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './DispatchCarrier.css';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import down from 'assets/icons/drivers/down.svg';
import headerIcon from 'assets/icons/vehicles/green.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/expand.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ShipmentIcon } from 'assets/icons/vehicles/shipment.svg';
import { ReactComponent as PLusIcon } from 'assets/icons/plus.svg';

import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import AddEditModalDetails from 'components/AddEditModalStops';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import classes from 'components/TableShipments/detailsRow/details.module.css';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';
import OverViewStop from 'components/TableShipments/detailsRow/steps/OverView/OverViewStops';
import { SkeletonStops } from 'components/TableShipments/detailsRow/steps/OverView/OverView';
import SettingsModal from 'components/TableShipments/detailsRow/steps/OverView/SettingsModal';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import { calculateFullDays } from 'components/TableShipments/components/RequestCellularTracking/RequestCellularTracking.data';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { formatAmount } from 'services/IFTAServices';
import { formatNumber, getAlphabet, palette } from 'utils/constants';
import { blockNonPositiveNumbers, getShipmentType } from 'utils/helpers';
import {
  addShipmentStop,
  deleteShipmentStop,
  getShipmentInfo,
  getStops,
  getTrackingCost,
  updateCargo,
  UpdateStopPointStop,
} from 'Api/Shipment';
import {
  dispatchCarrier,
  getEquipmentDimensions,
  getEquipmentRequiredTrailers,
  getEquipmentRequiredVehicles,
  getShipmentStops,
} from 'Api/Planner';
import { UpdateStopPoint } from 'Api/StopPoint';
import { getCities, getStates } from 'Api/JobPositions';
import { DateTimePicker } from 'common/Pickers';
import Input, { InputWithIcon, Textarea } from 'common/Input';
import { getCarrier, getCarrierContactBook, getCarrierShipmentCost } from 'Api/Carriers';
import { getBrokerageSetting, getShipmentDispatchSettings } from 'Api/CompanySettings';
import { getUserProfile } from 'Api/User';
import {
  createCargoConverter,
  createCargoFormDataConverter,
} from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { generatePDF } from 'pages/CarrierConfirmation/GenerateCarrierConfirmation/helpers';
import JsBarcode from 'jsbarcode';
import { useAuth } from 'context/auth.context';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getPlannerStatus } from '../TableShipments/helpers/constants';
import { SAddressWrapper, SFlexRow } from './DispatchCarrier.styles';
import DispatchCarrierMap from './DispatchMap';
import PhoneInput from '../../common/PhoneInput';
import { dispatchCarrierValidation } from './helpers';
import { getDispatchCarrierInitialValues } from './DispatchCarrier.data';
import AlreadyDispatchedModal from '../PlanToVehicle/AlreadyDispatchedModal';
import ContactBook from './ContactBook';

export default function DispatchCarrier() {
  const { use } = useTheme();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [searchParams] = useSearchParams();
  const shipmentId = searchParams.get('id');
  const carrierId = searchParams.get('carrier');
  const contactUserId = searchParams.get('contact');
  const amount = searchParams.get('amount');
  const stateId = searchParams.get('state');
  const cityId = searchParams.get('city');
  const { currency } = useSelector((state) => state.root);
  const [routeSelect, setRouteSelect] = useState('practical');
  const [customerContactBook, setCustomerContactBook] = useState([]);
  const [data, setData] = useState(null);
  const [shipmentStops, setShipmentStops] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [infoHeader, setInfoHeader] = useState();
  const [legs, setLegs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDispatch, setLoadingDispatch] = useState(false);
  const [selectModalType, setSelectModalType] = useState({});
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState('');
  const [isOpen, setIsOpen] = useState({});
  const [user, setUser] = useState({});
  const [showLicence, setShowLicence] = useState(false);
  const [trackingCost, setTrackingCost] = useState(null);
  const legsStopsCount = [];
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [trailerTypeList, setTrailerTypeList] = useState([]);
  const [trailerLengthData, setTrailerLengthData] = useState([]);
  const [customLatLong, setCustomLatLong] = useState({ latitude: null, longitude: null });
  const [trailerDisabled, setTrailerDisabled] = useState(false);
  const [alreadyDispatched, setAlreadyDispatched] = useState(null);
  const [foundedCity, setFoundedCity] = useState(null);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [company, setCompany] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [barcodeImage, setBarcodeImage] = useState(null);
  const { value: userData } = useAuth();
  const matches = useMediaQuery('(min-width:1600px)');

  const shipmentPlannedDuration = useMemo(() => {
    const firstStopDate = `${infoHeader?.shipment_stops?.[0]?.scheduled_date} ${infoHeader?.shipment_stops?.[0]?.to}`;
    const lastStopDate = `${
      infoHeader?.shipment_stops?.[Number(infoHeader?.shipment_stops?.length) - 1]?.scheduled_date
    } ${infoHeader?.shipment_stops?.[Number(infoHeader?.shipment_stops?.length) - 1]?.to}`;

    return getAverageTimeConverted(moment(lastStopDate).diff(moment(firstStopDate), 'minutes'));
  }, [infoHeader]);

  const orderingInLegsStops = useCallback((data) => {
    const legsStopsData = [];
    data.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    return legsStopsData;
  }, []);

  const onDispatch = (values) => {
    setLoadingDispatch(true);
    const formData = new FormData();
    formData.append('shipment_id', shipmentId);
    formData.append('carrier_id', carrierId);
    formData.append('immediately', Number(values.immediately));
    formData.append('in_future', Number(values.in_future));
    if (values.future_date && moment(values.future_date).isValid()) {
      formData.append('future_date', moment(values.future_date).format('YYYY-MM-DD HH:mm:ss'));
    }
    formData.append('after_specific_shipment_complated', Number(values.after_specific_shipment_complated));
    formData.append('before_scheduled_pickup', Number(values.before_scheduled_pickup));
    if (values.before_scheduled_pickup_hours) {
      formData.append('before_scheduled_pickup_hours', values.before_scheduled_pickup_hours);
    }
    formData.append('route_type', mapValues.route_type);
    formData.append('open_borders', Number(mapValues.open_borders));
    formData.append('highway_only', Number(mapValues.hwy_only));
    formData.append('hazmat_id', Number(mapValues.haz_mat));
    formData.append('avoid_tolls', Number(mapValues.avoid_tolls));
    formData.append('assign_driver_from', 4);
    formData.append('is_cellular_tracking_enable', values.cellular_tracking ? '1' : '0');

    formData.append('driver1_amount', values.flat_rate);
    formData.append('vehicle_id', values.vehicle_id);
    formData.append('vehicle_type_id', values.vehicle_type_id?.id);
    formData.append('add_shipment_notes', Number(values.add_shipment_notes));
    if (values.add_shipment_notes && values.shipment_notes) {
      formData.append('shipment_notes', values.shipment_notes);
    }
    formData.append('company_acceptance', 1);
    formData.append('assign_driver_from_shipment_latitude', customLatLong.latitude);
    formData.append('assign_driver_from_shipment_longitude', customLatLong.longitude);
    formData.append('trailer_id', values.trailer_id);
    if (values.trailer_length) {
      formData.append(
        'trailer_length_id',
        values.trailer_length?.length_info ? values.trailer_length?.length_info?.id : values.trailer_length?.id
      );
    }
    if (values.trailer_type?.id) {
      formData.append('trailer_type_id', values.trailer_type?.id);
    }
    formData.append('driver_name', values.driver);
    formData.append('driver_phone_number', values.phone);
    if (values.contact?.id) {
      formData.append('contact_id', values.contact?.id);
    }

    const { estimated_fuel_cost, total_amount } = data;
    const net_profit = +total_amount - (+values.flat_rate + Number(estimated_fuel_cost));
    formData.append('net_profit', net_profit);
    if (estimated_fuel_cost) {
      formData.append('estimated_fuel_cost', estimated_fuel_cost);
    }

    dispatchCarrier(formData)
      .then(() => {
        setLoadingDispatch(false);
        navigate(`/shipment/${shipmentId}/dispatch`, { state: { fromDispatch: true } });
      })
      .catch((err) => {
        if (err?.response?.data?.response?.shipment_id) {
          getShipmentInfo(shipmentId)
            .then((res) => {
              const { dispatched_by_user } = res.data || {};
              if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                setAlreadyDispatched(
                  `${dispatched_by_user.first_name || ''} ${
                    dispatched_by_user.last_name || ''
                  } has already dispatched this shipment, we are now exiting the dispatch process!`
                );
              }
            })
            .catch(() => {
              // Do nothing
            });
          setLoadingDispatch(false);
        } else {
          showToaster({ type: 'error', message: getErrorMessage(err) || 'Something went wrong' });
          setLoadingDispatch(false);
        }
      });
  };

  const { values, handleChange, touchedErrors, handleSubmit, setValues } = useForm({
    initialValues: getDispatchCarrierInitialValues(data, infoHeader, amount),
    validationSchema: dispatchCarrierValidation,
    onSubmit: onDispatch,
    enableReinitialize: true,
  });

  const { values: mapValues, handleChange: handleMapValuesChange } = useForm({
    initialValues: {
      route_type: 'practical',
      hwy_only: 0,
      open_borders: 0,
      haz_mat: 0,
      avoid_tolls: 0,
      vehicle_type: 0,
    },
  });

  const handleRouteSelect = (route) => {
    setRouteSelect(route);
    handleMapValuesChange('route_type', route);
  };

  const style = useMemo(() => {
    return {
      mapWrapper: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        height: '726px',
      },
      map: {
        position: 'static',
        zIndex: 20,
        width: '100%',
        height: 'calc(100% - 40px)',
        borderRadius: '8px 0 0 8px',
      },
    };
  }, []);

  const getDispatchSettings = async () => {
    try {
      const { data } = await getShipmentDispatchSettings();
      if (data) {
        setValues((prevState) => ({
          ...prevState,
          immediately: !!data.immediately,
          after_specific_shipment_complated: !!data.dispatch_next_in_line,
          before_scheduled_pickup: !!data.dispatch_driver_hours_before_scheduled,
          before_scheduled_pickup_hours: data.dispatch_driver_hours_before_scheduled || '0',
          send_trucking_link_to_customer: !!data.send_tracking_updates,
          send_pickup_reminder_to_driver: !!data.send_driver_reminder,
          send_pickup_reminder_to_driver_before_minutes: data.send_driver_reminder,
        }));
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getUser = async () => {
    try {
      const { data } = await getUserProfile();
      setUser(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getContacts();
    getVehicles();
    getUser();
    getTrailerTypes();
    getHeaderInfo();
    getCarrierInfo();
    getDispatchSettings();
    getShipmentStops(shipmentId).then((res) => {
      setShipmentStops(res.data);
    });
  }, []);

  const getCarrierInfo = () => {
    getCarrier({ id: carrierId }).then((res) => {
      setCarrier(res.data);
    });
  };

  const { totalDollar } = useMemo(() => {
    let totalDollar = 0;
    let totalCad = 0;

    infoHeader?.shipment_billing?.forEach((el) => {
      const amount = el?.total_amount || 0; // Safeguard against null/undefined values
      if (Number(el?.currency_id) === 1) {
        totalDollar += amount;
      } else {
        totalCad += amount;
      }
    });

    return { totalDollar, totalCad };
  }, [infoHeader]);

  const getTrackingCosts = async () => {
    try {
      const { price } = await getTrackingCost();
      setTrackingCost(price);
    } catch (e) {
      // Do nothing
    }
  };

  const getVehicles = async () => {
    const vehicles = await getEquipmentRequiredVehicles();
    setVehicleList(vehicles.data);
  };

  const getTrailerTypes = async () => {
    const trailerTypes = await getEquipmentRequiredTrailers();
    setTrailerTypeList(trailerTypes.data);
  };

  const getHeaderInfo = () => {
    getShipmentInfo(shipmentId)
      .then((res) => {
        if (res?.data) {
          handleMapValuesChange('open_borders', res.data.shipment_route?.open_borders);
          handleMapValuesChange('hwy_only', res.data.shipment_route?.highway_only);
          handleMapValuesChange('haz_mat', res.data.shipment_route?.hazmat_id);
          handleMapValuesChange('route_type', res.data.shipment_route?.route_type);
          handleMapValuesChange('vehicle_type', res.data.shipment_route?.vehicle_type);
          handleMapValuesChange('avoid_tolls', res.data.shipment_route?.avoid_tolls);
          setRouteSelect(res.data.shipment_route?.route_type);
          setInfoHeader(res.data);
          const { dispatched_by_user } = res.data || {};
          if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
            setAlreadyDispatched(
              `${dispatched_by_user.first_name || ''} ${
                dispatched_by_user.last_name || ''
              } has already dispatched this shipment, we are now exiting the dispatch process!`
            );
          }
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  function onBackPlanner() {
    navigate(`/planner/plan?id=${shipmentId}`);
  }

  const getContacts = () => {
    getCarrierContactBook({ id: carrierId })
      .then((res) => {
        setCustomerContactBook(res.data);
        if (contactUserId) {
          const contactUser = res.data.find((item) => Number(item.id) === Number(contactUserId));
          setTimeout(() => {
            handleChange('contact', contactUser || values.contact);
          }, 2000);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  useEffect(() => {
    getStopsData();
    getTrackingCosts();
  }, []);

  const getStopsData = () => {
    setLoading(true);
    getStops({ shipment_id: shipmentId })
      .then((res) => res?.data && setLegs(orderingInLegsStops(res?.data)))
      .finally(() => setLoading(false));
  };

  function onClickItemSettingsIcon(stop) {
    setIsOpenSettingsModal(stop?.stop_point?.id);
  }

  const onAddEditModalDetails = (values, type, item) => {
    delete values?.id;
    if (type === 'edit') {
      const formData = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        const val = values[key];
        if (key === 'to' || (val !== '' && !['arrival_time', 'departure_time'].includes(key))) {
          if (key === 'dimensions' && val?.l && val?.h && val?.w) {
            formData.append(`dimensions[l]`, val?.l || '');
            formData.append(`dimensions[h]`, val?.h || '');
            formData.append(`dimensions[w]`, val?.w || '');
          } else if (key === 'arrival_date') {
            if (val) {
              formData.append(
                'arrival_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.arrival_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'departure_date') {
            if (val) {
              formData.append(
                'departure_date',
                moment(`${moment(val).format('YYYY-MM-DD')} ${moment(values?.departure_time).format('HH:mm')}`).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              );
            }
          } else if (key === 'bill_type') {
            formData.append('bill_type', values.bill_type ? '1' : '2');
          } else if (key === 'scheduled_date') {
            formData.append('scheduled_date', moment(val).format('YYYY-MM-DD'));
          } else if (key === 'scheduled_date_to') {
            formData.append('scheduled_date_to', moment(val || values?.scheduled_date).format('YYYY-MM-DD'));
          } else if (key === 'from') {
            formData.append('from', moment(values?.from).format('HH:mm'));
          } else if (key === 'to') {
            formData.append('to', moment(val || values?.from).format('HH:mm'));
          } else if (key === 'reported_quantity_type' && !!val) {
            formData.append(
              'reported_quantity_type',
              values?.reported_quantity_type?.id || values?.reported_quantity_type
            );
          } else if (key === 'reported_weight_type' && !!val) {
            formData.append('reported_weight_type', values?.reported_weight_type?.id || values?.reported_weight_type);
          } else if (key === 'connected_cargos' && val?.length) {
            val.forEach((cargo, index) => {
              formData.append(`connected_cargos[${index}]`, cargo.id);
            });
          } else if (key === 'cargos') {
            // Do nothing
          } else {
            let value = val;
            if (typeof val === 'boolean') {
              if (key === 'bill_type') {
                return;
              }
              value = val ? 1 : 0;
            }
            if (key === 'dimensions') {
              return;
            }
            formData.append(key, value);
          }
        }
      });
      updateStop(formData, item?.id);

      if (Number(values.stop_point_type) === 1 && values.cargos?.length === 1) {
        const body = createCargoConverter(values.cargos[0]);
        updateCargo(values.cargos[0].id, body);
      }
    } else {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'id') {
          return;
        }
        let value = values[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        if (key === 'bill_type') {
          formData.append('bill_type', !values.bill_type ? 1 : 2);
        }

        if (key === 'dimensions' && typeof value === 'object') {
          Object.keys(value).forEach((dimensionKey) => {
            formData.append(`dimensions[${dimensionKey}]`, value[dimensionKey] || '');
          });
        } else if (key === 'cargos') {
          const cargos = values.cargos?.length ? values.cargos.filter((i) => !!i?.commodity) : [];
          createCargoFormDataConverter(cargos, formData);
        } else if (key === 'scheduled_date') {
          formData.append('scheduled_date', moment(value).format('YYYY-MM-DD'));
        } else if (key === 'scheduled_date_to') {
          formData.append('scheduled_date_to', moment(value || values?.scheduled_date).format('YYYY-MM-DD'));
        } else if (key === 'from') {
          formData.append('from', moment(values?.from).format('HH:mm'));
        } else if (key === 'to') {
          formData.append('to', moment(value || values?.from).format('HH:mm'));
        } else {
          formData.append(key, value);
        }
      });
      formData.append('shipment_id', shipmentId);
      formData.append('leg', selectModalType.legIndex);
      formData.append('order_in_leg', selectModalType.order_in_leg);
      addShipmentStop(formData)
        .then(() => {
          getStopsData();
          getHeaderInfo();
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const getDimensions = (equipment_type_id) => {
    const params = {
      equipment_type_id,
    };
    getEquipmentDimensions(params).then((res) => {
      setTrailerLengthData(res.data);
    });
  };

  const updateStop = (params, id) => {
    setLoading(true);
    UpdateStopPointStop({ params, id })
      .then(() => {
        getStopsData();
      })
      .finally(() => setLoading(false));
  };

  function onUpdateSettings(id, validateForm, resetForm, submitForm, values) {
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
        const backData = {};
        Object.keys(values).forEach((key) => {
          const value = values[key];
          if (typeof value === 'boolean') {
            backData[key] = value ? 1 : 0;
          } else {
            backData[key] = value;
          }
        });
        UpdateStopPoint(id, backData).then(() => setIsOpenSettingsModal(''));
      }
    });
  }

  function sumDurations(durations) {
    let totalMinutes = 0;
    for (const duration of durations) {
      let days = 0;
      let hours = 0;
      let minutes = 0;
      const parts = duration?.split(' ');
      for (const part of parts) {
        if (part) {
          if (part.endsWith('d')) {
            days = parseInt(part, 10);
          } else if (part.endsWith('h')) {
            hours = parseInt(part, 10);
          } else if (part.endsWith('m')) {
            minutes = parseInt(part, 10);
          }
        }
      }
      totalMinutes += days * 24 * 60 + hours * 60 + minutes;
    }
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${days}d ${hours}h ${minutes}m`;
  }

  const getGeoLocation = async () => {
    return new Promise((resolve) => {
      TrimbleMaps.Geocoder.geocode({
        address: {
          city: values?.city?.name,
          state: values?.state?.name,
          zip: values?.zip,
          region: TrimbleMaps.Common.Region.NA,
        },
        listSize: 1,
        success: (response) => {
          if (response && response?.length) {
            const filterByData = response?.map((el) => {
              return {
                state: el?.Address?.State,
                city: el?.Address?.City,
                latitude: el.Coords?.Lat,
                longitude: el.Coords?.Lon,
              };
            });
            resolve(filterByData[0]);
          }
        },
        failure: () => {
          showToaster({ type: 'error', message: 'Could not find location' });
          resolve(null);
        },
      });
    });
  };

  function onDeleteStop(stopId) {
    deleteShipmentStop(stopId)
      .then(() => {
        showToaster({ type: 'success', message: 'Shipment stop has been deleted successfully!' });
        getHeaderInfo();
        getStopsData();
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  }

  const onUpdateSuccess = () => {
    getHeaderInfo();
    getStopsData();
  };

  const getStatesList = async (params) => {
    try {
      const { data } = await getStates(params);
      setStates(data);

      if (stateId) {
        const matchingState = data.find((i) => Number(i.id) === Number(stateId));
        handleChange('state', matchingState || values.state);
      }
    } catch (e) {
      /* empty */
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const { data } = await getCities({ state_id: stateId });
      setCities(data);
      if (foundedCity) {
        const matchingCity = data.find((item) => item.name === foundedCity);
        if (matchingCity) {
          handleChange('city', matchingCity);
        }
      }

      if (cityId) {
        const matchingCity = data.find((item) => Number(item.id) === Number(cityId));
        if (matchingCity) {
          handleChange('city', matchingCity);
        }
      }
    } catch (e) {
      /* empty */
    }
  };

  const getBarcode = (id, displayValue = true) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, id, { displayValue });
    return canvas.toDataURL('image/jpeg');
  };

  const getCompanyProfile = () => {
    const today = moment().format('MM/DD/YYYY HH:MM');
    const garlaxDate = today.replace(/[^0-9]/g, '');
    const barNumber = shipmentId ? `${shipmentId}-${garlaxDate}` : null;

    const image = getBarcode(barNumber);
    setBarcodeImage(image);
    getBrokerageSetting()
      .then((res) => {
        if (res?.data) {
          setCompany(res?.data);
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        // Do nothing
      });
  };

  const viewConfirmation = async () => {
    const shippersAndConsignees = infoHeader.shipment_stops
      ?.filter((stop) => Number(stop.stop_point_type) === 1 || Number(stop.stop_point_type) === 2)
      .map((el) => {
        if (Number(el.stop_point_type) === 1) {
          return { ...el, type: 'Shipper' };
        }
        return { ...el, type: 'Consignee' };
      });

    const pickupStops = shippersAndConsignees?.filter((stop) => Number(stop.stop_point_type) === 1);

    const total_weight = pickupStops?.reduce((acc, cur) => acc + Number(cur.weight), 0);
    const total_qty = pickupStops?.reduce((acc, cur) => acc + cur.quantity, 0);

    const { url } = await generatePDF({
      shipment: {
        ...infoHeader,
        brokerage_dispatch: {
          carrier,
          ...values,
          contact_user: values.contact,
          trailer_length: values.trailer_length?.length_info || values.trailer_length,
        },
        driver_amount: values.flat_rate || 0,
        carrier_pay: [
          {
            type_name: { title: 'Carrier Linehaul' },
            description: 'Carrier Linehaul',
            quantity: 1,
            rate: Number(values.flat_rate || 0),
            total: Number(values.flat_rate || 0),
          },
        ],
        dispatched_by_user: userData.user,
        empty_miles: data?.empty_miles || infoHeader?.empty_miles,
      },
      company,
      shippersAndConsignees,
      currency,
      barcodeImage,
      pickupStops,
      total_weight,
      total_qty,
      values: {
        ...values,
        driver_name: values.driver,
        driver_phone_number: values.phone,
        trailer_length: values.trailer_length?.length_info || values.trailer_length,
      },
    });
    setPdfUrl(url);
  };

  useEffect(async () => {
    if (values.zip) {
      try {
        handleChange('state', null);
        handleChange('city', null);
        const res = await getGeoLocation();

        if (res.latitude && res.longitude) {
          setCustomLatLong(res);
          if (res.state && res.city) {
            const foundState = states.find((item) => item.short_name === res.state);
            setFoundedCity(res.city);
            handleChange('state', foundState);
          } else {
            handleChange('state', null);
            setFoundedCity(null);
          }
        } else {
          setFoundedCity(null);
          handleChange('state', null);
        }
      } catch (err) {
        if (err?.response?.data?.response?.shipment_id) {
          getShipmentInfo(shipmentId)
            .then((res) => {
              const { dispatched_by_user } = res.data || {};
              if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                setAlreadyDispatched(
                  `${dispatched_by_user.first_name || ''} ${
                    dispatched_by_user.last_name || ''
                  } has already dispatched this shipment, we are now exiting the dispatch process!`
                );
              }
            })
            .catch(() => {
              // Do nothing
            });
        }
      }
    }
  }, [values.zip]);

  useEffect(async () => {
    if (values.state && values.city) {
      try {
        const res = await getGeoLocation();
        if (res.latitude && res.longitude) {
          setCustomLatLong(res);
        }
      } catch (err) {
        if (err?.response?.data?.response?.shipment_id) {
          getShipmentInfo(shipmentId)
            .then((res) => {
              const { dispatched_by_user } = res.data || {};
              if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                setAlreadyDispatched(
                  `${dispatched_by_user.first_name || ''} ${
                    dispatched_by_user.last_name || ''
                  } has already dispatched this shipment, we are now exiting the dispatch process!`
                );
              }
            })
            .catch(() => {
              // Do nothing
            });
        }
      }
    }
  }, [values.state, values.city]);

  useEffect(async () => {
    if (values.vehicle_type_id) {
      try {
        const costParams = {
          shipment_id: shipmentId,
          assign_driver_from_shipment_latitude: customLatLong.latitude || undefined,
          assign_driver_from_shipment_longitude: customLatLong.longitude || undefined,
          vehicle_type_id: values.vehicle_type_id?.id,
        };
        const costData = await getCarrierShipmentCost(costParams);
        setData(costData?.data?.[0]);
      } catch (err) {
        if (err?.response?.data?.response?.shipment_id) {
          getShipmentInfo(shipmentId)
            .then((res) => {
              const { dispatched_by_user } = res.data || {};
              if (dispatched_by_user.first_name || dispatched_by_user.last_name) {
                setAlreadyDispatched(
                  `${dispatched_by_user.first_name || ''} ${
                    dispatched_by_user.last_name || ''
                  } has already dispatched this shipment, we are now exiting the dispatch process!`
                );
              }
            })
            .catch(() => {
              // Do nothing
            });
        } else {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        }
      }
    }
  }, [customLatLong, values.vehicle_type_id]);

  useEffect(() => {
    getCompanyProfile();
    getStatesList({ 'country_id[]': ['38', '231', '142'] });
  }, []);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  return (
    <div className='profile-container'>
      {showLicence && (
        <div className='check-addon-dispatch' onClick={() => setShowLicence(false)}>
          You do not have this feature enabled.
          <span onClick={() => navigate('/billing?addonId=2&planId=34')}> Purchase Addon </span>
        </div>
      )}
      <div className='user-profile-header w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='dispatch-vehicle-container w-100'>
          <div className='plan-vehicle-text-wrapper'>
            <div onClick={onBackPlanner} className='back_plan_to_vehicle'>
              <ArrowLeft width={10} height={10} />
              <Typography variant='c1' className='back'>
                Plan to Vehicle
              </Typography>
            </div>
          </div>
          {shipmentStops?.is_edi_api || shipmentStops?.manual_edi_api ? (
            <div className='dispatch-vehicle-text-wrapper'>
              <div className='d-flex'>
                <img src={headerIcon} alt='headerIcon' />
                <p className='dispatch-vehicle-text green-text'>EDI/API SHIPMENT </p>
              </div>
            </div>
          ) : null}

          <div className='d-flex '>
            <div className='dispatch-vehicle-details w-100'>
              <div className='dispatch-vehicle-details-item'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Dispatch
                </h2>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <Typography variant='overLine2' className='d-flex'>
                  <span className='bold-text'>{shipmentStops?.shipment_id}</span>
                  (REF:&nbsp;
                  {(shipmentStops?.shipment_billing && shipmentStops?.shipment_type !== 'ltl') ||
                  (shipmentStops?.shipment_type === 'ltl' && shipmentStops?.shipment_billing.length === 1) ? (
                    <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {shipmentStops?.shipment_billing?.[0]?.reference_id}
                    </Typography>
                  ) : (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id='button-tooltip-2'>
                          {shipmentStops?.shipment_billing?.map((el, i) => (
                            <p key={el?.id || i} style={{ margin: 0 }}>
                              {el?.reference_id}
                            </p>
                          ))}
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div ref={ref}>
                          <Typography
                            variant='s3'
                            {...triggerHandler}
                            style={{ color: use(palette.dark800, palette.gray200) }}
                          >
                            Multi-Ref LTL
                          </Typography>
                        </div>
                      )}
                    </OverlayTrigger>
                  )}
                  )
                </Typography>
              </div>
              <div className='dispatch-vehicle-details-item'>
                {shipmentStops?.shipment_billing &&
                shipmentStops?.shipment_billing.length &&
                shipmentStops?.shipment_billing.length === 1 ? (
                  <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {shipmentStops?.shipment_billing?.[0]?.billing_customer?.company_name}&nbsp;
                    {shipmentStops.shipment_type === 'ltl' && <Typography variant='s3'>(LTL)</Typography>}
                  </Typography>
                ) : shipmentStops?.shipment_billing && shipmentStops?.shipment_billing.length > 1 ? (
                  <OverLayTriggerHoverWrapper
                    overlay={shipmentStops?.shipment_billing.map((el, i) => (
                      <Typography
                        key={el?.id || i}
                        as='p'
                        variant='s2'
                        style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/customer-profile/${el?.billing_customer?.id}`);
                        }}
                      >
                        {el?.billing_customer?.company_name}
                      </Typography>
                    ))}
                  >
                    <div>
                      <Typography variant='s3' style={{ color: use(palette.dark800, palette.gray200) }}>
                        Multi-Customer LTL
                      </Typography>
                    </div>
                  </OverLayTriggerHoverWrapper>
                ) : null}
              </div>
              {shipmentStops?.shipment_billing[0]?.groups && (
                <div className='dispatch-vehicle-details-item'>
                  <div
                    className='tablePlaner-shortName'
                    style={{
                      color: use(palette.gray900, palette.gray700),
                      backgroundColor: shipmentStops?.shipment_billing[0]?.groups?.color,
                    }}
                  >
                    {shipmentStops?.shipment_billing[0]?.groups?.short_name}
                  </div>
                </div>
              )}

              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>{shipmentStops?.shipment_stops_count} Stops</p>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <div className='d-flex justify-content-start}'>
                  <div className='tablePlaner_status-wrapper'>
                    <span
                      className='tablePlaner_status'
                      style={{
                        backgroundColor: getPlannerStatus(infoHeader)?.bgStatus,
                        color: getPlannerStatus(infoHeader)?.colorStatus,
                      }}
                    >
                      {getPlannerStatus(infoHeader)?.statusName}
                    </span>
                  </div>
                </div>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>
                  {currency} {formatAmount(totalDollar)}
                </p>
              </div>
              <div className='dispatch-vehicle-details-item'>
                <p className='dispatch-details-text'>{Number(data?.loaded_miles || 0).toFixed()} Miles</p>
              </div>
              <div className='plan-vehicle-details-item'>
                <p className='plan-details-text'>{getShipmentType(infoHeader?.shipment_type)}</p>
              </div>
              {infoHeader?.planned_duration && data?.deadhead_drive_time && (
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text d-flex'>
                    Route Duration:{' '}
                    <span className='cost-purple-text ms-2'>
                      {sumDurations([
                        data?.deadhead_drive_time,
                        data?.shipment_drive_time,
                        ...[
                          ...(infoHeader?.shipment_stops || []).map((item) =>
                            getAverageTimeConverted(item.stop_point?.average_waiting_time)
                          ),
                        ],
                      ])}
                    </span>
                  </p>
                  <Dropdown className='d-inline mx-2' autoClose='outside'>
                    <Dropdown.Toggle
                      id='dropdown-autoclose-outside'
                      style={{
                        border: 'none',
                        outline: 'none',
                        background: 'none',
                        boxShadow: 'none',
                        padding: 0,
                      }}
                    >
                      <img alt='down' src={down} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                      <Dropdown.Item style={{ color: use(palette.dark800, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span>Deadhead Drive Time:</span>{' '}
                          <span className='cost-purple-text ms-2'>{data?.deadhead_drive_time}</span>
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item style={{ color: use(palette.dark800, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span>Shipment Drive Time:</span>{' '}
                          <span className='cost-purple-text ms-2'>{data?.shipment_drive_time}</span>
                        </p>
                      </Dropdown.Item>
                      {infoHeader?.shipment_stops?.map((item, index) => (
                        <Dropdown.Item key={item.id} style={{ color: use(palette.dark800, palette.gray200) }}>
                          <p className='dispatch-details-text d-flex justify-content-between'>
                            <span>Stop {getAlphabet(index)} - Avg. Wait Time:</span>{' '}
                            <span className='cost-purple-text ms-2'>
                              {getAverageTimeConverted(item.stop_point?.average_waiting_time)}
                            </span>
                          </p>
                        </Dropdown.Item>
                      ))}

                      <Dropdown.Item style={{ color: use(palette.dark500, palette.gray200) }}>
                        <p className='dispatch-details-text d-flex justify-content-between'>
                          <span style={{ fontWeight: 'bold' }}>Total Duration:</span>{' '}
                          <span className='cost-purple-text ms-2'>
                            {sumDurations([
                              data?.deadhead_drive_time,
                              data?.shipment_drive_time,
                              ...[
                                ...(infoHeader?.shipment_stops || []).map((item) =>
                                  getAverageTimeConverted(item.stop_point?.average_waiting_time)
                                ),
                              ],
                            ])}
                          </span>
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {infoHeader?.scheduled_duration && (
                <div className='dispatch-vehicle-details-item'>
                  <p className='dispatch-details-text d-flex'>
                    Scheduled Duration:{' '}
                    <span className='cost-purple-text ms-2'>
                      {getAverageTimeConverted(infoHeader.scheduled_duration)}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='plan-accordion'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowUp className='accordion-arrow' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          />
          <AccordionDetails>
            <div className='w-100 mt-4'>
              <div>
                {loading ? (
                  <SkeletonStops />
                ) : (
                  <>
                    {legs.map((leg, index) => {
                      legsStopsCount.push(leg.stops.length);
                      return (
                        <div key={leg.id} className={classes.legCountContainer}>
                          <OverViewStop
                            leg={leg}
                            legs={legs}
                            setLegs={setLegs}
                            indexPrefix={index}
                            setIsOpen={setIsOpen}
                            infoHeader={infoHeader}
                            legsStopsCount={legsStopsCount}
                            onSelectPlus={setSelectModalType}
                            deleteStop={onDeleteStop}
                            onClickSettingsIcon={onClickItemSettingsIcon}
                            onUpdateSuccess={onUpdateSuccess}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className='w-100' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-table-container-dispatch'>
          <MaterialTableWrapper
            columns={[
              {
                title: 'Vehicle ID / Type',
                field: 'VehicleID',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
              {
                title: 'Trailer Id / Type',
                field: 'Trailer',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                  minWidth: '200px',
                },
              },
              {
                title: 'Carrier / Driver',
                field: 'Driver',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
              {
                title: 'Select Contact',
                field: 'Contact',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
              {
                title: 'Starting Location',
                field: 'StartingLocation',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
              {
                title: 'Empty MIles',
                field: 'EmptyMIles',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
              {
                title: 'Loaded Miles',
                field: 'LoadedMiles',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
              {
                title: 'ETA to stop a',
                field: 'ETA',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
              {
                title: 'Awarded Amount',
                field: 'Cost',
                headerStyle: {
                  borderTop: 'none',
                },
                cellStyle: {
                  verticalAlign: 'top',
                },
              },
            ]}
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              borderTop: 'none',
              width: '100%',
            }}
            data={[
              {
                VehicleID: (
                  <div className='d-flex flex-column gap-3'>
                    <Input
                      name='vehicle_id'
                      placeholder='Vehicle ID'
                      onChange={(e) => handleChange(`vehicle_id`, e.target.value)}
                      value={values.vehicle_id}
                    />
                    <div>
                      <Autocomplete
                        error={touchedErrors.vehicle_type_id}
                        required
                        name='vehicle_type_id'
                        labelKey='title'
                        options={vehicleList}
                        value={values.vehicle_type_id || null}
                        onChange={(e, value) => {
                          handleChange('vehicle_type_id', value);
                          if (value.id === 5 || value.id === 1) {
                            setTrailerDisabled(false);
                          } else {
                            setTrailerDisabled(true);
                          }
                          handleChange('trailer_id', '');
                          handleChange('trailer_length', null);
                          handleChange('trailer_type', null);
                        }}
                        style={{ width: 150 }}
                      />
                    </div>
                  </div>
                ),
                Trailer: (
                  <div className='d-flex flex-column gap-3'>
                    <Input
                      disabled={trailerDisabled}
                      name='trailer_id'
                      placeholder='Trailer ID'
                      onChange={(e) => {
                        handleChange(`trailer_id`, e.target.value);
                      }}
                      value={values.trailer_id}
                    />

                    <div className='d-flex gap-2'>
                      <Autocomplete
                        style={{ width: 180 }}
                        name='trailer_type'
                        labelKey='title'
                        options={trailerTypeList}
                        value={values.trailer_type || null}
                        disabled={trailerDisabled}
                        onChange={(e, value) => {
                          handleChange('trailer_type', value);
                          handleChange('trailer_length', null);
                          getDimensions(value.id);
                        }}
                      />

                      <Autocomplete
                        style={{ width: 100 }}
                        name='trailer_length'
                        getOptionLabel={(option) => {
                          return option
                            ? `${
                                option?.length && !option?.length_info ? option.length : option?.length_info?.length
                              } ${option?.unit && !option?.length_info ? option.unit : option?.length_info?.unit}`
                            : '';
                        }}
                        options={trailerLengthData}
                        value={values.trailer_length || null}
                        disabled={trailerDisabled}
                        onChange={(e, value) => {
                          handleChange('trailer_length', value);
                        }}
                      />
                    </div>
                  </div>
                ),
                Driver: (
                  <div className='d-flex flex-column gap-3'>
                    <div
                      style={{
                        height: '32px',
                        boxShadow: palette.boxShadow2,
                        borderRadius: 6,
                        padding: '6px 12px',
                        opacity: 0.6,
                      }}
                    >
                      <Typography variant='s3'>{carrier?.name}</Typography>
                    </div>
                    <Input
                      name='driver'
                      placeholder='Driver'
                      onChange={(e) => handleChange(`driver`, e.target.value)}
                      value={values.driver}
                    />
                    <PhoneInput
                      name='phone'
                      placeholder='+1 (555) 555-1234'
                      onChange={(val) => handleChange('phone', val)}
                      value={values.phone}
                      onlyCountries={['us', 'ca', 'mx']}
                      style={{ width: 180 }}
                      error={touchedErrors.phone}
                    />
                  </div>
                ),
                Contact: (
                  <div className='d-flex gap-3 align-items-center'>
                    <div>
                      <Autocomplete
                        error={touchedErrors.contact}
                        style={{ width: 180 }}
                        name='contact'
                        labelKey='contact_name'
                        options={customerContactBook}
                        value={values.contact || null}
                        onChange={(e, value) => {
                          handleChange('contact', value);
                        }}
                      />
                    </div>
                    <PLusIcon onClick={() => setAddActionOpen(true)} />
                  </div>
                ),
                StartingLocation: (
                  <SAddressWrapper>
                    <SFlexRow>
                      <div className='w-100'>
                        <Autocomplete
                          error={touchedErrors.state}
                          name='state'
                          placeholder='Select State..'
                          value={values.state}
                          onChange={(e, value) => {
                            handleChange('state', value);
                            handleChange('city', null);
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          options={states}
                          disableClearable={false}
                        />
                      </div>
                    </SFlexRow>
                    <SFlexRow>
                      <div className='w-100'>
                        <Autocomplete
                          error={touchedErrors.city}
                          name='city'
                          placeholder='Select City..'
                          value={values.city}
                          onChange={(e, value) => handleChange('city', value)}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          options={cities}
                          disableClearable={false}
                        />
                      </div>

                      <div>
                        <Typography variant='s3'>or</Typography>
                      </div>

                      <div className='w-100'>
                        <Input
                          error={touchedErrors.zip}
                          name='zip'
                          className='w-100'
                          placeholder='Zip Code'
                          onChange={handleChange}
                          value={values.zip}
                        />
                      </div>
                    </SFlexRow>
                  </SAddressWrapper>
                ),
                EmptyMIles: (
                  <Typography variant='s2'>
                    {data?.empty_miles ? Number(data.empty_miles).toFixed() : Number(infoHeader?.empty_miles).toFixed()}
                  </Typography>
                ),
                LoadedMiles: (
                  <Typography variant='s2'>
                    {data?.loaded_miles
                      ? Number(data.loaded_miles).toFixed()
                      : Number(infoHeader?.loaded_miles).toFixed()}
                  </Typography>
                ),
                ETA: <Typography variant='s2'>{data?.eta || '-'}</Typography>,
                Cost: (
                  <InputWithIcon
                    type='number'
                    error={touchedErrors.flat_rate}
                    style={{ width: 130 }}
                    placeholder='0.00'
                    value={values.flat_rate}
                    onChange={(e) => handleChange(`flat_rate`, e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                ),
              },
            ]}
            options={{
              paging: false,
              sorting: false,
              toolbar: false,
              rowStyle: {
                borderBottom: 'none',
                width: '100%',
                backgroundColor: use(palette.white, palette.dark800),
              },
              headerStyle: {
                borderTop: 'none',
                width: '100%',
                backgroundColor: use(palette.white, palette.dark800),
              },
            }}
          />
        </div>
      </div>
      <div className='dispatch-vehicle-content'>
        <div className='dispatch-vehicle-data'>
          <div>
            <form>
              <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Options
              </h6>
              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.immediately}
                  name='immediately'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('in_future', false);
                      handleChange('after_specific_shipment_complated', false);
                      handleChange('before_scheduled_pickup', false);
                    } else if (
                      !values.in_future &&
                      !values.after_specific_shipment_complated &&
                      !values.before_scheduled_pickup
                    ) {
                      return;
                    }
                    handleChange('immediately', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text'>Dispatch to Carrier Immediately</p>
              </div>
              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.after_specific_shipment_complated}
                  name='after_specific_shipment_complated'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('in_future', false);
                      handleChange('before_scheduled_pickup', false);
                    } else if (!values.immediately && !values.in_future && !values.before_scheduled_pickup) {
                      return;
                    }
                    handleChange('after_specific_shipment_complated', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text me-2'>
                  Pending Dispatch as Next Shipment in Sequential Date Order
                </p>
              </div>

              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2 w-100'>
                <CustomCheckbox
                  type='switch'
                  checked={values.in_future}
                  name='in_future'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('after_specific_shipment_complated', false);
                      handleChange('before_scheduled_pickup', false);
                    } else if (
                      !values.immediately &&
                      !values.after_specific_shipment_complated &&
                      !values.before_scheduled_pickup
                    ) {
                      return;
                    }
                    handleChange('in_future', checked);
                  }}
                />
                <p className='dispatch-vehicle-check-text me-2'>Pending Dispatch Queue for Dispatch on</p>
                <DateTimePicker
                  width='190px'
                  name='future_date'
                  value={values.future_date}
                  onChange={(value) => handleChange('future_date', value)}
                  onBlur={(e) => {
                    const { value } = e.target;
                    if (value && moment(value).isValid() && moment(value).isBefore(moment())) {
                      handleChange('future_date', new Date());
                    }
                  }}
                  disablePast
                />
                <p className='dispatch-vehicle-check-text'>(Based on Specific Date/Time)</p>
              </div>

              <div className='with-border mt-2' />
              <div className='d-flex align-items-center my-2'>
                <CustomCheckbox
                  type='switch'
                  checked={values.before_scheduled_pickup}
                  name='before_scheduled_pickup'
                  onChange={(checked) => {
                    if (checked) {
                      handleChange('immediately', false);
                      handleChange('in_future', false);
                      handleChange('after_specific_shipment_complated', false);
                    } else if (!values.immediately && !values.in_future && !values.after_specific_shipment_complated) {
                      return;
                    }
                    handleChange('before_scheduled_pickup', checked);
                  }}
                />
                <p className={`dispatch-vehicle-check-text ${matches ? 'nowrap' : ''}`}>
                  Pending Dispatch Queue for Dispatch
                </p>
                <div className='dispatch-vehicle-input' style={{ marginLeft: '0.5rem', marginTop: 0 }}>
                  <input
                    type='number'
                    placeholder='0'
                    name='before_scheduled_pickup_hours'
                    value={values.before_scheduled_pickup_hours}
                    onChange={handleChange}
                  />
                </div>
                <p className='dispatch-vehicle-check-text'>Hour(s) Prior to Scheduled Pickup</p>
              </div>
              <div className='with-border mt-2' />
              {!!user?.customer?.cellular_tracking && (
                <>
                  <div className='d-flex align-items-center my-2'>
                    <CustomCheckbox
                      type='switch'
                      checked={values.cellular_tracking}
                      name='cellular_tracking'
                      onChange={(checked) => handleChange('cellular_tracking', checked)}
                    />
                    <p className='dispatch-vehicle-check-text'>Request Cellular Tracking</p>
                  </div>
                  {values.cellular_tracking && (
                    <div className='d-flex flex-column gap-2'>
                      <div className='d-flex align-items-center gap-2'>
                        <Typography variant='button2'>Tracking Cost:</Typography>
                        <Typography variant='s2'>
                          ${formatNumber(Number(trackingCost) * calculateFullDays(shipmentPlannedDuration))} (Shipment{' '}
                          {infoHeader?.shipment_id} / Max {calculateFullDays(shipmentPlannedDuration)}{' '}
                          {calculateFullDays(shipmentPlannedDuration) > 1 ? 'Days' : 'Day'})
                        </Typography>
                      </div>
                      <Typography variant='button2'>
                        Terms and Conditions:{' '}
                        <Typography variant='s2'>
                          By clicking the request tracking button below, you approve Truckin Systems LLC to track your
                          carrier and bill you $2.00 per day which ever is sooner: for maximum{' '}
                          {calculateFullDays(shipmentPlannedDuration)}{' '}
                          {calculateFullDays(shipmentPlannedDuration) > 1 ? 'days' : 'day'} or if shipment is completed.
                          You understand that the tracking uses cellular device data fields, cell tower data, and WiFi
                          access point array data to return latitude/longitude coordinates every minute.
                          <br />
                          There are no refunds if tracking is stopped after it was started as we only initiate billing
                          once driver starts tracking.
                        </Typography>
                      </Typography>
                    </div>
                  )}
                  <div className='with-border mt-2' />
                </>
              )}
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    type='switch'
                    checked={values.add_shipment_notes}
                    name='add_shipment_notes'
                    onChange={(checked) => {
                      handleChange('add_shipment_notes', checked);
                    }}
                  />
                  <p className='dispatch-vehicle-check-text' style={{ marginLeft: '1rem', marginTop: 0 }}>
                    Confirmation Notes
                  </p>
                </div>
                <div className='d-flex align-items-center'>
                  <div style={{ width: '28px', marginRight: '1rem' }} />
                  <div className='w-100'>
                    <Textarea
                      rows={4}
                      placeholder='Write your notes here...'
                      name='shipment_notes'
                      value={values.shipment_notes}
                      onChange={handleChange}
                      error={touchedErrors.description}
                      disabled={!values.add_shipment_notes}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            </form>

            <div className='with-border mt-2' />
            <div>
              <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Cost Estimate
              </h6>
              <div className='with-border mt-2' />
              <div className='dispatch-vehicle-cost'>
                <div>
                  <p className='dispatch-vehicle-cost-text'>Carrier Costs</p>
                  <p className='dispatch-vehicle-cost-text cost-bold-text'>Estimated Costs</p>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {formatAmount(values.flat_rate)}
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-bold-text'>
                    {currency}
                    {formatAmount(+values.flat_rate)}
                  </p>
                </div>
              </div>
            </div>
            <div className='with-border mt-2' />
            <div>
              <h6 className='username mt-4' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
                Net Profit
              </h6>
              <div className='with-border mt-2' />
              <div className='dispatch-vehicle-cost'>
                <div>
                  <p className='dispatch-vehicle-cost-text'>
                    Gross Shipment Charges{' '}
                    <span className='profit-gray-text'>
                      {currency}
                      {formatAmount(
                        Number(data?.total_amount) / (Number(data?.empty_miles) + Number(data?.loaded_miles))
                      )}{' '}
                      /MILE EST. GROSS
                    </span>
                  </p>
                  <p className='dispatch-vehicle-cost-text'>
                    Estimated Shipment Costs{' '}
                    <span className='profit-red-text'>
                      {currency}
                      {formatAmount(
                        Number(values.flat_rate) / (Number(data?.empty_miles) + Number(data?.loaded_miles))
                      )}{' '}
                      /MILE EST. COST
                    </span>
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-green-text'>
                    Estimated Net Profit{' '}
                    <span className='profit-green-text'>
                      {currency}
                      {formatAmount(
                        (Number(data?.total_amount) - Number(values.flat_rate)) /
                          (Number(data?.empty_miles) + Number(data?.loaded_miles))
                      )}{' '}
                      /MILE EST. NET
                    </span>
                  </p>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {formatAmount(data?.total_amount)}
                  </p>
                  <p className='dispatch-vehicle-cost-text'>
                    {currency}
                    {formatAmount(+values.flat_rate)}
                  </p>
                  <p className='dispatch-vehicle-cost-text cost-green-text'>
                    {currency}
                    {formatAmount(Number(data?.total_amount) - +values.flat_rate)}
                  </p>
                </div>
              </div>
            </div>
            <div className='with-border mt-2' />
            <div className='d-flex justify-content-end mt-4 mb-4'>
              <CustomButton
                type='secondary'
                title='Back'
                styleButton={{ padding: '6px 12px', marginRight: '18px' }}
                onClick={() => navigate(-1)}
              />
              <CustomButton
                type='primary'
                title='Preview'
                styleButton={{ padding: '6px 12px', marginRight: '18px' }}
                onClick={viewConfirmation}
              />
              <CustomButton
                type='primary'
                title='Email Confirmation & Dispatch'
                leftIcon={
                  loadingDispatch ? (
                    <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                  ) : (
                    <ShipmentIcon style={{ marginRight: 10 }} />
                  )
                }
                disabled={loadingDispatch}
                styleButton={{ padding: '6px 12px' }}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className='dispatch-vehicle-map'>
          <h6 className='username' style={{ color: use(palette.gray900, palette.gray50), fontSize: 18 }}>
            Route
          </h6>
          <div className='with-border mt-2' />
          <div className='d-flex justify-content-between align-items-center my-2'>
            <div className='d-flex align-items-center'>
              <p className='dispatch-vehicle-check-text' style={{ marginLeft: 0, textTransform: 'capitalize' }}>
                {routeSelect}
              </p>
              <Dropdown className='d-inline mx-2'>
                <Dropdown.Toggle
                  // className="AvailableButton btn btn-link"
                  id='dropdown-autoclose-outside'
                  style={{
                    border: 'none',
                    outline: 'none',
                    background: 'none',
                    boxShadow: 'none',
                    marginLeft: '0',
                    paddingLeft: '10x',
                  }}
                >
                  <img src={down} alt='down' />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: use(palette.white, palette.dark800) }}>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                    onClick={() => handleRouteSelect('practical')}
                  >
                    Practical
                  </Dropdown.Item>

                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => handleRouteSelect('shortest')}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Shortest
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='outofserviceDropDownItemsList'
                    onClick={() => handleRouteSelect('fastest')}
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    Fastest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <div className='d-flex align-items-center mb-1'>
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem', marginLeft: 0 }}>
                  Open Borders
                </p>
                <CustomCheckbox
                  checked={!!mapValues.open_borders}
                  onChange={(checked) => handleMapValuesChange('open_borders', checked)}
                />
                <p className='dispatch-vehicle-check-text' style={{ marginRight: '0.5rem' }}>
                  Highway Only
                </p>
                <CustomCheckbox
                  checked={!!mapValues.hwy_only}
                  onChange={(checked) => handleMapValuesChange('hwy_only', checked)}
                />
                <p className='dispatch-vehicle-check-text'>HazMat</p>
                <CustomCheckbox
                  checked={!!mapValues.haz_mat}
                  disabled
                  onChange={(checked) => handleMapValuesChange('haz_mat', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
                <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                <CustomCheckbox
                  checked={!!mapValues.avoid_tolls}
                  onChange={(checked) => handleMapValuesChange('avoid_tolls', checked)}
                  style={{ marginLeft: '0.5rem' }}
                />
              </div>
            </div>
          </div>
          <div style={style.mapWrapper}>
            {shipmentStops?.shipment_stops.length && mapValues && infoHeader && (
              <DispatchCarrierMap
                data={shipmentStops?.shipment_stops}
                mapValues={mapValues}
                empty_miles={data?.empty_miles}
                customLatLong={customLatLong}
              />
            )}
          </div>
        </div>
      </div>
      <SettingsModal isOpen={isOpenSettingsModal} setIsOpen={setIsOpenSettingsModal} onUpdate={onUpdateSettings} />
      {!!isOpen?.open && (
        <AddEditModalDetails
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={selectModalType}
          setType={setSelectModalType}
          isShipmentTable={false}
          onAdd={onAddEditModalDetails}
          onUpdateSuccess={getStopsData}
          shipmentId={shipmentId}
          legs={legs}
        />
      )}
      <AlreadyDispatchedModal
        open={!!alreadyDispatched}
        text={alreadyDispatched}
        onClose={() => setAlreadyDispatched(null)}
      />

      {addActionOpen && (
        <ContactBook
          open={addActionOpen}
          onClose={() => {
            setAddActionOpen(false);
            getContacts();
          }}
        />
      )}
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </div>
  );
}
