import { string, object, number } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  status: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  account: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  category: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),

  date: validator.pastRequiredDate(),
  vendor: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  unit_price: number().required('Unit price is required'),
  gallons: string().trim().required('Required'),
  transaction_id: string().trim().required('Required'),
});
