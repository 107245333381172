import React from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import CountInput from 'common/CountInput';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { formatAmount } from 'utils/helpers';

const Hardware = ({ hardware, prices, onQuantityChange, onDelete }) => {
  const hardwarePrice = prices?.hardware_price?.[hardware.id];
  const amount = (hardwarePrice?.amount || 0) + (hardwarePrice?.discount_value || 0);

  return (
    <div className='summary-item'>
      <DeleteIcon className='remove-cart-item-icon' fill={palette.red500} onClick={() => onDelete(hardware)} />
      <div className='summary-item-image'>
        <img src={hardware?.images[0]?.image} alt='img' width={60} height={60} />
      </div>
      <div className='summary-item-body'>
        <div className='d-flex flex-column gap-1'>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {hardware?.name}
          </Typography>
          <Typography variant='c1' style={{ color: palette.indigo500 }}>
            ${formatAmount(Number(hardware?.price || 0))}
            {hardware?.license === 1
              ? `/month /unit for 36 months license`
              : hardware?.license === 0
              ? `/one time purchase /unit`
              : ``}
          </Typography>
        </div>
      </div>
      <div className='summary-item-amount'>
        <CountInput value={hardware.quantity} handleChange={(val) => onQuantityChange(hardware, val)} minValue={1} />
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          ${formatAmount(amount)}
        </Typography>
      </div>
    </div>
  );
};

export default Hardware;
