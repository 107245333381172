import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { ReactComponent as DetentionIcon } from 'assets/icons/detention.svg';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as WarningIcon } from 'assets/icons/warningOutline.svg';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import Modal from 'common/Modal';
import Input from 'common/Input';
import ViewPdf from 'components/ViewPdf';
import DatePicker from 'common/DatePicker';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { getAppUrl } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import { CUSTOMER_CONTACT_TYPE, formatNumber, palette, STRIPE_MODULE } from 'utils/constants';
import { SBackdrop } from 'pages/Accounting/Receivables/Receivables.styles';
import { generatePDF } from 'pages/Reports/ReportDetails/CustomerStatement/generatePdf';
import PaymentMethodCard from 'pages/Accounting/Receivables/TableSection/shared/PaymentMethodCard';
import { convertTransactionsData } from 'pages/Reports/ReportDetails/CustomerStatement/CustomerStatement.data';
import { getCustomerTransactions } from 'Api/Reports';
import { getInvoiceId, processInvoice } from 'Api/AccountingReceivables';
import InputLabel from 'common/InputLabel';
import Divider from 'common/Divider';
import AddCustomerContact from '../AddCustomerContact';
import { generateBatchPDF } from '../GenerateStandaloneInvoice/batchStatement';
import { generateStandaloneInvoice, getMergedUrl } from '../GenerateStandaloneInvoice';
import { validationSchema } from '../SendInvoice/validationSchema';
import {
  addOrDeletePaymentMethod,
  getPaymentMethodsCombinedValue,
  onDownload,
  paymentMethods,
  urlToBlob,
  getInvoiceDueDate,
} from '../SendInvoice/SendInvoice.data';
import {
  initialValues,
  useColumns,
  groupInvoicesByCustomer,
  useUniqueCustomerColumns,
  getUniqueCustomersFromInvoices,
} from './SendOtherInvoice.data';
import { SWrapper } from './SendOtherInvoice.styles';

const SendOtherInvoice = ({
  open,
  companyProfile,
  accountingSettings,
  brokerageSettings,
  instructionsBrokerage,
  instructions,
  data,
  onClose,
  onSuccess,
}) => {
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const { formatDate } = useDateFormat();
  const hasBrokerage = useHasBrokerage();
  const { company_name, dot } = companyProfile || {};
  const { company_name: brokerageCompanyName } = brokerageSettings || {};
  const uniqueCustomers = getUniqueCustomersFromInvoices(data);
  const { currency } = useSelector((state) => state.root);
  const { stripeModuleData } = useSelector((state) => state.stripe);
  const [tableData, setTableData] = useState(data);
  const [openViewInvoices, setOpenViewInvoices] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const totalAmount = data.reduce((sum, item) => sum + item.total_amount, 0);
  const [loading, setLoading] = useState(false);
  const [loadingCreateInvoices, setLoadingCreateInvoices] = useState(false);
  const [customerToAddContact, setCustomerToAddContact] = useState(null);
  const attemptCountRef = useRef(0);

  const groupedInvoices = useMemo(() => {
    return groupInvoicesByCustomer(tableData);
  }, [tableData]);

  const generateInvoices = async (stripePaymentLink = '', groupedInvoices = null, index = null, tableData, values) => {
    setLoadingCreateInvoices(true);
    const batchStatement = [];
    const customerStatements = [];

    try {
      const promises = (groupedInvoices?.invoices || tableData).map(async (inv) => {
        return generateStandaloneInvoice({
          companyProfile:
            values.payable_to === 'brokerage'
              ? {
                  ...companyProfile,
                  company_name: brokerageSettings.company_name,
                  company_logo: brokerageSettings.logo,
                  phone_number: brokerageSettings.phone_number,
                  address1: brokerageSettings.address1,
                  address2: brokerageSettings.address2,
                  city_id: brokerageSettings.city,
                  state_id: brokerageSettings.state,
                  country_id: brokerageSettings.country,
                  zip: brokerageSettings.zipcode,
                }
              : companyProfile,
          invoiceData: inv,
          accountingSettings:
            values.payable_to === 'brokerage'
              ? {
                  ...accountingSettings,
                  show_billing: true,
                  website: brokerageSettings.website,
                  show_website: brokerageSettings.show_website,
                  receivable_email: brokerageSettings.receivable_email,
                }
              : accountingSettings,
          instructions: values.payable_to === 'brokerage' ? instructionsBrokerage : instructions,
          payable_to: values.payable_to,
          email_to: groupedInvoices ? values.batchEmailTo?.[index] : values.email_to,
          send_email: values.send_email,
          payment_method: values.payment_method,
          attach_noa_letter: values.attach_noa_letter,
          stripePaymentLink,
          currency,
          formatDate,
        });
      });

      if ((groupedInvoices?.invoices?.length || tableData.length) > 1) {
        const res = generateBatchPDF({
          companyProfile:
            values.payable_to === 'brokerage'
              ? {
                  ...companyProfile,
                  company_name: brokerageSettings.company_name,
                  company_logo: brokerageSettings.logo,
                  phone_number: brokerageSettings.phone_number,
                  address1: brokerageSettings.address1,
                  address2: brokerageSettings.address2,
                  city_id: brokerageSettings.city,
                  state_id: brokerageSettings.state,
                  country_id: brokerageSettings.country,
                  zip: brokerageSettings.zipcode,
                }
              : companyProfile,
          accountingSettings:
            values.payable_to === 'brokerage'
              ? {
                  ...accountingSettings,
                  show_billing: true,
                  website: brokerageSettings.website,
                  show_website: brokerageSettings.show_website,
                  receivable_email: brokerageSettings.receivable_email,
                }
              : accountingSettings,
          instructions: values.payable_to === 'brokerage' ? instructionsBrokerage : instructions,
          tableData: groupedInvoices?.invoices || tableData,
          batchId: groupedInvoices ? values.batchIds?.[index] : values.batchIds.find((batchId) => !!batchId) || '',
          payable_to: values.payable_to,
          payment_method: values.payment_method,
          stripePaymentLink,
          total_amount: groupedInvoices?.totalAmount || totalAmount,
          billDate: values.bill_date,
          batch_due_date: groupedInvoices ? values.batchDueDates?.[index] : values.batch_due_date,
          send_to_company:
            values.payable_to === 'factoring_company'
              ? { company_name: accountingSettings.factoring_company }
              : groupedInvoices?.customer || tableData[0].customer,
          currency,
          formatDate,
        });
        batchStatement.push(res.url);
      }

      if (values.attach_statement) {
        if (values.payable_to === 'factoring_company') {
          const transactionRequests = uniqueCustomers.map(async (customer) => {
            return getCustomerTransactions(customer.id, {
              filter: 'outstanding',
              itemsPerPage: 10000,
            });
          });

          const responses = await Promise.all(transactionRequests);
          uniqueCustomers.forEach((customer, i) => {
            const selectedFilters = { customer, filter: 'outstanding' };

            const { url } = generatePDF({
              selectedFilters,
              companyProfile,
              accountingSettings,
              sortedTransactions: convertTransactionsData(responses[i]?.data),
              currency,
              formatDate,
            });
            customerStatements.push(url);
          });
        } else {
          const { data } = await getCustomerTransactions(groupedInvoices.customer.id, {
            filter: 'outstanding',
            itemsPerPage: 10000,
          });
          const selectedFilters = { customer: groupedInvoices.customer, filter: 'outstanding' };

          const { url } = generatePDF({
            selectedFilters,
            companyProfile,
            accountingSettings,
            sortedTransactions: convertTransactionsData(data),
            currency,
            formatDate,
          });
          customerStatements.push(url);
        }
      }

      const results = await Promise.all(promises);
      const requestData = [];

      if (groupedInvoices) {
        setTableData((prevState) =>
          prevState.map((item, index) => {
            return { ...item, invoicePdf: results[index] };
          })
        );
        groupedInvoices.invoices.forEach((item, index) => {
          const data = { ...item, invoicePdf: results[index] };
          requestData.push(data);
        });
      } else {
        setTableData((prevState) =>
          prevState.map((item, index) => {
            const data = { ...item, invoicePdf: results[index] };
            requestData.push(data);
            return data;
          })
        );
      }

      const docs = [...batchStatement, ...results, ...customerStatements];

      if (values.attach_fti) {
        if (values.payable_to === 'brokerage') {
          docs.push(instructionsBrokerage.fti_doc);
        } else {
          docs.push(instructions.fti_doc);
        }
      }

      const mergedUrl = await getMergedUrl(
        docs,
        groupedInvoices ? values.batchIds?.[index] : values.batchIds.find((batchId) => !!batchId) || ''
      );

      return { mergedUrl, requestData };
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      setLoadingCreateInvoices(false);
    }
  };

  const processOtherInvoice = async (values, customerInvoices, allInvoices) => {
    if (attemptCountRef.current > 5) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
      return;
    }
    setLoading(true);
    const unprocessedInvoices = [];
    try {
      if (values.payable_to === 'factoring_company') {
        const batchId = values.batchIds.find((batchId) => !!batchId) || '';
        let stripePaymentLink;

        const url = getAppUrl();

        if (values.payment_method.includes('online')) {
          stripePaymentLink = `${url}/${dot}/p/${batchId || allInvoices[0].invoice_id}/${batchId ? '1' : '0'}`;
        }

        const { mergedUrl, requestData } = await generateInvoices(stripePaymentLink, null, null, allInvoices, values);
        const file = await urlToBlob(mergedUrl);
        const promises = requestData.map((el) => urlToBlob(el.invoicePdf));
        const standaloneFiles = await Promise.all(promises);

        const formData = new FormData();
        formData.append('payment_method', getPaymentMethodsCombinedValue(values.payment_method));
        formData.append('payable_to', values.payable_to);
        formData.append('delivery_option', values.delivery_option);

        if (batchId) {
          formData.append('batch_id', batchId);
          formData.append('total_due', totalAmount);
          formData.append('batch_due_date', moment(values.batch_due_date).format('YYYY-MM-DD'));
          formData.append('bill_date', moment(values.bill_date).format('YYYY-MM-DD'));
        } else {
          formData.append('bill_date', moment(requestData[0].customer_billed_date).format('YYYY-MM-DD'));
        }

        if (values.send_email) {
          formData.append('email_to[]', accountingSettings.factor_email);
        }

        requestData.forEach((el, i) => {
          formData.append('customer_id[]', el.customer.id);
          formData.append(`invoice_id[${i}]`, el.invoice_id);
          formData.append('due_date[]', moment(el.due_date).format('YYYY-MM-DD'));
          formData.append('standalone_pdf[]', standaloneFiles[i]);
        });

        formData.append('invoice_pdf', file);

        if (stripePaymentLink) {
          formData.append('stripe_payment_link', stripePaymentLink);
        }

        await processInvoice(formData);
        if (values.print_mail) {
          onDownload(file, batchId ? `Invoice-${batchId}` : `Invoice-${requestData[0].invoice_id}`);
        }
        onClose();
        navigate(`/accounting/receivables?tab=open_invoices`);
        onSuccess();
        showToaster({ type: 'success', message: 'Invoice has been successfully processed!' });
      } else {
        const promises = customerInvoices.map(async (inv, index) => {
          let stripePaymentLink;

          const url = getAppUrl();

          if (values.payment_method.includes('online')) {
            stripePaymentLink = `${url}/${dot}/p/${values.batchIds?.[index] || inv.invoices[0].invoice_id}/${
              values.batchIds?.[index] ? '1' : '0'
            }`;
          }

          const { mergedUrl, requestData } = await generateInvoices(stripePaymentLink, inv, index, null, values);
          const file = await urlToBlob(mergedUrl);
          const promises = requestData.map((el) => urlToBlob(el.invoicePdf));
          const standaloneFiles = await Promise.all(promises);

          const formData = new FormData();
          formData.append('payment_method', getPaymentMethodsCombinedValue(values.payment_method));
          formData.append('payable_to', values.payable_to);
          formData.append('delivery_option', values.delivery_option);

          if (values.batchIds?.[index]) {
            formData.append('batch_id', values.batchIds?.[index]);
            formData.append('total_due', inv.totalAmount);
            formData.append('batch_due_date', moment(values.batchDueDates?.[index]).format('YYYY-MM-DD'));
            formData.append('bill_date', moment(values.bill_date).format('YYYY-MM-DD'));
          } else {
            formData.append('bill_date', moment(requestData[0].customer_billed_date).format('YYYY-MM-DD'));
          }

          if (values.send_email) {
            values.batchEmailTo?.[index]?.forEach((emailTo) => {
              formData.append('email_to[]', emailTo.contact_email);
            });
            formData.append('customer_customer_id', requestData[0].customer.id);
          }

          if (values.print_mail) {
            formData.append('mail_to_id', requestData[0].customer.id);
          }

          requestData.forEach((el, i) => {
            formData.append('customer_id[]', el.customer.id);
            formData.append(`invoice_id[${i}]`, el.invoice_id);
            formData.append('due_date[]', moment(el.due_date).format('YYYY-MM-DD'));
            formData.append('standalone_pdf[]', standaloneFiles[i]);
          });

          formData.append('invoice_pdf', file);

          if (stripePaymentLink) {
            formData.append('stripe_payment_link', stripePaymentLink);
          }

          if (values.print_mail) {
            onDownload(
              file,
              values.batchIds?.[index] ? `Invoice-${values.batchIds?.[index]}` : `Invoice-${requestData[0].invoice_id}`
            );
          }
          return processInvoice(formData);
        });

        const responses = await Promise.allSettled(promises);
        const batchDueDates = [];
        const batchEmailTo = [];

        for (let index = 0; index < responses.length; index++) {
          const res = responses[index];
          if (
            res.reason?.response?.data?.message?.some(
              (message) => message?.includes('invoice_id') && message?.includes(`has already been taken`)
            )
          ) {
            showToaster({
              type: 'error',
              message: `One or more of selected invoices have already been processed, please select available invoices!`,
            });
            setLoading(false);
            onSuccess();
            onClose();
            return;
          }

          if (res.reason?.message?.includes('There is an issue with one of your uploaded documents')) {
            showToaster({
              type: 'error',
              message: res.reason?.message,
            });
            setLoading(false);
            return;
          }

          if (
            res.reason?.response?.data?.message?.some(
              (message) => message?.includes('batch id') && message?.includes(`has already been taken`)
            )
          ) {
            unprocessedInvoices.push(customerInvoices[index]);
            batchDueDates.push(values.batchDueDates[index]);
            batchEmailTo.push(values.batchEmailTo[index]);
          }
        }

        if (unprocessedInvoices.length) {
          attemptCountRef.current += 1;
          const invoicesArray = unprocessedInvoices.flatMap((el) => el.invoices);

          const { invoiceBatchId } = await getInvoiceId({
            billing_id: invoicesArray.map((el) => el.invoice_id),
            batch_count: groupedInvoices.filter((el) => el.invoices.length > 1).length,
            is_standalone: 1,
          });

          processOtherInvoice(
            { ...values, batchIds: invoiceBatchId, batchDueDates, batchEmailTo },
            unprocessedInvoices,
            allInvoices
          );
          return;
        }
        setLoading(false);

        onClose();
        navigate(`/accounting/receivables?tab=open_invoices`);
        onSuccess();
        showToaster({ type: 'success', message: 'Invoice has been successfully processed!' });
      }
    } catch (e) {
      const message = getErrorMessage(e);
      if (message?.includes('invoice_id') && message?.includes(`has already been taken`)) {
        showToaster({
          type: 'error',
          message: `One or more of selected invoices have already been processed, please select available invoices!`,
        });
        setLoading(false);
        onSuccess();
        onClose();
        return;
      }

      if (message?.includes('batch id') && message?.includes(`has already been taken`)) {
        attemptCountRef.current += 1;
        const { invoiceBatchId } = await getInvoiceId({
          billing_id: data.map((el) => el.invoice_id),
          batch_count: groupedInvoices.filter((el) => el.invoices.length > 1).length,
          is_standalone: 1,
        });
        processOtherInvoice({ ...values, batchIds: invoiceBatchId }, customerInvoices, allInvoices);
      } else {
        setLoading(false);
        showToaster({ type: 'error', message });
      }
    }
  };

  const onViewInvoices = async (invoice, index) => {
    setLoadingCreateInvoices(true);
    try {
      const { mergedUrl } = await generateInvoices(null, invoice, index, tableData, values);
      setInvoiceUrl(mergedUrl);
      setOpenViewInvoices(true);
    } catch (e) {
      console.log(e);
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingCreateInvoices(false);
    }
  };

  const onPaymentMethodChange = (value) => {
    const newPaymentMethods = addOrDeletePaymentMethod(values.payment_method, value, values.payable_to);
    setValues({ ...values, payment_method: newPaymentMethods });
  };

  const setInvoiceDueDate = () => {
    handleChange(
      'batchDueDates',
      groupedInvoices.map((item) =>
        getInvoiceDueDate(item.customer?.customer_payment_term?.invoice_pay_term, values.bill_date)
      )
    );
  };

  const onDateChange = (id, value) => {
    setTableData((prevState) => prevState.map((i) => (i.id === id ? { ...i, due_date: value } : i)));
  };

  const onBatchDueDateChange = (index, val, customerId) => {
    handleChange(`batchDueDates[${index}]`, val);
    setTableData((prevState) =>
      prevState.map((i) =>
        i.customer.id === customerId
          ? {
              ...i,
              due_date: val,
            }
          : i
      )
    );
  };

  const updateCustomerContacts = (id, contactBook) => {
    setTableData((prevState) =>
      prevState.map((i) =>
        i.customer.id === id
          ? {
              ...i,
              customer: {
                ...i.customer,
                customer_contact_book: contactBook,
              },
            }
          : i
      )
    );
  };

  const { values, handleSubmit, handleChange, touchedErrors, isValid, setValues } = useForm({
    initialValues,
    validationSchema,
    onSubmit: (values) => processOtherInvoice(values, groupedInvoices, tableData),
  });

  const getInvoiceIds = async () => {
    try {
      if (tableData.length > 1) {
        handleChange('is_batch', true);
      }
      if (accountingSettings.is_factoring === 0) {
        handleChange('payable_to', 'own_company');
      }
      if (
        tableData.length === 1 &&
        !!tableData[0].customer?.customer_payment_term?.is_brokerage_customer &&
        !!hasBrokerage
      ) {
        handleChange('payable_to', 'brokerage');
      }

      const { invoiceBatchId } = await getInvoiceId({
        billing_id: data.map((el) => el.invoice_id),
        batch_count: groupedInvoices.filter((el) => el.invoices.length > 1).length,
        is_standalone: 1,
      });

      if (invoiceBatchId) {
        const batches = [];
        let index = 0;
        groupedInvoices.forEach((item) => {
          if (item.invoices.length > 1) {
            batches.push(invoiceBatchId[index]);
            index++;
          } else {
            batches.push(null);
          }
        });

        if (batches.find((el) => !!el)) {
          handleChange('batchIds', batches);
        } else {
          handleChange('batchIds', [invoiceBatchId[0]]);
        }
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    setInvoiceDueDate();
  }, [values.bill_date]);

  useEffect(() => {
    getInvoiceIds();
    handleChange('payment_method', []);
    handleChange(
      'batchEmailTo',
      groupedInvoices.map((item) => item?.customer?.customer_contact_book?.filter((el) => el?.invoicing))
    );
  }, []);

  const columns = useColumns({ onDateChange });
  const uniqueCustomerColumns = useUniqueCustomerColumns({ onDateChange });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Send Invoice'
      className='modified-scrollbar'
      $bgColor={palette.gray0}
      $maxWidth='900px'
      $minWidth='900px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        ...(values.payable_to === 'factoring_company'
          ? [
              {
                key: 'view',
                type: 'secondary',
                title: 'Preview',
                onClick: () => onViewInvoices(),
                disabled: loading || loadingCreateInvoices || !isValid,
              },
            ]
          : []),
        {
          key: 'submit',
          type: 'primary',
          title: 'Process Invoice',
          onClick: handleSubmit,
          disabled: loading || loadingCreateInvoices,
        },
      ]}
    >
      <SWrapper>
        {tableData.length > 1 && (
          <div className='d-flex align-items-center justify-content-between w-75'>
            <div>
              <DatePicker
                name='bill_date'
                label='Bill Date'
                size='small'
                width={200}
                value={values.bill_date}
                onChange={(val) => handleChange('bill_date', val)}
                style={{ width: '150px', marginTop: '8px' }}
                dateFormat='MM/dd/yyyy'
                required
              />
            </div>
            {values.payable_to === 'factoring_company' && (
              <div>
                <Input
                  label='Send To:'
                  width='200px'
                  size='small'
                  value={accountingSettings.factoring_company}
                  disabled
                />
              </div>
            )}
            {values.payable_to === 'factoring_company' && (
              <div>
                <DatePicker
                  name='batch_due_date'
                  label='Batch Payment Due Date'
                  size='small'
                  width={200}
                  value={values.batch_due_date}
                  onChange={(val) => handleChange('batch_due_date', val)}
                  style={{ width: '150px', marginTop: '8px' }}
                  minDate={Date.now()}
                  dateFormat='MM/dd/yyyy'
                  required
                />
                <ErrorMessage error={touchedErrors.batch_due_date} />
              </div>
            )}
          </div>
        )}
        <div>
          <InputLabel required variant='button2' style={{ marginTop: '10px' }}>
            {data.length > 1 ? 'Requested Batch Payment Method ' : 'Requested Payment Method '}
          </InputLabel>
          <Divider margin='8px 0 16px' />
          <div className='payment-methods-wrapper'>
            {paymentMethods.map((item) => (
              <PaymentMethodCard
                key={item.title}
                cardData={item}
                isSelected={values.payment_method.includes(item.value)}
                onClick={() => onPaymentMethodChange(item.value)}
                isFactor={values.payable_to === 'factoring_company'}
                disabled={
                  (item.value === 'online' &&
                    (!stripeModuleData?.[STRIPE_MODULE.ACCOUNTS_RECEIVABLES]?.status ||
                      values.payable_to === 'factoring_company')) ||
                  (values.payable_to !== 'brokerage' && item.value === 'ach' && !instructions?.ach_enabled) ||
                  (values.payable_to !== 'brokerage' && item.value === 'wire' && !instructions?.wire_enabled) ||
                  (values.payable_to === 'brokerage' && item.value === 'ach' && !instructionsBrokerage?.ach_enabled) ||
                  (values.payable_to === 'brokerage' && item.value === 'wire' && !instructionsBrokerage?.wire_enabled)
                }
              />
            ))}
          </div>
          <ErrorMessage error={touchedErrors.payment_method} />
        </div>

        <div>
          <InputLabel required variant='button2' style={{ marginTop: '10px' }}>
            Payable To
          </InputLabel>
          <Divider margin='8px 0 16px' />
          <div className='d-flex gap-4'>
            <CustomRadioButton
              field={{
                name: 'payable_to',
                value: 'own_company',
                onChange: () => {
                  setValues({
                    ...values,
                    email_to: [],
                    payable_to: 'own_company',
                    payment_method: [],
                    attach_fti: false,
                  });
                },
                checked: values.payable_to === 'own_company',
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                {company_name}
              </Typography>
            </CustomRadioButton>
            {!!hasBrokerage && !!brokerageCompanyName && (
              <CustomRadioButton
                field={{
                  name: 'payable_to',
                  value: 'brokerage',
                  onChange: () => {
                    setValues((prevState) => ({
                      ...prevState,
                      email_to: [],
                      payable_to: 'brokerage',
                      payment_method: [],
                      attach_fti: false,
                    }));
                  },
                  checked: values.payable_to === 'brokerage',
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  {brokerageCompanyName}
                </Typography>
              </CustomRadioButton>
            )}
            {!!accountingSettings.is_factoring && (
              <CustomRadioButton
                field={{
                  name: 'payable_to',
                  value: 'factoring_company',
                  onChange: () => {
                    setValues({
                      ...values,
                      email_to: [{ contact_email: accountingSettings.factor_email }],
                      payable_to: 'factoring_company',
                      payment_method: [],
                      attach_fti: false,
                    });
                  },
                  checked: values.payable_to === 'factoring_company',
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  Factor
                </Typography>
              </CustomRadioButton>
            )}
          </div>
          <ErrorMessage error={touchedErrors.payable_to} />
        </div>
        <div className='d-flex flex-column gap-3'>
          <div className='additional-option-header'>
            <Typography variant='button2'>Additional Options</Typography>
          </div>
          <div className='additional-options'>
            <CustomCheckbox
              name='attach_statement'
              checked={!!values.attach_statement}
              onChange={(checked) => handleChange('attach_statement', checked)}
            >
              <Typography variant='s2' style={{ marginLeft: '8px' }}>
                Attach Statement (Outstanding Only)
              </Typography>
            </CustomCheckbox>
            {((values.payable_to !== 'brokerage' && !!instructions?.fti_doc) || !!instructionsBrokerage?.fti_doc) && (
              <CustomCheckbox
                name='attach_fti'
                checked={!!values.attach_fti}
                onChange={(checked) => handleChange('attach_fti', checked)}
              >
                <Typography variant='s2' style={{ marginLeft: '8px' }}>
                  Attach Funds Transfer Instructions Cover Sheet
                </Typography>
              </CustomCheckbox>
            )}
            {!!accountingSettings.is_factoring && !!accountingSettings.notice_letter && (
              <CustomCheckbox
                name='attach_noa_letter'
                checked={!!values.attach_noa_letter}
                onChange={(checked) => handleChange('attach_noa_letter', checked)}
              >
                <Typography variant='s2' style={{ marginLeft: '8px' }}>
                  Attach Notice of Assignment Letter (NOA) to each invoice
                </Typography>
              </CustomCheckbox>
            )}
          </div>
        </div>
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex flex-column gap-1'>
            <Typography variant='button2'>Delivery Options</Typography>
            {values.payable_to === 'factoring_company' && (
              <div className='d-flex align-items-center justify-content-start gap-2'>
                <WarningIcon />
                <Typography variant='b2' style={{ color: palette.orange500 }}>
                  Notice of Assignment message will automatically appear on each invoice.
                </Typography>
              </div>
            )}
            <ErrorMessage error={touchedErrors.delivery_option} />
          </div>
          <div className='delivery-option'>
            <div>
              <CustomCheckbox
                name='send_email'
                checked={!!values.send_email}
                onChange={(checked) => {
                  setValues((prevState) => ({
                    ...prevState,
                    send_email: checked,
                    delivery_option: prevState.delivery_option + (checked ? 2 : -2),
                  }));
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  Send Email
                </Typography>
              </CustomCheckbox>
              <Typography variant='c1' style={{ color: palette.gray400, marginLeft: 24 }}>
                Emails will include statement cover page, invoices and supporting documents.
              </Typography>
            </div>
            {!!values.send_email && values.payable_to === 'factoring_company' && (
              <div>
                <Input
                  required
                  label='Send To Email:'
                  value={accountingSettings.factor_email}
                  disabled
                  width='200px'
                  size='small'
                />
              </div>
            )}
          </div>
          <div className='delivery-option'>
            <div>
              <CustomCheckbox
                name='print_mail'
                checked={!!values.print_mail}
                onChange={(checked) => {
                  setValues((prevState) => ({
                    ...prevState,
                    print_mail: checked,
                    delivery_option: prevState.delivery_option + (checked ? 4 : -4),
                  }));
                }}
              >
                <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                  Print & Mail
                </Typography>
              </CustomCheckbox>
              <Typography variant='c1' style={{ color: palette.gray400, marginLeft: 24 }}>
                Documents will be saved to your computer and you can print / mail.
              </Typography>
            </div>
          </div>
        </div>
        {values.payable_to === 'factoring_company' ? (
          <div className='charges-table-wrapper'>
            <MaterialTableWrapper data={tableData} style={{ backgroundColor: palette.white }} columns={columns} />
            <div className='total-due-wrapper'>
              <Typography variant='s2'>Total Due</Typography>
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                {currency}
                {formatNumber(totalAmount)}
              </Typography>
            </div>
          </div>
        ) : (
          groupedInvoices.map((el, index) => {
            return (
              <div key={el.customer.id} className='charges-table-wrapper'>
                <div className='d-flex align-items-center justify-content-between mt-3'>
                  <div className='d-flex align-items-center gap-2'>
                    {!el.customer?.customer_contact_book?.length && !!values.send_email && (
                      <Tooltip
                        placement='bottom'
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: palette.white,
                              border: '1px solid #dadde9',
                            },
                          },
                        }}
                        title={
                          <Typography variant='s2' style={{ color: palette.gray700 }}>
                            Customer does not have email on file, please add contact with an email or it will not be
                            processed.
                          </Typography>
                        }
                      >
                        <DetentionIcon height={20} width={20} fill={palette.red500} />
                      </Tooltip>
                    )}
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {el.customer.company_name}
                    </Typography>
                  </div>
                  <CustomButton
                    styleTitle={{ fontSize: 14 }}
                    styleButton={{ padding: '6px 12px', margin: '0 36px 0 0' }}
                    type='secondary'
                    title='Preview'
                    onClick={() => onViewInvoices(el, index)}
                  />
                </div>
                <MaterialTableWrapper
                  data={el.invoices}
                  style={{ backgroundColor: palette.white }}
                  columns={uniqueCustomerColumns}
                />
                <div className='total-due-wrapper'>
                  <div>
                    {!!values.send_email && (
                      <div>
                        <div className='d-flex align-items-center justify-content-between'>
                          <InputLabel required variant='button2'>
                            Send To Email
                          </InputLabel>
                          <EditIcon
                            style={{ cursor: 'pointer' }}
                            fill={palette.gray500}
                            onClick={() => setCustomerToAddContact(el.customer)}
                          />
                        </div>
                        <Autocomplete
                          multiple
                          required
                          name={`batchEmailTo[${index}]`}
                          width='350px'
                          size='small'
                          value={values.batchEmailTo[index] || []}
                          options={el.customer.customer_contact_book}
                          onChange={(e, value) => handleChange(`batchEmailTo[${index}]`, value)}
                          isOptionEqualToValue={(option, value) =>
                            option?.contact_email === value?.contact_email && option?.id === value?.id
                          }
                          getOptionLabel={(option) =>
                            option ? `${CUSTOMER_CONTACT_TYPE[option.contact_type_id]} - ${option?.contact_name}` : ''
                          }
                          getTagLabel={(option) =>
                            option ? `${option?.contact_name || ''} - ${option?.contact_email || ''}` : ''
                          }
                          error={touchedErrors?.batchEmailTo?.[index]}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    {el.invoices.length > 1 && (
                      <DatePicker
                        name={`batchDueDates[${index}]`}
                        label='Batch Payment Due Date'
                        size='small'
                        width={200}
                        value={values.batchDueDates[index] || null}
                        onChange={(val) => onBatchDueDateChange(index, val, el.customer.id)}
                        style={{ width: '150px', marginTop: '8px' }}
                        minDate={values.bill_date}
                        dateFormat='MM/dd/yyyy'
                        required
                      />
                    )}
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <Typography variant='s2' style={{ color: palette.gray900 }}>
                      Total Due
                    </Typography>
                    <Typography variant='h5' style={{ color: palette.gray900 }}>
                      {currency}
                      {formatNumber(el.totalAmount)}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </SWrapper>
      {openViewInvoices && (
        <ViewPdf open={openViewInvoices} onClose={() => setOpenViewInvoices(false)} pdfUrl={invoiceUrl} />
      )}
      {!!customerToAddContact?.id && (
        <AddCustomerContact
          open={!!customerToAddContact?.id}
          onClose={() => setCustomerToAddContact(null)}
          customer={customerToAddContact}
          onSuccess={updateCustomerContacts}
        />
      )}
      <SBackdrop open={loading || loadingCreateInvoices}>
        <CircularProgress size={30} style={{ marginTop: '30px' }} />
        <Typography variant='h1' style={{ color: palette.white }}>
          Processing, please wait
        </Typography>
      </SBackdrop>
    </Modal>
  );
};

export default SendOtherInvoice;
