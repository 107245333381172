import React, { forwardRef, useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { palette } from 'utils/constants';

import Input from 'common/Input';
import { ReactComponent as CalendarIcon } from 'assets/icons/createShipment/calendar.svg';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { Typography } from '../Typography';
import sm from './CustomDatePicker.module.css';

const CustomDatePicker = (props) => {
  const {
    field,
    form,
    value,
    renderStart = true,
    onChange,
    label,
    labelStyle,
    required,
    style,
    styleIcon,
    className,
    showTimeSelect,
    showTimeInput,
    valueToShow,
    allowUserInput,
  } = props;
  const { pickerDateFormat, pickerTimeFormat, dateFormat, dateTimeFormat } = useDateFormat();
  const [inputValue, setInputValue] = useState(value || '');

  const renderDayContents = (day, date) => {
    const selected =
      field?.value || value ? moment(field?.value || value, 'MM/DD/YYYY').isSame(moment(date), 'day') : null;

    return (
      <div className={[sm.dayContents, selected ? sm.selected : ''].join(' ')}>
        <span>{day}</span>
      </div>
    );
  };

  const onChangeHandler = (e) => {
    onChange && onChange(e);
    !!form &&
      form.setFieldValue(field.name, moment(e).format(showTimeSelect || showTimeInput ? dateTimeFormat : dateFormat));

    if (allowUserInput) {
      onInputChange(e, true);
    }
  };

  useEffect(() => {
    !!renderStart && value && onChangeHandler(value);
    !!renderStart &&
      (field?.value || value) &&
      allowUserInput &&
      setInputValue(
        moment(field?.value || value).format(showTimeSelect || showTimeInput ? dateTimeFormat : dateFormat)
      );
  }, []);

  const onInputChange = (inputValue, select) => {
    if (inputValue === '') {
      onChange && onChange('');
      !!form && form.setFieldValue(field.name, '');
      return;
    }

    const parsedDate = moment(inputValue, dateFormat);
    if (parsedDate.isValid()) {
      onChange && onChange(parsedDate.toDate());
      !!form && form.setFieldValue(field.name, parsedDate.toDate());
      if (select) {
        setInputValue(moment(inputValue).format(showTimeSelect || showTimeInput ? dateTimeFormat : dateFormat));
      }
    }
  };

  return (
    <DatePicker
      {...field}
      customInput={
        <DatePickerCustomInput
          label={label}
          style={style}
          labelStyle={labelStyle}
          requiredFiled={required}
          placeholderText={dateFormat}
          styleIcon={styleIcon}
          className={className}
          allowUserInput={allowUserInput}
          onInputChange={onInputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      }
      {...props}
      value={field?.value ? moment(field.value).toDate() : value ? moment(value).toDate() : valueToShow || ''}
      selected={field?.value ? moment(field.value).toDate() : value ? moment(value).toDate() : ''}
      showPopperArrow={false}
      disabledKeyboardNavigation
      calendarClassName={sm.custom_datePicker}
      onChange={onChangeHandler}
      renderDayContents={renderDayContents}
      showYearDropdown
      showTimeSelect={showTimeSelect}
      dateFormat={showTimeSelect || showTimeInput ? `${pickerDateFormat} ${pickerTimeFormat}` : pickerDateFormat}
      timeFormat={pickerTimeFormat}
    />
  );
};

export default CustomDatePicker;

const DatePickerCustomInput = forwardRef(
  (
    {
      value,
      onClick,
      label,
      labelStyle,
      style,
      className,
      requiredFiled,
      placeholderText,
      styleIcon,
      allowUserInput,
      onInputChange,
      setInputValue,
      inputValue,
    },
    ref
  ) => {
    const { use } = useTheme();

    const handleInputChange = (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      onInputChange(newValue);
    };

    return (
      <label className={sm.datePicker_wrapper} style={{ ...labelStyle }}>
        {label && (
          <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
            {label}
            {requiredFiled && (
              <Typography style={{ color: palette.indigo500 }} variant='s2'>
                {' '}
                *
              </Typography>
            )}
          </Typography>
        )}
        <div
          ref={ref}
          onClick={onClick}
          className={`${sm['date-picker-custom-input']} custom-date-picker-input ${className || ''}`}
          style={{ backgroundColor: use(palette.white, palette.dark800), ...style }}
        >
          <CalendarIcon
            width={styleIcon?.width ? styleIcon.width : 14}
            height={styleIcon?.height ? styleIcon.height : 14}
            style={{ marginRight: 5, ...styleIcon }}
          />

          {allowUserInput ? (
            <Input
              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
              value={inputValue}
              onChange={handleInputChange}
            />
          ) : value ? (
            <Typography
              variant='s2'
              style={{
                whiteSpace: 'nowrap',
                color: use(palette.gray700, palette.gray200),
              }}
            >
              {value}
            </Typography>
          ) : (
            !!placeholderText && <Typography variant='overLine'>{placeholderText}</Typography>
          )}
        </div>
      </label>
    );
  }
);
