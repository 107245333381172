import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './StatusUpdateModal.css';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import InputLabel from 'common/InputLabel';
import { DateTimePicker } from 'common/Pickers';
import AddressFields from 'common/AddressFields';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useForm from 'hooks/useForm';
import { validationSchema } from './validationSchema';

export const driverStatusMapping = {
  1: 'Available',
  2: 'Not Available',
  3: 'On Shipment',
  4: 'Out of Service',
  5: 'Terminated',
  6: 'Quit',
};

export const driverStatusTextMapping = {
  Available: 1,
  'Not Available': 2,
  'On Shipment': 3,
  'Out of Service': 4,
  Terminated: 5,
  Quit: 6,
};

export default function UpdateModal({ ...props }) {
  const {
    out_of_service_date_time,
    quite_date_time,
    terminated_date_time,
    status_title,
    out_of_service_reason,
    note_not_available,
    driver_next_available_location,
    terminated_note,
    quit_note,
    driver_not_available_until_date,
    driver_not_available_until_time,
  } = props?.driverStatus || {};
  const { use } = useTheme();

  const statusUpdate = (values) => {
    const { status, driver_not_available_until_date, country, state, city } = values;
    const driverLocation = status === 2 ? `${city?.name || ''}, ${state?.name || ''}, ${country?.name || ''}` : '';
    const dateTime =
      status === 2
        ? moment(driver_not_available_until_date || new Date())
            .format('YYYY-MM-DD HH:mm:ss')
            .split(' ')
        : null;
    const serviceDate =
      status === 4 ? moment(out_of_service_date_time || new Date()).format('YYYY-MM-DD HH:mm:ss') : null;
    const terminatedDate =
      status === 5 ? moment(terminated_date_time || new Date()).format('YYYY-MM-DD HH:mm:ss') : null;
    const quitDate = status === 6 ? moment(quite_date_time || new Date()).format('YYYY-MM-DD HH:mm:ss') : null;

    const payload = {
      ...values,
      country_data: country,
      state_data: state,
      city_data: city,
      driver_next_available_location: driverLocation,
      driver_not_available_until_date: status === 2 ? dateTime[0] : '',
      driver_not_available_until_time: status === 2 ? dateTime[1] : '',
      status_title: driverStatusMapping[status],
      out_of_service_date_time: serviceDate,
      terminated_date_time: terminatedDate,
      quite_date_time: quitDate,
    };
    props.updateDriverStatus(payload, props.onHide, props.onSuccess, 'Driver status has been updated successfully');
  };

  const formik = useForm({
    initialValues: {
      status: status_title ? driverStatusTextMapping[status_title] : '',
      note_not_available: note_not_available || '',
      status_title: status_title || '',
      terminated_note: terminated_note || '',
      quit_note: quit_note || '',
      out_of_service_reason: out_of_service_reason || '',
      driver_not_available_until_date:
        driver_not_available_until_date &&
        driver_not_available_until_time &&
        moment(new Date(`${driver_not_available_until_date} ${driver_not_available_until_time}`)).isAfter(moment())
          ? new Date(`${driver_not_available_until_date} ${driver_not_available_until_time}`)
          : new Date(),
      out_of_service_date_time: out_of_service_date_time ? new Date(`${out_of_service_date_time}`) : new Date(),
      quite_date_time: quite_date_time ? new Date(`${quite_date_time}`) : new Date(),
      driver_not_available_until_time: driver_not_available_until_time || '',
      terminated_date_time: terminated_date_time ? new Date(`${terminated_date_time}`) : new Date(),
      country: null,
      state: null,
      city: null,
      driver_next_available_location: driver_next_available_location || '',
    },
    onSubmit: statusUpdate,
    validationSchema,
  });

  return (
    <div className='modal-container'>
      <Modal {...props} dialogClassName='modal-dialog-box' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
          closeButton
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Update Status for {props.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{ backgroundColor: use(palette.white, palette.dark900) }}
        >
          <p className='subline' style={{ color: use(palette.gray900, palette.gray200) }}>
            Change status
          </p>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='status'
              onChange={() => {
                formik.setFieldValue('status', 1);
              }}
              value={formik.values.status}
              checked={formik.values.status === 1}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Available
            </p>
          </div>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='status'
              onChange={() => {
                formik.setFieldValue('status', 2);
              }}
              value={formik.values.status}
              checked={formik.values.status === 2}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Not Available
            </p>
          </div>
          <div className='status-container'>
            <input
              type='radio'
              className='type'
              name='status'
              onChange={() => {
                formik.setFieldValue('status', 4);
              }}
              value={formik.values.status}
              checked={formik.values.status === 4}
            />
            <p className='status-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              Out of Service
            </p>
          </div>
          {formik.values.status === 2 ||
          formik.values.status === 4 ||
          formik.values.status === 5 ||
          formik.values.status === 6 ? (
            <div className='content-wrapper'>
              {formik.values.status === 2 ? (
                <DateTimePicker
                  required
                  label='Next Available'
                  name='driver_not_available_until_date'
                  value={formik.values.driver_not_available_until_date}
                  onChange={(date) => {
                    formik.setFieldValue('driver_not_available_until_date', date);
                  }}
                  onBlur={formik.handleBlur}
                  disablePast
                  error={
                    formik.touched.driver_not_available_until_date && formik.errors.driver_not_available_until_date
                  }
                />
              ) : formik.values.status === 4 ? (
                <DateTimePicker
                  required
                  label='Out of Service Date'
                  name='out_of_service_date_time'
                  value={formik.values.out_of_service_date_time}
                  onChange={(date) => {
                    formik.setFieldValue('out_of_service_date_time', date);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.out_of_service_date_time && formik.errors.out_of_service_date_time}
                />
              ) : formik.values.status === 5 ? (
                <DateTimePicker
                  required
                  label='Termination Date'
                  name='terminated_date_time'
                  value={formik.values.terminated_date_time}
                  onChange={(date) => {
                    formik.setFieldValue('terminated_date_time', date);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.terminated_date_time && formik.errors.terminated_date_time}
                />
              ) : formik.values.status === 6 ? (
                <DateTimePicker
                  required
                  label='Quit Date'
                  name='quite_date_time'
                  value={formik.values.quite_date_time}
                  onChange={(date) => {
                    formik.setFieldValue('quite_date_time', date);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quite_date_time && formik.errors.quite_date_time}
                />
              ) : null}
              {formik.values.driver_next_available_location && formik.values.status === 2 ? (
                <p
                  className='text-style mt-3'
                  style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}
                >
                  {formik.values.driver_next_available_location}
                </p>
              ) : null}
            </div>
          ) : null}
          {formik.values.status === 2 && (
            <div>
              <InputLabel required>Address</InputLabel>
              <AddressFields
                hideAddress
                hideZipcode
                values={formik.values}
                handleChange={formik.handleChange}
                touchedErrors={formik.touchedErrors}
              />
            </div>
          )}
          {formik.values.status === 2 ||
          formik.values.status === 4 ||
          formik.values.status === 5 ||
          formik.values.status === 6 ? (
            <div className='content-wrapper'>
              <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                Notes <span className='required'>*</span>
              </p>
              <div>
                {formik.values.status === 2 ? (
                  <textarea
                    className='text-area'
                    name='note_not_available'
                    id='note_not_available'
                    placeholder='Notes'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.note_not_available}
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                  />
                ) : formik.values.status === 4 ? (
                  <textarea
                    className='text-area'
                    name='out_of_service_reason'
                    id='out_of_service_reason'
                    placeholder='Notes'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.out_of_service_reason}
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                  />
                ) : formik.values.status === 5 ? (
                  <textarea
                    className='text-area'
                    name='terminated_note'
                    id='terminated_note'
                    placeholder='Notes'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.terminated_note}
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                  />
                ) : formik.values.status === 6 ? (
                  <textarea
                    className='text-area'
                    id='quit_note'
                    name='quit_note'
                    placeholder='Notes'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.quit_note}
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                    }}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark800),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.white, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button
                className='next-step'
                disabled={
                  (formik.values?.status === 2 &&
                    (!formik.values?.country ||
                      !formik.values?.state ||
                      !formik.values?.city ||
                      !formik.values?.note_not_available)) ||
                  (formik.values?.status === 4 && !formik.values?.out_of_service_reason) ||
                  (formik.values?.status === 5 && !formik.values?.terminated_note) ||
                  (formik.values?.status === 6 && !formik.values?.quit_note)
                }
                onClick={formik.handleSubmit}
              >
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
