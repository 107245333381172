import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import ShipmentCommodity from 'componentsV2/Commodity/ShipmentCommodity';
import { getStopName } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { getAlphabet, palette } from 'utils/constants';
import {
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  EQUIPMENT_ACTION_WAYPOINT,
  QUANTITY_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import InfoItem from './InfoItem';
import { SInfoSection, SShipmentStops } from '../ShipmentSummary.styles';

const ShipmentStops = ({ stops }) => {
  const { formatDateTime } = useDateFormat();
  const [cargosToView, setCargosToView] = useState(null);

  const onViewCargoCLick = (stop) => {
    if (!stop.cargos?.length && !stop.connected_cargos?.length) {
      return;
    }

    setCargosToView(
      (stop.cargos || stop.connected_cargos).map((item) => ({
        ...item,
        shipment_stop: { stop_point: stop.stop_point },
        expected_quantity_type: item.expected_quantity_type_data,
        expected_weight_type: item.expected_weight_type_data,
        expected_dimension_unit: item.expected_dimension_unit_data,
        expected_dimensions: typeof item.expected_dimensions === 'string' ? JSON.parse(item.expected_dimensions) : null,
      }))
    );
  };

  return (
    <SInfoSection>
      <SShipmentStops>
        <div className='d-flex gap-4 mb-3'>
          {stops.map((stop, index) => (
            <div key={stop.id} className='stop-wrapper'>
              <div>
                <div className='d-flex flex-column'>
                  <Typography variant='overLine' style={{ color: getStopName(stop.stop_point_type).color }}>
                    STOP {getAlphabet(index)}
                    {getStopName(stop.stop_point_type).type}
                  </Typography>
                  <Typography variant='s3' style={{ color: palette.gray900 }}>
                    {stop.stop_point.location_name}
                  </Typography>
                  <Typography variant='s3' style={{ color: palette.gray500 }}>
                    {stop.stop_point?.address1} {stop.stop_point?.address2} {stop.stop_point?.city?.name},{' '}
                    {stop.stop_point?.state?.short_name} {stop.stop_point?.zipcode}
                  </Typography>
                </div>
                <div className='d-flex gap-4 mt-4'>
                  <div className='d-flex flex-column gap-3'>
                    <InfoItem
                      titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                      title='Scheduled Date/Time'
                      content={`${formatDateTime(`${stop.scheduled_date} ${stop.from}`)}${
                        stop.to && [1, 3].includes(Number(stop.scheduled_type))
                          ? ` to ${formatDateTime(`${stop.scheduled_date_to || stop.scheduled_date} ${stop.to}`)}`
                          : ''
                      }`}
                    />
                    {Number(stop.stop_point_type) === 1 && (
                      <>
                        <InfoItem
                          titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                          title='Scheduled Pieces'
                          content={
                            stop.quantity ? `${stop.quantity} ${QUANTITY_TYPE[stop.quantity_type]?.label || ''}` : '-'
                          }
                        />
                        <InfoItem
                          titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                          title='Scheduled Weight'
                          content={stop.weight ? `${stop.weight} ${stop.weight_types?.unit || ''}` : '-'}
                        />
                      </>
                    )}
                    {[2, 3].includes(Number(stop.stop_point_type)) && (
                      <InfoItem
                        titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                        title='Equipment Action'
                        content={
                          (Number(stop.stop_point_type) === 2
                            ? EQUIPMENT_ACTION_DELIVERY
                            : EQUIPMENT_ACTION_WAYPOINT
                          ).find((i) => i.key === Number(stop.equipment_action))?.label || '-'
                        }
                      />
                    )}
                  </div>
                  <div className='d-flex justify-content-between flex-column gap-3'>
                    <InfoItem
                      titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                      title='Arrival Date/Time'
                      content={stop.arrival_date ? formatDateTime(stop.arrival_date, true) : '-'}
                    />
                    {Number(stop.stop_point_type) === 1 && (
                      <>
                        <InfoItem
                          titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                          title='Actual Pieces'
                          content={
                            stop.reported_quantity
                              ? `${stop.reported_quantity} ${QUANTITY_TYPE[stop.quantity_type]?.label || ''}`
                              : '-'
                          }
                        />
                        <InfoItem
                          titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                          title='Actual Weight'
                          content={
                            stop.reported_weight
                              ? `${stop.reported_weight} ${stop.reported_weight_types?.unit || ''}`
                              : '-'
                          }
                        />
                      </>
                    )}
                    {Number(stop.stop_point_type) === 2 && (
                      <>
                        <InfoItem
                          titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                          title='Seal ID'
                          content={stop.reported_seal_id || '-'}
                        />
                        <InfoItem
                          titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                          title='Proof of Delivery'
                          content={stop.proof_of_delivery || '-'}
                        />
                      </>
                    )}
                  </div>
                  <div className='d-flex flex-column gap-3'>
                    <InfoItem
                      titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                      title='Departure Date/Time'
                      content={stop.departure_date ? formatDateTime(stop.departure_date, true) : '-'}
                    />
                    {[1, 2].includes(Number(stop.stop_point_type)) && (
                      <InfoItem titleProps={{ variant: 's2', style: { color: palette.gray700 } }} title='Commodity'>
                        <Typography
                          variant='s2'
                          style={{ color: palette.indigo500, cursor: 'pointer' }}
                          onClick={() => onViewCargoCLick(stop)}
                        >
                          {!!stop.cargos?.length || !!stop.connected_cargos?.length ? 'View Cargo' : '-'}
                        </Typography>
                      </InfoItem>
                    )}
                    {Number(stop.stop_point_type) === 2 && (
                      <InfoItem
                        titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                        title='Bill of Lading ID'
                        content={stop.bill_of_lading_id || '-'}
                      />
                    )}
                    {Number(stop.stop_point_type) === 1 && (
                      <InfoItem
                        titleProps={{ variant: 's2', style: { color: palette.gray700 } }}
                        title='Equipment Action'
                        content={EQUIPMENT_ACTION.find((i) => i.key === Number(stop.equipment_action))?.label || '-'}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!!cargosToView && (
          <ShipmentCommodity
            open={!!cargosToView}
            onClose={() => setCargosToView(null)}
            cargo={cargosToView}
            readOnly
          />
        )}
      </SShipmentStops>
    </SInfoSection>
  );
};

export default ShipmentStops;
