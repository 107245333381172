import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import CancelAccountModal from 'components/Billing/CancelAccountModal/CancelAccountModal';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { toggleManageModalIsVisible, toggleUpDownGradeModalIsVisible } from 'store/reducers/billing.reducer';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import { cancelDowngrade } from 'Api/Billing';
import { getBillingDetail } from 'store/actions/billing.actions';
import useShowToaster from 'hooks/useShowToaster';
import { SWrapper } from './PlanLicense.styles';
import { useColumns } from './PlanLicense.data';

const PlanLicense = () => {
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [openCancelAccount, setOpenCancelAccount] = useState(false);
  const { billingDetail, manageModalIsVisible, upDownGradeModalIsVisible } = useSelector((state) => state.billing);
  const { customer_plan } = billingDetail || {};
  const duration = customer_plan?.plan?.duration;

  const tableData = [{ customer_plan }];

  const onCancelAccount = () => {
    setOpenCancelAccount(true);
  };

  const onUpgradeDowngrade = () => {
    dispatch(toggleUpDownGradeModalIsVisible(!upDownGradeModalIsVisible));
  };

  const onManageLicenses = () => {
    dispatch(toggleManageModalIsVisible(!manageModalIsVisible));
  };

  const cancelPlanDowngrade = async () => {
    try {
      await cancelDowngrade();
      dispatch(getBillingDetail());
      showToaster({ type: 'success', message: 'User license downgrade is canceled!' });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
  };

  const columns = useColumns({ onCancelAccount, onUpgradeDowngrade, onManageLicenses });

  return (
    <SWrapper>
      <div className='mb-2 d-flex align-items-center gap-3'>
        <Typography variant='h2' style={{ color: palette.gray900 }}>
          Plan Licenses
        </Typography>
        <div>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {planMapper[duration]?.billingHeaderTitle} -
          </Typography>{' '}
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            ${formatNumber(customer_plan?.amount)}
          </Typography>
        </div>
        <div>
          {!!customer_plan?.requested_downgrade && (
            <div className='downgrade-msg'>
              <Typography variant='c2' style={{ color: palette.amber600 }}>
                Pending downgrade from {customer_plan?.user_licence_count} user license to{' '}
                {customer_plan?.downgrade_upgrade_truck_size} on{' '}
                {customer_plan?.next_renewal_date ? formatDate(customer_plan?.next_renewal_date.split('T')[0]) : ''}.
              </Typography>
              <Typography
                className='red-hover'
                variant='c2'
                style={{ color: palette.red500 }}
                onClick={cancelPlanDowngrade}
              >
                Cancel
              </Typography>
            </div>
          )}
          {!!customer_plan?.upgrade_monthly_to_yearly && (
            <div className='downgrade-msg mt-1'>
              <Typography variant='c2' style={{ color: palette.amber600 }}>
                Pending upgrade to 3-year plan, awaiting agreement signature and payment.
              </Typography>
            </div>
          )}
        </div>
      </div>
      <MaterialTableWrapper
        data={tableData}
        rowPerPage={1}
        style={{ backgroundColor: palette.white }}
        components={{ Pagination: () => null }}
        columns={columns}
      />
      {!!openCancelAccount && (
        <CancelAccountModal
          show={openCancelAccount}
          onClose={() => setOpenCancelAccount(false)}
          data={billingDetail}
          onSuccess={() => dispatch(getBillingDetail())}
        />
      )}
    </SWrapper>
  );
};

export default PlanLicense;
