import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import twoWays from 'assets/icons/drivers/two-ways.svg';
import right from 'assets/icons/drivers/right.svg';
import documentIcon from 'assets/icons/drivers/document.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import calendar from 'assets/icons/drivers/calendar.svg';
import AlertMsg from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon';
import { ownerShipmentData } from 'Api/OwnerOperator';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import TableFooter from 'components/TableFooter/TableFooter';
import DateRange from 'components/DateRangePicker/DateRangePicker';
import MaterialTableWrapper, { MaterialTableSort } from 'components/MaterialTableWrapper';

const ShipmentTabTable = () => {
  const { id } = useParams();
  const [rowPerPage, setRowPerPage] = useState(10);
  const { use } = useTheme();
  const [shipmentTab, setShipmentTab] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });
  const [profileAlert, setProfileAlert] = useState(null);
  const [alertData, setAlertData] = useState();
  const ProfileAlertOpen = Boolean(profileAlert);
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;

  const navigate = useNavigate();

  //  onClick={() => goToPosts()}
  const getDriverShimpmentData = useCallback(
    (searchText = '', sortBy = 'desc') => {
      const { start, end } = dateRange;
      const startFormat = moment(start).format('YYYY-MM-DD');
      const endFormat = moment(end).format('YYYY-MM-DD');
      const payload = {
        page: '1',
        itemsPerPage: rowPerPage,
        from_date: startFormat,
        to_date: endFormat,
        sortBy,
        operator_id: id,
      };
      if (searchText) {
        payload.query = searchText;
      }
      ownerShipmentData(payload)
        .then((res) => {
          setLoading(false);
          for (const val of res?.data || {}) {
            val.origin = val.shipment_stops.find((stop) => stop.stop_point_type === '1');
            val.destination = val.shipment_stops.find((stop) => stop.stop_point_type === '2');
          }
          setShipmentTab(res);
        })
        .catch(() => setLoading(false));
    },
    [dateRange, rowPerPage, id]
  );

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      getDriverShimpmentData(search);
    }, 2000);
    setLoading(false);
    return () => clearTimeout(delayDebounceFn);
  }, [search, getDriverShimpmentData]);

  useEffect(() => {
    setLoading(true);
    getDriverShimpmentData(search);
  }, []);

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const onPageChange = (event, page) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filter = {
      page,
      itemsPerPage: rowPerPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      operator_id: id,
    };
    ownerShipmentData(filter)
      .then((res) => {
        setShipmentTab(res);
      })
      .finally(() => setLoading(false));
  };

  const ProfileAlerthandleClick = (e, alerts) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
  };

  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  const onChangeRowPerPage = (rowPage) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('YYYY-MM-DD');
    const endFormat = moment(end).format('YYYY-MM-DD');
    setRowPerPage(rowPage);
    setLoading(true);
    const payload = {
      page: 1,
      itemsPerPage: rowPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      operator_id: id,
    };
    if (search) {
      payload.query = search;
    }
    ownerShipmentData(payload)
      .then((res) => {
        setShipmentTab({ ...res });
      })
      .finally(() => setLoading(false));
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        rowPerPage={rowPerPage}
        totalCount={shipmentTab?.total}
        totalLength={shipmentTab?.data?.length}
        lastPage={shipmentTab?.last_page}
        currentPage={shipmentTab?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setShipmentTab(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'ShipmentID',
        title: <MaterialTableSort title='Shipment ID' field='ShipmentID' />,
        render: (rowData) => {
          return (
            <div className='shipment-row-container'>
              <p className='shipment-id' style={{ color: use(palette.gray900, palette.white) }}>
                {rowData?.shipment_id}
              </p>
              {rowData?.shipment_notes?.length ? (
                <img
                  src={documentIcon}
                  alt=''
                  className='document-icon'
                  onClick={(e) => {
                    navigate({
                      pathname: `/shipment/${rowData?.shipment_id}`,
                      search: '?activeTab=5',
                    });
                    e.stopPropagation();
                  }}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        field: 'shipment_billing',
        title: <MaterialTableSort title='Customer' field='shipment_billing' />,
        render: (rowData) =>
          rowData?.shipment_billing.length ? (
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.shipment_billing[0]?.billing_customer?.company_name}
            </p>
          ) : (
            <p
              className='table-data-text'
              onClick={(e) => {
                ProfileAlerthandleClick(e, rowData?.shipment_billing);
                e.stopPropagation();
              }}
              style={{ color: use(palette.gray700, palette.gray200) }}
            >
              Multi Customer
            </p>
          ),
      },
      {
        field: 'equipment',
        title: <MaterialTableSort title='Equipment' field='equipment' />,
        render: (rowData) => (
          <div className='equipment-container'>
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.equipment?.equipment_id}
            </p>
            <p className='equipment-sub-text'>{rowData?.equipment?.equipment_type?.title}</p>
            <img src={twoWays} alt='' />
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.equipment?.equipment_id}
            </p>
            <p className='equipment-sub-text'>
              {rowData?.equipment?.length?.length
                ? `${rowData?.equipment?.length?.length}-${rowData?.equipment?.equipment_type?.title}`
                : rowData?.equipment?.equipment_type?.title}
            </p>
          </div>
        ),
      },
      {
        field: 'paperwork_received',
        title: <MaterialTableSort title='PW' field='paperwork_received' />,
        render: (rowData) => (rowData?.shipment_billing?.paperwork_received === 1 ? <img src={right} alt='' /> : '-'),
      },

      {
        field: 'shipment_stops',
        title: <MaterialTableSort title='Stops' field='shipment_stops' />,
        render: (rowData) => (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            1 of {rowData?.shipment_stops?.length}
          </p>
        ),
      },
      {
        field: 'origin',
        title: <MaterialTableSort title='Origin' field='origin' />,
        render: (rowData) => {
          const origin = rowData?.origin?.stop_point;
          return (
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {(origin?.city?.name || '') +
                (origin?.city?.name ? ', ' : '') +
                (origin?.state?.name || '') +
                (origin?.state?.name ? ' ' : '') +
                (origin?.zipcode || '')}
            </p>
          );
        },
      },
      {
        field: 'destination',
        title: <MaterialTableSort title='Destination' field='destination' />,
        render: (rowData) => {
          const destination = rowData?.destination?.stop_point;
          return (
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {(destination?.city?.name || '') +
                (destination?.city?.name ? ', ' : '') +
                (destination?.state?.name || '') +
                (destination?.state?.name ? ' ' : '') +
                (destination?.zipcode || '')}
            </p>
          );
        },
      },
      {
        field: 'shipment_stops',
        title: <MaterialTableSort title='Complete Date' field='shipment_stops' />,
        render: (rowData) => {
          const departure = rowData?.shipment_stops[Number(rowData?.shipment_stops?.length) - 1];
          return (
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {departure?.departure_date ? departure?.departure_date : '-'}
            </p>
          );
        },
      },
      {
        field: 'empty_miles',
        title: <MaterialTableSort title='Miles' field='empty_miles' />,
        render: (rowData) => {
          const miles = Number(rowData?.empty_miles) + Number(rowData?.loaded_miles);
          return (
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {' '}
              {miles.toFixed(0)}
            </p>
          );
        },
      },
    ];
  }, [use]);

  if (loading) {
    return <TablePreLoader />;
  }

  return (
    <div
      className='shipment-table-container driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-header'>
          <p className='heading' style={{ color: use(palette.gray700, palette.gray200) }}>
            Shipments
          </p>
        </div>
        <div className='serach-wrapper'>
          <input
            type='text'
            onChange={(e) => setSearch(e.target.value)}
            className='serach-feild'
            placeholder='Search'
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              color: use(palette.gray400, palette.white),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          />
          <div className='calender-wrap'>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '117px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
          </div>
        </div>
      </div>
      <div className='table-wrap'>
        <div className='sub-table-container'>
          <MaterialTableWrapper
            data={shipmentTab?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
            onRowClick={(e, rowData) =>
              navigate({
                pathname: `/shipment/${rowData?.shipment_id}`,
                search: '?activeTab=0',
              })
            }
          />
        </div>
        <Popover
          id={ProfileAlertContent}
          open={ProfileAlertOpen}
          anchorEl={profileAlert}
          onClose={ProfileAlerthandleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          style={{ marginLeft: '20px' }}
        >
          <AlertMsg alertData={alertData} shipmentAlert='Shipment Notes' />
        </Popover>
      </div>
    </div>
  );
};

export default ShipmentTabTable;
