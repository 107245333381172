import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  shipment_id: string().trim().typeError('Required').required('Required'),
  default_shipment_type: object().when('default_global_data', {
    is: (default_global_data) => !!default_global_data,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  default_shipment_dispatch_option: object().when('default_shipment_dispatch', {
    is: (default_shipment_dispatch) => !!default_shipment_dispatch,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});
