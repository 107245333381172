import uuid from 'react-uuid';
import moment from 'moment/moment';

export const initialRecurs = [
  {
    checked: 0,
    title: 'Monday',
    id: 1,
    amount: '1',
    dateSettings: [
      {
        id: uuid(),
        day_wise_pickup_time: '',
        day_wise_pickup_time_to: '',
        day_wise_delivery_time_after: [''],
        delivery_time: [''],
        delivery_time_to: [''],
      },
    ],
  },
  {
    checked: 0,
    title: 'Tuesday',
    id: 2,
    amount: '1',
    dateSettings: [
      {
        id: uuid(),
        day_wise_pickup_time: '',
        day_wise_delivery_time_after: [''],
        delivery_time: [''],
        delivery_time_to: [''],
      },
    ],
  },
  {
    checked: 0,
    title: 'Wednesday',
    id: 3,
    amount: '1',
    dateSettings: [
      {
        id: uuid(),
        day_wise_pickup_time: '',
        day_wise_delivery_time_after: [''],
        delivery_time: [''],
        delivery_time_to: [''],
      },
    ],
  },
  {
    checked: 0,
    title: 'Thursday',
    id: 4,
    amount: '1',
    dateSettings: [
      {
        id: uuid(),
        day_wise_pickup_time: '',
        day_wise_delivery_time_after: [''],
        delivery_time: [''],
        delivery_time_to: [''],
      },
    ],
  },
  {
    checked: 0,
    title: 'Friday',
    id: 5,
    amount: '1',
    dateSettings: [
      {
        id: uuid(),
        day_wise_pickup_time: '',
        day_wise_delivery_time_after: [''],
        delivery_time: [''],
        delivery_time_to: [''],
      },
    ],
  },
  {
    checked: 0,
    title: 'Saturday',
    id: 6,
    amount: '1',
    dateSettings: [
      {
        id: uuid(),
        day_wise_pickup_time: '',
        day_wise_delivery_time_after: [''],
        delivery_time: [''],
        delivery_time_to: [''],
      },
    ],
  },
  {
    checked: 0,
    title: 'Sunday',
    id: 7,
    amount: '1',
    dateSettings: [
      {
        id: uuid(),
        day_wise_pickup_time: '',
        day_wise_delivery_time_after: [''],
        delivery_time: [''],
        delivery_time_to: [''],
      },
    ],
  },
];

export const getInitialValues = (shipmentData, stops) => {
  const fields = stops.map(() => '');

  return {
    shipmentOnly: shipmentData?.shipmentOnly || '',
    laneStartDate: shipmentData.laneStartDate ? moment(shipmentData.laneStartDate).toDate() : moment().toDate(),
    laneEndDate: shipmentData.laneEndDate ? moment(shipmentData.laneEndDate).toDate() : moment().toDate(),
    leadTime: shipmentData.leadTime || '0',
    recurs:
      shipmentData.recurs ||
      initialRecurs.map((i) => ({
        ...i,
        dateSettings: [
          {
            id: uuid(),
            day_wise_pickup_time: '',
            day_wise_pickup_time_to: '',
            day_wise_delivery_time_after: fields,
            delivery_time: fields,
            delivery_time_to: fields,
          },
        ],
      })),
  };
};
