import React, { useState } from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import AccordionSummary from '@mui/material/AccordionSummary';
import { SWrapper, SAccordion } from './FeatureCard.styles';

const FeatureCard = ({ feature }) => {
  const [expanded, setExpanded] = useState(false);
  const { children } = feature || {};

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <SWrapper>
      <div className='feature-header'>
        <Typography variant='s1' style={{ color: palette.gray900 }}>
          {feature.name}
        </Typography>
      </div>
      <Divider color={palette.gray50} margin='0' />
      <div className='feature-body'>
        {!!children?.length && (
          <ul className='mb-2'>
            <Typography variant='s2' as='li' style={{ color: palette.gray700 }}>
              {children[0].name}
            </Typography>
          </ul>
        )}
        {!!children?.slice(1)?.length && (
          <SAccordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary>
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                Show {expanded ? 'Less' : 'More'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {children?.slice(1).map((item) => (
                  <Typography variant='s2' as='li' style={{ color: palette.gray700 }} key={item.id}>
                    {item.name}
                  </Typography>
                ))}
              </ul>
            </AccordionDetails>
          </SAccordion>
        )}
      </div>
    </SWrapper>
  );
};

export default FeatureCard;
