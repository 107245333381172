import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import Chip from 'common/Chip';
import Loader from 'common/Loader';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import PageHeader from 'pages/CompanySettings/components/PageHeader';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import { GetAllConnectedSeries } from 'Api/Driver';
import {
  GetDepartments,
  GetSMSNumberList,
  getShipmentNoteDepartments,
  getSMSCommunicationSettings,
  updateShipmentNoteDepartments,
  createSMSCommunicationSettings,
} from 'Api/CompanySettings';
import { getInitialValues } from './SMSCommunications.data';
import { SWrapper, SItemRow } from './SMSCommunications.styles';

const SMSCommunications = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const { value } = useAuth();
  const [settingsData, setSettingsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [notesDepartments, setNotesDepartments] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [smsNumberList, setSmsNumberList] = useState([]);

  const getCommunicationSettings = async () => {
    try {
      const { data } = await getSMSCommunicationSettings();
      setSettingsData(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getNotesDepartments = async () => {
    try {
      const { data } = await getShipmentNoteDepartments();
      setNotesDepartments(data?.map((item) => item.department));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setLoadingUpdate(true);
    try {
      const body = {
        sms_primary: values.sms_primary?.phone_number || null,
        send_sms: Number(values.send_sms),
        send_sms_on_dispatch: Number(values.send_sms_on_dispatch),
        send_driver_shipment_notes_email: Number(values.send_driver_shipment_notes_email),
      };

      const addDepartmentsBody = {
        department_id: values.notesDepartments.map((department) => department.id),
      };

      const p1 = createSMSCommunicationSettings(body);
      const p2 = updateShipmentNoteDepartments(addDepartmentsBody);

      await Promise.all([p1, p2]);

      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
      getCommunicationSettings();
      getNotesDepartments();
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingUpdate(false);
    }
  };

  const { values, handleChange, handleSubmit } = useForm({
    initialValues: getInitialValues(settingsData, smsNumberList, notesDepartments),
    onSubmit,
    enableReinitialize: true,
  });

  const getDepartments = async () => {
    try {
      const { data } = await GetDepartments();
      setDepartments(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getSMSNumbers = async () => {
    try {
      const { data: connectedServices } = await GetAllConnectedSeries();
      const twilloService = connectedServices?.find((i) => i.id === 52);
      if (!twilloService?.connected) {
        return;
      }
      const { data } = await GetSMSNumberList();
      setSmsNumberList(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    setLoading(true);
    getCommunicationSettings();
    getSMSNumbers();
    getDepartments();
    getNotesDepartments();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SWrapper>
      <PageHeader
        title='Email/SMS Communications'
        isConfiguringStep={isConfiguringStep}
        onSaveAndNext={onSaveAndNext}
        onCancelPrevPage={onCancelPrevPage}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
      />
      <Divider />
      <div>
        <SItemRow>
          <CustomCheckbox
            type='switch'
            name='send_sms'
            checked={!!values.send_sms}
            onChange={(checked) => handleChange('send_sms', checked)}
            smail={false}
          />
          <div className='w-75'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Send Systems SMS/Email Notifications (Staff or Drivers)
            </Typography>
          </div>
        </SItemRow>
        <SItemRow>
          <CustomCheckbox
            type='switch'
            name='send_sms_on_dispatch'
            checked={!!values.send_sms_on_dispatch}
            onChange={(checked) => handleChange('send_sms_on_dispatch', checked)}
            smail={false}
          />
          <div>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Send SMS Notification on Dispatch
            </Typography>
          </div>
        </SItemRow>
        <SItemRow className='flex-column gap-3'>
          <div className='d-flex align-items-center'>
            <CustomCheckbox
              type='switch'
              name='send_driver_shipment_notes_email'
              checked={!!values.send_driver_shipment_notes_email}
              onChange={(checked) => handleChange('send_driver_shipment_notes_email', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray700, paddingLeft: '24px' }}>
              Driver to Shipment Notes Email Communications
            </Typography>
          </div>
          <div className='notes-departments-wrapper'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              The subsequent departments will be notified via email upon the entry of driver notes for every shipment:
            </Typography>
            <div>
              <Autocomplete
                multiple
                required
                width='400px'
                name='notesDepartments'
                labelKey='department_name'
                options={departments}
                value={values.notesDepartments}
                onChange={(e, value) => handleChange('notesDepartments', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                disabled={!values.send_driver_shipment_notes_email}
              />
            </div>
          </div>
        </SItemRow>
        <SItemRow className='align-items-center'>
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              SMS Number
            </Typography>
            <Chip size='medium' label='Requires SMS Addon' labelColor={palette.gray700} bgColor={palette.gray50} />
          </div>
          <div className='d-flex align-items-center gap-3'>
            <div className='d-flex'>
              <PhoneInput
                disableDropdown
                inputProps={{ hidden: true }}
                country='us'
                dropdownClass='dropDown_custom_phone_light'
              />
              <Autocomplete
                width='300px'
                labelKey='phone_number'
                options={smsNumberList}
                value={values.sms_primary}
                onChange={(e, val) => handleChange('sms_primary', val)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                disabled={smsNumberList?.length === 0}
              />
            </div>
            <div className='d-flex gap-1 align-items-center'>
              <WarningIcon />
              <Typography variant='b2' style={{ color: palette.orange500 }}>
                Detects incoming SMS from Drivers and Staff to Chat Module
              </Typography>
            </div>
          </div>
        </SItemRow>
      </div>
      <div className='mt-5'>
        <Typography variant='s2' style={{ color: palette.gray700, marginLeft: '8px' }}>
          DISCLAIMER
        </Typography>
        <Divider margin='0 0 8px' />
        <Typography variant='s2' style={{ color: palette.gray700, padding: '0 8px', display: 'inline-block' }}>
          By ticking the above, you authorize Truckin Systems LLC to send Truckin Digital system notifications via SMS
          text message to {value?.user?.customer?.company_name} drivers and staff users. This includes any system
          notifications, alerts and internal SMS Chat communications. Message/data rates apply. Consent is not a
          condition of purchase. See{' '}
          <Typography
            variant='s2'
            as='a'
            style={{ color: palette.indigo500 }}
            href='https://www.truckindigital.com/legal/terms-and-conditions'
            target='_blank'
          >
            terms and conditions
          </Typography>
          .
        </Typography>
      </div>
    </SWrapper>
  );
};

export default SMSCommunications;
