import React, { forwardRef, useState } from 'react';
import { Fade, Popper } from '@mui/material';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';
import { ReactComponent as PersonIcon } from 'assets/sidemanu/UserFilled.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/createShipment/settingsFiled.svg';
import { palette } from 'utils/constants';
import { Typography } from '../../../../../Typography';
import { SPaper, SUserInfo } from './StopInfo.styles';

const StopInfo = forwardRef(({ data, onClickSettingsIcon, stop }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const { name, phone_number, email, location_name } = data || {};
  const onOpen = (event) => {
    if (name) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <SUserInfo onClick={onOpen} onMouseLeave={onMouseLeave}>
      <Popper open={open} anchorEl={anchorEl} placement='top-start' transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <SPaper ref={ref} onClick={(e) => e.stopPropagation()}>
              <div className='user-info-content'>
                <ul>
                  <li onClick={(e) => e.stopPropagation()}>
                    <PersonIcon width={22} height={17} fill={palette.indigo400} />
                    <a>{name}</a>
                  </li>
                  <li onClick={(e) => e.stopPropagation()}>
                    <PhoneIcon width={22} height={22} fill={palette.indigo400} />
                    <a href={`tel:${phone_number}`}>{phone_number}</a>
                  </li>
                  <li onClick={(e) => e.stopPropagation()}>
                    <MailIcon width={22} height={12} fill={palette.indigo400} />
                    <a href={`mailto:${email}`}>{email}</a>
                  </li>
                </ul>
              </div>
            </SPaper>
          </Fade>
        )}
      </Popper>
      <div className='user-name-wrapper'>
        <Typography variant='s1'>
          {location_name}
          <SettingIcon onClick={() => onClickSettingsIcon(stop)} style={{ cursor: 'pointer', marginTop: -4 }} />
        </Typography>
      </div>
    </SUserInfo>
  );
});

export default StopInfo;
