import React, { useEffect, useState } from 'react';
import './Carriers.css';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { startCase, toLower } from 'lodash';

import Loader from 'common/Loader';
import Pagination from 'common/Pagination';
import RestrictedPage from 'components/RestrictedPage';
import useShowToaster from 'hooks/useShowToaster';

import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { TableContext } from 'context/tableContext';
import { getErrorMessage } from 'utils/error';
import { palette, RESTRICTION_TYPE } from 'utils/constants';
import { getCustomerPopoverSettings } from 'Api/Customers';
import { getBrokerageSetting, GetSettingsDepartments } from 'Api/CompanySettings';
import { carrierTabs, getCarriersList, updateCarrierGeneralInfo } from 'Api/Carriers';
import { allTab, initialFilters, mapperSettingsData, useColumns } from 'components/Carriers/Carriers.data';
import EquipmentPreloaderSkeleton from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper from '../MaterialTableWrapper';
import AdvancedFilter from './AdvancedFilter/AdvancedFilter';
import EquipmentTypes from './CarrierProfile/EquipmentTypes';
import CarriersHeader from './CarriersHeader';
import SwitchRadio from '../SwitchRadio/SwitchRadio';
import ImportCsv from './ImportCsv';

const Carriers = () => {
  const showToaster = useShowToaster();
  const { use } = useTheme();
  const { value } = useAuth();
  const navigate = useNavigate();
  const [createModalCLose, setCreateModalCLose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState({ data: [] });
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [brokerageSettings, setBrokerageSettings] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [openImportCsv, setOpenImportCsv] = useState(false);
  const [showMoreOpen, setShowMoreOpen] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all' },
    allValue: '',
    shipmentValue: '',
    selectedValues: [],
    customerSelectData: [],
    tableColumn,
    switchRadioButtons: {
      amount: '1',
      date: '1',
      time: '1',
    },
    forAll: false,
    filterTableTop: [],
    applyAllUsersModal: { value: '0' },
  });
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});

  const getCarriers = async (data) => {
    try {
      setLoading(true);
      const { advancedFilter: advancedFilterProps } = data || {};
      const filter = advancedFilterProps || convertFilterData(advancedFilter);
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'filters[status]': selectedFilters.filter || undefined,
        ...filter,
      };

      if (selectedFilters?.sort?.field) {
        params[`sort[][${selectedFilters?.sort?.field}]`] = selectedFilters?.sort?.sortBy;
      }

      const response = await getCarriersList(params);
      if (!filter.filterTableTop?.length) {
        const { data: tabData } = await carrierTabs();
        const newData = tabData.map((el) => ({
          ...el,
          count: el.count,
          label: startCase(toLower(el?.data?.name)),
          key: uuid(),
          value: uuid(),
        }));

        setFilter((prevState) => ({
          ...prevState,
          filterTableTop: [{ ...allTab, count: response.total }, ...newData],
        }));
      } else if (selectedFilters.tab === 0) {
        const newTabs = [...filter.filterTableTop];
        newTabs[0].count = response.total;
        setFilter((prevState) => ({ ...prevState, filterTableTop: newTabs }));
      }

      setCustomers(response);
      setLoading(false);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const checkBrokerageSettings = async () => {
    setLoadingSettings(true);
    try {
      const { data } = await getBrokerageSetting();
      setBrokerageSettings(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingSettings(false);
    }
  };

  const getSettings = () => {
    getCustomerPopoverSettings({ type: 'carrier' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({ title: mapperSettingsData(key), value: key, checked: value });
          });

          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page });
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const onChangeTab = (item) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, tab: item.id, filter: item.data?.filters?.status });
  };

  const updateOnCreate = (value) => {
    setCreateModalCLose(value);
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
    });
    return cols;
  };

  const sortingQuery = (field, nested_field) => {
    const direction = selectedFilters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSelectedFilters((prevState) => ({
      ...prevState,
      sort: { field, nested_field, sortBy: direction },
    }));
  };

  const getDepartments = async () => {
    try {
      const { data } = await GetSettingsDepartments();
      const userPermissions =
        data.find((department) => department.id === value?.user?.department?.id)?.permissions || [];
      setPermissions(userPermissions.map((i) => i.permission_name));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    setLoading(true);
    getDepartments();
    checkBrokerageSettings();
  }, []);

  useEffect(() => {
    getSettings();
  }, [createModalCLose]);

  useEffect(() => {
    getCarriers();
  }, [selectedFilters]);

  const openNotes = (event, row) => {
    event.stopPropagation();
    navigate(`/carrier-profile/${row.id}`, { state: { activeTab: 'Notes' } });
  };

  const changePreferred = (e, data) => {
    e.stopPropagation();
    const payload = {
      preferred: Number(!data.preferred),
    };
    updateCarrierGeneralInfo(payload, data.id)
      .then(() => {
        getCarriers();
        showToaster({ type: 'success', message: 'Success' });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const showMore = (e, data) => {
    e.stopPropagation();
    setShowMoreOpen(data.equipment_types);
  };

  const convertFilterData = (data) => {
    const requestData = {};

    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        // requestData[key] = value.map((el) => el.id).toString();

        value.forEach((el, index) => {
          const fieldName = `filters[${key}][${index}]`;
          requestData[fieldName] = el.id;
        });
      } else if (typeof value === 'object') {
        if (key === 'state_from') {
          requestData[`filters[operating_states][0]`] = value.id;
        }
        if (key === 'state_to') {
          requestData[`filters[operating_states][1]`] = value.id;
        }
      } else if (typeof value === 'string') {
        requestData[`filters[${key}]`] = value;
      }
    });

    return requestData;
  };

  const onSearch = (data) => {
    const clearAll = typeof data === 'object' && Object.keys(data).length === 0;
    const filterObject = data || advancedFilter;

    if (!search || clearAll) {
      delete filterObject.query;
    } else {
      filterObject.query = search;
    }
    getCarriers({ advancedFilter: convertFilterData(filterObject) });
  };

  const columns = useColumns({ sortingQuery, changePreferred, openNotes, showMore, sort: selectedFilters.sort });

  if (!loadingSettings && !brokerageSettings) {
    return <RestrictedPage restrictionType={RESTRICTION_TYPE.SETTINGS_REQUIRED} settingsTab='brokerage' />;
  }

  if (loadingSettings) {
    return <Loader />;
  }

  return (
    <div>
      <TableContext.Provider
        value={{
          filter,
          dragItem,
          onChangeOrder: (items) => setDragItem(items),
          tableColumn,
          onsStTableColumn: (v) => setTableColumn(v),
          getSettings,
          updateFilter,
          planerData: customers.data,
          createModalCLose,
          updateOnCreate,
        }}
      >
        <AdvancedFilter
          onSearch={onSearch}
          open={advancedOpen}
          setSearch={setSearch}
          filter={advancedFilter}
          setFilter={setAdvancedFilter}
        >
          {({ onSearchFilter }) => {
            return (
              <CarriersHeader
                filter={filter}
                search={search}
                dragItem={dragItem}
                setSearch={setSearch}
                setLoading={setLoading}
                getSettings={getSettings}
                permissions={permissions}
                tableColumn={tableColumn}
                onSearch={onSearchFilter}
                updateFilter={updateFilter}
                advancedOpen={advancedOpen}
                setAdvancedOpen={setAdvancedOpen}
                setOpenImportCsv={setOpenImportCsv}
                getCarriers={() => getCarriers(false)}
              />
            );
          }}
        </AdvancedFilter>
        <SwitchRadio
          name='tableTopTabMenu'
          items={filter.filterTableTop}
          value={selectedFilters.tab}
          type='tab'
          onChange={onChangeTab}
          plus={false}
        />
      </TableContext.Provider>

      {loading ? (
        <EquipmentPreloaderSkeleton />
      ) : (
        <MaterialTableWrapper
          data={customers?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          onRowClick={(e, rowData) => navigate(`/carrier-profile/${rowData.id}`)}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: () =>
              Pagination({
                data: customers,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          columns={filterTableColumn(columns)}
        />
      )}
      {openImportCsv && (
        <ImportCsv open={openImportCsv} onClose={() => setOpenImportCsv(false)} onSuccess={getCarriers} />
      )}
      {showMoreOpen && (
        <EquipmentTypes open={showMoreOpen} onClose={() => setShowMoreOpen(null)} equipmentTypes={showMoreOpen} />
      )}
    </div>
  );
};

export default Carriers;
