import React from 'react';
import user from 'assets/icons/drivers/user-profile.svg';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import RowActions from './RowActions';

export const useColumns = ({
  sort,
  sortingQuery,
  onView,
  onEdit,
  onDelete,
  rules,
  setRuleToView,
  isStaff,
  hideSensitiveInfo,
}) => {
  const { formatDateTime } = useDateFormat();

  return [
    {
      field: 'incident_id',
      title: <ColumnHeader title='INCIDENT ID' field='incident_id' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray900 }}>
          {rowData?.incident_id}
        </p>
      ),
    },
    {
      field: 'incident_date',
      title: <ColumnHeader title='DATE + TIME' field='incident_date' />,
      render: (rowData) => (
        <p className='incident-date' style={{ color: palette.gray900 }}>
          {`${formatDateTime(`${rowData?.incident_date} ${rowData?.incident_time}`)}`}
        </p>
      ),
    },
    {
      field: 'country',
      title: <ColumnHeader title='LOCATION' field='country' nested_field='name' />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {' '}
          {(rowData?.city?.name || '') +
            (rowData?.city?.name ? ', ' : '') +
            (rowData?.state?.name || '') +
            (rowData?.state?.name ? ', ' : '') +
            (rowData?.country?.name || '')}
        </p>
      ),
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='REPORT NUMBER' field='report_number' sort={sort} onClick={sortingQuery} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.report_number}
        </p>
      ),
    },
    {
      field: 'incident_type',
      title: <ColumnHeader title='TYPE' field='incident_type' />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: palette.gray700 }}>
          {rowData?.incident_type ? rowData?.incident_type?.type : rowData?.custom_incident_type}
        </p>
      ),
    },
    {
      field: 'related_to',
      title: <ColumnHeader title='RELATED TO' field='related_to' />,
      render: (row) => (
        <div className='d-flex align-items-center gap-1'>
          {rules?.some((rule) => rule?.incident_types?.some((type) => type?.id === row.incident_type?.id)) ? (
            rules
              ?.filter((rule) => rule?.incident_types?.some((type) => type?.id === row.incident_type?.id))
              .map((rule) => {
                return (
                  <div
                    key={rule.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRuleToView(rule);
                    }}
                  >
                    <Chip
                      label={rule.title}
                      bgColor={palette.indigo0}
                      labelColor={palette.indigo500}
                      sx={{
                        '& .MuiChip-label': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  </div>
                );
              })
          ) : (
            <p className='table-data-text' style={{ color: palette.gray700 }}>
              -
            </p>
          )}
        </div>
      ),
    },
    {
      field: 'incident_action_taken',
      title: <ColumnHeader title='ACTION TAKEN' field='action' />,
      render: (rowData) =>
        rowData?.incident_action_taken && (
          <div
            className='break-container'
            style={{ backgroundColor: `${rowData?.incident_action_taken?.color_code || palette.orange500}11` }}
          >
            <p
              style={{ color: rowData?.incident_action_taken?.color_code || palette.orange500 }}
              className='break-text'
            >
              {rowData?.incident_action_taken?.action}
            </p>
          </div>
        ),
    },
    {
      field: 'added_by',
      title: <ColumnHeader title='ACTION BY' field='first_name' />,
      render: (rowData) => (
        <div className='more-dowpdown-container'>
          {rowData?.added_by && (
            <div className='action-user'>
              <div className='image-container'>
                <img src={rowData?.added_by?.profile_logo || user} alt='' className='user-image' />
                <div className='user-active-indicator' />
              </div>
              <p className='table-data-text' style={{ color: palette.gray700 }}>{`${
                rowData?.added_by?.first_name || ''
              } ${rowData?.added_by?.last_name || ''}`}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      field: '',
      render: (rowData) =>
        isStaff || !hideSensitiveInfo ? (
          <RowActions
            onView={() => onView(rowData)}
            onEdit={() => onEdit(rowData)}
            onDelete={() => onDelete(rowData)}
          />
        ) : null,
    },
  ];
};
