import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/Mask Group.png';
import useShowToaster from 'hooks/useShowToaster';
import { sendResetPasswordEmail } from 'Api/User';

const ForgotPassword = () => {
  const showToaster = useShowToaster();
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const handleEmailSend = async (e, isResend) => {
    e.preventDefault();
    try {
      setSent(true);
      await sendResetPasswordEmail({ email });
      if (isResend) {
        showToaster({ type: 'success', message: 'Password reset successfully sent to your email!' });
      }
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <div className='signin-layout'>
      <div className='video-cover'>
        <video
          autoPlay
          loop
          muted
          playsInline
          data-wf-ignore
          data-object-fit='cover'
          id='video'
          poster='assets/images/image 23.png'
        >
          <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.mp4`} data-wf-ignore='true' />
          <source src={`${process.env.REACT_APP_BASE_URL}/assets/videos/login-video.webm`} data-wf-ignore='true' />
        </video>
      </div>

      <div className='purple-filter' />
      <div className='form-container'>
        <div className='logo'>
          <img src={logo} alt='Logo' />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h1 className='header'>{sent ? 'Check Your Email.' : 'Forgot Password?'}</h1>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className='subheader'>
            {sent
              ? 'If the supplied username exists, an email has been sent to the email address associated with that username.'
              : 'Enter the email address associated with your account.'}
          </p>
          {sent ? (
            <p className='subheader'>
              Please check your Spam / Junk mail in case the email went there.
              <br />{' '}
              <b onClick={(e) => handleEmailSend(e, true)} style={{ cursor: 'pointer' }}>
                or Resend.
              </b>
            </p>
          ) : (
            ''
          )}
        </div>

        <div>
          <form className='form' style={{ display: sent ? 'none' : '' }}>
            <input
              id='username'
              placeholder='Email'
              name='username'
              type='email'
              className='form-control onboarding-input'
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className='button btn' onClick={handleEmailSend}>
              Continue
            </button>
          </form>
          <p
            className='subheader txt-btn'
            style={{ textAlign: 'center', cursor: 'pointer' }}
            onClick={() => navigate('/login')}
          >
            Return to <b>Sign in</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
