import { Icons } from '../../../../../../assets/icons';

const vehicle = new Image(); // Image constructor
vehicle.src = Icons.Vehicle;
vehicle.alt = 'alt';

const GreyColorVehicle = new Image(); // Image constructor
GreyColorVehicle.src = Icons.GreyVehicle;
GreyColorVehicle.alt = 'alt';

const GreyVehiclePlusTrailer = new Image(); // Image constructor
GreyVehiclePlusTrailer.src = Icons.GreyColorVehicleTrailer;
GreyVehiclePlusTrailer.alt = 'alt';

const GreyTrailer = new Image(); // Image constructor
GreyTrailer.src = Icons.GreyColorTrailer;
GreyTrailer.alt = 'alt';

const VehiclePlusTrailer = new Image(); // Image constructor
VehiclePlusTrailer.src = Icons.VehiclePlusTrailer;
VehiclePlusTrailer.alt = 'alt';

const GreenCircle = new Image();
GreenCircle.src = Icons.GreenCircle;
GreenCircle.alt = 'alt';

const GreenCircleGreySquare = new Image();
GreenCircleGreySquare.src = Icons.GreenCircleGreenSquare;
GreenCircleGreySquare.alt = 'alt';

const PurpleSquare = new Image();
PurpleSquare.src = Icons.PurpleSquare;
PurpleSquare.alt = 'alt';

// const GreenTrailer = new Image();
// GreenTrailer.src = Icons.GreenSquare;
// GreenTrailer.alt = "alt";

export function animateMarker(marker, newPosition, oldPosition, speed) {
  if (newPosition?.lat === oldPosition?.lat && newPosition?.lng === oldPosition?.lng) {
    return;
  }

  const options = {
    duration: speed * 150,
    easing(x, t, b, c, d) {
      // eslint-disable-next-line no-return-assign
      return -c * (t /= d) * (t - 2) + b;
    },
  };

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

  const newPosition_lat = newPosition.lat;
  let newPosition_lng = newPosition.lng;

  if (Math.abs(newPosition_lng - oldPosition.lng) > 180) {
    if (newPosition_lng > oldPosition.lng) {
      newPosition_lng -= 360;
    } else {
      newPosition_lng += 360;
    }
  }

  function animateStep(marker, startTime) {
    const elapsedTime = new Date().getTime() - startTime;
    const durationRatio = elapsedTime / options.duration;

    const easingDurationRatio = options.easing(durationRatio, elapsedTime, 0, 1, options.duration);

    if (durationRatio < 1) {
      marker.setLngLat({
        lat: oldPosition.lat + (newPosition_lat - oldPosition.lat) * easingDurationRatio,
        lng: oldPosition.lng + (newPosition_lng - oldPosition.lng) * easingDurationRatio,
      });

      if (window.requestAnimationFrame) {
        marker.AT_animationHandler = window.requestAnimationFrame(() => {
          animateStep(marker, startTime);
        });
      } else {
        marker.AT_animationHandler = setTimeout(() => {
          animateStep(marker, startTime);
        }, 17);
      }
    } else {
      marker?.setLngLat(newPosition);
    }
  }

  if (window.cancelAnimationFrame) {
    window.cancelAnimationFrame(marker.AT_animationHandler);
  } else {
    clearTimeout(marker.AT_animationHandler);
  }

  animateStep(marker, new Date().getTime());
}

export function getMarker(profileData, trailerID, speedForDriver) {
  let markerElement;
  if (profileData?.hook_to) {
    // Check if the vehicle has a trailer attached or not
    if (trailerID !== 'trailer') {
      // Check if the vehicle is moving at a non-zero speed or if a special flag called "speedForDriver" is set to true
      if (
        (profileData?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] &&
          profileData?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] !== '0') ||
        speedForDriver
      ) {
        // If the vehicle is moving and has a trailer attached, display a special icon called "VehiclePlusTrailer"
        markerElement = VehiclePlusTrailer;
      } else if (
        profileData?.equipment_connected_services_device &&
        profileData?.equipment_connected_services_device?.engine_state !== 'Off'
      ) {
        markerElement = GreenCircleGreySquare;
      } else {
        // If the vehicle is not moving or has a speed of 0 and has a trailer attached, display a different icon called "GreyVehiclePlusTrailer"
        markerElement = GreyVehiclePlusTrailer;
      }
    } else {
      // If the vehicle does not have a trailer attached, display the regular "VehiclePlusTrailer" icon
      if (
        (profileData?.hooked_to?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] &&
          profileData?.hooked_to?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] !== '0') ||
        speedForDriver
      ) {
        // If the vehicle is moving and has a trailer attached, display a special icon called "VehiclePlusTrailer"
        markerElement = VehiclePlusTrailer;
      } else if (
        profileData?.hooked_to?.equipment_connected_services_device &&
        profileData?.hooked_to?.equipment_connected_services_device?.engine_state !== 'Off'
      ) {
        markerElement = GreenCircleGreySquare;
      } else {
        markerElement = GreyVehiclePlusTrailer;
      }
    }
  } else {
    // If profileData and hook_to do not exist, check if the vehicle has a trailer attached
    if (trailerID !== 'trailer') {
      // Check if the vehicle is moving at a non-zero speed or if a special flag called "speedForDriver" is set to true
      if (
        (profileData?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] &&
          profileData?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] !== '0') ||
        speedForDriver
      ) {
        // If the vehicle is moving and does not have a trailer attached, display the regular "vehicle" icon
        markerElement = vehicle;
      } else {
        // if the vehicle doesn't have speed , but engin is on we are showing green circle
        if (
          profileData?.equipment_connected_services_device &&
          profileData?.equipment_connected_services_device?.engine_state !== 'Off'
        ) {
          markerElement = GreenCircle;
        } else {
          // If the vehicle is not moving or has a speed of 0 and does not have a trailer attached, display a different icon called "GreyColorVehicle"
          markerElement = GreyColorVehicle;
        }
      }
    } else {
      // If the vehicle does not have a trailer attached and profileData and hook_to do not exist, check if the trailer is assigned to a driver or not
      if (profileData?.equipment_status?.id === 6) {
        // if this trailer is dropped
        markerElement = PurpleSquare;
      }
      // else if (+profileData?.equipment_connected_services_device?.speed_mph > 1) {
      //     markerElement = GreenTrailer
      // }
      else if (profileData?.equipment_status?.equipment_status === 'Not Assigned') {
        markerElement = GreyTrailer;
      } else {
        markerElement = GreyTrailer;
      }
    }
  }
  return markerElement;
}

export function getLiveMarker(profileData, trailerID, speedForDriver, is_hooked, engine_state) {
  let markerElement;
  if (is_hooked) {
    if (trailerID !== 'trailer') {
      if (speedForDriver > 1) {
        const newVehiclePlusTrailer = new Image();
        newVehiclePlusTrailer.src = Icons.VehiclePlusTrailer;
        newVehiclePlusTrailer.alt = 'alt';
        markerElement = newVehiclePlusTrailer;
      } else if (engine_state && engine_state !== 'Off') {
        const newGreenCircleGreySquare = new Image();
        newGreenCircleGreySquare.src = Icons.GreenCircleGreenSquare;
        newGreenCircleGreySquare.alt = 'alt';
        markerElement = newGreenCircleGreySquare;
      } else {
        const newGreyVehiclePlusTrailer = new Image(); // Image constructor
        newGreyVehiclePlusTrailer.src = Icons.GreyColorVehicleTrailer;
        newGreyVehiclePlusTrailer.alt = 'alt';
        markerElement = newGreyVehiclePlusTrailer;
      }
    } else if (speedForDriver > 1) {
      const newVehiclePlusTrailer = new Image();
      newVehiclePlusTrailer.src = Icons.VehiclePlusTrailer;
      newVehiclePlusTrailer.alt = 'alt';
      markerElement = newVehiclePlusTrailer;
    } else if (engine_state && engine_state !== 'Off') {
      const NGreenCircleGreySquare = new Image();
      NGreenCircleGreySquare.src = Icons.GreenCircleGreenSquare;
      NGreenCircleGreySquare.alt = 'alt';
      markerElement = NGreenCircleGreySquare;
    } else {
      const newGreyVehiclePlusTrailer = new Image();
      newGreyVehiclePlusTrailer.src = Icons.GreyColorVehicleTrailer;
      newGreyVehiclePlusTrailer.alt = 'alt';
      markerElement = newGreyVehiclePlusTrailer;
    }
  } else if (trailerID !== 'trailer') {
    if (speedForDriver > 1) {
      const newVehicle = new Image();
      newVehicle.src = Icons.Vehicle;
      newVehicle.alt = 'alt';
      markerElement = newVehicle;
    } else if (
      profileData?.equipment_connected_services_device &&
      profileData?.equipment_connected_services_device?.engine_state !== 'Off'
    ) {
      const newGreenCircle = new Image();
      newGreenCircle.src = Icons.GreenCircle;
      newGreenCircle.alt = 'alt';
      markerElement = newGreenCircle;
    } else {
      const newGreyColorVehicle = new Image();
      newGreyColorVehicle.src = Icons.GreyVehicle;
      newGreyColorVehicle.alt = 'alt';
      markerElement = newGreyColorVehicle;
    }
  } else {
    if (profileData?.equipment_status?.id === 6) {
      const newPurpleSquare = new Image();
      newPurpleSquare.src = Icons.PurpleSquare;
      newPurpleSquare.alt = 'alt';
      markerElement = newPurpleSquare;
    } else {
      // if (+speedForDriver  > 1 ) {
      //     const newGreenTrailer = new Image();
      //     newGreenTrailer.src = Icons.GreenSquare;
      //     newGreenTrailer.alt = "alt";
      //     markerElement = newGreenTrailer;
      // }
      // else {
      const newGreyTrailer = new Image();
      newGreyTrailer.src = Icons.GreyColorTrailer;
      newGreyTrailer.alt = 'alt';
      markerElement = newGreyTrailer;
    }
    // }
  }
  return markerElement;
}
