import styled from 'styled-components';
import { palette } from 'utils/constants';
import DatePicker from 'common/DatePicker';

export const SPageWrapper = styled.div`
  height: 100%;
  font-family: 'Inter', sans-serif;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;
    cursor: pointer;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
      padding: 8px 16px;
    }
  }
`;

export const SInnerTable = styled.table`
  width: 100%;

  th {
    border-bottom: none;
    font-size: 11px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 10px 16px 6px;
  }

  td {
    padding: 8px 16px;
    border: none;
    cursor: default;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell > div {
    visibility: hidden;
    transition: visibility 0.1s;
  }

  &.selected {
    background-color: ${palette.indigo0};
  }

  :hover {
    background-color: ${palette.gray0};

    .actions-cell > div {
      visibility: visible;
    }
  }
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .delete-icon {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }
`;

export const SDatePicker = styled(DatePicker)`
  width: 250px;
  font-size: 14px;
  box-shadow: ${palette.boxShadow2};
  border-radius: 0 6px 6px 0;
  padding: 5px 12px 6px;
`;
