import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { useAuth } from 'context/auth.context';
import { detectBrowser } from 'common/BrowserVarify';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import FirstTimeLogin from 'components/firstTimeLogin/firstTimeLogin';
import Widgets from './Widgets';

const Dashboard = () => {
  const { value, setAuth } = useAuth();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [knownBrowser, setKnownBrowser] = useState(false);
  const [isOpenConfigure, setIsOpenConfigure] = useState(false);

  const fifthStep = () => {
    return (
      <div className='fifty_wrapper'>
        <div className='fifty_header'>
          <SettingsIcon />
        </div>
        <div className='fifty_title'>
          <Typography variant='s1'>Company Settings</Typography>
        </div>
        <div className='fifty_subtitle'>
          <Typography variant='overLine2'>Before getting started Please configure your Company Settings.</Typography>
        </div>
        <div className='fifty_action'>
          <CustomButton
            type='primary'
            title='Configure Settings'
            styleButton={{ width: 280, justifyContent: 'center', padding: '6px 12px', margin: 0 }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => {
              setIsOpenConfigure(false);
              setAuth({ ...value, shouldPassConfigureModal: false });
              navigate('/configure-settings');
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (value.user.is_password_set === 0 || value.user.is_password_set === undefined) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [openModal, value]);

  const browserInfo = JSON.parse(localStorage.getItem('browserInfo'));
  const detectedBrowser = detectBrowser();
  useEffect(() => {
    if (!browserInfo && value.user.is_password_set === 1) {
      if (value.user?.customer?.send_otp_to_user) {
        setOpenModal(true);
      }
      setKnownBrowser(true);
    }
  }, [browserInfo, detectedBrowser, knownBrowser, value]);

  return knownBrowser ? (
    <div>
      <FirstTimeLogin
        isOpen={openModal}
        setOpenModal={setOpenModal}
        onlyOtp
        isOpenConfigure={isOpenConfigure}
        setIsOpenConfigure={setIsOpenConfigure}
      />
      {!openModal && browserInfo && <Widgets />}
    </div>
  ) : (
    <div>
      {openModal && (
        <FirstTimeLogin
          isOpen={openModal}
          setOpenModal={setOpenModal}
          isOpenConfigure={isOpenConfigure}
          setIsOpenConfigure={setIsOpenConfigure}
        />
      )}
      <Modal
        isOpen={isOpenConfigure}
        className='modal-custom2'
        overlayClassName='Overlay newBrowserOverlay'
        ariaHideApp={false}
      >
        {fifthStep()}
      </Modal>
      {!openModal && browserInfo && <Widgets />}
    </div>
  );
};

export default Dashboard;
