import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetStopPointsWorkingHours } from 'Api/StopPoint';
import WorkingHoursEdit from './WorkingHoursEdit';
import WorkingHoursPreview from './WorkingHoursPreview';

const WorkingHours = () => {
  const [edit, setEdit] = useState(false);
  const [stopPointsWorkingHours, setStopPointsWorkingHours] = useState({});
  const { id } = useParams();

  const getStopPointWorkingHours = (id) => {
    return GetStopPointsWorkingHours(id).then((res) => {
      setStopPointsWorkingHours(res);
    });
  };

  useEffect(() => {
    getStopPointWorkingHours(id);
  }, []);

  return edit ? (
    <WorkingHoursEdit
      setEdit={setEdit}
      getStopPointWorkingHours={getStopPointWorkingHours}
      stopPointsWorkingHours={stopPointsWorkingHours}
    />
  ) : (
    <WorkingHoursPreview setEdit={setEdit} stopPointsWorkingHours={stopPointsWorkingHours} />
  );
};
export default WorkingHours;
