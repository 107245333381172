import moment from 'moment';
import get from 'lodash/get';

export const statementTypes = [
  { name: 'All', value: 'activity' },
  { name: 'Outstanding', value: 'outstanding' },
];

export const currencies = {
  $: 'USD',
  C$: 'CAD',
  '₱': 'MXN',
};

export const getTotalBalanceDue = (transactions) => {
  const balances = transactions.map((transaction) => {
    const appliedPaymentsCount = transaction.payments_applied?.length;

    return !appliedPaymentsCount
      ? transaction.amount
      : transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due < 0
      ? 0
      : transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due;
  });

  return balances.reduce((acc, cur) => acc + cur, 0);
};

export const getTransactionDetails = (transaction) => {
  const appliedPaymentsCount = transaction.payments_applied?.length;

  const isPayed =
    transaction.amount === 0 ||
    (!!appliedPaymentsCount && transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due <= 0);

  const age =
    !!appliedPaymentsCount && transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due <= 0
      ? ''
      : transaction.age;

  const paymentDate = appliedPaymentsCount
    ? transaction.payments_applied?.[appliedPaymentsCount - 1].payment_date
    : null;

  const balance = !appliedPaymentsCount
    ? transaction.amount
    : transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due < 0
    ? 0
    : transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due;

  const totalPaymentsAmount = transaction.payments_applied?.reduce((acc, cur) => acc + cur.amount_paid, 0);

  return { isPayed, paymentDate, balance, totalPaymentsAmount, age };
};

export const getOverdueAmount = (transactions) => {
  const overdueTransactions = transactions.filter((transaction) =>
    moment(transaction.due_date).isBefore(moment().startOf('day'))
  );

  return getTotalBalanceDue(overdueTransactions);
};

export const splitArrayIntoRanges = (originalArray, chunkSize) => {
  const resultArray = [];
  let startIndex = 0;

  while (startIndex < originalArray.length) {
    const endIndex = Math.min(startIndex + chunkSize - 1, originalArray.length - 1);
    resultArray.push(originalArray.slice(startIndex, endIndex + 1));
    startIndex += chunkSize;
  }
  return resultArray;
};

export const convertTransactionsData = (transactions) => {
  const convertedData = [];

  transactions.forEach((transaction) => {
    const { isPayed, paymentDate, balance, totalPaymentsAmount, age } = getTransactionDetails(transaction);
    convertedData.push({
      ...transaction,
      reference_id: transaction.billing?.reference_id || transaction.reference_id,
      isPayed,
      paymentDate,
      balance,
      totalPaymentsAmount,
      age,
    });
  });

  return convertedData;
};

export const filterTransactions = (transactions, sort) => {
  return (transactions || []).sort((a, b) => {
    if (get(a, sort.field) > get(b, sort.field)) {
      return sort.sortBy === 'asc' ? 1 : -1;
    }
    if (get(a, sort.field) < get(b, sort.field)) {
      return sort.sortBy === 'asc' ? -1 : 1;
    }
    return 0;
  });
};
