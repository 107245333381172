import { boolean, number, object, string } from 'yup';

export const validationSchema1 = object().shape({
  shipping_country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  shipping_state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  shipping_city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  shipping_address1: string().trim().required('Required'),
  shipping_address2: string().trim().nullable(),
  shipping_zip: string().trim().required('Required'),
  terms_and_conditions: boolean().required('Required').nullable(),
  privacy_policy: boolean().required('Required').nullable(),
  billing_policy: boolean().required('Required').nullable(),
});

export const validationSchema2 = object().shape({
  terms_and_conditions: boolean().oneOf([true], 'Required'),
  privacy_policy: boolean().oneOf([true], 'Required'),
  billing_policy: boolean().oneOf([true], 'Required'),
});
