import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import right from 'assets/icons/drivers/right.svg';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { formatAmount, getShipmentType } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { formatNumber, palette, statusColor } from 'utils/constants';
import { SUnderlineText } from 'pages/Accounting/Receivables/Receivables.styles';
import { findNextStop } from 'pages/Accounting/Receivables/TableSection/TableSection.data';
import RowActions from '../shared/RowActions';
import DriversPopup from '../shared/DriversPopup';

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export const useColumns = ({
  onMarkPaid,
  onRevertInvoice,
  onUploadDocuments,
  onViewNotes,
  onViewPDF,
  handleSelectAll,
  selectedRows,
  handleSelectRow,
  data,
  sort,
  sortingQuery,
}) => {
  const { use } = useTheme();
  const hasQuickbook = useHasQuickbook();
  const navigate = useNavigate();
  const { formatDate, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: '',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <CustomCheckbox onChange={handleSelectAll} checked={selectedRows?.length === data?.length} />,
      render: (row) => (
        <span onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.id === row.id)}
          />
        </span>
      ),
    },
    {
      field: 'shipment_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='s2'>{row.billing?.shipment?.shipment_id || '-'}</Typography>
            {!!row.billing?.shipment?.shipment_notes?.length && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onViewNotes(row);
                }}
              >
                <NotesIcon width={14} height={14} />
              </div>
            )}
            {(!!row.billing?.shipment?.is_edi_api || !!row.billing?.shipment?.manual_edi_api) && (
              <CloudIcon fill={palette.green400} />
            )}
            {!!row.qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
          </div>
        );
      },
    },
    {
      field: 'invoice_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='INVOICE #' field='invoice_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <Typography variant='b2' style={{ color: use(palette.gray700) }}>
              {row.invoice_id}
            </Typography>
            {row.batch && (
              <SUnderlineText
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onViewPDF(row.batch);
                }}
              >
                {row.batch?.batch_id}
              </SUnderlineText>
            )}
          </div>
        );
      },
    },
    {
      field: 'reference_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.billing?.reference_id}
        </Typography>
      ),
    },
    {
      field: 'shipment_type',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SHIPMENT TYPE' field='shipment_type' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {getShipmentType(row.billing?.shipment.shipment_type)}
        </Typography>
      ),
    },
    {
      field: 'customer',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='CUSTOMER' field='customer' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.billing?.billing_customer.company_name || row.customer?.company_name || '-'}
        </Typography>
      ),
    },
    {
      field: 'equipment',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='EQUIPMENT' field='equipment' />,
      render: (row) => {
        return row.billing_id && (row.billing.shipment?.brokerage_dispatch || row.billing.shipment?.equipment) ? (
          <>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.billing.shipment?.brokerage_dispatch
                ? row.billing.shipment?.brokerage_dispatch?.vehicle_id
                : row.billing.shipment?.equipment?.equipment_id}
              &nbsp;
            </Typography>
            <Typography variant='overLine' style={{ color: palette.gray500, textTransform: 'uppercase' }}>
              {row.billing.shipment?.brokerage_dispatch
                ? row.billing.shipment?.brokerage_dispatch?.vehicle_type?.title
                : row.billing.shipment?.equipment?.equipment_type?.title}
            </Typography>
            {(!!row.billing.shipment?.last_hooked_equipment ||
              !!row.billing.shipment?.brokerage_dispatch?.trailer_type) && (
              <>
                <TwoWaysIcon style={{ margin: '0 8px' }} />
                <Typography variant='overLine2' style={{ textTransform: 'uppercase' }}>
                  {row.billing.shipment?.last_hooked_equipment?.equipment_id ||
                    row.billing.shipment?.brokerage_dispatch?.trailer_id}
                  &nbsp;
                  <Typography variant='overLine'>
                    {row.billing.shipment?.last_hooked_equipment?.length?.length ||
                      row.billing.shipment?.brokerage_dispatch?.trailer_length?.length}
                    -
                    {row.billing.shipment?.last_hooked_equipment?.equipment_type?.title ||
                      row.billing.shipment?.brokerage_dispatch?.trailer_type?.title}
                  </Typography>
                </Typography>
              </>
            )}
          </>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'driver',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DRIVER' field='driver' />,
      render: (row) => {
        const driver1 = row?.billing?.shipment?.assigned_to_1;
        const driver2 = row?.billing?.shipment?.assigned_to_2;

        return row.billing_id && (row.shipment?.brokerage_dispatch || driver1) ? (
          <div>
            {row.billing.shipment?.brokerage_dispatch ? (
              <div className='mb-2'>
                {row.billing.shipment.brokerage_dispatch.driver_name && (
                  <div className='details-container details-container--shipment-audit details-container---staff'>
                    <div className='image-container' style={{ width: '30px', height: '30px' }}>
                      <img
                        src={userDefault}
                        className='user-image'
                        style={{ width: '30px', height: '30px' }}
                        alt='driver'
                      />
                    </div>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {row.billing.shipment.brokerage_dispatch.driver_name}
                    </Typography>
                  </div>
                )}
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {row.billing.shipment.brokerage_dispatch.carrier?.name}
                </Typography>
              </div>
            ) : (
              <div>
                {row.billing.shipment?.shipment_type !== 'split-parent' && driver1 && (
                  <div className='details-container details-container--shipment-audit details-container---staff'>
                    <div className='image-container' style={{ width: '36px', height: '36px' }}>
                      <img
                        className='user-image'
                        alt='driver'
                        src={driver1.image ? driver1.image : userDefault}
                        style={{ width: '36px', height: '36px' }}
                      />
                      <div
                        className='user-active-indicator'
                        style={{
                          backgroundColor: statusColor[driver1.status_data?.driver_status || 'Logged Off']?.color,
                        }}
                      />
                    </div>
                    <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                      {driver1.fname || ''} {driver1.lname || ''}
                    </Typography>
                  </div>
                )}
                {row.billing.shipment?.shipment_type !== 'split-parent' && driver2 && (
                  <div className='details-container details-container--shipment-audit details-container---staff'>
                    <div className='image-container' style={{ width: '36px', height: '36px' }}>
                      <img
                        className='user-image'
                        alt='driver'
                        style={{ width: '36px', height: '36px' }}
                        src={driver2.image ? driver2.image : userDefault}
                      />
                      <div
                        className='user-active-indicator'
                        style={{
                          backgroundColor: statusColor[driver2.status_data?.driver_status || 'Logged Off']?.color,
                        }}
                      />
                    </div>
                    <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                      {driver2.fname || ''} {driver2.lname || ''}
                    </Typography>
                  </div>
                )}
                {row.billing.shipment?.shipment_type === 'split-parent' && (
                  <DriversPopup
                    shipmentId={row.billing.shipment_id}
                    masterShipmentDriver={row.billing.shipment?.assigned_to_1}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'pw',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='PW' field='pw' />,
      render: (row) =>
        row.billing?.paperwork_received ? (
          <img src={right} alt='' />
        ) : row.billing_id ? (
          <DeleteIcon fill={palette.red500} />
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            -
          </Typography>
        ),
    },
    {
      field: 'age',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AGING' field='age' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.age}
        </Typography>
      ),
    },
    {
      field: 'sent_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SENT DATE' field='sent_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {formatDate(row.customer_billed_date)}
        </Typography>
      ),
    },
    {
      field: 'sent_method',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SENT METHOD' field='sent_method' />,
      render: (row) => {
        const isEmailed =
          ['2', '6', '10', '14'].includes(row.delivery_option) ||
          row.email_to ||
          (Array.isArray(row.emails_to) && row.emails_to.length > 0) ||
          (!Array.isArray(row.emails_to) && row.email_to);
        const isPrintMailed = ['4', '6', '12', '14'].includes(row.delivery_option) || row.mail_to_id;
        const isEdiTransmitted = ['8', '10', '12', '14'].includes(row.delivery_option);

        if (!isEmailed && !isPrintMailed && !isEdiTransmitted) {
          return (
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              -
            </Typography>
          );
        }

        return (
          <div className='d-flex flex-column gap-1'>
            {isEmailed && (
              <NoMaxWidthTooltip
                title={
                  <Typography
                    variant='b2'
                    style={{
                      color: palette.white,
                      whiteSpace: 'pre-wrap',
                      display: 'inline-block',
                    }}
                  >
                    Emailed to{' '}
                    {Array.isArray(row?.emails_to)
                      ? row.emails_to.map((e) => e).join(',\n')
                      : row?.email_to || row?.emails_to || ''}{' '}
                    on {formatDateTime(row.processed_at)}
                  </Typography>
                }
              >
                <span>
                  <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                    Email
                  </Typography>
                </span>
              </NoMaxWidthTooltip>
            )}
            {isPrintMailed && (
              <NoMaxWidthTooltip
                title={
                  <Typography variant='b2' style={{ color: palette.white }}>
                    Mailed to {row.mail_to?.address1}, {row.mail_to?.address2 ? `${row.mail_to?.address2},` : ''}
                    {row.mail_to?.city?.name}, {row.mail_to?.state?.short_name} {row.mail_to?.zipcode}
                  </Typography>
                }
              >
                <span>
                  <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                    Print and Mailed
                  </Typography>
                </span>
              </NoMaxWidthTooltip>
            )}
            {isEdiTransmitted && (
              <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                EDI/API Transmission
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'due_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DUE DATE' field='due_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {formatDate(row.due_date)}
        </Typography>
      ),
    },
    {
      field: 'stops',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='STOPS' field='stops' />,
      render: (row) => {
        const count = findNextStop(row.billing?.shipment);

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${count} of ${row.billing.shipment?.stops_count}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'origin',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='ORIGIN' field='origin' />,
      render: (row) => {
        const city = row.billing?.origin_stop?.stop_point?.city?.name || '';
        const state = row.billing?.origin_stop?.stop_point?.state?.short_name || '';
        const zip = row.billing?.origin_stop?.stop_point?.zipcode || '';

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${city ? `${city},` : ''} ${state} ${zip}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'destination',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DESTINATION' field='destination' />,
      render: (row) => {
        const city = row.billing?.destination_stop?.stop_point?.city?.name || '';
        const state = row.billing?.destination_stop?.stop_point?.state?.short_name || '';
        const zip = row.billing?.destination_stop?.stop_point?.zipcode || '';

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${city ? `${city},` : ''} ${state} ${zip}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'complete_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='COMPLETE DATE' field='end_date_time' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return row.billing_id ? (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {formatDate(
              row.billing?.shipment?.tonu_date_time
                ? row.billing?.shipment?.tonu_date_time
                : row.billing?.destination_stop?.departure_date
            )}
          </Typography>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'miles',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='MILES' field='miles' />,
      render: (row) => {
        const loaded = row.billing?.shipment?.loaded_miles ? Math.round(row.billing.shipment.loaded_miles) : 0;
        const empty = row.billing?.shipment?.empty_miles ? Math.round(row.billing.shipment.empty_miles) : 0;
        const total = loaded + empty;

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${total}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'total_amount',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AMOUNT' field='total_amount' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.billing_id ? `${currency}${formatAmount(row.billing?.total_amount)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'open_balance',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='OPEN BALANCE' field='open_balance' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.billing_id ? `${currency}${formatAmount(row.billing?.open_balance)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='' />,
      render: (row) => {
        return (
          <RowActions
            row={row}
            onViewShipment={row.billing?.shipment_id ? () => navigate(`/shipment/${row?.billing?.shipment_id}`) : null}
            onMarkPaid={() => onMarkPaid(row)}
            onRevertInvoice={() => onRevertInvoice(row)}
            onUploadDocuments={() => onUploadDocuments(row)}
            onViewPDF={() => onViewPDF(row)}
            onViewNotes={() => onViewNotes(row)}
          />
        );
      },
    },
  ];
};

export const useOtherColumns = ({
  onViewPDF,
  onRevertInvoice,
  onMarkPaid,
  handleSelectRow,
  handleSelectAll,
  selectedRows,
  data,
  sort,
  sortingQuery,
  onViewNotes,
}) => {
  const hasQuickbook = useHasQuickbook();
  const { formatDate, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: '',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <CustomCheckbox onChange={handleSelectAll} checked={selectedRows?.length === data?.length} />,
      render: (row) => (
        <span onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.id === row.id)}
          />
        </span>
      ),
    },
    {
      field: 'invoice_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='INVOICE #' field='invoice_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex align-items-center gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.invoice_id}
            </Typography>
            {!!row.invoice_notes?.length && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onViewNotes(row);
                }}
              >
                <NotesIcon width={14} height={14} />
              </div>
            )}
            {!!row.qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
          </div>
        );
      },
    },
    {
      field: 'customer',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='CUSTOMER' field='customer' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.customer && row.customer.company_name}
        </Typography>
      ),
    },
    {
      field: 'reference_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='REFERENCE ID' field='reference_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.reference_id}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'customer_billed_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SENT DATE' field='customer_billed_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.customer_billed_date)}
        </Typography>
      ),
    },
    {
      field: 'sent_method',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SENT METHOD' field='sent_method' />,
      render: (row) => {
        const isEmailed =
          ['2', '6', '10', '14'].includes(row.delivery_option) ||
          row.email_to ||
          (Array.isArray(row.emails_to) && row.emails_to.length > 0) ||
          (!Array.isArray(row.emails_to) && row.email_to);
        const isPrintMailed = ['4', '6', '12', '14'].includes(row.delivery_option) || row.mail_to_id;

        if (!isEmailed && !isPrintMailed) {
          return (
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              -
            </Typography>
          );
        }

        return (
          <div className='d-flex flex-column gap-1'>
            {isEmailed && (
              <NoMaxWidthTooltip
                title={
                  <Typography
                    variant='b2'
                    style={{
                      color: palette.white,
                      whiteSpace: 'pre-wrap',
                      display: 'inline-block',
                    }}
                  >
                    Emailed to{' '}
                    {Array.isArray(row?.emails_to)
                      ? row.emails_to.map((e) => e).join(',\n')
                      : row?.email_to || row?.emails_to || ''}{' '}
                    on {formatDateTime(row.processed_at)}
                  </Typography>
                }
              >
                <span>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Email
                  </Typography>
                </span>
              </NoMaxWidthTooltip>
            )}
            {isPrintMailed && (
              <NoMaxWidthTooltip
                title={
                  <Typography variant='b2' style={{ color: palette.white }}>
                    Mailed to {row.mail_to?.address1}, {row.mail_to?.address2 ? `${row.mail_to?.address2},` : ''}
                    {row.mail_to?.city?.name}, {row.mail_to?.state?.short_name} {row.mail_to?.zipcode}
                  </Typography>
                }
              >
                <span>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    Print and Mailed
                  </Typography>
                </span>
              </NoMaxWidthTooltip>
            )}
          </div>
        );
      },
    },
    {
      field: 'due_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DUE DATE' field='due_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {formatDate(row.due_date)}
        </Typography>
      ),
    },
    {
      field: 'age',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AGING' field='age' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.age}
        </Typography>
      ),
    },
    {
      field: 'total_amount',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AMOUNT' field='total_amount' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(row.total_amount)}
        </Typography>
      ),
    },
    {
      field: 'open_balance',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='OPEN BALANCE' field='open_balance' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {`${currency}${formatAmount(row.open_balance)}`}
        </Typography>
      ),
    },
    {
      field: 'actions',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='' />,
      render: (row) => {
        return (
          <RowActions
            row={row}
            onViewPDF={() => onViewPDF(row)}
            onMarkPaid={() => onMarkPaid(row)}
            onViewNotes={() => onViewNotes(row)}
            onRevertInvoice={() => onRevertInvoice(row)}
          />
        );
      },
    },
  ];
};
