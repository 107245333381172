import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from 'utils/constants';

export const SWrapper = styled.div``;

export const SBackButton = styled.div`
  margin: 4px 0 12px;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const SLink = styled(Link)`
  color: ${palette.indigo500};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: ${palette.indigo500};
  }
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const STableWrapper = styled.div`
  .action-button {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 12px;
    font-family: Inter, sans-serif;
  }

  .table-title {
    border-bottom: 1px solid ${palette.gray50};
    margin-bottom: 12px;
    padding-bottom: 8px;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 8px;
    }
  }
`;

export const STableFilters = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .flex-left {
    display: flex;
    align-items: center;
    column-gap: 16px;
    row-gap: 8px;
    flex-wrap: wrap;
  }

  .multi-select {
    .MuiInputBase-root {
      height: 32px;
      overflow: hidden;
    }
  }
`;

export const STable = styled.table`
  width: 100%;

  .header-row {
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 0 10px 12px;
      white-space: nowrap;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    &.bg-gray {
      background: ${palette.gray0};
    }

    &.total-row {
      td {
        padding: 10px 10px 22px;
      }
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
      visibility: hidden;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    td {
      padding: 12px 10px;
    }

    :hover {
      background: ${palette.gray0};

      .action-wrapper {
        visibility: visible;
      }
    }
  }
`;
