import React from 'react';
import Pagination from 'common/Pagination';
import TableWrapper from 'components/TableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { STableWrapper } from '../../../ReadyToInvoice/components/OtherRevenueTable/OtherRevenueTable.styles';

const OtherRevenueTable = ({
  loading,
  data,
  selectedFilters,
  setSelectedFilters,
  setSelectedRows,
  setOpenMarkPaid,
  columns,
}) => {
  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  if (loading) {
    return <TablePreLoader styleWrapper={{ marginTop: 0 }} />;
  }

  return (
    <STableWrapper>
      <div className='tableFixHead table-fixed-header-300'>
        <TableWrapper
          data={data?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
                rowsPerPageOptions: [25, 50, 100, 150, 250, 500],
              }),
          }}
          onRowClick={(e, rowData) => {
            setSelectedRows([rowData]);
            setOpenMarkPaid(true);
          }}
          columns={columns}
        />
      </div>
    </STableWrapper>
  );
};

export default OtherRevenueTable;
