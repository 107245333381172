import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SSchedule = styled.div`
  padding: 18px 24px;

  .schedule-footer {
    width: 100%;
    height: 64px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 8px 8px;
    background-color: ${palette.white};
  }
`;

export const STable = styled.table`
  width: 100%;
  table-layout: fixed;

  th {
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 4px;
    white-space: nowrap;
    vertical-align: top;
  }

  td {
    padding: 4px;
    border-left: none;
    border-right: none;
    vertical-align: top;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;
