import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;

  .filters-row {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }

  .status-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    padding: 2px 6px;
    background-color: ${palette.indigo0};
    transition: 0.4s;

    .remove-status-icon {
      display: none;
    }
  }

  .status-wrapper:hover {
    background-color: ${palette.indigo100};

    .remove-status-icon {
      display: inline;
    }
  }

    .underline-text {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: ${palette.indigo500};

        :hover {
            text-decoration: underline;
            cursor: pointer;
        }
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }
`;
