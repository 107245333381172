import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FastField, Form, Formik } from 'formik';
import classNames from 'classnames';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as XIcon } from 'assets/icons/deleteThin.svg';
import { getContactsBillingCharges } from 'Api/Planner';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import ContactEditModal from 'components/CreateShipment/CreateModals/ContactEditModal';
import classes from './sharpeUpdate.module.scss';

const ShareUpdate = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();
  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );
  const [showContactEditModal, setShowContactEditModal] = useState(false);
  const [selectedBillTo, setSelectedBillTo] = useState({});
  const [contactData, setContactData] = useState({ 0: [] });

  const styles = useMemo(() => {
    return {
      styleBody: {
        maxHeight: 600,
        overflowY: 'auto',
        padding: '20px',
      },
      modal: {
        width: 534,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
      labelStyle: {
        margin: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      inputDriver: {
        height: 32,
        width: '100%',
        borderRadius: '0 4px 4px 0',
      },
      selectStyle: {
        height: 32,
        width: 221,
        borderRadius: '4px 0 0 4px',
        marginLeft: 36,
      },
      dropDownStyles: {
        flexGrow: 0,
        width: 221,
      },
      menuStyle: {
        width: 221,
        marginLeft: 36,
      },
      editIcon: {
        height: 15,
        cursor: 'pointer',
        marginTop: 20,
      },
    };
  }, [use, palette]);
  const [contacts, setContacts] = useState();
  const [hasContact, setHasContact] = useState(false);

  const onChangeContactEditModal = (value) => {
    setShowContactEditModal(value);
  };

  const getContacts = () => {
    if (data?.shipment_billing[0]?.billing_customer?.id) {
      setSelectedBillTo({ 0: data?.shipment_billing[0]?.billing_customer });

      getContactsBillingCharges(data?.shipment_billing[0]?.billing_customer?.id).then((res) => {
        const newData = res.data?.map((item) => {
          return {
            ...item,
            label: item.contact_name,
            key: item.id,
            labelSelected: null,
          };
        });
        setContactData({ 0: newData });
        newData.unshift({
          label: 'Select Contact',
          key: null,
          labelSelected: null,
        });
        setContacts(newData);
      });
    }
    if (data?.shipment_option?.customer_contact_book) {
      setHasContact(true);
    }
  };

  const updateContactData = (data) => {
    if (!Array.isArray(data)) {
      setContactData(data);
    } else {
      setContactData({ 0: data });
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  const initialValues = useMemo(() => {
    return {
      customer: data?.shipment_option?.customer_contact_book?.id
        ? data?.shipment_option?.customer_contact_book?.id
        : '',
    };
  }, [contacts]);

  return (
    <>
      <Modal
        size='lg'
        centered
        show={isOpen}
        autoFocus={false}
        onHide={() => setIsOpen(false)}
        enforceFocus={false}
        style={styles.modal}
        dialogClassName='dialogClassName'
      >
        {contacts && (
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            {({ values, resetForm, submitForm, validateForm }) => {
              return (
                <Form>
                  <Modal.Body style={styles.modalBody}>
                    <div
                      className={classes.modalHeader}
                      style={{ backgroundColor: use(palette.white, palette.dark900) }}
                    >
                      <div className={classes.titles}>
                        <Typography variant='button2'>Share Updates</Typography>
                        <Typography variant='overLine'>{subTitle}</Typography>
                      </div>
                      <div
                        className={classes.xBtn}
                        onClick={() => {
                          setIsOpen(false);
                          resetForm();
                        }}
                      >
                        <XIcon style={{ flexShrink: 0 }} />
                      </div>
                    </div>

                    <div
                      className={classNames(classes.modalBody, use(classes.light, classes.dark))}
                      style={{
                        backgroundColor: use(palette.gray0, palette.dark800),
                        borderColor: use(palette.gray50, palette.gray700),
                      }}
                    >
                      <div className={classes.fieldsZone}>
                        <div className={classes.filedButtonWrapper}>
                          <div className={classes.errorWrapper}>
                            <FastField
                              name='customer'
                              component={CustomSelect}
                              options={contacts}
                              style={{ paddingLeft: 10 }}
                              styles={{ width: 350, height: 32 }}
                              labelStyle={styles.labelStyle}
                              className={classes.customer}
                              defaultText='Select Contact'
                              label='Send Tracking Link to Customer'
                            />
                          </div>
                          <EditIcon
                            style={styles.editIcon}
                            onClick={() => {
                              setShowContactEditModal(true);
                            }}
                          />
                          <CustomButton
                            type='primary'
                            title={hasContact ? 'Replace' : 'Share'}
                            onClick={() => onAddBtn(validateForm, resetForm, submitForm, values)}
                            styleButton={{
                              padding: '2px 8px',
                              whiteSpace: 'nowrap',
                              height: 32,
                              marginTop: 27,
                              display: 'flex',
                              justifyContent: 'center',
                              width: 100,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
      <ContactEditModal
        contactData={contactData}
        getContacts={getContacts}
        show={showContactEditModal}
        selectedBillTo={selectedBillTo}
        updateContactData={updateContactData}
        onChangeShow={onChangeContactEditModal}
        activeBillChargeIndex={0}
      />
    </>
  );
};

export default ShareUpdate;
