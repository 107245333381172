import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 10px 16px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 2%), 0 0 0 1px rgb(70 79 96 / 2%), 0 2px 5px rgb(89 96 120 / 30%);
  border-radius: 8px;

  .MuiAccordion-root {
    box-shadow: none;

    .MuiAccordionSummary-root {
      min-height: auto;
      padding: 0;

      .MuiAccordionSummary-content {
        margin: 0 12px 0 0;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }

    .accordion-arrow {
      padding: 6px;
      border-radius: 50%;
      background-color: ${palette.gray50};
    }
  }

  .detail-badge {
    display: inline-block;
    height: 18px;
    border: ${({ $badgeColor }) => `4px solid ${$badgeColor || palette.gray700}`};
    border-radius: 8px;
  }
`;
