import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import ExportCsv from 'componentsV2/ExportCsv';
import HeaderStar from 'components/HeaderStar';
import ImportCSV, { CSV_TYPE } from 'componentsV2/ImportCSV';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import DarkOverlay from '../../DarkOverlay';
import StopPointSearch from '../StopPointSearch/StopPointSearch';
import PopoverSettingsStopPoints from '../PopoverSettingsStopPoints/PopoverSettingsStopPoints';

import styles from './SecurityLogHeader.module.css';

const StopPointHeader = ({
  onOpenModal,
  filter,
  dragItem,
  getSettings,
  updateFilter,
  tableColumn,
  search,
  setSearch,
  advancedOpen,
  setAdvancedOpen,
  onSearch,
  onImportSuccess,
}) => {
  const [show, setShow] = useState(false);

  const popOverContent = (
    <Popover id='popover-basic' className='my_popover' style={{ background: palette.white }}>
      <Popover.Body>
        <PopoverSettingsStopPoints
          filter={filter}
          dragItem={dragItem}
          getSettings={getSettings}
          updateFilter={updateFilter}
          tableColumn={tableColumn}
          setShowPopover={(isOpen) => setShow(isOpen)}
        />
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className={styles.stopPointHeader_wrapper}>
        <HeaderStar title='Stop Points' />
        <div className='d-flex align-items-center gap-3'>
          <ImportCSV type={CSV_TYPE.STOP_POINTS} onSuccess={onImportSuccess} />
          <ExportCsv type='stop-points' height={36} />
          <CustomButton
            width={12}
            height={12}
            type='primary'
            title='Add Stop Point'
            leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={onOpenModal}
          />
        </div>
      </div>
      <div className='top_popover_search-wrapper d-flex align-items-center'>
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          overlay={popOverContent}
          rootClose={show}
          onToggle={setShow}
          show={show}
          defaultShow={false}
          delay={0}
        >
          <div className='top_popover' style={{ backgroundColor: palette.white, marginBottom: 0, marginRight: '16px' }}>
            <DataTableIcon widht={14} height={14} fill={palette.gray700} />
          </div>
        </OverlayTrigger>
        <StopPointSearch search={search} onChange={setSearch} />

        <CustomButton
          type='primary'
          title='Search'
          onClick={() => onSearch()}
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '5px 12px', margin: '0 0 0 8px' }}
        />
        <CustomButton
          type='primary'
          title=''
          leftIcon={
            advancedOpen ? (
              <MinusIcon fill='#fff' style={{ margin: 8 }} />
            ) : (
              <PlusIcon fill='#fff' style={{ margin: 8 }} />
            )
          }
          className='ms-3'
          styleButton={{ height: 32, margin: 0 }}
          onClick={() => setAdvancedOpen(!advancedOpen)}
        />

        <DarkOverlay visible={show} styles={{ zIndex: 500 }} />
      </div>
    </>
  );
};
export default StopPointHeader;
