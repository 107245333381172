import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../package.json';

export const useClearCaches = () => {
  const navigate = useNavigate();

  const checkCaches = () => {
    const version = localStorage.getItem('version');
    const user = localStorage.getItem('user');
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.window.location.reload(true);
      }

      if (user) {
        localStorage.clear();
        navigate('/login');
      }
      localStorage.setItem('version', packageJson.version);
      window.location.reload();
    }
  };

  useEffect(() => {
    checkCaches();
  }, []);
};
