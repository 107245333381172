import React from 'react';
import { Skeleton } from '@material-ui/lab';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SBoxContent } from 'pages/Dashboard/Dashboard.styles';
import { options } from './LineChart.data';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const LineChart = ({ total, loading, title, header, graphData }) => {
  const { use } = useTheme();

  // const ctx = document.getElementById('chart')?.getContext('2d');
  // const gradient = ctx?.createLinearGradient(0, 0, 0, 250);
  // gradient?.addColorStop(0, 'rgba(193,197,255,1)');
  // gradient?.addColorStop(1, 'rgba(193,197,255,0)');

  const data = {
    labels: [...new Array(13).fill('')],
    datasets: [
      {
        fill: true,
        // fillColor: gradient,
        label: '',
        data: graphData,
        borderColor: '#4F5AED',
        backgroundColor: 'rgba(79, 90, 237, 0.2)',
      },
    ],
  };

  data.labels[0] = moment().subtract(12, 'month').format('MMM YYYY');
  data.labels[11] = moment().format('MMM YYYY');

  return (
    <SBoxContent>
      {header}
      {!header && !!title && (
        <Typography variant='s2' style={{ color: use(palette.gray700) }}>
          {title}
        </Typography>
      )}
      {total === 0 ? (
        <div className='d-flex align-items-center justify-content-center p4' style={{ flex: 1 }}>
          <Typography variant='h2' style={{ color: use(palette.gray500) }}>
            No stats to show
          </Typography>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center' style={{ maxHeight: '350px' }}>
          {loading ? (
            <Skeleton variant='rect' animation='wave' height='100%' width='100%' style={{ borderRadius: '10px' }} />
          ) : (
            <Line id='chart' data={data} options={options} />
          )}
        </div>
      )}
    </SBoxContent>
  );
};

export default LineChart;
