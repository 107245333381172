import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SShipmentsWarning = styled.div`
  position: absolute;
  padding: 18px 24px;
  background-color: ${palette.white};
  border-radius: 6px;
  width: 440px;
  top: 0;
  right: -456px;
`;
