import React from 'react';
import moment from 'moment';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/notesSvg.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star-filled.svg';
import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import BidDate from 'pages/OfferedShipments/ShipmentDetails/components/BidDate';
import OfferExpiration from 'pages/OfferedShipments/ShipmentDetails/components/OfferExpiration';
import { SCarrierNameImage } from './BidHistory.styles';

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
};

const bidStatus = {
  1: { label: 'BID RECEIVED', color: palette.orange500, bgColor: palette.orange0 },
  2: { label: 'OFFER DECLINED', color: palette.red500, bgColor: palette.red0 },
  3: { label: 'AWARDED', color: palette.green500, bgColor: palette.green0 },
  4: { label: 'AWARD ACCEPTED', color: palette.green500, bgColor: palette.green0 },
  5: { label: 'AWARD DECLINED', color: palette.red500, bgColor: palette.red0 },
  6: { label: 'BID DECLINED', color: palette.red500, bgColor: palette.red0 },
  7: { label: 'EXPIRED', color: palette.red500, bgColor: palette.red0 },
};

export const useColumns = ({ shipment, onNoteClick, sort, sortingQuery, onUpdatePreferBid, offerOptions }) => {
  const { convertToCustomerTime } = useDateFormat();
  const loadedMiles = Math.round(shipment?.loaded_miles || 0);
  const shipmentAmount = shipment?.shipment_billing?.reduce((acc, cur) => acc + Number(cur.total_amount), 0);

  return [
    {
      field: 'preferred',
      title: <ColumnHeader title='PREF' field='preferred' />,
      render: (row) => (
        <StarIcon
          fill={row.carrier?.preferred ? palette.amber500 : palette.white}
          stroke={row.carrier?.preferred ? undefined : palette.gray500}
          width={16}
          height={17}
          className='pointer ms-2'
          onClick={(e) => {
            e.stopPropagation();
            onUpdatePreferBid(row);
          }}
        />
      ),
    },
    {
      field: 'bid_by',
      title: <ColumnHeader title='BID BY' field='bid_by' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <SCarrierNameImage>
          {row.carrier?.logo_path || row.driver?.image ? (
            <img
              src={row.carrier?.logo_path || row.driver?.image}
              alt='img'
              width={30}
              height={30}
              className='carrier-image'
            />
          ) : (
            <CarrierIcon width={30} height={30} className='carrier-image' />
          )}
          <div className='d-flex flex-column align-items-start gap-1'>
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {row.carrier?.name || `${row.driver.fname} ${row.driver.lname}`}{' '}
              {!!row.offer_accepted && !!row.carrier && !!row.shipment_stops_schedule_dates && (
                <NoteIcon width={14} height={14} className='pointer' onClick={() => onNoteClick(row, shipment)} />
              )}
            </Typography>
            {!!row.selected_contact_user && (
              <Typography variant='b2' style={{ color: palette.gray500 }}>
                {row.selected_contact_user.contact_name}
              </Typography>
            )}
          </div>
        </SCarrierNameImage>
      ),
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <div className='d-flex flex-column align-items-start gap-1'>
          {!row.offer_accepted && row.shipment_offer?.expiration_date ? (
            <Chip
              label={bidStatus[7]?.label}
              labelColor={bidStatus[7]?.color}
              bgColor={bidStatus[7]?.bgColor}
              fontSize='11px'
              fontWeight={500}
            />
          ) : (
            <Chip
              label={bidStatus[row.offer_accepted]?.label || 'OFFERED'}
              labelColor={bidStatus[row.offer_accepted]?.color || palette.gray700}
              bgColor={bidStatus[row.offer_accepted]?.bgColor || palette.gray0}
              fontSize='11px'
              fontWeight={500}
            />
          )}
          {row.offer_accepted === 2 && !!row.decline_reason && (
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              Reason: {row.decline_reason.name}
            </Typography>
          )}
          {row.offer_accepted === 6 && !!row.declined_by && (
            <Typography variant='b2' style={{ color: palette.gray500 }}>
              {row.declined_by.first_name} {row.declined_by.last_name}
            </Typography>
          )}
          {row.offer_accepted === 3 && <OfferExpiration offerOptions={offerOptions} awardDate={row.award_date} />}
        </div>
      ),
    },
    {
      field: 'bid_date',
      title: <ColumnHeader title='BID DATE' field='bid_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (row.bid_date && !!row.offer_accepted ? <BidDate bidDate={row.bid_date} /> : '-'),
    },
    {
      field: 'rpm',
      title: <ColumnHeader title='RPM' field='rpm' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.bid_amount ? `$${formatNumber(row.rpm)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'location',
      title: <ColumnHeader title='DRIVER LOCATION' field='location' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.city?.name && row.state?.short_name ? `${row.city.name}, ${row.state.short_name}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'empty_miles',
      title: <ColumnHeader title='EMPTY MILES' field='empty_miles' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {Math.round(row.eta?.empty_miles || 0)}
        </Typography>
      ),
    },
    {
      field: 'loaded_miles',
      title: <ColumnHeader title='LOADED MILES' field='loaded_miles' sort={sort} onClick={sortingQuery} />,
      render: () => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {loadedMiles}
        </Typography>
      ),
    },
    {
      field: 'meet_eta',
      title: <ColumnHeader title='MEET ETAs' field='meet_eta' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.meet_eta}
        </Typography>
      ),
    },
    {
      field: 'eta',
      title: <ColumnHeader title='ETA' field='eta' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <div className='d-flex flex-column'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.eta?.eta_hours || '-'}
          </Typography>
          {!!row.driver_available_from && !!row.eta && (
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              {convertToCustomerTime(moment(row.driver_available_from).add(row.eta.minutes, 'minutes'))}
            </Typography>
          )}
        </div>
      ),
    },
    {
      field: 'eta_margin',
      title: <ColumnHeader title='ETA MARGIN' field='eta_margin' sort={sort} onClick={sortingQuery} />,
      render: (row) =>
        row.eta && row.eta_margin_labels ? (
          <Tooltip title={row.eta_margin > 0 ? 'Driver is early' : 'Driver will be late'}>
            <span>
              <Typography
                variant='b2'
                style={{ color: row.eta_margin > 0 ? palette.green500 : palette.red500 }}
                className='d-flex align-items-center'
              >
                <>
                  {row.eta_margin !== 0 && (
                    <ArrowUp
                      fill={row.eta_margin > 0 ? palette.green500 : palette.red500}
                      style={{ transform: row.eta_margin > 0 ? 'rotate(180deg)' : '' }}
                    />
                  )}
                  {row.eta_margin_labels[0] ? `${row.eta_margin_labels[0]}d` : ''} {row.eta_margin_labels[1]}h{' '}
                  {row.eta_margin_labels[2]?.length === 1 ? `0${row.eta_margin_labels[2]}` : row.eta_margin_labels[2]}m
                </>
              </Typography>
            </span>
          </Tooltip>
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
        ),
    },
    {
      field: 'offered_date',
      title: <ColumnHeader title='OFFERED DATE' field='updated_at' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.shipment_offer?.offer_date
            ? convertToCustomerTime(`${row.shipment_offer.offer_date} ${row.shipment_offer.offer_time} `)
            : '-'}
        </Typography>
      ),
    },
    {
      field: 'offered_amount',
      title: <ColumnHeader title='OFFERED AMOUNT' field='offered_amount' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          ${formatNumber(row.posted_amount)}
        </Typography>
      ),
    },
    {
      field: 'bid_amount',
      title: <ColumnHeader title='BID AMOUNT' field='bid_amount' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          ${formatNumber(row.bid_amount)}
        </Typography>
      ),
    },
    {
      field: 'margin',
      title: <ColumnHeader title='MARGIN' field='margin' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        const percent = row.bid_amount ? ((shipmentAmount - Number(row.bid_amount)) * 100) / shipmentAmount : null;
        return (
          <Typography
            variant='b2'
            style={{
              color: !percent || percent === 0 ? palette.gray700 : percent > 0 ? palette.green500 : palette.red500,
            }}
          >
            {percent !== null ? (
              <>
                {percent < 0 ? '-' : percent > 0 ? '+' : ''}
                {formatNumber(Math.abs(percent))}%
              </>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
  ];
};
