import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .dropzone {
    width: ${({ $width }) => $width || '100%'};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${palette.white};
    padding: 16px 20px;
    border-radius: 6px;
    border: 1px dashed ${palette.gray200};

    .dropzone-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 4px;
    }
  }

  .small-dropzone {
    width: ${({ $width }) => $width || '100%'};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${palette.white};
    padding: 5px 12px;
    border-radius: 6px;
    border: 1px dashed ${palette.gray200};

    .small-dropzone-content {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 12px;
    }
  }

  .simple-dropzone {
    padding: 2px 0;

    .simple-dropzone-content {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }

  .uploaded-files {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 2px 8px;
    border-radius: 6px;
    background-color: ${({ $uploadedBgColor }) => $uploadedBgColor || palette.indigo0};
    width: fit-content;

    .delete-file-icon {
      display: flex;
      cursor: pointer;
    }
  }

  .uploaded-replace-files {
    background-color: ${({ $uploadedBgColor }) => $uploadedBgColor || palette.indigo50};
  }
`;
