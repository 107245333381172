import axios from 'services/axios';

export async function getAlertsList(params) {
  const { data } = await axios.get('customer/alert-center', { params });
  return data;
}

export async function getAlertDetails(id) {
  const { data } = await axios.get(`customer/alert-center/get-details/${id}`);
  return data;
}

export async function updateAlert(id, body) {
  const { data } = await axios.put(`customer/alert-center/update-details/${id}`, body);
  return data;
}

export async function uploadDriverDocument(body) {
  const { data } = await axios.post(`driver/upload-document`, body);
  return data;
}

export async function markAlertsResolved(body) {
  const { data } = await axios.post(`customer/alert-center/mark-alert-resolved`, body);
  return data;
}
