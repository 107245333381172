import React from 'react';
import classNames from 'classnames';
import { Overlay } from 'react-bootstrap';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { numberWithCommas, palette } from 'utils/constants';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { useTheme } from 'context/themeContext';
import CustomButton from 'components/CustomButton/CustomButton';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import styles from './AdditionalServices.module.css';

const addonStatusColor = {
  Active: { color: '#14804A', backgroundColor: '#E1FCEF' },
  Cancelled: { color: '#D12953', backgroundColor: '#FAF0F3' },
  Pending: { color: '#4F5AED', backgroundColor: '#F0F1FA' },
  'In Trial': { color: '#14804A', backgroundColor: '#E1FCEF' },
};

const addonLicenseType = {
  1: 'Per Driver',
  2: 'Per Staff',
  3: 'Per Equipment',
  4: 'Flat',
};

export const brokerageAddon = {
  id: 269,
  customer_id: 779,
  addon_plan_id: 62,
  actual_price: 500,
  amount: '500',
  duration: 'monthly',
  next_renewal_date: '2029-11-16 15:46:30',
  status: 'In Trial',
  emailSentForExpiration: '2023-10-16 15:46:32',
  failure_cnt: 4,
  created_at: '2023-10-16T15:46:32.000000Z',
  license_cnt: 1,
  per_license_amount: 500,
  payment_method: 'credit-card',
  is_coupon_applied: 0,
  coupon_code: null,
  is_upgrade: 0,
  is_downgrade: 0,
  upgrade_downgrade_amount: 0,
  upgrade_downgrade_license_count: 0,
  agreement_number: '',
  invoice_pdf: null,
  addon_plan: {
    id: 62,
    addon_id: 26,
    plan_title: 'Brokerage Suite License',
    monthly_amount: 500,
    setup_one_time_fee: null,
    addon: {
      id: 26,
      name: 'Brokerage',
      description:
        'Add Carriers to Carrier List, Carrier Onboarding and Dispatch to Carriers. Send Confirmations to Carriers.',
      plan_id: 'qkxwy6',
      image: 'https://truckindigital-staging.s3.amazonaws.com/root/assets/addons/image_1697465772.png',
      bill_per: '4',
      created_at: '2023-10-16T14:16:12.000000Z',
      status: 1,
      webhook_required: '0',
      guide: null,
      guide_type: null,
      webhook_url: null,
      category: 'Brokerage',
      assigned_type: {
        id: 4,
      },
    },
  },
};

export const useColumns = ({
  sort,
  sortingQuery,
  elementsRef,
  showTool,
  setShowTool,
  setManage,
  setCancel,
  cancelDowngrade,
  handleUpgradeDowngrade,
}) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();

  return [
    {
      field: 'add_on',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          ADD-ON
        </Typography>
      ),
      render: (rowData) => {
        const name = rowData?.addon_plan?.addon?.name;
        const image = rowData?.addon_plan?.addon?.image;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <figure style={{ width: 35, height: 35, marginTop: 14 }}>
              <img style={{ width: 35, height: 35 }} src={image} alt='img' />
            </figure>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
              <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                {name}
              </Typography>
              {!!rowData?.is_downgrade && (
                <div>
                  <Chip
                    label={`Downgrade to ${rowData.upgrade_downgrade_license_count || 0} License(s) on ${formatDate(
                      rowData.next_renewal_date
                    )}`}
                    bgColor='#FCF2E6'
                    labelColor='#AA5B00'
                  />
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'description',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          DESCRIPTION
        </Typography>
      ),
      render: (rowData) => (
        <div style={{ display: 'flex' }}>
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.addon_plan?.plan_title}
          </Typography>
        </div>
      ),
    },
    {
      field: 'license_type',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          LICENSE TYPE
        </Typography>
      ),
      render: (rowData) => {
        const licenseType = addonLicenseType[rowData?.addon_plan?.addon?.bill_per];
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {licenseType}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        const status = rowData?.status;
        return (
          <Chip
            label={status?.toUpperCase()}
            labelColor={addonStatusColor[rowData?.status]?.color}
            bgColor={addonStatusColor[rowData?.status]?.backgroundColor}
            fontSize='11px'
            fontWeight={500}
          />
        );
      },
    },
    {
      field: 'license_cnt',
      title: <ColumnHeader title='QTY' field='license_cnt' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.license_cnt}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'amount',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
          AMOUNT
        </Typography>
      ),
      render: (rowData) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            {!!rowData?.amount && !!rowData.license_cnt && (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                ${numberWithCommas(Number(rowData.amount).toFixed(2))}
              </Typography>
            )}
            <Chip label={rowData?.duration?.toUpperCase()} fontSize='11px' fontWeight={500} />
          </div>
        );
      },
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='PURCHASE DATE' field='created_at' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{formatDate(rowData?.created_at)}</span>;
      },
    },
    {
      field: 'next_renewal_date',
      title: <ColumnHeader title='RENEWAL DATE' field='next_renewal_date' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => {
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData?.next_renewal_date
              ? rowData?.next_renewal_date?.includes('T')
                ? formatDate(rowData.next_renewal_date.split('T')[0])
                : formatDate(rowData.next_renewal_date)
              : '-'}
          </span>
        );
      },
    },
    {
      field: 'update',
      title: <Typography variant='s2' />,
      render: (rowData) => {
        if (rowData.status === 'In Trial') {
          return null;
        }

        return (
          <div className={styles.lastRow}>
            {rowData?.invoice_pdf && (
              <CustomButton
                type='secondary'
                title='Make Payment'
                styleButton={{ padding: '2px 8px', margin: '0 8px 0 0' }}
                styleTitle={{ fontSize: 14 }}
                onClick={() => null}
              />
            )}
            <div onMouseLeave={() => setShowTool((prev) => ({ ...prev, [rowData.id]: false }))}>
              {rowData.status !== 'Cancelled' && (
                <div
                  ref={elementsRef?.current?.[rowData.id]}
                  className={classNames([styles.editIconBox, { [styles.show]: showTool[rowData.id] }])}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTool((prev) => ({ ...prev, [rowData.id]: true }));
                  }}
                >
                  <EditIcon className={styles.editIcon} />
                </div>
              )}
              <Overlay show={!!showTool[rowData.id]} target={elementsRef?.current?.[rowData.id]} placement='left'>
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                  <div {...props} className={styles.dropDown}>
                    {rowData.addon_plan?.addon?.bill_per !== '4' && (
                      <>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setManage({
                              visible: true,
                              ...rowData,
                            });
                          }}
                          className={styles.text}
                        >
                          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                            Manage
                          </Typography>
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpgradeDowngrade(rowData);
                          }}
                          className={styles.text}
                        >
                          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                            Upgrade/Downgrade
                          </Typography>
                        </div>
                      </>
                    )}
                    {!!rowData?.is_downgrade && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          cancelDowngrade(rowData.id);
                        }}
                        className={styles.text}
                      >
                        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                          Cancel Downgrade
                        </Typography>
                      </div>
                    )}
                    <div
                      onClick={(e) => {
                        setCancel({
                          visible: true,
                          ...rowData,
                        });
                        e.stopPropagation();
                      }}
                      className={styles.text}
                    >
                      <Typography variant='s2' style={{ color: use(palette.red500) }}>
                        Cancel
                      </Typography>
                    </div>
                  </div>
                )}
              </Overlay>
            </div>
          </div>
        );
      },
    },
  ];
};
