import { Modal, Button } from 'react-bootstrap';
import './AddGroupModal.css';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import plus from 'assets/icons/drivers/plus.svg';
import { palette } from 'utils/constants';
import { DriverGroups } from 'Api/Driver';
import AddGroup from 'componentsV2/Group/AddGroup';
import CustomSelect from '../CustomSelect/CustomSelect';

export default function AddGroupsDrivers({ ...props }) {
  const [groups, setGroups] = useState([]);
  const [openAddGroup, setOpenAddGroup] = useState(false);

  const formik = useFormik({
    initialValues: {
      group_id: '',
    },
  });

  const getGroups = async () => {
    try {
      const { data } = await DriverGroups();
      setGroups(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddGroup = () => {
    const { group_id } = formik.values;
    const getGroup = groups.find((gr) => {
      return +gr.id === +group_id;
    });
    props.updateGroupProfile(getGroup, props.onHide, props.onSuccess, 'Driver groups has been updated successfully');
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (props.driverGroupInfo?.length) {
      formik.setFieldValue('group_id', props.driverGroupInfo[0]?.id);
    } else {
      formik.setFieldValue('group_id', props.driverGroupInfo?.id);
    }
  }, [props.driverGroupInfo]);

  return (
    <div className='modal-container driver-style-wrap'>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered enforceFocus={false}>
        <Modal.Header
          style={{
            backgroundColor: palette.white,
            color: palette.gray50,
            borderColor: palette.gray50,
          }}
        >
          <Modal.Title className='heading' id='contained-modal-title-vcenter' style={{ color: palette.gray900 }}>
            Add Tag&#40;s&#41;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-box driver-style-wrap' style={{ backgroundColor: palette.whiteBackground }}>
          <div className='content-wrapper-group'>
            <div className='head-container'>
              <p className='text-style' style={{ color: palette.gray700, marginLeft: '0' }}>
                Tag&#40;s&#41; <span className='required'>*</span>
              </p>

              <div className='add-container' onClick={() => setOpenAddGroup(true)}>
                <img src={plus} alt='plus' />
                <p className='add-new'>New Tag</p>
              </div>
            </div>
            <CustomSelect
              defaultValue='Select Tags..'
              options={groups}
              onChange={(value) => {
                formik.setFieldValue('group_id', value);
              }}
              onBlur={formik.handleBlur}
              value={formik?.values?.group_id}
              id='group_id'
              name='group_id'
              // patchProperty="group_name"
              propertyName='group_name'
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: palette.white, borderColor: palette.gray50 }}>
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' disabled={!formik.values.group_id} onClick={onAddGroup}>
                Save
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {!!openAddGroup && <AddGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={getGroups} />}
    </div>
  );
}
