import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';

import Input from 'common/Input';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import { getAlphabet, palette } from 'utils/constants';
import Uploader from 'common/Uploader';
import Autocomplete from 'common/Autocomplete';
import { getDocumentTypesByToken } from 'Api/Carriers';
import { SAddMore } from 'components/Carriers/AddCarrier/AddCarrier.styles';
import { useParams } from 'react-router-dom';

const Documents = ({ form, shipmentInfo }) => {
  const { dot, token } = useParams();
  const { values, handleChange, touchedErrors, setValues } = form;
  const { billOfLadingTitle } = useSelector((state) => state.root);
  const [documentTypes, setDocumentTypes] = useState();

  const deliveryStops = shipmentInfo?.shipment_stops?.map((item, index) => {
    if (item.stop_point_type === '2') {
      return {
        ...item,
        stopIndex: index,
      };
    }
    return null;
  });

  const removeRow = async (item, index) => {
    const newDocuments = values.documents.filter((_, idx) => idx !== index);
    setValues({ ...values, documents: newDocuments });
  };

  const addRow = () => {
    const newDocuments = [
      ...values.documents,
      {
        reference_id: '',
        type: '',
        file: '',
      },
    ];
    form.setValues({ ...values, documents: newDocuments });
  };

  useEffect(async () => {
    const types = await getDocumentTypesByToken(dot, token);
    setDocumentTypes(types.data);
  }, []);

  return (
    shipmentInfo && (
      <div className='d-flex justify-content-center'>
        <div className='d-flex flex-column mt-5'>
          <Typography variant='s2' style={{ color: palette.gray700, marginBottom: 5 }}>
            Please attach your Invoice <span className='required'>*</span>
          </Typography>
          <div className='d-flex align-items-center gap-2'>
            <Input
              name='invoiceReference'
              onChange={(e) => handleChange(`invoiceReference`, e.target.value)}
              style={{
                width: '220px',
              }}
              placeholder='Invoice Number'
              value={values.invoiceReference}
              error={touchedErrors.invoiceReference}
            />
            <Uploader
              type={3}
              uploaderLabel='Attach '
              document={values.invoice}
              onDrop={(files) => handleChange('invoice', files[0])}
              onRemove={() => handleChange('invoice', null)}
              error={touchedErrors.invoice}
              accept={['application/pdf']}
            />
          </div>

          {deliveryStops
            .filter((stop) => Number(stop?.stop_point_type) === 2)
            .map((stop, index) => {
              if (Number(stop?.stop_point_type) === 2) {
                return (
                  <div className='d-flex flex-column mt-4'>
                    <Typography variant='c1' style={{ color: palette.red500 }}>
                      STOP {getAlphabet(stop?.stopIndex)}&nbsp; DELIVERY
                    </Typography>
                    <div className='mb-2'>
                      <div className='d-flex gap-2'>
                        <Typography variant='s2'>{stop.stop_point?.location_name}</Typography>
                        <Typography variant='s3'>
                          {Number(stop?.stopIndex) + 1} of {shipmentInfo?.shipment_stops?.length}
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <Typography variant='s2' style={{ color: palette.gray700 }}>
                        Please attach the {billOfLadingTitle} <span className='required'>*</span>
                      </Typography>
                      <div className='d-flex align-items-center gap-2 mt-1'>
                        <Input
                          name={`bill_of_lading_array[${index}].billOfLadingReference`}
                          onChange={(e) => {
                            handleChange(`bill_of_lading_array[${index}].billOfLadingReference`, e.target.value);
                            handleChange(`bill_of_lading_array[${index}].shipment_stop_id`, stop.id);
                          }}
                          style={{
                            width: '220px',
                          }}
                          placeholder={`${billOfLadingTitle} Number`}
                          value={values.bill_of_lading_array?.[index]?.billOfLadingReference}
                          error={touchedErrors.bill_of_lading_array?.[index]?.billOfLadingReference}
                        />
                        <Uploader
                          type={3}
                          uploaderLabel='Attach '
                          document={values.bill_of_lading_array?.[index]?.bill_of_lading}
                          onDrop={(files) => handleChange(`bill_of_lading_array[${index}].bill_of_lading`, files[0])}
                          onRemove={() => handleChange(`bill_of_lading_array[${index}].bill_of_lading`, null)}
                          error={touchedErrors.bill_of_lading_array?.[index]?.bill_of_lading}
                          accept={['application/pdf']}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}

          <div className='d-flex  mt-4 '>
            <div>
              {values.documents.map((row, index) => (
                <div className='d-flex gap-3 mb-4 align-items-center'>
                  <div className='d-flex flex-column'>
                    <InputLabel>Additional Document</InputLabel>
                    <Input
                      name={`documents[${index}].reference_id`}
                      onChange={(e) => handleChange(`documents[${index}].reference_id`, e.target.value)}
                      style={{
                        width: '220px',
                      }}
                      placeholder='Document Reference'
                      value={row.reference_id}
                      error={touchedErrors.documents?.[index]?.reference_id}
                    />
                  </div>
                  <div className='d-flex flex-column'>
                    <InputLabel>Type of Document</InputLabel>

                    <div>
                      <Autocomplete
                        labelKey='name'
                        options={documentTypes}
                        value={values.documents[index]?.type}
                        name={`documents[${index}].type`}
                        style={{ width: '200px' }}
                        onChange={(e, value) => handleChange(`documents[${index}].type`, value)}
                        isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                        error={touchedErrors.documents?.[index]?.type}
                      />
                    </div>
                  </div>
                  <div className='w-100'>
                    <div style={{ height: 20 }} />
                    <Uploader
                      type={3}
                      document={row.file}
                      onDrop={(files) => handleChange(`documents[${index}].file`, files[0])}
                      onRemove={() => handleChange(`documents[${index}].file`, null)}
                      error={touchedErrors.documents?.[index]?.file}
                      accept={['application/pdf']}
                    />
                  </div>
                  <DeleteIcon fill={palette.red500} onClick={() => removeRow(row, index)} />
                </div>
              ))}
              <SAddMore onClick={addRow}>
                <PlusIcon />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  Add Another
                </Typography>
              </SAddMore>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Documents;
