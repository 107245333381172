import React from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import AddAccountModal from './AddAccountModal';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddAccount = (props) => {
  return (
    // <Elements stripe={stripePromise}>
    <AddAccountModal {...props} />
    // </Elements>
  );
};

export default AddAccount;
