import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as BillIcon } from 'assets/icons/billing.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import { ReactComponent as PrintCheck } from 'assets/icons/printCheck.svg';
import { ReactComponent as ExportIcon } from 'assets/icon.svg';

import Search from 'common/Search';
import Tooltip from 'common/Tooltip';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import TableWrapper from 'components/TableWrapper';
import DateRangePicker from 'common/DateRangePicker';
import ConfirmationModal from 'common/ConfirmationModal';
import QuickbooksSync from 'componentsV2/QuickbooksSync';
import CustomButton from 'components/CustomButton/CustomButton';
import { SEND_PAYMENT_KEYS } from 'components/SendPaymentModal/constants';
import AddBill from 'pages/Accounting/Payables/components/AddBill';
import MarkPaid from 'pages/Accounting/Payables/components/MarkPaid';
import TableFilters from 'pages/Accounting/Payables/TableSection/shared/TableFilters';
import { onChangeIsOpenSendPayment } from 'store/reducers/header.reducer';
import { palette } from 'utils/constants';
import { downloadCsv } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { useTheme } from 'context/themeContext';
import { deleteBill, exportBillsCsv, getBills } from 'Api/Payables';
import { useColumns } from './OpenBills.data';
import { initialFilters } from '../../TableSection.data';

const OpenBills = ({ vendor, refreshStats = () => null, carrierBill, getCounts = () => null }) => {
  const { use } = useTheme();
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const openCreate = searchParams.get('openCreate');
  const [openAddBill, setOpenAddBill] = useState(openCreate || false);
  const [openMarkPaid, setOpenMarkPaid] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [openBills, setOpenBills] = useState({ data: [] });
  const [billToEdit, setBillToEdit] = useState(null);
  const [billToDelete, setBillToDelete] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ field: 'aging', sortBy: 'desc' });
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const debouncedSearch = useDebounce(search, 500);
  const didMountRef = useRef(false);

  const getOpenBills = async () => {
    try {
      const sortField = `sort[][${sort.field}]`;
      const params = {
        status: 0,
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        vendor_id: vendor?.id || selectedFilters.vendor?.id || undefined,
        from: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        all: debouncedSearch || undefined,
        [sortField]: sort.sortBy,
      };

      const response = await getBills(params);
      setOpenBills(response);

      if (billToEdit) {
        const updated = response?.data?.find((i) => i.id === billToEdit.id);
        setBillToEdit(updated || null);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const deleteRow = async () => {
    if (!billToDelete?.id) {
      return;
    }
    setLoadingDelete(true);
    try {
      await deleteBill(billToDelete.id);
      showToaster({ type: 'success', message: 'Bill has been successfully deleted!' });
      getOpenBills();
      setBillToDelete(null);
      refreshStats();
      getCounts();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onExportCsv = async () => {
    if (!selectedRows?.length) {
      return;
    }
    setLoadingExport(true);
    try {
      const accounting_bills = selectedRows.map((i) => i.id);
      const response = await exportBillsCsv({ accounting_bills });
      if (response && typeof response === 'string') {
        await downloadCsv(response, 'open-bills.csv');
      } else {
        showToaster({ type: 'error', message: 'Something went wrong!' });
      }
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingExport(false);
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== openBills.data.length ? openBills.data : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const onAddBillClose = () => {
    setOpenAddBill(false);
    setBillToEdit(null);
  };

  const onEdit = (row) => {
    setBillToEdit(row);
    setOpenAddBill(true);
  };

  const onMarkPaid = (row) => {
    setSelectedRows([row]);
    setOpenMarkPaid(true);
  };

  const onDelete = (row) => {
    setBillToDelete(row);
  };

  const onMarkPaidSuccess = (data) => {
    getOpenBills();
    refreshStats();
    getCounts();
    setSelectedRows([]);
    if (data.some((item) => item.open_balance === 0)) {
      onAddBillClose();
    }
  };

  const onAddEditSuccess = (isRecurring, vendorId) => {
    if (isRecurring && !vendor) {
      navigate(`/accounting/payables?tab=3&vendorId=${vendorId}`);
    }

    getOpenBills();
    refreshStats();
    getCounts();
  };

  const onPrintReport = (reportId) => {
    const startDate = dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : '';
    const endDate = dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : '';

    navigate(`/reports/${reportId}${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''}`);
  };

  const infoTemplates = useCallback((type) => {
    return (
      <div className='d-flex align-items-start gap-1'>
        <InfoIcon width={12} height={12} fill={palette.white} style={{ marginTop: 4 }} />
        {type === 'print' ? (
          <span>This feature is temporary unavailable due to a new upgrade.</span>
        ) : (
          <span>This feature is not available for your company.</span>
        )}
      </div>
    );
  }, []);

  const listenEmitter = ({ detail }) => {
    if (detail.activeName === SEND_PAYMENT_KEYS.OPEN_BILLS) {
      setLoading(true);
      getOpenBills();
    }
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getOpenBills();
    didMountRef.current = true;
  }, [selectedFilters, dateRange, debouncedSearch, sort]);

  useEffect(() => {
    searchParams.delete('openCreate');
    setSearchParams(searchParams);
    window.addEventListener('on_print_check_complete', listenEmitter);
    return () => window.removeEventListener('on_print_check_complete', listenEmitter);
  }, []);

  const columns = useColumns({
    selectedRows,
    handleSelectRow,
    handleSelectAll,
    allSelected: openBills.data.length === selectedRows.length,
    hideSelectAll: !openBills.data.length,
    sort,
    sortingQuery,
    onEdit,
    onDelete,
    onMarkPaid,
  });

  return (
    <div>
      {selectedRows.length ? (
        <div className='d-flex mb-3 gap-2'>
          <CustomButton
            type='primary'
            title='Mark Paid'
            onClick={() => setOpenMarkPaid(true)}
            leftIcon={<CheckIcon fill={palette.white} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '4px 12px', marginTop: 0 }}
          />
          <div className='d-flex align-items-center'>
            <Tooltip title={infoTemplates()}>
              <span>
                <CustomButton
                  type='secondary'
                  title='Send ACH'
                  onClick={() => null}
                  styleButton={{ padding: '4px 12px', marginTop: 0 }}
                  leftIcon={<BillIcon width={16} height={16} fill={palette.gray700} style={{ marginRight: 4 }} />}
                />
              </span>
            </Tooltip>
          </div>
          <div className='d-flex align-items-center'>
            <span>
              <CustomButton
                type='secondary'
                title='Print Checks'
                onClick={() =>
                  dispatch(
                    onChangeIsOpenSendPayment({
                      open: true,
                      isAch: false,
                      defaultStep: 3,
                      data: _.cloneDeep(selectedRows),
                      fromTabName: SEND_PAYMENT_KEYS.OPEN_BILLS,
                    })
                  )
                }
                styleButton={{ padding: '4px 12px', marginTop: 0, marginLeft: 4 }}
                leftIcon={<PrintCheck width={16} height={16} fill={palette.gray700} style={{ marginRight: 4 }} />}
              />
            </span>
          </div>
          <Tooltip title='Export CSV'>
            <CustomButton
              type='secondary'
              styleButton={{ padding: '9px 12px', marginTop: 0 }}
              leftIcon={<ExportIcon fill={palette.gray700} />}
              onClick={onExportCsv}
              disabled={loadingExport}
            />
          </Tooltip>
          <QuickbooksSync
            action='bills'
            onSuccess={() => {
              getOpenBills();
              refreshStats();
              getCounts();
            }}
          />
        </div>
      ) : (
        <div className='d-flex justify-content-between align-items-center mb-3'>
          {vendor ? (
            <div className='d-flex align-items-center gap-3'>
              <DateRangePicker
                top='-108px'
                initialRangeName='All Time'
                dateRange={dateRange}
                setDateRange={setDateRange}
                type='allTime'
              />
              <Search search={search} onChange={setSearch} className='search-input' />
            </div>
          ) : (
            <TableFilters
              dateRange={dateRange}
              setDateRange={setDateRange}
              search={search}
              setSearch={setSearch}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          )}
          {!carrierBill && (
            <div className='d-flex align-items-center gap-3'>
              {!vendor && (
                <>
                  <CustomButton
                    type='secondary'
                    title='Print Report Summary'
                    leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
                    styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
                    styleTitle={{ fontSize: 14, fontWeight: 500 }}
                    onClick={() => onPrintReport(13)}
                  />
                  <CustomButton
                    type='secondary'
                    title='Print Report Detailed'
                    leftIcon={<PrintIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
                    styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}
                    styleTitle={{ fontSize: 14, fontWeight: 500 }}
                    onClick={() => onPrintReport(17)}
                  />
                </>
              )}
              <QuickbooksSync action='bills' onSuccess={getOpenBills} />
              <CustomButton
                type='primary'
                title='Add Bill'
                leftIcon={<PlusIcon style={{ marginRight: 10 }} />}
                styleButton={{ padding: '5px 12px', margin: 0, fontSize: '14px' }}
                onClick={() => setOpenAddBill(true)}
              />
            </div>
          )}
        </div>
      )}

      {loading ? (
        <TableSkeleton />
      ) : (
        <div className='tableFixHead table-fixed-header-300'>
          <TableWrapper
            data={openBills.data}
            rowPerPage={1000}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            columns={columns}
            onRowClick={(e, row) => onEdit(row)}
            components={{
              Pagination: () =>
                Pagination({
                  data: openBills,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                  rowsPerPageOptions: [25, 50, 100, 150],
                }),
            }}
          />
        </div>
      )}
      {openAddBill && (
        <AddBill
          open={openAddBill}
          onClose={onAddBillClose}
          onSuccess={onAddEditSuccess}
          bill={billToEdit}
          vendor={vendor}
          onMarkPaid={() => onMarkPaid(billToEdit)}
        />
      )}
      {!!billToDelete && (
        <ConfirmationModal
          title='Bill'
          open={!!billToDelete}
          onClose={() => setBillToDelete(null)}
          onConfirm={deleteRow}
          disabled={loadingDelete}
        />
      )}
      {openMarkPaid && (
        <MarkPaid
          open={openMarkPaid}
          onClose={() => setOpenMarkPaid(false)}
          bills={selectedRows}
          onSuccess={onMarkPaidSuccess}
        />
      )}
    </div>
  );
};

export default OpenBills;
