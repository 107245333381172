import React from 'react';
import Modal from 'common/Modal';
import Chip from 'common/Chip';
import { palette } from 'utils/constants';

const EquipmentTypes = ({ open, onClose, equipmentTypes, moreModalType }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<div>{moreModalType === 'states' ? 'Operating States' : 'Operating Equipment'}</div>}
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='500px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'done',
          type: 'primary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      {equipmentTypes?.map((item) => {
        return (
          <Chip
            size='medium'
            label={item?.title || item.short_name}
            labelColor={palette.indigo500}
            fontWeight={500}
            bgColor={palette.indigo0}
            height='20px'
            style={{ margin: '5px' }}
          />
        );
      })}
    </Modal>
  );
};

export default EquipmentTypes;
