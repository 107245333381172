import uuid from 'react-uuid';

export const convertVendorsData = (vendors) => {
  return vendors.map((vendor) => {
    return {
      id: vendor.id,
      key: uuid(),
      ownership_type: 2,
      title: vendor.name,
    };
  });
};

export const convertOwnerOperatorData = (owners) => {
  return owners.map((owner) => {
    return {
      id: owner.id,
      key: uuid(),
      ownership_type: 3,
      title: owner.owner_operator_name,
    };
  });
};
