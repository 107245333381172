import styled from 'styled-components';

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: default;

  :hover span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SApprovedFor = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  min-height: 33px;
`;
