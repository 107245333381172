import React, { useEffect, useState } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import Modal from 'common/Modal';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getAlphabet, palette } from 'utils/constants';
import { splitShipment } from 'Api/Planner';
import { getEquipmentDimensions } from 'Api/EquipmentImport';
import { addShipmentStop, deleteShipmentStop, getStops } from 'Api/Shipment';
import AddStops from './components/AddStops';
import SplitLine from './components/SplitLine';
import SplitSection from './components/SplitSection';
import { validationSchema } from './validationSchema';
import { createStopConverter, getInitialValues } from './SplitShipment.data';
import { SSplitShipment } from './SplitShipment.styles';

const SplitShipment = ({ open, onClose, shipment }) => {
  const { shipment_id, shipment_billing, shipment_stops } = shipment || {};
  const { id } = useParams();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [loadingStops, setLoadingStops] = useState(false);
  const [dragAndDropOrder, setDragAndDropOrder] = useState([]);
  const [stopsList, setStopsList] = useState(shipment_stops || []);
  const [stopsCreated, setStopsCreated] = useState(false);
  const [dimensions, setDimensions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingRevert, setLoadingRevert] = useState(false);

  const onSubmit = async (values) => {
    if (loadingCreate) {
      return;
    }

    try {
      setLoadingCreate(true);
      const delivery = stopsList[stopsList.length - 1];
      const allStops = dragAndDropOrder.flat();

      const promises = values.stops.map((stop) => {
        const formData = createStopConverter(stop, values.stop_point, delivery, shipment_id, allStops);
        return addShipmentStop(formData);
      });
      const response = await Promise.all(promises);
      const newStops = response.map((item) => item.data);

      setDragAndDropOrder((prevState) =>
        prevState.map((section) => {
          return section.map((stop) => {
            if (stop.isEmpty) {
              const stopToReplace = { ...newStops[0], isNew: true };
              newStops.shift();
              return stopToReplace;
            }

            return stop;
          });
        })
      );

      setStopsCreated(true);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingCreate(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(dragAndDropOrder, dimensions),
    onSubmit,
    validationSchema,
  });

  const getDimensions = async () => {
    try {
      const { data } = await getEquipmentDimensions();
      setDimensions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const orderingInLegsStops = (data) => {
    const legsStopsData = [];
    data?.forEach((el) => {
      const legCount = el.leg;
      const foundedIndex = legsStopsData?.findIndex((el) => Number(el?.number) === +legCount);

      if (foundedIndex === -1) {
        legsStopsData.push({ number: +legCount, stops: [{ ...el }] });
      } else {
        legsStopsData[foundedIndex] = {
          ...legsStopsData[foundedIndex],
          stops: [...legsStopsData[foundedIndex].stops, { ...el }],
        };
      }
    });
    return legsStopsData;
  };

  const getStopsData = async () => {
    try {
      const { data } = await getStops({ shipment_id: shipment_id || id });
      setStopsList(data);
      initialValueDragAndDrop(data);
    } catch (e) {
      //
    } finally {
      setLoadingStops(false);
    }
  };

  const initialOrderOptimize = (stopsArr) => {
    const timeCorrected = stopsArr.map((stop) => {
      const { stop_point_type, from, scheduled_date } = stop;
      const timeOrder =
        stop_point_type === 2
          ? moment(`${scheduled_date} ${from}`, 'YYYY-MM-DD HH:mm').add(2, 'hour').format('x')
          : moment(`${scheduled_date} ${from}`, 'YYYY-MM-DD HH:mm').format('x');
      return { ...stop, timeOrder };
    });

    // timeCorrected.sort((a, b) => {
    //   return a.timeOrder - b.timeOrder;
    // });

    const sections = [];

    timeCorrected.forEach((stop, index, arr) => {
      if (index === 0) {
        sections.push([stop, { id: uuid(), isEmpty: true, stop_point_type: '2' }]);
        return;
      }

      if (Number(stop.stop_point_type) === 1) {
        sections.push([stop, { id: uuid(), isEmpty: true, stop_point_type: '2' }]);
        return;
      }

      if (Number(stop.stop_point_type) === 2) {
        if (Number(arr[index - 1].stop_point_type) === 1) {
          sections.push([{ id: uuid(), isEmpty: true, stop_point_type: '1' }, stop]);
        } else {
          sections[sections.length - 1].push(stop);
        }
        return;
      }

      if (Number(stop.stop_point_type) === 3) {
        if (Number(arr[index - 1].stop_point_type) === 1) {
          sections.push([{ id: uuid(), isEmpty: true, stop_point_type: '1' }, stop]);
        } else {
          sections[sections.length - 1].push(stop);
        }
      }
    });

    return sections;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside of any list
    if (!destination) {
      return;
    }

    // Get the source and destination droppable Ids
    const sourceDroppableId = parseInt(source.droppableId.replace('drag-', ''), 10);
    const destinationDroppableId = parseInt(destination.droppableId.replace('drag-', ''), 10);

    if (sourceDroppableId === destinationDroppableId) {
      // Reordering in same list
      const newList = Array.from(dragAndDropOrder[sourceDroppableId]);
      const [removed] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, removed);

      const newDragAndDropOrder = Array.from(dragAndDropOrder);
      newDragAndDropOrder[sourceDroppableId] = newList;

      setDragAndDropOrder(newDragAndDropOrder);
    } else {
      // Moving between lists
      const sourceList = Array.from(dragAndDropOrder[sourceDroppableId]);
      const destinationList = Array.from(dragAndDropOrder[destinationDroppableId]);
      const draggedStop = sourceList[source.index];
      const sameInDestination = destinationList[source.index];
      const shouldRecalculateEmptyBoxes =
        !stopsCreated &&
        sameInDestination?.isEmpty &&
        sameInDestination?.stop_point_type === draggedStop.stop_point_type;

      const [removed] = shouldRecalculateEmptyBoxes
        ? sourceList.splice(source.index, 1, {
            id: uuid(),
            isEmpty: true,
            stop_point_type: draggedStop.stop_point_type,
          })
        : sourceList.splice(source.index, 1);

      destinationList.splice(
        shouldRecalculateEmptyBoxes ? source.index : destination.index,
        shouldRecalculateEmptyBoxes ? 1 : 0,
        removed
      );

      const newDragAndDropOrder = Array.from(dragAndDropOrder);
      newDragAndDropOrder[sourceDroppableId] = sourceList;
      newDragAndDropOrder[destinationDroppableId] = destinationList;

      setDragAndDropOrder(
        newDragAndDropOrder.filter((item, index) => (index > 1 ? item.some((i) => !i.isEmpty) : true))
      );
    }
  };

  function initialValueDragAndDrop(stops) {
    const stopsArr = [];
    orderingInLegsStops(stops)?.forEach((leg, indexLeg) => {
      const { reference_id } = shipment_billing?.[indexLeg] ?? {};
      leg.stops.forEach((stop, indexStop) => {
        stopsArr.push({ ...stop, order: { shipments: indexLeg, stop: indexStop }, reference_id });
      });
    });
    setDragAndDropOrder(initialOrderOptimize(stopsArr));
  }

  function checkNestedArrays(nestedArr) {
    return nestedArr.every((arr) => {
      if (arr.length >= 1) {
        const arrIncludesType1 = arr.some((item) => item.stop_point_type === '1' && !item.isEmpty);
        const arrIncludesType2 = arr.some((item) => item.stop_point_type === '2' && !item.isEmpty);

        return arrIncludesType1 && arrIncludesType2;
      }
      return false;
    });
  }

  const validateDates = () => {
    let firstStopIndex = null;
    let secondStopIndex = null;
    let sectionIndex = null;

    const isValid = dragAndDropOrder.every((stops, i) => {
      let isValidDate = true;

      stops.forEach((stop, index, arr) => {
        const prevStop = arr[index - 1];
        if (!prevStop || !isValidDate) {
          return;
        }
        const currentStopDate = moment(`${stop.scheduled_date} ${stop.from}`);
        const prevStopDate = moment(`${prevStop.scheduled_date} ${prevStop.from}`);

        if (!currentStopDate.isAfter(prevStopDate)) {
          isValidDate = false;
          firstStopIndex = index - 1;
          secondStopIndex = index;
          sectionIndex = i;
        }
      });

      return isValidDate;
    });

    return { isValid, firstStopIndex, secondStopIndex, sectionIndex };
  };

  const validateStops = () => {
    let firstPickupIndex = null;
    let firstDeliveryIndex = null;
    let orderErrorSectionIndex = null;

    const isValidOrder = dragAndDropOrder.every((stops, i) => {
      const firstPickup = stops.findIndex((item) => Number(item.stop_point_type) === 1);
      const firstDelivery = stops.findIndex((item) => Number(item.stop_point_type) === 2);

      if (firstPickup > firstDelivery) {
        firstPickupIndex = firstPickup;
        firstDeliveryIndex = firstDelivery;
        orderErrorSectionIndex = i;
        return false;
      }

      return true;
    });

    return { isValidOrder, firstPickupIndex, firstDeliveryIndex, orderErrorSectionIndex };
  };

  const handleSplit = async () => {
    const showError = !checkNestedArrays(dragAndDropOrder);
    if (showError) {
      showToaster({ type: 'error', message: 'Each split should have at least one pickup and one delivery!' });
      return;
    }

    const { isValid, firstStopIndex, secondStopIndex, sectionIndex } = validateDates();
    const { isValidOrder, firstPickupIndex, firstDeliveryIndex, orderErrorSectionIndex } = validateStops();

    if (!isValid) {
      showToaster({
        type: 'error',
        message: `Stop ${getAlphabet(secondStopIndex)} cannot be scheduled before Stop ${getAlphabet(
          firstStopIndex
        )} in Shipment ${shipment_id}-S${sectionIndex + 1}`,
      });
      return;
    }

    if (!isValidOrder) {
      showToaster({
        type: 'error',
        message: `Stop ${getAlphabet(firstDeliveryIndex)} (Delivery) cannot be placed before Stop ${getAlphabet(
          firstPickupIndex
        )} (Pickup) in Shipment ${shipment_id}-S${orderErrorSectionIndex + 1}`,
      });
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('shipment_id', shipment_id);

      const existingStops = dragAndDropOrder.map((item) => item.filter((i) => !i.isEmpty));

      for (let i = 0; i < existingStops.length; i++) {
        for (let j = 0; j < existingStops[i].length; j++) {
          if ('id' in existingStops[i][j]) {
            formData.append(`shipments[${i}][${j}]`, existingStops[i][j].id);
          }
        }
      }
      await splitShipment(formData);
      showToaster({ message: 'Success!', type: 'success' });
      navigate('/planner', { state: { allValue: shipment_id?.split('-S')[0] } });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const removeSplitSection = (index) => {
    setDragAndDropOrder((prevState) => prevState.filter((item, i) => i !== index));
  };

  const onRevertStops = async () => {
    if (loadingRevert) {
      return;
    }

    try {
      setLoadingRevert(true);
      const stopsToDelete = dragAndDropOrder.flat().filter((stop) => stop.isNew);

      const promises = stopsToDelete.map((item) => deleteShipmentStop(item.id));

      await Promise.all(promises);
      await getStopsData();
      setStopsCreated(false);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingRevert(false);
    }
  };

  const onStopUpdate = (newData) => {
    setDragAndDropOrder((prevState) => {
      return prevState.map((section) => {
        return section.map((stop) =>
          stop.id === newData.id
            ? { ...stop, scheduled_date: newData.scheduled_date, from: newData.from, to: newData.to }
            : stop
        );
      });
    });
  };

  useEffect(() => {
    if (dragAndDropOrder.length && dimensions.length) {
      form.setValues(getInitialValues(dragAndDropOrder, dimensions));
    }
  }, [dragAndDropOrder, dimensions]);

  useEffect(() => {
    setLoadingStops(true);

    getStopsData();
  }, [shipment_id]);

  useEffect(() => {
    getDimensions();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <div style={{ backgroundColor: palette.white }} className='d-flex flex-column'>
          <Typography variant='button2'>Split Shipment {shipment_id}</Typography>
          <Typography variant='overLine'>
            Shipment {shipment_id} {shipment_billing?.[0]?.billing_customer?.company_name}
          </Typography>
        </div>
      }
      $bgColor={palette.white}
      $maxWidth='96vw'
      $minWidth='1100px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      backdrop='static'
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: () => {
            if (stopsCreated) {
              onRevertStops();
            }
            onClose();
          },
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Split Shipment',
          onClick: () => handleSplit(),
          disabled: loading,
        },
      ]}
    >
      {loadingStops ? (
        <Loader loading />
      ) : (
        <SSplitShipment>
          <div className='add-stops-section'>
            {!stopsCreated && <AddStops form={form} shipmentId={shipment_id} dimensions={dimensions} />}
          </div>
          <div className='split-section'>
            <DragDropContext onDragEnd={onDragEnd}>
              {dragAndDropOrder.map((item, i) => (
                <div className='droppable-container'>
                  <Typography
                    variant='h5'
                    style={{ color: palette.gray900 }}
                    className='d-flex align-items-center gap-2'
                  >
                    Shipment {`${shipment_id}-S${i + 1}`}
                    {i > 1 && item.every((stop) => stop.isEmpty) && !stopsCreated && (
                      <div onClick={() => removeSplitSection(i)} className='pointer'>
                        <MinusIcon fill={palette.red400} />
                      </div>
                    )}
                  </Typography>
                  <Droppable droppableId={`drag-${i}`} style={{ overflowY: 'auto' }}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <div className='optimize-route-container'>
                          <SplitSection data={item} onStopUpdate={onStopUpdate} />
                        </div>
                        <div>{provided.placeholder}</div>
                      </div>
                    )}
                  </Droppable>

                  {i < dragAndDropOrder.length - 1 && (
                    <SplitLine
                      onSaveStops={i === 0 ? form.handleSubmit : undefined}
                      onRevertStops={onRevertStops}
                      stopsCreated={stopsCreated}
                    />
                  )}
                </div>
              ))}
            </DragDropContext>
            <SplitLine />
          </div>
        </SSplitShipment>
      )}
    </Modal>
  );
};

export default SplitShipment;
