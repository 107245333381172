import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as Notes } from 'assets/icons/notesSmall.svg';
import { ReactComponent as Check } from 'assets/icons/checkGreen.svg';
import { ReactComponent as NoCheck } from 'assets/icons/closeRed.svg';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import styles from 'components/TableShipments/detailsRow/steps/Detention/Detention.module.css';
import user from 'assets/icons/profile.svg';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';

import RowActions from './components/RowActions';
import { SUnderlineText } from './DetentionTable.styles';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);

const Title = ({ title }) => {
  const { use } = useTheme();
  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useColumns = ({
  setCheckoutData,
  setBillData,
  setWarningOpen,
  setWarningData,
  billed,
  onModifyDuration,
}) => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'shipment_id',
      title: <Title title='SHIPMENT ID' />,
      render: (rowData) => {
        return (
          <SUnderlineText>
            <Typography
              variant='b2'
              onClick={(e) => {
                if (rowData.shipment_id) {
                  e.stopPropagation();
                  navigate(`/shipment/${rowData.shipment_id}`);
                }
              }}
            >
              {rowData.shipment_id || '-'}
            </Typography>
          </SUnderlineText>
        );
      },
    },
    {
      field: 'location_name',
      title: <Title title='LOCATION NAME' />,
      render: (rowData) => {
        const location_name = rowData?.shipment_stop?.stop_point?.location_name;
        const note = rowData?.note;
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200), marginRight: 5 }}>
                {location_name || ''}
              </Typography>
              {note && (
                <LightTooltip title={note} placement='top' arrow>
                  <Notes />
                </LightTooltip>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'equipment_id',
      title: <Title title='EQUIPMENT ID' />,
      render: (rowData) => {
        const equipment_id = rowData?.equipment?.equipment_id;
        const equipment_type = rowData?.equipment?.equipment_type?.title;
        const length = rowData?.equipment?.length?.length;
        const unit = rowData?.equipment?.length?.unit;
        return (
          <div style={{ display: 'flex', gap: 8 }}>
            {equipment_id && (
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {equipment_id || ''}
              </Typography>
            )}
            {length && (
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {length || ''}
                {unit || ''}
              </Typography>
            )}
            {equipment_type && (
              <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {equipment_type || ''}
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'customer',
      title: <Title title='CUSTOMER' />,
      render: (rowData) => {
        const customer = rowData?.shipment_billing?.billing_customer?.customer_name;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {customer || ''}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'arrived_date',
      title: <Title title='DETENTION START' />,
      render: (rowData) => {
        const arrived_date = rowData?.arrived_date;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {arrived_date ? convertToCustomerTime(arrived_date, null, true) : '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'departed_date',
      title: <Title title='DETENTION END' />,
      render: (rowData) => {
        const departed_date = rowData?.departed_date;
        return (
          <div style={{ display: 'flex' }}>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {departed_date ? (
                convertToCustomerTime(departed_date, null, true)
              ) : (
                <span style={{ marginLeft: 30 }}>-</span>
              )}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'duration',
      title: <Title title='DURATION' />,
      render: (rowData) => {
        const duration_days = rowData?.duration_days;
        const duration_hours = rowData?.duration_hours;
        const duration_minutes = rowData?.duration_minutes;
        return (
          <div style={{ display: 'flex', gap: 6 }}>
            {!!duration_days && (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {duration_days}d
              </Typography>
            )}
            {(!!duration_hours || duration_hours === 0) && (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {duration_hours}h
              </Typography>
            )}
            {(!!duration_minutes || duration_minutes === 0) && (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {duration_minutes}m
              </Typography>
            )}
          </div>
        );
      },
    },
    ...(!billed
      ? [
          {
            field: 'amount',
            title: <Title title='AMOUNT' />,
            render: (rowData) => {
              const paymentTerms = rowData?.shipment_billing?.billing_customer?.customer_payment_term;
              const isTrailer = rowData?.equipment?.equipment_type?.vehicle_type_id === 2;
              const rate = isTrailer ? paymentTerms?.detention_trailer_dropped_billed_at : paymentTerms?.billed_at;
              const daysHours = isTrailer ? paymentTerms?.detention_trailer_dropped_start_after_per : 'HOURS';
              const duration =
                daysHours === 'HOURS'
                  ? (rowData.duration_days * 24 + rowData.duration_hours + rowData.duration_minutes / 60).toFixed(2)
                  : (rowData.duration_days + rowData.duration_hours / 24 + rowData.duration_minutes / 1440).toFixed(2);

              const totalAmount = formatNumber(duration * rate);

              return (
                <div>
                  {currency}
                  {totalAmount}
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: 'updated_by',
      title: <Title title='UPDATED BY' />,
      render: (rowData) => {
        const updated_by = rowData?.updated_by;
        return (
          <div className={styles.lastRow}>
            <div>
              {updated_by ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <div style={{ height: 30 }}>
                    <figure style={{ display: 'flex', width: 30, height: 30, borderRadius: '50%' }}>
                      <img
                        style={{
                          display: 'flex',
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                        }}
                        src={updated_by?.profile_logo || user}
                        alt='img'
                      />
                    </figure>
                  </div>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    {updated_by?.first_name} {updated_by?.last_name}
                  </Typography>
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <div style={{ height: 30 }}>
                    <figure style={{ display: 'flex', width: 30, height: 30, borderRadius: '50%' }}>
                      <img
                        style={{
                          display: 'flex',
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                        }}
                        src={truckinLogo}
                        alt='img'
                      />
                    </figure>
                  </div>
                  <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                    System
                  </Typography>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'billed',
      title: <Title title='BILLED' />,
      render: (rowData) => {
        return <div style={{ display: 'flex', marginLeft: 15 }}>{rowData?.billed_at ? <Check /> : <NoCheck />}</div>;
      },
    },
    {
      field: 'update',
      render: (rowData) => (
        <RowActions
          rowData={rowData}
          onBill={() => {
            if (!rowData.departed_date) {
              setWarningData(rowData);
              setWarningOpen(true);
              return;
            }
            setBillData(rowData);
          }}
          onCheckout={() => {
            setCheckoutData(rowData);
          }}
          onModifyDuration={() => onModifyDuration(rowData)}
        />
      ),
    },
  ];
};
