import React from 'react';
import { useSelector } from 'react-redux';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { formatNumber, palette } from 'utils/constants';
import { SFlexRow, SWrapper } from './VendorCard.styles';

const VendorCard = ({ data, onClick, className }) => {
  const hasQuickbook = useHasQuickbook();
  const { currency } = useSelector((state) => state.root);
  const { name, open_balance, add_to_report, active, qb_sync } = data ?? {};

  return (
    <SWrapper onClick={onClick} className={className}>
      <SFlexRow>
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='s2'>{name}</Typography>
          {!!add_to_report && <Chip label='1099' />}
          {!!qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
          {!active && <Chip label='INACTIVE' labelColor={palette.red500} bgColor={palette.red0} />}
        </div>
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {currency}
          {formatNumber(open_balance)}
        </Typography>
      </SFlexRow>
    </SWrapper>
  );
};

export default VendorCard;
