import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import CustomPagination from 'components/CustomPagination/CustomPagination';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import styles from 'components/SecurityLog/SecurityLogFooter/SecurityLogFooter.module.css';

const TableFooter = ({
  totalCount,
  totalLength,
  rowPerPage,
  onChangeRowPerPage,
  lastPage,
  currentPage,
  onPageChange,
  perPageOptions,
}) => {
  const { use } = useTheme();

  const pageChangeHandler = (page) => {
    if (lastPage !== 1) {
      onPageChange(page);
    }
  };

  return (
    <td className='d-flex mt-2 justify-content-between align-items-center'>
      <div className='d-flex'>
        <span className={styles.securityLog_rowsPerPage}>
          {(currentPage - 1) * rowPerPage + 1}-{currentPage === lastPage ? totalCount : currentPage * totalLength} of{' '}
          {totalCount} | Rows per page
        </span>
        <Dropdown
          className={styles.dropdown}
          onSelect={(value) => {
            onChangeRowPerPage(Number(value));
          }}
        >
          <Dropdown.Toggle
            placement='top'
            variant='outline-secondary'
            id='dropdown-basic'
            className={styles.securityLog_dropdownToggle}
            style={{
              color: use(palette.gray700, palette.gray200),
              backgroundColor: use(palette.white, palette.dark800),
            }}
          >
            {rowPerPage}
            <div className={styles.securityLog_arrows_wrapper}>
              <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            className={styles.securityLog_menu}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
          >
            {(perPageOptions || [25, 50, 100, 250, 500]).map((el) => (
              <Dropdown.Item
                eventKey={String(el)}
                active={+rowPerPage === el}
                style={{
                  color: +rowPerPage === el ? use(palette.white, palette.white) : use(palette.gray700, palette.gray200),
                }}
                key={el}
              >
                {el}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={styles.customPagination}>
        <CustomPagination
          lastPage={lastPage}
          pageSize={rowPerPage}
          totalCount={totalCount}
          currentPage={currentPage}
          onPageChange={pageChangeHandler}
        />
      </div>
    </td>
  );
};

export default TableFooter;
