import { date, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  highway_tax_expire_date: date().when(['highway_tax_active', 'highway_tax_no_expire'], {
    is: (highway_tax_active, highway_tax_no_expire) => !!highway_tax_active && !highway_tax_no_expire,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  highway_tax_date_attach: string().when('highway_tax_active', {
    is: true,
    then: string().trim().required('Required'),
  }),
});
