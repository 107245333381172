import moment from 'moment/moment';

export const contactTypes = [
  { id: 1, title: 'Primary' },
  { id: 2, title: 'Secondary' },
  { id: 3, title: 'Dispatch' },
  { id: 4, title: 'Broker' },
  { id: 5, title: 'Support' },
  { id: 6, title: 'Operations' },
  { id: 7, title: 'Manager' },
  { id: 8, title: 'Technical' },
  { id: 9, title: 'Sales' },
  { id: 10, title: 'Corporate' },
  { id: 11, title: 'Emergency' },
  { id: 12, title: 'Tracking' },
  { id: 13, title: 'After Hours' },
  { id: 14, title: 'Accounting' },
];

export const getItemSubtotalAmount = (quantity, price, is_taxable, tax_percentage = 0) => {
  if (is_taxable) {
    return Number((Number(quantity) * Number(price) * (Number(tax_percentage) / 100 + 1)).toFixed(2));
  }
  return Number((Number(quantity) * Number(price)).toFixed(2));
};

export const getItemTaxAmount = (quantity, price, is_taxable, tax_percentage = 0) => {
  if (is_taxable) {
    return Number((Number(quantity) * Number(price) * Number(tax_percentage / 100)).toFixed(2));
  }
  return 0;
};

export const getInitialValues = (invoice) => {
  const contact_type = contactTypes.find((contact) => contact.id === invoice?.contact_user?.contact_type_id);
  const customerContact = { ...invoice?.contact_user, contact_type };

  return {
    bill_date: invoice?.customer_billed_date ? moment(invoice?.customer_billed_date).toDate() : new Date(),
    due_date: invoice?.due_date ? moment(invoice?.due_date).toDate() : null,
    is_auto_debt_enabled: !!invoice?.is_auto_debt_enabled || false,
    payment_method: invoice?.payment_method || '',
    customer: invoice?.customer || null,
    reference_id: invoice?.reference_id || '',
    terms: invoice?.payment_terms || null,
    email_to: invoice?.contact_user ? customerContact : null,
    items: invoice?.items || [
      {
        id: Date.now(),
        item: null,
        desc: '',
        quantity: '',
        price: '',
        is_taxable: false,
        tax_amount: '',
        total_amount: '',
      },
    ],
    is_recurring: invoice?.is_recurring || false,
    recurring_days: invoice?.recurring_days || '',
    recurring_months: invoice?.recurring_months || null,
    recurring_duration: invoice?.recurring_duration || 1,
    recurring_frequency: invoice?.recurring_frequency || '',
    invoice_id: invoice?.invoice_id,
    note: invoice?.note || '',
    attachments: invoice?.attachments || [],
  };
};

export const getRequestBody = (values) => {
  const {
    bill_date,
    due_date,
    is_auto_debt_enabled,
    payment_method,
    customer,
    reference_id,
    terms,
    email_to,
    items,
    is_recurring,
    recurring_days,
    recurring_months,
    recurring_duration,
    recurring_frequency,
    note,
    attachments,
    invoice_id,
  } = values || {};

  const formData = new FormData();
  formData.append('bill_date', moment(bill_date).format('YYYY-MM-DD'));
  formData.append('due_date', moment(due_date).format('YYYY-MM-DD'));
  formData.append('is_auto_debt_enabled', is_auto_debt_enabled ? '1' : '0');
  if (payment_method) {
    formData.append('payment_method', payment_method);
  }

  formData.append('customer_customer_id', customer.id);
  formData.append('reference_id', reference_id);
  formData.append('terms', terms.id);
  formData.append('contact_user_id', email_to.id);

  items.forEach((item, index) => {
    formData.append(`items[${index}][item_id]`, item.item.id);
    formData.append(`items[${index}][desc]`, item.desc);
    formData.append(`items[${index}][quantity]`, item.quantity);
    formData.append(`items[${index}][price]`, item.price);
    formData.append(`items[${index}][is_taxable]`, item.is_taxable ? '1' : '0');
    formData.append(`items[${index}][tax_amount]`, item.tax_amount || '0');
    formData.append(`items[${index}][total_amount]`, item.total_amount);
  });

  formData.append('is_recurring', is_recurring ? '1' : '0');

  if (is_recurring) {
    formData.append('recurring_days', recurring_days);
    formData.append('recurring_months', recurring_months);
    formData.append('recurring_duration', recurring_duration);
    formData.append('recurring_frequency', recurring_duration === 1 ? 0 : recurring_frequency);
  }

  if (note) {
    formData.append('note', note);
  }

  if (attachments?.length) {
    attachments.forEach((attachment) => {
      if (attachment?.type) {
        formData.append(`attachments[]`, attachment);
      }
    });
  }

  formData.append('invoice_id', invoice_id);
  formData.append('is_draft', 0);

  return formData;
};
