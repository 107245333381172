import React from 'react';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const Title = ({ title }) => {
  const { use } = useTheme();

  return (
    <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
      {title}
    </Typography>
  );
};

export const useColumns = () => {
  return [
    {
      field: 'type',
      title: <Title title='INCIDENT TYPE' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {row?.type}
        </Typography>
      ),
    },
    {
      field: 'incident_count',
      title: <Title title='' />,
      render: (row) => (
        <Typography as='div' variant='s2' style={{ textAlign: 'end' }}>
          {row?.incident_count}
        </Typography>
      ),
    },
  ];
};
