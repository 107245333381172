import moment from 'moment/moment';
import { statusOptions } from 'componentsV2/AlertCenter/TakeActions/TakeActions.data';

export const photoIdentificationTypes = [
  { id: 1, name: 'Driving License' },
  { id: 2, name: 'Passport' },
  { id: 3, name: 'ID' },
];

export const getInitialValues = (alert) => {
  const { photo_identification, photo_identification_expiration, photo_identification_type, status } = alert || {};

  return {
    photo_identification: photo_identification || '',
    photo_identification_expiration: moment().isBefore(moment(photo_identification_expiration))
      ? new Date(alert.photo_identification_expiration)
      : null,
    photo_identification_type: photoIdentificationTypes.find((i) => i.id === Number(photo_identification_type)) || null,
    status: statusOptions.find((i) => i.name === status) || null,
  };
};
