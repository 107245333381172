import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Collapse } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as FilterDark } from 'assets/icons/filterIconDark.svg';

import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import CustomButton from 'components/CustomButton/CustomButton';
import { TRUCK_DAY_CAB_OPTION, TRUCK_SLEEPER_OPTION } from 'components/Equipment/TableEquipment/constat';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { equipmentStatuses } from 'Api/Map';
import { getEquipmentRequiredTrailers, getEquipmentRequiredVehicles, Groups } from 'Api/Equipment';
import { MAP_FILTER_SEARCH_BY_VEHICLE, MAP_FILTER_SEARCH_BY_TRAILER, CAN_TRAVEL_TO } from '../constat';
import classes from './MapHeader.module.scss';

// tabName === 'vehicle' || 'trailer';
const MapHeader = ({ isVehicle, onSearch, searchBy, setSearchBy, filter, setFilter, driverSelectData }) => {
  const { use } = useTheme();
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [errorLocation, setErrorLocation] = useState('');
  const [badgeData, setBadgeData] = useState([]);
  /** DATA ADVANCED * */
  const [vehicleStatusData, setVehicleStatusData] = useState([]);
  const [trailerStatusData, setTrailerStatusData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [trailerTypes, setTrailerTypes] = useState([]);
  /** DATA ADVANCED * */
  /** ENDPOINTS ADVANCED* */
  const GetVehicleStatuses = async () => {
    try {
      const { data } = await equipmentStatuses('vehicle');
      setVehicleStatusData(data);
    } catch (e) {
      // Do noting
    }
  };

  const GetTrailerStatuses = async () => {
    try {
      const { data } = await equipmentStatuses('trailer');
      setTrailerStatusData(
        data.map((item) => ({
          ...item,
          equipment_status: item.equipment_status === 'Assigned' ? 'Hooked' : item.equipment_status,
        }))
      );
    } catch (e) {
      // Do noting
    }
  };

  const getTrailerTypes = async () => {
    try {
      const { data } = await getEquipmentRequiredTrailers();
      setTrailerTypes(data);
    } catch (e) {
      // Do noting
    }
  };

  const GetGroups = async () => {
    try {
      const { data } = await Groups();
      setGroupsData(data);
    } catch (e) {
      // Do noting
    }
  };
  const GetVehicleType = async () => {
    try {
      const { data } = await getEquipmentRequiredVehicles();
      if (Array.isArray(data)) {
        const filteredData = data.filter((el) => +el.id !== 1);
        filteredData.push(TRUCK_DAY_CAB_OPTION, TRUCK_SLEEPER_OPTION);
        setVehicleData(filteredData);
      }
    } catch (e) {
      // Do noting
    }
  };
  /** ENDPOINTS ADVANCED* */

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
      if (key === 'equipment_type_id') {
        delete newFilter.truck_type;
      }
    } else {
      newFilter[key] = value;
      if (key === 'equipment_type_id') {
        newFilter.truck_type = value.map((el) => ({ id: el.truckType }));
      }
    }
    return setFilter(newFilter);
  };

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    return {
      name: item?.[nameKey] || '',
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const onSearchFilter = () => {
    if (
      Number(searchBy?.id) === 2 &&
      (((!filter.city || !filter.state) && !filter.postCode) || (!filter.city && !filter.state && !filter.postCode))
    ) {
      setErrorLocation('State and City or ZipCode is required');
      return;
    }
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const onChangeLocationValues = (e) => {
    if (errorLocation) {
      setErrorLocation('');
    }
    setFilter((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      const newItemValue = itemValue.filter((el) => el.id !== id);

      if (newItemValue.length === 0) {
        delete newFilter[key];
        if (key === 'equipment_type_id') {
          delete newFilter.truck_type;
        }
      } else {
        newFilter[key] = newItemValue;
        if (key === 'equipment_type_id') {
          newFilter.truck_type = newItemValue.map((el) => ({ id: el.truckType }));
        }
      }
    } else if (typeof itemValue === 'string') {
      if (key === 'city' || key === 'state') {
        delete newFilter.city;
        delete newFilter.state;
      } else {
        delete newFilter[key];
      }
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));
  };

  const handleClearAll = () => {
    setBadgeData([]);
    setFilter({});
    onSearch({});
  };

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(!!filter?.city || !!filter?.state || !!filter?.postCode || !!filter?.radius
        ? [
            {
              title: 'Location',
              children: [
                ...(filter?.city ? [{ name: filter.city, id: 'city', key: 'city' }] : []),
                ...(filter?.state ? [{ name: filter.state, id: 'state', key: 'state' }] : []),
                ...(filter?.postCode ? [{ name: filter.postCode, id: 'postCode', key: 'postCode' }] : []),
                ...(filter?.radius ? [{ name: `radius(${filter.radius})`, id: 'radius', key: 'radius' }] : []),
              ],
            },
          ]
        : []),
      ...(filter.status_id
        ? [
            {
              title: 'Status',
              children: filter.status_id.map((item) =>
                normalizeBadgeData(item, 'equipment_status', 'id', { key: 'status_id' })
              ),
            },
          ]
        : []),
      ...(filter.group_id
        ? [
            {
              title: 'Tag',
              children: filter.group_id.map((item) =>
                normalizeBadgeData(item, 'group_name', 'id', { key: 'group_id' })
              ),
            },
          ]
        : []),
      ...(filter.equipment_type_id
        ? [
            {
              title: 'Vehicle Type',
              children: filter.equipment_type_id.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'equipment_type_id' })
              ),
            },
          ]
        : []),
      ...(filter.can_travel_to
        ? [
            {
              title: 'Can Travel To',
              children: filter.can_travel_to.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'can_travel_to' })
              ),
            },
          ]
        : []),
      ...(filter?.equipment_id
        ? [
            {
              title: 'Equipment ID',
              children: [{ name: filter.equipment_id, id: 'equipment_id', key: 'equipment_id' }],
            },
          ]
        : []),
      ...(filter?.retired
        ? [{ title: 'Retired', children: [{ name: filter.retired, id: 'retired', key: 'retired' }] }]
        : []),
      ...(filter?.driver_id
        ? [
            {
              title: 'Driver',
              children: filter.driver_id.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'driver_id' })),
            },
          ]
        : []),
    ];
  }, []);

  const OptionsSearchBy = useMemo(
    () => (isVehicle ? MAP_FILTER_SEARCH_BY_VEHICLE : MAP_FILTER_SEARCH_BY_TRAILER),
    [isVehicle]
  );

  const getIcon = useCallback(
    (name) => {
      switch (name) {
        case 'arrow':
          return (
            <ArrowDown style={{ width: 10, height: 10, marginTop: 2 }} fill={use(palette.gray700, palette.gray200)} />
          );
        case 'filter':
          return (
            <FilterDark
              style={{ width: 12, height: 12, marginRight: 2 }}
              fill={use(palette.gray700, palette.gray200)}
            />
          );
        case 'plusMinus':
          return advancedOpen ? (
            <MinusIcon fill='#fff' style={{ margin: 8 }} />
          ) : (
            <PlusIcon fill='#fff' style={{ margin: 8 }} />
          );
        default:
          return (
            <ArrowDown style={{ width: 10, height: 10, marginTop: 2 }} fill={use(palette.gray700, palette.gray200)} />
          );
      }
    },
    [advancedOpen]
  );

  useEffect(() => {
    handleClearAll();
  }, [isVehicle]);

  useEffect(() => {
    GetVehicleStatuses().catch();
    GetTrailerStatuses().catch();
    getTrailerTypes().catch();
    GetGroups().catch();
    GetVehicleType().catch();
  }, []);

  return (
    <div className={classes.mapHeaderWrapper}>
      <div className={classes.filterWrapper}>
        <div className={classes.fieldsWrapper}>
          <Autocomplete
            width='130px'
            value={searchBy}
            labelKey='title'
            options={OptionsSearchBy}
            popupIcon={getIcon('arrow')}
            borderRadius='4px 0 0 4px'
            onChange={(e, val) => setSearchBy(val)}
            inputProps={{ startAdornment: getIcon('filter') }}
          />
          {searchBy?.id === 1 && (
            <Input
              name='equipment_id'
              placeholder='Search'
              value={filter?.equipment_id || ''}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearchFilter();
                }
              }}
              classNameInput={classes.fieldEquipment}
              onChange={(e) => setFilter((p) => ({ ...p, equipment_id: e.target.value }))}
            />
          )}

          {searchBy?.id === 2 && (
            <div className={classes.locationWrapper}>
              <Input
                name='city'
                placeholder='City'
                value={filter?.city || ''}
                classNameInput={classes.fieldCity}
                onChange={(e) => onChangeLocationValues(e)}
                error={errorLocation}
              />
              <Input
                name='state'
                placeholder='State'
                value={filter?.state || ''}
                classNameInput={classes.fieldInputState}
                onChange={(e) => onChangeLocationValues(e)}
              />

              <div className={classes.fieldWithTextsWrapper}>
                <Input
                  name='postCode'
                  placeholder='Zip'
                  value={filter?.postCode || ''}
                  classNameInput={classes.fieldWithText}
                  onChange={(e) => onChangeLocationValues(e)}
                />
                <div className={classes.fieldText}>
                  <span>Zip Code</span>
                </div>
              </div>

              <div className={classes.fieldWithTextsWrapper}>
                <Input
                  name='radius'
                  placeholder='Miles'
                  value={filter?.radius || ''}
                  classNameInput={classes.fieldWithText}
                  onChange={(e) => onChangeLocationValues(e)}
                />
                <div className={classes.fieldText}>
                  <span>Miles</span>
                </div>
              </div>
            </div>
          )}

          {searchBy?.id === 3 && (
            <Autocomplete
              multiple
              limitTags={1}
              width='261px'
              labelKey='title'
              options={driverSelectData}
              borderRadius='0 4px 4px 0'
              value={filter.driver_id || []}
              onChange={(e, value) => onChangeMultiSelectValues('driver_id', value)}
            />
          )}
        </div>
        <CustomButton
          type='primary'
          title='Search'
          onClick={onSearchFilter}
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '5px 12px', margin: '0 0 0 16px' }}
        />
        <CustomButton
          type='primary'
          leftIcon={getIcon('plusMinus')}
          styleButton={{ height: 32, margin: '0 0 0 16px' }}
          onClick={() => setAdvancedOpen(!advancedOpen)}
        />
      </div>

      <Collapse in={advancedOpen}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.status_id || []}
                options={isVehicle ? vehicleStatusData : trailerStatusData}
                placeholder='Status'
                onChange={(e, value) => onChangeMultiSelectValues('status_id', value)}
                getTagLabel={(option) => (option ? option?.equipment_status : '')}
                getOptionLabel={(option) => (option ? option?.equipment_status : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                options={CAN_TRAVEL_TO}
                placeholder='Can Travel To'
                value={filter?.can_travel_to || []}
                onChange={(e, value) => onChangeMultiSelectValues('can_travel_to', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.group_id || []}
                options={groupsData}
                placeholder='Tags'
                onChange={(e, value) => onChangeMultiSelectValues('group_id', value)}
                getTagLabel={(option) => (option ? option?.group_name : '')}
                getOptionLabel={(option) => (option ? option?.group_name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.equipment_type_id || []}
                options={isVehicle ? vehicleData : trailerTypes}
                placeholder={isVehicle ? 'Vehicle Type' : 'Trailer Type'}
                onChange={(e, value) => onChangeMultiSelectValues('equipment_type_id', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>
          </div>

          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>

      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div key={badge.title} className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div key={child.key} className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index ? <span className={classes.comma}>,</span> : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MapHeader;
