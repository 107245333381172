import { array, date, number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  vendor: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  payment_term: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  reference_id: string().trim().required('Required'),
  items: array()
    .of(
      object({
        item: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        desc: string().trim().required('Required').nullable(),
        quantity: number().required('Required').nullable(),
        price: number().required('Required').nullable(),
      })
    )
    .min(1, 'Required'),
  repeat_every_times: number().when('is_recurring', {
    is: true,
    then: number().required('Required').nullable(),
  }),
  due_day: number().when('is_recurring', {
    is: true,
    then: number().required('Required').nullable(),
  }),
  recurring_months: object().when('is_recurring', {
    is: true,
    then: object()
      .shape({ name: string().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  due_day_category: object().when('is_recurring', {
    is: true,
    then: object()
      .shape({ name: string().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  next_renew_date: date().when('is_recurring', {
    is: true,
    then: validator.futureRequiredDate(),
  }),
  end_date: validator.date(),
  date_entered: validator.requiredDate(),
  bill_due_date: validator.requiredDate(),
});
