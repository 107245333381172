import jsPDF from 'jspdf';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { formatNumber, palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Name', 8, top);
  doc.text('Description', 48, top);
  doc.text('Qty', 72, top);
  doc.text('Cost Per Qty', 88, top);
  doc.text('Total Value', 112, top);
  doc.text('Date Added', 137, top);
  doc.text('Date Removed', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createTableRow = (doc, data, top, convertToCustomerTime, currency) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${data.name || '-'}`, 8, top);
  doc.text(`${data.description || '-'}`, 48, top);
  doc.text(`${data.quantity || '-'}`, 72, top);
  doc.text(`${data.cost_per_quantity ? `${currency}${formatNumber(data.cost_per_quantity)}` : '-'}`, 88, top);
  doc.text(`${data.total_value ? `${currency}${formatNumber(data.total_value)}` : '-'}`, 112, top);
  doc.text(`${data.created_at ? convertToCustomerTime(data.created_at) : '-'}`, 137, top);
  doc.text(`${data.deleted_at ? convertToCustomerTime(data.deleted_at) : '-'}`, pageWidth - 8, top, {
    align: 'right',
  });
};

const createTableRows = (doc, inventory, convertToCustomerTime, currency) => {
  if (!inventory?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  inventory.forEach((item, index) => {
    createTableRow(doc, item, top + index * 5, convertToCustomerTime, currency);
  });

  return top + inventory.length * 5;
};

export const generatePDF = (data, equipmentId, equipmentType, convertToCustomerTime, currency) => {
  if (!data?.length) {
    return;
  }

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Inventory List', pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text(`${equipmentId} ${equipmentType}`, pageWidth / 2, 28, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${convertToCustomerTime(Date.now(), 'YYYY-MM-DD HH:mm:ss')}`, pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const firstPageInventory = data.slice(0, 40);
  const restInventory = data.slice(40);

  createTableRows(doc, firstPageInventory, convertToCustomerTime, currency);

  for (let i = 0; i < (restInventory?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageInventory = restInventory.slice(i * 45, (i + 1) * 45);
    createTableRows(doc, currentPageInventory, convertToCustomerTime, currency);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc);
  }

  const url = doc.output('datauristring', { filename: 'Inventory List' });
  const blob = doc.output('blob', { filename: 'Inventory List' });

  doc.save(`Inventory-list.pdf`);
  return { blob, url };
};
