import React, { useEffect, useState } from 'react';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import VendorAutocomplete from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/VendorAutocomplete';
import Input from 'common/Input';
import Uploader from 'common/Uploader';
import InputLabel from 'common/InputLabel';
import { DateTimePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { getVendorsList } from 'Api/Vendors';
import { getAccountsList } from 'Api/Accounts';
import { getCities, getStates } from 'Api/JobPositions';
import { createTransaction, getTransactionCategories } from 'Api/CardManagement';
import { COUNTRIES } from 'pages/PublicPositions/ApplyJob/ApplyJob.data';
import { validationSchema } from './validationSchema';
import { SCustomModal, SBackdrop, SAddressWrapper, SFormWrapper } from './AddTransaction.styles';

const statuses = [
  { id: 1, name: 'Capture' },
  { id: 2, name: 'Failed' },
];

const AddTransaction = ({ open, onClose, onSuccess, userData, transaction, isStaff }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      values.card_id && formData.append('card_id', values.card_id);
      values.account && formData.append('account_id', values.account?.id);
      values.date && formData.append('date', moment(values.date).format('YYYY-MM-DD HH:mm'));
      values.vendor && formData.append('vendor_id', values.vendor.id);
      formData.append('user_type', isStaff ? 'staff' : 'driver');
      userData && formData.append('user_id', userData.id);
      values.category && formData.append('purchase_description', values.category.id);
      values.amount && formData.append('amount', values.amount);
      formData.append('status', values.category?.code === 'CADV' ? 'Cash Advance' : values.status?.name);
      values.equipment_id && formData.append('equipment_id', values.equipment_id);
      values.gallons && formData.append('gallons', values.gallons);
      values.transaction_id && formData.append('transaction_id', values.transaction_id);
      values.unit_price && formData.append('unit_price', values.unit_price);
      values.gallons && formData.append('qty', values.gallons);
      values.city && formData.append('city', values.city.id);
      values.state && formData.append('state', values.state.id);
      values.country && formData.append('country', values.country.id);
      values.location_address && formData.append('location_address', values.location_address);
      values.description && formData.append('description', values.description);
      values.transaction_id && formData.append('invoice', values.transaction_id);
      values.fees && formData.append('fees', values.fees);
      values.gallons_available && formData.append('gallons_available', values.gallons_available);
      values.receipt && formData.append('receipt', values.receipt);

      await createTransaction(formData);
      showToaster({ type: 'success', message: 'Transaction has been successfully added!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, handleBlur } = useForm({
    initialValues: {
      status: transaction?.status
        ? statuses.find((i) => i.name.toLowerCase() === transaction.status.toLowerCase())
        : null,
      account: transaction?.account || null,
      category: transaction?.category || null,
      date: transaction?.date ? new Date(transaction.date) : null,
      vendor: transaction?.vendor || null,
      amount: transaction?.amount || '',
      gallons: transaction?.gallons || '',
      country: transaction?.countries || null,
      state: transaction?.states || null,
      city: transaction?.cities || null,
      unit_price: transaction?.unit_price || '',
      transaction_id: transaction?.transaction_id || '',
      fees: transaction?.fees || '',
      receipt: transaction?.receipt || '',
    },
    onSubmit,
    validationSchema,
  });

  const getTransactionCategory = async () => {
    try {
      const { data } = await getTransactionCategories();
      setCategories(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data.filter((i) => i.account_type.id === ACCOUNT_TYPE.EXPENSE));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  useEffect(() => {
    getTransactionCategory();
    getVendors();
    getAccounts();
  }, []);

  useEffect(() => {
    const amount = (Number(values.gallons) || 0) * (Number(values.unit_price) || 0) + (Number(values.fees) || 0);
    handleChange('amount', formatNumber(amount).replaceAll(',', ''));
  }, [values.gallons, values.unit_price, values.qty, values.fees]);

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Add Offline Transaction'
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Add Transaction',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <SFormWrapper>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '16px' }}>
          <div className='flex-grow-1'>
            <Autocomplete
              required
              name='status'
              label='Status'
              options={statuses}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              error={touchedErrors.status}
            />
          </div>
          <div className='flex-grow-1'>
            <DateTimePicker
              required
              name='date'
              label='Date'
              value={values.date}
              showTimeInput
              onChange={(val) => handleChange('date', val)}
              onBlur={handleBlur}
              disableFuture
              error={touchedErrors.date}
            />
          </div>
        </div>
        <div>
          <Autocomplete
            required
            name='category'
            label='Transaction Category'
            labelKey='description'
            options={categories}
            value={values.category}
            onChange={(e, val) => handleChange('category', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.category}
          />
        </div>
        <div>
          <Autocomplete
            required
            label='Account'
            name='account'
            labelKey='account_name'
            options={accounts}
            value={values.account}
            onChange={(e, val) => handleChange('account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.account}
          />
        </div>
        <div>
          <VendorAutocomplete
            required
            label='Vendor'
            size='medium'
            width='100%'
            value={values.vendor}
            options={vendors}
            onChange={(e, val) => handleChange('vendor', val)}
            onAddNewSuccess={(newVendor) => {
              getVendors();
              handleChange('vendor', newVendor);
            }}
            error={touchedErrors.vendor}
          />
        </div>
        <div>
          <InputLabel required>Location of Transaction</InputLabel>
          <SAddressWrapper>
            <div className='flex-grow-1'>
              <Autocomplete
                name='country'
                placeholder='Country..'
                value={values.country}
                onChange={(e, value) => {
                  handleChange('country', value);
                  handleChange('state', null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={COUNTRIES}
                error={touchedErrors.country}
              />
            </div>
            <div className='flex-grow-1'>
              <Autocomplete
                name='state'
                placeholder='Select State..'
                value={values.state}
                onChange={(e, value) => handleChange('state', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={states}
                error={touchedErrors.state}
              />
            </div>
            <div className='flex-grow-1'>
              <Autocomplete
                name='city'
                placeholder='Select City..'
                value={values.city}
                onChange={(e, value) => handleChange('city', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={cities}
                error={touchedErrors.city}
              />
            </div>
          </SAddressWrapper>
        </div>
        <Input
          required
          name='transaction_id'
          label='Reference ID'
          placeholder='Reference ID'
          value={values.transaction_id}
          onChange={handleChange}
          error={touchedErrors.transaction_id}
        />
        <Input
          type='number'
          required
          name='gallons'
          label={!values.category?.fuel_id || values.category?.fuel_id === '0' ? 'Quantity' : 'Gallons'}
          placeholder={!values.category?.fuel_id || values.category?.fuel_id === '0' ? 'Quantity' : 'Gallons'}
          value={values.gallons}
          onChange={handleChange}
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors.gallons}
        />
        <Input
          type='number'
          required
          name='unit_price'
          label='Unit Price'
          placeholder='Unit Price'
          value={values.unit_price}
          onChange={handleChange}
          onKeyDown={blockNonPositiveNumbers}
          error={touchedErrors.unit_price}
        />
        <Input
          type='number'
          name='fees'
          label='Fee'
          placeholder='Fee'
          value={values.fees}
          onChange={handleChange}
          onKeyDown={blockNonPositiveNumbers}
        />
        <Input
          type='number'
          required
          name='amount'
          label='Amount'
          placeholder='Amount'
          value={values.amount}
          onChange={handleChange}
          disabled
          onKeyDown={blockNotNumberChars}
        />
        <Uploader
          type={3}
          label='Attach Receipt'
          document={values.receipt}
          onDrop={(files) => handleChange('receipt', files[0])}
          onRemove={() => handleChange('receipt', null)}
          error={touchedErrors.receipt}
          accept={['application/pdf']}
        />
      </SFormWrapper>
      <SBackdrop open={loading}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default AddTransaction;
