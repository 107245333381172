import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getTimeOfDayGraph } from 'Api/AccidentsAndCrashes';
import { SBoxWrapper } from 'pages/AccidentsAndCrashes/StatsSection/StatsSection.styles';
import TimeOfDayChart from '../Charts/TimeOfDayChart';

const TimeOfDay = ({ statsRefreshIndex }) => {
  const [timeOfDays, setTimeOfDays] = useState({});

  const getChartData = async () => {
    try {
      const { data } = await getTimeOfDayGraph();
      setTimeOfDays(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getChartData();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex flex-column gap-1'>
        <Typography variant='s2'>TIME OF DAY</Typography>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          COMMON TIME ACCIDENTS HAPPEN
        </Typography>
      </div>
      <div className='chart-wrapper'>
        <TimeOfDayChart monthsData={timeOfDays} />
      </div>
    </SBoxWrapper>
  );
};

export default TimeOfDay;
