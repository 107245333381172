import styled from 'styled-components';

export const SHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .profile-image-container {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      border-radius: 40px;
    }

    .profile-active-indicator {
      position: absolute;
      right: 0;
      bottom: 0;
      background: #38a06c;
      box-shadow: 0 0 0 2px #ffffff;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
`;
