import { palette } from 'utils/constants';

export const statusColor = {
  Matched: palette.green500,
  'Not Matched': palette.red600,
};

export const getInitialValues = (transaction) => {
  const { deposit, memo, payee, account, payment, reconciled } = transaction || {};

  return {
    payee: payee || null,
    account: account || null,
    memo: memo || '',
    reconciled: !!reconciled,
    payment: payment ? payment.toFixed(2) : '',
    deposit: deposit ? deposit.toFixed(2) : '',
  };
};
