import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .alert-map-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-top: 8px;
  }
`;
