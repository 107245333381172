import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SPlanAhead = styled.div`
  padding: 16px 24px;
  border-radius: 10px;
  border: 1px solid ${palette.gray50};
  background-color: ${palette.gray0};
  margin-bottom: 24px;

  .plan-ahead-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${palette.gray50};
    margin-bottom: 8px;
    padding-bottom: 12px;
  }
`;
