import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { getVideoUrl } from './ViewVideo.data';

const ViewVideo = ({ open, onClose, videoUrl }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      $maxWidth='800px'
      $minWidth='800px'
      $height='450px'
      $bgColor={palette.white}
      $minHeight='100px'
      customBody={{ overflow: 'hidden' }}
      backdropClassName='double-modal-backdrop dark-backdrop'
      padding='0'
      footer={false}
    >
      <iframe
        title='time-app-demo'
        src={getVideoUrl(videoUrl)}
        width='800'
        height={getVideoUrl(videoUrl)?.includes('youtube.com') ? '450' : '500'}
        frameBorder='0'
        allow='autoplay; fullscreen'
        allowFullScreen
        style={{ marginTop: getVideoUrl(videoUrl)?.includes('youtube.com') ? 0 : '-25px' }}
      />
    </Modal>
  );
};

export default ViewVideo;
