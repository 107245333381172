import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import Input from 'common/Input';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateAlert } from 'Api/AlertCenter';

import Header from '../components/Header';
import AlertInfo from '../components/AlertInfo';
import { statusOptions } from '../TakeActions.data';
import { getInitialValues, photoIdentificationTypes } from './PhotoIdentification.data';
import { validationSchema } from './validationSchema';

const PhotoIdentification = ({ open, onClose, alert, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        photo_identification: values.photo_identification,
        photo_identification_expiration: moment(values.photo_identification_expiration).format('YYYY-MM-DD'),
        photo_identification_type: values.photo_identification_type.id,
        status: values.status.value,
      };
      await updateAlert(alert.id, body);
      showToaster({ type: 'success', message: 'Photo identification has been successfully updated!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: getInitialValues(alert),
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<Header date={alert.created_at} />}
      $maxWidth='800px'
      $minWidth='800px'
      $bgColor={palette.gray0}
      padding='0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Save Changes',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <AlertInfo alert={alert} onUserClick={onClose} />
        <div className='py-4 px-3'>
          <div className='d-flex gap-4 mb-4'>
            <Input
              required
              name='photo_identification'
              label='Photo Identification #'
              placeholder='Photo Identification #'
              value={values.photo_identification}
              onChange={handleChange}
              error={touchedErrors.photo_identification}
              className='w-100'
            />
            <DatePicker
              required
              width='214px'
              disablePast
              onBlur={handleBlur}
              label='Photo Identification Expiration'
              name='photo_identification_expiration'
              value={values.photo_identification_expiration}
              onChange={(val) => handleChange('photo_identification_expiration', val)}
              error={touchedErrors.photo_identification_expiration}
            />
            <div className='w-100'>
              <Autocomplete
                required
                width='100%'
                label='Photo Identification Type'
                name='photo_identification_type'
                options={photoIdentificationTypes}
                value={values.photo_identification_type}
                onChange={(e, val) => handleChange('photo_identification_type', val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors.photo_identification_type}
              />
            </div>
          </div>
          <div className='mb-4'>
            <Autocomplete
              required
              width='300px'
              label='Action'
              name='status'
              options={statusOptions}
              value={values.status}
              onChange={(e, val) => handleChange('status', val)}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={touchedErrors.status}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhotoIdentification;
