import React, { useEffect, useState } from 'react';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getMonthOfCollisionGraph } from 'Api/AccidentsAndCrashes';
import MonthOfCollisionChart from '../Charts/MonthOfCollisionChart';
import { SBoxWrapper } from '../../StatsSection.styles';

export const MonthOfCollision = ({ statsRefreshIndex }) => {
  const [monthOfCollision, setMonthOfCollision] = useState(null);

  const getPastDueBillsData = async () => {
    try {
      const { data } = await getMonthOfCollisionGraph();
      setMonthOfCollision(data?.months_data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getPastDueBillsData();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <Typography variant='s2'>MONTH OF COLLISION</Typography>
      <Divider color={palette.white} />
      <div className='chart-wrapper'>
        <MonthOfCollisionChart monthsData={monthOfCollision} />
      </div>
    </SBoxWrapper>
  );
};
