import React from 'react';
import { Bar } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { getOptions } from './Charts.data';

const TypeOfCollisionChart = ({ chartData }) => {
  const typeOfCollisionWithAccident = (chartData || []).filter((item) => item.total_count !== 0);

  const data = {
    labels: typeOfCollisionWithAccident.map((item) => `${item.type_of_collision}`),
    datasets: [
      {
        data: typeOfCollisionWithAccident.map((item) => item.total_count),
        backgroundColor: [
          palette.green400,
          palette.indigo400,
          palette.amber400,
          palette.red400,
          palette.orange400,
          palette.red200,
          palette.blue300,
          palette.dark0,
          palette.gray400,
          palette.green100,
          palette.indigo100,
          palette.amber0,
          palette.orange100,
          palette.red0,
          palette.gray200,
        ],
        borderRadius: 4,
      },
    ],
  };

  return <Bar options={getOptions(typeOfCollisionWithAccident)} data={data} />;
};

export default TypeOfCollisionChart;
