import moment from 'moment';
import {
  setDay,
  sub,
  add,
  startOfYear,
  getDay,
  startOfDay,
  endOfDay,
  format,
  isWithinInterval,
  isAfter,
} from 'date-fns';

export const getCurrentPayPeriod = (data) => {
  if (data.payperiod_type === 1) {
    const { week_day } = data;
    const dayOfThisWeek = setDay(new Date(), week_day);

    const end = isAfter(endOfDay(dayOfThisWeek), new Date()) ? dayOfThisWeek : add(dayOfThisWeek, { weeks: 1 });
    const start = sub(end, { days: 6 });

    return { start: format(start, 'MM/dd/yyyy'), end: format(end, 'MM/dd/yyyy') };
  }

  if (data.payperiod_type === 2) {
    const { week_day } = data;
    let firstAppearanceInYear = startOfYear(new Date());

    while (getDay(firstAppearanceInYear) !== week_day) {
      firstAppearanceInYear = add(firstAppearanceInYear, { days: 1 });
    }

    let end = firstAppearanceInYear;
    let start = sub(firstAppearanceInYear, { weeks: 1, days: 6 });

    while (!isWithinInterval(new Date(), { start: startOfDay(start), end: endOfDay(end) })) {
      start = add(start, { weeks: 2 });
      end = add(end, { weeks: 2 });
    }

    return { start: format(start, 'MM/dd/yyyy'), end: format(end, 'MM/dd/yyyy') };
  }

  if (data.payperiod_type === 3) {
    const startOfMonth = moment().startOf('month').format('MM/DD/YYYY');
    const endOfMonth = moment().endOf('month').format('MM/DD/YYYY');

    return { start: startOfMonth, end: endOfMonth };
  }
};
