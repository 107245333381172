import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;

  th {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    color: ${palette.gray700};
    white-space: nowrap;
    font-family: Inter, sans-serif;
    padding: 12px 8px;
    border-bottom: 1px solid ${palette.gray50};
  }

  td {
    padding: 6px 8px;
    border: none;
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const SShipmentStops = styled.div`
  overflow: auto;

  .stop-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 45%;
  }
`;

export const SInfoSection = styled.div`
  background-color: ${palette.white};
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(152, 161, 178, 0.1), 0 30px 70px -10px rgba(17, 24, 38, 0.25),
    0 10px 30px 0 rgba(0, 0, 0, 0.2);
  padding: 26px 20px;
`;
