import jsPDF from 'jspdf';
import moment from 'moment/moment';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { formatAmount } from 'utils/helpers';
import { palette } from 'utils/constants';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 8, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 8, pageHeight - 2, { align: 'right' });
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);
};

const createTableHeader = (doc, isBankAccount) => {
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const top = currentPage === 1 ? 40 : 16;

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Date', 8, top);
  doc.text('Reference ID', 30, top);
  doc.text('Payee', 56, top);
  doc.text('Account', 88, top);
  doc.text('Memo', 128, top);
  doc.text(`${isBankAccount ? 'Payment' : 'Debit'}`, 153, top);
  doc.text(`${isBankAccount ? 'Deposit' : 'Credit'}`, 173, top);
  doc.text('Balance', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createTableRow = (doc, data, isBankAccount, top, formatDate, currency) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${formatDate(data.date)}`, 8, top);
  doc.text(`*****${data.reference_id?.slice(-5)}`, 30, top);
  doc.text(`${data.payee?.name?.length <= 16 ? data.payee?.name || '-' : data.payee?.name?.substring(0, 16)}`, 56, top);

  if (isBankAccount) {
    doc.text(`${data.account?.account_name || '-'}`, 88, top);
  } else {
    doc.text(`${!data?.account && data?.bill?.is_split ? 'SPLIT' : `${data.account?.account_name || '-'}`}`, 88, top);
  }
  doc.text(`${data.memo?.length <= 16 ? data.memo || '-' : data.memo?.substring(0, 16)}`, 128, top);
  doc.text(`${currency}${formatAmount(isBankAccount ? data.payment : data.debit)}`, 153, top);
  doc.text(`${currency}${formatAmount(isBankAccount ? data.deposit : data.credit)}`, 173, top);
  doc.text(
    `${data?.balance < 0 ? '-' : ''}${currency}${formatAmount(Math.abs(Number(data?.balance || 0)))}`,
    pageWidth - 8,
    top,
    { align: 'right' }
  );
};

const createTotalRow = (doc, totalPayment, totalDeposit, currency, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top, pageWidth - 8, top);

  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Total', 8, top + 5);
  doc.text(`${totalPayment > 0 ? '-' : ''}${currency}${formatAmount(totalPayment)}`, 153, top + 5);
  doc.text(`${currency}${formatAmount(totalDeposit)}`, 173, top + 5);
  doc.text(
    `${totalDeposit - totalPayment < 0 ? '-' : ''}${currency}${formatAmount(Math.abs(totalDeposit - totalPayment))}`,
    pageWidth - 8,
    top + 5,
    { align: 'right' }
  );

  doc.setLineWidth(0.1);
  doc.line(8, top + 8, pageWidth - 8, top + 8);
};

const createTableRows = (doc, transactions, isBankAccount, formatDate, currency) => {
  if (!transactions?.length) {
    return;
  }

  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  const top = currentPage === 1 ? 46 : 22;

  transactions.forEach((transaction, index) => {
    createTableRow(doc, transaction, isBankAccount, top + index * 5, formatDate, currency);
  });

  return top + transactions.length * 5;
};

export const generatePDF = async ({
  transactions,
  accountName,
  isBankAccount,
  download,
  dateRange,
  formatDate,
  convertToCustomerTime,
  currency,
}) => {
  const { data, totalPayment, totalDeposit } = transactions;

  if (!data?.length) {
    return;
  }

  const { start, end } = dateRange;

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Account Transactions', pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10.5);
  doc.text(`${accountName || ''}`, pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(8.4);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  if (start && end) {
    doc.text(
      `${moment(start).format('MMMM D, YYYY')} through ${moment(end).format('MMMM D, YYYY')}`,
      pageWidth / 2,
      28,
      {
        align: 'center',
      }
    );
  } else {
    doc.text('All Time', pageWidth / 2, 28, {
      align: 'center',
    });
  }
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 8, 28, {
    align: 'right',
  });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(8, 30, pageWidth - 8, 30);

  const firstPageTransactions = data.slice(0, 40);
  const restTransactions = data.slice(40);
  let totalRowStart;

  totalRowStart = createTableRows(doc, firstPageTransactions, isBankAccount, formatDate, currency);

  for (let i = 0; i < (restTransactions?.length || 0) / 45; i++) {
    doc.addPage();
    const currentPageTransactions = restTransactions.slice(i * 45, (i + 1) * 45);
    totalRowStart = createTableRows(doc, currentPageTransactions, isBankAccount, formatDate, currency);
  }

  createTotalRow(doc, totalPayment, totalDeposit, currency, totalRowStart);

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    createTableHeader(doc, isBankAccount);
  }

  doc.setDocumentProperties({
    title: `Account Transactions - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}`,
  });

  const url = doc.output('datauristring', { filename: 'Account Transactions' });
  const blob = doc.output('blob');

  if (download) {
    doc.save(`Account Transactions - ${start && end ? `${formatDate(start)} - ${formatDate(end)}` : 'All Time'}.pdf`);
  }
  return { blob, url };
};
