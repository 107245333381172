import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { getAllDashboardTabs, updateDepartmentTabs } from 'Api/Dashboard';
import { CreateDepartment, GetDepartmentsPermissions, UpdateSettingsDepartment } from 'Api/CompanySettings';
import { useAuth } from 'context/auth.context';
import { validationSchema } from './validationSchema';
import { SWrapper } from './UpdateDepartment.styles';

const AddDepartment = ({ open, onClose, department, onSuccess }) => {
  const showToaster = useShowToaster();
  const { value, setAuth } = useAuth();
  const [permissions, setPermissions] = useState([]);
  const [dashboardTabs, setDashboardTabs] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let departmentId = department?.id;

      const body = {
        id: department?.id || undefined,
        department_name: values.department_name,
        department_email: values.department_email,
        permissions: values.permissions.map((item) => ({ id: item.id })),
      };

      if (department) {
        await UpdateSettingsDepartment({ id: department.id, body });
      } else {
        const { data } = await CreateDepartment(body);
        departmentId = data.id;
      }

      await updateDepartmentTabs({
        department_id: departmentId,
        dashboard_tabs: values.dashboard_tabs.map((tab) => tab.id),
      });
      showToaster({
        type: 'success',
        message: `Department has been successfully ${department ? 'updated' : 'created'}!`,
      });
      setAuth({ ...value, shouldUpdateSettings: Date.now() }); // To trigger get settings on App.jsx
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      department_name: department?.department_name || '',
      department_email: department?.department_email || '',
      permissions: department?.permissions || [],
      dashboard_tabs: department?.dashboard_tab_permission?.map((el) => el.dashboard_tab) || [],
    },
    onSubmit,
    validationSchema,
  });

  const getDepartmentsPermissions = () => {
    return GetDepartmentsPermissions().then((res) => {
      setPermissions(res.data);

      if (!department) {
        handleChange('permissions', res.data);
      }
    });
  };

  const getDashboardTabs = async () => {
    try {
      const { data } = await getAllDashboardTabs();
      setDashboardTabs(data);

      if (!department) {
        handleChange('dashboard_tabs', data);
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDepartmentsPermissions();
    getDashboardTabs();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={department ? 'Update Department' : 'Add Department'}
      $bgColor={palette.gray0}
      $maxWidth='840px'
      $minWidth='840px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: department ? 'Update' : 'Add Department',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <SWrapper>
        <div className='top-wrapper'>
          <Input
            required
            name='department_name'
            className='w-100'
            label='Department Name'
            value={values.department_name}
            onChange={handleChange}
            error={touchedErrors.department_name}
          />
          <Input
            name='department_email'
            className='w-100'
            label='Department Email'
            value={values.department_email}
            onChange={handleChange}
          />
        </div>
        <div>
          <Autocomplete
            required
            multiple
            label='Permissions'
            name='permissions'
            labelKey='permission_name'
            options={permissions}
            value={values.permissions}
            onChange={(e, val) => handleChange('permissions', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            error={touchedErrors.permissions}
            disableClearable={false}
          />
        </div>
        <div>
          <Autocomplete
            required
            multiple
            label='Dashboard Widgets'
            name='dashboard_tabs'
            labelKey='tab_name'
            options={dashboardTabs}
            value={values.dashboard_tabs}
            onChange={(e, val) => handleChange('dashboard_tabs', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            error={touchedErrors.dashboard_tabs}
            disableClearable={false}
          />
        </div>
      </SWrapper>
    </Modal>
  );
};

export default AddDepartment;
