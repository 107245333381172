import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { CardContent, CardHeader } from '@mui/material';

const VehicleSkeleton = () => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ padding: '5px 10px' }} className='d-flex justify-content-between align-items-center'>
        <Skeleton animation='wave' height={10} width='15%' />
        <Skeleton animation='wave' height={10} width='5%' />
      </div>
      <CardHeader
        style={{ padding: '5px 10px' }}
        avatar={<Skeleton animation='wave' variant='circular' width={40} height={40} />}
        title={<Skeleton animation='wave' height={10} width='80%' style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation='wave' height={10} width='40%' />}
      />
      <CardContent style={{ padding: '5px 10px' }}>
        <div className='d-flex justify-content-between '>
          <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} width='5%' />
          <Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} width='80%' />
        </div>
        <Skeleton animation='wave' height={10} width='80%' />
      </CardContent>
    </div>
  );
};

export default VehicleSkeleton;
