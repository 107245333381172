import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import { palette } from 'utils/constants';

export const SInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 6px 6px 0;
    height: 33px;
  }

  .MuiAutocomplete-hasPopupIcon {
    width: 150px;
  }
`;

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 32px;
    border-bottom: 1px solid ${palette.gray100};
    padding: 0 12px;

    .MuiTab-root {
      padding: 10px 4px;
      margin: 0 8px;
      min-height: 32px;
      min-width: auto;
      text-transform: none;

      :hover {
        color: ${palette.gray900};
      }

      &.Mui-selected {
        color: ${palette.indigo500};
      }
    }

    .MuiTabs-indicator {
      background-color: ${palette.indigo500};
    }
  }
`;

export const SPaper = styled(Paper)`
  .MuiAutocomplete-listbox {
    max-height: 30vh;
  }
`;
