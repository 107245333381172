import { ACCOUNT_TYPE } from 'utils/constants';

export const showBalanceFor = [
  ACCOUNT_TYPE.FIXED_ASSET,
  ACCOUNT_TYPE.EQUITY,
  ACCOUNT_TYPE.BANK,
  ACCOUNT_TYPE.CURRENT_LIABILITY,
  ACCOUNT_TYPE.OTHER_CURRENT_ASSET,
  ACCOUNT_TYPE.ACCOUNT_RECEIVABLE,
  ACCOUNT_TYPE.LOAN,
  ACCOUNT_TYPE.CREDIT_CARD,
];
export const allAccountOption = { id: 99999, account_type: 'All Accounts' };

export const initialFilters = {
  hideInactive: true,
  hideBalances: false,
  account_type: allAccountOption,
  search: '',
};

export const importableAccounts = [71, 58];
