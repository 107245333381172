import React from 'react';
import { ReactComponent as Tick } from 'assets/icons/checkBoxLight.svg';
import classNames from 'classnames';
import classes from './CustomCheckBoxCard.module.scss';

const CustomCheckBoxCard = ({ checked, onChange, disabled, name, value, leftTextProps, rightTextProps }) => {
  return (
    <label className={classNames(classes.card, { [classes.checked]: checked }, { [classes.disabled]: disabled })}>
      <input type='radio' name={name} checked={checked} onChange={onChange} disabled={disabled} value={value} />
      <span className={classes.checkmark}>
        <Tick width={8} height={8} />
      </span>

      <div className={classes.infoWrapper}>
        <div className={classes.left}>
          <span>{leftTextProps?.title || ''}</span>
          <span>{leftTextProps?.detail || ''}</span>
        </div>
        <div className={classes.right}>
          <span>{rightTextProps?.title || ''}</span>
          <span style={rightTextProps?.style}>{rightTextProps?.detail || ''}</span>
        </div>
      </div>
    </label>
  );
};

export default CustomCheckBoxCard;
