import React, { useState } from 'react';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { loadCashByCard } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { SCustomModal } from '../../Cards.styles';
import { SFlexWrapper } from '../UpdateStatus/UpdateStatus.styles';

const LoadCash = ({ open, onClose, onSuccess, data }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(1);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('cardNumber', values.card_number);
      formData.append('amount', type === 1 ? values.amount : 0 - values.amount);
      if (values.refNumber) {
        formData.append('refNumber', values.refNumber);
      }
      formData.append('payroll', '0');
      if (type !== 2) {
        formData.append('cashAdvance', values.cashAdvance ? 'Y' : 'N');
      }
      await loadCashByCard(formData);
      showToaster({ type: 'success', message: 'Cash has been successfully loaded' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      card_number: data?.card_number || '',
      amount: '',
      refNumber: '',
      cashAdvance: 0,
    },
    onSubmit,
    validationSchema,
  });

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle='Load / Remove Cash'
      $bgColor={palette.gray0}
      $maxWidth='470px'
      $minWidth='470px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: type === 1 ? 'Load Cash' : 'Remove Cash',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}>
        <div>
          <Typography variant='s2'>Type</Typography>
          <SFlexWrapper>
            <CustomRadioButton
              field={{
                name: 'type',
                value: 1,
                onChange: () => setType(1),
                checked: type === 1,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
                Load Cash
              </Typography>
            </CustomRadioButton>
            <CustomRadioButton
              field={{
                name: 'type',
                value: 2,
                onChange: () => setType(2),
                checked: type === 2,
              }}
            >
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
                Remove Cash
              </Typography>
            </CustomRadioButton>
          </SFlexWrapper>
        </div>
        <InputWithIcon
          required
          label='Card Number'
          value={values.card_number}
          disabled
          icon={<CardIcon />}
          error={touchedErrors.card_number}
        />
        <InputWithIcon
          required
          type='number'
          name='amount'
          className='w-100'
          onChange={handleChange}
          onKeyDown={blockNonPositiveNumbers}
          value={values.amount}
          label='Amount'
          placeholder='0.00'
          wrapperStyle={{ borderRadius: '6px' }}
          error={touchedErrors.amount}
        />
        <Input
          name='refNumber'
          label='Reference #'
          placeholder='Optional'
          value={values.refNumber}
          onChange={handleChange}
        />
        {type !== 2 && (
          <CustomCheckbox
            name='cashAdvance'
            checked={!!values.cashAdvance}
            onChange={(checked) => handleChange('cashAdvance', checked ? 1 : 0)}
          >
            <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 8, whiteSpace: 'nowrap' }}>
              Cash Advance
            </Typography>
          </CustomCheckbox>
        )}
      </div>
    </SCustomModal>
  );
};

export default LoadCash;
