import { object, date } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object({
  urs_biennial_renewal_date: date().when('urs_biennial_active', {
    is: true,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  mcs_biennial_update_date: date().when('mcs_biennial_active', {
    is: true,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  ucr_annual_due_date: date().when('ucr_annual_active', {
    is: true,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  clearing_house_renewal_date: date().when('clearing_house_active', {
    is: true,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  scac_code_renewal_date: date().when('scac_code_active', {
    is: true,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  cvor_certificate_renewal_date: date().when('cvor_certificate_active', {
    is: true,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
});
