import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import rightArrow from 'assets/icons/arrowRight.png';
import tickIcon from 'assets/icons/tick.png';
import { TableContext } from 'context/tableContext';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import styles from './MultiDropdown.module.css';
import MultiDropdown from './MultiDropdown';

const MultiDropdownItem = ({ data, depthLevel, component }) => {
  const ref = useRef();
  const { use } = useTheme();
  const { filter, updateFilter, onChangeMultiselectSearchBy, onCheckMultiselectData } = useContext(TableContext);
  const noDisabled = data?.disabled === undefined || !data?.disabled;

  const [dropdown, setDropdown] = useState(false);
  const selectedType = filter.searchBy?.key === data?.key && data?.type !== 'checkbox';

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current?.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    if (depthLevel !== 0) {
      noDisabled && window.innerWidth > 960 && setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const foundedIndex = useMemo(() => {
    return filter.selectedValues && filter.selectedValues.findIndex((selectedValue) => selectedValue.key === data.key);
  }, [data, filter]);

  const onChangeTick = (data) => {
    const selectedValues = [...filter.selectedValues];
    if (foundedIndex !== -1) {
      selectedValues.splice(foundedIndex, 1);
    } else {
      selectedValues.push(data);
    }
    updateFilter({ ...filter, selectedValues });
    onCheckMultiselectData(selectedValues, data);
  };

  const listItemClassName = useMemo(() => {
    const cls = [styles['menu-items']];
    depthLevel === 0 ? cls.push(styles.depthLevel_first_wrapper) : cls.push(styles.depthLevel_items_wrapper);

    return cls.join(' ');
  }, []);

  const dataForFilter = useMemo(() => {
    return {
      id: data.id,
      value: data.value,
      title: data.title,
      key: data.key,
    };
  }, [data]);

  return (
    <li
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={listItemClassName}
      style={{ backgroundColor: use(palette.white, palette.dark800) }}
    >
      {data.submenu ? (
        <div className={depthLevel !== 0 ? styles.subMenu : ''}>
          <button
            type='button'
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            className={`${depthLevel === 0 ? styles.depthLevel_one : styles.depthLevel_dep}`}
            onClick={() => noDisabled && setDropdown((prev) => !prev)}
          >
            {component && component}
            {depthLevel !== 0 && (
              <span
                style={{
                  userSelect: 'none',
                  color: !noDisabled ? use(palette.gray200, palette.gray700) : use(palette.dark800, palette.gray200),
                }}
              >
                {data.title}
              </span>
            )}{' '}
            {depthLevel > 0 ? (
              // dep_items
              <span className={styles.dep_arrow}>
                <img alt='#' width={7} height={7} src={rightArrow} />
              </span>
            ) : (
              // first_item
              <span className={styles.arrow} style={{ color: use(palette.gray900, palette.gray200) }} />
            )}
          </button>
          <MultiDropdown height={data.height} depthLevel={depthLevel} submenus={data.submenu} dropdown={dropdown} />
        </div>
      ) : data?.type === 'checkbox' ? (
        <CustomCheckbox
          style={{ marginLeft: 10 }}
          checked={foundedIndex !== -1}
          onChange={() => onChangeTick(dataForFilter)}
        >
          <div
            className={`${styles.doestSubMenu}
                                 ${selectedType ? styles.selectedType : ''}
                                 ${depthLevel !== 1 && data?.type !== 'checkbox' ? styles.doesNotCheckbox : ''}`}
          >
            <span style={{ color: use(palette.dark800, palette.gray200) }}>{data.title}</span>
            {selectedType && <img src={tickIcon} alt='tickIcon' width={11} height={11} />}
          </div>
        </CustomCheckbox>
      ) : (
        <div
          className={`${styles.doestSubMenu}
                                 ${selectedType ? styles.selectedType : ''}
                                 ${depthLevel !== 1 && data?.type !== 'checkbox' ? styles.doesNotCheckbox : ''}`}
          onClick={() => {
            noDisabled && onChangeMultiselectSearchBy(dataForFilter);
          }}
        >
          <span
            style={{
              color: !noDisabled ? use(palette.gray200, palette.gray700) : use(palette.dark800, palette.gray200),
            }}
          >
            {data.title}
          </span>
          {selectedType && <img src={tickIcon} alt='tickIcon' width={11} height={11} />}
        </div>
      )}
    </li>
  );
};

export default MultiDropdownItem;
