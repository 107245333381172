import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { palette } from 'utils/constants';

export const SPhoneInput = styled(PhoneInput)`
  &.react-tel-input {
    & .form-control {
      height: unset;
      width: ${({ $width }) => $width || '100%'};
      padding: 4px 12px 4px 48px;
      border-radius: 6px;
      border: none;
      outline: none;
      box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 0 0 1px rgb(104 113 130 / 16%);

      :disabled {
        background-color: ${palette.gray0};
      }

      :disabled,
      :disabled + .flag-dropdown {
        cursor: default;
      }
    }

    .flag-dropdown {
      border: none;
      border-radius: 6px 0 0 6px;

      .selected-flag {
        border-radius: 6px 0 0 6px;

        :hover {
          background-color: #f7f7f7;
        }
      }
    }
  }
`;
