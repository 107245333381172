import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import mexico from 'assets/icons/drivers/mexico.png';
import turkeyFlag from 'assets/icons/drivers/turkeyFlag.svg';
import { ReactComponent as Tsa } from 'assets/icons/drivers/tsa.svg';
import { ReactComponent as Fast } from 'assets/icons/drivers/fast.svg';
import { ReactComponent as Twic } from 'assets/icons/drivers/twic.svg';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { ReactComponent as Favourite } from 'assets/icons/favourite.svg';
import { ReactComponent as CarrierIcon } from 'assets/icons/carrier.svg';
import { ReactComponent as CallIcon } from 'assets/icons/drivers/call.svg';
import { ReactComponent as StarFilled } from 'assets/icons/star-filled.svg';
import { ReactComponent as Approved } from 'assets/icons/icons-approval.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/drivers/inbox.svg';
import { ReactComponent as UsaFlag } from 'assets/icons/drivers/usaFlag.svg';

import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import { SimpleLoader } from 'common/Loader';
import { InputWithIcon } from 'common/Input';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { blockNotNumberChars } from 'utils/helpers';
import { SCarrierNameImage } from 'componentsV2/Planner/OfferCarrier/OfferCarrier.styles';

export const initialFilters = {
  page: 1,
  itemsPerPage: 50,
  sort: { field: 'preferred', sortBy: 'desc' },
};

export const useColumns = ({
  showMore,
  carriers,
  handleSelectAll,
  selectedRows,
  handleSelectRow,
  sort,
  sortingQuery,
  revertOffer,
  onCostChange,
  offers,
  postedRate,
  onUpdatePreferred,
}) => {
  const sendEmail = (event, email) => {
    event.stopPropagation();
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };

  const call = (event, phone) => {
    event.stopPropagation();
    if (phone) {
      window.location.href = `tel:${phone}`;
    }
  };

  return [
    {
      field: '',
      title: carriers.length ? (
        <CustomCheckbox onChange={handleSelectAll} checked={carriers.length === selectedRows.length} />
      ) : (
        ''
      ),
      render: (row) => (
        <span onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.id === row.id)}
          />
        </span>
      ),
    },
    {
      field: 'name',
      title: <ColumnHeader title='CARRIER NAME' field='name' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        const { name, can_travel_to, approved_for, token, preferred, logo_path } = row;
        return (
          <div>
            <div className='d-flex gap-2 align-items-center'>
              <Tooltip
                title={
                  <div>
                    <a onClick={(e) => sendEmail(e, row.email)} className='pointer' style={{ textDecoration: 'none' }}>
                      <EmailIcon /> <span style={{ color: palette.dark800 }}>{row.email || '-'}</span>
                    </a>
                    <br />
                    <a
                      onClick={(e) => call(e, row.phone_number)}
                      className='pointer'
                      style={{ textDecoration: 'none' }}
                    >
                      <CallIcon />
                      <span style={{ color: palette.dark800 }}>{formatPhoneNumberIntl(row.phone_number) || '-'}</span>
                    </a>
                  </div>
                }
                componentsProps={{
                  placement: 'top',
                  tooltip: {
                    sx: {
                      color: palette.gray700,
                      bgcolor: palette.white,
                      fontSize: 14,
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                      maxWidth: 'unset',
                      border: '1px solid #dadde9',
                      marginTop: '4px !important',
                    },
                  },
                }}
              >
                <SCarrierNameImage>
                  {logo_path ? (
                    <img src={logo_path} alt='carrier' width={30} height={30} className='carrier-image' />
                  ) : (
                    <CarrierIcon width={30} height={30} className='carrier-image' />
                  )}
                  <span style={{ color: palette.dark800 }}>{name}</span>
                </SCarrierNameImage>
              </Tooltip>
              {!!token && !!token?.used && <Approved width={18} height={18} className='ms-2' />}
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdatePreferred(row);
                }}
              >
                {preferred ? <StarFilled fill={palette.amber500} /> : <Favourite />}
              </span>
            </div>
            <div>
              {!!can_travel_to?.includes(1) && <UsaFlag />}
              {!!can_travel_to?.includes(2) && <img src={turkeyFlag} alt='mexico-Flag' />}
              {!!can_travel_to?.includes(3) && <img src={mexico} alt='mexico-Flag' className='mexico-flag-icon' />}
              {!!approved_for?.includes(1) && <Tsa width={30} height={30} />}
              {!!approved_for?.includes(2) && <Fast width={40} height={30} />}
              {!!approved_for?.includes(3) && <Twic width={40} height={30} />}
            </div>
          </div>
        );
      },
    },
    {
      field: 'dot',
      title: <ColumnHeader title='DOT/MC' field='dot' />,
      render: (row) => {
        const { dot, mc } = row;
        return (
          <div className='d-flex flex-column'>
            <span style={{ color: palette.dark800 }}>DOT {dot || '-'}</span>
            <span style={{ color: palette.dark800 }}>MC {mc || '-'}</span>
          </div>
        );
      },
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status_id' />,
      render: (row) => {
        const { status } = row;

        return (
          <Chip
            fontWeight={500}
            fontSize='11px'
            style={{ textTransform: 'uppercase' }}
            bgColor={status.bg_color}
            labelColor={status.color}
            label={status.title}
          />
        );
      },
    },
    {
      field: 'states',
      title: <ColumnHeader title='OPERATING STATES' field='status_id' />,
      render: (row) => {
        const { all_states_canada, all_states_mexico, all_states_usa } = row;

        return (
          <>
            <div className='d-flex flex-column'>
              {!!all_states_usa && <span style={{ color: palette.dark800 }}>USA - All States</span>}
              {!!all_states_canada && <span style={{ color: palette.dark800 }}>Canada - All States</span>}
              {!!all_states_mexico && <span style={{ color: palette.dark800 }}>Mexico - All States</span>}
            </div>
            <>
              {!row?.operating_states?.length && '-'}
              {(row?.operating_states || [])?.slice(0, 2).map((item) => (
                <Chip
                  key={item.id}
                  size='medium'
                  label={item?.short_name}
                  labelColor={palette.indigo500}
                  fontWeight={500}
                  bgColor={palette.indigo0}
                  height='20px'
                  style={{ marginRight: '5px' }}
                />
              ))}
              {row?.operating_states?.length > 2 && (
                <Typography
                  variant='c1'
                  style={{ color: palette.indigo500 }}
                  onClick={(e) => showMore(e, row, 'states')}
                >
                  Show More
                </Typography>
              )}
            </>
          </>
        );
      },
    },
    {
      field: 'equipment_type',
      title: <ColumnHeader title='OPERATING EQUIPMENT' field='equipment_type' />,
      render: (row) => {
        return (
          <div>
            {!row?.equipment_types?.length && '-'}
            {(row?.equipment_types || [])?.slice(0, 2).map((item) => (
              <Chip
                key={item.id}
                size='medium'
                label={item?.title}
                labelColor={palette.indigo500}
                fontWeight={500}
                bgColor={palette.indigo0}
                height='20px'
                style={{ marginRight: '5px' }}
              />
            ))}
            {row?.equipment_types?.length > 2 && (
              <Typography
                variant='c1'
                style={{ color: palette.indigo500 }}
                onClick={(e) => showMore(e, row, 'equipment')}
              >
                Show More
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      field: 'power_units',
      title: <ColumnHeader title='POWER UNITS' field='power_unit' sort={sort} onClick={sortingQuery} />,
      render: (row) => {
        const { power_unit } = row;
        return <span style={{ color: palette.dark800 }}>{power_unit || '-'}</span>;
      },
    },
    {
      field: 'cost',
      title: <ColumnHeader title='POSTED AMOUNT' field='cost' />,
      render: (row) => {
        const offer = offers.find((item) => item.carrier_id === row.id);
        return (
          <div>
            <InputWithIcon
              width='140px'
              value={offer ? offer.offer_amount : selectedRows?.some((i) => i.id === row.id) ? postedRate : '' || ''}
              onChange={(e) => onCostChange(row, e.target.value)}
              error={!!row.costError && 'Required'}
              onKeyDown={blockNotNumberChars}
              disabled
            />
          </div>
        );
      },
    },
    {
      field: 'action',
      render: (row) => {
        const offer = offers.find((item) => item.carrier_id === row.id);

        return offer ? (
          <div className='d-flex align-items-center gap-2'>
            <CustomButton
              type='primary'
              title={
                <div className='d-flex'>
                  <SimpleLoader loading color={palette.white} size={16} />
                </div>
              }
              styleButton={{ padding: '6px 12px', margin: 0 }}
            />
            <CustomButton
              type='secondary'
              styleButton={{ padding: '6px 12px', margin: 0 }}
              title={
                <Tooltip title='Revert Offer'>
                  <div className='d-flex'>
                    <RevertIcon width={16} height={18} />
                  </div>
                </Tooltip>
              }
              onClick={() => revertOffer(offer)}
            />
          </div>
        ) : (
          <>
            {/* <CustomButton */}
            {/*  type='primary' */}
            {/*  title='Send Offer' */}
            {/*  styleButton={{ padding: '6px 12px' }} */}
            {/*  onClick={() => sendOffer([row])} */}
            {/* /> */}
          </>
        );
      },
    },
  ];
};
