import React, { useState } from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { tableCloudPopover } from 'Api/Planner';
import { getTimeDifference, timeDifference } from 'components/TableShipments/helpers/constants';
import EDIRejectReason from 'componentsV2/Shipment/EDIRejectReason';

const EdiApiAccept = ({ data, open, onClose, getPlanner }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [openRejectReason, setOpenRejectReason] = useState(false);

  const accept = () => {
    const shipment_ids = [data?.data?.shipment_id];
    setLoading(true);
    tableCloudPopover({ shipment_ids, acceptOrDecline: 1 })
      .then((res) => {
        if (res?.status) {
          showToaster({ type: 'success', message: 'Shipment Accepted Successfully' });
        } else {
          showToaster({ type: 'error', message: 'Something went wrong !' });
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        getPlanner();
        onClose();
        setLoading(false);
      });
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      $bgColor={palette.gray0}
      $maxWidth='400px'
      $minWidth='400px'
      customBody={{ borderTopRightRadius: 12, borderTopLeftRadius: 12 }}
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050', borderRadius: '12px' }}
      buttons={[
        {
          key: 'reject',
          type: 'danger',
          title: 'Reject',
          disabled: data?.data?.edi_acceptance_status,
          onClick: () => setOpenRejectReason(true),
          disable: loading,
        },
        {
          key: 'done',
          type: 'primary',
          title: 'Accept',
          onClick: () => accept(),
          disable: loading,
        },
      ]}
    >
      <div>
        <div className='mt-4 mb-4'>
          <Typography variant='s2' style={{ color: palette.gray700, marginTop: 10 }}>
            Shipment {data?.data?.shipment_id} was awarded <span>{timeDifference(data?.data?.created_at)}</span> ago.
          </Typography>
          <br />
          <Typography variant='s3'>
            Expires in <span> {getTimeDifference(data?.data?.edi_timelimit_date_time)}</span>.
          </Typography>
        </div>
      </div>
      {openRejectReason && (
        <EDIRejectReason
          open={openRejectReason}
          onClose={() => setOpenRejectReason(false)}
          shipment={data?.data}
          onSuccess={() => {
            getPlanner();
            setOpenRejectReason(false);
            onClose();
          }}
        />
      )}
    </Modal>
  );
};

export default EdiApiAccept;
