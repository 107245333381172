import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BankIcon } from 'assets/icons/bank.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import Modal from 'common/Modal';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import { BackdropLoader } from 'common/Loader';
import AddressFields from 'common/AddressFields';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon, Textarea } from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { ACCOUNT_TYPE, palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import {
  createAccount,
  getAccountTypes,
  getCostTypes,
  getFcaSessionId,
  syncBankFeed,
  updateAccount,
} from 'Api/Accounts';
import LoanFields from './components/LoanFields';
import { getValidationSchema } from './validationSchemas';
import { accountTypeInputMapper, getInitialValues, plaidAccountType, hasFeed } from './AddAccount.data';
import { createBalanceAdjustmentTransaction, createOpeningBalanceTransaction, createRecurringBill } from './helpers';

const AddAccountModal = ({
  open,
  onClose,
  onSuccess,
  account,
  className,
  defaultAccountType,
  disabledAccountType,
  uploaderLabel,
}) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [costTypeOptions, setCostTypesOptions] = useState([]);
  const [createAccountStep, setCreateAccountStep] = useState(account ? 2 : 1);
  const [plaidToken, setPlaidToken] = useState(null);
  const [cropModalShow, setCropModalShow] = useState(false);
  const [cropper, setCropper] = useState();
  const [cropperImage, setCropperImage] = useState();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        handleChange('account_logo', blob);
        setCropModalShow(false);
      });
    }
  };

  const imageSelected = (file) => {
    setCropModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setCropperImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = new FormData();
      values.account_type?.id && body.append('account_type_id', values.account_type?.id);
      values.account_code && body.append('account_code', values.account_code);
      values.account_name && body.append('account_name', values.account_name);
      body.append('cost_type_id', values.cost_type?.id || 0);
      values.account_number && body.append('account_number', values.account_number);

      values.account_bank_name && body.append('account_bank_name', values.account_bank_name);
      values.account_routing && body.append('account_routing', values.account_routing);
      values.account_starting_check && body.append('account_starting_check', values.account_starting_check);
      values.description && body.append('description', values.description);
      accountTypeInputMapper.loan.includes(values.account_type?.id) &&
        Number(values.interest_rate) &&
        body.append('interest_rate', Number(values.interest_rate));
      accountTypeInputMapper.loan.includes(values.account_type?.id) &&
        Number(values.loan_term) &&
        body.append('loan_term', Number(values.loan_term));
      accountTypeInputMapper.loan.includes(values.account_type?.id) &&
        values.loan_term_type &&
        body.append('loan_term_type', values.loan_term_type);
      accountTypeInputMapper.loan.includes(values.account_type?.id) &&
        Number(values.loan_amount) &&
        body.append('loan_amount', Number(values.loan_amount));
      accountTypeInputMapper.loan.includes(values.account_type?.id) &&
        values.compounding?.id &&
        body.append('compounding', values.compounding?.id);
      // body.append('access_token', null),
      body.append('account_balance', 0);
      body.append('account_status', 1);
      body.append('is_truckindigital', 0);
      body.append('is_connected', 0);
      values?.address && body.append('address1', values?.address);
      values?.address2 && body.append('address2', values?.address2);
      values?.country && body.append('country_id', values?.country?.id);
      values?.state && body.append('state_id', values?.state?.id);
      values?.city && body.append('city_id', values?.city?.id);
      values?.zipcode && body.append('zipcode', values?.zipcode);
      if (!values?.account_logo) {
        body.append('account_logo', '');
      } else if (values?.account_logo instanceof File || values?.account_logo instanceof Blob) {
        body.append('account_logo', values?.account_logo);
      }

      if (account) {
        body.append('_method', 'PUT');
        body.append('stripe_fca_id', account.stripe_fca_id);
        body.append('is_truckindigital', account.is_truckindigital);
        body.append('is_connected', account.is_connected);
        await updateAccount(account.id, body);
      } else {
        const { data } = await createAccount(body);
        if (accountTypeInputMapper.openingBalance.includes(values.account_type?.id) && values.has_opening_balance) {
          await createOpeningBalanceTransaction(values, data);
        }
        if (accountTypeInputMapper.loan.includes(values.account_type?.id)) {
          await createOpeningBalanceTransaction(values, data);
          await createBalanceAdjustmentTransaction(values, data);
          if (values.add_recurring_bill) {
            await createRecurringBill(values, data);
          }
        }
      }
      showToaster({ type: 'success', message: `Account has been successfully ${account ? 'updated' : 'created'}!` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, touchedErrors, resetForm } = useForm({
    initialValues: getInitialValues(account),
    validationSchema: getValidationSchema(account),
    onSubmit,
  });

  const handleConnectAccount = async (public_token, metadata) => {
    try {
      setBackdropLoading(true);

      const payloads = metadata.accounts.map((item) => {
        const formData = new FormData();

        formData.append('access_token', public_token);
        formData.append('account_type_id', plaidAccountType[item.type] || ACCOUNT_TYPE.BANK);
        formData.append('stripe_fca_id', item.id);
        item.name && formData.append('account_name', `${item.name} (${item.mask})`);
        formData.append('cost_type_id', '0');
        item.mask && formData.append('account_number', item.mask);
        metadata?.institution?.name && formData.append('account_bank_name', metadata?.institution?.name);
        metadata?.institution?.institution_id && formData.append('institution_id', metadata.institution.institution_id);
        // formData.append('account_routing', null);
        // formData.append('account_starting_check', null);
        item?.subtype && formData.append('description', item.subtype);
        formData.append('account_status', '1');
        // formData.append('interest_rate', null);
        // formData.append('loan_term', null);
        // formData.append('loan_term_type', null);
        // formData.append('loan_amount', null);
        // formData.append('compounding', null);
        formData.append('account_balance', '0');
        formData.append('is_truckindigital', '0');
        formData.append('is_connected', '1');

        return formData;
      });

      const promises = payloads.map((payload) => createAccount(payload));
      const responses = await Promise.all(promises);

      setTimeout(async () => {
        const syncFeedPromises = responses.map((response) => {
          return syncBankFeed({
            id: response.data.id,
            start_date: moment().startOf('year').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
          });
        });
        await Promise.all(syncFeedPromises);

        onSuccess();
        onClose();
        showToaster({ type: 'success', message: 'Account(s) has been successfully created!' });
        setBackdropLoading(false);
      }, 2000);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      setBackdropLoading(false);
    }
  };

  const getAccountTypeOptions = async () => {
    try {
      const { data } = await getAccountTypes();
      setTypeOptions(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getCostTypeOptions = async () => {
    try {
      const { data } = await getCostTypes();
      setCostTypesOptions(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getPlaidToken = async () => {
    try {
      const { data } = await getFcaSessionId({ link_customization_name: 'connect_multiple' });
      setPlaidToken(data.link_token);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (defaultAccountType) {
      getPlaidToken();
      handleChange('account_type', defaultAccountType);
    } else {
      getAccountTypeOptions();
    }

    getCostTypeOptions();
  }, []);

  const { open: openPlaid, ready } = usePlaidLink({
    onSuccess: handleConnectAccount,
    token: plaidToken,
    linkCustomizationName: 'connect_multiple',
  });

  return (
    <Modal
      className={className}
      showModal={open}
      onHide={onClose}
      headerTitle={account ? 'Update Account' : 'Add Account'}
      $bgColor={palette.gray0}
      $maxWidth='640px'
      $minWidth='640px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: account ? 'Update' : 'Add Account',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex gap-2'>
          <div className='w-50'>
            <Autocomplete
              required
              label='Account Type'
              name='account_type'
              labelKey='account_type'
              options={typeOptions}
              value={values.account_type}
              onChange={(e, val) => {
                resetForm();
                handleChange('account_type', val);
                if (hasFeed.includes(val.id)) {
                  getPlaidToken();
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.account_type}
              disabled={!!account || disabledAccountType}
            />
          </div>
          <GearIcon
            width={16}
            height={16}
            className='pointer'
            style={{ marginTop: '36px' }}
            onClick={() => navigate('/company-settings', { state: { tabId: 2, subTabName: 'AccountTypes' } })}
          />
        </div>
        {hasFeed.includes(values.account_type?.id) && createAccountStep !== 2 && (
          <div className='d-flex align-items-center gap-2'>
            <CustomButton
              type='secondary'
              onClick={() => openPlaid()}
              title={`Connect Account (${values.account_type?.account_type || 'Bank'} Feed)`}
              leftIcon={<BankIcon width={18} height={18} className='me-1' />}
              styleButton={{ padding: '6px 8px', height: 60, width: '100%', justifyContent: 'center' }}
              styleTitle={{ fontSize: 14 }}
              disabled={!ready}
            />
            <CustomButton
              type='secondary'
              onClick={() => setCreateAccountStep(2)}
              title='Manually Managed'
              styleButton={{ padding: '6px 8px', height: 60, width: '100%', justifyContent: 'center' }}
              styleTitle={{ fontSize: 14 }}
            />
          </div>
        )}
        {(!hasFeed.includes(values.account_type?.id) || createAccountStep === 2) && (
          <>
            {accountTypeInputMapper.cost_type.includes(values.account_type?.id) && (
              <div className='d-flex gap-2'>
                <ArrowIcon className='mt-1' />
                <div className='flex-grow-1'>
                  <Autocomplete
                    required
                    label='Cost Type'
                    name='cost_type'
                    labelKey='cost_type'
                    options={costTypeOptions}
                    value={values.cost_type}
                    onChange={(e, val) => handleChange('cost_type', val)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    error={touchedErrors.cost_type}
                    disabled={!!account?.default}
                  />
                </div>
              </div>
            )}
            {accountTypeInputMapper.account_bank_name.includes(values.account_type?.id) && (
              <>
                <Uploader
                  type={1}
                  label='Bank Logo'
                  document={values.account_logo}
                  uploaderLabel={uploaderLabel || ''}
                  accept={['image/jpeg', 'image/png']}
                  onDrop={(files) => imageSelected(files[0])}
                  onRemove={() => handleChange('account_logo', null)}
                />
                {cropModalShow && (
                  <ImageCropModal
                    style={{ zIndex: 1300 }}
                    show={cropModalShow}
                    onHide={() => setCropModalShow(false)}
                    image={cropperImage}
                    getCropData={getCropData}
                    setCropper={setCropper}
                  />
                )}
              </>
            )}
            <div className='d-flex align-items-center gap-3'>
              <Input
                required
                label='Acct Code'
                name='account_code'
                value={values.account_code}
                onChange={handleChange}
                error={touchedErrors.account_code}
              />
              <Input
                required
                label='Account Name'
                name='account_name'
                value={values.account_name}
                onChange={handleChange}
                className='flex-grow-1'
                error={touchedErrors.account_name}
              />
            </div>
            {accountTypeInputMapper.account_bank_name.includes(values.account_type?.id) && (
              <Input
                required
                label='Bank Name'
                placeholder='ABCD Bank'
                name='account_bank_name'
                value={values.account_bank_name}
                onChange={handleChange}
                error={touchedErrors.account_bank_name}
                disabled={!!account}
              />
            )}

            {accountTypeInputMapper.account_bank_name.includes(values.account_type?.id) && (
              <AddressFields values={values} required handleChange={handleChange} />
            )}

            {accountTypeInputMapper.loan.includes(values.account_type?.id) && (
              <LoanFields
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touchedErrors={touchedErrors}
                account={account}
              />
            )}
            {accountTypeInputMapper.openingBalance.includes(values.account_type?.id) && !account && (
              <div className='d-flex align-items-center gap-3'>
                <CustomCheckbox
                  type='switch'
                  name='has_opening_balance'
                  checked={!!values.has_opening_balance}
                  onChange={(checked) => handleChange('has_opening_balance', checked)}
                  smail={false}
                />
                <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                  Opening Balance
                </Typography>
                {!!values.has_opening_balance && (
                  <>
                    <InputWithIcon
                      type='number'
                      placeholder='0'
                      name='opening_balance'
                      value={values.opening_balance}
                      onChange={handleChange}
                      onKeyDown={blockNonPositiveNumbers}
                      error={touchedErrors.opening_balance}
                    />
                    <Typography variant='s2' style={{ color: palette.gray700, whiteSpace: 'nowrap' }}>
                      as of
                    </Typography>
                    <DatePicker
                      name='opening_date'
                      value={values.opening_date}
                      onBlur={handleBlur}
                      onChange={(val) => handleChange('opening_date', val)}
                      error={touchedErrors.opening_date}
                    />
                  </>
                )}
              </div>
            )}
            {accountTypeInputMapper.account_number.includes(values.account_type?.id) && (
              <Input
                required
                type='number'
                label={
                  [ACCOUNT_TYPE.LOAN, ACCOUNT_TYPE.CURRENT_LIABILITY].includes(values.account_type?.id)
                    ? 'Account Number (Last 4 digits)'
                    : 'Account Number'
                }
                placeholder={
                  [ACCOUNT_TYPE.LOAN, ACCOUNT_TYPE.CURRENT_LIABILITY].includes(values.account_type?.id)
                    ? '0000'
                    : '00000000000'
                }
                name='account_number'
                value={values.account_number}
                onChange={handleChange}
                onKeyDown={blockNotNumberChars}
                error={touchedErrors.account_number}
              />
            )}
            {accountTypeInputMapper.account_routing.includes(values.account_type?.id) && (
              <Input
                required
                name='account_routing'
                label='Routing Number'
                onChange={handleChange}
                placeholder='000 000 000'
                value={values.account_routing}
                error={touchedErrors.account_routing}
              />
            )}
            {accountTypeInputMapper.account_starting_check.includes(values.account_type?.id) && (
              <Input
                type='number'
                required
                disabled={!!account && account?.account_starting_check !== null}
                placeholder='0001'
                onChange={handleChange}
                name='account_starting_check'
                onKeyDown={blockNotNumberChars}
                value={values.account_starting_check}
                label='Print Checks - Starting Number'
                error={touchedErrors.account_starting_check}
              />
            )}
            <Textarea
              required
              rows={3}
              label='Description'
              name='description'
              value={values.description}
              onChange={handleChange}
              error={touchedErrors.description}
            />
          </>
        )}
      </div>
      <BackdropLoader loading={backdropLoading} />
    </Modal>
  );
};

export default AddAccountModal;
