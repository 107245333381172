import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Fade, Popper } from '@mui/material';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import carrierIcon from 'assets/icons/carrier.svg';
import userDefault, { ReactComponent as UserIcon } from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/drivers/call.svg';

import { Typography } from 'components/Typography';
import { palette, statusColor } from 'utils/constants';
import { SUserInfo, SPaper, SNameImage } from './UserInfo.styles';

export const staffStatus = {
  1: 'Available',
  2: 'Logged Off',
  3: 'Away',
  4: 'Do Not Disturb',
  5: 'Disabled',
};

const UserInfo = ({ data, size, statusSize, additionalInfo, hideName, hidePopup, onRemove }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const { id, name, user_type, phone_number, email, image, status } = data || {};

  const onMouseEnter = (event) => {
    event.stopPropagation();
    if (hidePopup) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onMouseLeave = (event) => {
    event.stopPropagation();
    if (hidePopup) {
      return;
    }
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <SUserInfo onMouseLeave={onMouseLeave} $size={size} $statusSize={statusSize}>
      <Popper open={open} anchorEl={anchorEl} placement='top-start' transition style={{ zIndex: 2999 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <SPaper onClick={(e) => e.stopPropagation()}>
              <div className='user-info-header'>
                <SNameImage>
                  <div className='profile-image-container'>
                    <img
                      src={image || (user_type === 'carrier' ? carrierIcon : userDefault)}
                      alt='User'
                      width={32}
                      height={32}
                    />
                    {status && (
                      <div
                        className='profile-active-indicator'
                        style={{
                          backgroundColor:
                            user_type === 'driver'
                              ? statusColor[status]?.color
                              : statusColor[staffStatus[status] || status]?.color,
                        }}
                      />
                    )}
                  </div>
                  <Typography variant='b2' style={{ color: palette.dark700 }}>
                    {name}
                  </Typography>
                </SNameImage>
              </div>
              <div className='user-info-content'>
                <ul>
                  {!!phone_number && (
                    <li onClick={(e) => e.stopPropagation()}>
                      <a className='w-100' href={`tel:${phone_number}`}>
                        <PhoneIcon width={22} height={22} /> {formatPhoneNumberIntl(phone_number)}
                      </a>
                    </li>
                  )}
                  {!!email && (
                    <li onClick={(e) => e.stopPropagation()}>
                      <a className='w-100' href={`mailto:${email}`}>
                        <MailIcon width={22} height={12} /> {email}
                      </a>
                    </li>
                  )}
                  {user_type !== 'carrier' && (
                    <li onClick={(e) => e.stopPropagation()}>
                      <NavLink className='w-100' to='/chat'>
                        <ChatIcon width={22} height={12} />
                        Chat
                      </NavLink>
                    </li>
                  )}
                  {!!user_type && !!id && user_type !== 'carrier' && (
                    <li onClick={(e) => e.stopPropagation()}>
                      <NavLink
                        className='w-100'
                        to={
                          user_type.toLowerCase() === 'staff'
                            ? `/staff-profile/${id}/general`
                            : `/driver-profile/${id}/general`
                        }
                      >
                        <UserIcon width={22} height={16} />
                        Profile
                      </NavLink>
                    </li>
                  )}
                  {!!onRemove && (
                    <li
                      className='remove-action'
                      onClick={() => {
                        setAnchorEl(null);
                        onRemove();
                      }}
                    >
                      Remove
                    </li>
                  )}
                </ul>
              </div>
            </SPaper>
          </Fade>
        )}
      </Popper>
      <div className='profile-image-container' onClick={onMouseEnter}>
        <img
          src={image || (user_type === 'carrier' ? carrierIcon : userDefault)}
          alt='User'
          className='profile-image'
          width={size}
          height={size}
        />
        {status && (
          <div
            className='profile-active-indicator'
            style={{
              backgroundColor:
                user_type === 'driver' ? statusColor[status]?.color : statusColor[staffStatus[status] || status]?.color,
            }}
          />
        )}
      </div>
      <div className='user-name-wrapper'>
        {!hideName && (
          <Typography variant='s2' style={{ color: palette.gray900 }} className='user-name' onClick={onMouseEnter}>
            {name}
          </Typography>
        )}
        {additionalInfo}
      </div>
    </SUserInfo>
  );
};

export default UserInfo;
