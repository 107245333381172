import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;
`;

export const SItemRow = styled.div`
  display: flex;
  column-gap: 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${palette.gray50};

  .notes-departments-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 52px;

    .MuiInputBase-root {
      border-radius: 6px;
    }
  }

  .react-tel-input {
    width: 38px;

    .flag-dropdown {
      top: -1px;
      height: 34px;

      .selected-flag {
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .MuiInputBase-root {
    border-radius: 0 6px 6px 0;
  }

  a {
    text-decoration: none;

    :hover {
      text-decoration: underline;
      color: ${palette.indigo400};
    }
  }

  label {
    margin-top: 5px;

    &.custom_phone_input {
      margin-top: 0 !important;
    }
  }
`;
