import React, { useState } from 'react';
import Divider from 'common/Divider';
import PageHeader from './components/PageHeader';
import { SPageWrapper } from './ApplicantsAndReferrals.styles';
import { JobPositionTable } from './components/JobPositionTable';

export const JobPositions = () => {
  const [open, setOpen] = useState(false);

  return (
    <SPageWrapper>
      <PageHeader
        title='Job Listings'
        showCopyUrl={false}
        hideAction
        showCopyPositionUrl
        onAddJobPosition={() => setOpen(true)}
      />

      <Divider />
      <JobPositionTable open={open} setOpen={setOpen} />
    </SPageWrapper>
  );
};
