import React from 'react';
import moment from 'moment';
import Radio from 'common/Radio';
import Modal from 'common/Modal';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { validationSchema } from './validationSchema';

const RefreshFeed = ({ open, onClose, loading, onRefreshFeed }) => {
  const { values, handleSubmit, handleChange, handleBlur, touchedErrors } = useForm({
    initialValues: {
      type: 1,
      start_date: moment().subtract(1, 'year').toDate(),
      end_date: moment().toDate(),
    },
    validationSchema,
    onSubmit: onRefreshFeed,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Refresh Feed'
      $maxWidth='750px'
      $minWidth='750px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Refresh Feed',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div>
        <div className='d-flex flex-column gap-4 mb-3'>
          <Radio
            name='type'
            value='1'
            checked={values.type === 1}
            onChange={() => handleChange('type', 1)}
            label='Sync Latest Updates'
          />
          <Radio
            name='type'
            value='2'
            checked={values.type === 2}
            onChange={() => handleChange('type', 2)}
            label='Get Historical Data'
          />
        </div>

        {values.type === 2 && (
          <div className='d-flex gap-4 ms-3'>
            <div>
              <DatePicker
                required
                label='From'
                width='150px'
                name='start_date'
                onBlur={handleBlur}
                value={values.start_date}
                onChange={(val) => handleChange(`start_date`, val)}
                minDate={moment().subtract(1, 'year').toDate()}
                error={touchedErrors.start_date}
              />
            </div>
            <div>
              <DatePicker
                required
                label='To'
                width='150px'
                name='end_date'
                onBlur={handleBlur}
                value={values.end_date}
                onChange={(val) => handleChange(`end_date`, val)}
                minDate={moment().subtract(1, 'year').toDate()}
                error={touchedErrors.end_date}
              />
            </div>
          </div>
        )}
        <div className='mt-5'>
          <Typography variant='s3' style={{ color: palette.gray700 }}>
            The system will sync latest updates from your bank account on a daily basis. You have the option to run a
            sync manually to fetch latest updates. We do not sync "Pending" transactions and only show "Posted"
            transactions. You also have the option to sync historical data up to 24 months with a specific date span.
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

export default RefreshFeed;
