import styled from 'styled-components';
import { palette } from '../../../utils/constants';

export const SCountWrapper = styled.div`
  border: 2px solid ${palette.gray100};
  text-align: center;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  padding: 0 8px;
`;

export const SDownloadWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;

export const SDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 24px;
  border-radius: 6px;
  border: 1px solid ${palette.gray100};
  background-color: ${palette.white};
  padding: 5px 20px;
`;

export const SAttachedDoc = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: ${palette.white};
`;
