import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './AddDriverStyle.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import Policies from './Policies';
import TravelArea from './TravelArea';
import BasicDetails from './BasicDetails';
import Compensation from './Compensation';
import NewDriverAdded from './DriverEditedModal';
import ConnectedServices from './ConnectedServices';
import PreviousEmployment from './PreviousEmployment';
import MedicalAndBackground from './MedicalAndBackground';
import CertificationsAgreements from './CertificationsAgreements';

const AddDriver = ({ close, getDrivers, openStep, currentDriverId }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const headerRef = useRef(null);
  const [status, setStatus] = useState(1);
  const [formData, setFormData] = useState({});
  const [modalShow, setModalShow] = useState(true);
  const [ownerData, setOwnerData] = useState();
  const [applicantMedicalAndBackground, setApplicantMedicalAndBackground] = useState(null);

  const [stepsDone, setStepsDone] = useState({
    isBasicDetailsSaved: false,
    isMedicalBackground: false,
    isPreviousEmployment: false,
    isCompensation: false,
    isPolices: false,
    isTravelArea: false,
    isConnectedSeries: false,
    isCertification: false,
  });
  const [driver, setDriver] = useState({
    basicDetails: {},
    basicDetailsStates: {},
    previousEmployement: null,
    compensation: {},
    policy: {},
    certification: {},
  });
  const statusHandler = (status) => {
    setStatus(status);
  };

  useEffect(() => {
    if (openStep) {
      setStatus(openStep);
    }
  }, []);

  const nextStep = (formValue, formMultiValue, message) => {
    if (status === 1) {
      const { formikData, stateData, driverId, previousEmployment } = formValue || {};

      setDriver({
        ...driver,
        basicDetails: formikData,
        basicDetailsStates: stateData,
        driverId: driverId || null,
        previousEmployement: previousEmployment || driver.previousEmployement,
      });
      setOwnerData(formikData);
    }
    if (status === 3) {
      setDriver({
        ...driver,
        previousEmployement: formValue,
      });
    }
    if (status === 4) {
      const { formikData, Id } = formValue || {};
      setDriver({
        ...driver,
        compensation: formikData,
        scheduleId: Id,
        Schedule: formMultiValue,
      });
    }
    if (status === 5) {
      setDriver({
        ...driver,
        policy: formValue,
      });
    }
    if (status === 6) {
      setDriver((prevState) => ({
        ...prevState,
        basicDetails: { ...prevState.basicDetails, operating_states: formValue },
      }));
    }
    if (status >= 5 && document.getElementById('driver-container')) {
      document.getElementById('driver-container').scrollLeft += 400;
    }
    setStatus(status + 1);
    if (message) {
      showToaster({ type: 'success', message });
    }
  };

  const prevStep = () => {
    if (status <= 5 && document.getElementById('driver-container')) {
      document.getElementById('driver-container').scrollLeft -= 400;
    }
    setStatus(status - 1);
  };

  const Cancel = () => {
    close();
  };

  const onSkip = () => {
    setStatus((prevState) => prevState + 1);
  };

  const showError = (message) => {
    showToaster({ type: 'error', message });
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <>
      {status !== 9 && (
        <div
          className='main-modal-wrapper'
          style={{ backgroundColor: use(palette.white, palette.dark600), position: 'relative' }}
        >
          <div style={{ position: 'absolute', top: '0.7rem', margin: '0 0 20px' }}>
            <p className='add-driver-modal-text' style={{ color: use('#171c26', palette.gray200) }}>
              Add Driver
            </p>
          </div>

          <Modal.Header className='add-driver-modal-header driver-style-wrap'>
            <div className='steps-wrapper' id='driver-container'>
              <div
                className={status >= 1 ? 'step-heading-active' : 'steps-heading'}
                // style={{height: '25px'}}
              >
                {status > 1 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Basic Details</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={!(status >= 1)}
                      checked={status >= 1}
                      onClick={() => statusHandler(1)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Basic Details</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 1 ? 'incomplete-progressbar' : status > 1 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>

              <div className={status >= 2 ? 'step-heading-active' : 'steps-heading'}>
                {status > 2 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Medical & Background</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 2}
                      checked={status === 2}
                      onClick={() => statusHandler(2)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Medical & Background</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 2 ? 'incomplete-progressbar' : status > 2 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 3 ? 'step-heading-active' : 'steps-heading'}>
                {status > 3 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Previous Employment</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 3}
                      checked={status === 3}
                      onClick={() => statusHandler(3)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Previous Employment</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 3 ? 'incomplete-progressbar' : status > 3 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 4 ? 'step-heading-active' : 'steps-heading'}>
                {status > 4 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Compensation</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 4}
                      checked={status === 4}
                      onClick={() => statusHandler(4)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Compensation</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 4 ? 'incomplete-progressbar' : status > 4 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 5 ? 'step-heading-active' : 'steps-heading'}>
                {status > 5 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Policies</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 5}
                      checked={status === 5}
                      onClick={() => statusHandler(5)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Policies</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 5 ? 'incomplete-progressbar' : status > 5 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div ref={headerRef} className={status >= 6 ? 'step-heading-active' : 'steps-heading'}>
                {status > 6 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Travel Area</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 6}
                      checked={status === 6}
                      onClick={() => statusHandler(6)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Travel Area</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 6 ? 'incomplete-progressbar' : status > 6 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div className={status >= 7 ? 'step-heading-active' : 'steps-heading'}>
                {status > 7 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span> Connected Services</span>
                  </div>
                ) : (
                  <div>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 7}
                      checked={status === 7}
                      onClick={() => statusHandler(7)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span> Connected Services</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 7 ? 'incomplete-progressbar' : status > 7 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
              <div ref={headerRef} className={status >= 8 ? 'step-heading-active' : 'steps-heading'}>
                {status > 8 ? (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      className='check-box rounded-circle'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <span>Documents</span>
                  </div>
                ) : (
                  <div className='rounded-checkbox-wrapper'>
                    <input
                      type='radio'
                      name=''
                      disabled={status !== 8}
                      checked={status === 8}
                      onClick={() => statusHandler(8)}
                      className='input-style'
                      style={{ marginBottom: '0px' }}
                    />
                    <span>Documents</span>
                  </div>
                )}

                <div className='progress'>
                  <div
                    className={status === 8 ? 'incomplete-progressbar' : status > 8 ? 'complete-progressbar' : ''}
                    role='progressbar'
                    aria-valuenow='50'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  />
                </div>
              </div>
            </div>
          </Modal.Header>
        </div>
      )}
      {status === 1 && (
        <div>
          <BasicDetails
            nextStep={nextStep}
            showError={showError}
            prevStep={prevStep}
            Cancel={Cancel}
            handleFormData={handleInputData}
            values={formData}
            basicDetails={driver.basicDetails}
            basicDetailsStates={driver.basicDetailsStates}
            setApplicantMedicalAndBackground={setApplicantMedicalAndBackground}
            stepsDone={stepsDone.isBasicDetailsSaved}
            getDrivers={getDrivers}
            driverId={driver.driverId || currentDriverId}
          />
        </div>
      )}
      {status === 2 && (
        <div>
          <MedicalAndBackground
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            applicantMedicalAndBackground={applicantMedicalAndBackground}
            stepsDone={stepsDone.isMedicalBackground}
            setStepsDone={setStepsDone}
            driverId={driver.driverId || currentDriverId}
            draftDriver={currentDriverId}
            getDrivers={getDrivers}
          />
        </div>
      )}
      {status === 3 && (
        <div>
          <PreviousEmployment
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            onSkip={onSkip}
            stepsDone={stepsDone.isPreviousEmployement}
            setStepsDone={setStepsDone}
            driverId={driver.driverId || currentDriverId}
            previousEmployment={driver.previousEmployement}
            draftDriver={currentDriverId}
            getDrivers={getDrivers}
          />
        </div>
      )}
      {status === 4 && (
        <div>
          <Compensation
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            compensation={driver.compensation}
            stepsDone={stepsDone.isCompensation}
            setStepsDone={setStepsDone}
            driverId={driver.driverId || currentDriverId}
            Schedule={driver.Schedule}
            ownerData={ownerData}
            draftDriver={currentDriverId}
            getDrivers={getDrivers}
          />
        </div>
      )}
      {status === 5 && (
        <div>
          <Policies
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isPolices}
            setStepsDone={setStepsDone}
            driverId={driver.driverId || currentDriverId}
            policy={driver.policy}
            draftDriver={currentDriverId}
            getDrivers={getDrivers}
          />
        </div>
      )}
      {status === 6 && (
        <div>
          <TravelArea
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isTravelArea}
            setStepsDone={setStepsDone}
            driverId={driver.driverId || currentDriverId}
            basicDetails={driver.basicDetails}
            getDrivers={getDrivers}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 7 && (
        <div>
          <ConnectedServices
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isConnectedSeries}
            setStepsDone={setStepsDone}
            driverId={driver.driverId || currentDriverId}
            draftDriver={currentDriverId}
            getDrivers={getDrivers}
          />
        </div>
      )}
      {status === 8 && (
        <div>
          <CertificationsAgreements
            nextStep={nextStep}
            showError={showError}
            Cancel={Cancel}
            prevStep={prevStep}
            stepsDone={stepsDone.isCertification}
            setStepsDone={setStepsDone}
            driverId={driver.driverId || currentDriverId}
            certification={driver.certification}
            getDrivers={getDrivers}
            draftDriver={currentDriverId}
          />
        </div>
      )}
      {status === 9 && (
        <div className={modalShow ? 'fade modal-backdrop show' : 'modal'}>
          <NewDriverAdded
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              close();
            }}
            name='Profile Created '
            desc='Driver has been created successfully!'
          />
        </div>
      )}
    </>
  );
};

export default AddDriver;
