import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { ReactComponent as EditDotsIcon } from 'assets/icons/createShipment/edit.svg';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import styles from 'components/TablePlaner/helpers/RecurrningDetails/steps/OverView/OverView.module.css';
import { palette } from 'utils/constants';

const OverViewStopActions = ({ onEditStop, onDeleteStop }) => {
  const target = useRef(null);
  const [isOpenOverlay, setIsOpenOverlay] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const onEdit = () => {
    setIsOpenOverlay(false);
    onEditStop();
  };

  const onDelete = () => {
    onDeleteStop();
  };

  return (
    <>
      <div
        ref={target}
        onClick={() => {
          setIsOpenOverlay(true);
        }}
      >
        <EditDotsIcon style={{ cursor: 'pointer' }} />
      </div>
      <Overlay
        placement='bottom'
        rootClose
        show={isOpenOverlay}
        target={target?.current}
        onHide={() => setIsOpenOverlay(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div className={styles.overLay_backDrop}>
            <div
              {...props}
              className={styles.overLay_top_stops_wrapper}
              style={{ ...props.style, backgroundColor: palette.white }}
            >
              <div className='d-flex flex-column'>
                <Typography variant='s2' onClick={onEdit}>
                  Update Stop
                </Typography>
                <Typography
                  variant='s2'
                  style={{ color: palette.red400 }}
                  onClick={() => {
                    setIsOpenOverlay(false);
                    setOpenDeleteConfirm(true);
                  }}
                >
                  Delete Stop
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Overlay>
      {openDeleteConfirm && (
        <ConfirmationModal
          title='Stop'
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
          onConfirm={onDelete}
        />
      )}
    </>
  );
};

export default OverViewStopActions;
