import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from 'assets/icons/logo/truckinLogo.png';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import PublicFooter from 'componentsV2/PublicFooter';
import CustomButton from 'components/CustomButton/CustomButton';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getCustomerInfo } from 'Api/JobPositions';
import { getQuotePublic, updateQuotePublic } from 'Api/Shipment';

import Header from './components/Header';
import Summary from './components/Summary';
import InfoItem from './components/InfoItem';
import CompanyInfo from './components/CompanyInfo';
import DeclineQuote from './components/DeclineQuote';
import { SContent, ScrollableContainer, SPublicQuote } from './PublicQuote.styles';

const PublicQuote = () => {
  const { dot, hash } = useParams();
  const showToaster = useShowToaster();
  const [company, setCompany] = useState(null);
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const matches = useMediaQuery('(max-width:1000px)');

  const { company_name } = company || {};
  const { send_by, quote_pdf_path, customer_notes } = quote || {};

  const getCompanyInfo = async () => {
    try {
      const { data } = await getCustomerInfo(dot);
      setCompany(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getQuote = async () => {
    try {
      const { data } = await getQuotePublic(hash, dot);
      setQuote(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const acceptQuote = async () => {
    try {
      setLoadingAccept(true);
      const body = {
        status_id: 3,
      };

      await updateQuotePublic(hash, dot, body);
      showToaster({ type: 'success', message: 'Quote is successfully declined!' });
      getQuote();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getCompanyInfo();
    getQuote();
  }, []);

  if (!quote) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
        {!loading && (
          <div className='d-flex align-items-center flex-column gap-2'>
            <img src={logo} alt='Truckin Digital' width={100} height={100} />
            <Typography variant='h1' style={{ color: palette.gray700 }}>
              This quote is not available! Please check your email for new quotes.
            </Typography>
          </div>
        )}
      </div>
    );
  }

  return (
    <SPublicQuote>
      <div className='public-quote-content'>
        <div className='public-quote-left-section'>
          <Header company={company} quote={quote} />
          <ScrollableContainer>
            <SContent>
              <div className='quote-content-title'>
                <Typography variant='h5'>Quote By {company_name}</Typography>
                <Typography variant='s2'>
                  {send_by?.first_name} {send_by?.last_name}
                </Typography>
              </div>
              <Divider margin='0' />
              <CompanyInfo company={company} />
              <div>
                <Summary quote={quote} />
                <div className='quote-summary'>
                  <div className='quote-summary-title'>
                    <Typography variant='h5'>Notes</Typography>
                  </div>
                  <Divider margin='0' />
                  <div className='quote-summary-content'>
                    <InfoItem content={customer_notes || '-'} />
                  </div>
                </div>
                {matches && !!quote_pdf_path && (
                  <div className='mb-4'>
                    <iframe
                      src={quote_pdf_path}
                      title='testPdf'
                      id='pdf-iframe'
                      width='100%'
                      style={{ height: 'calc(100vh - 94px)' }}
                    />
                  </div>
                )}

                {quote?.status_id !== 3 && quote?.status_id !== 4 && (
                  <div className='d-flex justify-content-end align-items-center p-4 gap-3'>
                    <CustomButton
                      type='danger'
                      title='Decline Quote'
                      styleTitle={{ fontSize: 14, fontWeight: 500 }}
                      styleButton={{ margin: 0, padding: '6px 12px' }}
                      onClick={() => setOpenDecline(true)}
                      disabled={loadingAccept}
                    />
                    <CustomButton
                      type='primary'
                      title='Accept Quote'
                      styleTitle={{ fontSize: 14, fontWeight: 500 }}
                      styleButton={{ margin: 0, padding: '6px 12px' }}
                      onClick={() => acceptQuote()}
                      disabled={loadingAccept}
                    />
                  </div>
                )}
              </div>
            </SContent>
          </ScrollableContainer>
          <PublicFooter />
        </div>
        {!matches && (
          <div className='public-quote-right-section'>
            {!!quote_pdf_path && (
              <iframe
                src={quote_pdf_path}
                title='testPdf'
                id='pdf-iframe'
                width='100%'
                style={{ height: 'calc(100vh - 94px)' }}
              />
            )}
          </div>
        )}
      </div>
      {openDecline && (
        <DeclineQuote
          open={openDecline}
          onClose={() => setOpenDecline(false)}
          dot={dot}
          hash={hash}
          onSuccess={() => getQuote()}
        />
      )}
    </SPublicQuote>
  );
};

export default PublicQuote;
