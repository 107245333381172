import {
  Accounting,
  Additions,
  Apikeys,
  Benefits,
  Brokerage,
  ChangeLog,
  CompanyProfile,
  CompanyResource,
  RulesConduct,
  CurrencyAndMetrics,
  DateFormat,
  Deductions,
  Factoring,
  FmcsaCompliance,
  FundsTransferInstructions,
  Incidents,
  InvoiceAppearance,
  Kyu,
  NotificationsAndEmail,
  Offers,
  PaymentTerms,
  PayPeriod,
  PositionsApplications,
  SMSCommunications,
  SpeedDistanceTime,
  Taxes,
  IFTA,
  TimeApp,
  Groups,
  Alerts,
  RepairShop,
  HighwayTax,
  Departments,
  AbsencePolicy,
  DelayReasons,
  AccountTypes,
  DataRetention,
  ShipmentTypes,
  OutOfServiceTriggers,
  CommodityTypes,
  DriverCompensation,
  ShipmentsGeneral,
  SettingsMobileApps,
  Dispatch,
  Checks,
  RejectReasons,
  EquipmentInsurance,
  StaffDriverInsurance,
  StaffOnboarding,
  Inventory,
  NextAvailability,
  // Chat,
} from 'pages/CompanySettings/pagesComponents';
import SecurityLog from 'components/SecurityLog/SecurityLog';
import { palette } from 'utils/constants';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import Documents from './pagesComponents/Documents';
import ProductivityEssentials from './pagesComponents/productivityEssentials/ProductivityEssentials';

// export const onboardingSettingsQueue = [
//   'CompanyProfile',
//   'CurrencyAndMetrics',
//   'SpeedDistanceTime',
//   'Accounting',
//   'Factoring',
//   'InvoiceAppearance',
//   'HighwayTax',
//   'IFTA',
//   'ShipmentTypes',
//   'Brokerage',
//   'SMSCommunications',
//   'General',
//   'CommodityTypes',
//   'DelayReasons',
//   'DriverApp',
//   'AbsencePolicy',
//   'Insurance',
//   'FMCSACompliance',
//   'PayPeriod',
//   'Notifications',
//   'DataRetention',
// ];
export const onboardingSettingsQueue = ['CompanyProfile', 'CurrencyAndMetrics', 'Brokerage', 'DriverApp', 'PayPeriod'];

export const onlyBrokerSettings = ['Brokerage'];

export const companyMenuList = [
  {
    id: 1,
    title: 'General',
    subtitle: [
      { id: 1, title: 'Company Profile', activeId: 'CompanyProfile', component: CompanyProfile },
      { id: 3, title: 'Developer & Keys', activeId: 'APIKey', component: Apikeys },
      { id: 4, title: 'Currency & Metrics', activeId: 'CurrencyAndMetrics', component: CurrencyAndMetrics },
      { id: 5, title: 'Date and Time Format', activeId: 'DateFormat', component: DateFormat },
      { id: 6, title: 'Speed / Distance / Time', activeId: 'SpeedDistanceTime', component: SpeedDistanceTime },
    ],
  },
  {
    id: 2,
    title: 'Accounting',
    subtitle: [
      { id: 1, title: 'General', activeId: 'Accounting', component: Accounting },
      { id: 2, title: 'Factoring', activeId: 'Factoring', component: Factoring },
      {
        id: 3,
        title: 'Funds Transfer Instructions',
        activeId: 'FundsTransferInstructions',
        component: FundsTransferInstructions,
      },
      { id: 4, title: 'Payment Terms', activeId: 'PaymentTerms', component: PaymentTerms },
      { id: 5, title: 'Account Types', activeId: 'AccountTypes', component: AccountTypes },
      { id: 6, title: 'Invoice Appearance', activeId: 'InvoiceAppearance', component: InvoiceAppearance },
      { id: 9, title: 'Items and Services', activeId: 'ShipmentTypes', component: ShipmentTypes },
      { id: 10, title: 'Checks', activeId: 'checks', component: Checks },
    ],
  },
  {
    id: 3,
    title: 'Manage Alerts',
    subtitle: [
      { id: 1, title: 'Alerts', activeId: 'Alerts', component: Alerts },
      { id: 2, title: 'Out of Service Triggers', activeId: 'OutOfServiceTriggers', component: OutOfServiceTriggers },
    ],
  },
  { id: 15, title: 'Brokerage', activeId: 'Brokerage', component: Brokerage },
  { id: 5, title: 'Repair Shop', activeId: 'Repair Shop', component: RepairShop },
  { id: 6, title: 'Tags', activeId: 'Groups', component: Groups },
  {
    id: 14,
    title: 'Communications',
    subtitle: [
      // { id: 1, title: 'Chat', activeId: 'Chat', component: Chat },
      { id: 2, title: 'Email/SMS', activeId: 'SMSCommunications', component: SMSCommunications },
    ],
  },
  { id: 19, title: 'Documents', activeId: 'Documents', component: Documents },
  {
    id: 7,
    title: 'Shipments',
    subtitle: [
      { id: 1, title: 'General', activeId: 'General', component: ShipmentsGeneral },
      { id: 2, title: 'Commodity Types', activeId: 'CommodityTypes', component: CommodityTypes },
      { id: 3, title: 'Delay Reason Codes', activeId: 'DelayReasons', component: DelayReasons },
      { id: 4, title: 'Reject Reason Codes', activeId: 'RejectReasons', component: RejectReasons },
      { id: 5, title: 'Offers', activeId: 'Offers', component: Offers },
      { id: 6, title: 'Dispatch', activeId: 'Dispatch', component: Dispatch },
    ],
  },
  {
    id: 21,
    title: 'Stop Points',
    subtitle: [{ id: 1, title: 'Inventory & History', activeId: 'Inventory', component: Inventory }],
  },
  {
    id: 8,
    title: 'Mobile Apps',
    subtitle: [
      { id: 1, title: 'Truckin Digital Driver App', activeId: 'DriverApp', component: SettingsMobileApps },
      { id: 2, title: 'Truckin Digital Time App', activeId: 'TimeApp', component: TimeApp },
    ],
  },
  {
    id: 9,
    title: 'Safety & Compliance',
    subtitle: [
      { id: 1, title: 'Incidents', activeId: 'Incidents', component: Incidents },
      { id: 2, title: 'Attendance Policy', activeId: 'AbsencePolicy', component: AbsencePolicy },
      { id: 4, title: 'FMCSA Compliance', activeId: 'FMCSACompliance', component: FmcsaCompliance },
      { id: 5, title: 'Guidance & Manuals', activeId: 'GuidanceManuals', component: CompanyResource },
      { id: 6, title: 'Rules and Conduct (Handbook)', activeId: 'RulesAndConduct', component: RulesConduct },
    ],
  },
  {
    id: 17,
    title: 'Equipment',
    subtitle: [
      { id: 1, title: 'Insurance', activeId: 'EquipmentInsurance', component: EquipmentInsurance },
      { id: 2, title: `Highway Tax `, activeId: 'HighwayTax', component: HighwayTax },
      { id: 3, title: `IFTA `, activeId: 'IFTA', component: IFTA },
      { id: 4, title: 'KYU', activeId: 'Kyu', component: Kyu },
    ],
  },
  {
    id: 18,
    title: 'Staff & Driver',
    subtitle: [
      { id: 1, title: 'Insurance', activeId: 'StaffDriverInsurance', component: StaffDriverInsurance },
      { id: 2, title: 'Staff Onboarding', activeId: 'StaffOnboarding', component: StaffOnboarding },
      { id: 3, title: 'Driver Next Availability', activeId: 'NextAvailability', component: NextAvailability },
    ],
  },
  {
    id: 4,
    title: 'Manage Security',
    subtitle: [
      { id: 1, title: 'Security', activeId: 'Security', component: SecurityLog },
      { id: 2, title: 'Departments & Permissions', activeId: 'Departments', component: Departments },
    ],
  },
  {
    id: 10,
    title: 'Payroll',
    subtitle: [
      { id: 1, title: 'Pay Period', activeId: 'PayPeriod', component: PayPeriod },
      { id: 2, title: 'Deductions', activeId: 'Deductions', background: '#fff', component: Deductions },
      { id: 3, title: 'Additions', activeId: 'Additions', background: '#fff', component: Additions },
      { id: 4, title: 'Taxes', activeId: 'Taxes', background: '#fff', component: Taxes },
      { id: 5, title: 'Benefits', activeId: 'Benefits', background: '#fff', component: Benefits },
      { id: 6, title: 'Driver Compensation', activeId: 'DriverCompensation', component: DriverCompensation },
      {
        id: 7,
        title: 'Payroll Notifications',
        activeId: 'Notifications',
        component: NotificationsAndEmail,
      },
    ],
  },
  {
    id: 11,
    title: 'Positions & Applications',
    activeId: 'Positions&Applications',
    component: PositionsApplications,
  },
  { id: 12, title: 'Data Retention', activeId: 'DataRetention', component: DataRetention },
  { id: 13, title: 'Change Logs', activeId: 'ChangeLogs', component: ChangeLog, background: palette.white },
  {
    id: 16,
    title: 'Productivity Essentials',
    activeId: 'ProductivityEssentials',
    component: ProductivityEssentials,
    background: palette.white,
  },
];

export const useGetMenuList = () => {
  const hasBrokerage = useHasBrokerage();

  const menuList = companyMenuList.reduce((acc, cur) => {
    if (cur.subtitle) {
      const newSubtitles = cur.subtitle.filter((item) =>
        hasBrokerage ? true : !onlyBrokerSettings.includes(item.activeId)
      );
      if (!newSubtitles.length) {
        return acc;
      }
      return [...acc, { ...cur, subtitle: newSubtitles }];
    }

    if (hasBrokerage ? true : !onlyBrokerSettings.includes(cur.activeId)) {
      return [...acc, cur];
    }

    return acc;
  }, []);

  const configureStepQueue = onboardingSettingsQueue.filter((i) =>
    hasBrokerage ? true : !onlyBrokerSettings.includes(i)
  );

  const configureMenuList = companyMenuList.reduce((acc, cur) => {
    if (cur.subtitle) {
      const newSubtitles = cur.subtitle.filter((item) => configureStepQueue.includes(item.activeId));
      if (!newSubtitles.length) {
        return acc;
      }
      return [...acc, { ...cur, subtitle: newSubtitles }];
    }

    if (configureStepQueue.includes(cur.activeId)) {
      return [...acc, cur];
    }

    return acc;
  }, []);

  return { menuList, configureMenuList, configureStepQueue };
};
