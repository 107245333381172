import { array, boolean, number, object } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape(
  {
    print_mail: boolean().when('send_email', {
      is: (send_email) => !send_email,
      then: boolean().oneOf([true], 'Required'),
      otherwise: boolean().nullable(),
    }),
    send_email: boolean().when('print_mail', {
      is: (send_email) => !send_email,
      then: boolean().oneOf([true], 'Required'),
      otherwise: boolean().nullable(),
    }),
    customers: array().of(
      object().shape({
        quotes: array().of(
          object().shape({
            expiry_date: validator.futureRequiredDate(),
            quote_date: validator.requiredDate(),
            billing: object().shape({
              contact: object()
                .shape({ id: number().required('Required') })
                .required('Required')
                .nullable(),
            }),
          })
        ),
      })
    ),
  },
  [['print_mail', 'send_email']]
);
