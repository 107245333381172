import uuid from 'react-uuid';
import moment from 'moment';
import { initialValues as cargoInitialValues } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { getAlphabet } from 'utils/constants';

export const getInitialPickupData = (settings, isRecurring) => {
  const {
    default_pickup_schedule_date_type,
    default_pickup_equipment_type,
    default_pickup_equipment_action,
    default_pickup_commodity,
    default_pickup_bill_type,
    default_pickup_dock_high,
    default_pickup_stackable,
    default_pickup_liftgate_service,
    default_pickup_labor_required,
    default_pickup_hazardous_materials,
  } = settings || {};

  return {
    stop_point_type: 1,
    stop_point_id: '',
    scheduled_type: default_pickup_schedule_date_type ? String(default_pickup_schedule_date_type) : '',
    scheduled_date: moment().toDate(),
    scheduled_date_to: moment().toDate(),
    equipment_type: default_pickup_equipment_type ? String(default_pickup_equipment_type) : '',
    equipment_type_length: '',
    equipment_id: '',
    equipment_tbd: false,
    equipment_action: default_pickup_equipment_action || '',
    number: '',
    quantity: '',
    quantity_type: '',
    dimensions: {
      l: '',
      w: '',
      h: '',
    },
    weight: '',
    weight_type: '',
    value: '',
    commodity: default_pickup_commodity ? String(default_pickup_commodity) : '',
    cargos: [{ ...cargoInitialValues, id: uuid() }],
    stop_notes: '',
    bill_type: !!default_pickup_bill_type,
    dock_high: !!default_pickup_dock_high,
    stackable: !!default_pickup_stackable,
    liftgate_service: !!default_pickup_liftgate_service,
    labor_required: !!default_pickup_labor_required,
    hazardous_materials: !!default_pickup_hazardous_materials,
    startTime: isRecurring ? moment().toDate() : null,
    endTime: isRecurring ? moment().toDate() : null,
    id: uuid(),
  };
};

export const getInitialDeliveryData = (settings, isRecurring) => {
  const {
    default_delivery_schedule_date_type,
    default_delivery_equipment_action,
    default_delivery_bill_type,
    default_delivery_dock_high,
    default_delivery_labor_required,
  } = settings || {};

  return {
    stop_point_type: 2,
    stop_point_id: '',
    scheduled_type: default_delivery_schedule_date_type ? String(default_delivery_schedule_date_type) : '',
    scheduled_date: moment().toDate(),
    scheduled_date_to: moment().toDate(),
    dimensions: {
      l: '',
      w: '',
      h: '',
    },
    weight: '',
    quantity: '',
    quantity_type: '',
    weight_type: '',
    equipment_action: default_delivery_equipment_action || '',
    number: '',
    stop_notes: '',
    bill_type: !!default_delivery_bill_type,
    dock_high: !!default_delivery_dock_high,
    labor_required: !!default_delivery_labor_required,
    startTime: isRecurring ? moment().toDate() : null,
    endTime: isRecurring ? moment().toDate() : null,
    id: uuid(),
  };
};

export const getInitialWaypointData = (settings, isRecurring) => {
  const {
    default_waypoint_schedule_date_type,
    default_waypoint_equipment_action,
    default_waypoint_bill_type,
    default_waypoint_dock_high,
    default_waypoint_labor_required,
  } = settings || {};

  return {
    stop_point_type: 3,
    stop_point_id: '',
    scheduled_type: default_waypoint_schedule_date_type ? String(default_waypoint_schedule_date_type) : '',
    scheduled_date: moment().toDate(),
    scheduled_date_to: moment().toDate(),
    equipment_tbd: false,
    equipment_id: '',
    equipment_action: default_waypoint_equipment_action || '',
    stop_notes: '',
    bill_type: !!default_waypoint_bill_type,
    dock_high: !!default_waypoint_dock_high,
    labor_required: !!default_waypoint_labor_required,
    startTime: isRecurring ? moment().toDate() : null,
    endTime: isRecurring ? moment().toDate() : null,
    id: uuid(),
  };
};

export const getInitialStopsData = (settings, isRecurring) => {
  return [getInitialPickupData(settings, isRecurring), getInitialDeliveryData(settings, isRecurring)];
};

export const getInitialStopData = (settings, stopType) => {
  switch (Number(stopType)) {
    case 1:
      return getInitialPickupData(settings);
    case 2:
      return getInitialDeliveryData(settings);
    case 3:
      return getInitialWaypointData(settings);
    default:
  }
};

export const getDeliveryCargos = (legs, delivery) => {
  const allStops = legs.reduce((acc, cur) => [...acc, ...(cur.stops || [])], []);
  const prevStops = allStops.slice(
    0,
    allStops.findIndex((i) => i.id === delivery.id)
  );
  const allConnectedCargos = allStops.reduce(
    (acc, cur) => (Number(cur.stop_point_type) === 2 ? [...acc, ...(cur.connected_cargos || [])] : acc),
    []
  );

  const allCargos = prevStops.reduce(
    (acc, cur, index) =>
      Number(cur.stop_point_type) === 1
        ? [...acc, ...(cur.cargos || []).map((i) => ({ ...i, stopIndex: getAlphabet(index) }))]
        : acc,
    []
  );

  return allCargos.filter((i) => !!i.commodity && allConnectedCargos.every((item) => item.id !== i.id));
};

export const isAllMetricsMatch = (legs) => {
  let isValid = true;
  const allPickups = legs
    .reduce((acc, cur) => [...acc, ...(cur.stops || [])], [])
    .filter((i) => Number(i.stop_point_type) === 1);

  allPickups.forEach((stop) => {
    const quantityType = stop.cargos[0]?.expected_quantity_type;
    const weightType = stop.cargos[0]?.expected_weight_type;
    const dimensionUnit = stop.cargos[0]?.expected_dimension_unit;
    const valueType = stop.cargos[0]?.value_type;

    if (
      !stop.cargos.every(
        (item) =>
          item.expected_quantity_type?.id === quantityType?.id &&
          item.expected_weight_type?.id === weightType?.id &&
          item.expected_dimension_unit?.id === dimensionUnit?.id &&
          item.value_type?.id === valueType?.id
      )
    ) {
      isValid = false;
    }
  });

  return isValid;
};

export const isDuplicateCargoInDelivery = (legs) => {
  const allStops = legs.reduce((acc, cur) => [...acc, ...(cur.stops || [])], []);

  const allDeliveryCargoIds = allStops
    .reduce((acc, cur) => (Number(cur.stop_point_type) === 2 ? [...acc, ...(cur.connected_cargos || [])] : acc), [])
    .map((i) => i.id);

  return new Set(allDeliveryCargoIds).size !== allDeliveryCargoIds.length;
};
