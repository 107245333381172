import React from 'react';
import { capitalize } from 'lodash';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SDetailItem, SDetailItems } from '../TransactionDetails.styles';

const PaymentDetails = ({ transaction }) => {
  const { payment_intent, balance_transaction } = transaction?.transaction_details || {};
  const { amount, status, statement_descriptor, description } = payment_intent || {};
  const { fee, net } = balance_transaction || {};

  return (
    <SDetailItems>
      <Typography variant='h5' style={{ color: palette.gray900 }}>
        Payment Details
      </Typography>
      <Divider />
      <div className='items-wrapper'>
        <SDetailItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Statement descriptor
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {statement_descriptor || '-'}
          </Typography>
        </SDetailItem>
        <SDetailItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Amount
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            ${formatNumber(amount / 100)}
          </Typography>
        </SDetailItem>
        <SDetailItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Fee
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }} className='d-flex align-items-center gap-1'>
            ${formatNumber(fee / 100)}
          </Typography>
        </SDetailItem>
        <SDetailItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Net
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            ${formatNumber(net / 100)}
          </Typography>
        </SDetailItem>
        <SDetailItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Status
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {capitalize(status)}
          </Typography>
        </SDetailItem>
        <SDetailItem>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            Description
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray900 }}>
            {description || '-'}
          </Typography>
        </SDetailItem>
      </div>
    </SDetailItems>
  );
};

export default PaymentDetails;
