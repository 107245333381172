import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as FileIcon } from 'assets/icons/createShipment/attachment.svg';
import InputLabel from 'common/InputLabel';
import ViewPdf from 'components/ViewPdf';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SWrapper } from './Uploader.styles';

const Uploader = ({
  document,
  onDrop,
  onRemove,
  label,
  uploaderLabel,
  required,
  width,
  type = 1,
  error,
  disabled,
  viewPdf,
  allowReplace,
  uploadedLabel,
  uploadedIcon,
  uploadedColor,
  uploadedBgColor,
  ...props
}) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  const onViewPdf = () => {
    if (typeof document === 'string') {
      setPdfUrl(document);
      return;
    }

    const url = URL.createObjectURL(document);
    setPdfUrl(url);
  };

  return (
    <SWrapper $width={width} $uploadedBgColor={uploadedBgColor}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      {!document ? (
        <Dropzone
          onDrop={(acceptedFiles, fileRejections, event) => {
            if (!acceptedFiles?.length) {
              return;
            }
            onDrop(acceptedFiles, fileRejections, event);
          }}
          {...props}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              {type === 1 && (
                <div {...getRootProps()} className='dropzone'>
                  <input {...getInputProps()} />
                  <div className='dropzone-content'>
                    <UploadIcon />
                    <Typography variant='s2' style={{ color: palette.gray700, lineHeight: '24px' }}>
                      <span style={{ color: palette.indigo500 }}>{uploaderLabel || 'Upload Document'} </span> or drag
                      and drop
                    </Typography>
                  </div>
                </div>
              )}
              {type === 2 && (
                <div {...getRootProps()} className='small-dropzone'>
                  <input {...getInputProps()} />
                  <div className='small-dropzone-content'>
                    <UploadIcon width={14} height={14} fill={palette.gray400} />
                    <Typography variant='s2' style={{ color: palette.gray700 }}>
                      {uploaderLabel || 'Upload Document'}
                    </Typography>
                  </div>
                </div>
              )}
              {type === 3 && (
                <div {...getRootProps()} className='simple-dropzone'>
                  <input {...getInputProps()} />
                  <div className='simple-dropzone-content'>
                    <PlusIcon fill={disabled ? palette.gray300 : palette.indigo500} />
                    <Typography
                      variant='s2'
                      style={{
                        color: disabled ? palette.gray300 : palette.indigo500,
                        cursor: disabled ? 'not-allowed' : 'pointer',
                      }}
                    >
                      {uploaderLabel || 'Choose File'}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
        </Dropzone>
      ) : type === 1 ? (
        <div className='dropzone'>
          <div className='dropzone-content'>
            <UploadIcon />
            <div className='uploaded-files'>
              <TickIcon fill={palette.green500} />
              <Typography variant='s2' style={{ color: palette.green500, whiteSpace: 'nowrap' }}>
                {uploadedLabel || 'File Uploaded'}
              </Typography>
              {!!onRemove && !disabled && (
                <div className='delete-file-icon' onClick={onRemove}>
                  <DeleteIcon fill={palette.indigo500} />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : allowReplace ? (
        <Dropzone
          onDrop={(acceptedFiles, fileRejections, event) => {
            if (!acceptedFiles?.length) {
              return;
            }
            onDrop(acceptedFiles, fileRejections, event);
          }}
          {...props}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className='uploaded-files uploaded-replace-files'>
              <input {...getInputProps()} />
              {uploadedIcon || <FileIcon fill={disabled ? palette.gray300 : palette.indigo500} />}
              <Typography
                variant='s2'
                style={{
                  color: disabled ? palette.gray300 : palette.indigo500,
                  cursor: disabled ? 'not-allowed' : 'pointer',
                  whiteSpace: 'nowrap',
                }}
              >
                {uploadedLabel || 'Replace'}
              </Typography>
              {!!onRemove && !disabled && (
                <div className='delete-file-icon' onClick={onRemove}>
                  <DeleteIcon fill={palette.indigo500} />
                </div>
              )}
            </div>
          )}
        </Dropzone>
      ) : (
        <div className='uploaded-files'>
          {uploadedIcon || <TickIcon fill={palette.green500} />}
          <Typography
            variant='s2'
            style={{
              color: uploadedColor || palette.green500,
              whiteSpace: 'nowrap',
              cursor: viewPdf ? 'pointer' : 'default',
            }}
            onClick={viewPdf ? onViewPdf : undefined}
          >
            {uploadedLabel || 'File Uploaded'}
          </Typography>
          {!!onRemove && !disabled && (
            <div className='delete-file-icon' onClick={onRemove}>
              <DeleteIcon fill={palette.indigo500} />
            </div>
          )}
        </div>
      )}
      <ErrorMessage error={error} />
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />}
    </SWrapper>
  );
};

export default Uploader;
