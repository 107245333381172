import React from 'react';
import { STableWrapper } from '../DotMonitoring.styles';
import SafetyRecords from './SafetyRecords/SafetyRecords';

const TableSection = () => {
  return (
    <STableWrapper>
      <SafetyRecords />
    </STableWrapper>
  );
};

export default TableSection;
