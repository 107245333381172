import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Tab from '@mui/material/Tab';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import { BackdropLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import DateRangePicker from 'common/DateRangePicker';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useDebounce from 'hooks/useDebounce';
import useShowToaster from 'hooks/useShowToaster';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import {
  deleteStandaloneInvoice,
  getReceivablesTableData,
  getReadyStandaloneInvoices,
} from 'Api/AccountingReceivables';
import {
  GetChargeTypes,
  GetCompanyProfile,
  getBrokerageSetting,
  getFundsTransferInstructions,
  GetSettingsAccounting,
  getFundsTransferInstructionsBrokerage,
} from 'Api/CompanySettings';
import { getErrorMessage } from 'utils/error';
import AddNotes from '../shared/AddNotes';
import SendInvoice from '../shared/SendInvoice';
import UploadDocument from '../shared/UploadDocument';
import SendOtherInvoice from '../shared/SendOtherInvoice';
import StandaloneInvoiceNotes from '../shared/StandaloneInvoiceNotes';
import BulkActions from './components/BulkActions';
import EditInvoice from './components/EditInvoice';
import OtherRevenueTable from './components/OtherRevenueTable';
import ShipmentInvoiceTable from './components/ShipmentInvoiceTable';
import { filterOptions, initialFilters, otherRevenueFilterOptions } from '../TableSection.data';
import { useColumns, useOtherColumns } from './ReadyToInvoice.data';
import { SFiltersWrapper, SSearchWrapper, STabs } from '../../Receivables.styles';

const ReadyToInvoice = ({ getCounts, stopPoints, customers, groups, tableRefreshIndex }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const hasBrokerage = useHasBrokerage();
  const controller = useRef(new AbortController());
  const controllerOther = useRef(new AbortController());
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('shipment');
  const [data, setData] = useState({ data: [] });
  const [otherData, setOtherData] = useState({ data: [] });
  const [companyProfile, setCompanyProfile] = useState(null);
  const [accountingSettings, setAccountingSettings] = useState(null);
  const [brokerageSettings, setBrokerageSettings] = useState({});
  const [instructions, setInstructions] = useState(null);
  const [instructionsBrokerage, setInstructionsBrokerage] = useState({});
  const [chargeTypes, setChargeTypes] = useState(null);
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [openStandaloneNotes, setOpenStandaloneNotes] = useState(false);
  const [openSendInvoice, setOpenSendInvoice] = useState(false);
  const [openSendOtherInvoice, setOpenSendOtherInvoice] = useState(false);
  const [modalData, setModalData] = useState({});
  const [search, setSearch] = useState('');
  const [otherSearch, setOtherSearch] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortByDate, setSortByDate] = useState(null);
  const [sort, setSort] = useState({ field: 'end_date_time', sortBy: 'desc' });
  const [otherSort, setOtherSort] = useState({ field: 'due_date', sortBy: 'asc' });
  const [loading, setLoading] = useState(false);
  const [otherLoading, setOtherLoading] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const otherDebouncedSearch = useDebounce(otherSearch, 500);
  const [filterType, setFilterType] = useState(filterOptions[0]);
  const [otherFilterType, setOtherFilterType] = useState(otherRevenueFilterOptions[0]);
  const [isFactoring, setIsFactoring] = useState(false);
  const [isBrokered, setIsBrokered] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [otherSelectedFilters, setOtherSelectedFilters] = useState(initialFilters);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [otherSelectedRows, setOtherSelectedRows] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [openEditInvoice, setOpenEditInvoice] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getShipmentInvoices = async () => {
    if (controller?.current) {
      controller.current.abort();
      controller.current = new AbortController();
    }
    try {
      setLoading(true);
      const { page, itemsPerPage, customers, groups, stopPoints } = selectedFilters || {};
      const filter = `filters[${filterType.value}]`;
      const condFilter = filterType.id !== 10 && filterType.id !== 11 && filterType.id !== 12;
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page,
        itemsPerPage,
        ...(condFilter && !!debouncedSearch && { [filter]: debouncedSearch }),
        'sort[][end_date_time]': sortByDate || undefined,
        'filters[tab]': 'ready_to_invoice',
        'filters[is_factoring]': isFactoring ? 1 : undefined,
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[to]': dateRange.end ? moment(dateRange.end.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[is_brokered]': isBrokered ? 1 : undefined,
        'filters[customer_id]': customers?.length ? customers.map((i) => i.id) : undefined,
        'filters[group_id]': groups?.length ? groups.map((i) => i.id) : undefined,
        'filters[stop_point_id]': stopPoints?.length ? stopPoints.map((i) => i.id) : undefined,
        [sortField]: sort.sortBy,
      };

      const response = await getReceivablesTableData(params, controller?.current?.signal);
      setData(response);
      getCounts();
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getOtherInvoices = async () => {
    if (controllerOther?.current) {
      controllerOther.current.abort();
      controllerOther.current = new AbortController();
    }
    try {
      setOtherLoading(true);
      const { page, itemsPerPage } = otherSelectedFilters || {};
      const filter = `filters[${otherFilterType.value}]`;
      const sortField = `sort[][${otherSort.field}]`;
      const condFilter = filterType.id !== 2;
      const params = {
        page,
        itemsPerPage,
        ...(condFilter && !!otherDebouncedSearch && { [filter]: otherDebouncedSearch }),
        'filters[is_factoring]': isFactoring ? 1 : undefined,
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[to]': dateRange.end ? moment(dateRange.end.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[is_brokered]': isBrokered ? 1 : undefined,
        'filters[customer_id]': otherSelectedFilters.other_customers?.length
          ? otherSelectedFilters.other_customers.map((i) => i.id)
          : undefined,
        [sortField]: otherSort.sortBy,
      };

      const response = await getReadyStandaloneInvoices(params, controllerOther?.current?.signal);
      setOtherData(response);
      getCounts();
    } catch (e) {
      // Do nothing
    } finally {
      setOtherLoading(false);
    }
  };

  const openInvoice = async () => {
    const invoiceId = searchParams.get('invoiceId');
    searchParams.delete('invoiceId');
    setSearchParams(searchParams);

    if (!invoiceId) {
      return;
    }

    setLoadingInvoice(true);
    try {
      const { data } = await getReceivablesTableData({ itemsPerPage: 10000, 'filters[tab]': 'ready_to_invoice' });
      const invoice = data.find((item) => item.id === Number(invoiceId));

      if (invoice) {
        onSendInvoice(invoice);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingInvoice(false);
    }
  };

  const getAccountingSettings = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountingSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCompanyProfile = async () => {
    try {
      const company = await GetCompanyProfile();
      setCompanyProfile(company);
    } catch (e) {
      // Do nothing
    }
  };

  const getBrokerSettings = async () => {
    try {
      const { data } = await getBrokerageSetting();
      setBrokerageSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getInstructions = async () => {
    try {
      const { data } = await getFundsTransferInstructions();
      setInstructions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getInstructionsBrokerage = async () => {
    try {
      const { data } = await getFundsTransferInstructionsBrokerage();
      setInstructionsBrokerage(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getChargeTypes = async () => {
    try {
      const { data } = await GetChargeTypes();
      setChargeTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onUploadDocuments = (row) => {
    setModalData(row);
    setOpenUploadDocument(true);
  };

  const onSendInvoice = (row) => {
    setSelectedRows([row]);
    setOpenSendInvoice(true);
  };

  const onSendOtherInvoice = (row) => {
    setOtherSelectedRows([row]);
    setOpenSendOtherInvoice(true);
  };

  const onEditInvoice = (row) => {
    setEditRow(row);
    setOpenEditInvoice(true);
  };

  const onBulkSendInvoice = () => {
    setOpenSendInvoice(true);
  };

  const onViewNotes = (row) => {
    setModalData(row);
    if (activeTab === 'shipment') {
      setOpenAddNotes(true);
    } else {
      setOpenStandaloneNotes(true);
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== data?.data?.length ? data?.data : []);
  };

  const handleOtherSelectAll = () => {
    setOtherSelectedRows(otherSelectedRows.length !== otherData?.data?.length ? otherData?.data : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const handleOtherSelectRow = (checked, row) => {
    if (checked) {
      setOtherSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = otherSelectedRows.filter((item) => item.id !== row.id);
      setOtherSelectedRows(updatedSelectedRows);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    const otherDirection = otherSort?.sortBy === 'asc' ? 'desc' : 'asc';
    if (activeTab === 'shipment') {
      setSort({ field, sortBy: direction });
    } else {
      setOtherSort({ field, sortBy: otherDirection });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const deleteInvoice = async () => {
    try {
      setLoadingDelete(true);
      await deleteStandaloneInvoice(invoiceToDelete.id);
      showToaster({
        type: 'success',
        message: `Invoice has been successfully deleted!`,
      });
      getOtherInvoices();
      setInvoiceToDelete(null);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onDeleteInvoice = (row) => {
    setInvoiceToDelete(row);
  };

  useEffect(() => {
    getShipmentInvoices();
  }, [dateRange, selectedFilters, debouncedSearch, sortByDate, sort, isFactoring, isBrokered]);

  useEffect(() => {
    getOtherInvoices();
  }, [otherSelectedFilters, otherDebouncedSearch, otherSort, isFactoring, tableRefreshIndex, isBrokered]);

  useEffect(() => {
    getAccountingSettings();
    getCompanyProfile();
    getInstructions();
    getChargeTypes();
    openInvoice();

    if (hasBrokerage) {
      getBrokerSettings();
      getInstructionsBrokerage();
    }
  }, []);

  useEffect(() => {
    setSelectedRows([]);
    setOtherSelectedRows([]);
  }, [activeTab]);

  const columns = useColumns({
    onUploadDocuments,
    onViewNotes,
    onSendInvoice,
    selectedRows,
    handleSelectAll,
    handleSelectRow,
    data: data?.data,
    sort,
    sortingQuery,
  });

  const otherColumns = useOtherColumns({
    data: otherData?.data,
    selectedRows: otherSelectedRows,
    handleSelectRow: handleOtherSelectRow,
    handleSelectAll: handleOtherSelectAll,
    sort: otherSort,
    sortingQuery,
    onSendInvoice: onSendOtherInvoice,
    onEditInvoice,
    onViewNotes,
    onDeleteInvoice,
  });

  return (
    <>
      {selectedRows.length || otherSelectedRows.length ? (
        activeTab === 'shipment' ? (
          <BulkActions data={selectedRows} onBulkSendInvoice={onBulkSendInvoice} />
        ) : (
          <SFiltersWrapper>
            <CustomButton
              type='primary'
              title='Send Invoices'
              onClick={() => setOpenSendOtherInvoice(true)}
              leftIcon={<SendIcon fill={palette.white} style={{ marginRight: 10 }} />}
              styleButton={{ padding: '5px 12px', marginTop: 0 }}
            />
          </SFiltersWrapper>
        )
      ) : (
        <SFiltersWrapper>
          {activeTab === 'shipment' && (
            <>
              <ThreeDotActions
                anchorEl={filterAnchorEl}
                setAnchorEl={setFilterAnchorEl}
                actionButton={
                  <CustomButton
                    type='secondary'
                    title=''
                    leftIcon={
                      <TwoWaysIcon
                        width={16}
                        height={16}
                        fill={palette.gray700}
                        style={{ transform: 'rotate(90deg)' }}
                      />
                    }
                    styleButton={{ padding: '8px 12px', margin: 0 }}
                    onClick={() => null}
                  />
                }
                popoverStyles={{ margin: '10px 0 0 70px' }}
              >
                <div style={{ padding: '0 8px' }}>
                  <CustomRadioButton
                    field={{
                      name: 'sort_by_date',
                      value: 'desc',
                      onChange: () => setSortByDate('desc'),
                      checked: sortByDate === 'desc',
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700) }}>
                      Recent
                    </Typography>
                  </CustomRadioButton>
                  <CustomRadioButton
                    field={{
                      name: 'sort_by_date',
                      value: 'asc',
                      onChange: () => setSortByDate('asc'),
                      checked: sortByDate === 'asc',
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700) }}>
                      Oldest
                    </Typography>
                  </CustomRadioButton>
                </div>
              </ThreeDotActions>
              <DateRangePicker
                top='-168px'
                dateRange={dateRange}
                initialRangeName='All Time'
                setDateRange={setDateRange}
                type='allTime'
              />
            </>
          )}
          {activeTab === 'shipment' ? (
            <SSearchWrapper>
              <div>
                <Autocomplete
                  width='150px'
                  labelKey='label'
                  options={filterOptions}
                  value={filterType}
                  onChange={(e, val) => {
                    setSearch('');
                    setFilterType(val);
                    setSelectedFilters(initialFilters);
                  }}
                />
              </div>
              {filterType?.value === 'customer' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='company_name'
                  disableClearable={false}
                  options={customers}
                  value={selectedFilters.customers}
                  placeholder={selectedFilters.customers?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, customers: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : filterType?.value === 'stop_point' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='location_name'
                  disableClearable={false}
                  options={stopPoints}
                  value={selectedFilters.stopPoints}
                  placeholder={selectedFilters.stopPoints?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, stopPoints: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : filterType?.value === 'group' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='group_name'
                  disableClearable={false}
                  options={groups}
                  value={selectedFilters.groups}
                  placeholder={selectedFilters.groups?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : (
                <Search search={search} onChange={setSearch} className='search-input' />
              )}
            </SSearchWrapper>
          ) : (
            <SSearchWrapper>
              <div>
                <Autocomplete
                  width='150px'
                  labelKey='label'
                  options={otherRevenueFilterOptions}
                  value={otherFilterType}
                  onChange={(e, val) => {
                    setOtherSearch('');
                    setOtherFilterType(val);
                    setOtherSelectedFilters(initialFilters);
                  }}
                />
              </div>
              {otherFilterType?.value === 'customer' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='company_name'
                  disableClearable={false}
                  options={customers}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.company_name}
                    </li>
                  )}
                  value={
                    activeTab === 'shipment' ? selectedFilters.other_customers : otherSelectedFilters.other_customers
                  }
                  placeholder={selectedFilters.other_customers?.length ? '' : 'Select..'}
                  onChange={(e, val) => {
                    if (activeTab === 'shipment') {
                      setSelectedFilters((prevState) => ({ ...prevState, other_customers: val }));
                    } else {
                      setOtherSelectedFilters((prevState) => ({ ...prevState, other_customers: val }));
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : (
                <Search search={otherSearch} onChange={setOtherSearch} className='search-input' />
              )}
            </SSearchWrapper>
          )}
          {!!accountingSettings?.is_factoring && (
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                smail={false}
                name='is_factoring'
                checked={!!isFactoring}
                onChange={(checked) => setIsFactoring(checked)}
              />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Factoring Customers Only
              </Typography>
            </div>
          )}
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              smail={false}
              name='is_brokered'
              checked={isBrokered}
              onChange={(checked) => setIsBrokered(checked)}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Brokered Shipment Only
            </Typography>
          </div>
        </SFiltersWrapper>
      )}
      <div>
        <STabs value={activeTab} onChange={handleTabChange}>
          <Tab
            id='tab-1'
            value='shipment'
            label='Shipment'
            iconPosition='end'
            icon={<span>{data?.total}</span>}
            disableRipple
          />
          <Tab
            id='tab-2'
            value='other'
            label='Other'
            iconPosition='end'
            icon={<span>{otherData?.total}</span>}
            disableRipple
          />
        </STabs>
        {activeTab === 'shipment' ? (
          <ShipmentInvoiceTable
            data={data}
            loading={loading}
            columns={columns}
            onSendInvoice={onSendInvoice}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        ) : (
          <OtherRevenueTable
            data={otherData}
            loading={otherLoading}
            columns={otherColumns}
            onSendInvoice={onSendOtherInvoice}
            selectedFilters={otherSelectedFilters}
            setSelectedFilters={setOtherSelectedFilters}
          />
        )}
      </div>
      {openUploadDocument && (
        <UploadDocument
          open={openUploadDocument}
          onClose={() => setOpenUploadDocument(false)}
          onSuccess={() => {
            getCounts();
            getShipmentInvoices();
          }}
          shipmentData={modalData}
        />
      )}
      {openAddNotes && (
        <AddNotes
          open={openAddNotes}
          onClose={() => setOpenAddNotes(false)}
          data={modalData}
          onSuccess={getShipmentInvoices}
        />
      )}
      {openStandaloneNotes && (
        <StandaloneInvoiceNotes
          open={openStandaloneNotes}
          onClose={() => setOpenStandaloneNotes(false)}
          invoice={modalData}
          onSuccess={getOtherInvoices}
        />
      )}
      {openSendInvoice && (
        <SendInvoice
          open={openSendInvoice}
          companyProfile={companyProfile}
          brokerageSettings={brokerageSettings}
          accountingSettings={accountingSettings}
          instructions={instructions}
          instructionsBrokerage={instructionsBrokerage}
          data={selectedRows}
          chargeTypes={chargeTypes}
          onClose={() => setOpenSendInvoice(false)}
          onSuccess={() => {
            getCounts();
            setSelectedRows([]);
            getShipmentInvoices();
          }}
        />
      )}
      {openSendOtherInvoice && (
        <SendOtherInvoice
          open={openSendOtherInvoice}
          companyProfile={companyProfile}
          brokerageSettings={brokerageSettings}
          accountingSettings={accountingSettings}
          instructions={instructions}
          instructionsBrokerage={instructionsBrokerage}
          data={otherSelectedRows}
          onClose={() => setOpenSendOtherInvoice(false)}
          onSuccess={() => {
            getCounts();
            setOtherSelectedRows([]);
            getOtherInvoices();
          }}
        />
      )}
      {openEditInvoice && (
        <EditInvoice
          open={openEditInvoice}
          onClose={() => setOpenEditInvoice(false)}
          onSuccess={() => {
            getCounts();
            getOtherInvoices();
            setEditRow(null);
          }}
          invoice={editRow}
        />
      )}
      {!!invoiceToDelete && (
        <ConfirmationModal
          width='510px'
          open={!!invoiceToDelete}
          onClose={() => setInvoiceToDelete(null)}
          headerTitle='Delete Invoice'
          text={`Are you sure you want to delete invoice ${invoiceToDelete.invoice_id}?`}
          onConfirm={deleteInvoice}
          disabled={loadingDelete}
        />
      )}
      <BackdropLoader loading={loadingInvoice} />
    </>
  );
};

export default ReadyToInvoice;
