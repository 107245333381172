import React from 'react';
import moment from 'moment';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { CURRENCY, formatNumber } from 'utils/constants';
import { millisecondsToInterval } from 'utils/helpers';
import InfoItem from './InfoItem';

const Summary = ({ quote }) => {
  const { billing, additional_line_items, total_duration, start_date, end_date } = quote || {};

  const chargesTotal = (billing?.charges || []).reduce(
    (acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0),
    0
  );
  const lineItemsTotal = (additional_line_items || []).reduce(
    (acc, cur) => acc + Number(cur.rate || 0) * Number(cur.quantity || 0),
    0
  );
  const currencySymbol = billing?.currency_id
    ? Object.values(CURRENCY).find((i) => i.id === billing.currency_id)?.symbol
    : null;
  const lineItemsCurrencySymbol = additional_line_items?.length
    ? Object.values(CURRENCY).find((i) => i.id === additional_line_items[0].currency_id)?.symbol
    : null;

  return (
    <div className='quote-summary mb-2'>
      <div className='quote-summary-title'>
        <Typography variant='h5'>Summary</Typography>
      </div>
      <Divider margin='0' />
      <div className='quote-summary-content'>
        <div className='d-flex'>
          <div className='d-flex flex-column gap-4 flex-1'>
            <InfoItem
              title='Total Transit'
              content={total_duration ? `${millisecondsToInterval(total_duration * 60 * 1000)}` : '-'}
            />
            <InfoItem title='Total Power Units' content={billing?.total_power_unit || '-'} />
            <InfoItem
              title='Other Charges'
              content={`${lineItemsCurrencySymbol || '$'}${formatNumber(lineItemsTotal)}`}
            />
            <InfoItem title='Start Date' content={start_date ? moment(start_date).format('MM/DD/YYYY') : '-'} />
          </div>
          <div className='d-flex flex-column gap-4 flex-1'>
            <InfoItem title='Distance' content='-' />
            <InfoItem
              title='Charge Per Unit'
              content={`${currencySymbol}${formatNumber(chargesTotal / (billing?.total_power_unit || 1))}`}
            />
            <InfoItem
              title='Grand Total'
              content={`${currencySymbol}${formatNumber((chargesTotal || 0) + (lineItemsTotal || 0))}`}
            />
            <InfoItem title='End Date' content={end_date ? moment(end_date).format('MM/DD/YYYY') : '-'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
