import { string, object } from 'yup';

export const getInitialValues = (addonData) => {
  const initialValues = {
    coupon_code: '',
    term: false,
    privacy: false,
    billing: false,
    license_cnt: null,
    paymentMethod: null,
  };

  addonData?.add_custom_fields?.forEach((item) => {
    if (item?.field_name) {
      initialValues[item.field_name] = '';
    }
  });

  return initialValues;
};

export const getValidationSchema = (data) => {
  const schema = {};
  data?.add_custom_fields?.forEach((item) => {
    if (item?.field_name) {
      schema[item.field_name] = string().trim().required('Required').nullable();
    }
  });

  return object(schema);
};
