import styled from 'styled-components';

export const SPaymentMethod = styled.div`
  width: 500px;
  display: flex;
  padding: 0 20px;
  margin-top: 12px;

  .payment-methods {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .payment-method {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 16px;
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16), 0 2px 5px rgba(89, 96, 120, 0.1);
    border-radius: 8px;

    &.selected {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 0 1px #4f5aed, 0 2px 5px rgba(79, 90, 237, 0.1);
    }

    .payment-method-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      height: 28px;
    }

    .payment-method-title {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }
`;
