import { date, number, object, string } from 'yup';
import { validator } from 'utils/helpers';
import { accountTypeInputMapper } from './AddAccount.data';

export const getValidationSchema = (account) => {
  if (account) {
    return object().shape({
      account_type: object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable(),
      account_code: string().trim().required('Required').nullable(),
      account_name: string().trim().required('Required').nullable(),
      description: string().trim().required('Required').nullable(),
      cost_type: object().when('account_type', (account_type) => {
        return accountTypeInputMapper.cost_type.includes(account_type?.id)
          ? object()
              .shape({ id: number().required('Required') })
              .required('Required')
              .nullable()
          : object().nullable();
      }),
      account_starting_check: string().when('account_type', (account_type) => {
        return accountTypeInputMapper.account_starting_check.includes(account_type?.id)
          ? string().trim().required('Required').nullable()
          : string().nullable();
      }),
    });
  }

  return object().shape({
    account_type: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    account_code: string().trim().required('Required').nullable(),
    account_name: string().trim().required('Required').nullable(),
    description: string().trim().required('Required').nullable(),

    account_bank_name: string().when('account_type', (account_type) => {
      return accountTypeInputMapper.account_bank_name.includes(account_type?.id)
        ? string().trim().required('Required').nullable()
        : string().nullable();
    }),
    cost_type: object().when('account_type', (account_type) => {
      return accountTypeInputMapper.cost_type.includes(account_type?.id)
        ? object()
            .shape({ id: number().required('Required') })
            .required('Required')
            .nullable()
        : object().nullable();
    }),
    account_number: string().when('account_type', (account_type) => {
      return accountTypeInputMapper.account_number.includes(account_type?.id)
        ? string().trim().required('Required').min(4).nullable()
        : string().nullable();
    }),
    account_routing: string().when('account_type', (account_type) => {
      return accountTypeInputMapper.account_routing.includes(account_type?.id)
        ? string().trim().required('Required').nullable()
        : string().nullable();
    }),
    account_starting_check: string().when('account_type', (account_type) => {
      return accountTypeInputMapper.account_starting_check.includes(account_type?.id)
        ? string().trim().required('Required').nullable()
        : string().nullable();
    }),
    interest_rate: number().when('account_type', (account_type) => {
      return accountTypeInputMapper.loan.includes(account_type?.id)
        ? number().required('Required').nullable()
        : number().nullable();
    }),
    loan_term: number().when('account_type', (account_type) => {
      return accountTypeInputMapper.loan.includes(account_type?.id)
        ? number().required('Required').nullable()
        : number().nullable();
    }),
    loan_amount: number().when('account_type', (account_type) => {
      return accountTypeInputMapper.loan.includes(account_type?.id)
        ? number().required('Required').nullable()
        : number().nullable();
    }),
    opening_balance: number().when('has_opening_balance', (has_opening_balance) => {
      return has_opening_balance ? number().required('Required').nullable() : number().nullable();
    }),
    opening_date: date().when(['account_type', 'has_opening_balance'], (account_type, has_opening_balance) => {
      return accountTypeInputMapper.loan.includes(account_type?.id) || has_opening_balance
        ? validator.requiredDate()
        : validator.date();
    }),
    current_balance: number().when('account_type', (account_type) => {
      return accountTypeInputMapper.loan.includes(account_type?.id)
        ? number().required('Required').nullable()
        : number().nullable();
    }),
    compounding: object().when('account_type', (account_type) => {
      return accountTypeInputMapper.loan.includes(account_type?.id)
        ? object()
            .shape({ id: number().required('Required') })
            .required('Required')
            .nullable()
        : object().nullable();
    }),
    vendor: object().when(['account_type', 'add_recurring_bill'], (account_type, add_recurring_bill) => {
      return accountTypeInputMapper.loan.includes(account_type?.id) && add_recurring_bill
        ? object()
            .shape({ id: number().required('Required') })
            .required('Required')
            .nullable()
        : object().nullable();
    }),
    next_payment_due: date().when(['account_type', 'add_recurring_bill'], (account_type, add_recurring_bill) => {
      return accountTypeInputMapper.loan.includes(account_type?.id) && add_recurring_bill
        ? validator.requiredDate()
        : validator.date();
    }),
  });
};
