import axios from '../services/axios';

export async function getTabsCount(params) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/tabs-count`, { params });
  return data;
}

export async function getReportStates(params = {}) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/report-states`, { params });
  return data;
}

export async function getInspections(params, signal) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/inspections`, { params, signal });
  return data;
}

export async function getViolations(params, signal) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/violations`, { params, signal });
  return data;
}

export async function getCrashes(params, signal) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/crashes`, { params, signal });
  return data;
}

export async function getGraphData(params) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/graph-data`, { params });
  return data;
}

export async function getDriverRecordsCount(id) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/driver/${id}/count`);
  return data;
}

export async function getEquipmentRecordsCount(id) {
  const { data } = await axios.get(`customer/safety/dot-monitoring/equipment/${id}/count`);
  return data;
}

export async function getIncidentTabsCount(params) {
  const { data } = await axios.get(`customer/safety/incident/tab-count`, { params });
  return data;
}

export async function updateInspections(id, body) {
  const { data } = await axios.put(`customer/safety/dot-monitoring/inspection/${id}`, body);
  return data;
}

export async function updateViolations(id, body) {
  const { data } = await axios.put(`customer/safety/dot-monitoring/violation/${id}`, body);
  return data;
}

export async function updateCrash(id, body) {
  const { data } = await axios.put(`customer/safety/dot-monitoring/crash/${id}`, body);
  return data;
}

export async function acknowledgeInspections(id) {
  const { data } = await axios.post(`customer/safety/dot-monitoring/inspection/${id}/acknowledge`);
  return data;
}

export async function acknowledgeViolations(id) {
  const { data } = await axios.post(`customer/safety/dot-monitoring/violation/${id}/acknowledge`);
  return data;
}

export async function acknowledgeCrash(id) {
  const { data } = await axios.post(`customer/safety/dot-monitoring/crash/${id}/acknowledge`);
  return data;
}

export async function getAllIncidents(params, signal) {
  const { data } = await axios.get(`customer/safety/incidents`, { params, signal });
  return data;
}

export async function getIncidentSummary(params) {
  const { data } = await axios.get('customer/safety/incident/summary', { params });
  return data;
}

export async function getIncidentYears() {
  const { data } = await axios.get('customer/safety/incident/years');
  return data;
}

export async function getIncidentById(id) {
  const { data } = await axios.get(`driver/incident/${id}`);
  return data;
}

export async function getReportNumberList() {
  const { data } = await axios.get(`customer/safety/dot-monitoring/report-number-list`);
  return data;
}
