import React, { useMemo } from 'react';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';

export const Typography = ({ as, variant, style, children, ...props }) => {
  const { theme, use } = useTheme();

  const variantStyles = useMemo(() => {
    return {
      big: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '48px',
        color: use(palette.gray900, palette.gray50),
      },
      h1: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '32px',
        color: use(palette.gray900, palette.gray50),
      },
      h2: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '28px',
        color: use(palette.gray900, palette.gray50),
      },
      h3: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '32px',
        color: use(palette.gray900, palette.gray50),
      },
      h4: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '28px',
        color: use(palette.gray700, palette.gray50),
      },
      h5: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: use(palette.gray700, palette.gray50),
      },
      h6: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray900, palette.gray50),
      },
      s1: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: use(palette.gray900, palette.gray50),
      },
      s2: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray900, palette.gray50),
      },
      s3: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray900, palette.gray50),
      },
      b1: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: use(palette.gray900, palette.gray50),
      },
      b2: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray900, palette.gray50),
      },
      b3: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray900, palette.gray50),
      },
      c1: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
        color: use(palette.gray900, palette.gray50),
      },
      c2: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: use(palette.gray900, palette.gray50),
      },
      c3: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '16px',
        color: use(palette.gray900, palette.gray50),
      },
      button1: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray900, palette.gray50),
      },
      button2: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray900, palette.gray50),
      },
      overLine: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '16px',
        color: use(palette.gray700, palette.gray50),
      },
      overLine2: {
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: use(palette.gray700, palette.gray50),
      },
      title1: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '28px',
        color: use(palette.gray900, palette.gray50),
      },
      title2: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '28px',
        color: use(palette.gray900, palette.gray50),
      },
    };
  }, [theme, use, palette]);

  const As = as || 'span';

  const getStyles = useMemo(() => {
    return variantStyles[variant];
  }, [variant, variantStyles]);

  return (
    <As {...props} style={{ ...getStyles, ...style }}>
      {children}
    </As>
  );
};
