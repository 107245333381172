import React from 'react';
import MultiDropdownItem from './MultiDropdownItem';
import { useTheme } from '../../context/themeContext';
import { palette } from '../../utils/constants';
import classes from './MultiDropdown.module.css';

const FilterComponent = ({ filterBy }) => {
  const { use } = useTheme();
  return (
    <div className='default_searchTitle_wrapper'>
      <span className={classes.default_searchTitle} style={{ color: use(palette.gray700, palette.gray200) }}>
        {filterBy}
      </span>
    </div>
  );
};

const Dropdown = ({ data, filterBy }) => {
  return (
    <ul className='menus p-0'>
      <MultiDropdownItem data={data} depthLevel={0} component={<FilterComponent filterBy={filterBy} />} />
    </ul>
  );
};

export default Dropdown;
