import React, { useEffect } from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Radio from 'common/Radio';
import PhoneInput from 'common/PhoneInput';
import InputLabel from 'common/InputLabel';
import { DatePicker } from 'common/Pickers';
import ErrorMessage from 'common/ErrorMessage';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import Input, { InputWithIconAndText, Textarea } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { deletePreviousEmployment } from 'Api/JobPositions';
import { previousEmploymentInitialValues } from '../../ApplyJob.data';
import { SAddMore, SFormListItem } from '../Steps.styles';

const PreviousEmployment = ({ form, employments, onDelete, companyId }) => {
  const { use } = useTheme();
  const matches = useMediaQuery('(max-width:900px)');
  const { values, setValues, handleChange, touchedErrors } = form;

  const deleteEmployment = async (id) => {
    try {
      await deletePreviousEmployment(id, companyId);
      onDelete(id);
    } catch (e) {
      // Do nothing
    }
  };

  const addNewForm = () => {
    handleChange(values.length, previousEmploymentInitialValues[0]);
  };

  const removeForm = (i, id) => {
    if (id) {
      deleteEmployment(id);
    }

    const newValues = values.filter((item, index) => index !== i);
    setValues(newValues);
  };

  const handleAddressChange = (event, value, index) => {
    const addressFieldsMapper = {
      address: `${index}.address`,
      city: `${index}.city`,
      state: `${index}.state`,
      country: `${index}.country`,
      zipcode: `${index}.zipcode`,
    };

    if (event?.target) {
      const { name, value } = event.target;
      handleChange(addressFieldsMapper[name], value);
    } else {
      handleChange(addressFieldsMapper[event], value);
    }
  };

  const onPresentChange = (checked, index) => {
    if (!checked) {
      handleChange(`${index}.present`, checked);
    } else {
      setValues((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? { ...item, present: true, isCurrentEmployer: 1 }
            : { ...item, present: false, isCurrentEmployer: 0 }
        )
      );
    }
  };

  useEffect(() => {
    if (!employments?.length) {
      return;
    }

    const convertedEmployments = employments.map((item) => ({
      id: item.id,
      isNew: false,
      employerName: item.employer_name || '',
      email: item.email || '',
      phoneNumber: item.phone_no || '',
      fax: item.fax || '',
      present: Boolean(item.present),
      startDate: item.start_date ? moment(item.start_date).toDate() : null,
      endDate: item.present ? null : item.end_date ? moment(item.end_date).toDate() : null,
      address: item.address || '',
      country: item.country || null,
      state: item.state || null,
      city: item.city || null,
      zipcode: item.zipcode || '',
      position: item.position || '',
      reason: item.reason || '',
      terminated: item.terminated,
      isCurrentEmployer: item.current_employer,
      canContact: item.can_contact,
      operatedCommercialVehicle: item.operated_commercial_vehicle,
      subjectToSafetyRegulations: item.subject_to_safety_regulations,
      performSafetyFunctions: item.perform_safety_functions,
      areasDriven: item.areas_driven || '',
      milesDriven: item.miles_driven || '',
      payRange: item.pay_range || '',
      truckName: item.truck_name || '',
      trailerNameSize: item.trailer_name_size || '',
    }));
    setValues(convertedEmployments);
  }, [employments]);

  return (
    <form>
      <Grid container>
        {values.map((item, i, arr) => (
          <SFormListItem
            item
            container
            columnSpacing={2}
            rowSpacing={2}
            key={item.id || i}
            className={i === arr.length - 1 ? '' : 'mb-5'}
          >
            <Grid item xs={12} md={2}>
              <Input
                name={`${i}.employerName`}
                placeholder='Company Name'
                label='Employer Name'
                onChange={(e) => handleChange(`${i}.employerName`, e.target.value)}
                value={item.employerName}
                error={touchedErrors[i]?.employerName}
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Input
                name={`${i}.email`}
                placeholder='Email'
                label='Email'
                onChange={(e) => handleChange(`${i}.email`, e.target.value)}
                value={item.email}
                error={touchedErrors[i]?.email}
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <PhoneInput
                name={`${i}.phoneNumber`}
                placeholder='+1 (555) 555-1234'
                label='Phone Number'
                onChange={(val) => handleChange(`${i}.phoneNumber`, val)}
                value={item.phoneNumber}
                error={touchedErrors[i]?.phoneNumber}
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <PhoneInput
                name={`${i}.fax`}
                placeholder='+1 (555) 555-1234'
                label='Fax Number'
                onChange={(val) => handleChange(`${i}.fax`, val)}
                value={item.fax}
                error={touchedErrors[i]?.fax}
              />
            </Grid>
            <Grid item xs={2} md={0.5} style={{ textAlign: 'center' }}>
              <InputLabel>Present</InputLabel>
              <CustomCheckbox
                checked={item.present}
                onChange={(checked) => onPresentChange(checked, i)}
                style={{ justifyContent: 'center', height: 33 }}
              />
            </Grid>
            <Grid item xs={5} md={1.5}>
              <InputLabel required>Start Date</InputLabel>
              <DatePicker
                width='100%'
                disableFuture
                name={`${i}.startDate`}
                value={item.startDate}
                onChange={(val) => handleChange(`${i}.startDate`, val)}
                error={touchedErrors?.[i]?.startDate}
              />
            </Grid>
            <Grid item xs={5} md={2}>
              <div className='last-column'>
                <div>
                  <InputLabel required={!item.present}>End Date</InputLabel>
                  <DatePicker
                    width='100%'
                    name={`${i}.endDate`}
                    value={item.endDate}
                    onChange={(val) => handleChange(`${i}.endDate`, val)}
                    disabled={item.present}
                    disableFuture
                    error={touchedErrors?.[i]?.endDate}
                  />
                </div>
                {i !== 0 ? (
                  <DeleteIcon
                    width={12}
                    height={12}
                    fill={palette.indigo400}
                    onClick={() => removeForm(i, item.id)}
                    className='delete-icon'
                  />
                ) : (
                  !matches && <span style={{ width: 12 }} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <AddressFields
                required
                hideAddress2
                values={item}
                handleChange={(e, value) => handleAddressChange(e, value, i)}
                touchedErrors={touchedErrors?.[i]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                required
                name={`${i}.position`}
                label='Position Held'
                onChange={(e) => handleChange(`${i}.position`, e.target.value)}
                value={item.position}
                error={touchedErrors[i]?.position}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Textarea
                required={!item.present}
                rows={3}
                label='Reason for leaving'
                name={`${i}.reason`}
                value={item.reason}
                onChange={handleChange}
                error={touchedErrors?.[i]?.reason}
                disabled={item.present}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <div className='mb-3'>
                <InputLabel required>Were you terminated/discharged/laid off?</InputLabel>
                <div className='d-flex gap-3'>
                  <Radio
                    id={`${i}.terminated-1`}
                    name={`${i}.terminated`}
                    value={1}
                    checked={item.terminated === 1}
                    onChange={() => handleChange(`${i}.terminated`, 1)}
                    label='Yes'
                  />
                  <Radio
                    id={`${i}.terminated-0`}
                    name={`${i}.terminated`}
                    value={0}
                    checked={item.terminated === 0}
                    onChange={() => handleChange(`${i}.terminated`, 0)}
                    label='No'
                  />
                </div>
                <ErrorMessage error={touchedErrors?.[i]?.terminated} />
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className='mb-3'>
                <InputLabel required>Is this your current employer?</InputLabel>
                <div className='d-flex gap-3'>
                  <Radio
                    id={`${i}.isCurrentEmployer-1`}
                    name={`${i}.isCurrentEmployer`}
                    value={1}
                    checked={item.isCurrentEmployer === 1}
                    onChange={() => handleChange(`${i}.isCurrentEmployer`, 1)}
                    label='Yes'
                  />
                  <Radio
                    id={`${i}.isCurrentEmployer-0`}
                    name={`${i}.isCurrentEmployer`}
                    value={0}
                    checked={item.isCurrentEmployer === 0}
                    onChange={() => handleChange(`${i}.isCurrentEmployer`, 0)}
                    label='No'
                  />
                </div>
                <ErrorMessage error={touchedErrors?.[i]?.isCurrentEmployer} />
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className='mb-3'>
                <InputLabel required>May we contact this employer at this time?</InputLabel>
                <div className='d-flex gap-3'>
                  <Radio
                    id={`${i}.canContact-1`}
                    name={`${i}.canContact`}
                    value={1}
                    checked={item.canContact === 1}
                    onChange={() => handleChange(`${i}.canContact`, 1)}
                    label='Yes'
                  />
                  <Radio
                    id={`${i}.canContact-0`}
                    name={`${i}.canContact`}
                    value={0}
                    checked={item.canContact === 0}
                    onChange={() => handleChange(`${i}.canContact`, 0)}
                    label='No'
                  />
                </div>
                <ErrorMessage error={touchedErrors?.[i]?.canContact} />
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className='mb-3'>
                <InputLabel required>Did you operate a commercial motor vehicle?</InputLabel>
                <div className='d-flex gap-3'>
                  <Radio
                    id={`${i}.operatedCommercialVehicle-1`}
                    name={`${i}.operatedCommercialVehicle`}
                    value={1}
                    checked={item.operatedCommercialVehicle === 1}
                    onChange={() => handleChange(`${i}.operatedCommercialVehicle`, 1)}
                    label='Yes'
                  />
                  <Radio
                    id={`${i}.operatedCommercialVehicle-0`}
                    name={`${i}.operatedCommercialVehicle`}
                    value={0}
                    checked={item.operatedCommercialVehicle === 0}
                    onChange={() => handleChange(`${i}.operatedCommercialVehicle`, 0)}
                    label='No'
                  />
                </div>
                <ErrorMessage error={touchedErrors?.[i]?.operatedCommercialVehicle} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className='mb-3'>
                <InputLabel required>
                  Were you subject to the Federal Motor Carrier or Transport Canada Safety Regulations while
                  employed/contracted by this employer/contractor?
                </InputLabel>
                <div className='d-flex gap-3'>
                  <Radio
                    id={`${i}.subjectToSafetyRegulations-1`}
                    name={`${i}.subjectToSafetyRegulations`}
                    value={1}
                    checked={item.subjectToSafetyRegulations === 1}
                    onChange={() => handleChange(`${i}.subjectToSafetyRegulations`, 1)}
                    label='Yes'
                  />
                  <Radio
                    id={`${i}.subjectToSafetyRegulations-0`}
                    name={`${i}.subjectToSafetyRegulations`}
                    value={0}
                    checked={item.subjectToSafetyRegulations === 0}
                    onChange={() => handleChange(`${i}.subjectToSafetyRegulations`, 0)}
                    label='No'
                  />
                </div>
                <ErrorMessage error={touchedErrors?.[i]?.subjectToSafetyRegulations} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className='mb-3'>
                <InputLabel required>
                  Did you perform any safety sensitive functions in this job, regulated by DOT, and subject to drug and
                  alcohol testing?
                </InputLabel>
                <div className='d-flex gap-3'>
                  <Radio
                    id={`${i}.performSafetyFunctions-1`}
                    name={`${i}.performSafetyFunctions`}
                    value={1}
                    checked={item.performSafetyFunctions === 1}
                    onChange={() => handleChange(`${i}.performSafetyFunctions`, 1)}
                    label='Yes'
                  />
                  <Radio
                    id={`${i}.performSafetyFunctions-0`}
                    name={`${i}.performSafetyFunctions`}
                    value={0}
                    checked={item.performSafetyFunctions === 0}
                    onChange={() => handleChange(`${i}.performSafetyFunctions`, 0)}
                    label='No'
                  />
                </div>
                <ErrorMessage error={touchedErrors?.[i]?.performSafetyFunctions} />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Textarea
                rows={3}
                label='Areas Driven'
                name={`${i}.areasDriven`}
                value={item.areasDriven}
                onChange={handleChange}
                error={touchedErrors?.[i]?.areasDriven}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Input
                type='number'
                name={`${i}.milesDriven`}
                label='Miles Driven'
                onChange={(e) => handleChange(`${i}.milesDriven`, e.target.value)}
                value={item.milesDriven}
                onKeyDown={blockNonPositiveNumbers}
                error={touchedErrors[i]?.milesDriven}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputLabel>Pay Range (cents/mile)</InputLabel>
              <InputWithIconAndText
                type='number'
                text='/mile'
                name={`${i}.payRange`}
                onChange={(e) => handleChange(`${i}.payRange`, e.target.value)}
                value={item.payRange}
                onKeyDown={blockNonPositiveNumbers}
                error={touchedErrors[i]?.payRange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Input
                name={`${i}.truckName`}
                label='Most common truck driven?'
                onChange={(e) => handleChange(`${i}.truckName`, e.target.value)}
                value={item.truckName}
                error={touchedErrors[i]?.truckName}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Input
                name={`${i}.trailerNameSize`}
                label='Most common trailer and size?'
                onChange={(e) => handleChange(`${i}.trailerNameSize`, e.target.value)}
                value={item.trailerNameSize}
                error={touchedErrors[i]?.trailerNameSize}
              />
            </Grid>
          </SFormListItem>
        ))}
      </Grid>
      <SAddMore onClick={addNewForm}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
          Add More...
        </Typography>
      </SAddMore>
    </form>
  );
};

export default PreviousEmployment;
