import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from 'common/ConfirmModal';
import DryRun from 'components/TableRowEditModalTypes/DryRun';
import Layover from 'components/TableRowEditModalTypes/Layover';
import SplitShipment from 'componentsV2/Shipment/SplitShipment';
import Detention from 'components/TableRowEditModalTypes/Detention';
import CancelModal from 'components/TableRowEditModalTypes/CancelModal';
import ShareUpdate from 'components/TableRowEditModalTypes/ShareUpdate';
import CopyShipment from 'components/TableRowEditModalTypes/CopyShipment';
import RevertCancel from 'components/TableRowEditModalTypes/RevertCancel';
import ReplaceDriver from 'components/TableRowEditModalTypes/ReplaceDriver';
import UnReserve from 'components/TableRowEditModalTypes/Unreserve/UnReserve';
import CheckInOutModal from 'components/TableRowEditModalTypes/CheckInOutModal';
import ReserveShipment from 'components/TableRowEditModalTypes/ReserveShipment';
import UploadShipmentDocuments from 'components/TableRowEditModalTypes/UploadShipmentDocumnets';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { handleToaster } from 'store/reducers/root.reducer';
import { applyPlannerDryRun, copyShipment } from 'Api/Shipment';
import {
  CancelShipment,
  deleteReservation,
  postReservation,
  RevertCancelShipment,
  splitShipment,
  unsplitShipment,
} from 'Api/Planner';
import moment from 'moment/moment';

const TablePlannerRowEditChildes = ({ data, onSubmit, unReserveEndPointData, getShipment }) => {
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function getStopInCheckInOutModal() {
    const index = data.rowData?.shipment_stops?.findIndex((el) => el?.departure_date === null);
    return data?.rowData?.shipment_stops?.[index]
      ? {
          ...data?.rowData?.shipment_stops?.[index],
          index,
          shipment_billing: data.rowData?.shipment_billing,
          shipment_stops: data.rowData?.shipment_stops,
        }
      : {};
  }

  function createCopyFormData(input) {
    const formData = new FormData();
    formData.append('copy_count', input.copy_count);
    formData.append('shipment_id', data.rowData.shipment_id);
    formData.append('reference_id', input.reference_id);
    input.stops.forEach((item, index) => {
      if (item.scheduled_date) {
        formData.append(`scheduled_dates[${index}][scheduled_date]`, moment(item.scheduled_date).format('YYYY-MM-DD'));
        formData.append(`scheduled_dates[${index}][from]`, moment(item.scheduled_date).format('HH:mm'));
        formData.append(
          `scheduled_dates[${index}][scheduled_date_to]`,
          moment(item.scheduled_date_to || item.scheduled_date).format('YYYY-MM-DD')
        );
        formData.append(
          `scheduled_dates[${index}][to]`,
          moment(item.scheduled_date_to || item.scheduled_date).format('HH:mm')
        );
      }
    });
    return formData;
  }

  function createFormData(shipments) {
    const formData = new FormData();

    formData.append('shipment_id', data.rowData.shipment_id);

    for (let i = 0; i < shipments.length; i++) {
      for (let j = 0; j < shipments[i].length; j++) {
        if ('id' in shipments[i][j]) {
          formData.append(`shipments[${i}][${j}]`, shipments[i][j].id);
        }
      }
    }

    return formData;
  }

  // add endpoints
  function onSubmitHandler(id, values) {
    switch (+id) {
      case 2:
      case 4:
      case 5:
        const params = {
          shipment_id: values.shipment_id ? values.shipment_id : data.rowData.shipment_id,
          billing_amount: values.amount,
        };
        return applyPlannerDryRun(params)
          .then(() => {
            showToaster({ message: 'Success!', type: 'success' });
            setActiveId(null);
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
      case 6:
      case 7:
      case 8:
        setLoading(true);
        const splitParams = createFormData(values);
        return splitShipment(splitParams)
          .then(() => {
            showToaster({ message: 'Success!', type: 'success' });
            setActiveId(null);
            navigate('/planner', { state: { allValue: data?.rowData?.shipment_id?.split('-S')[0] } });
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }))
          .finally(() => setLoading(false));
      case 24:
        setLoading(true);
        return unsplitShipment({ shipment_id: data?.rowData?.shipment_id })
          .then(() => {
            showToaster({ message: 'Shipment has been successfully unsplit!', type: 'success' });
            setActiveId(null);
            navigate('/planner', { state: { allValue: data?.rowData?.shipment_id?.split('-S')[0] } });
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }))
          .finally(() => setLoading(false));
      case 11:
        const copyFormData = createCopyFormData(values);
        return copyShipment(copyFormData)
          .then((res) => {
            showToaster({ message: 'Success!', type: 'success' });
            setActiveId(null);
            return res;
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
      case 14:
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => setTimeout(resolve, 1000, values));
      case 12:
        return postReservation({
          shipment_id: data?.rowData?.shipment_id,
          duration: values?.reserve_duration === 'always' ? 0 : Number(values?.reserve_duration),
          type:
            values?.reserve_duration === 'always'
              ? 'always'
              : Number(values?.reserve_duration) === 1
              ? 'hour'
              : 'minute',
        })
          .then((res) => {
            const { user, duration, type } = res?.data || {};
            const message =
              type === 'always'
                ? `Shipment Reserved by ${user.first_name} ${user.last_name} until shipment is dispatched`
                : `Shipment Reserved by ${user.first_name} ${user.last_name} for ${duration} ${
                    duration === 1 ? type : `${type}s`
                  }`;
            dispatch(handleToaster({ visible: true, type: 'success', message }));
            return res;
          })
          .catch(() => {
            // Do nothing
          });
      case 112:
        deleteReservation({ reserve_id: String(data?.rowData?.shipment_reserve?.id) })
          .then((res) => {
            typeof onSubmit === 'function' && onSubmit({ lastData: data, res });
            dispatch(handleToaster({ visible: true, type: 'success', message: values?.title }));
            setActiveId(null);
          })
          .catch(() => {
            // Do nothing
          });
        break;
      case 17:
        return CancelShipment([...values])
          .then(() => {
            const message = `Shipment has been canceled`;
            dispatch(handleToaster({ visible: true, type: 'success', message }));
            setActiveId(null);
          })
          .catch(() => {
            // Do nothing
          });
      case 125:
        return RevertCancelShipment([...values])
          .then(() => {
            const message = `Shipment has been reverted`;
            dispatch(handleToaster({ visible: true, type: 'success', message }));
            setActiveId(null);
          })
          .catch(() => {
            // Do nothing
          });
      default:
    }
  }

  const onAdd = async (validateForm, resetForm, submitForm, values, isSplit) => {
    if (submitForm) {
      submitForm();
      return validateForm().then((errors) => {
        const isValid = Object.keys(errors).length === 0;
        if (isValid) {
          return onSubmitHandler(Number(data?.item?.id), values).then((res) => {
            typeof onSubmit === 'function' && !isSplit && onSubmit({ lastData: data, res });
            setActiveId(null);
            resetForm();
          });
        }
        setLoading(false);
        return Promise.resolve();
      });
    }
    return onSubmitHandler(Number(data?.item?.id), values).then((res) => {
      typeof onSubmit === 'function' && !isSplit && onSubmit({ lastData: data, res });
      setActiveId(null);
    });
  };

  const currentModal = useMemo(() => {
    switch (activeId) {
      case 2:
        return (
          <CheckInOutModal
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
            onRevertSuccess={() => onSubmit({ lastData: data })}
          />
        );
      case 4:
        return (
          <Detention isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={getStopInCheckInOutModal()} />
        );
      case 5:
        return <DryRun isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} planner />;
      case 6:
        return (
          <Layover isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={getStopInCheckInOutModal()} />
        );
      case 7:
        return (
          <ShareUpdate isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={getStopInCheckInOutModal()} />
        );
      case 8:
        return (
          <SplitShipment
            open={!!activeId}
            onClose={() => {
              setActiveId(null);
              if (getShipment) {
                getShipment();
              }
            }}
            shipment={data.rowData}
          />
        );
      case 24:
        return (
          <ConfirmModal
            open={!!activeId}
            title='Unsplit Shipment'
            text={`Are you sure you want to unsplit shipment ${data?.rowData?.shipment_id?.split('-S')[0]}?`}
            btnType='primary'
            btnText='Unsplit'
            onClose={() => setActiveId(null)}
            onConfirm={() => onSubmitHandler(data?.item?.id)}
          />
        );
      case 11:
        return (
          <CopyShipment
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
          />
        );
      case 12:
        return (
          <ReserveShipment
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
          />
        );
      case 112:
        return (
          <UnReserve
            tableData={data}
            title='Unreserve Shipment'
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onSubmitHandler}
            data={getStopInCheckInOutModal()}
            endPointData={unReserveEndPointData}
          />
        );
      case 13:
        return (
          <UploadShipmentDocuments open={!!activeId} onClose={() => setActiveId(null)} shipmentData={data.rowData} />
        );
      case 14:
        return (
          <ReplaceDriver
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
          />
        );
      case 17:
        return <CancelModal isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />;
      case 125:
        return <RevertCancel isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />;
      default:
    }
  }, [activeId, loading]);

  useEffect(() => {
    setActiveId(Number(data?.item?.id));
  }, [data]);

  if (activeId === null || activeId === undefined) return null;
  return <div>{currentModal}</div>;
};

export default TablePlannerRowEditChildes;
