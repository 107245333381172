import React from 'react';
import Input from 'common/Input';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { STable } from 'pages/Accounting/Payables/Payables.styles';
import { dueOptions, recurringOptions } from '../AddBill.data';

const Recurring = ({ values, handleChange, setValues, touchedErrors, readOnly, isRecurring }) => {
  const onBlur = (e) => {
    if (e.target.name === 'recurring_days' && Number(e.target.value) > 30) {
      handleChange('recurring_days', 30);
    }
  };

  const onKeyDown = (e) => {
    blockNotNumberChars(e);
    blockNonPositiveNumbers(e);
  };

  // useEffect(() => {
  //   if (values.bill_due_date && values.recurring_months) {
  //     handleChange(
  //       'next_renew_date',
  //       moment(values.bill_due_date).add(values.recurring_months.amount, values.recurring_months.unit).toDate()
  //     );
  //   }
  // }, [values.bill_due_date, values.recurring_months]);

  return (
    <div className='d-flex align-items-center gap-4 mt-4'>
      <STable>
        <thead>
          <tr className='nowrap'>
            {!readOnly && !isRecurring && <th />}
            <th>GENERATE</th>
            <th>BILL DATE</th>
            <th>DUE DATE</th>
            <th>END DATE</th>
          </tr>
        </thead>
        <tbody>
          <tr className='nowrap'>
            {!readOnly && !isRecurring && (
              <td>
                <CustomCheckbox
                  name='is_recurring'
                  checked={!!values.is_recurring}
                  onChange={(checked) => handleChange('is_recurring', checked)}
                  disabled={readOnly}
                />
              </td>
            )}
            <td>
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Generate bill every
                </Typography>
                <div>
                  <Input
                    type='number'
                    width='50px'
                    placeholder='00'
                    name='repeat_every_times'
                    value={values.repeat_every_times}
                    onChange={handleChange}
                    error={touchedErrors.repeat_every_times}
                    disabled={!values.is_recurring || readOnly}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                  />
                </div>
                <div>
                  <Autocomplete
                    width='150px'
                    name='recurring_months'
                    options={recurringOptions}
                    value={values.recurring_months}
                    onChange={(e, val) => {
                      setValues((prevState) => ({
                        ...prevState,
                        recurring_months: val,
                        due_day_category: null,
                      }));
                    }}
                    error={touchedErrors.recurring_months}
                    disabled={!values.is_recurring || readOnly}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                  />
                </div>
              </div>
            </td>
            <td>
              <DatePicker
                required
                width='160px'
                name='next_renew_date'
                value={values.next_renew_date}
                onChange={(val) => handleChange('next_renew_date', val)}
                disablePast
                disabled={!values.is_recurring || readOnly}
                error={touchedErrors.next_renew_date}
              />
            </td>
            <td>
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Due
                </Typography>
                <Input
                  type='number'
                  width='50px'
                  placeholder='00'
                  name='due_day'
                  value={values.due_day}
                  onChange={handleChange}
                  error={touchedErrors.due_day}
                  disabled={!values.is_recurring || readOnly}
                  onKeyDown={onKeyDown}
                  onBlur={onBlur}
                />
                <div>
                  <Autocomplete
                    width='270px'
                    name='due_day_category'
                    options={
                      values.recurring_months?.value !== 'Weekly'
                        ? dueOptions
                        : dueOptions.filter((option) => option.value !== 'current_month')
                    }
                    value={values.due_day_category}
                    onChange={(e, val) => handleChange('due_day_category', val)}
                    error={touchedErrors.due_day_category}
                    disabled={!values.is_recurring || readOnly}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                  />
                </div>
              </div>
            </td>
            <td>
              <DatePicker
                width='160px'
                required
                name='end_date'
                value={values.end_date}
                onChange={(val) => handleChange('end_date', val)}
                disabled={!values.is_recurring || readOnly}
                error={touchedErrors.end_date}
              />
            </td>
          </tr>
        </tbody>
      </STable>
    </div>
  );
};

export default Recurring;
