import React from 'react';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SWrapper } from './DetailsComponents.styles';

const SettlementTakeHome = ({ settlement }) => {
  const { amount } = settlement ?? {};

  return (
    <SWrapper $badgeColor={palette.green400}>
      <div className='w-100 d-flex justify-content-between'>
        <Typography variant='s2' style={{ color: palette.gray900 }} className='d-flex align-items-center gap-1'>
          <span className='detail-badge' />
          Take Home
        </Typography>
        <Typography variant='button2' style={{ color: palette.gray900 }}>
          ${formatNumber(amount)}
        </Typography>
      </div>
    </SWrapper>
  );
};

export default SettlementTakeHome;
