import { createContext } from 'react';

export const TableContext = createContext(null);
export const TableShipmentsContext = createContext(null);
export const CompletedShipmentContext = createContext(null);
export const RecurringContext = createContext(null);
export const QuotesContext = createContext(null);
export const TableEquipmentContext = createContext(null);
export const HardwareContext = createContext(null);

export const CarrierContext = createContext(null);
