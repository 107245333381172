import { array, date, number, object, string } from 'yup';
import moment from 'moment';
import { validator } from 'utils/helpers';

const chargesValidationSchema = array().of(
  object().shape({
    charge_type: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    quantity: string().trim().required('Required').typeError('Required').nullable(),
    rate: string().trim().required('Required').typeError('Required').nullable(),
  })
);

export const validationSchema = object().shape({
  expiry_date: validator.futureRequiredDate(),
  billing: object().shape({
    customer: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    reference_id: string().nullable(),
    contact: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    payment_term: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    total_power_unit: string().trim().required('Required').typeError('Required').nullable(),
    currency: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
  }),

  charges: chargesValidationSchema,
  line_items: chargesValidationSchema,

  legs: array().of(
    object().shape(
      {
        deadhead_stop_point: object().when(
          ['deadhead_country', 'deadhead_state', 'deadhead_city', 'deadhead_zipcode'],
          {
            is: (deadhead_country, deadhead_state, deadhead_city, deadhead_zipcode) =>
              !deadhead_country && !deadhead_state && !deadhead_city && !deadhead_zipcode,
            then: object()
              .shape({ id: number().required('Required') })
              .required('Required')
              .nullable(),
            otherwise: object().nullable(),
          }
        ),
        deadhead_country: object().when('deadhead_stop_point', {
          is: (deadhead_stop_point) => !deadhead_stop_point,
          then: object()
            .shape({ id: number().required('Required') })
            .required('Required')
            .nullable(),
          otherwise: object().nullable(),
        }),
        deadhead_state: object().when('deadhead_stop_point', {
          is: (deadhead_stop_point) => !deadhead_stop_point,
          then: object()
            .shape({ id: number().required('Required') })
            .required('Required')
            .nullable(),
          otherwise: object().nullable(),
        }),
        deadhead_city: object().when('deadhead_stop_point', {
          is: (deadhead_stop_point) => !deadhead_stop_point,
          then: object()
            .shape({ id: number().required('Required') })
            .required('Required')
            .nullable(),
          otherwise: object().nullable(),
        }),
        deadhead_zipcode: string().when('deadhead_stop_point', {
          is: (deadhead_stop_point) => !deadhead_stop_point,
          then: string().trim().required('Required').nullable(),
          otherwise: string().nullable(),
        }),
        deadhead_start_date: validator.requiredDate(),
        stops: array().of(
          object().shape(
            {
              stop_point: object().when(['country', 'state', 'city', 'zipcode'], {
                is: (country, state, city, zipcode) => !country && !state && !city && !zipcode,
                then: object()
                  .shape({ id: number().required('Required') })
                  .required('Required')
                  .nullable(),
                otherwise: object().nullable(),
              }),
              country: object().when('stop_point', {
                is: (stop_point) => !stop_point,
                then: object()
                  .shape({ id: number().required('Required') })
                  .required('Required')
                  .nullable(),
                otherwise: object().nullable(),
              }),
              state: object().when('stop_point', {
                is: (stop_point) => !stop_point,
                then: object()
                  .shape({ id: number().required('Required') })
                  .required('Required')
                  .nullable(),
                otherwise: object().nullable(),
              }),
              city: object().when('stop_point', {
                is: (stop_point) => !stop_point,
                then: object()
                  .shape({ id: number().required('Required') })
                  .required('Required')
                  .nullable(),
                otherwise: object().nullable(),
              }),
              zipcode: string().when('stop_point', {
                is: (stop_point) => !stop_point,
                then: string().trim().required('Required').nullable(),
                otherwise: string().nullable(),
              }),

              equipment_type: object().when('stop_point_type', {
                is: (stop_point_type) => Number(stop_point_type) === 1,
                then: object()
                  .shape({ id: number().required('Required') })
                  .required('Required')
                  .nullable(),
                otherwise: object().nullable(),
              }),
              equipment_type_length: object().when(['stop_point_type', 'equipment_type'], {
                is: (stop_point_type, equipment_type) => Number(stop_point_type) === 1 && Number(equipment_type) >= 12,
                then: object()
                  .shape({ id: number().required('Required') })
                  .required('Required')
                  .nullable(),
                otherwise: object().nullable(),
              }),
              equipment_action: object()
                .shape({ key: number().required('Required') })
                .required('Required')
                .nullable(),
              scheduled_type: object()
                .shape({ key: number().required('Required') })
                .required('Required')
                .nullable(),
              scheduled_date: date().when('scheduled_tbd', {
                is: (scheduled_tbd) => !scheduled_tbd,
                then: validator.requiredDate(),
                otherwise: validator.date(),
              }),
              scheduled_date_to: date().when(['scheduled_type', 'scheduled_tbd'], {
                is: (scheduled_type, scheduled_tbd) => [1, 3].includes(Number(scheduled_type) && !scheduled_tbd),
                then: validator
                  .requiredDate()
                  .test('Invalid', 'Start date cannot be after end date', (value, context) => {
                    const { scheduled_date, from, to } = context.parent || {};
                    if (
                      !scheduled_date ||
                      !from ||
                      !to ||
                      !moment(scheduled_date).isValid() ||
                      !moment(from).isValid() ||
                      !moment(to).isValid()
                    ) {
                      return true;
                    }

                    return moment(
                      `${moment(scheduled_date).format('YYYY-MM-DD')} ${moment(from).format('HH:mm')}`
                    ).isSameOrBefore(`${moment(value).format('YYYY-MM-DD')} ${moment(to).format('HH:mm')}`);
                  }),
                otherwise: validator.date(),
              }),
              from: date().when('scheduled_tbd', {
                is: (scheduled_tbd) => !scheduled_tbd,
                then: validator.requiredDate(),
                otherwise: validator.date(),
              }),
              to: date().when(['scheduled_type', 'scheduled_tbd'], {
                is: (scheduled_type, scheduled_tbd) => [1, 3].includes(Number(scheduled_type)) && !scheduled_tbd,
                then: validator.requiredDate(),
                otherwise: validator.date(),
              }),
            },
            [
              ['country', 'stop_point'],
              ['state', 'stop_point'],
              ['city', 'stop_point'],
              ['zipcode', 'stop_point'],
            ]
          )
        ),
      },
      [
        ['deadhead_country', 'deadhead_stop_point'],
        ['deadhead_state', 'deadhead_stop_point'],
        ['deadhead_city', 'deadhead_stop_point'],
        ['deadhead_zipcode', 'deadhead_stop_point'],
      ]
    )
  ),
});

export const validationSchemaDraft = object().shape({
  billing: object().shape({
    customer: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
  }),
});
