import React, { useEffect, useState, useContext } from 'react';
import 'components/customerOnboardingComponents/hardware/hardware.css';
import { Modal } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import OnboardingService from 'services/onboardingService';
import cartIcon from 'assets/images/onboarding/icon.png';
import xIcon from 'assets/icons/x.png';
import authHeader from 'services/auth-header';
import { formatNumber, palette } from 'utils/constants';
import { LayoutContext } from 'components/layout';
import NoRecords from 'common/NoRecords';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { SPairedWith } from 'components/MyHardware/HardwareDetails/HardwareDetails.styles';
import useShowToaster from 'hooks/useShowToaster';
import { getVehicleList } from 'Api/HOS';
import { SimpleLoader } from 'common/Loader';
import styles from './hardware.module.css';

const HardWareCardModal = ({
  selectedcard,
  setcardModal,
  handleAddcart,
  handleRemove,
  qty,
  onChange,
  value,
  vehiclesCount,
  loadingVehicles,
}) => {
  const [activePreviw, setactivePreviw] = useState(0);
  const requiresMinVehicle =
    !!selectedcard?.required_minimum_vehicle && selectedcard?.minimum_vehicles_count > vehiclesCount;

  return (
    <div className='modalContainer ' style={{ top: '-75px' }}>
      <div className='hardware-card-modal-area'>
        <div className='hardware-card-modal-title'>
          <p className='heading-top'>{selectedcard.name}</p>
        </div>
        <div className='hardware-card-modal-content'>
          <div className='hardware-card-modal-img'>
            <img className='hardware-img' alt='' src={selectedcard.images[activePreviw].image} />
            <div className='hardware-card-modal-img-icons'>
              {selectedcard.images.map((img, idx) => (
                <img
                  key={img.image}
                  className={
                    idx === activePreviw ? 'hardware-card-modal-img-icon' : 'hardware-card-modal-img-icon blur '
                  }
                  src={img.image}
                  alt=''
                  onClick={() => setactivePreviw(idx)}
                />
              ))}
            </div>

            {loadingVehicles ? (
              <div>
                <SimpleLoader loading size={24} />
              </div>
            ) : (
              <div>
                {requiresMinVehicle ? (
                  <div className='d-inline-flex align-items-center gap-1 mt-3'>
                    <InfoIcon width={16} height={16} />
                    <Typography variant='b2'>
                      Requires minimum fleet size of {selectedcard?.minimum_vehicles_count} for purchase
                    </Typography>
                  </div>
                ) : (
                  <div className='hardware-add modal-qty-btn'>
                    <div className='hardware-add-btn'>
                      <button className='minus-btn plus-btn ' disabled={qty === 0} onClick={handleRemove}>
                        -
                      </button>
                      <input className='input-num' placeholder={qty} onChange={onChange} value={value} />
                      <button className='plus-btn ' onClick={handleAddcart}>
                        +
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!!selectedcard.warranty?.length && (
              <div className='mt-4'>
                <p className='heading'>Warranty & Refunds</p>
                <ul>
                  {selectedcard.warranty.map((data) => (
                    <li key={data.id}>
                      <p className='m-0'>➝ {data.warranty}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className='hardware-card-modal-body pb-4'>
            <div className='descrtption'>
              <p className='heading'>Description</p>
              <p className='text'>{selectedcard.description}</p>
            </div>
            <div className='d-flex gap-4 mt-2 mb-2'>
              <div>
                <Typography variant='s2'>Make:</Typography>{' '}
                <Typography variant='b2'>{selectedcard.hardware_make}</Typography>
              </div>
              <div>
                <Typography variant='s2'>Model:</Typography>{' '}
                <Typography variant='b2'>{selectedcard.hardware_model}</Typography>
              </div>
            </div>
            <div className='finance-agrement'>
              <p className='finance-agrement-heading'>Finance Agreement</p>
              <div className='d-flex align-items-center gap-2 mb-1'>
                <p className='finance-agrement-text mb-0'>
                  ${formatNumber(selectedcard.financed)}
                  <span>
                    {' '}
                    + finance charge / unit, paid monthly{' '}
                    {selectedcard.contract_for_mo ? `for ${selectedcard.contract_for_mo} months` : ''}
                  </span>{' '}
                </p>
                <Chip
                  label='BASED UPON CREDIT APPROVAL'
                  labelColor={palette.green400}
                  bgColor={palette.green0}
                  fontWeight={500}
                  fontSize='12px'
                />
              </div>
              <div className='border' />
              <p className='finance-agrement-heading'>Credit Card / ACH</p>
              <p className='finance-agrement-text mb-0'>
                ${formatNumber(selectedcard.price)}
                <span> for one-time payment</span>{' '}
              </p>
            </div>
            {!!selectedcard.features?.length && (
              <div className='included'>
                <p className='heading'>What’s Included?</p>
                <p className='text' style={{ margin: '0px' }}>
                  This item includes the following:
                </p>
                <ul>
                  {selectedcard.features.map((data) => (
                    <li key={data.title}>
                      <p className='m-0'>➝ {data.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!!selectedcard.technical_spec?.length && (
              <div className='mt-3'>
                <p className='heading'>Technical Specs</p>
                <ul>
                  {selectedcard.technical_spec.map((data) => (
                    <li key={data.id}>
                      <p className='m-0'>➝ {data.technical_spec}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!!selectedcard.requirement?.length && (
              <div className='mt-4'>
                <p className='heading'>Requirements</p>
                <ul>
                  {selectedcard.requirement.map((data) => (
                    <li key={data.id}>
                      <p className='m-0'>➝ {data.requirement}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!!selectedcard.hardware_pair_with?.length && (
              <div className='mt-4'>
                <p className='heading'>Products Related To This Item</p>
                <div className='d-flex flex-wrap gap-2'>
                  {selectedcard.hardware_pair_with.map((data) => (
                    <SPairedWith key={data.id}>
                      {!!data.images?.[0]?.image && (
                        <img src={data.images[0].image} width={100} height={60} alt='hardware' />
                      )}
                      <div>
                        <Typography variant='s1'>{data.name}</Typography>
                        <div className='d-flex align-items-center gap-4 mt-1'>
                          <span className='paired-hardware-price'>
                            ${formatNumber(data.price)}
                            <span> for one-time payment</span>{' '}
                          </span>
                        </div>
                      </div>
                    </SPairedWith>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className='bottem'>
            <button onClick={() => setcardModal(false)}> Done</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const HardwareCard = ({
  data,
  index,
  ActiveCard,
  handleCardClick,
  addToCart,
  dataId,
  cartItems,
  vehiclesCount,
  loadingVehicles,
}) => {
  const [qty, setqty] = useState(0);
  const [cardModal, setcardModal] = useState(false);
  const [selectedcard, setSelectedcard] = useState({});

  const requiresMinVehicle = !!data?.required_minimum_vehicle && data?.minimum_vehicles_count > vehiclesCount;

  const handleQuantity = () => {
    setqty(0);
    addToCart({ ...data, dataId }, 0, dataId);
  };

  const handleAddcart = () => {
    setqty(qty + 1);
    addToCart({ ...data, dataId }, qty + 1, dataId);
  };

  const handleRemove = () => {
    if (qty <= 0) return;
    setqty(qty - 1);
    addToCart({ ...data, dataId }, qty - 1, dataId);
  };

  const handleModal = () => {
    setcardModal(true);
  };

  const handleClick = () => {
    handleCardClick(index, data);
    setSelectedcard(data);
  };

  useEffect(() => {
    if (!data) return;
    const CartQty = cartItems?.filter((v) => v.id === dataId);
    setqty(CartQty?.length > 0 ? CartQty[0]?.quantity : 0);
  }, []);

  useEffect(() => {
    if (!data) return;
    const backCartItems = JSON.parse(localStorage.getItem('hardwareCart'));
    if (backCartItems !== null) {
      const ele = backCartItems?.find((ele) => ele.dataId === dataId);
      if (ele !== undefined) setqty(ele?.quantity);
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    const goBackCartItems = JSON.parse(localStorage.getItem('goBackCartItems'));
    if (goBackCartItems !== null && goBackCartItems?.length !== 0) {
      const ele = goBackCartItems?.find((ele) => ele.dataId === dataId);
      if (ele !== undefined) setqty(ele?.quantity);
    }
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const NumberRegEx = /'^[0-9]*$'/;
    if (!NumberRegEx.test(value)) return;
    setqty(Number(value));
    addToCart({ ...data, dataId }, Number(value), dataId);
  };

  if (!data) {
    return null;
  }
  return (
    <div className={ActiveCard === index ? 'hardware-card active-card' : 'hardware-card'} onClick={handleClick}>
      {cardModal ? (
        <HardWareCardModal
          selectedcard={selectedcard}
          setcardModal={setcardModal}
          qty={qty}
          handleRemove={handleRemove}
          handleAddcart={handleAddcart}
          handleQuantity={handleQuantity}
          onChange={handleChange}
          value={qty}
          vehiclesCount={vehiclesCount}
          loadingVehicles={loadingVehicles}
        />
      ) : null}

      <div className='hardware-card-img-container'>
        <img className='hardware-img' src={data?.images[0].image} onClick={handleModal} alt='' />
      </div>
      <p onClick={handleModal} className='hardware-name'>
        {data?.name}
      </p>
      <p className='card-price'>
        {data.financed ? (
          <p className='card-price'>
            ${(Number(data?.price) / 36).toFixed(2)} <span>Per Month </span>{' '}
          </p>
        ) : (
          <p className='card-price'>
            ${data.price} <span>Per Unit </span>{' '}
          </p>
        )}
      </p>
      {loadingVehicles ? (
        <div>
          <SimpleLoader loading size={24} />
        </div>
      ) : (
        <div>
          {requiresMinVehicle ? (
            <div className='d-inline-flex gap-1'>
              <InfoIcon width={16} height={16} style={{ marginTop: '2px' }} />
              <Typography variant='b2'>
                Requires minimum fleet size of {selectedcard?.minimum_vehicles_count} for purchase
              </Typography>
            </div>
          ) : (
            <div className='hardware-add'>
              <div className='hardware-add-btn'>
                <button className='minus-btn plus-btn ' disabled={qty === 0} onClick={handleRemove}>
                  -
                </button>
                <input className='input-num' placeholder={qty} onChange={handleChange} value={qty} />
                <button className='plus-btn ' onClick={handleAddcart}>
                  +
                </button>
              </div>
              {qty > 0 && <img src={xIcon} onClick={handleQuantity} alt='Xicon' />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Hardware = ({ purchaseDevice, Cancel, prevStep, CartItemQty, equipment, onSuccess }) => {
  const showToaster = useShowToaster();
  const { setCartItemsData } = useContext(LayoutContext);
  const [hardware, setHardware] = useState([]);
  const [ActiveCard, setactiveCard] = useState();
  const [addBtn, setaddBtn] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isAddtoCart, setisAddtoCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehiclesCount, setVehiclesCount] = useState(0);
  const [loadingVehicles, setLoadingVehicles] = useState(false);

  const getVehicles = async () => {
    try {
      setLoadingVehicles(true);
      const { data } = await getVehicleList();
      setVehiclesCount(data.length);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingVehicles(false);
    }
  };

  useEffect(() => {
    getVehicles();
  }, []);

  const [skipCart, setSkipCart] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const getHardware = async () => {
    setLoading(true);
    await OnboardingService.getHardware()
      .then((res) => {
        const visibleHardware = (res.data.data || []).filter(
          (i) =>
            !!i.is_visible_in_equipment &&
            i.equipment_types?.some((type) => type?.id === equipment?.equipmentType?.equipment_type_id)
        );
        setHardware(visibleHardware);
      })
      .finally(() => setLoading(false));
  };

  const AddEquipment = () => {
    showToaster({
      type: 'success',
      message: `Successfully created equipment ${equipment?.equipmentInfo?.equipment_id}`,
    });
    onSuccess(equipment?.equipmentInfo?.equipment_id);
    Cancel();
  };

  useEffect(() => {
    const items = [];
    if (CartItemQty) {
      CartItemQty?.map((cart) => {
        const data = hardware?.find((v) => v.id === cart.hardware_id);
        items.push({ ...data, quantity: cart?.quantity, id: cart?.hardware_id });
        return cart;
      });

      setCartItems(items);
    }
  }, [CartItemQty, hardware]);
  const addToCart = (data, qty) => {
    if (cartItems.length !== 0) {
      const isFound = cartItems.some((element) => {
        return element.dataId === data.id;
      });
      if (isFound) {
        cartItems.forEach((ele) => {
          if (ele.dataId === data.id) {
            ele.quantity = qty;
          }
        });
      } else {
        let items = [];
        items.push({ ...data, quantity: qty, dataId: data.id });
        items = [...cartItems, ...items];
        setCartItems(items);
      }
    } else {
      let items = [];

      items.push({ ...data, quantity: qty, dataId: data.id });
      items = [...cartItems, ...items];
      setCartItems(items);
    }
    if (qty <= 0) {
      const arr = cartItems.filter((ele) => ele.quantity !== 0);
      setCartItems(arr);
    }
  };

  const onContinue = () => {
    const hardwareItems = [];
    cartItems?.forEach((data) => {
      hardwareItems.push({ id: data.id, quantity: data.quantity });
    });
    localStorage.setItem('hardwareItem', JSON.stringify(hardwareItems));
    localStorage.setItem('hardware', JSON.stringify(cartItems));
    const submitForPrices = {
      payment_method: 'credit-card',
      hardware: hardwareItems,
      applied_coupon: '',
    };

    axios
      .post(`${API_URL}/hardware-cart`, submitForPrices, {
        headers: authHeader(),
      })
      .then((res) => {
        setCartItemsData(res);
        setSkipCart(true);
        setisAddtoCart(true);
        localStorage.removeItem('hardwareItem');
      });
  };

  useEffect(() => {
    setisAddtoCart(false);
  }, [cartItems]);

  const handleCardClick = (idx) => {
    setactiveCard(idx);
    setaddBtn(true);
  };

  useEffect(() => {
    const backCartItems = JSON.parse(localStorage.getItem('hardwareCart'));
    if (backCartItems !== null && backCartItems?.length !== 0) {
      setCartItems(backCartItems);
    }
  }, []);

  useEffect(() => {
    const goBackCartItems = JSON.parse(localStorage.getItem('goBackCartItems'));
    if (goBackCartItems !== null && goBackCartItems?.length !== 0) {
      setCartItems(goBackCartItems);
    }
  }, []);

  useEffect(() => {
    getHardware();
  }, []);

  return (
    <>
      <Modal.Body className={styles.hardwareModalBody}>
        <div className=''>
          <div className='d-flex' role='group' aria-labelledby='my-radio-group'>
            <p>Purchase Hardware</p>
          </div>
          <div className={styles.hardwareSubBody}>
            <div className='hardware-container'>
              {!loading ? (
                hardware?.map((data, index) => {
                  return (
                    <HardwareCard
                      key={data?.id}
                      dataId={data?.id}
                      data={data}
                      index={index}
                      ActiveCard={ActiveCard}
                      handleCardClick={handleCardClick}
                      addBtn={addBtn}
                      setCartItems={setCartItems}
                      cartItems={cartItems}
                      addToCart={addToCart}
                      vehiclesCount={vehiclesCount}
                      loadingVehicles={loadingVehicles}
                    />
                  );
                })
              ) : (
                <CircularProgress
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                  }}
                />
              )}
            </div>
            {!loading && !hardware?.length && (
              <div className='h-25 d-flex justify-content-center align-items-center'>
                <NoRecords />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between', padding: '5px 20px' }}>
        <button className={styles['type-cancel-button']} onClick={Cancel}>
          Cancel
        </button>
        <div className='d-flex'>
          <button className={styles['type-cancel-button']} onClick={prevStep}>
            Back
          </button>
          {purchaseDevice !== 'ExistingDevice' ? (
            <button
              className={styles['add-equipment-next-step']}
              type='submit'
              style={{ color: '#fff', backgroundColor: '#4F5AED' }}
            >
              {cartItems.length && !isAddtoCart ? (
                <span onClick={onContinue}>
                  <img src={cartIcon} alt='cartIcon' style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                  Add to cart
                </span>
              ) : (
                <span onClick={AddEquipment}>{skipCart ? 'Create Equipment' : 'Skip and Create'} </span>
              )}
            </button>
          ) : (
            <button
              className={styles['add-equipment-next-step']}
              onClick={AddEquipment}
              style={{ color: '#fff', backgroundColor: '#4F5AED' }}
            >
              Add Equipment
            </button>
          )}
        </div>
      </Modal.Footer>
    </>
  );
};

export default Hardware;
