import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import CustomButton from 'components/CustomButton/CustomButton';
import useShowToaster from 'hooks/useShowToaster';
import { getAppUrl } from 'utils/helpers';
import { palette } from 'utils/constants';

const Actions = () => {
  const showToaster = useShowToaster();
  const navigate = useNavigate();

  const onCopyUrl = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dot = user?.customer?.dot;
    const url = getAppUrl();

    if (!url || !dot) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
      return;
    }

    try {
      await navigator.clipboard.writeText(`${url}/job-positions/${dot}`);
      showToaster({ type: 'success', message: 'URL copied successfully!' });
    } catch (err) {
      showToaster({ type: 'error', message: 'URL copy failed!' });
    }
  };

  return (
    <div className='d-flex align-items-center gap-2'>
      <CustomButton
        width={12}
        height={12}
        type='secondary'
        title='Share'
        leftIcon={<ShareIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
        styleButton={{
          padding: '6px 12px',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
        }}
        onClick={onCopyUrl}
      />
      <CustomButton
        width={12}
        height={12}
        title=''
        type='secondary'
        leftIcon={<GearIcon fill={palette.gray700} />}
        styleButton={{
          padding: '6px 12px',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
          height: '32px',
        }}
        onClick={() => navigate('/company-settings', { state: { tabId: 11 } })}
      />
    </div>
  );
};

export default Actions;
