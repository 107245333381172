import React from 'react';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const ConfirmModal = ({ open, invoice, onClose, onConfirm, disabled }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Confirm Underpay Applyment'
      $maxWidth='470px'
      $minWidth='470px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Proceed',
          onClick: onConfirm,
          disabled,
        },
      ]}
    >
      <Typography variant='s1' as='p' className='mt-3 mb-3' style={{ color: palette.gray700 }}>
        Underpayment will be applied on the invoice {invoice?.invoice_id} also. Do you want to proceed?
      </Typography>
    </Modal>
  );
};

export default ConfirmModal;
