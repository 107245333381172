import React from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/drivers/upload.svg';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { palette } from 'utils/constants';
import { uploadDriverDocument } from 'Api/Driver';
import { SAddMore, SFlexWrapper } from 'componentsV2/MedicalAndBackgroundTab/MedicalAndBackgroundTab.styles';

const MultipleDocuments = ({ values, handleChange, name }) => {
  const uploadDocument = (file) => {
    uploadDriverDocument(file[0], 1).then((res) => {
      handleChange(name, [...values[name], res.data.full_path]);
    });
  };

  const onAttachmentDelete = (e, index) => {
    e.stopPropagation();
    const newImages = values[name].filter((item, i) => i !== index);
    handleChange(name, newImages);
  };

  return (
    <div className='d-flex align-items-center mt-2'>
      <SFlexWrapper>
        {values?.[name]?.map((image, index) => (
          <div key={image?.id || index}>
            <p className='attachment-wrapper' style={{ backgroundColor: palette.indigo0 }}>
              <img src={right} alt='attachment' className='attachment-icon' />
              <span className='text-style' style={{ color: palette.green400 }}>
                File {index + 1}
              </span>
              <img
                src={cross}
                alt='cross'
                className='upload-cancel-icon'
                onClick={(e) => onAttachmentDelete(e, index)}
              />
            </p>
          </div>
        ))}
        <SAddMore className='mt-2'>
          <UploadIcon width={14} height={13} fill={palette.indigo500} />
          <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
            {values?.[name]?.length ? 'Add Another...' : 'Attach document...'}
          </Typography>
          <ImageDropZone
            multiple
            onDrop={(files) => uploadDocument(files)}
            width='100%'
            height='100%'
            name='small'
            deletable={false}
            customStyle={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
            accept={['application/pdf']}
          />
        </SAddMore>
      </SFlexWrapper>
    </div>
  );
};

export default MultipleDocuments;
