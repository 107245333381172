import moment from 'moment';

export const rateParams = [
  { name: 'Hour(s)', value: 1 },
  { name: 'Day(s)', value: 2 },
];

export const durationParams = [{ duration_type: 'hours' }, { duration_type: 'days' }];

export const getInitialValues = (data, convertToCustomerTime) => {
  return {
    rate: '',
    duration: '',
    total_sum: '',
    bill_rate_type_id: 1,
    add_charge: data.shipment_billing?.customer_billed ? 0 : 1,
    arrived_date: moment(convertToCustomerTime(data.arrived_date)).toDate(),
    departed_date: moment(convertToCustomerTime(data.departed_date)).toDate(),
  };
};
