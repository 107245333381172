import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import AddressAutocomplete from 'common/AddressAutocomplete';
import { getCities, getStates } from 'Api/JobPositions';
import { COUNTRIES } from 'pages/PublicPositions/ApplyJob/ApplyJob.data';

const AddressFields = ({ label, form }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { values, handleChange, touchedErrors } = form;

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      /* empty */
    }
  };

  const onAddressSelect = (data) => {
    handleChange('country', data.country);
    handleChange('state', data.state);
    handleChange('city', data.city);
  };

  const changeAddress = ({ address, zip }) => {
    handleChange('address1', address);
    handleChange('zipCode', zip || values.zipCode);
  };

  useEffect(() => {
    if (values.country) {
      getStatesList({ 'country_id[]': [values.country.id] });
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      getCitiesList(values.state.id);
    }
  }, [values.state]);

  return (
    <Grid container item xs={12} columnSpacing={2.5} rowSpacing={2} className='mt-0'>
      <Grid item xs={12}>
        <AddressAutocomplete
          label={label}
          required
          name='address1'
          id='address1'
          onSuccess={onAddressSelect}
          changeAddress={changeAddress}
          onChange={handleChange}
          value={values.address1}
          error={touchedErrors?.address1}
          options={{ componentRestrictions: { country: ['us', 'ca', 'mx'] } }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name='address2'
          placeholder='Address 2'
          onChange={handleChange}
          value={values.address2}
          error={touchedErrors.address2}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          name='state'
          placeholder='Select State..'
          value={values.state}
          onChange={(e, value) => {
            handleChange('state', value);
            handleChange('city', null);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={states}
          error={touchedErrors.state}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          name='city'
          placeholder='Select City..'
          value={values.city}
          onChange={(e, value) => handleChange('city', value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={cities}
          error={touchedErrors.city}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Autocomplete
          name='country'
          placeholder='Select Country..'
          value={values.country}
          onChange={(e, value) => {
            handleChange('country', value);
            handleChange('state', null);
            handleChange('city', null);
          }}
          options={COUNTRIES}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          error={touchedErrors.country}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          name='zipCode'
          placeholder='Zip Code'
          onChange={handleChange}
          value={values.zipCode}
          error={touchedErrors.zipCode}
          required
        />
      </Grid>
    </Grid>
  );
};

export default AddressFields;
