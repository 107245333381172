import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { absenceBasedOnOptions, palette } from 'utils/constants';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { getDriverAbsence, getStaffAbsence } from 'Api/CompanySettings';
import { validationSchema } from './validationSchema';
import './UpdateAbsenceAllowanceModal.css';

export default function AbsenceAllowanceModal({
  show,
  onHide,
  absenceAllowance,
  updateAbsenceAllowanceData,
  userType,
  onSuccess,
  absLoading,
}) {
  const [absenceSettings, setAbsenceSettings] = useState(null);

  const onSubmit = (values) => {
    updateAbsenceAllowanceData(
      {
        ...values,
        absence_reset_based_on: values.absence_reset_based_on?.id,
        use_absence_policy: Number(values.use_absence_policy),
      },
      onHide,
      onSuccess,
      'Attendance Allowance has been updated successfully'
    );
  };

  const { values, handleSubmit, handleBlur, setValues, touchedErrors } = useForm({
    initialValues: {
      excused_absences: '',
      unexcused_absences: '',
      vacations_allowed: '',
      absence_reset_based_on: absenceBasedOnOptions[0],
      use_absence_policy: false,
    },
    onSubmit,
    validationSchema,
  });

  const getSettings = async () => {
    try {
      const { data } = await (userType === 'staff' ? getStaffAbsence() : getDriverAbsence());
      setAbsenceSettings(data || null);
      if (
        data &&
        !absenceAllowance?.excused_absences &&
        !absenceAllowance?.unexcused_absences &&
        !absenceAllowance?.vacations_allowed
      ) {
        setValues((prevState) => ({
          ...prevState,
          excused_absences: data.excused_absences,
          unexcused_absences: data.unexcused_absences,
          vacations_allowed: data.vacations_allowed,
          absence_reset_based_on: absenceBasedOnOptions.find((i) => i.id === data.reset_based_on) || null,
          use_absence_policy: true,
        }));
      }
    } catch (e) {
      // Do nothing
    }
  };

  const applyFromSettings = (checked) => {
    setValues((prevState) => ({
      ...prevState,
      excused_absences: checked ? absenceSettings.excused_absences : absenceAllowance.excused_absences,
      unexcused_absences: checked ? absenceSettings.unexcused_absences : absenceAllowance.unexcused_absences,
      vacations_allowed: checked ? absenceSettings.vacations_allowed : absenceAllowance.vacations_allowed,
      absence_reset_based_on: checked
        ? absenceBasedOnOptions.find((i) => i.id === absenceSettings.reset_based_on)
        : absenceBasedOnOptions.find((i) => i.id === absenceAllowance.absence_reset_based_on),
      use_absence_policy: checked,
    }));
  };

  const onFieldChange = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
      use_absence_policy: false,
    }));
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (
      !!absenceAllowance?.excused_absences ||
      !!absenceAllowance?.unexcused_absences ||
      !!absenceAllowance?.vacations_allowed
    ) {
      setValues((prevState) => ({
        ...prevState,
        excused_absences: absenceAllowance.excused_absences,
        unexcused_absences: absenceAllowance.unexcused_absences,
        vacations_allowed: absenceAllowance.vacations_allowed,
        use_absence_policy: !!absenceAllowance.use_absence_policy,
        absence_reset_based_on:
          absenceBasedOnOptions.find((i) => i.id === absenceAllowance.absence_reset_based_on) || null,
      }));
    }
  }, [absenceAllowance]);

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName='modal-70w'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: palette.white,
            borderColor: palette.gray50,
          }}
        >
          <Modal.Title className='heading' id='contained-modal-title-vcenter' style={{ color: palette.gray900 }}>
            Update Attendance Allowance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='absence-modal-body-box driver-style-wrap'
          style={{
            backgroundColor: palette.white,
            color: palette.gray50,
            borderColor: palette.white,
          }}
        >
          <div className='absence-modal-wrap'>
            <div className='absence-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: palette.gray700 }}>
                Excused Attendance <span className='required'>*</span>
              </p>
              <div>
                <input
                  type='number'
                  id='excused_absences'
                  onBlur={handleBlur}
                  onChange={(e) => onFieldChange('excused_absences', e.target.value)}
                  value={values.excused_absences}
                  className='update-input'
                  style={{ backgroundColor: palette.white }}
                />
                <ErrorMessage error={touchedErrors.excused_absences} />
              </div>
            </div>
          </div>
          <div className='absence-modal-wrap'>
            <div className='absence-container-wrapper'>
              <p className='text-style abcense-text-style' style={{ color: palette.gray700 }}>
                Unexcused Attendance <span className='required'>*</span>
              </p>
              <div>
                <input
                  type='number'
                  id='unexcused_absences'
                  onBlur={handleBlur}
                  onChange={(e) => onFieldChange('unexcused_absences', e.target.value)}
                  value={values.unexcused_absences}
                  className='update-input'
                  style={{ backgroundColor: palette.white }}
                />
                <ErrorMessage error={touchedErrors.unexcused_absences} />
              </div>
            </div>
          </div>
          <div className='absence-modal-wrap'>
            <div>
              <p className='text-style abcense-text-style' style={{ color: palette.gray700 }}>
                Paid Time Off (PTO) <span className='required'>*</span>
              </p>
              <div>
                <input
                  type='number'
                  id='vacations_allowed'
                  onBlur={handleBlur}
                  onChange={(e) => onFieldChange('vacations_allowed', e.target.value)}
                  value={values.vacations_allowed}
                  className='update-input'
                  style={{ backgroundColor: palette.white }}
                />
                <ErrorMessage error={touchedErrors.vacations_allowed} />
              </div>
            </div>
          </div>
          <div className='mt-4'>
            <Autocomplete
              label='Reset Based On'
              name='absence_reset_based_on'
              placeholder='Based on..'
              options={absenceBasedOnOptions}
              value={values.absence_reset_based_on}
              onChange={(e, value) => onFieldChange('absence_reset_based_on', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.absence_reset_based_on}
            />
          </div>
          {!!absenceSettings && (
            <div className='mt-4'>
              <CustomCheckbox checked={values.use_absence_policy} onChange={applyFromSettings}>
                <Typography variant='s2' style={{ marginLeft: '8px' }}>
                  Use From Company Settings
                </Typography>
              </CustomCheckbox>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: palette.white,
            borderColor: palette.gray50,
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={onHide}>
              Cancel
            </Button>
            {absLoading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' type='submit' onClick={handleSubmit}>
                Update
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
