import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import useDateFormat from 'hooks/useDateFormat';
import { SPickerWrapper } from '../Pickers.styles';

const DatePicker = ({ label, error, required, labelProps, width, borderRadius, onBlur, size, ...props }) => {
  const { pickerDateFormat } = useDateFormat();

  return (
    <SPickerWrapper $width={width} $borderRadius={borderRadius} $size={size}>
      {!!label && (
        <InputLabel required={required} {...labelProps}>
          {label}
        </InputLabel>
      )}
      <MuiDatePicker format={pickerDateFormat} slotProps={{ textField: { onBlur } }} {...props} />
      <ErrorMessage error={error} />
    </SPickerWrapper>
  );
};

export default DatePicker;
