import React, { useState } from 'react';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import RecurringModal from '../../../components/RecurringModal';
import { monthDays, monthWeekOptions, weekDays } from '../../Tables.data';
import { SAutocomplete, SRecurringWrapper, SFlexWrapper } from '../../Tables.styles';

const Recurring = ({ data, onDayChange, onMonthWeekChange, handleSubmit }) => {
  const { use } = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <SFlexWrapper>
      {!!data.every_settlement && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          Every Settlement
        </Typography>
      )}
      {!!data.month_week_settlement &&
        (data.edit || data.isNew ? (
          <SRecurringWrapper>
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
              Every
            </Typography>
            <SAutocomplete
              width={data.month_week === 'Month' ? '68px' : '114px'}
              value={data.day || null}
              onChange={(e, value) => onDayChange(data.id, value)}
              options={data.month_week === 'Month' ? monthDays : weekDays}
              className='week-days'
            />
            <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
              per
            </Typography>
            <SAutocomplete
              width='88px'
              value={data.month_week || null}
              onChange={(e, value) => onMonthWeekChange(data.id, value)}
              options={monthWeekOptions}
            />
          </SRecurringWrapper>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
            Every <Chip label={data.day} fontSize='12px' fontWeight='500' /> per {data.month_week}
          </Typography>
        ))}
      {!!data.one_time_addition && (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          One Time Addition
        </Typography>
      )}
      {(data.edit || data.isNew) && <GearIcon onClick={() => setOpen(true)} />}
      {open && (
        <RecurringModal
          open={open}
          onClose={() => setOpen(false)}
          data={data}
          width='500px'
          hideAmortized
          isAddition
          handleSubmit={(updatedData) => {
            handleSubmit(data.id, updatedData);
            setOpen(false);
          }}
        />
      )}
    </SFlexWrapper>
  );
};

export default Recurring;
