import { array, object, string } from 'yup';

export const validationSchema = object().shape({
  card_provider: string().trim().required('Required'),
  card_holders: array()
    .of(
      object({
        id: string().trim().required('Required'),
      })
    )
    .min(1, 'At least 1 cardholder is required'),
});
