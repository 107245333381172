import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getAvailableDrivers } from 'Api/Shipment';
import { validationSchema } from 'components/TableRowEditModalTypes/ReplaceDriver/validationSchema';
import classes from '../Detention/detention.module.scss';
import { Typography } from '../../Typography';
import CustomButton from '../../CustomButton/CustomButton';
import Driver from './types/Driver';
import Vehicle from './types/Vehicle';

const ReplaceDriver = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();
  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );
  const styles = useMemo(() => {
    return {
      modal: {
        width: 560,
        overflow: 'hidden',
        maxHeight: '90%',
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
    };
  }, [use, palette]);
  const [drivers, setDrivers] = useState([]);

  useEffect(async () => {
    const drivers = await getAvailableDrivers();
    const newData = drivers.data.map((item) => {
      return {
        ...item,
        label: `${item.fname}  ${item.lname}`,
        key: item.id,
        labelSelected: null,
      };
    });
    setDrivers(newData);
  }, []);

  const initialValue = useMemo(() => {
    return {
      assigned_to1: '',
      assigned_to2: '',
      equipment_id: '',
      amount1: '',
      amount2: '',
      previous_driver1_amount: '',
      previous_driver2_amount: '',
      replace1: true,
      replace2: !!data?.assigned_to_2,
      vehicle: {},
    };
  }, []);

  return (
    <Modal
      size='lg'
      centered
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      dialogClassName='dialogClassName'
    >
      <Formik
        initialValues={{ ...initialValue }}
        enableReinitialize
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {({ values, resetForm, submitForm, validateForm, touched, errors, setValues }) => {
          return (
            <Form>
              <Modal.Body style={styles.modalBody}>
                <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <Typography variant='button2'>Replace Driver</Typography>
                  <Typography variant='overLine'>{subTitle}</Typography>
                </div>
                <div
                  className={classes.modalBody}
                  style={{
                    backgroundColor: use(palette.gray0, palette.dark800),
                    borderColor: use(palette.gray50, palette.gray700),
                  }}
                >
                  <div className={classes.switchers}>
                    {data?.assigned_to_1 && (
                      <div>
                        <Driver
                          index={1}
                          data={data.assigned_to_1}
                          drivers={drivers}
                          values={values}
                          setValues={setValues}
                          hasSecond={!!data?.assigned_to_2}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    )}
                    {data?.assigned_to_2 && (
                      <div>
                        <Driver
                          index={2}
                          data={data?.assigned_to_2}
                          drivers={drivers}
                          values={values}
                          setValues={setValues}
                          touched={touched}
                          errors={errors}
                          hasSecond
                        />
                      </div>
                    )}

                    <Vehicle data={data} name='vehicle' values={values} setValues={setValues} />
                  </div>
                </div>

                <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
                  <div
                    className={classes.modalFooterLane}
                    style={{ backgroundColor: use(palette.white, palette.dark900) }}
                  >
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title='Swap'
                      onClick={() => onAddBtn(validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                      disabled={!values.replace1 && !values.vehicle?.equipment_id}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ReplaceDriver;
