import React, { useEffect, useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getVendor } from 'Api/Carriers';
import Bills from './Bills';

const CarrierBillTab = ({ carrier, onSuccess }) => {
  const [vendor, setVendor] = useState(null);

  useEffect(async () => {
    if (carrier?.status?.title !== 'Pending') {
      const { data } = await getVendor(carrier.vendor_id);
      setVendor(data);
    }
  }, [carrier]);

  if (carrier?.status?.title === 'Pending') {
    return (
      <div className='d-flex justify-content-center'>
        <div className='not-active-info-wrapper'>
          <InfoIcon width={16} height={16} />
          <Typography variant='s1' style={{ color: palette.indigo500, whiteSpace: 'nowrap' }}>
            You must set this Carrier to Active first to view bills.
          </Typography>
        </div>
      </div>
    );
  }

  return <Bills vendor={vendor} refreshList={onSuccess} carrierBill />;
};

export default CarrierBillTab;
