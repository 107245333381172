import React, { useState } from 'react';
import './dispatchChannel.css';
import micIcon from 'assets/icons/mic-48.svg';
import MenuItem from '../MenuItem/MenuItem';
import UserList from '../UserList/UserList';
import DeleteChannel from '../DeleteChannel/DeleteChannel';

const DispatchChannel = ({ selctedChannel }) => {
  const [channelUsers] = useState([]);
  const [leaveChannel, setleaveChannel] = useState(false);
  const [startRecording, setstartRecording] = useState(false);

  const handleRecording = () => {
    setstartRecording(!startRecording);
  };

  return (
    <div className='dispatch-channel'>
      {leaveChannel && (
        <DeleteChannel
          heading='Leave Channel'
          message='Are you sure you want to leave this channel? Leaving this channel will remove all the access and conversations.'
          deleteBtnName='Leave Channel'
          onCancel={setleaveChannel}
        />
      )}
      <div className='channel-header'>
        <p>{selctedChannel.channel_name}</p>
        <div className='users'>
          {channelUsers.map((user, idx) =>
            idx <= 5 ? (
              <div className='usersImg' key={user.id}>
                {idx <= 4 && <img src={user.avatar_url} alt='users' />}
                <UserList idx={idx} />
              </div>
            ) : null
          )}
          <MenuItem onLeave={setleaveChannel} leaveChannel />
        </div>
      </div>
      <div className='chats'>
        <div className='chat'>
          <p>.......</p>
        </div>
        <div className='chat-two'>
          <p>......</p>
        </div>
      </div>
      <div className='recorder'>
        <div className={startRecording ? 'recording-mic box-shadow ' : 'recording-mic'} onClick={handleRecording}>
          <div className={startRecording ? 'start-recording start' : 'start-recording'}>
            {!startRecording && <img src={micIcon} alt='' />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DispatchChannel;
