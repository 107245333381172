import React, { useEffect, useState } from 'react';
import validator from 'validator/es';
import { useNavigate } from 'react-router-dom';
import eyeIcon from 'assets/icons/eye.png';
import ErrorMessage from 'common/message/errorMessage';
import { useAuth } from 'context/auth.context';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { channelMsg, tabChannel } from 'channels/tabChannel';
import { MenuIcons } from '../leftMenu/menuIcons';
import CustomButton from '../CustomButton/CustomButton';
import { Typography } from '../Typography';
import sm from './IdleModal.module.css';

const IdleModal = ({ handleStillHere, modalData, setOpen }) => {
  const { use } = useTheme();
  const { title, description, buttonTitle, isLogout } = modalData;
  const { LogoIcon, TruckInDigital } = MenuIcons;
  const buttonsStyles = {
    padding: '2px 8px',
    margin: '20px 0 0 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 14,
  };

  return (
    <div className={sm.idleModal_overlay}>
      <div
        className={sm.idleModal_container}
        style={{ backgroundColor: use(palette.white, palette.dark700) }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={sm.header}>
          <LogoIcon />
          <TruckInDigital style={{ marginLeft: 8 }} />
        </div>

        <div className={sm.body}>
          <Typography variant='h5'>{title}</Typography>
          <Typography variant='b1' style={{ marginTop: 2, textAlign: 'center' }}>
            {description}
          </Typography>
          {isLogout ? (
            <SignInModal setOpen={setOpen} />
          ) : (
            <CustomButton
              type='primary'
              title={buttonTitle}
              styleButton={buttonsStyles}
              onClick={(e) => {
                e.stopPropagation();
                handleStillHere(isLogout);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IdleModal;

const SignInModal = ({ setOpen }) => {
  const { value, login, firstLogin } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const checkIfValidEmail = (e) => {
    if (validator.isEmail(e.target.value)) {
      setUsername(e.target.value);
      handleEmailChange(e.target.value);
    }
  };

  const handleEmailChange = (values) => {
    firstLogin(values);
  };

  const handleSubmit = () => {
    login(username, password, false, { user: value.user, isLoggedIn: true }).then((res) => {
      if (res && res?.id) {
        setOpen(false);
        tabChannel.postMessage(channelMsg.CLOSE_AWAY_MODAL).catch(null);
        navigate('/dashboard');
      }
    });
  };

  const handleDisplayErrorMsg = () => {
    return value.error ? setDisplayError(!displayError) : null;
  };

  useEffect(() => {
    handleDisplayErrorMsg();
  }, [value.error]);

  return (
    <div>
      <input
        id='username'
        placeholder='Email'
        type='email'
        className='form-control shadow-none'
        onChange={(e) => {
          checkIfValidEmail(e);
        }}
      />
      <input
        id='password'
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        className='form-control'
        type={!showPwd ? 'password' : 'text'}
        placeholder='Password'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
      <div className='input-group-addon'>
        <img src={eyeIcon} onClick={() => setShowPwd(!showPwd)} style={{ transform: 'translateX(190px)' }} alt='eye' />
      </div>
      <ErrorMessage
        message={value.error ? 'Incorrect password or email' : ''}
        close={() => setDisplayError(false)}
        show={displayError}
      />
      <button className='button btn' onClick={handleSubmit}>
        I’m Back!
      </button>
    </div>
  );
};
