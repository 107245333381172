import moment from 'moment/moment';
import { date, object, ref } from 'yup';
import { validator } from 'utils/helpers';

const oneYearAgo = moment().subtract(1, 'year').toDate();

export const validationSchema = object().shape({
  start_date: date().when('type', {
    is: (type) => type === 2,
    then: validator
      .requiredDate()
      .max(ref('end_date'), "Start date can't be after end date!")
      .min(oneYearAgo, "Start date can't be more than one year in the past from today!")
      .nullable(),
  }),
  end_date: date().when('type', {
    is: (type) => type === 2,
    then: validator
      .requiredDate()
      .min(ref('start_date'), '')
      .min(oneYearAgo, "End date can't be more than one year in the past from today!")
      .nullable(),
  }),
});
