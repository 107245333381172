import { object, string, array } from 'yup';
import { SEND_PAYMENT_KEYS } from './constants';

export const getValidationSchema = (activeSelectName) => {
  const noAmount =
    activeSelectName === SEND_PAYMENT_KEYS.OPEN_BILLS || activeSelectName === SEND_PAYMENT_KEYS.OPEN_SETTLEMENTS;
  return object().shape({
    printChecks: array().of(
      object().shape({
        checkNumber: string()
          .trim()
          .required('Required')
          .test('Required', function (value, context) {
            const startingCheckNumberValue = context?.from?.[1]?.value?.startingCheckNumberValue;
            if (
              startingCheckNumberValue !== undefined &&
              parseInt(value, 10) < parseInt(startingCheckNumberValue, 10)
            ) {
              return context.createError({ message: `check number should be more then ${startingCheckNumberValue}` });
            }
            return true;
          })
          .test('much', "The Check Numbers can't be the same", function (value, context) {
            const printChecks = context?.from?.[1]?.value?.printChecks;
            let foundedError = true;
            if (printChecks !== undefined) {
              foundedError = printChecks.find(
                (el) => el?.checkNumber !== undefined && el?.checkNumber === value && this.parent?.id !== el?.id
              );
            }
            return !foundedError;
          })
          .nullable(),
        memoValue: string().trim().required('Required').nullable(),
        amount: noAmount ? string() : string().trim().required('Required').nullable(),
      })
    ),
    paid_by_account: object().required('Required').nullable(),
    startingCheckNumberValue: string()
      .required('Required')
      .test('Required', function (value) {
        const defaultCheckNumber = this.parent.defaultStartingCheckNumberValue;
        if (parseInt(defaultCheckNumber, 10) > parseInt(value, 10)) {
          return this.createError({
            message: `Starting check number should be more or equal than ${defaultCheckNumber}`,
          });
        }
        return true;
      })
      .nullable(),
  });
};

// export const validationSchema = object().shape({
//   printChecks: array().of(
//     object().shape({
//       checkNumber: string()
//         .trim()
//         .required('Required')
//         .test('Required', function (value, context) {
//           const startingCheckNumberValue = context?.from?.[1]?.value?.startingCheckNumberValue;
//           if (startingCheckNumberValue !== undefined && parseInt(value) < parseInt(startingCheckNumberValue)) {
//             return context.createError({ message: `check number should be more then ${startingCheckNumberValue}` });
//           }
//           return true;
//         })
//         .test('much', "The Check Numbers can't be the same", function (value, context) {
//           const printChecks = context?.from?.[1]?.value?.printChecks;
//           let foundedError = true;
//           if (printChecks !== undefined) {
//             foundedError = printChecks.find(
//               (el) => el?.checkNumber !== undefined && el?.checkNumber === value && this.parent?.id !== el?.id
//             );
//           }
//           return !foundedError;
//         })
//         .nullable(),
//       memoValue: string().trim().required('Required').nullable(),
//       amount: string().trim().required('Required').nullable(),
//     })
//   ),
//   paid_by_account: object().required('Required').nullable(),
//   startingCheckNumberValue: string()
//     .required('Required')
//     .test('Required', function (value) {
//       const defaultCheckNumber = this.parent.defaultStartingCheckNumberValue;
//       if (parseInt(defaultCheckNumber) > parseInt(value)) {
//         return this.createError({
//           message: `Starting check number should be more or equal than ${defaultCheckNumber}`,
//         });
//       }
//       return true;
//     })
//     .nullable(),
// });
