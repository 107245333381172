import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Search from 'common/Search';
import Autocomplete from 'common/Autocomplete';
import { SDatePicker, SSearchWrapper } from '../AlertsTable.styles';

const TableFilters = ({ search, setSearch, dateRange, onDateRangeChange }) => {
  const [filterType, setFilterType] = useState('All');
  const [range, setRange] = useState(dateRange);
  const [startDate, endDate] = range;

  const onRemoveRange = () => {
    setRange([null, null]);
    onDateRangeChange([null, null]);
  };

  return (
    <SSearchWrapper>
      <div>
        <Autocomplete
          width='90px'
          options={['All', 'Date']}
          value={filterType}
          onChange={(e, val) => setFilterType(val)}
          inputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FilterIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      {filterType === 'All' && <Search search={search} onChange={setSearch} className='search-input' />}
      {filterType === 'Date' && (
        <div className='position-relative'>
          <SDatePicker
            selectsRange
            renderStart={false}
            shouldCloseOnSelect={false}
            startDate={startDate}
            endDate={endDate}
            placeholderText='MM/DD/YYYY - MM/DD/YYYY'
            onChange={(dates) => {
              setRange(dates);
              if (dates[0] && dates[1]) {
                onDateRangeChange(dates);
              }
            }}
          />
          <DeleteIcon className='delete-icon' onClick={onRemoveRange} />
        </div>
      )}
    </SSearchWrapper>
  );
};

export default TableFilters;
