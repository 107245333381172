import { ACCOUNT_TYPE } from 'utils/constants';
import moment from 'moment';
import { createBill } from 'Api/Payables';
import { createAccountTransaction, createBankAccountTransaction } from 'Api/Accounts';
import { CreateChargeType } from 'Api/CompanySettings';
import { accountTypeInputMapper } from './AddAccount.data';

export const createOpeningBalanceTransaction = async (values, account) => {
  try {
    if (account.account_type.id === ACCOUNT_TYPE.BANK) {
      const body = {
        account_id: account.id,
        date: moment(values.opening_date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        reference_id: 'Opening Balance',
        payee_id: 0,
        payee_type: null,
        account: 0,
        memo: 'Opening Balance',
        reconciled: 1,
        payment: 0,
        deposit: Number(values.opening_balance),
      };
      await createBankAccountTransaction(body);
    } else {
      const body = {
        account_id: account.id,
        date: moment(values.opening_date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        reference_id: 'Opening Balance',
        payee_id: 0,
        payee_type: null,
        account: 0,
        memo: 'Opening Balance',
        debit: accountTypeInputMapper.loan.includes(values.account_type?.id)
          ? Number(values.loan_amount)
          : Number(values.opening_balance),
        credit: 0,
      };
      await createAccountTransaction(body);
    }
  } catch (e) {
    // Do nothing
  }
};

export const createBalanceAdjustmentTransaction = async (values, account) => {
  try {
    const body = {
      account_id: account.id,
      date: moment(values.opening_date).startOf('day').add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
      reference_id: 'Balance Adjustment',
      payee_id: 0,
      payee_type: null,
      account: 0,
      memo: 'Balance Adjustment',
      debit: 0,
      credit: Number(values.loan_amount) - Number(values.current_balance),
    };
    await createAccountTransaction(body);
  } catch (e) {
    // Do nothing
  }
};

export const createRecurringBill = async (values, account) => {
  try {
    const { vendor, account_name, next_payment_due } = values || {};
    const body = {
      title: `Loan Payment (${vendor.name})`,
      account_id: account.id,
      type_id: values.account_type.id,
      desc: 'Loan Payment',
      code: null,
    };
    const { data: item } = await CreateChargeType(body);

    const { interest_rate, loan_term, loan_term_type, loan_amount, compounding } = account || {};
    const interest = Number(compounding) === 1 ? interest_rate / 100 / 12 : (1 + interest_rate / 100) ** (1 / 12) - 1;
    const numberOfPayments = loan_term * (loan_term_type === 'months' ? 1 : 12);

    const monthlyPayment =
      loan_amount * ((interest * (1 + interest) ** numberOfPayments) / ((1 + interest) ** numberOfPayments - 1));

    const formData = new FormData();
    formData.append('vendor_id', vendor.id);
    formData.append('reference_id', account_name);
    formData.append('date_entered', moment().format('YYYY-MM-DD'));
    formData.append('account_id', account.id);
    formData.append('amount_due', monthlyPayment.toString());

    formData.append('payment_term_id', '1');
    formData.append('bill_due_date', moment(next_payment_due).format('YYYY-MM-DD'));
    formData.append('memo', account_name);

    formData.append('is_recurring', '1');
    formData.append('recurring_days', '15');
    formData.append('recurring_months', 'Monthly');
    formData.append('recurring_duration', '2');
    formData.append('recurring_frequency', numberOfPayments.toString());
    formData.append('next_renew_date', moment(next_payment_due).format('YYYY-MM-DD'));

    formData.append(`items[0][item_id]`, item.id);
    formData.append(`items[0][desc]`, `${account_name} - Loan Payment`);
    formData.append(`items[0][quantity]`, '1');
    formData.append(`items[0][price]`, monthlyPayment.toString());

    await createBill(formData);
  } catch (e) {
    // Do nothing
  }
};
