import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import { palette } from 'utils/constants';

export const SUserInfo = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  .user-name-wrapper {
    display: inline-flex;
    flex-direction: column;
  }

  .user-name {
    color: ${palette.indigo500};
  }

  .user-name:hover {
    text-decoration: underline;
  }

  .user-type {
    font-size: 14px;
    color: ${palette.gray700};
  }

  .phone-icon {
    margin-left: 8px;

    svg path {
      fill: ${palette.indigo400};
    }
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const SDotsWrapper = styled.div`
  cursor: pointer;

  .dots {
    display: inline-flex;
    width: 20px;
    height: 20px;
  }

  .popover-actions {
    list-style-type: none;
    background: #ffffff;
    box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 0;
  }
`;

export const SPopover = styled(Popover)`
  &.MuiPopover-root {
    .MuiPaper-root {
      margin-top: -12px;
    }

    .actions-wrapper {
      list-style-type: none;
      background: #ffffff;
      box-shadow: 0 0 0 1px rgba(152, 161, 179, 0.1), 0 15px 35px -5px rgba(17, 24, 38, 0.2),
        0 5px 15px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 6px 4px;

      li {
        text-transform: none;
        cursor: pointer;
        width: 100%;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        border-radius: 4px;

        &.delete-action {
          color: ${palette.red500};
        }

        :hover {
          background-color: ${palette.indigo0};
        }
      }
    }
  }
`;

export const SActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
`;

export const SFiltersWrapper = styled.div`
  display: flex;
  column-gap: 24px;
  margin-top: 16px;
`;

export const SSearchWrapper = styled.div`
  display: flex;

  .MuiAutocomplete-root .MuiInputBase-root {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .MuiAutocomplete-root.filter-dropdown .MuiInputBase-root {
    border-radius: 0 6px 6px 0;

    &.MuiOutlinedInput-root {
      padding: 6px 24px 5px 6px;
    }
  }

  .transaction-search-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
