import React from 'react';
import { Line } from 'react-chartjs-2';
import { palette } from 'utils/constants';
import { monthOfCollisionOptions, timeOfDayConverter } from './Charts.data';

const TimeOfDayChart = ({ monthsData }) => {
  const data = {
    labels: (monthsData?.time_of_day || []).map((item) => `${timeOfDayConverter(item?.time_of_day)}`),
    datasets: [
      {
        label: '',
        data: monthsData?.time_of_day?.map((i) => i.total_count) || [],
        borderColor: palette.red500,
        pointBackgroundColor: palette.red500,
      },
    ],
  };

  return <Line options={monthOfCollisionOptions()} data={data} />;
};

export default TimeOfDayChart;
