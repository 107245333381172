import React, { useState } from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { Groups } from 'Api/Equipment';
import authHeader from 'services/auth-header';
import styles from './equipmentTab.module.css';

const EQUIPMENT_TYPES = {
  VEHICLE: 'Vehicle Type',
  TRAILER: 'Trailer Type',
};

const EquipmentModal = ({ Tabs, multiSelectOptions, onHide, onChange, OnTabChange, currentType }) => {
  const { value } = useAuth();
  const isManagement = value?.user?.department?.department_name === 'Management';
  const [mySwitch] = useState(true);
  const [CheckboxValue, setCheckboxValue] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [tabName, setTabName] = useState();
  const [statusId, setStatusId] = useState([]);
  const [applyUser, setApplyUser] = useState(false);
  const { use } = useTheme();
  const API_URL = process.env.REACT_APP_API_URL;

  const status = [
    {
      title: 'Assigned',
      id: 15,
      value: 'Assigned',
      type: 'checkbox',
      status_id: '7',
      parent: 'Status',
    },
    {
      title: 'Available',
      id: 12,
      value: 'Available',
      type: 'checkbox',
      status_id: '1',
      parent: 'Status',
    },
    {
      title: 'On Shipment',
      id: 13,
      value: 'On Shipment',
      type: 'checkbox',
      status_id: '2',
      parent: 'Status',
    },
    {
      title: 'Out Of Service',
      id: 14,
      value: 'Out Of Service',
      type: 'checkbox',
      status_id: '3',
      parent: 'Status',
    },
    {
      title: 'Not Assigned',
      id: 14,
      value: 'Not Assigned',
      type: 'checkbox',
      status_id: '4',
      parent: 'Status',
    },
    {
      title: 'Dropped Trailer',
      id: 14,
      value: 'Dropped Trailer',
      type: 'checkbox',
      status_id: '6',
      parent: 'Status',
    },
    {
      title: 'Retired',
      id: 14,
      value: 'Retired',
      type: 'checkbox',
      status_id: '5',
      parent: 'Status',
    },
  ];
  const { data, isLoading } = useQuery('grp', Groups);
  if (isLoading) {
    return <span className='text-style' />;
  }
  const array = data.data.map((t) => ({
    label: `${t.group_name}`,
    value: t.id,
  }));
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      marginTop: 4,
      minWidth: 250,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      // backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
  };
  const onChangeTrailer = (e, id) => {
    let updatedList = [...CheckboxValue];
    if (e.target.checked) {
      updatedList = [...CheckboxValue, id];
    } else {
      updatedList.splice(CheckboxValue.indexOf(id), 1);
    }
    setCheckboxValue(updatedList);
  };
  const GrouphandleChange = (e) => {
    setGroupData(e);
  };
  const onChangeStatus = (e, id) => {
    let updatedList = [...statusId];
    if (e.target.checked) {
      updatedList = [...statusId, id];
    } else {
      updatedList.splice(statusId.indexOf(id), 1);
    }
    setStatusId(updatedList);
  };

  const onChangeTick = (e) => {
    setApplyUser(e.target.checked);
  };
  const equipmentData = (options, type) => {
    let data;
    options?.submenu?.forEach((item) => {
      item?.value === type ? (data = item?.submenu) : item?.submenu && equipmentData(item);
    });

    return data;
  };

  const tabHandleSubmit = () => {
    let groupvalue = [];
    if (groupData) {
      groupvalue = groupData.map((v) => v?.value);
    }

    const values = {
      type: currentType === 'Vehicle Type' ? 'vehicle' : 'trailer',
      data: {
        name: tabName,
        filters: {
          status: statusId,
          equipment_type_id: CheckboxValue,
          group_id: groupvalue,
        },
      },
      forAll: applyUser,
    };
    axios
      .post(`${API_URL}/tabs`, values, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res) {
          Tabs();
          OnTabChange(res?.data?.data?.id, res?.data?.data?.type, res?.data?.data);
          onHide();
          onChange(1, res?.data?.data?.id);
        }
      });
  };
  return (
    <>
      <div className={styles['equipment-container']} style={{ backgroundColor: use(palette.gray50, palette.dark900) }}>
        <label className={styles.label_wrapper}>
          <span style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>Tab name</span>
          <input
            type='text'
            onChange={(e) => setTabName(e.target.value)}
            style={{
              backgroundColor: use(palette.white, palette.dark900),
              color: use(palette.gray700, palette.gray200),
            }}
            required
          />
        </label>

        <div className={styles.select_wrapper} style={{ color: use(palette.gray700, palette.gray200) }}>
          {mySwitch && (
            <div className={styles.equipment_mode_wrapper}>
              {currentType === EQUIPMENT_TYPES.VEHICLE ? (
                <div className={styles.equipment_childes}>
                  {equipmentData(multiSelectOptions, EQUIPMENT_TYPES.VEHICLE)?.map((item) => {
                    return (
                      <div className={styles.equipment_child} key={item.id}>
                        <input type='checkbox' onChange={(e) => onChangeTrailer(e, item.equipment_id)} />
                        <span style={{ color: use(palette.gray700, palette.gray200) }}>{item.title}</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={styles.equipment_childes}>
                  {equipmentData(multiSelectOptions, EQUIPMENT_TYPES.TRAILER)?.map((item) => {
                    return (
                      <div className={styles.equipment_child} key={item.equipment_id}>
                        <input type='checkbox' onChange={(e) => onChangeTrailer(e, item.equipment_id)} />
                        <span style={{ color: use(palette.gray700, palette.gray200) }}>{item.title}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          <div style={{ marginTop: 32 }}>
            <span style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>If has Tag</span>
            <Select
              isMulti
              options={array}
              type='checkbox'
              name='array'
              onChange={GrouphandleChange}
              styles={customStyles}
            />
          </div>
          {currentType === EQUIPMENT_TYPES.VEHICLE ? (
            <div style={{ marginTop: 32, color: use(palette.gray700, palette.gray200) }}>
              <span style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>If Status</span>
              {status.map((item) => {
                return (
                  item.title !== 'Dropped Trailer' &&
                  item.title !== 'Assigned' && (
                    <div>
                      <input type='checkbox' onChange={(e) => onChangeStatus(e, item.status_id)} key={item.id} />
                      <span style={{ color: use(palette.gray700, palette.gray200) }}>{item.title}</span>
                    </div>
                  )
                );
              })}
            </div>
          ) : currentType === EQUIPMENT_TYPES.TRAILER ? (
            <div style={{ marginTop: 32 }}>
              <span style={{ marginLeft: 0 }}>If Status</span>

              {status.map((item) => {
                return (
                  item.title !== 'Available' &&
                  item.title !== 'On Shipment' && (
                    <div>
                      <input type='checkbox' onChange={(e) => onChangeStatus(e, item.status_id)} key={item.id} />
                      <span style={{ color: use(palette.gray700, palette.gray200) }}>{item.title}</span>
                    </div>
                  )
                );
              })}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className={styles.customFooter}
        style={{
          backgroundColor: use(palette.white, palette.dark900),
          color: use(palette.gray700, palette.gray200),
        }}
      >
        <div className='d-flex'>
          {isManagement && (
            <>
              <input type='checkbox' id='user' onChange={(e) => onChangeTick(e)} style={{ cursor: 'pointer' }} />
              <label
                htmlFor='user'
                className={styles.footer_title_checkbox}
                style={{ marginLeft: '6px', color: use(palette.gray700, palette.gray200) }}
              >
                Apply to all users
              </label>
            </>
          )}
        </div>
        <div className='footer_buttons'>
          <button
            className={styles.TabCloseButton}
            style={{
              backgroundColor: use(palette.white, palette.dark800),
              color: use(palette.gray700, palette.gray200),
            }}
            onClick={onHide}
          >
            Close
          </button>
          <button className={styles.addTabButtons} onClick={tabHandleSubmit}>
            Add Tab
          </button>
        </div>
      </div>
    </>
  );
};

export default EquipmentModal;
