import React from 'react';
import License from 'assets/icons/drivers/license.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { SWrapper } from './RuleDetails.styles';

const appliesToOptions = [
  { id: 1, name: 'Driver', value: 'driver' },
  { id: 2, name: 'Staff', value: 'staff' },
];

const RuleDetails = ({ open, onClose, rule }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={rule.title}
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='1200px'
      className='modified-scrollbar'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <SWrapper>
        <div className='d-flex justify-content-between'>
          <div className='incident-details-container'>
            <div className='d-flex flex-column gap-1'>
              <Typography variant='overLine2'>Associated Incident Type</Typography>
              <div>
                {rule.incident_types.map((incident) => (
                  <span key={incident.id}>
                    <Chip
                      size='small'
                      label={incident.type}
                      labelColor={palette.indigo500}
                      bgColor={palette.indigo100}
                      style={{ marginLeft: '4px' }}
                    />
                  </span>
                ))}
              </div>
            </div>
            <div className='d-flex flex-column gap-1'>
              <Typography variant='overLine2'>Applies To</Typography>
              <div>
                {appliesToOptions.map(
                  (el) =>
                    rule.applies_to?.includes(el.value) && (
                      <span key={el.id}>
                        <Chip
                          size='small'
                          label={el.name}
                          labelColor={palette.indigo500}
                          bgColor={palette.indigo100}
                          style={{ marginLeft: '4px' }}
                        />
                      </span>
                    )
                )}
              </div>
            </div>
            <div className='d-flex flex-column gap-1'>
              <Typography variant='overLine2'>Description</Typography>
              <Typography variant='s2'>{rule?.description}</Typography>
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-center w-50'>
            {rule?.policy_attachment?.includes('.pdf') ? (
              <iframe
                src={rule?.policy_attachment?.includes(`https://`) ? rule?.policy_attachment : License}
                style={{ height: '100%', width: '100%' }}
                title='Doc'
              />
            ) : (
              <img src={rule?.policy_attachment ? rule.policy_attachment : License} alt='doc' className='image-show' />
            )}
          </div>
        </div>
      </SWrapper>
    </Modal>
  );
};

export default RuleDetails;
