import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  padding: 24px 0;
  min-height: 300px;
  position: relative;
`;

export const SItemRow = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${palette.gray50};

  & > div:first-child {
    width: 30%;
  }

  & > div:nth-child(2) {
    width: 70%;
  }
`;
