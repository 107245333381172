import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckRounded } from 'assets/icons/check-rounded.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon, InputWithText } from 'common/Input';
import { formatNumber, palette, VALUE_TYPES } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { deleteCargo, deleteQuoteCargo, deleteRecurringCargo } from 'Api/Shipment';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Tooltip from 'common/Tooltip';
import { SActions, SMergedInputs } from '../ShipmentCommodity.styles';

const FormRow = ({
  cargo,
  index,
  form,
  weightTypes,
  quantityTypes,
  measurementTypes,
  commodityTypes,
  onCommodityChange,
  stopId,
  quoteId,
  isRecurring,
  // onCreateSuccess,
  // onUpdateSuccess,
  onDeleteSuccess,
  readOnly,
  createMode,
}) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const { currency } = useSelector((state) => state.root);

  const { setValues, handleChange, touchedErrors } = form;

  // const onCreate = async (row) => {
  //   setLoading(true);
  //   try {
  //     const errors = await validateForm();
  //
  //     if (Object.keys(errors?.[index] || {}).length) {
  //       setTouched({ ...touched, [index]: true });
  //       return;
  //     }
  //
  //     const body = createCargoConverter(row);
  //     let createdCargo;
  //     if (isRecurring) {
  //       const { data } = await createRecurringCargo(stopId, body);
  //       createdCargo = data;
  //     } else {
  //       const { data } = await createCargo(stopId, body);
  //       createdCargo = data;
  //     }
  //     handleChange(`${index}`, { ...cargo, id: createdCargo.id, isNew: false });
  //     showToaster({ type: 'success', message: 'Cargo has been successfully created!' });
  //     onCreateSuccess(createdCargo);
  //   } catch (e) {
  //     showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  //
  // const onEdit = async (row) => {
  //   setLoading(true);
  //   try {
  //     const errors = await validateForm();
  //
  //     if (Object.keys(errors?.[index] || {}).length) {
  //       setTouched({ ...touched, [index]: true });
  //       return;
  //     }
  //
  //     const body = createCargoConverter(row);
  //     let updatedCargo;
  //     if (isRecurring) {
  //       const { data } = await updateRecurringCargo(row.id, body);
  //       updatedCargo = data;
  //     } else {
  //       const { data } = await updateCargo(row.id, body);
  //       updatedCargo = data;
  //     }
  //     showToaster({ type: 'success', message: 'Cargo has been successfully updated!' });
  //     handleChange(`${index}`, { ...cargo, id: updatedCargo.id, isNew: false });
  //     onUpdateSuccess(updatedCargo);
  //   } catch (e) {
  //     showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const onDelete = async (row) => {
    setLoading(true);
    try {
      if (isRecurring) {
        await deleteRecurringCargo(row.id);
      } else if (quoteId) {
        await deleteQuoteCargo(row.id);
      } else {
        await deleteCargo(row.id);
      }
      showToaster({ type: 'success', message: 'Cargo has been successfully deleted!' });
      onDeleteSuccess(row);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  // const handleSave = (row) => {
  //   if (row.isNew) {
  //     onCreate(row);
  //   } else {
  //     onEdit(row);
  //   }
  // };

  const onDeleteRow = async (row) => {
    if (stopId) {
      await onDelete(row);
    }

    setValues((prevState) => prevState.filter((item) => item.id !== row.id));
  };

  const onUnitChange = (field, value) => {
    setValues((prevState) => prevState.map((item) => ({ ...item, [field]: value })));
  };

  return (
    <tr className='body-row'>
      {!createMode && (
        <td>
          <Typography variant='b2' className='nowrap'>
            {cargo.shipment_stop?.stop_point?.location_name || '-'}
          </Typography>
        </td>
      )}
      {!createMode && (
        <td>
          <Typography variant='b2' className='nowrap'>
            {cargo.delivery_stop?.stop_point?.location_name || '-'}
          </Typography>
        </td>
      )}
      <td>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {cargo.commodity?.title || '-'}
          </Typography>
        ) : (
          <Autocomplete
            width='220px'
            size='small'
            name={`${index}.commodity`}
            labelKey='title'
            options={commodityTypes}
            value={cargo.commodity}
            onChange={(e, value) => onCommodityChange(index, value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors[index]?.commodity}
          />
        )}
      </td>
      <td>
        {readOnly ? (
          <Typography variant='b2'>{cargo.description || '-'}</Typography>
        ) : (
          <Input
            size='small'
            name={`${index}.description`}
            value={cargo.description}
            onChange={handleChange}
            error={touchedErrors[index]?.description}
          />
        )}
      </td>
      <td>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {cargo.expected_quantity || '-'} {!!cargo.expected_quantity && cargo.expected_quantity_type?.name}
          </Typography>
        ) : (
          <SMergedInputs>
            <Input
              width='100px'
              type='number'
              size='small'
              name={`${index}.expected_quantity`}
              value={cargo.expected_quantity}
              onChange={handleChange}
              error={touchedErrors[index]?.expected_quantity}
              onKeyDown={(e) => {
                blockNotNumberChars(e);
                blockNonPositiveNumbers(e);
              }}
            />
            <div>
              <Autocomplete
                width='100px'
                size='small'
                name={`${index}.expected_quantity_type`}
                options={quantityTypes}
                value={cargo.expected_quantity_type}
                onChange={(e, value) => onUnitChange(`expected_quantity_type`, value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors[index]?.expected_quantity_type}
              />
            </div>
          </SMergedInputs>
        )}
      </td>
      {!createMode && (
        <td>
          {!readOnly && !!cargo.shipment_stop?.departure_date ? (
            <SMergedInputs>
              <Input
                width='100px'
                type='number'
                size='small'
                name={`${index}.reported_quantity`}
                value={cargo.reported_quantity}
                onChange={handleChange}
                error={touchedErrors.reported_quantity}
                onKeyDown={(e) => {
                  blockNotNumberChars(e);
                  blockNonPositiveNumbers(e);
                }}
              />
              <div>
                <Autocomplete
                  width='100px'
                  size='small'
                  name='reported_quantity_type'
                  options={quantityTypes}
                  value={cargo.reported_quantity_type}
                  onChange={(e, value) => handleChange(`${index}.reported_quantity_type`, value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  error={touchedErrors.reported_quantity_type}
                />
              </div>
            </SMergedInputs>
          ) : (
            <div className='d-flex align-items-center gap-1'>
              <Typography variant='b2' className='nowrap'>
                {cargo.reported_quantity || '-'} {!!cargo.reported_quantity && cargo.reported_quantity_type?.name}
              </Typography>
              {!!cargo.reported_quantity && !!cargo.expected_quantity && (
                <Tooltip
                  title={Number(cargo.reported_quantity) === Number(cargo.expected_quantity) ? 'MATCHED' : 'MISMATCH'}
                >
                  {Number(cargo.reported_quantity) === Number(cargo.expected_quantity) ? (
                    <CheckRounded fill={palette.green500} />
                  ) : (
                    <WarningIcon fill={palette.red500} />
                  )}
                </Tooltip>
              )}
            </div>
          )}
        </td>
      )}
      <td>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {cargo.expected_weight || '-'}{' '}
            {!!cargo.expected_weight &&
              !!cargo.expected_weight_unit?.name &&
              !!cargo.expected_weight_type?.unit &&
              `${cargo.expected_weight_unit?.name}/${cargo.expected_weight_type?.unit}`}
          </Typography>
        ) : (
          <SMergedInputs>
            <Input
              width='90px'
              type='number'
              size='small'
              name={`${index}.expected_weight`}
              value={cargo.expected_weight}
              onChange={handleChange}
              error={touchedErrors[index]?.expected_weight}
              onKeyDown={blockNonPositiveNumbers}
            />
            <div className='middle-input'>
              <Autocomplete
                width='100px'
                size='small'
                name={`${index}.expected_weight_unit`}
                placeholder=''
                options={VALUE_TYPES}
                value={cargo.expected_weight_unit}
                onChange={(e, value) => handleChange(`${index}.expected_weight_unit`, value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors[index]?.expected_weight_unit}
              />
            </div>
            <div>
              <Autocomplete
                width='80px'
                size='small'
                name={`${index}.expected_weight_type`}
                labelKey='unit'
                placeholder=''
                options={weightTypes}
                value={cargo.expected_weight_type}
                onChange={(e, value) => onUnitChange(`expected_weight_type`, value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors[index]?.expected_weight_type}
              />
            </div>
          </SMergedInputs>
        )}
      </td>
      {!createMode && (
        <td>
          {!readOnly && !!cargo.shipment_stop?.departure_date ? (
            <SMergedInputs>
              <Input
                width='90px'
                type='number'
                size='small'
                name={`${index}.reported_weight`}
                value={cargo.reported_weight}
                onChange={handleChange}
                error={touchedErrors.reported_weight}
                onKeyDown={blockNonPositiveNumbers}
              />
              <div>
                <Autocomplete
                  width='80px'
                  size='small'
                  name='reported_weight_type'
                  labelKey='unit'
                  placeholder=''
                  options={weightTypes}
                  value={cargo.reported_weight_type}
                  onChange={(e, value) => handleChange(`${index}.reported_weight_type`, value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  error={touchedErrors.reported_weight_type}
                />
              </div>
            </SMergedInputs>
          ) : (
            <div className='d-flex align-items-center gap-1'>
              <Typography variant='b2' className='nowrap'>
                {cargo.reported_weight || '-'}{' '}
                {!!cargo.reported_weight &&
                  `${cargo.reported_weight_unit?.name ? `${cargo.reported_weight_unit?.name}/` : ''}${
                    cargo.reported_weight_type?.unit || ''
                  }`}
              </Typography>
              {!!cargo.reported_weight && !!cargo.expected_weight && (
                <Tooltip
                  title={Number(cargo.reported_weight) === Number(cargo.expected_weight) ? 'MATCHED' : 'MISMATCH'}
                >
                  {Number(cargo.reported_weight) === Number(cargo.expected_weight) ? (
                    <CheckRounded fill={palette.green500} />
                  ) : (
                    <WarningIcon fill={palette.red500} />
                  )}
                </Tooltip>
              )}
            </div>
          )}
        </td>
      )}
      <td>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {cargo.value ? `${currency}${formatNumber(cargo.value)}` : '-'} {!!cargo.value && cargo.value_type?.name}
          </Typography>
        ) : (
          <SMergedInputs>
            <InputWithIcon
              wrapperClassName='merged-input'
              width='100px'
              type='number'
              size='small'
              name={`${index}.value`}
              value={cargo.value}
              onChange={handleChange}
              error={touchedErrors[index]?.value}
              onKeyDown={blockNonPositiveNumbers}
            />
            <div>
              <Autocomplete
                width='100px'
                size='small'
                name={`${index}.value_type`}
                placeholder=''
                options={VALUE_TYPES}
                value={cargo.value_type}
                onChange={(e, value) => onUnitChange(`value_type`, value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors[index]?.value_type}
              />
            </div>
          </SMergedInputs>
        )}
      </td>
      {!createMode && (
        <td>
          {cargo.reported_value ? (
            <div className='d-flex align-items-center gap-1'>
              <Typography variant='b2' className='nowrap'>
                {cargo.reported_value ? `$${formatNumber(cargo.reported_value)}` : '-'}{' '}
                {!!cargo.reported_value && cargo.reported_value_type?.name}
              </Typography>
              {!!cargo.reported_value && !!cargo.value && (
                <Tooltip title={Number(cargo.reported_value) === Number(cargo.value) ? 'MATCHED' : 'MISMATCH'}>
                  {Number(cargo.reported_value) === Number(cargo.value) ? (
                    <CheckRounded fill={palette.green500} />
                  ) : (
                    <WarningIcon fill={palette.red500} />
                  )}
                </Tooltip>
              )}
            </div>
          ) : (
            <Typography variant='b2' className='nowrap'>
              -
            </Typography>
          )}
        </td>
      )}
      <td>
        {readOnly ? (
          <div>
            {cargo.expected_dimensions?.l && cargo.expected_dimensions?.w && cargo.expected_dimensions?.h ? (
              <>
                <Typography variant='b2' className='nowrap'>
                  {cargo.expected_dimensions?.l}L x{' '}
                </Typography>
                <Typography variant='b2' className='nowrap'>
                  {cargo.expected_dimensions?.w}W x{' '}
                </Typography>
                <Typography variant='b2' className='nowrap'>
                  {cargo.expected_dimensions?.h}H
                </Typography>
              </>
            ) : (
              <Typography variant='b2' className='nowrap'>
                -
              </Typography>
            )}
          </div>
        ) : (
          <InputWithText
            width='60px'
            type='number'
            size='small'
            text='L'
            name={`${index}.expected_dimensions.l`}
            value={cargo.expected_dimensions.l}
            onChange={handleChange}
            error={touchedErrors[index]?.expected_dimensions?.l}
            onKeyDown={blockNonPositiveNumbers}
          />
        )}
      </td>
      <td>
        {!readOnly && (
          <InputWithText
            width='60px'
            type='number'
            size='small'
            text='W'
            name={`${index}.expected_dimensions.w`}
            value={cargo.expected_dimensions.w}
            onChange={handleChange}
            error={touchedErrors[index]?.expected_dimensions?.w}
            onKeyDown={blockNonPositiveNumbers}
          />
        )}
      </td>
      <td>
        {!readOnly && (
          <InputWithText
            width='60px'
            type='number'
            size='small'
            text='H'
            name={`${index}.expected_dimensions.h`}
            value={cargo.expected_dimensions.h}
            onChange={handleChange}
            error={touchedErrors[index]?.expected_dimensions?.h}
            onKeyDown={blockNonPositiveNumbers}
          />
        )}
      </td>
      <td>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {cargo.expected_dimension_unit?.name}
          </Typography>
        ) : (
          <Autocomplete
            width='120px'
            size='small'
            name={`${index}.expected_dimension_unit`}
            options={measurementTypes}
            value={cargo.expected_dimension_unit}
            onChange={(e, value) => onUnitChange(`expected_dimension_unit`, value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors[index]?.expected_dimension_unit}
          />
        )}
      </td>
      <td>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {cargo.expected_stackable ? 'Yes' : 'No'}
          </Typography>
        ) : (
          <div className='d-flex justify-content-center'>
            <CustomCheckbox
              checked={!!cargo.expected_stackable}
              onChange={(checked) => handleChange(`${index}.expected_stackable`, checked)}
            />
          </div>
        )}
      </td>
      <td>
        {readOnly ? (
          <Typography variant='b2' className='nowrap'>
            {cargo.sku_barcode || '-'}
          </Typography>
        ) : (
          <Input
            width='120px'
            size='small'
            name={`${index}.sku_barcode`}
            value={cargo.sku_barcode}
            onChange={handleChange}
            error={touchedErrors[index]?.sku_barcode}
          />
        )}
      </td>
      {!createMode && (
        <td>
          <Typography variant='b2' className='nowrap'>
            {cargo.unknown ? 'Yes' : 'No'}
          </Typography>
        </td>
      )}
      <td>
        {!readOnly && (
          <SActions>
            {loading ? (
              <span className='loading-wrapper'>
                <CircularProgress size={16} />
              </span>
            ) : (
              <>
                {/* {!createMode && ( */}
                {/*  <span className='action-wrapper' onClick={() => handleSave(cargo)}> */}
                {/*    <CheckIcon width={11} height={11} /> */}
                {/*  </span> */}
                {/* )} */}
                {index !== 0 && (
                  <span className='action-wrapper' onClick={() => onDeleteRow(cargo)}>
                    <DeleteIcon width={11} height={11} fill={palette.red500} />
                  </span>
                )}
              </>
            )}
          </SActions>
        )}
      </td>
    </tr>
  );
};

export default FormRow;
