import React from 'react';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers';
import InputLabel from 'common/InputLabel';
import ErrorMessage from 'common/ErrorMessage';
import useDateFormat from 'hooks/useDateFormat';
import { SPickerWrapper } from '../Pickers.styles';

const TimePicker = ({ label, error, required, labelProps, width, borderRadius, onBlur, size, ...props }) => {
  const { pickerTimeFormat } = useDateFormat();

  return (
    <SPickerWrapper $width={width} $borderRadius={borderRadius} $size={size}>
      {!!label && (
        <InputLabel required={required} {...labelProps}>
          {label}
        </InputLabel>
      )}
      <MuiTimePicker
        format={pickerTimeFormat}
        ampm={pickerTimeFormat.includes('aa')}
        slotProps={{ textField: { onBlur } }}
        {...props}
      />
      <ErrorMessage error={error} />
    </SPickerWrapper>
  );
};

export default TimePicker;
