import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { WhiteAlert } from 'assets/icons';
import authHeader from 'services/auth-header';

const AlreadyReserved = ({ alreadyReserveid, AlreadyReserveMsgModalClose }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  async function reaserve() {
    const { data } = await axios.get(`${API_URL}/equipment-reserve/${alreadyReserveid}`, {
      headers: authHeader(),
    });
    return data;
  }

  const { data, isError, isLoading, error } = useQuery('hooked', reaserve);
  if (isLoading) {
    return (
      <span className='text-style' style={{ color: 'white' }}>
        Loading...
      </span>
    );
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }
  return (
    <div className='d-flex'>
      <div className='ms-3'>
        <div className='d-flex main-wrapper-eq-reserved mt-3 align-item-center'>
          <span className='me-2'>{WhiteAlert}</span>
          <div>
            {/* <p className='eq-reserved ms-2 mb-0 '>Equipment Reserved</p> */}
            {data && <p className='mt-0 eq-reserved-para mb-0'> {data?.message}</p>}
          </div>
        </div>
      </div>
      <div>
        <button className='eq-reserved-okay-msg mt-4 ms-2 ' onClick={AlreadyReserveMsgModalClose}>
          Okay
        </button>
      </div>
    </div>
  );
};

export default AlreadyReserved;
