import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Chip from 'common/Chip';
import Modal from 'common/Modal';
import Input from 'common/Input';
import Uploader from 'common/Uploader';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import { STATUS_TAB_ADD_MODAL_SHIPMENT } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers } from 'utils/helpers';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { createPaperLog, getShipmentListForDriver } from 'Api/HOS';
import { validationSchema } from './validationSchema';
import { getStartAndEndDate, initialValues } from './UploadPaperLog.data';

const UploadPaperLog = ({ open, onClose, driverId, onSuccess, vehicles, trailers }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [shipmentsList, setShipmentsList] = useState([]);
  const [cropModalShow, setCropModalShow] = useState(false);
  const [cropper, setCropper] = useState();
  const [cropperImage, setCropperImage] = useState();

  const onSubmit = async (values) => {
    try {
      const { start_date, end_date } = getStartAndEndDate(values.date);

      setLoading(true);
      const formData = new FormData();
      formData.append('driver_id', driverId);
      formData.append('start_time', start_date);
      formData.append('end_time', end_date);
      formData.append('distance_travelled', values.distance_travelled || 0);
      formData.append('paper_log_attach[]', values.paper_log_attach);

      values.shipments.forEach((shipment) => {
        formData.append('shipment_ids[]', shipment.shipment_id);
      });

      values.vehicles.forEach((vehicle) => {
        formData.append('vehicle_equipment_ids[]', vehicle.equipment_id);
      });

      values.trailers.forEach((trailer) => {
        formData.append('trailer_equipment_ids[]', trailer.equipment_id);
      });

      await createPaperLog(formData);
      showToaster({ type: 'success', message: 'Paper Log has been successfully uploaded!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors, handleBlur } = useForm({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const filteredShipmentList = useMemo(() => {
    if (!values.date) {
      return [];
    }
    return shipmentsList.filter((shipment) => {
      const firstStopDate = `${shipment?.shipment_stops?.[0]?.scheduled_date}`;
      const lastStopDate = `${
        shipment?.shipment_stops?.[Number(shipment?.shipment_stops?.length) - 1]?.scheduled_date
      }`;

      const start = moment(firstStopDate, 'YYYY-MM-DD');
      const end = moment(lastStopDate, 'YYYY-MM-DD');

      start.startOf('day');
      end.startOf('day');

      const dateToCheck = moment(values.date);

      return dateToCheck.isBetween(start, end, null, '[]');
    });
  }, [values.date, shipmentsList]);

  const getShipments = async () => {
    try {
      const params = {
        page: 1,
        itemsPerPage: 10000,
        'filters[driver_id][]': driverId,
        'filters[status]': STATUS_TAB_ADD_MODAL_SHIPMENT.map((el) => el.id),
      };
      const { data } = await getShipmentListForDriver(params);
      setShipmentsList(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        handleChange('paper_log_attach', blob);
        setCropModalShow(false);
      });
    }
  };

  const imageSelected = (file) => {
    setCropModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setCropperImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    getShipments();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Upload Paper Log'
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Upload',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-3'>
        <div>
          <DatePicker
            required
            label='Date'
            name='date'
            value={values.date}
            onChange={(val) => handleChange('date', val)}
            onBlur={handleBlur}
            maxDate={new Date()}
            disableFuture
            error={touchedErrors.date}
          />
        </div>
        <div className='d-flex flex-column'>
          <div>
            <Autocomplete
              multiple
              name='vehicles'
              label='Vehicle'
              options={vehicles}
              value={values.vehicles}
              onChange={(e, val) => handleChange('vehicles', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
              disableClearable={false}
              renderTags={() => null}
            />
          </div>
          <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', marginTop: '8px' }}>
            {values.vehicles.map((item) => (
              <Chip
                padding='4px 8px 4px 12px'
                label={item ? `${item.equipment_id} ${item.equipment_type.title}` : ''}
                labelColor={palette.indigo500}
                bgColor={palette.indigo0}
                deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                onDelete={() => {
                  setValues((prevState) => ({
                    ...prevState,
                    vehicles: prevState.vehicles.filter((i) => i.id !== item.id),
                  }));
                }}
              />
            ))}
          </div>
        </div>
        <div className='d-flex flex-column'>
          <div>
            <Autocomplete
              multiple
              name='trailers'
              label='Trailer'
              options={trailers}
              value={values.trailers}
              onChange={(e, val) => handleChange('trailers', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
              disableClearable={false}
              renderTags={() => null}
            />
          </div>
          <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', marginTop: '8px' }}>
            {values.trailers.map((item) => (
              <Chip
                padding='4px 8px 4px 12px'
                label={item ? `${item.equipment_id} ${item.equipment_type.title}` : ''}
                labelColor={palette.indigo500}
                bgColor={palette.indigo0}
                deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                onDelete={() => {
                  setValues((prevState) => ({
                    ...prevState,
                    trailers: prevState.trailers.filter((i) => i.id !== item.id),
                  }));
                }}
              />
            ))}
          </div>
        </div>
        <div>
          <Input
            type='number'
            placeholder='0'
            label='Total Miles'
            name='distance_travelled'
            value={values.distance_travelled}
            onChange={handleChange}
            onKeyDown={(e) => blockNonPositiveNumbers(e)}
          />
        </div>
        <div className='d-flex flex-column'>
          <div>
            <Autocomplete
              multiple
              name='shipments'
              label='Shipment'
              options={filteredShipmentList}
              value={values.shipments}
              getOptionLabel={(option) => (option ? `Shipment ${option.shipment_id}` : '')}
              onChange={(e, val) => handleChange('shipments', val)}
              isOptionEqualToValue={(option, value) => option.shipment_id === value.shipment_id}
              disableClearable={false}
              renderTags={() => null}
            />
          </div>
          <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', marginTop: '8px' }}>
            {values.shipments.map((item) => (
              <Chip
                padding='4px 8px 4px 12px'
                label={`Shipment ${item.shipment_id}`}
                labelColor={palette.indigo500}
                bgColor={palette.indigo0}
                deleteIcon={<DeleteIcon fill={palette.red500} style={{ marginLeft: '8px' }} />}
                onDelete={() => {
                  setValues((prevState) => ({
                    ...prevState,
                    shipments: prevState.shipments.filter((i) => i.shipment_id !== item.shipment_id),
                  }));
                }}
              />
            ))}
          </div>
        </div>
        <div>
          <Uploader
            type={1}
            required
            label='Logbook'
            document={values.paper_log_attach}
            uploaderLabel='Upload Logbook'
            uploadedLabel='Logbook Uploaded'
            accept={['image/jpeg', 'image/png']}
            onDrop={(files) => imageSelected(files[0])}
            onRemove={() => handleChange('paper_log_attach', null)}
            error={touchedErrors.paper_log_attach}
          />
        </div>
      </div>
      {cropModalShow && (
        <ImageCropModal
          show={cropModalShow}
          onHide={() => setCropModalShow(false)}
          image={cropperImage}
          getCropData={getCropData}
          setCropper={setCropper}
        />
      )}
    </Modal>
  );
};

export default UploadPaperLog;
