import moment from 'moment';

export const basicInfoConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    full_name: data.fullName,
    social_security_number: data.socialNumber,
    phone_no: data.phoneNumber,
    email: data.email,
    date_of_birth: moment(data.birthDate).format('MM/DD/YYYY'),
    emergency_contact_first_name: data.emergency_contact_first_name || '',
    emergency_contact_last_name: data.emergency_contact_last_name || '',
    emergency_contact_number: data.emergency_contact_number || '',
    is_send_email: data.is_send_email,
    is_send_text: data.is_send_text,
    addresses: data.addresses
      .filter((address) => !address.noPreviousAddress)
      .map((address) => ({
        start_date: moment(data.start_date).format('YYYY-MM-DD'),
        end_date: moment(data.end_date).format('YYYY-MM-DD'),
        current: address.current,
        address1: address.address1,
        address2: address.address2,
        country_id: address.country.id,
        state_id: address.state.id,
        city_id: address.city?.id || null,
        zipcode: address.zipcode,
      })),
  };
};

export const positionConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    job_application_id: data.jobId,
    applicant_type_id: data.applicantType?.id,
    job_position_type_id: data.position?.id,
    equipment_types_id: data.applicantType?.id === 1 ? data.equipment.map((el) => el.id) : undefined,
    experience_year: data.years ? Number(data.years) : 0,
    experience_month: data.months ? Number(data.months) : 0,
  };
};

export const applicantInfoConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    job_application_id: data.jobId,
    applicant_status: data.applicantStatus,
    company_name: data.applicantStatus === 'company' ? data.companyName : data.fullName,
    ein: data.applicantStatus === 'company' ? data.ein : data.socialNumber,
    address1: data.address1,
    address2: data.address2,
    country_id: data.country?.id || null,
    state_id: data.state?.id || null,
    city_id: data.city?.id || null,
    zipcode: data.zipcode,
    // can_travel_canada: Number(data.canTravelCanada),
    // can_travel_mexico: Number(data.canTravelMexico),
    // can_travel_us: Number(data.canTravelUS),
    // worked_before_company: Number(data.workedBefore),
    // currently_work_with: Number(data.currentlyEmployed),
    // currently_work_company: data.currentlyEmployed ? data.currentCompany : undefined,
    // read_write_speak_english: Number(data.haveEnglishSkills),
  };
};

export const driverLicenseConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    job_application_id: data.jobId,
    driving_licence_number: data.licenseNumber,
    driving_licence_attachment: data.licenseUrl,
    driving_licence_attachment_back: data.licenseUrlBack,
    licence_country_id: data.country?.id || null,
    country_id: data.country?.id || null,
    licence_state_id: data.state?.id || null,
    state_id: data.state?.id || null,
    licence_type_id: data.licenseType.id,
    driving_licence_expired: moment(data.expirationDate).format('MM/DD/YYYY'),
  };
};

export const staffLicenseConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    job_application_id: data.jobId,
    photo_id_number: data.photo_id_number,
    photo_id_type: data.photo_id_type.id,
    photo_id_expiration: moment(data.photo_id_expiration).format('MM/DD/YYYY'),
    photo_id_attachment: data.photo_id_attachment,
  };
};

export const medicalCardConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    job_application_id: data.jobId,
    upload_examiner_card: data.medicalCardUrl,
    expiry_date: moment(data.expirationDate).format('MM/DD/YYYY'),
  };
};

export const previousEmploymentConvertor = (data, jobId) => {
  if (!data) {
    return null;
  }

  const newForms = [];
  const updatedForms = [];
  data.forEach((item) => {
    (item.id ? updatedForms : newForms).push(item);
  });

  const formData = new FormData();

  formData.append('job_application_id', jobId);
  newForms.forEach((item, index) => {
    if (item.id) {
      return;
    }

    formData.append(`employer_name[${index}]`, item.employerName);
    formData.append(`email[${index}]`, item.email);
    formData.append(`phone_no[${index}]`, item.phoneNumber);
    formData.append(`fax[${index}]`, item.fax);
    formData.append(`present[${index}]`, Number(item.present));
    formData.append(`start_date[${index}]`, moment(item.startDate).format('MM/DD/YYYY'));
    formData.append(
      `end_date[${index}]`,
      item.endDate && !item.present ? moment(item.endDate).format('MM/DD/YYYY') : ''
    );
    formData.append(`address[${index}]`, item.address || '');
    formData.append(`country_id[${index}]`, item.country?.id || '');
    formData.append(`state_id[${index}]`, item.state?.id || '');
    formData.append(`city_id[${index}]`, item.city?.id || '');
    formData.append(`zipcode[${index}]`, item.zipcode);
    formData.append(`position[${index}]`, item.position);
    formData.append(`reason[${index}]`, item.reason);
    formData.append(`terminated[${index}]`, item.terminated ? 1 : 0);
    formData.append(`current_employer[${index}]`, item.isCurrentEmployer ? 1 : 0);
    formData.append(`can_contact[${index}]`, item.canContact ? 1 : 0);
    formData.append(`operated_commercial_vehicle[${index}]`, item.operatedCommercialVehicle ? 1 : 0);
    formData.append(`subject_to_safety_regulations[${index}]`, item.subjectToSafetyRegulations ? 1 : 0);
    formData.append(`perform_safety_functions[${index}]`, item.performSafetyFunctions ? 1 : 0);
    formData.append(`areas_driven[${index}]`, item.areasDriven);
    formData.append(`miles_driven[${index}]`, item.milesDriven);
    formData.append(`pay_range[${index}]`, item.payRange);
    formData.append(`truck_name[${index}]`, item.truckName);
    formData.append(`trailer_name_size[${index}]`, item.trailerNameSize);
  });

  const updated = updatedForms.reduce(
    (acc, item) => {
      acc.id.push(item.id);
      acc.employer_name.push(item.employerName);
      acc.email.push(item.email);
      acc.phone_no.push(item.phoneNumber);
      acc.fax.push(item.fax);
      acc.present.push(Number(item.present));
      acc.start_date.push(moment(item.startDate).format('MM/DD/YYYY'));
      acc.end_date.push(item.endDate && !item.present ? moment(item.endDate).format('MM/DD/YYYY') : '');
      acc.address.push(item.address);
      acc.country_id.push(item.country?.id);
      acc.state_id.push(item.state?.id);
      acc.city_id.push(item.city?.id);
      acc.zipcode.push(item.zipcode);
      acc.position.push(item.position);
      acc.reason.push(item.reason);
      acc.terminated.push(item.terminated);
      acc.current_employer.push(item.isCurrentEmployer);
      acc.can_contact.push(item.canContact);
      acc.operated_commercial_vehicle.push(item.operatedCommercialVehicle);
      acc.subject_to_safety_regulations.push(item.subjectToSafetyRegulations);
      acc.perform_safety_functions.push(item.performSafetyFunctions);
      acc.areas_driven.push(item.areasDriven);
      acc.miles_driven.push(item.milesDriven);
      acc.pay_range.push(item.payRange);
      acc.truck_name.push(item.truckName);
      acc.trailer_name_size.push(item.trailerNameSize);

      return acc;
    },
    {
      job_application_id: jobId,
      id: [],
      employer_name: [],
      email: [],
      phone_no: [],
      fax: [],
      present: [],
      start_date: [],
      end_date: [],
      address: [],
      country_id: [],
      state_id: [],
      city_id: [],
      zipcode: [],
      position: [],
      reason: [],
      terminated: [],
      current_employer: [],
      can_contact: [],
      operated_commercial_vehicle: [],
      subject_to_safety_regulations: [],
      perform_safety_functions: [],
      areas_driven: [],
      miles_driven: [],
      pay_range: [],
      truck_name: [],
      trailer_name_size: [],
    }
  );

  return { formData, updated, shouldCreate: !!newForms.length };
};

export const createMilitaryConvertor = (data) => {
  if (!data) {
    return null;
  }

  const formData = new FormData();
  formData.append('job_application_id', data.jobId);
  formData.append('service_branch', data.service_branch);
  formData.append('service_from', moment(data.service_from).format('YYYY-MM-DD'));
  formData.append('service_to', moment(data.service_to).format('YYYY-MM-DD'));
  formData.append('discharge_rank', data.discharge_rank);
  formData.append('discharge_type', data.discharge_type);
  formData.append('mos', data.mos);

  return formData;
};

export const updateMilitaryConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    job_application_id: data.jobId,
    service_branch: data.service_branch,
    service_from: moment(data.service_from).format('YYYY-MM-DD'),
    service_to: moment(data.service_to).format('YYYY-MM-DD'),
    discharge_rank: data.discharge_rank,
    discharge_type: data.discharge_type,
    mos: data.mos,
  };
};

export const createEducationConvertor = (data) => {
  if (!data) {
    return null;
  }

  const formData = new FormData();
  formData.append('job_application_id', data.jobId);
  formData.append('school_name', data.school_name);
  formData.append('start_date', moment(data.start_date).format('YYYY-MM-DD'));
  formData.append('end_date', moment(data.end_date).format('YYYY-MM-DD'));
  formData.append('address1', data.address);
  formData.append('country_id', data.country?.id || '');
  formData.append('state_id', data.state?.id || '');
  formData.append('city_id', data.city?.id || '');
  formData.append('zipcode', data.zipcode);
  formData.append('subject', data.subject);
  formData.append('phone_no', data.phone_no);
  formData.append('is_graduated', Number(!data.ongoing));
  if (data.graduation_date && !data.ongoing) {
    formData.append('graduation_date', moment(data.graduation_date).format('YYYY-MM-DD'));
  }
  formData.append(`subject_to_safety_regulations`, data.subject_to_safety_regulations ? 1 : 0);
  formData.append(`perform_safety_functions`, data.perform_safety_functions ? 1 : 0);
  formData.append('gpa', data.gpa);
  formData.append('instruction_hrs', data.instruction_hrs);
  formData.append(`crossed_border`, data.crossed_border ? 1 : 0);
  formData.append(`log_books`, data.log_books ? 1 : 0);
  formData.append(`federal_motor_regulations`, data.federal_motor_regulations ? 1 : 0);
  formData.append(`hazardous_materials`, data.hazardous_materials ? 1 : 0);

  return formData;
};

export const updateEducationConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    job_application_id: data.jobId,
    school_name: data.school_name,
    start_date: moment(data.start_date).format('YYYY-MM-DD'),
    end_date: moment(data.end_date).format('YYYY-MM-DD'),
    address1: data.address,
    country_id: data.country?.id || null,
    state_id: data.state?.id || null,
    city_id: data.city?.id || null,
    zipcode: data.zipcode,
    subject: data.subject,
    phone_no: data.phone_no,
    is_graduated: Number(!data.ongoing),
    graduation_date: !data.ongoing && data.graduation_date ? moment(data.graduation_date).format('YYYY-MM-DD') : null,
    subject_to_safety_regulations: Number(data.subject_to_safety_regulations),
    perform_safety_functions: Number(data.perform_safety_functions),
    gpa: data.gpa,
    instruction_hrs: data.instruction_hrs,
    crossed_border: Number(data.crossed_border),
    log_books: Number(data.log_books),
    federal_motor_regulations: Number(data.federal_motor_regulations),
    hazardous_materials: Number(data.hazardous_materials),
  };
};

export const createTrafficAccidentConvertor = (data) => {
  if (!data) {
    return null;
  }

  const formData = new FormData();
  formData.append('job_application_id', data.jobId);

  formData.append('involved_in_accidents', data.involved_in_accidents ? 1 : 0);

  if (data.involved_in_accidents) {
    data.accidents.forEach((item, index) => {
      formData.append(`accidents[${index}][accident_nature]`, item.accident_nature || '');
      formData.append(`accidents[${index}][date]`, moment(item.date).format('YYYY-MM-DD'));
      formData.append(`accidents[${index}][fatalities]`, item.fatalities ? 1 : 0);
      if (item.fatalities) {
        formData.append(`accidents[${index}][fatalities_count]`, item.fatalities_count || '');
      }
      formData.append(`accidents[${index}][injuries]`, item.injuries ? 1 : 0);
      if (item.injuries) {
        formData.append(`accidents[${index}][injuries_count]`, item.injuries_count || '');
      }
    });
  }

  formData.append('involved_in_violations', data.involved_in_violations);

  if (data.involved_in_violations) {
    data.traffics.forEach((item, index) => {
      formData.append(`traffics[${index}][address1]`, item.address || '');
      formData.append(`traffics[${index}][country_id]`, item.country?.id || '');
      formData.append(`traffics[${index}][state_id]`, item.state?.id || '');
      formData.append(`traffics[${index}][city_id]`, item.city?.id || '');
      formData.append(`traffics[${index}][zipcode]`, item.zipcode);
      formData.append(`traffics[${index}][date]`, moment(item.date).format('YYYY-MM-DD'));
      formData.append(`traffics[${index}][charge]`, item.charge);
      formData.append(`traffics[${index}][penalty]`, item.penalty);
    });
  }

  formData.append('denied_license', data.denied_license ? 1 : 0);
  if (data.denied_license) {
    formData.append('denied_statement', data.denied_statement || '');
  }

  formData.append('suspended_license', data.suspended_license ? 1 : 0);
  if (data.suspended_license) {
    formData.append('suspended_statement', data.suspended_statement || '');
  }

  return formData;
};

export const updateTrafficAccidentConvertor = (data) => {
  if (!data) {
    return null;
  }

  return {
    job_application_id: data.jobId,
    involved_in_accidents: Number(data.involved_in_accidents),
    accidents: data.involved_in_accidents
      ? data.accidents.map((item) => ({
          accident_nature: item.accident_nature || '',
          date: moment(item.date).format('YYYY-MM-DD'),
          fatalities: Number(item.fatalities),
          fatalities_count: item.fatalities ? item.fatalities_count : 0,
          injuries: Number(item.injuries),
          injuries_count: item.injuries ? item.injuries_count : 0,
        }))
      : [],
    involved_in_violations: Number(data.involved_in_violations),
    traffics: data.involved_in_violations
      ? data.traffics.map((item) => ({
          address1: item.address || null,
          country_id: item.country?.id || null,
          state_id: item.state?.id || null,
          city_id: item.city?.id || null,
          zipcode: item.zipcode,
          date: moment(item.date).format('YYYY-MM-DD'),
          charge: item.charge,
          penalty: item.penalty,
        }))
      : [],
    denied_license: Number(data.denied_license),
    denied_statement: data.denied_license ? data.denied_statement : null,
    suspended_license: Number(data.suspended_license),
    suspended_statement: data.suspended_license ? data.suspended_statement : null,
  };
};

export const otherConvertor = (data) => {
  if (!data) {
    return null;
  }

  const formData = new FormData();
  formData.append('job_application_id', data.jobId);

  data.questionIds.forEach((item, index) => {
    if (data.questions?.[index]) {
      formData.append(`question_id[${index}]`, item);
      formData.append(`answer[${index}]`, data.questions[index]);
    }
  });

  return formData;
};
