import styled from 'styled-components';

export const SWrapper = styled.div`
  position: relative;

  .MuiBackdrop-root {
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .date-range-wrapper {
    justify-content: flex-start;
  }
`;

export const SDateRangeWrapper = styled.div``;

export const SButton = styled.button`
  width: ${({ $width }) => $width || '148px'};
  height: ${({ $size }) => ($size === 'small' ? '25px' : '32px')};
`;
