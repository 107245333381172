import styled from 'styled-components';

export const SPageWrapper = styled.div`
  height: 100%;
  font-family: 'Inter', sans-serif;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const SFiltersWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  .delete-icon {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }
`;
