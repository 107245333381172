import get from 'lodash/get';

export const compensationTypes = ['loaded_miles', 'empty_miles', 'per_hour', 'percentage', 'flat_rate'];

export const getTaxAmount = ({ tax, deductions, additions, gross }) => {
  const deductionsToApplyAfterAmount = (deductions || []).reduce((amount, deduction) => {
    const { payroll_tax } = deduction.deduction_type || {};
    if (deduction.apply && payroll_tax?.some((i) => i.id === tax.tax_type?.id)) {
      amount += Number(deduction.amount) || 0;
    }
    return amount;
  }, 0);

  const taxExemptAdditionsAmount = (additions || []).reduce((amount, addition) => {
    const { tax_exempt } = addition.addition_type || {};
    if (addition.apply && !!tax_exempt) {
      amount += Number(addition.amount) || 0;
    }
    return amount;
  }, 0);

  return (((gross || 0) - deductionsToApplyAfterAmount - taxExemptAdditionsAmount) * (Number(tax.percent) || 0)) / 100;
};

export const calcAdditions = (data) => {
  const { additions, absences, detention, overtime, oneTimeAdditions } = data || {};
  const allAdditions = [...additions, ...absences, ...detention, ...overtime, ...oneTimeAdditions];

  const totalAmount = allAdditions.reduce((acc, curr) => {
    if (curr.apply) {
      acc += Number(curr.amount) || 0;
    }
    return acc;
  }, 0);

  return Number(totalAmount) || 0;
};

export const calcDeductions = ({ deductions, additions, transactions, taxes, benefits, gross }) => {
  const taxesAmount = taxes.reduce((acc, curr) => {
    if (curr.apply) {
      acc += getTaxAmount({ tax: curr, deductions, additions, gross });
    }
    return acc;
  }, 0);

  const totalBenefitsAndDeductions = [...deductions, ...benefits, ...transactions].reduce((acc, curr) => {
    if (curr.apply) {
      acc += Number(curr.amount) || 0;
    }
    return acc;
  }, 0);

  return taxesAmount + totalBenefitsAndDeductions;
};

export const calcGross = ({ settlement, shipments, commissions, timeTracking, totalAdditions }) => {
  const { pay_option, per_hr_option, each_booked_shipment, sales_commission_option } = get(
    settlement,
    'user.compensation_details',
    {}
  );

  const shipmentsAmount = shipments?.reduce((acc, curr) => {
    if (settlement.user_type === 1) {
      acc += Number(curr.amount) || 0;
    } else {
      acc += curr.tonu_date_time ? 0 : Number(curr.driver_amount) || 0;
    }
    return acc;
  }, 0);

  const hoursWorkedAmount = timeTracking?.reduce((acc, curr) => {
    acc += Number(curr.amount) || 0;
    return acc;
  }, 0);

  const commissionsAmount = commissions?.reduce((acc, curr) => {
    acc += Number(curr.pay_amount) || 0;
    return acc;
  }, 0);

  if (settlement?.user_type === 1) {
    return (
      (per_hr_option ? hoursWorkedAmount : 0) +
      (each_booked_shipment ? shipmentsAmount : 0) +
      (sales_commission_option ? commissionsAmount : 0) +
      totalAdditions
    );
  }

  return (pay_option === 'per_hour' ? hoursWorkedAmount : shipmentsAmount) + totalAdditions;
};

export const calcMiles = ({ shipments }) => {
  let live_loaded_miles = 0;
  let live_empty_miles = 0;

  (shipments || []).forEach((shipment) => {
    live_loaded_miles += Number(shipment.loaded_miles ?? 0);
    live_empty_miles += Number(shipment.empty_miles ?? 0);
  });

  return { live_loaded_miles, live_empty_miles };
};

export const calcTotalSettlement = ({ gross, totalDeductions }) => {
  return gross - totalDeductions;
};

export const calcAppliedAmount = (items) => {
  return items.reduce((acc, cur) => {
    if (cur.apply) {
      acc += Number(cur.amount);
    }

    return acc;
  }, 0);
};

export const calcAppliedTaxAmount = (taxes, deductions, additions, gross) => {
  return taxes.reduce((acc, cur) => {
    if (cur.apply) {
      acc += getTaxAmount({ tax: cur, deductions, additions, gross });
    }

    return acc;
  }, 0);
};

export const twoDecimals = (number) => {
  const splitNumber = number.toString().split('.');
  const rightSide = splitNumber[1]?.slice(0, 2);

  return `${splitNumber[0]}.${rightSide?.length === 1 ? `${rightSide}0` : rightSide || '00'}`;
};

export const calcTotalMiles = (shipments) => {
  const empty_miles = shipments.reduce((acc, cur) => {
    acc += Number(cur.empty_miles);

    return acc;
  }, 0);

  const loaded_miles = shipments.reduce((acc, cur) => {
    acc += Number(cur.loaded_miles);
    return acc;
  }, 0);

  return { empty_miles, loaded_miles };
};

export const isAllActionsDone = (data) => {
  const {
    additions,
    benefits,
    deductions,
    taxes,
    absences,
    detention,
    overtime,
    transactions,
    oneTimeAdditions,
    shipments,
    timeTracking,
  } = data;

  const allAdditionsDone = additions
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allBenefitsDone = benefits
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allTaxesDone = taxes
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allDeductionsDone = deductions
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allAbsencesDone = absences
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allOvertimeDone = overtime
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allDetentionDone = detention
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allShipmentsDone = shipments
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allTimeTrackingsDone = timeTracking
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allTransactionsDone = transactions
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);
  const allOneTimeAdditionsDone = oneTimeAdditions
    .filter((item) => item.disputed)
    .every((item) => item.dispute_approved || item.dispute_declined);

  return (
    allAdditionsDone &&
    allDeductionsDone &&
    allShipmentsDone &&
    allBenefitsDone &&
    allTaxesDone &&
    allAbsencesDone &&
    allOvertimeDone &&
    allDetentionDone &&
    allTimeTrackingsDone &&
    allTransactionsDone &&
    allOneTimeAdditionsDone
  );
};

export const validateDeductions = (deductions) => {
  return (deductions || []).every((item) => !!item.deduction_type?.id && !!Number(item.amount) && !!item.date);
};
export const validateAdditions = (additions) => {
  return (additions || []).every((item) => !!item.addition_type?.id && !!Number(item.amount) && !!item.date);
};
export const validateTaxes = (taxes) => {
  return (taxes || []).every((item) => !!item.tax_type?.id && !!Number(item.percent) && !!item.date);
};
export const validateBenefits = (benefits) => {
  return (benefits || []).every((item) => !!item.benefit_type?.id && !!Number(item.amount) && !!item.date);
};
