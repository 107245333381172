import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import { palette } from 'utils/constants';

export const SBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 999;
    background-color: rgb(0 0 0 / 30%);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${palette.indigo500};
    }
  }
`;
