import React from 'react';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';

const DepositWithdrawalSource = ({ isOnboardingPassed, externalAccount }) => {
  return (
    <div className='external-account'>
      <div className='external-account-header'>
        <Typography variant='h2'>Deposit & Withdrawal Source</Typography>
        {isOnboardingPassed && (
          <CustomButton
            type='secondary'
            title='Update Account'
            styleButton={{ padding: '2px 8px' }}
            onClick={() => null}
          />
        )}
      </div>
      <div className='external-account-details'>
        {isOnboardingPassed && externalAccount ? (
          <>
            <div className='detail-row'>
              <Typography className='label' variant='overLine2'>
                Debit Card
              </Typography>
              <Typography variant='s2'>-</Typography>
            </div>
            <div className='detail-row'>
              <Typography className='label' variant='overLine2'>
                Account Owner
              </Typography>
              <Typography variant='s2'>{externalAccount.account_holder_name}</Typography>
            </div>
            <div className='detail-row'>
              <Typography className='label' variant='overLine2'>
                Bank Name
              </Typography>
              <Typography variant='s2'>{externalAccount.bank_name}</Typography>
            </div>
            <div className='detail-row'>
              <Typography className='label' variant='overLine2'>
                Routing Number
              </Typography>
              <Typography variant='s2'>{externalAccount.routing_number}</Typography>
            </div>
            <div className='detail-row'>
              <Typography className='label' variant='overLine2'>
                Account Number
              </Typography>
              <Typography variant='s2'>•••• •••• •••• {externalAccount.account_number}</Typography>
            </div>
            <div className='detail-row'>
              <Typography className='label' variant='overLine2'>
                Account Type
              </Typography>
              <Typography variant='s2'>{externalAccount.account_type || '-'}</Typography>
            </div>
            <div className='detail-row'>
              <Typography className='label' variant='overLine2'>
                Account Nickname
              </Typography>
              <Typography variant='s2'>{externalAccount.account_type || '-'}</Typography>
            </div>
          </>
        ) : (
          <div className='feat-not-available'>
            <Typography variant='s2'>This feature is only available with Truckin Digital Cash Card</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default DepositWithdrawalSource;
