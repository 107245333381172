import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createContactBookByToken, getCarrierContactBookByToken, updateContactBook } from 'Api/Carriers';
import ContactBookForm from './ContactBookForm';
import { validationSchema } from './validationSchema';
import { getContactBookInitialValues } from './ContactBook.data';

const ContactBook = ({ open, onClose, onSuccess, dot, token, type, carrierId }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [contactBook, setContactBook] = useState(null);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const contactsToCreate = values.filter((i) => !!i.isNew);
      const contactsToEdit = values.filter((i) => !i.isNew);
      const contacts = contactsToCreate.map((item) => {
        return {
          contact_name: item.contact_name,
          phone_number: item.phone_number,
          contact_type_id: item.contact_type_id?.id,
          contact_email: item.contact_email,
          receive_offers: Number(item.receive_offers),
          check_calls: Number(item.check_calls),
          inactive: Number(item.inactive),
        };
      });

      const data = {
        carrier_id: carrierId,
        contact_books: contacts,
      };

      if (contactsToCreate.length) {
        await createContactBookByToken(dot, token, data, type === 'contact' ? 'contact' : undefined);
      }
      if (contactsToEdit.length) {
        const promises = contactsToEdit.map((item) => {
          const body = {
            contact_name: item.contact_name,
            phone_number: item.phone_number,
            contact_type_id: item.contact_type_id?.id,
            contact_email: item.contact_email,
            receive_offers: Number(item.receive_offers),
            check_calls: Number(item.check_calls),
            inactive: Number(item.inactive),
          };
          return updateContactBook(dot, token, item.id, body, type === 'contact' ? 'contact' : undefined);
        });
        await Promise.all(promises);
      }
      onSuccess();
      onClose();
    } catch (e) {
      setLoading(false);
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
    setLoading(false);
  };

  const { values, handleSubmit, handleChange, touchedErrors, setValues } = useForm({
    initialValues: getContactBookInitialValues(contactBook),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const getContactBook = async () => {
    try {
      const currentContactBook = await getCarrierContactBookByToken(
        dot,
        token,
        type === 'contact' ? 'contact' : undefined
      );
      setContactBook(currentContactBook?.data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getContactBook();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<>Contact Book</>}
      $bgColor={palette.gray0}
      $maxWidth='90vw'
      $minWidth='90vw'
      $minHeight='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'primary',
          title: 'Save',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <ContactBookForm
        values={values}
        setValues={setValues}
        handleChange={handleChange}
        touchedErrors={touchedErrors}
        dot={dot}
        token={token}
        type={type}
      />
    </Modal>
  );
};

export default ContactBook;
