import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Popover from '@mui/material/Popover';
import { TableShipmentsContext } from 'context/tableContext';
import { debounce, formatNumber, formatWithCommas, nFormatter, palette, STOP_POINT_TAB } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getColumnsWidth, getShipmentPopoverSettings, updateColumnsWidth } from 'Api/Shipment';
import {
  customerStopPoint,
  getAssignToGroupBillingCharges,
  getBillToBillingCharges,
  getEquipmentRequiredTrailers,
  getEquipmentRequiredVehicles,
  getTabs,
  getVehicleData,
} from 'Api/Planner';
import redArrow from 'assets/redArrow.svg';
import greenArrow from 'assets/greenArrow.png';
import edit from 'assets/icons/drivers/edit.svg';
import More from 'assets/icons/drivers/more.svg';
import MaterialTable from 'material-table';
import right from 'assets/icons/drivers/right.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { clearShipmentAuditData, Shipment } from 'Api/ShipmentAudit';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as DetentionIcon } from 'assets/icons/detention.svg';
import useDateFormat from 'hooks/useDateFormat';
import useShipmentLocked from 'hooks/useShipmentLocked';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import ColumnHeader from 'common/ColumnHeader';
import ExportCsv from 'componentsV2/ExportCsv';
import ShipmentSummary from 'componentsV2/Shipment/ShipmentSummary';
import DriverPay from 'components/CompletedShipmets/DriverPay';
import useGetShipmentWeight from 'hooks/useGetShipmentWeight';
import { convertToCustomerTime, getShipmentType } from 'utils/helpers';
import classes from './completedShipments.module.css';

import SwitchRadio from '../SwitchRadio/SwitchRadio';
import TableFooter from '../TableFooter/TableFooter';
import CustomModal from '../CustomModal/CustomModal';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import ShipmentAuditFilter from './ShipmentAuditFilter';
import ShipmentModalContent from './helpers/ShipmentModalContent';
import EquipmentPreloaderSkeleton from '../Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import { MaterialTableSort } from '../MaterialTableWrapper';
import {
  DEFAULT_DISABLED_TABS,
  LOCATION_INITIAL_VALUE,
  LOCATION_INITIAL_VALUE_STOP_POINT,
  SCHEDULED_DATE_TYPE_OBJ,
} from '../CreateShipment/ShipmentStops/helpers/constants';
import HeaderCompletedShipments from './HeaderShipments';
import SenderInfo from '../SenderInfo/SenderInfo';
import { getAverageTimeConverted } from '../StopPoint/Walmart/helpers';
import ClearShipments from './ClearShipments';
import RevertShipments from './RevertShipments';
import { Typography } from '../Typography';
import MilesColumn from './MilesColumn';
import './ShipmentAudit.css';
import { DEFAULTWIDTHOFCOLUMNSAUDIT, getStatusParamsInStatus } from '../TableShipments/helpers/constants';
import UserInfo from '../StopPoint/UserInfo';
import OverLayTriggerHoverWrapper from '../OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import UserInfoRowAssignedTo from '../TableShipments/helpers/UserInfoRowAssignedTo';
import OverLayShipmentIdCloud from '../TablePlaner/helpers/OverLayShipmentIdCloud';

let controller = null;

const CompletedShipments = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { isShipmentLocked } = useShipmentLocked();
  const { formatDate, formatTime, dateTimeFormat } = useDateFormat();
  const { getShipmentWeight } = useGetShipmentWeight();
  const [statuses, setStatuses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const resizingColumn = useRef();
  const { currency } = useSelector((state) => state.root);
  const [localFilterCheckboxes, setLocalFilterCheckboxes] = useState({ status: [], groups: [], equipment_types: [] });
  const [getShipmentFilterData, setGetShipmentFilterData] = useState({});
  const [showHeaderFilters, setShowHeaderFilters] = useState([]);
  const [stopPointCondition, setStopPointCondition] = useState(false);
  const myTableRef = useRef(null).current;
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [switchRadioButtons, setSwitchRadioButtons] = useState({});
  const [customers, setCustomers] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [shipmentsData, setShipmentsData] = useState({ data: [] });
  const [filterByGroupsData, setFilterByGroupsData] = useState([]);
  const [filterByEquipmentTypeData, setFilterByEquipmentTypeData] = useState([]);
  const [tabModalEditData, setTabModalEditData] = useState({});
  const [activeTabData, setActiveTabData] = useState();
  const [disabledFilters] = useState({ ...DEFAULT_DISABLED_TABS });
  const [dateRangeLocation, setDateRangeLocation] = useState([null, null]);
  const [dateRangeStopPoint, setDateRangeStopPoint] = useState([null, null]);
  const [columnWidths, setColumnWidths] = useState({ ...DEFAULTWIDTHOFCOLUMNSAUDIT });
  const [triggerAdvancedClear, setTriggerAdvancedClear] = useState(null);
  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
    allValue: '',
    cloudSwitch: false,
    selectedValues: [],
    locations: LOCATION_INITIAL_VALUE,
    stopPointAll: LOCATION_INITIAL_VALUE_STOP_POINT,
    customerSelectData: [],
    equipmentSelectedData: [],
    tableColumn,
    switchRadioButtons: {
      amount: '1',
      date: '1',
      time: '1',
    },
    forAll: false,
    filterTableTop: [],
    planerHeaderSwitch: '0',
    applyAllUsersModal: { value: '0' },
  });
  const [shipmentToEditDriverPay, setShipmentToEditDriverPay] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [senderPopupData] = useState({ data: {} });
  const open = Boolean(anchorEl);
  const Popup = open ? 'simple-popover' : undefined;
  const audit = true;

  const multiSelectOptions = useMemo(() => {
    return {
      title: 'All',
      id: 999,
      value: 'all',
      key: 9999,
      submenu: [
        {
          title: 'All',
          id: 999,
          value: 'all',
          key: 9999,
        },
        {
          title: 'Customer',
          id: 333,
          value: 'customers',
          key: uuid(),
          disabled: disabledFilters.customers,
        },
        {
          title: 'Location',
          id: 444,
          value: 'locations',
          key: uuid(),
        },
        {
          title: 'Stop Point',
          id: 555,
          value: 'stop_point',
          key: uuid(),
          disabled: disabledFilters.stop_point,
        },
        {
          title: 'Vehicle ID',
          id: 666,
          value: 'equipment',
          key: uuid(),
        },
      ],
    };
  }, [filterByGroupsData, filterByEquipmentTypeData, disabledFilters]);
  const [masterChecked, setMasterChecked] = useState(false);

  const checkAllRow = () => {
    const CheckRows = shipmentsData.data.map((item) => {
      return { ...item, isSelected: !masterChecked };
    });
    setMasterChecked(!masterChecked);
    setShipmentsData({ ...shipmentsData, data: CheckRows });
  };

  const handleMouseDown = (columnField) => (e) => {
    resizingColumn.current = {
      columnField,
      startingWidth: columnWidths[columnField],
      startingX: e.clientX,
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!resizingColumn.current) return;
    const delta = e.clientX - resizingColumn.current.startingX;
    const minWidth = ['loaded_miles', 'empty_miles'].includes(resizingColumn.current.columnField) ? 160 : 50;
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [resizingColumn.current.columnField]: Math.max(resizingColumn.current.startingWidth + delta, minWidth),
    }));

    const param = {
      table_type: 'shipment_audit',
      column_type: resizingColumn.current.columnField,
      width: Math.max(resizingColumn.current.startingWidth + delta, 50),
    };

    updateColumnsWidth(param);
  };

  const handleMouseUp = () => {
    resizingColumn.current = null;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const columns = [
    {
      field: '',
      resizable: false,
      width: columnWidths.checkbox,
      title: (
        <div>
          <CustomCheckbox
            // labelStyle={{ margin: '0 20px' }}
            checked={masterChecked}
            id='mastercheck'
            onChange={() => checkAllRow()}
          />
        </div>
      ),
      render: (rowData) => {
        return (
          <div>
            <div onClick={(e) => e.stopPropagation()}>
              <CustomCheckbox
                labelStyle={{ margin: '0 20px' }}
                checked={rowData?.isSelected}
                onChange={() => tableRowCheckboxChange(rowData)}
              />
            </div>
          </div>
        );
      },
    },
    {
      field: 'shipment_id',
      resizable: true,
      width: columnWidths.shipment_id,
      title: <MaterialTableSort title='shipment / ref id' field='shipment_id' sortingQuery={sortingQuery} />,
      render: (rowData) => (
        <div>
          <Dropdown className='driver-name-basic-wrapper'>
            <Dropdown.Toggle className='menu-drop' id='driver-name-basic'>
              <div className='shipment-container'>
                <div className='shipment-first'>
                  <div>
                    <p
                      className='shipment-id'
                      style={{
                        color: use(palette.dark800, palette.gray200),
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {rowData?.shipment_id}
                      <ShipmentSummary shipmentId={rowData.shipment_id} className='ms-1' />
                      {Array.isArray(rowData?.detentions) && rowData?.detentions?.length > 0 && (
                        <OverlayTrigger
                          placement='right'
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Detention warning</Tooltip>}
                        >
                          <div onClick={(e) => onClickRowIcon(e, rowData, 'detention')}>
                            <DetentionIcon
                              width={14}
                              height={14}
                              fill={palette.red400}
                              style={{ marginLeft: 4, flexShrink: 0 }}
                            />
                          </div>
                        </OverlayTrigger>
                      )}
                      {(rowData?.is_edi_api === 1 || rowData?.manual_edi_api === 1) && (
                        <OverLayShipmentIdCloud
                          rowData={rowData}
                          onClickButtons={getShipment}
                          allFilterData={{ ...getShipmentFilterData, ...activeTabData?.data?.filters }}
                        />
                      )}
                      <br />
                    </p>
                  </div>
                </div>
                <div className='shipment-second'>
                  <p className='text-shipment' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {rowData?.reference_id}
                  </p>
                </div>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
          <Typography variant='s3'>{getShipmentType(rowData.shipment_type)}</Typography>
        </div>
      ),
    },
    {
      field: 'status',
      resizable: true,
      width: columnWidths.status,
      title: <MaterialTableSort title='Status' field='status' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const status = rowData?.status;
        const paramsInStatus = getStatusParamsInStatus(status);
        const { bgStatus, colorStatus, statusName } = paramsInStatus || {};
        return (
          <div className='d-flex justify-content-start'>
            <div className={classes.statusWrapper}>
              <span className={classes.statusColumns} style={{ backgroundColor: bgStatus, color: colorStatus }}>
                {statusName}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'stops',
      resizable: true,
      width: columnWidths.stops,
      title: <MaterialTableSort title='stops' field='shipment_stops' sortingQuery={sortingQuery} isSorting={false} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray700 }}>
          {row.shipment_stops?.length}
        </Typography>
      ),
    },
    {
      field: 'origin',
      resizable: true,
      width: columnWidths.origin,
      title: <Typography variant='c3'>ORIGIN</Typography>,
      render: (rowData) => {
        const originStopName = rowData.shipment_stops[0]?.stop_point?.location_name;
        const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
        const id = rowData.shipment_stops[0]?.stop_point?.id;
        const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;

        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;
        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOrigin} ${toData || ''}`;

        return (
          <>
            <Typography
              variant='b3'
              className={classes.originNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {originStopName}{' '}
            </Typography>
            <br />
            <span className='rowData_wrapper}' style={{ color: use(palette.dark800, palette.gray200) }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(scheduledOrigin)} </Typography>
              <span className='rowData_wrapper}' style={{ color: use(palette.dark800, palette.gray200) }}>
                {fromData ? ` ${formatTime(from)}` : ''}
                {toData ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </>
          </>
        );
      },
    },
    {
      field: 'destination',
      resizable: true,
      width: columnWidths.destination,
      title: <Typography variant='c3'>DESTINATION</Typography>,
      render: (rowData) => {
        const destinationStopName =
          rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.location_name;
        const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        const zipcode = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.zipcode;
        const id = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.id;

        const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
        const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
        const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;

        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestination} ${toData || ''}`;

        return (
          <>
            <Typography
              variant='b3'
              className={classes.destinationNameWrapper}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/stop-point/${id}/${STOP_POINT_TAB.GENERAL}`);
              }}
            >
              {destinationStopName}{' '}
            </Typography>
            <br />
            <span className='rowData_wrapper}' style={{ color: use(palette.dark800, palette.gray200) }}>
              {city && `${city},`} {state}
              {zipcode && `, ${zipcode}`}
            </span>
            <br />
            <>
              <Typography variant='b3'>{formatDate(scheduledDestination)} </Typography>
              <span className='rowData_wrapper}' style={{ color: use(palette.dark800, palette.gray200) }}>
                {fromData ? `${formatTime(from)}` : ''}
                {toData ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </>
          </>
        );
      },
    },
    {
      field: 'weight',
      resizable: true,
      width: columnWidths.weight,
      title: <ColumnHeader title='WEIGHT' field='weight' />,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {getShipmentWeight(rowData.shipment_stops).expectedWeightWithUnit} (Scheduled)
            <br />
            {getShipmentWeight(rowData.shipment_stops).actualWeightWithUnit} (Actual)
          </Typography>
        );
      },
    },
    {
      field: 'departure_date',
      resizable: true,
      width: columnWidths.departure_date,
      title: (
        <MaterialTableSort
          title='Completed Date'
          field='departure_date'
          sortingQuery={sortingQuery}
          isSorting={false}
        />
      ),
      render: (rowData) => {
        const departure = rowData?.end_date_time;
        return (
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {departure ? convertToCustomerTime(departure, dateTimeFormat) : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'loaded_miles',
      resizable: true,
      width: Math.max(160, columnWidths.loaded_miles),
      title: <MaterialTableSort title='Loaded Miles' field='loaded_miles' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return <MilesColumn shipment={rowData} type='loaded' onSuccess={getShipment} />;
      },
    },
    {
      field: 'empty_miles',
      resizable: true,
      width: Math.max(160, columnWidths.empty_miles),
      title: <MaterialTableSort title='Empty Miles' field='empty_miles' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return <MilesColumn shipment={rowData} type='empty' onSuccess={getShipment} />;
      },
    },
    {
      field: 'planned_duration',
      resizable: true,
      width: columnWidths.planned_duration,
      title: <MaterialTableSort title='Route Duration' field='planned_duration' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {rowData?.plan_duration || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'scheduled_duration',
      resizable: true,
      width: columnWidths.scheduled_duration,
      title: <MaterialTableSort title='Scheduled Duration' field='scheduled_duration' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        return (
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {getAverageTimeConverted(rowData?.scheduled_duration) || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'actual_duration',
      resizable: true,
      width: columnWidths.actual_duration,
      title: (
        <MaterialTableSort
          title='Actual Duration'
          field='actual_duration'
          sortingQuery={sortingQuery}
          isSorting={false}
        />
      ),
      render: (rowData) => {
        const isNegative = rowData?.duration_diff < 0;
        return (
          <div className='d-flex flex-column'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              {rowData?.actual_duration || '-'}
            </Typography>
            {rowData?.duration_diff &&
              (isNegative ? (
                <Typography variant='s2' style={{ color: palette.red500 }}>
                  {getAverageTimeConverted(Math.abs(rowData?.duration_diff))} <img src={redArrow} alt='red arrow' />{' '}
                </Typography>
              ) : (
                <Typography variant='s2' style={{ color: palette.green500 }}>
                  {getAverageTimeConverted(rowData?.duration_diff)} <img src={greenArrow} alt='green arrow' />{' '}
                </Typography>
              ))}
          </div>
        );
      },
    },
    {
      field: 'assigned_driver',
      resizable: true,
      width: columnWidths.assigned_driver,
      title: (
        <MaterialTableSort
          title='Assigned Driver'
          field='assigned_driver'
          sortingQuery={sortingQuery}
          isSorting={false}
        />
      ),
      render: (rowData) => {
        return <UserInfoRowAssignedTo data={rowData} />;
      },
    },
    {
      field: 'driver_amount',
      resizable: true,
      width: columnWidths.driver_amount,
      title: <MaterialTableSort title='Driver Pay' field='driver_amount' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const amount = +filter.switchRadioButtons.amount;
        const formatDolor =
          amount === 2 ? nFormatter(rowData?.driver_amount) : formatWithCommas(Number(rowData?.driver_amount));
        return (
          <div className='driver-pay-container'>
            {isShipmentLocked(rowData) ? (
              <ActionLocked shipment={rowData} />
            ) : (
              <img
                src={edit}
                alt='edit'
                className='edit-driver-pay'
                onClick={(e) => {
                  e.stopPropagation();
                  setShipmentToEditDriverPay(rowData);
                }}
              />
            )}
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              {rowData?.driver_amount === 'null'
                ? `${currency}0`
                : rowData?.driver_amount
                ? `${currency}${formatDolor}`
                : ''}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'pw',
      resizable: true,
      width: columnWidths.pw,
      title: <MaterialTableSort title='Pw' field='pw' sortingQuery={sortingQuery} isSorting={false} />,
      render: (rowData) => {
        const isPW = !(rowData.shipment_billing || []).filter((billing) => billing.paperwork_received === 0).length;
        return isPW ? <img src={right} alt='' /> : <DeleteIcon fill={palette.red500} />;
      },
    },
    {
      field: 'revenue',
      resizable: true,
      width: columnWidths.revenue,
      title: <MaterialTableSort title='Net Revenue' fielda='revenue' sortingQuery={sortingQuery} isSorting={false} />,
      render: (rowData) => {
        const amount = +filter.switchRadioButtons.amount;
        const net = parseInt(rowData?.net_revenue.replace(/,/g, ''), 10);
        const formatDolor = amount === 2 ? nFormatter(net) : formatWithCommas(+net);
        return (
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {currency}
            {formatDolor}
          </Typography>
        );
      },
    },
    {
      field: 'gross_revenue',
      resizable: true,
      width: columnWidths.gross_revenue,
      title: (
        <MaterialTableSort title='Gross Revenue' field='gross_revenue' sortingQuery={sortingQuery} isSorting={false} />
      ),
      render: (rowData) => {
        const amount = +filter.switchRadioButtons.amount;
        const gross_revenue = rowData?.shipment_billing?.reduce((acc, current) => acc + (current.total_amount || 0), 0);
        const formatDolor = amount === 2 ? nFormatter(gross_revenue) : formatWithCommas(+gross_revenue);

        return (
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            {currency}
            {formatDolor}
          </Typography>
        );
      },
    },
    {
      field: 'customer',
      resizable: true,
      width: columnWidths.customer,
      title: <MaterialTableSort title='Customer' field='customer' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const shipmentBilling = rowData.shipment_billing;
        const {
          contact_name: name,
          contact_email: email,
          phone_number,
        } = rowData.shipment_billing?.[0]?.contact_user || {};
        const contactInfo = {
          name,
          email,
          phone_number,
        };

        return shipmentBilling.length === 1 ? (
          <div className='d-flex flex-column align-items-start'>
            <Typography variant='b3' style={{ color: use(palette.dark800, palette.gray200) }}>
              {shipmentBilling?.[0]?.billing_customer?.company_name}&nbsp;
            </Typography>
            <UserInfo data={contactInfo} />
          </div>
        ) : shipmentBilling.length > 1 ? (
          <OverLayTriggerHoverWrapper
            overlay={
              <div>
                {shipmentBilling.map((el, i) => (
                  <>
                    <Typography
                      key={el.id || i}
                      as='p'
                      variant='s2'
                      style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/customer-profile/${el?.billing_customer?.id}`);
                      }}
                    >
                      {el?.billing_customer?.company_name}
                    </Typography>
                    <UserInfo
                      data={{
                        name: el.contact_user?.contact_name,
                        email: el.contact_user?.contact_email,
                        phone_number: el.contact_user?.phone_number,
                      }}
                    />
                  </>
                ))}
              </div>
            }
          >
            <div>
              <Typography variant='b3' style={{ color: use(palette.dark800, palette.gray200) }}>
                Multi-Customer LTL
              </Typography>
            </div>
          </OverLayTriggerHoverWrapper>
        ) : null;
      },
    },
    {
      field: 'equipment',
      resizable: true,
      width: columnWidths.equipment,
      title: <MaterialTableSort title='Equipment' field='equipment' sortingQuery={sortingQuery} isSorting={false} />,
      render: (rowData) => {
        const { equipment, last_hooked_equipment } = rowData;
        return (
          <>
            <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
              {equipment?.equipment_id}&nbsp;
              <Typography variant='overLine'>{equipment?.equipment_type?.title}</Typography>
            </Typography>
            {!!last_hooked_equipment && (
              <>
                <TwoWaysIcon style={{ margin: '0 8px' }} />
                <Typography style={{ textTransform: 'uppercase' }} variant='overLine2'>
                  {last_hooked_equipment?.equipment_id}&nbsp;
                  <Typography variant='overLine'>
                    {last_hooked_equipment?.length?.length}-{last_hooked_equipment?.equipment_type?.title}
                  </Typography>
                </Typography>
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'margin',
      resizable: true,
      width: columnWidths.margin,
      title: <MaterialTableSort title='Margin' field='margin' sortingQuery={sortingQuery} isSorting={false} />,
      render: (row) => {
        const isNegative = row?.shipment_billing?.[0]?.margin < 0;
        return (
          <div>
            {isNegative ? (
              <Typography variant='s2' style={{ color: palette.red500 }}>
                {`${formatNumber(row.shipment_billing?.[0]?.margin)}%`}
              </Typography>
            ) : (
              <Typography variant='s2' style={{ color: palette.green500 }}>
                {`+${formatNumber(row.shipment_billing?.[0]?.margin)}%`}
              </Typography>
            )}
          </div>
        );
      },
    },
    ...(activeTabData?.label === 'Audited'
      ? [
          {
            field: 'audited_by',
            resizable: true,
            width: columnWidths.audited_by,
            title: (
              <MaterialTableSort title='Audited By' field='audited_by' sortingQuery={sortingQuery} isSorting={false} />
            ),
            render: (row) => (
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                {row.audited_by ? `${row.audited_by.first_name} ${row.audited_by.last_name}` : '-'}
              </Typography>
            ),
          },
        ]
      : []),
  ];

  const tableRowCheckboxChange = (row) => {
    const matchRow = shipmentsData.data.findIndex((item) => item.shipment_id === row?.shipment_id);
    const temp = [...shipmentsData.data];
    temp[matchRow].isSelected = !temp[matchRow].isSelected;
    const isUnMarkRow = temp.findIndex((item) => !item.isSelected);
    setMasterChecked(isUnMarkRow === -1);
    setShipmentsData({ ...shipmentsData, data: temp });
  };

  const getShipmentTabs = useCallback(({ type }) => {
    return getTabs({ type })
      .then((res) => {
        if (res && res?.data) {
          const newData = res.data.map((el) => {
            return {
              ...el,
              label: el?.data?.name,
              key: uuid(),
              value: uuid(),
            };
          });
          setActiveTabData(newData[0]);
          return [...newData];
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterByGroups = useCallback(() => {
    getAssignToGroupBillingCharges()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: el.group_name,
              id: el.id,
              value: 'groups',
              type: 'checkbox',
              key: uuid(),
              typeData: 'groups',
            };
          });
          setFilterByGroupsData(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getFilterStopPointData = useCallback(() => {
    customerStopPoint().then((res) => {
      if (res && res?.data) {
        const stopPoints = res.data.map((el) => ({
          id: el.id,
          key: el.id,
          label: el?.location_name,
        }));
        setStopPoints([...stopPoints]);
      }
    });
  }, []);

  const getFilterCustomerData = useCallback(() => {
    getBillToBillingCharges().then((res) => {
      const newCustomers = res.data.map((el) => ({
        id: el.id,
        title: el.company_name,
        label: el.company_name,
        key: el.id,
      }));
      setCustomers(newCustomers);
    });
  }, []);

  const getFilterEquipmentData = useCallback(() => {
    getVehicleData().then((res) => {
      if (res && res?.data) {
        const newEquipments = res.data.map((el) => {
          const label = `${el.equipment_type?.title} ${el.equipment_id} ${
            el.drivers ? `(${el.drivers?.map((item) => `${item?.fname} ${item?.lname} `) || ''})` : ''
          }`;
          return {
            id: el.id,
            title: label,
            label,
            searchValue: label,
            key: el.id,
          };
        });
        setEquipmentData(newEquipments);
      }
    });
  }, []);

  const getFilterByEquipmentType = useCallback(async () => {
    const filterByEquipmentData = [];

    await Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicle, trailers]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 1) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
        if (trailers && trailers?.data) {
          trailers.data.forEach((el) => {
            const filterByData = {
              title: el.title,
              id: el.id,
              value: 'equipment_types',
              type: 'checkbox',
              key: uuid(),
              typeData: 'trailers',
            };
            filterByEquipmentData.push(filterByData);
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);

  function extractDates(inputStr) {
    const regex = /(\d{2}\/\d{2}\/\d{4}) - (\d{2}\/\d{2}\/\d{4})/;
    const regexStartDate = /(\d{2}\/\d{2}\/\d{4})/;
    const match = inputStr.match(regex);
    const matchStartDate = inputStr.match(regexStartDate);
    if (match) {
      return {
        startDate: match[1],
        endDate: match[2],
      };
    }
    if (matchStartDate) {
      return {
        startDate: matchStartDate[1],
        endDate: matchStartDate[1],
      };
    }
    return null;
  }

  const onApplyFilterCheckboxes = useCallback(
    ({
      checkboxes,
      status,
      groups,
      customers,
      vehicles,
      createdBy,
      trailers,
      drivers,
      carrier_ids,
      stop_points,
      equipment_types,
      shipment_id,
      reference_id,
      bill_of_lading_id,
      valuePickupDate,
      valueDeliveryDate,
      origin,
      destination,
    }) => {
      const TYPES = ['status', 'groups', 'equipment_types'];

      const {
        status: statusArr,
        groups: groupsArr,
        equipment_types: equipment_types_Arr,
        customer,
        vehicle,
        trailer,
        created_by,
        driver,
        carrier,
        stop_point,
        shipment_id: shipmentIds,
        reference_id: referenceIds,
        bill_of_lading_id: billOfLadingId,
      } = checkboxes;

      const { origin_city, origin_state, origin_zipcode, origin_miles } = origin || {};
      const { destination_city, destination_state, destination_zipcode, destination_miles } = destination || {};

      const statusParent = { id: statusArr?.[0]?.id, value: TYPES[0], searchValue: statusArr, title: 'Status' };
      const groupsParent = { id: groupsArr?.[0]?.id, value: TYPES[1], searchValue: groupsArr, title: 'Tags' };
      const customerParent = { id: customer?.[0]?.id, value: TYPES[1], searchValue: customer, title: 'Customer' };
      const vehicleParent = { id: vehicle?.[0]?.id, value: TYPES[1], searchValue: vehicle, title: 'Vehicle' };
      const trailerParent = { id: trailer?.[0]?.id, value: TYPES[1], searchValue: trailer, title: 'Trailer' };
      const driverParent = { id: driver?.[0]?.id, value: TYPES[1], searchValue: driver, title: 'Driver' };
      const createdByParent = {
        id: created_by?.[0]?.id,
        value: TYPES[1],
        searchValue: created_by,
        title: 'Created By',
      };
      const carrierParent = {
        id: carrier?.[0]?.id,
        value: TYPES[1],
        searchValue: carrier.map((el) => ({ ...el, title: el.name })),
        title: 'Carrier',
      };
      const shipmentIdsParent = {
        id: shipmentIds?.[0],
        value: TYPES[1],
        searchValue: shipmentIds,
        title: 'Shipment ID',
      };
      const billOfLadingIdParent = {
        id: billOfLadingId?.[0],
        value: TYPES[1],
        searchValue: billOfLadingId,
        title: 'Bill of Lading ID',
      };
      const referenceIdsParent = {
        id: referenceIds?.[0],
        value: TYPES[1],
        searchValue: referenceIds,
        title: 'Reference ID',
      };
      const stop_pointParent = {
        id: stop_point?.[0]?.id,
        value: TYPES[1],
        searchValue: stop_point,
        title: 'Stop Point',
      };
      const equipmentParent = {
        id: equipment_types_Arr?.[0]?.id,
        value: TYPES[2],
        searchValue: equipment_types_Arr,
        title: 'Equipment types',
      };

      const selectedValues = [...statusArr, ...groupsArr, ...equipment_types_Arr]; // filter.selectedValues[state]

      // data in backEnd
      const allFilterData = {
        ...getShipmentFilterData,
        status,
        groups,
        equipment_types,
        customers,
        stop_point: stop_points,
        shipment_id: shipment_id?.[0],
        reference_id: reference_id?.[0],
        bill_of_lading_id: bill_of_lading_id?.[0],
        status_ids: status,
        equipment: vehicles,
        trailer_id: trailers,
        driver_id: drivers,
        carrier_ids,
        created_by: createdBy,
        ...activeTabData?.data?.filters,
      };

      let originParent;
      let searchValue = '';
      if (origin_city && origin_state) {
        searchValue = `${origin_city}, ${origin_state}`;
        allFilterData.origin_city = origin_city;
        allFilterData.origin_state = origin_state;
      } else if (origin_zipcode) {
        searchValue = `${origin_zipcode}`;
        allFilterData.origin_zipcode = origin_zipcode;
      }
      if (origin_zipcode && origin_city && origin_state) {
        searchValue += `, ${origin_zipcode}`;
      }
      if (origin_miles && ((origin_state && origin_city) || origin_zipcode)) {
        allFilterData.origin_miles = origin_miles;
        searchValue += ` (${origin_miles} miles)`;
      }
      if (searchValue) {
        originParent = {
          id: 'origin ',
          value: TYPES[1],
          searchValue,
          title: 'Origin',
        };
      }

      let destinationParent;
      let destinationSearchValue = '';
      if (destination_city && destination_state) {
        destinationSearchValue = `${destination_city}, ${destination_state}`;
        allFilterData.destination_city = destination_city;
        allFilterData.destination_state = destination_state;
      } else if (destination_zipcode) {
        destinationSearchValue = `${destination_zipcode}`;
        allFilterData.destination_zipcode = destination_zipcode;
      }
      if (destination_zipcode && destination_city && destination_state) {
        destinationSearchValue += `, ${destination_zipcode}`;
      }
      if (destination_miles && ((destination_state && destination_city) || destination_zipcode)) {
        allFilterData.destination_miles = destination_miles;
        destinationSearchValue += ` (${destination_miles} miles)`;
      }
      if (destinationSearchValue) {
        destinationParent = {
          id: 'destination ',
          value: TYPES[1],
          searchValue: destinationSearchValue,
          title: 'Destination',
        };
      }

      let pickupDateRangeParent;
      if (valuePickupDate) {
        pickupDateRangeParent = {
          id: 'pickup',
          value: TYPES[1],
          searchValue: valuePickupDate,
          title: 'Pickup Date Range',
        };
        const dates = extractDates(valuePickupDate);
        allFilterData.origin_scheduled_from = dates.startDate;
        allFilterData.origin_scheduled_to = dates.endDate;
      }

      let deliveryDateParent;
      if (valueDeliveryDate) {
        deliveryDateParent = {
          id: 'pickup',
          value: TYPES[1],
          searchValue: valueDeliveryDate,
          title: 'Delivery Date Range',
        };
        const dates = extractDates(valueDeliveryDate);
        allFilterData.destination_scheduled_from = dates.startDate;
        allFilterData.destination_scheduled_to = dates.endDate;
      }

      // show data in user
      const showData = [...showHeaderFilters];
      const filtered = showData.filter((option) => !TYPES.includes(option?.value));

      const newShowData = [...filtered];
      statusParent?.id && newShowData.push(statusParent);
      groupsParent?.id && newShowData.push(groupsParent);
      equipmentParent?.id && newShowData.push(equipmentParent);
      customerParent?.id && newShowData.push(customerParent);
      vehicleParent?.id && newShowData.push(vehicleParent);
      trailerParent?.id && newShowData.push(trailerParent);
      driverParent?.id && newShowData.push(driverParent);
      carrierParent?.id && newShowData.push(carrierParent);
      createdByParent?.id && newShowData.push(createdByParent);
      stop_pointParent?.id && newShowData.push(stop_pointParent);
      shipmentIdsParent?.id && newShowData.push(shipmentIdsParent);
      referenceIdsParent?.id && newShowData.push(referenceIdsParent);
      billOfLadingIdParent?.id && newShowData.push(billOfLadingIdParent);
      pickupDateRangeParent && newShowData.push(pickupDateRangeParent);
      deliveryDateParent && newShowData.push(deliveryDateParent);
      originParent?.id && newShowData.push(originParent);
      destinationParent?.id && newShowData.push(destinationParent);

      setShowHeaderFilters(newShowData);
      updateFilter({ ...filter, selectedValues });
      setGetShipmentFilterData({ ...getShipmentFilterData, status, groups, equipment_types });

      getShipment({ allFilterData });
    },
    [activeTabData, updateFilter, getShipment, showHeaderFilters, getShipmentFilterData, filter]
  );

  const onChangeMultiselectSearchBy = useCallback(
    (data) => {
      const values = showHeaderFilters.map((el) => el.value);

      const updateData = { ...filter, searchBy: data };
      if (data.value === 'all' && !values.includes(data.value)) updateData.allValue = '';
      updateFilter(updateData);
    },
    [showHeaderFilters, filter, updateFilter]
  );

  const onChangeRowPerPage = (rowPage) => {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    getShipment({ page: 1, itemsPerPage: rowPage, allFilterData });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
    const lastPage = shipmentsData.last_page;
    const tabData = activeTabData?.data?.filters || (activeTabData?.key === 'all' && {});
    const allFilterData = { ...getShipmentFilterData, ...tabData };

    if (lastPage !== 1) {
      getShipment({ page, itemsPerPage: rowPerPage, allFilterData });
    }
  };

  function updateFilter(updatedFilter) {
    setFilter(updatedFilter);
  }

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        myTableRef={myTableRef}
        rowPerPage={rowPerPage}
        totalCount={shipmentsData.total}
        lastPage={shipmentsData.last_page}
        totalLength={shipmentsData.data.length}
        currentPage={shipmentsData.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onClickModalButtons = (isVisible, item, typeClose) => {
    item !== null && onChangeTab(item, typeClose);
    setShowModal(isVisible);
  };

  function onClickRowIcon(e, rowData, type) {
    e.stopPropagation();
    switch (type) {
      case 'notes':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 5 } });
        break;
      case 'detention':
        navigate(`/shipment/${rowData?.shipment_id}`, { state: { ...rowData, defaultTab: 7 } });
        break;
      default:
    }
  }

  const implementCountTotal = useCallback(
    (total, activeData, reset, modalTypeData) => {
      const type = modalTypeData?.type;
      const modalUpdatedData = modalTypeData?.data;
      const selectedData = activeData || activeTabData;

      if (selectedData) {
        const filterTableTop = filter.filterTableTop.map((el) => {
          if (+el.id === Number(selectedData?.id) || (selectedData.key === 'all' && +el.id === +selectedData.id)) {
            return {
              ...el,
              count: total,
            };
          }
          return el;
        });

        // reset
        if (reset) {
          setFilter((prevState) => {
            return { ...prevState, ...reset, filterTableTop: [...filterTableTop] };
          });
          setShowHeaderFilters([]);
        } else {
          // modal add and delete
          if (type === 'add' || type === 'delete' || type === 'update') {
            if (type === 'add') {
              const addedData = {
                ...modalUpdatedData,
                count: total,
                label: modalUpdatedData.data.name,
                key: uuid(),
                value: uuid(),
              };
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...prevState.filterTableTop, { ...addedData }],
                };
              });
            } else if (type === 'delete') {
              const id = modalUpdatedData;
              const newFilteredData = filter.filterTableTop.filter((el) => el.id !== id);
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newFilteredData],
                };
              });
            } else if (type === 'update') {
              const id = modalUpdatedData?.id;
              const newUpdatedData = filter.filterTableTop.map((el) => {
                if (el.id === id) {
                  const updateData = {
                    ...modalUpdatedData,
                    count: total,
                    label: modalUpdatedData.data.name,
                    key: uuid(),
                    value: uuid(),
                  };
                  return updateData;
                }
                return el;
              });
              setFilter((prevState) => {
                return {
                  ...prevState,
                  filterTableTop: [...newUpdatedData],
                };
              });
            }
          } else if (filterTableTop?.length > 0) {
            setFilter((prevState) => {
              return { ...prevState, filterTableTop: [...filterTableTop] };
            });
          }
        }
      }
    },
    [activeTabData, filter, updateFilter]
  );

  function getShipment(data) {
    controller && controller.abort();
    controller = new AbortController();

    const {
      page = 1,
      reset,
      filtersType,
      sort,
      filtersValue,
      itemsPerPage,
      remainingObj,
      allFilterData,
      getData = false,
      implementActiveData,
      modalTypeData,
      hasALoading = true,
    } = data || {};
    hasALoading && setLoading(true);
    if (!allFilterData) {
      return Shipment({
        signal: controller.signal,
        page,
        itemsPerPage: itemsPerPage || rowPerPage,
        filtersType,
        filtersValue,
        sort: sort || sortData,
        status_ids: statuses,
        remainingData: remainingObj
          ? getData
            ? { ...remainingObj, ...getShipmentFilterData }
            : remainingObj
          : getShipmentFilterData,
      })
        .then((res) => {
          setShipmentsData(res);
          implementCountTotal(res.total, implementActiveData, reset, modalTypeData);
          return res;
        })
        .catch(() => {
          /* empty */
        })
        .finally(() => setLoading(false));
    }
    return Shipment({
      signal: controller.signal,
      page,
      sort: sort || sortData,
      itemsPerPage: itemsPerPage || rowPerPage,
      status_ids: statuses,
      allFilterData,
    })
      .then((res) => {
        implementCountTotal(res.total, implementActiveData, reset, modalTypeData);
        setShipmentsData(res);
        return res;
      })
      .catch(() => {
        /* empty */
      })
      .finally(() => setLoading(false));
  }

  const mapperSettingsData = (key) => {
    const map = {
      shipment_id: 'Shipment Id',
      status: 'Status',
      stops: 'Stops',
      origin: 'Origin',
      destination: 'Destination',
      departure_date: 'Completed Date',
      loaded_miles: 'Loaded Miles',
      empty_miles: 'Empty Miles',
      planned_duration: 'Route Duration',
      scheduled_duration: 'Scheduled Duration',
      audited_by: 'Audited By',
      actual_duration: 'Actual Duration',
      assigned_driver: 'Assigned Driver',
      driver_amount: 'Driver Pay',
      pw: 'Pw',
      margin: 'Margin',
      weight: 'Weight',
      revenue: 'Net Revenue',
      gross_revenue: 'Gross Revenue',
      customer: 'Customer',
      equipment: 'Equipment',
    };
    return map[key] ? map[key] : key;
  };

  const getSettings = useCallback(() => {
    getShipmentPopoverSettings({ type: 'shipment_audit' })
      .then((res) => {
        if (res?.data && res?.data?.data) {
          const allData = typeof res?.data?.data === 'string' ? JSON.parse(res?.data?.data) : res.data.data;
          const amount = String(allData.amount);
          const date = String(allData.date);
          const time = String(allData.time);
          const tableColumns = allData.columns;
          const dragItems = allData.columns_order;
          const tableColumnsData = [];
          Object.keys(tableColumns).forEach((key) => {
            const value = tableColumns[key];
            tableColumnsData.push({
              title: mapperSettingsData(key),
              value: key,
              checked: value,
            });
          });
          const tableOrder = dragItems.map((value, index) => {
            return {
              title: mapperSettingsData(value),
              value,
              order: index + 1,
              id: index + 1,
            };
          });
          setTableColumn(tableColumnsData);
          setDragItem(tableOrder);
          setSwitchRadioButtons({ amount, date, time });
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [mapperSettingsData]);

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [columns[0]];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      cols.push({
        ...col,
      });
    });
    return cols;
  };

  function sortingQuery(field, sortBy) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    let newSortedData;
    if (sortData.find((el) => el.type === field)) {
      newSortedData = sortData.filter((el) => el.type !== field);
      newSortedData.unshift({ type: field, sortBy });
      setSortData(newSortedData);
    } else {
      newSortedData = [{ type: field, sortBy }, ...sortData];
      setSortData((p) => [{ type: field, sortBy }, ...p]);
    }

    getShipment({ sort: newSortedData, allFilterData, hasALoading: false });
  }

  const onEditTableTab = useCallback((item) => {
    const { data, user_id, id, system } = item;
    const { name, filters } = data;
    const { status, groups, equipment_types, customers, stop_point, is_edi, above_margin, below_margin, is_dry_run } =
      filters;
    const initialValue = {
      name,
      status,
      groups,
      equipment_types,
      customers,
      stop_point,
      user_id,
      id,
      system,
      is_edi,
      is_dry_run,
      above_margin,
      below_margin,
    };
    setTabModalEditData(initialValue);
    setShowModal(true);
  }, []);

  const onChangeTabHelper = useCallback(
    (disabledFiltersData) => {
      const { customers, groups, status, equipment_types, stop_point } = disabledFiltersData;
      const showedFiltersArrData = showHeaderFilters.filter((el) => {
        const value = el?.value;
        if (value) {
          return disabledFilters.hasOwnProperty(value);
        }
        return Array.isArray(el) && el?.[0].type === 'stop_point';
      });
      const newShowHeaderFilters = showHeaderFilters.filter(
        (el) =>
          (!Array.isArray(el) && !disabledFiltersData[el.value]) ||
          (Array.isArray(el) && el?.[0].type === 'stop_point' && !stop_point)
      );

      setShowHeaderFilters(newShowHeaderFilters);

      const newSelectedValues = filter.selectedValues.filter((el) => !disabledFiltersData[el?.value]);
      const clone = { ...getShipmentFilterData };

      Object.keys(disabledFiltersData).forEach((key) => {
        const value = disabledFiltersData[key];
        if (value) {
          if (key === 'stop_point') {
            delete clone[key];
            delete clone.stop_point_scheduled_from;
            delete clone.stop_point_scheduled_to;
            setDateRangeStopPoint([null, null]);
          } else {
            delete clone[key];
          }
        }
      });

      setGetShipmentFilterData(clone);
      setFilter((prevState) => {
        return {
          ...prevState,
          selectedValues: newSelectedValues,
          customerSelectData: !customers ? prevState.customerSelectData : [],
          stopPointAll: !stop_point ? prevState.stopPointAll : LOCATION_INITIAL_VALUE_STOP_POINT,
        };
      });

      for (let i = 0; i < Object.keys(disabledFiltersData).length; i++) {
        const key = Object.keys(disabledFiltersData)[i];
        const value = Object.values(disabledFiltersData)[i];
        const foundedSelectData = showedFiltersArrData.find(
          (el) => (!Array.isArray(el) && el.value === key) || (Array.isArray(el) && el?.[0].type === 'stop_point')
        );
        if (value && foundedSelectData) {
          setFilter((prevState) => {
            return {
              ...prevState,
              searchBy: { id: 1, title: 'All', value: 'all', key: 9999 },
            };
          });
        }
      }
      const newLocalData = {
        groups: groups ? [] : localFilterCheckboxes.groups,
        status: status ? [] : localFilterCheckboxes.status,
        equipment_types: equipment_types ? [] : localFilterCheckboxes.equipment_types,
      };
      setLocalFilterCheckboxes(newLocalData);
      !!groups && setGroups([]);
      !!status && setStatuses([]);
      !!equipment_types && setEquipments([]);
    },
    [showHeaderFilters, disabledFilters, filter]
  );

  const onChangeTab = useCallback(
    (item, modalTypeData) => {
      const allTabData = item?.data?.filters;
      const disabledFiltersData = {};
      const remainingObj = {};
      let allFilterData;

      const activeTabData = { ...item, data: { ...item.data, filters: {} } };
      if (item.key !== 'all') {
        Object.keys(allTabData).forEach((key) => {
          const value = allTabData[key];
          const conditionCloudSwitch =
            key === 'is_edi' || key === 'above_margin' || (key === 'below_margin' && !!value);
          if ((Array.isArray(value) && value.length > 0) || conditionCloudSwitch) {
            activeTabData.data.filters[key] = value;
          }
        });
      }

      if (allTabData) {
        const clonegetShipmentFilterData = { ...getShipmentFilterData };
        Object.keys(allTabData).forEach((key) => {
          const value = allTabData[key];
          if (Array.isArray(value) && value.length > 0) {
            disabledFiltersData[key] = true;
            remainingObj[key] = value;
          } else if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
            disabledFiltersData[key] = true;
            remainingObj[key] = value?.key;
            delete clonegetShipmentFilterData.stop_point_scheduled_from;
            delete clonegetShipmentFilterData.stop_point_scheduled_to;
          } else if ((typeof value === 'boolean' || typeof value === 'string') && !!value) {
            remainingObj[key] = value;
          }
        });
        allFilterData = { ...clonegetShipmentFilterData, ...remainingObj };
      } else {
        if (item.key === 'all') {
          allFilterData = { ...getShipmentFilterData };
        } else if (item) {
          allFilterData = { ...getShipmentFilterData };
        }
      }
      setActiveTabData(activeTabData);
      onChangeTabHelper({ ...DEFAULT_DISABLED_TABS, ...disabledFiltersData });
      getShipment({ allFilterData, implementActiveData: item, modalTypeData });
    },
    [getShipmentFilterData, onChangeTabHelper, getShipment, showHeaderFilters]
  );

  const onRowClick = (e, row) => {
    navigate(`/shipment/${row.shipment_id}`);
  };

  /** Filter * */
  function onDeleteManyDataItem(item, parent_value) {
    if (item === 'all') {
      const deletedData = showHeaderFilters.filter((el) => el.value !== item);
      const filterType = showHeaderFilters?.find((el) => el.value === item)?.value;
      const remainingObj = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      delete remainingObj[filterType];
      updateFilter({ ...filter, allValue: '' });
      setGetShipmentFilterData({ ...remainingObj });
      setShowHeaderFilters(deletedData);
      getShipment({ filtersType: '', filtersValue: '', reset: false, remainingObj });
      return;
    }

    if (parent_value === 'equipment') {
      const cloneShowData = [...showHeaderFilters];
      const parentIndex = cloneShowData.findIndex((el) => el?.value === parent_value);
      const newShowData = cloneShowData[parentIndex].searchValue.filter((el) => Number(el?.id) !== +item.id);
      cloneShowData[parentIndex].searchValue = newShowData;
      if (newShowData.length === 0) {
        delete cloneShowData[parentIndex];
      }
      setShowHeaderFilters([...cloneShowData.filter((el) => el !== undefined)]);

      const filteredEquipments = filter.equipmentSelectedData.filter((el) => +el.id !== +item.id);

      const arrIds = filteredEquipments.map((el) => el?.id);
      const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      allFilterData.equipment = arrIds;

      setFilter({ ...filter, equipmentSelectedData: filteredEquipments });
      setGetShipmentFilterData({ ...getShipmentFilterData, equipment: arrIds });
      getShipment({ allFilterData });
      return;
    }

    const mapStates = {
      status: { state: statuses, setState: setStatuses },
      groups: { state: groups, setState: setGroups },
      equipment_types: { state: equipments, setState: setEquipments },
    };

    const { id, value } = item || {};
    const parentIndex = showHeaderFilters.findIndex((el) => el.value === parent_value);
    const parent = showHeaderFilters[parentIndex];

    if (parent) {
      const newFilterData = parent.searchValue.filter((el) => +el.id !== +id);
      const empty = newFilterData.length === 0;
      let allFilterNewData;

      if (empty) {
        allFilterNewData = showHeaderFilters.filter((el) => el.value !== parent_value);
      } else {
        const showHeaderFiltersData = [...showHeaderFilters];
        showHeaderFiltersData[parentIndex] = { ...showHeaderFiltersData[parentIndex], searchValue: newFilterData };
        allFilterNewData = showHeaderFiltersData;
      }
      let activeOption;
      const clonePlannerFilterData = { ...getShipmentFilterData };

      if (parent?.value !== 'customers') {
        activeOption = filter.selectedValues.filter((el) => +el.id !== +id);

        const foundedOptionIndex = filter.selectedValues.findIndex((el) => +el.id === +id);
        const foundedOption = filter.selectedValues[foundedOptionIndex];

        const { value: type, id: foundedId } = foundedOption || {};
        const { state, setState } = mapStates[type];
        const newState = state.filter((elId) => +elId !== +foundedId);
        setState(newState);
        setLocalFilterCheckboxes((prevState) => {
          return {
            ...prevState,
            [type]: activeOption,
          };
        });
        setFilter((prevState) => ({ ...prevState, selectedValues: activeOption }));
      } else {
        activeOption = filter.customerSelectData.filter((el) => +el.id !== +id);
        setFilter((prevState) => ({ ...prevState, customerSelectData: activeOption }));
      }

      const selectedValues = activeOption?.filter((el) => el.value === value);
      const arrTypeIds = selectedValues.map((el) => el.id);
      clonePlannerFilterData[parent.value] = arrTypeIds;

      const allFilterData = { ...clonePlannerFilterData, ...activeTabData?.data?.filters };
      getShipment({ allFilterData }).then(() => {
        setGetShipmentFilterData(clonePlannerFilterData);
        setShowHeaderFilters(allFilterNewData);
      });
    }
  }

  /** All * */
  const debounceAll = useMemo(() => {
    return debounce((value) => {
      const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      const showData = [...showHeaderFilters];
      const indexAll = showData.findIndex((el) => Number(el?.key) === 9999);
      const showAllObj = { id: 1, key: 9999, searchValue: value, title: 'All', value: 'all' };
      indexAll !== -1 ? (showData[indexAll] = showAllObj) : showData.push(showAllObj);

      if (!value) {
        delete allFilterData.all;
        setGetShipmentFilterData({ ...allFilterData });
        showData.splice(indexAll, 1);
      } else {
        allFilterData.all = value;
        setGetShipmentFilterData({ ...getShipmentFilterData, all: value });
      }

      setShowHeaderFilters([...showData]);

      if (controller) controller.abort();
      controller = new AbortController();
      getShipment({ allFilterData, signal: controller.signal });
    }, 350);
  }, [activeTabData, getShipmentFilterData, showHeaderFilters]);

  function onChangeAll(value) {
    const updateData = { ...filter };
    if (filter.searchBy.value === 'name') updateData.allValue = value;
    if (filter.searchBy.value === 'all') updateData.allValue = value;
    setFilter(updateData);
    debounceAll(value);
  }

  /** Customers * */
  function onChangeCustomers(value) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    const customerSelectData = [...filter.customerSelectData];
    const foundedIndex = customerSelectData.findIndex((data) => data.id === value?.id);
    foundedIndex !== -1 ? customerSelectData.splice(foundedIndex, 1) : customerSelectData.push(value);

    const idsCustomers = customerSelectData.map((el) => el?.id);
    allFilterData.customers = idsCustomers;

    // show_data
    const showData = [...showHeaderFilters];
    const indexShowCustomer = showData.findIndex((el) => Number(el?.id) === +filter.searchBy.id);
    const showCustomerObj = { id: 333, key: uuid(), title: 'Customer', value: 'customers' };

    if (indexShowCustomer !== -1) {
      showCustomerObj.searchValue = [...customerSelectData];
      showData[indexShowCustomer] = showCustomerObj;
    } else {
      showCustomerObj.searchValue = [{ ...value }];
      showData.push(showCustomerObj);
    }
    if (customerSelectData.length === 0) showData.splice(indexShowCustomer, 1);
    setShowHeaderFilters(showData);
    // show_data

    setGetShipmentFilterData({ ...getShipmentFilterData, customers: idsCustomers });
    setFilter({ ...filter, customerSelectData });
    getShipment({ allFilterData });
  }

  /** Stop-point-select-onChange * */
  function onChangeStopPointSelect(value) {
    const TYPE = 'stop_point';
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const foundIndexShow = showHeaderFilters.findIndex((el) => Array.isArray(el) && el[0].type === TYPE);

    if (foundIndexShow !== -1) {
      const stop_point_index = showHeaderFilters[foundIndexShow].findIndex((point) => point.value === TYPE);
      const cloneShowData = [...showHeaderFilters];
      stop_point_index === -1
        ? cloneShowData[foundIndexShow]?.push({ ...value, value: TYPE, type: TYPE, searchValue: value.label })
        : (cloneShowData[foundIndexShow][stop_point_index] = {
            ...value,
            value: TYPE,
            type: TYPE,
            searchValue: value.label,
          });
      setShowHeaderFilters(cloneShowData);
    } else {
      setShowHeaderFilters([
        ...showHeaderFilters,
        [
          {
            ...value,
            value: TYPE,
            type: TYPE,
            searchValue: value.label,
          },
        ],
      ]);
    }

    setGetShipmentFilterData({ ...getShipmentFilterData, stop_point: value?.id });
    allFilterData.stop_point = value?.id;
    getShipment({ allFilterData });
    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: {
          ...prev.stopPointAll,
          stop_point: { ...prev.stopPointAll.stop_point, ...value, searchValue: value.label },
        },
      };
    });
  }

  /** Stop-point-select-Delete * */
  function onDeleteStopPointSelect() {
    const TYPE = 'stop_point';
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const cloneShowData = [...showHeaderFilters];
    const foundIndexShow = cloneShowData.findIndex((el) => Array.isArray(el) && el[0].type === TYPE);
    if (foundIndexShow !== -1) {
      const stop_point_index = cloneShowData[foundIndexShow].findIndex((point) => point.value === TYPE);
      if (cloneShowData[foundIndexShow]?.length === 1) {
        cloneShowData.splice(foundIndexShow, 1);
      } else {
        cloneShowData[foundIndexShow].splice(stop_point_index, 1);
      }

      setShowHeaderFilters(cloneShowData);
    }

    const cloneGetPlanerFilterData = { ...getShipmentFilterData };
    delete cloneGetPlanerFilterData.stop_point;
    setGetShipmentFilterData({ ...cloneGetPlanerFilterData });

    delete allFilterData.stop_point;
    getShipment({ allFilterData });

    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: { ...prev.stopPointAll, stop_point: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point },
      };
    });
  }

  /** Stop-point-Dates * */
  function onChangeDatePickerStopPoint(dates) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const TYPE = 'stop_point';
    const first = dates[0];
    const last = dates[1];
    const STOP_POINT_FROM = 'stop_point_scheduled_from';
    const STOP_POINT_TO = 'stop_point_scheduled_to';
    const formatDateFirst = moment(first).format('MM/DD/YYYY');
    const formatDateLast = moment(last).format('MM/DD/YYYY');
    const isValidLast = !!moment(last).isValid();

    const cloneGetPlanerData = { ...getShipmentFilterData };
    const showData = [...showHeaderFilters];

    const foundIndexShow = showData.findIndex((el) => Array.isArray(el) && el?.[0].type === TYPE);
    const from = { key: uuid(), searchValue: formatDateFirst, type: 'stop_point', value: 'stop_point_scheduled_from' };
    const to = { key: uuid(), searchValue: formatDateLast, type: 'stop_point', value: 'stop_point_scheduled_to' };

    const arrDates = [from];

    if (isValidLast) {
      arrDates.push(to);
      cloneGetPlanerData.stop_point_scheduled_to = formatDateLast;
      allFilterData.stop_point_scheduled_to = formatDateLast;
    } else {
      delete cloneGetPlanerData.stop_point_scheduled_to;
      delete allFilterData?.stop_point_scheduled_to;
    }

    if (foundIndexShow === -1) {
      showData.push(arrDates);
    } else {
      const foundSelectStopPoint = showData?.[foundIndexShow]?.find((el) => el?.value === TYPE);
      !!foundSelectStopPoint && arrDates.push(foundSelectStopPoint);
      showData[foundIndexShow] = arrDates;
    }

    cloneGetPlanerData.stop_point_scheduled_from = formatDateFirst;
    allFilterData.stop_point_scheduled_from = formatDateFirst;

    setGetShipmentFilterData(cloneGetPlanerData);
    setShowHeaderFilters(showData);
    setDateRangeStopPoint(dates);
    setFilter((prev) => {
      return {
        ...prev,
        stopPointAll: {
          ...prev.stopPointAll,
          [STOP_POINT_FROM]: { ...prev.stopPointAll[STOP_POINT_FROM], searchValue: formatDateFirst },
          [STOP_POINT_TO]: {
            ...prev.stopPointAll[STOP_POINT_TO],
            searchValue: isValidLast ? formatDateLast : '',
          },
        },
      };
    });
    getShipment({ allFilterData });
  }

  /** Stop-point-show-data-Delete * */
  function onDeleteStopPointItem(item) {
    const newShowData = [];
    const cloneGetShipmentData = { ...getShipmentFilterData };
    showHeaderFilters.forEach((el) => {
      if (Array.isArray(el) && el[0]?.type === 'stop_point') {
        let newStopPointArr;
        if (item?.value === 'stop_point') {
          newStopPointArr = el.filter((point) => point.value !== item.value);
          delete cloneGetShipmentData[item.value];
          setFilter((prevState) => {
            return {
              ...prevState,
              stopPointAll: {
                ...filter.stopPointAll,
                stop_point: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point,
              },
            };
          });
        } else {
          newStopPointArr = el.filter((point) => point?.value === 'stop_point');
          delete cloneGetShipmentData.stop_point_scheduled_from;
          delete cloneGetShipmentData.stop_point_scheduled_to;
          setDateRangeStopPoint([null, null]);
          setFilter((prevState) => {
            return {
              ...prevState,
              stopPointAll: {
                ...filter.stopPointAll,
                stop_point_scheduled_from: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point_scheduled_from,
                stop_point_scheduled_to: LOCATION_INITIAL_VALUE_STOP_POINT.stop_point_scheduled_to,
              },
            };
          });
        }
        return newStopPointArr.length > 0 && newShowData.push(newStopPointArr);
      }
      newShowData.push(el);
    });
    setShowHeaderFilters(newShowData);
    setGetShipmentFilterData(cloneGetShipmentData);

    const allFilterData = { ...cloneGetShipmentData, ...activeTabData?.data?.filters };
    getShipment({ allFilterData });
  }

  /** Location-inputs * */
  const debounceLocation = useMemo(() => {
    return debounce((value, name) => {
      const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
      const showData = [...showHeaderFilters];
      const index_show_location = showData.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === 'locations');
      const showLocationObj = {
        key: uuid(),
        searchValue: value,
        title: 'Location',
        valuePrefix: 'locations',
        value: name,
      };

      if (index_show_location === -1) {
        showData.push([showLocationObj]);
      } else {
        const index_loc_item = showData[index_show_location].findIndex((loc) => loc.value === name);
        if (!value) {
          showData[index_show_location].length === 1
            ? showData.splice(index_show_location, 1)
            : showData[index_show_location].splice(index_loc_item, 1);
        } else {
          index_loc_item === -1
            ? showData[index_show_location].push(showLocationObj)
            : (showData[index_show_location][index_loc_item] = showLocationObj);
        }
      }

      if (!value) {
        delete allFilterData[name];
        setGetShipmentFilterData({ ...allFilterData });
      } else {
        allFilterData[name] = value;
        setGetShipmentFilterData({ ...getShipmentFilterData, [name]: value });
      }

      setShowHeaderFilters([...showData]);

      if (controller) controller.abort();
      controller = new AbortController();
      getShipment({ allFilterData, signal: controller.signal });
    }, 350);
  }, [activeTabData, getShipmentFilterData, showHeaderFilters]);

  function onChangeLocationValues(name, e) {
    const value = { ...filter?.locations, [name]: { ...filter?.locations[name], searchValue: e.target.value } };
    setFilter({ ...filter, [filter.searchBy.value]: value });
    debounceLocation(e.target.value, name);
  }

  /** Location-inputs and date delete * */
  function onDeleteLocationsItems(item) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    if (item.value === 'locations') {
      delete allFilterData?.scheduled_from;
      delete allFilterData?.scheduled_to;
      setDateRangeLocation([null, null]);
    } else {
      delete allFilterData?.[item.value];
    }

    const locations = filter.locations;
    const newLocation = {};

    Object.keys(locations).forEach((key) => {
      const obj = locations[key];

      if (key === item.value) {
        if (item.value === 'locations') {
          newLocation.scheduled_from = { ...obj, value: 'scheduled_from', searchValue: '' };
          newLocation.scheduled_to = { ...obj, value: 'scheduled_to', searchValue: '' };
        } else {
          newLocation[key] = { ...obj, searchValue: '' };
        }
      } else {
        newLocation[key] = { ...obj };
      }
    });
    const newShowFilterData = [];
    showHeaderFilters.forEach((el) => {
      if (Array.isArray(el)) {
        const locData = el.filter((loc) => loc.value !== item.value);
        locData.length > 0 && newShowFilterData.push(locData);
      } else {
        newShowFilterData.push(el);
      }
    });

    getShipment({ allFilterData }).then(() => {
      if (item.value === 'locations') setDateRangeLocation([null, null]);
      setFilter((prev) => ({ ...prev, locations: newLocation }));
      setShowHeaderFilters(newShowFilterData);
      setGetShipmentFilterData(allFilterData);
    });
  }

  /** Location-date-picker * */
  function onChangeLocationDatePicker(dates) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };
    const TYPE = 'locations';
    const first = dates[0];
    const last = dates[1];
    const SCHEDULED_FROM = 'scheduled_from';
    const SCHEDULED_TO = 'scheduled_to';
    const formatDateFirst = moment(first).format('MM/DD/YYYY');
    const formatDateLast = moment(last).format('MM/DD/YYYY');
    const isValidLast = !!moment(last).isValid();

    const cloneGetPlanerData = { ...getShipmentFilterData };
    const showData = [...showHeaderFilters];

    const index_show_location = showData.findIndex((el) => Array.isArray(el) && el?.[0]?.valuePrefix === TYPE);
    const showLocationObj = {
      key: uuid(),
      title: 'Location',
      value: TYPE,
      from: formatDateFirst,
      valuePrefix: TYPE,
      to: isValidLast ? formatDateLast : '',
      searchValue: `${formatDateFirst} ${isValidLast ? `- ${formatDateLast}` : ''}`,
    };

    if (index_show_location === -1) {
      showData.push([showLocationObj]);
    } else {
      const index_loc_item = showData[index_show_location].findIndex((loc) => loc.value === TYPE);
      index_loc_item === -1
        ? showData[index_show_location].push(showLocationObj)
        : (showData[index_show_location][index_loc_item] = showLocationObj);
    }

    if (isValidLast) {
      cloneGetPlanerData[SCHEDULED_TO] = formatDateLast;
      allFilterData[SCHEDULED_TO] = formatDateLast;
    } else {
      delete cloneGetPlanerData[SCHEDULED_TO];
      delete allFilterData?.[SCHEDULED_TO];
    }

    cloneGetPlanerData[SCHEDULED_FROM] = formatDateFirst;
    allFilterData[SCHEDULED_FROM] = formatDateFirst;

    setGetShipmentFilterData(cloneGetPlanerData);
    setShowHeaderFilters(showData);
    setDateRangeLocation(dates);
    setFilter((prev) => {
      return {
        ...prev,
        locations: {
          ...prev.locations,
          [SCHEDULED_FROM]: { ...filter?.locations[SCHEDULED_FROM], searchValue: formatDateFirst },
          [SCHEDULED_TO]: { ...filter?.locations[SCHEDULED_TO], searchValue: isValidLast ? formatDateLast : '' },
        },
      };
    });
    getShipment({ allFilterData });
  }

  function onChangeEquipment(value) {
    const allFilterData = { ...getShipmentFilterData, ...activeTabData?.data?.filters };

    const equipmentSelectedData = [...filter.equipmentSelectedData];
    const foundedIndex = equipmentSelectedData.findIndex((data) => data.id === value?.id);
    foundedIndex !== -1 ? equipmentSelectedData.splice(foundedIndex, 1) : equipmentSelectedData.push(value);

    const idsEquipment = equipmentSelectedData.map((el) => el?.id);
    allFilterData.equipment = idsEquipment;

    // show_data
    const showData = [...showHeaderFilters];
    const indexShowCustomer = showData.findIndex((el) => Number(el?.id) === +filter.searchBy.id);
    const showCustomerObj = { id: 666, key: uuid(), title: 'Vehicle ID', value: 'equipment' };

    if (indexShowCustomer !== -1) {
      showCustomerObj.searchValue = [...equipmentSelectedData];
      showData[indexShowCustomer] = showCustomerObj;
    } else {
      showCustomerObj.searchValue = [{ ...value }];
      showData.push(showCustomerObj);
    }
    if (equipmentSelectedData.length === 0) showData.splice(indexShowCustomer, 1);
    setShowHeaderFilters(showData);
    // show_data

    setGetShipmentFilterData({ ...getShipmentFilterData, equipment: idsEquipment });
    setFilter({ ...filter, equipmentSelectedData });
    getShipment({ allFilterData });
  }

  /** Clear All * */
  function onReset() {
    const resetDaya = {
      ...filter,
      allValue: '',
      selectedValues: [],
      locations: LOCATION_INITIAL_VALUE,
      customerSelectData: [],
      equipmentSelectedData: [],
      stopPointAll: LOCATION_INITIAL_VALUE_STOP_POINT,
    };
    setTriggerAdvancedClear(new Date());
    setShowHeaderFilters([]);
    setGroups([]);
    setStatuses([]);
    setEquipments([]);
    setLocalFilterCheckboxes({ status: [], groups: [], equipment_types: [] });
    setGetShipmentFilterData({});
    getShipment({ allFilterData: { ...activeTabData?.data?.filters }, reset: resetDaya });
    setDateRangeLocation([null, null]);
    setDateRangeStopPoint([null, null]);

    setStopPointCondition(true);
    setTimeout(() => {
      setStopPointCondition(false);
    }, 0);
  }

  /** Filter * */

  useEffect(() => {
    getFilterByGroups();
    getFilterByEquipmentType().then();
    getFilterCustomerData();
    getFilterStopPointData();
    getFilterEquipmentData();
    // fetchRecentActivity()
  }, []);

  useEffect(() => {
    !showModal && setTabModalEditData({});
  }, [showModal]);

  useEffect(async () => {
    getSettings();
    setLoading(true);
    const widths = await getColumnsWidth('shipment_audit');
    if (widths.data.length) {
      const updatedWidthsFromBackend = widths.data.reduce((acc, curr) => {
        acc[curr.column_type] = curr.width;
        return acc;
      }, {});
      setColumnWidths({
        ...DEFAULTWIDTHOFCOLUMNSAUDIT,
        ...updatedWidthsFromBackend,
      });
    }
    Shipment({ page: 1, itemsPerPage: rowPerPage, status_ids: statuses, allFilterData: { status: [1] } })
      .then((res) => {
        setShipmentsData(res);
        if (filter.filterTableTop.length === 0) {
          getShipmentTabs({ type: 'shipment_audit' }).then((tabs) => {
            setFilter({ ...filter, filterTableTop: tabs });
          });
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const clearShipment = (data) => {
    clearShipmentAuditData({ shipment_id: [data?.shipment_id] })
      .then(() => {
        getShipmentsAndTabs();
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) });
      });
  };

  const revertShipment = (data) => {
    clearShipmentAuditData({ revert_shipments: [data?.shipment_id] })
      .then(() => {
        showToaster({ type: 'success', message: 'Shipment has been reverted successfully!' });
        getShipmentsAndTabs();
      })
      .catch((error) => {
        showToaster({ type: 'error', message: getErrorMessage(error) });
      });
  };

  const getShipmentsAndTabs = () => {
    const allFilterData = {
      status: [1],
    };
    getShipment({ allFilterData }).then(() => {
      setActiveTabData(null);
      getShipmentTabs({ type: 'shipment_audit' }).then((tabs) => {
        setFilter({ ...filter, filterTableTop: tabs });
      });
    });
  };

  return (
    <>
      <TableShipmentsContext.Provider
        value={{
          filter,
          dragItem,
          customers,
          tableColumn,
          getSettings,
          updateFilter,
          stopPoints,
          stopPointCondition,
          equipmentData,
          switchRadioButtons,
          multiSelectOptions,
          onChangeMultiselectSearchBy,
          dateRangeLocation,
          setDateRangeLocation,
          dateRangeStopPoint,
          setDateRangeStopPoint,
          onsStTableColumn: (v) => setTableColumn(v),
          onChangeOrder: (items) => setDragItem(items),
          onSetSwitchRadioButtons: (v) => setSwitchRadioButtons(v),
          /** Filter-helpers* */
          onChangeAll,
          onChangeCustomers,
          onChangeEquipment,
          onChangeLocationValues,
          onChangeLocationDatePicker,
          onChangeDatePickerStopPoint,
          onChangeStopPointSelect,
          onDeleteStopPointSelect,
          /** table show data* */
          filterByGroupsData,
          onDeleteManyDataItem,
          localFilterCheckboxes,
          onDeleteStopPointItem,
          onDeleteLocationsItems,
          setEquipments,
          disabledFilters,
          onApplyFilterCheckboxes,
          setLocalFilterCheckboxes,
          filterByEquipmentTypeData,
          groups,
          onReset,
          loading,
          triggerAdvancedClear,
          statuses,
          setGroups,
          equipments,
          showHeaderFilters,
          setStatuses,
          audit,
        }}
      >
        <div className={classes.shipmentsContainer}>
          <div className='d-flex align-items-center justify-content-between'>
            <HeaderCompletedShipments />
            <ExportCsv type={activeTabData?.label === 'Audited' ? 'shipment-audit-cleared' : 'shipment-audit'} />
          </div>

          <ShipmentAuditFilter />

          {activeTabData?.label === 'Audit Required' && !loading && (
            <ClearShipments
              getShipments={getShipmentsAndTabs}
              setMasterChecked={setMasterChecked}
              shipmentData={shipmentsData?.data}
            />
          )}
          {activeTabData?.label === 'Audited' && !loading && (
            <RevertShipments
              getShipments={getShipmentsAndTabs}
              setMasterChecked={setMasterChecked}
              shipmentData={shipmentsData?.data}
            />
          )}

          <div className={classes.switchRadioWrapper}>
            <div className='mt-3'>
              <SwitchRadio
                plus
                type='tab'
                items={filter.filterTableTop}
                onChange={onChangeTab}
                onEdit={onEditTableTab}
                name='tableTopTabMenu'
                value={activeTabData?.id}
                onClickPlus={() => setShowModal(true)}
              />
            </div>
          </div>
          <CustomModal
            footer={false}
            filter={filter}
            showModal={showModal}
            headerTitle='Add Tab'
            styleBody={{ width: 446 }}
            updateFilter={updateFilter}
          >
            <ShipmentModalContent
              filter={filter}
              customers={customers}
              typeofDataJson='shipment_audit'
              stopPointsData={stopPoints}
              updateFilter={updateFilter}
              initialValue={tabModalEditData}
              onClickButtons={onClickModalButtons}
              filterByGroupsData={filterByGroupsData}
              filterByEquipmentTypeData={filterByEquipmentTypeData}
            />
          </CustomModal>

          <div className={classes.tableDrawerWrapper}>
            {loading ? (
              <EquipmentPreloaderSkeleton />
            ) : (
              <div className='planner_table_wrapper'>
                <MaterialTable
                  columns={filterTableColumn(columns)}
                  data={shipmentsData.data}
                  onRowClick={onRowClick}
                  actions={[
                    (rowData) => ({
                      icon: () => {
                        return (
                          <div
                            className=''
                            style={{ width: '100%', backgroundColor: 'red' }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Dropdown className='driver-document-document'>
                              <Dropdown.Toggle className='menu-drop' id='dropdown-basic'>
                                <img src={More} alt='' className='hidden icon' />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='dropdown-menu-contain' rootCloseEvent='mousedown'>
                                {!rowData.audited ? (
                                  <Dropdown.Item
                                    className='drop-text-contain'
                                    onClick={() => {
                                      clearShipment(rowData);
                                    }}
                                  >
                                    <p className='edit-text'>Mark As Audited</p>
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    className='drop-text-contain'
                                    onClick={() => {
                                      revertShipment(rowData);
                                    }}
                                  >
                                    <p className='edit-text'>Revert Audited</p>
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        );
                      },
                    }),
                  ]}
                  style={{
                    backgroundColor: use(palette.white, palette.dark800),
                  }}
                  options={{
                    tableLayout: 'fixed',
                    toolbar: false,
                    sorting: false,
                    pageSize: shipmentsData.data.length,
                    pageSizeOptions: [25, 50, 100, 250, 500],
                    actionsColumnIndex: -1,
                  }}
                  components={{
                    Header: ({ columns }) => {
                      return (
                        <thead>
                          <tr>
                            {columns.map((column) => (
                              <th
                                key={column.field}
                                style={{
                                  width: column.width,
                                  borderRight: column.resizable ? '1px solid #ddd' : '0px',
                                  boxSizing: 'border-box',
                                  padding: '4px 0 4px 12px',
                                }}
                              >
                                <div className='position-relative'>
                                  <div className='resizable-column-header'>{column.title}</div>
                                  {!!column.resizable && (
                                    <div
                                      style={{
                                        cursor: 'col-resize',
                                        position: 'absolute',
                                        right: '-2px',
                                        top: 0,
                                        bottom: 0,
                                        width: 5,
                                        zIndex: 10,
                                      }}
                                      onMouseDown={handleMouseDown(column.field)}
                                    />
                                  )}
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                      );
                    },
                    Pagination: PaginationComponent,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </TableShipmentsContext.Provider>
      {!!shipmentToEditDriverPay && (
        <DriverPay
          open={!!shipmentToEditDriverPay}
          onClose={() => setShipmentToEditDriverPay(null)}
          onSuccess={getShipment}
          shipment={shipmentToEditDriverPay}
        />
      )}

      <Popover
        id={Popup}
        anchorPosition={{ top: 200, left: 400 }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SenderInfo sendMessage senderData={senderPopupData} driver />
      </Popover>
    </>
  );
};

export default CompletedShipments;
