import React from 'react';
import moment from 'moment';
import ColumnHeader from 'common/ColumnHeader';
import { palette } from 'utils/constants';
import TableAccordion from './components/TableAccordion';
import { STableHeaderWrapper } from './PreviousEmployment.styles';

export const useColumns = ({ toggleAccordion, userType, onEditEmployer, deleteEmployer, deleteAttempt }) => {
  return [
    {
      field: 'name',
      title: (
        <STableHeaderWrapper>
          <ColumnHeader title='EMPLOYER NAME' field='name' />
          <ColumnHeader title='REQUEST DATE' field='request_date' />
          <ColumnHeader title='START-END DATE' field='start_date' />
          <ColumnHeader title='REQUEST TYPE' field='request_type' />
          <ColumnHeader title='TRANSMIT METHOD' field='request_form_source' />
          <ColumnHeader title='RESPONSE STATUS' field='response' />
          <ColumnHeader title='RESPONSE FORM' field='response_path' />
          <div />
        </STableHeaderWrapper>
      ),
      cellStyle: (e, row) => (row.isActive ? { backgroundColor: palette.indigo0 } : {}),
      render: (row) => (
        <TableAccordion
          row={row}
          userType={userType}
          onEditEmployer={onEditEmployer}
          deleteEmployer={deleteEmployer}
          deleteAttempt={deleteAttempt}
          toggleAccordion={toggleAccordion}
        />
      ),
    },
  ];
};

export const getLatestAttempt = (array, field) => {
  if (!array?.length) {
    return null;
  }

  let latestItem = array[0];
  let latestDate = moment(latestItem[field]);

  for (let i = 1; i < array.length; i++) {
    const currentDate = moment(array[i][field]);
    if (currentDate.isAfter(latestDate)) {
      latestItem = array[i];
      latestDate = currentDate;
    }
  }

  return latestItem;
};
