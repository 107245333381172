import React from 'react';
import { InputWithText } from 'common/Input';
import { Typography } from 'components/Typography';
import { DatePicker } from 'common/Pickers';
import { formatNumber } from 'utils/constants';
import { getQuoteStartEndDate, getQuoteTotalDistance } from 'componentsV2/Planner/Quotes/CreateQuote/CreateQuote.data';

const Schedule = ({ form }) => {
  const { values } = form;

  const { startDate, endDate, totalDuration } = getQuoteStartEndDate(values);
  const totalDistance = getQuoteTotalDistance(values);

  return (
    <div className='w-100'>
      <div className='mb-2'>
        <Typography variant='s1'>Schedule</Typography>
      </div>
      <div className='create-quote-section'>
        <div className='d-flex gap-3'>
          <InputWithText
            width='80px'
            label='TOTAL DURATION'
            labelProps={{ variant: 'c3' }}
            text='Days'
            name='total_duration'
            value={formatNumber(totalDuration / 60 / 24)}
            disabled
          />
          <DatePicker
            label='START DATE'
            labelProps={{ variant: 'c3' }}
            name='start_date'
            value={startDate || null}
            disabled
          />
          <DatePicker
            label='END DATE'
            labelProps={{ variant: 'c3' }}
            name='end_date'
            value={endDate || null}
            disabled
          />
          <InputWithText
            width='80px'
            label='TOTAL DISTANCE'
            labelProps={{ variant: 'c3' }}
            text='Miles'
            name='total_distance'
            value={Math.round(totalDistance || 0)}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
