import React from 'react';
import { Typography } from 'components/Typography';
import PayTypeChip from '../../../../shared/PayTypeChip';
import { SPayTypeWrapper } from '../UserInfo.styles';

const payOption = {
  mile: 'Per Mile',
  percentage: 'By Percentage',
  per_hour: 'Per Hour',
  flat_rate: 'Flat Rate',
};

const PayType = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <SPayTypeWrapper>
      <Typography variant='s2'>{payOption[data.pay_option] || data.pay_option}</Typography>
      {data.pay_option === 'mile' && <PayTypeChip data={data} />}
    </SPayTypeWrapper>
  );
};

export default PayType;
