import React, { useEffect, useMemo, useState } from 'react';
import { FastField, Field, Form, Formik } from 'formik';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomTextArea from 'components/CustomTextArea';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { CreateAccountingSettings, GetCompanyProfile, GetSettingsAccounting } from 'Api/CompanySettings';
import PhoneInput from 'common/PhoneInput';
import { validationSchema } from './validationSchema';
import styles from './Accounting.module.css';

const Accounting = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const showToaster = useShowToaster();
  const [accountData, setAccountData] = useState(null);
  const [companyWebsite, setCompanyWebsite] = useState(null);
  const [billingPhone, setBillingPhone] = useState(null);
  const companyEin = JSON.parse(localStorage.getItem('ein'));

  const getAccountData = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountData(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCompanyProfile = () => {
    return GetCompanyProfile().then((res) => {
      setCompanyWebsite(res?.company_website);
      setBillingPhone(res?.phone_number);
    });
  };

  useEffect(() => {
    getCompanyProfile().then();
    getAccountData();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleSubmit = (values) => {
    CreateAccountingSettings({
      invoice_no: values.invoice_no,
      is_combined: values.is_combined ? 1 : 0,
      quote_id: values.quote_id,
      payable_email: values.payable_email,
      receivable_email: values.receivable_email,
      show_website: values.show_website ? 1 : 0,
      show_billing: values.show_billing ? 1 : 0,
      website: values.website || null,
      billing_email: values.billing_email || null,
      billing_tel: values.billing_tel || null,
      invoice_terms: values.invoice_terms || null,
      ein: values.ein || null,
      state_identification_number: values.state_identification_number || null,
      irs_account_number: values.irs_account_number || null,
    }).then((res) => {
      if (res) {
        showToaster({ type: 'success', message: 'Accounting has been successfully updated!' });
        localStorage.removeItem('ein');
        getAccountData();
        if (isConfiguringStep) {
          setTimeout(() => {
            onSaveAndNext('saveAndNext');
          }, 1000);
        }
      }
    });
  };

  const initialValue = useMemo(() => {
    const {
      invoice_no,
      is_combined,
      quote_id,
      payable_email,
      receivable_email,
      show_website,
      website,
      show_billing,
      billing_email,
      billing_tel,
      invoice_terms,
      ein,
      state_identification_number,
      irs_account_number,
    } = accountData || {};
    return {
      invoice_no: invoice_no || '',
      is_combined: isConfiguringStep && !accountData ? 1 : is_combined || 0,
      quote_id: quote_id || '',
      payable_email: payable_email || '',
      receivable_email: receivable_email || '',
      show_website: show_website ? !!show_website : false,
      website: website || companyWebsite || '',
      show_billing: show_billing ? !!show_billing : false,
      billing_email: billing_email || '',
      billing_tel: billing_tel || billingPhone || '',
      invoice_terms: invoice_terms || '',
      ein: ein || companyEin || '',
      state_identification_number: state_identification_number || '',
      irs_account_number: irs_account_number || '',
    };
  }, [accountData, companyWebsite, billingPhone]);

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={initialValue}
      validationSchema={validationSchema(isConfiguringStep)}
    >
      {({ validateField, getFieldHelpers, ...props }) => {
        return (
          <AccountingForm
            {...props}
            validateField={validateField}
            getFieldHelpers={getFieldHelpers}
            accountData={accountData}
            isConfiguringStep={isConfiguringStep}
            onSaveAndNext={onSaveAndNext}
            onCancelPrevPage={onCancelPrevPage}
          />
        );
      }}
    </Formik>
  );
};

function AccountingForm({ values, submitForm, setFieldValue, errors, touched, isConfiguringStep, onCancelPrevPage }) {
  const { use } = useTheme();

  const validateTextArea = (value) => {
    let error;
    if (value?.length > 200) {
      error = 'Characters maximum counts is 200';
    }
    if (error) {
      setFieldValue('invoice_terms', values?.invoice_terms?.substring(0, values?.invoice_terms.length));
    }
    return error;
  };

  return (
    <Form>
      <div className={styles.accounting_wrapper}>
        <div className={styles.accounting_header}>
          <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
            Accounting
          </Typography>
          <div className={styles.actions}>
            {isConfiguringStep && (
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
            )}
            <CustomButton
              onClick={submitForm}
              type='primary'
              title={isConfiguringStep ? 'Save and Next' : 'Save'}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ margin: 0 }}
            />
          </div>
        </div>
        <div className={styles.basic1}>
          <div className={styles.left1}>
            <Typography variant='s2'>Invoice Starting Number</Typography>
          </div>
          <div className={styles.right}>
            <FastField
              name='invoice_no'
              type='number'
              component={CustomInput}
              onKeyDown={(e) => {
                blockNotNumberChars(e);
                blockNonPositiveNumbers(e);
              }}
              leftIcon={<p style={{ margin: 0, padding: 3 }}>#</p>}
              style={{
                margin: '0 !important',
                width: 320,
                height: 32,
                padding: '6px 24px',
                borderRadius: 6,
              }}
            />
            <ErrorMessage error={touched.invoice_no && errors.invoice_no} />

            <label>
              <Field name='is_combined'>
                {({ field }) => {
                  return (
                    <CustomCheckbox
                      field={field}
                      checked={!!field.value}
                      name='is_combined'
                      withinForm
                      onChange={() => {
                        setFieldValue('is_combined', +!values.is_combined);
                      }}
                    >
                      <Typography
                        variant='s2'
                        style={{
                          color: use(palette.gray700, palette.gray200),
                          marginLeft: 5,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Combine Shipment Number with Invoice Number
                      </Typography>
                    </CustomCheckbox>
                  );
                }}
              </Field>
            </label>

            <Typography variant='b2' style={{ color: use(palette.gray500, palette.gray200), margin: '8px 0 0 22px' }}>
              i.e. {values?.invoice_no}-Shipment#
            </Typography>
          </div>
        </div>
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Typography variant='s2'>Quote Starting Number</Typography>
          </div>
          <div className={styles.right}>
            <FastField
              name='quote_id'
              type='number'
              component={CustomInput}
              onKeyDown={(e) => {
                blockNotNumberChars(e);
                blockNonPositiveNumbers(e);
              }}
              leftIcon={<p style={{ margin: 0, padding: 3 }}>#</p>}
              style={{
                margin: '0 !important',
                width: 320,
                height: 32,
                padding: '6px 24px',
                borderRadius: 6,
              }}
            />
            <ErrorMessage error={touched.quote_id && errors.quote_id} />
          </div>
        </div>
        {!isConfiguringStep && (
          <>
            <div className={styles.basic}>
              <div className={styles.left1}>
                <Typography variant='s2'>EIN</Typography>
              </div>
              <div className={styles.right}>
                <FastField
                  name='ein'
                  type='text'
                  component={CustomInput}
                  placeholder='EIN'
                  style={{
                    margin: '0 !important',
                    width: 320,
                    height: 32,
                    padding: '6px 12px',
                    borderRadius: 6,
                  }}
                />
                <ErrorMessage error={touched.ein && errors.ein} />
              </div>
            </div>
            <div className={styles.basic}>
              <div className={styles.left1}>
                <Typography variant='s2'>State Identification Number</Typography>
              </div>
              <div className={styles.right}>
                <FastField
                  name='state_identification_number'
                  type='text'
                  component={CustomInput}
                  placeholder='State Identification Number'
                  style={{
                    margin: '0 !important',
                    width: 320,
                    height: 32,
                    padding: '6px 12px',
                    borderRadius: 6,
                  }}
                />
              </div>
            </div>
            <div className={styles.basic}>
              <div className={styles.left1}>
                <Typography variant='s2'>IRS Account Number</Typography>
              </div>
              <div className={styles.right}>
                <FastField
                  name='irs_account_number'
                  type='text'
                  component={CustomInput}
                  placeholder='IRS Account Number'
                  style={{
                    margin: '0 !important',
                    width: 320,
                    height: 32,
                    padding: '6px 12px',
                    borderRadius: 6,
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Typography variant='s2'>Accounts Payable Email</Typography>
          </div>
          <div className={styles.right}>
            <FastField
              name='payable_email'
              type='text'
              component={CustomInput}
              style={{
                margin: '0 !important',
                width: 320,
                height: 32,
                padding: '6px 12px',
                borderRadius: 6,
              }}
            />
            <ErrorMessage error={touched.payable_email && errors.payable_email} />
          </div>
        </div>
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Typography variant='s2'>Accounts Receivable Email</Typography>
          </div>
          <div className={styles.right}>
            <FastField
              name='receivable_email'
              type='text'
              component={CustomInput}
              onChange={(e) => {
                setFieldValue('receivable_email', e.target.value);
                setFieldValue('billing_email', e.target.value);
              }}
              style={{
                margin: '0 !important',
                width: 320,
                height: 32,
                padding: '6px 12px',
                borderRadius: 6,
              }}
            />
            <ErrorMessage error={touched.receivable_email && errors.receivable_email} />
          </div>
        </div>
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Typography variant='s2'>Billing Phone Number</Typography>
          </div>
          <div className={styles.right}>
            <PhoneInput
              name='billing_tel'
              placeholder='+1 (555) 555-1234'
              onChange={(val) => setFieldValue('billing_tel', val)}
              style={{ width: '320px' }}
              value={values.billing_tel}
              error={touched.billing_tel && errors.billing_tel}
            />
          </div>
        </div>
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Field name='show_website'>
              {({ field }) => {
                return (
                  <label className={styles.switch_label}>
                    <CustomCheckbox
                      name='show_website'
                      field={field}
                      type='switch'
                      withinForm
                      smail={false}
                      checked={!!field.value}
                    />
                    <Typography
                      variant='s2'
                      style={{
                        color: use(palette.gray700, palette.gray200),
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Show website on invoices
                    </Typography>
                  </label>
                );
              }}
            </Field>
          </div>
          <div className={styles.right}>
            <Field
              name='website'
              type='text'
              disabled={!values?.show_website}
              component={CustomInput}
              placeholder='www.website.com'
              leftIcon='https://'
              leftIconStyle={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 14,
                color: '#687182',
                borderTopLeftRadius: 6,
                borderBottomLeftRadius: 6,
                justifyContent: 'center',
                backgroundColor: '#F7F9FC',
                maxWidth: '74px',
                width: '100%',
                height: 32,
                left: 0,
              }}
              style={{
                margin: '0 !important',
                width: 440,
                height: 32,
                padding: '6px 8px 6px 86px',
                borderRadius: 6,
              }}
            />
          </div>
        </div>
        <div className={styles.basic}>
          <div className={styles.left1}>
            <Field name='show_billing'>
              {({ field }) => {
                return (
                  <label className={styles.switch_label}>
                    <CustomCheckbox
                      name='show_billing'
                      field={field}
                      type='switch'
                      withinForm
                      smail={false}
                      checked={!!field.value}
                    />
                    <Typography
                      variant='s2'
                      style={{
                        color: use(palette.gray700, palette.gray200),
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Show billing email on invoices
                    </Typography>
                  </label>
                );
              }}
            </Field>
          </div>
          <div className={styles.right}>
            <Field
              name='billing_email'
              type='text'
              component={CustomInput}
              disabled={!values?.show_billing}
              style={{
                margin: '0 !important',
                width: 320,
                height: 32,
                padding: '6px 12px',
                borderRadius: 6,
              }}
            />
          </div>
        </div>
        <div className={styles.basic1}>
          <div className={styles.left1}>
            <Typography variant='s2'>Invoice Terms</Typography>
          </div>
          <div className={styles.right}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <FastField
                name='invoice_terms'
                component={CustomTextArea}
                validate={validateTextArea}
                style={{ height: 74, width: 616 }}
              />
              <ErrorMessage
                name='invoice_terms'
                render={(error) => (
                  <Typography variant='c2' style={{ color: use(palette.red500, palette.red800) }}>
                    {error}
                  </Typography>
                )}
              />
            </div>
            <Typography variant='c1' style={{ color: use(palette.gray500, palette.gray200) }}>
              {200 - (values?.invoice_terms?.length || 0)} characters left
            </Typography>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default Accounting;
