export const initialValues = {
  shipment_id: '',
  reference_id: '',
  group: [],
  statuses: [],
  stop_points: [],
  customers: [],
  carriers: [],
  drivers: [],
  origin_city: '',
  origin_state: '',
  origin_zipcode: '',
  origin_miles: '',
  destination_city: '',
  destination_state: '',
  destination_miles: '',
  destination_zipcode: '',
  ediStatus: [],
  offer_to_drivers: [],
  offer_to_carriers: [],
};
