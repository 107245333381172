// Cost Estimate
import { mixed, number, object, string } from 'yup';

export const calcDriverCosts = (data) => {
  return data.reduce((acc, cur) => {
    if (cur.flatRateChecked && cur.flatRate) {
      acc += Number(cur.flatRate);
    } else {
      acc += Number(cur.driver1_cost || 0) + Number(cur.driver2_cost || 0);
    }

    return acc;
  }, 0);
};
export const calcEstimatedFuelCosts = (data) => {
  return data.reduce((acc, cur) => {
    acc += Number(cur.estimated_fuel_cost) || 0;

    return acc;
  }, 0);
};
export const calcEstimatedCosts = (data) => {
  return data.reduce((acc, cur) => {
    if (cur.flatRateChecked && cur.flatRate) {
      acc += Number(cur.flatRate) + Number(cur.estimated_fuel_cost);
    } else {
      acc += Number(cur.cost || 0);
    }

    return acc;
  }, 0);
};

// Net Profit Per Mile
export const calcGrossShipmentChargesPerMile = (data) => {
  return data.reduce((acc, cur) => {
    acc += Number(cur.total_amount || 0) / (Number(cur.empty_miles || 0) + Number(cur.loaded_miles || 0));

    return acc;
  }, 0);
};
export const calcShipmentCostsPerMile = (data) => {
  return data.reduce((acc, cur) => {
    if (cur.flatRateChecked && cur.flatRate) {
      acc +=
        (Number(cur.flatRate) + Number(cur.estimated_fuel_cost)) /
        (Number(cur.empty_miles || 0) + Number(cur.loaded_miles || 0));
    } else {
      acc += Number(cur.cost || 0) / (Number(cur.empty_miles || 0) + Number(cur.loaded_miles || 0));
    }

    return acc;
  }, 0);
};
export const calcEstimatedNetProfitPerMile = (data) => {
  return data.reduce((acc, cur) => {
    if (cur.flatRateChecked && cur.flatRate) {
      acc +=
        (Number(cur.total_amount || 0) - (Number(cur.flatRate) + Number(cur.estimated_fuel_cost || 0))) /
        (Number(cur.empty_miles || 0) + Number(cur.loaded_miles || 0));
    } else {
      acc += Number(cur.net_profit || 0) / (Number(cur.empty_miles || 0) + Number(cur.loaded_miles || 0));
    }

    return acc;
  }, 0);
};

// Net Profit
export const calcGrossShipmentCharges = (data) => {
  return data.reduce((acc, cur) => {
    acc += Number(cur.total_amount || 0);

    return acc;
  }, 0);
};
export const calcShipmentCosts = (data) => {
  return data.reduce((acc, cur) => {
    if (cur.flatRateChecked && cur.flatRate) {
      acc += Number(cur.flatRate) + Number(cur.estimated_fuel_cost || 0);
    } else {
      acc += Number(cur.cost || 0);
    }

    return acc;
  }, 0);
};
export const calcEstimatedNetProfit = (data) => {
  return data.reduce((acc, cur) => {
    if (cur.flatRateChecked && cur.flatRate) {
      acc += Number(cur.total_amount || 0) - (Number(cur.flatRate) + Number(cur.estimated_fuel_cost || 0));
    } else {
      acc += Number(cur.net_profit || 0);
    }

    return acc;
  }, 0);
};

export const transformTimeStringToNumber = (timeString) => {
  const [hoursPart, minutesPart] = timeString.split(' ');

  const hours = parseInt(hoursPart, 10);
  const minutes = parseInt(minutesPart, 10);

  const decimalMinutes = minutes / 60;

  const result = hours + decimalMinutes;

  return Number(result.toFixed(2));
};

export const getCostFormInitialValues = (data, flatRate, flatRateChecked) => {
  const { id, compensation_details, fname, lname } = data?.equipment?.drivers[0] || {};

  if (flatRateChecked) {
    return [
      {
        id: Date.now(),
        addition: { addition_type: 'Flat Rate' },
        driver_id: id,
        driver: `${fname} ${lname}`,
        qty: 1,
        rate: Number(flatRate) || 0,
        type: 'flat_rate',
        total: Number(flatRate) || 0,
        isDefault: true,
      },
    ];
  }

  if (compensation_details?.pay_option === 'mile') {
    return [
      {
        id: Date.now(),
        addition: { addition_type: 'Empty Miles (Per Mile)' },
        driver_id: id,
        driver: `${fname} ${lname}`,
        qty: Number(Number(data?.empty_miles)?.toFixed()),
        rate: Number(compensation_details?.mile_empty_amt),
        type: 'empty_miles',
        total: (Number(data?.empty_miles?.toFixed()) * Number(compensation_details?.mile_empty_amt))?.toFixed(2),
        isDefault: true,
      },
      {
        id: Date.now() + 1,
        addition: { addition_type: 'Loaded Miles (Per Mile)' },
        driver_id: id,
        driver: `${fname} ${lname}`,
        qty: Number(Number(data?.loaded_miles)?.toFixed()),
        rate: Number(compensation_details?.mile_loaded_amt),
        type: 'loaded_miles',
        total: (Number(data?.loaded_miles?.toFixed()) * Number(compensation_details?.mile_loaded_amt))?.toFixed(2),
        isDefault: true,
      },
    ];
  }

  if (compensation_details?.pay_option === 'per_hour') {
    return [
      {
        id: Date.now(),
        addition: { addition_type: 'Per Hour' },
        driver_id: id,
        driver: `${fname} ${lname}`,
        qty: transformTimeStringToNumber(data?.total_eta),
        rate: Number(compensation_details?.per_hour_worked),
        type: 'per_hour',
        total: (transformTimeStringToNumber(data?.total_eta) * Number(compensation_details?.per_hour_worked))?.toFixed(
          2
        ),
        isDefault: true,
      },
    ];
  }

  if (compensation_details?.pay_option === 'flat_rate') {
    return [
      {
        id: Date.now(),
        addition: { addition_type: 'Flat Rate' },
        driver_id: id,
        driver: `${fname} ${lname}`,
        qty: 1,
        rate: Number(flatRate) || 0,
        type: 'flat_rate',
        total: Number(flatRate) || 0,
        isDefault: true,
      },
    ];
  }

  if (compensation_details?.pay_option === 'percentage') {
    return [
      {
        id: Date.now(),
        addition: { addition_type: 'By Percentage' },
        driver_id: id,
        driver: `${fname} ${lname}`,
        qty: Number(data?.total_amount),
        rate: Number(compensation_details?.shipment_percentage) / 100,
        type: 'percentage',
        total: (Number(data?.total_amount) * Number(compensation_details?.shipment_percentage)) / 100,
        isDefault: true,
      },
    ];
  }
};

export const dispatchCarrierValidation = object().shape(
  {
    vehicle_type_id: mixed().required('Required'),
    contact: mixed().required('Required'),
    flat_rate: mixed().required('Required'),
    state: object().when('zip', {
      is: (zip) => !zip,
      then: object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable(),
      otherwise: object().nullable(),
    }),
    city: object().when(['state', 'zip'], {
      is: (state, zip) => !!state && !zip,
      then: object()
        .shape({ id: number().required('Required') })
        .required('Required')
        .nullable(),
      otherwise: object().nullable(),
    }),
    zip: string().when(['state', 'city'], {
      is: (state, city) => !!state && !!city,
      then: string().nullable(),
      otherwise: string().required('Required').nullable(),
    }),
  },
  [
    ['state', 'zip'],
    ['city', 'zip'],
  ]
);
