import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import BulkOffer from 'componentsV2/Planner/BulkOffer';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import EquipmentTypes from 'components/Carriers/CarrierProfile/EquipmentTypes';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { formatInputNumber, removeCommas } from 'utils/helpers';
import { getCarriersList } from 'Api/Carriers';
import { revertShipmentOffer, revertShipmentOffers, updatePreferBid } from 'Api/OfferedShipments';
import { getShipmentCarrierOffers, sendCarrierOffer } from 'Api/PlannerV2';
import { getShipmentOfferSettings } from 'Api/CompanySettings';
import { initialFilters, useColumns } from './OfferCarrier.data';
import { SWrapper } from './OfferCarrier.styles';

const OfferCarrier = ({ shipment, search, preferredOnly, shipmentForEquipmentType, operatingStates }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [carriers, setCarriers] = useState({ data: [] });
  const [showMoreOpen, setShowMoreOpen] = useState(null);
  const [moreModalType, setMoreModalType] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [offers, setOffers] = useState([]);
  const [postedRate, setPostedRate] = useState('');
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [loadingPreferred, setLoadingPreferred] = useState(false);

  const { shipment_id } = shipment || {};

  const getCarriers = async () => {
    setLoading(true);
    try {
      const { data } = await getShipmentOfferSettings(shipment_id);
      const sortField = `sort[][${selectedFilters.sort.field}]`;

      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'filters[query]': search || undefined,
        'filters[preferred]': preferredOnly ? 1 : undefined,
        'filters[shipment_for_equipment_type]': shipmentForEquipmentType ? shipment_id : undefined,
        'filters[shipment_for_operating_states]': operatingStates ? shipment_id : undefined,
        [sortField]: selectedFilters.sort.sortBy,
        'filters[status][0]': 2,
        'filters[status][1]': data.offer_pending_carrier ? 1 : undefined,
      };

      const response = await getCarriersList(params);
      setCarriers(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const sendOffer = async (carriers, bulkOfferValues, watchBids) => {
    if (bulkOfferValues) {
      setCarriers((prevState) => ({
        ...prevState,
        data: prevState.data.map((item) =>
          selectedRows.some((i) => i.id === item.id)
            ? { ...item, cost: formatInputNumber(bulkOfferValues.posted_rate), costError: false }
            : item
        ),
      }));
    }

    if (carriers.length === 1 && !carriers[0].cost && !bulkOfferValues) {
      setCarriers((prevState) => ({
        ...prevState,
        data: prevState.data.map((item) => (item.id === carriers[0].id ? { ...item, costError: true } : item)),
      }));
      return;
    }

    try {
      const offer_date = moment.utc(new Date()).format('YYYY/MM/DD');
      const offer_time = moment.utc(new Date()).format('HH:mm');

      const formData = new FormData();

      carriers.forEach((item, index) => {
        formData.append(`offers[${index}][shipment_id]`, shipment_id);
        formData.append(`offers[${index}][carrier_id]`, item.id);
        formData.append(
          `offers[${index}][offer_amount]`,
          bulkOfferValues ? removeCommas(bulkOfferValues.posted_rate) : removeCommas(item.cost)
        );
        formData.append(`offers[${index}][offer_date]`, offer_date);
        formData.append(`offers[${index}][offer_time]`, offer_time);
        if (bulkOfferValues?.note) {
          formData.append(`offers[${index}][note]`, bulkOfferValues.note);
        }
      });
      await sendCarrierOffer(formData);
      if (watchBids) {
        navigate(`/offered-shipments/${shipment_id}`, { replace: true });
      }
      getShipmentOffers();
      setSelectedRows([]);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getShipmentOffers = async () => {
    try {
      const { data } = await getShipmentCarrierOffers({ shipment_id });
      setOffers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const sortingQuery = (field) => {
    const direction = selectedFilters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSelectedFilters((prevState) => ({ ...prevState, sort: { field, sortBy: direction } }));
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters({ ...selectedFilters, page });
  };

  const showMore = (e, data, type) => {
    e.stopPropagation();
    setMoreModalType(type);
    setShowMoreOpen(type === 'equipment' ? data.equipment_types : data.operating_states);
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== carriers.data.length ? carriers.data : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
      setCarriers((prevState) => ({
        ...prevState,
        data: prevState.data.map((item) => (item.id === row.id ? { ...item, cost: postedRate } : item)),
      }));
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const onCostChange = (row, value) => {
    setCarriers((prevState) => ({
      ...prevState,
      data: prevState.data.map((item) => (item.id === row.id ? { ...item, cost: formatInputNumber(value) } : item)),
    }));
  };

  const revertOffer = async (offer) => {
    try {
      await revertShipmentOffer(offer.id, { offer_type: 'carrier' });
      getShipmentOffers();
    } catch (e) {
      // Do nothing
    }
  };

  const revertOffers = async (carrier_ids) => {
    try {
      setLoadingRevert(true);
      await revertShipmentOffers({ shipment_id, carrier_ids });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingRevert(false);
    }
  };

  const onUpdatePreferred = async (carrier) => {
    if (loadingPreferred) {
      return;
    }

    setLoadingPreferred(true);
    try {
      await updatePreferBid(carrier?.id, { preferred: carrier?.preferred ? 0 : 1 });
      const updatedCarriers = carriers.data.map((item) =>
        item.id === carrier.id ? { ...item, preferred: !item.preferred } : item
      );
      setCarriers((prevState) => ({ ...prevState, data: updatedCarriers }));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingPreferred(false);
    }
  };

  useEffect(() => {
    getCarriers();
  }, [selectedFilters, search, preferredOnly, shipmentForEquipmentType, operatingStates]);

  useEffect(() => {
    if (!shipment_id) {
      return;
    }

    getShipmentOffers();

    const intervalId = setInterval(() => {
      getShipmentOffers();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [shipment_id]);

  const columns = useColumns({
    shipment,
    showMore,
    selectedRows,
    carriers: carriers.data,
    handleSelectAll,
    handleSelectRow,
    sendOffer,
    revertOffer,
    onCostChange,
    offers,
    postedRate,
    sort: selectedFilters.sort,
    sortingQuery,
    onUpdatePreferred,
  });

  return (
    <SWrapper>
      {!!selectedRows.length && selectedRows.some((item) => offers.some((i) => i.carrier?.id === item.id)) && (
        <div className='mb-3 mt-2'>
          <CustomButton
            type='primary'
            title='Revert'
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={() => revertOffers(selectedRows.map((item) => item.id))}
          />
        </div>
      )}
      <div className='d-flex gap-4'>
        <div className='carriers-table'>
          {loading ? (
            <TableSkeleton />
          ) : (
            <MaterialTableWrapper
              data={carriers?.data}
              rowPerPage={selectedFilters.itemsPerPage}
              style={{ backgroundColor: palette.white }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: carriers,
                    rowPerPage: selectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                  }),
              }}
              columns={columns}
            />
          )}
        </div>
        <div className='offer-options'>
          <BulkOffer
            shipment={shipment}
            sendOffer={(values, watchBids) => sendOffer(selectedRows, values, watchBids)}
            onSuccess={getShipmentOffers}
            offersCount={selectedRows?.length}
            readOnly={!!offers?.length}
            onPostedRateChange={setPostedRate}
            revertAll={() => revertOffers(offers.map((item) => item.carrier_id))}
            loadingRevert={loadingRevert}
          />
        </div>
      </div>
      {showMoreOpen && (
        <EquipmentTypes
          open={showMoreOpen}
          moreModalType={moreModalType}
          onClose={() => setShowMoreOpen(null)}
          equipmentTypes={showMoreOpen}
        />
      )}
    </SWrapper>
  );
};

export default OfferCarrier;
