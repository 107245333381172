import { array, number, object, string } from 'yup';

export const validationSchema = object().shape({
  stop_point: object()
    .shape({ id: string().required('Required') })
    .required('Required')
    .nullable(),
  stops: array().of(
    object().shape({
      scheduled_type: object()
        .shape({ key: number().required('Required') })
        .required('Required')
        .nullable(),
      scheduled_date: string().trim().required('Required').typeError('Required').nullable(),
      from: string().trim().required('Required').typeError('Required').nullable(),
      to: string().when('scheduled_type', {
        is: (scheduled_type) => Number(scheduled_type) === 3,
        then: string().trim().required('Required').typeError('Required').nullable(),
        otherwise: string().nullable(),
      }),
      equipment_type: object().when('stop_point_type', {
        is: (stop_point_type) => Number(stop_point_type) === 1,
        then: object()
          .shape({ id: string().required('Required') })
          .required('Required')
          .nullable(),
        otherwise: object().nullable(),
      }),
      equipment_type_length: object().when(['stop_point_type', 'equipment_type'], {
        is: (stop_point_type, equipment_type) => Number(stop_point_type) === 1 && Number(equipment_type) >= 12,
        then: object()
          .shape({ id: string().required('Required') })
          .required('Required')
          .nullable(),
        otherwise: object().nullable(),
      }),
      equipment_action: object()
        .shape({ key: number().required('Required') })
        .required('Required')
        .nullable(),
    })
  ),
});
