import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckFilled } from 'assets/icons/check-filled.svg';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as Download } from 'assets/icons/download2.svg';
import PDFMerger from 'pdf-merger-js';
import { getCarrierStaticDocumentsByToken } from 'Api/Carriers';
import { useParams } from 'react-router-dom';
import { SFlexWrapper, SWrapper } from './Final.styles';
import { SPrimaryButton } from '../../OnboardCarrier.styles';

const Final = ({ company, carrier }) => {
  const { use } = useTheme();
  const { dot, token } = useParams();

  const [documents, setDocuments] = useState();

  const { ucr_doc, bmc_doc, authority, reference_sheet, company_name } = company || {};

  const { id } = carrier || {};

  useEffect(async () => {
    const currentStaticDocuments = await getCarrierStaticDocumentsByToken(dot, token, id);
    setDocuments(currentStaticDocuments.data);
  }, []);

  const getMergedUrl = async (docs) => {
    try {
      const merger = new PDFMerger();

      for (const file of docs) {
        await merger.add(file);
      }

      const mergedPdf = await merger.saveAsBlob();
      return URL.createObjectURL(mergedPdf);
    } catch (e) {
      // Do nothing
    }
  };

  const onDownload = (path) => {
    fetch(path, { mode: 'cors' })
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = typeof path === 'string' ? new Blob([resp], { type: 'application/pdf' }) : path;
        const link = document.createElement('a');
        const url = URL.createObjectURL(file);
        link.setAttribute('download', typeof path === 'string' ? 'download' : path.name);
        link.href = url;
        link.download = `Packet-${company_name}.pdf`;
        link.click();
      });
  };

  const downLoad = async () => {
    const docs = [reference_sheet, authority, bmc_doc, ucr_doc];

    if (documents.master_agreement) {
      docs.push(documents.master_agreement);
    }
    if (documents.trailer_interchange_and_indemnity_agreement) {
      docs.push(documents.trailer_interchange_and_indemnity_agreement);
    }
    const mergedUrl = await getMergedUrl(docs);
    onDownload(mergedUrl);
  };

  return (
    <SWrapper>
      <SFlexWrapper>
        <CheckFilled />
        <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray200) }} className='success-title'>
          Success!
        </Typography>
        <Typography variant='b1' style={{ color: use(palette.gray700, palette.gray200) }}>
          Your carrier onboarding has been successfully submitted. Download Broker Packet and Signed Master Agreement
        </Typography>
        <SPrimaryButton onClick={downLoad} className='icon-button mt-4'>
          <Download />
          Download
        </SPrimaryButton>
      </SFlexWrapper>
    </SWrapper>
  );
};

export default Final;
