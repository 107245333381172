import React, { useEffect, useRef, useState } from 'react';
import Search from 'common/Search';
import Divider from 'common/Divider';
import Pagination from 'common/Pagination';
import { Typography } from 'components/Typography';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { getTransferHistory } from 'Api/CardManagement';
import useDebounce from 'hooks/useDebounce';
import { SWrapper } from './TransferHistory.styles';
import { useColumns } from './TransferHistory.data';
import { STitleWrapper } from '../../Cards/Cards.styles';

const TransferHistory = () => {
  const [search, setSearch] = useState('');
  const [transfers, setTransfers] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25 });
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({ field: 'date', sortBy: 'desc' });
  const debouncedSearch = useDebounce(search, 500);
  const didMountRef = useRef(false);

  const getTransfers = async () => {
    try {
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'filters[all]': debouncedSearch || undefined,
        [sortField]: sort.sortBy,
      };
      const response = await getTransferHistory(params);
      setTransfers(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
    }
    getTransfers();
    didMountRef.current = true;
  }, [selectedFilters, debouncedSearch, sort]);

  const columns = useColumns({ sort, sortingQuery });

  return (
    <SWrapper>
      <div className='mb-2'>
        <STitleWrapper>
          <Typography variant='h2'>Transfer History</Typography>
          <Divider margin='8px 0 16px' />
        </STitleWrapper>
        <Search search={search} onChange={setSearch} margin='12px 0 0' />
      </div>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={transfers.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: palette.white }}
          components={{
            Pagination: () =>
              Pagination({
                data: transfers,
                rowPerPage: selectedFilters.itemsPerPage,
                onChangeRowPerPage,
                onPageChange,
              }),
          }}
          columns={columns}
        />
      )}
    </SWrapper>
  );
};

export default TransferHistory;
