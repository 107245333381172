export const getInitialValues = (policies) => {
  const {
    compensation_policy_id,
    compensation_policy_expiry_date,
    compensation_policy_attachment,
    use_default_worker_compensation,
  } = policies || {};

  return {
    use_default_worker_compensation: !!use_default_worker_compensation,
    compensation_policy_id: compensation_policy_id || '',
    compensation_policy_expiry_date: compensation_policy_expiry_date ? new Date(compensation_policy_expiry_date) : null,
    compensation_policy_attachment: compensation_policy_attachment || null,
  };
};
