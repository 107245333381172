import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SSendQuoteForm = styled.form`
  .customer-section-wrapper {
    display: flex;
    flex-direction: column;
    background-color: ${palette.white};
    border: 1px solid ${palette.gray100};
    border-radius: 6px;
    gap: 16px;
    padding: 12px;
    margin-bottom: 24px;
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    color: ${palette.gray700};
    white-space: nowrap;
    font-family: Inter, sans-serif;
    padding: 12px 8px;
    border-bottom: 1px solid ${palette.gray50};
  }

  td {
    padding: 6px 8px;
    border: none;
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;
