import { array, number, object, string } from 'yup';

export const timeValidationSchema = object().shape({
  day: object()
    .shape({ value: number().required('Required') })
    .required('Required')
    .nullable(),
  startTimeHours: string().trim().required('Required').nullable(),
  startTimeMinutes: string().trim().required('Required').nullable(),
  endTimeHours: string().trim().required('Required').nullable(),
  endTimeMinutes: string().trim().required('Required').nullable(),
});

export const locationValidationSchema = object().shape({
  locations: array()
    .of(
      object()
        .shape({ locId: number().required('Required') })
        .required('Required')
        .nullable()
    )
    .min(1, 'Required'),
});
