export const getInitialValues = (data, isConfiguringStep) => {
  const {
    is_factoring,
    factoring_company,
    address_line1,
    address_line2,
    country,
    city,
    state,
    zip,
    factor_email,
    factor_fax,
    notice,
    notice_letter,
  } = data || {};

  return {
    is_factoring: isConfiguringStep && (!data || !!data?.is_factoring) ? true : is_factoring ? !!is_factoring : false,
    factoring_company: factoring_company || '',
    factor_email: factor_email || '',
    factor_fax: factor_fax || '',
    address_line1: address_line1 || '',
    address_line2: address_line2 || '',
    notice: notice || '',
    notice_letter: notice_letter || null,
    country: country || null,
    state: state || null,
    city: city || null,
    zip: zip || '',
  };
};
