import React from 'react';

const DeleteChannel = ({ onCancel, heading, message, deleteBtnName, element }) => {
  return (
    <div className='modalContainer' style={{ zIndex: 5 }}>
      <div className='addon-modal-area create-channel' style={{ width: '550px' }}>
        <div
          className='modal-footer-custom'
          style={{
            justifyContent: 'space-between',
            borderRadius: '8px 8px 0px 0px ',
          }}
        >
          <p className='channel-modal-heading'>{heading}</p>
        </div>
        {element || <p className='channel-modal-text'>{message}</p>}
        <div className=' create-channel  modal-footer-custom addon-footer-btn '>
          <button className='cancel-btn' onClick={() => onCancel(false)}>
            Cancel
          </button>
          <button
            className='create-channel-btn'
            style={{
              background: ' #D12953',
              boxShadow:
                ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 0px 1px #D12953, 0px 2px 5px rgba(209, 41, 83, 0.1), inset 0px 1px 0px #E56081',
            }}
          >
            {deleteBtnName}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteChannel;
