import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .incident-details-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    padding: 10px 30px 10px 0;
    border-right: 1px solid ${palette.gray50};
    min-height: 600px;
  }

  .image-show {
    object-fit: contain;
  }
`;
