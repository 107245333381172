import React, { useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import { Icons, PetrolIcon, satelight, MobileIcon } from 'assets/icons';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { Toast } from 'react-bootstrap';
import hexRgb from 'hex-rgb';
import { Fade, Box, Modal } from '@mui/material';
import useDateFormat from 'hooks/useDateFormat';
import style from '../../EquipmentProfile.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import AddHookComp from '../../../DialogueModals/AddHook/AddHook';
import Documents from './Documents';
import FaultCode from '../../../DialogueModals/ProfileAlert/FaultCode';
import AddHookedError from '../../../DialogueModals/Equipmentsmsg/AddHookedError';

import EquipmentDetectedHardware from '../EquipmentDetectedHardware/EquipmentDetectedHardware';
import UnHooked from '../../../DialogueModals/AddHook/UnHooked';

import EquipmentProfileDrivers from './EquipmentProfileDrivers';
import EquipmentDetails from './EquipmentDetailsComponent';
import EquipmentConnectedServices from '../EquipmentConnectedServices/EquipmentConnectedServices';
import UnhookedMsg from '../../../DialogueModals/Equipmentsmsg/UnhookedTrailerMsg';
import AddHookedSuccess from '../../../DialogueModals/Equipmentsmsg/HookedTrailerSuccuss';
import AddGroup from '../../../DialogueModals/AddGroup/AddGroup';
import RemoveGroup from '../../../DialogueModals/AddGroup/RemoveGroup';
import { EquipmentDetailsContext } from '../../EquipmentDetails';

import AddGroupSuccess from '../../../DialogueModals/Equipmentsmsg/AddGroupSuccess';
import RemoveGroupSuccess from '../../../DialogueModals/Equipmentsmsg/RemoveGroupSuccess';
import ReserveEquipment from '../../../DialogueModals/ReserveEquipment/ReserveEquipment';
import UnresevedEquipment from '../../../DialogueModals/ReserveEquipment/UnreservedEquipment';
import AlreadyReserved from '../../../DialogueModals/Equipmentsmsg/AlreadyReserved';
import UnreservedEquipmentmsg from '../../../DialogueModals/ReserveEquipment/UnreserveEquipmentmsg';
import ReserveEquipmentMsg from '../../../DialogueModals/ReserveEquipment/ReservedEquipmentMsg';
import '../../equipmentdetails.css';
import './DateRangePicker/DatePicker.css';
import AlertMsg from '../../../DialogueModals/ProfileAlert/AlertIcon';
import TrackingHistory from './TrackingHistory/TrackingHistory';

const Vehicle = ({ profileData, init, timeLine, timeLineClose, equipmentId }) => {
  const { convertToCustomerTime } = useDateFormat();
  const [hook, setHook] = useState(false);
  const [id, setId] = useState();
  const [Hookederr, setHookederr] = useState(false);
  const [getHookederr, setGetHookedErr] = useState();
  const [success, setSuccess] = useState(false);
  const [unHookedTrailer, setUnHookedTrailer] = useState(false);
  const [unHookedData, setUnhookedData] = useState({ data: [] });
  const { use } = useTheme();
  const [unHookedMsg, setUnhookedMsg] = useState(false);
  const [unreserveSuccess, setUnReserveSuccess] = useState(false);
  const [RemoveGroupData, setRemoveGroupData] = useState({ data: [] });
  const [removeGroup, setRemoveGroup] = useState(false);
  const [addGroup, setAddGroup] = useState(false);
  const [removeGroupSuccess, setRemoveGroupSuccess] = useState(false);
  const [addGroupSuccess, setAddGroupSuccess] = useState(false);
  const [reserveEquipment, setReserveEquipment] = useState(false);
  const [reserveMsgData, setReserveMsgData] = useState();
  const [showA, setShowA] = useState(false);
  const [unReseveredEq, setUnReserveredEq] = useState(false);
  const [unReseveredEqId, setUnReserveredEqId] = useState({ data: [] });
  const [alreadyReserveMsg, setAlreadyReserveMsg] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [alertId, setAlertId] = useState(null);
  const [connectedServicesIndex, setConnectedServicesIndex] = useState(Date.now());

  const [profileAlert, setProfileAlert] = useState(null);
  const [alreadyReserveid, setAlreadyReserveId] = useState();
  const [faultCode, setFaultCode] = useState(null);
  const [faultCodeData, setFaultCodeData] = useState();
  const { driverSpeed } = useContext(EquipmentDetailsContext);

  const user = JSON.parse(localStorage.getItem('user'));
  const AlreadyReserveMsgModalClose = () => setAlreadyReserveMsg(false);
  const getId = (id) => {
    setId(id);
    ReserveEquipmentModal(true);
  };

  const FaultCodehandleClose = () => {
    setFaultCode(null);
  };
  const UnReserveSuccessShow = () => {
    setUnReserveSuccess(true);
    setTimeout(() => {}, 4000);
  };
  const UnReservedModalClose = () => {
    setUnReserveredEq(false);
  };

  const FaultCodehandleClick = (e, faultCode) => {
    setFaultCodeData(faultCode);
    setFaultCode(e.currentTarget);
  };
  const AlreadyReserveMsgModal = (id) => {
    setAlreadyReserveId(id);
    setAlreadyReserveMsg(true);
    setTimeout(() => {
      AlreadyReserveMsgModalClose();
    }, 5000);
  };
  const UnReservedEqModal = (id, duration, type, row_id) => {
    setUnReserveredEqId([id, duration, type, row_id]);
    setUnReserveredEq(true);
  };
  const ProfileAlerthandleClick = (e, alerts, id) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
    setAlertId(id);
  };
  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };
  const ReserveEquipmentModalClose = () => {
    setReserveEquipment(false);
  };
  const GetreserveMsgData = (val) => {
    setReserveMsgData(val);
  };
  const toggleShowA = () => {
    setShowA(true);
  };
  const ProfileAlertOpen = Boolean(profileAlert);
  const faultcodeOpen = Boolean(faultCode);

  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;
  const FaultCodeContent = faultcodeOpen ? 'simple-popover' : undefined;

  const toggleCloseA = () => setShowA(false);
  const ReserveEquipmentModal = () => {
    setReserveEquipment(true);
  };
  const UnHookedModalShow = (id, eq_id, length, unit, title) => {
    setUnhookedData([id, eq_id, length, unit, title]);
    setUnHookedTrailer(true);
  };
  const RemoveGroupModal = (id, eq_id, row_id, title, group_name) => {
    setRemoveGroup(true);
    setRemoveGroupData([id, eq_id, row_id, title, group_name]);
  };
  const addGroupModal = () => {
    setId(profileData.id);
    setAddGroup(true);
  };
  const AddGroupSuccessClose = () => {
    setAddGroupSuccess(false);
  };
  const AddGroupSuccessModal = () => {
    setAddGroupSuccess(true);
    setTimeout(() => {
      AddGroupSuccessClose();
    }, 3000);
  };
  const addGroupModalClose = () => setAddGroup(false);
  const UnHookedModalClose = () => {
    setUnHookedTrailer(false);
  };
  const RemoveGroupModalClose = () => {
    setRemoveGroup(false);
  };
  const hookModalClose = () => setHook(false);
  const hookModalShow = () => {
    setId(profileData.id);
    setHook(true);
  };
  const RemoveGroupSuccessModalClose = () => {
    setRemoveGroupSuccess(false);
  };
  const RemoveGroupSuccessModal = () => {
    setRemoveGroupSuccess(true);
    setTimeout(() => {
      RemoveGroupSuccessModalClose();
    }, 3000);
  };
  const SuccessShow = () => {
    setSuccess(true);
    init(null, true);
  };
  const SuccessClose = () => setSuccess(false);

  const GetHookedError = (err) => {
    setGetHookedErr(err);
  };
  const HookedErrShow = () => {
    setHookederr(true);
  };
  const HookedErrClose = () => {
    setHookederr(false);
  };
  const UnhookedMsgClose = () => {
    setUnhookedMsg(false);
    init(null, true);
  };
  const UnReserveSuccessClose = () => {
    setUnReserveSuccess(false);
  };
  const UnHoookedMsgShow = () => {
    setUnhookedMsg(true);
    setTimeout(() => {}, 4000);
  };
  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    backgroundColor: use(palette.white, palette.dark800),
    outline: 0,
  };

  return (
    <div
      className='overlay-equipment-details-container'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
      }}
    >
      <div className='equipment-profile-group-box'>
        <div className='d-flex mt-1 equipment-id-column  justify-content-between mb-2'>
          <div className='d-flex align-item-center'>
            <div className='flex-row d-flex align-items-center'>
              <div className='d-flex p-0'>
                {profileData?.equipment_reserve && profileData?.equipment_reserve?.duration !== 'Expired' ? (
                  <div>
                    <svg
                      className='ms-1'
                      width='12'
                      height='14'
                      viewBox='0 0 12 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      onClick={($event) => {
                        $event.stopPropagation();
                        user?.department?.department_name === 'Management' ||
                        user.id === profileData?.equipment_reserve?.user_id
                          ? UnReservedEqModal(
                              profileData?.equipment_reserve?.id,
                              profileData?.duration,
                              profileData?.equipment_type?.title,
                              profileData.equipment_id
                            )
                          : AlreadyReserveMsgModal(profileData?.equipment_reserve?.id);
                      }}
                    >
                      <path
                        d='M0.5 2C0.5 0.895431 1.39543 0 2.5 0H9.5C10.6046 0 11.5 0.895431 11.5 2V13.5993C11.5 13.8965 11.1873 14.0899 10.9213 13.9572L6.4467 11.723C6.16546 11.5826 5.83454 11.5826 5.55329 11.723L1.07868 13.9572C0.812734 14.0899 0.5 13.8965 0.5 13.5993V2Z'
                        fill='#767FFB'
                      />
                    </svg>
                  </div>
                ) : (
                  <div
                    className='reserve-equipment'
                    onClick={($event) => {
                      $event.stopPropagation();
                      getId(profileData?.id);
                    }}
                  >
                    <img src={Icons.buttonIcon} alt='button-icon' className='bookmark' />
                  </div>
                )}

                {/* <div className="me-1">
                {profileData?.equipment_connected_services_faultcode ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    FaultCodehandleClick
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={(e) => {
                      FaultCodehandleClick(
                        e,
                        profileData?.equipment_connected_services_faultcode
                      );
                      e.stopPropagation();
                    }}
                  >
                    <path
                      d="M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z"
                      fill="#DC4067"
                    />
                    <path
                      d="M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z"
                      fill="#DC4067"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z"
                      fill="#DC4067"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div> */}
              </div>
              {profileData?.expiring_equipment_alerts ? (
                <div className='ms-1 me-1' onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }}>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={(e) => ProfileAlerthandleClick(e, profileData?.expiring_equipment_alerts, profileData?.id)}
                  >
                    <path
                      d='M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z'
                      fill='#C97A20'
                    />
                    <path
                      d='M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z'
                      fill='#C97A20'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z'
                      fill='#C97A20'
                    />
                  </svg>
                </div>
              ) : (
                ''
              )}
              {profileData?.equipment_connected_services_device ? (
                <div className='ms-1'>
                  {profileData?.equipment_connected_services_device?.engine_state === 'Idle' ? (
                    <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                        fill='#81D6AB'
                      />
                    </svg>
                  ) : profileData?.equipment_connected_services_device?.engine_state === 'On' ? (
                    <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                        fill='#38A06C'
                      />
                    </svg>
                  ) : profileData?.equipment_connected_services_device?.engine_state === 'Off' ? (
                    <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                        fill='#5A6376'
                      />
                    </svg>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}

              {profileData?.equipment_connected_services_faultcode ? (
                <div className='ms-1'>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={(e) => {
                      FaultCodehandleClick(e, profileData?.equipment_connected_services_faultcode);
                    }}
                  >
                    <path
                      d='M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z'
                      fill='#DC4067'
                    />
                    <path
                      d='M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z'
                      fill='#DC4067'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z'
                      fill='#DC4067'
                    />
                  </svg>
                </div>
              ) : (
                ''
              )}
              {profileData?.equipment_connected_services_device?.fuel_percent ? (
                <>
                  <div className='ms-2 me-2'>{PetrolIcon}</div>
                  <div className={style.petrolPersent}>
                    <div
                      style={{
                        width: `${profileData?.equipment_connected_services_device?.fuel_percent}%`,
                        backgroundColor: '#81D6AB',
                        borderRadius: '4px',
                      }}
                    >
                      <span style={{ textAlign: 'center' }} className='ms-2'>
                        {profileData?.equipment_connected_services_device?.fuel_percent}%
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <div
            className='d-flex align-items-center'
            style={{
              backgroundColor: use(palette.white, palette.dark800),
            }}
          >
            {profileData?.location_target === 'Equipment' && profileData.equipment_connected_services_device ? (
              <span className='me-2'> {satelight}</span>
            ) : profileData?.location_target === 'Driver' ? (
              <span className='me-2'> {MobileIcon}</span>
            ) : profileData?.location_target === null ? (
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.79114 3.72156C4.49577 3.42619 4.01689 3.42619 3.72152 3.72156C3.42616 4.01693 3.42616 4.49581 3.72152 4.79117L6.93037 8.00002L3.72155 11.2088C3.42619 11.5042 3.42619 11.9831 3.72155 12.2784C4.01692 12.5738 4.4958 12.5738 4.79117 12.2784L7.99998 9.06963L11.2088 12.2785C11.5042 12.5738 11.9831 12.5738 12.2784 12.2785C12.5738 11.9831 12.5738 11.5042 12.2784 11.2089L9.06959 8.00002L12.2785 4.79114C12.5738 4.49577 12.5738 4.01689 12.2785 3.72152C11.9831 3.42616 11.5042 3.42616 11.2089 3.72152L7.99998 6.9304L4.79114 3.72156Z'
                  fill='#DC4067'
                />
              </svg>
            ) : (
              ''
            )}
            <div className={driverSpeed < 1 ? style.Zerospeed : style.speed}>
              {parseInt(driverSpeed, 10)}
              <span className='ms-1'>MPH</span>
            </div>
          </div>
        </div>
      </div>

      {timeLine && <TrackingHistory timeLineClose={timeLineClose} equipmentId={equipmentId} />}
      {!timeLine && (
        <div>
          <EquipmentProfileDrivers profileData={profileData} init={init} />
          <div className='equipment-profile-group-box mt-2'>
            <div className='d-flex mb-1  '>
              <span
                className={style['equipment-profile-content']}
                style={{
                  color: use(palette.dark800, palette.gray200),
                  width: '15vw',
                }}
              >
                Tags
              </span>
              <div className='equipment-group fade-in-row d-flex'>
                <div className='group-wrapper d-flex'>
                  <div className='row-style'>
                    {profileData?.equipment_group && profileData?.equipment_group.length >= 1 ? (
                      ''
                    ) : (
                      <div className='add-group'>
                        <div
                          className='d-flex'
                          style={{ display: 'flex', justifyContent: 'center' }}
                          onClick={($event) => {
                            $event.stopPropagation();
                            addGroupModal(profileData.id);
                          }}
                        >
                          <div className='d-flex'>
                            <img src={Icons.add} alt='' className={style['add-group-icon']} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {profileData?.equipment_group
                      ? profileData?.equipment_group.map((g) => {
                          const colorCode = hexRgb(g.color);
                          const TextColor =
                            colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                              ? 'white'
                              : 'rgb(23, 28, 38)';
                          return (
                            <span key={g.id} style={{ cursor: 'pointer' }}>
                              <span
                                className='equipment-groups'
                                style={{
                                  backgroundColor: g.color,
                                  color: TextColor,
                                }}
                                onClick={($event) => {
                                  RemoveGroupModal(
                                    g.id,
                                    profileData.equipment_id,
                                    profileData?.id,
                                    profileData?.equipment_type?.title,
                                    g?.group_name
                                  );
                                  $event.stopPropagation();
                                }}
                              >
                                {g.short_name}
                              </span>
                            </span>
                          );
                        })
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='equipment-profile-group-box mt-2'>
            <div className='d-flex mb-1  '>
              <span
                className={style['equipment-profile-content']}
                style={{
                  color: use(palette.dark800, palette.gray200),
                  width: '15vw',
                }}
              >
                <span className='mt-1'> Trailer</span>
              </span>
              <div className='equipment-group fade-in-row d-flex'>
                <div className='group-wrapper d-flex'>
                  <div className='row-style'>
                    <div className='add-group'>
                      <div className='d-flex' style={{ display: 'flex', justifyContent: 'center' }} />
                    </div>
                  </div>
                  <div className='hooked-to-column-wrapper fade-in-row'>
                    {profileData?.hook_to?.equipment_id ? (
                      <div
                        onClick={($event) => {
                          UnHookedModalShow(
                            profileData?.id,
                            profileData?.hook_to?.equipment_id,
                            profileData?.hook_to?.length?.length,
                            profileData?.hook_to?.length?.unit,
                            profileData?.hook_to?.equipment_type?.title
                          );
                          $event.stopPropagation();
                        }}
                      >
                        <div style={{ cursor: 'pointer' }}>
                          <span
                            style={{
                              color: use(palette.dark800, palette.gray200),
                            }}
                            className={style['equipment-profile-content-value']}
                          >
                            <span className='me-1'> {profileData?.hook_to?.equipment_id}</span>
                          </span>
                          <span
                            className='hooked-numbers-sub'
                            style={{
                              color: use(palette.dark800, palette.gray200),
                            }}
                          >
                            {profileData?.hook_to?.length?.length}
                            <span
                              style={{
                                color: use(palette.dark800, palette.gray200),
                              }}
                            >
                              {profileData?.hook_to?.length?.unit}{' '}
                            </span>
                            <span
                              style={{
                                color: use(palette.dark800, palette.gray200),
                              }}
                            >
                              {profileData?.hook_to?.equipment_type?.title}
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : profileData.equipment_type?.vehicle_type_id !== 1 || +profileData.equipment_type.id === 1 ? (
                      <div style={{ cursor: 'pointer' }} onClick={() => hookModalShow(profileData.id)}>
                        <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                            fill='#4f5aed'
                          />
                        </svg>
                        <span className='add-driver-content'>Hook</span>
                      </div>
                    ) : (
                      <>-</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex  mt-4'>
            <span
              className={style['equipment-profile-content']}
              style={{
                color: use(palette.dark800, palette.gray200),
                width: '15vw',
              }}
            >
              Last Update
            </span>
            <span className={style['last-update']} style={{ color: use(palette.dark800, palette.gray200) }}>
              <span style={{ fontSize: '10px' }} className='d-flex'>
                <span>
                  <img src={Icons?.LocationStep} alt='' />
                </span>
                <span className=' equipment-datepicker-input'>
                  <div style={{ fontSize: '13px' }}>
                    {profileData?.location_target === 'Driver' ? (
                      profileData?.drivers ? (
                        profileData?.drivers[0]?.driver_gps?.updated_at ? (
                          profileData?.drivers[0]?.driver_gps?.updated_at && (
                            <div
                              className='flex-row d-flex '
                              key={id}
                              style={{
                                color: use(palette.dark800, palette.gray200),
                              }}
                            >
                              <div>{convertToCustomerTime(profileData?.drivers[0]?.driver_gps?.updated_at)}</div>
                            </div>
                          )
                        ) : (
                          profileData?.last_known_update && (
                            <div
                              className='flex-row d-flex '
                              key={id}
                              style={{
                                color: use(palette.dark800, palette.gray200),
                              }}
                            >
                              <div>{convertToCustomerTime(profileData?.last_known_update)}</div>
                            </div>
                          )
                        )
                      ) : (
                        profileData?.last_known_update && (
                          <div
                            className='flex-row d-flex fade-id-row'
                            key={id}
                            style={{
                              color: use(palette.dark800, palette.gray200),
                            }}
                          >
                            {profileData?.last_known_update && convertToCustomerTime(profileData?.last_known_update)}
                          </div>
                        )
                      )
                    ) : profileData?.location_target === 'Equipment' && profileData?.equipment_gps?.updated_at ? (
                      <div
                        className='flex-row d-flex fade-in-row'
                        key={id}
                        style={{ color: use(palette.dark800, palette.gray200) }}
                      >
                        {profileData?.equipment_gps?.updated_at &&
                          convertToCustomerTime(profileData?.equipment_gps?.updated_at)}
                      </div>
                    ) : (
                      profileData?.last_known_update && (
                        <div
                          className='flex-row d-flex fade-in-row'
                          key={id}
                          style={{
                            color: use(palette.dark800, palette.gray200),
                          }}
                        >
                          {profileData?.last_known_update && convertToCustomerTime(profileData?.last_known_update)}
                        </div>
                      )
                    )}
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div className='d-flex mt-4  equipment-profile-group-box'>
            <span
              className={style['equipment-profile-content']}
              style={{
                color: use(palette.dark800, palette.gray200),
                display: 'flex',
                width: '15vw',
              }}
            >
              Location
              {/* <span className="mt-1 ms-1">
                <UpdateLocation />
              </span> */}
            </span>
            <span
              className={style.location}
              style={{
                color: use(palette.dark800, palette.gray200),
                width: '11vw',
              }}
            >
              <div className=' '>
                {profileData?.location_target === 'Driver' ? (
                  profileData?.location_target === 'Driver' && profileData?.drivers ? (
                    <div>
                      <p
                        className={style['current-location']}
                        style={{
                          color: use(palette.dark800, palette.gray200),
                        }}
                      >
                        {profileData?.drivers[0]?.driver_gps?.location
                          ? profileData?.drivers[0]?.driver_gps?.location
                          : profileData?.last_known_location}
                        {/* {d?.driver_gps?.location} */}
                      </p>
                    </div>
                  ) : (
                    <p
                      className={style['current-location']}
                      style={{
                        color: use(palette.dark800, palette.gray200),
                      }}
                    >
                      {profileData?.last_known_location}
                      {/* {d?.driver_gps?.location} */}
                    </p>
                  )
                ) : profileData?.location_target === 'Equipment' ? (
                  <p className={style['current-location']} style={{ color: use(palette.dark800, palette.gray200) }}>
                    {profileData?.equipment_gps?.location
                      ? profileData?.equipment_gps?.location
                      : profileData?.last_known_location}
                    {/* {rowData?.equipment_gps?.location} */}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </span>
          </div>
          <div>
            <EquipmentDetails
              profileData={profileData}
              init={init}
              updateConnectedServices={() => setConnectedServicesIndex(Date.now())}
            />
          </div>
          <Documents equipment={profileData} onUpdateSuccess={init} />
          <EquipmentConnectedServices profileData={profileData} refreshIndex={connectedServicesIndex} />
          <EquipmentDetectedHardware profileData={profileData} />
        </div>
      )}

      <Modal
        open={hook}
        onClose={hookModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={hook}>
          <Box sx={styles}>
            <AddHookComp
              id={id}
              handleClose={hookModalClose}
              SuccessShow={SuccessShow}
              SuccessClose={SuccessClose}
              HookedErrClose={HookedErrClose}
              HookedErrShow={HookedErrShow}
              GetHookedError={GetHookedError}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={unHookedTrailer}
        onClose={UnHookedModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={unHookedTrailer}>
          <Box sx={styles}>
            <UnHooked
              handleClose={UnHookedModalClose}
              unHookedData={unHookedData}
              UnhookedMsgClose={UnhookedMsgClose}
              UnHoookedMsgShow={(id) => UnHoookedMsgShow(id)}
              HookedErrClose={HookedErrClose}
              HookedErrShow={HookedErrShow}
              GetHookedError={GetHookedError}
            />
          </Box>
        </Fade>
      </Modal>

      <Toast show={unHookedMsg} onClose={UnhookedMsgClose} className='equipment-success-msg'>
        <div>
          <UnhookedMsg
            handleClose={UnReserveSuccessClose}
            UnhookedMsgClose={UnhookedMsgClose}
            UnHoookedMsgShow={UnHoookedMsgShow}
          />
        </div>
      </Toast>
      <Toast show={success} onClose={SuccessClose} className='equipment-success-msg'>
        <AddHookedSuccess handleClose={SuccessClose} />
      </Toast>
      <Toast show={addGroupSuccess} onClose={AddGroupSuccessClose} className='equipment-success-msg'>
        <div>
          <AddGroupSuccess AddGroupSuccessClose={AddGroupSuccessClose} />
        </div>
      </Toast>
      <Toast show={removeGroupSuccess} onClose={RemoveGroupSuccessModalClose} className='equipment-success-msg'>
        <div>
          <RemoveGroupSuccess RemoveGroupSuccessModalClose={RemoveGroupSuccessModalClose} />
        </div>
      </Toast>
      <Toast show={showA} onClose={toggleCloseA} className='reserve-equipment-toast'>
        <ReserveEquipmentMsg
          handleClose={toggleCloseA}
          reserveMsgData={reserveMsgData}
          userFname={user.first_name}
          userLname={user.last_name}
        />
      </Toast>
      <Toast show={unreserveSuccess} onClose={UnReserveSuccessClose} className='equipment-success-msg'>
        <div>
          <UnreservedEquipmentmsg handleClose={UnReserveSuccessClose} />
        </div>
      </Toast>
      <Toast show={alreadyReserveMsg} onClose={AlreadyReserveMsgModalClose} className='already-reserve-equipment-toast'>
        <div>
          <AlreadyReserved
            alreadyReserveid={alreadyReserveid}
            AlreadyReserveMsgModalClose={AlreadyReserveMsgModalClose}
          />
        </div>
      </Toast>
      <Modal
        open={addGroup}
        onClose={addGroupModalClose}
        style={{ outline: 0, zIndex: 999 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={addGroup}>
          <Box sx={styles}>
            <AddGroup
              handleClose={addGroupModalClose}
              id={id}
              AddGroupSuccessModal={(id) => AddGroupSuccessModal(id)}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={removeGroup}
        onClose={RemoveGroupModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={removeGroup}>
          <Box sx={styles}>
            <RemoveGroup
              handleClose={RemoveGroupModalClose}
              RemoveGroupData={RemoveGroupData}
              RemoveGroupSuccessModal={(id) => RemoveGroupSuccessModal(id)}
            />
          </Box>
        </Fade>
      </Modal>

      <Modal
        show={reserveEquipment}
        open={reserveEquipment}
        onClose={ReserveEquipmentModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={reserveEquipment}>
          <Box sx={styles}>
            <ReserveEquipment
              id={id}
              ReserveEquipmentModalClose={ReserveEquipmentModalClose}
              toggleShowA={toggleShowA}
              toggleCloseA={toggleCloseA}
              GetreserveMsgData={GetreserveMsgData}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={unReseveredEq}
        onClose={UnReservedModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={unReseveredEq}>
          <Box sx={styles}>
            <UnresevedEquipment
              handleClose={UnReservedModalClose}
              unReseverdEqId={unReseveredEqId}
              alreadyReserveid={alreadyReserveid}
              UnReserveSuccessShow={(id) => UnReserveSuccessShow(id)}
              UnReserveSuccessClose={UnReserveSuccessClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Popover
        id={ProfileAlertContent}
        // anchorPosition={{ top: 299, left: 669 }}
        open={ProfileAlertOpen}
        anchorEl={profileAlert}
        onClose={ProfileAlerthandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <AlertMsg alertData={alertData} alertID={alertId} equipmentAlert />
      </Popover>
      <Popover
        id={FaultCodeContent}
        anchorPosition={{ top: 299, left: 669 }}
        open={faultcodeOpen}
        anchorEl={faultCode}
        onClose={FaultCodehandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <div style={{ width: '360px' }}>
          <FaultCode faultCodeData={faultCodeData} />
        </div>
      </Popover>
      <Toast show={Hookederr} onClose={HookedErrClose} className='equipment-err-msg'>
        {/* <div className="errText">{getHookederr}</div> */}.
        <AddHookedError getHookederr={getHookederr} handleClose={HookedErrClose} />
      </Toast>
    </div>
  );
};

export default Vehicle;
