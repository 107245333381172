import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import TableWrapper from 'components/TableWrapper';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import AddUpdateModal from 'componentsV2/PreviousEmployment/components/AddUpdateModal';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { deletePrevEmployment, deletePrevEmploymentAttempt, getPrevEmployment } from 'Api/PreviousEmployment';
import { useColumns } from './PreviousEmployment.data';
import { SWrapper } from './PreviousEmployment.styles';

const PreviousEmployment = ({ userId, userType, initialEmployments }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [openAddUpdate, setOpenAddUpdate] = useState(false);
  const [employerToEdit, setEmployerToEdit] = useState(null);
  const [prevEmploymentData, setPrevEmploymentData] = useState({});

  const getPreviousEmployment = async () => {
    try {
      setLoading(true);
      const params = {
        page: 1,
        itemsPerPage: 10000,
      };
      const response = await getPrevEmployment({ id: userId, userType, params });
      setPrevEmploymentData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const deleteEmployer = async (employerId) => {
    try {
      await deletePrevEmployment({ id: employerId, userType });
      showToaster({ type: 'success', message: 'Employer has been successfully deleted!' });
      getPreviousEmployment();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
  };

  const deleteAttempt = async (id) => {
    try {
      await deletePrevEmploymentAttempt({ id, userType });
      setPrevEmploymentData((prevState) => ({
        ...prevState,
        data: prevState.data.map((item) => ({
          ...item,
          applicant_employment_attempts: item.applicant_employment_attempts.filter((i) => i.id !== id),
        })),
      }));
      showToaster({ type: 'success', message: 'Attempt has been successfully deleted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    }
  };

  const toggleAccordion = (row) => {
    const newData = prevEmploymentData.data.map((item) => {
      if (row.id !== item.id) {
        return item;
      }
      return { ...item, isActive: !item.isActive };
    });
    setPrevEmploymentData((prevState) => ({ ...prevState, data: newData }));
  };

  const onEditEmployer = (employer) => {
    setEmployerToEdit(employer);
    setOpenAddUpdate(true);
  };

  useEffect(() => {
    getPreviousEmployment();
  }, []);

  const columns = useColumns({
    toggleAccordion,
    userType,
    onEditEmployer,
    deleteEmployer,
    deleteAttempt,
  });

  return (
    <SWrapper>
      <div className='prev-employment-header'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Previous Employment
        </Typography>
        <CustomButton
          type='secondary'
          title='Add Previous Employer'
          leftIcon={<PlusIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
          styleButton={{ padding: '6px 12px', margin: 0, fontSize: '14px', whiteSpace: 'nowrap', fontWeight: 500 }}
          onClick={() => setOpenAddUpdate(true)}
        />
      </div>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <TableWrapper data={prevEmploymentData?.data} style={{ backgroundColor: palette.white }} columns={columns} />
      )}
      {!!openAddUpdate && (
        <AddUpdateModal
          open={openAddUpdate}
          previousEmployment={employerToEdit}
          onClose={() => {
            setEmployerToEdit(null);
            setOpenAddUpdate(false);
          }}
          onSuccess={getPreviousEmployment}
          userId={userId}
          userType={userType}
          initialEmployments={prevEmploymentData?.data?.length ? null : initialEmployments}
        />
      )}
    </SWrapper>
  );
};

export default PreviousEmployment;
