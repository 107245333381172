import { palette } from 'utils/constants';

export const options = {
  cutout: '65%',
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  labels: {
    display: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

export const textCenter = {
  id: 'textCenter',
  afterDraw(chart) {
    const {
      ctx,
      _active,
      chartArea: { width, height },
    } = chart;

    const total = chart.data.datasets[0].data?.reduce((acc, cur) => {
      return acc + cur;
    }, 0);

    if (_active.length === 0) {
      ctx.save();
      ctx.font = '700 32px Inter';
      ctx.fillStyle = palette.gray700;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      if (total) {
        ctx.fillText(`${total}`, width / 2, height / 2 - 10);
      }

      ctx.font = '500 16px Inter';
      ctx.fillStyle = palette.gray500;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('Total', width / 2, height / 2 + 16);
    }
  },
};

export const defaultDataset = {
  labels: [],
  datasets: [
    {
      data: [1],
      backgroundColor: [palette.gray200],
      borderColor: [palette.gray200],
      hoverBackgroundColor: [palette.gray300],
      hoverBorderColor: [palette.gray300],
      borderWidth: 1,
    },
  ],
};

export const textCenterDefault = {
  id: 'textCenter',
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;

    ctx.save();
    ctx.font = '700 32px Inter';
    ctx.fillStyle = palette.gray700;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText('0', width / 2, height / 2 - 10);

    ctx.font = '500 16px Inter';
    ctx.fillStyle = palette.gray500;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText('Total', width / 2, height / 2 + 16);
  },
};
