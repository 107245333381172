import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { assignUser } from 'Api/CardManagement';
import RowActions from './RowActions';
import SetRestriction from '../SetRestriction';
import { STable, SText } from '../CardLimits/CardLimits.styles';
import { SHeaderWrapper } from '../CardDetails/CardDetails.styles';
import { SBackdrop, SCustomModal, SCheckbox } from '../../Cards.styles';

const LocationRestrictions = ({ open, onClose, cardDetails, data, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [openUpdateRestriction, setOpenUpdateRestriction] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { location_restrictions, card_number } = data || {};

  const onRemove = async (item) => {
    setLoading(true);
    try {
      const newRestrictions = location_restrictions.filter((i) => i.location_id !== item.location_id);

      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');

      if (data.user) {
        formData.append(`user_id`, data.user?.id || null);
        formData.append(`user_type`, data.user_type === 'Driver' ? 2 : data.user_type === 'Staff' ? 1 : null);
      }

      if (newRestrictions?.length) {
        newRestrictions.forEach((item, index) => {
          if (item.location_details) {
            formData.append(`locations[${index}][location_id]`, item.location_details.location_id);
            formData.append(`locations[${index}][location_name]`, item.location_details.location_name);
            formData.append(`locations[${index}][state_id]`, item.location_details.state_id);
            formData.append(`locations[${index}][city_id]`, item.location_details.city_id);
            formData.append(`locations[${index}][country_id]`, item.location_details.country_id);
          }
        });
      } else {
        formData.append(`locations[]`, '');
      }
      await assignUser(formData);
      showToaster({ type: 'success', message: 'Location restrictions have been successfully updated' });
      onSuccess(card_number);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const onBulkRemove = async () => {
    setLoading(true);
    try {
      const newRestrictions = location_restrictions.filter((i) => !selectedRows.some((item) => item.id === i.id));

      const formData = new FormData();
      formData.append('cardNumber', data.card_number);
      formData.append('provider_id', data.card_provider?.id || '');

      if (data.user) {
        formData.append(`user_id`, data.user?.id || null);
        formData.append(`user_type`, data.user_type === 'Driver' ? 2 : data.user_type === 'Staff' ? 1 : null);
      }

      if (newRestrictions?.length) {
        newRestrictions.forEach((item, index) => {
          if (item.location_details) {
            formData.append(`locations[${index}][location_id]`, item.location_details.location_id);
            formData.append(`locations[${index}][location_name]`, item.location_details.location_name);
            formData.append(`locations[${index}][state_id]`, item.location_details.state_id);
            formData.append(`locations[${index}][city_id]`, item.location_details.city_id);
            formData.append(`locations[${index}][country_id]`, item.location_details.country_id);
          }
        });
      } else {
        formData.append(`locations[]`, '');
      }
      await assignUser(formData);
      setSelectedRows([]);
      showToaster({ type: 'success', message: 'Location restrictions have been successfully updated' });
      onSuccess(card_number);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== location_restrictions?.length ? location_restrictions : []);
  };

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  return (
    <SCustomModal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <SHeaderWrapper>
          <Typography variant='h5'>Location Restrictions</Typography>
          <CustomButton
            type='secondary'
            title='Add New'
            onClick={() => setOpenUpdateRestriction(true)}
            className='edit-button'
            styleButton={{ padding: '6px 12px', marginTop: 0 }}
          />
        </SHeaderWrapper>
      }
      $bgColor={palette.white}
      $maxWidth='1200px'
      $minWidth='800px'
      padding='24px 0'
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <div style={{ padding: '0 24px' }}>
        <div className='w-100 d-flex justify-content-end'>
          <CustomButton
            title='Bulk Remove'
            type='danger'
            styleButton={{ margin: '0', padding: '4px 12px' }}
            styleTitle={{ fontSize: 12, marginTop: 1 }}
            disabled={!selectedRows.length}
            onClick={onBulkRemove}
          />
        </div>
        <STable>
          <thead>
            <tr>
              <th>
                <SCheckbox
                  onChange={handleSelectAll}
                  checked={selectedRows.length === location_restrictions?.length}
                  disabled={!location_restrictions?.length}
                />
              </th>
              <th>LOCATION ID</th>
              <th>STATE</th>
              <th>CITY</th>
              <th>COUNTRY</th>
              <th>LOCATION NAME</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {location_restrictions?.map((item) => (
              <tr key={item.day}>
                <td>
                  <SCheckbox
                    onChange={(e) => handleSelectRow(e, item)}
                    checked={selectedRows.some((i) => i.id === item.id)}
                  />
                </td>
                <td>
                  <SText>{item.location_id}</SText>
                </td>
                <td>
                  <SText>{item.location_details?.state_id || '-'}</SText>
                </td>
                <td>
                  <SText>{item.location_details?.city_id || '-'}</SText>
                </td>
                <td>
                  <SText>{item.location_details?.country_id || '-'}</SText>
                </td>
                <td>
                  <SText>{item.location_details?.location_name || '-'}</SText>
                </td>
                <td>
                  <RowActions onRemove={() => onRemove(item)} />
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!location_restrictions?.length && (
          <div className='text-center mt-2'>
            <Typography>No Records</Typography>
          </div>
        )}
        {openUpdateRestriction && (
          <SetRestriction
            open={openUpdateRestriction}
            onClose={() => setOpenUpdateRestriction(false)}
            data={data}
            onSuccess={() => onSuccess(card_number)}
            cardDetails={cardDetails}
            defaultType={2}
          />
        )}
      </div>
      <SBackdrop open={loading}>
        <CircularProgress size={30} />
      </SBackdrop>
    </SCustomModal>
  );
};

export default LocationRestrictions;
