import React, { useEffect, useState } from 'react';
import './planTable.css';
import OnboardingService from 'services/onboardingService';
import Loader from 'common/Loader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getServices } from 'Api/Onboarding';
import FeatureCard from '../FeatureCard';
import ServiceCard from '../ServiceCard';
import { SFeaturesWrapper, SServicesWrapper } from './PlanTable.styles';

const PlanTable = () => {
  const [tableData, setTableData] = useState([]);
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const brokerInfo = JSON.parse(localStorage.getItem('brokerInfo'));

  const getFeatures = () => {
    OnboardingService.getFeatures()
      .then((res) => {
        setTableData(
          res.data.filter(
            (item) =>
              item.visible_on_boarding &&
              (brokerInfo
                ? item.customer_type?.some((i) => i.customer_type === 'Carrier & Broker')
                : item.customer_type?.some((i) => i.customer_type === 'Carrier'))
          )
        );
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getServicesList = async () => {
    setLoadingServices(true);
    try {
      const { data } = await getServices();
      setServices(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingServices(false);
    }
  };

  useEffect(() => {
    getServicesList();
    setTimeout(() => {
      getFeatures();
    }, 500);

    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <div className='plan-table'>
      {!!tableData?.length && (
        <div className='mt-4'>
          <div className='text-center'>
            <Typography variant='h4' style={{ color: palette.gray900 }}>
              What's Included
            </Typography>
          </div>
          <SFeaturesWrapper>
            {tableData?.map((data) => (
              <FeatureCard feature={data} key={data.id} />
            ))}
          </SFeaturesWrapper>
        </div>
      )}
      <div className='mt-5'>
        <div className='text-center'>
          <Typography variant='h4' style={{ color: palette.gray900 }}>
            Connected Services
          </Typography>
        </div>
        <Loader loading={loadingServices} />
        <SServicesWrapper>
          {services?.map((item) => (
            <ServiceCard key={item.id} service={item} />
          ))}
        </SServicesWrapper>
      </div>
      <div className='divider' />
      <div className='disclaimer-container'>
        <div className='disclaimer'>
          <p className='heading'>Disclaimer</p>
          <p className='disclaimer-body '>
            Addons and hardware are sold separately and are optional upon account creation. You may cancel service at
            any time, however it is your option to use monthly, annual or tri-annual payment methods. If billed
            tri-annually, you are pre-paid for 3 years. If billed annually, you are pre-paid for 1 year. If billed
            monthly, you are pre-paid for 1 month and renew on a monthly basis. All license purchases are
            non-refundable. 14 day trial is free of charge. Billed per user (driver and staff).
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlanTable;
