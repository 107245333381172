import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Chip from 'common/Chip';
import useDateFormat from 'hooks/useDateFormat';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getPlannerStatus, getStatusParamsInStatus } from 'components/TableShipments/helpers/constants';

const NavLinkWrapper = ({ deleteDefaultStyles = false, to, onClick, children, style, ...rest }) => {
  const defaultStyles = useMemo(() => {
    return {
      top: 0,
      left: 0,
      inset: 0,
      color: 'initial',
      position: 'absolute',
      background: 'initial',
      textDecoration: 'none',
      padding: '8px 8px 30px 8px',
    };
  }, []);
  return (
    <NavLink
      style={deleteDefaultStyles ? style : { ...defaultStyles, ...style }}
      to={to}
      onClick={(e) => {
        e.stopPropagation();
        // e.preventDefault();
        onClick?.(e);
      }}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

export const useColumns = ({ onClose }) => {
  const { formatDate, formatTime } = useDateFormat();

  return [
    {
      field: 'Shipment ID',
      title: <Typography variant='c3'>SHIPMENT ID</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        return (
          <div>
            <Typography variant='s3'>{rowData?.shipment_id}</Typography>
            <NavLinkWrapper
              onClick={() => onClose()}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </div>
        );
      },
    },
    {
      field: 'Status',
      title: <Typography variant='c3'>STATUS</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const status = rowData?.status;
        const paramsInStatus = rowData.assigned_to_1 ? getStatusParamsInStatus(status) : getPlannerStatus(rowData);
        const { bgStatus, colorStatus, statusName } = paramsInStatus || {};

        return (
          <div>
            <Chip
              label={statusName?.toUpperCase()}
              labelColor={colorStatus}
              bgColor={bgStatus}
              fontSize='11px'
              fontWeight={500}
            />
            <NavLinkWrapper
              onClick={() => onClose()}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </div>
        );
      },
    },
    {
      field: 'ORIGIN CITY, STATE',
      title: <Typography variant='c3'>ORIGIN</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const typeId = rowData.shipment_stops[0]?.scheduled_type;
        const scheduledOrigin = rowData.shipment_stops[0].scheduled_date;
        const fromData = rowData.shipment_stops[0]?.from;
        const toData = rowData.shipment_stops[0]?.to;

        const from = `${scheduledOrigin} ${fromData || ''}`;
        const to = `${scheduledOrigin} ${toData || ''}`;

        const city = rowData.shipment_stops[0]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[0]?.stop_point?.state?.short_name;
        return (
          <div>
            <span className='rowData_wrapper}' style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
            </span>
            <div>
              <Typography variant='b3'>{formatDate(scheduledOrigin)} </Typography>
              <br />
              <span style={{ color: palette.dark800 }}>
                {fromData ? ` ${formatTime(from)}` : ''}
                {toData ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </div>
            <NavLinkWrapper
              onClick={() => onClose()}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </div>
        );
      },
    },
    {
      field: 'DESTINATION CITY, STATE',
      title: <Typography variant='c3'>DESTINATION</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const typeId = rowData.shipment_stops?.[rowData.shipment_stops.length - 1]?.scheduled_type;
        const scheduledDestination = rowData.shipment_stops[rowData.shipment_stops.length - 1].scheduled_date;
        const fromData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.from;
        const toData = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.to;

        const from = `${scheduledDestination} ${fromData || ''}`;
        const to = `${scheduledDestination} ${toData || ''}`;

        const city = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.city?.name;
        const state = rowData.shipment_stops[rowData.shipment_stops.length - 1]?.stop_point?.state?.short_name;
        return (
          <div>
            <span className='rowData_wrapper' style={{ color: palette.dark800 }}>
              {city && `${city},`} {state}
            </span>
            <div>
              <Typography variant='b3'>{formatDate(scheduledDestination)} </Typography>
              <br />
              <span style={{ color: palette.dark800 }}>
                {fromData ? `${formatTime(from)}` : ''}
                {toData ? ` to ${formatTime(to)}` : ''}
                {!!typeId && `(${SCHEDULED_DATE_TYPE_OBJ[typeId].shortName})`}
              </span>
            </div>
            <NavLinkWrapper
              onClick={() => onClose()}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </div>
        );
      },
    },
    {
      field: 'MILES',
      title: <Typography variant='c3'>TOTAL MILES</Typography>,
      cellStyle: { position: 'relative' },
      render: (rowData) => {
        const { loaded_miles, empty_miles } = rowData;
        const miles = Math.round(loaded_miles + empty_miles);
        return (
          <div>
            <Typography variant='s3' style={{ color: palette.dark800 }}>
              {miles && miles}
            </Typography>
            <NavLinkWrapper
              onClick={() => onClose()}
              to={
                Number(rowData.status_id) === 8
                  ? `/planner/pending-dispatch?id=${rowData.shipment_id}`
                  : `/shipment/${rowData?.shipment_id}`
              }
            />
          </div>
        );
      },
    },
  ];
};
