import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Radio from 'common/Radio';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import { InputWithIcon, InputWithIconAndText } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getAllCustomers } from 'Api/Customers';
import { getErrorMessage } from 'utils/error';
import { deleteSalesCommission } from 'Api/Staff';
import { SPaymentOptions, STable, SAddMore, STableData, SActions } from './AddStaff.styles';

const PaymentOptions = ({ formik, staffId, onSuccess }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [customers, setCustomers] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { values, setFieldValue, handleBlur, touched, errors } = formik;

  const getCustomers = async () => {
    try {
      const { data } = await getAllCustomers();
      setCustomers(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const deleteCommission = async (commission) => {
    try {
      setLoadingDelete(true);
      await deleteSalesCommission({ staffId, id: commission.id });
      showToaster({ type: 'success', message: 'Commission has been successfully deleted!' });
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const addNewForm = () => {
    setFieldValue(`commissions[${values.commissions.length}]`, {
      isNew: true,
      id: Math.random(),
      customer: null,
      type: 1,
      flat_per_shipment: '',
      percentage_per_shipment: '',
      percentage_after_driver_pay: '',
      is_active: false,
      end_date: null,
    });
  };

  const deleteRow = (index) => {
    if (loadingDelete) {
      return;
    }

    if (values.commissions[index].isNew) {
      setFieldValue(
        `commissions`,
        values.commissions.filter((item, i) => i !== index)
      );
    } else {
      deleteCommission(values.commissions[index]);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <SPaymentOptions>
      <div className='mt-5'>
        <div>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              smail={false}
              type='switch'
              id='payPerHour'
              checked={values?.per_hr}
              onChange={(checked) => setFieldValue('per_hr', checked)}
            />
            <Typography variant='s2' className='ms-2' style={{ color: palette.gray700 }}>
              Per Hour
            </Typography>
          </div>
        </div>
        {!!values?.per_hr && (
          <div className='mt-3 ms-4 w-25'>
            <InputWithIconAndText
              width='150px'
              type='number'
              name='per_hr_amt'
              text='Per Hour'
              placeholder='0.00'
              value={values?.per_hr_amt || ''}
              onChange={(e) => {
                if (e.target.value < 0) {
                  return;
                }
                formik?.setFieldValue('per_hr_amt', e.target.value);
              }}
              onKeyDown={blockNonPositiveNumbers}
            />
          </div>
        )}
      </div>
      <div className='mt-4'>
        <div>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              smail={false}
              type='switch'
              id='payPerBookShipment'
              checked={values?.each_booked_shipment}
              onChange={(checked) => setFieldValue('each_booked_shipment', checked)}
            />
            <Typography variant='s2' className='ms-2' style={{ color: palette.gray700 }}>
              Per Each Book Shipment
            </Typography>
          </div>
        </div>
        {!!values?.each_booked_shipment && (
          <>
            <div className='mt-3 ms-4 w-25'>
              <div className='d-flex' style={{ marginTop: '12px' }}>
                <p className='payment-option'>
                  <input
                    checked={values.flat_or_per === '1'}
                    value={values.flat_or_per}
                    type='radio'
                    name='flat_or_per'
                    onChange={() => setFieldValue('flat_or_per', '1')}
                    className='radio-button'
                  />
                  <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    By Flat
                  </span>
                </p>
                <p className='payment-option'>
                  <input
                    type='radio'
                    name='flat_or_per'
                    checked={values.flat_or_per === '2'}
                    value={values.flat_or_per}
                    onChange={() => setFieldValue('flat_or_per', '2')}
                    className='radio-button'
                  />

                  <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    By Percentage{' '}
                  </span>
                </p>
              </div>
            </div>
            <div className='ms-4'>
              {values.flat_or_per === '1' && (
                <InputWithIcon
                  width='150px'
                  type='number'
                  name='flat_amt'
                  placeholder='Flat rate'
                  value={values.flat_amt}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      return;
                    }
                    formik?.setFieldValue('flat_amt', e.target.value);
                  }}
                  onKeyDown={blockNonPositiveNumbers}
                />
              )}
              {values.flat_or_per === '2' && (
                <InputWithIcon
                  width='150px'
                  type='number'
                  icon='%'
                  name='percentage'
                  placeholder='Percentage'
                  value={values.percentage}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      return;
                    }
                    formik?.setFieldValue('percentage', e.target.value);
                  }}
                  onKeyDown={blockNonPositiveNumbers}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className='mt-4'>
        <div>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              smail={false}
              type='switch'
              id='payPerSales'
              checked={values?.sales_commission_option}
              onChange={(checked) => setFieldValue('sales_commission_option', checked)}
            />
            <Typography variant='s2' className='ms-2' style={{ color: palette.gray700 }}>
              Sales Agent Commission
            </Typography>
          </div>
        </div>
        {!!values?.sales_commission_option && (
          <div className='sales-agent-table-wrapper'>
            <STable>
              <thead>
                <tr>
                  <th>CUSTOMER</th>
                  <th>FLAT PER SHIPMENT</th>
                  <th>% OF SHIPMENT GROSS</th>
                  <th>% OF SHIPMENT AFTER DRIVER PAY</th>
                  <th>END DATE</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {values.commissions?.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <Autocomplete
                        size='small'
                        width='240px'
                        name='customer'
                        labelKey='company_name'
                        options={customers}
                        value={values.commissions[index].customer}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, value) => setFieldValue(`commissions[${index}].customer`, value)}
                      />
                    </td>
                    <td>
                      <div className='d-flex align-items-center gap-2'>
                        <Radio
                          name={`commissions[${index}].type`}
                          id='type-1'
                          value={1}
                          checked={values.commissions[index].type === 1}
                          onChange={() => formik.setFieldValue(`commissions[${index}].type`, 1)}
                        />
                        <InputWithIcon
                          size='small'
                          width='150px'
                          type='number'
                          name='flat_per_shipment'
                          placeholder='0'
                          value={values.commissions[index].flat_per_shipment}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            formik?.setFieldValue(`commissions[${index}].flat_per_shipment`, e.target.value);
                          }}
                          onKeyDown={blockNonPositiveNumbers}
                          disabled={values.commissions[index].type !== 1}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center gap-2'>
                        <Radio
                          name={`commissions[${index}].type`}
                          id='type-2'
                          value={2}
                          checked={values.commissions[index].type === 2}
                          onChange={() => formik.setFieldValue(`commissions[${index}].type`, 2)}
                        />
                        <InputWithIcon
                          size='small'
                          width='150px'
                          type='number'
                          icon='%'
                          name='percentage_per_shipment'
                          placeholder='0'
                          value={values.commissions[index].percentage_per_shipment}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            formik?.setFieldValue(`commissions[${index}].percentage_per_shipment`, e.target.value);
                          }}
                          onKeyDown={blockNonPositiveNumbers}
                          disabled={values.commissions[index].type !== 2}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center gap-2'>
                        <Radio
                          name={`commissions[${index}].type`}
                          id='type-3'
                          value={3}
                          checked={values.commissions[index].type === 3}
                          onChange={() => formik.setFieldValue(`commissions[${index}].type`, 3)}
                        />
                        <InputWithIcon
                          size='small'
                          width='150px'
                          type='number'
                          icon='%'
                          name='percentage_after_driver_pay'
                          placeholder='0'
                          value={values.commissions[index].percentage_after_driver_pay}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            formik?.setFieldValue(`commissions[${index}].percentage_after_driver_pay`, e.target.value);
                          }}
                          onKeyDown={blockNonPositiveNumbers}
                          disabled={values.commissions[index].type !== 3}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center gap-3'>
                        <CustomCheckbox
                          checked={values.commissions[index].is_active}
                          onChange={(checked) => setFieldValue(`commissions[${index}].is_active`, checked)}
                        >
                          <Typography variant='s2' className='ms-2 nowrap' style={{ color: palette.gray700 }}>
                            While Active
                          </Typography>
                        </CustomCheckbox>
                        <div className='date-picker-wrapper'>
                          <DatePicker
                            size='small'
                            name={`commissions[${index}].end_date`}
                            value={values.commissions[index].end_date}
                            onBlur={handleBlur}
                            onChange={(val) => setFieldValue(`commissions[${index}].end_date`, val)}
                            disabled={!!values.commissions[index].is_active}
                            disablePast
                            error={touched?.commissions?.[index]?.end_date && errors?.commissions?.[index]?.end_date}
                          />
                        </div>
                      </div>
                    </td>
                    <STableData $width='52px'>
                      {index > 0 && (
                        <SActions className='flex-item'>
                          <span className='action-wrapper' onClick={() => deleteRow(index)}>
                            <DeleteIcon width={11} height={11} fill={palette.red500} />
                          </span>
                        </SActions>
                      )}
                    </STableData>
                  </tr>
                ))}
              </tbody>
            </STable>
            <SAddMore onClick={addNewForm}>
              <PlusIcon />
              <Typography variant='s2' style={{ color: use(palette.indigo500, palette.gray200) }}>
                Add Another...
              </Typography>
            </SAddMore>
          </div>
        )}
      </div>
    </SPaymentOptions>
  );
};

export default PaymentOptions;
