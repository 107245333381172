import React, { useState } from 'react';
import moment from 'moment';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Modal from 'common/Modal';
import { DateTimePicker } from 'common/Pickers';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import { customerTimeToUtc } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import { updateDetentionDate } from 'Api/Equipment';
import { validationSchema } from './validationSchema';

const ModifyDuration = ({ open, onClose, data, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const { convertToCustomerTime } = useDateFormat();

  const onSubmit = async (values) => {
    try {
      if (moment(values.departed_date).isSameOrBefore(values.arrived_date)) {
        showToaster({ type: 'error', message: 'End date cannot be before start date!' });
        return;
      }

      setLoading(true);
      const body = {
        arrived_date: customerTimeToUtc(
          moment(values.arrived_date).format('YYYY-MM-DD HH:mm:ss'),
          'YYYY-MM-DD HH:mm:ss'
        ),
        departed_date: customerTimeToUtc(
          moment(values.departed_date).format('YYYY-MM-DD HH:mm:ss'),
          'YYYY-MM-DD HH:mm:ss'
        ),
      };

      await updateDetentionDate(data.id, body);
      showToaster({ type: 'success', message: 'Duration has been successfully modified!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, handleBlur } = useForm({
    initialValues: {
      arrived_date: moment(convertToCustomerTime(data.arrived_date)).toDate(),
      departed_date: moment(convertToCustomerTime(data.departed_date)).toDate(),
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Modify Duration'
      $maxWidth='460px'
      $minWidth='460px'
      $bgColor={palette.gray0}
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', className: 'modal-cancel-btn' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Update',
          className: 'modal-submit-btn',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='d-flex gap-2 mb-4'>
        <div className='d-flex flex-column w-100'>
          <div className='d-flex flex-column'>
            <DateTimePicker
              required
              label='Start'
              name='arrived_date'
              onBlur={handleBlur}
              value={values.arrived_date}
              onChange={(val) => handleChange('arrived_date', val)}
            />
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          <ArrowRight fill={palette.indigo500} />
        </div>
        <div className='d-flex flex-column w-100'>
          <div className='d-flex flex-column'>
            <DateTimePicker
              required
              label='End'
              onBlur={handleBlur}
              name='departed_date'
              value={values.departed_date}
              onChange={(val) => handleChange('departed_date', val)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyDuration;
