import React from 'react';
import TableFooter from 'components/TableFooter/TableFooter';

const Pagination = ({ data, rowPerPage, onChangeRowPerPage, onPageChange, rowsPerPageOptions, ...props }) => {
  return (
    <TableFooter
      rowPerPage={rowPerPage}
      totalCount={data?.total || 0}
      totalLength={data?.data?.length || 0}
      lastPage={data?.last_page || 1}
      currentPage={data?.current_page || 1}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
      rowsPerPageOptions={rowsPerPageOptions}
      {...props}
    />
  );
};

export default Pagination;
