import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import sm from './CustomTextArea.module.css';
import { useTheme } from '../../context/themeContext';
import { palette } from '../../utils/constants';
import { Typography } from '../Typography';

const CustomTextArea = ({ field, form, style, classNameLabel, required, styleLabel, fitResize = false, ...props }) => {
  const { use } = useTheme();
  const ref = useRef();

  const onchange = (value) => {
    form && form?.setFieldValue(field.name, value);
    props?.onChange && props.onChange(value);
  };
  function resize() {
    const text = ref?.current;
    if (text) {
      text.style.height = 'auto';
      text.style.height = `${text.scrollHeight}px`;
    }
  }

  useEffect(() => {
    !!fitResize && resize();
  }, [ref?.current, props?.value, field?.value, fitResize]);

  return (
    <label style={{ ...styleLabel }} className={classNames(sm.textArea_label, classNameLabel)}>
      {props?.label !== undefined && <Typography variant='s2'>{props.label}</Typography>}
      {!!required && <span className={sm.input_required}>*</span>}

      <textarea
        {...field}
        {...props}
        ref={ref}
        onChange={(e) => onchange(e.target.value)}
        value={props?.value !== undefined ? props?.value : field.value}
        style={{
          ...style,
          marginTop: props?.label ? 8 : 0,
          color: use(palette.gray700, palette.gray200),
          backgroundColor: use(palette.white, palette.dark800),
        }}
        className={sm.customTextArea}
      />
    </label>
  );
};

export default CustomTextArea;
