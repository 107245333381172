import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { useLocation, useParams } from 'react-router-dom';

import { palette } from 'utils/constants';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import {
  CreateGeofence,
  DeleteGeofence,
  getVehicleAndTrailersForGeofence,
  resetGeofence,
  UpdateGeofence,
  UpdateStopPoint,
} from 'Api/StopPoint';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomButton from 'components/CustomButton/CustomButton';
import { Typography } from 'components/Typography';
import { Icons } from 'assets/icons';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import styles from './General.module.scss';

let areaPoints = [];

const GeneraGeoEdit = ({ stopPointsItem, geofenceData, getGeofence, getStopPointItem, updateIndex }) => {
  const showToaster = useShowToaster();
  const { state: rowData } = useLocation();
  const { id } = useParams();
  const [editGeo, setEditGeo] = useState(false);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const stop_point_id = rowData?.stop_point_id;
  const flagPoint = [
    Number(stopPointsItem?.stop_point?.geofencing_longitude),
    Number(stopPointsItem?.stop_point?.geofencing_latitude),
  ];

  function extractGpsData(data) {
    return (data || [])
      .map((item) => {
        if (item.equipment?.location_target === 'Equipment') {
          if (item.equipment?.equipment_gps) {
            return {
              latitude: item.equipment.equipment_gps.latitude,
              longitude: item.equipment.equipment_gps.longitude,
              heading: item.equipment.equipment_gps.heading,
              vehicleOrTrailer: item.equipment?.equipment_type_id < 12 ? 1 : 2,
              hooked_to: !!item.equipment?.hooked_to,
              equipment_id: item.equipment.equipment_id,
            };
          }
        } else {
          if (item.equipment?.drivers?.length) {
            if (item.equipment?.drivers[0]?.driver_gps) {
              return {
                latitude: item.equipment?.drivers[0]?.driver_gps.latitude,
                longitude: item.equipment?.drivers[0]?.driver_gps.longitude,
                heading: item.equipment?.drivers[0]?.driver_gps.heading,
                vehicleOrTrailer: item.equipment?.equipment_type_id < 12 ? 1 : 2,
                hooked_to: !!item.equipment?.hooked_to,
                equipment_id: item.equipment.equipment_id,
              };
            }
          }
        }
        return null;
      })
      .filter((item) => item !== null);
  }

  async function getVehiclesData() {
    try {
      const params = {
        stop_point_id: id,
      };

      const allVehicles = await getVehicleAndTrailersForGeofence(params);
      const equipmentGpsVehicles = extractGpsData(allVehicles.data);

      equipmentGpsVehicles.forEach((coord) => {
        let markerElement;
        const greyVehiclePlusTrailer = new Image(); // Image constructor
        greyVehiclePlusTrailer.src = Icons.GreyColorVehicleTrailer;

        const greyTrailer = new Image(); // Image constructor
        greyTrailer.src = Icons.GreyColorTrailer;

        const greyColorVehicle = new Image(); // Image constructor
        greyColorVehicle.src = Icons.GreyVehicle;

        if (coord.vehicleOrTrailer === 2 && coord.hooked_to) {
          markerElement = greyVehiclePlusTrailer;
        } else if (coord.vehicleOrTrailer === 2 && !coord.hooked_to) {
          markerElement = greyTrailer;
        } else if (coord.vehicleOrTrailer === 1 && coord.hooked_to) {
          markerElement = greyVehiclePlusTrailer;
        } else if (coord.vehicleOrTrailer === 1 && !coord.hooked_to) {
          markerElement = greyColorVehicle;
        }

        const marker = new TrimbleMaps.Marker({
          draggable: false,
          anchor: 'center',
          rotation: coord.heading || '',
          element: markerElement,
        });

        marker.setLngLat([parseFloat(coord.longitude), parseFloat(coord.latitude)]).addTo(map);

        const popupContent = `
              <div style="padding: 5px; background: white;margin: -10px -10px -15px; color: black; border-radius: 3px;">
                <span>${coord.equipment_id}</span>
              </div>`;

        const popup = new TrimbleMaps.Popup({ closeButton: false }).setHTML(popupContent);

        marker.getElement().addEventListener('mouseenter', () => {
          marker.setPopup(popup).togglePopup();
        });

        marker.getElement().addEventListener('mouseleave', () => {
          marker.togglePopup();
        });
      });
    } catch (e) {
      // do nothing
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      if (areaPoints.length === 0 && geofenceData?.ShapePoints?.length) {
        const data = {
          stop_point_id: id,
        };
        await DeleteGeofence(id, data);
        await UpdateStopPoint(id, {
          auto_check_in: Number(values?.auto_check_in),
        });
        await getStopPointItem(id);
      } else if (areaPoints.length > 2 && !geofenceData?.ShapePoints?.length) {
        // Create
        const points = {};
        const areaPointsArr = areaPoints.length > 2 ? [...areaPoints, areaPoints[0]] : [];
        areaPointsArr?.forEach((item, index) => {
          points[index] = {
            0: item[0],
            1: item[1],
          };
        });
        const geoJson = {
          stop_point_id: id,
          points,
        };
        await CreateGeofence(geoJson);
        await UpdateStopPoint(id, {
          auto_check_in: Number(values?.auto_check_in),
        });
        await getStopPointItem(id);
      } else if (areaPoints.length > 2 && geofenceData?.ShapePoints?.length) {
        // Update
        const points = {};
        const areaPointsArr = areaPoints.length > 2 ? [...areaPoints, areaPoints[0]] : [];
        areaPointsArr?.forEach((item, index) => {
          points[index] = {
            0: item[0],
            1: item[1],
          };
        });
        const geoJson = {
          stop_point_id: id,
          points,
        };
        await UpdateGeofence(geoJson);
        await UpdateStopPoint(id, {
          auto_check_in: Number(values?.auto_check_in),
        });
        await getStopPointItem(id);
      } else if (areaPoints.length === 1 && geofenceData.ShapeType === 1) {
        await UpdateStopPoint(id, {
          auto_check_in: Number(values?.auto_check_in),
        });
      } else {
        if (areaPoints.length < 3) {
          showToaster({
            type: 'error',
            message: `The number of markers must be not less than 3. The remaining markers count is ${
              3 - areaPoints.length
            }`,
          });
        }
      }
      await getGeofence(id);
      setEditGeo(false);
      await getStopPointItem(id);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setEditGeo(false);

    if (map) {
      onClear();
      areaPoints = geofenceData?.ShapePoints || [];
      !!map && drawArea(map, areaPoints);
    }
  };

  const onClear = () => {
    areaPoints = [];
    try {
      !!map?.getLayer('hqPoly') && map.removeLayer('hqPoly');
      !!map?.getLayer('hqOutline') && map.removeLayer('hqOutline');
      !!map?.getLayer('polygon') && map.removeLayer('polygon');
      !!map?.getSource('hqSource') && map.removeSource('hqSource');
      !!map?.getSource('pointSource') && map.removeSource('pointSource');
    } catch (e) {
      // Do nothing
    }
  };

  const onReset = async () => {
    try {
      setLoadingReset(true);
      onClear();
      await resetGeofence(id);
      showToaster({ type: 'success', message: 'Geofence has been successfully reset!' });
      await getGeofence(id);
      setEditGeo(false);
      await getStopPointItem(id);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingReset(false);
    }
  };

  const drawMarker = (map, point) => {
    getVehiclesData();
    const marker = document.createElement('div');
    marker.innerHTML = `
                     <div class='myMap_marker_container'>
                         <img src="${mapMarkerIcon}" alt="M">
                     </div>
                     `;
    return new TrimbleMaps.Marker({
      id: 'marker',
      element: marker,
    })
      .setLngLat(point)
      .addTo(map);
  };

  const drawPolygon = (map, points, drawing = false) => {
    const geoJsonData = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [points],
            },
          },
        ],
      },
    };

    try {
      if (geofenceData.ShapeType === 5 || drawing) {
        // Add GeoJSON data source to the map
        map.addSource('hqSource', geoJsonData);
        // Add a layer to draw circles for each point in the data source
        map.addLayer({
          id: 'hqPoly',
          type: 'fill',
          source: 'hqSource',
          paint: {
            'fill-color': '#767FFB',
            'fill-opacity': 0.5,
          },
        });
        // Add a black outline around the polygon.
        map.addLayer({
          id: 'hqOutline',
          type: 'line',
          source: 'hqSource',
          layout: {},
          paint: {
            'line-color': '#767FFB',
            'line-width': 3,
          },
        });
      } else {
        map.addSource('pointSource', createGeoJSONCircle(points[0], geofenceData.Radius));
        map.addLayer({
          id: 'polygon',
          type: 'fill',
          source: 'pointSource',
          layout: {},
          paint: {
            'fill-color': '#767FFB',
            'fill-opacity': 0.6,
          },
        });
      }
    } catch (e) {
      // Do nothing
    }
  };

  function createGeoJSONCircle(center, radiusInKm, points) {
    if (!points) points = 64;

    const coords = {
      latitude: center[1],
      longitude: center[0],
    };

    const km = radiusInKm;

    const ret = [];
    const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta;
    let x;
    let y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [ret],
            },
          },
        ],
      },
    };
  }

  const drawArea = (map, areaPoints) => {
    drawMarker(map, flagPoint);
    if (areaPoints?.length) {
      drawPolygon(map, areaPoints);
      const sw = areaPoints?.map((arr) => arr[0]);
      const ne = areaPoints?.map((arr) => arr[1]);
      const sMax = Math.max(...sw);
      const sMin = Math.min(...sw);
      const nMax = Math.max(...ne);
      const nMin = Math.min(...ne);
      map.fitBounds([sMin, nMin, sMax, nMax], { padding: 50 });
    } else {
      map.fitBounds([flagPoint, flagPoint], { maxZoom: 15 });
    }
  };

  const mapClickHandler = (e) => {
    const first = areaPoints?.[0];
    const last = areaPoints?.[areaPoints.length - 1];
    if (areaPoints.length < 2 || first[0] !== last[0] || first[1] !== last[1]) {
      areaPoints = [...areaPoints, [e.lngLat.lng, e.lngLat.lat]];
      try {
        !!map?.getLayer('hqPoly') && map.removeLayer('hqPoly');
        !!map?.getLayer('hqOutline') && map.removeLayer('hqOutline');
        !!map?.getLayer('polygon') && map.removeLayer('polygon');
        !!map?.getSource('hqSource') && map.removeSource('hqSource');
        !!map?.getSource('pointSource') && map.removeSource('pointSource');
      } catch (e) {
        // Do nothing
      }
      drawPolygon(map, areaPoints, true);
    }
  };

  const initMap = () => {
    try {
      TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;

      const mapInstance = new TrimbleMaps.Map({
        container: 'generalEditMap',
        style: TrimbleMaps.Common.Style.SATELLITE,
        center: flagPoint,
      });
      setMap(mapInstance);

      mapInstance.on('load', () => {
        drawArea(mapInstance, areaPoints, updateIndex);
      });
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    areaPoints = geofenceData?.ShapePoints || [];
    if (map) {
      drawArea(map, areaPoints);
    } else {
      initMap();
    }
  }, [geofenceData, map, updateIndex]);

  useEffect(() => {
    if (map) {
      if (editGeo) {
        map.on('click', mapClickHandler);
      } else {
        map.off('click', mapClickHandler);
      }
    }

    return () => {
      if (map) {
        map.off('click', mapClickHandler);
      }
    };
  }, [map, editGeo, mapClickHandler]);

  useEffect(() => {
    return () => {
      setMap(null);
    };
  }, [updateIndex]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        stop_point_id,
        auto_check_in: stopPointsItem?.stop_point?.auto_check_in,
      }}
    >
      {({ values, submitForm, setFieldValue }) => (
        <Form>
          <div className={styles.general_wrapper}>
            <div className={styles.general_geoFencing}>
              {editGeo ? (
                <>
                  <div className={styles.header2}>
                    <Typography variant='h2' style={{ color: palette.gray900 }}>
                      Geo-Fencing
                    </Typography>
                    <div className='d-flex align-items-center gap-2'>
                      <CustomButton
                        onClick={onCancel}
                        type='secondary'
                        title='Cancel'
                        styleButton={{ marginTop: 0, marginRight: 0 }}
                        styleTitle={{ fontSize: 14, fontWeight: 500 }}
                      />
                      <CustomButton
                        onClick={onReset}
                        type='secondary'
                        title='Reset'
                        styleButton={{ marginTop: 0, marginRight: 0 }}
                        styleTitle={{ fontSize: 14, fontWeight: 500 }}
                        disabled={loadingReset}
                      />
                      <CustomButton
                        onClick={submitForm}
                        type='primary'
                        title='Save'
                        styleButton={{ marginTop: 0, marginRight: 0 }}
                        styleTitle={{ fontSize: 14, fontWeight: 500 }}
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div className={styles.checked_info_wrapper_edit}>
                    <div>
                      <label className={styles.label_wrapper2}>
                        <Field name='auto_check_in'>
                          {({ field }) => {
                            return (
                              <CustomCheckbox
                                field={field}
                                checked={!!field.value}
                                name='auto_check_in'
                                withinForm
                                onChange={() => {
                                  setFieldValue('auto_check_in', +!values.auto_check_in);
                                }}
                              >
                                <Typography
                                  variant='s2'
                                  style={{
                                    color: palette.gray700,
                                    marginLeft: 5,
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  Auto Check In
                                </Typography>
                              </CustomCheckbox>
                            );
                          }}
                        </Field>
                      </label>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
                        <InfoIcon />
                        <Typography
                          variant='s2'
                          style={{
                            color: palette.gray700,
                            marginLeft: 6,
                          }}
                        >
                          Select more then one point on map around location to create geofence
                        </Typography>
                      </div>
                    </div>
                    <CustomButton
                      onClick={onClear}
                      type='secondary'
                      title='Clear'
                      styleButton={{ marginTop: 0, marginRight: 0 }}
                      styleTitle={{ fontSize: 14, fontWeight: 500 }}
                      className={styles.action}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.header2}>
                    <Typography variant='h2' style={{ color: palette.gray900 }}>
                      Geo-Fencing
                    </Typography>
                    <div
                      onClick={() => {
                        setEditGeo(true);
                      }}
                      className={styles.icon_wrapper}
                    >
                      <EditIcon className={styles.icon} />
                    </div>
                  </div>
                  <div className={styles.checked_info_wrapper}>
                    <Typography variant='b2' style={{ color: palette.gray500 }}>
                      Auto Check In
                    </Typography>
                    {stopPointsItem?.stop_point?.auto_check_in ? (
                      <div className={styles.infoYes} style={{ marginLeft: 40 }}>
                        <Typography variant='overLine' style={{ color: palette.green500 }}>
                          YES
                        </Typography>
                      </div>
                    ) : (
                      <div className={styles.infoNo} style={{ marginLeft: 40 }}>
                        <Typography variant='overLine' style={{ color: palette.red500 }}>
                          NO
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0 0 10px 20px' }}>
                    <InfoIcon />
                    <Typography
                      variant='s2'
                      style={{
                        color: palette.gray700,
                        marginLeft: 6,
                      }}
                    >
                      Select more then one point on map around location to create geofence
                    </Typography>
                  </div>
                </>
              )}

              <div className={styles.map_container}>
                <div id='generalEditMap' style={{ height: '100%', width: '100%' }} />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GeneraGeoEdit;
