import moment from 'moment';

export const getInitialValues = (settings) => {
  const { highway_tax_active, highway_tax_expire_date, highway_tax_date_attach } = settings || {};

  return {
    highway_tax_active: !!highway_tax_active,
    highway_tax_expire_date: highway_tax_expire_date ? moment(highway_tax_expire_date).toDate() : null,
    highway_tax_no_expire: !highway_tax_expire_date,
    highway_tax_date_attach: highway_tax_date_attach || null,
  };
};

export const highwayTaxFields = {
  id: 1,
  title: 'Highway Tax',
  policy_type: 'highway_tax',
  active: 'highway_tax_active',
  expire_date: 'highway_tax_expire_date',
  no_expire: 'highway_tax_no_expire',
  attachment: 'highway_tax_date_attach',
};
