import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Toast } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ManageType } from 'Api/Equipment';

import authHeader from 'services/auth-header';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Icons } from 'assets/icons';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { getVendorsList } from 'Api/Vendors';
import { getAllTrailers, getAllVehicles } from 'Api/Map';
import AddVendor from 'pages/Accounting/Vendors/components/AddVendor';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import InputLabel from 'common/InputLabel';
import { InputWithIcon } from 'common/Input';
import { blockNonPositiveNumbers } from 'utils/helpers';
import ManageTypes from './ManageTypes/ManageTypes';
import style from './Maintanance.module.css';

const AddRecordModal = ({ AddRecordModalClose, profileData, getRecordSuccess, equipmentMaintanance }) => {
  const showToaster = useShowToaster();
  const [manageTypes, setManageTypes] = useState([]);
  const [manageTypeSuccess, setManageTypeSuccess] = useState();
  const [fileName, setFileName] = useState();
  const [loading, setLoading] = useState(false);
  const [openAddVendor, setOpenAddVendor] = useState(false);
  const [openmanagetypes, setManageTypesOpen] = useState(false);
  const handleManageTypesOpen = () => setManageTypesOpen(true);
  const handleManageTypesClose = () => setManageTypesOpen(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const { convertToCustomerTime } = useDateFormat();
  const [startDate, setStartDate] = useState(new Date(convertToCustomerTime()));

  const getManageType = (data) => {
    setManageTypeSuccess(data);
  };

  const AddRecordErrorMessage = () => {
    setErrorMessage(true);
    setTimeout(() => {
      AddRecordErrorMessageClose();
    }, 2000);
  };

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getVehicles = async () => {
    try {
      const { data } = await getAllVehicles();
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getTrailers = async () => {
    try {
      const { data } = await getAllTrailers();
      setTrailers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const AddRecordErrorMessageClose = () => {
    setErrorMessage(false);
  };
  const API_URL = process.env.REACT_APP_API_URL;

  async function MaintenanceRecordManageType() {
    ManageType().then((res) => {
      if (res) {
        setManageTypes(res?.data);
      } else {
        setManageTypes([]);
      }
    });
  }

  useEffect(() => {
    MaintenanceRecordManageType();
  }, [manageTypeSuccess]);

  const SelectManageType = manageTypes.map((t) => ({
    label: t.type,
    value: t.id,
  }));

  const ValidationSchema = Yup.object().shape({
    record_type_id: Yup.object({
      label: Yup.string(),
    })
      .nullable()
      .required('Required')
      .test('has-label', 'Type is required', (obj) => !obj || (!!obj && !!obj.label)),
    description: Yup.string().required('Required'),
    service_date_time: Yup.string().required('Required'),
    equipment: Yup.object()
      .shape({ id: Yup.number().required('Required') })
      .required('Required')
      .nullable(),
    record_serviced_at_id: Yup.object({
      name: Yup.string(),
    })
      .nullable()
      .required('Required')
      .test('has-label', 'Serviced At is required', (obj) => !obj || (!!obj && !!obj.id)),
    cost: Yup.string().trim().required('Required'),
    mechanic_notes: Yup.string().when('mark_completed', {
      is: 1,
      then: Yup.string().trim().required('Required'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      record_type_id: null,
      description: '',
      service_date_time: convertToCustomerTime(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      record_serviced_at_id: '',
      cost: '',
      repair_receipt_attach: '',
      mechanic_notes: '',
      mark_completed: 0,
      equipment: profileData || null,
      isTrailer: false,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      if (values.mark_completed && !values.mechanic_notes) {
        return;
      }
      const formData = new FormData();
      formData.append('record_type_id', values.record_type_id?.value);
      if (values.description) {
        formData.append('description', values.description);
      }
      formData.append('service_date_time', values.service_date_time);
      if (values.record_serviced_at_id) {
        formData.append('record_serviced_at_id', values.record_serviced_at_id?.id);
      }
      if (values.cost) {
        formData.append('cost', values.cost);
      }
      if (values.repair_receipt_attach) {
        formData.append('repair_receipt_attach', values.repair_receipt_attach);
      }
      formData.append('mechanic_notes', values.mechanic_notes);
      formData.append('mark_complete', values.mark_completed);
      if (values?.mark_completed === 1) {
        formData.append('status_id', '1');
      }
      formData.append('equipment_id', values.equipment?.id);
      try {
        setLoading(true);
        await axios
          .post(`${API_URL}/equipment/maintenance-record`, formData, {
            headers: authHeader(),
          })
          .then((res) => {
            if (res?.status === 200) {
              getRecordSuccess(Date.now());
              AddRecordModalClose();
              showToaster({ type: 'success', message: 'Record Added Successfully!' });
              equipmentMaintanance();
              setLoading(false);
            }
          });
      } catch (err) {
        if (err) {
          setLoading(false);
          AddRecordErrorMessage();
        }
      }
    },
  });

  useEffect(() => {
    getVendors();

    if (!profileData) {
      getVehicles();
      getTrailers();
    }
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <p className={style.recordHeader}>Add Record</p>
          </div>
          <div className={style.recordBody}>
            {!profileData && (
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Vehicle
                </Typography>
                <CustomCheckbox
                  type='switch'
                  name='isTrailer'
                  checked={formik?.values?.isTrailer}
                  onChange={(checked) => {
                    formik.setFieldValue('isTrailer', checked);
                    formik.setFieldValue('equipment', null);
                  }}
                  smail={false}
                />
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Trailer
                </Typography>
              </div>
            )}
            <div className={style.recordTypeSelect}>
              <Autocomplete
                required
                label={profileData ? '' : formik?.values.isTrailer ? 'Trailer' : 'Vehicle'}
                name='equipment'
                value={formik?.values?.equipment}
                onChange={(e, value) => formik.setFieldValue('equipment', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={formik?.values.isTrailer ? trailers : vehicles}
                placeholder={formik?.values.isTrailer ? 'Select Trailer..' : 'Select Vehicle..'}
                getOptionLabel={(option) => {
                  return option ? `${option.equipment_id} (${option.make} ${option.model_id})` : '';
                }}
                disabled={profileData}
              />
              {formik.errors.equipment && formik.touched.equipment ? (
                <div
                  className={style['add-vendor-modal-content']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                    marginTop: '12px',
                  }}
                >
                  {formik.errors.equipment}{' '}
                </div>
              ) : null}
            </div>
            <div className='mt-4'>
              <div className='d-flex justify-content-between'>
                <InputLabel required>Type</InputLabel>
                <Typography
                  variant='s2'
                  onClick={handleManageTypesOpen}
                  style={{ cursor: 'pointer', color: palette.indigo500 }}
                >
                  Manage Types
                </Typography>
              </div>
              <Autocomplete
                name='record_type_id'
                required
                value={formik?.values?.record_type_id}
                onChange={(e, value) => {
                  formik.setFieldValue('record_type_id', value);
                }}
                labelKey='label'
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                options={SelectManageType}
                placeholder='Select Type..'
              />

              {formik.errors.record_type_id && formik.touched.record_type_id ? (
                <div
                  className={style['add-vendor-modal-content']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                    marginTop: '12px',
                  }}
                >
                  {formik.errors.record_type_id}{' '}
                </div>
              ) : null}
            </div>
            <div className='mt-4'>
              <span className={style.recordFormHeader}>
                Description <span style={{ color: 'blue' }}>*</span>
              </span>
              <div className='mt-1'>
                <textarea
                  className={style.recordFormDescription}
                  name='description'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows='3'
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className={style['add-vendor-modal-content']} style={{ color: '#D12953', fontSize: '12px' }}>
                    {formik.errors.description}{' '}
                  </div>
                ) : null}
              </div>
            </div>

            <div className='mt-4 mb-2'>
              <div className='d-flex justify-content-between mb-2'>
                <span className={style.recordFormHeader}>
                  Service Date + Time <span style={{ color: 'blue' }}>*</span>
                </span>
              </div>
              <div className={style['add-record-DatePicker']}>
                <img src={Icons.calendar} alt='' />
                <DatePicker
                  className={style['add-record-DatePicker-content']}
                  selected={startDate}
                  showTimeInput
                  shouldCloseOnSelect={false}
                  placeholderText='Select Date'
                  value={formik.values.service_date_time}
                  name='service_date_time'
                  onChange={(date) => {
                    setStartDate(date);
                    formik.setFieldValue('service_date_time', moment(date).format('YYYY-MM-DD HH:mm:ss'));
                  }}
                  dateFormat='MM/dd/yyyy h:mm aa'
                  timeInputLabel='Time:'
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.service_date_time && formik.touched.service_date_time ? (
                <div
                  className={style['add-vendor-modal-content']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                    marginTop: '6px',
                  }}
                >
                  {formik.errors.service_date_time}{' '}
                </div>
              ) : null}
            </div>
            <div>
              <div className='mt-4 mb-2'>
                <div className='d-flex justify-content-end'>
                  <span className={style.manageTypes} onClick={() => setOpenAddVendor(true)}>
                    <PlusIcon style={{ marginRight: '8px' }} />
                    Add Vendor
                  </span>
                </div>
                <div className={style.recordTypeSelect}>
                  <Autocomplete
                    required
                    name='record_serviced_at_id'
                    label='Serviced At'
                    value={formik?.values?.record_serviced_at_id}
                    onChange={(e, value) => formik.setFieldValue('record_serviced_at_id', value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={vendors}
                    placeholder='Select Serviced At..'
                  />
                  {formik.errors.record_serviced_at_id && formik.touched.record_serviced_at_id ? (
                    <div
                      className={style['add-vendor-modal-content']}
                      style={{
                        color: '#D12953',
                        fontSize: '12px',
                        marginTop: '12px',
                      }}
                    >
                      {formik.errors.record_serviced_at_id}{' '}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='mt-4'>
                {!!formik.values.record_serviced_at_id && (
                  <div className={style.venderAddress}>
                    <div className='ms-2 d-flex justify-content-between'>
                      <div>
                        <span className={style.recordFormHeader} style={{ textTransform: 'Capitalize' }}>
                          {formik.values.record_serviced_at_id.name}
                        </span>
                        <br />
                        <span className={style.recordFormHeader} style={{ textTransform: 'Capitalize' }}>
                          {formik.values.record_serviced_at_id.address}
                          <br />
                          {formik.values.record_serviced_at_id?.city?.name
                            ? `${formik.values.record_serviced_at_id?.city?.name},`
                            : ''}
                          <span className='ms-1'>{formik.values.record_serviced_at_id?.state?.name}</span>{' '}
                          {formik.values.record_serviced_at_id?.zipcode}
                        </span>
                      </div>
                      <div>
                        <span>
                          <img
                            src={Icons.CrossRedIcon}
                            alt=''
                            style={{ marginRight: '10px' }}
                            onClick={() => formik.setFieldValue('record_serviced_at_id', null)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='mt-4 ' style={{ paddingBottom: '12px' }}>
              <InputWithIcon
                required
                type='number'
                label='Cost'
                name='cost'
                value={formik.values.cost}
                onChange={(e) => formik.setFieldValue('cost', e.target.value)}
                onKeyDown={(e) => blockNonPositiveNumbers(e)}
              />
              <ErrorMessage error={formik.touched?.cost && formik.errors?.cost} />
            </div>
            <div className='mt-4' htmlFor='file'>
              <div className='mb-2'>
                {' '}
                <span className={style.recordFormHeader}>Upload Repair Receipt</span>
              </div>
              <div className='mt-3'>
                <div className='mt-1 '>
                  <label className={style.recordFormRepairReceipt}>
                    <p type='text'>
                      <input
                        name='repair_receipt_attach'
                        onChange={(e) => {
                          setFileName(e.currentTarget.files[0].name);
                          formik.setFieldValue('repair_receipt_attach', e.currentTarget.files[0]);
                        }}
                        type='file'
                        accept='application/pdf'
                        style={{ display: 'none' }}
                      />
                      {fileName ? (
                        <div style={{ paddingTop: '20px' }}>
                          <img src={Icons?.Doc} alt='' style={{ width: '20px', height: '20px' }} />
                          <p className={style.recordFormHeader}>{fileName}</p>
                        </div>
                      ) : (
                        <>
                          <img src={uploadLicense} alt='' />
                          <p>
                            <span className={style.manageTypes}>Upload document </span>
                            <span className={style.recordFormHeader}> or drag and drop</span>
                          </p>
                        </>
                      )}
                    </p>
                  </label>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <div className='mb-2 d-flex justify-content-between align-items-center'>
                <span className={style.recordFormHeader}>
                  Mechanic Notes {!!formik.values.mark_completed && <span style={{ color: 'blue' }}>*</span>}
                </span>
                <div className='d-flex justify-content-between align-items-center'>
                  <CustomCheckbox
                    style={{ margin: '10px' }}
                    checked={formik.values.mark_completed}
                    onChange={() => {
                      formik.setFieldValue('mark_completed', Number(!formik.values.mark_completed));
                    }}
                  >
                    <span className={style.recordFormHeader} style={{ marginLeft: '10px' }}>
                      Mark Completed
                    </span>
                  </CustomCheckbox>
                </div>
              </div>
              <div className='mt-1'>
                <textarea
                  className={style.recordFormDescription}
                  name='mechanic_notes'
                  value={formik.values.mechanic_notes}
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  rows='3'
                />
                <ErrorMessage error={formik.touched?.mechanic_notes && formik.errors?.mechanic_notes} />
              </div>
            </div>
          </div>
          <div className={style.recordFormFooter}>
            <button className={style.recordFormCancelButton} onClick={AddRecordModalClose}>
              Cancel
            </button>
            {loading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <button type='submit' className={style.recordFormAddRecordButton}>
                Add Record
              </button>
            )}
          </div>
          <Modal
            open={openmanagetypes}
            onClose={handleManageTypesClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <ManageTypes
              handleManageTypesClose={handleManageTypesClose}
              manageTypes={manageTypes}
              getManageType={getManageType}
              MaintenanceRecordManageType={MaintenanceRecordManageType}
            />
          </Modal>
        </div>
      </form>
      <Toast
        show={errorMessage}
        onClose={AddRecordErrorMessageClose}
        className='add-record-error-msg d-flex align-items-center p-2'
        style={{
          backgroundColor: '#D12953',
          left: '20%',
          bottom: '60px',
          height: '60px',
          position: 'fixed',
          width: '300px',
        }}
      >
        <div className='toastMessage'>Error !</div>
      </Toast>
      {openAddVendor && (
        <AddVendor open={openAddVendor} onClose={() => setOpenAddVendor(false)} onSuccess={getVendors} />
      )}
    </>
  );
};

export default AddRecordModal;
