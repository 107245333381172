import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as TrophyIcon } from 'assets/icons/trophy.svg';
import Tabs from 'common/Tabs';
import { PusherJs } from 'common/pusher';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import ConfirmationModal from 'common/ConfirmationModal';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { getTableSettings, getTabs } from 'Api/User';
import { cancelShipment, getOfferedShipments, revertAllOffers } from 'Api/OfferedShipments';

import AddNotes from './components/AddNotes';
import TableHeader from './components/TableHeader';
import ExtendExpiration from './components/ExtendExpiration';
import { initialFilters, settingsDataMapper, useColumns, actionsColumn } from './ShipmentsTable.data';

const ShipmentsTable = () => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [shipmentsData, setShipmentsData] = useState({ data: [] });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [search, setSearch] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [dragItem, setDragItem] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [rowToUpdate, setRowToUpdate] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const didMountRef = useRef(false);
  const controller = useRef(new AbortController());
  const { value: userData } = useAuth();

  const getTabsData = async (shipmentsData) => {
    try {
      const { data } = await getTabs({ type: 'shipment_offer' });
      setTabs((prevState) => [
        {
          key: 'all',
          label: 'All',
          value: 0,
          count: selectedFilters.tab === 0 ? shipmentsData.total : prevState[0]?.count || 0,
        },
        ...data.map((tab, index) => ({
          key: tab.data.name,
          label:
            tab.data.name === 'Award Accepted' ? (
              <span>
                <TrophyIcon fill={palette.green500} width={18} height={18} /> Award Accepted
              </span>
            ) : (
              tab.data.name
            ),
          value: index + 1,
          count: tab.data.name === 'Awarded & Dispatched' ? null : tab.count,
          filters: tab.data.filters,
        })),
      ]);
    } catch (e) {
      // Do nothing
    }
  };

  const getShipments = async () => {
    try {
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const sortField = selectedFilters.sort?.field ? `sort[][${selectedFilters.sort?.field}]` : null;
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'filters[all]': debouncedSearch || undefined,
        'filters[shipment_id]': selectedFilters.shipment_id || undefined,
        'filters[reference_id]': selectedFilters.reference_id || undefined,
        'filters[status]': selectedFilters.statuses?.length ? selectedFilters.statuses.map((i) => i.id) : undefined,
        'filters[groups]': selectedFilters.groups?.length ? selectedFilters.groups : undefined,
        'filters[customers]': selectedFilters.customers?.length
          ? selectedFilters.customers.map((i) => i.id)
          : undefined,
        'filters[stop_point]': selectedFilters.stop_points?.length
          ? selectedFilters.stop_points.map((i) => i.id)
          : undefined,
        'filters[equipment_types]': selectedFilters.equipmentTypes?.length ? selectedFilters.equipmentTypes : undefined,
        'filters[origin_city]': selectedFilters.origin_city || undefined,
        'filters[origin_state]': selectedFilters.origin_state || undefined,
        'filters[origin_miles]': selectedFilters.origin_miles || undefined,
        'filters[origin_zipcode]': selectedFilters.origin_zipcode || undefined,
        'filters[destination_city]': selectedFilters.destination_city || undefined,
        'filters[destination_state]': selectedFilters.destination_state || undefined,
        'filters[destination_miles]': selectedFilters.destination_miles || undefined,
        'filters[destination_zipcode]': selectedFilters.destination_zipcode || undefined,
        'filters[ediStatus]': selectedFilters.ediStatus?.length
          ? selectedFilters.ediStatus.map((i) => i.id)
          : undefined,
        'filters[origin_scheduled_from]': selectedFilters.pickupRange[0]
          ? moment(selectedFilters.pickupRange[0]).format('YYYY-MM-DD')
          : undefined,
        'filters[origin_scheduled_to]': selectedFilters.pickupRange[1]
          ? moment(selectedFilters.pickupRange[1]).format('YYYY-MM-DD')
          : undefined,
        'filters[destination_scheduled_from]': selectedFilters.deliveryRange[0]
          ? moment(selectedFilters.deliveryRange[0]).format('YYYY-MM-DD')
          : undefined,
        'filters[destination_scheduled_to]': selectedFilters.deliveryRange[1]
          ? moment(selectedFilters.deliveryRange[1]).format('YYYY-MM-DD')
          : undefined,
        'filters[offer_to_drivers]': selectedFilters.offer_to_drivers?.length
          ? selectedFilters.offer_to_drivers.map((i) => i.id)
          : undefined,
        'filters[offer_to_carriers]': selectedFilters.offer_to_carriers?.length
          ? selectedFilters.offer_to_carriers.map((i) => i.id)
          : undefined,
        [sortField]: sortField ? selectedFilters.sort?.sortBy : undefined,
      };

      Object.entries(tabs[selectedFilters.tab]?.filters || {}).forEach(([key, value]) => {
        params[`filters[${key}]`] = value;
      });

      const response = await getOfferedShipments(params, controller?.current?.signal);
      setShipmentsData(response);
      getTabsData(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await getTableSettings({ type: 'shipment_offer' });
      const allData = typeof data?.data === 'string' ? JSON.parse(data?.data) : data.data;
      const tableColumns = allData.columns;
      const dragItems = allData.columns_order;
      const tableColumnsData = [];
      Object.keys(tableColumns).forEach((key) => {
        const value = tableColumns[key];
        tableColumnsData.push({ title: settingsDataMapper(key), value: key, checked: value });
      });
      const tableOrder = dragItems.map((value, index) => {
        return {
          title: settingsDataMapper(value),
          value,
          order: index + 1,
          id: index + 1,
        };
      });
      setTableColumn(tableColumnsData);
      setDragItem(tableOrder);
    } catch (e) {
      // Do nothing
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setLoading(true);
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setLoading(true);
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field, nested_field) => {
    const direction = selectedFilters.sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSelectedFilters((prevState) => ({
      ...prevState,
      sort: { field, nested_field, sortBy: direction },
    }));
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length !== shipmentsData.data.length ? shipmentsData.data : []);
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.shipment_id !== row.shipment_id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const filterTableColumn = (columns) => {
    const order = dragItem.sort((a, b) => a.order - b.order).map((a) => a.value);
    const cols = [];
    order.forEach((value) => {
      const col = columns.find((c) => c.field === value);
      if (col) {
        cols.push({ ...col, hidden: !tableColumn?.find((el) => el.value === value)?.checked });
      }
    });
    return cols;
  };

  const handleTabChange = (tab) => {
    setLoading(true);
    setSelectedFilters((prevState) => ({ ...prevState, tab }));
  };

  const onViewNotes = async (row) => {
    setRowToUpdate({ action: 'viewNotes', data: row });
  };

  const onRevertAll = async (row, confirmed) => {
    if (!confirmed) {
      setRowToUpdate({ action: 'revertAll', data: row });
      return;
    }

    setLoadingAction(true);
    try {
      await revertAllOffers({ shipment_id: row.shipment_id });
      showToaster({ type: 'success', message: 'All offers have been successfully reverted!' });
      navigate(`/planner/plan?id=${row.shipment_id}&tab=External&action=offer`);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onRevertAndResend = async (row, confirmed) => {
    if (!confirmed) {
      setRowToUpdate({ action: 'revertAndResend', data: row });
      return;
    }

    setLoadingAction(true);
    try {
      await revertAllOffers({ shipment_id: row.shipment_id, resend: 1 });
      showToaster({ type: 'success', message: 'All offers have been successfully revered and resent!' });
      setRowToUpdate(null);
      getShipments();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onCancelShipment = async (row, confirmed) => {
    if (!confirmed) {
      setRowToUpdate({ action: 'cancelShipment', data: row });
      return;
    }

    setLoadingAction(true);
    try {
      await cancelShipment({ ids: [row.shipment_id] });
      showToaster({ type: 'success', message: 'Shipment has been successfully canceled!' });
      setRowToUpdate(null);
      getShipments();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingAction(false);
    }
  };

  const onExtendExpiration = (row) => {
    setRowToUpdate({ action: 'extendExpiration', data: row });
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (!didMountRef.current) {
      setLoading(true);
      didMountRef.current = true;
    }
    getShipments();
  }, [selectedFilters, debouncedSearch]);

  useEffect(() => {
    if (shipmentsData?.data?.length) {
      const channelNames = shipmentsData.data.map(
        (i) => `private-shipment-offers-update.${userData.user.customer.dot}-${i.shipment_id}`
      );
      const channels = channelNames.map((channelName) => PusherJs.subscribe(channelName));
      channels.forEach((channel) => {
        channel.bind('shipment-offers-update', () => {
          getShipments();
        });
      });

      return () => {
        channelNames.forEach((channelName) => {
          PusherJs.unsubscribe(channelName);
        });
      };
    }
  }, [shipmentsData]);

  const columns = useColumns({
    tab: selectedFilters.tab,
    shipments: shipmentsData.data,
    selectedRows,
    handleSelectAll,
    handleSelectRow,
    sort: selectedFilters.sort,
    sortingQuery,
    onViewNotes,
  });

  return (
    <div>
      <TableHeader
        search={search}
        setSearch={setSearch}
        dragItem={dragItem}
        setDragItem={setDragItem}
        tableColumn={tableColumn}
        setTableColumn={setTableColumn}
        getSettings={getSettings}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        loading={loading}
      />
      <div className='mt-4'>
        <Tabs tabs={tabs} activeTab={selectedFilters.tab} handleTabChange={handleTabChange} />
        {loading ? (
          <TableSkeleton />
        ) : (
          <MaterialTableWrapper
            data={shipmentsData.data}
            rowPerPage={selectedFilters.itemsPerPage}
            style={{ backgroundColor: palette.white }}
            columns={[
              ...filterTableColumn(columns),
              actionsColumn({ onRevertAll, onCancelShipment, onViewNotes, onRevertAndResend, onExtendExpiration }),
            ]}
            components={{
              Pagination: () =>
                Pagination({
                  data: shipmentsData,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                }),
            }}
            onRowClick={(e, row) => navigate(`/offered-shipments/${row.shipment_id}`)}
          />
        )}
      </div>
      <ConfirmationModal
        width='500px'
        open={!!rowToUpdate && rowToUpdate?.action === 'revertAll'}
        onClose={() => setRowToUpdate(null)}
        headerTitle='Revert Offers'
        text={`Are you sure you want to revert all offers of shipment ${rowToUpdate?.data?.shipment_id}?`}
        onConfirm={() => onRevertAll(rowToUpdate?.data, true)}
        buttonProps={{ type: 'primary', title: 'Revert' }}
        disabled={loadingAction}
      />
      <ConfirmationModal
        width='500px'
        open={!!rowToUpdate && rowToUpdate?.action === 'revertAndResend'}
        onClose={() => setRowToUpdate(null)}
        headerTitle='Revert and Resend Offers'
        text={`Are you sure you want to revert and resend all offers of shipment ${rowToUpdate?.data?.shipment_id}?`}
        onConfirm={() => onRevertAndResend(rowToUpdate?.data, true)}
        buttonProps={{ type: 'primary', title: 'Revert & Resend' }}
        disabled={loadingAction}
      />
      <ConfirmationModal
        width='500px'
        open={!!rowToUpdate && rowToUpdate?.action === 'cancelShipment'}
        onClose={() => setRowToUpdate(null)}
        headerTitle='Cancel Shipment'
        text={`Are you sure you want to cancel shipment ${rowToUpdate?.data?.shipment_id}?`}
        onConfirm={() => onCancelShipment(rowToUpdate?.data, true)}
        buttonProps={{ type: 'danger', title: 'Cancel Shipment' }}
        disabled={loadingAction}
      />
      {!!rowToUpdate && rowToUpdate.action === 'extendExpiration' && (
        <ExtendExpiration
          open
          onClose={() => setRowToUpdate(null)}
          shipmentId={rowToUpdate.data.shipment_id}
          onSuccess={getShipments}
        />
      )}
      {!!rowToUpdate && rowToUpdate.action === 'viewNotes' && (
        <AddNotes open onClose={() => setRowToUpdate(null)} shipmentId={rowToUpdate.data.shipment_id} />
      )}
    </div>
  );
};

export default ShipmentsTable;
