import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import copy from 'assets/copy.png';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { staffRestrictionSchedule, UpdateStaffRestrictionSchedule } from 'Api/Staff';
import { getErrorMessage } from 'utils/error';
import './AddStaffStyle.css';
import { saveFinishLater } from 'Api/StaffV2';
import CustomTimeInput from '../../CustomTimeInput';
import { SActions, SAddMore } from './AddStaff.styles';

const RestrcitionSchedule = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  stepsDone,
  setStepsDone,
  staffId,
  restrictShift = {},
  toggle,
  draftDriver,
}) => {
  const [onNextloading, setOnNextloading] = useState(false);
  const {
    staff_id,
    restrict,
    employee_schedule,
    restrict_login_ip,
    mon_shift,
    mon_start,
    mon_end,
    tue_shift,
    tue_start,
    tue_end,
    wed_shift,
    wed_start,
    wed_end,
    thur_shift,
    thur_start,
    thur_end,
    fri_shift,
    fri_start,
    fri_end,
    sat_shift,
    sat_start,
    sat_end,
    sun_shift,
    sun_start,
    sun_end,
  } = restrictShift || {};

  const formik = useFormik({
    initialValues: {
      staff_id: staff_id || staffId,
      restrict: restrict || false,
      employee_schedule: employee_schedule || false,
      restrict_login_ip: restrict_login_ip || [''],
      mon_shift: mon_shift || 1,
      mon_start: mon_start || '00:00',
      mon_end: mon_end || '00:00',
      tue_shift: tue_shift || 0,
      tue_start: tue_start || '00:00',
      tue_end: tue_end || '00:00',
      wed_shift: wed_shift || 0,
      wed_start: wed_start || '00:00',
      wed_end: wed_end || '00:00',
      thur_shift: thur_shift || 0,
      thur_start: thur_start || '00:00',
      thur_end: thur_end || '00:00',
      fri_shift: fri_shift || 0,
      fri_start: fri_start || '00:00',
      fri_end: fri_end || '00:00',
      sat_shift: sat_shift || 0,
      sat_start: sat_start || '00:00',
      sat_end: sat_end || '00:00',
      sun_shift: sun_shift || 0,
      sun_start: sun_start || '00:00',
      sun_end: sun_end || '00:00',
    },
  });

  const onPrevStep = () => {
    setStepsDone({
      ...stepsDone,
      isPolicies: true,
    });
    prevStep();
  };

  const onNextStep = () => {
    setOnNextloading(true);

    if (formik.values.restrict && !formik.values.restrict_login_ip?.filter((el) => !!el.trim())?.length) {
      showError('At least one IP is required!');
      setOnNextloading(false);
      return;
    }
    if (
      formik.values.employee_schedule &&
      !(
        formik.values.mon_shift ||
        formik.values.tue_shift ||
        formik.values.wed_shift ||
        formik.values.thur_shift ||
        formik.values.fri_shift ||
        formik.values.sat_shift ||
        formik.values.sun_shift
      )
    ) {
      showError('Enter at least one day schedule');
      setOnNextloading(false);
      return;
    }

    const tempData = {
      ...formik.values,
      restrict: formik.values.restrict ? 1 : 0,
      employee_schedule: formik.values.employee_schedule ? 1 : 0,
      mon_shift: formik.values.mon_shift ? 1 : 0,
      tue_shift: formik.values.tue_shift ? 1 : 0,
      wed_shift: formik.values.wed_shift ? 1 : 0,
      thur_shift: formik.values.thur_shift ? 1 : 0,
      fri_shift: formik.values.fri_shift ? 1 : 0,
      sat_shift: formik.values.sat_shift ? 1 : 0,
      sun_shift: formik.values.sun_shift ? 1 : 0,
      restrict_login_ip: formik.values.restrict
        ? formik.values.restrict_login_ip?.filter((el) => !!el.trim())?.join(',')
        : '',
    };
    if (tempData?.employee_schedule === 0 && tempData?.restrict === 0) {
      setOnNextloading(false);
      nextStep(formik.values, `Restriction and Shift has been ${stepsDone ? 'updated' : 'created'}`);
    } else if (stepsDone) {
      UpdateStaffRestrictionSchedule(tempData, staffId)
        .then(() => {
          setOnNextloading(false);
          nextStep(formik.values, `Restriction and Shift has been ${stepsDone ? 'updated' : 'created'}`);
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      staffRestrictionSchedule(tempData)
        .then(() => {
          setOnNextloading(false);
          nextStep(formik.values, `Restriction and Shift has been ${stepsDone ? 'updated' : 'created'}`);
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  useEffect(() => {
    if (!stepsDone) {
      formik.setFieldValue('restrict_login_ip', ['']);
    }
  }, []);

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 3,
    };

    saveFinishLater(staffId, body).then(() => {
      Cancel();
    });
  };

  const copyToAll = () => {
    formik.setFieldValue('tue_start', formik.values.mon_start);
    formik.setFieldValue('tue_end', formik.values.mon_end);
    formik.setFieldValue('wed_start', formik.values.mon_start);
    formik.setFieldValue('wed_end', formik.values.mon_end);
    formik.setFieldValue('thur_start', formik.values.mon_start);
    formik.setFieldValue('thur_end', formik.values.mon_end);
    formik.setFieldValue('fri_start', formik.values.mon_start);
    formik.setFieldValue('fri_end', formik.values.mon_end);
    formik.setFieldValue('sat_start', formik.values.mon_start);
    formik.setFieldValue('sat_end', formik.values.mon_end);
    formik.setFieldValue('sun_start', formik.values.mon_start);
    formik.setFieldValue('sun_end', formik.values.mon_end);
  };
  return (
    <Form>
      <Modal.Body
        style={toggle ? { backgroundColor: palette.white, height: '560px' } : { backgroundColor: palette.white }}
        className='add-driver-modal-body driver-style-wrap restrict-staff'
      >
        <RestrictionContent formik={formik} copyToAll={copyToAll} />
      </Modal.Body>
      {!toggle ? (
        <Modal.Footer style={{ backgroundColor: palette.white, borderColor: palette.gray50 }}>
          <div className='footer-btn'>
            <div>
              <Button className='cancel-button' onClick={saveAndFinishLater}>
                Save and Finish Later
              </Button>
            </div>
            <div className='pagination-btn'>
              {!draftDriver && (
                <Button className='cancel-button' onClick={onPrevStep}>
                  Back
                </Button>
              )}
              {onNextloading ? (
                <div className='ms-2'>
                  <CircularProgress size={30} />
                </div>
              ) : (
                <Button
                  type='button'
                  onClick={() => onNextStep()}
                  className='next-step'
                  disabled={!formik.isValid}
                  style={{ opacity: formik.isValid ? 1 : 0.7 }}
                >
                  Next Step
                </Button>
              )}
            </div>
          </div>
        </Modal.Footer>
      ) : null}
    </Form>
  );
};

export default RestrcitionSchedule;

export const RestrictionContent = ({ formik, copyToAll }) => {
  return (
    <>
      <div className='restrict'>
        <div className='form-switch switch-wrapper'>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            name='restrict'
            id='restrict'
            value={formik.values?.restrict}
            checked={formik.values?.restrict}
            onChange={() => {
              formik.setFieldValue('restrict', !formik.values.restrict);
            }}
          />
          <label className='text-style' htmlFor='flexSwitchCheckChecked' style={{ color: palette.gray700 }}>
            Restrict Login only to IP(s) below:
          </label>
        </div>
        {formik.values?.restrict ? (
          <div className='d-flex ms-5 restrict-down'>
            <div className='flex-col'>
              {formik.values.restrict_login_ip.map((ip, index) => {
                return (
                  <div className='d-flex align-items-center bank-routing gap-2'>
                    <div className='bank-name-conatiner ip-container'>
                      <p className='bank-name-style text-center' style={{ color: palette.gray500 }}>
                        IP
                      </p>
                    </div>
                    <input
                      type='text'
                      style={{ backgroundColor: palette.white, borderColor: palette.gray50 }}
                      name={`restrict_login_ip.${index}`}
                      value={ip}
                      placeholder='000.000.000.000'
                      onChange={(e) => formik.setFieldValue(`restrict_login_ip.${index}`, e.target.value)}
                      onBlur={formik.handleBlur}
                    />
                    <SActions className='flex-item'>
                      <span
                        className='action-wrapper'
                        onClick={() =>
                          formik.setFieldValue(
                            'restrict_login_ip',
                            formik.values.restrict_login_ip.filter((ip, i) => i !== index)
                          )
                        }
                      >
                        <DeleteIcon width={11} height={11} fill={palette.red500} />
                      </span>
                    </SActions>
                  </div>
                );
              })}
              <SAddMore
                onClick={() => formik.setFieldValue('restrict_login_ip', [...formik.values.restrict_login_ip, ''])}
              >
                <PlusIcon />
                <Typography variant='s2' style={{ color: palette.indigo500 }}>
                  Add Another...
                </Typography>
              </SAddMore>
            </div>
          </div>
        ) : null}
      </div>
      <div className=''>
        <div className='form-switch switch-wrapper'>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            name='employee_schedule'
            id='employee_schedule'
            value={formik.values?.employee_schedule}
            checked={formik.values?.employee_schedule}
            onChange={() => {
              formik.setFieldValue('employee_schedule', !formik.values.employee_schedule);
            }}
          />
          <label className='text-style' htmlFor='flexSwitchCheckChecked' style={{ color: palette.gray700 }}>
            Employee Schedule
          </label>
        </div>
        {formik.values?.employee_schedule ? (
          <>
            <div className='week-opt'>
              <span
                className={formik.values?.mon_shift ? 'week-days-opt selected-week-restrict' : 'week-days-opt'}
                onClick={() => formik.setFieldValue('mon_shift', !formik.values.mon_shift)}
              >
                Mon
              </span>
              <span
                className={formik.values?.tue_shift ? 'week-days-opt selected-week-restrict' : 'week-days-opt'}
                onClick={() => formik.setFieldValue('tue_shift', !formik.values.tue_shift)}
              >
                Tue
              </span>
              <span
                className={formik.values?.wed_shift ? 'week-days-opt selected-week-restrict' : 'week-days-opt'}
                onClick={() => formik.setFieldValue('wed_shift', !formik.values.wed_shift)}
              >
                Wed
              </span>
              <span
                className={formik.values?.thur_shift ? 'week-days-opt selected-week-restrict' : 'week-days-opt'}
                onClick={() => formik.setFieldValue('thur_shift', !formik.values.thur_shift)}
              >
                Thu
              </span>
              <span
                className={formik.values?.fri_shift ? 'week-days-opt selected-week-restrict' : 'week-days-opt'}
                onClick={() => formik.setFieldValue('fri_shift', !formik.values.fri_shift)}
              >
                Fri
              </span>
              <span
                className={formik.values?.sat_shift ? 'week-days-opt selected-week-restrict' : 'week-days-opt'}
                onClick={() => formik.setFieldValue('sat_shift', !formik.values.sat_shift)}
              >
                Sat
              </span>
              <span
                className={formik.values?.sun_shift ? 'week-days-opt selected-week-restrict' : 'week-days-opt'}
                onClick={() => formik.setFieldValue('sun_shift', !formik.values.sun_shift)}
              >
                Sun
              </span>
            </div>
            <div className='week-detail'>
              {formik.values.mon_shift ? (
                <div className='week-wrapper'>
                  <div className='week-wrapper-day'>
                    <p className=''>Monday</p>
                  </div>
                  <div className='week-wrapper-date d-flex justify-content-around '>
                    <CustomTimeInput
                      name='mon_start'
                      value={formik.values?.mon_start}
                      form={formik}
                      field={{
                        name: 'mon_start',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                    <div className='mx-2'>
                      <p>to</p>
                    </div>
                    <CustomTimeInput
                      name='mon_end'
                      value={formik.values?.mon_end}
                      form={formik}
                      field={{
                        name: 'mon_end',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                  </div>
                  <div className='week-wrapper-status'>
                    <img src={copy} className='copy' alt='' onClick={copyToAll} />
                    <p className='ms-2'>Copy time to all days</p>
                  </div>
                </div>
              ) : null}
              {formik.values.tue_shift ? (
                <div className='week-wrapper'>
                  <div className='week-wrapper-day'>
                    <p className=''>Tuesday</p>
                  </div>
                  <div className='week-wrapper-date d-flex justify-content-around '>
                    <CustomTimeInput
                      name='tue_start'
                      value={formik.values?.tue_start}
                      form={formik}
                      field={{
                        name: 'tue_start',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                    <div className='mx-2'>
                      <p>to</p>
                    </div>
                    <CustomTimeInput
                      name='tue_end'
                      value={formik.values?.tue_end}
                      form={formik}
                      field={{
                        name: 'tue_end',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                  </div>
                  <div className='week-wrapper-status' />
                </div>
              ) : null}
              {formik.values.wed_shift ? (
                <div className='week-wrapper'>
                  <div className='week-wrapper-day'>
                    <p className=''>Wednesday</p>
                  </div>
                  <div className='week-wrapper-date d-flex justify-content-around '>
                    <CustomTimeInput
                      name='wed_start'
                      value={formik.values?.wed_start}
                      form={formik}
                      field={{
                        name: 'wed_start',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                    <div className='mx-2'>
                      <p>to</p>
                    </div>
                    <CustomTimeInput
                      name='wed_end'
                      value={formik.values?.wed_end}
                      form={formik}
                      field={{
                        name: 'wed_end',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                  </div>
                  <div className='week-wrapper-status' />
                </div>
              ) : null}

              {formik.values.thur_shift ? (
                <div className='week-wrapper'>
                  <div className='week-wrapper-day'>
                    <p className=''>Thursday</p>
                  </div>
                  <div className='week-wrapper-date d-flex justify-content-around '>
                    <CustomTimeInput
                      name='thur_start'
                      value={formik.values?.thur_start}
                      form={formik}
                      field={{
                        name: 'thur_start',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                    <div className='mx-2'>
                      <p>to</p>
                    </div>
                    <CustomTimeInput
                      name='thur_end'
                      value={formik.values?.thur_end}
                      form={formik}
                      field={{
                        name: 'thur_end',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                  </div>
                  <div className='week-wrapper-status' />
                </div>
              ) : null}
              {formik.values.fri_shift ? (
                <div className='week-wrapper'>
                  <div className='week-wrapper-day'>
                    <p className=''>Friday</p>
                  </div>
                  <div className='week-wrapper-date d-flex justify-content-around '>
                    <CustomTimeInput
                      name='fri_start'
                      value={formik.values?.fri_start}
                      form={formik}
                      field={{
                        name: 'fri_start',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                    <div className='mx-2'>
                      <p>to</p>
                    </div>
                    <CustomTimeInput
                      name='fri_end'
                      value={formik.values?.fri_end}
                      form={formik}
                      field={{
                        name: 'fri_end',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                  </div>
                  <div className='week-wrapper-status' />
                </div>
              ) : null}
              {formik.values.sat_shift ? (
                <div className='week-wrapper'>
                  <div className='week-wrapper-day'>
                    <p className=''>Saturday</p>
                  </div>
                  <div className='week-wrapper-date d-flex justify-content-around '>
                    <CustomTimeInput
                      name='sat_start'
                      value={formik.values?.sat_start}
                      form={formik}
                      field={{
                        name: 'sat_start',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                    <div className='mx-2'>
                      <p>to</p>
                    </div>
                    <CustomTimeInput
                      name='sat_end'
                      value={formik.values?.sat_end}
                      form={formik}
                      field={{
                        name: 'sat_end',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                  </div>
                  <div className='week-wrapper-status' />
                </div>
              ) : null}
              {formik.values.sun_shift ? (
                <div className='week-wrapper'>
                  <div className='week-wrapper-day'>
                    <p className=''>Sunday</p>
                  </div>
                  <div className='week-wrapper-date d-flex justify-content-around '>
                    <CustomTimeInput
                      name='sun_start'
                      value={formik.values?.sun_start}
                      form={formik}
                      field={{
                        name: 'sun_start',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                    <div className='mx-2'>
                      <p>to</p>
                    </div>
                    <CustomTimeInput
                      name='sun_end'
                      value={formik.values?.sun_end}
                      form={formik}
                      field={{
                        name: 'sun_end',
                        onChange: formik.onChange,
                        onBlur: formik.onChange,
                      }}
                    />
                  </div>
                  <div className='week-wrapper-status' />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
