import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useForm from 'hooks/useForm';
import Input from 'common/Input';
import CustomButton from 'components/CustomButton/CustomButton';
import { ReactComponent as Delete } from 'assets/icons/xClose.svg';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import { validationSchema } from './validationSchema';
import { SActions, SInputWrapper } from '../ApiKeys.styles';

const initialValues = {
  key_name: '',
};

const ItemForm = ({ onSubmit, onDelete, loading }) => {
  const { handleChange, handleSubmit, values, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <SInputWrapper>
      <div style={{ width: '100%' }}>
        <Input
          name='key_name'
          onChange={handleChange}
          value={values.key_name}
          placeholder='Key Name'
          error={touchedErrors.key_name}
          style={{ maxWidth: '300px', width: '100%' }}
        />
      </div>
      <SActions>
        {loading ? (
          <span>
            <CircularProgress size={16} />
          </span>
        ) : (
          <CustomButton
            width={12}
            height={12}
            type='secondary'
            leftIcon={<Delete style={{ fill: '#464F60' }} />}
            styleButton={{ padding: '7px 8px', margin: 0 }}
            onClick={onDelete}
          />
        )}
        <CustomButton
          width={12}
          height={12}
          type='primary'
          leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={handleSubmit}
        />
      </SActions>
    </SInputWrapper>
  );
};

export default ItemForm;
