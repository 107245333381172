import { STRIPE_MODULE } from 'utils/constants';

export const redirectPath = {
  [STRIPE_MODULE.ACCOUNTS_RECEIVABLES]: '/accounting/receivables',
  [STRIPE_MODULE.ACCOUNTS_PAYABLE]: '/accounting/payables',
  [STRIPE_MODULE.PAYROLL]: '/payroll',
  [STRIPE_MODULE.CARD_MANAGEMENT]: '/card-management',
};

export const industryOptions = [
  { mcc: '4215', name: 'Courier Services' },
  { mcc: '4789', name: 'Transportation Services' },
  { mcc: '4214', name: 'Motor Freight Carriers and Trucking' },
];

export const basicInfoInitialValues = {
  legal_business_name: '',
  ein: '',
  doing_business_as: '',
  address1: '',
  address2: '',
  country: null,
  state: null,
  city: null,
  zipCode: '',
  business_phone_number: '',
  industry: industryOptions[0],
  website: '',
  product_description: '',
};

export const verificationInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  job_title: '',
  date_of_birth: null,
  address1: '',
  address2: '',
  country: null,
  state: null,
  city: null,
  zipCode: '',
  phone_number: '',
  last4: '',
  is_25_percent_owner: false,
  is_the_only_25_percent_owner: null,
};
