import React, { useState } from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import ViewPdf from 'components/ViewPdf';
import InputLabel from 'common/InputLabel';
import { BackdropLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { palette } from 'utils/constants';
import { SAddMore, SFlexWrapper, SAttachmentWrapper } from '../AddBill.styles';

const Attachment = ({ values, handleChange, readOnly }) => {
  const [loading, setLoading] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);

  const onAttachmentClick = (file) => {
    if (file?.path && !file?.type) {
      setPdfLink(file.path);
      return;
    }
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPdfLink(reader.result);
    };
    reader.onloadend = () => {
      setLoading(false);
    };
  };

  const onAttachmentDelete = (e, index) => {
    e.stopPropagation();
    const newAttachments = values.attachments.filter((item, i) => i !== index);
    handleChange('attachments', newAttachments);
  };

  return (
    <SAttachmentWrapper>
      <InputLabel>Attachment(s)</InputLabel>
      <div>
        <SFlexWrapper>
          {values?.attachments?.map((attachment, index) => (
            <div key={attachment?.id || index}>
              <p
                className='attachment-wrapper'
                onClick={() => onAttachmentClick(attachment)}
                style={{ backgroundColor: palette.indigo0 }}
              >
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: palette.green400 }}>
                  Attachment {index + 1}
                </span>
                {!readOnly && (
                  <img
                    src={cross}
                    alt='cross'
                    className='upload-cancel-icon'
                    onClick={(e) => onAttachmentDelete(e, index)}
                  />
                )}
              </p>
            </div>
          ))}
          {!readOnly && !!values?.attachments?.length && (
            <SAddMore>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
                Add Another...
              </Typography>
              <ImageDropZone
                multiple
                onDrop={(files) => !readOnly && handleChange('attachments', [...values.attachments, ...files])}
                width='100%'
                height='100%'
                name='small'
                deletable={false}
                disabled={readOnly}
                customStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  cursor: readOnly ? 'not-allowed' : 'pointer',
                }}
                accept={['application/pdf']}
              />
            </SAddMore>
          )}
        </SFlexWrapper>
        {!values?.attachments?.length && (
          <div className={`upload-document-deposit ${readOnly ? 'upload-document-disabled' : ''}`}>
            <div className='text-style upload-label'>
              <p className='upload-document-icon'>
                <img src={uploadLicense} alt='' />
              </p>
              <p className='upload-document-text'>
                <span style={{ color: palette.indigo500 }}> Upload Document </span>
                <span style={{ color: palette.gray700 }}> or drag and drop</span>
              </p>
            </div>
            <ImageDropZone
              multiple
              onDrop={(files) => !readOnly && handleChange('attachments', [...values.attachments, ...files])}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              disabled={readOnly}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: readOnly ? 'not-allowed' : 'pointer',
              }}
              accept={['application/pdf']}
            />
          </div>
        )}
      </div>
      <ViewPdf open={!!pdfLink} onClose={() => setPdfLink(null)} pdfUrl={pdfLink} title='View Attachment' />
      <BackdropLoader loading={loading} />
    </SAttachmentWrapper>
  );
};

export default Attachment;
