import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { Typography } from 'components/Typography';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { createRule } from 'Api/Accounts';
import { STableRow, SAddMore } from '../../BankFeed.styles';

const SuggestNewRule = ({ onCreateSuccess, rule, accountId }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const createNewRule = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const body = {
        account_id: accountId,
        contains_type: 'description',
        contains: rule.contains,
        itemized_as_account_id: rule.account.id,
        payee_type: rule.payee.type,
        payee_id: rule.payee.id,
        is_exact: 0,
      };

      await createRule(body);
      showToaster({ type: 'success', message: 'Rule has been successfully created!' });
      onCreateSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <STableRow $bgColor={palette.gray0}>
      <td colSpan='11' style={{ border: 'none' }}>
        <div className='d-flex gap-4 align-items-center'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            New Rule Suggestion:
          </Typography>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            If description contains {rule.contains}, itemize as {rule.account?.account_name} (
            {rule.account.account_code}) and rename Payee to {rule.payee.name}
          </Typography>
          <SAddMore onClick={createNewRule}>
            <PlusIcon fill={loading ? palette.indigo200 : palette.indigo500} />
            <Typography variant='s2' style={{ color: loading ? palette.indigo200 : palette.indigo500 }}>
              Add to Rules
            </Typography>
          </SAddMore>
        </div>
      </td>
    </STableRow>
  );
};

export default SuggestNewRule;
