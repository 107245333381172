import React, { useState } from 'react';
import Modal from 'common/Modal';
import { Textarea } from 'common/Input';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { declineBid } from 'Api/OfferedShipments';

const DeclineBid = ({ open, onClose, bid, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const body = {
        decline_reason: values.decline_reason,
      };
      await declineBid(bid.id, body);
      showToaster({ type: 'success', message: 'Bid is successfully declined!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { decline_reason: '' },
    onSubmit,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Decline Bid'
      $maxWidth='450px'
      $minWidth='450px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Close' },
        {
          key: 'submit',
          type: 'primary',
          title: 'Decline Bid',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className='mb-3'>
        <Textarea
          rows={3}
          label='Decline Reason'
          name='decline_reason'
          value={values.decline_reason}
          onChange={handleChange}
          error={touchedErrors.decline_reason}
        />
      </div>
    </Modal>
  );
};

export default DeclineBid;
