import React, { useEffect, useState } from 'react';
import { palette } from 'utils/constants';

import { useParams } from 'react-router-dom';
import { getCustomerInfo } from 'Api/JobPositions';
import useForm from 'hooks/useForm';
import { getBillOfLading, getBillOfLadingDetails, getShipmentInfo } from 'Api/Shipment';
import Loader from 'common/Loader';
import BillOfLadingHeader from './components/BillOfLadingHeader';
import { SWrapper, ScrollableContainer } from './BillOfLading.styles';
import Footer from './components/Footer';
import BillForm from './components/BillForm';
import { initialValues } from './BillOfLading.data';
import { validationSchema } from './validationSchema';
import { generateValue } from './helpers/utils';

const BillOfLading = () => {
  const { id, bolId } = useParams();
  const user = JSON.parse(localStorage.getItem('user'));
  const dot = user?.customer?.dot;
  const [billNumber, setBillNumber] = useState();

  const [company, setCompany] = useState(null);
  const [data, setData] = useState(null);
  const [info, setShipmentInfo] = useState(null);
  const [existingBol, setExistingBol] = useState(null);

  useEffect(() => {
    getCompanyInfo();
    getBillOfLadingInfo();
    if (bolId === 'new') {
      const blNumber = generateValue(id);
      setBillNumber(blNumber);
    }
  }, []);

  const getCompanyInfo = async () => {
    try {
      const response = await getCustomerInfo(dot);
      setCompany(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getBillOfLadingInfo = async () => {
    try {
      if (bolId !== 'new') {
        const currentBol = await getBillOfLading(bolId);
        setExistingBol(currentBol.data);
        setBillNumber(currentBol.data.bol_id);
      }

      const params = {
        shipment_id: id,
      };
      const response = await getBillOfLadingDetails(params);
      const info = await getShipmentInfo(id);
      setShipmentInfo(info.data);
      setData(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const form = useForm({
    initialValues: bolId && existingBol ? JSON.parse(existingBol.data) : initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return !info ? (
    <Loader loading={!info} />
  ) : (
    <SWrapper $bgColor={palette.gray0}>
      <ScrollableContainer>
        <BillOfLadingHeader company={company} info={info} data={data} billNumber={billNumber} />
        <BillForm form={form} info={info} billNumber={billNumber} existingBol={existingBol} />
      </ScrollableContainer>
      <Footer />
    </SWrapper>
  );
};

export default BillOfLading;
