import React from 'react';
import Tabs from 'common/Tabs';
import Divider from 'common/Divider';
import { STableWrapper } from '../Payables.styles';

const TableSection = ({ tabs, activeTab, handleTabChange, refreshStats, getCounts }) => {
  return (
    <STableWrapper>
      <Tabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      <Divider margin='2px 0 16px 0' />
      {tabs?.find((tab) => tab.value === activeTab)?.component({ refreshStats, getCounts })}
    </STableWrapper>
  );
};

export default TableSection;
