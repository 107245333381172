import React from 'react';
import { InputWithIcon } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import VendorAutocomplete from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/VendorAutocomplete';
import { equipmentOwnerOptions } from '../../Equipment.data';
import { SFlexRow, SWrapper } from './OwnerFields.styles';

const OwnerFields = ({ rowToEdit, onInputChange, ownerOperators, vendors, onAddVendorSuccess }) => {
  return (
    <SWrapper>
      <Autocomplete
        size='small'
        name='owner_operator'
        labelKey='label'
        options={equipmentOwnerOptions}
        value={rowToEdit.owner_operator || null}
        onChange={(e, value) => onInputChange({ target: { name: 'owner_operator', value } })}
        error={!rowToEdit.owner_operator && 'Required'}
      />
      {rowToEdit.owner_operator?.value === '2' && (
        <SFlexRow>
          <div>
            <VendorAutocomplete
              width='174px'
              required
              label='Leasing Company Name'
              value={vendors.find((i) => i.name === rowToEdit.leasing_company_name) || null}
              options={vendors || []}
              onChange={(e, val) => onInputChange({ target: { name: 'leasing_company_name', value: val.name } })}
              onAddNewSuccess={onAddVendorSuccess}
              error={!rowToEdit.leasing_company_name && 'Required'}
            />
          </div>
          <InputWithIcon
            required
            label='Lease or Credit Payment'
            size='small'
            name='lease_or_credit_payment'
            onChange={onInputChange}
            value={rowToEdit.lease_or_credit_payment || ''}
          />
        </SFlexRow>
      )}
      {rowToEdit.owner_operator?.value === '3' && (
        <SFlexRow>
          <Autocomplete
            required
            size='small'
            label='Owner Operator'
            name='owner'
            labelKey='label'
            options={ownerOperators || []}
            value={rowToEdit.owner || null}
            onChange={(e, value) => onInputChange({ target: { name: 'owner', value } })}
            width='172px'
          />
        </SFlexRow>
      )}
    </SWrapper>
  );
};

export default OwnerFields;
