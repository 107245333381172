import React, { useEffect, useState } from 'react';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import AddressAutocomplete from 'common/AddressAutocomplete';
import { getCities, getCountries, getStates } from 'Api/JobPositions';
import { GetCompanyProfile } from 'Api/CompanySettings';
import { initialValues } from './ShippingAddress.data';
import { validationSchema } from './ValidationSchema';
import { SFlexRow, SWrapper } from './ShippingAddress.styles';

const ShippingAddress = ({ values, touchedErrors, handleChange, isOnboarding }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const getCountriesList = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getStatesList = async (params) => {
    try {
      const response = await getStates(params);
      setStates(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCitiesList = async (stateId) => {
    try {
      const response = await getCities({ state_id: stateId });
      setCities(response.data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCompanySettings = async () => {
    try {
      const response = await GetCompanyProfile();
      const { country_id, state_id, city_id, address1, address2, zip } = response || {};
      onAddressSelect({ country: country_id, state: state_id, city: city_id });
      changeAddress({ address: address1, zip });
      if (address2) {
        handleChange('shipping_address2', address2);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onAddressSelect = (data) => {
    handleChange('shipping_country', data.country);
    handleChange('shipping_state', data.state);
    handleChange('shipping_city', data.city);
  };

  const changeAddress = ({ address, zip }) => {
    handleChange('shipping_address1', address);
    handleChange('shipping_zip', zip || values.shipping_zip);
  };

  useEffect(() => {
    getCountriesList();
    if (!isOnboarding) {
      getCompanySettings();
    }
  }, []);

  useEffect(() => {
    if (values.shipping_country) {
      getStatesList({ 'country_id[]': [values.shipping_country.id] });
    }
  }, [values.shipping_country]);

  useEffect(() => {
    if (values.shipping_state) {
      getCitiesList(values.shipping_state.id);
    }
  }, [values.shipping_state]);

  return (
    <SWrapper>
      <SFlexRow>
        <AddressAutocomplete
          name='shipping_address1'
          id='shipping_address1'
          label='Shipping Address'
          onSuccess={onAddressSelect}
          changeAddress={changeAddress}
          onChange={handleChange}
          value={values.shipping_address1}
          error={touchedErrors?.shipping_address1}
        />
        <Input
          name='shipping_address2'
          className='w-100'
          placeholder='Address 2'
          onChange={handleChange}
          value={values.shipping_address2}
        />
      </SFlexRow>
      <SFlexRow>
        <div style={{ width: '100%' }}>
          <Autocomplete
            name='shipping_country'
            placeholder='Select Country..'
            value={values.shipping_country}
            onChange={(e, value) => {
              handleChange('shipping_country', value);
              handleChange('shipping_state', null);
              handleChange('shipping_city', null);
            }}
            options={countries}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors?.shipping_country}
            required
          />
        </div>
        <div style={{ width: '100%' }}>
          <Autocomplete
            name='shipping_state'
            placeholder='Select State..'
            value={values.shipping_state}
            onChange={(e, value) => {
              handleChange('shipping_state', value);
              handleChange('shipping_city', null);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={states}
            error={touchedErrors?.shipping_state}
          />
        </div>
      </SFlexRow>
      <SFlexRow>
        <div style={{ width: '100%' }}>
          <Autocomplete
            name='shipping_city'
            placeholder='Select City..'
            value={values.shipping_city}
            onChange={(e, value) => handleChange('shipping_city', value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={cities}
            error={touchedErrors?.shipping_city}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Input
            name='shipping_zip'
            className='w-100'
            placeholder='Zip Code'
            onChange={handleChange}
            value={values.shipping_zip}
            error={touchedErrors?.shipping_zip}
            required
          />
        </div>
      </SFlexRow>
    </SWrapper>
  );
};

export default ShippingAddress;
export { initialValues, validationSchema };
