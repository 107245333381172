import React from 'react';
import { useSelector } from 'react-redux';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber } from 'utils/constants';
import RowActions from './RowActions';

export const useColumns = ({ onRemove, setImagesToView }) => {
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'name',
      title: <ColumnHeader title='NAME' field='name' />,
      render: (row) => <Typography variant='overLine2'>{row.name || '-'}</Typography>,
    },
    {
      field: 'description',
      title: <ColumnHeader title='DESCRIPTION' field='description' />,
      render: (row) => <Typography variant='overLine2'>{row.description || '-'}</Typography>,
    },
    {
      field: 'quantity',
      title: <ColumnHeader title='QTY' field='quantity' />,
      render: (row) => <Typography variant='overLine2'>{row.quantity || '-'}</Typography>,
    },
    {
      field: 'cost_per_quantity',
      title: <ColumnHeader title='COST PER QTY' field='cost_per_quantity' />,
      render: (row) => (
        <Typography variant='overLine2'>
          {row.cost_per_quantity ? `${currency}${formatNumber(row.cost_per_quantity)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'total_value',
      title: <ColumnHeader title='TOTAL VALUE' field='total_value' />,
      render: (row) => (
        <Typography variant='overLine2'>
          {row.total_value ? `${currency}${formatNumber(row.total_value)}` : '-'}
        </Typography>
      ),
    },
    {
      field: 'files',
      title: <ColumnHeader title='IMAGE' field='files' />,
      render: (row) => {
        if (!row.files?.length) {
          return <Typography variant='overLine2'>-</Typography>;
        }
        return (
          <span
            className='underline-text'
            onClick={(e) => {
              e.stopPropagation();
              setImagesToView(row.files);
            }}
          >
            View Image(s)
          </span>
        );
      },
    },
    {
      field: 'date_added',
      title: <ColumnHeader title='DATE ADDED' field='date_added' />,
      render: (row) => (
        <Typography variant='overLine2'>{row.created_at ? convertToCustomerTime(row.created_at) : '-'}</Typography>
      ),
    },
    {
      field: 'deleted_at',
      title: <ColumnHeader title='DATE REMOVED' field='deleted_at' />,
      render: (row) => (
        <Typography variant='overLine2'>{row.deleted_at ? convertToCustomerTime(row.deleted_at) : '-'}</Typography>
      ),
    },
    {
      field: 'ownership',
      title: <ColumnHeader title='OWNERSHIP' field='ownership' />,
      render: (row) => <Typography variant='overLine2'>{row.ownership || '-'}</Typography>,
    },
    {
      field: 'updated_by',
      title: <ColumnHeader title='UPDATED BY' field='updated_by' />,
      render: (row) => (
        <Typography variant='overLine2'>
          {row.updated_by.first_name || row.updated_by.fname} {row.updated_by.last_name || row.updated_by.lname}
        </Typography>
      ),
    },
    {
      field: 'actions',
      title: <ColumnHeader title='' />,
      render: (row) => {
        return !row.deleted_at ? <RowActions onRemove={() => onRemove(row.id)} /> : null;
      },
    },
  ];
};
