import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-cropper';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import styles from './ImageCropModale.module.css';

function ImageCropModal({ ...props }) {
  const { use } = useTheme();
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body>
        <Cropper
          className={styles.cropper123}
          style={{ height: 400, width: '100%' }}
          zoomTo={0.5}
          initialAspectRatio={1}
          src={props?.image?.blob}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            props.setCropper(instance);
          }}
          guides
        />
      </Modal.Body>
      <Modal.Footer
        className='connect-modal-header-footer'
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Button className='Cancel-modal-btn' onClick={() => props.onHide()}>
          Cancel
        </Button>
        <Button className='Connect-modal-btn' onClick={() => props.getCropData()}>
          Crop
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageCropModal;
