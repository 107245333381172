export const NAVBAR_OPTIONS = [
  { id: 1, name: 'Select Payee(s)' },
  { id: 2, name: 'Select Method' },
  { id: 3, name: 'Review & Submit' },
];

export const MAP_KEYS_SEND_PAYMENT = [
  'vendors',
  'drivers',
  'ownerOperators',
  'searchOpenBills',
  'searchOpenSettlements',
  'staff',
];

export const SEND_PAYMENT_KEYS = {
  VENDORS: 'vendors',
  DRIVERS: 'drivers',
  OWNER_OPERATORS: 'ownerOperators',
  OPEN_BILLS: 'searchOpenBills',
  OPEN_SETTLEMENTS: 'searchOpenSettlements',
  STAFF: 'staff',
};

export const SEND_PAYMENT_TYPES = {
  VENDORS: 2,
  DRIVERS: 1,
  OWNER_OPERATORS: 3,
  OPEN_BILLS: 4,
  OPEN_SETTLEMENTS: 5,
  STAFF: 6,
};

export const SEND_PAYMENT_TYPES_LABELS = {
  2: 'Vendor',
  1: 'Driver',
  3: 'Owner Operator',
  4: 'Bill',
  5: 'Settlement',
  6: 'STAFF',
};

export const SEND_PAYMENT_ACTIVE_ROUTE = {
  vendors: '/accounting/vendors',
  drivers: '/driver',
  ownerOperators: '/owner-operators',
  searchOpenBills: '/accounting/payables',
  searchOpenSettlements: '/payroll',
  staff: '/staff',
};

export const formatCheckNumber = (current, index) => {
  if (current?.trim() === '') return '';

  const currentNumber = parseInt(current, 10) + index;
  let zeroCount = current.length - String(currentNumber).length;
  zeroCount = zeroCount > 0 ? zeroCount : 0;
  return '0'.repeat(zeroCount) + currentNumber.toString();
};

export function numberToWords(num) {
  if (num === 0) return 'zero';

  // Define arrays for one through nineteen, and tens.
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const teens = [
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  // Convert the number to string.
  const numStr = num.toString();

  // Handle the fractional part (cents) separately.
  const decimalIndex = numStr.indexOf('.');
  let dollars = numStr;
  let cents = '';

  if (decimalIndex !== -1) {
    dollars = numStr.slice(0, decimalIndex);
    cents = numStr.slice(decimalIndex + 1);
  }

  function convertLessThanOneThousand(number) {
    let current = '';

    if (number < 10) {
      current = ones[number];
    } else if (number < 20) {
      current = teens[number - 10];
    } else if (number < 100) {
      current = tens[Math.floor(number / 10)];
      if (number % 10 !== 0) {
        current += ` ${ones[number % 10]}`;
      }
    } else {
      current = `${ones[Math.floor(number / 100)]} hundred`;
      if (number % 100 !== 0) {
        current += ` ${convertLessThanOneThousand(number % 100)}`;
      }
    }
    return current;
  }

  let result = '';
  let chunkCount = 0;
  while (dollars > 0) {
    if (dollars % 1000 !== 0) {
      result =
        convertLessThanOneThousand(dollars % 1000) +
        (chunkCount === 0 ? '' : ` thousand${result ? ', ' : ''}`) +
        result;
    }
    dollars = Math.floor(dollars / 1000);
    chunkCount++;
  }

  // Handle cents
  if (cents !== '') {
    result += `${(result ? ' and ' : '') + cents}/100`;
  }

  return result;
}
