import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import classNames from 'classnames';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import Tooltip from 'common/Tooltip';
import classes from './tableRowsEdit.module.css';
import { Typography } from '../Typography';

const TableRowsEdit = ({ ...props }) => {
  const { use } = useTheme();
  const target = useRef(null);
  const { options, onClickOption, onHide, overlayStyle, labelName, placement = 'bottom', targetStyle } = props;
  const [show, setShow] = useState(false);
  const LABEL_NAME = labelName || 'name';

  const getColorFromOption = (option, use, palette) => {
    if (option.cancel) return palette.red500;
    if (option.green) return palette.green500;
    if (option.yellow) return palette.goldDark;
    return use(palette.gray900, palette.gray50);
  };

  const flattenOptions = (options, labelName, depth = 0) => {
    return options.reduce((flattened, option) => {
      flattened.push({ ...option, depth });
      if (option.children) {
        flattened.push(...flattenOptions(option.children, labelName, depth + 1));
      }
      return flattened;
    }, []);
  };

  return (
    <>
      <div
        ref={target}
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
        className={classNames(classes.editIconWrapper, use(classes.light, classes.dark))}
        style={{ ...targetStyle }}
      >
        <EditIcon className={classes.editIcon} />
      </div>

      <Overlay
        placement={placement}
        target={target.current}
        show={show}
        onHide={() => {
          setShow(false);
          typeof onHide === 'function' && onHide();
        }}
        rootClose
        rootCloseEvent='mousedown'
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => {
          return (
            <div
              {...props}
              className={classNames(classes.overlay, use(classes.light, classes.dark))}
              style={{
                ...overlayStyle,
                backgroundColor: use(palette.white, palette.dark800),
                ...props.style,
              }}
            >
              {flattenOptions(options, LABEL_NAME).map((el, idx) =>
                el.tooltip ? (
                  <Tooltip title={el.tooltip}>
                    <div
                      className={classNames(classes.item, { [classes.childItem]: el.depth > 0 })}
                      key={el[LABEL_NAME]}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (el.disabled) {
                          return;
                        }
                        setShow(false);
                        onClickOption(el, idx);
                      }}
                      style={{
                        marginLeft: el.depth * 16, // Adjust margin as per your requirement
                      }}
                    >
                      <Typography
                        variant='s2'
                        style={{
                          color: getColorFromOption(el, use, palette),
                        }}
                      >
                        {el[LABEL_NAME]}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    className={classNames(classes.item, { [classes.childItem]: el.depth > 0 })}
                    key={el[LABEL_NAME]}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (el.disabled) {
                        return;
                      }
                      setShow(false);
                      onClickOption(el, idx);
                    }}
                    style={{
                      marginLeft: el.depth * 16, // Adjust margin as per your requirement
                    }}
                  >
                    <Typography
                      variant='s2'
                      style={{
                        color: getColorFromOption(el, use, palette),
                      }}
                    >
                      {el[LABEL_NAME]}
                    </Typography>
                  </div>
                )
              )}
            </div>
          );
        }}
      </Overlay>
    </>
  );
};

export default TableRowsEdit;
