import { object, string } from 'yup';

export const validationSchema = object().shape({
  kyu_id: string().when('kyu_active', {
    is: true,
    then: string().trim().required('Required'),
  }),
  kyu_attach: string().when('kyu_active', {
    is: true,
    then: string().trim().required('Required'),
  }),
});
