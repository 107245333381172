import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SBodyWrapper = styled.div`
  display: flex;
  column-gap: 36px;

  .left-section {
    width: 40%;
  }

  .right-section {
    width: 60%;
  }

  .tech-specs-button {
    display: inline-block;
    margin: 4px 12px 0;
    color: #4f5aed;
    cursor: pointer;
    width: fit-content;

    :hover {
      text-decoration: underline;
    }
  }

  .video-link-wrapper {
    display: flex;
    align-items: center;
    margin: 4px 12px 0;
    gap: 4px;
    width: fit-content;

    :hover {
      cursor: pointer;

      .underline-text {
        text-decoration: underline;
      }
    }
  }

  .hardware-image {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }

  .hardware-image-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    margin: 6px 0;

    .hardware-image-icon {
      width: 50px;
      height: 50px;
      margin-right: 2px;
      border: 1px solid #e9edf5;

      &.blur {
        opacity: 0.5;
        border: none;
      }
    }
  }

  .hardware-quantity-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    border-top: 1px solid #e9edf5;
    padding: 5px 0;
  }

  .count-input-wrapper {
    margin-top: 24px;
    padding: 12px;
    border: 1px solid ${palette.gray50};
    border-radius: 12px;
  }

  .separator {
    border-bottom: 1px solid #e9edf5;
    margin-bottom: 5px;
  }

  .finance-agreement {
    padding: 0;

    .finance-agreement-heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #4f5aed;
      margin-bottom: 5px;
    }

    .finance-agreement-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #171c26;
      margin-bottom: 5px;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #171c26;
      }
    }
  }

  .heading {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #171c26;
    border-bottom: 1px solid #e9edf5;
  }

  .text {
    font-family: 'Inter', sans-serif;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  ul li {
    margin: 5px 0;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
  }
`;

export const SPairedWith = styled.div`
  width: 550px;
  display: flex;
  column-gap: 4px;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: ${palette.boxShadow2};

  img {
    object-fit: contain;
  }

  .paired-hardware-price {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #171c26;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #171c26;
    }
  }

  .view-button {
    color: ${palette.indigo500};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;
