import React, { useState } from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import ViewPdf from 'components/ViewPdf';
import { BackdropLoader } from 'common/Loader';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import ConfirmationModal from 'common/ConfirmationModal';
import { palette } from 'utils/constants';
import { SAddMore, SFlexWrapper, SAttachmentWrapper } from '../EditInvoice.styles';

const Attachment = ({ values, handleChange, setAttachmentsToDelete }) => {
  const [loading, setLoading] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);

  const onAttachmentClick = (file) => {
    if (typeof file?.path === 'string' && !file?.type) {
      setPdfLink(file?.path);
      return;
    }
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPdfLink(reader.result);
    };
    reader.onloadend = () => {
      setLoading(false);
    };
  };

  const deleteUploadedAttachment = () => {
    setAttachmentsToDelete((prevState) => [...prevState, attachmentToDelete]);
    const newAttachments = values.attachments.filter((item, i) => i !== attachmentToDelete?.index);
    handleChange('attachments', newAttachments);
    setAttachmentToDelete(null);
  };

  const onAttachmentDelete = (e, index) => {
    e.stopPropagation();
    const newAttachments = values.attachments.filter((item, i) => i !== index);
    handleChange('attachments', newAttachments);
  };

  const onDeleteUploadedAttachment = (attachment, index) => {
    setAttachmentToDelete({ ...attachment, index });
  };

  return (
    <SAttachmentWrapper>
      <SFlexWrapper>
        {values?.attachments?.map((attachment, index) => (
          <div key={attachment?.id || index}>
            <p
              className='attachment-wrapper'
              onClick={() => onAttachmentClick(attachment)}
              style={{ backgroundColor: palette.indigo0 }}
            >
              <img src={right} alt='attachment' className='attachment-icon' />
              <span className='text-style' style={{ color: palette.green400 }}>
                Attachment {index + 1}
              </span>
              {attachment?.type ? (
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={(e) => onAttachmentDelete(e, index)}
                />
              ) : (
                <DeleteIcon
                  fill={palette.red500}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteUploadedAttachment(attachment, index);
                  }}
                />
              )}
            </p>
          </div>
        ))}
        {!!values?.attachments?.length && (
          <SAddMore>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
              Add Another...
            </Typography>
            <ImageDropZone
              multiple
              onDrop={(files) => handleChange('attachments', [...values.attachments, ...files])}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              accept={['application/pdf']}
            />
          </SAddMore>
        )}
      </SFlexWrapper>
      {!values?.attachments?.length && (
        <div className='upload-document-deposit'>
          <div className='text-style upload-label'>
            <p className='upload-document-icon'>
              <img src={uploadLicense} alt='' />
            </p>
            <p className='upload-document-text'>
              <span style={{ color: palette.indigo500 }}> Upload Document </span>
              <span style={{ color: palette.gray700 }}> or drag and drop</span>
            </p>
          </div>
          <ImageDropZone
            multiple
            onDrop={(files) => handleChange('attachments', [...values.attachments, ...files])}
            width='100%'
            height='100%'
            name='small'
            deletable={false}
            customStyle={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
            accept={['application/pdf']}
          />
        </div>
      )}
      <ViewPdf open={!!pdfLink} onClose={() => setPdfLink(null)} pdfUrl={pdfLink} title='View Attachment' />
      <BackdropLoader loading={loading} />
      {!!attachmentToDelete && (
        <ConfirmationModal
          title='Attachment'
          text='Are you sure you want to delete this uploaded attachment?'
          open={!!attachmentToDelete}
          onClose={() => setAttachmentToDelete(null)}
          onConfirm={deleteUploadedAttachment}
        />
      )}
    </SAttachmentWrapper>
  );
};

export default Attachment;
