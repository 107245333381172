import React from 'react';
import { Box } from '@mui/material';
import { Typography } from 'components/Typography';
import { CustomCollapsibleRow } from '../CustomCollapsibleRow';
import { SWrapper } from '../CustomCollapsibleRow/CustomCollapsibleRow.styles';

export const CollapsibleTable = ({ dailyLogs, vehicles, trailers, onDeleteSuccess, driverId }) => {
  return (
    <SWrapper>
      <Box>
        <Box sx={{ cursor: 'pointer', borderBottom: 1, borderColor: 'divider', padding: '20px', display: 'flex' }}>
          <Typography variant='b2' style={{ width: '3%' }} />
          <span className='column-title' style={{ width: '5%', textAlign: 'center' }}>
            DATE
          </span>
          <span className='column-title' style={{ width: '7%', textAlign: 'center' }}>
            SHIPMENT(S)
          </span>
          <span className='column-title' style={{ width: '6%', textAlign: 'center' }}>
            SHIFT
          </span>
          <span className='column-title' style={{ width: '6%', textAlign: 'center' }}>
            DRIVING
          </span>
          <span className='column-title' style={{ width: '14%', textAlign: 'center' }}>
            IN VIOLATION
          </span>
          <span className='column-title' style={{ width: '27%', textAlign: 'center' }}>
            FROM
          </span>
          <span className='column-title' style={{ width: '27%', textAlign: 'center' }}>
            TO
          </span>
          <span className='column-title' style={{ width: '5%', textAlign: 'center' }} />
        </Box>
        {dailyLogs?.map((row) => (
          <CustomCollapsibleRow
            key={row.id}
            row={row}
            vehicles={vehicles}
            trailers={trailers}
            onDeleteSuccess={onDeleteSuccess}
            driverId={driverId}
          />
        ))}
      </Box>
    </SWrapper>
  );
};

export default CollapsibleTable;
