import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SUploaderWrapper = styled.div`
  width: 50%;

  .attachment-wrapper {
    //width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f1fa;
    border-radius: 4px;
    height: 24px;
    padding: 0 8px 0 8px;
    margin: 0;
  }

  .attachment-icon {
    width: 12px;
    height: 14px;
  }

  .text-style {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #464f60;
    margin: 0 8px;
  }

  .upload-selector {
    height: 32px;
    border-radius: 6px;
    border: 1px dashed ${palette.gray50};
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 0 0 1px rgb(70 79 96 / 16%), 0 2px 5px rgb(89 96 120 / 10%);
    position: relative;
    background-color: ${palette.white};
    margin: 0;
    width: 100%;
  }
`;
