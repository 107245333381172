import React from 'react';
import { useSelector } from 'react-redux';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import RowActions from 'pages/Accounting/Payables/TableSection/shared/RowActions';

export const useColumns = ({
  allSelected,
  selectedRows,
  hideSelectAll,
  handleSelectAll,
  handleSelectRow,
  sort,
  sortingQuery,
  onEdit,
  onDelete,
  onMarkPaid,
}) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const { use } = useTheme();

  return [
    {
      field: '',
      cellStyle: { backgroundColor: palette.red0 },
      title: !hideSelectAll ? <CustomCheckbox onChange={handleSelectAll} checked={allSelected} /> : '',
      render: (row) => (
        <span onClick={(e) => e.stopPropagation()}>
          <CustomCheckbox
            onChange={(checked) => handleSelectRow(checked, row)}
            checked={selectedRows?.some((i) => i.id === row.id)}
          />
        </span>
      ),
    },
    {
      field: 'vendor',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='VENDOR' field='vendor_id' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: use(palette.gray700) }}>
          {row.vendor?.name || '-'}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='DUE DATE' field='bill_due_date' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {formatDate(row.bill_due_date)}
        </Typography>
      ),
    },
    {
      field: 'aging',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='AGING' field='aging' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.aging > 0
            ? `Past due ${row.aging} day${Math.abs(row.aging) > 1 ? 's' : ''}`
            : `Due in ${Math.abs(row.aging)} day${Math.abs(row.aging) > 1 ? 's' : ''}`}
        </Typography>
      ),
    },
    {
      field: 'reference_id',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='BILL REFERENCE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.reference_id}
        </Typography>
      ),
    },
    {
      field: 'memo',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='MEMO' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.memo}
        </Typography>
      ),
    },
    {
      field: 'amount_due',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='AMOUNT DUE' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency}
          {formatNumber(row.amount_due || 0)}
        </Typography>
      ),
    },
    {
      field: 'amount_paid',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='AMOUNT PAID' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency}
          {formatNumber(row.amount_paid)}
        </Typography>
      ),
    },
    {
      field: 'open_balance',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='OPEN BALANCE' field='open_balance' sort={sort} onClick={sortingQuery} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency}
          {formatNumber(row.open_balance)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      cellStyle: { backgroundColor: palette.red0 },
      title: <ColumnHeader title='' />,
      render: (row) => (
        <RowActions
          onPayBill={() => null}
          onEdit={() => onEdit(row)}
          onDelete={() => onDelete(row)}
          onMarkPaid={() => onMarkPaid(row)}
        />
      ),
    },
  ];
};
