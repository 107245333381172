import { array, object, string, number, date, boolean, ref, mixed } from 'yup';
import moment from 'moment';
import { validator } from 'utils/helpers';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const basicInfoValidationSchema = object().shape({
  fullName: string().trim().required('This field is required'),
  socialNumber: string()
    .trim()
    .required('This field is required')
    .test('a', 'Must be exactly 9 characters', (val) => val && val.toString().length === 9),
  phoneNumber: string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
  emergency_contact_number: string().matches(phoneRegExp, 'Phone number is not valid'),
  email: string().required('Email is required').email('Invalid Email Address'),
  birthDate: validator.pastRequiredDate('This field is required'),
  addresses: array().of(
    object().shape({
      start_date: date().when('noPreviousAddress', {
        is: (noPreviousAddress) => !noPreviousAddress,
        then: validator.pastRequiredDate().max(ref('end_date'), "Start date can't be after end date").nullable(),
        otherwise: validator.date(),
      }),
      end_date: date().when('noPreviousAddress', {
        is: (noPreviousAddress) => !noPreviousAddress,
        then: validator.pastRequiredDate().min(ref('start_date'), '').nullable(),
        otherwise: validator.date(),
      }),
      country: object().when('noPreviousAddress', {
        is: (noPreviousAddress) => !noPreviousAddress,
        then: object()
          .shape({ id: number().required('Country is required') })
          .required('Country is required')
          .nullable(),
        otherwise: object().nullable(),
      }),
      state: object().when('noPreviousAddress', {
        is: (noPreviousAddress) => !noPreviousAddress,
        then: object()
          .shape({ id: number().required('State is required') })
          .required('State is required')
          .nullable(),
        otherwise: object().nullable(),
      }),
      city: object().when('noPreviousAddress', {
        is: (noPreviousAddress) => !noPreviousAddress,
        then: object()
          .shape({ id: number().required('City is required') })
          .required('City is required')
          .nullable(),
        otherwise: object().nullable(),
      }),
      address1: string().when('noPreviousAddress', {
        is: (noPreviousAddress) => !noPreviousAddress,
        then: string().trim().required('Address 1 is required').nullable(),
        otherwise: string().trim().nullable(),
      }),
      address2: string().trim().nullable(),
      zipcode: string().when('noPreviousAddress', {
        is: (noPreviousAddress) => !noPreviousAddress,
        then: string().trim().required('Zip code is required').nullable(),
        otherwise: string().trim().nullable(),
      }),
    })
  ),
});

export const positionValidationSchema = object().shape(
  {
    applicantType: object()
      .shape({ id: number().required('Applicant type is required') })
      .required('Applicant type is required')
      .nullable(),
    position: object()
      .shape({ id: number().required('Position is required') })
      .required('Position is required')
      .nullable(),
    equipment: array().when('applicantType', {
      is: (applicantType) => applicantType?.id === 1,
      then: array()
        .of(
          object()
            .shape({ id: number().required('This field is required') })
            .required('This field is required')
            .nullable()
        )
        .min(1, 'This field is required')
        .nullable(),
      otherwise: array().nullable(),
    }),
    years: number()
      .min(0, 'Year must be in range of 0-100')
      .max(100, 'Year must be in range of 0-100')
      .when('months', {
        is: (months) => !months,
        then: number().required('This field is required').typeError('Years must be a number'),
        otherwise: number().typeError('Years must be a number'),
      }),
    months: number()
      .min(0, 'Month must be in range of 0-11')
      .max(12, 'Month must be in range of 0-11')
      .when('years', {
        is: (years) => !years,
        then: number().typeError('Months must be a number').required('This field is required'),
        otherwise: number().typeError('Months must be a number'),
      }),
  },
  [['years', 'months']]
);

export const applicantInfoValidationSchema = object().shape({
  applicantStatus: string().required('This field is required'),
  fullName: string().when('applicantStatus', {
    is: 'individual',
    then: string().trim().required('Name is required'),
  }),
  socialNumber: number().when('applicantStatus', {
    is: 'individual',
    then: number().required('SSN is required'),
  }),
  companyName: string().when('applicantStatus', {
    is: 'company',
    then: string().trim().required('Company name is required'),
  }),
  ein: string().when('applicantStatus', {
    is: 'company',
    then: string()
      .required('This field is required')
      .test('a', 'Must be exactly 9 characters', (val) => val && val.length === 9)
      .nullable(),
  }),
  country: object()
    .shape({ id: number().required('Country is required') })
    .required('Country is required')
    .nullable(),
  state: object()
    .shape({ id: number().required('State is required') })
    .required('State is required')
    .nullable(),
  city: object()
    .shape({ id: number().required('City is required') })
    .required('City is required')
    .nullable(),
  address1: string().trim().required('Address 1 is required'),
  address2: string().trim().nullable(),
  zipcode: string().trim().required('Zip code is required'),
  canTravelCanada: boolean().required('This field is required'),
  canTravelMexico: boolean().required('This field is required'),
  canTravelUS: boolean().required('This field is required'),
  workedBefore: boolean().required('This field is required'),
  currentlyEmployed: boolean().required('This field is required'),
  currentCompany: string().when('currentlyEmployed', {
    is: true,
    then: string().trim().required('This field is required'),
  }),
  haveEnglishSkills: boolean().required('This field is required'),
});

export const driverLicenseValidationSchema = object().shape({
  licenseNumber: string().trim().required('This field is required'),
  country: object()
    .shape({ id: number().required('Country is required') })
    .required('Country is required')
    .nullable(),
  state: object()
    .shape({ id: number().required('State is required') })
    .required('State is required')
    .nullable(),
  licenseType: object()
    .shape({ id: number().required('License type is required') })
    .required('License type is required')
    .nullable(),
  expirationDate: validator.futureRequiredDate('This field is required'),
  licenseUrl: mixed().required('Attachment is required'),
  licenseUrlBack: mixed().required('Attachment is required'),
});

export const staffLicenseValidationSchema = object().shape({
  photo_id_number: string().trim().required('This field is required'),
  photo_id_type: object()
    .shape({ id: number().required('Type is required') })
    .required('Type is required')
    .nullable(),
  photo_id_expiration: validator.futureRequiredDate('This field is required'),
  photo_id_attachment: mixed().required('Document is required'),
});

export const medicalCardValidationSchema = object().shape({
  medicalCardUrl: string().required('Medical examiner’s card is required').nullable(),
  expirationDate: validator.requiredDate('This field is required'),
});

export const previousEmploymentValidationSchema = array().of(
  object().shape({
    employerName: string().trim().required('This field is required'),
    email: string().required('Email is required').email('Invalid Email Address'),
    phoneNumber: string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    fax: string().matches(phoneRegExp, 'Fax number is not valid'),
    present: boolean().required('This field is required'),
    startDate: date().when('present', {
      is: false,
      then: validator
        .pastRequiredDate('Start date is required')
        .test('Required', "Start date can't be after end date", (val, context) => {
          const { endDate } = context.parent;
          return !moment(endDate).isBefore(moment(val));
        }),
      otherwise: validator.pastRequiredDate('Start date is required'),
    }),
    endDate: mixed().when('present', {
      is: false,
      then: validator
        .pastRequiredDate('End date is required')
        .min(ref('startDate'), "End date can't be before start date"),
      otherwise: mixed().nullable(),
    }),
    address: string().trim().required('Required').nullable(),
    country: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    state: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    city: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    zipcode: string().trim().required('Required').nullable(),
    position: string().trim().required('Required').nullable(),
    reason: string().when('present', {
      is: false,
      then: string().trim().required('Required').nullable(),
      otherwise: string().nullable(),
    }),
    terminated: number().oneOf([0, 1], 'Required').nullable(),
    isCurrentEmployer: number().oneOf([0, 1], 'Required').nullable(),
    canContact: number().oneOf([0, 1], 'Required').nullable(),
    operatedCommercialVehicle: number().oneOf([0, 1], 'Required').nullable(),
    subjectToSafetyRegulations: number().oneOf([0, 1], 'Required').nullable(),
    performSafetyFunctions: number().oneOf([0, 1], 'Required').nullable(),
  })
);

export const militaryValidationSchema = object().shape({
  service_branch: string().trim().required('Required'),
  service_from: validator.pastRequiredDate().max(ref('service_to'), "Start date can't be after end date").nullable(),
  service_to: validator.pastRequiredDate().min(ref('service_from'), '').nullable(),
  discharge_rank: string().trim().required('Required'),
  discharge_type: string().trim().required('Required'),
  mos: string().trim().required('Required'),
});

export const educationValidationSchema = object().shape({
  school_name: string().trim().required('Required').nullable(),
  start_date: validator.requiredDate().max(ref('start_date'), "Start date can't be after end date").nullable(),
  end_date: validator.requiredDate().min(ref('end_date'), '').nullable(),
  address: string().trim().required('Required').nullable(),
  country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  zipcode: string().trim().required('Required').nullable(),
  subject: string().trim().required('Required').nullable(),
  phone_no: string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
  subject_to_safety_regulations: number().oneOf([0, 1], 'Required').nullable(),
  perform_safety_functions: number().oneOf([0, 1], 'Required').nullable(),
  crossed_border: number().oneOf([0, 1], 'Required').nullable(),
  federal_motor_regulations: number().oneOf([0, 1], 'Required').nullable(),
  log_books: number().oneOf([0, 1], 'Required').nullable(),
  hazardous_materials: number().oneOf([0, 1], 'Required').nullable(),
  gpa: string().trim().required('Required').nullable(),
  instruction_hrs: string().trim().required('Required').nullable(),
  graduation_date: date().when('ongoing', {
    is: (ongoing) => !ongoing,
    then: validator.requiredDate(),
    otherwise: date().nullable(),
  }),
});

export const trafficAccidentsValidationSchema = object().shape({
  involved_in_accidents: number().oneOf([0, 1], 'Required').nullable(),
  accidents: array().when('involved_in_accidents', {
    is: (involved_in_accidents) => !!involved_in_accidents,
    then: array().of(
      object().shape({
        accident_nature: string().trim().required('Required').nullable(),
        date: validator.requiredDate(),
        fatalities: number().oneOf([0, 1], 'Required').nullable(),
        fatalities_count: number().when('fatalities', {
          is: (fatalities) => !!fatalities,
          then: number().required('Required').nullable(),
          other: number().nullable(),
        }),
        injuries: number().oneOf([0, 1], 'Required').nullable(),
        injuries_count: number().when('injuries', {
          is: (injuries) => !!injuries,
          then: number().required('Required').nullable(),
          other: number().nullable(),
        }),
      })
    ),
    otherwise: array().nullable(),
  }),
  involved_in_violations: number().oneOf([0, 1], 'Required').nullable(),
  traffics: array().when('involved_in_violations', {
    is: (involved_in_violations) => !!involved_in_violations,
    then: array().of(
      object().shape({
        address: string().trim().required('Required').nullable(),
        country: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        state: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        city: object()
          .shape({ id: number().required('Required') })
          .required('Required')
          .nullable(),
        zipcode: string().trim().required('Required').nullable(),
        date: validator.requiredDate(),
        charge: string().trim().required('Required').nullable(),
        penalty: string().trim().required('Required').nullable(),
      })
    ),
    otherwise: array().nullable(),
  }),

  denied_license: number().oneOf([0, 1], 'Required').nullable(),
  denied_statement: string().when('denied_license', {
    is: (denied_license) => !!denied_license,
    then: string().trim().required('Required').nullable(),
    other: string().nullable(),
  }),
  suspended_license: number().oneOf([0, 1], 'Required').nullable(),
  suspended_statement: string().when('suspended_license', {
    is: (suspended_license) => !!suspended_license,
    then: string().trim().required('Required').nullable(),
    other: string().nullable(),
  }),
});

export const otherValidationSchema = object().shape({
  questions: array().of(string().trim().required('This field is required')),
});
