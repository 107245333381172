import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import hexRgb from 'hex-rgb';
import BackArrow from 'assets/icons/drivers/arrow-left.svg';
import inbox from 'assets/icons/drivers/inbox.svg';
import call from 'assets/icons/drivers/call.svg';
import useFlag from 'assets/icons/drivers/usaFlag.svg';
import turkeyFlag from 'assets/icons/drivers/turkeyFlag.svg';
import maxico from 'assets/icons/drivers/mexico.png';
import down from 'assets/icons/drivers/down.svg';
import edit from 'assets/icons/drivers/edit.svg';
import './OwnerOperatorProfile.css';
import { AddCompensationOwner, UpdateBasicOwnerDetail, UpdateCompensationOwner } from 'Api/Driver';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import { palette, statusColor } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import {
  OperatorGeneral,
  getStatus,
  UpdateStatus,
  operatorDriverCount,
  operatorEquipmentCount,
  operatorStats,
  operatorCompansationData,
} from 'Api/OwnerOperator';
import CustomizedSnackbars from '../toast/Toast';
import OwnerProfileTab from '../OwnerProfileTab/OwnerProfileTab';
import AddGroups from '../AddGroupModal/AddGroupModal';
import OwnerOperatorUpdateModal from '../StatusUpdateModal/ownerOperatorStatusUpdate';

const OwnerOperatorProfile = () => {
  const { formatDate } = useDateFormat();
  const [statusUpdateModalShow, setStatusUpdateModalShow] = useState(false);
  const [addGroupModalShow, setAddGroupModalShow] = useState(false);
  const [driverStatus, setDriverStatus] = useState({});
  const [driverGeneral, setDriverGeneral] = useState();
  const [driverCompansation, setDriverCompansation] = useState();
  const [driverAbsence] = useState();
  const [driverStatsData, setDriverStatsData] = useState({});
  const [driverMedical] = useState([]);
  const [driverCount, setDriverCount] = useState();
  const [equipmentCount, setEquipmentCount] = useState();
  const [driverGroupsData, setDriverGroupsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { use } = useTheme();

  useEffect(() => {
    OperatorGeneral({ id }).then((res) => {
      setDriverGeneral(res?.data);
      setDriverGroupsData(res.data.operator_group);
    });

    getStatus({ id }).then((res) => {
      setDriverStatus(res.data?.status);
    });

    operatorStats({ id }).then((res) => {
      setDriverStatsData(res.data);
    });

    operatorDriverCount({ id }).then((res) => {
      let totalCount = 0;
      res.data.forEach((item) => {
        totalCount += item.drivers_count;
      });
      setDriverCount(totalCount);
    });

    operatorEquipmentCount({ id }).then((res) => {
      let totalCount = 0;
      res.data.forEach((item) => {
        totalCount += item.equipment_count;
      });

      setEquipmentCount(totalCount);
    });

    GetCompensation(id);
  }, []);

  const GetCompensation = (id) => {
    operatorCompansationData({ id }).then((res) => {
      setDriverCompansation(res?.data?.compensation);
    });
  };

  const onSuccess = (message) => {
    if (message) {
      setShowMessage({
        message,
        visible: true,
        type: 'success',
      });
      setTimeout(() => {
        setShowMessage({
          visible: false,
          message: '',
        });
      }, 6000);
    }
  };

  const updateProfile = (updatedDriverInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);

    UpdateBasicOwnerDetail(updatedDriverInfo, id)
      .then((res) => {
        const updatedData = {
          ...driverGeneral,
          ...updatedDriverInfo,
          ...res?.data,
          certifications: updatedDriverInfo?.certifications,
        };
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverGeneral(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const updateCompensation = (updatedCompensationInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    if (!driverCompansation) {
      AddCompensationOwner(updatedCompensationInfo)
        .then((res) => {
          const updatedData = { ...driverCompansation, ...updatedCompensationInfo, ...res?.data };
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          setDriverCompansation(updatedData);
          GetCompensation(id);
          setLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setLoading(false);
        });
    } else {
      UpdateCompensationOwner(updatedCompensationInfo, driverCompansation?.id)
        .then((res) => {
          const updatedData = { ...driverCompansation, ...updatedCompensationInfo, ...res?.data };
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          setDriverCompansation(updatedData);
          GetCompensation(id);
          setLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setLoading(false);
        });
    }
  };

  const updateGroupProfile = (updatedDriverGroupsInfo, onHide = null, onSuccess = null) => {
    setLoading(true);
    UpdateBasicOwnerDetail({ group: updatedDriverGroupsInfo.map((group) => group.id) }, id)
      .then(() => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess('Operator groups has been updated successfully');
        }
        setDriverGroupsData(updatedDriverGroupsInfo);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const showError = (message) => {
    setShowMessage({
      message,
      visible: true,
      type: 'error',
    });
    setTimeout(() => {
      setShowMessage({
        visible: false,
        message: '',
      });
    }, 6000);
  };

  const updateDriverStatus = (updatedDriverGroupsInfo, onHide = null, onSuccess = null, successMsg = '') => {
    setLoading(true);
    UpdateStatus({ ...updatedDriverGroupsInfo, operator_id: id })
      .then((res) => {
        if (onHide) {
          onHide();
        }
        if (onSuccess) {
          onSuccess(successMsg);
        }
        setDriverStatus(res?.data?.status);
        setLoading(false);
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setLoading(false);
      });
  };

  const continueAdding = () => {
    navigate(`/owner-operators`, {
      state: { openCreate: true, openStep: driverGeneral.draft_step_number, currentDriverId: id },
    });
  };

  return (
    <div className='profile-container driver-style-wrap owner-operator  '>
      <div className='back-navigate-container' onClick={() => navigate('/owner-operators')}>
        <img src={BackArrow} alt='Back-Arrow' />
        <p className='navigater-page-name'>Owner Operator</p>
      </div>
      <div
        className='user-profile-header pl-3 pb-3'
        style={{ borderBottomColor: use(palette.gray50, palette.darkborder), justifyContent: 'flex-start' }}
      >
        <div>
          <div className='user-details-container justify-content-start'>
            <div className='user-details'>
              <div className='user-name-and-actions-container'>
                <h2 className='username' style={{ color: use(palette.gray900, palette.gray50) }}>
                  {driverGeneral?.owner_operator_name || ''}{' '}
                </h2>
                <div className='call-to-action-icons'>
                  <a href={`mailto:${driverGeneral?.email}`}>
                    <img src={inbox} alt='inbox-icon' />
                  </a>
                  <a href={`tel:${driverGeneral?.phone_number}`}>
                    <img src={call} alt='call-icon' />
                  </a>
                </div>
              </div>
              <div className='user-country-and-status-container'>
                <div className='country-flag-icons'>
                  {driverGeneral?.vehicles_can_travel?.map((item) => {
                    return item?.id === 1 ? (
                      <img src={useFlag} alt='USA-Flag' />
                    ) : item?.id === 2 ? (
                      <img src={turkeyFlag} alt='canada-Flag' />
                    ) : item?.id === 3 ? (
                      <img src={maxico} alt='mexico-Flag' className='mexico-flag-icon' />
                    ) : null;
                  })}
                </div>
                <div
                  className='user-status'
                  style={{
                    backgroundColor: use(
                      statusColor[driverStatus?.status || ' ']?.bgColor,
                      statusColor[driverStatus?.status]?.darkBgColor || ''
                    ),
                  }}
                >
                  <p
                    className='user-status-tag'
                    style={{
                      color: use(
                        statusColor[driverStatus?.status || '']?.color,
                        statusColor[driverStatus?.status || '']?.darkColor
                      ),
                    }}
                  >
                    {driverStatus?.status || ''}
                  </p>
                </div>
                <div className='user-dropdown-icon'>
                  <img src={down} alt='Down-icon' onClick={() => setStatusUpdateModalShow(true)} />
                  {statusUpdateModalShow && (
                    <OwnerOperatorUpdateModal
                      show={statusUpdateModalShow}
                      onHide={() => setStatusUpdateModalShow(false)}
                      name={`${driverGeneral?.owner_operator_name}`}
                      id={id}
                      driverStatus={driverStatus}
                      updateDriverStatus={updateDriverStatus}
                      loading={loading}
                      onSuccess={onSuccess}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {driverGeneral?.draft_step_number && (
          <div className='draft ms-5' onClick={continueAdding}>
            <span className='draft-link'>Continue Adding Owner Operator </span>
          </div>
        )}
      </div>
      <div className='user-profile-sub-header' style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data'>
            <p className='user-data-head'>Tags</p>
            <img src={edit} alt='edit' onClick={() => setAddGroupModalShow(true)} />
            {addGroupModalShow && (
              <AddGroups
                show={addGroupModalShow}
                onHide={() => setAddGroupModalShow(false)}
                showError={showError}
                onSuccess={onSuccess}
                driverGroupInfo={driverGroupsData}
                updateGroupProfile={updateGroupProfile}
                loading={loading}
              />
            )}
          </div>
          <div className='data-group-wraper'>
            {[...(driverGroupsData || [])].splice(0, 4)?.map((group) => {
              const colorCode = hexRgb(group.color);
              const TextColor =
                colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';
              return (
                <div className='user-data-group' style={{ backgroundColor: group.color }}>
                  <p className='group-text' style={{ color: TextColor }}>
                    {group.group_name}
                  </p>
                </div>
              );
            })}
            {driverGroupsData?.length > 4 ? <span>...</span> : null}
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
            <div className='user-data'>
              <p className='user-data-head'>ID</p>
            </div>
            <div>
              <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
                {driverGeneral?.id}
              </p>
            </div>
          </div>
        </div>

        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
            <div className='user-data'>
              <p className='user-data-head'>Join Date</p>
            </div>
            <div>
              <p className='user-data-text' style={{ color: use(palette.gray900, palette.gray50) }}>
                {driverGeneral?.created_at ? formatDate(new Date(driverGeneral?.created_at)) : ''}
              </p>
            </div>
          </div>
        </div>

        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
            <div className='user-data'>
              <p className='user-data-head'>Driver Count</p>
            </div>
            <div
              className='date-container'
              style={{
                backgroundColor: use('#F0F2F7', palette.dark600),
                color: use(palette.gray600, palette.gray400),
              }}
            >
              <p className='sub-text'>{driverCount}</p>
            </div>
          </div>
        </div>
        <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
          <div className='user-data-container' style={{ borderRightColor: use(palette.gray50, palette.darkborder) }}>
            <div className='user-data'>
              <p className='user-data-head'>Equipment Count</p>
            </div>
            <div
              className='date-container'
              style={{
                backgroundColor: use('#F0F2F7', palette.dark600),
                color: use(palette.gray600, palette.gray400),
              }}
            >
              <p className='sub-text'>{equipmentCount}</p>
            </div>
          </div>
        </div>
      </div>
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
      <OwnerProfileTab
        loading={loading}
        staffInfo={driverGeneral}
        onSuccess={onSuccess}
        updateProfile={updateProfile}
        staffStatsData={driverStatsData}
        updateCompensation={updateCompensation}
        operatorCompansation={driverCompansation}
        driverMedical={driverMedical}
        showError={showError}
        driverAbsence={driverAbsence}
        email_id={driverGeneral?.email_id}
        operator_name={driverGeneral?.owner_operator_name}
      />
    </div>
  );
};
export default OwnerOperatorProfile;
