import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReactComponent as SubRight } from 'assets/icons/createShipment/subRight.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/createShipment/settingsFiled.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import ErrorMessage from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomTextArea from 'components/CustomTextArea';
import { DatePicker, TimePicker } from 'common/Pickers';
import CustomButton from 'components/CustomButton/CustomButton';
import Input, { InputWithIcon, InputWithText } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import ShipmentCommodity from 'componentsV2/Commodity/ShipmentCommodity';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { formatNumber, palette, VALUE_TYPES } from 'utils/constants';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import {
  EQUIPMENT_ACTION,
  QUANTITY,
  SCHEDULED_DATE_TYPE,
  WEIGHT_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getAllTrailers } from 'Api/Map';
import { createQuickEquipment } from 'Api/Shipment';
import { GetEquipmentTrailer } from 'Api/StopPoint';
import { getGrandTotal, getMeasurementTypes, getQuantityTypes, getWeightTypes } from 'Api/Planner';
import { getCargoTotals } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { SMergedInputs } from 'components/CreateShipment/CreateShipment.styles';
import classes from '../addEditModalStops.module.css';
import { shouldUpdateStops } from '../validates';

const PickUp = ({
  stop,
  errors,
  touched,
  stopPoints,
  equipmentRequired,
  currentStopAddValue,
  setEquipmentId,
  equipmentLengthData,
  commodity,
  onChangeCommodityModal,
  onChangeAddStopPointModal,
  getCommodityAll,
  getEquipmentRequired,
  getCustomerStopPoint,
  editMood,
  isShipmentTable,
  setFieldValue,
  setFieldTouched,
  isSplit,
  isRecurring,
  onCreateSuccess,
  onUpdateSuccess,
  onDeleteSuccess,
  shipmentSettings,
  stopInfo,
}) => {
  const { use } = useTheme();
  const ref = useRef(null);
  const showToaster = useShowToaster();
  const controller = new AbortController();
  const { billOfLadingTitle } = useSelector((state) => state.root);
  const [selectedEquipment, setSelectedEquipment] = useState({});
  const [localEquipmentIdData, setLocalEquipmentIdData] = useState([]);
  const [weightTypes, setWeightTypes] = useState([]);
  const [, setMeasurementTypes] = useState([]);
  const [quantityTypes, setQuantityTypes] = useState([]);
  const equipment = equipmentRequired?.find((i) => Number(i.key) === Number(stop?.equipment_type));
  const flagEquipmentRequired = equipment?.flag;
  const length = stop?.equipment_type_length
    ? equipmentLengthData?.find((i) => Number(i.key) === Number(stop?.equipment_type_length))
    : equipmentLengthData?.[0] || null;
  const [thirdParty, setThirdParty] = useState(false);
  const [toBeDetermined, setToBeDetermined] = useState(false);
  const [thirdPartyLoading, setThirdPartyLoading] = useState(false);

  const [customTrailerId, setCustomTrailerId] = useState(null);
  const [customTrailerType, setCustomTrailerType] = useState(null);
  const [customTrailerLength, setCustomTrailerLength] = useState(null);
  const [droppedTrailerIds, setDroppedTrailerIds] = useState([]);
  const [openAddCommodity, setOpenAddCommodity] = useState(false);

  const equipmentHeightFeet = !!length?.height && length.height / 12;
  const totalValues = getCargoTotals(stop.cargos || [], equipmentHeightFeet);

  const onCargoFieldChange = (field, value) => {
    setFieldValue(`cargos[0].${field}`, value);
  };

  const onChangeTimeValidate = (date, field) => {
    setFieldValue(field, date);

    if (field === 'scheduled_date') {
      const startDate = moment(date);
      const endDate = moment(stop.scheduled_date_to);
      if (endDate.isBefore(startDate)) {
        setFieldValue(`scheduled_date_to`, startDate.toDate());
      }
    }

    if (field === 'scheduled_date_to') {
      setFieldValue(`to`, null);
      setFieldTouched(`to`, false);
    } else {
      setFieldValue(`from`, null);
      setFieldTouched(`from`, false);
    }
  };

  const onChangeStopPointId = (key, first = false) => {
    const selectedStopPointId = stopPoints.find((el) => +el.key === +key);
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      !first && onChangeTimeValidate(date, 'scheduled_date');
    }
    if (selectedStopPointId?.working_hour_by) {
      setFieldValue(`scheduled_type`, selectedStopPointId?.working_hour_by?.toString());
      onChangeScheduledType(SCHEDULED_DATE_TYPE.find((i) => i.key === selectedStopPointId?.working_hour_by));
    }
  };

  const onChangeScheduledType = () => {
    setFieldValue(`to`, '');
    if (stop.scheduled_date) {
      const date = moment(stop.scheduled_date, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, 'scheduled_date');
    }

    if (stop.scheduled_date_to) {
      const date = moment(stop.scheduled_date_to, 'MM/DD/YYYY').toDate();
      onChangeTimeValidate(date, 'scheduled_date_to');
    }
  };

  const getGrandTotalDefault = () => {
    getGrandTotal()
      .then((res) => {
        if (res && res?.data) {
          const qtyObj = {
            pieces: QUANTITY[0].key,
            units: QUANTITY[1].key,
            qty: QUANTITY[2].key,
          };
          const weightObj = {
            lbs: WEIGHT_TYPE[0].key,
            kgs: WEIGHT_TYPE[1].key,
          };

          setFieldValue(`quantity_type`, String(qtyObj[res.data?.default_qty_type]));
          setFieldValue(`weight_type`, String(weightObj[res.data?.default_weight]));
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const getEquipmentIdInType = () => {
    getAllTrailers(null, null, null, { allFilterData: { status_id: '1,2,4,6,7,8' } }).then((res) => {
      if (res && Array.isArray(res.data)) {
        const equipmentId = res.data.map((el) => {
          const label = `${el?.equipment_id || ''} (${el?.make || ''} ${el?.model_id || ''})`;

          return {
            ...el,
            key: el.id,
            label,
            labelSelected: null,
          };
        });
        setLocalEquipmentIdData([...equipmentId]);
        setEquipmentId([...equipmentId]);
      }
    });
  };

  const getAdditionalData = async () => {
    try {
      const [{ data: measurement }, { data: quantity }, { data: weight }] = await Promise.all([
        getMeasurementTypes(),
        getQuantityTypes(),
        getWeightTypes(),
      ]);
      setWeightTypes(weight || []);
      setMeasurementTypes(measurement || []);
      setQuantityTypes(quantity || []);
    } catch (e) {
      // Do nothing
    }
  };

  const styles = useMemo(() => {
    return {
      equipmentType: {
        width: selectedEquipment?.flag === 'trailers' ? 283 : 400,
        maxWidth: selectedEquipment?.flag === 'trailers' ? 283 : 400,
        height: 22,
        marginRight: 8,
      },
    };
  }, [use, palette, selectedEquipment]);

  const getTrailers = async () => {
    try {
      const { data } = await GetEquipmentTrailer({
        'sort[][equipment_id]': 'desc',
        stop_point_id: Number(stop.stop_point_id),
      });
      setDroppedTrailerIds(data.map((i) => i.equipment_id));
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomerStopPoint(controller.signal);
    getEquipmentRequired();
    getCommodityAll();
    (!stop.reported_weight_type || !stop.quantity_type) && getGrandTotalDefault();
    getEquipmentIdInType();
  }, []);

  useEffect(() => {
    const selectedData = equipmentRequired.find((item) => +item.key === +stop.equipment_type);
    !!selectedData && setSelectedEquipment(selectedData);
    selectedData?.flag === 'vehicles' && setFieldValue(`equipment_type_length`, '');
  }, [equipmentRequired, stop?.equipment_type]);

  useEffect(() => {
    if (!stop?.stop_point_id) {
      return;
    }

    onChangeStopPointId(stop.stop_point_id, true);
    getTrailers();
  }, [stop.stop_point_id]);

  useEffect(() => {
    setToBeDetermined(stop?.equipment_tbd);
  }, [stop?.equipment_tbd]);

  useEffect(() => {
    getAdditionalData();
  }, []);

  const onAddCustomTrailer = () => {
    if (customTrailerType && customTrailerId && customTrailerLength) {
      const body = {
        equipment_type_id: customTrailerType.key,
        equipment_id: customTrailerId,
        length_id: customTrailerLength.key,
      };
      setThirdPartyLoading(true);
      createQuickEquipment(body)
        .then((res) => {
          const label = `${res?.data?.equipment_id || ''}  (${res?.data?.make || ''}  ${res?.data?.model_id || ''})`;
          const newValue = {
            ...res.data,
            key: res.data.id,
            label,
            labelSelected: null,
          };
          setLocalEquipmentIdData([...localEquipmentIdData, newValue]);
          showToaster({ type: 'success', message: 'Success' });
          setCustomTrailerId('');
          setCustomTrailerLength(null);
          setCustomTrailerType(null);
          ref.current.click();
          setFieldValue(`equipment_id`, res.data.id);
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        })
        .finally(() => {
          setThirdPartyLoading(false);
        });
    }
  };

  useEffect(() => {
    if (currentStopAddValue) {
      setFieldValue(`stop_point_id`, currentStopAddValue);
    }
  }, [currentStopAddValue]);

  return (
    <div className={classes.allFiledWrapper} ref={ref}>
      {/* Scheduled Date ID */}
      <div className={classes.fieldErrorWrapper}>
        <CustomSelect
          field={{
            name: `stop_point_id`,
            value: stop.stop_point_id,
          }}
          form={{ setFieldValue }}
          isSearchable
          options={stopPoints}
          menuStyles={{ maxWidth: 480 }}
          defaultText='Select Stop Point'
          onChange={(value) => onChangeStopPointId(value?.key)}
          onAddStopPoint={() => onChangeAddStopPointModal(true)}
          styles={{ maxWidth: 480, height: 32, margin: '8px 0 4px' }}
          shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'stop_point_id')}
          isSearchableStyles={{ maxWidth: 480, width: '100%', margin: '8px 0 4px', height: 32 }}
        />
        <ErrorMessage error={touched?.stop_point_id && errors?.stop_point_id} />
      </div>

      {/* Scheduled Date Type */}
      <div className={classes.fieldWrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Scheduled Date Type
        </Typography>
        <div style={{ flexGrow: 1 }}>
          <CustomSelect
            field={{ name: `scheduled_type`, value: stop.scheduled_type }}
            form={{ setFieldValue }}
            menuStyles={{ width: 400 }}
            options={SCHEDULED_DATE_TYPE}
            styles={{ maxWidth: 400, height: 22 }}
            onChange={(e) => onChangeScheduledType(e)}
            shouldUpdate={() => true}
          />
          <ErrorMessage error={touched?.scheduled_type && errors?.scheduled_type} />
        </div>
        <div style={{ flexGrow: 1 }} />
      </div>

      {/* Scheduled Date/Time */}
      <div className='d-flex justify-content-start align-items-start mt-2'>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start' }}>
          Scheduled Date/Time
        </Typography>
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex justify-content-start align-items-start gap-2'>
            <div>
              <DatePicker
                width='130px'
                size='small'
                name='scheduled_date'
                value={stop.scheduled_date}
                onChange={(date) => onChangeTimeValidate(date, 'scheduled_date')}
                error={touched?.scheduled_date && errors?.scheduled_date}
                disablePast={!shipmentSettings?.allow_past_date}
              />
            </div>
            <div>
              <TimePicker
                size='small'
                width='100px'
                value={stop.from}
                onChange={(time) => setFieldValue('from', time)}
                error={touched?.from && errors?.from}
              />
            </div>
          </div>
          {[1, 3].includes(+stop.scheduled_type) && (
            <div className='d-flex justify-content-start align-items-start gap-2'>
              <Typography variant='s2'>to</Typography>
              <div>
                <DatePicker
                  width='130px'
                  size='small'
                  name='scheduled_date_to'
                  value={stop.scheduled_date_to}
                  onChange={(date) => onChangeTimeValidate(date, 'scheduled_date_to')}
                  error={touched?.scheduled_date_to && errors?.scheduled_date_to}
                  minDate={moment(stop.scheduled_date).toDate()}
                />
              </div>
              <div>
                <TimePicker
                  size='small'
                  width='100px'
                  value={stop.to}
                  onChange={(time) => setFieldValue('to', time)}
                  error={touched?.to && errors?.to}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Arrival Date Type */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <div className={classes.fieldWrapperInput}>
            <Typography
              variant='s2'
              style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}
            >
              Arrival Date/Time
            </Typography>
            <div className={classes.fieldErrorWrapper} style={{ marginTop: 10 }}>
              <DatePicker
                width='130px'
                size='small'
                name='arrival_date'
                value={stop.arrival_date}
                onChange={(date) => setFieldValue('arrival_date', date)}
                error={touched?.arrival_date && errors?.arrival_date}
                disabled={!stopInfo?.stop?.arrival_date}
              />
            </div>
          </div>

          {/* /*Arrival Start Time*!/ */}
          <div className={classes.fieldWrapperInput} style={{ marginLeft: 12, marginTop: 10 }}>
            <div className={classes.fieldErrorWrapper}>
              <TimePicker
                size='small'
                width='100px'
                value={stop.arrival_time}
                onChange={(time) => setFieldValue('arrival_time', time)}
                error={touched?.arrival_time && errors?.arrival_time}
                disabled={!stopInfo?.stop?.arrival_date}
              />
            </div>
          </div>
        </div>
      )}

      {/* Departure Date Type */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <div className={classes.fieldWrapperInput}>
            <Typography
              variant='s2'
              style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}
            >
              Departure Date/Time
            </Typography>
            <div className={classes.fieldErrorWrapper} style={{ marginTop: 10 }}>
              <DatePicker
                width='130px'
                size='small'
                name='departure_date'
                value={stop.departure_date}
                onChange={(date) => setFieldValue('departure_date', date)}
                error={touched?.departure_date && errors?.departure_date}
                disabled={!stopInfo?.stop?.departure_date}
              />
            </div>
          </div>

          {/* Departure Start Time */}
          <div className={classes.fieldWrapperInput} style={{ marginLeft: 12, marginTop: 10 }}>
            <div className={classes.fieldErrorWrapper}>
              <TimePicker
                size='small'
                width='100px'
                value={stop.departure_time}
                onChange={(time) => setFieldValue('departure_time', time)}
                error={touched?.departure_time && errors?.departure_time}
                disabled={!stopInfo?.stop?.departure_date}
              />
            </div>
          </div>
        </div>
      )}

      {/* Equipment Required and Equipment Required length */}
      <div className={classes.fieldWrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Required
        </Typography>

        <div>
          <div className={classes.fieldErrorWrapper}>
            <CustomSelect
              field={{ name: `equipment_type`, value: stop.equipment_type }}
              form={{ setFieldValue }}
              isSearchable
              styles={styles.equipmentType}
              isSearchableStyles={styles.equipmentType}
              shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_type')}
              options={equipmentRequired}
              menuStyles={{ width: selectedEquipment?.flag === 'trailers' ? 283 : 400 }}
              onChange={(value) => {
                value.flag !== 'vehicles'
                  ? setFieldValue('equipment_action', null)
                  : setFieldValue('equipment_action', EQUIPMENT_ACTION[0].key);
              }}
            />
            <ErrorMessage error={touched?.equipment_type && errors?.equipment_type} />
          </div>
        </div>
        <div style={{ display: selectedEquipment?.flag === 'trailers' ? 'block' : 'none' }}>
          <CustomSelect
            field={{ name: `equipment_type_length`, value: stop.equipment_type_length }}
            form={{ setFieldValue }}
            isSearchable
            menuStyles={{ width: 109 }}
            options={equipmentLengthData}
            shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_type_length')}
            styles={{ width: 109, maxWidth: 109, height: 22 }}
            isSearchableStyles={{ width: 109, maxWidth: 109, height: 22 }}
          />
          <ErrorMessage error={touched?.equipment_type_length && errors?.equipment_type_length} />
        </div>
      </div>

      {/* Equipment Action and Equipment */}
      <div className={classes.fieldWrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Equipment Action
        </Typography>
        <div style={{ flexGrow: 1, maxWidth: 400 }}>
          <Autocomplete
            size='small'
            name='equipment_action'
            labelKey='label'
            isOptionEqualToValue={(option, value) => Number(option.key) === Number(value.key)}
            value={
              (flagEquipmentRequired === 'trailers' || Number(stop?.equipment_type) === 1
                ? EQUIPMENT_ACTION
                : flagEquipmentRequired === 'vehicles'
                ? [EQUIPMENT_ACTION[0]]
                : []
              ).find((i) => i.key === Number(stop.equipment_action)) || null
            }
            onChange={(e, val) => setFieldValue('equipment_action', val.key)}
            options={
              flagEquipmentRequired === 'trailers' || Number(stop?.equipment_type) === 1
                ? EQUIPMENT_ACTION
                : flagEquipmentRequired === 'vehicles'
                ? [EQUIPMENT_ACTION[0]]
                : []
            }
            disabled={!stop?.equipment_type}
          />
          <ErrorMessage error={touched.equipment_action && errors.equipment_action} />
        </div>
      </div>

      {/* Equipment */}
      {(+stop.equipment_action === 2 || +stop.equipment_action === 3) && (
        <div className={classes.fieldWrapper}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
            Equipment
          </Typography>
          <SubRight style={{ width: 20 }} />
          <div style={{ flexGrow: 1 }}>
            <CustomSelect
              field={{ name: `equipment_id`, value: stop.equipment_id }}
              form={{ setFieldValue }}
              isSearchable
              disabled={toBeDetermined}
              // validate={!toBeDetermined ? validateRequired : noValidate}
              styles={{ maxWidth: 380, height: 22 }}
              menuStyles={{ width: 380 }}
              isSearchableStyles={{ width: 380, height: 22 }}
              options={
                isSplit ? localEquipmentIdData.filter((i) => droppedTrailerIds.includes(i.id)) : localEquipmentIdData
              }
              shouldUpdate={(n, p) => shouldUpdateStops(n, p, 'equipment_id')}
            />
            <ErrorMessage error={touched?.equipment_id && errors?.equipment_id} />

            {(+stop.equipment_action === 2 || +stop.equipment_action === 3) && (
              <div>
                <div style={{ maxWidth: '380px', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                  <CustomCheckbox
                    checked={thirdParty}
                    onChange={(checked) => {
                      if (checked) {
                        setThirdParty(true);
                        setToBeDetermined(false);
                        setFieldValue(`equipment_id`, '');
                      } else {
                        setThirdParty(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      3rd party
                    </Typography>
                  </CustomCheckbox>
                  <CustomCheckbox
                    name='equipment_tbd'
                    checked={toBeDetermined}
                    onChange={(checked) => {
                      if (checked) {
                        setFieldValue(`equipment_tbd`, 1);
                        setToBeDetermined(true);
                        setThirdParty(false);
                        setFieldValue(`equipment_id`, '');
                      } else {
                        setFieldValue(`equipment_tbd`, 0);
                        setToBeDetermined(false);
                      }
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      To be determined (TBD)
                    </Typography>
                  </CustomCheckbox>
                </div>
                {thirdParty && (
                  <div className='d-flex gap-3 mb-2' style={{ width: 400, marginTop: 10 }}>
                    <CustomInput
                      value={customTrailerId}
                      labelStyle={{ margin: 0 }}
                      style={{
                        borderRadius: 6,
                        paddingLeft: 8,
                        width: 90,
                      }}
                      placeholder='ID'
                      onChange={(e) => {
                        setCustomTrailerId(e.target.value);
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Type'
                      options={equipmentRequired.filter((item) => item.flag === 'trailers')}
                      value={customTrailerType}
                      onChange={(e, value) => {
                        setCustomTrailerType(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <Autocomplete
                      size='small'
                      labelKey='label'
                      placeholder='Length'
                      options={equipmentLengthData}
                      value={customTrailerLength}
                      onChange={(e, value) => {
                        setCustomTrailerLength(value);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                    <div className='d-flex gap-2'>
                      <CustomButton
                        type='secondary'
                        leftIcon={<DeleteIcon fill={palette.red500} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', margin: 0 }}
                        onClick={() => {
                          setCustomTrailerId('');
                          setCustomTrailerLength(null);
                          setCustomTrailerType(null);
                          setThirdParty(false);
                          setFieldValue(`thirdParty`, false);
                          setFieldValue(`equipment_id`, '');
                        }}
                        disabled={thirdPartyLoading}
                      />
                      <CustomButton
                        type='primary'
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddCustomTrailer();
                        }}
                        leftIcon={<CheckIcon fill={palette.white} width={11} height={11} />}
                        styleButton={{ padding: '5px 8px', marginTop: 0 }}
                        disabled={thirdPartyLoading}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Pick Up Number */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Pick Up Number
        </Typography>
        <div className={classes.fieldErrorWrapper}>
          <CustomInput
            name='number'
            className={classes.customInputField}
            value={stop.number}
            onChange={(e) => setFieldValue(`number`, e.target.value)}
          />
        </div>
      </div>

      {/* Commodity */}
      <div className={classes.fieldWrapper}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157 }}>
          Commodity
          <SettingsIcon style={{ marginLeft: 4, cursor: 'pointer' }} onClick={() => onChangeCommodityModal(true)} />
        </Typography>
        <div className='d-flex align-items-center gap-3 flex-grow-1'>
          <div>
            <Autocomplete
              width='258px'
              size='small'
              name='cargos[0].value_type'
              labelKey='title'
              options={commodity}
              value={
                stop.cargos?.length > 1
                  ? { id: 'multi-commodity', title: 'Multi-Commodities' }
                  : stop.cargos?.[0]?.commodity
              }
              disabled={stop.cargos?.length > 1}
              onChange={(e, value) => onCargoFieldChange('commodity', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touched?.cargos?.[0]?.commodity && errors?.cargos?.[0]?.commodity}
            />
          </div>
          <Typography
            variant='s2'
            style={{ color: palette.indigo500 }}
            className='pointer'
            onClick={() => setOpenAddCommodity(true)}
          >
            View/Add Multiple
          </Typography>
        </div>
      </div>

      {/* Qty */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Qty
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div style={{ marginTop: '10px' }}>
              <SMergedInputs>
                <Input
                  width='250px'
                  type='number'
                  size='small'
                  name='cargos[0].expected_quantity'
                  value={totalValues.expected_quantity}
                  onChange={(e) => onCargoFieldChange('expected_quantity', e.target.value)}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                  disabled={stop.cargos?.length > 1}
                />
                <Autocomplete
                  width='150px'
                  size='small'
                  name='cargos[0].expected_quantity_type'
                  options={quantityTypes}
                  value={stop.cargos?.[0]?.expected_quantity_type}
                  onChange={(e, value) => onCargoFieldChange('expected_quantity_type', value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={stop.cargos?.length > 1}
                />
              </SMergedInputs>
            </div>
          </div>
          <ErrorMessage error={touched?.expected_quantity && errors?.expected_quantity} />
        </div>
      </div>

      {/* Actually Qty */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
            Actual Qty
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div style={{ marginTop: '10px' }}>
                <SMergedInputs>
                  <Input
                    width='250px'
                    type='number'
                    size='small'
                    name='cargos[0].reported_quantity'
                    value={totalValues.reported_quantity}
                    onChange={(e) => setFieldValue('cargos[0].reported_quantity', e.target.value)}
                    disabled={stop.cargos?.length > 1 || !stop.departure_date}
                    onKeyDown={(e) => {
                      blockNotNumberChars(e);
                      blockNonPositiveNumbers(e);
                    }}
                  />
                  <Autocomplete
                    width='150px'
                    size='small'
                    name='cargos[0].reported_quantity_type'
                    options={quantityTypes}
                    value={stop.cargos?.[0]?.reported_quantity_type || null}
                    onChange={(e, value) => setFieldValue('cargos[0].reported_quantity_type', value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={stop.cargos?.length > 1 || !stop.departure_date}
                  />
                </SMergedInputs>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Dimensions */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Dimensions
        </Typography>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {stop.cargos?.length === 1 && (
            <>
              <div style={{ marginTop: '10px' }}>
                <InputWithText
                  width='90px'
                  type='number'
                  size='small'
                  text='L'
                  name='cargos[0].expected_dimensions.l'
                  value={totalValues.length}
                  onChange={(e) => onCargoFieldChange('expected_dimensions.l', e.target.value)}
                  disabled={stop.cargos?.length > 1}
                  // error={touchedErrors[index]?.expected_dimensions?.l}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                />
              </div>

              <div className={classes.dimensionsX}>x</div>
              <div style={{ marginTop: '10px' }}>
                <InputWithText
                  width='90px'
                  type='number'
                  size='small'
                  text='W'
                  name='cargos[0].expected_dimensions.w'
                  value={totalValues.width}
                  onChange={(e) => onCargoFieldChange('expected_dimensions.w', e.target.value)}
                  disabled={stop.cargos?.length > 1}
                  // error={touchedErrors[index]?.expected_dimensions?.w}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                />
              </div>

              <div className={classes.dimensionsX}>x</div>
              <div style={{ marginTop: '10px' }}>
                <InputWithText
                  width='90px'
                  type='number'
                  size='small'
                  text='H'
                  name='cargos[0].expected_dimensions.h'
                  value={totalValues.height}
                  onChange={(e) => onCargoFieldChange('expected_dimensions.h', e.target.value)}
                  disabled={stop.cargos?.length > 1}
                  // error={touchedErrors[index]?.expected_dimensions?.h}
                  onKeyDown={(e) => {
                    blockNotNumberChars(e);
                    blockNonPositiveNumbers(e);
                  }}
                />
              </div>
            </>
          )}
          {stop.cargos?.length > 1 && (
            <div style={{ marginTop: '10px' }} className='ms-2'>
              <Typography variant='b2'>
                {formatNumber(totalValues.volumeInCubicFeet)} <Typography variant='c3'>CU. FT.</Typography>
              </Typography>
            </div>
          )}
        </div>
      </div>

      {/* Weight */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Weight
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div style={{ marginTop: '10px' }}>
              <SMergedInputs>
                <Input
                  width='250px'
                  type={stop.cargos?.length > 1 ? 'text' : 'number'}
                  size='small'
                  name='cargos[0].expected_weight'
                  value={
                    stop.cargos?.length > 1 ? formatNumber(totalValues.expected_weight) : totalValues.expected_weight
                  }
                  onChange={(e) => onCargoFieldChange('expected_weight', e.target.value)}
                  error={touched?.cargos?.[0]?.expected_weight && errors?.cargos?.[0]?.expected_weight}
                  onKeyDown={blockNonPositiveNumbers}
                  disabled={stop.cargos?.length > 1}
                />
                <div>
                  <Autocomplete
                    width='150px'
                    size='small'
                    labelKey='unit'
                    name='cargos[0].expected_weight_type'
                    options={weightTypes}
                    value={stop.cargos?.[0]?.expected_weight_type}
                    onChange={(e, value) => onCargoFieldChange('expected_weight_type', value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={stop.cargos?.length > 1}
                    error={touched?.cargos?.[0]?.expected_weight_type && errors?.cargos?.[0]?.expected_weight_type}
                  />
                </div>
              </SMergedInputs>
            </div>
          </div>
          <ErrorMessage error={touched?.weight && errors?.weight} />
        </div>
      </div>

      {/* Actually Weight */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
            Actual Weight
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div style={{ marginTop: '10px' }}>
                <SMergedInputs>
                  <Input
                    width='250px'
                    type='number'
                    size='small'
                    name='cargos[0].reported_weight'
                    value={totalValues.reported_weight}
                    onChange={(e) => setFieldValue('cargos[0].reported_weight', e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                    disabled={stop.cargos?.length > 1 || !stop.departure_date}
                  />
                  <Autocomplete
                    width='150px'
                    size='small'
                    labelKey='unit'
                    name='cargos[0].reported_weight_type'
                    options={weightTypes}
                    value={stop.cargos?.[0]?.reported_weight_type || null}
                    onChange={(e, value) => setFieldValue('cargos[0].reported_weight_type', value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={stop.cargos?.length > 1 || !stop.departure_date}
                  />
                </SMergedInputs>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Bill of Lading */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
            {billOfLadingTitle}
          </Typography>
          <div style={{ marginTop: '10px', width: '400px' }}>
            <Input
              size='small'
              name='bill_of_lading_id'
              value={stop.bill_of_lading_id}
              onChange={(e) => setFieldValue('bill_of_lading_id', e.target.value)}
            />
          </div>
        </div>
      )}

      {/* Seal ID */}
      {editMood && !!isShipmentTable && (
        <div className={classes.fieldWrapperInput}>
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
            Seal ID
          </Typography>
          <div style={{ marginTop: '10px', width: '400px' }}>
            <Input
              size='small'
              name='reported_seal_id'
              value={stop.reported_seal_id}
              onChange={(e) => setFieldValue('reported_seal_id', e.target.value)}
            />
          </div>
        </div>
      )}

      {/* Value */}
      <div className={classes.fieldWrapperInput}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 157, alignSelf: 'flex-start', marginTop: 12 }}>
          Value
        </Typography>
        <div className={classes.fieldErrorWrapper}>
          <div style={{ marginTop: '10px' }}>
            <SMergedInputs>
              <InputWithIcon
                wrapperClassName='merged-input'
                width='250px'
                type={stop.cargos?.length > 1 ? 'text' : 'number'}
                size='small'
                name='cargos[0].value'
                value={stop.cargos?.length > 1 ? formatNumber(totalValues.value) : stop.cargos?.[0]?.value || ''}
                onChange={(e) => onCargoFieldChange('value', e.target.value)}
                // error={touchedErrors[index]?.expected_quantity}
                disabled={stop.cargos?.length > 1}
                onKeyDown={blockNonPositiveNumbers}
              />
              <Autocomplete
                width='150px'
                size='small'
                options={VALUE_TYPES}
                name='cargos[0].value_type'
                value={stop.cargos?.[0]?.value_type}
                onChange={(e, value) => onCargoFieldChange('value_type', value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={stop.cargos?.length > 1}
                // error={touchedErrors[index]?.expected_quantity_type}
              />
            </SMergedInputs>
          </div>
        </div>
      </div>

      {/* Stop Notes */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ whiteSpace: 'nowrap', width: 114 }}>
          Stop Notes
        </Typography>
        <CustomTextArea
          field={{ value: stop.notes_text }}
          styleLabel={{ marginLeft: 36 }}
          name='notes_text'
          styles={{ maxWidth: 400, height: 22 }}
          value={stop.notes_text}
          onChange={(value) => setFieldValue(`notes_text`, value)}
        />
      </div>

      {/* {bill_type} */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.bill_type}
          name='bill_type'
          onChange={(checked) => setFieldValue(`bill_type`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Billable Move
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {dock_high} */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.dock_high}
          name='dock_high'
          onChange={(checked) => setFieldValue(`dock_high`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Dock High
          </Typography>
        </CustomCheckbox>
      </div>

      {/* /!* {stackable} *!/ */}
      {/* <div className={classes.fieldWrapperTextArea}> */}
      {/*  <Typography variant='s2' style={{ width: 157 }} /> */}
      {/*  <CustomCheckbox */}
      {/*    checked={stop.stackable} */}
      {/*    name='stackable' */}
      {/*    onChange={(checked) => setFieldValue(`stackable`, checked)} */}
      {/*  > */}
      {/*    <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}> */}
      {/*      Stackable */}
      {/*    </Typography> */}
      {/*  </CustomCheckbox> */}
      {/* </div> */}

      {/* {liftgate_service} */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.liftgate_service}
          name='liftgate_service'
          onChange={(checked) => setFieldValue(`liftgate_service`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Liftgate Service
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {labor_required} */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.labor_required}
          name='labor_required'
          onChange={(checked) => setFieldValue(`labor_required`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Labor Required (Loading)
          </Typography>
        </CustomCheckbox>
      </div>

      {/* {hazardous_materials} */}
      <div className={classes.fieldWrapperTextArea}>
        <Typography variant='s2' style={{ width: 157 }} />
        <CustomCheckbox
          checked={stop.hazardous_materials}
          name='hazardous_materials'
          onChange={(checked) => setFieldValue(`hazardous_materials`, checked)}
        >
          <Typography variant='s2' style={{ whiteSpace: 'nowrap', marginLeft: 8 }}>
            Hazardous Materials
          </Typography>
        </CustomCheckbox>
      </div>
      {openAddCommodity && (
        <ShipmentCommodity
          open={openAddCommodity}
          onClose={() => setOpenAddCommodity(false)}
          cargo={stop.cargos}
          onSave={(values) => setFieldValue(`cargos`, values)}
          stopId={editMood ? stop.shipment_stop_id : undefined}
          onCreateSuccess={onCreateSuccess}
          onUpdateSuccess={onUpdateSuccess}
          onDeleteSuccess={onDeleteSuccess}
          isRecurring={isRecurring}
          createMode={!editMood}
          equipment={equipment ? { ...(equipment || {}), length } : null}
        />
      )}
    </div>
  );
};

export default PickUp;
