import React, { useEffect, useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Field, Form, Formik } from 'formik';
import { ReactComponent as EyeIcon } from 'assets/icons/eyeS.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as ResetIcon } from 'assets/icons/drivers/reset.svg';
import { getAppUrl } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import ConfirmModal from 'common/ConfirmModal';
import { Typography } from 'components/Typography';
import CustomTextArea from 'components/CustomTextArea';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getAppLatestVersion } from 'Api/User';
import {
  GetCompanyProfile,
  getSettingsApiKey,
  getSecondaryApiKeys,
  resetSettingsApiKey,
  generateSecondaryApiKey,
  resetSecondaryApiKey,
  deleteSecondaryApiKey,
  UpdateCompanyProfile,
} from 'Api/CompanySettings';
import ItemForm from './ItemForm';
import {
  SPrimaryActions,
  SActionsArea,
  SBody,
  SCopy,
  SHeader,
  SKey,
  SReset,
  SShowIcon,
  SWrapper,
  SContentRow,
  SActions,
  SRow,
  SAddMore,
} from './ApiKeys.styles';

const Apikeys = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const url = getAppUrl();
  const showToaster = useShowToaster();
  const [isBeta, setIsBeta] = useState(false);
  const [, setProductionVersion] = useState('');
  const [apiKeyData, setApiKeyData] = useState(null);
  const [secondaryKeys, setSecondaryKeys] = useState(null);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [forms, setForms] = useState([]);

  const getApiKey = async () => {
    try {
      const { data } = await getSettingsApiKey();
      setApiKeyData(data);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
  };

  const getCompanyProfile = async () => {
    try {
      const res = await GetCompanyProfile();
      setIsBeta(!!res.beta);
    } catch (e) {
      // Do nothing
    }
  };

  const getAppVersion = async () => {
    try {
      const { version } = await getAppLatestVersion({ type: 'web' });
      setProductionVersion(version);
    } catch (e) {
      // Do nothing
    }
  };

  const getSecondaryKeys = async () => {
    try {
      const { data } = await getSecondaryApiKeys({ itemsPerPage: 1000 });
      setSecondaryKeys(data);
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
  };

  const onSave = async () => {
    try {
      await UpdateCompanyProfile({ beta: Number(isBeta) });
      if (url === 'https://app.truckindigital.com' && isBeta) {
        window.open('https://app.beta.truckindigital.com', '_self');
      }

      if (url === 'https://app.beta.truckindigital.com' && !isBeta) {
        window.open('https://app.truckindigital.com', '_self');
      }
      showToaster({ type: 'success', message: 'Settings have been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const generateSecondaryKey = async (key_name, id) => {
    setLoadingCreate(true);
    try {
      await generateSecondaryApiKey(key_name);
      await getSecondaryApiKeys({ itemsPerPage: 1000 });
      deleteFormItem(id);
      getSecondaryKeys();
      showToaster({ type: 'success', message: 'API Key has been successfully added!' });
    } catch (error) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
    setLoadingCreate(false);
  };

  const resetApiKey = async () => {
    try {
      const { data } = await resetSettingsApiKey();
      setApiKeyData(data);
      showToaster({ type: 'success', message: 'API Key has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
  };

  const resetSecondaryKey = async (id) => {
    setLoadingReset(true);
    try {
      await resetSecondaryApiKey(id);
      getSecondaryKeys();
      showToaster({ type: 'success', message: 'API Key has been successfully updated!' });
    } catch (e) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
    setLoadingReset(false);
  };

  const deleteKey = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteSecondaryApiKey(id);
      const newKeys = secondaryKeys.filter((item) => item.id !== id);
      setSecondaryKeys(newKeys);
      showToaster({ type: 'success', message: 'API Key has been successfully deleted!' });
      setKeyToDelete(null);
    } catch (error) {
      showToaster({ type: 'error', message: 'Something went wrong!' });
    }
    setLoadingDelete(false);
  };

  const addNewForm = () => {
    setForms((prevState) => [...prevState, { id: Date.now() }]);
  };

  const deleteFormItem = (id) => {
    const newForms = forms.filter((item) => item.id !== id);
    setForms(newForms);
  };

  const handleSubmit = () => {
    if (isConfiguringStep) {
      onSaveAndNext('saveAndNext');
    } else {
      onSave();
    }
  };

  useEffect(() => {
    getApiKey();
    getAppVersion();
    getSecondaryKeys();
    getCompanyProfile();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const initialValue = useMemo(() => {
    const { api_key } = apiKeyData || {};
    return { api_key: api_key || '' };
  }, [apiKeyData]);

  return (
    <>
      <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{ ...initialValue }}>
        {(props) => {
          return (
            <ApiKeysForm
              {...props}
              apiKeyData={apiKeyData}
              secondaryKeys={secondaryKeys}
              loadingDelete={loadingDelete}
              isConfiguringStep={isConfiguringStep}
              onSaveAndNext={onSaveAndNext}
              onCancelPrevPage={onCancelPrevPage}
              resetApiKey={resetApiKey}
              deleteKey={(id) => setKeyToDelete(id)}
              forms={forms}
              deleteFormItem={deleteFormItem}
              loadingCreate={loadingCreate}
              generateSecondaryKey={generateSecondaryKey}
              addNewForm={addNewForm}
              resetSecondaryKey={resetSecondaryKey}
              loadingReset={loadingReset}
              isBeta={isBeta}
              setIsBeta={setIsBeta}
              submitForm={handleSubmit}
            />
          );
        }}
      </Formik>
      {!!keyToDelete && (
        <ConfirmModal
          open={!!keyToDelete}
          title='Warning!'
          width='560px'
          text='By disconnecting this key, any active shipments associated will stop communicating! Are you sure you want to remove this key? '
          btnType='primary'
          btnText='Remove'
          onClose={() => setKeyToDelete(null)}
          onConfirm={() => deleteKey(keyToDelete)}
          disabled={loadingDelete}
        />
      )}
    </>
  );
};

function ApiKeysForm({
  values,
  submitForm,
  isConfiguringStep,
  onSaveAndNext,
  onCancelPrevPage,
  resetApiKey,
  secondaryKeys,
  loadingDelete,
  deleteKey,
  forms,
  deleteFormItem,
  loadingCreate,
  generateSecondaryKey,
  addNewForm,
  resetSecondaryKey,
  loadingReset,
  isBeta,
  setIsBeta,
}) {
  const showToaster = useShowToaster();
  const [isVisible, setIsVisible] = useState(false);

  const hashtag = useMemo(() => {
    const count = (values?.api_key?.length || 0) - 4;
    return count > 0 ? '#'.repeat(count) + (values?.api_key?.slice(-4) || '') : '';
  }, [isVisible, values?.api_key]);

  return (
    <Form>
      <SWrapper>
        <SHeader>
          <Typography variant='h2'>API Key</Typography>
          <SPrimaryActions>
            {isConfiguringStep && (
              <>
                <CustomButton
                  onClick={onCancelPrevPage}
                  type='secondary'
                  title='Back'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
                <CustomButton
                  onClick={() => onSaveAndNext('skip')}
                  type='secondary'
                  title='Skip'
                  styleButton={{ margin: 0 }}
                  styleTitle={{ fontSize: 14, fontWeight: 500 }}
                />
              </>
            )}
            <CustomButton
              onClick={submitForm}
              type='primary'
              title={isConfiguringStep ? 'Save and Next' : 'Save'}
              styleButton={{ margin: 0 }}
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
            />
          </SPrimaryActions>
        </SHeader>
        <SBody>
          <SActionsArea>
            <SShowIcon isVisible={isVisible} onClick={() => setIsVisible((prev) => !prev)}>
              <EyeIcon style={isVisible ? { fill: '#FFFFFF' } : {}} />
            </SShowIcon>
            <SCopy
              onClick={() => {
                navigator.clipboard.writeText(values?.api_key).then(() => {
                  showToaster({ type: 'success', message: 'API Key is successfully copied!' });
                });
              }}
            >
              <CopyIcon />
            </SCopy>
            <SReset onClick={resetApiKey}>
              <ResetIcon />
            </SReset>
          </SActionsArea>
          <SKey>
            <Field
              name='api_key'
              value={isVisible ? values?.api_key : hashtag}
              component={CustomTextArea}
              style={{ width: '100%' }}
              fitResize
            />
          </SKey>
        </SBody>
        <SHeader>
          <Typography variant='h2'>Secondary API Keys</Typography>
        </SHeader>
        <SBody>
          <SRow>
            <div className='settings-header-item'>
              <Typography variant='overLine'>KEY NAME</Typography>
            </div>
            <div className='settings-header-item'>
              <Typography variant='overLine'>API KEY</Typography>
            </div>
            <div className='settings-header-item'>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography variant='overLine'>ACTIONS</Typography>
              </div>
            </div>
          </SRow>
          {secondaryKeys?.map((item) => (
            <SContentRow key={item.api_key}>
              <div className='settings-table-item'>
                <Typography variant='s2'>{item.key_name}</Typography>
              </div>
              <div className='settings-table-item'>
                <Typography variant='s2'>...{item.api_key.slice(-20)}</Typography>
              </div>
              <SActions className='settings-table-item settings-table-actions-wrapper'>
                {loadingDelete || loadingReset ? (
                  <span className='loading-wrapper'>
                    <CircularProgress size={16} />
                  </span>
                ) : (
                  <>
                    <span className='action-wrapper'>
                      <CopyIcon
                        width={11}
                        height={11}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.api_key).then(() => {
                            showToaster({ type: 'success', message: 'API Key is successfully copied!' });
                          });
                        }}
                      />
                    </span>
                    <span className='action-wrapper' onClick={() => resetSecondaryKey(item.id)}>
                      <ResetIcon width={11} height={11} />
                    </span>
                    <span className='action-wrapper' onClick={() => deleteKey(item.id)}>
                      <DeleteIcon width={11} height={11} fill='#D12953' />
                    </span>
                  </>
                )}
              </SActions>
            </SContentRow>
          ))}
          {forms?.map((item) => (
            <ItemForm
              key={item.id}
              onSubmit={(values) => generateSecondaryKey(values, item.id)}
              onDelete={() => deleteFormItem(item.id)}
              loading={loadingCreate}
            />
          ))}
          <SAddMore onClick={addNewForm}>
            <PlusIcon fill='#4F5AED' />
            <Typography variant='s2' style={{ color: '#4F5AED', cursor: 'pointer' }}>
              Add Secondary Key...
            </Typography>
          </SAddMore>
        </SBody>
        <SHeader>
          <Typography variant='h2'>Early Release</Typography>
        </SHeader>
        <SBody>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='early_release_enabled'
              checked={isBeta}
              onChange={(checked) => setIsBeta(checked)}
              smail={false}
              disabled
            />
            <Typography variant='s2'>Enable Early Release</Typography>
          </div>
          <div className='d-flex flex-column gap-2 mt-2 mb-3'>
            <Typography variant='title1'>Join the Early Release Beta Test for Truckin Digital!</Typography>
            <Typography variant='s2'>
              We're excited to offer our valued customers an exclusive opportunity to be at the forefront of innovation
              with our Truckin Digital software. As we strive to refine and enhance your experience, your insights and
              feedback are invaluable. This is your chance to get early access to cutting-edge features and directly
              influence the final product.
            </Typography>
          </div>
          <div className='d-flex flex-column gap-2'>
            <Typography variant='title1'>Why Join the Beta Test?</Typography>
            <div>
              <Typography variant='button2'>Early Access: </Typography>
              <Typography variant='s2'>
                Experience the newest features before they're released to the public.
              </Typography>
            </div>
            <div>
              <Typography variant='button2'>Shape the Future: </Typography>
              <Typography variant='s2'>
                Your feedback will directly influence the development and refinement of Truckin Digital, ensuring it
                meets the real-world needs of professionals like you.
              </Typography>
            </div>
            <div>
              <Typography variant='button2'>Disclaimer: </Typography>
              <Typography variant='s2'>
                It's important to note that, as a beta version, this release is still in the testing phase. This means
                that you may experience some degree of instability, including potential downtime, as we work on
                improvements and address any issues that arise. We strive to minimize disruptions, but we want to be
                upfront about the possibility of occasional interruptions in service.
              </Typography>
            </div>
          </div>
        </SBody>
      </SWrapper>
    </Form>
  );
}

export default Apikeys;
