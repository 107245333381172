import moment from 'moment';
import {
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  EQUIPMENT_ACTION_WAYPOINT,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';

export const getInitialValues = (shipment) => {
  const { bid_amount, note, state, city, driver_available_from, shipment_stops_schedule_dates } = shipment?.bid || {};

  const shipmentStops = shipment_stops_schedule_dates ? JSON.parse(shipment_stops_schedule_dates) : null;

  return {
    contact: null,
    bid_amount: bid_amount || '',
    state: state || null,
    city: city || null,
    address: state && city ? `${city.name} ${state.short_name}` : '',
    note: note || '',
    driver_available_from: driver_available_from ? moment(driver_available_from).toDate() : moment().utc(),
    stops: shipment.planner.shipment_stops?.map((item) => ({
      id: item.id,
      can_handle: shipmentStops && shipmentStops[item.id] ? 0 : 1,
      new_eta:
        shipmentStops && shipmentStops[item.id]
          ? moment(shipmentStops[item.id]).toDate()
          : moment(`${item.scheduled_date} ${item.from}`).toDate(),
    })),
  };
};

export const equipmentAction = (stop) => {
  const { stop_point_type, equipment_action } = stop;

  switch (Number(stop_point_type)) {
    case 1:
      return EQUIPMENT_ACTION.find((item) => Number(item.key) === Number(equipment_action)).label;
    case 2:
      return EQUIPMENT_ACTION_DELIVERY.find((item) => Number(item.key) === Number(equipment_action)).label;
    case 3:
      return EQUIPMENT_ACTION_WAYPOINT.find((item) => Number(item.key) === Number(equipment_action)).label;
    default:
  }
};
