import axios from '../services/axios';

export async function getTopCompany(params) {
  const { data } = await axios.get('customer/payroll/top-company', {
    params,
  });
  return data;
}

export async function getSettlements(params) {
  const { data } = await axios.get('customer/payroll/settlements', {
    params,
  });
  return data;
}

export async function getSettlement(params) {
  const { data } = await axios.get(`customer/payroll/settlements/single`, { params });
  return data;
}

export async function updateSettlement({ id, body }) {
  const { data } = await axios.put(`customer/payroll/settlements/${id}`, body);
  return data;
}

// Deductions
export async function getDeductions({ user_id, user_type }) {
  const { data } = await axios.get(`${user_type}/deductions/${user_id}`);
  return data;
}

export async function createDeductions({ user_type, body }) {
  const { data } = await axios.post(`${user_type}/deductions`, body);
  return data;
}

export async function deleteDeduction({ id, user_type }) {
  const { data } = await axios.delete(`${user_type}/deductions/${id}`);
  return data;
}

// Additions
export async function getAdditions({ user_id, user_type }) {
  const { data } = await axios.get(`${user_type}/additions/${user_id}`);
  return data;
}

export async function createAdditions({ body, user_type }) {
  const { data } = await axios.post(`${user_type}/additions`, body);
  return data;
}

export async function deleteAddition({ id, user_type }) {
  const { data } = await axios.delete(`${user_type}/additions/${id}`);
  return data;
}

// Taxes
export async function getTaxes({ user_id, user_type }) {
  const { data } = await axios.get(`${user_type}/taxes/${user_id}`);
  return data;
}

export async function createTaxes({ body, user_type }) {
  const { data } = await axios.post(`${user_type}/taxes`, body);
  return data;
}

export async function deleteTax({ id, user_type }) {
  const { data } = await axios.delete(`${user_type}/taxes/${id}`);
  return data;
}

// Benefits
export async function getBenefits({ user_id, user_type }) {
  const { data } = await axios.get(`${user_type}/benefits/${user_id}`);
  return data;
}

export async function createBenefits({ body, user_type }) {
  const { data } = await axios.post(`${user_type}/benefits`, body);
  return data;
}

export async function deleteBenefit({ id, user_type }) {
  const { data } = await axios.delete(`${user_type}/benefits/${id}`);
  return data;
}

// Shipments
export async function getShipments({ driver_id, params }) {
  const { data } = await axios.get(`customer/driver/${driver_id}/shipments`, { params });
  return data;
}

export async function updateShipment(body) {
  const { data } = await axios.put(`customer/shipments`, body);
  return data;
}

// Working schedules
export async function getWorkingSchedules({ driver_id }) {
  const { data } = await axios.get(`driver/work-schedules/${driver_id}`);
  return data;
}

export async function processSettlement({ body, id }) {
  const { data } = await axios.post(`customer/payroll/settlements/${id}/process`, body);
  return data;
}

// Apply to settlement
export async function applyToSettlement({ body, id }) {
  const { data } = await axios.post(`customer/payroll/settlements/${id}/apply`, body);
  return data;
}

export async function removeFromSettlement({ body, id }) {
  const { data } = await axios.delete(`customer/payroll/settlements/${id}/remove`, { data: body });
  return data;
}

export async function declineDisputedRow({ body }) {
  const { data } = await axios.post(`customer/payroll/settlements/dispute`, body);
  return data;
}

export async function updateDetention({ body }) {
  const { data } = await axios.post(`customer/payroll/settlements/detention`, body);
  return data;
}

export async function updateOvertime({ body }) {
  const { data } = await axios.post(`customer/payroll/settlements/overtime`, body);
  return data;
}

export async function updatePto({ body }) {
  const { data } = await axios.post(`customer/payroll/settlements/pto`, body);
  return data;
}

export async function updateTransactions({ body }) {
  const { data } = await axios.post(`customer/payroll/settlements/transactions`, body);
  return data;
}

export async function updateOneTimeAdditions({ body }) {
  const { data } = await axios.post(`customer/payroll/settlements/one-time-additions`, body);
  return data;
}

export async function getYearToDateSummary(params) {
  const { data } = await axios.get(`customer/payroll/settlements/year-to-date-summary`, { params });
  return data;
}

export async function getUnreadSettlementsCount(params) {
  const { data } = await axios.get(`customer/payroll/unread-settlement/count`, { params });
  return data;
}

export async function getUserSettlements(params) {
  const { data } = await axios.get(`customer/payroll/settlements-by-userid`, { params });
  return data;
}

export async function getSettlementDetails(id) {
  const { data } = await axios.get(`customer/payroll/settlements/${id}`);
  return data;
}

export async function markSettlementSeen(id) {
  const { data } = await axios.post(`customer/payroll/settlement/${id}/seen`);
  return data;
}

export async function markSettlementPaid(body) {
  const { data } = await axios.post(`customer/payroll/settlements/mark-paid`, body);
  return data;
}

export async function getSettlementPdf(id) {
  const { data } = await axios.get(`customer/payroll/settlements/get-settlement-pdf-link/${id}`);
  return data;
}
