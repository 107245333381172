import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'common/PhoneInput';
import AddressFields from 'common/AddressFields';
import { Typography } from 'components/Typography';
import Input, { InputWithText } from 'common/Input';
import ErrorMessage from 'common/message/errorMessage';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { blockNotNumberChars } from 'utils/helpers';
import OnboardingService from 'services/onboardingService';
import { getInitialValues } from './BrokerInfo.data';
import { validationSchema } from './validationSchema';
import { SDotLoader, SWrapper } from '../Account.styles';

const BrokerInfo = ({ onGoBack }) => {
  const navigate = useNavigate();
  const { plans } = useSelector((state) => state.onboarding);
  const [loading, setLoading] = useState(false);
  const [dotError, setDotError] = useState('');
  const [addressInfo, setAddressInfo] = useState(null);
  const addressRef = useRef(null);
  const skipSelectPlan = !!plans?.[0]?.plan_types?.length && plans?.[0]?.plan_types?.length <= 1;

  const onSubmit = (values) => {
    localStorage.setItem(
      'brokerInfo',
      JSON.stringify({
        ...values,
        phone_number: values.phone_number?.startsWith('+') ? values.phone_number : `+${values.phone_number}`,
      })
    );
    if (skipSelectPlan) {
      localStorage.setItem('selected-plan', JSON.stringify(plans[0]));
      localStorage.setItem('plan-selected', JSON.stringify(plans[0].plan_types[0]));
      navigate('/onboarding/company');
    } else {
      navigate('/onboarding/select-plan');
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit,
  });

  const onDotChange = async (val) => {
    const value = (val || '').trim();
    setDotError('');
    handleChange('dot', value);
    if (value.length !== 7) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await OnboardingService.checkDot(value);

      if (data?.error?.message || data?.data?.message) {
        setDotError(data?.error?.message || data?.data?.message);
      } else {
        const { phone, legalName, phyStreet, phyCountry, phyState, phyCity, phyZipcode } =
          data?.data?.content?.carrier || {};

        if (phyCountry || phyState || phyCity) {
          setAddressInfo({ country: phyCountry, state: phyState, city: phyCity });
        }

        setValues((prevState) => ({
          ...prevState,
          company_name: legalName || values.company_name,
          phone_number: phone ? `+1${phone.replaceAll(/[- +()]/g, '')}` : value.phone,
          address: phyStreet || '',
          zipcode: phyZipcode || values.zipcode,
        }));
      }
    } catch (e) {
      // do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <SWrapper>
      <div className='content-wrapper'>
        <div className='title-wrapper'>
          {loading && (
            <SDotLoader>
              <p className='spinner-text'>
                Validating <br /> DOT...
              </p>
              <div className='spinner' />
            </SDotLoader>
          )}
          <ErrorMessage message={dotError} close={() => setDotError('')} show={!!dotError} />
          <Typography variant='h1' className='text-center'>
            Broker Profile
          </Typography>
          <Typography variant='s3' style={{ color: palette.gray700 }} className='text-center'>
            Please enter your broker company information below.
          </Typography>
        </div>
        <div className='form-wrapper'>
          <InputWithText
            left
            className='w-100'
            text='DOT #'
            name='dot'
            placeholder='DOT Number'
            value={values.dot}
            onChange={(e) => onDotChange(e.target.value)}
            error={touchedErrors.dot}
            bgColor={palette.gray50}
            onKeyDown={blockNotNumberChars}
          />
          <Input
            name='company_name'
            placeholder='Company Name'
            value={values.company_name}
            onChange={handleChange}
            error={touchedErrors.company_name}
          />
          <Input
            type='email'
            name='email'
            placeholder='Email'
            value={values.email}
            onChange={handleChange}
            error={touchedErrors.email}
          />
          <PhoneInput
            name='phone_number'
            placeholder='+1 (555) 555-1234'
            onChange={(val) => handleChange('phone_number', val)}
            value={values.phone_number}
            error={touchedErrors.phone_number}
            onlyCountries={['us', 'ca', 'mx']}
          />
          <AddressFields
            values={values}
            handleChange={handleChange}
            touchedErrors={touchedErrors}
            hideLabel
            defaultAddress={addressInfo}
            ref={addressRef}
          />
          <div className='d-flex flex-column gap-3 mt-3'>
            <CustomButton
              type='primary'
              title='Continue'
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px', margin: 0, width: '100%', justifyContent: 'center' }}
              onClick={handleSubmit}
            />
            <CustomButton
              type='secondar'
              title='Go Back'
              styleTitle={{ fontSize: 14, color: palette.gray900 }}
              styleButton={{ padding: '6px 12px', margin: 0, width: '100%', justifyContent: 'center' }}
              onClick={onGoBack}
            />
          </div>
        </div>
      </div>
    </SWrapper>
  );
};

export default BrokerInfo;
