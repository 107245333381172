import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SUnderlineText = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  color: #4f5aed;

  :hover {
    text-decoration: underline;
  }
`;

export const SSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  padding: 8px 80px 8px 60px;
  border-right: 1px solid ${palette.gray50};

  .terms {
    a {
      color: ${palette.indigo500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;
