import axios from 'axios';
import authHeader from '../services/auth-header';
import axiosInstance from '../services/axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function Document({
  page,
  itemsPerPage,
  query,
  document_type_id,
  from,
  to,
  dockTypes,
  sortField,
  sortBy,
}) {
  const params = {
    page,
    itemsPerPage,
    query,
    status_id: document_type_id,
    from_date: from,
    to_date: to,
    document_type_id: dockTypes,
    [sortField]: sortBy,
  };
  const { data } = await axios.get(`${API_URL}/document`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function storage() {
  const { data } = await axios.get(`${API_URL}/customer/storage`, {
    headers: authHeader(),
  });
  return data;
}

export async function changLog(document_id) {
  const { data } = await axios.get(`${API_URL}/document/change-log`, {
    headers: authHeader(),
    params: {
      page: 1,
      'sort[][id]': 'desc',
      document_id,
    },
  });
  return data;
}

export async function documentStatuses({ query, from, to, dockTypes }) {
  const { data } = await axios.get(`${API_URL}/document/statuses`, {
    headers: authHeader(),
    params: {
      query,
      from_date: from,
      to_date: to,
      document_type_id: dockTypes,
    },
  });
  return data;
}

export async function documentStatusesCount(id) {
  const { data } = await axios.get(`${API_URL}/document/status-count/${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function documentType(params) {
  const { data } = await axios.get(`${API_URL}/document/document_type`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function addDocumentType(postData) {
  const url = `${API_URL}/document/document_type`;
  const { data } = await axios.post(url, postData, { headers: { ...authHeader() } });
  return data;
}

export async function deleteDocumentType(id) {
  const url = `${API_URL}/document/document_type/${id}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteDocument(id) {
  const url = `${API_URL}/document/delete`;
  const { data } = await axios.delete(url, {
    data: {
      document_id: [id],
    },
    headers: authHeader(),
  });
  return data;
}

export async function shipmentIds() {
  const { data } = await axios.get(`${API_URL}/customer/shipments-list`, {
    headers: authHeader(),
  });
  return data;
}

export async function headerDocuments() {
  const { data } = await axios.get(`${API_URL}/document/header`, {
    headers: authHeader(),
  });
  return data;
}

export async function addDocument(values) {
  const user = JSON.parse(localStorage.getItem('user'));

  const data = new FormData();
  data.append('document_type_id', values.document_type);
  data.append('shipment_id', values.shipment_id);
  if (values.shipment_stop_id) {
    data.append('shipment_stop_id', values.shipment_stop_id);
  }
  data.append('reference_id', values.reference_id);
  data.append('sender_type_id', 2);
  data.append('sender_id', user.id);
  data.append('notes', values.notes);
  data.append('upload_files[]', values.uploadDocument);

  const config = {
    method: 'post',
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
    url: `${API_URL}/document/add`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export async function documentByCustomerID(id) {
  const { data } = await axios.get(`${API_URL}/document/customer/${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function documentByReferenceID(id) {
  const { data } = await axios.get(`${API_URL}/document/${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function updateDocumentStatus(status, id, reason) {
  const { data } = await axios.put(
    `${API_URL}/document/status/${status}`,
    {
      document_id: [id],
      reason: [reason],
    },
    {
      headers: authHeader(),
    }
  );
  return data;
}

export async function updateDocument(id, values) {
  const { data } = await axios.post(
    `${API_URL}/document/${id}`,
    {
      document_type_id: values.document_type,
      shipment_id: values.shipment_id,
      shipment_stop_id: values.shipment_stop?.id || undefined,
      reference_id: values.reference_id,
    },
    {
      headers: authHeader(),
    }
  );
  return data;
}

export async function sendDocumentViaEmail(postData) {
  const url = `${API_URL}/document/send`;
  const { data } = await axios.post(url, postData, { headers: { ...authHeader() } });
  return data;
}

export async function sendDocumentViaFax(postData) {
  const url = `${API_URL}/document/share`;
  const { data } = await axios.post(url, postData, { headers: { ...authHeader() } });
  return data;
}

export async function updateDocumentType(body) {
  const { data } = await axiosInstance.post(`/document/document_types_available_update`, body);
  return data;
}
