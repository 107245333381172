import React from 'react';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';

export const useColumns = () => {
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'event_description',
      title: <ColumnHeader title='EVENT DESCRIPTION' field='event_description' />,
      render: (row) => <Typography variant='s3'>{row?.description || '-'}</Typography>,
    },
    {
      field: 'date',
      title: <ColumnHeader title='DATE' field='date' />,
      render: (row) => <Typography variant='s3'>{convertToCustomerTime(row.date)}</Typography>,
    },
    {
      field: 'action_by',
      title: <ColumnHeader title='ACTION BY' field='action_by' />,
      render: (row) =>
        row.action_by ? (
          <UserInfo
            size='32px'
            statusSize='7px'
            data={{
              ...(row.action_by || {}),
              name:
                row.action_by_type === 'App\\User'
                  ? `${row.action_by?.first_name} ${row.action_by?.last_name}`
                  : `${row.action_by?.fname} ${row.action_by?.lname}`,
              image: row.action_by.image || row.action_by.profile_logo,
              user_type: row.action_by_type === 'App\\User' ? 'staff' : 'driver',
            }}
          />
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            -
          </Typography>
        ),
    },
  ];
};
