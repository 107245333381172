import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ManageType } from 'Api/Equipment';
import authHeader from 'services/auth-header';

import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { Icons } from 'assets/icons';
import { getVendorsList } from 'Api/Vendors';
import { InputWithIcon } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import AddVendor from 'pages/Accounting/Vendors/components/AddVendor';
import EditMaintenanceRecordViewDocument from './EditMaintenanceRecordViewDocument';

import ManageTypes from './ManageTypes/ManageTypes';
import style from './Maintanance.module.css';

const AddRecordModal = ({ handleClose, profileData, MaintenanceRecord, openToast, data, id }) => {
  const [manageTypes, setManageTypes] = useState([]);
  const [manageTypeSuccess, setManageTypeSuccess] = useState();
  const [removeDoc, setRemoveDoc] = useState(false);
  const [fileName, setFileName] = useState();
  const [openmanagetypes, setManageTypesOpen] = useState(false);
  const handleManageTypesOpen = () => setManageTypesOpen(true);
  const handleManageTypesClose = () => setManageTypesOpen(false);
  const [receiptValue, setReceiptValue] = useState();
  const [link, setLink] = useState();
  const [openAddVendor, setOpenAddVendor] = useState(false);
  const [vendors, setVendors] = useState([]);
  const { convertToCustomerTime } = useDateFormat();
  const [startDate, setStartDate] = useState(new Date(convertToCustomerTime()));

  const { use } = useTheme();
  const getManageType = (data) => {
    setManageTypeSuccess(data);
  };

  const API_URL = process.env.REACT_APP_API_URL;

  async function MaintenanceRecordManageType() {
    ManageType().then((res) => {
      if (res) {
        setManageTypes(res?.data);
      } else {
        setManageTypes([]);
      }
    });
  }

  const getVendors = async () => {
    try {
      const { data } = await getVendorsList();
      setVendors(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    MaintenanceRecordManageType();
  }, [manageTypeSuccess]);

  const SelectManageType = manageTypes.map((t) => ({
    label: `${t.type}`,
    value: t.id,
  }));

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      height: 40,
      minHeight: 32,
      minWidth: 250,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
    }),
  };

  const ValidationSchema = Yup.object().shape({
    record_type_id: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    // service_date_time: Yup.string()
    // .required('Required'),
    record_serviced_at_id: Yup.object({
      name: Yup.string(),
    })
      .nullable()
      .required('Required')
      .test('has-label', 'Serviced At is required', (obj) => !obj || (!!obj && !!obj.id)),
    cost: Yup.string().trim().required('Required'),
    mechanic_notes: Yup.string().when('mark_complete', {
      is: 1,
      then: Yup.string().trim().required('Required').nullable(),
      otherwise: Yup.string().trim().nullable(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      record_type_id: data?.record_type?.id,
      description: data?.description,
      service_date_time: data?.service_date_time,
      record_serviced_at_id: data?.service_at,
      cost: data?.cost,
      mark_complete: data?.mark_complete,
      // repair_receipt_attach:data?.repair_receipt_attach,
      mechanic_notes: data?.mechanic_notes || '',
      equipment_id: profileData?.id,
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      try {
        if (values?.mark_complete === 1) {
          values = { ...values, status_id: 1 };
        }
        const formData = new FormData();
        if (receiptValue?.repair_receipt_attach) {
          formData.append('repair_receipt_attach', receiptValue.repair_receipt_attach);
        }
        formData.append('equipment_id', values.equipment_id);

        if (values?.repair_receipt_attach) {
          values = { ...values, repair_receipt_attach: '' };
        }

        axios
          .put(
            `${API_URL}/equipment/maintenance-record/${id}`,
            { ...values, record_serviced_at_id: values.record_serviced_at_id.id },
            { headers: authHeader() }
          )
          .then(() => {
            handleClose();
            MaintenanceRecord();
            openToast('Record updated successfully');
          });
      } catch (e) {
        // Do nothing
      }
    },
  });

  // TODO: Understand the purpose of this useEffect
  // useEffect(() => {
  //   fetch(data).then((response) => {
  //     response.blob().then((blob) => {
  //       // eslint-disable-next-line no-new
  //       new File(blob, data?.repair_receipt_attach, {
  //         type: blob.type,
  //
  //         lastModified: new Date().getTime(),
  //       });
  //     });
  //   });
  // }, []);

  useEffect(() => {
    getVendors();
  }, []);

  const [openprevieweditrecord, setOpenPreviewEditRecord] = useState(false);

  const handleOpenPreviewEditRecord = (link) => {
    setOpenPreviewEditRecord(true);
    setLink(link);
  };
  const handleClosePreviewEditRecord = () => setOpenPreviewEditRecord(false);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <p className={style.recordHeader}>Update Record</p>
        </div>
        <div className={style.recordBody}>
          <div className='d-flex justify-content-between mb-2'>
            <span className={style.recordFormHeader}>
              Type <span style={{ color: 'blue' }}>*</span>
            </span>
            <span className={style.manageTypes} onClick={handleManageTypesOpen}>
              Manage Types
            </span>
          </div>
          <div className={style.recordTypeSelect}>
            <Select
              options={SelectManageType}
              name='record_type_id'
              defaultValue={{ label: data?.record_type?.type, value: data?.record_type?.id }}
              onChange={(e) => formik.setFieldValue('record_type_id', e?.value)}
              styles={customStyles}
              onBlur={formik.handleBlur}
            />
            {formik.errors.record_type_id && formik.touched.record_type_id ? (
              <div
                className={style['add-vendor-modal-content']}
                style={{ color: '#D12953', fontSize: '12px', marginTop: '12px' }}
              >
                {formik.errors.record_type_id}{' '}
              </div>
            ) : null}
          </div>
          <div className='mt-4'>
            <span className={style.recordFormHeader}>
              Description <span style={{ color: 'blue' }}>*</span>
            </span>
            <div className='mt-1'>
              <textarea
                className={style.recordFormDescription}
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows='3'
              />
              {formik.errors.description && formik.touched.description ? (
                <div className={style['add-vendor-modal-content']} style={{ color: '#D12953', fontSize: '12px' }}>
                  {formik.errors.description}{' '}
                </div>
              ) : null}
            </div>
          </div>

          <div className='mt-4 mb-2'>
            <div className='d-flex justify-content-between mb-2'>
              <span className={style.recordFormHeader}>
                Service Date + Time <span style={{ color: 'blue' }}>*</span>
              </span>
            </div>
            <div className={style['add-record-DatePicker']}>
              <img src={Icons.calendar} alt='' />
              <DatePicker
                className={style['add-record-DatePicker-content']}
                selected={startDate}
                showTimeInput
                shouldCloseOnSelect={false}
                placeholderText='Select Date'
                value={formik.values.service_date_time}
                name='service_date_time'
                onChange={(date) => {
                  setStartDate(date);
                  formik.setFieldValue('service_date_time', moment(date).format('YYYY-MM-DD HH:mm:ss'));
                }}
                dateFormat='MM/dd/yyyy h:mm aa'
                timeInputLabel='Time:'
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.errors.service_date_time && formik.touched.service_date_time ? (
              <div
                className={style['add-vendor-modal-content']}
                style={{ color: '#D12953', fontSize: '12px', marginTop: '6px' }}
              >
                {formik.errors.service_date_time}{' '}
              </div>
            ) : null}
          </div>
          <div>
            <div className='mt-4 mb-2'>
              <div className='d-flex justify-content-end'>
                <span className={style.manageTypes} onClick={() => setOpenAddVendor(true)}>
                  <PlusIcon style={{ marginRight: '8px' }} />
                  Add Vendor
                </span>
              </div>
              <div className={style.recordTypeSelect}>
                <Autocomplete
                  required
                  name='record_serviced_at_id'
                  label='Serviced At'
                  value={formik?.values?.record_serviced_at_id}
                  onChange={(e, value) => formik.setFieldValue('record_serviced_at_id', value)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={vendors}
                  placeholder='Select Serviced At..'
                />
                {formik.errors.record_serviced_at_id && formik.touched.record_serviced_at_id ? (
                  <div
                    className={style['add-vendor-modal-content']}
                    style={{ color: '#D12953', fontSize: '12px', marginTop: '12px' }}
                  >
                    {formik.errors.record_serviced_at_id}{' '}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='mt-4'>
              {!!formik.values.record_serviced_at_id && (
                <div className={style.venderAddress}>
                  <div className='ms-2 d-flex justify-content-between'>
                    <div>
                      <span className={style.recordFormHeader} style={{ textTransform: 'Capitalize' }}>
                        {formik.values.record_serviced_at_id.name}
                      </span>
                      <br />
                      <span className={style.recordFormHeader} style={{ textTransform: 'Capitalize' }}>
                        {formik.values.record_serviced_at_id.address}
                        <br />
                        {formik.values.record_serviced_at_id?.city?.name},
                        <span className='ms-1'>{formik.values.record_serviced_at_id?.state?.name}</span>{' '}
                        {formik.values.record_serviced_at_id?.zipcode}
                      </span>
                    </div>
                    <div>
                      <span>
                        <img
                          src={Icons.CrossRedIcon}
                          alt=''
                          style={{ marginRight: '10px' }}
                          onClick={() => formik.setFieldValue('record_serviced_at_id', null)}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mt-4 ' style={{ paddingBottom: '12px' }}>
            <InputWithIcon
              required
              type='number'
              label='Cost'
              name='cost'
              value={formik.values.cost}
              onChange={(e) => formik.setFieldValue('cost', e.target.value)}
              onKeyDown={(e) => blockNonPositiveNumbers(e)}
            />
            <ErrorMessage error={formik.touched?.cost && formik.errors?.cost} />
          </div>
          {!data?.repair_receipt_attach || removeDoc === true ? (
            <div className='mt-4' htmlFor='file'>
              <div className='mb-2'>
                {' '}
                <span className={style.recordFormHeader}>Upload Repair Receipt</span>
              </div>
              <div className='mt-3'>
                <div className='mt-1 '>
                  <label className={style.recordFormRepairReceipt}>
                    <p type='text'>
                      <input
                        name='repair_receipt_attach'
                        onChange={(e) => {
                          setFileName(e.currentTarget.files[0].name);
                          setReceiptValue({ repair_receipt_attach: e.currentTarget.files[0] });
                        }}
                        type='file'
                        accept='application/pdf'
                        style={{ display: 'none' }}
                      />
                      {fileName ? (
                        <div style={{ paddingTop: '20px' }}>
                          <img src={Icons?.Doc} alt='' style={{ width: '20px', height: '20px' }} />
                          <p className={style.recordFormHeader}>{fileName}</p>
                        </div>
                      ) : (
                        <>
                          <img src={uploadLicense} alt='' />
                          <p>
                            <span className={style.manageTypes}>Upload document </span>
                            <span className={style.recordFormHeader}> or drag and drop</span>
                          </p>
                        </>
                      )}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <span>
              {' '}
              <img src={Icons?.attachfile} className='me-2' alt='' />
              <span
                onClick={() => handleOpenPreviewEditRecord(data?.repair_receipt_attach)}
                className={style.recordFormHeader}
                style={{ color: '#4F5AED', cursor: 'pointer' }}
              >
                Repair Receipt
              </span>{' '}
              <img
                src={Icons.CrossRedIcon}
                className='ms-3'
                alt=''
                onClick={() => setRemoveDoc(true)}
                style={{ height: '10px', width: '10px' }}
              />
            </span>
          )}

          <div className='mt-3'>
            <div className='mb-2 d-flex justify-content-between align-items-center'>
              <span className={style.recordFormHeader}>
                Mechanic Notes {!!formik.values.mark_complete && <span style={{ color: 'blue' }}>*</span>}
              </span>
              <span className='d-flex justify-content-between align-items-center'>
                <input
                  type='checkbox'
                  defaultChecked={formik?.values?.mark_complete === 1}
                  onChange={(e) => {
                    formik.setFieldValue('mark_complete', e.target.checked ? 1 : 0);
                  }}
                  className='me-2'
                />
                <label className={style.recordFormHeader} style={{ margin: '-4px' }}>
                  {' '}
                  Mark Completed
                </label>
              </span>
            </div>
            <div className='mt-1'>
              <textarea
                className={style.recordFormDescription}
                name='mechanic_notes'
                value={formik.values.mechanic_notes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows='3'
              />
              <ErrorMessage error={formik.touched?.mechanic_notes && formik.errors?.mechanic_notes} />
            </div>
          </div>
        </div>
        <div className={style.recordFormFooter}>
          <button className={style.recordFormCancelButton} onClick={handleClose}>
            Cancel
          </button>
          <button type='submit' className={style.recordFormAddRecordButton}>
            Update Record
          </button>
        </div>
        <Modal
          open={openmanagetypes}
          onClose={handleManageTypesClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <ManageTypes
            handleManageTypesClose={handleManageTypesClose}
            manageTypes={manageTypes}
            getManageType={getManageType}
          />
        </Modal>
        <Modal
          open={openprevieweditrecord}
          onClose={handleClosePreviewEditRecord}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <EditMaintenanceRecordViewDocument handleClosePreviewEditRecord={handleClosePreviewEditRecord} link={link} />
        </Modal>
      </div>
      {openAddVendor && (
        <AddVendor open={openAddVendor} onClose={() => setOpenAddVendor(false)} onSuccess={getVendors} />
      )}
    </form>
  );
};

export default AddRecordModal;
