import React, { useMemo, useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import { compensationTypes } from 'pages/Payroll/Settlement/helpers';
import { palette } from 'utils/constants';
import { blockNonPositiveNumbers, formatAmount } from 'utils/helpers';
import { SRow, SContentRow } from './DriverPay.styles';

const CostModal = ({ open, onClose, shipment, onApply }) => {
  const [driverPay, setDriverPay] = useState(
    shipment.driver_pay?.length ? shipment.driver_pay.filter((el) => compensationTypes.includes(el.type)) : []
  );

  const totalCompensation = useMemo(() => {
    const compensationRows = driverPay.filter((el) => compensationTypes.includes(el.type));
    return compensationRows.reduce((acc, cur) => acc + cur.total, 0);
  }, [driverPay]);

  const onRateChange = (id, value) => {
    const newDriverPay = driverPay.map((item) => {
      if (item.id === id) {
        return { ...item, rate: Number(value), total: Number(value) * item.quantity };
      }
      return item;
    });
    setDriverPay(newDriverPay);
  };

  const onQuantityChange = (id, value) => {
    const newDriverPay = driverPay.map((item) => {
      if (item.id === id) {
        return { ...item, quantity: Number(value), total: Number(value) * item.rate };
      }
      return item;
    });
    setDriverPay(newDriverPay);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Driver Pay'
      className='modified-scrollbar'
      $bgColor={palette.gray0}
      $maxWidth='950px'
      $minWidth='950px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel', onClick: onClose },
        {
          key: 'submit',
          type: 'primary',
          title: 'Apply',
          onClick: () => onApply(shipment.shipment_id, driverPay, totalCompensation),
        },
      ]}
    >
      <SRow>
        <div className='header-item'>
          <Typography variant='overLine' style={{ color: palette.gray700 }}>
            DESCRIPTION
          </Typography>
        </div>
        <div className='header-item'>
          <div className='d-flex justify-content-end'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              QTY
            </Typography>
          </div>
        </div>
        <div className='header-item'>
          <div className='d-flex justify-content-end'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              RATE
            </Typography>
          </div>
        </div>
        <div className='header-item'>
          <div className='d-flex'>
            <Typography variant='overLine' style={{ color: palette.gray700 }}>
              TOTAL
            </Typography>
          </div>
        </div>
      </SRow>
      {driverPay.map((item) => (
        <SContentRow key={item.id}>
          <div className='flex-item'>
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {item.description}
            </Typography>
          </div>
          <div className='flex-item'>
            {compensationTypes.includes(item.type) ? (
              <Input
                type='number'
                name='quantity'
                onChange={(e) => onQuantityChange(item.id, e.target.value)}
                value={item.quantity}
                onKeyDown={blockNonPositiveNumbers}
              />
            ) : (
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {formatAmount(item.quantity)}
              </Typography>
            )}
          </div>
          <div className='flex-item'>
            {compensationTypes.includes(item.type) ? (
              <Input
                type='number'
                name='rate'
                onChange={(e) => onRateChange(item.id, e.target.value)}
                value={item.rate}
                onKeyDown={blockNonPositiveNumbers}
              />
            ) : (
              <Typography variant='s2' style={{ color: palette.gray900 }}>
                {formatAmount(item.rate)}
              </Typography>
            )}
          </div>
          <div className='flex-item'>
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {formatAmount(item.total || 0)}
            </Typography>
          </div>
        </SContentRow>
      ))}
      <div className='d-flex flex-column gap-2 align-items-end mt-4 me-3'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Total {formatAmount(totalCompensation)}
        </Typography>
      </div>
    </Modal>
  );
};

export default CostModal;
