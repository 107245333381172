import React, { useState } from 'react';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { deleteTab } from 'Api/Driver';

const ModalFooter = ({ onClose, handleSubmit, tab, values, handleChange, loading, onDeleteSuccess }) => {
  const { value } = useAuth();
  const showToaster = useShowToaster();
  const isManagement = value?.user?.department?.department_name === 'Management';
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteCustomTab = async () => {
    try {
      setLoadingDelete(true);
      await deleteTab({ tab: tab.id });
      showToaster({ type: 'success', message: 'Tab has been successfully removed!' });
      onClose();
      onDeleteSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <div className='w-100 d-flex justify-content-between pt-3 pb-3'>
      <div>
        {tab ? (
          <CustomButton
            styleTitle={{ fontSize: 14 }}
            styleButton={{ padding: '6px 12px', margin: 0 }}
            type='danger'
            title='Remove'
            onClick={deleteCustomTab}
            disabled={loadingDelete || loading}
          />
        ) : (
          isManagement && (
            <CustomCheckbox
              name='forAll'
              checked={values.forAll}
              onChange={(checked) => handleChange('forAll', checked)}
            >
              <Typography variant='s2' style={{ color: palette.gray700, marginLeft: 5, whiteSpace: 'nowrap' }}>
                Apply to all users
              </Typography>
            </CustomCheckbox>
          )
        )}
      </div>
      <div className='d-flex align-items-center gap-3'>
        <CustomButton
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '6px 12px', margin: 0 }}
          type='secondary'
          title='Cancel'
          onClick={onClose}
          disabled={loadingDelete || loading}
        />
        <CustomButton
          styleTitle={{ fontSize: 14 }}
          styleButton={{ padding: '6px 12px', margin: 0 }}
          type='primary'
          title={tab ? 'Update Tab' : 'Add Tab'}
          onClick={handleSubmit}
          disabled={loadingDelete || loading}
        />
      </div>
    </div>
  );
};

export default ModalFooter;
