import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';

import cardsImg from 'assets/images/onboarding/image 18.png';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/closeRed.svg';

import Input from 'common/Input';
import CountInput from 'common/CountInput';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useShowToaster from 'hooks/useShowToaster';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { purchaseAdditionalService, checkAddonPrices, getAssignedLicenses, getAddonLicenses } from 'Api/Billing';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';

import useDateFormat from 'hooks/useDateFormat';
import Radio from 'common/Radio';
import InputLabel from 'common/InputLabel';
import SpinnerLoadingMultipleTexts from 'components/SpinnerLoadingMultipleTexts/SpinerLoadingTwoTexts';
import { purchaseAddonConverter } from './converters';
import { getValidationSchema, getInitialValues } from './data';
import styles from './PurchaseAddOnsModalFinal.module.css';

const addonTypeMapper = {
  1: 'driver',
  2: 'staff',
  3: 'vehicle',
};

const PurchaseAddOnsModalFinal = ({ show, onClose, data, onSuccess = () => null }) => {
  const showToaster = useShowToaster();
  const { paymentMethod, additionalServicesList } = useSelector((state) => state.billing);
  const [prices, setPrices] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assignTo, setAssignTo] = useState([]);
  const [decreaseError, setDecreaseError] = useState(false);
  const { value, setAuth } = useAuth();

  const currentAddon = useMemo(() => {
    return additionalServicesList?.data?.find((item) => item?.addon_plan_id === data?.activePlan?.id);
  }, [additionalServicesList, data]);

  const getAssignedAddonLicenses = async (licences) => {
    try {
      const { data } = await getAssignedLicenses();
      const assignedItems = data.map((item) => Number(item?.assigned_to));
      setAssignTo(licences.filter((item) => assignedItems.includes(item.id)));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const getAddonLicense = async () => {
    try {
      const params = { itemsPerPage: 100000, tab: addonTypeMapper[data?.addon_plan?.addon?.bill_per] };
      const response = await getAddonLicenses(params);
      getAssignedAddonLicenses(response.data);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const handleSubmit = async (values) => {
    if (data.assigned_type.id !== 4 && Number(values.license_cnt) < assignTo.length) {
      setDecreaseError(true);
      return;
    }

    setLoading(true);
    try {
      const body = purchaseAddonConverter(values, data, prices, paymentMethod);
      await purchaseAdditionalService(body);
      onSuccess();
      setAuth({ ...value, shouldUpdateAddons: Date.now() }); // To trigger get addons on App.jsx
      showToaster({ type: 'success', message: 'Addon has been added and pending payment verification!' });
    } catch (e) {
      showToaster({ type: 'error', message: 'Payment declined!' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!show) {
      setDecreaseError(false);
    }
  }, [show]);

  useEffect(() => {
    getAddonLicense();
  }, []);

  return (
    <Modal
      centered
      enforceFocus={false}
      show={show}
      onHide={onClose}
      dialogClassName={classNames('dialogClassName', styles.dialogClassName)}
    >
      <Formik
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema(data)}
        initialValues={getInitialValues(data)}
      >
        {(props) => {
          return (
            <PurchaseAddOnsModalFinalForm
              {...props}
              data={data}
              onClose={onClose}
              prices={prices}
              setPrices={setPrices}
              currentAddon={currentAddon}
              decreaseError={decreaseError}
              setDecreaseError={setDecreaseError}
              assignTo={assignTo}
            />
          );
        }}
      </Formik>
      <SpinnerLoadingMultipleTexts
        isVisible={loading}
        fullScreen
        animationDuration={8}
        textStyle={{ fontSize: '28px', fontFamily: 'Inter, sans-serif', fontWeight: 700, color: palette.gray700 }}
        texts={[
          'Updating account license(s)..',
          'Please do not close or refresh this page..',
          'Please check your email for payment invoice, upon payment, your license(s) will be activated..',
        ]}
      />
    </Modal>
  );
};

function PurchaseAddOnsModalFinalForm({
  values,
  onClose,
  data,
  handleSubmit,
  setFieldValue,
  prices,
  setPrices,
  touched,
  errors,
  currentAddon,
  assignTo,
  decreaseError,
  setDecreaseError,
}) {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  // const dispatch = useDispatch();
  const [showApply, setShowApply] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState('');
  const [couponText, setCouponText] = useState('');
  const [discountAmount, setDiscountAmount] = useState(null);
  // const [updatePaymentOpen, setUpdatePaymentOpen] = useState(false);
  const { paymentMethod, billingDetail } = useSelector((state) => state.billing);
  const duration = billingDetail?.customer_plan?.plan?.duration;
  const financingDisabled = Number(prices?.to_be_paid) < 5000;
  const processingFee = Number(prices?.to_be_paid) * 0.0299 + 0.3;

  const checkPrices = async (body) => {
    try {
      const { addon_prices } = await checkAddonPrices(body);
      const paymentInfo = addon_prices.payment_info;
      setPrices(paymentInfo);
      if (paymentInfo.message) {
        setCouponText(paymentInfo.message);
      }
      if (paymentInfo.discount_amount) {
        setDiscountAmount(paymentInfo.discount_amount);
      }
      if (values.coupon_code && !paymentInfo.message) {
        setCouponError('Please enter valid promo code!');
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onCouponApply = () => {
    if (values.coupon_code && !couponError) {
      setFieldValue('coupon_code', '');
      setCoupon('');
      setCouponText('');
      setDiscountAmount(null);
      setShowApply(false);
      return;
    }
    setFieldValue('coupon_code', coupon);
    setCoupon('');
    setCouponError('');
  };

  // const onUpdateMethodSuccess = () => {
  //   dispatch(getPaymentMethod());
  //   // setUpdatePaymentOpen(false);
  // };

  const onPaymentMethodChange = (method) => {
    if (data?.status === 0 || (method === 'financing' && (duration === 'monthly' || financingDisabled))) {
      return;
    }

    // if (method === 'credit-card' && paymentMethod?.payment_method !== 'credit-card') {
    //   showToaster({
    //     type: 'success',
    //     message: 'You need to change your default payment method to credit card to proceed!',
    //   });
    //   setUpdatePaymentOpen(true);
    //   return;
    // }
    //
    // if (method === 'ach' && paymentMethod?.payment_method !== 'ach') {
    //   showToaster({
    //     type: 'success',
    //     message: 'You need to change your default payment method to ACH to proceed!',
    //   });
    //   setUpdatePaymentOpen(true);
    //   return;
    // }
    setFieldValue('paymentMethod', method);
  };

  useEffect(() => {
    if (!data?.activePlan?.addon_id || !Number(values?.license_cnt)) {
      return;
    }

    checkPrices({
      addon_plan_id: data?.activePlan?.id,
      payment_method: !paymentMethod?.payment_method_details?.id ? 'financing' : values?.paymentMethod || 'financing',
      addon_id: data?.activePlan?.addon_id,
      applied_coupon: values?.coupon_code,
      license_cnt: Number(values?.license_cnt),
      // stripe_token: paymentMethod?.payment_method_details?.id || null,
    });
  }, [values, paymentMethod]);

  useEffect(() => {
    setFieldValue(setFieldValue('license_cnt', currentAddon?.license_cnt || 1));
  }, [currentAddon]);

  const prorated =
    Number(data?.activePlan?.monthly_amount) * Number(values.license_cnt) * (planMapper[duration]?.monthsCount || 1) +
    Number(data?.activePlan?.setup_one_time_fee) -
    Number(prices?.to_be_paid);

  return (
    <>
      <Form>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.title}>
              <Typography variant='h5'>Purchase Add-On</Typography>
            </div>
            <div onClick={onClose} className={styles.close}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.body_wrapper}>
            <div className={styles.left}>
              <div className={styles.left_title}>
                <Typography variant='h5'>Payment Type</Typography>
              </div>
              <div className={styles.AddEquipmentPaymentCheckbox} onClick={() => onPaymentMethodChange('financing')}>
                <div className='d-flex container'>
                  <div className='mt-3'>
                    <input
                      type='radio'
                      name='paymentMethod'
                      value='financing'
                      checked={values.paymentMethod === 'financing'}
                      onChange={() => onPaymentMethodChange('financing')}
                      disabled={duration === 'monthly' || financingDisabled || data?.status === 0}
                    />
                  </div>
                  <div className={styles.PaymentCheckboxMainHeading}>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <span>Finance Agreement</span>
                      </div>
                    </div>
                    {duration === 'monthly' && (
                      <div>
                        <span className={styles.PaymentCheckboxSubP}>
                          Financing is not available for monthly plans, only for yearly plans.
                        </span>
                      </div>
                    )}
                    {duration !== 'monthly' && (
                      <div>
                        <span
                          className={styles.PaymentCheckboxSubHeading}
                          style={{ color: financingDisabled ? palette.gray700 : '' }}
                        >
                          ${formatNumber(prices?.to_be_paid)}{' '}
                        </span>
                        <span className={styles.PaymentCheckboxSubP}>{planMapper[duration]?.addonsRecurring}</span>
                        <br />
                        {financingDisabled ? (
                          <span className={styles.PaymentCheckboxSubP}>
                            Transaction must be over $5,000.00 for financing.
                          </span>
                        ) : (
                          <span className={styles.PaymentCheckboxSubP}>
                            If you choose to pay with the financing option, you will be making monthly payments for{' '}
                            {planMapper[duration]?.monthsCount} months + finance charges. Varies on credit approval.
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className={styles.AddEquipmentPaymentCheckbox} onClick={() => onPaymentMethodChange('credit-card')}> */}
              {/*  <div className='d-flex container'> */}
              {/*    <div className='mt-3'> */}
              {/*      <input */}
              {/*        type='radio' */}
              {/*        name='paymentMethod' */}
              {/*        value='credit-card' */}
              {/*        checked={values.paymentMethod === 'credit-card'} */}
              {/*        onChange={() => onPaymentMethodChange('credit-card')} */}
              {/*        disabled={data?.status === 0} */}
              {/*      /> */}
              {/*    </div> */}
              {/*    <div className={styles.PaymentCheckboxMainHeading}> */}
              {/*      <div className='d-flex justify-content-between'> */}
              {/*        <div> */}
              {/*          <span>Credit Card</span> */}
              {/*        </div> */}
              {/*        <div> */}
              {/*          <img src={cardsImg} alt='img' /> */}
              {/*        </div> */}
              {/*      </div> */}
              {/*      {values.paymentMethod === 'credit-card' && paymentMethod?.payment_method === 'credit-card' ? ( */}
              {/*        <div style={{ marginTop: '30px', display: 'flex', gap: '16px' }}> */}
              {/*          <div> */}
              {/*            {creditCard[paymentMethod?.payment_method_details?.card?.brand] || ( */}
              {/*              <Card width={40} height={40} /> */}
              {/*            )} */}
              {/*          </div> */}
              {/*          <div> */}
              {/*            <Typography variant='s2' style={{ color: '#464F60' }}> */}
              {/*              {paymentMethod?.payment_method_details?.billing_details?.name} */}
              {/*            </Typography> */}
              {/*            <div style={{ display: 'flex', columnGap: 18, marginTop: 8 }}> */}
              {/*              <Typography variant='s2' style={{ color: '#171C26' }}> */}
              {/*                **** **** **** {paymentMethod?.payment_method_details?.card?.last4} */}
              {/*              </Typography> */}
              {/*              <Typography variant='s2' style={{ color: '#464F60' }}> */}
              {/*                {paymentMethod?.payment_method_details?.card?.exp_month}/ */}
              {/*                {Number(paymentMethod?.payment_method_details?.card?.exp_year) % 100} */}
              {/*              </Typography> */}
              {/*            </div> */}
              {/*          </div> */}
              {/*        </div> */}
              {/*      ) : ( */}
              {/*        <div> */}
              {/*          <span className={styles.PaymentCheckboxSubHeading}> */}
              {/*            ${formatNumber(Number(prices?.to_be_paid) + processingFee)}{' '} */}
              {/*          </span> */}
              {/*          <span className={styles.PaymentCheckboxSubP}>{planMapper[duration]?.addonsRecurring}</span> */}
              {/*          <br /> */}
              {/*          <span className={styles.PaymentCheckboxSubP}> */}
              {/*            If you choose to pay with a credit card, you are pre-paid for 1 month of service. If paying */}
              {/*            with credit card, 2.9% + $0.30 processing fee applies. */}
              {/*          </span> */}
              {/*        </div> */}
              {/*      )} */}
              {/*    </div> */}
              {/*  </div> */}
              {/* </div> */}
              <div className={styles.AddEquipmentPaymentCheckbox} style={{ marginBottom: '30px' }}>
                <div className='d-flex container'>
                  <div className='mt-3'>
                    <input
                      type='radio'
                      name='paymentType'
                      value='ach'
                      checked={values.paymentMethod === 'ach'}
                      onChange={() => onPaymentMethodChange('ach')}
                      disabled={data?.status === 0}
                    />
                  </div>
                  <div className={styles.PaymentCheckboxMainHeading} onClick={() => onPaymentMethodChange('ach')}>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <span>Credit Card or ACH</span>
                      </div>
                      <img src={cardsImg} alt='img' />
                    </div>
                    {/* {values.paymentMethod === 'ach' && paymentMethod?.payment_method === 'ach' && ( */}
                    {/*  <div style={{ marginTop: '-10px' }}> */}
                    {/*    <div> */}
                    {/*      <Typography variant='button2'> */}
                    {/*        {paymentMethod?.payment_method_details?.us_bank_account?.bank_name} */}
                    {/*      </Typography> */}
                    {/*    </div> */}
                    {/*    <Typography variant='s2' style={{ color: '#464F60' }}> */}
                    {/*      {paymentMethod?.payment_method_details?.billing_details?.name} */}
                    {/*    </Typography> */}
                    {/*    <div style={{ display: 'flex', columnGap: 18, marginTop: 8 }}> */}
                    {/*      <Typography variant='s2' style={{ color: '#171C26' }}> */}
                    {/*        **** **** **** {paymentMethod?.payment_method_details?.us_bank_account?.last4} */}
                    {/*      </Typography> */}
                    {/*      <Typography variant='s2' style={{ color: '#464F60' }}> */}
                    {/*        {`| ${paymentMethod?.payment_method_details?.us_bank_account?.routing_number}`} */}
                    {/*      </Typography> */}
                    {/*    </div> */}
                    {/*  </div> */}
                    {/* )} */}
                    {/* {values.paymentMethod !== 'ach' && ( */}
                    <div>
                      <span className={styles.PaymentCheckboxSubHeading}> ${formatNumber(prices?.to_be_paid)} </span>
                      <span className={styles.PaymentCheckboxSubP}>{planMapper[duration]?.addonsRecurring}</span>
                      <br />
                      <span className={styles.PaymentCheckboxSubP}>
                        If you choose to pay with Credit Card or ACH, you are pre-paid for 1 month of service.
                      </span>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
              {data.add_custom_fields && (
                <div className={styles.custom_fields_wrapper}>
                  {data.add_custom_fields.map((field) =>
                    field.field_type === 'radio' ? (
                      <div className='mb-3'>
                        <InputLabel required>{field.field_name}</InputLabel>
                        <div className='d-flex gap-3'>
                          <Radio
                            name={field.field_name}
                            value='1'
                            checked={values[field.field_name] === 'Yes'}
                            onChange={() => setFieldValue(field.field_name, 'Yes')}
                            label='Yes'
                          />
                          <Radio
                            name={field.field_name}
                            value='1'
                            checked={values[field.field_name] === 'No'}
                            onChange={() => setFieldValue(field.field_name, 'No')}
                            label='No'
                          />
                        </div>
                        <ErrorMessage error={touched[field.field_name] && errors[field.field_name]} />
                      </div>
                    ) : (
                      <Input
                        key={field.name}
                        name={field.field_name}
                        label={field.field_name}
                        value={values[field.field_name]}
                        onChange={(e) => setFieldValue(field.field_name, e.target.value)}
                        error={touched[field.field_name] && errors[field.field_name]}
                        required
                        className='mb-2'
                      />
                    )
                  )}
                </div>
              )}
              {data?.category !== 'Integration (EDI/API)' && (
                <div className={styles.agree_wrapper}>
                  <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                    By clicking complete order, you must also check and agree to the following below:
                  </Typography>
                  <label className={styles.label}>
                    <Field name='term'>
                      {({ field }) => {
                        return (
                          <CustomCheckbox
                            field={field}
                            checked={!!field.value}
                            name='term'
                            withinForm
                            disabled={data?.status === 0}
                          >
                            <Typography
                              variant='s2'
                              style={{
                                color: use(palette.gray700, palette.gray200),
                                marginLeft: 5,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              I agree to the
                              <Typography
                                variant='s2'
                                style={{
                                  color: use(palette.indigo500),
                                  marginLeft: 5,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Terms & Conditions
                              </Typography>
                            </Typography>
                          </CustomCheckbox>
                        );
                      }}
                    </Field>
                  </label>
                  <label className={styles.label}>
                    <Field name='privacy'>
                      {({ field }) => {
                        return (
                          <CustomCheckbox
                            field={field}
                            checked={!!field.value}
                            name='privacy'
                            withinForm
                            disabled={data?.status === 0}
                          >
                            <Typography
                              variant='s2'
                              style={{
                                color: use(palette.gray700, palette.gray200),
                                marginLeft: 5,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              I agree to the
                              <Typography
                                variant='s2'
                                style={{
                                  color: use(palette.indigo500),
                                  marginLeft: 5,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Privacy Policy
                              </Typography>
                            </Typography>
                          </CustomCheckbox>
                        );
                      }}
                    </Field>
                  </label>
                  <label className={styles.label}>
                    <Field name='billing'>
                      {({ field }) => (
                        <CustomCheckbox
                          field={field}
                          checked={!!field.value}
                          name='billing'
                          withinForm
                          disabled={data?.status === 0}
                        >
                          <Typography
                            variant='s2'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              marginLeft: 5,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            I agree to the
                            <Typography
                              variant='s2'
                              style={{
                                color: use(palette.indigo500),
                                marginLeft: 5,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Billing Policy
                            </Typography>
                          </Typography>
                        </CustomCheckbox>
                      )}
                    </Field>
                  </label>
                </div>
              )}
            </div>
            <div className={styles.right}>
              <div className={styles.right_title}>
                <Typography variant='h5'>Order Summary</Typography>
                <span className={styles.sum_item}>1 Item</span>
              </div>
              <div className={styles.rightDoc_wrapper}>
                <div className={styles.item1}>
                  <div className={styles.image_wrapper}>
                    <figure className={styles.figure}>
                      <img className={styles.image} src={data?.image} alt='Img' />
                    </figure>
                  </div>
                  <div className={styles.info1}>
                    <div className={styles.info1_left}>
                      <div className={styles.info1_lt}>
                        <Typography variant='b2' style={{ color: use(palette.gray900, palette.gray200) }}>
                          {data?.name}
                        </Typography>
                        <Typography variant='c1' style={{ color: use(palette.gray500, palette.gray200) }}>
                          Add-On - {data?.activePlan?.plan_title}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                          ${formatNumber((prices?.monthly_payment || 0) * (planMapper[duration]?.monthsCount || 1))}
                        </Typography>
                        <Typography variant='c1'>Per {planMapper[duration]?.per}</Typography>
                      </div>
                    </div>
                    <div style={{ marginLeft: '8px' }}>
                      <Typography
                        variant='c1'
                        style={{ color: use(palette.gray700, palette.gray200), whiteSpace: 'nowrap' }}
                      >
                        {data?.assigned_type?.type} License(s)
                      </Typography>
                      {data?.assigned_type?.id !== 4 ? (
                        <div className='mt-1'>
                          <CountInput
                            value={values.license_cnt}
                            handleChange={(val) => {
                              setDecreaseError(false);
                              setFieldValue('license_cnt', val);
                            }}
                          />
                          {!!decreaseError && (
                            <ErrorMessage
                              error={`You have ${assignTo?.length} assigned licenses, please remove a license to decrease.`}
                            />
                          )}
                        </div>
                      ) : (
                        <div className='text-center mt-2'>
                          <Typography variant='s2'>1</Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.item2}>
                  <div className={styles.wrapper2}>
                    {data?.status !== 0 && (
                      <div className={styles.row}>
                        <Typography variant='b2'>Promo Code</Typography>
                        {showApply ? (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <div style={{ marginRight: 6 }}>
                              <CustomButton
                                type='secondary'
                                title={values.coupon_code && !couponError ? 'Cancel' : 'Apply'}
                                onClick={onCouponApply}
                                styleTitle={{ fontSize: 14 }}
                                styleButton={{
                                  padding: '4px 12px',
                                  margin: 0,
                                  justifyContent: 'center',
                                }}
                              />
                            </div>
                            <div className={styles.label_wrapper}>
                              {(!values.coupon_code || (values.coupon_code && couponError)) && (
                                <Input
                                  type='text'
                                  value={coupon}
                                  placeholder='Promo Code'
                                  onChange={(e) => setCoupon(e.target.value)}
                                  style={{ width: 140, height: 29, margin: 0, padding: '0 6px' }}
                                  error={couponError}
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.apply} onClick={() => setShowApply(true)}>
                            <PlusIcon />
                            <Typography variant='button1' style={{ color: use(palette.indigo500) }}>
                              Apply
                            </Typography>
                          </div>
                        )}
                        {couponText && <Typography variant='b2'>{couponText}</Typography>}
                      </div>
                    )}
                    {discountAmount && (
                      <div className={styles.row}>
                        <Typography variant='b2'>Discount Amount</Typography>
                        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                          ${formatNumber(discountAmount)}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.item2}>
                  <div className={styles.wrapper2}>
                    <div className={styles.row}>
                      <Typography variant='b2'>{planMapper[duration]?.recurring}</Typography>
                      <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                        ${formatNumber((prices?.monthly_payment || 0) * (planMapper[duration]?.monthsCount || 1))}
                      </Typography>
                    </div>
                    {!!data?.activePlan?.setup_one_time_fee && data?.activePlan?.setup_one_time_fee !== '0' && (
                      <div className={styles.row}>
                        <Typography variant='b2'>One Time Setup Fee</Typography>
                        <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                          ${formatNumber(Number(prices?.setup_one_time_fee))}
                        </Typography>
                      </div>
                    )}
                    {values.paymentMethod === 'credit-card' && (
                      <div className={styles.row}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                          <Typography variant='b2'>Processing Fee (2.9% + $0.30)</Typography>
                        </div>
                        <Typography variant='s2'>${formatNumber(processingFee)}</Typography>
                      </div>
                    )}
                    <div className={styles.row}>
                      <Typography variant='b2'>Subtotal</Typography>
                      <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                        $
                        {formatNumber(
                          Number(data?.activePlan?.monthly_amount) *
                            Number(values.license_cnt) *
                            (planMapper[duration]?.monthsCount || 1) +
                            Number(data?.activePlan?.setup_one_time_fee) +
                            (values?.paymentMethod === 'credit-card' ? processingFee : 0)
                        )}
                      </Typography>
                    </div>
                    <div className={styles.row}>
                      <Typography variant='b2'>Prorated</Typography>
                      <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                        {prorated === 0 ? '' : '-'}${formatNumber(prorated)}
                      </Typography>
                    </div>
                    <div className={styles.row}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <Typography variant='s1'>Due Now</Typography>
                      </div>
                      <Typography variant='h5'>
                        $
                        {formatNumber(
                          Number(prices?.to_be_paid) + (values?.paymentMethod === 'credit-card' ? processingFee : 0)
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={styles.item2}>
                  <div className={styles.wrapper2}>
                    <CustomButton
                      type={data?.status === 0 ? 'secondary' : 'primary'}
                      title={
                        data?.status === 0
                          ? 'NOT AVAILABLE'
                          : currentAddon
                          ? `Continue with $${formatNumber(prices?.to_be_paid)}`
                          : 'Purchase Add-On'
                      }
                      disabled={
                        !values?.term ||
                        !values?.privacy ||
                        !values?.billing ||
                        Number(values.license_cnt < 1) ||
                        !values.paymentMethod ||
                        Number(values.license_cnt) === currentAddon?.license_cnt
                      }
                      onClick={handleSubmit}
                      styleTitle={{ fontSize: 14 }}
                      styleButton={{
                        padding: '6px 12px',
                        margin: 0,
                        width: '100%',
                        justifyContent: 'center',
                      }}
                    />
                    <Typography variant='b2'>
                      License will automatically renew on {formatDate(prices?.next_renewal_date)}. Invoice will be
                      emailed to you separately. You will not be charged when you continue and services will not be
                      active until you make payment.
                    </Typography>
                  </div>
                </div>
                <div className={styles.item2}>
                  <div className={styles.wrapper2}>
                    <Typography variant='b2'>All prices are in US Dollars (USD).</Typography>
                  </div>
                </div>
                <div className={styles.item2}>
                  <div className={styles.wrapper3}>
                    <Typography variant='b2'>Cancel Subscription at anytime</Typography>
                  </div>
                </div>
              </div>

              {data?.category === 'Integration (EDI/API)' && (
                <div className={styles.agree_wrapper}>
                  <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200) }}>
                    By clicking complete order, you must also check and agree to the following below:
                  </Typography>
                  <label className={styles.label}>
                    <Field name='term'>
                      {({ field }) => {
                        return (
                          <CustomCheckbox
                            field={field}
                            checked={!!field.value}
                            name='term'
                            withinForm
                            disabled={data?.status === 0}
                          >
                            <Typography
                              variant='s2'
                              style={{
                                color: use(palette.gray700, palette.gray200),
                                marginLeft: 5,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              I agree to the
                              <Typography
                                variant='s2'
                                style={{
                                  color: use(palette.indigo500),
                                  marginLeft: 5,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Terms & Conditions
                              </Typography>
                            </Typography>
                          </CustomCheckbox>
                        );
                      }}
                    </Field>
                  </label>
                  <label className={styles.label}>
                    <Field name='privacy'>
                      {({ field }) => {
                        return (
                          <CustomCheckbox
                            field={field}
                            checked={!!field.value}
                            name='privacy'
                            withinForm
                            disabled={data?.status === 0}
                          >
                            <Typography
                              variant='s2'
                              style={{
                                color: use(palette.gray700, palette.gray200),
                                marginLeft: 5,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              I agree to the
                              <Typography
                                variant='s2'
                                style={{
                                  color: use(palette.indigo500),
                                  marginLeft: 5,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Privacy Policy
                              </Typography>
                            </Typography>
                          </CustomCheckbox>
                        );
                      }}
                    </Field>
                  </label>
                  <label className={styles.label}>
                    <Field name='billing'>
                      {({ field }) => (
                        <CustomCheckbox
                          field={field}
                          checked={!!field.value}
                          name='billing'
                          withinForm
                          disabled={data?.status === 0}
                        >
                          <Typography
                            variant='s2'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              marginLeft: 5,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            I agree to the
                            <Typography
                              variant='s2'
                              style={{
                                color: use(palette.indigo500),
                                marginLeft: 5,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Billing Policy
                            </Typography>
                          </Typography>
                        </CustomCheckbox>
                      )}
                    </Field>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
      {/* <Backdrop open={updatePaymentOpen}> */}
      {/*  <UpdatePaymentMethod */}
      {/*    open={updatePaymentOpen} */}
      {/*    onClose={() => setUpdatePaymentOpen(false)} */}
      {/*    onSuccess={onUpdateMethodSuccess} */}
      {/*    onCreditCardSuccess={() => { */}
      {/*      setFieldValue('paymentMethod', 'credit-card'); */}
      {/*    }} */}
      {/*    onAchSuccess={() => { */}
      {/*      setFieldValue('paymentMethod', 'ach'); */}
      {/*    }} */}
      {/*  /> */}
      {/* </Backdrop> */}
    </>
  );
}

export default PurchaseAddOnsModalFinal;
