import axios from '../services/axios';

export async function getBillData({ type, ids }) {
  const params = {};
  ids.forEach((id, index) => {
    params[`checks[${index}][id]`] = id;
    params[`checks[${index}][type]`] = type;
  });
  const { data } = await axios.get(`checks/get-bill-data`, { params });
  return data;
}

export async function createChecks(params) {
  const { data } = await axios.post(`/checks/create-check`, params);
  return data;
}

export async function getCheckSettings() {
  const { data } = await axios.get(`/checks/get-check-setting`);
  return data;
}
export async function updateCheckSettings(params) {
  const { data } = await axios.post(`/checks/update-check-setting`, params);
  return data;
}
