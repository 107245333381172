import React from 'react';
import { Icons } from 'assets/icons';
import call from 'assets/icons/drivers/call.svg';
import inbox from 'assets/icons/drivers/inbox.svg';
import right from 'assets/icons/drivers/right.svg';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';

export const useColumns = () => {
  return [
    {
      title: <ColumnHeader title='NAME' field='contact_name' />,
      field: 'contact_name',
      render: (row) => <Typography variant='b2'>{row.contact_name}</Typography>,
    },
    {
      title: <ColumnHeader title='EMAIL' field='contact_email' />,
      field: 'contact_email',
      render: (row) => (
        <div className='d-flex'>
          <div className='call-to-action-icons' style={{ width: 'fit-content' }}>
            <a href={`mailto:${row.contact_email}`}>
              <img src={inbox} alt='inbox-icon' />
            </a>
          </div>
          <Typography variant='b2'>{row.contact_email}</Typography>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='PHONE NUMBER' field='phone_number' />,
      field: 'phone_number',
      render: (row) => (
        <div className='d-flex'>
          <div className='call-to-action-icons' style={{ width: 'fit-content' }}>
            <a href={`tel:${row.phone_number}`}>
              <img src={call} alt='call-icon' />
            </a>
          </div>
          <Typography variant='b2'>{row.phone_number}</Typography>
        </div>
      ),
    },
    {
      title: <ColumnHeader title='TYPE' field='contact_type' />,
      field: 'contact_type',
      render: (row) => <Typography variant='b2'>{row.contact_type?.title}</Typography>,
    },
    {
      title: (
        <div className='d-flex justify-content-center'>
          <ColumnHeader title='Receive Offers' field='email_updates' />
        </div>
      ),
      field: 'receive_offers',
      render: (row) => {
        const receive_offers = row.receive_offers;
        return (
          <div className='d-flex justify-content-center'>
            {receive_offers ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
          </div>
        );
      },
    },
    {
      title: (
        <div className='d-flex justify-content-center'>
          <ColumnHeader title='Allow Check Calls' field='sms_updates' />
        </div>
      ),
      field: 'sms_updates',
      render: (row) => {
        const check_calls = row.check_calls;
        return (
          <div className='d-flex justify-content-center'>
            {check_calls ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
          </div>
        );
      },
    },
  ];
};
