import { object, string, array, number } from 'yup';

export const validationSchema = object().shape({
  name: string().trim().required('Please add position name').nullable(),
  job_applicant_type: object().required('Please select applicant type').nullable(),
  selectedStates: array()
    .min(1, 'Please select at least 1 state')
    .of(object().shape({ id: number() })),
  status: number().required('Please select job status').nullable(),
});
