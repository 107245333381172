import React from 'react';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { palette } from 'utils/constants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Filler, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label} - ${item[0].raw} ${item[0].raw === 1 ? 'hour' : 'hours'}`;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      bodyFont: { size: 0 },
      displayColors: false,
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        align: 'inner',
      },
    },
    y: {
      suggestedMax: 5,
      beginAtZero: true,
      grid: {
        borderDash: [8, 8],
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const LineChart = ({ staffGraphData }) => {
  const { data } = staffGraphData;

  const chartData = {
    labels: data?.map((item) => moment(item.date).format('DD MMM')),
    datasets: [
      {
        data: data.map((value) => Number(value?.hours)),
        backgroundColor: palette.indigo400,
        hoverBackgroundColor: palette.indigo500,
        borderRadius: 2,
      },
    ],
  };

  return <Bar data={chartData} options={options} />;
};

export default LineChart;
