import React from 'react';
import XIcon from 'assets/icons/x.png';
import NotesComponent from './NotesComponent';

const AddNotesComp = ({ isNotes, setNotes, chatUser, notesData, getAllNotes }) => {
  return (
    <div
      className='group_members'
      style={{
        width: isNotes ? '25%' : '0px',
        minWidth: isNotes ? '400px' : '0px',
        padding: isNotes ? '' : '0px',
      }}
    >
      <p className='group_member_heading'>
        Note(s)
        <img src={XIcon} alt='close' onClick={() => setNotes(false)} />
      </p>
      <div className='chat-group-notes '>
        <div className='notes-box'>
          {notesData.map((n) => (
            <div className='scheduled_msg' key={n.id}>
              <NotesComponent notes={n} getAllNotes={getAllNotes} chatUser={chatUser} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddNotesComp;
