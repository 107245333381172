import styled from 'styled-components';

export const SImageContainer = styled.div`
  display: flex;
  height: 60vh;
  background-image: ${({ $attachment }) => `url(${$attachment})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 6px;
`;
