export const initialValues = {
  id: Date.now(),
  contact_name: '',
  contact_email: '',
  phone_number: '',
  contact_type_id: null,
  portal_access: false,
  check_calls: false,
  inactive: false,
  isNew: true,
};

export const getContactBookInitialValues = (contacts) => {
  return contacts?.length
    ? contacts?.map((item) => {
        return {
          id: item.id,
          contact_name: item.contact_name,
          contact_email: item.contact_email,
          phone_number: item.phone_number,
          contact_type_id: item.contact_type,
          receive_offers: !!item.receive_offers,
          check_calls: !!item.check_calls,
          inactive: !!item.inactive,
        };
      })
    : [initialValues];
};
