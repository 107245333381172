import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-radius: 8px;
  margin-bottom: 40px;
  min-width: 900px;

  .medical-tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 60px;
    border-bottom: 1px solid ${palette.gray50};
  }

  .medical-tab-body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content-row {
      display: flex;
      border-bottom: 1px solid ${palette.gray50};

      .content-box {
        width: 33%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        border-right: 1px solid ${palette.gray50};
      }

      .content-box:last-child {
        border-right: none;
      }
    }

    .content-row:last-child {
      border-bottom: none;
    }
  }
`;

export const SUnderlineText = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${palette.indigo500};

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SEditWrapper = styled.div`
  margin: 20px 10px;

  .edit-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: ${palette.gray0};
    border: 1px solid ${palette.gray50};
    border-radius: 8px;
    margin: 20px 0;
    padding: 20px;
  }

  .uploader-wrapper {
    width: 200px;
  }
`;

export const SAddMore = styled.span`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: default;

  :hover span {
    text-decoration: underline;
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
`;

export const STable = styled.table`
  width: 100%;

  .header-row {
    border-bottom: 1px solid ${palette.gray50};

    th {
      font-family: 'Inter', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: ${palette.gray700};
      padding: 0 10px 12px;
      white-space: nowrap;
      width: 300px;
      box-sizing: border-box;
    }
  }

  .body-row {
    border-bottom: 1px solid ${palette.gray50};

    &.bg-gray {
      background: ${palette.gray0};
    }

    &.total-row {
      td {
        padding: 10px 10px 22px;
      }
    }

    .loading-wrapper {
      display: inline-block;
      height: 25px;
    }

    .action-wrapper {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
      visibility: hidden;

      :hover {
        background-color: ${palette.indigo50};
      }
    }

    td {
      padding: 12px 10px;
      width: 300px;
      box-sizing: border-box;
    }

    :hover {
      background: ${palette.gray0};

      .action-wrapper {
        visibility: visible;
      }
    }
  }
`;
