import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import ViewPdf from 'components/ViewPdf';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import TableFooter from 'components/TableFooter/TableFooter';
import { Typography } from 'components/Typography';
import { addMinimizedNotes } from 'store/reducers/root.reducer';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper, { MaterialTableSort } from 'components/MaterialTableWrapper';
import useDateFormat from 'hooks/useDateFormat';

import { ReactComponent as MinimizeIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { addNote, deleteNote, getCarrierNotes } from 'Api/Carriers';
import AddNotes from './AddNotes';
import styles from './notes.module.css';

const NotesTab = ({ onSuccess }) => {
  const { theme, use } = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { convertToCustomerTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [notesData, setNotesData] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState('');

  const columns = useMemo(() => {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
    return [
      {
        field: '#',
        width: '5%',
        title: <MaterialTableSort title='#' field='#' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>{Number(rowData?.tableData.id) + 1}</span>
          );
        },
      },
      {
        field: 'title',
        title: <MaterialTableSort title='TITLE' field='title' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.title}</span>;
        },
      },
      {
        field: 'note',
        title: <MaterialTableSort title='NOTE' field='note' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <div className={styles.columns_notes_wrapper}>
              <div className={styles.columns_notes}>
                <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.notes}</span>
              </div>
              <div className={styles.notes_bottom_wrapper}>
                {!!rowData?.department?.department_email && (
                  <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                    <Typography variant='c1'>{rowData?.department?.department_email}</Typography>
                  </div>
                )}
                {!!rowData?.department?.department_name && (
                  <div style={{ backgroundColor: use(palette.gray50, palette.dark700) }}>
                    <Typography variant='c1'>{rowData?.department?.department_name}</Typography>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        field: 'added_by',
        title: <MaterialTableSort title='ADDED BY' field='added_by' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const { first_name, last_name } = rowData.added_by || {};
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {!rowData.added_by ? '-' : `${first_name && first_name} ${last_name && last_name}`}
            </span>
          );
        },
      },
      {
        field: 'date',
        title: <MaterialTableSort title='DATE' field='date' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData?.created_at ? convertToCustomerTime(rowData?.created_at) : '-'}
            </span>
          );
        },
      },
      {
        field: 'attachment',
        title: <MaterialTableSort title='ATTACHMENT' field='attachment' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          return rowData.attachment || rowData.document ? (
            <div
              onClick={() => {
                if (rowData.attachment || rowData.document) {
                  setPdfUrl(rowData.attachment?.document || rowData.document);
                }
              }}
            >
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                View
              </Typography>
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        field: '',
        title: '',
        render: (rowData) => {
          return (
            <span style={{ color: use(palette.dark800, palette.gray200) }}>
              {rowData.show_on_invoice === 1 ? 'INVOICE' : ''}
            </span>
          );
        },
      },
      {
        field: '',
        title: '',
        render: (rowData) => {
          const userId = user?.id ? user?.id : null;
          return (
            <div className={styles.deleteRowWrapper}>
              {userId !== null && userId === rowData?.added_by?.id && (
                <div onClick={(e) => onDeleteRowItem(e, rowData)} className={styles.deleteRow}>
                  <DeleteIcon fill={palette.indigo500} />
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }, [notesData.data, theme, sortData, onDeleteRowItem, use]);

  function onDeleteRowItem(e, rowData) {
    e.stopPropagation();
    deleteNote(rowData.id).then((res) => {
      !!res?.success && getNotes();
      onSuccess();
    });
  }

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    getNotes({ page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
    const lastPage = notesData.last_page;
    if (lastPage !== 1) getNotes({ page, itemsPerPage: rowPerPage });
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={notesData.total}
        lastPage={notesData.last_page}
        totalLength={notesData.data.length}
        currentPage={notesData.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  function sortingQuery(field, sortBy) {
    let newSortedData;
    if (sortData.find((el) => el.type === field)) {
      newSortedData = sortData.filter((el) => el.type !== field);
      newSortedData.unshift({ type: field, sortBy });
      setSortData(newSortedData);
    } else {
      newSortedData = [{ type: field, sortBy }, ...sortData];
      setSortData((p) => [{ type: field, sortBy }, ...p]);
    }
    getNotes({ sort: newSortedData, hasALoading: false });
  }

  /** add notes * */
  const createNote = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => values[key] !== '' && formData.append(key, values[key]));
    return addNote(formData);
  };

  const onAddNote = (form) => {
    const { validateForm, submitForm, values } = form;
    submitForm();
    validateForm().then((res) => {
      const isValid = Object.keys(res).length === 0;
      if (isValid) {
        const dataBack = { ...values, carrier_id: id };
        createNote(dataBack).then((res) => {
          if (res && res?.data) {
            form.resetForm();
            getNotes();
            onSuccess();
          }
        });
      }
    });
  };

  function getNotes(params) {
    const { hasALoading = true, page = 1, sort, itemsPerPage } = params || {};
    if (hasALoading) setLoading(true);
    getCarrierNotes({
      page,
      itemsPerPage: itemsPerPage || rowPerPage,
      sort: sort || sortData,
      id,
    })
      .then((res) => setNotesData(res))
      .finally(() => setLoading(false));
  }

  function onMinimize(e) {
    e.stopPropagation();
    dispatch(addMinimizedNotes({ label: 'Shipment', shipment_id: id, id }));
  }

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <div className={classNames(styles.notes_container, styles.notes_container__nowrap)}>
      <div className={styles.header_wrapper}>
        <div>
          <Typography variant='h4'>Notes</Typography>
          <div onClick={(e) => onMinimize(e)} className={styles.minimize}>
            <MinimizeIcon />
          </div>
        </div>
        <AddNotes onSubmit={onAddNote} styleFormWrapper={{ marginRight: -20 }} />
      </div>
      {loading ? (
        <EquipmentPreloaderSkeleton />
      ) : (
        <MaterialTableWrapper
          data={notesData.data}
          rowPerPage={rowPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='View Document' />}
    </div>
  );
};

export default NotesTab;
