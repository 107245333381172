export const getInitialValues = (data, smsNumbers, notesDepartments) => {
  const { sms_primary, send_sms, send_sms_on_dispatch, send_driver_shipment_notes_email } = data || {};

  return {
    send_sms: !!send_sms || false,
    send_sms_on_dispatch: !!send_sms_on_dispatch || false,
    send_driver_shipment_notes_email: !!send_driver_shipment_notes_email,
    notesDepartments: notesDepartments || [],
    sms_primary: sms_primary ? smsNumbers?.find((i) => i.phone_number === sms_primary) || null : null,
  };
};
