import moment from 'moment/moment';
import { formatNumber, palette } from 'utils/constants';

export const inspectionsOptions = (data) => ({
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 1.5,
  tension: 0.2,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
    },
    tooltip: {
      callbacks: {
        title(item) {
          if (!item[0]) {
            return null;
          }
          return `${item[0].label}`;
        },
        label(context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += `${formatNumber(context.parsed.y, 0)}`;
          }
          return label;
        },
      },
      backgroundColor: '#FFF',
      titleColor: '#000000',
      bodyColor: '#000000',
      boxPadding: 3,
      titleFont: { weight: 500, family: 'Inter, sans-serif', size: 12 },
      yAlign: 'bottom',
      borderWidth: 1,
      borderColor: '#e0e0e0',
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value, index) {
          const { month } = data?.[index] || {};
          return month ? moment(month, 'MMMM YYYY').format('MMM YYYY') : '';
        },
      },
    },
    y: {
      suggestedMax: 10,
      beginAtZero: true,
      grid: {
        borderDash: [8, 8],
        color: palette.gray50,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
});
