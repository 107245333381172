export const getAccountToUpdate = (field, value, transaction) => {
  if (field === 'account') {
    if (!value) {
      return 0;
    }

    return value.id;
  }

  if (field === 'payee' && !!value && (value.account || value.account_id)) {
    return value.account?.id || value.account_id;
  }

  return transaction.account?.id || 0;
};
