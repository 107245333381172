import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { ReactComponent as MinimizeIcon } from 'assets/icons/minus.svg';
import Modal from 'common/Modal';
import Tooltip from 'common/Tooltip';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { addMinimizedNotes } from 'store/reducers/root.reducer';
import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';
import PayeeAutocomplete from 'pages/Accounting/Accounts/components/PayeeAutocomplete';
import { getAccountsList, getPayeeList, updateAccountTransaction } from 'Api/Accounts';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './UpdateTransaction.data';

const UpdateTransaction = ({ open, onClose, onSuccess, account, transaction }) => {
  const dispatch = useDispatch();
  const showToaster = useShowToaster();
  const [payees, setPayees] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addPayeeOpen, setAddPayeeOpen] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        account_id: account.id,
        date: moment(values.date).format('YYYY-MM-DD HH:mm:ss'),
        reference_id: transaction.reference_id,
        payee_id: values.payee?.id || 0,
        payee_type: values.payee?.type || null,
        account: values.account?.id,
        memo: values.memo || null,
        debit: transaction.debit > 0 ? Number(values.amount) : 0,
        credit: transaction.credit > 0 ? Number(values.amount) : 0,
      };

      const { data } = await updateAccountTransaction(transaction.id, body);
      showToaster({ type: 'success', message: 'Transaction has been successfully updated!' });
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleBlur, touchedErrors, handleSubmit, dirty } = useForm({
    initialValues: getInitialValues(transaction),
    validationSchema,
    onSubmit,
  });

  const getPayees = async () => {
    try {
      const { data } = await getPayeeList();
      setPayees(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getAccounts = async () => {
    try {
      const { data } = await getAccountsList();
      setAccounts(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddNewPayeeSuccess = (payee) => {
    handleChange('payee', payee);
  };

  const onPayeeChange = (value) => {
    handleChange('payee', value);
    if (value.account_id) {
      const account = accounts.find((i) => i.id === value.account_id);
      handleChange('account', account || values.account);
    }
  };

  const onMinimize = (e) => {
    e.stopPropagation();
    const styles = { width: 'fit-content', maxWidth: 'unset' };
    dispatch(
      addMinimizedNotes({
        label: 'update-transaction',
        id: Math.random(),
        accountId: account.id,
        transactionId: transaction.id,
        name: `Transaction ****${transaction.reference_id?.slice(-5)}`,
        styles,
      })
    );
  };

  useEffect(() => {
    getPayees();
    getAccounts();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <div className='d-flex justify-content-between'>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            Transaction Record {transaction.reference_id}
          </Typography>
          <Tooltip title='Minimize'>
            <span className='pointer' onClick={onMinimize}>
              <MinimizeIcon fill={palette.gray700} />
            </span>
          </Tooltip>
        </div>
      }
      $minHeight='100px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: 'primary',
          title: `Update`,
          onClick: handleSubmit,
          disabled: loading || !dirty,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex gap-3'>
          <div className='flex-grow-1'>
            <PayeeAutocomplete
              label='Payee'
              size='medium'
              options={payees}
              value={values.payee}
              onChange={(e, val) => onPayeeChange(val)}
              onAddNew={setAddPayeeOpen}
              error={touchedErrors.payee}
              disabled
            />
          </div>
          <div className='flex-grow-1'>
            <Autocomplete
              required
              label='Account'
              name='account'
              placeholder='Select Account'
              labelKey='account_name'
              options={accounts}
              value={values.account}
              onChange={(e, val) => handleChange('account', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.account}
              disabled
            />
          </div>
        </div>
        <div className='d-flex gap-3'>
          <DatePicker
            required
            width='170px'
            label='Date'
            name='date'
            value={values.date}
            onBlur={handleBlur}
            onChange={(val) => handleChange('date', val)}
            disabled
            error={touchedErrors.date}
          />
          <Input
            required
            label='Reference ID'
            name='reference_id'
            placeholder='ABC'
            value={values.reference_id}
            onChange={(e) => handleChange('reference_id', e.target.value)}
            error={touchedErrors.reference_id}
            className='flex-grow-1'
          />
          <Input
            required
            label='Memo'
            name='memo'
            placeholder='Memo'
            value={values.memo}
            onChange={(e) => handleChange('memo', e.target.value)}
            error={touchedErrors.memo}
            className='flex-grow-1'
          />
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <InputWithIcon
            required
            label='Amount'
            width='180px'
            type='number'
            name='amount'
            placeholder='0.00'
            value={values.amount}
            onChange={(e) => handleChange('amount', e.target.value)}
            onKeyDown={blockNonPositiveNumbers}
            error={touchedErrors.amount}
            disabled
          />
        </div>
      </div>
      {!!addPayeeOpen && (
        <AddPayee
          open={!!addPayeeOpen}
          name={addPayeeOpen}
          onClose={() => setAddPayeeOpen(false)}
          onSuccess={onAddNewPayeeSuccess}
        />
      )}
    </Modal>
  );
};

export default UpdateTransaction;
