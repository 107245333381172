import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';

const UpdateReference = ({ open, onClose, onSuccess, shipmentData }) => {
  const [editedShipmentData, setEditedShipmentData] = useState([]);

  useEffect(() => {
    setEditedShipmentData(shipmentData.shipment_billing.map((billing) => ({ ...billing })));
  }, [shipmentData]);

  const handleInputChange = (index, newValue) => {
    const updatedShipmentData = [...editedShipmentData];
    updatedShipmentData[index].reference_id = newValue;
    setEditedShipmentData(updatedShipmentData);
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<>Update Reference</>}
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
        {
          key: 'done',
          type: 'primary',
          title: 'Update',
          onClick: () => onSuccess(editedShipmentData),
        },
      ]}
    >
      <div>
        {editedShipmentData.map((billing, index) => {
          return (
            <div className='mt-2' key={billing.id}>
              <Typography variant='b2'>Reference - {billing.billing_customer?.company_name}</Typography>
              <Input value={billing.reference_id} onChange={(e) => handleInputChange(index, e.target.value)} />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default UpdateReference;
