import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { getAlphabet, palette } from 'utils/constants';
import {
  customerStopPoint,
  getCommodity,
  getEquipmentRequiredTrailers,
  getEquipmentRequiredVehicles,
} from 'Api/Planner';
import { GetSettingsShipments } from 'Api/CompanySettings';
import { getEquipmentDimensions } from 'Api/EquipmentImport';
import { getInitialValue } from 'componentsV2/Commodity/ShipmentCommodity/ShipmentCommodity.data';
import { validationSchema } from 'components/AddEditModalStops/validationSchema';
import Autocomplete from 'common/Autocomplete';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from '../TablePlaner/helpers/RecurrningDetails/steps/DocumentsRecurring/documentsRecurring.module.css';
import CustomButton from '../CustomButton/CustomButton';
import {
  fillStopPointDataOverView,
  getStopTemplateDataOverView,
} from '../CreateShipment/ShipmentStops/helpers/constants';
import PickUp from './types/PickUp';
import Delivery from './types/Delivery';
import Waypoint from './types/WayPoint';
import AddStopPointModal from '../CreateShipment/CreateModals/addStopPointModal';
import CommoditySettingsModal from '../CreateShipment/CreateModals/CommoditySettingsModal';
import classes from './addEditModalStops.module.css';
import { Typography } from '../Typography';
import OverLayTopStops from '../TablePlaner/helpers/RecurrningDetails/helpers/OverLayTopStops';

const mapTypes = {
  1: { id: 1, type: 'pickup', title: 'Pick Up', color: palette.green500 },
  2: { id: 2, type: 'delivery', title: 'Delivery', color: palette.red500 },
  3: { id: 3, type: 'waypoint', title: 'Waypoint', color: palette.blueText },
};

const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === 'object') {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `[name='${fieldErrorNames[0].replace(/.\d+/g, (match) => {
        return `[${match.slice(1)}]`;
      })}']`
    );

    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);

  return null;
};

const AddEditModalDetails = ({
  isOpen,
  setIsOpen,
  type,
  setType,
  onAdd,
  isShipmentTable,
  stops,
  loading,
  isSplit,
  isActiveStopPoint,
  onUpdateSuccess,
  isRecurring,
  shipmentId,
  legs,
}) => {
  const [stopPoints, setStopPoints] = useState([]);
  const [commodity, setCommodity] = useState([]);

  const [equipmentRequired, setEquipmentRequired] = useState([]);
  const [commodityModalIsOpen, setCommodityModalIsOpen] = useState(false);
  const [showAddStopPointModal, setShowAddStopPointModal] = useState(false);
  const [dimensions, setDimensions] = useState([]);
  const [currentStopAddValue, setCurrentStopAddValue] = useState(null);
  const [shipmentSettings, setShipmentSettings] = useState(null);

  const getCustomerStopPoint = (signal) => {
    customerStopPoint(signal, { only_active: 1, for_shipment: shipmentId }).then((res) => {
      if (res && res?.data) {
        const newStopPoints = res?.data.map((el) => {
          return {
            key: el.id,
            label: `${el?.location_name} (${el?.city.name} ${el?.state?.name})`,
            labelSelected: `${el?.location_name} - ${el?.address1} ${el?.city?.name ? el.city?.name : ''}`,
            latitude: el?.geofencing_latitude,
            longitude: el?.geofencing_longitude,
            zipcode: el?.zipcode,
            address1: el?.address1,
            city: el.city,
            state: el?.state,
            location_name: el?.location_name,
            stop_point_working_hour: el.stop_point_working_hour,
            working_hour_by: el?.working_hour_by,
          };
        });
        setStopPoints(newStopPoints);
      }
    });
  };

  const getCustomerStopPointAfterCreate = (signal) => {
    customerStopPoint(signal, { only_active: 1, for_shipment: shipmentId }).then((res) => {
      if (res && res?.data) {
        const newStopPoints = res?.data.map((el) => {
          return {
            key: el.id,
            label: `${el?.location_name} (${el?.city.name} ${el?.state?.name})`,
            labelSelected: `${el?.location_name} - ${el?.address1} ${el?.city?.name ? el.city?.name : ''}`,
            latitude: el?.geofencing_latitude,
            longitude: el?.geofencing_longitude,
            zipcode: el?.zipcode,
            address1: el?.address1,
            city: el.city,
            state: el?.state,
            location_name: el?.location_name,
            stop_point_working_hour: el.stop_point_working_hour,
          };
        });
        setStopPoints(newStopPoints);
        setCurrentStopAddValue(newStopPoints[newStopPoints.length - 1].key);
      }
    });
  };

  const getEquipmentRequired = useCallback(async () => {
    return Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicles, trailers]) => {
        const data = [];

        if (vehicles?.data) {
          const addFlagVehicles = vehicles?.data.map((el) => {
            if ([1, 2, 3, 4, 5, 10].includes(+el.id)) {
              return {
                ...el,
                key: el.id,
                label: el.id === 1 ? 'Truck (Power Only)' : el.title,
                flag: 'vehicles',
                labelSelected: null,
                noLengthField: true,
                type: el?.vehicle_type_id,
              };
            }
            return {
              ...el,
              key: el.id,
              label: el.title,
              flag: 'vehicles',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagVehicles);
        }

        if (trailers?.data) {
          const addFlagTrailers = trailers?.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              flag: 'trailers',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagTrailers);
        }
        data.sort((a, b) => a.key - b.key);

        setEquipmentRequired(data);
        return data;
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const getDimensions = async () => {
    try {
      const { data } = await getEquipmentDimensions();
      setDimensions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getShipmentSettings = async () => {
    try {
      const { data } = await GetSettingsShipments();
      setShipmentSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDimensions();
    getShipmentSettings();
  }, []);

  const getStopComponent = useCallback((t) => {
    const TYPE_STOPS = {
      1: PickUp,
      2: Delivery,
      3: Waypoint,
    };
    return TYPE_STOPS[t];
  }, []);

  const getCommodityAll = useCallback(() => {
    getCommodity().then((res) => {
      if (res && res?.data) {
        const newCommodity = res?.data?.map((commodity) => {
          return {
            ...commodity,
            key: commodity?.id,
            label: commodity?.title,
            labelSelected: null,
          };
        });
        setCommodity(newCommodity);
      }
    });
  }, []);

  const onAddBtn = (validateForm, resetForm, submitForm, values) => {
    const typeEditOrAdd = isOpen.type;
    submitForm();
    validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      !!isValid && typeof onAdd === 'function' && onAdd(values, typeEditOrAdd, type.stop);
      !!isValid && setIsOpen({});
      !!isValid && resetForm();
    });
  };

  const onChangeType = (id) => {
    const value = { ...mapTypes[id], index: type?.index };
    setType(value);
  };

  const initialValues = useMemo(() => {
    // if (stops && type?.id) {
    //   // Works only on split modal, prefills stop with previous stop with same type if exists (makes not billable)
    //   const prevStops = stops.slice(0, type?.index);
    //   if (prevStops.length) {
    //     const prevSimilarStop = prevStops.findLast((item) => Number(item.stop_point_type) === type.id);
    //
    //     if (prevSimilarStop) {
    //       return fillStopPointDataOverView(
    //         type?.id,
    //         { ...prevSimilarStop, connected_cargos: null, bill_type: 2 },
    //         isShipmentTable
    //       );
    //     }
    //   }
    // }

    if (type?.stop !== undefined) {
      return fillStopPointDataOverView(type?.id, type?.stop, isShipmentTable);
    }
    return { ...getStopTemplateDataOverView(type?.id) };
  }, [type, getStopTemplateDataOverView, fillStopPointDataOverView, isOpen, stops, isShipmentTable]);

  const similarStops = (stops || [])
    ?.map((item, index) => ({ ...item, index }))
    ?.filter((item) => Number(item.stop_point_type) === type.id);

  const onCopyStopSelect = (stop, setValues) => {
    if (stop) {
      setValues(
        fillStopPointDataOverView(type?.id, { ...stop, connected_cargos: null, bill_type: 2 }, isShipmentTable)
      );
    }
  };

  const currentLegStops = legs?.[Number(type?.legIndex) - 1]?.stops;

  return (
    <div className='testig'>
      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize
        validationSchema={validationSchema(shipmentSettings?.allow_past_date)}
        onSubmit={() => {}}
      >
        {({
          values,
          setFieldValue,
          resetForm,
          submitForm,
          validateForm,
          errors,
          touched,
          setFieldTouched,
          setValues,
        }) => {
          const StopComponent = getStopComponent(type.id);
          const lengthOptions = dimensions.reduce((acc, cur) => {
            if (Number(cur.equipment_type_id) === Number(values.equipment_type) && cur.length_info) {
              acc.push({
                ...cur,
                key: cur.length_info.id,
                label: `${cur.length_info.length} ${cur.length_info.unit}`,
                labelSelected: `${cur.length_info.length} ${cur.length_info.unit}`,
              });
            }
            return acc;
          }, []);

          return (
            <Form>
              <ModalWrapper
                width={640}
                minHeight={610}
                isOpen={isOpen?.open || false}
                title={!type.stop ? 'Add Stop' : 'Update Stop'}
                styleTitle={{ fontSize: 16 }}
                onHide={() => setIsOpen({})}
                styleBody={{ height: 600, overflowY: 'auto', padding: '0 0 20px 20px' }}
                footer={
                  <div className={styles['modal-split-footer']} style={{ backgroundColor: palette.white }}>
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen({});
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    <CustomButton
                      type='primary'
                      title={!type.stop ? 'Add Stop' : 'Update Stop'}
                      onClick={() => onAddBtn(validateForm, resetForm, submitForm, values)}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                      disabled={loading}
                    />
                  </div>
                }
              >
                <div>
                  <div
                    className={`${classes.stopTypeHeader} d-flex justify-content-between pe-4`}
                    style={{ backgroundColor: palette.white }}
                  >
                    <div className='d-flex'>
                      <Typography variant='c1' style={{ textTransform: 'uppercase', color: type?.color }}>
                        STOP {getAlphabet(Number(type?.index))} : {type.title}
                      </Typography>
                      {isOpen?.type !== 'edit' &&
                        type?.index !== 0 &&
                        type?.index !== (currentLegStops || []).length && (
                          <OverLayTopStops
                            deleted={false}
                            activeStopPointType={type?.id}
                            onChange={(e) => onChangeType(e.target.value)}
                          />
                        )}
                    </div>
                    {isOpen?.type !== 'edit' &&
                      !!isSplit &&
                      !!stops?.length &&
                      !!similarStops?.length &&
                      !!type?.id && (
                        <div>
                          <Autocomplete
                            size='small'
                            label='Copy From'
                            width='300px'
                            name='shipment_stop'
                            getOptionLabel={(option) =>
                              option?.stop_point
                                ? `STOP ${getAlphabet(Number(option?.index))}: ${option?.stop_point?.location_name} - ${
                                    option?.stop_point?.address1
                                  } ${option?.stop_point?.city?.name ? option?.stop_point.city?.name : ''}`
                                : ''
                            }
                            placeholder='Select Stop'
                            options={similarStops || []}
                            value={values.shipment_stop}
                            onChange={(e, value) => onCopyStopSelect(value, setValues)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                          />
                        </div>
                      )}
                  </div>
                  <ScrollToFieldError />

                  {!!StopComponent && !!Object.keys(values).length && (
                    <StopComponent
                      stop={values}
                      errors={errors}
                      touched={touched}
                      isSplit={isSplit}
                      setValues={setValues}
                      commodity={commodity}
                      stopPoints={stopPoints}
                      isRecurring={isRecurring}
                      shipmentSettings={shipmentSettings}
                      // onCreateSuccess={(cargos) => {
                      //   onUpdateSuccess();
                      //   setFieldValue('cargos', [...values.cargos, ...cargos.map((item) => getInitialValue(item))]);
                      // }}
                      onUpdateSuccess={(cargo) => {
                        onUpdateSuccess();
                        setFieldValue('cargos', [...cargo.map((item) => getInitialValue(item))]);
                      }}
                      onDeleteSuccess={(cargo) => {
                        onUpdateSuccess();
                        setFieldValue(
                          'cargos',
                          values.cargos.filter((i) => i.id !== cargo.id)
                        );
                      }}
                      isActiveStopPoint={isActiveStopPoint}
                      setEquipmentId={() => null}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      getCommodityAll={getCommodityAll}
                      isShipmentTable={isShipmentTable}
                      editMood={isOpen?.type === 'edit'}
                      equipmentRequired={equipmentRequired}
                      equipmentLengthData={lengthOptions}
                      currentStopAddValue={currentStopAddValue}
                      getCustomerStopPoint={getCustomerStopPoint}
                      getEquipmentRequired={getEquipmentRequired}
                      onChangeCommodityModal={(v) => setCommodityModalIsOpen(v)}
                      onChangeAddStopPointModal={(v) => setShowAddStopPointModal(v)}
                      shipmentId={shipmentId}
                      stopInfo={type}
                    />
                  )}
                </div>
                <ScrollToFieldError />
              </ModalWrapper>
            </Form>
          );
        }}
      </Formik>

      <AddStopPointModal
        show={showAddStopPointModal}
        onChangeShow={(v) => setShowAddStopPointModal(v)}
        getCustomerStopPoint={getCustomerStopPointAfterCreate}
      />

      <CommoditySettingsModal
        commodity={commodity}
        show={commodityModalIsOpen}
        onChangeShow={(v) => setCommodityModalIsOpen(v)}
        updateCommodityAll={getCommodityAll}
      />
    </div>
  );
};

export default AddEditModalDetails;
