import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import rightArrow from 'assets/icons/drivers/table-logo/right-arrow.svg';
import { useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import calendar from 'assets/icons/drivers/calendar.svg';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import { SUnderlineText } from 'components/StaffLogTab/StaffLogTab.styles';
import useDateFormat from 'hooks/useDateFormat';
import { OperatorLogData } from 'Api/OwnerOperator';
import DateRange from '../DateRangePicker/DateRangePicker';
import './LogTab.css';
import TablePreLoader from '../TablePreLoader/TablePreLoader';
import TableFooter from '../TableFooter/TableFooter';
import MaterialTableWrapper, { MaterialTableSort } from '../MaterialTableWrapper';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';

const LogTable = () => {
  const { formatDate, formatDateTime, convertToCustomerTime } = useDateFormat();
  const [rowPerPage, setRowPerPage] = useState(25);
  const [driverLog, setDriverLog] = React.useState([]);
  const [isShowDatePicker, setIsShowDatePicker] = React.useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { use } = useTheme();
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [search, setSearch] = React.useState('');
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={driverLog?.total}
        totalLength={driverLog?.data?.length}
        lastPage={driverLog?.last_page}
        currentPage={driverLog?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setDriverLog(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onChangeRowPerPage = (rowPage) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('MM/DD/YYYY');
    const endFormat = moment(end).format('MM/DD/YYYY');
    setRowPerPage(rowPage);
    setLoading(true);
    const payload = {
      page: 1,
      itemsPerPage: rowPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      operator_id: id,
    };
    if (search) {
      payload.query = search;
    }
    OperatorLogData(payload)
      .then((res) => {
        setDriverLog({ ...res });
      })
      .finally(() => setLoading(false));
  };

  const onPageChange = (event, page) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('MM/DD/YYYY');
    const endFormat = moment(end).format('MM/DD/YYYY');
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filter = {
      page,
      itemsPerPage: rowPerPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      operator_id: id,
      query: search,
    };
    OperatorLogData(filter)
      .then((res) => {
        setDriverLog(res);
      })
      .finally(() => setLoading(false));
  };

  const getDdriverIncidentData = useCallback(
    (searchText = '', sortBy = 'desc') => {
      const { start, end } = dateRange;
      const startFormat = moment(start).format('MM/DD/YYYY');
      const endFormat = moment(end).format('MM/DD/YYYY');
      const payload = {
        page: '1',
        itemsPerPage: rowPerPage,
        from_date: startFormat,
        to_date: endFormat,
        sortBy,
        operator_id: id,
      };
      if (searchText) {
        payload.query = searchText;
      }
      OperatorLogData(payload)
        .then((res) => {
          setLoading(false);
          setDriverLog(res);
        })
        .catch(() => setLoading(false));
    },
    [dateRange]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(false);
      getDdriverIncidentData(search);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [search, getDdriverIncidentData]);

  useEffect(() => {
    setLoading(true);
    getDdriverIncidentData();
  }, []);

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'change_description',
        title: <MaterialTableSort title={' '} field='change_description' />,
        render: (rowData) => (
          <div className='log-table-container-inner '>
            <div>
              {/* <p className="m-0" style={{ color: use(palette.dark900, palette.gray200) }}>General <span><img src={ArrowRight} alt='' /> </span>   Status</p> */}
              <p className='m-0' style={{ color: use(palette.dark900, palette.gray200) }}>
                {rowData?.change_description}
              </p>
            </div>
          </div>
        ),
      },
      {
        field: 'change_from',
        title: <MaterialTableSort title={' '} field='change_from' />,
        render: (row) => {
          const changeFromIsValidDate = moment(row.change_from, 'YYYY-MM-DD', true).isValid();
          const changeFromIsValidDateTime = moment(row.change_from, 'YYYY-MM-DD HH:mm:ss', true).isValid();
          const changeToIsValidDate = moment(row.change_to, 'YYYY-MM-DD', true).isValid();
          const changeToIsValidDateTime = moment(row.change_to, 'YYYY-MM-DD HH:mm:ss', true).isValid();

          return (
            <div className='d-flex align-items-center gap-2'>
              {row.change_from.startsWith('https://') ? (
                <SUnderlineText
                  onClick={(e) => {
                    e.stopPropagation();
                    setAttachmentUrl(row.change_from);
                  }}
                >
                  View Old Attachment
                </SUnderlineText>
              ) : (
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {changeFromIsValidDate
                    ? formatDate(row.change_from)
                    : changeFromIsValidDateTime
                    ? formatDateTime(row.change_from, true)
                    : row.change_from}
                </Typography>
              )}
              {row.change_to && (
                <>
                  <img src={rightArrow} alt='changed_to' />
                  {row.change_to.startsWith('https://') ? (
                    <SUnderlineText
                      onClick={(e) => {
                        e.stopPropagation();
                        setAttachmentUrl(row.change_to);
                      }}
                    >
                      View New Attachment
                    </SUnderlineText>
                  ) : (
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {changeToIsValidDate
                        ? formatDate(row.change_to)
                        : changeToIsValidDateTime
                        ? formatDateTime(row.change_to, true)
                        : row.change_to}
                    </Typography>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        field: 'created_at',
        title: <MaterialTableSort title={' '} field='created_at' />,
        render: (row) => (
          <div className='log-table-container-inner'>
            <div className='col-4 box3'>
              <p className='m-0' style={{ color: use(palette.dark900, palette.gray200) }}>
                By {row?.change_by_user_details?.first_name} {row?.change_by_user_details?.last_name} on{' '}
                {convertToCustomerTime(row.created_at)}
              </p>
            </div>
          </div>
        ),
      },
    ];
  }, []);

  if (loading) {
    return <TablePreLoader />;
  }

  return (
    <div
      className='log-table-container'
      style={{ background: use(palette.white, palette.dark800), borderColor: use(palette.gray50, palette.dark800) }}
    >
      <div className='log-header-container'>
        <div className='log-header-left'>
          <div className='headingCover'>
            <p className='heading log-heading' style={{ color: use(palette.gray900, palette.gray50) }}>
              Log
            </p>
          </div>
          <div className='serach-wrapper'>
            <input
              type='text'
              onChange={(e) => setSearch(e.target.value)}
              className='serach-feild'
              placeholder='Search'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray400, palette.white),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            />
            <div className='calender-wrap'>
              {isShowDatePicker && (
                <div style={{ position: 'relative', left: '117px' }}>
                  <DateRange
                    title='All Time'
                    dateTimeRange={dateRange}
                    dateSelect={dateSelect}
                    rangeName={rangeName}
                    setRangeName={setRangeName}
                    setIsShowDatePicker={setIsShowDatePicker}
                  />
                </div>
              )}
              <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
            </div>
          </div>
        </div>
        <div className='log-header-right' />
      </div>
      <div className='table-wrap log-table-wrapper'>
        <div className='sub-table-container'>
          <MaterialTableWrapper
            data={driverLog?.data}
            rowPerPage={rowPerPage}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={columns}
          />
        </div>
      </div>
      <ViewPdf
        title='View Attachment'
        open={!!attachmentUrl}
        onClose={() => setAttachmentUrl('')}
        pdfUrl={attachmentUrl}
      />
    </div>
  );
};

export default LogTable;
