import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getMergedUrl } from 'utils/helpers';
import useDebounce from 'hooks/useDebounce';
import useDateFormat from 'hooks/useDateFormat';
import { getDrivers } from 'Api/EquipmentProfile';
import { getDrugAlcoholReport } from 'Api/Reports';
import { GetCompanyProfile } from 'Api/CompanySettings';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { generateSeparateReport } from './generateSeparateResults';
import { drugTestReasonOptions } from './DrugAlcohol.data';
import { STableFilters, STableWrapper, STable } from '../ReportDetails.styles';

const DrugAlcohol = () => {
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const [pdfUrl, setPdfUrl] = useState('');
  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [reportItems, setReportItems] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    drivers: [],
    reason: [],
  });
  const debouncedFilters = useDebounce(selectedFilters, 600);
  const didMountRef = useRef(false);
  const controller = useRef(new AbortController());

  const getDrugAlcoholData = async () => {
    try {
      setLoading(true);
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }
      const driverIds = selectedFilters.drivers.map((el) => el.id);

      const params = {
        from: dateRange.start ? moment(dateRange.start.toUTCString()).format('YYYY-MM-DD') : undefined,
        to: dateRange.end ? moment(dateRange.end.toUTCString()).format('YYYY-MM-DD') : undefined,
        driver_id: selectedFilters.drivers.length ? driverIds : undefined,
        reason: selectedFilters.reason.length ? selectedFilters.reason.toString() : undefined,
      };
      const { data } = await getDrugAlcoholReport(params, controller.current?.signal);
      setReportItems(data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const getDriversList = async () => {
    try {
      setLoadingDrivers(true);
      const { data } = await getDrivers();
      setDrivers(data.map((item) => ({ id: item.id, name: `${item.fname} ${item.lname}` })));
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingDrivers(false);
    }
  };

  const getCompanyProfile = async () => {
    try {
      const company = await GetCompanyProfile();
      setCompanyProfile(company);
    } catch (e) {
      // Do nothing
    }
  };

  const onDownload = (path) => {
    fetch(path, { mode: 'cors' })
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = new Blob([resp], { type: 'application/pdf' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(file);
        link.download = `Drug and Alcohol Report - ${
          dateRange.start && dateRange.end
            ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}`
            : 'All Time'
        }`;
        link.href = url;
        link.click();
      });
  };

  const onPdfClick = async (download) => {
    try {
      setLoadingGenerate(true);
      const { url } = await generatePDF(reportItems, dateRange, formatDate, convertToCustomerTime);
      const docs = [url];

      reportItems.forEach((item) => {
        const { url } = generateSeparateReport(item, companyProfile, formatDate);
        docs.push(url);

        if (item.attach_drug_check_result?.endsWith('.pdf')) {
          docs.push(item.attach_drug_check_result);
        }
        if (item.drug_test_custom_form?.endsWith('.pdf')) {
          docs.push(item.drug_test_custom_form);
        }
      });

      const { url: mergedUrl } = await getMergedUrl(docs);

      if (!download) {
        setPdfUrl(mergedUrl);
      } else {
        onDownload(mergedUrl);
      }
    } catch (e) {
      /* empty */
    } finally {
      setLoadingGenerate(false);
    }
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setInitialLoading(true);
    }
    getDrugAlcoholData();
    didMountRef.current = true;
  }, [dateRange, debouncedFilters]);

  useEffect(() => {
    getDriversList();
    getCompanyProfile();
  }, []);

  return (
    <PageWrapper loading={initialLoading} title='Drug and Alcohol Report'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              initialRangeName='All Time'
              type='allTime'
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Autocomplete
              multiple
              width='320px'
              placeholder={selectedFilters.drivers.length ? '' : 'Select Driver..'}
              limitTags={3}
              options={drivers}
              value={selectedFilters.drivers}
              loading={loadingDrivers}
              getTagLabel={(option) => (option ? `${option.name}` : '')}
              getOptionLabel={(option) => (option ? `${option.name}` : '')}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, drivers: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable={false}
            />
            <Autocomplete
              multiple
              width='320px'
              limitTags={2}
              value={selectedFilters.reason}
              options={drugTestReasonOptions}
              placeholder={!selectedFilters.reason.length ? 'Reason..' : ''}
              onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, reason: val }))}
              disableClearable={false}
            />
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={reportItems.length === 0 || loading || loadingGenerate}
          />
        </STableFilters>
      </STableWrapper>
      <Divider margin='16px 0 36px' />
      <Header
        onDownloadPdf={() => onPdfClick(true)}
        disabled={reportItems.length === 0 || loading || loadingGenerate}
      />
      <Divider margin='16px 0 16px' />
      <STable>
        <thead>
          <tr className='header-row'>
            <th>DRIVER NAME</th>
            <th>DATE</th>
            <th>RESULTS</th>
            <th>TYPE</th>
            <th>REASON</th>
          </tr>
        </thead>
        <tbody>
          {reportItems.map((item) => (
            <tr key={item.id} className='body-row'>
              <td>
                <Typography variant='s2'>{item.driver?.full_name || '-'}</Typography>
              </td>
              <td>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.drug_checked_date ? formatDate(item.drug_checked_date) : '-'}
                </Typography>
              </td>
              <td>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.drug_check_results || '-'}
                </Typography>
              </td>
              <td>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.type || '-'}
                </Typography>
              </td>
              <td>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {item.reason || '-'}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </STable>
      {!loading && !reportItems?.length && <NoRecords />}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Drug and Alcohol Report' />
      )}
    </PageWrapper>
  );
};

export default DrugAlcohol;
