import {
  GetBillingCardsFeatures,
  GetBillingCardsPrice,
  PostBillingCheckPricesCoupon,
  GetBillingDetail,
  PostBillingCarrierCheckout,
  UpdateCustomPlan,
  UpdatePaymentMethod,
  GetAdditionalServices,
  getPaymentDetails,
  UpdateExpiredCustomPlan,
} from 'Api/Billing';
import { getPremiumPlans } from 'Api/Onboarding';
import {
  setPlans,
  setBillingState,
  setPaymentMethod,
  setBillingDetail,
  setBillingCardsPrice,
  setBillingCardsFeatures,
  setBillingCheckPricesCoupon,
  setBillingPaymentMethodUpdatedData,
  setBillingAdditionalServices,
} from '../reducers/billing.reducer';

export const getPaymentMethod = () => (dispatch) => {
  getPaymentDetails()
    .then((res) => {
      dispatch(setPaymentMethod(res.data));
    })
    .catch(() => {
      /* empty */
    });
};
export const getBillingDetail = () => (dispatch) => {
  GetBillingDetail()
    .then((res) => {
      dispatch(setBillingDetail({ ...res.data?.billing_details, promo_code: res.data.promo_code }));
      if (res.data?.customer_status_data) {
        localStorage.setItem('accountStatus', JSON.stringify(res.data.customer_status_data));
      }
    })
    .catch(() => {
      /* empty */
    });
};
export const getBillingCardsPrice = () => (dispatch) => {
  GetBillingCardsPrice()
    .then((res) => {
      dispatch(setBillingCardsPrice(res));
    })
    .catch(() => {
      /* empty */
    });
};

export const getPlans = (params) => (dispatch) => {
  getPremiumPlans(params)
    .then((res) => {
      const data = res.map((plan) => {
        const sortedPlanTypes = plan.plan_types.sort((a, b) => a.amount - b.amount);
        return { ...plan, plan_types: sortedPlanTypes };
      });
      dispatch(setPlans(data));
    })
    .catch(() => {
      /* empty */
    });
};

export const getBillingCardsFeatures = () => (dispatch) => {
  GetBillingCardsFeatures()
    .then((res) => {
      dispatch(setBillingCardsFeatures(res));
    })
    .catch(() => {
      /* empty */
    });
};
export const postBillingCheckPricesCoupon = (params) => (dispatch) => {
  PostBillingCheckPricesCoupon(params)
    .then((res) => {
      dispatch(setBillingCheckPricesCoupon(res));
    })
    .catch(() => {
      /* empty */
    });
};
export const postBillingCarrierCheckout = (params) => (dispatch) => {
  PostBillingCarrierCheckout(params)
    .then((res) => {
      dispatch(setBillingCheckPricesCoupon(res));
    })
    .catch(() => {
      /* empty */
    });
};
export const updateCustomPlan =
  (params, onSuccess = () => null, onError = () => null) =>
  () => {
    UpdateCustomPlan(params)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e);
        /* empty */
      });
  };
export const updateExpiredCustomPlan =
  (params, onSuccess = () => null, onError = () => null) =>
  () => {
    UpdateExpiredCustomPlan(params)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e);
        /* empty */
      });
  };
export const updatePaymentMethod = (params) => (dispatch) => {
  UpdatePaymentMethod(params)
    .then((res) => {
      dispatch(setBillingPaymentMethodUpdatedData(res));
    })
    .catch(() => {
      /* empty */
    });
};
export const getAdditionalServices = (params) => (dispatch) => {
  dispatch(setBillingState({ additionalServicesListStatus: 'LOADING' }));
  GetAdditionalServices(params)
    .then((res) => {
      dispatch(setBillingAdditionalServices(res));
    })
    .catch(() => {
      dispatch(setBillingState({ additionalServicesListStatus: 'ERROR' }));
    });
};
