import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import add from 'assets/icons/drivers/add.svg';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import {
  driverAbsenceData,
  driverRemoveAbsenceData,
  driverCreateAbsenceData,
  driverUpdateAbsenceData,
} from 'Api/Driver';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getErrorMessage } from 'utils/error';
import Autocomplete from 'common/Autocomplete';
import useDebounce from 'hooks/useDebounce';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import TableSkeleton from 'common/TableSkeleton';
import useShowToaster from 'hooks/useShowToaster';
import PrimaryBtn from 'components/DriverProfileButton/DriverProfileButton';
import SwitchRadio from 'components/SwitchRadio/SwitchRadio';
import MaterialTableWrapper, { MaterialTableSort } from 'components/MaterialTableWrapper';
import TableFooter from 'components/TableFooter/TableFooter';
import AbsenceModal from 'components/AddAbsenceModal/AddAbsenceModal';
import AbsenceAllowanceModal from 'components/UpdateAbsenceAllowanceModal/UpdateAbsenceAllowanceModal';
import RowActions from './RowActions';
import './AbsenceTable.css';

const AbsenceTable = ({ absenceDetails, absence_allowance, updateAbsenceAllowanceData, absLoading }) => {
  const showToaster = useShowToaster();
  const [tab, setTab] = useState(1);
  const myTableRef = useRef(null).current;
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const [modalShow, setModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [updateAbsenceModalShow, setUpdateAbsenceModalShow] = useState(false);
  const [driverAbsence, setDriverAbsence] = useState({});
  const [otherData, setOtherData] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [tabs, setTabs] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year().toString());
  const debouncedSearch = useDebounce(search, 500);

  const { id } = useParams();
  const { use } = useTheme();
  const [allAbsence, setAllAbsence] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateData, setUpdatedData] = useState({});

  const getDriverAbsenceData = useCallback((searchText = '', sortBy = 'desc', year = Number(moment().year())) => {
    setLoading(true);
    const payload = { page: '1', itemsPerPage: rowPerPage, year, sortBy, driver_id: id };
    if (searchText) {
      payload.query = searchText;
    }
    driverAbsenceData(payload)
      .then((res) => {
        setLoading(false);
        const tempTabs = [];
        let value = 1;
        for (const req of res.absence_data.data || []) {
          const hasRequestIndex = tempTabs.findIndex((tab) => tab.label === req?.absence_type?.name);
          if (hasRequestIndex === -1) {
            value += 1;
            tempTabs.push({ label: req?.absence_type?.name, count: 1, key: req?.type, value });
          } else {
            tempTabs[hasRequestIndex].count += 1;
          }
        }
        tempTabs.unshift({ label: 'All', count: res?.absence_data?.data.length, key: 'all', value: 1 });
        setTabs(tempTabs);
        setDriverAbsence(res.absence_data);
        setAllAbsence(res.absence_data);
        setOtherData(res.other_data);
        absenceDetails(year);
        const data = res?.other_data?.years?.find((item) => item === Number(moment(new Date()).format('yyyy')));
        if (data === undefined) {
          res?.other_data?.years?.push(Number(moment(new Date()).format('yyyy')));
        }
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    getDriverAbsenceData(debouncedSearch);
  }, [debouncedSearch]);

  const showError = (message) => {
    showToaster({ message, type: 'error' });
  };

  const onSuccess = (message) => {
    getDriverAbsenceData(search, undefined, selectedYear);
    if (message) {
      showToaster({ message, type: 'success' });
    }
  };

  const createAbsence = (absence, type, onHide = null, onSuccess = null, successMsg = '') => {
    setCreateLoading(true);
    if (type === 'add') {
      driverCreateAbsenceData(absence)
        .then(() => {
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          getDriverAbsenceData();
          setCreateLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setCreateLoading(false);
        });
    } else {
      const absenceId = absence?.id;
      delete absence?.id;
      delete absence.driver_id;
      driverUpdateAbsenceData(absence, absenceId)
        .then(() => {
          if (onHide) {
            onHide();
          }
          if (onSuccess) {
            onSuccess(successMsg);
          }
          getDriverAbsenceData();
          setCreateLoading(false);
        })
        .catch((error) => {
          showError(getErrorMessage(error));
          setCreateLoading(false);
        });
    }
  };

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        setLoading={(v) => setLoading(v)}
        myTableRef={myTableRef}
        rowPerPage={rowPerPage}
        totalCount={driverAbsence?.total}
        totalLength={driverAbsence?.data?.length}
        lastPage={driverAbsence?.last_page}
        currentPage={driverAbsence?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => {
          setDriverAbsence(data);
        }}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onPageChange = (event, page) => {
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filter = { page, itemsPerPage: rowPerPage, year: selectedYear, sortBy: 'desc', driver_id: id };
    driverAbsenceData(filter)
      .then((res) => {
        setDriverAbsence(res.absence_data);
      })
      .finally(() => setLoading(false));
  };

  const onChangeTab = (tabValue) => {
    setLoading(true);
    let currentTabData = {};
    if (tabValue.value === 1) {
      // show all data
      currentTabData = allAbsence;
    } else {
      const reqFilterData = allAbsence?.data?.filter((req) => req?.type === tabValue.key);
      currentTabData = { ...driverAbsence, data: JSON.parse(JSON.stringify(reqFilterData)) };
    }
    setTimeout(() => {
      setDriverAbsence(currentTabData);
      setTab(tabValue);
      setLoading(false);
    }, 500);
  };

  const onChangeRowPerPage = (rowPage) => {
    setRowPerPage(rowPage);
    myTableRef?.dataManager?.changePageSize(rowPage);
    setLoading(true);
    const payload = { page: 1, itemsPerPage: rowPage, year: selectedYear, sortBy: 'desc', driver_id: id };
    if (search) {
      payload.query = search;
    }
    driverAbsenceData(payload)
      .then((res) => {
        setDriverAbsence({ ...res.absence_data });
      })
      .finally(() => setLoading(false));
  };

  const removeAbsence = (item) => {
    const { id } = item;
    const index = item?.tableData?.id;
    driverRemoveAbsenceData(id)
      .then(() => {
        const tempData = [...driverAbsence.data];
        tempData.splice(index, 1);
        setDriverAbsence({ ...driverAbsence, total: driverAbsence.total - 1, data: tempData });
        onSuccess('Attendance has been deleted successfully');
      })
      .catch((error) => {
        showError(getErrorMessage(error));
        setCreateLoading(false);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'start_date',
        title: <MaterialTableSort title='Date' field='start_date' />,
        render: (rowData) => {
          return rowData?.start_date ? (
            <div className='sub-wraper'>
              <div
                className='date-container'
                style={{
                  backgroundColor: use('#F0F2F7', palette.dark600),
                  color: use(palette.gray600, palette.gray400),
                }}
              >
                <p className='sub-text'>{rowData?.start_date}</p>
              </div>
              <span>-</span>
              <div
                className='date-container'
                style={{
                  backgroundColor: use('#F0F2F7', palette.dark600),
                  color: use(palette.gray600, palette.gray400),
                }}
              >
                <p className='sub-text'>{rowData?.end_date}</p>
              </div>
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'reason_title',
        title: <MaterialTableSort title='REASON' field='reason_title' />,
        render: (rowData) => (
          <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
            {rowData?.reason_title}
          </p>
        ),
      },
      {
        field: 'days',
        title: <MaterialTableSort title='DAYS' field='days' />,
        render: (rowData) => {
          const a = moment(rowData?.start_date);
          const b = moment(rowData?.end_date);
          const day = moment.duration(b.diff(a)).asDays();
          return (
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {day === 0 ? '1 Day' : day ? `${day + 1} Days` : '-'}
            </p>
          );
        },
      },
      {
        field: 'type',
        title: <MaterialTableSort title='TYPE' field='type' />,
        render: (rowData) => (
          <div
            className={
              rowData?.type === '2'
                ? 'warning-container'
                : rowData?.type === '1'
                ? 'success-container success-container-absence'
                : 'break-container'
            }
          >
            <p
              className={
                rowData?.type === '2'
                  ? 'warning-text-absence'
                  : rowData?.type === '1'
                  ? 'success-text-absence'
                  : 'break-text-absence'
              }
            >
              {rowData.absence_type?.name?.toUpperCase() || rowData.type?.toUpperCase()}
            </p>
          </div>
        ),
      },
      {
        field: 'absence_details',
        title: <MaterialTableSort title='DRIVER NOTES' field='absence_details' />,
        render: (rowData) => (
          <p
            className='table-data-text'
            style={{
              color: use(palette.gray700, palette.gray200),
              whiteSpace: 'break-spaces',
              minWidth: rowData?.absence_details?.length > 40 ? '440px' : '',
            }}
          >
            {rowData?.absence_details}
          </p>
        ),
      },
      {
        field: 'absence_document',
        title: <MaterialTableSort title='SUPPORTING DOCUMENT' field='absence_document' />,
        render: (rowData) =>
          rowData?.absence_document ? (
            <p className='table-view-text'>Uploaded</p>
          ) : (
            <p className='table-view-text'>-</p>
          ),
      },
      {
        field: 'added_by_user',
        title: <MaterialTableSort title='Reviewed By' field='added_by_user' />,
        render: (rowData) => (
          <div className='more-dowpdown-container' onClick={(e) => e.stopPropagation()}>
            <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
              {rowData?.added_by_user?.first_name
                ? `${rowData?.added_by_user?.first_name} ${rowData?.added_by_user?.last_name}`
                : ''}
            </p>
          </div>
        ),
      },
      {
        field: '',
        render: (rowData) =>
          !hideSensitiveInfo ? (
            <RowActions
              onEdit={() => {
                setUpdatedData(rowData);
                setUpdateAbsenceModalShow(true);
              }}
              onDelete={() => removeAbsence(rowData)}
            />
          ) : null,
      },
    ];
  }, [driverAbsence]);
  return (
    <div
      className='absence-table-container absence-staff driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div className='header-container' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <div className='sub-header'>
          <p className='heading' style={{ color: use(palette.gray700, palette.gray200) }}>
            Attendance{' '}
          </p>
          <div className='header-modal-container'>
            {!hideSensitiveInfo && (
              <>
                <PrimaryBtn icon={add} title='Add Attendance' onClick={() => setModalShow(true)} />
                <img src={moreInfo} alt='' onClick={() => setUpdateModalShow(true)} />
              </>
            )}
          </div>
        </div>
        <div className='serach-wrapper'>
          <div className='position-relative'>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='serach-feild'
              placeholder='Search'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray400, palette.white),
                borderColor: use(palette.gray50, palette.darkborder),
                padding: '4px 24px 4px 35px',
              }}
            />
            {!!search?.length && (
              <DeleteIcon
                width={12}
                height={12}
                fill={palette.gray300}
                style={{ position: 'absolute', right: '8px', top: '8px', cursor: 'pointer' }}
                onClick={() => setSearch('')}
              />
            )}
          </div>
          <Autocomplete
            width='120px'
            options={(otherData?.years || []).map((item) => item.toString())}
            value={selectedYear}
            onChange={(e, value) => {
              setSelectedYear(value);
              getDriverAbsenceData(debouncedSearch, null, value);
            }}
          />
        </div>
      </div>
      <div className='table-wrap'>
        {loading ? (
          <TableSkeleton />
        ) : (
          <>
            <SwitchRadio
              name='tableTopTabMenu'
              items={tabs}
              value={tab}
              type='tab'
              onChange={(v) => onChangeTab(v)}
              plus={false}
            />
            <div
              className={driverAbsence?.data?.length < 2 ? 'single-setting sub-table-container' : 'sub-table-container'}
            >
              <MaterialTableWrapper
                data={driverAbsence?.data}
                rowPerPage={rowPerPage}
                style={{ backgroundColor: use(palette.white, palette.dark800) }}
                components={{
                  Pagination: PaginationComponent,
                }}
                columns={columns}
                onRowClick={(e, rowData) => {
                  if (hideSensitiveInfo) {
                    return;
                  }
                  setUpdatedData(rowData);
                  setUpdateAbsenceModalShow(true);
                }}
              />
            </div>
          </>
        )}
        {modalShow && (
          <AbsenceModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            loading={createLoading}
            title='Add Attendance'
            isupdate={false}
            driverId={id}
            createAbsence={createAbsence}
            onSuccess={onSuccess}
          />
        )}

        {updateAbsenceModalShow && (
          <AbsenceModal
            show={updateAbsenceModalShow}
            onHide={() => setUpdateAbsenceModalShow(false)}
            loading={createLoading}
            title='Update Attendance'
            isupdate
            updateData={updateData}
            driverId={id}
            createAbsence={createAbsence}
            onSuccess={onSuccess}
          />
        )}

        {!!updateModalShow && (
          <AbsenceAllowanceModal
            show={updateModalShow}
            onHide={() => setUpdateModalShow(false)}
            absenceAllowance={absence_allowance}
            updateAbsenceAllowanceData={updateAbsenceAllowanceData}
            onSuccess={onSuccess}
            absLoading={absLoading}
          />
        )}
      </div>
    </div>
  );
};

export default AbsenceTable;
