import { disabledIds } from '../Equipment.data';

export const isValidRow = (row) => {
  const {
    equipment_id,
    equipment_type_id,
    make,
    model_id,
    fuel_type_id,
    owner_operator,
    state,
    length,
    width,
    height,
    cargo_maximum_capacity_weight,
    gross_vehicle_weight,
    license_plate_number,
    leasing_company_name,
  } = row;

  return (
    !!equipment_id &&
    !!equipment_type_id &&
    !!make &&
    !!model_id &&
    !!fuel_type_id &&
    (owner_operator?.value === '1' ||
      (owner_operator?.value === '2' && leasing_company_name) ||
      owner_operator?.value === '3') &&
    license_plate_number &&
    !!state &&
    !!gross_vehicle_weight &&
    (disabledIds.includes(equipment_type_id.id)
      ? true
      : !!length && !!width && !!height && !!cargo_maximum_capacity_weight)
  );
};

export const getGVWRClass = (gross_vehicle_weight, gross_vehicle_measuring_unit) => {
  if (!gross_vehicle_weight || !gross_vehicle_measuring_unit) {
    return null;
  }

  if (gross_vehicle_measuring_unit.value === 'LBS') {
    if (Number(gross_vehicle_weight.split(',').join('')) < 6000) {
      return 'Class 1';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 6001 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 10000
    ) {
      return 'Class 2';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 10001 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 14000
    ) {
      return 'Class 3';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 14001 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 16000
    ) {
      return 'Class 4';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 16001 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 19500
    ) {
      return 'Class 5';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 19501 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 26000
    ) {
      return 'Class 6';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 26001 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 33000
    ) {
      return 'Class 7';
    }
    if (Number(gross_vehicle_weight.split(',').join('')) >= 33001) {
      return 'Class 8';
    }
  }
  if (gross_vehicle_measuring_unit.value === 'KGS') {
    if (Number(gross_vehicle_weight.split(',').join('')) <= 2721.54) {
      return 'Class 1';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 2722 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 4535
    ) {
      return 'Class 2';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 4536 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 6350
    ) {
      return 'Class 3';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 6351 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 7257
    ) {
      return 'Class 4';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 7258 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 8845
    ) {
      return 'Class 5';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 8846 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 11794
    ) {
      return 'Class 6';
    }
    if (
      Number(gross_vehicle_weight.split(',').join('')) > 11795 &&
      Number(gross_vehicle_weight.split(',').join('')) <= 14968
    ) {
      return 'Class 7';
    }
    if (Number(gross_vehicle_weight.split(',').join('')) >= 14969) {
      return 'Class 8';
    }
  }
};

export const getExtractedString = (url) => {
  const index = url.indexOf('fleet-staff');
  return url.substring(index);
};
