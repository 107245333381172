import React from 'react';
import { useSelector } from 'react-redux';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import PayPeriod from '../../shared/PayPeriod';
import PayOption from '../components/PayOption';
import RowActions from './ProcessedSettlements/RowActions';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';

const EmptyCell = () => {
  const { use } = useTheme();
  return (
    <Typography variant='b2' style={{ color: use(palette.gray300, palette.gray200) }}>
      N/A
    </Typography>
  );
};

export const useSettlementsColumns = ({
  sortingQuery,
  sort,
  isProcessed,
  isPaid,
  onRevert,
  onMarkPaid,
  allSelected,
  selectedRows,
  hideSelectAll,
  handleSelectAll,
  handleSelectRow,
  onClickRowItem,
}) => {
  const { use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    ...(isProcessed
      ? [
          {
            field: '',
            width: 'auto',
            headerStyle: { width: '40px' },
            cellStyle: { width: '40px' },
            title: !hideSelectAll ? <CustomCheckbox onChange={handleSelectAll} checked={allSelected} /> : '',
            render: (row) => (
              <div style={{ width: 16, height: 16 }} onClick={(e) => e.stopPropagation()}>
                <CustomCheckbox
                  onChange={(checked) => handleSelectRow(checked, row)}
                  checked={selectedRows?.some((i) => i.id === row.id)}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      field: 'full_name',
      title: <ColumnHeader title='NAME' field='full_name' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        const contactorName = row?.user?.app?.operator?.owner_operator_name || '';
        return row.user ? (
          <UserInfo
            size='32px'
            statusSize='7px'
            data={{
              ...(row.user || {}),
              name: `${row.user.fname || row.user.first_name} ${row.user.lname || row.user.last_name} ${
                contactorName && `(${contactorName})`
              }`,
              image: row.user.image || row.user.profile_logo,
              status: row.user?.status_data?.driver_status || row.user?.status_data?.id || row.user.status,
              user_type: row.user_type === 'App\\Driver' ? 'driver' : 'staff',
            }}
          />
        ) : (
          '-'
        );
      },
    },
    {
      field: 'id',
      title: <ColumnHeader title='SETTLEMENT ID' field='id' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
          {row.id}
        </Typography>
      ),
    },
    {
      field: 'rate',
      title: <ColumnHeader title='RATE' field='rate' />,
      render: (row) =>
        row?.user?.compensation_details ? (
          <PayOption
            data={row.user.compensation_details}
            userType={row.user_type === `App\\User` ? 'staff' : 'driver'}
          />
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'pay_period',
      title: <ColumnHeader title='PAY PERIOD' field='pay_period' />,
      render: (row) => <PayPeriod start={row.pay_period_start} end={row.pay_period_end} />,
    },
    {
      field: 'description',
      title: <ColumnHeader title='DESCRIPTION' field='description' />,
      render: (row) =>
        row.description ? (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.description}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'set_payment_date',
      title: <ColumnHeader title='SET PAY DATE' field='set_payment_date' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
          {row.set_payment_date ? convertToCustomerTime(row.set_payment_date) : '-'}
        </Typography>
      ),
    },
    {
      field: 'amount',
      title: <ColumnHeader title='AMOUNT' field='amount' />,
      render: (row) =>
        row.amount || row.amount === 0 ? (
          <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {currency}
            {formatNumber(row.amount)}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
    ...(isPaid
      ? [
          {
            field: 'payment_method',
            title: <ColumnHeader title='PAYMENT METHOD' field='payment_method' />,
            render: (row) => {
              return (
                <Typography
                  variant='b2'
                  style={{ color: use(palette.gray700, palette.gray200), textTransform: 'capitalize' }}
                >
                  {row?.payment_method || '-'}
                </Typography>
              );
            },
          },
          {
            field: 'reference',
            title: <ColumnHeader title='REFERENCE' field='reference' />,
            render: (row) => {
              return row?.pdf_check_file_link ? (
                <Typography
                  variant='b2'
                  style={{ color: palette.indigo500, textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickRowItem({ filed: 'reference', row });
                  }}
                >
                  {row?.reference || '-'}
                </Typography>
              ) : (
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {row?.reference || '-'}
                </Typography>
              );
            },
          },
          {
            field: 'paid_by_account',
            title: <ColumnHeader title='FUNDING ACCOUNT' field='paid_by_account' />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {row.paid_by_account ? row.paid_by_account?.account_name : '-'}
              </Typography>
            ),
          },
          {
            field: 'paid_date',
            title: <ColumnHeader title='PAID DATE' field='paid_date' />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {row.paid_at ? convertToCustomerTime(row.paid_at) : '-'}
              </Typography>
            ),
          },
          {
            field: 'paid_by_user',
            title: <ColumnHeader title='UPDATED BY' field='paid_by_user' />,
            render: (row) => (
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {row.paid_by_user?.staff
                  ? `${row.paid_by_user.staff.first_name} ${row.paid_by_user.staff.last_name}`
                  : '-'}
              </Typography>
            ),
          },
        ]
      : []),
    {
      ...(isProcessed
        ? {
            field: '',
            render: (row) => <RowActions onRevert={() => onRevert(row)} onMarkPaid={() => onMarkPaid(row)} />,
          }
        : {}),
    },
  ];
};
