import React from 'react';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import UserInfo from 'components/UserInfo';

export const useColumns = () => {
  const { formatDateTime } = useDateFormat();

  return [
    {
      field: 'date',
      title: <ColumnHeader title='Accident Date/Time' field='collision_date_time' />,
      render: (row) => {
        const { collision_date_time } = row;
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {collision_date_time ? formatDateTime(collision_date_time) : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'accident_location',
      title: <ColumnHeader title='Location of Accident' field='accident_location' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.accident_location || '-'}
        </Typography>
      ),
    },
    {
      field: 'fatalities',
      title: <ColumnHeader title='No. of Deaths' field='fatalities' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.fatalities || '0'}
        </Typography>
      ),
    },
    {
      field: 'injuries',
      title: <ColumnHeader title='No. of Non-Fatal Injuries' field='injuries' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.injuries || '0'}
        </Typography>
      ),
    },
    {
      field: 'hazmat',
      title: <ColumnHeader title='H/M' field='hazmat' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.hazmat ? 'Yes' : 'No'}
        </Typography>
      ),
    },
    {
      field: 'driver',
      title: <ColumnHeader title='Driver' field='driver' />,
      render: (row) => (
        <div>
          {row.driver ? (
            <div className='d-flex align-items-center gap-3'>
              <UserInfo
                size='28px'
                data={{
                  id: row.driver?.id,
                  name: `${row.driver?.fname} ${row?.driver?.lname}`,
                  image: row.driver?.image,
                  phone_number: row.driver?.phone_number,
                  email: row.driver?.email,
                  status: row.driver?.status_data,
                  user_type: 'driver',
                }}
              />
            </div>
          ) : (
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              -
            </Typography>
          )}
        </div>
      ),
    },
    {
      field: 'report_number',
      title: <ColumnHeader title='Report Number' field='report_number' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.report_number || '-'}
        </Typography>
      ),
    },
  ];
};
