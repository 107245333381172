import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './AddDriverStyle.css';
import { Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { uploadDriverDocument } from 'Api/Driver';
import {
  getDriverPsp,
  getFmcsaResults,
  saveFinishLater,
  createDriverPsp,
  createDriverFmcsa,
  updateDriverPsp,
  updateDriverFmcsa,
  getMedicalExaminers,
  getDriverMvrRecords,
  createDriverMvrRecords,
  updateDriverMvrRecords,
  createMedicalExaminers,
  updateMedicalExaminers,
  getDriverDrugTestRecords,
  getDriverCriminalRecords,
  createDriverDrugTestRecords,
  updateDriverCriminalRecords,
  createDriverCriminalRecords,
  updateDriverDrugTestRecords,
} from 'Api/DriverV2';
import { getMergedUrl } from 'utils/helpers';
import { getErrorMessage } from 'utils/error';
import MedicalAndBackgroundEdit from 'componentsV2/MedicalAndBackgroundTab/MedicalAndBackgroundEdit';
import CustomButton from 'components/CustomButton/CustomButton';
import { generatePdf } from 'components/AddIncidentModal/AddIncidentModal.data';

const MedicalAndBackground = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  stepsDone,
  setStepsDone,
  driverId,
  draftDriver,
  getDrivers,
  applicantMedicalAndBackground,
}) => {
  const [onNextLoading, setOnNextLoading] = useState(false);
  const [medicalData, setMedicalData] = useState(null);

  const mergeUploadedDocuments = async (documents) => {
    if (!documents.length) {
      return;
    }

    const { url } = documents.some(
      (file) => file?.endsWith('.png') || file?.endsWith('.jpg') || file?.endsWith('.jpeg')
    )
      ? generatePdf(documents)
      : {};

    const docs = [...documents.filter((file) => file.endsWith('.pdf'))];
    if (url) {
      docs.push(url);
    }

    const { blob } = await getMergedUrl(docs);
    const { data } = await uploadDriverDocument(blob, driverId);

    return data?.full_path;
  };

  const getDriverMedicalAndBackground = async () => {
    try {
      const p1 = getMedicalExaminers(driverId);
      const p2 = getDriverCriminalRecords(driverId);
      const p3 = getDriverMvrRecords(driverId);
      const p4 = getDriverDrugTestRecords(driverId);
      const p5 = getDriverPsp(driverId);
      const p6 = getFmcsaResults(driverId);
      const [
        { value: medical },
        { value: criminal },
        { value: mvr },
        { value: drug },
        { value: psp },
        { value: fmcsa },
      ] = await Promise.allSettled([p1, p2, p3, p4, p5, p6]);
      setMedicalData({
        medicalExaminersResults: medical?.data || [],
        mvrCheckResults: mvr?.data || [],
        drugTestResults: drug?.data || [],
        criminalCheckResults: criminal?.data || [],
        pspResults: psp?.data || [],
        fmcsaResults: fmcsa?.data || [],
      });
    } catch (e) {
      // Do nothing
    }
  };

  const onPrevStep = () => {
    setStepsDone((prevState) => ({
      ...prevState,
      isBasicDetailsSaved: true,
    }));
    prevStep();
  };

  const onNextStep = async (values) => {
    setOnNextLoading(true);
    const {
      medicalExaminersResults,
      mvrCheckResults,
      drugTestResults,
      criminalCheckResults,
      pspResults,
      fmcsaResults,
    } = values;

    const newMedicalExaminers = medicalExaminersResults.filter((item) => item.isNew);
    const updateMedicalExaminersResults = medicalExaminersResults.filter((item) => !item.isNew);
    const newMvr = mvrCheckResults.filter((item) => item.isNew);
    const updateMvr = mvrCheckResults.filter((item) => !item.isNew);
    const newDrugTests = drugTestResults.filter((item) => item.isNew);
    const updateDrugTests = drugTestResults.filter((item) => !item.isNew);
    const newCriminalChecks = criminalCheckResults.filter((item) => item.isNew);
    const updateCriminalChecks = criminalCheckResults.filter((item) => !item.isNew);
    const newPsp = pspResults.filter((item) => item.isNew);
    const updatePsp = pspResults.filter((item) => !item.isNew);
    const newFmcsa = fmcsaResults.filter((item) => item.isNew);
    const updateFmcsa = fmcsaResults.filter((item) => !item.isNew);

    const newMedicalExaminersBody = {
      records: newMedicalExaminers.map((item) => ({
        driver_id: driverId,
        expiry_date: item.expiry_date ? moment(item.expiry_date).format('YYYY-MM-DD') : '',
        attach_card: item.attach_card?.includes('https') ? item.attach_card : '',
        attach_long_form: item.attach_long_form?.includes('https') ? item.attach_long_form : '',
        attach_medical_verification: item.attach_medical_verification?.includes('https')
          ? item.attach_medical_verification
          : '',
        attach_national_registry: item.attach_national_registry?.includes('https') ? item.attach_national_registry : '',
      })),
    };

    const updateMedicalExaminersBody = {
      records: updateMedicalExaminersResults.map((item) => ({
        id: item.id,
        driver_id: driverId,
        expiry_date: item.expiry_date ? moment(item.expiry_date).format('YYYY-MM-DD') : '',
        attach_card: item.attach_card?.includes('https') ? item.attach_card : '',
        attach_long_form: item.attach_long_form?.includes('https') ? item.attach_long_form : '',
        attach_medical_verification: item.attach_medical_verification?.includes('https')
          ? item.attach_medical_verification
          : '',
        attach_national_registry: item.attach_national_registry?.includes('https') ? item.attach_national_registry : '',
      })),
    };

    const newMvrBody = {
      records: newMvr.map((item) => ({
        driver_id: driverId,
        mvr_check_results: item.mvr_check_results,
        mvr_check_date: item.mvr_check_date ? moment(item.mvr_check_date).format('YYYY-MM-DD') : '',
        mvr_next_check_date: item.mvr_next_check_date ? moment(item.mvr_next_check_date).format('YYYY-MM-DD') : '',
        attach_mvr_check_result: item.attach_mvr_check_result?.includes('https') ? item.attach_mvr_check_result : '',
      })),
    };

    const updateMvrBody = {
      records: updateMvr.map((item) => ({
        id: item.id,
        driver_id: driverId,
        mvr_check_results: item.mvr_check_results,
        mvr_check_date: item.mvr_check_date ? moment(item.mvr_check_date).format('YYYY-MM-DD') : '',
        mvr_next_check_date: item.mvr_next_check_date ? moment(item.mvr_next_check_date).format('YYYY-MM-DD') : '',
        attach_mvr_check_result: item.attach_mvr_check_result?.includes('https') ? item.attach_mvr_check_result : '',
      })),
    };

    const newDrugTestBody = {
      records: newDrugTests.map((item) => ({
        driver_id: driverId,
        type: item.type,
        reason: item.reason,
        drug_check_results: item.drug_check_results,
        drug_checked_date: item.drug_checked_date ? moment(item.drug_checked_date).format('YYYY-MM-DD') : '',
        attach_drug_check_result: item.attach_drug_check_result?.includes('https') ? item.attach_drug_check_result : '',
        drug_test_custom_form: item.drug_test_custom_form?.includes('https') ? item.drug_test_custom_form : '',
      })),
    };

    const updateDrugTestBody = {
      records: updateDrugTests.map((item) => ({
        id: item.id,
        driver_id: driverId,
        type: item.type,
        reason: item.reason,
        drug_check_results: item.drug_check_results,
        drug_checked_date: item.drug_checked_date ? moment(item.drug_checked_date).format('YYYY-MM-DD') : '',
        attach_drug_check_result: item.attach_drug_check_result?.includes('https') ? item.attach_drug_check_result : '',
        drug_test_custom_form: item.drug_test_custom_form?.includes('https') ? item.drug_test_custom_form : '',
      })),
    };

    const newCriminalCheckBody = {
      records: newCriminalChecks.map((item) => ({
        driver_id: driverId,
        criminal_check_results: item.criminal_check_results,
        criminal_check_date: item.criminal_check_date ? moment(item.criminal_check_date).format('YYYY-MM-DD') : '',
        attach_criminal_result: item.attach_criminal_result?.includes('https') ? item.attach_criminal_result : '',
      })),
    };

    const updateCriminalCheckBody = {
      records: updateCriminalChecks.map((item) => ({
        id: item.id,
        driver_id: driverId,
        criminal_check_results: item.criminal_check_results,
        criminal_check_date: item.criminal_check_date ? moment(item.criminal_check_date).format('YYYY-MM-DD') : '',
        attach_criminal_result: item.attach_criminal_result?.includes('https') ? item.attach_criminal_result : '',
      })),
    };

    const newPspBody = {
      records: newPsp.map((item) => ({
        driver_id: driverId,
        psp_check_date: item.psp_check_date ? moment(item.psp_check_date).format('YYYY-MM-DD') : '',
        attach_psp_check_result: item.attach_psp_check_result?.includes('https') ? item.attach_psp_check_result : '',
      })),
    };

    const updatePspBody = {
      records: updatePsp.map((item) => ({
        id: item.id,
        driver_id: driverId,
        psp_check_date: item.psp_check_date ? moment(item.psp_check_date).format('YYYY-MM-DD') : '',
        attach_psp_check_result: item.attach_psp_check_result?.includes('https') ? item.attach_psp_check_result : '',
      })),
    };

    const fmcsaDosPromises = newFmcsa.map((item) => {
      return mergeUploadedDocuments(item.attach_fmcsa_result);
    });

    const fmcsaDocUrls = await Promise.all(fmcsaDosPromises);

    const newFmcsaBody = {
      records: newFmcsa.map((item, index) => {
        return {
          driver_id: driverId,
          reason: item.reason,
          fmcsa_checked_date: item.fmcsa_checked_date ? moment(item.fmcsa_checked_date).format('YYYY-MM-DD') : '',
          fmcsa_next_check_date: item.fmcsa_next_check_date
            ? moment(item.fmcsa_next_check_date).format('YYYY-MM-DD')
            : null,
          attach_fmcsa_result: fmcsaDocUrls[index] || '',
        };
      }),
    };

    const fmcsaUpdaeDosPromises = updateFmcsa.map((item) => {
      return mergeUploadedDocuments(item.attach_fmcsa_result);
    });

    const fmcsaUpdateDocUrls = await Promise.all(fmcsaUpdaeDosPromises);

    const updateFmcsaBody = {
      records: updateFmcsa.map((item, index) => {
        return {
          id: item.id,
          driver_id: driverId,
          reason: item.reason,
          fmcsa_checked_date: item.fmcsa_checked_date ? moment(item.fmcsa_checked_date).format('YYYY-MM-DD') : '',
          fmcsa_next_check_date: item.fmcsa_next_check_date
            ? moment(item.fmcsa_next_check_date).format('YYYY-MM-DD')
            : null,
          attach_fmcsa_result: fmcsaUpdateDocUrls[index] || '',
        };
      }),
    };

    try {
      const promises = [];
      promises.push(createMedicalExaminers(driverId, newMedicalExaminersBody));
      if (newMvr?.[0]?.mvr_check_date) {
        promises.push(createDriverMvrRecords(driverId, newMvrBody));
      }
      if (newDrugTests?.[0]?.drug_checked_date) {
        promises.push(createDriverDrugTestRecords(driverId, newDrugTestBody));
      }
      if (newCriminalChecks?.[0]?.criminal_check_date) {
        promises.push(createDriverCriminalRecords(driverId, newCriminalCheckBody));
      }
      if (newPsp?.[0]?.psp_check_date) {
        promises.push(createDriverPsp(driverId, newPspBody));
      }
      promises.push(createDriverFmcsa(driverId, newFmcsaBody));
      promises.push(updateMedicalExaminers(updateMedicalExaminersBody));
      promises.push(updateDriverMvrRecords(updateMvrBody));
      promises.push(updateDriverDrugTestRecords(updateDrugTestBody));
      promises.push(updateDriverCriminalRecords(updateCriminalCheckBody));
      promises.push(updateDriverPsp(updatePspBody));
      promises.push(updateDriverFmcsa(updateFmcsaBody));
      await Promise.allSettled(promises);

      nextStep(values, null, `Medical Background has been ${stepsDone ? 'updated' : 'created'}`);
    } catch (e) {
      showError(getErrorMessage(e));
    } finally {
      setOnNextLoading(false);
    }
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 2,
    };

    saveFinishLater(driverId, body).then(() => {
      getDrivers();
      Cancel();
    });
  };

  useEffect(() => {
    getDriverMedicalAndBackground();
  }, []);

  const { use } = useTheme();
  return (
    <>
      <Modal.Body
        className='add-driver-modal-body driver-style-wrap'
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
      >
        {!!medicalData && (
          <MedicalAndBackgroundEdit
            onSave={onNextStep}
            driverId={driverId}
            medicalData={medicalData}
            applicantMedicalAndBackground={applicantMedicalAndBackground}
          />
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            <Button className='cancel-button ms-0' onClick={nextStep}>
              Skip
            </Button>
            {onNextLoading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <CustomButton
                title='Next Step'
                type='primary'
                disabled={onNextLoading}
                buttonProps={{ type: 'submit', form: 'medical-and-background-form', onClick: undefined }}
                styleTitle={{ fontSize: 12, fontWeight: 500 }}
                styleButton={{ padding: '6px 12px', margin: '0 16px 0 0' }}
              />
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default MedicalAndBackground;
