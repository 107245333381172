import { number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  photo_identification: string().trim().required('Required').nullable(),
  photo_identification_expiration: validator.requiredDate(),
  photo_identification_type: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  upload_identification_card: string().trim().required('Required').nullable(),
});
