import styled from 'styled-components';

export const SFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
