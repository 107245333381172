import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './MedicalAndBackgroundModal.css';
import license from 'assets/icons/drivers/license.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

export default function MedicalAndBackgroundModal({ ...props }) {
  const { use } = useTheme();
  return (
    <div className='modal-container'>
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        dialogClassName='modal-dialog-box-medical'
        backdropClassName='cancel-modal-backdrop'
      >
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
          closeButton
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            {props?.data?.name ? props?.data?.name : 'View Document'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap view-pdf-modal-main'
          style={{ backgroundColor: use(palette.white, palette.dark900) }}
        >
          <iframe
            src={
              props?.data?.imagePath
                ? props?.data?.imagePath
                : props?.data?.longFormPath
                ? props?.data?.longFormPath
                : license
            }
            style={{ height: '100%', width: '100%' }}
            title='Doc'
          />
        </Modal.Body>
        <Modal.Footer
          style={{
            background: use(palette.white, palette.dark800),
            color: use(palette.gray900, palette.gray50),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='incident-footer-btn-container'>
            <Button
              className='cancel-button'
              style={{
                backgroundColor: use(palette.white, palette.dark800),
                color: use(palette.gray700, palette.gray200),
                borderColor: use(palette.white, palette.boxShadow2),
              }}
              onClick={props.onHide}
            >
              Done
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
