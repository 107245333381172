import axios from '../services/axios';

export async function Shipment({
  page,
  itemsPerPage,
  sort,
  filtersType,
  filtersValue,
  remainingData,
  allFilterData,
  signal,
}) {
  const params = {
    page,
    itemsPerPage,
  };
  if (!allFilterData) {
    if (filtersValue) params[`filters[${filtersType}]`] = filtersValue;
    if (typeof remainingData === 'object') {
      Object.keys(remainingData).forEach((key) => {
        if (filtersType !== key) {
          const value = remainingData[key];
          if (value) params[`filters[${key}]`] = value;
        }
      });
    }
  } else {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      params[`filters[${key}]`] = value;
    });
  }

  if (sort?.length > 0) {
    sort.forEach((el) => {
      const arr = el.split(':');
      params[`${arr[0]}`] = arr[1];
    });
  }

  const { data } = await axios.get('customer/dispatched-shipments', {
    params,
    signal,
  });
  return data;
}

export async function getShipmentsTotal({
  page,
  itemsPerPage,
  sort,
  filtersType,
  filtersValue,
  remainingData,
  allFilterData,
  signal,
}) {
  const params = {
    page,
    itemsPerPage,
  };
  if (!allFilterData) {
    if (filtersValue) params[`filters[${filtersType}]`] = filtersValue;
    if (typeof remainingData === 'object') {
      Object.keys(remainingData).forEach((key) => {
        if (filtersType !== key) {
          const value = remainingData[key];
          if (value) params[`filters[${key}]`] = value;
        }
      });
    }
  } else {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      params[`filters[${key}]`] = value;
    });
  }

  if (sort?.length > 0) {
    sort.forEach((el) => {
      const arr = el.split(':');
      params[`${arr[0]}`] = arr[1];
    });
  }

  const { data } = await axios.get('customer/results/shipments', {
    params,
    signal,
  });
  return data;
}

export async function shipmentHistory({
  page,
  itemsPerPage,
  sort,
  filtersType,
  filtersValue,
  remainingData,
  allFilterData,
  signal,
}) {
  const params = {
    page,
    itemsPerPage,
  };
  if (!allFilterData) {
    if (filtersValue) params[`filters[${filtersType}]`] = filtersValue;
    if (typeof remainingData === 'object') {
      Object.keys(remainingData).forEach((key) => {
        if (filtersType !== key) {
          const value = remainingData[key];
          if (value) params[`filters[${key}]`] = value;
        }
      });
    }
  } else {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      params[`filters[${key}]`] = value;
    });
  }

  if (sort?.length > 0) {
    sort.forEach((el, index) => {
      const { type, sortBy } = el;
      params[`sort[${index}][${type}]`] = sortBy;
    });
  }

  const { data } = await axios.get('customer/shipments-history', {
    params,
    signal,
  });
  return data;
}

export async function getShipmentPopoverSettings({ type }) {
  const { data } = await axios.get('/table-settings', {
    params: {
      type,
    },
  });
  return data;
}

export async function getColumnsWidth(table_type) {
  const { data } = await axios.get('/table-columns/width', {
    params: {
      table_type,
    },
  });
  return data;
}

export async function updateColumnsWidth(body) {
  const { data } = await axios.post('/table-columns/width', body);
  return data;
}

export async function addNote(body) {
  const { data } = await axios.post('/notes/shipment', body);
  return data;
}

export async function addDocument(body) {
  const { data } = await axios.post('/document/add', body);
  return data;
}

export async function updateDocuments(body) {
  const { data } = await axios.post(`customer/shipments/shipment-documents`, body);
  return data;
}

export async function getStops(params) {
  const { data } = await axios.get(`/customer/shipments/stops`, { params });
  return data;
}

export async function getCharges(id) {
  const params = { shipment_id: id };
  const { data } = await axios.get(`/customer/shipments/billing`, { params });
  return data;
}

export async function getDocsFiles(shipment_id) {
  const params = { shipment_id };
  const { data } = await axios.get(`/customer/shipments/shipment-documents`, { params });
  return data;
}

export async function postShipmentDocument({ formData }) {
  const { data } = await axios.post(`/customer/shipments/shipment-document`, formData);
  return data;
}

export async function putShipmentDocument({ formData, id }) {
  const { data } = await axios.post(`/customer/shipments/shipment-document/${id}`, formData);
  return data;
}

export async function deleteShipmentDocument({ id }) {
  const { data } = await axios.delete(`/customer/shipments/shipment-document/${id}`);
  return data;
}

export async function getShipmentInfo(id) {
  const params = { shipment_id: id };
  const { data } = await axios.get(`/customer/shipments/info`, { params });
  return data;
}

export async function getShipmentNotes({ shipment_id, page, itemsPerPage, sort }) {
  const params = { page, itemsPerPage, sort };
  const { data } = await axios.get(`/notes/shipment/${shipment_id}`, { params });
  return data;
}

export async function getRecentActivity(params) {
  const { data } = await axios.get(`/customer/shipments/recent-activity`, { params });
  return data;
}

export async function deleteNote(id) {
  const { data } = await axios.delete(`/notes/shipment/${id}`);
  return data;
}

export async function getDocumentRequired(shipment_id) {
  const { data } = await axios.get(`/customer/shipments/required-documents`, {
    params: { shipment_id },
  });
  return data;
}

export async function UpdateStopPointStop({ params, id }) {
  const { data } = await axios.put(`/customer/shipment-stop/${id}`, params);
  return data;
}

export async function getShipmentsEvents({ shipment_id, params }) {
  const { data } = await axios.get(`customer/shipments/${shipment_id}/events`, { params });
  return data;
}

export async function UpdateShipmentBillingHeader({ params, id }) {
  const { data } = await axios.put(`/customer/shipments/billing/${id}`, params);
  return data;
}

export async function UpdateShipmentBillingCharge({ params, id }) {
  const { data } = await axios.put(`/customer/shipments/billing/charges/${id}`, params);
  return data;
}

export async function CreateShipmentBillingCharge(params) {
  const { data } = await axios.post(`/customer/shipments/billing/charges`, params);
  return data;
}

export async function deleteShipmentBillingCharge(id) {
  const { data } = await axios.delete(`/customer/shipments/billing/charges/${id}`);
  return data;
}

export async function deleteRevertShipment(params) {
  const { data } = await axios.delete(`/customer/shipments/dispatch/revert`, { data: params });
  return data;
}

export async function getDetention(params) {
  const { data } = await axios.get(`customer/all-shipments/detentions`, { params });
  return data;
}

export async function getDetentionsTotalAmount() {
  const { data } = await axios.get(`customer/all-shipments/detention-total`);
  return data;
}

export async function getTimeFrame() {
  const { data } = await axios.get(`tabs/time-frames`);
  return data;
}

export async function getAvailableDrivers() {
  const { data } = await axios.get(`drivers/not-assigned-equipment`);
  return data;
}

export async function applyDryRun(body) {
  const { data } = await axios.post(`/customer/shipments/apply-dry-run`, body);
  return data;
}

export async function applyPlannerDryRun(body) {
  const { data } = await axios.post(`/customer/shipments/planner-apply-dry-run`, body);
  return data;
}

export async function layover(body) {
  const { data } = await axios.post(`/customer/shipments/layover`, body);
  return data;
}

export async function swapDriverVehicle(params) {
  const { data } = await axios.post(`/customer/shipments/swap-driver-vehicle`, params);
  return data;
}

export async function checkOut(params) {
  const { data } = await axios.post(`/customer/shipments/check-out`, params);
  return data;
}

export async function checkIn(params) {
  const { data } = await axios.post(`/customer/shipments/check-in`, params);
  return data;
}

export async function copyShipment(params) {
  const { data } = await axios.post(`/customer/shipments/copy`, params);
  return data;
}

export async function hookOrDropTrailer(id, params) {
  const { data } = await axios.put(`/equipment/${id}`, params);
  return data;
}

export async function dropCargoTrailer(id, params) {
  const { data } = await axios.put(`equipment/equipment-status/${id}`, params);
  return data;
}

export async function addShipmentStop(params) {
  const { data } = await axios.post(`customer/shipment-stop`, params);
  return data;
}

export async function getAppliedPayments(params) {
  const { data } = await axios.get(`customer/shipments/billing/applied-payment`, { params });
  return data;
}

export async function deleteShipmentStop(id) {
  const { data } = await axios.delete(`customer/shipment-stop/${id}`, {});
  return data;
}

export async function swapShipmentStops(formData) {
  const { data } = await axios.post(`customer/shipment-stops/swap`, formData);
  return data;
}

export async function getSlaveShipments(params) {
  const { data } = await axios.get(`customer/shipments/slaves`, { params });
  return data;
}

export async function getDriverPays(params) {
  const { data } = await axios.get(`customer/shipments/driver-pays`, { params });
  return data;
}

export async function getCarrierPays(params) {
  const { data } = await axios.get(`customer/shipments/carrier-pays`, { params });
  return data;
}

export async function createDriverPay(body) {
  const { data } = await axios.post(`customer/shipments/driver-pays`, body);
  return data;
}

export async function createCarrierPay(body) {
  const { data } = await axios.post(`customer/shipments/carrier-pays`, body);
  return data;
}

export async function createMultipleDriverPay(body) {
  const { data } = await axios.post(`customer/shipments/base-driver-pays`, body);
  return data;
}

export async function deleteDriverPay(id) {
  const { data } = await axios.delete(`customer/shipments/driver-pays/${id}`, {});
  return data;
}

export async function deleteCarrierPay(id) {
  const { data } = await axios.delete(`customer/shipments/carrier-pays/${id}`, {});
  return data;
}

export async function updateDriverPay(body, id) {
  const { data } = await axios.put(`customer/shipments/driver-pays/${id}`, body);
  return data;
}

export async function updateCarrierPay(body, id) {
  const { data } = await axios.put(`customer/shipments/carrier-pays/${id}`, body);
  return data;
}

export async function getBillOfLadingDetails(params) {
  const { data } = await axios.get(`customer/shipments/bol-details`, { params });
  return data;
}

export async function deleteBillOfLading(id) {
  const { data } = await axios.delete(`customer/shipments/bill-of-lading/${id}`, {});
  return data;
}

export async function getBillOfLading(id) {
  const { data } = await axios.get(`customer/shipments/bill-of-lading/${id}`, {});
  return data;
}

export async function updateBillOfLading(id, body) {
  const { data } = await axios.post(`customer/shipments/bill-of-lading/${id}`, body);
  return data;
}

export async function getBillOfLadings(params) {
  const { data } = await axios.get(`customer/shipments/bill-of-lading`, { params });
  return data;
}

export async function createBillOfLading(body) {
  const { data } = await axios.post(`customer/shipments/bill-of-lading`, body);
  return data;
}

export async function changeDockStatus({ params, id }) {
  const { data } = await axios.put(`customer/shipment-stops/status/${id}`, params);
  return data;
}

export async function getMultipleShipmentsInfo(params) {
  const { data } = await axios.get(`customer/multiple-shipments/info`, { params });
  return data;
}

export async function updateRecurringLane(id, body) {
  const { data } = await axios.put(`customer/shipments/recurring-lane/${id}`, body);
  return data;
}

export async function getRecurringLaneCounts() {
  const { data } = await axios.get(`customer/shipments/recurring-lanes/status`);
  return data;
}

export async function getAllShipments() {
  const { data } = await axios.get(`customer/shipments-status-list`);
  return data;
}

// Recurring lane billing charges
export async function updateRecurringLaneBilling(id, body) {
  const { data } = await axios.put(`customer/shipments/recurring-lane/billing/${id}`, body);
  return data;
}

export async function updateRecurringLaneBillingCharge(id, body) {
  const { data } = await axios.put(`customer/shipments/recurring-lane/billing/charges/${id}`, body);
  return data;
}

export async function createRecurringLaneBillingCharge(body) {
  const { data } = await axios.post(`customer/shipments/recurring-lane/billing/charges`, body);
  return data;
}

export async function deleteRecurringLaneBillingCharge(id) {
  const { data } = await axios.delete(`customer/shipments/recurring-lane/billing/charges/${id}`);
  return data;
}

// Recurring lane stop points
export async function swapRecurringLaneStops(body) {
  const { data } = await axios.post(`customer/shipments/recurring-lane/stop/swap`, body);
  return data;
}

export async function updateRecurringLaneStop(id, body) {
  const { data } = await axios.put(`customer/shipments/recurring-lane/stop/${id}`, body);
  return data;
}

export async function addRecurringLaneStop(body) {
  const { data } = await axios.post(`customer/shipments/recurring-lane/stop`, body);
  return data;
}

export async function deleteRecurringLaneStop(id) {
  const { data } = await axios.delete(`customer/shipments/recurring-lane/stop/${id}`);
  return data;
}

export async function createQuickEquipment(body) {
  const { data } = await axios.post(`quick-equipment`, body);
  return data;
}

export async function getTrackingCost() {
  const { data } = await axios.get(`customer/cellular-tracking-setting`);
  return data;
}

export async function requestCellularTracking(body) {
  const { data } = await axios.post(`customer/shipments/cellular-tracking`, body);
  return data;
}

export async function getShipmentDataForTracking(dot, shipmentId) {
  const { data } = await axios.get(`get-shipment-details/${dot}/${shipmentId}`);
  return data;
}

export async function updateCellularTracking(body) {
  const { data } = await axios.post(`update-cellular-tracking-status`, body);
  return data;
}

export async function updateCellularTrackingLocation(body) {
  const { data } = await axios.post(`update-cellular-tracking-location`, body);
  return data;
}

export async function resendShipmentConfirmation(body) {
  const { data } = await axios.post(`customer/shipments/carrier/resend-confirmation`, body);
  return data;
}

export async function carrierHookOrDropTrailer(trailer_id, body) {
  const { data } = await axios.put(`equipment-status/trailer-updates/${trailer_id}`, body);
  return data;
}

export async function updateShipmentMiles(id, body) {
  const { data } = await axios.put(`/customer/shipment/${id}/miles`, body);
  return data;
}

export async function getShipmentCargo(params) {
  const { data } = await axios.get(`/customer/shipments/cargos`, { params });
  return data;
}

export async function createCargo(stopId, body) {
  const { data } = await axios.post(`/customer/shipments/stops/${stopId}/cargos`, body);
  return data;
}

export async function updateCargo(id, body) {
  const { data } = await axios.put(`/customer/shipments/stops/cargos/${id}`, body);
  return data;
}

export async function createMultipleCargo(stopId, body) {
  const { data } = await axios.post(`/customer/shipments/stops/${stopId}/cargos-bulk`, body);
  return data;
}

export async function updateMultipleCargo(body) {
  const { data } = await axios.put(`/customer/shipments/stops/cargos-bulk`, body);
  return data;
}

export async function deleteCargo(id) {
  const { data } = await axios.delete(`/customer/shipments/stops/cargos/${id}`);
  return data;
}

export async function createRecurringCargo(stopId, body) {
  const { data } = await axios.post(`/customer/shipments/recurring-lane/stops/${stopId}/cargos`, body);
  return data;
}

export async function updateRecurringCargo(id, body) {
  const { data } = await axios.put(`/customer/shipments/recurring-lane/stops/cargos/${id}`, body);
  return data;
}

export async function createMultipleRecurringCargo(stopId, body) {
  const { data } = await axios.post(`/customer/shipments/recurring-lane/stops/${stopId}/cargos-bulk`, body);
  return data;
}

export async function updateMultipleRecurringCargo(body) {
  const { data } = await axios.put(`/customer/shipments/recurring-lane/stops/cargos-bulk`, body);
  return data;
}

export async function deleteRecurringCargo(id) {
  const { data } = await axios.delete(`/customer/shipments/recurring-lane/stops/cargos/${id}`);
  return data;
}

export async function getAvailableCargo(params) {
  const { data } = await axios.get(`/customer/shipments/stops/available-cargos`, { params });
  return data;
}

export async function getSplits(params) {
  const { data } = await axios.get(`/customer/shipments/splits`, { params });
  return data;
}

export async function planAheadLane(laneId, body) {
  const { data } = await axios.post(`/customer/shipments/recurring-lane-plan-ahead/${laneId}`, body);
  return data;
}

export async function getDriverPlannedShipments(params) {
  const { data } = await axios.get(`/driver-planned-shipments`, { params });
  return data;
}

export async function shareEbol(body) {
  const { data } = await axios.post(`/customer/shipments/share-ebol`, body);
  return data;
}

export async function updateStopETA(stopId, body) {
  const { data } = await axios.put(`/customer/shipment-stop/eta/${stopId}`, body);
  return data;
}

export async function getStopEtaChanges(shipmentStopId, params) {
  const { data } = await axios.get(`/customer/shipment-stop/eta-changes/${shipmentStopId}`, { params });
  return data;
}

export async function createQuote(body) {
  const { data } = await axios.post(`/customer/shipment-quote`, body);
  return data;
}

export async function getQuotes(params, signal) {
  const { data } = await axios.get(`/customer/shipment-quote`, { params, signal });
  return data;
}

export async function getQuote(quoteId) {
  const { data } = await axios.get(`/customer/shipment-quote/${quoteId}`);
  return data;
}

export async function updateQuote(quoteId, body) {
  const { data } = await axios.put(`/customer/shipment-quote/${quoteId}`, body);
  return data;
}

export async function updateQuoteBilling(billingId, body) {
  const { data } = await axios.put(`/customer/shipment-quote/billing/${billingId}`, body);
  return data;
}

export async function createQuoteBillingCharge(body) {
  const { data } = await axios.post(`/customer/shipment-quote/billing-charges`, body);
  return data;
}

export async function updateQuoteBillingCharge(chargeId, body) {
  const { data } = await axios.put(`/customer/shipment-quote/billing-charges/${chargeId}`, body);
  return data;
}

export async function deleteQuoteBillingCharge(chargeId) {
  const { data } = await axios.delete(`/customer/shipment-quote/billing-charges/${chargeId}`);
  return data;
}

export async function createQuoteLineItem(body) {
  const { data } = await axios.post(`/customer/shipment-quote/line-items`, body);
  return data;
}

export async function updateQuoteLineItem(chargeId, body) {
  const { data } = await axios.put(`/customer/shipment-quote/line-items/${chargeId}`, body);
  return data;
}

export async function deleteQuoteLineItem(chargeId) {
  const { data } = await axios.delete(`/customer/shipment-quote/line-items/${chargeId}`);
  return data;
}

export async function createQuoteLeg(body) {
  const { data } = await axios.post(`/customer/shipment-quote/legs`, body);
  return data;
}

export async function updateQuoteLeg(legId, body) {
  const { data } = await axios.put(`/customer/shipment-quote/legs/${legId}`, body);
  return data;
}

export async function deleteQuoteLeg(legId) {
  const { data } = await axios.delete(`/customer/shipment-quote/legs/${legId}`);
  return data;
}

export async function createQuoteStop(body) {
  const { data } = await axios.post(`/customer/shipment-quote/stops`, body);
  return data;
}

export async function updateQuoteStop(stopId, body) {
  const { data } = await axios.put(`/customer/shipment-quote/stops/${stopId}`, body);
  return data;
}

export async function deleteQuoteStop(stopId) {
  const { data } = await axios.delete(`/customer/shipment-quote/stops/${stopId}`);
  return data;
}

export async function createQuoteCargo(stopId, body) {
  const { data } = await axios.post(`/customer/shipment-quote/stop-cargos/${stopId}`, body);
  return data;
}

export async function updateQuoteCargo(id, body) {
  const { data } = await axios.put(`/customer/shipment-quote/stop-cargos/${id}`, body);
  return data;
}

export async function createMultipleQuoteCargo(stopId, body) {
  const { data } = await axios.post(`/customer/shipment-quote/stop-cargos/${stopId}/cargos-bulk`, body);
  return data;
}

export async function updateMultipleQuoteCargo(body) {
  const { data } = await axios.put(`/customer/shipment-quote/stop/cargos-bulk`, body);
  return data;
}

export async function deleteQuoteCargo(id) {
  const { data } = await axios.delete(`/customer/shipment-quote/stop-cargos/${id}`);
  return data;
}

export async function getAvailableQuoteCargo(params) {
  const { data } = await axios.get(`/customer/shipment-quote/stop-cargos/available-cargos`, { params });
  return data;
}

export async function sendQuotes(body) {
  const { data } = await axios.post(`/customer/shipment-quote/send`, body);
  return data;
}

export async function getQuotePublic(hash, dot) {
  const { data } = await axios.get(`/customer/quote/${hash}`, { headers: { dot } });
  return data;
}

export async function updateQuotePublic(hash, dot, body) {
  const { data } = await axios.post(`/customer/quote/${hash}`, body, { headers: { dot } });
  return data;
}

export async function uploadQuoteDocument(body) {
  const { data } = await axios.post(`/quote/upload-document`, body);
  return data;
}

export async function getQuoteEvents(quoteId, params) {
  const { data } = await axios.get(`/customer/shipment-quote/${quoteId}/events`, { params });
  return data;
}

export async function acceptQuote(body) {
  const { data } = await axios.post(`/customer/shipment-quote/accept`, body);
  return data;
}

export async function calculateStopsDistance(body) {
  const { data } = await axios.post(`/customer/shipment-quote/calculate-distance`, body);
  return data;
}
