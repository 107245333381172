import React from 'react';
import { SWrapper } from './Map.styles';

const Map = () => {
  return (
    <SWrapper>
      <div id='alertViewMap' className='alert-map-wrapper' />
    </SWrapper>
  );
};

export default Map;
