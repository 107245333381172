import { useSelector } from 'react-redux';
import { useAuth } from 'context/auth.context';
import { ADDON_PLAN_ID } from 'utils/constants';

export const useHasBrokerage = () => {
  const accountStatus = JSON.parse(localStorage.getItem('accountStatus'));
  const { purchasedAddons } = useSelector((state) => state.root);
  const { value: userData } = useAuth();

  return (
    (userData?.user?.customer?.customer_type === 'carrier_broker' && accountStatus?.account_status === 'intrial') ||
    purchasedAddons?.some((i) => i.addon_plan?.addon?.plan_id === ADDON_PLAN_ID.BROKERAGE && i.status === 'Active')
  );
};
