import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import refresh from 'assets/icons/drivers/refresh.png';
import subRight from 'assets/icons/drivers/sub-right.png';
import upload from 'assets/icons/drivers/upload.png';
import cross from 'assets/icons/drivers/secondClose.svg';
import right from 'assets/icons/drivers/right.svg';
import add from 'assets/icons/drivers/add.png';
import { DatePicker } from 'common/Pickers';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import {
  DriverGroups,
  PositionAndApplication,
  uploadDriverDocument,
  AddStaffBasicDetail,
  DepartmentsEmail,
  UpdateStaffBasicDetail,
} from 'Api/Staff';
import { getApplicant } from 'Api/Applicants';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import CustomMultiSelect from 'common/CustomMultiSelect/CustomMultiSelect';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import ImageDropZone from 'components/ImgageDropZone/index';
import CustomizedSnackbars from 'components/toast/Toast';
import './AddStaffStyle.css';
import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import AddressFields from 'common/AddressFields';
import AddGroup from 'componentsV2/Group/AddGroup';
import { getPrevEmployment } from 'Api/PreviousEmployment';
import { validationBasicSchema } from '../ValidationSchemaStaff';

const AddStaffListStep1 = ({
  nextStep,
  showError,
  Cancel,
  staffId,
  basicDetails = {},
  stepsDone,
  basicDetailsStates = {},
  getStaff,
}) => {
  const showToaster = useShowToaster();
  const [uploadApplication, setUploadApplication] = useState('selectApplication');
  const [selectApplication, setSelectApplication] = useState([]);
  const [groups, setGroups] = useState([]);
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onNextloading, setOnNextloading] = useState(false);
  const [hireDate, setHireDate] = useState(new Date());
  const [department, setDepartment] = useState([]);
  const [cropModalShow, setCropModalShow] = useState();
  const [cropper, setCropper] = useState();
  const [image, setImage] = useState();
  const [driverLicense, setDriverLicense] = useState([
    { name: 'License', id: 1 },
    { name: 'Passport', id: 2 },
    { name: 'ID', id: 3 },
  ]);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const {
    fname,
    lname,
    title,
    department_id,
    hire_date,
    address,
    address2,
    country,
    state,
    city,
    dob,
    zipcode,
    email,
    phone_ext,
    phone_number,
    group_id,
    upload_application,
    applicant,
    photo_identification,
    photo_identification_expiration,
    photo_identification_type,
    upload_identification_card,
    emergency_contact_number,
    emergency_contact_last_name,
    emergency_contact_first_name,
  } = basicDetails || {};

  const formik = useFormik({
    initialValues: {
      fname: fname || '',
      lname: lname || '',
      title: title || '',
      upload_application: upload_application || '',
      department_id: department_id || '',
      hire_date: hire_date ? moment(hire_date).toDate() : new Date(),
      dob: dob ? moment(dob).toDate() : null,
      address: address || '',
      address2: address2 || '',
      country: country || '',
      state: state || '',
      city: city || '',
      zipcode: zipcode || '',
      email: email || '',
      phone_ext: phone_ext || '',
      phone_number: phone_number || '',
      group_id: group_id || [],
      photo_identification: photo_identification || '',
      photo_identification_expiration: photo_identification_expiration
        ? moment(photo_identification_expiration).toDate()
        : new Date(),
      photo_identification_type: photo_identification_type || null,
      upload_identification_card: upload_identification_card || '',
      emergency_contact_first_name: emergency_contact_first_name || '',
      emergency_contact_last_name: emergency_contact_last_name || '',
      emergency_contact_number: emergency_contact_number || '',
      applicant: applicant || '',
    },
    validationSchema: validationBasicSchema,
  });

  const onApplicantSelect = async (id) => {
    try {
      const { data } = await getApplicant(id);
      const fullName = data.full_name.split(' ');
      const address = data.addresses?.[0];

      formik.setValues((prevState) => ({
        ...prevState,
        applicant: id,
        phone_number: `+${data?.phone_no || ''}`,
        address: address?.address1 || '',
        address2: address?.address2 || '',
        zipcode: address?.zipcode || '',
        country: address?.country || null,
        state: address?.state || null,
        city: address?.city || null,
        dob: new Date(data.date_of_birth),
        email: data.email,
        fname: fullName[0],
        lname: fullName.length >= 2 ? fullName[1] : '',
        emergency_contact_first_name: data.emergency_contact_first_name,
        emergency_contact_last_name: data.emergency_contact_last_name,
        emergency_contact_number: `+${data?.emergency_contact_number || ''}`,
        photo_identification_type: data.driving_licence?.photo_id_type?.toString() || '', // Check
        photo_identification: data.driving_licence?.photo_id_number || '',
        upload_identification_card: data.driving_licence?.photo_id_attachment || '',
        photo_identification_expiration: new Date(data.driving_licence?.photo_id_expiration) || null,
      }));
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const onNextStep = async () => {
    setOnNextloading(true);
    const tempArray = [];
    formik.values.group_id.map((item) => tempArray.push(String(item?.id)));
    const staffInfo = {
      ...formik.values,
      address1: formik.values?.address,
      country_id: formik.values?.country?.id,
      state_id: formik.values?.state?.id,
      city_id: formik.values?.city?.id,
      zip: formik.values?.zipcode,
      hire_date: formik.values?.hire_date ? moment(formik.values?.hire_date).format('YYYY-MM-DD') : '',
      dob: formik.values?.dob ? moment(formik.values?.dob).format('YYYY-MM-DD') : '',
      photo_identification_expiration: formik.values?.photo_identification_expiration
        ? moment(formik.values?.photo_identification_expiration).format('YYYY-MM-DD')
        : '',
      group_id: tempArray,
      photo_identification_type: Number(formik.values?.photo_identification_type),
      application_id: formik.values?.applicant || null,
    };
    if (stepsDone) {
      UpdateStaffBasicDetail(staffInfo, staffId)
        .then(async () => {
          let previousEmployment;

          if (formik.values?.applicant) {
            const { data } = await getPrevEmployment({
              id: staffId,
              userType: 'staff',
              params: {
                page: 1,
                itemsPerPage: 10000,
              },
            });
            previousEmployment = data;
          }
          setOnNextloading(false);
          nextStep(
            {
              formikData: formik.values,
              stateData: { hireDate, uploadApplication, selectApplication, groups, department, driverLicense },
              staffId,
              previousEmployment,
            },
            `Staff has been ${stepsDone ? 'updated' : 'created'}`
          );
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    } else {
      AddStaffBasicDetail(staffInfo)
        .then(async (res) => {
          let previousEmployment;

          if (formik.values?.applicant) {
            const { data } = await getPrevEmployment({
              id: res?.data?.staff_user_id,
              userType: 'staff',
              params: {
                page: 1,
                itemsPerPage: 10000,
              },
            });
            previousEmployment = data;
          }

          setOnNextloading(false);
          nextStep(
            {
              formikData: formik.values,
              stateData: { hireDate, uploadApplication, selectApplication, groups, department, driverLicense },
              staffId: res?.data?.staff_user_id,
              previousEmployment,
            },
            `Staff has been ${stepsDone ? 'updated' : 'created'}`
          );
        })
        .catch((error) => {
          setOnNextloading(false);
          showError(getErrorMessage(error));
        });
    }
  };

  const onQuickAdd = () => {
    setLoading(true);
    const tempArray = [];
    formik.values.group_id.map((item) => tempArray.push(String(item?.id)));
    const staffInfo = {
      ...formik.values,
      address1: formik.values?.address,
      country_id: formik.values?.country?.id,
      state_id: formik.values?.state?.id,
      city_id: formik.values?.city?.id,
      zip: formik.values?.zipcode,
      hire_date: formik.values?.hire_date ? moment(formik.values?.hire_date).format('YYYY-MM-DD') : '',
      dob: formik.values?.dob ? moment(formik.values?.dob).format('YYYY-MM-DD') : '',
      photo_identification_expiration: formik.values?.photo_identification_expiration
        ? moment(formik.values?.photo_identification_expiration).format('YYYY-MM-DD')
        : '',
      group_id: tempArray,
    };

    AddStaffBasicDetail(staffInfo)
      .then(() => {
        setShowMessage({
          message: 'Staff has been created',
          visible: true,
          type: 'success',
        });
        getStaff();
        setTimeout(() => {
          Cancel();
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        setShowMessage({
          message: getErrorMessage(error),
          visible: true,
          type: 'error',
        });
      });
  };

  const { use } = useTheme();

  useEffect(() => {
    if (stepsDone) {
      const { uploadApplication, selectApplication, groups, hireDate, department, driverLicense } = basicDetailsStates;
      setSelectApplication(selectApplication);
      setGroups(groups);
      setUploadApplication(uploadApplication);
      setHireDate(hireDate);
      setDepartment(department);
      setDriverLicense(driverLicense);

      return;
    }
    formik.setFieldValue('fname', '');

    DepartmentsEmail().then((res) => {
      setDepartment(res?.data || []);
    });

    const body = {
      itemsPerPage: 1000,
      status_id: 3,
      'filters[removed_applied]': 1,
      'filters[applicant_type_id][]': 3,
    };

    PositionAndApplication(body).then((res) => {
      setSelectApplication(res?.data || []);
    });

    DriverGroups().then((res) => {
      setGroups(res.data);
    });
  }, []);

  const imageSelected = (file) => {
    setCropModalShow(true);
    const reader = new FileReader();
    reader.onload = () => {
      setImage({ blob: reader?.result });
    };
    reader.readAsDataURL(file[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadLicenseDocument([blob], 'upload_identification_card');
        setCropModalShow(false);
      });
    }
  };

  const uploadLicenseDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], staffId).then((res) => {
      formik.setFieldValue(propertyName, res.data.document_path);
    });
  };

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], staffId)
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const saveGroup = (val) => {
    setGroups([...groups, val]);
  };

  const handleFieldChange = (event, value) => {
    if (event?.target) {
      const { name, value } = event.target;
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(event, value);
    }
  };

  return (
    <Form style={{ borderColor: use(palette.white, palette.darkborder) }}>
      <Modal.Body
        className='add-driver-modal-body staff-basic-detail'
        style={{ backgroundColor: use(palette.gray0, palette.dark800) }}
      >
        {!!cropModalShow && (
          <ImageCropModal
            show={cropModalShow}
            onHide={() => setCropModalShow(false)}
            image={image}
            getCropData={getCropData}
            setCropper={setCropper}
          />
        )}
        <div className='basic-container driver-style-wrap'>
          <div style={{ marginRight: '20px', paddingTop: '0px' }}>
            <p
              className='text-style text-focus'
              style={{
                color: use('black', palette.gray200),
                marginLeft: '0',
              }}
            >
              Application
            </p>
            <div className='flexbox mt-4' role='group' aria-labelledby='my-radio-group'>
              <div style={{ marginRight: '20px' }}>
                <input
                  type='radio'
                  name='ApplicationList'
                  defaultChecked
                  checked={uploadApplication === 'selectApplication'}
                  onClick={() => setUploadApplication('selectApplication')}
                />
                <span className='text-style' style={{ color: use('black', palette.gray200) }}>
                  From Application Inbox
                </span>
                <img src={refresh} alt='refresh' />
              </div>
              <div>
                <input
                  type='radio'
                  name='ApplicationList'
                  checked={uploadApplication === 'uploadApplication'}
                  onClick={() => setUploadApplication('uploadApplication')}
                />
                <span className='text-style' style={{ color: use('black', palette.gray200) }}>
                  Upload Application
                </span>
              </div>
            </div>
            {uploadApplication === 'selectApplication' ? (
              <div className='flexbox'>
                <img src={subRight} className='sub-right-icon' alt={subRight} />
                <CustomSelect
                  options={selectApplication}
                  defaultValue='Select Applicant...'
                  value={formik.values.applicant}
                  styles={{ width: '357px' }}
                  onChange={onApplicantSelect}
                  propertyName='full_name'
                />
              </div>
            ) : formik?.values?.upload_application ? (
              <div className='flexbox justify-content-center '>
                <p
                  className='attachment-wrapper position-relative'
                  style={{ backgroundColor: use('#f0f1fa', '#20223F'), position: 'relative', left: '-220px' }}
                >
                  <img src={right} alt='attachment' className='attachment-icon' />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <img
                    src={cross}
                    alt='cross'
                    className='upload-cancel-icon'
                    onClick={() => formik.setFieldValue('upload_application', null)}
                  />
                </p>
              </div>
            ) : (
              <div className='flexbox justify-content-center '>
                <div className='d-flex' style={{ position: 'relative', left: '-103px' }}>
                  <div className='d-flex align-item-center'>
                    <img src={subRight} className='sub-right-icon' alt={subRight} />
                  </div>
                  <div
                    className='upload-document ms-5'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                      position: 'relative',
                      left: '-43px',
                    }}
                  >
                    <label htmlFor='myFile' className='text-style'>
                      <span className='upload-icon me-1'>
                        <img src={upload} alt='upload' />
                      </span>
                      <span
                        style={{
                          color: '#4F5AED',
                        }}
                      >
                        Upload document
                      </span>{' '}
                      or drag and drop
                    </label>
                    <ImageDropZone
                      onDrop={(file) => uploadDocument(file, 'upload_application')}
                      width='100%'
                      height='100%'
                      name='small'
                      deletable={false}
                      customStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                      accept={['application/pdf']}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <p className='driver-type-text text-focus' style={{ color: use('black', palette.gray200) }}>
              Staff Details
            </p>
          </div>
          <div className='d-flex'>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                First Name <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='fname'
                    id='fname'
                    className='input-field'
                    placeholder='Wilson'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.fname}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.fname && formik.errors.fname ? (
                  <span className='error-message-staff'>{formik.errors.fname}</span>
                ) : null}
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Last Name <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name name-wrap'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='lname'
                    id='lname'
                    className='input-field'
                    placeholder='Lubin'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.lname}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.lname && formik.errors.lname ? (
                  <span className='error-message-staff'>{formik.errors.lname}</span>
                ) : null}
              </div>
            </div>
            <div className='main-content-wrapper me-0'>
              <p className='text-style' style={{ marginLeft: '0', color: use(palette.gray700, palette.gray200) }}>
                Job Title <span className='required'>*</span>
              </p>
              <div className='d-flex '>
                <div className='flex-col'>
                  <div
                    className='selector-name kastum-gavno'
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <input
                      type='text'
                      name='title'
                      id='title'
                      className='input-field'
                      placeholder='Manager'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                      }}
                    />
                  </div>
                </div>
                <div className='flex-col'>
                  <div style={{ marginRight: '20px' }}>
                    <div className='custom-select-wrap-contain' style={{ width: '200px' }}>
                      <CustomSelect
                        defaultValue='Select Department'
                        options={department?.map((item) => item)} // deaprtment will be change
                        onChange={(value) => {
                          formik.setFieldValue('department_id', value === 'Select Department' ? '' : value);
                        }}
                        styles={{
                          borderBottomLeftRadius: '0px',
                          borderTopLeftRadius: '0px',
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.department_id}
                        id='department_id'
                        name='department_id'
                        patchProperty='id'
                        propertyName='department_name'
                      />
                    </div>
                    {formik.touched.department_id && formik.errors.department_id ? (
                      <span className='error-message-staff'>{formik.errors.department_id}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              {formik.touched.title && formik.errors.title ? (
                <span className='error-message-staff'>{formik.errors.title}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <DatePicker
                name='dob'
                width='150px'
                label='Date of Birth'
                value={formik.values.dob}
                onBlur={formik.handleBlur}
                onChange={(date) => formik.setFieldValue('dob', date)}
                disableFuture
              />
              {formik.touched?.dob && formik.errors?.dob ? (
                <span className='error-message'>{formik.errors.dob}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <DatePicker
                name='hire_date'
                width='150px'
                label='Hire Date'
                onBlur={formik.handleBlur}
                value={formik.values.hire_date}
                onChange={(date) => {
                  setHireDate(date);
                  formik.setFieldValue('hire_date', date);
                }}
                disableFuture
              />
              {formik.touched?.hire_date && formik.errors?.hire_date ? (
                <span className='error-message'>{formik.errors.hire_date}</span>
              ) : null}
            </div>
          </div>
          <div className='d-flex mt-2 mb-2'>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Photo Identification #
              </p>
              <div
                className='driver-license'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  className='input-field'
                  name='photo_identification'
                  value={formik.values.photo_identification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                />
              </div>
              {formik.touched.photo_identification && formik.errors.photo_identification ? (
                <span className='error-message-staff'>{formik.errors.photo_identification}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <DatePicker
                width='220px'
                label='Photo Identification Expiration'
                name='photo_identification_expiration'
                onBlur={formik.handleBlur}
                value={formik.values.photo_identification_expiration}
                onChange={(date) => formik.setFieldValue('photo_identification_expiration', date)}
              />
              {formik.touched.photo_identification_expiration && formik.errors.photo_identification_expiration ? (
                <span className='error-message-staff'>{formik.errors.photo_identification_expiration}</span>
              ) : null}
            </div>

            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Photo Identification Type
              </p>
              <div className='custom-select-wrap-contain'>
                <CustomSelect
                  defaultValue='Select Identification Type..'
                  options={driverLicense}
                  onChange={(value) => formik.setFieldValue('photo_identification_type', value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.photo_identification_type}
                  id='photo_identification_type'
                  name='photo_identification_type'
                  // patchProperty="name"
                />
              </div>
              {formik.touched.photo_identification_type && formik.errors.photo_identification_type ? (
                <span className='error-message-staff'>{formik.errors.photo_identification_type}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style text-focus'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Upload Identification Card
              </p>
              {formik?.values?.upload_identification_card ? (
                <p className='attachment-wrapper' style={{ backgroundColor: use('#f0f1fa', '#20223F') }}>
                  <img src={right} alt='attachment' className='attachment-icon' />
                  <span className='text-style' style={{ color: '#38A06C' }}>
                    File Uploaded
                  </span>
                  <img
                    src={cross}
                    alt='cross'
                    className='upload-cancel-icon'
                    onClick={() => formik.setFieldValue('upload_identification_card', null)}
                  />
                </p>
              ) : (
                <div
                  className='upload-selector'
                  style={{
                    position: 'relative',
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <label htmlFor='myFile' className='text-style'>
                    <span className='upload-icon'>
                      <img src={upload} alt='upload' />
                    </span>
                    <span
                      style={{
                        color: '#4F5AED',
                      }}
                    >
                      Upload Identification Card
                    </span>
                  </label>
                  <ImageDropZone
                    onDrop={(file) => imageSelected(file)}
                    width='100%'
                    height='100%'
                    name='small'
                    deletable={false}
                    customStyle={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                    accept={['application/pdf', 'image/jpeg', 'image/png']}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='mt-4' style={{ width: '814px' }}>
            <AddressFields values={formik.values} handleChange={handleFieldChange} isOneRow required={false} />
          </div>
          <div className='d-flex mt-3'>
            <div className='flex-col' style={{ marginRight: '10px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Phone Number <span className='required'>*</span>
              </p>
              <div className='d-flex'>
                <div
                  className='phone-number '
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <PhoneInput
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                    addInternationalOption={false}
                    className='input-field-phone input-field-phone--staff'
                    value={formik.values.phone_number}
                    // countries={whitelist}
                    onChange={(val) => formik.setFieldValue('phone_number', val)}
                    onBlur={() => formik.setTouched({ ...(formik.touched || {}), phone_number: true })}
                    defaultCountry='US'
                    placeholder='(555) 555-1234'
                  />
                </div>
              </div>
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <span className='error-message-staff'>{formik.errors.phone_number}</span>
              ) : null}
            </div>
            <div className='flex-col'>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Email <span className='required'>*</span>
              </p>
              <div
                className='driver-license staff-email-id-container'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              >
                <input
                  type='text'
                  className='input-field'
                  name='email'
                  id='email'
                  placeholder='Email'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                  }}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <span className='error-message-staff'>{formik.errors.email}</span>
              ) : null}
            </div>
          </div>

          <div className='d-flex mt-2'>
            <div className='flex-col' style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Emergency contact phone number
              </p>
              <div className='d-flex'>
                <div
                  className='phone-number '
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <PhoneInput
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                    addInternationalOption={false}
                    className='input-field-phone input-field-phone--staff-emergency'
                    value={formik.values.emergency_contact_number}
                    onChange={(val) => formik.setFieldValue('emergency_contact_number', val)}
                    onBlur={() => formik.setTouched({ ...(formik.touched || {}), emergency_contact_number: true })}
                    defaultCountry='US'
                    placeholder='(555) 555-1234'
                  />
                </div>
              </div>
              {formik.touched.emergency_contact_number && formik.errors.emergency_contact_number ? (
                <span className='error-message-staff'>{formik.errors.emergency_contact_number}</span>
              ) : null}
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Emergency contact first name
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name driver-license'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='emergency_contact_first_name'
                    id='emergency_contact_first_name'
                    className='input-field'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.emergency_contact_first_name}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.emergency_contact_first_name && formik.errors.emergency_contact_first_name ? (
                  <span className='error-message-staff'>{formik.errors.emergency_contact_first_name}</span>
                ) : null}
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Emergency contact last name
              </p>
              <div className='d-flex flex-column'>
                <div
                  className='selector-name driver-license'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                >
                  <input
                    type='text'
                    name='emergency_contact_last_name'
                    id='emergency_contact_last_name'
                    className='input-field'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.emergency_contact_last_name}
                    style={{
                      backgroundColor: use(palette.white, palette.dark600),
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  />
                </div>
                {formik.touched.emergency_contact_last_name && formik.errors.emergency_contact_last_name ? (
                  <span className='error-message-staff'>{formik.errors.emergency_contact_last_name}</span>
                ) : null}
              </div>
            </div>
          </div>

          <div
            className='d-flex   mt-4 justify-content-between align-items-center'
            style={{ marginTop: '4px', width: '300px' }}
          >
            <p
              className='driver-type-text '
              style={{
                color: use(palette.gray700, palette.gray200),
                marginTop: '20px',
              }}
            >
              Add Tag(s)
            </p>
            <p
              className='text-style choose-file'
              style={{
                color: use(palette.gray700, palette.gray200),
                marginTop: '10px',
                marginbottom: '0px',
                marginLeft: '5px',
              }}
              onClick={() => setOpenAddGroup(true)}
            >
              <img src={add} className='' alt='add' /> New Tags
            </p>
          </div>
          <div style={{ marginRight: '12px' }}>
            <div className='custom-select-wrap-contain-sub'>
              <CustomMultiSelect
                placeholder='Select tags'
                selectedValues={formik.values.group_id}
                formik={formik}
                patchProperty='group_id'
                propertyName='group_name'
                options={groups}
              />
            </div>
            <div style={{ marginRight: '12px' }} className='d-flex justify-content-center align-items-center' />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        {!staffId && (
          <Button type='button' className='cancel-button' onClick={Cancel}>
            Cancel
          </Button>
        )}
        <div className='Basic-detail-footer-btn'>
          {!staffId &&
            (loading ? (
              <div className='me-4'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button type='button' onClick={onQuickAdd} className='quick-add' disabled={!formik.isValid}>
                Quick Add
              </Button>
            ))}
          {onNextloading ? (
            <div className='ms-2'>
              <CircularProgress size={30} />
            </div>
          ) : (
            <button
              type='button'
              onClick={onNextStep}
              className='next-step-btn'
              disabled={!formik.isValid}
              style={{ opacity: formik.isValid ? 1 : 0.7 }}
            >
              Next Step
            </button>
          )}
        </div>
      </Modal.Footer>
      {!!openAddGroup && <AddGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={saveGroup} />}
      {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
    </Form>
  );
};

export default AddStaffListStep1;
