import uuid from 'react-uuid';

export const STATUSES = [
  {
    title: 'Available',
    id: 1,
    value: 'status_id',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Not Available',
    id: 2,
    value: 'status_id',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'On Shipment',
    id: 3,
    value: 'status_id',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Out Of Service',
    id: 4,
    value: 'status_id',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Terminated',
    id: 5,
    value: 'status_id',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Quit',
    id: 6,
    value: 'status_id',
    type: 'checkbox',
    key: uuid(),
  },
];

export const CAN_TRAVEL_TO = [
  {
    title: 'USA',
    id: 1,
    value: 'can_travel_usa',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Canada',
    id: 2,
    value: 'can_travel_canada',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'Mexico',
    id: 3,
    value: 'can_travel_mexico',
    type: 'checkbox',
    key: uuid(),
  },
];

export const APPROVED_FOR = [
  {
    title: 'TSA',
    id: 1,
    value: 'has_tsa',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'TWIC',
    id: 2,
    value: 'has_twic',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'FAST',
    id: 3,
    value: 'has_fast',
    type: 'checkbox',
    key: uuid(),
  },
  {
    title: 'HAZMAT',
    id: 4,
    value: 'has_hazmat',
    type: 'checkbox',
    key: uuid(),
  },
];

export const COMPENSATION_TYPES = [
  {
    title: 'Per Mile',
    id: 1,
    value: 'mile',
    key: uuid(),
  },
  {
    title: 'By Percentage',
    id: 2,
    value: 'percentage',
    key: uuid(),
  },
  {
    title: 'Per Hour',
    id: 3,
    value: 'per_hour',
    key: uuid(),
  },
  {
    title: 'Flat Rate',
    id: 4,
    value: 'flat_rate',
    key: uuid(),
  },
];

export const DUTY_STATUSES = [
  { id: 1, title: 'On Duty', value: 'onDuty', key: uuid() },
  { id: 2, title: 'Driving', value: 'driving', key: uuid() },
  { id: 3, title: 'Off Duty', value: 'offDuty', key: uuid() },
];

export const DRIVER_TYPE = [
  { id: 1, title: 'Company', value: 'company', key: uuid() },
  { id: 2, title: 'Owner Operator', value: 'owner', key: uuid() },
];
