import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import Divider from 'common/Divider';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { SFlexWrapper, SReasonWrapper, SWrapper } from './DisputeReason.styles';

const DisputeReason = ({ settlement }) => {
  const { formatDateTime } = useDateFormat();
  const { use } = useTheme();

  return (
    <SWrapper>
      <SFlexWrapper>
        <WarningIcon />
        <Typography variant='s1' style={{ color: use(palette.red700, palette.red200) }}>
          {settlement?.user?.fname} {settlement?.user?.lname} has disputed on{' '}
          {formatDateTime(settlement?.disputed_date)}
        </Typography>
      </SFlexWrapper>
      <Divider color={palette.red50} />
      <SReasonWrapper>
        <Typography variant='button2' style={{ color: use(palette.red500, palette.red200), marginBottom: '4px' }}>
          Reason
        </Typography>
        <Typography variant='b2' style={{ color: use(palette.red500, palette.red200) }}>
          {settlement?.dispute_note}
        </Typography>
      </SReasonWrapper>
    </SWrapper>
  );
};

export default DisputeReason;
