import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { months, palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { createCustomerHoliday, getHolidays, updateCustomerHoliday } from 'Api/Customers';
import { customerStopPoint } from 'Api/Planner';
import Autocomplete from 'common/Autocomplete';
import validationSchema from './ValidationSchema';
import sm from './AddHolidayModal.module.css';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomButton from '../CustomButton/CustomButton';

const AddHolidayModal = ({ customerHoliday, onClose, onSuccess }) => {
  const { use } = useTheme();
  const [stopPoints, setStopPoints] = useState(
    customerHoliday ? customerHoliday?.holiday_customers?.[0]?.holiday_stop_points?.map((item) => item.stop_point) : []
  );
  const [stopPointsList, setStopPointList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const formik = useFormik({
    initialValues: {
      holiday: customerHoliday || null,
      apply_to_stop_points: customerHoliday
        ? !!customerHoliday?.holiday_customers?.[0]?.holiday_stop_points?.length
        : false,
    },
    validationSchema,
  });
  const { id } = useParams();

  const styles = useMemo(() => {
    return {
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const getHolidayLogData = async () => {
    try {
      const holidays = await getHolidays();
      if (holidays && holidays?.data) {
        const days = holidays.data.map((el) => ({
          ...el,
          id: el.id,
          key: el.id,
          label: el?.holiday_name,
        }));
        setHolidayList([...days]);
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getHolidayLogData();
    getFilterStopPointData();
  }, []);

  const onSubmit = () => {
    if (formik.values.holiday) {
      const holidayStopPoints = stopPoints.filter((stopPoint) => stopPoint !== '');

      if (!customerHoliday) {
        createCustomerHoliday(formik.values, holidayStopPoints, id).then((res) => {
          if (res) {
            onSuccess();
            onClose();
          }
        });
      } else {
        const body = {
          holiday_id: formik.values.holiday.id,
          stop_point_ids: formik.values.apply_to_stop_points ? holidayStopPoints.map((item) => item.id) : [],
        };

        updateCustomerHoliday(customerHoliday?.holiday_customers?.[0]?.id, body).then((res) => {
          if (res) {
            onSuccess();
            onClose();
          }
        });
      }
    }
  };

  const getFilterStopPointData = useCallback(() => {
    customerStopPoint().then((res) => {
      if (res && res?.data) {
        const stopPoints = res.data.map((el) => ({
          ...el,
          id: el.id,
          key: el.id,
          name: el?.location_name,
        }));
        setStopPointList([...stopPoints]);
      }
    });
  }, []);

  return (
    <>
      <Modal.Header
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Modal.Title className='document-modal' id='contained-modal-title-vcenter'>
          <h3 className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
            {customerHoliday ? 'Update Holiday' : 'Add Holiday'}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='driver-style-wrap'
        style={{ backgroundColor: use(palette.gray0, palette.dark800), padding: '16px 46px 16px 16px' }}
      >
        <div className='basic-container driver-style-wrap'>
          <div className='d-flex flex-column'>
            <div>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Holiday <span className='required'>*</span>
              </p>
              <div className='d-flex flex-column' style={{ width: '100%' }}>
                <Autocomplete
                  required
                  name='holiday'
                  labelKey='holiday_name'
                  options={holidayList}
                  value={formik.values.holiday}
                  onChange={(e, val) => {
                    formik.setFieldValue('holiday', val);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                {formik.touched.holiday && formik.errors.holiday ? (
                  <span className={sm['tab-error-message']}>{formik.errors.holiday}</span>
                ) : null}
              </div>
            </div>
            {formik.values.holiday && (
              <div style={{ marginTop: '20px' }}>
                <p
                  className='text-style'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    marginLeft: '0',
                  }}
                >
                  {formik.values.holiday.day_of_year} of {months[formik.values.holiday.month_of_year]}
                </p>
              </div>
            )}
            <div className='d-flex align-items-center' style={{ marginTop: '20px' }}>
              <CustomCheckbox
                type='switch'
                checked={!!formik.values.apply_to_stop_points}
                onChange={(value) => {
                  formik.setFieldValue('apply_to_stop_points', value);
                  if (!value) {
                    setStopPoints([]);
                  } else {
                    setStopPoints(['']);
                  }
                }}
                value={formik.values.apply_to_stop_points}
              />
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginRight: '10px',
                }}
              >
                Apply to Stop Point(s)
              </p>
            </div>
            {formik.values.apply_to_stop_points && (
              <div className='d-flex flex-column gap-2'>
                {stopPoints.map((point, index) => {
                  return (
                    <div>
                      <div className='d-flex align-items-center' style={{ width: '100%' }}>
                        <Autocomplete
                          required
                          name='stopPoints'
                          labelKey='location_name'
                          options={stopPointsList}
                          value={stopPoints[index]}
                          onChange={(e, val) => {
                            setStopPoints((prevState) =>
                              prevState.map((item, idx) => {
                                return index === idx ? val : item;
                              })
                            );
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          disabled={!stopPointsList}
                        />
                        {stopPoints.length > 1 && index !== 0 ? (
                          <DeleteIcon
                            width={12}
                            height={12}
                            fill={palette.indigo500}
                            style={styles.deleteIcon}
                            onClick={() => setStopPoints(stopPoints.filter((item, idx) => idx !== index))}
                          />
                        ) : (
                          <span style={{ width: 28 }} />
                        )}
                      </div>
                    </div>
                  );
                })}
                <CustomButton
                  type='secondary'
                  title='Add Another'
                  styleTitle={styles.addAnotherTitle}
                  styleButton={styles.addAnotherButton}
                  onClick={() => setStopPoints([...stopPoints, ''])}
                  leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.white, palette.darkborder),
        }}
      >
        <div className='footer-btn-container'>
          <Button className='cancel-button' onClick={onClose}>
            Cancel
          </Button>
          <Button className='next-step' type='submit' onClick={onSubmit}>
            {customerHoliday ? 'Update Holiday' : 'Add Holiday'}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default AddHolidayModal;
