import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import dingSound from 'assets/ding.mp3';
import { ReactComponent as CheckIcon } from 'assets/icons/checkGreen.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { createBankAccountTransaction, updateBankAccountTransaction } from 'Api/Accounts';

import AddPayee from 'pages/Accounting/Accounts/components/AddPayee';
import { getAccountToUpdate } from 'pages/Accounting/Accounts/helpers';
import PayeeAutocomplete from 'pages/Accounting/Accounts/components/PayeeAutocomplete';
import { STableData, SActions } from 'pages/Accounting/Accounts/RightSection/RightSection.styles';
import { validationSchema } from './validationSchema';
import { checkTransactionDateValidity, getInitialValues } from './BankAccountFormItem.data';

const BankAccountFormItem = ({
  account,
  accounts,
  payees,
  transaction,
  onDelete,
  onCreateSuccess,
  onUpdateSuccess,
  form,
  getPayees,
  firstInFuture,
  onRowClick,
  onClickRowItem,
}) => {
  const showToaster = useShowToaster();
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [disabledInput, setDisabledInput] = useState(transaction?.payment ? 'deposit' : 'payment');
  const [addPayeeOpen, setAddPayeeOpen] = useState(false);
  const paymentRef = useRef(null);
  const depositRef = useRef(null);
  const accountOptions = useMemo(() => accounts.filter((i) => i.id !== account.id), [accounts, account]);

  const playAudio = () => {
    try {
      const ding = new Audio(dingSound);
      ding.play();
    } catch (e) {
      // Do nothing
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        account_id: account.id,
        date: moment(values.date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        reference_id: values.reference_id,
        payee_id: values.payee?.id || 0,
        payee_type: values.payee?.type || null,
        account: values.account?.id || 0,
        memo: values.memo,
        reconciled: 1,
        payment: Number(values.payment),
        deposit: Number(values.deposit),
      };
      await createBankAccountTransaction(body);
      showToaster({ type: 'success', message: 'Transaction has been successfully created!' });
      playAudio();
      onCreateSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, touchedErrors } = useForm({
    initialValues: getInitialValues(transaction, form),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const onFieldChange = (field, value, shouldGetPayees) => {
    handleChange(field, value);

    if (field === 'payee' && value.type === 'vendor' && (value.account || value.account_id)) {
      const account = value.account || accounts.find((acc) => Number(acc.id) === value.account_id);
      handleChange('account', account);
    }

    if (!value || !transaction || (field === 'date' && checkTransactionDateValidity(value))) {
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const accountToUpdate = getAccountToUpdate(field, value, transaction);

    const body = {
      account_id: account.id,
      date: field === 'date' ? moment(value).format('YYYY-MM-DD HH:mm:ss') : transaction.date,
      reference_id: transaction.reference_id,
      payee_id: field === 'payee' ? value.id : transaction.payee?.id || 0,
      payee_type: field === 'payee' ? value.type : transaction.payee?.type || null,
      account: accountToUpdate,
      memo: field === 'memo' ? value : transaction.memo,
      reconciled: 1,
      payment: field === 'payment' ? Number(value) : disabledInput === 'payment' ? 0 : transaction.payment,
      deposit: field === 'deposit' ? Number(value) : disabledInput === 'deposit' ? 0 : transaction.deposit,
    };

    const id = setTimeout(async () => {
      try {
        const { data } = await updateBankAccountTransaction(transaction.id, body);
        onUpdateSuccess(data, shouldGetPayees);
        showToaster({ type: 'success', message: 'Successfully updated!' });
      } catch (e) {
        showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
      }
    }, 250);
    setTimeoutId(id);
  };

  const onAddNewPayeeSuccess = (payee) => {
    onFieldChange('payee', payee, true);
    if (!transaction) {
      getPayees();
    }
  };

  const onAmountInputClick = (type) => {
    setDisabledInput(type === 'payment' ? 'deposit' : 'payment');
    setTimeout(() => {
      if (type === 'payment') {
        paymentRef.current.focus();
      } else {
        depositRef.current.focus();
      }
    });
  };

  const onAmountKeyDown = (e) => {
    blockNonPositiveNumbers(e);
    if (e.key === 'Enter' && !transaction) {
      handleSubmit();
    }
  };

  return (
    <tr
      onClick={onRowClick}
      className={classNames({
        'red-background': Number(transaction?.balance) < 0,
        'blue-border': !!firstInFuture,
        clickable: !!transaction?.stripe_synced,
      })}
    >
      <td>
        <div onClick={(e) => e.stopPropagation()}>
          <DatePicker
            size='small'
            name='date'
            value={values.date}
            onBlur={handleBlur}
            onChange={(val) => onFieldChange('date', val)}
            error={touchedErrors.date}
          />
        </div>
      </td>
      <td>
        {transaction ? (
          <div className='d-flex align-items-center gap-2'>
            {transaction.pdf_check_file_link ? (
              <Typography
                variant='b2'
                style={{ color: palette.indigo500, textDecoration: 'underline', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickRowItem({ field: 'reference_id', row: transaction });
                }}
              >
                *****{transaction.reference_id?.slice(-5)}
              </Typography>
            ) : (
              <Tooltip
                arrow
                title={transaction.reference_id}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: 13,
                      fontFamily: 'Inter',
                    },
                  },
                }}
              >
                <span style={{ color: palette.gray700 }}>*****{transaction.reference_id?.slice(-5)}</span>
              </Tooltip>
            )}
          </div>
        ) : (
          <Input
            width='200px'
            size='small'
            name='reference_id'
            placeholder='ABC'
            value={values.reference_id}
            onChange={(e) => onFieldChange('reference_id', e.target.value)}
            error={touchedErrors.reference_id}
          />
        )}
      </td>
      <STableData $width='320px'>
        <div onClick={(e) => e.stopPropagation()}>
          <PayeeAutocomplete
            options={payees}
            value={values.payee}
            onChange={(e, val) => onFieldChange('payee', val)}
            onAddNew={setAddPayeeOpen}
            error={touchedErrors.payee}
          />
        </div>
      </STableData>
      <STableData $width='320px'>
        <div onClick={(e) => e.stopPropagation()}>
          <Autocomplete
            size='small'
            name='account'
            placeholder='Select Account'
            labelKey='account_name'
            options={accountOptions}
            value={values.account}
            onChange={(e, val) => onFieldChange('account', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.account}
          />
        </div>
      </STableData>
      <STableData $width='250px'>
        <div onClick={(e) => e.stopPropagation()}>
          <Input
            size='small'
            name='memo'
            placeholder='Memo'
            value={values.memo}
            onChange={(e) => onFieldChange('memo', e.target.value)}
            error={touchedErrors.memo}
          />
        </div>
      </STableData>
      <STableData $width='170px'>
        {transaction?.stripe_synced ? (
          !!values.payment && (
            <Typography variant='s3' style={{ color: palette.gray700 }}>
              {currency}
              {formatNumber(values.payment)}
            </Typography>
          )
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            <InputWithIcon
              ref={paymentRef}
              size='small'
              type='number'
              name='payment'
              placeholder='0.00'
              value={values.payment}
              onChange={(e) => onFieldChange('payment', e.target.value)}
              onKeyDown={onAmountKeyDown}
              onClick={() => onAmountInputClick('payment')}
              className={disabledInput === 'payment' ? 'amount-disabled' : ''}
              wrapperStyle={{ backgroundColor: disabledInput === 'payment' ? palette.gray0 : '' }}
              tabIndex={disabledInput === 'payment' ? -1 : undefined}
            />
          </div>
        )}
      </STableData>
      <STableData $width='170px'>
        {transaction?.stripe_synced ? (
          !!values.deposit && (
            <Typography variant='s3' style={{ color: palette.gray700 }}>
              {currency}
              {formatNumber(values.deposit)}
            </Typography>
          )
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            <InputWithIcon
              ref={depositRef}
              size='small'
              type='number'
              name='deposit'
              placeholder='0.00'
              value={values.deposit}
              onChange={(e) => onFieldChange('deposit', e.target.value)}
              onKeyDown={onAmountKeyDown}
              onClick={() => onAmountInputClick('deposit')}
              className={disabledInput === 'deposit' ? 'amount-disabled' : ''}
              wrapperStyle={{ backgroundColor: disabledInput === 'deposit' ? palette.gray0 : '' }}
              tabIndex={disabledInput === 'deposit' ? -1 : undefined}
            />
          </div>
        )}
      </STableData>
      <td>
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {transaction?.balance < 0 && '-'}
          {currency}
          {formatNumber(Math.abs(Number(transaction?.balance || 0)))}
        </Typography>
      </td>
      <td>
        <SActions onClick={(e) => e.stopPropagation()}>
          {!transaction &&
            (loading ? (
              <span className='loading-wrapper'>
                <CircularProgress size={16} />
              </span>
            ) : (
              <span className='action-wrapper' onClick={() => handleSubmit()}>
                <CheckIcon width={11} height={11} />
              </span>
            ))}
          <span className='action-wrapper' onClick={onDelete}>
            <DeleteIcon width={11} height={11} fill={palette.red500} />
          </span>
        </SActions>
      </td>
      {!!addPayeeOpen && (
        <AddPayee
          open={!!addPayeeOpen}
          name={addPayeeOpen}
          onClose={() => setAddPayeeOpen(false)}
          onSuccess={onAddNewPayeeSuccess}
        />
      )}
    </tr>
  );
};

export default BankAccountFormItem;
