import React from 'react';
import HeaderStar from 'components/HeaderStar';
import EDILogsTable from 'componentsV2/EDI/EDILogs';
import { SPageWrapper } from './EDILogs.styles';

const Detention = () => {
  return (
    <SPageWrapper>
      <div className='d-flex justify-content-between mb-4'>
        <HeaderStar title='EDI/API Logs' />
      </div>
      <EDILogsTable />
    </SPageWrapper>
  );
};

export default Detention;
