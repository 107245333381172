import React, { useState, useEffect } from 'react';
import './fuelPrice.css';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import IFTAServices, { formatAmount } from 'services/IFTAServices';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import CalendarIcon from 'assets/icons/createShipment/calendar.svg';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import useDateFormat from 'hooks/useDateFormat';
import TableFooter from '../../ApplicantsAndReferrals/components/TableFooter';
import { useColumns } from './Fuel.Price.data';

const initialFilters = {
  page: 1,
  page_size: 100,
};

const fuelType = [
  {
    id: 1,
    key: 1,
    label: 'Diesel',
  },
  {
    id: 3,
    key: 3,
    label: 'Gasoline',
  },
  {
    id: 4,
    key: 4,
    label: 'Natural Gas',
  },
  {
    id: 2,
    key: 2,
    label: 'Electric',
  },
];

const FuelPrice = () => {
  const { use } = useTheme();
  const [fuelTypeOption, setFuelTypeOption] = useState(fuelType[0]);
  const [fuelData, setFuelData] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date().setFullYear(endDate.getFullYear() - 1));
  const [sort, setSort] = useState({ field: 'date', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);

  const getFuelPoint = (data) => {
    setLoading(true);
    IFTAServices.getFuelHistory(data).then((res) => {
      setFuelData(res.data);
    });
    setLoading(false);
  };

  const onPageChange = (event, page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    if (startDate && fuelTypeOption) {
      const data = {
        fuel_type: fuelTypeOption.label,
        from_date: moment(startDate).format('MM/DD/YYYY'),
        to_date: moment(endDate).format('MM/DD/YYYY'),
        sort_by: sort.field,
        sort_order: sort.sortBy,
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.page_size,
      };
      getFuelPoint(data);
    }
  }, [fuelTypeOption, sort, selectedFilters, endDate, startDate]);

  const PaginationComponent = (event) => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.page_size}
      totalCount={fuelData?.history?.total}
      totalLength={fuelData?.history?.data?.length}
      lastPage={fuelData?.history?.last_page}
      currentPage={fuelData?.history?.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange.bind(null, event)}
    />
  );

  const columns = useColumns({ sortingQuery, sort });

  return (
    <div className='fuel-price-layout'>
      <div className='fuel-inner-header'>
        <div>
          <DateRangePicker
            setStartDate={setStartDate}
            startDate={startDate}
            setEndDate={setEndDate}
            endDate={endDate}
          />
        </div>
        <div>
          <CustomSelect
            value={fuelTypeOption}
            defaultActiveValue={fuelType[0]}
            onChange={setFuelTypeOption}
            options={fuelType}
            styles={{ height: 32, width: 140 }}
          />
        </div>
      </div>
      <div className='fuel-price-row' style={{ marginTop: '20px' }}>
        <div>Price</div>
        <div>Unit</div>
      </div>
      <div className='fuel-price-row'>
        <div>
          <span className='fuel-price-heading'>${formatAmount(fuelData?.data?.price)} </span>
          <span className='fuel-caption'> (as of {fuelData?.data?.date})</span>
        </div>
        <div>
          {fuelData?.data?.unit}
          <span className='fuel-type-tag'>{fuelData?.data?.type}</span>
        </div>
      </div>
      <div className='fuel-price-row' style={{ margin: '20px 0px' }}>
        <ChartOption fuelData={fuelData} />
      </div>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <MaterialTableWrapper
          data={fuelData?.history?.data}
          rowPerPage={selectedFilters.page_size}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{ Pagination: PaginationComponent }}
          columns={columns}
        />
      )}
    </div>
  );
};

export default FuelPrice;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { position: 'top' },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

const ChartOption = ({ fuelData }) => {
  const priceData = fuelData?.history?.data?.map((d) => Number(formatAmount(d.price)))?.reverse();
  const labels = fuelData?.history?.data?.map((d) => moment(d.date).format('DD MMM YY'))?.reverse();

  const data = {
    labels,
    maintainAspectRatio: false,
    datasets: [
      {
        fill: true,
        label: fuelData?.data?.type,
        data: priceData,
        borderColor: '#4F5AED',
        backgroundColor: '#C1C5FF',
      },
    ],
  };

  return <Line options={options} height={400} data={data} />;
};

const DateRangePicker = ({ setStartDate, startDate, setEndDate, endDate }) => {
  const { pickerDateFormat } = useDateFormat();

  return (
    <div className='fuel-date-range-picker'>
      <img src={CalendarIcon} alt='' />
      <DatePicker
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={25}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat={pickerDateFormat}
      />
      -
      <DatePicker
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={25}
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        className='end-date'
        dateFormat={pickerDateFormat}
      />
    </div>
  );
};
