import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'common/Modal';
import ViewPdf from 'components/ViewPdf';
import { palette } from 'utils/constants';
import useForm from 'hooks/useForm';
import { getErrorMessage } from 'utils/error';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { GetCompanyProfile } from 'Api/CompanySettings';
import { generatePdf } from 'componentsV2/Planner/Quotes/CreateQuote/generatePdf';
import { sendQuotes, updateQuote, updateQuoteBilling, uploadQuoteDocument } from 'Api/Shipment';
import CustomerSection from './components/CustomerSection';
import DeliveryOptions from './components/DeliveryOptions';
import { getInitialValues } from './SendQuote.data';
import { validationSchema } from './validationSchema';
import { SSendQuoteForm } from './SendQuote.styles';

const SendQuote = ({ open, onClose, quotes, onSuccess }) => {
  const showToaster = useShowToaster();
  const dateFormat = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const allQuotes = values.customers.reduce((acc, cur) => [...acc, ...cur.quotes], []);

      await Promise.all(
        allQuotes.map((quote) => {
          const body = {
            customer_id: quote.billing.customer.id,
            contact_user_id: quote.billing.contact.id,
          };

          return updateQuoteBilling(quote.billing.id, body);
        })
      );

      const uploadResponses = await Promise.all(
        allQuotes.map((quote) => {
          const { blob } = generatePdf({ quote, companyProfile, dateFormat });
          const formData = new FormData();
          formData.append('file_upload', blob);

          return uploadQuoteDocument(formData);
        })
      );

      await Promise.all(
        allQuotes.map((quote, index) => {
          const body = {
            expiry_date: quote.expiry_date ? moment(quote.expiry_date).format('YYYY-MM-DD') : null,
            quote_date: quote.quote_date ? moment(quote.quote_date).format('YYYY-MM-DD') : null,
            customer_notes: quote.customer_notes || '',
            quote_pdf_path: uploadResponses[index].full_path,
          };

          return updateQuote(quote.id, body);
        })
      );

      if (values.print_mail) {
        allQuotes.forEach((quote) => {
          generatePdf({ quote, companyProfile, dateFormat }, true);
        });
      }

      await sendQuotes({ quote_ids: allQuotes.map((quote) => quote.id), send_email: values?.send_email ? 1 : 0 });

      showToaster({
        type: 'success',
        message:
          quotes.length > 0
            ? `Quotes have been successfully ${values?.send_email ? 'sent' : 'downloaded to your computer'}!`
            : `Quote has been successfully ${values?.send_email ? 'sent' : 'downloaded to your computer'}!`,
      });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    initialValues: getInitialValues(quotes),
    onSubmit,
    validationSchema,
  });

  const { values, handleSubmit } = form;

  const onPreview = (quote) => {
    const { url } = generatePdf({ quote, companyProfile, dateFormat });
    setPdfUrl(url);
  };

  const getCompanyProfile = async () => {
    try {
      const company = await GetCompanyProfile();
      setCompanyProfile(company);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCompanyProfile();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Send Quote'
      className='modified-scrollbar'
      $bgColor={palette.gray0}
      $maxWidth='920px'
      $minWidth='920px'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'create',
          type: 'primary',
          buttonProps: {
            type: 'submit',
            form: 'send-quote-form',
            onClick: undefined,
          },
          title: 'Send Quote',
          disabled: loading,
        },
      ]}
    >
      <SSendQuoteForm id='send-quote-form' onSubmit={handleSubmit}>
        <DeliveryOptions form={form} />
        {values.customers.map((customerData, customerIndex) => (
          <CustomerSection
            key={customerData.customer.id}
            form={form}
            customerData={customerData}
            customerIndex={customerIndex}
            onPreview={onPreview}
          />
        ))}
      </SSendQuoteForm>
      <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Quote' />
    </Modal>
  );
};

export default SendQuote;
