import React, { useContext, useEffect, useMemo } from 'react';
import { DatePicker } from 'common/Pickers';
import { InputWithText } from 'common/Input';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { ShipmentContext } from 'components/CreateShipment/Layout';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { weekdaysBetween } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import Recurs from './Recurs';
import { getValidationSchema } from './validationSchema';
import { getInitialValues } from './Schedule.data';
import { SSchedule } from './Schedule.styles';

const Schedule = () => {
  const showToaster = useShowToaster();
  const {
    setStep,
    createShipmentData,
    updateShipmentData,
    onCloseModal,
    selectedCharges,
    updateSelectedTotalAmount,
    stopPoints,
  } = useContext(ShipmentContext);
  const allStops = useMemo(() => {
    return (createShipmentData.legs || createShipmentData.shipmentsLegs).reduce(
      (acc, cur) => [
        ...acc,
        ...cur.stops.map((item) => ({
          ...item,
          stop_point: stopPoints.find((i) => Number(i.key) === Number(item.stop_point_id)),
        })),
      ],
      []
    );
  }, [createShipmentData.legs]);

  const onSubmit = (values) => {
    if (values.recurs?.every((i) => !i.checked)) {
      showToaster({ type: 'error', message: 'At least one day should be selected' });
      return;
    }

    const { recurs, shipmentOnly, leadTime } = values;

    const data = {
      shipmentOnly,
      recurs,
      leadTime,
      laneStartDate: values?.laneStartDate ? values?.laneStartDate : '',
      laneEndDate: values?.laneEndDate ? values?.laneEndDate : '',
    };

    updateShipmentData({ ...createShipmentData, ...data });
    setStep((p) => p + 1);
  };

  const form = useForm({
    initialValues: getInitialValues(createShipmentData, allStops),
    onSubmit,
    validationSchema: getValidationSchema(allStops),
  });
  const { values, handleChange, handleSubmit, handleBlur, touchedErrors } = form;

  useEffect(() => {
    const subTotal = { 0: 0 };
    selectedCharges[0]?.forEach((charge) => {
      if (charge?.qty && charge?.rate) {
        subTotal[0] = +subTotal[0] + Number(charge.qty) * Number(charge.rate);
      }
    });
    updateSelectedTotalAmount(subTotal);
  }, [selectedCharges]);

  const totalCount = useMemo(() => {
    return form.values.recurs.reduce((acc, cur, index) => {
      if (cur.checked) {
        const daysCount = weekdaysBetween(form.values.laneStartDate, form.values.laneEndDate, index + 1);
        const occurrence = (cur.amount || 0) * (daysCount || 0);
        acc += occurrence;
      }
      return acc;
    }, 0);
  }, [form.values]);

  return (
    <SSchedule>
      <div>
        <div className='d-flex align-items-center gap-4 mb-4'>
          <DatePicker
            required
            label='Lane Start Date'
            width='200px'
            name='laneStartDate'
            onBlur={handleBlur}
            value={values.laneStartDate}
            onChange={(date) => handleChange('laneStartDate', date)}
            error={touchedErrors.laneStartDate}
          />
          <DatePicker
            required
            label='Lane End Date'
            width='200px'
            name='laneEndDate'
            onBlur={handleBlur}
            value={values.laneEndDate}
            onChange={(date) => handleChange('laneEndDate', date)}
            error={touchedErrors.laneEndDate}
          />
        </div>
        <div>
          <div>
            <InputWithText
              width='80px'
              text='days'
              label='Lead Time: Creation of shipment prior to days selected below'
              name='leadTime'
              value={form.values.leadTime}
              onChange={form.handleChange}
              error={touchedErrors.leadTime}
            />
          </div>
          <Recurs
            form={form}
            stops={allStops}
            createMode
            shipmentData={createShipmentData}
            updateShipmentData={updateShipmentData}
          />
          <div className='d-flex align-items-center mt-4'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Create {totalCount} Shipments Only
            </Typography>
          </div>
        </div>
      </div>
      <div className='schedule-footer'>
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onCloseModal}
        />
        <div className='d-flex'>
          <CustomButton
            type='secondary'
            title='Go Back'
            styleButton={{ padding: '6px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => setStep((p) => p - 1)}
          />
          <CustomButton
            type='primary'
            title='Next Step'
            styleButton={{ padding: '6px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={handleSubmit}
            buttonProps={{ type: 'submit' }}
          />
        </div>
      </div>
    </SSchedule>
  );
};

export default Schedule;
