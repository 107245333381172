import React, { useState } from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { syncWithQuickbook } from 'Api/AccountingReceivables';
import SpinnerLoadingMultipleTexts from 'components/SpinnerLoadingMultipleTexts/SpinerLoadingTwoTexts';

const QuickbooksSync = ({ action, onSuccess }) => {
  const showToaster = useShowToaster();
  const hasQuickbook = useHasQuickbook();
  const [loadingSync, setLoadingSync] = useState(false);

  const handleSync = async () => {
    try {
      setLoadingSync(true);
      const params = { action };
      const { message, success } = await syncWithQuickbook(params);
      if (success) {
        showToaster({ type: 'success', message: message || 'Data have been successfully synced!' });
        onSuccess();
      } else {
        showToaster({ type: 'error', message: message || 'Something went wrong!' });
      }
    } catch (e) {
      if (e?.response?.status === 500) {
        showToaster({
          type: 'error',
          message: 'QuickBooks Sync is already running and has not completed yet. Please check back in 1 hour.',
        });
        return;
      }

      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingSync(false);
    }
  };

  return (
    <div>
      {!!hasQuickbook && (
        <CustomButton
          type='secondary'
          title='Sync to QuickBooks'
          styleTitle={{ color: '#ffffff', whiteSpace: 'nowrap' }}
          styleButton={{ margin: 0, backgroundColor: '#2CA01C', height: 34, borderRadius: 4 }}
          onClick={handleSync}
        />
      )}
      <SpinnerLoadingMultipleTexts
        isVisible={loadingSync}
        texts={['Syncing with QuickBooks.. please stand by..', "Still working on your sync.. don't go anywhere.."]}
      />
    </div>
  );
};

export default QuickbooksSync;
