import React from 'react';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import InputLabel from 'common/InputLabel';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import { palette } from 'utils/constants';
import { SAddMore, SAttachmentWrapper, SFlexWrapper } from '../InventoryList.styles';

const Photos = ({ values, handleChange }) => {
  const uploadPhoto = (files) => {
    handleChange('files', [...values.files, ...files]);
  };

  const onAttachmentDelete = (e, index) => {
    e.stopPropagation();
    const newImages = values.files.filter((item, i) => i !== index);
    handleChange('files', newImages);
  };

  return (
    <SAttachmentWrapper>
      <InputLabel className='mb-0'> Photo(s)</InputLabel>
      <div>
        <SFlexWrapper>
          {values?.files?.map((image, index) => (
            <div key={image?.id || index}>
              <p className='attachment-wrapper' style={{ backgroundColor: palette.indigo0 }}>
                <img src={right} alt='attachment' className='attachment-icon' />
                <span className='text-style' style={{ color: palette.green400 }}>
                  Image {index + 1}
                </span>
                <img
                  src={cross}
                  alt='cross'
                  className='upload-cancel-icon'
                  onClick={(e) => onAttachmentDelete(e, index)}
                />
              </p>
            </div>
          ))}
          {!!values?.files?.length && (
            <SAddMore>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
                Add Another...
              </Typography>
              <ImageDropZone
                multiple
                onDrop={(files) => uploadPhoto(files)}
                width='100%'
                height='100%'
                name='small'
                deletable={false}
                customStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                }}
                accept={['image/jpeg', 'image/png']}
              />
            </SAddMore>
          )}
        </SFlexWrapper>
        {!values?.files?.length && (
          <div className='upload-document-deposit'>
            <div className='text-style upload-label'>
              <p className='upload-document-icon'>
                <img src={uploadLicense} alt='' />
              </p>
              <p className='upload-document-text'>
                <span style={{ color: palette.indigo500 }}> Upload Image </span>
                <span style={{ color: palette.gray700 }}> or drag and drop</span>
              </p>
            </div>
            <ImageDropZone
              multiple
              onDrop={(files) => uploadPhoto(files)}
              width='100%'
              height='100%'
              name='small'
              deletable={false}
              customStyle={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              accept={['image/jpeg', 'image/png']}
            />
          </div>
        )}
      </div>
    </SAttachmentWrapper>
  );
};

export default Photos;
