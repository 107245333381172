import { Modal, Button } from 'react-bootstrap';
import './AddGroupModal.css';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import plus from 'assets/icons/drivers/plus.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import AddGroup from 'componentsV2/Group/AddGroup';
import { DriverGroups } from 'Api/Driver';
import CustomMultiSelect from 'common/CustomMultiSelect/CustomMultiSelect';

export default function AddGroups({ ...props }) {
  const { use } = useTheme();
  const [groups, setGroups] = useState([]);
  const [openAddGroup, setOpenAddGroup] = useState(false);

  const formik = useFormik({
    initialValues: {
      group_id: [],
    },
  });

  const onAddGroup = () => {
    const { group_id } = formik.values;
    props.updateGroupProfile(group_id, props.onHide, props.onSuccess, 'Staff groups has been updated successfully');
  };

  const getGroups = async () => {
    try {
      const { data } = await DriverGroups();
      setGroups(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (props.driverGroupInfo?.length) {
      formik.setFieldValue('group_id', props.driverGroupInfo);
    }
  }, [props.driverGroupInfo]);

  return (
    <div className='modal-container driver-style-wrap'>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered enforceFocus={false}>
        <Modal.Header
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            color: use(palette.gray50, palette.gray900),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <Modal.Title
            className='heading'
            id='contained-modal-title-vcenter'
            style={{ color: use(palette.gray900, palette.gray50) }}
          >
            Add Tag&#40;s&#41;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className='modal-body-box driver-style-wrap'
          style={{ backgroundColor: use(palette.whiteBackground, palette.dark900) }}
        >
          <div className='content-wrapper-group'>
            <div className='head-container'>
              <p className='text-style' style={{ color: use(palette.gray700, palette.gray50), marginLeft: '0' }}>
                Tag&#40;s&#41; <span className='required'>*</span>
              </p>

              <div className='add-container' onClick={() => setOpenAddGroup(true)}>
                <img src={plus} alt='plus' />
                <p className='add-new'>New Tag</p>
              </div>
            </div>
            <CustomMultiSelect
              placeholder={!groups?.length ? 'No Data' : null}
              selectedValues={formik.values.group_id || []}
              formik={formik}
              patchProperty='group_id'
              propertyName='group_name'
              options={groups}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: use(palette.white, palette.dark800),
            borderColor: use(palette.gray50, palette.darkborder),
          }}
        >
          <div className='footer-btn-container'>
            <Button className='cancel-button' onClick={props.onHide}>
              Cancel
            </Button>
            {props.loading ? (
              <div>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button className='next-step' disabled={!formik.values.group_id} onClick={onAddGroup}>
                Save
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {!!openAddGroup && <AddGroup open={openAddGroup} onClose={() => setOpenAddGroup(false)} onSuccess={getGroups} />}
    </div>
  );
}
