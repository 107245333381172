import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';

const ConfirmOverpayment = ({ open, onClose, invoices, onSubmit }) => {
  const { currency } = useSelector((state) => state.root);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Overpayment'
      $minHeight='100px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        { key: 'close', type: 'secondary', title: 'Cancel' },
        {
          key: 'submit',
          type: 'primary',
          title: `Proceed`,
          onClick: onSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-5'>
        <div className='d-flex flex-column gap-2'>
          {invoices.map((item) => (
            <Typography variant='s2' key={item.id}>
              <Typography variant='button2'>
                {currency}
                {formatNumber(item.over_payment)}
              </Typography>{' '}
              will be transferred to credit account for customer {item.customer?.company_name}!
            </Typography>
          ))}
        </div>
        <Typography variant='s2'>Do you want to proceed?</Typography>
      </div>
    </Modal>
  );
};

export default ConfirmOverpayment;
