import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { getMinUnit } from 'react-calendar-timeline/lib/lib/utility/calendar';
import Tooltip from 'common/Tooltip';
import NoRecords from 'common/NoRecords';
import UserInfo from 'components/UserInfo';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import { getStatusParamsInStatus } from 'components/TableShipments/helpers/constants';
import { getAppUrl } from 'utils/helpers';
import { calendarStyles, unitMapper } from './AvailabilityCalendar.data';
import { SCalendar, SSidebarHeader, SWrapper } from './AvailabilityCalendar.styles';

const AvailabilityCalendar = ({ drivers, filters, loading }) => {
  const { currency } = useSelector((state) => state.root);
  const { formatDateTime } = useDateFormat();
  const ref = useRef(null);

  const start = filters.dateRange[0] ? moment(filters.dateRange[0]).startOf('day') : moment().startOf('week');
  const end =
    filters.dateRange[1] || filters.dateRange[0]
      ? moment(filters.dateRange[1] || filters.dateRange[0]).endOf('day')
      : moment().endOf('week');

  const visibleTime = useRef({ visibleTimeStart: start.valueOf(), visibleTimeEnd: end.valueOf() });
  const initialDiff = moment(end).diff(start, 'seconds');

  const [viewType, setViewType] = useState(
    initialDiff > 2678400 ? 'Month' : initialDiff <= 2678400 && initialDiff > 345600 ? 'Week' : 'Day'
  );
  const [calendarRange, setCalendarRange] = useState([
    filters.dateRange[0] ? moment(filters.dateRange[0]).startOf('day') : moment().startOf('week'),
    filters.dateRange[1] || filters.dateRange[0]
      ? moment(filters.dateRange[1] || filters.dateRange[0]).endOf('day')
      : moment().endOf('week'),
  ]);

  const data = (drivers || []).reduce((acc, cur) => {
    const absences = cur.absences.map((item) => ({
      id: `absence-${item.id}-${uuid()}`,
      group: cur.id,
      title: '',
      absence: { ...item, fname: cur.fname, lname: cur.lname },
      start_time: moment(item.start_date).startOf('day'),
      end_time: moment(item.end_date).endOf('day'),
    }));

    const shipments = cur.shipments.map((item) => {
      return {
        id: `shipment-${item.shipment_id}-${uuid()}`,
        group: cur.id,
        title: '',
        shipment: { ...item },
        start_time: moment(`${item.shipment_stops[0].scheduled_date} ${item.shipment_stops[0].from}`),
        end_time: moment(
          [1, 3].includes(Number(item.shipment_stops[item.shipment_stops.length - 1].scheduled_type))
            ? `${
                item.shipment_stops[item.shipment_stops.length - 1].scheduled_date_to ||
                item.shipment_stops[item.shipment_stops.length - 1].scheduled_date
              } ${item.shipment_stops[item.shipment_stops.length - 1].to}`
            : `${item.shipment_stops[item.shipment_stops.length - 1].scheduled_date} ${
                item.shipment_stops[item.shipment_stops.length - 1].from
              }`
        ).add(
          Number(item.shipment_stops[item.shipment_stops.length - 1]?.stop_point?.average_waiting_time || 0),
          'minutes'
        ),
      };
    });

    const nextAvailability = [];

    if (cur.nextAvailability) {
      nextAvailability.push({
        id: `next-availability-${uuid()}`,
        group: cur.id,
        title: '',
        nextAvailability: cur.nextAvailability,
        start_time: moment(cur.nextAvailability.start),
        end_time: moment(cur.nextAvailability.end),
      });
    }

    return [...acc, ...absences, ...shipments, ...nextAvailability];
  }, []);

  const groups = drivers.map((driver) => ({
    ...driver,
    title: (
      <div className='d-flex align-items-center gap-2 ps-2 pe-2'>
        <div className='w-50'>
          <UserInfo
            size='28px'
            statusSize='6px'
            data={{
              ...driver,
              name: `${driver.fname} ${driver.lname}`,
              image: driver.image,
              status: driver.status_data?.driver_status,
              user_type: 'driver',
            }}
          />
        </div>
        <div className='w-25'>
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {Math.round(Number(driver.total_miles) || 0)}
          </Typography>
        </div>
        <div className='w-25'>
          <Typography variant='b2' style={{ color: palette.gray700 }} className='nowrap'>
            {currency}
            {formatNumber((Number(driver.driver_gross_revenue) || 0) / (Number(driver.total_miles) || 0))} /mile
          </Typography>
        </div>
      </div>
    ),
  }));

  const itemRenderer = ({ item: data, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

    const item = data.shipment || data.absence || data.nextAvailability;

    if (data.nextAvailability) {
      return (
        <div {...getItemProps(item.itemProps)}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

          <div className='rct-item-content' style={{ maxHeight: `${itemContext.dimensions.height}` }}>
            <Tooltip
              title='NOT AVAILABLE'
              tooltipStyles={{
                fontSize: '12px',
                textTransform: 'uppercase',
                background: palette.red0,
                color: palette.red500,
              }}
            >
              <div
                className='timeline-item'
                style={{
                  background: palette.red0,
                  color: palette.red500,
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                }}
              >
                {item.title}
              </div>
            </Tooltip>
          </div>

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      );
    }

    if (data.absence) {
      return (
        <div {...getItemProps(item.itemProps)}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

          <div className='rct-item-content' style={{ maxHeight: `${itemContext.dimensions.height}` }}>
            <Tooltip
              title={item.absence_type?.name || item.type}
              tooltipStyles={{
                fontSize: '12px',
                textTransform: 'uppercase',
                background: calendarStyles[item.type]?.bgColor,
                color: calendarStyles[item.type]?.color,
              }}
            >
              <NavLink
                style={{ color: 'inherit', textDecoration: 'none' }}
                to={`/driver-profile/${item.driver_id}/absences`}
              >
                <div
                  className='timeline-item'
                  style={{
                    background: calendarStyles[item.type]?.bgColor,
                    color: calendarStyles[item.type]?.color,
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    fontWeight: 500,
                  }}
                >
                  {item.fname} {item.lname} ({item.absence_type?.name || item.type})
                </div>
              </NavLink>
            </Tooltip>
          </div>

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      );
    }

    const destinationLocationName = item.shipment_stops[item.shipment_stops.length - 1]?.stop_point?.location_name;
    const destinationCity = item.shipment_stops[item.shipment_stops.length - 1]?.stop_point?.city?.name;
    const destinationState = item.shipment_stops[item.shipment_stops.length - 1]?.stop_point?.state?.short_name;
    const paramsInStatus = getStatusParamsInStatus(item.status);
    const { bgStatus, colorStatus, statusName } = paramsInStatus || {};

    return (
      <div {...getItemProps(item.itemProps)}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

        <div className='rct-item-content' style={{ maxHeight: `${itemContext.dimensions.height}` }}>
          <Tooltip
            title={`${statusName.toUpperCase()}`}
            tooltipStyles={{
              fontSize: '12px',
              textTransform: 'uppercase',
              background: bgStatus,
              color: colorStatus,
            }}
          >
            <NavLink style={{ color: 'inherit', textDecoration: 'none' }} to={`/shipment/${item.shipment_id}`}>
              <div
                className='timeline-item'
                style={{
                  background: bgStatus,
                  color: colorStatus,
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                }}
              >
                {destinationLocationName} - {destinationCity}, {destinationState} -&gt;{' '}
                {formatDateTime(
                  moment(
                    [1, 3].includes(Number(item.shipment_stops[item.shipment_stops.length - 1].scheduled_type))
                      ? `${
                          item.shipment_stops[item.shipment_stops.length - 1].scheduled_date_to ||
                          item.shipment_stops[item.shipment_stops.length - 1].scheduled_date
                        } ${item.shipment_stops[item.shipment_stops.length - 1].to}`
                      : `${item.shipment_stops[item.shipment_stops.length - 1].scheduled_date} ${
                          item.shipment_stops[item.shipment_stops.length - 1].from
                        }`
                  ).add(
                    Number(item.shipment_stops[item.shipment_stops.length - 1]?.stop_point?.average_waiting_time || 0),
                    'minutes'
                  )
                )}
              </div>
            </NavLink>
          </Tooltip>
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    );
  };

  const getTimelineUnit = ({ visibleTimeStart, visibleTimeEnd }) => {
    // Due to the fact that this lib does not properly implement the logic for onTimeChange "unit" when clicking on header item, I'm doing the calculations manually
    // width - 400 is for getting actual timeline width (container width - sidebar width)

    const width = ref.current.offsetWidth;

    const timeSteps = {
      second: 1,
      minute: 1,
      hour: 1,
      day: 1,
      month: 1,
      year: 1,
    };

    const zoom = visibleTimeEnd - visibleTimeStart;
    return getMinUnit(zoom, width - 400, timeSteps);
  };

  const onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);

    const unit = getTimelineUnit({ visibleTimeStart, visibleTimeEnd });
    visibleTime.current = { visibleTimeStart, visibleTimeEnd };
    if (unitMapper[unit || 'week'] !== viewType) {
      setViewType(unitMapper[unit || 'week']);
    }
  };

  const onViewTypeChange = (type) => {
    setViewType(type);
    const start = visibleTime.current.visibleTimeStart;

    switch (type) {
      case 'Day':
        setCalendarRange([moment(start).startOf('day'), moment(start).endOf('day')]);
        break;
      case 'Week':
        setCalendarRange([moment(start).startOf('week'), moment(start).endOf('week')]);
        break;
      case 'Month':
        setCalendarRange([moment(start).startOf('month'), moment(start).endOf('month')]);
        break;
      case 'Year':
        setCalendarRange([moment(start).startOf('year'), moment(start).endOf('year')]);
        break;
      default:
        setCalendarRange([moment().startOf('week'), moment(start).endOf('week')]);
    }
  };

  const onItemRightClick = (itemId) => {
    const clickedItem = data.find((item) => item.id === itemId);
    const url = getAppUrl();

    if (clickedItem?.shipment) {
      window.open(`${url}/shipment/${clickedItem.shipment.shipment_id}`, '_blank');
    }
    if (clickedItem?.absence) {
      window.open(`${url}/driver-profile/${clickedItem.absence.driver_id}/absences`, '_blank');
    }
  };

  useEffect(() => {
    setCalendarRange([
      filters.dateRange[0] ? moment(filters.dateRange[0]).startOf('day') : moment().startOf('week'),
      filters.dateRange[1] || filters.dateRange[0]
        ? moment(filters.dateRange[1] || filters.dateRange[0]).endOf('day')
        : moment().endOf('week'),
    ]);

    const start = filters.dateRange[0] ? moment(filters.dateRange[0]).startOf('day') : moment().startOf('week');
    const end =
      filters.dateRange[1] || filters.dateRange[0]
        ? moment(filters.dateRange[1] || filters.dateRange[0]).endOf('day')
        : moment().endOf('week');

    const diff = moment(end).diff(start, 'seconds');
    const newViewType = diff > 2678400 ? 'Month' : diff <= 2678400 && diff > 345600 ? 'Week' : 'Day';
    setViewType(newViewType);
  }, [filters.dateRange.toString()]);

  return (
    <SWrapper ref={ref}>
      {!!drivers?.length && (
        <SCalendar>
          <SSidebarHeader>
            <div className='d-flex justify-content-end mb-2'>
              <Autocomplete
                size='small'
                width='100px'
                options={['Day', 'Week', 'Month']}
                value={viewType}
                onChange={(e, val) => onViewTypeChange(val)}
              />
            </div>
            <div className='table-header-wrapper'>
              <div className='w-50'>
                <Typography variant='c1' style={{ color: palette.gray500 }}>
                  NAME
                </Typography>
              </div>
              <div className='w-25'>
                <Typography variant='c1' style={{ color: palette.gray500 }}>
                  MILES
                </Typography>
              </div>
              <div className='w-25'>
                <Typography variant='c1' style={{ color: palette.gray500 }}>
                  REVENUE
                </Typography>
              </div>
            </div>
          </SSidebarHeader>
          <Timeline
            key={calendarRange.toString()}
            groups={groups}
            items={data}
            defaultTimeStart={calendarRange[0]}
            defaultTimeEnd={calendarRange[1]}
            canMove={false}
            canResize={false}
            itemRenderer={itemRenderer}
            maxZoom={365.24 * 86400 * 1000}
            minZoom={300 * 60 * 1000}
            sidebarWidth={400}
            stackItems
            lineHeight={42}
            itemHeightRatio={0.8}
            onTimeChange={onTimeChange}
            onItemContextMenu={onItemRightClick}
          />
        </SCalendar>
      )}
      {!loading && !drivers?.length && <NoRecords />}
    </SWrapper>
  );
};

export default AvailabilityCalendar;
