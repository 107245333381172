import React from 'react';
import { palette } from 'utils/constants';
import { STypography } from './InputLabel.styles';

const InputLabel = ({ required, children, className, ...props }) => {
  return (
    <STypography
      className={`label ${required ? 'label-required' : ''} ${className || ''}`}
      variant='s2'
      style={{ color: palette.gray700 }}
      {...props}
    >
      {children}
    </STypography>
  );
};

export default InputLabel;
