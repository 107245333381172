import React from 'react';
import { palette } from 'utils/constants';
import ColumnHeader from 'common/ColumnHeader';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import classes from 'components/TableRowEditModalTypes/NotesEditModal/notesEditModal.module.css';

export const useColumns = ({ sort, sortingQuery, onClickView }) => {
  const { use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'Title',
      title: <ColumnHeader title='TITLE' field='title' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <span className='active-text' style={{ color: use(palette.indigo500, palette.indigo400) }}>
          {rowData?.title}
        </span>
      ),
    },
    {
      field: 'note',
      title: <ColumnHeader title='NOTE' field='note' sortingQuery={sortingQuery} sort={sort} />,
      render: (rowData) => {
        return (
          <div className={classes.columns_notes_wrapper}>
            <div className={classes.columns_notes}>
              <span style={{ color: palette.dark800 }}>{rowData?.notes}</span>
            </div>
            <div className={classes.notes_bottom_wrapper} style={{ marginBottom: '2px' }}>
              {!!rowData?.note_to_email && (
                <div style={{ backgroundColor: palette.gray50 }}>
                  <Typography variant='c1'>Sent: {rowData?.note_to_email}</Typography>
                </div>
              )}
            </div>
            <div className={classes.notes_bottom_wrapper}>
              {!!rowData?.department?.department_name && (
                <div style={{ backgroundColor: palette.gray50 }}>
                  <Typography variant='c1'>CC: {rowData?.department?.department_name}</Typography>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'added_by',
      title: <ColumnHeader title='ADDED BY' field='added_by' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <p className='table-data-text' style={{ color: use(palette.gray700, palette.gray200) }}>
          {rowData?.added_by?.first_name || '-'} {rowData?.added_by?.last_name || ''}
        </p>
      ),
    },
    {
      field: 'Date',
      title: <ColumnHeader title='DATE' field='created_at' onClick={sortingQuery} sort={sort} />,
      render: (rowData) => (
        <div className='last-row-container-notes'>
          {rowData?.created_at ? convertToCustomerTime(rowData?.created_at) : ''}

          {/* <p className="table-data-text" style={{ color: use(palette.gray700, palette.gray200) }}>{rowData?.created_at?.replace('T', " ")?.slice(0, 19)}</p> */}
        </div>
      ),
    },
    {
      field: 'Attachment',
      title: <Typography variant='c3'>ATTACHMENT</Typography>,
      render: (rowData) => (
        <div className='more-dowpdown-container'>
          {rowData?.attachment ? (
            <p className='View-text' onClick={() => onClickView(rowData?.attachment)}>
              View
            </p>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
  ];
};
