import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class PositionApplicationsServices {
  getJobApplicationSettings() {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
      'x-dot': user.customer.dot,
    };
    return axios
      .get(`${API_URL}/job-position/job-application-settings`, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  updateJobApplicationSettings(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };

    return axios
      .put(`${API_URL}/job-position/job-application-settings`, data, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  getQuestionnaire() {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
      'x-dot': user.customer.dot,
    };
    return axios
      .get(`${API_URL}/job-position/questionnaire`, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  deleteQuestionnaire(id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
      'x-dot': user.customer.dot,
    };
    return axios
      .delete(`${API_URL}/job-position/questionnaire/${id}`, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  addQuestionnaire(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .post(`${API_URL}/job-position/questionnaire`, data, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  getPositonStatus() {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .get(`${API_URL}/customer/job-position-status`, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  getStates(countryId) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .get(`${API_URL}/search-states`, {
        headers,
        params: { 'country_id[]': countryId },
      })
      .then((response) => {
        return response;
      });
  }

  addJobPosition(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .post(`${API_URL}/customer/job-position`, data, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  updateJobPosition(id, data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .put(`${API_URL}/customer/job-position/${id}`, data, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }

  deleteJobPosition(Id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    };
    return axios
      .delete(`${API_URL}/customer/job-position/${Id}`, {
        headers,
      })
      .then((response) => {
        return response;
      });
  }
}

export default new PositionApplicationsServices();
