import jsPDF from 'jspdf';
import truckinLogo from 'assets/images/truckinLogo.png';
import { formatNumber, getAlphabet, palette, TRUCK_TYPES } from 'utils/constants';
import { getStopName } from 'utils/helpers';
import {
  EQUIPMENT_ACTION,
  EQUIPMENT_ACTION_DELIVERY,
  EQUIPMENT_ACTION_WAYPOINT,
  QUANTITY_TYPE,
} from 'components/CreateShipment/ShipmentStops/helpers/constants';
import { getAverageTimeConverted } from 'components/StopPoint/Walmart/helpers';

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
};

const createEventsTableHeader = (doc, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(7);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  doc.text('EVENT', 8, top);
  doc.text('EVENT DESCRIPTION', 32, top);
  doc.text('EQUIPMENT ID', 98, top, { align: 'center' });

  doc.text('MILES', 117, top - 2, { align: 'center' });
  doc.text('TRAVELED', 117, top + 1, { align: 'center' });

  doc.text('LOCATION', 126, top);
  doc.text('DATE', 162, top);
  doc.text('ACTION BY', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createEventTableRow = (doc, event, top, dateFormat) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(
    !!event.type?.title && event.type.title.length > 24
      ? `${event.type.title.slice(0, 24)}..`
      : `${event.type?.title || '-'}`,
    8,
    top,
    { maxWidth: 24 }
  );
  doc.text(
    !!event.description && event.description.length > 66
      ? `${event.description.slice(0, 66)}..`
      : `${event.description || '-'}`,
    32,
    top,
    { maxWidth: 62 }
  );
  doc.text(`${event.equipment?.equipment_id || '-'}`, 98, top, { align: 'center' });
  doc.text(`${Math.round(event.miles_traveled || 0)}`, 117, top, { align: 'center' });
  doc.text(
    !!event.location && event.location.length > 40 ? `${event.location.slice(0, 40)}..` : `${event.location || '-'}`,
    126,
    top,
    { maxWidth: 34 }
  );
  doc.text(`${dateFormat.convertToCustomerTime(event.date)}`, 162, top, { maxWidth: 22 });
  doc.text(
    `${event.action_by?.first_name || event.action_by?.fname || '-'} ${
      event.action_by?.last_name || event.action_by?.lname || ''
    }`,
    pageWidth - 8,
    top,
    { align: 'right' }
  );
};

const createEventTableRows = (doc, data, top, dateFormat) => {
  if (!data?.length) {
    return;
  }

  let rowTop = top;
  let multiplier = 0;

  data.forEach((item) => {
    if (rowTop + multiplier * 8 > 260) {
      doc.addPage();
      createEventsTableHeader(doc, 16);
      multiplier = 0;
      rowTop = 23;
    }

    createEventTableRow(doc, item, rowTop + multiplier * 8, dateFormat);
    multiplier++;
  });

  return top + data.length * 8;
};

const createNotesTableHeader = (doc, top) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(7);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 700);
  doc.text('#', 8, top);
  doc.text('TITLE', 18, top);
  doc.text('NOTE', 50, top);
  doc.text('ADDED BY', 160, top);
  doc.text('DATE', pageWidth - 8, top, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.1);
  doc.line(8, top + 2, pageWidth - 8, top + 2);
};

const createNotesTableRow = (doc, note, top, dateFormat) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(8);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${note.id}`, 8, top);
  doc.text(`${note.title}`, 18, top, { maxWidth: 31 });
  doc.text(note.notes && note.notes.length > 290 ? `${note.notes.slice(0, 290)}..` : `${note.notes || '-'}`, 50, top, {
    maxWidth: 110,
  });
  doc.text(note.added_by ? `${note.added_by.first_name} ${note.added_by.last_name}` : '-', 160, top, { maxWidth: 30 });
  doc.text(note.created_at ? dateFormat.convertToCustomerTime(note.created_at) : '-', pageWidth - 8, top, {
    align: 'right',
    maxWidth: 22,
  });
};

const createNotesTableRows = (doc, data, top, dateFormat) => {
  if (!data?.length) {
    return;
  }

  let rowTop = top;
  let multiplier = 0;

  data.forEach((item) => {
    if (rowTop + multiplier * 15 > 250) {
      doc.addPage();
      createNotesTableHeader(doc, 16);
      multiplier = 0;
      rowTop = 23;
    }

    createNotesTableRow(doc, item, rowTop + multiplier * 15, dateFormat);
    multiplier++;
  });

  return top + data.length * 15;
};

export const generatePDF = (shipment, { currency, dateFormat }) => {
  if (!shipment) {
    return;
  }

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  const {
    shipment_id,
    shipment_billing,
    shipment_stops,
    empty_miles,
    loaded_miles,
    scheduled_duration,
    actual_duration,
    equipment,
    assigned_to_1,
    brokerage_dispatch,
    type,
    driver_amount,
    net_revenue,
    shipment_events,
    shipment_notes,
  } = shipment;
  const grossRevenue = shipment_billing.reduce((acc, current) => acc + (current.total_amount || 0), 0);
  const tags = shipment_billing
    .filter((item) => !!item.groups?.short_name)
    .map((item) => item.groups.short_name)
    .join(', ');
  const { formatDateTime, convertToCustomerTime } = dateFormat;

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Shipment Summary', pageWidth / 2, 12, { align: 'center' });
  doc.setFontSize(10);
  doc.text(
    `Shipment ${shipment_id || ''} - ${shipment_billing.map((item) => item.billing_customer.company_name).join(', ')}`,
    pageWidth / 2,
    18,
    { align: 'center' }
  );

  doc.setFontSize(8);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text(convertToCustomerTime(), pageWidth - 16, 22, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.3);
  doc.line(16, 24, pageWidth - 16, 24);

  doc.setFontSize(10);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.text('Type', 16, 30);
  doc.text('Reference', 16, 40);
  doc.text('Stops', 16, 50);
  doc.text('Equipment', 16, 60);

  doc.text('Empty Miles', pageWidth / 4, 30);
  doc.text('Loaded Miles', pageWidth / 4, 40);
  doc.text('Total Miles', pageWidth / 4, 50);

  doc.text('Gross Revenue', pageWidth / 2, 30);
  doc.text('Costs', pageWidth / 2, 40);
  doc.text('Net', pageWidth / 2, 50);
  doc.text('RPM', pageWidth / 2, 60);

  doc.text('Scheduled Duration', (3 * pageWidth) / 4, 30);
  doc.text('Actual Duration', (3 * pageWidth) / 4, 40);
  doc.text('Driver', (3 * pageWidth) / 4, 50);
  doc.text('Tags', (3 * pageWidth) / 4, 60);

  doc.setFontSize(9);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(type === 'ltl' ? 'LTL' : 'TL', 16, 34);
  doc.text(shipment_billing.length > 1 ? 'Multi-Ref' : `${shipment_billing[0].reference_id || '-'}`, 16, 44);
  doc.text(`${shipment_stops.length}`, 16, 54);
  if (equipment) {
    doc.text(
      `${equipment.equipment_id || 'TBD'} ${equipment.equipment_type?.title || ''}${
        equipment.truck_type ? ` (${TRUCK_TYPES[equipment.truck_type]})` : ''
      }${
        equipment.hooked_to
          ? ` ⇒ ${equipment.hooked_to?.equipment_id || 'TBD'} ${equipment.hooked_to?.length?.length} ${
              equipment.hooked_to?.length?.unit
            } ${equipment.hooked_to?.equipment_type?.title || ''}`
          : ''
      }`,
      16,
      64
    );
  } else {
    doc.text('-', 16, 64);
  }

  doc.text(`${Math.round(empty_miles)}`, pageWidth / 4, 34);
  doc.text(`${Math.round(loaded_miles)}`, pageWidth / 4, 44);
  doc.text(`${Math.round(empty_miles) + Math.round(loaded_miles)}`, pageWidth / 4, 54);

  doc.text(`${currency}${formatNumber(grossRevenue)}`, pageWidth / 2, 34);
  doc.text(`${currency}${formatNumber(driver_amount)}`, pageWidth / 2, 44);
  doc.text(`${currency}${net_revenue || 0}`, pageWidth / 2, 54);
  doc.text(
    `${currency}${formatNumber(
      shipment_billing.reduce((acc, cur) => Number(acc) + Number(cur.total_amount || 0), 0) /
        (Math.round(loaded_miles || 0) + Math.round(empty_miles || 0))
    )}`,
    pageWidth / 2,
    64
  );

  doc.text(`${scheduled_duration ? getAverageTimeConverted(scheduled_duration) : '-'}`, (3 * pageWidth) / 4, 34);
  doc.text(`${actual_duration || '-'}`, (3 * pageWidth) / 4, 44);
  if (brokerage_dispatch || assigned_to_1) {
    doc.text(
      brokerage_dispatch ? `${brokerage_dispatch.driver_name || '-'}` : `${assigned_to_1.fname} ${assigned_to_1.lname}`,
      (3 * pageWidth) / 4,
      54
    );
  } else {
    doc.text('-', (3 * pageWidth) / 4, 54);
  }
  doc.text(`${tags || '-'}`, (3 * pageWidth) / 4, 64);

  let currentPosition = 74;

  shipment_stops.forEach((stop, index) => {
    if (index === 8) {
      doc.addPage();
      currentPosition = 16;
    }

    const left = index % 2 === 0 ? 16 : pageWidth / 2;
    const { stop_point, stop_point_type } = stop;

    doc.setFontSize(8);
    doc.setTextColor(getStopName(stop_point_type).color);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`STOP ${getAlphabet(index)}${getStopName(stop_point_type).type}`, left, currentPosition);

    doc.setFontSize(8);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);
    doc.text(stop_point.location_name, left, currentPosition + 4);
    doc.text(
      `${stop_point?.address1 || ''} ${stop_point?.address2 || ''} ${stop_point?.city?.name || ''}, ${
        stop_point?.state?.short_name || ''
      } ${stop_point?.zipcode || ''}`,
      left,
      currentPosition + 8
    );

    doc.setFontSize(9);
    doc.setTextColor(palette.gray700);
    doc.setFont('Inter', 'normal', 400);
    doc.text('Scheduled Date', left, currentPosition + 14);
    doc.text('Arrival Date', left + 32, currentPosition + 14);
    doc.text('Departure Date', left + 60, currentPosition + 14);
    doc.text('Equipment Action', left + 60, currentPosition + 26);

    if (Number(stop.stop_point_type) === 1) {
      doc.text('Scheduled Pieces', left, currentPosition + 26);
      doc.text('Actual Pieces', left + 32, currentPosition + 26);

      doc.text('Scheduled Weight', left, currentPosition + 36);
      doc.text('Actual Weight', left + 32, currentPosition + 36);
    }

    if (Number(stop.stop_point_type) === 2) {
      doc.text('Seal ID', left, currentPosition + 26);
      doc.text('Bill of Lading ID', left + 32, currentPosition + 26);

      doc.text('Proof of Delivery', left, currentPosition + 36);
    }

    doc.setFontSize(8);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 700);
    doc.text(
      `${formatDateTime(`${stop.scheduled_date} ${stop.from}`)}${
        stop.to && [1, 3].includes(Number(stop.scheduled_type))
          ? ` to ${formatDateTime(`${stop.scheduled_date_to || stop.scheduled_date} ${stop.to}`)}`
          : ''
      }`,
      left,
      currentPosition + 18,
      { maxWidth: 30 }
    );
    doc.text(stop.arrival_date ? formatDateTime(stop.arrival_date) : '-', left + 32, currentPosition + 18, {
      maxWidth: 30,
    });
    doc.text(stop.departure_date ? formatDateTime(stop.departure_date) : '-', left + 60, currentPosition + 18, {
      maxWidth: 30,
    });

    if (Number(stop.stop_point_type) === 1) {
      doc.text(
        stop.quantity ? `${stop.quantity} ${QUANTITY_TYPE[stop.quantity_type]?.label || ''}` : '-',
        left,
        currentPosition + 30
      );
      doc.text(
        stop.reported_quantity ? `${stop.reported_quantity} ${QUANTITY_TYPE[stop.quantity_type]?.label || ''}` : '-',
        left + 32,
        currentPosition + 30
      );

      doc.text(stop.weight ? `${stop.weight} ${stop.weight_types?.unit || ''}` : '-', left, currentPosition + 40);
      doc.text(
        stop.reported_weight ? `${stop.reported_weight} ${stop.reported_weight_types?.unit || ''}` : '-',
        left + 32,
        currentPosition + 40
      );
    }

    if (Number(stop.stop_point_type) === 2) {
      doc.text(stop.reported_seal_id || '-', left, currentPosition + 30);
      doc.text(stop.bill_of_lading_id || '-', left + 32, currentPosition + 30);

      doc.text(stop.proof_of_delivery || '-', left, currentPosition + 40);
    }

    doc.text(
      (Number(stop.stop_point_type) === 1
        ? EQUIPMENT_ACTION
        : Number(stop.stop_point_type) === 2
        ? EQUIPMENT_ACTION_DELIVERY
        : EQUIPMENT_ACTION_WAYPOINT
      ).find((i) => i.key === Number(stop.equipment_action))?.label || '-',
      left + 60,
      currentPosition + 30
    );

    if (index % 2 === 1 || index === shipment_stops.length - 1) {
      currentPosition += 50;
    }
  });

  if (shipment_stops.length === 7 || shipment_stops.length === 8 || shipment_stops.length > 12) {
    doc.addPage();
    currentPosition = 11;
  }

  // let firstPageEventsCount = 0;
  //
  // switch (shipment_stops.length) {
  //   case 1:
  //   case 2:
  //     firstPageEventsCount = 16;
  //     break;
  //   case 3:
  //   case 4:
  //     firstPageEventsCount = 10;
  //     break;
  //   case 5:
  //   case 6:
  //     firstPageEventsCount = 4;
  //     break;
  //   case 7:
  //   case 8:
  //     firstPageEventsCount = 30;
  //     break;
  //   case 9:
  //   case 10:
  //     firstPageEventsCount = 22;
  //     break;
  //   case 11:
  //   case 12:
  //     firstPageEventsCount = 16;
  //     break;
  //   default:
  //     firstPageEventsCount = 30;
  // }

  let eventsTop = currentPosition;

  if (shipment_notes?.length) {
    if (currentPosition > 220) {
      doc.addPage();
      currentPosition = 16;
    }

    createNotesTableHeader(doc, currentPosition + 8);
    eventsTop = createNotesTableRows(doc, shipment_notes, currentPosition + 15, dateFormat);
  }

  if (shipment_events?.length) {
    if (eventsTop > 220) {
      doc.addPage();
      eventsTop = 16;
    }
    createEventsTableHeader(doc, eventsTop + 5);
    createEventTableRows(doc, shipment_events, eventsTop + 12, dateFormat);
  }

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
    // createTableHeader(doc);
  }

  doc.setDocumentProperties({ title: `Shipment Summary` });

  const url = doc.output('bloburi', { filename: `shipment-summary-${shipment_id}` });
  const blob = doc.output('blob');

  return { blob, url };
};
