import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import Input, { InputWithIcon } from 'common/Input';
import { Typography } from 'components/Typography';
import useForm from 'hooks/useForm';
import { useTheme } from 'context/themeContext';
import { absenceBasedOnOptions, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNotNumberChars } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import { createDriverAbsence, createStaffAbsence, getDriverAbsence, getStaffAbsence } from 'Api/CompanySettings';
import PageHeader from '../../../components/PageHeader';
import { absencePolicyValidationSchema } from '../validationSchemas';
import { initialValues } from './AbsencePolicy.data';
import { SWrapper, SCircularProgress } from '../Policies.styles';

const AbsencePolicy = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const updateAbsencePolicySettings = async (values) => {
    try {
      setLoadingUpdate(true);
      const driverBody = {
        reset_based_on: values.driver_reset_based_on.id,
        excused_absences: Number(values.driver_excused_absences),
        unexcused_absences: Number(values.driver_unexcused_absences),
        vacations_allowed: Number(values.driver_vacations_allowed),
        excused_reset_after_month: Number(values.driver_excused_reset_after_month),
        unexcused_reset_after_month: Number(values.driver_unexcused_reset_after_month),
        vacations_reset_after_month: Number(values.driver_vacations_reset_after_month),
      };
      const staffBody = {
        reset_based_on: values.staff_reset_based_on.id,
        excused_absences: Number(values.staff_excused_absences),
        unexcused_absences: Number(values.staff_unexcused_absences),
        vacations_allowed: Number(values.staff_vacations_allowed),
        excused_reset_after_month: Number(values.staff_excused_reset_after_month),
        unexcused_reset_after_month: Number(values.staff_unexcused_reset_after_month),
        vacations_reset_after_month: Number(values.staff_vacations_reset_after_month),
      };
      await Promise.all([createDriverAbsence(driverBody), createStaffAbsence(staffBody)]);
      showToaster({ type: 'success', message: 'Settings have been successfully updated' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
    setLoadingUpdate(false);
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors } = useForm({
    initialValues,
    validationSchema: absencePolicyValidationSchema,
    onSubmit: updateAbsencePolicySettings,
  });

  const getAbsencePolicySettings = async () => {
    try {
      setLoading(true);
      const [{ data: driverData }, { data: staffData }] = await Promise.all([getDriverAbsence(), getStaffAbsence()]);
      setValues({
        driver_reset_based_on: absenceBasedOnOptions.find((i) => i.id === driverData.reset_based_on) || null,
        driver_excused_absences: driverData.excused_absences || '',
        driver_unexcused_absences: driverData.unexcused_absences || '',
        driver_vacations_allowed: driverData.vacations_allowed || '',
        driver_excused_reset_after_month: driverData.excused_reset_after_month || '',
        driver_unexcused_reset_after_month: driverData.unexcused_reset_after_month || '',
        driver_vacations_reset_after_month: driverData.vacations_reset_after_month || '',
        staff_reset_based_on: absenceBasedOnOptions.find((i) => i.id === staffData.reset_based_on) || null,
        staff_excused_absences: staffData.excused_absences || '',
        staff_unexcused_absences: staffData.unexcused_absences || '',
        staff_vacations_allowed: staffData.vacations_allowed || '',
        staff_excused_reset_after_month: staffData.excused_reset_after_month || '',
        staff_unexcused_reset_after_month: staffData.unexcused_reset_after_month || '',
        staff_vacations_reset_after_month: staffData.vacations_reset_after_month || '',
      });
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAbsencePolicySettings();
  }, []);

  if (loading) {
    return (
      <SWrapper>
        <SCircularProgress size={26} />
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      <PageHeader
        title='Attendance Policy'
        isConfiguringStep={isConfiguringStep}
        onCancelPrevPage={onCancelPrevPage}
        onSaveAndNext={onSaveAndNext}
        handleSubmit={handleSubmit}
        loading={loadingUpdate}
      />
      <div className='mt-4'>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s1'>Drivers</Typography>
          </Grid>
          <div>
            <Autocomplete
              width='200px'
              name='driver_reset_based_on'
              placeholder='Based on..'
              options={absenceBasedOnOptions}
              value={values.driver_reset_based_on}
              onChange={(e, value) => handleChange('driver_reset_based_on', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.driver_reset_based_on}
            />
          </div>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Excused Absences
            </Typography>
          </Grid>
          <div className='d-flex align-items-center gap-2'>
            <InputWithIcon
              type='number'
              className='absence-input'
              icon='#'
              text='/year'
              name='driver_excused_absences'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.driver_excused_absences}
              error={touchedErrors.driver_excused_absences}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              resets every
            </Typography>
            <Input
              width='100px'
              type='number'
              name='driver_excused_reset_after_month'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.driver_excused_reset_after_month}
              error={touchedErrors.driver_excused_reset_after_month}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              months
            </Typography>
          </div>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Unexcused Absences
            </Typography>
          </Grid>
          <div className='d-flex align-items-center gap-2'>
            <InputWithIcon
              type='number'
              className='absence-input'
              icon='#'
              text='/year'
              name='driver_unexcused_absences'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.driver_unexcused_absences}
              error={touchedErrors.driver_unexcused_absences}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              resets every
            </Typography>
            <Input
              width='100px'
              type='number'
              name='driver_unexcused_reset_after_month'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.driver_unexcused_reset_after_month}
              error={touchedErrors.driver_unexcused_reset_after_month}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              months
            </Typography>
          </div>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Paid Time Off (PTO)
            </Typography>
          </Grid>
          <div className='d-flex align-items-center gap-2'>
            <InputWithIcon
              type='number'
              className='absence-input'
              icon='#'
              text='/year'
              name='driver_vacations_allowed'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.driver_vacations_allowed}
              error={touchedErrors.driver_vacations_allowed}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              resets every
            </Typography>
            <Input
              width='100px'
              type='number'
              name='driver_vacations_reset_after_month'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.driver_vacations_reset_after_month}
              error={touchedErrors.driver_vacations_reset_after_month}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              months
            </Typography>
          </div>
        </Grid>
      </div>
      <div className='staff-attendance-section'>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s1'>Staff</Typography>
          </Grid>
          <div>
            <Autocomplete
              width='200px'
              name='staff_reset_based_on'
              placeholder='Based on..'
              options={absenceBasedOnOptions}
              value={values.staff_reset_based_on}
              onChange={(e, value) => handleChange('staff_reset_based_on', value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              error={touchedErrors.staff_reset_based_on}
            />
          </div>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Excused Absences
            </Typography>
          </Grid>
          <div className='d-flex align-items-center gap-2'>
            <InputWithIcon
              type='number'
              className='absence-input'
              icon='#'
              text='/year'
              name='staff_excused_absences'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.staff_excused_absences}
              error={touchedErrors.staff_excused_absences}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              resets every
            </Typography>
            <Input
              width='100px'
              type='number'
              name='staff_excused_reset_after_month'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.staff_excused_reset_after_month}
              error={touchedErrors.staff_excused_reset_after_month}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              months
            </Typography>
          </div>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Unexcused Absences
            </Typography>
          </Grid>
          <div className='d-flex align-items-center gap-2'>
            <InputWithIcon
              type='number'
              className='absence-input'
              icon='#'
              text='/year'
              name='staff_unexcused_absences'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.staff_unexcused_absences}
              error={touchedErrors.staff_unexcused_absences}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              resets every
            </Typography>
            <Input
              width='100px'
              type='number'
              name='staff_unexcused_reset_after_month'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.staff_unexcused_reset_after_month}
              error={touchedErrors.staff_unexcused_reset_after_month}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              months
            </Typography>
          </div>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Paid Time Off (PTO)
            </Typography>
          </Grid>
          <div className='d-flex align-items-center gap-2'>
            <InputWithIcon
              type='number'
              className='absence-input'
              icon='#'
              text='/year'
              name='staff_vacations_allowed'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.staff_vacations_allowed}
              error={touchedErrors.staff_vacations_allowed}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              resets every
            </Typography>
            <Input
              width='100px'
              type='number'
              name='staff_vacations_reset_after_month'
              onChange={handleChange}
              onKeyDown={blockNotNumberChars}
              value={values.staff_vacations_reset_after_month}
              error={touchedErrors.staff_vacations_reset_after_month}
            />
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              months
            </Typography>
          </div>
        </Grid>
      </div>
    </SWrapper>
  );
};

export default AbsencePolicy;
