export const reportItemsConverter = (reportItems, payees) => {
  return (reportItems || [])
    .filter((item) => !!item.transactions?.length)
    .map((account) => {
      return {
        ...account,
        transactions: account.transactions.map((transaction) => {
          return {
            ...transaction,
            payee:
              (payees || []).find(
                (i) => Number(i.id) === Number(transaction.payee_id) && i.type === transaction.payee_type
              ) || null,
          };
        }),
      };
    });
};

export const transformReportItemsCsv = (reportItems) => {
  const transformed = [];

  reportItems.forEach((item) => {
    const itemWithoutTransactions = { ...item, isAccount: true };
    delete itemWithoutTransactions.transactions;

    transformed.push(itemWithoutTransactions);

    item.transactions.forEach((transaction) => {
      transformed.push(transaction);
    });
  });

  return transformed;
};

export const transformReportItemsPdf = (reportItems) => {
  const transformed = [];

  reportItems.forEach((item, index) => {
    const itemWithoutTransactions = { ...item, isAccount: true };
    delete itemWithoutTransactions.transactions;

    if (index !== 0) {
      transformed.push({ isBlank: true }, { isBlank: true });
    }
    transformed.push(itemWithoutTransactions);

    item.transactions.forEach((transaction) => {
      transformed.push(transaction);
    });
  });

  return transformed;
};
