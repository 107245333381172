import React from 'react';
import NEC from './ReportDetails/NEC';
import Cost from './ReportDetails/Cost';
import VendorList from './ReportDetails/VendorList';
import DrugAlcohol from './ReportDetails/DrugAlcohol';
import AverageWaitTime from './ReportDetails/AverageWaitTime';
import FleetSafestYear from './ReportDetails/FleetSafestYear';
import MaintenanceCost from './ReportDetails/MaintenanceCost';
import AssetPerformance from './ReportDetails/AssetPerformance';
import CardTransactions from './ReportDetails/CardTransactions';
import AccidentRegister from './ReportDetails/AccidentRegister';
import CustomerStatement from './ReportDetails/CustomerStatement';
import PayableAgingDetail from './ReportDetails/PayableAgingDetail';
import AccountTransactions from './ReportDetails/AccountTransactions';
import CustomerSalesDetail from './ReportDetails/CustomerSalesDetail';
import CustomerSalesSummary from './ReportDetails/CustomerSalesSummary';

export const detailsPageMapper = {
  4: <CustomerSalesDetail />,
  10: <CustomerSalesSummary />,
  12: <Cost title='Administrative Cost' costTypeId={3} />,
  13: <VendorList title='Account Payable Aging Summary' />,
  15: <MaintenanceCost />,
  16: <Cost title='Variable Cost' costTypeId={2} />,
  17: <PayableAgingDetail />,
  19: <Cost title='Fixed Cost' costTypeId={1} />,
  24: <NEC />,
  25: <AccountTransactions />,
  26: <CardTransactions />,
  31: <VendorList title='Vendor List' />,
  35: <AssetPerformance />,
  36: <CustomerStatement />,
  37: <AverageWaitTime />,
  38: <FleetSafestYear title='Fleet Safest Year' />,
  39: <AccidentRegister />,
  40: <DrugAlcohol />,
};

export const reportTitleMapper = {
  'Driver Average Wait Time': 'Arrivals and Departures',
};
