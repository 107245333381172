import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SDocuments = styled.div`
  border-bottom: 1px solid rgba(239, 237, 237, 0.656);

  .equipment-close {
    background: ${palette.white};
    box-shadow: ${palette.boxShadow2};
    border-radius: 4px;
    border: none;
    margin-top: 5px;
  }

  .equipment-save {
    background: ${palette.indigo500};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 0 1px #4f5aed, 0 2px 5px rgba(79, 90, 237, 0.1),
      inset 0 1px 0 #767ffb;
    border-radius: 4px;
    border: none;
    margin-left: 10px;
    margin-top: 5px;
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    color: ${palette.gray700};
    white-space: nowrap;
    color: ${palette.gray700};
    font-family: Inter, sans-serif;
    padding: 12px 8px;
    border-bottom: 1px solid ${palette.gray50};

    :first-child {
      min-width: 120px;
    }

    :last-child {
      min-width: 145px;
      text-align: right;
    }
  }

  td {
    padding: 6px 8px;
    border: none;
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell {
    width: 100px;
  }
`;
