import React, { useContext, useEffect, useState } from 'react';
import useForm from 'hooks/useForm';
import PlanAheadForm from 'componentsV2/Shipment/PlanAhead';
import CustomButton from 'components/CustomButton/CustomButton';
import { ShipmentContext } from 'components/CreateShipment/Layout';
import { validationSchema } from 'componentsV2/Shipment/PlanAhead/validationSchema';
import { getShipmentOfferSettings } from 'Api/CompanySettings';
import { getInitialValues, planAheadDataConverter } from 'componentsV2/Shipment/PlanAhead/PlanAhead.data';
import useShowToaster from 'hooks/useShowToaster';
import { SPlanAhead } from './PlanAhead.styles';

const PlanAhead = () => {
  const showToaster = useShowToaster();
  const [offerSettings, setOfferSettings] = useState(null);
  const {
    setStep,
    createShipmentData,
    onCreate,
    shipmentCreateLoading,
    onCloseModal,
    selectedCharges,
    updateSelectedTotalAmount,
  } = useContext(ShipmentContext);
  const billingAmount = createShipmentData?.billingCharges?.reduce((a, b) => a + Number(b.qty) * Number(b.rate), 0);

  const onSubmit = (values) => {
    if (
      [2, 3].includes(values.plan_ahead_type?.id) &&
      !values.immediately &&
      !values.next_in_line &&
      !values.before_scheduled_pickup
    ) {
      showToaster({ type: 'error', message: 'One of the dispatch options should be selected!' });
      return;
    }

    const body = planAheadDataConverter(values);
    onCreate({ lastStepData: { lane_plan_ahead: body }, isLTL: Number(createShipmentData.type) === 2 });
  };

  const form = useForm({
    initialValues: getInitialValues(null, offerSettings),
    onSubmit,
    validationSchema,
  });

  const getOfferSettings = async () => {
    try {
      const { data: settingsData } = await getShipmentOfferSettings();
      setOfferSettings(settingsData);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getOfferSettings();
  }, []);

  useEffect(() => {
    if (Number(createShipmentData?.type) === 2) {
      const subTotal = {};
      Object.keys(selectedCharges).forEach((key) => {
        const charges = selectedCharges[key];
        subTotal[key] = 0;
        charges.forEach((charge) => {
          subTotal[key] = +subTotal[key] + Number(charge.qty) * Number(charge.rate);
        });
      });

      updateSelectedTotalAmount(subTotal);
    } else {
      const subTotal = { 0: 0 };
      selectedCharges[0]?.forEach((charge) => {
        if (charge?.qty && charge?.rate) {
          subTotal[0] = +subTotal[0] + Number(charge.qty) * Number(charge.rate);
        }
      });
      updateSelectedTotalAmount(subTotal);
    }
  }, [selectedCharges]);

  return (
    <SPlanAhead>
      <PlanAheadForm form={form} customerId={createShipmentData?.customer_id} billingAmount={billingAmount} />
      <div className='plan-ahead-footer'>
        <CustomButton
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '6px 12px' }}
          styleTitle={{ fontSize: 14 }}
          onClick={onCloseModal}
        />
        <div className='d-flex'>
          <CustomButton
            type='secondary'
            title='Go Back'
            styleButton={{ padding: '6px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => setStep((p) => p - 1)}
          />
          <CustomButton
            type='primary'
            title='Create Recurring Lane'
            styleButton={{ padding: '6px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={form.handleSubmit}
            buttonProps={{ type: 'submit' }}
            disabled={shipmentCreateLoading}
          />
        </div>
      </div>
    </SPlanAhead>
  );
};

export default PlanAhead;
