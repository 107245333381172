import React from 'react';
import { useSelector } from 'react-redux';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import UserInfo from 'components/StopPoint/UserInfo';
import { Typography } from 'components/Typography';
import { DocumentTableSort } from 'components/DocumentsTableSort/DocumentsTableSort';
import { formatAmount } from 'components/Customers/helpers/CustomersTableHeaderFilterByShow';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { formatPhoneNumber } from 'utils/helpers';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import hexRgb from 'hex-rgb';

export const initialFilters = {
  tab: 0,
  page: 1,
  itemsPerPage: 50,
  types: [],
  payTerm: [],
};

export const allTab = {
  key: 'all',
  label: 'All',
  value: 0,
  id: 0,
  count: 0,
};

export const mapperSettingsData = (key) => {
  const map = {
    customer_id: 'Customer ID',
    company_name: 'Company',
    address: 'Address',
    telephone_number: 'Phone Number',
    status_id: 'Status',
    balance: 'Balance',
    customer_payment_term: 'Pay Terms',
    credit_line_maximum: 'Credit Line Maximum',
    aging_average: 'Aging',
    general_ledger_id: 'General Ledger ID',
    primary_contact_name: 'Primary Contact Name',
    group_id: 'Tags',
  };
  return map[key] ? map[key] : key;
};

export const useColumns = ({ sortingQuery }) => {
  const { use } = useTheme();
  const hasQuickbook = useHasQuickbook();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'customer_id',
      title: <DocumentTableSort title='Customer ID' field='customer_id' sortingQuery={sortingQuery} />,
      render: (rowData) => (
        <div className='d-flex flex-row align-items-center' key={rowData.id}>
          <span className='tablePlaner_shipment' style={{ color: use(palette.dark800, palette.gray200) }}>
            {rowData.customer_id || '-'}
          </span>
        </div>
      ),
    },
    {
      field: 'group_id',
      title: <Typography variant='c3'>TAGS</Typography>,
      render: (rowData) => {
        const groups = rowData?.customer_group;
        return (
          <div className={`flex-row d-flex table_planer_groups_wrapper  ${rowData.id ? 'rowData_wrapper' : ''}`}>
            {groups?.length ? (
              [...(groups || [])]?.map((group) => {
                const colorCode = hexRgb(group.color);
                const TextColor =
                  colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70 ? 'white' : 'rgb(23, 28, 38)';
                return (
                  <div className='user-data-group mb-2' key={group.color} style={{ backgroundColor: group?.color }}>
                    <p className='group-text' style={{ color: TextColor }}>
                      {group?.short_name}
                    </p>
                  </div>
                );
              })
            ) : (
              <div
                className='user-data-group'
                style={{ backgroundColor: groups?.color ? groups?.color : 'transparent' }}
              >
                <p className='group-text'>{groups?.short_name}</p>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'company_name',
      title: <DocumentTableSort title='Company' field='company_name' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const companyName = rowData.company_name;
        const quickbooksId = rowData.qb_sync;
        return (
          <div className='d-flex align-items-center gap-2'>
            <span style={{ color: use(palette.dark800, palette.gray200) }}>{companyName || '-'}</span>
            {!!quickbooksId && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
          </div>
        );
      },
    },
    {
      field: 'address',
      title: <DocumentTableSort title='Address' field='address1' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const { address1 } = rowData;
        const { address2 } = rowData;
        const city = rowData.city?.name;
        const state = rowData.state?.name;
        const { zipcode } = rowData;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {address1 ? `${address1}, ` : '-'}
            {address2 && `${address2}, `}
            {city && `${city}, `}
            {state && `${state}, `}
            {state && `${zipcode}`}
          </span>
        );
      },
    },
    {
      field: 'primary_contact_name',
      title: (
        <DocumentTableSort title='Primary Contact Name' field='primary_contact_name' sortingQuery={sortingQuery} />
      ),
      render: (rowData) => {
        const {
          contact_name: name,
          contact_email: email,

          phone_number,
        } = rowData.customer_contact_book?.[0] || {};

        const contactInfo = {
          name,
          email,
          phone_number,
        };

        return (
          <div
            style={{ display: 'flex', flexDirection: 'column', color: use(palette.dark800) }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {rowData.customer_contact_book?.[0] ? (
              <UserInfo data={contactInfo} />
            ) : (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <span>-</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'telephone_number',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
          PHONE NUMBER
        </Typography>
      ),
      render: (rowData) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Typography variant='b2' className='list-driver-phone'>
            <a href={`tel:${rowData.telephone_number}`}>
              {!rowData.telephone_number
                ? '-'
                : rowData.telephone_number.startsWith('+')
                ? formatPhoneNumber(rowData.telephone_number)
                : formatPhoneNumber(`+${rowData.telephone_number}`)}
            </a>
          </Typography>
        </div>
      ),
    },
    {
      field: 'status_id',
      title: <DocumentTableSort title='Status' field='status_id' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const { status } = rowData;

        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            <span
              className='tablePlaner_status'
              style={{
                backgroundColor: status.background_color,
                color: status.color,
                padding: '2px 6px',
                borderRadius: '4px',
              }}
            >
              {status.title || '-'}
            </span>
          </span>
        );
      },
    },
    {
      field: 'customer_payment_term',
      title: (
        <Typography variant='overLine' style={{ color: use(palette.gray900, palette.gray200) }}>
          PAY TERMS
        </Typography>
      ),
      render: (rowData) => {
        const terms = rowData.customer_payment_term?.pay_term_types;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{getPaymentTermTitle(terms)}</span>;
      },
    },
    {
      field: 'balance',
      title: (
        <DocumentTableSort
          title='Balance'
          field='open_balance_aging_avarage'
          nested_field='balance'
          sortingQuery={sortingQuery}
        />
      ),
      render: (rowData) => {
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {currency} {formatAmount(Number(rowData.balance || 0))}
          </span>
        );
      },
    },
    {
      field: 'credit_line_maximum',
      title: (
        <DocumentTableSort
          title='Credit Line Maximum'
          field='credit_line_maximum'
          nested_field='customer_payment_term'
          sortingQuery={sortingQuery}
        />
      ),
      render: (rowData) => {
        const creditLineMaximum = rowData.customer_payment_term?.credit_line_maximum;
        return (
          <span style={{ color: use(palette.dark800, palette.gray200) }}>
            {creditLineMaximum ? `${currency} ${formatAmount(creditLineMaximum)}` : '-'}
          </span>
        );
      },
    },
    {
      field: 'aging_average',
      title: <DocumentTableSort title='Aging' field='aging_average' sortingQuery={sortingQuery} />,
      render: (row) => {
        return (
          <span
            style={{
              color: use(palette.dark800, palette.gray200),
            }}
          >
            {row.aging_average || '-'}
          </span>
        );
      },
    },
    {
      field: 'general_ledger_id',
      title: <DocumentTableSort title='General Ledger ID' field='general_ledger_id' sortingQuery={sortingQuery} />,
      render: (rowData) => {
        const generalLedgerId = rowData.general_ledger_id;
        return <span style={{ color: use(palette.dark800, palette.gray200) }}>{generalLedgerId || '-'}</span>;
      },
    },
  ];
};
