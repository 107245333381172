import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: ${palette.gray300};

  .MuiChip-label {
    font-size: 11px;
    font-weight: 500;
  }
`;
