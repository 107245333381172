import moment from 'moment';

export const getInitialValues = (bill, vendor, isRecurring) => {
  const {
    reference_id,
    date_entered,
    amount_due,
    bill_due_date,
    is_recurring,
    recurring_days,
    repeat_every_times,
    due_day,
    due_day_category,
    end_date,
    recurring_months,
    recurring_duration,
    recurring_frequency,
    payment_term,
    attachments,
    memo,
    items,
  } = bill || {};

  let dueDate;
  const recurringMonths = recurring_months ? recurringOptions.find((i) => i.value === recurring_months) || null : null;
  const dueDayCategory = due_day_category ? dueOptions.find((i) => i.value === due_day_category) || null : null;

  if (isRecurring && recurringMonths && bill_due_date) {
    dueDate = moment(bill_due_date).subtract(recurringMonths.amount, recurringMonths.unit).toDate();
  }

  return {
    vendor: bill?.vendor || vendor || null,
    reference_id: reference_id || '',
    items: items
      ? items.map((item, index) => ({
          id: index,
          item: item.item || null,
          desc: item.desc || '',
          quantity: item.quantity || '',
          price: item.price || '',
        }))
      : [{ id: Date.now(), item: null, desc: '', quantity: '', price: '' }],
    date_entered: date_entered ? moment(date_entered).toDate() : new Date(),
    amount_due: amount_due || '',
    bill_due_date: dueDate || (bill_due_date ? moment(bill_due_date).toDate() : null),

    is_recurring: !!isRecurring || !!is_recurring,
    repeat_every_times: repeat_every_times || '',
    recurring_months: recurringMonths,
    next_renew_date: date_entered ? moment(date_entered).toDate() : new Date(),
    due_day: due_day || '',
    due_day_category: dueDayCategory || null,
    end_date: end_date ? moment(end_date).toDate() : null,

    recurring_days: recurring_days || '',
    recurring_duration: recurring_duration || 1,
    recurring_frequency: recurring_frequency || '',

    payment_term: payment_term || bill?.vendor?.payment_term || vendor?.payment_term || null,
    attachments: attachments || [],
    memo: memo || '',
  };
};

export const getRequestBody = (values) => {
  const {
    vendor,
    reference_id,
    date_entered,
    payment_term,
    bill_due_date,
    is_recurring,
    recurring_months,
    repeat_every_times,
    due_day,
    due_day_category,
    end_date,
    next_renew_date,
    attachments,
    memo,
    items,
  } = values || {};

  const formData = new FormData();

  const amount_due = items.reduce((acc, cur) => {
    if (cur.quantity && cur.price) {
      acc += Number(cur.quantity) * Number(cur.price);
    }
    return acc;
  }, 0);

  formData.append('vendor_id', vendor.id);
  formData.append('reference_id', reference_id);
  formData.append('date_entered', moment(date_entered).format('YYYY-MM-DD'));
  formData.append('amount_due', amount_due);
  formData.append('payment_term_id', payment_term.id);
  formData.append('bill_due_date', moment(bill_due_date).format('YYYY-MM-DD'));
  if (attachments?.length) {
    attachments.forEach((attachment) => {
      if (attachment?.path && !attachment?.type) {
        formData.append(`attachments[]`, attachment.file);
      } else {
        formData.append(`attachments[]`, attachment);
      }
    });
  }
  if (memo) {
    formData.append('memo', memo);
  }
  formData.append('is_recurring', is_recurring ? '1' : '0');
  if (is_recurring) {
    formData.append('recurring_days', '0');
    formData.append('recurring_months', recurring_months.value);
    formData.append('repeat_every_times', repeat_every_times);
    formData.append('recurring_duration', '1');
    formData.append('recurring_frequency', '0');
    formData.append('due_day', due_day);
    formData.append('due_day_category', due_day_category.value);
    if (end_date) {
      formData.append('end_date', moment(end_date).format('YYYY-MM-DD'));
    }
    formData.append('next_renew_date', moment(next_renew_date).format('YYYY-MM-DD'));
  }

  items.forEach((item, index) => {
    formData.append(`items[${index}][item_id]`, item.item.id);
    formData.append(`items[${index}][desc]`, item.desc);
    formData.append(`items[${index}][quantity]`, item.quantity);
    formData.append(`items[${index}][price]`, item.price);
  });

  return formData;
};

export const recurringOptions = [
  { name: 'Week(s)', value: 'Weekly', days: 7, amount: 1, unit: 'weeks' },
  { name: 'Month(s)', value: 'Monthly', days: 30, amount: 1, unit: 'months' },
];

export const dueOptions = [
  { name: 'of the following month', value: 'following_month' },
  { name: 'day(s) after the bill date', value: 'day_after_bill_date' },
  { name: 'of the current month', value: 'current_month' },
];
