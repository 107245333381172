export const getInitialValues = (data) => {
  const {
    name,
    job_applicant_type,
    states,
    status,
    description,
    federal_frca_summary_of_rights_acknowledgement,
    psp_disclosure_and_authorization,
    frca_disclosure,
    frca_authorization,
    employment_verification_acknowledgement,
    require_medical_examiners_card,
    questions,
    requirements,
  } = data || {};

  return {
    name: name || '',
    job_applicant_type: job_applicant_type ? { id: job_applicant_type.id, name: job_applicant_type.name } : null,
    selectedStates: states?.map((item) => item.state) || [],
    status: Number(status?.id) || null,
    description: description || '',
    federal_frca_summary_of_rights_acknowledgement: !!federal_frca_summary_of_rights_acknowledgement,
    psp_disclosure_and_authorization: !!psp_disclosure_and_authorization,
    frca_disclosure: !!frca_disclosure,
    frca_authorization: !!frca_authorization,
    employment_verification_acknowledgement: !!employment_verification_acknowledgement,
    require_medical_examiners_card: !!require_medical_examiners_card,
    question_ids: (questions || []).map((i) => i.id),
    requirement_ids: (requirements || []).map((i) => i.id),
  };
};

export const createPositionConverter = (values) => {
  const {
    name,
    job_applicant_type,
    selectedStates,
    status,
    description,
    federal_frca_summary_of_rights_acknowledgement,
    psp_disclosure_and_authorization,
    frca_disclosure,
    frca_authorization,
    employment_verification_acknowledgement,
    require_medical_examiners_card,
    question_ids,
    requirement_ids,
  } = values || {};

  const statesIds = selectedStates.map((item) => item.id);

  return {
    name,
    job_applicant_type_id: job_applicant_type.id,
    description,
    status_id: status,
    state_id: statesIds,
    federal_frca_summary_of_rights_acknowledgement: Number(federal_frca_summary_of_rights_acknowledgement),
    psp_disclosure_and_authorization: Number(psp_disclosure_and_authorization),
    frca_disclosure: Number(frca_disclosure),
    frca_authorization: Number(frca_authorization),
    employment_verification_acknowledgement: Number(employment_verification_acknowledgement),
    require_medical_examiners_card: Number(require_medical_examiners_card),
    question_ids,
    requirement_ids,
  };
};

export const applicantTypes = [
  { id: 1, name: 'Driver' },
  { id: 3, name: 'Staff' },
  { id: 2, name: 'Mechanic' },
];

export const acknowledgements = [
  {
    id: 1,
    label: 'Federal FCRA Summary of Rights Acknowledgement',
    name: 'federal_frca_summary_of_rights_acknowledgement',
  },
  { id: 2, label: 'PSP Disclosure and Authorization', name: 'psp_disclosure_and_authorization' },
  { id: 3, label: 'FRCA Disclosure', name: 'frca_disclosure' },
  { id: 4, label: 'FRCA Authorization', name: 'frca_authorization' },
  {
    id: 5,
    label: 'Employment Verification Acknowledgment and Release (DOT Drug and Alcohol)',
    name: 'employment_verification_acknowledgement',
  },
];
