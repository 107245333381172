import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';
import RowActions from 'componentsV2/Customer/SalesAgents/RowActions';

export const useColumns = ({ onEdit, onDelete }) => {
  const { currency } = useSelector((state) => state.root);
  const { dateFormat } = useDateFormat();

  return [
    {
      field: 'name',
      title: <ColumnHeader title='NAME' field='name' />,
      render: (row) => (
        <Typography variant='s3' style={{ color: palette.gray700 }}>
          {row.user?.staff?.first_name} {row.user?.staff?.last_name}
        </Typography>
      ),
    },
    {
      field: 'type',
      title: <ColumnHeader title='PAYMENT STRUCTURE' field='type' />,
      render: (row) => {
        return (
          <div>
            <Typography variant='s3' style={{ color: palette.gray700 }}>
              {row.type === 1 && `Flat Per Shipment $${row.flat_per_shipment}`}
              {row.type === 2 && `${row.percentage_per_shipment}% of Shipment Gross`}
              {row.type === 3 && `${row.percentage_after_driver_pay}% of Shipment After Driver Pay`}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'total_shipments',
      title: <ColumnHeader title='TOTAL SHIPMENTS' field='total_shipments' />,
      render: (row) => {
        return (
          <Typography variant='s3' style={{ color: palette.gray700 }}>
            {row.total_shipment_count || 0}
          </Typography>
        );
      },
    },
    {
      field: 'earnings',
      title: <ColumnHeader title='LIFE TIME EARNINGS' field='earnings' />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {currency}
            {formatNumber(
              Number(row.total_flat_per_shipment || 0) +
                Number(row.total_percentage_per_shipment || 0) +
                Number(row.total_percentage_after_driver_pay || 0)
            )}
          </Typography>
        );
      },
    },
    {
      field: 'end_date',
      title: <ColumnHeader title='END DATE' field='end_date' />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.is_active ? 'While Active' : moment(row.end_date).format(dateFormat)}
          </Typography>
        );
      },
    },
    {
      field: '',
      render: (row) => <RowActions onEdit={() => onEdit(row)} onDelete={() => onDelete(row)} />,
    },
  ];
};
