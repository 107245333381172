import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, numberWithCommas, palette } from 'utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';

import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import {
  createCarrierPay,
  createDriverPay,
  deleteCarrierPay,
  deleteDriverPay,
  getCarrierPays,
  getDriverPays,
  updateCarrierPay,
  updateDriverPay,
} from 'Api/Shipment';
import useShowToaster from 'hooks/useShowToaster';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import EquipmentPreloaderSkeleton from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import AdditionsSettings from 'pages/Payroll/Settlement/Tables/Additions/components/AdditionsSettings';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { getAdditionsList } from 'Api/PayrollSettings';
import { reviseConfirmation } from 'Api/Carriers';
import { getErrorMessage } from 'utils/error';
import { getCarrierPayTypes } from 'Api/Planner';
import useShipmentLocked from 'hooks/useShipmentLocked';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import classes from './driverPays.module.css';
import EditDriverPayDot from './editDriverPayDot';
import CustomButton from '../../../../CustomButton/CustomButton';

const driverCompensationTypes = ['empty_miles', 'loaded_miles', 'per_hour', 'flat_rate', 'percentage'];

const DriverPays = ({ infoHeader, getHeaderInfo, isModalView, driverPayRefreshIndex }) => {
  const navigate = useNavigate();
  const { isShipmentLocked } = useShipmentLocked();
  const { brokerage_dispatch } = infoHeader || {};
  const { carrier, revise_confirmation } = brokerage_dispatch || {};
  const { currency } = useSelector((state) => state.root);
  const showToaster = useShowToaster();
  const { theme, use } = useTheme();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [driverPay, setDriverPay] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [additions, setAdditions] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const total = useMemo(() => {
    return driverPay?.reduce((acc, cur) => {
      if (editingRow?.id === cur.id) {
        return acc + (editingRow?.rate || 0) * (editingRow?.quantity || 0);
      }

      return acc + (cur?.rate || 0) * (cur?.quantity || 0);
    }, 0);
  }, [driverPay, editingRow]);

  const onDelete = (item) => {
    (carrier ? deleteCarrierPay(item.id) : deleteDriverPay(item.id))
      .then(() => {
        getHeaderInfo();
        showToaster({ type: 'success', message: 'Success' });
        getCols();
      })
      .catch(() => {
        // Do nothing
      });
  };

  const onAdd = () => {
    const newList = driverPay.slice(0, driverPay.length - 2);
    newList.push({
      isNew: true,
      description: null,
      rate: '',
      quantity: '',
      driver: drivers?.[0] || null,
      addition_type: null,
      total: '',
      id: Math.random(),
    });
    newList.push({ description: '', id: 'add' });
    newList.push({ description: 'Total', id: 'total' });
    setDriverPay(newList);
  };

  const onDeleteAdded = (id) => {
    const newList = driverPay.filter((item) => item.id !== id);
    setDriverPay(newList);
  };

  const getAdditions = async () => {
    try {
      if (carrier) {
        const params = {
          'type_id[]': 1,
          charges_for: 'carrier_pay',
        };
        const { data } = await getCarrierPayTypes(params);
        setAdditions(data || []);
      } else {
        const { data } = await getAdditionsList();
        setAdditions(data || []);
      }
    } catch (e) {
      // Do nothing
    }
  };

  const addDriverPay = async (rowData) => {
    try {
      if (rowData.isNew) {
        if (carrier) {
          const body = {
            shipment_id: id || infoHeader?.shipment_id,
            type_id: rowData.addition_type?.id,
            carrier_id: carrier.id,
            quantity: rowData?.quantity,
            rate: rowData?.rate,
          };
          await createCarrierPay(body);
        } else {
          const body = {
            shipment_id: id || infoHeader?.shipment_id,
            addition_id: rowData.addition_type?.id,
            driver_id: rowData?.driver?.id,
            quantity: rowData?.quantity,
            rate: rowData?.rate,
            type: rowData?.type,
          };
          await createDriverPay(body);
        }

        getCols();
        getHeaderInfo();
        setDriverPay((prevState) =>
          prevState.map((i) =>
            i.id === rowData.id
              ? {
                  ...i,
                  isNew: false,
                }
              : i
          )
        );
      } else {
        if (carrier) {
          const body = {
            shipment_id: id || infoHeader?.shipment_id,
            type_id: editingRow?.addition_type?.id || editingRow?.type_name?.id,
            carrier_id: carrier.id,
            quantity: editingRow?.quantity,
            rate: editingRow?.rate,
          };
          await updateCarrierPay(body, rowData.id);
        } else {
          const body = {
            shipment_id: id || infoHeader?.shipment_id,
            addition_id: editingRow.addition_type?.id,
            driver_id: editingRow.driver?.id,
            quantity: editingRow?.quantity,
            rate: editingRow?.rate,
            type: rowData?.type,
          };
          await updateDriverPay(body, rowData.id);
        }

        getCols();
        getHeaderInfo();
        showToaster({ type: 'success', message: 'Success' });
        setEditingRow(null);
        setDriverPay((prevState) => prevState.map((i) => (i.id === rowData.id ? editingRow : i)));
      }
    } catch (e) {
      // Do nothing
    }
  };

  const onEdit = (item) => {
    setEditingRow(item);
  };

  const onSettingsClose = () => {
    getAdditions();
    setSettingsOpen(false);
  };

  const onSuccess = () => {
    getHeaderInfo();
    getCols();
  };

  const onFieldChange = (rowData, filed, value) => {
    if (rowData.isNew) {
      setDriverPay((prevState) =>
        prevState.map((item) => (item.id === rowData.id ? { ...item, [filed]: value } : item))
      );
      return;
    }

    setEditingRow((prevState) => ({ ...prevState, [filed]: value }));
  };

  const revise = () => {
    const formData = new FormData();
    formData.append('shipment_id', id || infoHeader?.shipment_id);
    reviseConfirmation(formData)
      .then(() => {
        getHeaderInfo();
        showToaster({ type: 'success', message: 'Success' });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'description',
        title: (
          <div className='d-flex gap-2'>
            <Typography variant='c3'>DESCRIPTION</Typography>
            <GearIcon
              onClick={() => {
                carrier
                  ? navigate('/company-settings', { state: { tabId: 2, subTabName: 'ShipmentTypes' } })
                  : setSettingsOpen(true);
              }}
              className='settings-gear'
            />
          </div>
        ),
        render: (rowData) => {
          if (editingRow?.id === rowData.id || rowData.isNew) {
            return driverCompensationTypes.some((type) => type === rowData.type) ? (
              <div className={classes.colWrapper}>
                <Typography variant='s3'>{rowData.description}</Typography>
              </div>
            ) : (
              <Autocomplete
                name='addition_type'
                labelKey={carrier ? 'title' : 'addition_type'}
                options={additions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={
                  rowData.isNew
                    ? carrier
                      ? rowData.type_name
                      : rowData?.addition_type || null
                    : carrier
                    ? editingRow?.type_name
                    : editingRow?.addition_type || null
                }
                onChange={(e, value) => {
                  onFieldChange(rowData, 'addition_type', value);
                  onFieldChange(rowData, 'quantity', 1);
                  if (value.amount) {
                    onFieldChange(rowData, 'rate', value.amount);
                  }
                }}
              />
            );
          }
          if (rowData.id === 'add') {
            return (
              <div className='d-flex gap-3'>
                <div className={classes.addAnother} onClick={onAdd}>
                  <PlusIcon fill='#4F5AED' />
                  <Typography variant='s2' style={{ color: '#4F5AED', cursor: 'pointer' }}>
                    Add Another
                  </Typography>
                </div>
              </div>
            );
          }
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>
                {rowData?.addition_type ? rowData.addition_type.addition_type : rowData.description}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'driver',
        title: <Typography variant='c3'>{carrier ? 'Carrier' : 'Driver'}</Typography>,
        render: (rowData) => {
          if (editingRow?.id === rowData.id || rowData.isNew) {
            return (
              !carrier && (
                <Autocomplete
                  name='driver_id'
                  options={drivers}
                  getOptionLabel={(option) => `${option.fname} ${option.lname}`}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={rowData.isNew ? rowData?.driver : editingRow?.driver}
                  onChange={(e, value) => {
                    onFieldChange(rowData, 'driver', value);
                  }}
                />
              )
            );
          }
          return (
            <div className={classes.colWrapper}>
              {carrier ? (
                <Typography variant='s3'>{rowData?.carrier?.name}</Typography>
              ) : (
                <Typography variant='s3'>
                  {rowData?.driver?.fname} {rowData?.driver?.lname}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        field: 'quantity',
        title: <Typography variant='c3'>QTY</Typography>,
        render: (rowData) => {
          if (editingRow?.id === rowData.id || rowData.isNew) {
            return (
              <Input
                name='quantity'
                onChange={(e) => onFieldChange(rowData, 'quantity', e.target.value)}
                value={rowData.isNew ? rowData?.quantity : editingRow?.quantity}
                type='number'
                style={{ maxWidth: '150px' }}
                placeholder='Qty'
              />
            );
          }
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>{numberWithCommas(rowData?.quantity)}</Typography>
            </div>
          );
        },
      },
      {
        field: 'rate',
        title: <Typography variant='c3'>RATE</Typography>,
        render: (rowData) => {
          if (editingRow?.id === rowData.id || rowData.isNew) {
            return (
              <Input
                name='rate'
                onChange={(e) => onFieldChange(rowData, 'rate', e.target.value)}
                value={rowData.isNew ? rowData?.rate : editingRow?.rate}
                type='number'
                style={{ maxWidth: '150px' }}
                placeholder='Rate'
              />
            );
          }
          return (
            <div className={classes.colWrapper}>
              <Typography variant='s3'>{rowData?.rate}</Typography>
            </div>
          );
        },
      },
      {
        field: 'total',
        title: <Typography variant='c3'>TOTAL</Typography>,
        render: (rowData) => {
          const isLastRow = driverPay.indexOf(rowData) === driverPay.length - 1;

          return (
            <div className={classes.colWrapper}>
              {rowData.id === 'total' ? (
                <Typography variant={isLastRow ? 's2' : 's3'}>
                  {currency}
                  {formatNumber(total)}
                </Typography>
              ) : rowData.id === 'add' ? (
                <div />
              ) : (
                <Typography variant={isLastRow ? 's2' : 's3'}>
                  {currency}
                  {formatNumber(
                    Number((editingRow?.id === rowData.id ? editingRow : rowData)?.quantity) *
                      Number((editingRow?.id === rowData.id ? editingRow : rowData)?.rate)
                  )}{' '}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        field: '',
        title: '',
        render: (rowData) => {
          const isLastRow = driverPay.indexOf(rowData) === driverPay.length - 1;
          const isAddNewRow = driverPay.indexOf(rowData) === driverPay.length - 2;

          return (
            <div className='d-flex justify-content-end '>
              {editingRow?.id === rowData.id || rowData.isNew ? (
                <div>
                  {(editingRow?.id === rowData.id || rowData.isNew) && (
                    <div className='d-flex gap-2'>
                      {!rowData.isNew && (
                        <CustomButton
                          type='secondary'
                          title='Cancel'
                          styleButton={{ padding: '7px 8px', margin: 0, height: '26px' }}
                          onClick={() => setEditingRow(null)}
                        />
                      )}
                      <CustomButton
                        type='danger'
                        title=''
                        leftIcon={<Minus style={{ fill: '#fff' }} />}
                        styleButton={{ padding: '7px 8px', margin: 0 }}
                        onClick={() => (!rowData?.isNew ? onDelete(rowData) : onDeleteAdded(rowData?.id))}
                      />
                      <CustomButton
                        type='primary'
                        title=''
                        leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
                        styleButton={{ padding: '7px 8px', margin: 0 }}
                        onClick={() => addDriverPay(rowData)}
                      />
                    </div>
                  )}
                </div>
              ) : isShipmentLocked(infoHeader) ? (
                <ActionLocked shipment={infoHeader} />
              ) : (
                <div style={{ display: isLastRow || rowData.isNew || isAddNewRow ? 'none' : 'block' }}>
                  <EditDriverPayDot
                    onDelete={onDelete}
                    onEditRow={onEdit}
                    onSuccess={onSuccess}
                    infoHeader={infoHeader}
                    rowData={rowData}
                  />
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }, [driverPay, theme, editingRow, drivers]);

  const getCols = () => {
    const params = {
      shipment_id: id || infoHeader?.shipment_id,
    };
    !!loading && setLoading(true);
    (carrier ? getCarrierPays(params) : getDriverPays(params))
      .then((res) => {
        let totalSum = 0;
        res.data.forEach((item) => {
          if (item.total) {
            totalSum += item.total;
          }
        });
        const drivers = [infoHeader.driver1];
        if (infoHeader.driver2) {
          drivers.push(infoHeader.driver2);
        }
        setDrivers(drivers);
        if (!(Number(infoHeader?.status_id) === 7 && infoHeader?.invoiced)) {
          res.data.push({ description: '', id: 'add' });
        }
        res.data.push({ description: 'Total', total: totalSum, id: 'total' });
        setDriverPay(res.data);
      })
      .finally(() => !!loading && setLoading(false));
  };

  useEffect(() => {
    getCols();
    getAdditions();
  }, [driverPayRefreshIndex]);

  return (
    <div className={classes.container}>
      {!isModalView && (
        <div className={classes.header}>
          <Typography variant='h4'>{carrier ? 'Carrier Pay' : 'Driver Pay'}</Typography>
        </div>
      )}
      <div style={{ margin: !isModalView ? 1 : 0 }}>
        {loading ? (
          <EquipmentPreloaderSkeleton />
        ) : (
          <MaterialTableWrapper
            data={driverPay}
            rowPerPage={50}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
            columns={columns}
            options={{
              paging: false,
              sorting: false,
              toolbar: false,
              rowStyle: {
                borderBottom: 'none',
                width: '100%',
              },
              headerStyle: {
                borderTop: 'none',
                width: '100%',
              },
            }}
          />
        )}
      </div>

      {!!revise_confirmation && !isModalView && (
        <div className='d-flex justify-content-end'>
          <CustomButton
            type='primary'
            title='Revise Confirmation'
            onClick={revise}
            styleButton={{ padding: '6px 12px', margin: 10 }}
          />
        </div>
      )}

      <AdditionsSettings open={settingsOpen} onClose={onSettingsClose} />
    </div>
  );
};

export default DriverPays;
