import React, { useEffect, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import classNames from 'classnames';
import mapMarkerIcon from 'assets/icons/createShipment/MapMarker.svg';
import person from 'assets/images/person.svg';
import locationFilled from 'assets/icons/location-filled.svg';
import { getAlphabet, palette, statusColor } from 'utils/constants';
import './OfferMap.css';
import { getAverageTimeConverted } from '../../StopPoint/Walmart/helpers';

const NAME_TYPES = {
  1: 'PICK UP',
  2: 'DELIVERY',
  3: 'WAYPOINT',
};

const CLASS_TYPES = {
  1: 'pickUp',
  2: 'delivery',
  3: 'wayPoint',
};

const OfferMap = ({
  data,
  selectedDrivers,
  literalJsx,
  getLiteralItemJsx,
  hoverPopover = true,
  styleContainer,
  classContainer,
  type,
  children,
  mapValues,
  setDriverRouteIncludesCountries,
  setStopsRouteIncludesCountries,
}) => {
  const [coords, setCoords] = useState([]);
  const [stops, setStops] = useState([]);

  useEffect(() => {
    const coords = data.map((item) => ({
      latitude: item?.stop_point?.geofencing_latitude,
      longitude: item.stop_point?.geofencing_longitude,
      average_waiting_time: item.stop_point?.average_waiting_time,
      city: item.stop_point?.city,
      state: item.stop_point?.state,
      zipcode: item.stop_point?.zipcode,
      location_name: item.stop_point?.location_name,
    }));
    setCoords(coords);

    setStops(data);
  }, [data, selectedDrivers]);

  useEffect(() => {
    async function init() {
      TrimbleMaps.APIKey = process.env.REACT_APP_PC_MILER_KEY;
      const myMap = new TrimbleMaps.Map({
        container: 'myMap',
        style: TrimbleMaps.Common.Style.BASIC,
        center: new TrimbleMaps.LngLat(-96, 35),
        zoom: 3,
      });

      const routeId = 'myRoute';
      const myRouteElem = document.getElementById('myRoute');
      /// ///// Marker //////////
      const markers = [];
      // Create a marker with SVG content

      coords.forEach((cord, i) => {
        const alphabet = getAlphabet(i);
        const id = stops[i]?.stop_point_type;
        const svgContent = document.createElement('div');
        let content;
        if (!literalJsx === undefined && getLiteralItemJsx === undefined) {
          content = `
                             <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                                 <img src="${mapMarkerIcon}">
                                 <div class='myMap_marker_text_wrapper'>
                                   <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                                 </div> 
                             </div>
                             `;
        } else {
          let label;
          if (literalJsx) {
            label = literalJsx;
          } else if (typeof getLiteralItemJsx === 'function') {
            label = getLiteralItemJsx(stops?.[i], stops, i);
          }
          content = `
                             <div class='myMap_marker_container ${CLASS_TYPES[+id]}'>
                                 <img src="${mapMarkerIcon}">
                                 <div class='myMap_marker_text_wrapper'>
                                   <span>STOP ${alphabet} : ${NAME_TYPES[+id]}</span>
                                 </div> 
                                 <div 
                                  class="map_popover ${!label ? 'noneLAbel' : ''}"
                                  style="opacity: ${hoverPopover ? 0 : 1}"
                                  >
                                  ${label}
                                 </div>
                             </div>
                             `;
        }
        svgContent.innerHTML = content;

        const popup = new TrimbleMaps.Popup({ anchor: 'left', closeButton: true, closeOnClick: false }).setLngLat([
          Number(cord?.longitude),
          Number(cord?.latitude),
        ]).setHTML(`
                        <div class="vehicle-popup-wrapper">
                              <div class="d-flex align-items-center vehicle-popup">
                                    ${cord.location_name}
                              </div>
                             <div class="d-flex align-items-center vehicle-popup">
                                    <img src="${locationFilled}" alt="" class="me-2">
                                    ${cord.city?.name},  ${cord.state?.name} ${cord.zipcode}
                              </div>
                            <div class="d-flex align-items-center vehicle-popup">
                                   Avg. Wait Time:  ${getAverageTimeConverted(cord.average_waiting_time)}
                              </div>
                        </div>    
                        `);

        const marker = new TrimbleMaps.Marker({
          draggable: false,
          element: svgContent,
        })
          .setLngLat([cord?.longitude, cord?.latitude])
          .addTo(myMap)
          .setPopup(popup);
        markers.push(marker);

        marker.getElement().addEventListener('mouseenter', () => {
          marker.togglePopup();
        });

        marker.getElement().addEventListener('mouseleave', () => {
          marker.togglePopup();
        });
      });

      const routes = [];
      const driverMarkers = [];
      selectedDrivers.forEach((driver) => {
        const popover = `<div class="vehicle-popup-wrapper">
                          <div class="d-flex justify-content-between align-items-center vehicle-popup">
                                <span class="vehicle-popup-driver">${driver?.drivers[0]?.fname} ${
          driver?.drivers[0]?.lname
        }</span>
                                <div class="vehicle-popup-status" style="background-color: ${
                                  statusColor[driver?.drivers[0]?.status_data?.driver_status]?.bgColor
                                }" >
                                  <span style="color: ${
                                    statusColor[driver?.drivers[0]?.status_data?.driver_status]?.color
                                  }">${driver?.drivers[0]?.status_data?.driver_status}</span>
                                </div>
                          </div>
                          <div class="d-flex justify-content-between align-items-center mt-1">
                            <div class="d-flex">
                              <span class="number-truck me-1">${
                                driver?.equipment_number
                              }</span> <span class="type-truck">${driver?.equipment_type?.title}</span>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between align-items-center mt-1">
                            <span class="miles-away">${driver?.empty_miles?.toFixed()} Miles Away</span>
                            <span class="miles-away">${driver?.distance_time}</span>
                          </div>
                          <div class="d-flex align-items-center gap-3 mt-1">
                            <div>
                              <img src="${locationFilled}" alt="">
                            </div>
                            <div class="d-flex flex-column">
                              <span class="vehicle-popup-address">${
                                driver?.location_target === 'Driver'
                                  ? driver?.drivers[0]?.driver_gps?.location
                                  : driver?.equipment_gps?.location
                              }</span>
                            </div>
                          </div>
                        </div>
                        `;

        const svgContent = document.createElement('div');
        const driverIconContent = `<div class='myMap_driver_marker_container' id="">
                                 <img src="${driver?.drivers?.[0]?.image || person}">
                                 <div class='myMap_marker_text_wrapper driver_marker'>
                                   <span>DEAD HEAD ${driver?.empty_miles.toFixed()} Miles</span>
                                 </div>
                             </div> `;
        const popup = new TrimbleMaps.Popup({ anchor: 'left', closeButton: true, closeOnClick: false }).setHTML(
          popover
        );

        svgContent.innerHTML = driverIconContent;
        const driverMarker = new TrimbleMaps.Marker({
          draggable: false,
          element: svgContent,
        })
          .setLngLat([
            driver?.location_target === 'Driver'
              ? driver?.drivers[0]?.driver_gps?.longitude
              : driver?.equipment_gps?.longitude,
            driver?.location_target === 'Driver'
              ? driver?.drivers[0]?.driver_gps?.latitude
              : driver?.equipment_gps?.latitude,
          ])
          .setPopup(popup);
        driverMarkers.push(driverMarker);

        driverMarker.getElement().addEventListener('mouseenter', () => {
          driverMarker.togglePopup();
        });

        driverMarker.getElement().addEventListener('mouseleave', () => {
          driverMarker.togglePopup();
        });

        const startPoint = data.find((item) => item.stop_point_type === '1');
        const route = new TrimbleMaps.Route({
          routeId: `${driver.drivers[0].id}`,
          stops: [
            new TrimbleMaps.LngLat(
              driver?.location_target === 'Driver'
                ? driver?.drivers[0]?.driver_gps?.longitude
                : driver?.equipment_gps?.longitude,
              driver?.location_target === 'Driver'
                ? driver?.drivers[0]?.driver_gps?.latitude
                : driver?.equipment_gps?.latitude
            ),
            new TrimbleMaps.LngLat(
              startPoint?.stop_point?.geofencing_longitude,
              startPoint?.stop_point?.geofencing_latitude
            ),
          ],
          routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
          highwayOnly: !!mapValues.hwy_only,
          tollRoads: mapValues.avoid_tolls ? 2 : 3,
          hazMatType: mapValues.haz_mat ? 1 : 0,
          vehicleType: mapValues.vehicle_type,
          bordersOpen: !!mapValues.open_borders,
          reportType: ['State'],
          routeColor: palette.blue300,
          showStops: false,
        });

        route.on('report', function (reports) {
          const states = reports?.['0']?.StateReportLines?.map((item) => item.StCntry);
          const routeIncludesCountries = [];

          if (states?.includes('US')) {
            routeIncludesCountries.push(231);
          }
          if (states?.includes('Canada')) {
            routeIncludesCountries.push(38);
          }
          if (states?.includes('Mexico')) {
            routeIncludesCountries.push(142);
          }

          setDriverRouteIncludesCountries(routeIncludesCountries);
        });

        routes.push(route);
        markers.push(driverMarker);
      });

      const stopsMap = coords.map((cord) => new TrimbleMaps.LngLat(cord?.longitude, cord?.latitude));

      const routeOptions = {
        routeId,
        stops: stopsMap,
        routeColor: palette.indigo500, // optional routeColor,
        routeType: mapValues.route_type === 'practical' ? 0 : mapValues.route_type === 'shortest' ? 1 : 2,
        highwayOnly: !!mapValues.hwy_only,
        tollRoads: mapValues.avoid_tolls ? 2 : 3,
        hazMatType: mapValues.haz_mat ? 1 : 0,
        vehicleType: mapValues.vehicle_type,
        bordersOpen: !!mapValues.open_borders,
        reportType: ['State'],
      };

      const myRoute = new TrimbleMaps.Route(routeOptions);

      myRoute.on('report', function (reports) {
        const states = reports?.['0']?.StateReportLines?.map((item) => item.StCntry);
        const routeIncludesCountries = [];

        if (states?.includes('US')) {
          routeIncludesCountries.push(231);
        }
        if (states?.includes('Canada')) {
          routeIncludesCountries.push(38);
        }
        if (states?.includes('Mexico')) {
          routeIncludesCountries.push(142);
        }

        setStopsRouteIncludesCountries(routeIncludesCountries);
      });

      myRoute.on('stopInserting', (e) => {
        myRouteElem.innerHTML = `Mouse down on ${e.routeLegPositions.length} route leg(s)`;
        myRoute.getRouteWithNewStop(e.newStop, e.routeLegPositions[e.routeLegPositions.length - 1]);
      });

      myMap.on('load', async () => {
        myRoute.addTo(myMap);
        routes.forEach((item) => item.addTo(myMap));
        driverMarkers.forEach((item) => item.addTo(myMap));
      });
    }

    init();
  }, [coords, stops, type, selectedDrivers, mapValues]);

  return (
    <div className={classNames('plan_map_container', classContainer)} style={{ ...styleContainer }}>
      <div className='map_wrapper'>
        <div id='myMap' style={{ height: '100%', width: '100%' }} />
        {children}
      </div>
    </div>
  );
};

export default OfferMap;
