import React, { useEffect } from 'react';
import moment from 'moment';
import Chip from 'common/Chip';
import Radio from 'common/Radio';
import Input from 'common/Input';
import Divider from 'common/Divider';
import { DatePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import ErrorMessage from 'common/ErrorMessage';
import { recurringOptions } from '../CreateInvoice.data';
import { STable } from '../CreateInvoice.styles';

const Recurring = ({ values, handleChange, setValues, touchedErrors }) => {
  const showToaster = useShowToaster();

  const onBlur = (e) => {
    if (e.target.name === 'recurring_days' && Number(e.target.value) > 30) {
      handleChange('recurring_days', 30);
    }
  };

  const onKeyDown = (e) => {
    blockNotNumberChars(e);
    blockNonPositiveNumbers(e);
  };

  useEffect(() => {
    if (values.due_date && moment(values.due_date).isValid() && values.recurring_months) {
      handleChange(
        'next_renew_date',
        moment(values.due_date).add(values.recurring_months.amount, values.recurring_months.unit).toDate()
      );
    }
  }, [values.due_date, values.recurring_months]);

  return (
    <div className='d-flex flex-column gap-2'>
      <div className='d-flex align-items-center gap-2'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Recurring Invoice
        </Typography>
        <Chip label='OPTIONAL' />
      </div>
      <STable>
        <thead>
          <tr className='nowrap'>
            <th />
            <th>GENERATE</th>
            <th>RECURRING</th>
            <th>NEXT RENEWAL DATE</th>
            <th>DURATION</th>
          </tr>
        </thead>
        <tbody>
          <tr className='nowrap'>
            <td>
              <CustomCheckbox
                name='is_recurring'
                checked={!!values.is_recurring}
                onChange={(checked) => {
                  if (!checked) {
                    setValues((prevState) => ({
                      ...prevState,
                      is_recurring: checked,
                      payment_method: '',
                      is_auto_debt_enabled: false,
                    }));
                  }
                  handleChange('is_recurring', checked);
                }}
              />
            </td>
            <td>
              <div className='d-flex align-items-center gap-2'>
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  Generate Invoice
                </Typography>
                <Input
                  type='number'
                  width='50px'
                  placeholder='00'
                  name='recurring_days'
                  value={values.recurring_days}
                  onChange={handleChange}
                  error={touchedErrors.recurring_days}
                  disabled={!values.is_recurring}
                  onKeyDown={onKeyDown}
                  onBlur={onBlur}
                />
                <Typography variant='s2' style={{ color: palette.gray700 }}>
                  days before next due date
                </Typography>
              </div>
            </td>
            <td>
              <Autocomplete
                width='180px'
                name='recurring_months'
                options={recurringOptions}
                value={values.recurring_months}
                onChange={(e, val) => handleChange('recurring_months', val)}
                error={touchedErrors.recurring_months}
                disabled={!values.is_recurring}
                isOptionEqualToValue={(option, value) => option.name === value.name}
              />
            </td>
            <td>
              <DatePicker required width='150px' name='next_renew_date' value={values.next_renew_date} disabled />
            </td>
            <td>
              <div className='d-flex align-items-center gap-3'>
                <Radio
                  value={1}
                  checked={values.recurring_duration === 1}
                  onChange={() => handleChange('recurring_duration', 1)}
                  label='Indefinitely'
                  disabled={!values.is_recurring}
                />
                <div className='d-flex align-items-center gap-2'>
                  <Radio
                    value={2}
                    checked={values.recurring_duration === 2}
                    onChange={() => handleChange('recurring_duration', 2)}
                    disabled={!values.is_recurring}
                  />
                  <Input
                    type='number'
                    width='60px'
                    placeholder='0'
                    name='recurring_frequency'
                    value={values.recurring_frequency}
                    onChange={handleChange}
                    error={touchedErrors.recurring_frequency}
                    disabled={!values.is_recurring || values.recurring_duration !== 2}
                    onKeyDown={onKeyDown}
                  />
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    number of times
                  </Typography>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </STable>
      <div className='d-flex flex-column gap-1 ms-2'>
        <div className='d-flex align-items-center gap-2'>
          <CustomCheckbox
            type='switch'
            name='is_auto_debt_enabled'
            checked={!!values.is_auto_debt_enabled}
            onChange={(checked) => {
              if (checked && !values.is_recurring) {
                showToaster({ type: 'error', message: 'Auto-debiting is only available for recurring invoices!' });
                return;
              }
              if (checked && !values.customer) {
                showToaster({ type: 'error', message: 'Billing customer needs to be selected first!' });
                return;
              }
              handleChange('is_auto_debt_enabled', checked);
            }}
            smail={false}
          />
          <Typography variant='s2' style={{ color: palette.gray900 }}>
            Allow auto-debiting for this invoice
          </Typography>
        </div>
        {!!values.is_auto_debt_enabled && (
          <div className='d-flex flex-column gap-1 ms-4'>
            <Divider margin='0 0 4px' />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              Payment Method:
            </Typography>
            <div className='d-flex flex-column'>
              <div className='d-flex gap-4'>
                <CustomRadioButton
                  field={{
                    name: 'payment_method',
                    value: 'credit_card',
                    onChange: () => handleChange('payment_method', 'credit_card'),
                    checked: values.payment_method === 'credit_card',
                  }}
                >
                  <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                    Credit Card
                  </Typography>
                </CustomRadioButton>
                <CustomRadioButton
                  field={{
                    name: 'payment_method',
                    value: 'ach',
                    onChange: () => handleChange('payment_method', 'ach'),
                    checked: values.payment_method === 'ach',
                  }}
                >
                  <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                    ACH
                  </Typography>
                </CustomRadioButton>
              </div>
              <ErrorMessage error={touchedErrors.payment_method} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recurring;
