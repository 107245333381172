import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from '../Typography';
import styles from './modalWrapper.module.css';

const ModalWrapper = ({
  isOpen,
  onHide,
  children,
  width,
  title,
  footer: Footer,
  styleBody,
  subTitle,
  styleTitle,
  header: Header,
  dialogClassName,
  styleFooterWrapper,
  backdrop,
  footerContainer = true,
}) => {
  const { use } = useTheme();

  const style = useMemo(() => {
    return {
      modal: {
        width: width || 500,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        bgcolor: use(palette.white, palette.dark900),
        backgroundColor: 'rgba(0,0,0,0.4)',
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
        border: `1px solid ${use(palette.gray50, palette.dark700)}`,
        width: width || 500,
      },
    };
  }, [use, palette, width]);

  return (
    <Modal
      size='lg'
      centered
      backdrop={backdrop}
      autoFocus={false}
      show={isOpen}
      onHide={onHide}
      enforceFocus={false}
      style={style.modal}
      dialogClassName={dialogClassName}
      className={styles[('laneModal', 'test-modal')]}
    >
      <Modal.Body style={style.modalBody}>
        <div
          className={styles.modal_header}
          style={{ backgroundColor: use(palette.white, palette.dark900), padding: '5px' }}
        >
          {!Header ? (
            <div className={styles['title-split']}>
              <Typography variant='button2' style={styleTitle}>
                {title}
              </Typography>
              {!!subTitle && <Typography variant='overLine'>{subTitle}</Typography>}
            </div>
          ) : typeof Header === 'function' ? (
            <Header />
          ) : (
            Header
          )}
        </div>
        <div
          className={styles.modal_body}
          style={{
            backgroundColor: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.gray700),
            ...styleBody,
          }}
        >
          {!!children && children}
        </div>

        {footerContainer ? (
          <div
            className={styles.modal_footer}
            style={{
              backgroundColor: use(palette.white, palette.dark900),
              ...styleFooterWrapper,
            }}
          >
            {typeof Footer === 'function' ? <Footer /> : Footer}
          </div>
        ) : typeof Footer === 'function' ? (
          <Footer />
        ) : Footer === null ? null : (
          Footer
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalWrapper;
