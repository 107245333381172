import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../maintenancecost.css';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { EquipmentTableSort } from 'components/Equipment/EquipmentTableWrapper/EquipmentTableWrapper';
import { EquipmentMaintenanceRecord } from 'Api/Equipment';
import authHeader from 'services/auth-header';
import { Icons } from 'assets/icons';
import useDateFormat from 'hooks/useDateFormat';
import UpdateMarkCompleted from './MaintainanceComponents/UpdateMaintenanceRecord';
import UpdateMaintenanceRecord from './MaintainanceComponents/EditMaintenanceRecord';
import MaintenanceRecordInspection from './MaintenanceRecordInspection/MaintenanceRecordInspection';

const Record = ({ profileData, recordSuccess, openToast, equipmentMaintanance }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [openmaintenancerecordinspection, setMaintenanceRecordInspectionOpen] = useState(false);
  const [editMaintenance, setEditMaintenance] = useState(false);
  const [markCompleted, setMarkCompleted] = useState(false);
  const [recordID, setRecordID] = useState();

  const markCompletedModalOpen = () => {
    setMarkCompleted(true);
    viewPopupClose();
  };
  const markCompletedModalClose = () => {
    setMarkCompleted(false);
  };
  const handleMaintenanceRecordInspectionOpen = (recordID) => {
    setMaintenanceRecordInspectionOpen(true);
    setRecordID(recordID);
    viewPopupClose();
  };
  const handleMaintenanceRecordInspectionClose = () => setMaintenanceRecordInspectionOpen(false);
  const [viewPopup, setViewPopup] = useState(null);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(2);

  const [index, setIndex] = useState();

  const [loading, setLoading] = useState(false);
  const viewPopupOpen = Boolean(viewPopup);
  const viewPopupModal = viewPopupOpen ? 'simple-popover' : undefined;
  const viewPopupModalOpen = (e, id, index) => {
    e.preventDefault();
    setViewPopup(e.currentTarget);
    setRecordID(id);
    setIndex(index);
  };

  const viewPopupClose = () => {
    setViewPopup(null);
  };
  const API_URL = process.env.REACT_APP_API_URL;

  async function MaintenanceRecord(query = '') {
    if (query === '') {
      query = `sort[][service_date_time] = desc`;
    }
    setLoading(true);
    EquipmentMaintenanceRecord(profileData?.id, {
      query,
    }).then((res) => {
      setData(res?.data);
      setLoading(false);
      setPage(2);
    });
  }

  useEffect(() => {
    MaintenanceRecord();
  }, [recordSuccess]);

  async function moreData() {
    setLoading(true);
    const url = `${API_URL}/equipment/maintenance-record?page=${page}&equipment_id=${profileData?.id}`;
    axios.get(url, { headers: authHeader() }).then((res) => {
      setData([...data, ...(res?.data?.data || [])]);
      setPage(res?.data?.last_page > page ? page + 1 : '');
      setLoading(false);
    });
  }

  const handleRemoveRecord = () => {
    axios.delete(`${API_URL}/equipment/maintenance-record/${recordID}`, { headers: authHeader() }).then((res) => {
      if (res) {
        openToast('Record remove successfully');
        viewPopupClose();
        MaintenanceRecord();
        equipmentMaintanance();
        setPage(2);
      }
    });
  };

  const onScroll = () => {
    const tableEl = document.getElementById('Maintenance-reacords');
    if (tableEl.scrollHeight - tableEl.offsetHeight - tableEl.scrollTop <= 1) {
      moreData();
    }
  };

  const updateMaintenanceRecordClose = () => {
    setEditMaintenance(false);
    equipmentMaintanance();
  };
  const updateMaintenanceRecordOpen = () => {
    setEditMaintenance(true);
    viewPopupClose();
  };

  const AddRecordstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 0,
    padding: '0px',
    borderRadius: '6px',
  };
  function sortingQuery(field, sortBy, nested_field) {
    let urlQuery;
    if (nested_field) {
      urlQuery = `sort[${nested_field}]${field}=${sortBy}`;
    } else {
      urlQuery = `sort[][${field}]=${sortBy}`;
    }

    MaintenanceRecord(urlQuery);
  }

  return (
    <div className='maintenance-table' id='Maintenance-reacords' onScroll={onScroll}>
      <table className='table table-hover' style={{ marginBottom: '30px', borderSpacing: '20px !important' }}>
        {loading ? (
          <div style={{ position: 'absolute', left: '50%', top: '60%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          </div>
        ) : (
          ''
        )}
        <thead>
          <tr style={{ padding: '10px 0px' }}>
            <th scope='col p-1' className='maintenance-cost-table-heading' data-field='TYPE'>
              <span>
                {/* <img src={Icons.ColumnSorting} alt="" /> */}
                <EquipmentTableSort
                  title='TYPE'
                  sortingQuery={sortingQuery}
                  // field={"vin"}
                  field='[type]'
                  nested_field='record_type'
                />
              </span>
            </th>
            <th scope='col p-1' className='maintenance-cost-table-heading'>
              DESCRIPTION
            </th>
            <th scope='col' className='maintenance-cost-table-heading'>
              <EquipmentTableSort title='DUE BY' sortingQuery={sortingQuery} field='service_date_time' />
            </th>
            <th scope='col' className='maintenance-cost-table-heading'>
              <EquipmentTableSort
                title='STATUS'
                sortingQuery={sortingQuery}
                // field={"updated_at"}
                field='[status]'
                nested_field='status'
              />
            </th>
            <th scope='col' className='maintenance-cost-table-heading'>
              <EquipmentTableSort title='SHOP' sortingQuery={sortingQuery} field='[id]' nested_field='service_at' />
            </th>
            <th scope='col' className='maintenance-cost-table-heading'>
              <EquipmentTableSort title='COST' sortingQuery={sortingQuery} field='cost' />
            </th>
            <th scope='col' className='maintenance-cost-table-heading'>
              <EquipmentTableSort title='UPDATED ON' sortingQuery={sortingQuery} field='updated_at' />
            </th>
            <th scope='col' className='maintenance-cost-table-heading'>
              <EquipmentTableSort title='UPDATED BY' sortingQuery={sortingQuery} field='added_by' />
            </th>
          </tr>
        </thead>

        <tbody className='maintenance-cost-table-content align-middle'>
          {data &&
            data?.map((record, index) => (
              <tr
                className='table-row more_icon_parent'
                onClick={() => handleMaintenanceRecordInspectionOpen(record?.id)}
              >
                <td className='align-middle'>{record?.record_type?.type}</td>
                <td style={{ maxWidth: '500px' }}>{record?.description || '-'}</td>
                <td>{formatDate(record?.service_date_time)}</td>
                <td>
                  <span
                    className={
                      record?.status?.status === 'OVERDUE'
                        ? 'maintenance-status-overdue'
                        : record?.status?.status === 'COMPLETED'
                        ? 'maintenance-status-completed'
                        : record?.status?.status === 'SCHEDULED'
                        ? 'maintenance-status-schedule'
                        : ''
                    }
                  >
                    {record?.status?.status}
                  </span>
                </td>
                <td>
                  <span style={{ fontSize: '13px' }}>{record?.service_at?.name || '-'}</span>
                </td>
                <td>{record?.cost ? `${currency} ${record?.cost}` : '-'}</td>
                <td>
                  <div className='d-flex align-item-center'>
                    <div>
                      <span className='me-1' style={{ fontSize: '13px' }}>
                        {formatDate(record?.updated_at)}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-item-center justify-content-between me-3'>
                    <div>
                      <span style={{ fontSize: '13px' }}>{record?.added_by ? record?.added_by : 'System'}</span>
                    </div>
                    <span className=' more-icon '>
                      <span>
                        <img
                          src={Icons.More}
                          alt=''
                          onClick={(e) => {
                            e.stopPropagation();
                            viewPopupModalOpen(e, record?.id, index);
                          }}
                        />
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          {loading === false && data?.length === 0 && (
            <div style={{ position: 'absolute', left: '40%', top: '60%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <span>No record found</span>
              </Box>{' '}
            </div>
          )}
        </tbody>
      </table>
      <Popover
        id={viewPopupModal}
        // anchorPosition={{ top: 299, left: 669 }}
        open={viewPopupOpen}
        anchorEl={viewPopup}
        onClose={viewPopupClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        style={{ marginRight: '40px' }}
      >
        <div className='maintenance-action-pop-up'>
          <div className='maintenance-action-pop-up-content'>
            <span className='mt-1 popover-link' onClick={() => handleMaintenanceRecordInspectionOpen(recordID)}>
              View
            </span>
            <span className='mt-1 popover-link' onClick={() => updateMaintenanceRecordOpen()}>
              Edit
            </span>

            <span className='mt-1 popover-link' onClick={() => markCompletedModalOpen()}>
              Mark Completed
            </span>
            <span className='mt-1 popover-link ' style={{ color: '#D12953' }} onClick={handleRemoveRecord}>
              Remove
            </span>
          </div>
        </div>
      </Popover>
      <Modal
        open={openmaintenancerecordinspection}
        onClose={handleMaintenanceRecordInspectionClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <MaintenanceRecordInspection
          handleMaintenanceRecordInspectionClose={handleMaintenanceRecordInspectionClose}
          id={recordID}
        />
      </Modal>
      <Modal
        open={markCompleted}
        onClose={markCompletedModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <UpdateMarkCompleted
          handleClose={markCompletedModalClose}
          id={recordID}
          openToast={openToast}
          MaintenanceRecord={MaintenanceRecord}
        />
      </Modal>
      <Modal
        open={editMaintenance}
        onClose={updateMaintenanceRecordClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ zIndex: 1055 }}
      >
        <Box sx={AddRecordstyle}>
          <UpdateMaintenanceRecord
            updateMaintenanceRecordClose={updateMaintenanceRecordClose}
            handleClose={updateMaintenanceRecordClose}
            id={recordID}
            openToast={openToast}
            data={data[index]}
            MaintenanceRecord={MaintenanceRecord}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Record;
