import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import './accountStatus.css';

const AccountStatus = ({ accountStatus }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { billingDetail } = useSelector((state) => state.billing);

  useEffect(() => {
    if (accountStatus?.account_status === 'trialexpired') {
      navigate('../billing', { replace: true });
    }
    if (accountStatus?.account_status === 'pastdue' && accountStatus?.date_diff_past_due > 14) {
      navigate('../billing', { replace: true });
    }
    if (accountStatus?.account_status === 'canceled') {
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
      navigate('../login', { replace: true });
    }
  }, [accountStatus, billingDetail, location?.pathname]);

  return accountStatus?.account_status === 'intrial' ? (
    <div className='accountStatusMessage intrial'>
      <p>{accountStatus?.message}</p>
      <div className='accountStatusBtn'>
        <Link to='/billing'>
          <button className='billing'>Manage Billing</button>
        </Link>
        <a href='https://calendly.com/d/32d-m9r-5dm/1-hour-training' target='_blank' rel='noreferrer'>
          <button className='training'>Book Free Training</button>
        </a>
      </div>
    </div>
  ) : accountStatus?.account_status === 'trialexpired' ? (
    <div className='accountStatusMessage trialexpired'>
      <p>{accountStatus?.message}</p>
    </div>
  ) : accountStatus?.account_status === 'pastdue' ? (
    <div className='accountStatusMessage pastDue'>
      <p>{accountStatus?.message}</p>
      <Link to='/billing'>
        <button className='training'>Make Payment</button>
      </Link>
    </div>
  ) : accountStatus?.account_status === 'canceled' ? (
    <div className='accountStatusMessage canceled'>
      <p>{accountStatus?.message}</p>
    </div>
  ) : null;
};

export default AccountStatus;
