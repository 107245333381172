import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import YearPicker from 'common/YearPicker';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import { SBoxWrapper } from '../../StatsSection.styles';

const StatBox = ({ title, data, year, onYearChange, loading, onTitleClick }) => {
  const { currency } = useSelector((state) => state.root);
  const { amountDiff, perMileDiff, current_year } = data || {};

  return (
    <SBoxWrapper>
      <div className='d-flex align-items-center justify-content-between mb-2 title-wrapper'>
        <span className='d-inline-flex gap-1 pointer' onClick={onTitleClick}>
          <Typography variant='c3' style={{ color: palette.gray700 }}>
            {title.toUpperCase()} COSTS
          </Typography>
          {title !== 'total' && <ArrowUp fill={palette.gray400} style={{ transform: 'rotate(90deg)' }} />}
        </span>
        {title !== 'total' && <YearPicker year={year} onChange={onYearChange} />}
      </div>
      <div>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <div>
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              {currency}
              {formatNumber(Math.abs(current_year?.per_mile), 3)}
            </Typography>
            <Typography variant='c2' style={{ color: palette.gray500 }}>
              {' '}
              per Mile
            </Typography>
          </div>
        )}
      </div>
      <div className='mt-1'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
        ) : (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {currency}
            {formatNumber(Math.abs(current_year?.amount))}
          </Typography>
        )}
      </div>
      <div className='d-flex mt-3 gap-1'>
        {loading ? (
          <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '150px', height: '18px' }} />
        ) : (
          <>
            {amountDiff !== 0 && (
              <ArrowUp
                fill={amountDiff < 0 ? palette.green500 : palette.red500}
                style={{ transform: amountDiff < 0 ? 'rotate(180deg)' : '' }}
              />
            )}
            <Typography
              variant='c2'
              style={{ color: amountDiff === 0 ? palette.gray700 : amountDiff < 0 ? palette.green500 : palette.red500 }}
            >
              {currency}
              {formatNumber(Math.abs(perMileDiff), 3)} / mile, {currency}
              {formatNumber(Math.abs(amountDiff))} since last month
            </Typography>
          </>
        )}
      </div>
    </SBoxWrapper>
  );
};

export default StatBox;
