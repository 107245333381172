import React from 'react';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import ColumnHeader from '../ColumnHeader';

export const initialFilters = {
  tab: 0,
  page: 1,
  itemsPerPage: 25,
};

const statusMapper = {
  New: <Chip size='small' label='NEW' labelColor='#0E7C90' bgColor='#E5FAFE' />,
  Pending: <Chip size='small' label='PENDING' labelColor={palette.goldDark} bgColor={palette.goldLight} />,
  Approved: <Chip size='small' label='APPROVED' labelColor={palette.green500} bgColor={palette.green0} />,
  Declined: <Chip size='small' label='DECLINED' labelColor={palette.red500} bgColor={palette.red0} />,
  'Ready for Orientation': (
    <Chip size='small' label='READY FOR ORIENTATION' labelColor={palette.indigo500} bgColor={palette.indigo0} />
  ),
  'Orientation Scheduled': (
    <Chip size='small' label='ORIENTATION SCHEDULED' labelColor={palette.indigo500} bgColor={palette.indigo0} />
  ),
};

const Title = ({ title }) => {
  return (
    <Typography variant='overLine' style={{ color: palette.gray900 }}>
      {title}
    </Typography>
  );
};

const EmptyCell = () => {
  return (
    <Typography variant='b2' style={{ color: palette.gray300 }}>
      N/A
    </Typography>
  );
};

export const useApplicantsColumns = ({ sortingQuery, sort }) => {
  const { formatDateTime } = useDateFormat();

  return [
    {
      field: 'full_name',
      title: <ColumnHeader title='FULL NAME' field='full_name' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.full_name}
        </Typography>
      ),
    },
    {
      field: 'applied',
      title: <ColumnHeader title='APPLIED' field='created_at' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: palette.gray700 }}>
          {row.applied}
        </Typography>
      ),
    },
    {
      field: 'status',
      title: <Title title='STATUS' />,
      render: (row) => (
        <div className='d-flex flex-column align-items-start gap-1'>
          {statusMapper[row.status.name]}{' '}
          {row.status.name === 'Orientation Scheduled' && !!row.orientation_scheduled_date && (
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              {formatDateTime(row.orientation_scheduled_date)}
            </Typography>
          )}
        </div>
      ),
    },
    {
      field: 'applicant_type',
      title: <Title title='APPLICANT TYPE' />,
      render: (row) =>
        row.job_positing?.applicant_type?.name ? (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.job_positing.applicant_type.name}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'position',
      title: <Title title='POSITION' />,
      render: (row) =>
        row.job_positing?.job_position_type?.name ? (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.job_positing?.job_position_type?.name}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'experience',
      title: <Title title='EXPERIENCE' />,
      render: (row) =>
        row.job_positing?.experience_year || row.job_positing?.experience_month ? (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.job_positing?.experience_year ? `${row.job_positing?.experience_year} years ` : ''}
            {row.job_positing?.experience_month ? `${row.job_positing?.experience_month} months ` : ''}
          </Typography>
        ) : (
          <EmptyCell />
        ),
    },
  ];
};
