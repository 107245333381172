import styled from 'styled-components';

export const SMapWrapper = styled.div`
  position: relative;
  height: 100%;

  .shipment-bid-map {
    height: 100%;
    width: 100%;
  }

  .confetti-animation {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 50%;
    min-width: 900px;
    height: calc(100vh - 120px);
    z-index: 999;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
`;
