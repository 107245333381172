import { Box } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Toast, Spinner, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import './equipmentconnectedservices.css';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';

import authHeader from 'services/auth-header';
import { EquipmentConnectedService, getVehicleConnectedServices } from 'Api/Equipment';
import { EquipmentDetailsContext } from '../../EquipmentDetails';
import ConnectModal from './ConnectModal';

const EquipmentConnectedServices = ({ profileData, refreshIndex }) => {
  const { use } = useTheme();
  const [connectedservicedata, setConnectedServiceData] = useState([]);
  const [connectedServicesList, setConnectedServicesList] = useState([]);
  const [connectedServiceID, setConnectedServiceID] = useState([]);
  const [success, setSuccess] = useState(false);
  const [connectSuccess, setConnectSuccess] = useState(false);
  const [connectSuccessData, setConnectSuccessData] = useState(false);
  const [ConnectError, setConnectError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [providerName, setProviderName] = useState({});
  const [show, setShow] = useState(false);
  const [getSuccess, setGetSuccess] = useState(false);
  const successShow = () => {
    setSuccess(true);
    setTimeout(() => {
      successClose();
    }, 2000);
  };
  const successClose = () => {
    setSuccess(false);
  };
  const getConnectSuccess = (data) => {
    if (data) {
      setGetSuccess(true);
    }
  };
  const ConnectErrorModal = () => {
    setConnectError(true);
    setTimeout(() => {
      ConnectErrorModalClose();
    }, 2000);
  };
  const ConnectErrorModalClose = () => {
    setConnectError(false);
  };
  const ConnectsuccessShow = () => {
    setConnectSuccess(true);
    setTimeout(() => {
      ConnectsuccessClose();
    }, 2000);
  };
  const ConnectsuccessClose = () => {
    setConnectSuccess(false);
  };
  const { setConnectedServicesData } = useContext(EquipmentDetailsContext);
  async function init() {
    setLoading(true);
    EquipmentConnectedService(profileData?.id).then((res) => {
      setConnectedServiceData(res?.data?.connectedServices);
      setConnectedServicesData(res?.data?.connectedServices);
    });
    setLoading(false);
  }

  useEffect(() => {
    if (profileData?.id) {
      init();
    }
  }, [profileData?.id, getSuccess, refreshIndex]);

  const ConnecthandleClose = () => setShow(false);

  async function getConnectedServices() {
    setLoading(true);
    getVehicleConnectedServices(profileData?.id).then((res) => {
      setConnectedServicesList(res?.data);
    });
    setLoading(false);
  }
  const handleShow = (name) => {
    setConnectSuccessData(name);
    setShow(true);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 0,
    padding: '10px 16px',
  };
  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    border: 0,
    padding: '10px 16px',
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = (data) => {
    setConnectedServiceID((data || []).map((v) => v?.connected_service_id)[0]);
    setOpen(true);
    getConnectedServices();
  };
  const handleClose = () => setOpen(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const removeConnectedServices = (id, serial_id, name) => {
    setProviderName({ name, serial_id });
    successShow();
    const config = {
      data: {
        serial_id,
      },
      headers: authHeader(),
      method: 'delete',
    };
    axios.delete(`${API_URL}/connected-services/vehicle/${`${id}`}`, config).then((res) => {
      if (res) {
        successShow();
        init();
        setGetSuccess(false);
      }
    });
  };
  return (
    <>
      <div className='d-flex justify-content-between  equipment-connected-services-box'>
        <span className='details' style={{ color: use(palette.dark800, palette.gray200) }}>
          CONNECTED SERVICES
        </span>
        <Button onClick={() => handleOpen(connectedservicedata)}>
          <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
              fill='#4f5aed'
            />
          </svg>
        </Button>
      </div>
      {connectedservicedata.length !== 0 && (
        <div className='equipment-profile-group-box mt-3'>
          {connectedservicedata &&
            connectedservicedata?.map((v) => (
              <div className='d-flex justify-content-between align-item-center mb-2' key={v.id}>
                <span className='d-flex justify-content-between align-item-center equipment-profile-content'>
                  <div
                    // className="d-flex"
                    style={{ width: '100px', height: '80px' }}
                  >
                    <img
                      src={v?.connected_services?.logo}
                      alt='Aws Logo'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'scale-down',
                      }}
                    />
                  </div>
                  <div className='d-flex align-items-center'>
                    <span className='mt-1'>
                      {v?.connected_services?.provider_name} (
                      {v?.equipment_connected_services?.map((d) => d?.custom_field_value)})
                    </span>
                  </div>
                </span>
                <button
                  className='equipment-profile-content'
                  style={{
                    border: 'none',
                    color: 'red',
                    // backgroundColor: "white",
                    backgroundColor: use(palette.white, palette.dark800),
                  }}
                  onClick={() =>
                    removeConnectedServices(
                      profileData?.id,
                      v?.equipment_connected_services?.map((d) => d?.custom_field_value)[0],
                      v?.connected_services?.provider_name
                    )
                  }
                >
                  Remove
                </button>
              </div>
            ))}
        </div>
      )}
      <Modal disableEnforceFocus open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className='connectedTable'>
            <div>
              <p className='equipment-connected-services-heading'>Add Services</p>
            </div>
            <div className='connected-services-filter '>
              <div className='filter-tab-wrapper mt-4'>
                <div className='tab-content' id='myTabContent'>
                  <Tabs defaultActiveKey='home' id='uncontrolled-tab-example'>
                    <Tab
                      eventKey='home'
                      title={
                        <p className='d-flex'>
                          <span className='tabs-text'> All </span>
                          <span className='tabs-values'>1</span>
                        </p>
                      }
                      className='tab-container ms-3'
                    />
                    <Tab
                      disabled
                      title={
                        <p className='d-flex ms-2'>
                          <span className='connected-services-tab-text'> Email & Storage </span>
                          <span className='tabs-values'>0</span>
                        </p>
                      }
                    />
                    <Tab
                      eventKey='outofserives'
                      disabled
                      title={
                        <p className='d-flex ms-2'>
                          <span className='connected-services-tab-text'> Fuel & Cash Card</span>
                          <span className='tabs-values'>0</span>
                        </p>
                      }
                    />
                    <Tab
                      eventKey='outofserives'
                      disabled
                      title={
                        <p className='d-flex ms-2'>
                          <span className='connected-services-tab-text'>Payroll</span>
                          <span className='tabs-values'>0</span>
                        </p>
                      }
                    />
                    <Tab
                      eventKey='outofserives'
                      disabled
                      title={
                        <p className='d-flex ms-2'>
                          <span className='connected-services-tab-text'>EDI</span>
                          <span className='tabs-values'>0</span>
                        </p>
                      }
                    />
                    <Tab
                      eventKey='outofserives'
                      disabled
                      title={
                        <p className='d-flex ms-2'>
                          <span className='connected-services-tab-text'>Telematics</span>
                          <span className='tabs-values'>1</span>
                        </p>
                      }
                    />
                  </Tabs>
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <table className='table table-hover p-3'>
                <tr className='connected-services-table-row'>
                  <th className='connected-services-table-head'>Services</th>
                  <th className='connected-services-table-head'>Type</th>
                  <th />
                </tr>
                {connectedServicesList.map((value) => (
                  <tr className='connected-services-table-row' key={value.provider_name}>
                    <td className='d-flex align-item-center'>
                      <span>
                        <div style={{ height: '100px', width: '150px' }}>
                          <img
                            src={value.logo}
                            alt=''
                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                          />
                        </div>
                      </span>
                      <div className='table-content d-flex align-items-center'>{value.provider_name}</div>
                    </td>
                    <td>
                      <span className='table-content'>{value.type}</span>
                    </td>
                    <td className='d-flex justify-content-end me-4'>
                      {connectedServiceID !== value?.connected_service_id || getSuccess ? (
                        <button className='connect-button-connected'>Connected</button>
                      ) : (
                        <button className='connect-button' onClick={() => handleShow(value?.provider_name)}>
                          Connect
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            )}
            <button
              className='connected-modal-cancel-button '
              onClick={handleClose}
              style={{ position: 'absolute', bottom: '10px', right: '10px' }}
            >
              Done
            </button>
          </div>
        </Box>
      </Modal>
      <Modal open={show} disableEnforceFocus onClose={ConnecthandleClose} className='service-connect-modal'>
        <Box sx={style2}>
          <ConnectModal
            handleClose={ConnecthandleClose}
            profileData={profileData}
            ConnectsuccessShow={ConnectsuccessShow}
            ConnectErrorModal={ConnectErrorModal}
            getConnectSuccess={getConnectSuccess}
          />
        </Box>
      </Modal>
      <Toast show={success} onClose={successClose} className='equipment-connected-success-msg'>
        <div>
          Successfully removed {providerName?.name}( {providerName?.serial_id} )
        </div>

        {/* /> */}
      </Toast>
      <Toast show={connectSuccess} onClose={ConnectsuccessClose} className='equipment-connected-success-msg'>
        <div>Successfully connected {connectSuccessData}</div>

        {/* /> */}
      </Toast>
      <Toast show={ConnectError} onClose={ConnectErrorModalClose} className='equipment-connected-error-msg'>
        <div>Error, there is a mismatch with VIN!</div>

        {/* /> */}
      </Toast>
    </>
  );
};

export default EquipmentConnectedServices;
