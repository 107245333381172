import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { subYears } from 'date-fns';
import Divider from 'common/Divider';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import DateRangePicker from 'common/DateRangePicker';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { getAccidents } from 'Api/AccidentsAndCrashes';
import { palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import Header from '../shared/Header';
import PageWrapper from '../shared/PageWrapper';
import { generatePDF } from './generatePdf';
import { STable, STableFilters, STableWrapper } from '../ReportDetails.styles';

const AccidentRegister = () => {
  const { formatDate, formatDateTime, convertToCustomerTime } = useDateFormat();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [accidentRegister, setAccidentRegister] = useState([]);
  const didMountRef = useRef(false);
  const controller = useRef(new AbortController());

  const [dateRange, setDateRange] = useState({
    start: startDate ? moment(startDate).toDate() : subYears(new Date(), 3),
    end: endDate ? moment(endDate).toDate() : new Date(),
  });

  const getAccidentRegister = async () => {
    try {
      setLoading(true);
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const params = {
        page: 1,
        itemsPerPage: 10000,
        'filters[from_date]': dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        'filters[to_date]': dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        'filters[type]': 'Reportable',
        'sort[][collision_date_time]': 'desc',
      };

      const { data } = await getAccidents(params, controller.current?.signal);
      setAccidentRegister(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      const { url } = await generatePDF(
        accidentRegister,
        download,
        dateRange,
        formatDate,
        formatDateTime,
        convertToCustomerTime
      );
      if (!download) {
        setPdfUrl(url);
      }
    } catch (e) {
      /* empty */
    }
  };

  const onExport = () => {
    if (!accidentRegister?.length) {
      return;
    }

    const titles = {
      date: 'Accident Date/Time',
      location: 'Location of Accident',
      deaths: 'No. of Deaths',
      injuries: 'No. of Non-Fatal Injuries',
      hazmat: 'H/M',
      driver: 'Driver',
      reportNumber: 'Report Number',
    };

    const csvRecords = accidentRegister.map((item) => ({
      date: item.collision_date_time ? formatDateTime(item.collision_date_time) : '-',
      location: item.accident_location ? item.accident_location.replace(/,/g, '/') : '-',
      deaths: item.fatalities || '0',
      injuries: item.injuries || '0',
      hazmat: item.hazmat ? 'Yes' : 'No',
      driver: item.driver ? `${item.driver?.fname} ${item?.driver?.lname}` : '-',
      reportNumber: item.report_number || '-',
    }));

    const arrayToConvert = [titles, ...csvRecords];

    let str = '';
    for (let i = 0; i < arrayToConvert.length; i++) {
      let line = '';
      for (const index in arrayToConvert[i]) {
        if (line !== '') line += ',';

        line += arrayToConvert[i][index];
      }
      str += `${line}\r\n`;
    }

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute(
      'download',
      `Accident Register - ${
        dateRange.start && dateRange.end ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}` : 'All Time'
      }`
    );
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (!didMountRef.current) {
      setInitialLoading(true);
    }
    getAccidentRegister();
    didMountRef.current = true;
  }, [dateRange]);

  useEffect(() => {
    if (startDate) {
      setIsCustomRange(true);
    }
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams, { replace: true });
  }, []);

  return (
    <PageWrapper loading={initialLoading} title='Accident Register'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              dateRange={dateRange}
              initialRangeName={isCustomRange ? 'Custom Range' : 'Last 3 Years'}
              setDateRange={setDateRange}
              type='allTime'
            />
          </div>
          <CustomButton
            className='action-button'
            title='Generate Report'
            onClick={() => onPdfClick(false)}
            disabled={accidentRegister.length === 0 || loading}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={onExport}
          disabled={accidentRegister.length === 0 || loading}
        />
        <Divider margin='16px 0 16px' />
        {!loading && (
          <STable>
            <thead>
              <tr className='header-row'>
                <th>ACCIDENT DATE/TIME</th>
                <th>LOCATION OF ACCIDENT</th>
                <th>NO. OF DEATHS</th>
                <th>NO. OF NON-FATAL INJURIES</th>
                <th>H/M</th>
                <th>DRIVER</th>
                <th>REPORT NUMBER</th>
              </tr>
            </thead>
            <tbody>
              {accidentRegister.map((item) => (
                <tr key={item.id} className='body-row'>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.collision_date_time ? formatDateTime(item.collision_date_time) : '-'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.accident_location || '-'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.fatalities || '0'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.injuries || '0'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.hazmat ? 'Yes' : 'No'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.driver ? `${item.driver?.fname} ${item?.driver?.lname}` : '-'}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='b2' style={{ color: palette.gray700 }}>
                      {item.report_number || '-'}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </STable>
        )}
      </STableWrapper>
      {!loading && !accidentRegister?.length && <NoRecords />}
      {!!pdfUrl && (
        <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='Accident Register' />
      )}
    </PageWrapper>
  );
};

export default AccidentRegister;
