import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import northAmericaMap from 'assets/combined-us-canada-mexico-with-states-provinces.geojson';
import Tooltip from 'common/Tooltip';
import Autocomplete from 'common/Autocomplete';
import { palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import { getStats } from 'Api/Carriers';
import { saveFinishLater } from 'Api/DriverV2';
import { updateGeneDriver } from 'Api/Driver';
import { getErrorMessage } from 'utils/error';

const TravelArea = ({ basicDetails, Cancel, prevStep, setStepsDone, nextStep, driverId, getDrivers, draftDriver }) => {
  const showToaster = useShowToaster();
  const [selectedStates, setSelectedStates] = useState(basicDetails?.operating_states || []);
  const [states, setStates] = useState([]);
  const [tooltipContent, setTooltipContent] = useState('');
  const [loading, setLoading] = useState(false);

  const toggleState = (stateId) => {
    const partToCheck = stateId.includes('-') ? stateId.split('-').pop() : stateId;
    const country = stateId.includes('-') ? stateId.split('-')?.[0] : stateId;

    const checkCountryId = country === 'US' ? 1 : country === 'CA' ? 2 : 3;

    if (
      (basicDetails?.can_travel_usa && checkCountryId === 1) ||
      (basicDetails?.can_travel_canada && checkCountryId === 2) ||
      (basicDetails?.can_travel_mexico && checkCountryId === 3)
    ) {
      const currentState = states.find((state) => state.short_name === partToCheck);
      setSelectedStates((prev) => {
        const isStatePresent = prev.some((item) => item.id === currentState.id);

        return isStatePresent ? prev.filter((item) => item.id !== currentState.id) : [...prev, currentState];
      });
    } else {
      showToaster({ type: 'error', message: 'Driver can not travel to that country' });
    }
  };

  const handleStateChange = (value, reason, details) => {
    const updateStatesList = (countryId, countryName) => {
      if (reason === 'selectOption' && details.option.id === countryId) {
        return states.filter((item) => item.country?.name === countryName);
      }
      if (reason === 'removeOption' && details.option.id === countryId) {
        value = value.filter((item) => item.country?.name !== countryName);
        return [];
      }
      return [];
    };

    const allUsaStates = updateStatesList('usa', 'United States');
    const allCanadaStates = updateStatesList('canada', 'Canada');
    const allMexicoStates = updateStatesList('mexico', 'Mexico');

    const mergedValues = [...value, ...allUsaStates, ...allCanadaStates, ...allMexicoStates];
    setSelectedStates(mergedValues);
  };

  const onNextStep = async () => {
    try {
      setLoading(true);
      const params = {
        operating_states: selectedStates.map((i) => i.id).filter((i) => Number(i)),
      };
      await updateGeneDriver(params, driverId);
      nextStep(selectedStates);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Some' });
    } finally {
      setLoading(false);
    }
  };

  const onPrevStep = () => {
    setStepsDone((prevState) => ({
      ...prevState,
      isPolicy: true,
    }));
    prevStep();
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 6,
    };

    saveFinishLater(driverId, body).then(() => {
      getDrivers();
      Cancel();
    });
  };

  useEffect(() => {
    const countryIds = [];
    if (basicDetails?.can_travel_usa) {
      countryIds.push('231');
    }
    if (basicDetails?.can_travel_canada) {
      countryIds.push('38');
    }
    if (basicDetails?.can_travel_mexico) {
      countryIds.push('142');
    }

    const params = {
      'country_id[]': countryIds,
    };

    getStats(params).then((res) => {
      const statesWithCountries = res.data
        .map((item) => {
          if (item.id && item.short_name) {
            return item;
          }
          return null;
        })
        .filter((item) => item !== null);

      if (statesWithCountries) {
        if (basicDetails?.can_travel_usa) {
          statesWithCountries.unshift({ short_name: 'All United States', id: 'usa' });
        }
        if (basicDetails?.can_travel_canada) {
          statesWithCountries.unshift({ short_name: 'All Canada', id: 'canada' });
        }
        if (basicDetails?.can_travel_mexico) {
          statesWithCountries.unshift({ short_name: 'All Mexico', id: 'mexico' });
        }
      }

      setStates(statesWithCountries);
    });
  }, []);

  return (
    <>
      <Modal.Body className='add-driver-modal-body'>
        <div style={{ backgroundColor: palette.gray0, height: '100%' }}>
          <div className='d-flex gap-4 mt-4'>
            <div className='mt-2'>
              <Autocomplete
                width='300px'
                label='Please select where the driver operates'
                labelKey='short_name'
                multiple
                options={states}
                placeholder={selectedStates?.length ? '' : 'Select States'}
                value={selectedStates}
                onChange={(e, val, reason, details) => handleStateChange(val, reason, details)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
              />
            </div>
            <ComposableMap
              projection='geoMercator'
              projectionConfig={{
                scale: 200,
                center: [-100, 60],
              }}
              style={{
                width: '950px',
                height: 'auto',
                margin: '0',
                display: 'block',
              }}
            >
              <Tooltip
                title={tooltipContent}
                placement='left'
                arrow={false}
                componentsProps={{
                  placement: 'left',
                  tooltip: {
                    sx: {
                      fontSize: 16,
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                      maxWidth: 'unset',
                    },
                  },
                }}
              >
                <Geographies geography={northAmericaMap}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const partToCheck = geo.id?.includes('-') ? geo.id?.split('-').pop() : geo?.id;
                      const isStateSelected = selectedStates.some((item) => item.short_name === partToCheck);
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          onClick={() => {
                            toggleState(geo.id);
                          }}
                          onMouseEnter={() => {
                            const { name } = geo.properties;
                            setTooltipContent(name);
                          }}
                          onMouseLeave={() => {
                            setTooltipContent('');
                          }}
                          style={{
                            default: {
                              fill: isStateSelected ? palette.indigo500 : palette.gray700,
                              stroke: '#FFF',
                              strokeWidth: 0.75,
                              outline: 'none',
                            },
                            hover: {
                              fill: palette.indigo200,
                              stroke: '#FFF',
                              strokeWidth: 0.75,
                              outline: 'none',
                            },
                            pressed: {
                              fill: palette.indigo500,
                              stroke: '#FFF',
                              strokeWidth: 0.75,
                              outline: 'none',
                            },
                          }}
                        />
                      );
                    })
                  }
                </Geographies>
              </Tooltip>
            </ComposableMap>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: palette.white,
          borderColor: palette.gray50,
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            <Button className='cancel-button ms-0' onClick={nextStep}>
              Skip
            </Button>
            <Button className='next-step' onClick={onNextStep} type='submit' disabled={loading}>
              Next Step
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default TravelArea;
