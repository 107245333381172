import React from 'react';
import { capitalize } from 'lodash';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import useDateFormat from 'hooks/useDateFormat';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import RowActions from './RowActions';

const accountStatus = {
  active: { label: 'ACTIVE', color: palette.green500, bgColor: palette.green0 },
  intrial: { label: 'IN TRIAL', color: palette.green500, bgColor: palette.green0 },
  trialexpired: { label: 'TRIAL EXPIRED', color: palette.red500, bgColor: palette.red0 },
  pastdue: { label: 'PAST DUE', color: palette.red500, bgColor: palette.red0 },
  canceled: { label: 'CANCELED', color: palette.gray700, bgColor: palette.gray0 },
};

export const useColumns = ({ onCancelAccount, onManageLicenses, onUpgradeDowngrade }) => {
  const { formatDate, convertToCustomerTime, dateFormat } = useDateFormat();

  return [
    {
      title: <ColumnHeader title='PLAN TYPE' field='plan_type' />,
      field: 'plan_type',
      render: (row) => <Typography variant='b2'>{planMapper[row.customer_plan?.plan?.duration]?.title}</Typography>,
    },
    {
      title: <ColumnHeader title='USER LICENSE' field='user_license_count' />,
      field: 'user_license_count',
      render: (row) => <Typography variant='b2'>{row.customer_plan?.user_licence_count}</Typography>,
    },
    {
      title: <ColumnHeader title='STATUS' field='status' />,
      field: 'status',
      render: (row) => (
        <Chip
          label={
            accountStatus[row.customer_plan?.customer_status]?.label || capitalize(row.customer_plan?.customer_status)
          }
          labelColor={accountStatus[row.customer_plan?.customer_status]?.color}
          bgColor={accountStatus[row.customer_plan?.customer_status]?.bgColor}
          fontSize='11px'
          fontWeight={500}
        />
      ),
    },
    {
      title: <ColumnHeader title='AMOUNT' field='amount' />,
      field: 'amount',
      render: (row) => (
        <div className='d-flex align-items-center gap-2'>
          <Typography variant='b2'>${formatNumber(row.customer_plan?.amount)}</Typography>
          <Chip label={row.customer_plan?.plan?.duration?.toUpperCase()} fontSize='11px' fontWeight={500} />
        </div>
      ),
    },
    {
      title: <ColumnHeader title='CREATE DATE' field='created_at' />,
      field: 'created_at',
      render: (row) => (
        <Typography variant='b2'>
          {row.customer_plan?.created_at ? convertToCustomerTime(row.customer_plan.created_at, dateFormat) : '-'}
        </Typography>
      ),
    },
    {
      title: <ColumnHeader title='RENEWAL DATE' field='renewal_date' />,
      field: 'renewal_date',
      render: (row) => (
        <Typography variant='b2'>
          {row.customer_plan?.next_renewal_date && row.customer_plan.next_renewal_date?.includes('T')
            ? formatDate(row.customer_plan.next_renewal_date.split('T')[0])
            : formatDate(row.customer_plan?.next_renewal_date)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      render: () => (
        <RowActions
          onCancelAccount={onCancelAccount}
          onManageLicenses={onManageLicenses}
          onUpgradeDowngrade={onUpgradeDowngrade}
        />
      ),
    },
  ];
};
