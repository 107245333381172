import React from 'react';
import { Modal } from 'react-bootstrap';
import Tooltip from 'common/Tooltip';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import CustomButton from '../CustomButton/CustomButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

const CustomModal = ({
  children,
  onHide,
  showModal,
  styleModal,
  size,
  styleBody,
  customBody,
  buttons,
  headerTitle,
  filter,
  updateFilter,
  styleButtons,
  onExited,
  footer = true,
  className = '',
  backdropClassName,
  backdrop,
  customHeader,
  customFooter,
}) => {
  const { use } = useTheme();

  const onChangeTick = (data) => {
    const applyAllUsersModal = { ...filter.applyAllUsersModal, value: String(data) };
    updateFilter({ ...filter, applyAllUsersModal });
  };

  return (
    <Modal
      size={size || 'lg'}
      centered
      show={showModal}
      onHide={onHide}
      onExited={onExited}
      className={`customModal ${className}`}
      style={styleModal}
      enforceFocus={false}
      backdropClassName={backdropClassName}
      backdrop={backdrop}
    >
      <Modal.Body style={styleBody}>
        {(headerTitle !== undefined || customHeader) && (
          <div className='customHeader' style={{ background: use(palette.white, palette.dark800) }}>
            {customHeader || <span style={{ color: use(palette.gray900, palette.white) }}>{headerTitle}</span>}
          </div>
        )}
        <div className='customBody' style={customBody || { background: use(palette.gray0, palette.dark700) }}>
          {children}
        </div>

        {footer && (
          <div className='customFooter' style={{ background: use(palette.white, palette.dark800) }}>
            {filter?.applyAllUsersModal !== undefined && (
              <CustomCheckbox
                checked={Number(filter?.applyAllUsersModal.value)}
                onChange={() => onChangeTick(Number(filter?.applyAllUsersModal.value) ? 0 : 1)}
              >
                <span className='footer_title_checkbox'>Apply to all users</span>
              </CustomCheckbox>
            )}
            {customFooter || (
              <div className='footer_buttons'>
                {buttons?.map((el, i) =>
                  el.tooltip ? (
                    <Tooltip title={el.tooltip} placement='top' key={el.key}>
                      <span>
                        <CustomButton
                          key={el.key}
                          type={el.type}
                          onClick={el.key === 'close' && !el.onClick ? onHide : el.onClick}
                          title={el.title}
                          className={el.className}
                          disabled={el.disabled}
                          styleButton={{ margin: i === 0 ? 10 : '10px 0 10px', padding: '2px 12px', ...styleButtons }}
                          styleTitle={{
                            fontSize: 12,
                            marginTop: 1,
                          }}
                          buttonProps={el.buttonProps}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <CustomButton
                      key={el.key}
                      type={el.type}
                      onClick={el.key === 'close' && !el.onClick ? onHide : el.onClick}
                      title={el.title}
                      className={el.className}
                      disabled={el.disabled}
                      styleButton={{ margin: i === 0 ? 10 : '10px 0 10px', padding: '2px 12px', ...styleButtons }}
                      styleTitle={{
                        fontSize: 12,
                        marginTop: 1,
                      }}
                      buttonProps={el.buttonProps}
                    />
                  )
                )}
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
