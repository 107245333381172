import { string, object } from 'yup';

export const getInitialValues = (addonData) => {
  const initialValues = { count: '1' };

  addonData?.add_custom_fields?.forEach((item) => {
    if (item?.field_name) {
      initialValues[item.field_name] = '';
    }
  });

  return initialValues;
};

export const getValidationSchema = (data) => {
  const schema = {};
  data?.add_custom_fields?.forEach((item) => {
    if (item?.field_name) {
      schema[item.field_name] = string().trim().required('Required').nullable();
    }
  });

  return object(schema);
};
