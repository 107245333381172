import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from '../../../utils/constants';

export const SActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${palette.gray50};
`;

export const SBackButton = styled.div`
  margin: 24px 0 4px;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const SLink = styled(Link)`
  color: ${palette.indigo500};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;

  &:hover {
    text-decoration: underline;
    color: ${palette.indigo500};
  }
`;

export const SDescription = styled.div`
  li {
    list-style-type: '>';
    padding-inline-start: 1ch;
  }

  li::marker {
    color: ${palette.indigo500};
  }

  span {
    background-color: transparent !important;
  }

  * {
    font-family: 'Inter', sans-serif !important;
  }
`;
