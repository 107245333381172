import jsPDF from 'jspdf';
import moment from 'moment/moment';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import globeIcon from 'assets/icons/globe.png';
import locationIcon from 'assets/icons/location.png';
import { removeCommas } from 'utils/helpers';
import { DATE_FORMAT, formatNumber, TIME_FORMAT } from 'utils/constants';

const createFooter = ({ doc, company, page = 1, totalPages = 1 }) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const { address, address2, city, state, zipcode, phone_number, website, show_website, company_email } = company || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  // background
  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 24, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5.5, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(51, pageHeight - 20, 51, pageHeight - 10);
    doc.line(109, pageHeight - 20, 109, pageHeight - 10);
    doc.line(174, pageHeight - 20, 174, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 8, pageHeight - 20, 3, 3);
    doc.addImage(globeIcon, 'png', 56, pageHeight - 20, 3, 3);
    doc.addImage(mailIcon, 'png', 114, pageHeight - 20, 4, 3);
    doc.addImage(phoneIcon, 'png', 179, pageHeight - 20, 3, 3);
    doc.text('Address', 13, pageHeight - 17.5);
    doc.text('Online', 61, pageHeight - 17.5);
    doc.text('Email', 119, pageHeight - 17.5);
    doc.text('Phone', 184, pageHeight - 17.5);

    doc.setFontSize(8);
    doc.setTextColor('#171C26');
    doc.text(`${address}${address2 ? `, ${address2}` : ''}`, 8, pageHeight - 13);
    doc.text(`${city?.name}, ${state?.short_name || state?.name} ${zipcode}`, 8, pageHeight - 9, { maxWidth: 45 });

    if (show_website && website) {
      doc.text(`${website}`, 56, pageHeight - 13);
    } else {
      doc.text('-', 56, pageHeight - 13);
    }
    if (company_email) {
      doc.text(`${company_email || '-'}`, 114, pageHeight - 13);
    } else {
      doc.text('-', 114, pageHeight - 13);
    }
    doc.text(`${phone}`, 179, pageHeight - 13);
  }
};

export const generateIndemityPdf = async ({ company, carrier, values }) => {
  const {
    logo,
    company_name,
    signature_first_name,
    signature_last_name,
    master_agreement_title,
    liability_minimum_coverage,
    cargo_insurance_minimum_coverage,
    general_liability_minimum_coverage,
    use_own_trailer_interchange,
    trailer_interchange_agreement,
    address,
    // address2,
    city,
    state,
    // country,
    zipcode,
    phone_number,
    receivable_email,
    signature,
    date_time_format_settings,
  } = company || {};

  const {
    name,
    trailer_interchange,
    address1: carrierAddress1,
    city: carrierCity,
    state: carrierState,
    zipcode: carrierZipcode,
    email: carrierEmail,
    trailer_interchange_from,
    trailer_interchange_expires,
  } = carrier || {};
  const { title, printedName, phone, signature: indemitySignature } = values || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  const dateFormat = DATE_FORMAT[date_time_format_settings?.date_format] || DATE_FORMAT[1];
  const timeFormat = TIME_FORMAT[date_time_format_settings?.time_format] || TIME_FORMAT[1];

  doc.addImage(`${logo}?r=${Math.random()}`, 'JPEG', 8, 2, 40, 10, 'Logo', 'FAST');

  doc.setFontSize(14);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Trailer Interchange and Indemnity Agreement', pageWidth / 2, 18, { align: 'center' });
  doc.setDrawColor(188, 194, 206);

  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 400);

  const interchargeHtml = `
<p>This Trailer Interchange and Indemnity Agreement is entered into between ${company_name} located at ${address}, ${
    city?.name
  }, ${state?.short_name} ${zipcode} and ${name || 'Carrier'} located at ${carrierAddress1 || ''}, ${
    carrierCity?.name
  }, ${carrierState?.short_name} ${carrierZipcode} on ${moment().format('Do')} day of ${moment().format(
    'MMMM YYYY'
  )} and shall govern the terms and conditions of the use of trailer equipment belonging to one party by another.</p>
<p>WHEREAS, the parties are engaged in transportation agreements which provide for the through movement of freight including pickup and delivery and spotting of trailer equipment under circumstances which require or permit one party to use the trailer of another to facilitate service; and</p>
<p>WHEREAS, the parties are engaged in transportation agreements which provide for the through movement of freight including pickup and delivery and spotting of trailer equipment under circumstances which require or permit one party to use the trailer of another to facilitate service; and</p>

<p>WHEREAS, this agreement is valid from ${moment(trailer_interchange_from || new Date()).format(
    dateFormat
  )} and will expire on midnight  ${moment(trailer_interchange_expires || new Date()).format(dateFormat)}; and </p>

<p>WHEREAS, the parties desire to permit such interchange and use of trailer by the other subject to the terms and conditions of this uniform Agreement;</p>
<p>NOW, THEREFORE, the Parties agree as follows:</p>
${
  !use_own_trailer_interchange || !trailer_interchange_agreement
    ? `
<p>As otherwise agreed in writing from time to time, one party may make permissive use of trailer equipment owned by the other with or without compensation under the following conditions.</p>
<p>1. The party owning the equipment warrants that equipment is regularly maintained and is free from any known defects by it.</p>
<p>2. Upon acceptance of any trailer, the receiving party shall prepare an equipment receipt/inspection report noting all damage, absence of damage and conditions of safety related items including, but not limited to, tires, brakes, air systems, sliding tandem hook pin. Such reports shall be prepared upon the acceptance of the trailer. Should the inspection reveal any safety related defects, the receiving party will notify the owner before use. Use of any trailer without notification or the preparation of an inspection report shall be deemed as acceptance without recourse. No warranty, express or implied, is made by the owner of the quality, design, or manufacture of the equipment. The owning party agrees to extend to the receiving party all warranties, if any, offered by the manufacturers of the equipment. The receiving party shall be responsible for all loss or damage to trailer while the trailer is in its possession and control,except to the extent such loss is caused by the act or omission of owner or its employees.</p>
<p>3. Receipt by any party of equipment belonging to the other without written notice shall constitute the receiving party’s acknowledgment that the shipment is in good working order and free of reasonable defect.</p>
<p>4. While equipment is in its care, custody and control, any party receiving the equipment of another under this Agreement warrants as follows:</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(a) It will indemnify and hold the owner of the trailer equipment harmless from all risk of loss, claims, damage or cause of action arising out of its use including, but not limited to, any legal fee, the cost of defense or a judgment resulting from any claim of negligent entrustment or vicarious liability arising out of the receiving party's use, including the loading and unloading of the trailer while in the receiving party’s possession.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(b) The receiving party shall use the trailer only for the use contemplated by the authorizing agreement between the parties and shall return same at the completion of use to the location agreed to by the parties, free from defect, ordinary wear and tear excepted.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(c) The receiving party shall bear all costs associated with any physical damage resulting from its use, and assume liability for any loss and/or damage to equipment (including shipments contained therein) while said equipment is stored on its premises or is being used by it or its designee to load, unload, transport cargo, or reposition equipment, unless such loss or damage is caused by the negligent acts or omissions of the owner.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(d) The receiving party warrants that its auto liability coverage is properly endorsed to extend coverage to the owner of trailer equipment under a lease interchange endorsement.</p>
<p>5. Insurance. As financial surety for the indemnities and undertakings in 1 through 4 above, each warrants that it and/or its designee shall maintain the following insurance coverage which it warrants will inure to the owner of the trailer equipment’s benefit:</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(a) Commercial General Liability Coverage in the amount of not less than $${formatNumber(
        removeCommas(general_liability_minimum_coverage)
      )}.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(b) Auto Liability Coverage in the amount of not less than $${formatNumber(
        removeCommas(liability_minimum_coverage)
      )} per occurrence.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(c) Motor Truck Cargo Insurance in the amount of not less than the market value of shipment but not to exceed $${formatNumber(
        removeCommas(cargo_insurance_minimum_coverage)
      )} per truck load.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(d) Worker’s Compensation Insurance in the amount required by applicable state law.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;(e) Physical Damage Insurance in an amount equal to $${formatNumber(
        removeCommas(trailer_interchange)
      )} below or exceeding the value of the interchanged equipment or evidence of an appropriate trailer interchange endorsement.</p>
<p>Each will be named a certificate holder as well as an additional insured on (a), supra and as a loss payee on (c) and (e) supra, on the other parties’ policies.</p>
<p>6. Miscellaneous. This Agreement will become effective on the date written below and will continue from month-to-month thereafter, subject to termination by either party on 10 days prior written notice to the other. This Agreement shall not be modified except by written instrument signed by authorized representatives for affected parties. This Agreement shall be governed by and interpreted under the laws of the State of Tennessee. In the absence of written trailer interchange executed at time of the transfer of possession or other contemporaneous written notification, the receiving party of equipment accepts the equipment free from observable defect or damage.</p>
`
    : `${trailer_interchange_agreement}`
}
`;

  const wrappedHtmlString = `
    <div id="vazgulya" style="font-size: 10px; width: 200px">
        <style>
        #vazgulya h2, h4, li, ul, h1, h3, h5, h6 {
         font-size: 3.8px;
         line-height: 4px;
         font-family: 'Inter', sans-serif;
         letter-spacing: 0;
         font-weight: 500;
         word-spacing: -0.5px;
         margin-bottom: 3px;
        }      
        
        #vazgulya figure {
          display: unset;
          margin-block-start: unset;
          margin-block-end: unset;
          margin-inline-start: unset;
          margin-inline-end: unset;
          unicode-bidi: unset;
          border: none;
      }
      
      #vazgulya figure table {
          border-collapse: collapse;
          border: none;
      }
      
      #vazgulya figure table tbody {
         border: none;
      }
      
      #vazgulya figure table tr {
          border: none;
      }
      
      #vazgulya figure table td {
          font-size: 3.8px;
          line-height: 4px;
          font-family: 'Inter', sans-serif;
          letter-spacing: 0;
          font-weight: 500;
          padding: 2px;
          border: 0.1px solid black;
      }
        
        #vazgulya strong {
          font-size: 3.8px;
          line-height: 4px;
          font-family: 'Inter', sans-serif;
          letter-spacing: 0;
          font-weight: 700;
          vertical-align: super;
        }
        
         #vazgulya p {
         word-spacing: -0.5px;
         font-weight: 500;
         font-size: 3.8px;
         line-height: 4px;
         font-family: 'Inter', sans-serif;
         letter-spacing: 0;
         margin-bottom: 3px;
         padding: 0;
        }      
        
        </style>
       ${interchargeHtml}
    </div>`;

  await doc.html(wrappedHtmlString, {
    x: 0,
    y: 18,
    margin: [8, 8, 20, 8],
    width: pageWidth,
    autoPaging: 'text',
  });

  doc.addPage();
  doc.setFontSize(9);
  doc.setFont('Inter', 'normal', 400);
  doc.setLineWidth(0.3);
  doc.setDrawColor(188, 194, 206);

  doc.text(`${company_name || ''}`, 8, 16);
  if (name) {
    doc.text(`${name}`, pageWidth - 78, 16);
  }

  // Signature
  if (!signature) {
    doc.roundedRect(8, 18, 70, 35, 2, 2);
  }
  if (!indemitySignature) {
    doc.roundedRect(pageWidth - 78, 18, 70, 35, 2, 2);
  }

  doc.addImage(`${signature}?r=${Math.random()}`, 'JPEG', 16, 26, 40, 20);
  if (indemitySignature) {
    doc.addImage(`${indemitySignature}`, 'JPEG', pageWidth - 70, 21, 50, 30);
  }

  doc.setTextColor('#687182');
  doc.text('Broker, Authorized Signature', 8, 57);
  doc.text('Carrier, Authorized Signature', pageWidth - 78, 57);

  // Printed Name
  doc.text('Printed Name', 10, 65);
  doc.text('Printed Name', pageWidth - 76, 65);

  doc.setTextColor('#000000');

  doc.text(`${signature_first_name || ''} ${signature_last_name || ''}`, 10, 70);
  if (printedName) {
    doc.text(`${printedName}`, pageWidth - 76, 70);
  }

  // Title
  doc.setTextColor('#687182');
  doc.text('Title', 10, 81);
  doc.text('Title', pageWidth - 76, 81);

  doc.setTextColor('#000000');
  doc.text(`${master_agreement_title || ''}`, 10, 86);
  doc.text(title || '', pageWidth - 76, 86);

  // Company Address
  doc.setTextColor('#687182');
  doc.text('Company Address', 10, 97);
  doc.text('Company Address', pageWidth - 76, 97);

  doc.setTextColor('#000000');
  if (address) {
    doc.text(`${address}`, 10, 102);
    doc.text(`${city?.name || ''}, ${state?.short_name || ''} ${zipcode || ''}`, 10, 107);
  }
  if (carrierAddress1) {
    doc.text(`${carrierAddress1}`, pageWidth - 76, 102);
    doc.text(
      `${carrierCity?.name || ''}, ${carrierState?.short_name || ''} ${carrierZipcode || ''}`,
      pageWidth - 76,
      107
    );
  }

  // Phone
  doc.setTextColor('#687182');
  doc.text('Phone', 10, 113);
  doc.text('Phone', pageWidth - 76, 113);

  doc.setTextColor('#000000');
  doc.text(phone_number ? `${formatPhoneNumberIntl(phone_number)}` : '', 10, 118);
  doc.text(phone ? `${formatPhoneNumberIntl(phone)}` : '', pageWidth - 76, 118);

  // Email
  doc.setTextColor('#687182');
  doc.text('Email', 10, 129);
  doc.text('Email', pageWidth - 76, 129);

  doc.setTextColor('#000000');
  doc.text(`${receivable_email || ''}`, 10, 134);
  doc.text(`${carrierEmail || ''}`, pageWidth - 76, 134);

  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();

  // Ip Address
  doc.setTextColor('#687182');
  doc.text('IP Address', pageWidth - 76, 145);

  doc.setTextColor('#000000');
  doc.text(`${data.ip || ''}`, pageWidth - 76, 149);

  // Date
  doc.setTextColor('#687182');
  doc.text('Date', 10, 145);
  doc.text('Date', pageWidth - 76, 160);

  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  doc.setTextColor('#000000');

  doc.text(moment().tz(currentTimezone).format(`${dateFormat} ${timeFormat} z`), 10, 149);
  doc.text(moment().tz(currentTimezone).format(`${dateFormat} ${timeFormat} z`), pageWidth - 76, 165);

  const totalPages = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter({
      doc,
      company,
      page,
      totalPages,
    });
  }

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  return { blob, url };
};
