import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${({ $color }) => $color || palette.gray800};
  background-color: ${({ $bgColor }) => $bgColor || palette.white};
`;
