import styled from 'styled-components';

export const SPageWrapper = styled.div`
  padding-top: 16px;
`;

export const STableWrapper = styled.div`
  padding-bottom: 24px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const STableHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 12px;

  & > :nth-child(1) {
    width: 25%;
  }

  & > :nth-child(2) {
    width: 40%;
  }

  & > :nth-child(3) {
    width: 15%;
  }

  & > :nth-child(4) {
    width: 18%;
  }

  & > :nth-child(5) {
    width: 2%;
  }
`;
