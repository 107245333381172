import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { Typography } from 'components/Typography';
import { getReadyToInvoice } from 'Api/Dashboard';
import { formatNumber, palette } from 'utils/constants';
import ReadyToInvoiceChart from 'pages/Dashboard/components/Charts/ReadyToInvoiceChart';
import { yearFilters, initialCountsData, countsDataConverter } from './ReadyToInvoice.data';
import { SBoxContent } from '../../../Dashboard.styles';

const ReadyToInvoice = () => {
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [countsData, setCountsData] = useState(initialCountsData);

  const getCounts = async () => {
    try {
      const params = {
        miles_from_date: moment(yearFilters.miles_range.start).format('YYYY-MM-DD'),
        miles_to_date: moment(yearFilters.miles_range.end).format('YYYY-MM-DD'),
        revenue_from_date: moment(yearFilters.revenue_range.start).format('YYYY-MM-DD'),
        revenue_to_date: moment(yearFilters.revenue_range.end).format('YYYY-MM-DD'),
        closed_from_date: moment(yearFilters.closed_range.start).format('YYYY-MM-DD'),
        closed_to_date: moment(yearFilters.closed_range.end).format('YYYY-MM-DD'),
        graphReadyAndMissingYear: yearFilters.graphReadyAndMissingYear,
        graphOpenReceivablesYear: yearFilters.graphOpenReceivablesYear,
        graphOpenReceivablesMonth: yearFilters.graphOpenReceivablesMonth,
        closed_month: yearFilters.closed_month,
      };

      const { data } = await getReadyToInvoice(params);

      const convertedData = countsDataConverter(data);
      setCountsData(convertedData);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <SBoxContent>
        <div className='d-flex justify-content-between' style={{ height: '66px' }}>
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Ready to Invoice
            </Typography>
            <div>
              {loading ? (
                <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '100px', height: '32px' }} />
              ) : (
                <Typography variant='button2' style={{ display: 'flex', color: palette.indigo500 }}>
                  {currency}
                  {formatNumber(countsData?.readyAndMissing?.readyToInvoiceAmount)}
                  <span className='receivable-chart-section-tag-indigo'>
                    {countsData?.readyAndMissing?.readyToInvoiceCount}
                  </span>
                </Typography>
              )}
            </div>
          </div>
          <div className='d-flex flex-column gap-1'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Missing Paperwork
            </Typography>
            <div>
              {loading ? (
                <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '100px', height: '32px' }} />
              ) : (
                <Typography variant='button2' style={{ display: 'flex', color: palette.red500 }}>
                  {currency}
                  {formatNumber(countsData?.readyAndMissing?.missingPaperworkAmount)}
                  <span className='receivable-chart-section-tag-red'>
                    {countsData?.readyAndMissing?.missingPaperworkCount}
                  </span>
                </Typography>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <Skeleton variant='rect' animation='wave' height='100%' width='100%' style={{ borderRadius: '10px' }} />
        ) : (
          <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
            <ReadyToInvoiceChart monthsData={countsData?.readyAndMissing?.monthsData} />
          </div>
        )}
      </SBoxContent>
    </div>
  );
};

export default ReadyToInvoice;
