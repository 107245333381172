import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const VendorActions = ({ vendor, onUpdateStatus, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'edit':
        setTimeout(onEdit);
        break;
      case 'changeStatus':
        setTimeout(onUpdateStatus);
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl} size={18}>
      <li onClick={(event) => onActionClick(event, 'edit')}>Edit</li>
      <li
        className={vendor?.active ? 'delete-action' : 'green-action'}
        onClick={(event) => onActionClick(event, 'changeStatus')}
      >
        {vendor?.active ? 'Set Inactive' : 'Set Active'}
      </li>
    </ThreeDotActions>
  );
};

export default VendorActions;
