import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import user from 'assets/icons/profile.svg';
import MessagePopup from 'common/MessagePopup';
import { palette, STOP_POINT_TAB } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { useTheme } from 'context/themeContext';
import { TableContext } from 'context/tableContext';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { ReactComponent as Notes } from 'assets/icons/notesSmall.svg';
import { ReactComponent as Check } from 'assets/icons/checkGreen.svg';
import { ReactComponent as NoCheck } from 'assets/icons/closeRed.svg';
import { GetShipmentDetention } from 'Api/DetentionShipment';
import { Typography } from 'components/Typography';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { DocumentTableSort } from 'components/DocumentsTableSort/DocumentsTableSort';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import useDateFormat from 'hooks/useDateFormat';
import ModifyDuration from 'components/TableShipments/detailsRow/steps/Detention/ModifyDuration';
import styles from './Detention.module.css';
import DetentionHeader from './DetentionHeader/DetentionHeader';
import DetentionFooter from './DetentionFooter/DetentionFooter';
import BillDetention from './BillDetention';
import CheckoutTrailer from './CheckoutTrailer';

const Detention = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const elementsRef = useRef();
  const { theme, use } = useTheme();
  const { convertToCustomerTime } = useDateFormat();
  const myTableRef = useRef(null).current;
  const [search] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);
  const [dragItem, setDragItem] = useState([]);
  const [showTool, setShowTool] = useState({});
  const [billModal, setBillModal] = useState({ visible: false });
  const [dateToModify, setDateToModify] = useState(null);
  const [tableColumn, setTableColumn] = useState([]);
  const [checkoutData, setCheckoutData] = useState(null);
  const [detentionData, setDetentionData] = useState(null);

  function navigateToInventory(stopId) {
    navigate(`/stop-point/${stopId}/${STOP_POINT_TAB.INVENTORY}`);
  }

  const [filter, setFilter] = useState({
    switchRadioButtons: {
      amount: '0',
      dateFormat: '1',
      timeFormat: '1',
    },
    forAll: false,
    filterTableTop: [
      {
        key: 'vehicles',
        label: 'Vehicles',
        value: 0,
        id: 0,
        count: 0,
      },
      {
        key: 'trailers',
        label: 'Trailers',
        value: 1,
        id: 1,
        count: 0,
      },
    ],
    selectedValues: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    tab: 0,
    page: 1,
    query: '',
    page_size: 25,
  });
  const [detention, setDetention] = useState(null);
  const [warningOpen, setWarningOpen] = useState(false);

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    })
  );

  const onBillDetention = (e, data) => {
    e.stopPropagation();
    if (!data.departed_date) {
      setWarningOpen(true);
      setDetentionData(data);
      return;
    }
    setBillModal({ visible: true, ...data });
  };

  const onModifyDuration = (e, data) => {
    e.stopPropagation();
    setDateToModify(data);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'location_name',
        title: <DocumentTableSort title='LOCATION NAME' field='location_name' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const location_name = rowData?.shipment_stop?.stop_point?.location_name;
          const note = rowData?.note;
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='s2' style={{ color: use(palette.gray900, palette.gray200), marginRight: 5 }}>
                  {location_name || ''}
                </Typography>
                {note && (
                  <LightTooltip title={note} placement='top' arrow>
                    <Notes />
                  </LightTooltip>
                )}
              </div>
            </div>
          );
        },
      },
      {
        field: 'equipment_id',
        title: <DocumentTableSort title='EQUIPMENT ID' field='equipment_id' sortingQuery={sortingQuery} />,
        render: (rowData) => {
          const equipment_id = rowData?.equipment?.equipment_id;
          const equipment_type = rowData?.equipment?.equipment_type?.title;
          const length = rowData?.equipment?.length?.length;
          const unit = rowData?.equipment?.length?.unit;
          return (
            <div style={{ display: 'flex', gap: 8 }}>
              {length && (
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {length || ''}
                  {unit || ''}
                </Typography>
              )}
              {equipment_type && (
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {equipment_type || ''}
                </Typography>
              )}
              {equipment_id && (
                <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {equipment_id || ''}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        field: 'customer',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            CUSTOMER
          </Typography>
        ),
        render: (rowData) => {
          const customer = rowData?.shipment_billing?.billing_customer?.customer_name;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {customer || ''}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'arrived_date',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DETENTION START
          </Typography>
        ),
        render: (rowData) => {
          const arrived_date = rowData?.arrived_date;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {arrived_date ? convertToCustomerTime(arrived_date, null, true) : ''}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'departed_date',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DETENTION END
          </Typography>
        ),
        render: (rowData) => {
          const departed_date = rowData?.departed_date;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {departed_date ? (
                  convertToCustomerTime(departed_date, null, true)
                ) : (
                  <span style={{ marginLeft: 30 }}>-</span>
                )}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'duration',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DURATION
          </Typography>
        ),
        render: (rowData) => {
          const duration_days = rowData?.duration_days;
          const duration_hours = rowData?.duration_hours;
          const duration_minutes = rowData?.duration_minutes;
          return (
            <div style={{ display: 'flex', gap: 6 }}>
              {!!duration_days && (
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {duration_days}d
                </Typography>
              )}
              {(!!duration_hours || duration_hours === 0) && (
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {duration_hours}h
                </Typography>
              )}
              {(!!duration_minutes || duration_minutes === 0) && (
                <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                  {duration_minutes}m
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        field: 'updated_by',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            UPDATED BY
          </Typography>
        ),
        render: (rowData) => {
          const updated_by = rowData?.updated_by;
          return (
            <div className={styles.lastRow}>
              <div>
                {updated_by ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <div style={{ height: 30 }}>
                      <figure style={{ display: 'flex', width: 30, height: 30, borderRadius: '50%' }}>
                        <img
                          style={{
                            display: 'flex',
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                          }}
                          src={updated_by?.profile_logo || user}
                          alt='img'
                        />
                      </figure>
                    </div>
                    <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                      {updated_by?.first_name} {updated_by?.last_name}
                    </Typography>
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <div style={{ height: 30 }}>
                      <figure style={{ display: 'flex', width: 30, height: 30, borderRadius: '50%' }}>
                        <img
                          style={{
                            display: 'flex',
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                          }}
                          src={truckinLogo}
                          alt='img'
                        />
                      </figure>
                    </div>
                    <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                      System
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        field: 'billed',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            BILLED
          </Typography>
        ),
        render: (rowData) => {
          return <div style={{ display: 'flex', marginLeft: 15 }}>{rowData?.billed_at ? <Check /> : <NoCheck />}</div>;
        },
      },
      {
        field: 'update',
        title: <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }} />,
        render: (rowData) => {
          return (
            <div className={styles.lastRow}>
              <div onMouseLeave={() => setShowTool((prev) => ({ ...prev, [rowData.id]: false }))}>
                <div
                  ref={elementsRef?.current?.[rowData.id]}
                  className={classNames([styles.editIconBox, { [styles.show]: showTool[rowData.id] }])}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTool((prev) => ({ ...prev, [rowData.id]: true }));
                  }}
                >
                  <EditIcon className={styles.editIcon} />
                </div>

                <Overlay show={!!showTool[rowData.id]} target={elementsRef?.current?.[rowData.id]} placement='left'>
                  {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div {...props} className={styles.dropDown}>
                      <div onClick={(e) => onBillDetention(e, rowData)} className={styles.text}>
                        <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                          {rowData?.billed_at ? 'Revert Bill' : 'Bill Detention'}
                        </Typography>
                      </div>
                      {!!rowData.departed_date && (
                        <div onClick={(e) => onModifyDuration(e, rowData)} className={styles.text}>
                          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                            Modify Duration
                          </Typography>
                        </div>
                      )}
                      {rowData?.equipment?.equipment_type_id >= 12 && !rowData?.departed_date && (
                        <div
                          onClick={(e) => {
                            setCheckoutData(rowData);
                            e.stopPropagation();
                          }}
                          className={styles.text}
                        >
                          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
                            Checkout Trailer
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                </Overlay>
              </div>
            </div>
          );
        },
      },
    ];
  }, [detention?.data, showTool, theme, elementsRef]);

  const getDetentionData = async (filters) => {
    setLoading(true);
    const { page, page_size, tab, query, ...rest } = filters || {};

    try {
      const params = {
        page,
        page_size,
        shipment_id: id,
        ...(!!query && { query }),
        ...rest,
      };

      const detention = await GetShipmentDetention(params);
      setDetention(detention);
      setFilter((prevState) => {
        const filterTableTop = [...prevState.filterTableTop];
        filterTableTop[1].count = detention?.total;
        return {
          ...prevState,
          filterTableTop,
        };
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, page_size: rowPage });
  };

  const onPageChange = (event, page) => {
    event.onChangePage(event, page - 1);
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const PaginationComponent = (event) => {
    return (
      <DetentionFooter
        setLoading={(v) => setLoading(v)}
        myTableRef={myTableRef}
        rowPerPage={selectedFilters?.page_size}
        totalCount={detention?.total}
        totalLength={detention?.data?.length}
        lastPage={detention?.last_page}
        currentPage={detention?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setDetention={(data) => setDetention(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  function sortingQuery(field, sortBy, nested_field) {
    let sortField;
    if (nested_field) {
      sortField = `sort[${nested_field}][${field}]`;
    } else {
      sortField = `sort[][${field}]`;
    }
    if (field === 'location_name') {
      sortField = `sort[stop_point][${field}]`;
    }

    setSelectedFilters({ ...selectedFilters, [sortField]: sortBy });
  }

  useEffect(() => {
    const refs = {};
    detention?.data?.forEach((item) => {
      refs[item.id] = createRef();
    });
    elementsRef.current = refs;
  }, [detention]);

  useEffect(() => {
    if (selectedFilters.query !== debouncedSearch) {
      setSelectedFilters({ ...selectedFilters, query: debouncedSearch, page: 1 });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    getDetentionData(selectedFilters).then();
  }, [selectedFilters]);

  return (
    <div className={styles.detention_wrapper}>
      <TableContext.Provider
        value={{
          filter,
          dragItem,
          updateFilter,
          tableColumn,
          onChangeOrder: (items) => setDragItem(items),
          onsStTableColumn: (v) => setTableColumn(v),
        }}
      >
        <DetentionHeader />
      </TableContext.Provider>

      {loading ? (
        <Preloader />
      ) : (
        <MaterialTableWrapper
          data={detention?.data}
          rowPerPage={selectedFilters?.page_size}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          components={{
            Pagination: PaginationComponent,
          }}
          columns={columns}
          onRowClick={(e, row) => onBillDetention(e, row)}
        />
      )}
      {billModal?.visible && (
        <BillDetention
          open={billModal.visible}
          onClose={() => setBillModal({ visible: false })}
          data={billModal}
          onSuccess={() => getDetentionData(selectedFilters)}
        />
      )}
      {!!checkoutData && (
        <CheckoutTrailer
          open={!!checkoutData}
          onClose={() => setCheckoutData(null)}
          data={checkoutData}
          onSuccess={() => getDetentionData(selectedFilters)}
        />
      )}
      {!!dateToModify && (
        <ModifyDuration
          open={!!dateToModify}
          onClose={() => setDateToModify(null)}
          data={dateToModify}
          onSuccess={() => getDetentionData(selectedFilters)}
        />
      )}
      <MessagePopup
        title='Warning'
        message={
          <div>
            <Typography variant='s2'>You must checkout vehicle prior to billing detention!</Typography>
            <span
              className={styles.underline_text}
              onClick={() => navigateToInventory(detentionData?.shipment_stop?.stop_point_id)}
            >
              View Stop Point - Inventory
            </span>
          </div>
        }
        open={warningOpen}
        onClose={() => {
          setDetentionData(null);
          setWarningOpen(false);
        }}
      />
    </div>
  );
};

export default Detention;
