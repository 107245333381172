import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { Typography } from 'components/Typography';
import DriverInfo from './DriverInfo';
import DriversType from './DriversType';
import ChartSection from './ChartSection';
import SettlementsInfo from './SettlementsInfo';
import { SWrapper } from './TopSection.styles';
import { SLoadingWrapper } from '../../PayrollAndSettlements.styles';

const TopSection = ({
  topCompany,
  loading,
  checkedDriverTypes,
  onDriverTypeChange,
  getTopCompanyData,
  hideChartSection,
}) => {
  const [open, setOpen] = useState(false);

  const onDriverTypeClose = () => {
    getTopCompanyData();
    setOpen(false);
  };

  if (!topCompany || loading) {
    return (
      <SLoadingWrapper>
        <CircularProgress size={26} />
      </SLoadingWrapper>
    );
  }

  return (
    <SWrapper $hideChartSection={hideChartSection}>
      <div className='sub-section sub-section-one'>
        {!topCompany?.top_drivers?.current_month && !topCompany?.top_drivers?.current_month_second && (
          <Typography variant='s2' className='empty-data'>
            No stats to show for this month, please complete shipments first.
          </Typography>
        )}
        {topCompany.top_drivers?.current_month && (
          <DriverInfo isCurrent companyName={topCompany.company_name} data={topCompany.top_drivers.current_month} />
        )}
        {topCompany.top_drivers?.current_month_second && (
          <DriverInfo
            isCurrent
            companyName={topCompany.company_name}
            data={topCompany.top_drivers.current_month_second}
            top={2}
          />
        )}
        <GearIcon className='settings-icon' onClick={() => setOpen(true)} />
      </div>
      {!hideChartSection && <ChartSection topCompany={topCompany} />}
      <SettlementsInfo data={topCompany.amounts_by_statuses} />
      <DriversType
        open={open}
        onClose={onDriverTypeClose}
        checkedValues={checkedDriverTypes}
        onDriverTypeChange={onDriverTypeChange}
      />
    </SWrapper>
  );
};

export default TopSection;
