import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import truckinLogo from 'assets/icons/logo/truckinLogo.png';
import CustomButton from 'components/CustomButton/CustomButton';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateModuleData, updateOnboarding } from 'store/reducers/stripe.reducer';
import { getAllModules, checkOnboarding, activateModule, checkModuleStatus } from 'Api/Stripe';
import { ACTIVATION_STATUS, moduleMapper } from './StripeConnection.data';
import SetupCard from './SetupCard';

const StripeConnection = ({ module }) => {
  const showToaster = useShowToaster();
  const dispatch = useDispatch();
  const [openSetupCashCard, setOpenSetupCashCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingActivate, setLoadingActivate] = useState(false);
  const [moduleData, setModuleData] = useState(null);
  const [onboardingDetails, setOnboardingDetails] = useState(null);
  const [refreshIndex, setRefreshIndex] = useState(null);

  const activateStripeModule = async () => {
    try {
      setLoadingActivate(true);
      await activateModule({ module_name: module });
      setRefreshIndex(Date.now());
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingActivate(false);
    }
  };

  const checkOnboardingAndModule = async () => {
    try {
      setLoading(true);
      const [{ data: onboarding }, { data: moduleData }] = await Promise.all([
        checkOnboarding(),
        checkModuleStatus({ module_name: module }),
      ]);
      setOnboardingDetails(onboarding);
      setModuleData(moduleData || null);
      dispatch(updateOnboarding(onboarding));
      dispatch(updateModuleData({ key: module, data: moduleData }));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getModules = async () => {
    try {
      const { data } = await getAllModules();
      const currentModule = data.find((i) => i.module_name === module);
      setModuleData(currentModule);
    } catch (e) {
      // Do nothing
    }
  };

  const handleClick = () => {
    if (!onboardingDetails?.details_submitted) {
      setOpenSetupCashCard(true);
      return;
    }

    activateStripeModule();
  };

  useEffect(() => {
    checkOnboardingAndModule();
    getModules();
  }, [refreshIndex]);

  return (
    <div>
      {!loading &&
        (!onboardingDetails?.details_submitted ||
          (!!moduleData && ![ACTIVATION_STATUS.ACTIVE, ACTIVATION_STATUS.PENDING].includes(moduleData.status))) && (
          <CustomButton
            type='secondary'
            title={moduleMapper[module].buttonTitle}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            leftIcon={
              <img src={truckinLogo} alt='Profile logo' width={18} height={18} style={{ marginRight: '4px' }} />
            }
            styleButton={{ padding: '6px 12px', margin: 0 }}
            onClick={handleClick}
            disabled={loadingActivate}
          />
        )}
      {openSetupCashCard && (
        <SetupCard open={openSetupCashCard} onClose={() => setOpenSetupCashCard(false)} module={module} />
      )}

      {/* {openSetupCashCard && module !== STRIPE_MODULE.ACCOUNTS_PAYABLE && ( */}
      {/*  <SetupCard open={openSetupCashCard} onClose={() => setOpenSetupCashCard(false)} module={module} /> */}
      {/* )} */}
      {/* {openSetupCashCard && module === STRIPE_MODULE.ACCOUNTS_PAYABLE && ( */}
      {/*  <EnableBillPayModal module={module} onClose={() => setOpenSetupCashCard(false)} /> */}
      {/* )} */}
    </div>
  );
};

export default StripeConnection;
