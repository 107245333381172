import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import { InputWithIcon } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { depositFunds, getPaymentSource, getStripeFees } from 'Api/CardManagement';
import { validationSchema } from './validationSchema';
import { initialValues } from './Deposit.data';

const Deposit = ({ open, onClose }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = {
        origin_payment_method: values.deposit_source.stripe_bank_account_id,
        deposit_type: values.deposit_type,
        amount: Number(values.amount),
        currency: 'usd',
        description: 'Deposit',
        statement_descriptor: 'Truckin',
      };
      const response = await depositFunds(body);

      if (response?.error) {
        showToaster({ type: 'error', message: response.error });
        return;
      }

      showToaster({ type: 'success', message: 'Success!' });
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getFees = async () => {
    try {
      const { data } = await getStripeFees();
      handleChange('fee', data.total_fees_for_ach);
    } catch (e) {
      // Do nothing
    }
  };

  const getSource = async () => {
    try {
      const { data } = await getPaymentSource();
      setSource([data]);
      handleChange('deposit_source', data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getFees();
    getSource();
  }, []);

  useEffect(() => {
    if (values.amount) {
      const fee = (Number(values.amount) * Number(values.fee || 0)) / 100;
      const total = Number(values.amount) + Number(fee || 0);
      handleChange('total', total);
    } else {
      handleChange('total', '');
    }
  }, [values.amount, values.fee, values.deposit_type]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Deposit to Truckin Digital'
      $bgColor={palette.gray0}
      $maxWidth='520px'
      $minWidth='520px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Deposit',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='d-flex flex-column gap-4 mb-5 mt-2'>
        <div className='mb-3'>
          <div className='mb-2'>
            <Autocomplete
              required
              label='Deposit Source'
              name='deposit_source'
              value={values.deposit_source}
              options={source}
              getOptionLabel={(option) => `${option.bank_name} **** ${option.account_number}`}
              onChange={(e, val) => handleChange('deposit_source', val)}
              error={touchedErrors.deposit_source}
            />
          </div>
          {/* <div className='d-flex flex-column gap-2 mt-3'> */}
          {/*  <div className='w-100 d-flex justify-content-between'> */}
          {/*    <Radio */}
          {/*      label='ACH (Same day, cut off 11am CST)' */}
          {/*      name='deposit_type' */}
          {/*      id='type-1' */}
          {/*      value={1} */}
          {/*      checked={values.deposit_type === 1} */}
          {/*      onChange={() => setValues({ ...values, deposit_type: 1, fee: 0 })} */}
          {/*      gap='8px' */}
          {/*      labelColor={palette.gray700} */}
          {/*    /> */}
          {/*    <Chip label='FREE' bgColor={palette.indigo0} labelColor={palette.indigo500} /> */}
          {/*  </div> */}
          {/*  <div className='w-100 d-flex justify-content-between'> */}
          {/*    <Radio */}
          {/*      label='ACH (1 to 2 business days, cut off 6pm CST)' */}
          {/*      name='deposit_type' */}
          {/*      id='type-2' */}
          {/*      value={2} */}
          {/*      checked={values.deposit_type === 2} */}
          {/*      onChange={() => setValues({ ...values, deposit_type: 2, fee: 0 })} */}
          {/*      gap='8px' */}
          {/*      labelColor={palette.gray700} */}
          {/*    /> */}
          {/*    <Chip label='FREE' bgColor={palette.indigo0} labelColor={palette.indigo500} /> */}
          {/*  </div> */}
          {/*  <div className='w-100 d-flex justify-content-between'> */}
          {/*    <Radio */}
          {/*      label='Wire (Same day, cut off 3pm CST)' */}
          {/*      name='deposit_type' */}
          {/*      id='type-3' */}
          {/*      value={3} */}
          {/*      checked={values.deposit_type === 3} */}
          {/*      onChange={() => setValues({ ...values, deposit_type: 3, fee: 15 })} */}
          {/*      gap='8px' */}
          {/*      labelColor={palette.gray700} */}
          {/*    /> */}
          {/*    <Chip label='$15.00' bgColor={palette.indigo0} labelColor={palette.indigo500} /> */}
          {/*  </div> */}
          {/*  <ErrorMessage error={touchedErrors.deposit_type} /> */}
          {/* </div> */}
        </div>
        <div className='d-flex align-items-center gap-3'>
          <InputWithIcon
            type='number'
            label='Deposit Amount'
            placeholder='0.00'
            name='amount'
            value={values.amount}
            onChange={handleChange}
            onKeyDown={blockNonPositiveNumbers}
            error={touchedErrors.amount}
          />
          <InputWithIcon
            icon='%'
            type='number'
            label='Fees'
            placeholder='0.00'
            value={values.fee}
            onChange={handleChange}
            onKeyDown={blockNonPositiveNumbers}
            disabled
          />
          <InputWithIcon
            type='number'
            label='Total Deposit'
            placeholder='0.00'
            value={values.total}
            onChange={handleChange}
            onKeyDown={blockNonPositiveNumbers}
            disabled
          />
        </div>
      </div>
    </Modal>
  );
};

export default Deposit;
