import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import Chip from 'common/Chip';
import InputLabel from 'common/InputLabel';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import Input, { InputWithIcon } from 'common/Input';
import { ACCOUNT_TYPE, formatNumber, palette } from 'utils/constants';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { GetChargeTypes } from 'Api/CompanySettings';
import ItemsSettings from 'pages/Accounting/Payables/components/ItemsSettings';
import { SFormRow, SAddMore, SFormWrapper, STotalSection, SOption } from '../AddBill.styles';

const ItemsForm = ({ values, handleChange, setValues, touchedErrors, readOnly }) => {
  const { currency } = useSelector((state) => state.root);
  const [items, setItems] = useState([]);
  const [openItemsSettings, setOpenItemsSettings] = useState(false);

  const total = useMemo(() => {
    return values.items.reduce((acc, cur) => {
      if (cur.quantity && cur.price) {
        acc += Number(cur.quantity) * Number(cur.price);
      }
      return acc;
    }, 0);
  }, [values]);

  const onAddMore = () => {
    handleChange(`items[${values.items.length}]`, {
      id: Date.now(),
      item: null,
      desc: '',
      quantity: '',
      price: '',
    });
  };

  const onDeleteRow = (index) => {
    handleChange(
      'items',
      values.items.filter((item, i) => i !== index)
    );
  };

  const getItems = async () => {
    try {
      const { data } = await GetChargeTypes({ 'sort[][bill_charges]': 'asc', 'type_id[]': 2 });
      setItems(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onItemsSettingsClose = () => {
    getItems();
    setOpenItemsSettings(false);
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <div>
      <div className='mb-2'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Items
        </Typography>
      </div>
      <SFormWrapper>
        {values.items.map((item, index) => (
          <SFormRow key={item.id}>
            <div>
              {!index && (
                <div className='d-flex align-items-center justify-content-between'>
                  <InputLabel required>Item</InputLabel>
                  <GearIcon className='pointer' onClick={() => setOpenItemsSettings(true)} />
                </div>
              )}
              <Autocomplete
                width='250px'
                required
                labelKey='title'
                name={`items[${index}].item`}
                options={items}
                value={item.item}
                onChange={(e, val) => {
                  setValues((prevState) => ({
                    ...prevState,
                    items: prevState.items.map((item, i) =>
                      i !== index ? item : { ...item, item: val, desc: val.desc || '' }
                    ),
                  }));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={touchedErrors.items?.[index]?.item}
                disabled={readOnly}
                renderOption={(props, option) => (
                  <SOption {...props}>
                    <Typography>{option.title}</Typography>
                    <Chip label={option.code} />
                  </SOption>
                )}
              />
            </div>
            <Input
              className='w-100'
              required
              label={!index ? 'Description' : null}
              name={`items[${index}].desc`}
              value={item.desc}
              onChange={handleChange}
              error={touchedErrors.items?.[index]?.desc}
              disabled={readOnly}
            />
            <Input
              width='200px'
              required
              label={!index ? 'Account' : null}
              value={item.item?.account?.account_name}
              disabled
            />
            <Input
              width='80px'
              required
              type='number'
              placeholder='0'
              label={!index ? 'Qty' : null}
              name={`items[${index}].quantity`}
              value={item.quantity}
              onChange={handleChange}
              error={touchedErrors.items?.[index]?.quantity}
              disabled={readOnly}
              onKeyDown={(e) => blockNonPositiveNumbers(e)}
            />
            <InputWithIcon
              width='150px'
              required
              type='number'
              placeholder='0.00'
              label={!index ? 'Unit Price' : null}
              name={`items[${index}].price`}
              value={item.price}
              onChange={(e) => {
                if (item.item?.account?.account_type_id === ACCOUNT_TYPE.INCOME) {
                  handleChange(
                    `items[${index}].price`,
                    Number(e.target.value) > 0 ? `-${e.target.value}` : e.target.value
                  );
                  return;
                }

                handleChange(`items[${index}].price`, e.target.value);
              }}
              error={touchedErrors.items?.[index]?.price}
              disabled={readOnly}
            />
            <InputWithIcon
              width='150px'
              required
              type='number'
              placeholder='0.00'
              label={!index ? 'Amount' : null}
              name={`items[${index}].amount`}
              value={`${
                item.quantity * item.price > 0 && item.item?.account?.account_type_id === ACCOUNT_TYPE.INCOME
                  ? -(item.quantity * item.price)
                  : item.quantity * item.price
              }`}
              onChange={handleChange}
              onKeyDown={blockNonPositiveNumbers}
              disabled
            />
            {!readOnly && (
              <div className='icon-wrapper'>
                {index !== 0 ? (
                  <span className='delete-icon' onClick={() => onDeleteRow(index)}>
                    <DeleteIcon fill={palette.indigo500} />
                  </span>
                ) : (
                  <div className='empty-icon' />
                )}
              </div>
            )}
          </SFormRow>
        ))}
      </SFormWrapper>
      <SAddMore onClick={onAddMore}>
        <PlusIcon />
        <Typography variant='s2' style={{ color: palette.indigo500 }}>
          Add Another...
        </Typography>
      </SAddMore>
      <STotalSection>
        <div className='total-row'>
          <Typography variant='s2' style={{ color: palette.gray700 }}>
            Subtotal
          </Typography>
          <Typography variant='s2'>
            {currency}
            {formatNumber(total)}
          </Typography>
        </div>
        <div className='total-row'>
          <Typography variant='s1'>Total</Typography>
          <Typography variant='h5' style={{ color: palette.gray900 }}>
            {currency}
            {formatNumber(total)}
          </Typography>
        </div>
      </STotalSection>
      {openItemsSettings && <ItemsSettings open={openItemsSettings} onClose={onItemsSettingsClose} />}
    </div>
  );
};

export default ItemsForm;
