export const getOrdinal = (number) => {
  const num = Math.abs(Math.floor(number));

  const lastTwoDigits = num % 100;
  const suffix =
    lastTwoDigits >= 11 && lastTwoDigits <= 13
      ? 'th'
      : lastTwoDigits % 10 === 1
      ? 'st'
      : lastTwoDigits % 10 === 2
      ? 'nd'
      : lastTwoDigits % 10 === 3
      ? 'rd'
      : 'th';

  return `${num}${suffix}`;
};

export const getRowId = () => {
  const randomDecimal = Math.random();
  return Math.floor(randomDecimal * 1000000) + 1;
};
