import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import classes from './UpdateDockStatus.module.scss';
import useForm from '../../../hooks/useForm';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';

const UpdateDockStatus = ({ isOpen, setIsOpen, onAddBtn, data }) => {
  const { use } = useTheme();
  const subTitle = `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name} `;

  const styles = useMemo(() => {
    return {
      styleBody: {
        maxHeight: 600,
        overflowY: 'auto',
        padding: '20px',
      },
      modal: {
        width: 440,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        borderRadius: '8px',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
      },
      modalBody: {
        padding: 0,
        borderRadius: 6,
        backgroundColor: use(palette.white, palette.dark800),
      },
      labelStyle: {
        margin: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      inputDriver: {
        height: 32,
        width: '100%',
        borderRadius: '0 4px 4px 0',
      },
      selectStyle: {
        height: 32,
        width: 221,
        borderRadius: '4px 0 0 4px',
        marginLeft: 36,
      },
      dropDownStyles: {
        flexGrow: 0,
        width: 221,
      },
      menuStyle: {
        width: 221,
        marginLeft: 36,
      },
      datePicker: {
        minWidth: 221,
        margin: 0,
        justifyContent: 'flex-start',
      },
    };
  }, [use, palette]);

  const onSubmit = async (values) => {
    onAddBtn(null, null, null, values);
  };

  const { values, handleSubmit, handleChange } = useForm({
    initialValues: {
      waitingForDock: data.reported_dock_status === 1,
      atTheDock: data.reported_dock_status === 2,
    },
    onSubmit,
  });

  return (
    <Modal
      size='lg'
      centered
      autoFocus={false}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      enforceFocus={false}
      style={styles.modal}
      backdrop='static'
      dialogClassName='dialogClassName'
    >
      <Modal.Body style={styles.modalBody}>
        <div className={classes.modalHeader} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <Typography variant='button2'>Update Dock Status</Typography>
          <Typography variant='overLine'>{subTitle}</Typography>
        </div>

        <div
          className={classes.modalBody}
          style={{
            backgroundColor: use(palette.gray0, palette.dark800),
            borderColor: use(palette.gray50, palette.gray700),
          }}
        >
          <div className='d-flex gap-2 align-items-center'>
            <CustomCheckbox
              type='switch'
              checked={values.waitingForDock}
              name='waitingForDock'
              onChange={(checked) => {
                if (checked) {
                  handleChange('atTheDock', false);
                } else if (!values.atTheDock) {
                  return;
                }
                handleChange('waitingForDock', checked);
              }}
            />
            <Typography variant='b3'>Waiting for dock</Typography>
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <CustomCheckbox
              type='switch'
              checked={values.atTheDock}
              name='atTheDock'
              onChange={(checked) => {
                if (checked) {
                  handleChange('waitingForDock', false);
                } else if (!values.waitingForDock) {
                  return;
                }
                handleChange('atTheDock', checked);
              }}
            />
            <Typography variant='b3'>At the dock</Typography>
          </div>
        </div>

        {/* // footer */}
        <div className={classes.modalFooter} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
          <div className={classes.modalFooterLane} style={{ backgroundColor: use(palette.white, palette.dark900) }}>
            <CustomButton
              type='secondary'
              title='Cancel'
              onClick={() => {
                setIsOpen(false);
              }}
              styleButton={{ padding: '2px 8px', marginTop: 0 }}
            />
            <CustomButton
              type='primary'
              title='Update'
              onClick={handleSubmit}
              styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDockStatus;
