import React from 'react';
import { useSelector } from 'react-redux';
import Chip from 'common/Chip';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { millisecondsToInterval } from 'utils/helpers';
import { formatNumber, palette } from 'utils/constants';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import RowActions from './components/RowActions';

export const quoteStatus = {
  0: { label: 'DRAFT', color: palette.gray500, bgColor: palette.gray0 },
  1: { label: 'UNSENT', color: palette.orange500, bgColor: palette.orange0 },
  2: { label: 'SENT', color: palette.green400, bgColor: palette.green0 },
  3: { label: 'ACCEPTED', color: palette.green600, bgColor: palette.green50 },
  4: { label: 'DECLINED', color: palette.red500, bgColor: palette.red0 },
  5: { label: 'EXPIRED', color: palette.red500, bgColor: palette.red0 },
  6: { label: 'CANCELED', color: palette.gray500, bgColor: palette.gray0 },
};

export const initialFilters = {
  page: 1,
  itemsPerPage: 25,
  tab: 0,
  sort: { field: '', sortBy: 'desc' },
};

export const useColumns = ({
  selectedRows,
  handleSelectRow,
  onEdit,
  onPreviewQuote,
  onSendQuote,
  onShareLink,
  onAcceptQuote,
  onCancelQuote,
  onViewLogs,
}) => {
  const { currency } = useSelector((state) => state.root);
  const { formatDate, convertToCustomerTime } = useDateFormat();

  return [
    {
      field: '',
      render: (row) =>
        row.status_id === 1 && (
          <span onClick={(e) => e.stopPropagation()}>
            <CustomCheckbox
              onChange={(checked) => handleSelectRow(checked, row)}
              checked={selectedRows?.some((i) => i.id === row.id)}
            />
          </span>
        ),
    },
    {
      field: 'customer',
      title: <ColumnHeader title='CUSTOMER' field='customer' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.billing?.customer?.company_name}
        </Typography>
      ),
    },
    {
      field: 'id',
      title: <ColumnHeader title='QUOTE ID' field='id' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.quote_id}
        </Typography>
      ),
    },
    {
      field: 'reference',
      title: <ColumnHeader title='REFERENCE' field='id' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.billing?.reference_id || '-'}
        </Typography>
      ),
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='CREATED DATE' field='created_at' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.created_at ? convertToCustomerTime(row.created_at) : '-'}
        </Typography>
      ),
    },
    {
      field: 'sent_date',
      title: <ColumnHeader title='SENT DATE' field='sent_date' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.send_date ? convertToCustomerTime(row.send_date) : '-'}
        </Typography>
      ),
    },
    {
      field: 'expiry_date',
      title: <ColumnHeader title='EXPIRY DATE' field='expiry_date' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.expiry_date ? formatDate(row.expiry_date) : '-'}
        </Typography>
      ),
    },
    {
      field: 'mileage',
      title: <ColumnHeader title='TOTAL MILEAGE' field='mileage' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.total_distance || '-'}
        </Typography>
      ),
    },
    {
      field: 'duration',
      title: <ColumnHeader title='TOTAL DURATION' field='duration' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {row.total_duration ? millisecondsToInterval(row.total_duration * 60 * 1000) : '-'}
        </Typography>
      ),
    },
    {
      field: 'start_end_date',
      title: <ColumnHeader title='START/END DATE' field='start_end_date' />,
      render: (row) =>
        row.start_date && row.end_date ? (
          <div className='d-flex align-items-center gap-2'>
            <Chip label={formatDate(row.start_date)} />
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              -
            </Typography>
            <Chip label={formatDate(row.end_date)} />
          </div>
        ) : (
          <Typography variant='s2' style={{ color: palette.gray900 }}>
            -
          </Typography>
        ),
    },
    {
      field: 'status',
      title: <ColumnHeader title='STATUS' field='status' />,
      render: (row) => (
        <Chip
          label={quoteStatus[row.status_id]?.label}
          labelColor={quoteStatus[row.status_id]?.color}
          bgColor={quoteStatus[row.status_id]?.bgColor}
          fontSize='11px'
          fontWeight={500}
        />
      ),
    },
    {
      field: 'grand_total',
      title: <ColumnHeader title='GRAND TOTAL' field='grand_total' />,
      render: (row) => (
        <Typography variant='s2' style={{ color: palette.gray900 }}>
          {currency}
          {formatNumber(row.billing?.total_amount)}
        </Typography>
      ),
    },
    {
      field: 'created_by',
      title: <ColumnHeader title='CREATED BY' field='created_by' />,
      render: (row) => (
        <UserInfo
          size='32px'
          statusSize='7px'
          data={{
            ...(row.created_by || {}),
            image: row.created_by?.profile_logo,
            name: `${row.created_by?.first_name} ${row.created_by?.last_name}`,
            user_type: 'staff',
          }}
        />
      ),
    },
    {
      field: 'actions',
      render: (row) =>
        row.status_id !== 6 && (
          <RowActions
            row={row}
            onEdit={() => onEdit(row)}
            onPreviewQuote={() => onPreviewQuote(row)}
            onViewLogs={() => onViewLogs(row)}
            onSendQuote={() => onSendQuote(row)}
            onShareLink={() => onShareLink(row)}
            onAcceptQuote={() => onAcceptQuote(row)}
            onCancelQuote={() => onCancelQuote(row)}
          />
        ),
    },
  ];
};
