import styled from 'styled-components';
import Autocomplete from 'common/Autocomplete';

export const SAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    row-gap: 2px;
  }

  .MuiButtonBase-root.MuiChip-root {
    margin: 0 3px;
  }

  .MuiAutocomplete-tagSizeMedium {
    margin: 0 3px;
    font-size: 14px;
    line-height: 14px;
  }
`;
