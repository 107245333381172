import styled from 'styled-components';
import Input from '../../../../../common/Input';
import { palette } from '../../../../../utils/constants';
import PhoneInput from '../../../../../common/PhoneInput';

export const STable = styled.table`
  width: 100%;
  margin-top: 12px;

  td {
    padding: 16px 20px;
    border: 1px solid ${palette.gray50};
    min-width: 200px;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }
`;

export const SLabel = styled.p`
  color: ${palette.gray400};
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0 0 4px 0;
`;

export const SText = styled.p`
  color: ${palette.gray700};
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0;

  .dots {
    font-size: 9px;
  }
`;

export const SAction = styled.p`
  color: ${palette.indigo500};
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 20px;
  text-align: left;
  margin: 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const SFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
`;

export const SHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .flex-left {
    display: inline-flex;
    align-items: center;
    column-gap: 12px;
  }

  button.edit-button span {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .MuiChip-label {
    font-weight: 400 !important;
    font-size: 13px !important;
  }
`;

export const SInput = styled(Input)`
  input {
    padding: 2px 12px;
  }
`;

export const SPhoneInput = styled(PhoneInput)`
  &.react-tel-input input.form-control {
    padding: 1px 12px 1px 48px;
  }
`;
