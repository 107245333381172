import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import CustomButton from 'components/CustomButton/CustomButton';
import AddUpdatePrevEmployment from 'componentsV2/PreviousEmployment/components/AddUpdatePrevEmployment';
import { addEmploymentDataConverter } from 'componentsV2/PreviousEmployment/components/AddUpdateModal/AddUpdateModal.data';
import { saveFinishLater } from 'Api/StaffV2';
import { createPrevEmployment, getPrevEmployment, updatePrevEmployment } from 'Api/PreviousEmployment';

const StaffPreviousEmployment = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  stepsDone,
  setStepsDone,
  staffId,
  previousEmployment,
  draftDriver,
}) => {
  const [onNextLoading, setOnNextLoading] = useState(false);

  const onNextStep = async (values) => {
    try {
      setOnNextLoading(true);
      const promises = values.map((employer) => {
        const formData = addEmploymentDataConverter(employer, 'staff', staffId);
        if (employer.id) {
          return updatePrevEmployment(formData);
        }
        return createPrevEmployment(formData);
      });
      await Promise.all(promises);

      const { data } = await getPrevEmployment({
        id: staffId,
        userType: 'staff',
        params: {
          page: 1,
          itemsPerPage: 10000,
        },
      });

      nextStep(data, null, `Previous Employment has been ${stepsDone ? 'updated' : 'created'}`);
    } catch (e) {
      showError(getErrorMessage(e));
    } finally {
      setOnNextLoading(false);
    }
  };

  const onPrevStep = () => {
    setStepsDone({
      ...stepsDone,
      isRestrictShift: true,
    });
    prevStep();
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 4,
    };

    saveFinishLater(staffId, body).then(() => {
      Cancel();
    });
  };

  return (
    <>
      <Modal.Body style={{ backgroundColor: palette.white }} className='add-driver-modal-body driver-style-wrap'>
        <AddUpdatePrevEmployment
          previousEmployments={previousEmployment}
          onSave={onNextStep}
          userType='staff'
          userId={staffId}
        />
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: palette.white,
          borderColor: palette.gray50,
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button onClick={nextStep} className='cancel-button skip-btn-staff'>
              Skip
            </Button>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            {onNextLoading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <CustomButton
                title='Next Step'
                type='primary'
                disabled={onNextLoading}
                buttonProps={{ type: 'submit', form: 'previous-employment-form', onClick: undefined }}
                styleTitle={{ fontSize: 12, fontWeight: 500 }}
                styleButton={{ padding: '6px 12px', margin: '0 16px 0 0' }}
              />
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default StaffPreviousEmployment;
