import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({
  shipment,
  onViewNotes,
  onExtendExpiration,
  onRevertAll,
  onRevertAndResend,
  onCancelShipment,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'view':
        navigate(`/shipment/${shipment.shipment_id}`);
        break;
      case 'viewNotes':
        onViewNotes();
        break;
      case 'extendExpiration':
        onExtendExpiration();
        break;
      case 'revertAndResend':
        onRevertAndResend();
        break;
      case 'revertAll':
        onRevertAll();
        break;
      case 'cancelShipment':
        onCancelShipment();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <li onClick={(event) => onActionClick(event, 'view')}>View Shipment</li>
      {onViewNotes && <li onClick={(event) => onActionClick(event, 'viewNotes')}>Notes</li>}
      {onExtendExpiration && <li onClick={(event) => onActionClick(event, 'extendExpiration')}>Extend Expiration</li>}
      {onRevertAndResend && <li onClick={(event) => onActionClick(event, 'revertAndResend')}>Revert & Resend</li>}
      {onRevertAll && (
        <li className='delete-action' onClick={(event) => onActionClick(event, 'revertAll')}>
          Revert All Offers
        </li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
