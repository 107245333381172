import { useParams } from 'react-router-dom';
import { detailsPageMapper } from 'pages/Reports/Reports.data';

const ReportDetails = () => {
  const { id } = useParams();

  return detailsPageMapper[Number(id)];
};

export default ReportDetails;
