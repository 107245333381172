import React from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SWrapper } from './NoRecords.styles';

const NoRecords = ({ color = palette.gray700, className, text }) => {
  return (
    <SWrapper className={className}>
      <Typography variant='s2' style={{ color }}>
        {text || 'No records to display'}
      </Typography>
    </SWrapper>
  );
};

export default NoRecords;
