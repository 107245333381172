import React, { useMemo, useRef, useState, useEffect, createRef } from 'react';
import { Overlay } from 'react-bootstrap';
import classNames from 'classnames';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { DeleteCompanyResources, GetCompanyResources } from 'Api/CompanySettings';
import CompanyResourceViewModal from '../CompanyResourceViewModal/CompanyResourceViewModal';
import styles from './CompanyResource.module.css';
import CompanyResourceWatchModal from '../CompanyResourcesWatchModal/CompanyResourcesWatchModal';
import AddMaterial from './components/AddMaterial';

const CompanyResource = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use, theme } = useTheme();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [openViewModal, setOpenViewModal] = useState({
    visible: false,
  });
  const [openWatchModal, setOpenWatchModal] = useState({
    visible: false,
  });
  const [show, setShow] = useState({});
  const elementsRef = useRef();
  const [resourcesList, setResourcesList] = useState(null);

  const columns = useMemo(() => {
    return [
      {
        field: 'title',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            TITLE
          </Typography>
        ),
        render: (rowData) => {
          const title = rowData?.title;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: use(palette.dark800, palette.gray200) }}>
                {title}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'category',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            CATEGORY
          </Typography>
        ),
        render: (rowData) => {
          const category = rowData?.category?.name;
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {category}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'description',
        width: '100%',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DESCRIPTION
          </Typography>
        ),
        render: (rowData) => {
          const description = rowData?.description;
          return (
            <div style={{ display: 'flex', whiteSpace: 'normal' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {description}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'visible_to',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            VISIBLE TO
          </Typography>
        ),
        render: (rowData) => {
          const visible_to = rowData?.visible_to?.map((item) => item.department_name);
          const visibleToDepartments = visible_to?.join(', ');
          return (
            <div style={{ display: 'flex', whiteSpace: 'normal', minWidth: '200px' }}>
              <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray200) }}>
                {visibleToDepartments}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'video',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            VIDEO
          </Typography>
        ),
        render: (rowData) => {
          if (!rowData.video) {
            return '-';
          }

          return (
            <div style={{ display: 'flex' }}>
              <Typography
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenWatchModal({
                    visible: true,
                    ...rowData,
                  });
                }}
                className={styles.watch}
                variant='b2'
                style={{ color: use(palette.indigo500, palette.gray200) }}
              >
                Watch
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'document',
        title: (
          <Typography variant='overLine' style={{ color: use(palette.gray700, palette.gray200) }}>
            DOCUMENT
          </Typography>
        ),
        render: (rowData) => {
          if (!rowData?.document) {
            return '-';
          }

          return (
            <div style={{ display: 'flex' }}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenViewModal({
                    visible: true,
                    ...rowData,
                  });
                }}
                style={{ display: 'flex' }}
              >
                <Typography
                  variant='b2'
                  className={styles.view}
                  style={{ color: use(palette.indigo500, palette.gray200) }}
                >
                  View
                </Typography>
              </div>
            </div>
          );
        },
      },
      {
        field: 'lastRow',
        render: (rowData) => {
          return (
            <div className={[styles.lastRow]}>
              <div onMouseLeave={() => setShow((prev) => ({ ...prev, [rowData.id]: false }))}>
                <div
                  ref={elementsRef?.current?.[rowData.id]}
                  className={classNames([styles.editIconBox, { [styles.show]: show[rowData.id] }])}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShow((prev) => ({ ...prev, [rowData.id]: true }));
                  }}
                >
                  <EditIcon className={styles.editIcon} />
                </div>

                <Overlay show={!!show[rowData.id]} target={elementsRef?.current?.[rowData.id]} placement='left'>
                  <div className={styles.dropDown}>
                    <div className={styles.text}>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          setRowToEdit(rowData);
                        }}
                        variant='s2'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        Edit
                      </Typography>
                    </div>
                    <div className={styles.text}>
                      <Typography
                        onClick={async (e) => {
                          e.stopPropagation();
                          DeleteCompanyResources(rowData?.id).then((res) => {
                            if (res.success) {
                              getCompanyResources();
                            }
                          });
                        }}
                        variant='s2'
                        style={{ color: use(palette.red500) }}
                      >
                        Delete
                      </Typography>
                    </div>
                  </div>
                </Overlay>
              </div>
            </div>
          );
        },
      },
    ];
  }, [resourcesList, elementsRef, show, theme]);

  const getCompanyResources = async () => {
    return GetCompanyResources().then((res) => {
      setResourcesList(res);
    });
  };

  useEffect(() => {
    const refs = {};
    resourcesList?.data.forEach((item) => {
      refs[item.id] = createRef();
    });
    elementsRef.current = refs;
  }, [resourcesList]);

  useEffect(() => {
    getCompanyResources().then();
  }, []);

  return (
    <div className={styles.companyResource_wrapper}>
      <div className={styles.companyResource_header}>
        <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
          Guidance & Manuals
        </Typography>
        <div className={styles.actions}>
          {isConfiguringStep && (
            <>
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ margin: 0 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
                styleButton={{ margin: 0 }}
              />
            </>
          )}
          <CustomButton
            width={135}
            height={12}
            type='secondary'
            title='Add Materials'
            leftIcon={<PlusIcon style={{ marginRight: 10, fill: '#464F60' }} />}
            styleButton={{ margin: 0 }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
            onClick={() => setOpenAddModal(!openAddModal)}
          />
          {isConfiguringStep && (
            <CustomButton
              onClick={() => onSaveAndNext('saveAndNext')}
              type='primary'
              title='Save and Next'
              styleTitle={{ fontSize: 14, fontWeight: 500 }}
              styleButton={{ margin: 0 }}
            />
          )}
        </div>
      </div>
      <MaterialTableWrapper
        data={resourcesList?.data}
        style={{ backgroundColor: use(palette.white, palette.dark800) }}
        columns={columns}
        onRowClick={(e, rowData) => setRowToEdit(rowData)}
      />
      {(openAddModal || !!rowToEdit) && (
        <AddMaterial
          open={openAddModal || !!rowToEdit}
          onClose={() => {
            setOpenAddModal(false);
            setRowToEdit(null);
          }}
          onSuccess={getCompanyResources}
          material={rowToEdit}
        />
      )}
      <CompanyResourceViewModal
        data={openViewModal}
        openViewModal={openViewModal?.visible}
        setOpenViewModal={setOpenViewModal}
      />
      <CompanyResourceWatchModal
        data={openWatchModal}
        openWatchModal={openWatchModal?.visible}
        setOpenWatchModal={setOpenWatchModal}
      />
    </div>
  );
};

export default CompanyResource;
