import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input, { InputWithIcon } from 'common/Input';
import Loader from 'common/Loader';
import InputLabel from 'common/InputLabel';
import { DateTimePicker } from 'common/Pickers';
import Autocomplete from 'common/Autocomplete';
import AddressAutocomplete from 'common/AddressAutocomplete';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars, formatPhoneNumber } from 'utils/helpers';
import { getDrivers } from 'Api/EquipmentProfile';
import { getReportNumberList } from 'Api/DotMonitoring';
import {
  getRoadTypes,
  getCrashTypes,
  getRoadControl,
  createCollision,
  updateCollision,
  getTypeOfWeather,
  getLightCondition,
  getRoadConditions,
  getControlDeviceTypes,
  getAllIncidents,
  createAccidentReport,
  createChecklist,
} from 'Api/AccidentsAndCrashes';
import { getShipmentsList } from 'Api/Planner';
import { getStops } from 'Api/Shipment';
import Radio from 'common/Radio';
import Uploader from 'common/Uploader';
import PhoneInput from 'common/PhoneInput';
import { getGeneralInitialValues } from './General.data';
import ReportNumberAutocomplete from '../ReportNumberAutocomplete';
import { SUnderlineText } from '../../AccidentsAndCrashes.styles';
import { validationSchema } from './validationSchema';

const General = ({
  Cancel,
  nextStep,
  accident,
  id,
  setId,
  loadingData,
  accidentId,
  setSelectedDriver,
  setSelectedVehicle,
  setSelectedTrailer,
  setSelectedShipment,
  setSelectedIncidentId,
  setSelectedCollisionDateTime,
  vehiclesList,
  trailersList,
}) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const { convertToCustomerTime, formatDateTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [roadTypes, setRoadTypes] = useState([]);
  const [roadControls, setRoadControls] = useState([]);
  const [crashTypes, setCrashTypes] = useState([]);
  const [weatherTypes, setWeatherTypes] = useState([]);
  const [roadConditions, setRoadConditions] = useState([]);
  const [lightConditions, setLightConditions] = useState([]);
  const [controlDeviceTypes, setControlDeviceTypes] = useState([]);
  const [stopsData, setStopsData] = useState([]);
  const [reportNumbers, setReportNumbers] = useState([]);
  const [detailView, setDetailView] = useState(false);
  const [onSubmitAction, setOnSubmitAction] = useState('next');
  const [pdfUrl, setPdfUrl] = useState('');

  const onSubmit = async (values) => {
    if (!values.vehicle && !values.trailer && !values.driver) {
      showToaster({
        type: 'error',
        message: 'At least one of required fields (driver, vehicle or trailer) needs to be selected!',
      });
      return;
    }

    try {
      const body = {
        collision_date_time: values.collision_date_time
          ? moment(values.collision_date_time).format('YYYY-MM-DD HH:mm')
          : null,
        driver_id: values.driver?.id || null,
        type: values.type || 'Reportable',
        vehicle_id: values.vehicle?.id || null,
        trailer_id: values.trailer?.id || null,
        incident_id: values.incident?.id || null,
        report_number: values.report_number,
        type_of_crash_id: values.type_of_crash?.id || null,
        type_of_crash_custom: values.type_of_crash_custom || '',
        control_device_type_id: values.control_device_type?.id || null,
        control_device_other: values.control_device_other || undefined,
        shipment_id: values.shipment?.shipment_id || null,
        shipment_stop_id: values.shipment_stop?.id || null,
        speed_limit: values.speed_limit,
        road_type_id: values.road_type?.id || null,
        road_control_id: values.road_control?.id || null,
        injuries: values.injuries || undefined,
        fatalities: values.fatalities || undefined,
        towing: !!values.towing,
        hazmat: !!values.hazmat,
        weather_id: values.weather?.id || null,
        weather_other: values.weather_other || '',
        road_condition: values.road_condition?.id || null,
        road_condition_other: values.road_condition_other || '',
        light_condition_id: values.light_condition?.id || null,
        light_other: values.light_other || '',
        preventable: !!values.preventable,
      };

      const formData = new FormData();
      values.accident_location && formData.append('accident_location', values.accident_location);
      formData.append('police_report', values.police_report);
      values.police_report_attachment && formData.append('police_report_attachment', values.police_report_attachment);
      values.police_report_number && formData.append('police_report_number', values.police_report_number);
      formData.append('citation_issued', values.citation_issued);
      values.citation_issued_violation_count &&
        formData.append('citation_issued_violation_count', values.citation_issued_violation_count);

      const checklistFormData = new FormData();
      checklistFormData.append('driver_family_notified', Number(values.driver_family_notified));
      if (values.driver_family_notified) {
        checklistFormData.append('driver_family_notified_to_first_name', values.driver_family_notified_to_first_name);
        checklistFormData.append('driver_family_notified_to_last_name', values.driver_family_notified_to_last_name);
        checklistFormData.append('driver_family_notified_to_telephone', values.driver_family_notified_to_telephone);
        values.driver_family_notified_datetime &&
          checklistFormData.append(
            'driver_family_notified_datetime',
            moment(values.driver_family_notified_datetime).format('YYYY-MM-DD HH:mm')
          );
      }
      checklistFormData.append('tow_service_notified', Number(values.tow_service_notified));
      if (values.tow_service_notified) {
        checklistFormData.append('tow_service_notified_name', values.tow_service_notified_name);
        checklistFormData.append('tow_service_notified_address', values.tow_service_notified_address);
        checklistFormData.append('tow_service_notified_telephone', values.tow_service_notified_telephone);
        values.tow_service_notified_datetime &&
          checklistFormData.append(
            'tow_service_notified_datetime',
            moment(values.tow_service_notified_datetime).format('YYYY-MM-DD HH:mm')
          );
      }

      setLoading(true);
      if (accident || id) {
        formData.append('safety_accident_id', accident.id || id);

        const { data } = await updateCollision(accident.id || id, body);
        await createAccidentReport(accident?.id || id, formData);
        await createChecklist(accident?.id || id, checklistFormData);
        setId(data.id);
        if (data.driver) {
          setSelectedDriver(data.driver);
        }
        if (data.vehicle) {
          setSelectedVehicle(values.vehicle);
        }
        if (data.trailer) {
          setSelectedTrailer(values.trailer);
        }
        if (data.shipment) {
          setSelectedShipment(data.shipment);
        }
        if (data.incident_id) {
          setSelectedIncidentId(data.incident_id);
        }
        if (data.collision_date_time) {
          setSelectedCollisionDateTime(data.collision_date_time);
        }
      } else {
        const { data } = await createCollision(body);
        await createAccidentReport(data.id, formData);
        await createChecklist(data.id, checklistFormData);
        setId(data.id);
        if (data.driver) {
          setSelectedDriver(data.driver);
        }
        if (data.vehicle) {
          setSelectedVehicle(values.vehicle);
        }
        if (data.trailer) {
          setSelectedTrailer(values.trailer);
        }
        if (data.shipment) {
          setSelectedShipment(data.shipment);
        }
        if (data.incident_id) {
          setSelectedIncidentId(data.incident_id);
        }
        if (data.collision_date_time) {
          setSelectedCollisionDateTime(data.collision_date_time);
        }
      }

      if (onSubmitAction === 'next') {
        nextStep();
        return;
      }

      if (onSubmitAction === 'close') {
        Cancel();
      }
      showToaster({ type: 'success', message: 'Success!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, handleBlur, touchedErrors, setValues } = useForm({
    initialValues: getGeneralInitialValues(convertToCustomerTime()),
    onSubmit,
    validationSchema,
  });

  const getDriversList = async () => {
    try {
      const { data } = await getDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getIncidents = async () => {
    try {
      const { data } = await getAllIncidents({ itemsPerPage: 10000, driver_id: values.driver?.id });
      setIncidents(data || []);
    } catch (e) {
      // do something
    } finally {
      setLoading(false);
    }
  };

  const getReportNumbers = async () => {
    try {
      const { data } = await getReportNumberList();
      setReportNumbers(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCrashTypesList = async () => {
    try {
      const { data } = await getCrashTypes();
      setCrashTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getControlDeviceTypesList = async () => {
    try {
      const { data } = await getControlDeviceTypes();
      setControlDeviceTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRoadTypesList = async () => {
    try {
      const { data } = await getRoadTypes();
      setRoadTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getTypeOfWeatherList = async () => {
    try {
      const { data } = await getTypeOfWeather();
      setWeatherTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getLightConditionList = async () => {
    try {
      const { data } = await getLightCondition();
      setLightConditions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRoadControlList = async () => {
    try {
      const { data } = await getRoadControl();
      setRoadControls(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getShipments = async () => {
    try {
      const params = {
        type: 'dispatched',
      };
      const { data } = await getShipmentsList(params);
      setShipments(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getShipmentStopsData = async (shipmentId) => {
    try {
      const { data } = await getStops({ shipment_id: shipmentId });
      setStopsData(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getRoadConditionList = async () => {
    try {
      const { data } = await getRoadConditions();
      setRoadConditions(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddressSelect = ({ state, city }) => {
    handleChange('accident_location', `${city.name}, ${state.short_name}`);
  };

  const changeAddress = ({ formatted_address }) => {
    handleChange('accident_location', formatted_address);
  };

  const changeTowAddress = ({ address, formatted_address }) => {
    handleChange('tow_service_notified_address', formatted_address || address);
  };

  const onSave = () => {
    setOnSubmitAction('save');
    handleSubmit();
  };

  const onSaveAndClose = () => {
    setOnSubmitAction('close');
    handleSubmit();
  };

  const onNext = () => {
    setOnSubmitAction('next');
    handleSubmit();
  };

  useEffect(() => {
    getShipments();
    getDriversList();
    getReportNumbers();
    getRoadTypesList();
    getCrashTypesList();
    getRoadControlList();
    getTypeOfWeatherList();
    getRoadConditionList();
    getLightConditionList();
    getControlDeviceTypesList();
  }, []);

  useEffect(() => {
    if (values.driver?.id) {
      getIncidents();
    }
  }, [values.driver?.id]);

  useEffect(() => {
    if (accident) {
      const {
        collision_date_time,
        type,
        driver,
        vehicle,
        trailer,
        incident,
        report_number,
        type_of_crash,
        type_of_crash_custom,
        control_device_type,
        control_device_other,
        shipment,
        shipment_stop,
        speed_limit,
        road_type,
        road_control,
        injuries,
        fatalities,
        towing,
        hazmat,
        weather,
        weather_other,
        road_conditions,
        road_condition_other,
        light_condition,
        light_other,
        preventable,

        accident_location,
        police_report,
        police_report_number,
        police_report_attachment,
        citation_issued,
        citation_issued_violation_count,

        driver_family_notified,
        driver_family_notified_to_first_name,
        driver_family_notified_to_telephone,
        driver_family_notified_to_last_name,
        driver_family_notified_datetime,
        tow_service_notified,
        tow_service_notified_name,
        tow_service_notified_address,
        tow_service_notified_telephone,
        tow_service_notified_datetime,
      } = accident || {};

      setDetailView(true);

      setValues({
        collision_date_time: collision_date_time ? moment(collision_date_time).toDate() : new Date(),
        driver: driver || null,
        vehicle: vehicle || null,
        type: type || 'Reportable',
        trailer: trailer || null,
        incident: incident || null,
        report_number: report_number || '',
        type_of_crash: type_of_crash || null,
        type_of_crash_custom: type_of_crash_custom || '',
        control_device_type: control_device_type || null,
        control_device_other: control_device_other || '',
        shipment: shipment || null,
        shipment_stop: shipment_stop || null,
        speed_limit: speed_limit || '',
        road_type: road_type || null,
        road_control: road_control || null,
        injuries: injuries || '',
        fatalities: fatalities || '',
        towing: !!towing,
        hazmat: !!hazmat,
        weather: weather || null,
        weather_other: weather_other || '',
        road_condition: road_conditions || null,
        road_condition_other: road_condition_other || '',
        light_condition: light_condition || null,
        light_other: light_other || '',
        preventable: !!preventable,

        accident_location: accident_location || '',
        police_report: police_report || '',
        police_report_number: police_report_number || '',
        police_report_attachment: police_report_attachment || null,
        citation_issued: citation_issued || '',
        citation_issued_violation_count: citation_issued_violation_count || '',

        driver_family_notified,
        driver_family_notified_to_first_name:
          driver_family_notified_to_first_name || driver?.emergency_contact_first_name,
        driver_family_notified_to_telephone: driver_family_notified_to_telephone || driver?.emergency_contact_number,
        driver_family_notified_to_last_name: driver_family_notified_to_last_name || driver?.emergency_contact_last_name,
        driver_family_notified_datetime,
        tow_service_notified,
        tow_service_notified_name,
        tow_service_notified_address,
        tow_service_notified_telephone,
        tow_service_notified_datetime,
      });

      if (values.shipment?.shipment_id) {
        getShipmentStopsData(values.shipment?.shipment_id);
      }
    }
  }, [accident]);

  const onAddReportNumber = (value) => {
    setReportNumbers((prevState) => [value, ...prevState]);
    handleChange('report_number', value);
  };

  return (
    <>
      <Modal.Body className='add-accident-modal-body'>
        {loadingData ? (
          <Loader loading={loadingData} />
        ) : detailView ? (
          <div className='d-flex flex-column'>
            <Grid container columnSpacing={2.5} rowSpacing={3} style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={4}>
                <div className='d-flex flex-column  w-100'>
                  <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Date and Time</InputLabel>
                  <Typography variant='s3'>
                    {accident?.collision_date_time ? formatDateTime(accident?.collision_date_time) : '-'}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>
                  Location of Accident (City & State)
                </InputLabel>
                <Typography variant='s3'>{accident?.accident_location || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Police Report</InputLabel>
                <Typography variant='s3'>{accident?.police_report ? 'Yes' : 'No'}</Typography>
              </Grid>

              {accident?.police_report_number && (
                <Grid item xs={12} sm={4}>
                  <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Police Report Number</InputLabel>
                  <Typography variant='s3'>{accident?.police_report_number}</Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Were Any Citations Issues?</InputLabel>
                <Typography variant='s3'>{accident?.citation_issued ? 'Yes' : 'No'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Report Type</InputLabel>
                <Typography variant='s3'>{accident?.type || 'Reportable'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Police Report Attachment</InputLabel>
                {accident?.police_report_attachment ? (
                  <SUnderlineText onClick={() => setPdfUrl(accident.police_report_attachment)}>
                    View Report
                  </SUnderlineText>
                ) : (
                  '-'
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Driver</InputLabel>
                <Typography variant='s3'>
                  {accident?.driver?.fname || '-'} {accident?.driver?.lname}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Vehicle</InputLabel>
                <Typography variant='s3'>
                  {accident?.vehicle?.equipment_id || '-'} {accident?.vehicle?.equipment_type?.title}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Trailer</InputLabel>
                <Typography variant='s3'>
                  {accident?.trailer?.equipment_id || '-'} {accident?.trailer?.equipment_type?.title}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}> Incident</InputLabel>
                <Typography variant='s3'>{accident?.incident?.incident_id || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Report Number</InputLabel>
                <Typography variant='s3'>{accident?.report_number || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Type of Crash</InputLabel>
                <Typography variant='s3'>{accident?.type_of_crash?.name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Control Device</InputLabel>
                <Typography variant='s3'>{accident?.control_device_type?.name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Shipment</InputLabel>
                <Typography variant='s3'>{accident?.shipment?.shipment_id || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Specific Stop</InputLabel>
                <Typography variant='s3'>{accident.shipment_stop?.stop_point?.location_name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Speed Limit</InputLabel>
                <Typography variant='s3'>{accident.speed_limit || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Road Type</InputLabel>
                <Typography variant='s3'>{accident.road_type?.name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Road Control</InputLabel>
                <Typography variant='s3'>{accident.road_control?.name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Injuries</InputLabel>
                <Typography variant='s3'>{accident.injuries || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Fatalities</InputLabel>
                <Typography variant='s3'>{accident.fatalities || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Weather</InputLabel>
                <Typography variant='s3'>{accident.weather?.name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Light Condition</InputLabel>
                <Typography variant='s3'>{accident.light_condition?.name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Road Condition</InputLabel>
                <Typography variant='s3'>{accident.road_condition?.name || '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Towing</InputLabel>
                <Typography variant='s3'>{accident.towing ? 'Yes' : 'No'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Hazmat</InputLabel>
                <Typography variant='s3'>{accident.hazmat ? 'Yes' : 'No'}</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ color: palette.gray900, marginBottom: 0 }}>Preventable</InputLabel>
                <Typography variant='s3'>{accident.preventable ? 'Yes' : 'No'}</Typography>
              </Grid>
            </Grid>

            <div className='d-flex flex-column gap-5 mt-4 mb-4'>
              <div className='d-flex flex-column gap-2'>
                <div className='d-flex flex-column'>
                  <Typography variant='s2'>Drivers Family Notified</Typography>
                  <Typography variant='s3'>{values.driver_family_notified ? 'Yes' : 'No'}</Typography>
                </div>
                {!!values.driver_family_notified && (
                  <div className='d-flex gap-5'>
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Name</Typography>
                      <Typography variant='s3'>{`${values.driver_family_notified_to_first_name || ''} ${
                        values.driver_family_notified_to_last_name || ''
                      }`}</Typography>
                    </div>
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Telephone</Typography>
                      <Typography variant='s3'>
                        {values.driver_family_notified_to_telephone
                          ? formatPhoneNumber(values.driver_family_notified_to_telephone)
                          : '-'}
                      </Typography>
                    </div>
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Date/Time Notified</Typography>
                      <Typography variant='s3'>
                        {values.driver_family_notified_datetime
                          ? formatDateTime(values.driver_family_notified_datetime)
                          : '-'}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex flex-column gap-2'>
                <div className='d-flex flex-column'>
                  <Typography variant='s2'>Tow Service Notified</Typography>
                  <Typography variant='s3'>{values.tow_service_notified ? 'Yes' : 'No'}</Typography>
                </div>
                {!!values.tow_service_notified && (
                  <div className='d-flex gap-5'>
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Name</Typography>
                      <Typography variant='s3'>{values.tow_service_notified_name || '-'}</Typography>
                    </div>
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Address of Tow</Typography>
                      <Typography variant='s3'>{values.tow_service_notified_address || '-'}</Typography>
                    </div>
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Telephone</Typography>
                      <Typography variant='s3'>
                        {values.tow_service_notified_telephone
                          ? formatPhoneNumber(values.tow_service_notified_telephone)
                          : '-'}
                      </Typography>
                    </div>
                    <div className='d-flex flex-column'>
                      <Typography variant='s2'>Date/Time Notified</Typography>
                      <Typography variant='s3'>
                        {values.tow_service_notified_datetime
                          ? formatDateTime(values.tow_service_notified_datetime)
                          : '-'}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-column'>
            <Grid container columnSpacing={2.5} rowSpacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={4}>
                <div className='d-flex flex-column  w-100'>
                  <DateTimePicker
                    required
                    label='Date and Time'
                    name='collision_date_time'
                    value={values.collision_date_time}
                    onBlur={handleBlur}
                    disableFuture
                    onChange={(val) => handleChange('collision_date_time', val)}
                    error={touchedErrors.collision_date_time}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <AddressAutocomplete
                  label='Location of Accident (City & State)'
                  id='address'
                  name='accident_location'
                  placeholder=''
                  onSuccess={(data) => onAddressSelect(data)}
                  changeAddress={(data) => changeAddress(data)}
                  onChange={handleChange}
                  value={values.accident_location}
                  options={{
                    types: ['locality', 'administrative_area_level_1', 'country', 'postal_code'],
                    componentRestrictions: { country: ['us', 'ca', 'mx'] },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel>Police Report</InputLabel>
                <div className='d-flex gap-4 align-items-center' style={{ minHeight: 33 }}>
                  <Radio
                    id='policy-report-0'
                    label='No'
                    name='police_report'
                    value={0}
                    checked={values.police_report === 0}
                    onChange={() => handleChange('police_report', 0)}
                  />
                  <Radio
                    id='policy-report-1'
                    label='Yes'
                    name='police_report'
                    value={1}
                    checked={values.police_report === 1}
                    onChange={() => handleChange('police_report', 1)}
                  />
                  {!!values.police_report && (
                    <InputWithIcon
                      className='flex-grow-1'
                      icon='#'
                      placeholder='Police Report Number'
                      name='police_report_number'
                      value={values.police_report_number}
                      onChange={handleChange}
                    />
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel>Were Any Citations Issues?</InputLabel>
                <div className='d-flex gap-4 align-items-center' style={{ minHeight: 33 }}>
                  <Radio
                    id='citation-issued-0'
                    label='No'
                    name='citation_issued'
                    value={0}
                    checked={values.citation_issued === 0}
                    onChange={() => handleChange('citation_issued', 0)}
                  />
                  <Radio
                    id='citation-issued-1'
                    label='Yes'
                    name='citation_issued'
                    value={1}
                    checked={values.citation_issued === 1}
                    onChange={() => handleChange('citation_issued', 1)}
                  />
                  {!!values.citation_issued && (
                    <Input
                      type='number'
                      placeholder='Violation Count'
                      name='citation_issued_violation_count'
                      value={values.citation_issued_violation_count}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        blockNonPositiveNumbers(e);
                        blockNotNumberChars(e);
                      }}
                    />
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Uploader
                  label='Attach Police Report'
                  type={3}
                  uploaderLabel='Attach'
                  document={values.police_report_attachment}
                  onDrop={(files) => handleChange('police_report_attachment', files[0])}
                  onRemove={() => handleChange('police_report_attachment', null)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel>Report Type</InputLabel>
                <Autocomplete
                  options={['Reportable', 'Not Reportable']}
                  value={values.type}
                  onChange={(e, val) => handleChange('type', val)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel required>Select Driver</InputLabel>
                <Autocomplete
                  options={drivers}
                  value={values.driver}
                  onChange={(e, val) => {
                    handleChange('incident', null);
                    handleChange('driver', val);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => (option ? `${option.fname} ${option.lname}` : '')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel required>Select Vehicle</InputLabel>
                <Autocomplete
                  options={vehiclesList}
                  value={values.vehicle}
                  getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
                  onChange={(e, val) => handleChange('vehicle', val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel required>Select Trailer</InputLabel>
                <Autocomplete
                  options={trailersList}
                  value={values.trailer}
                  getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
                  onChange={(e, val) => handleChange('trailer', val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Select Incident</InputLabel>
                <Autocomplete
                  labelKey='incident_id'
                  options={incidents}
                  value={values.incident}
                  onChange={(e, val) => handleChange('incident', val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Report Number</InputLabel>
                <ReportNumberAutocomplete
                  name='report_number'
                  placeholder='Select Report Number'
                  options={reportNumbers}
                  value={values.report_number}
                  onChange={(e, value) => handleChange('report_number', value)}
                  onAddNew={onAddReportNumber}
                  disableClearable={false}
                />
              </Grid>
              <Grid item xs={12} sm={4} />
              <Grid item xs={12} sm={4}>
                <InputLabel>Type of Crash</InputLabel>
                <Autocomplete
                  name='type_of_crash'
                  placeholder='Select Type of Crash'
                  options={crashTypes}
                  value={values.type_of_crash}
                  onChange={(e, value) => handleChange('type_of_crash', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Control Device</InputLabel>
                <Autocomplete
                  name='control_device_type'
                  placeholder='Select Control Device'
                  options={controlDeviceTypes}
                  value={values.control_device_type}
                  onChange={(e, value) => handleChange('control_device_type', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Shipment</InputLabel>
                <Autocomplete
                  name='shipment'
                  labelKey='shipment_id'
                  placeholder='Select Shipment'
                  options={shipments}
                  value={values.shipment}
                  onChange={(e, value) => {
                    handleChange('shipment', value);
                    if (value) {
                      getShipmentStopsData(value.shipment_id);
                    }
                  }}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.shipment_id === value.shipment_id}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {values.type_of_crash?.id === 15 && (
                  <Input
                    name='type_of_crash_custom'
                    placeholder='Custom'
                    label='Custom'
                    onChange={handleChange}
                    value={values.type_of_crash_custom}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                {values.control_device_type?.id === 5 && (
                  <Input
                    name='control_device_other'
                    placeholder='Other'
                    label='Other'
                    onChange={handleChange}
                    value={values.control_device_other}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4} />

              <Grid item xs={12} sm={4}>
                <InputLabel>Specific Stop</InputLabel>
                <Autocomplete
                  getOptionLabel={(option) => {
                    return option?.stop_point ? `${option?.stop_point?.location_name}` : '';
                  }}
                  name='shipment_stop'
                  placeholder='Specific Stop'
                  options={stopsData}
                  value={values.shipment_stop}
                  onChange={(e, value) => handleChange('shipment_stop', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Input
                  name='speed_limit'
                  placeholder='Speed Limit'
                  label='Speed Limit'
                  onChange={handleChange}
                  value={values.speed_limit}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel>Road Type</InputLabel>
                <Autocomplete
                  name='road_type'
                  placeholder='Road Type'
                  options={roadTypes}
                  value={values.road_type}
                  onChange={(e, value) => handleChange('road_type', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel>Road Control</InputLabel>
                <Autocomplete
                  name='road_control'
                  placeholder='Road Control'
                  options={roadControls}
                  value={values.road_control}
                  onChange={(e, value) => handleChange('road_control', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input
                  name='injuries'
                  placeholder='Injuries'
                  label='Injuries'
                  type='number'
                  onChange={handleChange}
                  value={values.injuries}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input
                  name='fatalities'
                  placeholder='Fatalities'
                  label='Fatalities'
                  onChange={handleChange}
                  value={values.fatalities}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel>Weather</InputLabel>
                <Autocomplete
                  name='weather'
                  placeholder='Weather'
                  options={weatherTypes}
                  value={values.weather}
                  onChange={(e, value) => handleChange('weather', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel>Light Condition</InputLabel>
                <Autocomplete
                  name='light_condition'
                  placeholder='Day/Night'
                  options={lightConditions}
                  value={values.light_condition}
                  onChange={(e, value) => handleChange('light_condition', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  label='Road Condition'
                  name='road_condition'
                  placeholder='Road Control'
                  options={roadConditions}
                  value={values.road_condition}
                  onChange={(e, value) => handleChange('road_condition', value)}
                  disableClearable={false}
                  isOptionEqualToValue={(option, value) => option === value}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {values.weather?.id === 9 && (
                  <Input
                    name='weather_other'
                    placeholder='Other'
                    label='Other'
                    onChange={handleChange}
                    value={values.weather_other}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {values.light_condition?.id === 6 && (
                  <Input
                    name='light_other'
                    placeholder='Other'
                    label='Other'
                    onChange={handleChange}
                    value={values.light_other}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {values.road_condition?.id === 6 && (
                  <Input
                    name='road_condition_other'
                    placeholder='Other'
                    label='Other'
                    onChange={handleChange}
                    value={values.road_condition_other}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomCheckbox checked={values.towing} onChange={(checked) => handleChange('towing', checked)}>
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Towing
                  </Typography>
                </CustomCheckbox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomCheckbox checked={values.hazmat} onChange={(checked) => handleChange('hazmat', checked)}>
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Hazmat
                  </Typography>
                </CustomCheckbox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomCheckbox
                  checked={values.preventable}
                  onChange={(checked) => handleChange('preventable', checked)}
                >
                  <Typography variant='s2' style={{ marginLeft: '8px' }}>
                    Preventable
                  </Typography>
                </CustomCheckbox>
              </Grid>
            </Grid>

            <div>
              <div className='d-flex align-items-center gap-2 mt-2'>
                <CustomCheckbox
                  name='driver_family_notified'
                  type='switch'
                  smail={false}
                  checked={values.driver_family_notified}
                  onChange={(checked) => handleChange('driver_family_notified', checked)}
                />
                <Typography variant='s2'>Drivers Family Notified</Typography>
              </div>
              {!!values.driver_family_notified && (
                <div className='d-flex ms-5 gap-3'>
                  <div className='d-flex flex-column w-25'>
                    <InputLabel>First Name</InputLabel>
                    <Input
                      name='driver_family_notified_to_first_name'
                      value={values.driver_family_notified_to_first_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='d-flex flex-column w-25'>
                    <InputLabel>Last Name</InputLabel>
                    <Input
                      name='driver_family_notified_to_last_name'
                      value={values.driver_family_notified_to_last_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='d-flex flex-column w-25'>
                    <InputLabel>Telephone</InputLabel>
                    <PhoneInput
                      name='driver_family_notified_to_telephone'
                      placeholder='+1 (555) 555-1234'
                      onChange={(val) => handleChange('driver_family_notified_to_telephone', val)}
                      value={values.driver_family_notified_to_telephone}
                    />
                  </div>
                  <div className='d-flex flex-column  w-25'>
                    <DateTimePicker
                      label='Date/Time Notified'
                      name='driver_family_notified_datetime'
                      onBlur={handleBlur}
                      value={values.driver_family_notified_datetime}
                      onChange={(val) => handleChange('driver_family_notified_datetime', val)}
                      disableFuture
                      error={touchedErrors.driver_family_notified_datetime}
                    />
                  </div>
                </div>
              )}

              {!!values.towing && (
                <>
                  <div className='d-flex align-items-center gap-2 mt-2'>
                    <CustomCheckbox
                      name='tow_service_notified'
                      type='switch'
                      smail={false}
                      checked={values.tow_service_notified}
                      onChange={(checked) => handleChange('tow_service_notified', checked)}
                    />
                    <Typography variant='s2'>Tow Service Notified</Typography>
                  </div>
                  {!!values.tow_service_notified && (
                    <div className='d-flex ms-5 gap-3'>
                      <div className='d-flex flex-column w-25'>
                        <InputLabel>Name</InputLabel>
                        <AddressAutocomplete
                          id='tow_service_notified_name'
                          name='tow_service_notified_name'
                          placeholder='Tow name'
                          onSuccess={() => null}
                          changeAddress={(data) => changeTowAddress(data)}
                          onChange={(e) => {
                            handleChange('tow_service_notified_name', e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          value={values.tow_service_notified_name}
                          options={{
                            types: ['geocode', 'establishment'],
                            componentRestrictions: { country: ['us', 'ca', 'mx'] },
                          }}
                        />
                      </div>
                      <div className='d-flex flex-column w-25'>
                        <InputLabel>Address of Tow</InputLabel>
                        <AddressAutocomplete
                          name='address1'
                          onSuccess={() => null}
                          changeAddress={changeTowAddress}
                          onChange={(e) => handleChange('tow_service_notified_address', e.target.value)}
                          value={values.tow_service_notified_address}
                        />
                      </div>
                      <div className='d-flex flex-column w-25'>
                        <InputLabel>Telephone</InputLabel>
                        <PhoneInput
                          name='tow_service_notified_telephone'
                          placeholder='+1 (555) 555-1234'
                          onChange={(val) => handleChange('tow_service_notified_telephone', val)}
                          value={values.tow_service_notified_telephone}
                        />
                      </div>
                      <div className='d-flex flex-column  w-25'>
                        <DateTimePicker
                          label='Date/Time Notified'
                          name='tow_service_notified_datetime'
                          value={values.tow_service_notified_datetime}
                          onChange={(val) => handleChange('tow_service_notified_datetime', val)}
                          renderStart={false}
                          disableFuture
                          onBlur={handleBlur}
                          error={touchedErrors.tow_service_notified_datetime}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            {!!accidentId && (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='secondary'
                title='Edit'
                disabled={loading}
                leftIcon={<EditIcon style={{ marginRight: 10 }} />}
                onClick={() => setDetailView((prevState) => !prevState)}
              />
            )}
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save'
              disabled={loading}
              onClick={onSave}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save & Go To List'
              disabled={loading}
              onClick={onSaveAndClose}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Next'
              onClick={onNext}
              disabled={loading}
              leftIcon={
                loading ? (
                  <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
      </Modal.Footer>
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='' />}
    </>
  );
};

export default General;
