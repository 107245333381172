import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { formatNumber, palette } from 'utils/constants';
import RevenueChart from 'pages/Accounting/Payables/StatsSection/components/Charts/RevenueChart';
import { getRevenues } from 'Api/Payables';
import { SBoxContent } from '../../../Dashboard.styles';

const Revenue = () => {
  const { currency } = useSelector((state) => state.root);
  const [loading, setLoading] = useState(false);
  const [revenue, setRevenue] = useState(null);

  const getRevenueData = async () => {
    setLoading(true);
    try {
      const { data } = await getRevenues();
      const count = data?.monthly_revenues?.reduce((a, b) => {
        a += b.count || 0;
        return a;
      }, 0);
      setRevenue({ ...data, count });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRevenueData();
  }, []);

  return (
    <div className='box-content-wrapper'>
      <SBoxContent>
        <div className='d-flex align-items-center justify-content-between'>
          <span className='d-inline-flex align-items-center gap-1'>
            <Typography variant='c3' style={{ color: palette.gray700 }}>
              REVENUE
            </Typography>
            <Chip label={revenue?.count} />
          </span>
        </div>
        <div className='d-flex justify-content-between'>
          {loading ? (
            <Skeleton sx={{ my: 4, mx: 4 }} style={{ width: '50px', height: '24px' }} />
          ) : (
            <div className='d-flex flex-column'>
              <Typography variant='h5' style={{ color: palette.gray900 }}>
                {currency}
                {formatNumber(revenue?.this_month_total)}
              </Typography>
              <Typography variant='c2' style={{ color: palette.gray500 }}>
                This Month
              </Typography>
            </div>
          )}
        </div>
        <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
          <RevenueChart monthsData={revenue?.monthly_revenues} />
        </div>
      </SBoxContent>
    </div>
  );
};

export default Revenue;
