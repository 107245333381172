import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import right from 'assets/icons/drivers/right.svg';
import quickbooks from 'assets/icons/quickbooksIcon.svg';
import userDefault from 'assets/icons/drivers/user-profile.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import ColumnHeader from 'common/ColumnHeader';
import UserInfo from 'components/UserInfo';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { useHasQuickbook } from 'hooks/useHasQuickbook';
import { formatAmount, getShipmentType } from 'utils/helpers';
import { palette, statusColor, statusColorInStatusId } from 'utils/constants';
import RowActions from 'pages/Accounting/Receivables/TableSection/shared/RowActions';
import DriversPopup from 'pages/Accounting/Receivables/TableSection/shared/DriversPopup';
import { SUnderlineText } from 'pages/Accounting/Receivables/Receivables.styles';

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export const getTransactionDetails = (transaction) => {
  const appliedPaymentsCount = transaction.payments_applied?.length;

  const isPayed =
    transaction.amount === 0 ||
    (!!appliedPaymentsCount && transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due <= 0);

  const openBalance = !appliedPaymentsCount
    ? transaction.amount
    : transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due < 0
    ? 0
    : transaction.payments_applied?.[appliedPaymentsCount - 1].balance_due;

  return { appliedPaymentsCount, isPayed, openBalance };
};

export const useColumns = ({
  sort,
  sortingQuery,
  onViewPdf,
  onMarkPaid,
  onRevertInvoice,
  onUploadDocuments,
  onRefundAndRevert,
  onViewShipmentNotes,
  onViewStandaloneNotes,
  setSelectedTransactionType,
}) => {
  const { use } = useTheme();
  const hasQuickbook = useHasQuickbook();
  const navigate = useNavigate();
  const { formatDate, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'shipment_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SHIPMENT ID' field='shipment_id' />,
      render: (row) => <Typography variant='s2'>{row.billing?.shipment?.shipment_id || '-'}</Typography>,
    },
    {
      field: 'invoice_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='INVOICE #' field='invoice_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex align-items-center gap-2'>
              <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                {row.invoice_id}
              </Typography>
              {!!row.qb_sync && !!hasQuickbook && <img src={quickbooks} alt='qb' height={20} />}
            </div>
            {row.batch && (
              <SUnderlineText
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onViewPdf(row.batch);
                }}
              >
                {row.batch?.batch_id}
              </SUnderlineText>
            )}
          </div>
        );
      },
    },
    {
      field: 'reference_id',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='REF #' field='reference_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.reference_id || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'shipment_type',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SHIPMENT TYPE' field='shipment_type' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.billing_id ? getShipmentType(row.billing?.shipment?.shipment_type) : '-'}
        </Typography>
      ),
    },
    {
      field: 'equipment',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='EQUIPMENT' field='equipment' />,
      render: (row) => {
        return row.billing_id && row.billing.shipment?.equipment ? (
          <>
            <Typography variant='b2' style={{ color: use(palette.gray700) }}>
              {row.billing.shipment?.equipment?.equipment_id}&nbsp;
            </Typography>
            <Typography variant='overLine' style={{ color: use(palette.gray500), textTransform: 'uppercase' }}>
              {row.billing.shipment?.equipment?.equipment_type?.title}
            </Typography>
            {!!row.billing.shipment?.last_hooked_equipment &&
              row.billing.shipment?.last_hooked_equipment.id !== row.billing.shipment?.equipment.id && (
                <>
                  <TwoWaysIcon style={{ margin: '0 8px' }} />
                  <Typography variant='overLine2' style={{ textTransform: 'uppercase' }}>
                    {row.billing.shipment?.last_hooked_equipment?.equipment_id}&nbsp;
                    <Typography variant='overLine'>
                      {row.billing.shipment?.last_hooked_equipment?.length?.length}-
                      {row.billing.shipment?.last_hooked_equipment?.equipment_type?.title}
                    </Typography>
                  </Typography>
                </>
              )}
          </>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'driver',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DRIVER' field='driver' />,
      render: (row) => {
        const driver1 = row?.billing?.shipment?.assigned_to_1;
        const driver2 = row?.billing?.shipment?.assigned_to_2;

        return row.billing_id ? (
          <div>
            {row?.billing?.shipment?.shipment_type !== 'split-parent' &&
              driver1 &&
              (row.billing?.shipment?.dispatch_type !== 'external_carrier' ? (
                <UserInfo
                  size='32px'
                  statusSize='7px'
                  data={{
                    ...(driver1 || {}),
                    name: `${driver1.fname || driver1.first_name} ${driver1.lname || driver1.last_name}`,
                    image: driver1.image || driver1.profile_logo,
                    status: driver1?.status_data?.driver_status || driver1?.status_data?.id || driver1.status,
                    user_type: 'driver',
                  }}
                />
              ) : (
                <div className='details-container details-container--shipment-audit details-container---staff'>
                  <div className='image-container' style={{ width: '36px', height: '36px' }}>
                    <img
                      className='user-image'
                      alt='driver'
                      src={driver1.image ? driver1.image : userDefault}
                      style={{ width: '36px', height: '36px' }}
                    />
                    {!!driver1.status && (
                      <div
                        className='user-active-indicator'
                        style={{ backgroundColor: use(statusColorInStatusId[driver1.status]?.color) }}
                      />
                    )}
                  </div>
                  <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                    {driver1.fname || ''} {driver1.lname || ''}
                  </Typography>
                </div>
              ))}
            {row.billing?.shipment?.shipment_type !== 'split-parent' &&
              driver2 &&
              (row.billing?.shipment?.dispatch_type !== 'external_carrier' ? (
                <UserInfo
                  size='32px'
                  statusSize='7px'
                  data={{
                    ...(driver2 || {}),
                    name: `${driver2.fname || driver2.first_name} ${driver2.lname || driver2.last_name}`,
                    image: driver2.image || driver2.profile_logo,
                    status: driver2?.status_data?.driver_status || driver2?.status_data?.id || driver2.status,
                    user_type: 'driver',
                  }}
                />
              ) : (
                <div className='details-container details-container--shipment-audit details-container---staff'>
                  <div className='image-container' style={{ width: '36px', height: '36px' }}>
                    <img
                      className='user-image'
                      alt='driver'
                      style={{ width: '36px', height: '36px' }}
                      src={driver2.image ? driver2.image : userDefault}
                    />
                    {!!driver2.status && (
                      <div
                        className='user-active-indicator'
                        style={{ backgroundColor: use(statusColor[driver2.login_status]?.color) }}
                      />
                    )}
                  </div>
                  <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                    {driver2.fname || ''} {driver2.lname || ''}
                  </Typography>
                </div>
              ))}
            {row.billing.shipment?.shipment_type === 'split-parent' && (
              <DriversPopup
                shipmentId={row.billing.shipment_id}
                masterShipmentDriver={row.billing.shipment?.assigned_to_1}
              />
            )}
          </div>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'pw',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='PW' field='pw' />,
      render: (row) =>
        row.billing?.paperwork_received ? (
          <img src={right} alt='' />
        ) : row.billing_id ? (
          <DeleteIcon fill={palette.red500} />
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            -
          </Typography>
        ),
    },
    {
      field: 'age',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AGING' field='age' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {row.age}
        </Typography>
      ),
    },
    {
      field: 'sent_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SENT DATE' field='customer_billed_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {formatDate(row.customer_billed_date)}
        </Typography>
      ),
    },
    {
      field: 'sent_method',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='SENT METHOD' field='sent_method' />,
      render: (row) => {
        const sentMethod = row.mail_to_id ? 'Print and Mailed' : row.email_to ? 'Email' : '';
        const title =
          sentMethod === 'Print and Mailed' ? (
            <Typography variant='b2' style={{ color: palette.white }}>
              Mailed to {row.mail_to?.address1}, {row.mail_to?.address2 ? `${row.mail_to?.address2},` : ''}
              {row.mail_to?.city?.name}, {row.mail_to?.state?.short_name} {row.mail_to?.zipcode}
            </Typography>
          ) : (
            <Typography variant='b2' style={{ color: palette.white }}>
              Emailed to {row.email_to} on {formatDateTime(row.processed_at)}
            </Typography>
          );

        if (!sentMethod) {
          return (
            <Typography variant='b2' style={{ color: palette.gray700 }}>
              -
            </Typography>
          );
        }

        return (
          <NoMaxWidthTooltip title={title}>
            <span>
              <Typography variant='b2' style={{ color: use(palette.gray700) }}>
                {sentMethod}
              </Typography>
            </span>
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: 'due_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DUE DATE' field='due_date' onClick={sortingQuery} sort={sort} />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {formatDate(row.due_date)}
        </Typography>
      ),
    },
    {
      field: 'stops',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='STOPS' field='stops' />,
      render: (row) => {
        let count = 0;

        if (row.billing_id) {
          for (let i = 0; i < row.billing.shipment?.stops_count; i++) {
            const stop = row.billing.shipment?.shipment_stops[i];
            if (stop?.arrival_date === null) break;
            count++;
          }
        }

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${count} of ${row.billing.shipment?.stops_count}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'origin',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='ORIGIN' field='origin' />,
      render: (row) => {
        const origin_stop = row.billing?.shipment?.shipment_stops[0];

        const city = origin_stop?.stop_point?.city?.name;
        const state = origin_stop?.stop_point?.state?.short_name;
        const zip = origin_stop?.stop_point?.zipcode;

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${city}, ${state} ${zip}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'destination',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='DESTINATION' field='destination' />,
      render: (row) => {
        const destination_stop =
          row.billing?.shipment?.shipment_stops?.[Number(row.billing?.shipment?.shipment_stops?.length) - 1];

        const city = destination_stop?.stop_point?.city?.name;
        const state = destination_stop?.stop_point?.state?.short_name;
        const zip = destination_stop?.stop_point?.zipcode;

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${city}, ${state} ${zip}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'complete_date',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='COMPLETE DATE' field='end_date_time' />,
      render: (row) => {
        const completeDate = row.billing?.shipment?.tonu_date_time || row.billing?.shipment?.end_date_time;

        return row.billing_id ? (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {completeDate ? formatDate(completeDate) : '-'}
          </Typography>
        ) : (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'miles',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='MILES' field='miles' />,
      render: (row) => {
        const loaded = row.billing?.shipment?.loaded_miles ? Math.round(row.billing.shipment.loaded_miles) : 0;
        const empty = row.billing?.shipment?.empty_miles ? Math.round(row.billing.shipment.empty_miles) : 0;
        const total = loaded + empty;

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {row.billing_id ? `${total}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'total_amount',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='AMOUNT' field='total_amount' />,
      render: (row) => (
        <Typography variant='b2' style={{ color: use(palette.gray700) }}>
          {currency}
          {formatAmount(row.amount)}
        </Typography>
      ),
    },
    {
      field: 'open_balance',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='OPEN BALANCE' field='open_balance' />,
      render: (row) => {
        const { openBalance } = getTransactionDetails(row);

        return (
          <Typography variant='b2' style={{ color: use(palette.gray700) }}>
            {currency}
            {formatAmount(openBalance)}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      cellStyle: (e, row) => ({ backgroundColor: moment().isAfter(row.due_date) ? palette.red0 : '' }),
      title: <ColumnHeader title='' />,
      render: (row) => {
        const { isPayed, openBalance } = getTransactionDetails(row);
        const isShipmentTransaction = !!row.billing_id;

        return isShipmentTransaction ? (
          !isPayed ? (
            <RowActions
              row={row}
              onViewShipment={
                row.billing?.shipment_id ? () => navigate(`/shipment/${row?.billing?.shipment_id}`) : null
              }
              onMarkPaid={() => onMarkPaid(row)}
              onRevertInvoice={() => {
                setSelectedTransactionType('shipment');
                onRevertInvoice(row);
              }}
              onUploadDocuments={() => onUploadDocuments(row)}
              onViewPDF={() => onViewPdf(row)}
              onViewNotes={() => onViewShipmentNotes(row)}
            />
          ) : (
            <RowActions
              row={row}
              onViewPDF={() => onViewPdf(row)}
              onViewNotes={() => onViewShipmentNotes(row)}
              onRefundAndRevert={() => onRefundAndRevert(row)}
            />
          )
        ) : !isPayed ? (
          <RowActions
            row={row}
            onViewPDF={() => onViewPdf(row)}
            onMarkPaid={() => onMarkPaid({ ...row, open_balance: openBalance })}
            onViewNotes={() => onViewStandaloneNotes(row)}
            onRevertInvoice={() => {
              setSelectedTransactionType('standalone');
              onRevertInvoice(row);
            }}
          />
        ) : (
          <RowActions
            row={row}
            onViewPDF={() => onViewPdf(row)}
            onViewNotes={() => onViewStandaloneNotes(row)}
            onRefundAndRevert={() => onRefundAndRevert(row)}
          />
        );
      },
    },
  ];
};
