import React from 'react';
import { ReactComponent as Barcode } from 'assets/barcode.svg';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { SAddressFields, SCompanyInfo, SHeader } from '../BillOfLading.styles';
import DetailsHeader from '../../detailsRow/DetailsHeader';

const BillOfLadingHeader = ({ company, info, data, billNumber }) => {
  const { logo_path } = company || {};
  const { invoice_customer } = data || {};

  return (
    <SHeader>
      <DetailsHeader GPS={null} data={info} isShipmentTable={false} isBillOfLading />
      <SCompanyInfo>
        <div className='logo-wrapper'>{logo_path && <img src={logo_path} alt='Company logo' height={48} />}</div>
        <div className='d-flex flex-column gap-2'>
          <Typography variant='h4' style={{ textAlign: 'center', color: palette.gray900 }}>
            SCAC: {invoice_customer?.scac}
          </Typography>

          <Typography variant='s2' style={{ textAlign: 'center', color: palette.gray900 }}>
            Web straight bill of lading—original—not negotiable
          </Typography>
          <Typography variant='c2' style={{ textAlign: 'center', color: palette.gray500 }}>
            For shipping information please call {formatPhoneNumberIntl(invoice_customer?.phone_number)}
          </Typography>
        </div>
        <SAddressFields>
          <Typography variant='s2'>{billNumber}</Typography>
          <Barcode />
        </SAddressFields>
      </SCompanyInfo>
    </SHeader>
  );
};

export default BillOfLadingHeader;
