import React from 'react';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';

const Description = ({ open, onClose, description }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={<div />}
      $bgColor={palette.gray0}
      $maxWidth='600px'
      $minWidth='500px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'done',
          type: 'primary',
          title: 'Done',
          onClick: onClose,
        },
      ]}
    >
      <Typography>{description}</Typography>
    </Modal>
  );
};

export default Description;
