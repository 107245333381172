import React, { useState, useRef, useEffect } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import useDateFormat from 'hooks/useDateFormat';
import './DateRangePicker.css';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import calendar from '../../assets/icons/drivers/calendar.svg';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { fullOptions, liveOptions, pastDates, sideBar, sideBarAllTime } from './DateRangePicker.data';

const local = {
  format: 'DD-MM-YYYY',
  sundayFirst: false,
};

function RangeCalender({
  labelType,
  dateSelect,
  dateTimeRange,
  setRangeName,
  setIsShowDatePicker,
  type,
  maxDate,
  deletedStaticRangesIndexes = [],
  className,
}) {
  const { formatDate } = useDateFormat();
  const datePickerRef = useRef(null);
  const [staticRanges, setStaticRanges] = useState([]);
  const [state, setState] = useState([
    {
      startDate: dateTimeRange.start,
      endDate: dateTimeRange.end,
      key: 'selection',
    },
  ]);

  const apply = () => {
    const sideBarOption =
      type === 'live'
        ? liveOptions.find((el) => el.label === state?.[0]?.label)
        : type === 'allTime'
        ? sideBarAllTime.find((el) => el.label === state?.[0]?.label)
        : type === 'full'
        ? fullOptions.find((el) => el.label === state?.[0]?.label)
        : type === 'pastDates'
        ? pastDates.find((el) => el.label === state?.[0]?.label)
        : sideBar.find((el) => el.label === state?.[0]?.label);

    const isCustomRange =
      !state?.[0]?.label ||
      (sideBarOption?.range()?.startDate !== state[0]?.startDate &&
        sideBarOption?.range()?.endDate !== state[0]?.endDate);

    if (labelType === 'showDates') {
      if (state[0].label === 'All Time') {
        setRangeName('All Time');
      } else {
        setRangeName(`${formatDate(state[0]?.startDate)} - ${formatDate(state[0]?.endDate)}`);
      }
    } else {
      setRangeName(!isCustomRange ? state[0].label : 'Custom Range');
    }

    dateSelect({
      start: state[0]?.startDate,
      end: state[0]?.endDate,
    });

    setIsShowDatePicker(false);
  };

  useEffect(() => {
    if (type === 'live') {
      setStaticRanges([
        ...createStaticRanges(liveOptions.filter((_, index) => !deletedStaticRangesIndexes.includes(index))),
      ]);
    } else if (type === 'full') {
      setStaticRanges([
        ...createStaticRanges(fullOptions.filter((_, index) => !deletedStaticRangesIndexes.includes(index))),
      ]);
    } else if (type === 'allTime') {
      setStaticRanges([
        ...createStaticRanges(sideBarAllTime.filter((_, index) => !deletedStaticRangesIndexes.includes(index))),
      ]);
    } else if (type === 'pastDates') {
      setStaticRanges([
        ...createStaticRanges(pastDates.filter((_, index) => !deletedStaticRangesIndexes.includes(index))),
      ]);
    } else {
      setStaticRanges([
        ...createStaticRanges(sideBar.filter((_, index) => !deletedStaticRangesIndexes.includes(index))),
      ]);
    }
  }, [type]);

  return (
    <div className={`datePicker ${className || ''}`}>
      <div>
        <DateRangePicker
          showSelectionPreview
          ranges={state}
          onChange={(item) => setState([item.selection])}
          months={1}
          direction='vertical'
          scroll={{ enabled: true }}
          showMonthAndYearPickers
          staticRanges={staticRanges}
          inputRanges={[]}
          moveRangeOnFirstSelection={false}
          local={local}
          ref={datePickerRef}
          maxDate={maxDate}
        />
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', background: '#fff' }}>
          <div onClick={() => setIsShowDatePicker(false)} style={{ marginRight: '10px' }}>
            <PrimaryBtn title='Close' />
          </div>
          <div onClick={apply}>
            <PrimaryBtn icon={calendar} title='Apply' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RangeCalender;
