import jsPDF from 'jspdf';
import truckinLogo from 'assets/icons/logo/truckinDigital.png';
import { palette } from 'utils/constants';

export const generatePdf = (incident, formatDateTime) => {
  const doc = new jsPDF({ format: 'letter' });
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.setFontSize(11);
  doc.text(
    `${incident.incident_id ? `${incident.incident_id} - ` : ''} ${
      incident.custom_incident_type ? `${incident.custom_incident_type}` : `${incident.incident_type?.type || ''}`
    }`,
    pageWidth / 2,
    22,
    { align: 'center' }
  );

  doc.setDrawColor(188, 194, 206);
  doc.setLineWidth(0.4);
  doc.line(16, 27, pageWidth - 16, 27);

  doc.setTextColor(palette.gray500);
  doc.setFontSize(9);
  doc.text('Incident ID', 16, 40);
  doc.text('Report Number', pageWidth / 2 + 16, 40);
  doc.text('Date/Time', 16, 60);
  doc.text('Type of Incident', pageWidth / 2 + 16, 60);
  doc.text('Action Taken', 16, 80);
  doc.text('Location of Incident', pageWidth / 2 + 16, 80);
  doc.text('Action By', 16, 100);
  doc.text('Report Details', 16, 120);
  doc.text('Reason(s) given by Person Implicated', 16, 150);
  doc.text('Notes', 16, 180);
  doc.text('Driver Signature', 16, 210);

  doc.setTextColor(palette.gray900);
  doc.text(`${incident.incident_id || ''}`, 16, 45);
  doc.text(`${incident?.report_number || '-'}`, pageWidth / 2 + 16, 45);
  doc.text(`${formatDateTime(`${incident.incident_date} ${incident.incident_time}`)}`, 16, 65);
  doc.text(
    `${incident.custom_incident_type ? incident.custom_incident_type : incident.incident_type?.type || '-'}`,
    pageWidth / 2 + 16,
    65
  );
  doc.text(
    `${
      incident.city?.name || incident.state?.name || incident.country?.name
        ? `${incident.city?.name || ''}${incident.city?.name ? ',' : ''} ${incident.state?.name || ''}${
            incident.state?.name ? ',' : ''
          } ${incident.country?.name || ''}`
        : '-'
    }`,
    pageWidth / 2 + 16,
    85
  );
  doc.text(`${incident.incident_action_taken?.action || '-'}`, 16, 85);
  doc.text(
    `${
      incident.added_by?.first_name ? `${incident.added_by?.first_name} ${incident.added_by?.last_name || ' '}` : '-'
    }`,
    16,
    105
  );
  doc.text(`${incident.report_details || '-'}`, 16, 125, { maxWidth: pageWidth - 32 });
  doc.text(
    `${incident.reason || incident.statement ? `${incident.reason || ''} ${incident.statement || ''}` : '-'}`,
    16,
    155,
    { maxWidth: pageWidth - 32 }
  );
  doc.text(`${incident.notes || '-'}`, 16, 185, { maxWidth: pageWidth - 32 });
  if (incident.signature) {
    doc.addImage(`${incident.signature}?cache=${Math.random()}`, 'JPEG', 16, 215, 60, 12, 'signature', 'FAST');
  } else {
    doc.text('-', 16, 215);
  }

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Powered by`, 16, pageHeight - 6);
  doc.addImage(truckinLogo, 'png', 32, pageHeight - 11, 40, 8);

  const imageDocs = incident.incident_record_documents?.filter(
    (file) => file?.path?.endsWith('.png') || file?.path?.endsWith('.jpg') || file?.path?.endsWith('.jpeg')
  );

  imageDocs.forEach((img, i) => {
    doc.addPage();
    doc.addImage(
      `${img.path}?cache=${Math.random()}`,
      'JPEG',
      16,
      16,
      pageWidth - 32,
      pageHeight - 50,
      `image-${i}`,
      'FAST'
    );
  });

  if (incident.incident_record_images?.length) {
    incident.incident_record_images.forEach((img, i) => {
      doc.addPage();
      doc.addImage(
        `${img.image_path}?cache=${Math.random()}`,
        'JPEG',
        16,
        16,
        pageWidth - 32,
        pageHeight - 50,
        `image-${i}`,
        'FAST'
      );
    });
  }

  doc.setDocumentProperties({ title: `Incident - ${incident.incident_id}` });
  const url = doc.output('datauristring', { filename: `Incident - ${incident.incident_id}` });
  const blob = doc.output('blob', { filename: `Incident - ${incident.incident_id}` });

  return { blob, url };
};
