export const getInitialValues = (maintenance, equipment = null) => {
  const {
    description,
    cost,
    one_time,
    date_interval,
    record_type,
    service_at,
    date_interval_type_id,
    odometer_interval_mode,
    odometer_interval,
    odometer_type,
    one_time_datetime,
  } = maintenance || {};

  return {
    isTrailer: false,
    equipment,
    record_type_id: record_type || null,
    description: description || '',
    record_serviced_id: service_at || null,
    cost: cost || '',
    interval_type: maintenance && !!one_time ? 'one_time' : odometer_interval_mode ? 'odometer' : 'date',
    date_interval: date_interval || '0',
    date_interval_type: date_interval_type_id || dateIntervalTypeOptions[0],
    odometer_interval: odometer_interval || '0',
    odometer_type: odometer_type || 'Miles',
    one_time_datetime: one_time_datetime ? new Date(one_time_datetime) : new Date(),
  };
};

export const dateIntervalTypeOptions = [
  {
    type: 'Day(s)',
    id: 1,
  },
  {
    type: 'Month(s)',
    id: 2,
  },
  {
    type: 'Year(s)',
    id: 3,
  },
];
