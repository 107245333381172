import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const AccountActions = ({ account, onEdit, onUpdateStatus, onConnectBankFeed, onDisconnectBankFeed }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { id, account_status, is_truckindigital, is_connected, system_account_type } = account || {};

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'edit':
        setTimeout(onEdit);
        break;
      case 'disconnectBankFeed':
        setTimeout(onDisconnectBankFeed);
        break;
      case 'connectBankFeed':
        setTimeout(onConnectBankFeed);
        break;
      case 'changeStatus':
        setTimeout(onUpdateStatus);
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl} size={18}>
      <li onClick={(event) => onActionClick(event, 'edit')}>Edit</li>
      {!!is_connected && id !== 98 && !is_truckindigital && (
        <li onClick={(event) => onActionClick(event, 'disconnectBankFeed')}>Disconnect Bank Feed</li>
      )}
      {!is_connected && id !== 98 && !is_truckindigital && (
        <li onClick={(event) => onActionClick(event, 'connectBankFeed')}>Connect Bank Feed</li>
      )}
      <li
        className={account_status ? 'delete-action' : 'green-action'}
        onClick={(event) => onActionClick(event, 'changeStatus')}
      >
        {account_status
          ? [2, 3].includes(system_account_type)
            ? 'Deactivate Account'
            : 'Make Inactive'
          : 'Make Active'}
      </li>
    </ThreeDotActions>
  );
};

export default AccountActions;
