import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as UnexcusedIcon } from 'assets/icons/createShipment/unexcused.svg';
import { ReactComponent as BillableMoveIcon } from 'assets/icons/createShipment/billableMove.svg';
import { Typography } from 'components/Typography';
import { formatNumber, getAlphabet, palette } from 'utils/constants';
import { SCHEDULED_DATE_TYPE_OBJ } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import OverLayTriggerHoverWrapper from 'components/OverLayTriggerHoverWrapper/OverLayTriggerHoverWrapper';
import Tooltip from 'common/Tooltip';
import { CustomerGeneral } from 'Api/Customers';
import { SWrapper } from './ShipmentInfo.styles';

const getStopInfo = (type) => {
  const TYPE_STOPS = {
    1: { type: ' : PICK UP', color: palette.green500, title: 'Pick up' },
    2: { type: ' : DELIVERY', color: palette.red500, title: 'Delivery' },
    3: { type: ' : WAYPOINT', color: palette.blueText, title: 'Waypoint' },
  };
  return TYPE_STOPS[type];
};

const getSlaveShipmentId = (input) => {
  if (input) {
    const numbers = input.split('-').map(Number);
    return numbers[1] - 1;
  }
  return 0;
};

const ShipmentInfo = ({ shipment }) => {
  const navigate = useNavigate();
  const { shipment_id, shipment_billing, shipment_stops } = shipment;
  const [customerInfo, setCustomerInfo] = useState(null);

  const getCustomerInfo = async () => {
    try {
      const { data } = await CustomerGeneral({ id: shipment_billing[0].billing_customer.id });
      setCustomerInfo(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  return (
    <SWrapper>
      <div className='info-items-wrapper'>
        <div className='info-wrapper'>
          <div className='info-header'>
            <Typography variant='button2' style={{ color: palette.gray900 }}>
              Shipment {shipment_id}
            </Typography>
          </div>
          <div className='info-box'>
            {shipment_billing.length > 1 ? (
              <OverLayTriggerHoverWrapper
                overlay={shipment_billing.map((el, i) => (
                  <Typography
                    key={el?.id || i}
                    as='p'
                    variant='s2'
                    style={{ margin: 0, whiteSpace: 'nowrap', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/customer-profile/${el?.billing_customer?.id}`);
                    }}
                  >
                    {el?.billing_customer?.company_name}
                  </Typography>
                ))}
              >
                <div className='multi-customer'>
                  <Typography variant='s1' style={{ color: palette.gray900 }}>
                    Multi-Customer LTL
                  </Typography>
                </div>
              </OverLayTriggerHoverWrapper>
            ) : (
              <div className='d-flex flex-column'>
                <Typography variant='s1' style={{ color: palette.gray900 }}>
                  {shipment_billing[0].billing_customer.company_name}
                </Typography>
                <Typography variant='b2' style={{ color: palette.gray700 }}>
                  {customerInfo?.address1 && `${customerInfo?.address1},`}{' '}
                  {customerInfo?.city?.name && `${customerInfo?.city.name},`} {customerInfo?.state?.short_name}{' '}
                  {customerInfo?.zipcode}
                </Typography>
              </div>
            )}
            <Typography variant='c1' style={{ color: palette.gray900 }}>
              ${formatNumber(shipment_billing.reduce((acc, cur) => acc + cur.total_amount, 0))}
            </Typography>
          </div>
        </div>

        {shipment_stops.map((item, index) => {
          const { stop_point, bill_type, scheduled_date, from, to, scheduled_type, id } = item;
          const stopInfo = getStopInfo(item.stop_point_type);
          return (
            <div className='info-wrapper' key={id}>
              <div className='info-header'>
                <Typography variant='c3' style={{ color: stopInfo.color }}>
                  STOP {getAlphabet(index)}
                  {stopInfo.type}
                </Typography>
                {shipment_billing.length > 1 && (
                  <Typography variant='button1' style={{ color: palette.gray900, marginLeft: '24px' }}>
                    {
                      shipment_billing?.[getSlaveShipmentId(item?.slave_stop?.shipment_id)]?.billing_customer
                        ?.company_name
                    }
                  </Typography>
                )}
              </div>
              <div className='info-box'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center gap-2'>
                    <Typography variant='s1' style={{ color: palette.gray900 }}>
                      {stop_point.location_name}
                    </Typography>
                    <Tooltip title={Number(bill_type) === 1 ? 'Billable Move' : 'Not Billable Move'}>
                      {Number(bill_type) === 1 ? <BillableMoveIcon /> : <UnexcusedIcon />}
                    </Tooltip>
                  </div>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {stop_point.address1 && `${stop_point.address1},`}{' '}
                    {stop_point.city?.name && `${stop_point.city.name},`} {stop_point.state?.short_name}{' '}
                    {stop_point.zipcode}
                  </Typography>
                </div>
                <Typography variant='c1' style={{ color: palette.gray900 }}>
                  {scheduled_date && `${scheduled_date},`} {from && `${from}`}{' '}
                  {to && +scheduled_type === 3 && ` to ${to}`}{' '}
                  {`(${SCHEDULED_DATE_TYPE_OBJ?.[scheduled_type]?.shortName})`}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </SWrapper>
  );
};

export default ShipmentInfo;
