import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowDown.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkBoxDark.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/createShipment/subRight.svg';
import Chip from 'common/Chip';
import { SimpleLoader } from 'common/Loader';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { useAuth } from 'context/auth.context';
import useShowToaster from 'hooks/useShowToaster';
import { ownershipOptions } from 'pages/CompanySettings/pagesComponents/Equipment/Equipment.data';
import { applyPolicyToEquipmentType, getAppliedEquipmentTypes, removePolicyFromEquipmentType } from 'Api/Insurance';
import { getEquipmentTypes, getVehicleTypes } from 'Api/Equipment';
import { validationSchema } from './validationSchema';
import { SActions, SMergedInputs, STable } from './AppliedEquipment.styles';

const ApplyEquipment = ({ policyType }) => {
  const showToaster = useShowToaster();
  const { value: userData } = useAuth();
  const [appliedEquipment, setAppliedEquipment] = useState(null);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [typesToDelete, setTypesToDelete] = useState(null);

  const filteredTypes = useMemo(() => {
    return equipmentTypes.filter(
      (item) =>
        appliedEquipment?.['1']?.data?.every((i) => i.equipment_type_id !== item.id) ||
        appliedEquipment?.['2']?.data?.every((i) => i.equipment_type_id !== item.id) ||
        appliedEquipment?.['3']?.data?.every((i) => i.equipment_type_id !== item.id)
    );
  }, [equipmentTypes, appliedEquipment]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const requestBodies = [];

      if (values.ownership.id === 4) {
        requestBodies.push({
          policy_name: policyType,
          ownership: 1,
          equipment_type_id: values.equipment.map((i) => i.id),
        });
        requestBodies.push({
          policy_name: policyType,
          ownership: 2,
          equipment_type_id: values.equipment.map((i) => i.id),
        });
        requestBodies.push({
          policy_name: policyType,
          ownership: 3,
          equipment_type_id: values.equipment.map((i) => i.id),
        });
      } else {
        requestBodies.push({
          policy_name: policyType,
          ownership: values.ownership.id,
          equipment_type_id: values.equipment.map((i) => i.id),
        });
      }

      const promises = requestBodies.map((body) => {
        return applyPolicyToEquipmentType(body);
      });

      await Promise.all(promises);
      showToaster({ type: 'success', message: 'Equipment type(s) have been successfully added to policy!' });
      getAppliedEquipment();
      resetForm();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, resetForm } = useForm({
    initialValues: { ownership: null, equipment: [] },
    onSubmit,
    validationSchema,
  });

  const getAppliedEquipment = async () => {
    try {
      const { data } = await getAppliedEquipmentTypes({ policy_name: policyType });
      const result = {
        1: { label: 'COMPANY', expanded: appliedEquipment?.['1']?.expanded || true, data: [] },
        2: { label: 'LEASED', expanded: appliedEquipment?.['2']?.expanded || true, data: [] },
        3: { label: 'OWNER OPERATOR', expanded: appliedEquipment?.['3']?.expanded || true, data: [] },
      };

      (data || []).forEach((item) => {
        if (result[item.ownership]) {
          result[item.ownership].data.push(item);
        }
      });

      setAppliedEquipment(result);
    } catch (e) {
      // Do nothing
    }
  };

  const getTypes = async () => {
    try {
      const { data } = await (['physical_insurance'].includes(policyType)
        ? getEquipmentTypes({}, userData.user?.customer?.dot)
        : getVehicleTypes());
      setEquipmentTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const removeEquipment = async () => {
    try {
      setLoadingDelete(true);
      await Promise.all(typesToDelete.map((item) => removePolicyFromEquipmentType(item.id)));
      showToaster({ type: 'success', message: 'Equipment type has been successfully removed from policy!' });
      setTypesToDelete(null);
      getAppliedEquipment();
      resetForm();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onDelete = (equipmentTypes) => {
    setTypesToDelete(equipmentTypes);
  };

  useEffect(() => {
    getAppliedEquipment();
  }, [policyType]);

  useEffect(() => {
    getTypes();
  }, []);

  return (
    <div style={{ width: '564px' }}>
      <div className='d-flex align-items-center gap-3'>
        <SMergedInputs>
          <div>
            <Autocomplete
              width='170px'
              name='ownership'
              className='left-autocomplete'
              placeholder='Select Ownership'
              options={ownershipOptions}
              value={values.ownership}
              onChange={(e, val) => handleChange('ownership', val)}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              error={touchedErrors.ownership}
            />
          </div>
          <div>
            <Autocomplete
              multiple
              limitTags={2}
              width='350px'
              name='equipment'
              labelKey='title'
              className='right-autocomplete'
              placeholder={values.equipment.length ? '' : 'Select Equipment Types'}
              options={filteredTypes}
              value={values.equipment}
              onChange={(e, val) => handleChange('equipment', val)}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              error={touchedErrors.equipment}
            />
          </div>
        </SMergedInputs>
        <SActions>
          {loading ? (
            <SimpleLoader loading={loading} />
          ) : (
            <CustomButton
              type='primary'
              leftIcon={<CheckIcon fill={palette.white} />}
              styleButton={{ padding: '7px 8px', margin: 0 }}
              onClick={handleSubmit}
              disabled={loading || !values.ownership || !values.equipment?.length}
            />
          )}
        </SActions>
      </div>
      <div className='mt-2'>
        {appliedEquipment &&
          Object.keys(appliedEquipment).map(
            (key) =>
              !!appliedEquipment[key]?.data?.length && (
                <div className='mb-4' key={key}>
                  <div className='d-flex justify-content-between align-items-center mb-2 ps-2 pe-2'>
                    <div
                      className='d-flex align-items-center gap-2 pointer'
                      onClick={() =>
                        setAppliedEquipment((prevState) => ({
                          ...prevState,
                          [key]: { ...prevState[key], expanded: !prevState[key].expanded },
                        }))
                      }
                    >
                      <Chip label={appliedEquipment[key].label} />
                      <ArrowIcon
                        fill={palette.gray500}
                        style={{ transform: appliedEquipment[key].expanded ? 'rotate(-90deg)' : '' }}
                      />
                    </div>
                    <SActions>
                      <span className='action-wrapper' onClick={() => onDelete(appliedEquipment[key].data)}>
                        <DeleteIcon width={9} height={9} fill={palette.red500} />
                      </span>
                    </SActions>
                  </div>
                  {appliedEquipment[key]?.expanded && (
                    <div className='d-flex ms-4'>
                      <div className='me-3'>
                        <ArrowRight />
                      </div>
                      <STable>
                        <tbody>
                          {appliedEquipment[key]?.data?.map((equipment) => (
                            <tr className='body-row' key={equipment.id}>
                              <td>
                                <Chip
                                  label={
                                    Number(equipment.ownership) === 1
                                      ? 'COMPANY'
                                      : Number(equipment.ownership) === 2
                                      ? 'LEASED'
                                      : 'OWNER OPERATOR'
                                  }
                                />
                              </td>
                              <td>
                                <div className='flex-grow-1'>
                                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                                    {equipment.equipment_type?.title}
                                  </Typography>
                                </div>
                              </td>
                              <td>
                                <SActions>
                                  <span className='action-wrapper' onClick={() => onDelete([equipment])}>
                                    <DeleteIcon width={9} height={9} fill={palette.red500} />
                                  </span>
                                </SActions>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </STable>
                    </div>
                  )}
                </div>
              )
          )}
      </div>
      {!!typesToDelete && (
        <ConfirmationModal
          title='Equipment Types'
          text='Are you sure you want to remove this policy from all equipment using this policy?'
          open={!!typesToDelete}
          onClose={() => setTypesToDelete(null)}
          onConfirm={removeEquipment}
          disabled={loadingDelete}
        />
      )}
    </div>
  );
};

export default ApplyEquipment;
