import React, { useMemo } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { SFlexColumn } from '../Shipments.styles';

const Origin = ({ data }) => {
  const { use } = useTheme();
  const { formatDate } = useDateFormat();
  const origin = useMemo(() => data?.shipment_stops?.find((item) => item.stop_point_type === '1'), [data]);

  if (!origin) {
    return (
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        N/A
      </Typography>
    );
  }

  return (
    <SFlexColumn>
      <Typography variant='button2'>
        {formatDate(origin.scheduled_date)} {origin.from}
        {origin?.to && origin?.to !== origin.from && ` - ${origin.to}`}
      </Typography>
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        {origin?.stop_point?.location_name}
      </Typography>
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        {origin?.stop_point?.address1}
      </Typography>
      <Typography variant='b2' style={{ color: use(palette.gray700, palette.gray50) }}>
        {origin?.stop_point?.city?.name} {origin?.stop_point?.state?.short_name} {origin?.stop_point?.zipcode}
      </Typography>
    </SFlexColumn>
  );
};

export default Origin;
