import React from 'react';
import { Icons } from 'assets/icons';

const AddDriverSuccess = ({ DriversAddedmsgModalClose }) => {
  return (
    <div className='d-flex '>
      <div className=''>
        <div className='d-flex main-wrapper-eq-unreserved  align-item-center'>
          <span className='me-2'>
            <img src={Icons.success} alt='success' />
          </span>
          <div>
            <p className='eq-reserved ms-1 mb-0 '>Driver assigned successfully</p>
          </div>
        </div>
      </div>
      <div>
        <button className='eq-reserved-okay-msg ms-2' onClick={DriversAddedmsgModalClose}>
          Okay
        </button>
      </div>
    </div>
  );
};

export default AddDriverSuccess;
