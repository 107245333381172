import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'common/Pagination';
import PlanShipment from 'componentsV2/Planner/PlanShipment';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import { palette } from 'utils/constants';
import { GetSettingsShipments } from 'Api/CompanySettings';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { useAuth } from 'context/auth.context';
import TableBulkActions from 'components/TableBulkActions';
import TablePlannerRowEditChildes from 'components/TablePlaner/helpers/TablePlannerRowEditChildes';
import { useColumns } from './ShipmentsTable.data';

const ShipmentsTable = ({ data, getLaneData }) => {
  const navigate = useNavigate();
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 50 });
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [shipmentSettings, setShipmentSettings] = useState(null);
  const [rowCheckBoxes, setRowCheckBoxes] = useState([]);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [tablePlannerModalObj, setTablePlannerModalObj] = useState({});
  const hasBrokerage = useHasBrokerage();
  const { value: userData } = useAuth();

  const shipments = [
    ...(data?.shipment_lane?.dispatched_shipments || []).map((item) => ({ ...item, isDispatched: true })),
    ...(data?.shipment_lane?.shipments || []),
  ];

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters({ ...selectedFilters, page });
  };

  const getShipmentSettings = async () => {
    try {
      const { data } = await GetSettingsShipments();
      setShipmentSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onRowClick = (e, shipment, actionType) => {
    try {
      const userId = shipment?.shipment_reserve?.user?.id;
      const shipmentId = shipment?.shipment_id;
      const { status, pending_dispatch } = shipment;
      if (
        shipment.isDispatched ||
        shipment?.shipment_type === 'slave' ||
        shipment?.shipment_type === 'split-parent' ||
        (userData.user.id !== Number(userId) && !Number.isNaN(Number(userId)))
      ) {
        navigate(`/shipment/${shipmentId}`, { state: shipment });
      } else {
        if (pending_dispatch) {
          navigate(`/planner/pending-dispatch?id=${shipmentId}`);
        } else {
          if (status === 'Offered') {
            if (hasBrokerage) {
              navigate(`/offered-shipments/${shipmentId}`);
            } else {
              navigate(`/planner/offer?id=${shipmentId}`);
            }
          } else {
            if (!actionType && hasBrokerage) {
              if (
                !!shipmentSettings?.default_shipment_dispatch &&
                !!shipmentSettings.default_shipment_dispatch_option
              ) {
                navigate(
                  `/planner/plan?id=${shipmentId}&action=${
                    shipmentSettings.default_shipment_dispatch_option === 1 ? 'dispatch' : 'offer'
                  }`
                );
              } else {
                setSelectedShipment(shipment);
              }

              return;
            }
            navigate(
              actionType ? `/planner/plan?id=${shipmentId}&action=${actionType}` : `/planner/plan?id=${shipmentId}`
            );
          }
        }
      }
    } catch (e) {
      // Do nothing
    }
  };

  const tableRowCheckboxChange = (value) => {
    if (value === 'all') {
      const cond = rowCheckBoxes.includes('all');
      const all = cond ? [] : ['all'];

      !cond &&
        shipments.forEach((row) => {
          all.push(row?.id);
        });

      setRowCheckBoxes(all);
    } else {
      let newRowData;
      const activeTr = document.querySelector(`td[value='${value}']`)?.parentElement;
      if (!rowCheckBoxes.some((el) => el.shipment_id === value)) {
        if (activeTr) activeTr.style.background = palette.indigo0;
        newRowData = [...rowCheckBoxes, shipments.find((el) => el.shipment_id === value)];
        // const allChecked = arr.data.every((el) => newRowData.includes(el?.id));
        // !!allChecked && newRowData.push('all');
        setRowCheckBoxes(newRowData);
      } else {
        if (activeTr) activeTr.style.background = null;
        newRowData = rowCheckBoxes.filter((el) => el.shipment_id !== value);
        setRowCheckBoxes(newRowData);
      }
    }
  };

  const getBulkActions = () => {
    if (rowCheckBoxes.every((item) => (item.is_edi_api || item.manual_edi_api) && !item.accept_date)) {
      return [
        { id: 18, name: 'Accept', tab: 'Vehicle' },
        { id: 19, name: 'Reject', tab: 'Offer' },
      ];
    }
    return [
      { id: 15, name: 'Dispatch', tab: 'Vehicle' },
      { id: 16, name: 'Offer', tab: 'Offer' },
      { id: 17, name: 'Cancel Shipment', cancel: true },
    ];
  };

  const onClickBulkAction = (item, index, arr) => {
    const ids = arr.map((el) => el.shipment_id);
    const rowData = shipments.filter((shipment) => ids.includes(shipment.shipment_id));
    switch (item?.id) {
      case 15:
      case 16:
        if (arr.length === 1) {
          onRowClick(null, arr[0], true);
        } else {
          const queryParams = new URLSearchParams();
          ids.forEach((id) => {
            queryParams.append('id', id);
          });
          if (item.tab) {
            queryParams.set('tab', item.tab);
          }
          navigate(`/planner/plan?${queryParams.toString()}`);
        }
        break;
      case 17:
        setTablePlannerModalObj({ item, rowData });
        setShowBulkActions(false);
        break;
      default:
    }
  };

  const onSubmitPlannerRowEditChildes = (data) => {
    switch (data.lastData.item.id) {
      case 17:
        getLaneData();
        setRowCheckBoxes([]);
        break;
      default:
    }
  };

  useEffect(() => {
    getShipmentSettings();
  }, []);

  const columns = useColumns({ tableRowCheckboxChange, rowCheckBoxes });

  return (
    <div>
      <div className='mb-2'>
        <TableBulkActions
          title='Bulk Actions'
          typeBtn='primary'
          overlayStyle={{ width: 160 }}
          placement='bottom-start'
          styleContainer={{ marginTop: 8 }}
          showBulkActions={showBulkActions}
          setShowBulkActions={setShowBulkActions}
          options={getBulkActions()}
          conditionShow={rowCheckBoxes?.length > 0}
          onClickOption={(e, i) => onClickBulkAction(e, i, rowCheckBoxes)}
        />
      </div>
      <MaterialTableWrapper
        data={shipments}
        rowPerPage={shipments.length}
        style={{ backgroundColor: palette.white }}
        components={{
          Pagination: () =>
            Pagination({
              data: { data: shipments, total: shipments.length },
              rowPerPage: selectedFilters.itemsPerPage,
              onChangeRowPerPage,
              onPageChange,
              rowsPerPageOptions: [50, 100, 150],
            }),
        }}
        onRowClick={(e, row) => onRowClick(e, row)}
        columns={columns}
      />
      {!!selectedShipment && (
        <PlanShipment
          open={!!selectedShipment}
          onClose={() => setSelectedShipment(null)}
          shipment={selectedShipment}
          onNavigate={(type) => onRowClick(null, selectedShipment, type)}
        />
      )}
      <TablePlannerRowEditChildes data={tablePlannerModalObj} onSubmit={onSubmitPlannerRowEditChildes} />
    </div>
  );
};

export default ShipmentsTable;
