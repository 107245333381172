import { object, string } from 'yup';

export const validationSchema = object({
  equipment_type_id: string().required('Required').nullable(),
  length: string().when(['type', 'equipment_type_id'], {
    is: (type, equipment_type_id) => type === 'trailer' || [3, 11, 9, 4, 8, 5].includes(Number(equipment_type_id)),
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
});
