import jsPDF from 'jspdf';
import moment from 'moment';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { getCustomerInfo } from 'Api/JobPositions';
import { palette } from 'utils/constants';

const createSingleReport = (doc, pageWidth, report, company) => {
  const {
    address_details,
    compensation,
    ein,
    federal_tax,
    id,
    irs_account_number,
    name,
    state_tax,
    state_identification_number,
    tax_id,
    pay_period_start,
  } = report;

  const { company_name, address1, city, state, country, zip, phone_number } = company;

  for (let i = 0; i < 3; i++) {
    doc.addPage();

    const lineColors = i === 0 ? [235, 136, 161] : [188, 194, 206];
    const boxColors = i === 0 ? [250, 240, 243] : [233, 237, 245];
    const textColors = i === 0 ? palette.red700 : palette.gray900;

    doc.setFontSize(11);
    doc.setTextColor(palette.gray900);
    doc.setFont('Inter', 'normal', 400);
    if (i === 0) {
      doc.text(`${id}`, 28, 21);
    }
    doc.setDrawColor(...lineColors);
    doc.setTextColor(textColors);
    if (i !== 2) {
      doc.roundedRect(52, 18, 3.2, 3.2, 0.7, 0.7);
      doc.text('VOID', 58, 21);
    }
    doc.roundedRect(74, 18, 3.2, 3.2, 0.7, 0.7);
    doc.text('CORRECTED', 80, 21);
    if (i === 2) {
      doc.setFontSize(9);
      doc.text('(if checked)', 106, 21);
    }

    doc.line(8, 24, pageWidth - 8, 24);
    doc.line(80, 24, 80, 150);
    doc.line(160, 24, 160, 150);
    doc.line(120, 24, 120, 67);
    doc.line(120, 50, 160, 50);

    doc.setFontSize(9);
    doc.setTextColor(textColors);
    doc.setFont('Inter', 'normal', 400);
    doc.text(
      'PAYER’S name, street address, city or town, state or province, country, ZIP or foreign postal code, and' +
        ' telephone no.',
      8,
      28,
      { lineHeightFactor: 1.4, maxWidth: 68 }
    );

    doc.setFontSize(10);
    doc.text(company_name, 8, 43);
    doc.text(address1, 8, 48);
    doc.text(`${city?.name}, ${state?.short_name || state?.name} ${zip}`, 8, 53);
    doc.text(`${country?.sortname} - Phone: ${formatPhoneNumberIntl(phone_number)}`, 8, 58);

    doc.setFillColor(...boxColors);
    doc.rect(80.2, 24.2, 39.6, 43, 'F');

    doc.setFontSize(9);
    doc.text('OMB No.1545-0116', 125, 28);
    doc.setFontSize(10);
    doc.text('Form', 124, 36);
    doc.setFontSize(12);
    doc.setFont('Inter', 'normal', 700);
    doc.text('1099-NEC', 134, 36);
    doc.setFontSize(9);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`(Rev. ${moment().format('MMMM')} ${moment().format('YYYY')})`, 125, 44);
    doc.text('For calendar year', 127, 55);
    doc.text(`20   ${moment(pay_period_start).year() % 100}`, 134, 62);
    doc.line(140, 63, 150, 63);
    doc.setFontSize(12);
    doc.setFont('Inter', 'normal', 700);
    doc.text('Nonemployee Compensation', pageWidth - 8, 45, { maxWidth: 40, align: 'right' });

    doc.line(8, 67, pageWidth - 8, 67);
    doc.line(8, 84, 160, 84);

    // Second row
    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`PAYER'S TIN`, 8, 73);
    doc.text(`RECIPIENT’S TIN`, 50, 73);
    doc.setFont('Inter', 'normal', 700);
    doc.text('1', 82, 73);
    doc.setFont('Inter', 'normal', 400);
    doc.text('Nonemployee compensation', 85, 73);
    doc.setFontSize(10);
    doc.text(`${ein}`, 8, 79);
    doc.text(`${tax_id}`, 50, 79);
    doc.text(`$ ${(compensation || 0).toFixed(2)}`, 82, 79);

    doc.setFontSize(11);
    doc.setFont('Inter', 'normal', 700);
    doc.text(`Copy ${i === 0 ? 'A' : '1'}`, pageWidth - 8, 73, { align: 'right' });
    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 400);
    if (i === 0) {
      doc.text('For Internal Revenue Service Center File with Form 1096.', pageWidth - 8, 79, {
        maxWidth: 40,
        align: 'right',
      });
      doc.text(
        'For Privacy Act and Paperwork Reduction Act Notice, see the current General Instructions for Certain Information' +
          ' Returns.',
        pageWidth - 8,
        93,
        { maxWidth: 40, align: 'right' }
      );
    }
    if (i === 1) {
      doc.text('For State Tax Department', pageWidth - 8, 79, { maxWidth: 40, align: 'right' });
    }
    if (i === 2) {
      doc.text('For Recipient', pageWidth - 8, 79, { maxWidth: 40, align: 'right' });
      doc.text(
        'This is important tax information and is being furnished to the IRS. If you are required to file a return, a negligence penalty or other sanction may be imposed on you if this income is taxable and the IRS determines that it has not been reported.',
        pageWidth - 8,
        84,
        { maxWidth: 40, align: 'right' }
      );
    }

    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`RECIPIENT'S name`, 8, 90);
    doc.setFont('Inter', 'normal', 700);
    doc.text('2', 82, 90);
    doc.setFont('Inter', 'normal', 400);
    doc.text('Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale', 85, 90, {
      maxWidth: 65,
    });
    doc.roundedRect(154, 89, 3.2, 3.2, 0.7, 0.7);
    doc.line(80, 98, 160, 98);

    doc.setFontSize(10);
    doc.text(`${name}`, 8, 96, { maxWidth: 50 });
    doc.line(8, 104, 80, 104);

    doc.setFontSize(8);
    doc.text(`Street address (including apt. no.)`, 8, 109);
    doc.setFontSize(10);
    doc.text(`${address_details?.address}`, 8, 114);
    doc.line(8, 118, 80, 118);

    doc.setFontSize(8);
    doc.text(`City or town, state or province, country, and ZIP or foreign postal code`, 8, 123, { maxWidth: 65 });
    doc.setFontSize(10);
    doc.text(
      `${address_details?.city?.name}, ${address_details?.state?.short_name || address_details?.state?.name} ${
        address_details?.zip
      } - ${address_details?.country?.sortname}`,
      8,
      132
    );
    doc.line(8, 136, 80, 136);

    doc.setFontSize(8);
    doc.text(`Account number (see instructions)`, 8, 141);
    doc.setFontSize(10);
    doc.text(`${irs_account_number}`, 8, 146);
    doc.line(8, 150, pageWidth - 8, 150);
    doc.setFontSize(8);
    if (i === 0) {
      doc.line(55, 136, 55, 150);
      doc.text(`2nd TIN not.`, 59, 141);
      doc.roundedRect(65, 144, 3.2, 3.2, 0.7, 0.7);
    }

    doc.setFillColor(...boxColors);
    doc.rect(80.3, 98.2, 79.4, 16, 'F');
    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 700);
    doc.text('3', 82, 103);
    doc.setFont('Inter', 'normal', 400);
    doc.line(80, 114, 160, 114);

    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 700);
    doc.text('4', 82, 119);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`Federal income tax withheld`, 85, 119);
    doc.setFontSize(10);
    doc.text(`$ ${(federal_tax || 0).toFixed(2)}`, 82, 124);
    doc.line(80, 127, pageWidth - 8, 127);

    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 700);
    doc.text('5', 82, 132);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`State tax withheld`, 85, 132);
    doc.setFontSize(10);
    doc.text(`$ ${(state_tax || 0).toFixed(2)}`, 82, 137);
    doc.text(`$ ${(state_tax || 0).toFixed(2)}`, 82, 145);

    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 700);
    doc.text('6', 117, 132);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`State/Payer's state no`, 120, 132);
    doc.setFontSize(10);
    doc.text(`${state?.short_name || state?.name}     ${state_identification_number}`, 117, 137);

    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 700);
    doc.text('7', 162, 132);
    doc.setFont('Inter', 'normal', 400);
    doc.text(`State income`, 165, 132);
    doc.setFontSize(10);

    doc.line(115, 127, 115, 150);
    doc.setLineDashPattern([1], 1);
    doc.line(80, 140, pageWidth - 8, 140);

    doc.text('Form', 8, 155);
    doc.setFont('Inter', 'normal', 700);
    doc.text('1099-NEC', 18, 155);
    doc.setFont('Inter', 'normal', 400);
    doc.setFontSize(8);
    doc.text(`(Rev. ${moment().month() + 1}-${moment().year()})`, 36, 155);
    if (i === 0) {
      doc.text('Cat. No. 72590N', 60, 155);
    }
    if (i === 2) {
      doc.text('(keep for your records)', 58, 155);
    }
    doc.text('www.irs.gov/Form1099NEC', 90, 155);
    doc.text('Department of the Treasury - Internal Revenue Service', 130, 155);

    doc.setFontSize(10);
    doc.setFont('Inter', 'normal', 700);
    if (i === 0) {
      doc.text(
        'Do Not Cut or Separate Forms on This Page — Do Not Cut or Separate Forms on This Page',
        pageWidth / 2,
        162,
        { align: 'center' }
      );
    }
  }
};

const addUnderline = (doc, text, x, y, color = [79, 90, 237]) => {
  doc.setDrawColor(...color);
  const textWidth = doc.getTextWidth(text);
  doc.line(x, y, x + textWidth, y);
};

const createFooter = (doc, page = 1, totalPages = 1) => {
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth - 16, pageHeight - 2, { align: 'right' });
};

const text1 =
  'Copy A of this form is provided for informational purposes only. Copy A appears in red, similar to the official IRS form. The official printed version of Copy A of this IRS form is scannable, but the online version of it, printed from this website, is not. Do not print and file copy A downloaded from this website; a penalty may be imposed for filing with the IRS information return forms that can’t be scanned. See part O in the current General Instructions for';
const text2 =
  'Please note that Copy B and other copies of this form, which appear in black, may be downloaded and printed and used to satisfy the requirement to provide the information to the recipient.';

export const generatePDF = async (data, download, dateFormat) => {
  if (!data) {
    return;
  }

  const user = JSON.parse(localStorage.getItem('user'));
  const { data: company } = await getCustomerInfo(user.customer.dot);
  const { company_name } = company || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(20);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text(company_name, pageWidth / 2, 16, { align: 'center' });
  doc.setFontSize(10);
  doc.text('1099-NEC', pageWidth / 2, 22, { align: 'center' });

  doc.setFontSize(9);
  doc.setTextColor(palette.gray700);
  doc.setFont('Inter', 'normal', 400);
  doc.setDrawColor(70, 79, 96);
  doc.roundedRect(16, 19.3, 3, 3, 0.7, 0.7);
  doc.text('Corrected', 21, 22);
  doc.text(dateFormat.formatDateTime(), pageWidth - 16, 22, { align: 'right' });

  doc.setDrawColor(188, 194, 206);
  doc.line(16, 24, pageWidth - 16, 24);

  doc.setFontSize(18);
  doc.setTextColor(palette.red700);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Attention', pageWidth / 2, 36, { align: 'center' });

  doc.setFontSize(10);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 400);
  doc.text(text1, 16, 44, { lineHeightFactor: 1.5, maxWidth: pageWidth - 32 });

  doc.text('Certain Information Returns, available at', 16, 65);
  doc.setTextColor(palette.indigo500);
  doc.textWithLink('www.irs.gov/form1099', 80, 65, { url: 'www.irs.gov/form1099' });
  addUnderline(doc, 'www.irs.gov/form1099', 80, 66);
  doc.setTextColor(palette.gray900);
  doc.text(', for more information about penalties.', 115, 65);

  doc.text(text2, 16, 75, { lineHeightFactor: 1.5, maxWidth: pageWidth - 32 });

  doc.text(
    'To order official IRS information returns, which include a scannable Copy A for filing with the IRS and all other',
    16,
    90
  );
  doc.text('applicable copies of the form, visit', 16, 95);
  doc.setTextColor(palette.indigo500);
  doc.textWithLink('www.irs.gov/orderforms', 70, 95, { url: 'www.irs.gov/orderforms' });
  addUnderline(doc, 'www.irs.gov/orderforms', 70, 96);
  doc.setTextColor(palette.gray900);
  doc.text('. Click on', 107, 95);
  doc.setTextColor(palette.indigo500);
  doc.text('Employer and Information Returns', 123, 95);
  doc.setTextColor(palette.gray900);
  doc.text(`, and we'll`, 177, 95);
  doc.text(
    `mail you the forms you request and their instructions, as well as any publications you may order.`,
    16,
    100
  );

  doc.text(
    'Information returns may also be filed electronically using the IRS Filing Information Returns Electronically',
    16,
    110
  );
  doc.text('(FIRE) system (visit', 16, 115);
  doc.setTextColor(palette.indigo500);
  doc.textWithLink('www.irs.gov/FIRE', 48, 115, { url: 'www.irs.gov/FIRE' });
  addUnderline(doc, 'www.irs.gov/FIRE', 48, 116);
  doc.setTextColor(palette.gray900);
  doc.text(') or the IRS Affordable Care Act Information Returns (AIR) program', 76, 115);
  doc.text('(visit', 16, 120);
  doc.setTextColor(palette.indigo500);
  doc.textWithLink('www.irs.gov/AIR', 25, 120, { url: 'www.irs.gov/AIR' });
  addUnderline(doc, 'www.irs.gov/AIR', 25, 121);
  doc.setTextColor(palette.gray900);
  doc.text(').', 51, 120);

  doc.text('See IRS Publications', 16, 130);
  doc.setTextColor(palette.indigo500);
  doc.textWithLink('1141', 50, 130, { url: 'https://www.irs.gov/forms-pubs/about-publication-1141' });
  addUnderline(doc, '1141', 50, 131);
  doc.textWithLink('1167', 60, 130, { url: 'https://www.irs.gov/forms-pubs/about-publication-1167' });
  addUnderline(doc, '1167', 60, 131);
  doc.textWithLink('1179', 76, 130, { url: 'https://www.irs.gov/forms-pubs/about-publication-1179' });
  addUnderline(doc, '1179', 76, 131);
  doc.setTextColor(palette.gray900);
  doc.text(',', 58, 130);
  doc.text('and', 69, 130);
  doc.text('for more information about printing these tax forms.', 85, 130);

  // Report pages
  data.map((report) => {
    return createSingleReport(doc, pageWidth, report, company);
  });

  // Instructions for Recipient page
  doc.addPage();
  doc.setFontSize(12);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Instructions for Recipient', 16, 16);

  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 400);
  doc.text(
    'You received this form instead of Form W-2 because the payer did not consider you an employee and did' +
      ' not withhold income tax or social security and Medicare tax.',
    16,
    24,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.text(
    'If you believe you are an employee and cannot get the payer to correct' +
      'this form, report this amount on the line for “Wages, salaries, tips, etc.” of Form 1040, 1040-SR, or 1040-NR. You must also complete Form 8919 and attach it to your return. For more information, see Pub. 1779, Independent Contractor or Employee.',
    16,
    45,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.text(
    'If you are not an employee but the amount in this box is not selfemployment (SE) income (for example, it is income from a sporadic activity' +
      'or a hobby), report this amount on the “MasterAgreement income” line (on Schedule 1 (Form 1040)).',
    16,
    80,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.setFont('Inter', 'normal', 700);
  doc.text('Recipient’s taxpayer identification number (TIN).', 16, 107);
  doc.setFont('Inter', 'normal', 400);
  doc.text(
    'For your protection, this form may show only the last four digits of your TIN (social security number (SSN), individual taxpayer identification number (ITIN), adoption taxpayer identification number (ATIN), or employer identification number (EIN)). However, the issuer has reported your complete TIN to the IRS.',
    16,
    112,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.setFont('Inter', 'normal', 700);
  doc.text('Account number. ', 16, 144);
  doc.setFont('Inter', 'normal', 400);
  doc.text('May show an account or other', 46, 144);
  doc.text('unique number the payer assigned to distinguish your account.', 16, 149, {
    maxWidth: pageWidth / 2 - 20,
    lineHeightFactor: 1.4,
  });
  doc.setFont('Inter', 'normal', 700);
  doc.text('Box 1. ', 16, 161);
  doc.setFont('Inter', 'normal', 400);
  doc.text('Shows nonemployee compensation. If the', 28, 161);
  doc.text(
    'amount in this box is SE income, report it on Schedule C or F (Form 1040) if a sole proprietor, or on Form 1065 and Schedule K-1 (Form 1065) if a partnership, and the recipient/partner completes Schedule SE (Form 1040).',
    16,
    166,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );

  doc.setFont('Inter', 'normal', 700);
  doc.text('Note:', pageWidth / 2 + 8, 24);
  doc.setFont('Inter', 'normal', 400);
  doc.text('If you are receiving payments on which no', pageWidth / 2 + 18, 24);
  doc.text(
    'income, social security, and Medicare taxes are withheld, you should make estimated tax payments. See Form 1040-ES (or Form 1040-ES (NR)). Individuals must report these amounts as explained in these box 1 instructions. Corporations, fiduciaries, and partnerships must report these amounts on the appropriate line of their tax returns.',
    pageWidth / 2 + 8,
    29,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.setFont('Inter', 'normal', 700);
  doc.text('Box 2.', pageWidth / 2 + 8, 65);
  doc.setFont('Inter', 'normal', 400);
  doc.text('If checked, consumer products totaling $5,000', pageWidth / 2 + 20, 65);
  doc.text(
    'or more were sold to you for resale, on a buy-sell, a deposit-commission, or other basis. Generally, report any income from your sale of these products on Schedule C (Form 1040)..',
    pageWidth / 2 + 8,
    70,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.setFont('Inter', 'normal', 700);
  doc.text('Box 3.', pageWidth / 2 + 8, 91);
  doc.setFont('Inter', 'normal', 400);
  doc.text('Reserved for future use.', pageWidth / 2 + 20, 91);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Box 4.', pageWidth / 2 + 8, 97);
  doc.setFont('Inter', 'normal', 400);
  doc.text('Shows backup withholding. A payer must', pageWidth / 2 + 20, 97);
  doc.text(
    'backup withhold on certain payments if you did not give your TIN to the payer. See Form W-9, Request for Taxpayer Identification Number and Certification, for information on backup withholding. Include this amount on your income tax return as tax withheld.',
    pageWidth / 2 + 8,
    102,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.setFont('Inter', 'normal', 700);
  doc.text('Box 5-7.', pageWidth / 2 + 8, 128);
  doc.setFont('Inter', 'normal', 400);
  doc.text('State income tax withheld reporting boxes.', pageWidth / 2 + 23, 128);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Future developments.', pageWidth / 2 + 8, 134);
  doc.setFont('Inter', 'normal', 400);
  doc.text('For the latest information', pageWidth / 2 + 46, 134);
  doc.text(
    'about developments related to Form 1099-NEC and its instructions, such as legislation enacted after they were published, go to www.irs.gov/Form1099NEC.',
    pageWidth / 2 + 8,
    139,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.setFont('Inter', 'normal', 700);
  doc.text('Free File Program.', pageWidth / 2 + 8, 155);
  doc.setFont('Inter', 'normal', 400);
  doc.text('Go to www.irs.gov/FreeFile to see', pageWidth / 2 + 40, 155);
  doc.text(
    'if you qualify for no-cost online federal tax preparation, e-filing, and direct deposit or payment options.',
    pageWidth / 2 + 8,
    160,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );

  // Instructions for Payer page
  doc.addPage();
  doc.setFontSize(12);
  doc.setTextColor(palette.gray900);
  doc.setFont('Inter', 'normal', 700);
  doc.text('Instructions for Payer', 16, 16);

  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 400);
  doc.text('To complete Form 1099-NEC, use', 16, 24);
  doc.text('•', 18, 29);

  doc.text('The current General Instructions for Certain Information Returns, and', 20, 29, {
    maxWidth: pageWidth / 2 - 20,
    lineHeightFactor: 1.4,
  });
  doc.text('•', 18, 39);
  doc.text('The current Instructions for Forms 1099-MISC and 1099-NEC.', 20, 39, {
    maxWidth: pageWidth / 2 - 20,
    lineHeightFactor: 1.4,
  });
  doc.text('To order these instructions and additional forms, go to www.irs.gov/EmployerForms.', 16, 49, {
    maxWidth: pageWidth / 2 - 20,
    lineHeightFactor: 1.4,
  });
  doc.setFont('Inter', 'normal', 700);
  doc.text('Caution:', 16, 59);
  doc.setFont('Inter', 'normal', 400);
  doc.text('Because paper forms are scanned during', 32, 59);
  doc.text(
    'processing, you cannot file certain Forms 1096, 1097,1098, 1099, 3921, or 5498 that you print from the' +
      ' IRS website.',
    16,
    64,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );

  doc.setFont('Inter', 'normal', 700);
  doc.text('Filing and furnishing.', pageWidth / 2 + 8, 24);
  doc.setFont('Inter', 'normal', 400);
  doc.text('For filing and furnishing', pageWidth / 2 + 45, 24);
  doc.text(
    'instructions, including due dates, and to request filing or furnishing extensions, see the current General Instructions for Certain Information Returns.',
    pageWidth / 2 + 8,
    29,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );
  doc.setFont('Inter', 'normal', 700);
  doc.text('Need help?', pageWidth / 2 + 8, 44);
  doc.setFont('Inter', 'normal', 400);
  doc.text('If you have questions about reporting on', pageWidth / 2 + 28, 44);
  doc.text(
    'Form 1099-NEC, call the information reporting customer service site toll free at 866-455-7438 or 304-263-8700 (not toll free). Persons with a hearing or speech disability with access to TTY/TDD equipment can call 304-579-4827 (not toll free).',
    pageWidth / 2 + 8,
    49,
    { maxWidth: pageWidth / 2 - 20, lineHeightFactor: 1.4 }
  );

  const pagesCount = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < pagesCount; i++) {
    doc.setPage(i);
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter(doc, currentPage, pagesCount);
  }

  const url = doc.output('datauristring', { filename: '1099-NEC' });
  const blob = doc.output('blob', { filename: '1099-NEC' });

  if (download) {
    doc.save(`1099-NEC.pdf`);
  }
  return { blob, url };
};
