import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Header from 'pages/Reports/ReportDetails/shared/Header';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import DateRangePicker from 'common/DateRangePicker';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import { getAllVehicles } from 'Api/Map';
import { getStates } from 'Api/EquipmentImport';
import FeatureNotAvailable from 'pages/CardManagement/FeatureNotAvailable';
import LinkTransaction from 'pages/CardManagement/Transactions/components/LinkTransaction';
import { getAllTransactions, getDriverStaffList, getTransactionCategories } from 'Api/CardManagement';
import PageWrapper from '../shared/PageWrapper';
import { useColumns } from './cardTransactions.data';
import { generatePDF } from './generatePdf';
import { STableFilters, STableWrapper } from '../ReportDetails.styles';
import { getPdfData, INITIAL_DATE_RANGE, initialFilters } from './constats';

const CardTransactions = () => {
  const controller = useRef(new AbortController());
  const { use } = useTheme();
  const dateFormat = useDateFormat();

  /** LOADINGS * */
  const [loading, setLoading] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [isPreparingCsvOrPdf, setIsPreparingCsvOrPdf] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingVehicle, setLoadingVehicle] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  /** LOADINGS * */

  /** DATA * */
  const [transactions, setTransactions] = useState({ data: [], total_amount: 0 });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [statesData, setStatesData] = useState([]);

  /** DATA * */

  /** FILTER * */
  const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  /** FILTER * */

  const [pdfUrl, setPdfUrl] = useState('');

  /** ENDPOINTS * */
  const GetUsersData = async () => {
    try {
      setLoadingUsers(true);
      const { data } = await getDriverStaffList();
      setUsersData(data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingUsers(false);
    }
  };

  const GetVehicles = async () => {
    try {
      setLoadingVehicle(true);
      const { data } = await getAllVehicles(null, null, null, null);
      setVehiclesData(data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingVehicle(false);
    }
  };

  const GetStates = async () => {
    try {
      setLoadingState(true);
      const { data } = await getStates({ country_id: [231, 38, 142] });
      setStatesData(data?.data || []);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingState(false);
    }
  };

  const GetTransactions = async (data) => {
    const { isExport = false, isPdf = false } = data || {};
    if (!isExport && !isPdf) {
      setLoading(true);
    } else {
      setIsPreparingCsvOrPdf(true);
    }

    try {
      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.page_size,
        start_date: dateRange.start ? moment(dateRange.start).format('YYYY-MM-DD') : undefined,
        end_date: dateRange.end ? moment(dateRange.end).format('YYYY-MM-DD') : undefined,
        export: isExport || isPdf ? true : undefined,
        ...(selectedFilters.selected_user && { card_holder: selectedFilters?.selected_user?.full_name }),
        ...(selectedFilters.selected_vehicle && { equipment_id: selectedFilters?.selected_vehicle?.id }),
        ...(selectedFilters.selected_state && { 'filter[state_id]': selectedFilters?.selected_state?.id }),
      };

      selectedFilters?.category?.forEach((item, index) => {
        params[`filter[category][${index}]`] = item?.id;
      });

      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const data = await getAllTransactions(params, controller?.current?.signal);
      if (isPdf) {
        return data;
      }

      if (isExport && typeof data === 'string') {
        downloadCsv(data);
      } else {
        setTransactions(data);
      }
      return data;
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
      setIsPreparingCsvOrPdf(false);
    }
  };

  /** ENDPOINTS * */

  const downloadCsv = async (string) => {
    try {
      const url = URL.createObjectURL(new Blob([string], { type: 'text/csv;charset=utf-8' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `card-transactions.csv`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    } catch (e) {
      // Do nothing
    }
  };

  const getTransactionCategory = async () => {
    setLoadingCategory(true);
    try {
      const { data } = await getTransactionCategories();
      setCategoryOptions(data);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingCategory(false);
    }
  };

  const onPdfClick = async (download) => {
    try {
      setIsPreparingCsvOrPdf(true);

      const string = await GetTransactions({ isPdf: true });
      const dataTransactions = getPdfData(string, {});
      const data = { transactions: dataTransactions, title: 'Card Transactions' };
      const isSpecific = !!selectedFilters.selected_user || !!selectedFilters.selected_vehicle;
      const { blob } = await generatePDF(data, download, dateRange, dateFormat, isSpecific);

      setIsPreparingCsvOrPdf(false);
      if (!download) {
        setPdfUrl(URL.createObjectURL(blob));
      }
    } catch (e) {
      setIsPreparingCsvOrPdf(false);
    }
  };

  useEffect(() => {
    GetTransactions();
  }, [selectedFilters, dateRange]);

  useEffect(() => {
    getTransactionCategory();
    GetUsersData();
    GetVehicles();
    GetStates();
  }, []);

  /** TABLE * */
  const [receipt, setReceipt] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openFeatureNotAvailable, setOpenFeatureNotAvailable] = useState(false);

  const onAssignTransaction = (provider) => {
    setSelectedProvider(provider);
    setOpenLinkModal(true);
  };

  const onViewReceipt = (receipt) => {
    setReceipt(receipt);
  };
  const columns = useColumns({
    setOpenFeatureNotAvailable,
    onAssignTransaction,
    onViewReceipt,
    totalAmount: transactions.total_amount,
  });
  /** TABLE * */

  return (
    <PageWrapper title='Card Transactions'>
      <STableWrapper>
        <STableFilters>
          <div className='flex-left'>
            <DateRangePicker
              type='allTime'
              dateRange={dateRange}
              setDateRange={setDateRange}
              initialRangeName='Last 30 days'
            />

            <Autocomplete
              width='250px'
              labelKey='equipment_id'
              disableClearable={false}
              loading={loadingVehicle}
              options={vehiclesData}
              placeholder='Select Vehicle...'
              value={selectedFilters.selected_vehicle}
              onChange={(e, val) => setSelectedFilters((p) => ({ ...p, selected_vehicle: val }))}
              getOptionLabel={(option) => (option ? `${option.equipment_id} ${option.equipment_type.title}` : '')}
              isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value || ''}
            />

            <Autocomplete
              width='250px'
              labelKey='full_name'
              disableClearable={false}
              loading={loadingUsers}
              options={usersData}
              placeholder='Select User...'
              value={selectedFilters.selected_user}
              onChange={(e, val) => setSelectedFilters((p) => ({ ...p, selected_user: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id || ''}
            />
            <Autocomplete
              width='250px'
              labelKey='name'
              disableClearable={false}
              loading={loadingState}
              options={statesData}
              placeholder='Select State...'
              value={selectedFilters.selected_state}
              onChange={(e, val) => setSelectedFilters((p) => ({ ...p, selected_state: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id || ''}
            />

            <Autocomplete
              multiple
              width='250px'
              labelKey='description'
              disableClearable={false}
              loading={loadingCategory}
              options={categoryOptions}
              placeholder='Select Category...'
              value={selectedFilters.category}
              onChange={(e, val) => setSelectedFilters((p) => ({ ...p, category: val }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>

          <CustomButton
            title='Generate Report'
            className='action-button'
            styleTitle={{ whiteSpace: 'nowrap' }}
            disabled={loading || isPreparingCsvOrPdf}
            onClick={() => onPdfClick(false)}
          />
        </STableFilters>
        <Divider margin='16px 0 36px' />
        <Header
          disabled={loading || isPreparingCsvOrPdf}
          onDownloadPdf={() => onPdfClick(true)}
          onCsvExport={() => GetTransactions({ isExport: true })}
        />
        <Divider margin='16px 0 16px' color='transparent' />
        {loading ? (
          <TablePreLoader styleWrapper={{ marginTop: 0 }} />
        ) : (
          <MaterialTableWrapper
            columns={columns}
            data={[...(transactions?.data || []), { footer: true }]}
            rowPerPage={selectedFilters.page_size}
            components={{ Pagination: () => null }}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
          />
        )}
      </STableWrapper>

      {openFeatureNotAvailable && (
        <FeatureNotAvailable
          open={openFeatureNotAvailable}
          onClose={() => setOpenFeatureNotAvailable(false)}
          text='This feature is only available with the Truckin Digital Cash Card.'
        />
      )}

      {openLinkModal && (
        <LinkTransaction
          open={openLinkModal}
          onClose={() => {
            setOpenLinkModal(false);
            setSelectedProvider(null);
          }}
          assignData={selectedProvider}
          onSuccess={() => {
            GetTransactions();
          }}
        />
      )}
      <ViewPdf title='Receipt' open={!!receipt} onClose={() => setReceipt(null)} pdfUrl={receipt} />
      {pdfUrl && <ViewPdf pdfUrl={pdfUrl} open={pdfUrl} title='Card Transactions' onClose={() => setPdfUrl(null)} />}
    </PageWrapper>
  );
};

export default CardTransactions;
