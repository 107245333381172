import React from 'react';
import HeaderStar from '../../HeaderStar';
import styles from '../../StopPoint/StopPointHeader/SecurityLogHeader.module.css';

const EquipmentStopPointHeader = () => {
  return (
    <div className={styles.stopPointHeader_wrapper}>
      <HeaderStar title='Equipment at Stop Points' />
    </div>
  );
};
export default EquipmentStopPointHeader;
