import styled from 'styled-components';

export const SWrapper = styled.div`
  padding: 24px 0 48px;
  min-height: 300px;
  position: relative;
`;

export const SRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 12px 0;
  border-bottom: 1px solid #e9edf5;
`;
