import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckRounded } from '../../../../assets/icons/check-rounded.svg';
import { palette } from '../../../../utils/constants';
import { Typography } from '../../../../components/Typography';
import { useTheme } from '../../../../context/themeContext';

const SWrapper = styled.div`
  display: flex;

  svg {
    margin-right: 8px;
    margin-top: 5px;
  }
`;

const Requirement = ({ text }) => {
  const { use } = useTheme();

  if (!text) {
    return null;
  }

  return (
    <SWrapper>
      <CheckRounded />
      <Typography variant='b1' style={{ color: use(palette.gray700, palette.gray200) }}>
        {text}
      </Typography>
    </SWrapper>
  );
};

export default Requirement;
