import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DatePicker from 'common/DatePicker';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, palette } from 'utils/constants';

export const groupInvoicesByCustomer = (invoices) => {
  const groupedInvoices = new Map();

  invoices.forEach((invoice) => {
    const customerId = invoice.customer.id;
    if (!groupedInvoices.has(customerId)) {
      groupedInvoices.set(customerId, {
        customer: invoice.customer,
        invoices: [],
        totalAmount: 0,
      });
    }
    const customerData = groupedInvoices.get(customerId);
    customerData.invoices.push(invoice);
    customerData.totalAmount += invoice.total_amount;
  });
  return Array.from(groupedInvoices.values());
};

export const initialValues = {
  bill_date: new Date(),
  payment_method: [],
  payable_to: 'own_company',
  send_email: false,
  print_mail: false,
  delivery_option: 0,
  mail_to_id: null,
  batch_due_date: null,
  email_to: [],
  is_batch: false,
  attach_statement: false,
  attach_fti: false,
  attach_noa_letter: false,
  batchDueDates: [],
  batchEmailTo: [],
  batchIds: [],
};

export const getUniqueCustomersFromInvoices = (invoices) => {
  const uniqueCustomersMap = new Map();
  invoices.forEach((item) => {
    uniqueCustomersMap.set(item.customer?.id, item);
  });
  const uniqueCustomersArray = [...uniqueCustomersMap.values()];

  return uniqueCustomersArray.map((el) => el.customer);
};

const Title = ({ title }) => {
  return (
    <Typography variant='overLine' style={{ color: palette.gray900 }}>
      {title}
    </Typography>
  );
};

export const useColumns = ({ onDateChange }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'customer',
      title: <Title title='BILL TO' />,
      render: (row) => <Typography variant='s2'>{row.customer.company_name}</Typography>,
    },
    {
      field: 'customer_billed_date',
      title: <Title title='BILLED DATE' />,
      render: (row) => (
        <Typography variant='s2'>{row.customer_billed_date ? formatDate(row.customer_billed_date) : '-'}</Typography>
      ),
    },
    {
      field: 'due_date',
      title: <Title title='DUE DATE' />,
      render: (row) => (
        <div style={{ paddingRight: '20px' }}>
          <DatePicker
            name='due_date'
            width={200}
            value={moment(row.due_date).toDate()}
            onChange={(val) => onDateChange(row.id, val)}
            minDate={moment(row.customer_billed_date).toDate()}
            dateFormat='MM/dd/yyyy'
            error
            required
          />
        </div>
      ),
    },
    {
      field: 'invoice_id',
      title: <Title title='INVOICE ID' />,
      render: (row) => <Typography variant='s2'>{row.invoice_id}</Typography>,
    },
    {
      field: 'reference_id',
      title: <Title title='REFERENCE ID' />,
      render: (row) => <Typography variant='s2'>{row.reference_id}</Typography>,
    },
    {
      field: 'total_charges',
      title: <Title title='TOTAL CHARGES' />,
      render: (row) => (
        <Typography variant='s2'>
          {currency}
          {formatNumber(row.total_amount)}
        </Typography>
      ),
    },
  ];
};

export const useUniqueCustomerColumns = ({ onDateChange }) => {
  const { formatDate } = useDateFormat();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'invoice_id',
      title: <Title title='INVOICE ID' />,
      render: (row) => <Typography variant='s2'>{row.invoice_id}</Typography>,
    },
    {
      field: 'customer_billed_date',
      title: <Title title='BILLED DATE' />,
      render: (row) => (
        <Typography variant='s2'>{row.customer_billed_date ? formatDate(row.customer_billed_date) : '-'}</Typography>
      ),
    },
    {
      field: 'due_date',
      title: <Title title='DUE DATE' />,
      render: (row) => (
        <div style={{ paddingRight: '20px' }}>
          <DatePicker
            name='due_date'
            width={200}
            value={moment(row.due_date).toDate()}
            onChange={(val) => onDateChange(row.id, val)}
            minDate={moment(row.customer_billed_date).toDate()}
            dateFormat='MM/dd/yyyy'
            error
            required
          />
        </div>
      ),
    },
    {
      field: 'reference_id',
      title: <Title title='REFERENCE ID' />,
      render: (row) => <Typography variant='s2'>{row.reference_id}</Typography>,
    },
    {
      field: 'total_charges',
      title: <Title title='TOTAL CHARGES' />,
      render: (row) => (
        <Typography variant='s2'>
          {currency}
          {formatNumber(row.total_amount)}
        </Typography>
      ),
    },
  ];
};
