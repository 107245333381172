import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import down from 'assets/icons/drivers/down.svg';
import { Typography } from 'components/Typography';
import CustomizedSnackbars from 'components/toast/Toast';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { isAllMetricsMatch, isDuplicateCargoInDelivery } from 'components/CreateShipment/helpers';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getAlphabet, palette } from 'utils/constants';
import { getEquipmentRequiredLength, getEquipmentRequiredTrailers, getEquipmentRequiredVehicles } from 'Api/Planner';
import { ShipmentContext } from '../../Layout';
import AddStopPointLTL from './AddStopPointLTL';
import OptimizeRoutePageLTL from './OptimizeRoutePageLTL';
import ScheduledWarning from '../../helpers/ShaduledWarning';
import MapCreate from '../../ShipmentStops/helpers/mapCreate';
import { getStopTemplateLTL } from '../../ShipmentStops/helpers/constants';
import {
  compareTimeDurations,
  formatTimeDiff,
  parseFlexibleTimeToMinutes,
} from '../../../TableShipments/BillOfLading/helpers/utils';
import { validationSchema } from './validationSchema';
import sm from '../../ShipmentStops/ShipmentStops.module.css';

const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === 'object') {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `[name='${fieldErrorNames[0].replace(/.\d+/g, (match) => {
        return `[${match.slice(1)}]`;
      })}']`
    );

    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);

  return null;
};

const ShipmentStopsLTL = () => {
  const showToaster = useShowToaster();
  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'error',
  });

  const controller = new AbortController();
  const [optimizeRoute, setOptimizeRoute] = useState(false);
  const [showScheduledWarning, setShowScheduledWarning] = useState(null);
  const [stops, setStops] = useState([]);
  const showWarningRef = useRef(false);

  const {
    setStep,
    onCloseModal,
    createShipmentData,
    updateShipmentData,
    stopPoints,
    getCustomerStopPoint,
    updateEquipmentRequired,
    totalData,
    getMilesTotal,
    updateEquipmentLengthData,
    billToData,
    optimizeLTLOrder,
    setOptimizeLTLOrder,
    mapDragRoute,
    setMapDragRoute,
    shipmentSettings,
  } = useContext(ShipmentContext);

  const { values: mapValues, handleChange: handleMapValuesChange } = useForm({
    initialValues: {
      route_type: 'practical',
      vehicle_type: 0,
      highway_only: 0,
      open_borders: 0,
      hazmat_id: 0,
      avoid_tolls: 0,
    },
  });

  function extractTime(stop) {
    const timeStr = moment(stop.endTime || stop.startTime).format('HH:mm');
    const [hours, minutes] = timeStr.split(':').map(Number);
    return new Date(`${moment(stop.scheduled_date).format('YYYY-MM-DD')} ${hours}:${minutes}`);
  }

  function calculateTimeDifference(data) {
    if (data.length < 1 || data[0].stops.length === 0 || data[data.length - 1].stops.length === 0) {
      return '0d 0h 0m';
    }

    const firstStop = data[0].stops[0];
    const lastStopArray = data[data.length - 1].stops;
    const lastStop = lastStopArray[lastStopArray.length - 1];

    const firstStopTime = extractTime(firstStop);
    const lastStopTime = extractTime(lastStop);
    const diffMinutes = (lastStopTime - firstStopTime) / 60000;

    return formatTimeDiff(diffMinutes);
  }

  useEffect(() => {
    if (totalData.time && optimizeRoute && Number(createShipmentData?.frequency) !== 2) {
      const duration = calculateTimeDifference(initialValue.shipmentsLegs);
      const allStops = initialValue.shipmentsLegs?.reduce((acc, cur) => [...acc, ...(cur.stops || [])], []);
      const lastStop = stopPoints.find((i) => Number(i.key) === Number(allStops[allStops.length - 1]?.stop_point_id));
      const minutes = parseFlexibleTimeToMinutes(totalData.time);
      const minutesSubtracted = Number(minutes || 0) - Number(lastStop?.average_waiting_time || 0);

      const showWarning = compareTimeDurations(duration, formatTimeDiff(minutesSubtracted));
      if (showWarning && !showWarningRef.current) {
        setShowScheduledWarning(initialValue.shipmentsLegs);
        showWarningRef.current = true;
      }
    }
  }, [totalData?.time, optimizeRoute, createShipmentData?.frequency]);

  const getMilesOptimize = useCallback(
    (stopPointsArr, values, mapValues) => {
      let equipment_required;
      values.shipmentsLegs.forEach((leg) => {
        for (let i = 0; i < leg.stops.length; i++) {
          const stop = leg.stops[i];
          if (+stop.stop_point_type === 1) {
            equipment_required = stop.equipment_type;
            break;
          }
        }
      });
      getMilesTotal({ stopPointsArr, equipment_required, mapValues });
    },
    [getMilesTotal, mapValues]
  );

  const getEquipmentLength = useCallback(() => {
    getEquipmentRequiredLength().then((res) => {
      if (res && res?.data) {
        const sortBySelectData = res.data?.map((el) => {
          return {
            key: el.id,
            label: `${el?.length} ${el?.unit}`,
            labelSelected: `${el?.length} ${el?.unit}`,
          };
        });

        updateEquipmentLengthData([...sortBySelectData]);
      }
    });
  }, [updateEquipmentLengthData]);

  const getEquipmentRequired = useCallback(async () => {
    return Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicles, trailers]) => {
        const data = [];

        if (vehicles?.data) {
          const addFlagVehicles = vehicles?.data.map((el) => {
            if ([1, 2, 3, 4, 5, 10].includes(+el.id)) {
              return {
                ...el,
                key: el.id,
                label: el.title,
                flag: 'vehicles',
                labelSelected: null,
                noLengthField: true,
                type: el?.vehicle_type_id,
              };
            }
            return {
              ...el,
              key: el.id,
              label: el.title,
              flag: 'vehicles',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagVehicles);
        }

        if (trailers?.data) {
          const addFlagTrailers = trailers?.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              flag: 'trailers',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagTrailers);
        }
        data.sort((a, b) => a.key - b.key);

        updateEquipmentRequired(data);
        return data;
      })
      .catch(() => {
        // Do nothing
      });
  }, [updateEquipmentRequired]);

  const validateDateTimeOrder = useCallback((legs) => {
    let isValidDate = { isValidDate: true };
    const allStops = [];
    legs.forEach((leg) => {
      leg.stops.forEach((stop) => {
        allStops.push(stop);
      });
    });

    let prevOnlyStartIndex = null;
    for (let i = 0; i < allStops.length; i++) {
      const prevStop = allStops[prevOnlyStartIndex];
      const currentStop = allStops[i];

      if (prevOnlyStartIndex !== null) {
        const date = prevStop?.scheduled_date;
        const startTime = prevStop?.startTime;

        const nextDate = currentStop?.scheduled_date;
        const nextStartTime = currentStop?.startTime;

        const prevDate = moment(`${date} ${startTime}`).toDate();
        const currentDateFull = moment(`${nextDate} ${nextStartTime}`).toDate();

        if (
          Number(createShipmentData?.frequency) === 2 ||
          (!moment(prevDate).isAfter(currentDateFull, 'minutes') &&
            !moment(prevDate).isSame(currentDateFull, 'minutes'))
        ) {
          isValidDate = { isValidDate: true };
        } else {
          const currentAlphabet = getAlphabet(prevOnlyStartIndex);
          const nextAlphabet = getAlphabet(i);
          return { isValidDate: false, currentAlphabet, nextAlphabet };
        }
      }
      prevOnlyStartIndex = i;
    }

    return isValidDate;
  }, []);

  const initialOrderOptimize = (stopsArr) => {
    const timeCorrected = [];
    stopsArr.forEach((stop) => {
      const { stop_point_type, startTime, scheduled_date } = stop;
      const timeOrder =
        stop_point_type === 2
          ? moment(`${scheduled_date} ${startTime}`, 'MM/DD/YYYY HH:mm').add(2, 'hour').format('MM/DD/YYYY HH:mm')
          : moment(`${scheduled_date} ${startTime}`, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
      timeCorrected.push({ ...stop, timeOrder });
    });

    timeCorrected.sort((a, b) => {
      const stopA = moment(a.timeOrder, 'MM/DD/YYYY HH:mm').toDate().getTime();
      const stopB = moment(b.timeOrder, 'MM/DD/YYYY HH:mm').toDate().getTime();
      return stopA - stopB;
    });

    return timeCorrected;
  };

  function validateBillType(data) {
    for (let i = 0; i < data.length; i++) {
      let hasStopPointType1 = false;
      let hasStopPointType2 = false;

      for (let j = 0; j < data[i].stops.length; j++) {
        if (+data[i].stops[j].stop_point_type === 1 && data[i].stops[j].bill_type) {
          hasStopPointType1 = true;
        }

        if (+data[i].stops[j].stop_point_type === 2 && data[i].stops[j].bill_type) {
          hasStopPointType2 = true;
        }
      }

      if (!hasStopPointType1 || !hasStopPointType2) {
        return false;
      }
    }

    return true;
  }

  const onClickOptimizeRoute = useCallback(
    (values, submitForm, touched, errors, validateForm) => {
      const { isValidDate, currentAlphabet, nextAlphabet } = validateDateTimeOrder(values.shipmentsLegs) || {};

      const lastStops = values.shipmentsLegs[values.shipmentsLegs.length - 1].stops.length;
      const stopPointsArr = [];
      const stopsArr = [];

      const hasTwoBillableMoves = validateBillType(values.shipmentsLegs);
      if (!hasTwoBillableMoves) {
        showToaster({
          type: 'error',
          message: 'At least one pickup billable move and at least one delivery billable move are required ',
        });
        return;
      }

      const hasSameMetrics = isAllMetricsMatch(values.shipmentsLegs);
      if (!hasSameMetrics) {
        showToaster({
          type: 'error',
          message: 'Please ensure that all stop points have the same metric types selected.',
        });
        return;
      }

      const hasDuplicateCargo = isDuplicateCargoInDelivery(values.shipmentsLegs);

      if (hasDuplicateCargo) {
        showToaster({
          type: 'error',
          message: 'Same cargo cannot be used in different delivery stops!',
        });
        return;
      }

      values.shipmentsLegs.forEach((leg, indexLeg) => {
        const { reference_id, customer_id } = createShipmentData.shipmentsBilling[indexLeg];
        const customer = billToData.find((el) => +el.id === +customer_id);
        leg.stops.forEach((stop, indexStop) => {
          const fSP = stopPoints.find((el) => Number(el?.key) === +stop.stop_point_id);
          fSP && stopPointsArr.push({ lat: fSP?.latitude, lng: fSP?.longitude, stopPoint: true, stopPointId: fSP.key });
          stopsArr.push({ ...stop, order: { shipments: indexLeg, stop: indexStop }, customer, reference_id });
        });
      });
      setStops(stopPointsArr);
      if (lastStops > 1) {
        submitForm();
        validateForm().then((e) => {
          const isValidCustom = Object.keys(e).length === 0;
          if (!isValidCustom) {
            showToaster({ type: 'error', message: 'Please fill all required fields!' });
          }

          if (!!isValidDate && !!isValidCustom) {
            setOptimizeRoute(true);
            getMilesOptimize(stopPointsArr, values, mapValues);
            setOptimizeLTLOrder(initialOrderOptimize(stopsArr));
          }
          !!isValidCustom &&
            !isValidDate &&
            setShowMessage({
              ...showMessage,
              visible: true,
              message: `Stop ${nextAlphabet} is scheduled before Stop ${currentAlphabet}!`,
            });
        });
      } else {
        setShowMessage({ ...showMessage, visible: true, message: 'leg length error' });
      }
    },
    [getMilesOptimize, showMessage, validateDateTimeOrder, stopPoints]
  );

  const onNext = (values) => {
    updateShipmentData({ ...createShipmentData, ...values });
  };

  const onDragEnd = (result, newItems, values) => {
    if (!result.destination) return;
    const items = Array.from(optimizeLTLOrder);
    const [renderData] = items.splice(result?.source?.index, 1);
    items.splice(result.destination.index, 0, renderData);
    setOptimizeLTLOrder([...items]);
    getMilesOptimize(mapDragRoute, values, mapValues);
  };

  const onMountCoords = (defaultCoords) => {
    const stops = defaultCoords?.map((cord) => {
      const { latitude, longitude, stopPoint, stopPointId } = cord || {};
      if (stopPoint) {
        return { lat: latitude, lng: longitude, stopPoint, stopPointId };
      }
      return { lat: latitude, lng: longitude };
    });
    defaultCoords.length > 0 && setMapDragRoute(stops);
  };

  const onChangeCoords = (coords, values) => {
    setMapDragRoute(coords?.stops);
    getMilesOptimize(coords?.stops, values, mapValues);
  };

  const initialValue = useMemo(() => {
    let initVal;
    const { shipmentsLegs, shipmentsBilling } = createShipmentData || {};

    if (Array.isArray(shipmentsLegs) && shipmentsLegs?.length > 0 && shipmentsLegs.length === shipmentsBilling.length) {
      initVal = shipmentsLegs;
    } else if (
      Array.isArray(shipmentsLegs) &&
      shipmentsLegs?.length > 0 &&
      shipmentsLegs.length < shipmentsBilling.length
    ) {
      const plusEmptyStopsCount = Number(shipmentsBilling?.length) - Number(shipmentsLegs?.length);
      const allLegsData = [...shipmentsLegs, ...Array(plusEmptyStopsCount).fill(0)].map((leg, index) => {
        if (leg === 0) {
          return {
            number: index + 1,
            stops: [
              { ...getStopTemplateLTL(1, Number(createShipmentData?.frequency) === 2) },
              { ...getStopTemplateLTL(2, Number(createShipmentData?.frequency) === 2) },
            ],
          };
        }
        return {
          number: index + 1,
          stops: leg.stops,
        };
      });
      initVal = [...allLegsData];
    } else {
      initVal = shipmentsBilling.map((_, index) => {
        return {
          number: index + 1,
          stops: [
            { ...getStopTemplateLTL(1, Number(createShipmentData?.frequency) === 2) },
            { ...getStopTemplateLTL(2, Number(createShipmentData?.frequency) === 2) },
          ],
        };
      });
    }

    return { shipmentsLegs: initVal };
  }, [createShipmentData]);

  const handleRouteValuesChange = (field, value, stops, values) => {
    handleMapValuesChange(field, value);
    getMilesOptimize(stops, values, { ...mapValues, [field]: value });
  };

  useEffect(() => {
    getCustomerStopPoint(controller.signal);
    getEquipmentLength();
    getEquipmentRequired().then();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className={sm.shipmentStops_wrapper}>
      <div style={{ width: '100%' }}>
        <div>
          {showMessage.visible && <CustomizedSnackbars showMessage={showMessage} setShowMessage={setShowMessage} />}
          <Formik
            initialValues={initialValue}
            onSubmit={onNext}
            validationSchema={validationSchema(
              Number(createShipmentData?.frequency),
              shipmentSettings?.allow_past_date
            )}
          >
            {({ values, submitForm, errors, touched, validateForm, setFieldValue, setValues, setFieldTouched }) => {
              const legsStopsCount = [];
              return (
                <Form>
                  <FieldArray
                    name='shipmentsLegs'
                    render={(arrayHelpers) => {
                      const { shipmentsLegs } = values;

                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {!optimizeRoute ? (
                            <div style={{ width: '100%' }}>
                              {shipmentsLegs.map((leg, index) => {
                                legsStopsCount.push(leg.stops.length);
                                return (
                                  <div key={leg.number} style={{ width: '100%' }}>
                                    <AddStopPointLTL
                                      leg={leg}
                                      errors={errors}
                                      values={values}
                                      touched={touched}
                                      indexPrefix={index}
                                      legs={shipmentsLegs}
                                      setValues={setValues}
                                      stopPoints={stopPoints}
                                      createShipmentData={createShipmentData}
                                      billToData={billToData}
                                      optimizeRoute={optimizeRoute}
                                      setFieldValue={setFieldValue}
                                      legsStopsCount={legsStopsCount}
                                      setFieldTouched={setFieldTouched}
                                      arrayHelpersPrefix={arrayHelpers}
                                      namePrefix={`shipmentsLegs[${index}]`}
                                      setOptimizeRoute={(v) => setOptimizeRoute(v)}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <MapCreate
                              legs={shipmentsLegs}
                              stopPoints={stopPoints}
                              orderStops={optimizeLTLOrder}
                              onMountCoords={onMountCoords}
                              onChangeCoords={(c) => onChangeCoords(c, values)}
                              mapValues={mapValues}
                            >
                              <div className={sm.createMapWidget} style={{ background: 'rgba(255, 255, 255, 0.88)' }}>
                                <div className='d-flex align-items-center mb-1'>
                                  <div className='d-flex align-items-center'>
                                    <p
                                      className='dispatch-vehicle-check-text'
                                      style={{
                                        marginLeft: 0,
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      {mapValues.route_type}
                                    </p>
                                    <Dropdown className='d-inline mx-2' menuPortalTarget={document.body}>
                                      <Dropdown.Toggle
                                        id='dropdown-autoclose-outside'
                                        menuPortalTarget={document.body}
                                        style={{
                                          border: 'none',
                                          outline: 'none',
                                          background: 'none',
                                          boxShadow: 'none',
                                          marginLeft: 0,
                                          padding: 0,
                                        }}
                                      >
                                        <img src={down} alt='down' />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        menuPortalTarget={document.body}
                                        style={{ backgroundColor: palette.white }}
                                      >
                                        <Dropdown.Item
                                          className='outofserviceDropDownItemsList'
                                          onClick={() =>
                                            handleRouteValuesChange('route_type', 'practical', stops, values)
                                          }
                                          style={{ color: palette.dark800 }}
                                        >
                                          Practical
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          className='outofserviceDropDownItemsList'
                                          onClick={() =>
                                            handleRouteValuesChange('route_type', 'shortest', stops, values)
                                          }
                                          style={{ color: palette.dark800 }}
                                        >
                                          Shortest
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className='outofserviceDropDownItemsList'
                                          onClick={() =>
                                            handleRouteValuesChange('route_type', 'fastest', stops, values)
                                          }
                                          style={{ color: palette.dark800 }}
                                        >
                                          Fastest
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <p className='dispatch-vehicle-check-text'>Open Borders</p>
                                  <CustomCheckbox
                                    checked={!!mapValues.open_borders}
                                    style={{ marginLeft: '0.5rem' }}
                                    onChange={(checked) =>
                                      handleRouteValuesChange('open_borders', Number(checked), stops, values)
                                    }
                                  />
                                  <p className='dispatch-vehicle-check-text'>Highway Only</p>
                                  <CustomCheckbox
                                    checked={!!mapValues.highway_only}
                                    onChange={(checked) =>
                                      handleRouteValuesChange('highway_only', Number(checked), stops, values)
                                    }
                                    style={{ marginLeft: '0.5rem' }}
                                  />
                                  <p className='dispatch-vehicle-check-text'>HazMat</p>
                                  <CustomCheckbox
                                    checked={!!mapValues.hazmat_id}
                                    disabled
                                    style={{ marginLeft: '0.5rem' }}
                                  />
                                  <p className='dispatch-vehicle-check-text'>Avoid Tolls</p>
                                  <CustomCheckbox
                                    checked={!!mapValues.avoid_tolls}
                                    onChange={(checked) =>
                                      handleRouteValuesChange('avoid_tolls', Number(checked), stops, values)
                                    }
                                    style={{ marginLeft: '0.5rem' }}
                                  />
                                </div>
                              </div>
                            </MapCreate>
                          )}
                        </div>
                      );
                    }}
                  />
                  <div className={sm.shipments_stop_footer} style={{ backgroundColor: palette.white }}>
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      styleButton={{ padding: '6px 12px' }}
                      styleTitle={{ fontSize: 14 }}
                      onClick={onCloseModal}
                    />
                    <div className={sm.footer_right}>
                      <CustomButton
                        type='secondary'
                        title='Back'
                        styleButton={{ padding: '6px 12px' }}
                        styleTitle={{ fontSize: 14 }}
                        onClick={() => {
                          updateShipmentData({ ...createShipmentData, ...values });
                          if (optimizeRoute) {
                            setOptimizeRoute(false);
                            showWarningRef.current = false;
                          } else {
                            setStep((p) => p - 1);
                          }
                        }}
                      />
                      {!optimizeRoute ? (
                        <CustomButton
                          type='primary'
                          disabled={!stopPoints?.length}
                          title='Optimize Route'
                          styleButton={{ padding: '6px 12px' }}
                          styleTitle={{ fontSize: 14 }}
                          onClick={() => onClickOptimizeRoute(values, submitForm, touched, errors, validateForm)}
                          buttonProps={{
                            type: 'submit',
                          }}
                        />
                      ) : (
                        <CustomButton
                          type='primary'
                          title='Next step'
                          styleButton={{ padding: '6px 12px' }}
                          styleTitle={{ fontSize: 14 }}
                          onClick={() => setStep((p) => p + 1)}
                        />
                      )}
                    </div>
                  </div>
                  <ScrollToFieldError />
                  {optimizeRoute && (
                    <>
                      <DragDropContext onDragEnd={(r, i) => onDragEnd(r, i, values)}>
                        <Droppable droppableId='optimize_page'>
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              <div className={sm.optimize_stops_container} style={{ height: '70vh' }}>
                                <OptimizeRoutePageLTL data={optimizeLTLOrder} />
                              </div>
                              <div style={{ height: 0, width: 0 }}>{provided.placeholder}</div>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <div className={sm.optimize_fixed_bottom_LTL} style={{ backgroundColor: palette.white }}>
                        <Typography variant='c2' style={{ color: palette.goldDark }}>
                          System automatically optimizes based on date and time. Please be sure to drag and drop order
                          if necessary.
                        </Typography>

                        <div className={sm.total_miles_times_wrapper}>
                          <div>
                            <Typography variant='s2' style={{ fontWeight: 600 }}>
                              Route Duration:
                            </Typography>
                            <Typography
                              variant='s2'
                              style={{
                                marginLeft: 5,
                                color: palette.gray700,
                              }}
                            >
                              {totalData?.time}
                            </Typography>
                          </div>
                          <div>
                            <Typography variant='s2' style={{ fontWeight: 600 }}>
                              Total Miles:
                            </Typography>
                            <Typography
                              variant='s2'
                              style={{
                                marginLeft: 5,
                                color: palette.gray700,
                              }}
                            >
                              {totalData?.miles && Math.round(totalData?.miles)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
        {showScheduledWarning && (
          <ScheduledWarning
            open={showScheduledWarning}
            data={showScheduledWarning}
            duration={totalData?.time}
            onClose={() => {
              setOptimizeRoute(false);
              setShowScheduledWarning(null);
              showWarningRef.current = false;
            }}
            onSuccess={() => {
              setShowScheduledWarning(null);
            }}
            stopPoints={stopPoints}
          />
        )}
      </div>
    </div>
  );
};

export default ShipmentStopsLTL;
