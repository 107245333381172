import { object, string, date, number, array } from 'yup';

export const validationSchema = object().shape({
  payment_method: array()
    .nullable()
    .test('at-least-one-selected', 'At least one payment method is required', function () {
      const { payment_method } = this.parent;
      return !!payment_method.length;
    }),
  payable_to: string().required('Payable to is required'),
  email_to: array().when(['send_email', 'payable_to'], {
    is: (send_email, payable_to) => send_email && payable_to === 'factoring_company',
    then: array()
      .of(object().shape({ contact_email: string().required('Send to email is required').nullable() }))
      .min(1, 'Send to email is required')
      .required('Send to email is required')
      .nullable(),
    otherwise: array().nullable(),
  }),
  batch_due_date: date().when(['is_batch', 'payable_to'], {
    is: (is_batch, payable_to) => is_batch && payable_to === 'factoring_company',
    then: date().required('Batch payment due date is required').nullable(),
    otherwise: date().nullable(),
  }),
  delivery_option: number()
    .moreThan(0, 'At least one delivery option is required')
    .required('At least one delivery option is required'),
  batchEmailTo: array().when(['send_email', 'payable_to'], {
    is: (send_email, payable_to) => send_email && payable_to !== 'factoring_company',
    then: array().of(
      array()
        .of(
          object()
            .shape({ id: number().required('Required') })
            .required('Required')
            .nullable()
        )
        .min(1, 'Required')
    ),
    otherwise: array().nullable(),
  }),
});
