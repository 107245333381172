import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import right from 'assets/icons/drivers/right.svg';
import cross from 'assets/icons/drivers/secondClose.svg';
import uploadLicense from 'assets/icons/drivers/uploadLicense.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editPensle.svg';
import InputLabel from 'common/InputLabel';
import ViewPdf from 'components/ViewPdf';
import { Typography } from 'components/Typography';
import ImageDropZone from 'components/ImgageDropZone';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { createChecklist } from 'Api/AccidentsAndCrashes';
import { getChecklistInitialValues } from '../AddAccidents.data';
import { SUnderlineText } from '../../AccidentsAndCrashes.styles';

const AlcoholDrugTest = ({ cancel, nextStep, accident, id }) => {
  const showToaster = useShowToaster();
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(!accident);

  const handleChecklistSubmit = async (values) => {
    try {
      setLoading(true);
      const { alcohol_test_conducted, alcohol_test_attachment, drug_test_conducted, drug_test_attachment } =
        values || {};
      const formData = new FormData();

      formData.append('alcohol_test_conducted', Number(alcohol_test_conducted));
      if (alcohol_test_conducted && alcohol_test_attachment?.length) {
        formData.append('alcohol_test_attachment', alcohol_test_attachment[0]);
      }

      formData.append('drug_test_conducted', Number(drug_test_conducted));

      if (drug_test_conducted && drug_test_attachment.length) {
        formData.append('drug_test_attachment', drug_test_attachment[0]);
      }

      await createChecklist(accident?.id || id, formData);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, setValues } = useForm({
    initialValues: getChecklistInitialValues(),
    onSubmit: handleChecklistSubmit,
  });

  const onSave = () => {
    handleSubmit();
    showToaster({ type: 'success', message: 'Success!' });
  };

  const onSaveAndClose = () => {
    handleSubmit();
    showToaster({ type: 'success', message: 'Success!' });
    cancel();
  };

  const onNext = () => {
    handleSubmit();
    nextStep();
  };

  useEffect(() => {
    if (accident) {
      const { alcohol_test_conducted, alcohol_test_attachment, drug_test_conducted, drug_test_attachment } =
        accident || {};

      setValues({
        alcohol_test_conducted,
        alcohol_test_attachment,
        drug_test_conducted,
        drug_test_attachment,
      });
    }
  }, [accident]);

  return (
    <>
      <Modal.Body className='add-accident-modal-body'>
        {editMode ? (
          <div>
            <div className='d-flex align-items-center gap-2 mt-2'>
              <CustomCheckbox
                name='alcohol_test_conducted'
                type='switch'
                smail={false}
                checked={values.alcohol_test_conducted}
                onChange={(checked) => handleChange('alcohol_test_conducted', checked)}
              />
              <Typography variant='s2'>Alcohol Test Conducted (2 to 8 Hours Post Max)</Typography>
            </div>
            {!!values.alcohol_test_conducted && (
              <div className='d-flex ms-5 gap-3'>
                <div className='d-flex flex-column  mt-3'>
                  <InputLabel>Attach Results</InputLabel>
                  <div>
                    {values?.alcohol_test_attachment ? (
                      <p className='attachment-wrapper' style={{ backgroundColor: '#f0f1fa' }}>
                        <img src={right} alt='attachment' className='attachment-icon' />
                        <span className='text-style' style={{ color: '#38A06C' }}>
                          File Uploaded
                        </span>
                        <img
                          src={cross}
                          alt='cross'
                          className='upload-cancel-icon'
                          onClick={() => handleChange('alcohol_test_attachment', null)}
                        />
                      </p>
                    ) : (
                      <div
                        className='upload-document-deposit'
                        style={{
                          background: palette.white,
                          color: palette.gray900,
                          borderColor: palette.gray200,
                          position: 'relative',
                          width: '100%',
                        }}
                      >
                        <label htmlFor='myFile' className='text-style upload-label'>
                          <p className='upload-document-icon'>
                            <img src={uploadLicense} alt='' />
                          </p>
                          <p className='upload-document-text'>
                            <span style={{ color: '#4F5AED' }}> Upload Document </span>
                            <span style={{ color: palette.gray700 }}> or drag and drop</span>
                          </p>
                        </label>
                        <input
                          type='file'
                          id='myFile'
                          name='filename'
                          onChange={(file) => {
                            handleChange('alcohol_test_attachment', file);
                          }}
                          style={{ display: 'none' }}
                        />
                        <ImageDropZone
                          onDrop={(file) => handleChange('alcohol_test_attachment', file)}
                          width='100%'
                          height='100%'
                          name='small'
                          deletable={false}
                          maxSize={1000000}
                          customStyle={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                          }}
                          accept={['application/pdf', 'image/jpeg', 'image/png']}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className='d-flex align-items-center gap-2 mt-2'>
              <CustomCheckbox
                name='drug_test_conducted'
                type='switch'
                smail={false}
                checked={values.drug_test_conducted}
                onChange={(checked) => handleChange('drug_test_conducted', checked)}
              />
              <Typography variant='s2'>Drug Test Conducted (32 Hours Post Max)</Typography>
            </div>
            {!!values.drug_test_conducted && (
              <div className='d-flex ms-5 gap-3'>
                <div className='d-flex flex-column  mt-3'>
                  <InputLabel>Attach Results</InputLabel>
                  <div>
                    {values?.drug_test_attachment ? (
                      <p className='attachment-wrapper' style={{ backgroundColor: '#f0f1fa' }}>
                        <img src={right} alt='attachment' className='attachment-icon' />
                        <span className='text-style' style={{ color: '#38A06C' }}>
                          File Uploaded
                        </span>
                        <img
                          src={cross}
                          alt='cross'
                          className='upload-cancel-icon'
                          onClick={() => handleChange('drug_test_attachment', null)}
                        />
                      </p>
                    ) : (
                      <div
                        className='upload-document-deposit'
                        style={{
                          background: palette.white,
                          color: palette.gray900,
                          borderColor: palette.gray200,
                          position: 'relative',
                          width: '100%',
                        }}
                      >
                        <label htmlFor='myFile' className='text-style upload-label'>
                          <p className='upload-document-icon'>
                            <img src={uploadLicense} alt='' />
                          </p>
                          <p className='upload-document-text'>
                            <span style={{ color: '#4F5AED' }}> Upload Document </span>
                            <span style={{ color: palette.gray700 }}> or drag and drop</span>
                          </p>
                        </label>
                        <input
                          type='file'
                          id='myFile'
                          name='filename'
                          onChange={(file) => {
                            handleChange('drug_test_attachment', file);
                          }}
                          style={{ display: 'none' }}
                        />
                        <ImageDropZone
                          onDrop={(file) => handleChange('drug_test_attachment', file)}
                          width='100%'
                          height='100%'
                          name='small'
                          deletable={false}
                          maxSize={1000000}
                          customStyle={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                          }}
                          accept={['application/pdf', 'image/jpeg', 'image/png']}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='d-flex flex-column gap-5 mt-4 mb-4'>
            <div className='d-flex flex-column'>
              <Typography variant='s2'>Alcohol Test Conducted (2 to 8 Hours Post Max)</Typography>
              <div className='d-flex gap-3'>
                <Typography variant='s3'>{values.alcohol_test_conducted ? 'Yes' : 'No'}</Typography>
                {!!values.alcohol_test_attachment && !!values.alcohol_test_conducted && (
                  <SUnderlineText onClick={() => setPdfUrl(values.alcohol_test_attachment)}>
                    View Test Attachment
                  </SUnderlineText>
                )}
              </div>
            </div>
            <div className='d-flex flex-column'>
              <Typography variant='s2'>Drug Test Conducted (32 Hours Post Max)</Typography>
              <div className='d-flex gap-3'>
                <Typography variant='s3'>{values.drug_test_conducted ? 'Yes' : 'No'}</Typography>
                {!!values.drug_test_attachment && !!values.drug_test_conducted && (
                  <SUnderlineText onClick={() => setPdfUrl(values.drug_test_attachment)}>
                    View Test Attachment
                  </SUnderlineText>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: palette.white, borderColor: palette.gray50 }}>
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={cancel}>
              Close
            </Button>
          </div>
          <div className='pagination-btn'>
            {!!accident && (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '6px 12px' }}
                type='secondary'
                title='Edit'
                disabled={loading}
                leftIcon={<EditIcon style={{ marginRight: 10 }} />}
                onClick={() => setEditMode((prevState) => !prevState)}
              />
            )}
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save'
              disabled={loading}
              onClick={onSave}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='secondary'
              title='Save & Go To List'
              disabled={loading}
              onClick={onSaveAndClose}
            />
            <CustomButton
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '6px 12px' }}
              type='primary'
              title='Next'
              onClick={onNext}
              disabled={loading}
              leftIcon={
                loading ? (
                  <CircularProgress style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }} />
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
      </Modal.Footer>
      {!!pdfUrl && <ViewPdf open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} title='' />}
    </>
  );
};

export default AlcoholDrugTest;
