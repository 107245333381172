import React, { useContext, useEffect, useState } from 'react';
import { TableContext } from 'context/tableContext';
import { UpdateCustomersPopoverSettings } from 'Api/Customers';
import styles from '../../PopoverSettings/PopoverSettings.module.css';
import TableColumn from '../../PopoverSettings/TableColumn';
import ColumnOrder from '../../PopoverSettings/ColumnOrder';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';
import CustomButton from '../../CustomButton/CustomButton';

const PopoverSettingsStopPoints = (props) => {
  const { filter, updateFilter, setShowPopover, switchRadioButtons, tableColumn, dragItem } = props;

  const { onChangeOrder, onsStTableColumn } = useContext(TableContext);

  const [localState, setLocalState] = useState([]);
  const [localDragItem, setLocalDragItem] = useState([]);
  const [switchRadioButtonsLocal, setSwitchRadioButtonsLocal] = useState({});
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

  function UpdateTableSettings(dataJson) {
    return UpdateCustomersPopoverSettings(dataJson)
      .then((res) => res)
      .catch(() => {
        // Do nothing
      });
  }

  const onChangeTick = (value) => {
    updateFilter({ ...filter, forAll: value });
  };

  const onCancel = () => {
    setShowPopover(false);
  };

  const onClickApply = () => {
    const { forAll } = filter;

    updateFilter({ ...filter, tableColumn: localState, switchRadioButtons: switchRadioButtonsLocal });
    onsStTableColumn([...localState]);
    onChangeOrder(localDragItem);
    const updateData = {
      type: 'stopPoint',
      forAll,
      data: {
        columns: {},
        columns_order: {},
      },
    };
    localState.forEach((el) => {
      const { value, checked } = el;
      updateData.data.columns[value] = checked;
    });

    updateData.data.columns_order = localDragItem.map((el) => el.value);

    UpdateTableSettings(updateData).then((res) => res && res?.data && setShowPopover(false));
  };

  useEffect(() => {
    setSwitchRadioButtonsLocal(switchRadioButtons);
  }, [switchRadioButtons]);

  useEffect(() => {
    setLocalDragItem(dragItem);
  }, [dragItem]);

  return (
    <div>
      <div className={styles.popoverSettings_title_wrapper}>
        <span>Table Settings</span>
      </div>

      <TableColumn
        localState={localState}
        tableColumn={tableColumn}
        setLocalState={(param) => setLocalState(param)}
        localDragItem={localDragItem}
        onChangeOrderLocal={(items) => setLocalDragItem(items)}
      />

      <ColumnOrder localDragItem={localDragItem} onChangeOrderLocal={(items) => setLocalDragItem(items)} />
      <div style={{ borderBottom: '1px solid #D5DBE5', margin: '32px 0 8px', height: '1px' }} />
      <div className={styles.popoverSettings_footer}>
        {user?.department?.department_name === 'Management' && (
          <CustomCheckbox style={{ marginLeft: 10 }} checked={filter.forAll} onChange={onChangeTick}>
            <span className={styles.footer_title_checkbox}>Apply to all users</span>
          </CustomCheckbox>
        )}

        <div className={styles.footer_right}>
          <CustomButton
            type='secondary'
            title='Cancel'
            styleButton={{ padding: '2px 8px', marginRight: 16 }}
            onClick={onCancel}
          />

          <CustomButton type='primary' title='Apply' styleButton={{ padding: '2px 8px' }} onClick={onClickApply} />
        </div>
      </div>
    </div>
  );
};

export default PopoverSettingsStopPoints;
