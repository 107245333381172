import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SSection = styled.div`
  border-radius: 10px;
  border: 1px solid ${palette.gray50};
  background-color: ${palette.gray0};
  margin-bottom: 24px;

  .section-header {
    display: flex;
    justify-content: space-between;
    background-color: ${palette.white};
    border-radius: 10px 10px 0 0;
    margin-bottom: 8px;
    padding: 16px 20px;
  }

  .section-body {
    padding: 16px 20px;
    background-color: ${palette.gray0};
  }

  .setting-item {
    &:not(:last-child) {
      border-bottom: 1px solid ${palette.gray50};
      margin-bottom: 24px;
    }
  }

  .action-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    :hover {
      background-color: ${palette.indigo50};
    }
  }
`;

export const SInnerSection = styled.div`
  padding: 15px 12px;
  border-radius: 10px;
  border: 1px solid ${palette.gray50};
  background-color: ${palette.white};
  margin-bottom: 24px;

  .inner-section-header {
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    padding-bottom: 8px;
    border-bottom: 1px solid ${palette.gray50};
  }

  .setting-options-container {
    .setting-option {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 10px 4px;

      &:not(:last-child) {
        border-bottom: 1px solid ${palette.gray50};
      }
    }
  }
`;

export const STable = styled.table`
  width: 100%;

  th {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    color: ${palette.gray700};
    white-space: nowrap;
    font-family: Inter, sans-serif;
    padding: 12px 8px;
    border-bottom: 1px solid ${palette.gray50};
  }

  td {
    padding: 12px 8px;
    border-bottom: 1px solid ${palette.gray50};
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const SAddMore = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 10px;
  cursor: pointer;

  :hover span {
    text-decoration: underline;
  }
`;
