import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Divider from 'common/Divider';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import { Typography } from 'components/Typography';
import CustomRadioButton from 'components/CustomRadioButton';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { getPayPeriod, updatePayPeriod } from 'Api/PayrollSettings';
import CustomButton from 'components/CustomButton/CustomButton';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import WeekSettings from './WeekSettings';
import { validationSchema } from './validationSchema';
import { initialValues, paymentForOptions } from './PayPeriod.data';
import { getPayPeriodConverter, updatePayPeriodConverter } from '../converters';
import { SCircularProgress, SWrapper } from '../Payroll.styles';
import styles from '../../companyProfile/CompanyProfile.module.css';

const PayPeriod = ({ isConfiguringStep, onSaveAndNext, onCancelPrevPage }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(true);

  const updatePayPeriodSettings = async (values) => {
    try {
      const body = updatePayPeriodConverter(values);
      await updatePayPeriod(body);
      showToaster({ type: 'success', message: 'Settings have been successfully updated' });
      if (isConfiguringStep) {
        onSaveAndNext('saveAndNext');
      }
    } catch (error) {
      showToaster({ type: 'error', message: getErrorMessage(error) });
    }
  };

  const { values, handleChange, setValues, handleSubmit, touchedErrors } = useForm({
    initialValues,
    validationSchema,
    onSubmit: updatePayPeriodSettings,
  });

  const getPayPeriodSettings = async () => {
    try {
      setLoading(true);
      const { data } = await getPayPeriod();
      const convertedValues = getPayPeriodConverter(data);
      setValues(convertedValues);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPayPeriodSettings();
  }, []);

  if (loading) {
    return (
      <SWrapper>
        <SCircularProgress size={26} />
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      <div className='pay-period-header'>
        <Typography variant='h2' style={{ color: use(palette.gray900, palette.gray200) }}>
          Pay Period
        </Typography>
        <div className={styles.actions}>
          {isConfiguringStep && (
            <>
              {' '}
              <CustomButton
                onClick={onCancelPrevPage}
                type='secondary'
                title='Back'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
              <CustomButton
                onClick={() => onSaveAndNext('skip')}
                type='secondary'
                title='Skip'
                styleButton={{ margin: 0 }}
                styleTitle={{ fontSize: 14, fontWeight: 500 }}
              />
            </>
          )}
          <CustomButton
            onClick={handleSubmit}
            type='primary'
            title={isConfiguringStep ? 'Save and Next' : 'Save'}
            styleButton={{ margin: 0 }}
            styleTitle={{ fontSize: 14, fontWeight: 500 }}
          />
        </div>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={3}>
          <CustomRadioButton
            field={{
              name: 'payperiod_type',
              value: 1,
              onChange: () => handleChange('payperiod_type', 1),
              checked: values.payperiod_type === 1,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
              Weekly
            </Typography>
          </CustomRadioButton>
        </Grid>
        {values.payperiod_type === 1 && (
          <WeekSettings values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
        )}
      </Grid>
      <Divider margin='16px 0' />
      <Grid container>
        <Grid item xs={3}>
          <CustomRadioButton
            field={{
              name: 'payperiod_type',
              value: 2,
              onChange: () => handleChange('payperiod_type', 2),
              checked: values.payperiod_type === 2,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
              Bi-Weekly
            </Typography>
          </CustomRadioButton>
        </Grid>
        {values.payperiod_type === 2 && (
          <WeekSettings values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
        )}
      </Grid>
      <Divider margin='16px 0' />
      <Grid container>
        <Grid item xs={3}>
          <CustomRadioButton
            field={{
              name: 'payperiod_type',
              value: 3,
              onChange: () => handleChange('payperiod_type', 3),
              checked: values.payperiod_type === 3,
            }}
          >
            <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
              Monthly
            </Typography>
          </CustomRadioButton>
        </Grid>
        {values.payperiod_type === 3 && (
          <Grid container item xs={9} columnGap={1}>
            <Grid style={{ marginTop: 4 }}>
              <Typography variant='s2' style={{ marginLeft: 8, color: use(palette.gray700, palette.gray200) }}>
                Set Payment for
              </Typography>
            </Grid>
            <Grid style={{ minWidth: '284px' }}>
              <Autocomplete
                name='set_payment_for'
                value={values.set_payment_for}
                onChange={(e, value) => handleChange('set_payment_for', value)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={paymentForOptions}
                error={touchedErrors.set_payment_for?.value || touchedErrors.set_payment_for}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <ErrorMessage style={{ marginTop: 4 }} error={touchedErrors.payperiod_type} />
    </SWrapper>
  );
};

export default PayPeriod;
