import React, { useState } from 'react';
import ThreeDotActions from 'common/ThreeDotActions';

const RowActions = ({ onViewEquipment, onViewLog, onCheckoutTrailer, equipmentType, onUpdateTrailer }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onActionClick = (event, type) => {
    event.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case 'viewEquipment':
        onViewEquipment();
        break;
      case 'viewLog':
        onViewLog();
        break;
      case 'checkoutTrailer':
        onCheckoutTrailer();
        break;
      case 'updateTrailer':
        onUpdateTrailer();
        break;
      default:
    }
  };

  return (
    <ThreeDotActions anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      {Number(equipmentType) === 2 && (
        <li onClick={(event) => onActionClick(event, 'updateTrailer')}>Update Trailer</li>
      )}
      <li onClick={(event) => onActionClick(event, 'viewEquipment')}>View Equipment</li>
      <li onClick={(event) => onActionClick(event, 'viewLog')}>View Log</li>
      {Number(equipmentType) === 2 && (
        <li onClick={(event) => onActionClick(event, 'checkoutTrailer')}>Checkout Trailer</li>
      )}
    </ThreeDotActions>
  );
};

export default RowActions;
