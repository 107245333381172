import React from 'react';
import { palette } from 'utils/constants';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import sm from './HeaderProgress.module.css';
import { Typography } from '../../../Typography';

const HeaderProgress = ({ step, progressData }) => {
  return (
    <div className={sm.progressContainer}>
      {progressData.map((el) => {
        const { id, title } = el;
        const className = step === id ? sm['is-active'] : step > id ? sm['is-passed'] : '';
        return (
          <div key={id} className={[sm.progressWrapper, className].join(' ')}>
            <div>
              <div className={sm.progressCheckbox}>
                {step === id && <div className={sm.checkboxDot} />}
                <TickIcon fill={palette.white} className={sm.tick} />
              </div>
              <Typography variant='button1'>{title}</Typography>
            </div>

            <div className={sm.progressLine}>
              <div className={sm.activeLine} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderProgress;
