import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';

import NoRecords from 'common/NoRecords';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { CURRENCY, formatNumber, numberWithCommas, palette } from 'utils/constants';
import { convertToCustomerTime } from 'utils/helpers';
import { getAppliedPayments } from 'Api/Shipment';
import Tooltip from '@mui/material/Tooltip';
import useDateFormat from 'hooks/useDateFormat';
import useShipmentLocked from 'hooks/useShipmentLocked';
import Chip from 'common/Chip';
import styles from './billingCharges.module.css';
import { BillingChargesContext } from './BillingChargeChild';

const SkilletBillCharges = ({ isRecurring }) => {
  const { use } = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { formatDate } = useDateFormat();
  const { isShipmentLocked } = useShipmentLocked();
  const { currency } = useSelector((state) => state.root);
  const { assignToGroupsData } = useSelector((state) => state?.shipments);
  const [activeContact, setActiveContact] = useState({});
  const [appliedPayments, setAppliedPayments] = useState([]);
  const {
    values,
    editMood,
    setEditMood,
    selectedCharges,
    selectedGrandTotalForm,
    shipmentChargeType,
    count,
    contactDataObj,
    indexPrefix,
    infoHeader,
    allChargesData,
  } = useContext(BillingChargesContext);
  const currencyData = Object.values(CURRENCY).find((item) => item.id === Number(selectedGrandTotalForm?.id));

  const contactData = contactDataObj?.[0];
  const paymentApplied = useMemo(
    () => (appliedPayments || []).reduce((a, b) => a + Number(b.amount_paid || 0), 0),
    [appliedPayments]
  );
  const totalCharges = useMemo(() => Number(count?.toString()?.replaceAll(',', '') || 0), [count]);

  const getPaymentsApplied = async () => {
    try {
      const { data } = await getAppliedPayments({ shipment_id: infoHeader.shipment_id, itemsPerPage: 1000 });
      setAppliedPayments(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const activeItemGroups = useMemo(() => {
    if (values?.group) {
      return assignToGroupsData.find((el) => +el.id === +values.group);
    }
  }, [assignToGroupsData, values]);

  const billingStyles = useMemo(() => {
    return {
      headerLeft: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
      global: {
        width: 'calc(20% - 40px)',
      },
      text: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
      },
      group: {
        maxWidth: '100%',
        wordBreak: 'break-word',
        margin: 10,
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
      editPaymentTerm: {
        width: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    };
  }, [use, palette]);

  const findActiveContact = useCallback(
    (formValues) => {
      const activeContact = contactData.find((el) => Number(el?.id) === Number(formValues?.contact_user_id));
      if (activeContact !== undefined) setActiveContact(activeContact);
    },
    [contactData]
  );

  useEffect(() => {
    findActiveContact(values);
  }, [findActiveContact, values?.contact_user_id, contactData]);

  useEffect(() => {
    setEditMood(!searchParams.get('isEdit'));
    navigate('.', { replace: true });
  }, []);

  useEffect(() => {
    if (!isRecurring && !!infoHeader?.shipment_id) {
      getPaymentsApplied();
    }
  }, [infoHeader]);

  return (
    <>
      <div className={classNames(styles.billing_container, use(styles.light, styles.dark))}>
        <div className={styles.global_header_wrapper} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
          <div>
            <Typography variant='h2' style={{ marginBottom: 16 }}>
              Bill To
              {infoHeader && infoHeader.shipment_type === 'ltl' && (
                <Typography variant='s2' style={{ marginRight: 8 }}>
                  {' '}
                  ( {isRecurring ? 'Lane ID' : 'Shipment'} {id}-{indexPrefix + 1} )
                </Typography>
              )}
            </Typography>
            {isShipmentLocked(infoHeader) ? (
              <ActionLocked shipment={infoHeader} size={16} />
            ) : (
              <div
                onClick={() => setEditMood(false)}
                className={classNames(styles.edit_mood_wrapper, use(styles.light, styles.dark))}
              >
                <EditIcon style={{ cursor: 'pointer' }} fill={use(palette.gray500, palette.gray200)} />
              </div>
            )}
          </div>
          <div className={styles.line} />
        </div>
        <div className={styles.billing_charges_header} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
          <div className={styles.header_titles_wrapper}>
            <div className={styles.titles}>
              <div className={styles.header_title_left}>
                <Typography variant='overLine' style={billingStyles.global}>
                  BILL TO
                </Typography>
                <Typography variant='overLine' style={billingStyles.global}>
                  REFERENCE ID
                </Typography>
                <Typography variant='overLine' style={billingStyles.global}>
                  {!editMood ? 'ASSIGN TAG' : 'TAG'}
                </Typography>
                <div className={[styles.edit_wrapper].join(' ')} style={billingStyles.global}>
                  <Typography variant='overLine'>CONTACT</Typography>
                </div>
                <Typography style={billingStyles.editPaymentTerm} variant='overLine' className={styles.paymentTerm}>
                  PAYMENT TERM
                </Typography>
              </div>
            </div>
            <div className={styles.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
          </div>
          <div className={styles.header_wrapper}>
            <div style={billingStyles.headerLeft}>
              <div style={billingStyles.global}>
                {!!values?.customer_id && (
                  <div className={styles.header_titles_wrapper}>
                    <Typography variant='s2'>{allChargesData.billing_customer?.company_name}</Typography>
                    <Typography
                      variant='b2'
                      style={{ ...billingStyles.text, color: use(palette.gray700, palette.gray50) }}
                    >
                      {allChargesData.billing_customer?.address1},&nbsp;
                      {allChargesData.billing_customer?.address2}
                    </Typography>
                    <Typography
                      variant='b2'
                      style={{ ...billingStyles.text, color: use(palette.gray700, palette.gray50) }}
                    >
                      {allChargesData.billing_customer?.city?.name},&nbsp;
                      {allChargesData.billing_customer?.state?.name}&nbsp;
                      {allChargesData.billing_customer?.zipcode}&nbsp;
                    </Typography>
                    <Typography
                      variant='b2'
                      style={{ ...billingStyles.text, color: use(palette.gray700, palette.gray50) }}
                    >
                      {allChargesData.billing_customer?.country?.name}
                    </Typography>
                  </div>
                )}
              </div>
              <div style={billingStyles.global}>{values.reference_id}</div>

              <div style={billingStyles.global}>
                {activeItemGroups?.short_name && (
                  <div className={styles.group_wrapper} style={{ backgroundColor: activeItemGroups?.color }}>
                    <Typography variant='c2' style={billingStyles.group}>
                      {activeItemGroups.short_name}
                    </Typography>
                  </div>
                )}
              </div>

              <div style={billingStyles.global}>
                {!!values?.contact_user_id && Object.keys(activeContact).length > 0 && (
                  <div className={styles.header_titles_wrapper}>
                    <Typography variant='s2'>{activeContact?.contact_name}</Typography>
                    {!!activeContact?.phone_number && (
                      <Typography
                        variant='b2'
                        style={{ ...billingStyles.text, color: use(palette.gray700, palette.gray50) }}
                      >
                        <ContactIcon style={{ marginRight: 8 }} />
                        {activeContact?.phone_number}
                      </Typography>
                    )}
                    {!!activeContact?.contact_email && (
                      <Typography
                        variant='b2'
                        style={{ ...billingStyles.text, color: use(palette.gray700, palette.gray50) }}
                      >
                        <MailIcon style={{ marginRight: 8 }} />
                        {activeContact?.contact_email}
                      </Typography>
                    )}
                  </div>
                )}
              </div>

              <Typography variant='s2' style={{ width: '20%' }} className={styles.paymentTerm}>
                {getPaymentTermTitle(allChargesData?.payment_term)}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.billing_container}>
        <div className={styles.global_header_wrapper} style={{ backgroundColor: use(palette.white, palette.dark800) }}>
          <Typography variant='h2' style={{ marginBottom: 16 }}>
            Charges
          </Typography>
          <div className={styles.line} />
        </div>

        <div className={styles.skillet_charges_selected}>
          <div className={styles.header_titles_wrapper}>
            <div className={styles.titles}>
              <Typography variant='overLine' style={{ minWidth: 600 }}>
                CHARGES
              </Typography>
              <Typography variant='overLine' style={{ minWidth: 80 }}>
                QTY
              </Typography>
              <Typography variant='overLine' style={{ minWidth: 160 }}>
                RATE
              </Typography>
              <Typography variant='overLine' style={{ minWidth: 160 }}>
                SUB-TOTAL
              </Typography>
              <Typography variant='overLine' style={{ minWidth: 120 }} />
            </div>
            <div className={styles.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
          </div>
        </div>
        <div className={styles.grand_totals_wrapper}>
          <div className={styles.skillet_charges_selected}>
            {selectedCharges?.map((charge) => {
              const selectInput = shipmentChargeType.find((el) => el.key === +charge.charge_type);
              const ref_id = charge.ref_id;
              const ref_shipment_id = charge.ref_shipment_id?.split('-')?.[1];
              const current_shipment_id = infoHeader.shipment_id?.split('-')?.[1];

              return (
                <div key={charge.id} className={styles.skillet_charges_line_wrapper}>
                  <div className={styles.charge_wrapper}>
                    <Typography variant='s2' style={{ width: 600 }}>
                      {charge?.detention?.equipment?.equipment_type?.id >= 12
                        ? 'Dropped Trailer Detention'
                        : selectInput?.label}{' '}
                      {charge?.charge_type === 6 && !!charge.detention
                        ? `(${charge?.detention?.shipment_stop?.stop_point?.location_name}) - ${convertToCustomerTime(
                            charge?.detention?.arrived_date,
                            'MM-DD-YYYY HH:mm:ss'
                          )} to ${convertToCustomerTime(charge?.detention?.departed_date, 'MM-DD-YYYY HH:mm:ss')}`
                        : ''}
                      {infoHeader?.shipment_type === 'split-children' && (
                        <Chip
                          label={ref_id ? ref_shipment_id || 'MASTER' : current_shipment_id}
                          fontWeight={500}
                          fontSize='11px'
                          className='ms-2'
                        />
                      )}
                      {infoHeader?.shipment_type === 'split-parent' && (
                        <Chip
                          label={!charge.ref_id ? 'MASTER' : ref_shipment_id}
                          fontWeight={500}
                          fontSize='11px'
                          className='ms-2'
                        />
                      )}
                    </Typography>
                    <Typography variant='s2' style={{ width: 80 }}>
                      {charge?.quantity}
                    </Typography>
                    <Typography variant='s2' style={{ width: 160 }}>
                      {charge?.rate}
                    </Typography>
                    <Typography variant='s2' style={{ width: 160 }}>
                      {currencyData?.symbol || '$'}
                      {numberWithCommas((charge.quantity * charge.rate).toFixed(2))}
                    </Typography>
                    <Typography variant='s2' style={{ width: 120, display: 'flex', justifyContent: 'flex-end' }} />
                  </div>
                  <div className={styles.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
                </div>
              );
            })}
          </div>
        </div>

        {/* // Grand Total */}
        <div className={classNames(styles.header_titles_wrapper, styles.grandTotal)}>
          <div className={styles.titles}>
            <Typography variant='overLine' style={{ minWidth: 600 }} />
            <Typography variant='overLine' style={{ minWidth: 80 }} />
            <Typography variant='s2' style={{ minWidth: 154 }}>
              Grand Total
            </Typography>
            <Typography variant='s1' style={{ minWidth: 170 }}>
              <Typography variant='h5'>
                {currencyData?.symbol || '$'}
                {count}{' '}
              </Typography>
              {selectedGrandTotalForm?.title}
            </Typography>
            <Typography variant='overLine' style={{ minWidth: 120 }} />
          </div>
        </div>
      </div>
      {!isRecurring && (
        <div className={styles.billing_container}>
          <div
            className={styles.global_header_wrapper}
            style={{ backgroundColor: use(palette.white, palette.dark800) }}
          >
            <Typography variant='h2' style={{ marginBottom: 16 }}>
              Payments Applied
            </Typography>
            <div className={styles.line} />
          </div>

          <div className={styles.skillet_charges_selected}>
            <div className={styles.header_titles_wrapper}>
              <div className={styles.titles}>
                <Typography variant='overLine' style={{ width: 200 }}>
                  PAYMENT DATE
                </Typography>
                <Typography variant='overLine' style={{ width: 250 }}>
                  PAY REFERENCE
                </Typography>
                <Typography variant='overLine' style={{ width: 160 }}>
                  PAY METHOD
                </Typography>
                <Typography variant='overLine' style={{ width: 250 }}>
                  DEPOSIT ACCOUNT
                </Typography>
                <Typography variant='overLine' style={{ width: 300 }}>
                  AMOUNT PAID
                </Typography>
              </div>
              <div className={styles.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
            </div>
          </div>
          <div className={styles.grand_totals_wrapper}>
            <div className={styles.skillet_charges_selected}>
              {appliedPayments.map((payment) => (
                <div key={payment.id} className={styles.skillet_charges_line_wrapper}>
                  <div className={styles.charge_wrapper}>
                    <Typography variant='s2' style={{ width: 200 }}>
                      {formatDate(payment.payment_date)}
                    </Typography>
                    <Typography variant='s2' style={{ width: 250 }}>
                      <Tooltip
                        arrow
                        title={payment.pay_reference}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 13,
                              fontFamily: 'Inter',
                            },
                          },
                        }}
                      >
                        <span style={{ color: palette.gray700 }}>*****{payment.pay_reference?.slice(-5)}</span>
                      </Tooltip>
                    </Typography>
                    <Typography variant='s2' style={{ width: 160 }}>
                      {payment?.pay_method}
                    </Typography>
                    <Typography variant='s2' style={{ width: 250 }}>
                      {payment?.account?.account_name || '-'}
                    </Typography>
                    <Typography variant='s2' style={{ width: 300 }}>
                      {currency}
                      {formatNumber(payment.amount_paid)}
                    </Typography>
                  </div>
                  <div className={styles.line} style={{ backgroundColor: use(palette.gray50, palette.gray50) }} />
                </div>
              ))}
            </div>
          </div>
          {!appliedPayments.length && <NoRecords />}
          <div className='d-flex justify-content-end m-3' style={{ width: '85%' }}>
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex gap-4'>
                <Typography variant='s2'>Payments Applied:</Typography>
                <Typography variant='h5'>
                  {currency}
                  {formatNumber(paymentApplied)}
                </Typography>
              </div>
              <div className='d-flex gap-4'>
                <Typography variant='s2' style={{ marginRight: '37px' }}>
                  Balance Due:
                </Typography>
                <Typography variant='h5'>
                  {currencyData?.symbol || '$'}
                  {formatNumber(totalCharges - paymentApplied)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SkilletBillCharges;
