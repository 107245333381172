import { array, object, string } from 'yup';

export const validationSchema = object().shape({
  users: array()
    .of(
      object()
        .shape({ type: string().required('Required') })
        .required('Required')
        .nullable()
    )
    .min(1, 'Required'),
});
