import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { Typography } from 'components/Typography';
import ThreeDotActions from 'common/ThreeDotActions';
import { palette } from 'utils/constants';
import { initialRecurs } from './Schedule.data';

const CopyRecurring = ({ id, onCopyFromRow, disabled }) => {
  const [anchorEl, setAnchorEl] = useState();

  const onActionClick = (event, id) => {
    event.stopPropagation();
    setAnchorEl(null);
    onCopyFromRow(id);
  };

  return (
    <ThreeDotActions
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      disabled={disabled}
      actionButton={
        <div className='d-flex justify-content-between align-items-center gap-2'>
          <CopyIcon fill={disabled ? palette.gray200 : palette.indigo500} />
          <Typography variant='s2' style={{ color: disabled ? palette.gray200 : palette.indigo500 }}>
            Copy
          </Typography>
        </div>
      }
    >
      {initialRecurs
        .filter((i) => i.id !== id)
        .map((item) => (
          <li onClick={(event) => onActionClick(event, item.id)}>{item.title}</li>
        ))}
    </ThreeDotActions>
  );
};

export default CopyRecurring;
