import { DATE_FORMAT, TIME_FORMAT } from 'utils/constants';

const dateFormatLabelMapper = {
  [DATE_FORMAT[1]]: DATE_FORMAT[1],
  [DATE_FORMAT[2]]: DATE_FORMAT[2],
  [DATE_FORMAT[3]]: DATE_FORMAT[3],
  [DATE_FORMAT[4]]: DATE_FORMAT[4],
};

const timeFormatLabelMapper = {
  [TIME_FORMAT[1]]: 'AM/PM',
  [TIME_FORMAT[2]]: 'Military Time',
};

export const dateFormats = Object.keys(DATE_FORMAT).map((key) => ({
  name: dateFormatLabelMapper[DATE_FORMAT[key]],
  id: Number(key),
}));
export const timeFormats = Object.keys(TIME_FORMAT).map((key) => ({
  name: timeFormatLabelMapper[TIME_FORMAT[key]],
  id: Number(key),
}));

export const getInitialValues = (data) => {
  const { date_format, time_format } = data || {};

  return {
    date_format: dateFormats.find((i) => i.id === date_format) || null,
    time_format: timeFormats.find((i) => i.id === time_format) || null,
  };
};
