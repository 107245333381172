import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  shipping_country: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  shipping_state: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  shipping_city: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  shipping_address1: string().trim().required('Required'),
  shipping_address2: string().trim().nullable(),
  shipping_zip: string().trim().required('Required'),
});
