import styled from 'styled-components';

export const SFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  .merged-inputs {
    display: flex;

    .name-input input {
      border-radius: 6px 0 0 6px;
    }

    .color-input input {
      border-radius: 0 6px 6px 0;
      height: 33px;
    }
  }
`;
