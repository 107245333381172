export const getInitialValues = () => {
  const carrierInfo = JSON.parse(localStorage.getItem('account'));

  return {
    name: carrierInfo?.name || '',
    last_name: carrierInfo?.last_name || '',
    company_name: carrierInfo?.company_name || '',
    dot: carrierInfo?.dot || '',
    phone: carrierInfo?.phone || '',
    email: carrierInfo?.email || '',
    workers: carrierInfo?.workers || '',
  };
};
