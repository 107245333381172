import { array, date, number, object, string } from 'yup';

export const deductionsValidationSchema = object().shape({
  deductions: array().of(
    object().shape({
      date: date().required('Required'),
      deduction_type_id: string().required('Required').nullable(),
      amount: number()
        .required('Required')
        .nullable()
        .test('Required', 'Required', (value) => value > 0)
        .nullable(),
    })
  ),
});

export const additionsValidationSchema = object().shape({
  additions: array().of(
    object().shape({
      date: date().required('Required'),
      addition_type_id: string().required('Required').nullable(),
      amount: number()
        .required('Required')
        .nullable()
        .test('Required', 'Required', (value) => value > 0)
        .nullable(),
    })
  ),
});
export const taxesValidationSchema = object().shape({
  taxes: array().of(
    object().shape({
      date: date().required('Required'),
      tax_type_id: string().required('Required').nullable(),
      percent: number()
        .required('Required')
        .nullable()
        .test('Required', 'Required', (value) => value > 0)
        .nullable(),
    })
  ),
});
export const benefitsValidationSchema = object().shape({
  benefits: array().of(
    object().shape({
      date: date().required('Required'),
      benefit_type_id: string().required('Required').nullable(),
      amount: number()
        .required('Required')
        .nullable()
        .test('Required', 'Required', (value) => value > 0)
        .nullable(),
    })
  ),
});
