import React from 'react';
import AbsencesTable from 'pages/Absence/AbsencesTable';
import { SPageWrapper } from './Absence.styles';

const Absence = () => {
  return (
    <SPageWrapper>
      <AbsencesTable />
    </SPageWrapper>
  );
};

export default Absence;
