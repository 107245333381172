import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import CustomButton from 'components/CustomButton/CustomButton';
import { SWrapper, SYearPicker } from './YearPicker.styles';

const YearPicker = ({ year, onChange, ...props }) => {
  const addOneYear = (date) => {
    onChange(moment(date).add(1, 'year').toDate());
  };

  const decreaseOneYear = (date) => {
    onChange(moment(date).subtract(1, 'year').toDate());
  };

  return (
    <SWrapper>
      <CustomButton
        type='secondary'
        title='<'
        styleButton={{
          padding: '2px 10px',
          margin: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        styleTitle={{ fontSize: '14px' }}
        onClick={() => decreaseOneYear(year)}
      />
      <SYearPicker>
        <CalendarIcon width={14} height={14} />
        <DatePicker
          selected={year}
          onChange={(date) => onChange(date)}
          showYearPicker
          dateFormat='yyyy'
          yearItemNumber={9}
          {...props}
        />
      </SYearPicker>
      <CustomButton
        type='secondary'
        title='>'
        styleButton={{
          padding: '2px 10px',
          margin: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        styleTitle={{ fontSize: '14px' }}
        onClick={() => addOneYear(year)}
      />
    </SWrapper>
  );
};

export default YearPicker;
