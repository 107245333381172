import moment from 'moment';
import uuid from 'react-uuid';

export const COUNTRIES = [
  { key: 'US', name: 'United States', id: 231, phonecode: 1 },
  { key: 'CA', name: 'Canada', id: 38, phonecode: 1 },
  { key: 'MX', name: 'Mexico', id: 142, phonecode: 52 },
];

export const LICENSE_TYPES = [
  { id: 1, name: 'Non-Commercial' },
  { id: 2, name: 'Chauffeur' },
  { id: 3, name: 'Class A Commercial' },
  { id: 4, name: 'Class B Commercial' },
  { id: 5, name: 'Class C Commercial' },
];

export const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const skipSteps = ['prevEmployment'];

export const introInitialValues = [false, false, false, false, false, false, false];

export const basicInfoInitialValues = {
  fullName: '',
  socialNumber: '',
  phoneNumber: '',
  email: '',
  birthDate: null,
  emergency_contact_first_name: '',
  emergency_contact_last_name: '',
  emergency_contact_number: '',
  addresses: [
    {
      id: Math.random(),
      start_date: null,
      end_date: null,
      current: 1,
      address1: '',
      address2: '',
      country: null,
      state: null,
      city: null,
      zipcode: '',
    },
    {
      id: Math.random(),
      start_date: null,
      end_date: null,
      current: 0,
      address1: '',
      address2: '',
      country: null,
      state: null,
      city: null,
      zipcode: '',
      noPreviousAddress: false,
    },
  ],
};

export const positionInitialValues = {
  applicantType: null,
  position: null,
  equipment: [],
  years: '',
  months: '',
};

export const applicantInfoInitialValues = {
  applicantStatus: 'individual',
  fullName: '',
  socialNumber: '',
  companyName: '',
  ein: '',
  address1: '',
  address2: '',
  country: null,
  state: null,
  city: null,
  zipcode: '',
  canTravelCanada: false,
  canTravelMexico: false,
  canTravelUS: false,
  workedBefore: false,
  currentlyEmployed: false,
  currentCompany: '',
  haveEnglishSkills: false,
};

export const driverLicenseInitialValues = {
  licenseNumber: '',
  licenseUrl: null,
  licenseUrlBack: null,
  country: null,
  state: null,
  licenseType: null,
  expirationDate: null,
};

export const staffLicenseInitialValues = {
  photo_id_number: '',
  photo_id_type: null,
  photo_id_expiration: null,
  photo_id_attachment: null,
};

export const medicalCardInitialValues = {
  medicalCardUrl: null,
  expirationDate: null,
};

export const previousEmploymentInitialValues = [
  {
    isNew: true,
    employerName: '',
    phoneNumber: '',
    fax: '',
    email: '',
    present: false,
    startDate: null,
    endDate: null,
    address: '',
    country: null,
    state: null,
    city: null,
    zipcode: '',
    position: '',
    reason: '',
    terminated: null,
    isCurrentEmployer: null,
    canContact: null,
    operatedCommercialVehicle: null,
    subjectToSafetyRegulations: null,
    performSafetyFunctions: null,
    areasDriven: '',
    milesDriven: '',
    payRange: '',
    truckName: '',
    trailerNameSize: '',
  },
];

export const militaryInitialValues = {
  service_branch: '',
  service_from: null,
  service_to: null,
  discharge_rank: '',
  discharge_type: '',
  mos: '',
};

export const educationInitialValues = {
  school_name: '',
  start_date: null,
  end_date: null,
  address: '',
  country: null,
  state: null,
  city: null,
  zipcode: '',
  subject: '',
  phone_no: '',
  ongoing: false,
  graduation_date: null,
  subject_to_safety_regulations: null,
  perform_safety_functions: null,
  gpa: '',
  instruction_hrs: '',
  crossed_border: null,
  log_books: null,
  federal_motor_regulations: null,
  hazardous_materials: null,
};

export const trafficAccidentsInitialValues = {
  involved_in_accidents: null,
  accidents: [{ id: uuid(), accident_nature: '', date: null, fatalities: '', fatalities_count: '', injuries: '' }],
  involved_in_violations: null,
  traffics: [
    {
      id: uuid(),
      address: '',
      country: null,
      state: null,
      city: null,
      zipcode: '',
      date: null,
      charge: '',
      penalty: '',
    },
  ],
  denied_license: null,
  denied_statement: '',
  suspended_license: null,
  suspended_statement: '',
};

export const otherInitialValues = { questions: [] };

export const checkHomeAddressDates = (datePairs) => {
  const threeYearsAgo = moment().subtract(3, 'years').startOf('day');
  const today = moment().startOf('day');

  const datesToCover = [];
  for (let m = moment(threeYearsAgo); m.isSameOrBefore(today); m.add(1, 'days')) {
    datesToCover.push(m.format('YYYY-MM-DD'));
  }

  datePairs.forEach((pair) => {
    const start = moment(pair.start_date).startOf('day');
    const end = moment(pair.end_date).startOf('day');
    for (let m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
      const dateIndex = datesToCover.indexOf(m.format('YYYY-MM-DD'));
      if (dateIndex > -1) {
        datesToCover.splice(dateIndex, 1);
      }
    }
  });

  return datesToCover.length === 0;
};
