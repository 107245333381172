export const getGeneralInitialValues = (customerTime) => {
  return {
    collision_date_time: new Date(customerTime),
    type: 'Reportable',
    driver: null,
    vehicle: null,
    trailer: null,
    incident: null,
    report_number: null,
    type_of_crash: null,
    control_device_type: null,
    control_device_other: '',
    shipment: null,
    shipment_stop: null,
    speed_limit: '',
    road_type: null,
    road_control: null,
    injuries: '',
    fatalities: '',
    towing: false,
    hazmat: false,
    weather: null,
    light_condition: null,
    preventable: false,

    accident_location: '',
    police_report: '',
    police_report_number: '',
    police_report_attachment: null,
    citation_issued: '',
    citation_issued_violation_count: '',

    driver_family_notified: false,
    driver_family_notified_to_first_name: '',
    driver_family_notified_to_last_name: '',
    driver_family_notified_to_telephone: '',
    driver_family_notified_datetime: null,

    tow_service_notified: false,
    tow_service_notified_name: '',
    tow_service_notified_address: '',
    tow_service_notified_telephone: '',
    tow_service_notified_datetime: null,
  };
};
