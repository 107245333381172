import React, { useEffect, useState } from 'react';
import moment from 'moment';
import pending from 'assets/icons/drivers/pending.svg';
import calendar from 'assets/icons/drivers/calendar.svg';
import './StaffShipmentActivity.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { getStaffShipmentBooked, getStaffWorkingHours } from 'Api/Staff';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import BarChat from '../BarChart/BarChart';
import DateRange from '../DateRangePicker/DateRangePicker';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';
import BarChatShipment from '../BarChartShipmentBooked/BarChartShipmentBooked';

const StaffShipmentActivity = ({ staff_id }) => {
  const { use } = useTheme();
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [staffShipmentOtherData, setStaffShipmenOtherData] = useState({});
  const [staffStatsOtherData, setStaffStatsOtherData] = useState({});
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -29)),
    end: endOfDay(new Date()),
  });
  const [staffShipmentData, seStaffShipmentData] = useState({
    loading: true,
    data: [],
  });
  const [staffStatsData, setStaffStatsData] = useState({
    loading: true,
    data: [],
  });
  const dateSelect = (dates) => {
    setDateRange(dates);
  };
  const { start, end } = dateRange;
  const startFormat = moment(start).format('MM/DD/YYYY');
  const endFormat = moment(end).format('MM/DD/YYYY');

  useEffect(() => {
    const payload = { date_from: startFormat, date_to: endFormat, staff_id };

    getStaffShipmentBooked(payload)
      .then((res) => {
        if (res.data) {
          setStaffStatsData({
            loading: false,
            data: res.data,
          });
          setStaffStatsOtherData(res?.other);
        }
      })
      .catch(() => {
        setStaffStatsData({
          loading: false,
        });
      });

    getStaffWorkingHours(payload).then((res) => {
      seStaffShipmentData({
        loading: false,
        data: res.data,
      });
      setStaffShipmenOtherData(res?.other);
    });
  }, [dateRange]);

  return (
    <div className='staff-activity'>
      <div
        className='shipment-activity-wrapper  driver-style-wrap mt-0'
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div
          className='shipment-activity-header-container'
          style={{ borderColor: use(palette.gray50, palette.darkborder) }}
        >
          <div className='calender-wrap'>
            {isShowDatePicker && (
              <div style={{ position: 'relative', left: '143px', bottom: '57px' }}>
                <DateRange
                  title='All Time'
                  dateTimeRange={dateRange}
                  dateSelect={dateSelect}
                  rangeName={rangeName}
                  setRangeName={setRangeName}
                  setIsShowDatePicker={setIsShowDatePicker}
                />
              </div>
            )}
            <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
          </div>
        </div>
        <div className='lineChart-wrapper'>
          <div className='staff-line-chart-left'>
            <div
              className='staff-line-chart-left-upper'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              <div className='absence-details '>
                <img src={pending} alt='' className='absence-image' />
                <p className='detail-name' style={{ color: use(palette.gray900, palette.gray400) }}>{`${
                  staffShipmentOtherData?.workedHours || 0
                }h ${staffShipmentOtherData?.workedMinutes || 0}m`}</p>
                <p className='detail' style={{ color: use(palette.gray500, palette.gray50) }}>
                  Worked Hours
                </p>
              </div>
              <div className='staff-line-chart-left-lower'>
                <p>Avg: {staffShipmentOtherData?.avg}h/day</p>
              </div>
            </div>
            <div className='staff-line-chart-left-bottom'>
              <BarChat staffGraphData={staffShipmentData} />
            </div>
          </div>
          <div className='staff-line-chart-left'>
            <div
              className='staff-line-chart-left-upper'
              style={{ borderColor: use(palette.gray50, palette.darkborder) }}
            >
              <div className='absence-details '>
                <img src={pending} alt='' className='absence-image' />
                <p className='detail-name' style={{ color: use(palette.gray900, palette.gray400) }}>
                  {staffStatsOtherData?.totalBookedShipments}
                </p>
                <p className='detail' style={{ color: use(palette.gray500, palette.gray50) }}>
                  shipment booked
                </p>
              </div>
              <div className='staff-line-chart-left-lower'>
                <p>Avg: {staffStatsOtherData?.avg}/day</p>
              </div>
            </div>
            <div className='staff-line-chart-left-bottom'>
              <BarChatShipment staffGraphData={staffStatsData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffShipmentActivity;
