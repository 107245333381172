import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import { formatNumber, palette } from 'utils/constants';
import UserInfo from 'pages/CardManagement/Cards/components/UserInfo';
import RowActions from 'pages/CardManagement/Transactions/components/RowActions';
import { SUserInfo } from 'pages/CardManagement/Cards/Cards.styles';
import { TYPE_MAPPER } from './constats';

const Title = ({ title }) => {
  return <Typography variant='overLine'>{title}</Typography>;
};

export const useColumns = ({ setOpenFeatureNotAvailable, onAssignTransaction, totalAmount }) => {
  const { use } = useTheme();
  const { currency } = useSelector((state) => state.root);

  return [
    {
      field: 'card_holder',
      title: <Title title='CARD HOLDER' />,
      render: (row) => {
        if (row?.footer) {
          return '';
        }
        const { card_holder_id, id, card_holder_name, phone_number, email, profile_image, user_type, provider_id } =
          row || {};
        return user_type ? (
          <UserInfo
            data={{
              user: {
                id: card_holder_id,
                first_name: card_holder_name?.split(' ')[0] || '',
                last_name: card_holder_name?.split(' ')[1] || '',
                phone_number,
                email,
                profile_logo: profile_image,
              },
              user_type,
            }}
          />
        ) : (
          <SUserInfo
            onClick={(e) => {
              e.stopPropagation();
              onAssignTransaction({ id, provider_id });
            }}
          >
            <div className='assign-user'>
              <PlusIcon width={12} height={12} />
            </div>
            <Typography variant='s2' style={{ color: use(palette.indigo500) }} className='user-name'>
              Assign User
            </Typography>
          </SUserInfo>
        );
      },
    },
    {
      field: 'service',
      title: <Title title='SERVICE' />,
      render: (row) => {
        if (row?.footer) return '';

        return row.provider_logo ? (
          <img src={row.provider_logo} alt='card logo' height={22} />
        ) : (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'card',
      title: <Title title='CARD' />,
      render: (row) => {
        if (row?.footer) return '';
        return row.card_id ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              <span style={{ fontSize: '8px' }}>●●●●</span> {row.card_id.slice(-4)}
            </Typography>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {row?.card?.exp}
            </Typography>
          </div>
        ) : (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            -
          </Typography>
        );
      },
    },
    {
      field: 'date',
      title: <Title title='DATE' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.date}
          </Typography>
        );
      },
    },
    {
      field: 'vendor',
      title: <Title title='VENDOR' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.vendor?.name || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'reference',
      title: <Title title='REFERENCE' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.invoice || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'category',
      title: <Title title='CATEGORY' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.category?.description || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'address',
      title: <Title title='LOCATION' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <div className='d-flex flex-column'>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {!!row.cities?.name && `${row.cities?.name},`}{' '}
              {!!(row.states?.short_name || row.states?.name) && `${row.states?.short_name || row.states?.name}`}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'cost_per_gallon',
      title: <Title title='COST PER GALLON' />,
      render: (row) => {
        if (row?.footer) return '';
        const totalGallons = Number(row.gallons || 1);
        const totalAmount = Number(row.amount || 0);

        const costPerGallon = totalAmount / totalGallons;

        return (
          <div className='d-flex flex-column'>
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
              {currency}
              {formatNumber(costPerGallon)}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'fees',
      title: <Title title='FEES' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {Number(row.fees) ? `${currency}${formatNumber(row.fees)}` : '-'}
          </Typography>
        );
      },
    },
    {
      field: 'equipment',
      title: <Title title='EQUIPMENT' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.equipment_id || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'gallons',
      title: <Title title='QTY' />,
      render: (row) => {
        if (row?.footer)
          return (
            <Typography variant='button2' style={{ color: use(palette.gray700, palette.gray200) }}>
              Total:
            </Typography>
          );
        return (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {row.gallons || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'amount',
      title: <Title title='AMOUNT' />,
      render: (row) => {
        return row?.footer ? (
          <Typography variant='button2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {currency}
            {formatNumber(totalAmount)}
          </Typography>
        ) : (
          <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray200) }}>
            {currency}
            {formatNumber(row?.amount)}
          </Typography>
        );
      },
    },
    {
      field: 'type',
      title: <Title title='TYPE' />,
      render: (row) => {
        if (row?.footer) return '';
        return (
          <Chip
            size='small'
            label={row.status?.toUpperCase()}
            labelColor={TYPE_MAPPER[row.status]?.color}
            bgColor={TYPE_MAPPER[row.status]?.bgColor}
          />
        );
      },
    },
    {
      field: 'actions',
      title: <Title title='' />,
      render: (row) =>
        row?.footer ? '' : <RowActions row={row} setOpenFeatureNotAvailable={setOpenFeatureNotAvailable} />,
    },
  ];
};
