import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalBody } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { getFullPrices } from 'Api/Planner';
import useShowToaster from 'hooks/useShowToaster';
import styles from './DriverCustomCost.module.css';

const DriverCustomCost = ({ driverCustomCost }) => {
  const { currency } = useSelector((state) => state.root);
  const [fuelPrice, setFuelPrice] = useState(null);
  const navigate = useNavigate();
  const showToaster = useShowToaster();

  useEffect(() => {
    getFullPriceShipment();
  }, []);

  const showWarning = () => {
    showToaster({ type: 'warning', message: 'This feature is not available for your organization.' });
  };

  const getFullPriceShipment = () => {
    return getFullPrices().then((res) => {
      if (res && res?.data) {
        setFuelPrice(res.data?.diesel?.price);
      }
      return res;
    });
  };

  const createShipment = () => {
    navigate('/planner', { state: { openCreate: true } });
  };
  return (
    <ModalBody className='p-0' style={{ borderRadius: '12px' }}>
      <div>
        <div className='p-3'>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <Typography variant='c1'>Empty Miles</Typography>
            <Typography variant='c1'>
              <CountUp end={Number(driverCustomCost?.emptyMiles)} duration={5} />
            </Typography>
          </div>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <Typography variant='c1'>Loaded Miles</Typography>
            <Typography variant='c1'>
              <CountUp end={Number(driverCustomCost?.loadedMiles)} duration={5} />
            </Typography>
          </div>

          <div className='d-flex justify-content-between align-items-center mb-2'>
            <Typography variant='c1'>Duration</Typography>
            <span className={styles.felCostSubText}>{driverCustomCost.totalDuration}</span>
          </div>
          <div>
            {/* <Typography variant="s2"> */}
            {/*    Cost Estimate */}
            {/* </Typography> */}
            <div className={styles.costEstimateBottomLine} />
            <div className='d-flex flex-column gap-2'>
              <div className='d-flex justify-content-between align-items-center'>
                <Typography variant='c1'>Driver Cost</Typography>
                <Typography variant='c1'>
                  {currency}{' '}
                  <CountUp
                    end={Number(driverCustomCost?.driver_cost)?.toFixed()}
                    duration={5}
                    decimal='.'
                    separator=','
                    suffix='.00'
                  />
                </Typography>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <Typography variant='c1'>
                  Estimated Fuel Cost{' '}
                  <span className={styles.felCostSubText}>
                    <CountUp end={fuelPrice} decimals={2} duration={5} />
                    {currency} /GAL DIESEL
                  </span>
                </Typography>
                <Typography variant='c1'>
                  {currency}{' '}
                  <CountUp
                    end={Number(driverCustomCost?.estimatedFuelCost)?.toFixed()}
                    duration={5}
                    decimal='.'
                    separator=','
                    suffix='.00'
                  />
                </Typography>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <Typography variant='c1'>Estimated Cost</Typography>
                <Typography variant='c1'>
                  <Typography variant='c1'>
                    {currency}{' '}
                    <CountUp
                      decimal='.'
                      separator=','
                      suffix='.00'
                      end={
                        (
                          Number(driverCustomCost?.estimatedFuelCost) + Number(driverCustomCost?.driver_cost)
                        ).toFixed() || 0
                      }
                      duration={5}
                    />
                  </Typography>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className='p-2 d-flex justify-content-end gap-2'>
          <CustomButton
            type='secondary'
            styleButton={{
              height: 32,
              padding: '2px 10px',
              margin: 0,
            }}
            title='Create Quote'
            styleTitle={{ fontSize: '12px' }}
            onClick={showWarning}
          />
          <CustomButton
            type='primary'
            styleButton={{
              height: 32,
              padding: '2px 10px',
              margin: 0,
            }}
            title='Create Shipment'
            styleTitle={{ fontSize: '12px' }}
            onClick={createShipment}
          />
        </div>
      </div>
    </ModalBody>
  );
};

export default DriverCustomCost;
