import React, { useEffect, useState } from 'react';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { getTypeOfCollisionGraph } from 'Api/AccidentsAndCrashes';
import TypeOfCollisionChart from '../Charts/TypeOfCollisionChart';
import { SBoxWrapper } from '../../StatsSection.styles';

const TypeOfCollision = ({ statsRefreshIndex }) => {
  const [countsData, setCountsData] = useState([]);

  const getCounts = async () => {
    try {
      const params = {};
      const { data } = await getTypeOfCollisionGraph(params);
      setCountsData(data.type_of_collisions);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCounts();
  }, [statsRefreshIndex]);

  return (
    <SBoxWrapper $minWidth='460px' $maxWidth='30%'>
      <div className='d-flex flex-column gap-1'>
        <Typography variant='s2'>TYPE OF COLLISION</Typography>
        <Typography variant='c3' style={{ color: palette.gray700 }}>
          WHERE THE EQUIPMENT WAS STRUCK
        </Typography>
      </div>
      <div className='chart-wrapper'>
        <TypeOfCollisionChart chartData={countsData} />
      </div>
    </SBoxWrapper>
  );
};

export default TypeOfCollision;
