import styled from 'styled-components';

export const SVerificationInputContainer = styled.div`
  display: flex;
  justify-content: center;

  .verification-input {
    width: 40px;
    text-align: center;

    input {
      border-radius: 0;
      height: 44px;
      font-size: 24px;
    }

    :first-child {
      input {
        border-radius: 6px 0 0 6px;
      }
    }

    :last-child {
      input {
        border-radius: 0 6px 6px 0;
      }
    }
  }
`;
