import React, { useState, useEffect } from 'react';
import moment from 'moment';
import rightArrow from 'assets/icons/drivers/table-logo/right-arrow.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { driverLogData } from 'Api/Driver';
import { useParams } from 'react-router-dom';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import calendar from 'assets/icons/drivers/calendar.svg';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import ViewPdf from 'components/ViewPdf';
import ColumnHeader from 'common/ColumnHeader';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import useDebounce from 'hooks/useDebounce';
import useDateFormat from 'hooks/useDateFormat';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import DateRange from '../DateRangePicker/DateRangePicker';
import './LogTab.css';
import TableFooter from '../TableFooter/TableFooter';
import MaterialTableWrapper from '../MaterialTableWrapper';
import PrimaryBtn from '../DriverProfileButton/DriverProfileButton';

const SENSITIVE_LOG_TYPES = [
  'General -> Tax ID',
  'General -> Tax Type',
  'General -> Passport',
  'General -> License No',
  'General -> License State',
  'General -> License City',
  'General -> License Zip',
  'General -> License Address2',
  'General -> License Address1',
  'General -> License Country',
  'General -> License Expiration Date',
];

const IncidentTabTable = () => {
  const { id } = useParams();
  const { use } = useTheme();
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const { formatDate, convertToCustomerTime } = useDateFormat();
  const [rowPerPage, setRowPerPage] = useState(25);
  const [driverLog, setDriverLog] = useState([]);
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rangeName, setRangeName] = useState('Last 30 days');
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    start: startOfDay(addDays(new Date(), -31)),
    end: endOfDay(new Date()),
  });
  const debouncedSearch = useDebounce(search, 500);

  const PaginationComponent = (event) => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={driverLog?.total}
        totalLength={driverLog?.data?.length}
        lastPage={driverLog?.last_page}
        currentPage={driverLog?.current_page}
        onChangeRowPerPage={onChangeRowPerPage}
        setPlanerData={(data) => setDriverLog(data)}
        onPageChange={onPageChange.bind(null, event)}
      />
    );
  };

  const onChangeRowPerPage = (rowPage) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('MM/DD/YYYY');
    const endFormat = moment(end).format('MM/DD/YYYY');
    setRowPerPage(rowPage);
    setLoading(true);
    const payload = {
      page: 1,
      itemsPerPage: rowPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      driver_id: id,
    };
    if (search) {
      payload.query = search;
    }
    driverLogData(payload)
      .then((res) => {
        setDriverLog({ ...res });
      })
      .finally(() => setLoading(false));
  };

  const onPageChange = (event, page) => {
    const { start, end } = dateRange;
    const startFormat = moment(start).format('MM/DD/YYYY');
    const endFormat = moment(end).format('MM/DD/YYYY');
    setLoading(true);
    event.onChangePage(event, page - 1);
    const filter = {
      page,
      itemsPerPage: rowPerPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      driver_id: id,
      query: search,
    };
    driverLogData(filter)
      .then((res) => {
        setDriverLog(res);
      })
      .finally(() => setLoading(false));
  };

  const getDriverIncidentData = () => {
    setLoading(true);
    const { start, end } = dateRange;
    const startFormat = moment(start).format('MM/DD/YYYY');
    const endFormat = moment(end).format('MM/DD/YYYY');
    const payload = {
      page: '1',
      itemsPerPage: rowPerPage,
      from_date: startFormat,
      to_date: endFormat,
      sortBy: 'desc',
      driver_id: id,
      query: debouncedSearch || undefined,
    };
    driverLogData(payload)
      .then((res) => {
        setLoading(false);
        setDriverLog(res);
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDriverIncidentData();
  }, [debouncedSearch, dateRange]);

  const dateSelect = (dates) => {
    setDateRange(dates);
  };

  const columns = [
    {
      field: 'change_description',
      title: <ColumnHeader title='CHANGED SECTION' field='change_description' />,
      render: (rowData) => (
        <div className='log-table-container-inner '>
          <div>
            <p className='m-0' style={{ color: use(palette.dark900, palette.gray200) }}>
              {rowData?.change_description}
            </p>
          </div>
        </div>
      ),
    },
    {
      field: 'change_from',
      title: <ColumnHeader title='CHANGED FROM / TO' field='change_from' />,
      render: (rowData) => (
        <div className='log-table-container-inner '>
          {!!hideSensitiveInfo && SENSITIVE_LOG_TYPES.includes(rowData.change_description) ? (
            <div className='log-table-box1'>
              <p className='m-0' style={{ color: use(palette.dark900, palette.gray200) }}>
                {rowData?.change_from ? '****' : ''}
              </p>
              <div>
                {rowData?.change_to && (
                  <span className='upload-icon ms-2'>
                    <img src={rightArrow} alt='upload' />
                  </span>
                )}
              </div>
              <p className='m-0' style={{ color: use(palette.dark900, palette.gray200) }}>
                {rowData?.change_to ? '****' : ''}
              </p>
            </div>
          ) : (
            <div className='log-table-box1'>
              {rowData.change_type === 'image' && rowData?.change_from ? (
                <span
                  className='tablePlaner_status'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    backgroundColor: '#F0F2F7',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    marginRight: 10,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setPdfUrl(rowData?.change_from)}
                >
                  {rowData?.change_from && 'View Old Attachment'}
                </span>
              ) : (
                <p className='m-0' style={{ color: use(palette.dark900, palette.gray200) }}>
                  {rowData.change_type === 'date' && rowData?.change_from
                    ? formatDate(rowData.change_from)
                    : rowData?.change_from}
                </p>
              )}
              <div>
                {rowData?.change_to && (
                  <span className='upload-icon ms-2'>
                    <img src={rightArrow} alt='upload' />
                  </span>
                )}
              </div>
              {rowData.change_type === 'image' ? (
                <span
                  className='tablePlaner_status'
                  style={{
                    color: use(palette.gray600, palette.gray200),
                    backgroundColor: '#F0F2F7',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    marginLeft: 10,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setPdfUrl(rowData.change_to)}
                >
                  {rowData.change_to && 'View New Attachment'}
                </span>
              ) : (
                <p className='m-0' style={{ color: use(palette.dark900, palette.gray200) }}>
                  {rowData.change_type === 'date' && rowData?.change_to
                    ? formatDate(rowData.change_to)
                    : rowData?.change_to}
                </p>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='CHANGED BY' field='created_at' />,
      render: (rowData) => (
        <Typography variant='b2'>
          By{' '}
          {rowData?.change_by_user_type === 'staff'
            ? rowData?.change_by_user_details?.first_name
            : rowData?.change_by_driver_details?.first_name}{' '}
          {rowData?.change_by_user_type === 'staff'
            ? rowData?.change_by_user_details?.last_name
            : rowData?.change_by_driver_details?.last_name}{' '}
          on {convertToCustomerTime(rowData?.created_at)}
        </Typography>
      ),
    },
  ];

  return (
    <div className='log-table-container' style={{ background: palette.white, borderColor: palette.gray50 }}>
      <div className='log-header-container'>
        <div className='log-header-left'>
          <div className='headingCover'>
            <p className='heading log-heading' style={{ color: palette.gray900 }}>
              Log
            </p>
          </div>
          <div className='serach-wrapper'>
            <div className='position-relative'>
              <input
                type='text'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className='serach-feild'
                placeholder='Search'
                style={{
                  backgroundColor: use(palette.white, palette.dark800),
                  color: use(palette.gray400, palette.white),
                  borderColor: use(palette.gray50, palette.darkborder),
                  padding: '4px 24px 4px 35px',
                }}
              />
              {!!search?.length && (
                <DeleteIcon
                  width={12}
                  height={12}
                  fill={palette.gray300}
                  style={{ position: 'absolute', right: '8px', top: '8px', cursor: 'pointer' }}
                  onClick={() => setSearch('')}
                />
              )}
            </div>
            <div className='calender-wrap'>
              {isShowDatePicker && (
                <div style={{ position: 'relative', left: '117px' }}>
                  <DateRange
                    title='All Time'
                    dateTimeRange={dateRange}
                    dateSelect={dateSelect}
                    rangeName={rangeName}
                    setRangeName={setRangeName}
                    setIsShowDatePicker={setIsShowDatePicker}
                  />
                </div>
              )}
              <PrimaryBtn icon={calendar} title={rangeName} onClick={() => setIsShowDatePicker(true)} />
            </div>
          </div>
        </div>
        <div className='log-header-right' />
      </div>
      {loading ? (
        <TableSkeleton />
      ) : (
        <div className='table-wrap'>
          <div className='sub-table-container'>
            <MaterialTableWrapper
              data={driverLog?.data}
              rowPerPage={rowPerPage}
              style={{ backgroundColor: palette.white }}
              components={{ Pagination: PaginationComponent }}
              columns={columns}
            />
          </div>
        </div>
      )}
      <ViewPdf title='View Document' open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
    </div>
  );
};

export default IncidentTabTable;
