import React, { useState } from 'react';
import { ReactComponent as Check } from 'assets/icons/checkBoxDark.svg';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import RecommendedActionsEdit from './RecommendedActionsEdit';
import { validationSchema } from './validationSchema';
import { getRowId } from '../Incidents.data';
import { SFormWrapper, SActions, SAddMore } from '../Incidents.styles';

const riskLevelOptions = [
  { id: 1, label: 'Low Risk', risk_level: 'L' },
  { id: 2, label: 'High Risk', risk_level: 'H' },
];

const infractionTypeOptions = [
  { id: 1, label: 'Safety' },
  { id: 2, label: 'Conduct' },
];

const ItemForm = ({ onSubmit, onCancel, item, defaultItem, actionTakenOptions }) => {
  const [openRecommendedActions, setOpenRecommendedActions] = useState(false);

  const initialValues = {
    type: item?.type ? item?.type : '',
    type_for: item?.type_for === 'driver',
    risk_level: item?.risk_level ? riskLevelOptions.find((i) => i.risk_level === item.risk_level) : null,
    infraction_type: item?.infraction_type_id
      ? infractionTypeOptions.find((i) => i.id === item.infraction_type_id)
      : null,
    recommended_actions: item?.incident_actions?.length
      ? item.incident_actions.map((action) => ({
          ...actionTakenOptions.find((opt) => opt.id === action.id),
          rowId: getRowId(),
        }))
      : [],
  };

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const { handleChange, handleSubmit, values, touchedErrors } = form;

  const onRiskLevelSelect = (value) => {
    handleChange('risk_level', value);
  };

  return (
    <SFormWrapper>
      <div className='flex-item'>
        {!defaultItem ? (
          <Input
            name='type'
            onChange={handleChange}
            value={values.type}
            placeholder='Incident Type'
            error={touchedErrors.type}
            style={{ maxWidth: '250px' }}
          />
        ) : (
          <Typography variant='s2'>{item.type}</Typography>
        )}
      </div>
      <div className='flex-item'>
        <Autocomplete
          name='risk_level'
          placeholder='Select Risk Level...'
          labelKey='label'
          options={riskLevelOptions || []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.risk_level}
          onChange={(e, value) => onRiskLevelSelect(value)}
          error={touchedErrors.risk_level}
          width='160px'
        />
      </div>
      <div className='flex-item'>
        <SAddMore onClick={() => setOpenRecommendedActions(true)}>
          <Typography variant='s2' style={{ color: palette.indigo500, cursor: 'pointer' }}>
            {item?.incident_actions?.length ? 'Edit' : 'Add'}
          </Typography>
        </SAddMore>
      </div>
      <div className='flex-item'>
        <Autocomplete
          name='infraction_type'
          placeholder='Select Infraction Type...'
          labelKey='label'
          options={infractionTypeOptions || []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.infraction_type}
          onChange={(e, value) => handleChange('infraction_type', value)}
          error={touchedErrors.infraction_type}
          width='160px'
        />
      </div>
      <div className='flex-item d-flex align-items-center justify-content-center pe-4'>
        <CustomCheckbox
          name='type_for'
          checked={values.type_for}
          onChange={(checked) => handleChange('type_for', checked)}
        />
      </div>
      <SActions className='flex-item'>
        <CustomButton
          width={12}
          height={12}
          type='secondary'
          title='Cancel'
          styleButton={{ padding: '1px 6px', margin: 0 }}
          onClick={onCancel}
        />
        <CustomButton
          width={12}
          height={12}
          type='primary'
          title=''
          leftIcon={<Check style={{ fill: '#FFFFFF' }} />}
          styleButton={{ padding: '7px 8px', margin: 0 }}
          onClick={handleSubmit}
        />
      </SActions>
      {openRecommendedActions && (
        <RecommendedActionsEdit
          title={values.type}
          open={openRecommendedActions}
          onClose={() => setOpenRecommendedActions(false)}
          form={form}
          actionTakenOptions={actionTakenOptions}
        />
      )}
    </SFormWrapper>
  );
};

export default ItemForm;
