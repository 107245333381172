import React, { useMemo } from 'react';
import TimePicker from 'react-time-picker-input';
import 'react-time-picker-input/dist/components/TimeInput.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';

const CustomTimePicker = ({
  field,
  form,
  style,
  className,
  hour12Format = false,
  allowDelete = true,
  disabled = false,
  eachInputDropdown = false,
  manuallyDisplayDropdown = false,
  ...props
}) => {
  const { use } = useTheme();
  const DEFAULT_VALUE = '--:--';

  const valueInput = useMemo(() => {
    if (field?.value !== undefined) {
      return !field.value ? DEFAULT_VALUE : field?.value;
    }
    if (props?.value !== undefined) {
      return !props.value ? DEFAULT_VALUE : props?.value;
    }
    return DEFAULT_VALUE;
  }, [field?.value, props?.value]);

  function onChangeHandler(value) {
    const regex = /[\d-]/g;
    const match = value.match(regex);
    const val = match.find((v) => v !== '-');

    /** when input value DEFAULT_VALUE but got to empty* */
    if ((val === undefined && value?.includes('aN')) || (value === DEFAULT_VALUE && value?.includes('aN'))) {
      setTouched();
      form && form.setFieldValue(field.name, '');
      props?.onChange && props.onChange('');
    } else {
      /** input value half done and value go to empty but input changed value* */
      if (value?.includes('-') && value?.includes('aN')) {
        setTouched();
        form && form.setFieldValue(field.name, '');
        props?.onChange && props.onChange('');
      } else {
        if (value !== DEFAULT_VALUE) {
          setTouched();
          form && form.setFieldValue(field.name, value);
          props?.onChange && props.onChange(value);
        } else {
          form && form.setFieldValue(field.name, '');
          props?.onChange && props.onChange('');
        }
      }
    }
  }

  const initialStyle = useMemo(() => {
    return {
      wrapper: {
        width: 105,
        height: 32,
        margin: 1,
        display: 'flex',
        borderRadius: 4,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: use(palette.white, palette.dark800),
        boxShadow: '0 1px 2px rgb(0 0 0 / 6%), 0 0 0 1px rgb(104 113 130 / 16%)',
      },
    };
  }, [use, palette]);

  const setTouched = () => {
    form && form.setFieldTouched(field.name, true);
  };

  return (
    <div
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
      tabIndex={0}
      className={`react-timer-picker_wrapper ${use('light', 'dark')} ${className}`}
      style={{ ...initialStyle.wrapper, ...style }}
      onBlur={setTouched}
    >
      <TimePicker
        {...props}
        value={valueInput}
        disabled={disabled}
        allowDelete={allowDelete}
        onChange={onChangeHandler}
        hour12Format={hour12Format}
        eachInputDropdown={eachInputDropdown}
        manuallyDisplayDropdown={manuallyDisplayDropdown}
      />
    </div>
  );
};

export default React.memo(CustomTimePicker);
