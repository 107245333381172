import React, { Fragment } from 'react';
import StopBox from '../StopBox';
import StopPoint from '../StopPoint';
import { SSplitSection } from './SplitSection.styles';

const SplitSection = ({ data, onStopUpdate }) => {
  return (
    <SSplitSection>
      {data?.map((stop, index) => (
        <Fragment key={stop.id.toString()}>
          {stop.isEmpty ? (
            <StopBox stop={stop} index={index} />
          ) : (
            <StopPoint stop={stop} index={index} onStopUpdate={onStopUpdate} stops={data} />
          )}
        </Fragment>
      ))}
    </SSplitSection>
  );
};

export default SplitSection;
