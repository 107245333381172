import React from 'react';
import Pagination from 'common/Pagination';
import TableWrapper from 'components/TableWrapper';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';

const ShipmentInvoiceTable = ({ loading, data, selectedFilters, setSelectedFilters, onSendInvoice, columns }) => {
  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  if (loading) {
    return <TablePreLoader styleWrapper={{ marginTop: 0 }} />;
  }

  return (
    <div className='tableFixHead table-fixed-header-300'>
      <TableWrapper
        data={data?.data}
        rowPerPage={selectedFilters.itemsPerPage}
        style={{ backgroundColor: palette.white }}
        components={{
          Pagination: () =>
            Pagination({
              data,
              rowPerPage: selectedFilters.itemsPerPage,
              onChangeRowPerPage,
              onPageChange,
              rowsPerPageOptions: [25, 50, 100, 150, 250, 500],
            }),
        }}
        onRowClick={(e, rowData) => onSendInvoice(rowData)}
        columns={columns}
      />
    </div>
  );
};

export default ShipmentInvoiceTable;
