import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import northAmericaMap from 'assets/combined-us-canada-mexico-with-states-provinces.geojson';
import { palette } from 'utils/constants';
import Tooltip from 'common/Tooltip';
import { getStats, updateCarrierOperations } from 'Api/Carriers';
import { getEquipmentRequiredTrailers, getEquipmentRequiredVehicles } from 'Api/Planner';
import Autocomplete from 'common/Autocomplete';
import InputLabel from 'common/InputLabel';
import useShowToaster from 'hooks/useShowToaster';
import { CarrierContext } from 'context/tableContext';

const OperationsTab = ({ carrierInfo, onSuccess }) => {
  const { id } = useParams();
  const showToaster = useShowToaster();
  const { operating_states, equipment_types, can_travel_to } = carrierInfo || {};
  const [selectedStates, setSelectedStates] = useState(operating_states || []);
  const [states, setStates] = useState({});
  const [tooltipContent, setTooltipContent] = useState('');
  const [equipmentRequired, setEquipmentRequired] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState(
    equipment_types
      ? equipment_types.map((item) => ({ ...item, title: item.id === 1 ? 'Truck (Power Only)' : item.title }))
      : []
  );
  const { setOpenEdit } = useContext(CarrierContext);

  const toggleState = (stateId) => {
    const partToCheck = stateId.includes('-') ? stateId.split('-').pop() : stateId;
    const country = stateId.includes('-') ? stateId.split('-')?.[0] : stateId;

    const checkCountryId = country === 'US' ? 1 : country === 'CA' ? 2 : 3;

    if (can_travel_to?.includes(String(checkCountryId))) {
      const currentState = states.find((state) => state.short_name === partToCheck);
      setSelectedStates((prev) => {
        const isStatePresent = prev.some((item) => item.id === currentState.id);

        const newState = isStatePresent ? prev.filter((item) => item.id !== currentState.id) : [...prev, currentState];

        const stateIds = newState?.map((item) => item.id)?.filter((i) => Number(i));
        const params = {
          operating_states: stateIds,
        };
        updateCarrierOperations(params, id).then(() => {});
        return newState;
      });
    } else {
      showToaster({ type: 'error', message: 'Carrier can not travel to that country' });
    }
  };

  useEffect(() => {
    const params = {
      'country_id[]': ['38', '231', '142'],
    };

    getStats(params).then((res) => {
      const statesWithCountries = res.data
        .map((item) => {
          if (item.id && item.short_name) {
            return item;
          }
          return null;
        })
        .filter((item) => item !== null);

      if (statesWithCountries) {
        if (can_travel_to?.includes('1')) {
          statesWithCountries.unshift({ short_name: 'All United States', id: 'usa' });
        }
        if (can_travel_to?.includes('2')) {
          statesWithCountries.unshift({ short_name: 'All Canada', id: 'canada' });
        }
        if (can_travel_to?.includes('3')) {
          statesWithCountries.unshift({ short_name: 'All Mexico', id: 'mexico' });
        }
      }

      setStates(statesWithCountries);
      if (carrierInfo?.all_states_usa) {
        setSelectedStates((prevState) => [{ short_name: 'All United States', id: 'usa' }, ...prevState]);
      }
      if (carrierInfo?.all_states_canada) {
        setSelectedStates((prevState) => [{ short_name: 'All Canada', id: 'canada' }, ...prevState]);
      }
      if (carrierInfo?.all_states_mexico) {
        setSelectedStates((prevState) => [{ short_name: 'All Mexico', id: 'mexico' }, ...prevState]);
      }
    });

    getEquipmentRequired().then();
  }, []);

  useEffect(() => {
    return () => {
      setOpenEdit('');
    };
  }, []);

  const getEquipmentRequired = useCallback(async () => {
    return Promise.all([getEquipmentRequiredVehicles(), getEquipmentRequiredTrailers()])
      .then(([vehicles, trailers]) => {
        const data = [];

        if (vehicles?.data) {
          const addFlagVehicles = vehicles?.data.map((el) => {
            if ([1, 2, 3, 4, 5, 10].includes(+el.id)) {
              return {
                id: el.id,
                title: el.id === 1 ? 'Truck (Power Only)' : el.title,
                flag: 'vehicles',
                labelSelected: null,
                noLengthField: true,
                type: el?.vehicle_type_id,
              };
            }
            return {
              id: el.id,
              title: el.title,
              flag: 'vehicles',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagVehicles);
        }

        if (trailers?.data) {
          const addFlagTrailers = trailers?.data.map((el) => {
            return {
              id: el.id,
              title: el.title,
              flag: 'trailers',
              labelSelected: null,
              type: el?.vehicle_type_id,
            };
          });
          data.push(...addFlagTrailers);
        }
        data.sort((a, b) => a.key - b.key);

        setEquipmentRequired(data);
        return data;
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const handleChange = (value) => {
    setEquipmentTypes(value);
    const newState = value.map((item) => item.id);
    const params = {
      equipment_types: newState,
    };
    updateCarrierOperations(params, id).then(() => {
      onSuccess();
    });
  };

  const handleStateChange = (value, reason, details) => {
    const updateStatesList = (countryId, countryName, country_id) => {
      if (reason === 'selectOption' && details.option.id === countryId) {
        return states.filter(
          (item) => item.country?.name === countryName && selectedStates.every((i) => item.id !== i.id)
        );
      }
      if (reason === 'removeOption' && details.option.id === countryId) {
        value = value.filter((item) => (item.country_id || item.country?.id) !== country_id);
        return [];
      }
      return [];
    };

    const allUsaStates = updateStatesList('usa', 'United States', 231);
    const allCanadaStates = updateStatesList('canada', 'Canada', 38);
    const allMexicoStates = updateStatesList('mexico', 'Mexico', 142);

    const mergedValues = [...value, ...allUsaStates, ...allCanadaStates, ...allMexicoStates];
    setSelectedStates(mergedValues);
    const stateIds = mergedValues.map((item) => item.id).filter((i) => Number(i));
    const params = {
      operating_states: stateIds,
    };
    updateCarrierOperations(params, id).then(() => {
      onSuccess();
    });
  };

  return (
    <div>
      <div className='mb-4 w-50'>
        <div className='w-100 mt-2'>
          <InputLabel>Please select carrier equipment type:</InputLabel>
          <Autocomplete
            labelKey='title'
            multiple
            options={equipmentRequired}
            placeholder={equipmentTypes?.length ? '' : 'Select Equipment Type'}
            value={equipmentTypes}
            onChange={(e, val) => handleChange(val)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
          />
        </div>
      </div>

      <div className='w-50 mt-2'>
        <InputLabel>Please select where the carrier operates:</InputLabel>
        <Autocomplete
          labelKey='short_name'
          multiple
          options={states}
          placeholder={selectedStates?.length ? '' : 'Select States'}
          value={selectedStates}
          onChange={(e, val, reason, details) => handleStateChange(val, reason, details)}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
        />
      </div>
      <ComposableMap
        projection='geoMercator'
        projectionConfig={{
          scale: 200,
          center: [-100, 60],
        }}
        style={{
          width: '100%',
          height: 'auto',
          margin: '0',
          display: 'block',
        }}
      >
        <Tooltip
          title={tooltipContent}
          placement='top'
          arrow={false}
          componentsProps={{
            placement: 'top',
            tooltip: {
              sx: {
                fontSize: 16,
                fontFamily: 'Inter',
                whiteSpace: 'nowrap',
                maxWidth: 'unset',
              },
            },
          }}
        >
          <Geographies geography={northAmericaMap}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const partToCheck = geo.id?.includes('-') ? geo.id?.split('-').pop() : geo?.id;
                const isStateSelected = selectedStates.some((item) => item.short_name === partToCheck);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => {
                      toggleState(geo.id);
                    }}
                    onMouseEnter={() => {
                      const { name } = geo.properties;
                      setTooltipContent(name);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                    style={{
                      default: {
                        fill: isStateSelected ? palette.indigo500 : palette.gray700,
                        stroke: '#FFF',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                      hover: {
                        fill: palette.indigo200,
                        stroke: '#FFF',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                      pressed: {
                        fill: palette.indigo500,
                        stroke: '#FFF',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </Tooltip>
      </ComposableMap>
    </div>
  );
};

export default OperationsTab;
