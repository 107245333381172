import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteThin.svg';
import Modal from 'common/Modal';
import { palette } from 'utils/constants';
import { SImageContainer } from './ViewAttachment.styles';

const ViewAttachment = ({ open, onClose, attachment, title = 'Attachment', isVideo = false }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={title}
      $minHeight='100px'
      $bgColor={palette.gray0}
      backdropClassName='double-modal-backdrop'
      footer={false}
      customBody={{ borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
    >
      <CloseIcon className='close-modal-icon' fill={palette.red500} onClick={onClose} />
      {!isVideo ? (
        <SImageContainer $attachment={attachment} />
      ) : (
        <video width='100%' controls autoPlay>
          <source src={attachment} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </Modal>
  );
};

export default ViewAttachment;
