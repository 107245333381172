import React, { useMemo, useRef, useState, useEffect } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { createCustomerDelayCode } from 'Api/Customers';
import { getDelayReasonsList } from 'Api/CompanySettings';
import sm from './ContactBook.module.css';
import { Typography } from '../../Typography';
import CustomInput from '../../CreateShipment/helpers/CustomInput';
import CustomButton from '../../CustomButton/CustomButton';
import CustomSelect from '../../CreateShipment/ShipmentStops/helpers/CustomSelect';

const DelayCodes = ({ Cancel, stepsDone, nextStep, customer }) => {
  const [onNextloading, setOnNextloading] = useState(false);
  const formRef = useRef();
  const [reasons, setReasons] = useState();

  const onSkip = () => {
    nextStep();
  };

  const handleSubmit = () => {
    if (formRef.current) {
      if (formRef.current.isValid && formRef.current.dirty) {
        onNextStep(formRef.current.values.delay_codes);
      }
    }
  };
  const { use } = useTheme();
  const defaultValue = {
    reason_id: '',
    edi_api_code: '',
  };
  const titlesOption = useMemo(() => {
    return [
      { key: 1, label: 'Reason', width: '30%', required: true },
      { key: 2, label: 'EDI/API Code', width: '15%', required: true },
    ];
  }, []);

  const [showMessage, setShowMessage] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  useEffect(() => {
    getDelayReasonsList().then((res) => {
      if (res && res?.data) {
        const options = res.data.map((el) => {
          return {
            ...el,
            key: el.id,
            label: el.title,
            labelSelected: null,
          };
        });
        setReasons(options);
      }
    });
  }, []);
  const onNextStep = (values) => {
    nextStep();
    if (stepsDone) {
      // Do nothing
    } else {
      setOnNextloading(true);
      const f = new FormData();
      values.forEach((item, index) => {
        f.append(`delay_codes[${index}][reason_id]`, item.reason_id);
        f.append(`delay_codes[${index}][edi_api_code]`, item.edi_api_code);
      });
      f.append(`customer_id`, customer.basicDetails.customer);
      createCustomerDelayCode(f)
        .then((res) => {
          if (res?.status === 200) {
            setOnNextloading(false);
            setShowMessage({ ...showMessage, message: 'EDI/API Code added successfully!', visible: true });
            nextStep(res.data);
          }
        })
        .catch(() => setOnNextloading(false));
    }
  };

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: use(palette.red500, palette.red800),
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: use(palette.indigo500, palette.indigo500),
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette, use]);

  const initialValue = customer.delayCodes.data
    ? { delay_codes: customer.delayCodes.data }
    : { delay_codes: [{ ...defaultValue }] };

  return (
    <>
      <Modal.Body className='add-driver-modal-body'>
        <div className={sm.container_wrapper}>
          <div
            className={sm.container}
            style={{ backgroundColor: use(palette.gray0, palette.dark800), height: '100%' }}
          >
            <div className={sm.body}>
              <Formik initialValues={initialValue} innerRef={formRef}>
                {({ values, getFieldHelpers }) => (
                  <Form style={{ width: '100%' }}>
                    <FieldArray
                      name='delay_codes'
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            <div className={sm.row} style={{ justifyContent: 'flex-start', marginTop: 0 }}>
                              {titlesOption.map((el) => {
                                return (
                                  <Typography
                                    key={el?.label}
                                    variant='s2'
                                    style={{ width: el.width, whiteSpace: 'nowrap', marginRight: 10 }}
                                  >
                                    {el.label}
                                    {el.required && <span className={sm.required_system}>*</span>}
                                  </Typography>
                                );
                              })}
                            </div>

                            {values.delay_codes.map((el, index) => {
                              const namePrefix = `delay_codes[${index}]`;
                              return (
                                <div
                                  key={el?.id}
                                  className={[sm.row, sm.line].join(' ')}
                                  style={{ marginTop: 0, justifyContent: 'flex-start' }}
                                >
                                  <div style={{ width: '30%', marginRight: 10 }}>
                                    <div>
                                      <Field
                                        name={`${namePrefix}[reason_id]`}
                                        options={reasons}
                                        component={CustomSelect}
                                        styles={styles.type}
                                        labelStyle={styles.labelStyle}
                                        validate={validateStopPointInput}
                                        onChange={(value) => {
                                          getFieldHelpers(`delay_codes[${index}][edi_api_code]`).setValue(value.code);
                                          getFieldHelpers(`delay_codes[${index}][reason_id]`).setValue(value);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`${namePrefix}[reason_id]`}
                                        render={(error) => (
                                          <Typography variant='c2' style={styles.error}>
                                            {error}
                                          </Typography>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ width: '30%' }}>
                                    <Field
                                      name={`${namePrefix}[edi_api_code]`}
                                      component={CustomInput}
                                      style={{ paddingLeft: 10, width: '100%' }}
                                      labelStyle={styles.labelStyle}
                                      validate={validateStopPointInput}
                                      className={sm.input_basic_details}
                                    />
                                    <ErrorMessage
                                      name={`${namePrefix}[edi_api_code]`}
                                      render={(error) => (
                                        <Typography variant='c2' style={styles.error}>
                                          {error}
                                        </Typography>
                                      )}
                                    />
                                  </div>

                                  {values.delay_codes.length > 1 && index !== 0 ? (
                                    <DeleteIcon
                                      width={12}
                                      height={12}
                                      fill={palette.indigo500}
                                      style={styles.deleteIcon}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  ) : (
                                    <span style={{ width: 28 }} />
                                  )}
                                </div>
                              );
                            })}

                            <CustomButton
                              type='secondary'
                              title='Add Another'
                              styleTitle={styles.addAnotherTitle}
                              styleButton={styles.addAnotherButton}
                              onClick={() => arrayHelpers.push({ ...defaultValue })}
                              leftIcon={<PlusIcon fill={use(palette.indigo500, palette.indigo500)} />}
                            />
                          </div>
                        );
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={Cancel}>
              Cancel
            </Button>
          </div>
          <div className='pagination-btn'>
            <Button className='cancel-button' onClick={onSkip}>
              Skip
            </Button>
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button onClick={handleSubmit} className='next-step'>
                Next Step
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default DelayCodes;
