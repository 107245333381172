import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { getPaymentMethod } from 'store/actions/billing.actions';
import OrderSummary from './Cart/OrderSummary';
import PaymentAgreement from './PaymentAgreement/PaymentAgreement';
import style from './PurcheseSteps.module.css';

const PurchaseSteps = ({
  setHardwareSteps,
  purchaseDevice,
  setPurchaseDevice,
  equipment,
  handleClose,
  CartItemQty,
  UpdateCart,
  onSuccess = () => null,
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(1);
  const statusHandler = (status) => {
    setStatus(status);
  };
  const nextStep = () => {
    setStatus(status + 1);
  };
  const prevStep = () => {
    setStatus(status - 1);
  };

  useEffect(() => {
    dispatch(getPaymentMethod());
  }, []);

  return (
    <div>
      <Modal.Header className={style['add-equipment-modal-header']}>
        <div className={style['add-steps-wrapper']}>
          <div className={status >= 1 ? style['purchese-step-heading-active'] : style['purchese-steps-heading']}>
            {status > 1 ? (
              <div className={style['rounded-checkbox-wrapper']}>
                <input className={style['check-box']} type='checkbox' value='' id='flexCheckChecked' checked />
                <span>Order Summary</span>
              </div>
            ) : (
              <div className={style['rounded-checkbox-wrapper']}>
                <input
                  type='radio'
                  name=''
                  disabled={status < 1}
                  checked={status >= 1}
                  onClick={() => statusHandler(1)}
                  className={style['input-style']}
                  style={{ marginBottom: '0px' }}
                />
                <span>Order Summary</span>
              </div>
            )}

            <div className={style['purchese-progress']}>
              <div
                className={
                  status === 1
                    ? style['incomplete-progressbar']
                    : status > 1
                    ? style['equipment-complete-progressbar']
                    : ''
                }
                role='progressbar'
                aria-valuenow='50'
                aria-valuemin='0'
                aria-valuemax='100'
              />
            </div>
          </div>

          <div className={status >= 2 ? style['purchese-step-heading-active'] : style['purchese-steps-heading']}>
            {status > 2 ? (
              <div className={style['rounded-checkbox-wrapper']}>
                <input className={style['check-box']} type='checkbox' value='' id='flexCheckChecked' checked />
                <span>Agreement</span>
              </div>
            ) : (
              <div className={style['rounded-checkbox-wrapper']}>
                <input
                  type='radio'
                  name=''
                  disabled={status !== 2}
                  checked={status === 2}
                  onClick={() => statusHandler(2)}
                  className={style['input-style']}
                  style={{ marginBottom: '0px' }}
                />
                <span>Agreement</span>
              </div>
            )}

            <div className={style['purchese-progress']}>
              <div
                className={
                  status === 2
                    ? style['incomplete-progressbar']
                    : status > 2
                    ? style['equipment-complete-progressbar']
                    : ''
                }
                role='progressbar'
                aria-valuenow='50'
                aria-valuemin='0'
                aria-valuemax='100'
              />
            </div>
          </div>
        </div>
      </Modal.Header>
      {status === 1 && (
        <div>
          <OrderSummary
            nextStep={nextStep}
            prevStep={prevStep}
            purchaseDevice={purchaseDevice}
            setPurchaseDevice={setPurchaseDevice}
            setHardwareSteps={setHardwareSteps}
            equipment={equipment}
            CartItemQty={CartItemQty}
            handleClose={handleClose}
            UpdateCart={UpdateCart}
            onSuccess={onSuccess}
          />
        </div>
      )}
      {status === 2 && (
        <div>
          <PaymentAgreement
            nextStep={nextStep}
            prevStep={prevStep}
            purchaseDevice={purchaseDevice}
            setPurchaseDevice={setPurchaseDevice}
            setHardwareSteps={setHardwareSteps}
            equipment={equipment}
            handleClose={handleClose}
          />
        </div>
      )}
    </div>
  );
};

export default PurchaseSteps;
