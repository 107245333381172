import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from 'context/themeContext';
import { palette } from 'utils/constants';

import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Arrived } from 'assets/icons/arrived.svg';
import { ReactComponent as DelayedInfo } from 'assets/icons/delayedInfo.svg';
import { ReactComponent as Departed } from 'assets/icons/departed.svg';
import { ReactComponent as Document } from 'assets/icons/documentAudrey.svg';
import { ReactComponent as Driver } from 'assets/icons/driver.svg';
import { ReactComponent as Route } from 'assets/icons/onRoute.svg';
import { ReactComponent as EdiApiIcon } from 'assets/icons/ediApi.svg';
import { ReactComponent as FourHourIcon } from 'assets/icons/FourHour.svg';
import { ReactComponent as ReceivedOfferIcon } from 'assets/icons/received-offer.svg';
import { ReactComponent as OfferAcceptedIcon } from 'assets/icons/offer-accepted.svg';
import { ReactComponent as OfferDeclinedIcon } from 'assets/icons/offer-declined.svg';
import { ReactComponent as DriverConfirmedIcon } from 'assets/icons/driver-confirmed.svg';
import { ReactComponent as DriverWaitingIcon } from 'assets/icons/driver-waiting.svg';
import { ReactComponent as AtDockIcon } from 'assets/icons/at-dock.svg';
import { ReactComponent as ShipmentCompletedIcon } from 'assets/icons/shipment-completed.svg';
import { Typography } from '../Typography';
import classes from './tableShipments.module.css';
import styles from '../Map/VehicleContainer.module.css';

const TableDrawer = ({
  isOpen,
  setIsOpen,
  width,
  scrollHeight = '75vh',
  data,
  moreData,
  page,
  activityStyles = {},
  ...props
}) => {
  const { use } = useTheme();
  const ref = useRef(null);
  const refBody = useRef(null);
  const styleDrawer = useMemo(() => {
    const style = { ...activityStyles };

    if (isOpen) {
      style.transform = 'translateX(20px)';
    } else {
      style.transform = `translateX(${width + 20}px)`;
    }
    return style;
  }, [ref?.current, isOpen, width]);

  useEffect(() => {
    if (refBody?.current) {
      const top = Number(refBody?.current.getBoundingClientRect().top) + document.documentElement.scrollTop + 40;
      const height = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      refBody.current.style.height = `${height - top - 44}px`;
    }
  }, [props.filterTableTop, props.loading]);

  const getIcon = useCallback((icon) => {
    const map = {
      47: <FourHourIcon style={{ flexShrink: 0 }} />,
      48: <ReceivedOfferIcon style={{ flexShrink: 0 }} />,
      49: <OfferAcceptedIcon style={{ flexShrink: 0 }} />,
      50: <OfferDeclinedIcon style={{ flexShrink: 0 }} />,
      51: <EdiApiIcon style={{ flexShrink: 0 }} />,
      52: <Driver style={{ flexShrink: 0 }} />,
      53: <DriverConfirmedIcon style={{ flexShrink: 0 }} />,
      54: <DelayedInfo style={{ flexShrink: 0 }} />,
      55: <DriverWaitingIcon style={{ flexShrink: 0 }} />,
      56: <AtDockIcon style={{ flexShrink: 0 }} />,
      57: <DelayedInfo style={{ flexShrink: 0 }} />,
      58: <ShipmentCompletedIcon style={{ flexShrink: 0 }} />,
      59: <Arrived style={{ flexShrink: 0 }} />,
      60: <Departed style={{ flexShrink: 0 }} />,
      61: <Document style={{ flexShrink: 0 }} />,
      routeDelayed: <Route style={{ flexShrink: 0 }} fill={palette.red200} />,
    };
    return map?.[icon] ? map[icon] : <Route />;
  }, []);

  return (
    <div
      ref={ref}
      style={{ ...styleDrawer, backgroundColor: use(palette.gray0, palette.dark800), width: width || '350px' }}
      className={classNames(classes.tableDrawer, use(classes.light, classes.dark))}
    >
      <div style={{ backgroundColor: use(palette.white, palette.dark800) }} className={classes.header}>
        <Typography variant='button1'>Recent Activity</Typography>
        <div className={classes.closeDrawer} onClick={() => setIsOpen(false)}>
          <Minus />
        </div>
      </div>
      <div ref={refBody} className={classes.bodyDrawer}>
        <InfiniteScroll
          className={styles.InfiniteScrollDiv}
          dataLength={data?.length}
          next={moreData}
          hasMore
          height={scrollHeight}
          pullDownToRefreshThreshold={50}
        >
          {data.map((el, index) => {
            const { alert_message, alert_type, created_at, take_action, shipment_id } = el || {};
            const Icon = getIcon(take_action);
            return (
              <div key={`${el.id}${Math.random()}`} className={classes.drawerItem}>
                <div className={classes.drawerItemIconWrapper}>
                  {Icon}
                  {index !== data.length - 1 && <div className={classes.verticalLine} />}
                </div>
                <div className={classes.drawerItemTexts}>
                  <Typography variant='button1'>
                    {alert_type} {shipment_id ? `- ${shipment_id}` : ''}
                  </Typography>
                  <Typography variant='c1'>{alert_message}</Typography>
                  <Typography variant='overLine'>{moment(created_at).fromNow()}</Typography>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default TableDrawer;
