import React from 'react';
import Tab from '@mui/material/Tab';
import { SColorLabel, STabs } from './Tabs.styles';

const Tabs = ({ tabs, activeTab, handleTabChange }) => {
  return (
    <STabs value={activeTab} onChange={(e, newValue) => handleTabChange(newValue)}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.key || tab.value}
          id={`tab-${index}`}
          value={tab.value}
          label={
            tab.color ? (
              <>
                <SColorLabel $color={tab.color} />
                {tab.label}
              </>
            ) : (
              tab.label
            )
          }
          iconPosition='end'
          disableRipple
          icon={!!tab.count || tab.count === 0 ? <span>{tab.count}</span> : undefined}
        />
      ))}
    </STabs>
  );
};

export default Tabs;
