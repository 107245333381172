import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { palette } from 'utils/constants';

import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import { getCustomerPayTerms } from 'Api/Customers';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { getErrorMessage } from 'utils/error';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { createCarrierPaymentTerms, getCarrierPayTerms, updateCarrierPayTerm } from 'Api/Carriers';
import Autocomplete from 'common/Autocomplete';
import Input, { InputWithText } from 'common/Input';
import AddressFields from 'common/AddressFields';
import useForm from 'hooks/useForm';
import useShowToaster from 'hooks/useShowToaster';
import { payTermValidationSchema } from 'pages/OnboardCarrier/validationSchemas';
import PaymentMethod from 'components/Carriers/AddCarrier/PaymentMethod';
import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import InputLabel from 'common/InputLabel';
import classes from '../../AddCarrier/PaymentTerms.module.scss';
import sm from './PaymentTermsTab.module.css';
import ViewReason from '../ViewReason';

const PaymentTermsTab = ({ company }) => {
  const [toggle, setToggle] = React.useState(false);
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState();
  const showToaster = useShowToaster();
  const { offer_quick_pay, quick_pay_count, quick_pay_type, quick_pay_percent, default_invoice_pay_term } =
    company || {};
  const [addReason, setAddReason] = useState(false);

  const durationConvertor = {
    days: 'Days',
    hours: 'hours',
    weeks: 'weeks',
  };

  const { id } = useParams();
  const {
    id: payTermId,
    pay_factoring,
    factoring_company,
    email,
    pay_term_types,
    address1,
    address2,
    country,
    city,
    state,
    zipcode,
    quick_pay,
    payment_method,
    bank_name,
    bank_routing,
    bank_account,
    bank_direct_deposit_form,
    third_party_name,
    third_party_id,
    bank_account_verified,
    bank_account_mismatch_reason,
  } = paymentTerms || {};

  const initialValues = {
    invoicePayTerm: pay_term_types || default_invoice_pay_term,
    payFactoring: !!pay_factoring,
    factoringCompany: factoring_company,
    email,
    address: address1,
    address2,
    country,
    city,
    state,
    zipcode,
    quick_pay: !!quick_pay,
    payment_method: payment_method || 'check',
    bank_name: bank_name || '',
    bank_routing: bank_routing || '',
    routing_confirmed: bank_routing || '',
    bank_account: bank_account || '',
    account_confirmed: bank_account || '',
    bank_direct_deposit_form: bank_direct_deposit_form || '',
    third_party_name: third_party_name || '',
    third_party_id: third_party_id || '',
  };

  const handleBasicInfoSubmit = (values) => {
    const data = {
      invoice_pay_term: values.invoicePayTerm?.id,
      pay_factoring: Number(values.payFactoring),
      factoring_company: values.payFactoring ? values.factoringCompany : null,
      email: values.payFactoring ? values.email : null,
      address1: values.payFactoring ? values.address : null,
      address2: values.payFactoring ? values.address2 : null,
      country_id: values.payFactoring ? values.country?.id : null,
      state_id: values.payFactoring ? values.state?.id : null,
      city_id: values.payFactoring ? values.city?.id : null,
      zipcode: values.payFactoring ? values.zipcode : null,
      quick_pay: Number(values.quick_pay),
      carrier_id: id,
      payment_method: values.payment_method,
      bank_name: values.bank_name || '',
      bank_routing: values.bank_routing || '',
      bank_account: values.bank_account || '',
      bank_direct_deposit_form: values.bank_direct_deposit_form || '',
      third_party_name: values.third_party_name || '',
      third_party_id: values.third_party_id || '',
      bank_account_verified,
    };
    if (payTermId) {
      updateCarrierPayTerm(data, payTermId)
        .then(() => {
          setToggle(!toggle);
          getPayTerms();
          showToaster({ type: 'success', message: 'Payment Terms updated successfully!' });
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    } else {
      createCarrierPaymentTerms(data)
        .then(() => {
          setToggle(!toggle);
          getPayTerms();
          showToaster({ type: 'success', message: 'Payment Terms updated successfully!' });
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    }
  };

  const form = useForm({
    initialValues,
    onSubmit: handleBasicInfoSubmit,
    validationSchema: payTermValidationSchema,
    enableReinitialize: true,
  });

  const { values, handleChange, touchedErrors, setValues } = form;

  const { use } = useTheme();

  const getPayTerms = () => {
    getCarrierPayTerms({ id }).then((result) => {
      setPaymentTerms(result.data);
      getCustomerPayTerms().then((res) => {
        if (res && res?.data) {
          setInvoicePayTermsOptions(res.data);
        }
      });
    });
  };

  useEffect(() => {
    getPayTerms();
  }, []);

  return (
    <div
      className={sm.paymentTermsTab}
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderBottomColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div
        className={sm['paymentTermsTab-header']}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <Typography variant='h4' style={{ color: use(palette.gray900, palette.gray50) }}>
          Payment Terms
        </Typography>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={() => setToggle(!toggle)} className='driver-compansation-cancel-btn'>
              Cancel
            </button>

            <button onClick={form.handleSubmit} className='driver-compansation-save-btn'>
              Save
            </button>
          </div>
        )}
      </div>
      {toggle ? (
        <div className={classes.payTermsFormWrapper}>
          <div className='d-flex gap-5 align-items-end'>
            <div className='mt-2'>
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Invoice Pay Terms <span className='required'>*</span>
              </Typography>
              <Autocomplete
                getOptionLabel={(option) => `${getPaymentTermTitle(option)}`}
                placeholder='Select ...'
                options={invoicePayTermsOptions || []}
                value={values.invoicePayTerm}
                onChange={(e, val) => handleChange('invoicePayTerm', val)}
                isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
                style={{ width: '340px', marginTop: 5 }}
                error={touchedErrors?.invoicePayTerm}
              />
            </div>
            <div>
              {!!offer_quick_pay && (
                <CustomCheckbox
                  checked={values.quick_pay}
                  onChange={(e) => handleChange('quick_pay', e)}
                  disabled={values.payFactoring}
                >
                  <Typography variant='s2' style={{ color: palette.gray700, marginLeft: '8px' }}>
                    Enable {quick_pay_count} {durationConvertor[quick_pay_type]} Quick Pay - {quick_pay_percent}% Fee
                    Deducted Per Invoice)
                  </Typography>
                </CustomCheckbox>
              )}
            </div>
          </div>

          <div className={classes.payFactoringWrapper}>
            <CustomCheckbox
              name='pay_driver1'
              type='switch'
              smail={false}
              checked={values.payFactoring}
              onChange={(checked) => {
                if (checked) {
                  setValues((prevState) => ({ ...prevState, payFactoring: checked, quick_pay: false }));
                } else {
                  handleChange(`payFactoring`, checked);
                }
              }}
            />
            <Typography variant='s2'>Pay Factoring</Typography>
          </div>

          {!!values.payFactoring && (
            <div className={classes.payFactoringFieldsWrapper}>
              <div className={classes.contactWrapper}>
                <div>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Factoring Company <span className='required'>*</span>
                  </Typography>
                  <Input
                    name='origin_city'
                    style={{ width: '340px', marginTop: 5 }}
                    placeholder='Factoring Company'
                    onChange={(e) => handleChange(`factoringCompany`, e.target.value)}
                    value={values.factoringCompany}
                    error={touchedErrors.factoringCompany}
                  />
                </div>
                <div>
                  <Typography variant='s2' style={{ color: palette.gray700 }}>
                    Factor Email <span className='required'>*</span>
                  </Typography>
                  <Input
                    name='email'
                    style={{ width: '340px', marginTop: 5 }}
                    placeholder='example@email.com'
                    onChange={(e) => handleChange(`email`, e.target.value)}
                    value={values.email}
                    error={touchedErrors.email}
                  />
                </div>
              </div>
              <div className='w-75 mt-4'>
                <AddressFields values={values} handleChange={handleChange} touchedErrors={touchedErrors} />
              </div>
            </div>
          )}

          <div className='mt-5'>
            <Typography variant='s1' style={{ color: palette.gray900 }}>
              Payout Method
            </Typography>
            <PaymentMethod form={form} carrierId={id} />
          </div>
        </div>
      ) : (
        <div className={sm.list}>
          <div className={sm['list-item']}>
            <Typography variant='b2' style={{ color: palette.gray700, minWidth: '300px' }}>
              Invoice Pay Terms
            </Typography>
            <Typography variant='b2'>{getPaymentTermTitle(pay_term_types || default_invoice_pay_term)}</Typography>
          </div>

          {!!offer_quick_pay && (
            <div className={sm['list-item']}>
              <Typography variant='b2' style={{ color: palette.gray700, minWidth: '300px' }}>
                Request Quick Pay
              </Typography>
              {quick_pay ? (
                <Chip
                  label={`Enabled ${quick_pay_count} ${durationConvertor[quick_pay_type]} Quick Pay - ${quick_pay_percent}% Fee Deducted Per Invoice`}
                  labelColor={palette.green400}
                  bgColor={palette.green0}
                />
              ) : (
                <Chip label='NO' />
              )}
            </div>
          )}
          <div className={sm['list-item']}>
            <Typography variant='b2' style={{ color: palette.gray700, minWidth: '300px' }}>
              Pay Factoring
            </Typography>
            {pay_factoring ? (
              <Chip label='YES' labelColor={palette.green400} bgColor={palette.green0} />
            ) : (
              <Chip label='NO' />
            )}
          </div>
          {!!pay_factoring && (
            <div style={{ marginLeft: 30 }}>
              <div className={sm['list-item']}>
                <Typography variant='b2' style={{ color: palette.gray700, minWidth: '270px' }}>
                  Factoring Company
                </Typography>
                <Typography variant='b2'>{factoring_company || '-'}</Typography>
              </div>
              <div className={sm['list-item']}>
                <Typography variant='b2' style={{ color: palette.gray700, minWidth: '270px' }}>
                  Factor Email
                </Typography>
                <Typography variant='b2'>{email || '-'}</Typography>
              </div>
              <div className={sm['list-item']}>
                <Typography variant='b2' style={{ color: palette.gray700, minWidth: '270px' }}>
                  Address
                </Typography>
                <Typography variant='b2'>
                  {address1 ? `${address1},` : '-'}
                  <br />
                  {city && `${city?.name}, ${state?.name},`}
                  <br />
                  {country && `${country?.name} - ${zipcode}`}
                </Typography>
              </div>
            </div>
          )}
          <div className={sm['list-item']}>
            <div className='d-flex flex-column gap-2'>
              <Typography variant='b2' style={{ color: palette.gray700, minWidth: '270px' }}>
                Payout Method
              </Typography>
              {bank_account_verified === 1 && (
                <Tooltip
                  title='The carrier has connected their account and we have verified it.'
                  arrow={false}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: 13,
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        maxWidth: 'unset',
                      },
                    },
                  }}
                >
                  <span>
                    <Chip
                      label='VERIFIED'
                      labelColor={palette.green400}
                      bgColor={palette.green0}
                      style={{ width: 80 }}
                    />
                  </span>
                </Tooltip>
              )}
              {bank_account_verified === 2 && (
                <div className='d-flex align-items-start flex-column mt-3 gap-2'>
                  <Chip
                    label='Possible Mismatch / Fraud'
                    labelColor={palette.red400}
                    bgColor={palette.red0}
                    style={{ width: 180 }}
                  />
                  <Typography
                    variant='s3'
                    style={{ color: palette.indigo500, textDecoration: 'underline' }}
                    onClick={() => setAddReason(true)}
                  >
                    View Reason
                  </Typography>
                </div>
              )}
            </div>
            {payment_method === 'check' && <Typography variant='b2'>Check</Typography>}

            {payment_method === 'ach' && (
              <div className='d-flex gap-3 mb-2'>
                <div className='d-flex flex-column gap-3'>
                  <InputWithText left className='w-100' name='bank_name' text='Bank Name' value={bank_name} disabled />
                  <div className='d-flex gap-2'>
                    <InputWithText
                      left
                      textWidth='96px'
                      width='200px'
                      name='bank_routing'
                      text='Routing #'
                      value={bank_routing}
                      disabled
                    />

                    <Input name='routing_confirmed' placeholder='Re-enter Routing #' value={bank_routing} disabled />
                  </div>
                  <div className='d-flex gap-2'>
                    <InputWithText
                      left
                      textWidth='96px'
                      width='200px'
                      type='number'
                      name='bank_account'
                      text='Account #'
                      value={bank_account}
                      disabled
                    />

                    <Input name='account_confirmed' placeholder='Re-enter Account #' value={bank_account} disabled />
                  </div>
                </div>
              </div>
            )}
            {payment_method === 'third_party' && (
              <div className='d-flex flex-column gap-2'>
                <div className='d-flex gap-2'>
                  <InputLabel>3rd Party Name -</InputLabel>
                  <Typography variant='b2'>{third_party_name}</Typography>
                </div>
                <div className='d-flex gap-2'>
                  <InputLabel>3rd Party Identifier -</InputLabel>
                  <Typography variant='b2'>{third_party_id}</Typography>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {addReason && bank_account_mismatch_reason && (
        <ViewReason
          open={addReason}
          details_info={JSON.parse(bank_account_mismatch_reason || {})}
          onClose={() => {
            setAddReason(false);
          }}
        />
      )}
    </div>
  );
};

export default PaymentTermsTab;
