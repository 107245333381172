import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SWrapper = styled.div`
  .scrollable-container {
    display: flex;
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .chart-boxes-wrapper {
    width: 80%;
    height: 366px;
    display: flex;
    min-width: 1200px;
  }

  .stat-boxes-wrapper {
    width: 20%;
    display: flex;
    min-width: 320px;
    flex-direction: column;
  }
`;

export const SBoxWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-left: none;
  border-bottom: 1px solid ${palette.gray50};
  padding: 8px 2px 16px 16px;
  min-width: ${({ $minWidth }) => $minWidth || ''};
  max-width: ${({ $maxWidth }) => $maxWidth || ''};

  :last-child {
    border-right: 1px solid ${palette.gray50};
  }

  .chart-wrapper {
    height: calc(100% - 60px);
  }

  canvas {
    height: 100% !important;
  }
`;

export const SStatBoxWrapper = styled.div`
  width: 100%;
  border: 1px solid ${palette.gray50};
  border-bottom: none;
  padding: 16px;
  min-width: ${({ $minWidth }) => $minWidth || ''};
  max-width: ${({ $maxWidth }) => $maxWidth || ''};

  .title-wrapper {
    min-height: 25px;
  }
`;

export const SummaryWrapper = styled.div`
  .MuiTableRow-root::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .MuiTableRow-root::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 8px;
  }

  .progress-bar {
    background: ${palette.indigo500};
  }
`;

export const SBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;
  width: 100%;

  @media (min-width: 1800px) {
    max-width: 500px !important;
  }

  .download-text {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    justify-content: flex-end;
    margin-right: 8px;
  }

  .download-text span {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #4f5aed;
    border-bottom: transparent;
  }

  .download-text:hover span {
    text-decoration: underline;
  }

  .MuiTableCell-root {
    padding: 8px 4px;
    border-bottom: none;
  }

  .MuiTableRow-root.MuiTableRow-head th {
    height: 32px !important;
  }

  .MuiTableRow-root.MuiTableRow-head {
    border-top: none;
    border-bottom: 1px solid rgb(224, 224, 224);
  }

  .receivable-chart-section-tag-indigo {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${palette.indigo500};
    padding: 2px 10px;
    background: ${palette.indigo0};
    border-radius: 10px;
    margin-left: 10px;
  }
`;
