import React, { useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import Search from 'common/Search';
import Pagination from 'common/Pagination';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import TableWrapper from 'components/TableWrapper';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import TablePreLoader from 'components/TablePreLoader/TablePreLoader';
import { palette } from 'utils/constants';
import { getStandaloneRecurringInvoices } from 'Api/AccountingReceivables';
import { useColumns } from './Recurring.data';
import { initialFilters, otherRevenueFilterOptions } from '../TableSection.data';
import { SSearchWrapper, STableTop } from '../../Receivables.styles';

const Recurring = ({ customers, groups, tableRefreshIndex }) => {
  const [recurringInvoices, setRecurringInvoices] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState(otherRevenueFilterOptions[0]);
  const debouncedSearch = useDebounce(search, 500);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [sort, setSort] = useState({ field: 'customer_billed_date', sortBy: 'desc' });

  const getRecurringInvoices = async () => {
    try {
      setLoading(true);
      const { page, itemsPerPage } = selectedFilters || {};
      const filter = `filters[${filterType.value}]`;
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page,
        itemsPerPage,
        [filter]: debouncedSearch || undefined,
        [sortField]: sort.sortBy,
        'filters[is_brokered]': selectedFilters.is_brokered ? 1 : undefined,
        'filters[customer_id]': selectedFilters.customers?.length
          ? selectedFilters.customers.map((i) => i.id)
          : undefined,
        'filters[group_id]': selectedFilters.groups?.length ? selectedFilters.groups.map((i) => i.id) : undefined,
      };

      const response = await getStandaloneRecurringInvoices(params);
      setRecurringInvoices(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  useEffect(() => {
    getRecurringInvoices();
  }, [selectedFilters, debouncedSearch, sort, tableRefreshIndex]);

  const columns = useColumns({ sort, sortingQuery });

  return (
    <div>
      <STableTop>
        <div className='filters-wrapper'>
          <SSearchWrapper>
            <div>
              <Autocomplete
                width='150px'
                labelKey='label'
                options={otherRevenueFilterOptions}
                value={filterType}
                onChange={(e, val) => {
                  setFilterType(val);
                  setSelectedFilters(initialFilters);
                }}
              />
            </div>
            {filterType?.value === 'customer' ? (
              <Autocomplete
                multiple
                width='380px'
                labelKey='company_name'
                disableClearable={false}
                options={customers}
                value={selectedFilters.customers}
                placeholder={selectedFilters.customers?.length ? '' : 'Select..'}
                onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, customers: val }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                className='filter-dropdown'
                limitTags={2}
              />
            ) : filterType?.value === 'group' ? (
              <Autocomplete
                multiple
                width='380px'
                labelKey='group_name'
                disableClearable={false}
                options={groups}
                value={selectedFilters.groups}
                placeholder={selectedFilters.groups?.length ? '' : 'Select..'}
                onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                className='filter-dropdown'
                limitTags={2}
              />
            ) : (
              <Search search={search} onChange={setSearch} className='search-input' />
            )}
          </SSearchWrapper>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='is_brokered'
              checked={!!selectedFilters.is_brokered}
              onChange={(checked) => setSelectedFilters((prevState) => ({ ...prevState, is_brokered: checked }))}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Brokered Shipment Only
            </Typography>
          </div>
        </div>
      </STableTop>
      {loading ? (
        <TablePreLoader styleWrapper={{ marginTop: 0 }} />
      ) : (
        <div className='tableFixHead table-fixed-header-300'>
          <TableWrapper
            data={recurringInvoices?.data}
            rowPerPage={selectedFilters.itemsPerPage}
            style={{ backgroundColor: palette.white }}
            components={{
              Pagination: () =>
                Pagination({
                  data: recurringInvoices,
                  rowPerPage: selectedFilters.itemsPerPage,
                  onChangeRowPerPage,
                  onPageChange,
                  rowsPerPageOptions: [25, 50, 100, 150],
                }),
            }}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

export default Recurring;
