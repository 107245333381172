export const typeOptions = [
  {
    name: 'License',
    id: 1,
  },
  {
    name: 'Passport',
    id: 2,
  },
  {
    name: 'ID',
    id: 3,
  },
];
