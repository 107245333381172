import React, { useEffect, useMemo, useState } from 'react';
import { Collapse, OverlayTrigger, Popover } from 'react-bootstrap';

import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { ReactComponent as DeleteItemIcon } from 'assets/icons/delete.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/createShipment/arrowDown.svg';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomButton from 'components/CustomButton/CustomButton';
import sm from './SecurityLogFilter.module.css';

const types = [
  {
    id: 1,
    title: 'Staff Users',
  },
  {
    id: 2,
    title: 'Drivers',
  },
];

const SecurityLogFilter = ({ filter, updateFilter, disabledFilters, handleReset }) => {
  const { use } = useTheme();

  const [show, setShow] = useState(false);
  const [collapses, setCollapses] = useState({ types: false });
  const [localFilter, setLocalFilter] = useState({
    types: filter?.types ? [...filter.types] : [],
  });

  const styles = useMemo(() => {
    return {
      container: {
        marginBottom: 24,
        borderColor: use(palette.gray200, palette.gray700),
      },
      title: {
        color: use(palette.indigo500, palette.indigo500),
        marginTop: 4,
        background: palette.indigo0,
        padding: '0 6px 0 6px',
      },
      reset: {
        marginLeft: 8,
        marginTop: 4,
        cursor: 'pointer',
        color: use(palette.indigo500, palette.indigo500),
      },
      searchValue: {
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: use(palette.indigo700, palette.indigo700),
      },
      additionalText: {
        marginTop: 4,
        whiteSpace: 'nowrap',
        background: palette.indigo0,
        color: use(palette.indigo700, palette.indigo700),
      },
      options: {
        cursor: 'pointer',
      },
      checkboxes: {
        margin: '0 0 5px 10px',
        width: 'calc(50% - 30px)',
      },
      checkboxesText: {
        color: use(palette.gray700, palette.gray200),
        marginLeft: 5,
        whiteSpace: 'nowrap',
      },
    };
  }, [use, palette]);

  function onToggleCollapse(name) {
    setCollapses((prevState) => {
      return {
        types: false,
        [name]: !prevState[name],
      };
    });
  }

  function onTickCheckboxes(type, id) {
    const updatedValue = [...localFilter[type]];
    if (updatedValue.includes(id)) {
      const index = updatedValue.findIndex((itemId) => itemId === id);
      updatedValue.splice(index, 1);
    } else {
      updatedValue.push(id);
    }
    const newState = {
      ...localFilter,
      [type]: updatedValue,
    };
    setLocalFilter(newState);

    return newState;
  }

  const deleteFilterHandler = (type, id) => {
    const localFilter = onTickCheckboxes(type, id);
    applyHandler(localFilter);
  };

  const applyHandler = (localFilter) => {
    setShow(false);
    updateFilter({ ...filter, ...localFilter });
  };

  const onReset = () => {
    const filterResetState = {
      types: [],
    };
    setLocalFilter(filterResetState);
    updateFilter({ ...filter, ...filterResetState });
    handleReset();
  };

  const optionsCount = filter?.types?.length ? 1 : 0;

  const content = (
    <Popover
      style={{
        minWidth: 426,
        backgroundColor: use(palette.white, palette.dark800),
      }}
    >
      <div className={sm.overlay_header} style={{ borderColor: use(palette.gray200, palette.gray700) }}>
        <Typography variant='h5'>Options</Typography>
      </div>

      <div className={sm.overlay_body} style={{ backgroundColor: use(palette.gray0, palette.dark700) }}>
        {/* Type */}
        <div className={sm.overlay_status_wrapper}>
          <div
            className={`${sm.closed} ${disabledFilters.type ? sm.disable_collapse : ''}`}
            onClick={() => {
              if (!disabledFilters.type) {
                onToggleCollapse('type');
              }
            }}
          >
            <Typography variant='s1'>Type</Typography>
            <ArrowDown style={{ transform: `rotateX(${collapses.type ? 180 : 0}deg)` }} />
          </div>

          <Collapse in={collapses.type}>
            <div className={sm.opened}>
              {types.map((currentType) => {
                const { id, title } = currentType;
                return (
                  <CustomCheckbox
                    key={id}
                    style={styles.checkboxes}
                    checked={localFilter.types.includes(id)}
                    onChange={() => onTickCheckboxes('types', id)}
                  >
                    <Typography variant='s2' style={styles.checkboxesText}>
                      {title}
                    </Typography>
                  </CustomCheckbox>
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>

      <div className={sm.overlay_footer} style={{ borderColor: use(palette.gray200, palette.gray700) }}>
        <CustomButton
          title='Cancel'
          type='secondary'
          onClick={() => setShow(false)}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 10px 10px 0', padding: '2px 12px' }}
        />
        <CustomButton
          title='Apply'
          type='primary'
          onClick={applyHandler.bind(null, localFilter)}
          styleTitle={{ fontSize: 12, marginTop: 1 }}
          styleButton={{ margin: '10px 0 10px', padding: '2px 12px' }}
        />
      </div>
    </Popover>
  );

  return (
    <div className={sm.TableHeaderFilterByShow_container} style={styles.container}>
      <div className={sm.title_wrapper}>
        <OverlayTrigger show={show} trigger='click' rootClose placement='right' overlay={content} onToggle={setShow}>
          <div className={sm.overlay_trigger_wrapper}>
            <Typography variant='overLine' style={styles.options}>
              <FilterIcon
                width={11}
                height={11}
                style={{ marginRight: 4 }}
                fill={use(palette.gray700, palette.gray200)}
              />
              Options: {optionsCount}
            </Typography>
          </div>
        </OverlayTrigger>
        <Typography variant='overLine' style={styles.reset} onClick={onReset}>
          Clear All
        </Typography>
      </div>
      <div className={sm.mapped_data_container}>
        <SelectedFilter
          title='Type'
          selectedTitleKey='title'
          selectedIds={filter?.types}
          allData={types}
          styles={styles}
          onDelete={(id) => deleteFilterHandler('types', id)}
        />
      </div>
    </div>
  );
};

const SelectedFilter = ({ title, selectedTitleKey, selectedIds, allData, styles, onDelete }) => {
  const [dataMap, setDataMap] = useState([]);

  useEffect(() => {
    const newDataMap = selectedIds?.map((id) => {
      return allData.find((data) => data.id === id);
    });
    if (Array.isArray(newDataMap)) {
      setDataMap(newDataMap);
    }
  }, [selectedIds]);

  if (dataMap.length === 0) {
    return null;
  }

  return (
    <div className={sm.mapped_data_wrapper}>
      <Typography variant='c1' style={styles.title}>
        {title}:
      </Typography>
      {dataMap.map((item, index) => {
        return (
          <div key={item?.id || index} className={sm.many_data_show_wrapper}>
            <Typography variant='c1' style={styles.searchValue}>
              {item[selectedTitleKey]}
              {index !== dataMap.length - 1 && ','}
              &nbsp;
            </Typography>
            <div className={sm.delete_item_wrapper} onClick={() => onDelete(item.id)}>
              <DeleteItemIcon width={8} height={8} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SecurityLogFilter;
