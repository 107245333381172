import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Divider from 'common/Divider';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette } from 'utils/constants';
import { getTabs } from 'Api/Planner';
import { getDriverGroups } from 'Api/DriverV2';
import { getCustomersList } from 'Api/Customers';
import { getAllStopPoints } from 'Api/StopPoint';
import QuickbooksSync from 'componentsV2/QuickbooksSync';
import ItemsSettings from '../../Payables/components/ItemsSettings';
import Drafts from './Drafts';
import Recurring from './Recurring';
import PaidInvoices from './PaidInvoices';
import OpenInvoices from './OpenInvoices';
import ReadyToInvoice from './ReadyToInvoice';
import MissingPaperwork from './MissingPaperwork';
import CreateInvoice from './shared/CreateInvoice';
import { STableWrapper, STabs } from '../Receivables.styles';

const TableSection = ({ activeTab, handleTabChange, refreshStats }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openCreate = searchParams.get('openCreate');
  const [openItemsSettings, setOpenItemsSettings] = useState(false);
  const [openCreateInvoice, setOpenCreateInvoice] = useState(openCreate || false);
  const [customers, setCustomers] = useState([]);
  const [stopPoints, setStopPoints] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tableRefreshIndex, setTableRefreshIndex] = useState(Date.now());

  const [counts, setCounts] = useState({
    missing_paperwork: '',
    ready_to_invoice: '',
    open_invoices: '',
    past_due: '',
    recurring: '',
    draft: '',
    paid_invoices: '',
  });

  const getCounts = async () => {
    const { data } = await getTabs({ type: 'accounts_receivable' });
    setCounts({
      missing_paperwork: data[0].count,
      ready_to_invoice: data[1].count,
      open_invoices: data[2].count,
      past_due: data[3].count,
      recurring: data[4].count,
      draft: data[5].count,
      paid_invoices: data[6].count,
    });
  };

  const getCustomers = async () => {
    try {
      const { data } = await getCustomersList({ itemsPerPage: 1000 });
      setCustomers(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getStopPoints = async () => {
    try {
      const { data } = await getAllStopPoints();
      setStopPoints(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await getDriverGroups();
      setGroups(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getCustomers();
    getStopPoints();
    getGroups();
  }, []);

  const currentTab = useMemo(() => {
    switch (activeTab) {
      case 'missing_paperwork':
        return <MissingPaperwork customers={customers} stopPoints={stopPoints} groups={groups} getCounts={getCounts} />;
      case 'ready_to_invoice':
        return (
          <ReadyToInvoice
            customers={customers}
            stopPoints={stopPoints}
            groups={groups}
            getCounts={getCounts}
            tableRefreshIndex={tableRefreshIndex}
          />
        );
      case 'open_invoices':
        return (
          <OpenInvoices
            customers={customers}
            stopPoints={stopPoints}
            groups={groups}
            getCounts={getCounts}
            tableRefreshIndex={tableRefreshIndex}
          />
        );
      case 'past_due':
        return (
          <OpenInvoices
            customers={customers}
            stopPoints={stopPoints}
            groups={groups}
            getCounts={getCounts}
            isPastDue
            tableRefreshIndex={tableRefreshIndex}
          />
        );
      case 'recurring':
        return <Recurring customers={customers} groups={groups} tableRefreshIndex={tableRefreshIndex} />;
      case 'draft':
        return <Drafts customers={customers} groups={groups} tableRefreshIndex={tableRefreshIndex} />;
      case 'paid_invoices':
        return (
          <PaidInvoices
            customers={customers}
            stopPoints={stopPoints}
            groups={groups}
            tableRefreshIndex={tableRefreshIndex}
          />
        );
      default:
    }
  }, [activeTab, customers, stopPoints, groups, tableRefreshIndex]);

  useEffect(() => {
    getCounts();

    searchParams.delete('openCreate');
    setSearchParams(searchParams);
  }, []);

  return (
    <STableWrapper>
      <div className='create-invoice'>
        <div className='d-flex align-items-center gap-3'>
          <CustomButton
            type='secondary'
            title='···'
            styleButton={{ padding: '5px 12px', margin: 0 }}
            onClick={() => setOpenItemsSettings(true)}
          />
          {(activeTab === 'open_invoices' || activeTab === 'past_due' || activeTab === 'paid_invoices') && (
            <QuickbooksSync
              action={activeTab === 'paid_invoices' ? 'paid_invoices' : 'open_invoices'}
              onSuccess={() => {
                refreshStats();
                setTableRefreshIndex(Date.now());
                getCounts();
              }}
            />
          )}
          <CustomButton
            type='primary'
            title='Create Invoice'
            leftIcon={<PlusIcon fill={palette.white} style={{ marginRight: 10 }} />}
            styleButton={{ padding: '5px 12px', marginTop: 0 }}
            onClick={() => setOpenCreateInvoice(true)}
          />
        </div>
      </div>
      <STabs value={activeTab} onChange={(e, newValue) => handleTabChange(newValue)}>
        <Tab
          id='tab-1'
          value='missing_paperwork'
          label='Missing Paperwork'
          iconPosition='end'
          icon={<span>{counts?.missing_paperwork}</span>}
          disableRipple
        />
        <Tab
          id='tab-2'
          value='ready_to_invoice'
          label='Ready to Invoice'
          iconPosition='end'
          icon={<span>{counts?.ready_to_invoice}</span>}
          disableRipple
        />
        <Tab
          id='tab-3'
          value='open_invoices'
          label='Open Invoices'
          iconPosition='end'
          icon={<span>{counts?.open_invoices}</span>}
          disableRipple
        />
        <Tab
          id='tab-4'
          value='past_due'
          label='Past Due'
          iconPosition='end'
          icon={<span>{counts?.past_due}</span>}
          disableRipple
        />
        <Tab
          id='tab-5'
          value='recurring'
          label='Recurring'
          iconPosition='end'
          icon={<span>{counts?.recurring}</span>}
          disableRipple
        />
        <Tab
          id='tab-6'
          value='draft'
          label='Drafts'
          iconPosition='end'
          icon={<span>{counts?.draft}</span>}
          disableRipple
        />
        <Tab id='tab-7' value='paid_invoices' label='Paid Invoices' disableRipple />
      </STabs>
      <Divider margin='2px 0 16px 0' />
      {currentTab}
      {openItemsSettings && <ItemsSettings open={openItemsSettings} onClose={() => setOpenItemsSettings(false)} />}
      {openCreateInvoice && (
        <CreateInvoice
          open={openCreateInvoice}
          onClose={() => setOpenCreateInvoice(false)}
          onSuccess={() => {
            refreshStats();
            setTableRefreshIndex(Date.now());
            getCounts();
          }}
        />
      )}
    </STableWrapper>
  );
};

export default TableSection;
